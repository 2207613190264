import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class A11yAnnouncerService {
	constructor(private readonly liveAnnouncer: LiveAnnouncer) {}

	async announce(message: string, politeness?: 'off' | 'polite' | 'assertive', duration?: number) {
		await this.liveAnnouncer.announce(message, politeness, duration);
		await new Promise(resolve => setTimeout(resolve, 2000));
		this.liveAnnouncer.clear();
	}
}
