import { Injectable, ComponentRef } from '@angular/core';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { Vulnerability, WeaknessSeverity, AgeFilterOptions, FilterImpactedAssets } from '@wcd/domain';
import { VulnerabilityPanelComponent } from '../components/vulnerability-panel.component';
import { Observable, of } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { ThreatInfoService } from '../../software-inventory/services/threat-info.service';

@Injectable()
export class VulnerabilityService {
	private _vulnerabilityPanel: ComponentRef<VulnerabilityPanelComponent>;
	private _vulnerabilitiesFilter: Record<string, any>;
	private _weaknessSeverityTypesToIgnore: WeaknessSeverity[];
	private _threatFilterValues: number[];

	constructor(
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private threatInfoService: ThreatInfoService,
		featuresService: FeaturesService
	) {
		this._weaknessSeverityTypesToIgnore = [WeaknessSeverity.None, WeaknessSeverity.Unknown];
		this._threatFilterValues = [0, 1];
		if (featuresService.isEnabled(Feature.TvmExploits)) {
			this._threatFilterValues.push(...[2, 3]);
		}
	}

	showVulnerabilityPanel(
		vulnerability: Vulnerability,
		includeExposedMachinesComponent: boolean,
		isNetworkGearCve?: boolean
	) {
		this.dialogsService
			.showPanel(
				VulnerabilityPanelComponent,
				{
					id: 'vulnerabilities-panel',
					isModal: true,
					showOverlay: false,
					hasCloseButton: true,
					type: PanelType.large,
					noBodyPadding: true,
					scrollBody: true,
					isBlocking: false,
					back: {
						onClick: () => this._vulnerabilityPanel && this._vulnerabilityPanel.destroy(),
					},
				},
				{
					vulnerability: vulnerability,
					includeExposedMachinesComponent: includeExposedMachinesComponent,
					isNetworkGearCve: isNetworkGearCve,
				}
			)
			.subscribe((panel: ComponentRef<VulnerabilityPanelComponent>) => {
				this._vulnerabilityPanel = panel;

				panel.onDestroy(() => {
					this._vulnerabilityPanel = null;
				});
			});
	}

	getFiltersData(): Observable<Record<string, any>> {
		if (!this._vulnerabilitiesFilter) {
			this._vulnerabilitiesFilter = {
				numOfImpactedAssets: {
					values: [
						{
							value: FilterImpactedAssets.hasEffect,
							name: this.i18nService.get(
								'tvm.vulnerabilitiesPage.filters.numOfImpactedAssets.hasEffect'
							),
						},
						{
							value: FilterImpactedAssets.noEffect,
							name: this.i18nService.get(
								'tvm.vulnerabilitiesPage.filters.numOfImpactedAssets.noEffect'
							),
						},
						{
							value: FilterImpactedAssets.notAvailable,
							name: this.i18nService.get('notAvailable.long'),
						},
					],
				},
				severity: {
					values: Object.keys(WeaknessSeverity)
						.filter(
							type => this._weaknessSeverityTypesToIgnore.indexOf(WeaknessSeverity[type]) < 0
						)
						.map(type => {
							return {
								value: type,
								name: WeaknessSeverity[type],
								priority: Object.keys(WeaknessSeverity).indexOf(type) + 1,
							};
						}),
				},
				threats: { values: this.threatInfoService.getFiltersData(true) },
				age: {
					values: Object.keys(AgeFilterOptions).map(type => {
						return {
							value: type,
							name: this.i18nService.get(
								`tvm.vulnerabilitiesPage.filters.age.${AgeFilterOptions[type]}`
							),
						};
					}),
				},
			};
		}
		return of(
			Object.keys(this._vulnerabilitiesFilter).reduce(
				(res, key) => Object.assign(res, { [key]: this._vulnerabilitiesFilter[key] }),
				{}
			)
		);
	}
}
