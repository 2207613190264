import { Component, Injector } from '@angular/core';
import { AadUser } from '@wcd/domain';
import { EntityDetailsComponentBase } from '../entity-details.component.base';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'aad-user-entity-details',
	templateUrl: './aad-user-entity-details.component.html',
})
export class AadUserEntityDetailsComponent extends EntityDetailsComponentBase<AadUser> {
	readonly unavailableTxt: string;
	get aadUser(): AadUser {
		return this.entity;
	}

	constructor(injector: Injector, private i18: I18nService) {
		super(injector);
		this.unavailableTxt = this.i18.get('dataUnavailable');
	}
}
