
		<fab-dialog
			[hidden]="hidden"
			[minWidth]="options.dimensions?.width || 400"
			[forceFocusInsideTrap]="false"
			[dialogContentProps]="{
				title: getText(options.title),
				styles: { title: getClassName(options.title) }
			}"
			[modalProps]="{
				isDarkOverlay: options.showOverlay,
				layerProps: { styles: { root: { zIndex: 9999 } } },
				isBlocking: options.isBlocking,
				styles: options.dimensions?.height && {
					main: { minHeight: options.dimensions.height }
				}
			}"
			(onDismiss)="cancelAndClose()"
		>
			<form (submit)="confirmAndClose($event)">
				<div
					*ngIf="getText(options.body) as bodyText"
					class="wcd-padding-bottom"
					[ngClass]="getClassName(options.body)"
				>
					<markdown [data]="bodyText"></markdown>
				</div>

				<ng-container #content></ng-container>

				<fab-dialog-footer>
					<div class="text-left">
						<fab-primary-button
							type="submit"
							[contentClass]="[getClassName(options.confirmButton), 'wcd-margin-small-right']"
							[disabled]="options.confirmDisabled || submitDisabled"
						>
							<fab-spinner
								*ngIf="submitInProgress"
								[size]="SpinnerSize.small"
								contentClass="wcd-margin-xsmall-right"
							>
							</fab-spinner>

							<div>
								<ng-container
									*ngIf="
										getText(options.confirmButton) as confirmText;
										else defaultConfirmText
									"
								>
									{{ confirmText }}
								</ng-container>

								<ng-template #defaultConfirmText>
								{{'buttons_confirm' | i18n}}
								</ng-template>
							</div>
						</fab-primary-button>

						<fab-default-button
							*ngIf="!hideCancelButton(options.cancelButton)"
							[text]="getText(options.cancelButton) || ('confirmation_dialog_cancel' | i18n)"
							[contentClass]="getClassName(options.cancelButton)"
							(onClick)="cancelAndClose()"
						>
						</fab-default-button>
					</div>
				</fab-dialog-footer>
			</form>
		</fab-dialog>
	