
		<fab-icon-button
			*ngIf="isSupportedActionType"
			[menuIconProps]="{ iconName: 'Flag' }"
			(click)="onFlagClick()"
			[styles]="getFlagButtonStyle()"
			[ariaLabel]="'events.markedEvents.filterText' | i18n"
			[tabIndex]="-1"
		>
		</fab-icon-button>
	