import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'USB event',
	pluralName: 'USB events',
	readonly: true,
})
export class UsbEvent extends EntityModelBase<string> {
	@EntityField({ data: 'DriveLetter' })
	driveLetter?: string;

	@EntityField({ data: 'ProductName' })
	productName?: string;

	@EntityField({ data: 'SerialNumber' })
	serialNumber?: string;

	@EntityField({ data: 'BusType' })
	busType?: string;

	@EntityField({ data: 'Volume' })
	volume?: string;

	@EntityField({ data: 'Manufacturer' })
	manufacturer?: string;
}
