import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { agentOnboardingStatusValues } from './agent-onboarding-status.entity.values';

@Entity({
	singularName: 'Agent onboarding Status',
	pluralName: 'Agent onboarding Status',
	values: agentOnboardingStatusValues,
})
export class AgentOnboardingStatus extends StatusModel {}
