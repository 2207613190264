import { TvmDashboardComponent } from './dashboard/tvm.dashboard.component';
import { Routes } from '@angular/router';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';
import { MdeAllowedActionsGuard, AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard, FlavorGuard, } from '@wcd/auth';
import { Feature } from '@wcd/config';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { AppFlavorConfig } from '@wcd/scc-common';
var ɵ0 = {
    breadcrumbsConfig: {
        itemConfig: {
            id: "tvmDashboard",
            label: 'Threat and Vulnerability Management',
            icon: 'dashboard',
            url: '/tvm_dashboard',
        },
        resetListOnEnter: true,
        show: false,
    },
    features: [Feature.TvmPages],
    pageTitleKey: 'menuSections.tvm',
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
    flavorConfig: AppFlavorConfig.routes.tvm,
};
var tvmRoutes = [
    {
        path: 'tvm_dashboard',
        canActivate: [
            FeatureEnabledGuard,
            AuthenticatedGuard,
            OnboardGuard,
            MdeAllowedActionsGuard,
            FlavorGuard,
        ],
        component: TvmDashboardComponent,
        data: ɵ0,
        resolve: {
            breadcrumbs: BreadcrumbsResolver,
        },
    },
];
var TvmModule = /** @class */ (function () {
    function TvmModule() {
    }
    return TvmModule;
}());
export { TvmModule };
export { ɵ0 };
