import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RbacService } from '../../../../rbac/services/rbac.service';

@Injectable()
export class CommonRemediationService {
	rbacGroupIdToNameMap$: Observable<{ [id: number]: string }>;

	constructor(rbacService: RbacService) {
		this.rbacGroupIdToNameMap$ = rbacService.userExposedRbacGroups$.pipe(
			map(rbacGroups => {
				const machineGroupNameById: { [id: number]: string } = rbacGroups.reduce(
					(map, currMachineGroup) => {
						map[currMachineGroup.id] = currMachineGroup.name;
						return map;
					},
					{}
				);
				return machineGroupNameById;
			})
		);
	}
}
