var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MailClusterUtils } from '../mailbox/mail-cluster.utils';
import { RiskLevelEnum } from '../mailbox/mailbox-risk-level.values';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';
var ɵ0 = ['username', 'user'];
var RemediationActionEntity = /** @class */ (function (_super) {
    __extends(RemediationActionEntity, _super);
    function RemediationActionEntity(props) {
        var _this = _super.call(this, props) || this;
        _this.displayQuery = MailClusterUtils.getDisplayQuery(_this.clusterBy, _this.clusterByValue);
        return _this;
    }
    __decorate([
        EntityField({ data: 'id' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "entityTypeId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], RemediationActionEntity.prototype, "created", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], RemediationActionEntity.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'threat_type' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "threatType", void 0);
    __decorate([
        EntityField({ data: 'Threats' }),
        __metadata("design:type", Array)
    ], RemediationActionEntity.prototype, "threats", void 0);
    __decorate([
        EntityField({ data: 'hash_sha1' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "sha1", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "path", void 0);
    __decorate([
        EntityField({ data: 'product_vendor' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "productVendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "pid", void 0);
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "username", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "address", void 0);
    __decorate([
        EntityField({ data: 'bin_path' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "binPath", void 0);
    __decorate([
        EntityField({ data: 'display_name' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "displayName", void 0);
    __decorate([
        EntityField({ data: 'driver_type' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "driverType", void 0);
    __decorate([
        EntityField({ data: 'command_line' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "commandLine", void 0);
    __decorate([
        EntityField({ data: 'user_object_id' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "identityId", void 0);
    __decorate([
        EntityField({ data: 'Url' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "url", void 0);
    __decorate([
        EntityField({ data: 'MailboxPrimaryAddress' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "mailboxPrimaryAddress", void 0);
    __decorate([
        EntityField({ data: 'Sender' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "sender", void 0);
    __decorate([
        EntityField({ data: 'P1Sender' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "p1Sender", void 0);
    __decorate([
        EntityField({ data: 'Recipient' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "recipient", void 0);
    __decorate([
        EntityField({ data: 'Subject' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "subject", void 0);
    __decorate([
        EntityField({ data: 'ClusterBy' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "clusterBy", void 0);
    __decorate([
        EntityField({ data: 'ClusterByValue' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "clusterByValue", void 0);
    __decorate([
        EntityField({ data: 'PhishCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "phishCount", void 0);
    __decorate([
        EntityField({ data: 'MalwareCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "malwareCount", void 0);
    __decorate([
        EntityField({ data: 'HighConfidencePhishCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "highConfidencePhishCount", void 0);
    __decorate([
        EntityField({ data: 'SpamCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "spamCount", void 0);
    __decorate([
        EntityField({ data: 'DeliveredCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "deliveredCount", void 0);
    __decorate([
        EntityField({ data: 'JunkedCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "junkedCount", void 0);
    __decorate([
        EntityField({ data: 'ReplacedCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "replacedCount", void 0);
    __decorate([
        EntityField({ data: 'BlockedCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "blockedCount", void 0);
    __decorate([
        EntityField({ data: 'MailboxCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "mailboxCount", void 0);
    __decorate([
        EntityField({ data: 'NotInMailboxCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "notInMailboxCount", void 0);
    __decorate([
        EntityField({ data: 'OnPremOrExternalCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "onPremOrExternalCount", void 0);
    __decorate([
        EntityField({ data: 'MailCount' }),
        __metadata("design:type", Number)
    ], RemediationActionEntity.prototype, "mailCount", void 0);
    __decorate([
        EntityField({ data: 'Operation' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "operation", void 0);
    __decorate([
        EntityField({ data: 'AccessRights' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "accessRights", void 0);
    __decorate([
        EntityField({ data: 'ForwardingSmtpAddress' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "forwardingSmtpAddress", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'RiskLevel' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "riskLevel", void 0);
    __decorate([
        EntityField({ data: 'ThreatsKeys' }),
        __metadata("design:type", Array)
    ], RemediationActionEntity.prototype, "threatsI18nKeys", void 0);
    __decorate([
        EntityField({ data: 'Sid' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "sid", void 0);
    __decorate([
        EntityField({ data: 'AadUserId' }),
        __metadata("design:type", String)
    ], RemediationActionEntity.prototype, "aadUserId", void 0);
    RemediationActionEntity = __decorate([
        ValueObject({
            singularName: 'Remediation Action Entity',
            pluralName: 'Remediation Action Entities',
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], RemediationActionEntity);
    return RemediationActionEntity;
}(ModelBase));
export { RemediationActionEntity };
export { ɵ0 };
