var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { MachineNetworkInfoRelationship, AdapterStatus, Machine, } from '@wcd/domain';
import { RegExpService } from '@wcd/shared';
import { finalize, map, tap } from 'rxjs/operators';
import { flatMap } from 'lodash-es';
import { Feature, FeaturesService } from '@wcd/config';
var MachineIpsComponent = /** @class */ (function () {
    function MachineIpsComponent(paris, changeDetector, featuresService) {
        this.paris = paris;
        this.changeDetector = changeDetector;
        this.featuresService = featuresService;
        this.isLoading = true;
        this.expandedIps = new Set();
        this.backendOptions = {
            isUnifiedDevicePage: featuresService.isEnabled(Feature.NewDevicePage),
        };
    }
    MachineIpsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paris
            .getRelatedItem(MachineNetworkInfoRelationship, this.machine, {
            where: this.backendOptions,
        })
            .pipe(tap(function (machineNetworkInfo) {
            _this.isMacSupportedForMachine = machineNetworkInfo.isMachineReportingMacAddress;
        }), map(function (machineNetworkInfo) {
            return _this.extractIps(machineNetworkInfo.ipAdapters).sort(_this.compareIps);
        }), finalize(function () {
            _this.isLoading = false;
        }))
            .subscribe(function (ips) {
            _this.machineIps = ips;
            _this.changeDetector.markForCheck();
        }, function (err) {
            _this.hasError = true;
            _this.changeDetector.markForCheck();
        });
    };
    MachineIpsComponent.prototype.toggleIp = function (ip) {
        if (this.expandedIps.has(ip))
            this.expandedIps.delete(ip);
        else
            this.expandedIps.add(ip);
    };
    MachineIpsComponent.prototype.extractIps = function (ipAdapters) {
        if (!ipAdapters) {
            return [];
        }
        return flatMap(ipAdapters, function (adapter) {
            return adapter.ipAddresses.map(function (ipAddress) { return (__assign({}, ipAddress, { adapter: adapter })); });
        });
    };
    MachineIpsComponent.prototype.compareIps = function (a, b) {
        // Show active adapters first, then with unknown status, and then those which are down
        if (a.adapter.status !== b.adapter.status) {
            switch (a.adapter.status) {
                case AdapterStatus.Up:
                    return -1;
                case AdapterStatus.Down:
                    return 1;
                case AdapterStatus.Unknown:
                    return b.adapter.status === AdapterStatus.Down ? -1 : 1;
            }
        }
        var aAddress = a.address || '';
        var bAddress = b.address || '';
        var aIsIpv4 = RegExpService.ipv4.test(aAddress), bIsIpv4 = RegExpService.ipv4.test(bAddress);
        // Show IPv4 addresses first
        if (aIsIpv4 !== bIsIpv4) {
            return aIsIpv4 ? -1 : 1;
        }
        // Then sort by length
        if (aAddress.length !== bAddress.length) {
            return aAddress.length - bAddress.length;
        }
        // Fall back to lexicographic order
        return aAddress.localeCompare(bAddress);
    };
    return MachineIpsComponent;
}());
export { MachineIpsComponent };
