import { RemediationType, RemediationTaskStateValue } from '@wcd/domain';
import { TvmDownloadService } from '../../../../../tvm/services/tvm-download.service';
import { of } from 'rxjs';
import { I18nService } from '../../../../../../../../../projects/i18n/src/public_api';
import { FeaturesService } from '@wcd/config';
import _ from 'lodash';
var RemediationTaskService = /** @class */ (function () {
    function RemediationTaskService(tvmDownloadService, i18nService, featuresService) {
        this.tvmDownloadService = tvmDownloadService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        // TODO: update those values when supporting remediation of those types. VSTS 23421057
        this.remediationTypesToIgnoreInFilter = [];
    }
    RemediationTaskService.prototype.downloadRemediationTaskCsv = function (remediationTask) {
        if (!remediationTask) {
            throw new Error('Error in RemediationTaskService[downloadRemediationTaskCsv] - remediation activity cant be undefined');
        }
        return this.tvmDownloadService.downloadCsv({
            url: "remediation-tasks/remediationTasks/" + remediationTask.id + "/remediation-task-details.csv",
            fileName: remediationTask.name,
            dataQuery: null,
        });
    };
    RemediationTaskService.prototype.getRemediationTaskFilters = function () {
        var _this = this;
        if (!this._remediationTaskFilters) {
            this._remediationTaskFilters = {
                remediationType: {
                    count: null,
                    values: Object.keys(RemediationType)
                        .filter(function (type) { return _this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0; })
                        .map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.remediationTask.type." + RemediationType[type]),
                            count: null,
                        };
                    }),
                },
                status: {
                    count: null,
                    values: Object.keys(RemediationTaskStateValue).map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.remediationTask.status." + RemediationTaskStateValue[type]),
                            count: null,
                        };
                    }),
                },
            };
        }
        this._remediationTaskFilters = _.omitBy(this._remediationTaskFilters, _.isNull);
        return of(Object.keys(this._remediationTaskFilters).reduce(function (res, key) {
            var _a;
            return Object.assign(res, (_a = {}, _a[key] = _this._remediationTaskFilters[key], _a));
        }, {}));
    };
    return RemediationTaskService;
}());
export { RemediationTaskService };
