var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ValueObject, EntityModelBase } from '@microsoft/paris';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { sevilleInvestigationStatusToDisplayStatus } from '../investigation/investigation-status.values';
var ɵ0 = function (fieldData) {
    var displayStatus = sevilleInvestigationStatusToDisplayStatus[fieldData];
    return displayStatus && displayStatus.id;
};
var EvaluationInvestigation = /** @class */ (function (_super) {
    __extends(EvaluationInvestigation, _super);
    function EvaluationInvestigation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'InvestigationId' }),
        __metadata("design:type", Number)
    ], EvaluationInvestigation.prototype, "investigationId", void 0);
    __decorate([
        EntityField({ data: 'end_date' }),
        __metadata("design:type", Date)
    ], EvaluationInvestigation.prototype, "endDate", void 0);
    __decorate([
        EntityField({ data: 'start_date' }),
        __metadata("design:type", Date)
    ], EvaluationInvestigation.prototype, "startDate", void 0);
    __decorate([
        EntityField({ data: 'title' }),
        __metadata("design:type", String)
    ], EvaluationInvestigation.prototype, "title", void 0);
    __decorate([
        EntityField({
            data: 'status',
            parse: ɵ0,
        }),
        __metadata("design:type", InvestigationStatus)
    ], EvaluationInvestigation.prototype, "status", void 0);
    EvaluationInvestigation = __decorate([
        ValueObject({
            singularName: 'Evaluation investigation',
            pluralName: 'Evaluation investigation',
        })
    ], EvaluationInvestigation);
    return EvaluationInvestigation;
}(EntityModelBase));
export { EvaluationInvestigation };
export { ɵ0 };
