var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FeaturesService, PreferencesService, Dictionary } from '@wcd/config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { ConvergencePromotionService } from "../../../../../convergence-promotion/services/convergence-promotion.service";
import { I18nService } from '@wcd/i18n';
var BANNER_DISMISSED = "promotionBannerDismissedTs";
var PromotionBannerWidget = /** @class */ (function (_super) {
    __extends(PromotionBannerWidget, _super);
    function PromotionBannerWidget(reportsService, featuresService, preferencesService, convergencePromotionService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.featuresService = featuresService;
        _this.preferencesService = preferencesService;
        _this.convergencePromotionService = convergencePromotionService;
        _this.i18nService = i18nService;
        _this.hide = false;
        return _this;
    }
    PromotionBannerWidget.prototype.dismiss = function () {
        this.convergencePromotionService.dismiss();
        this.hide = true;
        this.widgetConfig$.next(this.widgetConfig);
    };
    Object.defineProperty(PromotionBannerWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'promotion-banner',
                bodyClassName: "no-padding",
                isDisabled: this.hide || !this.convergencePromotionService.shouldShowPromotionBanner(),
                showHeader: false,
                hideOnNoData: true,
                name: this.i18nService.get('promotion_name'),
                loadData: function () { return _this.preferencesService.preferences$; },
                parseData: function (data) {
                    return { dismissTs: data.get(BANNER_DISMISSED) };
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    return PromotionBannerWidget;
}(ReportWidgetComponent));
export { PromotionBannerWidget };
