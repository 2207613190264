import { Injectable } from '@angular/core';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { MdeUserRoleActionEnum, ComparisonResultSingularEntity } from '@wcd/domain';
import {
	ComparisonResultEntityPanelComponent,
	TvmContextOptions,
} from '../components/comparison-result.entity-panel.component';
import { ItemActionModel } from '../../../../../app/dataviews/models/item-action.model';
import { TvmReportInaccuracyService } from '../../../../../app/tvm/services/tvm-report-inaccuracy.service';
import { FabricIconNames } from '@wcd/scc-common';
import { InaccuracyContext } from '../../../../../app/tvm/services/tvm-texts.service';

@Injectable()
export class ComparisonResultEntityTypeService implements EntityTypeService<ComparisonResultSingularEntity> {
	constructor(private reportInaccuracyService: TvmReportInaccuracyService) {}

	readonly entityType: EntityType<ComparisonResultSingularEntity> = {
		id: 'comparisonResult',
		entity: ComparisonResultSingularEntity,
		singleEntityPanelComponentType: ComparisonResultEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: (comparisonResult: ComparisonResultSingularEntity) => comparisonResult.cveId,
		getActions: (
			selectedcomparisonResults: Array<ComparisonResultSingularEntity>,
			options: TvmContextOptions
		) => {
			if (selectedcomparisonResults.length !== 1) {
				return [];
			}
			return [];
		},
	};
}
