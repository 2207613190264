var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase, DataQuerySortDirection } from '@microsoft/paris';
import { OAuthAppRisk } from './oauth-app-risk.values';
import { Stream } from '../entity/stream.value-object';
export var ApplicationType;
(function (ApplicationType) {
    ApplicationType["CloudApplication"] = "cloud-application";
    ApplicationType["OAuthApplication"] = "oauth-application";
})(ApplicationType || (ApplicationType = {}));
export var ApplicationTypeEnum;
(function (ApplicationTypeEnum) {
    ApplicationTypeEnum[ApplicationTypeEnum["CloudApplication"] = 8192] = "CloudApplication";
    ApplicationTypeEnum[ApplicationTypeEnum["OAuthApplication"] = 16384] = "OAuthApplication";
})(ApplicationTypeEnum || (ApplicationTypeEnum = {}));
export var isApplicationAsset = function (type) {
    return type === ApplicationType.CloudApplication || type === ApplicationType.OAuthApplication;
};
var ɵ0 = function (dataQuery) {
    if (!dataQuery) {
        return {};
    }
    var pageSettings = {};
    if (dataQuery.page) {
        pageSettings.pageIndex = dataQuery.page;
    }
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    return Object.assign({}, dataQuery.where, pageSettings, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = ['ApplicationId', 'appId', 'oAuthAppId', 'applicationId'], ɵ3 = ['ApplicationClientId'], ɵ4 = ['OauthAppId '], ɵ5 = ['ActiveAlerts', 'activeAlerts'], ɵ6 = ['TotalAlerts', 'totalAlerts'], ɵ7 = ['stream', 'Stream'], ɵ8 = ['Type', 'type'], ɵ9 = function (typeData) {
    if (typeData === ApplicationTypeEnum.CloudApplication)
        return ApplicationType.CloudApplication;
    else if (typeData === ApplicationTypeEnum.OAuthApplication)
        return ApplicationType.OAuthApplication;
    return typeData;
}, ɵ10 = ['Name', 'name'], ɵ11 = function (nameField, applicationData) {
    if (applicationData.Type === ApplicationTypeEnum.CloudApplication ||
        applicationData.type === ApplicationType.CloudApplication)
        return applicationData.instanceName || applicationData.InstanceName || nameField;
    return nameField;
}, ɵ12 = ['RiskLevel', 'risk'], ɵ13 = ['OAuthObjectId', 'oAuthObjectId'], ɵ14 = ['InstanceName', 'instanceName'];
var Application = /** @class */ (function (_super) {
    __extends(Application, _super);
    function Application() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Application_1 = Application;
    Object.defineProperty(Application.prototype, "linkToAppPage", {
        get: function () {
            if (this.type == ApplicationType.OAuthApplication)
                return "/#/app?oauthAppId=" + this.id;
            return (this.stream) ? "/#/app?appId=" + this.id + "&streamId=" + this.stream.id : "/#/services/" + (Application_1.getAppstanceFromSaaSIdAndInstanceId(this.saasId, this.instanceId) || this.id);
        },
        enumerable: true,
        configurable: true
    });
    // This logic was copied from MCAS FE code - in the future we need to consider calculating this once in the BE and passing it on app entity
    Application.getAppstanceFromSaaSIdAndInstanceId = function (saasId, instanceId) {
        if (!saasId) {
            return null;
        }
        if (instanceId === null) {
            return Application_1.getAppstanceFromSaaSIdAndInstanceId(saasId, Application_1.NULLSTANCE_INSTANCE_ID);
        }
        if (isNaN(instanceId)) {
            return saasId;
        }
        if (instanceId > Application_1.NULLSTANCE_INSTANCE_ID) {
            throw new Error("instance " + instanceId + " is too big");
        }
        return (instanceId << 20) | saasId;
    };
    var Application_1;
    Application.NULLSTANCE_INSTANCE_ID = 2047;
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], Application.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", String)
    ], Application.prototype, "applicationClientId", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", String)
    ], Application.prototype, "oAuthAppId", void 0);
    __decorate([
        EntityField({ data: ɵ5 }),
        __metadata("design:type", Number)
    ], Application.prototype, "activeAlerts", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", Number)
    ], Application.prototype, "totalAlerts", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", Stream)
    ], Application.prototype, "stream", void 0);
    __decorate([
        EntityField({
            data: ɵ8,
            parse: ɵ9,
        }),
        __metadata("design:type", String)
    ], Application.prototype, "type", void 0);
    __decorate([
        EntityField({
            data: ɵ10,
            parse: ɵ11
        }),
        __metadata("design:type", String)
    ], Application.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'PublisherName' }),
        __metadata("design:type", String)
    ], Application.prototype, "publisher", void 0);
    __decorate([
        EntityField({ data: ɵ12, required: false }),
        __metadata("design:type", String)
    ], Application.prototype, "riskLevel", void 0);
    __decorate([
        EntityField({ data: ɵ13, required: false }),
        __metadata("design:type", String)
    ], Application.prototype, "oAuthObjectId", void 0);
    __decorate([
        EntityField({ data: ɵ14, required: false }),
        __metadata("design:type", String)
    ], Application.prototype, "instanceName", void 0);
    __decorate([
        EntityField({ data: 'InstanceId', required: false }),
        __metadata("design:type", Number)
    ], Application.prototype, "instanceId", void 0);
    __decorate([
        EntityField({ data: 'SaasId', required: false }),
        __metadata("design:type", Number)
    ], Application.prototype, "saasId", void 0);
    Application = Application_1 = __decorate([
        Entity({
            singularName: 'App',
            pluralName: 'Apps',
            endpoint: 'apps',
            parseDataQuery: ɵ0,
            allItemsProperty: 'results',
            baseUrl: ɵ1,
            cache: {
                time: 60000,
                max: 10,
            },
        })
    ], Application);
    return Application;
}(EntityModelBase));
export { Application };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
