var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var InvestigationActionCategory = /** @class */ (function (_super) {
    __extends(InvestigationActionCategory, _super);
    function InvestigationActionCategory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InvestigationActionCategory.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], InvestigationActionCategory.prototype, "iconName", void 0);
    InvestigationActionCategory = __decorate([
        Entity({
            singularName: 'Investigation Action Category',
            pluralName: 'Investigation Action Categories',
            values: [
                { iconName: 'investigationOutline', name: 'Analysis', id: 'Analysis' },
                { iconName: 'users.user', name: 'User activity', id: 'UserActivity' },
                { iconName: 'storage', name: 'Drive', id: 'Drive' },
                { iconName: 'entities.driver', name: 'Driver', id: 'Driver' },
                { iconName: 'entities.file', name: 'File', id: 'File' },
                { iconName: 'entities.connection', name: 'Network', id: 'Network' },
                { iconName: 'entities.process', name: 'Process', id: 'Process' },
                { iconName: 'entities.persistence_method', name: 'Persistence', id: 'Persistence' },
                { iconName: 'registry', name: 'Registry', id: 'Registry' },
                { iconName: 'entities.service', name: 'Service', id: 'Service' },
                { iconName: 'investigationOutline', name: 'System', id: 'System' },
                { iconName: 'users.user', name: 'User', id: 'User' },
                { iconName: 'actions', name: 'unknown', id: 'unknown' },
                { iconName: 'actions', name: 'Other', id: 'Other' },
            ],
        })
    ], InvestigationActionCategory);
    return InvestigationActionCategory;
}(EntityModelBase));
export { InvestigationActionCategory };
