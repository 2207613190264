import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { FILE_TEMPLATE } from '../actioncenter/seville.actioncenter.file';

SevilleModule.factory('blackListService', blackListService);

blackListService.$inject = ['hoverPaneService', '$http', 'appConfig', '$uibModal'];

function blackListService(hoverPaneService, $http, appConfig, $modal) {
	function openActionCenter(file) {
		hoverPaneService.open({
			template: FILE_TEMPLATE,
			controller: 'seville.actioncenter.file',
			controllerAs: 'actioncenterfile',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return file;
				},
			},
		});
	}

	return {
		blackList: function(
			file,
			commentText: string,
			isDeleted: boolean,
			newStatus,
			openActionCenterPanel: boolean,
			machineGroupsIds
		) {
			const rbacGroupsState = {};
			file.machineGroupsIds.forEach(function(groupId) {
				rbacGroupsState[groupId] = !isDeleted;
			});

			$http
				.post(appConfig.serviceUrls.userRequests + '/bannedfiles/rbac/upsert', {
					FileIdentifier: file.Sha1,
					FileIdentifierType: 'Sha1',
					RequestorComment: commentText,
					RbacGroupsState: rbacGroupsState,
					FileIdentifierSecondary: file.Sha256,
					FileIdentifierTypeSecondary: 'Sha256',
				})
				.then(function(response) {
					if (response.status == 200) {
						file.ResponseActionExists = true;
						file.BlacklistStatus = newStatus;
						if (openActionCenterPanel == true) {
							openActionCenter(file);
						}
					} else {
						$modal.open({
							template: ERROR_POPUP_TEMPLATE,
							size: 'md',
							windowClass: 'machine-actions-modal',
							backdrop: false,
						});
					}
				});
		},
		resolveBannedFilesStatus: function(
			blackList,
			isAdminUser: boolean,
			rbacMachineGroupsEnabled: boolean
		) {
			if (rbacMachineGroupsEnabled) {
				const isBlockedByAdmin: boolean = blackList.some(
					blockedFile => blockedFile.RbacGroupId === 0 && !blockedFile.IsDeleted
				);

				if (isBlockedByAdmin && !isAdminUser) {
					return 'BlockedByAdmin';
				}

				var isBlocked: boolean;
				if (isAdminUser) {
					isBlocked =
						isBlockedByAdmin ||
						(blackList.length > 0 && blackList.every(blockedFile => !blockedFile.IsDeleted));
				} else {
					const filteredBlackList = blackList.filter(blockedFile => blockedFile.RbacGroupId !== 0);
					isBlocked =
						filteredBlackList.length > 0 &&
						filteredBlackList.every(blockedFile => !blockedFile.IsDeleted);
				}

				return isBlocked ? 'Blocked' : 'Unblocked';
			} else {
				if (blackList.length > 0 && blackList.every(blockedFile => !blockedFile.IsDeleted)) {
					return 'Blocked';
				}
				return 'Unblocked';
			}
		},
	};
}
