import { Component } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { Router } from '@angular/router';

@Component({
	templateUrl: './assessment-job-completed.component.html',
})
export class AssessmentJobCompletedComponent extends WizardBaseStep<AssessmentJobModel> {
	constructor(private router: Router) {
		super();
	}

	navigateToDeviceInventory(): void {
		this.router.navigateByUrl('machines?category=network-devices');
	}
}
