import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { WcdSharedModule } from '@wcd/shared';
import { DataTableModule } from '@wcd/datatable';
import { HuntingCustomActionsComponent } from './components/hunting-custom-actions.component';
import { HuntingBulkActionsComponent } from './components/hunting-bulk-actions.component';
import { HuntingEntityNamesService } from './services/hunting-entity-names.service';
import { FabSpinnerModule } from '@angular-react/fabric';

@NgModule({
	imports: [SharedModule, PanelsModule, WcdFormsModule, WcdSharedModule, DataTableModule, FabSpinnerModule],
	declarations: [HuntingCustomActionsComponent, HuntingBulkActionsComponent],
	providers: [HuntingEntityNamesService],
	exports: [HuntingCustomActionsComponent],
	entryComponents: [HuntingBulkActionsComponent],
})
export class HuntingCustomActionsModule {}
