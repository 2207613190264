var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-click-events-have-key-events */
import { OnInit, OnDestroy, Injector, ChangeDetectorRef, } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { EntityDetailsComponentBase } from '../entity-details.component.base';
import { ActivatedEntity } from '../../../services/activated-entity.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { MissingKb, Vulnerability, MissingKbExposedAssetsRelationship, MissingKbRelatedVulnerabilitiesRelationship, AssetMissingKb, TvmEndPoint, AssetMissingKbVulnerabilityRelationship, EolState, LegalNoteLinks, ShouldShowLegalNoteFor, } from '@wcd/domain';
import { SidePanelService } from '../../../../@entities/@tvm/common/side-panel.service';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';
import { TextToken, TvmTextsService } from '../../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { MessageBarStyles } from '../../../../@entities/@tvm/common/styles';
import { Router } from '@angular/router';
import { MaxItemsInCollapseExpandTable } from '../../../../tvm/tvm.constants';
import { TzDateService } from '@wcd/localization';
var MissingKbEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(MissingKbEntityDetailsComponent, _super);
    function MissingKbEntityDetailsComponent(activatedEntity, sidePanelService, paris, i18nService, tvmTextsService, injector, tvmDownloadService, changeDetectorRef, featuresService, tzDateService, router) {
        var _this = _super.call(this, injector) || this;
        _this.activatedEntity = activatedEntity;
        _this.sidePanelService = sidePanelService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.tvmTextsService = tvmTextsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.tzDateService = tzDateService;
        _this.router = router;
        _this.SpinnerSize = SpinnerSize;
        _this.MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;
        _this.isAssetsMinimized = true;
        _this.isCvesMinimized = true;
        _this.MessageBarType = MessageBarType;
        _this.messageBarState = {
            showBody: false,
        };
        _this._messageBarStyles = MessageBarStyles;
        _this.LegalNoticeMessageBarType = MessageBarType;
        _this.legalNoticeMessageBarState = {
            showBody: false,
        };
        _this.missingKbExposedAssetsRepository = _this.paris.getRelationshipRepository(MissingKbExposedAssetsRelationship);
        _this.missingKbRelatedCvesRepository = _this.paris.getRelationshipRepository(MissingKbRelatedVulnerabilitiesRelationship);
        _this.assetVulnerabilitiesRepository = _this.paris.getRelationshipRepository(AssetMissingKbVulnerabilityRelationship);
        _this.isExposedOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
        _this.missingKbNameText = _this.i18nService.strings.tvm_missingKb_id;
        return _this;
    }
    Object.defineProperty(MissingKbEntityDetailsComponent.prototype, "missingKb", {
        get: function () {
            return this._missingKb;
        },
        set: function (missingKb) {
            this._missingKb = missingKb;
            this.isRedHatProductFix = missingKb.productFixId.startsWith('RHSA');
            this.legalNoticeText = this.tvmTextsService.getText(TextToken.RedHatLegalNotice);
            if (this.machineDetails.singleAssetMissingKb) {
                this.assetVulnerabilitiesRepository.sourceItem = new AssetMissingKb(__assign({}, this._missingKb, { assetId: this.machineDetails.machineId }));
            }
            else {
                this.loadNewAssets(missingKb);
                this.missingKbRelatedCvesRepository.sourceItem = missingKb;
            }
        },
        enumerable: true,
        configurable: true
    });
    MissingKbEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.missingKb) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof Vulnerability; }))
                .subscribe(function (missingKb) {
                _this.missingKb = missingKb;
                _this.initMissingKbFields();
            });
        }
        else {
            this.initMissingKbFields();
        }
    };
    MissingKbEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    MissingKbEntityDetailsComponent.prototype.initMissingKbFields = function () {
        this.showEolMessage = this.shouldShowEolMessage();
        this.eolText = this.getEolText();
        this.messageBarType =
            this.missingKb.eolVersionState === EolState.AlreadyEOL
                ? MessageBarType.warning
                : MessageBarType.info;
        this.legalNote = this.tvmTextsService.getText(TextToken.HasEolVersionsMicrosoftLegalNote, this.missingKb.productId);
        this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.missingKb.productId);
        this.legalNoteLink = LegalNoteLinks[this.missingKb.productId];
        this.legalNoteLinkText = this.tvmTextsService.getText(TextToken.LegalNoteLinkText, this.missingKb.productId);
        this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.missingKb.productId);
        this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
    };
    MissingKbEntityDetailsComponent.prototype.loadNewAssets = function (missingKb) {
        var queryOptions = {
            page: 1,
            pageSize: MaxItemsInCollapseExpandTable,
        };
        this.missingKbExposedAssetsRepository.sourceItem = missingKb;
        this.assets$ = this.missingKbExposedAssetsRepository.query(queryOptions);
    };
    MissingKbEntityDetailsComponent.prototype.openAllExposedMachines = function () {
        this.sidePanelService.showAllExposedAssetsPanel(this._missingKb);
    };
    MissingKbEntityDetailsComponent.prototype.openAllRelatedCves = function (includeExposedMachinesComponent) {
        this.sidePanelService.showAllRelatedCvesPanel(this.machineDetails.singleAssetMissingKb
            ? this.assetVulnerabilitiesRepository.sourceItem
            : this._missingKb, includeExposedMachinesComponent);
    };
    MissingKbEntityDetailsComponent.prototype.exportExposedMachines = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.missingKbExposedAssetsRepository, TvmEndPoint.Analytics, this.missingKb.vendorBulletin + " (" + this.missingKb.productFixId + ") - " + this.i18nService.get('tvm.common.exposedDevices'), undefined);
    };
    MissingKbEntityDetailsComponent.prototype.exportCves = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.machineDetails.singleAssetMissingKb
            ? this.assetVulnerabilitiesRepository
            : this.missingKbRelatedCvesRepository, TvmEndPoint.Analytics, this.missingKb.vendorBulletin + " (" + this.missingKb.productFixId + ") - " + this.i18nService.get('tvm.common.relatedCVE.title'), undefined);
    };
    MissingKbEntityDetailsComponent.prototype.getLegalNoticeStyles = function (messageBarProps) {
        if (this.legalNoticeMessageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.legalNoticeMessageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
        return this._messageBarStyles;
    };
    MissingKbEntityDetailsComponent.prototype.getStyles = function (messageBarProps) {
        if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.messageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
        return this._messageBarStyles;
    };
    MissingKbEntityDetailsComponent.prototype.shouldShowEolMessage = function () {
        return (this.missingKb.eolVersionState === EolState.UpcomingEOL ||
            this.missingKb.eolVersionState === EolState.AlreadyEOL);
    };
    MissingKbEntityDetailsComponent.prototype.getEolText = function () {
        if (this.missingKb.eolVersionState === EolState.AlreadyEOL) {
            if (this.missingKb.eolVersionSinceDate) {
                return this.i18nService.get('tvm.softwarePage.missingKb.eolTextWithDate', {
                    date: this.tzDateService.format(this.missingKb.eolVersionSinceDate, 'shortDate'),
                });
            }
            else {
                return this.i18nService.get('tvm.softwarePage.missingKb.eolTextWithoutDate');
            }
        }
        if (this.missingKb.eolVersionState === EolState.UpcomingEOL) {
            return this.i18nService.get('tvm.softwarePage.missingKb.upcomingEolText', {
                date: this.tzDateService.format(this.missingKb.eolVersionSinceDate, 'shortDate'),
            });
        }
    };
    MissingKbEntityDetailsComponent.prototype.openSoftwareVersions = function () {
        var alreadyEOL = this.missingKb.eolVersionState === EolState.AlreadyEOL ? 'AlreadyEOL' : '';
        var upcomingEOL = this.missingKb.eolVersionState === EolState.UpcomingEOL ? 'UpcomingEOL' : '';
        this.router.navigate(["/software-inventory/" + encodeURIComponent(this.missingKb.productId) + "/versions"], {
            queryParams: {
                filters: "versionDistributionTags=" + [alreadyEOL, upcomingEOL]
                    .filter(function (s) { return s.length > 0; })
                    .join('|'),
            },
        });
        this.changeDetectorRef.detectChanges();
    };
    MissingKbEntityDetailsComponent.prototype.getSoftwareVersionsLinkText = function () {
        return this.tvmTextsService.getText(TextToken.SoftwareVersionsLinkText, this.missingKb.productsNames[0]);
    };
    return MissingKbEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { MissingKbEntityDetailsComponent };
