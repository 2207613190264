export function percToDeg(perc: number): number {
	return perc * 360;
}

export function percToRad(perc: number): number {
	return degToRad(percToDeg(perc));
}

export function degToRad(deg: number): number {
	return (deg * Math.PI) / 180;
}
