var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
var RecommendationExceptionAggregatedEntityPanelComponent = /** @class */ (function (_super) {
    __extends(RecommendationExceptionAggregatedEntityPanelComponent, _super);
    function RecommendationExceptionAggregatedEntityPanelComponent(changeDetectorRef) {
        return _super.call(this, changeDetectorRef) || this;
    }
    Object.defineProperty(RecommendationExceptionAggregatedEntityPanelComponent.prototype, "recommendationExceptionAggregated", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecommendationExceptionAggregatedEntityPanelComponent.prototype, "recommendationException", {
        get: function () {
            var recommendationExceptionAggregated = __assign({}, this.recommendationExceptionAggregated);
            return {
                id: recommendationExceptionAggregated.id,
                title: recommendationExceptionAggregated.title,
                exceptionJustification: recommendationExceptionAggregated.distinctJustifications[0],
                justificationContext: recommendationExceptionAggregated.justificationContext,
                expiringOn: recommendationExceptionAggregated.expiringOn,
                creationTime: recommendationExceptionAggregated.createdOn,
                requester: recommendationExceptionAggregated.requester,
                status: {
                    value: recommendationExceptionAggregated.primaryStatus,
                },
                exceptionArgs: recommendationExceptionAggregated.exceptionArgs,
            };
        },
        enumerable: true,
        configurable: true
    });
    RecommendationExceptionAggregatedEntityPanelComponent.prototype.initEntity = function (recommendationExceptionAggregated, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, recommendationExceptionAggregated, isExtendedData);
        this.isGlobalException = recommendationExceptionAggregated.isGlobalException;
        this.changeDetectorRef.markForCheck();
    };
    return RecommendationExceptionAggregatedEntityPanelComponent;
}(EntityPanelComponentBase));
export { RecommendationExceptionAggregatedEntityPanelComponent };
