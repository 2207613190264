import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { RemediationAction } from '../remediation/remediation-action.entity';

@ValueObject({
	singularName: 'Related Entity Type Entity',
	pluralName: 'Related Entity Type Entities',
	readonly: true,
})
export class AirsEntityRelationTypeEntity extends ModelBase {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: string;

	@EntityField() name: string;

	@EntityField({ data: 'remediation_actions', arrayOf: RemediationAction })
	remediationActions: Array<RemediationAction>;

	@EntityField({ data: 'entity_status' })
	status: AirsEntityStatus;
}
