/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/charts/src/lib/pie/pie-chart.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/charts/src/lib/pie/pie-chart.component";
import * as i3 from "../../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../../../projects/charts/src/lib/legend/legend.component.ngfactory";
import * as i6 from "../../../../../../../../../projects/charts/src/lib/legend/legend.component";
import * as i7 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i8 from "../../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "../../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i12 from "../../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i13 from "../../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i16 from "./active-alerts.widget";
import * as i17 from "../../../../../shared-reports/services/reports.service";
import * as i18 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i19 from "@microsoft/paris/dist/lib/paris";
import * as i20 from "../../../../../global_entities/services/entity-panels.service";
import * as i21 from "../../../../../insights/services/app-insights.service";
import * as i22 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i23 from "../../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i24 from "../../../../../global_entities/services/global-entity-types.service";
import * as i25 from "../../../../../@entities/alerts/services/alerts.service";
import * as i26 from "../../../../../@entities/alerts/services/alert.entity-type.service";
var styles_ActiveAlertsWidget = ["@media (min-width: 1024px) and (max-width: 1350px), (max-width: 700px) {\n\t\t\t\tchart-legend[_ngcontent-%COMP%] {\n\t\t\t\t\tdisplay: none;\n\t\t\t\t}\n\t\t\t}"];
var RenderType_ActiveAlertsWidget = i0.ɵcrt({ encapsulation: 0, styles: styles_ActiveAlertsWidget, data: {} });
export { RenderType_ActiveAlertsWidget as RenderType_ActiveAlertsWidget };
function View_ActiveAlertsWidget_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "wcd-pie-chart", [], [[8, "hidden", 0]], [[null, "titleClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("titleClick" === en)) {
        var pd_0 = (_co.onNewAlertsTitleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PieChartComponent_0, i1.RenderType_PieChartComponent)), i0.ɵdid(1, 770048, null, 0, i2.PieChartComponent, [i0.ElementRef, i3.PrettyNumberService], { settings: [0, "settings"], data: [1, "data"], upperTitle: [2, "upperTitle"], allowTitleClick: [3, "allowTitleClick"], ariaLabel: [4, "ariaLabel"] }, { titleClick: "titleClick" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(4, 1), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.newAlertsPieSettings; var currVal_2 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform(_co.newAlertsData$)); var currVal_3 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform(_co.newAlertsTitle$)); var currVal_4 = true; var currVal_5 = i0.ɵunv(_v, 1, 4, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "alerts_active")); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 5).transform(_co.error$)); _ck(_v, 0, 0, currVal_0); }); }
function View_ActiveAlertsWidget_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "wcd-pie-chart", [], [[8, "hidden", 0]], [[null, "titleClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("titleClick" === en)) {
        var pd_0 = (_co.onInProgressAlertsTitleClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_PieChartComponent_0, i1.RenderType_PieChartComponent)), i0.ɵdid(1, 770048, null, 0, i2.PieChartComponent, [i0.ElementRef, i3.PrettyNumberService], { settings: [0, "settings"], data: [1, "data"], upperTitle: [2, "upperTitle"], allowTitleClick: [3, "allowTitleClick"], ariaLabel: [4, "ariaLabel"] }, { titleClick: "titleClick" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(4, 1), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.inProgressAlertsPieSettings; var currVal_2 = i0.ɵunv(_v, 1, 1, i0.ɵnov(_v, 2).transform(_co.inProgressAlertsData$)); var currVal_3 = i0.ɵunv(_v, 1, 2, i0.ɵnov(_v, 3).transform(_co.inProgressAlertsTitle$)); var currVal_4 = true; var currVal_5 = i0.ɵunv(_v, 1, 4, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "alerts_active")); _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 5).transform(_co.error$)); _ck(_v, 0, 0, currVal_0); }); }
function View_ActiveAlertsWidget_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 22, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 13, "div", [["class", "wcd-flex-spaceBetween-horizontal wcd-flex-wrap-horizontal wcd-padding-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActiveAlertsWidget_2)), i0.ɵdid(5, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActiveAlertsWidget_3)), i0.ɵdid(9, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "wcd-chart-legend", [], [[2, "wcd-full-height", null], [2, "wcd-flex-horizontal", null], [2, "wcd-flex-wrap", null], [2, "wcd-flex-vertical", null], [2, "small", null]], null, null, i5.View_LegendComponent_0, i5.RenderType_LegendComponent)), i0.ɵdid(13, 49152, null, 0, i6.LegendComponent, [i3.PrettyNumberService], { items: [0, "items"], showValues: [1, "showValues"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(17, 0, null, null, 4, "wcd-datatable", [], [[8, "hidden", 0]], [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.showAlertSidePanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i7.View_DataTableComponent_0, i7.RenderType_DataTableComponent)), i0.ɵdid(18, 13352960, null, 0, i8.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i9.LiveAnnouncer, i10.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], showHeaders: [3, "showHeaders"], label: [4, "label"] }, { itemClick: "itemClick" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵppd(20, 1), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.newAlertsData$)); _ck(_v, 5, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.inProgressAlertsData$)); _ck(_v, 9, 0, currVal_1); var currVal_7 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform(_co.legendData$)); var currVal_8 = true; _ck(_v, 13, 0, currVal_7, currVal_8); var currVal_10 = i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform(_co.latestAlertData$)); var currVal_11 = false; var currVal_12 = _co.tableColumns; var currVal_13 = false; var currVal_14 = i0.ɵunv(_v, 18, 4, _ck(_v, 20, 0, i0.ɵnov(_v.parent, 0), "machines.entityDetails.fields.activeAlerts.title")); _ck(_v, 18, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵnov(_v, 13).hostFullHeight; var currVal_3 = i0.ɵnov(_v, 13).hostFlexHorizontal; var currVal_4 = i0.ɵnov(_v, 13).hostFlexWrap; var currVal_5 = i0.ɵnov(_v, 13).hostFlexVertical; var currVal_6 = i0.ɵnov(_v, 13).hostSmall; _ck(_v, 12, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 21).transform(_co.error$)); _ck(_v, 17, 0, currVal_9); }); }
function View_ActiveAlertsWidget_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 19, "div", [["class", "widget-nodata wcd-full-height wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 16, "div", [["class", "widget-nodata-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 5, "div", [["class", "widget-nodata-message-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "wcd-shared-icon", [], null, null, null, i11.View_IconComponent_0, i11.RenderType_IconComponent)), i0.ɵdid(8, 573440, null, 0, i12.IconComponent, [i13.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 6, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\tWondering where to start?\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 3, "a", [], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 15).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(15, 671744, null, 0, i14.RouterLinkWithHref, [i14.Router, i14.ActivatedRoute, i4.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵppd(17, 1), (_l()(), i0.ɵted(-1, null, [".\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = "hide"; _ck(_v, 8, 0, currVal_0); var currVal_3 = "/preferences2/onboarding"; _ck(_v, 15, 0, currVal_3); }, function (_ck, _v) { var currVal_1 = i0.ɵnov(_v, 15).target; var currVal_2 = i0.ɵnov(_v, 15).href; _ck(_v, 14, 0, currVal_1, currVal_2); var currVal_4 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v.parent, 0), "onboarding.runDetectionTest.title")); _ck(_v, 16, 0, currVal_4); }); }
export function View_ActiveAlertsWidget_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i15.I18nPipe, [i10.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ActiveAlertsWidget_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["noAlerts", 2]], null, 0, null, View_ActiveAlertsWidget_4)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var tmp_0_0 = null; var currVal_0 = (((tmp_0_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.latestAlertData$))) == null) ? null : tmp_0_0.length); var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ActiveAlertsWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "active-alerts-widget", [], null, null, null, View_ActiveAlertsWidget_0, RenderType_ActiveAlertsWidget)), i0.ɵdid(1, 245760, null, 0, i16.ActiveAlertsWidget, [i17.ReportsService, i18.FeaturesService, i14.Router, i19.Paris, i20.EntityPanelsService, i21.AppInsightsService, i22.AppConfigService, i23.ServiceUrlsService, i3.PrettyNumberService, i24.GlobalEntityTypesService, i25.AlertsService, i26.AlertEntityTypeService, i10.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ActiveAlertsWidgetNgFactory = i0.ɵccf("active-alerts-widget", i16.ActiveAlertsWidget, View_ActiveAlertsWidget_Host_0, {}, {}, []);
export { ActiveAlertsWidgetNgFactory as ActiveAlertsWidgetNgFactory };
