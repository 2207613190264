import {
	Component,
	EventEmitter,
	forwardRef,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import {
	CustomTiIndicatorsType,
	ImportTiIndicatorResult,
	ImportTiIndicators,
	ImportTiIndicatorsApiCall,
} from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';

const MAX_CSV_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;

@Component({
	selector: 'custom-ti-indicators-import',
	templateUrl: './custom-ti-indicator-import.component.html',
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => CustomTiIndicatorImportComponent),
		},
	],
})
export class CustomTiIndicatorImportComponent implements OnInit, OnDestroy {
	@Input() customTiIndicatorType: CustomTiIndicatorsType;
	@Output() done: EventEmitter<void> = new EventEmitter<void>();
	@Output() import: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('importCsvFileInput', { static: false }) importCsvFileInput: ElementRef;

	public isSaving: boolean = false;
	public totalCustomTiParsed: number;
	public successfullyImported: number;
	public showInvalidCsvFileSizeMessage: boolean = false;

	public importFailures: Array<ImportTiIndicatorResult>;

	private _importTiIndicators: ImportTiIndicators;
	private _importSubscription: Subscription;

	private dialogsService: DialogsService;
	private customTiIndicatorsService: CustomTiIndicatorsService;

	constructor(private paris: Paris, private injector: Injector, private i18nService: I18nService) {}

	ngOnInit(): void {
		this.dialogsService = this.injector.get(DialogsService);
		this.customTiIndicatorsService = this.injector.get(CustomTiIndicatorsService);
	}

	ngOnDestroy(): void {
		this._importSubscription && this._importSubscription.unsubscribe();
	}

	onImport() {
		this.isSaving = true;
		this._importSubscription = this.paris
			.apiCall<Array<ImportTiIndicatorResult>, ImportTiIndicators>(
				ImportTiIndicatorsApiCall,
				this._importTiIndicators
			)
			.subscribe(
				(results: Array<ImportTiIndicatorResult>) => {
					this.isSaving = false;
					this.totalCustomTiParsed = 0;
					this.successfullyImported = 0;

					if (results) {
						this.totalCustomTiParsed = results.length;
						this.importFailures = results.filter(
							(importReslt: ImportTiIndicatorResult) => importReslt.isFailed
						);

						this.successfullyImported = this.totalCustomTiParsed - this.importFailures.length;

						this.import.emit(this.successfullyImported > 0);
					}
				},
				error => {
					this.isSaving = false;
					this.dialogsService.showError({
						title: this.i18nService.get('customTiIndicator.dataview.actions.import.failed'),
						data: error,
					});
				}
			);
	}

	downloadCsvSample() {
		this.customTiIndicatorsService.downloadCsv({
			type: this.customTiIndicatorType.id,
			downloadImportCsvSample: true,
		});
	}

	onFileSelect(event) {
		const files = event.srcElement.files;
		const file = files && files[0];

		if (!!file) {
			if (file.size > MAX_CSV_FILE_SIZE_IN_BYTES) {
				this.showInvalidCsvFileSizeMessage = true;
				this.importCsvFileInput.nativeElement.value = null;
			} else {
				this.showInvalidCsvFileSizeMessage = false;
				this._importTiIndicators = {
					file: file,
					type: this.customTiIndicatorType.id,
				};
			}
		}
	}

	get isValid(): boolean {
		return !!(this._importTiIndicators && this._importTiIndicators.file);
	}
}
