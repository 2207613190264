import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('userSearchResults', userSearchResultsDirective);

userSearchResultsDirective.$inject = [];

function userSearchResultsDirective() {
	return {
		restrict: 'EA',
		scope: {
			results: '=',
			matchTitle: '=',
			loading: '=',
		},
		template: `
		<div ng-class="{'loading-opacity': userSearchResults.loading }">
        <div ng-if="userSearchResults.results">
            <div class="row hover-default file-search-matches-title">
                <div class="col-md-height col-middle overflow-all">
                    <b>{{::userSearchResults.matchTitle}}</b>
                </div>
            </div>
            <div ng-if="userSearchResults.results.length == 0" class="event-timeline-event row hover-default">
                <div class="search-results-desc">
                    No results
                </div>
            </div>
        </div>

        <div class="event-timeline-event row hover-default side-pane-container"
        	data-track-component="UsersSearchResults"
        	ng-repeat="user in userSearchResults.results  | orderBy:'LastSeen':true">
            <div class="row-same-height event-timeline-event-main search-results-lighter nopadding">
                <div class="col-md-4 col-md-height col-middle overflow-all search-results-desc">
                    <i class="icon machine-timeline-icon icon-Contact"></i>
                    <a data-track-id="GoToUser"
						data-track-type="Navigation"
						href="/users/nosid?accountDomainName={{user.AccountDomainName}}&accountName={{user.AccountName}}">
                        <span>{{::user.AccountDomainName}}\\{{::user.AccountName}}</span>
                    </a>
                </div>

                <div class="col-md-4 col-md-height col-middle overflow-all search-results-desc">
                    <span>{{::user.LastSeen | sevilleDate : 'date'}} | {{::user.LastSeen | sevilleDate : 'time'}}</span>
                </div>
                <div class="col-md-4 col-md-height col-middle overflow-all search-results-desc">
                    {{::user.LoggedOnMachines}}
                </div>
            </div>
        </div>
    </div>
		`,
		controllerAs: 'userSearchResults',
		bindToController: true,
		controller: [function() {}],
	};
}
