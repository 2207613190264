import { Pipe, PipeTransform } from '@angular/core';
import { TzDateService } from '@wcd/localization';
declare const moment: typeof import('moment');

@Pipe({ name: 'calculateRemainingDays' })
export class CalculateRemainingDaysPipe implements PipeTransform {
	constructor(private tzDateService: TzDateService) {}

	transform(dueDate: Date) {
		/*
		The calculation of remaining days is affected by the timezone the user chose.
		For example, let's say dueDate is set by the user, when he uses "Timezone Local", to 01/02/2020 in 00:00 Israeli Time.
		In UTC, due date is 01/01/2020 in 22:00.
		Therefore, when user uses "Timezone Local" (Israeli time), he will see: Due date = 01/02/20. So we have to tell him that Remaining Days is 1.
		But, if user moves to "Timezone UTC", he will see: Due date = 01/01/20. In this case, we have to tell him that Remaining Days is 0 (and not 1).
		Therefore, the dates here are generated in the same way they generated for the user.
		TzDateService takes into account the Timezone (UTC/local) the user works with.
		Sending "en-US" as locale to "format" enables us to generate the correct date for moment, and to prevevnt a confusion between the month and the day.
		*/
		const now = moment(new Date(this.tzDateService.format(new Date(), 'shortDate', undefined, "en-US")));
		const end = moment(new Date(this.tzDateService.format(dueDate, 'shortDate', undefined, "en-US")));
		return end.diff(now, 'days');
	}
}
