
		<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingOrError">
			<wcd-datatable
				*ngIf="tableItems.length; else noData"
				[items]="tableItems"
				[columns]="tableFields"
				[selectEnabled]="false"
				[fixedTable]="true"
				label="{{ 'alerts.panel.linkedByIncidentDetails.header' | i18n }}"
			></wcd-datatable>
		</ng-container>

		<ng-template #loadingOrError>
			<ng-container *ngIf="loading; else noData">
				<i class="loader-icon"></i>
				{{ 'common.loading' | i18n }}
			</ng-container>
		</ng-template>
		<ng-template #noData>
			{{ 'common.noDataAvailable' | i18n }}
		</ng-template>
	