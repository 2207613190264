<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-full-height wcd-flex-vertical" data-track-component="TopActiveRemediationTasksWidget">
	<div class="wcd-flex-vertical wcd-flex-1 ie11Patch ie11Flex">

		<h2 class="wcd-margin-small-top wcd-margin-bottom wcd-font-weight-bold">{{'tvm.activeRemediation.title' | i18n}}</h2>

		<p class="wcd-margin-bottom">
			{{'tvm.activeRemediation.description' | i18n}}
		</p>

		<wcd-stretched-donut-bars
			[data] = "data$ | async"
			(onClick)="openRemediationTask($event.id)"
			barCssClass = 'wcd-margin-small-bottom'
			class="wcd-margin-bottom">
		</wcd-stretched-donut-bars>

		<div class="wcd-font-size-xs">
			<span>
				<fab-icon iconName="SquareShapeSolid" contentClass="ms-color-blue"></fab-icon>
				{{'tvm.activeRemediation.completed' | i18n}}
			</span>
		</div>
	</div>
	<a
		[attr.aria-label]="'tvm.dashboard.showMore.activeRemediation' | i18n"
		tabindex="0"
		class="wcd-margin-top"
		data-track-id="OpenRemediationTasksScreen"
		data-track-type="Navigation"
		(click)="openRemediationTasksScreen()"
		>
		{{'tvm.activeRemediation.showMore' | i18n}}
	</a>
</div>

