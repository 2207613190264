import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { sccHostService } from '@wcd/scc-interface';

@ApiCall({
	name: 'Manual action on email',
	endpoint: (config, query) => {
		return `Save/AirAdminAction`;
	},
	method: 'POST',
	baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
	customHeaders: { 'content-type': 'multipart/form-data' },
	parseQuery: (manualAdminActionRequest: ManualAdminActionRequest) => {
		return {
			params: {
				tenantId: manualAdminActionRequest.TenantId,
			},
			data: { Payload: manualAdminActionRequest, TenantId: manualAdminActionRequest.TenantId },
		};
	},
})
export class ManualActionOnEmailApiCall extends ApiCallModel<void, ManualAdminActionRequest> {}

export interface ManualActionEmailData {
	NetworkMessageId: string;
	Recipient: string;
}

export interface ManualAdminActionRequest {
	EmailList?: Array<ManualActionEmailData>; // relevant to manual action on mail message
	QueryString?: string; // relevant to manual action on mail cluster
	AdminActionType: AdminActionType;
	SubActionType: SubActionTypeValue;
	StartTime: Date;
	EndTime: Date;
	TenantId: string;
	Name: string;
	Description?: string;
	ApproverUpn: string;
	IsImmediateAction: boolean;
	BulkId: string;
	AdminActionSource: AdminActionSource;
}

export enum AdminActionType {
	None = 0,
	MailAction = 1,
	BlockUrlAction = 2,
	BlockSenderAction = 3,
}

export enum MdoManualAction {
	MoveToJunk = 'MoveToJunk',
	MoveToInbox = 'MoveToInbox',
	Delete = 'HardDeleteEmail',
	SoftDelete = 'SoftDeleteEmail',
	MoveToDeletedItems = 'MoveToDeletedItems',
}

export enum SubActionTypeValue {
	MoveToJunk = 1,
	MoveToInbox = 2,
	Delete = 5,
	SoftDelete = 6,
	MoveToDeletedItems = 7,
}

//TODO: LIRAN - need to add 'MTP_ActionCenter' value to this enum when we have it in Substrate repo (client task: https://microsoft.visualstudio.com/OS/_workitems/edit/32054585)
export enum AdminActionSource {
	None = 0,
	MTP_CustomDetection = 1,
	MTP_BulkAction = 2,
	OATP_Explorer = 3,
}
