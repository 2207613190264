var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef } from '@angular/core';
import { ServiceUrlsService } from '@wcd/app-config';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Machine } from '@wcd/domain';
import { InvestigationActionsService } from '../services/investigation-actions.service';
import { DownloadService } from '../../../shared/services/download.service';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { CommentModel } from '../../../comments/models/comment.model';
var InvestigationActionEntityPanelComponent = /** @class */ (function (_super) {
    __extends(InvestigationActionEntityPanelComponent, _super);
    function InvestigationActionEntityPanelComponent(investigationActionsService, downloadService, errorsDialogService, serviceUrlsService, globalEntityTypesService, i18nService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.investigationActionsService = investigationActionsService;
        _this.downloadService = downloadService;
        _this.errorsDialogService = errorsDialogService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        _this.Machine = Machine;
        return _this;
    }
    Object.defineProperty(InvestigationActionEntityPanelComponent.prototype, "investigationAction", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    InvestigationActionEntityPanelComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, entity, isExtendedData);
        this.actionDetails =
            (this.investigationAction.i18nNameKey &&
                this.i18nService.get("investigationAction_field_details_" + this.investigationAction.i18nNameKey + "_description", null, true)) ||
                this.investigationAction.details;
        this.machine = null;
        if (this.investigationAction.machine) {
            var machineId = this.investigationAction.machine.machineId;
            this.machine = new Machine({
                id: machineId,
                senseMachineId: machineId,
                name: this.investigationAction.machine.name,
            });
        }
    };
    InvestigationActionEntityPanelComponent.prototype.openInput = function () {
        this.openActionIO(ActionIoType.Input);
    };
    InvestigationActionEntityPanelComponent.prototype.openOutput = function () {
        this.openActionIO(ActionIoType.Output);
    };
    InvestigationActionEntityPanelComponent.prototype.openActionIO = function (type) {
        var _this = this;
        var url = this.serviceUrlsService.automatedIr + "/investigationaction/" + this.investigationAction.id + "/" + type + ".json";
        this.downloadService.dumpJsonFromUrlToNewTab(url).subscribe(function () { }, function (err) {
            return _this.errorsDialogService.showError({
                title: _this.i18nService.get("investigation_error_get_action_" + type),
                message: err.message || err,
                data: err,
            });
        });
    };
    InvestigationActionEntityPanelComponent.prototype.getComments = function () {
        return this.investigationAction.commentData
            ? [
                new CommentModel({
                    id: undefined,
                    message: this.investigationAction.commentData.approverComment,
                    timestamp: this.investigationAction.commentData.actionApprovedTime,
                    user: this.investigationAction.commentData.approvedBy,
                }),
            ]
            : null;
    };
    return InvestigationActionEntityPanelComponent;
}(EntityPanelComponentBase));
export { InvestigationActionEntityPanelComponent };
var ActionIoType;
(function (ActionIoType) {
    ActionIoType["Input"] = "input";
    ActionIoType["Output"] = "output";
})(ActionIoType || (ActionIoType = {}));
