import { Injectable } from '@angular/core';
import { BaselineComplianceProfileStatusColorService } from './baseline-compliance-profile-status-color.service';
import {
	ColorMap,
	KindTextFormatter,
} from '../../shared/components/events-summary-bar/events-summary-bar.component';
import { LegendItem, ValueFormatter } from '@wcd/charts';
import { Strings } from '@wcd/i18n';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { BaselineComplianceProfileStatusPercentage } from '../components/tvm-profile-compliance-status-bars/baseline-compliance-profile-status-percentage.enum';

const BaselineComplianceProfileStatusPercentageDisplayKeysMap: Record<
	BaselineComplianceProfileStatusPercentage,
	keyof Strings
> = {
	[BaselineComplianceProfileStatusPercentage.MoreThan75PercentConfiguredDevices]:
		'tvm_baseline_profile_compliance_status_widget_75',
	[BaselineComplianceProfileStatusPercentage.MoreThan50LessThan75PercentConfiguredDevices]:
		'tvm_baseline_profile_compliance_status_widget_75_50',
	[BaselineComplianceProfileStatusPercentage.MoreThan25LessThan50PercentConfiguredDevices]:
		'tvm_baseline_profile_compliance_status_widget_50_25',
	[BaselineComplianceProfileStatusPercentage.LessThan25PercentConfiguredDevices]:
		'tvm_baseline_profile_compliance_status_widget_25',
	[BaselineComplianceProfileStatusPercentage.NoDataDevices]:
		'tvm_baseline_profile_compliance_status_widget_na',
};

@Injectable()
export class TvmProfilesComplianceStatusBarsService {
	complianceStatusesColorMap: ColorMap<BaselineComplianceProfileStatusPercentage> = {
		type: 'class',
		map: this.baselineComplianceProfileStatusColorService.backgroundColorsClassMap,
	};

	statusPercentageKeys: BaselineComplianceProfileStatusPercentage[] = Object.values(
		BaselineComplianceProfileStatusPercentage
	);

	complianceStatusesTextFormatter: KindTextFormatter<BaselineComplianceProfileStatusPercentage> = status =>
		this.displayTextPipe.transform(status, BaselineComplianceProfileStatusPercentageDisplayKeysMap);

	readonly legendTextFormatter: ValueFormatter = count => `(${count})`;

	legendItems: LegendItem[] = this.statusPercentageKeys.map(key => ({
		name: this.complianceStatusesTextFormatter(key),
		iconClassName: this.baselineComplianceProfileStatusColorService.fontColorsClassMap.get(key),
	}));

	constructor(
		private baselineComplianceProfileStatusColorService: BaselineComplianceProfileStatusColorService,
		private displayTextPipe: DisplayTextPipe
	) {}
}
