import { Injectable, NgZone } from '@angular/core';
import { Store } from '../../data/models/store.base';
import { CommentConfig, CommentModel } from '../models/comment.model';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { CommentsBackendService } from './comments.backend.service';
import { AuthService } from '@wcd/auth';
import { Observable } from 'rxjs';
import { DataSet } from '@microsoft/paris';
import { DatasetBackendOptions } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class CommentsStore extends Store<CommentModel, number> {
	constructor(
		backendService: CommentsBackendService,
		ngZone: NgZone,
		dialogsService: DialogsService,
		private authService: AuthService,
		i18nService: I18nService
	) {
		super(backendService, ngZone, CommentModel, dialogsService, i18nService,{
			itemNameSingular: 'Comment',
			itemNamePlural: 'Comments',
			iconName: 'comment',
		});
	}

	parseData(itemData: CommentConfig) {
		if (!itemData) itemData.user = this.authService.currentUser.name;

		return itemData;
	}

	getItemsDataSet(options?: DatasetBackendOptions): Observable<DataSet<CommentModel>> {
		if (!options) options = {};
		if (!options.ordering) options.ordering = '-timestamp';

		return super.getItemsDataSet(options);
	}

	createComment(message: string, linkedObjects: { [index: string]: string | number }): CommentModel {
		const comment: CommentModel = new CommentModel();
		comment.message = message;
		comment.linkedObjects = linkedObjects;
		return comment;
	}
}
