import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { AssetRecommendation } from '@wcd/domain';
import { SecurityRecommendationEntityPanelComponent } from '../components/security-recommendation.entity-panel.component';
import { SecurityRecommendationEntityDetailsComponent } from '../../../../global_entities/components/entity-details/security-recommendation.entity-details.component';
import { ItemActionModelConfig, ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { Injectable } from '@angular/core';
import { SingleAssetSecurityRecommendationFieldsService } from './single-asset-security-recommendation.fields.service';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { SidePanelService } from '../../common/side-panel.service';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';

export interface SecurityRecommendationsDataViewFixedOptions {}

export const FILTER_ONLY_SECURITY_RECOMMENDATION_FIELDS = [];

@Injectable()
export class SingleAssetSecurityRecommendationEntityTypeService
	implements EntityTypeService<AssetRecommendation> {
	constructor(
		private reportInaccuracyService: TvmReportInaccuracyService,
		private tvmTextsService: TvmTextsService,
		private sidePanelService: SidePanelService,
		private tvmTagsService: TvmTagsService
	) {}

	readonly entityType: EntityType<AssetRecommendation> = {
		id: 'AssetRecommendation',
		entity: AssetRecommendation,
		entityDetailsComponentType: SecurityRecommendationEntityDetailsComponent,
		singleEntityPanelComponentType: SecurityRecommendationEntityPanelComponent,
		getEntityName: (securityRecommendation: AssetRecommendation) =>
			this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, securityRecommendation),
		getEntityDataviewLink: () => '/security-recommendations',
		getActions: (securityRecommendations: Array<AssetRecommendation>) => {
			if (securityRecommendations.length !== 1) return;

			const actions: Array<ItemActionModelConfig> = [];
			actions.push({
				id: 'showFullRecommendation',
				nameKey: 'tvm.securityRecommendation.openFullRecommendation',
				icon: FabricIconNames.PageCheckedin,
				method: (recommendations: Array<AssetRecommendation>) => {
					const recommendationId = recommendations[0].id;
					const url = `/security-recommendations/${encodeURIComponent(recommendationId)}`;
					return this.sidePanelService.navigateToUrlInContext(url, {
						search: recommendationId,
					});
				},
			});
			if (
				securityRecommendations[0].productId &&
				securityRecommendations[0].productName &&
				securityRecommendations[0].productName !== ''
			) {
				actions.push({
					id: 'navigateToSoftware',
					nameKey: 'tvm.securityRecommendation.openSoftwarePage',
					icon: FabricIconNames.AppIconDefault,
					method: (recommendations: Array<AssetRecommendation>) => {
						const url = `/software-inventory/${encodeURIComponent(recommendations[0].productId)}`;
						return this.sidePanelService.navigateToUrlInContext(url);
					},
				});
			}
			actions.push({
				id: 'openReportInaccuracy',
				nameKey: 'tvm.reportInaccuracy.reportButton',
				icon: FabricIconNames.Feedback,
				method: (recommendations: Array<AssetRecommendation>) =>
					this.reportInaccuracyService.openReportInaccuracyPanel(
						this.reportInaccuracyService.evaluateContext(true, recommendations[0]),
						recommendations[0]
					),
			});
			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
		getTags: (recommendations: Array<AssetRecommendation>) =>
			this.tvmTagsService.getRecommendationTags(recommendations[0]),
		loadFullEntityInPanel: false,
		dataViewOptions: <
			EntityDataViewOptions<AssetRecommendation, SecurityRecommendationsDataViewFixedOptions>
		>{
			fields: SingleAssetSecurityRecommendationFieldsService,
		},
	};
}
