var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FileEntityTypeService } from './file.entity-type.service';
import { FileInstance } from '@wcd/domain';
var FileInstanceEntityTypeService = /** @class */ (function () {
    function FileInstanceEntityTypeService(filesService) {
        this.filesService = filesService;
        this.entityType = __assign({}, this.filesService.entityType, { id: 'fileInstance', entity: FileInstance, searchOptions: null });
    }
    return FileInstanceEntityTypeService;
}());
export { FileInstanceEntityTypeService };
