export class TimeRanges {
	ranges: Array<TimeRange>;

	constructor(ranges?: Array<TimeRange>) {
		this.ranges = ranges || [];
	}

	/**
	 * Returns the total time of all ranges, in milliseconds.
	 * Takes into account parallel ranges
	 */
	getTotalTime(): number {
		const reducedTimeRanges: TimeRanges = this.flatten();

		return reducedTimeRanges.ranges.reduce((totalTime: number, range: TimeRange) => {
			return totalTime + range.duration;
		}, 0);
	}

	flatten(): TimeRanges {
		const sortedRanges = this.ranges.slice().sort((a: TimeRange, b: TimeRange) => {
			const aVal = a.start.valueOf(),
				bVal = b.start.valueOf();

			if (aVal === bVal) {
				return 0;
			}

			return aVal > bVal ? 1 : -1;
		});

		const now: Date = new Date();
		return new TimeRanges(
			sortedRanges.reduce((reducedTimeRanges: Array<TimeRange>, range: TimeRange) => {
				const previousRange =
						reducedTimeRanges.length && reducedTimeRanges[reducedTimeRanges.length - 1],
					previousRangeEnd: Date = previousRange && previousRange.end;

				range.end = range.end || now;
				if (range.end >= (previousRangeEnd || 0)) {
					if (range.start < previousRangeEnd) {
						range.start = previousRangeEnd;
					}
					reducedTimeRanges.push(range);
				}

				return reducedTimeRanges;
			}, [])
		);
	}
}

export class TimeRange {
	constructor(public start: Date, public end: Date) {}

	get duration(): number {
		return (this.end || new Date()).valueOf() - this.start.valueOf();
	}
}
