import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { UserEntityPanelComponent } from './components/user-entity-panel.component';
import { UserEntityTypeService } from './services/user.entity-type.service';
import { LegacyUsersFieldsService } from './services/legacy-users.fields.service';
import { UsersService } from './services/users.service';
import { UserObservedMachineEntityTypeService } from './services/user-observed-machine.entity-type.service';
import { UserObservedMachinePanelComponent } from './components/user-observed-machine-panel.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [
		UsersService,
		UserEntityTypeService,
		LegacyUsersFieldsService,
		UserObservedMachineEntityTypeService,
	],
	declarations: [UserEntityPanelComponent, UserObservedMachinePanelComponent],
	entryComponents: [UserEntityPanelComponent, UserObservedMachinePanelComponent],
})
export class UserEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(UserEntityTypeService);
		globalEntityTypesService.registerEntityType(UserObservedMachineEntityTypeService);
	}
}
