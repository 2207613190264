import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { CustomTiIndicator } from './custom-ti-indicator.entity';

@ValueObject({
	singularName: 'Import TI Indicator result',
	pluralName: 'Import TI Indicator results',
	readonly: true,
})
export class ImportTiIndicatorResult extends ModelBase {
	@EntityField({ data: ['IsFailed'] })
	isFailed: boolean;

	@EntityField({ data: ['Indicator'] })
	indicator: CustomTiIndicator;

	@EntityField({ data: ['FailureReason'] })
	failureReason?: string;
}
