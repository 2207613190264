import { Pipe, PipeTransform } from '@angular/core';
import { MdatpMitreService } from '@wcd/scc-common';

@Pipe({ name: 'filterMitreTechniques' })
export class FilterInvalidMitreTechniques implements PipeTransform {
	transform(techniques: Array<string>) {
		return MdatpMitreService.filterInvalidMitreTechniques(techniques);
	}
}

@Pipe({ name: 'mitreTechniqueDisplay' })
export class MitreTechniqueDisplayPipe implements PipeTransform {
	transform(mitreTechniqueId: string, addIdPrefix?: boolean) {
		return MdatpMitreService.getMitreTechniqueName(mitreTechniqueId, addIdPrefix);
	}
}

@Pipe({ name: 'mitreTechniqueLink' })
export class MitreTechniqueLinkPipe implements PipeTransform {
	transform(mitreTechniqueId: string) {
		return MdatpMitreService.getMitreTechniqueLink(mitreTechniqueId);
	}
}
