var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { UserRoleAssignment } from './user-role-assignment.value-object';
import { MdeUserRoleAction } from './user-role-action.entity';
import { userRoleActionValues, deprecatedUserRoleActionValues } from './user-role-action.values';
import { UserRoleVisibility } from './user-role-visibility.entity';
import { userRoleVisibilityValues } from './user-role-visibility.values';
var ɵ0 = function (config) { return config.data.serviceUrls.rbacManagementApi; }, ɵ1 = function (allowedActionsBitWise) {
    if (!allowedActionsBitWise)
        return [1];
    if (allowedActionsBitWise === -1)
        return [-1];
    return MdeUserRoleAction.flatten(userRoleActionValues).reduce(function (actions, userRoleAction) {
        return userRoleAction.id > 0 && allowedActionsBitWise & userRoleAction.id
            ? actions.concat([userRoleAction]) : actions;
    }, []);
}, ɵ2 = function (userRoleActions) {
    return userRoleActions.reduce(function (bitwiseValue, action) {
        return bitwiseValue | action.id | action.additionalImplicitAllowedActions;
    }, 0);
}, ɵ3 = function (allowedActionsBitWise) {
    if (!allowedActionsBitWise)
        return [1];
    if (allowedActionsBitWise === -1)
        return [-1];
    return deprecatedUserRoleActionValues.reduce(function (actions, userRoleAction) {
        return userRoleAction.id > 0 && allowedActionsBitWise & userRoleAction.id
            ? actions.concat([userRoleAction]) : actions;
    }, []);
}, ɵ4 = function (userRoleActions) {
    return userRoleActions.reduce(function (bitwiseValue, action) {
        return bitwiseValue | action.id | action.additionalImplicitAllowedActions;
    }, 0);
}, ɵ5 = function (visibilityBitWise) {
    if (visibilityBitWise === -1)
        return [];
    return userRoleVisibilityValues.reduce(function (visibilities, userRoleVisibility) {
        return visibilityBitWise & userRoleVisibility.id
            ? visibilities.concat([userRoleVisibility.id]) : visibilities;
    }, []);
}, ɵ6 = function (userRoleVisibilities) {
    return userRoleVisibilities.reduce(function (bitwiseValue, userRoleVisibility) { return bitwiseValue | userRoleVisibility.id; }, 0);
};
var UserRole = /** @class */ (function (_super) {
    __extends(UserRole, _super);
    function UserRole() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UserRole.prototype.assignGroup = function (aadGroup) {
        var containsGroup = this.assignments.some(function (assignment) { return assignment.aadGroup.id === aadGroup.id; });
        if (!containsGroup) {
            this.assignments = [new UserRoleAssignment({ aadGroup: aadGroup })].concat(this.assignments);
            return true;
        }
        return false;
    };
    UserRole.prototype.setGroups = function (aadGroups) {
        this.assignments = aadGroups
            ? aadGroups.map(function (aadGroup) { return new UserRoleAssignment({ aadGroup: aadGroup }); })
            : [];
    };
    __decorate([
        EntityField({ data: 'UserRoleId' }),
        __metadata("design:type", Number)
    ], UserRole.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], UserRole.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], UserRole.prototype, "description", void 0);
    __decorate([
        EntityField({
            data: 'AllowedActions',
            arrayOf: MdeUserRoleAction,
            parse: ɵ1,
            serialize: ɵ2,
        }),
        __metadata("design:type", Array)
    ], UserRole.prototype, "allowedActions", void 0);
    __decorate([
        EntityField({
            data: 'AllowedActions',
            arrayOf: MdeUserRoleAction,
            parse: ɵ3,
            serialize: ɵ4,
        }),
        __metadata("design:type", Array)
    ], UserRole.prototype, "oldAllowedActions", void 0);
    __decorate([
        EntityField({
            data: 'PiiVisibility',
            arrayOf: UserRoleVisibility,
            parse: ɵ5,
            serialize: ɵ6,
        }),
        __metadata("design:type", Array)
    ], UserRole.prototype, "piiVisibility", void 0);
    __decorate([
        EntityField({ data: 'LastUpdated' }),
        __metadata("design:type", Date)
    ], UserRole.prototype, "lastUpdatedDate", void 0);
    __decorate([
        EntityField({ data: 'LastUpdated' }),
        __metadata("design:type", String)
    ], UserRole.prototype, "lastUpdated", void 0);
    __decorate([
        EntityField({ data: 'UserRoleAssignments', arrayOf: UserRoleAssignment }),
        __metadata("design:type", Array)
    ], UserRole.prototype, "assignments", void 0);
    __decorate([
        EntityField({ data: 'IsGlobalAdmin', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], UserRole.prototype, "isGlobalAdmin", void 0);
    UserRole = __decorate([
        Entity({
            singularName: 'Role',
            pluralName: 'Roles',
            endpoint: 'rbac/user_roles',
            baseUrl: ɵ0,
        })
    ], UserRole);
    return UserRole;
}(EntityModelBase));
export { UserRole };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
