var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, OnDestroy } from '@angular/core';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { ReportModel } from '../../../reports/models/report.model';
import { TitleService } from '../../../shared/services/title.service';
import { FeaturesService, Feature } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { routeToLegacyAlertRoute } from '../services/alerts.service';
import { AlertContextWidgetComponent } from './widgets/alert-context-widget.component';
import { AlertStatusWidgetComponent } from './widgets/alert-status-widget.component';
var AlertEntityComponent = /** @class */ (function (_super) {
    __extends(AlertEntityComponent, _super);
    function AlertEntityComponent(i18nService, titleService, featuresService, router, route) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.titleService = titleService;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.route = route;
        _this.featuresChangeSubscription = _this.featuresService.featureChanged$.subscribe(function (_a) {
            var featureId = _a.featureId, isEnabled = _a.value;
            if (featureId === Feature.UpgradeAlertPage && !isEnabled) {
                var urlTree = routeToLegacyAlertRoute(_this.route.snapshot);
                _this.router.navigate(urlTree, { replaceUrl: true });
            }
        });
        _this.report = new ReportModel({
            id: 'alert-entity-widgets',
            allowRangeSelect: false,
            allowExport: false,
            enablePadding: false,
            overflow: 'none',
            refreshOnQueryParamsChange: false,
            rows: [
                {
                    columns: [
                        {
                            widgets: [AlertContextWidgetComponent],
                        },
                        {
                            widgets: [AlertStatusWidgetComponent],
                        },
                    ],
                },
            ],
            transparent: true,
        });
        return _this;
    }
    Object.defineProperty(AlertEntityComponent.prototype, "alert", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AlertEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.titleService.setState({
            pageTitle: this.i18nService.get('alert.page.title', { name: this.alert.name }),
        });
        this.tabs = [
            {
                id: 'tree',
            },
            {
                id: 'graph',
            },
            {
                id: 'timeline',
            },
            {
                id: 'description',
            },
        ].map(function (tab) {
            return new TabModel(__assign({ name: _this.i18nService.get("alert.tabs." + tab.id + ".title"), routerLink: "./" + tab.id }, tab));
        });
    };
    AlertEntityComponent.prototype.ngOnDestroy = function () {
        if (this.featuresChangeSubscription) {
            this.featuresChangeSubscription.unsubscribe();
        }
    };
    return AlertEntityComponent;
}(EntityComponentBase));
export { AlertEntityComponent };
