import { Injectable } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { VulnerabilityNotificationRule, VulnerabilityChangeEventType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class TvmNotificationsTextsService {
	constructor(private i18nService: I18nService, featuresService: FeaturesService) {
	}

	public getRuleScope(rule: VulnerabilityNotificationRule): string {
		const deviceGroupsPostfix =
			rule.rbacGroupIds.length === 1
				? this.i18nService.strings.notifications_scope_device_group
				: this.i18nService.strings.notifications_scope_device_groups;

		return rule.isGlobalNotification
			? this.i18nService.strings.notifications_scope_global
			: `${rule.rbacGroupIds.length} ${deviceGroupsPostfix}`;
	}

	public getChangeEventTypesLabels(
		changeEventTypes: VulnerabilityChangeEventType[]
	): Map<VulnerabilityChangeEventType, string> {
		const result = new Map<VulnerabilityChangeEventType, string>();
		changeEventTypes.forEach(e => {
			result[e] = this.getChangeEventTypeLabel(e);
		});
		return result;
	}

	public getChangeEventTypeLabel = (changeEventType: VulnerabilityChangeEventType) => this.i18nService.get(
		'tvm_notifications_vulnerability_' + changeEventType)
}
