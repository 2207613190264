import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FabricIconName } from '@wcd/scc-common';
import { CommentModel } from '../models/comment.model';

@Component({
	selector: 'comments-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./comments-list.component.scss'],
	template: `
		<ul
			*ngIf="comments?.length; else noComments"
			class="unstyled comments-list"
			data-track-component="CommentsList"
		>
			<li
				class="comment"
				*ngFor="let comment of comments; trackBy: getCommentId"
				[ngClass]="{ 'comment-new': comment.isNew }"
			>
				<header class="comment-header">
					<!-- If there's an action - show that (and the user will be shown below), otherwise show the user -->
					<div class="wcd-font-weight-semibold">
						{{ comment.action || comment.user }}
					</div>

					<div class="wcd-font-size-s">
						<span *ngIf="comment.action">
							<span class="comments-header-subtitle-descriptor">{{ 'by' | i18n }}</span>
							<span>{{ comment.user }}</span>
							<span class="comments-header-subtitle-descriptor">{{ 'on' | i18n }}</span>
						</span>

						<span *ngIf="comment.timestamp">{{ comment.timestamp | date: 'medium' }}</span>
					</div>
				</header>
				<div [class.wcd-flex-horizontal]="comment.icon || defaultCommentIcon">
					<div
						*ngIf="comment.icon || defaultCommentIcon"
						class="wcd-flex-none wcd-padding-small-right comment-icon"
					>
						<wcd-shared-icon [iconName]="comment.icon || defaultCommentIcon" class="small-icon">
						</wcd-shared-icon>
					</div>
					<div [class.wcd-flex-1]="comment.icon || defaultCommentIcon" class="comment-body">
						{{ comment.message }}
					</div>
				</div>
				<div class="comment-metadata" *ngIf="showLink && showLink(comment)">
					<button
						class="btn btn-link btn-inline"
						data-track-id="GoToCommentLink"
						data-track-type="Navigation"
						(click)="onCommentLinkClick(comment)"
					>
						<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
						{{ getLinkTitle(comment) }}
					</button>
				</div>
			</li>
		</ul>
		<ng-template #noComments>
			<div>{{ 'comments.noData' | i18n }}</div>
		</ng-template>
	`,
})
export class CommentsListComponent {
	@Input() comments?: ReadonlyArray<CommentModel> = [];
	@Input() showLink: (comment: CommentModel) => boolean;
	@Input() getLinkTitle: (comment: CommentModel) => string;
	@Input() defaultCommentIcon: string | FabricIconName;

	@Output() readonly commentLinkClick: EventEmitter<CommentModel> = new EventEmitter<CommentModel>();

	getCommentId(index, comment: CommentModel): number {
		return comment.id;
	}

	onCommentLinkClick(comment: CommentModel) {
		this.commentLinkClick.emit(comment);
	}
}
