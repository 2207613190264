import { AssessmentJob } from '@wcd/domain';

export class AssessmentJobModel {
	assessmentJob: AssessmentJob;
	selectedIps: Array<string>;
	requiredNewScan: boolean;
	canShowScans: boolean;
	overrideAuth: boolean;
	isNewDraft: boolean;
	startScanTime: string;
	readOnly: boolean;

	constructor(assessmentJob: AssessmentJob, readOnly?: boolean) {
		this.assessmentJob = new AssessmentJob();
		this.assessmentJob = Object.assign({}, assessmentJob);

		// Doing split first for multiple replacing
		this.selectedIps = assessmentJob.target
			? assessmentJob.target
					.split(' ')
					.join('')
					.split(',')
			: [];

		this.isNewDraft = this.assessmentJob && !this.assessmentJob.id;

		this.readOnly = readOnly;
	}
}
