import { NgModule } from '@angular/core';
import { DatePickerComponent } from './components/datepicker.component';
import { FancySelectComponent } from './components/fancy-select.component';
import { MultiInputComponent } from './components/multi-input.component';
import { HourValidator } from './validators/hour.validator';
import { ListValidator } from './validators/list.validator';
import { LengthValidator } from './validators/length.validator';
import { RegExpValidator } from './validators/regexp.validator';
import { DateRangeComponent } from './components/daterange.component';
import { WcdIconsModule } from '@wcd/icons';
import { CommonModule } from '@angular/common';
import { DropdownComponent } from './components/dropdown.component';
import { WcdFormsModule } from '@wcd/forms';
import { FormsModule } from '@angular/forms';
import { TooltipsModule } from '@wcd/dialogs';
import { MentionableTextModule } from './components/mentionable-text/mentionable-text.module';
import { MinMaxNumberValidator } from './validators/min-max.number.validator';
import { MinValidator } from './validators/min.validator';
import { ClearOnEscapeDirective } from './directives/clear-on-escape.directive';
import { AutofocusDirective } from './directives/autofocus.directive';
import { RadioListComponent } from './components/radiolist.component';
import { GeneralPipesModule } from '../general_pipes/general_pipes.module';
import { I18nModule } from '@wcd/i18n';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { A11yModule } from '@angular/cdk/a11y';
import { MultiSelectComponent } from './components/multi-select.component';
import { RbacModule } from '../rbac/rbac.module';
import { ExtendedFabDatePickerComponent } from './components/fab-date-picker/extended-fab-date-picker.component';
import { FabDatePickerModule, FabButtonModule } from '@angular-react/fabric';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		WcdIconsModule,
		TooltipsModule,
		MentionableTextModule,
		GeneralPipesModule,
		I18nModule,
		AngularExtensionsModule,
		WcdFormsModule,
		A11yModule,
		RbacModule,
		FabDatePickerModule,
		FabButtonModule,
	],
	declarations: [
		DatePickerComponent,
		DateRangeComponent,
		FancySelectComponent,
		MultiInputComponent,
		HourValidator,
		LengthValidator,
		ListValidator,
		RegExpValidator,
		MinMaxNumberValidator,
		MinValidator,
		DropdownComponent,
		ClearOnEscapeDirective,
		AutofocusDirective,
		RadioListComponent,
		MultiSelectComponent,
		ExtendedFabDatePickerComponent
	],
	exports: [
		DatePickerComponent,
		DateRangeComponent,
		FancySelectComponent,
		MultiInputComponent,
		HourValidator,
		LengthValidator,
		ListValidator,
		RegExpValidator,
		MinMaxNumberValidator,
		MinValidator,
		DropdownComponent,
		MentionableTextModule,
		ClearOnEscapeDirective,
		AutofocusDirective,
		RadioListComponent,
		MultiSelectComponent,
		ExtendedFabDatePickerComponent
	],
})
export class HexaditeFormsModule {}
