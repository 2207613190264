/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./notification.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "./notification.component";
import * as i4 from "../services/notifications.service";
import * as i5 from "./floating-notifications.component";
var styles_FloatingNotificationsComponent = [];
var RenderType_FloatingNotificationsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FloatingNotificationsComponent, data: {} });
export { RenderType_FloatingNotificationsComponent as RenderType_FloatingNotificationsComponent };
function View_FloatingNotificationsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "notification", [], null, [[null, "minimize"], [null, "snooze"], [null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("minimize" === en)) {
        var pd_0 = (_co.notificationsService.updateHighPriorityNotifications() !== false);
        ad = (pd_0 && ad);
    } if (("snooze" === en)) {
        var pd_1 = (_co.notificationsService.snoozeNotifications() !== false);
        ad = (pd_1 && ad);
    } if (("select" === en)) {
        var pd_2 = (_co.notificationsService.selectNotification(_v.context.$implicit) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_NotificationComponent_0, i1.RenderType_NotificationComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "new-notification": 0 }), i0.ɵdid(4, 49152, null, 0, i3.NotificationComponent, [i4.NotificationsService], { notification: [0, "notification"], isMinimizable: [1, "isMinimizable"] }, { select: "select", minimize: "minimize", snooze: "snooze" })], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit.isNew); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit; var currVal_2 = true; _ck(_v, 4, 0, currVal_1, currVal_2); }, null); }
function View_FloatingNotificationsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [["class", "notifications wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "wcd-flex-1 notifications-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FloatingNotificationsComponent_2)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.notificationsService.highPriorityNotifications$)); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_FloatingNotificationsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 2, null, View_FloatingNotificationsComponent_1)), i0.ɵdid(1, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.notificationsService.snoozed$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_FloatingNotificationsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "floating-notifications", [], null, null, null, View_FloatingNotificationsComponent_0, RenderType_FloatingNotificationsComponent)), i0.ɵdid(1, 49152, null, 0, i5.FloatingNotificationsComponent, [i4.NotificationsService], null, null)], null, null); }
var FloatingNotificationsComponentNgFactory = i0.ɵccf("floating-notifications", i5.FloatingNotificationsComponent, View_FloatingNotificationsComponent_Host_0, {}, { close: "close" }, []);
export { FloatingNotificationsComponentNgFactory as FloatingNotificationsComponentNgFactory };
