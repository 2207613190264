import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.readonlymodal', onboardingReadOnlyModalController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.readonlymodal', {
			url: '/readonlymodal',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-readonly-modal">
                <div class="page-header-title">
                    Contact your administrator
                </div>
                <br />
                <div>
                    <table>
                        <tr>
                            <td class="icon icon-lg icon-Error onboarding-readonly-warning-padding"></td>
                            <td>
                                Service onboarding must be done before you can access the portal.
                                <br/>
                                Contact your administrator to complete the service onboarding so that you can access the portal.
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.readonlymodal',
					controllerAs: 'readonlymodal',
				},
			},
		});
	},
]);

onboardingReadOnlyModalController.$inject = ['$scope', '$state', '$uibModal', '$uibModalInstance'];

function onboardingReadOnlyModalController($scope, $state, $modal, $modalInstance) {
	$scope.close = function() {
		$modalInstance.dismiss();
		$state.go('permissions');
	};
}
