import { Injectable } from '@angular/core';
import { cloneDeep, find, isNil } from 'lodash-es';
import {
	LegacyUser,
	Machine,
	MachineUserDetailsAccount,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { I18nService } from '@wcd/i18n';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';

@Injectable()
export class MachineUserDetailsAccountFields {
	private readonly userFields: Array<DataviewFieldConfig<MachineUserDetailsAccount>>;

	constructor(globalEntityTypesService: GlobalEntityTypesService, private i18nService: I18nService) {
		const legacyUserEntityTypeService = globalEntityTypesService.getEntityType(LegacyUser);

		this.userFields = [
			{
				id: 'name',
				name: this.i18nService.get('machines.loggedOnUsers.fields.name.title'),
				component: {
					type: EntityNameComponent,
					getProps: (account: MachineUserDetailsAccount) => {
						return {
							entity: account.user,
							entityTypeId: 'user',
							entityName: account.name,
						};
					},
				},
				getTooltip: user => user.name,
				className: 'nowrap wcd-text-overflow-small',
			},
			{
				id: 'loginCount',
				name: this.i18nService.get('machines.loggedOnUsers.fields.loginCount.title'),
				description: this.i18nService.get('machines.loggedOnUsers.fields.loginCount.tooltip'),
				sort: {
					enabled: false,
					sortLocally: false,
				},
			},
			{
				id: 'firstSeen',
				name: this.i18nService.get('machines.loggedOnUsers.fields.firstSeen.title'),
				component: {
					type: TzDateComponent,
					getProps: (account: MachineUserDetailsAccount) => ({ date: account.firstSeen }),
				},
				sort: {
					enabled: false,
					sortLocally: false,
				},
			},
			{
				id: 'lastSeen',
				name: this.i18nService.get('machines.loggedOnUsers.fields.lastSeen.title'),
				component: {
					type: TzDateComponent,
					getProps: (account: MachineUserDetailsAccount) => ({ date: account.lastSeen }),
				},
				sort: {
					enabled: false,
					sortLocally: false,
				},
			},
			{
				id: 'userType',
				name: this.i18nService.get('machines.loggedOnUsers.fields.userType.title'),
				getDisplay: (account: MachineUserDetailsAccount) => {
					if (isNil(account.isLocalAdmin)) {
						return '';
					}
					return account.isLocalAdmin ? 'Local admin' : 'Standard user';
				},
			},
			{
				id: 'loginType',
				name: this.i18nService.get('machines.loggedOnUsers.fields.loginType.title'),
				getDisplay: (account: MachineUserDetailsAccount) => account.logonTypes.join(', '),
			},
		];
	}

	// return the dataview fields by the machine os type.
	// windows 7 machines should not allow links to user pages.
	getFieldsByMachine(machine: Machine): Array<DataviewField> {
		if (
			machine.os &&
			machine.os.platform &&
			machine.os.platform.category === OperatingSystemPlatformCategories.Windows7
		) {
			const win7FieldsObjects = cloneDeep(this.userFields);
			const nameObj = find(win7FieldsObjects, fieldObj => {
				return fieldObj.id === 'name' && fieldObj.getLink !== null;
			});
			nameObj.getLink = null;
			return DataviewField.fromList(win7FieldsObjects);
		}

		return DataviewField.fromList(this.userFields);
	}
}
