/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dataviews/components/dataview.component.ngfactory";
import * as i2 from "../../../dataviews/components/dataview.component";
import * as i3 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i4 from "../../../dialogs/services/dialogs.service";
import * as i5 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i6 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../dataviews/services/dataview-repository.service";
import * as i9 from "../../../insights/services/app-insights.service";
import * as i10 from "../../../dialogs/services/errors-dialog.service";
import * as i11 from "../../services/entity-panels.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i13 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "../../../dialogs/confirm/confirm.service";
import * as i17 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i18 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i19 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i20 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i21 from "@angular/common";
import * as i22 from "./related-exceptions.panel.component";
import * as i23 from "../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exceptions.fields.service";
import * as i24 from "../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service";
var styles_RelatedExceptionsPanelComponent = [];
var RenderType_RelatedExceptionsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RelatedExceptionsPanelComponent, data: {} });
export { RenderType_RelatedExceptionsPanelComponent as RenderType_RelatedExceptionsPanelComponent };
function View_RelatedExceptionsPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "dataview", [["style", "min-height: 1px;"]], null, [[null, "onItemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onItemClick" === en)) {
        var pd_0 = (_co.openRelatedExceptionPanel($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataViewComponent_0, i1.RenderType_DataViewComponent)), i0.ɵdid(1, 245760, null, 0, i2.DataViewComponent, [i3.WicdSanitizerService, i4.DialogsService, i5.PanelService, i6.PreferencesService, i7.ActivatedRoute, i8.DataviewRepositoryService, i7.Router, i0.ChangeDetectorRef, i9.AppInsightsService, i10.ErrorsDialogService, i11.EntityPanelsService, i12.FeaturesService, i13.AppContextService, i14.Paris, i15.LiveAnnouncer, i16.ConfirmationService, i17.I18nService], { allowFilters: [0, "allowFilters"], allowPaging: [1, "allowPaging"], disableSelection: [2, "disableSelection"], hideControls: [3, "hideControls"], navigateOnChange: [4, "navigateOnChange"], padLeft: [5, "padLeft"], searchEnabled: [6, "searchEnabled"], allowColumnCustomization: [7, "allowColumnCustomization"], label: [8, "label"], fixedTable: [9, "fixedTable"], ignoreQueryParams: [10, "ignoreQueryParams"], dataViewConfig: [11, "dataViewConfig"], fields: [12, "fields"], repository: [13, "repository"], sortDisabledFields: [14, "sortDisabledFields"] }, { onItemClick: "onItemClick" }), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, null, ["\n        "]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = true; var currVal_3 = true; var currVal_4 = false; var currVal_5 = true; var currVal_6 = false; var currVal_7 = false; var currVal_8 = i0.ɵunv(_v, 1, 8, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "tvm.common.machineGroups")); var currVal_9 = true; var currVal_10 = true; var currVal_11 = _co.dataViewConfig; var currVal_12 = _co.fields; var currVal_13 = _co.repository; var currVal_14 = _co.sortDisabledFieldsIds; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
export function View_RelatedExceptionsPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i18.I18nPipe, [i17.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 13, "wcd-panel", [["class", "wcd-full-height wcd-flex-vertical"], ["data-track-component", "related-exceptions-panel"], ["data-track-component-type", "Side Panel"]], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i19.View_PanelComponent_0, i19.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i20.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i5.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n    "])), (_l()(), i0.ɵeld(5, 0, null, 1, 8, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "h2", [["class", "wcd-padding-large-horizontal wcd-padding-large-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n        "])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RelatedExceptionsPanelComponent_1)), i0.ɵdid(12, 16384, null, 0, i21.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(-1, 1, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.fields; _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), "tvm.common.machineGroups")); _ck(_v, 8, 0, currVal_1); }); }
export function View_RelatedExceptionsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "related-exceptions-panel", [], null, null, null, View_RelatedExceptionsPanelComponent_0, RenderType_RelatedExceptionsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i22.RelatedExceptionsPanelComponent, [i7.Router, i23.RecommendationExceptionsFieldsService, i14.Paris, i0.ChangeDetectorRef, i24.RecommendationExceptionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RelatedExceptionsPanelComponentNgFactory = i0.ɵccf("related-exceptions-panel", i22.RelatedExceptionsPanelComponent, View_RelatedExceptionsPanelComponent_Host_0, { settings: "settings", sourceItem: "sourceItem" }, {}, []);
export { RelatedExceptionsPanelComponentNgFactory as RelatedExceptionsPanelComponentNgFactory };
