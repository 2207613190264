import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { KustoChartType } from '@wcd/domain';

@ValueObject({
	singularName: 'Hunting query chart visualization',
	pluralName: 'Hunting query chart visualization',
	readonly: true,
})
export class HuntingChartVisualization extends ModelBase {
	@EntityField({ data: 'ChartType' })
	readonly chartType: KustoChartType;
}
