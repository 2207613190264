import { ValueObject, EntityField, EntityModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: 'Hunting query result record',
	pluralName: 'items',
})
export class HuntingQueryResultRecord extends EntityModelBase {
	@EntityField({ data: 'DataItem' })
	dataItem: { [key: string]: any };
}
