<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="(data$ | async) as data" class="wcd-full-height wcd-flex-vertical">
	<wcd-datatable
		[items]="data"
		[columns]="fields"
		[selectEnabled]="false"
		[fixedTable]="false"
		[fluidHeaderWidth]="true"
		(itemClick)="openSoftwareVulnerabilities($event.item)">
	</wcd-datatable>
	<a
		[attr.aria-label]="'tvm.dashboard.showMore.vulnerableSoftware' | i18n"
		tabindex="0"
		data-track-id="OpenSoftwareInventory"
		data-track-type="Navigation"
		(click)="openSoftwareInventory()">{{('common.showMore'| i18n)}}</a>
</div>
