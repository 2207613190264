import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { ServiceTemplate } from './service-template.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Service',
	pluralName: 'Services',
	endpoint: 'services',
	loadAll: true,
	allItemsProperty: 'results',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class Service extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() product: string;

	@EntityField({ data: 'template_id' })
	template: ServiceTemplate;

	get vendorLogo(): string {
		return this.template.logoUrl;
	}

	get iconName(): string {
		return `services.${this.name.toLowerCase().replace(/\s/g, '_')}`;
	}
}
