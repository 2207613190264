/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { ChecklistValue } from '@wcd/forms';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { TvmMachineGroupsFilterService } from '../../services/tvm-machine-groups-filter.service';
import { MachineGroup } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { remove } from 'lodash-es';

const numOfAllowedGroupsInDropdown = 20;
const numOfGroupsToShowInDropdown = 3;

@Component({
	selector: 'machine-groups-dropdown',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine-groups-dropdown.component.html',
})
export class TvmMachineGroupsDropdownComponent implements OnInit {
	availableMachineGroups: Array<ChecklistValue> = [];
	currentMachineGroups: Array<ChecklistValue> = [];
	dropdownText: string;
	hoverMachineGroups: string;
	maxNumberOfGroups = numOfAllowedGroupsInDropdown;
	machineGroupSecondaryText: string = '';
	machineGroupDataviewDescriptionText: string;
	containsFilteredGroups: boolean;
	manuallySelectedMachineGroups: number[] = [];
	numberOfDeviceGroupsText: string;
	machineGroupInfoText: string;

	@Input() isDisabled: boolean = false;
	@Input() preselectedMachineGroups: number[] = [];
	@Input() disabledMachineGroups: number[] = [];
	@Input() dataviewTitle: string;
	@Input() ariaLabel: string;
	@Output() groupChosen = new EventEmitter<any>();

	ngOnInit() {
		this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1)).subscribe(gr => {
			// PM has decided that we should only show user the groups if they have selected in the top right tvm machine group global filter.
			// That means in the dropdown list, we should only show those groups as selectable. If they have non selected, show all.
			const allSelectedGroups = gr.machineGroups.filter(mg => mg.isSelected);
			if (allSelectedGroups.length > 0) {
				// Since they already have some pre-selected, show those as the options in the dropdown, and pre-select all those options for them
				this.availableMachineGroups = this.currentMachineGroups = allSelectedGroups.map(
					machineGroup => {
						return { id: machineGroup.groupId, name: machineGroup.groupName };
					}
				);

				this.containsFilteredGroups = true;
			} else {
				// This means the user has no selected groups. Give them all the options, and select all the groups for them
				this.availableMachineGroups = this.currentMachineGroups = gr.machineGroups.map(
					machineGroup => {
						return { id: machineGroup.groupId, name: machineGroup.groupName };
					}
				);
			}

			this.applyMachineGroupsFilters();

			// The below is for when there are more than 20 machine groups (see ngIf above), and if they already have preselected filters from TVM
			this.hoverMachineGroups =
				this.currentMachineGroups.length != 0
					? this.currentMachineGroups.map(mg => mg.name).join(', ')
					: '';
			this.onMachineGroupsChange(this.currentMachineGroups);

			// On first load, make the dropdown text always show the 'show all' text
			// Using the above logic in the comments above, we always want the dropdown text to say "All filtered groups" (as per the mocks)
			this.dropdownText = this.allGroupsSelectedDropdownText();

			// this for greater than 20 machine groups
			this.numberOfDeviceGroupsText = this.i18nService.get(
				'tvm.common.machineGroupFilter.allGroupsSelected'
			);

			// We want to show them a message explaining how many we are filtering out
			if (this.availableMachineGroups.length < gr.machineGroups.length) {
				this.machineGroupSecondaryText = this.i18nService.get(
					'tvm.securityRecommendation.remediationTaskCreation.machineGroupSelection.secondaryLabelText',
					{
						availableMachineGroups: this.availableMachineGroups.length,
						totalMachineGroups: gr.machineGroups.length,
					}
				);

				if(this.containsFilteredGroups && this.disabledMachineGroups.length > 0) {
					this.machineGroupInfoText = this.i18nService.get(
						'tvm.securityRecommendation.remediationTaskCreation.machineGroupSelection.filteredAndExpectedInfoText'
					);
				}
				else if(this.containsFilteredGroups) {
					this.machineGroupInfoText = this.i18nService.get(
						'tvm.securityRecommendation.remediationTaskCreation.machineGroupSelection.filteredInfoText'
					);
				}
				else {
					this.machineGroupInfoText = this.i18nService.get(
						'tvm.securityRecommendation.remediationTaskCreation.machineGroupSelection.exceptedInfoText'
					);
				}
			}
		});
	}

	constructor(
		private sidePanelService: SidePanelService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private readonly i18nService: I18nService
	) {}

	onMachineGroupsChange(event: Array<ChecklistValue>) {
		this.dropdownText = this.shortenRbacDisplayLogic(event);

		// Convert to MachineGroup instead of ChecklistValue array
		const machineGroupsToSend: Array<MachineGroup> = event.map((value: ChecklistValue) => {
			// To keep things consistent whether they use the dropdown experience or datatable in 2ndary panel, convert to MachineGroup
			return value as MachineGroup;
		});

		this.groupChosen.emit(machineGroupsToSend);
	}

	allGroupsSelectedDropdownText() {
		// Building 'All groups selected (4)' or 'All filtered device groups (4)' string. Also include count at the end
		const allText = this.i18nService.get('tvm.common.machineGroupFilter.dropdown.allGroupsSelected');
		const groupsSelectedText = this.containsFilteredGroups
			? this.i18nService.get('tvm.common.machineGroupFilter.dropdown.allGroupsSelectedAndFiltered')
			: '';
		const numberOfMachinesSelected = this.availableMachineGroups.length;
		return `${allText}${groupsSelectedText} (${numberOfMachinesSelected})`;
	}

	shortenRbacDisplayLogic(groups: Array<ChecklistValue>) {
		if (groups.length == this.availableMachineGroups.length) {
			return this.allGroupsSelectedDropdownText();
		} else {
			return this.i18nService.get(`tvm_common_machineGroupFilter_dropdown_someGroupsSelected${groups.length == 1 ? "_plural" : ""}`, {
				count: groups.length,
			});
		}
	}

	openSidePanel() {
			this.sidePanelService.showRbacGroupsDataviewPanel(
			this.preselectedMachineGroups,
			this.manuallySelectedMachineGroups,
			this.disabledMachineGroups,
			this.dataviewTitle,
			this.containsFilteredGroups,
			(machineGroups: Array<MachineGroup>) => {
				this.manuallySelectedMachineGroups = machineGroups.map(group => group.id);
				// The below is for when there are more than 20 machine groups (see ngIf above). This just gives them some hover text to see the groups they selected when returning out of the panel
				this.hoverMachineGroups =
					machineGroups.length != 0 ? machineGroups.map(mg => mg.name).join(', ') : '';

				// We also want to change the text from 'All filtered groups' to 'X selected device groups'
				if (this.availableMachineGroups.length != machineGroups.length) {
					this.numberOfDeviceGroupsText = this.i18nService.get(
						'tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually.header',
						{
							number: machineGroups.length,
						}
					);
				} else {
					this.numberOfDeviceGroupsText = this.i18nService.get(
						'tvm.common.machineGroupFilter.allGroupsSelected'
					);
				}

				this.groupChosen.emit(machineGroups);
			}
		);
	}

	private applyMachineGroupsFilters() {
		this.applyMachineGroupFilters(this.preselectedMachineGroups, true);

		// TODO[omcheri]: for now, this will not show the disabled machine groups
		// figure out a way to show them as disabled instead (Dataview is the issue)
		this.applyMachineGroupFilters(this.disabledMachineGroups, false);
	}

	private applyMachineGroupFilters(array: number[], keepIn: boolean) {
		if (!array || array.length == 0) {
			return;
		}

		const set = new Set(array);
		remove(this.availableMachineGroups, rbacGroup => {
			const result = set.has(Number(rbacGroup.id));
			return keepIn ? !result : result;
		});
	}
}
