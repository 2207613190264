var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { UserStatusTypes } from '../../AAD/user/aad.user-status';
export var stringToNumberParse = function (value) {
    return typeof value === 'string' ? parseInt(value, 10) : value;
};
var ɵ0 = ['aad_user_id', 'aad_id'], ɵ1 = ['display_name', 'name'], ɵ2 = function (priority) { return stringToNumberParse(priority); }, ɵ3 = function (percentile) { return stringToNumberParse(percentile); };
var AirsAccount = /** @class */ (function (_super) {
    __extends(AirsAccount, _super);
    function AirsAccount(data) {
        var _this = _super.call(this, data) || this;
        _this.entityName = _this.userDisplayName || _this.entityName;
        _this.id = _this.sid || _this.aadUserId;
        return _this;
    }
    __decorate([
        EntityField({ data: 'sid' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "sid", void 0);
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "aadUserId", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "userDisplayName", void 0);
    __decorate([
        EntityField({ data: 'upn' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "upn", void 0);
    __decorate([
        EntityField({ data: 'email' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "email", void 0);
    __decorate([
        EntityField({ data: 'dns_domain' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "dnsDomain", void 0);
    __decorate([
        EntityField({ data: 'upn_suffix' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "upnSuffix", void 0);
    __decorate([
        EntityField({ data: 'username' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "username", void 0);
    __decorate([
        EntityField({ data: 'nt_domain' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "ntDomain", void 0);
    __decorate([
        EntityField({ data: 'user_status' }),
        __metadata("design:type", String)
    ], AirsAccount.prototype, "userStatus", void 0);
    __decorate([
        EntityField({
            data: 'investigation_priority',
            parse: ɵ2,
        }),
        __metadata("design:type", Number)
    ], AirsAccount.prototype, "investigationPriority", void 0);
    __decorate([
        EntityField({
            data: 'mcas_percentile',
            parse: ɵ3,
        }),
        __metadata("design:type", Number)
    ], AirsAccount.prototype, "percentile", void 0);
    AirsAccount = __decorate([
        Entity(__assign({}, airsEntityConfig, { singularName: 'Account', pluralName: 'Accounts', modelWith: null, forwardRefName: AirsEntityRefNames.Account })),
        __metadata("design:paramtypes", [Object])
    ], AirsAccount);
    return AirsAccount;
}(AirsEntity));
export { AirsAccount };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
