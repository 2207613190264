import { Component, Input, OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Alert, AlertToHuntingRuleRelationship, HuntingRule, MtpWorkload } from '@wcd/domain';
import { take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { addTenantIdUrlParam } from '../../../@entities/scheduled_hunting/services/scheduled-hunting-utils';

@Component({
    selector: 'alert-custom-detection-rule',
    template: `
    <ng-container *ngIf="!loadRuleErrorMessageKey; else errorMessage">
        <div *ngIf="!isLoading; else loader">
            <dl [class.key-values]="asKeyValueList">
                <dt>{{'scheduledHunting_fields_RuleName_title' | i18n}}</dt>
                <dd><a href="{{huntingRulePageLink}}">{{huntingRule.name}}</a></dd>
                
                <dt>{{'scheduledHunting_fields_isEnabled_title' | i18n}}</dt>
                <dd>{{'scheduledHunting_fields_isEnabled_Rule' + (huntingRule?.isEnabled ? 'On' : 'Off') | i18n}}</dd>

                <ng-container *ngIf="machineGroupsDisplay">
                    <dt>{{'hunting.scheduledMonitorSidePane.fields.machineGroups.sectionTitle' | i18n}}</dt>
                    <dd>{{machineGroupsDisplay}}</dd>
                </ng-container>

                <dt>{{ "scheduledHunting.fields.lastRunTime.title" | i18n}}</dt>
                <dd>{{huntingRule.lastRunTime | date:'medium'}}</dd>
                
                <ng-container *ngIf="huntingRule.lastRunStatus as lastRunStatus">
                    <dt>{{ "scheduledHunting.fields.lastRunStatus.title" | i18n}}</dt>
                    <dd>
                        <fab-icon *ngIf="lastRunStatus.iconName"
                            [iconName]="lastRunStatus.iconName"
                            [contentClass]="'field-value-icon-base' + (lastRunStatus.className ? ' color-text-' + lastRunStatus.className	: '')">
                        </fab-icon>
                        <img *ngIf="lastRunStatus.image"
                            [attr.src]="lastRunStatus.image | basePath"
                            [ngClass]="'field-value-icon-base'"
                            alt="" />
                        <span [ngClass]="(lastRunStatus.isError && lastRunStatus.className ? 'color-text-' + lastRunStatus.className : '')">
                            {{lastRunStatusText}}
                        </span>
                    </dd>
                </ng-container>
                
                <dt>{{ "scheduledHunting.fields.nextRunTime.title" | i18n}}</dt>
                <dd>{{huntingRule.nextRunTime | date:'medium'}}</dd>

            </dl>
        </div>
    </ng-container>

    <ng-template #loader>
        <span>
            <i class="loader-icon"></i>
        </span>
    </ng-template>
    
    <ng-template #errorMessage>
        <span>{{loadRuleErrorMessageKey | i18n}}</span>
    </ng-template>
    `
})
export class AlertCustomDetectionRuleDetailsComponent implements OnInit{
    @Input() alert: Alert;

    isLoading = true;
    huntingRule: HuntingRule;
    huntingRulePageLink: string;
    error: boolean = false;
    loadRuleErrorMessageKey: string;
    machineGroupsDisplay: string;
    lastRunStatusText: string;    

    constructor(
        private readonly paris: Paris,
        private readonly i18nService: I18nService){ }

    ngOnInit(){
        this.paris
			.getRelationshipRepository<Alert, HuntingRule>(AlertToHuntingRuleRelationship)
            .getRelatedItem(this.alert)
            .pipe(take(1))
            .subscribe(
                rule => {
                    this.huntingRule = rule;
                    this.huntingRulePageLink = addTenantIdUrlParam('/custom_detection/' + this.huntingRule.id);
                    this.machineGroupsDisplay = this.getMachineGroupsDisplay(rule);
                    this.lastRunStatusText = this.getLastRunStatusText(rule);
                    this.isLoading = false;
                },
                e => {
                    this.loadRuleErrorMessageKey = e.status === 404 ? "scheduledHunting_ErrorLoading_Deleted" : "scheduledHunting_ErrorLoading";
                    this.error = true;
                });
    }

    private getLastRunStatusText(rule: HuntingRule) {
        const status: string = this.i18nService.get(`statuses_${rule.lastRunStatus.name.toLocaleLowerCase()}`);
        return rule.lastRunStatus.isError && rule.lastRunFailureReason ?
            status + ': ' + rule.lastRunFailureReason : status;
    }

    private getMachineGroupsDisplay(rule: HuntingRule){
        return rule.mtpWorkloads && 
            rule.mtpWorkloads.includes(MtpWorkload.Mdatp) &&
            rule.machineGroups &&
            rule.machineGroups.length &&
            rule.machineGroups.map(g => g.name).join();
    }
}