import { EventEmitter } from '@angular/core';
import { KnownColorsService } from '@wcd/shared';
import { FabricIconNames } from '@wcd/scc-common';
var WIDTH_DEFAULT = 146;
var HEIGHT_DEFAULT = 12;
var BarComponent = /** @class */ (function () {
    function BarComponent(colorsService) {
        this.colorsService = colorsService;
        /**
         * Flag to show value as percentage out of total
         */
        this.showValueAsPercentage = false;
        /**
         * true to use noDataText as custom display instead of the numbers (X/Y)
         */
        this.useCustomDisplay = false;
        /**
         * The knwon color name of the color for the bar's fill (should be present in colors.scss)
         * @default 'themePrimary'
         */
        this.colorName = 'themePrimary';
        /**
         * The known color  name of the color for the bar's background (should be present in colors.scss)
         * @default 'neutralQuaternaryAlt'
         */
        this.backgroundColorName = 'neutralQuaternaryAlt';
        /**
         * For number - the width of the bar in pixels
         * For string - passed as is e.g. "100%" or "2rem"
         * @default '146'
         */
        this.width = WIDTH_DEFAULT;
        /**
         * The height of the bar
         * @default '12'
         */
        this.height = HEIGHT_DEFAULT;
        /**
         * Space between the bars
         */
        this.spaceBetweenBars = '0px';
        this.tabIndex = null;
        this.valueAriaLabel = null;
        this.totalAriaLabel = null;
        this.onClick = new EventEmitter(false);
    }
    BarComponent.prototype.getBackgroundColor = function (knownColorName) {
        return this.colorsService.backgroundColorsClassMap[knownColorName];
    };
    Object.defineProperty(BarComponent.prototype, "actualWidth", {
        get: function () {
            var width = this.width;
            return typeof width === 'number' ? width + "px" : this.width ? this.width : WIDTH_DEFAULT + "px";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BarComponent.prototype, "actualHeight", {
        get: function () {
            var height = this.height;
            return typeof height === 'number' ? height + "px" : height ? height : HEIGHT_DEFAULT + "px";
        },
        enumerable: true,
        configurable: true
    });
    BarComponent.prototype.valueTooltip = function (value, total, unit, valuePrefix) {
        if (value >= 0) {
            return ((valuePrefix ? valuePrefix + ": " : '') +
                (value.toLocaleString() + "/" + (total.toLocaleString() + (unit ? unit : ''))));
        }
    };
    BarComponent.prototype.totalTooltip = function (value, total, unit, restPrefix) {
        if (value >= 0) {
            return ((restPrefix ? restPrefix + ": " : '') +
                ((total - value).toLocaleString() + "/" + (total.toLocaleString() + (unit ? unit : ''))));
        }
    };
    BarComponent.prototype.calculatePercentage = function () {
        return this.total > 0 ? Math.round((this.value * 100) / this.total) : 0;
    };
    return BarComponent;
}());
export { BarComponent };
