import { OnInit } from '@angular/core';
import { getTheme } from '@uifabric/styling';
var CommandBarCustomIconComponent = /** @class */ (function () {
    function CommandBarCustomIconComponent() {
    }
    Object.defineProperty(CommandBarCustomIconComponent.prototype, "contextualMenuItem", {
        get: function () {
            return this._contextualMenuItem;
        },
        set: function (value) {
            this.disabled = !!value.disabled;
            this._contextualMenuItem = value;
            this.iconName =
                this._contextualMenuItem &&
                    this._contextualMenuItem.iconProps &&
                    this._contextualMenuItem.iconProps.iconName;
        },
        enumerable: true,
        configurable: true
    });
    CommandBarCustomIconComponent.prototype.ngOnInit = function () {
        var semanticColors = getTheme().semanticColors;
        this.iconColor = semanticColors.menuIcon;
    };
    return CommandBarCustomIconComponent;
}());
export { CommandBarCustomIconComponent };
