/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import {
	CyberEventEntityDisplay,
	CyberEventsActionTypesService,
} from '../services/cyber-events-action-types.service';
import {
	AccessTokenModificationDetails,
	CommandLine,
	CyberEvent,
	CyberEventEntityRelationTypeEnum,
	Process,
	File,
	FileInstance,
	LegacyUser,
	NetworkEndpoint,
	RegistryModificationDetails,
	ScheduledTask,
	Email,
} from '@wcd/domain';
import { isNil } from 'lodash-es';
import { DataEntityType, Paris } from '@microsoft/paris';
import { tap } from 'rxjs/operators';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import {Feature, FeaturesService} from "@wcd/config";

const DEPTH_LEVEL_LEFT_PADDING: number = 24;
const supportedEntityTypes = {
	accessToken: AccessTokenModificationDetails,
	commandLine: CommandLine,
	file: File,
	fileInstance: FileInstance,
	networkEndpoint: NetworkEndpoint,
	process: Process,
	registryModification: RegistryModificationDetails,
	scheduledTask: ScheduledTask,
	user: LegacyUser,
	email: Email,
};

@Component({
	selector: 'cyber-event-entities-graph',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cyber-event.entities-graph.component.html',
	styleUrls: ['./cyber-event.entities-graph.component.scss'],
})
export class CyberEventEntitiesGraphComponent implements OnChanges<CyberEventEntitiesGraphComponent> {
	@Input() event: CyberEvent;
	@Input() collapseEntities?: 'all' | 'parents';

	readonly CyberEventEntityRelationTypeEnum = CyberEventEntityRelationTypeEnum;
	readonly DEPTH_LEVEL_LEFT_PADDING = DEPTH_LEVEL_LEFT_PADDING;

	entities: Array<CyberEventEntityDisplay>;
	entitesGraphInitilized: boolean;

	minimizedSections: Set<string> = new Set<string>();
	supportedEntityTypes = supportedEntityTypes;

	dataChanged: boolean;

	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService,
		private readonly paris: Paris,
		private cd: ChangeDetectorRef,
		private readonly featuresService: FeaturesService,
	) {}

	ngOnChanges(changes: TypedChanges<CyberEventEntitiesGraphComponent>) {
		this.buildEntitesGraphOnChanges(changes);

		// OneCyber events are not enriched from BE so a manual enrichment for specific entities is required
		this.enrichOneCyberEntities().then(() => {
			this.buildEntitesGraphOnChanges(changes, true);
		});
	}

	getEventEntityRelationText(entity: CyberEventEntityDisplay): string {
		return this.cyberEventsActionTypesService.getEventEntityRelationDescription(this.event, entity);
	}

	toggleSections(entity: CyberEventEntityDisplay) {
		if (this.minimizedSections.has(this.getEntityUniqueId(entity)))
			this.minimizedSections.delete(this.getEntityUniqueId(entity));
		else this.minimizedSections.add(this.getEntityUniqueId(entity));
	}

	getEntityUniqueId(entity: CyberEventEntityDisplay): string {
		return `${this.event.id}_${entity.entityType && entity.entityType.singularName}_${entity.item && entity.item.id}`;
	}

	private buildEntitesGraphOnChanges(changes: TypedChanges<CyberEventEntitiesGraphComponent>, dataEnriched = false) {
		this.entitesGraphInitilized = false;
		this.entities = this.cyberEventsActionTypesService.getEventEntities(this.event);
		if (changes.event) {
			this.dataChanged = !changes.event.isFirstChange() || dataEnriched;
			this.collapseEntitiesNodes();
		}
		this.entitesGraphInitilized = true;
		this.cd.markForCheck();
	}

	private collapseEntitiesNodes() {
		if (this.cyberEventsActionTypesService.isOneCyberEvent(this.event)) {
			this.entities
				.filter((entity: CyberEventEntityDisplay) => entity.isCollapsed)
				.map(entity => this.minimizedSections.add(this.getEntityUniqueId(entity)));
		} else {
			this.entities
				.filter(
					(entity: CyberEventEntityDisplay) =>
						this.collapseEntities === 'all' ||
						(this.collapseEntities === 'parents' &&
							(entity.item === this.event.initiatingProcessParent ||
								entity.item === this.event.initiatingProcessParentParent))
				)
				.map(entity => this.minimizedSections.add(this.getEntityUniqueId(entity)));
		}
	}

	private enrichOneCyberEntities(): Promise<any> {
		if (this.cyberEventsActionTypesService.isOneCyberEvent(this.event)) {
			const getFilePromises = [];
			for (const entity of this.event.entities) {
				const entityType: DataEntityType = this.globalEntityTypesService.getEntityType(entity.entityType).entity;
				if (entityType === Process) {
					const sha1 = entity.item['ImageFile'] ? entity.item['ImageFile']['Sha1'] : null;
					if (!isNil(sha1) && isNil(entity.item['ImageFile']['CertificateInfo'])) {
						getFilePromises.push(
							this.paris.getItemById(File, sha1, undefined, { ['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW)}).pipe(
								tap(file => entity.item['ImageFile']['CertificateInfo'] = file.certificateInfo)
							).toPromise().catch(() => {})
						);
					}
				} else if (entityType === File || entityType === FileInstance) {
					if (!isNil(entity.item['Sha1']) && isNil(entity.item['CertificateInfo'])) {
						getFilePromises.push(
							this.paris.getItemById(File, entity.item['Sha1'], undefined, { ['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW)}).pipe(
								tap(file => entity.item['CertificateInfo'] = file.certificateInfo)
							).toPromise().catch(() => {})
						);
					}
				}
			}
			return getFilePromises.length > 0 ? Promise.all(getFilePromises) : Promise.reject();
		}
		return Promise.reject();
	}
}
