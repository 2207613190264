var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// tslint:disable:template-click-events-have-key-events
import { ChangeDetectorRef } from '@angular/core';
import { ActionHistory, Machine, RemediationActionType, RemediationActionTypeTypes, ActionFailureReason, } from '@wcd/domain';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Feature, FeaturesService } from '@wcd/config';
import { map } from 'rxjs/operators';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';
var MANAGE_ACTION_ACCOUNT_DOC_URL = 'https://docs.microsoft.com/en-us/defender-for-identity/manage-action-accounts';
var ActionHistoryEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ActionHistoryEntityPanelComponent, _super);
    function ActionHistoryEntityPanelComponent(changeDetectorRef, featuresService, entityPanelsService, paris, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.featuresService = featuresService;
        _this.entityPanelsService = entityPanelsService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.moreActionsFilter = function (obs$) {
            return obs$.pipe(map(function (actions) { return actions && actions.filter(function (a) { return a.isUndoable; }); }));
        };
        _this.messageBarType = MessageBarType;
        _this.manageActionAccountUrl = MANAGE_ACTION_ACCOUNT_DOC_URL;
        _this.showLinkInFailureBanner = false;
        return _this;
    }
    Object.defineProperty(ActionHistoryEntityPanelComponent.prototype, "actionHistory", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ActionHistoryEntityPanelComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, entity, isExtendedData);
        this.machine$ =
            this.actionHistory.machine && this.actionHistory.machine.id
                ? this.paris.getItemById(Machine, this.actionHistory.machine.id)
                : null;
    };
    ActionHistoryEntityPanelComponent.prototype.setEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entity, isExtendedData);
        this.moreActionsEnabled =
            this.featuresService.isEnabled(Feature.BulkFileUndo) && this.actionHistory.isUndoable;
        var actionFailureI18nKey = this.actionHistory.actionFailureI18nKey;
        this.showLinkInFailureBanner =
            actionFailureI18nKey &&
                (actionFailureI18nKey === ActionFailureReason.GmsaInsufficientPermissions ||
                    actionFailureI18nKey === ActionFailureReason.NoGmsaForRequestedDomain);
        this.changeDetectorRef.markForCheck();
    };
    ActionHistoryEntityPanelComponent.prototype.openOriginalAction = function () {
        this.openAction(new ActionHistory({
            id: this.undoneActionId,
            undoState: 'undone',
        }));
    };
    ActionHistoryEntityPanelComponent.prototype.openUndoAction = function () {
        this.openAction(new ActionHistory({
            id: this.undoActionId,
            actionType: this.paris.getValue(RemediationActionType, function (type) {
                return type.type === RemediationActionTypeTypes.undo_clean_resource;
            }),
        }));
    };
    ActionHistoryEntityPanelComponent.prototype.openAction = function (action) {
        var _this = this;
        var currentAction = this.actionHistory;
        this.entityPanelsService.showEntity(ActionHistory, action, {}, {
            back: {
                onClick: function () { return _this.entityPanelsService.showEntity(ActionHistory, currentAction); },
            },
        });
    };
    ActionHistoryEntityPanelComponent.prototype.onResetData = function () {
        this.undoActionId = this.undoneActionId = null;
        this.changeDetectorRef.markForCheck();
    };
    ActionHistoryEntityPanelComponent.prototype.onInvestigationActionLoad = function (action) {
        this.undoActionId = action.undoActionId;
        this.undoneActionId = action.undoneActionId;
        this.changeDetectorRef.markForCheck();
    };
    return ActionHistoryEntityPanelComponent;
}(EntityPanelComponentBase));
export { ActionHistoryEntityPanelComponent };
