var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris } from '@microsoft/paris';
import { RecommendationContextKeyAndExposedMachinesCount, SecurityRecommendation, RecommendationContextType, TvmEndPoint, RecommendationContextKeyPanelType, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
var RecommendationContextKeyPanelComponent = /** @class */ (function (_super) {
    __extends(RecommendationContextKeyPanelComponent, _super);
    function RecommendationContextKeyPanelComponent(sidePanelService, tvmTextsService, tvmDownloadService, paris, recommendationContextFieldsService, router, featuresService, globalEntityTypesService) {
        var _this = _super.call(this, router) || this;
        _this.sidePanelService = sidePanelService;
        _this.tvmTextsService = tvmTextsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.paris = paris;
        _this.recommendationContextFieldsService = recommendationContextFieldsService;
        _this.entityType = globalEntityTypesService.getEntityType(RecommendationContextKeyAndExposedMachinesCount);
        _this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
        return _this;
    }
    Object.defineProperty(RecommendationContextKeyPanelComponent.prototype, "recommendationContextType", {
        set: function (recommendationContextType) {
            this._recommendationContextType = recommendationContextType;
            this.loadingMessageText = this.tvmTextsService.recommendationContextToExposedSectionLoadingTitle[recommendationContextType];
        },
        enumerable: true,
        configurable: true
    });
    RecommendationContextKeyPanelComponent.prototype.ngOnInit = function () {
        this.fields =
            this.panelType === RecommendationContextKeyPanelType.EXPOSED_MACHINES_COUNT
                ? this.recommendationContextFieldsService.getExposedMachinesCountPerRecommendationContextKeyFields(this._recommendationContextType)
                : this.recommendationContextFieldsService.getAssetRecommendationContextFields(this._recommendationContextType);
        var allFieldsIds = this.fields.map(function (field) { return field.id; });
        this.dataViewConfig = {
            //must supply unique id for correct dataview caching purposes - unique id is based on the fields
            id: "recommendationContextDetails(" + allFieldsIds.join() + ")",
        };
    };
    RecommendationContextKeyPanelComponent.prototype.openRecommendationContextItemPanel = function (contextKeyEntity) {
        this.sidePanelService.showRecommendationContextPropertiesPanel(this.securityRecommendation, contextKeyEntity.contextKey, this._recommendationContextType);
    };
    RecommendationContextKeyPanelComponent.prototype.exportCveData = function () {
        this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.repository, TvmEndPoint.Analytics, this.recommendationCtxKeyTitle + " - " + this.panelSubTitle);
    };
    return RecommendationContextKeyPanelComponent;
}(PanelContainer));
export { RecommendationContextKeyPanelComponent };
