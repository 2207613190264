import { InvestigationActionCategory } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';

@Injectable()
export class InvestigationActionCategories {
	private actionCategories: Map<string, InvestigationActionCategory>;

	constructor(paris: Paris) {
		this.actionCategories = new Map(
			paris
				.getRepository(InvestigationActionCategory)
				.entity.values.map<[string, InvestigationActionCategory]>(category => [category.id, category])
		);
	}
	getActionCategoryById(categoryId: string) {
		return this.actionCategories.get(categoryId) || this.actionCategories.get('unknown');
	}
}
