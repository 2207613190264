var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a;
import { EventEmitter } from '@angular/core';
import { ActionHistory, ActionSourceType, AdminActionSource, AdminActionType, AirsEntityType, Machine, MachineMachineRequestsStateRelationship, ManualActionOnEmailApiCall, MdoManualAction, RemediationActionTypeTypes, SubActionTypeValue, UndoRemediationApiCall, ManualActionOnUserApiCall, ManualUserActionType, AlertV3EntityTypes, } from '@wcd/domain';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { ItemActionModel, } from '../../../dataviews/models/item-action.model';
import { ActionHistoryEntityPanelComponent } from '../components/action-history.entity-panel.component';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { Paris } from '@microsoft/paris';
import { combineLatest, defer, from, of } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { InvestigationsService } from '../../investigations/services/investigations.service';
import { MtpInvestigationsService } from '../../mtp_investigations/services/mtp-investigations.service';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { filter, map, take } from 'rxjs/operators';
import { compact, flatMap, uniqBy } from 'lodash-es';
import { ActionFinderService } from './pending-action-finder.service';
import { AddFilesToAllowlistModal } from '../../remediation/components/add-files-to-allowlist.modal';
import { ActionHistoryConverterService } from './action-history-converter.service';
import { MachineRequestsPermissionsService } from '../../machines/services/machines-requests.permissions.service';
import { MachinesActionsService } from '../../machines/services/machines-actions.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { v4 as uuid4 } from 'uuid';
import { AuthService } from '@wcd/auth';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
var ACTION_TYPE_TO_MACHINE_RESPONSE = Object.freeze((_a = {},
    _a[RemediationActionTypeTypes.restrict_app_execution_response] = 'RestrictExecutionResponse',
    _a[RemediationActionTypeTypes.isolate_response] = 'IsolationResponse',
    _a));
var BASE_UNDO_CONFIG = Object.freeze({
    id: 'undo',
    rbac: [RbacMdeAllowedActions.remediationActions],
    nameKey: 'actionsHistory_undo_action',
    icon: FabricIconNames.Undo,
    closeOnAction: true,
    allowRbacTooltipOverride: true,
});
var ActionHistoryEntityTypeService = /** @class */ (function () {
    function ActionHistoryEntityTypeService(confirmationService, dialogsService, i18nService, actionFinderService, paris, featuresService, machineRequestsPermissionsService, machinesActionsService, globalEntityTypesService, flavorService, authService, appContextService) {
        var _this = this;
        this.confirmationService = confirmationService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.actionFinderService = actionFinderService;
        this.paris = paris;
        this.featuresService = featuresService;
        this.machineRequestsPermissionsService = machineRequestsPermissionsService;
        this.machinesActionsService = machinesActionsService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.flavorService = flavorService;
        this.authService = authService;
        this.appContextService = appContextService;
        this.entityType = {
            id: 'ActionHistory',
            entity: ActionHistory,
            icon: FabricIconNames.History,
            getIcon: function (actions) {
                var actionType;
                if (actions && actions.length) {
                    actionType = actions[0].actionType;
                    if (actions.some(function (a) { return a.actionType !== actionType; })) {
                        actionType = null;
                    }
                }
                return actionType ? actionType.icon : FabricIconNames.History;
            },
            singleEntityPanelComponentType: ActionHistoryEntityPanelComponent,
            getEntityName: function (actionHistory) {
                return actionHistory.actionType && actionHistory.actionType.name;
            },
            loadFullEntityInPanel: false,
            getEntitiesLink: function (actions) {
                var investigationId = GlobalEntityTypesService.getCommonValue(actions, function (a) { return a.investigationId; });
                var machineId = GlobalEntityTypesService.getCommonValue(actions, function (a) { return a.machine && a.machine.id; });
                if (!investigationId && !machineId) {
                    return;
                }
                if (investigationId) {
                    if (!_this.flavorService.isEnabled(AppFlavorConfig.routes.investigation)) {
                        return;
                    }
                    return (_this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)
                        ? MtpInvestigationsService
                        : InvestigationsService).getInvestigationLink({
                        isOfficeInvestigation: actions[0].isOfficeInvestigation,
                        externalInvestigationPageUrl: actions[0].investigationDeeplink,
                        isLiveResponse: actions[0].isLiveResponse,
                        id: investigationId,
                    });
                }
                else if (machineId) {
                    return _this.globalEntityTypesService.getEntityLink(Machine, actions[0].machine);
                }
            },
            getUseExternalRouting: function (actions) {
                var investigationId = GlobalEntityTypesService.getCommonValue(actions, function (a) { return a.investigationId; });
                return (investigationId &&
                    !_this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
                    !!actions[0].investigationDeeplink);
            },
            getEntitiesLinkText: function (actions) {
                var investigationId = GlobalEntityTypesService.getCommonValue(actions, function (a) { return a.investigationId; });
                return investigationId
                    ? _this.i18nService.strings.actionHistory_panel_button_openInvestigationPage
                    : _this.i18nService.strings.actionHistory_panel_button_openDevicePage;
            },
            getEntitiesLinkTooltip: function (actions) {
                var action = actions[0];
                // office investigation data retention is 30 days, hence, we need to add tooltip that explain it in this case
                return action.doesInvestigationExist
                    ? _this.i18nService.strings.actionHistory_panel_button_openInvestigationPage
                    : _this.i18nService.strings
                        .actionHistory_panel_button_openInvestigationPage_data_retention_tooltip;
            },
            getEntitiesLinkDisabled: function (actions) {
                // office investigation data retention is 30 days, hence, we need to disable the button if investigation not exists
                var action = actions[0];
                return !action.doesInvestigationExist;
            },
            // @ts-ignore the type here is incorrect, TODO: fix me
            getActions: function (actions) {
                // get the action with mail message or mail cluster entity (assuming there's only one that can be either cluster or message)
                var mailRelatedAction = actions &&
                    actions.find(function (action) {
                        return action.entityType &&
                            (action.entityType.id === AirsEntityType.MailMessage ||
                                action.entityType.id === AirsEntityType.MailCluster);
                    });
                if (actions &&
                    actions.length === 1 &&
                    _this.appContextService.isSCC &&
                    _this.featuresService.isEnabled(Feature.MdoManualActions) &&
                    mailRelatedAction) {
                    var manualActionsRes = [];
                    // Add MDO manual actions
                    var moveToInbox$ = _this.getManualActionModelConfig(mailRelatedAction, MdoManualAction.MoveToInbox, SubActionTypeValue.MoveToInbox, FabricIconNames.Inbox);
                    var softDelete$ = _this.getManualActionModelConfig(mailRelatedAction, MdoManualAction.SoftDelete, SubActionTypeValue.SoftDelete, FabricIconNames.Delete);
                    var hardDelete$ = _this.getManualActionModelConfig(mailRelatedAction, MdoManualAction.Delete, SubActionTypeValue.Delete, FabricIconNames.Delete);
                    var moveToDelete$ = _this.getManualActionModelConfig(mailRelatedAction, MdoManualAction.MoveToDeletedItems, SubActionTypeValue.MoveToDeletedItems, FabricIconNames.FabricMovetoFolder);
                    var moveToJunk$ = _this.getManualActionModelConfig(mailRelatedAction, MdoManualAction.MoveToJunk, SubActionTypeValue.MoveToJunk, FabricIconNames.FabricMovetoFolder);
                    manualActionsRes.push(moveToInbox$);
                    manualActionsRes.push(softDelete$);
                    manualActionsRes.push(hardDelete$);
                    manualActionsRes.push(moveToDelete$);
                    manualActionsRes.push(moveToJunk$);
                    return combineLatest(manualActionsRes).pipe(map(function (_res) { return compact(_res).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); }); }));
                }
                if (actions.every(function (action) { return action.isUndoable || action.isUndone; })) {
                    var undoConfig = __assign({}, BASE_UNDO_CONFIG, { method: function (_actions) { return __awaiter(_this, void 0, void 0, function () {
                            var allActions, done$, e, flatActions, error_1;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, Promise.all(_actions.map(function (action) { return __awaiter(_this, void 0, void 0, function () {
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0: return [4 /*yield*/, this.pendingActionToExtraActions([action])
                                                            .pipe(map(function (allActions) { return uniqBy(allActions, 'id'); }), take(1))
                                                            .toPromise()];
                                                    case 1: return [2 /*return*/, _a.sent()];
                                                }
                                            });
                                        }); }))];
                                    case 1:
                                        allActions = _a.sent();
                                        done$ = new EventEmitter();
                                        return [4 /*yield*/, this.confirmationService.confirm({
                                                title: this.i18nService.strings.actionsHistory_undo_confirm_title,
                                                text: this.i18nService.strings.actionsHistory_undo_confirm_text +
                                                    (allActions.length === 1 && allActions[0].length > 1
                                                        ? '\n' +
                                                            this.i18nService.get('actionsHistory.undo.confirm.extraFiles', {
                                                                fileCount: allActions[0].length - 1,
                                                            })
                                                        : ''),
                                                showLoaderAndCloseOnEvent: done$,
                                            })];
                                    case 2:
                                        e = _a.sent();
                                        flatActions = Array.from(new Set(compact(flatMap(allActions))));
                                        if (!e.confirmed) return [3 /*break*/, 8];
                                        _a.label = 3;
                                    case 3:
                                        _a.trys.push([3, 5, , 7]);
                                        return [4 /*yield*/, this.paris
                                                .apiCall(UndoRemediationApiCall, Array.from(new Set(flatActions.map(function (a) { return a.id; }))))
                                                .toPromise()];
                                    case 4:
                                        _a.sent();
                                        done$.next(true);
                                        done$.complete();
                                        if (flatActions.every(function (a) { return a.actionType.type === RemediationActionTypeTypes.file_quarantine; })) {
                                            return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                                    var modal;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0: return [4 /*yield*/, this.dialogsService
                                                                    .showModal(AddFilesToAllowlistModal, {
                                                                    id: 'add-file-to-allowlist-modal',
                                                                    title: this.i18nService.strings
                                                                        .actionsHistory_undo_addToWhitelist_title,
                                                                    titleIcon: FabricIconNames.Accept,
                                                                }, {
                                                                    message: this.i18nService.get('actionsHistory_undo_addToWhitelist_message', { fileCount: _actions.length }),
                                                                    hashes: _actions.map(function (a) {
                                                                        var action = ActionHistoryConverterService.getRemediationActionFromActionHistory(a);
                                                                        return action && action.file && action.file.sha1;
                                                                    }),
                                                                    onDone: function () {
                                                                        resolve({ confirmed: true, data: null });
                                                                        modal.destroy();
                                                                    },
                                                                })
                                                                    .pipe(take(1))
                                                                    .toPromise()];
                                                            case 1:
                                                                modal = _a.sent();
                                                                modal.instance.cancel.subscribe(function () {
                                                                    resolve({ confirmed: true, data: null });
                                                                    modal.destroy();
                                                                });
                                                                return [2 /*return*/];
                                                        }
                                                    });
                                                }); })];
                                        }
                                        return [3 /*break*/, 7];
                                    case 5:
                                        error_1 = _a.sent();
                                        done$.next(true);
                                        done$.complete();
                                        return [4 /*yield*/, this.confirmationService.confirm({
                                                title: this.i18nService.strings.actionsHistory_undo_generalError_title,
                                                icon: FabricIconNames.Error,
                                                text: this.i18nService.strings.actionsHistory_undo_generalError,
                                                showConfirm: false,
                                                cancelText: this.i18nService.strings.buttons_done,
                                            })];
                                    case 6:
                                        _a.sent();
                                        return [3 /*break*/, 7];
                                    case 7: return [2 /*return*/, Promise.resolve({ confirmed: true, data: null })];
                                    case 8: return [2 /*return*/, Promise.reject()];
                                }
                            });
                        }); } });
                    var undoneActionsCount = actions.filter(function (action) { return action.isUndone; }).length;
                    if (undoneActionsCount) {
                        var tooltip = void 0;
                        if (undoneActionsCount === actions.length) {
                            if (actions.length === 1) {
                                tooltip = 'actionsHistory_undo_undone_single';
                            }
                            else {
                                tooltip = 'actionsHistory_undo_undone_all';
                            }
                        }
                        else {
                            tooltip = 'actionsHistory_undo_undone_multiple';
                        }
                        undoConfig.tooltip = _this.i18nService.strings[tooltip];
                        undoConfig.disabled = true;
                    }
                    return [new ItemActionModel(undoConfig)];
                }
                if (actions && actions.length === 1 && actions[0].machine) {
                    var action_1 = actions[0];
                    var cloudResponseType_1 = ACTION_TYPE_TO_MACHINE_RESPONSE[action_1.actionType.type];
                    if (cloudResponseType_1) {
                        return defer(function () { return __awaiter(_this, void 0, void 0, function () {
                            var machine, featuresStatus, requestsState, undoAction;
                            var _this = this;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0: return [4 /*yield*/, this.paris.getItemById(Machine, action_1.machine.id).toPromise()];
                                    case 1:
                                        machine = _a.sent();
                                        return [4 /*yield*/, this.machineRequestsPermissionsService
                                                .getFeaturesStatus(machine)
                                                .toPromise()];
                                    case 2:
                                        featuresStatus = _a.sent();
                                        if (!(featuresStatus && featuresStatus[cloudResponseType_1])) {
                                            return [2 /*return*/];
                                        }
                                        return [4 /*yield*/, this.paris
                                                .getRelatedItem(MachineMachineRequestsStateRelationship, machine)
                                                .toPromise()];
                                    case 3:
                                        requestsState = _a.sent();
                                        if (cloudResponseType_1 === 'IsolationResponse' &&
                                            requestsState.isolation === 'RequestCompleted') {
                                            undoAction = function () { return _this.machinesActionsService.releaseFromIsolation(machine); };
                                        }
                                        else if (cloudResponseType_1 === 'RestrictExecutionResponse' &&
                                            requestsState.restrictExecution === 'RequestCompleted') {
                                            undoAction = function () { return _this.machinesActionsService.removeAppRestrictions(machine); };
                                        }
                                        if (!undoAction) {
                                            return [2 /*return*/];
                                        }
                                        return [2 /*return*/, [
                                                new ItemActionModel(__assign({}, BASE_UNDO_CONFIG, { method: function () { return __awaiter(_this, void 0, void 0, function () {
                                                        var confirmed;
                                                        return __generator(this, function (_a) {
                                                            switch (_a.label) {
                                                                case 0: return [4 /*yield*/, undoAction()];
                                                                case 1:
                                                                    confirmed = (_a.sent()).confirmed;
                                                                    if (!confirmed) {
                                                                        return [2 /*return*/, Promise.reject()];
                                                                    }
                                                                    return [2 /*return*/];
                                                            }
                                                        });
                                                    }); } })),
                                            ]];
                                }
                            });
                        }); }).pipe(filter(Boolean));
                    }
                }
                var actionType = actions && actions.length === 1 && actions[0].actionType;
                if (actionType &&
                    (actionType.type === RemediationActionTypeTypes.enable_user ||
                        actionType.type === RemediationActionTypeTypes.disable_user)) {
                    var manualUserActionsRes = [];
                    var enableUser$ = _this.getManualUserActionModelConfig(actions[0], ManualUserActionType.EnableUser);
                    var disableUser$ = _this.getManualUserActionModelConfig(actions[0], ManualUserActionType.DisableUser);
                    manualUserActionsRes.push(enableUser$);
                    manualUserActionsRes.push(disableUser$);
                    return combineLatest(manualUserActionsRes).pipe(map(function (_res) { return compact(_res).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); }); }));
                }
            },
        };
    }
    ActionHistoryEntityTypeService.prototype.getManualActionModelConfig = function (action, manualAction, subActionType, icon) {
        var _this = this;
        var manualActionI18nKey = "actionsHistory_panel_manualAction_" + manualAction;
        var isSecAdmin = this.authService.currentUser.isSecAdmin;
        var isAllowed = isSecAdmin || !action.isOfficeInvestigation;
        var ignoreRbac = this.appContextService.isSCC && action.isOfficeInvestigation;
        var isAllowed$;
        if (!isAllowed && this.appContextService.isSCC) {
            isAllowed$ = from(sccHostService.auth.isInRole(SccRoles.searchAndPurge));
        }
        else {
            isAllowed$ = of(isAllowed);
        }
        return isAllowed$.pipe(map(function (hasPermission) {
            return Object.assign({
                id: "manualAction_" + manualAction,
                nameKey: manualActionI18nKey,
                tooltip: hasPermission
                    ? _this.i18nService.get(manualActionI18nKey)
                    : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                method: function (_actions) { return __awaiter(_this, void 0, void 0, function () {
                    var done$, isMailMessage, entityI18nKey, confirmTextI18nKey, confirmEvent, requestBody, title_1, titleIcon_1, message_1, error_2;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                done$ = new EventEmitter();
                                isMailMessage = action.entityType.id === AirsEntityType.MailMessage;
                                entityI18nKey = isMailMessage ? 'mailMessage' : 'mailCluster';
                                confirmTextI18nKey = "actionsHistory_panel_" + entityI18nKey + "_manualAction_" + manualAction + "_confirmText";
                                return [4 /*yield*/, this.confirmationService.confirm({
                                        title: this.i18nService.get(manualActionI18nKey),
                                        text: this.i18nService.get(confirmTextI18nKey),
                                        showLoaderAndCloseOnEvent: done$,
                                    })];
                            case 1:
                                confirmEvent = _a.sent();
                                if (!confirmEvent.confirmed) return [3 /*break*/, 7];
                                requestBody = this.buildManualAdminActionRequest(action, subActionType);
                                title_1 = '';
                                titleIcon_1 = null;
                                message_1 = '';
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, 4, 5, 6]);
                                if (!requestBody) {
                                    throw new Error('RequestBody is empty');
                                }
                                return [4 /*yield*/, this.paris
                                        .apiCall(ManualActionOnEmailApiCall, requestBody)
                                        .toPromise()];
                            case 3:
                                _a.sent();
                                title_1 = this.i18nService.strings
                                    .actionsHistory_panel_manualAction_resultModal_success_title;
                                titleIcon_1 = FabricIconNames.Accept;
                                message_1 = this.i18nService.get("actionsHistory_panel_" + entityI18nKey + "_manualAction_" + manualAction + "_resultModal_success_body");
                                return [3 /*break*/, 6];
                            case 4:
                                error_2 = _a.sent();
                                titleIcon_1 = FabricIconNames.Error;
                                if (error_2.response && error_2.response.status === 409) {
                                    title_1 = this.i18nService.strings
                                        .actionsHistory_panel_manualAction_requestAlreadySubmitted_title;
                                    message_1 = this.i18nService.get("actionsHistory_panel_" + entityI18nKey + "_manualAction_" + manualAction + "_resultModal_requestAlreadySubmitted");
                                }
                                else {
                                    title_1 = this.i18nService.strings
                                        .actionsHistory_panel_manualAction_request_generalError_title;
                                    message_1 = this.i18nService.strings
                                        .actionsHistory_panel_manualAction_request_generalError;
                                }
                                return [3 /*break*/, 6];
                            case 5:
                                done$.next(true);
                                done$.complete();
                                return [7 /*endfinally*/];
                            case 6: return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.confirmationService.confirm({
                                                    title: title_1,
                                                    icon: titleIcon_1,
                                                    text: message_1,
                                                    showConfirm: false,
                                                    cancelText: this.i18nService.strings.buttons_done,
                                                })];
                                            case 1:
                                                _a.sent();
                                                resolve({ confirmed: true, data: null });
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                            case 7: return [2 /*return*/, Promise.reject()];
                        }
                    });
                }); },
                icon: icon,
                closeOnAction: false,
                allowRbacTooltipOverride: true,
                refreshOnResolve: false,
                refreshEntityPanelOnResolve: false,
                disabled: !hasPermission,
            }, ignoreRbac
                ? null
                : {
                    rbac: [RbacMdeAllowedActions.remediationActions],
                    rbacState: RbacControlState.disabled,
                });
        }));
    };
    ActionHistoryEntityTypeService.prototype.buildManualAdminActionRequest = function (actionHistory, subActionType) {
        var relatedEntities = actionHistory.relatedEntities;
        var entity = relatedEntities && relatedEntities.length
            ? relatedEntities[0]
            : {};
        // the range for the request should be 30 days
        var today = new Date();
        var monthAgo = new Date();
        monthAgo.setDate(monthAgo.getDate() - 30);
        /*
            In admin playbook we have 'approvalId' for each action and for other MDO actions we might not have
            this value.
            If 'approvalId' value exists on the original action, this value should be used as 'bulkId' in the new action
            so we will be able to connect between those actions.
            In case of no 'approvalId', the fallback is to generate 'bulkId' from the entityId or just random guid
            if there's no entityId.
        */
        var entityId = entity.entityId;
        var bulkId = actionHistory.approvalId
            ? actionHistory.approvalId
            : typeof entityId === 'string'
                ? this.createGuidFromEntityId(entityId)
                : uuid4();
        var tenantId = sccHostService.isSCC ? sccHostService.loginUser.tenantId : null;
        var adminActionRequest = {
            AdminActionType: AdminActionType.MailAction,
            SubActionType: subActionType,
            StartTime: actionHistory.entityType.id === AirsEntityType.MailCluster
                ? new Date(entity.clusterQueryStartTime)
                : monthAgo,
            EndTime: actionHistory.entityType.id === AirsEntityType.MailCluster
                ? new Date(entity.clusterQueryTime)
                : today,
            TenantId: tenantId,
            Name: this.i18nService.strings.actionHistory_panel_manualAction_requestName_field_value,
            ApproverUpn: sccHostService.isSCC ? sccHostService.loginUser.upn : null,
            IsImmediateAction: true,
            BulkId: bulkId,
            AdminActionSource: AdminActionSource.MTP_BulkAction,
        };
        if (actionHistory.entityType.id === AirsEntityType.MailMessage) {
            var emailList = [
                { NetworkMessageId: entity.networkMessageId, Recipient: entity.recipientEmailAddress },
            ];
            return Object.assign(adminActionRequest, { EmailList: emailList });
        }
        else if (actionHistory.entityType.id === AirsEntityType.MailCluster) {
            return Object.assign(adminActionRequest, {
                QueryString: entity.clusterQueryString,
            });
        }
    };
    ActionHistoryEntityTypeService.prototype.getManualUserActionModelConfig = function (action, userActionType) {
        var _this = this;
        var userActionI18nKey = "actionsHistory_panel_Account_manualUserAction_" + userActionType;
        var isAllowed$ = of(this.authService.currentUser.isMdeAdmin);
        return isAllowed$.pipe(map(function (hasPermission) {
            return Object.assign({
                id: "manualUserAction_" + userActionType,
                nameKey: userActionI18nKey,
                tooltip: hasPermission
                    ? _this.i18nService.get(userActionI18nKey)
                    : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                method: function (_actions) { return __awaiter(_this, void 0, void 0, function () {
                    var done$, confirmTextI18nKey, confirmEvent, requestBody, title_2, titleIcon_2, message_2, error_3;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                done$ = new EventEmitter();
                                confirmTextI18nKey = "actionsHistory_panel_Account_manualUserAction_" + userActionType + "_confirmText";
                                return [4 /*yield*/, this.confirmationService.confirm({
                                        title: this.i18nService.get(userActionI18nKey),
                                        text: this.i18nService.get(confirmTextI18nKey),
                                        showLoaderAndCloseOnEvent: done$,
                                    })];
                            case 1:
                                confirmEvent = _a.sent();
                                if (!confirmEvent.confirmed) return [3 /*break*/, 7];
                                requestBody = this.buildManualUserActionRequest(action, userActionType);
                                title_2 = '';
                                titleIcon_2 = null;
                                message_2 = '';
                                _a.label = 2;
                            case 2:
                                _a.trys.push([2, 4, 5, 6]);
                                if (!requestBody) {
                                    throw new Error('RequestBody is empty');
                                }
                                return [4 /*yield*/, this.paris
                                        .apiCall(ManualActionOnUserApiCall, requestBody)
                                        .toPromise()];
                            case 3:
                                _a.sent();
                                title_2 = this.i18nService.strings
                                    .actionsHistory_panel_Account_manualUserAction_resultModal_success_title;
                                titleIcon_2 = FabricIconNames.Accept;
                                message_2 = this.i18nService.get("actionsHistory_panel_Account_manualUserAction_" + userActionType + "_resultModal_success_body");
                                return [3 /*break*/, 6];
                            case 4:
                                error_3 = _a.sent();
                                titleIcon_2 = FabricIconNames.Error;
                                title_2 = this.i18nService.strings
                                    .actionsHistory_panel_manualAction_request_generalError_title;
                                message_2 = this.i18nService.strings
                                    .actionsHistory_panel_manualAction_request_generalError;
                                return [3 /*break*/, 6];
                            case 5:
                                done$.next(true);
                                done$.complete();
                                return [7 /*endfinally*/];
                            case 6: return [2 /*return*/, new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, this.confirmationService.confirm({
                                                    title: title_2,
                                                    icon: titleIcon_2,
                                                    text: message_2,
                                                    showConfirm: false,
                                                    cancelText: this.i18nService.strings.buttons_done,
                                                })];
                                            case 1:
                                                _a.sent();
                                                resolve({ confirmed: true, data: null });
                                                return [2 /*return*/];
                                        }
                                    });
                                }); })];
                            case 7: return [2 /*return*/, Promise.reject()];
                        }
                    });
                }); },
                icon: FabricIconNames.People,
                closeOnAction: false,
                allowRbacTooltipOverride: true,
                refreshOnResolve: false,
                refreshEntityPanelOnResolve: false,
                disabled: !hasPermission,
            }, {
                rbac: [RbacMdeAllowedActions.admin],
                rbacState: RbacControlState.disabled,
            });
        }));
    };
    ActionHistoryEntityTypeService.prototype.buildManualUserActionRequest = function (actionHistory, userActionType) {
        var relatedEntities = actionHistory.relatedEntities;
        var entity = relatedEntities && relatedEntities.length
            ? relatedEntities[0]
            : {};
        var currentUser = sccHostService.loginUser;
        var accountAlertV3Type = 'Account';
        var aadTenantId = currentUser.tenantId;
        return {
            InitiatedByAccount: {
                $type: accountAlertV3Type,
                AadUserId: currentUser.userId,
                DisplayName: currentUser.displayName && currentUser.displayName.split('@')[0],
                UPNSuffix: currentUser.upn && currentUser.upn.split('@')[1],
                AadTenantId: aadTenantId,
                Type: AlertV3EntityTypes.Account,
            },
            RemediationActions: [
                {
                    ActionOrigin: 'Manual',
                    ActionSource: ActionSourceType.MDIManualAction,
                    ActionType: userActionType,
                    Entities: [
                        {
                            $type: accountAlertV3Type,
                            Name: entity.name,
                            AadTenantId: entity.aadTenantId,
                            AadUserId: entity.aadUserId,
                            NTDomain: entity.ntDomain,
                            DnsDomain: entity.dnsDomain,
                            UPNSuffix: entity.upnSuffix,
                            Sid: entity.sid,
                            DisplayName: entity.userDisplayName,
                            FriendlyName: entity.friendlyName,
                            UniqueName: entity.uniqueName,
                            Type: AlertV3EntityTypes.Account,
                        },
                    ],
                    ResourceIdentifiers: [
                        {
                            AadTenantId: aadTenantId,
                            Type: 'AAD',
                            $type: 'AADIdentifier',
                        },
                    ],
                    ApprovedBy: currentUser.displayName,
                    $type: 'InvestigationAction',
                },
            ],
        };
    };
    /**
     * This function generates a guid from an entityId.
     * MDO entityId is in the form of: 'urn:<entity type>:<32 chars>'.
     * The output is a guid from the 32 chars of the entityId.
     * For example:
     * for entityId: 'urn:MailEntity:558cb4d9989d7fcb23a81762f0458b53'
     * The output will be: 558cb4d9-989d-7fcb-23a8-1762f0458b53
     *
     * @param entityId
     * @private
     */
    ActionHistoryEntityTypeService.prototype.createGuidFromEntityId = function (entityId) {
        if (!entityId) {
            return '';
        }
        // get the last part of the entityId.
        // for example: '558cb4d9989d7fcb23a81762f0458b53' from 'urn:MailEntity:558cb4d9989d7fcb23a81762f0458b53'
        var strippedId = entityId.split(':').pop();
        var allowedChars = '[a-f0-9]';
        var guid = strippedId.replace(new RegExp("^(" + allowedChars + "{8})(" + allowedChars + "{4})(" + allowedChars + "{4})(" + allowedChars + "{4})(" + allowedChars + "{12})"), '$1-$2-$3-$4-$5');
        return guid;
    };
    ActionHistoryEntityTypeService.prototype.pendingActionToExtraActions = function (pendingActions) {
        var _this = this;
        if (pendingActions.some(function (a) { return a.actionType.type !== RemediationActionTypeTypes.file_quarantine; })) {
            return of(pendingActions);
        }
        return combineLatest(pendingActions.map(function (a) { return _this.actionFinderService.getMoreActionsForFile(a).pipe(take(1)); })).pipe(map(function (d) {
            var actions = compact(flatMap(d));
            return actions.concat(pendingActions);
        }));
    };
    return ActionHistoryEntityTypeService;
}());
export { ActionHistoryEntityTypeService };
