import { Component, OnDestroy, OnInit } from '@angular/core';
import {
	PENDING_USER_NOTIFICATION_ID,
	PendingActionsService,
	PendingActionTypes,
} from '../services/pending-actions.service';
import { Subscription } from 'rxjs';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { TitleService } from '../../../shared/services/title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { RemediationActionTypeActionCount } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'pending-actions-page',
	templateUrl: './pending-actions.page.component.html',
})
export class PendingActionsPageComponent implements OnInit, OnDestroy {
	pendingActionTypes: Array<RemediationActionTypeActionCount>;
	isLoading: boolean = true;
	error: any;
	pollingError: any;
	selectedRemediationActionTypeActions: RemediationActionTypeActionCount;
	tabs: Array<TabModel>;

	private _pendingActionsSubscription: Subscription;
	private _setActionTypesTimeout;

	constructor(
		public pendingActionsService: PendingActionsService,
		private notificationsService: NotificationsService,
		private featureService: FeaturesService,
		public titleService: TitleService,
		private router: Router,
		private route: ActivatedRoute,
		public i18nService: I18nService
	) {}

	ngOnInit() {
		this._pendingActionsSubscription = this.pendingActionsService.pendingActions$.subscribe(
			(data: PendingActionTypes | Error) => {
				this.isLoading = false;
				if (data instanceof Error) {
					if (this.pendingActionTypes && this.pendingActionTypes.length) {
						// if we already have some actions, just show warning on top of screen. else show error in the center of the screen.
						this.pollingError = data;
						this.error = null;
					} else this.error = data;
				} else {
					this.error = null;
					this.pollingError = null;
					clearTimeout(this._setActionTypesTimeout);

					this._setActionTypesTimeout = setTimeout(
						() => {
							this.pendingActionTypes = (<PendingActionTypes>data).all;
							// this.selectedRemediationActionTypeActions = this.pendingActionTypes[0];
							// this.setTabs();
						},
						this.pendingActionTypes ? 600 : 0
					); // If it's an update, allow time for any collapsing to happen before removing the pending-actions-type
				}
			},
			error => {
				this.isLoading = false;
				this.error = error;
			}
		);
		this.notificationsService.setHiddenHighPriorityNotificationId(PENDING_USER_NOTIFICATION_ID);
	}

	ngOnDestroy() {
		this._pendingActionsSubscription.unsubscribe();
		if (!this.featureService.isEnabled(Feature.DisablePortalRemediationNotifications)) {
			this.notificationsService.setHiddenHighPriorityNotificationId(
				PENDING_USER_NOTIFICATION_ID,
				false
			);
		}
	}

	setTab(tab: TabModel) {
		const resetParams = {
			page: null,
			page_size: null,
			filters: null,
			search: null,
			ordering: null,
			fields: null,
		};

		this.router.navigate(['.'], { relativeTo: this.route, queryParams: resetParams });

		this.selectedRemediationActionTypeActions = tab.data.remediationActionTypeActions;
	}

	setTabs() {
		this.tabs = this.pendingActionTypes.map(
			(remediationActionTypeActions: RemediationActionTypeActionCount) => {
				return new TabModel({
					id: remediationActionTypeActions.remediationActionType.type,
					name: remediationActionTypeActions.remediationActionType.name,
					value: remediationActionTypeActions.actionCount,
					data: {
						remediationActionTypeActions: remediationActionTypeActions,
					},
				});
			}
		);
	}
}
