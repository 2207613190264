/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/summery-bars-with-legend/summery-bars-with-legend.component.ngfactory";
import * as i2 from "../../../shared/components/summery-bars-with-legend/summery-bars-with-legend.component";
import * as i3 from "@angular/common";
import * as i4 from "./tvm-profiles-compliance-status-bars.component";
import * as i5 from "../../services/tvm-profiles-compliance-status-bars.service";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "@angular/router";
var styles_TvmProfilesComplianceStatusBarsComponent = [];
var RenderType_TvmProfilesComplianceStatusBarsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmProfilesComplianceStatusBarsComponent, data: {} });
export { RenderType_TvmProfilesComplianceStatusBarsComponent as RenderType_TvmProfilesComplianceStatusBarsComponent };
function View_TvmProfilesComplianceStatusBarsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "summery-bars-with-legend", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClickOnProfile($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SummeryBarsWithLegendComponent_0, i1.RenderType_SummeryBarsWithLegendComponent)), i0.ɵdid(1, 49152, null, 0, i2.SummeryBarsWithLegendComponent, [], { settingsArray: [0, "settingsArray"], legendItems: [1, "legendItems"], showLegend: [2, "showLegend"] }, { onClick: "onClick" }), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingsArray; var currVal_1 = _co.tvmProfilesComplianceStatusBarsService.legendItems; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TvmProfilesComplianceStatusBarsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmProfilesComplianceStatusBarsComponent_1)), i0.ɵdid(1, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settingsArray; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TvmProfilesComplianceStatusBarsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-profiles-compliance-status-bars", [], null, null, null, View_TvmProfilesComplianceStatusBarsComponent_0, RenderType_TvmProfilesComplianceStatusBarsComponent)), i0.ɵdid(1, 573440, null, 0, i4.TvmProfilesComplianceStatusBarsComponent, [i0.ChangeDetectorRef, i5.TvmProfilesComplianceStatusBarsService, i6.I18nService, i7.Router], null, null)], null, null); }
var TvmProfilesComplianceStatusBarsComponentNgFactory = i0.ɵccf("tvm-profiles-compliance-status-bars", i4.TvmProfilesComplianceStatusBarsComponent, View_TvmProfilesComplianceStatusBarsComponent_Host_0, { profilesComplianceStatus: "profilesComplianceStatus", showTitle: "showTitle" }, {}, []);
export { TvmProfilesComplianceStatusBarsComponentNgFactory as TvmProfilesComplianceStatusBarsComponentNgFactory };
