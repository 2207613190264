import { MachinesService } from './../services/machines.service';
import { Component, Input, OnInit } from '@angular/core';
import { Machine, MachineSecurityAnalytics, MachineSecurityControlProperty } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';

@Component({
	selector: 'machine-security-state-panel',
	templateUrl: './machine-score.panel.component.html',
})
export class MachineSecurityStatePanelComponent extends PanelContainer implements OnInit {
	@Input() machine: Machine;
	@Input() machineSecurityAnalytics: MachineSecurityAnalytics;

	controls: Array<ControlViewModel> = [];
	constructor(
		private dialogsService: DialogsService,
		private machineService: MachinesService,
		router: Router
	) {
		super(router);
	}

	ngOnInit() {
		this.controls = this.extractSecurityControls(this.machineSecurityAnalytics);
	}

	doPropertyAction(propertyName: string) {
		// Open missing updates side pane
		if (this.ShouldPropertyDisplayAction(propertyName)) {
			this.machineService.showMachineCvesPanel(this.machineSecurityAnalytics.machineSecurityMissingKbs);
		}
	}

	ShouldPropertyBeDisplayed(propertyName: string): boolean {
		// Excluding Poncherello
		return (
			propertyName !== 'SecurityUpdatesPoncherelloOs' &&
			propertyName !== 'SecurityUpdatesPoncherelloFirmware' &&
			propertyName !== 'SecurityUpdatesPoncherelloOsAndFirmware'
		);
	}

	ShouldPropertyDisplayAction(propertyName: string): boolean {
		return (
			propertyName == 'SecurityUpdatesUpToDate' &&
			this.machineSecurityAnalytics.machineSecurityMissingKbs.length > 0
		);
	}

	getAdditionalInfo(controlType: string): string {
		if (controlType === 'AntiVirus') {
			return this.machineSecurityAnalytics.machineSecurityAvSigVersion;
		}
		return '';
	}

	extractSecurityControls(machineSecurityLatestState: MachineSecurityAnalytics): Array<ControlViewModel> {
		const machineSecurityAnalyticsControls: Array<ControlViewModel> = [];

		if (!machineSecurityLatestState) {
			return machineSecurityAnalyticsControls;
		}

		const controlMaxScore: number =
			machineSecurityLatestState.maxPotentialSecurityScore /
			machineSecurityLatestState.securityControls.length;
		machineSecurityLatestState.securityControls.map(control =>
			machineSecurityAnalyticsControls.push({
				type: control.controlType,
				score: control.score,
				maxScore: controlMaxScore,
				overallStatus: control.overallStatus,
				properties: control.propertyImprovementScores,
			})
		);

		return machineSecurityAnalyticsControls;
	}
}

interface ControlViewModel {
	type: string;
	score: number;
	maxScore: number;
	overallStatus: string;
	properties: Array<MachineSecurityControlProperty>;
	isExpanded?: boolean;
}
