
		<ng-container *ngIf="(alertData$ | async) as alertData">
			<ng-container *ngIf="alertData !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="alertData?.alertsSeveritySummary?.hasAlerts || alertData?.alerts?.length"
					[sectionId]="collapsibleID.ActiveAlerts"
					(isExpandedChange)="$event && alertsDataview && alertsDataview.updateTableHeaders()"
					[label]="activeAlertsTitle"
				>
					<div class="wcd-padding-large-bottom">
						<alerts-severity-summary
							[alertsSeveritySummary]="alertData.alertsSeveritySummary"
						></alerts-severity-summary>
					</div>
					<alerts-dataview
						*ngIf="alertData.alerts"
						#alertsDataview
						[dataViewId]="'airs-entity-alert-dataview'"
						[dataViewConfig]="{ data: alertData.alerts, id: 'airs-entity-alert-dataview' }"
						[showHeader]="false"
						[allowFilters]="false"
						[allowPaging]="false"
						[allowGrouping]="false"
						[allowTimeRangeSelect]="false"
						[hideControls]="true"
						[disableSelection]="true"
						[fixedTable]="true"
						[onlyFields]="['title', 'severity']"
						[queueHeader]="false"
						[sortDisabledFields]="['title', 'severity']"
						[removePadding]="false"
					></alerts-dataview>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="(vtData$ | async) as vtData">
			<ng-container *ngIf="vtData !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="vtData.total"
					[sectionId]="collapsibleID.VirusTotal"
					[label]="i18nService.strings.entities_panelSections_virusTotal"
				>
					<div class="wcd-full-width wcd-flex-horizontal">
						<wcd-bar
							[fillRatio]="vtData.positives / vtData.total"
							[width]="'100%'"
							[colorName]="'yellow'"
							class="wcd-full-width wcd-margin-small-right"
						></wcd-bar>
						<span class="bold">{{ vtData.positives }}</span>
						<span class="color-text-neutralTertiary">/{{ vtData.total }}</span>
					</div>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="(fileStats$ | async) as fileStats">
			<ng-container *ngIf="fileStats !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="fileStats?.length && fileStats[0].prevalentThreatName"
					[sectionId]="collapsibleID.FileStats"
					(isExpandedChange)="$event && tableComponent.updateHeaderCells()"
					[label]="i18nService.strings.entities_panelSections_malware"
				>
					<!--[
					Added container with min-height to 1px - if the height is zero, no expansion will take place.
					wcd-datatable has height=100%, which is being affected by the containers height.
					]-->
					<div [style.minHeight.px]="1">
						<wcd-datatable
							#tableComponent
							[items]="fileStats"
							[columns]="malwareFields"
							[fixedTable]="true"
							[selectEnabled]="false"
						></wcd-datatable>
					</div>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<wcd-collapsible-section
			*ngIf="file && isFilePrevalenceApiEnabled"
			[sectionId]="collapsibleID.FilePrevalence"
			[label]="i18nService.strings.entities_panelSections_filePrevalence"
		>
			<file-prevalence [file]="file" [summaryView]="true"></file-prevalence>
		</wcd-collapsible-section>
		<ng-template #showLoader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner></fab-spinner>
			</div>
		</ng-template>
	