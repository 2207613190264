import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { SoftwareInstallation } from '@wcd/domain';
//import { SoftwareInstallation } from '../../../../src/tvm/software-installation/software-installation.entity';

@Pipe({ name: 'SoftwareInstallationNormalizedDisplay' })
export class SoftwareInstallationNormalizedDisplayPipe implements PipeTransform {
	constructor(private i18nService: I18nService) {}

	transform(software: SoftwareInstallation) {
		if (!software.isNormalized) {
			return this.i18nService.get(`tvm.common.softwareCpeNotAvailable`);
		}
		return [software.vendor, software.productName, software.installedVersion]
			.map(str => str.toLocaleLowerCase().replace(/ /g, '_'))
			.join(':');
	}
}
