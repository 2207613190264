import { ApiCall, ApiCallModel, ParisConfig } from '@microsoft/paris';

@ApiCall({
	name: 'Get number of new or updated outbreaks for the current user',
	endpoint: 'outbreaks/changeCount',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.threatAnalytics,
	method: 'GET',
	parseData: (data: { items: number }) => data.items,
})
export class GetNewOutbreaksCountAPICall extends ApiCallModel<number, void> {}
