var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { Machine } from '@wcd/domain';
var MachineDirectoryDataPanelComponent = /** @class */ (function (_super) {
    __extends(MachineDirectoryDataPanelComponent, _super);
    function MachineDirectoryDataPanelComponent(router) {
        return _super.call(this, router) || this;
    }
    MachineDirectoryDataPanelComponent.prototype.ngOnInit = function () {
        this.directoryDataIdentifiers = {
            canonicalName: this.machine.canonicalName,
            distinguishedName: this.machine.distinguishedName,
            dnsName: this.machine.name,
            sid: this.machine.sid,
        };
    };
    return MachineDirectoryDataPanelComponent;
}(PanelContainer));
export { MachineDirectoryDataPanelComponent };
