var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { EventHubProperties } from './event-hub-properties.value-object';
import { LogProperties } from './log-properties.value-object';
import { StorageAccountProperties } from './storage-account-properties.value-object';
var ɵ0 = function (item, config) { return 'POST'; }, ɵ1 = function (item) { return 'dataexportsettings'; }, ɵ2 = function (config) { return config.data.serviceUrls.wdatpApi; }, ɵ3 = function (response) {
    return {
        count: response.value.length,
        items: response.value,
    };
};
var DataExportSettingsEntity = /** @class */ (function (_super) {
    __extends(DataExportSettingsEntity, _super);
    function DataExportSettingsEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'id' }),
        __metadata("design:type", String)
    ], DataExportSettingsEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'designatedTenantId' }),
        __metadata("design:type", String)
    ], DataExportSettingsEntity.prototype, "designatedTenantId", void 0);
    __decorate([
        EntityField({ data: 'eventHubProperties' }),
        __metadata("design:type", EventHubProperties)
    ], DataExportSettingsEntity.prototype, "eventHubProperties", void 0);
    __decorate([
        EntityField({ data: 'storageAccountProperties' }),
        __metadata("design:type", StorageAccountProperties)
    ], DataExportSettingsEntity.prototype, "storageAccountProperties", void 0);
    __decorate([
        EntityField({ data: 'workspaceProperties' }),
        __metadata("design:type", StorageAccountProperties)
    ], DataExportSettingsEntity.prototype, "workspaceProperties", void 0);
    __decorate([
        EntityField({ data: 'logs', arrayOf: LogProperties }),
        __metadata("design:type", Array)
    ], DataExportSettingsEntity.prototype, "logs", void 0);
    DataExportSettingsEntity = __decorate([
        Entity({
            singularName: 'Data export settings',
            pluralName: 'Data export settings',
            endpoint: 'dataexportsettings',
            saveMethod: ɵ0,
            parseSaveQuery: ɵ1,
            readonly: true,
            separateArrayParams: true,
            baseUrl: ɵ2,
            allItemsEndpointTrailingSlash: false,
            parseDataSet: ɵ3,
        })
    ], DataExportSettingsEntity);
    return DataExportSettingsEntity;
}(EntityModelBase));
export { DataExportSettingsEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
