import { memoize } from 'lodash-es';

const COLORS = Object.freeze({
	good: '#00A5B0',
	remediated: '#107C10',
	success: '#107c10',
	bad: '#A80000',
	error: '#a80000',
	attention: '#a80000',
	highSeverity: '#990000',
	mediumSeverity: '#e60000',
	lowSeverity: '#F56A00',
	partial: '#F2610C',
	'warning-dark': '#F2610C',
	pending: '#F2610C',
	neutral: '#A19F9D',
	neutralLight: '#C8C6C4',
	pendingLow: '#ffb900',
	terminated: '#605E5C',
	benign: '#00A5B0',
	orange: '#d83b01',
	red: '#a80000',
	greenLight: '#6bb700',
	successLight: '#6BB700',
	neutralQuaternaryAlt: '#D9D9D9',
	neutral2: '#a19f9d',
	'gray-700': '#605E5C',
	'gray-900': '#323130',
	hexadite: '#00A5B0',
	error2: '#A80000',
	primary: '#0078d4',
	primaryLight: '#71AFE5',
	secondary: '#2b88d8 ',
	purple: '#917EDB',
	white: '#ffffff',
});

const FILTER_DATES: { [index: string]: { name: string } } = {
	day: {
		name: 'Last 24 hours',
	},
	week: {
		name: 'Last week',
	},
	month: {
		name: 'Last month',
	},
	older: {
		name: 'Older than 1 month',
	},
};

const ICON_LIBRARIES = {
	wcd: 'wcd-icons',
	wdatp: 'segoe-mdl2-icons',
};

const ICON_NAMES = {
	alert: 'alert',
	attentionRequired: 'user_attention',
	certificate: 'certificate',
	comment: 'comment',
	connection: 'ethernet',
	dismiss: 'thumb_up',
	error: 'error',
	group: 'domain',
	fullyRemediated: 'fully_remediated',
	remediate: 'first_aid',
	host: 'host',
	hosts: 'computers',
	investigation: 'investigation',
	ipAddress: 'ipaddress',
	link: 'link',
	malware: 'bug_report',
	semiAutomatic: 'semi_automatic',
	service: 'service',
	settings: 'settings',
	success: 'check_circle',
	timerange: 'timelapse',
};

const IMAGE_URLS: { [index: string]: string } = {
	adminAvatar: 'assets/images/icons/admin_avatar.svg',
	approve: 'assets/images/approve-all.png',
	automatic: 'assets/images/icons/automatic.png',
	certificate: 'assets/images/icons/certificate.svg',
	check: 'assets/images/icons/check-lg.svg',
	computer: 'assets/images/icons/computer-gray.svg',
	computerNetwork: 'assets/images/icons/computer_network.png',
	computersGray: 'assets/images/icons/computers-gray.svg',
	cyberark: 'assets/images/detection_systems/cyberark.png',
	delete: 'assets/images/icons/error-red.svg',
	document: 'assets/images/icons/file_cyan.svg',
	documents: 'assets/images/icons/documents.svg',
	discard: 'assets/images/discard-all.png',
	dismiss: 'assets/images/dismiss-all.png',
	email: 'assets/images/icons/email.svg',
	emailGray: 'assets/images/icons/email-gray.svg',
	error: 'assets/images/icons/error-red.svg',
	errorTriangle: 'assets/images/icons/error-white.svg',
	exclamation: 'assets/images/icons/exclamation.svg',
	file: 'assets/images/icons/file.svg',
	firstAid: 'assets/images/icons/first_aid.svg',
	group: 'assets/images/icons/computers.svg',
	hexadite: 'assets/images/hexadite.svg',
	hexaditeGraphStatus: 'assets/images/hexadite_graph_status.svg',
	hexaditeGray: 'assets/images/hexadite-gray.svg',
	hostAnalyzing: 'assets/images/icons/host-analyzing.svg',
	important: 'assets/images/favorite-active-icon.png',
	investigationRemediated: 'assets/images/investigation_status/investigation_remediated.svg',
	investigationRemediatedPartial: 'assets/images/investigation_status/investigation_remediated_partial.svg',
	investigationFailed: 'assets/images/investigation_status/investigation_failed.svg',
	investigationPaused: 'assets/images/investigation_status/investigation_paused.svg',
	investigationRunning: 'assets/images/investigation_status/investigation_running.svg',
	investigationRunningIcon: 'assets/images/icons/investigation_running.svg',
	investigationStopped: 'assets/images/investigation_status/investigation_stopped.svg',
	investigationPartial: 'assets/images/investigation_status/investigation_investigated_partial.svg',
	investigationBenign: 'assets/images/investigation_status/investigation_benign.svg',
	loader: 'assets/images/circle-loading.gif',
	location: 'assets/images/icons/location.svg',
	malware: 'assets/images/icons/malware.svg',
	network: 'assets/images/icons/network.png',
	printLogo: 'assets/images/logo_text.svg',
	networkCyan: 'assets/images/icons/network_cyan.png',
	remediation: 'assets/images/icons/remediation.svg',
	semiAutomatic: 'assets/images/icons/semi_automatic.png',
	sha1: 'assets/images/icons/sha1.svg',
	sha256: 'assets/images/icons/sha256.svg',
	stop: 'assets/images/icons/stop_circle.svg',
	retry: 'assets/images/retry-all.png',
	tools: 'assets/images/icons/tools.svg',
	undo: 'assets/images/icons/undo.svg',
	unprotected: 'assets/images/icons/unprotected.svg',
	uncoveredHost: 'assets/images/icons/uncovered_host.svg',
	userAvatar: 'assets/images/icons/user_avatar.svg',
};

Object.freeze(IMAGE_URLS);

const NOTIFICATIONS = Object.freeze({
	displayTime: 8, // 5 seconds
	maxCount: 5, // A maximum of 5 notifications can be displayed simultaneously
});

// Polling interval times, in seconds, for refreshing parts of the app:
const REFRESH_INTERVALS = Object.freeze({
	categoriesInvestigationsCount: 10,
	dashboard: 60,
	investigations: 10,
	pendingInvestigations: 10,
	systemIssues: 30,
});


export interface ScreenWidthBreakpoints {
	xs: number;
	small: number;
	medium: number;
	large: number;
	xl: number;
	xxl: number;
	xxxl: number;
}

const MS_SCREEN_SIZE_BREAKPOINTS: ScreenWidthBreakpoints = Object.freeze({
	xs: 320,
	small: 480,
	medium: 640,
	large: 940,
	xl: 1024,
	xxl: 1366,
	xxxl: 1920
});

export const widthBreakpointSmallerThan = (currentWidthBreakpoint: keyof ScreenWidthBreakpoints, maxWidthBreakpoint: number) => {
	return maxWidthBreakpoint >= MS_SCREEN_SIZE_BREAKPOINTS[currentWidthBreakpoint];
}

export const getMaxBreakpoint = memoize((currentValue: number, breakpoints: [string, number][]) => {
	for (const [key, value] of breakpoints) {
		if (currentValue < value) {
			return key;
		}
	}
	return breakpoints[breakpoints.length -1]
});

export let config = {
	color: COLORS,
	filterDates: FILTER_DATES,
	iconLibraries: ICON_LIBRARIES,
	icons: ICON_NAMES,
	images: IMAGE_URLS,
	investigationsPageSize: 10,
	notifications: NOTIFICATIONS,
	refreshIntervals: REFRESH_INTERVALS,
	msScreenSizeBreakpoints: MS_SCREEN_SIZE_BREAKPOINTS,
	widthBreakpointSmallerThan: widthBreakpointSmallerThan,
	getMaxBreakpoint: getMaxBreakpoint,
};
