import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationTaskStateValue } from './remediation-task-state-value.enum';

@ValueObject({
	singularName: 'Task arguments',
	pluralName: 'Tasks arguments',
})
export class RemediationTaskStatus extends ModelBase {
	@EntityField({
		data: 'value',
	})
	readonly value: RemediationTaskStateValue;

	@EntityField({
		data: 'lastModifiedOn',
	})
	readonly lastModifiedOn?: Date;
}
