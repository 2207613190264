import { Entity, EntityField, EntityModelBase, DataQuery, DataQuerySortDirection } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { OAuthAppRisk } from './oauth-app-risk.values';
import {Stream} from '../entity/stream.value-object'

export enum ApplicationType {
	CloudApplication = 'cloud-application',
	OAuthApplication = 'oauth-application',
}

export enum ApplicationTypeEnum {
	CloudApplication = 8192,
	OAuthApplication = 16384,
}

export const isApplicationAsset = (type: string) =>
	type === ApplicationType.CloudApplication || type === ApplicationType.OAuthApplication;

@Entity({
	singularName: 'App',
	pluralName: 'Apps',
	endpoint: 'apps',
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) {
			return {};
		}

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) {
			pageSettings.pageIndex = dataQuery.page;
		}

		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		return Object.assign(
			{},
			dataQuery.where,
			pageSettings,
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
						sortByField: dataQuery.sortBy[0].field,
						sortOrder:
							dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined
		);
	},
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 60_000,
		max: 10,
	},
})
export class Application extends EntityModelBase<number | string> {
	@EntityField({ data: ['ApplicationId', 'appId', 'oAuthAppId', 'applicationId'] })
	id: string;

	@EntityField({ data: ['ApplicationClientId'] })
	applicationClientId?: string;

	@EntityField({ data: ['OauthAppId '] })
	oAuthAppId?: string;

	@EntityField({ data: ['ActiveAlerts', 'activeAlerts'] })
	activeAlerts?: number;

	@EntityField({ data: ['TotalAlerts', 'totalAlerts'] })
	totalAlerts?: number;

	@EntityField({ data: ['stream', 'Stream'] })
	stream?: Stream;

	@EntityField({
		data: ['Type', 'type'],
		parse: (typeData) => {
			if (typeData === ApplicationTypeEnum.CloudApplication) return ApplicationType.CloudApplication;
			else if (typeData === ApplicationTypeEnum.OAuthApplication)
				return ApplicationType.OAuthApplication;
			return typeData;
		},
	})
	type: string;

	@EntityField({
		data: ['Name', 'name'],
		parse: (nameField, applicationData) => {
			if (
				applicationData.Type === ApplicationTypeEnum.CloudApplication ||
				applicationData.type === ApplicationType.CloudApplication
			)
				return applicationData.instanceName || applicationData.InstanceName || nameField;
			return nameField;
		} })
	name: string;

	@EntityField({ data: 'PublisherName' })
	publisher: string;

	@EntityField({ data: ['RiskLevel', 'risk'], required: false })
	readonly riskLevel?: OAuthAppRisk;

	@EntityField({ data: ['OAuthObjectId', 'oAuthObjectId'], required: false })
	readonly oAuthObjectId?: string;

	@EntityField({ data: ['InstanceName', 'instanceName'], required: false })
	instanceName: string;

	@EntityField({ data: 'InstanceId', required: false })
	instanceId: number;

	@EntityField({ data: 'SaasId', required: false })
	saasId: number;

	get linkToAppPage(): string {
		if(this.type == ApplicationType.OAuthApplication) return `/#/app?oauthAppId=${this.id}`
		return (this.stream) ? `/#/app?appId=${this.id}&streamId=${this.stream.id}` : `/#/services/${Application.getAppstanceFromSaaSIdAndInstanceId(this.saasId, this.instanceId) || this.id}`;
	}

	static NULLSTANCE_INSTANCE_ID = 2047;

	// This logic was copied from MCAS FE code - in the future we need to consider calculating this once in the BE and passing it on app entity
	static getAppstanceFromSaaSIdAndInstanceId(saasId: number, instanceId: number): number {
		if (!saasId) {
			return null;
		}

		if (instanceId === null) {
			return Application.getAppstanceFromSaaSIdAndInstanceId(
				saasId,
				Application.NULLSTANCE_INSTANCE_ID
			);
		}
		if (isNaN(instanceId)) {
			return saasId;
		}

		if (instanceId > Application.NULLSTANCE_INSTANCE_ID) {
			throw new Error(`instance ${instanceId} is too big`);
		}

		return (instanceId << 20) | saasId;
	}
}
