import { EntityField, ParisConfig, ModelBase, ValueObject } from '@microsoft/paris';
import { AlertIocContextBase } from './alert-ioc-context-base.interface';

@ValueObject({
	singularName: 'Alert Url Context Item',
	pluralName: 'Alert Url Context Items',
})
export class AlertUrlContext extends ModelBase implements AlertIocContextBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'Url' })
	name: string;

	@EntityField({ data: 'Url' })
	url: string;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({
		data: 'Sha1',
		parse: data => (data && data.toLowerCase() !== 'null' ? data : null),
	})
	sha1: string;
}
