import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'capitalizeString' })
export class CapitalizeStringPipe implements PipeTransform {
	transform(value: string) {
		return value
			.split(' ')
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ');
	}
}
