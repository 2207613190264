import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Network devices component filters',
	pluralName: 'Network devices component filters',
	readonly: true,
})
export class NetworkFiltersFields extends ModelBase {
	@EntityField() name: string;
	@EntityField() filters: { name: string, children: string[] }[];
}