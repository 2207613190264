import { I18nService } from '@wcd/i18n';
import { HealthStatus, OsPlatform, OsVersion, AvStatus } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { KnownColorsService } from '../shared/services/known-colors.service';
import { Injectable } from '@angular/core';
import { ReportMapping } from './services/machine-protection-report.service';

@Injectable({
	providedIn: 'root',
})
export class MachineProtectionStatusMapping {

	private readonly _healthStatusMapping: ReportMapping<HealthStatus>;
	private readonly _avStatusMapping: ReportMapping<AvStatus>;
	private readonly _osPlatformMapping: ReportMapping<OsPlatform>;
	private readonly _osVersionMapping: ReportMapping<OsVersion>;

	constructor(private i18nService: I18nService, private knownColorsService: KnownColorsService, private featuresService: FeaturesService) {

		this._healthStatusMapping = {
			[HealthStatus.Active]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['green'],
					trend: this.knownColorsService.knownColorsMap['green'],
				},
				label: this.i18nService.get(`reporting.machineReport.healthStatus.active`),
				machineListFilter: 'Active',
			},
			[HealthStatus.ImpairedCommunication]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['orangeLighter'],
					trend: this.knownColorsService.knownColorsMap['orangeLighter'],
				},
				label: this.i18nService.get(`reporting.machineReport.healthStatus.impairedCommunication`),
				machineListFilter: 'MisconfiguredCyberNoCnc',
			},
			[HealthStatus.Inactive]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['red'],
					trend: this.knownColorsService.knownColorsMap['red'],
				},
				label: this.i18nService.get(`reporting.machineReport.healthStatus.inactive`),
				machineListFilter: 'Inactive',
			},
			[HealthStatus.NoSensorData]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['neutralTertiary'],
					trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
				},
				label: this.i18nService.get(`reporting.machineReport.healthStatus.noSensorData`),
				machineListFilter: 'MisconfiguredCncNoCyber',
			},
		};

		this._avStatusMapping = {
			[AvStatus.Updated]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['green'],
					trend: this.knownColorsService.knownColorsMap['green'],
				},
				label: this.i18nService.get(`reporting.machineReport.antivirusStatus.updated`),
				machineListFilter: '', // No filter for Updated on machine list. Empty values are filtered out on drill down to machine list
				priority: 1,
			},
			[AvStatus.NotUpdated]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['orangeLighter'],
					trend: this.knownColorsService.knownColorsMap['orangeLighter'],
				},
				label: this.i18nService.get(`reporting.machineReport.antivirusStatus.notUpdated`),
				machineListFilter: 'AntiVirusSignatureVersion',
				priority: 2,
			},
			[AvStatus.Disabled]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['red'],
					trend: this.knownColorsService.knownColorsMap['red'],
				},
				label: this.i18nService.get(`reporting.machineReport.antivirusStatus.disabled`),
				machineListFilter: 'AntiVirusEnabled',
				priority: 3,
			},
			[AvStatus.Unknown]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['neutralTertiary'],
					trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
				},
				label: featuresService.isEnabled(Feature.UseTvmMachinesAvStatus) ? this.i18nService.get(`reporting.machineReport.antivirusStatus.unknownNew`) :
																					this.i18nService.get(`reporting.machineReport.antivirusStatus.unknown`),
				machineListFilter: 'AntiVirusNotReporting',
				priority: 4,
			},
		};

		this._osPlatformMapping = {
			[OsPlatform.Windows11]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['greenLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows11`),
				machineListFilter: 'Windows11',
			},
			[OsPlatform.Windows10]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['purpleLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows10`),
				machineListFilter: 'Windows10',
			},
			[OsPlatform.Windows8Blue]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['green'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows8blue`),
				machineListFilter: 'Windows8Blue',
			},
			[OsPlatform.Windows8]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['tealLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows8`),
				machineListFilter: 'Windows8',
			},
			[OsPlatform.Windows7]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['blue'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows7`),
				machineListFilter: 'Windows7',
			},
			[OsPlatform.WindowsServer2022]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['orangeLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windowsServer2022`),
				machineListFilter: 'WindowsServer2022',
			},
			[OsPlatform.WindowsServer2019]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['magenta'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windowsServer2019`),
				machineListFilter: 'WindowsServer2019',
			},
			[OsPlatform.WindowsServer2016]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['orangeLighter'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windowsServer2016`),
				machineListFilter: 'WindowsServer2016',
			},
			[OsPlatform.WindowsServer2012R2]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['purple'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windowsServer2012R2`),
				machineListFilter: 'WindowsServer2012R2',
			},
			[OsPlatform.WindowsServer2008R2]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windowsServer2008R2`),
				machineListFilter: 'WindowsServer2008R2',
			},
			[OsPlatform.Windows10WVD]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['blueLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.windows10WVD`),
				machineListFilter: 'Windows10WVD',
			},
			[OsPlatform.Linux]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['magentaLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.linux`),
				machineListFilter: 'Linux',
			},
			[OsPlatform.macOS]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['teal'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.macOS`),
				machineListFilter: 'macOS',
			},
			[OsPlatform.iOS]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['orange'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.iOS`),
				machineListFilter: 'iOS',
			},
			[OsPlatform.Android]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['greenDark'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.android`),
				machineListFilter: 'Android',
			},
			[OsPlatform.Other]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['blue'],
					trend: this.knownColorsService.knownColorsMap['blueMid'],
				},
				label: this.i18nService.get(`reporting.machineReport.osPlatform.other`),
				machineListFilter:
					'WindowsXp,None,Windows2003,Windows2008,Windows2016,Windowsvista,Windows8blueserver,Windows8server,Windowsxpembedded,Windows2012',
			},
		};

		this._osVersionMapping = {
			[OsVersion.RS1]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['magenta'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs1`),
				machineListFilter: '1607',
			},
			[OsVersion.RS2]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['tealLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs2`),
				machineListFilter: '1703',
			},
			[OsVersion.RS3]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['purpleLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs3`),
				machineListFilter: '1709',
			},
			[OsVersion.RS4]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['purple'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs4`),
				machineListFilter: '1803',
			},
			[OsVersion.RS5]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['orangeLighter'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs5`),
				machineListFilter: '1809',
			},
			[OsVersion.RS6]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['green'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs6`),
				machineListFilter: '1903',
			},
			[OsVersion.RS7]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['blue'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs7`),
				machineListFilter: '1909',
			},
			[OsVersion.RS8]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['orange'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs8`),
				machineListFilter: '2004',
			},
			[OsVersion.RS9]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['magentaLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs9`),
				machineListFilter: '20H2',
			},
			[OsVersion.RS10]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['greenLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs10`),
				machineListFilter: '21H1',
			},
			[OsVersion.RS11]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['blueLight'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.rs11`),
				machineListFilter: '21H2',
			},
			[OsVersion.Future]: {
				knownColorsMap: {
					daily: this.knownColorsService.knownColorsMap['tealLight'],
					trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
				},
				label: this.i18nService.get(`reporting.machineReport.osVersion.future`),
				machineListFilter: 'Future',
			},
		};
	}

	public get healthStatusMapping(): DeepReadonly<ReportMapping<HealthStatus>> {
		return this._healthStatusMapping;
	}

	public get avStatusMapping(): DeepReadonly<ReportMapping<AvStatus>> {
		return this._avStatusMapping;
	}

	public get osPlatformMapping(): DeepReadonly<ReportMapping<OsPlatform>> {
		return this._osPlatformMapping;
	}

	public get osVersionMapping(): DeepReadonly<ReportMapping<OsVersion>> {
		return this._osVersionMapping;
	}
}

type DeepReadonly<T> = { readonly [P in keyof T]: DeepReadonly<T[P]> };
