var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { InvestigationStatus } from '../../investigation/investigation-status.entity';
var ɵ0 = function (config, query) { return query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr; }, ɵ1 = function (itemId, entity, config, params) {
    var id = String(itemId);
    if (id.startsWith('CLR')) {
        return "cloud/live_response/sessions/" + itemId;
    }
    return (String(itemId).startsWith('LR') ? 'v2/live_response/sessions' : 'live_response/sessions') +
        ("/" + itemId);
}, ɵ2 = function (session, entity, config, options) {
    if (session.useV3Api) {
        return 'cloud/live_response/create_session';
    }
    return session.useV2Api ? 'v2/live_response/create_session' : 'live_response/create_session';
}, ɵ3 = function (session) { return ({
    end_date: session.endDate || null,
    machine_last_seen: session.machine.lastSeen || null,
    machine_id: session.machine.id,
    session_status: session.status || null,
    start_date: session.startDate || null,
}); }, ɵ4 = function (machineId, rawData) {
    var machineInfo = rawData['machine_info'];
    if (machineInfo) {
        return machineInfo.machine_id;
    }
    return machineId;
}, ɵ5 = function (fieldData) { return String(fieldData).startsWith('LR'); }, ɵ6 = function (fieldData) { return String(fieldData).startsWith('CLR'); };
var LiveResponseSession = /** @class */ (function (_super) {
    __extends(LiveResponseSession, _super);
    function LiveResponseSession() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'session_id' }),
        __metadata("design:type", Object)
    ], LiveResponseSession.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: 'machine_id',
            parse: ɵ4,
        }),
        __metadata("design:type", Machine)
    ], LiveResponseSession.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'session_status' }),
        __metadata("design:type", InvestigationStatus)
    ], LiveResponseSession.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'user' }),
        __metadata("design:type", String)
    ], LiveResponseSession.prototype, "initiatingUser", void 0);
    __decorate([
        EntityField({ data: 'start_date' }),
        __metadata("design:type", Date)
    ], LiveResponseSession.prototype, "startDate", void 0);
    __decorate([
        EntityField({ data: 'end_date' }),
        __metadata("design:type", Date)
    ], LiveResponseSession.prototype, "endDate", void 0);
    __decorate([
        EntityField({ data: 'session_id', parse: ɵ5 }),
        __metadata("design:type", Boolean)
    ], LiveResponseSession.prototype, "useV2Api", void 0);
    __decorate([
        EntityField({ data: 'session_id', parse: ɵ6 }),
        __metadata("design:type", Boolean)
    ], LiveResponseSession.prototype, "useV3Api", void 0);
    LiveResponseSession = __decorate([
        Entity({
            singularName: 'Session',
            pluralName: 'Sessions',
            baseUrl: ɵ0,
            endpoint: 'NOOP',
            parseItemQuery: ɵ1,
            parseSaveQuery: ɵ2,
            serializeItem: ɵ3,
        })
    ], LiveResponseSession);
    return LiveResponseSession;
}(EntityModelBase));
export { LiveResponseSession };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
