<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="recommendationException" class="wcd-scroll-vertical wcd-margin-medium-top">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">

		<div class="tvm-list__header">{{'tvm.recommendationException.details.title' | i18n}}</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.securityRecommendation.relatedRemediation.status' | i18n}}</dt>
			<dd>{{('tvm.recommendationException.status.' + recommendationException.status.value | i18n) || '-'}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.securityRecommendation.recommendationExceptionCreation.justification' | i18n}}</dt>
			<dd>{{getJustificationLabel(recommendationException.exceptionJustification)}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.securityRecommendation.recommendationExceptionCreation.justificationContext' | i18n}}</dt>
			<dd>{{recommendationException.justificationContext}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.recommendationException.type' | i18n}}</dt>
			<dd>{{remediationType}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.common.createdBy' | i18n}}</dt>
			<dd>{{recommendationException.requester?.email || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.securityRecommendation.relatedRemediation.createdOn' | i18n}}</dt>
			<dd>{{(recommendationException.creationTime | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.recommendationException.expiry.date' | i18n}}</dt>
			<dd>{{(recommendationException.expiringOn | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div class="tvm-list__key_val_item" *ngIf="recommendationException.status.value === 'Cancelled'">
			<dt>{{'tvm.securityRecommendation.relatedRemediation.canceledOn' | i18n}}</dt>
			<dd>{{(recommendationException.status.lastModifiedOn | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div *ngIf="recommendationException.exceptionArgs.type === 'SoftwarePatch'" class="tvm-list__key_val_item">
			<dt>{{'tvm.common.relatedComponent' | i18n}}</dt>
			<dd>
				<a
					data-track-id="exceptionNavigateToRelatedComponent"
					data-track-type="Navigation"
					(click)="navigateToRelatedComponent()">
					{{relatedComponent}}
				</a>
			</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.remediationTask.ticket.dueDate' | i18n}}</dt>
			<dd>{{this.remainingDays(recommendationException)}}</dd>
		</div>

		<div class="tvm-list__block_item">
			<a
				data-track-id="navigateToRecommendations"
				data-track-type="Navigation"
				(click)="navigateToRecommendations()"
				>{{'tvm.common.viewRecommendation' | i18n}}
			</a>
		</div>

	</div>
</div>
