
		<div
			*ngIf="(fileVerdicts$ | async) as fileVerdicts"
			[ngClass]="{
				'wcd-flex-vertical wcd-flex-items-start': showLinkBelow,
				'wcd-flex-center-vertical wcd-flex-wrap': !showLinkBelow
			}"
		>
			<span class="wcd-padding-small-right">{{ getFileVerdictText(fileVerdicts) }}</span>
			<a
				*ngIf="fileVerdicts.length !== 0 && showFileVerdictPanelLink"
				class="wcd-font-weight-regular"
				(click)="showFileVerdictPanel(fileVerdicts)"
			>
				{{ 'files.entityDetails.sections.detections.fields.malwareDetected.seeDetails' | i18n }}
			</a>
		</div>
	