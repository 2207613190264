import { SevilleModule } from '../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

SevilleModule.controller('seville.onboarding.preferences.processing', onboardingProcessingController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.processing', {
			url: '/processing',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Creating your Microsoft Defender for Endpoint cloud instance</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position">
                        <div ng-if="!processing.isErrorOccured">
                                <uib-progressbar class="onboarding-progressbar onboarding-progressbar-color" value="dynamic"></uib-progressbar>
                                <p align="center">
                                    <img style="align-items: center" class="onboarding-progress-image img-responsive" ng-src="/assets/images/linear-loader.gif" />
                                </p>
                                <br />
                        </div>
                        <div ng-if="processing.isErrorOccured" class="page-header-title" ng-bind-html="processing.statusLabel">
                            <i class="icon icon-md icon-Error"></i>
                        </div>
                    </div>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.processing',
					controllerAs: 'processing',
				},
			},
		});
	},
]);

onboardingProcessingController.$inject = [
	'$scope',
	'$state',
	'$http',
	'appConfig',
	'$interval',
	'$window',
	'appInsights',
];

function onboardingProcessingController(
	$scope,
	$state,
	$http,
	appConfig: AppConfigService,
	$interval,
	$window,
	appInsights: AppInsightsService
) {
	var vm = this;
	vm.statusLabel = '';

	if (!$scope.$parent.stepPermissionsVisited) {
		$state.go('onboarding.permissions');
	} else {
		vm.isErrorOccured = false;
		vm.onboardingBaseUrl = appConfig.serviceUrls && appConfig.serviceUrls.onboarding;
		vm.serverOnboardingBaseUrl = appConfig.serviceUrls && appConfig.serviceUrls.serveronboarding;

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepProcessingVisited = true;
			$scope.$parent.stepPreferencesInProgress = true;
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
		});

		$scope.max = 98;
		$scope.dynamic = 0;
		$scope.counter = 0;

		$scope.provisioningHasRetried = false;

		var ProvisioninggStatus = {
			Success: 1,
			Failure: 2,
			InProgress: 3,
		};

		CreateTenant();
	}

	function SetError() {
		vm.statusLabel =
			"An error occurred. Please <a href='https://support.microsoft.com/en-us/assistedsupportproducts' target='_blank'>Contact support</a>.";
		vm.isErrorOccured = true;
		$scope.provisioningHasRetried = false;
	}

	function DoProgressbar(setpValue) {
		$interval(
			function() {
				if ($scope.dynamic <= $scope.max) {
					$scope.dynamic = $scope.dynamic + 1;
				}
			},
			1000,
			setpValue
		);
	}

	function ProvisionOmsWorkspace() {
		DoProgressbar(15);

		if ($scope.$parent.currentOnboardingState < $scope.$parent.TenantOnboardingStatus.OmsProvisioned) {
			$http
				.post(vm.serverOnboardingBaseUrl + '/ProvisionOmsAsync', {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							ProvisionSevilleEndpointPackages();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionOmsWorkspace',
							});
						} else {
							console.log('status: ' + response.status);
							ProvisionSevilleEndpointPackages();
							// TODO: Set error when this will be critical step
							// SetError();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionOmsWorkspace',
								FailureType: 'API',
							});
						}
					},
					function(response) {
						console.log('status: ' + response.status);
						ProvisionSevilleEndpointPackages();
						// TODO: Set error when this will be critical step
						// SetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'ProvisionOmsWorkspace',
							FailureType: 'Request',
						});
					}
				);
		} else {
			ProvisionSevilleEndpointPackages();
		}
	}

	function ProvisionSevilleEndpointPackages() {
		DoProgressbar(20);

		$http
			.get(vm.onboardingBaseUrl + '/ProvisionSevilleEndpointPackages', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						$state.onboardingStepPreferencesSucceeded = true;
						$scope.$parent.stepPermissionsDone = true;
						$scope.$parent.stepWelcomeDone = true;
						$scope.$parent.stepPreferencesDone = true;
						$state.go('onboarding.download');
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'ProvisionSevilleEndpointPackages',
						});
					} else {
						console.log('status: ' + response.status);
						SetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'ProvisionSevilleEndpointPackages',
							FailureType: 'API',
						});
					}
				},
				function(response) {
					console.log('status: ' + response.status);
					SetError();
					appInsights.trackEvent('ButtonClick', {
						ButtonType: 'CreateTenant',
						Page: 'OnboardingProcessing',
						ResponseStatus: response.status,
						Step: 'ProvisionSevilleEndpointPackages',
						FailureType: 'Request',
					});
				}
			);
	}

	function GetProvisioningStatus() {
		$http
			.get(vm.onboardingBaseUrl + '/GetProvisioningStatusAsync', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						const status: number = response.data;
						if (status === ProvisioninggStatus.Success) {
							$scope.provisioningHasRetried = false;
							ProvisionCustomerTiDataAccess();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'GetProvisioningStatusAsync',
							});
							return;
						}
						if (status === ProvisioninggStatus.Failure) {
							if ($scope.provisioningHasRetried === true) {
								SetError();
								return;
							}

							$scope.provisioningHasRetried = true;
							ProvisionSevilleDataAccess();

							$scope.dynamic = 20;
							return;
						}
						if (status === ProvisioninggStatus.InProgress) {
							$window.setTimeout(function() {
								GetProvisioningStatus();
							}, 15000);
							DoProgressbar(4);
						}
					} else {
						console.log('status: ' + response.status);
						SetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'GetProvisioningStatusAsync',
							FailureType: 'API',
						});
						return;
					}
				},
				function(response) {
					console.log('status: ' + response.status);
					SetError();
					appInsights.trackEvent('ButtonClick', {
						ButtonType: 'CreateTenant',
						Page: 'OnboardingProcessing',
						ResponseStatus: response.status,
						Step: 'GetProvisioningStatusAsync',
						FailureType: 'Request',
					});
					return;
				}
			);
	}

	function RetryProvisionCustomerTiOrSetError() {
		if ($scope.provisioningHasRetried === true) {
			// SetError();
			// SetError once we decide to fail onboarding process on TI
			$scope.provisioningHasRetried = false;
			ProvisionOmsWorkspace();
			return;
		}

		ProvisionCustomerTiDataAccess();
		$scope.provisioningHasRetried = true;
		return;
	}

	function ProvisionCustomerTiDataAccess() {
		DoProgressbar(4);
		if (
			$scope.$parent.currentOnboardingState <
			$scope.$parent.TenantOnboardingStatus.CustomerTiProvisioned
		) {
			$http
				.post(vm.onboardingBaseUrl + '/ProvisionCustomerTiAccessAsync', {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							ProvisionOmsWorkspace();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionCustomerTiAccess',
							});
						} else {
							RetryProvisionCustomerTiOrSetError();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionCustomerTiAccess',
								FailureType: 'API',
							});
						}
					},
					function(response) {
						RetryProvisionCustomerTiOrSetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'ProvisionDataAccessAsync',
							FailureType: 'Request',
						});
					}
				);
		} else {
			ProvisionOmsWorkspace();
		}
	}

	function ProvisionSevilleDataAccess() {
		DoProgressbar(5);
		if ($scope.$parent.currentOnboardingState < $scope.$parent.TenantOnboardingStatus.Provisioned) {
			$http
				.post(vm.onboardingBaseUrl + '/ProvisionDataAccessAsync', {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							$window.setTimeout(function() {
								GetProvisioningStatus();
							}, 8000);
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionDataAccessAsync',
							});
						} else {
							SetError();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'ProvisionDataAccessAsync',
								FailureType: 'API',
							});
						}
					},
					function(response) {
						SetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'ProvisionDataAccessAsync',
							FailureType: 'Request',
						});
					}
				);
		} else {
			ProvisionCustomerTiDataAccess();
		}
	}

	function IndustryToString(industryModel) {
		var industryString = '';
		industryModel.forEach(function(element, index, array) {
			industryString = industryString.concat(element.id, '-');
		});

		var industryStringData = industryString.substring(0, industryString.length - 1);
		return industryStringData;
	}

	function CreateTenant() {
		appInsights.trackEvent('ButtonClick', {
			ButtonType: 'CreateTenant',
			Page: 'OnboardingProcessing',
			Step: 'Start',
		});
		if ($scope.$parent.currentOnboardingState < $scope.$parent.TenantOnboardingStatus.Configured) {
			DoProgressbar(10);

			vm.tenantPreferences = {
				GeoRegion: $scope.$parent.geo,
				RetentionPolicy: $scope.$parent.retentionPolicy,
				MinEndpoints: $scope.$parent.minEndpointsNumber,
				MaxEndpoints: $scope.$parent.maxEndpointsNumber,
				OptInPublicPreviewFeatures: $scope.$parent.optInPreview,
				CommunicationsAndMedia: IndustryToString($scope.$parent.CommunicationsAndMedia),
				FinancialServices: IndustryToString($scope.$parent.FinancialServices),
				ManufacturingAndResources: IndustryToString($scope.$parent.ManufacturingAndResources),
				PublicSector: IndustryToString($scope.$parent.PublicSector),
				RetailConsumerProductsAndService: IndustryToString(
					$scope.$parent.RetailConsumerProductsAndService
				),
			};

			$http
				.post(vm.onboardingBaseUrl + '/SaveTenantPreferences', vm.tenantPreferences, {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							$scope.$parent.isDemoScriptEnabled = response.data.IsDemoScriptEnabled;
							ProvisionSevilleDataAccess();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'SaveTenantPreferences',
							});
						} else {
							console.log('status: ' + response.status);
							SetError();
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'CreateTenant',
								Page: 'OnboardingProcessing',
								ResponseStatus: response.status,
								Step: 'SaveTenantPreferences',
								FailureType: 'API',
							});
						}
					},
					function(response) {
						console.log('status: ' + response.status);
						SetError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'CreateTenant',
							Page: 'OnboardingProcessing',
							ResponseStatus: response.status,
							Step: 'SaveTenantPreferences',
							FailureType: 'Request',
						});
					}
				);
		} else {
			ProvisionSevilleDataAccess();
		}
	}
}
