import { Entity } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Security Group',
	pluralName: 'Security Groups',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsSecurityGroup,
})
export class AirsSecurityGroup extends AirsEntity {}
