
		<div>
			<div [innerHtml]="settings?.instructionsText"></div>
			<div>
				<textarea
					*ngIf="settings?.commandTitle"
					[rows]="1"
					id="detection-test-command-title"
					class="code-for-copy wcd-padding-small-all"
					readonly
					[ngClass]="settings?.commandClassName"
					>{{ settings?.commandTitle }}</textarea
				>
				<textarea
					*ngIf="settings?.command"
					[rows]="settings?.commandTextRows ? settings?.commandTextRows : 3"
					id="detection-test-command"
					[attr.aria-label]="'endpointManagement_sense_onboarding_detection_test_command' | i18n"
					class="code-for-copy wcd-padding-small-all"
					readonly
					[ngClass]="settings?.commandClassName"
					>{{ settings?.command }}</textarea
				>
				<copy-to-clipboard
					[content]="settings?.command"
					[settings]="{ className: 'new-line' }"
					[originId]="'detection-test-command'"
				>
				</copy-to-clipboard>
			</div>
			<div *ngIf="settings?.resultText" class="wcd-margin-top" [innerHtml]="settings?.resultText"></div>
		</div>
	