import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	Input, OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { IButtonStyles, ICommandBarStyles, IIconStyles, IStyle } from 'office-ui-fabric-react';
import { EntityModelBase } from '@microsoft/paris';
import { EntityType } from '../../models/entity-type.interface';
import { EntityDetailsComponentBase } from '../entity-details/entity-details.component.base';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { EntityMinimizedDetailsComponentBase } from '../entity-minimized/entity-minimized-details.component.base';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { EntityHeaderBottomComponentBase } from '../entity-header-bottom/entity-header-bottom.component.base';
import { merge, Observable, of, Subject } from 'rxjs';
import { toObservable } from '../../../utils/rxjs/utils';
import { map, share, shareReplay, take } from 'rxjs/operators';
import { ServiceUrlsService } from '@wcd/app-config';
import { Tag } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { sccHostService } from '@wcd/scc-interface';

const buttonSize = 48;
let lastId = 0;

const entityIconStyle: IIconStyles = {
	root: {
		fontSize: '42px',
		lineHeight: '72px',
		width: '72px',
		textAlign: 'center',
		verticalAlign: 'super',
		marginLeft: '2px',
	},
};

const defaultButtonStyle: IStyle = {
	padding: 0,
	top: 0,
	minWidth: buttonSize,
	height: buttonSize,
	backgroundColor: 'transparent',
	position: 'absolute',
};

const leftPositionedSidePanelButtonStyles: IButtonStyles = {
	root: {
		...defaultButtonStyle,
		left: 0,
	},
};

const rightPositionedSidePanelButtonStyles: IButtonStyles = {
	root: {
		...defaultButtonStyle,
		right: 0,
	},
};

const defaultHiddenSidePanelButtonsStyles: ICommandBarStyles = {
	root: {
		...defaultButtonStyle,
		position: 'relative',
	},
};

@Component({
	selector: 'entity-details-pane',
	templateUrl: './entity-details-pane.component.html',
	styleUrls: ['./entity-details-pane.component.scss'],
})
export class EntityDetailsPanelComponent<
	TEntity extends EntityModelBase<TId>,
	TId extends string | number = string
> implements OnInit, OnChanges<EntityDetailsPanelComponent<TEntity, TId>> {
	@Input() entity: TEntity;
	@Input() entityType: EntityType<TEntity>;
	@Input() entityPageViewMode: EntityPageViewMode;
	@Input() rightSidePanel: boolean = false;
	@Input() removePaddingRight: boolean = false;
	// This is here for backward compatibility, while we need to support not-"asset"/"modern" pages.
	// It can be removed once all slices will get new pages
	@Input() topPadded: boolean = true;
	@Input() showToggleButton: boolean = false;
	@Input() toggleButtonPosition: 'left' | 'right' = 'right';
	@Input() isNarrowLayout: boolean;
	@Input() showTitle: boolean = true;


	minimized: boolean = false;
	readonly styleSettings = {
		leftArrowIconName: 'ChevronLeft',
		rightArrowIconName: 'ChevronRight',
		leftPositionedSidePanelButtonStyles: leftPositionedSidePanelButtonStyles,
		rightPositionedSidePanelButtonStyles: rightPositionedSidePanelButtonStyles,
		hiddenPanelButtons: defaultHiddenSidePanelButtonsStyles,
	};

	readonly paneUniqueId = `entity-details-pane-${++lastId}`;

	toggleIconName: string = this.styleSettings.leftArrowIconName;
	toggleButtonStyleSettings: ICommandBarStyles = this.styleSettings.rightPositionedSidePanelButtonStyles;
	entityPageViewModeEnum = EntityPageViewMode;

	@ViewChild('minimizedEntityViewPlaceholder', { read: ViewContainerRef, static: true })
	minimizedEntityViewPlaceholder: ViewContainerRef;
	minimizedComponentRef: ComponentRef<EntityMinimizedDetailsComponentBase<TEntity>>;

	@ViewChild('entityDetailsPlaceholder', { read: ViewContainerRef, static: true })
	entityDetailsPlaceholder: ViewContainerRef;
	detailsComponentRef: ComponentRef<EntityDetailsComponentBase<TEntity>>;

	@ViewChild('bottomHeaderEntityViewPlaceHolder', { read: ViewContainerRef, static: false })
	set bottomHeaderEntityViewPlaceHolder(bottomHeaderEntityViewPlaceHolderContainertRef: ViewContainerRef) {
		this.setBottomHeaderComponent(bottomHeaderEntityViewPlaceHolderContainertRef);
	}
	headerBottomComponentRef: ComponentRef<EntityHeaderBottomComponentBase<TEntity>>;
	iconImageUrl$: Observable<string>;
	iconName: string;
	iconCssClass: string;
	subTitle: string;
	tags$: Observable<ReadonlyArray<Tag>>;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();

	title: string;
	entityTitle: string;
	entityIconStyle = entityIconStyle;
	isScc = sccHostService.isSCC;

	constructor(
		private readonly resolver: ComponentFactoryResolver,
		private readonly serviceUrls: ServiceUrlsService,
		public i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
	) {}

	ngOnInit() {
		this.entityTitle = this.i18nService.get('dataview_entity_details_panel_button_title');
		if (this.entity && this.entityType) {
			const entityTypeDisplayName = this.i18nService.get(`entity_type_display_name_${this.entityType.id}`, {},true);
			if (entityTypeDisplayName !== '') {
				this.entityTitle = this.i18nService.get('dataview_entity_details_panel_title',
					{ entityTitle: entityTypeDisplayName })
			}
		}
	}

	ngOnChanges(changes: TypedChanges<EntityDetailsPanelComponent<TEntity, TId>>) {
		if (this.entity && this.entityType) {
			this.setEntityDetailsComponent();
			this.setMinimizedDetailsComponent();

			this.iconName = this.entityType.getIcon
				? this.entityType.getIcon([this.entity])
				: this.entityType.icon;
			this.iconCssClass =
				this.entityType.getIconCssClass && this.entityType.getIconCssClass([this.entity]);
			this.subTitle = this.entityType.getSubtitle && this.entityType.getSubtitle([this.entity]);

			this.iconImageUrl$ = this.entityType.getImage
				? toObservable(this.entityType.getImage([this.entity])).pipe(
						map(imageUrl => `url(${this.serviceUrls.assetsBaseUrl}${imageUrl})`),
						take(1),
						share()
				  )
				: of(null);

			if (this.entityType.getTags) {
				this.tags$ = merge(
					toObservable(this.entityType.getTags([this.entity])),
					this._tagsSubject$
				).pipe(shareReplay(1));
			}
			this.title = this.entityType.getEntityName(this.entity);
		}
		if (
			changes.toggleButtonPosition &&
			(changes.toggleButtonPosition.firstChange ||
				changes.toggleButtonPosition.currentValue !== this.toggleButtonPosition)
		) {
			this.updateButtonStyles();
		}
	}

	toggleSidePanel() {
		this.minimized = !this.minimized;
		this.updateButtonStyles();
		this.fireResizeEvent();
		this.announceToggleSidePanel();
	}

	fireResizeEvent() {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		}, 100);
	}

	private setEntityDetailsComponent() {
		if (!(this.detailsComponentRef && this.detailsComponentRef.instance)) {
			const injector = Injector.create({
					providers: [],
					parent: this.entityDetailsPlaceholder.parentInjector,
				}),
				factory: ComponentFactory<
					EntityDetailsComponentBase<TEntity>
				> = this.resolver.resolveComponentFactory<EntityDetailsComponentBase<TEntity>>(
					this.entityType.entityDetailsComponentType
				);

			this.detailsComponentRef = factory ? factory.create(injector) : null;

			Object.assign(this.detailsComponentRef.instance, {
				asKeyValueList: false,
				mode: EntityDetailsMode.EntityPage,
				entityPageViewMode: this.entityPageViewMode,
			});

			this.entityDetailsPlaceholder.insert(this.detailsComponentRef.hostView);
		}
		this.detailsComponentRef.instance.setEntity(this.entity);
	}

	private setMinimizedDetailsComponent() {
		if (
			!(this.minimizedComponentRef && this.minimizedComponentRef.instance) &&
			this.entityType.entityMinimizedComponentType
		) {
			const factory = this.resolver.resolveComponentFactory(
				this.entityType.entityMinimizedComponentType
			);
			this.minimizedComponentRef = this.minimizedEntityViewPlaceholder.createComponent(factory);

			this.minimizedEntityViewPlaceholder.insert(this.minimizedComponentRef.hostView);
			this.minimizedComponentRef.instance.setEntity(this.entity);
		}
	}

	private setBottomHeaderComponent(bottomHeaderEntityViewPlaceHolderContainertRef: ViewContainerRef) {
		if (
			!(this.headerBottomComponentRef && this.headerBottomComponentRef.instance) &&
			this.entityType.entityHeaderBottomComponentType && bottomHeaderEntityViewPlaceHolderContainertRef
		) {
			const factory = this.resolver.resolveComponentFactory(
				this.entityType.entityHeaderBottomComponentType
			);
			this.headerBottomComponentRef = bottomHeaderEntityViewPlaceHolderContainertRef.createComponent(factory);

			bottomHeaderEntityViewPlaceHolderContainertRef.insert(this.headerBottomComponentRef.hostView);
			this.headerBottomComponentRef.instance.setEntity(this.entity);
		}
	}

	private updateButtonStyles() {
		const isLeftPositioned = this.toggleButtonPosition === 'left';
		let buttonStyleSettings: ICommandBarStyles;
		if (this.minimized) {
			this.toggleIconName = isLeftPositioned
				? this.styleSettings.leftArrowIconName
				: this.styleSettings.rightArrowIconName;
			buttonStyleSettings = this.styleSettings.hiddenPanelButtons;
		} else {
			this.toggleIconName = isLeftPositioned
				? this.styleSettings.rightArrowIconName
				: this.styleSettings.leftArrowIconName;
			buttonStyleSettings = isLeftPositioned
				? this.styleSettings.leftPositionedSidePanelButtonStyles
				: this.styleSettings.rightPositionedSidePanelButtonStyles;
		}
		this.toggleButtonStyleSettings = buttonStyleSettings;
	}

	private announceToggleSidePanel(){
		this.liveAnnouncer.announce(
			!!this.minimized
				? this.i18nService.strings.dataview_entity_details_panel_button_show
				: this.i18nService.strings.dataview_entity_details_panel_button_hide,
			'assertive',
			300
		);
	}

	ngOnDestroy() {
		this._tagsSubject$.complete();
	}
}
