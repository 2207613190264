import { Injectable } from '@angular/core';
import { DataEntityType, ModelBase } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { Lazy } from '@wcd/utils';
import { RegExpService } from '@wcd/shared';
import { AuthService } from '@wcd/auth';
import { FlavorService } from '@wcd/config';

export type SearchEntityTypeId =
	| 'file'
	| 'machine'
	| 'investigation'
	| 'incident'
	| 'investigation-action'
	| 'remediation-action'
	| 'alert'
	| 'airs-entity'
	| 'scheduled-hunting'
	| 'user'
	| 'ip'
	| 'url'
	| 'SecurityRecommendation'
	| 'software'
	| 'vulnerability';
export type SearchRouteEntityTypeId =
	| 'files'
	| 'ip'
	| 'machines'
	| 'url'
	| 'users'
	| 'securityRecommendation'
	| 'softwareInventory'
	| 'vulnerability';

/**
 * Maps a `SearchRouteEntityTypeId` to `SearchEntityTypeId`.
 * Used for getting the relevant entity id from a search route.
 */
export const searchRouteIdEntityIdMap: Record<SearchRouteEntityTypeId, SearchEntityTypeId> = {
	files: 'file',
	machines: 'machine',
	users: 'user',
	ip: 'ip',
	url: 'url',
	securityRecommendation: 'SecurityRecommendation',
	softwareInventory: 'software',
	vulnerability: 'vulnerability',
};

@Injectable()
export class MainSearchEntityTypesService {
	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly authService: AuthService,
		readonly flavorService: FlavorService,
	) {

	}

	private readonly _allEntityTypes = new Lazy<ReadonlyArray<SearchEntityType>>(() => {
		const globalEntityTypes = this.globalEntityTypesService.entityTypes
			.filter(entityType => {
				if (!entityType.searchOptions) return false;
				if (!entityType.searchOptions.requiredAllowedActions) return true;
				if(entityType.searchOptions.flavorConfig && !this.flavorService.isEnabled(entityType.searchOptions.flavorConfig)) return false;
				return this.authService.currentUser.hasMdeAllowedUserRoleAction(
					entityType.searchOptions.requiredAllowedActions
				);
			})
			.sort(
				(entity1, entity2) =>
					entity1.searchOptions.searchDropdownOrder - entity2.searchOptions.searchDropdownOrder
			)
			.map<SearchEntityType>(entityType => ({
				id: entityType.id as SearchEntityTypeId,
				displayName: entityType.searchOptions.displayName,
				entityType: entityType.entity,
				getSearchParams: entityType.searchOptions.getSearchParams,
				resolveTypeFromSearchTerm: entityType.searchOptions.resolveTypeFromSearchTerm,
			}));

		return globalEntityTypes;
	});

	private readonly _defaultEntityTypes = new Lazy<Readonly<SearchEntityType<ModelBase>>>(() => {
		return this._allEntityTypes.value.find(entityType => entityType.id === 'machine');
	});

	get all(): ReadonlyArray<SearchEntityType> {
		return this._allEntityTypes.value;
	}

	get default(): Readonly<SearchEntityType<ModelBase>> {
		return this._defaultEntityTypes.value;
	}
}

export interface SearchEntityType<TEntity extends ModelBase = ModelBase> {
	readonly id: SearchEntityTypeId;
	readonly entityType: DataEntityType<TEntity>;
	readonly displayName: string;
	readonly resolveTypeFromSearchTerm?: (searchTerm: string) => boolean;
	readonly getSearchParams: (searchTerm: string) => Readonly<SearchParams>;
}

export interface SearchParams {
	readonly url?: string;
	readonly filter?: string;
}
