var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnDestroy, OnInit, } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import assertNever from 'assert-never';
import { compact, cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MachineExtention, Machine, MachineMachineRequestLatestRelationship, MachineRequestLogsCollectionDownloadRelationship, MachineRequestPackageDownloadRelationship, } from '@wcd/domain';
import { CommentModel } from '../../../../../comments/models/comment.model';
import { PanelContainer, PanelType } from '@wcd/panels';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState, } from '../../../../../rbac/models/rbac-control-settings.model';
import { DownloadService } from '../../../../../shared/services/download.service';
import { MachineRequestErrorCodePipe } from '../../../pipes/machine-request-error-code.pipe';
import { MachinesActionsService } from '../../../services/machines-actions.service';
import { MachineRequestsPermissionsService } from '../../../services/machines-requests.permissions.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "action-center-details";
})(CollapsibleID || (CollapsibleID = {}));
var MachineActionCenterPanelComponent = /** @class */ (function (_super) {
    __extends(MachineActionCenterPanelComponent, _super);
    function MachineActionCenterPanelComponent(router, activatedEntity, paris, i18nService, machineRequestErrorCodePipe, machinesPermissionsService, machinesActionsService, downloadService, changeDetector, liveAnnouncer) {
        var _this = _super.call(this, router) || this;
        _this.activatedEntity = activatedEntity;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.machineRequestErrorCodePipe = machineRequestErrorCodePipe;
        _this.machinesPermissionsService = machinesPermissionsService;
        _this.machinesActionsService = machinesActionsService;
        _this.downloadService = downloadService;
        _this.changeDetector = changeDetector;
        _this.liveAnnouncer = liveAnnouncer;
        _this.SpinnerSize = SpinnerSize;
        _this.collapsibleID = CollapsibleID;
        _this.investigationPackageCollectionRbac = {
            state: RbacControlState.disabled,
            permissions: [RbacMdeAllowedActions.alertsInvestigation],
        };
        _this.requests$ = new BehaviorSubject([]);
        _this.isLoading = true;
        _this.cancelAnnounceKeysMap = {
            Succeeded: 'machines_actionCenter_actions_cancellation_success',
            Failed: 'machines_actionCenter_actions_cancellation_failed',
            InProgress: 'machines_actionCenter_actions_cancellation_inProgress',
            Cancelled: 'machines_actionCenter_actions_cancellation_success'
        };
        _this.buildActionTextsMaps();
        return _this;
    }
    MachineActionCenterPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settings = __assign({}, this.settings, { showOverlay: true, hasCloseButton: true, type: PanelType.large, noBodyPadding: true, isBlocking: true, className: this.settings.className || '' });
        this.currentMachine$ = this.machine
            ? of(this.machine)
            : this.activatedEntity.currentEntity$.pipe(filter(function (currentEntity) { return currentEntity instanceof Machine; }), map(function (machine) { return machine; }));
        var featuresStatus$ = this.currentMachine$.pipe(mergeMap(function (machine) { return _this.machinesPermissionsService.getFeaturesStatus(machine); }));
        var extendedMachine = new MachineExtention(this.machine, this.requestGuid);
        var machineRequests$ = this.currentMachine$.pipe(mergeMap(function (machine) {
            return _this.paris.queryForItem(MachineMachineRequestLatestRelationship, extendedMachine);
        }), map(function (dataSet) { return dataSet.items; }));
        this._dataSetSubscription = combineLatest(machineRequests$, featuresStatus$)
            .pipe(map(function (_a) {
            var requests = _a[0], featuresStatus = _a[1];
            return requests.filter(function (request) { return featuresStatus[request.type]; });
        }))
            .subscribe(function (items) {
            _this.requests$.next(items);
            _this.isLoading = false;
            _this.changeDetector.markForCheck();
        });
        this.sections = this.createSections();
        _super.prototype.ngOnInit.call(this);
    };
    MachineActionCenterPanelComponent.prototype.ngOnDestroy = function () {
        if (this._dataSetSubscription) {
            this._dataSetSubscription.unsubscribe();
        }
    };
    MachineActionCenterPanelComponent.prototype.createState = function (type) {
        var _this = this;
        return this.requests$.pipe(map(function (items) { return items.find(function (item) { return item.type === type; }); }), filter(function (requestItem) { return Boolean(requestItem); }), map(function (requestItem) {
            var cancellationComment = requestItem.cancellationDateTimeUtc &&
                new CommentModel({
                    id: 'cancellationComment',
                    timestamp: requestItem.cancellationDateTimeUtc,
                    action: _this.calculateActionText(requestItem, 'cancel'),
                    user: requestItem.cancellationRequestor,
                    message: requestItem.cancellationComment,
                });
            var creationComment = requestItem.creationDateTimeUtc &&
                new CommentModel({
                    id: 'creationComment',
                    user: requestItem.requestor,
                    timestamp: requestItem.creationDateTimeUtc,
                    action: _this.calculateActionText(requestItem, 'submit'),
                    message: requestItem.requestorComment,
                });
            return {
                comments: compact([cancellationComment, creationComment]),
                submission: {
                    dateTime: requestItem.creationDateTimeUtc,
                    allowCancellation: requestItem.requestStatus === 'Submitted',
                    status: requestItem.requestStatus,
                },
                status: _this.getRequestStatus(requestItem),
            };
        }));
    };
    MachineActionCenterPanelComponent.prototype.createSections = function () {
        var _this = this;
        return [
            {
                labelKey: 'machines.actionCenter.sections.investigationPackageCollection.label',
                type: 'ForensicsResponse',
                onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                    var forensicsResponseRequest, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                forensicsResponseRequest = this.requests$.value.find(function (item) { return item.type === 'ForensicsResponse'; });
                                if (!forensicsResponseRequest) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.machinesActionsService.cancelCollectInvestigationPackage(forensicsResponseRequest.requestGuid)];
                            case 1:
                                result = _a.sent();
                                if (result.confirmed) {
                                    return [2 /*return*/, result.data];
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
            {
                labelKey: 'machines.actionCenter.sections.antivirusScan.label',
                type: 'ScanResponse',
                onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                    var scanResponseRequest, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                scanResponseRequest = this.requests$.value.find(function (item) { return item.type === 'ScanResponse'; });
                                if (!scanResponseRequest) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.machinesActionsService.cancelAntivirusScan(scanResponseRequest.requestGuid)];
                            case 1:
                                result = _a.sent();
                                if (result.confirmed) {
                                    return [2 /*return*/, result.data];
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
            {
                labelKey: 'machines.actionCenter.sections.troubleshootMode.label',
                type: 'TroubleshootResponse',
            },
            {
                labelKey: 'machines.actionCenter.sections.appRestriction.label',
                type: 'RestrictExecutionResponse',
                onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                    var restrictResponseRequest, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                restrictResponseRequest = this.requests$.value.find(function (item) { return item.type === 'RestrictExecutionResponse'; });
                                if (!restrictResponseRequest) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.machinesActionsService.cancelRestrictAppExecution(restrictResponseRequest.requestGuid)];
                            case 1:
                                result = _a.sent();
                                if (result.confirmed) {
                                    return [2 /*return*/, result.data];
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
            {
                labelKey: 'machines.actionCenter.sections.machineIsolation.label',
                type: 'IsolationResponse',
                onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                    var isolationResponseRequest, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                isolationResponseRequest = this.requests$.value.find(function (item) { return item.type === 'IsolationResponse'; });
                                if (!isolationResponseRequest) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.machinesActionsService.cancelIsolation(isolationResponseRequest.action, isolationResponseRequest.requestGuid)];
                            case 1:
                                result = _a.sent();
                                if (result.confirmed) {
                                    return [2 /*return*/, result.data];
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
            {
                labelKey: 'machines.actionCenter.sections.logsCollection.label',
                type: 'LogsCollectionResponse',
                onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                    var logsCollectionRequest, result;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                logsCollectionRequest = this.requests$.value.find(function (item) { return item.type === 'LogsCollectionResponse'; });
                                if (!logsCollectionRequest) {
                                    return [2 /*return*/];
                                }
                                return [4 /*yield*/, this.machinesActionsService.cancelLogsCollection(logsCollectionRequest.requestGuid)];
                            case 1:
                                result = _a.sent();
                                if (result.confirmed) {
                                    return [2 /*return*/, result.data];
                                }
                                return [2 /*return*/];
                        }
                    });
                }); },
            },
        ].map(function (partialSection) { return (__assign({}, partialSection, { state$: _this.createState(partialSection.type), onCancelSubmission: function (machine) { return __awaiter(_this, void 0, void 0, function () {
                var newRequest, newRequests;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4 /*yield*/, partialSection.onCancelSubmission(machine)];
                        case 1:
                            newRequest = _a.sent();
                            if (!newRequest)
                                return [2 /*return*/];
                            this.announceCancelRequestStatus(newRequest);
                            newRequests = this.requests$.value.map(function (existingRequest) {
                                return existingRequest.type === newRequest.type ? newRequest : existingRequest;
                            });
                            this.requests$.next(newRequests);
                            return [2 /*return*/];
                    }
                });
            }); } })); });
    };
    MachineActionCenterPanelComponent.prototype.getRequestStatus = function (requestItem) {
        var _this = this;
        var isDownloadStatus = (requestItem.type === 'ForensicsResponse' || requestItem.type === 'LogsCollectionResponse') && requestItem.requestStatus === 'Succeeded';
        var machineRequest = requestItem;
        if (isDownloadStatus) {
            machineRequest = cloneDeep(requestItem); // duplicate item to allow updating RO property
            machineRequest.packageIdentity = this.machine.name;
        }
        return {
            text: this.calculateStatusText(machineRequest),
            helpText: this.calculateHelpText(machineRequest),
            iconName: isDownloadStatus ? FabricIconNames.Download : null,
            onClick: isDownloadStatus
                ? function () {
                    var downloadUrl$ = (requestItem.type === 'ForensicsResponse' ? _this.paris
                        .getRelatedItem(MachineRequestPackageDownloadRelationship, machineRequest) :
                        _this.paris
                            .getRelatedItem(MachineRequestLogsCollectionDownloadRelationship, machineRequest))
                        .pipe(map(function (_a) {
                        var url = _a.url;
                        return url;
                    }));
                    _this.downloadService.downloadFromUrl(downloadUrl$, {
                        isAuthenticated: true,
                    });
                }
                : null,
        };
    };
    MachineActionCenterPanelComponent.prototype.buildActionTextsMaps = function () {
        this._statusTextPrefixRequestTextKeysMap = {
            IsolationResponse: function (request) {
                if (request.action === 'Isolate')
                    return 'machines.actionCenter.statuses.prefix.isolation.isolate';
                else if (request.action === 'Unisolate')
                    return 'machines.actionCenter.statuses.prefix.isolation.unisolate';
                assertNever(request.action);
            },
            ForensicsResponse: function (request) {
                return 'machines.actionCenter.statuses.prefix.packageCollection';
            },
            ScanResponse: function (request) {
                return 'machines.actionCenter.statuses.prefix.antivirusScan';
            },
            RestrictExecutionResponse: function (request) {
                if (request.policyType === 'Restrict')
                    return 'machines.actionCenter.statuses.prefix.appRestriction.policyType.restrict';
                else if (request.policyType === 'Unrestrict')
                    return 'machines.actionCenter.statuses.prefix.appRestriction.policyType.unrestrict';
                assertNever(request.policyType);
            },
            TroubleshootResponse: function (request) {
                return 'machines.actionCenter.statuses.prefix.troubleshootMode';
            },
            LogsCollectionResponse: function (request) { return 'machines.actionCenter.statuses.prefix.logsCollection'; }
        };
        this._statusTextSuffixRequestTextKeysMap = {
            Cancelled: function (request) { return 'machines.actionCenter.statuses.suffix.status.cancelled'; },
            Failed: function (request) { return 'machines.actionCenter.statuses.suffix.status.failed'; },
            InProgress: function (request) { return 'machines.actionCenter.statuses.suffix.status.inProgress'; },
            Submitted: function (request) { return 'machines.actionCenter.statuses.suffix.status.submitted'; },
            None: function (request) { return ''; },
            Succeeded: function (request) {
                switch (request.type) {
                    case 'IsolationResponse':
                        return request.action === 'Unisolate'
                            ? 'machines.actionCenter.statuses.suffix.status.succeeded.isolation.unisolate'
                            : 'machines.actionCenter.statuses.suffix.status.succeeded.isolation.isolate';
                    case 'ForensicsResponse':
                        return 'machines.actionCenter.statuses.suffix.status.succeeded.packageCollection';
                    case 'RestrictExecutionResponse':
                        return request.policyType === 'Unrestrict'
                            ? 'machines.actionCenter.statuses.suffix.status.succeeded.appRestriction.policyType.unrestrict'
                            : 'machines.actionCenter.statuses.suffix.status.succeeded.appRestriction.policyType.restrict';
                    case 'ScanResponse':
                        return 'machines.actionCenter.statuses.suffix.status.succeeded.antivirusScan';
                    case 'TroubleshootResponse':
                        return 'machines.actionCenter.statuses.suffix.status.succeeded.troubleshootMode';
                    case 'LogsCollectionResponse':
                        return 'machines.actionCenter.statuses.suffix.status.succeeded.logsCollection';
                    default:
                        assertNever(request);
                }
            },
        };
        this._actionTextPrefixRequestTextKeysMap = {
            ForensicsResponse: function (request, kind) {
                return 'machines.actionCenter.statusActions.prefix.packageCollection';
            },
            ScanResponse: function (request, kind) {
                return request.scanType === 'Quick'
                    ? 'machines.actionCenter.statusActions.prefix.antivirusScan.scanType.quick'
                    : 'machines.actionCenter.statusActions.prefix.antivirusScan.scanType.full';
            },
            RestrictExecutionResponse: function (request, kind) {
                return 'machines.actionCenter.statusActions.prefix.appRestriction';
            },
            IsolationResponse: function (request, kind) {
                if (request.action === 'Isolate') {
                    return kind === 'submit' && request.isolationType === 'Selective'
                        ? 'machines.actionCenter.statusActions.prefix.isolation.selective'
                        : 'machines.actionCenter.statusActions.prefix.isolation.full';
                }
                else if (request.action === 'Unisolate') {
                    return 'machines.actionCenter.statusActions.prefix.isolation.unisolate';
                }
                assertNever(request.action);
            },
            TroubleshootResponse: function (request, kind) {
                return 'machines.actionCenter.statusActions.prefix.troubleshootMode';
            },
            LogsCollectionResponse: function (request, kind) {
                return 'machines.actionCenter.statusActions.prefix.logsCollection';
            },
        };
    };
    MachineActionCenterPanelComponent.prototype.calculateStatusText = function (request) {
        var prefixKey = this._statusTextPrefixRequestTextKeysMap[request.type](request);
        var suffixKey = this._statusTextSuffixRequestTextKeysMap[request.requestStatus](request);
        return this.i18nService.get(prefixKey) + " " + this.i18nService.get(suffixKey);
    };
    MachineActionCenterPanelComponent.prototype.calculateHelpText = function (request) {
        return ((request &&
            request.errorCode &&
            this.machineRequestErrorCodePipe.transform(request.errorCode.value)) ||
            null);
    };
    MachineActionCenterPanelComponent.prototype.calculateActionText = function (request, kind) {
        var prefixKey = this._actionTextPrefixRequestTextKeysMap[request.type](request, kind);
        var suffixKey = kind === 'submit'
            ? 'machines.actionCenter.statuses.suffix.kind.submit'
            : 'machines.actionCenter.statuses.suffix.kind.cancel';
        return this.i18nService.get(prefixKey) + " " + this.i18nService.get(suffixKey);
    };
    MachineActionCenterPanelComponent.prototype.announceCancelRequestStatus = function (request) {
        if (!request)
            return;
        var textSelector = this.cancelAnnounceKeysMap[request.requestStatus];
        if (!textSelector)
            return;
        this.liveAnnouncer.announce(this.i18nService.get(textSelector), 'assertive', 300);
    };
    return MachineActionCenterPanelComponent;
}(PanelContainer));
export { MachineActionCenterPanelComponent };
