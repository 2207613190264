import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('remediationStatus', remediationStatusFilter);

function remediationStatusFilter() {
	return function(input) {
		if (input == null) {
			return input;
		}

		if (input) {
			return 'Remediated successfully';
		}

		return 'Not Remediated';
	};
}
