import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';

export enum EvaluationSimulationStatusType {
	'Failed' = 'Failed',
	'Running' = 'Running',
	'Completed' = 'Completed',
}

export const evaluationSimulationStatusValues: EvaluationSimulationStatus[] = [
	{
		id: 'Failed',
		name: 'failed',
		className: 'ms-color-red',
		type: EvaluationSimulationStatusType.Failed,
	},
	{
		id: 'Running',
		name: 'running',
		className: 'ms-color-themeSecondary',
		type: EvaluationSimulationStatusType.Running,
	},
	{
		id: 'Completed',
		name: 'completed',
		className: 'ms-color-greenLight',
		type: EvaluationSimulationStatusType.Completed,
	},
];

@Entity({
	singularName: 'Simulator status',
	pluralName: 'Simulator status',
	values: evaluationSimulationStatusValues,
})
export class EvaluationSimulationStatus extends StatusModel<string, EvaluationSimulationStatusType> {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() type: EvaluationSimulationStatusType;
}
