<tvm-assets-statistics
	*ngIf="!isLoading; else loading"
	[totalAssetCount]=totalAssetCount
	[assetsCount]=assetsCount
	[assetsCountHistory]=assetsCountHistory
	[isImprovementNegative]=isImprovementNegative>
</tvm-assets-statistics>
<ng-template #loading>
	<div>
		{{ i18nService.strings.common_loading }}
	</div>
</ng-template>
