import { ComponentRef, Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FabricIconName, FabricIconNames } from '@wcd/scc-common';
import {
	FiltersFieldConfig,
	FilterValuesChecklistComponent,
	FilterValuesCheckListConfig,
	FilterValuesChecklistData,
	FilterValuesChecklistSelection,
	FilterValuesChecklistWithSearchComponent,
	FilterValuesChecklistWithSearchConfig,
	ListFilterValue,
} from '@wcd/ng-filters';
import { Observable } from 'rxjs';
import {
	CustomTiIndicatorIdentifierType,
	CustomTiIndicatorIdentifierTypes,
	CustomTiIndicatorsProperties,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	TiIndicatorFilterSearchResult,
	TiIndicatorsFilterSearchApiCall,
	TiIndicatorsSearchParams,
	CustomTiIndicator,
	CustomTiIndicatorsTypeField,
} from '@wcd/domain';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { CustomTiIndicatorImportPanelComponent } from '../components/custom-ti-indicator-import.panel.component';
import { CustomTiIndicatorNewModalComponent } from '../components/custom-ti-indicator-new.modal.component';
import {
	CustomTiIndicatorBackendService,
	DownloadCustomTiIndicatorCsvParams,
} from './custom-ti-indicator.backend.services';

const CUSTOM_TI_INDICATORS_ICONS: Record<CustomTiIndicatorsTypes, FabricIconName> = {
	[CustomTiIndicatorsTypes.Files]: FabricIconNames.Page,
	[CustomTiIndicatorsTypes.Ip]: FabricIconNames.Streaming,
	[CustomTiIndicatorsTypes.Url]: FabricIconNames.Link,
	[CustomTiIndicatorsTypes.Certificate]: FabricIconNames.Certificate,
	[CustomTiIndicatorsTypes.MemoryContent]: FabricIconNames.Processing,
};

declare const moment: typeof import('moment');

@Injectable()
export class CustomTiIndicatorsService {
	constructor(
		private dialogsService: DialogsService,
		private backendService: CustomTiIndicatorBackendService,
		private i18nService: I18nService,
		private paris: Paris
	) {}

	showCustomTiIndicatorNewDialog(
		customTiIndicatorType: CustomTiIndicatorsType,
		indicatorValue?: string
	): Observable<ComponentRef<CustomTiIndicatorNewModalComponent>> {
		return this.dialogsService.showPanel(
			CustomTiIndicatorNewModalComponent,
			{
				id: 'new-custom-ti-indicator',
				type: PanelType.large,
				noBodyPadding: true,
				hasCloseButton: true,
			},
			{
				customTiIndicatorType: customTiIndicatorType,
				indicatorValue: indicatorValue,
			}
		);
	}

	showCustomTiIndicatorImportDialog(
		customTiIndicatorType: CustomTiIndicatorsType
	): Observable<ComponentRef<CustomTiIndicatorImportPanelComponent>> {
		const headerText = this.getCustomTiIndicatorImportTitle(customTiIndicatorType);
		return this.dialogsService.showPanel(
			CustomTiIndicatorImportPanelComponent,
			{
				id: 'import-custom-ti-indicator',
				type: PanelType.medium,
				noBodyPadding: true,
				headerIcon: 'BulkUpload',
				headerText: headerText,
				hasCloseButton: false,
			},
			{
				customTiIndicatorType: customTiIndicatorType,
			}
		);
	}

	private getCustomTiIndicatorImportTitle(customTiIndicatorType: CustomTiIndicatorsType): string {
		let entityName;

		switch (customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Files: {
				entityName = this.i18nService
					.get('customTiIndicator.dataview.entity.names.file.pluralName')
					.toLowerCase();
				break;
			}
			case CustomTiIndicatorsTypes.Ip: {
				entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.ip.pluralName');
				break;
			}
			case CustomTiIndicatorsTypes.Url: {
				entityName = `${this.i18nService.get(
					'customTiIndicator.dataview.entity.names.url.pluralName'
				)}/${this.i18nService.get('customTiIndicator.dataview.entity.names.domain.pluralName')}`;
				break;
			}
			case CustomTiIndicatorsTypes.Certificate: {
				entityName = this.i18nService
					.get('customTiIndicator.dataview.entity.names.certificate.pluralName')
					.toLowerCase();
				break;
			}
		}

		return `${this.i18nService.get('customTiIndicator.dataview.actions.import.title')} ${entityName}`;
	}

	downloadCsv(downloadParams: DownloadCustomTiIndicatorCsvParams): Promise<void> {
		return this.backendService.downloadCsv(downloadParams);
	}

	getCustomTiDisplay(item: CustomTiIndicator, property: CustomTiIndicatorsTypeField) {
		switch (property.id) {
			case CustomTiIndicatorsProperties.TiIndicatorType: {
				return this.i18nService.get(
					`customTiIndicator.dataview.entity.fields.tiIndicatorType.${item.tiIndicatorType.name.toLocaleLowerCase()}.title`
				);
			}
			default: {
				if (property.itemProperty) {
					return item[property.id][property.itemProperty];
				} else {
					return item[property.id];
				}
			}
		}
	}

	getCustomTiFilters(
		propertyId: CustomTiIndicatorsProperties,
		type: CustomTiIndicatorsTypes
	): FiltersFieldConfig<
		FilterValuesChecklistData<string | number>,
		FilterValuesChecklistSelection<string | number>,
		FilterValuesCheckListConfig<string | number>,
		ReadonlyArray<ListFilterValue<string | number>>
	> {
		switch (propertyId) {
			case CustomTiIndicatorsProperties.TiIndicatorType: {
				return {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (actionTypeId: CustomTiIndicatorIdentifierTypes) => {
								const identifierType: CustomTiIndicatorIdentifierType = this.paris.getValue<
									CustomTiIndicatorIdentifierType,
									CustomTiIndicatorIdentifierTypes
								>(CustomTiIndicatorIdentifierType, actionTypeId);

								if (identifierType) {
									return {
										name: this.i18nService.get(
											`customTiIndicator.dataview.entity.fields.tiIndicatorType.${identifierType.name.toLowerCase()}.title`
										),
										id: identifierType.id,
									};
								}
								return null;
							},
						},
					},
				};
			}
			case CustomTiIndicatorsProperties.TiIndicator:
			case CustomTiIndicatorsProperties.Application: {
				return {
					component: {
						type: FilterValuesChecklistWithSearchComponent,
						config: this.getFilterValuesConfig(propertyId, type),
					},
				};
			}
		}

		return null;
	}

	getFilterValuesConfig(
		propertyId: CustomTiIndicatorsProperties,
		type: CustomTiIndicatorsTypes
	): FilterValuesChecklistWithSearchConfig<string | number> {
		return {
			search: {
				method: (term: string) => {
					return this.paris.apiCall<
						Array<TiIndicatorFilterSearchResult<string | number>>,
						TiIndicatorsSearchParams
					>(TiIndicatorsFilterSearchApiCall, {
						field: propertyId,
						term: term.trim(),
						type: type,
					});
				},
				placeholder: this.i18nService.get('customTiIndicator_search_textbox_placeholder'),
			},
		};
	}

	getIcon = (indicatorType: CustomTiIndicatorsTypes): FabricIconName =>
		CUSTOM_TI_INDICATORS_ICONS[indicatorType];

	// Since browser supports only local time dates, we need to move the date values back in time and then create a DateTime
	// The browser will display the time in local time zone, but when "fixing" the date (e.g. subtracting the delta beween local time and UTC)
	// the value that the browser will show will actually be the UTC that we wanted to display
	public reinterpretUtcAsLocalTime(date: Date): Date {
		let utcDateAsLocal = undefined;

		if (!!date) {
			utcDateAsLocal = moment(date)
				.subtract(new Date().getTimezoneOffset() / 60, 'hours')
				.toDate();
		}

		return utcDateAsLocal;
	}
}
