import { Component } from '@angular/core';
import { ColorClassNames } from '@uifabric/styling';
import { mapValues } from 'lodash-es';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { ChartSettings, ChartSettingsSeriesItem, LegendItem } from '@wcd/charts';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { Dictionary } from '@wcd/config';
import { Prettify } from '../../../../../utils/services/prettify.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService, TimeSeriesDataItem } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';

const NAME_COLOR_MAPPING = {
	SmartScreens: {
		className: 'ms-border-tealLight',
		color: '#00A5B0',
		colorName: 'tealLight',
	},
	ExploitGuard: {
		className: 'ms-border-blueMid',
		color: '#00188F',
		colorName: 'blueMid',
	},
	Firewall: {
		className: 'ms-color-purpleLight',
		color: '#917EDB',
		colorName: 'purpleLight',
	},
	AntiVirus: {
		className: 'color-border-primary',
		color: '#0078d4',
		colorName: 'themePrimary',
	},
	DeviceGuard: {
		className: 'ms-border-magentaDark',
		color: '#4B003F',
		colorName: 'magentaDark',
	},
};

@Component({
	selector: 'suspicious-activities-widget',
	template: `
		<div class="wcd-flex-horizontal-spaced wcd-flex-wrap" [hidden]="error$ | async">
			<wcd-pie-chart
				class="wcd-margin-large-bottom"
				[data]="activitiesBySourceData$ | async"
				[upperTitle]="activitiesBySourceTitle$ | async"
				[settings]="activitiesBySourceSettings"
				[labelsOutside]="false"
			></wcd-pie-chart>

			<div class="wcd-margin-large-bottom">
				<wcd-chart-legend
					[items]="activitiesBySourceDataLegendItems$ | async"
					[showValues]="true"
				></wcd-chart-legend>
			</div>

			<wcd-time-series-chart
				[data]="machinesOverTimeData$ | async"
				[settings]="machineOverTimeSettings"
				[change]="localeConfigService.config$ | async"
			></wcd-time-series-chart>
		</div>
	`,
})
export class SuspiciousActivitiesWidget extends ReportWidgetComponent<
	SuspiciousActivitiesParsedData,
	SuspiciousActivitiesBackendData
> {
	Prettify = Prettify;
	activitiesBySourceData$ = this.data$.pipe(
		map((data: SuspiciousActivitiesParsedData) => {
			return data && data.activitiesBySource;
		})
	);

	activitiesBySourceDataLegendItems$: Observable<Array<LegendItem>> = this.activitiesBySourceData$.pipe(
		filter(Boolean),
		map((data: Array<SuspiciousActivityChartItem>) =>
			data.map((chartItem: SuspiciousActivityChartItem) => ({
				name: chartItem.displayName || chartItem.name,
				value: chartItem.value,
				iconColorName: chartItem.colorName,
			}))
		)
	);

	machinesOverTimeData$ = this.data$.pipe(
		map((data: SuspiciousActivitiesParsedData) => {
			return data && data.machinesOverTime;
		})
	);
	activitiesBySourceTitle$ = this.activitiesBySourceData$.pipe(
		map((data: Array<SuspiciousActivityChartItem>) => {
			return this.prettyNumberService.prettyNumber(
				(data || []).reduce(
					(res: number, item: SuspiciousActivityChartItem) => res + (item.machineCount || 0),
					0
				)
			);
		})
	);
	suspiciousActivityDataMapping: Dictionary<string, SuspiciousActivityChartItem>;

	get widgetConfig(): ReportWidgetConfig<SuspiciousActivitiesParsedData, SuspiciousActivitiesBackendData> {
		const rangeInDays: number = 30;
		return {
			id: 'suspiciousActivities',
			name: this.i18nService.get('widget_title_suspiciousActivities'),
			rangeInDays: rangeInDays,
			api: {
				url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/suspicious_activities`,
				isExternal: true,
			},
			parseData: this.parseData.bind(this),
		};
	}

	activitiesBySourceSettings: ChartSettings = {
		options: {
			data: {
				colors: mapValues(NAME_COLOR_MAPPING, value => value.color),
			},
			tooltip: {
				contents: (data: Array<SuspiciousActivityChartItem>) => {
					const item = data && data.length && this.suspiciousActivityDataMapping.get(data[0].name);
					return item
						? `<table class="c3-tooltip">
								<tbody>
									<tr class="c3-tooltip-name--${item.name}">
										<td class="name">${this.prettyNumberService.prettyNumber(item.value || 0)}
											${item.displayName} ${this.i18nService.get('machine_entityType_pluralName')}</br>
											${this.prettyNumberService.prettyNumber(item.machineCount || 0)}
											${this.i18nService.get('machine_entityType_pluralName')}
										</td>
									</tr>
								</tbody>
							</table>`
						: '';
				},
			},
			donut: {
				title: this.i18nService.get('suspiciousActivities_widget_Activities_by_source'),
				width: 16,
			},
			legend: {
				show: false,
			},
			size: {
				height: 170,
				width: 200,
			},
		},
	};

	machineOverTimeSettings: ChartSettings = {
		useValues: false,
		setColors: true,
		series: [{ value: 'count', name: this.i18nService.get('machine_entityType_pluralName'), color: config.color.primary }],
		options: {
			data: {},
			axis: {
				x: {
					tick: {
						format: value => this.tzDateService.format(value, 'MM/dd'),
						count: 4,
					},
				},
				y: {
					tick: {
						format: value => this.prettyNumberService.prettyNumber(value),
						count: 2,
					},
				},
			},
			tooltip: {
				format: {
					title: value => this.tzDateService.format(value, 'MM/dd'),
				},
			},
			size: {
				height: 170,
				width: 350,
			},
			legend: {
				show: false,
			},
			point: { show: false },
		},
	};

	constructor(
		reportsService: ReportsService,
		private serviceUrlsService: ServiceUrlsService,
		private tzDateService: TzDateService,
		private prettyNumberService: PrettyNumberService,
		public localeConfigService: LocaleConfigService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.activitiesBySourceData$.subscribe((data: Array<SuspiciousActivityChartItem>) => {
			this.suspiciousActivityDataMapping = Dictionary.fromList<string, SuspiciousActivityChartItem>(
				data,
				'name'
			);
		});
	}

	parseData(data: SuspiciousActivitiesBackendData): SuspiciousActivitiesParsedData {
		return {
			activitiesBySource:
				data && data.sources
					? data.sources.map((item: SuspiciousActivitySource) => {
							const itemColorData = NAME_COLOR_MAPPING[item.id];
							return {
								name: item.id,
								value: item.activity_count,
								machineCount: item.machine_count,
								displayName: item.name,
								className: itemColorData && itemColorData.className,
								color: itemColorData && itemColorData.color,
								colorName: itemColorData && itemColorData.colorName,
							};
					  })
					: [],
			machinesOverTime:
				data && data.machines_over_time
					? ReportsService.parseTimeSeriesData(data.machines_over_time)
					: [],
		};
	}
}

interface SuspiciousActivityChartItem extends ChartSettingsSeriesItem {
	value: number;
	machineCount: number;
	displayName: string;
	className: string;
	color: string;
	colorName: keyof typeof ColorClassNames;
}

interface SuspiciousActivitySource {
	id: string;
	name: string;
	activity_count: number;
	machine_count: number;
}

interface SuspiciousActivitiesBackendData {
	sources: Array<SuspiciousActivitySource>;
	machines_over_time: Array<TimeSeriesDataItem>;
}

interface SuspiciousActivitiesParsedData {
	activitiesBySource: Array<SuspiciousActivityChartItem>;
	machinesOverTime: Array<TimeSeriesDataItem>;
}
