var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { Ip, Url } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
var NetworkEndpointEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(NetworkEndpointEntityDetailsComponent, _super);
    function NetworkEndpointEntityDetailsComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.ipType = Ip;
        _this.urlType = Url;
        return _this;
    }
    Object.defineProperty(NetworkEndpointEntityDetailsComponent.prototype, "endpoint", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return NetworkEndpointEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { NetworkEndpointEntityDetailsComponent };
