
		<div class="wcd-flex-horizontal-spaced wcd-flex-wrap" [hidden]="error$ | async">
			<wcd-pie-chart
				class="wcd-margin-large-bottom"
				[data]="activitiesBySourceData$ | async"
				[upperTitle]="activitiesBySourceTitle$ | async"
				[settings]="activitiesBySourceSettings"
				[labelsOutside]="false"
			></wcd-pie-chart>

			<div class="wcd-margin-large-bottom">
				<wcd-chart-legend
					[items]="activitiesBySourceDataLegendItems$ | async"
					[showValues]="true"
				></wcd-chart-legend>
			</div>

			<wcd-time-series-chart
				[data]="machinesOverTimeData$ | async"
				[settings]="machineOverTimeSettings"
				[change]="localeConfigService.config$ | async"
			></wcd-time-series-chart>
		</div>
	