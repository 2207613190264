import { Component, OnDestroy, OnInit } from '@angular/core';
import { Url } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import { Subscription } from 'rxjs';
import { Feature, FeaturesService } from '@wcd/config';
import { UrlsService } from '../services/urls.service';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ReportModel } from '../../../reports/models/report.model';
import { urlReportModel } from './url.overview.component';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Component({
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div
				class="wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal
				wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens"
			>
				<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
			</div>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class UrlEntityComponent extends EntityComponentBase<Url> implements OnInit, OnDestroy {
	report: ReportModel;
	tabs: ReadonlyArray<TabModel> = [];
	featuresChangeSubscription: Subscription;

	constructor(
		private readonly i18nService: I18nService,
		private readonly featuresService: FeaturesService,
		private readonly urlsService: UrlsService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {
		super();

		this.featuresChangeSubscription = this.featuresService.featureChanged$
			.pipe(filter(({ featureId }) => featureId === Feature.UpgradeUrlPage))
			.subscribe(() => {
				if (!featuresService.isEnabled(Feature.UpgradeUrlPage)) {
					const params = this.route.snapshot.queryParams;
					this.router.navigateByUrl(
						this.urlsService.getUrlLink(
							this.url.id,
							new Date((params && params['to']) || Date.now())
						)
					);
				}
			});

		this.report = urlReportModel;
	}

	get url(): Url {
		return this.entity;
	}

	ngOnInit() {
		this.tabs = this.getTabs(this.url);
	}

	ngOnDestroy() {
		this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
	}

	private getTabs(url: Url): Array<TabModel> {
		const tabs = [
			{
				id: 'alerts',
				name: this.i18nService.get('urls.tabs.alerts'),
				routerLink: './alerts',
			},
			{
				id: 'timeline',
				name: this.i18nService.get('urls.tabs.observed'),
				routerLink: './timeline',
			},
		];

		tabs.unshift({
			id: 'overview',
			name: this.i18nService.get('urls.tabs.overview'),
			routerLink: './overview',
		});

		return tabs.map(tab => new TabModel(tab));
	}
}
