import { Component, Input } from '@angular/core';
import { EntityModelBase } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { TvmReportInaccuracyService } from '../../services/tvm-report-inaccuracy.service';
import { InaccuracyContext } from '../../services/tvm-texts.service';

@Component({
	selector: 'tvm-report-inaccuracy-dropdown',
	template: `
		<div *ngIf="inaccuracyContext && contextObject" class="wcd-flex-none">
			<fancy-select
				[values]="moreMenuValues"
				[showIconDropdown]="false"
				[highlightSelected]="false"
				[useValueAsButtonText]="false"
				[buttonIcon]="'MoreVertical'"
				[dropdownAreaRole]="role"
				[ariaLabel]="'tvm.reportInaccuracy.moreOptions' | i18n"
				[ngModel]="selection"
				(ngModelChange)="onSelect($event)"
			></fancy-select>
		</div>
	`,
})
export class TvmReportInaccuracyDropdownComponent {
	@Input() contextObject: EntityModelBase;
	@Input() inaccuracyContext: InaccuracyContext;

	selection: string;
	moreMenuValues: Array<string>;
	role: string = 'button';

	constructor(
		public i18nService: I18nService,
		private reportInaccuracyService: TvmReportInaccuracyService
	) {
		this.moreMenuValues = [this.i18nService.get('tvm.reportInaccuracy.reportButton')];
	}

	onSelect(_: any) {
		// Currently only one option in the dropdown menu (Report inaccuracy) so no need to check the selection
		this.reportInaccuracyService.openReportInaccuracyPanel(this.inaccuracyContext, this.contextObject);
	}
}
