import { ChangeDetectorRef, OnChanges, } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataviewActionTypes } from './dataview-actions.model';
import { DataViewModel } from '../../models/dataview.model';
import { config } from '@wcd/shared';
import { Positions } from '@wcd/forms';
import { MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';
var MAX_ACTIONS_VISIBLE_BIG_SCREENS = 7;
var MAX_ACTIONS_VISIBLE_SMALL_SCREENS = 3;
var DataviewActionsComponent = /** @class */ (function () {
    function DataviewActionsComponent(changeDetectorRef, i18nService, mainAppStateService) {
        var _this = this;
        this.changeDetectorRef = changeDetectorRef;
        this.i18nService = i18nService;
        this.shouldShowPageNumbersOnPagination = false;
        this.positions = Positions;
        this.smallScreenView = false;
        this.dataviewActionTypes = DataviewActionTypes;
        this.actionsWithStatus = [];
        this.showMoreActionsDropdown = false;
        mainAppStateService.state$
            .pipe(filter(function (state) {
            return _this.currentMaxWidthBreakpoint !== state.pageContentMaxWidthBreakpoint;
        }))
            .subscribe(function (state) {
            _this.currentMaxWidthBreakpoint = state.pageContentMaxWidthBreakpoint;
            _this.calculateRepsonsiveActions();
            _this.changeDetectorRef.markForCheck();
        });
    }
    Object.defineProperty(DataviewActionsComponent.prototype, "actions", {
        set: function (actionsInput) {
            if (!this.actionsWithStatus.length) {
                this.actionsWithStatus = actionsInput.map(function (currentAction) {
                    return { action: currentAction, isUnderMoreActionsIcon: false };
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    DataviewActionsComponent.prototype.ngOnChanges = function () {
        this.calculateRepsonsiveActions();
    };
    DataviewActionsComponent.prototype.ngDoCheck = function () {
        this.changeDetectorRef.markForCheck();
    };
    DataviewActionsComponent.prototype.fixDropdownItems = function (items) {
        return items.map(function (item) {
            return item.dropdownButtonRef;
        });
    };
    DataviewActionsComponent.prototype.focusButton = function (element) {
        return element.dropdownButtonRef;
    };
    DataviewActionsComponent.prototype.isActionHidden = function (isMoreActionsList, isUnderMoreActionsIcon) {
        return ((isMoreActionsList && !isUnderMoreActionsIcon) || (!isMoreActionsList && isUnderMoreActionsIcon));
    };
    DataviewActionsComponent.prototype.getActionClass = function (isMoreActionsList, isUnderMoreActionsIcon) {
        return this.isActionHidden(isMoreActionsList, isUnderMoreActionsIcon)
            ? 'dataview-action-hidden'
            : 'dataview-action';
    };
    DataviewActionsComponent.prototype.calculateRepsonsiveActions = function () {
        this.setLabelsVisibility();
        this.buildResponsiveActionsLists();
    };
    DataviewActionsComponent.prototype.setLabelsVisibility = function () {
        if (this.isRightSideActionBar &&
            config.widthBreakpointSmallerThan(this.currentMaxWidthBreakpoint, config.msScreenSizeBreakpoints.xl)) {
            this.smallScreenView = true;
        }
        else {
            this.smallScreenView = config.widthBreakpointSmallerThan(this.currentMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
        }
    };
    DataviewActionsComponent.prototype.buildResponsiveActionsLists = function () {
        var _this = this;
        var matchSmall = config.widthBreakpointSmallerThan(this.currentMaxWidthBreakpoint, config.msScreenSizeBreakpoints.small);
        var maxItemsVisible = matchSmall
            ? MAX_ACTIONS_VISIBLE_SMALL_SCREENS
            : MAX_ACTIONS_VISIBLE_BIG_SCREENS;
        var showPagination = this.actionsWithStatus.find(function (action) { return action.action.actionType === DataviewActionTypes.Pagination; });
        if (showPagination) {
            // Pagination should be counted as 2 actions.
            // note that it is always a visible action since it should always be on a right command bar as the left most action.
            maxItemsVisible = maxItemsVisible - 1;
        }
        this.showMoreActionsDropdown = this.actionsWithStatus.length > maxItemsVisible;
        this.actionsWithStatus.forEach(function (action, index) {
            if (_this.showMoreActionsDropdown && index + 1 >= maxItemsVisible) {
                action.isUnderMoreActionsIcon = true;
            }
            else {
                action.isUnderMoreActionsIcon = false;
            }
        });
    };
    return DataviewActionsComponent;
}());
export { DataviewActionsComponent };
