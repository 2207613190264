import { Injectable } from '@angular/core';
import { TimeRangeId, TimeRangeValue } from '@wcd/date-time-picker';
import { I18nService } from '@wcd/i18n';
import { Lazy } from '@wcd/utils';

@Injectable()
export class TimeRangesService {
	private readonly _allTimeRangeValues = new Lazy<ReadonlyArray<TimeRangeValue>>(() => [
		{
			id: TimeRangeId.day,
			value: 1,
			name: this.i18nService.get('datetime.day.singular.template', { amount: 1 }),
		},
		{
			id: TimeRangeId['3days'],
			value: 3,
			name: this.i18nService.get('datetime.day.plural.template', { amount: 3 }),
		},
		{
			id: TimeRangeId.week,
			value: 7,
			name: this.i18nService.get('datetime.week.singular.template', { amount: 1 }),
		},
		{
			id: TimeRangeId.month,
			value: 30,
			name: this.i18nService.get('datetime.day.plural.template', { amount: 30 }),
		},
		{
			id: TimeRangeId['6months'],
			value: 180,
			name: this.i18nService.get('datetime.month.plural.template', { amount: 6 }),
		},
		{
			id: TimeRangeId.custom,
			name: this.i18nService.strings.datetime_dataRange_customRange_label,
			isCustom: true,
			customRange: null,
		},
	]);

	constructor(private readonly i18nService: I18nService) {}

	get all(): ReadonlyArray<TimeRangeValue> {
		return this._allTimeRangeValues.value;
	}

	get standard(): ReadonlyArray<TimeRangeValue> {
		return this.all.filter(rangeValue => rangeValue.id !== TimeRangeId.custom);
	}

	pick(ranges: ReadonlyArray<TimeRangeId>) {
		return this.all.filter(rangeValue => ranges.includes(rangeValue.id));
	}
}
