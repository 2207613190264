import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, OnDestroy, OnInit, Type, ViewContainerRef, } from '@angular/core';
import { DynamicContentsDirective } from '../../shared/directives/dynamic-contents.directive';
import { BehaviorSubject, merge } from 'rxjs';
import { share, skip, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
var lastId = 0;
var ReportWidgetDynamicComponent = /** @class */ (function () {
    function ReportWidgetDynamicComponent(i18nService, _componentFactoryResolver, changeDetectorRef, elementRef) {
        this.i18nService = i18nService;
        this._componentFactoryResolver = _componentFactoryResolver;
        this.changeDetectorRef = changeDetectorRef;
        this.elementRef = elementRef;
        this.headerLevel = 3;
        this.reportWidgetHeaderId = "report-widget-header-" + lastId++;
        this._loading$ = new BehaviorSubject(false);
    }
    Object.defineProperty(ReportWidgetDynamicComponent.prototype, "widgetNoDataMessage", {
        get: function () {
            if (!this.widget || !this.widget.noDataMessage) {
                return null;
            }
            var noData = this.widget.noDataMessage;
            return noData instanceof Function ? noData() : noData;
        },
        enumerable: true,
        configurable: true
    });
    ReportWidgetDynamicComponent.prototype.ngOnInit = function () {
        if (this.widgetComponentType)
            this.setComponent();
    };
    ReportWidgetDynamicComponent.prototype.ngOnDestroy = function () {
        this.widgetConfigSubscription && this.widgetConfigSubscription.unsubscribe();
        this.dataSubscription && this.dataSubscription.unsubscribe();
    };
    ReportWidgetDynamicComponent.prototype.setComponent = function () {
        var _this = this;
        var componentFactory = this._componentFactoryResolver.resolveComponentFactory(this.widgetComponentType);
        var viewContainerRef = this.contentsDirective.viewContainerRef;
        viewContainerRef.clear();
        var componentRef = viewContainerRef.createComponent(componentFactory);
        this.widgetComponent = componentRef.instance;
        Object.assign(this.widgetComponent, {
            params: this.params,
            dataOptions: this.dataOptions,
            ariaLabelledby: this.reportWidgetHeaderId,
            headerLevel: this.headerLevel
        });
        this.widgetComponent.setWidget();
        this.widgetConfigSubscription = this.widgetComponent.widgetConfig$.subscribe(function () {
            _this.widgetComponent.setWidget();
            _this.widget = _this.widgetComponent.widget;
            _this.widgetComponent.reload();
        });
        this.widget = this.widgetComponent.widget;
        this.data$ = this.widgetComponent.data$.asObservable().pipe(skip(1));
        this.loading$ = merge(this._loading$, this.widgetComponent.showLoader$).pipe(tap(function (isLoading) { return _this.changeDetectorRef.detectChanges(); }));
        this.error$ = this.widgetComponent.error$.pipe(tap(function (error) {
            _this._loading$.next(false);
            _this.changeDetectorRef.detectChanges();
        }), share());
        this._loading$.next(true);
        this.dataSubscription = this.data$.subscribe(function (data) {
            if (_this.widgetComponent.widgetConfig.hideOnNoData && !data)
                _this.elementRef.nativeElement.classList.add('hidden');
            else
                _this.elementRef.nativeElement.classList.remove('hidden');
            _this._loading$.next(false);
        }, function (error) { return _this._loading$.next(false); });
    };
    return ReportWidgetDynamicComponent;
}());
export { ReportWidgetDynamicComponent };
