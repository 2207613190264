
		<div
			class="tutorials-teaser-wrapper wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-center-vertical color-box-primary-75"
			*ngIf='isSimulationsAndTutorialsSectionVisible'
		>
			<img [src]="'/assets/images/onboarding/img_diy.svg' | basePath" [attr.alt]="'simulationAndTutorials.teaser.title' | i18n" style="height:100px;" />
			<div class="wcd-margin-large-left">
				<h1>{{ 'simulationAndTutorials.teaser.title' | i18n }}</h1>
				<h3>
					<a
						[routerLink]="['/tutorials']"
						data-track-id="TutorialsTeaser"
						data-track-type="Navigation"
						class="color-text-primary-700"
						*ngIf='!isTutorialsOnly; else tutorials_only'
					>
						{{ 'simulationAndTutorials.teaser.link' | i18n }}
						<wcd-shared-icon iconName="navigateForward"> </wcd-shared-icon>
					</a>
					<ng-template #tutorials_only>
						<a
							[routerLink]="['/tutorials']"
							data-track-id="TutorialsTeaser"
							data-track-type="Navigation"
							class="color-text-primary-700"
						>
							{{ 'tutorials.teaser.link' | i18n }}
							<wcd-shared-icon iconName="navigateForward"> </wcd-shared-icon>
						</a>
					</ng-template>
				</h3>
			</div>
		</div>
	