<div *ngIf="!hide" class="wcd-banner wcd-padding-none-left-sm-screens">
	<div class="wcd-flex-horizontal color-box-primary wcd-padding-small-bottom wcd-padding-small-top">
		<div class="wcd-flex-1" [class.wcd-flex-horizontal]="!isNarrowLayout" [class.wcd-flex-vertical]="isNarrowLayout">
			<div
				class="wcd-margin-left wcd-margin-right wcd-flex-horizontal wcd-flex-horizontal-align-items-center color-text-white wcd-flex-1"
			>
				<markdown class="wcd-banner-markdown">{{ text }}</markdown>
			</div>

			<div [class.wcd-margin-left]="isNarrowLayout" [class.wcd-margin-small-top]="isNarrowLayout">
				<fab-default-button
					*ngIf="linkOptions"
					[text]="linkOptions.buttonTextKey | i18n"
					[attr.data-track-id]="linkOptions.id"
					[attr.data-track-type]="'Button'"
					[styles]="buttonStyle"
					[ariaLabel]="linkOptions.buttonTextKey | i18n"
					[href]="linkOptions.navigateTo"
					target="_blank"
				></fab-default-button>
			</div>


		</div>
		<button
				*ngIf="showCloseButton"
				class="btn-icon-only btn-unstyled-with-outline wcd-font-size-s wcd-margin-horizontal color-text-white"
				(click)="close()"
				[attr.aria-label]="i18nService.strings.common_close"
			>
				<wcd-shared-icon [ariaLabel]="i18nService.strings.common_close" iconName="close"></wcd-shared-icon>
			</button>
	</div>
</div>
