import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { tenantContextCache, HandleTenantStatusRedirection } from '@wcd/auth';

const DEFAULT_ONBOARDING_REDIRECT = 'incidents';

@Injectable()
export class TenantStatusGuard implements CanActivate {
	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
		const routeData = route.data || {};
		const { allowMdeOptedOutOfMtp = true, allowOptedOutOfMtp = false } = routeData;
		const appConfigBackendData = tenantContextCache.appConfig;
		const redirectTo = (route.routeConfig && route.routeConfig.path) || DEFAULT_ONBOARDING_REDIRECT;
		return HandleTenantStatusRedirection(
			appConfigBackendData,
			redirectTo,
			allowMdeOptedOutOfMtp,
			allowOptedOutOfMtp
		);
	}
}
