import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('pagingControls', pagingDirective);

pagingDirective.$inject = [];

function pagingDirective() {
	return {
		restrict: 'EA',
		template: `
		<div class="noselect paging-btns">
        <div class="text-right">
            <div class="paging-btn paging-btn-disabled no-outline" ng-if="vm.pageIndex == 1 || vm.loading">
                <i class="icon icon-ChevronLeft"></i>
            </div>
    
            <div class="paging-btn paging-btn-enabled no-outline" ng-click="vm.prevPage()" ng-if="vm.pageIndex > 1 && !vm.loading">
                <i class="icon icon-ChevronLeft"></i>
            </div>
    
            <div class="paging-btn paging-btn-disabled no-outline" ng-if="!vm.moreAvailable || vm.loading || vm.nextLoading">
                <i class="icon icon-ChevronRight"></i>
            </div>
    
            <div class="paging-btn paging-btn-enabled no-outline" ng-click="vm.nextPage()" ng-if="vm.moreAvailable && !vm.loading && !vm.nextLoading">
                <i class="icon icon-ChevronRight"></i>
            </div>
        </div>
    </div>
		`,
		controllerAs: 'vm',
		bindToController: true,
		scope: {
			nextPage: '&',
			prevPage: '&',
			pageIndex: '=',
			moreAvailable: '=',
			loading: '=',
			nextLoading: '=',
		},
		controller: [function() {}],
	};
}
