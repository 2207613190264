import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { PanelsModule } from '@wcd/panels';
import { WcdSharedModule } from '@wcd/shared';
import { GoHuntService } from './services/go-hunt.service';

@NgModule({
	imports: [SharedModule, PanelsModule, WcdSharedModule],
	declarations: [],
	providers: [GoHuntService],
	exports: [],
	entryComponents: [],
})
export class GoHuntModule {}
