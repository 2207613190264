import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import * as EntityTypes from './hunting-query.models';
type EntityTypeName = EntityTypes.EntityTypeName;
type KustoFieldType = EntityTypes.KustoFieldType;

@ValueObject({
	singularName: 'Hunting schema',
	pluralName: 'Hunting schemas',
	readonly: true,
})
export class HuntingQuerySchemaField extends ModelBase {
	@EntityField({ data: 'Name' })
	readonly name: string;

	@EntityField({ data: 'Type' })
	readonly type: KustoFieldType;

	@EntityField({
		data: 'Entity',
		parse: value => (isNil(value) ? undefined : value),
	})
	readonly entityName?: EntityTypeName;
}
