import { LocaleConfigService } from '@wcd/localization';
import { skip } from 'rxjs/operators';

declare var angular: angular.IAngularStatic;

export let SevilleAppStateModule = angular
	.module('seville.appState', [])
	.factory('appStateService', appStateService);

appStateService.$inject = ['$log', '$rootScope', '$state', 'localeConfigService'];

function appStateService($log, $rootScope, $state, localeConfigService: LocaleConfigService) {
	let isSeville: boolean = false;

	return {
		setGlobalAlertMessage: function(msg) {
			$rootScope.$broadcast('appState:globalalert', { msg: msg });
		},
		get isSeville() {
			return isSeville;
		},
		alerts: {
			append: function(type, msg) {
				if (type !== 'success' && type !== 'info' && type !== 'warning' && type !== 'error') {
					$log.error('Alerts of type ' + type + ' are not supported');

					return;
				}

				$rootScope.$broadcast('appState:alert', { type: type, msg: msg });
			},
		},
		init: function() {
			localeConfigService.config$.pipe(skip(1)).subscribe(() => {
				if (isSeville) $state.reload();
			});
		},
		setIsSeville: function(_isSeville: boolean) {
			isSeville = _isSeville === true;
		},
	};
}
