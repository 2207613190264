var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import * as AntiVirusTypes from './antivirus-scan/antivirus-scan.models';
import * as RawIsolationTypes from './isolation/isolation-requests.models';
import * as RestrictPolicyTypes from './restrict-app-execution/restrict-app-execution.models';
import { MachineRequestErrorCode } from './machine-request-error-code.value-object';
var ɵ0 = function (_a) {
    var type = _a.Type;
    switch (type) {
        case 'ForensicsResponse':
            return MachineCollectInvesigationPackageRequest;
        case 'IsolationResponse':
            return MachineIsolationRequest;
        case 'RestrictExecutionResponse':
            return MachineRestrictAppExecutionRequest;
        case 'ScanResponse':
            return MachineRunAntivirusScanRequest;
        case 'TroubleshootResponse':
            return TroubleshootModeRequest;
        case 'LogsCollectionResponse':
            return MachineLogsCollectionRequest;
        default:
            return null;
    }
};
var MachineRequestBase = /** @class */ (function (_super) {
    __extends(MachineRequestBase, _super);
    function MachineRequestBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'MachineId' }),
        __metadata("design:type", Object)
    ], MachineRequestBase.prototype, "machineId", void 0);
    __decorate([
        EntityField({ data: 'CreationDateTimeUtc' }),
        __metadata("design:type", Date)
    ], MachineRequestBase.prototype, "creationDateTimeUtc", void 0);
    __decorate([
        EntityField({ data: 'LastUpdateTimeUtc' }),
        __metadata("design:type", Date)
    ], MachineRequestBase.prototype, "lastUpdateTimeUtc", void 0);
    __decorate([
        EntityField({ data: 'Requestor' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "requestor", void 0);
    __decorate([
        EntityField({ data: 'RequestorComment' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "requestorComment", void 0);
    __decorate([
        EntityField({ data: 'CancellationRequestor' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "cancellationRequestor", void 0);
    __decorate([
        EntityField({ data: 'CancellationComment' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "cancellationComment", void 0);
    __decorate([
        EntityField({ data: 'CancellationDateTimeUtc' }),
        __metadata("design:type", Date)
    ], MachineRequestBase.prototype, "cancellationDateTimeUtc", void 0);
    __decorate([
        EntityField({ data: 'RequestGuid' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "requestGuid", void 0);
    __decorate([
        EntityField({ data: 'RequestStatus' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "requestStatus", void 0);
    __decorate([
        EntityField({ data: 'Type' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "type", void 0);
    __decorate([
        EntityField({ data: 'ErrorHResult' }),
        __metadata("design:type", MachineRequestErrorCode)
    ], MachineRequestBase.prototype, "errorCode", void 0);
    __decorate([
        EntityField({ data: 'PackageIdentity' }),
        __metadata("design:type", String)
    ], MachineRequestBase.prototype, "packageIdentity", void 0);
    MachineRequestBase = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Device request',
            pluralName: 'Device requests',
            modelWith: ɵ0,
        })
    ], MachineRequestBase);
    return MachineRequestBase;
}(ModelBase));
export { MachineRequestBase };
/**
 * All of these subtypes are defined here to avoid circular dependency.
 * `MachineRequestBase` references them for the `modelWith` function, and all the sub-types `extend MachineRequestBase`.
 */
var MachineCollectInvesigationPackageRequest = /** @class */ (function (_super) {
    __extends(MachineCollectInvesigationPackageRequest, _super);
    function MachineCollectInvesigationPackageRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Sha256' }),
        __metadata("design:type", String)
    ], MachineCollectInvesigationPackageRequest.prototype, "sha256", void 0);
    MachineCollectInvesigationPackageRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Device forensics request',
            pluralName: '',
        })
    ], MachineCollectInvesigationPackageRequest);
    return MachineCollectInvesigationPackageRequest;
}(MachineRequestBase));
export { MachineCollectInvesigationPackageRequest };
var ɵ1 = function (rawValue) {
    return AntiVirusTypes.QueryAntivirusScanTypeRaw[rawValue];
};
var MachineRunAntivirusScanRequest = /** @class */ (function (_super) {
    __extends(MachineRunAntivirusScanRequest, _super);
    function MachineRunAntivirusScanRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'ScanType',
            parse: ɵ1,
        }),
        __metadata("design:type", String)
    ], MachineRunAntivirusScanRequest.prototype, "scanType", void 0);
    MachineRunAntivirusScanRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Device scan request',
            pluralName: '',
        })
    ], MachineRunAntivirusScanRequest);
    return MachineRunAntivirusScanRequest;
}(MachineRequestBase));
export { MachineRunAntivirusScanRequest };
var ɵ2 = function (rawValue) {
    return RawIsolationTypes.RawIsolationAction[rawValue];
}, ɵ3 = function (rawValue) { return RawIsolationTypes.RawIsolationType[rawValue]; };
var MachineIsolationRequest = /** @class */ (function (_super) {
    __extends(MachineIsolationRequest, _super);
    function MachineIsolationRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'Action',
            parse: ɵ2,
        }),
        __metadata("design:type", String)
    ], MachineIsolationRequest.prototype, "action", void 0);
    __decorate([
        EntityField({
            data: 'IsolationType',
            parse: ɵ3,
        }),
        __metadata("design:type", String)
    ], MachineIsolationRequest.prototype, "isolationType", void 0);
    MachineIsolationRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Device isolation request',
            pluralName: '',
        })
    ], MachineIsolationRequest);
    return MachineIsolationRequest;
}(MachineRequestBase));
export { MachineIsolationRequest };
var ɵ4 = function (rawValue) {
    return RestrictPolicyTypes.QueryExecutionPolicyTypeRaw[rawValue];
};
var MachineRestrictAppExecutionRequest = /** @class */ (function (_super) {
    __extends(MachineRestrictAppExecutionRequest, _super);
    function MachineRestrictAppExecutionRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'PolicyType',
            parse: ɵ4,
        }),
        __metadata("design:type", String)
    ], MachineRestrictAppExecutionRequest.prototype, "policyType", void 0);
    MachineRestrictAppExecutionRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Device restrict app execution request',
            pluralName: '',
        })
    ], MachineRestrictAppExecutionRequest);
    return MachineRestrictAppExecutionRequest;
}(MachineRequestBase));
export { MachineRestrictAppExecutionRequest };
var TroubleshootModeRequest = /** @class */ (function (_super) {
    __extends(TroubleshootModeRequest, _super);
    function TroubleshootModeRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'TroubleshootExpirationDateTimeUtc',
        }),
        __metadata("design:type", Date)
    ], TroubleshootModeRequest.prototype, "troubleshootExpirationDateTimeUtc", void 0);
    __decorate([
        EntityField({
            data: 'TroubleshootStartDateTimeUtc',
        }),
        __metadata("design:type", Date)
    ], TroubleshootModeRequest.prototype, "troubleshootStartDateTimeUtc", void 0);
    __decorate([
        EntityField({
            data: 'RequestSource'
        }),
        __metadata("design:type", String)
    ], TroubleshootModeRequest.prototype, "requestSource", void 0);
    __decorate([
        EntityField({
            data: 'TroubleshootState'
        }),
        __metadata("design:type", Number)
    ], TroubleshootModeRequest.prototype, "troubleshootState", void 0);
    TroubleshootModeRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Troubleshoot request',
            pluralName: '',
        })
    ], TroubleshootModeRequest);
    return TroubleshootModeRequest;
}(MachineRequestBase));
export { TroubleshootModeRequest };
var MachineLogsCollectionRequest = /** @class */ (function (_super) {
    __extends(MachineLogsCollectionRequest, _super);
    function MachineLogsCollectionRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Sha256' }),
        __metadata("design:type", String)
    ], MachineLogsCollectionRequest.prototype, "sha256", void 0);
    MachineLogsCollectionRequest = __decorate([
        ValueObject({
            readonly: true,
            singularName: 'Collect support logs request',
            pluralName: '',
        })
    ], MachineLogsCollectionRequest);
    return MachineLogsCollectionRequest;
}(MachineRequestBase));
export { MachineLogsCollectionRequest };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
