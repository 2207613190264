var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
var RuleNameStepComponent = /** @class */ (function (_super) {
    __extends(RuleNameStepComponent, _super);
    function RuleNameStepComponent(i18n, changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.changeDetectorRef = changeDetectorRef;
        _this.MessageBarType = MessageBarType;
        /*
            Some types of notifications include status field.
            If includeStatus == true, then there is a checkbox which enables the user to enable/disable the created rule.
    
            Rule description is changed between different types of notifications.
        */
        _this.includeStatus = false;
        _this.nameLength = 100;
        _this.descriptionLength = 1000;
        _this.nameRegExp = 'notEmpty';
        _this.descriptionRegExp = '';
        return _this;
    }
    RuleNameStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setStepValidation(true);
        setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
    };
    RuleNameStepComponent.prototype.onModelChange = function () {
        this.changeDetectorRef.detectChanges();
    };
    return RuleNameStepComponent;
}(WizardBaseStep));
export { RuleNameStepComponent };
