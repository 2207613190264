/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entity-minimized.component.base.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "./entity-minimized-details.component.base";
import * as i6 from "../../services/global-entity-types.service";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_EntityMinimizedDetailsComponentBase = [i0.styles];
var RenderType_EntityMinimizedDetailsComponentBase = i1.ɵcrt({ encapsulation: 0, styles: styles_EntityMinimizedDetailsComponentBase, data: {} });
export { RenderType_EntityMinimizedDetailsComponentBase as RenderType_EntityMinimizedDetailsComponentBase };
export function View_EntityMinimizedDetailsComponentBase_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "wcd-shared-icon", [["class", "wcd-entity-details-minimized-icon-layout"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(2, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconName; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EntityMinimizedDetailsComponentBase_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "minimized-entity", [], null, null, null, View_EntityMinimizedDetailsComponentBase_0, RenderType_EntityMinimizedDetailsComponentBase)), i1.ɵdid(1, 114688, null, 0, i5.EntityMinimizedDetailsComponentBase, [i6.GlobalEntityTypesService, i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntityMinimizedDetailsComponentBaseNgFactory = i1.ɵccf("minimized-entity", i5.EntityMinimizedDetailsComponentBase, View_EntityMinimizedDetailsComponentBase_Host_0, { entity: "entity", entityType: "entityType" }, {}, []);
export { EntityMinimizedDetailsComponentBaseNgFactory as EntityMinimizedDetailsComponentBaseNgFactory };
