var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityMinimizedDetailsComponentBase } from './entity-minimized-details.component.base';
import { machineHealthStatuses } from '@wcd/domain';
var MachineMinimizedDetailsComponent = /** @class */ (function (_super) {
    __extends(MachineMinimizedDetailsComponent, _super);
    function MachineMinimizedDetailsComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MachineMinimizedDetailsComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        var riskScore = this.entity.riskScore;
        this.machineRiskClass =
            riskScore && riskScore.id && "machine-risk-" + riskScore.id.toString().toLowerCase();
        var status = this.entity.status;
        this.isActive = status && status.id && status.id === machineHealthStatuses.Active.id;
        this.description =
            status &&
                riskScore &&
                this.i18nService.get('machines.entityDetails.minimized.tooltip', {
                    risk: this.i18nService.get(this.entity.riskScore.nameI18nKey),
                    status: this.entity.status.name,
                });
        this.riskDescription =
            riskScore &&
                this.i18nService.get('machines.entityDetails.minimized.risk', {
                    risk: this.i18nService.get(this.entity.riskScore.nameI18nKey),
                });
        this.statusDescription =
            status &&
                this.i18nService.get('machines.entityDetails.minimized.status', {
                    status: this.entity.status.name,
                });
    };
    return MachineMinimizedDetailsComponent;
}(EntityMinimizedDetailsComponentBase));
export { MachineMinimizedDetailsComponent };
