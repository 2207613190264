import { Component, OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';

@Component({
	templateUrl: './baseline-profile-completed.component.html',
})
export class BaselineProfileCompletedComponent extends WizardBaseStep<ProfileForCreationFormData> implements OnInit {
	title: string;

	constructor(private i18nService: I18nService) {
		super();
	}

	ngOnInit(){
		this.title = this.data.isUpdateFlow
			? this.i18nService.strings.tvm_baseline_profile_wizard_profileUpdated_title
			: this.i18nService.strings.tvm_baseline_profile_wizard_profileCreated_title
	}
}
