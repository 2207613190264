// tslint:disable:template-click-events-have-key-events
import { OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DomainUrl } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DomainUrlFields } from '../services/domain-urls.fields';
import { DomainsService } from '../services/domains.service';
import { of } from 'rxjs';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '../../../utils/lazy';
import { PreferencesService } from '@wcd/config';
import { TIME_RANGE_DEFAULT_PREFERENCE_ID, WEB_THREAT_CATEGORY_FILTERS_ID, DEFAULT_WEB_THREAT_CATEGORY_FILTER, DOMAIN_TIME_RANGES, DEFAULT_DOMAIN_TIME_RANGE, DEFAULT_WEB_THREAT_STATUS_FILTER, DEFAULT_WEB_THREAT_SEVERITY_FILTER, } from './domains.dataview';
var DomainUrlsComponent = /** @class */ (function () {
    function DomainUrlsComponent(paris, domainUrlFields, domainsService, globalEntityTypesService, timeRangesService, preferencesService) {
        var _this = this;
        this.paris = paris;
        this.domainUrlFields = domainUrlFields;
        this.domainsService = domainsService;
        this.timeRangesService = timeRangesService;
        this.preferencesService = preferencesService;
        this.hideControls = true;
        this.allowPaging = true;
        this.navigateOnChange = true;
        this.disableSelection = true;
        this.hideCommandBarOnEmptyData = true;
        this.fullUrlList = false;
        this.searchEnabled = false;
        this._timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick(DOMAIN_TIME_RANGES);
        });
        if (!this.domainRepository)
            this.domainRepository = paris.getRepository(DomainUrl);
        this.entityType = globalEntityTypesService.getEntityType(DomainUrl);
    }
    Object.defineProperty(DomainUrlsComponent.prototype, "timeRanges", {
        get: function () {
            return this._timeRanges.value;
        },
        enumerable: true,
        configurable: true
    });
    DomainUrlsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.domainRepository)
            this.domainRepository = this.paris.getRepository(DomainUrl);
        this.currentTimeRange =
            this.timeRanges.find(function (range) { return range.id === _this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID); }) || this.timeRanges[2];
        var filtersPreferences = this.preferencesService.getPreference(WEB_THREAT_CATEGORY_FILTERS_ID);
        if (filtersPreferences) {
            this.currentlySelectedWebThreatTypeFilters = filtersPreferences.webThreatCategory
                ? filtersPreferences.webThreatCategory
                : DEFAULT_WEB_THREAT_CATEGORY_FILTER;
            this.currentlySelectedStatusFilters = filtersPreferences.status
                ? filtersPreferences.status
                : DEFAULT_WEB_THREAT_STATUS_FILTER;
            this.currentlySelectedSeverityFilters = filtersPreferences.severity
                ? filtersPreferences.severity
                : DEFAULT_WEB_THREAT_SEVERITY_FILTER;
        }
        else {
            this.currentlySelectedWebThreatTypeFilters = DEFAULT_WEB_THREAT_CATEGORY_FILTER;
            this.currentlySelectedStatusFilters = DEFAULT_WEB_THREAT_STATUS_FILTER;
            this.currentlySelectedSeverityFilters = DEFAULT_WEB_THREAT_SEVERITY_FILTER;
        }
        this.setFixedOptions();
    };
    DomainUrlsComponent.prototype.setFixedOptions = function () {
        var _this = this;
        this.dataviewFixedOptions = Object.assign({
            id: this.domainId,
            fullList: this.fullUrlList,
            lookBackInDays: !isCustomTimeRangeValue(this.currentTimeRange)
                ? this.currentTimeRange.value
                : DEFAULT_DOMAIN_TIME_RANGE,
            webThreatCategory: this.currentlySelectedWebThreatTypeFilters,
            status: this.currentlySelectedStatusFilters,
            severity: this.currentlySelectedSeverityFilters,
        }, this.fixedOptions);
        this.domainRepository
            .query({
            where: {
                id: this.domainId,
                fullList: this.fullUrlList,
                lookBackInDays: !isCustomTimeRangeValue(this.currentTimeRange)
                    ? this.currentTimeRange.value
                    : DEFAULT_DOMAIN_TIME_RANGE,
                webThreatCategory: this.currentlySelectedWebThreatTypeFilters,
                status: this.currentlySelectedStatusFilters,
                severity: this.currentlySelectedSeverityFilters,
            },
        }, { allowCache: true })
            .subscribe(function (val) {
            if (val != null) {
                if (val.count !== 0) {
                    _this.displayViewAllLink = of(true);
                }
            }
        });
    };
    DomainUrlsComponent.prototype.showAllDomains = function () {
        this.domainsService.showAllDomains(this.domainId);
    };
    return DomainUrlsComponent;
}());
export { DomainUrlsComponent };
