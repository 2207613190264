
		<span *ngIf="countOnly; else machinesList"> {{ machines?.length }} devices </span>
		<ng-template #machinesList>
			<ul class="investigated-machines-list" *ngIf="machines">
				<li *ngFor="let machine of (machines | slice: 0:currentLimit)">
					<machine-link [machine]="machine"></machine-link>
				</li>
			</ul>
			<a
				*ngIf="isMoreButtonShown"
				data-track-id="MachinesFullListToggle"
				data-track-type="Toggle"
				(click)="onShowMoreClick()"
			>
				{{ isFullListShown ? 'View less' : 'View all' }}
			</a>
		</ng-template>
	