import { Entity } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { alertCategoryValues } from './alert-category.entity.values';

@Entity({
	singularName: 'Alert Category',
	pluralName: 'Alert Categories',
	values: alertCategoryValues,
})
export class AlertCategory extends StatusModel {}
