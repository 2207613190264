import { FabIconModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HelpComponent } from './help.component';
import { TooltipDirective } from './tooltip.directive';
import { WcdTooltipPlaceholderComponent } from './tooltip-placeholder.component';

@NgModule({
	imports: [CommonModule, FabIconModule],
	declarations: [HelpComponent, TooltipDirective, WcdTooltipPlaceholderComponent],
	exports: [HelpComponent, TooltipDirective, WcdTooltipPlaceholderComponent],
})
export class TooltipsModule {}
