import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AirsEntityTypeResults, RemediationStatusType } from '@wcd/domain';
import {
	ColorMap,
	EventModel,
	KindTextFormatter,
} from '../../shared/components/events-summary-bar/events-summary-bar.component';
import { LegendItem, ValueFormatter } from '@wcd/charts';
import { EvidenceTypeColorService } from '../../shared/services/evidence-type-color.service';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { EvidenceCountSummary } from '../../@entities/airs_entities/services/airs-entities.service';
import { Strings } from '@wcd/i18n';

export const OTHER_STATUS_TYPE_NAME = 'other';
export type OTHER_STATUS_TYPE = 'other';
type EvidenceConfig = EventModel<RemediationStatusType | OTHER_STATUS_TYPE> & LegendItem;

const evidenceTypeDisplayKeysMap: Record<RemediationStatusType | OTHER_STATUS_TYPE, keyof Strings> = {
	[RemediationStatusType.Remediated]: 'incident_overview_evidence_data_categories_remediated',
	[RemediationStatusType.NotFound]: 'incident_overview_evidence_data_categories_notFound',
	[RemediationStatusType.Unremediated]: 'incident_overview_evidence_data_categories_unremediated',
	[RemediationStatusType.Failed]: 'incident_overview_evidence_data_categories_failed',
	[RemediationStatusType.Prevented]: 'incident_overview_evidence_data_categories_prevented',
	[RemediationStatusType.Blocked]: 'incident_overview_evidence_data_categories_blocked',
	[RemediationStatusType.PendingApproval]: 'incident_overview_evidence_data_categories_pendingApproval',
	[RemediationStatusType.Declined]: 'incident_overview_evidence_data_categories_declined',
	[RemediationStatusType.Running]: 'incident_overview_evidence_data_categories_running',
	[RemediationStatusType.Active]: 'incident_overview_evidence_data_categories_active',
	[RemediationStatusType.PartiallyRemediated]:
		'incident_overview_evidence_data_categories_partially_remediated',
	[OTHER_STATUS_TYPE_NAME]: 'incident_overview_evidence_data_categories_other',
};

@Component({
	selector: 'evidence-summary',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<events-summary-bars
			[events]="evidence"
			[kindColorMap]="evidenceColorMap"
			[displayTextFormatter]="barTextFormatter"
			size="large"
		>
		</events-summary-bars>
		<wcd-chart-legend
			class="wcd-full-width wcd-margin-small-top"
			orientation="horizontal"
			[wrap]="true"
			[showValues]="false"
			[valueFormatter]="legendTextFormatter"
			[items]="evidence"
		></wcd-chart-legend>
	`,
})
export class EvidenceSummaryComponent implements OnChanges {
	@Input() evidenceSummary: Array<AirsEntityTypeResults>;
	@Input() countSummary: EvidenceCountSummary;

	evidence: ReadonlyArray<EvidenceConfig>;

	readonly evidenceColorMap: ColorMap<string>;

	readonly barTextFormatter: KindTextFormatter<RemediationStatusType>;

	readonly legendTextFormatter: ValueFormatter;

	constructor(
		private readonly evidenceTypeColorService: EvidenceTypeColorService,
		private displayTextPipe: DisplayTextPipe
	) {
		this.evidenceColorMap = {
			type: 'class',
			map: evidenceTypeColorService.backgroundColorsClassMap,
		};

		this.barTextFormatter = evidenceStatus =>
			displayTextPipe.transform(evidenceStatus, evidenceTypeDisplayKeysMap);

		this.legendTextFormatter = count => `(${count})`;
	}

	ngOnChanges() {
		this.evidence = this.createEvidenceConfig(this.evidenceSummary);
	}

	private createEvidenceConfig(airsEntityTypeResults: Array<AirsEntityTypeResults>): Array<EvidenceConfig> {
		const groupedResults = airsEntityTypeResults.reduce(
			(acc, current) => {
				Object.values(RemediationStatusType).forEach(status => {
					acc[status] = (acc[status] || 0) + current.getEntityCountByStatus(status);
				});
				return acc;
			},
			{} as Record<RemediationStatusType, number>
		);

		const evidenceConfigList: Array<EvidenceConfig> = Object.entries(groupedResults)
			.filter(([_, count]) => !!count)
			.map(
				([status, count]: [RemediationStatusType, number]) =>
					<EvidenceConfig>{
						kind: status,
						count,
						name: this.displayTextPipe.transform(status, evidenceTypeDisplayKeysMap),
						iconClassName: this.evidenceTypeColorService.fontColorsClassMap.get(status),
						value: count,
					}
			);

		const othersCount = this.countSummary
			? this.countSummary.totalEvidenceCount - this.countSummary.totalRemediationStatusCount
			: 0;
		if (othersCount > 0) {
			evidenceConfigList.push({
				kind: OTHER_STATUS_TYPE_NAME,
				count: othersCount,
				name: this.displayTextPipe.transform(OTHER_STATUS_TYPE_NAME, evidenceTypeDisplayKeysMap),
				iconClassName: this.evidenceTypeColorService.fontColorsClassMap.get(OTHER_STATUS_TYPE_NAME),
				value: othersCount,
			});
		}
		return evidenceConfigList;
	}
}
