const IPV6 =
	'((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)(\\.(25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]?\\d)){3}))|:)))(%.+)?';
const IPV4 =
	'(((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:\\d+)?)|((?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}(:\\d+)?))';
const IP = `(?:${IPV6}|${IPV4})`;
const DOMAIN = '(?=.{1,254}$)((?=[a-z0-9-—]{1,63}.)(xn--+)?[a-z0-9]+([-—]+[a-z0-9]+)*.)+[a-zd]{2,63}';
const SEGMENT = '((?:[A-F0-9]{1,4}:){7}[A-F0-9]{1,4}(:\\d+)?)';
const URL = '(?:([\\w-]+)(?:(\\.[\\w-]+)+)([\\w.,@?^=%!&amp;:/~+#-]*[\\w@?^=%!&amp;/~+#-])?)';
const HTTP = '^(http)://';
const HTTPS = '^(https)://';
const HTTP_OR_HTTPS = '^(https?)://';
const FTP = '^(ftp)://';
const IPV4_OR_IPV4_RANGE_CIDR = `${IPV4}(\\/([0-9]|[1-2][0-9]|3[0-2]))?`;
const IPV6_OR_IPV6_RANGE_CIDR = `${IPV6}(\\/([0-9]|[1-9][0-9]|1[0-1][0-9]|12[0-8]))?`;
const IP_OR_IP_RANGE_CIDR = `(?:${IPV4_OR_IPV4_RANGE_CIDR}|${IPV6_OR_IPV6_RANGE_CIDR})`;
const IP_OR_IP_RANGE_CIDR_COMMA_SEPARATED = `(?:${IP_OR_IP_RANGE_CIDR})((,\\s*|\\s+)(?:${IP_OR_IP_RANGE_CIDR}))*`;

export let RegExpService = {
	notEmpty: /\S+/,
	commaSeparatedList: /[\w\d\,]+/i,
	domain: new RegExp(`^${DOMAIN}$`, 'i'),
	// email validator is following the RFC2822 https://regexr.com/2rhq7 & https://gist.github.com/gregseth/5582254
	email: /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
	emailWithSpecialChars: /^([\w-]+(?:[.+][\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i,
	fileHash: /^((?:[a-f0-9]{32})|(?:[a-f0-9]{40})|(?:[a-f0-9]{64}))$/i,
	folderPath: /((\\|\/)|\*)$/,
	hostName: /^[A-Za-z0-9][\w\-_.]{0,63}$/,
	ip: new RegExp(`^${IP}$`, 'i'),
	ipv4: new RegExp(`^${IPV4}$`, 'i'),
	ipOrSegment: new RegExp(`^${IP}|${SEGMENT}$`, 'i'),
	ipOrDomain: new RegExp(`^${IP}|${DOMAIN}$`, 'i'),
	integer: /^\d+$/,
	url: new RegExp(`${HTTP_OR_HTTPS}${URL}$`),
	httpUrl: new RegExp(`${HTTP}${URL}$`),
	httpsIp: new RegExp(`${HTTPS}${IP}$`),
	httpsUrl: new RegExp(`${HTTPS}${URL}$`),
	httpIpOrUrl: new RegExp(`${HTTP}(${IP}|${URL})$`),
	httpsIpOrUrl: new RegExp(`${HTTPS}(${IP}|${URL})$`),
	httpOrHttpsOrFtpUrl: new RegExp(`(${HTTP_OR_HTTPS}|${FTP})${URL}$`),
	urlSchema: /^\/\/|^.*?:(\/\/)?/,
	username: /^[^@]+$/,
	license: /^[A-Z0-9]+$/,
	sha1: /^(?:[a-f0-9]{40})$/i,
	md5: /(^|\s)([0-9a-f]{32})(?=\s|$)/i,
	sha256: /(^|\s)([0-9a-f]{64})(?=\s|$)/i,
	rs4: /10.(4)([0-9]{3})./,
	guid: /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
	sid: /^S-\d-\d+-(\d+-){1,14}\d+$/,
	senseMachineIdRegex: /^[A-Fa-f0-9]{40}$/,
	ipOrIpRangeCidr: new RegExp(`^${IP_OR_IP_RANGE_CIDR}$`, 'i'),
	urlOrDomain: new RegExp(`^(${HTTP_OR_HTTPS}${URL})|${DOMAIN}$`, 'i'),
	ipCommaSeparatedOrRangeCidr: new RegExp(`^${IP_OR_IP_RANGE_CIDR_COMMA_SEPARATED}$`, 'i'),
	ipv4OrRangeCidr: new RegExp(`^${IPV4_OR_IPV4_RANGE_CIDR}$`, 'i'),
	tvmVulnerabilityNotificationsAllowedText: new RegExp(`^[a-zA-Z0-9_ ,.:;=+-/!%&]*$`),
	anchorTag: /(<a)(.*)(<\/a>)/
};
