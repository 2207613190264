import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { alertHistoryItemTypes } from './alert-history-item-type.values';

@Entity({
	singularName: 'Alert History Item Type',
	pluralName: 'Alert History Item Types',
	values: alertHistoryItemTypes,
})
export class AlertHistoryItemType extends EntityModelBase {
	@EntityField() icon: string;
}
