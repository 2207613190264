import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { Observable } from 'rxjs';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { merge } from 'lodash-es';

const INVESTIGATIONS_API_ENDPOINT = '/investigations';

@Injectable()
export class InvestigationsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + INVESTIGATIONS_API_ENDPOINT;
	}

	cancelInvestigations(investigationIds: Array<number | string>, data?, options?): Observable<any> {
		return this.post(
			'terminate',
			merge({ investigations_ids: investigationIds }, data),
			this.getUrlParams(options)
		);
	}
}
