var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { Dictionary, PollingService } from '@wcd/config';
import { BehaviorSubject, from, of } from 'rxjs';
import { LocalStorageService } from '@wcd/shared';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { SaveUserPreferencesApiCall, UserPreferences } from '@wcd/domain';
import { get, isEqual } from 'lodash-es';
var PREFERENCES_REFRESH_INTERVAL = 60 * 1000;
var PREFERENCES_KEY = 'user_preferences';
var PreferencesService = /** @class */ (function () {
    function PreferencesService(authService, localStorageService, paris, pollingService) {
        this.authService = authService;
        this.localStorageService = localStorageService;
        this.paris = paris;
        this.pollingService = pollingService;
        this._preferences = new Dictionary();
        this._preferencesSubject = new BehaviorSubject(undefined);
        this.preferences$ = this._preferencesSubject.asObservable();
        this.preferencesRepository = this.paris.getRepository(UserPreferences);
    }
    Object.defineProperty(PreferencesService.prototype, "preferences", {
        get: function () {
            return this._preferences;
        },
        enumerable: true,
        configurable: true
    });
    PreferencesService.prototype.init = function () {
        var _this = this;
        // listens to changes on other tabs\computers
        this.refreshPreferencesSubscription = this.pollingService
            .poll(PREFERENCES_REFRESH_INTERVAL, PREFERENCES_REFRESH_INTERVAL)
            .pipe(switchMap(function () { return from(_this.getPersistedPreferences()); }))
            .subscribe(function (preferences) {
            if (isEqual(_this._preferences, preferences))
                return;
            _this._preferences = preferences;
            _this._preferencesSubject.next(_this._preferences);
        });
        return from(this.getPersistedPreferences()).pipe(tap(function (preferences) {
            _this.incidentNameColumnConvertor(preferences);
            _this._preferences = preferences;
            _this._preferencesSubject.next(_this._preferences);
        }));
    };
    PreferencesService.prototype.setPreference = function (preferenceId, value) {
        this.preferences.set(preferenceId, value);
        this.persistPreferences();
    };
    PreferencesService.prototype.getPreference = function (preferenceId) {
        return this.preferences.get(preferenceId);
    };
    PreferencesService.prototype.removePreference = function (preferenceId) {
        this.preferences.delete(preferenceId);
        this.persistPreferences();
    };
    PreferencesService.prototype.getPersistedPreferences = function () {
        return __awaiter(this, void 0, void 0, function () {
            var userPreferences, e_1, preferences;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        userPreferences = null;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4 /*yield*/, this.preferencesRepository.getItemById(null).toPromise()];
                    case 2:
                        userPreferences = _a.sent();
                        return [3 /*break*/, 4];
                    case 3:
                        e_1 = _a.sent();
                        return [3 /*break*/, 4];
                    case 4:
                        preferences = (userPreferences && userPreferences.preferences) || null;
                        if (!preferences || !preferences.size) {
                            return [2 /*return*/, this.getLocalStoragePreferences()];
                        }
                        return [2 /*return*/, Dictionary.fromIndex(preferences.get(PREFERENCES_KEY))];
                }
            });
        });
    };
    PreferencesService.prototype.persistPreferences = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.authService.currentUser)
                            return [2 /*return*/];
                        return [4 /*yield*/, this.paris
                                .apiCall(SaveUserPreferencesApiCall, {
                                key: PREFERENCES_KEY,
                                preferences: this.preferences.stringify(),
                            })
                                .pipe(catchError(function () { return of(null); }))
                                .toPromise()];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PreferencesService.prototype.getLocalStoragePreferences = function () {
        var localStorageData;
        try {
            localStorageData = this.localStorageService.getItem(this.getStorageKey());
        }
        catch (e) {
            console.warn('Failed to access local storage');
        }
        return Dictionary.fromIndex(localStorageData ? JSON.parse(localStorageData) : null);
    };
    PreferencesService.prototype.getStorageKey = function () {
        var currentUserName = this.authService.currentUser
            ? this.authService.currentUser.name
            : 'defaultUser';
        return currentUserName + "_preferences";
    };
    PreferencesService.prototype.incidentNameColumnConvertor = function (preferences) {
        var dataViewIncidents = preferences.get('dataView_incidents');
        if (!!dataViewIncidents) {
            var visibleFields = dataViewIncidents['visibleFields'];
            var nameColumn = get(visibleFields, 0);
            // replace old col id with the new id (title => name)
            if (nameColumn === 'title') {
                visibleFields[0] = 'name';
            }
        }
    };
    PreferencesService.prototype.ngOnDestroy = function () {
        this._preferencesSubject.complete();
        this.refreshPreferencesSubscription && this.refreshPreferencesSubscription.unsubscribe();
    };
    return PreferencesService;
}());
export { PreferencesService };
