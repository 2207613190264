import { NgModule } from '@angular/core';
import { ReportWidgetDynamicComponent } from './components/report-widget.component';
import { ReportComponent } from './components/report.component';
import { ReportPageComponent } from './components/report.page.component';
import { ReportRowComponent } from './components/report-row.component';
import { ReportColumnComponent } from './components/report-column.component';
import { ReportWidgetHeaderComponent } from './components/report-widget-header.component';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../shared/shared.module';
import { DashboardsReportModule } from './widgets/dashboards/dashboards.report.module';
import { ReportWithFilters } from './components/report-with-filters.component';
import { FiltersModule } from '@wcd/ng-filters';
import { FabButtonModule, FabSpinnerModule } from '@angular-react/fabric';
import { AlertsSeveritySummaryComponent } from './components/alerts-severity-summary.component';
import { EvidenceSummaryComponent } from './components/evidence-summary.component';
import { BarsChartModule, LegendModule } from '@wcd/charts';
import { PanelsModule } from '@wcd/panels';
import { AlertsSeverityIncidentSummaryComponent } from './components/alerts-severity-incident-summary.component';
import { AlertSeverityIncidentSummaryTitleComponent } from './components/alert-severity-incident-summary.title.component';
import { PrettyNumberPipe } from '@wcd/prettify';
import { SharedReportsModule } from '../shared-reports/shared-reports.module';

@NgModule({
	imports: [
		SharedModule,
		DashboardsReportModule,
		FiltersModule,
		FabButtonModule,
		FabSpinnerModule,
		FiltersModule,
		BarsChartModule,
		LegendModule,
		PanelsModule,
		SharedReportsModule,
	],
	declarations: [
		ReportWidgetDynamicComponent,
		ReportComponent,
		ReportColumnComponent,
		ReportRowComponent,
		ReportPageComponent,
		ReportWidgetHeaderComponent,
		ReportWithFilters,
		AlertsSeveritySummaryComponent,
		EvidenceSummaryComponent,
		AlertsSeverityIncidentSummaryComponent,
		AlertSeverityIncidentSummaryTitleComponent,
	],
	exports: [
		ReportComponent,
		ReportPageComponent,
		RouterModule,
		ReportWithFilters,
		AlertsSeveritySummaryComponent,
		EvidenceSummaryComponent,
		AlertsSeverityIncidentSummaryComponent,
		AlertSeverityIncidentSummaryTitleComponent,
	],
	providers: [PrettyNumberPipe],
})
export class ReportsModule {}
