import { FlavorConfig } from '@wcd/scc-common';

export interface IIncidentsConfig {
	autoIr: FlavorConfig;
	associatedThreat: FlavorConfig;
	mailboxes: FlavorConfig;
	hunting: FlavorConfig;
	urlExplorerLink: FlavorConfig;
	evidenceOrgPrevalence: FlavorConfig;
	dataSensitivity: FlavorConfig;
	extendedAirsEntityTypes: FlavorConfig;
	alertFieldsExtended: FlavorConfig;
	evidenceEntityType: FlavorConfig;
	fileOpenPage: FlavorConfig;
	detectionSources: FlavorConfig;
	incidentGraph: FlavorConfig;
	associatedIncident: FlavorConfig;
	filePrevalenceApi: FlavorConfig;
}

export const IncidentsConfig: IIncidentsConfig = {
	autoIr: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	associatedThreat: {
		mdeFlavors: ['P2', 'Smb'],
	},
	mailboxes: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	hunting: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	urlExplorerLink: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	evidenceOrgPrevalence: {
		mdeFlavors: ['P2'],
	},
	evidenceEntityType: {
		mdeFlavors: ['Smb', 'P2'],
	},
	dataSensitivity: {
		mdeFlavors: ['P2'],
	},
	extendedAirsEntityTypes: {
		mdeFlavors: ['P2'],
	},
	alertFieldsExtended: {
		mdeFlavors: ['Smb', 'P2'],
	},
	fileOpenPage: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	detectionSources: {
		mdeFlavors: ['Smb', 'P2'],
	},
	incidentGraph: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	associatedIncident: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	filePrevalenceApi: {
		mdeFlavors: ['Smb', 'P2'],
		enableForNonMdeWorkloads: true,
	},
};
