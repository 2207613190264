import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ChartSettings } from '@wcd/charts';
import { ChangeDetectionStrategy } from '@angular/core';
import { DimPeriodData } from '@wcd/domain';
import { ChartSeriesConfiguration, ChartSingleSeriesConfiguration } from './reporting.types';

@Component({
	selector: 'dim-overtime-chart',
	template: `
		<ng-template *ngIf="colorMappingOnTop" [ngTemplateOutlet]="mapping"> </ng-template>
		<wcd-time-series-chart [data]="(data$ | async)?.data" [settings]="chartSettings$ | async">
		</wcd-time-series-chart>
		<ng-template *ngIf="!colorMappingOnTop" [ngTemplateOutlet]="mapping"> </ng-template>

		<ng-template #mapping>
			<div class="wcd-font-size-xs">
				<span *ngFor="let legendItem of legendItems" class="wcd-padding-small-left wcd-inline-block">
					<fab-icon iconName="SquareShapeSolid" [contentClass]="legendItem.colorClass"></fab-icon>
					{{ legendItem.label }}
				</span>
			</div>
		</ng-template>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DimOvertimeChart {
	@Input()
	set chartSeriesConfig(newChartSeriesConfig: ChartSeriesConfiguration<string>) {
		this._chartSeriesConfig = newChartSeriesConfig;
		this.setLegendItems();
	}
	get chartSeriesConfig(): ChartSeriesConfiguration<string> {
		return this._chartSeriesConfig;
	}

	@Input() data$: Observable<DimPeriodData<string, string>>;
	@Input() chartSettings$: Observable<ChartSettings>;
	@Input() colorMappingOnTop: boolean = true;
	legendItems: Array<ReportLegend>;
	private _chartSeriesConfig: ChartSeriesConfiguration<string>;

	private setLegendItems() {
		if (!this.chartSeriesConfig) {
			return;
		}

		this.legendItems = Object.entries<ChartSingleSeriesConfiguration>(this.chartSeriesConfig)
			.sort(([, value1], [, value2]) =>
				!value1.priority || !value2.priority ? 0 : value1.priority > value2.priority ? 1 : -1
			)
			.map(([, value]) => {
				const font = value.knownColor
					? value.knownColor.classes.font
					: value.knownColorsMap.trend.classes.font;
				return { colorClass: font, label: value.label };
			});
	}
}

export interface ReportLegend {
	colorClass: string;
	label: string;
}
