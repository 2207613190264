
		<wcd-collapsible-section
			[sectionId]="collapsibleID.AlertsManage"
			[label]="'alerts.panel.manageAlerts' | i18n"
		>
			<div
				*ngIf="hasReadOnlyAlert; else alertsManage"
				[wcdTooltip]="'alerts.panel.cantManageAllAlerts' | i18n"
				class="disabled"
			>
				<ng-container *ngTemplateOutlet="alertsManage"></ng-container>
			</div>

			<ng-template #alertsManage>
				<alerts-manage
					[alerts]="entities"
					[readonly]="hasReadOnlyAlert || (isUserExposed$ | async) !== true"
					(statusChanged)="onStatusChanged()"
					(classificationChanged)="onClassificationChanged()"
					(determinationChanged)="onDeterminationChanged()"
					(assigneeChanged)="onAssigneeChanged()"
					(restored)="refreshEntities()"
				></alerts-manage>
			</ng-template>
		</wcd-collapsible-section>

		<wcd-collapsible-section
			[label]="'entityCommon.panel.comments.header' | i18n"
			[sectionId]="collapsibleID.AlertComments"
			*ngIf="(isUserExposed$ | async)"
		>
			<alerts-comments [comments]="comments" [alerts]="entities"></alerts-comments>
		</wcd-collapsible-section>
	