import { Component, Input } from '@angular/core';
import { AirsEntity, AirsEntityType, MappableAirsEntityTypes } from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'entity-details',
	template: `
		<dl class="key-values" role="none">
			<ng-container *ngIf="entity.verdict || entity.status">
				<dt role="none">{{ i18nService.strings.entities_details_verdict }}</dt>
				<dd role="none" class="wcd-margin-bottom">
					<ng-container *ngIf="useNewStatus; else oldStatus">
						<wcd-shared-icon
							role="none"
							*ngIf="entity.verdict"
							[ngClass]="'color-text-' + entity.verdict.className"
							iconName="StatusCircleInner"
							[ariaLabel]="entity.verdict.name"
						></wcd-shared-icon>
						<span *ngIf="entity.verdict">{{ entity.verdict.name }}</span>
					</ng-container>
					<ng-template #oldStatus>
						<wcd-shared-icon
							role="none"
							*ngIf="entity.status"
							[ngClass]="'color-text-' + entity.status.className"
							iconName="StatusCircleInner"
							[ariaLabel]="entity.status.name"
						></wcd-shared-icon>
						<span *ngIf="entity.status">{{ entity.status.name }}</span>
					</ng-template>
					<div class="description-text wcd-margin-small-top">
						<ul
							[ngClass]="{
								unstyled: entity.statusDetails.length === 1,
								'inline-list': entity.statusDetails.length > 1
							}"
							*ngIf="entity.statusDetails"
						>
							<li *ngFor="let statusDetail of entity.statusDetails">{{ statusDetail }}</li>
						</ul>
						<span *ngIf="!entity.statusDetails && entity.status">{{
							entity.status.helpKey | i18n
						}}</span>
						<div *ngIf="entity.systemExclusionRule" class="wcd-margin-top">
							{{
								'airsEntities_details_exclusions'
									| i18n
										: {
												entityType: entity.type.singularName.toLowerCase()
										  }
							}}
							<span
								class="tag"
								[ngClass]="'color-box-' + entity.systemExclusionRule.listType.className"
							>
								{{
									entity.systemExclusionRule.listType.isWhitelist
										? i18nService.strings.airsEntities_details_exclusions_whitelist
										: i18nService.strings.airsEntities_details_exclusions_blacklist
								}}
							</span>
							<wcd-help [text]="entity.systemExclusionRule.listType.helpKey | i18n"></wcd-help>
						</div>
					</div>
				</dd>
				<ng-container *ngIf="useNewStatus">
					<dt role="none">{{ i18nService.strings.entities_details_remediationStatus }}</dt>
					<dd role="none">
						<ng-container *ngIf="entity.remediationStatus">
							<wcd-shared-icon
								role="none"
								[ngClass]="'color-text-' + entity.remediationStatus.className"
								iconName="StatusCircleInner"
								[ariaLabel]="entity.remediationStatus.name"
							></wcd-shared-icon>
							<span>{{ entity.remediationStatus.name }}</span>
						</ng-container>
					</dd>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="entity.machine">
				<dt role="none">{{ i18nService.strings.entities_details_machine }}</dt>
				<dd role="none">
					<machine-name
						[machine]="entity.machine"
						[showIcon]="true"
						[showLink]="true"
						iconCssClass="small-icon"
					>
					</machine-name>
				</dd>
			</ng-container>
		</dl>
		<ng-container [ngSwitch]="entity.type.id">
			<airs-file-details *ngSwitchCase="AirsEntityType.File" [entity]="entity"></airs-file-details>
			<airs-file-details
				*ngSwitchCase="AirsEntityType.AppendedFile"
				[entity]="entity"
			></airs-file-details>
			<airs-process-details
				*ngSwitchCase="AirsEntityType.Process"
				[entity]="entity"
			></airs-process-details>
			<airs-driver-details
				*ngSwitchCase="AirsEntityType.Driver"
				[entity]="entity"
			></airs-driver-details>
			<airs-service-details
				*ngSwitchCase="AirsEntityType.Service"
				[entity]="entity"
			></airs-service-details>
			<airs-url-details *ngSwitchCase="AirsEntityType.URL" [entity]="entity"></airs-url-details>
			<airs-ip-details *ngSwitchCase="AirsEntityType.IP" [entity]="entity"></airs-ip-details>
			<airs-url-details *ngSwitchCase="AirsEntityType.EmailUrl" [entity]="entity"></airs-url-details>
			<airs-user-details *ngSwitchCase="AirsEntityType.User" [entity]="entity"></airs-user-details>
			<airs-url-details *ngSwitchCase="AirsEntityType.WebPage" [entity]="entity"></airs-url-details>
			<airs-persistence-method-details
				*ngSwitchCase="AirsEntityType.PersistenceMethod"
				[entity]="entity"
			></airs-persistence-method-details>
			<airs-user-activity-details
				*ngSwitchCase="AirsEntityType.UserActivity"
				[entity]="entity"
			></airs-user-activity-details>
			<airs-email-cluster-details
				*ngSwitchCase="AirsEntityType.MailCluster"
				[entity]="entity"
			></airs-email-cluster-details>
			<airs-email-message-details
				*ngSwitchCase="AirsEntityType.MailMessage"
				[entity]="entity"
			></airs-email-message-details>
			<airs-mailbox-details
				*ngSwitchCase="AirsEntityType.Mailbox"
				[entity]="entity"
			></airs-mailbox-details>
			<airs-mailbox-configuration-details
				*ngSwitchCase="AirsEntityType.MailboxConfiguration"
				[entity]="entity"
			></airs-mailbox-configuration-details>
			<airs-email-message-details
				*ngSwitchCase="AirsEntityType.SubmissionMail"
				[entity]="entity"
			></airs-email-message-details>
			<airs-account-details
				*ngSwitchCase="AirsEntityType.Account"
				[entity]="entity"
			></airs-account-details>
			<airs-security-group-details
				*ngSwitchCase="AirsEntityType.SecurityGroup"
				[entity]="entity"
			></airs-security-group-details>
			<airs-registry-key-details
				*ngSwitchCase="AirsEntityType.RegistryKey"
				[entity]="entity"
			></airs-registry-key-details>
			<airs-registry-value-details
				*ngSwitchCase="AirsEntityType.RegistryValue"
				[entity]="entity"
			></airs-registry-value-details>
			<airs-cloud-application-details
				*ngSwitchCase="AirsEntityType.CloudApplication"
				[entity]="entity"
			></airs-cloud-application-details>
			<airs-cloud-logon-session-details
				*ngSwitchCase="AirsEntityType.CloudLogonSession"
				[entity]="entity"
			></airs-cloud-logon-session-details>
			<airs-oauth-application-details
				*ngSwitchCase="AirsEntityType.OauthApplication"
				[entity]="entity"
			></airs-oauth-application-details>
			<airs-active-directory-domain-details
				*ngSwitchCase="AirsEntityType.ActiveDirectoryDomain"
				[entity]="entity"
			></airs-active-directory-domain-details>
		</ng-container>
		<a *ngIf="showDeepLink && entity.deepLink" [href]="entity.deepLink" target="_blank">
			{{ 'entities_details_deepLinkLabel' | i18n: { name: entity.type.singularName.toLowerCase() } }}
			<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
		</a>
	`,
})
export class EntityDetailsComponent {
	AirsEntityType = AirsEntityType;

	@Input() entity: AirsEntity;
	@Input() set allowStatusSplit(val: boolean) {
		const featuresEnabled = this.featuresService.isEnabled(Feature.RemediationStatusSplit);
		this.useNewStatus = val && featuresEnabled;
	}

	useNewStatus: boolean;
	showDeepLink: boolean;

	constructor(private featuresService: FeaturesService, public i18nService: I18nService) {
		this.showDeepLink = !this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence);
	}

	/**
	 * This function will create a compile time error in case an entity type is omitted from the switch.
	 * It is not called in runtime, it's just here to remind the developer to add new types to the component
	 * mapping.
	 **/
	private validateTypeComponent(type: MappableAirsEntityTypes): true {
		switch (type) {
			case 'File':
			case 'Process':
			case 'Driver':
			case 'Service':
			case 'URL':
			case 'IP':
			case 'AppendedFile':
			case 'EmailUrl':
			case 'User':
			case 'WebPage':
			case 'PersistenceMethod':
			case 'UserActivity':
			case 'MailCluster':
			case 'MailMessage':
			case 'Mailbox':
			case 'SubmissionMail':
			case 'MailboxConfiguration':
			case 'Account':
			case 'SecurityGroup':
			case 'RegistryKey':
			case 'RegistryValue':
			case 'CloudApplication':
			case 'CloudLogonSession':
			case 'OauthApplication':
			case 'ActiveDirectoryDomain':
				return true;
		}

		function assertType(x: never): never {
			throw new Error(`type '${x}' is not mapped to a component`);
		}
		// if you got here, you should add your entity type to the component selector in the template above
		// and to the switch
		return assertType(type);
	}
}
