import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Outbreak alerts count',
	pluralName: 'Outbreak alerts count',
	readonly: true,
})
export class OutbreakAlertsCount extends ModelBase {
	@EntityField({ data: 'ActiveAlertsCount', defaultValue: 0 })
	readonly activeAlertsCount: number;

	@EntityField({ data: 'ResolvedAlertsCount', defaultValue: 0 })
	readonly resolvedAlertsCount: number;
}
