import * as CreateRequest from '../machine-requests.models';

export enum RawIsolationAction {
	Isolate = 0,
	Unisolate = 1,
}

export enum RawIsolationType {
	Full = 0,
	Selective = 1,
	UnManagedDevice = 2,
}

export enum IsolationTypeValues {
	Full = 'Full',
	Selective = 'Selective',
	UnManagedDevice = 'UnManagedDevice',
}

export type IsolationAction = keyof typeof RawIsolationAction;
export type IsolationType = keyof typeof RawIsolationType;

export interface IsolationRawApiRequestBody<TIsolationAction extends IsolationAction>
	extends CreateRequest.RawApiRequestBody<'IsolationRequest'> {
	readonly Action: TIsolationAction;
	readonly IsolationType: IsolationType;
}

export type IsolationRawApiResponse = CreateRequest.RawApiResponse;

export type IsolationResponse = CreateRequest.Response;

export interface IsolationParameters extends CreateRequest.Parameters {
	readonly isolationType: IsolationType;
}

export interface IsolationPublicApiParameters extends CreateRequest.MachineActionParameters {
	title: string;
	isolationType: IsolationTypeValues;
}

export interface IsolateMachinePublicApiRequestBody extends CreateRequest.MachineActionRequestBody {
	Title: string; // this is the remediation name
	IsolationType: IsolationTypeValues;
}
