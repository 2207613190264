<!-- tslint:disable:template-click-events-have-key-events -->
<dataview
	*ngIf="dataViewConfig"
	[repository]="remediationActionsRepository"
	[entityType]="remediationActionEntityType"
	[allowFilters]="false"
	[dataViewConfig]="dataViewConfig"
	[fields]="fields"
	[id]="remediationActionTypeActions.remediationActionType.type"
	[disableSelection]="
		!(isUserAllowed$ | async) || !remediationActionTypeActions.remediationActionType.isUserPending
	"
	commandBarClassName="no-left-padding"
	[fixedOptions]="mergedFixedOptions"
	[entityPanelOptions]="{
		showInvestigation: showInvestigationData,
		loadInvestigation: showInvestigationData,
		hideOpenLink: !showInvestigationData,
		allowStatusSplit: allowStatusSplit,
		huntingContext: huntingContext,
		showComments: showComments
	}"
	className="no-left-padding"
	(onAction)="onAction.emit()"
	(onData)="onDataReceived($event)"
	(dataLoadError)="onDataLoadError($event)"
	[showDialogOnError]="false"
	[selectAllEnabled]="true"
	(onTableRenderComplete)="onTableRenderComplete()"
>
	<div dataview-header class="wcd-flex-vertical wcd-full-width">
		<div *ngIf="hasNewerData" class="dataview-selection-all wcd-full-width">
			{{ i18nService.strings.refreshData_newData }}
			<a data-track-id="Refresh" data-track-type="Navigation" (click)="onRefreshClick()">
				{{ i18nService.strings.refreshData_clickToRefresh }}
			</a>
		</div>
		<p class="wcd-padding-top page-subtitle">
			{{
				'remediationActions.actions.' +
					remediationActionTypeActions.remediationActionType.type +
					'.description' | i18n
			}}
		</p>
	</div>
</dataview>
