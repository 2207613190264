import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { AuthBackendService, WDATP_LOGOUT_URL, WDATP_REDIRECT_TO_LOGIN_URL } from './auth.backend.service';
import { EMPTY, Observable, of, ReplaySubject, Subject, Subscription, timer } from 'rxjs';
import { BrowserDetectionService, IPreload } from '@wcd/shared';
import { AppConfigData, AppConfigModel, AppConfigService } from '@wcd/app-config';
import { HttpParams } from '@angular/common/http';
import { ApiBaseModel } from '@wcd/data';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { APC_HEADER_KEY, tenantContextCache, AuthUser, AuthUserInfo } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';

const SESSION_STORAGE_KEY = 'wcdPortalSessionId';
const TID_STORAGE_KEY = 'tenantId';

@Injectable()
export class AuthService implements IPreload, OnDestroy {
	private _logoutSubject: Subject<void> = new Subject<void>();
	private _sessionId: string;
	private _apcHeader: string;
	private checkingAuthStatus: boolean;
	private authSub: Subscription;
	currentUser: AuthUser;
	token: string;
	tokenExpirationDate: Date;
	redirectUrl$: ReplaySubject<string> = new ReplaySubject<string>(1);
	logout$: Observable<void> = this._logoutSubject.asObservable();
	token$: Subject<string> = new Subject<string>(); // used to push new token to seville.authentication. Can be removed once angularJs is removed.
	loginError: boolean = null;

	get tenantId(): string {
		return this.appConfigService.tenantId || this.onBehalfTenantId;
	}

	get isLoggedIn(): boolean {
		return !!this.token;
	}

	get sessionId(): string {
		return this._sessionId;
	}

	get onBehalfTenantId(): string {
		try {
			return sessionStorage.getItem(TID_STORAGE_KEY);
		} catch (e) {
			console.warn('Failed to access session storage');
		}
	}

	set onBehalfTenantId(value: string) {
		try {
			sessionStorage.setItem(TID_STORAGE_KEY, value);
		} catch (e) {
			console.warn('Failed to access session storage');
		}
	}

	get apcHeader(): string {
		if (!this._apcHeader) {
			try {
				this._apcHeader = sessionStorage.getItem(APC_HEADER_KEY);
			} catch (e) {
				console.warn('Failed to access session storage');
			}
		}
		return this._apcHeader;
	}

	get isGlobalAdmin(): boolean {
		return this.currentUser && this.currentUser.isMdeAdmin;
	}

	set apcHeader(value: string) {
		if (this.apcHeader !== value) {
			this._apcHeader = value;
			try {
				sessionStorage.setItem(APC_HEADER_KEY, value);
			} catch (e) {
				console.warn('Failed to access session storage');
			}
		}
	}

	/*
	 * checks the browser's user agent in order to detect if the user is a pdf reports generator
	 * returns true if this is a pdf reports generator and therefore allowed to use the app without login and token
	 */
	get isExecutiveReport(): boolean {
		return this.browserDetectionService.browserData.hexaditeReports;
	}

	constructor(
		private authBackendService: AuthBackendService,
		private browserDetectionService: BrowserDetectionService,
		private appConfigService: AppConfigService,
		private appContext: AppContextService,
		private featuresService: FeaturesService,
		private location: Location,
		private readonly telemetryService: TelemetryService
	) {
		const initialUrl: string = this.location.path(true);
		this.redirectUrl$.next(initialUrl);
		this.setOnBehalfScenario(initialUrl);
	}

	private setOnBehalfScenario(url) {
		// support for MSSP/On-Behalf scenarios
		const tidMatch: RegExpMatchArray = url.match(/[?&]tid=([^?&]+)/i);
		if (tidMatch) this.onBehalfTenantId = tidMatch[1];
	}

	private generateSessionId() {
		return new Date().valueOf().toString();
	}

	addTenantIdToUrl(url: string): string {
		const prefix: string = /\?/.test(url) ? '&' : '?';
		return url + (this.tenantId ? prefix + `tenant_id=${this.tenantId}` : '');
	}

	setAuthToken(token: string) {
		this.token = token;
		this.token$.next(token);
	}

	init(): Observable<AuthUser> {
		if (!this.token) {
			return this.login().pipe(map(() => this.currentUser));
		} else {
			let sessionId: string = sessionStorage.getItem(SESSION_STORAGE_KEY);
			if (!sessionId) {
				sessionId = this.generateSessionId();
				sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);
			}

			this._sessionId = sessionId;
		}

		return of(this.currentUser);
	}

	handleAuthError(checkAuthStatus: boolean = true) {
		if (!checkAuthStatus) this.redirectToLogin();
		else {
			// avoid making multiple requests
			if (!this.checkingAuthStatus) {
				this.checkingAuthStatus = true;
				this.authBackendService.checkAuthStatus().subscribe(
					(isAuthenticated: boolean) => {
						if (!isAuthenticated) {
							this.redirectToLogin();
						}
						this.checkingAuthStatus = false;
					},
					(err) => {
						this.checkingAuthStatus = false;
					},
					() => {
						this.checkingAuthStatus = false;
					}
				);
			}
		}
	}

	private redirectToLogin() {
		this.setAuthToken(null);
		this.redirectUrl$.pipe(take(1), delay(1)).subscribe((url) => {
			url = url || '/';
			const options: { params: HttpParams } = ApiBaseModel.getUrlParams(
				Object.assign({}, this.onBehalfTenantId && { tid: this.onBehalfTenantId }, {
					state: url,
				})
			);
			const redirectToLoginUrl = `${WDATP_REDIRECT_TO_LOGIN_URL}?${options.params.toString()}`;
			window.location.href = redirectToLoginUrl;
		});
	}

	login(): Observable<AppConfigData> {
		return this.authBackendService.login().pipe(
			tap(
				(appConfigBackendData: AppConfigData) => {
					this.appConfigService.setData(appConfigBackendData);
					this.handleLoginResponse(appConfigBackendData, true);
				},
				(err: Error | Response) => {
					if ((<Response>err).status === 401) this.loginError = true;
				}
			)
		);
	}

	private handleLoginResponse(appConfigBackendData: AppConfigData, isLogin?: boolean) {
		this.setAuthToken(appConfigBackendData.AuthInfo.AccessToken);
		const tokenExpirationStr: RegExpMatchArray =
			appConfigBackendData.AuthInfo &&
			appConfigBackendData.AuthInfo.TokenExpirationDate &&
			appConfigBackendData.AuthInfo.TokenExpirationDate.match(/\d+/);
		this.tokenExpirationDate = tokenExpirationStr && new Date(parseInt(tokenExpirationStr[0], 10));
		this.currentUser = new AuthUser(this.getAuthUserInfoFromAppConfigData(appConfigBackendData));
		if (isLogin) {
			this.telemetryService.trackEvent({
				type: TrackingEventType.Action,
				id: 'userLoggedIn',
				payload: { allowedActions: this.currentUser.mdeAllowedActions.toString() },
			});
		}
		sessionStorage.setItem(
			SESSION_STORAGE_KEY,
			this._sessionId || (this._sessionId = this.generateSessionId())
		);
	}

	setRefreshToken() {
		if (!this.featuresService.isEnabled(Feature.RefreshToken) || this.appContext.isSCC) {
			return;
		}
		const callAt: Date = new Date(this.tokenExpirationDate);
		// the call can only be made 5 minutes before the token expires
		callAt.setMinutes(callAt.getMinutes() - 4);
		const callIn: number = callAt.getTime() - new Date().getTime();
		timer(callIn)
			.pipe(
				switchMap(() =>
					// verify token is not expired
					this.tokenExpirationDate.getTime() - new Date().getTime() > 0
						? this.authBackendService.refreshToken(this.tenantId)
						: EMPTY
				),
				tap((auth) => {
					const newConfig = new AppConfigModel();
					newConfig.setData(auth);
					// TODO: check user model? Currently user changes are reflected immediately, so probably no need
					if (this.appConfigService.equals(newConfig)) {
						this.handleLoginResponse(auth);
						this.setRefreshToken();
					} else {
						location.reload();
					}
				})
			)
			.toPromise();
	}

	logout() {
		this.setAuthToken(null);
		this._logoutSubject.next();

		const urlSuffix: string = ApiBaseModel.getUrlParams(
			this.onBehalfTenantId && { tid: this.onBehalfTenantId }
		).params.toString();

		sessionStorage.removeItem(SESSION_STORAGE_KEY);
		sessionStorage.removeItem(TID_STORAGE_KEY);
		!sccHostService.isSCC && sessionStorage.removeItem(APC_HEADER_KEY);

		let url = WDATP_LOGOUT_URL;
		url = urlSuffix ? `${url}?${urlSuffix}` : url;

		window.location.href = url;
	}

	isCurrentUser(userName: string): boolean {
		return userName === this.currentUser.name;
	}

	ngOnDestroy() {
		this.redirectUrl$.complete();
		this._logoutSubject.complete();
		this.token$.complete();
		this.authSub && this.authSub.unsubscribe();
		!sccHostService.isSCC && sessionStorage.removeItem(APC_HEADER_KEY);
	}

	//SCC login flow

	async initSCC(): Promise<AppConfigData> {
		try {
			const appConfig = await tenantContextCache.getTenantContext();
			if (appConfig.IsStubData) this.setOptOutSettings();
			else return await this.onTenantContextLoaded(appConfig);
		} catch (err) {
			await this.onTenantContextLoadError(err);
		}
	}

	private async onTenantContextLoaded(appConfigBackendData: AppConfigData): Promise<AppConfigData> {
		if (!appConfigBackendData.IsMtpEligible && !appConfigBackendData.IsTvmEligible) {
			return Promise.reject();
		}

		sessionStorage.setItem(
			SESSION_STORAGE_KEY,
			this._sessionId || (this._sessionId = this.generateSessionId())
		);
		this.featuresService.applyFeatures(appConfigBackendData.Features);
		appConfigBackendData.ServiceUrls.assetsBaseUrl = sccHostService.getPackageBasePathByPkg();
		// This is only relevant for working with localhost, this replaces the localhost with the host injected from SCC devhost params(for tests)
		this.replaceServiceHostsWithDevhost(appConfigBackendData);
		this.appConfigService.setData(appConfigBackendData);
		this.currentUser = new AuthUser(this.getAuthUserInfoFromAppConfigData(appConfigBackendData));
		return appConfigBackendData;
	}

	// TODO find a  way of doing this without changing url from localhost
	private replaceServiceHostsWithDevhost(appConfigBackendData) {
		// This is only relevant for working with localhost, this replaces the localhost with the host injected from SCC devhost params(for tests)
		Object.keys(appConfigBackendData.ServiceUrls)
			.filter((prop) => appConfigBackendData.ServiceUrls[prop])
			.forEach(
				(prop) =>
					(appConfigBackendData.ServiceUrls[prop] = appConfigBackendData.ServiceUrls[prop].replace(
						'http://localhost:4200',
						appConfigBackendData.ServiceUrls.assetsBaseUrl
					))
			);
	}

	private async onTenantContextLoadError(err: Error | Response) {
		// Clear cache so next call might work
		tenantContextCache.invalidateCache();
		sccHostService.log.trackException(err as Error);
		throw err;
	}

	private getAuthUserInfoFromAppConfigData(appConfigData: AppConfigData): AuthUserInfo {
		return {
			DirRoles: appConfigData.DirRoles,
			IsItpActive: appConfigData.IsItpActive,
			IsMdatpActive: appConfigData.IsMdatpActive,
			IsOatpActive: appConfigData.IsOatpActive,
			IsMapgActive: appConfigData.IsMapgActive,
			IsAadIpActive: appConfigData.IsAadIpActive,
			IsDlpActive: appConfigData.IsDlpActive,
			IsMdiActive: appConfigData.IsMdiActive,
			ItpMtpPermissions: appConfigData.ItpMtpPermissions,
			MdatpMtpPermissions: appConfigData.MdatpMtpPermissions,
			OatpMtpPermissions: appConfigData.OatpMtpPermissions,
			MapGMtpPermissions: appConfigData.MapgMtpPermissions,
			AadIpMtpPermissions: appConfigData.AadIpMtpPermissions,
			DlpMtpPermissions: appConfigData.DlpMtpPermissions,
			MdiMtpPermissions: appConfigData.MdiMtpPermissions,
			UserName: appConfigData.AuthInfo.UserName,
			AadUserId: appConfigData.AuthInfo.AadUserId,
			MdeAllowedActions: appConfigData.AuthInfo.AllowedActions,
		};
	}

	private setOptOutSettings() {
		this.currentUser = new AuthUser({
			UserName: sccHostService.loginUser.upn,
			TenantId: sccHostService.loginUser.tenantId,
			AadUserId: sccHostService.loginUser.userId,
			IsMdatpActive: false,
			IsItpActive: false,
			IsOatpActive: true,
		});
		this.appConfigService.setData((({
			AuthInfo: {
				TenantId: sccHostService.loginUser.tenantId,
				UserName: sccHostService.loginUser.upn,
			},
			AutomatedIrLiveResponse: false,
			IsMtpEligible: false,
		} as Partial<AppConfigData>) as unknown) as AppConfigData);
	}
}
