var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { camelCase } from 'lodash-es';
import { protectionLevelValues } from './protection-level.values';
var ProtectionLevel = /** @class */ (function (_super) {
    __extends(ProtectionLevel, _super);
    function ProtectionLevel(data) {
        var _this = _super.call(this, data) || this;
        if (data) {
            _this.type = camelCase(data.name);
        }
        return _this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ProtectionLevel.prototype, "nameKey", void 0);
    __decorate([
        EntityField({ defaultValue: true }),
        __metadata("design:type", Boolean)
    ], ProtectionLevel.prototype, "hostEdit", void 0);
    __decorate([
        EntityField({
            data: 'is_default',
        }),
        __metadata("design:type", Boolean)
    ], ProtectionLevel.prototype, "isDefault", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ProtectionLevel.prototype, "descriptionKey", void 0);
    ProtectionLevel = __decorate([
        Entity({
            singularName: 'Protection Level',
            pluralName: 'Protection Levels',
            readonly: true,
            values: protectionLevelValues,
        }),
        __metadata("design:paramtypes", [Object])
    ], ProtectionLevel);
    return ProtectionLevel;
}(EntityModelBase));
export { ProtectionLevel };
