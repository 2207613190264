var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { isNull, merge } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
var FiltersService = /** @class */ (function () {
    function FiltersService() {
    }
    /**
     * Loads data from server to populate the FilterResult's fields' _filterRawValues.
     * @param noCache {boolean} Whether to allow caching
     */
    FiltersService.loadRawFilterValues = function (config) {
        var options = FiltersService.getOptions(config);
        return config.filterValuesLoader(options);
    };
    /**
     * Gets a JSON object to use as options for an API query, representing this filters
     */
    FiltersService.getOptions = function (_a) {
        var selectedValues = _a.selectedValues, fixedFilterValues = _a.fixedFilterValues, optionalParams = _a.optionalParams;
        var options = {};
        // TODO: Use this.selectedValues, not fieldFilterValues.
        // this._fieldFilterValues.forEach((fieldFilterValues, fieldId) => {
        // 	if (fieldFilterValues.hasSelectedValues) {
        // 		options[fieldId] = Array.from(fieldFilterValues.selectedValues.keys()).map(filterValue =>
        // 			this.getFilterValueParamValue(
        // 				filterValue,
        // 				fieldFilterValues.selectedValues.get(filterValue)
        // 			)
        // 		);
        // 	}
        // });
        return merge(options, optionalParams, fixedFilterValues);
    };
    FiltersService.filtersQueryParamToSerializedFilters = function (filtersQueryParam) {
        if (!filtersQueryParam)
            return {};
        return filtersQueryParam.split(',').reduce(function (serialized, param) {
            var _a;
            var _b = param.split('='), key = _b[0], value = _b[1];
            if (isNull(value) || value === "undefined" || value.length > 0 && value[0] === "undefined") {
                if (sccHostService.isSCC) {
                    sccHostService.log.trackEvent("Undefined query filter value found", { param: param });
                }
                return serialized;
            }
            var decodedValue = decodeURIComponent(value);
            var serializedFilterValue = decodedValue
                .split('|')
                .map(function (_value) { return decodeURIComponent(_value); });
            return __assign({}, serialized, (_a = {}, _a[key] = serializedFilterValue, _a));
        }, {});
    };
    FiltersService.getFiltersQueryParam = function (serializedFilters) {
        if (!serializedFilters)
            return null;
        return (Object.keys(serializedFilters)
            .filter(function (param) {
            var value = serializedFilters[param];
            if (isNull(value) || value === 'undefined' || value.length > 0 && value[0] === "undefined") {
                if (sccHostService.isSCC) {
                    sccHostService.log.trackEvent("Undefined serialized filter value found", { param: param });
                }
                return false;
            }
            return true;
        })
            .map(function (param) {
            var paramValue = serializedFilters[param];
            var queryParamValue = paramValue instanceof Array
                ? paramValue.map(function (value) { return encodeURIComponent(value); }).join('|')
                : paramValue;
            return param + "=" + encodeURIComponent(queryParamValue);
        })
            .join(',') || null);
    };
    return FiltersService;
}());
export { FiltersService };
