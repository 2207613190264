import { EntityField, ValueObject } from '@microsoft/paris';
import { EntityTags } from '../tag/entity-tags.value-object';

@ValueObject({
	singularName: 'Incident tags',
	pluralName: '',
	readonly: true,
})
export class IncidentTags extends EntityTags {

	@EntityField({ data: ["IncidentUserTags","incidentUserTags"] })
	incidentUserTags?: string[];

}
