import { InvestigationStatusEnum } from './investigation-status.enum';
import { keyBy } from 'lodash-es';

export const investigationStatusValues = [
	{
		id: InvestigationStatusEnum.running,
		type: 'running',
		name: 'Running',
		className: 'primary',
		isRunning: true,
		image: '/assets/images/icons/investigation_running.svg',
		priority: 1,
		actions: ['stop'],
		investigationQueued: true,
		sevilleStatusId: 1 << 5,
	},
	{
		id: InvestigationStatusEnum.benign,
		type: 'benign',
		name: 'No threats found',
		className: 'gray-700',
		iconName: 'investigations.status.benign',
		priority: 7,
		hideErrors: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 2,
	},
	{
		id: InvestigationStatusEnum.pending,
		type: 'pending',
		name: 'Pending',
		className: 'warning-dark',
		iconName: 'investigations.status.paused',
		priority: 2,
		isRunning: true,
		isPending: true,
		investigationQueued: true,
	},
	{
		id: InvestigationStatusEnum.terminatedBySystem,
		type: 'terminatedBySystem',
		name: 'Terminated by system',
		className: 'gray-700',
		iconName: 'investigations.status.systemTerminated',
		priority: 10,
		isTerminated: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 17,
	},
	{
		id: InvestigationStatusEnum.terminatedByUser,
		type: 'terminatedByUser',
		name: 'Terminated by user',
		className: 'gray-700',
		iconName: 'investigations.status.userTerminated',
		priority: 10,
		isTerminated: true,
		isTerminatedByUser: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 16,
	},
	{
		id: InvestigationStatusEnum.failed,
		type: 'failed',
		name: 'Failed',
		className: 'error',
		iconName: 'investigations.status.failed',
		priority: 6,
		isError: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 3,
	},
	{
		id: InvestigationStatusEnum.fullyRemediated,
		type: 'fullyRemediated',
		name: 'Remediated',
		className: 'success',
		iconName: 'investigations.status.success',
		priority: 9,
		hideErrors: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 1,
	},
	{
		id: InvestigationStatusEnum.partiallyRemediated,
		type: 'partiallyRemediated',
		name: 'Partially remediated',
		className: 'success',
		iconName: 'investigations.status.partial',
		priority: 8,
		investigationQueued: true,
		sevilleStatusId: 1 << 4,
	},
	{
		id: InvestigationStatusEnum.partiallyInvestigated,
		type: 'partiallyInvestigated',
		name: 'Partially investigated',
		className: 'benign',
		iconName: 'investigations.status.failed',
		priority: 11,
		investigationQueued: true,
		sevilleStatusId: 1 << 15,
	},
	{
		id: InvestigationStatusEnum.pendingResource,
		type: 'pendingResource',
		name: 'Waiting for device',
		className: 'warning-dark',
		iconName: 'investigations.status.paused',
		priority: 2,
		isRunning: true,
		isPending: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 7,
		isTempOffloadingStatus: true,
	},
	{
		id: InvestigationStatusEnum.pendingUser,
		type: 'pendingUser',
		name: 'Pending approval',
		className: 'warning-dark',
		iconName: 'investigations.status.paused',
		priority: 2,
		isRunning: true,
		isPending: true,
		isPendingUser: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 6,
		isTempOffloadingStatus: true,
	},
	{
		id: InvestigationStatusEnum.queued,
		type: 'queued',
		name: 'Queued',
		className: 'warning-dark',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: true,
		sevilleStatusId: 1 << 9,
	},
	{
		id: InvestigationStatusEnum.disabled,
		type: 'disabled',
		name: 'Not applicable',
		className: 'neutral',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: false,
		isDisabled: true,
		sevilleStatusId: 1 << 8,
	},
	{
		id: InvestigationStatusEnum.innerFailure,
		type: 'innerFailure',
		name: 'Failed',
		className: 'error',
		iconName: 'error',
		priority: 6,
		isError: true,
		investigationQueued: true,
		sevilleStatusId: 1 << 10,
	},
	{
		id: InvestigationStatusEnum.unsupportedAlertType,
		type: 'unsupportedAlertType',
		name: 'Unsupported alert type',
		className: 'neutral',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: false,
		sevilleStatusId: 1 << 13,
	},
	{
		id: InvestigationStatusEnum.unsupportedOs,
		type: 'unsupportedOs',
		name: 'Unsupported OS',
		className: 'neutral',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: false,
		sevilleStatusId: 1 << 12,
	},
	{
		id: InvestigationStatusEnum.preexistingAlert,
		type: 'preexistingAlert',
		name: 'Preexisting alert',
		className: 'neutral',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: false,
		sevilleStatusId: 1 << 11,
	},
	{
		id: InvestigationStatusEnum.suppressedAlert,
		type: 'suppressedAlert',
		name: 'Suppressed alert',
		className: 'neutral',
		iconName: 'investigations.status.empty',
		priority: 12,
		investigationQueued: false,
		sevilleStatusId: 1 << 14,
	},
];

export const sevilleInvestigationStatusToDisplayStatus = keyBy(investigationStatusValues, 'sevilleStatusId');
