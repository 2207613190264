var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField } from '@microsoft/paris';
import { AuthenticationParams, AuthenticationParamsSerializationType } from './authentication-params.value-object';
export var SnmpAuthType;
(function (SnmpAuthType) {
    SnmpAuthType["CommunityString"] = "Community String";
    SnmpAuthType["NoAuthNoPriv"] = "NoAuthNoPriv";
    SnmpAuthType["AuthNoPriv"] = "AuthNoPriv";
    SnmpAuthType["AuthPriv"] = "AuthPriv";
})(SnmpAuthType || (SnmpAuthType = {}));
export var SnmpAuthProtocol;
(function (SnmpAuthProtocol) {
    SnmpAuthProtocol["md5"] = "MD5";
    SnmpAuthProtocol["sha1"] = "SHA1";
})(SnmpAuthProtocol || (SnmpAuthProtocol = {}));
export var SnmpPrivProtocol;
(function (SnmpPrivProtocol) {
    SnmpPrivProtocol["des"] = "DES";
    SnmpPrivProtocol["threeDes"] = "3DES";
    SnmpPrivProtocol["aes"] = "AES";
})(SnmpPrivProtocol || (SnmpPrivProtocol = {}));
var SnmpAuthParams = /** @class */ (function (_super) {
    __extends(SnmpAuthParams, _super);
    function SnmpAuthParams(previous) {
        return _super.call(this, AuthenticationParamsSerializationType.SnmpAuthParams, previous) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "type", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "communityString", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "username", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "authProtocol", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "authPassword", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "privProtocol", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SnmpAuthParams.prototype, "privPassword", void 0);
    SnmpAuthParams = __decorate([
        ValueObject({
            singularName: 'Snmp authentication parameters',
            pluralName: 'Snmp authentication parameters',
        }),
        __metadata("design:paramtypes", [SnmpAuthParams])
    ], SnmpAuthParams);
    return SnmpAuthParams;
}(AuthenticationParams));
export { SnmpAuthParams };
