var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { OnChanges } from '@angular/core';
import { AlertsSeveritySummary, SeverityType } from '@wcd/domain';
import { SeverityTypeColorService } from '../../shared/services/severity-type-color.service';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { I18nService } from '@wcd/i18n';
var severityTypeDisplayKeysMap = (_a = {},
    _a[SeverityType.Informational] = 'alert_severity_informational',
    _a[SeverityType.Low] = 'alert_severity_low',
    _a[SeverityType.Medium] = 'alert_severity_medium',
    _a[SeverityType.High] = 'alert_severity_high',
    _a);
var AlertsSeveritySummaryComponent = /** @class */ (function () {
    function AlertsSeveritySummaryComponent(severityTypeColorService, i18nService, displayTextPipe) {
        this.severityTypeColorService = severityTypeColorService;
        this.i18nService = i18nService;
        this.severityColorMap = {
            type: 'class',
            map: severityTypeColorService.backgroundColorsClassMap,
        };
        this.barTextFormatter = function (severity) { return displayTextPipe.transform(severity, severityTypeDisplayKeysMap); };
        this.legendTextFormatter = function (count) { return "(" + count + ")"; };
    }
    AlertsSeveritySummaryComponent.prototype.ngOnChanges = function () {
        var _a = this.alertsSeveritySummary, high = _a.high, medium = _a.medium, low = _a.low, info = _a.info;
        this.severities = [
            this.createSeverityConfig(high),
            this.createSeverityConfig(medium),
            this.createSeverityConfig(low),
            this.createSeverityConfig(info),
        ].filter(Boolean);
    };
    AlertsSeveritySummaryComponent.prototype.createSeverityConfig = function (_a) {
        var severity = _a.severity, count = _a.count;
        if (count === 0)
            return null;
        var barConfig = {
            kind: severity.type,
            count: count,
        };
        var legendConfig = {
            name: this.i18nService.get(severityTypeDisplayKeysMap[severity.type]),
            iconClassName: this.severityTypeColorService.fontColorsClassMap.get(severity.type),
            value: count,
        };
        return __assign({}, barConfig, legendConfig);
    };
    return AlertsSeveritySummaryComponent;
}());
export { AlertsSeveritySummaryComponent };
