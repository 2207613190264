import { RemediationActionTypeTypes } from './remediation-action-type-types.enum';

export const remediationActionTypeValues = [
	{
		id: 0,
		name: 'Quarantine file',
		mainEntities: [{ fieldName: 'file', entityTypeId: 1 }],
		type: RemediationActionTypeTypes.file_quarantine,
		icon: 'remediationActions.quarantineFile',
		remediationText: items => {
			return 'Quarantine ' + (items.length === 1 ? 'file' : items.length + ' files');
		},
		dismissText: items => {
			return 'Decline to quarantine ' + (items.length === 1 ? 'file' : items.length + ' files');
		},
		pendingText: count => {
			let verb: string, actionSuffix: string, prefix: string;
			if (count === 1) {
				verb = 'needs';
				actionSuffix = '';
				prefix = 'A';
			} else {
				verb = 'need';
				actionSuffix = 's';
				prefix = String(count);
			}
			return `${prefix} pending file quarantine action${actionSuffix} ${verb} approval`;
		},
		titleText: count => {
			const text = count === 1 ? 'File' : count + ' Files';
			return text + ' Pending Quarantine';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'File quarantine approved';
			else return items.length + ' files quarantine approved';
		},
		simpleName: 'Quarantine file',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 6,
		name: 'Quarantine service',
		mainEntities: [{ fieldName: 'service', entityTypeId: 4 }],
		type: RemediationActionTypeTypes.service_quarantine,
		icon: 'remediationActions.quarantineService',
		remediationText: items => {
			return 'Quarantine ' + (items.length === 1 ? 'service' : items.length + ' services');
		},
		dismissText: items => {
			return 'Decline to quarantine ' + (items.length === 1 ? 'service' : items.length + ' services');
		},
		pendingText: count => {
			const text = count === 1 ? 'A service needs' : count + ' service need';
			return text + ' to be quarantined';
		},
		titleText: count => {
			const text = count === 1 ? 'Service' : count + ' Services';
			return text + ' Pending Quarantine';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'Service quarantine approved';
			else return items.length + ' services quarantine approved';
		},
		simpleName: 'Quarantine service',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 7,
		name: 'Quarantine driver',
		mainEntities: [{ fieldName: 'driver', entityTypeId: 3 }],
		type: RemediationActionTypeTypes.driver_quarantine,
		icon: 'remediationActions.quarantineDriver',
		remediationText: items => {
			return 'Quarantine ' + (items.length === 1 ? 'driver' : items.length + ' drivers');
		},
		dismissText: items => {
			return 'Decline to quarantine ' + (items.length === 1 ? 'driver' : items.length + ' drivers');
		},
		pendingText: count => {
			const text = count === 1 ? 'A driver needs' : count + ' driver need';
			return text + ' to be quarantined';
		},
		titleText: count => {
			const text = count === 1 ? 'Driver' : count + ' Drivers';
			return text + ' Pending Quarantine';
		},
		simpleName: 'Driver quarantine',
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'Driver quarantine approved';
			else return items.length + ' drivers quarantine approved';
		},
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 4,
		name: 'Release file handle',
		mainEntities: [{ fieldName: 'file', entityTypeId: 1 }, { fieldName: 'process', entityTypeId: 2 }],
		type: RemediationActionTypeTypes.release_file,
		icon: 'file',
		remediationText: items => {
			return 'Release ' + items.length + ' File' + (items.length !== 1 ? 's' : '');
		},
		dismissText: items => {
			return 'Decline to release ' + items.length + ' File' + (items.length !== 1 ? 's' : '');
		},
		pendingText: count => {
			const text = count === 1 ? 'A file needs' : count + ' files need';
			return text + ' to be released';
		},
		titleText: count => {
			const text = count === 1 ? 'File' : count + ' Files';
			return text + ' Pending Release';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'File release approved';
			else return items.length + ' files release approved';
		},
		simpleName: 'File handle release',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 3,
		name: 'Stop process',
		mainEntities: [{ fieldName: 'process', entityTypeId: 2 }],
		type: RemediationActionTypeTypes.kill_process,
		icon: 'remediationActions.killProcess',
		remediationText: items => {
			return 'Stop ' + items.length + ' process' + (items.length !== 1 ? 'es' : '');
		},
		dismissText: items => {
			return 'Decline to stop ' + items.length + ' process' + (items.length !== 1 ? 'es' : '');
		},
		pendingText: count => {
			let verb: string, actionSuffix: string, prefix: string;
			if (count === 1) {
				verb = 'needs';
				actionSuffix = '';
				prefix = 'A';
			} else {
				verb = 'need';
				actionSuffix = 's';
				prefix = String(count);
			}
			return `${prefix} pending action${actionSuffix} to stop process ${verb} approval`;
		},
		titleText: count => {
			const text = count === 1 ? 'Process' : count + ' Processes';
			return text + ' Pending Termination';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'Process stop approved';
			else return items.length + ' processes stop approved';
		},
		simpleName: 'Stop process',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 18,
		name: 'Stop processes',
		mainEntities: [{ fieldName: 'processes', entityTypeId: 2, isList: true }],
		type: RemediationActionTypeTypes.kill_processes,
		icon: 'remediationActions.killProcess',
		remediationText: items => {
			return 'Stop processes';
		},
		dismissText: items => {
			return 'Decline to stop processes';
		},
		pendingText: count => {
			return `${String(count)} processes need to be stopped`;
		},
		titleText: count => {
			return 'Processes Memory Pending Termination';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'Process stop approved';
			else return items.length + ' processes stop approved';
		},
		simpleName: 'Stop processes',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 5,
		name: 'Block remote address',
		mainEntities: [{ fieldName: 'address', entityTypeId: 7 }],
		type: RemediationActionTypeTypes.block_ip,
		icon: 'remediationActions.blockIp',
		remediationText: items => {
			return 'Block ' + items.length + ' IP address' + (items.length !== 1 ? 'es' : '');
		},
		dismissText: items => {
			return 'Dismiss ' + items.length + ' IP address' + (items.length !== 1 ? 'es' : '');
		},
		isDisabled: items => {
			return !items || !items.length;
		},
		pendingText: count => {
			const text = count === 1 ? 'An IP address' : count + ' IP addresses';
			return text + ' need' + (count === 1 ? 's' : '') + ' to be blocked';
		},
		titleText: count => {
			const text = count === 1 ? 'An IP address' : count + ' IP Addresses';
			return text + ' needs to be blocked';
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'IP address block approved';
			else return items.length + ' IP addresses blocked approved';
		},
		simpleName: 'IP address block',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 16,
		name: 'Block URL',
		mainEntities: [{ fieldName: 'address', entityTypeId: 6 }],
		type: RemediationActionTypeTypes.block_url,
		icon: 'remediationActions.blockIp',
		remediationText: items => {
			return 'Block ' + items.length + ' URL' + (items.length !== 1 ? 's' : '');
		},
		dismissText: items => {
			return 'Dismiss ' + items.length + ' URL' + (items.length !== 1 ? 's' : '');
		},
		isDisabled: items => {
			return !items || !items.length;
		},
		pendingText: count => {
			const text = count === 1 ? 'A URL' : count + ' URLs';
			return `${text} should be blocked`;
		},
		titleText: count => {
			const text = count === 1 ? 'A URL' : count + ' URLs';
			return `${text} should be blocked`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			const text = 'The remediation action to block';
			if (isString || items.length === 1) return `${text} a URL was approved`;
			else return `${text} ${items.length} URLs was approved`;
		},
		simpleName: 'URL block',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 8,
		name: 'Waiting for device',
		type: RemediationActionTypeTypes.pending_resource,
		icon: 'hosts.status.unavailable',
		remediationText: items => {
			const itemsCount = items.length === 1 ? 'resource' : `${items.length} resources`;
			return `Retry to reach ${itemsCount}`;
		},
		pendingText: count => {
			return count === 1 ? 'Pending resource request' : `${count} Pending resource requests`;
		},
		titleText: count => {
			return `Resource Unavailable (${count})`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'Retried to reach resource';
			else return `Retried to reach resources`;
		},
		isDisabled: items => {
			return !items || !items.length;
		},
		isUserPending: false,
		allowDismiss: false,
	},
	{
		id: 9,
		name: 'Initiate DLP investigation',
		type: RemediationActionTypeTypes.initiate_dlp,
		icon: 'users.user_attention',
		remediationText: () => {
			return 'Limit User Privileges';
		},
		dismissText: items => {
			return `Don't limit privileges for ${items.length === 1 ? 'this' : 'these'} user${
				items.length === 1 ? '' : ''
			}`;
		},
		pendingText: count => {
			return `${count} Limit User Privileges`;
		},
		titleText: count => {
			return `Limit User Privileges (${count})`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'User privileges were limited';
			else return `Privileges for ${items.length} users were limited`;
		},
		simpleName: 'Limit user privileges',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 10,
		name: 'Limit user privileges',
		mainEntities: [{ fieldName: 'user', entityTypeId: 10 }],
		type: RemediationActionTypeTypes.limit_user_privileges_info,
		icon: 'users.user_attention',
		remediationText: () => {
			return 'Limit User Privileges';
		},
		dismissText: items => {
			return `Don't limit privileges for ${items.length === 1 ? 'this' : 'these'} user${
				items.length === 1 ? '' : ''
			}`;
		},
		pendingText: count => {
			return `${count} Limit User Privileges`;
		},
		titleText: count => {
			return `Limit User Privileges (${count})`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'User privileges were limited';
			else return `Privileges for ${items.length} users were limited`;
		},
		simpleName: 'Limit user privileges',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 11,
		name: 'Remove user from local group',
		mainEntities: [{ fieldName: 'user', entityTypeId: 10 }],
		type: RemediationActionTypeTypes.remove_user_from_local_group,
		icon: 'users.user_attention',
		remediationText: items => {
			return `Remove ${items.length} user${items.length === 1 ? '' : 's'} from local group`;
		},
		dismissText: items => {
			return `Don't limit privileges for ${items.length === 1 ? 'this' : 'these'} user${
				items.length === 1 ? '' : ''
			}`;
		},
		pendingText: count => {
			return `${count} Limit User Privileges`;
		},
		titleText: count => {
			return `Remove Users From Local Group (${count})`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			if (isString || items.length === 1) return 'User privileges were limited';
			else return `Privileges for ${items.length} users were limited`;
		},
		simpleName: 'User removed from local group',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 12,
		name: 'Expand pivot',
		mainEntities: [
			{ fieldName: 'file', entityTypeId: 1 },
			{ fieldName: 'appendedFile', entityTypeId: 8 },
			{ fieldName: 'address', entityTypeId: 7 },
		],
		type: RemediationActionTypeTypes.start_multiple_investigations,
		icon: 'createInvestigation',
		remediationText: items => {
			return `Investigate`;
		},
		dismissText: items => {
			return `Ignore`;
		},
		pendingText: count => {
			let verb: string, pivotSuffix: string, prefix: string;
			if (count === 1) {
				verb = 'needs';
				pivotSuffix = '';
				prefix = 'A';
			} else {
				verb = 'need';
				pivotSuffix = 's';
				prefix = String(count);
			}
			return `${prefix} pending investigation pivot${pivotSuffix} ${verb} approval`;
		},
		titleText: count => {
			return `Investigate Devices`;
		},
		notificationText: items => {
			return 'Investigations started';
		},
		simpleName: 'Expand pivot',
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 17,
		name: 'Remove script persistence',
		type: RemediationActionTypeTypes.persistence_method_quarantine,
		icon: 'remediationActions.quarantinePersistenceMethod',
		remediationText: items => {
			return `Remove script persistence`;
		},
		dismissText: items => {
			return `Decline to remove script persistence`;
		},
		pendingText: count => {
			let verb: string, actionSuffix: string, prefix: string;
			if (count === 1) {
				verb = 'needs';
				actionSuffix = '';
				prefix = 'A';
			} else {
				verb = 'need';
				actionSuffix = 's';
				prefix = String(count);
			}
			return `${prefix} pending action${actionSuffix} to remove script persistence ${verb} approval`;
		},
		titleText: count => {
			return `Persistence Method${count === 1 ? '' : 's'} Pending Quarantine`;
		},
		notificationText: items => {
			return 'Script persistence removal approved';
		},
		simpleName: 'Remove script persistence',
		mainEntities: [{ fieldName: 'persistenceMethod', entityTypeId: 17 }],
		allowDismiss: true,
		isUserPending: true,
	},
	{
		id: 19,
		name: 'Mark user as compromised',
		type: RemediationActionTypeTypes.confirm_compromised_user,
		icon: 'users.user_attention',
		remediationText: items => {
			return `Mark user as compromised`;
		},
		dismissText: items => {
			return `Decline to mark user as compromised`;
		},
		pendingText: count => {
			let verb: string, actionSuffix: string, prefix: string;
			if (count === 1) {
				verb = 'needs';
				actionSuffix = '';
				prefix = 'A';
			} else {
				verb = 'need';
				actionSuffix = 's';
				prefix = String(count);
			}
			return `${prefix} pending action${actionSuffix} to mark compromised users ${verb} approval`;
		},
		titleText: count => {
			return `Mark user as compromised pending`;
		},
		notificationText: items => {
			return 'User marking approved';
		},
		simpleName: 'Mark user as compromised',
		// TODO: change?
		mainEntities: [{ fieldName: 'identity', entityTypeId: 21 }],
		allowDismiss: true,
		isUserPending: true,
	},

	// fake type, not in BE
	{
		id: 110,
		name: 'Undo action',
		type: RemediationActionTypeTypes.undo_clean_resource,
		icon: 'remediationActions.undoCleanResource',
		simpleName: 'Undo clean resource',
	},
	{
		id: 120,
		name: 'Live Response command',
		type: RemediationActionTypeTypes.live_response_command,
		icon: 'entities.commandPrompt',
		simpleName: 'Live Response command',
	},
	{
		id: 200,
		name: 'Remove file',
		type: RemediationActionTypeTypes.remove_file,
		icon: 'remediationActions.removeFile',
		simpleName: 'Remove file',
	},

	// Office actions
	{
		id: 1000,
		name: 'Generic remediation action',
		type: RemediationActionTypeTypes.generic,
		// icon: 'entities.commandPrompt',
		simpleName: 'Generic remediation action',
	},
	{
		id: 1001,
		name: 'Quarantine registry key',
		type: RemediationActionTypeTypes.quarantine_registry_key,
		// icon: 'entities.commandPrompt',
		simpleName: 'Quarantine registry key',
	},
	{
		id: 1002,
		name: 'Block sender',
		type: RemediationActionTypeTypes.block_sender,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.BlockContact',
		simpleName: 'Block sender',
		pendingText: count => {
			const text = count === 1 ? "A sender's email address" : `${count} sender's email addresses`;
			return `${text} should be blocked`;
		},
		remediationText: items => {
			return 'Block sender\'s email address';
		},
		titleText: count => {
			const text = count === 1 ? "A sender's email address" : `${count} sender's email addresses`;
			return `${text} should be blocked`;
		},
		notificationText: items => {
			const isString = items.constructor === String;

			const text = 'The remediation action to block';
			if (isString || items.length === 1) return `${text} a sender\'s email address was approved`;
			else return `${text} ${items.length} senders\' email addresses was approved`;
		},
		isUserPending: true,
	},
	{
		id: 1003,
		name: 'Block sender domain',
		type: RemediationActionTypeTypes.block_sender_domain,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.URLBlock',
		simpleName: 'Block sender domain',
		pendingText: count => {
			return 'Block a sender\'s domain';
		},
		remediationText: items => {
			return 'Block a sender\'s domain';
		},
		titleText: count => {
			return 'Block a sender\'s domain';
		},
		notificationText: items => {
			return 'The remediation action to block a sender\'s domain was approved';
		},
		isUserPending: true,
	},
	{
		id: 1004,
		name: 'Block SPO file',
		type: RemediationActionTypeTypes.block_spo_file,
		// mainEntities: [{ fieldName: 'mailMessage', entityTypeId: -2 }],
		icon: 'entities.FileOff',
		simpleName: 'Block SPO file',
		pendingText: count => {
			return 'Block a file in SharePoint Online';
		},
		remediationText: items => {
			return 'Block a file in SharePoint Online';
		},
		titleText: count => {
			return 'Block a file in SharePoint Online';
		},
		notificationText: items => {
			return 'The remediation action to block a file in SharePoint Online was approved';
		},
		isUserPending: true,
	},
	{
		id: 1005,
		name: 'Soft delete emails',
		type: RemediationActionTypeTypes.email_remediation,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.deleteEmail',
		simpleName: 'Soft delete emails',
		pendingText: count => {
			const suffix = count === 1 ? 'email message' : 'email messages';
			return `Soft delete ${count} ${suffix}`;
		},
		remediationText: items => {
			return 'Soft delete email messages';
		},
		titleText: count => {
			return 'Soft delete email messages';
		},
		notificationText: items => {
			return 'The remediation action to soft delete email messages was approved';
		},
		isUserPending: true,
	},
	{
		id: 1006,
		name: 'Disable EWS configuration',
		type: RemediationActionTypeTypes.ews_setting_change_remediation,
		mainEntities: [{ fieldName: 'mailboxConfiguration', entityTypeId: -5 }],
		icon: 'entities.ConfigurationSolid',
		simpleName: 'Disable EWS configuration',
		pendingText: count => {
			return 'Disable EWS access for the mailbox';
		},
		remediationText: items => {
			return 'Disable EWS access for the mailbox';
		},
		titleText: count => {
			return 'Disable EWS access for the mailbox';
		},
		notificationText: items => {
			return 'The remediation action to disable EWS access for the mailbox was approved';
		},
		isUserPending: true,
	},
	{
		id: 1007,
		name: 'Disable mailbox delegation',
		type: RemediationActionTypeTypes.mail_delegation_remediation,
		mainEntities: [{ fieldName: 'mailboxConfiguration', entityTypeId: -5 }],
		icon: 'entities.mailOptions',
		simpleName: 'Disable mailbox delegation',
		pendingText: count => {
			return 'Remove email delegation';
		},
		remediationText: items => {
			return 'Remove email delegation';
		},
		titleText: count => {
			return 'Remove email delegation';
		},
		notificationText: items => {
			return 'The remediation action to remove email delegation was approved';
		},
		isUserPending: true,
	},
	{
		id: 1008,
		name: 'Turn off external mail forwarding',
		type: RemediationActionTypeTypes.mail_forwarding_rule_remediation,
		mainEntities: [
			{ fieldName: 'mailbox', entityTypeId: -3 },
			{ fieldName: 'mailboxConfiguration', entityTypeId: -5 },
		],
		icon: 'entities.mailForward',
		simpleName: 'Turn off external mail forwarding',
		pendingText: count => {
			return 'Remove an email forwarding rule';
		},
		remediationText: items => {
			return 'Remove an email forwarding rule';
		},
		titleText: count => {
			return 'Disable email forwarding';
		},
		notificationText: items => {
			return 'The remediation action to remove an email forwarding to external email addresses was approved';
		},
		isUserPending: true,
	},
	{
		id: 1009,
		name: 'Disable Outlook Web Access',
		type: RemediationActionTypeTypes.owa_setting_change_remediation,
		mainEntities: [{ fieldName: 'mailboxConfiguration', entityTypeId: -5 }],
		icon: 'entities.ConfigurationSolid',
		simpleName: 'Disable Outlook Web Access',
		pendingText: count => {
			return 'Disable Outlook on the web for the mailbox';
		},
		remediationText: items => {
			return 'Disable Outlook on the web for the mailbox';
		},
		titleText: count => {
			return 'Disable Outlook on the web for the mailbox';
		},
		notificationText: items => {
			return 'The remediation action to disable Outlook on the web for the mailbox was approved';
		},
		isUserPending: true,
	},
	{
		id: 1010,
		name: 'Move to inbox',
		type: RemediationActionTypeTypes.move_to_inbox,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.RemoveFromTrash',
		simpleName: 'Move to inbox',
		pendingText: count => {
			return 'Move email to the Inbox';
		},
		remediationText: items => {
			return 'Move email to the Inbox';
		},
		titleText: count => {
			return 'Move email to the Inbox';
		},
		notificationText: items => {
			return 'The action to move email to the Inbox was approved';
		},
		isUserPending: true,
	},
	{
		id: 1011,
		name: 'Hard delete emails',
		type: RemediationActionTypeTypes.hard_email_remediation,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.Delete',
		simpleName: 'Hard delete emails',

		pendingText: count => {
			const suffix = count === 1 ? 'email message' : 'email messages';
			return `Hard delete ${count} ${suffix}`;
		},
		remediationText: items => {
			return 'Hard delete email messages';
		},
		titleText: count => {
			return 'Hard delete email messages';
		},
		notificationText: items => {
			return 'The remediation action to hard delete email messages was approved';
		},
		isUserPending: true,
	},
	{
		id: 1012,
		name: 'Move to junk',
		type: RemediationActionTypeTypes.move_to_junk,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.RemoveContent',
		simpleName: 'Move to junk',
		pendingText: count => {
			return 'Move email to the Junk Email folder';
		},
		remediationText: items => {
			return 'Move email to the Junk Email folder';
		},
		titleText: count => {
			return 'Move email to Junk Email folder';
		},
		notificationText: items => {
			return 'The remediation action to move email to the Junk Email folder was approved';
		},
		isUserPending: true,
	},
	{
		id: 1013,
		name: 'Move to deleted items',
		type: RemediationActionTypeTypes.move_to_deleted_items,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.Delete',
		simpleName: 'Move to deleted items',
		pendingText: count => {
			return 'Move email to the Deleted Items folder';
		},
		remediationText: items => {
			return 'Move email to the Deleted Items folder';
		},
		titleText: count => {
			return 'Move email to Deleted Items folder';
		},
		notificationText: items => {
			return 'The remediation action to move email to the Deleted Items folder was approved';
		},
		isUserPending: true,
	},
	{
		id: 1014,
		name: 'Force password reset',
		type: RemediationActionTypeTypes.force_password_reset_remediation,
		mainEntities: [
			{ fieldName: 'mailbox', entityTypeId: -3 },
			{ fieldName: 'account', entityTypeId: 22 },
		],
		icon: 'entities.CRMServices',
		simpleName: 'Force password reset',
		pendingText: count => {
			return 'Require the user to reset their password';
		},
		remediationText: items => {
			return 'Require a user to reset their password';
		},
		titleText: count => {
			return 'Require the user to reset their password';
		},
		notificationText: items => {
			return 'The remediation action to require the user to reset their password was approved';
		},
		isUserPending: true,
	},
	{
		id: 1015,
		name: 'Disable suspicious Outlook Inbox rules',
		type: RemediationActionTypeTypes.user_inbox_rules_remediation,
		mainEntities: [{ fieldName: 'mailboxConfiguration', entityTypeId: -5 }],
		icon: 'entities.DisableUpdates',
		simpleName: 'Disable suspicious Outlook Inbox rules',
		pendingText: count => {
			return 'Disable a rule in Outlook';
		},
		remediationText: items => {
			return 'Disable a rule in Outlook';
		},
		titleText: count => {
			return 'Disable a rule in Outlook';
		},
		notificationText: items => {
			return 'The remediation action to disable a suspicious rule in Outlook was approved';
		},
		isUserPending: true,
	},
	{
		id: 1016,
		name: 'Block IP',
		type: RemediationActionTypeTypes.office_block_ip,
		mainEntities: [{ fieldName: 'mailMessage', entityTypeId: -2 }],
		icon: 'entities.URLBlock',
		simpleName: 'Block IP',
		pendingText: count => {
			return 'Block a sender\'s IP address';
		},
		remediationText: items => {
			return 'Block a sender\'s IP address';
		},
		titleText: count => {
			return 'Block a sender\'s IP address';
		},
		notificationText: items => {
			return 'The remediation action to block a sender\'s IP address was approved';
		},
		isUserPending: true,
	},
	{
		id: 1017,
		name: 'Delete attachment',
		type: RemediationActionTypeTypes.delete_attachment,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.RecycleBin',
		simpleName: 'Delete attachment',
		pendingText: count => {
			return 'Delete an email attachment';
		},
		remediationText: items => {
			return 'Delete an email attachment';
		},
		titleText: count => {
			return 'Delete attachment';
		},
		notificationText: items => {
			return 'The remediation action to delete an email attachment was approved';
		},
		isUserPending: true,
	},
	{
		id: 1018,
		name: 'Submit for dynamic analysis',
		type: RemediationActionTypeTypes.submit_for_detonation,
		mainEntities: [
			{ fieldName: 'mailMessage', entityTypeId: -2 },
			{ fieldName: 'mailCluster', entityTypeId: -1 },
		],
		icon: 'entities.ZoomIn',
		simpleName: 'Submit for dynamic analysis',
		pendingText: count => {
			return 'Submit a file or URL for dynamic analysis';
		},
		remediationText: items => {
			return 'Submit a file or URL for dynamic analysis';
		},
		titleText: count => {
			return 'Submit a file or URL for dynamic analysis';
		},
		notificationText: items => {
			return 'The action to submit a file or URL for dynamic analysis was approved';
		},
		isUserPending: true,
	},

	// Cloud actions
	{
		id: 2000,
		name: 'Isolate device',
		type: RemediationActionTypeTypes.isolate_response,
		simpleName: 'Isolate device',
	},
	// {
	// 	id: 2001,
	// 	name: 'Stop isolation',
	// 	type: RemediationActionTypeTypes.unisolate,
	// },
	// {
	// 	id: 2002,
	// 	name: 'Stop and quarantine',
	// 	type: RemediationActionTypeTypes.stop_and_quarantine,
	// },
	// {
	// 	id: 2003,
	// 	name: 'Request sample',
	// 	type: RemediationActionTypeTypes.request_sample,
	// 	simpleName: 'Request sample',
	// },
	{
		id: 2004,
		name: 'Restrict app execution',
		type: RemediationActionTypeTypes.restrict_app_execution_response,
		simpleName: 'Restrict app execution',
	},
	{
		id: 2005,
		name: 'Stop restricting app execution',
		type: RemediationActionTypeTypes.unrestrict_app_execution_response,
		simpleName: 'Stop restricting app execution',
	},
	// {
	// 	id: 2006,
	// 	name: 'Restrict code execution',
	// 	type: RemediationActionTypeTypes.restrict_code_execution,
	// 	simpleName: 'Restrict code execution',
	// },
	// {
	// 	id: 2007,
	// 	name: 'Stop restricting code execution',
	// 	type: RemediationActionTypeTypes.unrestrict_code_execution,
	// 	simpleName: 'Stop restricting code execution',
	// },
	{
		id: 2008,
		name: 'Start antivirus scan',
		type: RemediationActionTypeTypes.antivirus_scan_response,
		simpleName: 'Start antivirus scan',
	},
	{
		id: 2009,
		name: 'Collect investigation package',
		type: RemediationActionTypeTypes.collect_investigation_package_response,
		simpleName: 'Collect investigation package',
	},
	{
		id: 2010,
		name: 'Offboard device',
		type: RemediationActionTypeTypes.offboard_device,
		simpleName: 'Offboard device',
	},
	{
		id: 2011,
		name: 'Stop and quarantine',
		type: RemediationActionTypeTypes.stop_and_quarantine,
		simpleName: 'Stop and quarantine',
	},
	{
		id: 2012,
		name: 'Stop isolation',
		type: RemediationActionTypeTypes.unisolate,
		simpleName: 'Stop isolation',
	},
	{
		id: 2013,
		name: 'Collect file',
		type: RemediationActionTypeTypes.request_sample,
		simpleName: 'Collect file',
	},


	// Msec Identity actions
	{
		id: 3000,
		name: 'Confirm user is compromised',
		mainEntities: [{ fieldName: 'account', entityTypeId: 22 }],
		type: RemediationActionTypeTypes.confirm_user_compromised,
		simpleName: 'Confirm user is compromised',
		remediationText: items => 'Confirm a user is compromised',
		titleText: count => 'Confirm a user is compromised',
		icon: 'users.user',
		isUserPending: false,
	},
	{
		id: 3001,
		name: 'Suspend user',
		mainEntities: [{ fieldName: 'account', entityTypeId: 22 }],
		type: RemediationActionTypeTypes.suspend_user,
		simpleName: 'Suspend user',
		remediationText: items => 'Suspend a user',
		titleText: count => 'Suspend a user',
		icon: 'users.user',
		isUserPending: false,
	},
	{
		id: 3002,
		name: 'Invalidate all refresh tokens for a user',
		mainEntities: [{ fieldName: 'account', entityTypeId: 22 }],
		type: RemediationActionTypeTypes.invalidate_all_refresh_tokens_for_a_user,
		simpleName: 'Invalidate all refresh tokens for a user',
		remediationText: items => 'Invalidate all refresh tokens for a user',
		titleText: count => 'Invalidate all refresh tokens for a user',
		icon: 'users.user',
		isUserPending: false,
	},

	// MDI actions
	{
		id: 4000,
		name: 'Disable user',
		mainEntities: [{ fieldName: 'account', entityTypeId: 22 }],
		type: RemediationActionTypeTypes.disable_user,
		simpleName: 'Disable user',
		remediationText: items => 'Disable a user',
		titleText: count => 'Disable a user',
		icon: 'users.user',
		isUserPending: false,
	},
	{
		id: 4002,
		name: 'Enable user',
		mainEntities: [{ fieldName: 'account', entityTypeId: 22 }],
		type: RemediationActionTypeTypes.enable_user,
		simpleName: 'Enable a user account',
		remediationText: items => 'Enable a user account',
		titleText: count => 'Enable a user account',
		icon: 'users.user',
		isUserPending: false,
	},
	{
		id: 5000,
		name: 'Contain device',
		type: RemediationActionTypeTypes.contain_device,
		icon: 'remediationActions.blockIp',
		simpleName: 'Contain device',
		titleText: count => 'Contain device',
		isUserPending: false,
	},
	{
		id: 5001,
		name: 'Release device containment',
		type: RemediationActionTypeTypes.uncontain_device,
		icon: 'remediationActions.blockIp',
		simpleName: 'Release device from containment',
		titleText: count => 'Release device from containment',
		isUserPending: false,
	},
];
