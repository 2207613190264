import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { FeaturesService, Feature, TenantStatusGuard, EndpointsGuard } from '@wcd/config';
import { FeatureEnabledGuard, FlavorGuard, MtpPermission, OatpGuard } from '@wcd/auth';
import { cloneDeep } from 'lodash-es';
import { RbacControlService } from '../../../../../apps/portal/src/app/rbac/services/rbac-control.service';
import { MtpWorkload } from '@wcd/domain';

@Injectable()
export class ThreatAnalyticsMtpGuard implements CanActivate {
	constructor(
		private featuresService: FeaturesService,
		private endpointsGuard: EndpointsGuard,
		private featureEnabledGuard: FeatureEnabledGuard,
		private tenantStatusGuard: TenantStatusGuard,
		private oatpGuard: OatpGuard,
		private flavorGuard: FlavorGuard,
		private rbacControlService: RbacControlService,
	) {}

	canActivateForMde(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const hasReadPermissionOnMdatp = this.rbacControlService.hasRequiredRbacPermissions({
			mtpPermissions: [MtpPermission.SecurityData_Read],
			mtpWorkloads: [MtpWorkload.Mdatp],
		});

		return this.endpointsGuard.canActivate(route, state) && this.flavorGuard.canActivate(route, state) && hasReadPermissionOnMdatp;
	}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> | UrlTree {
		const threatAnalyticsMtpFeatures: Array<string> = [Feature.ThreatAnalyticsMTPForMDATP, Feature.ThreatAnalyticsMTP];
		if (this.featuresService.isAnyEnabled(threatAnalyticsMtpFeatures)) {
			// Note: OatpGuard should run before canActivateForMde since canActivateForMde might perform redirect
			return (
				this.tenantStatusGuard.canActivate(route, state) &&
				(this.oatpGuard.canActivate() || this.canActivateForMde(route, state))
			);
		} else {
			const requiredFeatures = { features: [Feature.PortedSccPages] };
			const clonedRouteSnapshot = cloneDeep(route);
			clonedRouteSnapshot.data = requiredFeatures;
			clonedRouteSnapshot.routeConfig.data = requiredFeatures;
			return this.canActivateForMde(route, state) && this.featureEnabledGuard.canActivate(clonedRouteSnapshot, state);
		}
	}
}
