import { Directive, AfterViewInit, HostListener, OnDestroy, ElementRef, Input } from '@angular/core';
import { FocusKeyManager } from '@angular/cdk/a11y';
import {
	KeyboardNavigableElementAddedEventName,
	KeyboardNavigableElementDirective,
	KeyboardNavigableElementRemoveEventName,
	KeyboardNavigableElementSelectedExternallyEventName,
} from './keyboard-navigable-element.directive';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';

export interface ListItemManagerInput {
	horizontal: boolean;
}

/*
	This directive should be used along with KeyboardNavigableElement in order to enable selection of element
	(focus on elements) using arrow keys. This directive is used as a container for navigable elements within
	this container.
 */
@Directive({
	selector: '[keyboard-navigable-container]',
})
export class KeyboardNavigableContainerDirective
	implements AfterViewInit, OnDestroy, OnChanges<KeyboardNavigableContainerDirective> {
	@Input('keyboard-navigable-container') config?: ListItemManagerInput = { horizontal: false };

	@Input() elementVisible: boolean = true;
	@Input() stopPropagation: string[] = [];

	private readonly nativeElement: HTMLElement;
	private keyboardAccessibleItems: KeyboardNavigableElementDirective[] = [];
	private keyManager: FocusKeyManager<KeyboardNavigableElementDirective>;
	private firstSelectableElementFound: boolean = false;

	private itemAddedCallback = (event: CustomEvent) => {
		this.keyboardAccessibleItems.push(event.detail);

		const firstSelectableElement =
			this.keyboardAccessibleItems.find(item => item.isStopPoint) ||
			(this.keyboardAccessibleItems.length && this.keyboardAccessibleItems[0]);
		if (firstSelectableElement && !this.firstSelectableElementFound && this.keyManager) {
			this.keyManager.setActiveItem(firstSelectableElement);
			this.firstSelectableElementFound = true;
		}

		if (event.stopPropagation) {
			event.stopPropagation();
		}
	};

	private itemRemovedCallback = (event: CustomEvent) => {
		const index = this.keyboardAccessibleItems.indexOf(event.detail);

		if (index > -1) {
			this.keyboardAccessibleItems.splice(index, 1);
		}

		if (event.stopPropagation) {
			event.stopPropagation();
		}
	};

	private itemSelectedCallback = (event: CustomEvent) => {
		const item = event.detail;
		const previousActiveItem = this.keyManager.activeItem;

		this.keyManager.setFocusOrigin('keyboard');
		this.keyManager.setActiveItem(item);
		if (previousActiveItem && previousActiveItem !== this.keyManager.activeItem) {
			previousActiveItem.deactivate();
		}
	};

	constructor(private readonly elementRef: ElementRef<HTMLElement>) {
		this.nativeElement = this.elementRef.nativeElement;
		this.nativeElement.addEventListener(KeyboardNavigableElementAddedEventName, this.itemAddedCallback);
		this.nativeElement.addEventListener(
			KeyboardNavigableElementRemoveEventName,
			this.itemRemovedCallback
		);
		this.nativeElement.addEventListener(
			KeyboardNavigableElementSelectedExternallyEventName,
			this.itemSelectedCallback
		);
	}

	ngOnChanges(changes: TypedChanges<KeyboardNavigableContainerDirective>): void {
		if (changes.elementVisible && changes.elementVisible.currentValue) {
			this.setFirstSelectableElement();
		}
	}

	ngAfterViewInit(): void {
		this.keyManager = new FocusKeyManager(this.keyboardAccessibleItems).skipPredicate(
			item => !item.visible
		);

		if (this.config.horizontal) {
			this.keyManager.withHorizontalOrientation('ltr').withVerticalOrientation(false);
		}
		this.setFirstSelectableElement();
	}

	setFirstSelectableElement() {
		const firstSelectableElement =
			this.keyboardAccessibleItems.find(item => item.isStopPoint) ||
			(this.keyboardAccessibleItems.length && this.keyboardAccessibleItems[0]) || null;

		if (firstSelectableElement && this.keyManager) {
			this.keyManager.setActiveItem(firstSelectableElement);
			this.firstSelectableElementFound = true;
		}
	}

	ngOnDestroy() {
		this.nativeElement.removeEventListener(
			KeyboardNavigableElementAddedEventName,
			this.itemAddedCallback
		);
		this.nativeElement.removeEventListener(
			KeyboardNavigableElementRemoveEventName,
			this.itemRemovedCallback
		);
		this.nativeElement.removeEventListener(
			KeyboardNavigableElementSelectedExternallyEventName,
			this.itemRemovedCallback
		);
		this.keyboardAccessibleItems = null;
	}

	@HostListener('keydown', ['$event'])
	onKeydown($event: KeyboardEvent) {
		const previousActiveItem = this.keyManager.activeItem;

		this.keyManager.onKeydown($event);
		if (this.stopPropagation.includes(($event.key))) {
			$event.stopPropagation();
			$event.stopImmediatePropagation();
			$event.preventDefault();
		}
		if (previousActiveItem && previousActiveItem !== this.keyManager.activeItem) {
			previousActiveItem.deactivate();
		}
	}
}
