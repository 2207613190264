import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Severity } from '../severity/severity.entity';

@ValueObject({
	singularName: 'Alert Severity Count',
	pluralName: 'Alert Severity Count',
	readonly: true,
})
export class AlertSeverityCount extends ModelBase {
	@EntityField() severity: Severity;

	@EntityField({ defaultValue: 0 })
	count: number;
}
