var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { EntityPanelWithAlertComponent } from '../../../global_entities/components/entity-panels/entity-panel-with-alert.component.base';
import { MachinesService } from '../../machines/services/machines.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { AlertEntityTypeService } from '../../alerts/services/alert.entity-type.service';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { I18nService } from '@wcd/i18n';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "investigation-entity-panel-details";
    CollapsibleID["TriggeringAlertDetails"] = "investigation-entity-panel-triggering-alert-details";
    CollapsibleID["TriggeringAlertDescription"] = "investigation-entity-panel-triggering-alert-description";
    CollapsibleID["TriggeringAlertRecommendedAction"] = "investigation-entity-panel-triggering-alert-recommended-action";
    CollapsibleID["relatedMachine"] = "investigation-entity-panel-related-machine";
})(CollapsibleID || (CollapsibleID = {}));
var InvestigationEntityPanelComponent = /** @class */ (function (_super) {
    __extends(InvestigationEntityPanelComponent, _super);
    function InvestigationEntityPanelComponent(changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService, alertEntityTypeService, i18nService) {
        var _this = _super.call(this, changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService) || this;
        _this.alertEntityTypeService = alertEntityTypeService;
        _this.i18nService = i18nService;
        _this.collapsibleID = CollapsibleID;
        return _this;
    }
    Object.defineProperty(InvestigationEntityPanelComponent.prototype, "investigation", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    // Update table when expanding collapsible - fixes table UI not aligned on expansion
    InvestigationEntityPanelComponent.prototype.updateAlertsTable = function () {
        if (this.machineEntityCmp) {
            this.machineEntityCmp.updateTableView();
        }
    };
    InvestigationEntityPanelComponent.prototype.initEntity = function (investigation, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, investigation, isExtendedData);
        if (investigation && isExtendedData) {
            if (investigation.machines.length === 1)
                this.setMachine(investigation.machines[0].machineId);
            if (investigation.alert)
                this.setAlert(investigation.alert.id);
        }
    };
    return InvestigationEntityPanelComponent;
}(EntityPanelWithAlertComponent));
export { InvestigationEntityPanelComponent };
