/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../global_entities/components/entity-details/registry-modification.entity-details.component.ngfactory";
import * as i2 from "../../../global_entities/components/entity-details/registry-modification.entity-details.component";
import * as i3 from "./registry.entity-panel.component";
var styles_RegistryEntityPanelComponent = [];
var RenderType_RegistryEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistryEntityPanelComponent, data: {} });
export { RenderType_RegistryEntityPanelComponent as RenderType_RegistryEntityPanelComponent };
export function View_RegistryEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-full-height wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "registry-modification-entity-details", [], null, null, null, i1.View_RegistryModificationEntityDetailsComponent_0, i1.RenderType_RegistryModificationEntityDetailsComponent)), i0.ɵdid(3, 114688, null, 0, i2.RegistryModificationEntityDetailsComponent, [i0.Injector], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.registry; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RegistryEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "registry-entity-panel", [], null, null, null, View_RegistryEntityPanelComponent_0, RenderType_RegistryEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i3.RegistryEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistryEntityPanelComponentNgFactory = i0.ɵccf("registry-entity-panel", i3.RegistryEntityPanelComponent, View_RegistryEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { RegistryEntityPanelComponentNgFactory as RegistryEntityPanelComponentNgFactory };
