export type AntivirusScanType = keyof typeof QueryAntivirusScanTypeRaw;

/**
 * When querying (GET) The backend - we get back the "raw" value (i.e. as a number - either 0 or 1).
 * When making calls on it, the backend expects string values (i.e. either 'Full' or 'Quick'). This is typed above as `AntivirusScanType`.
 */
export enum QueryAntivirusScanTypeRaw {
	Quick = 0,
	Full = 1,
}
