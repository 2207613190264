import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.preferences.orgsize', onboardingOrgsizeController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.orgsize', {
			url: '/orgsize',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Set up your preferences</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position">
                        <div class="page-header-title onboarding-title-margin">
                            Select your organization size
                        </div>
                        <div>
                            Select the estimated number of devices you have in your organization.
                        </div>
                        <br />
                        <div class="btn-group onboarding-dropdown-custom onboarding-dropdown-margin-top" style="text-align: right" uib-dropdown>
                            <button class="onboarding-dropdown" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle>
                                {{orgsize.endpoints}}
                                <span class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                            </button>
            
                            <ul class="dropdown-menu-shadow dropdown-menu onboarding-dropdown-custom" role="menu">
                                <li ng-model="orgsize.endpoints" uib-btn-radio="'Up to 1000'"><a href="#">Up to 1,000</a></li>
                                <li ng-model="orgsize.endpoints" uib-btn-radio="'1001 - 10000'"><a href="#">1,001 - 10,000</a></li>
                                <li ng-model="orgsize.endpoints" uib-btn-radio="'10001 - 100000'"><a href="#">10,001 - 100,000</a></li>
                                <li ng-model="orgsize.endpoints" uib-btn-radio="'100001 - 500000'"><a href="#">100,001 - 500,000</a></li>
                                <li ng-model="orgsize.endpoints" uib-btn-radio="'Above 500000'"><a href="#">Above 500,000</a></li>
                            </ul>
            
                        </div>
                    </div>
                </div>
                <div class="onboarding-navigation">
                    <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-icon onboarding-paging-icon-back-margin" ng-click="orgsize.MoveBack()"></i>
                    Back
                </div>
                <div class="onboarding-navigation onboarding-paging-icon-margin">
                    Next
                    <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin" ng-click="orgsize.MoveNext()"></i>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.orgsize',
					controllerAs: 'orgsize',
				},
			},
		});
	},
]);

onboardingOrgsizeController.$inject = ['$scope', '$state'];

function onboardingOrgsizeController($scope, $state) {
	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPreferencesInProgress = true;
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
		});

		var minEndpointsNumber = 0;
		var maxEndpointsNumber = 10000;

		vm.MoveBack = function() {
			ParseEndpointsNumberSelection();
			($scope.$parent.minEndpointsNumber = minEndpointsNumber),
				($scope.$parent.maxEndpointsNumber = maxEndpointsNumber),
				$state.go('onboarding.retention');
		};

		vm.MoveNext = function() {
			ParseEndpointsNumberSelection();
			($scope.$parent.minEndpointsNumber = minEndpointsNumber),
				($scope.$parent.maxEndpointsNumber = maxEndpointsNumber),
				$state.go('onboarding.industry');
		};

		setRestoredEndpointsNumber($scope.$parent.minEndpointsNumber);
	}

	function ParseEndpointsNumberSelection() {
		if (vm.endpoints === 'Up to 1000') {
			minEndpointsNumber = 0;
			maxEndpointsNumber = 1000;
		} else if (vm.endpoints === '1001 - 10000') {
			minEndpointsNumber = 1001;
			maxEndpointsNumber = 10000;
		} else if (vm.endpoints === '10001 - 100000') {
			minEndpointsNumber = 10001;
			maxEndpointsNumber = 100000;
		} else if (vm.endpoints === '100001 - 500000') {
			minEndpointsNumber = 100001;
			maxEndpointsNumber = 500000;
		} else if (vm.endpoints === 'Above 500000') {
			minEndpointsNumber = 500000;
			maxEndpointsNumber = 1000000;
		}
	}

	function setRestoredEndpointsNumber(restoredMinEndpoints) {
		if (restoredMinEndpoints === 0) {
			vm.endpoints = 'Up to 1000';
		} else if (restoredMinEndpoints === 1001) {
			vm.endpoints = '1001 - 10000';
		} else if (restoredMinEndpoints === 10001) {
			vm.endpoints = '10001 - 100000';
		} else if (restoredMinEndpoints === 100001) {
			vm.endpoints = '100001 - 500000';
		} else if (restoredMinEndpoints === 500000) {
			vm.endpoints = 'Above 500000';
		}
	}
}
