
		<div class="wcd-full-height wcd-flex-vertical">
			<div #graphContainer class="wcd-flex-1 wcd-scroll-vertical">
				<div *ngIf="loading && !error" class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
				<div *ngIf="error" class="wcd-padding-all">
					<wcd-shared-icon iconName="error"></wcd-shared-icon>
					{{ 'alert.tabs.graph.error' | i18n }}
				</div>
			</div>
		</div>
	