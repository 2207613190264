import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { alertGroupTypes } from './alert-group-type.values';
import { StatusModel } from '../status.model';

@Entity({
	singularName: 'Alert Group Type',
	pluralName: 'Alert Group Types',
	values: alertGroupTypes,
})
export class AlertGroupType extends EntityModelBase {
	@EntityField() nameI18nKey: string;
}
