import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';

@Entity({
	...airsEntityConfig,
	singularName: 'Link',
	pluralName: 'Links',
	modelWith: null,
})
export class AirsLinkBase extends AirsEntity {
	@EntityField()
	address: string;

	@EntityField({ data: 'ip_addresses' })
	ipAddresses: Array<string>;

	@EntityField({ data: 'status' })
	domainStatus: string;

	constructor(data) {
		super(data);
		this.entityName = this.address;
	}
}
