var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnInit, ViewContainerRef, } from '@angular/core';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { merge, of, Subject } from 'rxjs';
import { toObservable } from '../../../utils/rxjs/utils';
import { map, share, shareReplay, take } from 'rxjs/operators';
import { ServiceUrlsService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { sccHostService } from '@wcd/scc-interface';
var buttonSize = 48;
var lastId = 0;
var entityIconStyle = {
    root: {
        fontSize: '42px',
        lineHeight: '72px',
        width: '72px',
        textAlign: 'center',
        verticalAlign: 'super',
        marginLeft: '2px',
    },
};
var defaultButtonStyle = {
    padding: 0,
    top: 0,
    minWidth: buttonSize,
    height: buttonSize,
    backgroundColor: 'transparent',
    position: 'absolute',
};
var leftPositionedSidePanelButtonStyles = {
    root: __assign({}, defaultButtonStyle, { left: 0 }),
};
var rightPositionedSidePanelButtonStyles = {
    root: __assign({}, defaultButtonStyle, { right: 0 }),
};
var defaultHiddenSidePanelButtonsStyles = {
    root: __assign({}, defaultButtonStyle, { position: 'relative' }),
};
var EntityDetailsPanelComponent = /** @class */ (function () {
    function EntityDetailsPanelComponent(resolver, serviceUrls, i18nService, liveAnnouncer) {
        this.resolver = resolver;
        this.serviceUrls = serviceUrls;
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.rightSidePanel = false;
        this.removePaddingRight = false;
        // This is here for backward compatibility, while we need to support not-"asset"/"modern" pages.
        // It can be removed once all slices will get new pages
        this.topPadded = true;
        this.showToggleButton = false;
        this.toggleButtonPosition = 'right';
        this.showTitle = true;
        this.minimized = false;
        this.styleSettings = {
            leftArrowIconName: 'ChevronLeft',
            rightArrowIconName: 'ChevronRight',
            leftPositionedSidePanelButtonStyles: leftPositionedSidePanelButtonStyles,
            rightPositionedSidePanelButtonStyles: rightPositionedSidePanelButtonStyles,
            hiddenPanelButtons: defaultHiddenSidePanelButtonsStyles,
        };
        this.paneUniqueId = "entity-details-pane-" + ++lastId;
        this.toggleIconName = this.styleSettings.leftArrowIconName;
        this.toggleButtonStyleSettings = this.styleSettings.rightPositionedSidePanelButtonStyles;
        this.entityPageViewModeEnum = EntityPageViewMode;
        this._tagsSubject$ = new Subject();
        this.entityIconStyle = entityIconStyle;
        this.isScc = sccHostService.isSCC;
    }
    Object.defineProperty(EntityDetailsPanelComponent.prototype, "bottomHeaderEntityViewPlaceHolder", {
        set: function (bottomHeaderEntityViewPlaceHolderContainertRef) {
            this.setBottomHeaderComponent(bottomHeaderEntityViewPlaceHolderContainertRef);
        },
        enumerable: true,
        configurable: true
    });
    EntityDetailsPanelComponent.prototype.ngOnInit = function () {
        this.entityTitle = this.i18nService.get('dataview_entity_details_panel_button_title');
        if (this.entity && this.entityType) {
            var entityTypeDisplayName = this.i18nService.get("entity_type_display_name_" + this.entityType.id, {}, true);
            if (entityTypeDisplayName !== '') {
                this.entityTitle = this.i18nService.get('dataview_entity_details_panel_title', { entityTitle: entityTypeDisplayName });
            }
        }
    };
    EntityDetailsPanelComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.entity && this.entityType) {
            this.setEntityDetailsComponent();
            this.setMinimizedDetailsComponent();
            this.iconName = this.entityType.getIcon
                ? this.entityType.getIcon([this.entity])
                : this.entityType.icon;
            this.iconCssClass =
                this.entityType.getIconCssClass && this.entityType.getIconCssClass([this.entity]);
            this.subTitle = this.entityType.getSubtitle && this.entityType.getSubtitle([this.entity]);
            this.iconImageUrl$ = this.entityType.getImage
                ? toObservable(this.entityType.getImage([this.entity])).pipe(map(function (imageUrl) { return "url(" + _this.serviceUrls.assetsBaseUrl + imageUrl + ")"; }), take(1), share())
                : of(null);
            if (this.entityType.getTags) {
                this.tags$ = merge(toObservable(this.entityType.getTags([this.entity])), this._tagsSubject$).pipe(shareReplay(1));
            }
            this.title = this.entityType.getEntityName(this.entity);
        }
        if (changes.toggleButtonPosition &&
            (changes.toggleButtonPosition.firstChange ||
                changes.toggleButtonPosition.currentValue !== this.toggleButtonPosition)) {
            this.updateButtonStyles();
        }
    };
    EntityDetailsPanelComponent.prototype.toggleSidePanel = function () {
        this.minimized = !this.minimized;
        this.updateButtonStyles();
        this.fireResizeEvent();
        this.announceToggleSidePanel();
    };
    EntityDetailsPanelComponent.prototype.fireResizeEvent = function () {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    };
    EntityDetailsPanelComponent.prototype.setEntityDetailsComponent = function () {
        if (!(this.detailsComponentRef && this.detailsComponentRef.instance)) {
            var injector = Injector.create({
                providers: [],
                parent: this.entityDetailsPlaceholder.parentInjector,
            }), factory = this.resolver.resolveComponentFactory(this.entityType.entityDetailsComponentType);
            this.detailsComponentRef = factory ? factory.create(injector) : null;
            Object.assign(this.detailsComponentRef.instance, {
                asKeyValueList: false,
                mode: EntityDetailsMode.EntityPage,
                entityPageViewMode: this.entityPageViewMode,
            });
            this.entityDetailsPlaceholder.insert(this.detailsComponentRef.hostView);
        }
        this.detailsComponentRef.instance.setEntity(this.entity);
    };
    EntityDetailsPanelComponent.prototype.setMinimizedDetailsComponent = function () {
        if (!(this.minimizedComponentRef && this.minimizedComponentRef.instance) &&
            this.entityType.entityMinimizedComponentType) {
            var factory = this.resolver.resolveComponentFactory(this.entityType.entityMinimizedComponentType);
            this.minimizedComponentRef = this.minimizedEntityViewPlaceholder.createComponent(factory);
            this.minimizedEntityViewPlaceholder.insert(this.minimizedComponentRef.hostView);
            this.minimizedComponentRef.instance.setEntity(this.entity);
        }
    };
    EntityDetailsPanelComponent.prototype.setBottomHeaderComponent = function (bottomHeaderEntityViewPlaceHolderContainertRef) {
        if (!(this.headerBottomComponentRef && this.headerBottomComponentRef.instance) &&
            this.entityType.entityHeaderBottomComponentType && bottomHeaderEntityViewPlaceHolderContainertRef) {
            var factory = this.resolver.resolveComponentFactory(this.entityType.entityHeaderBottomComponentType);
            this.headerBottomComponentRef = bottomHeaderEntityViewPlaceHolderContainertRef.createComponent(factory);
            bottomHeaderEntityViewPlaceHolderContainertRef.insert(this.headerBottomComponentRef.hostView);
            this.headerBottomComponentRef.instance.setEntity(this.entity);
        }
    };
    EntityDetailsPanelComponent.prototype.updateButtonStyles = function () {
        var isLeftPositioned = this.toggleButtonPosition === 'left';
        var buttonStyleSettings;
        if (this.minimized) {
            this.toggleIconName = isLeftPositioned
                ? this.styleSettings.leftArrowIconName
                : this.styleSettings.rightArrowIconName;
            buttonStyleSettings = this.styleSettings.hiddenPanelButtons;
        }
        else {
            this.toggleIconName = isLeftPositioned
                ? this.styleSettings.rightArrowIconName
                : this.styleSettings.leftArrowIconName;
            buttonStyleSettings = isLeftPositioned
                ? this.styleSettings.leftPositionedSidePanelButtonStyles
                : this.styleSettings.rightPositionedSidePanelButtonStyles;
        }
        this.toggleButtonStyleSettings = buttonStyleSettings;
    };
    EntityDetailsPanelComponent.prototype.announceToggleSidePanel = function () {
        this.liveAnnouncer.announce(!!this.minimized
            ? this.i18nService.strings.dataview_entity_details_panel_button_show
            : this.i18nService.strings.dataview_entity_details_panel_button_hide, 'assertive', 300);
    };
    EntityDetailsPanelComponent.prototype.ngOnDestroy = function () {
        this._tagsSubject$.complete();
    };
    return EntityDetailsPanelComponent;
}());
export { EntityDetailsPanelComponent };
