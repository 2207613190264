import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { EvaluationReportSummary } from './evaluation-report-summary.value-object';
import { Alert } from '../alert/alert.entity';
import { Incident } from '../incident/incident.entity';

@ValueObject({
	singularName: 'Evaluation incidents report',
	pluralName: 'Evaluation incidents report',
})
export class EvaluationIncidents extends ModelBase {
	@EntityField({ data: 'Alerts', arrayOf: Alert })
	alerts: Array<Alert>;

	@EntityField({ data: 'Incidents', arrayOf: Incident })
	incidents: Array<Incident>;

	@EntityField({ data: 'MachineAlertCounts' })
	machineAlertCounts: { [index: string]: number };
}
