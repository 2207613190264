import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ReactPanelsService {
	public currentlyRenderedComponents = new Map<string, ServiceReactComponentConfig>();
	private currentlyRenderedComponentsArray: Array<ServiceReactComponentConfig> = [];
	private idCounter = 0;

	/**
	 * Renders react component with the app.scc.component as the parent component.
	 * @param componentConfig object of type ReactComponentConfig with the data needed to render the component
	 * @return Unique id of the component, can be used to close the component
	 **/
	public renderComponent(componentConfig: ReactComponentConfig): string {
		const componentId = `${componentConfig.componentName}_${this.idCounter++}`;
		const configuredComponent: ServiceReactComponentConfig = {
			...componentConfig,
			onComponentDestroy: this.onComponentDestroy.bind(this, componentId),
			id: componentId,
			isOpen: true,
		};
		this.currentlyRenderedComponents.set(componentId, configuredComponent);
		this.currentlyRenderedComponentsArray = Array.from(this.currentlyRenderedComponents.values());
		return componentId;
	}

	/**
	 * Removes the component from the DOM, Should be used to close the component in case we need to close it via the service.
	 * @param componentId: id of the component to be removed
	 */
	public removeComponent(componentId: string) {
		const component = this.getComponentById(componentId);
		if (component) {
			component.isOpen = false;
		}
		else {
			console.warn('ReactPanelService.removeComponent: Wrong id or Tried to remove component that has been removed already')
		}
	}

	get renderedComponentsArray() {
		return this.currentlyRenderedComponentsArray;
	}

	/**
	 * Get the component config object by id from  currently opened components array
	 * @param id
	 */
	private getComponentById(id) {
		return this.currentlyRenderedComponents.get(id);
	}

	/**
	 * Removes the component from `currentlyRenderedComponents` and calls component's provided onDismiss.
	 * Gets called by the angular placeholder when the wrapper emits onComponentDismiss event
	 * @param componentId: id of the component to be removed
	 */
	private onComponentDestroy(componentId: string) {
		const component = this.getComponentById(componentId);
		this.currentlyRenderedComponents.delete(componentId);
		this.currentlyRenderedComponentsArray = Array.from(this.currentlyRenderedComponents.values());
		component.props.onDismiss && component.props.onDismiss();
	}
}

interface ServiceReactComponentConfig extends ReactComponentConfig {
	onComponentDestroy: () => void;
	id: string;
	isOpen: boolean;
}

/**
 * componentName: the name of the react component. e.g `ReactComponent@wicd-ine/main` is the name of a component named ReactComponent that is exported from ine package.
 * props: the props to pass to the react component
 */
export interface ReactComponentConfig {
	componentName: string;
	props: any;
}
