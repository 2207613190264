var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { SendPartnerRecommendationApiCall } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { PanelContainer } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { RegExpService } from '@wcd/shared';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var PartnerRecommendationPanelComponent = /** @class */ (function (_super) {
    __extends(PartnerRecommendationPanelComponent, _super);
    function PartnerRecommendationPanelComponent(router, authService, changeDetectorRef, paris, i18nService, dialogsService, liveAnnouncer) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.authService = authService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.SpinnerSize = SpinnerSize;
        _this.RegExpService = RegExpService;
        _this.isSaving = false;
        _this.includeEmail = true;
        return _this;
    }
    PartnerRecommendationPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.setEmail();
    };
    Object.defineProperty(PartnerRecommendationPanelComponent.prototype, "isValid", {
        get: function () {
            var validName = !!this.partnerName && this.partnerName !== '';
            var validWeb = RegExpService.urlOrDomain.test(this.partnerWebsite);
            var validDescription = !!this.partnerDescription && this.partnerDescription !== '';
            var validEmail = !!!this.email || this.email === '' || RegExpService.email.test(this.email);
            return validName && validWeb && validDescription && validEmail;
        },
        enumerable: true,
        configurable: true
    });
    PartnerRecommendationPanelComponent.prototype.setEmail = function () {
        this.email = this.includeEmail ? this.authService.currentUser.name : null;
        this.changeDetectorRef.markForCheck();
    };
    PartnerRecommendationPanelComponent.prototype.getVsoDescription = function () {
        return "New partner recommendation. Partner name: " + this.partnerName + ", Partner Website: " + this.partnerWebsite + ", Description: " + this.partnerDescription;
    };
    PartnerRecommendationPanelComponent.prototype.submit = function () {
        var _this = this;
        this.isSaving = true;
        this.liveAnnouncer.announce(this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submitting'), 'assertive', 300);
        var data = {
            email: this.includeEmail && this.email,
            description: this.getVsoDescription(),
        };
        this.paris
            .apiCall(SendPartnerRecommendationApiCall, data)
            .pipe(finalize(function () {
            _this.isSaving = false;
        }))
            .subscribe(function (result) {
            _this.closePanel();
            _this.dialogsService.confirm({
                title: _this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.title'),
                text: _this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.text'),
                showConfirm: false,
                cancelText: _this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.close'),
            });
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.error.title'),
                text: _this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.error.text'),
                data: error,
            });
        });
        this.changeDetectorRef.detectChanges();
    };
    return PartnerRecommendationPanelComponent;
}(PanelContainer));
export { PartnerRecommendationPanelComponent };
