import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { MachineGroup } from '@wcd/domain';
import { FieldsService } from '../../global_entities/models/entity-type.interface';
import _ from 'lodash';

@Injectable()
export class MachineGroupsFieldsService implements FieldsService<MachineGroup> {
	private _fields: Array<DataviewField<MachineGroup>>;

	get fields(): Array<DataviewField<MachineGroup>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<MachineGroup>([
				{
					id: 'name',
					name: 'Name',
					minWidth: 350,
				},
			]);
		}

		return this._fields;
	}
}
