/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../entity-link.component.ngfactory";
import * as i2 from "../entity-link.component";
import * as i3 from "../../services/global-entity-types.service";
import * as i4 from "../../../insights/services/app-insights.service";
import * as i5 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i6 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "./network-endpoint.entity-details.component";
var styles_NetworkEndpointEntityDetailsComponent = [];
var RenderType_NetworkEndpointEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NetworkEndpointEntityDetailsComponent, data: {} });
export { RenderType_NetworkEndpointEntityDetailsComponent as RenderType_NetworkEndpointEntityDetailsComponent };
function View_NetworkEndpointEntityDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "entity-link", [], null, null, null, i1.View_EntityLinkComponent_0, i1.RenderType_EntityLinkComponent)), i0.ɵdid(3, 573440, null, 0, i2.EntityLinkComponent, [i0.Injector, i3.GlobalEntityTypesService, i4.AppInsightsService, i5.RoutesService, i6.FeaturesService], { linkText: [0, "linkText"], entityType: [1, "entityType"], entity: [2, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endpoint.ip.name; var currVal_1 = _co.ipType; var currVal_2 = _co.endpoint.ip; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NetworkEndpointEntityDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endpoint.ip.name; _ck(_v, 0, 0, currVal_0); }); }
function View_NetworkEndpointEntityDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_2)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["ipName", 2]], null, 0, null, View_NetworkEndpointEntityDetailsComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showEntityPanelLink; var currVal_2 = i0.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "networkEndpoints.fields.ip.title")); _ck(_v, 3, 0, currVal_0); }); }
function View_NetworkEndpointEntityDetailsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "entity-link", [], null, null, null, i1.View_EntityLinkComponent_0, i1.RenderType_EntityLinkComponent)), i0.ɵdid(3, 573440, null, 0, i2.EntityLinkComponent, [i0.Injector, i3.GlobalEntityTypesService, i4.AppInsightsService, i5.RoutesService, i6.FeaturesService], { linkText: [0, "linkText"], entityType: [1, "entityType"], entity: [2, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endpoint.url.name; var currVal_1 = _co.urlType; var currVal_2 = _co.endpoint.url; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_NetworkEndpointEntityDetailsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.endpoint.url.name; _ck(_v, 0, 0, currVal_0); }); }
function View_NetworkEndpointEntityDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_5)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["urlName", 2]], null, 0, null, View_NetworkEndpointEntityDetailsComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showEntityPanelLink; var currVal_2 = i0.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "networkEndpoints.fields.url.title")); _ck(_v, 3, 0, currVal_0); }); }
function View_NetworkEndpointEntityDetailsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "networkEndpoints.fields.port.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.endpoint.port; _ck(_v, 7, 0, currVal_1); }); }
function View_NetworkEndpointEntityDetailsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "networkEndpoints.fields.protocol.title")); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.endpoint.protocol; _ck(_v, 7, 0, currVal_1); }); }
export function View_NetworkEndpointEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 13, "dl", [["role", "none"]], [[2, "key-values", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_1)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_4)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_7)), i0.ɵdid(10, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityDetailsComponent_8)), i0.ɵdid(13, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.endpoint.ip; _ck(_v, 4, 0, currVal_1); var currVal_2 = _co.endpoint.url; _ck(_v, 7, 0, currVal_2); var currVal_3 = _co.endpoint.port; _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.endpoint.protocol; _ck(_v, 13, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asKeyValueList; _ck(_v, 1, 0, currVal_0); }); }
export function View_NetworkEndpointEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "network-endpoint-entity-details", [], null, null, null, View_NetworkEndpointEntityDetailsComponent_0, RenderType_NetworkEndpointEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i10.NetworkEndpointEntityDetailsComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NetworkEndpointEntityDetailsComponentNgFactory = i0.ɵccf("network-endpoint-entity-details", i10.NetworkEndpointEntityDetailsComponent, View_NetworkEndpointEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { NetworkEndpointEntityDetailsComponentNgFactory as NetworkEndpointEntityDetailsComponentNgFactory };
