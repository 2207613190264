/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "./value-with-external-link.component";
var styles_ValueWithExternalLinkComponent = [];
var RenderType_ValueWithExternalLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ValueWithExternalLinkComponent, data: {} });
export { RenderType_ValueWithExternalLinkComponent as RenderType_ValueWithExternalLinkComponent };
function View_ValueWithExternalLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "a", [["class", "wcd-margin-xsmall-left"], ["data-track-id", "OpenLink"], ["data-track-type", "Navigation"], ["target", "_blank"]], [[8, "href", 4], [1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "wcd-shared-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(8, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"], verticalAlign: [1, "verticalAlign"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.context.icon; var currVal_4 = _co.context.verticalAlign; _ck(_v, 8, 0, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context.value; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.context.link; var currVal_2 = _co.context.title; _ck(_v, 5, 0, currVal_1, currVal_2); }); }
export function View_ValueWithExternalLinkComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ValueWithExternalLinkComponent_1)), i0.ɵdid(2, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.context; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ValueWithExternalLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "value-with-external-link", [], null, null, null, View_ValueWithExternalLinkComponent_0, RenderType_ValueWithExternalLinkComponent)), i0.ɵdid(1, 49152, null, 0, i5.ValueWithExternalLinkComponent, [], null, null)], null, null); }
var ValueWithExternalLinkComponentNgFactory = i0.ɵccf("value-with-external-link", i5.ValueWithExternalLinkComponent, View_ValueWithExternalLinkComponent_Host_0, { context: "context" }, {}, []);
export { ValueWithExternalLinkComponentNgFactory as ValueWithExternalLinkComponentNgFactory };
