import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ServiceUrlsService } from '@wcd/app-config';
import { Observable, of } from 'rxjs';
import { catchError, map, take, switchMap } from 'rxjs/operators';
import {
	File,
	FileDownloadLink,
	FileFileDownloadLinkRelationship,
	FileFileProtectionInfoRelationship,
	FileId,
	FileProtectionInfo,
	GetSampleStateApiCall,
	GenerateDownloadUriApiCall,
	SampleDownloadRequest,
	SampleDownloadResponse,
	SampleRequestResponse,
	CollectSampleRequest,
	CollectSampleApiCall,
	SampleActionStateResponse,
	SampleActionStateRequest,
} from '@wcd/domain';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { CsvService } from '../../../shared/services/csv.service';
import { DownloadService } from '../../../shared/services/download.service';
import { RegExpService } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

const EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
const EXPORT_TO_CSV_URL = 'DownloadFileSearchResults';
const EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL = 'DownloadFileObservedMachinesCsv';

/// Quarantine
const EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_TOKEN_URL = 'remediation/authtoken';
const EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_URL = 'remediation/downloadRemediationStatusCsv';

export interface DownloadFilesSearchCsvParams {
	readonly searchTerm: string;
	readonly lookingBackInDays: string;
}

@Injectable()
export class FilesBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService,
		private readonly csvService: CsvService,
		private appInsightsService: AppInsightsService,
		private paris: Paris
	) {
		super(http, downloadService);
	}

	protected get apiUrl(): string {
		return this.serviceUrlsService.filedownloads;
	}

	downloadCsv(params: DownloadFilesSearchCsvParams): Promise<void> {
		return this.csvService.downloadCsv({
			tokenUrl: `${this.apiUrl}/${EXPORT_TO_CSV_TOKEN_URL}?exportUrl=${EXPORT_TO_CSV_URL}`,
			apiUrl: `${this.apiUrl}/${EXPORT_TO_CSV_URL}`,
			params: {
				searchTerm: params.searchTerm,
				lookingBackInDays: params.lookingBackInDays,
			},
		});
	}

	downloadFileObservedMachinesCsv(sha1: string) {
		return this.csvService.downloadCsv({
			tokenUrl: `${this.apiUrl}/${EXPORT_TO_CSV_TOKEN_URL}?exportUrl=${EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL}`,
			apiUrl: `${this.apiUrl}/${EXPORT_FILE_OBSERVED_MACHINES_TO_CSV_URL}`,
			params: { sha1: sha1 },
		});
	}

	getFileProtectionInfo(fileId: FileId): Promise<FileProtectionInfo> {
		if (!RegExpService.sha1.test(fileId) && !RegExpService.sha256.test(fileId)) {
			return Promise.reject(`'${fileId}' is not valid SHA1 or SHA256.`);
		}

		// initialize a file object with the relevant fields in order to use paris
		const file = {
			id: fileId,
			name: fileId,
			sha1: null,
			sha256: null,
			md5: null,
			firstSeen: new Date(),
			lastSeen: new Date(),
			appType: null,
			fileId,
		};

		return this.paris
			.getRelatedItem<File, FileProtectionInfo>(FileFileProtectionInfoRelationship, file)
			.pipe(take(1))
			.toPromise();
	}

	private generateFileDownloadLink(fileId: FileId): Observable<FileDownloadLink> {
		const file = {
			id: fileId,
			name: fileId,
			sha1: null,
			sha256: null,
			md5: null,
			firstSeen: new Date(),
			lastSeen: new Date(),
			appType: null,
			fileId,
		};

		return this.paris.getRelatedItem<File, FileDownloadLink>(FileFileDownloadLinkRelationship, file);
	}

	private generateFileDownloadLinkNew(
		sha: string,
		filename: string,
		requestorComment: string,
		password: string
	): Observable<string> {
		const args: SampleDownloadRequest = {
			sha: sha,
			filename: filename,
			requestorComment: requestorComment,
			zipPassword: password,
		};
		return this.paris
			.apiCall<SampleDownloadResponse, SampleDownloadRequest>(GenerateDownloadUriApiCall, args)
			.pipe(map(downloadResponse => downloadResponse.sasUri));
	}

	canDownloadFile(fileId: FileId): Observable<boolean> {
		return this.generateFileDownloadLink(fileId).pipe(
			map(link => !!(link && link.downloadUrl)),
			catchError((error: any) => {
				if (!error.status || (error.status !== 404 && error.status !== 401)) {
					this.appInsightsService.appInsights.trackException(error);
				}
				return of(false);
			})
		);
	}

	getSampleState(sha: string, checkPrevalence: boolean): Observable<SampleActionStateResponse> {
		return this.paris.apiCall<SampleActionStateResponse, SampleActionStateRequest>(
			GetSampleStateApiCall,
			{ sha, checkPrevalence }
		);
	}

	downloadFile(fileId: FileId): Promise<void> {
		const downloadUrl$ = this.generateFileDownloadLink(fileId).pipe(
			map(link => link && link.downloadUrl)
		);
		return this.downloadService.downloadFromUrl(downloadUrl$, { isAuthenticated: true });
	}

	exportQuarantineActionsResults(sha1: string) {
		this.csvService.downloadCsv({
			tokenUrl: `${
				this.serviceUrlsService.userRequests
			}/${EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_TOKEN_URL}`,
			apiUrl: `${this.serviceUrlsService.userRequests}/${EXPORT_FILE_QUARANTINE_RESULT_TO_CSV_URL}`,
			params: { FileIdentifier: sha1, FileIdentifierType: 'Sha1' },
		});
	}

	downloadFileNew(
		sha: string,
		filename: string,
		requestorComment: string,
		password: string
	): Observable<void> {
		return this.generateFileDownloadLinkNew(sha, filename, requestorComment, password).pipe(
			switchMap(url => this.downloadService.downloadFromUrl(url, { isAuthenticated: true }))
		);
	}

	collectSample(sha: string, reason: string): Observable<SampleRequestResponse> {
		return this.paris.apiCall<SampleRequestResponse, CollectSampleRequest>(CollectSampleApiCall, {
			sha: sha,
			requestorComment: reason,
		});
	}
}
