import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { Paris, RelationshipRepository } from "@microsoft/paris";
import { BaselineProfileConfiguration, BaselineProfileDeviceIds, BaselineProfileDeviceIdsBaselineConfigurationsRelationship, ResolveEntityURL, TvmEndPoint } from "@wcd/domain";
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { ProfileDeviceFieldsService } from "../services/profile-device.fields.service";
import { TvmDownloadService } from "../../../../../../tvm/services/tvm-download.service";

@Component({
	selector: 'profile-device-configurations-dataview',
	providers: [ProfileDeviceFieldsService],
	templateUrl: './profile-device-configurations.dataview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileDeviceConfigurationsDataviewComponent implements OnInit {
	fields: Array<DataviewField<BaselineProfileConfiguration>>;
	dataViewConfig: DataViewConfig;
	configurationsRepo: RelationshipRepository<BaselineProfileDeviceIds, BaselineProfileConfiguration>;

	@Input()
	profileName: string;

	@Input()
	deviceName: string;

	@Input()
	sourceValue: BaselineProfileDeviceIds;

	constructor(
		private fieldsService: ProfileDeviceFieldsService,
		private tvmDownloadService: TvmDownloadService,
		protected paris: Paris) {
	}

	ngOnInit(): void {
		this.fields = this.fieldsService.configurationsFields;
		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			id: `baselineDeviceConfigurations(${allFieldsIds.join()})`, //must supply unique id for correct dataview caching purposes - unique id is based on the fields
			showModalOnExport: false,
			exportResults: (_, __, dataQuery) => this.exportResults(dataQuery),
		};
		this.configurationsRepo = this.paris.getRelationshipRepository(BaselineProfileDeviceIdsBaselineConfigurationsRelationship);
		this.configurationsRepo.sourceItem = this.sourceValue;
	}

	exportResults(dataQuery) {
		const url = ResolveEntityURL({
			endPoint: TvmEndPoint.Analytics,
			entityModelBaseOrRelationship: BaselineProfileDeviceIdsBaselineConfigurationsRelationship,
			sourceModel: this.sourceValue,
		});

		const currentDate = new Date();
		const timestamp = currentDate.getTime();
		const profileName = this.profileName.split(' ').join('_');
		const deviceName = this.deviceName.split(' ').join('_');
		return this.tvmDownloadService.downloadCsv({
			url: url,
			fileName: `Export_${profileName}_${deviceName}_configurations_${timestamp}`,
			dataQuery: dataQuery,
		});
	}
}
