
		<div class="wcd-full-height wcd-flex-vertical search-page">
			<div class="wcd-flex-0">
				<div class="queue-layout-header">
					<h2>{{ 'search.page.title' | i18n }}</h2>
				</div>
				<main-search [isScc]="isScc"></main-search>
			</div>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	