import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { File, MatchType } from '@wcd/domain';
import { FilterValuesChecklistData } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class FilesFiltersService {
	constructor(private i18nService: I18nService) {}
	private readonly defaultFilters: Record<string, any> &
		Partial<Record<keyof File, FilterValuesChecklistData<string>>> = {
		matchType: {
			count: null,
			values: [
				{
					count: null,
					name: this.i18nService.get('search_exactMatch'),
					value: MatchType.Exact,
				},
				{
					count: null,
					name: this.i18nService.get('search_partialMatch'),
					value: MatchType.ContainsOrEndsWith,
				},
			],
		},
	};

	getFilesFilters(): Observable<Record<string, any>> {
		return of(this.defaultFilters);
	}
}
