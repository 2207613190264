var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { I18nService } from '@wcd/i18n';
import { EvaluationLab } from '@wcd/domain';
import { EvaluationService } from '../services/evaluation.service';
var EvaluationPasswordDialogComponent = /** @class */ (function (_super) {
    __extends(EvaluationPasswordDialogComponent, _super);
    function EvaluationPasswordDialogComponent(router, i18nService, evaluationService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.evaluationService = evaluationService;
        return _this;
    }
    EvaluationPasswordDialogComponent.prototype.getDescription = function () {
        return this.provisionedMachines + 1 === this.lab.maxNumberOfMachines
            ? this.i18nService.get("evaluation.dashboard.dataView.commandBar.addMachine.descriptionLastMachine")
            : this.i18nService.get("evaluation.dashboard.dataView.commandBar.addMachine.description", {
                provisionedMachinesCount: this.provisionedMachines,
                maxMachinesCount: this.lab.maxNumberOfMachines,
            });
    };
    return EvaluationPasswordDialogComponent;
}(ModalContainer));
export { EvaluationPasswordDialogComponent };
