
		<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<suppression-rule-edit
				class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
				[rule]="rule"
				[alert]="alert"
				[refreshOn$]="refreshOn$"
				[changingRuleStatus$]="changingRuleStatus$"
				[isEditRuleMode]="isEditRuleMode"
				(save)="onDone($event)"
				(changeStatus)="onChangeStatus($event)"
				(closePanel)="onClosePanel()"
			>
			</suppression-rule-edit>
		</wcd-panel>
	