/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./async.component";
var styles_AsyncComponent = [];
var RenderType_AsyncComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsyncComponent, data: {} });
export { RenderType_AsyncComponent as RenderType_AsyncComponent };
export function View_AsyncComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(0, null, ["\n\t\t", "\n\t"])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, i0.ɵnov(_v, 1).transform(_co.value$)); _ck(_v, 0, 0, currVal_0); }); }
export function View_AsyncComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-async", [], null, null, null, View_AsyncComponent_0, RenderType_AsyncComponent)), i0.ɵdid(1, 49152, null, 0, i2.AsyncComponent, [], null, null)], null, null); }
var AsyncComponentNgFactory = i0.ɵccf("wcd-async", i2.AsyncComponent, View_AsyncComponent_Host_0, { value$: "value$" }, {}, []);
export { AsyncComponentNgFactory as AsyncComponentNgFactory };
