import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { AlertIncidentGraphItemEntity } from './incident-graph-item-entity';
/**
 * old alert incident graph element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Incident Graph Element',
	pluralName: 'Alert Incident Graph Elements',
})
export class AlertIncidentGraphElement extends ModelBase {
	@EntityField({ data: 'IsAssociatedWithAlerts', defaultValue: false })
	isAssociatedWithAlert: boolean;

	@EntityField({
		require: 'SubElements',
		arrayOf: AlertIncidentGraphElement,
		data: 'SubElements',
	})
	childElements: Array<AlertIncidentGraphElement>;

	@EntityField({
		require: 'PrimaryEntityId',
		data: '__self',
		parse: data => {
			return {
				id: data.PrimaryEntityId,
				name: data.PrimaryEntityDisplayName,
				type: data.PrimaryEntityType,
			};
		},
	})
	primaryEntity: AlertIncidentGraphItemEntity;

	@EntityField({
		require: 'SatalliteEntityId',
		data: '__self',
		parse: data => {
			return {
				id: data.SatalliteEntityId,
				name: data.SatalliteEntityDisplayName,
				type: data.SatalliteEntityType,
			};
		},
	})
	satelliteEntity: AlertIncidentGraphItemEntity;
}
