/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./help.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "./tooltip.directive";
import * as i5 from "./tooltips.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./help.component";
var styles_HelpComponent = [i0.styles];
var RenderType_HelpComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_HelpComponent, data: {} });
export { RenderType_HelpComponent as RenderType_HelpComponent };
function View_HelpComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["style", "display: none"]], [[1, "id", 0], [8, "innerHTML", 1]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.helpUniqueId; var currVal_1 = _co.htmlTooltip; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_HelpComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HelpComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "fab-icon", [["aria-roledescription", "tooltip"], ["iconName", "Info"], ["role", "button"]], [[1, "tabindex", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0]], [[null, "focus"], [null, "blur"], [null, "keyup.enter"], [null, "keydown.enter"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("focus" === en)) {
        var pd_4 = (_co.onFabFocus($event) !== false);
        ad = (pd_4 && ad);
    } if (("blur" === en)) {
        var pd_5 = (_co.onFabBlur($event) !== false);
        ad = (pd_5 && ad);
    } if (("keyup.enter" === en)) {
        var pd_6 = (_co.preventDefault($event) !== false);
        ad = (pd_6 && ad);
    } if (("keydown.enter" === en)) {
        var pd_7 = (_co.preventDefault($event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i1.ɵdid(1, 5816320, null, 0, i3.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"], ariaLabel: [2, "ariaLabel"] }, null), i1.ɵdid(2, 147456, null, 0, i4.TooltipDirective, [i1.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipEnableKeyboardEvents: [1, "wcdTooltipEnableKeyboardEvents"], wcdTooltipSanitizeHtml: [2, "wcdTooltipSanitizeHtml"], wcdTooltipAllowHtmlRendering: [3, "wcdTooltipAllowHtmlRendering"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = ("wcd-help-icon__i" + (_co.isLight ? " wcd-help-icon__i--light" : (_co.isLarge ? " wcd-help-icon__i--large" : ""))); var currVal_4 = "Info"; var currVal_5 = (_co.wcdTooltipAllowHtmlRendering ? "tooltip" : null); _ck(_v, 1, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = _co.helpHtml; var currVal_7 = _co.wcdTooltipEnableKeyboardEvents; var currVal_8 = _co.wcdTooltipSanitizeHtml; var currVal_9 = _co.wcdTooltipAllowHtmlRendering; _ck(_v, 2, 0, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndexValue; var currVal_1 = (_co.wcdTooltipAllowHtmlRendering ? null : _co.toolTipAriaLabel); var currVal_2 = (_co.wcdTooltipAllowHtmlRendering ? _co.helpUniqueId : null); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HelpComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 7, "span", [["class", "wcd-help-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_3)), i1.ɵdid(5, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_4)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v.parent, 9); _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.showIcon; _ck(_v, 8, 0, currVal_1); }, null); }
function View_HelpComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HelpComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "span", [["aria-roledescription", "tooltip"], ["class", "wcd-help-icon"], ["role", "button"]], [[1, "tabindex", 0], [1, "aria-label", 0], [1, "aria-labelledby", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i4.TooltipDirective, [i1.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipSanitizeHtml: [1, "wcdTooltipSanitizeHtml"], wcdTooltipAllowHtmlRendering: [2, "wcdTooltipAllowHtmlRendering"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_6)), i1.ɵdid(5, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.helpHtml; var currVal_4 = _co.wcdTooltipSanitizeHtml; var currVal_5 = _co.wcdTooltipAllowHtmlRendering; _ck(_v, 2, 0, currVal_3, currVal_4, currVal_5); var currVal_6 = i1.ɵnov(_v.parent, 9); _ck(_v, 5, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabIndexValue; var currVal_1 = (_co.wcdTooltipAllowHtmlRendering ? null : _co.toolTipAriaLabel); var currVal_2 = (_co.wcdTooltipAllowHtmlRendering ? _co.helpUniqueId : null); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_HelpComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_HelpComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HelpComponent_2)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["noIcon", 2]], null, 0, null, View_HelpComponent_5)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵand(0, [["content", 2]], null, 0, null, View_HelpComponent_7)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wcdTooltipAllowHtmlRendering; _ck(_v, 2, 0, currVal_0); var currVal_1 = !!_co.showIcon; var currVal_2 = i1.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_HelpComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-help", [], null, null, null, View_HelpComponent_0, RenderType_HelpComponent)), i1.ɵdid(1, 638976, null, 0, i8.HelpComponent, [i6.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HelpComponentNgFactory = i1.ɵccf("wcd-help", i8.HelpComponent, View_HelpComponent_Host_0, { showIcon: "showIcon", title: "title", text: "text", isLight: "isLight", tabIndexValue: "tabIndexValue", isLarge: "isLarge", ariaLabel: "ariaLabel", wcdTooltipAllowHtmlRendering: "wcdTooltipAllowHtmlRendering", wcdTooltipSanitizeHtml: "wcdTooltipSanitizeHtml", wcdTooltipEnableKeyboardEvents: "wcdTooltipEnableKeyboardEvents" }, { onFocus: "onFocus", onBlur: "onBlur" }, ["*"]);
export { HelpComponentNgFactory as HelpComponentNgFactory };
