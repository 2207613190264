import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import {
	RecommendationContextPropertiesRelationship,
	RecommendationContextKeyAndExposedMachinesCount,
	SecurityRecommendation,
	RecommendationContextType,
	TvmEndPoint,
	RecommendationContextProperties,
} from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris, RelationshipRepository } from '@microsoft/paris';

@Component({
	selector: 'recommendation-context-properties-panel-component',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-context-properties.panel.component.html',
})
export class RecommendationContextPropertiesPanelComponent extends PanelContainer {
	title: string;
	isExportEnabled: Boolean;
	repository: RelationshipRepository<
		RecommendationContextKeyAndExposedMachinesCount,
		RecommendationContextProperties
	>;

	@Input() recommendationContextKey: any;
	@Input() recommendationContextType: RecommendationContextType;
	@Input() securityRecommendation: SecurityRecommendation;

	constructor(
		private paris: Paris,
		private tvmTextsService: TvmTextsService,
		private tvmDownloadService: TvmDownloadService,
		featuresService: FeaturesService,
		router: Router
	) {
		super(router);
		this.repository = this.paris.getRelationshipRepository(RecommendationContextPropertiesRelationship);
		this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
	}

	ngOnInit() {
		this.title = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationTitle,
			this.securityRecommendation
		);
		this.repository.sourceItem = new RecommendationContextKeyAndExposedMachinesCount({
			scId: this.securityRecommendation.scId,
			contextKey: this.recommendationContextKey,
		});
	}

	exportCveData() {
		this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.repository,
			TvmEndPoint.Analytics,
			`${this.title} - ${this.recommendationContextKey}`
		);
	}
}
