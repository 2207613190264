import { LiveAnnouncer } from '@angular/cdk/a11y';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewEncapsulation,
} from '@angular/core';

import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'wcd-simple-pagination',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./simple-pagination.component.scss'],
	template: `
		<div *ngIf="shouldShowRange()" [ngClass]="'wcd-simple-pagination ' +  className" role="navigation"
			[attr.aria-label]="i18nService.strings.simplePagination_navigation">
			<span
				*ngIf="(showCount && !shouldShowPageNumbers); else pageNumbers"
				class="wcd-simple-pagination__position"
			>
				{{ firstIndex }}-{{ lastIndex }}
				<span *ngIf="total">
					<ng-container i18n>{{ i18nService.strings.simplePagination_of }} </ng-container>
					<span data-test-id="paging-total">{{ total }}</span>
				</span>
			</span>
			<ng-template #pageNumbers>
				<span class="wcd-simple-pagination__position">
					<ng-container i18n>{{ i18nService.strings.page }} </ng-container>
					{{ page }}
				</span>
			</ng-template>

			<button
				*ngIf="!(pageCount && pageCount <= 1)"
				[attr.aria-label]="calculatePagesNavigationAriaLabel('simplePagination_previous_page', ariaPagePrevIndex)"
				class="wcd-simple-pagination wcd-margin-xxsmall-right"
				[disabled]="page === 1"
				(click)="prev()"
				[wcdTooltip]="i18nService.strings.simplePagination_previous_page"
				data-track-id="SimplePagination__Previous"
				data-track-type="Button"
			>
				<fab-icon [iconName]="'ChevronLeft'" contentClass="wcd-simple-pagination-icon"></fab-icon>
			</button>

			<button
				*ngIf="!(pageCount && pageCount <= 1)"
				[attr.aria-label]="calculatePagesNavigationAriaLabel('simplePagination_next_page', ariaPageNextIndex)"
				class="wcd-simple-pagination"
				[disabled]="isLastPage"
				(click)="next()"
				[wcdTooltip]="i18nService.strings.simplePagination_next_page"
				data-track-id="SimplePagination__Next"
				data-track-type="Button"
			>
				<fab-icon [iconName]="'ChevronRight'" contentClass="wcd-simple-pagination-icon"></fab-icon>
			</button>
		</div>
	`,
})
export class SimplePaginationComponent implements OnChanges {
	@Input() page: number;
	@Input() className: number;
	@Input() pageSize: number;
	@Input() total: number;
	@Input() currentPageCount: number;
	@Input() shouldShowPageNumbers?: boolean = false;
	@Input() showOnSinglePage?: boolean = false;

	@Output() readonly pageChange = new EventEmitter<{ page: number }>();

	get showCount(): boolean {
		return !isNaN(this.total);
	}

	pageCount: number;
	isLastPage: boolean;
	firstIndex: number;
	lastIndex: number;
	readonly ariaPagePrevIndex= -1;
	readonly ariaPageNextIndex= 1;

	constructor(private readonly changeDetectorRef: ChangeDetectorRef, public i18nService: I18nService,private liveAnnouncer: LiveAnnouncer) {}

	ngOnChanges() {
		this.update();
		this.announcePageUpdated(`${this.i18nService.get('simplePagination_item_range_page')} ${this.firstIndex} - ${this.lastIndex} ${this.total ? this.i18nService.get('simplePagination_of') : ''} ${this.total ? this.total : ''}`);
	}

	next() {
		if (this.page === this.pageCount) {
			return;
		}
		this.page = this.page + 1;
		this.notifyPageChange();
		this.update();
	}

	prev() {
		this.page = this.page - 1;
		this.notifyPageChange();
		this.update();
	}

	notifyPageChange() {
		this.pageChange.emit({ page: this.page });
		this.announcePageUpdated(this.i18nService.get('simplePagination_loading_page_items'));
	}

	update() {
		this.page = this.page || 1;
		this.pageCount = this.total ? Math.ceil(this.total / this.pageSize) : null;
		this.isLastPage = this.pageCount
			? this.page === this.pageCount
			: this.currentPageCount < this.pageSize;
		this.firstIndex = (this.page - 1) * this.pageSize + 1;
		this.lastIndex = this.firstIndex + this.currentPageCount - 1;
		this.changeDetectorRef.markForCheck();
	}

	calculatePagesNavigationAriaLabel(buttonNameKey: string, buttonIndex: number) {
		const translatedButtonName = this.i18nService.get(buttonNameKey);
		const page = this.page + buttonIndex;
		const firstIndex = (page - 1) * this.pageSize + 1;
		const isNextPageLast = this.pageCount &&  page === this.pageCount;
		const lastIndex = isNextPageLast ?  this.total : firstIndex + this.currentPageCount - 1;
		if (firstIndex < 0 || lastIndex > this.total) {
			return translatedButtonName;
		}
		return `${translatedButtonName} ${firstIndex} - ${lastIndex} ${this.i18nService.strings.simplePagination_of} ${this.total}`
	}

	shouldShowRange() {
		if (isNaN(this.lastIndex) || !this.lastIndex) {
			return false;
		}

		return this.showOnSinglePage || this.pageCount !== 1;
	}

	private announcePageUpdated(announcement : string){
		this.liveAnnouncer.announce(
			announcement,
			'assertive',
			300
		);
	}
}
