import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { File } from '../file.entity';
import { EntityStatistics } from '../../entity/entity-statistics.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: EntityStatistics,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: config => config.data.serviceUrls.fileprevalence,
	endpoint: (_, query: DataQuery) => `files/${query.where['id']}/prevalence`,
	getRelationshipData: (file: File) => ({ id: file.sha1 || file.sha256 || file.id }),
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
	timeout: 2 * 60 * 1000, // This request takes a long (l-o-n-g) time on average!
})
export class FilePrevalenceRelationship implements EntityRelationshipRepositoryType<File, EntityStatistics> {}
