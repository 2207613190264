import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { NetworkEndpoint } from '@wcd/domain';

@Component({
	selector: 'network-endpoint-entity-panel',
	templateUrl: './network-endpoint.entity-panel.component.html',
})
export class NetworkEndpointEntityPanelComponent extends EntityPanelComponentBase<NetworkEndpoint>
	implements OnDestroy, OnInit {
	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	get networkEndpoint(): NetworkEndpoint {
		return this.entity;
	}
}
