<!-- tslint:disable:template-accessibility-label-for -->
<wcd-panel (close)="destroy()" [settings]="settings">
	<div class="wcd-full-height wcd-flex-vertical">
		<div class="wcd-flex-1 wcd-scroll-vertical">
			<div class="wcd-padding-large-bottom">
				<label for="incidentName-{{id}}" class="wcd-font-size-m wcd-font-weight-semibold">{{
					'incident.commandBar.actions.manageIncident.name' | i18n
					}}</label>
				<input id="incidentName-{{id}}" [(ngModel)]="incidentName" type="text" class="form-control wcd-full-width"
					[disabled]="isReadOnly"  [textMask]="'[0-9A-Za-z0-9 _[)(\\]\\-]'" [attr.aria-describedby]="incidentName !== incident?.name && ('incidentName-' + id + '-info')"/>
				<fab-message-bar [messageBarType]="MessageBarType.info"
					*ngIf="incidentName !== incident?.name"
					contentClass="wcd-margin-small-bottom wcd-margin-largeMedium-top">
						<div [attr.id]="'incidentName-' + id + '-info'" class="wcd-flex-1">{{ 'incident.manageIncident.nameChanged' | i18n }}</div>
				</fab-message-bar>
			</div>
			<div class="wcd-padding-large-bottom">
				<label class="wcd-font-size-m wcd-font-weight-semibold">{{
					'incident.commandBar.actions.manageIncident.tags' | i18n
					}}</label>
				<fab-tag-picker
					[defaultSelectedItems]="tags"
					[resolveSuggestions]="onFilterChanged"
					(onChange)="onTagsChange($event)"
					[pickerSuggestionsOptions]="tagsPickerSuggestionsProps"
					[renderSuggestionsItem]="suggestedItem"
					[inputProps]="tagPickerInputProps"
					[disabled]="isReadOnly"
					[removeButtonAriaLabel]="'remove' | i18n"
				>
				</fab-tag-picker>
				<ng-template #suggestedItem let-name="name" let-isnew="isNewItem">
					<div class="ms-Button-flexContainer wcd-flex-content-start-vertical">
						<div class="ms-TagItem-TextOverflow wcd-suggestion-textoverflow wcd-padding-mediumSmall-horizontal">
							{{name}}<span *ngIf="isnew"> {{'incident.commandBar.actions.manageIncident.createNew' | i18n}}</span>
						</div>
					</div>
				</ng-template>
			</div>
			<div class="wcd-padding-large-bottom">
				<ng-container *ngIf="isAssignToSomeoneElseEnabled else assignToMe">
					<label class="wcd-font-size-m wcd-font-weight-semibold">{{'incident.commandBar.manageIncident.assignTo' | i18n}}
						<wcd-shared-icon
							iconName="Info"
							[wcdTooltip]="'User selected in this list might not have the permissions to view/manage the incident'">
						</wcd-shared-icon>
					</label>
					<assignee-picker (onSelectAssignee)="onSelectAssignee($event)" [assignedTo]="assignedTo"></assignee-picker>
				</ng-container>
				<ng-template #assignToMe>
					<wcd-toggle
						[id]="'assignedToMe-' + id"
						[ngModel]="assignedToMe"
						(ngModelChange)="onAssignToMeChanges($event)"
						[trueLabel]="'incident.commandBar.actions.manageIncident.assignToMe' | i18n"
						[falseLabel]="'incident.commandBar.actions.manageIncident.assignToMe' | i18n"
						[isDisabled]="isReadOnly"
					></wcd-toggle>
					<div class="wcd-padding-top  ms-fontColor-gray90" *ngIf="!assignedToMail">
						{{  'assigned.unassigned' | i18n }}
					</div>
					<div class="wcd-padding-top" *ngIf="assignedToMail">
						{{ 'incident.commandBar.actions.manageIncident.assignedTo' | i18n: {name: assignedToMail} }}
					</div>
				</ng-template>
			</div>
			<ng-container *ngIf="isInProgressIncidentStatusEnabled; else oldStatusComponent">
				<div class="wcd-padding-large-bottom">
					<label
						class="wcd-font-size-m wcd-font-weight-semibold"
					>{{ 'incident.commandBar.actions.manageIncident.status' | i18n }}
					</label>
					<fab-dropdown
									[selectedKey]="selectedStatus"
									[options]="incidentStatusOptions"
									(onChange)="incidentStatusChange($event)">
					</fab-dropdown>
				</div>
			</ng-container>
			<ng-template #oldStatusComponent>
				<div class="wcd-padding-large-bottom">
					<wcd-toggle
						[id]="'resolveIncident-'+ id"
						[ngModel]="resolveIncident"
						(ngModelChange)="resolveIncidentChanges($event)"
						[trueLabel]="'incident.commandBar.actions.manageIncident.resolve' | i18n"
						[falseLabel]="'incident.commandBar.actions.manageIncident.resolve' | i18n"
						[isDisabled]="isReadOnly"
					></wcd-toggle>
					<div class="wcd-padding-top">
						{{ 'incident.commandBar.actions.manageIncident.resolveComment' | i18n }}
					</div>
				</div>
			</ng-template>
			<ng-container *ngIf="isCombinedClassificationEnabled; else oldClassification">
				<div class="wcd-padding-large-bottom">
					<label
						class="wcd-font-size-m wcd-font-weight-semibold"
						for="incidentClassification-{{id}}"
					>{{ 'incident.commandBar.actions.manageIncident.classification' | i18n }}</label
					>
						<fab-dropdown dropdownWidth="5"
									  [selectedKey]="selectedCombinedClassification"
									  [options]="combinedClassificationMap"
									  (onChange)="onNewClassificationDropdownChange($event)">
						</fab-dropdown>
					<fab-message-bar
						[messageBarType]="MessageBarType.info"
						contentClass=" wcd-margin-small-bottom wcd-margin-largeMedium-top"
						*ngIf="incidentClassification?.id !== NOT_SET">
						<div
							class="wcd-flex-1">{{ 'incident.commandBar.actions.manageIncident.classificationInfo' | i18n }}</div>
					</fab-message-bar>
				</div>
			</ng-container>
			<ng-template #oldClassification>
				<div class="wcd-padding-large-bottom">
					<label
						class="wcd-font-size-m wcd-font-weight-semibold"
						for="incidentClassification-{{id}}"
					>{{ 'incident.commandBar.actions.manageIncident.classification' | i18n }}</label
					>
					<fancy-select
						[ngModel]="incidentClassification"
						(ngModelChange)="incidentClassificationChanges($event)"
						listId="incidentClassification-{{id}}"
						name="incidentClassification-{{id}}"
						[isBordered]="true"
						labelClass="dropdown-width-medium wcd-full-width"
						labelI18nKey="nameI18nKey"
						placeholder="{{'incident.commandBar.actions.manageIncident.select' | i18n}}"
						[trackBy]="'id'"
						[values]="selectableAlertClassifications"
						[isFullWidth]="true"
						[isDisabled]="isReadOnly"
						[ariaLabel]="'incident.commandBar.actions.manageIncident.classification' | i18n"
						[dropdownAreaRole]="'combobox'"
					>
					</fancy-select>
					<fab-message-bar
						[messageBarType]="MessageBarType.info"
						contentClass=" wcd-margin-small-bottom wcd-margin-largeMedium-top"
						*ngIf="incidentClassification?.id !== NOT_SET">
						<div
							class="wcd-flex-1">{{ 'incident.commandBar.actions.manageIncident.classificationInfo' | i18n }}</div>
					</fab-message-bar>
				</div>
			<div class="wcd-padding-large-bottom" *ngIf="incidentClassification?.allowDetermination">
				<label class="wcd-font-size-m wcd-font-weight-semibold" for="determination-{{id}}">{{
					'incident.commandBar.actions.manageIncident.determination' | i18n
					}}</label>
				<fancy-select
					[ngModel]="determination"
					(ngModelChange)="determinationChanges($event)"
					listId="determination-{{id}}"
					name="determination-{{id}}"
					[isBordered]="true"
					labelClass="wcd-dropdown__bordered wcd-full-width"
					placeholder="{{'incident.commandBar.actions.manageIncident.select' | i18n}}"
					labelI18nKey="nameI18nKey"
					[trackBy]="'id'"
					[values]="allAlertDeterminations"
					[isFullWidth]="true"
					[isDisabled]="isReadOnly"
					[ariaLabel]="'incident.commandBar.actions.manageIncident.determination' | i18n"
					[dropdownAreaRole]="'combobox'"
				>
				</fancy-select>
				<fab-message-bar
					[messageBarType]="MessageBarType.info"
					contentClass=" wcd-margin-small-bottom wcd-margin-largeMedium-top"
					*ngIf="determination">
					<div
						class="wcd-flex-1">{{ 'incident.commandBar.actions.manageIncident.determinationInfo' | i18n }}</div>
				</fab-message-bar>
			</div>
			</ng-template>
			<div class="wcd-padding-bottom wcd-textarea-placeholder">
				<label class="wcd-font-size-m wcd-font-weight-semibold" for="comment-text-{{id}}">{{
					'incident.commandBar.actions.manageIncident.comment' | i18n
					}}</label>
				<textarea
					id="comment-text-{{id}}"
					class="form-control wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[placeholder]="'incident.commandBar.actions.manageIncident.addComment' | i18n"
					[(ngModel)]="comment"
					name="comment-text-{{id}}"
					rows="3"
					[disabled]="isReadOnly"
				></textarea>
			</div>
		</div>
		<div class="wcd-border-top-light wcd-padding-top">
			<fab-primary-button
				data-track-id="SetIncident"
				data-track-type="Button"
				(onClick)="saveIncident()"
				[disabled]="isSaving || isReadOnly"
			>
				<i class="loader-icon" [hidden]="!isSaving"></i>
				{{ 'incident.commandBar.actions.manageIncident.save' | i18n }}
			</fab-primary-button>
			<fab-default-button
				data-track-id="CancelSetIncident"
				data-track-type="Button"
				className="btn-secondary"
				(onClick)="destroy()"
			>
				{{ 'incident.commandBar.actions.manageIncident.cancel' | i18n }}
			</fab-default-button>
		</div>
	</div>
</wcd-panel>
