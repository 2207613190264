import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

const SAVE_DATA = { timeout: 60000 };

@Entity({
	singularName: 'Intune integration status',
	pluralName: 'Intune integration status',
	endpoint: 'onboarding/intune/status',
	parseItemQuery: () => 'onboarding/intune/status',
	parseSaveQuery: (value: IntuneIntegrationStatus) =>
		`onboarding/intune/${value.intuneIntegrationEnabled ? '' : 'de'}provision`,
	serializeItem: () => SAVE_DATA,
	saveMethod: 'POST',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class IntuneIntegrationStatus extends EntityModelBase {
	@EntityField({
		parse: (value, data) => data === 1,
		defaultValue: false,
	})
	intuneIntegrationEnabled: boolean;

	@EntityField({
		parse: (value, data) => data === 0 || data === 1,
		defaultValue: false,
	})
	licenseEnabled: boolean;

	failed: boolean = false;
}
