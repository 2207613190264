import { ChangeDetectorRef, EventEmitter, ElementRef, AfterViewInit, } from '@angular/core';
import { ClipboardService } from '../services/clipboard.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
var CopyToClipboardComponent = /** @class */ (function () {
    function CopyToClipboardComponent(clipboardService, changeDetectionRef, i18nService, liveAnnouncer, document) {
        this.clipboardService = clipboardService;
        this.changeDetectionRef = changeDetectionRef;
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.document = document;
        this.ariaLabelFieldName = '';
        this.copyEvent = new EventEmitter();
        this.errorEvent = new EventEmitter();
        this.DEFAULT_COPY_SETTINGS = {
            copyText: this.i18nService.get('copy'),
        };
        this._settings = this.DEFAULT_COPY_SETTINGS;
    }
    Object.defineProperty(CopyToClipboardComponent.prototype, "settings", {
        get: function () {
            if (!this._settings) {
                this._settings = this.DEFAULT_COPY_SETTINGS;
            }
            return this._settings;
        },
        set: function (value) {
            if (value) {
                this._settings = Object.assign({}, this.DEFAULT_COPY_SETTINGS, value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CopyToClipboardComponent.prototype, "copyButton", {
        set: function (button) {
            this.buttonEl = button.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    CopyToClipboardComponent.prototype.ngAfterViewInit = function () {
        this.tooltipText = this.i18nService.get('common_copyFieldToClipboardLabel', {
            field: this.ariaLabelFieldName,
        });
    };
    CopyToClipboardComponent.prototype.copyToClipboard = function () {
        var _this = this;
        this.selectElement();
        this.clipboardService.copy(this.content).subscribe(function () {
            _this.isCopied = true;
            _this.tooltipText = _this.i18nService.get('common.copyToClipboard', {
                text: _this.ariaLabelFieldName,
            });
            _this.liveAnnouncer.announce(_this.i18nService.get('common.copyToClipboard', { text: _this.ariaLabelFieldName }));
            _this.changeDetectionRef.detectChanges();
            setTimeout(function () {
                _this.isCopied = false;
                _this.tooltipText = _this.i18nService.get('common_copyFieldToClipboardLabel', {
                    field: _this.ariaLabelFieldName,
                });
                _this.changeDetectionRef.detectChanges();
            }, 1500);
            _this.copyEvent.emit(_this.content);
        }, function (error) {
            _this.changeDetectionRef.detectChanges();
            _this.errorEvent.emit(error);
        }, function () {
            _this.buttonEl.focus();
        });
    };
    CopyToClipboardComponent.prototype.selectElement = function () {
        var _this = this;
        if (this.originId) {
            var originElement_1 = this.document.getElementById(this.originId);
            if (originElement_1 &&
                (originElement_1 instanceof HTMLTextAreaElement || originElement_1 instanceof HTMLInputElement)) {
                setTimeout(function () {
                    originElement_1.select();
                    _this.changeDetectionRef.markForCheck();
                });
            }
        }
    };
    return CopyToClipboardComponent;
}());
export { CopyToClipboardComponent };
