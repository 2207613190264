import { DataQuery, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineGroupSimpleRule } from './machine-group-simple-rule.value-object';
import { MachineGroupAssignment } from './machine-group-assignment.value-object';
import { ProtectionLevel } from '../protection-level/protection-level.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Device Group',
	pluralName: 'Device Groups',
	endpoint: 'rbac/machine_groups',
	parseDataQuery: (dataQuery: DataQuery) => {
		const defaultParams = {
			addAadGroupNames: 'false',
			addMachineGroupCount: 'false',
		};

		if (dataQuery && typeof dataQuery.where === 'string') {
			throw new Error('Error in machine Groups entity: where is string and not an object');
		}

		return Object.assign({}, defaultParams, dataQuery && dataQuery.where);
	},
	allItemsEndpointTrailingSlash: false,
	loadAll: true,
	allItemsProperty: 'items',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.rbacManagementApi,
	saveMethod: 'PUT',
	parseSaveItemsQuery: (items: Array<any>) => {
		return { data: items };
	},
})
export class MachineGroup extends EntityModelBase<number> {
	@EntityField({ data: 'MachineGroupId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Description' })
	description: string;

	@EntityField({ data: 'AutoRemediationLevel' })
	protectionLevel: ProtectionLevel;

	@EntityField({ data: 'Priority' })
	priority: number;

	@EntityField({ data: 'IsUnassignedMachineGroup', defaultValue: false })
	readonly isUnassignedMachineGroup: boolean;

	@EntityField({ data: 'MachineCount', defaultValue: 0 })
	machineCount: number;

	@EntityField({ data: 'LastUpdated' })
	lastUpdatedDate: Date;

	@EntityField({ data: 'LastUpdated' })
	lastUpdated: string; // There's also a string version so it's not parsed into a Date, because the backend requires the exact same string when updating, and we can't do microseconds in JS

	@EntityField({ data: 'GroupRules', arrayOf: MachineGroupSimpleRule })
	rules?: Array<MachineGroupSimpleRule>;

	@EntityField({
		data: 'MachineGroupAssignments',
		arrayOf: MachineGroupAssignment,
	})
	assignments: Array<MachineGroupAssignment>;

	@EntityField({ data: 'OldMachineGroupId' })
	readonly oldMachineGroupId: number;
}
