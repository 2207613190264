<h2 class="wcd-font-weight-bold">
	{{ i18n.strings.baseline_profile_wizard_profile_name_title }}
</h2>
<div class="wcd-margin-large-top">
	<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
		{{ i18n.strings.baseline_profile_name_description }}
	</div>

	<label for="baseline-profile-name-name" class="wcd-margin-mediumSmall-top required wcd-font-weight-semibold">
		{{ i18n.strings.tvm_baseline_profile_name_label }}
	</label>
	<input
		id="baseline-profile-name-name"
		type="text"
		name="name"
		class="form-control wcd-flex-1 wcd-width-large"
		[maxlength]="nameLength"
		[placeholder]="'baseline.profile.name.placeholder' | i18n"
		[(ngModel)]="data.name"
		[validateRegExp]="nameRegExp"
		required
		#name="ngModel"
	/>
	<div class="error-message" *ngIf="data.name && !name.valid">
		{{ 'baselineProfiles_profileName_error' | i18n }}
	</div>

	<label for="baseline-profile-name-description" class="wcd-margin-mediumSmall-top wcd-font-weight-semibold">
		{{ i18n.strings.tvm_baseline_description_label }}
	</label>
	<textarea
		id="baseline-profile-name-description"
		class="form-control wcd-width-large"
		[placeholder]="'baseline.profile.description.placeholder' | i18n"
		name="description"
		[maxlength]="descriptionLength"
		rows="4"
		[(ngModel)]="data.description"
		#description="ngModel"
	></textarea>
	<div class="error-message" *ngIf="data.description && !description.valid">
		{{ 'baselineProfiles_profileDescription_error' | i18n }}
	</div>

	<div class="form-group wcd-margin-large-top">
		<wcd-checkbox
			[(ngModel)]="data.isEnabled"
			[id]="'baseline-profile-enable'"
			[name]="'baseline-profile-enable'"
			[label]="i18n.strings.baseline_profile_enable"
		></wcd-checkbox>
	</div>
</div>
