import { FlavorConfig } from '@wcd/scc-common';

export interface IAlertsConfig {
	fileActions: FlavorConfig;
	openEntityPage: FlavorConfig;
	extraPanelDetails: FlavorConfig;
	detectionSourceExtended: FlavorConfig;
	alertFieldsExtended: FlavorConfig;
	timeline: FlavorConfig;
	user: FlavorConfig;
	observedInOrgSection: FlavorConfig;
	messageBar: FlavorConfig;
	investigation: FlavorConfig;
	threatExpertSource: FlavorConfig;
	autoIr: FlavorConfig;
	createSuppressionRule: FlavorConfig;
	deepAnalysis: FlavorConfig;
	mitre: FlavorConfig;
	goHunt: FlavorConfig;
}

export const AlertsConfig: IAlertsConfig = {
	fileActions: {
		mdeFlavors: ['P2'],
	},
	openEntityPage: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	extraPanelDetails: {
		mdeFlavors: ['P2'],
	},
	detectionSourceExtended: {
		mdeFlavors: ['P2', 'Smb'],
	},
	threatExpertSource: {
		mdeFlavors: ['P2'],
	},
	alertFieldsExtended: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	timeline: {
		mdeFlavors: ['P2'],
	},
	user: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	observedInOrgSection: {
		mdeFlavors: ['P2'],
	},
	messageBar: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	investigation: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	autoIr: {
		mdeFlavors: ['P2', 'Smb'],
	},
	createSuppressionRule: {
		mdeFlavors: ['P2', 'Smb'],
	},
	deepAnalysis: {
		mdeFlavors: ['P2'],
	},
	mitre: {
		mdeFlavors: ['P2', 'P1'],
	},
	goHunt: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
};
