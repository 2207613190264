var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy } from '@angular/core';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { FeaturesService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { userReportModel } from './user-overview.component';
var UserEntityComponent = /** @class */ (function (_super) {
    __extends(UserEntityComponent, _super);
    function UserEntityComponent(usersService, featuresService, router, route, i18nService) {
        var _this = _super.call(this) || this;
        _this.usersService = usersService;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.route = route;
        _this.i18nService = i18nService;
        _this.report = userReportModel;
        return _this;
    }
    Object.defineProperty(UserEntityComponent.prototype, "user", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    UserEntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscription = this.route.queryParamMap.subscribe(function (params) {
            var accountName = params.get('accountName');
            var accountDomainName = params.get('accountDomainName');
            _this.tabs = _this.getTabs(accountName, accountDomainName);
        });
    };
    UserEntityComponent.prototype.ngOnDestroy = function () {
        this.subscription && this.subscription.unsubscribe();
    };
    UserEntityComponent.prototype.getTabs = function (accountName, accountDomainName) {
        var tabs = [
            {
                id: 'alerts',
                name: this.i18nService.get('users.tabs.alerts'),
                routerLink: 'alerts',
                routerLinkQueryParams: { accountName: accountName, accountDomainName: accountDomainName },
            },
            {
                id: 'machines',
                name: this.i18nService.get('users.tabs.observed'),
                routerLink: 'machines',
                routerLinkQueryParams: { accountName: accountName, accountDomainName: accountDomainName },
            },
        ];
        tabs.unshift({
            id: 'overview',
            name: this.i18nService.get('users.tabs.overview'),
            routerLink: 'overview',
            routerLinkQueryParams: { accountName: accountName, accountDomainName: accountDomainName },
        });
        return tabs.map(function (tab) { return new TabModel(tab); });
    };
    return UserEntityComponent;
}(EntityComponentBase));
export { UserEntityComponent };
