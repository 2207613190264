import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { WcdPortalParisConfig, IncidentLinkedByCategory } from '..';
import { Incident, IncidentLinkedByEntity } from '@wcd/domain';

@ValueObject({
	singularName: 'Related incident',
	pluralName: 'Related incidents',
	readonly: true,
})
export class RelatedIncident extends ModelBase {
	@EntityField({
		data: 'Category',
	})
	category: IncidentLinkedByCategory;

	@EntityField({
		data: 'TargetIncidentId',
	})
	targetIncidentId: number;

	@EntityField({
		data: 'Entity',
	})
	entity: IncidentLinkedByEntity;
}

@ValueObject({
	singularName: 'Associated incidents response',
	pluralName: 'Associated incidents responses',
	readonly: true,
})
export class AssociatedIncidentResponse extends ModelBase {
	@EntityField({
		data: 'RelatedIncidents',
		arrayOf: RelatedIncident,
	})
	relatedIncidents: Array<RelatedIncident>;

	@EntityField({
		data: 'HasMore',
	})
	hasMore: boolean;
}

@ApiCall({
	name: 'Incident sample associated incidents',
	endpoint: (_, query) => {
		return `incidents/${query.where['id']}/relatedIncidentsSample`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.relatedIncidents,
	method: 'GET',
	type: AssociatedIncidentResponse,
})
export class IncidentAssociatedIncidentsApiCallSample extends ApiCallModel<
	AssociatedIncidentResponse,
	Incident
> {}

@ApiCall({
	name: 'Incident associated incidents',
	endpoint: (_, query) => {
		return `incidents/${query.where['id']}/relatedIncidents`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.relatedIncidents,
	method: 'GET',
	type: AssociatedIncidentResponse,
	cache: true,
})
export class IncidentAssociatedIncidentsApiCall extends ApiCallModel<AssociatedIncidentResponse, Incident> {}
