import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Registry } from './registry.value-object';

@ValueObject({
	singularName: 'Registry modification',
	pluralName: 'Registry modifications',
	readonly: true,
})
export class RegistryModificationDetails extends EntityModelBase<string> {
	@EntityField({ data: 'Registry' })
	current: Registry;

	@EntityField({ data: 'PreviousRegistry' })
	previous: Registry;
}
