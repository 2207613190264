import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { AirsEntityActionStatus } from './airs-entity-action-status.entity';
import { RemediationActionType } from '../remediation/remediation-action-type.entity';
import { AirsEntityActionUser } from './airs-entity-action-user.value-object';

@ValueObject({
	singularName: 'Entity Action',
	pluralName: 'Entity Actions',
	readonly: true,
})
export class AirsEntityAction extends ModelBase {
	@EntityField() status: AirsEntityActionStatus;

	@EntityField({
		parse: (value, rawData) => {
			return { name: rawData.user, type: rawData.type };
		},
	})
	user: AirsEntityActionUser;

	@EntityField() reason: string;

	@EntityField({ data: 'action_type' })
	remediationActionType: RemediationActionType;
}
