var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { OutbreakDailyImpactedDevices } from './impact/outbreak-daily-impacted-devices.entity';
import { OutbreakUserState } from './outbreak-user-state.entity';
import { OutbreakAlertsCount } from './alerts/outbreak-alerts-count.value-object';
import { OutbreakImpactedEntitiesCount } from './impact/outbreak-impacted-entities-count.value-object';
import { ThreatReportType } from './threat-report-type.values';
import { sccHostService } from '@wcd/scc-interface';
import { ThreatExposureSeverity } from './threat-exposure-severity.entity';
export var THREAT_ANALYTICS_ROUTE = 'threatanalytics3';
export var setTaSccNavNotificationIndicator = function (indicator) {
    sccHostService.ui.setNavNotificationIndicator([THREAT_ANALYTICS_ROUTE], indicator);
};
var ɵ0 = function (config, query) {
    return query && query.where && query.where.isMtp ?
        query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedDataMtp' : 'v2/outbreaksEnrichedDataMtp'
        : query && query.where && query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedData' : 'outbreaksEnrichedData';
}, ɵ1 = function (config) { return config.data.serviceUrls.threatAnalytics; }, ɵ2 = function (rawData) {
    return ({
        items: rawData['Items'] || rawData['items'],
        meta: {
            devicesCalculationStatus: rawData['DevicesCalculationStatus'],
            mailboxesCalculationStatus: rawData['MailboxesCalculationStatus'],
            threatExposureCalculationStatus: rawData['ThreatExposureCalculationStatus']
        },
        count: 0
    });
};
var ExtendedOutbreak = /** @class */ (function (_super) {
    __extends(ExtendedOutbreak, _super);
    function ExtendedOutbreak(data) {
        var _this = _super.call(this, data) || this;
        _this.isNewDataHelper = {};
        return _this;
    }
    Object.defineProperty(ExtendedOutbreak.prototype, "isNew", {
        get: function () {
            return !this.isNewDataHelper.lastVisitTime || this.isNewDataHelper.lastVisitTime < this.lastUpdatedOn;
        },
        enumerable: true,
        configurable: true
    });
    ExtendedOutbreak.adjustOutbreaksData = function (outbreaks, lastVisitTimes) {
        var userStateExistsInBackend = (outbreaks || []).some(function (outbreak) { return outbreak.userState.lastVisitTime != null; });
        (outbreaks || []).forEach(function (outbreak) {
            outbreak.isNewDataHelper.lastVisitTime = userStateExistsInBackend
                ? outbreak.userState.lastVisitTime
                : (lastVisitTimes && lastVisitTimes.get(outbreak.id));
        });
        return outbreaks;
    };
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], ExtendedOutbreak.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'DisplayName' }),
        __metadata("design:type", String)
    ], ExtendedOutbreak.prototype, "displayName", void 0);
    __decorate([
        EntityField({ data: 'CreatedOn' }),
        __metadata("design:type", Date)
    ], ExtendedOutbreak.prototype, "createdOn", void 0);
    __decorate([
        EntityField({ data: 'StartedOn' }),
        __metadata("design:type", Date)
    ], ExtendedOutbreak.prototype, "startedOn", void 0);
    __decorate([
        EntityField({ data: 'LastUpdatedOn' }),
        __metadata("design:type", Date)
    ], ExtendedOutbreak.prototype, "lastUpdatedOn", void 0);
    __decorate([
        EntityField({ data: 'Tags' }),
        __metadata("design:type", Array)
    ], ExtendedOutbreak.prototype, "tags", void 0);
    __decorate([
        EntityField({ data: 'Impact' }),
        __metadata("design:type", OutbreakDailyImpactedDevices)
    ], ExtendedOutbreak.prototype, "impact", void 0);
    __decorate([
        EntityField({ data: 'ImpactOvertime', arrayOf: OutbreakDailyImpactedDevices }),
        __metadata("design:type", Array)
    ], ExtendedOutbreak.prototype, "impactOvertime", void 0);
    __decorate([
        EntityField({
            data: 'AlertsCount',
            defaultValue: {
                activeAlertsCount: 0,
                resolvedAlertsCount: 0,
            }
        }),
        __metadata("design:type", OutbreakAlertsCount)
    ], ExtendedOutbreak.prototype, "alertsCount", void 0);
    __decorate([
        EntityField({
            data: 'ImpactedEntitiesCount',
            defaultValue: {
                impactedDevicesCount: 0,
                impactedMailboxesCount: 0,
                hasDevicesDefinition: false,
                hasMailboxesDefinition: false,
            }
        }),
        __metadata("design:type", OutbreakImpactedEntitiesCount)
    ], ExtendedOutbreak.prototype, "impactedEntitiesCount", void 0);
    __decorate([
        EntityField({ data: 'ExposureSeverity' }),
        __metadata("design:type", ThreatExposureSeverity)
    ], ExtendedOutbreak.prototype, "exposureSeverity", void 0);
    __decorate([
        EntityField({ data: 'ExposureScore' }),
        __metadata("design:type", Number)
    ], ExtendedOutbreak.prototype, "exposureScore", void 0);
    __decorate([
        EntityField({ data: 'ExposedDevices' }),
        __metadata("design:type", Number)
    ], ExtendedOutbreak.prototype, "exposedDevices", void 0);
    __decorate([
        EntityField({ data: 'CveAndScidNotDefined' }),
        __metadata("design:type", Boolean)
    ], ExtendedOutbreak.prototype, "cveAndScidNotDefined", void 0);
    __decorate([
        EntityField({ data: 'ReportType' }),
        __metadata("design:type", String)
    ], ExtendedOutbreak.prototype, "reportType", void 0);
    __decorate([
        EntityField({ data: 'UserState' }),
        __metadata("design:type", OutbreakUserState)
    ], ExtendedOutbreak.prototype, "userState", void 0);
    __decorate([
        EntityField({ data: 'CveNotDefined' }),
        __metadata("design:type", Boolean)
    ], ExtendedOutbreak.prototype, "cveNotDefined", void 0);
    __decorate([
        EntityField({ data: 'ScidNotDefined' }),
        __metadata("design:type", Boolean)
    ], ExtendedOutbreak.prototype, "scidNotDefined", void 0);
    __decorate([
        EntityField({ data: 'ScaExposedDevices' }),
        __metadata("design:type", Number)
    ], ExtendedOutbreak.prototype, "scaExposedDevices", void 0);
    __decorate([
        EntityField({ data: 'VaExposedDevices' }),
        __metadata("design:type", Number)
    ], ExtendedOutbreak.prototype, "vaExposedDevices", void 0);
    ExtendedOutbreak = __decorate([
        Entity({
            singularName: 'Threat',
            pluralName: 'Threats',
            readonly: true,
            // Once MDATP portal is deprecated the endpoint is always outbreaksEnrichedDataMtp
            endpoint: ɵ0,
            loadAll: true,
            baseUrl: ɵ1,
            parseDataSet: ɵ2
        }),
        __metadata("design:paramtypes", [Object])
    ], ExtendedOutbreak);
    return ExtendedOutbreak;
}(EntityModelBase));
export { ExtendedOutbreak };
export { ɵ0, ɵ1, ɵ2 };
