/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./evidence-detection-context.component.ngfactory";
import * as i2 from "./evidence-detection-context.component";
import * as i3 from "../services/evidence.service";
import * as i4 from "@angular/common";
import * as i5 from "./evidence-detection-context-tooltip.component";
var styles_EvidenceDetectionContextTooltipComponent = [];
var RenderType_EvidenceDetectionContextTooltipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EvidenceDetectionContextTooltipComponent, data: {} });
export { RenderType_EvidenceDetectionContextTooltipComponent as RenderType_EvidenceDetectionContextTooltipComponent };
function View_EvidenceDetectionContextTooltipComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [", "]))], null, null); }
function View_EvidenceDetectionContextTooltipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "evidence-detection-context", [], null, null, null, i1.View_EvidenceDetectionContextComponent_0, i1.RenderType_EvidenceDetectionContextComponent)), i0.ɵdid(3, 49152, null, 0, i2.EvidenceDetectionContextComponent, [i3.EvidenceService], { showTooltip: [0, "showTooltip"], detectionContext: [1, "detectionContext"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextTooltipComponent_2)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = false; var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = !_v.context.last; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_EvidenceDetectionContextTooltipComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextTooltipComponent_1)), i0.ɵdid(2, 278528, null, 0, i4.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detectionContexts; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EvidenceDetectionContextTooltipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "evidence-detection-context-tooltip", [], null, null, null, View_EvidenceDetectionContextTooltipComponent_0, RenderType_EvidenceDetectionContextTooltipComponent)), i0.ɵdid(1, 49152, null, 0, i5.EvidenceDetectionContextTooltipComponent, [], null, null)], null, null); }
var EvidenceDetectionContextTooltipComponentNgFactory = i0.ɵccf("evidence-detection-context-tooltip", i5.EvidenceDetectionContextTooltipComponent, View_EvidenceDetectionContextTooltipComponent_Host_0, { detectionContexts: "detectionContexts" }, {}, []);
export { EvidenceDetectionContextTooltipComponentNgFactory as EvidenceDetectionContextTooltipComponentNgFactory };
