import { ValueObject, EntityField, EntityModelBase } from '@microsoft/paris';
import { exploitGuardInnerRules } from './exploit-guard-inner-rule.values';

@ValueObject({
	singularName: 'Exploit guard inner rule',
	pluralName: 'Exploit guard inner rules',
	readonly: true,
	values: exploitGuardInnerRules,
})
export class ExploitGuardInnerRule extends EntityModelBase<string> {
	@EntityField() name?: string;
}
