
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div class="wcd-flex-vertical wcd-full-height" *ngIf="agentsConfig">
				<div
					class="wcd-flex-1 wcd-padding-large-horizontal wcd-margin-small-vertical wcd-scroll-vertical"
				>
					<form #agentsForm="ngForm">
						<simulator-agent-form-content
							[agentsConfig]="agentsConfig"
							(onProviderToggled)="onProviderToggled()"
						></simulator-agent-form-content>
						<fab-spinner *ngIf="saving" contentClass="wcd-margin-xLarge-top"></fab-spinner>
						<fab-message-bar
							[messageBarType]="MessageBarType.error"
							contentClass="wcd-margin-xLarge-top"
							*ngIf="(error$ | async) && !saving"
						>
							{{ 'evaluation.lab.setupPanel.summaryStep.simulatorError' | i18n }}
						</fab-message-bar>
					</form>
				</div>
				<div
					class="wcd-flex-justify-end wcd-flex-none wcd-padding-horizontal wcd-padding-top wcd-flex-horizontal"
				>
					<fab-primary-button
						contentClass="wcd-margin-small-horizontal"
						[disabled]="!agentsForm.valid || saving || !canUpdate"
						[text]="'evaluation_lab_setupPanel_summaryStep_update' | i18n"
						(onClick)="onUpdate()"
					></fab-primary-button>
				</div>
			</div>
		</wcd-panel>
	