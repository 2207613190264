<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-flex-horizontal" data-track-component="AirsEntityBehavior">
	<div class="wcd-flex-none" style="margin-right: 2rem">
		<ul class="nav-tree">
			<li *ngFor="let behaviorGroup of entityBehavior">
				<h5
					class="minimize-btn"
					(click)="toggleBehaviorGroup(behaviorGroup)"
					[ngClass]="{ minimized: collapsedBehavior[behaviorGroup.name] }"
				>
					{{ behaviorGroup.name }}
					<wcd-shared-icon [iconName]="'carets.right'" class="minimize-btn-icon"> </wcd-shared-icon>
				</h5>
				<ul class="nav-tree" *ngIf="!collapsedBehavior[behaviorGroup.name]">
					<li *ngFor="let behaviorGroupProperty of (behaviorGroup.values | withValues)">
						<a
							(click)="setCurrentGroupProperty(behaviorGroup, behaviorGroupProperty)"
							data-track-id="SetCurrentGroupProperty"
							data-track-type="Action"
							[ngClass]="{ active: behaviorGroupProperty === currentBehaviorGroupProperty }"
						>
							{{ behaviorGroupProperty.name }} ({{ behaviorGroupProperty.values.length }})
						</a>
					</li>
				</ul>
			</li>
		</ul>
	</div>
	<div class="wcd-flex-1">
		<div class="spread-list-filter" [ngClass]="{ filtered: valuesFilter }">
			<wcd-shared-icon [iconName]="'filter'" class="label-icon noprint"> </wcd-shared-icon>
			<input
				type="text"
				[(ngModel)]="valuesFilter"
				placeholder="{{
					'airsEntities_behavior_filter'
						| i18n
							: {
									group: currentBehaviorGroup.name,
									groupProperty: currentBehaviorGroupProperty.name
							  }
				}}"
			/>
			<button
				class="btn btn-link"
				[wcdTooltip]="'Clear filter'"
				*ngIf="valuesFilter"
				(click)="clearFilter()"
			>
				<wcd-shared-icon [iconName]="'clear'"> </wcd-shared-icon>
			</button>
		</div>
		<ul class="spread-list">
			<li
				*ngFor="
					let behaviorValue of (currentBehaviorGroupProperty.values | stringContains: valuesFilter)
				"
				[ngSwitch]="currentBehaviorGroup.name"
			>
				<div *ngSwitchCase="'Network'">
					<div *ngIf="isArray(behaviorValue)">
						<span *ngFor="let p of behaviorValue">
							<strong>{{ p[0] }}</strong
							>: {{ p[1] }}
						</span>
					</div>
					<span *ngIf="!isArray(behaviorValue)">{{ behaviorValue }}</span>
				</div>
				<span *ngSwitchDefault>{{ behaviorValue }}</span>
			</li>
		</ul>
	</div>
</div>
