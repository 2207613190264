import { SevilleAlertsModule } from './seville.alerts.module';
import { userAccountService } from '../../seville/services/seville.userAccounts';
import { SevilleUtils } from '../../common/services/seville.utils';
import { FeaturesService, Feature } from '@wcd/config';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { HybridRoutingService } from '../../../../hybrid-routing.service';
import { THREAT_EXPERT_DETECTION_SOURCE_ID } from '../entity/seville.threatintel.alert';

declare let angular: angular.IAngularStatic;

alertRowController.$inject = [
	'$state',
	'appConfig',
	'featuresService',
	'machinesService',
	'ng2router',
	'urlMapping',
];

SevilleAlertsModule.component('alertRow', {
	bindings: {
		alert: '=',
		selected: '=',
		showAlertHighlight: '<',
		showMachineContext: '=',
		showUserContext: '<',
		entityPageLayout: '=',
		checkmarkClick: '<',
		selectRow: '<',
		manageAlert: '=',
	},
	template: `
  <div class="row attack hover-default overflow col-middle"
       right-click="vm.printJsonToDebug(vm.alert)"
       data-track-component="SevilleAlertRow"
       ng-class="{'alert-row-managed' : vm.alert.managed && !vm.alert.highlighted,
                      'alert-related-event' : vm.selected }" >
      <div class="row-same-height side-pane-container" >

          <!-- Title + checkmark - queue -->
          <div class="col-xs-12 col-sm-6 col-sm-height alert-queue-cell-padding col-middle no-dim"
               ng-class="::vm.getColumnSize()"
               ng-if="::!vm.entityPageLayout"
               ng-click="vm.selectRow(vm.alert, $event)" >
              <a ng-href="{{::vm.alert.alertLink}}" class="alert-queue-record-text wcd-full-width"
              	data-track-id="OpenAlertPanelFromClickOnRow"
	   			data-track-type="Navigation"
                 ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive, 'pointer' : vm.alert.clickSensitive}">
                  <table class="alert-queue-title-margin col-full-height alert-title-table alert-row full-table" role="none">
                      <tr role="none">
                          <td class="alert-queue-checkmark-col" role="none">
                              <side-pane-alert class="side-pane-alert" selected="vm.selected" ng-click="vm.checkmarkClick(vm.alert, $event)"></side-pane-alert>
                          </td>
                          <td class="alert-queue-title-inner-padding" role="none">
                              <table class="col-full-height" ng-class="::{'alert-queue-child-alert' : !vm.alert.isGroup && vm.alert.GroupId > 0}" role="none">
                                  <tr role="none">
                                      <td ng-if="vm.alert.ActorName" role="none"
                                          class="col-middle attack-campain attack-campain-{{vm.alert.Severity | lowercase }}">
                                          <span class="alert-queue-record-text" ng-if="vm.alert.ActorName">
                                              {{vm.alert.ActorName}}
                                          </span>
                                      <td role="none">
                                          <table class="col-full-height"
                                          ng-class="vm.getAlertSeverityColorOrActorClassName()" role="none">
                                              <tr role="none">
                                                  <td class="alert-queue-title-cell" role="none">
                                                      <span class="alert-queue-external-ti text-nowrap"
                                                      	ng-if="vm.alert.ThreatIntelProvider && vm.alert.ThreatIntelProvider.toLowerCase() == 'customer'">Custom TI</span>
                                                      <div class="inline" >
                                                          <table class="full-table" ng-if="vm.alert.isGroup" role="none">
                                                              <tr role="none">
                                                                  <td class="title-table-alerts-cell" role="none">
                                                                      <i class="icon alert-queue-group-icon" ng-if="::vm.alert.isGroup"
                                                                      	ng-class="{'icon-CaretHollow' : vm.alert.collapsed, 'icon-CaretSolid' : !vm.alert.collapsed}"></i>
                                                                  </td>
                                                                  <td role="none">
                                                                      <span class="bolder" ng-if="::vm.alert.AlertCount > 1">{{::vm.alert.AlertCount}} alerts: </span>
                                                                      <span class="bolder" ng-if="::vm.alert.AlertCount == 1 && vm.alert.isGroup">{{::vm.alert.AlertCount}} alert: </span>
                                                                      <span class="break-words">
                                                                      	<span ng-if="::vm.alert.isThreatExpertOriginated"
																			class="tag wcd-margin-small-right tag-color-box-threatExpert">
																			{{::vm.alert.DetectionSource | alertDetectionSource }}
																		</span>
                                                                      	{{::vm.alert.Title}}
                                                                      	</span>
                                                                  </td>
                                                              </tr>
                                                          </table>

                                                          <span ng-if="!vm.alert.isGroup" class="alert-queue-record-text-underline pointer break-words"
                                                          	ng-click="vm.selectRow(vm.alert, $event, true)">
                                                          	<span ng-if="::vm.alert.isThreatExpertOriginated"
                                                          		class="tag wcd-margin-small-right tag-color-box-threatExpert">
																{{::vm.alert.DetectionSource | alertDetectionSource }}
															</span>
                                                          	{{::vm.alert.Title}}
                                                          	</span>
                                                      </div>

                                                  </td>
                                              </tr>
                                              <tr>
                                                  <td class="alert-group-subtitle alert-queue-title-inner-padding" role="none">
                                                      <span class="similar-by-padding" ng-if="vm.alert.isGroup && vm.alert.similarBy">Grouped by: {{::vm.alert.similarBy }}</span>
                                                      <span ng-if="!vm.alert.isGroup">Category:
                                                          {{::vm.alert.Categories | arrayToText | splitCamelCase }}
                                                      </span>
                                                  </td>
                                              </tr>
                                          </table>
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </a>
          </div>

          <!-- Last activity + checkmark - entity pages -->
          <div class="col-xs-12 col-sm-2 col-md-1 col-sm-height attack-info-content alert-queue-cell-padding col-middle entity-page-alert-date alert-last-activity-entity-container"

		  	ng-if="::vm.entityPageLayout"
               data-track-id="SelectAlertRow"
	   			data-track-type="Action"
               ng-click="vm.selectRow(vm.alert, $event)"
               ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive, 'pointer' : vm.alert.clickSensitive}"
               small-container=110>
              <a data-track-id="AlertLink"
	   				data-track-type="Navigation"
	   				ng-href="{{vm.alert.alertLink}}" class="alert-queue-record-text">
                  <table class="alert-associated-title-margin col-full-height alert-row full-table" role="none" ng-class="{'alert-queue-child-alert' : vm.alert.GroupId > 0}">
                      <tr role="none">
                          <td class="alert-queue-checkmark-col" role="none">
                              <div class="alert-queue-checkmark-sidepane">
                                  <side-pane-alert class="side-pane-alert" alert-id="vm.alert.sidePaneKey" selected="vm.selected" ng-click="vm.checkmarkClick(vm.alert, $event)"></side-pane-alert>
                              </div>

                          </td>
                          <td class="alert-queue-title-inner-padding bolder small-padding" role="none">
                              <div class="alert-last-activity last-activity-big-container">
                              <date ng-if="!vm.alert.isGroup || (vm.alert.collapsed)"
                                    date-value="vm.alert.LastEventTime"></date>
                              </div>
                              <div class="alert-last-activity last-activity-small-container"  tooltips tooltip-title="{{::vm.alert.formattedLastEventTime}}" role="none">
                              <date ng-if="!vm.alert.isGroup || (vm.alert.collapsed)"
                                    date-value="vm.alert.LastEventTime"></date>
                              </div>

                          </td>
                      </tr>
                  </table>
              </a>
          </div>


          <!-- Title - entity page -->
          <div class="col-xs-12 col-sm-6 col-md-4 col-sm-height alert-queue-cell-padding col-middle"
               ng-if="::vm.entityPageLayout"
               ng-click="vm.selectRow(vm.alert, $event)"
               data-track-id="SelectAlertRow"
			   data-track-type="Action"

               ng-class="{'active-alerts-record-resolved' : vm.alert.Status == 8,
                              'alert-title-big' : vm.isEdge,
                              'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}">
              <a data-track-id="AlertLink"
	   				data-track-type="Navigation"
	   				ng-href="{{vm.alert.alertLink}}" class="alert-queue-record-text wcd-full-width">
                  <table class="col-full-height alert-title-table" role="none">
                      <tr role="none">
                          <td ng-if="vm.alert.ActorName" role="none"
                              class="col-middle attack-campain attack-campain-{{vm.alert.Severity | lowercase }}">
                              <span class="alert-queue-record-text" ng-if="vm.alert.ActorName">
                                  {{vm.alert.ActorName}}
                              </span>
                          <td role="none">
                              <table class="col-full-height"
                              ng-class="vm.getAlertSeverityColorOrActorClassName()" role="none">
                                  <tr role="none">
                                      <td class="alert-queue-title-cell" role="none">
                                          <span class="alert-queue-external-ti text-nowrap" ng-if="vm.alert.ThreatIntelProvider && vm.alert.ThreatIntelProvider.toLowerCase() != 'isight' && vm.alert.ThreatIntelProvider.toLowerCase() != 'microsoft'">Custom TI</span>
                                          <span >
                                              <i class="icon alert-queue-group-icon" ng-if="::vm.alert.isGroup" ng-class="{'icon-CaretHollow' : vm.alert.collapsed, 'icon-CaretSolid' : !vm.alert.collapsed}"></i>
                                              <span class="bolder" ng-if="::vm.alert.AlertCount > 1">{{::vm.alert.AlertCount}} alerts: </span>
                                              <span class="bolder" ng-if="::vm.alert.AlertCount == 1 && vm.alert.isGroup">{{::vm.alert.AlertCount}} alert: </span>
                                              <span class="break-words" ng-class="::{'alert-queue-record-text-underline' : !vm.alert.isGroup}"
                                              	ng-click="vm.selectRow(vm.alert, $event, true)">
                                              	<span ng-if="::vm.alert.isThreatExpertOriginated"
													class="tag wcd-margin-small-right tag-color-box-threatExpert">
													{{::vm.alert.DetectionSource | alertDetectionSource }}
												</span>
                                              	{{vm.alert.Title}}
                                              	</span>
                                          </span>

                                      </td>
                                  </tr>
                                  <tr role="none">
                                      <td class="alert-group-subtitle alert-queue-title-inner-padding" role="none">
                                          {{::vm.alert.Categories | arrayToText | splitCamelCase }}
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </a>
          </div>

          <!-- Context -->
          <div class="col-xs-12 col-sm-height col-middle alert-queue-cell-padding" ng-click="vm.selectRow(vm.alert, $event)"
               ng-class="{'alert-queue-context-big' : vm.entityPageLayout,
                              'alert-queue-context' : !vm.entityPageLayout,
                              'col-sm-3 col-md-3': (vm.entityPageLayout && !vm.isAutoIRFeatureEnabled),
                              'col-sm-2 col-md-2': (!vm.entityPageLayout || (vm.entityPageLayout && (vm.isAutoIRFeatureEnabled))),
                              'active-alerts-record-resolved' :  vm.entityPageLayout && vm.alert.Status == 8,
                              'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}">
              <div ng-if="vm.alert.MachineId && vm.showMachineContext" class="overflow-all ellipsis">
                  <i class="icon icon-DeviceLaptopNoPic"></i>
                  <side-pane-machine machineid="vm.alert.MachineId" computerdnsname="vm.alert.ComputerDnsName" internalid="vm.alert.InternalMachineId" hide-indicator="::!(vm.alert.ComputerDnsName && vm.alert.MachineId && vm.alert.MachineCount <= 1)"/>
                  <a ng-if="::vm.alert.ComputerDnsName && vm.alert.MachineId && vm.alert.MachineCount <= 1"
                  	class="ellipsis attack-machine-name-clickable"
                  	data-track-id="GoToMachine"
	   				data-track-type="Navigation"
                  	href="{{::vm.getMachineLink(vm.alert)}}"
                  	ng-click="vm.selectMachine(vm.alert, $event)" stop-propagation>
                      <span ng-if="::(((vm.alert.ComputerDnsName | parts:1).length < 50 && vm.entityPageLayout) || ((vm.alert.ComputerDnsName | parts:1).length < 27 && !vm.entityPageLayout))">
                          {{ vm.alert.ComputerDnsName | parts:1 | lowercase }}
                      </span>
                      <span ng-if="::(((vm.alert.ComputerDnsName | parts:1).length >= 50 && vm.entityPageLayout) || ((vm.alert.ComputerDnsName | parts:1).length >= 27 && !vm.entityPageLayout))"
                          tooltips tooltip-title="{{ vm.alert.ComputerDnsName | parts:1 | lowercase }}">
                          {{ vm.alert.ComputerDnsName | parts:1 | lowercase }}
                      </span>
                  </a>
                  <a ng-if="::!vm.alert.ComputerDnsName && vm.alert.MachineId && vm.alert.MachineCount <= 1"
					data-track-id="GoToMachine"
	   				data-track-type="Navigation"
                  	class="ellipsis attack-machine-name-clickable"
                  	href="{{::vm.getMachineLink(vm.alert)}}"
                  	ng-click="vm.selectMachine(vm.alert, $event)" stop-propagation>1 device</a>
                  <span ng-if="::!vm.alert.ComputerDnsName && vm.alert.MachineId && vm.alert.MachineCount > 1" class="ellipsis attack-machine-name-clickable">{{::vm.alert.MachineCount}} machines</span>
              </div>

              <div ng-if="!vm.alert.MachineId && vm.alert.MachineCount == 1" class="ellipsis">1 Device</div>
              <div ng-if="!vm.alert.MachineId && vm.alert.MachineCount > 1" class="ellipsis pointer">{{::vm.alert.MachineCount}} devices</div>

              <div class="overflow-all ellipsis"
                   ng-class="::{'alert-queue-user-margin' : vm.showMachineContext }"
                   ng-if="vm.showUserContext && vm.alert.DomainName && vm.alert.AccountName">
                  <i class="icon" ng-class="{'icon-Contact': !vm.alert.ContainerId, 'icon-DeviceGuard icon-green-color': vm.alert.ContainerId}"></i>
                  <side-pane-user accountname="::vm.alert.AccountName" accountdomainname="vm.alert.DomainName" sid="vm.alert.AccountSid" hide-indicator="::!vm.showUserLinkAndSidePane" />
                  <a ng-if="::vm.showUserLinkAndSidePane"
                  	class="ellipsis"
                  	data-track-id="OpenUserPanel"
	   				data-track-type="Navigation"
                  	ui-sref="user(::{ userdomain: vm.alert.DomainName, id: vm.alert.AccountName, sid: vm.alert.AccountSid})">
                      <span ng-if="::(((vm.alert.DomainName + vm.alert.AccountName).length < 49 && vm.entityPageLayout) || ((vm.alert.DomainName + vm.alert.AccountName).length < 26 && !vm.entityPageLayout))">
                          {{ vm.alert.DomainName | lowercase}}\\{{vm.alert.AccountName | lowercase}}
                      </span>
                      <span ng-if="::(((vm.alert.DomainName + vm.alert.AccountName).length >= 49 && vm.entityPageLayout) || ((vm.alert.DomainName + vm.alert.AccountName).length >= 26 && !vm.entityPageLayout))"
                          tooltips tooltip-title="{{ vm.alert.DomainName | lowercase}}\\{{vm.alert.AccountName | lowercase}}">
                          {{ vm.alert.DomainName | lowercase}}\\{{vm.alert.AccountName | lowercase}}
                      </span>
                  </a>
                  <span ng-if="::!vm.showUserLinkAndSidePane" class="ellipsis">
                      <span ng-if="::(((vm.alert.DomainOrContainerId + vm.alert.AccountName).length < 49 && vm.entityPageLayout) || ((vm.alert.DomainOrContainerId + vm.alert.AccountName).length < 26 && !vm.entityPageLayout))">
                          {{ vm.alert.DomainOrContainerId | lowercase}}\\{{vm.alert.AccountName | lowercase}}
                      </span>
                      <span ng-if="::(((vm.alert.DomainOrContainerId + vm.alert.AccountName).length >= 49 && vm.entityPageLayout) || ((vm.alert.DomainOrContainerId + vm.alert.AccountName).length >= 26 && !vm.entityPageLayout))"
                          tooltips tooltip-title="{{ vm.alert.DomainOrContainerId | lowercase}}\\{{vm.alert.AccountName | lowercase}}">
                          {{ vm.alert.DomainOrContainerId | lowercase}}\\{{vm.alert.AccountName | lowercase}}
                      </span>
                  </span>
              </div>
          </div>

          <!--Need to split groups and singles for ng-class perf on group open / close -->
          <!-- Severity - click (groups in queue / machine timline)-->
              <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding pointer"
                 data-track-id="GoToAlert"
				 data-track-type="Navigation"
                 ng-click="vm.selectRow(vm.alert, $event)"
                 ng-class="{'active-alerts-record-resolved' :  vm.entityPageLayout && vm.alert.Status == 8}"
                 ng-href="{{vm.alert.alertLink}}"
                 ng-if="::vm.alert.clickSensitive">
                  <div class="overflow overflow-all ellipsis" tooltip-show-on-overflow-only tooltips tooltip-title="{{ vm.alert.Severity | severity }}" ng-if="!vm.alert.isGroup || vm.alert.collapsed">{{ vm.alert.Severity | severity }}</div>
              </a>

              <!-- Severity - no click (single alert in queue)-->
              <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding select-disabled-cursor"
                 data-track-id="GoToAlert"
				 data-track-type="Navigation"
				 ng-href="{{::vm.alert.alertLink}}"
                 ng-if="::(!vm.alert.clickSensitive && !vm.entityPageLayout)">
                  <div class="inline">
                      <span class="ellipsis">{{ vm.alert.Severity | severity }}</span>
                      </div>
  			</a>
              <!-- Severity - no click and backgound (single alert in entity page timeline)-->
              <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding select-disabled-cursor"
                 ng-href="{{::vm.alert.alertLink}}"
                 data-track-id="GoToAlert"
				 data-track-type="Navigation"
				 ng-if="::(!vm.alert.clickSensitive && vm.entityPageLayout)"
                 ng-class="{'active-alerts-record-resolved' :  vm.alert.Status == 8}">
                  <span class="ellipsis">{{ vm.alert.Severity | severity }}</span>
              </a>


      <!-- Last activity time - queue-->
      <a class="col-xs-12 col-sm-2 col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding"
         ng-if="!vm.entityPageLayout"
         data-track-id="GoToAlert"
		 data-track-type="Navigation"
         ng-click="vm.selectRow(vm.alert, $event)"
         ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}"
         ng-href="{{::vm.alert.alertLink}}">
          <div class="alert-last-activity">
              <date ng-if="!vm.alert.isGroup || (vm.alert.collapsed)" src="vm.alert.LastEventTime"></date>
          </div>
      </a>

          <!-- First Seen - queue -->
          <a class="col-xs-12 col-sm-1 col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding overflow-all ellipsis"
             data-track-id="GoToAlert"
			 data-track-type="Navigation"
			 ng-click="vm.selectRow(vm.alert, $event)"
             ng-href="{{::vm.alert.alertLink}}"
             ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}"
             ng-if="!vm.entityPageLayout">
              <span ng-if="!vm.alert.isGroup || (vm.alert.collapsed)">
                  <span class="icon icon-Calendar"></span>
                  <span class="ellipsis">{{::vm.alert.FirstSeen | alertsFirstSeen}}</span>
              </span>
          </a>

          <!--Detection source- queue-->
          <span class="hidden-xs hidden-sm col-md-1 col-md-height col-middle alerts-queue-record-text ellipsis"
            ng-if="::!vm.entityPageLayout">
            {{ ::vm.alert.DetectionSource | alertDetectionSource }}
          </span>

          <!--Need to split groups and singles for ng-class perf on group open / close -->
          <!-- Status + classification - click (groups and machine timeline)-->
          <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding pointer"
             data-track-id="GoToAlert"
			 data-track-type="Navigation"
             ng-click="vm.selectRow(vm.alert, $event)"
             ng-if="::vm.alert.clickSensitive"
             ng-class="{'active-alerts-record-resolved' :  vm.entityPageLayout && vm.alert.Status == 8 }"
             ng-href="{{vm.alert.alertLink}}">
              <div ng-if="!vm.alert.isGroup || vm.alert.collapsed">{{ vm.alert.Status | status }}</div>
              <div ng-if="!vm.alert.isGroup || (vm.alert.Status != 'Multiple' && vm.alert.collapsed)" class="alert-group-subtitle">
                  <span ng-if="vm.alert.Classification">{{ vm.alert.Classification | alertClassification }}</span><span ng-if="vm.alert.Classification && vm.alert.Determination">: </span>
                  <div ng-if="vm.alert.Determination">{{ vm.alert.Determination | alertDetermination }}</div>
              </div>
          </a>

          <!-- Status + classification - no click (single alert in queue)-->
          <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding select-disabled-cursor"
             ng-if="::(!vm.alert.clickSensitive && !vm.entityPageLayout)"
             data-track-id="GoToAlert"
		 	 data-track-type="Navigation"
             ng-href="{{::vm.alert.alertLink}}">
              <div>{{ vm.alert.Status | status }}</div>
              <div class="alert-group-subtitle">
                  <span ng-if="vm.alert.Classification">{{ vm.alert.Classification | alertClassification }}</span><span ng-if="vm.alert.Classification && vm.alert.Determination">: </span>
                  <div ng-if="vm.alert.Determination">{{ vm.alert.Determination | alertDetermination }}</div>
              </div>
          </a>

          <!-- Status + classification - no click (single alert in entity page)-->
      <a class="hidden-xs hidden-sm col-md-1 col-sm-height col-lg-height col-middle alert-queue-record-text alert-queue-cell-padding select-disabled-cursor"
         ng-if="::(!vm.alert.clickSensitive && vm.entityPageLayout)"
         ng-class="{'active-alerts-record-resolved' :  vm.alert.Status == 8 }"
         data-track-id="GoToAlert"
		 data-track-type="Navigation"
         ng-href="{{vm.alert.alertLink}}">
          <div>{{ vm.alert.Status | status }}</div>
          <div class="alert-group-subtitle">
              <span ng-if="vm.alert.Classification">{{ vm.alert.Classification | alertClassification }}</span><span ng-if="vm.alert.Classification && vm.alert.Determination">: </span>
              <div ng-if="vm.alert.Determination">{{ vm.alert.Determination | alertDetermination }}</div>
          </div>
      </a>

  <!--    Investigation Status-->
      <div ng-if="vm.isAutoIRFeatureEnabled" class="col-xs-12 col-sm-1 col-md-1 col-sm-height col-middle alert-queue-record-text alert-queue-cell-padding align-center print-align-left alert-investigation-state-cell-display">
          <img ng-if="!vm.alert.ContainedAlerts && vm.alert.InvestigationState === vm.stateMap.Running" src="/assets/images/circle-loading.gif" style="width: 14px; height: 14px"/>
          <span class="" ng-if="::vm.alert.ContainedAlerts && vm.alert.InvestigationCount > 0">{{::vm.alert.InvestigationCount}} Investigations</span>
          <span class="" ng-if="::vm.alert.ContainedAlerts && !vm.alert.InvestigationCount"></span>
          <span ng-if="::!vm.alert.ContainedAlerts && vm.alert.InvestigationState">
            {{vm.investigationStateDisplay()}}
          </span>
          <span ng-if="::(vm.alert.ContainedAlerts && !vm.alert.InvestigationCount) || !vm.alert.InvestigationState">N/A</span>
		  <span class="space" ng-if="!vm.alert.ContainedAlerts && vm.alert.InvestigationState === vm.stateMap.Running">... </span>
		  <a ng-if="!vm.alert.ContainedAlerts && vm.alert.InvestigationState && vm.alert.InvestigationId"
              router-link="['/investigation/' + vm.alert.InvestigationId]"
              data-track-id="GoToInvestigation"
			  data-track-type="Navigation"
              href="{{'/investigation/' + vm.alert.InvestigationId}}">
		  <i class="icon icon-OpenInNewWindow color-semi-bold alignTop" tooltips tooltip-title="Open investigation page"></i>
		</a>
      </div>

      <!-- Assigned to -->
            <div class="col-xs-12  col-sm-1 col-sm-height col-lg-height col-middle alert-queue-cell-padding"
              ng-class="::{ 'col-md-2': vm.entityPageLayout }">
              <table class="alert-queue-menu-table" role="none">
                  <tr role="none">
                      <td class="hidden-sm col-middle" role="none"
                              ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}"
                              ng-click="vm.selectRow(vm.alert, $event)">
                              <div class="overflow-all alert-queue-assigned-to ellipsis pull-left"
                                   ng-class="{'active-alerts-record-resolved' :  vm.entityPageLayout && vm.alert.Status == 8}">
                                  <a class="alert-queue-record-text"
                                     data-track-id="GoToAlert"
				 					 data-track-type="Navigation"
                                     ng-href="{{vm.alert.alertLink}}"
                                     ng-class="::{'select-disabled-cursor' : !vm.alert.clickSensitive , 'pointer' : vm.alert.clickSensitive}">
                                      {{ vm.alert.assignedToUser || 'Not assigned'}}
                                  </a>
                              </div>

                          </td>
                      <td class="text-right alert-record-actions-menu pointer" role="none">
                          <div uib-dropdown class="alert-toolbox-btn btn-group pull-right">
                              <button type="button" uib-dropdown-toggle class="btn dropdown-toggle" data-toggle="dropdown" aria-expanded="false" aria-label="more options">
                                  <span tabindex="0" class="icon icon-More"></span>
                              </button>
                              <ul id="alert-dd-menu" class="dropdown-menu dropdown-menu-shadow" role="menu">
                                  <li><a href="javascript:void(0)"
                                  	data-track-id="ManageAlert"
				 					data-track-type="Navigation"
                                  	ng-click="vm.manageAlert(vm.alert, $event)"
                                  	ng-if="::!vm.alert.isGroup">Manage alert</a></li>
                                  <li><a href="javascript:void(0)"
                                  	data-track-id="ManageAlerts"
				 					data-track-type="Navigation"
                                  	ng-click="vm.manageAlert(vm.alert, $event)"
                                  	ng-if="::vm.alert.isGroup">Manage alerts</a></li>
                                  <li><a ng-if="::(!vm.alert.isGroup && vm.alert.MachineId && vm.showMachineContext)"
										data-track-id="GoToMachine"
										data-track-type="Navigation"
										href="{{::vm.getMachineLink(vm.alert)}}"
										ng-click="vm.selectMachine(vm.alert, $event)" stop-propagation>Go to device timeline</a></li>
                                  <li><a href="javascript:void(0)"
                                  	data-track-id="UnselectAlertOnTimeline"
									data-track-type="Action"
                                  	ng-click="vm.selectRow(vm.alert, $event)"
                                  	ng-if="vm.showAlertHighlight && vm.alert.highlighted">Deselect alert and unmark events</a></li>
                                  <li><a href="javascript:void(0)"
                                  	data-track-id="GoToAlert"
				 					data-track-type="SelectAlertOnTimeline"
                                  	ng-click="vm.selectRow(vm.alert, $event)"
                                  	ng-if="vm.showAlertHighlight && !vm.alert.highlighted">Select alert and mark events</a></li>
                              </ul>
                          </div>
                      </td>
                  </tr>
              </table>
          </div>
      </div>
  </div>
	`,
	controllerAs: 'vm',
	controller: 'seville.threatintel.alertrow',
}).controller('seville.threatintel.alertrow', alertRowController);

function alertRowController(
	$state,
	appConfig,
	featuresService: FeaturesService,
	machinesService: MachinesService,
	ng2router: HybridRoutingService,
	urlMapping
) {
	var vm = this;
	angular.extend(vm, {
		$onInit: activate,
		getColumnSize: getColumnSize,
	});

	function activate() {
		vm.baseUrl = appConfig.serviceUrls.threatIntel;
		vm.stateMap = urlMapping.investigationsStates;

		vm.isAllowedDisplayOfAccount = featuresService.isEnabled('User-Links');
		vm.isAutoIRFeatureEnabled = appConfig.isAutomatedIrEnabled;
		vm.investigationBaseLink = urlMapping.investigationsUrls.base.concat(
			urlMapping.investigationsUrls.relatives.investigation
		);

		// User links are not enabled for win7 machines
		// If no osPlatform received - allow user link for backward compatibility + most os platforms do support user links.
		const allowUserLinkByOsPlatform =
			!vm.alert.OsPlatform ||
			(vm.alert.OsPlatform.toLowerCase() !== 'windows7' &&
				vm.alert.OsPlatform.toLowerCase() !== 'linux' &&
				vm.alert.OsPlatform.toLowerCase() !== 'macos');

		// container alerts has no valid account link
		vm.showUserLinkAndSidePane =
			allowUserLinkByOsPlatform &&
			userAccountService.isAllowedAccount(
				vm.alert.AccountName,
				vm.alert.DomainName,
				vm.isAllowedDisplayOfAccount && !vm.alert.ContainerId
			);

		// for alerts with container - show containerId/User instead of Domain/User
		vm.alert.DomainOrContainerId = vm.alert.ContainerId ? vm.alert.ContainerId : vm.alert.DomainName;
		vm.alert.isThreatExpertOriginated = vm.alert.DetectionSource === THREAT_EXPERT_DETECTION_SOURCE_ID;

		vm.isEdge = SevilleUtils.detectEdge();
		vm.isIE = !vm.isEdge && SevilleUtils.detectIE();
	}

	vm.investigationStateDisplay = () => {
		const investigationState = vm.alert.InvestigationState;
		if (investigationState === vm.stateMap.UnsupportedOs) {
			return 'Unsupported OS';
		}

		return investigationState ? toTitleCamelCase(vm.stateMap[investigationState]) : '';
	};

	vm.getMachineLink = alert => {
		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			const alertTime = new Date(alert.LastEventTime || alert.LastSeen),
				alertId = alert.AlertId;
			return machinesService.getMachineLink(alert.MachineId, true, alertTime, alertId);
		} else {
			return $state.href('machine', {
				id: alert.MachineId,
				time: alert.LastEventTime || alert.LastSeen,
			});
		}
	};

	vm.selectMachine = function(alert, $event) {
		$event.preventDefault();
		$event.stopPropagation();

		if ($event.ctrlKey) {
			window.open(vm.getMachineLink(alert));
			return;
		}

		if (
			!featuresService.isEnabled(Feature.UpgradeMachinePage) &&
			alert.MachineId &&
			alert.MachineId.length > 0
		) {
			var state = $state.get('machine');
			if (!state.data) {
				state.data = {};
			}

			state.data.activeAlertId = alert.AlertId;
			state.data.activeAlertTime = alert.LastEventTime || alert.LastSeen;
			state.data.slider = new Date(state.data.activeAlertTime);
		}

		goToMachine(alert);
	};

	function goToMachine(alert) {
		if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
			ng2router.navigateByUrl(vm.getMachineLink(alert));
		} else {
			$state.go('machine', {
				id: alert.MachineId,
				time: alert.LastEventTime || alert.LastSeen,
			});
		}
	}

	vm.printJsonToDebug = function(ioa) {
		var item = { ioa: {} };

		angular.copy(ioa, item.ioa);

		console.dir(item);
	};

	// returns the class name according to the severity or actor of the alert.
	// should NOT be done inside ng-class statement (interpolations problem in ng class is a known issue)
	vm.getAlertSeverityColorOrActorClassName = function() {
		if (vm.alert.ActorName) {
			return 'alert-queue-actor-padding';
		}

		return ('attack-sev sev-' + vm.alert.Severity).toLowerCase();
	};

	function getColumnSize() {
		var result = '';
		result += vm.isAutoIRFeatureEnabled && appConfig.isAutomatedIrEnabled ? 'col-md-3' : 'col-md-4';
		result += ' ' + (vm.isEdge && 'alert-title-big');
		return result;
	}

	// create a string with words starting by capital letter and a space between each word.
	// for example - "unsupportedAlertType" -> "Unsupported Alert Type".
	function toTitleCamelCase(str) {
		return str
			? str.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
					return str.toUpperCase();
			  })
			: '';
	}
}
