<div *ngIf="events">
	<div *ngIf="showNumbers" class="wcd-flex-horizontal">
		<div *ngIf="compliantCount >= 0 && total > 0" class="wcd-flex-justify-end-horizontal wcd-font-weight-semibold text-align-left">
			{{compliantCount | prettyNumber}}
			<span class="color-text-gray-400">
				/ {{total | prettyNumber}}
			</span>
		</div>
		<div *ngIf="showPercentage && percentage" class="wcd-margin-xsmall-left">
			{{percentage}}
		</div>
	</div>
	<events-summary-bars
		class="events-summary-bars wcd-margin-xsmall-top wcd-flex-auto"
		[events]="events"
		[kindColorMap]="colorMap"
		[displayTextFormatter]="textFormatter"
		[size]="size"
	>
	</events-summary-bars>
</div>
<div *ngIf="showLegend && events" class="wcd-padding-mediumSmall-top">
	<span *ngFor="let legendItem of events" class="wcd-inline-block wcd-padding-small-right">
		<fab-icon
			iconName="SquareShapeSolid"
			contentClass="wcd-vertical-align-bottom {{ legendItem.iconClassName }}"
		></fab-icon>
		{{ legendItem.name }}
	</span>
</div>
