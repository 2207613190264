import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { ImportTiIndicatorResult } from './custom-ti-indicator-import-result.value.object';
import { ImportTiIndicators } from './custom-ti-indicator-import.value.object';

const filePropertyName: string = 'file';

@ApiCall({
	name: 'Custom TI indicator import',
	endpoint: 'ti/import/csv',
	method: 'POST',
	type: ImportTiIndicatorResult,
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: (importTiIndicators: ImportTiIndicators): HttpOptions<FormData> => {
		const formData = new FormData();

		if (importTiIndicators[filePropertyName]) {
			formData.append(filePropertyName, importTiIndicators[filePropertyName]);
		}

		return {
			data: formData,
			params: { type: importTiIndicators.type },
		};
	},
})
export class ImportTiIndicatorsApiCall extends ApiCallModel<
	Array<ImportTiIndicatorResult>,
	ImportTiIndicators
> {}
