import { Component, Input, OnInit } from '@angular/core';
import { Dictionary } from '@wcd/config';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

type CancelReason = { id: number; name: string; requireText?: boolean };

@Component({
	selector: 'cancel-investigation',
	templateUrl: './cancel-investigation.modal.html',
})
export class CancelInvestigationModalComponent extends ModalContainer implements OnInit {
	@Input() investigationCount: number;
	@Input() reasonsType: string = 'investigation';
	@Input() hostName: string;
	@Input() confirm: (confirmData: { reason?: number; comment: string }) => any;
	@Input() cancel: Function;

	selectedReasonName: string = '';
	selectedReason: CancelReason;
	comment: string;
	cancelReasons: ReadonlyArray<CancelReason>;
	cancelReasonsDictionary: Dictionary<string, CancelReason>;

	constructor(protected router: Router, public i18nService: I18nService) {
		super(router);
	}

	private investigationCancelReasons: ReadonlyArray<CancelReason> = Object.freeze([
		{ id: 23, name: this.i18nService.strings.investigation_cancel_modal_reason_skipDevice },
		{ id: 24, name: this.i18nService.strings.investigation_cancel_modal_reason_duplicateInvestigation },
		{ id: 25, name: this.i18nService.strings.investigation_cancel_modal_reason_deviceRemoved },
		{ id: 26, name: this.i18nService.strings.investigation_cancel_modal_reason_falsePositive },
		{ id: 27, name: this.i18nService.strings.investigation_cancel_modal_reason_malfunction },
		{ id: 28, name: this.i18nService.strings.investigation_cancel_modal_reason_other, requireText: true },
	]);

	private hostInvestigationCancelReasons: ReadonlyArray<CancelReason> = Object.freeze([
		{ id: 33, name: "Microsoft Defender for Endpoint shouldn't analyze device (:hostName)" },
		{ id: 35, name: 'Device (:hostName) was removed from the network' },
		{ id: 37, name: 'This is a duplicated Investigation on device (:hostName)' },
		{ id: 38, name: '(Other)', requireText: true },
	]);

	ngOnInit() {
		this.selectedReasonName = '';
		this.cancelReasons =
			this.reasonsType === 'host' ? this.getHostCancelReasons() : this.investigationCancelReasons;
		this.cancelReasonsDictionary = Dictionary.fromList<string, CancelReason>(this.cancelReasons, 'name');
	}

	setCurrentSelectedReason() {
		this.selectedReason = this.cancelReasonsDictionary.get(this.selectedReasonName);
	}

	submitCancelInvestigation() {
		this.confirm({
			reason: this.selectedReason.id,
			comment: this.selectedReason.requireText ? this.comment : this.selectedReason.name,
		});
	}

	close() {
		this.cancel();
	}

	private getHostCancelReasons(): ReadonlyArray<CancelReason> {
		const hostCancelReasons = cloneDeep(this.hostInvestigationCancelReasons);
		hostCancelReasons.forEach(
			cancelReason => (cancelReason.name = cancelReason.name.replace('(:hostName)', this.hostName))
		);
		return Object.freeze(hostCancelReasons);
	}
}
