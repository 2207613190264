import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { SendFeedbackApiCall } from '@wcd/domain';
import { Router } from '@angular/router';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'wcd-feedback-modal',
	template: `
		<modal (close)="destroy()" [settings]="settings" data-html2canvas-ignore>
			<wcd-feedback
				[isEmailOptional]="true"
				i18nType="feedback."
				[submitApiCall]="submitApiCall"
				(done)="onDone()"
			></wcd-feedback>
		</modal>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeedbackModalComponent extends ModalContainer {
	readonly submitApiCall = SendFeedbackApiCall;

	constructor(router: Router, private dialogsService: DialogsService, private i18nService: I18nService) {
		super(router);
	}

	onDone() {
		this.dialogsService
			.confirm({
				title: this.i18nService.get('feedback.confirm.title'),
				text: this.i18nService.get('feedback.confirm.text'),
				showConfirm: false,
				cancelText: this.i18nService.get('feedback.confirm.close'),
			})
			.then(() => {
				this.closeModal();
			});
	}
}
