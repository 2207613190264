import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Service',
	pluralName: 'Services',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsService,
})
export class AirsService extends AirsEntity {
	@EntityField({ data: 'bin_path' })
	binPath: string;

	@EntityField({ data: 'start_type' })
	startType: string;

	@EntityField({ data: 'args' })
	args: string;

	@EntityField({ data: 'flags' })
	flags: string;

	@EntityField({ data: 'process_id' })
	processId: number;

	@EntityField({ data: 'username' })
	username: string;
}
