import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
	selector: '[autofocus]',
})
export class AutofocusDirective implements AfterViewInit {
	@Input() autofocus: boolean;

	constructor(private el: ElementRef) {}

	ngAfterViewInit() {
		if (this.autofocus !== false) this.el.nativeElement.focus && this.el.nativeElement.focus();
	}
}
