import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'RbacGroups',
	pluralName: 'RbacGroups',
})
export class RbacGroups extends ModelBase {
	@EntityField({
		data: 'value',
	})
	readonly value: number[];
}
