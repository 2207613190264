import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class AuthenticatedGuard implements CanActivate {
	constructor(
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private router: Router
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (this.authService.isExecutiveReport) return true;

		if (this.appConfigService.isSuspended || this.appConfigService.isDeleted) {
			if (this.appConfigService.isOnboardingComplete) {
				this.router.navigate(['/suspended/offboarding']);
			} else {
				this.router.navigate(['/Error/NoLicense']);
			}
			return false;
		}

		return !!this.authService.currentUser;
	}
}
