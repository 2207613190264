<ng-container *ngIf="(data$ | async) as data" >
	<div class="wcd-m365-typography wcd-full-height"
		 *ngIf="data.showFileVerdict; else noData">
		<ng-container>
			<ng-container *ngIf="data.showFileVerdict">
				<h4>
					{{ 'file.malwareDetection' | i18n }}
				</h4>
				<file-detections-summary [file]="data.file" class="wcd-font-size-s color-text-gray-700 wcd-font-weight-bold"></file-detections-summary>
			</ng-container>
		</ng-container>
	</div>
	<ng-template #noData>
		<h4>{{ 'common.noDataAvailable' | i18n }}</h4>
	</ng-template>
</ng-container>
