<dl class="wcd-flex-1 wcd-scroll-vertical" role="none">
	<ng-container>
		<dt role="none">{{ 'incident.details.incidentTags' | i18n }}</dt>
		<dd role="none">
			<tags-list [multiline]="true" [tags]="incidentTags"></tags-list>
		</dd>
	</ng-container>

	<ng-container *ngIf="withDataSensitivity && (apiData.dataClassifications.tags$ | async) as dataClassifications">
		<dt role="none">{{ 'incident.overview.tags.dataSensitivity' | i18n }}</dt>
		<dd role="none">
			<tags-list [multiline]="true" [tags]="dataClassifications"></tags-list>
		</dd>
	</ng-container>

	<ng-container *ngIf="(apiData.machineGroups.tags$ | async) as machineGroups">
		<dt role="none">{{ 'incident.overview.tags.machineGroups' | i18n }}</dt>
		<dd role="none">
			<tags-list [multiline]="true" [tags]="machineGroups"></tags-list>
		</dd>
	</ng-container>

	<ng-container *ngIf="isMtp && (apiData.userGroups.tags$ | async) as userGroups">
		<dt role="none">{{ 'incident.overview.tags.userGroups' | i18n }}</dt>
		<dd role="none">
			<tags-list [multiline]="true" [tags]="userGroups"></tags-list>
		</dd>
	</ng-container>
</dl>
