<div class="wcd-full-height wcd-flex-vertical">
    <div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all wcd-margin-medium-left">
        <section class="wcd-padding-bottom">
            <h4 class="definition-list-title wcd-font-weight-bold">
				{{ "reporting.webContentFilteringReport.pivots.commonPivots.sections.accessTrend.title" | i18n}}
            </h4>
            <div class="wcd-margin-small-bottom">
                <span>
                    {{"reporting.webContentFilteringReport.pivots.commonPivots.sections.accessTrend.webCategorydescription" | i18n:{timeRange: timeRange} }}{{ timeRange > 1 ? 's': '' }}
                </span>
            </div>
           <ng-container *ngIf="report.isDeltaPercentageValid && report.trendsDeltaPercentage !== 0; else invalidData">
                <fab-icon
                    iconName="{{
                        report.trendsDeltaPercentage > 0 ? 'StockUp' : 'StockDown'
                    }}"
                    contentClass="ms-color-blue"
                >
                </fab-icon>
                <span class="wcd-margin-xsmall-left">
                    {{ trendsDeltaPercentageAsAbs }}% {{ 'reporting.webContentFilteringReport.pivots.commonPivots.sections.accessTrend.tooltipDescription' | i18n }}
                </span>
            </ng-container>
            <ng-template #invalidData>
                <span class="wcd-margin-xsmall-left">{{
                    'reporting.webContentFilteringReport.topParentCategories.' +
                        (report.isDeltaPercentageValid ? 'noChange' : 'invalidData')
                        | i18n
                }}</span>
            </ng-template>
            <ng-container *ngIf="report.accessTrends?.length">
                <web-content-filtering-access-trend-over-time-chart
                    [entity]="report"
                >
                </web-content-filtering-access-trend-over-time-chart>
            </ng-container>
        </section>
        <section class="wcd-padding-bottom">
            <h4 class="definition-list-title wcd-font-weight-bold">
                {{ "reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.title" | i18n}}
            </h4>
            <div class="wcd-width-full">
                <wcd-datatable
                    [items]="report.topDomains"
                    [columns]="topDomainsFields"
					[selectEnabled]="false"
					[label]="'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.title' | i18n"
                ></wcd-datatable>
            </div>
        </section>
        <section class="wcd-padding-bottom">
            <h4 class="definition-list-title wcd-font-weight-bold">
                {{ "reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.title" | i18n}}
            </h4>
            <div class="wcd-width-full">
                <wcd-datatable
                    [items]="report.topDevices"
                    [columns]="topDevicesFields"
					[selectEnabled]="false"
					[label]="'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.title' | i18n"
                ></wcd-datatable>
            </div>
        </section>
        <section class="wcd-padding-bottom">
            <h4 class="definition-list-title wcd-font-weight-bold">
                {{ "reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.title" | i18n}}
            </h4>
            <div *ngIf="!loadingPolicies" class="wcd-width-full">
                <wcd-datatable
                    [items]="relatedPolicies"
                    [columns]="relatedPoliciesFields"
					[selectEnabled]="false"
					[label]="'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.title' | i18n"
                ></wcd-datatable>
            </div>
        </section>
    </div>
</div>
