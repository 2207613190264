<div *ngIf="_trendSettings && _trend && _trend.length > 0">
	<div class="trend__metadata wcd-flex-baseline"
		*ngIf="_tvmHistoryTrendOptions.showAdvancement || _tvmHistoryTrendOptions.showNumberOfDays || _tvmHistoryTrendOptions.title">
		<div *ngIf="_tvmHistoryTrendOptions.title" class="wcd-font-weight-bold">
			{{ _tvmHistoryTrendOptions.title }}
		</div>
		<div *ngIf="_tvmHistoryTrendOptions.advancement.show && _advancement" class="advancement wcd-flex-baseline">
			<fab-icon [iconName]="_advancement.iconName" [contentClass]="_advancement.contentClass">
			</fab-icon>
			<div class="ms-color-gray140 wcd-font-size-s">
				{{ _advancement.advancement }}
			</div>
		</div>
		<div *ngIf="_tvmHistoryTrendOptions.showNumberOfDays"
			class="numberOfDays ms-color-gray140 wcd-font-size-mi">
			{{ 'tvm.historyTrends.daysAnnotation' | i18n : { days: _numberOfDays } }}
		</div>
	</div>
	<div *ngIf="_trendSettings && _trend">
		<wcd-time-series-chart [settings]="_trendSettings" [data]="_trend"> </wcd-time-series-chart>
	</div>
</div>
