var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';
import { DirectoryType } from './directory-type.entity';
import { directoryTypes } from './directory-type.values';
import { keyBy } from 'lodash-es';
import { MailClusterUtils } from '../mailbox/mail-cluster.utils';
import { dateStrToUtc } from '../utils';
var dirTypesByType = keyBy(directoryTypes, 'type');
var ɵ0 = function (folderType) {
    var folderTypeId = folderType && folderType.toLowerCase();
    return dirTypesByType[folderTypeId];
}, ɵ1 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ2 = ['SenderFromMailAddress', 'Sender'], ɵ3 = ['RecipientEmailAddress', 'Recipient'];
var ActionHistoryRelatedEntity = /** @class */ (function (_super) {
    __extends(ActionHistoryRelatedEntity, _super);
    function ActionHistoryRelatedEntity(props) {
        var _this = _super.call(this, props) || this;
        _this.displayQuery = MailClusterUtils.getDisplayQuery(_this.clusteredBy, _this.clusteredByValue);
        return _this;
    }
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", AirsEntityTypeConfig)
    ], ActionHistoryRelatedEntity.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'EntityId' }),
        __metadata("design:type", Object)
    ], ActionHistoryRelatedEntity.prototype, "entityId", void 0);
    __decorate([
        EntityField({ data: 'SHA1' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "sha1", void 0);
    __decorate([
        EntityField({ data: 'SHA256' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "sha256", void 0);
    __decorate([
        EntityField({ data: 'FileName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "fileName", void 0);
    __decorate([
        EntityField({ data: 'FolderPath' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "folderPath", void 0);
    __decorate([
        EntityField({
            data: 'FolderType',
            parse: ɵ0,
        }),
        __metadata("design:type", DirectoryType)
    ], ActionHistoryRelatedEntity.prototype, "folderType", void 0);
    __decorate([
        EntityField({ data: 'CompanyName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "companyName", void 0);
    __decorate([
        EntityField({ data: 'FileSizeBytes' }),
        __metadata("design:type", Number)
    ], ActionHistoryRelatedEntity.prototype, "fileSize", void 0);
    __decorate([
        EntityField({ data: 'IsSigned' }),
        __metadata("design:type", Boolean)
    ], ActionHistoryRelatedEntity.prototype, "isSigned", void 0);
    __decorate([
        EntityField({ data: 'ProcessId' }),
        __metadata("design:type", Number)
    ], ActionHistoryRelatedEntity.prototype, "processId", void 0);
    __decorate([
        EntityField({ data: 'ProcessCommandLine' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "processCommandLine", void 0);
    __decorate([
        EntityField({ data: 'ProcessCreationTime', parse: ɵ1 }),
        __metadata("design:type", Date)
    ], ActionHistoryRelatedEntity.prototype, "processCreationTime", void 0);
    __decorate([
        EntityField({ data: 'ServiceName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "serviceName", void 0);
    __decorate([
        EntityField({ data: 'ServiceDisplayName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "serviceDisplayName", void 0);
    __decorate([
        EntityField({ data: 'ServiceBinPath' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "serviceBinPath", void 0);
    __decorate([
        EntityField({ data: 'DriverName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "driverName", void 0);
    __decorate([
        EntityField({ data: 'DriverDisplayName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "driverDisplayName", void 0);
    __decorate([
        EntityField({ data: 'DriverBinPath' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "driverBinPath", void 0);
    __decorate([
        EntityField({ data: 'DriverType' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "driverType", void 0);
    __decorate([
        EntityField({ data: 'MethodName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "methodName", void 0);
    __decorate([
        EntityField({ data: 'MethodCommandLine' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "methodCommandLine", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'NTDomain' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "ntDomain", void 0);
    __decorate([
        EntityField({ data: 'DnsDomain' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "dnsDomain", void 0);
    __decorate([
        EntityField({ data: 'UPNSuffix' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "upnSuffix", void 0);
    __decorate([
        EntityField({ data: 'AadTenantId' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "aadTenantId", void 0);
    __decorate([
        EntityField({ data: 'IsDomainJoined' }),
        __metadata("design:type", Boolean)
    ], ActionHistoryRelatedEntity.prototype, "isDomainJoined", void 0);
    __decorate([
        EntityField({ data: 'PUID' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "puid", void 0);
    __decorate([
        EntityField({ data: 'AadUserId' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "aadUserId", void 0);
    __decorate([
        EntityField({ data: 'DisplayName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "userDisplayName", void 0);
    __decorate([
        EntityField({ data: 'FriendlyName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "friendlyName", void 0);
    __decorate([
        EntityField({ data: 'UniqueName' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "uniqueName", void 0);
    __decorate([
        EntityField({ data: 'Email' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "email", void 0);
    __decorate([
        EntityField({ data: 'Sid' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "sid", void 0);
    __decorate([
        EntityField({ data: 'ReceivedDate' }),
        __metadata("design:type", Date)
    ], ActionHistoryRelatedEntity.prototype, "receivedDate", void 0);
    __decorate([
        EntityField({ data: 'DeliveryAction' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "deliveryAction", void 0);
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "senderFromMailAddress", void 0);
    __decorate([
        EntityField({ data: 'P1Sender' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "p1SenderFromMailAddress", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "recipientEmailAddress", void 0);
    __decorate([
        EntityField({ data: 'ClusterBy' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "clusteredBy", void 0);
    __decorate([
        EntityField({ data: 'ClusterByValue' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "clusteredByValue", void 0);
    __decorate([
        EntityField({ data: 'MailboxPrimaryAddress' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "mailboxPrimaryAddress", void 0);
    __decorate([
        EntityField({ data: 'Url' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "url", void 0);
    __decorate([
        EntityField({ data: 'NetworkMessageId' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "networkMessageId", void 0);
    __decorate([
        EntityField({ data: 'Query' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "clusterQueryString", void 0);
    __decorate([
        EntityField({ data: 'QueryStartTime' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "clusterQueryStartTime", void 0);
    __decorate([
        EntityField({ data: 'QueryTime' }),
        __metadata("design:type", String)
    ], ActionHistoryRelatedEntity.prototype, "clusterQueryTime", void 0);
    ActionHistoryRelatedEntity = __decorate([
        ValueObject({
            singularName: 'Entity',
            pluralName: 'Entities',
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], ActionHistoryRelatedEntity);
    return ActionHistoryRelatedEntity;
}(ModelBase));
export { ActionHistoryRelatedEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
