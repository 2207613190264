/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./airs-notification-rule-edit.component.ngfactory";
import * as i5 from "./airs-notification-rule-edit.component";
import * as i6 from "../services/airs-notification-rules.store";
import * as i7 from "../services/airs-notification-rules-reports.store";
import * as i8 from "../services/airs-notification-rule-condition-groups.store";
import * as i9 from "./airs-notification-rule-edit.panel.component";
import * as i10 from "@angular/router";
var styles_AirsNotificationRuleEditPanelComponent = [];
var RenderType_AirsNotificationRuleEditPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AirsNotificationRuleEditPanelComponent, data: {} });
export { RenderType_AirsNotificationRuleEditPanelComponent as RenderType_AirsNotificationRuleEditPanelComponent };
export function View_AirsNotificationRuleEditPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "airs-notification-rule-edit", [], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_AirsNotificationRuleEditComponent_0, i4.RenderType_AirsNotificationRuleEditComponent)), i0.ɵdid(6, 114688, null, 0, i5.AirsNotificationRuleEditComponent, [i6.AirsNotificationRulesStore, i7.AirsNotificationRulesReportsStore, i8.AirsNotificationRuleConditionGroupsStore], { notificationRule: [0, "notificationRule"] }, { save: "save", cancel: "cancel" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.notificationRule; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AirsNotificationRuleEditPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "airs-notification-rule-edit-panel", [], null, null, null, View_AirsNotificationRuleEditPanelComponent_0, RenderType_AirsNotificationRuleEditPanelComponent)), i0.ɵdid(1, 245760, null, 0, i9.AirsNotificationRuleEditPanelComponent, [i10.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AirsNotificationRuleEditPanelComponentNgFactory = i0.ɵccf("airs-notification-rule-edit-panel", i9.AirsNotificationRuleEditPanelComponent, View_AirsNotificationRuleEditPanelComponent_Host_0, { settings: "settings", onDone: "onDone", onCancel: "onCancel", notificationRule: "notificationRule" }, {}, []);
export { AirsNotificationRuleEditPanelComponentNgFactory as AirsNotificationRuleEditPanelComponentNgFactory };
