import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FilterValuesCheckListConfig } from '@wcd/ng-filters';
import {
	WebContentFilteringCategoryType,
	WebContentFilteringCategoryTypes,
	WebContentFilteringParentCategoryType,
	WebContentFilteringPolicy,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ChecklistValue } from '@wcd/forms';
import { Feature, FeaturesService } from '@wcd/config';

export enum ScopeTypesEnum {
	All = 0,
	Specific = 1,
}

@Injectable()
export class WebContentFilteringPolicyService {
	private _categoryList: Array<WebContentFilteringCategoryType>;
	private _parentCategoryList: Array<WebContentFilteringParentCategoryType>;
	private _categoryChecklistConfig: FilterValuesCheckListConfig<string>;

	selectableMachineGroupScopes: Array<ChecklistValue> = [
		{
			id: ScopeTypesEnum.All,
			name: this.i18nService.get(
				'webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.values.allmachines'
			),
		},
		{
			id: ScopeTypesEnum.Specific,
			name: this.i18nService.get(
				'webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.values.selectedgroups'
			),
		},
	];

	constructor(
		private i18nService: I18nService,
		private paris: Paris,
		private featureService: FeaturesService
	) {
		const newUncatFlowEnabled = this.featureService.isEnabled(
			Feature.EnableWebContentFilterUncategorizedV2
		);
		this._categoryList = this.paris
			.getRepository(WebContentFilteringCategoryType)
			.entity.values.filter((c) => this.filterCategory(c));
		this._parentCategoryList = this.paris.getRepository(
			WebContentFilteringParentCategoryType
		).entity.values;
	}

	getSelectableMachineGroupScopes(): Array<ChecklistValue> {
		return this.selectableMachineGroupScopes;
	}

	getCategoryChecklistConfig(): FilterValuesCheckListConfig<string> {
		if (!this._categoryChecklistConfig) {
			// tslint:disable-next-line:prefer-const
			var groups = this._categoryList.reduce(
				(
					r: { [key: number]: WebContentFilteringCategoryType[] },
					a: WebContentFilteringCategoryType
				) => {
					r[a.type] = r[a.type] || [];
					r[a.type].push(a);

					return r;
				},
				Object.create(null)
			);

			this._categoryChecklistConfig = {
				allowSingleValueDeselection: true,
				allowUnknownValues: false,
				values: this._parentCategoryList.map(
					(parentCategory: WebContentFilteringParentCategoryType) => {
						return {
							value: parentCategory.id,
							name: this.i18nService.get(
								`webCategories.parentCategories.${parentCategory.name}`
							),
							count: null,
							children: groups[parentCategory.id].map(
								(category: WebContentFilteringCategoryType) => {
									return {
										value: category.id,
										name: this.i18nService.get(
											`webCategories.categories.${category.name}`
										),
										count: null,
									};
								}
							),
						};
					}
				),
			};
		}

		return this._categoryChecklistConfig;
	}

	getCategoryNames(policy: WebContentFilteringPolicy): string[] {
		return this._categoryList
			.filter((cat) => policy.blockedCategoryIds.includes(cat.id))
			.map((cat) => this.i18nService.get(`webCategories.categories.${cat.name}`));
	}

	filterCategory(category: WebContentFilteringCategoryType): boolean {
		const newUncatFlowEnabled = this.featureService.isEnabled(
			Feature.EnableWebContentFilterUncategorizedV2
		);
		if (newUncatFlowEnabled) {
			if (
				category.id == WebContentFilteringCategoryTypes.NewlyRegisteredDomains ||
				category.id == WebContentFilteringCategoryTypes.ParkedDomains
			) {
				category.isSupported = true;
				return true;
			} else if (category.id == WebContentFilteringCategoryTypes.Unknown) {
				category.isSupported = false;
				return false;
			} else {
				return category.isSupported;
			}
		} else {
			return category.isSupported;
		}
	}
}
