import { EntityField, ModelBase, ValueObject, Entity } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';

export enum MitigationLevel {
	Partial,
	Full,
}

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Mitigated vulnerability',
	pluralName: 'Mitigated vulnerabilities',
	endpoint: 'mitigatedVulnerability',
	readonly: true,
})
export class MitigatedVulnerability extends ModelBase {
	@EntityField({
		required: true,
	})
	readonly name: string;

	@EntityField({
		required: true,
	})
	countOfMitigatedAssets: number;

	@EntityField({
		required: true,
	})
	countOfNonMitigatedAssets: number;

	@EntityField({
		required: true,
	})
	mitigationLevel: MitigationLevel;

	@EntityField() mitigationDescription: String;
}
