/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../global_entities/components/entity-details/baseline-configuration.entity-details.component.ngfactory";
import * as i2 from "../../../../../../global_entities/components/entity-details/baseline-configuration.entity-details.component";
import * as i3 from "../../../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../../../../tvm/services/baseline-detected-value.service";
import * as i5 from "../../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i7 from "../../../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "./configurations-devices/profile-configuration-devices.component.ngfactory";
import * as i10 from "./configurations-devices/profile-configuration-devices.component";
import * as i11 from "../services/baseline-configuration.fields.service";
import * as i12 from "../../../../../../tvm/services/tvm-download.service";
import * as i13 from "@microsoft/paris/dist/lib/paris";
import * as i14 from "./configurations-devices/general-configuration-devices.component.ngfactory";
import * as i15 from "./configurations-devices/general-configuration-devices.component";
import * as i16 from "@angular/common";
import * as i17 from "../../../../../../shared/components/tabs/tabs.component.ngfactory";
import * as i18 from "../../../../../../shared/components/tabs/tabs.component";
import * as i19 from "@angular/router";
import * as i20 from "./profile-configuration.entity-panel.component";
var styles_ProfileConfigurationEntityPanelComponent = [];
var RenderType_ProfileConfigurationEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileConfigurationEntityPanelComponent, data: {} });
export { RenderType_ProfileConfigurationEntityPanelComponent as RenderType_ProfileConfigurationEntityPanelComponent };
function View_ProfileConfigurationEntityPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "baseline-configuration-entity-details", [], null, null, null, i1.View_BaselineConfigurationEntityDetailsComponent_0, i1.RenderType_BaselineConfigurationEntityDetailsComponent)), i0.ɵdid(3, 245760, null, 0, i2.BaselineConfigurationEntityDetailsComponent, [i3.ActivatedEntity, i4.BaselineDetectedValuesService, i5.I18nService, i0.Injector], { configuration: [0, "configuration"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration; _ck(_v, 3, 0, currVal_0); }, null); }
function View_ProfileConfigurationEntityPanelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-datatable", [["class", ""]], null, null, null, i6.View_DataTableComponent_0, i6.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, null, 0, i7.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i8.LiveAnnouncer, i5.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fluidHeaderWidth: [3, "fluidHeaderWidth"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.applicableProfiles; var currVal_1 = false; var currVal_2 = _co.applicableProfilesFields; var currVal_3 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_ProfileConfigurationEntityPanelComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "profile-configuration-devices", [], null, null, null, i9.View_ProfileConfigurationDevicesComponent_0, i9.RenderType_ProfileConfigurationDevicesComponent)), i0.ɵdid(1, 114688, null, 0, i10.ProfileConfigurationDevicesComponent, [i11.BaselineConfigurationFieldsService, i12.TvmDownloadService, i4.BaselineDetectedValuesService, i13.Paris], { configuration: [0, "configuration"], profileName: [1, "profileName"], profileId: [2, "profileId"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration; var currVal_1 = _co.profileName; var currVal_2 = _co.profileId; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_ProfileConfigurationEntityPanelComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "general-configuration-devices", [], null, null, null, i14.View_GeneralConfigurationDevicesComponent_0, i14.RenderType_GeneralConfigurationDevicesComponent)), i0.ɵdid(2, 114688, null, 0, i15.GeneralConfigurationDevicesComponent, [i11.BaselineConfigurationFieldsService, i12.TvmDownloadService, i4.BaselineDetectedValuesService, i13.Paris], { configuration: [0, "configuration"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration; _ck(_v, 2, 0, currVal_0); }, null); }
function View_ProfileConfigurationEntityPanelComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileConfigurationEntityPanelComponent_5)), i0.ɵdid(3, 16384, null, 0, i16.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["generalConfiguration", 2]], null, 0, null, View_ProfileConfigurationEntityPanelComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isProfileConfiguration; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ProfileConfigurationEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 18, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "tabs", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.setTab($event.tab) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i17.View_TabsComponent_0, i17.RenderType_TabsComponent)), i0.ɵdid(3, 4833280, null, 0, i18.TabsComponent, [i0.ChangeDetectorRef, i19.ActivatedRoute, i19.Router], { tabsData: [0, "tabsData"] }, { select: "select" }), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 11, "div", [["class", "wcd-flex-vertical"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i16.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileConfigurationEntityPanelComponent_2)), i0.ɵdid(10, 278528, null, 0, i16.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i16.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileConfigurationEntityPanelComponent_3)), i0.ɵdid(13, 278528, null, 0, i16.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i16.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileConfigurationEntityPanelComponent_4)), i0.ɵdid(16, 278528, null, 0, i16.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i16.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.currentTab.id; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.tabIds.configurationDetails; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.tabIds.applicableProfiles; _ck(_v, 13, 0, currVal_3); var currVal_4 = _co.tabIds.devices; _ck(_v, 16, 0, currVal_4); }, null); }
function View_ProfileConfigurationEntityPanelComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "baseline-configuration-entity-details", [], null, null, null, i1.View_BaselineConfigurationEntityDetailsComponent_0, i1.RenderType_BaselineConfigurationEntityDetailsComponent)), i0.ɵdid(4, 245760, null, 0, i2.BaselineConfigurationEntityDetailsComponent, [i3.ActivatedEntity, i4.BaselineDetectedValuesService, i5.I18nService, i0.Injector], { configuration: [0, "configuration"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.configuration; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_ProfileConfigurationEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileConfigurationEntityPanelComponent_1)), i0.ɵdid(1, 16384, null, 0, i16.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(0, [["singleAsset", 2]], null, 0, null, View_ProfileConfigurationEntityPanelComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.singleAssetPanel; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_ProfileConfigurationEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "profile-configuration-entity-panel", [], null, null, null, View_ProfileConfigurationEntityPanelComponent_0, RenderType_ProfileConfigurationEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i20.ProfileConfigurationEntityPanelComponent, [i5.I18nService, i3.ActivatedEntity, i0.ChangeDetectorRef, i13.Paris, i19.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileConfigurationEntityPanelComponentNgFactory = i0.ɵccf("profile-configuration-entity-panel", i20.ProfileConfigurationEntityPanelComponent, View_ProfileConfigurationEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { ProfileConfigurationEntityPanelComponentNgFactory as ProfileConfigurationEntityPanelComponentNgFactory };
