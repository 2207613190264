var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Alert, AlertCategory, AlertClassification, AlertClassificationId, alertClassificationValues, alertDeterminationValues, AlertHistoryItem, AlertStatus, InvestigationStatus, Outbreak, ServiceSourceType, Severity, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { combineLatest, from, of, zip } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '@wcd/auth';
import { ServiceUrlsService } from '@wcd/app-config';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { MachinesFiltersService, } from '../../machines/services/machines.filters.service';
import { find, isNil, omit, values } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../../rbac/services/rbac.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { SourceFilterService } from '../../common/services/source-filter.service';
import * as FileSaver from 'file-saver';
import { sccHostService } from '@wcd/scc-interface';
import { DropdownMenuItemType } from 'office-ui-fabric-react';
export function routeToLegacyAlertRoute(route) {
    var id = route.params.id;
    return ['alert', id];
}
var IncidentEntityType = 'IncidentEntity';
var AlertsService = /** @class */ (function () {
    function AlertsService(dialogsService, paris, http, authService, machinesFiltersService, serviceUrlsService, featuresService, i18nService, rbacService, entityPanelsService, sourceFilterService) {
        var _this = this;
        this.dialogsService = dialogsService;
        this.paris = paris;
        this.http = http;
        this.authService = authService;
        this.machinesFiltersService = machinesFiltersService;
        this.serviceUrlsService = serviceUrlsService;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.rbacService = rbacService;
        this.entityPanelsService = entityPanelsService;
        this.sourceFilterService = sourceFilterService;
        this.isIncidentEntity = function (entityType) { return entityType === IncidentEntityType; };
        this.alertHistoryMessageMap = {
            Feedback: function (historyItem) { return historyItem.newValue; },
            AssignedTo: function (historyItem) {
                var entityType = _this.i18nService.get(_this.isIncidentEntity(historyItem.entityType)
                    ? 'incident_entityType_singularName'
                    : 'alert_entityType_singularName');
                return historyItem.newValue
                    ? _this.i18nService.get('alert_history_change_assignedTo_message', {
                        entityType: entityType,
                        value: historyItem.newValue,
                    })
                    : _this.i18nService.get('alert_history_assignedTo_removed', { entityType: entityType });
            },
            Unassign: function (historyItem) { return historyItem.newValue; },
            Suppressed: function (historyItem) {
                return _this.i18nService.get('alert_history_suppressed_rule', {
                    rule: historyItem.newValue,
                });
            },
            Status: function (historyItem) {
                if (!historyItem.oldValue) {
                    return historyItem.newValue;
                }
                // TODO: This is a temporary solution till BE will send status name in newValue and oldValue
                var incidentStatus = {
                    '1': _this.i18nService.get('alert_history_incident_status_active'),
                    '2': _this.i18nService.get('alert_history_incident_status_resolved'),
                    '4': _this.i18nService.get('alert_history_incident_status_inProgress'),
                };
                var toStatus = _this.isIncidentEntity(historyItem.entityType)
                    ? incidentStatus[historyItem.newValue]
                    : historyItem.newStatus
                        ? _this.i18nService.get(historyItem.newStatus.nameI18nKey)
                        : '';
                var fromText = _this.i18nService.get('alert_history_from');
                var fromStatus = _this.isIncidentEntity(historyItem.entityType)
                    ? historyItem.oldValue
                        ? " " + fromText + " '" + incidentStatus[historyItem.oldValue] + "'"
                        : ''
                    : historyItem.oldStatus
                        ? " " + fromText + " '" + _this.i18nService.get(historyItem.oldStatus.nameI18nKey) + "'"
                        : '';
                return _this.i18nService.get('alert_history_status_change', { fromStatus: fromStatus, toStatus: toStatus });
            },
            Classification: function (historyItem) {
                return historyItem.newClassification
                    ? _this.i18nService.get('alert_history_change_classification_message', {
                        value: _this.i18nService.get(historyItem.newClassification.nameI18nKey),
                    })
                    : _this.i18nService.get('alert_history_classification_removed');
            },
            Determination: function (historyItem) {
                if (historyItem.newDetermination) {
                    var determination = historyItem.newDetermination;
                    var name_1 = _this.i18nService.get(determination.nameI18nKey);
                    return _this.i18nService.get('alert_history_change_determination_message', { name: name_1 });
                }
                else {
                    return _this.i18nService.get('alert_history_determination_removed');
                }
            },
            Create: function (_) { return _this.i18nService.get('alert_history_generated_message'); },
            LinkAlertToIncident: function (historyItem) { return historyItem.newValue; },
            UnlinkAlertFromIncident: function (historyItem) { return historyItem.newValue; },
            Title: function (historyItem) {
                var fromText = _this.i18nService.get('alert_history_from');
                var fromName = historyItem.oldValue ? " '" + fromText + "' " + historyItem.oldValue : '';
                return _this.i18nService.get('alert_history_title_name_change', {
                    fromName: fromName,
                    toName: historyItem.newValue,
                });
            },
        };
        this.getAlertHistoryMessage = function (item) {
            var alertHistoryMessage = '';
            try {
                if (item && _this.alertHistoryMessageMap[item.type.id]) {
                    alertHistoryMessage = _this.alertHistoryMessageMap[item.type.id](item);
                }
            }
            catch (error) {
                console.error(error);
            }
            return alertHistoryMessage;
        };
    }
    AlertsService.prototype.getAlertHistory = function (alertId) {
        var alertHistoryRepo = this.paris.getRepository(AlertHistoryItem);
        return alertHistoryRepo
            .query({ where: { id: alertId }, pageSize: 100 })
            .pipe(map(function (dataSet) { return dataSet.items; }));
    };
    /*
     * @param subset (array of field names): if given, returns only filters that were defined in the subset
     */
    AlertsService.prototype.getAlertsFilters = function (subset, useNestedServiceSources) {
        var _this = this;
        if (useNestedServiceSources === void 0) { useNestedServiceSources = false; }
        if (this._alertFilters)
            return of(this._alertFilters);
        var rbacGroups$ = this.rbacService.isFilteringNecessary$.pipe(switchMap(function (isFilteringNecessary) {
            return isFilteringNecessary
                ? _this.machinesFiltersService.getUserExposedRbacGroups()
                : of(null);
        }));
        var outbreaks$ = this.featuresService.isEnabled(Feature.ThreatAnalytics2)
            ? this.paris.getRepository(Outbreak).allItems$
            : of([]);
        return zip(rbacGroups$, outbreaks$).pipe(take(1), map(function (_a) {
            var rbacGroupFilterValues = _a[0], outbreaks = _a[1];
            var alertFilters = {
                status: {
                    count: null,
                    values: AlertStatus.entityConfig.values
                        .filter(function (status) { return status.isSelectable; })
                        .map(function (status) {
                        return {
                            value: status.type,
                            name: _this.i18nService.get(status.nameI18nKey),
                            count: null,
                            priority: status.priority,
                        };
                    }),
                },
                AssignedTo: {
                    count: null,
                    values: [
                        {
                            value: _this.authService.currentUser.username.toLowerCase(),
                            name: 'Assigned to me',
                            count: null,
                        },
                        {
                            value: 'Automation',
                            name: 'Assigned to automation',
                            count: null,
                        },
                    ].filter(Boolean),
                },
                detectionSource: useNestedServiceSources
                    ? null
                    : {
                        count: null,
                        values: _this.sourceFilterService.getDetectionSourcesFilterValues(),
                    },
                serviceSource: useNestedServiceSources
                    ? {
                        count: null,
                        values: _this.sourceFilterService
                            .getServiceSourceTreeFilterValues()
                            .sort(function (a, b) { return b.priority - a.priority; }),
                    }
                    : null,
                severity: {
                    count: null,
                    values: Severity.entityConfig.values
                        .filter(function (severity) { return severity.isSelectable; })
                        .map(function (severity) {
                        return {
                            value: severity.name,
                            name: _this.i18nService.get(severity.nameI18nKey),
                            count: null,
                            priority: severity.priority,
                        };
                    }),
                },
                category: {
                    count: null,
                    values: AlertCategory.entityConfig.values.map(function (category) {
                        return {
                            value: category.type,
                            name: category.name,
                            count: null,
                        };
                    }),
                },
                osPlatform: {
                    count: null,
                    values: values(_this.machinesFiltersService.osPlatformCategoriesMap).map(function (osPlatform) {
                        return {
                            value: osPlatform.id,
                            count: null,
                        };
                    }),
                },
                classification: {
                    count: null,
                    values: AlertClassification.entityConfig.values.map(function (classification) {
                        return {
                            value: classification.type,
                            name: _this.i18nService.get(classification.nameI18nKey),
                            count: null,
                        };
                    }),
                },
                investigationStates: {
                    count: null,
                    values: InvestigationStatus.entityConfig.values
                        .filter(function (status) { return status.id >= 0; })
                        .filter(function (status) {
                        return _this.featuresService.isEnabled(Feature.AirsApiOffloading)
                            ? !isNil(status.sevilleStatusId)
                            : !status.isTempOffloadingStatus;
                    })
                        .map(function (status) {
                        return {
                            value: status.id.toString(),
                            name: status.name,
                            count: null,
                            priority: status.priority,
                        };
                    }),
                },
            };
            if (rbacGroupFilterValues) {
                Object.assign(alertFilters, {
                    rbacGroup: {
                        count: rbacGroupFilterValues.length,
                        values: rbacGroupFilterValues,
                    },
                });
            }
            if (_this.featuresService.isEnabled(Feature.ThreatAnalytics2)) {
                Object.assign(alertFilters, {
                    IoaDefinitionIds: {
                        count: null,
                        values: outbreaks
                            .filter(function (outbreak) { return outbreak.ioaIds && outbreak.ioaIds.length > 0; })
                            .map(function (outbreak) {
                            return {
                                value: outbreak.id,
                                name: outbreak.displayName,
                                count: null,
                            };
                        }),
                    },
                });
            }
            return alertFilters;
        }), map(function (filters) {
            if (!subset)
                return filters;
            return Object.keys(filters)
                .filter(function (filterName) { return subset.includes(filterName); })
                .reduce(function (filtersObj, filterName) {
                filtersObj[filterName] = filters[filterName];
                return filtersObj;
            }, {});
        }), tap(function (filterValues) {
            _this._alertFilters = filterValues;
        }));
    };
    AlertsService.prototype.searchFilterValues = function (field, term, options) {
        if (field.id === 'rbacGroup') {
            return this.machinesFiltersService.searchMachineGroupFilterValues(term, options);
        }
        return Promise.resolve([]);
    };
    AlertsService.prototype.assignAlertsToCurrentUser = function (alerts) {
        var _this = this;
        return this.updateAlerts(alerts).pipe(tap(function () {
            var entity = alerts[0].constructor.entityConfig;
            _this.dialogsService.showSuccessSnackbar({
                text: (alerts.length > 1 ? entity.pluralName : entity.singularName) + " " + (alerts.length > 1 ? ' were' : ' was') + " assigned to " + _this.authService.currentUser.name,
            });
        }), map(function () { return _this.authService.currentUser; }));
    };
    AlertsService.prototype.assignAlertsToSomeoneElse = function (alerts, assignee) {
        var _this = this;
        var updateData = {
            AlertIds: alerts.map(function (alert) { return alert.id; }),
            AlertUpdateRequest: { assignedTo: assignee || '' }
        };
        return this.http
            .patch("<mtpalert>/alerts", updateData)
            .pipe(map(function () { return _this.authService.currentUser; }));
    };
    AlertsService.prototype.setAlertsStatus = function (alerts, status, requireConfirmNewStatus, focusableQuery) {
        var _this = this;
        if (requireConfirmNewStatus === void 0) { requireConfirmNewStatus = true; }
        var setAlertStatus$ = this.updateAlerts(alerts, { status: status.type }).pipe(tap(function () {
            var entityName = _this.i18nService.get(alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName');
            var text = _this.i18nService.get('alerts_status_change', {
                entityName: entityName,
                status: _this.i18nService.get(status.nameI18nKey),
            });
            _this.dialogsService.showSuccessSnackbar({ text: text, focusableQuery: focusableQuery });
        }), map(function () { return status; }));
        if (requireConfirmNewStatus && status.type === 'New') {
            return from(this.dialogsService.confirm({
                title: this.i18nService.get('alerts.status.changeToNew.title'),
                text: this.i18nService.get('alerts.status.changeToNew.description'),
            })).pipe(switchMap(function (confirmed) { return (confirmed.confirmed ? setAlertStatus$ : of(null)); }));
        }
        return setAlertStatus$;
    };
    AlertsService.prototype.setAlertsClassification = function (alerts, classification, focusableQuery) {
        var _this = this;
        return this.updateAlerts(alerts, {
            classification: classification.type,
        }).pipe(tap(function () {
            var entityName = "" + _this.i18nService.get(alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName');
            var value = "" + _this.i18nService.get(classification.nameI18nKey);
            var text = _this.i18nService.get('set_alertsClassification', { entityName: entityName, value: value });
            _this.dialogsService.showSuccessSnackbar({ text: text, focusableQuery: focusableQuery });
        }));
    };
    AlertsService.prototype.setAlertsDetermination = function (alerts, determination) {
        var _this = this;
        return this.updateAlerts(alerts, {
            determination: determination.type,
        }).pipe(tap(function () {
            var entityName = "" + _this.i18nService.get(alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName');
            var value = "" + _this.i18nService.get(determination.nameI18nKey);
            var text = _this.i18nService.get('set_alertsDetermination', { entityName: entityName, value: value });
            _this.dialogsService.showSuccessSnackbar({ text: text });
        }));
    };
    AlertsService.prototype.setAlertsClassificationAndDetermination = function (alerts, value) {
        var _this = this;
        var _a = combinedClassifications[value], classification = _a.classification, determination = _a.determination;
        return this.updateAlerts(alerts, {
            classification: alertClassificationValues.find(function (value) { return value.id === classification; }).type,
        })
            .toPromise()
            .then(function () {
            _this.updateAlerts(alerts, {
                determination: alertDeterminationValues.find(function (value) { return value.id === determination; }).type,
            })
                .toPromise()
                .then(function () {
                var text = _this.i18nService.get('alerts_classification_changed');
                _this.dialogsService.showSuccessSnackbar({ text: text });
            });
        });
    };
    AlertsService.prototype.restoreHiddenAlerts = function (alerts) {
        var _this = this;
        return this.dialogsService
            .confirm({
            title: 'Restore hidden alert',
            confirmText: 'Restore',
            text: "This alert will be moved to the 'New alerts' queue, assigned to you and no longer be hidden.",
        })
            .then(function (e) {
            return new Promise(function (resolve, reject) {
                if (e.confirmed) {
                    var newAlertStatus = find(_this.paris.getRepository(AlertStatus).entity.values, function (alertStatus) {
                        return alertStatus.isSelectable && alertStatus.type === 'New';
                    });
                    _this.setAlertsStatus(alerts, newAlertStatus, false).subscribe(function () {
                        resolve();
                    }, function (error) { return reject(error); });
                }
            });
        });
    };
    AlertsService.prototype.addCommentToAlerts = function (alerts, comment) {
        return this.updateAlerts(alerts, { comment: comment });
    };
    AlertsService.prototype.updateAlerts = function (alerts, data) {
        var updateData = Object.assign({
            AlertIds: alerts.map(function (alert) { return alert.id; }),
            AlertUpdateRequest: data
        });
        return this.http.patch("<mtpalert>/alerts", updateData);
    };
    AlertsService.prototype.showAlertsSidePanelByRawData = function (alertsRawData, options) {
        var _this = this;
        var alerts$ = combineLatest(alertsRawData.map(function (alertData) { return _this.paris.createItem(Alert, alertData); }));
        alerts$.subscribe(function (alerts) { return _this.entityPanelsService.showEntities(Alert, alerts, options); });
    };
    AlertsService.prototype.getContainedAlerts = function (alert, options, groupByHash) {
        if (groupByHash === void 0) { groupByHash = false; }
        if (!alert.isGroup) {
            throw new Error('Alert must be a group');
        }
        var config = Object.assign({ detectionSource: alert.detectionSource.type }, groupByHash ? { groupHash: alert.groupHash } : { groupId: alert.groupId });
        return this.paris
            .query(Alert, {
            where: Object.assign({}, omit(options, ['alertType']), config),
            pageSize: alert.groupedAlertsCount,
        })
            .pipe(map(function (dataSet) { return dataSet.items; }));
    };
    AlertsService.prototype.getAlertLink = function (id) {
        if (this.featuresService.isEnabled(Feature.UpgradeAlertPage)) {
            return "/alerts/" + id + "/details";
        }
        else {
            return "/alert/" + id;
        }
    };
    // Check if this alert can be opened in mdatp portal
    AlertsService.prototype.isAlertLinkableToMdatp = function (alert) {
        return (!alert.isGroup &&
            (!sccHostService.isSCC ||
                (alert.serviceSource &&
                    alert.serviceSource.id === ServiceSourceType.Wdatp &&
                    !alert.isCustomDetection)));
    };
    AlertsService.prototype.downloadCsv = function (alerts, options) {
        if (options.columns && options.fields) {
            var fields_1 = options.fields
                .filter(function (field) { return options.columns.includes(field.id); })
                .reduce(function (r, field) {
                var _a;
                return (__assign({}, r, (_a = {}, _a[field.id] = field, _a)));
            }, {});
            var titles = options.columns.map(function (column) { return fields_1[column].name; }).join(',');
            var rows = alerts.map(function (alert) {
                return options.columns
                    .map(function (column) { return "\"" + fields_1[column].getDisplay(alert).replace(/"/g, '""') + "\""; })
                    .join(',');
            });
            var csv = [titles].concat(rows).join('\n');
            var blob = new Blob([csv]);
            FileSaver.saveAs(blob, 'sample-file.csv');
        }
    };
    return AlertsService;
}());
export { AlertsService };
var ɵ0 = function (acc, currentValue) {
    acc[currentValue.type] = currentValue.id;
    return acc;
};
var determinationNewValues = alertDeterminationValues.reduce(ɵ0, {});
export var combinedClassifications = {
    NotSet: { determination: 'NotSet', classification: AlertClassificationId.Unknown },
    MultiStagedAttack: {
        determination: determinationNewValues.MultiStagedAttack,
        classification: AlertClassificationId.TruePositive,
    },
    Malware: {
        determination: determinationNewValues.Malware,
        classification: AlertClassificationId.TruePositive,
    },
    MaliciousUserActivity: {
        determination: determinationNewValues.MaliciousUserActivity,
        classification: AlertClassificationId.TruePositive,
    },
    UnwantedSoftware: {
        determination: determinationNewValues.UnwantedSoftware,
        classification: AlertClassificationId.TruePositive,
    },
    Phishing: {
        determination: determinationNewValues.Phishing,
        classification: AlertClassificationId.TruePositive,
    },
    CompromisedUser: {
        determination: determinationNewValues.CompromisedUser,
        classification: AlertClassificationId.TruePositive,
    },
    TruePositiveOther: {
        determination: determinationNewValues.Other,
        classification: AlertClassificationId.TruePositive,
    },
    SecurityTesting: {
        determination: determinationNewValues.SecurityTesting,
        classification: AlertClassificationId.BenignPositive,
    },
    ConfirmedUserActivity: {
        determination: determinationNewValues.ConfirmedUserActivity,
        classification: AlertClassificationId.BenignPositive,
    },
    LineOfBusinessApplication: {
        determination: determinationNewValues.LineOfBusinessApplication,
        classification: AlertClassificationId.BenignPositive,
    },
    BenignPositiveOther: {
        determination: determinationNewValues.Other,
        classification: AlertClassificationId.BenignPositive,
    },
    Clean: {
        determination: determinationNewValues.Clean,
        classification: AlertClassificationId.FalsePositive,
    },
    InsufficientData: {
        determination: determinationNewValues.InsufficientData,
        classification: AlertClassificationId.FalsePositive,
    },
    FalsePositiveOther: {
        determination: determinationNewValues.Other,
        classification: AlertClassificationId.FalsePositive,
    },
};
export function getCombinedClassificationMap(i18nService) {
    return [
        { key: 'NotSet', text: i18nService.get('alerts_classification_dropdown_notSet') },
        {
            key: 'trueClassificationHeader',
            text: i18nService.get('reporting_alertsByClassification_trueAlert'),
            itemType: DropdownMenuItemType.Header,
        },
        { key: 'MultiStagedAttack', text: i18nService.get('alertDetermination_MultiStagedAttack') },
        { key: 'Malware', text: i18nService.get('alertDetermination_malware') },
        { key: 'MaliciousUserActivity', text: i18nService.get('alertDetermination_MaliciousUserActivity') },
        { key: 'UnwantedSoftware', text: i18nService.get('alertDetermination_unwanted_software') },
        { key: 'Phishing', text: i18nService.get('alertDetermination_Phishing') },
        { key: 'CompromisedUser', text: i18nService.get('alertDetermination_CompromisedUser') },
        { key: 'TruePositiveOther', text: i18nService.get('alertDetermination_other') },
        { key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
        {
            key: 'benignClassificationHeader',
            text: i18nService.get('alerts_classification_dropdown_benign'),
            itemType: DropdownMenuItemType.Header,
        },
        { key: 'SecurityTesting', text: i18nService.get('alertDetermination_security_testing') },
        { key: 'ConfirmedUserActivity', text: i18nService.get('alertDetermination_ConfirmedUserActivity') },
        {
            key: 'LineOfBusinessApplication',
            text: i18nService.get('alertDetermination_LineOfBusinessApplication'),
        },
        { key: 'BenignPositiveOther', text: i18nService.get('alertDetermination_other') },
        { key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
        {
            key: 'falseClassificationHeader',
            text: i18nService.get('reporting_alertsByClassification_falseAlert'),
            itemType: DropdownMenuItemType.Header,
        },
        { key: 'Clean', text: i18nService.get('alertDetermination_Clean') },
        { key: 'InsufficientData', text: i18nService.get('alertDetermination_InsufficientData') },
        { key: 'FalsePositiveOther', text: i18nService.get('alertDetermination_other') },
    ];
}
export { ɵ0 };
