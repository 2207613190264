import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { catchError, map } from 'rxjs/operators';
import { AppContextService } from '@wcd/config';
import { CookiesService } from '@wcd/shared';
export var LOGIN_API_URL = '/Authenticate/Login';
export var WDATP_LOGOUT_URL = '/Authenticate/LogOff';
export var WDATP_REDIRECT_TO_LOGIN_URL = '/Authenticate/RedirectToLoginPage';
export var WDATP_AUTH_VALIDATION_URL = '/api/AllowedActions';
var REFRESH_TOKEN_URL = '/Authenticate/RefreshToken';
var XSS_COOKIE = 'afc';
var AuthBackendService = /** @class */ (function () {
    function AuthBackendService(http, serviceUrlsService, appContext, cookiesService) {
        this.http = http;
        this.serviceUrlsService = serviceUrlsService;
        this.appContext = appContext;
        this.cookiesService = cookiesService;
    }
    Object.defineProperty(AuthBackendService.prototype, "loginUrl", {
        get: function () {
            return LOGIN_API_URL;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthBackendService.prototype, "authValidationUrl", {
        get: function () {
            return (this.serviceUrlsService.authorization || '') + WDATP_AUTH_VALIDATION_URL;
        },
        enumerable: true,
        configurable: true
    });
    AuthBackendService.prototype.checkAuthStatus = function () {
        return this.http.get(this.authValidationUrl).pipe(map(function (res) { return true; }), catchError(function (err) { return of(err.status !== 401); }));
    };
    AuthBackendService.prototype.callWithXssHeader = function (url) {
        var _a;
        var xssToken = this.cookiesService.getCookie(XSS_COOKIE);
        return this.http.get(url, xssToken
            ? {
                headers: (_a = {}, _a[XSS_COOKIE] = xssToken, _a),
            }
            : undefined);
    };
    AuthBackendService.prototype.login = function () {
        return this.callWithXssHeader(this.loginUrl);
    };
    AuthBackendService.prototype.refreshToken = function (tid) {
        return this.callWithXssHeader(REFRESH_TOKEN_URL + "?tid=" + tid);
    };
    return AuthBackendService;
}());
export { AuthBackendService };
