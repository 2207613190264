
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<dl role="none">
				<dt>{{ 'alerts.severity' | i18n }}</dt>
				<dd style="max-width: 200px">
					<events-summary-bars
						class="events-summary-bars"
						[events]="severityData"
						[kindColorMap]="severityColorMap"
					></events-summary-bars>
					<wcd-chart-legend
						class="wcd-full-width wcd-margin-small-top wcd-margin-large-bottom"
						orientation="horizontal"
						[showValues]="true"
						[items]="calcSeverityLegend(severityData)"
					></wcd-chart-legend>
				</dd>
				<dt>{{ 'incident.setClassification.incidentStatus' | i18n }}</dt>
				<dd style="max-width: 200px">
					<wcd-bars-chart
						[data]="statusData"
						[settings]="{ isSelectable: false, outerValues: true }"
					></wcd-bars-chart>
				</dd>
				<dt>{{ 'alerts.classification' | i18n }}</dt>
				<dd style="max-width: 200px">
					<wcd-bars-chart
						[data]="classificationData"
						[settings]="{ isSelectable: false, outerValues: true }"
					></wcd-bars-chart>
				</dd>
			</dl>
		</section>
	