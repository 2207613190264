var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { investigationStatusValues } from './investigation-status.values';
var InvestigationStatus = /** @class */ (function (_super) {
    __extends(InvestigationStatus, _super);
    function InvestigationStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isPending", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isError", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isRunning", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isTerminated", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isTerminatedByUser", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isDisabled", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "hideErrors", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "investigationQueued", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], InvestigationStatus.prototype, "sevilleStatusId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isTempOffloadingStatus", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], InvestigationStatus.prototype, "isPendingUser", void 0);
    InvestigationStatus = __decorate([
        Entity({
            singularName: 'Investigation Status',
            pluralName: 'Investigation Statuses',
            values: investigationStatusValues,
        })
    ], InvestigationStatus);
    return InvestigationStatus;
}(StatusModel));
export { InvestigationStatus };
