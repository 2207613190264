var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
export var EvaluationSimulatorStatusType;
(function (EvaluationSimulatorStatusType) {
    EvaluationSimulatorStatusType["Failed"] = "Failed";
    EvaluationSimulatorStatusType["PendingStart"] = "PendingStart";
    EvaluationSimulatorStatusType["Running"] = "Running";
    EvaluationSimulatorStatusType["Completed"] = "Completed";
})(EvaluationSimulatorStatusType || (EvaluationSimulatorStatusType = {}));
export var evaluationSimulatorStatusValues = [
    {
        id: 'Failed',
        name: 'failed',
        className: 'ms-color-red',
        type: EvaluationSimulatorStatusType.Failed,
        priority: 0,
    },
    {
        id: 'PendingStart',
        name: 'pendingStart',
        className: 'ms-color-themeSecondary',
        type: EvaluationSimulatorStatusType.PendingStart,
        priority: 1,
    },
    {
        id: 'Running',
        name: 'running',
        className: 'ms-color-themeSecondary',
        type: EvaluationSimulatorStatusType.Running,
        priority: 2,
    },
    {
        id: 'Completed',
        name: 'completed',
        className: 'ms-color-greenLight',
        type: EvaluationSimulatorStatusType.Completed,
        priority: 3,
    },
];
var EvaluationSimulatorStatus = /** @class */ (function (_super) {
    __extends(EvaluationSimulatorStatus, _super);
    function EvaluationSimulatorStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvaluationSimulatorStatus.prototype, "type", void 0);
    EvaluationSimulatorStatus = __decorate([
        Entity({
            singularName: 'Simulator status',
            pluralName: 'Simulator status',
            values: evaluationSimulatorStatusValues,
        })
    ], EvaluationSimulatorStatus);
    return EvaluationSimulatorStatus;
}(StatusModel));
export { EvaluationSimulatorStatus };
