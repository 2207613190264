import { ModuleWithProviders, NgModule } from '@angular/core';
import { PanelService } from './services/panels.service';
import { PanelComponent } from './components/panel.component';
import { CommonModule } from '@angular/common';
import { WcdIconsModule } from '@wcd/icons';
import { WcdFormsModule } from '@wcd/forms';
import { I18nModule } from '@wcd/i18n';
import { TooltipsModule } from '@wcd/dialogs';
import { WcdPanelPlaceholderComponent } from './components/panel-placeholder.component';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { A11yModule } from '@angular/cdk/a11y';

@NgModule({
	imports: [
		CommonModule,
		WcdIconsModule,
		AngularExtensionsModule,
		WcdFormsModule,
		TooltipsModule,
		I18nModule,
		A11yModule,
	],
	declarations: [WcdPanelPlaceholderComponent, PanelComponent],
	exports: [WcdPanelPlaceholderComponent, PanelComponent],
	entryComponents: [WcdPanelPlaceholderComponent],
})
export class PanelsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: PanelsModule,
			providers: [PanelService],
		};
	}
}
