import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'AAD Group',
	pluralName: 'AAD Groups',
	endpoint: 'rbac/aad_groups',
	allItemsEndpointTrailingSlash: false,
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.rbacManagementApi,
	supportedEntityGetMethods: [EntityGetMethod.query],
})
export class AadGroup extends EntityModelBase {
	@EntityField({ data: 'ObjectId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'DisplayName' })
	name: string;
}
