var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router } from '@angular/router';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { VulnerabilityNotificationRuleFormData } from '../models/vulnerability-notification-rule.models';
import { VulnerabilityEmailNotificationSettingsStepComponent } from './wizard/vulnerability-email-notification-settings-step.component';
import { VulnerabilityNotificationRulesService } from '../services/vulnerability-notification-rules.service';
import { I18nService } from '@wcd/i18n';
import { EmailNotificationRecipientsStepComponent } from '../../../../email-notifications/components/email-notification-recipients-step.component';
import { EmailNotificationReviewStepComponent } from '../../../../email-notifications/components/email-notification-review-step.component';
import { RuleNameStepComponent } from '../../../../email-notifications/components/rule-name-step.component';
import { VulnerabilityChangeEventType } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { TvmNotificationsTextsService } from '../../../../tvm/services/tvm-notifications-texts.service';
var VulnerabilityNotificationRuleWizardComponent = /** @class */ (function (_super) {
    __extends(VulnerabilityNotificationRuleWizardComponent, _super);
    function VulnerabilityNotificationRuleWizardComponent(vulnerabilityNotificationRulesService, i18nService, notificationTextsService, appConfigService, router) {
        var _this = _super.call(this, router) || this;
        _this.vulnerabilityNotificationRulesService = vulnerabilityNotificationRulesService;
        _this.i18nService = i18nService;
        _this.notificationTextsService = notificationTextsService;
        _this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
        _this.ruleNameComponentInputs = new Map([
            ['includeStatus', true],
            [
                'ruleNameDescription',
                _this.i18nService.strings.vulnerability_email_notification_rule_name_description,
            ],
            ['nameLength', 50],
            ['descriptionLength', 300],
            ['nameRegExp', 'tvmVulnerabilityNotificationsAllowedText'],
            ['descriptionRegExp', 'tvmVulnerabilityNotificationsAllowedText'],
        ]);
        _this.recipientsComponentInputs = new Map([
            ['testMailEnabled', false],
            [
                'introductionText',
                _this.i18nService.strings.vulnerability_notifications_recipients_introduction,
            ],
        ]);
        _this.setReviewRuleComponentInputs();
        _this.setSteps();
        return _this;
    }
    Object.defineProperty(VulnerabilityNotificationRuleWizardComponent.prototype, "isUpdateFlow", {
        get: function () {
            return this._isUpdateFlow;
        },
        set: function (value) {
            this._isUpdateFlow = value;
            this.title = this._isUpdateFlow
                ? 'vulnerability.email.notification.wizard.breadcrumbs.update'
                : 'vulnerability.email.notification.wizard.breadcrumbs.creation';
            this.formData = __assign({ isUpdateFlow: this._isUpdateFlow }, this.data);
            this.steps[3].nextButtonText = this._isUpdateFlow
                ? 'incident.email.notification.wizard.update.rule'
                : 'incident.email.notification.wizard.create.rule';
        },
        enumerable: true,
        configurable: true
    });
    VulnerabilityNotificationRuleWizardComponent.prototype.onRuleCreated = function () {
        this.closePanel();
        this.onDone();
    };
    VulnerabilityNotificationRuleWizardComponent.prototype.onClosePanel = function () {
        this.closePanel();
        this.onClose();
    };
    VulnerabilityNotificationRuleWizardComponent.prototype.setReviewRuleComponentInputs = function () {
        var _this = this;
        this.reviewRuleComponentInputs = new Map([
            ['shouldDisplayNotificationSettings', function (data) { return data.includeOrgName; }],
            [
                'customSettings',
                function (data) {
                    var customSettingsArray = [
                        {
                            key: _this.i18nService.strings
                                .vulnerability_email_notification_wizard_eventType_summary,
                            value: Object.values(VulnerabilityChangeEventType)
                                .filter(function (e) { return data.eventTypes[e]; })
                                .map(function (e) { return _this.notificationTextsService.getChangeEventTypeLabel(e); })
                                .join('<br>'),
                        },
                    ];
                    if (data.severityLevelForNewCve) {
                        customSettingsArray.push({
                            key: _this.i18nService.strings
                                .vulnerability_email_notification_wizard_severity_threshold_summary,
                            value: _this.i18nService.get("tvm_notifications_vulnerability_" + data.severityLevelForNewCve),
                        });
                    }
                    return customSettingsArray;
                },
            ],
            [
                'notificationSettings',
                function (data) {
                    var notificationSettingsArray = [];
                    if (data.includeOrgName) {
                        notificationSettingsArray.push(_this.i18nService.strings.email_notification_include_org_name);
                    }
                    return notificationSettingsArray;
                },
            ],
            ['shouldDisplayDeviceGroups', this.tenantHasMachineGroups],
            ['explicitGlobalNotificationText', true],
            [
                'saveRuleFunction',
                this.vulnerabilityNotificationRulesService.saveVulnerabilityNotificationRule,
            ],
            [
                'status',
                function (data) {
                    return data.isEnabled
                        ? _this.i18nService.strings.tvm_common_active
                        : _this.i18nService.strings.tvm_common_notActive;
                },
            ],
        ]);
    };
    VulnerabilityNotificationRuleWizardComponent.prototype.setSteps = function () {
        this.steps = [
            {
                id: 1,
                name: 'RuleName',
                verticalStepKeyName: 'email_notification_wizard_basics',
                component: RuleNameStepComponent,
                componentInputs: this.ruleNameComponentInputs,
            },
            {
                id: 2,
                name: 'VulnerabilityEmailNotificationSettings',
                verticalStepKeyName: 'email.notification.wizard.settings.step.title',
                component: VulnerabilityEmailNotificationSettingsStepComponent,
            },
            {
                id: 3,
                name: 'Recipients',
                verticalStepKeyName: 'email.notification.wizard.recipients.step.title',
                component: EmailNotificationRecipientsStepComponent,
                componentInputs: this.recipientsComponentInputs,
            },
            {
                id: 4,
                name: 'ReviewRule',
                verticalStepKeyName: 'email.notification.wizard.review.rule.step.title',
                component: EmailNotificationReviewStepComponent,
                onNext: true,
                nextButtonText: 'email.notification.wizard.create.rule',
                componentInputs: this.reviewRuleComponentInputs,
            },
        ];
    };
    return VulnerabilityNotificationRuleWizardComponent;
}(PanelContainer));
export { VulnerabilityNotificationRuleWizardComponent };
