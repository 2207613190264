import { Injectable, Injector } from '@angular/core';
import { AirsEntityRelationPath } from '../models/airs-entity-relation-path.interface';
import { AirsEntity, AirsEntityTypeConfig } from '@wcd/domain';
import { PanelSettings } from '@wcd/panels';

import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { findIndex } from 'lodash-es';

@Injectable()
export class RelatedEntitiesService {
	private _entityRelatedPaths: Array<AirsEntityRelationPath> = [];

	constructor(private injector: Injector) {}

	showRelatedEntity(
		sourceEntity: AirsEntity,
		relatedEntityType: AirsEntityTypeConfig,
		relatedEntityId: string
	) {
		const investigationId = sourceEntity.investigation && sourceEntity.investigation.id;
		const existingRelatedPathIndex: number = findIndex(
			this._entityRelatedPaths,
			(relatedPath: AirsEntityRelationPath) =>
				relatedPath.entityId === sourceEntity.id &&
				relatedPath.entityType === sourceEntity.type &&
				relatedPath.investigationId === investigationId
		);
		const relatedEntityPath: AirsEntityRelationPath = {
			entityType: sourceEntity.type,
			name: sourceEntity.name,
			entityId: sourceEntity.id,
			investigationId: investigationId,
		};

		this._entityRelatedPaths = ~existingRelatedPathIndex
			? this._entityRelatedPaths.slice(0, existingRelatedPathIndex)
			: [...this._entityRelatedPaths, relatedEntityPath];

		this.doShowEntity(relatedEntityId, relatedEntityType, investigationId);
	}

	private doShowEntity(
		entityId: string,
		entityType: AirsEntityTypeConfig,
		investigationId: string | number
	): void {
		const panelNav: PanelSettings = this._entityRelatedPaths.length
			? {
					back: {
						text: this._entityRelatedPaths[this._entityRelatedPaths.length - 1].name,
						onClick: () => {
							const previousEntityPath: AirsEntityRelationPath = this._entityRelatedPaths.pop();
							this.doShowEntity(
								previousEntityPath.entityId,
								previousEntityPath.entityType,
								investigationId
							);
						},
					},
					previous: null,
					next: null,
			  }
			: {
					back: null,
			  };

		this.injector
			.get(EntityPanelsService)
			.showEntityById(
				AirsEntity,
				entityId,
				{ type_id: entityType.id, investigation_id: investigationId },
				panelNav
			);
	}
}
