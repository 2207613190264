import { isEqual } from 'lodash-es';

export class ReadOnlyIdentifiable<T extends string | number> implements IReadonlyIdentifiable {
	protected _data: any;

	id: T;
	name: string;

	get isNew() {
		return this.id === undefined;
	}

	get __data(): any {
		return this._data;
	}

	constructor(data: ReadOnlyIdentifiableConfig, params?: Array<any>) {
		this.preSetData.apply(this, [data].concat(params));

		if (data) this.setData.apply(this, [data].concat(params));
	}

	preSetData(data: ReadOnlyIdentifiableConfig, ...params: Array<any>) {}

	setData(data: ReadOnlyIdentifiableConfig, ...params: Array<any>) {
		this.id = <T>data.id;

		if (data.name !== undefined) this.name = data.name;

		this._data = data;
	}

	compare(otherItem: ReadOnlyIdentifiable<T>): boolean {
		return isEqual(this._data, otherItem._data);
	}
}

export interface ReadOnlyIdentifiableConfig {
	id: string | number;
	name?: string;
}

export interface IReadonlyIdentifiable extends ReadOnlyIdentifiableConfig {
	readonly isNew: boolean;
	readonly __data: any;

	preSetData(data: ReadOnlyIdentifiableConfig, ...params: Array<any>);

	setData(data: ReadOnlyIdentifiableConfig, ...params: Array<any>);

	compare(otherItem: IReadonlyIdentifiable): boolean;
}
