<div class="wcd-full-height wcd-flex-vertical" style="justify-content: space-between">
	<div>
		<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">{{ 'tvm.exposureScoreWidget.header' | i18n }}</h2>

		<p class="wcd-margin-bottom">
			{{ 'tvm.exposureScoreWidget.description' | i18n }} <wcd-help [text]="widgetTooltip" [ariaLabel]="widgetTooltipAriaLabel" [wcdTooltipAllowHtmlRendering]="true"></wcd-help>
			<ng-container *ngIf="isImpactedByExceptions">
					<br><span [innerHTML]="'tvm.secureScoreWidget.scoreImpactedByException' | i18n"></span>
			</ng-container>
		</p>

		<div class="wcd-margin-bottom">
			<div a11y-invisible>
				<h5>{{ 'tvm_exposureScoreWidget_a11y_description' | i18n}}</h5>
				<div *ngLet="(accessibleGaugeExposureScore$ | async) as accessibleGaugeExposureScore">
					{{ 'tvm_exposureScoreWidget_a11y_score' | i18n : { exposureScore: accessibleGaugeExposureScore } }}
				</div>
				<div>{{ 'tvm_exposureScoreWidget_a11y_scale_description' | i18n }}</div>
				<div>{{ widgetTooltipAriaLabel }}</div>
			</div>
			<div aria-hidden="true" class="gauge-container wcd-margin-bottom">
				<wcd-gauge *ngIf="(exposureScorePercentage$ | async) != null" [config]="gauge.config" [percent]="exposureScorePercentage$ | async" [forceReRender$]="forceGaugeReRender$"></wcd-gauge>
			</div>
			<div aria-hidden="true" class="chart-legend-container wcd-margin-medium-bottom wcd-margin-top wcd-flex-center-horizontal">
				<wcd-chart-legend orientation="horizontal" [wrap]="true" [items]="gaugeLegendItems"></wcd-chart-legend>
			</div>
		</div>
	</div>
	<tvm-score-trend *ngIf="trendOptions" [options]="trendOptions"></tvm-score-trend>
	<wcd-line-chart *ngIf="newChartOptions" [options]="newChartOptions"></wcd-line-chart>
</div>
