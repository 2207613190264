import { Entity } from '@microsoft/paris';
import { ActionBaseEntity } from './action-base.entity';
import { mapActionFromApiV2ToActionHistory } from './action-center-api-v2.converter';
import { compact } from 'lodash-es';

@Entity({
	singularName: 'Action',
	pluralName: 'Actions',
	endpoint: (_, query) => {
		if (query.where['useActionCenterApiV2']) {
			return `actioncenterui/pending-actions`;
		}
		return query.where && query.where['useMtpApi'] ? 'MtpPendingActions' : 'PendingActions';
	},
	baseUrl: (config, query) => {
		const useActionCenterApiV2 = query && query.where && query.where['useActionCenterApiV2'];

		return useActionCenterApiV2
			? config.data.serviceUrls.automatedIr
			: config.data.serviceUrls.threatIntel;
	},
	parseData: (data, _, query) => {
		if (query && query.where['useActionCenterApiV2']) {
			// convert data from the new action center service format to the model format
			const results = data && data.Results;
			const convertedData =
				results &&
				(Array.isArray(results) ? results : [results]).map(action =>
					mapActionFromApiV2ToActionHistory(action)
				);

			return { count: data.Count, results: compact(convertedData) };
		}

		return data;
	},
	allItemsProperty: 'results',
	separateArrayParams: true,
	parseDataQuery: ActionBaseEntity.convertActionCenterQueryToParams,
})
export class PendingActionHistory extends ActionBaseEntity {}
