import { Component } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { NotificationRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { NotificationRulesFields } from '../services/notification-rules.fields';
import { NotificationRulesService } from '../services/notification-rules.service';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'notification-rules-dataview',
	providers: [NotificationRulesFields, NotificationRulesService],
	template: `
		<div class="wcd-flex-vertical wcd-full-height">
			<div class="wcd-padding-large-left wcd-margin-large-bottom wcd-flex">
				<markdown [data]="'notificationRules.description' | i18n"></markdown>
			</div>
			<div class="wcd-flex-1">
				<dataview
					class="wcd-full-height"
					id="email-notification-rules"
					[repository]="repository"
					[fields]="notificationRulesFields.fields"
					[highlightedItems]="notificationRulesService.displayedRules$ | async"
					[refreshOn]="notificationRulesService.refreshOn$ | async"
					[allowFilters]="false"
					[allowPaging]="false"
					[allowAdd]="isUserAllowedActions"
					(onNewItem)="openEditPanel()"
					[setItemActions]="boundSetItemActions"
					(onItemClick)="openEditPanel($event.item)"
					[disableSelection]="!isUserAllowedActions"
					[queueHeader]="true"
					[padLeft]="false"
					[responsiveActionBar]="true"
					commandBarNoPadding="true"
					responsiveActionBar="true"
					responsiveLayout="true"
					[removePaddingRight]="isScc"
					[label]="'notificationRules.alert.table.title' | i18n"
					(onTableRenderComplete)="onEmailNotificationsDataViewComplete()"
				>
				</dataview>
			</div>
		</div>
	`,
})
export class NotificationRulesDataviewComponent {
	repository: Repository<NotificationRule>;
	boundSetItemActions: (rules: Array<NotificationRule>) => Array<ItemActionModel>;
	isScc = sccHostService.isSCC;

	constructor(
		public paris: Paris,
		public authService: AuthService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		public notificationRulesFields: NotificationRulesFields,
		public notificationRulesService: NotificationRulesService,
		private performanceSccService: PerformanceSccService
	) {
		this.repository = paris.getRepository(NotificationRule);
		this.boundSetItemActions = this.setItemActions.bind(this);
	}

	get isUserAllowedActions() {
		return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
	}

	openEditPanel(rule?: NotificationRule) {
		this.notificationRulesService.showRulePanel(rule);
	}

	setItemActions(selectedRules: Array<NotificationRule>): Array<ItemActionModel> {
		if (
			!selectedRules ||
			!selectedRules.length ||
			selectedRules.length > 1 ||
			selectedRules[0].readonly ||
			!this.isUserAllowedActions
		)
			return [];

		return [
			new ItemActionModel({
				id: 'delete',
				name: this.i18nService.get('delete'),
				icon: 'delete',
				tooltip: this.i18nService.get('deleteSelectedItems', {
					itemsName: this.repository.entity.pluralName,
				}),
				method: (items: Array<NotificationRule>) => this.deleteItem(items[0]),
				refreshOnResolve: true,
			}),
		];
	}

	deleteItem(item: NotificationRule): Promise<NotificationRule> {
		return this.dialogsService
			.confirm({
				title: this.i18nService.get('deleteItem', { itemName: item.name }),
				text: this.i18nService.get('deleteConfirm', {
					itemPluralName: this.repository.entity.pluralName,
				}),
				confirmText: this.i18nService.get('delete'),
			})
			.then((e: ConfirmEvent) => {
				return (
					e.confirmed &&
					this.repository
						.removeItem(item, { data: this.repository.serializeItem(item) })
						.toPromise()
				);
			});
	}

	onEmailNotificationsDataViewComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('settings-email-notifications');
	}
}
