import { EntityModelBase, EntityField } from '@microsoft/paris';
import { WebContentFilteringAccessTrend } from './category-report.value-object';

export class WebContentFilteringReportBase extends EntityModelBase<string | number> {
	@EntityField({ data: 'AccessCount' })
	accessCount: number;

	@EntityField({ data: 'BlockedCount' })
	blockedCount: number;

	@EntityField({ data: 'TrendsDeltaPercentage' })
	trendsDeltaPercentage: number;

	@EntityField({ data: 'IsDeltaPercentageValid' })
	isDeltaPercentageValid: boolean;
}
