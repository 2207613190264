<div class="main-search-wrapper"
	 [class.active]="active || isHovered"
	 [class.hovered]="isHovered"
	 data-track-component="Main Search"
	 data-track-component-type="Search">
	<form (submit)="search()" class="relative">
		<div class="wcd-flex-horizontal search-container" [class.search-container-margin]="!isScc">
			<div class="wcd-flex-none">
				<fancy-select [(ngModel)]="currentSearchEntityType"
					class="search-page-dropdown-icon"
					(ngModelChange)="typeChanged()"
					listId="main-search-type"
					name="main-search-type"
					[isBordered]="true"
					#searchTypeInput
					(focus)="typeFocused = true"
					(blur)="typeBlurred()"
					(exit)="cancelSearch()"
					[label]="'displayName'"
					[showIconDropdown]="true"
					[values]="searchEntityTypes"
					[focusButtonOnClose]="false"
					[ariaLabel]="'main.search.title' | i18n "
					[navigateUsingArrowKeysOnly]="true"></fancy-select>
			</div>
			<div class="wcd-flex-1 relative search-container-size">
				<input type="text"
					[(ngModel)]="searchTerm"
					id="main-search-term"
					name="main-search-term"
					#searchInput
					(focus)="textFocused = true"
					(blur)="textFocused = false"
					(ngModelChange)="searchTermChanged()"
					placeholder="{{ 'main.search.placeholder' | i18n }}"
					[attr.aria-label]="'main.search.placeholder' | i18n"/>
				<button id="main-search-go-btn"
					class="btn btn-link wcd-margin-mediumSmall-right"
					wcdTooltip="{{ 'main.search.title' | i18n }}"
					(focus)="textFocused = true"
					(blur)="textFocused = false"
					data-track-id="SearchGo"
					data-track-type="Button"
					type="submit"
					[attr.aria-label]=" 'main.search.title' | i18n ">
					<wcd-shared-icon class="search-page-search-icon" iconName="search"> </wcd-shared-icon >

				</button>
			</div>
		</div>
		<div *ngIf="lengthError" class="search-error-message error-message">
			{{ 'main.search.minLengthError' | i18n }}
		</div>
	</form>
</div>
