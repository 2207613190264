var FileHashAlgorithm;
(function (FileHashAlgorithm) {
    FileHashAlgorithm[FileHashAlgorithm["MD5"] = 1] = "MD5";
    FileHashAlgorithm[FileHashAlgorithm["SHA1"] = 2] = "SHA1";
    FileHashAlgorithm[FileHashAlgorithm["SHA256"] = 3] = "SHA256";
})(FileHashAlgorithm || (FileHashAlgorithm = {}));
export function mapFileEntityToAirsEntity(entityData) {
    var res = Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "Name" /* Name */:
                    acc['name'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "SizeInBytes" /* SizeInBytes */:
                    var intSizeInBytes = parseInt(val);
                    acc['size'] = isNaN(intSizeInBytes) ? 0 : intSizeInBytes;
                    break;
                case "CreatedTimeUtc" /* CreatedTimeUtc */:
                    acc['created_time'] = val;
                    break;
                case "LastWriteTimeUtc" /* LastWriteTimeUtc */:
                    acc['modified_time'] = val;
                    break;
                case "Directory" /* Directory */:
                    acc['directory_path'] = val;
                    break;
                case "FileHashes" /* FileHashes */:
                    var hashes = val &&
                        val.reduce(function (res, _a) {
                            var Algorithm = _a.Algorithm, Value = _a.Value;
                            var enumAlgorithm = FileHashAlgorithm[Algorithm];
                            if (Algorithm === FileHashAlgorithm.MD5 ||
                                enumAlgorithm === FileHashAlgorithm.MD5) {
                                res['md5'] = Value;
                            }
                            else if (Algorithm === FileHashAlgorithm.SHA1 ||
                                enumAlgorithm === FileHashAlgorithm.SHA1) {
                                res['sha1'] = Value;
                            }
                            else if (Algorithm === FileHashAlgorithm.SHA256 ||
                                enumAlgorithm === FileHashAlgorithm.SHA256) {
                                res['sha256'] = Value;
                            }
                            return res;
                        }, {});
                    acc['hashes'] = hashes;
                    if (hashes) {
                        acc['md5'] = hashes.md5;
                        acc['sha1'] = hashes.sha1;
                        acc['sha256'] = hashes.sha256;
                    }
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
    var directory = res['directory_path'];
    var name = res['name'];
    var path = name ? (directory ? directory + "\\" + name : name) : '';
    return Object.assign(res, { path: path });
}
