export const enum SecurityGroupEntityAlertV3Fields {
	FriendlyName = 'FriendlyName',
	Id = 'Id',
}

export function mapSecurityGroupEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case SecurityGroupEntityAlertV3Fields.FriendlyName:
					acc['name'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case SecurityGroupEntityAlertV3Fields.Id:
					acc['id'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
