import { Injectable } from '@angular/core';
import { OnboardingBackendService } from './onboarding.backend.service';
import { Observable } from 'rxjs';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { EndpointOnboardingStatus } from '../models/endpoint-onboarding-status.interface';
import { AppConfigService } from '@wcd/app-config';
import { tap } from 'rxjs/operators';
import { SessionStorageService } from '@wcd/shared';

const DISMISS_WIDGET_STORAGE_KEY = 'onboarding-widget-dismissed';

@Injectable()
export class OnboardingService {
	private _onboardingWidgetDismissed: boolean;

	get onboardingWidgetDismissed(): boolean {
		return this._onboardingWidgetDismissed;
	}

	constructor(
		private onboardingBackendService: OnboardingBackendService,
		appConfigService: AppConfigService,
		private appInsights: AppInsightsService,
		private sessionStorageService: SessionStorageService
	) {
		this._onboardingWidgetDismissed =
			appConfigService.onboardingTileDismissed ||
			!!this.sessionStorageService.getItem(DISMISS_WIDGET_STORAGE_KEY);
	}

	dismissOnboardingWidget(
		endpointOnboardingStatus: EndpointOnboardingStatus,
		trackEvent: boolean = true
	): Observable<any> {
		if (trackEvent) {
			this.trackOnboardingWidgetEvent(true, endpointOnboardingStatus);
		}

		this._onboardingWidgetDismissed = true;
		this.sessionStorageService.setItem(DISMISS_WIDGET_STORAGE_KEY, '1');

		return this.onboardingBackendService
			.dismissOnboardingWidget()
			.pipe(tap(null, err => (this._onboardingWidgetDismissed = false)));
	}

	trackOnboardingWidgetEvent(isDecline: boolean, endpointOnboardingStatus: EndpointOnboardingStatus) {
		this.appInsights.trackEvent(`EndpointOnboarding-DashboardTile${isDecline ? 'Dismiss' : 'Button'}`, {
			Page: 'DashboardTile',
			RanTestAlert: endpointOnboardingStatus.RanTestAlert,
			MachineOnboarded: endpointOnboardingStatus.MachineOnboarded,
		});
	}
}
