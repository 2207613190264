<!-- tslint:disable:template-click-events-have-key-events -->
<section *ngFor="let treeElement of treeElements" role="presentation">
	<div class="tree-element" role="presentation"
		 [class.has-relation-text]="treeElement.relationToPrecedingElement">

		<!-- Relation to preceding element -->
		<div *ngIf="treeElement.relationToPrecedingElement && !treeElement.isAssociatedItem"
			 class="wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis tree-element--relation-text"
			 [wcdTooltip]="treeElement.relationToPrecedingElement"
			 role="presentation"
			 aria-hidden="true">
			{{treeElement.relationToPrecedingElement}}
		</div>

		<!-- Current tree element data -->
		<div class="wcd-flex-horizontal" role="presentation">
			<!-- Tree element left icon -->
			<div class="wcd-flex-vertical" role="presentation" aria-hidden="true" *ngIf="!treeElement.isAssociatedItem">
				<wcd-shared-icon *ngIf="treeElement.icon"
								[iconName]="treeElement.icon"
								class="tree-element--title--icon wcd-padding-small-top">
				</wcd-shared-icon>

				<div class="tree-connectors" [class.has-children]="treeElement.lastChild && !treeElement.isSubTreeMinimized"></div>
			</div>
			<div class="wcd-flex-vertical wcd-flex-1" role="presentation">
				<div class="element--border element"
					 [class.clickable]="treeElement.cyberEntityType && treeElement.cyberEntity?.id"
					 (keydown.enter)="alertPageService.updateDetailsTab(treeElement.cyberEntityType, treeElement.cyberEntity, treeElement.cyberEntity?.id)"
					 tabindex="0"
					 [attr.role]="treeElement.isAssociatedItem ? 'listitem' : 'treeitem'"
					 [attr.aria-label]="treeElement.isAssociatedItem ? treeElement.cyberEntityType : treeElement.cyberEntityType + treeElement.boldTitle"
					 [attr.aria-current]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id]"
					 [attr.aria-description]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id] ? ('alert_accessibility_selected_element_description' | i18n) : null"
					 [attr.aria-owns]="treeElement.lastChild ? 'childrenOf' + treeElement.id : null">
					<div class="selectable--outer"
						 [class.selected]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id]">
						<div class="wcd-flex-horizontal tree-element--title--box selectable--inner element--centered"
							 [class.associated]= "treeElement.isAssociatedItem"
							 [class.open-details]="!treeElement.isMinimized"
							 [class.selected]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id]">

							 <!-- Tree element left icon if it is an associated item-->
							<div (click)="alertPageService.updateDetailsTab(treeElement.cyberEntityType, treeElement.cyberEntity, treeElement.cyberEntity?.id)"
							class="wcd-flex-vertical" *ngIf="treeElement.isAssociatedItem">
								<wcd-shared-icon *ngIf="treeElement.icon"
												[iconName]="treeElement.icon"
												class="tree-element--title--icon" [class.associated]= "treeElement.isAssociatedItem">
								</wcd-shared-icon>
							</div>

							<!-- Tree element title -->
							<div (click)="alertPageService.updateDetailsTab(treeElement.cyberEntityType, treeElement.cyberEntity, treeElement.cyberEntity?.id)"
								 class="tree-element--title--box--text wcd-flex-1 wcd-padding-right"
								 data-track-type="Button"
								 [attr.data-track-id]="treeElement.cyberEntityType + (treeElement.isAssociatedItem ? '-associated-item' : '-tree-element-tile')"
								 [attr.data-track-value]="treeElement.cyberEntity?.id">
								<div class="wcd-flex-horizontal">
									<span class="wcd-flex-1
												wcd-text-overflow-ellipsis-nowrap
												wcd-text-overflow-ellipsis"
										[wcdTooltip]="(treeElement.nonBoldTitlePrefix ? treeElement.nonBoldTitlePrefix + ' ' : '') +
													(treeElement.boldTitle ? treeElement.boldTitle + ' ': '') +
													(treeElement.nonBoldTitleSuffix ? treeElement.nonBoldTitleSuffix : '')">
										{{treeElement.nonBoldTitlePrefix}}
										<span class="wcd-font-weight-bold">{{treeElement.boldTitle}}</span>
										{{treeElement.nonBoldTitleSuffix}}
									</span>
								</div>
							</div>

							<entity-command-bar *ngIf="treeElement.cyberEntity && getEntityType(treeElement.cyberEntityType) as entityType"
												[entityType]="entityType"
												[entity]="treeElement.cyberEntity"
												[entities]="[treeElement.cyberEntity]"
												[overFlowActionsView]="true">
							</entity-command-bar>

							<!-- Tree element right details expander toggle -->
							<wcd-expander-button
								*ngIf="(treeElement.associatedItems | notEmpty) || (treeElement.allGenericFields | notEmpty)"
								[isExpanded]="!treeElement.isMinimized"
								[toggleOnTitleClick]="false"
								[arrowPosition]="'right'"
								[ariaLabel]="'alert.accessibility.process.tree.element.details.expand.toggle.label' | i18n"
								data-track-type="Button"
								[attr.data-track-id]="treeElement.cyberEntityType + (treeElement.isAssociatedItem ? '-associated-item-' : '-tree-element-tile-') + (treeElement.isMinimized ? 'expand' : 'collapse') + '-additional-details'"
								[attr.data-track-value]="treeElement.cyberEntity?.id"
								(isExpandedChange)="onTreeElementDetailsMinimizedChange(treeElement)">
							</wcd-expander-button>
						</div>

						<!-- Tree element details -->
						<div *ngIf="!treeElement.detailsNeverLoaded || !treeElement.isMinimized && (treeElement.allGenericFields | notEmpty)"
							[hidden]="treeElement.isMinimized"
							class="element selectable--inner wcd-padding-small-vertical wcd-padding-small-right"
							[class.selected]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id]"
							(click)="alertPageService.updateDetailsTab(treeElement.cyberEntityType, treeElement.cyberEntity, treeElement.cyberEntity?.id)"
							data-track-type="Button"
							[attr.data-track-id]="treeElement.cyberEntityType + (treeElement.isAssociatedItem ? '-associated-item' : '-tree-element-tile')"
							[attr.data-track-value]="treeElement.cyberEntity?.id"
							role="none">
							<generic-fields-list [genericFields]="treeElement.allGenericFields" [processTreeElementStyle]="true"></generic-fields-list>
						</div>

						<!-- Tree element associated items -->
						<dl *ngIf="!treeElement.detailsNeverLoaded || !treeElement.isMinimized && (treeElement.associatedItems | notEmpty)"
							[hidden]="treeElement.isMinimized"
							class="element selectable--inner wcd-padding-small-vertical wcd-padding-small-right key-values"
							[class.selected]="alertPageService.selectedTreeElements[treeElement.cyberEntity?.id]"
							role="list">
							<ng-container *ngFor="let treeElementAssociatedItem of treeElement.associatedItems" >
								<dt class="tree-element--details tree-element--details--associated-item
									tree-element--details--key
									wcd-text-overflow-ellipsis-nowrap
									wcd-text-overflow-ellipsis"
									(click)="alertPageService.updateDetailsTab(treeElement.cyberEntityType, treeElement.cyberEntity, treeElement.cyberEntity?.id)"
									data-track-type="Button"
									[attr.data-track-id]="treeElement.cyberEntityType + (treeElement.isAssociatedItem ? '-associated-item' : '-tree-element-tile')"
									[attr.data-track-value]="treeElement.cyberEntity?.id"
									[wcdTooltip]="treeElementAssociatedItem.relationToPrecedingElement"
									role="none">
								{{ treeElementAssociatedItem.relationToPrecedingElement }}
								</dt>

								<multiple-alerts-process-tree
									class="wcd-flex-1 tree-element--details tree-element--details--associated-item tree-element--details--key"
									data-track-type="Button"
									role="none"
									[attr.data-track-id]="treeElement.cyberEntityType + (treeElement.isAssociatedItem ? '-associated-item' : '-tree-element-tile')"
									[attr.data-track-value]="treeElement.cyberEntity?.id"
									[treeElements]="[treeElementAssociatedItem]"
									(doneRendering)="OnDoneRendering()"></multiple-alerts-process-tree>
							</ng-container>
						</dl>
					</div>
				</div>

				<!-- Tree element associated alerts -->
				<associated-alerts *ngIf="treeElement.associatedAlerts && treeElement.associatedAlerts.length > 0"
								   [associatedAlerts]="treeElement.associatedAlerts" role="presentation">
				</associated-alerts>
			</div>
		</div>

		<!-- Tree element left sub tree expander toggle -->
		<wcd-expander
			*ngIf="treeElement.lastChild != null"
			[nodeStyle]="treeElement.isRoot ? subTreeRootExpanderButtonStyle : subTreeExpanderButtonStyle"
			[isExpanded]="!treeElement.isSubTreeMinimized"
			[toggleOnTitleClick]="false"
			[arrowPosition]="'left'"
			[ariaRole]="'button'"
			[ariaLabel]="'alert.accessibility.process.tree.element.sub.tree.expand.toggle.label' | i18n"
			buttonRole="button"
			(isExpandedChange)="onSubTreeMinimizedChange(treeElement)">
			<div [attr.id]="'childrenOf' + treeElement.id">
				<ng-container *ngIf="!treeElement.subTreeNeverLoaded || !treeElement.isSubTreeMinimized" >
					<!-- Tree element middle children -->
					<ng-container *ngIf="treeElement.children">
							<div *ngFor="let treeElementMiddleChild of treeElement.children"
								class="wcd-flex-horizontal"
								role="presentation">
								<div class="wcd-flex-vertical" role="presentation" aria-hidden="true">
									<div class="tree-connectors tree-connectors--children tree-connectors--children--joint"></div>

									<div class="tree-connectors tree-connectors--children tree-connectors--children--vertical"></div>
								</div>

								<multiple-alerts-process-tree
									role="presentation"
									class="wcd-flex-1"
									[treeElements]="[treeElementMiddleChild]"
									(doneRendering)="OnDoneRendering()"></multiple-alerts-process-tree>
							</div>
					</ng-container>

					<!-- Tree element last children -->
					<div class="wcd-flex-horizontal" role="presentation">
						<div class="tree-connectors tree-connectors--children tree-connectors--children--joint" role="presentation" aria-hidden="true"></div>

						<multiple-alerts-process-tree
							class="wcd-flex-1"
							role="presentation"
							[treeElements]="[treeElement.lastChild]"
							(doneRendering)="OnDoneRendering()"></multiple-alerts-process-tree>
					</div>
				</ng-container>
			</div>
		 </wcd-expander>
	</div>
</section>
