import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { MtpWorkload } from '../../rbac/mtp-workload-enum';

@ApiCall({
	name: 'Get query MTP workloads',
	endpoint: 'hunting/query/mtpWorkloads',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	method: 'POST',
	parseQuery: (queryText: string) => ({ data: { QueryText: queryText } }),
	parse: ({ MtpWorkloads }) => MtpWorkloads,
})
export class HuntingQueryMtpWorkloadsApiCall extends ApiCallModel<Array<MtpWorkload>, string> {}

@ApiCall({
	name: 'Get query MTP workloads',
	endpoint: 'schema/query/mtpWorkloads',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
	parseQuery: (queryText: string) => ({ data: JSON.stringify(queryText) }),
	parse: ({ MtpWorkloads }) => MtpWorkloads,
})
export class HuntingQueryMtpWorkloadsApiCallVNext extends ApiCallModel<Array<MtpWorkload>, string> {}
