import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Asset, RecommendationContextType } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { TvmContextOptions } from '../../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { DataSet } from '@microsoft/paris';
import { Observable, BehaviorSubject } from 'rxjs';
import { TvmTextsService } from '../../../../tvm/services/tvm-texts.service';
import { TvmRecommendationContextService } from '../../../../tvm/services/tvm-recommendation-context.service';

@Component({
	selector: 'exposed-machines-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './exposed-machines.details.component.html',
})
export class ExposedMachinesDetailsComponent implements OnInit {
	SpinnerSize = SpinnerSize;
	isListCollapsed = true;
	oneDayAgo: number;

	readonly oneDay = 60 * 60 * 24 * 1000;

	//Recommendation context related fields:
	private _machinesTableHeadersCount: number = 2;
	private _collapsed: Array<boolean> = [];
	private _isRecommendationContextFeatureFlagEnabled = false;
	contextTableHeaders: Array<string> = [];
	showContextTable: boolean = false;
	contextTableColSpan: number = 1;
	machinesTableColSpan: number = 1;
	misconfiguredContextItemsCountHeaderTitle: string = '';
	recommendationContextIcon: String;

	assetsSubject$ = new BehaviorSubject<DataSet<Asset>>(null);
	softwareVersionsLinkText: string;

	@Input()
	set assets$(assets: Observable<DataSet<Asset>>) {
		assets.subscribe(assets => {
			this.processRecommendationContextTable(assets);
			this.assetsSubject$.next(assets);
		});
	}

	@Input() contextOptions: TvmContextOptions;
	@Input() includeProductivityAssessment: boolean;
	@Input() title?: string;
	@Input() isNetworkDevice?: boolean;
	@Input() loadingText?: string;
	@Input() includeLastSeenStatus?: boolean;
	@Input() includeOperatingSystem?: boolean = false;
	@Input() openByMachineNameNewTab?: boolean = false;
	@Input() isShowMore?: boolean = true;

	@Output() showMore: EventEmitter<void> = new EventEmitter<void>();
	@Output() export: EventEmitter<void> = new EventEmitter<void>();

	constructor(
		private featuresService: FeaturesService,
		public tvmTextsService: TvmTextsService,
		private recommendationContextService: TvmRecommendationContextService,
		private router: Router
	) {
		this.oneDayAgo = new Date().getTime() - this.oneDay;

		this._isRecommendationContextFeatureFlagEnabled = this.featuresService.isEnabled(
			Feature.TvmRecommendationContext
		);
	}

	ngOnInit() {
		if (this.includeProductivityAssessment) {
			this._machinesTableHeadersCount += 1;
		}
	}

	onItemClick(assetId: string) {
		if (this.openByMachineNameNewTab) {
			const baseCompareUrl = this.router.url;
			const baseUrl = baseCompareUrl.substring(0, baseCompareUrl.lastIndexOf('/'));
			window.open(baseUrl + `/machines/${assetId}/overview`, '_blank');
		} else {
			const link = this.featuresService.isEnabled(Feature.UpgradeMachinePage)
				? `/machines/${assetId}`
				: `/machine/${assetId}`;
			this.router.navigate([link]);
		}
	}

	openAllExposedMachines() {
		this.showMore.emit();
	}

	getLastSeenDate(asset: Asset): string {
		return new Date(asset.lastSeen).toLocaleString();
	}

	private processRecommendationContextTable(assets: DataSet<Asset>) {
		this.showContextTable =
			this._isRecommendationContextFeatureFlagEnabled &&
			assets.items.some(
				asset =>
					asset.recommendationContext &&
					asset.recommendationContext.length > 0 &&
					!asset.recommendationContext.some(
						contextItem => contextItem.contextType === RecommendationContextType.Unknown
					)
			);
		if (this.showContextTable) {
			// Build the context table according to the type of the context:
			this.buildContext(this.recommendationContextService.getRecommendationContextTypeByAssets(assets));

			this.updateColSpanValues();
			this.initContextCollapseStatesArray(assets.items.length);
		}
	}

	getContextItemCollapseState(i: number) {
		return this._collapsed[i];
	}

	toggleContextItemCollapseState(i: number) {
		this._collapsed[i] = !this._collapsed[i];
	}

	private buildContext(recommendationContextType: RecommendationContextType) {
		this.contextTableHeaders = this.recommendationContextService.getRecommendationContextTableHeadersByType(
			recommendationContextType
		);
		this.misconfiguredContextItemsCountHeaderTitle = this.tvmTextsService.recommendationContextToCountHeader[
			recommendationContextType
		];
		this._machinesTableHeadersCount += 1;
		this.recommendationContextIcon = this.recommendationContextService.getRecommendationContextKeyIconNameByType(
			recommendationContextType
		);
	}

	private updateColSpanValues() {
		if (this._machinesTableHeadersCount < this.contextTableHeaders.length) {
			this.machinesTableColSpan += this.contextTableHeaders.length - this._machinesTableHeadersCount;
		} else {
			this.contextTableColSpan += this._machinesTableHeadersCount - this.contextTableHeaders.length;
		}
	}

	private initContextCollapseStatesArray(assetsCount) {
		for (var i = 0; i < assetsCount; i++) {
			this._collapsed[i] = false;
		}
	}
}
