import { Injectable } from '@angular/core';
import { CsvService } from '../../../shared/services/csv.service';
import { ServiceUrlsService } from '@wcd/app-config';

const EXPORT_TO_CSV_TOKEN_URL = 'ti/authtoken';
const EXPORT_TO_CSV_URL = 'ti/csv';

export interface DownloadCustomTiIndicatorCsvParams {
	readonly type: string;
	readonly downloadImportCsvSample: boolean;
}

@Injectable()
export class CustomTiIndicatorBackendService {
	constructor(private serviceUrlsService: ServiceUrlsService, private readonly csvService: CsvService) {}

	get apiUrl(): string {
		return this.serviceUrlsService.userRequests;
	}

	downloadCsv(downloadParams: DownloadCustomTiIndicatorCsvParams): Promise<void> {
		const params: Record<string, string> = {
			type: downloadParams.type,
			downloadImportCsvSample: downloadParams.downloadImportCsvSample.toString(),
		};

		return this.csvService.downloadCsv({
			tokenUrl: `${this.apiUrl}/${EXPORT_TO_CSV_TOKEN_URL}`,
			apiUrl: `${this.apiUrl}/${EXPORT_TO_CSV_URL}`,
			params: params,
		});
	}
}
