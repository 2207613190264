var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { merge } from 'lodash-es';
import { DataTableField } from '@wcd/datatable';
// @dynamic
var DataviewField = /** @class */ (function (_super) {
    __extends(DataviewField, _super);
    function DataviewField(dataViewFieldConfig) {
        var _this = _super.call(this, dataViewFieldConfig) || this;
        Object.assign(_this, {
            enabledByDefault: dataViewFieldConfig.enabledByDefault !== false,
            enabled: dataViewFieldConfig.enabledByDefault !== false,
            alwaysDisplay: dataViewFieldConfig.alwaysDisplay === true,
        });
        Object.freeze(_this);
        return _this;
    }
    /**
     * Small helper to easily map an array of DataviewFieldConfig objects to DataviewFields.
     */
    DataviewField.fromList = function (fields) {
        return fields.filter(Boolean).map(function (field) { return new DataviewField(field); });
    };
    DataviewField.prototype.clone = function (data) {
        return new DataviewField(merge({}, this, data));
    };
    return DataviewField;
}(DataTableField));
export { DataviewField };
