var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, EventEmitter, OnChanges, OnDestroy, OnInit, SimpleChanges, TemplateRef, } from '@angular/core';
import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { compact, uniqueId } from 'lodash-es';
import { ItemActionType } from '../../../dataviews/models/item-action.model';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { CommandBarItemService } from '../../../shared/components/command-bar/command-bar-item.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { EntityViewType } from '../../models/entity-view-type.enum';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RoutesService } from '@wcd/shared';
import { Router } from '@angular/router';
import { EntityCommandBarDisplayMode } from '../../models/entity-command-bar-display-mode.enum';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
var overFlowCommandBarHeight = 36;
var overFlowCommandBarStyles = {
    root: {
        height: overFlowCommandBarHeight,
        padding: 0,
    },
};
// make link look like a button, and override SCC underline styles
var buttonStyles = {
    rootHovered: {
        textDecoration: 'none !important',
    },
    rootFocused: {
        textDecoration: 'none !important',
    },
    rootDisabled: {
        pointerEvents: 'inherit',
    },
};
var EntityCommandBarComponent = /** @class */ (function () {
    function EntityCommandBarComponent(router, commandBarItemService, routesService, activatedEntity, changeDetectorRef, i18nService) {
        this.router = router;
        this.commandBarItemService = commandBarItemService;
        this.routesService = routesService;
        this.activatedEntity = activatedEntity;
        this.changeDetectorRef = changeDetectorRef;
        this.i18nService = i18nService;
        this.isLoadingEntitySubject$ = new BehaviorSubject(false);
        this.loadEntityError = false;
        this.isUserExposed$ = new BehaviorSubject(false);
        this.overFlowActionsView = false;
        this.entityCommandBarDisplayMode = null;
        this.commandBarAction = new EventEmitter();
        this.isRunningAction$ = new BehaviorSubject(false);
        this.overFlowCommandBarStyles = overFlowCommandBarStyles;
        this._tagsSubject$ = new Subject();
        this.tagEditOpen = false;
        this.destroyed = false;
    }
    Object.defineProperty(EntityCommandBarComponent.prototype, "isSingleEntity", {
        get: function () {
            return this.entities && this.entities.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    EntityCommandBarComponent.prototype.ngOnInit = function () {
        this.setActions();
    };
    EntityCommandBarComponent.prototype.ngOnChanges = function (changes) {
        if (changes.lastSetActionsTime || changes.options) {
            this.setActions();
        }
        if (changes.lastDetailsActionTime) {
            this.runActionById(this.detailsActionId);
        }
        if (changes.entities) {
            this.updateTags();
        }
        this.changeDetectorRef.markForCheck();
    };
    EntityCommandBarComponent.prototype.ngOnDestroy = function () {
        this.destroyed = true;
    };
    EntityCommandBarComponent.prototype.updateTags = function () {
        if (this.entityType.getTags) {
            this.tags$ = merge(toObservable(this.entityType.getTags(this.entities)), this._tagsSubject$).pipe(shareReplay(1));
        }
    };
    EntityCommandBarComponent.prototype.setActions = function () {
        var _this = this;
        this.actions$ = toObservable(this.entityType.getActions
            ? this.entityType.getActions(this.entities, this.options, this.entities.length === 1
                ? EntityViewType.SingleEntitySidePanel
                : EntityViewType.MultipleEntitySidePanel, this.entityCommandBarDisplayMode || EntityCommandBarDisplayMode.Default) || []
            : []).pipe(catchError(function (err) {
            return of([]);
        }));
        // The item actions, disabled and with a progress cursor if needed, based on the loading state of the entity in the panel or if an action is currently running.
        var disableOverrideConsideredActions$ = combineLatest(this.actions$, this.isLoadingEntitySubject$, this.isRunningAction$).pipe(map(function (_a) {
            var itemActions = _a[0], isLoadingEntity = _a[1], isRunningAction = _a[2];
            var newItemActions = itemActions.filter(function (item) { return item.type != ItemActionType.Hidden; });
            return newItemActions && newItemActions.length
                ? newItemActions.map(function (itemAction) { return (__assign({}, itemAction, { disabled: itemAction.disabled ||
                        (!itemAction.allowDisplayWhileLoading && isLoadingEntity) ||
                        isRunningAction, buttonClass: isRunningAction && 'cursor-progress', tooltip: itemAction.tooltip ||
                        itemAction.name ||
                        _this.i18nService.get(itemAction.nameKey) })); })
                : [];
        }));
        var actionsCommandBarItems$ = disableOverrideConsideredActions$.pipe(map(function (itemActions) { return itemActions.map(function (itemAction) { return _this.actionToCommandBarItem(itemAction); }); }));
        this.commandBarItems$ = actionsCommandBarItems$.pipe(startWith([]), map(function (actionsCommandBarItems) { return compact([_this.openItemCommandBarItem].concat(actionsCommandBarItems)); }));
    };
    EntityCommandBarComponent.prototype.actionToCommandBarItem = function (itemAction) {
        var _this = this;
        return this.commandBarItemService.fromItemActionModel({
            itemActionModel: itemAction,
            tagsTemplateRef: this.tagsItemTemplateRef,
            onClick: function (itemActionModel) {
                _this.runAction(itemActionModel);
            },
        });
    };
    Object.defineProperty(EntityCommandBarComponent.prototype, "openItemCommandBarItem", {
        get: function () {
            if (this.entityType &&
                this.entityType.getShowOpenPageLink &&
                !this.entityType.getShowOpenPageLink(this.entity)) {
                return null;
            }
            if (this.entityType && this.entityType.getItemParams) {
                try {
                    var hideOpenLink = this.entityType.getItemParams(this.entities[0]).hideOpenLink;
                    if (hideOpenLink && hideOpenLink()) {
                        return null;
                    }
                }
                catch (e) { }
            }
            if (this.options && this.options.hideOpenLink) {
                return null;
            }
            var entitiesLink = this.entityType.getEntitiesLink
                ? this.entityType.getEntitiesLink(this.entities)
                : null;
            var entityName = this.isSingleEntity
                ? (this.entityType.entitySingularNameKey &&
                    this.i18nService.get(this.entityType.entitySingularNameKey)) ||
                    this.entityType.entity.singularName.toLowerCase()
                : (this.entityType.entityPluralNameKey &&
                    this.i18nService.get(this.entityType.entityPluralNameKey)) ||
                    this.entityType.entity.pluralName.toLowerCase();
            var entitiesLinkText = entitiesLink && this.entityType.getEntitiesLinkText
                ? this.entityType.getEntitiesLinkText(this.entities)
                : this.i18nService.get('entityCommon_commandBar_openPage', {
                    entity: entityName.toLowerCase(),
                });
            var entitiesLinkTooltip = this.loadEntityError ?
                this.i18nService.get('entityCommon_commandBar_openPage_unavailble', {
                    entity: entityName.toLowerCase(),
                }) : entitiesLink && this.entityType.getEntitiesLinkTooltip
                ? this.entityType.getEntitiesLinkTooltip(this.entities)
                : entitiesLinkText;
            var entitiesLinkDisabled = this.loadEntityError || (this.entityType.getEntitiesLinkDisabled
                ? this.entityType.getEntitiesLinkDisabled(this.entities)
                : false);
            var urlInContext = entitiesLink;
            var isExternal = false;
            if (urlInContext) {
                if (this.entityType.getNavigationModel &&
                    this.entityType.getNavigationModel(this.entities[0]) &&
                    this.entityType.getNavigationModel(this.entities[0]).sccInternalRedirectDefinition) {
                    var navModel = this.entityType.getNavigationModel(this.entities[0]);
                    var sccRouteDef = navModel.sccInternalRedirectDefinition(navModel.externalLink);
                    urlInContext = sccRouteDef;
                }
                else {
                    isExternal =
                        this.entityType.getUseExternalRouting && this.entityType.getUseExternalRouting(this.entities);
                    var mdatpFromSccUrl = !isExternal && this.routesService.getMdatpFromSccLinkConfig(entitiesLink);
                    if (mdatpFromSccUrl) {
                        urlInContext = mdatpFromSccUrl.url;
                        isExternal = mdatpFromSccUrl.isExternal;
                    }
                }
            }
            return this.createOpenEntityCommandBarItem({
                text: entitiesLinkText,
                tooltip: entitiesLinkTooltip,
                path: urlInContext,
                isExternal: isExternal,
                disabled: entitiesLinkDisabled,
            });
        },
        enumerable: true,
        configurable: true
    });
    EntityCommandBarComponent.prototype.createOpenEntityCommandBarItem = function (linkConfig) {
        if (!linkConfig || !linkConfig.path) {
            return null;
        }
        var openEntityCommandBarItem = {
            key: uniqueId('openEntityLink'),
            text: linkConfig.text,
            title: linkConfig.tooltip,
            iconProps: {
                iconName: FabricIconNames.PageRight,
            },
            buttonStyles: buttonStyles,
            disabled: linkConfig.disabled,
        };
        openEntityCommandBarItem = this.commandBarItemService.withRouting(openEntityCommandBarItem, {
            link: [linkConfig.path],
            isExternal: linkConfig.isExternal,
        });
        openEntityCommandBarItem = this.commandBarItemService.withTracking(openEntityCommandBarItem, {
            id: "EntityPanelLinkToEntityPage_" + linkConfig.text,
            type: TrackingEventType.Navigation,
        });
        return openEntityCommandBarItem;
    };
    EntityCommandBarComponent.prototype.runActionById = function (actionId) {
        var _this = this;
        if (!actionId)
            return;
        this.actions$
            .pipe(map(function (actions) { return actions.find(function (action) { return action.id === actionId; }); }), filter(function (action) { return Boolean(action); }))
            .subscribe(function (action) { return _this.runAction(action); });
    };
    EntityCommandBarComponent.prototype.runAction = function (action) {
        return __awaiter(this, void 0, void 0, function () {
            var data;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.isRunningAction$.next(true);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, , 3, 4]);
                        return [4 /*yield*/, action.method(this.entities, this.options)];
                    case 2:
                        data = _a.sent();
                        this.commandBarAction.emit({ action: action, data: data, tags: null });
                        return [3 /*break*/, 4];
                    case 3:
                        this.isRunningAction$.next(false);
                        if (!this.destroyed) {
                            this.changeDetectorRef.detectChanges();
                        }
                        return [7 /*endfinally*/];
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    EntityCommandBarComponent.prototype.onEntitiesLinkClick = function () {
        if (this.isSingleEntity) {
            var entity = this.entity || (this.entities && this.entities[0]);
            this.activatedEntity.setNextEntity(entity);
        }
    };
    EntityCommandBarComponent.prototype.onChangeTags = function (tags, action) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.isRunningAction$.next(true);
                this._tagsSubject$.next(tags);
                this.changeDetectorRef.markForCheck();
                this.commandBarAction.emit({ action: action, data: null, tags: tags });
                this.isRunningAction$.next(false);
                return [2 /*return*/];
            });
        });
    };
    EntityCommandBarComponent.prototype.tagsEditorToggled = function (open) {
        this.tagEditOpen = open;
    };
    return EntityCommandBarComponent;
}());
export { EntityCommandBarComponent };
