import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '@wcd/domain';

@Entity({
	singularName: 'Magellan devices with same IPs',
	pluralName: 'Magellan devices with same IPs',
	endpoint: (_, query) => {
		return `api/Devices/${query.where['id']}/LastIpInfo`;
	},
	idProperty: 'DeviceId',
	readonly: true,
	parseItemQuery: (id) => `Devices/${id}/LastIpInfo`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class DevicesWithTheSameIps extends ModelBase {
	@EntityField({ data: 'DeviceId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'DevicesWithSameLastIp' })
	devicesWithSameLastIp: string[];
}
