var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationType } from '../remediation/remediation-task-type.enum';
import { Mitigation } from '../../common/remediation/remediation-mitigation.value-object';
var ɵ0 = function (data) { return data.type === RemediationType.Update; };
var SoftwareArgs = /** @class */ (function (_super) {
    __extends(SoftwareArgs, _super);
    function SoftwareArgs() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'type',
        }),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "taskType", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'vendorId',
        }),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "vendorId", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'nameId',
        }),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "nameId", void 0);
    __decorate([
        EntityField({
            data: 'productId',
        }),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "productId", void 0);
    __decorate([
        EntityField({
            require: ɵ0,
            data: 'recommendedVersion',
        }),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "recommendedVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "recommendedVendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SoftwareArgs.prototype, "recommendedProgram", void 0);
    __decorate([
        EntityField({
            data: 'isEOL',
        }),
        __metadata("design:type", Boolean)
    ], SoftwareArgs.prototype, "isEOL", void 0);
    __decorate([
        EntityField({
            data: 'isUpcomingEol',
        }),
        __metadata("design:type", Boolean)
    ], SoftwareArgs.prototype, "isUpcomingEol", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], SoftwareArgs.prototype, "hasEolVersions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], SoftwareArgs.prototype, "hasUpcomingEolVersions", void 0);
    __decorate([
        EntityField({
            data: 'mitigation',
        }),
        __metadata("design:type", Mitigation)
    ], SoftwareArgs.prototype, "mitigation", void 0);
    SoftwareArgs = __decorate([
        ValueObject({
            singularName: 'Software arguments',
            pluralName: 'Software arguments',
        })
    ], SoftwareArgs);
    return SoftwareArgs;
}(ModelBase));
export { SoftwareArgs };
export { ɵ0 };
