var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-alt-text */
import { EventEmitter, OnInit } from '@angular/core';
import { of, ReplaySubject, Subject, timer } from 'rxjs';
import { catchError, delayWhen, first, map, retryWhen, scan, switchMap, tap } from 'rxjs/operators';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { EvaluationService } from '../../services/evaluation.service';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
var SummaryStepComponent = /** @class */ (function (_super) {
    __extends(SummaryStepComponent, _super);
    function SummaryStepComponent(evaluationService, i18nService, dialogsService) {
        var _this = _super.call(this) || this;
        _this.evaluationService = evaluationService;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.onLabCreated = new EventEmitter();
        _this.vendorsCount = 0;
        _this.saving$ = new Subject();
        _this.error$ = new ReplaySubject(1);
        _this.MessageBarType = MessageBarType;
        _this.labCreated$ = new ReplaySubject(1);
        _this.createLabAndUpdateSimulators = function () {
            _this.setBackButtonEnabled(false);
            _this.setStepValidation(false);
            _this.saving$.next(true);
            return _this.labCreated$.pipe(first(), 
            // First create the lab and then update the agents configurations
            // NOTE: Do not repeat the lab creation in case of error
            switchMap(function (labCreated) {
                if (labCreated) {
                    return of(true);
                }
                return _this.evaluationService.createEvaluationLab({
                    id: 0,
                    maxNumberOfMachines: _this.data.labConfiguration.numberOfMachines,
                    machineExpirationInHours: _this.data.labConfiguration.hoursPerMachine,
                });
            }), tap(function () { return _this.labCreated$.next(true); }), switchMap(function () {
                var agentAdditionalSettings = {
                    FirstName: _this.data.firstName,
                    LastName: _this.data.lastName,
                    EmailAddress: _this.data.email,
                };
                return _this.evaluationService.setSimulatorsConfigurations(agentAdditionalSettings, _this.data.withSafeBreach, _this.data.withAiq);
            }), tap(function () { return _this.onLabCreated.emit(); }), map(function () { return true; }), retryWhen(function (errors) {
                return errors.pipe(scan(function (errorCount, err) {
                    if (errorCount > 2)
                        throw err;
                    return errorCount + 1;
                }, 0), delayWhen(function () { return timer(1000); }));
            }), catchError(function (error) {
                return _this.labCreated$;
            }), switchMap(function (labCreated) {
                if (labCreated) {
                    _this.dialogsService.showSnackbar({
                        icon: 'ErrorBadge',
                        text: _this.i18nService.get('evaluation.lab.setupPanel.summaryStep.simulatorError'),
                        className: 'color-box-failureBackground',
                    });
                    _this.onLabCreated.emit();
                }
                else {
                    _this.error$.next(_this.i18nService.get('evaluation.lab.setupPanel.summaryStep.createLabError'));
                }
                _this.setBackButtonEnabled(true);
                _this.setStepValidation(true);
                _this.saving$.next(false);
                return of(false);
            }));
        };
        _this.labCreated$.next(false);
        return _this;
    }
    SummaryStepComponent.prototype.ngOnInit = function () {
        this.setOnNext(this.createLabAndUpdateSimulators);
        if (this.data.withAiq) {
            this.vendorsCount++;
        }
        if (this.data.withSafeBreach) {
            this.vendorsCount++;
        }
        this.setStepValidation(true);
    };
    return SummaryStepComponent;
}(WizardBaseStep));
export { SummaryStepComponent };
