import { Component, Injector, OnDestroy } from '@angular/core';
import { LiveResponseSession, Machine } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { I18nService } from '@wcd/i18n';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'live-response-session-entity-details',
	template: `
		<ng-container *ngIf="session">
			<div [ngClass]="{ 'wcd-full-height wcd-flex-vertical': mode === 'EntityPage' }">
				<wcd-collapsible-section [label]="i18nService.strings.liveResponse_session_deviceDetails">
					<a (click)="openMachinePanel()" class="wcd-padding-bottom" href="javascript:void(0);">
						{{ i18nService.strings.liveResponse_session_deviceDetailsLink }}
					</a>
				</wcd-collapsible-section>
				<wcd-collapsible-section [label]="i18nService.strings.liveResponse_session_generalInfo">
					<dl [class.key-values]="asKeyValueList" role="none">
						<dt>{{ i18nService.strings.liveResponse_session_id }}</dt>
						<dd>
							{{ session.id }}
						</dd>
						<dt>{{ i18nService.strings.liveResponse_session_createdBy }}</dt>
						<dd>
							{{ session.initiatingUser }}
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_started }}</dt>
						<dd>
							{{ session.startDate | date: 'short' }}
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_ended }}</dt>
						<dd>
							<ng-container *ngIf="session.endDate; else notAvailable">
								{{ session.endDate | date: 'short' }}
							</ng-container>
							<ng-template #notAvailable>
								{{ i18nService.strings.notAvailable_short }}
							</ng-template>
						</dd>

						<dt>{{ i18nService.strings.liveResponse_session_duration }}</dt>
						<dd>
							<stopwatch [start]="session.startDate" [end]="session.endDate"></stopwatch>
						</dd>
					</dl>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					*ngIf="session.machine"
					[label]="i18nService.strings.liveResponse_session_deviceInfo"
				>
					<device-details [machine]="session.machine" [includeMachineName]="false"></device-details>
				</wcd-collapsible-section>
			</div>
		</ng-container>
	`,
})
export class LiveResponseSessionEntityDetailsComponent extends EntityDetailsComponentBase<
	LiveResponseSession
> implements OnDestroy{
	constructor(
		injector: Injector,
		public i18nService: I18nService,
		public readonly entityPanelsService: EntityPanelsService,
		private readonly route: ActivatedRoute,
		private readonly router: Router,
	) {
		super(injector);
	}

	ngOnInit() {
		super.ngOnInit();
		this.setupEntityUpdateListener();
	}
	get session(): LiveResponseSession {
		return this.entity;
	}

	openMachinePanel() {
		this.entityPanelsService.showEntity(Machine, this.entity.machine);
	}

	entityUpdateListener = (e: CustomEvent) => {
		if (e.detail.newEntityId !== this.entity.id) {
			const entityTypePath = this.route.parent.routeConfig.path;
			this.router.navigate(
				['/' + entityTypePath, e.detail.newEntityId , e.detail.entityData ? e.detail.entityData : {}],
				{
					queryParamsHandling: "merge", // remove to replace all query params by provided
				});
		}
	}

	clearEntityUpdateListener () {
		document.removeEventListener(`liveResponseSessionEntityUpdated`, this.entityUpdateListener);
	}

	setupEntityUpdateListener () {
			this.clearEntityUpdateListener();
			document.addEventListener(`liveResponseSessionEntityUpdated`, this.entityUpdateListener);
		}

	ngOnDestroy() {
		this.clearEntityUpdateListener();
	}
}
