
		<div
			*ngIf="severitiesCountArray; else noAlerts"
			class="wcd-flex-horizontal"
			[class.wcd-flex-wrap-horizontal]="wrap !== false"
		>
			<severity-bar
				class="SeveritySummary__bar"
				*ngFor="let severity of severitiesCountArray"
				[severity]="severity.type"
				[count]="severity.count"
				[size]="size"
			>
			</severity-bar>
		</div>

		<ng-template #noAlerts>
			<div class="subtle">No alerts</div>
		</ng-template>
	