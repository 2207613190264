import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('dynController', [
	'$compile',
	'$parse',
	function($compile, $parse) {
		return {
			restrict: 'A',
			terminal: true,
			priority: 100000,
			scope: {
				template: '=',
				controller: '=',
			},
			link: function(scope, elem, attrs) {
				// Add controller
				elem.removeAttr('dyn-controller');
				elem.attr('ng-controller', scope['controller']);

				// Add template
				elem.append(scope['template']);

				// Compile the element with the ng-controller attribute
				$compile(elem)(scope);
			},
		};
	},
]);
