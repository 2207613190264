var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FilterValuesChecklistComponent, } from '../checklist/filter-values.checklist.component';
import { ChangeDetectorRef } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { I18nService } from '@wcd/i18n';
var FilterValuesChecklistWithSearchComponent = /** @class */ (function (_super) {
    __extends(FilterValuesChecklistWithSearchComponent, _super);
    function FilterValuesChecklistWithSearchComponent(changeDetectorRef, i18nService) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.i18nService = i18nService;
        _this.fieldName = null;
        return _this;
    }
    Object.defineProperty(FilterValuesChecklistWithSearchComponent.prototype, "data", {
        set: function (data) {
            if (this.searchValues) {
                var _a = data || { values: [], count: null }, values = _a.values, count = _a.count;
                this._allValues = {
                    count: count,
                    values: values.concat(this.searchValues),
                };
            }
            else {
                this._allValues = data;
            }
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistWithSearchComponent.prototype, "config", {
        get: function () {
            return this._config;
        },
        set: function (conf) {
            this._config = conf;
            this.changeDetectorRef.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistWithSearchComponent.prototype, "allValues", {
        get: function () {
            return this._allValues;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistWithSearchComponent.prototype, "value", {
        get: function () {
            return this.checklist.value;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesChecklistWithSearchComponent.prototype.setSelectedValues = function (selectedValues) {
        this.checklist.setSelectedValues(selectedValues);
    };
    FilterValuesChecklistWithSearchComponent.prototype.serialize = function () {
        return this.checklist.serialize();
    };
    FilterValuesChecklistWithSearchComponent.prototype.deserialize = function (serializedSelectedValues) {
        return this.checklist.deserialize(serializedSelectedValues);
    };
    FilterValuesChecklistWithSearchComponent.prototype.addSearchValue = function (searchValue) {
        var _a = this._allValues || { values: [], count: null }, values = _a.values, count = _a.count;
        this._allValues = {
            count: count,
            values: values.concat([searchValue]),
        };
        this.searchValues = this.searchValues ? this.searchValues.concat([searchValue]) : [searchValue];
        this.selectedValues = (this.selectedValues || []).concat([searchValue.value]);
        this.setSelectedValues(this.selectedValues);
        this.filterValuesChange.emit(this.checklist.getSelectedValues());
        this.changeDetectorRef.markForCheck();
    };
    return FilterValuesChecklistWithSearchComponent;
}(FilterValuesComponent));
export { FilterValuesChecklistWithSearchComponent };
