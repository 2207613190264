import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ChartComponent, ChartSettings } from '../chart.component.base';
import { format } from 'd3-format';
import { select } from 'd3-selection';
import * as c3 from 'c3';
import * as d3 from 'd3';

@Component({
	selector: 'wcd-stacked-bars-chart',
	template: `
		<div class="chart" [attr.aria-label]="'charts.stackedBarsChart' | i18n"></div>
	`,
})
export class StackedBarsChartComponent extends ChartComponent {
	@Input() allowXLabelClick: boolean;
	@Output() xLabelClick: EventEmitter<number> = new EventEmitter<number>();

	constructor(elementRef: ElementRef) {
		super(elementRef);
	}

	protected chartSettings: ChartSettings = {
		chartType: 'bar',
		useValues: true,
		xProperty: 'label',
		setColors: true,
		stackGroups: true,
		data: {
			order: 'desc',
		},
		options: {
			data: {},
			axis: {
				y: {
					tick: {
						format: format('d'),
					},
				},
			},
			onrendered: this.onRendered.bind(this),
			oninit: this.onInit.bind(this),
		},
	};

	private onRendered() {
		if (!this.allowXLabelClick) return;
		const divElement = select(this.el);
		const xAxis = divElement.select('.c3-axis-x');
		xAxis
			.selectAll('.tick')
			.select('text')
			.attr('class', 'btn-link ms-fill-blue')
			.on('click', (index: number) => {
				this.xLabelClick.emit(index);
			});
	}

	onInit(){
		setTimeout( () => {
			const options: c3.ChartConfiguration = this.getChartOptions();
			d3.selectAll(this.el.querySelectorAll(`path.c3-bar`))
				.attr('tabindex', '0')
				.attr('focusable', 'true')
				.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
					d3.select(nodesList[index]).attr(
						'aria-label',
						`${dataPoint.id}, ${options.axis.x.categories[dataPoint.x]}, ${dataPoint.value}, `
					);
				});
		}, 0);
	}
}
