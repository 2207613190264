import { NgModule } from "@angular/core";
import { GlobalEntityTypesService } from "../../../global_entities/services/global-entity-types.service";
import { NetworkDevicesFieldsService } from "./services/network-devices.fields.service";
import { NetworkDeviceEntityTypeService } from "./services/network-devices.entity-type.service";
import { SharedModule } from "../../../shared/shared.module";
import { NetworkDevicesService } from "./services/network-devices.service";
import { NetworkDeviceEntityPanelComponent } from "./components/network-device.entity-panel.components";
import { GlobalEntitiesModule } from "../../../global_entities/global-entities.module";
import { NetworkDeviceEntityStatusComponent } from "./components/network-device-entity-status.component";
import { FabButtonModule } from "@angular-react/fabric";

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, FabButtonModule],
	declarations: [NetworkDeviceEntityPanelComponent, NetworkDeviceEntityStatusComponent],
	exports: [NetworkDeviceEntityPanelComponent],
	entryComponents: [NetworkDeviceEntityPanelComponent, NetworkDeviceEntityStatusComponent],
	providers: [NetworkDevicesService, NetworkDevicesFieldsService, NetworkDeviceEntityTypeService],
})
export class NetworkDevicesEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(NetworkDeviceEntityTypeService);
	}
}