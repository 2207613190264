/* tslint:disable:template-click-events-have-key-events */
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SecurityRecommendation, VulnerabilityWorkaround } from '@wcd/domain';
import { flatMap } from 'lodash-es';

@Component({
	selector: 'recommendation-workarounds-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-workarounds.panel.component.html',
})
export class RecommendationWorkaroundsPanelComponent extends PanelContainer implements OnInit {
	@Input() securityRecommendation: SecurityRecommendation;
	@Input() programName: string;
	scids: Array<string>;
	showWorkaround: boolean;
	workarounds: Array<VulnerabilityWorkaround>;

	constructor(router: Router) {
		super(router);
	}

	ngOnInit() {
		this.workarounds = this.securityRecommendation.workarounds.sort((w1, w2) =>
			w1.id.localeCompare(w2.id)
		);
		this.scids = flatMap(this.workarounds, workaround => workaround.scids).filter(scid => scid);
		this.showWorkaround = this.securityRecommendation.workarounds.some(
			workaround => !!workaround.details
		);
	}

	openScaRecommendation(scid: string) {
		this.router.navigate(['/security-recommendations', `sca-_-${scid}`], {
			queryParams: {
				search: scid,
			},
		});
	}
}
