/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../reports/components/report.component.ngfactory";
import * as i2 from "../../../reports/components/report.component";
import * as i3 from "../../../shared-reports/services/reports.service";
import * as i4 from "../../../insights/services/app-insights.service";
import * as i5 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/polling.service";
import * as i10 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i11 from "../../../shared/components/tabs/tabs.component.ngfactory";
import * as i12 from "../../../shared/components/tabs/tabs.component";
import * as i13 from "@angular/common";
import * as i14 from "./alert-entity.component";
import * as i15 from "../../../shared/services/title.service";
import * as i16 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_AlertEntityComponent = [];
var RenderType_AlertEntityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertEntityComponent, data: {} });
export { RenderType_AlertEntityComponent as RenderType_AlertEntityComponent };
function View_AlertEntityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "report", [], [[8, "hidden", 0]], null, null, i1.View_ReportComponent_0, i1.RenderType_ReportComponent)), i0.ɵdid(1, 4964352, null, 0, i2.ReportComponent, [i3.ReportsService, i4.AppInsightsService, i5.AppConfigService, i6.ActivatedRoute, i6.Router, i0.ComponentFactoryResolver, i7.LocaleConfigService, i8.I18nService, i9.PollingService, i10.TzDateService], { report: [0, "report"], showControls: [1, "showControls"], showPollingError: [2, "showPollingError"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.report; var currVal_2 = false; var currVal_3 = false; _ck(_v, 1, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isFullScreen; _ck(_v, 0, 0, currVal_0); }); }
function View_AlertEntityComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tabs", [], null, null, null, i11.View_TabsComponent_0, i11.RenderType_TabsComponent)), i0.ɵdid(3, 4833280, null, 0, i12.TabsComponent, [i0.ChangeDetectorRef, i6.ActivatedRoute, i6.Router], { tabsData: [0, "tabsData"], currentRouteIsActive: [1, "currentRouteIsActive"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_AlertEntityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 16, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertEntityComponent_1)), i0.ɵdid(4, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "wcd-flex-none wcd-margin-medium-horizontal wcd-margin-medium-bottom wcd-flex-spaceBetween-horizontal wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertEntityComponent_2)), i0.ɵdid(9, 16384, null, 0, i13.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 4, "div", [["class", "wcd-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(14, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(15, 212992, null, 0, i6.RouterOutlet, [i6.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.report; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.tabs; _ck(_v, 9, 0, currVal_1); _ck(_v, 15, 0); }, null); }
export function View_AlertEntityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AlertEntityComponent_0, RenderType_AlertEntityComponent)), i0.ɵdid(1, 245760, null, 0, i14.AlertEntityComponent, [i8.I18nService, i15.TitleService, i16.FeaturesService, i6.Router, i6.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertEntityComponentNgFactory = i0.ɵccf("ng-component", i14.AlertEntityComponent, View_AlertEntityComponent_Host_0, { entity: "entity", isFullScreen: "isFullScreen", action$: "action$", entityPageViewMode: "entityPageViewMode" }, { runAction$: "runAction$" }, []);
export { AlertEntityComponentNgFactory as AlertEntityComponentNgFactory };
