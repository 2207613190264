<!-- tslint:disable:template-click-events-have-key-events -->
<wcd-panel (close)="destroy()" [settings]="settings"
	class="wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="recommendation-workarounds-panel"
	data-track-component-type="Side Panel">
	<header class="wcd-margin-vertical wcd-padding-large-horizontal">
		<h3 #panelHeader class="wcd-flex-1 wcd-flex-center-vertical side-panel-title wcd-font-weight-bold">
				{{ 'tvm_securityRecommendation_workaroundPanel_title' | i18n}}
		</h3>
		<p>{{'tvm_securityRecommendation_workaroundPanel_subtitle' | i18n:{software: programName} }}<p>
	</header>
	<div class="wcd-margin-large-all">
		<div *ngIf="scids.length !== 0" class="wcd-flex-vertical">
			<h4 class="wcd-font-weight-bold">
					{{ 'tvm_securityRecommendation_workaroundPanel_related_scid_title' | i18n}}
			</h4>
			<p>{{ 'tvm_securityRecommendation_workaroundPanel_related_scid_description' | i18n}}</p>
			<ul>
				<li *ngFor="let scid of scids">
					<a (click)="openScaRecommendation(scid)">
						{{scid}}
					</a>
				</li>
			</ul>
		</div>

		<div *ngIf="showWorkaround" class="wcd-flex-vertical">
			<h4 class="wcd-font-weight-bold">
					{{ 'tvm_securityRecommendation_workaroundPanel_workaround_title' | i18n}}
			</h4>
			<div class="wcd-margin-small-vertical">{{ 'tvm_securityRecommendation_workaroundPanel_workaround_description' | i18n}}</div>
			<div class="wcd-margin-medium-vertical" *ngFor="let workaround of workarounds">
					<div class="title wcd-margin-small-bottom wcd-font-weight-bold">
							{{workaround.title}}
					</div>
					<div [innerHTML]="workaround.details"></div>
			</div>
		</div>
	</div>
</wcd-panel>
