import { DataQuery, EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { MailStats } from '../mail-stats.value-object';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: MailStats,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['vNextApi'] ? config.data.serviceUrls.k8s + '/ine/entitypagesservice/files' : config.data.serviceUrls.threatIntel;
	},
	endpoint: 'MailStats',
	getRelationshipData: (file: File) => ({
		sha1: file.sha1,
		sha256: file.sha256,
	}),
	fixedData: { lookingBackIndays: 30 },
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
})
export class FileMailStatsRelationship implements EntityRelationshipRepositoryType<File, MailStats> {}
