var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _this = this;
import { Injector } from '@angular/core';
import { SccAppPaths } from './app-routing.scc.module';
import { APC_HEADER_KEY, AuthService, tenantContextCache } from '@wcd/auth';
import { registerLocales } from './app.locales';
import { getPortalLanguage, I18nService } from '@wcd/i18n';
import { CodeEditorSettings } from '@wcd/code-editor';
import { AppInsightsService } from './insights/services/app-insights.service';
import { PreferencesService, TvmLicensesAngularService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { ajax } from 'rxjs/ajax';
import { defer, from, of } from 'rxjs';
import { sccHostService } from '@wcd/scc-interface';
import { createTheme } from '@uifabric/styling';
import { loadTheme } from 'office-ui-fabric-react';
import { NotificationsService } from './notifications/services/notifications.service';
import { SccProxyUtils } from '@wcd/app-config';
import { preFetch } from './app.scc.prefetch.svc';
import { finalize } from 'rxjs/operators';
var xLargePlusFont = {
    fontFamily: '\'Segoe UI\', \'Segoe UI Web (West European)\', \'Segoe UI\', -apple-system, BlinkMacSystemFont, \'Roboto\', \'Helvetica Neue\', sans-serif',
    MozOsxFontSmoothing: 'grayscale',
    WebkitFontSmoothing: 'antialiased',
    fontSize: '24px',
    fontWeight: 100,
};
registerLocales();
var ɵ0 = function (req) {
    var _a;
    if (!sccHostService.isSCC) {
        return of(req);
    }
    var overrideHeaders = {
        headers: __assign({}, req.headers, (_a = { 'Tenant-Id': sccHostService.loginUser.tenantId }, _a[APC_HEADER_KEY] = tenantContextCache.apcToken, _a['Accept-Language'] = getPortalLanguage(), _a)),
    };
    if (SccProxyUtils.urlMatchSccProxyPattern(req.url)) {
        return of(Object.assign(req, overrideHeaders));
    }
    return from(sccHostService.auth.getToken().then(function (token) {
        overrideHeaders.headers.authorization = "Bearer " + token;
        return Object.assign(req, overrideHeaders);
    }));
}, ɵ1 = function (config) {
    var url = typeof config === 'string' ? config : config.url;
    // only urls matching <whatever> will go to SCC's ajax service
    if (SccProxyUtils.urlMatchSccProxyPattern(url)) {
        //support comparability with axios
        //@ts-ignore
        var source_1 = window.axios.CancelToken.source();
        //@ts-ignore
        config = Object.assign({}, config, { data: config.body, cancelToken: source_1.token });
        return defer(function () { return sccHostService.ajax.request(config).then(function (res) {
            // We convert AxiosResponse to AjaxReponse as best we can which is what paris expects
            return __assign({ response: res.data }, res);
        }).catch(function (err) {
            // Convert the AxiosError to a subtype that looks like AjaxError for Paris consumers
            // https://github.com/ReactiveX/rxjs/blob/6.4.0/src/internal/observable/dom/AjaxObservable.ts#L468-L483
            err.xhr = err.response;
            err.status = err.request.status;
            err.responseType = err.request.responseType;
            err.response = Object.assign({}, err.response, err.request.response);
            err.request.url = typeof config === 'string' ? config : config.url;
            err.request.async = true;
            err.request.body = typeof config === 'string' ? null : config.body;
            err.request.timeout = typeof config === 'string' ? 0 : config.timeout;
            err.request.headers = typeof config === 'string' ? {} : config.headers;
            throw err;
        }); }).pipe(finalize(function () { return source_1.cancel('axios cancel request'); }));
    }
    return ajax(config);
};
var pari = new Paris({
    apiRoot: '/api',
    intercept: ɵ0,
    http: {
        withCredentials: true,
    },
    //@ts-ignore
    ajaxService: ɵ1,
});
var parisInit = function () {
    var features = tenantContextCache.appConfig.Features || {};
    var serviceUrls = SccProxyUtils.getServiceUrls(tenantContextCache.appConfig.ServiceUrls, features);
    pari.setConfig(Object.assign({}, pari.config, {
        data: {
            serviceUrls: serviceUrls,
        },
    }));
    preFetch(pari, tenantContextCache.appConfig);
    return pari;
};
var ɵ2 = parisInit;
var ɵ3 = function (injector, i18n, auth, preferences) { return function () { return __awaiter(_this, void 0, void 0, function () {
    var appInsights, notifications, tvmLicenses;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, Promise.all([auth.initSCC(), i18n.init().toPromise(), preferences.init()])];
            case 1:
                _a.sent();
                appInsights = injector.get(AppInsightsService);
                notifications = injector.get(NotificationsService);
                tvmLicenses = injector.get(TvmLicensesAngularService);
                return [2 /*return*/, Promise.all([
                        appInsights.init().toPromise(),
                        notifications.init().toPromise(),
                        tvmLicenses.init(),
                    ]).then(function (res) {
                        !sccHostService.appLoadedOnce && sccHostService.log.trackSccScriptLoadDurationsByPackage('wdatp');
                        sccHostService.appLoadedOnce = true;
                        return res;
                    })];
        }
    });
}); }; }, ɵ4 = function () {
    var url = !window.mdatp.wdatpMode ?
        sccHostService.resource.getUrl('hunting-static/monaco') :
        sccHostService.resource.getUrl('vs').replace('/vs', '');
    return {
        baseUrl: url,
        preload: true,
    };
}, ɵ5 = SccAppPaths;
var AppSCCModule = /** @class */ (function () {
    function AppSCCModule() {
        var theme = createTheme({
            fonts: {
                xLargePlus: xLargePlusFont,
            },
        });
        //@ts-ignore
        loadTheme(theme);
    }
    return AppSCCModule;
}());
export { AppSCCModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
