import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../../../analyticsEndPointUtils';
import { BaselineProfileConfiguration } from '../../baseline-profile-configuration/baseline-profile-configuration.entity';
import { BaselineProfileConfigurationIds } from '../../baseline-profile-configuration/baseline-profile-configuration-ids.entity';
import { BaselineConfigurationDevice } from '../../baseline-profile-device/baseline-configuration-device.value-object';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: BaselineProfileConfigurationIds,
	dataEntity: BaselineConfigurationDevice,
	endpoint: (_, query) => `baseline/profiles/${query.where['profileId']}/settings/${query.where['configurationId']}/devices`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (configuration: BaselineProfileConfigurationIds) => ({
		profileId: configuration.profileId,
		configurationId: configuration.configurationId
	}),
})
export class BaselineProfileConfigurationsBaselineProfileDeviceRelationship
	implements EntityRelationshipRepositoryType<BaselineProfileConfiguration, BaselineConfigurationDevice> { }
