import { Injectable } from '@angular/core';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../global_entities/models/entity-type-service.interface';
import { BaselineConfigurationByCategory } from '@wcd/domain';

@Injectable()
export class ConfigurationByCategoryEntityTypeService implements EntityTypeService<BaselineConfigurationByCategory> {
	readonly entityType: EntityType<BaselineConfigurationByCategory> = {
		id: 'configurationByCategory',
		entity: BaselineConfigurationByCategory,
		getEntityName: (conf: BaselineConfigurationByCategory) => conf.category,
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<BaselineConfigurationByCategory, {}>>{
			defaultSortFieldId: 'category',
		},
	};
}
