export const alertTypesArray: Array<AlertType> = [
	{
		id: 'Groups',
		nameI18nKey: 'alertTypes_Groups',
		isGrouped: true,
		icon: 'list.grouped',
		type: 'grouped',
	},
	{
		id: 'Alert',
		nameI18nKey: 'alertTypes_Flat',
		isGrouped: false,
		icon: 'list.single',
		type: 'flat',
	},
];

export const alertTypes: { [id: string]: AlertType } = {
	flat: alertTypesArray[1],
	grouped: alertTypesArray[0],
};

export interface AlertType {
	id: string;
	nameI18nKey: string;
	isGrouped: boolean;
	icon: string;
	type: string;
}
