import { Entity, EntityField, EntityModelBase } from "@microsoft/paris";

@Entity({
	singularName: 'Device',
	pluralName: 'Devices',
	endpoint: 'baselineProfileDeviceIds',
})
export class BaselineProfileDeviceIds extends EntityModelBase {
	@EntityField() readonly deviceId: string;

	@EntityField() readonly profileId: string;
}
