import { Component, Input } from '@angular/core';

@Component({
	selector: 'incident-name',
	template: `
		<a class="field-value-link" [routerLink]="['/incidents', incidentId]" data-track-id="IncidentNameLink"
		   data-track-type="Navigation">
			{{incidentName}}
		</a>
	`,
})
export class IncidentNameComponent {
	@Input()
	incidentId: string

	@Input()
	incidentName: string
}
