/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./contents-state.component";
var styles_ContentsStateComponent = [];
var RenderType_ContentsStateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ContentsStateComponent, data: {} });
export { RenderType_ContentsStateComponent as RenderType_ContentsStateComponent };
function View_ContentsStateComponent_1(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ContentsStateComponent_2(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 1), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ContentsStateComponent_3(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 2), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_ContentsStateComponent_4(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 3), (_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_ContentsStateComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 14, null, null, null, null, null, null, null)), i0.ɵdid(2, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentsStateComponent_1)), i0.ɵdid(5, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentsStateComponent_2)), i0.ɵdid(8, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentsStateComponent_3)), i0.ɵdid(11, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ContentsStateComponent_4)), i0.ɵdid(14, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentState; _ck(_v, 2, 0, currVal_0); var currVal_1 = "Loading"; _ck(_v, 5, 0, currVal_1); var currVal_2 = "Error"; _ck(_v, 8, 0, currVal_2); var currVal_3 = "Complete"; _ck(_v, 11, 0, currVal_3); var currVal_4 = "Empty"; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_ContentsStateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-contents-state", [], null, null, null, View_ContentsStateComponent_0, RenderType_ContentsStateComponent)), i0.ɵdid(1, 49152, null, 0, i2.ContentsStateComponent, [], null, null)], null, null); }
var ContentsStateComponentNgFactory = i0.ɵccf("wcd-contents-state", i2.ContentsStateComponent, View_ContentsStateComponent_Host_0, { contentState: "contentState" }, {}, ["[loading]", "[error]", "[complete]", "[empty]"]);
export { ContentsStateComponentNgFactory as ContentsStateComponentNgFactory };
