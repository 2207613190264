/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./machine-ips.component.ngfactory";
import * as i5 from "./machine-ips.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i8 from "./machine-ips.panel.component";
import * as i9 from "@angular/router";
var styles_MachineIpsPanelComponent = [];
var RenderType_MachineIpsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineIpsPanelComponent, data: {} });
export { RenderType_MachineIpsPanelComponent as RenderType_MachineIpsPanelComponent };
export function View_MachineIpsPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "machine-ip-side-pane"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(4, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, 1, 1, "machine-ips", [], null, null, null, i4.View_MachineIpsComponent_0, i4.RenderType_MachineIpsComponent)), i0.ɵdid(8, 114688, null, 0, i5.MachineIpsComponent, [i6.Paris, i0.ChangeDetectorRef, i7.FeaturesService], { machine: [0, "machine"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.machine; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_MachineIpsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-machine-ips-panel", [], null, null, null, View_MachineIpsPanelComponent_0, RenderType_MachineIpsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i8.MachineIpsPanelComponent, [i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineIpsPanelComponentNgFactory = i0.ɵccf("app-machine-ips-panel", i8.MachineIpsPanelComponent, View_MachineIpsPanelComponent_Host_0, { settings: "settings", machine: "machine" }, {}, []);
export { MachineIpsPanelComponentNgFactory as MachineIpsPanelComponentNgFactory };
