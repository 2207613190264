import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import {
	WebContentFilteringCategoryReport,
	WebContentFilteringDomain,
	WebContentFilteringDevice,
	WebContentFilteringPolicy,
	WebContentFilteringWebCategoryRelationship,
} from '@wcd/domain';
import { Paris, DataSet, RelationshipRepository } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { ActivatedRoute } from '@angular/router';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { Subscription } from 'rxjs';
import { TzDateService } from '@wcd/localization';
import { FabricIconNames } from '@wcd/scc-common';

@Component({
	selector: 'web-content-filtering-category-report-details',
	templateUrl: './web-content-filtering-category-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebContentFilteringCategoryReportDetailsComponent
	extends EntityPanelComponentBase<WebContentFilteringCategoryReport>
	implements OnInit, OnDestroy {
	entityType: EntityType<WebContentFilteringCategoryReport>;

	private policySubscription: Subscription;
	loadingPolicies: boolean = true;
	relatedPolicies: Array<WebContentFilteringPolicy>;
	policyRepository: RelationshipRepository<WebContentFilteringCategoryReport, WebContentFilteringPolicy>;

	categoryName: string;
	timeRange: number;

	get report(): WebContentFilteringCategoryReport {
		return this.entity;
	}

	get trendsDeltaPercentageAsAbs(): number {
		return Math.abs(this.entity.trendsDeltaPercentage);
	}

	readonly topDomainsFields = DataTableField.fromList<WebContentFilteringDomain>([
		{
			id: 'domain',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.domain'
			),
			getDisplay: item => item.name,
			getLink: item => `url/${item.name}`,
		},
		{
			id: 'count',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.accessCount'
			),
			getDisplay: item => item.count,
		},
	]);

	readonly topDevicesFields = DataTableField.fromList<WebContentFilteringDevice>([
		{
			id: 'device',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.device'
			),
			getDisplay: item => item.name,
			getLink: item => `machines/${item.deviceId}/main`,
			icon: {
				fabricIcon: () => FabricIconNames.System,
			},
		},
		{
			id: 'deviceCount',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.deviceCount'
			),
			getDisplay: item => item.count,
		},
	]);

	readonly relatedPoliciesFields = DataTableField.fromList<WebContentFilteringPolicy>([
		{
			id: 'policy',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.policyName'
			),
			getDisplay: policy => policy.policyName,
		},
		{
			id: 'action',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.name'
			),
			getDisplay: policy => {
				if (policy.blockedCategoryIds.includes(this.entity.id)) {
					return this.i18nService.get(
						'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.values.block'
					);
				} else {
					return this.i18nService.get(
						'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.values.audit'
					);
				}
			},
		},
		{
			id: 'dateModified',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.dateApplied'
			),
			getDisplay: policy => this.tzDateService.format(policy.lastUpdateTime, 'MM/dd/yyyy'),
		},
		{
			id: 'editPolicy',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.source'
			),
			getDisplay: policy =>
				this.i18nService.get(
					'reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.editPolicy'
				),
			getLink: policy => `preferences2/web_content_filtering_policy/${policy.id}`,
		},
	]);

	constructor(
		private paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		private route: ActivatedRoute,
		private i18nService: I18nService,
		private tzDateService: TzDateService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);

		this.entityType = this.globalEntityTypesService.getEntityType(WebContentFilteringCategoryReport);

		this.policyRepository = this.paris.getRelationshipRepository(
			WebContentFilteringWebCategoryRelationship
		);
	}

	ngOnInit() {
		super.ngOnInit();

		this.categoryName = this.entityType.getEntityName(this.report).toLowerCase();

		const currentTimeRange = this.route.snapshot.queryParams.lookBackInDays;
		this.timeRange = currentTimeRange ? Number(currentTimeRange) : 30;

		this.policySubscription = this.policyRepository.queryForItem(this.entity).subscribe(
			(data: DataSet<WebContentFilteringPolicy>) => {
				this.relatedPolicies = data.items.sort((x, y) =>
					x.blockedCategoryIds.includes(this.entity.id) ? -1 : 1
				);
				this.loadingPolicies = false;
			},
			error => {
				this.loadingPolicies = false;
			}
		);
	}

	ngOnDestroy() {
		this.policySubscription && this.policySubscription.unsubscribe();
	}
}
