import { Injectable, ComponentRef } from '@angular/core';
import { Domain } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { DomainEntityPanelComponent } from '../components/domain.entity-panel.component';

@Injectable()
export class DomainEntityTypeService implements EntityTypeService<Domain> {
	constructor() {}

	// add entityDetailsComponentType whenever we have details ready
	readonly entityType: EntityType<Domain> = {
		id: 'domain',
		entity: Domain,
		singleEntityPanelComponentType: DomainEntityPanelComponent,
		getEntityName: (domain: Domain) => domain.id,
		maxNameLength: 50,
		loadFullEntityInPanel: false,
	};
}
