/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./user-role-edit.component.ngfactory";
import * as i5 from "./user-role-edit.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../dialogs/services/dialogs.service";
import * as i8 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "../../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i10 from "../../services/user-role-actions.service";
import * as i11 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i12 from "../../../../insights/services/app-insights.service";
import * as i13 from "../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i14 from "../../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i15 from "./user-role-edit.panel.component";
import * as i16 from "@angular/router";
var styles_UserRoleEditPanelComponent = [];
var RenderType_UserRoleEditPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserRoleEditPanelComponent, data: {} });
export { RenderType_UserRoleEditPanelComponent as RenderType_UserRoleEditPanelComponent };
export function View_UserRoleEditPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { userRoleEdit: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { canDeactivate: [0, "canDeactivate"], settings: [1, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 2, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 1, 1, "user-role-edit", [["class", "wcd-full-height"]], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.save($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_UserRoleEditComponent_0, i4.RenderType_UserRoleEditComponent)), i0.ɵdid(7, 114688, [[1, 4]], 0, i5.UserRoleEditComponent, [i6.Paris, i7.DialogsService, i8.I18nService, i9.AuthService, i10.UserRoleActionsService, i11.FeaturesService, i12.AppInsightsService, i13.AppConfigService, i14.FlavorService], { userRole: [0, "userRole"] }, { save: "save", cancel: "cancel" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundCanDeactivate; var currVal_1 = _co.settings; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.userRole; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_UserRoleEditPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-role-edit-panel", [], null, null, null, View_UserRoleEditPanelComponent_0, RenderType_UserRoleEditPanelComponent)), i0.ɵdid(1, 245760, null, 0, i15.UserRoleEditPanelComponent, [i16.Router, i7.DialogsService, i8.I18nService, i6.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserRoleEditPanelComponentNgFactory = i0.ɵccf("user-role-edit-panel", i15.UserRoleEditPanelComponent, View_UserRoleEditPanelComponent_Host_0, { settings: "settings", onDone: "onDone", onCancel: "onCancel", userRole: "userRole" }, {}, []);
export { UserRoleEditPanelComponentNgFactory as UserRoleEditPanelComponentNgFactory };
