import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { UserStatusTypes } from './aad.user-status';

export const stringToNumberParse = (value: string | number) =>
	typeof value === 'string' ? parseInt(value, 10) : value;

@Entity({
	singularName: 'User',
	pluralName: 'Users',
	endpoint: 'users',
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
	separateArrayParams: true,
})
export class AadUser extends EntityModelBase {
	@EntityField({ data: ['UserSid', 'AadUserId'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'AadUserId' })
	aadUserId: string;

	@EntityField({ data: 'DisplayName' })
	displayName: string;

	@EntityField({ data: 'AccountName' })
	accountName: string;

	// Backward compatibility with LegacyUser
	@EntityField({ data: 'DomainName' })
	accountDomainName: string;

	@EntityField({ data: 'DomainName' })
	domainName: string;

	@EntityField({ data: 'JobTitle' })
	jobTitle: string;

	@EntityField({ data: 'UserSid' })
	sid: string;

	@EntityField({
		data: 'InvestigationPriority',
		parse: stringToNumberParse,
	})
	investigationPriority?: number;

	@EntityField({ data: 'McasPercentile', parse: stringToNumberParse })
	percentile?: number;

	@EntityField({ data: 'UserPageUrl' })
	userPageUrl?: string;

	@EntityField({ data: 'Mail' })
	mail: string;

	@EntityField({ data: 'Department' })
	department: string;

	@EntityField({ data: 'UserGroups' })
	groups: string[];

	@EntityField({ data: 'UserStatus' })
	userStatus: UserStatusTypes;
}
