import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'OAuthAlertPageLinkDetails',
	pluralName: '',
	readonly: true,
})
export class OAuthAlertPageLinkDetails extends ModelBase {
	@EntityField({ data: 'OAuthAppName' })
	oAuthAppName: string;

	@EntityField({ data: 'OAuthAppId' })
	oAuthAppId: string;
}
