import { EolState, VA_RECOMMENDATION_FILTER_TAG, VERSION_DISTRIBUTION_FILTER_TAG, VulnerabilityType, } from '@wcd/domain';
import { Feature } from '@wcd/scc-common';
var TvmTagsServiceCommon = /** @class */ (function () {
    function TvmTagsServiceCommon(featuresService) {
        this._scaSupportedFilterTags = [];
        this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
    }
    TvmTagsServiceCommon.prototype.getSoftwareInstallationVersionTags = function (installation, eolVersionLocalizedString, hasUpcomingEolVersionsLocalizedString) {
        var tags = new Array();
        if (this.verifyAlreadyEolVersionTag(installation.eolVersionState)) {
            tags.push({
                id: 'endOfLifeVersionTag',
                name: eolVersionLocalizedString,
            });
        }
        if (this.verifyUpcomingEolVersionTag(installation.eolVersionState)) {
            tags.push({
                id: 'hasUpcomingEolVersionsTag',
                name: hasUpcomingEolVersionsLocalizedString,
            });
        }
        return tags;
    };
    TvmTagsServiceCommon.prototype.getVulnerabilityTags = function (vulnerability, zeroDayLocalizedString) {
        var tags = new Array();
        if (this.verifyZeroDayTag(vulnerability.mostSevereVulnerabilityType, vulnerability.patchReleaseDate)) {
            tags.push({
                id: 'zeroDayTag',
                name: zeroDayLocalizedString,
                className: 'color-box-attention',
            });
        }
        return tags;
    };
    TvmTagsServiceCommon.prototype.getSoftwareVersionTagsForFiltering = function () {
        return Object.keys(VERSION_DISTRIBUTION_FILTER_TAG);
    };
    TvmTagsServiceCommon.prototype.getVaRecommendationsTagsForFiltering = function () {
        var _this = this;
        return Object.keys(VA_RECOMMENDATION_FILTER_TAG).filter(function (tag) {
            switch (VA_RECOMMENDATION_FILTER_TAG[tag]) {
                case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
                    return _this.zeroDayEnabled;
                default:
                    return true;
            }
        });
    };
    Object.defineProperty(TvmTagsServiceCommon.prototype, "scaRecommendationsTagsForFiltering", {
        get: function () {
            return this._scaSupportedFilterTags;
        },
        enumerable: true,
        configurable: true
    });
    TvmTagsServiceCommon.prototype.verifyUpcomingEolVersionTag = function (eolVersionState) {
        return eolVersionState == EolState.UpcomingEOL;
    };
    TvmTagsServiceCommon.prototype.verifyAlreadyEolVersionTag = function (eolVersionState) {
        return eolVersionState == EolState.AlreadyEOL;
    };
    TvmTagsServiceCommon.prototype.verifyZeroDayTag = function (vulnerabilityType, patchReleaseDate) {
        return this.zeroDayEnabled && vulnerabilityType === VulnerabilityType.ZeroDay && !patchReleaseDate;
    };
    return TvmTagsServiceCommon;
}());
export { TvmTagsServiceCommon };
