var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { AlertSeverityCount } from './alert-severity-count.value-object';
import { sumBy } from 'lodash-es';
var ɵ0 = ['InformationalSevAlerts', 'Informational'], ɵ1 = function (data) { return ({ severity: 32, count: data }); }, ɵ2 = ['LowSevAlerts', 'Low'], ɵ3 = function (data) { return ({ severity: 1, count: data }); }, ɵ4 = ['MediumSevAlerts', 'Medium'], ɵ5 = function (data) { return ({ severity: 2, count: data }); }, ɵ6 = ['HighSevAlerts', 'High'], ɵ7 = function (data) { return ({ severity: 4, count: data }); };
var AlertsSeveritySummary = /** @class */ (function (_super) {
    __extends(AlertsSeveritySummary, _super);
    function AlertsSeveritySummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AlertsSeveritySummary.prototype, "alertsCount", {
        get: function () {
            return sumBy(this.severitiesWithCount, function (_a) {
                var count = _a.count;
                return count;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsSeveritySummary.prototype, "hasAlerts", {
        get: function () {
            return this.severitiesWithCount.length !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsSeveritySummary.prototype, "severitiesWithCount", {
        get: function () {
            return [this.high, this.medium, this.low, this.info].filter(function (severityCount) { return severityCount.count; });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: ɵ0,
            parse: ɵ1,
        }),
        __metadata("design:type", AlertSeverityCount)
    ], AlertsSeveritySummary.prototype, "info", void 0);
    __decorate([
        EntityField({
            data: ɵ2,
            parse: ɵ3,
        }),
        __metadata("design:type", AlertSeverityCount)
    ], AlertsSeveritySummary.prototype, "low", void 0);
    __decorate([
        EntityField({
            data: ɵ4,
            parse: ɵ5,
        }),
        __metadata("design:type", AlertSeverityCount)
    ], AlertsSeveritySummary.prototype, "medium", void 0);
    __decorate([
        EntityField({
            data: ɵ6,
            parse: ɵ7,
        }),
        __metadata("design:type", AlertSeverityCount)
    ], AlertsSeveritySummary.prototype, "high", void 0);
    __decorate([
        EntityField({ data: 'IncidentCount' }),
        __metadata("design:type", Number)
    ], AlertsSeveritySummary.prototype, "incidentsCount", void 0);
    AlertsSeveritySummary = __decorate([
        ValueObject({
            singularName: 'Alerts Severity Summary',
            pluralName: 'Alerts Severity Summaries',
            readonly: true,
        })
    ], AlertsSeveritySummary);
    return AlertsSeveritySummary;
}(ModelBase));
export { AlertsSeveritySummary };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
