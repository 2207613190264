var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { RelationshipType, EntityRelationship } from '@microsoft/paris';
import { AadUser } from './aad.user.entity';
import { LegacyUserProfile } from '../../legacy/user/legacy-user-profile.entity';
var ɵ0 = function (aadUser) { return ({
    sid: aadUser.id,
    userAccount: aadUser.accountName,
    userAccountDomain: aadUser.domainName,
}); }, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
var AadUserLegacyUserProfileRelationship = /** @class */ (function () {
    function AadUserLegacyUserProfileRelationship() {
    }
    AadUserLegacyUserProfileRelationship = __decorate([
        EntityRelationship({
            sourceEntity: AadUser,
            dataEntity: LegacyUserProfile,
            getRelationshipData: ɵ0,
            endpoint: 'UserCommunicationProfile',
            allowedTypes: [RelationshipType.OneToOne],
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ1,
        })
    ], AadUserLegacyUserProfileRelationship);
    return AadUserLegacyUserProfileRelationship;
}());
export { AadUserLegacyUserProfileRelationship };
export { ɵ0, ɵ1 };
