var _a;
import { PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ActionabilityType } from '@wcd/domain';
var ActionabilityTypeDisplayKeysMap = (_a = {},
    _a[ActionabilityType.Actionable] = 'reporting.recommendationsByActionability.actionable',
    _a[ActionabilityType.NonActionable] = 'reporting.recommendationsByActionability.nonActionable',
    _a);
var ExposureLevelDisplayTextPipe = /** @class */ (function () {
    function ExposureLevelDisplayTextPipe(i18nService) {
        this.i18nService = i18nService;
        this.transform = memoize(this.transform);
    }
    ExposureLevelDisplayTextPipe.prototype.transform = function (value) {
        if (!(value in ActionabilityTypeDisplayKeysMap)) {
            return null;
        }
        return this.i18nService.get(ActionabilityTypeDisplayKeysMap[value]);
    };
    return ExposureLevelDisplayTextPipe;
}());
export { ExposureLevelDisplayTextPipe };
