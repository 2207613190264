import { FabMessageBarModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { CustomTiIndicatorsDataviewComponent } from './components/custom-ti-indicators.dataview.component';
import { CustomTiIndicatorsEntityTypeModule } from './custom-ti-indicators.entity-type.module';
import { CustomTiIndicatorEntityTypeService } from './services/custom-ti-indicator.entity-type.service';
import { CustomTiIndicatorsFields } from './services/custom-ti-indicators.fields';
import { CustomTiIndicatorsService } from './services/custom-ti-indicators.service';
import { FeaturesService } from '@wcd/config';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
import { sccHostService } from '@wcd/scc-interface';

export const customTiIndicatorsTitle = 'customTiIndicator_common_title';
export const customTiIndicatorsIcon = 'customTiIndicators';

const breadcrumbs = sccHostService.isSCC
	? {
			addParentWhenEmpty: false,
			addItemOnEnter: false,
			resetListOnEnter: false,
			show: true,
	  }
	: null;

export function getCustomTiIndicatorPageTitleKey(featuresService: FeaturesService): string {
	return customTiIndicatorsTitle;
}

export const customTiIndicatorsRoutes: Routes = [
	{
		path: ':customTiIndicatorType',
		component: CustomTiIndicatorsDataviewComponent,
		resolve: {
			breadcrumbs: BreadcrumbsResolver,
		},
		data: {
			breadcrumbsConfig: breadcrumbs,
			pageTitle: customTiIndicatorsTitle,
			pageTitleIcon: customTiIndicatorsIcon,
			pageDescriptionKey: 'customTiIndicators.description',
		},
	},
	{
		path: '',
		component: HistorySafeRedirectComponent,
		data: {
			redirectTo: 'files'
		},
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		FabMessageBarModule,
		CustomTiIndicatorsEntityTypeModule,
		RouterModule.forChild(customTiIndicatorsRoutes),
	],
	declarations: [CustomTiIndicatorsDataviewComponent],
	providers: [CustomTiIndicatorsService, CustomTiIndicatorsFields, CustomTiIndicatorEntityTypeService],
	entryComponents: [CustomTiIndicatorsDataviewComponent],
	exports: [RouterModule],
})
export class CustomTiIndicatorsModule {}
