<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="availableMachineGroups.length > maxNumberOfGroups; else showDropdown">
	<span [title]="hoverMachineGroups" [ngClass]="{ 'disabled-completely': isDisabled }"
		>{{ numberOfDeviceGroupsText }}
		<a (click)="openSidePanel()" (keydown.enter)="openSidePanel()" [attr.tabindex]="!isDisabled ? 0 : null">{{ 'tvm.common.machineGroupFilter.edit' | i18n }}</a>
	</span>
</div>
<ng-template #showDropdown>

	<div *ngIf="machineGroupSecondaryText" class="wcd-padding-small-bottom">
		{{ machineGroupSecondaryText }}
		<wcd-help [text]="machineGroupInfoText"></wcd-help>
	</div>
	<wcd-checklist-dropdown
		[ariaLabel]="ariaLabel"
		[id]="'MachineGroupsDropdown'"
		class="command-bar-item-dropdown wcd-full-width"
		[ngClass]="{ 'disabled': isDisabled }"
		[buttonText]="dropdownText"
		[placeholder]="'tvm.common.machineGroupFilter.dropdown.noGroupsSelected' | i18n"
		name="machine-groups-selection"
		[selectById]="false"
		[isFullWidth]="true"
		[disableDropdown]="isDisabled"
		[(ngModel)]="currentMachineGroups"
		(ngModelChange)="onMachineGroupsChange($event)"
		[values]="availableMachineGroups">
	</wcd-checklist-dropdown>
</ng-template>
