import { SevilleModule } from '../../seville/seville.module';
import { serviceSourceValues } from '@wcd/domain';
SevilleModule.filter('alertProductSource', alertServiceSourceFilter);
var ɵ0 = function (acc, serviceSource) {
    acc[serviceSource.id] = serviceSource;
    return acc;
};
var sources = serviceSourceValues.reduce(ɵ0, {});
function alertServiceSourceFilter() {
    return function (input) { return sources[input].name; };
}
export { ɵ0 };
