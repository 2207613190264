import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild, OnInit } from '@angular/core';
import { WindowsAuthParams, WindowsAuthType } from '@wcd/domain';
import { FormControl, NgForm } from '@angular/forms';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { ChecklistValue } from '@wcd/forms';

@Component({
	selector: 'windows-auth-type',
	templateUrl: './windows-auth-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'windowsAuthType',
})
export class WindowsAuthTypeComponent implements OnInit {
	@Input() parentForm?: FormControl;
	@Input() label?: string;
	@Input() auth?: WindowsAuthParams = new WindowsAuthParams();
	@Output() authChange: EventEmitter<WindowsAuthParams> = new EventEmitter<WindowsAuthParams>();
	@ViewChild('windowsAuthTypeForm', { static: false }) public form: NgForm;

	constructor(private readonly assessmentJobService: AssessmentJobService)
	{
	}

	selectedProtocol: ChecklistValue | null;

	ngOnInit() {
		if (this.auth) {
			this.selectedProtocol = this.protocolList.find(value => value.id === this.auth.type);
		}
	}

	readonly protocolList: Array<ChecklistValue> = (this.isNewAuthEnabled()
		? Object.values(WindowsAuthType).filter(protocol => protocol !== WindowsAuthType.DomainNtlm && protocol !== WindowsAuthType.LocalNtlm)
		: Object.values(WindowsAuthType).filter(protocol => protocol !== WindowsAuthType.Negotiate)
		).map(value =>
			({
				id: value,
				name: value
			}));

	WindowsAuthType = WindowsAuthType;
	MessageBarType = MessageBarType;

	displayDomainInput(): boolean {
		return !this.auth.isKeyVault && (this.auth.type === WindowsAuthType.DomainNtlm || this.auth.type === WindowsAuthType.Kerberos || this.auth.type === WindowsAuthType.Negotiate);
	}

	displayUserPasswordInput(): boolean {
		return !this.auth.isKeyVault && (this.auth.type === WindowsAuthType.LocalNtlm || this.auth.type === WindowsAuthType.DomainNtlm || this.auth.type === WindowsAuthType.Kerberos || this.auth.type === WindowsAuthType.Negotiate);
	}

	isDomainRequired(): boolean {
		return this.auth.type === WindowsAuthType.DomainNtlm || this.auth.type === WindowsAuthType.Kerberos;
	}

	isNewAuthEnabled(): boolean {
		return this.assessmentJobService.isWindowsScanNewAuthEnabled();
	}

	isAuthProtocolSelectedNotKerberos(): boolean {
		return this.auth.type && this.auth.type !== WindowsAuthType.Kerberos;
	}

	//clear input fields when auth type change
	updateWindowsType() {
		this.auth = new WindowsAuthParams(this.auth);
		this.auth.type = this.selectedProtocol.id;

		this.notifyAuthChanged();
	}

	notifyAuthChanged() {
		this.authChange.emit(this.auth);
	}

	updateIsKeyVault(isKeyVault: boolean) {
		const previousAuthType = this.auth.type;

		this.auth = new WindowsAuthParams();
		this.auth.type = previousAuthType;
		this.auth.isKeyVault = isKeyVault;

		this.notifyAuthChanged();
	}
}
