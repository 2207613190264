var _a;
import { MachineExposureScoreType } from './machine-exposure-score.models';
var ɵ0 = MachineExposureScoreType.None, ɵ1 = MachineExposureScoreType.Low, ɵ2 = MachineExposureScoreType.Medium, ɵ3 = MachineExposureScoreType.High;
export var machineExposureScoreValues = [
    {
        id: ɵ0,
        name: 'No data available',
        className: 'neutral',
        nameI18nKey: 'machineExposureScoreType_None',
        priority: 4,
    },
    {
        id: ɵ1,
        name: 'Low',
        icon: 'warning',
        className: 'lowSeverity',
        nameI18nKey: 'machineExposureScoreType_Low',
        priority: 3,
    },
    {
        id: ɵ2,
        name: 'Medium',
        icon: 'warning',
        className: 'mediumSeverity',
        nameI18nKey: 'machineExposureScoreType_Medium',
        priority: 2,
    },
    {
        id: ɵ3,
        name: 'High',
        icon: 'warning',
        className: 'highSeverity',
        nameI18nKey: 'machineExposureScoreType_High',
        priority: 1,
    },
];
export var exposureScoreNumberToEntity = (_a = {},
    _a[0] = machineExposureScoreValues[0],
    _a[10] = machineExposureScoreValues[1],
    _a[20] = machineExposureScoreValues[2],
    _a[30] = machineExposureScoreValues[3],
    _a);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
