
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-urls-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_emailMessage_urls_and_threats_title }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-medium-left">
				<wcd-datatable [items]="items" [columns]="tableFields" [selectEnabled]="false">
				</wcd-datatable>
			</div>
		</wcd-panel>
	