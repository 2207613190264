/* tslint:disable:template-accessibility-label-for */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AirsNotificationRulesStore } from '../services/airs-notification-rules.store';
import { AirsNotificationRuleConditionModel } from '../models/airs-notification-rule-condition.model';
import { AirsNotificationRuleConditionGroupsStore } from '../services/airs-notification-rule-condition-groups.store';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AirsNotificationRuleConditionGroupModel } from '../models/airs-notification-rule-condition-group.model';
import { AirsReportTypes } from '../services/airs-report-types.service';
import { scheduleConfig } from '../services/airs-notification-rule-schedule-config.service';
import { AirsNotificationRulesReportsStore } from '../services/airs-notification-rules-reports.store';
import { Store } from '../../../data/models/store.base';
import { NgForm } from '@angular/forms';
import { FormComponent } from '../../../shared/models/form.component';

@Component({
	selector: 'airs-notification-rule-edit',
	templateUrl: './airs-notification-rule-edit.component.html',
})
export class AirsNotificationRuleEditComponent implements OnInit, FormComponent {
	@Input() notificationRule: AirsNotificationRuleModel;

	@Output() save: EventEmitter<AirsNotificationRuleModel> = new EventEmitter<AirsNotificationRuleModel>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	test: boolean = true;
	conditionGroups: Array<AirsNotificationRuleConditionGroupModel>;
	scheduleConfiguration = scheduleConfig;

	get unusedConditionGroups(): Array<AirsNotificationRuleConditionGroupModel> {
		if (!this.conditionGroups) return [];

		return this.conditionGroups.filter(
			conditionGroup => !this.notificationRule.containsGroup(conditionGroup)
		);
	}

	@ViewChild('notificationRuleForm', { static: false }) notificationRuleForm: NgForm;

	get isDirty(): boolean {
		return this.notificationRuleForm.form.dirty;
	}

	get confirmDeactivateText(): string {
		return `Are you sure you wish to discard ${
			this.notificationRule.isNew ? '' : 'changes to '
		}this Notification Rule?`;
	}

	constructor(
		private notificationRulesStore: AirsNotificationRulesStore,
		private notificationRulesReportsStore: AirsNotificationRulesReportsStore,
		public notificationRuleConditionGroupsStore: AirsNotificationRuleConditionGroupsStore
	) {}

	ngOnInit() {
		this.notificationRuleConditionGroupsStore.items$.subscribe(conditionGroups => {
			this.conditionGroups = conditionGroups;
		});
	}

	getStoreForRule(notificationRule: AirsNotificationRuleModel): Store<AirsNotificationRuleModel, number> {
		return notificationRule.type.requireSchedule
			? this.notificationRulesReportsStore
			: this.notificationRulesStore;
	}

	onConditionGroupChange(
		condition: AirsNotificationRuleConditionModel,
		conditionGroup: AirsNotificationRuleConditionGroupModel
	) {
		condition.group = conditionGroup;
		condition.clearValues();
	}

	getUnusedConditionGroups(
		condition: AirsNotificationRuleConditionModel
	): Array<AirsNotificationRuleConditionGroupModel> {
		return this.conditionGroups.filter(
			conditionGroup =>
				!this.notificationRule.containsGroup(conditionGroup) ||
				(condition.group && conditionGroup.id === condition.group.id)
		);
	}

	removeCondition(condition: AirsNotificationRuleConditionModel) {
		this.notificationRule.removeCondition(condition);
		this.notificationRuleForm.form.markAsDirty();
	}

	getConditionValuesText(condition: AirsNotificationRuleConditionModel): string {
		if (condition.values.length > 3) return `${condition.values.length} values`;

		const values = condition.group.getValuesByIds(condition.values);

		if (values.length) return values.map(value => value.name).join(', ');

		return '(Please select values)';
	}

	getScheduleReportTypeValuesText(): string {
		if (!this.notificationRule.reportSchedule.reportTypes.length) return '(Please select reports)';

		if (this.notificationRule.reportSchedule.reportTypes.length === AirsReportTypes.ALL.length)
			return 'All';

		return this.notificationRule.reportSchedule.reportTypes
			.map(reportTypeId => AirsReportTypes.getReportTypeById(reportTypeId).name)
			.join(', ');
	}

	getScheduleReportDaysValuesText(): string {
		if (!this.notificationRule.reportSchedule.repeatDays.length) return '(Please select days)';

		return this.notificationRule.reportSchedule.repeatDays
			.map((day, index) => scheduleConfig.repeatOn[day].abbr)
			.join(', ');
	}

	filterConditionGroups(group: AirsNotificationRuleConditionGroupModel): boolean {
		return !this.notificationRule.containsGroup(group);
	}

	allowAddCondition() {
		return this.conditionGroups.some(
			conditionGroup => !this.notificationRule.containsGroup(conditionGroup)
		);
	}

	saveRule() {
		this.getStoreForRule(this.notificationRule)
			.save(this.notificationRule)
			.then(() => {
				this.notificationRuleForm.form.markAsPristine();
				this.save.emit(this.notificationRule);
			});
	}
}
