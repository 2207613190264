import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import {DateRangeModel, TzDateService} from '@wcd/localization';
import { OnChanges, TypedChanges, wasChangedAndExists } from '@wcd/angular-extensions';

@Component({
	selector: 'wcd-date-range-picker',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./date-range-picker.component.scss'],
	template: `
		<div class="date-range-picker" *ngIf="dateRange">
			<label for="from" class="wcd-date-range-picker__label" i18n>From</label>
			<wcd-date-time-picker
				id="from"
				className="wcd-date-range-picker__picker--from"
				[(value)]="dateRange.from"
				(valueChange)="onChange()"
				[allowTimeSelection]="allowTimeSelection"
				[minDate]="minDate"
				[maxDate]="maxDate"
				[showGoToToday]="showGoToToday"
				[isMonthPickerVisible]="isMonthPickerVisible"
				[autoFocusOpen]="false"
			></wcd-date-time-picker>

			<label for="to" class="wcd-date-range-picker__label wcd-date-range-picker__label--to" i18n
				>To</label
			>
			<wcd-date-time-picker
				id="to"
				className="wcd-date-range-picker__picker--to"
				[(value)]="dateRange.to"
				(valueChange)="onChange()"
				[allowTimeSelection]="allowTimeSelection"
				[minDate]="minDate"
				[maxDate]="maxDate"
				[showGoToToday]="showGoToToday"
				[isMonthPickerVisible]="isMonthPickerVisible"
				[autoFocusOpen]="false"
			></wcd-date-time-picker>
		</div>
	`,
})
export class DateRangePickerComponent implements OnInit, OnChanges<DateRangePickerComponent> {
	@Input() allowTimeSelection?: boolean = false;
	@Input() minDate?: Date;
	@Input() maxDate?: Date;
	@Input() dateRange?: DateRangeModel;
	@Input() showGoToToday?: boolean = true;
	@Input() isMonthPickerVisible?: boolean = false;

	@Output() readonly dateRangeChange = new EventEmitter<DateRangeModel>();

	constructor(private readonly changeDetectorRef: ChangeDetectorRef, private tzDateService: TzDateService) {}

	ngOnInit() {
		if (!this.dateRange) {
			this.dateRange = new DateRangeModel(this.tzDateService, new Date(), new Date());
		}
	}

	ngOnChanges(changes: TypedChanges<DateRangePickerComponent>) {
		if (wasChangedAndExists(changes.dateRange)) {
			if (!this.allowTimeSelection) {
				this.dateRange.to = DateRangeModel.endOfDay(this.dateRange.to);
			}
		}
	}

	onChange() {
		if (!this.allowTimeSelection) {
			this.dateRange.to = DateRangeModel.endOfDay(this.dateRange.to);
		}

		if (this.dateRange.from.valueOf() > this.dateRange.to.valueOf()) {
			this.dateRange.from = this.dateRange.to;
		}

		if (this.dateRange.to.valueOf() < this.dateRange.from.valueOf()) {
			this.dateRange.to = this.dateRange.from;
		}

		this.changeDetectorRef.markForCheck();
		this.dateRangeChange.emit(this.dateRange);
	}
}
