/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./mailbox-entity-details.component";
import * as i8 from "../../services/global-entity-types.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_MailboxEntityDetailsComponent = [];
var RenderType_MailboxEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MailboxEntityDetailsComponent, data: {} });
export { RenderType_MailboxEntityDetailsComponent as RenderType_MailboxEntityDetailsComponent };
function View_MailboxEntityDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-shared-icon", [["class", "field-value-icon field-additional-icon"], ["iconName", "popOut"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null)], function (_ck, _v) { var currVal_0 = "popOut"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MailboxEntityDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵeld(7, 0, null, null, 4, "a", [], [[8, "href", 4], [8, "target", 0]], null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", "\n\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxEntityDetailsComponent_2)), i0.ɵdid(10, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.shouldLinkTabOut; _ck(_v, 10, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "mailboxes.entityDetails.details.OfficeATPLink")); _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵinlineInterpolate(1, "", _co.mailboxPageLink, ""); var currVal_2 = i0.ɵinlineInterpolate(1, "", (_co.shouldLinkTabOut ? "_blank" : null), ""); _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_3 = _co.mailboxPageLink; _ck(_v, 8, 0, currVal_3); }); }
export function View_MailboxEntityDetailsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i5.I18nPipe, [i6.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 11, "dl", [["class", "key-values"], ["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MailboxEntityDetailsComponent_1)), i0.ɵdid(11, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.showLink && _co.mailboxPageLink); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "mailboxes.entityDetails.details.displayName")); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.displayName; _ck(_v, 8, 0, currVal_1); }); }
export function View_MailboxEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mailbox-entity-details", [], null, null, null, View_MailboxEntityDetailsComponent_0, RenderType_MailboxEntityDetailsComponent)), i0.ɵdid(1, 638976, null, 0, i7.MailboxEntityDetailsComponent, [i0.Injector, i8.GlobalEntityTypesService, i9.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailboxEntityDetailsComponentNgFactory = i0.ɵccf("mailbox-entity-details", i7.MailboxEntityDetailsComponent, View_MailboxEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { MailboxEntityDetailsComponentNgFactory as MailboxEntityDetailsComponentNgFactory };
