import { Component, Input, OnChanges } from '@angular/core';
import {
	Incident,
	GetIncidentUserGroupsApiCall,
	GetIncidentMachineSensitivitiesApiCall,
	GetIncidentMachineGroupsApiCall,
	IncidentUsersSummaryRelationship,
	UsersSummary,
	Machine,
	Tag,
	IncidentMachinesRelationship,
} from '@wcd/domain';
import { TypedChanges } from '@wcd/angular-extensions';
import { DataQuery, DataQuerySortDirection, Paris, RelationshipRepository } from '@microsoft/paris';
import { ApiCallType } from '@microsoft/paris/dist/lib/api/api-calls/api-call.model';
import { Observable, throwError, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppContextService, FlavorService } from '@wcd/config';
import { ShimmerElementType } from 'office-ui-fabric-react';
import { uniqBy, compact } from 'lodash-es';
import { EntityTagsService } from '../../../../@entities/common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';

interface TagsApiData {
	tags$: Observable<any[]>;
	error: boolean;
	apiCallType: ApiCallType;
}

interface IncidentTagsData {
	dataClassifications: TagsApiData;
	machineGroups: TagsApiData;
	userGroups: TagsApiData;
}

const SHIMMER_LINE = { type: ShimmerElementType.line, width: '30%' };
const SHIMMER_GAP = { type: ShimmerElementType.gap, width: '5%' };

const SHIMMER_ELEMENTS = [SHIMMER_LINE, SHIMMER_GAP, SHIMMER_LINE, SHIMMER_GAP, SHIMMER_LINE];
const incidentMachinesQueryOptions: DataQuery = {
	sortBy: [{ field: 'RiskScore', direction: DataQuerySortDirection.descending }],
};
@Component({
	selector: 'incident-entity-details-tags',
	templateUrl: './incident.entity-details-tags.component.html',
})
export class IncidentEntityDetailsTagsComponent implements OnChanges {
	@Input() incident: Incident;

	private incidentMachinesRepository: RelationshipRepository<Incident, Machine>;
	readonly shimmerElements = SHIMMER_ELEMENTS;
	readonly apiData: IncidentTagsData = {
		dataClassifications: {
			tags$: null,
			apiCallType: GetIncidentMachineSensitivitiesApiCall,
			error: false,
		},
		machineGroups: {
			tags$: null,
			apiCallType: GetIncidentMachineGroupsApiCall,
			error: false,
		},
		userGroups: {
			tags$: null,
			apiCallType: GetIncidentUserGroupsApiCall,
			error: false,
		},
	};
	readonly isMtp: boolean = false;
	private readonly useUserSummaryApi: boolean;

	withDataSensitivity: boolean;

	constructor(
		private paris: Paris,
		appContextService: AppContextService,
		readonly entityTagsService: EntityTagsService,
		flavorService: FlavorService
	) {
		this.isMtp = appContextService.isMtp;
		this.incidentMachinesRepository = paris.getRelationshipRepository(IncidentMachinesRelationship);
		this.withDataSensitivity = flavorService.isEnabled(AppFlavorConfig.incidents.dataSensitivity);
	}

	ngOnChanges(changes: TypedChanges<IncidentEntityDetailsTagsComponent>) {
		const incident = changes && changes.incident.currentValue;

		if (incident) {
			this.incidentMachinesRepository.sourceItem = incident;
			this.setApiCall(this.apiData.dataClassifications);
			this.setApiCall(
				this.apiData.machineGroups,
				incident.incidentTags ? of(null) : this.getIncidentMachineTags()
			);
			if (this.isMtp) {
				this.useUserSummaryApi
					? this.setUserGroupsRelationship(incident)
					: this.setApiCall(this.apiData.userGroups);
			}
		}
	}

	get incidentTags() {
		return this.incident.incidentTags
			? this.entityTagsService.getIncidentTags(this.incident)
			: this.incident.tags;
	}

	private setUserGroupsRelationship(incident) {
		const repository: RelationshipRepository<
			Incident,
			UsersSummary
		> = this.paris.getRelationshipRepository(IncidentUsersSummaryRelationship);
		const apiData = this.apiData.userGroups;
		repository.sourceItem = incident;

		apiData.tags$ = repository.queryItem().pipe(
			map(
				(userSummary) =>
					userSummary.userGroups && userSummary.userGroups.length > 0 && userSummary.userGroups
			),
			catchError((err) => {
				apiData.error = true;
				return throwError(err);
			})
		);

		apiData.error = false;
	}

	private setApiCall(apiData: TagsApiData, userTags$ = of([])) {
		apiData.tags$ = combineLatest(
			this.paris.apiCall(apiData.apiCallType, this.incident).pipe(
				catchError((err) => {
					apiData.error = true;
					return throwError(err);
				})
			),
			userTags$
		).pipe(
			map(([machineTags, userTags]: any) => compact([...machineTags, ...userTags])),
			map((tags: any[]) => tags.length > 0 && tags)
		);
		apiData.error = false;
	}

	private getIncidentMachineTags(): Observable<any> {
		return this.incidentMachinesRepository.query(incidentMachinesQueryOptions).pipe(
			map((r) =>
				uniqBy(
					r.items.reduce((flattenTags: string[], machine: Machine) => {
						const userTags = machine.userDefinedTags.map((tag) => ({
							id: tag,
							name: tag,
							type: 'user',
						}));
						return [...flattenTags, ...userTags];
					}, []),
					(tag: any) => tag.name
				)
			),
			map((tags: any[]) => tags.length > 0 && tags)
		);
	}
}
