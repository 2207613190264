<!-- tslint:disable:template-click-events-have-key-events -->
<nav
	class="wcd-flex-horizontal main-header-navbar light-theme"
	role="navigation"
	[attr.aria-label]="'Top banner'"
	data-track-component="Main Header"
	data-track-component-type="Main">
	<div class="wcd-flex-none wcd-flex-center-vertical mobile-header-button" [class.mobile-hide]="!showMobileHeader">
		<button class="btn main-app-logo-icon" (click)="toggleMobileMenu()">
			<wcd-shared-icon iconName="Cancel" class="ms-icon-top-2-fix"></wcd-shared-icon>
		</button>
	</div>
	<div class="wcd-flex-1" [class.mobile-hide]="showMobileHeader">
		<div id="app-title">
			<route-link
				id="main-app-logo"
				linkId="go-to-dashboard"
				className="main-app-logo noprint no-hover no-focus"
				[routeLink]="mainAppLogoLink">
				<span class="main-app-logo-icon">
					<wcd-shared-icon iconName="waffle"> </wcd-shared-icon>
				</span>
				<h1 class="main-app-logo-text">
					{{ 'main.header.' + (isMtp ? 'mtpAppTitle' : 'appTitle') | i18n }}
					<ng-container *ngIf="authService.onBehalfTenantId && appConfigService?.tenantName">
						{{ ' | ' + appConfigService.tenantName }}
					</ng-container>
				</h1>
			</route-link>
		</div>
	</div>
	<div
		id="main-search-container"
		*ngIf="showMainSearch"
		class="wcd-flex-1 wcd-margin-large-horizontal"
		[class.hovered]="mainAppState.mainSearchShown">
		<main-search
			[isHovered]="mainAppState.mainSearchShown"
			(close)="mainAppStateService.toggleMainSearch(false)"
		></main-search>
	</div>
	<div class="wcd-flex-none wcd-flex-center-vertical" id="main-header-buttons" [class.mobile-hide]="!showMobileHeader">
		<ng-container *ngIf="authService.isLoggedIn">
			<div
				id="main-header-search-btn"
				class="main-header-btn"
				*ngIf="showMainSearch">
				<button
					type="button"
					id="main-search-button"
					wcdTooltip="{{ 'main.search.title' | i18n }}"
					data-track-id="ToggleSearchHoveredPanel"
					data-track-type="Toggle"
					(click)="mainAppStateService.toggleMainSearch()"
					[attr.aria-label]="'main.search.title' | i18n ">
					<wcd-shared-icon iconName="search"></wcd-shared-icon>
				</button>
			</div>
			<div id="main-header-community" *ngIf="!isMtp" class="wcd-flex-none main-header-btn relative">
				<a
					data-track-id="CommunityNotifications"
					data-track-type="ExternalLink"
					(click)="mainAppStateService.notifyMainHeaderClick('community')"
					href="https://techcommunity.microsoft.com/t5/microsoft-defender-for-endpoint/bg-p/MicrosoftDefenderATPBlog"
					target="communityTab"
					aria-label="Community Center"
					wcdTooltip="{{ 'main.community.title' | i18n }}">
					<wcd-shared-icon iconName="group"></wcd-shared-icon>
				</a>
				<span
					*ngIf="communityCount"
					class="main-header-badge color-box-primary"
					[class.two-digit]="communityCount > 9 && communityCount < 100"
					[class.over-two-digits]="communityCount > 99">
					{{ communityCount > 99 ? '99+' : communityCount }}
				</span>
			</div>
			<div
				id="main-header-settings-btn"
				*ngIf="!isMtp && showLocalization"
				class="wcd-flex-none main-header-btn">
				<wcd-dropdown
					buttonIcon="globe"
					buttonTooltip="{{ 'main.localization.title' | i18n }}"
					[showIconDropdown]="false"
					ariaLabel="Localization"
					[isBordered]="false"
					[closeOnClick]="true"
					ariaRole="button"
					>
					<h5 class="wcd-padding-xsmall-left">{{ 'main.localization.timezone' | i18n }}</h5>
					<ul
						class="dropdown-list"
						data-track-component-type="Dropdown"
						data-track-component="App Timezone">
						<li
							*ngFor="let timezone of timeZoneOptions"
							tabindex="0"
							(click)="onTimezoneChange(timezone.value)"
							(keyup.enter)="onTimezoneChange(timezone.value)"
							[attr.data-track-id]="'timeZone_' + timezone.id"
							data-track-type="Action"
							[class.selected]="
								timezone.value === (localeConfigService.selectedTimezone$ | async)
							"
							[attr.data-test-id]="'main-settings-timezone-' + timezone.id"
							#accessibleListItem>
							{{ timezone.text }}
						</li>
					</ul>
					<ng-container *ngIf="availableLocales?.length > 1">
						<h5 class="wcd-padding-xsmall-left">{{ 'main.localization.locale' | i18n }}</h5>
						<ul
							class="dropdown-list"
							data-track-component-type="Dropdown"
							data-track-component="App Locale">
							<li
								*ngFor="let locale of availableLocales"
								tabindex="0"
								(click)="onLocaleChange(locale)"
								(keyup.enter)="onLocaleChange(locale)"
								[attr.data-track-id]="'locale_' + locale"
								data-track-type="Action"
								[ngClass]="{
									selected: locale === (localeConfigService.selectedLocale$ | async)
								}"
								[attr.data-test-id]="'main-settings-locale-' + locale"
								#accessibleListItem>
								{{ ('main.localization.locale' | i18n) + ' ' + locale }}
							</li>
						</ul>
					</ng-container>
				</wcd-dropdown>
			</div>
			<div id="main-header-help-btn" class="wcd-flex-none main-header-btn">
				<wcd-dropdown
					buttonIcon="help_fabric"
					buttonId="main-help-menu"
					buttonTooltip="{{ 'main.help.title' | i18n }}"
					[showIconDropdown]="false"
					ariaLabel="Help"
					[isBordered]="false"
					[closeOnClick]="true"
					ariaRole="button"
					>
					<ul
						class="dropdown-list"
						data-track-component-type="Dropdown"
						data-track-component="App Help Links">
						<li
							*ngFor="let item of helpLinks"
							class="external-link-list-item"
							[attr.data-test-id]="'main-help-' + item.id"
							#accessibleListItem>
							<a
								target="_blank"
								[attr.data-track-id]="'ExternalLinkTo_' + item.id"
								data-track-type="ExternalLink"
								href="{{ item.link }}"
								>{{ (item.i18nKey | i18n) }}</a>
						</li>
						<li
							*ngIf="supportCentralService.withSupportCentral"
							class="external-link-list-item"
							#accessibleListItem>
							<a
								data-track-id="openSupportCentral"
								data-track-type="Action"
								(click)="supportCentralService.toggleWidgetIfPossible()">
								{{ supportLinkI18nKey | i18n }}
							</a>
						</li>
						<li
							class="external-link-list-item"
							*ngIf="!isMtp && authService.isLoggedIn && enableSimulations"
							#accessibleListItem>
							<route-link
								class="external-link-list-item"
								[routeLink]="{ routerLink: '/tutorials' }"
								>{{ 'simulationAndTutorials.title' | i18n }}
							</route-link>
						</li>
						<li class="external-link-list-item" *ngIf="!isMtp && evaluationEnabled" #accessibleListItem>
							<route-link
								class="external-link-list-item"
								[routeLink]="{ routerLink: '/evaluation' }"
								>{{ 'evaluation.title' | i18n }}
							</route-link>
						</li>
						<li
						*ngIf="
						!isMtp &&
						((appConfigService.isBilbaoEnabled && expertsOnDemandEnabled) || expertsOnDemandEnabled2)"
							class="external-link-list-item"
							#accessibleListItem>
							<a
								[routerLink]=""
								data-track-id="askResearcherOpen"
								data-track-type="Action"
								(click)="askThreatExpertService.showResearcherAssistanceDialog()">
								{{ 'researcherAssistance.title' | i18n }}
							</a>
						</li>
					</ul>
				</wcd-dropdown>
			</div>
			<div id="main-header-feedback-btn"
				class="wcd-flex-none main-header-btn">
				<button
					type="button"
					wcdTooltip="{{ 'feedback.button' | i18n }}"
					data-track-id="ShowFeedbackPanel"
					data-track-type="Button"
					(click)="feedbackService.showFeedbackDialog()"
					[attr.aria-label]="'feedback.button' | i18n">
					<wcd-shared-icon iconName="Emoji2"></wcd-shared-icon>
				</button>
			</div>
		</ng-container>
		<div id="main-header-username" class="wcd-flex-none main-header-btn">
			<wcd-dropdown [closeOnClick]="false" [showIconDropdown]="false" [isBordered]="false" ariaRole="button" >
				<span button-content role="button" [attr.aria-label]="'loggedInUser' | i18n: {userName: authService.currentUser.username}">
					<user-icon class="pull-right"></user-icon>
					<div *ngIf="authService.currentUser" class="username-text">
						{{ authService.currentUser.username }}
					</div>
					<span
						class="readonly-triangle"
						*ngIf="authService?.currentUser?.isReadonly"
						wcdTooltip="{{ 'main.header.readonly' | i18n }}">
						<wcd-shared-icon iconName="eye" class="icon-readonly-eye"></wcd-shared-icon>
					</span>
				</span>
				<dl class="main-header-username__details" role="none">
					<ng-container *ngIf="authService.currentUser">
						<dt role="none">
							{{ 'main.logout.loggedInAs' | i18n }}
						</dt>
						<dd role="none">
							{{ authService.currentUser?.username }}
						</dd>
					</ng-container>
					<dt role="none">{{ 'main.logout.tenantId' | i18n }}</dt>
					<dd role="none">{{ authService.tenantId }}</dd>
					<ng-container *ngIf="appConfigService.orgId">
						<dt role="none">{{ 'main.logout.orgId' | i18n }}</dt>
						<dd role="none">{{ appConfigService.orgId }}</dd>
					</ng-container>
				</dl>
				<ul
					class="dropdown-list"
					data-track-component-type="Dropdown"
					data-track-component="App Logout">
					<li #accessibleListItem (keyup.enter)="authService.logout()" (click)="authService.logout()" tabindex="0" data-track-id="logout" data-track-type="Action" role="button">
						<wcd-shared-icon iconName="logout" class="ms-icon-top-2-fix"></wcd-shared-icon>
						{{ 'main.logout.title' | i18n }}
					</li>
				</ul>
			</wcd-dropdown>
		</div>
	</div>
	<div class="wcd-flex-none wcd-flex-center-vertical mobile-header-button main-header-btn" [class.mobile-hide]="showMobileHeader">
		<button class="menu-btn" (click)="toggleMobileMenu()">
			<wcd-shared-icon iconName="More" class="ms-icon-top-2-fix"></wcd-shared-icon>
		</button>
	</div>
</nav>
