import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgClassInput, NgStyleInput } from '@wcd/angular-extensions';
import { ExpanderArrowPosition, ExpanderButtonComponent } from './expander-button/expander-button.component';
import { PreferencesService } from '@wcd/config';

let lastId = 0;

@Component({
	selector: 'wcd-expander',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./expander.component.scss'],
	template: `
		<div [ngClass]="nodeClass" [ngStyle]="nodeStyle" [attr.role]="ariaRole">
			<wcd-expander-button
				[label]="label"
				[labelClass]="labelClass"
				[(isExpanded)]="isExpanded"
				[toggleOnTitleClick]="toggleOnTitleClick"
				[arrowPosition]="arrowPosition"
				(labelClicked)="labelClicked.emit($event)"
				(isExpandedChange)="onExpandedChange($event)"
				[ariaRole]="buttonRole"
				[ariaLabel]="ariaLabel"
				[attr.role]="'presentation'"
				[iconName]="iconName"
				[regionId]="regionId"
				[buttonId]="buttonId"
			>
				<ng-content select="[wcd-expander-title]" ngProjectAs="[wcd-expander-title]"></ng-content>
			</wcd-expander-button>
		</div>
		<div
			class="wcd-expander-content"
			[ngClass]="collapsibleClass"
			[wcdCollapse]="!isExpanded"
			role="region"
			[attr.id]="regionId"
			[attr.aria-labelledby]="buttonId"
		>
			<div>
				<!--[Added "div" to "ng-content", otherwise the expander sometimes will not get his size properly]-->
				<ng-content></ng-content>
			</div>
		</div>
	`,
})
export class ExpanderComponent implements OnInit {
	@Input() label: string;
	@Input() isExpanded = true;
	@Input() toggleOnTitleClick = true;
	@Input() nodeStyle?: NgStyleInput;
	@Input() nodeClass?: NgClassInput;
	@Input() labelClass?: NgClassInput;
	@Input() collapsibleClass: NgClassInput;
	@Input() arrowPosition?: ExpanderArrowPosition = 'left';
	@Input() ariaRole?: string = 'presentation';
	@Input() ariaLabel?: string;
	@Input() buttonRole?: string;
	@Input() sectionId: string;
	@Input() iconName?: string;

	@Output() readonly labelClicked = new EventEmitter<MouseEvent>();
	@Output() readonly isExpandedChange = new EventEmitter<boolean>();

	@ViewChild(ExpanderButtonComponent, {static: false}) expenderButton: ExpanderButtonComponent

	buttonId: string = `expander-button-${lastId}`;
	regionId: string = `expander-region-${lastId++}`;

	constructor(private preferenceService: PreferencesService) {}

	ngOnInit(): void {
		if (this.sectionId) {
			const stateString = this.preferenceService.getPreference(this.sectionId);
			this.isExpanded = stateString != null ? stateString !== 'false' : this.isExpanded;
		}
	}

	onExpandedChange(state: boolean): void {
		this.isExpandedChange.emit(state);
		if (!this.sectionId) {
			return;
		}
		this.preferenceService.setPreference(this.sectionId, state.toString());
	}

	focusExpender(){
		this.expenderButton.focusExpenderButton();
	}
}
