import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { airsEntityActionStatusValues } from './airs-entity-action-status.values';

@Entity({
	singularName: 'Entity Action Status',
	pluralName: 'Entity Action Statuses',
	values: airsEntityActionStatusValues,
})
export class AirsEntityActionStatus extends StatusModel {}
