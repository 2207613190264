
		<ng-container *ngIf="routeLink">
			<span
				[ngSwitch]="linkType"
				role="presentation"
				[ngClass]="classWrapper"
			>
				<a
					*ngSwitchCase="'upgraded'"
					[ngClass]="className"
					[routerLinkActive]="routerLinkActiveCssClass"
					(click)="onClick.emit($event)"
					[routerLink]="upgradedLink"
					[queryParams]="upgradedQueryParams"
					[attr.data-track-id]="dataTrackId"
					[attr.data-link-id]="dataTrackId"
					[attr.id]="linkId"
					data-track-type="Navigation"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-current]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
				<a
					*ngSwitchCase="'legacy'"
					[routerLink]="routeLink.routerLink"
					[queryParams]="routeLink.queryParams"
					[ngClass]="className"
					(click)="goToLegacyRoute($event)"
					data-legacy
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
				<a
					*ngSwitchCase="'external'"
					rel="noopener"
					[target]="routeLink.externalLinkTarget || '_blank'"
					(click)="onClick.emit($event)"
					[ngClass]="className"
					[href]="externalLink || mdatpFromSccUrl"
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
					<wcd-shared-icon
						*ngIf="routeLink.showExternalLinkIcon !== false"
						iconName="popOut"
						class="field-value-icon field-additional-icon"
					></wcd-shared-icon>
				</a>
				<a
					*ngSwitchCase="'sccInternal'"
					rel="noopener"
					[ngClass]="className"
					[href]="routeLink.routerLink"
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
			</span>
		</ng-container>
		<ng-template #innerContent><ng-content></ng-content></ng-template>
	