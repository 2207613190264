import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { Paris, Repository, SaveEntityEvent } from '@microsoft/paris';
import {
	VulnerabilityNotificationRule,
	SeverityValue,
	MdeUserRoleActionEnum,
	MachineGroup,
	VulnerabilityChangeEventType,
} from '@wcd/domain';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { VulnerabilityNotificationRuleFormData } from '../models/vulnerability-notification-rule.models';
import { VulnerabilityNotificationRuleWizardComponent } from '../components/vulnerability-notification-rule-wizard.component';
import { Observable } from 'rxjs';
import { AuthService } from '@wcd/auth';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { PanelType } from '@wcd/panels';

@Injectable()
export class VulnerabilityNotificationRulesService {
	repo: Repository<VulnerabilityNotificationRule>;
	severityLevels: Map<SeverityValue, number>;
	isUserAllowedActionsValue: boolean;
	isGlobalAdmin: boolean;
	changeEventTypes = Object.keys(VulnerabilityChangeEventType);

	constructor(
		private dialogsService: DialogsService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private appInsightsService: AppInsightsService,
		paris: Paris,
		authService: AuthService
	) {
		this.saveVulnerabilityNotificationRule = this.saveVulnerabilityNotificationRule.bind(this);
		this.repo = paris.getRepository<VulnerabilityNotificationRule>(VulnerabilityNotificationRule);
		this.severityLevels = new Map<SeverityValue, number>([
			[SeverityValue.Low, 0],
			[SeverityValue.Medium, 4],
			[SeverityValue.High, 7],
			[SeverityValue.Critical, 9],
		]);
		this.isUserAllowedActionsValue = [
			MdeUserRoleActionEnum.securitySettings,
			MdeUserRoleActionEnum.tvmViewData,
		].every(r => authService.currentUser.hasMdeAllowedUserRoleAction(r));
		this.isGlobalAdmin = authService.currentUser.isMdeAdmin;
	}

	isUserAllowedActions() {
		return this.isUserAllowedActionsValue;
	}

	// Verify that the user is exposed to all RBAC groups of the rule
	isUserCanEditRule(
		vulnerabilityNotificationRule: VulnerabilityNotificationRule,
		userGroups: Array<MachineGroup>
	) {
		return (
			this.isGlobalAdmin ||
			(!vulnerabilityNotificationRule.isGlobalNotification &&
				vulnerabilityNotificationRule.rbacGroupIds.every(gr => userGroups.includes(gr)))
		);
	}

	showWizardPanel(vulnerabilityNotificationRule?: VulnerabilityNotificationRule): Promise<any> {
		let vulnerabilityNotificationRuleData;
		const isUpdateFlow = !!vulnerabilityNotificationRule;
		vulnerabilityNotificationRuleData = isUpdateFlow
			? this.convertVulnerabilityNotificationRuleToFormData(
					vulnerabilityNotificationRule,
					vulnerabilityNotificationRuleData
			  )
			: new VulnerabilityNotificationRuleFormData();

		return new Promise((resolve, reject) => {
			this.dialogsService.showPanel(
				VulnerabilityNotificationRuleWizardComponent,
				{
					id: 'incident-email-notification-wizard',
					type: PanelType.wizard,
					hasCloseButton: false,
					noBodyPadding: true,
				},
				{
					onDone: resolve,
					onClose: resolve,
					data: vulnerabilityNotificationRuleData,
					isUpdateFlow,
				},
				this.componentFactoryResolver
			);
		});
	}

	saveVulnerabilityNotificationRule(
		vulnerabilityNotificationRuleData: Partial<VulnerabilityNotificationRuleFormData>
	): Observable<VulnerabilityNotificationRule> {
		const ruleArgsCollection = this.changeEventTypes
			.filter(e => vulnerabilityNotificationRuleData.eventTypes[e])
			.map(e => ({
				cvss:
					e === VulnerabilityChangeEventType.NewCve
						? this.severityLevels.get(vulnerabilityNotificationRuleData.severityLevelForNewCve)
						: undefined,
				eventType: e,
			}));
		const itemToSave: any = {
			title: vulnerabilityNotificationRuleData.name,
			description: vulnerabilityNotificationRuleData.description,
			recipients: vulnerabilityNotificationRuleData.recipients.map((m) => m.toLowerCase()),
			notificationRuleArgsCollection: ruleArgsCollection,
			isEnabled: vulnerabilityNotificationRuleData.isEnabled,
			eTag: vulnerabilityNotificationRuleData.eTag,
			includeOrgName: vulnerabilityNotificationRuleData.includeOrgName,
			isGlobalNotification: vulnerabilityNotificationRuleData.allRbacGroups,
			rbacGroupIds: vulnerabilityNotificationRuleData.allRbacGroups
				? []
				: vulnerabilityNotificationRuleData.machineGroups,
		};

		itemToSave.id = vulnerabilityNotificationRuleData.id;

		this.repo.save$.subscribe((saveEvent: SaveEntityEvent) => {
			this.trackEvent(itemToSave.id ? 'Update' : 'Create', {
				eventTypes: ruleArgsCollection.map(args => args.eventType),
				severity: vulnerabilityNotificationRuleData.severityLevelForNewCve,
				isEnabled: itemToSave.isEnabled,
			});
		});
		const saveObservable$ = this.repo.save(itemToSave);

		return saveObservable$;
	}

	private convertVulnerabilityNotificationRuleToFormData(
		vulnerabilityNotificationRule: VulnerabilityNotificationRule,
		vulnerabilityNotificationRuleData: VulnerabilityNotificationRuleFormData
	) {
		const eventTypes = new Map<VulnerabilityChangeEventType, boolean>();
		this.changeEventTypes.forEach(e => {
			eventTypes[e] = false;
		});
		vulnerabilityNotificationRule.notificationRuleArgsCollection.forEach(
			c => (eventTypes[c.eventType] = true)
		);
		vulnerabilityNotificationRuleData = new VulnerabilityNotificationRuleFormData({
			id: vulnerabilityNotificationRule.id,
			name: vulnerabilityNotificationRule.title,
			description: vulnerabilityNotificationRule.description,
			recipients: [...vulnerabilityNotificationRule.recipients],
			severityLevelForNewCve: this.getSeverityThreshold(vulnerabilityNotificationRule),
			eventTypes: eventTypes,
			machineGroups: vulnerabilityNotificationRule.rbacGroupIds,
			allRbacGroups: vulnerabilityNotificationRule.isGlobalNotification,
			isEnabled: vulnerabilityNotificationRule.isEnabled,
			includeOrgName: vulnerabilityNotificationRule.includeOrgName,
			eTag: vulnerabilityNotificationRule.eTag,
		});
		return vulnerabilityNotificationRuleData;
	}

	updateStatus(
		notificationRule: VulnerabilityNotificationRule,
		isEnabledValue: boolean
	): Observable<VulnerabilityNotificationRule> {
		const updatedRule: Partial<VulnerabilityNotificationRule> = {
			id: notificationRule.id,
			notificationRuleArgsCollection: notificationRule.notificationRuleArgsCollection,
			isEnabled: isEnabledValue,
			title: notificationRule.title,
			description: notificationRule.description,
			recipients: notificationRule.recipients,
			eTag: notificationRule.eTag,
			isGlobalNotification: notificationRule.isGlobalNotification,
			rbacGroupIds: notificationRule.rbacGroupIds,
			includeOrgName: notificationRule.includeOrgName,
		};
		const updateObservable$ = this.repo.save(updatedRule);
		updateObservable$.subscribe(() =>
			this.trackEvent('UpdateStatus', {
				isEnabled: isEnabledValue,
			})
		);
		return updateObservable$;
	}

	deleteVulnerabilityNotificationRule(
		notificationRule: VulnerabilityNotificationRule
	): Promise<VulnerabilityNotificationRule> {
		const deleteObservable$ = this.repo.removeItem(notificationRule, {
			data: { eTag: notificationRule.eTag },
		});
		deleteObservable$.subscribe(() =>
			this.trackEvent('Delete', {
				eventTypes: notificationRule.notificationRuleArgsCollection.map(args => args.eventType),
			})
		);
		return deleteObservable$.toPromise();
	}

	trackEvent(action: string, value: any) {
		this.appInsightsService.track({
			id: `VulnerabilityNotificationRule_${action}`,
			component: `vulnerability-notification-rule-service`,
			type: TrackingEventType.Saving,
			value: value,
		});
	}

	getSeverityThreshold(notificationRule: VulnerabilityNotificationRule): SeverityValue {
		const cvssThreshold = this.getNotificationRuleCvssThreshold(notificationRule);
		return cvssThreshold ? this.cvssToSeverityLevel(cvssThreshold) : undefined;
	}

	private cvssToSeverityLevel(cvss: number): SeverityValue {
		return cvss < 4
			? SeverityValue.Low
			: cvss < 7
			? SeverityValue.Medium
			: cvss < 9
			? SeverityValue.High
			: SeverityValue.Critical;
	}

	private getNotificationRuleCvssThreshold(notificationRule: VulnerabilityNotificationRule): number {
		const newCveArgsWithCvss = notificationRule.notificationRuleArgsCollection.find(
			c => c.eventType === VulnerabilityChangeEventType.NewCve && c.cvss != null
		);
		return newCveArgsWithCvss ? newCveArgsWithCvss.cvss : undefined;
	}
}
