import { ComponentRef, Injectable } from '@angular/core';
import { AlertEntityPanelComponent } from '../components/alert.entity-panel.component';
import { AlertsEntityPanelComponent } from '../components/alerts.entity-panel.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import {
	Alert,
	AlertEntityExposureApiCall,
	InvestigationStatusesMapping,
	Outbreak,
	ScheduledHuntingByAlertApiCall,
	ServiceSourceType,
	Severity,
	SuppressionRule,
	Tag,
	TagType,
} from '@wcd/domain';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { AuthService, AuthUser, MtpPermission } from '@wcd/auth';
import { AlertsService } from './alerts.service';
import { Paris } from '@microsoft/paris';
import { EntityDataViewOptions, EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import {
	SuppressionRulePanelMode,
	SuppressionRulesService,
} from '../../suppression_rules/services/suppression-rules.service';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertLinkToIncidentPanelComponent } from '../components/alert-link-to-incident-panel.component';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { AppFlavorConfig, FabricIconNames } from '@wcd/scc-common';
import { SerializedFilters } from '@wcd/ng-filters';
import { castArray, flatMap, uniq } from 'lodash-es';
import { MachinesService } from '../../machines/services/machines.service';
import { AlertEntityComponent } from '../components/alert-entity.component';
import { AlertEntityDetailsComponent } from '../../../global_entities/components/entity-details/alert.entity-details.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { AlertStatusComponent } from '../components/alert-status.component';
import { HUNTING_ROUTE, isUnifiedAlert } from '@wcd/shared';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { HuntingComponentQueryParams } from '@wcd/hunting';
import { sccHostService } from '@wcd/scc-interface';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';

//This is the initial date used to determine supported mcas alerts in UAP, all alerts after this date are supported.
//TO-DO: update the date when we have the final one. Task #38189631
const SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT = new Date(2022, 1, 17);
const MCAS_ALERT_ID_PREFIX = "ca";

@Injectable()
export class AlertEntityTypeService implements EntityTypeService<Alert> {
	threatExpertAction: boolean;
	timelineAction: boolean;
	openAlertPage: boolean;
	constructor(
		private authService: AuthService,
		private alertsService: AlertsService,
		private paris: Paris,
		private suppressionRulesService: SuppressionRulesService,
		private dialogsService: DialogsService,
		private featuresService: FeaturesService,
		private machinesService: MachinesService,
		private appContextService: AppContextService,
		private entityPanelsService: EntityPanelsService,
		private i18nService: I18nService,
		private askThreatExpertService: AskThreatExpertService,
		private readonly router: Router,
		flavorService: FlavorService
	) {
		this.threatExpertAction = flavorService.isEnabled(AppFlavorConfig.threatExpert.mde);
		this.timelineAction = flavorService.isEnabled(AppFlavorConfig.alerts.timeline);
		this.openAlertPage = flavorService.isEnabled(AppFlavorConfig.incidents.fileOpenPage);
	}

	readonly entityType: EntityType<Alert> = {
		id: 'alert',
		entity: Alert,
		icon: FabricIconNames.LightningBolt,
		entityPluralNameKey: 'alert_entityType_pluralName',
		entitySingularNameKey: 'alert_entityType_singularName',
		getItem: (alert, loadedEntity$, options) => {
			if (options && options['loadExtraData'] === false) {
				return of(alert);
			}
			return loadedEntity$;
		},
		getImage: (alerts: ReadonlyArray<Alert>) => {
			const commonSeverity: Severity = GlobalEntityTypesService.getCommonValue(
				alerts,
				(alert) => alert.severity
			);

			return `/assets/images/icons/entities/alert_${
				commonSeverity ? commonSeverity.name.toLowerCase() : 'informational'
			}.svg`;
		},
		entityContentsComponentType: AlertEntityComponent,
		entityDetailsComponentType: AlertEntityDetailsComponent,
		singleEntityPanelComponentType: AlertEntityPanelComponent,
		multipleEntitiesComponentType: AlertsEntityPanelComponent,
		entityPanelStatusComponentType: AlertStatusComponent,
		getTags: (alerts: Array<Alert>) => {
			const tags: Array<Tag> = [];
			alerts.forEach((alert) => {
				if (alert.isThreatExpertOriginated) {
					tags.push({
						id: alert.detectionSource.id,
						name: this.i18nService.strings.incident_tags_mte_tag,
						type: TagType.behavior,
						className: 'tag-color-box-threatExpert',
					});
				}
				if (alert.actor) {
					tags.push({
						id: alert.actor,
						name: alert.actor,
						type: TagType.behavior,
						className: `color-box-${alert.severity.className}`,
					});
				}
			});
			return tags;
		},
		getNavigationModel: (alert: Alert) => {
			const unsupportedMcas =
				alert.id && alert.id.startsWith(MCAS_ALERT_ID_PREFIX) &&
				alert.firstEventTime < SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT;
			// MCAS alerts are supported in UAP after a specific Date, Set as SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT.
			if (
				alert.id &&
				isUnifiedAlert(alert.id, this.featuresService, alert.detectionSource)
			) {
				if (this.shouldRedirectAlertToAttackStoryTab(alert)) {
					const tab = this.featuresService.isEnabled(Feature.IncidentV2Tab) ? 'overview' : 'incident-graph';
					return {
						routerLink: [`/incidents/${alert.incidentId}/${tab}`],
						queryParams: { alertId: alert.id },
					}
				} else if (!unsupportedMcas) {
					return {
						sccInternalRedirectDefinition: () => '/alerts/' + alert.id,
					};
				}
			}
			if (alert.alertPageExternalUrl) {
				return {
					externalLink: alert.alertPageExternalUrl,
				};
			}
			return this.alertsService.isAlertLinkableToMdatp(alert)
				? {
						routerLink: alert.id ? [this.alertsService.getAlertLink(alert.id)] : null,
				  }
				: null;
		},
		getEntityName: (alert: Alert) => alert.name,
		getEntityDataviewLink: () => (sccHostService.isSCC ? '/alerts' : '/alertsQueue'),
		getEntitiesLink: (alerts: Array<Alert>) => {
			if (
				alerts.length === 1 &&
				isUnifiedAlert(alerts[0].id, this.featuresService, alerts[0].detectionSource)
			) {
				if (this.shouldRedirectAlertToAttackStoryTab(alerts[0])) {
					const tab = this.featuresService.isEnabled(Feature.IncidentV2Tab) ? 'overview' : 'incident-graph';
					return`/incidents/${alerts[0].incidentId}/${tab}?alertId=${alerts[0].id}`;
				}
				// MCAS alerts are supported in UAP after a specific Date, Set as SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT.
				if (
					alerts[0].id.startsWith(MCAS_ALERT_ID_PREFIX) &&
					alerts[0].firstEventTime < SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT
				) {
					return alerts[0].alertPageExternalUrl;
				}
				return '/alerts/' + alerts[0].id;
			}
			if (alerts.length === 1) {
				return (
					alerts[0].alertPageExternalUrl ||
					(this.alertsService.isAlertLinkableToMdatp(alerts[0]) &&
						this.alertsService.getAlertLink(alerts[0].id))
				);
			}
		},
		getUseExternalRouting: (alerts: Array<Alert>) => {
			return (
				alerts.length === 1 &&
				Boolean(alerts[0].alertPageExternalUrl) &&
				!isUnifiedAlert(alerts[0].id, this.featuresService, alerts[0].detectionSource)
			);
		},
		getActions: (alerts: Array<Alert>, options, entityViewType: EntityViewType) => {
			const allAlertsAssignedToCurrentUser: boolean = alerts.every((alert) =>
				this.authService.isCurrentUser(alert.assignedTo)
			);

			const actions: Array<ItemActionModelConfig> = [];
			const singleAlert = alerts.length === 1 && alerts[0];
			// At the moment "externalAlert"==="isReadOnly". In the feature we might have a situation
			// where some alerts are external but are editable
			const onlyInternalAlerts = alerts.every((alert) => !alert.isReadOnly);

			const workloads = uniq(flatMap(alerts.map((alert) => alert.mtpWorkloads)));

			const machine = singleAlert && singleAlert.machine;

			if (this.timelineAction && machine && onlyInternalAlerts) {
				const machineLink = this.machinesService.getMachineLink(
					machine.machineId,
					true,
					singleAlert.lastEventTime || singleAlert.lastSeen,
					singleAlert.id
				);

				if (machineLink) {
					actions.push({
						id: 'seeAlertInMachineTimeline',
						nameKey: 'alerts.seeInTimeline.title',
						href: machineLink,
						icon: FabricIconNames.Timeline,
					});
				}
			}

			if (
				this.featuresService.isEnabled(Feature.Incidents) &&
				alerts.every((alert) => alert.status && !alert.status.isHidden)
			) {
				actions.push({
					id: 'linkToIncident',
					nameKey: 'alerts.linkToIncident.title',
					icon: 'incident',
					refreshOnResolve: true,
					localRefreshOnResolve: true,
					rbacState: RbacControlState.hidden,
					rbacMtpPermission: [MtpPermission.SecurityData_Manage],
					rbacRequireAllPermissions: true,
					rbacMtpWorkloads: workloads,
					method: (alerts?: Array<Alert>) => {
						const panelSettings: PanelSettings = {
							id: 'link-alert-to-incident-panel',
							type: PanelType.large,
							noBodyPadding: false,
							isBlocking: false,
						};
						//TODO fix explicit construction
						return new Promise<void>((resolve) => {
							this.dialogsService
								.showPanel(AlertLinkToIncidentPanelComponent, panelSettings, {
									alerts: alerts,
								})
								.subscribe((panel: ComponentRef<AlertLinkToIncidentPanelComponent>) => {
									panel.onDestroy(() => {
										resolve();
									});
								});
						});
					},
				});
			}

			if (
				!this.featuresService.isEnabled(Feature.AssignToSomeoneElse) &&
				!allAlertsAssignedToCurrentUser &&
				onlyInternalAlerts
			) {
				actions.push({
					id: 'alertAssignToMe',
					nameKey: 'alerts.assignToMe',
					icon: 'users.userCheck',
					refreshOnResolve: true,
					rbacState: RbacControlState.hidden,
					rbacMtpPermission: [MtpPermission.SecurityData_Manage],
					rbacRequireAllPermissions: true,
					rbacMtpWorkloads: workloads,
					method: (alerts: Array<Alert>) => {
						return this.alertsService
							.assignAlertsToCurrentUser(alerts)
							.toPromise()
							.then((assignedUser: AuthUser) => {
								alerts.forEach((alert) => (alert.assignedTo = assignedUser.name));
								return assignedUser;
							});
					},
				});
			}

			if (
				singleAlert &&
				!singleAlert.suppressionRuleId &&
				!this.appContextService.isMtp &&
				onlyInternalAlerts
			) {
				actions.push({
					id: 'alertSuppressionRule',
					nameKey: 'alerts.addSuppressionRule',
					icon: 'suppressionRule',
					refreshOnResolve: true,
					localRefreshOnResolve: true,
					rbacState: RbacControlState.hidden,
					rbacMtpPermission: [MtpPermission.SecurityConfig_Manage],
					rbacRequireAllPermissions: true,
					rbacMtpWorkloads: workloads,
					method: (alerts: Array<Alert>) =>
						this.suppressionRulesService
							.showRulePanel(SuppressionRulePanelMode.create, null, alerts[0])
							.then((suppressionRule: SuppressionRule) => {
								Object.assign(alerts[0], {
									suppressionRuleId: suppressionRule.id,
									suppressionRuleTitle: suppressionRule.name,
								});

								return suppressionRule;
							}),
				});
			}

			if (this.threatExpertAction && this.askThreatExpertService.shouldShowThreatExpertPanel()) {
				actions.push(
					this.askThreatExpertService.getAskTheExpertCommandConfig('alertEntityCommandBar')
				);
			}

			if (singleAlert && entityViewType === EntityViewType.EntityPage) {
				actions.unshift({
					id: 'manage',
					nameKey: 'alert.actions.manage.title',
					method: async (alerts?: Array<Alert>) => {
						this.entityPanelsService.showEntities(Alert, alerts);
					},
				});
			}

			const contextMatchingServiceSource = this.appContextService.isMtp
				? ServiceSourceType.Mtp
				: ServiceSourceType.Wdatp;

			if (
				singleAlert &&
				singleAlert.isCustomDetection &&
				this.featuresService.isEnabled(Feature.ShowScheduledHuntingOnMTP) &&
				singleAlert.serviceSource.id === contextMatchingServiceSource // MTP alert in MTP context or MDATP alert in MDATP context
			) {
				const monthAgo = new Date();
				monthAgo.setDate(monthAgo.getDate() - 30);
				const alertOlderThan30Days = singleAlert.lastEventTime <= monthAgo;

				const goHuntActionButton: ItemActionModelConfig = {
					id: 'goHunt',
					nameKey: 'alert.actions.goHunt.title',
					icon: 'hunting',
					tooltip: this.i18nService.get(
						alertOlderThan30Days
							? 'alert.actions.goHunt.availableWithin30Days'
							: 'alert.actions.goHunt.descriptionTooltip'
					),
					disabled: alertOlderThan30Days,
					method: alertOlderThan30Days
						? null
						: (alrts: Array<Alert>) => {
								const relatedAlert = alrts[0];
								return this.paris
									.apiCall<string, Alert>(ScheduledHuntingByAlertApiCall, relatedAlert)
									.pipe(
										switchMap((queryId) => {
											const goHuntQueryParams: HuntingComponentQueryParams = {
												queryId,
												fromDate: relatedAlert.firstEventTime.toISOString(),
												toDate: relatedAlert.lastEventTime.toISOString(),
												runQuery: true,
											};
											return this.router.navigate([`/${HUNTING_ROUTE}`], {
												queryParams: goHuntQueryParams,
											});
										})
									)
									.toPromise();
						  },
				};

				actions.push(goHuntActionButton);
			}

			return actions.map((itemActionConfig) => new ItemActionModel(itemActionConfig));
		},
		isUserExposedToEntity: (alert: Alert) => {
			if (!alert.isGroup) {
				return this.isExposedToAlert(alert.id);
			}
			return this.alertsService.getContainedAlerts(alert).pipe(
				map((alerts) => alerts.map((alert) => alert.id)),
				map((alertIds) => alertIds.map((alertId) => this.isExposedToAlert(alertId))),
				mergeMap((isExposedObservableArray) => combineLatest(isExposedObservableArray)),
				map((isExposedArray) => isExposedArray.reduce((prev, curr) => prev && curr, true))
			);
		},
		dataViewOptions: <EntityDataViewOptions<Alert, any>>{
			getFilterQueryOptions: async (serializedFilters: SerializedFilters) => {
				const serializedFilterValues = await Promise.all(
					Object.entries(serializedFilters).map(async ([param, value]) => {
						if (param === 'IoaDefinitionIds') {
							const values = castArray(value);

							const allIoas = await Promise.all(
								values.map((outbreakId) =>
									this.paris
										.getItemById(Outbreak, outbreakId)
										.pipe(
											map((outbreak) => outbreak.ioaIds),
											take(1)
										)
										.toPromise<string[]>()
								)
							);

							return {
								[param]: uniq(flatMap(allIoas)).join(','),
							};
						} else if (param === 'investigationStates') {
							const parsedValues = castArray(value).map((val) => parseInt(val, 10));
							const transformedValues: InvestigationStatusesMapping[] = flatMap(
								parsedValues,
								(val) =>
									val === InvestigationStatusesMapping.Pending
										? [
												InvestigationStatusesMapping.PendingApproval,
												InvestigationStatusesMapping.PendingResource,
										  ]
										: [val]
							);
							const namedTransformedValues = transformedValues.map<
								keyof typeof InvestigationStatusesMapping
							>((val) => {
								// Choose the right backend key for duplicate values in InvestigationStatusesMapping
								if (val === InvestigationStatusesMapping.FullyRemediated) {
									return 'SuccessfullyRemediated';
								}
								if (val === InvestigationStatusesMapping.PartiallyRemediated) {
									return 'PartiallyRemediated';
								}
								if (val === InvestigationStatusesMapping.PartiallyInvestigated) {
									return 'PartiallyInvestigated';
								}

								return InvestigationStatusesMapping[
									val
								] as keyof typeof InvestigationStatusesMapping;
							});

							return {
								[param]: namedTransformedValues.join(','),
							};
						} else {
							return { [param]: value };
						}
					})
				);

				return serializedFilterValues.reduce(
					(_serializedFilters, fieldValue) => ({ ..._serializedFilters, ...fieldValue }),
					{}
				);
			},
		},
		getShowOpenPageLink: () => this.openAlertPage,
	};

	private shouldRedirectAlertToAttackStoryTab(alert: Alert) {
		return alert.incidentId &&
			this.appContextService.isMtp &&
			this.featuresService.isEnabled('RedirectAlertsToAttackStory') &&
			(this.featuresService.isEnabled(Feature.IncidentV2) || this.featuresService.isEnabled(Feature.IncidentV2Tab));
	}

	private isExposedToAlert(alertId: string) {
		return this.paris.apiCall(AlertEntityExposureApiCall, alertId);
	}
}
