import { ChangeDetectionStrategy, Component, OnInit, Input } from '@angular/core';
import { ActivatedEntity } from '../../../../../app/global_entities/services/activated-entity.service';
import { ModelBase } from '@microsoft/paris';
import { Alert } from '@wcd/domain';
import { filter } from 'rxjs/operators';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-scroll-vertical">
			<div *ngIf="alert; else loader" class="wcd-flex-1 wcd-flex-horizontal">
				<div class="wcd-block wcd-padding-all wcd-flex-1">
					<h3 class="wcd-margin-bottom">{{ 'alert.tabs.description.title' | i18n }}</h3>
					<markdown [innerHTML]="alert.description"></markdown>
					<markdown [data]="alert.extendedDescription"></markdown>
				</div>
				<div class="wcd-block wcd-padding-all wcd-flex-1">
					<h3 class="wcd-margin-bottom">
						{{ 'alert.tabs.description.recommendedActions' | i18n }}
					</h3>
					<markdown [data]="alert.recommendedAction"></markdown>
				</div>
			</div>
			<ng-template #loader>
				<div class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</ng-template>
		</div>
	`,
	styles: [
		`
			::ng-deep markdown p {
				margin: 0 0 10px;
			}
		`,
	],
})
export class AlertDescriptionComponent implements OnInit {
	@Input() alert: Alert;

	constructor(private readonly activatedEntity: ActivatedEntity) {}

	ngOnInit(): void {
		if (!this.alert) {
			this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Alert))
				.subscribe((alert: Alert) => {
					this.alert = alert;
				});
		}
	}
}
