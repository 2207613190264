
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<simple-data-list-table
				[dataList]="dataList"
				[openPanelForAllItems]="false"
				[shouldShowIcon]="shouldShowIcon"
				[iconName]="iconName"
				[fullPanelView]="true"
			></simple-data-list-table>
		</wcd-panel>
	