import { uniq, isEmpty } from 'lodash-es';
import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class ThreatsDisplayNameService {
	constructor(private i18nService: I18nService) {}

	getThreatsDisplayName(threatsI18nKeys: Array<string> | string): string {
		if (isEmpty(threatsI18nKeys)) {
			return '';
		}

		if (typeof threatsI18nKeys === 'string') {
			threatsI18nKeys = threatsI18nKeys.split(', ');
		}

		const uniqKeys = uniq(threatsI18nKeys);

		const threatsDisplayNames = uniqKeys.reduce((res, key) => {
			const threatFromI18nKey = this.i18nService.get(
				`evidence_threats_field_displayName_${key}`,
				null,
				true
			);

			res.push(threatFromI18nKey || key);
			return res;
		}, []);

		return threatsDisplayNames.join(', ');
	}

	getThreatDetectionMethodsDisplayName(detectionMethodsI18nKeys: Array<string>): string {
		if (isEmpty(detectionMethodsI18nKeys)) {
			return '';
		}

		const uniqKeys = uniq(detectionMethodsI18nKeys);
		const detectionMethodsDisplayNames = uniqKeys.reduce((res, key) => {
			const detectionMethodFromI18nKey = this.i18nService.get(
				`evidence_threats_detection_methods_displayName_${key}`,
				null,
				true
			);

			res.push(detectionMethodFromI18nKey || key);
			return res;
		}, []);

		return detectionMethodsDisplayNames.join(', ');
	}
}
