var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Ip } from '../ip/ip.entity';
import { Url } from '../url/url.entity';
var ɵ0 = function (rawData) {
    return rawData['Url'] || rawData['Hostname'] || rawData['IPAddress'] || rawData['IpAddress'];
}, ɵ1 = function (rawData) {
    return rawData.Url ? rawData : null;
}, ɵ2 = function (rawData) {
    return rawData.IPAddress || rawData.IpAddress ? rawData : null;
};
var NetworkEndpoint = /** @class */ (function (_super) {
    __extends(NetworkEndpoint, _super);
    function NetworkEndpoint() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(NetworkEndpoint.prototype, "name", {
        get: function () {
            var ip = this.ip && "" + this.ip.name + (this.port ? ':' + this.port : '');
            var url = this.url && "" + this.url.name;
            if (ip && url)
                url = " (" + url + ")";
            return "" + (ip || '') + (url || '');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ0,
        }),
        __metadata("design:type", String)
    ], NetworkEndpoint.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ1,
        }),
        __metadata("design:type", Url)
    ], NetworkEndpoint.prototype, "url", void 0);
    __decorate([
        EntityField({ data: 'Hostname' }),
        __metadata("design:type", String)
    ], NetworkEndpoint.prototype, "hostName", void 0);
    __decorate([
        EntityField({ data: 'RegisteredDomain' }),
        __metadata("design:type", String)
    ], NetworkEndpoint.prototype, "registeredDomain", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ2,
        }),
        __metadata("design:type", Ip)
    ], NetworkEndpoint.prototype, "ip", void 0);
    __decorate([
        EntityField({ data: 'Port' }),
        __metadata("design:type", Number)
    ], NetworkEndpoint.prototype, "port", void 0);
    __decorate([
        EntityField({ data: 'Protocol' }),
        __metadata("design:type", String)
    ], NetworkEndpoint.prototype, "protocol", void 0);
    NetworkEndpoint = __decorate([
        ValueObject({
            singularName: 'Network endpoint',
            pluralName: 'Network endpoints',
            readonly: true,
        })
    ], NetworkEndpoint);
    return NetworkEndpoint;
}(EntityModelBase));
export { NetworkEndpoint };
export { ɵ0, ɵ1, ɵ2 };
