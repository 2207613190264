/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerted-description.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i3 from "ngx-markdown";
import * as i4 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i5 from "@angular-react/fabric";
import * as i6 from "@angular/common";
import * as i7 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "./alerted-description.component";
import * as i10 from "../../../@entities/urls/services/urls.service";
import * as i11 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
var styles_AlertedDescriptionComponent = [i0.styles];
var RenderType_AlertedDescriptionComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AlertedDescriptionComponent, data: {} });
export { RenderType_AlertedDescriptionComponent as RenderType_AlertedDescriptionComponent };
function View_AlertedDescriptionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "markdown", [["class", "wcd-block"]], null, null, null, i2.View_MarkdownComponent_0, i2.RenderType_MarkdownComponent)), i1.ɵdid(1, 4767744, null, 0, i3.MarkdownComponent, [i1.ElementRef, i3.MarkdownService], { data: [0, "data"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.descriptionText; _ck(_v, 1, 0, currVal_0); }, null); }
function View_AlertedDescriptionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "a", [["class", "oAuthAlert-link"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t", "\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.oAuthAlertPageLink; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.oauthUrlText; _ck(_v, 1, 0, currVal_1); }); }
function View_AlertedDescriptionComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "wcd-block pre-line"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.descriptionText; _ck(_v, 2, 0, currVal_0); }); }
function View_AlertedDescriptionComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wcd-padding-small-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "a", [["class", "encyclopedia-link"], ["data-track-id", "MicrosoftEncyclopediaReadMore"], ["data-track-type", "ExternalLink"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "fab-icon", [["iconName", "ReadingMode"]], null, null, null, i4.View_FabIconComponent_0, i4.RenderType_FabIconComponent)), i1.ɵdid(5, 5816320, null, 0, i5.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(6, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(7, 1), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_1 = "ReadingMode"; _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.decodedLinkToEncyclopedia; _ck(_v, 2, 0, currVal_0); var currVal_2 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent.parent, 0), "alert.description.defender.readMore")); _ck(_v, 6, 0, currVal_2); }); }
function View_AlertedDescriptionComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "div", [["class", "read-more ms-fontColor-blue wcd-padding-largeMedium-top"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.descriptionExpanded = !_co.descriptionExpanded) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(4, 1), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.descriptionExpanded ? i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent, 0), "alert.threatExpert.description.readLess")) : i1.ɵunv(_v, 3, 0, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), "alert.threatExpert.description.readMore"))); _ck(_v, 3, 0, currVal_0); }); }
function View_AlertedDescriptionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedDescriptionComponent_2)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedDescriptionComponent_3)), i1.ɵdid(6, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵand(0, [["plainDescription", 2]], null, 0, null, View_AlertedDescriptionComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedDescriptionComponent_5)), i1.ɵdid(11, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedDescriptionComponent_6)), i1.ɵdid(14, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.allowHtmlRendering; var currVal_1 = i1.ɵnov(_v, 8); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.showOauthUrl; _ck(_v, 6, 0, currVal_2); var currVal_3 = _co.decodedLinkToEncyclopedia; _ck(_v, 11, 0, currVal_3); var currVal_4 = _co.expandableDescription; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_AlertedDescriptionComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i7.I18nPipe, [i8.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedDescriptionComponent_1)), i1.ɵdid(3, 16384, null, 0, i6.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.description || _co.recommendedAction); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AlertedDescriptionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerted-description", [], null, null, null, View_AlertedDescriptionComponent_0, RenderType_AlertedDescriptionComponent)), i1.ɵdid(1, 573440, null, 0, i9.AlertedDescriptionComponent, [i8.I18nService, i10.UrlsService, i11.WicdSanitizerService], null, null)], null, null); }
var AlertedDescriptionComponentNgFactory = i1.ɵccf("alerted-description", i9.AlertedDescriptionComponent, View_AlertedDescriptionComponent_Host_0, { description: "description", recommendedAction: "recommendedAction", linkToEncyclopedia: "linkToEncyclopedia", allowHtmlRendering: "allowHtmlRendering", oAuthAlertPageLinkLabel: "oAuthAlertPageLinkLabel", oAuthAlertPageLink: "oAuthAlertPageLink" }, {}, []);
export { AlertedDescriptionComponentNgFactory as AlertedDescriptionComponentNgFactory };
