import { Injectable } from '@angular/core';
import { LiveResponseCommand } from '@wcd/domain';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { LiveResponseCommandEntityPanelComponent } from '../components/live-response-command.entity-panel.component';
import { DownloadService } from '../../../shared/services/download.service';
import { FabricIconNames } from '@wcd/scc-common';
import { of } from 'rxjs';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class LiveResponseCommandEntityTypeService implements EntityTypeService<LiveResponseCommand> {
	constructor(private downloadService: DownloadService, private i18nService: I18nService) {}

	readonly entityType: EntityType<LiveResponseCommand> = {
		id: 'liveResponseCommand',
		entity: LiveResponseCommand,
		getEntityName: entity => this.i18nService.strings.liveResponse_command_panel_title,
		singleEntityPanelComponentType: LiveResponseCommandEntityPanelComponent,
		getActions: commands => {
			const actions: Array<ItemActionModelConfig<LiveResponseCommand>> = [];
			if (commands.every(c => !!c.powershellTranscript)) {
				actions.push({
					id: 'downloadTranscript',
					nameKey: 'liveResponse_actions_downloadTranscript_title',
					icon: FabricIconNames.Download,
					refreshOnResolve: false,
					method: _commands => {
						return Promise.all(
							_commands.map(c =>
								this.downloadService.download(
									of(
										new Blob([c.powershellTranscript], {
											type: 'application/octet-stream',
										})
									),
									`${c.commandTypeId}_${c.id}_transcript.txt`
								)
							)
						);
					},
				});
			}
			return actions.map(a => new ItemActionModel(a));
		},
		getItemParams: entity => ({
			useV2Api: entity.useV2Api,
			useV3Api: entity.useV3Api,
			session_id: entity.sessionId
		}),
	};
}
