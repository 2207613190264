import { SevilleModule } from '../../../seville/seville.module';
import { SevilleUtils } from '../../../common/services/seville.utils';

SevilleModule.directive('prevalenceCube', prevalenceCubeDirective);
SevilleModule.controller('seville.threatintel.prevalenceCube', prevalenceCubeController);

function prevalenceCubeDirective() {
	return {
		restrict: 'EA',
		scope: {
			org: '=',
			prevalence: '=',
			lastSeen: '=',
			firstSeen: '=',
			loaded: '=',
			lookback: '=',
		},
		template: `
		<div class="inner-box border-default">
        <div class="prevalence-title">
            <span class="entity-meta-main-title">
                <span ng-if="prevalenceCube.org">
                    Prevalence: devices
                </span>
                <span ng-if="!prevalenceCube.org">
                    Prevalence worldwide
                </span>
            </span>
            <div class="prevalence-lookback" ng-if="prevalenceCube.lookback">Last {{prevalenceCube.lookback}} days</div>
        </div>
        <div class="prevalence-content">
            <div class="prevalence-counter" ng-if="prevalenceCube.loaded">{{prevalenceCube.prevalence | megaNumber}}</div>
    
            <div class="loading-from-archive" ng-if="prevalenceCube.org && !prevalenceCube.loaded && prevalenceCube.loadsFromColdStorage">
                <div>Retrieving data from archive</div>
                <div>This action may take a few minutes</div>
                <img src="/assets/images/linear-loader.gif" />
            </div>
        </div>
        <div ng-class="{'prevalence-counter-child':prevalenceCube.loaded || prevalenceCube.org}">First seen: <span class="prevalence-date" time-ago utc="{{prevalenceCube.firstSeen}}"></span></div>
        <div>Last seen: <span class="prevalence-date" time-ago utc="{{prevalenceCube.lastSeen}}"></span></div>
    </div>
		`,
		controllerAs: 'prevalenceCube',
		bindToController: true,
		controller: 'seville.threatintel.prevalenceCube',
	};
}

prevalenceCubeController.$inject = ['$scope', 'coldStorage'];

function prevalenceCubeController(scope, coldStorage) {
	var vm = this;
	vm.loadsFromColdStorage = false;

	scope.$watch(
		function() {
			return vm.lookback;
		},
		function() {
			vm.loadsFromColdStorage = vm.isLookbackInColdStorage();
		}
	);

	vm.isLookbackInColdStorage = function() {
		if (!vm.org || vm.loaded || typeof vm.lookback === 'undefined') {
			return false;
		}

		var d = new Date();
		SevilleUtils.date.endOfDay(d);
		d.setDate(d.getDate() - vm.lookback);
		return coldStorage.isInColdStorage(d);
	};
}
