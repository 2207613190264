import { EasyTrialResponse, EasyTrialStatus } from '@wcd/domain';
import { TvmLicenseType } from '@wcd/scc-common';

export enum TvmTrialStatus {
	preTrial,
	nonEasyPreTrial,
	tvmStandalonePreTrial,
	tvmStandaloneNonEasyPreTrial,
	premium,
	none,
}

export const TvmPremiumTrialOffer = '5c403172-39ec-4bd1-8ec3-efe39e64afb9';
export const TvmStandaloneTrialOffer = '98f07273-f75f-4ec8-9a55-ca789df73e84';
export const TvmPremiumTrialPublicPreviewOffer = '5908ecaa-b8a7-4a04-b6c0-d44fd934b6f2';
export const TvmStandaloneTrialPublicPreviewOffer = 'dee3976b-2cfd-40c3-90b6-3147cbf03146';

export enum TrialRoleOptions {
	TvmPremium = 'IsTVMPremiumTrialEligible',
	TvmBasic = 'IsTVMStandaloneTrialEligible',
	TvmPremiumPublicPreview = 'IsTVMPremiumTrialPublicPreviewEligible',
	TvmBasicPublicPreview = 'IsTVMStandaloneTrialPublicPreviewEligible',
}

export const licenseToRoleMap = new Map([
	[TvmLicenseType.TvmPremium, [TrialRoleOptions.TvmPremium, TrialRoleOptions.TvmPremiumPublicPreview]],
	[TvmLicenseType.TvmBasic, [TrialRoleOptions.TvmBasic, TrialRoleOptions.TvmBasicPublicPreview]],
]);

export const getStatusFromProduct: (data: EasyTrialResponse) => EasyTrialStatus = data => {
	const resultData = data ? data.ResultData : undefined;
	const res = resultData && resultData[0];
	return res ? Object.keys(EasyTrialStatus)[res.Status] : EasyTrialStatus.Unknown;
};
