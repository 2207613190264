<wcd-panel (close)="destroy()" [settings]="settings">
	<form #classificationForm="ngForm" class="wcd-full-height wcd-flex-vertical" (submit)="updateIncidents()" data-track-component="IncidentClassification">
		<div class="wcd-flex-1 wcd-scroll-vertical">
			<div class="form-group">
				<label for="incidentStatus">{{'incident.setClassification.incidentStatus' | i18n}}</label>
				<div>
					<fancy-select [(ngModel)]="incidentStatus"
						listId="incidentStatus"
						name="incidentStatus"
						[isBordered]="true"
						labelClass="dropdown-width-large"
						label="name"
						labelI18nKey="nameI18nKey"
						[placeholder]="'alerts_classification_dropdown_select' | i18n"
						[trackBy]="'id'"
						[values]="incidentStatuses"
						[ariaLabel]="'incident.setClassification.incidentStatus' | i18n"
						[dropdownAreaRole]="'combobox'">
					</fancy-select>
				</div>
			</div>
			<div class="form-group" *ngIf="isAssignToSomeoneElseEnabled">
				<label for="assign-to">{{'incident.commandBar.manageIncident.assignTo' | i18n}}</label>
				<div id="assign-to" class="wcd-padding-largeMedium-right">
					<assignee-picker
						[assignedTo]="assignedTo"
						(onSelectAssignee)="onSelectAssignee($event)"
					></assignee-picker>
				</div>
			</div>
			<ng-container *ngIf="isCombinedClassificationEnabled; else oldClassification">
				<div class="form-group">
					<label for="incidentClassification">{{'alerts.classification' | i18n}}</label>
					<div>
						<fab-dropdown dropdownWidth="5"
									  [selectedKey]="selectedCombinedClassification"
									  [options]="combinedClassificationMap"
									  (onChange)="onNewClassificationDropdownChange($event)">
						</fab-dropdown>
					</div>
				</div>

			</ng-container>
			<ng-template #oldClassification>
				<div class="form-group">
					<label for="incidentClassification">{{'alerts.classification' | i18n}}</label>
					<div>
						<fancy-select [(ngModel)]="incidentClassification"
							listId="incidentClassification"
							name="incidentClassification"
							[isBordered]="true"
							labelClass="dropdown-width-large"
							[placeholder]="(!incidentClassification ? ('alerts_classification_dropdown_notSet' | i18n) : null)"
							[ariaLabel]="'alerts.classification' | i18n"
							label="name"
							labelI18nKey="nameI18nKey"
							[trackBy]="'id'"
							[values]="selectableAlertClassifications"
							(ngModelChange)="onClassificationChange(incidentClassification.id)"
							[dropdownAreaRole]="'combobox'">
						</fancy-select>
					</div>
				</div>

				<div class="form-group" *ngIf="incidentClassification?.allowDetermination">
					<label for="determination">{{'incident.setClassification.determination' | i18n}}</label>
					<div>
						<fancy-select [(ngModel)]="determination"
							listId="determination"
							name="determination"
							[isBordered]="true"
							labelClass="dropdown-width-medium"
							[placeholder]="'alerts_classification_dropdown_select' | i18n"
							label="name"
							labelI18nKey="nameI18nKey"
							[trackBy]="'id'"
							[values]="allAlertDeterminations"
							[ariaLabel]="'incident.setClassification.determination' | i18n"
							[dropdownAreaRole]="'combobox'">
						</fancy-select>
					</div>
				</div>
			</ng-template>

			<div class="form-group">
				<label [attr.for]="labelId">{{'incident.setClassification.comment' | i18n}}</label>
				<textarea class="wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
						attr.aria-label="{{ 'common.textareaAriaLabelComment.title' | i18n}}"
						[attr.id]="labelId"
						[placeholder]="commentPlaceholder"
						[(ngModel)]="comment"
						name="new-title-text"
						rows="5"></textarea>
			</div>
		</div>
		<div class="color-text-neutralSecondary">
			<div class="wcd-padding-medium-bottom">
				{{getIncidentStatusWarning()}}
			</div>
			<div class="wcd-padding-medium-bottom">
				{{'incident.setClassification.classificationWarning' | i18n}}
			</div>
			<div class="wcd-padding-medium-bottom">
				{{'incident.setClassification.determinationWarning' | i18n}}
			</div>
		</div>

		<div class="wcd-flex-none wcd-flex-horizontal">
			<div class="wcd-flex-1">
				<button type="submit"
						data-track-id="SetClassification"
						data-track-type="Button"
						class="btn btn-lg btn-primary no-padding"
						[disabled]="isSubmitDisabled">
					<i class="loader-icon" [hidden]="!isSaving"></i>
					{{submitButtonText}}
				</button>
				<button type="button"
						data-track-id="Cancel"
						data-track-type="Button"
						class="btn btn-lg btn-cancel no-padding"
						(click)="destroy()">{{'incident.setClassification.cancel' | i18n}}</button>
			</div>
		</div>
	</form>
</wcd-panel>
