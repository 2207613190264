var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { RecommendationExceptionAggregated } from './recommendation-exception-aggregated.entity';
import { RecommendationException } from './recommendation-exception.entity';
import { TvmRemediationSharedEntityConfigurations } from '../remediationEndPointUtils';
var ɵ0 = function (_, query) { return "aggregatedExceptions/" + encodeURIComponent(query.where['id']) + "/exceptions"; }, ɵ1 = function (recommendationExceptionAggregated) {
    return { id: recommendationExceptionAggregated.id };
};
var RecommendationExceptionAggregatedRelationship = /** @class */ (function () {
    function RecommendationExceptionAggregatedRelationship() {
    }
    RecommendationExceptionAggregatedRelationship = __decorate([
        EntityRelationship(__assign({}, TvmRemediationSharedEntityConfigurations, { sourceEntity: RecommendationExceptionAggregated, dataEntity: RecommendationException, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1 }))
    ], RecommendationExceptionAggregatedRelationship);
    return RecommendationExceptionAggregatedRelationship;
}());
export { RecommendationExceptionAggregatedRelationship };
export { ɵ0, ɵ1 };
