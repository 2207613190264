var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { Positions } from '@wcd/forms';
import { FancySelectComponent } from '../../../forms/components/fancy-select.component';
var lastId = 0;
var DataviewActionFancySelectComponent = /** @class */ (function () {
    function DataviewActionFancySelectComponent(i18nService) {
        this.i18nService = i18nService;
        this.openMenuPosition = Positions.Default;
        this.smallScreenView = false;
        this.isInMoreMenu = false;
        this.listId = 'dataview-action-fancy-select-' + lastId++;
    }
    DataviewActionFancySelectComponent.prototype.buttonElementPicker = function () {
        return this.fancyElement.dropdown.buttonEl;
    };
    DataviewActionFancySelectComponent.prototype.ngOnInit = function () {
        Object.assign(this, __assign({}, this.fancySelectActionConfig));
    };
    DataviewActionFancySelectComponent.prototype.getTooltip = function () {
        if (this.currentSelection.value) {
            return this.formatLabel
                ? this.formatLabel(this.currentSelection.value)
                : this.currentSelection.value[this.propertyIdForLabel];
        }
        return '';
    };
    return DataviewActionFancySelectComponent;
}());
export { DataviewActionFancySelectComponent };
