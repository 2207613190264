import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WeaknessesCountBySeverity } from '../weaknesses-count-by-severity.interface';
import { ProductIdentifier } from '../../common/product-identifier.interface';
import { TvmAnalyticsApiCallSharedConfigurations } from '../../analyticsEndPointUtils';
import { AddMachineGroupsFilterQueryParam } from '../../commonEndPointUtils';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'misconfigurations-by-severities-api-call',
	method: 'GET',
	endpoint: 'configurations/countBySeverity',
	cache: null,
	parse: (data: any) =>
		data.map(record => ({ weaknesses: record.misconfigurationsCount, severity: record.severity })),
	parseQuery: input => {
		const params = {};
		if (input.hasOwnProperty('product') && input.hasOwnProperty('vendor')) {
			params['productName'] = input.product;
			params['vendor'] = input.vendor;
		}

		AddMachineGroupsFilterQueryParam(params);

		return {
			params: params,
		};
	},
})
export class CountMisconfigurationsBySeverityApiCall extends ApiCallModel<
	WeaknessesCountBySeverity[],
	ProductIdentifier
> {}
