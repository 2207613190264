import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineDimensionType } from './machine-protection-dimension-types.values';
import { MachineProtectionData } from './machine-protection-data.value-object';

@ValueObject({
	singularName: 'Device protection',
	pluralName: 'Device protection',
	readonly: true,
})
export class MachineProtection extends ModelBase {
	@EntityField({ data: 'dimensionName' })
	dimensionType: MachineDimensionType;

	@EntityField({ arrayOf: MachineProtectionData })
	data: Array<MachineProtectionData>;
}
