import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { RemediationActionType } from '../remediation/remediation-action-type.entity';
import { EvidenceDetectionSource } from '../evidence/evidence-detection-source.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { AntivirusRemediationStatus } from './antivirus-remediation-status.entity';

@Entity({
	singularName: 'Infected Entity',
	pluralName: 'Infected Entities',
	readonly: true,
	endpoint: 'entities/infected',
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'data',
	separateArrayParams: true,
	supportedEntityGetMethods: [EntityGetMethod.query],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InfectedEntity extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField({ data: 'entity_status' })
	status: AirsEntityStatus;

	@EntityField({ data: 'host' })
	machine: InvestigatedMachine;

	@EntityField({ data: 'action_type' })
	remediationActionType: RemediationActionType;

	@EntityField({ data: 'threat_type' })
	threatType: string;

	@EntityField() path: string;

	@EntityField() paths: Array<string>;

	@EntityField({
		data: 'av_remediation_status',
		parse: status => {
			// status can be 0, and that doesn't exist
			return status ? status : undefined;
		},
	})
	avRemediationStatus?: AntivirusRemediationStatus;

	@EntityField({ data: 'entity_detection_src' })
	detectionSource: EvidenceDetectionSource;
}
