var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
var REGISTRY_PROPERTIES = ['key', 'valueName', 'valueData', 'valueType'];
var RegistryModificationEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(RegistryModificationEntityDetailsComponent, _super);
    function RegistryModificationEntityDetailsComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.registryProperties = REGISTRY_PROPERTIES;
        return _this;
    }
    Object.defineProperty(RegistryModificationEntityDetailsComponent.prototype, "current", {
        get: function () {
            return this.entity && this.entity.current;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegistryModificationEntityDetailsComponent.prototype, "previous", {
        get: function () {
            return this.entity && this.entity.previous;
        },
        enumerable: true,
        configurable: true
    });
    return RegistryModificationEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { RegistryModificationEntityDetailsComponent };
