import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
	FabButtonModule,
	FabCommandBarModule,
	FabContextualMenuModule,
	FabIconModule,
} from '@angular-react/fabric';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { PopupModule } from '@progress/kendo-angular-popup';

import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { WcdFormsModule } from '@wcd/forms';
import { TooltipsModule } from '@wcd/dialogs';
import { PaginationModule } from '@wcd/pagination';

import { HuntingSharedModule } from '../shared/hunting-shared.module';
import { ResultsTableComponent } from './results-table/results-table.component';
import { ResultsTableLocalStorageService } from './services/results-table-local-storage.service';
import { WcdSharedModule } from '@wcd/shared';
import { LocalizationModule } from '@wcd/localization';
import { I18nModule } from '@wcd/i18n';
import { HuntingRecordPropertyDisplayComponent } from './results-table/hunting-record-property-display.component';
import { DataTableModule } from '@wcd/datatable';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		GridModule,
		ExcelModule,
		PopupModule,
		FabContextualMenuModule,
		FabButtonModule,
		AngularExtensionsModule,
		FabIconModule,
		WcdFormsModule,
		TooltipsModule,
		PaginationModule,
		FabCommandBarModule,
		HuntingSharedModule,
		WcdSharedModule,
		LocalizationModule,
		I18nModule,
		DataTableModule,
	],
	providers: [ResultsTableLocalStorageService],
	declarations: [ResultsTableComponent, HuntingRecordPropertyDisplayComponent],
	exports: [ResultsTableComponent, HuntingRecordPropertyDisplayComponent],
})
export class ResultsTableModule {}
