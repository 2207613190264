import { SevilleModule } from '../../../seville/seville.module';

SevilleModule.directive('associatedIoas', associatedIoasDirective);
SevilleModule.controller('seville.threatintel.associated', associatedController);

associatedIoasDirective.$inject = [];

function associatedIoasDirective() {
	return {
		restrict: 'EA',
		scope: {
			entity: '=',
			hideMachineNames: '=',
			hideUserNames: '=',
			url: '=?',
			ip: '=?',
			sha1: '=?',
			sha256: '=?',
			aggregativeAlertId: '=?',
			machineId: '=?',
			accountName: '=?',
			accountDomainName: '=?',
			accountSid: '=?',
			ruleId: '=?',
			refreshInvestigationStates: '=?',
		},
		template: `
		<div class="row" id="active-alerts-container">
        <div class="col-sm-12 nopadding">
            <div class="entity-section-title">
                <span ng-if="::associatedIoas.entity != 'aggregativealert'">Alerts related to this {{::associatedIoas.entity}}</span>
                <span ng-if="::associatedIoas.entity == 'aggregativealert'">Underlying alerts</span>
                <div class="pull-right">
                    <div class="associated-alerts-summary" ng-if="associatedIoas.totalResults > associatedIoas.pageSize">
                        <span>{{associatedIoas.startIndex}}-</span><span ng-if="associatedIoas.endIndex >= associatedIoas.totalResults">{{::associatedIoas.totalResults}}</span><span ng-if="associatedIoas.endIndex < associatedIoas.totalResults">{{associatedIoas.endIndex}}</span>
                        <span> of {{::associatedIoas.totalResults}}</span>
                    </div>
                    <div paging-controls page-index="associatedIoas.pageIndex" more-available="associatedIoas.moreAvailable"
                         ng-if="associatedIoas.loaded && (associatedIoas.pageIndex > 1 || associatedIoas.moreAvailable || (associatedIoas.loaded && associatedIoas.loading))"
                         prev-page="associatedIoas.prev()" next-page="associatedIoas.next()" loading="associatedIoas.loading" class="pull-right">
                    </div>
                </div>
            </div>
            <div class="active-alerts-loading" ng-if="associatedIoas.loading && !associatedIoas.loaded && associatedIoas.pageIndex == 1">
                <img src="/assets/images/circle-loading.gif" />
            </div>
            <div id="active-alerts-list" ng-if="associatedIoas.loaded && associatedIoas.ioas && associatedIoas.ioas.length > 0"
                 ng-class="{'loading-opacity': associatedIoas.loading}">
                <alert-group alerts="associatedIoas.ioas"
                             sort="associatedIoas.sortBy"
                             sort-order="associatedIoas.sortOrder"
                             loading="associatedIoas.loading"
                             load-alerts="associatedIoas.loadAlerts()"
                             page-index="associatedIoas.pageIndex"
                             entity-page-layout="true"
                             show-machine-context="!associatedIoas.hideMachineNames"
                             show-user-context="!associatedIoas.hideUserNames"
                             show-alert-highlight="associatedIoas.hideMachineNames"
                             refresh-investigation-states="associatedIoas.refreshInvestigationStates"/>
            </div>
            <div ng-if="associatedIoas.loaded && (!associatedIoas.ioas || associatedIoas.ioas.length == 0) && associatedIoas.pageIndex==1">
                <br/>
                <span>No alerts found.</span>
                <br />
                <br />
            </div>
        </div>
    </div>
		`,
		bindToController: true,
		controllerAs: 'associatedIoas',
		controller: 'seville.threatintel.associated',
	};
}

associatedController.$inject = [
	'$rootScope',
	'$scope',
	'$state',
	'$log',
	'$http',
	'alertFilterService',
	'urlMapping',
];

function associatedController($rootScope, $scope, $state, $log, $http, alertFilterService, urlMapping) {
	var vm = this;
	vm.loading = true;
	vm.pageIndex = 1;
	vm.pageSize = 8;
	vm.loaded = false;
	vm.moreAvailable = false;
	vm.sortBy = 'lastevent';
	vm.sortOrder = 'Descending';
	vm.totalResults = 0;

	$scope.$watch(
		function() {
			return (
				vm.sha1 ||
				vm.sha256 ||
				vm.machineId ||
				vm.ip ||
				vm.url ||
				vm.accountName ||
				vm.accountDomainName ||
				vm.ruleId ||
				vm.aggregativeAlertId
			);
		},
		function(newValue, oldValue) {
			if (newValue) loadAlerts();
		}
	);

	$scope.$watch(
		function() {
			return vm.sortBy + vm.sortOrder;
		},
		function(newValue, oldValue) {
			if (newValue != oldValue) {
				vm.pageIndex = 1;
				loadAlerts();
			}
		}
	);

	function updateIndexes() {
		vm.startIndex = (vm.pageIndex - 1) * vm.pageSize + 1;
		vm.endIndex = vm.pageIndex * vm.pageSize;
	}

	function loadAlerts() {
		vm.loading = true;
		var url = urlMapping.getThreatIntelUrl() + '/AssociatedAlerts';
		var params: any = {
			lookBackInDays: 180,
			pageIndex: vm.pageIndex,
			pageSize: vm.pageSize,
			sortOrder: vm.sortOrder,
			sortByField: vm.sortBy,
			timeField: 'lastEvent',
		};

		if (vm.url) {
			params.Url = vm.url;
		} else if (vm.machineId) {
			params.WcdMachineId = vm.machineId;
		} else if (vm.sha1) {
			params.Sha1 = vm.sha1;
		} else if (vm.sha256) {
			params.Sha256 = vm.sha256;
		} else if (vm.aggregativeAlertId) {
			params.AggregativeAlertId = vm.aggregativeAlertId;
		} else if (vm.ip) {
			params.Ip = vm.ip;
		} else if (vm.accountName) {
			params.AccountName = vm.accountName;
			params.AccountDomainName = vm.accountDomainName;
			params.AccountSid = vm.accountSid;
		}

		$http
			.get(url, {
				params: params,
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug('associated alerts loaded successfully');
						vm.ioas = response.data.Items;
						vm.moreAvailable = response.data.TotalResults > vm.pageSize * vm.pageIndex;
						vm.totalResults = response.data.TotalResults;
						updateIndexes();
					}

					vm.loading = false;
					vm.loaded = true;
				},
				function(response) {
					$log.error('Error occur while loading associated alerts');
					vm.loading = false;
				}
			);
	}

	vm.next = function() {
		vm.pageIndex++;
		updateIndexes();
		loadAlerts();
	};

	vm.prev = function() {
		if (vm.pageIndex == 1) return;
		vm.pageIndex--;
		updateIndexes();
		loadAlerts();
	};
}
