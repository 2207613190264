import { Mailbox, AirsEntityType } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import {
	MailboxEntityPanelComponent,
	MailboxPanelOptions,
} from '../components/mailbox.entity-panel.component';
import { MailboxEntityDetailsComponent } from '../../../global_entities/components/entity-details/mailbox-entity-details.component';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { Observable, EMPTY, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { FeaturesService, Feature } from '@wcd/config';

const sccRedirectFunc = (path: string) => path? `/threatexplorer?${path.split('threatexplorer&')[1]}` : '';

@Injectable()
export class MailboxEntityTypeService implements EntityTypeService<Mailbox> {
	constructor(private goHuntService: GoHuntService, private featuresService: FeaturesService) {}

	private readonly _entityType = new Lazy<Readonly<EntityType<Mailbox>>>(() => ({
		entity: Mailbox,
		id: 'mailbox',
		icon: FabricIconNames.Inbox,
		entityDetailsComponentType: MailboxEntityDetailsComponent,
		singleEntityPanelComponentType: MailboxEntityPanelComponent,
		getEntityName: mailbox => mailbox.id,
		entityPluralNameKey: 'mailbox_entityType_pluralName',
		entitySingularNameKey: 'mailbox_entityType_singularName',
		getEntitiesLink: mailboxes => {
			if (!mailboxes || mailboxes.length !== 1) {
				return null;
			}
			const [mailbox] = mailboxes;
			return this.featuresService.isEnabled(Feature.PortedSccPages) && mailbox.mailboxPageUrl ? sccRedirectFunc(mailbox.mailboxPageUrl) : mailbox.mailboxPageUrl;
		},
		getNavigationModel: (mailbox: Mailbox) => ({
			externalLink: mailbox.mailboxPageUrl,
			sccInternalRedirectDefinition: this.featuresService.isEnabled(Feature.PortedSccPages) && mailbox.mailboxPageUrl? sccRedirectFunc : undefined,
		}),
		getUseExternalRouting: (mailboxes: Array<Mailbox>) => this.featuresService.isEnabled(Feature.PortedSccPages)? false : true,
		loadFullEntityInPanel: false,
		getActions: (mailboxes: Array<Mailbox>, options: MailboxPanelOptions) => {
			if (mailboxes.length !== 1) {
				return EMPTY;
			}

			const actions: Array<Observable<ItemActionModelConfig>> = [];
			const mailbox = mailboxes[0];
			actions.push(
				this.goHuntService.getGoHuntActionObservable(mailbox, AirsEntityType.Mailbox, {
					incident: options && options.incident,
				})
			);

			return combineLatest(actions).pipe(
				map(_actions =>
					compact(_actions).map(itemActionConfig => new ItemActionModel(itemActionConfig))
				)
			);
		},
	}));

	get entityType(): Readonly<EntityType<Mailbox>> {
		return this._entityType.value;
	}
}
