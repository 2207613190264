var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnChanges } from '@angular/core';
import { Investigation, MtpInvestigation, OfficeUtils } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { TimeRange, TimeRanges } from '../../../utils/time-ranges.model';
import { I18nService } from '@wcd/i18n';
var InvestigationEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(InvestigationEntityDetailsComponent, _super);
    function InvestigationEntityDetailsComponent(injector, globalEntityTypesService, i18nService) {
        var _this = _super.call(this, injector) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        return _this;
    }
    InvestigationEntityDetailsComponent.prototype.ngOnChanges = function () {
        var isMtpInvestigation = this.investigation instanceof MtpInvestigation;
        this.totalPendingTime = isMtpInvestigation
            ? this.getTotalPendingTimeRanges()
            : (this.investigation.remediationPendingTime || 0) +
                (this.investigation.resourcePendingTime || 0);
        this.shortInvestigationId = OfficeUtils.getShortId(this.investigation.id);
    };
    InvestigationEntityDetailsComponent.prototype.getTotalPendingTimeRanges = function () {
        return (new TimeRanges(this.investigation.pendingActions.map(function (pendingAction) {
            return new TimeRange(pendingAction.startTime, pendingAction.endTime);
        })).getTotalTime() / 1000);
    };
    return InvestigationEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { InvestigationEntityDetailsComponent };
