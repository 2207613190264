/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./entity-command-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/command-bar/command-bar.component.ngfactory";
import * as i3 from "../../../shared/components/command-bar/command-bar.component";
import * as i4 from "../../../shared/main/services/main-app-state.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../projects/forms/src/lib/components/dropdown.component.ngfactory";
import * as i7 from "../../../rbac/directives/rbac-control.directive";
import * as i8 from "../../../rbac/services/rbac-control.service";
import * as i9 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i10 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i11 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i12 from "../../../../../../../projects/forms/src/lib/components/dropdown.component";
import * as i13 from "../../../tags/components/tags-edit/tags-edit.component.ngfactory";
import * as i14 from "../../../shared/interfaces/disableable.interface";
import * as i15 from "../../../tags/components/tags-edit/tags-edit.component";
import * as i16 from "../../../dialogs/services/dialogs.service";
import * as i17 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i18 from "./entity-command-bar.component";
import * as i19 from "@angular/router";
import * as i20 from "../../../shared/components/command-bar/command-bar-item.service";
import * as i21 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i22 from "../../services/activated-entity.service";
var styles_EntityCommandBarComponent = [i0.styles];
var RenderType_EntityCommandBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EntityCommandBarComponent, data: {} });
export { RenderType_EntityCommandBarComponent as RenderType_EntityCommandBarComponent };
function View_EntityCommandBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "entity-command-bar"]], [[2, "overflow-actions", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 2, "command-bar", [["class", "entity-command-bar"]], [[2, "command-bar", null], [2, "button", null]], null, null, i2.View_CommandBarComponent_0, i2.RenderType_CommandBarComponent)), i1.ɵdid(5, 49152, null, 0, i3.CommandBarComponent, [i4.MainAppStateService, i1.ChangeDetectorRef], { id: [0, "id"], styles: [1, "styles"], items: [2, "items"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = (_co.entityType.entity.singularName + "_EntityCommandBar"); var currVal_4 = (_co.overFlowActionsView ? _co.overFlowCommandBarStyles : null); var currVal_5 = _v.parent.context.ngIf; _ck(_v, 5, 0, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.overFlowActionsView; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.overFlowActionsView; var currVal_2 = _co.overFlowActionsView; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_EntityCommandBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EntityCommandBarComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = ((_v.context.ngIf == null) ? null : _v.context.ngIf.length); _ck(_v, 3, 0, currVal_0); }, null); }
function View_EntityCommandBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(1, 16777216, null, null, 14, "wcd-dropdown", [["ariaRole", "menuitem"], ["class", "command-bar-item command-bar-item-dropdown"], ["data-track-type", "TagStart"]], [[1, "data-track-id", 0]], [[null, "onToggle"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onToggle" === en)) {
        var pd_0 = (_co.tagsEditorToggled($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_DropdownComponent_0, i6.RenderType_DropdownComponent)), i1.ɵdid(2, 4800512, null, 0, i7.RbacControlDirective, [i8.RbacControlService, i1.ElementRef, i1.ChangeDetectorRef, i9.WicdSanitizerService, [8, null], i10.TooltipsService, i1.ViewContainerRef, i11.I18nService], { rbac: [0, "rbac"] }, null), i1.ɵdid(3, 4964352, [["dropDownRef", 4]], 1, i12.DropdownComponent, [i1.ElementRef, i1.ChangeDetectorRef], { buttonId: [0, "buttonId"], buttonIcon: [1, "buttonIcon"], buttonTooltip: [2, "buttonTooltip"], closeOnClick: [3, "closeOnClick"], showIconDropdown: [4, "showIconDropdown"], isBordered: [5, "isBordered"], ariaRole: [6, "ariaRole"], ariaOwns: [7, "ariaOwns"] }, { onToggle: "onToggle" }), i1.ɵqud(603979776, 2, { dropdownItems: 1 }), (_l()(), i1.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "span", [["button-content", ""]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i1.ɵeld(10, 0, null, 1, 4, "tags-edit", [], null, [[null, "changed"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("changed" === en)) {
        var pd_0 = (_co.onChangeTags($event, _v.context.item.data.itemAction) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_TagsEditComponent_0, i13.RenderType_TagsEditComponent)), i1.ɵprd(6144, null, i14.DISABLEABLE_TOKEN, null, [i15.TagsEditComponent]), i1.ɵdid(12, 573440, null, 0, i15.TagsEditComponent, [i16.DialogsService, i1.ChangeDetectorRef, i11.I18nService], { tags: [0, "tags"], readonly: [1, "readonly"], settings: [2, "settings"], callOnEscape: [3, "callOnEscape"], tagEditDialogOpen: [4, "tagEditDialogOpen"], dialogId: [5, "dialogId"] }, { changed: "changed" }), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, 1, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _v.context.item.data.rbacSettings; _ck(_v, 2, 0, currVal_1); var currVal_2 = (_v.context.item.key + "-tags"); var currVal_3 = _v.context.item.iconProps.iconName; var currVal_4 = _v.context.item.title; var currVal_5 = false; var currVal_6 = false; var currVal_7 = false; var currVal_8 = "menuitem"; var currVal_9 = (_v.context.item.key + "-tags-dialog"); _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_11 = i1.ɵunv(_v, 12, 0, i1.ɵnov(_v, 13).transform(_co.tags$)); var currVal_12 = !i1.ɵunv(_v, 12, 1, i1.ɵnov(_v, 14).transform(_co.isUserExposed$)); var currVal_13 = _v.context.item.data.itemAction.options.tags; var currVal_14 = i1.ɵnov(_v, 3); var currVal_15 = _co.tagEditOpen; var currVal_16 = (_v.context.item.key + "-tags-dialog"); _ck(_v, 12, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16); }, function (_ck, _v) { var currVal_0 = _v.context.item.key; _ck(_v, 1, 0, currVal_0); var currVal_10 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent, 0), "tags.manage")); _ck(_v, 7, 0, currVal_10); }); }
export function View_EntityCommandBarComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i17.I18nPipe, [i11.I18nService]), i1.ɵqud(402653184, 1, { tagsItemTemplateRef: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_EntityCommandBarComponent_1)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i5.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(0, [[1, 2], ["tagsItem", 2]], null, 0, null, View_EntityCommandBarComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.commandBarItems$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EntityCommandBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "entity-command-bar", [], null, null, null, View_EntityCommandBarComponent_0, RenderType_EntityCommandBarComponent)), i1.ɵdid(1, 770048, null, 0, i18.EntityCommandBarComponent, [i19.Router, i20.CommandBarItemService, i21.RoutesService, i22.ActivatedEntity, i1.ChangeDetectorRef, i11.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntityCommandBarComponentNgFactory = i1.ɵccf("entity-command-bar", i18.EntityCommandBarComponent, View_EntityCommandBarComponent_Host_0, { entityType: "entityType", entity: "entity", entities: "entities", options: "options", isLoadingEntitySubject$: "isLoadingEntitySubject$", lastSetActionsTime: "lastSetActionsTime", detailsActionId: "detailsActionId", lastDetailsActionTime: "lastDetailsActionTime", loadEntityError: "loadEntityError", isUserExposed$: "isUserExposed$", overFlowActionsView: "overFlowActionsView", entityCommandBarDisplayMode: "entityCommandBarDisplayMode" }, { commandBarAction: "commandBarAction" }, []);
export { EntityCommandBarComponentNgFactory as EntityCommandBarComponentNgFactory };
