
		<ul
			class="radio-list"
			[ngClass]="{ 'wcd-flex-horizontal': isHorizontal }"
			role="radiogroup"
			[attr.aria-labelledby]="ariaLabelledby"
			[attr.aria-activedescendant]="value && 'radio-' + name + '_' + value.id"
			#radiolist
		>
			<li
				*ngFor="let item of values; let i = index"
				[ngClass]="isHorizontal && itemWidth ? 'wcd-width-' + itemWidth : liCustomClass"
				[tabIndex]="getTabIndex(item)"
				(keydown.arrowDown)="selectItem(i + 1)"
				(keydown.arrowRight)="selectItem(i + 1)"
				(keydown.arrowUp)="selectItem(i - 1)"
				(keydown.arrowLeft)="selectItem(i - 1)"
				role="radio"
				[attr.aria-checked]="item === value"
			>
				<div [ngClass]="!!getItemLabel(item) ? 'wcd-flex-vertical': 'wcd-flex-horizontal'">
					<input
						type="radio"
						[name]="'radio-' + name + '_' + item.id"
						[id]="'radio-' + name + '_' + item.id"
						[attr.value]="item.id"
						(change)="selectValue(item)"
						[disabled]="disabled || item.disabled"
						[checked]="item === value"
					/>
					<label
						[attr.for]="'radio-' + name + '_' + item.id"
						[class.disabled]="disabled || item.disabled"
					>
						<wcd-shared-icon
							[iconName]="'radio.' + (item === value ? 'checked' : 'unchecked')"
							[ariaLabel]="''"
							class="radio-btn-icon"
							[ngClass]="{
								'color-text-secondary': item === value,
								disabled: disabled || item.disabled
							}"
						>
						</wcd-shared-icon>
						<wcd-shared-icon *ngIf="getItemIcon(item)" [iconName]="getItemIcon(item)">
						</wcd-shared-icon>
						<span class="radio-btn-text" [ngClass]="labelClass">{{ getItemLabel(item) }}</span>
						<wcd-help *ngIf="item.helpKey" [text]="item.helpKey | i18n"></wcd-help>
					</label>
					<label
						[attr.for]="'radio-' + name + '_' + item.id"
						[class.disabled]="disabled || item.disabled"
					>
						<ng-container
							*ngTemplateOutlet="radioTemplate; context: { $implicit: item }"
						></ng-container>
					</label>
				</div>
				<hr
					*ngIf="isBordered"
					class="ms-border-color-neutralQuaternaryAlt wcd-margin-small-vertical"
				/>
			</li>
		</ul>
	