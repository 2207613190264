import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { AlertClassification } from '../alert/alert-classification.entity';
import { AlertDetermination } from '../alert/alert-determination.entity';
import { AlertHistoryItem } from '../alert/history/alert-history-item.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { IncidentStatus } from './incident-status/incident-status.entity';
@ApiCall({
	name: 'Update incidents',
	endpoint: 'incidents',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.incidentUpdate,
	separateArrayParams: true,
	parseQuery: (incidentUpdateOptions: IncidentsUpdateOptions) => {
		return {
			data: Object.assign(
				{ Ids: incidentUpdateOptions.incidentIds },
				incidentUpdateOptions.determination
					? {
							Determination: incidentUpdateOptions.determination.id,
					  }
					: null,
				incidentUpdateOptions.assignedTo === null
					? {
						AssignedTo: null,
					} :
					{
						AssignedTo: incidentUpdateOptions.assignedTo,
					},
				incidentUpdateOptions.status ? { Status: incidentUpdateOptions.status.id } : null,
				incidentUpdateOptions.classification
					? {
							Classification: incidentUpdateOptions.classification.id,
					  }
					: null,
				incidentUpdateOptions.title ? { Title: incidentUpdateOptions.title } : null,
				incidentUpdateOptions.comment ? { Comment: incidentUpdateOptions.comment } : null
			),
		};
	},
})
export class IncidentsUpdateApiCall extends ApiCallModel<AlertHistoryItem, IncidentsUpdateOptions> {}

export interface IncidentsUpdateOptions {
	incidentIds: Array<string>;
	determination?: AlertDetermination;
	assignedTo?: { user: string } | string;
	status?: IncidentStatus;
	classification?: AlertClassification;
	title?: string;
	comment?: string;
}

interface IncidentUpdateApiParameters {
	Ids: Array<string>;
	Determination?: string;
	AssignedTo?: string;
	Status?: string;
	Classification?: string;
	Title?: string;
	Comment?: string;
}
