<div class="wcd-full-height">
	<ng-container *ngIf="(data$ | async) as data">
		<ng-container *ngIf="data.alertsSeveritySummary?.alertsCount > 0; else noAlerts">
			<alerts-severity-incident-summary
				class="wcd-full-height"
				[alertsSeveritySummary]="data.alertsSeveritySummary"
			></alerts-severity-incident-summary>
		</ng-container>

		<ng-template #noAlerts>
			<h4>{{ 'reports.widgets.alertsSummary.noAlerts' | i18n }}</h4>
		</ng-template>
	</ng-container>
</div>
