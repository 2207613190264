var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { MachinesService } from '../services/machines.service';
var MachineEntityPanelComponent = /** @class */ (function (_super) {
    __extends(MachineEntityPanelComponent, _super);
    function MachineEntityPanelComponent(injector, machinesService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.injector = injector;
        _this.machinesService = machinesService;
        return _this;
    }
    Object.defineProperty(MachineEntityPanelComponent.prototype, "machine", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    MachineEntityPanelComponent.prototype.showLoggedOnUsers = function () {
        this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
            noShadow: true,
            hasCloseButton: false,
        });
    };
    return MachineEntityPanelComponent;
}(EntityPanelComponentBase));
export { MachineEntityPanelComponent };
