/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./machine-itp-group-membership.component";
var styles_MachineGroupMembershipComponent = [];
var RenderType_MachineGroupMembershipComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineGroupMembershipComponent, data: {} });
export { RenderType_MachineGroupMembershipComponent as RenderType_MachineGroupMembershipComponent };
function View_MachineGroupMembershipComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wcd-datatable", [], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fieldWidths: [3, "fieldWidths"] }, null), i0.ɵpod(2, { name: 0, formattedLastSeen: 1, domainName: 2 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.groupMembership; var currVal_1 = false; var currVal_2 = _co.tableFields; var currVal_3 = _ck(_v, 2, 0, _co.resourceTableFieldsWidth, _co.resourceTableFieldsWidth, _co.resourceTableFieldsWidth); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_MachineGroupMembershipComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), "machines.entityDetails.fields.groupMembership.noneFound")); _ck(_v, 0, 0, currVal_0); }); }
export function View_MachineGroupMembershipComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineGroupMembershipComponent_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["noneFound", 2]], null, 0, null, View_MachineGroupMembershipComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.groupMembership == null) ? null : _co.groupMembership.length); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_MachineGroupMembershipComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-itp-group-membership", [], null, null, null, View_MachineGroupMembershipComponent_0, RenderType_MachineGroupMembershipComponent)), i0.ɵdid(1, 49152, null, 0, i7.MachineGroupMembershipComponent, [i4.I18nService], null, null)], null, null); }
var MachineGroupMembershipComponentNgFactory = i0.ɵccf("machine-itp-group-membership", i7.MachineGroupMembershipComponent, View_MachineGroupMembershipComponent_Host_0, { groupMembership: "groupMembership" }, {}, []);
export { MachineGroupMembershipComponentNgFactory as MachineGroupMembershipComponentNgFactory };
