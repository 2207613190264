import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { Subscription, zip } from 'rxjs';
import { Paris } from '@microsoft/paris';
import {
	SecurityRecommendation,
	RemediationTask,
	TaskArgs,
	RemediationType,
	RemediationCategory,
	ItsmTool,
	RemediationTaskPriority,
	MdeUserRoleActionEnum,
	ProductivityImpactRemediationType,
	RemediationTicket,
	RecommendationCategory,
	RemediationError,
	RemediationErrorType,
	RemediationErrorDetails,
	EolState,
	MachineGroup,
	RecommendationException,
	RecommendationToExceptionsRelationship,
	RecommendationExceptionStateValue,
	GlobalExceptionRbacGroupId,
	VulnerabilityType,
	TvmRecommendationOnboardingStatus,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { AuthService } from '@wcd/auth';
import { TvmMachineGroupsFilterService } from '../../../../tvm/services/tvm-machine-groups-filter.service';
import { take, map } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { RemediationItsmToolsService } from '../../remediation/remediation-tasks/services/remediation-itsm-tools-service';
import { AppConfigService } from '@wcd/app-config';
import { TabModel, TabModelConfig } from '../../../../shared/components/tabs/tab.model';
import { TvmProductivityImpactService } from '../../../../tvm/services/tvm-productivity-impact.service';
import { MessageBarStyles } from '../../../@tvm/common/styles';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

declare const moment: typeof import('moment-timezone');

// Hard coded SCIDs that Intune supports.
const INTUNE_SUPPORTED_SCIDS = [
	'scid-2012',
	'scid-91',
	'scid-92',
	'scid-90',
	'scid-2016',
	'scid-2013',
	'scid-2003',
	'scid-2509',
	'scid-2513',
	'scid-2503',
	'scid-2502',
	'scid-2501',
	'scid-2506',
	'scid-2512',
	'scid-2505',
	'scid-2504',
	'scid-2510',
	'scid-2511',
	'scid-2507',
	'scid-2500',
	'scid-2508',
	'scid-2090',
	'scid-2021',
	'scid-20000',
];

export enum Tabs {
	update = 'Update',
	uninstall = 'Uninstall',
	none = 'None',
}

const TRUSTED_FILES_REASONS: Array<string> = [
	'FileCertificateMicrosoftRoot',
	'FileCertificateNonMsTrustedSigner',
];
class RemediationActionTabModel extends TabModel {}

@Component({
	selector: 'remediation-task-creation',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './remediation-task-creation.component.html',
	styleUrls: ['./remediation-task-creation.component.scss'],
})
export class RemediationTaskCreationComponent extends PanelContainer implements OnInit {
	private _messageBarType = MessageBarType;
	private _savingSubscription: Subscription;
	private _groupsSubscription: Subscription;
	private isUserAllowedToCreateRemediation: boolean;
	private _saveCustomTiSubscription: Subscription;
	private queryIndicatorSubscription: Subscription;

	SpinnerSize = SpinnerSize;
	warningText: string;
	shouldLinkToException = false;
	shouldDisable: boolean;
	shouldDisableDueToRemediationType: boolean;
	remediationTaskTitle: string;
	remediationFullProductName: string;
	remediationTaskPriority: RemediationTaskPriority = RemediationTaskPriority.Medium;
	notes: string;
	dueDate: Date;
	earliestDateAllowed = moment()
		.add(24, 'hours')
		.toDate();
	error: string;
	isSaving: boolean;
	exportToCsv = false;
	exceptionLink: string;
	selectedItsmTool: ItsmTool;
	createTicketInIntune = false;
	dataTrackMap = {};
	submitDataTrackValue = '';
	supportedItsmTools: ItsmTool[];
	connectedItsmTools: ItsmTool[];
	itsmNoneOption = 'None';
	remediationTaskPriorityList = Object.keys(RemediationTaskPriority).filter(
		key => isNaN(key as any) && RemediationTaskPriority[key] !== 0
	);
	productivityImpactRemediationTypeList = Object.keys(ProductivityImpactRemediationType);
	isServiceNowFeatureEnabled: boolean;
	messageBarStyles = MessageBarStyles;
	isScaRemediation: boolean;
	isVaUpdateRemediation: boolean;
	isVaUninstallRemediation: boolean;
	isVaUpgradeRemediation: boolean;
	isVaAttentionRequiredRemediation: boolean;
	showProductivityImpactComponents: boolean;
	isExceptionsPerRbacFeatureEnabled: boolean;
	isZeroDayFeatureEnabled: boolean;
	isVaRemediation: boolean;
	isDeviceGroupFeatureEnabled: boolean;
	isRemediationOptionsSupported: boolean;
	isZeroDayRecommendation: boolean;
	isNetworkDevice: boolean;
	machineGroups: Array<MachineGroup>;
	readonly Tabs = Tabs;
	machineGroupsFromTvmGlobalFilter: number[];

	selectableRemediationActions: Array<TabModel>;

	selectedRemediationAction: TabModel;
	applyRemediationSelection: Record<ProductivityImpactRemediationType, string>;
	safeMachinesCounterText: string;
	isMicrosoftVendor: boolean = false;
	isMac: boolean = false;
	shouldHideMachineGroups: boolean = false;
	userExposedRbacGroupIds: number[];
	existingRecommendationExceptions: Array<RecommendationException>;
	existingActiveExceptionsRbacGroupIds: number[] = [];
	allRbacGroupsHaveAnActiveException: boolean;
	openIntuneTicketText: string;

	private _recommendation: SecurityRecommendation;
	private _remediationType: RemediationType;
	private _originalRemediationType: RemediationType;

	@Input() defaultSelectedRemediationActionId?: Tabs;

	@Input() productivityImpactRemediation: ProductivityImpactRemediationType;

	@Input()
	set recommendation(recommendation: SecurityRecommendation) {
		const assetStatistics = recommendation.assetsStatistics;
		this.showProductivityImpactComponents =
			this.productivityImpactService.isProductivityImpactExposed &&
			!!assetStatistics.nonProductivityImpactedAssets;

		if (this.showProductivityImpactComponents) {
			this.applyRemediationSelection = this.tvmTextsService.getCountedProductivityImpactRemediations(
				assetStatistics
			);
			this.safeMachinesCounterText = this.tvmTextsService.getText(
				TextToken.RemediationTaskSafeMachines,
				assetStatistics
			);
		}
		this._recommendation = recommendation;
	}

	get recommendation(): SecurityRecommendation {
		return this._recommendation;
	}

	@Output() done = new EventEmitter<RemediationTask>();

	constructor(
		router: Router,
		private paris: Paris,
		private tvmTextsService: TvmTextsService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private changeDetectionRef: ChangeDetectorRef,
		private authService: AuthService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private remediationItsmToolsService: RemediationItsmToolsService,
		private appConfigService: AppConfigService,
		private productivityImpactService: TvmProductivityImpactService,
		private appInsightsService: AppInsightsService,
		featuresService: FeaturesService,
		private rbacService: RbacService
	) {
		super(router);
		this.isServiceNowFeatureEnabled =
			featuresService.isEnabled(Feature.TvmServiceNowIntegration) ||
			this.appConfigService.IsServiceNowIntegrationEnabled;

		this.isUserAllowedToCreateRemediation = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.tvmRemediationHandling
		);

		this.isExceptionsPerRbacFeatureEnabled =
			appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);

		this.isZeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);

		this.isDeviceGroupFeatureEnabled = featuresService.isEnabled(Feature.TvmRemediationTaskDeviceGroups);
	}

	onRemediationDropdownChanged = newTab => {
		this.selectedRemediationAction = newTab;
		// First check if user has selected the same entry two times in a row. In that case, do nothing.
		if (this._remediationType == this.selectedRemediationAction.data) {
			return;
		}

		if (newTab.id == Tabs.none) {
			this._remediationType = RemediationType.AttentionRequired;
		} else {
			// Make sure it is their original remediation type since only 2 options.
			this._remediationType = this._originalRemediationType;
		}

		this.shouldDisableDueToRemediationType = this.shouldDisablePartialForms();
	};

	ngOnInit() {
		super.ngOnInit();
		this._remediationType = this._recommendation.remediationType;
		this._originalRemediationType = this._recommendation.remediationType;

		if (this.showProductivityImpactComponents && !this.productivityImpactRemediation) {
			this.productivityImpactRemediation = ProductivityImpactRemediationType.AllExposedAssets;
		}

		this.getPriorityLabel = this.getPriorityLabel.bind(this);
		this.getItsmToolLabel = this.getItsmToolLabel.bind(this);
		this.getUserImpactRemediationLabel = this.getUserImpactRemediationLabel.bind(this);

		this.isScaRemediation = this._recommendation.remediationType === RemediationType.ConfigurationChange;
		this.isVaUpdateRemediation = this._recommendation.remediationType === RemediationType.Update;
		this.isVaUninstallRemediation = this._recommendation.remediationType === RemediationType.Uninstall;
		this.isVaUpgradeRemediation = this._recommendation.remediationType === RemediationType.Upgrade;
		this.isVaAttentionRequiredRemediation =
			this._recommendation.remediationType === RemediationType.AttentionRequired;
		this.isVaRemediation =
			this.isVaUpdateRemediation ||
			this.isVaUninstallRemediation ||
			this.isVaUpgradeRemediation ||
			this.isVaAttentionRequiredRemediation;
		this.isRemediationOptionsSupported =
			(this.isZeroDayFeatureEnabled) && this.isVaRemediation;

		this.isZeroDayRecommendation =
			this._recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
			!this._recommendation.patchReleaseDate;

		this.remediationTaskTitle = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationTitle,
			this._recommendation
		);

		this.warningText = this.getWarningText();
		this.shouldDisable = this.shouldDisableForms();
		this.shouldDisableDueToRemediationType = this.shouldDisablePartialForms();
		this.exceptionLink = `remediation/recommendation-exceptions/${
			this.isScaRemediation ? this._recommendation.scId : this._recommendation.productId
		}`;

		this.supportedItsmTools = this.isScaRemediation
			? this.remediationItsmToolsService.getScaSupportedItsmTools()
			: this.remediationItsmToolsService.getSupportedItsmTools();
		this.connectedItsmTools = this.remediationItsmToolsService.getConnectedItsmTools();
		if (this.isScaRemediation) {
			this.connectedItsmTools = this.connectedItsmTools.filter(tool =>
				this.supportedItsmTools.includes(tool)
			);
			if (!INTUNE_SUPPORTED_SCIDS.includes(this._recommendation.scId)) {
				this.connectedItsmTools = this.connectedItsmTools.filter(tool => tool !== ItsmTool.Intune);
			}
		}
		this.createSubmitDataTraceValue();
		this.updateSubmitDataTrackValue();

		this.initRemediationActionOptions();
		this.initActiveExceptionRbacGroups();
		const softwareName: string = this.parseEntityName(this._recommendation.productName);
		this.isNetworkDevice = this.recommendation.category === RecommendationCategory.NetworkGear;

		this.openIntuneTicketText =
			this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.NotOnboarded
				? this.i18nService.strings
						.tvm_securityRecommendation_remediationTaskCreation_openIntuneTicket_for_not_managed_devices
				: this.i18nService.strings
						.tvm_securityRecommendation_remediationTaskCreation_openIntuneTicket;
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this._savingSubscription && this._savingSubscription.unsubscribe();
		this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();
		this.queryIndicatorSubscription && this.queryIndicatorSubscription.unsubscribe();
	}

	private isGlobalException(exception: RecommendationException): boolean {
		return (
			exception.rbacGroupId === null ||
			exception.rbacGroupId === undefined ||
			exception.rbacGroupId === GlobalExceptionRbacGroupId
		);
	}

	get shouldDisabledMachineGroupsDropdown(): boolean {
		return this.shouldDisable || this.allRbacGroupsHaveAnActiveException;
	}

	submitRequest() {
		// Removed 'completing the remedition' as Elena mentioned it should no longer be done on the front end. Will re factor this code next time.
		this.createRemediationActivity();
	}

	// Only block remediation needs to complete remediation activity once it is created, so set 'complateTask' to false by default
	createRemediationActivity() {
		if (this.selectedItsmTool === ItsmTool.Intune) {
			this.dialogsService.showSnackbar({
				text: this.i18nService.get(
					'tvm.securityRecommendation.remediationTaskCreation.pleaseWaitIntune'
				),
			});
		}

		if (this.isDeviceGroupFeatureEnabled) {
			this.submitRemediationActivity(this.machineGroups ? this.machineGroups.map(mg => mg.id) : []);
		} else {
			this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1)).subscribe(gr => {
				const groupIds: string = gr.machineGroups
					.filter(mg => mg.isSelected)
					.map(mg => mg.groupId)
					.join();
				this.submitRemediationActivity(groupIds);
			});
		}
	}

	submitRemediationActivity(groupIds) {
		this.isSaving = true;
		const newTask = this.createRemediationTask();
		const options = { params: [] };

		if (groupIds.length) {
			options.params['rbacGroups'] = `groups in (${groupIds})`;
		}

		this._savingSubscription = this.paris
			.getRepository(RemediationTask)
			.save(newTask, groupIds ? options : null)
			.subscribe(
				remediationTask => {
					this.done.emit(this.exportToCsv ? remediationTask : undefined);
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get('tvm.remediationTask.remediationCreated.remediationOnly.success'),
						icon: 'checkCircle',
						iconClassName: 'color-text-success',
					});

					this.appInsightsService.trackEvent('tvm-remediation-submit', {
						ticket: newTask.ticket,
						taskArgs: newTask.taskArgs,
						export: this.exportToCsv,
					});
				},
				(error: any) => {
					const remediationError: RemediationError = error.response;
					const data =
						newTask.ticket &&
						newTask.ticket.itsmTool === ItsmTool.ServiceNow &&
						remediationError.type === RemediationErrorType.ExternalTicket &&
						remediationError.externalTicketError
							? this.getServicenowErrorMessageData(remediationError.externalTicketError)
							: this.i18nService.get(
									'tvm.securityRecommendation.remediationTaskCreation.creationFailMessage'
								);

					this.dialogsService.showError({
						title: this.i18nService.strings.tvm_securityRecommendation_remediationTaskCreation_creationFailTitle,
						data: data,
					});

					this.isSaving = false;
					this.changeDetectionRef.markForCheck();
				}
			);
	}

	machineGroupSelected(machineGroups: Array<MachineGroup>) {
		this.machineGroups = machineGroups;
	}

	getUserImpactRemediationLabel(remediationType: ProductivityImpactRemediationType) {
		return this.applyRemediationSelection[remediationType];
	}

	getPriorityLabel(remediationTaskPriority: RemediationTaskPriority): string {
		return this.i18nService.get(`tvm_remediationTask_priority_${remediationTaskPriority}`);
	}

	getItsmToolLabel(itsmTool: ItsmTool): string {
		return this.i18nService.get(
			`tvm_securityRecommendation_remediationTaskCreation_itsmToolSelection_${itsmTool}`
		);
	}

	moveToSettingsPage(itsmTool: ItsmTool) {
		let route = '';
		switch (itsmTool) {
			case ItsmTool.Intune:
				route = 'integration';
				break;
			case ItsmTool.ServiceNow:
				route = 'servicenow';
				break;
		}
		this.router.navigate(['/preferences2', route]);
	}

	getDisconnectedItsmTools(): ItsmTool[] {
		return this.supportedItsmTools.filter(tool => !this.connectedItsmTools.includes(tool));
	}

	isItsmToolConnected(tool: ItsmTool): boolean {
		return this.connectedItsmTools.indexOf(tool) >= 0;
	}

	getEnableItsmToolText(itsmTool: ItsmTool): string {
		return this.i18nService.get(
			`tvm.securityRecommendation.remediationTaskCreation.itsmToolEnable.${itsmTool}`
		);
	}

	onSelectDate(date: Date) {
		if (date && date !== this.dueDate) {
			this.dueDate = date;
		}
	}

	updateSubmitDataTrackValue(event?: any) {
		if (this.isServiceNowFeatureEnabled) {
			this.dataTrackMap['createItsmTicket'] = this.selectedItsmTool
				? this.selectedItsmTool
				: this.itsmNoneOption;
		} else {
			this.selectedItsmTool = this.createTicketInIntune ? ItsmTool.Intune : null;
			this.dataTrackMap['createIntuneTicket'] = this.createTicketInIntune;
		}
		this.submitDataTrackValue = JSON.stringify(this.dataTrackMap);
	}

	private getServicenowErrorMessageData(error: RemediationErrorDetails) {
		const statusCodes = `${error.itsmStatusCode}_${error.externalServiceStatusCode}`;

		switch (statusCodes) {
			case '403_500':
				return `${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_403_500_1
				}<br><br>${this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_403_500_2}`;
			case '401_500':
				return `${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_1
				} <a href="https://securitycenter.windows.com/preferences2/servicenow">${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_link
				}</a><br><br>${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_2
				}`;
			case '500_500':
				return `${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_1
				}<br><br>${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_2
				}<a href="https://www.servicenow.com/support/contact-support.html" target="_blank" rel="noopener noreferrer">${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_link
				}</a>`;
			case '200_500':
				return `${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_1
				}<br><br>${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_2
				}<br>${this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_3}`;
			default:
				return `${
					this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_default_1
				}<br><br>${this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_default_2}`;
		}
	}

	private createSubmitDataTraceValue() {
		this.dataTrackMap = {
			remediationType: this.recommendation.remediationType,
			hasEolVersions: this.recommendation.hasEolVersions,
			hasUpcomingEolVersions: this.recommendation.hasUpcomingEolVersions,
		};
	}

	private createRemediationTask(): Partial<RemediationTask> {
		const remediationTaskRelatedComponent = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationRelatedComponent,
			this._recommendation
		);

		// Currently, there is a bug in back-end. DueDate is required for remediation request, however, in actuality, it is not required for AttentionRequired
		// Give it a date in the future for now. Email contact - jomok AND/OR elenaap
		// Since newTask is a const and I don't want to touch existing code, will override newTask here
		const futureDate = new Date();
		futureDate.setDate(futureDate.getDate() + 300);

		const remediationTask: Partial<RemediationTask> = {
			name: this.createRemediationTaskTitle(this._recommendation),
			description: this.createRemediationTaskDescription(this._recommendation),
			relatedComponent: remediationTaskRelatedComponent,
			requesterNotes: this.notes,
			potentialRisk: this.isScaRemediation ? this._recommendation.potentialRisk : null,
			recommendationDescription: this.isScaRemediation ? this._recommendation.description : null,
			dueDate:
				!this.isScaRemediation &&
				this.selectedRemediationAction &&
				this.selectedRemediationAction.id == 'None' &&
				this.dueDate == null
					? futureDate
					: this.dueDate,
			taskArgs: this.createTaskArgs(
				this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.Onboarded
			),
			ticket: this.createTaskTicket(),
			priority: this.remediationTaskPriority,
		};

		return remediationTask;
	}

	shouldDisableForms() {
		return (
			this._recommendation.status === 'Exception' ||
			!this.isUserAllowedToCreateRemediation ||
			(this._recommendation.assetsStatistics.assetsAtRiskCount === 0 &&
				!this.isVaUninstallRemediation &&
				!this.isVaUpgradeRemediation)
		);
	}

	shouldDisablePartialForms() {
		// Will probably add more conditions here as we iterate
		return this._remediationType === RemediationType.AttentionRequired;
	}

	shouldDisableSubmitButton() {
		if (this.isDeviceGroupFeatureEnabled) {
			// If user has de-selected all groups, disable submit button (no matter what the remediation type is)
			if (!this.shouldHideMachineGroups && this.machineGroups && this.machineGroups.length == 0) {
				return true;
			}
		}

		if (this._remediationType === RemediationType.AttentionRequired) {
			return false;
		}

		if (!this.dueDate) {
			return true;
		}

		return this.shouldDisable;
	}

	private createTaskTicket(): RemediationTicket {
		return this.selectedItsmTool && this.selectedItsmTool !== this.itsmNoneOption
			? {
					itsmTool: this.selectedItsmTool,
			  }
			: null;
	}

	private createTaskArgs(areAssetsManaged: boolean): TaskArgs {
		return this.isScaRemediation
			? {
					productivityImpactRemediationType: this.productivityImpactRemediation,
					category: RemediationCategory.SecurityConfiguration,
					securityConfigurationArgs: {
						scid: this._recommendation.scId,
						taskType: this._recommendation.remediationType,
					},
					areAssetsManaged: areAssetsManaged,
			  }
			: {
					productivityImpactRemediationType: this.productivityImpactRemediation,
					category: RemediationCategory.Software,
					softwareArgs: {
						taskType: this._remediationType,
						vendorId: this._recommendation.vendor,
						nameId: this._recommendation.productName,
						recommendedVersion: this._recommendation.recommendedVersion,
						recommendedVendor: this._recommendation.recommendedVendor,
						recommendedProgram: this._recommendation.recommendedProgram,
						productId: this._recommendation.productId,
						isEOL: this._recommendation.eolSoftwareState === EolState.AlreadyEOL,
						isUpcomingEol: this._recommendation.eolSoftwareState === EolState.UpcomingEOL,
						hasEolVersions: this._recommendation.hasEolVersions,
						hasUpcomingEolVersions: this._recommendation.hasUpcomingEolVersions,
						mitigation: null
					},
					areAssetsManaged: areAssetsManaged,
			  };
	}

	getWarningText() {
		let text = '';
		if (!this.isUserAllowedToCreateRemediation) {
			text = text.concat(
				this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.NoPermission')
			);
		}
		if (this._recommendation.status === 'Exception') {
			text = text
				.concat(text.length === 0 ? '' : '\n\n')
				.concat(
					this.i18nService.get(
						'tvm.securityRecommendation.remediationTaskCreation.recommendationUnderException'
					)
				);
			this.shouldLinkToException = true;
		}

		if (
			this._recommendation.assetsStatistics.assetsAtRiskCount === 0 &&
			!this.isVaUninstallRemediation &&
			!this.isVaUpgradeRemediation
		) {
			text = text
				.concat(text.length === 0 ? '' : '\n\n')
				.concat(
					this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.noAssetsAtRisk')
				);
		}

		if (text.length === 0) {
			return this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.unknownError');
		}
		return text;
	}

	private createRemediationTaskTitle(recommendation: SecurityRecommendation): string {
		// Should use text service to get title, but we don't have a recommendation of type 'Block', and remediationType in recommendation is readonly, so need to use a hacky way here
		if (this._remediationType === RemediationType.AttentionRequired && !this.isZeroDayRecommendation) {
			return this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.attnSoftware', {
				software: this.parseEntityName(recommendation.productName),
			});
		} else {
			return this.remediationTaskTitle;
		}
	}

	private createRemediationTaskDescription(recommendation: SecurityRecommendation): string {
		const recommendationDescription = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationDescription,
			{ recommendation, noHtmlTags: true }
		);

		if (this.isScaRemediation) {
			return recommendation.remediationDescription;
		} else if (
			this._remediationType == RemediationType.AttentionRequired &&
			!this.isZeroDayRecommendation
		) {
			const fullProductName = this.tvmTextsService.getText(TextToken.EntityFullName, [
				this._recommendation.productName,
				this._recommendation.vendor,
			]);

			return this.i18nService.get(
				'tvm.securityRecommendation.descriptionTemplates.attnSoftwareDescription',
				{ software: `${fullProductName}` }
			);
		} else if (
			this.isVaUpdateRemediation ||
			this.isVaUninstallRemediation ||
			this.isVaUpgradeRemediation ||
			(this.isZeroDayRecommendation && this._remediationType == RemediationType.AttentionRequired)
		) {
			const vaDefinedRemediation = recommendation.remediationDescription;
			return vaDefinedRemediation ? vaDefinedRemediation : recommendationDescription;
		} else {
			throw new Error(`'${recommendation.remediationType}' isn't a supported remediation type`);
		}
	}

	// TODO: remove after recommendation supports type 'Software Block'
	private parseEntityName = (rawName: string) => {
		return rawName
			.split('_')
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ');
	};

	private initRemediationActionOptions() {
		if (
			(!this.isZeroDayFeatureEnabled) ||
			!this.isVaRemediation ||
			this.isVaUpgradeRemediation
		) {
			return;
		}

		const remediationActionOptions: Array<TabModelConfig> = [];

		if (this.isVaUpdateRemediation || this.isVaAttentionRequiredRemediation) {
			remediationActionOptions.push({
				id: Tabs.update,
				name: 'Update',
				data: RemediationType.Update,
			});
		}

		if (this.isVaUninstallRemediation || this.isVaAttentionRequiredRemediation) {
			remediationActionOptions.push({
				id: Tabs.uninstall,
				name: 'Uninstall',
				data: RemediationType.Uninstall,
			});
		}

		if (this.isZeroDayFeatureEnabled) {
			remediationActionOptions.push({
				id: Tabs.none,
				name: 'Attention required',
				data: RemediationType.AttentionRequired,
			});
		}

		this.selectableRemediationActions = remediationActionOptions.map(tab => new TabModel(tab));
		const defaultRemediationAction = this.selectableRemediationActions.find(
			remediation => remediation.id === this.defaultSelectedRemediationActionId
		);

		this.onRemediationDropdownChanged(defaultRemediationAction || this.selectableRemediationActions[0]);
	}

	private initActiveExceptionRbacGroups() {
		const isRbacGroupDropdownEnabled = this.isDeviceGroupFeatureEnabled;

		if (!this.isExceptionsPerRbacFeatureEnabled || !isRbacGroupDropdownEnabled) {
			return;
		}

		// Get related exceptions, so we can hide the machine groups that have been 'excepted'
		const repository = this.paris.getRelationshipRepository<
			SecurityRecommendation,
			RecommendationException
		>(RecommendationToExceptionsRelationship);
		repository.sourceItem = this._recommendation;
		zip(
			this.rbacService.userExposedRbacGroups$.pipe(
				map(res => res.map(machineGroup => machineGroup.id))
			),
			repository.query().pipe(map(res => res.items))
		).subscribe(([userExposedRbacGroupIds, existingRecommendationExceptions]) => {
			this.userExposedRbacGroupIds = userExposedRbacGroupIds;
			this.existingRecommendationExceptions = existingRecommendationExceptions;
			this.existingActiveExceptionsRbacGroupIds = this.existingRecommendationExceptions
				.filter(
					exception =>
						exception.status.value === RecommendationExceptionStateValue.Active &&
						!this.isGlobalException(exception)
				)
				.map(exception => exception.rbacGroupId);

			const existingActiveExceptionsRbacGroupIdsSet = new Set(
				this.existingActiveExceptionsRbacGroupIds
			);
			this.allRbacGroupsHaveAnActiveException = this.userExposedRbacGroupIds.every(rbacGroupId =>
				existingActiveExceptionsRbacGroupIdsSet.has(rbacGroupId)
			);

			if (this.allRbacGroupsHaveAnActiveException) {
				// Under full exception, disable form
				this.shouldDisable = true;
				this.warningText = this.i18nService.get(
					'tvm.securityRecommendation.remediationTaskCreation.recommendationUnderException'
				);

				this.shouldLinkToException = true;
			}

			this.changeDetectionRef.markForCheck();
		});
	}
}
