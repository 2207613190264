import { FlavorConfig } from '../models';

export interface IDevicesConfig {
	exposureLevel: FlavorConfig;
	iotDevices: FlavorConfig;
	networkTab: FlavorConfig;
	dataSensitivity: FlavorConfig;
	ipAddresses: FlavorConfig;
	users: FlavorConfig;
	autoIr: FlavorConfig;
	liveResponseSession: FlavorConfig;
	extraDeviceDetailsTabs: FlavorConfig;
	securityAssessmentWidget: FlavorConfig;
	loggedOnUsersWidget: FlavorConfig;
	isTimelineEnabled: FlavorConfig;
	tvm: FlavorConfig;
	machineValue: FlavorConfig;
	directoryData: FlavorConfig;
	onboardingStatus: FlavorConfig;
	goHunt: FlavorConfig;
}

export const DevicesConfig: IDevicesConfig = {
	exposureLevel: {
		mdeFlavors: ['P2', 'Smb'],
	},
	iotDevices: {
		mdeFlavors: ['P2', 'Smb'],
	},
	onboardingStatus: {
		mdeFlavors: ['P2', 'Smb'],
	},
	networkTab: {
		mdeFlavors: ['P2', 'Smb'],
	},
	dataSensitivity: {
		mdeFlavors: ['P2'],
	},
	ipAddresses: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	users: {
		mdeFlavors: ['P2'],
	},
	autoIr: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	liveResponseSession: {
		mdeFlavors: ['P2', 'Smb'],
	},
	extraDeviceDetailsTabs: {
		mdeFlavors: ['P2', 'Smb'],
	},
	securityAssessmentWidget: {
		mdeFlavors: ['P2', 'Smb'],
	},
	loggedOnUsersWidget: {
		mdeFlavors: ['P2'],
	},
	isTimelineEnabled: {
		mdeFlavors: ['P2'],
	},
	tvm: {
		mdeFlavors: ['P2', 'Smb'],
	},
	machineValue: {
		mdeFlavors: ['P2', 'Smb'],
	},
	directoryData: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	goHunt: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
};
