import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationLab, SimulatorsSettingsData } from '@wcd/domain';

@ApiCall({
	name: 'Update labs simulators settings',
	endpoint: 'evaluation/labs',
	method: 'PUT',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class UpdateSimulatorsSettings extends ApiCallModel<
	EvaluationLab,
	{ simulatorsSettings: SimulatorsSettingsData }
> {}
