/* tslint:disable:template-click-events-have-key-events */
import {
	Component,
	Input,
	ViewEncapsulation,
	SimpleChanges,
	OnChanges,
	SecurityContext,
} from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { UrlsService } from '../../../@entities/urls/services/urls.service';
import {  WicdSanitizerService, RegExpService } from '@wcd/shared';

const ALERT_DESCRIPTION_MAX_LENGTH_SHOWN = 500;


@Component({
	selector: 'alerted-description',
	templateUrl: './alerted-description.component.html',
	styleUrls: ['./alerted-description.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlertedDescriptionComponent implements OnChanges {
	@Input() description: string;
	@Input() recommendedAction: string;
	@Input() linkToEncyclopedia?: string = null;
	@Input() allowHtmlRendering?: boolean = false;
	@Input() oAuthAlertPageLinkLabel?: string;
	@Input() oAuthAlertPageLink?: string;

	decodedLinkToEncyclopedia?: string = null;

	descriptionExpanded: boolean = true;

	constructor(
		private i18nService: I18nService,
		private urlsService: UrlsService,
		private domSanitizer: WicdSanitizerService
	) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.linkToEncyclopedia && this.linkToEncyclopedia) {
			this.decodedLinkToEncyclopedia = this.urlsService.getDecodedUrl(this.linkToEncyclopedia);
		}
	}

	get expandableDescription(): boolean {
		return (
			(this.description ? this.description.length : 0) +
				(this.recommendedAction ? this.recommendedAction.length : 0) >
			ALERT_DESCRIPTION_MAX_LENGTH_SHOWN
		);
	}

	get descriptionText(): string {
		let descriptionText = '';
		const cleanedDescription = this.showOauthUrl && this.description ? this.description.replace(RegExpService.anchorTag, "") : this.description;

		if (this.description && this.description.length && this.recommendedAction && this.recommendedAction.length) {
			descriptionText = descriptionText.concat(cleanedDescription);
			descriptionText = descriptionText.concat('<br><br>');
			descriptionText = descriptionText.concat('<h5>');
			descriptionText = descriptionText.concat(
				this.i18nService.get('alerts.alert.recommendedActions')
			);
			descriptionText = descriptionText.concat('</h5>');
			descriptionText = descriptionText.concat(this.recommendedAction);

		} else {
			if (this.description && this.description.length) {
				descriptionText = cleanedDescription;
			}

			if (this.recommendedAction && this.recommendedAction.length) {
				descriptionText = this.recommendedAction;
			}
		}

		descriptionText = descriptionText.slice(
			0,
			this.descriptionExpanded ? Infinity : ALERT_DESCRIPTION_MAX_LENGTH_SHOWN
		);

		return descriptionText;
	}

	get oauthUrlText(): string {
		return this.domSanitizer.sanitize(SecurityContext.HTML, this.oAuthAlertPageLinkLabel);
	}

	get showOauthUrl(): boolean {
		return !!this.oAuthAlertPageLinkLabel;
	}
}
