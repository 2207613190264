<table>
	<label for="basics" class="wcd-margin-large-top wcd-font-weight-semibold">
		{{ i18n.strings.email_notification_wizard_basics }}
	</label>
    <tr>
        <td class="wcd-padding-large-right">{{ i18n.strings.email_notification_wizard_rule_name_step_title }}</td>
        <td>{{ data.name }}</td>
	</tr>
	<tr *ngIf="data.description">
        <td class="wcd-padding-large-right">{{ i18n.strings.email_notification_description_label }}</td>
        <td>{{ data.description }}</td>
	</tr>
	<tr *ngIf="statusForData">
        <td class="wcd-padding-large-right">{{ i18n.strings.tvm_common_status  }}</td>
        <td>{{ statusForData }}</td>
	</tr>
	<div class="wcd-padding-small-top">
		<a href="#" (click)="goToStep(0)">{{ editTitle }}</a>
	</div>
	<label for="notification-settings" class="wcd-margin-large-top wcd-font-weight-semibold">
		{{ i18n.strings.email_notification_wizard_notificationsSettings }}
	</label>
	<ng-container *ngFor="let setting of customSettingsForData">
		<tr *ngIf="!(isSettingsV2 && setting.key === i18n.strings.email_notification_notifyOnAlertSeverity)">
			<td class="wcd-padding-large-right wcd-flex-vertical">{{ setting.key }}</td>
			<td [innerHTML]="setting.value"></td>
		</tr>
	</ng-container>
	<tr *ngIf="shouldDisplayDeviceGroups">
		<td class="wcd-padding-large-right">{{ i18n.strings.email_notification_wizard_deviceGroupScope_title }}</td>
        <td>{{ machineDisplayText }}</td>
	</tr>
	<tr *ngIf="notificationSettingsForData && notificationSettingsForData.length">
		<td class="wcd-padding-large-right wcd-flex-vertical">{{ i18n.strings.email_notification_wizard_details }}</td>
		<td>
			<table>
				<ng-container *ngFor="let setting of notificationSettingsForData">
					<tr>
						<td>{{ setting }}</td>
					</tr>
				</ng-container>
			</table>
		</td>
	</tr>

	<div class="wcd-padding-small-top">
		<a href="#" (click)="goToStep(1)">{{ editTitle }}</a>
	</div>

	<div *ngIf="isSettingsV2" class="wcd-margin-large-top"></div>
	<wcd-collapsible-section *ngIf="isSettingsV2"
							 [label]="i18n.strings.notificationRules_conditions_serviceSource_title"
	>
		<tr>
			<th>{{ i18n.strings.notificationRules_columns_serviceSources }}</th>
			<th class="wcd-padding-large-horizontal">{{ i18n.strings.email_notification_wizard_detectionSource }}</th>
			<th>{{ i18n.strings.email_notification_wizard_severity }}</th>
		</tr>
		<tr *ngFor="let rule of rulesList">
			<td>{{ rule.serviceSource }}</td>
			<td class="wcd-padding-large-horizontal" >{{ rule.detectionSource }}</td>
			<td>{{ rule.severity }}</td>
		</tr>
		<div class="wcd-padding-small-top">
			<a href="#" (click)="goToStep(1)">{{ editTitle }}</a>
		</div>
	</wcd-collapsible-section>

	<label for="recipients" class="wcd-margin-large-top wcd-font-weight-semibold">
		{{ i18n.strings.email_notification_wizard_recipients }}
	</label>
	<tr>
        <td>
			<ul class="unstyled">
				<li *ngFor="let recipient of recipientsList">
					{{ recipient }}
				</li>
				<li *ngIf="additionalRecipientsCount > 0">
					{{ additionalRecipientsText }}
				</li>
			</ul>
		</td>
	</tr>
	<div class="wcd-padding-small-top">
		<a href="#" (click)="goToStep(2)">{{ editTitle }}</a>
	</div>
</table>



