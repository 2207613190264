var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsEntity, AirsEntityType, CapacityState, CustomTiIndicatorsCapacityApiCall, CustomTiIndicatorsType, CustomTiIndicatorsTypes, EvidenceDetectionSourceTypeEnum, File, RemediationActionTypeTypes, RemediationStatusId, SystemExclusionType, SystemExclusionTypeEnum, Tag, UndoRemediationApiCall, UndoEvidenceRemediationApiCall, } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AirsEntityEntityPanelComponent } from '../components/airs-entity.entity-panel.component';
import { compact, flatMap, isNil } from 'lodash-es';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { combineLatest, defer, of, race } from 'rxjs';
import { map, mapTo, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { RemediationActionsService } from '../../remediation/services/remediation-actions.service';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { Paris } from '@microsoft/paris';
import { AirsEntityTypesService } from './airs-entity-types.service';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { GoHuntAirsEntityService } from './go-hunt-airs-entity.service';
import { AuthService, tenantContextCache } from '@wcd/auth';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AirsEntityEntityPanelMultiComponent } from '../components/airs-entity.entity-panel-multi.component';
import { FabricIconNames } from '@wcd/scc-common';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { toObservable } from '../../../utils/rxjs/utils';
export var PENDING_ACTION_ID_STR = 'PendingAction';
export var UNDO_ACTION_ID_STR = 'entity-undo';
var SUPPORTED_UNDO_ENTITIES = [
    AirsEntityType.File,
    AirsEntityType.Service,
    AirsEntityType.Driver,
    AirsEntityType.PersistenceMethod,
];
// data retention in office is 30 days
var OFFICE_DATA_RETENTION = 30;
var AirsEntityEntityTypeService = /** @class */ (function () {
    function AirsEntityEntityTypeService(i18nService, featuresService, remediationActionsService, paris, goHuntAirsEntityService, customTiIndicatorService, entityTypesService, globalEntityTypesService, authService, confirmationService, flavorService) {
        var _this = this;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.remediationActionsService = remediationActionsService;
        this.paris = paris;
        this.goHuntAirsEntityService = goHuntAirsEntityService;
        this.customTiIndicatorService = customTiIndicatorService;
        this.entityTypesService = entityTypesService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.authService = authService;
        this.confirmationService = confirmationService;
        this.flavorService = flavorService;
        this.entityType = {
            id: 'airs-entity',
            entity: AirsEntity,
            getIcon: function (entities) {
                return GlobalEntityTypesService.getCommonValue(entities, function (entity) { return entity.type && entity.type.icon; });
            },
            getItem: function (entity, loadedEntity$, options) {
                var intEntityId = parseInt(entity && entity.id);
                var isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
                var loadUsingMergeByKey = entity.mergeByKey && _this.featuresService.isEnabled(Feature.EvidenceApiV2);
                if (entity.isOfficeEntity && !entity.isConvergedPlatform) {
                    var monthAgo = new Date();
                    monthAgo.setDate(monthAgo.getDate() - OFFICE_DATA_RETENTION);
                    if (entity.firstSeen && entity.firstSeen < monthAgo) {
                        // office data retention is 30 days, hence, we can't call to OATP APIs
                        return of(entity);
                    }
                }
                if (!loadUsingMergeByKey &&
                    (isIdNegative ||
                        (!(entity.investigation && entity.investigation.id) &&
                            !(options && options.investigation_id) &&
                            !(options && entity.isOfficeEntity)))) {
                    return of(entity);
                }
                var entity$ = loadedEntity$.pipe(map(function (e) {
                    e.useNewStatus = e.useNewStatus || entity.useNewStatus;
                    return e;
                }));
                // entity details API is missing some properties, so it's not always best to use
                if (entity.isOfficeEntity &&
                    options &&
                    options.loadDetailsInOptOut === false &&
                    sccHostService.isSCC &&
                    !tenantContextCache.hasMtpConsent) {
                    return of(entity);
                }
                return entity$;
            },
            getItemParams: function (entity, options) {
                var typeId = !isNil(options && options.type_id)
                    ? options.type_id
                    : entity.type && entity.type.id;
                if (!typeId) {
                    throw new Error("Can't get Entity params, missing entity type ID.");
                }
                var investigationId = !isNil(options && options.investigation_id)
                    ? options.investigation_id
                    : entity.investigation && entity.investigation.id;
                var useEvidenceApiV2 = _this.featuresService.isEnabled(Feature.EvidenceApiV2) && entity.mergeByKey;
                // no need investigationId for office entity and for the new evidence service
                if (!investigationId && !entity.isOfficeEntity && !useEvidenceApiV2) {
                    throw new Error("Can't get Entity params, missing entity investigation ID.");
                }
                // when 'convergedPlatorm' field is true - AIRS BE should be called, hence, don't use office entity api
                var useOfficeEntityApi = _this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
                    entity.isOfficeEntity &&
                    !entity.isConvergedPlatform;
                return {
                    type_id: typeId,
                    investigation_id: investigationId,
                    force_reload: true,
                    mergeByKey: entity.mergeByKey,
                    alertId: entity.detectionContext &&
                        entity.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert &&
                        entity.detectionContext.detectionId,
                    tenantId: sccHostService.isSCC ? sccHostService.loginUser.tenantId : null,
                    useOfficeEntityApi: useOfficeEntityApi,
                    useEvidenceApiV2: useEvidenceApiV2,
                    hideOpenLink: function () { return !_this.flavorService.isEnabled(AppFlavorConfig.incidents.fileOpenPage); },
                    evidenceV2: {
                        callDetails: !entity.isOfficeEntity || !entity.isConvergedPlatform,
                        entityId: entity.additionalData &&
                            entity.additionalData.evidenceV2 &&
                            entity.additionalData.evidenceV2.entityId,
                    },
                };
            },
            getIconCssClass: function (entities) {
                var commonRemediationStatus = GlobalEntityTypesService.getCommonValue(entities, function (entity) { return entity.useNewStatus && entity.remediationStatus; });
                if (commonRemediationStatus) {
                    return "round-icon color-box-" + commonRemediationStatus.className;
                }
                var commonVerdict = GlobalEntityTypesService.getCommonValue(entities, function (entity) { return (entity.useNewStatus ? entity.verdict : entity.status); });
                if (commonVerdict) {
                    return "round-icon color-box-" + commonVerdict.className;
                }
            },
            singleEntityPanelComponentType: AirsEntityEntityPanelComponent,
            multipleEntitiesComponentType: AirsEntityEntityPanelMultiComponent,
            getEntityClassName: function (entity) {
                return entity.useNewStatus && entity.verdict
                    ? entity.verdict.className
                    : entity.status
                        ? entity.status.className
                        : null;
            },
            getEntityName: function (entity) { return entity.entityName; },
            getSubtitle: function (entities) {
                return GlobalEntityTypesService.getCommonValue(entities, function (entity) { return entity.type.singularName; });
            },
            getEntitiesLink: function (entities) {
                var entity = entities.length === 1 && entities[0];
                if (entity) {
                    if (!_this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
                        entity.deepLink) {
                        return entity.deepLink;
                    }
                    if (entity.type.id === AirsEntityType.File && entity.sha1) {
                        return _this.globalEntityTypesService.getEntityLink(File, {
                            id: entity.sha1,
                        });
                    }
                }
                return null;
            },
            getUseExternalRouting: function (entities) {
                if (_this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)) {
                    return;
                }
                return !!(entities && entities.length && entities[0].deepLink);
            },
            getNavigationModel: function (entity) {
                var link = _this.entityType.getEntitiesLink([entity]);
                return link ? { routerLink: [link] } : null;
            },
            getEntitiesLinkText: function (entities) {
                return entities && entities.length === 1
                    ? _this.i18nService.get('entityCommon_commandBar_openPage', {
                        entity: entities[0].type.singularName.toLowerCase(),
                    })
                    : null;
            },
            getActions: function (entities, options) {
                var approvalActions = [];
                if (entities &&
                    entities.every(function (entity) {
                        return entity.remediationStatus &&
                            entity.remediationStatus.id === RemediationStatusId.PendingApproval;
                    })) {
                    // approval actions only relevant if all the actions are in Pending Approval status
                    approvalActions = _this.getApprovalActions(entities);
                }
                var undoAction = _this.getUndoAction(entities);
                var exclusionAction = _this.getExclusionAction(entities);
                var goHuntAction = _this.getGoHuntAction(entities, options);
                var actions = approvalActions.concat([undoAction, exclusionAction, goHuntAction]).filter(Boolean)
                    .map(toObservable);
                return combineLatest(actions).pipe(map(function (_actions) {
                    return compact(_actions).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
                }));
            },
            getTags: function (entities) {
                var commonVerdict = GlobalEntityTypesService.getCommonValue(entities, function (entity) { return (entity.useNewStatus ? entity.verdict : entity.status); });
                if (commonVerdict) {
                    var tags = [
                        new Tag({
                            id: commonVerdict.name,
                            isEditable: false,
                            name: commonVerdict.name,
                            className: 'color-box-' + commonVerdict.className,
                        }),
                    ];
                    var commonRemediationStatus = GlobalEntityTypesService.getCommonValue(entities, function (entity) { return entity.useNewStatus && entity.remediationStatus; });
                    if (commonRemediationStatus) {
                        tags.push(new Tag({
                            id: commonRemediationStatus.name,
                            isEditable: false,
                            name: commonRemediationStatus.name,
                            className: 'color-box-' + commonRemediationStatus.className,
                        }));
                    }
                    return tags;
                }
            },
        };
    }
    AirsEntityEntityTypeService.prototype.getExclusionAction = function (entities) {
        var _this = this;
        var entitiesType = GlobalEntityTypesService.getCommonValue(entities, function (entity) { return entity.type; });
        if (entitiesType &&
            entitiesType.enableSystemExclusion &&
            entities.every(function (entity) { return !entity.systemExclusionRule; })) {
            var systemExclusionType_1 = this.paris.getValue(SystemExclusionType, entitiesType.systemExclusionType);
            var indicatorValue_1 = GlobalEntityTypesService.getCommonValue(entities, function (entity) {
                var systemExclusion = _this.entityTypesService.getSystemExclusionRule(entity, systemExclusionType_1, null);
                if (systemExclusionType_1.id === SystemExclusionTypeEnum.files) {
                    return systemExclusion.properties.file_hash;
                }
                else if (systemExclusionType_1.id === SystemExclusionTypeEnum.ip) {
                    return systemExclusion.properties.ip;
                }
            });
            if (indicatorValue_1) {
                return defer(function () { return __awaiter(_this, void 0, void 0, function () {
                    var capacity, canAddIndicator, e_1;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                if (!tenantContextCache.hasMtpConsent && !tenantContextCache.appConfig.IsMdatpActive) {
                                    return [2 /*return*/, null];
                                }
                                _a.label = 1;
                            case 1:
                                _a.trys.push([1, 3, , 4]);
                                return [4 /*yield*/, this.paris
                                        .apiCall(CustomTiIndicatorsCapacityApiCall)
                                        .pipe(take(1))
                                        .toPromise()];
                            case 2:
                                capacity = _a.sent();
                                canAddIndicator = capacity.capacityState !== CapacityState.Unavailable &&
                                    capacity.total + 1 <= capacity.limit;
                                if (!canAddIndicator) {
                                    return [2 /*return*/, null];
                                }
                                // TODO: refactor once entity_types moves to FE
                                return [2 /*return*/, {
                                        id: 'createEntityExclusionRule',
                                        name: this.i18nService.get('systemExclusions_addRule', {
                                            systemExclusionType: entitiesType.singularName.toLowerCase(),
                                        }),
                                        rbac: [
                                            RbacMdeAllowedActions.securitySettings,
                                            RbacMdeAllowedActions.remediationActions,
                                        ],
                                        rbacState: RbacControlState.hidden,
                                        icon: 'add',
                                        method: function () {
                                            return _this.customTiIndicatorService
                                                .showCustomTiIndicatorNewDialog(_this.paris.getValue(CustomTiIndicatorsType, systemExclusionType_1.id === SystemExclusionTypeEnum.files
                                                ? CustomTiIndicatorsTypes.Files
                                                : systemExclusionType_1.id === SystemExclusionTypeEnum.ip
                                                    ? CustomTiIndicatorsTypes.Ip
                                                    : null), indicatorValue_1)
                                                .pipe(switchMap(function (panel) {
                                                return race([
                                                    panel.instance.save.pipe(mapTo(true)),
                                                    panel.instance.cancel.pipe(mapTo(false)),
                                                ]).pipe(take(1));
                                            }))
                                                .toPromise();
                                        },
                                        tooltip: this.i18nService.get('systemExclusions_generalDescription', {
                                            type: entitiesType.singularName,
                                        }),
                                        refreshOnResolve: true,
                                        closeOnAction: true,
                                    }];
                            case 3:
                                e_1 = _a.sent();
                                return [2 /*return*/, null];
                            case 4: return [2 /*return*/];
                        }
                    });
                }); });
            }
        }
    };
    AirsEntityEntityTypeService.prototype.getApprovalActions = function (entities) {
        var _this = this;
        //TODO: remove this if when evidenceV2 supports approve/decline actions
        if (this.featuresService.isEnabled(Feature.EvidenceApiV2) && this.featuresService.isEnabled(Feature.EvidenceApiV2DisableApprovalActions)) {
            return [];
        }
        if (this.featuresService.isEnabled(Feature.EvidenceApiV2) &&
            entities.every(function (e) {
                return e.mergeByKey &&
                    e.detectionContext &&
                    e.detectionContext.detectionType &&
                    e.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert;
            })) {
            return this.getApprovalActionsV2(entities);
        }
        var remediationActionsArr = entities.map(function (e) { return e.remediationActions; });
        var allRemediationActions = Array.from(new Set(compact(flatMap(remediationActionsArr))));
        if (remediationActionsArr &&
            // make sure every entity has remediation actions
            remediationActionsArr.every(function (_actions) { return !!(_actions && _actions.length); }) &&
            allRemediationActions &&
            allRemediationActions.length) {
            var isSecAdmin = this.authService.currentUser.isSecAdmin;
            var isAllowed = isSecAdmin || allRemediationActions.every(function (a) { return !a.isOfficeInvestigation; });
            var ignoreRbac_1 = sccHostService.isSCC && allRemediationActions.every(function (a) { return a.isOfficeInvestigation; });
            var isAllowed$ = void 0;
            if (!isAllowed && sccHostService.isSCC) {
                isAllowed$ = defer(function () { return sccHostService.auth.isInRole(SccRoles.searchAndPurge); });
            }
            else {
                isAllowed$ = of(isAllowed);
            }
            isAllowed$ = isAllowed$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
            var actionType_1 = GlobalEntityTypesService.getCommonValue(allRemediationActions, function (action) { return action && action.remediationActionType; });
            var approveAction = isAllowed$.pipe(map(function (hasPermission) {
                return Object.assign({
                    id: "approve" + PENDING_ACTION_ID_STR + "-" + ((actionType_1 && actionType_1.simpleName) ||
                        'general'),
                    name: actionType_1
                        ? _this.i18nService.strings.remediationActions_approve +
                            ' ' +
                            actionType_1.simpleName
                        : _this.i18nService.strings.remediationActions_approveGeneric,
                    icon: actionType_1 ? actionType_1.icon : null,
                    method: function (_entities) {
                        return _this.remediationActionsService.approvePendingActions(allRemediationActions);
                    },
                    tooltip: hasPermission
                        ? _this.i18nService.strings.remediationActions_tooltips_approve
                        : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                    refreshOnResolve: true,
                    disabled: !hasPermission,
                    allowRbacTooltipOverride: true,
                }, ignoreRbac_1
                    ? null
                    : {
                        rbac: [RbacMdeAllowedActions.remediationActions],
                        rbacState: RbacControlState.disabled,
                    });
            }));
            var declineAction = isAllowed$.pipe(map(function (hasPermission) {
                return Object.assign({
                    id: "decline" + PENDING_ACTION_ID_STR + "-" + ((actionType_1 && actionType_1.simpleName) ||
                        'general'),
                    name: actionType_1
                        ? _this.i18nService.strings.remediationActions_dismiss +
                            ' ' +
                            actionType_1.simpleName
                        : _this.i18nService.strings.remediationActions_dismissGeneric,
                    icon: actionType_1 ? actionType_1.icon : null,
                    method: function (_entities) {
                        var fileSha1 = (actionType_1 &&
                            actionType_1.type === RemediationActionTypeTypes.file_quarantine &&
                            GlobalEntityTypesService.getCommonValue(_entities, function (entity) { return entity && entity.sha1; })) ||
                            null;
                        return _this.remediationActionsService.confirmAndDismissPendingActionsByType(allRemediationActions, fileSha1);
                    },
                    tooltip: hasPermission
                        ? _this.i18nService.strings.remediationActions_tooltips_decline
                        : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                    refreshOnResolve: true,
                    disabled: !hasPermission,
                    allowRbacTooltipOverride: true,
                }, ignoreRbac_1
                    ? null
                    : {
                        rbac: [RbacMdeAllowedActions.remediationActions],
                        rbacState: RbacControlState.disabled,
                    });
            }));
            return [approveAction, declineAction];
        }
    };
    AirsEntityEntityTypeService.prototype.getApprovalActionsV2 = function (entities) {
        var _this = this;
        var remediationStatus = GlobalEntityTypesService.getCommonValue(entities, function (e) { return e.remediationStatus; });
        if (!(remediationStatus && remediationStatus.id === RemediationStatusId.PendingApproval)) {
            return;
        }
        var isSecAdmin = this.authService.currentUser.isSecAdmin;
        var isAllowed = isSecAdmin || entities.every(function (a) { return !a.isOfficeEntity; });
        var ignoreRbac = sccHostService.isSCC && entities.every(function (a) { return a.isOfficeEntity; });
        var isAllowed$;
        if (!isAllowed && sccHostService.isSCC) {
            isAllowed$ = defer(function () { return sccHostService.auth.isInRole(SccRoles.searchAndPurge); });
        }
        else {
            isAllowed$ = of(isAllowed);
        }
        isAllowed$ = isAllowed$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
        var approveAction = isAllowed$.pipe(map(function (hasPermission) {
            return Object.assign({
                id: "approve" + PENDING_ACTION_ID_STR + "-general",
                name: _this.i18nService.strings.remediationActions_approveGeneric,
                icon: null,
                method: function (_entities) {
                    return _this.remediationActionsService.approvePendingEntities(_entities.map(function (e) { return ({
                        mergeByKey: e.mergeByKey,
                        alertId: e.detectionContext.detectionId,
                        type: e.type,
                    }); }));
                },
                tooltip: hasPermission
                    ? _this.i18nService.strings.remediationActions_tooltips_approve
                    : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                refreshOnResolve: true,
                disabled: !hasPermission,
                allowRbacTooltipOverride: true,
            }, ignoreRbac
                ? null
                : {
                    rbac: [RbacMdeAllowedActions.remediationActions],
                    rbacState: RbacControlState.disabled,
                });
        }));
        var declineAction = isAllowed$.pipe(map(function (hasPermission) {
            return Object.assign({
                id: "decline" + PENDING_ACTION_ID_STR + "-general",
                name: _this.i18nService.strings.remediationActions_dismissGeneric,
                icon: null,
                method: function (_entities) {
                    var fileSha1 = GlobalEntityTypesService.getCommonValue(_entities, function (entity) {
                        return entity &&
                            entity.type.id === AirsEntityType.File &&
                            entity.sha1;
                    }) || null;
                    return _this.remediationActionsService.confirmAndDismissPendingActionsByEntities(_entities.map(function (e) { return ({
                        mergeByKey: e.mergeByKey,
                        alertId: e.detectionContext.detectionId,
                        type: e.type,
                    }); }), fileSha1);
                },
                tooltip: hasPermission
                    ? _this.i18nService.strings.remediationActions_tooltips_decline
                    : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                refreshOnResolve: true,
                disabled: !hasPermission,
                allowRbacTooltipOverride: true,
            }, ignoreRbac
                ? null
                : {
                    rbac: [RbacMdeAllowedActions.remediationActions],
                    rbacState: RbacControlState.disabled,
                });
        }));
        return [approveAction, declineAction];
    };
    AirsEntityEntityTypeService.prototype.getUndoAction = function (entities) {
        var _this = this;
        if (this.featuresService.isEnabled(Feature.EvidenceApiV2) &&
            entities.every(function (e) {
                return e.mergeByKey &&
                    e.detectionContext &&
                    e.detectionContext.detectionType &&
                    e.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert;
            })) {
            var undoAction = this.getUndoActionV2(entities);
            if (undoAction) {
                return undoAction;
            }
        }
        var undoableActionsArr = entities.map(function (e) { return e.undoableActions; });
        var allUndoableActions = Array.from(new Set(compact(flatMap(undoableActionsArr))));
        if (undoableActionsArr &&
            // make sure every entity has undoable actions
            undoableActionsArr.every(function (_actions) { return !!(_actions && _actions.length); }) &&
            allUndoableActions &&
            allUndoableActions.length) {
            return {
                id: UNDO_ACTION_ID_STR,
                rbac: [RbacMdeAllowedActions.remediationActions],
                nameKey: 'actionsHistory_undo_action',
                icon: FabricIconNames.Undo,
                closeOnAction: true,
                allowRbacTooltipOverride: true,
                method: function (_entities) { return __awaiter(_this, void 0, void 0, function () {
                    var done$, e;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                done$ = new EventEmitter();
                                return [4 /*yield*/, this.confirmationService.confirm({
                                        title: this.i18nService.strings.actionsHistory_undo_confirm_title,
                                        text: this.i18nService.strings.actionsHistory_undo_confirm_text,
                                        showLoaderAndCloseOnEvent: done$,
                                    })];
                            case 1:
                                e = _a.sent();
                                if (!e.confirmed) return [3 /*break*/, 3];
                                return [4 /*yield*/, this.paris
                                        .apiCall(UndoRemediationApiCall, Array.from(new Set(allUndoableActions.map(function (a) { return a.id; }))))
                                        .toPromise()];
                            case 2:
                                _a.sent();
                                done$.next(true);
                                done$.complete();
                                return [2 /*return*/, Promise.resolve({ confirmed: true, data: null })];
                            case 3: return [2 /*return*/, Promise.reject()];
                        }
                    });
                }); },
            };
        }
    };
    AirsEntityEntityTypeService.prototype.getGoHuntAction = function (entities, options) {
        if (this.flavorService.isEnabled(AppFlavorConfig.incidents.hunting) && entities.length == 1) {
            return this.goHuntAirsEntityService
                .getGoHuntAirsEntityObservable(entities[0], {
                incident: options && options.incident,
                huntingContext: options && options.huntingContext,
            })
                .pipe(startWith(null));
        }
    };
    AirsEntityEntityTypeService.prototype.getUndoActionV2 = function (entities) {
        var _this = this;
        var remediationStatus = GlobalEntityTypesService.getCommonValue(entities, function (e) { return e.remediationStatus; });
        if (!(remediationStatus && remediationStatus.id === RemediationStatusId.Remediated)) {
            return;
        }
        // TODO: the backend logic is more complex, we need to know the action type to make a better decision here
        if (!entities.every(function (e) { return e.type && SUPPORTED_UNDO_ENTITIES.includes(e.type.id); })) {
            return;
        }
        return {
            id: UNDO_ACTION_ID_STR,
            rbac: [RbacMdeAllowedActions.remediationActions],
            nameKey: 'actionsHistory_undo_action',
            icon: FabricIconNames.Undo,
            closeOnAction: true,
            allowRbacTooltipOverride: true,
            method: function (_entities) { return __awaiter(_this, void 0, void 0, function () {
                var done$, e;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            done$ = new EventEmitter();
                            return [4 /*yield*/, this.confirmationService.confirm({
                                    title: this.i18nService.strings.actionsHistory_undo_confirm_title,
                                    text: this.i18nService.strings.actionsHistory_undo_confirm_text,
                                    showLoaderAndCloseOnEvent: done$,
                                })];
                        case 1:
                            e = _a.sent();
                            if (!e.confirmed) return [3 /*break*/, 3];
                            return [4 /*yield*/, this.paris
                                    .apiCall(UndoEvidenceRemediationApiCall, _entities.map(function (e) { return ({
                                    mergeByKey: e.mergeByKey,
                                    alertId: e.detectionContext.detectionId,
                                }); }))
                                    .toPromise()];
                        case 2:
                            _a.sent();
                            done$.next(true);
                            done$.complete();
                            return [2 /*return*/, Promise.resolve({ confirmed: true, data: null })];
                        case 3: return [2 /*return*/, Promise.reject()];
                    }
                });
            }); },
        };
    };
    return AirsEntityEntityTypeService;
}());
export { AirsEntityEntityTypeService };
