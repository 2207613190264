import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as IsolationCreateRequest from './isolation-requests.models';

export type UnisolateRawApiRequestBody = IsolationCreateRequest.IsolationRawApiRequestBody<'Unisolate'>;
export type UnisolateRawApiResponse = IsolationCreateRequest.IsolationRawApiResponse;

export type UnisolateParameters = IsolationCreateRequest.IsolationParameters;
export type UnisolateResponse = IsolationCreateRequest.IsolationResponse;

@ApiCall({
	name: 'Unisolate machine',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
		isolationType,
	}: UnisolateParameters): HttpOptions<UnisolateRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Type: 'IsolationRequest',
			IsolationType: isolationType,
			Action: 'Unisolate',
		},
	}),
	parseData: (rawData: UnisolateRawApiResponse): UnisolateResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.Id,
	}),
})
export class MachineUnisolateApiCall extends ApiCallModel<UnisolateResponse, UnisolateParameters> {}
