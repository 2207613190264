import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { columnClickEventPayload, HuntingGridColumn } from './results-table.component';
import { DataTableField, DataTableFieldConfig } from '@wcd/datatable';
import { isEmpty, isPlainObject, difference, maxBy, isObjectLike, head, isString } from 'lodash-es';
@Component({
	selector: 'hunting-record-property-display',
	styleUrls: ['./hunting-record-property-display.component.scss'],
	template: `
		<div class="wcd-full-height" [class.wcd-flex-center-vertical]="!presentValueAsTable">
			<span *ngIf="propertyData && columnInfo.icon" class="hunting-property-cell__entity-icon">
				<fab-icon [iconName]="columnInfo.icon"></fab-icon>
			</span>
			<route-link
				*ngIf="link && propertyData && !columnInfo.isClickable; else cellContentTemplate"
				[routeLink]="link"
				class="hunting-property-cell__link"
				rel="noopener"
				[linkAriaLabel]="columnInfo.name + ': ' + propertyData"
			>
				<ng-container *ngTemplateOutlet="cellContentTemplate"></ng-container>
			</route-link>

			<ng-template #cellContentTemplate>
				<ng-container *ngIf="!presentValueAsTable; else presentAsTable">
					<ng-container [ngSwitch]="columnInfo.type">
						<span
							*ngSwitchCase="'date'"
							[wcdTooltip]="propertyData | date: 'MM/dd/yyyy HH:mm:ss.SSS'"
						>
							{{ propertyData | date: 'M/d/yyyy H:mm:ss' }}
						</span>
						<span
							*ngSwitchCase="'object'"
							[wcdTooltip]="formatObjectString(propertyData)"
							[filterHtml]="true"
							>{{ formatObjectString(propertyData) }}</span
						>

						<span *ngSwitchDefault [wcdTooltip]="propertyData" [filterHtml]="true">
							<span *ngIf="columnInfo.isClickable; else notClickable">
								<span *ngIf="!isUserEntity; else userDataCell"
									class="hunting-property-cell__link"
									(click)="
									openEntitySidePanelClickEvent.emit({
										ItemId: propertyData,
										entityType: columnInfo.entityType
									})">
									{{ propertyData }}
								</span>
								<ng-template #userDataCell>
									<span class="hunting-property-cell__link"
										  (click)="onUserCellClick($event)">
										{{ propertyData }}
									</span>
								</ng-template>
							</span>
							<ng-template #notClickable>
								{{ propertyData }}
							</ng-template>
						</span>
					</ng-container>
				</ng-container>
				<ng-template #presentAsTable>
					<wcd-datatable
						[columns]="tableColumns"
						[items]="tableItems"
						[selectEnabled]="false"
						[isSmallPadding]="true"
						[showHeaders]="showTableHeaders"
					>
					</wcd-datatable>
				</ng-template>
			</ng-template>
		</div>
	`,
})
export class HuntingRecordPropertyDisplayComponent implements OnInit {
	@Input() columnInfo?: HuntingGridColumn;
	@Input() propertyData: any;
	@Input()
	set link(value) {
		this._link = !!value && !isEmpty(value.routerLink) && !!head(value.routerLink) ? value : null;
	}
	get link() {
		return this._link;
	}
	@Input() presentObjectValuesAsTable?: boolean = false;
	@Output() openUserPanelEvent: EventEmitter<{sid: string} | {aadUserId: string}> = new EventEmitter();
	@Output() openEntitySidePanelClickEvent: EventEmitter<columnClickEventPayload> = new EventEmitter<
		columnClickEventPayload
	>();

	private _link: any;
	tableColumns: Array<DataTableField> = [];
	tableItems: Array<{ [key: string]: any }> = [];
	presentValueAsTable: boolean;
	showTableHeaders: boolean = false;

	ngOnInit(): void {
		this.presentValueAsTable = this.presentObjectValuesAsTable && this.shouldPresentValueInTable(this.propertyData);
		if (this.presentValueAsTable) {
			if (!isObjectLike(this.propertyData)) {
				let parsedJsonValue;
				try {
					parsedJsonValue = JSON.parse(this.propertyData);
				} catch {
					// invalid json
					this.presentValueAsTable = false;
					return;
				}
				this.propertyData = parsedJsonValue;
			}
			this.setDisplayColumns();
		}
	}

	formatObjectString(value: string): string {
		return typeof value === 'object' ? JSON.stringify(value) : value;
	}

	private shouldPresentValueInTable(value: any): boolean {
		let parsedValue = value;
		if (isString(value)){
			// Check for stringified object / array.
			try{
				parsedValue = JSON.parse(value);
			}
			catch{
				// string value that can't be parsed to an object / array. don't show as a table.
				return false;
			}
		}

		if (isObjectLike(parsedValue)){
			// Object / array values are presented as tables as long as they aren't empty.
			return !isEmpty(parsedValue);
		}

		// Not an object / array - shouldn't be presented in a table.
		return false;
	}

	private setDisplayColumns(): void {
		if (Array.isArray(this.propertyData)) {
			this.setDisplayColumnsForArray();
		} else {
			// object type
			this.tableColumns = DataTableField.fromList([
				{
					id: 'key',
					name: 'Key',
				},
				{
					id: 'value',
					name: 'Value',
					getTooltip: (keyAndValueObject: { key: string; value: any }) =>
						this.formatObjectString(keyAndValueObject.value),
				},
			]);

			this.tableItems = Object.keys(this.propertyData).map(key => ({
				key: key,
				value: this.formatObjectString(this.propertyData[key]),
			}));
		}
	}

	private isArrayOfSimilarObjects(data: Array<any>): boolean {
		if (!data.every(isPlainObject)) {
			return false;
		}

		/**
		 * some arrays may include objects with "missing" keys when their values are null.
		 * For example: [{ 'key1':1, 'key2':2 }, { 'key1':11 }].
		 * Such arrays should show table with all possible keys.
		 */
		const keysOfItemWithMaxKeys = Object.keys(maxBy(data, item => Object.keys(item).length));

		// Check that every object in the array has keys that are contained in the item with max number of keys.
		return data.every(item => Object.keys(item).every(key => keysOfItemWithMaxKeys.includes(key)));
	}

	private setDisplayColumnsForArray(): void {
		if (this.isArrayOfSimilarObjects(this.propertyData)) {
			// property data is array of objects with same keys.
			// set the table's column as the objects keys.
			const firstItemKeys = isPlainObject(this.propertyData[0]) && Object.keys(this.propertyData[0]);
			this.tableColumns = DataTableField.fromList(
				firstItemKeys.map(key => ({
					id: key,
					name: key,
					getTooltip: (keyAndValueObject: { key: string; value: any }) =>
						this.formatObjectString(keyAndValueObject.value),
				}))
			);

			this.tableItems = this.propertyData;
			this.showTableHeaders = true;
			return;
		}

		this.tableColumns = DataTableField.fromList([
			{
				id: 'value',
				name: 'Value',
				getTooltip: (keyAndValueObject: { key: string; value: any }) =>
					this.formatObjectString(keyAndValueObject.value),
			},
		]);
		this.tableItems = this.propertyData.map(item => ({
			value: this.formatObjectString(item),
		}));
	}

	get isUserEntity(){
		return userCellProps.includes(this.columnInfo.name);
	}

	onUserCellClick($event) {
		$event.stopPropagation();
		const {name} = this.columnInfo;
		let userProps;
		if(name === "AccountSid" || name === "InitiatingProcessAccountSid"){
			userProps = {sid: this.propertyData};
		}
		else {
			userProps = {aadUserId: this.propertyData};
		}
		this.openUserPanelEvent.emit(userProps);
	}
}

export const userCellProps = ['AccountSid', 'AccountObjectId', 'InitiatingProcessAccountSid', 'InitiatingProcessAccountObjectId'];
