import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('bytes', bytesFilter);

function bytesFilter() {
	return function(bytes, precision) {
		if (!bytes || bytes.length == 0 || isNaN(parseFloat(bytes)) || !isFinite(bytes)) return '';
		if (typeof precision === 'undefined') precision = 1;

		if (bytes == 0) return 0;

		var units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
		var number = Math.floor(Math.log(bytes) / Math.log(1024));

		return (bytes / Math.pow(1024, Math.floor(number))).toFixed(precision) + ' ' + units[number];
	};
}
