import { Pipe, PipeTransform } from '@angular/core';
import { escapeRegExp } from 'lodash-es';

@Pipe({ name: 'stringContains' })
export class StringContainsPipe implements PipeTransform {
	transform(items: Array<string>, value: string) {
		const valueRegExp = new RegExp(escapeRegExp(value), 'i');
		return value
			? items.filter(item => {
					return valueRegExp.test(item);
			  })
			: items;
	}
}
