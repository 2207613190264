import { EntityModelBase, Entity, EntityField, DataSet } from '@microsoft/paris';
import { TvmAuthenticatedScanSharedEntityConfigurations } from '@wcd/domain';

@Entity({
    ...TvmAuthenticatedScanSharedEntityConfigurations,
    singularName: 'Authenticated scan result',
    pluralName: 'Authenticated scan results',
    endpoint: 'commands/liveResults',
    parseDataSet: (response: any): DataSet<any> => {
        return {
            count: response["output"].length,
            items: response["output"],
        };
    },
})
export class AuthenticatedScanResult extends EntityModelBase<string> {
    @EntityField({ required: true })
    readonly ip: string;

	// Required for dataview
	@EntityField({ data: 'ip' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

    @EntityField({ required: true })
    readonly scanSuccess: boolean;

    @EntityField()
    readonly errorMessage: string;

    @EntityField({ required: true })
	readonly ipRange: string;

	@EntityField()
    readonly description: string;

    @EntityField()
    readonly hostName: string;
}
