import { OnInit, Component, ChangeDetectionStrategy } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../reports/models/report-widget.model';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { filter, mergeMap, tap, map } from 'rxjs/operators';
import { LegacyUser, Machine, USER_LOOKBACK_IN_DAYS } from '@wcd/domain';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'user-device-list-widget',
	templateUrl: './user-device-list-widget.component.html',
})
export class UserDeviceListWidgetComponent extends ReportWidgetComponent<LegacyUser> {
	readonly machineEntityTypeService: EntityType<Machine>;

	readonly machineType = Machine;

	constructor(
		reportsService: ReportsService,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly activatedEntity: ActivatedEntity,
		private readonly i18nService: I18nService
	) {
		super(reportsService);
		this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
	}

	get widgetConfig(): ReportWidgetConfig<LegacyUser, LegacyUser, any> {
		return {
			id: 'user-device-list-widget',
			name: this.i18nService.get('users.entityDetails.report.widgets.deviceList.title'),
			rangeInDays: USER_LOOKBACK_IN_DAYS,
			loadData: () => {
				return this.activatedEntity.currentEntity$.pipe(
					filter(e => e instanceof LegacyUser),
					map((user: LegacyUser) => user)
				);
			},
		};
	}
}
