import { SevilleModule } from '../../seville/seville.module';

declare const moment: typeof import('moment-timezone');

SevilleModule.directive('datePicker', [
	'$compile',
	'$timeout',
	function($compile, $timeout) {
		return {
			restrict: 'E',
			replace: true,
			scope: {
				selectedDate: '=',
				minDate: '=',
				maxDate: '=',
				dateFormat: '&',
				utc: '&?',
				onLostFocus: '&?',
				// calling this "ariaLabel" will map this to the attribute "aria-label" causing
				// Narrator to read the label twice (both on the directive and on the input)
				inputAriaLabel: '=',
			},
			template:
				"<div class='input-group date'>\n  <input ng-attr-aria-label='{{inputAriaLabel ? inputAriaLabel : null}}' type='text' class='form-control'/>\n  <span class='input-group-addon'>\n    <span class='icon icon-CalendarMirrored'></span>\n  </span>\n</div>",
			link: function($scope: any, element, attr) {
				var input = element.find('input');

				element.find('.input-group-addon').on('click', function(e) {
					return input.focus();
				});

				element.on('dp.change', function(e: any) {
					$scope.changed = true;
					if (e.date) {
						if (e.oldDate) {
							// Detect whether the user changed the time component
							// (Assuming the smallest time unit displayed is minutes)
							var minutesInDay = 1440;
							var minutesChange =
								Math.abs(e.date.diff(e.oldDate, 'minutes', true)) % minutesInDay;
							if (minutesChange >= 1 && minutesChange <= minutesInDay - 1) {
								$scope.timeChanged = true;
							}
						}

						$scope.$evalAsync(function() {
							$scope.selectedDate = e.date;
						});
					}
				});

				if ($scope.onLostFocus) {
					input.on('blur', function(e) {
						//sometimes the blur event fires twice, prevent this
						var time = new Date();
						if ($scope.lastEvent && time.getTime() - $scope.lastEvent.getTime() < 100) return;

						$scope.lastEvent = time;

						$timeout(function() {
							$scope.selectedDate = input.data('DateTimePicker').date();
						}).then(function() {
							//call event handler and pass it whether the user changed the date and time components
							$scope.onLostFocus({
								e: {
									changed: $scope.changed,
									timeChanged: $scope.timeChanged,
								},
							});
						});
					});

					element.on('dp.show', function() {
						$scope.changed = false;
						$scope.timeChanged = false;
					});
				}

				function updateMinMax() {
					var min = $scope.minDate;
					var max = $scope.maxDate;
					if (max && (!min || min < max)) {
						input.data('DateTimePicker').maxDate(max);
					}
					if (min && (!max || min < max)) {
						input.data('DateTimePicker').minDate(min);
					}
				}

				$scope.$watch(
					function() {
						return $scope.maxDate;
					},
					function(newValue, oldValue) {
						if (newValue) {
							updateMinMax();
						}
					}
				);

				$scope.$watch(
					function() {
						return $scope.minDate;
					},
					function(newValue, oldValue) {
						if (newValue) {
							updateMinMax();
						}
					}
				);

				$scope.$watch(
					function() {
						return $scope.selectedDate;
					},
					function(newValue, oldValue) {
						if (newValue) {
							input.data('DateTimePicker').date(newValue);
						}
					}
				);

				if ($scope.utc && $scope.utc() && !moment.tz.zone('Etc/UTC')) {
					// Date picker is using moment.tz for time zone conversions
					// Load UTC time zone info into moment.tz, taken from the docs
					moment.tz.add('Etc/UTC|UTC|0|0|');
				}

				(<any>input).datetimepicker({
					format: $scope.dateFormat(),
					useCurrent: false,
					timeZone: $scope.utc && $scope.utc() ? 'Etc/UTC' : '', // Set picker time zone to UTC/local
					icons: {
						time: 'icon icon-Length',
						date: 'icon icon-CalendarMirrored',
						up: 'ms-Icon ms-Icon--TriangleSolidUp12',
						down: 'ms-Icon ms-Icon--TriangleSolidDown12',
					},
				});

				$scope.timeChanged = false;
				$scope.changed = false;

				return $compile(input)($scope);
			},
		};
	},
]);
