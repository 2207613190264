import { SevilleModule } from '../../../seville/seville.module';

SevilleModule.directive('investigationBar', investigationBarDirective);

function investigationBarDirective() {
	return {
		restrict: 'EA',
		controllerAs: 'vm',
		bindToController: true,
		template: `
		<div class="page-spacing">
        <div class="row investigation-flow ng-cloak">
            <span ng-repeat="item in vm.getItems()">
                <span ng-if="!$last">
                    <span class="investigation-flow-item">
                        <i ng-if="::item.icon" class="icon investigation-flow-icon" ng-class="item.icon"></i>
                        <a ng-if="!item.params" ui-sref="{{ item.state }}({ id: item.Id })">{{ item.displaystate }}</a>
                        <a ng-if="item.params" ui-sref="{{ item.state }}(item.params)">{{ item.displaystate }}</a>
                    </span>
                    <i class="icon icon-ChevronRight investigation-flow-icon"></i>
                </span>
                <span ng-if="$last" class="bolder">
                    <i ng-if="::item.icon" class="icon" ng-class="item.icon"></i>
                    {{item.displaystate}}
                </span>
            </span>
        </div>
    </div>
		`,
		controller: [
			'investigationService',
			function(investigation) {
				var vm = this;

				vm.getItems = function() {
					return investigation.flowItems;
				};
			},
		],
	};
}
