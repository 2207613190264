import { DateRangeModel } from '@wcd/localization';

export const enum TimeRangeId {
	'day' = 'day',
	'3days' = '3days',
	'week' = 'week',
	'month' = 'month',
	'3months' = '3months',
	'6months' = '6months',
	'custom' = 'custom',
}

export interface CustomTimeRangeValue {
	id: TimeRangeId.custom;
	isCustom: true;
	customRange: DateRangeModel;
	name: string;
	isDefault?: boolean;
}

export interface SpecificTimeRangeValue {
	id: Exclude<TimeRangeId, 'custom'>;
	value: 1 | 3 | 7 | 30 | 90 | 180;
	name: string;
	isDefault?: boolean;
}

export type TimeRangeValue = CustomTimeRangeValue | SpecificTimeRangeValue;

export function isCustomTimeRangeValue(timeRange: TimeRangeValue): timeRange is CustomTimeRangeValue {
	return (
		timeRange != null &&
		typeof timeRange === 'object' &&
		timeRange.hasOwnProperty('isCustom') &&
		(timeRange as CustomTimeRangeValue).isCustom === true
	);
}
