import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'AMSI Script',
	pluralName: 'AMSI Scripts',
	readonly: true,
	endpoint: 'files/amsiScript',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60 * 5,
		max: 10,
	},
})
export class Script extends EntityModelBase<string> {
	// override a property with a getter/setter pair isn't allowed 
	// @ts-expect-error 
	get id(): string {
		return this.sha256 || this.sha1;
	}

	set id(value: string) {}

	@EntityField({ data: 'Sha1' })
	readonly sha1: string;

	@EntityField({ data: 'Sha256' })
	readonly sha256: string;

	@EntityField({ data: 'ScriptContent' })
	readonly content: string;
}
