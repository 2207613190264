import { ComponentRef, Injectable, Output } from '@angular/core';
import { PanelContainer, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { HuntingDocumentationTableReferencePanelComponent } from '../components/hunting-documentation-table-reference.panel.component';
import { HuntingDocumentationSchemaTableListReferencePanelComponent } from '../components/hunting-documentation-schema-table-List-reference.panel.component';
import { map, take } from 'rxjs/operators';
import { SchemaService } from '@wcd/hunting';
import { AppContextService } from '@wcd/config';
import { Subject } from 'rxjs';

const MDATP_HELP_LINK: string = 'https://go.microsoft.com/fwlink/?linkid=2044079';
const MTP_HELP_LINK: string = 'https://aka.ms/mtp-ah-docs';

@Injectable({
	providedIn: 'root',
})
export class HuntingDocumentationService {
	@Output() huntingDocSampleQueryClick$: Subject<string> = new Subject<string>();
	@Output() huntingDocActionTypeOrFieldClick$: Subject<string> = new Subject<string>();
	private _huntingDocsPanel: ComponentRef<PanelContainer>;
	private _schemaTablesList: string[];

	get schemaTablesList(): string[] {
		return this._schemaTablesList;
	}

	constructor(
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private readonly appContextService: AppContextService,
		schemaService: SchemaService
	) {
		schemaService
			.getSchema()
			.pipe(
				map(schema => schema.tables),
				take(1)
			)
			.subscribe(schema => {
				this._schemaTablesList = schema.map(table => table.name);
				this._schemaTablesList.sort();
			});
	}

	getHelpLink(): string {
		return this.appContextService.isMtp ? MTP_HELP_LINK : MDATP_HELP_LINK;
	}

	openDocTableSidePanel(tableName: string) {
		if (!tableName) {
			return;
		}
		if (this._huntingDocsPanel) {
			this._huntingDocsPanel.destroy();
		}
		this.dialogsService
			.showPanel(
				HuntingDocumentationTableReferencePanelComponent,
				{
					id: 'hunting-documentation-table-reference-panel',
					isModal: true,
					showOverlay: false,
					hasCloseButton: true,
					type: PanelType.large,
					noBodyPadding: true,
					scrollBody: true,
					isBlocking: false,
					back: { onClick: () => this.openDocSchemaTableListSidePanel() },
				},
				{
					tableName: tableName,
				}
			)
			.subscribe((panel: ComponentRef<HuntingDocumentationTableReferencePanelComponent>) => {
				this._huntingDocsPanel = panel;
				panel.onDestroy(() => {
					this._huntingDocsPanel = null;
				});
			});
	}

	openDocSchemaTableListSidePanel() {
		if (!(Array.isArray(this._schemaTablesList) && this._schemaTablesList.length)) {
			return;
		}
		if (this._huntingDocsPanel) {
			this._huntingDocsPanel.destroy();
		}
		this.dialogsService
			.showPanel(
				HuntingDocumentationSchemaTableListReferencePanelComponent,
				{
					id: 'hunting-documentation-schema-table-list-panel',
					isModal: true,
					showOverlay: false,
					hasCloseButton: true,
					type: PanelType.large,
					noBodyPadding: true,
					scrollBody: true,
					isBlocking: false,
					role:"none"
				},
				{
					tableNameList: this._schemaTablesList,
				}
			)
			.subscribe((panel: ComponentRef<HuntingDocumentationSchemaTableListReferencePanelComponent>) => {
				this._huntingDocsPanel = panel;
				panel.onDestroy(() => {
					this._huntingDocsPanel = null;
				});
			});
	}

	runSampleQueryClicked(queryText: string) {
		this.huntingDocSampleQueryClick$.next(queryText);
	}

	onFieldOrActionTypeClick(recordId: string){
		this.huntingDocActionTypeOrFieldClick$.next(recordId);
	}
}
