import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ComponentProvisioningStatusModel } from './component-provisioning-status.value-object';

@ApiCall({
	name: 'Get component provisioning status',
	endpoint: 'mtp/componentProvisioning',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetComponentProvisioningStatusApiCall extends ApiCallModel<ComponentProvisioningStatusModel> {}
