import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { SendPartnerRecommendationApiCall, UserAssistanceData } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { PanelContainer } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { RegExpService } from '@wcd/shared';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
	selector: 'partner-recommendation-panel',
	template: `
		<wcd-panel (close)="destroy()" (save)="closePanel()" [settings]="settings">
			<form
				#partnerRecommendationForm="ngForm"
				class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical"
			>
				<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
					<div class="subtle wcd-margin-large-bottom">
						{{
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.helpKey'
								| i18n
						}}
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-required">
						<label for="partnerName">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerName'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="partnerName"
							class="form-control wcd-padding-small-all wcd-full-width wcd-no-resize"
							required
							name="partnerName"
							id="partnerName"
							(keydown.enter)="$event.preventDefault()"
						/>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top wcd-required">
						<label for="partnerWebsite">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerWebsite'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="partnerWebsite"
							class="form-control wcd-padding-small-all wcd-full-width"
							required
							name="partnerWebsite"
							id="partnerWebsite"
							[pattern]="RegExpService.urlOrDomain"
							(keydown.enter)="$event.preventDefault()"
						/>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top">
						<label for="email">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.title'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="email"
							class="form-control wcd-padding-small-all wcd-full-width wcd-margin-small-bottom"
							[placeholder]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.placeHolder'
									| i18n
							"
							name="email"
							id="email"
							validateRegExp="email"
							[disabled]="!includeEmail"
							(keydown.enter)="$event.preventDefault()"
						/>
						<wcd-checkbox
							[(ngModel)]="includeEmail"
							(ngModelChange)="setEmail()"
							name="recommendationIncludeEmail"
							[label]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.includeEmail'
									| i18n
							"
							></wcd-checkbox>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top wcd-required">
						{{
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.title'
								| i18n
						}}
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<textarea
							class="form-control comment-input wcd-full-width wcd-margin-large-bottom"
							[placeholder]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.placeHolder'
									| i18n
							"
							maxlength="1000"
							required
							rows="5"
							[(ngModel)]="partnerDescription"
							name="partnerDescription"
							[attr.aria-label]="'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.title' | i18n "
						></textarea>
						<div class="wcd-flex-justify-end-horizontal">
							<markdown [data]="'common.privacyStatement' | i18n"></markdown>
						</div>
					</div>
				</div>
				<footer class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
					<fab-primary-button
						[disabled]="!isValid || isSaving"
						(onClick)="submit()"
						className="wcd-margin-small-right"
						data-track-type="Button"
						[text]="
							(isSaving
								? 'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submitting'
								: 'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submit')
								| i18n
						"
						type="submit"
					>
						<fab-spinner
							*ngIf="isSaving"
							[size]="SpinnerSize.small"
							contentClass="wcd-margin-xsmall-right"
						>
						</fab-spinner>
					</fab-primary-button>
					<fab-default-button
						(onClick)="closePanel()"
						data-track-type="Button"
						[text]="'buttons.cancel' | i18n"
					>
					</fab-default-button>
				</footer>
			</form>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PartnerRecommendationPanelComponent extends PanelContainer implements OnInit {
	readonly SpinnerSize: typeof SpinnerSize = SpinnerSize;
	readonly RegExpService: typeof RegExpService = RegExpService;

	partnerName: string;
	partnerWebsite: string;
	partnerDescription: string;
	email: string;
	isSaving: boolean = false;
	includeEmail = true;

	constructor(
		protected router: Router,
		private authService: AuthService,
		private changeDetectorRef: ChangeDetectorRef,
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private liveAnnouncer: LiveAnnouncer,
	) {
		super(router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.setEmail();
	}

	get isValid(): boolean {
		const validName: boolean = !!this.partnerName && this.partnerName !== '';
		const validWeb: boolean = RegExpService.urlOrDomain.test(this.partnerWebsite);
		const validDescription = !!this.partnerDescription && this.partnerDescription !== '';
		const validEmail = !!!this.email || this.email === '' || RegExpService.email.test(this.email);
		return validName && validWeb && validDescription && validEmail;
	}

	setEmail() {
		this.email = this.includeEmail ? this.authService.currentUser.name : null;
		this.changeDetectorRef.markForCheck();
	}

	getVsoDescription(): string {
		return `New partner recommendation. Partner name: ${this.partnerName}, Partner Website: ${
			this.partnerWebsite
		}, Description: ${this.partnerDescription}`;
	}

	submit() {
		this.isSaving = true;
		this.liveAnnouncer.announce(
			this.i18nService.get('endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submitting'),
			'assertive',
			300
		);
		const data: Partial<UserAssistanceData> = {
			email: this.includeEmail && this.email,
			description: this.getVsoDescription(),
		};

		this.paris
			.apiCall(SendPartnerRecommendationApiCall, data)
			.pipe(
				finalize(() => {
					this.isSaving = false;
				})
			)
			.subscribe(
				result => {
					this.closePanel();
					this.dialogsService.confirm({
						title: this.i18nService.get(
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.title'
						),
						text: this.i18nService.get(
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.text'
						),
						showConfirm: false,
						cancelText: this.i18nService.get(
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.confirm.close'
						),
					});
				},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.get(
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.error.title'
						),
						text: this.i18nService.get(
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.error.text'
						),
						data: error,
					});
				}
			);
		this.changeDetectorRef.detectChanges();
	}
}
