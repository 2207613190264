/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/expander/src/lib/collapsible-section.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/expander/src/lib/collapsible-section.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i5 from "@angular-react/fabric";
import * as i6 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./collapsible-asset-hardware-and-firmware-section.component";
import * as i9 from "@microsoft/paris/dist/lib/paris";
import * as i10 from "../../../../../tvm/services/tvm-texts.service";
import * as i11 from "../../../../../shared/services/react-panels.service";
var styles_CollapsibleAssetHardwareAndFirmwareComponent = [];
var RenderType_CollapsibleAssetHardwareAndFirmwareComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CollapsibleAssetHardwareAndFirmwareComponent, data: {} });
export { RenderType_CollapsibleAssetHardwareAndFirmwareComponent as RenderType_CollapsibleAssetHardwareAndFirmwareComponent };
function View_CollapsibleAssetHardwareAndFirmwareComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 31, "wcd-collapsible-section", [], null, null, null, i1.View_CollapsibleSectionComponent_0, i1.RenderType_CollapsibleSectionComponent)), i0.ɵdid(1, 49152, null, 0, i2.CollapsibleSectionComponent, [], { label: [0, "label"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 26, "dl", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["", ""])), i0.ɵppd(8, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵppd(15, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(17, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i0.ɵeld(20, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(21, null, ["", ""])), i0.ɵppd(22, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(24, 0, null, null, 1, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(25, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵeld(27, 0, null, null, 2, "a", [["data-track-id", "OpenSeeAllDetails"], ["data-track-type", "Navigation"], ["role", "button"], ["tabindex", "0"]], null, [[null, "keydown.enter"], [null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.enter" === en)) {
        var pd_0 = (_co.openSeeAllDetails() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.openSeeAllDetails() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(28, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(29, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_hardware_and_firmware_section_title")), ""); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_hardware_and_firmware_system_model_title")); _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.systemText; _ck(_v, 11, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_hardware_and_firmware_processor_model_title")); _ck(_v, 14, 0, currVal_3); var currVal_4 = _co.processorText; _ck(_v, 18, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 21, 0, _ck(_v, 22, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_hardware_and_firmware_bios_title")); _ck(_v, 21, 0, currVal_5); var currVal_6 = _co.biosText; _ck(_v, 25, 0, currVal_6); var currVal_7 = i0.ɵunv(_v, 28, 0, _ck(_v, 29, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_hardware_and_firmware_see_all_details")); _ck(_v, 28, 0, currVal_7); }); }
function View_CollapsibleAssetHardwareAndFirmwareComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleAssetHardwareAndFirmwareComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.systemData || _co.biosData) || _co.processorData); _ck(_v, 3, 0, currVal_0); }, null); }
function View_CollapsibleAssetHardwareAndFirmwareComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "fab-spinner", [["labelPosition", "right"]], null, null, null, i4.View_FabSpinnerComponent_0, i4.RenderType_FabSpinnerComponent)), i0.ɵdid(2, 5816320, null, 0, i5.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { size: [0, "size"], label: [1, "label"], labelPosition: [2, "labelPosition"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.large; var currVal_1 = i0.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "common_loading")); var currVal_2 = "right"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_CollapsibleAssetHardwareAndFirmwareComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_CollapsibleAssetHardwareAndFirmwareComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_CollapsibleAssetHardwareAndFirmwareComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loaded; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_CollapsibleAssetHardwareAndFirmwareComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "collapsible-asset-hardware-and-firmware", [], null, null, null, View_CollapsibleAssetHardwareAndFirmwareComponent_0, RenderType_CollapsibleAssetHardwareAndFirmwareComponent)), i0.ɵdid(1, 114688, null, 0, i8.CollapsibleAssetHardwareAndFirmwareComponent, [i9.Paris, i0.ChangeDetectorRef, i10.TvmTextsService, i11.ReactPanelsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CollapsibleAssetHardwareAndFirmwareComponentNgFactory = i0.ɵccf("collapsible-asset-hardware-and-firmware", i8.CollapsibleAssetHardwareAndFirmwareComponent, View_CollapsibleAssetHardwareAndFirmwareComponent_Host_0, { machineId: "machineId" }, {}, []);
export { CollapsibleAssetHardwareAndFirmwareComponentNgFactory as CollapsibleAssetHardwareAndFirmwareComponentNgFactory };
