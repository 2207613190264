var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FilterValuesChecklistComponent, } from '../checklist/filter-values.checklist.component';
import { ChangeDetectorRef, OnInit, OnDestroy, } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { from, Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
var FilterValuesDropdownComboComponent = /** @class */ (function (_super) {
    __extends(FilterValuesDropdownComboComponent, _super);
    function FilterValuesDropdownComboComponent(changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.isLoading$ = new Subject();
        _this.loadingText$ = new Subject();
        _this.spinnerSize = SpinnerSize.small;
        return _this;
    }
    Object.defineProperty(FilterValuesDropdownComboComponent.prototype, "data", {
        get: function () {
            return this._dropdownValues;
        },
        set: function (data) {
            this._dropdownValues = data;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesDropdownComboComponent.prototype, "value", {
        get: function () {
            return {
                dropdown: this.currentDropdownSelection,
                checklist: this.checklist.value,
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesDropdownComboComponent.prototype, "checklistConfig", {
        get: function () {
            return __assign({}, ((this.config && this.config.checklist) || {}), { allowUnknownValues: false });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesDropdownComboComponent.prototype, "dropdownValues", {
        get: function () {
            return ((this.config && this.config.dropdown && this.config.dropdown.values) || this._dropdownValues || []);
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesDropdownComboComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.selectedValues)
            this.selectedValues = { checklist: [] };
        if (this.config) {
            if (this.config.dropdown && this.config.dropdown.getValues) {
                this._getDropdownValuesSubscription = this.config.dropdown.getValues().subscribe(function (values) {
                    _this._dropdownValues = values;
                });
            }
            if (this.config.defaultValue) {
                this.selectedValues = __assign({}, this.selectedValues, { dropdown: this.config.defaultValue });
                this.currentDropdownSelection = this.config.defaultValue;
                this.onDropdownSelect();
            }
            if (typeof this.config.loadingText === 'string')
                this.loadingText$.next(this.config.loadingText);
        }
    };
    FilterValuesDropdownComboComponent.prototype.ngOnDestroy = function () {
        if (this._getDropdownValuesSubscription)
            this._getDropdownValuesSubscription.unsubscribe();
    };
    FilterValuesDropdownComboComponent.prototype.onDropdownSelect = function (clearChecklistSelection) {
        var _this = this;
        if (clearChecklistSelection === void 0) { clearChecklistSelection = true; }
        this.error = null;
        if (clearChecklistSelection) {
            this.setChecklistSelection([]);
            this.checklist.setSelectedValues([]);
        }
        this.currentChecklistValues = { values: [] };
        if (this.currentDropdownSelection) {
            this.isLoading$.next(true);
            if (this.config && this.config.loadingText instanceof Function)
                this.loadingText$.next(this.config.loadingText(this.currentDropdownSelection));
            from(this.config.getValues(this.currentDropdownSelection))
                .pipe(finalize(function () {
                _this.isLoading$.next(false);
                _this.changeDetectorRef.markForCheck();
            }))
                .subscribe(function (checklistValues) {
                _this.currentChecklistValues = checklistValues;
                if (checklistValues.values.length === 1)
                    _this.checklist.setSelectedValues([checklistValues.values[0].value]);
            }, function (error) { return (_this.error = error); });
        }
    };
    FilterValuesDropdownComboComponent.prototype.onChecklistSelectionChange = function ($event) {
        this.setChecklistSelection($event);
    };
    FilterValuesDropdownComboComponent.prototype.setChecklistSelection = function (checklistSelection) {
        this.selectedValues = {
            dropdown: this.currentDropdownSelection,
            checklist: checklistSelection,
        };
        this.filterValuesChange.emit(this.selectedValues);
    };
    FilterValuesDropdownComboComponent.prototype.setSelectedValues = function (selectedValues) {
        this.selectedValues =
            selectedValues instanceof Array
                ? { checklist: selectedValues }
                : selectedValues || { checklist: [] };
        this.currentDropdownSelection = this.selectedValues.dropdown;
        if (this.checklist)
            this.checklist.setSelectedValues(this.selectedValues.checklist);
        this.onDropdownSelect(false);
        this.changeDetectorRef.markForCheck();
    };
    FilterValuesDropdownComboComponent.prototype.serialize = function () {
        return this.checklist.serialize();
    };
    FilterValuesDropdownComboComponent.prototype.deserialize = function (serializedSelectedValues) {
        return {
            checklist: this.checklist ? this.checklist.deserialize(serializedSelectedValues) : null,
        };
    };
    return FilterValuesDropdownComboComponent;
}(FilterValuesComponent));
export { FilterValuesDropdownComboComponent };
