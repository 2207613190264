class CSVUtils {


	constructor() {
	}

	public encodeCsv(value: string): string {
		if (value.includes(',') || value.includes('"')) {
			// Strings that contain a comma or double quotes should be escaped and wrapped in double quotes.
			return '"' + value.replace(/"/g, '""') + '"';
		}
		return value;
	}


	public decodeCsv(value: string): string[] {
		if (value === '') return [''];

		const values = [];

		let match: RegExpExecArray;
		const matcher = this.getSplitRegex();
		while (((match = matcher.exec(value)) !== null)) {
			if (match) values.push(this.decodeValue(match[1]));
		}
		return values;
	}

	public decodeValue(value: string): string {
		if (value.includes(',') || value.includes('"')) {
			// Strings that contain a comma or double quotes should be escaped and wrapped in double quotes.
			value =  value.slice(1, value.length-1);
		}
		if (value.includes('""')) {
			// Strings that contain a comma or double quotes should be escaped and wrapped in double quotes.
			return value.replace(/""/g, '"');
		}
		return value;
	}

	private getSplitRegex(): RegExp {
		return new RegExp('(?:,|\n|^)("(?:(?:"")*[^"]*)*"|[^",\n]*|(?:\n|$))', "g");
	}
}

export const CsvUtils = new CSVUtils();

