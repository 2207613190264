import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { DateRangeModel, TzDateService } from '@wcd/localization';
import { compact, merge } from 'lodash-es';
import { Investigation, InvestigationPendingType, InvestigationStatusEnum, } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FeaturesService, PreferencesService } from '@wcd/config';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { DownloadService } from '../../../shared/services/download.service';
import { InvestigationsService } from '../services/investigations.service';
import { SevilleInvestigationFields } from '../services/seville-investigation.fields';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
var TIME_RANGE_DEFAULT_PREFERENCE_ID = 'investigations_time_range_default';
var SevilleInvestigationsDataviewComponent = /** @class */ (function () {
    function SevilleInvestigationsDataviewComponent(preferencesService, investigationFields, investigationsService, authService, route, router, downloadService, globalEntityTypesService, entityPanelsService, appConfigService, featuresService, http, paris, i18nService, timeRangesService, tzDateService) {
        var _this = this;
        this.preferencesService = preferencesService;
        this.investigationFields = investigationFields;
        this.authService = authService;
        this.route = route;
        this.router = router;
        this.downloadService = downloadService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.entityPanelsService = entityPanelsService;
        this.appConfigService = appConfigService;
        this.featuresService = featuresService;
        this.http = http;
        this.paris = paris;
        this.i18nService = i18nService;
        this.timeRangesService = timeRangesService;
        this.tzDateService = tzDateService;
        this.isCustomTimeRangeValue = isCustomTimeRangeValue;
        this.entityType = this.globalEntityTypesService.getEntityType(Investigation);
        this.setTimeRanges();
        this.investigationsRepo = paris.getRepository(Investigation);
        this.setInvestigationActions = investigationsService.getInvestigationActions.bind(investigationsService);
        var currentRange = this.timeRanges.find(function (timeRange) {
            return timeRange.id ===
                (_this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID) ||
                    (_this.appConfigService.isDemoTenant && '1year'));
        }) || this.timeRanges[1];
        var locationRange = route.snapshot.queryParams.range;
        var locationCustomRangeMatch = locationRange && locationRange.match(/^(\d+)?:(\d+)?$/);
        if (locationRange) {
            var foundRange = this.timeRanges.find(function (range) {
                return (range.id === locationRange ||
                    (!isCustomTimeRangeValue(range) && range.value === Number(locationRange)) ||
                    (locationCustomRangeMatch && isCustomTimeRangeValue(range)));
            });
            if (foundRange) {
                currentRange = foundRange;
                if (isCustomTimeRangeValue(currentRange) && locationCustomRangeMatch)
                    this.customDateRange = new DateRangeModel(this.tzDateService, locationCustomRangeMatch[1] && new Date(parseInt(locationCustomRangeMatch[1])), locationCustomRangeMatch[2] && new Date(parseInt(locationCustomRangeMatch[2])));
            }
        }
        this.setDataviewConfig();
        this.currentRange = currentRange;
        this.setFixedOptions();
    }
    Object.defineProperty(SevilleInvestigationsDataviewComponent.prototype, "fixedOptions", {
        get: function () {
            return this._fixedOptions;
        },
        set: function (fixedOptions) {
            this._inputFixedOptions = fixedOptions;
            this.setFixedOptions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SevilleInvestigationsDataviewComponent.prototype, "currentRange", {
        get: function () {
            return this._currentRange;
        },
        set: function (range) {
            this._currentRange = range;
            if (!isCustomTimeRangeValue(range)) {
                this.customDateRange = DateRangeModel.fromDays(this.tzDateService, range.value);
            }
        },
        enumerable: true,
        configurable: true
    });
    SevilleInvestigationsDataviewComponent.prototype.onRangeTypeSelect = function (newRange) {
        var oldCustomRange = this.customDateRange;
        this.currentRange = newRange;
        if (!isCustomTimeRangeValue(this.currentRange)) {
            this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentRange.id);
        }
        if (this.customDateRange.toString() !== oldCustomRange.toString())
            this.onRangeSelect();
    };
    SevilleInvestigationsDataviewComponent.prototype.onRangeSelect = function () {
        var paramRange = this.currentRange.id;
        if (isCustomTimeRangeValue(this.currentRange)) {
            // create a new DateRangeModel so with full days instead of exact time
            this.customDateRange = new DateRangeModel(this.tzDateService, this.customDateRange.from, this.customDateRange.to);
            paramRange = this.customDateRange.toString();
        }
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: null,
                range: paramRange,
            },
            queryParamsHandling: 'merge',
        });
        this.setFixedOptions();
    };
    SevilleInvestigationsDataviewComponent.prototype.setFixedOptions = function () {
        this._fixedOptions = merge({
            fromDate: this.customDateRange.from.toISOString(),
            toDate: this.customDateRange.to.toISOString(),
            useSevilleApi: true,
        }, this._inputFixedOptions);
    };
    SevilleInvestigationsDataviewComponent.prototype.setDataviewConfig = function () {
        var _this = this;
        var config = {
            id: 'newInvestigations_dataview',
            getFiltersData: function (options) {
                var endpointUrl = _this.investigationsRepo.getEndpointUrl({ where: options });
                endpointUrl = endpointUrl.replace(/\/list$/, '');
                return _this.http.get(endpointUrl + "/filters", {
                    params: Object.assign({}, options, _this.fixedOptions),
                });
            },
        };
        if (this.appConfigService.isExposedToAllMachineGroups) {
            this.dataviewConfig = Object.assign({}, config, {
                exportResults: this.exportResults.bind(this),
            });
        }
        else {
            this.dataviewConfig = config;
        }
    };
    SevilleInvestigationsDataviewComponent.prototype.exportResults = function (options) {
        var _this = this;
        var statusList = options.status && (Array.isArray(options.status) ? options.status : [options.status]);
        var airsStatuses = statusList &&
            compact(statusList.map(function (status) { return InvestigationsService.getInvestigationStatusBySevilleStatus(status); }));
        var pendingTypes = airsStatuses &&
            airsStatuses
                .filter(function (status) { return status.isPending; })
                .map(function (status) {
                return _this.paris.getValue(InvestigationPendingType, function (type) { return type.equivalentStatus === status.id; });
            });
        return this.downloadService.downloadFromUrl(this.investigationsRepo.getEndpointUrl() + "/all.csv", {
            params: Object.assign({}, options, {
                start_date: this.customDateRange.toString(),
            }, airsStatuses && {
                status: Array.from(new Set(airsStatuses.map(function (status) {
                    // re-map to actual AIRS statuses (in the AIRS BE there are no separate pending types)
                    if (status.isPending) {
                        return InvestigationStatusEnum.pending;
                    }
                    return status.id;
                }))),
            }, pendingTypes &&
                pendingTypes.length && {
                pending_type: pendingTypes.map(function (type) { return type.id; }),
            }, options.originatingAlertTitles && {
                names: options.originatingAlertTitles,
            }, 
            // not supported
            // options.detectionSource && {
            // 	detection_system: options.detectionSource,
            // },
            options.machineNames && {
                hosts: options.machineNames,
            }),
        });
    };
    SevilleInvestigationsDataviewComponent.prototype.setTimeRanges = function () {
        this.timeRanges = this.timeRangesService
            .pick(["day" /* day */, "week" /* week */, "month" /* month */])
            .concat(this.appConfigService.isDemoTenant
            ? this.timeRangesService.pick(["6months" /* '6months' */]).concat({
                id: '1year',
                value: 365,
                name: this.i18nService.get('datetime_year_singular_template', { amount: 1 }),
            })
            : [])
            .concat(this.timeRangesService.pick(["custom" /* custom */]));
    };
    return SevilleInvestigationsDataviewComponent;
}());
export { SevilleInvestigationsDataviewComponent };
