/**
 * Manages SCC config services
 */
export class SccConfigService {
	constructor(private sccConfig) {}

	test(flightName: string): boolean {
		return this.sccConfig.test(flightName);
	}

	get(sectionName: string, settingName: string): Promise<string> {
		return this.sccConfig.get(sectionName, settingName);
	}

	getVariantSettings(settings: any, sectionName: string) {
		return this.sccConfig.getVariantSettings(settings, sectionName);
	}

	getVariantSetting(settings: any, sectionName?: string, settingName?: string) {
		return this.sccConfig.getVariantSetting(settings, sectionName, settingName);
	}
}
