import { AlertsSummaryWidgetBase } from '../../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.base';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { File, FileActiveAlertsSummaryRelationship } from '@wcd/domain';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';

@Component({
	selector: 'file-alerts-summary-widget',
	templateUrl:
		'../../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileAlertsSummaryWidgetComponent extends AlertsSummaryWidgetBase<File> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		i18nService: I18nService,
		reportsService: ReportsService
	) {
		super(paris, activatedEntity, reportsService, i18nService);
	}

	id = 'file-alerts-summary';

	entityType = File;

	entityAlertsSummaryRelationship = FileActiveAlertsSummaryRelationship;
}
