import { FlagsComponent } from './components/flags.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [CommonModule, BrowserModule, SharedModule],
	declarations: [FlagsComponent],
	exports: [FlagsComponent],
})
export class FlagsModule {}
