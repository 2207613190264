import { Injectable } from '@angular/core';
import { PanelSettings } from '@wcd/panels';
import { AirsEntity, AirsIp, AirsEntityType, AirsEntityTypeConfig, AirsUrl } from '@wcd/domain';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { DataEntityType, EntityModelBase } from '@microsoft/paris';
import { getCommonIpEntityFromAirsIp } from '../common_entities_converters/airs-ip-entity-to-ip.converter';
import { FeaturesService, Feature } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { getCommonUrlEntityFromAirsUrl } from '../common_entities_converters/airs-url-entity-to-url.converter';

@Injectable()
export class AirsEntityUnifiedPanelService {
	constructor(private entityPanelsService: EntityPanelsService, private featureService: FeaturesService) {}

	shouldOpenUnifiedEntityPanel(airsEntityType: AirsEntityTypeConfig): boolean {

		if (!airsEntityType || !sccHostService.isSCC) return false;

		switch (airsEntityType.id) {
			case AirsEntityType.IP:
				return this.featureService.isEnabled(Feature.AutoIrUnifiedIpPanel);
			case AirsEntityType.URL:
				return this.featureService.isEnabled(Feature.AutoIrUnifiedUrlPanel);
			default:
				return false;
		}
	}

	openUnifiedEntityPanel(airsEntity: AirsEntity, options?: any, panelSettings?: PanelSettings) {
		const entityTypeId = airsEntity.type.id;
		let commonEntityUnifiedPanel: CommonEntityUnifiedPanel<EntityModelBase>;

		switch (entityTypeId) {
			case AirsEntityType.IP:
				commonEntityUnifiedPanel = getCommonIpEntityFromAirsIp(<AirsIp>airsEntity);
				break;
			case AirsEntityType.URL:
				commonEntityUnifiedPanel = getCommonUrlEntityFromAirsUrl(<AirsUrl>airsEntity);
				break;
			default:
				return;
		}

		this.entityPanelsService.showEntity(
			commonEntityUnifiedPanel.entityConstructor,
			commonEntityUnifiedPanel.entityBaseData,
			options,
			panelSettings
		);
	}
}

export interface CommonEntityUnifiedPanel<T extends EntityModelBase<string | number>> {
	entityConstructor: DataEntityType<T>;
	entityBaseData: T;
}
