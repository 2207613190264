/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/charts/src/lib/bars/horizontal-bars-chart.component.ngfactory";
import * as i2 from "../../../../../projects/charts/src/lib/bars/horizontal-bars-chart.component";
import * as i3 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "@angular/common";
import * as i5 from "./threat-protection-detection-source.widget";
import * as i6 from "./services/reports.service";
import * as i7 from "./services/threat-protection-report.service";
import * as i8 from "./threat-protection-status-mapping";
var styles_ThreatProtectionDetectionSourceWidget = [];
var RenderType_ThreatProtectionDetectionSourceWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_ThreatProtectionDetectionSourceWidget, data: {} });
export { RenderType_ThreatProtectionDetectionSourceWidget as RenderType_ThreatProtectionDetectionSourceWidget };
export function View_ThreatProtectionDetectionSourceWidget_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "wcd-horizontal-bars-chart", [], null, null, null, i1.View_HorizontalBarsChartComponent_0, i1.RenderType_HorizontalBarsChartComponent)), i0.ɵdid(2, 770048, null, 0, i2.HorizontalBarsChartComponent, [i0.ElementRef, i3.I18nService], { settings: [0, "settings"], data: [1, "data"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.chartSettings$)); var currVal_1 = i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 4).transform(_co.data$)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_ThreatProtectionDetectionSourceWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ThreatProtectionDetectionSourceWidget_0, RenderType_ThreatProtectionDetectionSourceWidget)), i0.ɵdid(1, 245760, null, 0, i5.ThreatProtectionDetectionSourceWidget, [i6.ReportsService, i3.I18nService, i7.ThreatProtectionReport2Service, i8.ThreatProtectionStatusMapping], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ThreatProtectionDetectionSourceWidgetNgFactory = i0.ɵccf("ng-component", i5.ThreatProtectionDetectionSourceWidget, View_ThreatProtectionDetectionSourceWidget_Host_0, {}, {}, []);
export { ThreatProtectionDetectionSourceWidgetNgFactory as ThreatProtectionDetectionSourceWidgetNgFactory };
