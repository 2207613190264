<!-- tslint:disable:template-click-events-have-key-events -->
<div class="container">
    <span class="wcd-font-weight-bold">
        {{ 'tvm.securityRecommendation.exposedOperatingSystems.title' | i18n }}
    </span>
    <ul>
        <li *ngFor="let entry of knownExposedOperatingSystemsStatistics">
			<ng-container
			*ngIf="tvmTextsService.getOsProductName(entry.osName); then softwareLink; else softwareText">
		  </ng-container>

		  <ng-template #softwareLink>
            <a (click)="navigateToSoftwarePage(entry.osName)">
				{{ tvmTextsService.getOsPlatformLabel(entry.osName) }}
			</a>
		  </ng-template>

		  <ng-template #softwareText>
            <span>
				{{ tvmTextsService.getOsPlatformLabel(entry.osName)}}
			</span>
		  </ng-template>

            ({{ entry.exposedAssetsCount | prettyNumber }} {{ buildExposedMachinesText(entry.exposedAssetsCount) }})
		</li>
		<li *ngIf="unknownExposedOperatingSystemsCount > 0">
			{{ 'tvm.recommendationException.justification.Other' | i18n }}
            ({{ unknownExposedOperatingSystemsCount | prettyNumber }} {{ buildExposedMachinesText(unknownExposedOperatingSystemsCount) }})
		</li>
    </ul>
</div>
