import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { DataSensitivity } from './data-sensitivity.value-object';

@ApiCall({
	name: 'Incident sensitivity filter values',
	endpoint: 'incidents/sensitivityLabels',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.sensitivityLabels,
	type: DataSensitivity,
})
export class IncidentSensitivityFilterValues extends ApiCallModel<Array<DataSensitivity>> {}
