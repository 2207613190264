import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { UserAssistanceData, userAssistanceParseQuery } from './user-assistance.api-call.base';

@ApiCall({
	name: 'Send feedback by the user',
	endpoint: 'SubmitFeedbackAsync',
	method: 'POST',
	timeout: 60000,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.feedback,
	parseQuery: (feedback: Feedback) => {
		return {
			data: {
				FeedbackType: 'negative',
				...userAssistanceParseQuery(feedback),
			},
		};
	},
})
export class SendFeedbackApiCall extends ApiCallModel<any, Feedback> {}

export type Feedback = UserAssistanceData;
