import { NgModule } from '@angular/core';
import { PreloadingStrategy, Route, RouterModule, Routes } from '@angular/router';
import {
	AppConfigValueGuard,
	FeatureEnabledGuard,
	IsLiveResponseEnabled,
	SccRolesGuard,
	ThreatAnalyticsMtpGuard,
	FlavorGuard,
	TvmLicenseOrFlavorGuard,
	TvmLicenseMigrationFlavorGuard,
} from '@wcd/auth';
import { Feature, TenantStatusGuard } from '@wcd/config';
import { FLAGS_URL } from './app-routing.module';
import { errorRoutes } from './error/error.module';
import { FlagsComponent } from './flags/components/flags.component';
import { BreadcrumbsResolver } from './breadcrumbs/services/breadcrumbs-resolver.service';
import { Observable, of } from 'rxjs';
import { FilterImpactedAssets } from '@wcd/domain';
import { EndpointsGuard, SccNotOnboardedGuard, SccRedirectGuard } from '@wcd/config';
import { EndpointManagementPageComponent } from './endpoint_management/components/endpoint-management-page.component';
import { SccRoles } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
import { HUNTING_V2_SCC_STATE, HUNTING_PREFER_OLD_PAGE } from '@wcd/shared';
import { TvmLicenseType } from '@wcd/scc-common';

export const sccAppRoutes: Routes = [
	{
		path: '',
		resolve: {
			breadcrumbs: BreadcrumbsResolver,
		},
		children: [
			{
				path: 'settings/mtp_settings',
				loadChildren: 'app/scc-settings/scc-settings.module#SccSettingsModule',
				canActivate: [TenantStatusGuard],
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_settings',
					},
					allowMdeOptedOutOfMtp: false, // Only tenant who are opted-in to MTP can access mtp settings
				},
			},
			{
				path: 'onboard_mtp',
				canActivate: [SccNotOnboardedGuard, SccRolesGuard],
				loadChildren:
					'app/scc-onboarding-package/scc-onboarding-package.module#SccOnboardingPackageModule',
				data: {
					allowNotOnboarded: true,
					sccroles: [SccRoles.hasMtpViewPermission, SccRoles.mtpEligible],
				},
			},
			{
				path: 'mdatp-licenseexpired',
				canActivate: [SccRolesGuard],
				component: EndpointManagementPageComponent,
				data: {
					licenseExpired: true, // workaround for TC flags (IsSuspended, IsDeleted) which are hardcoded in the BE for MTP tenants
					sccroles: [SccRoles.mdatpLicenseExpired],
				},
			},
			// Note: this is needed since Nibiru don't support locating one nav item in two optional positions.
			{
				path: 'smb-incidents',
				redirectTo: 'incidents',
			},
			{
				path: 'incidents-queue',
				loadChildren: 'app/@entities/incidents/incidents.module#IncidentsModule',
				canActivate: [TenantStatusGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						entityTypeNameKey: 'incident_entityType_pluralName',
						transparent: true,
					},
				},
			},
			{
				path: 'incidents',
				loadChildren: 'app/@entities/incidents/incidents.module#IncidentsModule',
				canActivate: [TenantStatusGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						entityTypeNameKey: 'incident_entityType_pluralName',
						transparent: true,
					},
				},
			},
			{
				path: 'machines',
				loadChildren: 'app/@entities/machines/machines.module#MachinesModule',
				canActivate: [EndpointsGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						entityTypeNameKey: 'machine_entityType_pluralName',
						transparent: true,
					},
				},
			},
			{
				path: 'files',
				loadChildren: 'app/@entities/files/files.module#FilesModule',
				canActivate: [EndpointsGuard, FlavorGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						descriptionKey: 'loading_files',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.files,
				},
			},
			{
				path: 'urls',
				loadChildren: 'app/@entities/urls/urls.module#UrlsModule',
				canActivate: [EndpointsGuard, FlavorGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						descriptionKey: 'loading_urls',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.urls,
				},
			},
			{
				path: 'ips',
				loadChildren: 'app/@entities/ips/ips.module#IpsModule',
				canActivate: [EndpointsGuard, FlavorGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						descriptionKey: 'loading_ips',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.ips,
				},
			},
			{
				path: 'mdatp-users',
				loadChildren: 'app/@entities/users/users.module#UsersModule',
				canActivate: [EndpointsGuard, FlavorGuard],
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_users',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.mdeUsers,
				},
			},
			{
				path: 'investigation',
				loadChildren:
					'app/graph/airs-investigation/investigation-graph.module#InvestigationGraphModule',
				canActivate: [TenantStatusGuard, FlavorGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						descriptionKey: 'loading_investigation',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.investigation,
				},
			},
			{
				path: 'mtp-investigation',
				loadChildren:
					'app/graph/mtp-investigation/mtp-investigation-routing.module#MtpInvestigationRoutingModule',
				canActivate: [TenantStatusGuard, FlavorGuard],
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_investigation',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.investigation,
					allowOptedOutOfMtp: true,
				},
			},
			{
				path: 'action-center',
				loadChildren: 'app/@entities/action_center/action-center.module#ActionCenterModule',
				canActivate: [TenantStatusGuard],
				data: {
					preload: true,
					loadingComponentConfig: {
						descriptionKey: 'loading_action_center',
						transparent: true,
					},
					breadcrumbsConfig: {
						resetListOnEnter: true,
						show: false,
						itemConfig: {
							labelKey: 'actionCenter_title',
						},
					},
				},
			},
			{
				path: 'live-response',
				loadChildren: 'app/@entities/live_response/live-response.module#LiveResponseModule',
				canActivate: [TenantStatusGuard, AppConfigValueGuard, FeatureEnabledGuard],
				data: {
					features: [Feature.LiveResponse],
					appConfigValidator: IsLiveResponseEnabled,
					loadingComponentConfig: {
						descriptionKey: 'loading_liveResponse',
						transparent: true,
					},
				},
			},
			{
				path: 'hunting',
				canActivate: [TenantStatusGuard, TvmLicenseOrFlavorGuard, SccRedirectGuard],
				loadChildren: 'app/hunting-package/hunting-package.module#HuntingPackageModule',
				data: {
					preload: false,
					sccRedirect: {
						redirectTo: HUNTING_V2_SCC_STATE,
						featureNameToRedirect: [
							Feature.ShowNewHuntingPageToggle,
							Feature.HuntingNewPageByDefault,
						],
						sessionStorageKeyToStay: HUNTING_PREFER_OLD_PAGE,
					},
					flavorConfig: AppFlavorConfig.routes.hunting,
					tvmLicenseType: TvmLicenseType.TvmPremium,
				},
			},
			{
				path: 'advanced-hunting',
				canActivate: [TenantStatusGuard, TvmLicenseOrFlavorGuard, SccRedirectGuard],
				loadChildren: 'app/hunting-package/hunting-package.module#HuntingPackageModule',
				data: {
					preload: false,
					flavorConfig: AppFlavorConfig.routes.hunting,
					tvmLicenseType: TvmLicenseType.TvmBasic,
					sccRedirect: {
						redirectTo: HUNTING_V2_SCC_STATE,
						featureNameToRedirect: [
							Feature.ShowNewHuntingPageToggle,
							Feature.HuntingNewPageByDefault,
						],
						sessionStorageKeyToStay: HUNTING_PREFER_OLD_PAGE,
					},
				},
			},
			{
				path: 'custom_detection',
				canActivate: [TenantStatusGuard, TvmLicenseOrFlavorGuard, FeatureEnabledGuard],
				loadChildren:
					'app/@entities/scheduled_hunting/scheduled-hunting-routing.module#ScheduledHuntingRoutingModule',
				data: {
					features: [Feature.MtpCustomDetectionSccPage],
					flavorConfig: AppFlavorConfig.routes.hunting,
					tvmLicenseType: TvmLicenseType.TvmPremium,
				},
			},
			{
				path: 'interoperability',
				loadChildren: 'app/interoperability/interoperability.module#InteroperabilityModule',
				canActivate: [EndpointsGuard, FlavorGuard],
				data: {
					loadingComponentConfig: {
						descriptionKey: 'common_loading',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.interoperability,
				},
			},
			// Note: this is needed since Nibiru don't support locating one nav item in two optional positions.
			// It can be removed from here and from manifest.json once ThreatAnalyticsMTP feature is enabled
			// in all slices and 'mtp-threatanalytics' is changed to 'threatanalytics3' in Nibiru
			{
				path: 'mtp-threatanalytics',
				redirectTo: 'threatanalytics3',
			},
			{
				path: 'threatanalytics2',
				redirectTo: 'threatanalytics3',
			},
			{
				path: 'threatanalytics3',
				canActivate: [ThreatAnalyticsMtpGuard],
				data: {
					flavorConfig: AppFlavorConfig.threatAnalytics.mde,
				},
				loadChildren:
					'app/dashboards/threat-analytics3/threat-analytics.module#ThreatAnalyticsModule',
			},
			{
				path: 'configuration-management',
				canActivate: [EndpointsGuard, FeatureEnabledGuard, FlavorGuard],
				data: {
					features: [Feature.ConfigurationManagement],
					flavorConfig: AppFlavorConfig.routes.configurationManagement,
				},
				loadChildren:
					'app/configuration-management/configuration-management.module#ConfigurationManagementModule',
			},
			{
				path: 'configuration-management-2',
				canActivate: [EndpointsGuard, FeatureEnabledGuard],
				data: {
					features: [Feature.ConfigurationManagement],
				},
				loadChildren:
					'app/configuration-management/configuration-management.module#ConfigurationManagementModule',
			},
			{
				path: 'preferences2',
				canActivate: [EndpointsGuard],
				data: {
					breadcrumbsConfig: {
						show: true,
						resetListOnEnter: true,
						addParentWhenEmpty: true,
						itemConfig: {
							labelKey: 'settings_endpoints_page_title',
							icon: 'dashboard',
						},
						parentItemConfig: {
							id: `securitysettings`,
							labelKey: 'settings_page_title',
							icon: 'dashboard',
							url: '/securitysettings',
						},
					},
				},
				loadChildren: 'app/admin/admin.module#AdminModule',
				resolve: {
					breadcrumbs: BreadcrumbsResolver,
				},
			},
			{
				path: 'mdatp-reports',
				canActivate: [EndpointsGuard, FeatureEnabledGuard],
				data: {
					features: [Feature.ThreatReport],
					loadingComponentConfig: {
						descriptionKey: 'loading_report',
						transparent: true,
					},
				},
				loadChildren: 'app/reporting/reporting.module#ReportingModule',
			},
			{
				path: 'tvm_dashboard',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren: 'app/tvm/tvm.module#TvmModule',
				data: {
					breadcrumbsConfig: {
						itemConfig: {
							id: `tvmDashboard`,
							label: 'Threat and Vulnerability Management',
							icon: 'dashboard',
							url: '/tvm_dashboard',
						},
						resetListOnEnter: true,
						show: false,
					},
					pageTitle: 'Threat and Vulnerability Management',
					flavorConfig: AppFlavorConfig.routes.tvm,
				},
				resolve: {
					breadcrumbs: BreadcrumbsResolver,
				},
			},
			{
				path: 'baseline-compliance',
				canActivate: [EndpointsGuard, TvmLicenseMigrationFlavorGuard, FeatureEnabledGuard],
				loadChildren:
					'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
				data: {
					features: [Feature.TvmBaselineCompliance],
					flavorConfig: AppFlavorConfig.routes.tvm,
					tvmLicenseType: TvmLicenseType.TvmPremium,
				},
			},
			{
				path: 'baseline-compliance_t',
				canActivate: [EndpointsGuard, TvmLicenseMigrationFlavorGuard, FeatureEnabledGuard],
				loadChildren:
					'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
				data: {
					features: [Feature.TvmBaselineCompliance],
					flavorConfig: AppFlavorConfig.routes.tvm,
					tvmLicenseType: TvmLicenseType.TvmPremium,
				},
			},
			{
				path: 'tvm-event-insights',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren: 'app/@entities/@tvm/events/change-event.module#ChangeEventModule',
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_events',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.tvmEventTimeline,
				},
			},
			{
				path: 'endpoints/search',
				canActivate: [EndpointsGuard],
				loadChildren: 'app/search/search.module#SearchModule',
				data: {
					preload: false,
					loadingComponentConfig: {
						descriptionKey: 'loading_search',
						transparent: true,
					},
				},
			},
			{
				path: 'compare-kit',
				canActivate: [EndpointsGuard, FeatureEnabledGuard],
				loadChildren:
					'app/@entities/@tvm/comparison-tool/comparison-tool.module#ComparisonToolModule',
				data: {
					enableFeatures: [Feature.TvmComparisonKit],
					loadingComponentConfig: {
						descriptionKey: 'loading_compare_kit',
						transparent: true,
					},
				},
			},
			{
				path: 'vulnerabilities',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren:
					'app/@entities/@tvm/vulnerabilities/vulnerabilities.module#VulnerabilitiesModule',
				data: {
					loadingComponentConfig: {
						entityTypeNameKey: 'vulnerability_entityType_pluralName',
						transparent: true,
					},
					queryParams: {
						filters: `numOfImpactedAssets=${FilterImpactedAssets.hasEffect}`,
					},
					flavorConfig: AppFlavorConfig.routes.tvm,
				},
			},
			{
				path: 'software-inventory',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren:
					'app/@entities/@tvm/software-inventory/software-inventory.module#SoftwareInventoryModule',
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_software_inventory',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.tvm,
				},
			},
			{
				path: 'security-recommendations',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren:
					'app/@entities/@tvm/security-recommendations/security-recommendations.module#SecurityRecommendationsModule',
				data: {
					loadingComponentConfig: {
						entityTypeNameKey: 'securityRecommendation_entityType_pluralName',
						transparent: true,
					},
					queryParams: {
						filters: 'status=Active',
					},
					flavorConfig: AppFlavorConfig.routes.tvm,
				},
			},
			{
				path: 'remediation',
				canActivate: [EndpointsGuard, FlavorGuard],
				loadChildren: 'app/@entities/@tvm/remediation/remediation.module#TvmRemediationModule',
				data: {
					loadingComponentConfig: {
						descriptionKey: 'loading_remediation_page',
						transparent: true,
					},
					flavorConfig: AppFlavorConfig.routes.tvm,
				},
			},
			{
				path: FLAGS_URL,
				component: FlagsComponent,
				canActivate: [TenantStatusGuard, FeatureEnabledGuard],
				data: {
					pageTitle: 'Flags',
					features: [Feature.Flags],
				},
			},
		],
	},
];

export class PreloadSelectedModulesList implements PreloadingStrategy {
	preload(route: Route, load: Function): Observable<any> {
		return route.data && route.data.preload ? load() : of(null);
	}
}

export const SccAppPaths: Array<string> = sccAppRoutes[0].children
	// Other Modules BaseRoutes
	.concat(errorRoutes)
	.map((route) => `/${route.path.split('/')[0]}`);

@NgModule({
	imports: [RouterModule.forRoot(sccAppRoutes, { preloadingStrategy: PreloadSelectedModulesList })],
	providers: [PreloadSelectedModulesList],
	exports: [RouterModule],
})
export class SccAppRoutingModule { }
