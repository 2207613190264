import { ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AlertsSeveritySummary, Ip, IpActiveAlertsSummaryRelationship } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import {
	buildSeveritiesMap,
	SeveritiesCountMap,
} from '../../../shared/components/severity-summary/severity-summary.component';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'ip-entity-panel',
	templateUrl: './ip.entity-panel.component.html',
})
export class IpEntityPanelComponent extends EntityPanelComponentBase<Ip> implements OnDestroy {
	get ip(): Ip {
		return this.entity;
	}

	alertsSeveritiesCountMap: SeveritiesCountMap;
	activeAlertsSummarySubscription: Subscription;
	alertsSeveritySummaryContentState: ContentState = ContentState.Loading;
	readonly dataAsset = this.i18nService.get('help_externalLoadError_data_asset');

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly i18nService: I18nService
	) {
		super(changeDetectorRef);
	}

	setEntity(entity: Ip, isExtendedData: boolean = false): void {
		super.setEntity(entity, isExtendedData);

		this.setActiveAlertsSummary();
	}

	ngOnDestroy() {
		if (this.activeAlertsSummarySubscription) {
			this.activeAlertsSummarySubscription.unsubscribe();
		}
	}

	setActiveAlertsSummary() {
		this.activeAlertsSummarySubscription = this.paris
			.getRelatedItem<Ip, AlertsSeveritySummary>(IpActiveAlertsSummaryRelationship, this.entity)
			.pipe(finalize(() => this.changeDetectorRef.markForCheck()))
			.subscribe(
				alertsSeveritySummary =>
					(this.alertsSeveritiesCountMap = buildSeveritiesMap(alertsSeveritySummary)),
				() => (this.alertsSeveritySummaryContentState = ContentState.Error),
				() =>
					(this.alertsSeveritySummaryContentState = this.alertsSeveritiesCountMap
						? ContentState.Complete
						: ContentState.Empty)
			);
	}
}
