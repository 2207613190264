import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { RelatedEvidenceCount } from '@wcd/domain';
import { wasChangedAndExists, TypedChanges } from '@wcd/angular-extensions';
import { I18nService } from '@wcd/i18n';

interface EntityCount {
	name: string;
	count: number;
}

@Component({
	selector: 'related-evidence',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entity-related-evidence.component.scss'],
	templateUrl: './entity-related-evidence.component.html',
})
export class EntityRelatedEvidenceComponent implements OnChanges {
	constructor(private i18nService: I18nService) {}

	@Input() relatedEvidenceCount: RelatedEvidenceCount;
	shownEntities: EntityCount[] = [];

	ngOnChanges(changes: TypedChanges<EntityRelatedEvidenceComponent>) {
		if (
			!wasChangedAndExists(changes.relatedEvidenceCount) ||
			!changes.relatedEvidenceCount.currentValue
		) {
			return;
		}

		const currentEvidenceCount = changes.relatedEvidenceCount.currentValue;
		this.shownEntities = Object.keys(currentEvidenceCount)
			.filter(key => currentEvidenceCount[key] > 0)
			.map(key => {
				return {
					name: this.i18nService.get(`entityCommon.panel.relatedEvidence.entities.${key}`),
					count: currentEvidenceCount[key],
				};
			})
			.sort((ent1: EntityCount, ent2: EntityCount) => ent1.name.localeCompare(ent2.name));
	}
}
