<div class="wcd-margin-large-vertical wcd-margin-large-horizontal">
	<form (submit)="onSubmit()">
		<textarea
			required
			class="alert-processTree-json--input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
			name="alert-processTree-json"
			rows="20"
			[(ngModel)]="newAlertProcessTreeJson"
			[placeholder]="newAlertProcessTreeJsonPlaceholder"
		></textarea>

		<div class="wcd-flex-horizontal-justify-items-end">
			<fab-default-button
				data-track-id="SubmitAlert"
				data-track-type="Button"
				type="submit"
				text="Submit alert"
				[disabled]="!newAlertProcessTreeJson"
			></fab-default-button>
		</div>
	</form>
</div>
