import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { CategorySecureScore } from './category-secure-score.entity';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';

export enum SupportedConfigurationScoreCategories {
	Application = 'Application',
	OS = 'OS',
	Network = 'Network',
	Accounts = 'Accounts',
	SecurityControls = 'Security controls',
}

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'Read secure score by categories',
	method: 'GET',
	endpoint: 'configurations/securescore/categories',
	cache: null,
	type: CategorySecureScore, // type is required when directly using ApiCall, in order to tell Paris how to parse the model correctly
	parseData: (categoriesScores: CategorySecureScore[]) => {
		// Enforcing the acceptance of only supported categories as in the
		// SupportedConfigurationScoreCategories enum and enforcing their order in the display
		const tmpResults = {};

		categoriesScores.forEach(c => {
			if ((<any>Object).values(SupportedConfigurationScoreCategories).includes(c.category)) {
				tmpResults[c.category] = c;
			}
			// TODO: evhvoste - raise alert for wrong category
		});
		const sorted = [];
		for (const category in SupportedConfigurationScoreCategories) {
			if (tmpResults.hasOwnProperty(SupportedConfigurationScoreCategories[category])) {
				sorted.push(tmpResults[SupportedConfigurationScoreCategories[category]]);
			}
		}
		return sorted;
	},
})
export class CategorySecureScoreApiCall extends ApiCallModel<CategorySecureScore[]> {}
