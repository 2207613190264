import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { VulnerabilityAffectedProduct } from './vulnerability-affected-product.entity';

@ValueObject({
	singularName: 'VulnerabilityInstalledAffectedProduct',
	pluralName: 'VulnerabilityInstalledAffectedProduct',
})
export class VulnerabilityInstalledAffectedProduct extends ModelBase {
	@EntityField() readonly vendor?: string;

	@EntityField() readonly productName?: string;

	@EntityField() readonly productVersion?: string;

	@EntityField() readonly productFixId?: string;

	@EntityField() readonly vulnerabilityAffectedProduct: VulnerabilityAffectedProduct;
}
