import {
	Component,
	Input,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	ViewChild,
	Output,
	EventEmitter,
} from '@angular/core';
import { ReportModel } from '../models/report.model';
import { Panel, PanelType } from '@wcd/panels';
import { isNil, clone, isEqual } from 'lodash-es';
import { FiltersField, FiltersState, SerializedFilters, FiltersService } from '@wcd/ng-filters';
import { Observable } from 'rxjs';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { Params, ActivatedRoute, Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { ICommandBarItem } from '../../shared/components/command-bar/models/command-bar-item.models';
import { ICommandBarStyles } from 'office-ui-fabric-react';
import { FabricIconNames } from '@wcd/scc-common';
import { CommandBarComponent } from '../../shared/components/command-bar/command-bar.component';
import React from 'react';

const FILTERS_PANEL_SETTINGS: Panel = new Panel({
	type: PanelType.smallFixedFar,
	headerText: 'Filters',
	showOverlay: false,
	isBlocking: false,
	isModal: true,
	isStatic: false,
	hasCloseButton: true,
	noBodyPadding: true,
});

const COMMAND_BAR_STYLES: ICommandBarStyles = {
	root: {
		backgroundColor: 'transparent',
	},
};

export enum WidgetType {
	Trend,
	Daily,
	All,
}

@Component({
	selector: 'report-with-filters',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './report-with-filters.component.html',
	styleUrls: ['./report-with-filters.component.scss'],
})
export class ReportWithFilters {
	@Input() reports: ReadonlyArray<ReportModel>;
	@Input()
	filters: {
		data$?: Observable<Record<string, any>>;
		fields: Array<FiltersField>;
	};

	@Input() reportTitle: string;
	@Input() exportEnabled: boolean = false;
	@Input() reportsClass: string = '';

	// note that this component can handle many reports, then it will happen on each report
	@Output() onReportWithFiltersRenderComplete: EventEmitter<void> = new EventEmitter<void>();

	commandBarItems: Array<ICommandBarItem> = [
		{
			name: this.i18nService.get('grid.commands.filters.text'),
			key: 'filter',
			onClick: ($event: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>) =>
				this.toggleFilters(true, <HTMLButtonElement>$event.target),
			iconProps: { iconName: FabricIconNames.Filter, styles: { root: { color: 'black' } } },
		},
		{
			name: this.i18nService.get('grid.commands.exportToExcel.text'),
			key: 'export',
			onClick: () => this.exportData(),
			iconProps: { iconName: FabricIconNames.ExcelLogo, styles: { root: { color: 'black' } } },
			hidden: !this.exportEnabled,
		},
	];

	overFlowCommandBarStyles: ICommandBarStyles = COMMAND_BAR_STYLES;

	readonly filtersPanelSettings = FILTERS_PANEL_SETTINGS;

	showFilters: boolean = false;
	isFocusOff: boolean = false;
	filtersState: FiltersState = { selection: {}, serialized: {} };

	@ViewChild('reportCommandBar', { static: false }) reportCommandBar: CommandBarComponent;
	private filterButtonElement: HTMLButtonElement;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private appInsightsService: AppInsightsService,
		private route: ActivatedRoute,
		private router: Router,
		private i18nService: I18nService
	) {
		FILTERS_PANEL_SETTINGS.headerText = this.i18nService.strings.report_filter_title;

		const queryParams: Params = clone(this.route.snapshot.queryParams);
		const serializedFilters: SerializedFilters = FiltersService.filtersQueryParamToSerializedFilters(
			queryParams.filters
		);

		if (
			(serializedFilters && !this.filtersState) ||
			(this.filtersState && !isEqual(serializedFilters, this.filtersState.serialized))
		) {
			this.filtersState = { selection: {}, serialized: serializedFilters };
		}
		this.changeDetectorRef.markForCheck();
	}

	toggleFilters(isOpen?: boolean, el?: HTMLButtonElement) {
		this.showFilters = isNil(isOpen) ? !this.showFilters : isOpen;

		if (!this.showFilters) {
			if (this.filterButtonElement instanceof HTMLButtonElement) this.filterButtonElement.focus();
		} else {
			this.filterButtonElement = el;
		}
		// trigger the window resize event to trigger the resize of the graphs
		// https://microsoft.visualstudio.com/DefaultCollection/OS/_workitems/edit/26360969
		window.dispatchEvent(new Event('resize'));
		this.changeDetectorRef.markForCheck();
	}

	onFiltersChanged(filtersState: FiltersState) {
		this.filtersState = filtersState;
		this.setUrlParams();
		this.changeDetectorRef.markForCheck();

		this.trackFilters(filtersState);
	}

	private setUrlParams() {
		const search: Params = {
			filters: FiltersService.getFiltersQueryParam(this.filtersState && this.filtersState.serialized),
		};
		const queryParams: Params = clone(this.route.snapshot.queryParams);
		Object.assign(queryParams, search);

		this.router.navigate(['.'], { relativeTo: this.route, queryParams: queryParams });
	}

	trackFilters(filtersState: FiltersState) {
		const filterTracking: Array<{
			field: string;
			values?: string | Array<string>;
			valueCount: number;
		}> = Object.keys(filtersState.serialized).map((filterParam) => {
			const serializedFilterValues = filtersState.serialized[filterParam];

			const fieldTrackingData: {
				field: string;
				values?: string | Array<string>;
				valueCount: number;
			} = {
				field: filterParam,
				valueCount: serializedFilterValues instanceof Array ? serializedFilterValues.length : 1,
			};

			const field: FiltersField = this.filters.fields.find((_field) => _field.id === filterParam);

			if (field && field.custom && field.custom.allowFilterValueTracking)
				fieldTrackingData.values = serializedFilterValues;

			return fieldTrackingData;
		});

		this.appInsightsService.track({
			id: 'FiltersChanged',
			component: this.reportTitle,
			type: TrackingEventType.Filter,
			componentType: 'Report',
			value: filterTracking,
		});
	}

	exportData() {
		// TODO: will be implemented after the BE will start working on it
	}

	isNotFocused(event: boolean) {
		this.isFocusOff = event;
	}

	// note that this component can handle many reports, then it will happen on each report
	OnEachReportRenderComplete() {
		this.onReportWithFiltersRenderComplete.emit();
	}
}

export interface CurrentTypeFilters {
	readonly widgetType: WidgetType;
	readonly chosenFilters: Record<string, ReadonlyArray<string>>;
}
