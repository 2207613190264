import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('splitCamelCase', splitCamelCaseFilter);

function splitCamelCaseFilter() {
	return function(input) {
		if (!input) {
			return '';
		}

		return input.replace(/([A-Z])/g, ' $1').substr(1);
	};
}
