/**
 * Manages SCC i18n services
 */
var SccI18nService = /** @class */ (function () {
    function SccI18nService(sccI18n) {
        this.sccI18n = sccI18n;
    }
    Object.defineProperty(SccI18nService.prototype, "language", {
        get: function () {
            return this.sccI18n && this.sccI18n.language;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccI18nService.prototype, "timezone", {
        get: function () {
            return this.sccI18n.timezone;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccI18nService.prototype, "alwaysUseUTC", {
        get: function () {
            return this.sccI18n.alwaysUseUTC;
        },
        enumerable: true,
        configurable: true
    });
    SccI18nService.prototype.format = function (date, format) {
        return this.sccI18n.format(date, format);
    };
    Object.defineProperty(SccI18nService.prototype, "timeFormat", {
        get: function () {
            return this.sccI18n.timeFormat;
        },
        enumerable: true,
        configurable: true
    });
    return SccI18nService;
}());
export { SccI18nService };
