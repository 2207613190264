import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { BaselineCompliantBarComponent, } from '../../../../../../tvm/components/baseline-compliant-bar/baseline-compliant-bar.component';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService } from '../../../../../../tvm/services/tvm-color.service';
import { BaselineDetectedValuesService } from '../../../../../../tvm/services/baseline-detected-value.service';
var BaselineConfigurationFieldsService = /** @class */ (function () {
    function BaselineConfigurationFieldsService(i18nService, tvmColorService, baselineDetectedValuesService) {
        var _this = this;
        this.i18nService = i18nService;
        this.tvmColorService = tvmColorService;
        this.baselineDetectedValuesService = baselineDetectedValuesService;
        this.getApplicableAssetsFields = function (configuration) { return DataviewField.fromList([
            {
                id: 'name',
                name: _this.i18nService.strings.tvm_common_name,
                icon: {
                    fabricIcon: FabricIconNames.System,
                },
                getTooltip: function (device) { return device.name; },
                getLink: function (device) { return "/machines/" + encodeURIComponent(device.id) + "/baseline-compliance"; },
                sort: { enabled: false },
                className: 'nowrap wcd-text-overflow-medium',
            },
            {
                id: 'deviceCompliant',
                name: _this.i18nService.strings.tvm_baseline_configuration_compliant_column_name,
                getDisplay: function (device) {
                    return device.compliantConfigurations === 1 || device.isCompliant ?
                        _this.i18nService.strings.common_yes :
                        _this.i18nService.strings.common_no;
                },
                filter: { priority: 1 },
            },
            _this.baselineDetectedValuesService.isConfigurationSupported(configuration) &&
                {
                    id: 'detectValue',
                    name: _this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_sidePanel_currentState,
                    getDisplay: function (device) { return _this.baselineDetectedValuesService.getConfigurationDetectedValuesString(device); },
                    sort: { enabled: false },
                },
        ].filter(Boolean)); };
    }
    Object.defineProperty(BaselineConfigurationFieldsService.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                this._fields = this.getFields(false);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(BaselineConfigurationFieldsService.prototype, "singleAssetFields", {
        get: function () {
            if (!this._singleAssetFields) {
                this._singleAssetFields = this.getFields(true);
            }
            return this._singleAssetFields;
        },
        enumerable: true,
        configurable: true
    });
    BaselineConfigurationFieldsService.prototype.getFields = function (singleAsset) {
        var _this = this;
        return DataviewField.fromList([
            {
                id: 'configurationId',
                name: this.i18nService.strings.tvm_baseline_profiles_configurations_ID_title,
                getDisplay: function (configuration) { return configuration.id; },
                sort: { enabled: false },
            },
            {
                id: 'name',
                name: this.i18nService.strings.tvm_common_name,
                getTooltip: function (configuration) { return configuration.name; },
                maxWidth: 500,
                help: function (configuration) { return _this.isCustomValues(configuration) ? _this.i18nService.strings.tvm_baseline_compliant_enum_customized_values : null; },
            },
            {
                id: 'category',
                name: this.i18nService.strings.tvm_baseline_profiles_configurations_category_title,
                getTooltip: function (configuration) { return configuration.category; },
                filter: { priority: singleAsset ? 2 : 1 },
                maxWidth: 250,
            },
            singleAsset ?
                {
                    id: 'compliantDevices',
                    name: this.i18nService.strings
                        .tvm_baseline_profiles_configurations_complianceStatus_title,
                    icon: {
                        fabricIcon: FabricIconNames.StatusCircleInner,
                        className: function (configuration) {
                            return _this.tvmColorService.getCompliantIcon(configuration.compliantDevices === 1);
                        },
                    },
                    getDisplay: function (configuration) {
                        return _this.isInProgress(configuration) ? _this.i18nService.strings.tvm_baseline_compliant_enum_in_progress :
                            configuration.compliantDevices === 1
                                ? _this.i18nService.strings.tvm_baseline_compliant_enum_compliant
                                : _this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant;
                    },
                    maxWidth: 150,
                    allowResize: false,
                    filter: { priority: 1 },
                    help: function (configuration) { return _this.isInProgress(configuration) ? _this.i18nService.strings.tvm_baseline_compliant_enum_in_progress_tooltip : null; }
                }
                : {
                    id: 'compliantDevices',
                    name: this.i18nService.strings
                        .tvm_baseline_profiles_configurations_compliantDevices_title,
                    getDynamicComponent: function (configuration) {
                        if (_this.isInProgress(configuration)) {
                            return null;
                        }
                        return {
                            type: BaselineCompliantBarComponent,
                            getProps: function (configuration) { return ({
                                showLegend: false,
                                compliantCount: configuration.compliantDevices,
                                notCompliantCount: configuration.applicableDevices - configuration.compliantDevices,
                                total: configuration.applicableDevices,
                                showPercentage: false,
                                showNumbers: true,
                            }); },
                        };
                    },
                    getDisplay: function (configuration) { return _this.isInProgress(configuration) ? _this.i18nService.strings.tvm_baseline_compliant_enum_in_progress : null; },
                    help: function (configuration) { return _this.isInProgress(configuration) ? _this.i18nService.strings.tvm_baseline_compliant_enum_in_progress_tooltip : null; },
                    maxWidth: 250,
                    allowResize: true
                },
            {
                id: 'complianceLevels',
                name: this.i18nService.strings.baseline_profile_wizard_profile_compliance_level_label,
                getDisplay: function (configuration) {
                    var levelsLength = configuration.complianceLevels && configuration.complianceLevels.length;
                    var firstLevel = levelsLength ? configuration.complianceLevels[0] : '-';
                    return levelsLength > 1 ? firstLevel + " (+" + (levelsLength - 1) + " more)" : firstLevel;
                },
                getTooltip: function (configuration) {
                    return configuration.complianceLevels
                        ? "<div>\n\t\t\t\t\t\t\t" + configuration.complianceLevels.join('<br>') + "\n\t\t\t\t\t\t</div>"
                        : '';
                },
                valueTooltipAllowHtmlRendering: true,
                sort: { enabled: false },
                minWidth: 150,
                filter: { priority: singleAsset ? 3 : 2 },
            },
        ]);
    };
    BaselineConfigurationFieldsService.prototype.isInProgress = function (configuration) {
        return !configuration || configuration.compliantDevices === -1;
    };
    // TODO: lischach to update logic once other value is present
    BaselineConfigurationFieldsService.prototype.isCustomValues = function (configuration) {
        return configuration && configuration.id && configuration.id.includes(":");
    };
    return BaselineConfigurationFieldsService;
}());
export { BaselineConfigurationFieldsService };
