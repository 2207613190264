var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { I18nService } from './../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { TvmTextsService, TextToken } from './../../../tvm/services/tvm-texts.service';
import { ChangeEventService } from './../../../@entities/@tvm/events/services/change-event.service';
import { Injector } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ChangeEventRelatedVulnerabilitiesRelationship, TvmEndPoint, ChangeEvent, ChangeEventCategory, ChangeEventExposedOsRelationship, ChangeEventRecommendationRelationship, } from '@wcd/domain';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris } from '@microsoft/paris';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { Router } from '@angular/router';
import { VulnerabilityFieldsService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { ChangeEventTextService } from '../../../tvm/services/tvm-change-events-texts.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ExposedOperatingSystemsComponent } from '../../../tvm/components/tooltips/exposed-operating-systems/exposed-operating-systems.component';
//TODO: - Task 27819447: Break change event entity details sections into VA sections and SCA sections lists
var ChangeEventEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(ChangeEventEntityDetailsComponent, _super);
    function ChangeEventEntityDetailsComponent(paris, router, injector, sidePanelService, tvmDownloadService, changeEventService, changeEventTextService, tvmTextsService, i18nService, vulnerabilityFieldsService) {
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.router = router;
        _this.sidePanelService = sidePanelService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.changeEventService = changeEventService;
        _this.changeEventTextService = changeEventTextService;
        _this.tvmTextsService = tvmTextsService;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this.ExposedOperatingSystemsComponent = ExposedOperatingSystemsComponent;
        _this.vulnerabilitiesRelationshipRepository = _this.paris.getRelationshipRepository(ChangeEventRelatedVulnerabilitiesRelationship);
        _this.exposedOsRelationshipRepository = _this.paris.getRelationshipRepository(ChangeEventExposedOsRelationship);
        _this.recommendationRelationshipRepository = _this.paris.getRelationshipRepository(ChangeEventRecommendationRelationship);
        _this.fields = vulnerabilityFieldsService.changeEventFieldsConfig;
        _this.scoreImpactTooltip = _this.i18nService.get('tvm_changeEvent_configuration_score_impact_tooltip');
        return _this;
    }
    Object.defineProperty(ChangeEventEntityDetailsComponent.prototype, "changeEvent", {
        get: function () {
            return this._changeEvent;
        },
        set: function (changeEvent) {
            this._changeEvent = changeEvent;
            this.vulnerabilitiesRelationshipRepository.sourceItem = changeEvent;
            //TODO: update this when adding more categories
            this.isVaEvent = changeEvent.category === ChangeEventCategory.Vulnerability;
            this._relatedProductId = this.changeEventService.getRelatedProductId(changeEvent);
            this.relatedComponent = this.changeEventTextService.getRelatedComponent(changeEvent);
            this.scaConfigurationCategory = changeEvent.configurationCategory; // for different mapping from SCA BE category to display e.g. OS -> Operating system
            this.iconName = this.changeEventService.getScoreTrendIconName(changeEvent);
            this.iconClass = this.changeEventService.getScoreTrendIconClass(changeEvent);
            this.scoreText = this.changeEventService.getScoreText(changeEvent);
            this.assetsCount =
                this.changeEventService.getAssetsCountString(changeEvent) ||
                    this.i18nService.strings.notAvailable_long;
            this.currentAssetsCount = this.changeEventService.getCurrentAssetsCountString(changeEvent);
            this.scoreTooltip = this.changeEventService.getToolTip(changeEvent);
            var headerKey = 'tvm_common_relatedComponent';
            if (!this.isVaEvent) {
                this.exposedOsRelationshipRepository.sourceItem = changeEvent;
                this.exposedOperatingSystems$ = this.exposedOsRelationshipRepository.query();
                this.recommendationRelationshipRepository.sourceItem = changeEvent;
                this.securityRecommendation$ = this.recommendationRelationshipRepository.queryItem();
                headerKey = 'tvm_securityRecommendation_configurationChange_category';
                var achievablePoints = changeEvent.achievablePoints;
                var reductionPoints = changeEvent.reductionPoints;
                this.showScaEventScoring = reductionPoints >= 0 && achievablePoints > 0; // achievable points must be positive (comes from scid severity)
                if (this.showScaEventScoring) {
                    this.scaEventAchievedScoreText =
                        reductionPoints === achievablePoints
                            ? this.i18nService.strings.tvm_changeEvent_configuration_no_score_achieved
                            : this.i18nService.get('tvm_changeEvent_configuration_score_achieved', {
                                points: (achievablePoints - reductionPoints).toFixed(2),
                                percentage: Math.round(100 - changeEvent.currentAffectedAssetsPercent),
                            });
                }
            }
            this.relatedComponentHeader = this.i18nService.get(headerKey);
        },
        enumerable: true,
        configurable: true
    });
    ChangeEventEntityDetailsComponent.prototype.openAllRelatedCves = function () {
        this.sidePanelService.showAllRelatedCvesPanel(this.changeEvent);
    };
    ChangeEventEntityDetailsComponent.prototype.exportCves = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.vulnerabilitiesRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.ChangeEventActivity, this.changeEvent) + " - " + this.i18nService.get('tvm.common.relatedCVE.title'));
    };
    // TODO: evhvoste: refactor the exposed operating system to a component (for this and recommendation entity details component)
    // Task 27307409: TVM Portal - Refactor exposed os into a component
    ChangeEventEntityDetailsComponent.prototype.generateExposedOperatingSystems = function (operatingSystems) {
        var res = '';
        if (operatingSystems.length > 0) {
            res += this.tvmTextsService.getOsPlatformLabel(operatingSystems[0].osName);
        }
        if (operatingSystems.length > 1) {
            res += ", " + this.tvmTextsService.getOsPlatformLabel(operatingSystems[1].osName);
        }
        if (operatingSystems.length > 2) {
            res += " (+" + (operatingSystems.length - 2) + " more)";
        }
        return res;
    };
    ChangeEventEntityDetailsComponent.prototype.navigateToRelatedComponent = function () {
        if (this.isVaEvent) {
            this.router.navigate(['/software-inventory', this._relatedProductId]);
        }
        else {
            this.router.navigate(['/security-recommendations'], {
                queryParams: {
                    filters: "relatedComponent=recommendationCategory:eq:'" + this.scaConfigurationCategory + "'",
                },
            });
        }
    };
    return ChangeEventEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { ChangeEventEntityDetailsComponent };
