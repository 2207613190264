import { Entity, ModelBase, EntityField, DataQuery } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, ParseDataQuery } from '../analyticsEndPointUtils';
import { ExposureScoreCategory } from '../common/exposure-score/exposure-score-category.enum';
import { ThreatInfo } from '../common/threat-info.value-object';
import { SoftwareInstallationBase } from './software-installation-base.entity';
import { EolState } from '../software-version/eol-version-state.enum';

// TODO: there is a bug in paris - use of idProperty: 'assetId' has no affect and the id is null
@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	//enriching the query with the Asset's exposure score:
	parseDataQuery: (dataQuery: DataQuery) => ({
		...ParseDataQuery(dataQuery),
		$expand: 'Asset($select=ExposureScore)',
	}),
	singularName: 'installation',
	pluralName: 'installations',
	endpoint: 'installations',
	readonly: true,
})
export class SoftwareInstallation extends SoftwareInstallationBase {
	@EntityField({
		required: true,
		parse: (_, item) => `${item.assetId}-${item.productName}-${item.vendor}-${item.installedVersion}`,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({
		required: true,
	})
	readonly installedVersion: string;

	@EntityField()
	readonly eolVersionState: EolState;

	@EntityField()
	readonly eolVersionSinceDate: Date;
}
