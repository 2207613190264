/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./investigation-comments.component.ngfactory";
import * as i5 from "../../../shared/interfaces/disableable.interface";
import * as i6 from "./investigation-comments.component";
import * as i7 from "../../../dialogs/services/dialogs.service";
import * as i8 from "../../investigation_actions/services/investigation-actions.service";
import * as i9 from "../../../comments/services/comments.store";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./investigation-comments.panel.component";
import * as i12 from "@angular/router";
var styles_InvestigationCommentsPanelComponent = [];
var RenderType_InvestigationCommentsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvestigationCommentsPanelComponent, data: {} });
export { RenderType_InvestigationCommentsPanelComponent as RenderType_InvestigationCommentsPanelComponent };
export function View_InvestigationCommentsPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "wcd-panel", [], [[1, "id", 0]], [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 2, "investigation-comments", [], null, null, null, i4.View_InvestigationCommentsComponent_0, i4.RenderType_InvestigationCommentsComponent)), i0.ɵprd(6144, null, i5.DISABLEABLE_TOKEN, null, [i6.InvestigationCommentsComponent]), i0.ɵdid(7, 114688, null, 0, i6.InvestigationCommentsComponent, [i0.ChangeDetectorRef, i7.DialogsService, i8.InvestigationActionsService, i9.CommentsStore, i10.I18nService], { investigationGraphData: [0, "investigationGraphData"], investigation: [1, "investigation"], panelId: [2, "panelId"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.settings; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.investigationGraphData; var currVal_3 = _co.investigation; var currVal_4 = _co.panelId; _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.panelId; _ck(_v, 1, 0, currVal_0); }); }
export function View_InvestigationCommentsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "investigation-comments-edit-panel", [], null, null, null, View_InvestigationCommentsPanelComponent_0, RenderType_InvestigationCommentsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i11.InvestigationCommentsPanelComponent, [i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InvestigationCommentsPanelComponentNgFactory = i0.ɵccf("investigation-comments-edit-panel", i11.InvestigationCommentsPanelComponent, View_InvestigationCommentsPanelComponent_Host_0, { settings: "settings", investigationGraphData: "investigationGraphData", investigation: "investigation" }, {}, []);
export { InvestigationCommentsPanelComponentNgFactory as InvestigationCommentsPanelComponentNgFactory };
