import { isNil } from 'lodash-es';

const NUMBER_MULTIPLIES = [
	{ value: Math.pow(10, 9), suffix: 'B', maximumFractionDigits: 0 },
	{ value: Math.pow(10, 6), suffix: 'M', maximumFractionDigits: 0 },
	{ value: Math.pow(10, 3), suffix: 'k', maximumFractionDigits: 1 },
];

const DEFAULT_LOCALE: string = 'en-US';

/**
 * Given a number (float or int), returns a string of short representation of the number.
 * e.g. 1000 -> 1k, 5234 -> 5.23k, 4351111 -> 4.35M, etc, according to numberMultiplies.
 * @param number The number to prettify
 * @param locale The locale to pass to Intl.NumberFormat. Defaults to 'en-US'.
 */
export function prettyNumber(number: number, locale: string = DEFAULT_LOCALE): string {
	if (isNil(number)) return '';

	let floatNumber,
		numberStr: string = String(number);

	if (isNaN(number)) return numberStr;

	if (number < 1) return (Math.round(number * 100) / 100).toString();

	if (number < 100) return (Math.round(number * 10) / 10).toString();

	let prettyNumber: number,
		maximumFractionDigits = 1;

	for (let i = 0, multiplier; (multiplier = NUMBER_MULTIPLIES[i]); i++) {
		if (number >= multiplier.value) {
			prettyNumber = number / multiplier.value;
			maximumFractionDigits = getFractionDigits(prettyNumber, multiplier.maximumFractionDigits);

			floatNumber = prettyNumber
				.toFixed(maximumFractionDigits)
				.toString()
				.replace(/(^.*\..*?)0+$/, '$1')
				.replace(/\.$/, '');
			numberStr = floatNumber + multiplier.suffix;
			break;
		}
	}

	const originalNumberLength = number.toString().length;

	if (originalNumberLength < 4 && numberStr.length >= originalNumberLength)
		return new Intl.NumberFormat(locale, {
			maximumFractionDigits: maximumFractionDigits,
		}).format(number);

	return numberStr;
}

// 2 fraction digits for showing 1.23k or 3.45M
// 0 fraction digits for showing 123k or 345M
// defined number of fraction digits (specifically to avoid too big number rounding for thousands e.g. showing 12k for when 11,510 and 12,490)
function getFractionDigits(prettyNumber: number, definedFractionDigits: number): number {
	return prettyNumber < 10 ? 2 : prettyNumber >= 100 ? 0 : definedFractionDigits;
}
