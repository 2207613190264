var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { HuntingRule } from '../hunting/hunting-rule.entity';
import { Alert } from './alert.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.k8s; }, ɵ1 = function (query) { return ({ alertId: query.id }); }, ɵ2 = function (config, query) {
    return "ine/huntingservice/alerts/" + query.where.alertId + "/rule";
};
var AlertToHuntingRuleRelationship = /** @class */ (function () {
    function AlertToHuntingRuleRelationship() {
    }
    AlertToHuntingRuleRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Alert,
            dataEntity: HuntingRule,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ0,
            getRelationshipData: ɵ1,
            endpoint: ɵ2,
        })
    ], AlertToHuntingRuleRelationship);
    return AlertToHuntingRuleRelationship;
}());
export { AlertToHuntingRuleRelationship };
export { ɵ0, ɵ1, ɵ2 };
