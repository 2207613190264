import { keyBy } from 'lodash-es';
import { MachineExclusionState } from './machine-exclusion-state.enum';

export const exclusionStates = [
	{
		id: MachineExclusionState.Included,
		i18nNameKey: 'machines_exclusionState_Included',
		priority: 1,
	},
	{
		id: MachineExclusionState.Excluded,
		i18nNameKey: 'machines_exclusionState_Excluded',
		priority: 2,
	},
];

export const machineExclusionStateMap = keyBy(exclusionStates, o => o.id);
