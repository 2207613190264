/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "./baseline-profile-completed.component";
import * as i5 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_BaselineProfileCompletedComponent = [];
var RenderType_BaselineProfileCompletedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaselineProfileCompletedComponent, data: {} });
export { RenderType_BaselineProfileCompletedComponent as RenderType_BaselineProfileCompletedComponent };
export function View_BaselineProfileCompletedComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "h2", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "wcd-shared-icon", [["class", "large-icon ms-color-greenLight wcd-vertical-align-bottom"], ["iconName", "SkypeCircleCheck"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(4, null, ["\n\t", "\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "SkypeCircleCheck"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.title; _ck(_v, 4, 0, currVal_1); }); }
export function View_BaselineProfileCompletedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BaselineProfileCompletedComponent_0, RenderType_BaselineProfileCompletedComponent)), i0.ɵdid(1, 114688, null, 0, i4.BaselineProfileCompletedComponent, [i5.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaselineProfileCompletedComponentNgFactory = i0.ɵccf("ng-component", i4.BaselineProfileCompletedComponent, View_BaselineProfileCompletedComponent_Host_0, { data: "data" }, {}, []);
export { BaselineProfileCompletedComponentNgFactory as BaselineProfileCompletedComponentNgFactory };
