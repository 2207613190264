import { ReportModel } from '../../../../../reports/models/report.model';
import { AssetsStatisticsExpandedWidget } from './widgets/assets-statistics-expanded/assets-statistics-expanded.widget';
import { WeaknessesDistributionWidget } from './widgets/weaknesses-distribution/weaknesses-distribution.widget';

export function SoftwareEntityReport() {
	return new ReportModel({
		id: 'softwareEntityReport',
		allowExport: false,
		allowRangeSelect: false,
		enablePadding: false,
		overflow: 'none',
		refreshOnQueryParamsChange: false,
		refreshRate: 86400000, //24 hours
		rows: [
			{
				columns: [
					{
						widgets: [WeaknessesDistributionWidget],
					},
					{
						widgets: [AssetsStatisticsExpandedWidget],
					},
				],
			},
		],
	});
}
