/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	OnDestroy,
	Injector,
	ChangeDetectorRef,
} from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModelBase, DataSet, DataQuery, Paris, RelationshipRepository } from '@microsoft/paris';
import { EntityDetailsComponentBase } from '../entity-details.component.base';
import { ActivatedEntity } from '../../../services/activated-entity.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmContextOptions } from '../../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { MissingKbFieldsService } from '../../../../@entities/@tvm/software-inventory/services/missing-kb.fields.service';
import {
	MissingKb,
	Asset,
	Vulnerability,
	MissingKbExposedAssetsRelationship,
	MissingKbRelatedVulnerabilitiesRelationship,
	AssetMissingKb,
	TvmEndPoint,
	AssetMissingKbVulnerabilityRelationship,
	EolState,
	LegalNoteLinks,
	ShouldShowLegalNoteFor,
} from '@wcd/domain';
import { SidePanelService } from '../../../../@entities/@tvm/common/side-panel.service';
import { MissingKbDetailsOptions } from '../../../../@entities/@tvm/software-inventory/components/software-entity/missing-kb.entity-panel.component';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { I18nService } from '@wcd/i18n';
import { MessageBarType, IMessageBarStyleProps } from 'office-ui-fabric-react';
import { TextToken, TvmTextsService } from '../../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { MessageBarStyles } from '../../../../@entities/@tvm/common/styles';
import { Router } from '@angular/router';
import { MaxItemsInCollapseExpandTable } from '../../../../tvm/tvm.constants';
import { TzDateService } from '@wcd/localization';

@Component({
	selector: 'missing-kb-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './missing-kb.entity-details.component.html',
})
export class MissingKbEntityDetailsComponent extends EntityDetailsComponentBase<MissingKb>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	private _missingKb: MissingKb;

	isExposedOperatingSystem: boolean;

	SpinnerSize = SpinnerSize;
	readonly MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;

	isAssetsMinimized = true;
	missingKbExposedAssetsRepository: RelationshipRepository<MissingKb, Asset>;
	assets$: Observable<DataSet<Asset>>;
	isCvesMinimized = true;
	missingKbRelatedCvesRepository: RelationshipRepository<MissingKb, Vulnerability>;
	vulnerabilities$: Observable<DataSet<Vulnerability>>;
	missingKbFieldsService: MissingKbFieldsService;
	assetVulnerabilitiesRepository: RelationshipRepository<AssetMissingKb, Vulnerability>;
	private messageBarType: MessageBarType;
	readonly MessageBarType = MessageBarType;
	shouldShowLegalNote: boolean;
	legalNoteLinkText: string;
	legalNoteLink: string;
	showLegalNoteLink: boolean;
	messageBarState = {
		showBody: false,
	};
	_messageBarStyles = MessageBarStyles;
	showEolMessage: boolean;
	eolText: string;
	legalNote: string;
	softwareVersionsLinkText: string;
	missingKbNameText: string;

	readonly LegalNoticeMessageBarType = MessageBarType;
	legalNoticeMessageBarState = {
		showBody: false,
	};
	legalNoticeText: string;
	isRedHatProductFix: boolean;

	@Input() machineDetails: MissingKbDetailsOptions;
	@Input() contextOptions: TvmContextOptions;
	@Input()
	set missingKb(missingKb: MissingKb) {
		this._missingKb = missingKb;
		this.isRedHatProductFix = missingKb.productFixId.startsWith('RHSA');
		this.legalNoticeText = this.tvmTextsService.getText(TextToken.RedHatLegalNotice);
		if (this.machineDetails.singleAssetMissingKb) {
			this.assetVulnerabilitiesRepository.sourceItem = new AssetMissingKb({
				...this._missingKb,
				assetId: this.machineDetails.machineId,
			});
		} else {
			this.loadNewAssets(missingKb);
			this.missingKbRelatedCvesRepository.sourceItem = missingKb;
		}
	}
	get missingKb(): MissingKb {
		return this._missingKb;
	}

	constructor(
		private activatedEntity: ActivatedEntity,
		private sidePanelService: SidePanelService,
		private paris: Paris,
		public i18nService: I18nService,
		private tvmTextsService: TvmTextsService,
		injector: Injector,
		private tvmDownloadService: TvmDownloadService,
		private changeDetectorRef: ChangeDetectorRef,
		featuresService: FeaturesService,
		private tzDateService: TzDateService,
		private router: Router
	) {
		super(injector);

		this.missingKbExposedAssetsRepository = this.paris.getRelationshipRepository(
			MissingKbExposedAssetsRelationship
		);

		this.missingKbRelatedCvesRepository = this.paris.getRelationshipRepository(
			MissingKbRelatedVulnerabilitiesRelationship
		);

		this.assetVulnerabilitiesRepository = this.paris.getRelationshipRepository(
			AssetMissingKbVulnerabilityRelationship
		);

		this.isExposedOperatingSystem = featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
		this.missingKbNameText = this.i18nService.strings.tvm_missingKb_id;
	}
	ngOnInit() {
		if (!this.missingKb) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Vulnerability))
				.subscribe((missingKb: MissingKb) => {
					this.missingKb = missingKb;
					this.initMissingKbFields();
				});
		} else {
			this.initMissingKbFields();
		}
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	initMissingKbFields() {
		this.showEolMessage = this.shouldShowEolMessage();
		this.eolText = this.getEolText();
		this.messageBarType =
			this.missingKb.eolVersionState === EolState.AlreadyEOL
				? MessageBarType.warning
				: MessageBarType.info;
		this.legalNote = this.tvmTextsService.getText(
			TextToken.HasEolVersionsMicrosoftLegalNote,
			this.missingKb.productId
		);
		this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.missingKb.productId);
		this.legalNoteLink = LegalNoteLinks[this.missingKb.productId];
		this.legalNoteLinkText = this.tvmTextsService.getText(
			TextToken.LegalNoteLinkText,
			this.missingKb.productId
		);
		this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.missingKb.productId);
		this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
	}

	loadNewAssets(missingKb: MissingKb) {
		const queryOptions: DataQuery = {
			page: 1,
			pageSize: MaxItemsInCollapseExpandTable,
		};
		this.missingKbExposedAssetsRepository.sourceItem = missingKb;
		this.assets$ = this.missingKbExposedAssetsRepository.query(queryOptions);
	}

	openAllExposedMachines() {
		this.sidePanelService.showAllExposedAssetsPanel(this._missingKb);
	}

	openAllRelatedCves(includeExposedMachinesComponent: boolean) {
		this.sidePanelService.showAllRelatedCvesPanel(
			this.machineDetails.singleAssetMissingKb
				? this.assetVulnerabilitiesRepository.sourceItem
				: this._missingKb,
			includeExposedMachinesComponent
		);
	}

	exportExposedMachines() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.missingKbExposedAssetsRepository,
			TvmEndPoint.Analytics,
			`${this.missingKb.vendorBulletin} (${this.missingKb.productFixId}) - ${this.i18nService.get(
				'tvm.common.exposedDevices'
			)}`,
			undefined
		);
	}

	exportCves() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.machineDetails.singleAssetMissingKb
				? this.assetVulnerabilitiesRepository
				: this.missingKbRelatedCvesRepository,
			TvmEndPoint.Analytics,
			`${this.missingKb.vendorBulletin} (${this.missingKb.productFixId}) - ${this.i18nService.get(
				'tvm.common.relatedCVE.title'
			)}`,
			undefined
		);
	}

	getLegalNoticeStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.legalNoticeMessageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.legalNoticeMessageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
		return this._messageBarStyles;
	}

	getStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.messageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
		return this._messageBarStyles;
	}

	shouldShowEolMessage() {
		return (
			this.missingKb.eolVersionState === EolState.UpcomingEOL ||
			this.missingKb.eolVersionState === EolState.AlreadyEOL
		);
	}

	getEolText() {
		if (this.missingKb.eolVersionState === EolState.AlreadyEOL) {
			if (this.missingKb.eolVersionSinceDate) {
				return this.i18nService.get('tvm.softwarePage.missingKb.eolTextWithDate', {
					date: this.tzDateService.format(this.missingKb.eolVersionSinceDate, 'shortDate'),
				});
			} else {
				return this.i18nService.get('tvm.softwarePage.missingKb.eolTextWithoutDate');
			}
		}
		if (this.missingKb.eolVersionState === EolState.UpcomingEOL) {
			return this.i18nService.get('tvm.softwarePage.missingKb.upcomingEolText', {
				date: this.tzDateService.format(this.missingKb.eolVersionSinceDate, 'shortDate'),
			});
		}
	}

	openSoftwareVersions() {
		const alreadyEOL = this.missingKb.eolVersionState === EolState.AlreadyEOL ? 'AlreadyEOL' : '';
		const upcomingEOL = this.missingKb.eolVersionState === EolState.UpcomingEOL ? 'UpcomingEOL' : '';
		this.router.navigate(
			[`/software-inventory/${encodeURIComponent(this.missingKb.productId)}/versions`],
			{
				queryParams: {
					filters: `versionDistributionTags=${[alreadyEOL, upcomingEOL]
						.filter(s => s.length > 0)
						.join('|')}`,
				},
			}
		);
		this.changeDetectorRef.detectChanges();
	}

	getSoftwareVersionsLinkText() {
		return this.tvmTextsService.getText(
			TextToken.SoftwareVersionsLinkText,
			this.missingKb.productsNames[0]
		);
	}
}
