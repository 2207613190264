import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'User Activity',
	pluralName: 'User Activities',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsUserActivity,
})
export class AirsUserActivity extends AirsEntity {
	@EntityField({ data: 'user_object_id' })
	userObjectId: string;

	@EntityField({ data: 'upn' })
	upn: string;

	@EntityField({ data: 'full_name' })
	fullName: string;

	@EntityField({ data: 'sid' })
	sid: string;

	constructor(data) {
		super(data);
		this.entityName = this.upn;
	}
}
