/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./machine-groups-filter-control.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../projects/angular-extensions/src/lib/directives/focusOn.directive";
import * as i3 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i4 from "@angular-react/fabric";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./machine-groups-filter-control.component";
import * as i9 from "../../../services/tvm-machine-groups-filter.service";
import * as i10 from "../../../../../../../../projects/config/src/lib/services/features.service";
var styles_TvmMachineGroupsFilterControlComponent = [i0.styles];
var RenderType_TvmMachineGroupsFilterControlComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TvmMachineGroupsFilterControlComponent, data: {} });
export { RenderType_TvmMachineGroupsFilterControlComponent as RenderType_TvmMachineGroupsFilterControlComponent };
function View_TvmMachineGroupsFilterControlComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "button", [["class", "btn btn-link wcd-font-weight-semibold allow-focus-outline"], ["tabindex", "-1"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMachineGroupsFilterPanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 4734976, null, 0, i2.FocusOnDirective, [i1.ElementRef], { focusOn: [0, "focusOn"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "fab-icon", [["iconName", "Devices2"]], null, null, null, i3.View_FabIconComponent_0, i3.RenderType_FabIconComponent)), i1.ɵdid(4, 5816320, null, 0, i4.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["(", ""])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "ofCount"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["/", ""])), (_l()(), i1.ɵted(-1, null, [")"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.focusOnMachineGroup; _ck(_v, 1, 0, currVal_1); var currVal_2 = "Devices2"; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.aria_label; _ck(_v, 0, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v.parent.parent, 0), "tvm.machineGroupsFilter.control.header")); _ck(_v, 7, 0, currVal_3); var currVal_4 = _co._selectedCount; _ck(_v, 11, 0, currVal_4); var currVal_5 = _co._totalCount; _ck(_v, 13, 0, currVal_5); }); }
function View_TvmMachineGroupsFilterControlComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmMachineGroupsFilterControlComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._totalCount > 1); _ck(_v, 3, 0, currVal_0); }, null); }
function View_TvmMachineGroupsFilterControlComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "fab-spinner", [["labelPosition", "right"]], null, null, null, i3.View_FabSpinnerComponent_0, i3.RenderType_FabSpinnerComponent)), i1.ɵdid(2, 5816320, null, 0, i4.FabSpinnerComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { size: [0, "size"], label: [1, "label"], labelPosition: [2, "labelPosition"] }, null), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.small; var currVal_1 = i1.ɵinlineInterpolate(1, "", i1.ɵunv(_v, 2, 1, _ck(_v, 3, 0, i1.ɵnov(_v.parent, 0), "tvm.machineGroupsFilter.control.loading")), ""); var currVal_2 = "right"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_TvmMachineGroupsFilterControlComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TvmMachineGroupsFilterControlComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_TvmMachineGroupsFilterControlComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co._totalCount !== undefined); var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_TvmMachineGroupsFilterControlComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tvm-machine-groups-filter-control", [], null, null, null, View_TvmMachineGroupsFilterControlComponent_0, RenderType_TvmMachineGroupsFilterControlComponent)), i1.ɵdid(1, 180224, null, 0, i8.TvmMachineGroupsFilterControlComponent, [i9.TvmMachineGroupsFilterService, i10.FeaturesService, i1.ChangeDetectorRef, i7.I18nService], null, null)], null, null); }
var TvmMachineGroupsFilterControlComponentNgFactory = i1.ɵccf("tvm-machine-groups-filter-control", i8.TvmMachineGroupsFilterControlComponent, View_TvmMachineGroupsFilterControlComponent_Host_0, { focusOnMachineGroup: "focusOnMachineGroup" }, {}, []);
export { TvmMachineGroupsFilterControlComponentNgFactory as TvmMachineGroupsFilterControlComponentNgFactory };
