import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { Script } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { ScriptPanelComponent } from '../components/script.entity-panel.component';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class ScriptEntityTypeService implements EntityTypeService<Script> {
	constructor(private readonly i18nService: I18nService) {}

	readonly entityType: Readonly<EntityType<Script>> = {
		id: 'amsi-script',
		entity: Script,
		loadFullEntityInPanel: true,
		singleEntityPanelComponentType: ScriptPanelComponent,
		getEntityName: () => this.i18nService.get('amsiScripts.entityDetails.title'),
		getItemParams: (entity, options) =>
			options && (<any>options).actionTime
				? { actionTime: (<any>options).actionTime.toISOString() }
				: null,
	};
}
