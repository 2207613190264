export const RULE_STATUS_ID_DOES_NOT_APPLY = 100;
export const RULE_STATUS_TYPE_DOES_NOT_APPLY = 'doesNotApply';

export const scheduledHuntingRunStatus = [
	{
		id: 0,
		type: 'running',
		name: 'Running',
		nameI18nKey: 'statuses_running',
		className: 'primary',
		image: '/assets/images/circle-loading.gif',
	},
	{
		id: 1,
		type: 'completed',
		name: 'Completed',
		nameI18nKey: 'statuses_completed',
		iconName: 'CompletedSolid',
		className: 'success',
	},
	{
		id: 2,
		type: 'failure',
		name: 'Failed',
		nameI18nKey: 'statuses_failed',
		iconName: 'Error',
		className: 'error',
		isError: true,
	},
	{
		id: 3,
		type: 'partial',
		name: 'Partial',
		nameI18nKey: 'statuses_partial',
		iconName: 'Error',
		className: 'error',
		isError: true,
	},
	{
		id: RULE_STATUS_ID_DOES_NOT_APPLY,
		type: RULE_STATUS_TYPE_DOES_NOT_APPLY,
		name: "Doesn't apply",
		nameI18nKey: 'statuses_doesNotApply',
		isError: false,
	},
];
