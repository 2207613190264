<h2 class="wcd-font-weight-bold">{{ i18n.strings.baseline_profile_wizard_settings_step_title }}</h2>
<div class="wcd-margin-large-top">
    <div class="wcd-margin-mediumSmall-top required wcd-font-weight-semibold">
        {{ i18n.strings.baseline_profile_wizard_profile_software_label }}
    </div>
    <fancy-select
        [ariaLabel]="'Status'"
        [values]="operatingSystemsActions"
        [(ngModel)]="selectedOperatingSystemAction"
        [isBordered]="true"
        labelClass="dropdown-width-medium"
		[placeholder]="i18n.strings.baseline_profile_wizard_profile_software_label_placeHolder"
        name="operating-system-action"
        label="name"
        (ngModelChange)="onOperatingSystemsDropdownChanged($event)"
		required>
    </fancy-select>
    <div class="wcd-margin-mediumSmall-top required wcd-font-weight-semibold">
        {{ i18n.strings.baseline_profile_wizard_profile_benchmark_label }}
    </div>
    <fancy-select
        [ariaLabel]="'Status'"
        [values]="benchmarksActions"
        [isDisabled]="!benchmarksActions"
        [(ngModel)]="selectedBenchmarkAction"
        [isBordered]="true"
		[placeholder]="i18n.strings.baseline_profile_wizard_profile_benchmark_label_placeHolder"
        labelClass="dropdown-width-medium"
        name="benchmark-action"
        label="name"
        (ngModelChange)="onBenchmarkDropdownChanged($event)"
        required>
    </fancy-select>
    <div *ngIf="!!selectedBenchmarkAction">
        <div class="wcd-margin-mediumSmall-top required wcd-font-weight-semibold">
            {{ i18n.strings.baseline_profile_wizard_profile_compliance_level_label }}
        </div>
        <fancy-select
            [ariaLabel]="'Status'"
            [values]="complianceLevelsActions"
            [(ngModel)]="selectedCisComplianceLevelAction"
            [isBordered]="true"
			[placeholder]="i18n.strings.baseline_profile_wizard_profile_compliance_level_label_placeHolder"
            labelClass="dropdown-width-medium"
            name="cis-compliance-level-action"
            label="name"
            (ngModelChange)="onCisComplianceLevelDropdownChanged($event)"
		    required>
        </fancy-select>
    </div>
</div>
