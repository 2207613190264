
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RoutesService } from '@wcd/shared';
import { ConvergencePromotionService } from '../services/convergence-promotion.service';

@Component({
	selector: 'convergance-promotion-toolbar',
	template: `
		<div *ngIf="!shouldHideToolbar" class='wcd-flex-justify-end ms-bgColor-gray10'>
			<div class="wcd-margin-small-horizontal wcd-flex-none wcd-padding-small-vertical semi-bold">
				<fab-command-bar-button
					data-track-id="PromotionBannerExploreFromBarClick"
					data-track-type="Button"
					className='ms-bgColor-gray10'
					[href]='navigateToUrl'
					target='_blank'>
					<wcd-shared-icon iconName="OpenInNewWindow" class='wcd-padding-small-right'></wcd-shared-icon>
					{{ 'promotion.action.navbar' | i18n }}
				</fab-command-bar-button>
			</div>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvergencePromotionToolbarComponent {

	navigateToUrl : string = RoutesService.getSCCRootUrl();
	shouldHideToolbar : boolean = null;

	constructor(private convergencePromotionService: ConvergencePromotionService) {
		this.shouldHideToolbar = !this.convergencePromotionService.promotionConvergenceEnabled();
	}


}

