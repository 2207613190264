var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Subject } from 'rxjs';
import { AirsEntity, AirsEntityStatusCount, AirsEntityType, AirsEntityTypeResults } from '@wcd/domain';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
var AirsEntitiesLoaderService = /** @class */ (function () {
    function AirsEntitiesLoaderService(paris) {
        this.paris = paris;
        this.stop$ = new Subject();
    }
    AirsEntitiesLoaderService.prototype.setEntities = function (options) {
        this.entities$ = this.paris
            .getRepository(AirsEntity)
            .query({ where: __assign({}, options, { isListView: true }) })
            .pipe(shareReplay(1), takeUntil(this.stop$));
    };
    AirsEntitiesLoaderService.prototype.getEntitiesForInvestigation = function (options) {
        if (!this.entities$) {
            this.setEntities(options);
        }
        return this.entities$;
    };
    AirsEntitiesLoaderService.prototype.getEntitiesResultsForInvestigation = function (options) {
        return this.getEntitiesForInvestigation(options).pipe(map(function (entities) {
            var res = entities &&
                entities.items &&
                entities.items.reduce(function (acc, e) {
                    // mailbox is an asset, not evidence. this should be removed once Office remove it from the results
                    if (e.type.id === AirsEntityType.Mailbox) {
                        return acc;
                    }
                    var currRes = acc.find(function (r) { return r.entityType === e.type; });
                    if (!currRes) {
                        acc.push(new AirsEntityTypeResults({
                            entityType: e.type,
                            results: [
                                new AirsEntityStatusCount({
                                    _status: e.verdict,
                                    remediationStatus: e.remediationStatus,
                                    count: 1,
                                }),
                            ],
                        }));
                    }
                    else {
                        var currStatus = currRes.results.find(function (d) { return d.status === e.remediationStatus || d.status === e.verdict; });
                        if (currStatus) {
                            currStatus.count++;
                        }
                        else {
                            currRes.results.push(new AirsEntityStatusCount({
                                _status: e.verdict,
                                remediationStatus: e.remediationStatus,
                                count: 1,
                            }));
                        }
                    }
                    return acc;
                }, []);
            return {
                count: (res && res.length) || 0,
                items: res,
            };
        }));
    };
    AirsEntitiesLoaderService.prototype.stop = function () {
        this.stop$.next();
    };
    AirsEntitiesLoaderService.prototype.ngOnDestroy = function () {
        this.stop$.next();
        this.stop$.complete();
    };
    return AirsEntitiesLoaderService;
}());
export { AirsEntitiesLoaderService };
