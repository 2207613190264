var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { Software } from './software.entity';
import { SoftwareVersion } from '../software-version/software-version.entity';
import { SoftwareInstallation } from '../software-installation/software-installation.entity';
import { SecurityRecommendation } from '../security-recommendation/security-recommendation.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations, ParseDataQuery, } from '../analyticsEndPointUtils';
import { MissingKb } from '../missing-kb/missing-kb.entity';
import { SoftwareInstallationAgg } from './../software-installation/software-installation-agg-versions.entity';
import { ChangeEvent, ChangeEventTypesForMdatp } from '../change-event/change-event.entity';
var ɵ0 = function () { return "products/product/recommendations"; }, ɵ1 = function (software) { return ({ productId: software.id }); };
var SoftwareSecurityRecommendationRelationship = /** @class */ (function () {
    function SoftwareSecurityRecommendationRelationship() {
    }
    SoftwareSecurityRecommendationRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: SecurityRecommendation, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1 }))
    ], SoftwareSecurityRecommendationRelationship);
    return SoftwareSecurityRecommendationRelationship;
}());
export { SoftwareSecurityRecommendationRelationship };
var ɵ2 = function () { return "products/product/vulnerabilities"; }, ɵ3 = function (software) { return ({ productId: software.id }); };
var SoftwareVulnerabilityRelationship = /** @class */ (function () {
    function SoftwareVulnerabilityRelationship() {
    }
    SoftwareVulnerabilityRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: Vulnerability, endpoint: ɵ2, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ3 }))
    ], SoftwareVulnerabilityRelationship);
    return SoftwareVulnerabilityRelationship;
}());
export { SoftwareVulnerabilityRelationship };
var ɵ4 = function () { return "products/product/productversions"; }, ɵ5 = function (data, _, query) {
    return Object.assign({}, data, data.results && {
        results: data.results.map(function (r) { return Object.assign({}, r, { productId: query.where['productId'] }); }),
    });
}, ɵ6 = function (software) { return ({ productId: software.id }); };
var SoftwareVersionRelationship = /** @class */ (function () {
    function SoftwareVersionRelationship() {
    }
    SoftwareVersionRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: SoftwareVersion, endpoint: ɵ4, parseData: ɵ5, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ6 }))
    ], SoftwareVersionRelationship);
    return SoftwareVersionRelationship;
}());
export { SoftwareVersionRelationship };
var ɵ7 = function () { return "products/product/installations"; }, ɵ8 = function (software) { return ({ productId: software.id }); };
var SoftwareInstallationRelationship = /** @class */ (function () {
    function SoftwareInstallationRelationship() {
    }
    SoftwareInstallationRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: SoftwareInstallation, endpoint: ɵ7, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ8 }))
    ], SoftwareInstallationRelationship);
    return SoftwareInstallationRelationship;
}());
export { SoftwareInstallationRelationship };
var ɵ9 = function () { return "products/product/installations/aggregate"; }, ɵ10 = function (software) { return ({
    productId: software.id,
    isNetworkDeviceInstallation: software.category === 'NetworkGear',
}); }, ɵ11 = function (dataQuery) {
    var params = ParseDataQuery(dataQuery);
    params['addNetworkDeviceIpAddresses'] = dataQuery.where['isNetworkDeviceInstallation'];
    return params;
};
var SoftwareInstallationAggRelationship = /** @class */ (function () {
    function SoftwareInstallationAggRelationship() {
    }
    SoftwareInstallationAggRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: SoftwareInstallationAgg, endpoint: ɵ9, allowedTypes: [RelationshipType.OneToMany], allItemsEndpointTrailingSlash: false, getRelationshipData: ɵ10, parseDataQuery: ɵ11 }))
    ], SoftwareInstallationAggRelationship);
    return SoftwareInstallationAggRelationship;
}());
export { SoftwareInstallationAggRelationship };
var ɵ12 = function () { return "products/product/missingkbs"; }, ɵ13 = function (software) { return ({ productId: software.id }); }, ɵ14 = function (data, _, query) {
    data.results = Array.from(data.results).map(function (record) { return (__assign({}, record, { productId: query.where['productId'] })); });
    return data;
};
var SoftwareMissingKbsRelationship = /** @class */ (function () {
    function SoftwareMissingKbsRelationship() {
    }
    SoftwareMissingKbsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: MissingKb, endpoint: ɵ12, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ13, parseData: ɵ14 }))
    ], SoftwareMissingKbsRelationship);
    return SoftwareMissingKbsRelationship;
}());
export { SoftwareMissingKbsRelationship };
var ɵ15 = function () { return "products/product/changeEvents"; }, ɵ16 = function (software) { return ({ productId: software.id }); }, ɵ17 = function (dataQuery) {
    if (dataQuery.where && typeof dataQuery.where === 'object' && dataQuery.where['source'] === 'mdatp') {
        dataQuery.where['eventType'] = ChangeEventTypesForMdatp;
    }
    return ParseDataQuery(dataQuery);
};
var SoftwareChangeEventsRelationship = /** @class */ (function () {
    function SoftwareChangeEventsRelationship() {
    }
    SoftwareChangeEventsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: Software, dataEntity: ChangeEvent, endpoint: ɵ15, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ16, parseDataQuery: ɵ17 }))
    ], SoftwareChangeEventsRelationship);
    return SoftwareChangeEventsRelationship;
}());
export { SoftwareChangeEventsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17 };
