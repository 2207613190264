var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Paris } from '@microsoft/paris';
import { BehaviorSubject, concat, from, of, throwError, timer } from 'rxjs';
import { delayWhen, first, last, map, retryWhen, scan, share, skipWhile, switchMap, tap, } from 'rxjs/operators';
import { AdditionalPlatformProvisionApiCall, ComponentProvisioningApiCall, ComponentProvisioningStatus, GetComponentProvisioningStatusApiCall, GetPlatformProvisionStatusApiCall, PlatformProvisionApiCall, PlatformProvisionStatus, } from '@wcd/domain';
import { isNullOrUndefined } from 'util';
import { HttpClient } from '@angular/common/http';
import { tenantContextCache } from '@wcd/auth';
import { AppInsightsService } from '../../../../../../apps/portal/src/app/insights/services/app-insights.service';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
export var TrackingProperties = [
    'IsMtpEligible',
    'IsNominated',
    'IsOnboardingComplete',
    'IsPermittedOnboarding',
    'AccountType',
    'MtpConsent',
];
var POLLING_INTERVAL = 15000;
var OnboardingService = /** @class */ (function () {
    function OnboardingService(paris, http, appInsightsService, appConfigService, featureService) {
        var _this = this;
        this.paris = paris;
        this.http = http;
        this.appInsightsService = appInsightsService;
        this.appConfigService = appConfigService;
        this.featureService = featureService;
        this.provisionStatusSubject$ = new BehaviorSubject(null);
        this.trackEvent = function (name, value) {
            _this.appInsightsService.trackEvent(name, {
                value: value,
                additionalTrackingProperties: _this.getTrackingProperties(),
            });
        };
    }
    Object.defineProperty(OnboardingService.prototype, "platformProvisionStatus$", {
        get: function () {
            var _this = this;
            if (isNullOrUndefined(this.provisionStatusSubject$.getValue())) {
                this.getPlatformProvisionStatus()
                    .pipe(tap(function (status) {
                    _this.provisionStatusSubject$.next(status);
                }))
                    .subscribe();
            }
            return this.provisionStatusSubject$.pipe(skipWhile(function (val) { return isNullOrUndefined(val); }));
        },
        enumerable: true,
        configurable: true
    });
    OnboardingService.prototype.getPlatformProvisionStatus = function () {
        return this.paris
            .apiCall(GetPlatformProvisionStatusApiCall)
            .pipe(map(function (platformProvisionModel) { return platformProvisionModel.platformProvision; }));
    };
    OnboardingService.prototype.onboardTenant = function (data, setMtpConsent) {
        if (setMtpConsent === void 0) { setMtpConsent = false; }
        var onboardObservable = from(this.onboardTenantV2(data, setMtpConsent));
        return onboardObservable.pipe(last(), 
        // Convert to observable of void
        switchMap(function () { return of(undefined); }), retryWhen(function (errors) {
            return errors.pipe(scan(function (retries, err) {
                if (retries > 2)
                    throw err;
                return retries + 1;
            }, 0), delayWhen(function () { return timer(1500); }));
        }), share());
    };
    OnboardingService.prototype.onboardTenantV2 = function (data, setMtpConsent) {
        return __awaiter(this, void 0, void 0, function () {
            var status;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.provisionStatusSubject$.pipe(first()).toPromise()];
                    case 1:
                        status = _a.sent();
                        return [4 /*yield*/, concat.apply(void 0, this.getRequiredOperationsForPlatformProvisioning(data, status, setMtpConsent)).toPromise()];
                    case 2:
                        _a.sent();
                        if (this.featureService.isEnabled(Feature.ComponentProvisioningInScc)) {
                            return [2 /*return*/, concat.apply(void 0, this.getRequiredOperationsForComponentProvisioning()).toPromise()];
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    OnboardingService.prototype.optInTenant = function () {
        var _this = this;
        // Do not use Paris here since Paris is not updated with the serviceUrls post tenant onboarding
        var optInPromise = sccHostService.ajax.post(this.getOptInUrl())
            .then(function () {
            _this.appConfigService.updateChangeableProperties({
                mtpConsent: true,
                isOnboardingComplete: true,
            });
            return true;
        });
        return from(optInPromise);
    };
    OnboardingService.prototype.getOptInUrl = function () {
        return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + "/api/mtp/workloads/consent" : '<mtp>/k8s/mtp/workloads/consent';
    };
    OnboardingService.prototype.getRequiredOperationsForPlatformProvisioning = function (data, status, setMtpConsent) {
        var operations = [];
        if (status === PlatformProvisionStatus.NotProvisioned) {
            var settings = this.getDefaultOnboardingSettings(data.dataCenterRegion, setMtpConsent);
            operations.push(this.platformProvisioning(settings));
            operations.push(this.pollPlatformProvision());
            operations.push(this.additionalPlatformProvisioning());
        }
        else if (status === PlatformProvisionStatus.InProgress) {
            operations.push(this.pollPlatformProvision());
            operations.push(this.additionalPlatformProvisioning());
        }
        else if (status === PlatformProvisionStatus.PlatformProvisioned) {
            operations.push(this.additionalPlatformProvisioning());
        }
        else if (status === PlatformProvisionStatus.AdditionalPlatformProvisioned) {
            // Support MDATP-provisioned tenants with PlatformProvisionStatus.AdditionalPlatformProvisioned and IsOnboardingComplete == false
            operations.push(of(status));
        }
        return operations;
    };
    OnboardingService.prototype.platformProvisioning = function (settings) {
        return this.paris
            .apiCall(PlatformProvisionApiCall, settings)
            .pipe(map(function () { return PlatformProvisionStatus.InProgress; }));
    };
    OnboardingService.prototype.pollPlatformProvision = function () {
        var _this = this;
        return this.paris.apiCall(GetPlatformProvisionStatusApiCall).pipe(switchMap(function (response) {
            var status = response.platformProvision;
            if (status === PlatformProvisionStatus.PlatformProvisioned) {
                return of(PlatformProvisionStatus.PlatformProvisioned);
            }
            else if (status === PlatformProvisionStatus.InProgress) {
                return timer(POLLING_INTERVAL).pipe(switchMap(function () { return _this.pollPlatformProvision(); }));
            }
            else {
                return throwError('Platform provision failed');
            }
        }));
    };
    OnboardingService.prototype.additionalPlatformProvisioning = function () {
        return this.paris
            .apiCall(AdditionalPlatformProvisionApiCall)
            .pipe(map(function () { return PlatformProvisionStatus.AdditionalPlatformProvisioned; }));
    };
    OnboardingService.prototype.getRequiredOperationsForComponentProvisioning = function () {
        return [this.componentProvisioning(), this.pollComponentProvisioningStatus()];
    };
    OnboardingService.prototype.componentProvisioning = function () {
        return this.paris
            .apiCall(ComponentProvisioningApiCall)
            .pipe(map(function () { return ComponentProvisioningStatus.InProgress; }));
    };
    OnboardingService.prototype.pollComponentProvisioningStatus = function () {
        var _this = this;
        return this.paris.apiCall(GetComponentProvisioningStatusApiCall).pipe(switchMap(function (response) {
            var status = response.componentProvisioning;
            if (status === ComponentProvisioningStatus.Provisioned) {
                return of(ComponentProvisioningStatus.Provisioned);
            }
            else if (status === ComponentProvisioningStatus.InProgress) {
                return timer(POLLING_INTERVAL).pipe(switchMap(function () { return _this.pollComponentProvisioningStatus(); }));
            }
            else {
                return throwError('Component provisioning failed');
            }
        }));
    };
    OnboardingService.prototype.getDefaultOnboardingSettings = function (selectedGeoRegion, setMtpConsent) {
        var geoRegion = selectedGeoRegion ? selectedGeoRegion.id : undefined;
        var mtpConsentValue = !!setMtpConsent;
        return {
            geoRegion: geoRegion,
            maxEndpoints: 1000,
            minEndpoints: 0,
            optInPublicPreviewFeatures: false,
            retentionPolicy: 180,
            mtpConsent: mtpConsentValue,
        };
    };
    OnboardingService.prototype.getTrackingProperties = function () {
        var appConfigTracking = Object.create(null);
        TrackingProperties.map(function (p, index) {
            return (appConfigTracking[TrackingProperties[index]] =
                tenantContextCache.appConfig[TrackingProperties[index]]);
        });
        return appConfigTracking;
    };
    return OnboardingService;
}());
export { OnboardingService };
