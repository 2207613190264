
		<div
			class="DistributionElement wcd-padding-small-left"
			[ngClass]="borderColorClass"
			[class.DistributionElement--compact]="size === 'compact'"
			(click)="onClick.emit()"
			(keydown.enter)="onClick.emit()"
		>
			<span [ngClass]="{'DistributionElement__clickable pointer': clickable}">
				<div class="DistributionElement__title" [ngStyle]="{ 'font-size': titleSize, color: titleColor }">
					{{ title }}
					<wcd-help *ngIf="helpText" [text]="helpText"></wcd-help>
				</div>
				<div class="wcd-flex wcd-flex-nowrap-horizontal DistributionElement__text-container">
					<div
						class="DistributionElement__main-text wcd-font-weight-bold"
						[ngStyle]="{ 'font-size': mainTextSize, color: mainTextColor }"
					>
						{{ mainText }}
					</div>
					<div *ngIf="secondaryText" class="DistributionElement__secondary-text"
						[ngStyle]="{ 'font-size': secondaryTextSize, 'font-weight': secondaryFontWeight, 'color': secondaryTextColor }">
						{{ secondaryText }}
					</div>
				</div>
			</span>
		</div>
	