<!-- tslint:disable:template-click-events-have-key-events -->
<ng-container *ngIf="actor">
	<h4 class="wcd-margin-none-all
			   wcd-padding-vertical
			   wcd-padding-right
			   wcd-border-bottom
			   wcd-font-weight-bold">
		{{ actor.name }}
	</h4>

	<ng-container *ngFor="let section of actor.sections">
		<h4 class="wcd-margin-none-all
				   wcd-padding-vertical
				   wcd-padding-right
				   wcd-font-weight-bold">
			{{ section.title }}
		</h4>

		<markdown
			[data]="section.content"
			class="wcd-block ck-content">
		</markdown>
	</ng-container>

	<ng-container *ngIf="actor.linkToReport">
		<a (click)="onDownloadReport()">{{ 'alerts.alertActor.downloadReport' | i18n }}</a>
	</ng-container>
</ng-container>
