<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
	<form
		class="wcd-full-height wcd-flex-vertical wcd-padding-all"
		data-track-component="AddEvaluationMachineComponent"
	>
		<h3 #panelHeader class="wcd-font-size-xxl wcd-font-weight-bold">
			{{'evaluation.dashboard.dataView.commandBar.addMachine.button' | i18n}}
		</h3>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-margin-top">
			<div class="wcd-margin-bottom">{{ getDisclaimer() }}</div>
			<div class="wcd-margin-bottom">{{ getDescription() }}</div>
			<div class="wcd-font-weight-bold wcd-margin-bottom">
				{{ 'evaluation.dashboard.dataView.commandBar.addMachine.' +
					(evaluationMachine ? 'chooseMachineTypeTitle' : 'machineTypeTitle') | i18n }}
			</div>
			<fancy-select *ngIf="!evaluationMachine; else showConnectionDetails"
				[(ngModel)]="machineOs"
				[values]="machineOsValues"
				[formatLabel]="getMachineOsLabel"
				label="name"
				name="add-evaluation-machine"
				isBordered="true"
				labelClass="dropdown-width-small-medium"
				[disabled]="gettingConnectionDetails || evaluationMachine"
			></fancy-select>
			<ng-template #showConnectionDetails>
				<div>
					{{ getMachineOsLabel(machineOs) }}
				</div>
				<div class="wcd-font-weight-bold wcd-margin-large-top">
					{{ 'evaluation.dashboard.dataView.commandBar.addMachine.connectionDetailsTitle' | i18n }}
				</div>

			</ng-template>
			<div *ngIf="gettingConnectionDetails" class="wcd-margin-large-top">
				<fab-spinner [size]="SpinnerSize.large"> </fab-spinner>
				<div class="ms-color-themePrimary wcd-flex-center-horizontal">
					{{ 'evaluation.dashboard.dataView.commandBar.addMachine.gettingConnectionDetails' | i18n }}
				</div>
			</div>
			<div *ngIf="evaluationMachine">
				<div class="wcd-margin-large-vertical">
					<fab-message-bar [messageBarType]="MessageBarType.warning">
							<markdown [data]="'evaluation.dashboard.dataView.commandBar.addMachine.saveConnectionDetails' | i18n">
							</markdown>
					</fab-message-bar>
				</div>
				<div class="wcd-margin-small-bottom">
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.machineName' | i18n }}
					</span>
					{{ evaluationMachine.name }}
				</div>
				<div class="wcd-margin-small-bottom">
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.userName' | i18n }}
					</span>
					{{ machineUserName }}
				</div>
				<fab-text-field
					[(value)]="evaluationMachine.password"
					[readOnly]="true"
					[disabled]="true"
				>
				</fab-text-field>
				<copy-to-clipboard
					[content]="evaluationMachine.password"
					[originId]="'evaluation-machine-password'"
					class="wcd-flex-items-end-vertical"
				>
				</copy-to-clipboard>
			</div>
		</div>
		<div class="wcd-flex-none wcd-margin-top">
			<footer>
				<div
					class="wcd-flex-1 wcd-flex-horizontal wcd-padding-top"
					[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				>
					<fab-primary-button
						data-track-id="AddMachine"
						data-track-type="Button"
						[text]="
							evaluationMachine
								? ('buttons.done' | i18n)
								: ('evaluation.dashboard.dataView.commandBar.addMachine.button'
									| i18n)
						"
						(onClick)="evaluationMachine ? closeAddMachinePanel() : addEvaluationMachine()"
						[disabled]="gettingConnectionDetails"
					>
					</fab-primary-button>
				</div>
			</footer>
		</div>
	</form>
</wcd-panel>
