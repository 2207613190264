import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { GuidedHuntingQuery, HuntingQueryResult } from '@wcd/domain';

interface RawHuntingQueryParameters {
	QueryText: string;
	StartTime: Date | null;
	EndTime: Date | null;
	MaxRecordCount?: number;
}

export interface HuntingQueryParameters {
	queryText: string;
	startTime?: Date;
	endTime?: Date;
	resultsLimit?: number;
	guidedQuery?: GuidedHuntingQuery;
}

/**
 * Run a Hunting query in hunting service.
 */
@ApiCall({
	name: 'Hunting query',
	endpoint: 'queryExecutor',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
	cache: false,
	timeout: 1000 * 60 * 10, // 10 minutes timeout, hunting queries can be long
	type: HuntingQueryResult,
	parseQuery: (query: HuntingQueryParameters): HttpOptions<RawHuntingQueryParameters> => ({
		data: {
			QueryText: query.queryText,
			StartTime: query.startTime || null,
			EndTime: query.endTime || null,
			MaxRecordCount: query.resultsLimit || null,
		},
	}),
})
export class HuntingQueryApiCall extends ApiCallModel<HuntingQueryResult, HuntingQueryParameters> {}
