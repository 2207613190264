/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "./empty-field.component";
var styles_EmptyFieldComponent = [];
var RenderType_EmptyFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EmptyFieldComponent, data: {} });
export { RenderType_EmptyFieldComponent as RenderType_EmptyFieldComponent };
export function View_EmptyFieldComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "table-empty-object"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "common_noDataAvailable")); _ck(_v, 3, 0, currVal_0); }); }
export function View_EmptyFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "empty-field", [], null, null, null, View_EmptyFieldComponent_0, RenderType_EmptyFieldComponent)), i0.ɵdid(1, 49152, null, 0, i3.EmptyFieldComponent, [], null, null)], null, null); }
var EmptyFieldComponentNgFactory = i0.ɵccf("empty-field", i3.EmptyFieldComponent, View_EmptyFieldComponent_Host_0, {}, {}, []);
export { EmptyFieldComponentNgFactory as EmptyFieldComponentNgFactory };
