var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { RecommendationContextPropertiesRelationship, RecommendationContextKeyAndExposedMachinesCount, SecurityRecommendation, RecommendationContextType, TvmEndPoint, } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris } from '@microsoft/paris';
var RecommendationContextPropertiesPanelComponent = /** @class */ (function (_super) {
    __extends(RecommendationContextPropertiesPanelComponent, _super);
    function RecommendationContextPropertiesPanelComponent(paris, tvmTextsService, tvmDownloadService, featuresService, router) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.tvmTextsService = tvmTextsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.repository = _this.paris.getRelationshipRepository(RecommendationContextPropertiesRelationship);
        _this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
        return _this;
    }
    RecommendationContextPropertiesPanelComponent.prototype.ngOnInit = function () {
        this.title = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.securityRecommendation);
        this.repository.sourceItem = new RecommendationContextKeyAndExposedMachinesCount({
            scId: this.securityRecommendation.scId,
            contextKey: this.recommendationContextKey,
        });
    };
    RecommendationContextPropertiesPanelComponent.prototype.exportCveData = function () {
        this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.repository, TvmEndPoint.Analytics, this.title + " - " + this.recommendationContextKey);
    };
    return RecommendationContextPropertiesPanelComponent;
}(PanelContainer));
export { RecommendationContextPropertiesPanelComponent };
