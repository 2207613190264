
		<div class="wcd-margin-medium-bottom  message-bar" *ngIf="shouldDisplayOnboardingCta">
			<fab-message-bar [messageBarType]="MessageBarType.warning" [renderActions]="actions">
				<div class="wcd-font-size-s wcd-margin-none-bottom">
					<div>{{ 'DevicePaneOnboardingCtaMessage' | i18n }}</div>
				</div>
			</fab-message-bar>

			<ng-template #actions>
				<div class="wcd-margin-small-horizontal">
					<fab-messagebar-button
						[text]="'DevicePaneOnboardingCtaMessageButtonText' | i18n"
						(onClick)="goToOnboardingPage()"
						[disabled]="!isAdmin"
					></fab-messagebar-button>
				</div>
			</ng-template>
		</div>
	