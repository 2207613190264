
		<div
			class="message-bar wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal "
			[ngClass]="className"
			data-track-component="Top Message Bar"
			data-track-component-type="Message Bar"
		>
			<wcd-shared-icon [iconName]="iconName" class="message-bar-icon"> </wcd-shared-icon>
			<div [attr.role]="messageRole" class="message-bar-text wcd-flex-1 message-bar-text-size" [wcdTooltip]="tooltipKey | i18n">
				<markdown [data]="messageKey | i18n: { data: messageData }"></markdown>
			</div>
			<div *ngIf="actions?.length" class="wcd-flex-none">
				<ng-container *ngFor="let action of actions">
					<button
						class="message-bar-item btn"
						[attr.data-track-id]="'MessageBar-' + action.id"
						data-track-type="Button"
						type="button"
						[wcdTooltip]="action.tooltip"
						[ngClass]="action.buttonClass || 'btn-primary'"
						[disabled]="isRunning(action)"
						(click)="runAction(action)"
					>
						<i class="loader-icon" [hidden]="!isRunning(action)"></i>
						<wcd-shared-icon
							*ngIf="action.icon && !isRunning(action)"
							[iconName]="action.icon"
							class="control-icon"
						>
						</wcd-shared-icon>
						<span class="command-bar-item-button-label">
							{{ action.name }}
						</span>
					</button>
				</ng-container>
			</div>
		</div>
	