
		<div class="progress-indicator">
			<span *ngIf="label">{{ label }}</span>
			<div class="progress-track color-box-gray-175">
				<div
					#content
					class="color-box-primary wcd-full-height"
					role="progressbar"
					aria-valuemin="0"
					aria-valuemax="100"
				></div>
			</div>
		</div>
	