import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsSecurityGroup } from '@wcd/domain';

@Component({
	selector: 'airs-security-group-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_securityGroup_fields_displayName }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">{{ i18nService.strings.airsEntities_securityGroup_fields_firstSeen }}</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsSecurityGroupDetailsComponent {
	@Input() entity: AirsSecurityGroup;

	constructor(public i18nService: I18nService) {}
}
