import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Dictionary, Feature, FeaturesService } from '@wcd/config';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { escapeRegExp } from 'lodash-es';
import { I18nService, LANGUAGE_KEY, getPortalLanguage, DEFAULT_LANGUAGE } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'flags',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-scroll-vertical">
			<div class="wcd-padding-large-all wcd-border-bottom">
				<h3 class="wcd-margin-bottom">Language</h3>
				<p>
					You can override MDE pages' language on this browser. Select a language and refresh the
					page. Make sure that the 'I18n language change' flag is enabled.
				</p>
				<p>
					You're on {{ isScc ? 'SCC' : 'MDE' }} portal. Default is: '<strong>{{
						defaultPortalLanguage
					}}</strong
					>'
				</p>
				<p>
					<strong *ngIf="isScc"
						>Please use the Office-shell settings panel from the main header in order to imitate
						the user's language change.</strong
					>
				</p>
				<wcd-select
					[values]="languages"
					(ngModelChange)="onLanguageChange($event)"
					[(ngModel)]="selectedLanguage"
					[placeholder]="'Select...'"
					class="wcd-margin-top"
				></wcd-select>
				<div class="wcd-margin-top">
					<button (click)="refresh()" class="btn btn-primary">Refresh</button>
					<button
						(click)="resetLanguage()"
						class="btn btn-secondary wcd-margin-left"
						wcdTooltip="Resets language pick. After a refresh, the language will be taken from default or Office-shell settings panel."
					>
						Reset language selection
					</button>
				</div>
			</div>
			<div class="wcd-padding-large-all">
				<h3>{{ i18n.strings.flags_title }}</h3>
				<p class="page-subtitle wcd-margin-small-top">{{ 'flags_description' | i18n }}</p>
			</div>
			<div class="wcd-padding-large-horizontal wcd-margin-large-bottom">
				<input
					type="text"
					(keyup)="filter$.next($event.target.value)"
					placeholder="Filter flags..."
					class="wcd-width-medium search"
				/>
			</div>
			<div class="wcd-padding-large-horizontal wcd-padding-large-bottom">
				<table class="key-value-list with-light-borders">
					<tbody>
						<tr *ngFor="let flag of (filteredFlags$ | async)">
							<td class="key wcd-padding-small-top">
								<strong [id]="flag.id">{{ flag.name }}</strong>
								<markdown [data]="'flags.flag.' + flag.id | i18n: {}:true"></markdown>
							</td>
							<td class="value">
								<fancy-select
									[name]="'flag-' + flag.id"
									[(ngModel)]="flag.state"
									label="name"
									[formatLabel]="getFormatLabel(flag)"
									[isBordered]="true"
									[labelClass]="
										(flag.state.value
											? 'color-box-success'
											: flag.state.value === false
											? 'color-box-error'
											: '') + ' dropdown-width-small-medium'
									"
									(ngModelChange)="setFlag(flag, $event)"
									[values]="flagValues"
								></fancy-select>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	`,
})
export class FlagsComponent {
	flags: Array<Flag>;
	filteredFlags$: Subject<Array<Flag>> = new Subject();
	filter$: Subject<string> = new Subject<string>();
	flagValues = [
		{ id: FlagState.Default, name: 'Default', value: null },
		{ id: FlagState.Enabled, name: 'Enabled', value: true },
		{ id: FlagState.Disabled, name: 'Disabled', value: false },
	];

	languages = [
		'en',
		'de',
		'es',
		'fr',
		'it',
		'ja',
		'zh-Hans',
		'zh-Hant',
		'ko',
		'pt-BR',
		'ru',
		'asterisk',
		'qps-ploc',
		'qps-ploca',
		'qps-plocm',
	];
	isScc = sccHostService.isSCC;
	defaultPortalLanguage = DEFAULT_LANGUAGE;
	selectedLanguage = getPortalLanguage();

	private flagValuesDictionary: Dictionary<number, FlagStateValue> = Dictionary.fromList(
		this.flagValues,
		'id'
	);

	constructor(private featuresService: FeaturesService, public i18n: I18nService) {
		this.flags = Object.keys(Feature)
			.map(featureId => {
				const localFeatureValue: boolean = featuresService.getLocalFeatureValue(featureId);

				return {
					id: featureId,
					name: Feature[featureId].replace(/([a-z])([A-Z])/g, '$1 $2'),
					isLocal: localFeatureValue !== null,
					defaultValue: this.featuresService.isDefault(featureId),
					value: featuresService.isEnabled(featureId),
					state:
						localFeatureValue === null
							? this.flagValuesDictionary.get(FlagState.Default)
							: localFeatureValue
							? this.flagValuesDictionary.get(FlagState.Enabled)
							: this.flagValuesDictionary.get(FlagState.Disabled),
				};
			})
			.sort((a, b) => (a.name > b.name ? 1 : -1));

		this.filter$
			.pipe(
				debounceTime(200),
				distinctUntilChanged()
			)
			.subscribe(filter => this.filterFlags(filter));

		this.filter$.next('');
	}

	private filterFlags(filter: string) {
		if (!filter) this.filteredFlags$.next(this.flags);
		else {
			const filterRegExp = new RegExp(escapeRegExp(filter).replace(/\s/g, ''), 'i');
			this.filteredFlags$.next(this.flags.filter(flag => filterRegExp.test(flag.id)));
		}
	}

	setFlag(flag: Flag, flagState: FlagStateValue) {
		this.featuresService.setLocalFeatureValue(flag.id, flagState.value);
	}

	getFormatLabel(flag: Flag): (value: FlagStateValue) => string {
		return (value: FlagStateValue) => {
			return value.id === FlagState.Default
				? `${value.name} (${flag.defaultValue ? 'Enabled' : 'Disabled'})`
				: value.name;
		};
	}

	onLanguageChange(lang) {
		localStorage.setItem(LANGUAGE_KEY, lang);
	}

	resetLanguage() {
		localStorage.removeItem(LANGUAGE_KEY);
		this.selectedLanguage = getPortalLanguage();
		this.refresh();
	}

	refresh() {
		window.location.reload();
	}
}

interface Flag {
	id: string;
	defaultValue: boolean;
	name: string;
	isLocal: boolean;
	value: boolean;
	state: FlagStateValue;
}

interface FlagStateValue {
	id: FlagState;
	name: string;
	value: boolean;
}

enum FlagState {
	Default,
	Enabled,
	Disabled,
}
