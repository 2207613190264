
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<file-action-center-collection
				[file]="file"
				(foundActions)="foundSampleCollectionActions = $event"
			></file-action-center-collection>
			<hr *ngIf="foundQuarantineActions && foundSampleCollectionActions" />
			<file-action-center-quarantine
				[file]="file"
				(foundActions)="foundQuarantineActions = $event"
			></file-action-center-quarantine>
			<!-- show message only after search is complete, and no actions were found -->
			<fab-message-bar
				*ngIf="
					foundQuarantineActions !== null &&
					foundSampleCollectionActions !== null &&
					!foundSampleCollectionActions &&
					!foundQuarantineActions
				"
				[messageBarType]="MessageBarType.info"
				[styles]="{ text: { marginBottom: 16 } }"
			>
				{{ 'file.actionCenter.noActions' | i18n }}
			</fab-message-bar>
		</wcd-panel>
	