import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

export enum CustomTiIndicatorActionsTypes {
	Allow = 0,
	Alert = 1,
	AlertAndBlock = 2,
	Warn = 3,
	Block = 4,
	Audit = 11,
	BlockAndRemediate = 12,
}

@Entity({
	singularName: 'Custom Ti Indicator Action Type',
	pluralName: 'Custom Ti Indicator Action Types',
	values: [
		{
			id: CustomTiIndicatorActionsTypes.Allow,
			name: 'Allow',
		},
		{
			id: CustomTiIndicatorActionsTypes.Alert,
			name: 'Alert',
		},
		{
			id: CustomTiIndicatorActionsTypes.AlertAndBlock,
			name: 'AlertAndBlock',
		},
		{
			id: CustomTiIndicatorActionsTypes.Warn,
			name: 'Warn',
		},
		{
			id: CustomTiIndicatorActionsTypes.Block,
			name: 'Block',
		},
		{
			id: CustomTiIndicatorActionsTypes.Audit,
			name: 'Audit',
		},
		{
			id: CustomTiIndicatorActionsTypes.BlockAndRemediate,
			name: 'BlockAndRemediate',
		},
	],
})
export class CustomTiIndicatorActionsType extends EntityModelBase<number> {
	@EntityField() name: string;
}
