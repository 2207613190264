/* tslint:disable:template-click-events-have-key-events */
import { Component, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SpinnerSize } from 'office-ui-fabric-react';
import { WebContentFilteringDomainFeedback, DomainFeedback, FeedbackPriority } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { finalize } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';

const PRIORITY_VALUES: Array<string> = ['Low', 'Medium', 'High'];

@Component({
	selector: 'dispute-category-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './dispute-category.panel.component.html',
	styles: [
		`
			.fullWidthDropdown {
				width: 100%;
			}
		`,
	],
})
export class DisputeCategoryPanelComponent extends PanelContainer implements OnInit {
	readonly SpinnerSize = SpinnerSize;
	@Input() domainName: string;
	@Input() categoryIdAsInput: number;
	@Input() categoryNameAsInput: string;

	domain: string;
	categoryId: number;
	categoryName: string;
	currentPrioritySelected: string;
	comments: string;
	isSending: boolean;

	priorityValues = Object.keys(FeedbackPriority).filter(
		key => isNaN(key as any) && FeedbackPriority[key] !== 0
	);
	constructor(
		router: Router,
		private paris: Paris,
		private dialogsService: DialogsService,
		private i18nService: I18nService) {
		super(router);
	}

	ngOnInit() {
		this.domain = this.domainName;
		this.categoryId = this.categoryIdAsInput;
		this.categoryName = this.categoryNameAsInput;
		this.currentPrioritySelected = this.priorityValues[0];
	}

	updatePrioritySelected(newPriority: string) {
		this.currentPrioritySelected = newPriority;
	}

	submitDispute() {
		this.isSending = true;
		this.paris
			.apiCall<void, DomainFeedback>(WebContentFilteringDomainFeedback, {
				Comments: this.comments,
				Domain: this.domain,
				CategoryId: this.categoryId,
				CategoryName: this.categoryName,
				FeedbackPriority: FeedbackPriority[this.currentPrioritySelected]
			}).pipe(
				finalize(()=> (this.isSending = false))
			)
			.subscribe(
				() => {
					this.closePanel();
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get(
							'urls.fields.domain.disputeCategory.panel.submit.success',
						),
						icon: 'checkCircle',
						iconClassName: 'color-text-success',
					});
				},
				(error) => {
					this.closePanel();
					this.dialogsService.showError({
						title: this.i18nService.get(
							'urls.fields.domain.disputeCategory.panel.submit.error',
						),
						data: error,
					});
				}
			);
	}
}
