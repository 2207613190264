import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from '@wcd/app-config';
import { ApiBaseModel } from '@wcd/data';
import { IPreload } from '@wcd/shared';
import { Observable, zip } from 'rxjs';
import { tap } from 'rxjs/operators';
import { assign, forEach, map } from 'lodash-es';

class preferencesSettingLoader {
	public getSettingsUrl: string;
	public setSettingsUrl: string;
	public observable?: Observable<object>; // http get observable - required to handle the get request and as part of the return value of the Preload interface init implementation
}

@Injectable()
export class PreferencesSetupSettingsService implements IPreload {
	// TODO - extend service to all preferences
	private preferencesSetupSettings: { [settingId: string]: preferencesSettingLoader } = {
		advancedFeatures: {
			getSettingsUrl: '/GetAdvancedFeaturesSetting',
			setSettingsUrl: '/SaveAdvancedFeaturesSetting',
		},
		previewExperienceSettings: {
			getSettingsUrl: '/GetPreviewExperienceSetting',
			setSettingsUrl: '/SavePreviewExperienceSetting',
		},
	};

	// holds all the preferences page settings
	private allSettings: { [settingId: string]: any };

	constructor(private http: HttpClient, private appConfig: AppConfigService) {}

	init() {
		const managementUrlPrefix: string = this.appConfig.serviceUrls.management;
		this.allSettings = {};

		// go over each of the setting pages, creates a request to get the settings from the server and update in the 'allSettings' object of the service.
		forEach(this.preferencesSetupSettings, (settingsObj, settingsName) => {
			// create an observable that handles the request to get settings.
			this.preferencesSetupSettings[settingsName].observable = this.http
				.get(
					managementUrlPrefix + settingsObj.getSettingsUrl,
					ApiBaseModel.getUrlParams({ asJson: true })
				)
				.pipe(
					tap((settingsData: { [settingId: string]: any }) => {
						// load specific setting page into the settings object of this service.
						this.updateSettings(settingsData);
					})
				);
		});

		// collect all the observables of each preferences setting page and return as united observable - for PreLoading interface.
		const settingsObservables: Array<Observable<Object>> = map(
			this.preferencesSetupSettings,
			settingsObj => {
				return settingsObj.observable;
			}
		);
		return zip.apply(null, settingsObservables);
	}
	// #region settingsGetters
	private getSetting(settingId: string) {
		return this.allSettings[settingId];
	}

	getAatpIntegrationEnabled() {
		return this.getSetting('AatpIntegrationEnabled');
	}

	getAatpWorkspaceExists() {
		return this.getSetting('AatpWorkspaceExists');
	}

	getBlockListEnabled() {
		return this.getSetting('BlockListEnabled');
	}

	getEnableWdavAuditMode() {
		return this.getSetting('EnableWdavAuditMode');
	}

	getEnableWdavPassiveModeRemediation() {
		return this.getSetting('EnableWdavPassiveModeRemediation');
	}

	getIsOptIn() {
		return this.getSetting('IsOptIn');
	}

	getLicenseEnabled() {
		return this.getSetting('LicenseEnabled');
	}

	getO365ToAtpIntegrationEnabled() {
		return this.getSetting('O365ToAtpIntegrationEnabled');
	}

	getOfficeIntegrationEnabled() {
		return this.getSetting('OfficeIntegrationEnabled');
	}

	getOfficeLicenseEnabled() {
		return this.getSetting('OfficeLicenseEnabled');
	}

	getShowUserAadProfile() {
		return this.getSetting('ShowUserAadProfile');
	}

	getSkypeIntegrationEnabled() {
		return this.getSetting('SkypeIntegrationEnabled');
	}

	getDartDataCollection() {
		return this.getSetting('DartDataCollection');
	}

	getSliceId() {
		return this.getSetting('SliceId');
	}

	// #endregion settingsGetters

	/*
	 * gets an object contaiing settings - with setting name as keys and setting value as value
	 * updating the service setting object accordingly
	 * TODO - currently this function does not update the server with the given settings - this is done in the related setting page
	 */
	updateSettings(settingsObject: { [settingId: string]: any }) {
		assign(this.allSettings, settingsObject);
	}
}
