import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('sidePaneAlert', sidePaneAlertIndicatorDirective);

sidePaneAlertIndicatorDirective.$inject = [];

function sidePaneAlertIndicatorDirective() {
	return {
		restrict: 'E',
		bindToController: true,
		controllerAs: 'vm',
		scope: {
			selected: '=',
		},
		template: `
		<div class="side-pane-alert-container-outer pointer" 
			ng-class="{'side-pane-selected' : vm.selected, 'side-pane-unselected': !vm.selected}"
			data-track-id="sidePaneButtonAlert" 
			data-track-type="SidePaneToggleButton"
			ng-class="{'side-pane-selected' : vm.selected, 'side-pane-unselected': !vm.selected}">
        <div class="side-pane-alert-container-inner">
            <i class="icon icon-TaskStateComplete"></i>
        </div>
    </div>
		`,
		controller: function() {},
	};
}
