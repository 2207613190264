
		<fancy-select
			[wcdTooltip]="getTooltip()"
			[ngModel]="currentSelection.value"
			[name]="dataTrackId"
			[openMenuPosition]="openMenuPosition"
			[label]="propertyIdForLabel"
			class="hidden-action command-bar-item-dropdown"
			[formatLabel]="formatLabel"
			(ngModelChange)="onSelectionChanged($event)"
			[values]="selections"
			[listId]="listId + (isInMoreMenu ? '-more' : '')"
			[buttonIcon]="icon"
			[ariaLabel]="ariaLabel"
			[navigateUsingArrowKeysOnly]="true"
			[navigateUsingTab]="true"
			[focusOnInit]="focusOnInit"
			(focus)="focus && focus()"
			[showIconDropdown]="!smallScreenView"
			[placeholder]=""
			[iconButtonOnly]="smallScreenView"
			[dropdownAreaRole]="'button'"
			#fancyElement
		>
		</fancy-select>
	