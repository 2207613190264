import { Observable } from 'rxjs';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { EntityModelBase, Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { ActivatedEntity } from './activated-entity.service';
import { take, switchMap } from 'rxjs/operators';
import { EntityIdRouteService } from './entity-id-route.service';
import { EntityResolver } from './entity.resolver';
import { TvmMachineGroupsFilterService } from '../../tvm/services/tvm-machine-groups-filter.service';
import { AppContextService } from '@wcd/config';

@Injectable()
export class TvmEntityResolver<TEntity extends EntityModelBase> extends EntityResolver<TEntity> {
	constructor(
		protected paris: Paris,
		protected activatedEntity: ActivatedEntity,
		protected globalEntityTypesService: GlobalEntityTypesService,
		protected router: Router,
		protected readonly entityIdRouteService: EntityIdRouteService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		protected appContext: AppContextService
	) {
		super(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext);
	}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TEntity> {
		return this.machineGroupsFilterService.machineGroupsFilter$.pipe(
			take(1),
			switchMap(machineGroupsFilterData => {
				const params = {};
				if (machineGroupsFilterData && machineGroupsFilterData.isFiltering) {
					const groupIds: string = machineGroupsFilterData.machineGroups
						.filter(mg => mg.isSelected)
						.map(mg => mg.groupId)
						.join();
					params['rbacGroups'] = `groups in (${groupIds})`;
				}
				return super.resolve(route, state, params);
			})
		);
	}
}
