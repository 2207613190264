/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../global_entities/components/entity-details/security-recommendation.entity-details.component.ngfactory";
import * as i2 from "../../../../global_entities/components/entity-details/security-recommendation.entity-details.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../../tvm/services/tvm-texts.service";
import * as i5 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../common/side-panel.service";
import * as i8 from "../../../../tvm/services/tvm-download.service";
import * as i9 from "../services/security-recommendation.service";
import * as i10 from "../../vulnerabilities/services/vulnerability.service";
import * as i11 from "../../../../tvm/services/tvm-productivity-impact.service";
import * as i12 from "../../../../tvm/services/tvm-recommendation-context.service";
import * as i13 from "../../remediation/remediation-tasks/services/remediation-itsm-tools-service";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i16 from "./security-recommendation.entity-panel.component";
import * as i17 from "../../../../insights/services/app-insights.service";
var styles_SecurityRecommendationEntityPanelComponent = [];
var RenderType_SecurityRecommendationEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SecurityRecommendationEntityPanelComponent, data: {} });
export { RenderType_SecurityRecommendationEntityPanelComponent as RenderType_SecurityRecommendationEntityPanelComponent };
export function View_SecurityRecommendationEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "security-recommendation-entity-details", [], null, null, null, i1.View_SecurityRecommendationEntityDetailsComponent_0, i1.RenderType_SecurityRecommendationEntityDetailsComponent)), i0.ɵdid(3, 114688, null, 0, i2.SecurityRecommendationEntityDetailsComponent, [i3.Paris, i4.TvmTextsService, i5.FeaturesService, i6.I18nService, i0.Injector, i0.ChangeDetectorRef, i7.SidePanelService, i8.TvmDownloadService, i9.SecurityRecommendationService, i10.VulnerabilityService, i11.TvmProductivityImpactService, i12.TvmRecommendationContextService, i13.RemediationItsmToolsService, i14.Router, i15.TzDateService], { recommendationOptions: [0, "recommendationOptions"], recommendation: [1, "recommendation"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.singleAssetRecommendationOptions; var currVal_1 = _co.securityRecommendation; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_SecurityRecommendationEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "security-recommendation-entity-panel", [], null, null, null, View_SecurityRecommendationEntityPanelComponent_0, RenderType_SecurityRecommendationEntityPanelComponent)), i0.ɵdid(1, 4440064, null, 0, i16.SecurityRecommendationEntityPanelComponent, [i0.ChangeDetectorRef, i17.AppInsightsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SecurityRecommendationEntityPanelComponentNgFactory = i0.ɵccf("security-recommendation-entity-panel", i16.SecurityRecommendationEntityPanelComponent, View_SecurityRecommendationEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { SecurityRecommendationEntityPanelComponentNgFactory as SecurityRecommendationEntityPanelComponentNgFactory };
