import { SevilleModule } from '../../seville/seville.module';
import '../services/seville.threatintel.demoTenantResolver';
import { RegExpService } from '@wcd/shared';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { mapNetworkEventTypeToMessage } from './common/timelineeventcommon';
import { breadcrumbsStateService } from '@wcd/shared';
import { Feature } from '@wcd/config';
import { IpsService } from '../../../../@entities/ips/services/ips.service';
import { filter } from 'rxjs/operators';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';

SevilleModule.controller('seville.threatintel.ip', ipController);

SevilleModule.config([
	'$stateProvider',
	'searchProvider',
	function($stateProvider, searchProvider) {
		var ipRegex = /^(?!0)(?!.*\.$)((1?\d?\d|25[0-5]|2[0-4]\d)(\.|$)){4}$/;
		var ipv6Regex = /\b((([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])))\b/;

		$stateProvider.state('ip', {
			url: '/ip/:id/:time',
			params: {
				time: {
					value: null,
					squash: true,
				},
			},
			showBreadcrumbs: true,
			featureFlagToggleFeatureId: Feature.UpgradeIpPage,
			showToggleFeatureId: Feature.ShowUpgradeIpPageToggle,
			title: 'IP',
			data: { entity: null },
			views: {
				main: {
					template: `
				<div ng-if="ip.main" data-track-component="IpPage">
					<div ng-if="ip.showDeprecatedMessage"
						class="wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal ms-background-color-themeLight"
						style="height:50px; margin: 0 -12px;">
						<i class="icon icon-Info wcd-margin-small-right"></i>
						<div class="message-bar-text wcd-flex-1">
							<span>{{ip.deprecatedMessageText}}</span>
							<a ng-click="ip.turnOnNewIpPage()">Take me to the new ip page!</a>
						</div>
					</div>
                <div class="row entity-title">
                    IP worldwide
                </div>
                <div class="row entity">
                    <!-- Main -->
                    <div class="col-sm-12 entity-cube">
                        <div class="border-default inner-box" style="min-height:0">
                            <div>
                                <i class="icon icon-md icon-Streaming"></i>
                                <span class="entity-meta-main-title">IP</span>
                            </div>
                            <div>
                                <div class="entity-meta-main-sub">{{ ip.main.Id || ip.ipAddress || 'IP Address'}}</div>
                                <div ng-if="ip.main.Asn > 0">ASN: {{ip.main.Asn}}</div>
                                <div ng-if="ip.main.City">City: <span class="text-capitalize">{{ip.main.City}}</span></div>
                                <div ng-if="ip.main.State">State: <span class="text-capitalize">{{ip.main.State}}</span></div>
                                <div ng-if="ip.main.Country">Country: <span class="text-capitalize">{{ip.main.Country}}</span></div>
                                <div ng-if="ip.main.Organization">Organization: <span class="text-uppercase">{{ip.main.Organization}}</span></div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <!-- Associated Alerts -->
                        <associated-ioas entity="'IP'" ip="ip.main.Id" refresh-investigation-states="true"></associated-ioas>

                        <div class="row entity-title entity-section">
                            IP in organization
                        </div>

                        <div class="row">
                            <div>
                                Filter by:
                                <div class="dropdown inline" uib-dropdown>
                                    <button class="btn btn-link dropdown-toggle alerts-btn" uib-dropdown-toggle type="button" data-toggle="dropdown" aria-expanded="true">
                                        <span>
                                            <span ng-if="ip.telemetryLookback == 180">6 months</span>
                                            <span ng-if="ip.telemetryLookback == 1">1 day</span>
                                            <span ng-if="ip.telemetryLookback != 180 && ip.telemetryLookback > 1">
                                                {{ip.telemetryLookback}} days
                                            </span>
                                        </span>
                                        <span class="icon icon-sm icon-ChevronDown"></span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-shadow dropdown-margin">
                                        <li ng-class="{'dropdown-selected' : ip.telemetryLookback == '1'}">\<a href="#"
											data-track-id="TelemetryLookback1d"
											data-track-type="Selection"
											ng-model="ip.telemetryLookback" uib-btn-radio="'1'">1 day</a></li>
                                        <li ng-class="{'dropdown-selected' : ip.telemetryLookback == '3'}"><a href="#"
                                        	data-track-id="TelemetryLookback3d"
											data-track-type="Selection"
                                        	ng-model="ip.telemetryLookback" uib-btn-radio="'3'">3 days</a></li>
                                        <li ng-class="{'dropdown-selected' : ip.telemetryLookback == '7'}"><a href="#"
                                        	data-track-id="TelemetryLookback7d"
											data-track-type="Selection"
                                        	ng-model="ip.telemetryLookback" uib-btn-radio="'7'">7 days</a></li>
                                        <li ng-class="{'dropdown-selected' : ip.telemetryLookback == '30'}"><a href="#"
                                        	data-track-id="TelemetryLookback30d"
											data-track-type="Selection"
                                        	ng-model="ip.telemetryLookback" uib-btn-radio="'30'">30 days</a></li>
                                        <li ng-class="{'dropdown-selected' : ip.telemetryLookback == '180'}"><a href="#"
                                        	data-track-id="TelemetryLookback180d"
											data-track-type="Selection"
                                        	ng-model="ip.telemetryLookback" uib-btn-radio="'180'">6 months</a></li>
                                    </ul>
                                </div>
                            </div>

                            <!--Prevalence-->
                            <prevalence-cube loaded="ip.prevalenceLoaded" class="entity-cube col-sm-12"
                                             org="true"
                                             prevalence="ip.orgPrevalence"
                                             first-seen="ip.orgFirstseen"
                                             lookback="ip.telemetryLookback"
                                             last-seen="ip.orgLastseen">
                            </prevalence-cube>
                        </div>
                    </div>
                </div>

    			<!-- Date picker & time bar -->
    			<div ng-class=":: {'entity-toolbox': ip.exportTimelineToCsvEnabled, 'row': !ip.exportTimelineToCsvEnabled}" class="wcd-margin-large-top">
        			<timeline-datepicker from-date="ip.fromDate" to-date="ip.toDate" load-timeline="ip.loadIpTimeline()" start-date="ip.telemetryFromDate" end-date="ip.telemetryToDate" mask-results="ip.maskResults" in-cold-storage="ip.inColdStorage" picker-title="'Most recent observed devices with IP from:'" cold-storage-enabled="true"></timeline-datepicker>
        			<timeline-timebar from-date="ip.fromDate" to-date="ip.toDate"></timeline-timebar>
    			</div>
    			<div ng-if="::ip.exportTimelineToCsvEnabled" class="entity-toolbox entity-toolbar">
        			<button ng-click="ip.openExportToCsvDialog()" class="entity-toolbox-button entity-toolbar-button pull-right">
            			<i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
        			</button>
    			</div>

    			<div class="row table-header event-timeline-columnsheader">
                    <div class="col-md-5 col-md-offset-1 user-timeline-title-header">Device</div>
                    <div class="col-md-5 col-md-offset-1 user-timeline-title-header user-timeline-title-header-description">Description</div>
                </div>
                <!-- Ip grid -->
                <timeline-grid loading="ip.loading" partial-results-loaded="ip.partialResultsLoaded" events="ip.events" records-per-page="ip.telemetryPageSize" mask-results="ip.maskResults" in-cold-storage="ip.inColdStorage" load-timeline="ip.loadIpTimeline()" entityname="ip.ipAddress"></timeline-grid>
            </div>
						`,
					controller: 'seville.threatintel.ip',
					controllerAs: 'ip',
				},
			},
		});

		searchProvider.registerEntitySearchProvider(
			'IP',
			function(searchTerm) {
				return ipRegex.test(searchTerm) || ipv6Regex.test(searchTerm);
			},
			function(searchTerm, $state) {
				$state.go('ip', { id: searchTerm }, { reload: true });
			}
		);
	},
]);

ipController.$inject = [
	'$scope',
	'$log',
	'$http',
	'$state',
	'$filter',
	'$stateParams',
	'$q',
	'urlMapping',
	'investigationService',
	'demoTenantResolverService',
	'coldStorage',
	'coldStorageConsts',
	'progressiveTimelineLoader',
	'featuresService',
	'exportToCsvService',
	'appInsights',
	'ng2router',
	'ipsService',
];

function ipController(
	$scope,
	$log,
	$http,
	$state,
	$filter,
	$stateParams,
	$q,
	urlMapping,
	investigation,
	demoTenantResolverService,
	coldStorage,
	coldStorageConsts,
	progressiveTimelineLoader,
	featuresService,
	exportToCsvService,
	appInsights: AppInsightsService,
	ng2router,
	ipsService: IpsService
) {
	var vm = this;
	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	// handle old links: if url is of old ip page but "upgrade ip page" flag is on, redirect to new ip page
	if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
		goToNewIpPage();
		return;
	} else {
		if (
			featuresService._defaultFeatures.has(Feature.UpgradeIpPage) &&
			!featuresService._defaultFeatures.has(Feature.ShowUpgradeIpPageToggle) &&
			!featuresService.getLocalFeatureValue(Feature.UpgradeIpPage)
		) {
			featuresService.clearLocalFeatureValue(Feature.ShowUpgradeIpPageToggle);
			featuresService.clearLocalFeatureValue(Feature.UpgradeIpPage);
			goToNewIpPage();
			return;
		}
	}

	vm.loading = 0;
	vm.partialResultsLoaded = false;
	vm.ipAddress = $stateParams.id;
	if ($state.data && $state.data.eventTime) {
		vm.ipEventTime = $state.data.eventTime;
		delete $state.data.eventTime;
	}
	vm.telemetryLookback = '30';
	vm.telemetryPageSize = 100;
	var lookback = vm.isDemoTenant
		? demoTenantResolverService.getStatsLookback()
		: coldStorageConsts.IP_TIMELINE_LOOKBACK;
	vm.telemetryToDate = $stateParams.time ? new Date($stateParams.time) : new Date();
	vm.telemetryFromDate = coldStorage.overrideStartDateIfBeyondHotData(vm.telemetryToDate, lookback);
	vm.events = [];
	vm.isDemoTenant = demoTenantResolverService.isDemoTenant();
	vm.maskResults = false;
	vm.inColdStorage = false;
	var isoStringFilter = $filter('isoString');
	vm.exportTimelineToCsvEnabled = featuresService.isEnabled('ExportEntityTimelinesToCsv');
	vm.showDeprecatedMessage = featuresService.isEnabled(Feature.ShowDeprecatedIpPageMessage);

	const privatePreviewDisableDate = new Date(Date.UTC(2019, 7, 25, 23, 59, 59)); // 25.8.2019 23:59:59
	const disableDate = new Date() < privatePreviewDisableDate ? 'August 25' : 'September 1';
	vm.deprecatedMessageText = `Please note that this view will be unavailable starting ${disableDate}, 2019.`;

	loadEntity();

	$scope.$watch(
		function() {
			return vm.telemetryLookback;
		},
		function() {
			loadIpStats();
		}
	);

	// listen to "OptIn" feature toggle and navigate to new ip page
	vm.featuresChangeSubscription = featuresService.featureChanged$
		.pipe(filter(({ featureId }) => featureId === Feature.UpgradeIpPage))
		.subscribe((featureId: string) => {
			if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
				goToNewIpPage();
			}
		});

	$scope.$on('$destroy', () => {
		vm.featuresChangeSubscription && vm.featuresChangeSubscription.unsubscribe();
	});

	vm.turnOnNewIpPage = function() {
		appInsights.trackEvent('UI', {
			type: TrackingEventType.Action,
			id: 'turnOnNewIpPageFromDeprecatedPageMessage',
			component: 'OldIpPage',
		});
		featuresService.setLocalFeatureValue(Feature.UpgradeIpPage, true);
	};

	function loadEntity() {
		if ($state.current.data && $state.current.data.entity) {
			$log.debug('Entity is already loaded in memory');
			var data = $state.current.data.entity;
			updateInvestigationFlow(data);
			vm.main = data;
			delete $state.current.data.entity;
		} else {
			$log.debug('loading ip information for ip: ' + vm.ipAddress);
			var geoLocationLookback = demoTenantResolverService.isDemoTenant()
				? demoTenantResolverService.demoTenantLookback
				: 30;
			$http
				.get(urlMapping.getThreatIntelUrl() + '/GeoLocation', {
					params: {
						ip: vm.ipAddress,
						lookbackInDays: geoLocationLookback,
						eventTime: vm.ipEventTime,
					},
				})
				.then(
					function(response) {
						if (response.status === 200) {
							$log.debug('entity loaded successfully');
							vm.main = response.data;
							updateInvestigationFlow(response.data);
							vm.loadIpTimeline();
						} else if (response.status === 404 || response.status === 400) {
							ng2router.navigate(['/errors/IPNotFound'], {
								queryParams: { itemId: vm.ipAddress },
							});
						}
					},
					function(response) {
						delete vm.main;
						$log.debug('entity loading failed');
					}
				);
		}
	}

	function loadIpStats() {
		vm.orgFirstseen = null;
		vm.orgLastseen = null;
		vm.orgPrevalence = null;
		vm.prevalenceLoaded = false;
		var lookback = demoTenantResolverService.isDemoTenant()
			? demoTenantResolverService.demoTenantLookback
			: vm.telemetryLookback;
		$log.debug('loading first & last seen, for ip with id=' + vm.ipAddress);
		$http
			.get(urlMapping.getThreatIntelUrl() + '/IpStats', {
				params: {
					ip: vm.ipAddress,
					lookingBackIndays: lookback,
				},
			})
			.then(
				function(response) {
					if (response.status === 200) {
						$log.debug(
							'loading ip stats for ip with id=' + vm.ipAddress + ' loaded successfully'
						);
						var data = response.data;
						if (data) {
							vm.orgFirstseen = data.OrgFirstSeen;
							vm.orgLastseen = data.OrgLastSeen;
							vm.orgPrevalence = data.OrgPrevalence ? parseInt(data.OrgPrevalence) : 0;

							vm.prevalenceLoaded = true;
						}
					}
				},
				function(response) {
					$log.error('Error occur while loading first & last seen information');
				}
			);
	}

	vm.loadIpTimeline = function() {
		if (!vm.ipAddress || !vm.telemetryToDate) {
			return;
		}

		progressiveTimelineLoader.loadTimelineProgressively(
			loadTimelineEvents,
			vm.telemetryFromDate,
			vm.telemetryToDate,
			vm.telemetryPageSize,
			function(newEvents, isPartialResultsLoaded) {
				vm.events = newEvents;
				vm.partialResultsLoaded = isPartialResultsLoaded;
			},
			function(record) {
				return record.machineId || record.MachineId;
			}
		);
	};

	function loadTimelineEvents(fromDate, toDate) {
		return $q(function(resolve, reject) {
			if (!vm.ipAddress || !vm.telemetryToDate) {
				return reject();
			}

			vm.loading++;
			var ip = vm.ipAddress;

			var url = urlMapping.getThreatIntelUrl() + '/IpTimeline';
			$log.debug('loading ip timeline, for ip=' + ip);
			$http
				.get(url, {
					params: {
						Ip: ip,
						fromDate: fromDate,
						toDate: toDate,
						pageSize: vm.telemetryPageSize,
						pageIndex: 1,
					},
				})
				.then(
					function(response) {
						if (response.status == 200) {
							var data = response.data;
							if (!data || !data.Items) {
								$log.error('Error occur while loading events timeline');

								vm.loading--;
								return;
							}
							$log.debug('ip timeline events loaded successfully');

							var newEvents = [];
							for (var i = 0; i < data.Items.length; i++) {
								var record = data.Items[i];
								var files = {
									TotalResults: 1,
									Items: [],
								};

								if (!record.Sha1 || !RegExpService.sha1.test(record.Sha1)) {
									record.Sha1 = 'Hash could not be retrieved';
									record.invalidHash = true;
								}

								files.Items.push({
									Sha1: record.Sha1,
									Path: record.RealPath,
									Name: record.FileName,
									Valid: !record.invalidHash,
									Signer: record.Signer,
								});

								const description = mapNetworkEventTypeToMessage(record.NetworkEventType);

								var item = {
									time: record.Timestamp,
									sort: $filter('date')(record.Timestamp, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
									desc: record.Description,
									wcdMachineId: record.WcdMachineId,
									machineId: record.MachineId,
									filename: record.FileName,
									sha1: record.Sha1,
									realPath: record.RealPath,
									signer: record.Signer,
									isValidCertificate: record.IsValidCertificate,
									computerDnsName: record.ComputerDnsName,
									type: 'Machine',
									files: files,
									valid: !record.invalidHash,
									json: record,
									isoTime: isoStringFilter(record.Timestamp),
									destinationPort: record.DestinationPort,
									destinationDnsRecordName: record.DestinationDnsRecordName,
									containerId: record.ContainerId,
									message: description,
								};

								newEvents.push(item);
							}

							resolve(newEvents);
						}

						vm.loading--;
					},
					function(response) {
						$log.error('Error occured while loading ip timeline events');
						vm.loading--;
						return reject();
					}
				);
		});
	}

	vm.openExportToCsvDialog = function() {
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'ExportToCsv',
			Page: 'IP',
			Component: 'IPExportToCsv',
		});
		return exportToCsvService.openDialog({
			title: 'Export IP organizational footprint',
			maxDays: 30,
			maxDaysInColdStorage: 7,
			timelineDate: vm.telemetryToDate,
			initialStartDate: vm.telemetryFromDate,
			initialEndDate: vm.telemetryToDate,
			httpRequestParams: { ip: vm.ipAddress },
			checkResultsUrl: '/LookForIpEventsToExport',
			getAuthTokenUrl: '/GetAuthToken?exportUrl=DownloadIpTimelineToCsv',
			downloadUrl: '/DownloadIpTimelineToCsv',
		});
	};

	function updateInvestigationFlow(data) {
		if (!data || data.length <= 1) {
			data = {};
		}

		data.icon = 'icon-Streaming';
		data.displaystate = vm.ipAddress || 'IP';
		data.state = 'IP';
		investigation.setActive(data);
		breadcrumbsStateService.add({
			id: `ip_${data.Id}`,
			label: data.displaystate,
			url: $state.href($state.current),
			queryParams: $state.params,
		});
	}

	function goToNewIpPage() {
		const alertTime = $state.params.time && new Date($state.params.time);
		ng2router.navigate([
			ipsService.getIpLink((vm.main && vm.main.ipAddress) || $state.params.id, alertTime),
		]);
	}
}
