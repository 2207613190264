import {
	ChangeDetectorRef,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	Input,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
	ChangeDetectionStrategy,
} from '@angular/core';
import { get } from 'lodash-es';
import { DataTableField, DataTableFieldComponent } from '../models/datatable-field.model';
import { WcdIconNames } from '@wcd/icons';
import { DataTableFieldIcon } from '../models/datatable-icon.interface';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { Params, Router } from '@angular/router';
import { AppContextService } from '@wcd/config';

const DEFAULT_ICON_CLASSNAME = 'field-value-icon';

@Component({
	selector: 'wcd-datatable-field-value',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './datatable-field-value.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./datatable-field-value.component.scss'],
})
export class DataTableFieldValueComponent<TData = any, TComponent = any>
	implements OnChanges<DataTableFieldValueComponent<TData>> {
	@Input() field: DataTableField<TData>;
	@Input() value: TData;
	@Input() allowLink = true;
	@Input() shortDisplay = false;
	@Input()
	set refreshOn(refreshOn) {
		this.updateValue();
	}

	display: string;
	isDisplayError: boolean;
	visible = false;
	fabricIconName: string;
	wcdIconName: WcdIconNames;
	iconCssClass: string;
	imageSrc: string;
	link: string | Array<string>;
	linkQueryParams: Params;
	listItems: Array<any>;
	customFields: Array<any>;
	tooltip: string;
	helpText: string;

	get itemValue(): any {
		return (
			(this.field.itemProperty && get(this.value, this.field.itemProperty)) || this.value[this.field.id]
		);
	}

	constructor(
		private appContext: AppContextService,
		private router: Router
	) {}

	ngOnChanges(changes: TypedChanges<DataTableFieldValueComponent<TData>>) {
		if ((changes.field || changes.value) && this.field && this.value) {
			this.updateValue();
		}
	}

	private updateValue() {
		try {
			this.display = this.field.display(this.value);
		} catch (e) {
			this.isDisplayError = true;
			e.message = `Field '${this.field.id}' display parsing error: ${e.message}`;
			console.error(e);
		}

		this.setIcon();

		this.imageSrc = this.field.getImage && this.field.getImage(this.value);

		this.link = this.allowLink && this.field.getLink && this.field.getLink(this.value);
		if (this.link && this.field.getLinkQueryParams) {
			this.linkQueryParams = this.field.getLinkQueryParams(this.value);
		}

		if (this.field.listValues) {
			if (this.field.listValues.data) {
				this.listItems = this.field.listValues.data(this.value);
			} else if (this.field.listValues.path) {
				this.listItems = <Array<any>>get(this.value, this.field.listValues.path);
			}
		}

		this.customFields =
			this.field.custom && this.field.custom.fields
				? this.field.custom.fields.filter(field => !!this.value[this.field.id][field.id]) // show rows that has a value only
				: [];
		this.tooltip = (this.field.getTooltip && this.field.getTooltip(this.value)) || this.field.tooltip || this.field.display(this.value);
		this.helpText = this.field.help && this.field.help(this.value);
	}

	private setIcon() {
		const icon: DataTableFieldIcon<TData> = this.field.icon;
		if (!icon) return;

		if (icon.fabricIcon) {
			this.fabricIconName =
				icon.fabricIcon instanceof Function ? icon.fabricIcon(this.value) : icon.fabricIcon;
		}

		if (icon.wcdIcon) {
			this.wcdIconName = icon.wcdIcon instanceof Function ? icon.wcdIcon(this.value) : icon.wcdIcon;
		}

		this.iconCssClass =
			icon.className &&
			(icon.className instanceof Function ? icon.className(this.value) : icon.className);
		if (this.iconCssClass) this.iconCssClass = `field-value-icon-base ${this.iconCssClass}`;
		else this.iconCssClass = DEFAULT_ICON_CLASSNAME;
	}

	getUseCustomComponent(item: TData): boolean {
		const customComponent = this.getCustomComponent(item);
		return customComponent !== undefined;
	}

	private getCustomComponent(item: TData): DataTableFieldComponent<TData, any> {
		return (
			(this.field.useDefaultEmptyFieldComponent &&
				this.field.getIsEmptyField(item) &&
				this.field.defaultEmptyComponent) ||
			(this.field.getDynamicComponent && this.field.getDynamicComponent(item)) ||
			this.field.component
		);
	}
}
