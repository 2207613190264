import { FabIconModule, FabSpinnerModule, FabTextFieldModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ConfirmationDialogModule, TooltipsModule } from '@wcd/dialogs';
import { ExpanderModule } from '@wcd/expander';
import { QueriesListComponent } from './components/queries-list/queries-list.component';
import { QueriesListTreeComponent } from './components/queries-list-tree/queries-list-tree.component';
import { ContentsStateModule } from '@wcd/contents-state';
import { HuntingSharedModule } from '../shared/hunting-shared.module';
import { ScheduledHuntingErrorParsingService } from './services/scheduled-hunting.error-parsing.service';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FabSpinnerModule,
		FabIconModule,
		FabTextFieldModule,
		ExpanderModule,
		PopupModule,
		TooltipsModule,
		ConfirmationDialogModule,
		ContentsStateModule,
		HuntingSharedModule,
		I18nModule,
	],
	declarations: [QueriesListTreeComponent, QueriesListComponent],
	providers: [ScheduledHuntingErrorParsingService],
	exports: [QueriesListComponent, QueriesListTreeComponent],
})
export class HuntingQueriesModule {}
