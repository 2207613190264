
		<modal (close)="destroy()" [settings]="settings">
			<form
				#disableRuleConfirmForm="ngForm"
				class="wcd-full-height wcd-flex-vertical"
				(submit)="submitDisable()"
				data-track-component="DisableSuppressionRuleConfirm"
			>
				<div class="wcd-flex-1 dialog-contents">
					<p>
						{{ 'suppressionRules.disable.description' | i18n }}
					</p>
					<div class="form-group">
						<wcd-radiolist
							[values]="selectableActions"
							name="restore-alerts-action"
							[(ngModel)]="restoreAlerts"
						></wcd-radiolist>
					</div>
					<div class="form-group">
						<textarea
							required
							rows="5"
							name="confirm-text"
							placeholder="Please add a comment"
							class="form-control wcd-full-width"
							[(ngModel)]="confirmText"
						></textarea>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none">
					<button
						type="submit"
						data-track-id="DisableRuleConfirm"
						data-track-type="Button"
						[disabled]="
							disableRuleConfirmForm.form.pristine ||
							!disableRuleConfirmForm.form.valid ||
							!isValid
						"
						class="btn btn-primary wcd-margin-right"
					>
						{{ confirmBtn }}
					</button>
					<button
						type="button"
						data-track-id="Cancel"
						data-track-type="Button"
						(click)="close()"
						class="btn btn-cancel"
					>
						{{ 'buttons.cancel' | i18n }}
					</button>
				</footer>
			</form>
		</modal>
	