import { SevilleUtils } from '../../common/services/seville.utils';
import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { Feature } from '@wcd/config';
import { AuthService } from '@wcd/auth';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { IHttpService } from 'angular';

SevilleModule.controller('seville.actioncenter.file', fileActionCenterController);

fileActionCenterController.$inject = [
	'$log',
	'$scope',
	'$http',
	'entity',
	'appConfig',
	'featuresService',
	'$uibModal',
	'$window',
	'appInsights',
	'authService',
	'blackListService',
];

function fileActionCenterController(
	$log,
	$scope,
	$http: IHttpService,
	entity,
	appConfig,
	featuresService,
	$modal,
	$window,
	appInsights: AppInsightsService,
	authService: AuthService,
	blackListService
) {
	var vm = this;
	vm.file = entity;

	vm.rbacMachineGroupsEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
	vm.isAdminUser = authService.currentUser.isMdeAdmin;

	loadKillAndQuarentineStatus();

	if (featuresService.isEnabled(Feature.ResponseBlacklist)) {
		vm.showBlackList = true;
		loadBlackListStatus();
	} else {
		$log.debug('Action center will not show blacklist, since tenant is not flighting the feature');
	}

	vm.hideResults = function() {
		vm.showActionResults = !vm.showActionResults;
	};

	function loadKillAndQuarentineStatus() {
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'ActionCenter',
			Page: 'File',
			Component: 'ActionsButton',
		});
		var url = appConfig.serviceUrls.userRequests + '/remediation/latestforfile';
		var latestforfilePromise = $http.get(url, {
			params: {
				FileIdentifierType: 0,
				FileIdentifier: vm.file.Sha1,
			},
		});

		vm.loadingKillAndQuarentine = true;

		handleKillAndQuarentineStatusResponse(latestforfilePromise);
	}

	function downloadStatusCsv() {
		var url = appConfig.serviceUrls.userRequests + '/remediation/getRemediationStatusCsvUri';

		$http
			.get(url, {
				params: {
					FileIdentifier: vm.file.Sha1,
					FileIdentifierType: 'Sha1',
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						var csvDownloadUrl = response.data;
						$window.open(csvDownloadUrl, '_blank');
					} else {
						$log.error(
							response.status +
								' - Error occur while tried to download remediation devices status'
						);
					}
				},
				function(response) {
					$log.error(
						response.status + ' - Error occur while tried to download remediation devices status'
					);
				}
			);
	}

	function handleKillAndQuarentineStatusResponse(postResponsePromise, openErrorDialog?) {
		postResponsePromise.then(
			function(response) {
				if (response.status == 200) {
					if (response.data) {
						vm.killAndQuarentine = arrangeRemediationResults(response.data);
						vm.killAndQuarentine.cancel = cancelRemediation;
						vm.killAndQuarentine.DisplayCancelButton =
							vm.killAndQuarentine.pendingNotRecievedRequest &&
							vm.file.QuarantineStatus != 'Readonly';
						vm.killAndQuarentine.downloadStatusCsv = downloadStatusCsv;

						if (vm.killAndQuarentine.pending == 0) {
							vm.file.QuarantineStatus = 'Available';
						}
					}
				} else {
					$log.debug('remediation status loading failed');

					if (openErrorDialog == true) {
						$modal.open({
							animation: $scope.animationsEnabled,
							template: ERROR_POPUP_TEMPLATE,
							size: 'md',
							windowClass: 'machine-actions-modal',
							backdrop: false,
						});
					}
				}

				vm.loadingKillAndQuarentine = false;
			},
			function(response) {
				$log.debug('remediation status loading failed');
				vm.loadingKillAndQuarentine = false;
			}
		);
	}

	function loadBlackListStatus() {
		var url = appConfig.serviceUrls.userRequests + '/bannedfiles/rbac/latest';
		vm.loadingBlackList = true;

		var latestPromise = $http.post(url, {
			fileIdentifier: vm.file.Sha1,
		});

		handleBlockStatusResponse(latestPromise);
	}

	function handleBlockStatusResponse(postResponsePromise, invokedFromCancelation?) {
		postResponsePromise.then(
			function(response) {
				if (response.status == 200) {
					if (response.data && response.data.length > 0) {
						const blockedFiles = response.data;

						const isBlockedByAdmin: boolean = blockedFiles.some(
							blockedFile => blockedFile.RbacGroupId === 0 && !blockedFile.IsDeleted
						);
						const actionStatus: string = blackListService.resolveBannedFilesStatus(
							blockedFiles,
							vm.isAdminUser,
							vm.rbacMachineGroupsEnabled
						);
						const isDeleted: boolean =
							actionStatus !== 'Blocked' && actionStatus !== 'BlockedByAdmin';

						vm.blacklist = {
							CreationDateTimeUtc: blockedFiles[0].CreationTime,
							Requestor: blockedFiles[0].CreatedBy,
							RequestorComment: blockedFiles[0].Comment,
							LastUpdateTimeUtc: blockedFiles[0].LastUpdateTime,
							ActionStatus:
								actionStatus !== 'BlockedByAdmin'
									? actionStatus
									: 'Blocked by a global admin',
							CancellationDateTimeUtc: blockedFiles[0].CancellationTime,
							CancellationRequestor: blockedFiles[0].CancellationRequestor,
							CancellationComment: blockedFiles[0].CancellationComment,
							DisplayCancelButton:
								!isDeleted &&
								vm.file.BlacklistStatus != 'Readonly' &&
								(vm.isAdminUser || (!vm.isAdminUser && !isBlockedByAdmin)),
							cancel: unblockFile,
						};

						if (invokedFromCancelation == true) {
							vm.file.BlacklistStatus = 'Unblocked';
						}
					}
				} else {
					$log.debug('blacklist status loading failed');

					if (invokedFromCancelation == true) {
						$modal.open({
							animation: $scope.animationsEnabled,
							template: ERROR_POPUP_TEMPLATE,
							size: 'md',
							windowClass: 'machine-actions-modal',
							backdrop: false,
						});
					}
				}
				vm.loadingBlackList = false;
			},
			function(response) {
				$log.debug('blacklist status loading failed');
				vm.BlackListError = 'Data could not be retrieved.';
				vm.loadingBlackList = false;
			}
		);
	}

	function arrangeRemediationResults(results) {
		var responseResults: any = {};

		if (results.length > 0) {
			responseResults.CreationDateTimeUtc = results[0].CreationDateTimeUtc;
			responseResults.Requestor = results[0].Requestor;
			responseResults.RequestorComment = results[0].RequestorComment;
			var groupedResults = SevilleUtils.groupBy(results, function(item) {
				return [item.RemediationStatus];
			});

			// Cluster machines by statuses
			responseResults.groupedResults = groupedResults;
			responseResults.pendingNotRecievedRequestMachines = getStatusMachineList(
				'ReceivedByServer',
				groupedResults,
				false
			);
			responseResults.failedMachines = getStatusMachineList('Failed', groupedResults, true);
			responseResults.successMachines = getStatusMachineList('Succeeded', groupedResults, false).concat(
				getStatusMachineList('SucceededPendingReboot', groupedResults, false)
			);
			responseResults.pendingMachines = responseResults.pendingNotRecievedRequestMachines.concat(
				getStatusMachineList('ReceivedByClient', groupedResults, false)
			);

			// If there are machines where remediation was canceled, update Cancel details
			responseResults.canceledMachines = getStatusMachineList('Cancelled', groupedResults, false);
			if (responseResults.canceledMachines.length > 0) {
				responseResults.CancellationDateTimeUtc =
					responseResults.canceledMachines[0].CancellationDateTimeUtc;
				responseResults.CancellationComment = responseResults.canceledMachines[0].CancellationComment;
				responseResults.CancellationRequestor =
					responseResults.canceledMachines[0].CancellationRequestor;
			}

			responseResults.canceledMachines = responseResults.canceledMachines.concat(
				responseResults.pendingMachines.concat()
			);

			// Count each cluster to display it later
			responseResults.failures = responseResults.failedMachines.length;
			responseResults.successes = responseResults.successMachines.length;
			responseResults.pending = responseResults.pendingMachines.length;
			responseResults.pendingNotRecievedRequest =
				responseResults.pendingNotRecievedRequestMachines.length;
			responseResults.canceled = responseResults.canceledMachines.length;

			return responseResults;
		}

		return responseResults;
	}

	function getStatusMachineList(status, results, isFailureStatus) {
		for (var i = 0; i < results.length; i++) {
			if (results[i][0].RemediationStatus == status) {
				if (!isFailureStatus) {
					return results[i];
				}
				return getFailedMachinesList(results[i]);
			}
		}

		return [];
	}

	// retrieve only failed instances from the remediation instances list - since failue status can have both success and failute instances.
	function getFailedMachinesList(results) {
		for (var i = 0; i < results.length; i++) {
			var instances = [];
			if (results[i].RemediationInstances != null) {
				for (var j = 0; j < results[i].RemediationInstances.length; j++) {
					if (results[i].RemediationInstances[j].RemediationStatus == 1) {
						instances = instances.concat(results[i].RemediationInstances[j]);
					}
				}
				results[i].RemediationInstances = instances;
			}
		}

		return results;
	}

	function cancelRemediation() {
		if ($scope.posting) return;

		$log.debug('Canceling Remediation...');
		$scope.posting = true;

		var modalInstance = $modal.open({
			animation: $scope.animationsEnabled,
			template: CANCEL_CONFIRMATION_TEMPLATE,
			windowClass: 'machine-actions-modal',
			controller: 'seville.actioncenter.response.cancel.confirmation',
			backdrop: false,
			resolve: {
				entity: function() {
					return vm.file;
				},
				action: function() {
					return 'stop and quarantine';
				},
				openActionCenterPanel: function() {
					return false;
				},
			},
		});

		modalInstance.result.then(
			function(result) {
				if (result == true) {
					vm.loadingKillAndQuarentine = true;

					var cancelPromise = $http.post(
						appConfig.serviceUrls.userRequests + '/remediation/cancel',
						{
							FileIdentifierType: 'Sha1',
							FileIdentifier: vm.file.Sha1,
							RequestorComment: vm.file.commentText,
						}
					);

					handleKillAndQuarentineStatusResponse(cancelPromise, true);
				}

				vm.file.commentText = '';
				$scope.posting = false;
			},
			function() {
				vm.file.commentText = '';
				$scope.posting = false;
			}
		);
	}

	function unblockFile() {
		if (vm.posting) return;

		$scope.posting = false;

		var modalInstance = $modal.open({
			animation: $scope.animationsEnabled,
			template: CANCEL_CONFIRMATION_TEMPLATE,
			windowClass: 'machine-actions-modal',
			controller: 'seville.actioncenter.response.cancel.confirmation',
			backdrop: false,
			resolve: {
				entity: function() {
					return vm.file;
				},
				action: function() {
					return 'Unblock';
				},
				openActionCenterPanel: function() {
					return false;
				},
			},
		});

		modalInstance.result.then(
			function(result) {
				if (result == true) {
					vm.loadingBlackList = true;

					const rbacGroupsState = {};
					vm.file.machineGroupsIds.forEach(function(groupId) {
						rbacGroupsState[groupId] = false;
					});

					const unblockPromise = $http.post(
						appConfig.serviceUrls.userRequests + '/bannedfiles/rbac/upsert',
						{
							FileIdentifier: vm.file.Sha1,
							FileIdentifierType: 'Sha1',
							RequestorComment: vm.file.commentText,
							RbacGroupsState: rbacGroupsState,
							FileIdentifierSecondary: vm.file.Sha256,
							FileIdentifierTypeSecondary: 'Sha256',
						}
					);

					handleBlockStatusResponse(unblockPromise, true);
				}
				vm.file.commentText = '';
				$scope.posting = false;
			},
			function(response) {
				vm.file.commentText = '';
				$scope.posting = false;
			}
		);
	}
}

export const FILE_TEMPLATE: string = `
<div>
    <action-status results="actioncenterfile.killAndQuarentine" header="'Stop and Quarantine file'" loading="actioncenterfile.loadingKillAndQuarentine" summary="false" action-description="'Stop and Quarantine submitted'" cancel-action-description="'Stop and Quarantine canceled'" cancel-button-title="'Cancel action'"></action-status>
    <action-status results="actioncenterfile.blacklist" ng-if="actioncenterfile.showBlackList" header="'Block file'" loading="actioncenterfile.loadingBlackList" summary="true" action-description="'File blocked'" cancel-action-description="'File unblocked'" cancel-button-title="'Remove from block list'"></action-status>
</div>
<div class="action-center-bottom">
    <div class="action-center-info">
        <i class="icon icon-Info"></i>
        <span>For submitted actions to take effect, devices must be connected to the network.</span>
    </div>
    <button class="btn btn-primary primary-button" ng-click="$close()">Close</button>
</div>
`;

export const CANCEL_CONFIRMATION_TEMPLATE: string = `
<div class="container">
    <div class="row">
        <div class="col-md-12 nopadding response-cancel-confirmation-title">
            <div ng-if="::action!='Unblock'" class="response-cancel-confirmation-title-text">Cancel {{action}} action?</div>
            <div ng-if="::action=='Unblock'" class="response-cancel-confirmation-title-text">Remove file from blocked list?</div>
        </div>
    </div>
    <div class="row" ng-switch="::action">
        <div ng-switch-when="isolate" class="col-md-12 nopadding response-cancel-confirmation-text">
            Canceling isolate action will keep the device connected to the network.
        </div>
        <div ng-switch-when="release from isolation" class="col-md-12 nopadding response-cancel-confirmation-text">
            Canceling release action will keep the device isolated.
        </div>
        <div ng-switch-when="Unblock" class="col-md-12 nopadding response-cancel-confirmation-text">
            Removing the file from the blocked list will allow the file to run on devices in your organization.
        </div>
        <div ng-switch-when="antivirus scan" class="col-md-12 nopadding response-cancel-confirmation-text">
            If the scan has not started, it will be canceled.
        </div>
        <div ng-switch-when="app restriction" class="col-md-12 nopadding response-cancel-confirmation-text">
            Without app restrictions, applications that are not signed by Microsoft will be allowed to run.
        </div>
        <div ng-switch-when="removal of app restrictions" class="col-md-12 nopadding response-cancel-confirmation-text">
            Restrictions on apps that are not signed by Microsoft will continue to be enforced.
        </div>
        <div ng-switch-default class="col-md-12 nopadding response-cancel-confirmation-text">Canceling {{action}} action will prevent it from completing.</div>
    </div>
    <div class="row">
        <span class="response-confirmation-comments-text">Comment:<span class="response-confirmation-asterisk"> *</span></span>
        <textarea placeholder="Specify why you want to cancel this action" class="form-control response-confirmation-comments-box response-cancel-confirmation-comments-box" maxlength="1000" autofocus ng-model="entity.commentText"></textarea>
    </div>
    <div class="row">
        <div class="col-sm-12 text-right response-cancel-confirmation-buttons">
            <button class="btn btn-primary primary-button response-cancel-confirmation-btn" ng-disabled="entity.commentText=='' || entity.commentText==undefined" ng-click="ok()">Yes</button>
            <button class="btn btn-primary normal-button normal-primary-buttons-margin" ng-click="close()">No</button>
        </div>
    </div>
</div>
`;
