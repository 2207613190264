import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.preferences.optinpreview', onboardingOptInPreviewController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.optinpreview', {
			url: '/optinpreview',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Set up your preferences</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position">
                        <div>
                            <div class="page-header-title onboarding-title-margin">Preview experience</div>
                            <div>
                                This section allows you to turn preview features on/off.<br />
                                Turn on to be among the first to try upcoming features.<br />
                                It is turned on by default to allow you to experience the latest features as they become available.
                            </div>
                        </div>
                        <br />
                        <div class="ms-Toggle">
                            <input type="checkbox" id="toggle" class="order ms-Toggle-input" ng-model="optinpreview.optInPreview" ng-checked="optinpreview.optInPreview">
                            <label for="toggle" class="order ms-Toggle-field ob-common-toggle">
                            </label>
                            <div class="ms-Toggle-description ob-common-toggle-description-title">
                                <span class="ob-common-toggle-state">{{ optinpreview.optInPreview ? "On" : "Off" }}</span>
                                Preview features
                            </div>
                        </div>
                    </div>
                </div>
                <div class="onboarding-navigation">
                    <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-icon onboarding-paging-icon-back-margin" ng-click="optinpreview.MoveBack()"></i>
                    Back
                </div>
                <div class="onboarding-navigation onboarding-paging-icon-margin">
                    Next
                    <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin" ng-click="optinpreview.MoveNext()"></i>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.optinpreview',
					controllerAs: 'optinpreview',
				},
			},
		});
	},
]);

onboardingOptInPreviewController.$inject = [
	'$scope',
	'$state',
	'$http',
	'$timeout',
	'$interval',
	'$uibModal',
];

function onboardingOptInPreviewController($scope, $state, $http, $timeout, $interval, $modal) {
	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
			$scope.$parent.stepPreferencesInProgress = true;
		});

		vm.optInPreview = $scope.$parent.optInPreview;

		vm.MoveBack = function() {
			$scope.$parent.optInPreview = vm.optInPreview;
			$state.go('onboarding.industry');
		};
		vm.MoveNext = function() {
			$scope.$parent.optInPreview = vm.optInPreview;

			$modal.open({
				template: `
				<div class="onboarding-create-modal">
            <div class="page-header-title">
                Create your cloud instance
            </div>
            <br />
            <div>
                <table>
                    <tr>
                        <td class="icon icon-lg icon-Error onboarding-create-warning-padding"></td>
                        <td>
                            You won't be able to change some of your preferences (such as the data storage location) after clicking 'Continue'.
                            <br />
                            If you want to check or make any changes, click 'Back to preferences' and review your preferences. Click 'Continue' if you want to set up your account.
                        </td>
                    </tr>
                </table>
            </div>
            <br />
            <div class="col-xs-12">
                <div class="col-xs-2 col-xs-offset-3">
                    <button class="onboarding-create-button onboarding-button onboarding-button-enabled btn-block"
                            ng-click="create()">
                            Continue
                    </button>
                </div>
                <div class="col-xs-2 col-xs-offset-3">
                    <button
                            class="onboarding-create-button onboarding-button"
                            ng-click="backToSettings()">
                            Back to preferences
                    </button>
                </div>
            </div>
            <br />
            <br />
            <br />
        </div>
				`,
				controller: 'seville.onboarding.preferences.create',
				size: 'md',
			});
		};
	}
}
