import { SevilleModule } from '../../../seville/seville.module';
import '../../services/seville.threatintel.coldStorage';
import '../../services/seville.threatintel.filtersLocalStorage';
import { RegExpService } from '@wcd/shared';
import { SevilleUtils } from '../../../common/services/seville.utils';
import { LocaleConfigService } from '@wcd/localization';
import { config } from '@wcd/shared';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { OfficeIntegrationSettings } from '@wcd/domain';
import { OfficeIntegrationService } from '../../../../../admin/services/office-integration.service';
import { filter } from 'rxjs/operators';
import { Feature } from '@wcd/config';
import { IpsService } from '../../../../../@entities/ips/services/ips.service';
import { HybridRoutingService } from '../../../../../hybrid-routing.service';
import { UrlsService } from '../../../../../@entities/urls/services/urls.service';
import { FilesService } from '../../../../../@entities/files/services/files.service';

declare let angular: angular.IAngularStatic;
declare const moment: typeof import('moment');

SevilleModule.directive('eventsTimeline', eventsTimelineDirective);
SevilleModule.controller('seville.threatintel.eventsTimeline', eventsTimelineController);

eventsTimelineDirective.$inject = [];

function eventsTimelineDirective() {
	return {
		restrict: 'EA',
		template: `
		<div class="entity-meta" id="events-timeline-container">
        <!-- Filters -->
        <div class="event-timeline-header">
            <!--stick-on-scroll
            stick-on-scroll-container=".wrapper"
            stick-on-scroll-offset="80"-->
            <div class="entity-title event-timeline-title" ng-switch="eventsvm.type">
                <span ng-switch-when="MachineTimeline">Machine timeline</span>
                <span ng-switch-when="AlertTimeline">Artifact timeline</span>
            </div>

            <div class="noselect" id="event-timeline-toolbox" ng-if="::eventsvm.fullPagingExperience">
                <table class="toolbox-table">
                    <tr class="event-timeline-titles-row">
                        <td>Value</td>
                        <td>Information level</td>
                        <td>Event type</td>
                        <td>User account</td>
                        <td></td>
                    </tr>
                    <tr>
                        <td class="search-input-col">
                            <div class="has-feedback border-default events-timline-search-input pointer" ng-class="{'filter-dropdown-open': eventsvm.timlineFilterDropdownOpen}">
                                <input id="event-timeline-filter"
                                       class="form-control"
                                       type="text"
                                       ng-model="eventsvm.searchTerm"
                                       ng-click="eventsvm.openTypeahead()"
                                       placeholder="Search in device timeline"
                                       uib-typeahead="state as state.title for state in eventsvm.getSearchOptions($viewValue)"
                                       typeahead-template-url="eventsSearchSuggestionRecordTmpl.html"
                                       typeahead-show-hint="false"
                                       typeahead-min-length="0"
                                       typeahead-is-open="eventsvm.timlineFilterDropdownOpen"
                                       typeahead-select-on-exact="true"
                                       typeahead-on-select="eventsvm.onSearchOptionSelected($item, $model, $label, $event)"
                                       typeahead-should-select="eventsvm.searchShouldSelect($event)"
                                       ng-keydown="eventsvm.handleSearchKeyPress($event)">

                                <i class="icon icon-ChevronDown form-control-feedback event-timeline-filter-icon"
                                   ng-click="eventsvm.openTypeahead()"></i>
                                <i class="icon icon-Search form-control-feedback event-timeline-search-icon"
                                   ng-click="eventsvm.addSearchCriteria()"></i>
                            </div>
                        </td>
                        <td uib-dropdown on-toggle="eventsvm.closeTypeAhead(open)">
                            <button class="btn btn-link events-timeline-filter-btn"
                                    id="event-timeline-level-filter"
                                    uib-dropdown-toggle type="button" dropdown-append-to-body="true"
                                    data-toggle="dropdown" aria-expanded="true">
                                <span ng-if="eventsvm.fidelity == 'HiFi'">Detections</span>
                                <span ng-if="eventsvm.fidelity == 'Behaviors'">Behaviors</span>
                                <span ng-if="eventsvm.fidelity == 'All'">All</span>
                                <span class="icon icon-sm icon-ChevronDown event-timeline-filter-icon"></span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-shadow event-timeline-dropdownmenu">
                                <li ng-class="{'dropdown-selected' : eventsvm.fidelity == 'HiFi'}"><a href="#"
                                	data-track-id="FidelityDetections"
									data-track-type="Filter"
                                	ng-model="eventsvm.fidelity" uib-btn-radio="'HiFi'">Detections</a></li>
                                <li ng-class="{'dropdown-selected' : eventsvm.fidelity == 'Behaviors'}"><a href="#"
                                	data-track-id="FidelityBehaviors"
									data-track-type="Filter"
                                	ng-model="eventsvm.fidelity" uib-btn-radio="'Behaviors'">Behaviors</a></li>
                                <li ng-class="{'dropdown-selected' : eventsvm.fidelity == 'All'}"><a href="#"
                                	data-track-id="FidelityAll"
									data-track-type="Filter"
                                	ng-model="eventsvm.fidelity" uib-btn-radio="'All'">Verbose</a></li>
                            </ul>
                        </td>
                        <td>
                            <ng-dropdown-multiselect selected-model="eventsvm.eventTypeValues"
                                                     options="eventsvm.eventTypeOptions"
                                                     extra-settings="eventsvm.eventTypeSettings"
                                                     events="eventsvm.eventTypeEvents"
                                                     translation-texts="eventsvm.eventsUifiltertext"
                                                     alignment="left" />
                        </td>
                        <td>
                            <ng-dropdown-multiselect selected-model="eventsvm.userContextValues"
                                                     options="eventsvm.userContextOptions"
                                                     extra-settings="eventsvm.userContextSettings"
                                                     events="eventsvm.userContextEvents"
                                                     translation-texts="eventsvm.userUifiltertext"
                                                     alignment="left" />
                        </td>
                        <td>
                            <span ng-click="eventsvm.resetFilters(true)"
                                  class="pointer no-outline events-timeline-clearfilters-btn"
                                  ng-class="{'disabled': !eventsvm.filtersApplied}"
                                  tabindex="{{(!eventsvm.filtersApplied && -1) || (eventsvm.filtersApplied && 0)}}">
                                <i class="icon icon-Cancel"></i> Remove all filters
                            </span>
                        </td>
                    </tr>
                </table>
                <div class="event-timeline-toolbox-msg">
                    <table class="toolbox-table">
                        <tr>
                            <td id="event-timeline-search-input-error-msg">
                                {{eventsvm.searchErrorMsg}} {{eventsvm.resetMessage}}
                            </td>
                        </tr>
                    </table>
                </div>

                <div class="events-timeline-search-tags">
                    <span class="events-timeline-search-tags-msg" ng-if="eventsvm.searchTags && eventsvm.searchTags.length > 0">Events filtered by:</span>
                    <tags-cloud tags="eventsvm.searchTags" on-tag-removed="eventsvm.onSearchCriteriaTagRemoved"></tags-cloud>
                </div>

                <script type="text/ng-template" id="eventsSearchSuggestionRecordTmpl.html">
                    <div class="events-timeline-search-sugg-record" ng-class="{'events-timeline-search-sugg-disabled': match.model.selected}">
                        <table class="toolbox-table">
                            <tr>
                                <th class="events-timeline-search-sugg-title">{{match.model.title}}:</th>
                                <th class="events-timeline-search-sugg-desc">{{match.model.desc}}</th>
                            </tr>
                        </table>
                    </div>
                </script>

                <div class="slider-row" data-track-component="Timeline Slider" data-track-component-type="Date Selection">
                    <div class="row noselect">
                        <div class="col-sm-12 events-timeline-slider-container">
                            <!-- Show time picker if enabled -->
                            <rzslider ng-if="eventsvm.timePickerModel.isTimePickerEnabled"
                                      rz-slider-options="eventsvm.sliderOptions"
                                      rz-slider-model="eventsvm.sliderValue"
                                      data-track-id="eventsTimelineSliderEnabled"
                                      data-track-type="Filter"
                                      rz-slider-tpl-url="seville.threatintel.events.slidertemplate.html"></rzslider>
                            <!-- Otherwise show normal slider -->
                            <rzslider ng-if="!eventsvm.timePickerModel.isTimePickerEnabled"
                                      rz-slider-options="eventsvm.sliderOptions"
                                      data-track-id="eventsTimelineSliderDisabled"
                                      data-track-type="Filter"
                                      rz-slider-model="eventsvm.sliderValue"></rzslider>
                        </div>
                    </div>
                </div>

                <div class="machine-timeline-second-toolbox">
                    <button id="exportMachineTimelineToCsv"
						data-track-id="exportEvents"
						data-track-type="Export"
                          ng-click="eventsvm.openExportDialog(eventsvm.senseMachineId,eventsvm.computerDnsName)"
						  class="export-to-csv-open-dialog-button entity-toolbox-button no-outline">
              <i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i><span> Export </span>
                    </button>
                    <span class="btn-group">
                        <button class="btn btn-link dropdown-toggle"
                  uib-dropdown-toggle type="button" data-toggle="dropdown" aria-expanded="true">
                            <i class="icon icon-Settings"></i>{{eventsvm.recordsPerPage}} events per page
                <i class="icon icon-ChevronDown"></i>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-shadow">
                            <li ng-class="{'dropdown-selected' : eventsvm.recordsPerPage == 20}"><a href="#"
                            	data-track-id="RecordsPerPage20"
								data-track-type="Selection"
                            	ng-model="eventsvm.recordsPerPage" uib-btn-radio="20">20 events per page</a></li>
                            <li ng-class="{'dropdown-selected' : eventsvm.recordsPerPage == 50}"><a href="#"
                            	data-track-id="RecordsPerPage50"
								data-track-type="Selection"
                            	ng-model="eventsvm.recordsPerPage" uib-btn-radio="50">50 events per page</a></li>
                            <li ng-class="{'dropdown-selected' : eventsvm.recordsPerPage == 100}"><a href="#"
                            	data-track-id="RecordsPerPage50"
								data-track-type="Selection"
                            	ng-model="eventsvm.recordsPerPage" uib-btn-radio="100">100 events per page</a></li>
                        </ul>
                    </span>

                    <div class="timeline-paging-top-container inline" ng-include src="'/timeline-paging.html'">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row events-timeline-rows-container">
        <div class="col-sm-12">
            <!-- Timeline Header -->
            <div ng-switch="::eventsvm.type" class="hidden-sm hidden-xs">
                <div ng-switch-when="AlertTimeline" class="row event-timeline-columnsheader table-header">
                    <div class="col-md-offset-1 col-md-5">Description</div>
                    <div class="col-md-2">First Observed</div>
                    <div class="col-md-4 alert-timeline-details">Details</div>
                </div>

                <div ng-switch-default class="row event-timeline-columnsheader table-header">
                    <div class="col-md-1 nopadding">Date</div>
                    <div class="col-md-5 nopadding">Event</div>
                    <div class="col-md-1 machine-timeline-malware"></div>
                    <div class="col-md-3">Details</div>
                    <div class="col-md-2">User</div>
                </div>
            </div>

            <!-- Timeline Rows -->
            <div id="machine-timeline-events">
                <div class="loading-layer" ng-if="eventsvm.loading > 0 && (!eventsvm.eventsWithDates || eventsvm.eventsWithDates.length == 0)">
            <img src="/assets/images/circle-loading.gif" />
                    <div class="loading-from-archive" ng-if="eventsvm.isSliderInColdStorage">
                        <div>Retrieving data from archive</div>
                        <div>This action may take a few minutes</div>
                    </div>
                </div>
                <div ng-repeat="event in eventsvm.eventsWithDates"
                     right-click="eventsvm.printJsonToDebug(event)">
                    <div ng-if="::event.dateGroup" class="event-timeline-group-title">
                        <b>
                            {{::event.dateGroup}}
                        </b>
                    </div>
                    <div role="list" ng-if="::!event.dateGroup" class="event-timeline-event row hover-default side-pane-container">

                        <div class="row-same-height pointer event-timeline-event-main nopadding" ng-click="eventsvm.expandEvent(event)"
							 ng-class="{'alert-related-event' : eventsvm.isHighlighted(event)}" role="listitem"
							 ng-attr-aria-expanded="{{(event.type == 'File' || event.type == 'Network' || event.type == 'AlertFile' || event.type == 'ScanEvent') && eventsvm.type === 'AlertTimeline'? !!event.showdetails : undefined }}"
							 ng-attr-aria-label="{{(event.type == 'File' || event.type == 'Network' || event.type == 'AlertFile' || event.type == 'ScanEvent') && eventsvm.type === 'AlertTimeline'? (event.showdetails? 'Collapse' : 'Expand') : '' }} artifact">
                            <div class=" col-md-1 col-md-height col-middle"
                                 data-context-menu="{{::event.type =='Alert' ? 'templates/seville.threatintel.alertcontextmenu.html' : '' }}"
                                 scroll-container=".wrapper"
                                 ng-model="event"
                                 ng-controller="alertsContextMenuController as alertsContextMenuController">
                                <!-- Time -->
                                <a ui-sref="machine(::{id: eventsvm.id, time: eventsvm.getEventTimeForNewTab(event.time) })"
                                   data-track-id="GoToMachine"
									data-track-type="Navigation"
                                   target="_blank"
                                   ng-if="eventsvm.searchCriterias && eventsvm.searchCriterias.length > 0">{{::event.filteredtime}}</a>
                                <span ng-if="!eventsvm.searchCriterias || eventsvm.searchCriterias.length == 0">{{::event.filteredtime}}</span>
                            </div>

                            <!-- Description -->
                            <div ng-class="::{ 'col-md-5' : eventsvm.type == 'AlertTimeline' || eventsvm.type == 'MachineTimeline', 'col-md-6' : eventsvm.type != 'AlertTimeline' && eventsvm.type != 'MachineTimeline', 'attack-sev sev-{{::event.severity}}':event.type=='Alert'}"
                                 class="col-md-height col-middle overflow-all event-timeline-desc {{::event.id}}"
                                 ng-switch="::event.type">
                                <div ng-switch-when="Alert"
                                     data-context-menu="templates/seville.threatintel.alertcontextmenu.html"
                                     scroll-container=".wrapper"
                                     ng-model="event"
                                     ng-controller="alertsContextMenuController as alertsContextMenuController"
                                     ng-class="::{'active-alerts-record-resolved' : event.status == 8}">
                                    <span>
                                        <i class="icon machine-timeline-icon icon-LightningBolt"></i>
                                        <span>&nbsp;</span> <!-- extra space instead of details indicator -->
                                        <a data-track-id="GoToAlert"
				 							data-track-type="Navigation"
				 							ui-sref="alert(::{ id: event.id })" stop-propagation>
                                            {{::event.subtitle}}
                                        </a>
                                    </span>
                                </div>

                                <div>
                                    <span ng-switch-when="File">
                                        <i ng-if="::!event.isDetection" class="icon machine-timeline-icon icon-Page"></i>
                                        <i ng-if="::event.isDetection" class="icon machine-timeline-icon icon-Bug"></i>
                                        <side-pane-file sha1="::event.sha1" filename="::event.filename" hide-indicator="true" /> <!-- keep the same spacing-->
                                        <span ng-if="!event.isBlockAction">{{::event.desc}}</span>
                                        <side-pane-file sha1="::event.sha1" filename="::event.filename" ng-if="::event.filename && event.valid" />
										<a href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	ng-if="::event.filename && event.valid">{{::event.filename}}</a>
                                        <span ng-if="::event.filename && !event.valid">
                                            {{::event.filename}}
                                        </span>
                                        <span ng-if="event.isBlockAction">{{::event.desc}}</span>
                                        <span ng-if="::!event.filename && !event.subtitle">
                                            {{::event.files.TotalResults}} {{::event.files.TotalResults > 1 ? 'files' : 'file'}}
                                        </span>
                                    </span>

                                    <span ng-switch-when="AlertFile">
                                        <i class="icon machine-timeline-icon icon-Page"></i>
                                        {{::event.desc}}
                                        <side-pane-file sha1="::event.sha" filename="::event.filename" hide-indicator="::!event.filename || !event.valid" />
										<a href="{{::eventsvm.getFileLink(event.sha, event.filename)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	ng-if="::event.filename && event.valid">
                                            {{::event.filename}}
                                        </a>
                                        <span ng-if="::event.filename && !event.valid"> {{::event.filename}}</span>
                                        <span ng-if="::!event.filename && !event.valid"> Filename could not be retrieved</span>
                                        <span ng-if="::!event.filename && !event.subtitle">
                                            {{::event.files.TotalResults}} {{::event.files.TotalResults > 1 ? 'files' : 'file'}}
                                        </span>
                                    </span>

                                    <span ng-switch-when="AlertIp">
                                        <i class="icon machine-timeline-icon icon-Streaming"></i>
                                        <side-pane-ip ip="::event.ip" event-time="::event.time"/>
										<a href="{{::eventsvm.getIpLink(event.ip, event.time)}}"
                                        	data-track-id="GoToIp"
											data-track-type="Navigation"
                                        	ng-click="eventsvm.preserveIpEventTimeOnState(event.time)">{{::event.ip}}</a>
                                    </span>

                                    <span ng-switch-when="AlertUrl">
                                        <i class="icon machine-timeline-icon icon-Link"></i>
                                        <span>
                                            <a class="icon-container"
                                            	data-track-id="GoToUrl"
												data-track-type="Navigation"
												aria-label="URL page"
												href="{{::eventsvm.getUrlLink(event.domain)}}"
												ng-click="eventsvm.goToUrl(event.domain, event.parsedEventISOTime, $event)">
                                                <i class="icon icon-Info" />
                                            </a>
                                            <side-pane-url url="::event.url" first-seen="::event.firstSeen" />
                                            {{::event.url}}
                                        </span>
                                    </span>

                                    <span ng-switch-when="Network">
                                        <i class="icon machine-timeline-icon icon-Streaming"></i>
                                        <side-pane-file sha1="::event.sha1" filename="::event.filename" hide-indicator="::!event.filename || !event.valid" />
										<a href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	ng-if="::event.filename && event.valid">
                                            {{::event.filename}}
                                        </a>
                                        <span ng-if="::event.filename && !event.valid">{{::event.filename}}</span>
                                        communicated with <span ng-if="::event.ips" ng-repeat="ip in event.ips | limitTo: 3">
                                            <side-pane-ip ip="::ip" />
                                            <a data-track-id="GoToIp"
												data-track-type="Navigation"
												href="{{::eventsvm.getIpLink(ip, event.parsedEventISOTime)}}"
												ng-click="eventsvm.goToIp(ip, event.parsedEventISOTime, $event)">{{::ip}}</a><span ng-if="::!$last">, </span>
                                        </span>
                                        <span ng-if="::event.moreIPs || event.ips.length > 3">and more</span>
                                    </span>

                                    <span ng-switch-when="FileCreate">
                                        <i class="icon-PageAdd icon machine-timeline-icon"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
                                        <span ng-if="::!event.processName">Unknown process</span>
                                        created
                                        <span ng-if=":: !event.valid">{{::event.filename}}</span>
                                        <span ng-if="::event.associatedSha1AndFileNamesCount > 1"><b>{{::event.associatedSha1AndFileNamesCount}}</b> files</span>
                                        <side-pane-file sha1="::event.sha1" filename="::event.filename" hide-indicator="::event.associatedSha1AndFileNamesCount != 1 || !event.valid" />
                                        <a ng-if="::event.associatedSha1AndFileNamesCount == 1 && event.valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}">{{::event.filename}}</a>
                                    </span>

                                    <span ng-switch-when="ProcessCreate">
                                        <i class="icon-Settings icon machine-timeline-icon"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
                                        <span ng-if="::!event.processName">Unknown process</span>
                                        created process
                                        <span ng-if=":: !event.valid">{{::event.filename}}</span>
                                        <span ng-if=":: event.sha1 && event.valid">
                                            <side-pane-file sha1="::event.sha1" filename="::event.filename" />
											<a href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}"
                                            	data-track-id="GoToFile"
												data-track-type="Navigation">{{::event.filename}}</a>
                                        </span>
                                    </span>

                                    <span ng-switch-when="EtwGenericEvent" ng-switch="event.etwType">
                                        <span ng-switch-when="DeviceGuard">
                                            <i class="icon machine-timeline-icon" ng-class="::event.icon"></i>
                                            <side-pane-file sha1="::event.sha1" filename="::event.filename" hide-indicator=":: !event.sha1 || !event.valid" />
                                            <span ng-if=":: !event.valid">{{::event.filename}}</span>
                                            <span ng-if=":: event.sha1 && event.valid">
												<a href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation">{{::event.filename}}</a>
                                            </span>
                                            {{::event.desc}}
                                        </span>
                                        <span ng-switch-when="MemAllocForHighRiskProcesses">
                                            <i class="icon machine-timeline-icon" ng-class="::event.icon"></i>
                                            Anomalous memory allocation in
                                            <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                            <a ng-if="::event.processName && event.processSha1Valid"
                                            	data-track-id="GoToFile"
												data-track-type="Navigation"
												href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">
												{{::event.processName}}
												</a>
                                            <span ng-if="::!event.processSha1Valid && event.processName">{{::event.processName}}</span>
                                            <span ng-if="::!event.processName">Unknown process</span>
                                            process memory
                                        </span>
                                        <span ng-switch-default>
                                            <i class="icon machine-timeline-icon" ng-class="::event.icon"></i>
                                            <span ng-if=":: event.descriptionPrefix">{{::event.descriptionPrefix}}</span>
                                            <!--Initiating Process-->
                                            <span ng-if="::!event.hideInitiatingProcessNameInDescription">
                                                <side-pane-file sha1="::event.processSha1" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                                <a ng-if="::event.processName && event.processSha1Valid"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation"
													href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">
													{{::event.processName | limitTo:40}}{{::event.processName.length > 40 ? '...' : ''}}
												</a>
                                                <span ng-if="::!event.processSha1Valid">{{::event.processName | limitTo:40}}{{::event.processName.length > 40 ? '...' : ''}}</span>
                                                <span ng-if="::!event.processName">Unknown process</span>
                                            </span>
                                            <!--Uri (if exists)-->
                                            <span ng-if="::event.Uri && !event.hideUriInDescription">
                                            <side-pane-url url="::event.Uri"/>
                                            <a ng-if="::event.Uri.length > 60"
                                            	data-track-id="GoToUrl"
												data-track-type="Navigation"
											    href="{{::eventsvm.getUrlLink(event.Uri)}}"
												ng-click="eventsvm.goToUrl(event.Uri, event.parsedEventISOTime, $event)"
											   tooltips tooltip-side="left" tooltip-title="{{::event.Uri}}">{{::event.Uri | limitTo:60}}...</a>
                                            <a ng-if="::event.Uri.length <= 60"
                                            	data-track-id="GoToUrl"
												data-track-type="Navigation"
												href="{{::eventsvm.getUrlLink(event.Uri)}}"
												ng-click="eventsvm.goToUrl(event.Uri, event.parsedEventISOTime, $event)">{{::event.Uri }}</a>
											</span>
                                            {{::event.desc}}
                                            <!--Target Process-->
                                            <span ng-if=":: event.filename && !event.isShaValid ">{{::event.filename | limitTo:40}}{{::event.filename.length > 40 ? '...' : ''}}</span>
                                            <span ng-if=":: event.filename && event.isShaValid">
                                                <side-pane-file sha1="::event.sha" filename="::event.filename" />
												<a href="{{::eventsvm.getFileLink(event.sha, event.filename)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation">{{::event.filename| limitTo:40}}{{::event.filename.length > 40 ? '...' : ''}}</a>
											</span>
                                            <span ng-if=":: event.Ip">
                                                <side-pane-ip ip="::event.Ip" />
												<a href="{{::eventsvm.getIpLink(event.Ip, event.parsedEventISOTime)}}"
                                                	data-track-id="GoToIp"
													data-track-type="Navigation"
													ng-click="eventsvm.goToIp(event.Ip, event.parsedEventISOTime, $event)">{{::event.Ip}}</a><span ng-if=":: event.Port">:{{::event.Port}}</span>
                                            </span>
                                            <span ng-if=":: event.descriptionSuffix">{{::event.descriptionSuffix}}</span>
                                        </span>
                                    </span>

                                    <span ng-switch-when="LoadImage">
                                        <i class="icon-Processing icon machine-timeline-icon"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
                                        <span ng-if="::!event.processName">Unknown process</span>
                                        loaded module
                                        <span ng-if=":: !event.valid">{{::event.filename}}</span>
                                        <span ng-if=":: event.sha1 && event.valid">
                                            <side-pane-file sha1="::event.sha1" filename="::event.filename" /> <a
                                            	data-track-id="GoToFile"
												data-track-type="Navigation"
												href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}">{{::event.filename}}</a>
                                        </span>
                                    </span>

                                    <span ng-switch-when="NetworkEvent">
                                        <i class="icon machine-timeline-icon icon-Streaming"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
										<span ng-if="::!event.processName">Unknown process</span>
										{{::event.description}}
                                        <span ng-if="::event.destinationIpsCount > 1">{{::event.destinationIpsCount}} IPs</span>
                                        <side-pane-ip hide-indicator="::event.destinationIpsCount != 1" ip="::event.destinationIp" event-time="::event.time" />
                                        <span ng-if="::event.destinationIpsCount == 1">
											<a href="{{::eventsvm.getIpLink(event.destinationIp, event.time)}}"
                                            	data-track-id="GoToIp"
												data-track-type="Navigation"
                                            	ng-click="eventsvm.preserveIpEventTimeOnState(event.time)">{{::event.destinationIp}}</a>
                                            	<span ng-if="event.destinationPort">:{{::event.destinationPort}}</span>
                                            <span ng-if="event.destinationDnsRecordName">({{::event.destinationDnsRecordName}})</span>
                                        </span>
                                    </span>

                                    <span ng-switch-when="RegistryEvent">
                                        <i class="icon machine-timeline-icon icon-OEM"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
                                        <span ng-if="::!event.processName">Unknown process</span>
                                        changed
                                        <span ng-if="::event.registryEventsCount > 1">{{::event.registryEventsCount}} registry values</span>
                                        <span ng-if="::event.registryEventsCount == 1"> 1 registry value</span>
                                    </span>

                                    <span ng-switch-when="ScanEvent">
                                        <i class="icon machine-timeline-icon" ng-class="::event.icon"></i>
                                        <side-pane-file sha1="::event.sha1" filename="::event.fileName" hide-indicator="::!event.fileName || !event.processSha1Valid" />
                                        <a ng-if="::event.fileName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.sha1, event.fileName)}}">{{::event.fileName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.fileName}}</span>
                                        <span ng-if="::!event.fileName">Unknown process</span>
                                        {{::event.detectedOrObservedOnMachine}}
                                        <span ng-if="::event.desc"> as {{::event.desc}}</span>
                                    </span>

                                    <span ng-switch-when="BehaviorEvent">
                                        <i class="icon-{{ event.behaviorEventIcon }} icon machine-timeline-icon"></i>
                                        <side-pane-file sha1="::event.processSha1" filename="::event.processName" hide-indicator="::!event.processName || !event.processSha1Valid" />
                                        <a ng-if="::event.processName && event.processSha1Valid"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.processSha1, event.processName)}}">{{::event.processName}}</a>
                                        <span ng-if="::!event.processSha1Valid">{{::event.processName}}</span>
                                        <span ng-if="::!event.processName">Unknown process</span>
                                        <span ng-if="::event.reportIndexCount > 1">{{::event.pluralFormat }}</span>
                                        <span ng-if="::event.reportIndexCount == 1">{{::event.singularFormat}}</span>
                                    </span>

                                    <span ng-switch-when="Machine">
                                        <i class="icon machine-timeline-icon icon-DeviceLaptopNoPic"></i>

                                        <a ui-sref="machine(::{ id: event.machineId })"
                                        	data-track-id="GoToMachine"
											data-track-type="Navigation"
                                        	ng-click="eventsvm.preserveDateOnState($event, 'machine', event.time)">
                                            <span ng-if="::event.computerDnsName">{{:: event.computerDnsNameFirstPart }}</span>
                                            <span ng-if="::!event.computerDnsName && event.wcdMachineId">{{::event.wcdMachineId}}</span>
                                            <span ng-if="::!event.computerDnsName && !event.wcdMachineId">1 machine</span>
                                        </a>
                                    </span>

                                    <span ng-switch-when="Response">
                                        <i class="icon machine-timeline-icon icon-F12DevTools"></i>
                                        <side-pane-file sha1="::event.sha1" hide-indicator="::!event.sha1 || !event.filename" />
                                        <a ng-if="::event.sha1 && event.filename"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
											href="{{::eventsvm.getFileLink(event.sha1, event.filename)}}">{{::event.filename}} </a>
                                        <span>{{::event.responseText}}</span>
                                        <i ng-if="::event.displayAttentionIcon" class="icon machine-timeline-icon icon-Error"></i>
									</span>

									<span ng-switch-when="LogonEvent">
										<i class="icon machine-timeline-icon icon-SwitchUser"></i>
										<span ng-if="::event.isLocalLogon">Local {{:: event.logonTypeDisplayName | lowercase}}</span>
										<span ng-if="::!event.isLocalLogon">{{:: event.logonTypeDisplayName}}</span>
										<!--Domain/User-->
										<span ng-if="::event.accountName">
											by
											<side-pane-user accountname="::event.accountName" accountdomainname="event.accountDomainName" sid="event.accountSid" hide-indicator="::!event.isAllowedAccount" />
											<a ng-if="::event.isAllowedAccount"
												data-track-id="GoToUser"
												data-track-type="Navigation"
												ui-sref="user(::{ userdomain: event.accountDomainName, id: event.accountName, sid: event.accountSid })">
												<span ng-if="::event.accountDomainName.length + event.accountName.length <= 30">{{::event.accountDomainName}}\\{{::event.accountName}}</span>
												<span ng-if="::event.accountDomainName.length + event.accountName.length > 30">{{::event.accountName | limitTo:30}}{{::(event.accountName.length > 30 ? '...' : '')}}</span>
											</a>
											<span ng-if="::!event.isAllowedAccount">
												<span ng-if="::event.accountDomainName.length + event.accountName.length <= 30">{{::event.accountDomainName}}\\{{::event.accountName}}</span>
												<span ng-if="::event.accountDomainName.length + event.accountName.length > 30">{{::event.accountName | limitTo:30}}{{::(event.accountName.length > 30 ? '...' : '')}}</span>
											</span>
										</span>
										<span ng-if="::!event.isLocalLogon && (event.remoteComputerName || event.remoteIp)">
											from
											<span ng-if="::event.remoteComputerName">{{::event.remoteComputerName | parts:1}}</span>
											<a ng-if="::!event.remoteComputerName"
												data-track-id="GoToIp"
												data-track-type="Navigation"
												href="{{::eventsvm.getIpLink(event.remoteIp, event.parsedEventISOTime)}}"
												ng-click="eventsvm.goToIp(event.remoteIp, event.parsedEventISOTime, $event)">{{:: event.remoteIp}}</a>
										</span>
										{{:: (event.actionType === "LogonFailed" ? "failed" : "succeeded" )}}
									</span>
                                </div>

                            </div>

                            <!-- First Observed on AlertTimeline -->
                            <div ng-if="::eventsvm.type == 'AlertTimeline'" class="col-md-2 col-md-height col-middle overflow-all event-timeline-desc {{::event.id}}">
                                <span>{{::event.filteredfirstSeen}}</span>
                            </div>

                            <!-- Malware in Machine timeline -->
                            <div ng-if="::eventsvm.type == 'MachineTimeline' && event.type != 'Alert'"
                                 class="col-md-1 col-md-height col-middle overflow-all machine-timeline-details-col {{::event.id}} machine-timeline-malware nowrap">

                                <i ng-style="::{'visibility': event.containerId ? 'visible':'hidden'}"
                                   class="icon machine-timeline-icon icon-DeviceGuard machine-timeline-detection-active"
                                   tooltips tooltip-size="large" tooltip-title="Contained in Appguard" tooltip-side="right" tooltip-speed="slow"></i>

                                <i ng-style="::{'visibility': event.isActive ? 'visible':'hidden'}"
                                   class="icon machine-timeline-icon icon-Warning machine-timeline-detection-active"
                                   tooltips tooltip-size="large" tooltip-title="Was executed" tooltip-side="right" tooltip-speed="slow"></i>

                                <i ng-style="::{'visibility':  event.isRemediated != null ? 'visible':'hidden'}"
                                   ng-class="::{'machine-timeline-remediation-success' : event.isRemediated, 'machine-timeline-row-red-icon' : !event.isRemediated}"
                                   class="icon icon-Hospital machine-timeline-icon machine-timeline-remediation-icon"
                                   tooltips tooltip-size="large" tooltip-title="{{::event.isRemediatedTitle}}" tooltip-side="right"  tooltip-speed="slow"></i>

                                <i ng-style="::{'visibility':  event.isRemediated != null ? 'visible':'hidden'}"
                                   ng-class="::{'machine-timeline-remediation-status-success icon-CompletedSolid' : event.isRemediated, 'machine-timeline-remediation-status-failure icon-StatusErrorFull' : !event.isRemediated}"
                                   class="icon machine-timeline-icon machine-timeline-remediation-status-icon"></i>

                                <i ng-style="::{'visibility': event.fullEtwType === 'UserDecision' ? 'visible':'hidden'}"
                                   class="icon machine-timeline-icon icon-BlockedSite machine-timeline-row-red-icon"
                                   tooltips tooltip-size="large" tooltip-title="User disregarded warning" tooltip-side="right" tooltip-speed="slow"></i>
                            </div>

                            <!-- Alert placeholder - right click sensitive-->
                            <div ng-if="::eventsvm.type == 'MachineTimeline' && event.type == 'Alert'"
                                 class="col-md-1 col-md-height col-middle overflow-all machine-timeline-details-col {{::event.id}} machine-timeline-malware"
                                 data-context-menu="templates/seville.threatintel.alertcontextmenu.html"
                                 scroll-container=".wrapper"
                                 ng-model="event"
                                 ng-controller="alertsContextMenuController as alertsContextMenuController"></div>

                            <!-- Details -not alerts-->
                            <div ng-if="::event.type != 'Alert'"
                                 ng-class="::{ 'col-md-4' : eventsvm.type == 'AlertTimeline' , 'col-md-3' : eventsvm.type == 'MachineTimeline', 'col-md-5' : eventsvm.type != 'AlertTimeline' && eventsvm.type != 'MachineTimeline'}"
                                 class="col-md-height col-middle overflow machine-timeline-details-col {{::event.id}}">
                                <div ng-if="::event.type == 'File' || event.type == 'Network' || event.type == 'AlertFile' || event.type == 'ScanEvent'" class="overflow-all">
									<img class="sha-svg" alt="null" ng-if="::eventsvm.isValidSha(event.sha1 || event.sha)"
										ng-src="{{eventsvm.getShaIconAddress(event.sha1 || event.sha)}}" />
									<side-pane-file sha1="::event.sha1" sha="::event.sha" filename="::event.filename"
										hide-indicator="::!event.valid" />
									<a href="{{::eventsvm.getFileLink(event.sha1 || event.sha)}}"
										data-track-id="GoToFile"
										data-track-type="Navigation"
										ng-if="::event.valid">
									  <span>{{::event.sha1 || event.sha | limitTo:50}}{{::event.sha && event.sha.length > 40 ? '...' : ''}}</span>
									</a>
									<span ng-if="::!event.valid">{{::event.sha1 || event.sha}}</span>
                                    <span ng-if="::eventsvm.type === 'AlertTimeline'">
                                        <i class="icon alert-artifact-timeline-expand-icon machine-timeline-user-expand-icon icon-AddTo" ng-if="!event.showdetails"></i>
                                        <i class="icon alert-artifact-timeline-expand-icon machine-timeline-user-expand-icon icon-RemoveFrom" ng-if="event.showdetails"></i>
                                    </span>
                                </div>

                                <span ng-switch="::event.type">
                                    <div ng-switch-when="FileCreate">
                                        <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                        <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}} > <span ng-if="::event.associatedSha1AndFileNamesCount > 1">{{::event.associatedSha1AndFileNamesCount}} files</span><span ng-if="::event.associatedSha1AndFileNamesCount == 1">{{::event.filename}}</span>
                                    </div>

                                    <div ng-switch-when="ProcessCreate">
                                        <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                        <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}} > <span>{{::event.filename}}</span>
                                    </div>

                                    <div ng-switch-when="EtwGenericEvent" ng-switch="event.etwType">
                                        <div ng-switch-when="DeviceGuard">
                                            <span ng-if=":: !event.valid">{{::event.sha1}}</span>
                                            <span ng-if=":: event.sha1 && event.valid">
                                                <side-pane-file sha1="::event.sha1" filename="::event.processName" />
												<a href="{{::eventsvm.getFileLink(event.sha1)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation">{{::event.sha1}}</a>
                                            </span>
                                        </div>
                                        <div ng-switch-when="PowerShellCommand">
                                            <i class="icon machine-timeline-icon" ng-class="event.icon"></i>
                                            <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent && event.etwJsonProperties.Command">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}} > <span>{{::event.etwJsonProperties.Command}}</span>
                                        </div>
										<div ng-switch-default>
											<span ng-if="::!event.detailsColumnSha">
												<i class="icon machine-timeline-icon icon-OpenSource"></i>
												<span>{{::event.detailsColumnData}}</span>
											</span>
											<span ng-if="::event.detailsColumnSha">
												<side-pane-file sha1="::event.detailsColumnSha" filename="::event.filename" hide-indicator="::!event.valid" />
												<a href="{{::eventsvm.getFileLink(event.detailsColumnSha)}}"
													data-track-id="GoToFile"
													data-track-type="Navigation"> {{::event.detailsColumnSha}}</a>
											</span>
                                        </div>
                                    </div>

                                    <div ng-switch-when="LoadImage">
                                        <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                        <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}} > <span>{{::event.filename}}</span>
                                    </div>

                                    <div ng-switch-when="NetworkEvent">
                                        <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                        <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}} > <span ng-if="::event.destinationIpsCount > 1">{{::event.destinationIpsCount}} IPs</span><span ng-if="::event.destinationIpsCount == 1">{{::event.destinationIp}}<span ng-if="event.destinationPort ">:{{::event.destinationPort}}</span></span>
                                    </div>

                                    <div ng-switch-when="RegistryEvent">
                                        <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                        <span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}}<span ng-if="::event.registryEventsCount > 1"> > {{::event.registryEventsCount}} registry values</span><span ng-if="::event.registryEventsCount == 1">{{::event.registryEvent.RegistryNewValue_ValueName}}</span>
                                    </div>
                                    <div ng-switch-when="Response">
                                        <span ng-if="::event.submittedBy"> Submitted By: {{event.submittedBy}} </span>
									</div>

									<div ng-switch-when="LogonEvent">
										<i class="icon machine-timeline-icon icon-OpenSource"></i>
										<span ng-if="::event.parentProcessName && !event.isParentProcessAbsent">{{::event.parentProcessName}} > </span>{{::event.processName || 'Unknown'}}<span ng-if="::event.remoteIp"> > {{::event.remoteIp}}</span>
									</div>
                                </span>

                                <div ng-if="::event.type == 'BehaviorEvent' && !(event.enableO365Enrichment && eventsvm.isE2EIntegrationEnabled)">
                                    <i class="icon machine-timeline-icon icon-OpenSource"></i>
                                    <span ng-if="::event.parentProcessName">{{::event.parentProcessNameFiltered}} > </span>{{::event.processName || 'Unknown'}}
                                    >
                                    <span ng-if="::event.reportIndexCount > 1">{{::event.reportIndexCount}}</span> {{::event.createdEntityDesc}}
                                </div>
                                <div ng-if="::event.type == 'BehaviorEvent' && (event.enableO365Enrichment && eventsvm.isE2EIntegrationEnabled)">
                                    <a ng-if="::eventsvm.isDemoTenant"
                                    	data-track-id="DemoO365MoreDetailsOnEmail"
										data-track-type="ExternalLink"
                                    	class="icon-container" target="_blank" href="{{'https://go.microsoft.com/fwlink/?linkid=841531&amp;pc=' + event.sha256.substring(0,60) }}">
                                        <i class="icon icon-Info" /> More details on this email in O365 <br />
                                    </a>
                                    <a ng-if="::!eventsvm.isDemoTenant" class="icon-container" href="#"
                                    	data-track-id="O365MoreDetailsOnEmail"
										data-track-type="ExternalLink"
                                    	ng-click="eventsvm.redirectToO365(event.accountSid, event.sha256, event.time, event.accountDomainName, $event)">
                                        <i class="icon icon-Info" /> More details on this email in O365 <br />
                                    </a>
                                </div>
                            </div>
                        <!-- User -not alerts-->
                            <div class="col-md-2 col-md-height col-middle overflow machine-timeline-details-col {{::event.id}} "
                                 ng-if="::event.type !== 'Alert' && eventsvm.type !== 'AlertTimeline'">
                                    <span ng-if="::event.accountName">
                                        <i class="icon machine-timeline-icon icon-Contact"></i>
                                        <span ng-switch="::event.accountName">
                                            <span ng-switch-when="liz.bean" ng-click="$event.stopPropagation();">
                                                <side-pane-user accountname="::event.accountName" accountdomainname="event.accountDomainName" sid="event.accountSid" hide-indicator="::!event.isAllowedAccount || event.containerId" />
                                                <a href="{{eventsvm.lizBeanLink}}"
                                                	data-track-id="LizBeanLink"
													data-track-type="ExternalLink"
                                                	target="_blank">{{::event.accountName}}</a>
                                            </span>
                                            <span ng-switch-default>
                                                <side-pane-user ng-if="eventsvm.isUserLinkAllowed" accountname="::event.accountName" accountdomainname="event.accountDomainName" sid="event.accountSid" hide-indicator="::!event.isAllowedAccount || event.containerId" />
                                                <a ng-if="eventsvm.isUserLinkAllowed && event.isAllowedAccount && !event.containerId"
                                                	data-track-id="GoToUser"
													data-track-type="Navigation"
                                                	ui-sref="user(::{ userdomain: event.accountDomainName, id: event.accountName, sid: event.accountSid })"
												   class="ellipsis event-timeline-username">
                                                    <span ng-if="::(event.accountName.length < 8)">{{::event.accountName}}</span>
                                                    <span ng-if="(event.accountName.length >=8)" tooltips tooltip-side="left" tooltip-title="{{::event.accountName}}">{{::event.accountName}}</span>
												</a>

                                                <span ng-if="((!eventsvm.isUserLinkAllowed || !event.isAllowedAccount || event.containerId) && (event.fullAccountNameToDisplay.length >= 8))"
                                                      tooltips tooltip-side="left" tooltip-title="{{::event.fullAccountNameToDisplay}}">
                                                    <!--containerId if exists-->
                                                    <span ng-if="event.containerId" class="event-timeline-user-container-col">
                                                        <span class="ellipsis event-timeline-user-container-id">{{::event.containerId}}</span><span ng-if="::event.accountName">\\</span>
                                                    </span>
                                                    <!--account name-->
                                                    <span ng-class="{'event-timeline-username' : !event.containerId, 'event-timeline-username-with-container': event.containerId}"
                                                          class="ellipsis">{{::event.accountName}}</span>
                                                </span>

                                                <span ng-if="((!eventsvm.isUserLinkAllowed || !event.isAllowedAccount || event.containerId) && (event.fullAccountNameToDisplay.length < 8))">
                                                    <!--containerId if exists-->
                                                    <span ng-if="event.containerId" class="event-timeline-user-container-col">
                                                        <span class="ellipsis event-timeline-user-container-id">{{event.containerId}}</span><span ng-if="::event.accountName">\\</span>
                                                    </span>
                                                    <!--account name-->
                                                    <span ng-class="{'event-timeline-username' : !event.containerId, 'event-timeline-username-with-container': event.containerId}"
                                                          class="ellipsis">{{::event.accountName}}</span>
                                                </span>

                                            </span>
                                        </span>
                                    </span>
                                    <i class="icon machine-timeline-expand-icon machine-timeline-user-expand-icon"
                                    ng-class="{'icon-AddTo':!event.showdetails, 'icon-RemoveFrom':event.showdetails}"></i>
                                </div>

                            <!-- Details - alerts, right click sensitive -->
                            <div ng-if="::event.type == 'Alert'"
                                 ng-class="::{ 'col-md-4' : eventsvm.type == 'AlertTimeline', 'col-md-5' : eventsvm.type != 'AlertTimeline', 'active-alerts-record-resolved' : event.status == 8}" class="col-md-height col-middle overflow machine-timeline-details-col {{::event.id}}"
                                 data-context-menu="templates/seville.threatintel.alertcontextmenu.html"
                                 ng-model="event"
                                 ng-controller="alertsContextMenuController as alertsContextMenuController">
                                <table class="event-desc-table">
                                    <tr>
                                        <td class="overflow-all">
                                            <div ng-if="::event.type == 'File' || event.type == 'Network' || event.type == 'AlertFile'" class="overflow-all">
                                                <side-pane-file sha1="::event.sha1" filename="::event.filename" ng-if="event.type=='AlertFile'" hide-indicator="::!event.valid" />
												<a href="{{::eventsvm.getFileLink(event.sha1)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation"
                                                	ng-if="::event.valid">
                                                    <span>{{::event.sha1}}</span>
                                                </a>
                                                <span ng-if="::!event.valid">{{::event.sha1}}</span>
                                            </div>

                                            <div ng-if="::event.type == 'Alert' && event.files && event.files.Items">
                                                <side-pane-file sha1="::event.files.Items[0].Sha1" filename="::event.files.Items[0].filename" hide-indicator="::!event.files.Items[0].Valid" />
												<a href="{{::eventsvm.getFileLink(event.files.Items[0].Sha1)}}"
                                                	data-track-id="GoToFile"
													data-track-type="Navigation"
                                                	ng-if="::event.files.Items[0].Valid">
                                                    {{::event.files.Items[0].Sha1}}
                                                </a>
                                                <span ng-if="::!event.files.Items[0].Valid">{{::event.files.Items[0].Sha1}}</span>
                                            </div>

                                        </td>
                                        <td class="text-right machine-timeline-expand-icon-container"
                                            ng-class="::{'machine-timeline-expand-username-container': event.accountName}">
                                            <span ng-if="::event.accountName" class="ellipsis event-timeline-username">
                                                <i class="icon machine-timeline-icon icon-Contact"></i>
                                                <span ng-switch="::event.accountName">
                                                    <span ng-switch-when="liz.bean" ng-click="$event.stopPropagation();">
                                                    <a href="https://winatpmanagement.windows.com/Client/management/static/elizabethbeanactivities.html"
                                                    	data-track-id="Elizabethbeanactivities"
														data-track-type="ExternalLink"
                                                    	target="_blank">{{::event.accountName}}</a></span>
                                                    <span ng-switch-default>
                                                        <side-pane-user accountname="::event.accountName" accountdomainname="event.accountDomainName" sid="event.accountSid" hide-indicator="::!event.isAllowedAccount" />
                                                        <a ng-if="::event.isAllowedAccount"
                                                        	data-track-id="GoToUser"
															data-track-type="Navigation"
                                                        	ui-sref="user(::{ userdomain: event.accountDomainName, id: event.accountName, sid: event.accountSid })">
                                                            <span ng-if="::(event.accountName.length < 8)">{{::event.accountName}}</span>
                                                            <span ng-if="(event.accountName.length >=8)" tooltips tooltip-side="left" tooltip-title="{{::event.accountName}}">{{::event.accountName}}</span>
                                                        </a>
                                                        <span ng-if="::(!event.isAllowedAccount && event.accountName.length < 8)">{{::event.accountName}}</span>
                                                        <span ng-if="::(!event.isAllowedAccount && event.accountName.length >= 8)"
                                                              tooltips tooltip-side="left" tooltip-title="{{::event.accountName}}">{{::event.accountName}}</span>
                                                    </span>
                                                </span>
                                            </span>
                                            <i class="icon machine-timeline-expand-icon" ng-class="{'icon-AddTo':!event.showdetails, 'icon-RemoveFrom':event.showdetails}"></i>
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>

                        <!-- Collapsed Zone -->
                        <div class="event-timeline-more event-timeline-more-animate" ng-if="event.showdetails">
                            <div ng-if="event.loading || eventsvm.isLoadingOfficeStatusInProgress" class="text-center">
                                <img src="/assets/images/circle-loading.gif" />
                            </div>
                            <div ng-if="!event.loading && !eventsvm.isLoadingOfficeStatusInProgress" class="process-tree-animate">
                                <!--Sense Event -->
                                <div ng-if="::(event.type === 'File' || eventsvm.isSenseEvent(event.type)) && !event.isProcessTreeDisabled">
                                    <!-- Graph -->
                                    <process-tree graph="event.graphRootNode" showtime="eventsvm.showtime" jump-to-date="event.time"></process-tree>
                                </div>

                                <!--Etw event with no processTree-->
                                <div class="row event-timeline-section" ng-if="::event.type === 'EtwGenericEvent' && event.isProcessTreeDisabled">
								<div class="event-timeline-associated-record overflow-all col-md-6 col-md-offset-1" ng-repeat="element in event.elementsToDisplayWhenProcessTreeDisabled">
									<i class="icon machine-timeline-icon" ng-class="::element.icon" ng-if="::element.icon && (element.data || element.encyclopedia || element.sha)"></i>
									<span ng-if="::element.data">{{::element.data }}</span>
									<a ng-if="::element.encyclopedia" target="_blank"
										data-track-id="MicrosoftEncyclopediaReadMore"
										data-track-type="ExternalLink"
										href="{{::element.encyclopedia}}">Read more on Microsoft Encyclopedia</a>
									<span ng-if="::element.sha">
										<side-pane-file sha1="::element.sha" filename="::event.filename" hide-indicator="::!event.valid" />
										<a href="{{::eventsvm.getFileLink(element.sha)}}"
											data-track-id="GoToFile"
											data-track-type="Navigation"> {{::element.sha}}</a>
									</span>
                                    </div>
                                </div>

                                <!--Associated Files-->
                                <div class="row event-timeline-section" ng-if="::event.files.TotalResults > 0" ng-repeat="file in event.files.Items">
                                    <div class="col-md-1"></div>

                                    <div ng-if="::event.type !='AlertFile' && event.type !='Response'" class="event-timeline-associated-record overflow-all col-md-6">
                                        <i ng-if="::file.Valid" class="icon machine-timeline-icon flip-vertical icon-ExitRight"></i>
                                        <span ng-if="::file.Path">{{:: file.Path }}</span>
                                        <span ng-if="::!file.Path && file.Name">{{:: file.Name }}</span>
                                    </div>
                                    <div ng-if="::event.type == 'AlertFile'" class="event-timeline-associated-record overflow-all col-md-6">
                                        <div ng-if="::file.Paths" ng-repeat="path in file.Paths">
                                            <i ng-if="::file.Sha1" class="icon machine-timeline-icon flip-vertical icon-ExitRight"></i>
                                            <span>{{:: path }}</span>
                                        </div>
                                        <span ng-if="::!file.Paths && file.Name">{{:: file.Name }}</span>
                                    </div>
                                    <div ng-if="::event.type == 'Response'" class="event-timeline-associated-record overflow-all col-md-11 col-md-offset-1">
									<div class="response-failure-details" ng-if="::event.displayErrorCode">Details: {{::event.ErrorCode | errorcode}}</div>
                                        <div class="response-failure-details" ng-if="::event.requestorComment">
                                            <i ng-if="event.requestorComment" class="icon machine-timeline-icon icon-Comment"></i>
                                            <span class="overflow white-space">Comment: {{::event.requestorComment}}</span>
                                        </div>
                                        <div class="row" ng-if="event.remediationInstances" ng-repeat="remediationInstance in event.remediationInstances">
                                            <div class="col-md-7">
                                                <i class="icon machine-timeline-icon flip-vertical icon-ExitRight"></i>
                                                <span>{{::remediationInstance.Path}}</span>
                                                <div ng-if="(remediationInstance.RemediationStatus | response: event.responseType)== 'Failed' || remediationInstance.HResult == -2147024894">Details: {{remediationInstance.HResult | errorcode}}</div>
                                            </div>
                                            <div class="col-md-4 col-md-offset-1 timeline-block-status">
                                                <span>Status: {{::remediationInstance.RemediationStatus | response : event.responseType}}</span>
                                            </div>
                                        </div>
                                        <div ng-if="event.responseType =='Forensic' && event.status == 'Succeeded'">
                                            <a ng-if="event.packageUri" href="{{::event.packageUri}}"
                                            	data-track-id="EventPackageUriLink"
												data-track-type="ExternalLink"
                                            	target="_blank">
                                                <i class="icon machine-timeline-icon icon-Download"></i>Download investigation package
                                            </a>
                                            <span ng-if="!event.packageUri">Package expired</span>
                                        </div>
                                    </div>
                                    <div class="col-md-5 overflow-all" ng-if="::file.Sha1 && event.type == 'FileCreate'">
										<a href="{{::eventsvm.getFileLink(file.Sha1)}}"
                                        	data-track-id="GoToFile"
											data-track-type="Navigation"
                                        	ng-if="::file.Valid">
                                            {{::file.Sha1}}
                                        </a>
                                        <span ng-if="::!file.Valid">{{::file.Sha1}}</span>
                                    </div>
                                </div>

                                <!--Associated IPs -->
                                <div class="row event-timeline-section" ng-if="::event.ips.length > 0">
                                    <div class="col-md-1"></div>
                                    <div class="col-md-6">
                                        <span ng-repeat="ip in event.ips | limitTo: 30">
                                            <side-pane-ip ip="::ip" />
											<a href="{{::eventsvm.getIpLink(ip, event.parsedEventISOTime)}}"
                                            	data-track-id="GoToIp"
												data-track-type="Navigation"
												ng-click="eventsvm.goToIp(ip, event.parsedEventISOTime, $event)">{{::ip}}</a><span ng-if="::!$last">, </span>
                                        </span>
                                        <span ng-if="::event.ips.length > 30 || event.moreIPs">and more</span>
                                    </div>
                                </div>

                                <!--More info-->
                                <div class="row event-timeline-section" ng-if="::event.details || event.link">
                                    <div class="col-md-1">
                                    </div>

                                    <div class="col-md-11">
                                        <div class="white-space">{{::event.details}}</div>
                                        <div>
                                            <div ng-if="::event.link">
                                                <i class="icon machine-timeline-icon icon-ReadingMode"></i>
                                                <a ng-href="{{::event.link | decode}}"
                                                	data-track-id="MicrosoftEncyclopediaReadMore"
													data-track-type="ExternalLink"
                                                	target="_blank">Read more on Microsoft Encyclopedia</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div ng-if="eventsvm.loading == 0 && eventsvm.eventsWithDates && eventsvm.events != null && eventsvm.eventsWithDates.length == 0 && !eventsvm.error"
                     class="text-center events-timeline-norecords">
                    <span ng-if="eventsvm.type == 'AlertTimeline'">
                        No indicators associated with this alert.
                    </span>
                    <span ng-if="eventsvm.type != 'AlertTimeline'">
                        No items to show in the timeline.
                        Check back again soon or change the search criteria.
                    </span>
                </div>
                <div ng-if="eventsvm.error" class="text-center events-timeline-norecords">
                    <div class="entity-meta-main-title machine-timeline-error">
                        Refresh the page
                    </div>
                    <div>
                        The service couldn’t complete the search. Refresh the page and try again.
                    </div>
                    <div>
                        If that doesn’t work, check back again soon or try changing the search criteria.
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="timeline-paging-bottom-container"
         ng-if="eventsvm.fullPagingExperience && eventsvm.eventsWithDates && eventsvm.eventsWithDates.length > 0"
         ng-include src="'/timeline-paging.html'">
    </div>
    <script type="text/ng-template" id="/timeline-paging.html">
        <div class="noselect timeline-paging-btns">
            <div class="text-right">
                <div class="timeline-paging-btn timeline-paging-btn-disabled no-outline"
                     ng-if="!eventsvm.filteredEvents || eventsvm.filteredEvents.length == 0 || (eventsvm.skipRecords + eventsvm.recordsPerPage) > eventsvm.filteredEvents.length">
                    <i class="icon icon-ChevronLeft"></i> Older
                </div>

                <div class="timeline-paging-btn timeline-paging-btn-enabled"
                     ng-click="eventsvm.showNext()"
                     ng-if="eventsvm.filteredEvents && eventsvm.filteredEvents.length > 0 && (eventsvm.skipRecords + eventsvm.recordsPerPage) <= eventsvm.filteredEvents.length">
                    <i class="icon icon-ChevronLeft"></i> Older
                </div>

                <div class="timeline-paging-btn timeline-paging-btn-disabled no-outline"
                     ng-if="eventsvm.skipRecords <= 0">
                    Newer <i class="icon icon-ChevronRight"></i>
                </div>

                <div class="timeline-paging-btn timeline-paging-btn-enabled"
                     ng-click="eventsvm.showPrev()"
                     ng-if="eventsvm.skipRecords > 0">
                    Newer <i class="icon icon-ChevronRight"></i>
                </div>
            </div>
        </div>
    </script>
		`,
		controllerAs: 'eventsvm',
		bindToController: true,
		scope: {
			startDate: '=?',
			fidelity: '=?',
			signer: '=',
			events: '=',
			loading: '=',
			type: '=',
			fileNames: '=',
			usercontext: '=?',
			eventTypesFilter: '=?',
			entityname: '=?',
			loadEvents: '&?',
			senseMachineId: '=?',
			computerDnsName: '=?',
			error: '=',
			isUserLinkAllowed: '=',
		},
		controller: 'seville.threatintel.eventsTimeline',
	};
}

eventsTimelineController.$inject = [
	'$scope',
	'$http',
	'$state',
	'$stateParams',
	'$log',
	'$filter',
	'$anchorScroll',
	'$timeout',
	'$localStorage',
	'localeConfigService',
	'appConfig',
	'urlMapping',
	'investigationService',
	'alertFilterService',
	'demoTenantResolverService',
	'coldStorage',
	'$uibModal',
	'featuresService',
	'genericEtwService',
	'filtersLocalStorage',
	'localeService',
	'appInsights',
	'exportToCsvService',
	'officeIntegrationService',
	'ng2router',
	'ipsService',
	'urlsService',
	'filesService',
];

function eventsTimelineController(
	$scope,
	$http,
	$state,
	$stateParams,
	$log,
	$filter,
	$anchorScroll,
	$timeout,
	$localStorage,
	localeConfigService: LocaleConfigService,
	appConfig,
	urlMapping,
	investigationService,
	alertFilterService,
	demoTenantResolverService,
	coldStorage,
	$uibModal,
	featuresService,
	genericEtwService,
	filtersLocalStorage,
	localeService,
	appInsights: AppInsightsService,
	exportToCsvService,
	officeIntegrationService: OfficeIntegrationService,
	ng2router: HybridRoutingService,
	ipsService: IpsService,
	urlsService: UrlsService,
	filesService: FilesService
) {
	var vm = this;
	const invalidValueErrorMsg = 'Invalid value.';
	var deafultUserContext = filtersLocalStorage.deafultUserContext;
	var defaultEventTypeFilter = filtersLocalStorage.defaultEventTypeFilter;
	var sevilleDateFilter = $filter('sevilleDate');
	var behaviorFilter = $filter('behavior');
	var behaviorTypeFilter = $filter('behaviorType');
	var severityFilter = $filter('severity');
	var partsFilter = $filter('parts');
	var wcdMachineIdFilter = $filter('wcdMachineId');
	var remediationStatusFilter = $filter('remediationStatus');
	var filenameFilter = $filter('filename');

	this.$onInit = $onInit;

	function $onInit() {
		vm.fidelity = $localStorage.MachineTimelineFidelityFilter || 'All';
		vm.usercontext = filtersLocalStorage.getUserContextSavedOrDefault();
		vm.eventTypesFilter = filtersLocalStorage.getEventTypeFilterSavedOrDefault();

		vm.id = $stateParams.id;
		vm.eventsWithDates = [];
		vm.showtime = true;
		vm.fullPagingExperience = vm.loadEvents ? true : false;
		vm.skipRecords = 0;
		vm.recordsPerPage = !vm.fullPagingExperience ? 100 : $localStorage.EventsPerPage || 20;
		vm.alertModes = ['none', 'filter', 'highlight'];
		vm.isDemoTenant = demoTenantResolverService.isDemoTenant();
		vm.lizBeanLink = 'http://go.microsoft.com/fwlink/?LinkId=828006';
		vm.isAllowedDisplayOfAccount = featuresService.isEnabled('User-Links');

		officeIntegrationService.officeIntegrationSettings$
			.pipe(filter(value => !!value))
			.subscribe(setOfficeIntegrationSettings);

		vm.isSenseEvent = function(type) {
			var senseEvents = [
				'FileCreate',
				'NetworkEvent',
				'BehaviorEvent',
				'RegistryEvent',
				'ProcessCreate',
				'LoadImage',
				'EtwGenericEvent',
				'LogonEvent',
			];
			if (senseEvents.indexOf(type) > -1) {
				return true;
			}

			return false;
		};

		vm.expandEvent = function(event) {
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExpandEventOnTimeline',
				Page: 'Device',
				Component: 'ExpandEvent',
			});
			if (!vm.isSenseEvent(event.type) && event.responseType != 'Forensic') {
				event.showdetails = !event.showdetails;
				return;
			}

			if (event.lazyLoaded || event.loading) {
				event.showdetails = !event.showdetails;
				return;
			}

			if (event.etwType && event.etwType === 'UserDecision') {
				event.loading = true;
				var updateUserDecisionPromise = updateUserDecisionEventWithMachingLookupEvent(event);
				if (updateUserDecisionPromise) {
					updateUserDecisionPromise.then(function() {
						loadAssociatedProcessEvent(event, false);
					});
				}

				event.showdetails = !event.showdetails;
				return;
			}

			if (event.type == 'BehaviorEvent') {
				loadSenseEventsForBehaviorEvent(event);
			} else if (event.responseType == 'Forensic' && event.status == 'Succeeded') {
				loadInvestigationPackageUri(event);
			} else {
				loadAssociatedProcessEvent(event, false);
			}

			event.showdetails = !event.showdetails;
		};

		vm.getIpLink = (ipId, time) => {
			const eventTime = (time && new Date(time)) || vm.dateNow;
			if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
				return ipsService.getIpLink(ipId, eventTime);
			} else {
				return $state.href('ip', {
					id: ipId,
					time: eventTime.toISOString(),
				});
			}
		};

		vm.getFileLink = (fileId, fileName?) => filesService.getFileLink(fileId, null, fileName);

		vm.goToIp = (ipId, time, $event) => {
			$event.preventDefault();
			$event.stopPropagation();

			if (featuresService.isEnabled(Feature.UpgradeIpPage)) {
				ng2router.navigateByUrl(vm.getIpLink(ipId, time));
			} else {
				$state.go('ip', {
					id: ipId,
					time: time,
				});
			}
		};

		vm.getUrlLink = (urlId, time) => {
			const eventTime = (time && new Date(time)) || vm.dateNow;
			if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
				return urlsService.getUrlLink(urlId, eventTime);
			} else {
				return $state.href('url', {
					id: urlId,
					time: eventTime.toISOString(),
				});
			}
		};

		vm.goToUrl = (urlId, time, $event) => {
			$event.preventDefault();
			$event.stopPropagation();

			if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
				ng2router.navigateByUrl(vm.getUrlLink(urlId, time));
			} else {
				$state.go('url', {
					id: urlId,
					time: time,
				});
			}
		};

		$scope.$on('alertFilter:changeDate', function(event, payload) {
			vm.resetFilters(false);

			if (payload.activeAlertTime) {
				vm.events = [];
				vm.eventsWithDates = [];
				vm.filteredEvents = [];
				var d = new Date(payload.activeAlertTime);
				d.setMilliseconds(d.getMilliseconds() + 10);
				navigateToDate(d, true, true);
			}
		});

		if (!vm.fullPagingExperience) {
			$scope.$watch(
				function() {
					return vm.events;
				},
				function(newValue, oldValue) {
					if (vm.loading <= 0 && vm.events) {
						vm.userContextValues = getContextFilterFromString();
						filterEvents();
						arrangeEventsByDate();
					}
				}
			);
		}

		$scope.$watch(
			function() {
				return vm.recordsPerPage;
			},
			function(oldValue, newValue) {
				if (oldValue == newValue) return;

				$localStorage.EventsPerPage = vm.recordsPerPage;
				arrangeEventsByDate();
			}
		);

		// watch timeline filters
		$scope.$watch(
			function() {
				return vm.fidelity + vm.usercontext + vm.eventTypesFilter;
			},
			function(oldValue, newValue) {
				if (oldValue == newValue) return;

				// save filtering preferences
				$localStorage.MachineTimelineFidelityFilter = vm.fidelity;
				$localStorage.MachineTimelineContextFilter = vm.usercontext;
				$localStorage.MachineTimelineEventTypesFilter = vm.eventTypesFilter;

				vm.events = [];
				vm.eventsWithDates = [];
				vm.filteredEvents = [];

				updateFilterTags();

				vm.startDate.setMilliseconds(vm.startDate.getMilliseconds() + 10);
				navigateToDate(vm.startDate, true);
			}
		);

		vm.preserveDateOnState = function($event, state, date) {
			$event.stopPropagation();
			if (!$state.get(state).data) {
				$state.get(state).data = {};
			}

			$state.get(state).data.slider = new Date(date);
		};

		vm.preserveIpEventTimeOnState = function(eventTime) {
			if (!$state.data) {
				$state.data = {};
			}

			$state.data.eventTime = eventTime;
		};

		vm.loadNextPage = function() {
			var lastRecord = vm.events[vm.events.length - 1];
			if (lastRecord.loadedFromHere) return;

			lastRecord.loadedFromHere = true;
			var newStartDate = new Date(lastRecord.time);
			newStartDate.setMilliseconds(newStartDate.getMilliseconds() - 1);
			vm.startDate = newStartDate;
			if (vm.fullPagingExperience) {
				updateIsSliderInColdStorage();
				vm.loadEvents()(vm.startDate, 'desc', vm.searchCriterias).then(addDescEvents);
			}
		};

		vm.loadPrevPage = function() {
			if (vm.fullPagingExperience) {
				var firstRecord = vm.events[0];

				if (firstRecord.loadedFromHere) return;

				firstRecord.loadedFromHere = true;
				var newStartDate = new Date(firstRecord.time);
				newStartDate.setMilliseconds(newStartDate.getMilliseconds() + 1);

				vm.startDate = newStartDate;
				vm.loadEvents()(vm.startDate, 'asc', vm.searchCriterias).then(addAscEvents);
			}
		};

		vm.showNext = function() {
			vm.skipRecords = vm.skipRecords + vm.recordsPerPage;

			arrangeEventsByDate();

			if (vm.skipRecords + vm.recordsPerPage * 3 > vm.events.length) vm.loadNextPage();
		};

		vm.showPrev = function() {
			vm.skipRecords = vm.skipRecords - vm.recordsPerPage;
			if (vm.skipRecords < 0) vm.skipRecords = 0;

			arrangeEventsByDate();

			if (vm.skipRecords <= vm.recordsPerPage * 3) vm.loadPrevPage();
		};

		initSlider();

		if (vm.usercontext) {
			vm.userContextOptions = [
				{ id: 'LogonUsers', label: 'Logon Users' },
				{
					id: 'System',
					label: 'SYSTEM',
				},
				{ id: 'Network', label: 'NETWORK' },
				{ id: 'LocalService', label: 'LOCAL SERVICE' },
			];
			vm.userContextValues = getContextFilterFromString();
			vm.userContextSettings = {
				buttonClasses: 'btn btn-link events-timeline-filter-btn dropdown-toggle input-box ellipsis',
				dynamicTitle: true,
				showCheckAll: true,
				showUncheckAll: true,
				scrollable: false,
				smartButtonMaxItems: 4,
				displayProp: 'label',
				idProp: 'id',
				openIconClass: 'event-timeline-filter-icon',
				allSelectedText: 'All',
			};

			vm.userContextEvents = {
				onSelectionClosed: function() {
					vm.usercontext = getContextStringFromFilter();
				},
			};

			vm.userUifiltertext = {
				buttonDefaultText: 'User Context',
			};
		}

		initEventTypesFilter();

		var getContextStringFromFilter = function() {
			var usercontext = '';
			vm.userContextValues.forEach(function(element, index, array) {
				usercontext = usercontext.concat(element.id, ',');
			});

			if (!usercontext) {
				vm.userContextValues = getContextFilterFromString();
				return vm.usercontext;
			}
			return usercontext.substring(0, usercontext.length - 1);
		};

		vm.isHighlighted = function(event) {
			return alertFilterService.ishighlightActive(event.id) || alertFilterService.applyHighlight(event);
		};

		vm.printJsonToDebug = function(eventRecord) {
			var item: any = {
				event: {},
				json: {},
			};

			angular.copy(eventRecord, item.event);
			angular.copy(eventRecord.json, item.json);
			delete item.event.json;

			console.dir(item);
		};

		vm.openExportDialog = function(senseId, wcdId) {
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExportToCsv',
				Page: 'Device',
				Component: 'DeviceExportToCsv',
			});
			exportToCsvService.openDialog({
				title: 'Export device timeline events',
				maxDays: 7,
				timelineDate: vm.timePickerModel.selectedDate,
				httpRequestParams: {
					senseMachineId: senseId,
					computerDnsName: wcdId,
				},
				checkResultsUrl: '/LookForEventsToExport',
				getAuthTokenUrl: '/ExportMachineTimelineToCsv',
				downloadUrl: '/DownloadMachineTimelineToCsv',
			});
		};

		vm.getShaIconAddress = function(fileId) {
			if (RegExpService.sha1.test(fileId)) {
				return config.images.sha1;
			}

			if (RegExpService.sha256.test(fileId)) {
				return config.images.sha256;
			}

			return '';
		};

		vm.isValidSha = function(potentialSha) {
			return RegExpService.sha1.test(potentialSha) || RegExpService.sha256.test(potentialSha);
		};

		vm.initSearch = function() {
			vm.currentDate = moment();
			vm.searchEndDate = moment(vm.currentDate);
			vm.searchStartDate = moment(vm.currentDate).subtract(7, 'days');
			vm.searchDateformat = 'MMM, DD YYYY';

			vm.searchOptions = [
				{
					title: 'hash',
					desc: 'SHA1, SHA256, MD5',
				},
				{
					title: 'file',
					desc: 'File name',
				},
				{
					title: 'ext',
					desc: 'With file extension',
				},
				{
					title: 'path',
					desc: 'Under path',
				},
				{
					title: 'cmdline',
					desc: 'Has the command line',
				},
				{
					title: 'user',
					desc: 'Logon user',
				},
				{
					title: 'ip',
					desc: 'IP',
				},
				{
					title: 'url',
					desc: 'URL',
				},
			];

			vm.searchTags = [];
			vm.searchCriterias = [];

			updateFilterTags();
		};

		vm.getSearchOptions = function(val) {
			if (!val) return vm.searchOptions;
			val = val.toLowerCase();
			var matchingOptions = vm.searchOptions.filter(function(option) {
				return option.title.indexOf(val) > -1;
			});

			return matchingOptions;
		};

		vm.handleSearchKeyPress = function($event) {
			delete vm.searchErrorMsg;
			if ($event.keyCode === 8 && vm.searchTerm) {
				// on backspace

				var splitted = vm.searchTerm.trim().split(':');
				if (splitted.length <= 1 || splitted[1] === '') {
					delete vm.searchTerm;
					delete vm.searchSelectedOption;

					$timeout(vm.openTypeahead, 50);
				}
			} else if ($event.keyCode === 8 && !vm.searchTerm) {
				vm.openTypeahead();
			} else if ($event.keyCode === 13) {
				// on enter
				vm.addSearchCriteria();
			} else if ($event.keyCode === 9) {
				// on enter
				vm.closeTypeAhead(true);
				$('#event-timeline-level-filter').focus();
			} else if (
				$event.keyCode === 186 && // on :
				vm.searchTerm &&
				(vm.searchTerm[vm.searchTerm.length - 1] === ':' ||
					(vm.searchTerm[vm.searchTerm.length - 2] === ':' &&
						vm.searchTerm[vm.searchTerm.length - 1] === ' '))
			) {
				event.preventDefault();
				return false;
			}
		};

		vm.searchShouldSelect = function(event) {
			if (!vm.searchTerm) return false;
			var optionTitle = vm.searchTerm.split(':')[0];
			if (!optionTitle) return false;

			var matchingOptions = vm.searchOptions.filter(function(option) {
				return option.title.indexOf(optionTitle) > -1;
			});

			return (
				matchingOptions.length == 1 &&
				!matchingOptions[0].selected &&
				(!vm.searchSelectedOption || vm.searchSelectedOption.title != matchingOptions[0].title)
			);
		};

		vm.onSearchOptionSelected = function(item, model, label, event) {
			vm.searchSelectedOption = item;
			vm.searchTerm = item.title + ': ';
		};

		vm.onSearchCriteriaTagRemoved = function(tag) {
			if (tag.key == 'info level') {
				vm.fidelity = 'All';
			} else if (tag.key == 'event type') {
				vm.eventTypesFilter = vm.eventTypesFilter
					.replace(tag.id + ',', '')
					.replace(',' + tag.id, '')
					.replace(tag.id, '');
				vm.eventTypeValues = getEventFilterFromModel();
			} else if (tag.key == 'userAccount') {
				vm.usercontext = vm.usercontext
					.replace(tag.id + ',', '')
					.replace(',' + tag.id, '')
					.replace(tag.id, '');
				vm.userContextValues = getContextFilterFromString();
			} else {
				// clear selected
				var matchingOptions = vm.searchOptions.filter(function(option) {
					return option.title === tag.key;
				});

				for (var i = 0; i < matchingOptions.length; i++) {
					delete matchingOptions[i].selected;
				}
			}

			applySearchCriterias();
		};

		vm.goToEvent = function(event, $event) {
			$event.preventDefault();
			clearSearchCriterias();
			var date = new Date(event.time);
			date.setMilliseconds(date.getMilliseconds() + 5);
			navigateToDate(date, true, true);
		};

		vm.openTypeahead = function() {
			var elem = angular.element('#event-timeline-filter');
			var menu = angular.element('.events-timline-search-input ul');
			menu.show();
			if (elem.is(':focus')) {
				// open
				elem.controller('ngModel').$setViewValue('');
				$timeout(function() {
					elem.focus();
				});
			} else {
				if (angular.element('.events-timline-search-input .dropdown-menu').is(':visible')) {
					// close
					elem.blur();
				} else {
					// focus
					elem.focus();
				}
			}
		};

		vm.closeTypeAhead = function(close) {
			if (close) {
				var elem = angular.element('.events-timline-search-input ul');
				elem.hide();
			}
		};

		vm.addSearchCriteria = function() {
			if (!vm.searchSelectedOption) {
				if (!vm.searchTerm) {
					vm.searchErrorMsg = invalidValueErrorMsg;
					return;
				}

				if (vm.searchTerm.indexOf(':') < 0) {
					vm.searchErrorMsg = 'Field not selected.';
					return;
				}

				// check if has fields without selection
				var splitted = vm.searchTerm.trim().split(':');
				var matchingOptions = vm.searchOptions.filter(function(option) {
					return option.title == splitted[0];
				});

				if (matchingOptions.length > 0) {
					vm.searchSelectedOption = matchingOptions[0];
				} else {
					vm.searchErrorMsg = invalidValueErrorMsg;
					return;
				}
			}

			var key = vm.searchSelectedOption.title;

			if (vm.searchSelectedOption.selected) {
				vm.searchErrorMsg =
					key + ' already specified. Select another field or remove existing filter.';
				return;
			}

			let value = vm.searchTerm.split(key + ': ')[1];

			if (!value) {
				vm.searchErrorMsg = invalidValueErrorMsg;
				return false;
			}
			value = value.trim();

			if (!validateSearchCriteria(key, value)) {
				return false;
			}

			appInsights.track({
				id: 'machineEventsSearch',
				type: TrackingEventType.Search,
				component: 'legacyMachineEvents',
				componentType: 'Legacy DataView',
				value: key,
			});

			vm.searchTags.push({
				key: key,
				value: value,
			});

			// reset other filters
			var hasOtherFilters = vm.searchTags.some(function(tag) {
				return vm.searchOptions.every(function(option) {
					return option.title !== tag.key;
				});
			});
			if (hasOtherFilters) {
				vm.fidelity = 'All';
				vm.usercontext = deafultUserContext;
				vm.userContextValues = getContextFilterFromString();

				vm.eventTypesFilter = defaultEventTypeFilter;
				vm.eventTypeValues = getEventFilterFromModel();
				vm.resetMessage = 'Filters reset by new search, reapply as desired.';
				$timeout(function() {
					vm.resetMessage = '';
				}, 6000);
			}

			applySelectedSearchCriteria(vm.searchSelectedOption);
			applySearchCriterias();

			vm.filtersApplied = vm.searchTags.length > 0;

			delete vm.searchTerm;
			delete vm.searchSelectedOption;
		};

		vm.resetFilters = function(shouldLoadTimeline) {
			if (!vm.filtersApplied) {
				return;
			}
			delete vm.query;
			vm.fidelity = 'All';
			vm.usercontext = deafultUserContext;
			vm.userContextValues = getContextFilterFromString();

			vm.eventTypesFilter = defaultEventTypeFilter;
			vm.eventTypeValues = getEventFilterFromModel();

			vm.searchCriterias = [];
			vm.searchTags = [];

			for (var i = 0; i < vm.searchOptions.length; i++) {
				delete vm.searchOptions[i].selected;
			}

			if (shouldLoadTimeline) {
				applySearchCriterias();
			} else {
				vm.resetMessage = 'Filters reset by new search, reapply as desired.';
				$timeout(function() {
					vm.resetMessage = '';
				}, 6000);
			}
		};

		vm.getEventTimeForNewTab = function(time) {
			var date = new Date(time);
			date.setMilliseconds(date.getMilliseconds() + 5);
			return date.toISOString();
		};

		vm.redirectToO365 = function(accountSid, sha256, time, accountDomainName, $event) {
			$event.preventDefault();
			$event.stopPropagation();
			var url = urlMapping.getThreatIntelUrl() + '/RedirectToO365';
			$http
				.get(url, {
					params: {
						accountSid: accountSid,
						sha256: sha256,
						timestamp: time,
						accountDomainName: accountDomainName,
						officeTenantPrefix: vm.officeTenantPrefix,
					},
				})
				.then(
					function(response) {
						if (response.status == 200 && response.data) {
							window.open(response.data, '_blank');
							appInsights.trackEvent('UsageTrack', {
								Status: 'Success',
								Page: 'RedirectToO365',
								Component: 'O365',
							});
						} else {
							$log.error(response.status + ' - Error occur while fetching O365 Url');
							appInsights.trackEvent('UsageTrack', {
								Status: 'Error',
								Page: 'RedirectToO365',
								Component: 'O365',
							});
						}
					},
					function(response) {
						$log.error(response.status + ' - Error occur while fetching O365 Url');
					}
				);
		};

		vm.initSearch();
	}

	function setOfficeIntegrationSettings(officeIntegrationSettings: OfficeIntegrationSettings) {
		if (!officeIntegrationSettings) {
			return;
		}

		officeIntegrationService.getOfficeTenantUrlPrefix().subscribe((officeTenantPrefix: string) => {
			vm.officeTenantPrefix = officeTenantPrefix;
			vm.isE2EIntegrationEnabled = officeIntegrationSettings.isE2EIntegrationEnabled || vm.isDemoTenant;
		});
	}

	function loadMailDetails(event, originMailDetails) {
		if (originMailDetails != null) {
			event.o365MailSender = originMailDetails.Sender;
			event.o365MailSubject = originMailDetails.Subject;
			event.o365MailRecipients = originMailDetails.Recipients;
			event.o365MailReceived = originMailDetails.ReceivedDate;
			event.o365MailIoAType = originMailDetails.IoAType;
			event.o365MailIoAIdentifier = originMailDetails.IoAIdentifier;
			event.o365MailDeepLink = originMailDetails.DeepLinkToO365Portal;
		}
	}

	function updateCertificateDate(event, data) {
		if (event.signer == null) {
			event.signer = data.Signer;
			event.isValidCertificate = data.IsValidCertificate;
			event.isCertificateDataPopulated = data.IsCertificateDataPopulated;
		}
		if (event.processSigner == null) {
			event.processSigner = data.ProcessSigner;
			event.processIsValidCertificate = data.ProcessIsValidCertificate;
			event.isProcessCertificateDataPopulated = data.IsProcessCertificateDataPopulated;
		}
		if (event.creatorSigner == null) {
			event.creatorSigner = data.CreatorSigner;
			event.creatorIsValidCertificate = data.CreatorIsValidCertificate;
			event.isCreatorCertificateDataPopulated = data.IsCreatorCertificateDataPopulated;
		}
	}

	function loadInvestigationPackageUri(event) {
		event.loading = true;
		$log.debug('loading investigation package uri');
		const url = appConfig.serviceUrls.userRequests + '/requests/forensics/downloaduribyguid';
		$http
			.get(url, {
				params: {
					requestGuid: event.requestGuid,
					packageIdentity: vm.computerDnsName,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						event.packageUri = response.data;
						event.loading = false;
						event.lazyLoaded = true;
					} else {
						event.loading = false;
						event.lazyLoaded = false;
					}
				},
				function(response) {
					$log.debug('investigation package uri loading failed');
					event.loading = false;
					event.lazyLoaded = false;
				}
			);
	}

	function loadSenseEventsForBehaviorEvent(event) {
		event.loading = true;
		$log.debug(
			'loading associated sense event for behavior, officeTenantPrefix: ' + vm.officeTenantPrefix
		);
		const reportIndexes = event.reportIndexList.join(', ');
		const url = urlMapping.getThreatIntelUrl() + '/AssociatedSenseEvent';
		$http
			.get(url, {
				params: {
					processId: event.processId,
					reportIndexList: reportIndexes,
					officeTenantPrefix: vm.officeTenantPrefix,
					senseMachineId: event.senseMachineId,
					wcdMachineId: event.wcdMachineId,
					tableName: event.tableName,
					binCreationTime: event.binCreationTime,
					enableO365Enrichment: vm.isE2EIntegrationEnabled && event.enableO365Enrichment,
					minReportIndexEventTime: event.minReportEventTime,
					maxReportIndexEventTime: event.maxReportEventTime,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						const data = response.data[0];

						// if no data returned from the server, stop displaying the spinning cursor
						if (data == null) {
							const errorMsg = `loading associated sense event for behavior returned no results for event process id: ${
								event.processId
							}, senseMachineId: ${event.senseMachineId}, wcdMachineId: ${
								event.wcdMachineId
							}, reportIndexList: ${reportIndexes}`;
							$log.error(errorMsg);
							event.loading = false;
							return;
						}

						switch (event.tableName) {
							case 'FileCreateBehaviors':
								event.associatedSha1AndFileNames = data.AssociatedSha1AndFileNames;
								event.associatedSha1AndFileNamesCount = data.AssociatedSha1AndFileNames
									? Object.keys(data.AssociatedSha1AndFileNames).length
									: 0;
								event.parentProcessName = data.ParentProcessName;
								event.processName = data.ProcessName;
								event.processSha1 = data.ProcessSha1;
								event.processCommand = data.ProcessCommandLine;
								event.processCreationTime = data.ProcessCreationTime;
								event.processPath = data.ProcessPath;
								event.processId = data.ProcessId;
								event.filename = data.FileName;
								event.path = data.RealPath;
								if (!data.Sha1 || !RegExpService.sha1.test(data.Sha1)) {
									data.Sha1 = 'Hash could not be retrieved';
									data.invalidHash = true;
								}
								event.sha1 = data.Sha1;
								event.valid = !data.invalidHash;
								loadMailDetails(event, data.OriginMailDetails);
								break;
							case 'NetworkConnectionBehaviors':
								event.destinationIps = data.DestinationIpsList;
								event.destinationIpsCount = data.DestinationIpsList.length;
								event.destinationIp = data.DestinationIpsList[0];
								event.parentProcessName = data.ParentProcessName;
								event.processName = data.ProcessName;
								event.processSha1 = data.ProcessSha1;
								event.processCommand = data.ProcessCommandLine;
								event.processCreationTime = data.ProcessCreationTime;
								event.processPath = data.ProcessPath;
								event.processId = data.ProcessId;
								break;
							case 'ProcessCreateBehaviors':
								event.parentProcessName = data.CreatorParentName;
								event.processName = data.CreatorName;
								event.processSha1 = data.CreatorSha1;
								event.processCommand = data.CreatorCommandLine;
								event.processCreationTime = data.CreatorCreationTime;
								event.processPath = data.CreatorPath;
								event.processId = data.CreatorProcessId;
								event.childProcessName = data.ProcessName;
								event.childProcessSha1 = data.ProcessSha1;
								event.childProcessPath = data.ProcessPath;
								event.childProcessId = data.ProcessId;
								event.childProcessCommand = data.ProcessCommandLine;
								event.associatedSha1AndProcessNames = data.AssociatedSha1AndProcessNames;
								event.associatedSha1AndProcessNamesCount = data.AssociatedSha1AndProcessNames
									? Object.keys(data.AssociatedSha1AndProcessNames).length
									: 0;

								loadMailDetails(event, data.OriginMailDetails);
								break;
							default:
								$log.debug('Cannot handle response table' + event.tableName);
								break;
						}

						updateCertificateDate(event, data);
						loadAssociatedProcessEvent(event, true);
					} else {
						event.loading = false;
						event.lazyLoaded = false;
					}
				},
				function(response) {
					$log.debug('associated process event loading failed');
					event.loading = false;
					event.lazyLoaded = false;
				}
			);
	}

	function updateUserDecisionEventWithMachingLookupEvent(event) {
		$log.debug(
			'user decision expansion - trying to match and update its data with the related lookup event'
		);
		const url = urlMapping.getThreatIntelUrl() + '/UserDecisionUpdate';
		if (!event.etwJsonProperties.ActivityId) {
			// can't match user decision event with no activity id
			return null;
		}

		return $http
			.get(url, {
				params: {
					SenseMachineId: event.machineId,
					EventTime: event.time,
					ActivityId: event.etwJsonProperties.ActivityId,
				},
			})
			.then(
				function(response) {
					if (response.status === 200 && response.data) {
						const relatedLookupEvent = response.data;

						// set main event properties
						genericEtwService.setEtwEventPropertiesFromRecord(event, relatedLookupEvent);

						event.EventPropertiesAsJson = relatedLookupEvent.EventPropertiesAsJson || {};

						// the properties below are based on those that were set above
						event.etwType = genericEtwService.getEtwEventType(relatedLookupEvent);
						event.Uri = relatedLookupEvent.Uri || event.EventPropertiesAsJson.Uri;
						event.name = genericEtwService.getEtwMachineProcessTreeNodeName(event);
						event.desc = genericEtwService.getEtwMachineTimelineDescription(event);
						event.descriptionSuffix = genericEtwService.getEtwMachineTimelineDescriptionSuffix(
							event
						);
						event.processTreeNodeContentHeader = genericEtwService.getEtwMachineProcessTreeNodeDescription(
							event
						);
						event.detailsColumnData = genericEtwService.getEtwDetailsColumnData(event);
						event.isProcessTreeDisabled = genericEtwService.isProcessTreeDisabled(event);
					}
				},
				function() {
					$log.error(
						'Failed update user decision event with ActivityId:' +
							(event.etwJsonProperties.ActivityId || 'none')
					);
				}
			);
	}

	function loadAssociatedProcessEvent(event, requestOnlyCreatorDetails) {
		event.loading = true;
		$log.debug('loading sense process create for file: ' + event.sha1);

		if (vm.isSenseEvent(event.type)) {
			const url = urlMapping.getThreatIntelUrl() + '/AssociatedProcessEvent';
			$http
				.get(url, {
					params: {
						ProcessSha1: event.processSha1,
						ProcessCmdLine: event.processCommand,
						ProcessCreationTime: event.processCreationTime,
						ProcessParentName: event.parentProcessName,
						ProcessId: event.processId,
						Sha1: event.sha1,
						RequestOnlyCreatorDetails: requestOnlyCreatorDetails,
						SenseMachineId: event.machineId,
					},
				})
				.then(
					function(response) {
						if (response.status == 200 || response.status == 404) {
							addProcessCreateDataToEvent(event, response.data);
						}

						event.loading = false;
					},
					function(response) {
						$log.debug('associated process event loading failed');
						event.loading = false;
					}
				);
		}
	}

	function addProcessCreateDataToEvent(event, data) {
		if (data != null) {
			event.processAccountName = data.ProcessAccountName;
			event.processTokenElevation = data.CreatedProcessTokenElevationType;
			event.processIntegrityLevel = data.CreatedProcessIntegrityLevel;

			event.creatorCommand = data.CreatorCommandLine;
			event.creatorSha1 = data.CreatorSha1;
			event.creatorPath = data.CreatorPath;
			event.creatorName = data.CreatorName;
			event.creatorCreationTime = data.CreatorCreationTime;
			event.creatorProcessId = data.CreatorProcessId;
			event.creatorAccountName = data.CreatorAccountName;
			event.creatorTokenElevation = data.CreatorProcessTokenElevationType;
			event.creatorIntegrityLevel = data.CreatorProcessIntegrityLevel;

			event.creatorParentName = data.CreatorParentName;
			event.creatorParentProcessId = data.CreatorParentProcessId;
			event.creatorParentProcessCreationTime = data.CreatorParentProcessCreationTime;

			updateCertificateDate(event, data);

			event.creatorSha1Valid = true;
			if (!event.creatorSha1 || !RegExpService.sha1.test(event.creatorSha1)) {
				event.creatorSha1 = 'Hash could not be retrieved';
				event.creatorSha1Valid = false;
			}
			if (event.type == 'File') {
				event.parentProcessName = data.ParentProcessName;
				event.processName = data.ProcessName;
				event.processSha1 = data.ProcessSha1;
				event.processSha1Valid = true;
				if (!event.processSha1 || !RegExpService.sha1.test(event.processSha1)) {
					event.processSha1 = 'Hash could not be retrieved';
					event.processSha1Valid = false;
				}
				event.processCommand = data.ProcessCommandLine;
				event.processCreationTime = data.ProcessCreationTime;
				event.processPath = data.ProcessPath;
			}

			event.childProcessSha1Valid = true;
			if (event.type == 'Behavior') {
				if (!event.childProcessSha1 || !RegExpService.sha1.test(event.childProcessSha1)) {
					event.childProcessSha1 = 'Hash could not be retrieved';
					event.childProcessSha1Valid = false;
				}
			}

			$log.debug('associated process event loaded successfully');

			buildProcessTree(event);
		}

		event.lazyLoaded = true;
		event.loading = false;
	}

	function buildProcessTree(event) {
		let currentNode: any, currentEvent: any;

		event.graphRootNode = {
			children: [],
		};

		if (event.creatorParentName) {
			currentEvent = {
				name: event.creatorParentName,
				processId: event.creatorParentProcessId,
				machineId: event.machineId,
				actionTime: event.creatorParentProcessCreationTime,
				actionType: 'CreateProcess',
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			};

			event.graphRootNode.children.push(currentEvent);

			currentNode = event.graphRootNode.children[0];
		}

		if (event.creatorName) {
			currentEvent = {
				name: event.creatorName,
				sha1: event.creatorSha1,
				valid: event.creatorSha1Valid,
				signer: event.creatorSigner,
				isValidCertificate: event.creatorIsValidCertificate,
				isCertificateDataPopulated: event.isCreatorCertificateDataPopulated,
				path: event.creatorPath,
				command: event.creatorCommand,
				processTokenElevation: event.creatorTokenElevation,
				processIntegrityLevel: event.creatorIntegrityLevel,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.creatorCreationTime,
				actionType: 'CreateProcess',
				accountName: event.creatorAccountName,
				processId: event.creatorProcessId,
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			};

			if (currentNode) {
				currentNode.children.push(currentEvent);
				currentNode = currentNode.children[0];
			} else {
				event.graphRootNode.children.push(currentEvent);
				currentNode = event.graphRootNode.children[0];
			}
		}

		// only if it's a process that has a parent, add the creator process parent name
		// => We didn't find the event, but have the details in the original one
		if (event.parentProcessName && !event.creatorName && !event.isParentProcessAbsent) {
			currentEvent = {
				name: event.parentProcessName,
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			};

			if (currentNode) {
				currentNode.children.push(currentEvent);
				currentNode = currentNode.children[0];
			} else {
				event.graphRootNode.children.push(currentEvent);
				currentNode = event.graphRootNode.children[0];
			}
		}

		// if we didn't find a parent to the creator process, check if it's an absent process. if yes - add only the creator and build a 2 level tree
		if (!currentNode && event.isParentProcessAbsent) {
			currentNode = {
				name: event.processName || 'Unknown',
				sha1: event.processSha1,
				valid: event.processSha1Valid,
				command: event.processCommand,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.processCreationTime,
				accountName: event.processAccountName,
				processId: event.processId,
				processTokenElevation: event.processTokenElevation,
				processIntegrityLevel: event.processIntegrityLevel,
				actionType: 'CreateProcess',
				path: event.processPath,
				signer: event.processSigner,
				isValidCertificate: event.processIsValidCertificate,
				isCertificateDataPopulated: event.isProcessCertificateDataPopulated,
				notDirectChild: event.notDirectChild,
				remoteText: resolveRemoteText(event),
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			};
			event.graphRootNode.children.push(currentNode);
		}

		// if the event doesn't have a ParentProcessName but it's not an absent process - add an unknown parent node.
		if (!currentNode) {
			currentEvent = {
				name: event.parentProcessName || 'Unknown',
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			};
			event.graphRootNode.children.push(currentEvent);
			currentNode = event.graphRootNode.children[0];
		}

		// if the creator process has a parent, we already have a current node and we push another level to the tree of the creator process details
		if (!event.isParentProcessAbsent) {
			currentNode.children.push({
				name: event.processName || 'Unknown',
				sha1: event.processSha1,
				valid: event.processSha1Valid,
				command: event.processCommand,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.processCreationTime,
				accountName: event.processAccountName,
				processId: event.processId,
				processTokenElevation: event.processTokenElevation,
				processIntegrityLevel: event.processIntegrityLevel,
				actionType: 'CreateProcess',
				path: event.processPath,
				signer: event.processSigner,
				isValidCertificate: event.processIsValidCertificate,
				isCertificateDataPopulated: event.isProcessCertificateDataPopulated,
				notDirectChild: event.notDirectChild,
				remoteText: resolveRemoteText(event),
				icon: 'icon-Settings',
				type: 'file',
				children: [],
			});

			currentNode = currentNode.children[0];
		}

		if (event.associatedSha1AndFileNamesCount > 1) {
			let eventsCount = event.associatedSha1AndFileNamesCount;
			if (event.type == 'BehaviorEvent') {
				eventsCount = event.reportIndexCount;
			}

			currentNode.children.push({
				name: 'files',
				sha1: event.sha1,
				path: event.path,
				valid: event.valid,
				icon: 'icon-Page',
				filesAndSha1: event.associatedSha1AndFileNames,
				filesAndSha1Count: eventsCount,
				multipleFiles: true,
				type: 'file',
				o365MailSender: event.o365MailSender,
				o365MailSubject: event.o365MailSubject,
				o365MailRecipients: event.o365MailRecipients,
				o365MailReceived: event.o365MailReceived,
				o365MailIoAType: event.o365MailIoAType,
				o365MailIoAIdentifier: event.o365MailIoAIdentifier,
				o365MailDeepLink: event.o365MailDeepLink,
				children: [],
			});

			currentNode = currentNode.children[0];
		} else if (event.associatedSha1AndFileNamesCount == 1) {
			currentNode.children.push({
				name: event.filename,
				sha1: event.sha1,
				signer: event.signer,
				path: event.path,
				valid: event.valid,
				isValidCertificate: event.isValidCertificate,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.time,
				actionType: 'CreateFile',
				isCertificateDataPopulated: event.isCertificateDataPopulated,
				icon: 'icon-Page',
				type: 'file',
				o365MailSender: event.o365MailSender,
				o365MailSubject: event.o365MailSubject,
				o365MailRecipients: event.o365MailRecipients,
				o365MailReceived: event.o365MailReceived,
				o365MailIoAType: event.o365MailIoAType,
				o365MailIoAIdentifier: event.o365MailIoAIdentifier,
				o365MailDeepLink: event.o365MailDeepLink,
				lastChild: true,
				reportIndexCount: event.reportIndexCount,
				children: [],
			});

			currentNode = currentNode.children[0];
		}

		if (event.associatedSha1AndProcessNamesCount > 1) {
			let eventsCount = event.associatedSha1AndProcessNamesCount;
			if (event.type == 'BehaviorEvent') {
				eventsCount = event.reportIndexCount;
			}
			currentNode.children.push({
				name: 'processes',
				sha1: event.sha1,
				path: event.path,
				valid: event.valid,
				icon: 'icon-Settings',
				filesAndSha1: event.associatedSha1AndProcessNames,
				filesAndSha1Count: eventsCount,
				multipleFiles: true,
				type: 'file',
				children: [],
			});

			currentNode = currentNode.children[0];
		} else if (event.associatedSha1AndProcessNamesCount == 1) {
			currentNode.children.push({
				name: event.childProcessName,
				sha1: event.childProcessSha1,
				valid: event.childProcessSha1Valid,
				command: event.childProcessCommand,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.time,
				accountName: event.accountName,
				processTokenElevation: event.tokenElevation,
				processIntegrityLevel: event.integrityLevel,
				processId: event.childProcessId,
				actionType: 'CreateProcess',
				path: event.childProcessPath,
				signer: event.signer,
				isValidCertificate: event.isValidCertificate,
				isCertificateDataPopulated: event.isCertificateDataPopulated,
				icon: 'icon-Settings',
				type: 'file',
				o365MailSender: event.o365MailSender,
				o365MailSubject: event.o365MailSubject,
				o365MailRecipients: event.o365MailRecipients,
				o365MailReceived: event.o365MailReceived,
				o365MailIoAType: event.o365MailIoAType,
				o365MailIoAIdentifier: event.o365MailIoAIdentifier,
				o365MailDeepLink: event.o365MailDeepLink,
				lastChild: true,
				reportIndexCount: event.reportIndexCount,
				children: [],
			});

			currentNode = currentNode.children[0];
		}

		if (event.destinationIpsCount > 1) {
			let eventsCount = event.destinationIpsCount;
			if (event.type == 'BehaviorEvent') {
				eventsCount = event.reportIndexCount;
			}

			currentNode.children.push({
				name: 'IPs',
				icon: 'icon-Streaming',
				destinationIps: event.destinationIps,
				destinationIpsCount: event.destinationIpsCount,
				multipleIps: true,
				type: 'ip',
				children: [],
			});

			currentNode = currentNode.children[0];
		} else if (event.destinationIpsCount == 1) {
			currentNode.children.push({
				ip: event.destinationIp,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.time,
				actionType: 'NetworkConnection',
				icon: 'icon-Streaming',
				type: 'ip',
				destinationDnsRecordName: event.destinationDnsRecordName,
				protocol: event.protocol,
				destinationPort: event.destinationPort,
			});
			currentNode = currentNode.children[0];
		}

		if (event.registryEventsCount == 1) {
			currentNode.children.push({
				registryOperation: event.registryEvent.RegistryOperation,
				readableOperation: getReadableRegistryOperation(
					event.registryEvent.RegistryOperation,
					event.registryEvent.RegistryValueType
				),
				valueData: event.registryEvent.RegistryValueData,
				valueName: event.registryEvent.RegistryValueName,
				name: event.registryEvent.RegistryValueName,
				key: event.registryEvent.RegistryKey,
				previousValueData: event.registryEvent.RegistryPreviousValueData,
				previousValueName: event.registryEvent.RegistryPreviousValueName,
				previousKey: event.registryEvent.RegistryPreviousKey,
				valueType: event.registryEvent.RegistryValueType,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.time,
				actionType: 'Registry',
				icon: 'icon-OEM',
				type: 'registry',
				children: [],
			});

			currentNode = currentNode.children[0];
		}

		if (event.registryEventsCount > 1) {
			for (let i = 0; i < event.registryEventsCount; i++) {
				currentNode.children.push({
					registryOperation: event.registryEvents[i].RegistryOperation,
					readableOperation: getReadableRegistryOperation(
						event.registryEvents[i].RegistryOperation,
						event.registryEvents[i].RegistryValueType
					),
					valueData: event.registryEvents[i].RegistryValueData,
					valueName: event.registryEvents[i].RegistryValueName,
					name: event.registryEvents[i].RegistryValueName,
					key: event.registryEvents[i].RegistryKey,
					previousValueData: event.registryEvents[i].RegistryPreviousValueData,
					previousValueName: event.registryEvents[i].RegistryPreviousValueName,
					previousKey: event.registryEvents[i].RegistryPreviousKey,
					valueType: event.registryEvents[i].RegistryValueType,
					machineId: event.machineId,
					eventTime: event.time,
					actionTime: event.registryEvents[i].EventTime,
					actionType: 'Registry',
					icon: 'icon-OEM',
					type: 'registry',
					children: [],
				});
			}
		}

		if (event.type == 'ProcessCreate' || event.type == 'EtwGenericEvent') {
			currentNode.children.push({
				name: genericEtwService.getEtwMachineProcessTreeNodeName(event),
				sha1: event.sha1,
				path: event.path,
				valid: event.valid,
				signer: event.signer,
				isValidCertificate: event.isValidCertificate,
				isCertificateDataPopulated: event.isCertificateDataPopulated,
				command: event.createdProcessCommand,
				machineId: event.machineId,
				eventTime: event.time,
				actionTime: event.time,
				processTokenElevation: event.tokenElevation,
				processIntegrityLevel: event.integrityLevel,
				processId: event.createdProcessId,
				accountName: event.accountName,
				actionType: event.type,
				icon: genericEtwService.getEtwMachineProcessTreeNodeIcon(event),
				processTreeNodeContentHeaderIcon: genericEtwService.getEtwIcons(event.etwType)
					.machineProcessTreeContentHeaderIcon,
				type: 'file',
				children: [],
				processTreeNodeContentHeader: genericEtwService.getEtwMachineProcessTreeNodeDescription(
					event
				),
				EventPropertiesAsJson: event.etwJsonProperties,
				etwType: event.etwType,
				threat: genericEtwService.getEtwMachineProcessTreeThreat(event),
				encyclopediaLink: genericEtwService.getEtwMachineProcessTreeEncyclopediaLink(event),
				processTreeNodeContent: genericEtwService.getEtwMachineProcessTreeNodeContent(event),
				Uri: event.Uri,
				EtwEventShowNodeContentOnly: genericEtwService.getEtwShowNodeContentOnly(event),
				EtwEventAvoidUrlSidePane: genericEtwService.getEtwEventAvoidUrlSidePane(event),
			});
		}

		if (event.type === 'LogonEvent') {
			if (!event.processName && !event.parentProcessName) {
				// avoid showing an empty process tree
				event.graphRootNode.children = [];
				currentNode = event.graphRootNode;
			}

			if (event.remoteComputerName || event.remoteIp || event.remotePort) {
				currentNode.children.push({
					type: 'logon',
					icon: 'icon-Streaming',
					remoteComputerName: event.remoteComputerName,
					remoteIp: event.remoteIp,
					remotePort: event.remotePort,
				});
			}
		}

		if (event.type == 'LoadImage') {
			currentNode.children.push({
				name: event.filename,
				sha1: event.sha1,
				path: event.path,
				valid: event.valid,
				signer: event.signer,
				isValidCertificate: event.isValidCertificate,
				isCertificateDataPopulated: event.isCertificateDataPopulated,
				command: event.createdProcessCommand,
				accountName: event.AccountName,
				machineId: event.machineId,
				actionTime: event.time,
				actionType: event.type,
				icon: 'icon-Processing',
				type: 'file',
				children: [],
			});
		}

		delete event.files;
	}

	function getReadableRegistryOperation(operation, valueType) {
		switch (operation) {
			case 'DeleteValue':
				return 'Value deleted';
			case 'DeleteKey':
				return 'Key deleted';
			case 'CreateKey':
				return 'Key created';
			case 'RenameKey':
				return 'Key renamed';
			case 'SetValue':
			default:
				return valueType !== 'Binary' ? 'Value set' : 'Binary value set';
		}
	}

	function resolveRemoteText(event) {
		if (event.type === 'EtwGenericEvent') {
			return genericEtwService.getEtwMachineProcessTreeUndirectChildRemoteText(event);
		} else if (
			event.type === 'NetworkEvent' &&
			event.networkEventType === 'InboundTcpConnectionAccepted'
		) {
			return 'Accepted connection';
		}
		return null;
	}

	function initSlider() {
		if (!vm.fullPagingExperience) {
			return;
		}

		const ticksArray = [];
		const today = new Date();
		let currentDate = new Date();
		const minDate = new Date();
		minDate.setMonth(minDate.getMonth() - 6); // the date 6 months ago
		const totalOffset = caculateDaysOffset(currentDate, minDate);
		let value = totalOffset;
		ticksArray.push(totalOffset); // today
		while (true) {
			if (currentDate.getDate() !== 1) {
				const firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
				const offset = caculateDaysOffset(today, firstOfMonth);
				currentDate = firstOfMonth;
				ticksArray.push(totalOffset - offset);

				continue;
			}

			currentDate.setMonth(currentDate.getMonth() - 1);
			if (currentDate < minDate) {
				break;
			}
			const offset = caculateDaysOffset(today, currentDate);
			ticksArray.push(totalOffset - offset);
		}

		ticksArray.sort(function(a, b) {
			return a - b;
		});

		vm.sliderValue = ticksArray[ticksArray.length - 1];
		const minTicksMarginForLabel = 10;

		const localize = localeConfigService.isLocalTimeZone;

		// init time picker
		vm.timePickerModel = {
			isTimePickerEnabled: featuresService.isEnabled('MachineTimelineDatePicker'),
			minDate: moment(minDate),
			maxDate: localize ? moment().endOf('day') : moment.utc().endOf('day'),
			dateFormat: localeService.getSpecificDateFormat('YYYY', 'MM', 'DD', '.') + ' | HH:mm',
			utc: !localize,
			onLostFocus: onTimePickerLostFocus,
		};

		vm.sliderOptions = {
			showTicks: true,
			ceil: totalOffset,
			showSelectionBar: true,
			showTicksValues: true,
			hideLimitLabels: true,
			keyboardSupport: true,
			ticksArray: ticksArray,
			customTemplateScope: vm.timePickerModel,
			translate: function(value, sliderId, label) {
				let d: Date;

				switch (label) {
					case 'model':
						d = new Date();
						SevilleUtils.date.endOfDay(d);
						d.setDate(d.getDate() - (totalOffset - value));

						return sevilleDateFilter(d, 'date');
					case 'tick-value':
						if (value === totalOffset) {
							return 'Today';
						}
						if (totalOffset - value < minTicksMarginForLabel) {
							return '';
						}

						d = new Date();
						SevilleUtils.date.endOfDay(d);
						d.setDate(d.getDate() - (totalOffset - value));

						if (localize) {
							return $filter('date')(d, 'MMM yyyy');
						} else {
							return $filter('date')(d, 'MMM yyyy', 'UTC');
						}
					default:
						break;
				}
			},
			onEnd: function(sliderId, modelValue, highValue) {
				value = modelValue;
				const d = new Date();
				SevilleUtils.date.endOfDay(d);
				d.setDate(d.getDate() - (totalOffset - modelValue));
				if (!localize) {
					SevilleUtils.date.addTZOffset(d);
				}
				updateDateInUrl(d);
				navigateToDate(d, true, false);
			},
		};

		if (vm.timePickerModel.isTimePickerEnabled) {
			vm.sliderOptions.onChange = function(sliderId, modelValue, highValue, pointerType) {
				// Called while dragging the slider handle. Update time picker to the selected day
				const d = new Date();
				SevilleUtils.date.endOfDay(d);
				d.setDate(d.getDate() - (totalOffset - modelValue));
				if (!localize) {
					SevilleUtils.date.addTZOffset(d);
				}
				vm.timePickerModel.selectedDate = moment(d);
			};
		}

		vm.startDate = new Date();
		const stateDate = $state.current.data ? $state.current.data.slider : null;
		const savedStateDate = stateDate || investigationService.getState('slider');
		if (savedStateDate && $.type(savedStateDate) === 'string') {
			throw new Error("slider date can't be string");
		}

		if (savedStateDate) {
			if ($state.current.data && $state.current.data.slider) {
				delete $state.current.data.slider;
			}

			savedStateDate.setMilliseconds(savedStateDate.getMilliseconds() + 10);
			vm.startDate = savedStateDate;
		} else if ($stateParams.time) {
			const timestamp = Date.parse($stateParams.time);
			if (isNaN(timestamp) === false) {
				vm.startDate = new Date(timestamp);
			}
		}

		vm.sliderValue = resolveTickIndexFromDate(vm.startDate);
		vm.sliderInitialized = true;
		vm.timePickerModel.selectedDate = moment(vm.startDate);
		navigateToDate(vm.startDate, true, false);
	}

	function updateIsSliderInColdStorage() {
		vm.isSliderInColdStorage = coldStorage.isInColdStorage(vm.startDate);
	}

	function navigateToDate(date, evenIfLoading?, updateSlider?, tryLoadEventsFromCache?) {
		if (!vm.sliderOptions || (!evenIfLoading && vm.loading > 0)) {
			return;
		}
		if (!vm.sliderInitialized) {
			return;
		}

		if (updateSlider) {
			updateSliderHandle(date);
		}

		// On a datepicker change - If the events are cached locally, satisfy from cache
		// to prevent reloading on a +/- few minutes change.
		// do not take from cache if change was not made in the date picker as there might be filters changes that do require
		// timeline reloading
		const eventIndex = tryLoadEventsFromCache ? getLastEventBefore(date) : -1;
		if (eventIndex >= 0 && eventIndex <= vm.filteredEvents.length - vm.recordsPerPage) {
			vm.skipRecords = eventIndex;
			arrangeEventsByDate();

			if (vm.skipRecords < vm.recordsPerPage * 3) {
				vm.loadPrevPage();
			}
			if (vm.skipRecords > vm.filteredEvents.length - vm.recordsPerPage * 3) {
				vm.loadNextPage();
			}
		} else {
			reset();

			abortPendingRequests();

			vm.startDate = date;
			updateIsSliderInColdStorage();

			if (vm.fullPagingExperience) {
				vm.loadEvents()(date, 'desc', vm.searchCriterias).then(addDescEvents);
				vm.loadEvents()(date, 'asc', vm.searchCriterias).then(addAscEvents);
			}
		}
	}

	function updateDateInUrl(time) {
		investigationService.saveState('slider', new Date(time));
		const d = new Date(time);
		const newIsoString = d.toISOString();
		if (newIsoString === $stateParams.time) {
			return;
		}
		$stateParams.time = newIsoString;

		if (vm.notFirstUrlLoad) {
			$state.go($state.current.name, $stateParams, {
				// prevent the events onStart and onSuccess from firing
				notify: false,
				// prevent reload of the current state
				reload: false,
				// replace the last record when changing the params so you don't hit the back button and get old params
				location: 'replace',
				// inherit the current params on the url
				inherit: true,
			});
		}

		vm.notFirstUrlLoad = true;
	}

	function caculateDaysOffset(firstDate, secondDate) {
		SevilleUtils.date.endOfDay(firstDate);
		SevilleUtils.date.endOfDay(secondDate);

		const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / oneDay));
	}

	function resolveTickIndexFromDate(date) {
		const today = new Date();
		let sliderDate;
		if ($.type(date) === 'string') {
			if (date.length === 10) {
				date = date.replace('-', '.', 'g').replace('-', '.', 'g');
				const tokens = date.split('.');

				sliderDate = new Date(tokens[2], tokens[0] - 1, tokens[1]);
			} else {
				const d = new Date(date);
				sliderDate = new Date(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate(), 13, 59, 59, 999);
			}
		} else {
			const givenDate = new Date(date);
			sliderDate = givenDate;
		}

		if (localeConfigService.isLocalTimeZone) {
			SevilleUtils.date.addTZOffset(sliderDate);
		}

		const maxTick = vm.sliderOptions.ticksArray[vm.sliderOptions.ticksArray.length - 1];
		const offset = caculateDaysOffset(today, sliderDate);

		return maxTick - offset;
	}

	function getLastEventBefore(date) {
		const time = new Date(date).getTime();

		if (vm.filteredEvents && vm.filteredEvents.length >= 2) {
			let low = 0,
				high = vm.filteredEvents.length - 1;

			// The events are sorted by time, descending
			const latest = moment(vm.filteredEvents[low].time).toDate();
			const first = moment(vm.filteredEvents[high].time).toDate();

			if (latest.getTime() > time && first.getTime() <= time) {
				while (high - low > 1) {
					const mid = Math.floor((high + low) / 2);
					const midTime = moment(vm.filteredEvents[mid].time).toDate();
					if (midTime.getTime() > time) {
						low = mid;
					} else {
						high = mid;
					}
				}

				return high;
			}
		}

		return -1;
	}

	function filterEvents() {
		vm.filteredEvents = vm.events;
	}

	function addDescEvents(newEvents) {
		if (newEvents && newEvents.length > 0) {
			vm.events = vm.events.concat(newEvents);

			filterEvents();
			arrangeEventsByDate();
		}
	}

	function addAscEvents(newEvents) {
		if (newEvents && newEvents.length > 0) {
			newEvents.reverse();
			vm.events = newEvents.concat(vm.events);
			vm.skipRecords += newEvents.length;

			filterEvents();
			arrangeEventsByDate();
		}
	}

	function reset() {
		vm.skipRecords = 0;
		vm.events = [];
		vm.eventsWithDates = [];
		vm.filteredEvents = [];
	}

	function arrangeEventsByDate() {
		if (!vm.events) {
			vm.eventsWithDates = [];

			return;
		}

		const minIndex = vm.skipRecords;
		const maxIndex = minIndex + vm.recordsPerPage;
		const localize = localeConfigService.isLocalTimeZone;
		const newCollection = [];
		let lastDate = '';
		for (let i = minIndex; i < maxIndex && i < vm.filteredEvents.length; i++) {
			const event = vm.filteredEvents[i];
			const date = sevilleDateFilter(event.time, 'date');

			if (date !== lastDate) {
				lastDate = date;
				newCollection.push({ dateGroup: date });
			}

			event.dateGroupName = date;

			applyFilters(event);
			newCollection.push(event);
		}

		vm.eventsWithDates = newCollection;
		updateSliderHandle();
	}

	function applyFilters(event) {
		event.filteredtime = sevilleDateFilter(event.time, 'time');
		event.behaviorEventIcon = behaviorTypeFilter(event.tableName, event.detectionRelatedBehavior);
		event.severityFiltered = severityFilter(event.severity);
		event.computerDnsNameFirstPart = partsFilter(event.computerDnsName, 1);
		event.wcdMachineId = wcdMachineIdFilter(event.wcdMachineId);
		event.isRemediatedTitle = remediationStatusFilter(event.isRemediated);
		event.parentProcessNameFiltered = filenameFilter(event.parentProcessName);
		if (event.reportIndexCount > 1) {
			event.pluralFormat = behaviorFilter(event.pluralFormat, event.reportIndexCount);
		}

		if (event.firstSeen) {
			event.filteredfirstSeen = sevilleDateFilter(event.firstSeen, 'full');
		}
	}

	function updateSliderHandle(date?) {
		if (!vm.fullPagingExperience) {
			return;
		}

		let topEvent;
		if (!date && (!vm.eventsWithDates || vm.eventsWithDates.length === 0)) {
			return;
		}

		topEvent = date || vm.eventsWithDates[1];
		updateDateInUrl(date || topEvent.time);
		const newSliderValue = resolveTickIndexFromDate(date || topEvent.time);
		if (vm.sliderValue !== newSliderValue) {
			vm.sliderValue = newSliderValue;
		}
		vm.timePickerModel.selectedDate = moment(date || topEvent.time);
	}

	function initEventTypesFilter() {
		vm.eventTypeOptions = filtersLocalStorage.machineTimelineEventTypeFilterOptions;

		vm.eventTypeValues = getEventFilterFromModel();

		vm.eventTypeSettings = {
			buttonClasses: 'btn btn-link events-timeline-filter-btn dropdown-toggle input-box ellipsis',
			dynamicTitle: true,
			showCheckAll: true,
			showUncheckAll: true,
			scrollable: false,
			smartButtonMaxItems: 4,
			displayProp: 'label',
			idProp: 'id',
			openIconClass: 'event-timeline-filter-icon',
			allSelectedText: 'All',
		};

		vm.eventTypeEvents = {
			onSelectionClosed: function() {
				vm.eventTypesFilter = getEventTypesFilterStringFromFilter();
			},
		};

		vm.eventsUifiltertext = {
			buttonDefaultText: 'All',
		};
	}

	function getEventFilterFromModel() {
		const filter = [];
		if (!vm.eventTypesFilter) {
			vm.eventTypesFilter = defaultEventTypeFilter;
		}

		vm.eventTypesFilter.split(',').forEach(function(element, index, array) {
			filter.push({
				id: element,
			});
		});

		return filter;
	}

	function getEventTypesFilterStringFromFilter() {
		let result = '';
		vm.eventTypeValues.forEach(function(element, index, array) {
			result = result.concat(element.id, ',');
		});

		if (!result) {
			vm.eventTypeValues = getEventFilterFromModel();
			return vm.eventTypesFilter;
		}
		return result.substring(0, result.length - 1);
	}

	function getContextFilterFromString() {
		const filter = [];
		if (!vm.usercontext) {
			vm.usercontext = deafultUserContext;
		}

		vm.usercontext.split(',').forEach(function(element, index, array) {
			filter.push({
				id: element,
			});
		});

		return filter;
	}

	function abortPendingRequests() {
		angular.forEach($http.pendingRequests, function(request) {
			if (request.url.indexOf('Timeline') > 0) {
				$http.abort(request);
			}
		});
	}

	function onTimePickerLostFocus(e) {
		if (vm.timePickerModel.selectedDate && e.changed) {
			const period = e.timeChanged ? 'minute' : 'day'; // jump to end of minute/day
			const date = vm.timePickerModel.selectedDate
				.clone()
				.endOf(period)
				.toDate();

			navigateToDate(date, true, true, true);
		}
	}

	function validateSearchCriteria(type, value) {
		switch (type) {
			case 'hash':
				if (
					RegExpService.md5.test(value) ||
					RegExpService.sha1.test(value) ||
					RegExpService.sha256.test(value)
				) {
					return true;
				} else {
					vm.searchErrorMsg = invalidValueErrorMsg;
				}
				return false;
			case 'cmdline':
			case 'path':
			case 'file':
				if (!value || value.length < 2) {
					vm.searchErrorMsg = invalidValueErrorMsg;
					return false;
				}
				return true;
			case 'url':
			case 'ext':
				if (!value || value.length < 1) {
					vm.searchErrorMsg = invalidValueErrorMsg;

					return false;
				}
				return true;
			case 'ip':
				if (RegExpService.ip.test(value)) {
					return true;
				} else {
					vm.searchErrorMsg = invalidValueErrorMsg;
					return false;
				}
			case 'user':
				if (!value || value.length < 1) {
					vm.searchErrorMsg = invalidValueErrorMsg;

					return false;
				}
				return true;
			default:
				$log.error('search criteria with of type' + type + ' is not supported.');
				return false;
		}
	}

	function applySelectedSearchCriteria(option) {
		const matchingOptions = vm.searchOptions.filter(function(op) {
			return op.title === option.title;
		});

		matchingOptions[0].selected = true;
	}

	function clearSearchCriterias() {
		vm.searchCriterias = [];
		vm.searchTags = [];

		for (let i = 0; i < vm.searchOptions.length; i++) {
			vm.searchOptions[i].selected = false;
		}

		vm.filtersApplied = vm.searchTags.length > 0;
	}

	function applySearchCriterias() {
		if (!vm.searchTags) {
			vm.searchCriterias = [];
			return;
		}

		vm.searchCriterias = [];
		for (let i = 0; i < vm.searchTags.length; i++) {
			const tag = vm.searchTags[i];
			switch (tag.key) {
				case 'hash':
					let field;
					if (RegExpService.md5.test(tag.value)) {
						field = 'MD5';
					} else if (RegExpService.sha1.test(tag.value)) {
						field = 'Sha1';
					} else if (RegExpService.sha256.test(tag.value)) {
						field = 'Sha256';
					}

					vm.searchCriterias.push({
						Operator: 'Equals',
						Term: tag.value,
						Field: field,
					});

					break;
				case 'file':
					vm.searchCriterias.push({
						Operator: 'Contains',
						Term: tag.value,
						Field: 'Filename',
					});

					break;
				case 'ext':
					vm.searchCriterias.push({
						Operator: 'EndsWith',
						Term: tag.value,
						Field: 'Filename',
					});
					break;
				case 'path':
					vm.searchCriterias.push({
						Operator: 'Contains',
						Term: tag.value,
						Field: 'Path',
					});
					break;
				case 'cmdline':
					vm.searchCriterias.push({
						Operator: 'Contains',
						Term: tag.value,
						Field: 'CommandLine',
					});
					break;
				case 'user':
					vm.searchCriterias.push({
						Operator: 'Contains',
						Term: tag.value,
						Field: 'AccountName',
					});
					break;
				case 'ip':
					vm.searchCriterias.push({
						Operator: 'Equals',
						Term: tag.value,
						Field: RegExpService.ipv4.test(tag.value) ? 'IPv4' : 'IPv6',
					});
					break;
				case 'url':
					vm.searchCriterias.push({
						Operator: 'Contains',
						Term: tag.value,
						Field: 'URL',
					});
					break;
				default:
					continue;
			}
		}

		vm.filtersApplied = vm.searchTags.length > 0;
		navigateToDate(vm.startDate, true, true);
	}

	function updateFilterTags() {
		// information level
		// remove existing
		vm.searchTags = vm.searchTags.filter(function(tag) {
			return tag.key !== 'info level';
		});

		// apply tag if different than the default
		if (vm.fidelity !== 'All') {
			let fidelityVal = 'verbose';
			switch (vm.fidelity) {
				case 'HiFi':
					fidelityVal = 'detections';
					break;
				case 'Behaviors':
					fidelityVal = 'behaviors';
					break;
			}
			vm.searchTags.push({
				key: 'info level',
				value: fidelityVal,
			});
		}

		// event type
		// remove existing
		vm.searchTags = vm.searchTags.filter(function(tag) {
			return tag.key !== 'event type';
		});

		// apply tag if different than the default
		const eventTypeValues = vm.eventTypesFilter.split(',');
		if (eventTypeValues.length < defaultEventTypeFilter.split(',').length) {
			for (let i = 0; i < eventTypeValues.length; i++) {
				const et = eventTypeValues[i];
				const eventTypeVal = vm.eventTypeOptions.filter(function(item) {
					return item.id === et;
				});

				if (eventTypeVal && eventTypeVal.length > 0 && eventTypeVal[0].label) {
					vm.searchTags.push({
						key: 'event type',
						value: eventTypeVal[0].label.toLowerCase(),
						id: et,
					});
				}
			}
		}

		// user account
		// remove existing
		vm.searchTags = vm.searchTags.filter(function(tag) {
			return tag.key !== 'userAccount';
		});

		// apply tag if different than the default
		const userFilterValues = vm.usercontext.split(',');
		if (userFilterValues.length !== deafultUserContext.split(',').length) {
			for (let i = 0; i < userFilterValues.length; i++) {
				const uf = userFilterValues[i];
				const userFilterVal = vm.userContextOptions.filter(function(item) {
					return item.id === uf;
				});

				if (userFilterVal && userFilterVal.length > 0 && userFilterVal[0].label) {
					vm.searchTags.push({
						key: 'userAccount',
						value: userFilterVal[0].label.toLowerCase(),
						id: uf,
					});
				}
			}
		}

		vm.filtersApplied = vm.searchTags.length > 0;
	}
}
