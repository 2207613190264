import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'License info',
	pluralName: 'License info',
})
export class WebContentFilteringLicenseStatusEntity extends ModelBase {
	@EntityField({ data: 'OrgId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'CreationTime' })
	creationDate: Date;

	@EntityField({ data: 'LastUpdateTime' })
	lastUpdateTime: Date;

	@EntityField({ data: 'ExpirationTime' })
	expirationTime: Date;

	@EntityField({ data: 'PartnerName' })
	partnerName: string;

	@EntityField({ data: 'PartnerId' })
	partnerId: string;

	@EntityField({ data: 'Category' })
	category: string;

	@EntityField({ data: 'Status' })
	status: string;

	@EntityField({ data: 'Context' })
	context: string;
}
