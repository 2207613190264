import { BaseAirsEntityAlertV3Fields } from '../alertV3-base-fields.enum';

export const enum FileEntityAlertV3Fields {
	Name = 'Name',
	FileHashes = 'FileHashes',
	SizeInBytes = 'SizeInBytes',
	LastWriteTimeUtc = 'LastWriteTimeUtc',
	Directory = 'Directory',
}

enum FileHashAlgorithm {
	MD5 = 1,
	SHA1 = 2,
	SHA256 = 3,
}

export function mapFileEntityToAirsEntity(entityData): any {
	const res = Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case FileEntityAlertV3Fields.Name:
					acc['name'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case FileEntityAlertV3Fields.SizeInBytes:
					const intSizeInBytes = parseInt(<string>val);
					acc['size'] = isNaN(intSizeInBytes) ? 0 : intSizeInBytes;
					break;
				case BaseAirsEntityAlertV3Fields.CreatedTimeUtc:
					acc['created_time'] = val;
					break;
				case FileEntityAlertV3Fields.LastWriteTimeUtc:
					acc['modified_time'] = val;
					break;
				case FileEntityAlertV3Fields.Directory:
					acc['directory_path'] = val;
					break;
				case FileEntityAlertV3Fields.FileHashes:
					const hashes =
						val &&
						(<Array<{ [key: string]: any }>>val).reduce((res, { Algorithm, Value }) => {
							const enumAlgorithm: any = FileHashAlgorithm[Algorithm];
							if (
								Algorithm === FileHashAlgorithm.MD5 ||
								enumAlgorithm === FileHashAlgorithm.MD5
							) {
								res['md5'] = Value;
							} else if (
								Algorithm === FileHashAlgorithm.SHA1 ||
								enumAlgorithm === FileHashAlgorithm.SHA1
							) {
								res['sha1'] = Value;
							} else if (
								Algorithm === FileHashAlgorithm.SHA256 ||
								enumAlgorithm === FileHashAlgorithm.SHA256
							) {
								res['sha256'] = Value;
							}
							return res;
						}, {});

					acc['hashes'] = hashes;
					if (hashes) {
						acc['md5'] = hashes.md5;
						acc['sha1'] = hashes.sha1;
						acc['sha256'] = hashes.sha256;
					}
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});

	const directory = res['directory_path'];
	const name = res['name'];
	const path = name ? (directory ? `${directory}\\${name}` : name) : '';
	return Object.assign(res, { path: path });
}
