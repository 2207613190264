var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { AlertIncidentGraphItemEntity } from './incident-graph-item-entity';
var ɵ0 = function (data) {
    return {
        id: data.PrimaryEntityId,
        name: data.PrimaryEntityDisplayName,
        type: data.PrimaryEntityType,
    };
}, ɵ1 = function (data) {
    return {
        id: data.SatalliteEntityId,
        name: data.SatalliteEntityDisplayName,
        type: data.SatalliteEntityType,
    };
};
/**
 * old alert incident graph element. please use "cyber-event" model.
 * @deprecated
 */
var AlertIncidentGraphElement = /** @class */ (function (_super) {
    __extends(AlertIncidentGraphElement, _super);
    function AlertIncidentGraphElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AlertIncidentGraphElement_1 = AlertIncidentGraphElement;
    var AlertIncidentGraphElement_1;
    __decorate([
        EntityField({ data: 'IsAssociatedWithAlerts', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AlertIncidentGraphElement.prototype, "isAssociatedWithAlert", void 0);
    __decorate([
        EntityField({
            require: 'SubElements',
            arrayOf: AlertIncidentGraphElement_1,
            data: 'SubElements',
        }),
        __metadata("design:type", Array)
    ], AlertIncidentGraphElement.prototype, "childElements", void 0);
    __decorate([
        EntityField({
            require: 'PrimaryEntityId',
            data: '__self',
            parse: ɵ0,
        }),
        __metadata("design:type", AlertIncidentGraphItemEntity)
    ], AlertIncidentGraphElement.prototype, "primaryEntity", void 0);
    __decorate([
        EntityField({
            require: 'SatalliteEntityId',
            data: '__self',
            parse: ɵ1,
        }),
        __metadata("design:type", AlertIncidentGraphItemEntity)
    ], AlertIncidentGraphElement.prototype, "satelliteEntity", void 0);
    AlertIncidentGraphElement = AlertIncidentGraphElement_1 = __decorate([
        ValueObject({
            singularName: 'Alert Incident Graph Element',
            pluralName: 'Alert Incident Graph Elements',
        })
    ], AlertIncidentGraphElement);
    return AlertIncidentGraphElement;
}(ModelBase));
export { AlertIncidentGraphElement };
export { ɵ0, ɵ1 };
