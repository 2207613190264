import { Incident, IncidentLinkedByEntityTypeMapper } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { EntityNameComponent } from '../../../../global_entities/components/entity-name/entity-name.component';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { AlertIncidentService } from '../../../alerts/services/alert-incident.service';
var AssociatedIncidentsFields = /** @class */ (function () {
    function AssociatedIncidentsFields(i18nService, entityTypeService, alertIncidentService) {
        this.i18nService = i18nService;
        this.entityTypeService = entityTypeService;
        this.alertIncidentService = alertIncidentService;
    }
    Object.defineProperty(AssociatedIncidentsFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataTableField.fromList([
                    {
                        id: 'incidentId',
                        name: this.i18nService.get('incident.associatedIncidents.table.fields.incident.id'),
                        sort: { enabled: false },
                        component: {
                            type: EntityNameComponent,
                            getProps: function (incident) {
                                return {
                                    entity: { id: incident.targetIncidentId },
                                    /**The EntityTypeService can't resolve 'RelatedIncident' (Not a real 'Incident').
                                     * Since 'EntityNameComponent' only requires the incident id to generate a URL, we mimic the incident type by providing it alone **/
                                    entityTypeId: _this.entityTypeService.getEntityType(Incident).entity,
                                    entityName: incident.targetIncidentId,
                                    hideIcon: true,
                                };
                            },
                        },
                    },
                    {
                        id: 'reason',
                        name: this.i18nService.get('incident.associatedIncidents.table.fields.reason'),
                        sort: { enabled: false },
                        className: 'nowrap wcd-text-overflow-medium',
                        getDisplay: function (incident) {
                            return _this.alertIncidentService.getCategoryDisplayText(incident.category);
                        },
                        getTooltip: function (incident) {
                            return _this.alertIncidentService.getCategoryDisplayText(incident.category);
                        },
                    },
                    {
                        id: 'entity',
                        name: this.i18nService.get('incident.associatedIncidents.table.fields.entity'),
                        sort: { enabled: false },
                        component: {
                            type: EntityNameComponent,
                            getProps: function (incident) {
                                var rawType = IncidentLinkedByEntityTypeMapper[incident.entity.type];
                                return incident
                                    ? {
                                        entity: incident.entity,
                                        entityName: incident.entity.name,
                                        entityTypeId: _this.entityTypeService.getEntityType(rawType).entity,
                                        hideIcon: true,
                                    }
                                    : null;
                            },
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return AssociatedIncidentsFields;
}());
export { AssociatedIncidentsFields };
