import {
	ChangeDetectorRef,
	Component,
	ComponentFactoryResolver,
	Inject,
	Input,
	OnDestroy,
	OnInit,
} from '@angular/core';
import { catchError, delay, map, switchMap, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import {
	OnboardingState,
	PANEL_SERVICE_INJECTION_TOKEN,
	SccOnboardingData,
	SccOnboardingPanelService,
} from '../models/onboarding.models';
import { OnboardingService } from '../services/onboarding.service';
import { from, of, Subscription, throwError } from 'rxjs';
import { tenantContextCache } from '@wcd/auth';
import { Router } from '@angular/router';
import { breadcrumbsStateService } from '@wcd/shared';
import { ChecklistValue } from '@wcd/forms';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { MessageBarStyles } from '../../../../../../apps/portal/src/app/@entities/@tvm/common/styles';
import { AppConfigService } from '@wcd/app-config';
import { Utilities } from '../services/utilities';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { FeaturesService, Feature } from '@wcd/config';

const ONBOARD_URL = '/onboard_mtp';

// Events for the onboarding experience with an explicit consent (check box)
const SAW_ONBOARDING_LANDING_PAGE = 'MtpOnboarding-SawOnboardingLandingPage';
const CLICKED_ACCEPT = 'MtpOnboarding-ClickedAccept';

// Event for onboarding experience with contractual consent
const SAW_CONTRACTUAL_CONSENT_ONBOARDING_PAGE = 'MtpOnboarding-SawContractualConsentOnboardingPage';

// Event to be reported when onboarding completes (either on explicit or contractual onboarding experience)
const DONE = 'MtpOnboarding-Done';
const SAW_ERROR_VIEW = 'MtpOnboarding-SawErrorView';
const START_DELAY_POST_ONBOARDING = 'MtpOnboarding-StartDelayPostOnboarding';
const END_DELAY_POST_ONBOARDING = 'MtpOnboarding-EndDelayPostOnboarding';

// Events for the re-opt-in experience: tenants that opted-out and are now re-opting-in.
const SAW_RE_OPTIN_PAGE = 'MtpOnboarding-SawReOptInPage';
const ONBOARDED_TENANT_RE_ENABLED_MTP = 'MtpOnboarding-OboardedTenantReEnabledMtp';

// Events for op-in
const OPTIN_BEFORE = 'MtpOnboarding-OptIn-Before';

// Events to be reported for onboarding statuses
const STARTONBOARDING_BEFORE = 'MtpOnboarding-StartOnboarding-Before';
const STARTONBOARDING_AFTER_GET_TENANTCONTEXT = 'MtpOnboarding-StartOnboarding-AfterGetTenantContext';
const STARTONBOARDING_AFTER_ISINROLE = 'MtpOnboarding-StartOnboarding-AfterIsInRole';
const STARTONBOARDING_AFTER_OPTIN = 'MtpOnboarding-StartOnboarding-AfterOptIn';
const STARTONBOARDING_FINISH = 'MtpOnboarding-StartOnboarding-Finish';

@Component({
	selector: 'mtp-onboarding',
	template: `
		<ng-container
			*ngIf="!onboardingV3ContractualConsent; else contractualConsent"
			class="wcd-padding-all"
		>
			<fab-message-bar
				*ngIf="this.showErrorMessage"
				[messageBarType]="MessageBarType.error"
				contentClass="wcd-padding-small-all"
			>
				{{ 'sccOnboarding.mtpOnboarding.errorMessage' | i18n }}
			</fab-message-bar>
			<div
				class="wcd-flex-justify-content-space-between wcd-flex-vertical welcome-content wcd-padding-xxLarge-vertical"
				[class.wcd-padding-xxLarge-horizontal]="!isScc"
			>
				<div class="wcd-flex-horizontal">
					<div class="wcd-flex-3">
						<h1 class="wcd-font-size-su wcd-font-weight-bold header-title">
							{{ 'sccOnboarding.mtpOnboarding.title' | i18n }}
						</h1>
						<div class="wcd-margin-xLarge-top">
							<markdown [data]="'sccOnboarding.mtpOnboarding.description' | i18n"></markdown>
						</div>
						<div class="wcd-margin-medium-top">
							<div class="wcd-margin-xsmall-bottom" *ngIf="!isMtpOnboardingAllowed">
								<markdown
									[data]="'sccSettings.mtpConsent.toggleNoPermissions' | i18n"
								></markdown>
							</div>
							<fab-checkbox
								[disabled]="isConsentCheckboxDisabled || !isMtpOnboardingAllowed"
								[checked]="this.isConsentCheckboxChecked"
								[label]="consentCheckboxText"
								(onChange)="onCheckboxChange()"
							></fab-checkbox>
							<div class="wcd-padding-large-top">
								<markdown [data]="geoRegionText"></markdown>
							</div>
						</div>
						<div class="wcd-padding-large-top" *ngIf="duringOnboarding">
							<fab-progress-indicator></fab-progress-indicator>
							<div class="wcd-padding-medium-top wcd-font-size-m wcd-font-weight-bold">
								{{ 'sccOnboarding.mtpOnboarding.sitTightText' | i18n }}
							</div>
							<div class="wcd-padding-small-top">
								{{ 'sccOnboarding.mtpOnboarding.durationInfo' | i18n }}
							</div>
						</div>
					</div>
				</div>
				<div class="wcd-padding-mediumSmall-top wcd-border-top-light">
					<fab-primary-button
						[text]="'buttons.accept' | i18n"
						[disabled]="!isConsentCheckboxChecked || isConsentCheckboxDisabled"
						(onClick)="onAccept()"
					>
					</fab-primary-button>
				</div>
			</div>
		</ng-container>

		<ng-template #contractualConsent>
			<div
				class="wcd-full-height wcd-flex-horizontal wcd-scroll-vertical"
				[class.wcd-padding-xxLarge-horizontal]="!isScc"
			>
				<div class="wcd-flex-3 wcd-flex-vertical wcd-flex-center-all">
					<img [src]="getImagePath()" [alt]="getHeaderText()" />
					<div class="wcd-padding-large-vertical">
						<h1 class="wcd-font-size-su wcd-font-weight-bold header-title text-center">
							{{ getHeaderText() }}
						</h1>
					</div>
					<fab-spinner
						*ngIf="!this.showErrorMessage"
						[label]="'common.loading' | i18n"
						[size]="SpinnerSize.large"
						ng
						contentClass="wcd-flex-center-all"
					></fab-spinner>
					<div class="wcd-flex-horizontal">
						<markdown
							class="wcd-padding-medium-top wcd-padding-xxLarge-horizontal text-center"
							[ngClass]="{ 'wcd-flex-3': !this.showErrorMessage }"
							[data]="getDetailsMessageText()"
						>
						</markdown>
					</div>
				</div>
			</div>
		</ng-template>

		<ng-template #contractualConsentErrorView> </ng-template>
	`,
})
export class MtpOnboardingComponent implements OnInit, OnDestroy {
	readonly SpinnerSize = SpinnerSize;
	@Input() onboardingState: OnboardingState;
	@Input() redirectTo: string;

	showErrorMessage = false;

	isMtpOnboardingAllowed: boolean;
	alreadyOnboarded = false;
	showOnboardPage = false;

	consentCheckboxText: string;
	isConsentCheckboxChecked: boolean;
	duringOnboarding = false;

	isConsentCheckboxDisabled = false;
	geoRegionText: string;

	onboardingSubscription$: Subscription;

	MessageBarType = MessageBarType;
	messageBarStyles = MessageBarStyles;

	sccOnboardingData: SccOnboardingData;

	onboardingV3ContractualConsent: boolean;

	isScc = sccHostService.isSCC;

	constructor(
		@Inject(PANEL_SERVICE_INJECTION_TOKEN) private readonly panelService: SccOnboardingPanelService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private i18n: I18nService,
		private onboardingService: OnboardingService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService
	) {
		this.consentCheckboxText = this.i18n.get('sccOnboarding.mtpOnboarding.checkboxText');
		this.isMtpOnboardingAllowed = tenantContextCache.appConfig.IsPermittedOnboarding;
		this.geoRegionText = this.getGeoRegionText();
		this.showOnboardPage = this.router.url === ONBOARD_URL;
	}

	ngOnInit() {
		breadcrumbsStateService.reset();

		// forceExplicitMtpConsent is an indication from BE that the tenant has opted-out (through the support channel) and shouldn't be exposed again to the Contractual Consent (implicit) view
		this.onboardingV3ContractualConsent = !this.featuresService.isEnabled(
			Feature.ForceExplicitMtpConsent
		);
		this.onboardingV3ContractualConsent
			? this.initContractualConsentView()
			: this.initExplicitConsentView();
	}

	initExplicitConsentView(): void {
		this.setOnboardingInitialView();

		const eventToTrack =
			this.onboardingState === OnboardingState.PlatformProvisioningCompleted
				? SAW_RE_OPTIN_PAGE
				: SAW_ONBOARDING_LANDING_PAGE;
		this.onboardingService.trackEvent(eventToTrack);

		if (this.onboardingState === OnboardingState.ConsentedDuringOnboarding) {
			this.startOnboardingTenant();
		}
	}

	initContractualConsentView(): void {
		this.onboardingService.trackEvent(SAW_CONTRACTUAL_CONSENT_ONBOARDING_PAGE);
		this.startOnboardingTenant();
	}

	getGeoRegionText(): string {
		const geoRegion = this.i18n.get(
			'endpointManagement.supportedGeoRegions.' + tenantContextCache.appConfig.GeoRegion
		);
		return this.i18n.get('sccOnboarding.mtpOnboarding.geoRegionText', {
			geoRegion: geoRegion,
		});
	}

	onCheckboxChange() {
		this.isConsentCheckboxChecked = !this.isConsentCheckboxChecked;

		// Temporary hack to handle change detection in SCC
		this.changeDetectorRef.detectChanges();
	}

	onAccept() {
		this.isConsentCheckboxDisabled = true;
		this.changeDetectorRef.detectChanges();

		if (this.alreadyOnboarded) {
			this.setInProgressView();
			this.onboardingService.trackEvent(ONBOARDED_TENANT_RE_ENABLED_MTP);
			this.optIn().subscribe(() => this.redirectToPortal(), () => this.setOnboardingErrorView());
		} else {
			this.onboardingService.trackEvent(CLICKED_ACCEPT);
			this.startOnboardingTenant(); // New UX is coupled with auto-geo-selection
		}
	}

	// TODO (amlandeb) remove and simplify state matrix once V1 is fully deprecated
	private setOnboardingInitialView() {
		switch (this.onboardingState) {
			case OnboardingState.ConsentedNotOnboarded:
			case OnboardingState.NotConsentedNotOnboarded:
			case OnboardingState.NotConsentedDuringOnboarding:
				this.isConsentCheckboxChecked = false;
				break;
			case OnboardingState.ConsentedDuringOnboarding:
				this.duringOnboarding = true;
				this.isConsentCheckboxDisabled = true;
				this.isConsentCheckboxChecked = true;
				break;
			case OnboardingState.PlatformProvisioningCompleted:
				this.alreadyOnboarded = true;
				break;
			case OnboardingState.EncounteredError:
				this.setOnboardingErrorView('setOnboardingInitialView_switchCase');
				break;
		}
	}

	private setOnboardingErrorView(errorFrom = '') {
		this.isConsentCheckboxChecked = false;
		this.isConsentCheckboxDisabled = false;
		this.showErrorMessage = true;
		this.duringOnboarding = false;

		this.onboardingService.trackEvent(SAW_ERROR_VIEW, errorFrom);
	}

	private setInProgressView() {
		this.duringOnboarding = true; // Show progress indicator and disable toggle
		this.isConsentCheckboxDisabled = true;
		this.changeDetectorRef.detectChanges();
	}

	startOnboardingTenant(selectedGeoRegion?: ChecklistValue) {
		this.setInProgressView();
		this.sccOnboardingData = { dataCenterRegion: selectedGeoRegion };
		this.onboardingSubscription$ = this.onboardingService
			.onboardTenant(this.sccOnboardingData, true)
			.pipe(
				tap(() => this.onboardingService.trackEvent(STARTONBOARDING_BEFORE)),
				// Need to refresh tenant context data post provisioning
				switchMap(() => {
					tenantContextCache.invalidateCache();
					return from(tenantContextCache.getTenantContext(true));
				}),
				tap(() => this.onboardingService.trackEvent(STARTONBOARDING_AFTER_GET_TENANTCONTEXT)),
				switchMap(() => {
					// Refresh cache to support Component (MDATP) provisioning
					return from(sccHostService.auth.isInRole(SccRoles.mdatpWorkloadActive, true));
				}),
				tap(() => this.onboardingService.trackEvent(STARTONBOARDING_AFTER_ISINROLE)),
				switchMap(() => this.optIn()),
				tap(() => this.onboardingService.trackEvent(STARTONBOARDING_AFTER_OPTIN)),
				catchError(error => {
					sccHostService.log.trackException(error, {
						errorFrom: 'startOnboardingTenant',
						component: 'mtp-onboarding.component',
					});

					this.setOnboardingErrorView('startOnboardingTenant_catchError');
					this.changeDetectorRef.detectChanges();
					throwError('Get current status failed');
					return of();
				}),
				tap(() => {
					this.onboardingService.trackEvent(STARTONBOARDING_FINISH)
					this.redirectToPortal();
				})
			)
			.subscribe();
	}

	private redirectToPortal(): void {
		const shouldAddSlash = !this.redirectTo || this.redirectTo.charAt(0) !== '/';
		window.location.href = `${shouldAddSlash ? '/' : ''}${this.redirectTo}`;
	}

	private optIn() {
		this.onboardingService.trackEvent(OPTIN_BEFORE);
		const optIntObservable = this.onboardingService.optInTenant();

		return optIntObservable.pipe(
			// Temporarily add a 2 minute delay post onboarding to get a fresh tenant context
			// TODO (amlandeb) remove delay and events once we start using TenantContext from real-time
			tap(() => this.onboardingService.trackEvent(START_DELAY_POST_ONBOARDING)),
			delay(120000),
			tap(() => this.onboardingService.trackEvent(END_DELAY_POST_ONBOARDING)),
			map(() => {
				this.duringOnboarding = false;
				this.isConsentCheckboxDisabled = false;
				this.changeDetectorRef.detectChanges();
				return true;
			}),
			tap(() => this.onboardingService.trackEvent(DONE)),
			catchError(error => {
				sccHostService.log.trackException(error, {
					errorFrom: 'optIn',
					component: 'mtp-onboarding.component',
				});
				this.setOnboardingErrorView('from_optIn_tryCatch');
				this.changeDetectorRef.detectChanges();
				return throwError('Consent operation failed. ' + error);
			})
		);
	}

	getImagePath() {
		const path = this.showErrorMessage
			? '/assets/images/onboarding/mtp/coffee-spilled.svg'
			: '/assets/images/onboarding/mtp/coffee.svg';
		return Utilities.getImagePath(path);
	}

	getHeaderText() {
		const headerText = this.showErrorMessage
			? 'sccOnboarding.mtpOnboarding.ContractualConsent.somethingWentWrongText'
			: 'sccOnboarding.mtpOnboarding.ContractualConsent.hangOnText';
		return this.i18n.get(headerText);
	}

	getDetailsMessageText() {
		const detailsMessageText = this.showErrorMessage
			? 'sccOnboarding.mtpOnboarding.ContractualConsent.pleaseRefreshText'
			: 'sccOnboarding.mtpOnboarding.ContractualConsent.durationInfo';
		return this.i18n.get(detailsMessageText);
	}

	ngOnDestroy() {
		this.onboardingSubscription$ && this.onboardingSubscription$.unsubscribe();
	}
}
