// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'

import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import {
	NetworkDevice,
	Vulnerability,
	NetworkDeviceRelatedVulnerabilitiesRelationship,
	SecurityRecommendation,
	NetworkDeviceRelatedSecurityRecommendationsRelationship,
	TvmEndPoint,
} from '@wcd/domain';
import { RelationshipRepository, Paris, DataSet } from '@microsoft/paris';
import { MessageBarType } from 'office-ui-fabric-react';
import { take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { I18nService } from '@wcd/i18n';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'network-device-entity-details',
	templateUrl: './network-device.entity-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkDeviceEntityDetailsComponent extends EntityDetailsComponentBase<NetworkDevice> {
	private readonly MillisecondsToDays = 86400000;
	private readonly MaxDays = 21;
	private readonly DaysConditionToShowMessage = 4;
	readonly MessageBarType = MessageBarType;
	vulnerabilitiesRelationshipRepository: RelationshipRepository<NetworkDevice, Vulnerability>;
	securityRecommendationsRelationshipRepository: RelationshipRepository<
		NetworkDevice,
		SecurityRecommendation
	>;
	shouldShowMessage: boolean;
	diffDays: number;
	remainingDays: number;
	recommendationTitle: string;
	recommendationDescription: string;
	private _device: NetworkDevice;
	private productId: string;

	@Input()
	set device(device: NetworkDevice) {
		this._device = device;
		this.vulnerabilitiesRelationshipRepository.sourceItem = device;
		this.securityRecommendationsRelationshipRepository.sourceItem = device;
		this.securityRecommendationsRelationshipRepository
			.queryForItem(this._device)
			.pipe(
				take(1),
				map(result => (result.items[0] ? result.items[0] : null))
			)
			.subscribe(recommendation => {
				if (recommendation) {
					this.recommendationTitle = this.securityRecommendationFieldsService.getTitleFieldsParams(
						recommendation
					).title;
					this.recommendationDescription = this.tvmTextsService.getText(
						TextToken.SecurityRecommendationDescription,
						{ recommendation }
					);
					this.productId = recommendation.productId;
				}
			});

		this.calcDays();
	}

	get device(): NetworkDevice {
		return this._device;
	}

	constructor(
		injector: Injector,
		private paris: Paris,
		private router: Router,
		private tvmDownloadService: TvmDownloadService,
		private i18nService: I18nService,
		private sidePanelService: SidePanelService,
		private securityRecommendationFieldsService: SecurityRecommendationFieldsService,
		private tvmTextsService: TvmTextsService
	) {
		super(injector);
		this.vulnerabilitiesRelationshipRepository = this.paris.getRelationshipRepository(
			NetworkDeviceRelatedVulnerabilitiesRelationship
		);
		this.securityRecommendationsRelationshipRepository = this.paris.getRelationshipRepository(
			NetworkDeviceRelatedSecurityRecommendationsRelationship
		);
	}

	navigateToRecommendation() {
		this.router.navigate(['/security-recommendations', `va-_-${this.productId}`], {
			queryParams: {
				search: this.productId,
			},
		});
	}

	exportCves() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.vulnerabilitiesRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this._device.ipAddress} - ${this.i18nService.get('tvm.common.relatedCVE.title')}`
		);
	}

	openAllRelatedCves() {
		this.sidePanelService.showAllRelatedCvesPanel(this._device, false, true);
	}

	private calcDays() {
		const today = new Date();
		const diffMilliseconds = today.getTime() - this._device.lastScanned.getTime();
		this.diffDays = Math.round(diffMilliseconds / this.MillisecondsToDays);
		this.remainingDays = this.MaxDays - this.diffDays;
		this.shouldShowMessage = this.diffDays > this.DaysConditionToShowMessage && this.remainingDays >= 0;
	}
}
