import { NgZone } from '@angular/core';
import { Identifiable, IIdentifiable } from './identifiable.model';
import { StoreBackendBase } from './store.backend.base';
import { ReadOnlyStore, ReadOnlyStoreOptions } from './readonly-store.base';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Observable } from 'rxjs';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { SnackBarMethod } from '../../dialogs/snackbars/models/snackbar-method.model';
import { ConfirmEvent } from '../../dialogs/confirm/confirm.event';
import { findIndex } from 'lodash-es';
import { map, share } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';

export class Store<T extends Identifiable<U>, U extends string | number> extends ReadOnlyStore<T, U> {
	changes$: Observable<StoreModelChanges<T>>;
	private _changesObserver;

	constructor(
		protected backendService: StoreBackendBase,
		protected ngZone: NgZone,
		protected itemConstructor: new (data: any, ...args) => T,
		protected dialogsService: DialogsService,
		protected i18nService: I18nService,
		options: ReadOnlyStoreOptions,
	) {
		super(backendService, ngZone, itemConstructor, options);

		this.changes$ = new Observable<StoreModelChanges<T>>(observer => {
			this._changesObserver = observer;
		}).pipe(share());
	}

	save(item: T, apiOptions?: any, showNotification: boolean = true, focusableQuery: string = null): Promise<T> {
		item.isSaving = true;

		let observable, backendData;

		try {
			backendData = item.getBackendData();
		} catch (e) {
			const errorTitle = this.i18nService.get('store_base_error_failed_save',
				{ itemName: this.options.itemNameSingular});

			this.dialogsService.showError({
				title: errorTitle,
				data: e,
			});

			item.isSaving = false;

			return new Promise((resolve, reject) => {
				reject(errorTitle);
			});
		}

		const isNewItem: boolean = item.isNew;

		if (item.isNew) {
			observable = this.backendService.create(backendData, apiOptions).pipe(
				map((data: any) => {
					const newItem: T = this.createItem(data);

					if (this._items) {
						this._items.push(newItem);
						this.itemsDictionary.set(newItem.id, newItem);
					}

					if (this.options.cacheItems) this.itemsDictionary.set(newItem.id, newItem);

					this.ngZone.run(() => {
						this._itemsObserver &&
							this._itemsObserver.next(this._items ? this._items.slice(0) : [newItem]);
						this._changesObserver &&
							this._changesObserver.next({
								change: StoreModelChangeType.created,
								item: newItem,
							});
					});

					return newItem;
				})
			);
		} else {
			observable = this.backendService.update(item.id, backendData, apiOptions).pipe(
				map((data: any) => {
					let newItem: T;
					if (!data || typeof data !== 'object') newItem = item;
					else {
						data.id = item.id;
						newItem = this.createItem(data);
					}

					if (this._items) {
						const currentItemIndex = findIndex(this._items, _item => _item.id === item.id);
						if (~currentItemIndex) this._items[currentItemIndex] = newItem;
						else this._items.push(newItem);

						if (this.options.cacheItems) this.itemsDictionary.set(newItem.id, newItem);
					}

					this.ngZone.run(() => {
						this._itemsObserver &&
							this._itemsObserver.next(this._items ? this._items.slice(0) : [item]);
						this._changesObserver &&
							this._changesObserver.next({
								change: StoreModelChangeType.updated,
								item: newItem,
							});
					});

					return newItem;
				})
			);
		}

		return observable
			.toPromise()
			.then((item: T) => {
				if (this.options.showSnackbarOnUpdate !== false && showNotification) {
					this.dialogsService.showSnackbar({
						text: `${this.options.itemNameSingular} ${
							item.name ? '"' + item.name + '" ' : ''
						}was ${isNewItem ? 'created' : 'updated'}`,
						icon: 'check',
						className: 'color-box-successBackground',
						focusableQuery: focusableQuery,
					});
				}

				item.isSaving = false;
				return Promise.resolve(item);
			})
			.catch(error => {
				const errorMessage = this.i18nService.get(`store_base_error_failed_${item.isNew ? 'create' : 'update'}`,
					{ itemNameS: this.options.itemNameSingular });

				this.dialogsService.showError({
					title: errorMessage,
					data: error,
				});

				item.isSaving = false;
				return new Promise<T>((resolve, reject) => {
					reject(errorMessage);
				});
			});
	}

	updateItems(updateData: Object, items?: Array<T>, options?: Object): Observable<any> {
		return this.backendService.updateMultiple(updateData, items.map(item => item.id), options);
	}

	remove(item: T, requireConfirm: boolean = true, apiOptions?: any): Promise<T> {
		if (requireConfirm) {
			const itemName = item.name
			const itemNameSingular = this.options.itemNameSingular

			return this.dialogsService
				.confirm({
					title: this.i18nService.get('store_base_delete_dialog_title',
						{ itemNameSingular }),
					text: itemName ?
						this.i18nService.get('store_base_delete_message_with_name', { itemName, itemNameSingular}):
						this.i18nService.get('store_base_delete_message', { itemNameSingular })
					,
					confirmText: this.i18nService.get('store_base_confirm_delete'),
				})
				.then((e: ConfirmEvent) => {
					if (e.confirmed) {
						return new Promise<T>((resolve, reject) => {
							this.ngZone.run(() => {
								this._remove(item, true, apiOptions).then(
									item => resolve(item),
									error => reject(error)
								);
							});
						});
					}
				});
		} else return this._remove(item, true, apiOptions);
	}

	removeItems(items: Array<T>, requireConfirm: boolean = true, apiOptions?: any): Promise<Array<T>> {
		return new Promise((resolve, reject) => {
			const remove = () => {
				Promise.all(items.map(item => this._remove(item, false, apiOptions))).then(items => {
					this.ngZone.run(() => {
						resolve(items);

						this.dialogsService.showSnackbar({
							text: items.length === 1 ?
								this.i18nService.get('store_base_deleted_snackbar_single', { itemNameSingular: this.options.itemNameSingular}) :
								this.i18nService.get('store_base_deleted_snackbar_multiple', { itemNamePlural: this.options.itemNamePlural, items: items.length })
							,
							icon: 'delete',
							iconClassName: 'color-text-error',
						});
					});
				}, onError);
			};

			const onError = error => {
				this.ngZone.run(() => {
					this.dialogsService.showError({
						title: items && items.length == 1 ?
							this.i18nService.get('store_base_failed_delete_singular', { itemNameSingular: this.options.itemNameSingular}):
							this.i18nService.get('store_base_failed_delete_multiple', { itemNamePlural: this.options.itemNamePlural }),
						data: error,
					});

					reject(error);
				});
			};
console.log('requireConfirm', requireConfirm)
			if (requireConfirm) {
				const itemName =
					items.length === 1
						? `${
								items[0].name
									? this.options.itemNameSingular + " '" + items[0].name + "'"
									: 'this ' + this.options.itemNameSingular
						  }`
						: `these ${items.length} ${this.options.itemNamePlural}`;

				this.dialogsService
					.confirm({
						title: `Delete ${
							items.length === 1
								? this.options.itemNameSingular
								: items.length + ' ' + this.options.itemNamePlural
						}`,
						text: `Are you sure you wish to delete ${itemName}?`,
						confirmText: 'Delete',
					})
					.then((e: ConfirmEvent) => (e.confirmed ? remove() : resolve()));
			} else remove();
		});
	}

	protected getRemoveItemAction(): ItemActionModel {
		return new ItemActionModel({
			id: 'delete',
			name: 'Delete',
			icon: 'delete',
			method: (items: Array<T>) => this.removeItems(items, true),
			tooltip: 'Delete the selected ' + this.options.itemNamePlural,
			refreshOnResolve: false,
		});
	}

	private _remove(item: T, showSnackbar: boolean = true, apiOptions?: any): Promise<T> {
		item.isDeleting = true;

		return this.backendService
			.remove(item.id, apiOptions)
			.pipe(
				map(() => {
					item.isDeleting = false;

					if (this._items) {
						const currentItemIndex = this._items.indexOf(item);
						this._items.splice(currentItemIndex, 1);
						this._items = this._items.slice();
						this._itemsObserver.next(this._items);
					}

					this._changesObserver &&
						this._changesObserver.next({
							change: StoreModelChangeType.removed,
							item: item,
						});

					if (showSnackbar) {
						const itemName =
							this.options.itemNameSingular.toLowerCase() +
							(item.name ? ` '${item.name}'` : '');

						this.dialogsService.showSnackbar({
							text: `Deleted ${itemName}`,
							icon: 'delete',
							iconClassName: 'color-text-error',
							method: this.options.allowDeleteUndo
								? new SnackBarMethod({
										text: 'Undo',
										icon: 'undo',
										method: () => {
											this.restore(item, null, false).then((item: T) => {
												this._changesObserver &&
													this._changesObserver.next({
														change: StoreModelChangeType.restored,
														item: item,
													});
											});
										},
								  })
								: null,
						});
					}

					return item;
				})
			)
			.toPromise();
	}

	restore(item: T, apiOptions?: any, showNotification: boolean = false): Promise<T> {
		item.id = undefined;
		return this.save(item, apiOptions, showNotification);
	}
}

export interface StoreModelChanges<T extends IIdentifiable> {
	change: StoreModelChangeType;
	item: T;
}

export enum StoreModelChangeType {
	created,
	updated,
	removed,
	restored,
}
