import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('decode', decodeFilter);

function decodeFilter() {
	return function(input, length) {
		if (!input || length <= 0) {
			return '';
		}

		return decodeURIComponent(input);
	};
}
