<div *ngIf="settingsArray">
	<div *ngFor="let summerySettings of settingsArray.events" class="wcd-margin-mediumSmall-top">
		<div class="wcd-flex-horizontal-spaced">
			<div *ngIf="summerySettings.title"
			class="wcd-flex-1 wcd-font-weight-semibold cursor-pointer"
			(click)="onItemClick(summerySettings)"
			(keydown.enter)="onItemClick(summerySettings)">
				<span>
					{{summerySettings.title}}
				</span>
			</div>
			<div *ngIf="summerySettings.amount >= 0 && summerySettings.total > 0" class="wcd-flex-justify-end-horizontal wcd-font-weight-semibold flex-right">
				{{summerySettings.amount | prettyNumber}}
				<span class="color-text-gray-400">
					/ {{summerySettings.total | prettyNumber}}
				</span>
			</div>
		</div>
		<events-summary-bars
			class="events-summary-bars wcd-margin-xsmall-top"
			[events]="summerySettings.events"
			[kindColorMap]="settingsArray.colorMap"
			[displayTextFormatter]="settingsArray.textFormatter"
			[size]="settingsArray.size"
		>
		</events-summary-bars>
	</div>
</div>
<div *ngIf="showLegend && legendItems" class="wcd-padding-mediumSmall-top">
	<span *ngFor="let legendItem of legendItems" class="wcd-inline-block wcd-padding-small-right">
		<fab-icon
			iconName="SquareShapeSolid"
			contentClass="wcd-vertical-align-bottom {{ legendItem.iconClassName }}"
		></fab-icon>
		{{ legendItem.name }}
	</span>
</div>
