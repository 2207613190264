import { SevilleModule } from '../../seville/seville.module';
import { RbacMdeAllowedActions } from '../../../../rbac/enums/mde-allowed-actions.enum';

declare let angular: angular.IAngularStatic;

SevilleModule.controller('seville.threatintel.machine.classification', machineClassificationController);

machineClassificationController.$inject = [
	'$rootScope',
	'$log',
	'machineClassificationService',
	'params',
	'$uibModalInstance',
	'authenticationService',
	'$timeout',
];

function machineClassificationController(
	$rootScope,
	$log,
	machineClassificationService,
	params,
	$modalInstance,
	authenticationService,
	$timeout
) {
	var vm = this;
	vm.machine = params;
	vm.changesExist = false;
	vm.alertsInvestigationAllowed = authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.alertsInvestigation,
	]);
	getMachineTags();

	const blurElementDelay = 120;

	function getMachineTags() {
		vm.loading = true;

		machineClassificationService.getTagsForMachine(vm.machine.internalId).then(
			function(result) {
				if (result != null) {
					vm.builtInTags = result.BuiltInTags;
					vm.tags = result.UserDefinedTags;
					vm.loading = false;
				}
			},
			function(result) {
				vm.loading = false;
			}
		);
	}

	vm.dismiss = function() {
		$modalInstance.dismiss(false);
	};

	vm.saveTags = function() {
		machineClassificationService.editMachineTags(vm.machine.internalId, vm.tags).then(
			function(response) {
				response.success && $rootScope.$broadcast('machineTagsChanged', vm.tags);
				$modalInstance.dismiss(true);
			},
			function(err) {
				$log.error(err);
			}
		);
	};

	vm.addTag = function() {
		vm.isTagsInputExposed = false;
		if (vm.tag == null || vm.tag == '') {
			return;
		}

		vm.tags.unshift(vm.tag);
		vm.tag = '';
		vm.changesExist = true;
	};

	vm.handleSearchKeyPress = function(event) {
		if (event.keyCode == 13) {
			vm.addTag();
		}

		return;
	};

	vm.removeTag = function(tag) {
		var index = vm.tags.indexOf(tag.value);
		if (index > -1) {
			vm.tags.splice(index, 1);
		}
		vm.changesExist = true;
	};

	vm.clearAll = function() {
		vm.tags = [];
		vm.changesExist = true;
	};

	vm.focusInput = function() {
		vm.isTagsInputExposed = true;
		angular.element('#tags-input').trigger('focus');
	};
}
