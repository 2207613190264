import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PrettyNumberService } from '@wcd/prettify';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChartSettings, LegendItem } from '@wcd/charts';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { FeaturesService } from '@wcd/config';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { MachinesFiltersService } from '../../../../../@entities/machines/services/machines.filters.service';
import { I18nService } from '@wcd/i18n';

const MORE_INFO_URL = 'https://go.microsoft.com/fwlink/p/?linkid=839876';
const MISCONFIGURED_ID = 'Misconfigured';
const INACTIVE_ID = 'Inactive';

@Component({
	selector: 'sensor-health-widget',
	template: `
		<div class="wcd-flex-horizontal-spaced wcd-flex-wrap" data-track-component="SensorHealthWidget">
			<wcd-pie-chart
				[data]="chartData$ | async"
				[hidden]="error$ | async"
				[settings]="settings"
				[allowTitleClick]="true"
				[upperTitle]="pieChartTitle$ | async"
				(titleClick)="navigateToMachinesPage()"
				[ariaLabel]="'securityOperations_sensorHealth_widgetTitle' | i18n"
			>
			</wcd-pie-chart>
			<div [hidden]="error$ | async" class="wcd-flex-vertical">
				<wcd-chart-legend [items]="legendData$ | async" [showValues]="false"></wcd-chart-legend>
				<a
					href="${MORE_INFO_URL}"
					target="_blank"
					style="padding-top: 1.5em"
					data-track-id="MoreInfoDocs"
					data-track-type="Navigation"
				>
					{{ 'widget_moreInfo_sensorHealth' | i18n }}
				</a>
			</div>
		</div>
	`,
})
export class SensorHealthWidget extends ReportWidgetComponent<SensorHealthChartData> {
	pieChartTitle$: Observable<string> = this.data$.pipe(
		map((data: SensorHealthChartData) => {
			return this.prettyNumberService.prettyNumber(
				data ? data.legendData.map((item: LegendItem) => item.value).reduce((a, b) => a + b, 0) : 0
			);
		})
	);

	legendData$: Observable<LegendItem[]> = this.data$.pipe(
		map((data: SensorHealthChartData) => {
			return data && data.legendData;
		})
	);

	chartData$: Observable<any> = this.data$.pipe(
		map((data: SensorHealthChartData) => {
			return data && data.sensorHealthData;
		})
	);

	get widgetConfig(): ReportWidgetConfig<SensorHealthChartData> {
		return {
			id: 'sensorHealth',
			name: this.i18nService.get('securityOperations_sensorHealth_widgetTitle'),
			api: {
				url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/GetMachinesHealth`,
				isExternal: true,
			},
			parseData: this.parseData.bind(this),
		};
	}

	settings: ChartSettings = {
		dataPath: 'columns',
		options: {
			data: {
				colors: {
					Misconfigured: '#0078D4',
					Inactive: '#d9d9d9',
				},
				onclick: e => this.navigateToMachinesPage(e.id),
			},
			tooltip: {
				format: {
					value: (name: string, ratio: number) => {
						return name;
					},
				},
			},
			donut: {
				title: this.i18nService.get('securityOperations_sensorHealth_pie_centerlabel'),
			},
			legend: {
				show: false,
			},
			size: {
				width: 250,
				height: 200,
			},
		},
	};

	navigateToMachinesPage = (category?: string) => {
		this.appInsightsService.track({
			id: category,
			type: TrackingEventType.Navigation,
			component: String(this.widgetConfig.id),
			componentType: 'Widget',
		});

		this.router.navigate(['/machines'], {
			queryParams: {
				filters: `healthStatuses=${encodeURIComponent(category || 'Inactive|Misconfigured')}`,
				ordering: 'healthStatuses',
				range: 180,
			},
		});
	};

	constructor(
		reportsService: ReportsService,
		private router: Router,
		private featuresService: FeaturesService,
		private serviceUrlsService: ServiceUrlsService,
		private appInsightsService: AppInsightsService,
		private appConfigService: AppConfigService,
		private prettyNumberService: PrettyNumberService,
		private machinesFiltersService: MachinesFiltersService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	parseData(data: SensorHealthBackendData): SensorHealthChartData {
		const misconfiguredValue = Object.keys(data)
			.filter((key: string) => key.startsWith(MISCONFIGURED_ID))
			.reduce((sum: number, key: string) => {
				return sum + data[key];
			}, 0);
		const inactiveValue = data.InactiveRecent || 0;
		const legendData = [
			{
				name: this.i18nService.get('securityOperations_sensorHealth_misconfigured_label'),
				value: misconfiguredValue,
				iconClassName: 'ms-fontColor-themePrimary',
				helpKey: 'securityOperations.sensorHealth.misconfigured',
			},
			{
				name: this.i18nService.get('securityOperations_sensorHealth_inactive_label'),
				value: inactiveValue,
				iconClassName: 'ms-fontColor-neutralQuaternaryAlt',
				helpKey: 'securityOperations.sensorHealth.inactive',
			},
		];

		const sensorHealthData = {
			columns: [
				{ name: MISCONFIGURED_ID, value: misconfiguredValue },
				{ name: INACTIVE_ID, value: inactiveValue },
			],
			names: {
				[MISCONFIGURED_ID]: this.i18nService.get(
					'securityOperations_sensorHealth_misconfigured_label'
				),
				[INACTIVE_ID]: this.i18nService.get('securityOperations_sensorHealth_inactive_label'),
			},
		};
		return { legendData: legendData, sensorHealthData: sensorHealthData };
	}
}

interface SensorHealthBackendData {
	Active: number;
	ActiveDefault: number;
	InactiveGoneDark: number;
	InactiveRecent: number;
	MisconfiguredCyberNoCnc: number;
	MisconfiguredCncNoCyber: number;
	MisconfiguredUtcAndDefenderOnly: number;
	MisconfiguredUtcOnly: number;
	MisconfiguredDefenderOnly: number;
}

interface SensorHealthChartData {
	legendData: Array<LegendItem>;
	sensorHealthData: any;
}
