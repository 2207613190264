import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsAccount, AadUserStatus } from '@wcd/domain';

@Component({
	selector: 'airs-account-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.userDisplayName">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_userDisplayName }}
				</dt>
				<dd role="none">
					<user-display-name
						[showIcon]="true"
						[showLink]="true"
						[displayName]="entity.userDisplayName"
						[sid]="entity.sid"
						[aadUserId]="entity.aadUserId"
						[iconCssClass]="'icon small-icon wcd-margin-small-right'"
					></user-display-name>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.userStatus">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_userStatus }}
				</dt>
				<dd role="none">
					<aad-user-status-display-name
						[userStatusType]="entity.userStatus"
					></aad-user-status-display-name>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.upnSuffix">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_upnSuffix }}
				</dt>
				<dd role="none">{{ entity.upnSuffix }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.dnsDomain">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_dnsDomain }}
				</dt>
				<dd role="none">{{ entity.dnsDomain }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.ntDomain">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_ntDomain }}
				</dt>
				<dd role="none">{{ entity.ntDomain }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.sid">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_sid }}
				</dt>
				<dd role="none">
					{{ entity.sid }}
					<copy-to-clipboard [content]="entity.sid" [settings]="{ copyText: '' }">
					</copy-to-clipboard>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.aadUserId">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_aadUserId }}
				</dt>
				<dd role="none">{{ entity.aadUserId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">{{ i18nService.strings.airsEntities_account_fields_firstSeen }}</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container
				*ngIf="entity.investigationPriority !== null && entity.investigationPriority !== undefined"
			>
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_investigationPriority }}
				</dt>
				<dd role="none">
					<aad-user-investigation-priority
						[percentile]="entity.percentile"
						[priority]="entity.investigationPriority"
					></aad-user-investigation-priority>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.email || entity.upn">
				<dt role="none">
					{{ i18nService.strings.airsEntities_account_fields_email_or_upn }}
				</dt>
				<dd role="none">{{ entity.email || entity.upn }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsAccountDetailsComponent {
	AadUserStatus = AadUserStatus;

	@Input() entity: AirsAccount;

	constructor(public i18nService: I18nService) {}
}
