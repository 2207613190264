export const enum IpEntityAlertV3Fields {
	Address = 'Address',
}

export function mapIpEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case IpEntityAlertV3Fields.Address:
					acc['address'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
