var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { machineHealthStatuses } from '@wcd/domain';
import { AppFlavorConfig } from '@wcd/scc-common';
var MachineHeaderBottomComponent = /** @class */ (function (_super) {
    __extends(MachineHeaderBottomComponent, _super);
    function MachineHeaderBottomComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MachineHeaderBottomComponent.prototype.constractur = function (flavorService) {
        this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
    };
    MachineHeaderBottomComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        var status = this.entity.status;
        this.hasStatus = !!status;
        this.isActive = status && status.id && status.id === machineHealthStatuses.Active.id;
        this.description =
            status &&
                this.i18nService.get('machines.entityDetails.bottomHeader.tooltip', {
                    exposure: this.i18nService.strings['machine_details_exposureScore_' + entity.exposureScore.id] ||
                        this.entity.exposureScore.name,
                    status: this.i18nService.strings['machine_details_status_' + this.entity.status.id + '_name'] ||
                        this.entity.status.name,
                });
        if (this.hasStatus) {
            this.statusDescription =
                this.i18nService.strings['machine_details_status_' + this.entity.status.id + '_name'] ||
                    this.entity.status.name;
        }
    };
    return MachineHeaderBottomComponent;
}(EntityHeaderBottomComponentBase));
export { MachineHeaderBottomComponent };
