import { Component } from '@angular/core';
import { WidgetType } from '../reports/components/report-with-filters.component';
import {
	ReportsService,
	reportWithFiltersFromDateProperty,
	reportWithFiltersToDateProperty,
} from './services/reports.service';
import { MachineProtectionReportService } from './services/machine-protection-report.service';
import { ChartSettings } from '@wcd/charts';
import { I18nService } from '@wcd/i18n';
import { MachineProtection, HealthStatus, MachineDimensionType } from '@wcd/domain';
import { BaseReportWidgetComponent } from '../reporting/base-reporting-widget';
import { MachineProtectionStatusMapping } from './machine-protection-status-mapping';
import { ChangeDetectionStrategy } from '@angular/core';
import { SerializedFilters } from '@wcd/ng-filters';
import { forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

@Component({
	template: `
		<wcd-time-series-chart [data]="(data$ | async)?.data" [settings]="chartSettings$ | async">
		</wcd-time-series-chart>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachineProtectionActiveStatusOvertimeWidget extends BaseReportWidgetComponent<
	MachineProtection
> {
	readonly widgetType = WidgetType.Trend;
	constructor(
		reportsService: ReportsService,
		i18nService: I18nService,
		private machineProtectionSvc: MachineProtectionReportService,
		public machineProtectionStatusMapping: MachineProtectionStatusMapping
	) {
		super(
			'dashboardActiveDevices',
			'securityOperations_activeDevices_widgetTitle',
			reportsService,
			i18nService,
			{},
			undefined,
			30
		);
	}

	get dataFunc() {
		const today = new Date();
		today.setUTCHours(0, 0, 0, 0);
		return (serializedFilters: SerializedFilters) =>
			forkJoin([
				this.machineProtectionSvc.getMachineByHealthStatusData({
					...serializedFilters,
					healthStatus: HealthStatus.Active,
				}),
				this.machineProtectionSvc.getMachineByHealthStatusData({
					...serializedFilters,
					healthStatus: HealthStatus.Active,
					[reportWithFiltersFromDateProperty]: today.toISOString(),
					[reportWithFiltersToDateProperty]: today.toISOString(),
				}),
			]).pipe(
				map(([overTimeData, dailyData]) => {
					const machineProtection = new MachineProtection();
					machineProtection.dimensionType = MachineDimensionType.HealthStatus;
					machineProtection.data = overTimeData.data.concat(dailyData.data);
					return machineProtection;
				})
			);
	}

	getSettings(data?: MachineProtection): ChartSettings {
		return this.machineProtectionSvc.getSettingsOverTime(
			HealthStatus,
			this.machineProtectionStatusMapping.healthStatusMapping,
			data,
			HealthStatus.Active
		);
	}
}
