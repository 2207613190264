<div class="wcd-margin-vertical wcd-margin-large-horizontal">
	<section class="wcd-padding-vertical wcd-padding-large-horizontal">
		<h4 class="definition-list-title">
			{{'users.entityDetails.logons.title' | i18n}}
		</h4>

		<div class="wcd-break-words" *ngIf="user">
			{{
				'users.entityDetails.userObservedInPastPeriod'
					| i18n: { user: user.fullName }
			}}
		</div>

		<ng-container *ngIf="(userLogons$ | async) as userLogons; else loading">

			<dl class="key-values wcd-margin-large-top" *ngFor="let logon of userLogons; trackBy: trackByLogonType" role="none">
				<dt>{{ 'users.entityDetails.logonType' | i18n }}</dt>
				<dd class="wcd-font-weight-bold">{{ getLogonText(logon.logonType) }}</dd>

				<dt>
					{{ 'users.entityDetails.sections.networkActivity.fields.firstSeen.title' | i18n }}
				</dt>
				<dd>{{ logon.firstSeen | date: 'medium' }}</dd>

				<dt>
					{{ 'users.entityDetails.sections.networkActivity.fields.lastSeen.title' | i18n }}
				</dt>
				<dd>{{ logon.lastSeen | date: 'medium' }}</dd>
			</dl>

			<div *ngIf="!userLogons.length" class="disabled">
				<br/>
				<span>{{ 'users.entityDetails.logons.empty' | i18n }}</span>
			</div>

		</ng-container>
		<ng-template #loading>
			<br/>
			<span><i class="loader-icon"></i> {{'users.entityDetails.logons.loading' | i18n}}</span>
		</ng-template>
	</section>

	<section class="wcd-padding-vertical wcd-padding-large-horizontal">
		<h4 class="definition-list-title">
				{{'users.entityDetails.machines.title' | i18n}}
		</h4>
		<machine-entity-details
			[machine]="userObservedMachine.machine"
			(loggedOnUsersClick)="showLoggedOnUsers()"
			[includeMachineName]="true"
			[collapsibleSections]="false"
		></machine-entity-details>
	</section>
</div>
