/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./alerted-machine-details.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../tags/components/tags-list/tags-list.component.ngfactory";
import * as i3 from "../../../tags/components/tags-list/tags-list.component";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i9 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i10 from "@angular/platform-browser";
import * as i11 from "../../../global_entities/components/entity-command-bar/entity-command-bar.component.ngfactory";
import * as i12 from "../../../global_entities/components/entity-command-bar/entity-command-bar.component";
import * as i13 from "@angular/router";
import * as i14 from "../../../shared/components/command-bar/command-bar-item.service";
import * as i15 from "../../../../../../../projects/shared/src/lib/services/routes.service";
import * as i16 from "../../../global_entities/services/activated-entity.service";
import * as i17 from "@angular/common";
import * as i18 from "./alerted-user-details.component";
import * as i19 from "../../../global_entities/services/global-entity-types.service";
import * as i20 from "@microsoft/paris/dist/lib/paris";
import * as i21 from "../services/alert-page.service";
var styles_AlertedUserDetailsComponent = [i0.styles];
var RenderType_AlertedUserDetailsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AlertedUserDetailsComponent, data: {} });
export { RenderType_AlertedUserDetailsComponent as RenderType_AlertedUserDetailsComponent };
function View_AlertedUserDetailsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "tags-list", [], null, null, null, i2.View_TagsListComponent_0, i2.RenderType_TagsListComponent)), i1.ɵdid(3, 573440, null, 0, i3.TagsListComponent, [], { tags: [0, "tags"] }, null), i1.ɵpad(4, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.userProfile.title); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AlertedUserDetailsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(2, 0, null, null, 34, "div", [["class", "element--border element clickable selectable--outer non-tree-element"], ["role", "presentation"]], [[2, "clickable", null], [2, "clickable--with-constant-shadow", null], [2, "clickable--with-radius", null], [2, "selected", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 31, "div", [["class", "tree-element--title--box selectable--inner element--centered"], ["data-track-id", "user-tile"], ["data-track-type", "Button"], ["role", "listitem"], ["tabindex", "0"]], [[2, "selected", null], [1, "aria-label", 0], [1, "aria-current", 0], [1, "data-track-value", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.alertPageService.updateDetailsTab(_co.userDetailsTabType, _co.user, _co.user.id) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.alertPageService.updateDetailsTab(_co.userDetailsTabType, _co.user, _co.user.id) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(7, 0, null, null, 27, "div", [["class", "wcd-flex-vertical wcd-flex-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(9, 0, null, null, 18, "div", [["class", "wcd-flex-horizontal\telement element--centered"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(11, 0, null, null, 9, "span", [["class", "wcd-flex-horizontal wcd-flex-1 wcd-padding-small-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "fab-icon", [], null, null, null, i6.View_FabIconComponent_0, i6.RenderType_FabIconComponent)), i1.ɵdid(15, 5816320, null, 0, i7.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(17, 0, null, null, 2, "span", [["class", "wcd-font-size-m\n\t\t\t\t\t\t\t\twcd-text-overflow-ellipsis-nowrap\n\t\t\t\t\t\t\t\twcd-text-overflow-ellipsis\n\t\t\t\t\t\t\t\twcd-font-weight-bold\n\t\t\t\t\t\t\t\twcd-flex-1\n\t\t\t\t\t\t\t\twcd-padding-small-left"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 18).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 18).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 18).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 147456, null, 0, i8.TooltipDirective, [i1.ElementRef, i9.TooltipsService, i10.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(19, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(23, 0, null, null, 3, "entity-command-bar", [], null, null, null, i11.View_EntityCommandBarComponent_0, i11.RenderType_EntityCommandBarComponent)), i1.ɵdid(24, 770048, null, 0, i12.EntityCommandBarComponent, [i13.Router, i14.CommandBarItemService, i15.RoutesService, i16.ActivatedEntity, i1.ChangeDetectorRef, i5.I18nService], { entityType: [0, "entityType"], entity: [1, "entity"], entities: [2, "entities"], overFlowActionsView: [3, "overFlowActionsView"], entityCommandBarDisplayMode: [4, "entityCommandBarDisplayMode"] }, null), i1.ɵpad(25, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "wcd-padding-right alert-page-tags"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.alertPageService.updateDetailsTab(_co.userDetailsTabType, _co.user, _co.user.id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_AlertedUserDetailsComponent_1)), i1.ɵdid(32, 16384, null, 0, i17.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_8 = _co.userIcon; _ck(_v, 15, 0, currVal_8); var currVal_9 = _co.user.fullName; _ck(_v, 18, 0, currVal_9); var currVal_11 = _co.userType; var currVal_12 = _co.user; var currVal_13 = _ck(_v, 25, 0, _co.user); var currVal_14 = true; var currVal_15 = _co.entityCommandBarDisplayMode.Overflow; _ck(_v, 24, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); var currVal_16 = (_co.userProfile && _co.userProfile.title); _ck(_v, 32, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user.id; var currVal_1 = _co.user.id; var currVal_2 = _co.user.id; var currVal_3 = _co.alertPageService.selectedTreeElements[_co.user.id]; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.alertPageService.selectedTreeElements[_co.user.id]; var currVal_5 = (_co.user.fullName + i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v, 0), "alert.accessibility.user.details.label"))); var currVal_6 = _co.alertPageService.selectedTreeElements[_co.user.id]; var currVal_7 = _co.user.id; _ck(_v, 4, 0, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_10 = _co.user.fullName; _ck(_v, 19, 0, currVal_10); }); }
export function View_AlertedUserDetailsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "alerted-user-details", [], null, null, null, View_AlertedUserDetailsComponent_0, RenderType_AlertedUserDetailsComponent)), i1.ɵdid(1, 114688, null, 0, i18.AlertedUserDetailsComponent, [i19.GlobalEntityTypesService, i20.Paris, i21.AlertPageService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertedUserDetailsComponentNgFactory = i1.ɵccf("alerted-user-details", i18.AlertedUserDetailsComponent, View_AlertedUserDetailsComponent_Host_0, { user: "user" }, {}, []);
export { AlertedUserDetailsComponentNgFactory as AlertedUserDetailsComponentNgFactory };
