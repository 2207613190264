import { SevilleModule } from '../../../../../app/seville/seville.module';
import { Feature } from '@wcd/config';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.securityanalytics', settingsSecurityAnalyticsController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		$stateProvider.state('preferences.securescore', {
			url: '/securescore',
			title: 'Secure score',
			parent: 'preferences',
			views: {
				SettingsView: {
					template: `
				<div>
            <div class="settings-view-page">

                <!-- Title -->
				<div class="settings-page-title">
					Set the baselines for calculating the score of Microsoft Defender security controls on the <a ng-click="sa.navigateToSecureScore()" >secure score dashboard</a>.<br/>
					If you use third-party solutions, consider excluding the corresponding controls from the calculations.<br/>
					Changes might take up to a few hours to reflect on the dashboard.<br/><br/>
					Note: Controls for device detection and response and OS security updates cannot be excluded.<br/>
					These controls are tied to core aspects of security enforcement and data collection.
				</div>

                <!-- loading state (configuration) -->
                <div ng-if="sa.preferencesLoadState === 'loading'">
                    <img class="settings-page-loading img-responsive" src="/assets/images/linear-loader.gif"/>
                </div>

                <!-- state (configuration) loaded - main view -->
                <div id="settings.sa.preferences" ng-class="{'disabled': sa.isReadOnly}" ng-if="sa.preferencesLoadState === 'loaded'">
                    <div ng-repeat="item in sa.controls">
						<div ng-if="item.canBeExcluded" class="settings-secAnalytics-control ms-Toggle">
							<input ng-disabled="sa.isReadOnly" ng-class="{'disabled': sa.isReadOnly }" type="checkbox" id="{{item.name}}" class="order ms-Toggle-input" ng-click="sa.controlStateChanged(item)" ng-checked="!item.exclude">
							<label ng-class="{'disabled': sa.isReadOnly }" for="{{item.name}}" class="order ms-Toggle-field ob-common-toggle">
							</label>
							<div class="ms-Toggle-description ob-common-toggle-description-title">
								<span class="ob-common-toggle-state" ng-class="{'settings-grey-text': sa.isReadOnly}">{{ item.exclude ? $parent.toggleStateOff : $parent.toggleStateOn }}</span>
								{{item.name}}
								<div class="ob-common-toggle-description">
									<span ng-bind-html="item.desc"></span>
								</div>
							</div>
						</div>
                    </div>
                    <div class="settings-secAnalytics-savePreferences">
                        <form ng-submit="sa.savePreferences()">
                            <button ng-disabled="!sa.savePreferencesButtonEnabled" class="settings-button ng-class:{'settings-button-enabled':sa.savePreferencesButtonEnabled, 'settings-button-disabled settings-disabled': !sa.savePreferencesButtonEnabled}">
                                Save preferences
                            </button>
                        </form>
                        <span ng-if="sa.preferencesSaveState === 'saving'">
                            <img class="settings-toggle-applying img-responsive" src="/assets/images/linear-loader.gif" />
                        </span>
                        <span ng-if="sa.preferencesSaveState === 'savedReminder'" class="settings-secAnalytics-saving-reminder">
                            Note that changes might take up to a few hours to reflect on the dashboard.
                        </span>
                    </div>
                </div>

                <!-- error loading state (configuration) -->
                <div ng-if="sa.preferencesLoadState === 'loadingError' || sa.preferencesSaveState === 'savingError'" class="settings-error-message">
                    {{sa.errorMessage}}
                </div>
            </div>
        </div>
				`,
					controller: 'seville.settings.securityanalytics',
					controllerAs: 'sa',
				},
			},
		});
	},
]);

settingsSecurityAnalyticsController.$inject = [
	'$log',
	'$scope',
	'$http',
	'$timeout',
	'$state',
	'$injector',
	'featuresService',
	'authenticationService',
];

function settingsSecurityAnalyticsController(
	$log,
	$scope,
	$http,
	$timeout,
	$state,
	$injector,
	featuresService,
	authenticationService
) {
	var vm = this;
	vm.preferencesLoadState = ''; // represent a state machine for loading settings operation result (loading, loaded, loadingError);
	vm.preferencesSaveState = ''; // represent a state machine for saving settings operation result (saving, saved, savingError);
	vm.errorMessage = '';
	vm.savePreferencesButtonEnabled = false;
	vm.isDirtyState = false;
	vm.isReadOnly = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.systemSettings]);
	vm.loadedSettings = null;
	vm.currentSettings = null;
	var reminderTimer = null;

	vm.controls = [
		{
			type: 'Sense',
			name: 'Device detection and response',
			exclude: false,
			desc:
				'This control checks whether the Microsoft Defender for Endpoint sensor is turned on and configured properly.<br/>The sensor collects configuration data across multiple controls, so this control cannot be excluded.',
			canBeExcluded: false,
		},
		{
			type: 'AntiVirus',
			name: 'Windows Defender Antivirus',
			exclude: false,
			desc:
				'This control checks whether Windows Defender Antivirus is turned on and providing optimal antimalware protection.',
			canBeExcluded: true,
		},
		{
			type: 'SecurityUpdates',
			name: 'OS security updates',
			exclude: false,
			desc:
				'This control checks whether Windows Update is turned on and security updates are installed.<br/>This control cannot be excluded.',
			canBeExcluded: false,
		},
		{
			type: 'ExploitGuard',
			name: 'Windows Defender Exploit Guard',
			exclude: false,
			desc:
				'This control checks whether Windows Defender Exploit Guard prerequisites are met<br/>and whether Exploit Guard has been configured properly according to recommended baselines.',
			canBeExcluded: true,
		},
		{
			type: 'AppGuard',
			name: 'Windows Defender Application Guard',
			exclude: false,
			desc:
				'This control checks whether Windows Defender Application Guard prerequisites are met<br/>and whether Application Guard has been configured properly according to recommended baselines.',
			canBeExcluded: true,
		},
		{
			type: 'SmartScreen',
			name: 'Windows Defender SmartScreen',
			exclude: false,
			desc:
				'This control checks whether Windows Defender SmartScreen is turned on<br/>and configured properly according to recommended baselines.',
			canBeExcluded: true,
		},
	];

	if (featuresService.isEnabled(Feature.SecurityAnalyticsFirewallControl)) {
		vm.controls.push({
			type: 'Firewall',
			name: 'Windows Defender Firewall',
			exclude: false,
			desc:
				'This control checks whether Windows Defender Firewall is turned on<br/>and configured properly according to recommended baselines.',
			canBeExcluded: true,
		});
	}

	if (featuresService.isEnabled(Feature.SecurityAnalyticsCredentialGuardControl)) {
		vm.controls.push({
			type: 'CredentialGuard',
			name: 'Windows Defender Credential Guard',
			exclude: false,
			desc:
				'This control checks whether Windows Defender Credential Guard is turned on<br/>and configured properly according to recommended baselines.',
			canBeExcluded: true,
		});
	}

	if (featuresService.isEnabled(Feature.SecurityAnalyticsWinHelloControl)) {
		vm.controls.push({
			type: 'WindowsHello',
			name: 'Windows Hello',
			exclude: false,
			desc:
				'This control checks whether Windows Hello is configured<br/> properly according to recommended baselines, and is used by the user.',
			canBeExcluded: true,
		});
	}

	if (featuresService.isEnabled(Feature.SecurityAnalyticsBitLockerControl)) {
		vm.controls.push({
			type: 'BitLocker',
			name: 'BitLocker',
			exclude: false,
			desc:
				'This control checks whether BitLocker is turned on<br/>and configured properly according to recommended baselines on supported drives.',
			canBeExcluded: true,
		});
	}

	if (featuresService.isEnabled(Feature.SecurityAnalyticsOsVersionUpgradeControl)) {
		vm.controls.push({
			type: 'UpgradeableWindowsVersion',
			name: 'OS Platform',
			exclude: false,
			desc:
				'This control identifies devices running versions of Windows that can be upgraded to Windows 10. With Windows 10, devices get improved visibility of breach activities and better threat prevention and remediation.',
			canBeExcluded: true,
		});
	}

	vm.navigateToSecureScore = function() {
		const ng2router = $injector.get('ng2router');
		ng2router.navigate(['/securescore']);
	};

	vm.controlStateChanged = function(control) {
		vm.saveSuccessMessage = '';
		vm.preferencesSaveState = '';
		vm.errorMessage = '';

		var index = vm.currentSettings.ExcludedControls.indexOf(control.type);

		if (index >= 0) {
			vm.currentSettings.ExcludedControls.splice(index, 1);
		} else {
			vm.currentSettings.ExcludedControls.push(control.type);
		}

		control.exclude = !control.exclude;
		vm.isDirtyState =
			vm.currentSettings.ExcludedControls.length !== vm.loadedSettings.ExcludedControls.length ||
			!vm.currentSettings.ExcludedControls.every(function(v, i) {
				return v === vm.loadedSettings.ExcludedControls[i];
			});

		vm.savePreferencesButtonEnabled = vm.preferencesSaveState !== 'saving' && vm.isDirtyState;
	};

	vm.savePreferences = function() {
		vm.preferencesSaveState = 'saving';
		vm.savePreferencesButtonEnabled = false;

		$http
			.post($scope.$parent.settingsBaseUrl + '/SecurityAnalyticsSettings', vm.currentSettings, {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						vm.isDirtyState = false;
						vm.loadedSettings = JSON.parse(JSON.stringify(vm.currentSettings));
						vm.preferencesSaveState = 'savedReminder';
						reminderTimer = $timeout(function() {
							vm.preferencesSaveState = 'saved';
						}, 3000);
					} else {
						$log.error('failed saving secure score preferences. status: ' + response.status);
						vm.preferencesSaveState = 'savingError';
						vm.errorMessage =
							'failed saving secure score preferences. please try again, if the problem persist - please contact support';
						vm.savePreferencesButtonEnabled = true;
					}
				},
				function(response) {
					$log.error('failed saving secure score preferences. status: ' + response.status);
					vm.preferencesSaveState = 'savingError';
					vm.errorMessage =
						'failed saving secure score preferences. please try again, if the problem persist - please contact support';
					vm.savePreferencesButtonEnabled = true;
				}
			);
	};

	$scope.$on('$destroy', function(event) {
		if (reminderTimer !== null) {
			$timeout.cancel(reminderTimer);
		}
	});

	var loadPreferences = function() {
		vm.preferencesLoadState = 'loading';
		$http
			.get($scope.$parent.settingsBaseUrl + '/SecurityAnalyticsSettings', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200 && response.data !== null) {
						vm.loadedSettings = JSON.parse(JSON.stringify(response.data));
						vm.currentSettings = JSON.parse(JSON.stringify(response.data));
						vm.controls.forEach(function(element) {
							if (vm.loadedSettings.ExcludedControls.indexOf(element.type) >= 0) {
								element.exclude = true;
							}
						});
						vm.preferencesLoadState = 'loaded';
					} else {
						$log.error(
							'failed loading secure score settings. status: ' +
								response.status +
								', data: ' +
								response.data
						);
						vm.preferencesLoadState = 'loadingError';
						vm.errorMessage =
							'Problem encountered while loading settings. Try reloading this page after a few minutes.';
					}
					$scope.$parent.applyGetPreferencesSuccess();
				},
				function(response) {
					$log.error('failed loading secure score settings. status: ' + response.status);
					vm.preferencesLoadState = 'loadingError';
					vm.errorMessage =
						'Problem encountered while loading settings. Try reloading this page after a few minutes.';
					$scope.$parent.applyGetPreferencesSuccess();
				}
			);
	};

	var init = function() {
		loadPreferences();
	};

	init();
}
