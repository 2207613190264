import { UrlHandlingStrategy, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';

const WDATP_SETTINGS: Array<string> = ['general', 'email', 'powerbi', 'securescore', 'siemconnector'];

const sevilleRoutes = [
	/^\/alert\//,
	/^\/aggregativealert\//,
	/^\/file\//,
	/^\/ip\//,
	/^\/url\//,
	/^\/_machine\//,
	/^\/user\//,
	new RegExp(`^\/preferences(\/(?:${WDATP_SETTINGS.join('|')}))?$`),
	/^\/download\/(?:client|server|nonWindows)$/i,
	/^\/onboarding\//,
	/^\/search\//,
];

@Injectable()
export class Ng1Ng2UrlHandlingStrategy implements UrlHandlingStrategy {

	shouldProcessUrl(url: UrlTree) {
		const urlStr = url.toString();
		return !sevilleRoutes.some(routeRegExp => routeRegExp.test(urlStr));
	}

	extract(url) {
		return url;
	}

	merge(newUrlPart: UrlTree, rawUrl: UrlTree): UrlTree {
		return newUrlPart;
	}
}
