var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { WebContentFilteringDevice, WebContentFilteringDomain, WebContentFilteringAccessTrend, } from './category-report.value-object';
import { WebContentFilteringReportBase } from './report.model';
import { WebContentFilteringCategoryTypes, WebContentFilteringParentCategoryTypes, } from '../../web-content-filtering/web-content-filtering-web-category-type';
var ɵ0 = function (config) { return config.data.serviceUrls.webThreatProtection; };
var WebContentFilteringCategoryReport = /** @class */ (function (_super) {
    __extends(WebContentFilteringCategoryReport, _super);
    function WebContentFilteringCategoryReport() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ParentCategory' }),
        __metadata("design:type", String)
    ], WebContentFilteringCategoryReport.prototype, "parentCategory", void 0);
    __decorate([
        EntityField({ data: 'CategoryId' }),
        __metadata("design:type", Number)
    ], WebContentFilteringCategoryReport.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'TotalDevices' }),
        __metadata("design:type", Number)
    ], WebContentFilteringCategoryReport.prototype, "totalDevices", void 0);
    __decorate([
        EntityField({ data: 'TotalDomains' }),
        __metadata("design:type", Number)
    ], WebContentFilteringCategoryReport.prototype, "totalDomains", void 0);
    __decorate([
        EntityField({ data: 'TopDevices', arrayOf: WebContentFilteringDevice }),
        __metadata("design:type", Array)
    ], WebContentFilteringCategoryReport.prototype, "topDevices", void 0);
    __decorate([
        EntityField({ data: 'TopDomains', arrayOf: WebContentFilteringDomain }),
        __metadata("design:type", Array)
    ], WebContentFilteringCategoryReport.prototype, "topDomains", void 0);
    __decorate([
        EntityField({ data: 'AccessTrends', arrayOf: WebContentFilteringAccessTrend }),
        __metadata("design:type", Array)
    ], WebContentFilteringCategoryReport.prototype, "accessTrends", void 0);
    WebContentFilteringCategoryReport = __decorate([
        Entity({
            singularName: 'Category report',
            pluralName: 'Category reports',
            endpoint: 'WebContentFiltering/Reports/CategoryReports',
            readonly: true,
            loadAll: true,
            baseUrl: ɵ0,
            allItemsEndpointTrailingSlash: false,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], WebContentFilteringCategoryReport);
    return WebContentFilteringCategoryReport;
}(WebContentFilteringReportBase));
export { WebContentFilteringCategoryReport };
export { ɵ0 };
