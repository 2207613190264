import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'network-device-icon',
	template: `
		<span class="wcd-margin-xsmall-right tvm-network-device-icon"></span>
	`,
	styleUrls: ['./network-device-icon.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkDeviceIconComponent {}
