var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingCustomActionEntity } from './hunting-custom-action-result.value-object';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (rawData) {
    return rawData &&
        rawData.map(function (machine) { return ({
            EntityId: machine.SenseMachineId,
            EntityName: machine.ComputerDnsName,
            EntityType: HuntingRuleEntityType.Machine,
        }); });
};
var HuntingCustomActionDeviceNames = /** @class */ (function (_super) {
    __extends(HuntingCustomActionDeviceNames, _super);
    function HuntingCustomActionDeviceNames() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingCustomActionDeviceNames = __decorate([
        ApiCall({
            name: 'Get names of machines by machine IDs',
            endpoint: 'machines/profiles',
            baseUrl: ɵ0,
            method: 'POST',
            fixedData: { idType: 'SenseMachineId' },
            parseData: ɵ1,
            type: HuntingCustomActionEntity,
        })
    ], HuntingCustomActionDeviceNames);
    return HuntingCustomActionDeviceNames;
}(ApiCallModel));
export { HuntingCustomActionDeviceNames };
var ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ3 = function (rawData) {
    return rawData &&
        rawData.map(function (user) { return ({
            EntityId: user.ObjectId,
            EntityName: user.DisplayName,
            EntityType: HuntingRuleEntityType.User,
        }); });
};
var HuntingCustomActionAadUserNames = /** @class */ (function (_super) {
    __extends(HuntingCustomActionAadUserNames, _super);
    function HuntingCustomActionAadUserNames() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingCustomActionAadUserNames = __decorate([
        ApiCall({
            name: 'Get names of AAD users by user IDs',
            endpoint: 'users/aad',
            baseUrl: ɵ2,
            method: 'POST',
            parseData: ɵ3,
            type: HuntingCustomActionEntity,
        })
    ], HuntingCustomActionAadUserNames);
    return HuntingCustomActionAadUserNames;
}(ApiCallModel));
export { HuntingCustomActionAadUserNames };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
