import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: 'Storage account properties',
	pluralName: '',
	readonly: true,
})
export class StorageAccountProperties extends ModelBase {
	@EntityField({ data: 'storageAccountResourceId' })
	storageAccountResourceId: string;

	@EntityField({ data: 'subscriptionId' })
	subscriptionId: string;

	@EntityField({ data: 'resourceGroup' })
	resourceGroup: string;

	@EntityField({ data: 'name' })
	name: string;
}
