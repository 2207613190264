var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../../../shared/components/tabs/tab.model';
import { Paris } from '@microsoft/paris';
import { BaselineProfileSettings } from '@wcd/domain';
import { TvmTextsService } from '../../../../../tvm/services/tvm-texts.service';
var ProfileSettingsStepComponent = /** @class */ (function (_super) {
    __extends(ProfileSettingsStepComponent, _super);
    function ProfileSettingsStepComponent(tvmTextsService, i18n, paris) {
        var _this = _super.call(this) || this;
        _this.tvmTextsService = tvmTextsService;
        _this.i18n = i18n;
        _this.paris = paris;
        _this.MessageBarType = MessageBarType;
        _this.onOperatingSystemsDropdownChanged = function (newTab) {
            _this.selectedOperatingSystemAction = newTab;
            _this.selectedBenchmarkAction = null;
            _this.selectedCisComplianceLevelAction = null;
            _this.data.benchmark = null;
            _this.data.benchmarkVersion = null;
            _this.data.complianceLevel = null;
            _this.data.selectedSettings = [];
            _this.setBenchmarksActions();
            _this.data.operatingSystem = _this.selectedOperatingSystemAction.data.operatingSystem;
            _this.data.operatingSystemVersion = _this.selectedOperatingSystemAction.data.operatingSystemVersion;
        };
        _this.onBenchmarkDropdownChanged = function (newTab) {
            _this.data.selectedSettings = [];
            _this.selectedBenchmarkAction = newTab;
            _this.setCisComplianceLevelsActions();
            _this.data.benchmark = _this.selectedBenchmarkAction.data.benchmarkName;
            _this.data.benchmarkVersion = _this.selectedBenchmarkAction.data.benchmarkVersion;
        };
        _this.onCisComplianceLevelDropdownChanged = function (newTab) {
            _this.data.selectedSettings = [];
            _this.selectedCisComplianceLevelAction = newTab;
            _this.data.complianceLevel = _this.selectedCisComplianceLevelAction.name;
        };
        return _this;
    }
    ProfileSettingsStepComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    ProfileSettingsStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setStepValidation(true);
        this.profileSettingsRepo = this.paris.getRepository(BaselineProfileSettings);
        this.settings$ = this.profileSettingsRepo.allItems$;
        this._subscription = this.settings$.subscribe(function (settings) {
            _this.operatingSystemsActions = settings.map(function (setting) { return new TabModel({
                id: setting.operatingSystem + setting.operatingSystemVersion,
                name: setting.operatingSystem + " " + setting.operatingSystemVersion,
                data: setting
            }); });
            if (_this.data.operatingSystem && _this.data.operatingSystemVersion) {
                _this.selectedOperatingSystemAction = _this.operatingSystemsActions.find(function (action) {
                    return action.id === _this.data.operatingSystem + _this.data.operatingSystemVersion;
                });
                _this.setBenchmarksActions();
            }
        });
    };
    ProfileSettingsStepComponent.prototype.setBenchmarksActions = function () {
        var _this = this;
        this.benchmarksActions = this.selectedOperatingSystemAction.data.benchmarks.map(function (benchmark) { return new TabModel({
            id: benchmark.benchmarkName + " " + benchmark.benchmarkVersion,
            name: (_this.tvmTextsService.getBaselineBenchmarkLabel(benchmark.benchmarkName) || benchmark.benchmarkName) + " v" + benchmark.benchmarkVersion,
            data: {
                benchmarkName: benchmark.benchmarkName,
                benchmarkVersion: benchmark.benchmarkVersion,
                complianceLevels: benchmark.complianceLevels
            }
        }); });
        if (this.data.benchmark && this.data.benchmarkVersion) {
            this.selectedBenchmarkAction = this.benchmarksActions.find(function (action) {
                return action.data.benchmarkName === _this.data.benchmark && action.data.benchmarkVersion === _this.data.benchmarkVersion;
            });
            this.setCisComplianceLevelsActions();
        }
    };
    ProfileSettingsStepComponent.prototype.setCisComplianceLevelsActions = function () {
        var _this = this;
        var levels = this.selectedBenchmarkAction.data.complianceLevels;
        this.complianceLevelsActions = levels.map(function (level) { return new TabModel({
            id: levels.indexOf(level),
            name: level
        }); });
        if (this.data.complianceLevel) {
            this.selectedCisComplianceLevelAction = this.complianceLevelsActions.find(function (action) { return action.name === _this.data.complianceLevel; });
        }
    };
    return ProfileSettingsStepComponent;
}(WizardBaseStep));
export { ProfileSettingsStepComponent };
