import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { AirsNotificationRulesStore } from '../services/airs-notification-rules.store';
import { AirsNotificationRulesReportsStore } from '../services/airs-notification-rules-reports.store';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AuthService } from '@wcd/auth';
import { AirsNotificationRulesService } from '../services/airs-notification-rules.service';

@Component({
	selector: 'airs-notification-rules',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './airs-notification-rules.component.html',
})
export class AirsNotificationRulesComponent {
	notificationRules: Array<AirsNotificationRuleModel> = [];
	notificationRulesReports: Array<AirsNotificationRuleModel> = [];
	error: Error;

	constructor(
		public notificationRulesStore: AirsNotificationRulesStore,
		public notificationRulesService: AirsNotificationRulesService,
		public notificationRulesReportsStore: AirsNotificationRulesReportsStore,
		private changeDetectorRef: ChangeDetectorRef,
		public authService: AuthService
	) {
		this.updateNotificationRules();
		notificationRulesService.isEditingNotificationRule$.subscribe(() => changeDetectorRef.markForCheck());
	}

	get allNotificationRules() {
		return this.notificationRules
			.concat(this.notificationRulesReports)
			.sort((a: AirsNotificationRuleModel, b: AirsNotificationRuleModel) => {
				return a.id < b.id ? 1 : -1;
			});
	}

	private updateNotificationRules() {
		this.notificationRulesStore.items$.subscribe(
			data => {
				this.notificationRules = data;
				this.changeDetectorRef.markForCheck();
			},
			(error: Error) => {
				this.error = error;
				this.changeDetectorRef.markForCheck();
			}
		);
	}

	removeNotificationRule(notificationRule: AirsNotificationRuleModel) {
		const store = notificationRule.type.requireSchedule
			? this.notificationRulesReportsStore
			: this.notificationRulesStore;
		store.remove(notificationRule);
	}
}
