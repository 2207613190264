import { mapValues } from 'lodash-es';
import { getTheme } from 'office-ui-fabric-react';
import { Lazy } from '@wcd/utils';
import * as i0 from "@angular/core";
function createColorMap(palette) {
    return mapValues(palette, function (value, key) { return ({
        name: key,
        raw: value,
        classes: {
            font: "ms-fontColor-" + key,
            background: "ms-bgColor-" + key,
            border: "ms-borderColor-" + key,
        },
    }); });
}
/**
 * Service for exposing colors (in various forms) to components and other consumers in an easy way.
 */
var KnownColorsService = /** @class */ (function () {
    function KnownColorsService() {
        var _this = this;
        this._theme = getTheme();
        this._colorsMap = new Lazy(function () {
            return createColorMap(_this._theme.palette);
        });
        this._fontColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('font');
        });
        this._backgroundColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('background');
        });
        this._borderColorsClassMap = new Lazy(function () {
            return _this._calculateClassMap('border');
        });
    }
    Object.defineProperty(KnownColorsService.prototype, "knownColorsMap", {
        get: function () {
            return this._colorsMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "fontColorsClassMap", {
        get: function () {
            return this._fontColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "backgroundColorsClassMap", {
        get: function () {
            return this._backgroundColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KnownColorsService.prototype, "borderColorsClassMap", {
        get: function () {
            return this._borderColorsClassMap.value;
        },
        enumerable: true,
        configurable: true
    });
    KnownColorsService.prototype._calculateClassMap = function (type) {
        return mapValues(this.knownColorsMap, function (value) { return value.classes[type]; });
    };
    KnownColorsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function KnownColorsService_Factory() { return new KnownColorsService(); }, token: KnownColorsService, providedIn: "root" });
    return KnownColorsService;
}());
export { KnownColorsService };
