import { ChangeDetectionStrategy, Component, Input, QueryList, ViewChildren } from '@angular/core';
import { ReportWidgetsLayoutRowModel } from '../models/report-widgets-layout.row.model';
import { ReportWidgetsContainer } from '../models/report-widgets-container.interface';
import { ReportWidgetComponent } from './report-widget.component.base';
import { ReportColumnComponent } from './report-column.component';
import { ReportWidgetDynamicComponent } from './report-widget.component';
import { flatMap } from 'lodash-es';

@Component({
	selector: 'report-row',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<h2 [attr.aria-aria-level]="headerLevel" *ngIf="reportRow.title" class="dashboard-row-title">{{ reportRow.title }}</h2>
		<div class="dashboard-columns">
			<report-column
				*ngFor="let reportColumn of reportRow.columns"
				#column
				[reportColumn]="reportColumn"
				[dataOptions]="dataOptions"
				[params]="params"
				[style.width]="reportColumn.width ? reportColumn.width + 'px' : null"
				[ngClass]="
					'wcd-flex-' +
					(reportColumn.width ? 'none' : reportColumn.weight) +
					(reportColumn.className ? ' ' + reportColumn.className : '')
				"
				class="dashboard-column fit-to-row"
				[headerLevel]="++headerLevel"
			>
			</report-column>
		</div>
		<report-widget
			*ngFor="let widgetComponent of reportRow.widgetComponents"
			[params]="params"
			[dataOptions]="dataOptions"
			[widgetComponentType]="widgetComponent.type"
			[ngClass]="'wcd-flex-' + widgetComponent.weight"
			[headerLevel]="headerLevel"
		></report-widget>
	`,
})
export class ReportRowComponent implements ReportWidgetsContainer {
	@Input() reportRow: ReportWidgetsLayoutRowModel;
	@Input() params: { [index: string]: any };
	@Input() dataOptions: {};
	@Input() headerLevel: number = 2;

	@ViewChildren(ReportWidgetDynamicComponent) widgets: QueryList<ReportWidgetDynamicComponent>;
	@ViewChildren('column') columns: QueryList<ReportColumnComponent>;

	get allWidgets(): Array<ReportWidgetComponent> {
		return this.widgets
			.toArray()
			.map((dynamicWidget: ReportWidgetDynamicComponent) => dynamicWidget.widgetComponent)
			.concat(<Array<ReportWidgetComponent>>(
				flatMap(this.columns.toArray().map((column: ReportWidgetsContainer) => column.allWidgets))
			));
	}
}
