<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="RemediationTaskCreation">
	<div class="wcd-full-height wcd-flex-vertical wcd-padding-large-horizontal wcd-padding-vertical">
		<header class="wcd-flex-none wcd-flex-horizontal wcd-margin-large-bottom">
			<span class="side-panel-title wcd-text-overflow-ellipsis">
				<h2>
					<p>
						<b>
							{{ 'tvm.securityRecommendation.remediationTaskCreation.title' | i18n }}
						</b>
					</p>
				</h2>
				<h3>
					<p>
						<b>
							{{ remediationTaskTitle }}
						</b>
					</p>
				</h3>
			</span>
		</header>

		<div class="wcd-flex-1 wcd-scroll-vertical tvm-list tvm-list--no-columns">
			<div *ngIf="shouldDisable" class="tvm-list__block_item">
				<fab-message-bar [messageBarType]="_messageBarType.warning" [styles]="messageBarStyles">
					<div class="spaced">{{
						warningText
					}}<a [href]="exceptionLink" *ngIf="shouldLinkToException">{{
							'tvm.securityRecommendation.remediationTaskCreation.viewException' | i18n
						}}</a></div>
				</fab-message-bar>
			</div>

			<div class="tvm-list__block_item">
				{{ 'tvm.securityRecommendation.generalRemediationDescriptionFirst' | i18n }}
				{{ 'tvm.securityRecommendation.generalRemediationDescriptionLinkToActivity' | i18n }}
				{{ 'tvm.securityRecommendation.generalRemediationDescriptionSecond' | i18n }}
				<a href="remediation">
					{{ 'tvm.securityRecommendation.generalRemediationDescriptionLinkToRemediationPage' | i18n }}
				</a>
				{{ 'tvm.securityRecommendation.generalRemediationDescriptionThird' | i18n }}
			</div>

			<ng-container *ngIf="isScaRemediation">
				<div class="tvm-list__header">
					{{ 'tvm.securityRecommendation.remediationTaskCreation.remediationOptions' | i18n }}
				</div>
				<div
					class="tvm-list__block_item"
					[innerHTML]="recommendation.remediationDescription"
				></div>
			</ng-container>

			<ng-container *ngIf="!showProductivityImpactComponents">
				<div class="tvm-list__header_semi-bold">
					{{ (isNetworkDevice ? 'tvm.common.exposedNetworkDevices' : 'tvm.securityRecommendation.remediationTaskCreation.exposedDevices') | i18n }}
				</div>
				<div class="tvm-list__block_item">
					{{recommendation.assetsStatistics.assetsAtRiskCount | prettyNumber }} / {{recommendation.assetsStatistics.totalAssetCount | prettyNumber }}
				</div>
			</ng-container>

			<div *ngIf="showProductivityImpactComponents" class="tvm-list">
				<div class="tvm-list__key_val_item">
					<dt>{{'tvm.common.exposedDevices' | i18n}}</dt>
					<dd>{{recommendation.assetsStatistics.assetsAtRiskCount | prettyNumber}} / {{recommendation.assetsStatistics.totalAssetCount | prettyNumber}}</dd>
				</div>

				<div class="tvm-list__key_val_item">
					<dt>
						{{'tvm.securityRecommendation.remediationTaskCreation.noUserImpactMachines' | i18n}}
						<wcd-help [text]="'tvm.securityRecommendation.userImpact.infoIconToolTip' | i18n"></wcd-help>
					</dt>
					<dd [innerHTML]="safeMachinesCounterText"></dd>
				</div>

				<ng-container *ngIf="applyRemediationSelection">
					<div class="tvm-list__header" [ngClass]="{ disabled: shouldDisable }">
						{{ 'tvm.securityRecommendation.remediationTaskCreation.applyProductivityRemediationTypeTitle' | i18n }}
					</div>
					<div class="tvm-list__block_item">
						<fancy-select class="command-bar-item-dropdown tvm-remediation-dropdown"
							[(ngModel)]="productivityImpactRemediation"
							[isBordered]="true"
							[isDisabled]="isSaving || shouldDisable"
							[ngClass]="{ 'disabled': shouldDisable }"
							[values]="productivityImpactRemediationTypeList"
							[isFullWidth]="true"
							[formatLabel]="getUserImpactRemediationLabel">
						</fancy-select>
					</div>
				</ng-container>
			</div>

			<ng-container *ngIf="isDeviceGroupFeatureEnabled && !shouldHideMachineGroups">
				<div class="tvm-list__header" [ngClass]="{ disabled: shouldDisable }">
					{{ 'tvm.securityRecommendation.remediationTaskCreation.remediationDescriptionHeader' | i18n }}
				</div>
				<div class="tvm-list__block_item" [ngClass]="{ disabled: shouldDisable }">
					{{ 'tvm.securityRecommendation.remediationTaskCreation.remediationDescription' | i18n }}
				</div>
				<ng-container *ngIf="(!isExceptionsPerRbacFeatureEnabled || existingRecommendationExceptions); else loading">
					<div class="tvm-list__header_semi-bold" [ngClass]="{ disabled: shouldDisable }">
							{{ 'tvm.securityRecommendation.remediationTaskCreation.machineGroupSelection' | i18n }}
					</div>
					<div class="tvm-list__block_item">
						<div class="tvm-remediation-dropdown" [ngClass]="{ disabled: shouldDisable }">
							<machine-groups-dropdown
								[dataviewTitle]="remediationTaskTitle"
								[preselectedMachineGroups]="machineGroupsFromTvmGlobalFilter" (groupChosen)="machineGroupSelected($event)"
								[isDisabled]="shouldDisabledMachineGroupsDropdown"
								[disabledMachineGroups]="existingActiveExceptionsRbacGroupIds">
							</machine-groups-dropdown>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="isRemediationOptionsSupported">
				<div class="tvm-list__header_semi-bold" [ngClass]="{ disabled: shouldDisable }">
					{{ 'tvm.securityRecommendation.remediationTaskCreation.remediationAction' | i18n }}
					<wcd-help [text]="'tvm.securityRecommendation.remediationTaskCreation.remediationSelection' | i18n"></wcd-help>
				</div>
				<div class="tvm-list__block_item">
					<fancy-select
						[ariaLabel]="'Status'"
						[values]="selectableRemediationActions"
						[(ngModel)]="selectedRemediationAction"
						[isBordered]="true"
						labelClass="dropdown-width-small-medium"
						name="remediation-action"
						label="name"
						class="command-bar-item-dropdown tvm-remediation-dropdown"
						[isDisabled]="isSaving ||shouldDisable"
						[ngClass]="{ 'disabled': shouldDisable }"
						(ngModelChange)="onRemediationDropdownChanged($event)">
					</fancy-select>
				</div>
			</ng-container>
			<ng-container *ngIf="connectedItsmTools.length > 0 && (isVaUpdateRemediation || isVaUninstallRemediation || isVaUpgradeRemediation || isScaRemediation)">
				<div class="tvm-list__header" [ngClass]="{ disabled: shouldDisable }">
					{{ 'tvm.remediationTask.ticket.itsmToolsHeader' | i18n }}
				</div>
				<div class="tvm-list__header_semi-bold" [ngClass]="{ disabled: shouldDisable }">
					{{ 'tvm.remediationTask.ticket.itsmTools' | i18n }}
				</div>
				<div class="tvm-list__block_item wcd-flex-vertical">
					<ng-container *ngIf="connectedItsmTools.length > 0; else notConnected">
						<wcd-checkbox *ngIf="!isServiceNowFeatureEnabled ; else newItsmToolsSelector"
							[attr.data-track-value]="createTicketInIntune"
							[isDisabled]="isSaving || shouldDisable"
							[(ngModel)]="createTicketInIntune"
							[ngClass]="{ disabled: shouldDisable }"
							[label]="openIntuneTicketText"
							(change)="updateSubmitDataTrackValue($event)"
							class="tvm-list__checkbox_left_margin"
							data-track-id="createIntuneTicketCheckbox"
							data-track-type="Selection"
						>
						</wcd-checkbox>
						<ng-template #newItsmToolsSelector>
							<fancy-select [(ngModel)]="selectedItsmTool"
							[formatLabel]="getItsmToolLabel"
							[ariaLabel]="'tvm_remediationTask_ticket_itsmTools' | i18n"
							[isBordered]="true"
							[isDisabled]="isSaving || shouldDisable"
							class="command-bar-item-dropdown tvm-remediation-dropdown"
							[isFullWidth]="true"
							(ngModelChange)="updateSubmitDataTrackValue(null)"
							[ngClass]="{ 'disabled': shouldDisable }"
							[values]="connectedItsmTools.concat([itsmNoneOption])"
							[placeholder]="'tvm_securityRecommendation_remediationTaskCreation_choose' | i18n"
							data-track-id="createItsmTicketDropdown"
							data-track-type="Selection"
							[attr.data-track-value]="selectedItsmTool"
							[isDisabled]="shouldDisable">
							</fancy-select>
							<ng-container *ngTemplateOutlet="notConnected"></ng-container>
						</ng-template>
					</ng-container>
					<ng-template #notConnected>
						<ng-container *ngFor="let itsmTool of getDisconnectedItsmTools(); index as i">
							<a *ngIf="!shouldDisable; else disabledLink"
								role="link"
								tabindex="0"
								[ngClass]="{ 'wcd-padding-small-top': i !== 0 || connectedItsmTools.length !== 0 }"
								style="align-self:self-start"
								(click)="moveToSettingsPage(itsmTool)"
								(keydown.enter)="moveToSettingsPage(itsmTool)"
								[attr.data-track-id]="'GoToOnboard' + itsmTool + 'Settings'"
								data-track-type="Navigation"
								>{{ getEnableItsmToolText(itsmTool) }}
							</a>
							<ng-template #disabledLink>
								<div class="disabled wcd-padding-small-top">
									{{ getEnableItsmToolText(itsmTool) }}
								</div>
							</ng-template>
						</ng-container>
					</ng-template>
				</div>
			</ng-container>

			<div class="tvm-list__header_semi-bold" [ngClass]="{ disabled: shouldDisable }">
				{{ 'tvm.common.priority' | i18n }}
			</div>
			<div class="tvm-list__block_item">
				<fancy-select [(ngModel)]="remediationTaskPriority"
					[formatLabel]="getPriorityLabel"
					[ariaLabel]="'tvm.common.priority' | i18n"
					[isBordered]="true"
					[isDisabled]="isSaving || shouldDisable"
					class="command-bar-item-dropdown tvm-remediation-dropdown"
					[isFullWidth]="true"
					[ngClass]="{ 'disabled': shouldDisable }"
					[values]="remediationTaskPriorityList"
					[placeholder]="'tvm.securityRecommendation.remediationTaskCreation.select' | i18n">
				</fancy-select>
			</div>

			<div class="tvm-list__header_semi-bold" [ngClass]="{ disabled: shouldDisable || shouldDisableDueToRemediationType }">
				{{ 'tvm.securityRecommendation.remediationTaskCreation.dueDate.header' | i18n }}
				<wcd-help [text]="'tvm.securityRecommendation.remediationTaskCreation.dueDate.tooltip' | i18n"></wcd-help>
			</div>
			<div class="tvm-list__block_item date-picker">
				<extended-fab-date-picker
					[ariaLabel]="'common.dueDate' | i18n "
					[disabled]="isSaving || shouldDisable || shouldDisableDueToRemediationType"
					[minDate]="earliestDateAllowed"
					(onSelectDate)="onSelectDate($event)"
					[placeHolder]="'tvm.securityRecommendation.remediationTaskCreation.select' | i18n"
					[disableAutoFocus]="false"
				></extended-fab-date-picker>
			</div>

			<div class="tvm-list__header" [ngClass]="{ disabled: shouldDisable }">
				{{ 'tvm.securityRecommendation.remediationTaskCreation.addNotes' | i18n }}
			</div>
			<div class="tvm-list__block_item">
				<textarea
					class="comment-input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[(ngModel)]="notes"
					name="notes-text"
					maxlength="1000"
					rows="4"
					[disabled]="isSaving || shouldDisable"
					[placeholder]="'tvm.securityRecommendation.remediationTaskCreation.notesPlaceHolder' | i18n"
					[ngClass]="{ disabled: shouldDisable }"
					[attr.aria-label]="'tvm.securityRecommendation.remediationTaskCreation.addNotes' | i18n">
				</textarea>
			</div>
		</div>

		<div class="wcd-flex-none wcd-padding-small-vertical" [ngClass]="{ disabled: shouldDisable }">
			<wcd-checkbox
				[isDisabled]="isSaving"
				[(ngModel)]="exportToCsv"
				[label]="'tvm.securityRecommendation.remediationTaskCreation.exportToCsv' | i18n"
				[ngClass]="{ disabled: shouldDisable }"
			></wcd-checkbox>
		</div>

		<footer class="wcd-flex-none wcd-flex-horizontal wcd-border-top">
			<div class="wcd-flex-1 wcd-padding-small-top">
				<fab-primary-button
					data-track-id="Save"
					data-track-type="Button"
					[attr.data-track-value]="submitDataTrackValue"
					(onClick)="submitRequest()"
					[disabled]="isSaving || shouldDisableSubmitButton()"
				>
					<i class="loader-icon" [hidden]="!isSaving"></i>
					{{ 'tvm.securityRecommendation.remediationTaskCreation.submitRequest' | i18n }}
				</fab-primary-button>
			</div>
		</footer>
	</div>

	<ng-template #loading>
		<fab-spinner
		[size]="SpinnerSize.large"
		[label]="'webContentFilteringPolicy_sidePane_sections_organizationalscope_machinegroups_loading' | i18n">
		</fab-spinner>
	</ng-template>
</wcd-panel>
