<div
	class="wcd-full-height wcd-flex-vertical"
	data-track-component="Investigation comments Panel"
	data-track-component-type="Side Panel"
>
	<header class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
		<div class="page-header-icon wcd-flex-none">
			<wcd-shared-icon class="round-icon color-box-gray-200" iconName="comment"> </wcd-shared-icon>
		</div>
		<div class="wcd-flex-1 wcd-flex-center-vertical">
			<h3 class="side-panel-title">{{ i18nService.strings.investigations_fields_comments }}</h3>
		</div>
	</header>
	<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-vertical wcd-padding-large-horizontal">
		<editable-comments-list
			[options]="commentsOptions"
			[showLink]="showLink"
			[refreshOn]="investigationGraphData.commentCount$ | async"
			[getLinkTitle]="getLinkTitle"
			[readonly]="readonly"
			[rbac]="{ permissions: ['alertsInvestigation'], state: 'disabled' }"
			(save)="saveNewComment($event)"
			[isSaving]="isSavingComment"
			(commentLinkClick)="
				investigationActionsService.showInvestigationAction(
					$event.linkedObjects['investigation_action']
				)
			"
		></editable-comments-list>
	</div>
</div>
