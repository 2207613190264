import { EntityRelationship, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { FileDownloadLink } from '../file.download-link.value-object';
import { File } from '../file.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileDownloadLink,
	getRelationshipData: (file: File) => ({ Sha: file.id }),
	endpoint: 'sampledownloadrequest/download',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
})
export class FileFileDownloadLinkRelationship
	implements EntityRelationshipRepositoryType<File, FileDownloadLink> {}
