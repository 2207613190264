import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
	selector: 'network-device-field',
	template: `
		<network-device-icon class="wcd-margin-xsmall-right"></network-device-icon>{{ title }}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NetworkDeviceFieldComponent {
	@Input() title: string;
}
