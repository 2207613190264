type filterExpressionType = string | number | boolean | Date;

type filterOperator = 'and' | 'or';

export class ODataFilterQueryBuilder {
	private fragments: string[] = [];
	private operator: filterOperator;

	constructor(operator: filterOperator = 'and') {
		this.operator = operator;
	}

	private filterExpression = (field: string, operator: string, value: filterExpressionType) => {
		this.fragments.push(`${field} ${operator} ${this.getValue(value)}`);
		return this;
	};

	private binaryFunctionExpression = (field: string, functionName: string, value: filterExpressionType) => {
		this.fragments.push(`${functionName}(${field}, ${this.getValue(value)})`);
		return this;
	};

	private collectionQueryExpression = (collectionName: string, collectionFunction: string, functionName: string, value: filterExpressionType) => {
		this.fragments.push(`${collectionName}/${collectionFunction}(element: element ${functionName} ${this.getValue(value)})`);
		return this;
	};

	any = (field: string, value: filterExpressionType) => this.collectionQueryExpression(field, 'any', 'eq', value)

	equals = (field: string, value: filterExpressionType) => this.filterExpression(field, 'eq', value);

	notEquals = (field: string, value: filterExpressionType) => this.filterExpression(field, 'ne', value);

	lessThan = (field: string, value: filterExpressionType) => this.filterExpression(field, 'lt', value);

	greaterThan = (field: string, value: filterExpressionType) => this.filterExpression(field, 'gt', value);

	contains = (field: string, value: filterExpressionType) => this.binaryFunctionExpression(field, 'Contains', value);

	lessThanOrEquals = (field: string, value: filterExpressionType) =>
		this.filterExpression(field, 'le', value);

	greaterThanOrEquals = (field: string, value: filterExpressionType) =>
		this.filterExpression(field, 'ge', value);

	and = (predicate: (filter: ODataFilterQueryBuilder) => ODataFilterQueryBuilder) => {
		this.fragments.push(`(${predicate(new ODataFilterQueryBuilder('and')).toQuery()})`);
		return this;
	};

	join = (queryBuilder: ODataFilterQueryBuilder) => {
		this.fragments.push(queryBuilder.toQuery());
		return this;
	};

	or = (predicate: (filter: ODataFilterQueryBuilder) => ODataFilterQueryBuilder) => {
		this.fragments.push(`(${predicate(new ODataFilterQueryBuilder('or')).toQuery()})`);
		return this;
	};

	toQuery = (): string => {
		if (!this.fragments || this.fragments.length < 1) return '';
		return this.fragments.join(` ${this.operator} `);
	};

	private getValue(value: filterExpressionType): string {
		let type: string = typeof value;
		if (value instanceof Date) type = 'date';

		switch (type) {
			case 'string':
				return `'${value}'`;
			case 'date':
				return `${(value as Date).toISOString()}`;
			default:
				return `${value}`;
		}
	}
}
