import { Injectable, SecurityContext } from '@angular/core';
import {
	SafeValue,
	DomSanitizer,
	SafeHtml,
	SafeStyle,
	SafeScript,
	SafeUrl,
	SafeResourceUrl,
} from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';
import { FeaturesService } from '@wcd/config';
import { Feature } from '@wcd/config';

/**
 * A wrapper to determine between NG's DOMSanitizer or SCC's DOMPurify services.
 */
@Injectable({ providedIn: 'root' })
export class WicdSanitizerService {
	constructor(private domSanitizer: DomSanitizer,
				private featuresService: FeaturesService) {}

	sanitize(context: SecurityContext, value: SafeValue | string | null): string | null {
		if (sccHostService.isSCC && this.featuresService.isEnabled(Feature.UseSCCDomSanitizer)) {
			return sccHostService.ui.sanitize(value as string);
		}
		else return this.domSanitizer.sanitize(context, value);
	};

	bypassSecurityTrustHtml(value: string): SafeHtml {
		return this.domSanitizer.bypassSecurityTrustHtml(value);
	};

	bypassSecurityTrustStyle(value: string): SafeStyle {
		return this.domSanitizer.bypassSecurityTrustStyle(value);
	}

	bypassSecurityTrustScript(value: string): SafeScript {
		return this.domSanitizer.bypassSecurityTrustScript(value);
	}

	bypassSecurityTrustUrl(value: string): SafeUrl {
		return this.domSanitizer.bypassSecurityTrustUrl(value);
	}

	bypassSecurityTrustResourceUrl(value: string): SafeResourceUrl {
		return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
	};
}
