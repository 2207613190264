import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input } from '@angular/core';
import { ActionBaseEntity, ActionHistoryRelatedEntity, RemediationActionTypeTypes } from '@wcd/domain';

@Component({
	selector: 'action-history-details-field',
	template: `
		<ul
			class="field-value-list inline-list with-bullets"
			[wcdTooltip]="tooltip"
			[wcdTooltipAllowHtmlRendering]="true"
			[ngClass]="{ unstyled: (action.relatedEntities?.length || 0) <= 1 }"
		>
			<ng-container [ngSwitch]="action.actionType?.type">
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.start_multiple_investigations">
					<li>
						<span>
							<ng-container *ngIf="action.expandedHostsCount === 1; else multipleHosts">
								{{
									'actionHistory_details_fields_expandedHosts_singular'
										| i18n
											: {
													source: action.expandSource
											  }
								}}
							</ng-container>
							<ng-template #multipleHosts
								>{{
									'actionHistory_details_fields_expandedHosts_plural'
										| i18n
											: {
													hostCount: action.expandedHostsCount,
													source: action.expandSource
											  }
								}}
							</ng-template>
						</span>
					</li>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.live_response_command">
					<li>{{ action.rawCommand }}</li>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.email_remediation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_junk">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_inbox">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.hard_email_remediation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.move_to_deleted_items">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.delete_attachment">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.submit_for_detonation">
					<ng-container [ngTemplateOutlet]="emailRemediationAction"></ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.block_sender">
					<ng-container *ngFor="let item of action.relatedEntities">
						<li>
							<mailbox-details-field
								[senderEmailAddress]="
									item?.senderFromMailAddress || item?.p1SenderFromMailAddress
								"
								[displayQuery]="item?.displayQuery"
							></mailbox-details-field>
						</li>
					</ng-container>
				</ng-container>
				<ng-container *ngSwitchCase="RemediationActionTypeTypes.confirm_compromised_user">
					<li>{{ action.investigatedUser.upn }}</li>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.kill_process">
					<ng-container *ngTemplateOutlet="processDisplay"></ng-container>
				</ng-container>

				<ng-container *ngSwitchCase="RemediationActionTypeTypes.kill_processes">
					<ng-container *ngTemplateOutlet="processDisplay"></ng-container>
				</ng-container>

				<ng-container *ngSwitchDefault>
					<ng-container *ngIf="getDisplayByActionType[(action.actionType?.type)] as displayMethod">
						<li *ngFor="let item of action.relatedEntities">
							<span>{{ displayMethod(item) }}</span>
						</li>
					</ng-container>
				</ng-container>
				<ng-template #emailRemediationAction>
					<li *ngFor="let item of action.relatedEntities">
						<mailbox-details-field
							[senderEmailAddress]="item?.senderFromMailAddress"
							[recipientEmailAddress]="item?.recipientEmailAddress"
							[displayQuery]="item?.displayQuery"
						></mailbox-details-field>
					</li>
				</ng-template>
			</ng-container>
		</ul>
		<ng-template #processDisplay>
			<li *ngFor="let item of action.relatedEntities">
				<span>
					<ng-container *ngIf="item.processCreationTime; else noCreationTime"
						>{{
							'actionHistory_details_fields_processWithCreationTime'
								| i18n
									: {
											fileName: item.fileName,
											processId: item.processId,
											creationTime: item.processCreationTime | date: 'short'
									  }
						}}
					</ng-container>
					<ng-template #noCreationTime
						>{{
							'actionHistory_details_fields_process'
								| i18n
									: {
											fileName: item.fileName,
											processId: item.processId
									  }
						}}
					</ng-template>
				</span>
			</li>
		</ng-template>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHistoryDetailsFieldComponent {
	private _action: ActionBaseEntity;
	RemediationActionTypeTypes = RemediationActionTypeTypes;
	getDisplayByActionType: Partial<
		Record<RemediationActionTypeTypes, (entity: ActionHistoryRelatedEntity) => string>
	> = {
		[RemediationActionTypeTypes.file_quarantine]: entity => entity.folderPath,
		[RemediationActionTypeTypes.service_quarantine]: entity => entity.serviceName,
		[RemediationActionTypeTypes.driver_quarantine]: entity => entity.driverName,
		[RemediationActionTypeTypes.persistence_method_quarantine]: entity => entity.methodCommandLine,
		[RemediationActionTypeTypes.undo_clean_resource]: entity =>
			entity.methodCommandLine || entity.driverName || entity.serviceName || entity.folderPath,
		[RemediationActionTypeTypes.mail_forwarding_rule_remediation]: entity => entity.mailboxPrimaryAddress,
		[RemediationActionTypeTypes.force_password_reset_remediation]: entity =>
			entity.mailboxPrimaryAddress || entity.userDisplayName,
		[RemediationActionTypeTypes.mail_delegation_remediation]: entity => entity.mailboxPrimaryAddress,
		[RemediationActionTypeTypes.user_inbox_rules_remediation]: entity => entity.mailboxPrimaryAddress,
		[RemediationActionTypeTypes.block_url]: entity => entity.url,
		[RemediationActionTypeTypes.confirm_user_compromised]: entity => entity.userDisplayName,
		[RemediationActionTypeTypes.suspend_user]: entity => entity.userDisplayName,
		[RemediationActionTypeTypes.invalidate_all_refresh_tokens_for_a_user]: entity =>
			entity.userDisplayName,
		[RemediationActionTypeTypes.disable_user]: entity => entity.userDisplayName,
		[RemediationActionTypeTypes.enable_user]: entity => entity.userDisplayName,
	};
	tooltip: string;

	@Input()
	set action(value: ActionBaseEntity) {
		this._action = value;
		this.changeDetectorRef.detectChanges();
		this.tooltip = this.elementRef.nativeElement.innerHTML;
		this.changeDetectorRef.markForCheck();
	}

	get action(): ActionBaseEntity {
		return this._action;
	}

	constructor(private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}
}
