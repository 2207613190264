import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as CreateRequest from '../machine-requests.models';

export type LogsCollectionRawApiRequestBody = CreateRequest.RawApiRequestBody<'LogsCollectionRequest'>;
export type LogsCollectionRawApiResponse = CreateRequest.RawApiResponse;

export type LogsCollectionParameters = CreateRequest.Parameters;
export type LogsCollectionResponse = CreateRequest.Response;

@ApiCall({
	name: 'Collect support logs',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
	}: LogsCollectionParameters): HttpOptions<LogsCollectionRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Type: 'LogsCollectionRequest',
		},
	}),
	parseData: (rawData: LogsCollectionRawApiResponse): LogsCollectionResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.Id,
	}),
})
export class MachineLogsCollectionApiCall extends ApiCallModel<
	LogsCollectionResponse,
	LogsCollectionParameters
> {}
