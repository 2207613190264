import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
	selector: 'agent-offboarding',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			class="wcd-margin-large-bottom"
			data-track-component-type="Settings"
			data-track-component="Agent Offboarding"
		>
			<markdown [data]="'endpointManagement.agent.offboarding.description' | i18n"></markdown>
		</div>
	`,
})
export class AgentOffboardingComponent {}
