export enum ExceptionJustification {
	ThirdPartyControl = 'ThirdPartyControl',
	AlternateMitigation = 'AlternateMitigation',
	CompensatingControlExists = 'CompensatingControlExists',
	AcceptableUse = 'AcceptableUse',
	AcceptableRisk = 'AcceptableRisk',
	PlannedRemediationPending = 'PlannedRemediationPending',
	FalsePositive = 'FalsePositive',
	Other = 'Other',
}
