import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { DataViewsModule } from '../../../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { TvmModule } from '../../../../../tvm/tvm.module';
import { ProfileDeviceEntityPanelComponent } from './components/profile-device.entity-panel.component';
import { ProfileDeviceEntityTypeService } from './services/profile-device.entity-type.service';
import { ProfileDeviceFieldsService } from './services/profile-device.fields.service';
import { ProfileDeviceConfigurationsDataviewComponent } from './components/profile-device-configurations.dataview.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [ProfileDeviceEntityPanelComponent, ProfileDeviceConfigurationsDataviewComponent],
	exports: [ProfileDeviceEntityPanelComponent, ProfileDeviceConfigurationsDataviewComponent],
	entryComponents: [ProfileDeviceEntityPanelComponent, ProfileDeviceConfigurationsDataviewComponent],
	providers: [ProfileDeviceFieldsService, ProfileDeviceEntityTypeService],
})
export class ProfileDeviceEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ProfileDeviceEntityTypeService);
	}
}
