var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
var ɵ0 = ['UserSid', 'AccountSid'], ɵ1 = ['UserName', 'AccountName'];
var AlertUser = /** @class */ (function (_super) {
    __extends(AlertUser, _super);
    function AlertUser() {
        var args = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            args[_i] = arguments[_i];
        }
        var _this = _super.apply(this, args) || this;
        _this.sid = _this.id;
        _this.accountName = _this.accountName || _this.name;
        _this.accountDomainName = _this.accountDomainName || _this.domain;
        return _this;
    }
    Object.defineProperty(AlertUser.prototype, "fullName", {
        get: function () {
            return "" + (this.domain ? this.domain + '\\' : '') + (this.name || '');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", String)
    ], AlertUser.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], AlertUser.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'DomainName' }),
        __metadata("design:type", String)
    ], AlertUser.prototype, "domain", void 0);
    AlertUser = __decorate([
        ValueObject({
            singularName: 'Alert User',
            pluralName: 'Alert Users',
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], AlertUser);
    return AlertUser;
}(ModelBase));
export { AlertUser };
export { ɵ0, ɵ1 };
