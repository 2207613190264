import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	OnDestroy,
	Output,
	EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {
	DataEntityType,
	DataSet,
	EntityModelBase,
	Paris,
	RelationshipRepository,
	Repository,
} from '@microsoft/paris';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { DateRangeModel, FULL_DATE_WITH_MILLISECONDS_FORMAT, TzDateService } from '@wcd/localization';
import { cloneDeep, find, findIndex, minBy, omit } from 'lodash-es';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Alert, CyberEvent, CyberEventMark } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { TimebarRange } from '../timebar-picker.component';
import { QuickAction } from '../quick-actions-list/quick-actions-list.component';
import { isCustomTimeRangeValue, TimeRangeId, TimeRangeValue } from '@wcd/date-time-picker';
import { Lazy } from '@wcd/utils';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppContextService, PreferencesService } from '@wcd/config';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';
import { CyberEventsFieldsService } from '../../../@entities/cyber_events/services/cyber-events.fields';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { CyberEventsUtilsService } from '../../../@entities/cyber_events/services/cyber-events-utils.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { SeverityTypeColorService } from '../../../shared/services/severity-type-color.service';
import { TimebarItem } from '@wcd/timebar';
import { Feature, FeaturesService } from '@wcd/config';
import { CyberEventPanelOptions } from '../../../@entities/cyber_events/components/cyber-event.entity-panel.component';
import { FiltersState } from '@wcd/ng-filters';
import { DataviewActionCustomRangeConfig } from '../../../dataviews/components/actions-components/dataview-action-custom-range.component';
import {
	DataviewAction,
	DataviewActionTypes,
} from '../../../dataviews/components/actions-components/dataview-actions.model';
import { v4 as uuid4 } from 'uuid';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { FabricIconNames } from '@wcd/scc-common';
import { SeverityType } from '@wcd/domain';

declare const moment: typeof import('moment');

export const TIME_RANGE_DEFAULT_PREFERENCE_ID = 'machine_events_time_range_default';
const ENTITY_TIMELINE_FIELDS_RESET = 'entity_timeline_fields_reset';
const DATAVIEW_FIELDS_PREFERENCE_KEY = 'dataView_Events_dataview';
const HIGHLIGHT_ALERT_ACTION_ID = 'highlightedAlert',
	EVENTS_HOT_STORAGE_IN_DAYS = 30,
	MAX_RANGE_IN_DAYS = 30,
	DEFAULT_ALERT_RANGE_IN_DAYS = 7,
	COLD_STORAGE_MAX_RANGE_IN_DAYS = 7,
	EXPORT_MAX_RANGE_IN_DAYS = 7,
	EVENTS_PAGE_SIZE = 200;

interface EntityEventsTimelineDateRange {
	from: Date;
	to: Date;
}

interface EntityEventsLoadingTextOptions {
	timelineDateRange: EntityEventsTimelineDateRange;
}

@Component({
	selector: 'entity-events-timeline',
	templateUrl: './entity-events-timeline.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityEventsTimelineComponent<TEntity extends EntityModelBase<string | number>>
	implements OnInit, OnDestroy {
	readonly isCustomTimeRangeValue = isCustomTimeRangeValue;
	readonly newTimebarFeatureName = Feature.NewTimebar;
	readonly legendStyles = {
		low: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.Low).raw } },
		medium: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.Medium).raw } },
		high: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.High).raw } },
	};
	@Input() removePaddingRight = false;
	@Input() isSmallPadding = false;
	@Input() loadMoreOnEmpty = false;
	@Input() toggleFullScreenEnabled = false;
	@Input() measurePerformance = false;

	@Input() entity: TEntity;

	/**
	 * A relationship repository to fetch Alerts for the TEntity.
	 */
	@Input() entityAlertsRepository: RelationshipRepository<TEntity, Alert>;

	/**
	 * A relationship repository to fetch marked timeline records for the TEntity.
	 */
	@Input() entityFlagsRepository: RelationshipRepository<TEntity, CyberEventMark>;

	/**
	 * Params to send to the query for the cyber events dataset
	 */
	@Input() cyberEventsQueryFixedOptions: Record<string, any>;

	/**
	 * Options to be sent to the cyber event(s) panel
	 */
	@Input() cyberEventsPanelOptions: CyberEventPanelOptions;

	/**
	 * Whether or not to display the column for the event's machine in the dataview.
	 */
	@Input() showMachineColumn = true;

	/**
	 *  Whether or not to show only part of the fields, and which ones
	 */
	@Input() shownFields: Array<string>;

	/**
	 *  Whether or not to enable search on timeline
	 */
	@Input() searchEnabled: boolean = true;

	/**
	 *  Whether or not to disable the export button while exporting
	 */
	@Input() showBusyOnExport: boolean = false;

	/**
	 *  Whether or not to support partial responses
	 */
	@Input() supportPartialResponse: boolean = false;

	/**
	 *  Whether or not to enable loading previous items on table top
	 */
	@Input() allowLoadItemsOnTableTop: boolean = false;

	/**
	 * Whether or not to enable infinite scrolling
	 */
	@Input() infiniteScrolling: boolean = true;

	@Input() allowFilters: boolean = true;

	/**
	 * Whether or not to show the time bar - allows setting date range and filter timeline by alerts
	 */
	@Input() showTimebar: boolean = true;

	/**
	 * Whether or not to show the expander button on top of the time bar
	 */
	@Input() enableTimebarExpander: boolean = false;

	@Input() className: string = 'no-left-padding';

	@Input() padLeft: boolean = true;

	@Input() customFlags?: Array<CyberEventMark> = [];

	@Input() showLegend?: boolean = false;

	/**
	 * changes the order of the command bar buttons when in asset page
	 */
	@Input() assetCommandBar: boolean = false;
	@Output() onTableRenderComplete: EventEmitter<any> = new EventEmitter();

	fixedOptions: { [index: string]: any };
	allowTimeRangeSelect: boolean = true;

	repository: Repository<CyberEvent>;
	refreshOn: any;

	visibleFields: Array<string>;
	alerts$: Observable<Array<Alert>>;
	flags: Array<CyberEventMark> = [];
	alertsItems$: Observable<Array<TimebarItem>> = of([]);
	cyberEventEntityType: EntityType<CyberEvent>;
	dataviewFixedOptions: { [index: string]: any };
	customDateRange: DateRangeModel;
	currentTimeBarRange: TimebarRange;
	hotStorageLimitDate: Date;
	loadingText: string;
	stillLoadingText: string;
	exportTooltipText: string;
	selectedAlertId: Alert['id'];
	highlightAlertAction: QuickAction;
	permanentHighlightedItems$: BehaviorSubject<Array<CyberEvent>> = new BehaviorSubject<Array<CyberEvent>>(
		[]
	);

	get showLoadTopItemsButton(): boolean {
		return (
			this.allowLoadItemsOnTableTop && this.currentRange && isCustomTimeRangeValue(this.currentRange)
		);
	}

	private _dataViewId: string;
	get dataViewId(): string {
		if (!this._dataViewId && this.entity) {
			const entityRepo: Repository<TEntity> = this.paris.getRepository(
				<DataEntityType>this.entity.constructor
			);
			this._dataViewId = `${entityRepo.entity.singularName.toLowerCase()}-events`;
		}

		return this._dataViewId;
	}

	private _skipReloadOnLocationChange: boolean;
	private _dataViewConfig: DataViewConfig;
	private _originalDataViewConfig: DataViewConfig;
	private _currentRange: TimeRangeValue;
	private readonly _timeRanges = new Lazy(() => {
		return this.timeRangesService.pick([
			TimeRangeId.day,
			TimeRangeId.week,
			TimeRangeId.month,
			TimeRangeId.custom,
		]);
	});

	private useVerboseTelemetryActionsFilter: boolean = false;
	private markedEventsUpdateSubscription: Subscription;
	private isFullScreen = false;

	@Input()
	set dataViewConfig(value: DataViewConfig) {
		this._originalDataViewConfig = cloneDeep(value);
		this._dataViewConfig = this.getDataViewConfig();
	}

	get dataViewConfig(): DataViewConfig {
		return this._dataViewConfig;
	}

	get currentRange(): TimeRangeValue {
		return this._currentRange;
	}

	set currentRange(range: TimeRangeValue) {
		this._currentRange = range;
		if (!isCustomTimeRangeValue(range))
			this.customDateRange = DateRangeModel.fromDays(
				this.tzDateService,
				range.value,
				undefined,
				undefined,
				false
			);
		this.currentTimeBarRange = {
			to: this.customDateRange.to,
			from: this.customDateRange.from,
		};
	}

	updateDateRangeConfigOnRangeChange() {
		if (this.customRangeConfig) {
			this.customRangeConfig.currentRange = this.currentRange;
			this.customRangeConfig.customDateRange = this.customDateRange;
		}
	}

	get timeRanges() {
		return this._timeRanges.value;
	}

	fields: Array<DataviewField<CyberEvent>>;
	customRangeConfig: DataviewActionCustomRangeConfig;
	commandBarRight: DataviewAction[];

	constructor(
		public readonly paris: Paris,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly preferencesService: PreferencesService,
		private readonly appInsightsService: AppInsightsService,
		public readonly i18nService: I18nService,
		public readonly cyberEventsFieldsService: CyberEventsFieldsService,
		private readonly cyberEventsUtilsService: CyberEventsUtilsService,
		private readonly timeRangesService: TimeRangesService,
		private readonly dialogsService: DialogsService,
		private readonly tzDateService: TzDateService,
		private readonly changeDetectionRef: ChangeDetectorRef,
		private readonly severityTypeColorService: SeverityTypeColorService,
		public readonly featuresService: FeaturesService,
		private readonly activatedEntity: ActivatedEntity,
		private readonly appContext: AppContextService
	) {
		this.cyberEventEntityType = globalEntityTypesService.getEntityType<CyberEvent>(CyberEvent);
		this.onData = this.onData.bind(this);
		this.validateRange = this.validateRange.bind(this);
		this.getAlertsTooltipTemplate = this.getAlertsTooltipTemplate.bind(this);
		this.exportTooltipText = this.i18nService.get('events.export.tooltip');
	}

	ngOnChanges() {
		if (this.customFlags.length) {
			this.flags = [...this.flags, ...this.customFlags];
			this.flags.forEach((flag) => this.updateFlagsInTimebarOnFlagging(flag));
		}
	}

	ngOnDestroy(): void {
		this.markedEventsUpdateSubscription && this.markedEventsUpdateSubscription.unsubscribe();
	}

	private getCustomRangeConfig(): DataviewActionCustomRangeConfig {
		return {
			currentRange: { ...this.currentRange },
			onNewRangeSelectedCallback: this.onNewRangeSelectedCallback.bind(this),
			preferenceId: TIME_RANGE_DEFAULT_PREFERENCE_ID,
			timeRanges: this.timeRanges,
			customDateRange: this.customDateRange,
			dataViewId: this.dataViewId,
			actionType: DataviewActionTypes.CustomRange,
			validateRange: this.validateRange.bind(this),
			dateFormat: 'short',
		};
	}

	/**
	 * Restore the machine id column to be exposed by default.
	 * This is a bug fix for users who were exposed to the upgraded entity pages at their initial versions
	 * where the machine id column was not visible by default and their local storage keeps it hidden for them.
	 * Can be removed 1 month after the old entity pages (file / ip / url) were disabled to all slices
	 */
	resetCustomFields() {
		const entityTimelineFieldsReset = this.preferencesService.getPreference(ENTITY_TIMELINE_FIELDS_RESET);
		const entityTimelineCustomizedColumnsPref = this.preferencesService.getPreference(
			DATAVIEW_FIELDS_PREFERENCE_KEY
		);
		if (
			!entityTimelineFieldsReset &&
			entityTimelineCustomizedColumnsPref &&
			entityTimelineCustomizedColumnsPref.visibleFields &&
			entityTimelineCustomizedColumnsPref.visibleFields.indexOf('machine') < 0
		) {
			this.preferencesService.setPreference(ENTITY_TIMELINE_FIELDS_RESET, true);
			entityTimelineCustomizedColumnsPref.visibleFields.push('machine');
			this.preferencesService.setPreference(
				DATAVIEW_FIELDS_PREFERENCE_KEY,
				entityTimelineCustomizedColumnsPref
			);
		}
	}

	ngOnInit() {
		this.resetCustomFields();

		this.fields = this.shownFields
			? this.cyberEventsFieldsService.fields.filter((field) => this.shownFields.includes(field.id))
			: this.cyberEventsFieldsService.fields;

		if (!this.repository) this.repository = this.paris.getRepository(CyberEvent);

		if (this.entityAlertsRepository) {
			this.entityAlertsRepository.sourceItem = this.entity;
			const alertsData$ = this.entityAlertsRepository.query({
				where: {
					useFileAssociatedAlertsVnextApi: this.featuresService.isEnabled(Feature.AssociatedAlertsByEntity),
					useDetectionAssociatedAlertsVnextApi: this.featuresService.isEnabled(Feature.K8SMigrationAssociatedAlertsVnextOldModel),
				}
			});
			this.alerts$ = this.entityAlertsRepository.query({
					where: {
						useFileAssociatedAlertsVnextApi: this.featuresService.isEnabled(Feature.AssociatedAlertsByEntity),
						useDetectionAssociatedAlertsVnextApi: this.featuresService.isEnabled(Feature.K8SMigrationAssociatedAlertsVnextOldModel),
					}
				}
			).pipe(map((result) => result.items));
			this.alertsItems$ = alertsData$.pipe(
				map((result) =>
					result.items
						.sort((a, b) => b.severity.priority - a.severity.priority)
						.map((alert) => {
							return {
								name: alert.name,
								itemData: alert,
								date: alert.lastEventTime,
								color: this.severityTypeColorService.colorsMap.get(alert.severity.type),
								getTooltipTemplate: this.getAlertsTooltipTemplate,
							};
						})
				)
			);
		}

		if (this.entityFlagsRepository) {
			this.entityFlagsRepository.sourceItem = this.entity;
			this.entityFlagsRepository
				.query()
				.pipe(
					take(1),
					map((result) => result.items)
				)
				.subscribe((flags) => (this.flags = [...flags, ...this.customFlags]));

			this.markedEventsUpdateSubscription = this.cyberEventsUtilsService
				.getMarkEventOperationObservable()
				.subscribe(this.updateFlagsInTimebarOnFlagging);
		} else if (this.customFlags.length) {
			this.flags.concat(this.customFlags);
			this.updateFlagsInTimebarOnFlagging(this.customFlags[0]);
		}

		this.currentRange =
			this.timeRanges.find(
				(timeRange) =>
					timeRange.id === this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID)
			) || this.timeRanges.find((range) => range.id === TimeRangeId.week);

		this.hotStorageLimitDate = new Date();
		this.hotStorageLimitDate.setDate(this.hotStorageLimitDate.getDate() - EVENTS_HOT_STORAGE_IN_DAYS);

		this.route.queryParams.subscribe((params: Params) => {
			const locationFixedRange = params.range,
				locationCustomRange = params.from || params.to;
			let locationRangeChange: boolean = false;

			this.setSelectedAlert(params.alert);

			if (locationFixedRange) {
				const foundFixedRange: TimeRangeValue = find(
					this.timeRanges,
					(range) => range.id === locationFixedRange
				);

				locationRangeChange = foundFixedRange !== this.currentRange;
				if (locationRangeChange && foundFixedRange) {
					this.currentRange = foundFixedRange;
					this.updateDateRangeConfigOnRangeChange();
				}
			}

			if (locationCustomRange) {
				const customRangeType = this.timeRanges.find(isCustomTimeRangeValue);

				locationRangeChange =
					customRangeType !== this.currentRange ||
					(this.customDateRange &&
						(this.customDateRange.from !== params.from || this.customDateRange.to !== params.to));
				if (locationRangeChange && customRangeType) {
					this.customDateRange = new DateRangeModel(
						this.tzDateService,
						new Date(params.from),
						new Date(params.to),
						false
					);
					this.currentRange = customRangeType;
					this.updateDateRangeConfigOnRangeChange();
				}
			}

			if (locationRangeChange && !this._skipReloadOnLocationChange) {
				this.setFixedOptions();
				this.dataViewConfig = this.getDataViewConfig();
			}
			this._skipReloadOnLocationChange = false;
		});

		this.setFixedOptions();
		this.dataViewConfig = this.getDataViewConfig();

		this.customRangeConfig = this.getCustomRangeConfig();
		this.commandBarRight = this.getFullScreenAction().concat([this.customRangeConfig]);
		this.enableTimebarExpander =
			this.enableTimebarExpander && this.featuresService.isEnabled(Feature.TimelineFullScreenToggling);
	}

	private updateFlagsInTimebarOnFlagging = (newFlagOperation: CyberEventMark): void => {
		let flagsUpdated = false;
		const indexOfExistingFlag = findIndex(
			this.flags,
			(flag: CyberEventMark) =>
				flag.actionTime.getTime() === newFlagOperation.actionTime.getTime() &&
				(flag.reportId == newFlagOperation.reportId || flag.alertId == newFlagOperation.alertId)
		);

		if (newFlagOperation.isFlagged && indexOfExistingFlag == -1) {
			// adding missing flag symbol to timebar
			this.flags.push(newFlagOperation);
			flagsUpdated = true;
		}

		if (!newFlagOperation.isFlagged && indexOfExistingFlag != -1) {
			// user unmarked event - remove from timebar
			this.flags.splice(indexOfExistingFlag, 1);
			flagsUpdated = true;
		}

		if (flagsUpdated) {
			// reassign flags to allow update with onPush change detection strategy
			this.flags = [...this.flags];
			this.changeDetectionRef.markForCheck();
		}
	};

	onData(dataSet: DataSet<CyberEvent>) {
		const highlightedItems = this.selectedAlertId
			? dataSet.items.filter(
				(event: CyberEvent) =>
					(event.relatedAlert && event.relatedAlert.id === this.selectedAlertId) ||
					(event.alertIds && event.alertIds.some((id) => id === this.selectedAlertId))
			)
			: [];

		if (this.permanentHighlightedItems$.value !== highlightedItems) {
			this.permanentHighlightedItems$.next(highlightedItems);
			this.changeDetectionRef.markForCheck();
		}

		const supportedActionTypes = dataSet.items
			.filter((event: CyberEvent) => event.actionType)
			.reduce((set, event: CyberEvent) => {
				const groupKey = event.isCyberData ? event.actionType.typeName + event.cyberActionType.typeName : event.actionType.typeName;
				set[groupKey] = set.hasOwnProperty(groupKey) ? set[groupKey] + 1 : 1;
				return set;
			}, {});

		const unsupportedActionTypes = dataSet.items
			.filter((event: CyberEvent) => !event.actionType)
			.reduce((set, event: CyberEvent) => {
				const groupKey = event.rawActionType;
				set[groupKey] = set.hasOwnProperty(groupKey) ? set[groupKey] + 1 : 1;
				return set;
			}, {});

		if (supportedActionTypes || unsupportedActionTypes) {
			this.appInsightsService.trackEvent(`Machine timeline - action types`, {
				events: JSON.stringify({unsupportedActionTypes, supportedActionTypes}),
			});
		}
	}

	onItemsAddedOnTop(dataChange: DataSet<CyberEvent>) {
		if (dataChange.count) {
			const lastItem = dataChange.items[0];
			const newDateRange = new DateRangeModel(
				this.tzDateService,
				this.customDateRange.from,
				lastItem.actionTime,
				false
			);
			newDateRange.from = this.validateFromDateRangeLimit(newDateRange.from, newDateRange.to);

			const newRange = this.timeRanges.find(isCustomTimeRangeValue);
			newRange.customRange = this.customDateRange = newDateRange;
			this.currentRange = newRange;
			this._skipReloadOnLocationChange = true;

			this.refreshQueryParams({
				from: this.currentRange.customRange.from.toISOString(),
				to: this.currentRange.customRange.to.toISOString(),
			});
		}
	}

	onFiltersChange = (filterChange: FiltersState) => {
		const newUseVerboseTelemetryActionsFilterValue = !!(
			filterChange.selection && filterChange.selection.verboseTelemetryActions
		);
		if (newUseVerboseTelemetryActionsFilterValue !== this.useVerboseTelemetryActionsFilter) {
			this.useVerboseTelemetryActionsFilter = newUseVerboseTelemetryActionsFilterValue;
			this.setLoadingText();
		}
	};

	private onNewRangeSelectedCallback(currentRange: TimeRangeValue, customDateRange?: DateRangeModel) {
		this.refreshQueryParams({
			page: null,
			range: currentRange && currentRange.id,
			from: customDateRange && customDateRange.from.toISOString(),
			to: customDateRange && customDateRange.to.toISOString(),
		});

		this.setFixedOptions();
	}

	onTimebarSelect(timeRange: TimebarRange) {
		this.appInsightsService.trackEvent('UsageTrack', {
			ButtonType: 'TimebarPickerRangeSelect',
			Component: this.dataViewId,
		});

		this.refreshQueryParams({
			page: null,
			range: null,
			from: timeRange.from.toISOString(),
			to: timeRange.to.toISOString(),
		});
	}

	onAlertSelected(alert: Alert) {
		this.appInsightsService.trackEvent('UsageTrack', {
			ButtonType: 'TimebarPickerAlertSelect',
			Component: this.dataViewId,
		});
		// adding extra millisec to the to time to guarantee that tha alert event won't be sliced in the BE.
		const toDate = alert.lastEventTime;
		toDate.setMilliseconds(alert.lastEventTime.getMilliseconds() + 1);
		this.scrollToTime({
			to: toDate,
			alertId: alert.id,
		});
	}

	onFlagSelected(flag: CyberEventMark) {
		this.appInsightsService.trackEvent('TimebarPickerFlagSelect', {
			ButtonType: 'TimebarPickerFlagSelect',
			Component: this.dataViewId,
			SenseMachineId: flag.deviceId,
			ActionType: flag.actionType,
			ActionTimeOfFlag: flag.actionTimeIsoString,
		});
		// adding extra millisec to the to time to guarantee that tha flag event won't be sliced in the BE.
		const toDate = flag.actionTime;
		toDate.setMilliseconds(flag.actionTime.getMilliseconds() + 1);
		this.scrollToTime({
			to: toDate,
		});
	}

	onQuickAction(action: QuickAction): void {
		if (action.id === HIGHLIGHT_ALERT_ACTION_ID) this.unHighlightAlert();
	}

	unHighlightAlert() {
		this.setSelectedAlert(null);
		this.permanentHighlightedItems$.next([]);
	}

	private scrollToTime(timeOptions: { to: Date; alertId?: string }) {
		const from = new Date(timeOptions.to);
		from.setDate(from.getDate() - DEFAULT_ALERT_RANGE_IN_DAYS);

		this.refreshQueryParams({
			page: null,
			range: null,
			from: from.toISOString(),
			to: timeOptions.to.toISOString(),
			alert: timeOptions.alertId,
		});
	}

	getTopItem(items: Array<TimebarItem>): TimebarItem {
		return minBy(items, (item) => (<Alert>item.itemData).severity.priority);
	}

	getAlertsTooltipTemplate(alerts: Array<Alert>): string {
		let template = alerts
			.map(
				(alert) => `
					<div class="subtle wcd-margin-xsmall-bottom">${this.tzDateService.format(
						alert.lastEventTime,
						FULL_DATE_WITH_MILLISECONDS_FORMAT
					)}</div>
					<div class="wcd-margin-bottom">
						<span class="wcd-severity wcd-severity-${alert.severity.type}">${this.i18nService.get(
					alert.severity.nameI18nKey
				)}
						</span>
						<span class="wcd-margin-left wcd-font-weight-bold">${alert.name}</span>
					</div>
				`
			)
			.join('');
		template += `<div>${this.i18nService.get('events.clickOnAlert')}</div>`;
		return template;
	}

	validateRange(range: TimebarRange): TimebarRange | null {
		const momentTo = moment(range.to),
			momentFrom = moment(range.from),
			diffInDays = moment.duration(momentTo.diff(momentFrom)).asDays(),
			rangeIsInColdStorage = range.from < this.hotStorageLimitDate;
		let updatedTo: Date;

		const now = new Date();
		if (momentTo.valueOf() > now.valueOf()) {
			updatedTo = new Date(now);
		}

		if (!updatedTo && (!rangeIsInColdStorage || diffInDays <= COLD_STORAGE_MAX_RANGE_IN_DAYS)) {
			return null;
		} else {
			return {
				from: moment
					.min(
						moment(this.hotStorageLimitDate),
						moment(updatedTo || momentTo).subtract(COLD_STORAGE_MAX_RANGE_IN_DAYS, 'days')
					)
					.toDate(),
				to: updatedTo || momentTo.toDate(),
			};
		}
	}

	private getTimelineDateRange = (): EntityEventsTimelineDateRange => {
		let from: Date, to: Date;
		if (isCustomTimeRangeValue(this.currentRange)) {
			to = this.customDateRange.to;
			from = this.customDateRange.from;
		} else {
			to = new Date();
			from = moment(to)
				.subtract(+this.currentRange.value, 'days')
				.toDate();
		}

		const fromValidatedDate = this.validateFromDateRangeLimit(from, to);

		return { from: fromValidatedDate, to };
	};

	private setFixedOptions() {
		// Change correlationId after each events timeline configuration change (i.e: filter, search, date picker, etc...)
		CyberEventsUtilsService.correlationId$.next(uuid4());
		const timelineDateRange = this.getTimelineDateRange();
		this.setLoadingText({ timelineDateRange });

		this.dataviewFixedOptions = Object.assign(this.getTarget(timelineDateRange), this.fixedOptions);
	}

	private getTarget(timelineDateRange: EntityEventsTimelineDateRange) {
		return {
			...this.cyberEventsQueryFixedOptions,
			fromDate: timelineDateRange.from.toISOString(),
			toDate: timelineDateRange.to.toISOString(),
			pageSize: EVENTS_PAGE_SIZE,
			...CyberEventsUtilsService.getTimelineFlagParams(this.featuresService, this.appContext),
		};
	}

	private setLoadingText = (options?: EntityEventsLoadingTextOptions) => {
		if (this.useVerboseTelemetryActionsFilter) {
			this.loadingText = this.i18nService.get('events.loadingVerboseData');
			return;
		}

		let timelineDateRange = options && options.timelineDateRange;
		if (!timelineDateRange) {
			timelineDateRange = this.getTimelineDateRange();
		}

		this.loadingText = this.i18nService.get(
			`events.loading${timelineDateRange.from < this.hotStorageLimitDate ? 'OnColdStorage' : ''}`
		);
		this.stillLoadingText = this.i18nService.get(
			`events.loading${timelineDateRange.from < this.hotStorageLimitDate ? 'OnColdStorage' : 'Still'}`
		);
	};

	private getDataViewConfig(): DataViewConfig {
		if (this._originalDataViewConfig) {
			const dataViewConfig: DataViewConfig = cloneDeep(this._originalDataViewConfig);
			return dataViewConfig;
		} else {
			this._originalDataViewConfig = {
				requireFiltersData: false,
				showBusyOnExport: this.showBusyOnExport,
				supportPartialResponse: this.supportPartialResponse,
				showModalOnExport: false,
				exportResults: async (options) => {
					// When dataview triggers "export" action, he may not be aware to date range changes that happen, so we update them here.
					let updatedOptions = Object.assign({}, options, options['isPartialExport'] || {
						fromDate: this.customDateRange.from.toISOString(),
						toDate: this.customDateRange.to.toISOString(),
					});
					const momentTo = moment(updatedOptions.toDate);
					let confirmModalResult: ConfirmEvent;

					// With flagged events filter, date range to export data is allowed up to the full hot storage date range.
					const isMarkedEventsFilterOn =
						options.markedEventsOnly && options.markedEventsOnly[0] === 'true';
					const lastHotStorageDate = new Date();
					lastHotStorageDate.setDate(
						lastHotStorageDate.getDate() - (EVENTS_HOT_STORAGE_IN_DAYS + 1)
					);
					const isDateRangeInHotStorage =
						this.customDateRange.from.getTime() >= lastHotStorageDate.getTime();
					const maxRangeToExport =
						isMarkedEventsFilterOn && isDateRangeInHotStorage
							? EVENTS_HOT_STORAGE_IN_DAYS
							: EXPORT_MAX_RANGE_IN_DAYS;

					if (momentTo.diff(moment(updatedOptions.fromDate), 'days') > maxRangeToExport) {
						const newFromDate = momentTo.subtract(maxRangeToExport, 'days').toISOString();
						updatedOptions = Object.assign({}, updatedOptions, { fromDate: newFromDate });

						confirmModalResult = await this.dialogsService.confirm({
							title: this.i18nService.get('events.export.confirm.title'),
							text: this.i18nService.get('events.export.confirm.limitDescription', {
								from: this.tzDateService.format(updatedOptions.fromDate, 'medium'),
								to: this.tzDateService.format(updatedOptions.toDate, 'medium'),
							}),
							confirmText: this.i18nService.get('events.export.confirm.limitExecute'),
						});
					}
					if (!confirmModalResult || confirmModalResult.confirmed) {
						return this.cyberEventsUtilsService.downloadCsv({
							...omit(updatedOptions, ['page', 'page_size', 'pageSize']),
						});
					}
					return Promise.resolve();
				},
			};
			return this._originalDataViewConfig;
		}
	}

	// returns "from" date after fixing
	private validateFromDateRangeLimit(from: Date, to: Date): Date {
		const momentTo = moment(to),
			momentFrom = moment(from),
			diffInDays = moment.duration(momentTo.diff(momentFrom)).asDays();

		if (diffInDays > MAX_RANGE_IN_DAYS) {
			// force max days range and fix DST if needed
			return this.checkAndFixDSTOverTheRange(from, to, MAX_RANGE_IN_DAYS);
		} else if (from < this.hotStorageLimitDate && diffInDays > COLD_STORAGE_MAX_RANGE_IN_DAYS) {
			// force max days range on coldStorage and fix DST if needed
			return this.checkAndFixDSTOverTheRange(from, to, COLD_STORAGE_MAX_RANGE_IN_DAYS);
		} else {
			return momentFrom.toDate();
		}
	}

	// Forces "from" date to be less than a given range, while fixing Daylight Savings Time leap
	private checkAndFixDSTOverTheRange(from: Date, to: Date, maxDaysAllowed: number): Date {
		// force max days range on coldStorage
		let newFrom = moment(to).subtract(maxDaysAllowed, 'days').toDate();

		// fix Daylight savings time differences: add 1 hour if there was a DST change in the range
		if (moment(newFrom).isDST() !== moment(to).isDST()) {
			newFrom = moment(newFrom).add(1, 'hours').toDate();
		}

		return newFrom;
	}

	private setSelectedAlert(alertId?: string) {
		if (alertId) {
			this.selectedAlertId = alertId;
			this.highlightAlertAction = null;
			this.paris.getItemById(Alert, alertId).subscribe(
				(alert) => {
					this.setHighlightAlertAction(null, alert);
				},
				(error) => {
					this.setHighlightAlertAction(null);
				}
			);
		} else {
			this.selectedAlertId = null;
			this.setHighlightAlertAction(null);
		}
	}

	private setHighlightAlertAction(alertId?: string, alert?: Alert) {
		if (alertId || alert) {
			this.highlightAlertAction = {
				id: HIGHLIGHT_ALERT_ACTION_ID,
				itemText: this.i18nService.get('machines.highlightedAlert.title', {
					alertName: (alert && alert.name) || alert.id,
				}),
				tooltipText: this.i18nService.get('machines.highlightedAlert.tooltip', {
					alertName: (alert && alert.name) || alert.id,
				}),
			};
			this.changeDetectionRef.markForCheck();
		} else {
			if (this.highlightAlertAction) {
				this.highlightAlertAction = null;
				this.refreshQueryParams({
					alert: null,
				});
			}
		}
	}

	private refreshQueryParams(params: { [index: string]: string }) {
		this.router.navigate(['.'], {
			relativeTo: this.route,
			queryParams: params,
			queryParamsHandling: 'merge',
		});
	}

	onTableRenderCompleteLoaded() {
		this.onTableRenderComplete.emit();
	}

	private getFullScreenAction(): DataviewAction[] {
		const getFullScreenButtonText = () =>
			this.i18nService.get(
				this.isFullScreen ? 'entities.fullScreen.collapse' : 'entities.fullScreen.expand'
			);
		return this.toggleFullScreenEnabled &&
			this.featuresService.isEnabled(Feature.TimelineFullScreenToggling)
			? [
					{
						localizedTooltipFn: getFullScreenButtonText,
						onClickCallback: () => {
							this.isFullScreen = !this.isFullScreen;
							this.activatedEntity.fullScreenEntity$.next(this.isFullScreen);
						},
						iconFn: () =>
							this.isFullScreen ? FabricIconNames.BackToWindow : FabricIconNames.FullScreen,
						localizedLabelFn: getFullScreenButtonText,
						actionType: DataviewActionTypes.Button,
						dataTrackId: 'toggle-full-screen-mode',
						dataTrackType: 'Button',
					},
			  ]
			: [];
	}
}
