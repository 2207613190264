import { Injectable, ComponentFactoryResolver } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { AskTheExpertPanelComponent } from '../components/ask-threat-expert.panel.component';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { AskThreatExpertModalComponent } from '../components/ask-threat-expert.modal.component';
import { DimensionsModel } from '../../dialogs/models/dimensions.model';
import { ItemActionModelConfig, ItemActionType } from '../../dataviews/models/item-action.model';
import { FabricIconNames } from '@wcd/scc-common';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { AppContextService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class AskThreatExpertService {
	constructor(
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private featuresService: FeaturesService,
		private appContextService: AppContextService,
		private flavorService: FlavorService
	) {}

	showResearcherAssistanceDialog() {
		if (this.featuresService.isEnabled(Feature.BilbaoExpertsOnDemand2)) {
			this.dialogsService
				.showPanel(
					AskTheExpertPanelComponent,
					{
						id: 'ask-threat-expert',
						type: PanelType.large,
						persistOnNavigate: false,
						disableOverlayClick: true,
						showOverlay: true,
					},
					this.componentFactoryResolver
				)
				.subscribe();
		} else {
			this.dialogsService
				.showModal(AskThreatExpertModalComponent, {
					id: 'researcherAssistance',
					title: this.i18nService.get('researcherAssistance.title'),
					dimensions: new DimensionsModel(800, 0),
					enableOverlay: false,
				})
				.subscribe();
		}
	}

	shouldShowThreatExpertPanel() {
		return (
			this.featuresService.isEnabled(Feature.BilbaoExpertsOnDemand2) &&
			this.flavorService.isEnabled(AppFlavorConfig.threatExpert.mde) &&
			this.appContextService.isMdatpActive
		);
	}

	getAskTheExpertCommandConfig(trackingComponent: string): ItemActionModelConfig {
		return {
			id: 'consultThreatExpert',
			nameKey: 'threatExpert.commandBarTitle',
			type: ItemActionType.Button,
			icon: FabricIconNames.Help,
			tracking: {
				id: 'consultThreatExpertCommandBar',
				type: TrackingEventType.Action,
				component: trackingComponent,
			},
			refreshOnResolve: false,
			method: () => {
				sccHostService.log.trackEvent('Improved file page - Ask threat expert');
				return Promise.resolve(this.showResearcherAssistanceDialog());
			},
		};
	}
}
