import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { MachinesService } from '../../machines/services/machines.service';
import { of, combineLatest, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isEmpty, differenceWith, uniq } from 'lodash-es';
import { Paris } from '@microsoft/paris';
import { HuntingRuleCustomSupportedActionType, MachineGroup, MtpWorkload } from '@wcd/domain';
import { AuthService, MtpPermission } from '@wcd/auth';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
var HuntingRbacService = /** @class */ (function () {
    function HuntingRbacService(appConfigService, featuresService, machinesService, authService, rbacControlService, paris) {
        this.appConfigService = appConfigService;
        this.featuresService = featuresService;
        this.machinesService = machinesService;
        this.authService = authService;
        this.rbacControlService = rbacControlService;
        this.machineGroupsRepo = paris.getRepository(MachineGroup);
    }
    HuntingRbacService.prototype.isUserExposedToHuntingRulesScope = function (huntingRules) {
        var mdatpHuntingRules = huntingRules.filter(function (rule) { return rule.mtpWorkloads && rule.mtpWorkloads.includes(MtpWorkload.Mdatp); });
        if (mdatpHuntingRules.length === 0 ||
            !this.appConfigService.hasMachineGroups ||
            this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.admin])) {
            return of(true);
        }
        var userExposedGroups$ = this.machinesService.getFullUserExposedMachineGroups().pipe(take(1));
        var allMachineGroups$ = this.machineGroupsRepo.query().pipe(map(function (dataSet) { return dataSet.items; }));
        var isExposedToHuntingRule = function (rule) {
            return zip(userExposedGroups$, allMachineGroups$).pipe(map(function (_a) {
                var userExposedGroups = _a[0], allMachineGroups = _a[1];
                var huntingRuleGroups = isEmpty(rule.machineGroups)
                    ? allMachineGroups
                    : rule.machineGroups;
                return isEmpty(differenceWith(huntingRuleGroups, userExposedGroups, function (a, b) { return a.id === b.id; }));
            }));
        };
        return combineLatest(mdatpHuntingRules.map(isExposedToHuntingRule)).pipe(map(function (isExposedToRule) { return isExposedToRule.reduce(function (prev, current) { return prev && current; }, true); }));
    };
    HuntingRbacService.prototype.isUserAllowedToEdit = function (rule) {
        var _this = this;
        if (this.featuresService.isEnabled(Feature.MtpCustomRolesSupport)) {
            if (rule.mtpWorkloads && rule.mtpWorkloads.length) {
                // user must have permission for all products participating in the rule
                if (rule.mtpWorkloads.some(function (product) {
                    return !_this.authService.currentUser.hasRequiredMtpPermissionInWorkload(MtpPermission.SecurityConfig_Manage, product);
                })) {
                    return false;
                }
            }
            else {
                // anyway, the user must have permission on at least one product
                if (!this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityConfig_Manage)) {
                    return false;
                }
            }
            if (rule.actions && rule.actions.length > 0) {
                // the user must have remediate permission for all actions
                var actionWorkloads = uniq(rule.actions.map(function (_a) {
                    var actionType = _a.actionType;
                    return _this.getActionMtpWorkload(actionType);
                }));
                if (actionWorkloads.some(function (workload) {
                    return !_this.authService.currentUser.hasRequiredMtpPermissionInWorkload(MtpPermission.SecurityData_Remediate, workload);
                })) {
                    return false;
                }
            }
            return true;
        }
        // hunting MTP RBAC is not enabled - require security settings role, as before
        return this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings]);
    };
    HuntingRbacService.prototype.isUserAllowedToCreateNewRule = function () {
        // to create a new rule, user must have permission to at least one product
        // more RBAC checks may be applied by the BE for a particular query string
        return this.featuresService.isEnabled(Feature.MtpCustomRolesSupport)
            ? this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityConfig_Manage)
            : this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings]);
    };
    HuntingRbacService.prototype.getActionMtpWorkload = function (actionType) {
        var exhaustiveCheck;
        switch (actionType) {
            case HuntingRuleCustomSupportedActionType.IsolateMachine:
            case HuntingRuleCustomSupportedActionType.CollectInvestigationPackage:
            case HuntingRuleCustomSupportedActionType.RunAntivirusScan:
            case HuntingRuleCustomSupportedActionType.InitiateInvestigation:
            case HuntingRuleCustomSupportedActionType.QuarantineFile:
            case HuntingRuleCustomSupportedActionType.AllowFile:
            case HuntingRuleCustomSupportedActionType.BlockFile:
            case HuntingRuleCustomSupportedActionType.RestrictExecution:
                return MtpWorkload.Mdatp;
            case HuntingRuleCustomSupportedActionType.MarkUserAsCompromised:
                return MtpWorkload.AadIp;
            case HuntingRuleCustomSupportedActionType.MoveEmailToFolder:
            case HuntingRuleCustomSupportedActionType.DeleteEmail:
            case HuntingRuleCustomSupportedActionType.BlockEmailSender:
            case HuntingRuleCustomSupportedActionType.BlockEmailUrl:
            case HuntingRuleCustomSupportedActionType.StartNewSubmission:
            case HuntingRuleCustomSupportedActionType.TriggerInvestigation:
                return MtpWorkload.Oatp;
            case HuntingRuleCustomSupportedActionType.DisableUser:
            case HuntingRuleCustomSupportedActionType.ForceUserPasswordReset:
                return MtpWorkload.AadIp; // TODO: change to ITP (MCAS) or Mdi once they support the remediate role
            default:
                exhaustiveCheck = actionType; // generate compilation error if the switch is not exhaustive
                throw new Error("Hunting action type " + exhaustiveCheck + " is not mapped to a workload. Please add it to the mapping.");
        }
    };
    return HuntingRbacService;
}());
export { HuntingRbacService };
