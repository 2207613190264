import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash-es';
var ConfigurationsByCategoryFieldsService = /** @class */ (function () {
    function ConfigurationsByCategoryFieldsService(i18nService) {
        this.i18nService = i18nService;
        this.categoryIdPrefix = "category-";
        this.FIELDS_DATA = this.getAllFieldsData();
    }
    Object.defineProperty(ConfigurationsByCategoryFieldsService.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                var fieldsData = this.FIELDS_DATA;
                this._fields = DataviewField.fromList(compact([
                    fieldsData.expand,
                    fieldsData.name,
                    fieldsData.complianceLevel,
                ]));
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    ConfigurationsByCategoryFieldsService.prototype.getAllFieldsData = function () {
        var _this = this;
        return {
            expand: {
                id: 'configurationByCategoryExpand',
                name: '',
                enabledByDefault: true,
                alwaysDisplay: true,
                allowResize: false,
                getFieldCssClass: function (item) { return item.id.startsWith(_this.categoryIdPrefix) ? 'datatable-expand' : ''; },
                sort: { enabled: false },
                isTabbale: true,
            },
            name: {
                id: 'configurationByCategoryName',
                name: this.i18nService.strings.baseline_profile_fields_name_title,
                getFieldCssClass: function () { return "datatable-group-item-indent-left title-field"; },
                getDisplay: function (item) {
                    return item.id.startsWith(_this.categoryIdPrefix) ? item.category + " (" + item.count + ")" : item.title;
                },
                getTooltip: function (item) {
                    return item.id.startsWith(_this.categoryIdPrefix) ? "" : item.title;
                },
                sort: { enabled: false },
                alwaysDisplay: true,
                enabledByDefault: true
            },
            complianceLevel: {
                id: 'configurationByCategoryComplianceLevel',
                name: this.i18nService.strings.baseline_profile_fields_level_title,
                getDisplay: function (item) {
                    if (item.id.startsWith(_this.categoryIdPrefix))
                        return "";
                    return item.complianceLevels.length == 1 ? item.complianceLevels[0] :
                        item.complianceLevels[0] + " +(" + (item.complianceLevels.length - 1) + " more)";
                },
                getTooltip: function (item) {
                    if (item.id.startsWith(_this.categoryIdPrefix) || item.complianceLevels.length == 1)
                        return "";
                    var tooltip = "<ul>";
                    tooltip += item.complianceLevels.map(function (level) { return "<li>" + level + "</li>"; }).join("\n");
                    tooltip += "</ul>";
                    return tooltip;
                },
                valueTooltipAllowHtmlRendering: true,
                sort: { enabled: false },
                alwaysDisplay: true,
                enabledByDefault: true
            }
        };
    };
    return ConfigurationsByCategoryFieldsService;
}());
export { ConfigurationsByCategoryFieldsService };
