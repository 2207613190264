import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { FILE_TEMPLATE } from '../actioncenter/seville.actioncenter.file';

SevilleModule.controller('seville.threatintel.file.quarantine', quarantineController);

quarantineController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$uibModalInstance',
	'appConfig',
	'file',
	'$uibModal',
	'hoverPaneService',
	'$interval',
	'urlMapping',
	'$httpParamSerializer',
	'$window',
];

function quarantineController(
	$log,
	$scope,
	$state,
	$http,
	$uibModalInstance,
	appConfig,
	file,
	$modal,
	hoverPaneService,
	$interval,
	urlMapping,
	$httpParamSerializer,
	$window
) {
	$scope.file = file;
	var vm = $scope;

	vm.quarantine = function() {
		if (vm.posting) return;

		if (!vm.file.commentText || vm.file.commentText == '') return;

		vm.posting = true;

		$http
			.post(appConfig.serviceUrls.userRequests + '/remediation/remediate', {
				FileIdentifierType: 'Sha1',
				FileIdentifier: file.Sha1,
				RequestorComment: vm.file.commentText,
			})
			.then(function(response) {
				if (response.status == 200) {
					file.ResponseActionExists = true;
					openActionCenter();

					// change quarantine status to RequestPending for 5 minutes
					$log.debug('change quarantine status to RequestPending for 5 minutes');
					file.QuarantineStatus = 'RequestPending';
					$interval(updateQuarantineStatus, 5 * 60000, 1);
				} else {
					$modal.open({
						animation: $scope.animationsEnabled,
						template: ERROR_POPUP_TEMPLATE,
						size: 'md',
						windowClass: 'machine-actions-modal',
						backdrop: false,
					});
				}

				vm.file.commentText = '';
				vm.posting = false;
				$uibModalInstance.dismiss();
			});
	};

	vm.close = function() {
		vm.file.commentText = '';
		$uibModalInstance.dismiss();
	};

	vm.downloadMachineListCsv = function() {
		var url = urlMapping.getThreatIntelUrl() + '/ExportFileObservedMachinesCsv';
		$http.get(url).then(
			function(response) {
				if (response.status == 200) {
					var data = response.data;
					var params = {
						ctx: data.ctx,
						token: data.token,
						sha1: vm.file.Sha1,
					};

					var downloadUrl =
						urlMapping.getThreatIntelUrl() +
						'/DownloadFileObservedMachinesCsv?' +
						$httpParamSerializer(params);

					$window.open(downloadUrl, '_blank');
				} else {
					$log.error(
						response.status +
							' - Error occur while tried to download machines list where the file was observed.'
					);
				}
			},
			function(response) {
				$log.error(
					response.status +
						' - Error occur while tried to download machines list where the file was observed.'
				);
			}
		);
	};

	function updateQuarantineStatus() {
		$log.debug('change quarantine status to available');
		file.QuarantineStatus = 'Available';
	}

	function openActionCenter() {
		hoverPaneService.open({
			template: FILE_TEMPLATE,
			controller: 'seville.actioncenter.file',
			controllerAs: 'actioncenterfile',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return file;
				},
			},
		});
	}
}
