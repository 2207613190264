import { OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { DateRangeModel, TzDateService } from '@wcd/localization';
import { find, merge } from 'lodash-es';
import { Investigation } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PreferencesService } from '@wcd/config';
import { isCustomTimeRangeValue, } from '@wcd/date-time-picker';
import { DownloadService } from '../../../shared/services/download.service';
import { InvestigationsFields } from '../services/investigation.fields';
import { InvestigationsService } from '../services/investigations.service';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
var DISABLED_FILTER_IDS = ['start_date'];
var TIME_RANGE_DEFAULT_PREFERENCE_ID = 'investigations_time_range_default';
var InvestigationsDataviewComponent = /** @class */ (function () {
    function InvestigationsDataviewComponent(preferencesService, investigationFields, investigationsService, authService, route, router, downloadService, globalEntityTypesService, entityPanelsService, appConfigService, i18nService, timeRangesService, paris, tzDateService) {
        var _this = this;
        this.preferencesService = preferencesService;
        this.investigationFields = investigationFields;
        this.authService = authService;
        this.route = route;
        this.router = router;
        this.downloadService = downloadService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.entityPanelsService = entityPanelsService;
        this.appConfigService = appConfigService;
        this.i18nService = i18nService;
        this.timeRangesService = timeRangesService;
        this.tzDateService = tzDateService;
        this.isCustomTimeRangeValue = isCustomTimeRangeValue;
        this.disabledFilters = DISABLED_FILTER_IDS;
        this.entityType = this.globalEntityTypesService.getEntityType(Investigation);
        this.timeRanges = this.timeRangesService
            .pick(["day" /* day */, "week" /* week */, "month" /* month */])
            .concat(this.appConfigService.isDemoTenant
            ? [
                {
                    id: '6months',
                    value: 180,
                    name: this.i18nService.get('datetime_month_plural_template', { amount: 6 }),
                    isCustom: true,
                    customRange: DateRangeModel.fromDays(this.tzDateService, 180),
                },
                {
                    id: '1year',
                    value: 365,
                    name: this.i18nService.get('datetime_year_singular_template', { amount: 1 }),
                    isCustom: true,
                    customRange: DateRangeModel.fromDays(this.tzDateService, 365),
                },
            ]
            : [])
            .concat(this.timeRangesService.pick(["custom" /* custom */]));
        this.dataviewConfig = {
            exportResults: function (options) {
                return _this.downloadService.downloadFromUrl(_this.investigationsRepo.getEndpointUrl() + "/all.csv", { params: options });
            },
        };
        this.investigationsRepo = paris.getRepository(Investigation);
        this.setInvestigationActions = investigationsService.getInvestigationActions.bind(investigationsService);
        var currentRange = this.timeRanges.find(function (timeRange) {
            return timeRange.id ===
                (_this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID) ||
                    (_this.appConfigService.isDemoTenant && '1year'));
        }) || this.timeRanges[1];
        var locationRange = route.snapshot.queryParams.range;
        var locationCustomRangeMatch = locationRange && locationRange.match(/^(\d+)?:(\d+)?$/);
        if (locationRange) {
            var foundRange = find(this.timeRanges, function (range) {
                return (range.id === locationRange ||
                    (!isCustomTimeRangeValue(range) && range.value === Number(locationRange)) ||
                    (locationCustomRangeMatch && isCustomTimeRangeValue(range)));
            });
            if (foundRange) {
                currentRange = foundRange;
                if (isCustomTimeRangeValue(currentRange) && locationCustomRangeMatch)
                    this.customDateRange = new DateRangeModel(this.tzDateService, locationCustomRangeMatch[1] && new Date(parseInt(locationCustomRangeMatch[1])), locationCustomRangeMatch[2] && new Date(parseInt(locationCustomRangeMatch[2])));
            }
        }
        this.currentRange = currentRange;
        this.setFixedOptions();
    }
    Object.defineProperty(InvestigationsDataviewComponent.prototype, "fixedOptions", {
        get: function () {
            return this._fixedOptions;
        },
        set: function (fixedOptions) {
            this._inputFixedOptions = fixedOptions;
            this.setFixedOptions();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigationsDataviewComponent.prototype, "currentRange", {
        get: function () {
            return this._currentRange;
        },
        set: function (range) {
            this._currentRange = range;
            if (!isCustomTimeRangeValue(range)) {
                this.customDateRange = DateRangeModel.fromDays(this.tzDateService, range.value);
            }
            if (range.customRange) {
                this.customDateRange = range.customRange;
            }
        },
        enumerable: true,
        configurable: true
    });
    InvestigationsDataviewComponent.prototype.ngOnChanges = function (changes) {
        if (changes.disabledFields)
            this.disabledFilters = DISABLED_FILTER_IDS.concat(this.disabledFields);
    };
    InvestigationsDataviewComponent.prototype.onRangeTypeSelect = function (newRange) {
        var oldCustomRange = this.customDateRange;
        this.currentRange = newRange;
        if (!isCustomTimeRangeValue(this.currentRange) || this.currentRange.customRange)
            this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentRange.id);
        if (this.customDateRange.toString() !== oldCustomRange.toString())
            this.onRangeSelect();
    };
    InvestigationsDataviewComponent.prototype.onRangeSelect = function () {
        var paramRange = isCustomTimeRangeValue(this.currentRange) && !this.currentRange.customRange
            ? this.customDateRange.toString()
            : this.currentRange.id;
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: null,
                range: paramRange,
            },
            queryParamsHandling: 'merge',
        });
        this.setFixedOptions();
    };
    InvestigationsDataviewComponent.prototype.setFixedOptions = function () {
        var timeRange = isCustomTimeRangeValue(this.currentRange)
            ? this.customDateRange.toString()
            : this.currentRange.id;
        this._fixedOptions = merge({
            start_date: [timeRange],
        }, this._inputFixedOptions);
    };
    return InvestigationsDataviewComponent;
}());
export { InvestigationsDataviewComponent };
