import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { UserRoleAssignment } from './user-role-assignment.value-object';
import { MdeUserRoleAction } from './user-role-action.entity';
import { userRoleActionValues, deprecatedUserRoleActionValues } from './user-role-action.values';
import { UserRoleVisibility } from './user-role-visibility.entity';
import { userRoleVisibilityValues } from './user-role-visibility.values';
import { AadGroup } from './aad-group.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Role',
	pluralName: 'Roles',
	endpoint: 'rbac/user_roles',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.rbacManagementApi,
})
export class UserRole extends EntityModelBase<number> {
	@EntityField({ data: 'UserRoleId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Description' })
	description: string;

	@EntityField({
		data: 'AllowedActions',
		arrayOf: MdeUserRoleAction,
		parse: (allowedActionsBitWise: number) => {
			if (!allowedActionsBitWise) return [1];

			if (allowedActionsBitWise === -1) return [-1];

			return MdeUserRoleAction.flatten(userRoleActionValues).reduce(
				(actions: Array<number>, userRoleAction) => {
					return userRoleAction.id > 0 && allowedActionsBitWise & userRoleAction.id
						? [...actions, userRoleAction]
						: actions;
				},
				[]
			);
		},
		serialize: (userRoleActions: Array<MdeUserRoleAction>) =>
			userRoleActions.reduce(
				(bitwiseValue: number, action) =>
					bitwiseValue | action.id | action.additionalImplicitAllowedActions,
				0
			),
	})
	allowedActions: Array<MdeUserRoleAction>;

	@EntityField({
		data: 'AllowedActions',
		arrayOf: MdeUserRoleAction,
		parse: (allowedActionsBitWise: number) => {
			if (!allowedActionsBitWise) return [1];

			if (allowedActionsBitWise === -1) return [-1];

			return deprecatedUserRoleActionValues.reduce((actions: Array<number>, userRoleAction) => {
				return userRoleAction.id > 0 && allowedActionsBitWise & userRoleAction.id
					? [...actions, userRoleAction]
					: actions;
			}, []);
		},
		serialize: (userRoleActions: Array<MdeUserRoleAction>) =>
			userRoleActions.reduce(
				(bitwiseValue: number, action) =>
					bitwiseValue | action.id | action.additionalImplicitAllowedActions,
				0
			),
	})
	// Parse allowed actions according to the old permissions model
	oldAllowedActions: Array<MdeUserRoleAction>;

	@EntityField({
		data: 'PiiVisibility',
		arrayOf: UserRoleVisibility,
		parse: (visibilityBitWise: number) => {
			if (visibilityBitWise === -1) return [];

			return userRoleVisibilityValues.reduce((visibilities: Array<number>, userRoleVisibility) => {
				return visibilityBitWise & userRoleVisibility.id
					? [...visibilities, userRoleVisibility.id]
					: visibilities;
			}, []);
		},
		serialize: (userRoleVisibilities: Array<UserRoleVisibility>) =>
			userRoleVisibilities.reduce(
				(bitwiseValue: number, userRoleVisibility) => bitwiseValue | userRoleVisibility.id,
				0
			),
	})
	piiVisibility: Array<UserRoleVisibility>;

	@EntityField({ data: 'LastUpdated' })
	lastUpdatedDate: Date;

	@EntityField({ data: 'LastUpdated' })
	lastUpdated: string; // There's also a string version so it's not parsed into a Date, because the backend requires the exact same string when updating, and we can't do microseconds in JS

	@EntityField({ data: 'UserRoleAssignments', arrayOf: UserRoleAssignment })
	assignments: Array<UserRoleAssignment>;

	@EntityField({ data: 'IsGlobalAdmin', defaultValue: false })
	readonly isGlobalAdmin: boolean;

	assignGroup(aadGroup: AadGroup): boolean {
		const containsGroup: boolean = this.assignments.some(
			assignment => assignment.aadGroup.id === aadGroup.id
		);
		if (!containsGroup) {
			this.assignments = [new UserRoleAssignment({ aadGroup: aadGroup }), ...this.assignments];
			return true;
		}

		return false;
	}

	setGroups(aadGroups: Array<AadGroup>) {
		this.assignments = aadGroups
			? aadGroups.map((aadGroup: AadGroup) => new UserRoleAssignment({ aadGroup: aadGroup }))
			: [];
	}
}
