import { OnDestroy } from '@angular/core';
import { combineLatest, fromEvent, merge, Observable, of, timer, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import * as i0 from "@angular/core";
var possibleHiddenProperties = [
    { hidden: 'hidden', visibilityChange: 'visibilitychange' },
    { hidden: 'webkitHidden', visibilityChange: 'webkitvisibilitychange' },
    { hidden: 'msHidden', visibilityChange: 'msvisibilitychange' },
    { hidden: 'mozHidden', visibilityChange: 'mozvisibilitychange' },
];
var ɵ0 = function (hiddenObj) { return document[hiddenObj.hidden] !== undefined; };
var hiddenData = possibleHiddenProperties.find(ɵ0);
var hiddenProperty = hiddenData.hidden;
var visibilityChangeProperty = hiddenData.visibilityChange;
var PollingService = /** @class */ (function () {
    function PollingService() {
        this.destroy$ = new Subject();
    }
    /**
     * Creates a polling observable that only emits when the document is in view
     * (not minimized and is the active tab). If the doc is not in view for a while (longer than the interval period),
     * then once it regains focus, the observable will emit immediately.
     *
     * @param start {number} how long from now to start polling (in milliseconds)
     * @param interval {number} polling frequency (in milliseconds)
     * @returns {Observable<number>}
     */
    PollingService.prototype.poll = function (start, interval) {
        var _this = this;
        if (start === void 0) { start = 0; }
        return new Observable(function (observer) {
            var timer$ = timer(start, interval);
            var focus$ = merge(fromEvent(document, visibilityChangeProperty), of(null));
            var handledIndex, handledAt, currentEmission = 0, nextEmissionSubscription;
            var subscription = combineLatest(timer$, focus$)
                .pipe(takeUntil(_this.destroy$), tap(function (combinedValue) {
                // stop emissions if document is hidden
                if (document[hiddenProperty]) {
                    nextEmissionSubscription && nextEmissionSubscription.unsubscribe();
                    return;
                }
                var timerIndex = combinedValue[0];
                // this index was already handled
                if (handledIndex && handledIndex === timerIndex)
                    return;
                // handle index
                handledIndex = timerIndex;
                // map to a new delayed timer
                nextEmissionSubscription = timer(handledAt ? interval - (Date.now() - handledAt) : 0).subscribe(function () {
                    observer.next(currentEmission);
                    currentEmission++;
                    handledAt = Date.now();
                }, function (err) { return observer.error(err); });
            }))
                .subscribe();
            return function () {
                nextEmissionSubscription && nextEmissionSubscription.unsubscribe();
                subscription && subscription.unsubscribe();
            };
        });
    };
    PollingService.prototype.ngOnDestroy = function () {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    };
    PollingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PollingService_Factory() { return new PollingService(); }, token: PollingService, providedIn: "root" });
    return PollingService;
}());
export { PollingService };
export { ɵ0 };
