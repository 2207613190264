import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { AlertsSeveritySummary } from '../../alert/alerts-severity-summary.value-object';
import { Url } from '../url.entity';

@EntityRelationship({
	sourceEntity: Url,
	dataEntity: AlertsSeveritySummary,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	baseUrl: config => config.data.serviceUrls.activeAlertsSummary,
	endpoint: 'activeAlertsSummary', 
	getRelationshipData: (url: Url) => ({
		Url: url.id,
	}),
	parseDataQuery: (dataQuery: DataQuery) =>
		Object.assign({ lookingBackInDays: 180 }, dataQuery && dataQuery.where),
})
export class UrlActiveAlertsSummaryRelationship
	implements EntityRelationshipRepositoryType<Url, AlertsSeveritySummary> {}
