import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('response', responseFilter);

function responseFilter() {
	return function(input, responseAction, action) {
		var actionMsgPrefix = responseAction;

		if (responseAction == 'Isolation') {
			actionMsgPrefix = action == 1 ? 'Release from isolation' : 'Device isolation';
		} else if (responseAction == 'Forensic') {
			actionMsgPrefix = 'Package collection';
		} else if (responseAction == 'Scan') {
			actionMsgPrefix = 'Antivirus scan';
		} else if (responseAction == 'ExecutionRestriction') {
			actionMsgPrefix = action == 1 ? 'App restriction removal' : 'App restriction';
		}

		if (
			responseAction == 'Isolation' ||
			responseAction == 'Forensic' ||
			responseAction == 'Scan' ||
			responseAction == 'ExecutionRestriction'
		) {
			switch (input) {
				case 'Cancelled':
					return actionMsgPrefix + ' canceled';
				case 'Failed':
					return actionMsgPrefix + ' failed';
				case 'Submitted':
					return actionMsgPrefix + ' pending';
				case 'InProgress':
					return actionMsgPrefix + ' in progress';
				case 'Succeeded':
					if (responseAction == 'Isolation') {
						return action == 1
							? 'Released from isolation'
							: actionMsgPrefix + ' configuration applied';
					}

					if (responseAction == 'Forensic') {
						return 'Package available';
					}

					if (responseAction == 'ExecutionRestriction') {
						return action == 1 ? 'App restriction removed' : 'App restriction applied';
					}

					if (responseAction == 'Scan') {
						return actionMsgPrefix + ' successfully triggered';
					}

					return actionMsgPrefix + ' succeeded';
				default:
					return input;
			}
		}

		switch (input) {
			case 1:
				return 'Failed';
			case 2:
				return 'Received By Server';
			case 4:
				return 'Received By Client';
			case 8:
				return 'Succeeded';
			case 16:
				return 'Succeeded Pending Reboot';
			default:
				return input;
		}
	};
}
