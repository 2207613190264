var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { dateStrToUtc } from '../../utils';
var ɵ0 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ1 = function (attachments) {
    /*
        There's a bug in the BE - attachments return as string and not as array (https://microsoft.visualstudio.com/OS/_workitems/edit/29226724)
        This is a temp solution so it won't break the UI
     */
    return Array.isArray(attachments) ? attachments : null;
};
var AirsEmail = /** @class */ (function (_super) {
    __extends(AirsEmail, _super);
    function AirsEmail(data) {
        var _this = _super.call(this, data) || this;
        _this.entityName = _this.subject;
        return _this;
    }
    __decorate([
        EntityField({ data: 'sender_ip' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "senderIp", void 0);
    __decorate([
        EntityField({ data: 'received_date', parse: ɵ0 }),
        __metadata("design:type", Date)
    ], AirsEmail.prototype, "receivedDate", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "recipient", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "threats", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "sender", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "subject", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], AirsEmail.prototype, "urls", void 0);
    __decorate([
        EntityField({ data: 'delivery_status' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "deliveryStatus", void 0);
    __decorate([
        EntityField({ data: 'network_message_id' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "networkMessageId", void 0);
    __decorate([
        EntityField({
            data: 'attachments',
            parse: ɵ1,
        }),
        __metadata("design:type", Array)
    ], AirsEmail.prototype, "attachments", void 0);
    __decorate([
        EntityField({ data: 'internet_message_id' }),
        __metadata("design:type", Number)
    ], AirsEmail.prototype, "internetMessageId", void 0);
    __decorate([
        EntityField({ data: 'delivery_location' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "originalDeliveryLocation", void 0);
    __decorate([
        EntityField({ data: 'attachment_file_names' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "attachmentFileNames", void 0);
    __decorate([
        EntityField({ data: 'attachment_file_hashes' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "attachmentFileHashes", void 0);
    __decorate([
        EntityField({ data: 'email_headers' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "emailHeaders", void 0);
    __decorate([
        EntityField({ data: 'email_download' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "emailDownload", void 0);
    __decorate([
        EntityField({ data: 'delivery_action' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "deliveryAction", void 0);
    __decorate([
        EntityField({ data: 'cluster_id' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "clusterId", void 0);
    __decorate([
        EntityField({ data: 'sender_display_name' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "senderDisplayName", void 0);
    __decorate([
        EntityField({ data: 'attachment_malware_family' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "attachmentMalwareFamily", void 0);
    __decorate([
        EntityField({ data: 'language' }),
        __metadata("design:type", String)
    ], AirsEmail.prototype, "language", void 0);
    AirsEmail = __decorate([
        Entity(__assign({}, airsEntityConfig, { singularName: 'Email', pluralName: 'Emails', modelWith: null, forwardRefName: AirsEntityRefNames.AirsEmailMessage })),
        __metadata("design:paramtypes", [Object])
    ], AirsEmail);
    return AirsEmail;
}(AirsEntity));
export { AirsEmail };
export { ɵ0, ɵ1 };
