import { padStart } from 'lodash-es';

export function getUserTimeZone(): number {
	return new Date().getTimezoneOffset() / -60;
}

export function getClientTimeZoneString(): string {
	return getTzString(new Date().getTimezoneOffset() * -1);
}

export function getTzString(timeZoneOffset: number): string {
	const timeZoneHours: number = Math.abs(Math.floor(timeZoneOffset / 60)),
		timeZoneMinutes: number = timeZoneOffset % 60,
		sign: string = timeZoneOffset < 0 ? '-' : '+';

	return `${sign}${padStart(timeZoneHours.toString(), 2, '0')}:${padStart(
		timeZoneMinutes.toString(),
		2,
		'0'
	)}`;
}
