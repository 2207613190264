var ɵ0 = function (osPlatform) {
    if (osPlatform == null || osPlatform == 'undefined') {
        return false;
    }
    return osPlatform.indexOf('Server') > -1;
};
export var machinesLogicService = {
    IsServerOS: ɵ0,
};
export { ɵ0 };
