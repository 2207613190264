var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-elements-content */
import { EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { DataExportSettingsEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { cloneDeep } from 'lodash-es';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { LogType } from '../enums/log-type.enum';
import { DataExportService } from '../services/data-export.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AuthService } from '@wcd/auth';
var DataExportNewModalComponent = /** @class */ (function (_super) {
    __extends(DataExportNewModalComponent, _super);
    function DataExportNewModalComponent(paris, i18nService, router, dataExportService, authService, featureService, dialogsService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.router = router;
        _this.dataExportService = dataExportService;
        _this.authService = authService;
        _this.featureService = featureService;
        _this.dialogsService = dialogsService;
        _this.save = new EventEmitter();
        _this.clickedSave = false;
        _this.isSaving = false;
        _this.sendToStorage = false;
        _this.sendToEventHub = false;
        _this.userExposedToNewDataExportPage = false;
        _this.dataExportRepository = _this.paris.getRepository(DataExportSettingsEntity);
        _this.availableLogTypes = dataExportService.notDeprecatedAvailableLogTypes;
        _this.selectedLogTypes = cloneDeep(_this.availableLogTypes);
        _this.userExposedToNewDataExportPage = _this.featureService.isEnabled(Feature.DataExportSettingsV2Page);
        return _this;
    }
    Object.defineProperty(DataExportNewModalComponent.prototype, "isDirty", {
        get: function () {
            return this.newDataExportForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    DataExportNewModalComponent.prototype.onSave = function () {
        this.save.emit();
        this.clickedSave = true;
        this.closePanel();
    };
    DataExportNewModalComponent.prototype.cancel = function () {
        this.closePanel();
    };
    DataExportNewModalComponent.prototype.saveDataExport = function () {
        var _this = this;
        if (!this.clickedSave) {
            this.clickedSave = true;
        }
        if (!this.isValid) {
            return;
        }
        this.isSaving = true;
        var newDataExport = this.dataExportRepository.createNewItem();
        Object.assign(newDataExport, {
            id: this.name,
            eventHubProperties: this.sendToEventHub
                ? {
                    eventHubResourceId: this.eventHubResourceId,
                    name: this.eventHubName,
                }
                : null,
            storageAccountProperties: this.sendToStorage
                ? {
                    storageAccountResourceId: this.storageAccountId,
                }
                : null,
            logs: this.selectedLogTypes.map(function (checklistValue) {
                return ({
                    category: LogType[checklistValue.id],
                    enabled: _this.availableLogTypes.some(function (logType) { return logType.id === checklistValue.id; }),
                });
            }),
        });
        // Adding a new data export item takes 1-3 seconds, so artificial delay is required
        // to ensure that item appears after the panel close and the data view components
        // tried to read the latest set of items
        this.dataExportRepository.save(newDataExport).subscribe(function () {
            _this.isSaving = false;
            _this.save.emit();
            _this.closePanel();
        }, function (error) {
            _this.isSaving = false;
            var errorMessage = error.message || error;
            if (!_this.authService.currentUser.isSecAdmin) {
                var addedMessage = _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.missingAadPermissions', { tenantid: _this.authService.tenantId });
                errorMessage += " (" + addedMessage + ")";
                error.message = errorMessage;
            }
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.addNew.failedToSave'),
                message: errorMessage,
                data: error,
            });
        });
    };
    Object.defineProperty(DataExportNewModalComponent.prototype, "isValid", {
        get: function () {
            if (!this.name) {
                return false;
            }
            if (!this.sendToEventHub && !this.sendToStorage) {
                return false;
            }
            if (this.sendToEventHub && (!this.eventHubResourceId || !this.eventHubName)) {
                return false;
            }
            if (this.sendToStorage && !this.storageAccountId) {
                return false;
            }
            if (this.selectedLogTypes.length === 0) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    return DataExportNewModalComponent;
}(PanelContainer));
export { DataExportNewModalComponent };
