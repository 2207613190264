import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { DeviceGroupMembership } from '@wcd/domain';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
@Component({
	selector: 'machine-itp-group-membership',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-datatable
			*ngIf="groupMembership?.length; else noneFound"
			[items]="groupMembership"
			[columns]="tableFields"
			[selectEnabled]="false"
			[fieldWidths]="{
				name: resourceTableFieldsWidth,
				formattedLastSeen: resourceTableFieldsWidth,
				domainName: resourceTableFieldsWidth
			}"
		>
		</wcd-datatable>
		<ng-template #noneFound>
			{{ 'machines.entityDetails.fields.groupMembership.noneFound' | i18n }}
		</ng-template>
	`,
})
export class MachineGroupMembershipComponent {
	@Input() groupMembership: Array<DeviceGroupMembership>;

	readonly tableFields: Array<DataTableField>;
	readonly resourceTableFieldsWidth: number = 167;

	constructor(private i18nService: I18nService) {
		this.tableFields = DataTableField.fromList<ReadonlyArray<DeviceGroupMembership>>([
			{
				id: 'name',
				name: this.i18nService.get(
					'machines.entityDetails.fields.groupMembership.tableFields.groupName'
				),
			},
			{
				id: 'domainName',
				name: this.i18nService.get('machines.entityDetails.fields.domain.title'),
			},
			{
				id: 'description',
				name: this.i18nService.get(
					'machines.entityDetails.fields.groupMembership.tableFields.description'
				),
			},
		]);
	}
}
