import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { UsbEvent } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';

@Component({
	selector: 'usb-event-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './usb-event.entity-details.component.html',
})
export class UsbEventEntityDetailsComponent extends EntityDetailsComponentBase<UsbEvent> {
	constructor(injector: Injector) {
		super(injector);
	}
	get event(): UsbEvent {
		return this.entity;
	}
}
