import { Injectable } from '@angular/core';
import { sccHostService } from '@wcd/scc-interface';
import { MtpPermission, tenantContextCache } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class AppContextService {
	constructor(private appConfigService: AppConfigService) {}

	/**
	 * Determines if the app is running in 'MTP' mode
	 */
	get isMtp(): boolean {
		return sccHostService.isSCC && tenantContextCache.hasMtpConsent;
	}

	get isSCC(): boolean {
		return sccHostService.isSCC;
	}

	/**
	 * Determines if MDATP is active.
	 * If the app is running in SCC - check isMdatpActive flag; Otherwise the app is running in MDATP portal - return true
	 */
	get isMdatpActive(): boolean {
		return !this.isSCC || this.appConfigService.isMdatpActive
	}

	/**
	 * Determines if OATP is active.
	 */
	get isOatpActive(): boolean {
		return this.isSCC && this.appConfigService.isOatpActive
	}

	get hasOatpReadPermissions(): boolean {
		return this.appConfigService.oatpMtpPermissions
			&& this.appConfigService.oatpMtpPermissions.includes(MtpPermission.SecurityData_Read);
	}

	get isAadIpActive(): boolean {
		return this.isSCC && this.appConfigService.isAadIpActive;
	}

	getCurrentAppPrefix(): string {
		if (this.isSCC) {
			return 'mdatp-scc-';
		}
		return '';
	}
}
