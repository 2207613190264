const settings = {
	MCASPortalUrls: {
		value: 'https://portal.cloudappsecurity.com',
		values: [
			{
				environment: 'TBPROD--Group', // GCC High
				value: 'https://portal.cloudappsecurity.us'
			},
			{
				environment: 'PFProd--Group', // GCC DoD
				value: 'https://portal.cloudappsecurity.us'
			},
			{
				environment: 'Ppe--Group', // PPE
				value: 'https://console-rs.adallom.com'
			},
			{
				environment: 'eopforest:gcc01b|eopforest:gcc02b',
				value: "https://portal.cloudappsecuritygov.com"
			}
		],
	}
};

export const baseUrlsSettings = window.$host && window.$host.config.getVariantSettings(settings);
