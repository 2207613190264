import { Component, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { KnownColorName } from '../../models/colors/known-colors.models';
import { EMPTY, Observable } from 'rxjs';
import { DistributionElementSize } from './distribution-element/distribution-element.component';
import { PrettyNumberService } from '@wcd/prettify';
import { map } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';

export interface DistributionData {
	barColor: KnownColorName;
	title: string;
	titleColor?: string;
	titleSize?: string;
	mainText?: string | number;
	mainTextSize?: string;
	mainTextColor?: string;
	secondaryText?: string;
	secondaryTextSize?: string;
	secondaryFontWeight?: Number;
	size?: DistributionElementSize;
	class?: string;
	helpText?: string;
}

@Component({
	selector: 'wcd-distribution',
	template: `
		<div
			*ngIf="(processedDistributionData$ | async) as distributionData; else loading"
			[ngClass]="verticalView ? 'wcd-flex-vertical' : ''"
		>
			<distribution-element
				*ngFor="let distributionItem of distributionData"
				[ngClass]="sameWidth ? 'wcd-width-small-medium' : ''"
				[barColor]="distributionItem.barColor"
				[title]="distributionItem.title"
				[mainText]="distributionItem.mainText || '-'"
				[titleColor]="distributionItem.titleColor"
				[titleSize]="distributionItem.titleSize"
				[mainTextSize]="distributionItem.mainTextSize"
				[mainTextColor]="distributionItem.mainTextColor"
				[secondaryText]="distributionItem.secondaryText"
				[secondaryTextSize]="distributionItem.secondaryTextSize"
				[secondaryFontWeight]="distributionItem.secondaryFontWeight"
				[secondaryTextColor]="distributionItem.secondaryTextColor"
				[size]="size"
				[clickable]="clickable"
				[helpText] = "distributionItem.helpText"
				(onClick)="this.onClick.emit(distributionItem)"
			>
			</distribution-element>
		</div>

		<ng-template #loading>
			<fab-spinner
				[size]="SpinnerSize.small"
				[label]="spinnerLabel"
				labelPosition="right"
			></fab-spinner>
		</ng-template>
	`,
	styleUrls: ['./distribution.component.scss'],
})
export class DistributionComponent {
	processedDistributionData$: Observable<ReadonlyArray<DistributionData>>;
	readonly SpinnerSize = SpinnerSize;

	@Output() onDistributionComplete: EventEmitter<void> = new EventEmitter<void>();

	constructor(private prettyNumberService: PrettyNumberService) {}

	@Input() size?: DistributionElementSize = 'compact';

	@Input() verticalView?: boolean;
	@Input() spinnerLabel: string;
	@Input() sameWidth: boolean = false;
	@Input() clickable: boolean = false;

	@Input()
	set distributionData$(value$: Observable<ReadonlyArray<DistributionData>>) {
		if (!value$)
		{ return;
		}
		this.processedDistributionData$ = value$ && value$.pipe(
			map(distributionDataArr =>
				distributionDataArr.map(distributionData => {
					const value =
						typeof distributionData.mainText === 'number'
							? this.prettyNumberService.prettyNumber(distributionData.mainText as number)
							: distributionData.mainText;
					return {
						...distributionData,
						mainText: value,
					};
				})
			)
		);

		this.onDistributionComplete.emit();
	}

	@Output() onClick = new EventEmitter<DistributionData>(false);
}
