import { ChangeDetectionStrategy, Component, Injector, Input } from '@angular/core';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { Alert } from '@wcd/domain';

@Component({
	selector: 'machine-timeline-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div>
			<route-link
				[routeLink]="{
					routerLink: [
						machinesService.getMachineLink(
							machineId || alert.machine.machineId,
							false,
							actionTime
						)
					],
					queryParams: machinesService.getMachineWithAlertParams(
						alert || { lastEventTime: actionTime }
					)
				}"
			>
				<fab-icon iconName="TimelineProgress"></fab-icon>
				{{ 'machines.entityDetails.fields.name.linkText' | i18n }}
			</route-link>
		</div>
	`,
})
export class MachineTimelineLinkComponent {
	@Input() machineId: string;
	@Input() actionTime: Date;
	@Input() alert: Alert;

	constructor(readonly machinesService: MachinesService) {}
}
