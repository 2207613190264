<section
	class="wcd-padding-vertical wcd-padding-large-horizontal wcd-border-bottom-light"
	*ngIf="
		action?.description ||
		('remediationActions.actions.' + type.type + '.description' | i18n: null:true) as description
	"
>
	{{ description }}
</section>
<ng-content select="[action-post-description]"></ng-content>
<wcd-collapsible-section
	*ngIf="type?.id === pivotActionId"
	[label]="i18nService.strings.remediationAction_panel_pivotDetails"
	[sectionId]="'remediation-action-panel-pivot'"
>
	<dl class="key-values wcd-padding-bottom" role="none">
		<dt>{{ i18nService.strings.remediation_fields_reason }}</dt>
		<dd>{{ action.reason }}</dd>
		<dt>{{ i18nService.strings.machines }}</dt>
		<dd>
			<investigated-machines-list [machines]="action.machines"></investigated-machines-list>
		</dd>
	</dl>
</wcd-collapsible-section>
<wcd-collapsible-section
	*ngIf="options?.showComments !== false && !action?.isOfficeInvestigation"
	[sectionId]="'remediation-action-panel-comments'"
	[label]="i18nService.strings.entityCommon_panel_comments_header"
>
	<editable-comments-list
		[readonly]="!allowEditComments"
		[options]="commentsOptions"
		[comments]="comments"
		[isSaving]="isSavingComment"
		[refreshOn]="refreshOn"
		[rbac]="{ permissions: ['alertsInvestigation'], state: 'disabled' }"
		(save)="saveComment($event)"
	></editable-comments-list>
</wcd-collapsible-section>
<ng-container *ngIf="isLoadingAirsEntities; else checkAirsEntities">
	<ng-container *ngTemplateOutlet="showLoader"></ng-container>
</ng-container>
<ng-template #checkAirsEntities>
	<ng-container *ngIf="airsEntities?.length">
		<h4 class="definition-list-title wcd-padding-large-horizontal" *ngIf="airsEntities?.length > 1">
			{{ 'remediationAction_panel_entityCount' | i18n: { entityCount: airsEntities.length } }}
		</h4>
		<airs-entity-entity-panel
			*ngFor="let remediationActionEntity of airsEntities"
			[entity]="remediationActionEntity"
			[flatView]="true"
			[showApproveReject]="false"
			[showGoHuntInDetails]="true"
			[options]="{
				summaryView: summarizeEntities,
				allowStatusSplit: options.allowStatusSplit,
				huntingContext: options.huntingContext
			}"
		></airs-entity-entity-panel>
	</ng-container>
</ng-template>
<wcd-collapsible-section
	*ngIf="action?.relatedEntities?.length"
	data-track-component="RelatedEntities"
	[label]="
		'remediationAction_panel_remediationBundleCount'
			| i18n: { entityCount: action.relatedEntities.length }
	"
	[sectionId]="'remediation-action-panel-related-entities'"
>
	<div *ngIf="action?.relatedEntities?.length">
		<ul class="link-list">
			<li *ngFor="let relatedEntity of action.relatedEntities" class="link-item wcd-border-top-light">
				<div *ngIf="relatedEntity.type && relatedEntity.id; else unknownEntityLabel">
					<button
						(click)="showRelatedEntity(relatedEntity)"
						class="btn btn-link"
						data-track-id="ShowRelatedEntity"
						data-track-type="Button"
					>
						<wcd-shared-icon [iconName]="relatedEntity.type.icon"> </wcd-shared-icon>
						<span>{{ relatedEntity.name }}</span>
						<wcd-shared-icon iconName="carets.right" class="pull-right"> </wcd-shared-icon>
					</button>
				</div>
				<ng-template #unknownEntityLabel>
					<div class="no-link">
						<wcd-shared-icon
							*ngIf="relatedEntity.type; else schemeLabel"
							[iconName]="relatedEntity.type.icon"
						>
						</wcd-shared-icon>
						<ng-template #schemeLabel>{{ relatedEntity.avScheme }} -</ng-template>
						<span>{{ relatedEntity.avKey }}</span>
					</div>
				</ng-template>
			</li>
		</ul>
	</div>
</wcd-collapsible-section>
<ng-container *ngIf="isLoadingInvestigation; else checkInvestigation">
	<ng-container *ngTemplateOutlet="showLoader"></ng-container>
</ng-container>
<ng-template #checkInvestigation>
	<wcd-collapsible-section
		*ngIf="options.showInvestigation && investigation"
		[sectionId]="'remediation-action-panel-investigation-details'"
		[label]="i18nService.strings.investigation_panel_details_title"
	>
		<investigation-entity-details [investigation]="investigation"></investigation-entity-details>
	</wcd-collapsible-section>
</ng-template>
<ng-container *ngIf="isLoadingAlert; else checkAlert">
	<ng-container *ngTemplateOutlet="showLoader"></ng-container>
</ng-container>
<ng-template #checkAlert>
	<wcd-collapsible-section
		*ngIf="alert"
		[sectionId]="'remediation-action-panel-alert-details'"
		[label]="i18nService.strings.remediationAction_panel_alertDetails"
	>
		<alert-entity-details [entity]="alert" [showTitle]="false"> </alert-entity-details>
	</wcd-collapsible-section>

	<wcd-collapsible-section
		*ngIf="alert && alert.description"
		[label]="i18nService.strings.alerts_alertDescription"
		[sectionId]="'remediation-action-panel-alert-description'"
	>
		<alerted-description [description]="alert.description"></alerted-description>
	</wcd-collapsible-section>

	<wcd-collapsible-section
		*ngIf="alert && alert.recommendedAction"
		[label]="i18nService.strings.alerts_alert_recommendedActions"
		[sectionId]="'remediation-action-panel-alert-recommended-action'"
	>
		<alerted-description [recommendedAction]="alert.recommendedAction"></alerted-description>
	</wcd-collapsible-section>
</ng-template>
<ng-template #showLoader>
	<div class="wcd-flex-center-all wcd-padding-all">
		<fab-spinner></fab-spinner>
	</div>
</ng-template>
