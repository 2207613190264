var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { FolderExclusionModel } from '../models/folder-exclusion.model';
import { FolderExclusionsBackendService } from './folder-exclusions.backend.service';
import { Store } from '../../../data/models/store.base';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
var FolderExclusionsStore = /** @class */ (function (_super) {
    __extends(FolderExclusionsStore, _super);
    function FolderExclusionsStore(backendService, ngZone, dialogsService, i18nService) {
        return _super.call(this, backendService, ngZone, FolderExclusionModel, dialogsService, i18nService, {
            itemNameSingular: i18nService.strings.admin_folderExclusions_singular,
            itemNamePlural: i18nService.strings.admin_folderExclusions_plural,
            iconName: 'folderExclusion',
        }) || this;
    }
    return FolderExclusionsStore;
}(Store));
export { FolderExclusionsStore };
