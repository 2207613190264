import { OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TitleService } from '../../../shared/services/title.service';
import { Domain } from '@wcd/domain';
import { DomainFields } from '../services/domain.fields';
import { Paris, Repository } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PreferencesService } from '@wcd/config';
import { of } from 'rxjs';
import { DomainsService } from '../services/domains.service';
import { AppConfigService } from '@wcd/app-config';
import { Lazy } from '../../../utils/lazy';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { find } from 'lodash-es';
import { mergeMap } from 'rxjs/operators';
export var TIME_RANGE_DEFAULT_PREFERENCE_ID = 'domains_time_range_default';
export var WEB_THREAT_CATEGORY_FILTERS_ID = 'domains_web_threat_category_filters';
export var DOMAIN_TIME_RANGES = [
    "day" /* day */,
    "week" /* week */,
    "month" /* month */,
    "6months" /* '6months' */,
];
export var DEFAULT_DOMAIN_TIME_RANGE = 30;
export var DEFAULT_WEB_THREAT_CATEGORY_FILTER = 'Any';
export var DEFAULT_WEB_THREAT_SEVERITY_FILTER = '0';
export var DEFAULT_WEB_THREAT_STATUS_FILTER = '0';
var DomainsDataviewComponent = /** @class */ (function () {
    function DomainsDataviewComponent(paris, domainFields, domainService, titleService, globalEntityTypesService, appConfigService, timeRangesService, preferencesService, route, router) {
        var _this = this;
        this.paris = paris;
        this.domainFields = domainFields;
        this.domainService = domainService;
        this.titleService = titleService;
        this.appConfigService = appConfigService;
        this.timeRangesService = timeRangesService;
        this.preferencesService = preferencesService;
        this.route = route;
        this.router = router;
        this.dataViewId = 'domains';
        this.allowFilters = true;
        this.allowTimeRangeSelect = true;
        this.showHeader = true;
        this.disableSelection = true;
        this._timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick(DOMAIN_TIME_RANGES);
        });
        this.entityType = globalEntityTypesService.getEntityType(Domain);
        this.repository = this.paris.getRepository(Domain);
    }
    DomainsDataviewComponent.prototype.parseQuery = function (queryString) {
        var query = {};
        var pairs = (queryString[0] === '?' ? queryString.substr(1) : queryString).split(',');
        Object.entries(pairs).forEach(function (_a) {
            var key = _a[0], values = _a[1];
            var pair = values.split('=');
            query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '').replace(/\|/g, ',');
        });
        return query;
    };
    DomainsDataviewComponent.prototype.getDataViewConfig = function () {
        var _this = this;
        return {
            getFiltersData: function () { return _this.domainService.getDomainsFilters(); },
            disabledVisibleFieldIds: ['severity', 'status'],
        };
    };
    DomainsDataviewComponent.prototype.onRangeTypeSelect = function () {
        if (this.currentTimeRange)
            this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentTimeRange.id);
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: {
                page: null,
                range: this.currentTimeRange.id,
            },
            queryParamsHandling: 'merge',
        });
        this.setFixedOptions();
    };
    DomainsDataviewComponent.prototype.setFixedOptions = function () {
        this.dataviewFixedOptions = Object.assign({
            lookBackInDays: this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
                ? this.currentTimeRange.value
                : DEFAULT_DOMAIN_TIME_RANGE,
        }, this.fixedOptions);
    };
    Object.defineProperty(DomainsDataviewComponent.prototype, "timeRanges", {
        get: function () {
            return this._timeRanges.value;
        },
        enumerable: true,
        configurable: true
    });
    DomainsDataviewComponent.prototype.getTotalDomainCount = function () {
        return this.repository
            .query({
            where: {
                lookBackInDays: this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
                    ? this.currentTimeRange.value
                    : DEFAULT_DOMAIN_TIME_RANGE,
                webThreatCategory: this.webThreatCategoryFilters$ &&
                    this.webThreatCategoryFilters$['webThreatCategory']
                    ? this.webThreatCategoryFilters$['webThreatCategory']
                    : DEFAULT_WEB_THREAT_CATEGORY_FILTER,
                status: this.webThreatCategoryFilters$ && this.webThreatCategoryFilters$['status']
                    ? this.webThreatCategoryFilters$['status']
                    : DEFAULT_WEB_THREAT_STATUS_FILTER,
                severity: this.webThreatCategoryFilters$ && this.webThreatCategoryFilters$['severity']
                    ? this.webThreatCategoryFilters$['severity']
                    : DEFAULT_WEB_THREAT_SEVERITY_FILTER,
            },
        }, { allowCache: true })
            .pipe(mergeMap(function (items) {
            return of("(" + items.items.length + ")");
        }));
    };
    DomainsDataviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.currentTimeRange = this.appConfigService.isDemoTenant
            ? this.timeRanges[this.timeRanges.length - 1]
            : this.timeRanges.find(function (range) {
                return range.id === _this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID);
            }) || this.timeRanges[1];
        this.route.queryParams.subscribe(function (params) {
            var locationRange = params.range;
            var locationRangeChange = false;
            if (locationRange) {
                var foundRange = find(_this.timeRanges, function (range) { return range.id === locationRange; });
                locationRangeChange = foundRange !== _this.currentTimeRange;
                if (locationRangeChange && foundRange)
                    _this.currentTimeRange = foundRange;
                // The range in the url param overrides preference settings (need to change preference if url params specifically queries with specific time range)
                _this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, _this.currentTimeRange.id);
            }
            if (locationRangeChange) {
                _this.setFixedOptions();
                _this.dataViewConfig = _this.getDataViewConfig();
            }
            if (params.filters) {
                var webThreatCategoryFilters = _this.parseQuery(params.filters);
                _this.preferencesService.setPreference(WEB_THREAT_CATEGORY_FILTERS_ID, webThreatCategoryFilters);
                _this.webThreatCategoryFilters$ = webThreatCategoryFilters;
            }
            else {
                _this.preferencesService.setPreference(WEB_THREAT_CATEGORY_FILTERS_ID, null);
                _this.webThreatCategoryFilters$ = null;
            }
            if (_this.repository) {
                _this.totalDomains$ = _this.getTotalDomainCount();
            }
        });
        this.setFixedOptions();
        this.dataViewConfig = this.getDataViewConfig();
    };
    return DomainsDataviewComponent;
}());
export { DomainsDataviewComponent };
