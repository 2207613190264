import { FlexDirection } from '@wcd/shared';
var TagsFieldComponent = /** @class */ (function () {
    function TagsFieldComponent() {
        this.maxItems = 2;
        this.autoOverflow = false; // only supported for horizontal orientation
        this.orientation = FlexDirection.Horizontal;
        this.localTags = [];
    }
    Object.defineProperty(TagsFieldComponent.prototype, "tags", {
        set: function (value) {
            this.localTags = value;
        },
        enumerable: true,
        configurable: true
    });
    return TagsFieldComponent;
}());
export { TagsFieldComponent };
