import { ModelBase, ValueObject, EntityField, Entity } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';
import { VulnerabilityProductFix } from './vulnerability-product-fix.value-object';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'VulnerabilityAffectedProduct',
	pluralName: 'VulnerabilityAffectedProduct',
	endpoint: 'affectedProducts',
	readonly: true,
})
export class VulnerabilityAffectedProduct extends ModelBase {
	@EntityField() readonly vendor?: string;

	@EntityField() readonly product?: string;

	@EntityField() readonly osFeatures?: Array<string>;

	@EntityField() readonly productVersion?: string;

	@EntityField({
		data: 'productFixes',
		arrayOf: VulnerabilityProductFix,
	})
	productFixes?: Array<VulnerabilityProductFix>;

	@EntityField() readonly productMinVersion?: string;

	@EntityField() readonly productMinVersionIsIncluded?: boolean;

	@EntityField() readonly productMaxVersion?: string;

	@EntityField() readonly productMaxVersionIsIncluded?: boolean;

	@EntityField() readonly targetSoftware?: string;

	@EntityField() readonly uniqueId?: string;
}
