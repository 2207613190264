import { EventEmitter } from '@angular/core';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
var ExtendedFabDatePickerComponent = /** @class */ (function () {
    function ExtendedFabDatePickerComponent(localeConfigService, tzDateService) {
        this.localeConfigService = localeConfigService;
        this.tzDateService = tzDateService;
        this.disabled = false;
        this._minDate = null;
        this._maxDate = null;
        this.isMonthPickerVisible = false;
        this.onSelectDate = new EventEmitter();
        this.disableAutoFocus = true;
        this.formatDate = this.formatDate.bind(this);
        this.emitDate = this.emitDate.bind(this);
    }
    Object.defineProperty(ExtendedFabDatePickerComponent.prototype, "minDate", {
        get: function () { return this._minDate; },
        set: function (minDate) {
            this._minDate = this.calculateDateInRightTimeZone(minDate);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExtendedFabDatePickerComponent.prototype, "maxDate", {
        get: function () { return this._maxDate; },
        set: function (maxDate) {
            this._maxDate = this.calculateDateInRightTimeZone(maxDate);
        },
        enumerable: true,
        configurable: true
    });
    ExtendedFabDatePickerComponent.prototype.translateDateToCorrectTimezone = function (date) {
        // fab-date-picker returns 00:00 of the chosen date in local time
        // When user runs with "UTC timezone", we should return 00:00 in UTC time
        // For example:
        // If user runs in UTC and selects 20/07/15 - datepicker returns 20/07/15 00:00 in Israeli time, which is the same as 20/07/14 21:00 in UTC.
        // This function will return 20/07/15 00:00 in UTC which is the same as 20/07/15 03:00 in Israeli time
        // If user runs in Israeli time and selects 20/07/15 - datepicker returns 20/07/15 00:00 in Israeli time, which is the same as 20/07/14 21:00 in UTC.
        // This function will return 20/07/14 21:00 in UTC which is the same as 20/07/15 00:00 in Israeli time
        return this.localeConfigService.isLocalTimeZone ? date : this.convertLocalToUTCDate(date);
    };
    ExtendedFabDatePickerComponent.prototype.emitDate = function (event) {
        this.onSelectDate.emit(this.translateDateToCorrectTimezone(event.date));
    };
    ExtendedFabDatePickerComponent.prototype.formatDate = function (date) {
        if (!date) {
            return '';
        }
        var convertedDate = this.translateDateToCorrectTimezone(date);
        return this.format ? this.format(convertedDate) : this.tzDateService.format(convertedDate, 'shortDate');
    };
    ExtendedFabDatePickerComponent.prototype.calculateDateInRightTimeZone = function (date) {
        // fab-date-picker is always in localTimezone.
        // Therefore, when we get min/maxDate, we should convert it to UTC, if user chooses to use UTC in his preferences.
        return this.localeConfigService.isLocalTimeZone ? date : moment([
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
        ]).toDate();
    };
    ExtendedFabDatePickerComponent.prototype.convertLocalToUTCDate = function (date) {
        return moment.utc([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
        ]).toDate();
    };
    return ExtendedFabDatePickerComponent;
}());
export { ExtendedFabDatePickerComponent };
