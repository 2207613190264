import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ActiveIncidents } from './active-incidents.value-object';

const DEFAULT_OPTIONS: ActiveIncidentsOptions = {
	readFromCache: false,
	latestIncidentsCount: 4,
	lookbackIndays: 30,
};

@ApiCall({
	name: 'Get the top active incidents',
	endpoint: 'Dashboard/ActiveIncidentsSummary',
	type: ActiveIncidents,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (options: ActiveIncidentsOptions) => ({ params: { ...DEFAULT_OPTIONS, ...options } }),
})
export class ActiveIncidentsApiCall extends ApiCallModel<ActiveIncidents, ActiveIncidentsOptions> {}

@ApiCall({
	name: 'Get the top active incidents',
	endpoint: 'Dashboard/ActiveIncidentsSummary',
	type: ActiveIncidents,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.incidentDashboard,
	parseQuery: (options: ActiveIncidentsOptions) => ({ params: { ...DEFAULT_OPTIONS, ...options } }),
})
export class ActiveIncidentsIncidentsPhase2ApiCall extends ApiCallModel<ActiveIncidents, ActiveIncidentsOptions> {}

export interface ActiveIncidentsOptions {
	readFromCache?: boolean;
	latestIncidentsCount?: number;
	lookbackIndays?: number;
}
