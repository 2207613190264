import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { OrgExposureScoreModel } from './org-exposure-score.value-object';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	cache: null,
	name: 'Org exposure score',
	endpoint: 'riskscore',
	type: OrgExposureScoreModel,
})
export class OrgExposureScoreApiCall extends ApiCallModel<OrgExposureScoreModel> {}
