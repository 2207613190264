import { EntityModelBase } from '@microsoft/paris';
import { ModelBase, EntityField } from '@microsoft/paris';

export interface CmsSection {
	title: string;
	content: string;
	order: number;
}

export interface CmsEntity extends EntityModelBase {
	id: string;
	name: string;
	sections: Array<CmsSection>;
	linkedReport?: string;
}

export class CmsSectionBase extends ModelBase implements CmsSection {
	@EntityField({ data: 'Title' })
	title: string;

	@EntityField({ data: 'Content' })
	content: string;

	@EntityField({ data: 'Order' })
	order: number;
}
