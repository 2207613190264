var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { FileActionPermission } from '../file.action-permission.value-object';
import { File } from '../file.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ1 = function (file) {
    return {
        FileIdentifierType: file.sha1 ? 'Sha1' : 'Sha256',
        FileIdentifier: file.sha1 || file.sha256,
    };
};
var FileActionPermissionRelationship = /** @class */ (function () {
    function FileActionPermissionRelationship() {
    }
    FileActionPermissionRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileActionPermission,
            endpoint: 'remediation/permission',
            baseUrl: ɵ0,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            getRelationshipData: ɵ1,
            cache: {
                max: 5,
                time: 5 * 60 * 1000,
            },
        })
    ], FileActionPermissionRelationship);
    return FileActionPermissionRelationship;
}());
export { FileActionPermissionRelationship };
export { ɵ0, ɵ1 };
