var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { Injector, OnInit, OnDestroy } from '@angular/core';
import { filter, take } from 'rxjs/operators';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RecommendationExceptionAggregated } from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { Router } from '@angular/router';
import { RecommendationExceptionService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import { I18nService } from '@wcd/i18n';
var RecommendationExceptionAggregatedEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(RecommendationExceptionAggregatedEntityDetailsComponent, _super);
    function RecommendationExceptionAggregatedEntityDetailsComponent(activatedEntity, tvmTextsService, router, recommendationExceptionService, i18nService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.activatedEntity = activatedEntity;
        _this.tvmTextsService = tvmTextsService;
        _this.router = router;
        _this.recommendationExceptionService = recommendationExceptionService;
        _this.i18nService = i18nService;
        return _this;
    }
    RecommendationExceptionAggregatedEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.recommendationExceptionAggregated) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof RecommendationExceptionAggregated; }))
                .pipe(take(1))
                .subscribe(function (recommendationExceptionAggregated) {
                _this.recommendationExceptionAggregated = recommendationExceptionAggregated;
                _this.setProperties();
            });
        }
        this.setProperties();
    };
    RecommendationExceptionAggregatedEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    RecommendationExceptionAggregatedEntityDetailsComponent.prototype.navigateToRelatedRecommendation = function () {
        this.router.navigate([
            '/security-recommendations',
            this.recommendationExceptionService.getRelatedRecommendationId(this.recommendationExceptionAggregated),
        ], {
            queryParams: {
                search: "" + this.recommendationExceptionService.getRelatedRecommendationId(this.recommendationExceptionAggregated, false),
            },
        });
    };
    RecommendationExceptionAggregatedEntityDetailsComponent.prototype.navigateToRelatedComponent = function () {
        this.router.navigate([
            '/software-inventory',
            this.recommendationExceptionAggregated.exceptionArgs.vaRecommendationArgs.productId,
        ]);
    };
    RecommendationExceptionAggregatedEntityDetailsComponent.prototype.setProperties = function () {
        var _this = this;
        this.exceptionScopeText = this.tvmTextsService.getText(TextToken.AggregatedExceptionScope, this.recommendationExceptionAggregated);
        this.exceptionTypeText = this.tvmTextsService.getText(TextToken.ExceptionRemediationType, this.recommendationExceptionAggregated);
        this.relatedComponent = this.tvmTextsService.getText(TextToken.RecommendationExceptionRelatedComponent, this.recommendationExceptionAggregated);
        var totalAmountOfMachineGroups = this.recommendationExceptionAggregated.rbacGroupIdsCount;
        this.exceptionStatusTextList = this.recommendationExceptionAggregated.distinctStatusWithCount.map(function (statusWithCount) {
            return _this.i18nService.get("tvm.recommendationException.status." + statusWithCount.status) + " (" + _this.i18nService.get("tvm.recommendationException.machineGroupsScopeOutOfTotalText", {
                scope: statusWithCount.count,
                total: totalAmountOfMachineGroups,
            }) + ")";
        });
    };
    return RecommendationExceptionAggregatedEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { RecommendationExceptionAggregatedEntityDetailsComponent };
