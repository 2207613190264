import { ApiCall, ApiCallModel, ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { CyberEvent } from '../event/cyber-event.entity';

@ValueObject({
	singularName: 'Hunting record process tree response',
	pluralName: '',
})
export class HuntingRecordResponse extends ModelBase {
	@EntityField({ data: 'TelemetryAction' })
	cyberEvent: CyberEvent;
}

@ApiCall({
	name: 'Get extended info of hunting query result record',
	endpoint: 'hunting/record/extendedDetails',
	method: 'POST',
	type: HuntingRecordResponse,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class HuntingRecordExtendApiCall extends ApiCallModel<HuntingRecordResponse, any> {}
