import { PositionModel } from '../models/position.model';
import { DimensionsModel } from '../models/dimensions.model';

export class PositionService {
	static fitPosition(
		position: PositionModel,
		dimensions: { width: number; height: number },
		margin?: number
	): PositionModel {
		const windowDimensions = new DimensionsModel(
				document.documentElement.clientWidth,
				document.documentElement.clientHeight
			),
			resultPosition: PositionModel = position.clone();

		if (!margin) margin = 0;

		if (position.left + margin + dimensions.width > windowDimensions.width - margin)
			resultPosition.left = position.left - dimensions.width;

		if (resultPosition.left < margin)
			resultPosition.left = windowDimensions.width - dimensions.width - margin;

		if (position.top + dimensions.height > windowDimensions.height - margin)
			resultPosition.top = position.top - dimensions.height;

		if (resultPosition.top < margin)
			resultPosition.top = windowDimensions.height - dimensions.height - margin;

		return resultPosition;
	}

	static fitsPosition(
		position: PositionModel,
		dimensions: { width: number; height: number },
		margin?: number
	): FitsPositionInterface {
		const windowDimensions = new DimensionsModel(
			document.documentElement.clientWidth,
			document.documentElement.clientHeight
		);

		if (!margin) margin = 0;

		return {
			right: position.left + dimensions.width + margin <= windowDimensions.width,
			bottom: position.top + dimensions.height + margin <= windowDimensions.height,
			top: dimensions.height + margin * 2 <= windowDimensions.height,
			left: dimensions.width + margin * 2 <= windowDimensions.width,
		};
	}
}

interface FitsPositionInterface {
	top: boolean;
	right: boolean;
	bottom: boolean;
	left: boolean;
}
