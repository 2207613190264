
		<div class="wcd-full-height wcd-flex-vertical">
			<report
				*ngIf="report"
				[hidden]="isFullScreen"
				[report]="report"
				[showPollingError]="false"
				[showControls]="false"
			>
			</report>
			<div
				class="wcd-flex-none wcd-margin-medium-horizontal wcd-margin-medium-bottom wcd-flex-spaceBetween-horizontal wcd-flex-center-vertical"
			>
				<ng-container *ngIf="tabs">
					<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
				</ng-container>
			</div>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	