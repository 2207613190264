import { config } from '@wcd/scc-common';
import { DirRoleType } from './dir-role-type';
import { MtpPermission } from './mtp-permission-enum';
import { uniq } from 'lodash-es';
import { MtpWorkload } from './mtp-workload-enum';
import { MdeUserRoleActionEnum } from './user-role-action';
var AuthUser = /** @class */ (function () {
    function AuthUser(authUserInfo) {
        var _this = this;
        this.hasDefaultManagePermissions = function () {
            return (_this._dirRoles.includes(DirRoleType.GlobalAdministrator) ||
                _this._dirRoles.includes(DirRoleType.SecurityAdministrator) ||
                _this._dirRoles.includes(DirRoleType.SecurityOperator));
        };
        this.aadUserId = authUserInfo.AadUserId;
        this.name = authUserInfo.UserName;
        var usernameMatch = this.name && this.name.match(/^(\w+)@.+/);
        this.shortName = usernameMatch ? usernameMatch[1] : '';
        this.mdeAllowedActions = authUserInfo.MdeAllowedActions || 0;
        this.mdatpMtpPermissions = authUserInfo.MdatpMtpPermissions || [];
        this.itpMtpPermissions = authUserInfo.ItpMtpPermissions || [];
        this.oatpMtpPermissions = authUserInfo.OatpMtpPermissions || [];
        this.mapgMtpPermissions = authUserInfo.MapGMtpPermissions || [];
        this.aadIpMtpPermissions = authUserInfo.AadIpMtpPermissions || [];
        this.dlpMtpPermissions = authUserInfo.DlpMtpPermissions || [];
        this.mdiMtpPermissions = authUserInfo.MdiMtpPermissions || [];
        var activeMainWorkloadPermissions = [].concat(authUserInfo.IsMdatpActive ? this.mdatpMtpPermissions : [], authUserInfo.IsOatpActive ? this.oatpMtpPermissions : [], authUserInfo.IsItpActive ? this.itpMtpPermissions : [], authUserInfo.IsDlpActive ? this.dlpMtpPermissions : [], authUserInfo.IsMdiActive ? this.mdiMtpPermissions : []);
        var allActiveWorkloadPermissions = activeMainWorkloadPermissions.concat(authUserInfo.IsMapgActive ? this.mapgMtpPermissions : [], authUserInfo.IsAadIpActive ? this.aadIpMtpPermissions : []);
        // MTP *main* workloads are the ones that determine eligibility for MTP experiences
        this._mtpPermissionsAllActiveWorkloads = uniq(allActiveWorkloadPermissions);
        this._mtpPermissionsOfMainActiveWorkloads = uniq(activeMainWorkloadPermissions);
        this.isMdeAdmin = this.mdeAllowedActions === MdeUserRoleActionEnum.admin;
        this.isReadonly =
            this.mdeAllowedActions === MdeUserRoleActionEnum.viewData ||
                this.mdeAllowedActions === MdeUserRoleActionEnum.tvmViewData ||
                this.mdeAllowedActions === MdeUserRoleActionEnum.viewData + MdeUserRoleActionEnum.tvmViewData ||
                this._mtpPermissionsAllActiveWorkloads.every(function (permission) {
                    return permission === MtpPermission.SecurityData_Read ||
                        permission === MtpPermission.TvmData_Read;
                });
        this._dirRoles = authUserInfo.DirRoles;
        this.isSecAdmin = this._dirRoles
            ? this._dirRoles.includes(DirRoleType.SecurityAdministrator) ||
                this._dirRoles.includes(DirRoleType.GlobalAdministrator)
            : false;
        this.username = authUserInfo.UserName;
        this._imageUrl = this.isMdeAdmin ? config.images.adminAvatar : config.images.userAvatar;
        Object.freeze(this);
    }
    Object.defineProperty(AuthUser.prototype, "imageCss", {
        get: function () {
            if (!this._imageUrl)
                return null;
            return "url(\"" + this._imageUrl + "\")";
        },
        enumerable: true,
        configurable: true
    });
    AuthUser.prototype.hasMdeAllowedUserRoleAction = function (userRoleAction) {
        var role = typeof userRoleAction === 'number' ? userRoleAction : userRoleAction.id;
        return this.isMdeAdmin || (role !== MdeUserRoleActionEnum.admin && !!(this.mdeAllowedActions & role));
    };
    //ToDo: rename hasRequiredMtpPermission to hasRequiredPermission
    AuthUser.prototype.hasRequiredMtpPermission = function (permission) {
        return this._mtpPermissionsAllActiveWorkloads.includes(permission);
    };
    //ToDo: rename hasRequiredMtpPermissionsFromMainWorkloads to hasRequiredPermissionsFromMainWorkloads
    AuthUser.prototype.hasRequiredMtpPermissionsFromMainWorkloads = function (permission) {
        return this._mtpPermissionsOfMainActiveWorkloads.includes(permission);
    };
    //ToDo: rename hasRequiredMtpPermissionInWorkload to hasRequiredPermissionInWorkload
    AuthUser.prototype.hasRequiredMtpPermissionInWorkload = function (permission, mtpWorkload) {
        switch (mtpWorkload) {
            case MtpWorkload.Itp:
                return this.itpMtpPermissions.includes(permission);
            case MtpWorkload.Oatp:
                return this.oatpMtpPermissions.includes(permission);
            case MtpWorkload.Mdatp:
                return this.mdatpMtpPermissions.includes(permission);
            case MtpWorkload.MapG:
                return this.mapgMtpPermissions.includes(permission);
            case MtpWorkload.AadIp:
                return this.aadIpMtpPermissions.includes(permission);
            case MtpWorkload.Dlp:
                return this.dlpMtpPermissions.includes(permission);
            case MtpWorkload.Mdi:
                return this.mdiMtpPermissions.includes(permission);
            default:
                return false;
        }
    };
    return AuthUser;
}());
export { AuthUser };
