import { Component, Input } from '@angular/core';
import { TreeElementAlertContext } from '@wcd/domain';
import { AlertPageService } from '../../services/alert-page.service';

@Component({
	selector: 'associated-alerts',
	templateUrl: './associated-alerts.component.html',
	styleUrls: ['./associated-alerts.component.scss'],
})
export class AssociatedAlertsComponent {
	@Input() associatedAlerts: Array<TreeElementAlertContext>;

	constructor(readonly alertPageService: AlertPageService) {}
}
