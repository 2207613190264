var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, OnDestroy, Injector } from '@angular/core';
import { filter } from 'rxjs/operators';
import { BaselineConfiguration } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { BaselineDetectedValuesService } from '../../../tvm/services/baseline-detected-value.service';
import { FabricIconNames } from '@wcd/scc-common';
import { MessageBarType } from 'office-ui-fabric-react';
var BaselineConfigurationEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(BaselineConfigurationEntityDetailsComponent, _super);
    function BaselineConfigurationEntityDetailsComponent(activatedEntity, detectedValuesService, i18nService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.activatedEntity = activatedEntity;
        _this.detectedValuesService = detectedValuesService;
        _this.i18nService = i18nService;
        _this.MessageBarType = MessageBarType;
        _this.noDataAvailable = i18nService.strings.notAvailable_short;
        return _this;
    }
    BaselineConfigurationEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.configuration) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof BaselineConfiguration; }))
                .subscribe(function (configuration) {
                _this.configuration = configuration;
            });
        }
        this.singleAsset = this.configuration.applicableDevices === 1;
        this.isCompliant = this.configuration.compliantDevices === 1;
        // TODO: lischach to update logic once other value is present
        this.isInProgress = !this.configuration || this.configuration.compliantDevices === -1;
        this.isCustomValue = this.configuration && this.configuration.id && this.configuration.id.includes(":");
        this.complianceStatus = this.isCompliant
            ? this.i18nService.strings.tvm_baseline_compliant_enum_compliant
            : this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant;
        this.compliantIconName = this.isCompliant
            ? FabricIconNames.StatusCircleInner
            : FabricIconNames.AlertSolid;
        this.isDetectedValueEnabled = this.detectedValuesService.isConfigurationSupported(this.configuration);
        this.detectedValueString = this.detectedValuesService.getConfigurationDetectedValuesString(this.configuration);
        this.sourceValues = this.detectedValuesService.getConfigurationUniqueSources(this.configuration);
    };
    BaselineConfigurationEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    return BaselineConfigurationEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { BaselineConfigurationEntityDetailsComponent };
