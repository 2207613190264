var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnInit, } from '@angular/core';
import { catchError, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, defer, of, throwError, EMPTY } from 'rxjs';
import { File, FileFileStatsRelationship, FileMailStatsRelationship, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FilePrevalenceRelationship } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
var loadingSymbol = Symbol();
var FilePrevalenceComponent = /** @class */ (function () {
    function FilePrevalenceComponent(paris, timeRangeService, changeDetectorRef, officeIntegrationService, appConfigService, appInsightService, featuresService) {
        var _this = this;
        this.paris = paris;
        this.timeRangeService = timeRangeService;
        this.changeDetectorRef = changeDetectorRef;
        this.officeIntegrationService = officeIntegrationService;
        this.appConfigService = appConfigService;
        this.appInsightService = appInsightService;
        this.featuresService = featuresService;
        this.loadingSymbol = loadingSymbol;
        this.secondaryTextShimmerStyle = {
            shimmerWrapper: {
                height: 10,
                maxWidth: '65%',
            },
            root: {
                marginTop: 4,
            },
        };
        this.summaryView = false;
        this.supportNewLayout = false;
        this.error = new EventEmitter();
        this.currentOrgPrevDateRange = (this.timeRangeService.pick(["month" /* month */])[0]);
        this.fileOrgPrevalenceDateRangeChange$ = new BehaviorSubject({
            newRangeValue: this.currentOrgPrevDateRange,
            oldRangeValue: this.currentOrgPrevDateRange,
        });
        this.tenantPrefix$ = this.officeIntegrationService.isIntegrationEnabled$.pipe(filter(function (isEnabled) { return isEnabled; }), switchMap(function (_) { return _this.officeIntegrationService.getOfficeTenantUrlPrefix(); }), filter(function (tenantPrefix) { return Boolean(tenantPrefix); }), shareReplay({ bufferSize: 1, refCount: true }), catchError(function (error) {
            _this.appInsightService.trackException(error, 'officeIntegrationError');
            return EMPTY;
        }));
        this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
    }
    Object.defineProperty(FilePrevalenceComponent.prototype, "file", {
        set: function (value) {
            if (!this.isSameFile(value)) {
                this._file = value;
                this.setMailObservable();
                this.setFileObservable();
                this.setDataObservable();
            }
        },
        enumerable: true,
        configurable: true
    });
    FilePrevalenceComponent.prototype.ngOnInit = function () {
        // TODO - always use FilePrevalenceRelationship when once FilePrevalenceAndTopNamesNewApi fully enabled.
        this.fileStatsRelationshipTypeToUse = this.featuresService.isEnabled(Feature.FilePrevalenceAndTopNamesNewApi)
            ? FilePrevalenceRelationship
            : FileFileStatsRelationship;
        this.filePrevalenceBuckets = this.featuresService.isEnabled(Feature.FilePrevalenceBuckets)
            ? [1000, 5000, 10000, 20000, 50000]
            : null;
    };
    FilePrevalenceComponent.prototype.setMailObservable = function () {
        var _this = this;
        if (!this._file) {
            this.mailStats$ = null;
        }
        else {
            this.mailStats$ = this.tenantPrefix$.pipe(switchMap(function (officeTenantPrefix) {
                return _this.paris.getRelatedItem(FileMailStatsRelationship, _this._file, {
                    where: {
                        officeTenantPrefix: officeTenantPrefix,
                        vNextApi: _this.featuresService.isEnabled('K8SMigration-EPSFilesMailStats-kw')
                    },
                });
            }), catchError(function (error) {
                _this.appInsightService.trackException(error, 'mailStatsDeepLinkToO365Portal');
                return of(null);
            }), startWith(loadingSymbol));
        }
        this.changeDetectorRef.markForCheck();
    };
    FilePrevalenceComponent.prototype.setFileObservable = function () {
        var _this = this;
        if (!this._file) {
            this.fileStats$ = null;
        }
        else {
            this.fileStats$ = defer(function () {
                var data;
                return _this.fileOrgPrevalenceDateRangeChange$.pipe(switchMap(function (timeRangeChangeObject) {
                    return _this.paris
                        .getRelatedItem(_this.fileStatsRelationshipTypeToUse, _this._file, {
                        where: {
                            lookingBackInDays: timeRangeChangeObject.newRangeValue.value,
                            //relevant only for FileStats, ignored by FilePrevalence
                            filesPrefix: _this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw')
                        },
                    })
                        .pipe(tap(function (_data) {
                        data = _data;
                    }, function (err) {
                        // set the previous date range back to the range relevant to the existing data
                        _this.currentOrgPrevDateRange = timeRangeChangeObject.oldRangeValue;
                        _this.changeDetectorRef.markForCheck();
                    }), catchError(function (err) {
                        if (data) {
                            return of(data);
                        }
                        return throwError(err);
                    }), startWith(loadingSymbol));
                }));
            });
        }
        this.changeDetectorRef.markForCheck();
    };
    FilePrevalenceComponent.prototype.setDataObservable = function () {
        var _this = this;
        if (!this._file) {
            this.data$ = null;
        }
        else {
            this.data$ = defer(function () {
                var _fileStats;
                return combineLatest(_this.fileStats$, _this.mailStats$, _this.tenantPrefix$.pipe(startWith(undefined))).pipe(map(function (_a) {
                    var fileStats = _a[0], mailStats = _a[1], tenantPrefix = _a[2];
                    var isLoadingOrganizationFileStats = fileStats === loadingSymbol;
                    if (!_fileStats && !isLoadingOrganizationFileStats) {
                        // setting cached file stats data
                        _fileStats = fileStats;
                    }
                    if (_fileStats) {
                        // Keep worldwide file stats data.
                        // This is done since the current BE api returns worldwide and organization stats together
                        // while the date range should only be applied on the organization prevalence stats.
                        fileStats = isLoadingOrganizationFileStats
                            ? _fileStats
                            : __assign({}, fileStats, { worldwideFirstSeen: _fileStats.worldwideFirstSeen, worldwideLastSeen: _fileStats.worldwideLastSeen, worldwidePrevalence: _fileStats.worldwidePrevalence });
                    }
                    if (fileStats || mailStats) {
                        return {
                            fileStats: fileStats,
                            mailStats: mailStats,
                            isO365IntegrationEnabled: !!tenantPrefix,
                            isLoadingOrganizationFileStats: isLoadingOrganizationFileStats,
                        };
                    }
                    else {
                        return null;
                    }
                }), tap({
                    error: function (err) {
                        _this.error.emit(err);
                    },
                }), startWith(loadingSymbol));
            });
        }
        this.changeDetectorRef.markForCheck();
    };
    FilePrevalenceComponent.prototype.onOrgPrevDateRangeChange = function (timeRange) {
        if (this.currentOrgPrevDateRange !== timeRange) {
            var timeRangeChangeObject = {
                newRangeValue: timeRange,
                oldRangeValue: this.currentOrgPrevDateRange,
            };
            this.currentOrgPrevDateRange = timeRangeChangeObject.newRangeValue;
            this.fileOrgPrevalenceDateRangeChange$.next(timeRangeChangeObject);
        }
    };
    FilePrevalenceComponent.prototype.isSameFile = function (file) {
        return ((this._file && this._file.sha1) === (file && file.sha1) &&
            (this._file && this._file.sha256) === (file && file.sha256));
    };
    return FilePrevalenceComponent;
}());
export { FilePrevalenceComponent };
