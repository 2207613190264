import { FlavorConfig } from '@wcd/scc-common';

export interface IThreatExpertConfig {
	mde: FlavorConfig;
}

export const ThreatExpertConfig: IThreatExpertConfig = {
	mde: {
		mdeFlavors: ['P2'],
	},
};
