import { Component } from '@angular/core';
import { ReportModel } from '../../reports/models/report.model';
import {
	SecurityOperationsDashboardReport,
	SecurityOperationsDashboardReportNoRbacRows,
	SecurityOperationsDashboardReportInScc,
	SecurityOperationsDashboardReportNoRbacRowsInScc,
} from '../../reports/widgets/dashboards/security-operations/security-operations.dashboard.report';
import { FeaturesService } from '@wcd/config';
import {
	ReportWidgetsLayoutRowConfig,
	ReportWidgetsLayoutRowModel,
} from '../../reports/models/report-widgets-layout.row.model';
import { AppConfigService } from '@wcd/app-config';
import {
	ReportLayoutWidgetConfig,
	ReportWidgetsLayoutModel,
} from '../../reports/models/report-widgets-layout.base';
import { ReportWidgetComponent } from '../../reports/components/report-widget.component.base';
import { sccDisabledWidgets } from '../../reports/widgets/dashboards/dashboards.report.module';
import { Type } from '@angular/core';
import { sccHostService } from '@wcd/scc-interface';

const reportLayoutTypes = ['columns', 'rows'];

@Component({
	selector: 'dashboard',
	template: `
		<div class="wcd-full-height">
			<convergance-promotion-toolbar></convergance-promotion-toolbar>
			<report class="wcd-full-height" [report]="dashboardReport" [showControls]="false"></report>
		</div>
	`,
})
export class SecurityOperationsDashboardComponent {
	dashboardReport: ReportModel;

	constructor(private featuresService: FeaturesService,
				private appConfigService: AppConfigService) {
		if (sccHostService.isSCC) {
			this.fillDashboardReport(
				SecurityOperationsDashboardReportInScc,
				SecurityOperationsDashboardReportNoRbacRowsInScc
			);
		} else {
			this.fillDashboardReport(
				SecurityOperationsDashboardReport,
				SecurityOperationsDashboardReportNoRbacRows
			);
		}

		reportLayoutTypes.forEach(reportLayoutType =>
			this.dashboardReport[reportLayoutType].forEach(widgetsLayout =>
				this.removeDisabledWidgets(widgetsLayout)
			)
		);
	}

	private fillDashboardReport(
		baseReport: ReportModel,
		noRbacFilterRows: Array<ReportWidgetsLayoutRowConfig>
	): void {
		this.dashboardReport = baseReport;
		if (this.appConfigService.isExposedToAllMachineGroups) {
			this.dashboardReport.rows = noRbacFilterRows.map(
				(rowConfig: ReportWidgetsLayoutRowConfig) => new ReportWidgetsLayoutRowModel(rowConfig)
			);
		}
	}

	private isDisabledInScc(widgetConfig: ReportLayoutWidgetConfig): boolean {
		return (<Type<ReportWidgetComponent>[]>sccDisabledWidgets).includes(widgetConfig.type);
	}

	private removeDisabledWidgets(widgetsLayout: ReportWidgetsLayoutModel) {
		if (widgetsLayout.widgetComponents) {
			widgetsLayout.widgetComponents = widgetsLayout.widgetComponents.filter(widgetComponent => {
				if (sccHostService.isSCC && this.isDisabledInScc(widgetComponent)) {
					return false;
				}
				const widgetFeatures = widgetComponent.custom && widgetComponent.custom['features'];
				if (
					widgetFeatures &&
					!widgetFeatures.every(feature => this.featuresService.isEnabled(feature))
				)
					return false;

				const widgetNotFeatures = widgetComponent.custom && widgetComponent.custom['disableFeatures'];
				return (
					!widgetNotFeatures ||
					widgetNotFeatures.some(notFeature => !this.featuresService.isEnabled(notFeature))
				);
			});
		}

		reportLayoutTypes.forEach(reportLayout => {
			const rows: ReadonlyArray<ReportWidgetsLayoutRowModel> = widgetsLayout[reportLayout];
			if (rows) rows.forEach(row => this.removeDisabledWidgets(row));
		});
	}
}
