
		<ng-container *ngIf="attachments?.length; else noAttachments">
			<a
				(click)="showMailMessageAttachments()"
				(keydown.enter)="showMailMessageAttachments()"
				data-track-id="ShowEmailAttachmentsPanel"
				data-track-type="Button"
				class="btn-inline btn-link no-padding"
				tabindex="0"
				role="link"
			>
				{{ 'airsEntities_emailMessage_fields_fields_attachments_seeAll' | i18n: { count: attachments.length } }}
			</a>
		</ng-container>
		<ng-template #noAttachments>
			{{ i18nService.strings.airsEntities_emailMessage_fields_attachments_noAttachmentsFound }}
		</ng-template>
	