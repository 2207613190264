import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DoCheck, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewActionElementButtonPicker } from './dataview-action-wrapper.component';
import { DataviewActionTypes } from './dataview-actions.model';

export interface DataviewActionButtonConfig {
	localizedTooltipFn?: () => string;
	localizedTooltip?: string;
	onClickCallback: () => void;
	iconFn?: () => string;
	icon?: string;
	localizedLabelFn?: () => string;
	localizedLabel?: string;
	isBusyFn?: () => boolean; 
	localizedBusyLabel?: string;
	disabled?: boolean;
	elementId?: string;
	dataTrackId?: string;
	dataTrackType?: string;
	actionType: DataviewActionTypes;
}

let lastId = 0;

@Component({
	selector: 'dataview-action-button',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<button [class.disabled]="disabled || isBusy"
			id="{{ elementId + (isInMoreMenu ? '-more' : '')}}"
			class="hidden-action command-bar-item-button"
			*ngIf="shouldRender"
			[attr.data-track-id]="dataTrackId"
			[attr.aria-label]="localizedTooltip"
			[attr.data-track-type]="dataTrackType || 'Button'"
			[wcdTooltip]="localizedTooltip"
			[disabled]="disabled || isBusy"
			(click)="onClickCallback()"
			#buttonEl
		>

			<i class="loader-icon wcd-margin-small-right" [hidden]="!isBusy"></i>
			<wcd-shared-icon [iconName]="icon" [hidden]="isBusy"> </wcd-shared-icon>
			{{ smallScreenView ? '' : isBusy ? localizedBusyLabel : localizedLabel }}

		</button>
	`,
})
export class DataviewActionButtonComponent implements DataViewActionElementButtonPicker, OnInit, DoCheck {
	@Input() buttonActionConfig: DataviewActionButtonConfig;
	@Input() smallScreenView: boolean = false;
	@Input() isInMoreMenu: boolean = false;

	private isBusy: boolean = false;
	public isBusyFn: () => boolean = () => false;

	public localizedTooltipFn: () => string;
	public localizedTooltip: string;
	public onClickCallback: () => void;
	public iconFn: () => string;
	public icon: string;
	public localizedLabelFn: () => string;
	public localizedLabel: string;
	public disabled: boolean = false;
	public shouldRender: boolean = true;
	public elementId = 'command-bar-item-' + ++lastId;
	public dataTrackId: string;
	public dataTrackType: string;

	@ViewChild('buttonEl', { static: false }) buttonEl: ElementRef;

	constructor(public i18nService: I18nService, private cd: ChangeDetectorRef) {}

	buttonElementPicker() {
		return this.buttonEl.nativeElement
	}

	ngOnInit() {
		Object.assign(this, { ...this.buttonActionConfig });
	}

	ngDoCheck() {
		let hasChanges = false;
		if (this.isBusyFn && this.isBusy !== this.isBusyFn()) {
			this.isBusy = this.isBusyFn();
			hasChanges = true;
		}

		if (this.localizedLabelFn && this.localizedLabel !== this.localizedLabelFn()) {
			this.localizedLabel = this.localizedLabelFn();
			hasChanges = true;
		}

		if (this.iconFn && this.icon !== this.iconFn()) {
			this.icon = this.iconFn();
			hasChanges = true;
		}

		if (this.localizedTooltipFn && this.localizedTooltip !== this.localizedTooltipFn()) {
			this.localizedTooltip = this.localizedTooltipFn();
			hasChanges = true;
		}

		if (hasChanges) {
			this.cd.detectChanges();
		}
	}
}
