var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ActivatedRoute, Router } from '@angular/router';
import { Software, SoftwareInstallation } from '@wcd/domain';
import { HistorySafeRedirectComponent } from './history-safe-redirect.component';
var NonSupportedRedirectComponent = /** @class */ (function (_super) {
    __extends(NonSupportedRedirectComponent, _super);
    function NonSupportedRedirectComponent(activatedRoute, router) {
        var _this = _super.call(this, activatedRoute, router) || this;
        var entity = activatedRoute.snapshot.data.entity;
        if ((entity instanceof Software || entity instanceof SoftwareInstallation) && entity.productNeverMatched) {
            _this.redirectTo = "installations";
        }
        return _this;
    }
    return NonSupportedRedirectComponent;
}(HistorySafeRedirectComponent));
export { NonSupportedRedirectComponent };
