import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityRelationType } from './airs-related-entity-type.value-object';

@ValueObject({
	singularName: 'Related Entity',
	pluralName: 'Related Entity',
	readonly: true,
})
export class AirsRelatedEntity extends ModelBase {
	@EntityField({ data: 'entity_type' })
	entityType: AirsEntityTypeConfig;

	@EntityField() count: number;

	@EntityField({ data: 'relation_types', arrayOf: AirsEntityRelationType })
	relationTypes: Array<AirsEntityRelationType>;
}
