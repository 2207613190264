<dataview class="wcd-full-height"
	*ngIf="sourceValue"
	id="basline-device-configuration-dataview"
	[dataViewConfig]="dataViewConfig"
	[giveSearchBoxMoreSpace]="true"
	[repository]="configurationsRepo"
	[navigateOnChange]="false"
	[allowPaging]="true"
	[allowFilters]="false"
	[searchEnabled]="true"
	[ignoreQueryParams]="true"
	[allowColumnCustomization]="false"
	[fields]="fields"
	[disableSelection]="true">
</dataview>
