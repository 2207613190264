import { Injectable, ElementRef } from '@angular/core';
import { AlertsEtwService } from './alert-etw.service';
import { BaseType, event, select, selectAll, Selection } from 'd3-selection';
import { hierarchy, tree } from 'd3-hierarchy';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { FilesService } from '../../../@entities/files/services/files.service';
import { UrlsService } from '../../../@entities/urls/services/urls.service';
import { IpsService } from '../../../@entities/ips/services/ips.service';
import { RegExpService } from '@wcd/shared';
import { Alert } from '@wcd/domain';
import { Observable, Subject } from 'rxjs';

const iconFontSize = 20;
const textFontSize = 14;
const expandMargin = 1;
const expandToIcon = 13;
const sidePaneIconSize = 12;
const expandIconSize = 12;
const iconPadding = 2; // comes from css icon
const textVerticalAlignment = -2; // make sure not have a value ending with 0.5 so text is not on pixel intersection
const textToAdditionalText = 2;
const rowHeight = 32;
const additionalTextHeight = 8;
const additionalTextHeight2 = 16;
const defualtHeight = 400;
const alertCircleRadius = 7;
const bugCircleRadius = 7;
const alertIconFontSize = 10;
const alertCircleMargin = 10;
const iconOffset = (iconFontSize - expandIconSize) / 2;
const encyclopediaLink = 'http://go.microsoft.com/fwlink/?LinkID=142185&Name=';
const toolTipConfig = {
	padding: 24,
	rectPadding: 8,
	xPadding: 4,
	yPadding: 2,
};
const distanceToIconNode = expandIconSize + expandToIcon;
const distanceToText = distanceToIconNode + iconFontSize + expandMargin + 1;
const xLevelDistance = distanceToIconNode + (iconFontSize - sidePaneIconSize) / 2; // put the next level under the icon in the middle
const bugXLocation = distanceToIconNode + 10;
const whiteCircleBugLocation = distanceToIconNode + 15;
const margin = { top: 30, right: 0, bottom: 30, left: 20 };
const spacingBetweenTrees = 70;
const duration = 100;

export type NodeElement = any;
/**
 * Note: this is a stateful service. Do not use as singleton provider.
 */
@Injectable()
export class AlertProcessTreeHelperService {
	private graphDataWithTrees: any[];
	private totalHeight: number;
	private tree: Function;
	private treeContainerEl: ElementRef<HTMLElement>;
	private alert: Alert;
	private nodeClickedSubject = new Subject<NodeElement>();

	constructor(
		private readonly genericEtwService: AlertsEtwService,
		private readonly machinesService: MachinesService,
		private readonly urlsService: UrlsService,
		private readonly ipsService: IpsService,
		private readonly filesService: FilesService
	) {}

	public init(
		treeContainerEl: ElementRef<HTMLElement>,
		alert: Alert,
		alertTimeline: any
	): Observable<NodeElement> {
		this.treeContainerEl = treeContainerEl;
		this.alert = alert;
		const graphData = this.buildGraphData(alertTimeline.RootElements, 0, null);
		this.renderGraph(graphData);
		return this.nodeClickedSubject.asObservable();
	}

	public getEventTypeFriendlyName(eventType) {
		let eventText = '';
		switch (eventType) {
			case 'CreateFile':
				eventText = 'file modification';
				break;
			case 'CreateProcess':
				eventText = 'process creation';
				break;
			case 'NetworkConnection':
				eventText = 'network connection';
				break;
			case 'Registry':
				eventText = 'registry modification';
				break;
			case 'LoadImage':
				eventText = 'module load';
				break;
			case 'Scan':
				eventText = 'detection';
				break;
			case 'UserLogon':
				eventText = 'user log-on';
				break;
			case 'FileSignatures':
				eventText = 'scan';
				break;
			default:
				if (this.genericEtwService.isEtwSupported(eventType)) {
					const alertProcessDetails = this.genericEtwService.getEtwAlertProcessTreeDetails(
						eventType
					);
					if (alertProcessDetails && alertProcessDetails.eventTypeFriendlyName != null) {
						eventText = alertProcessDetails.eventTypeFriendlyName;
						break;
					}
				}
				eventText = 'other';
				break;
		}

		return eventText;
	}

	private buildGraphData(elements, depth, parentElement) {
		const data = [];

		for (let i = 0; i < elements.length; i++) {
			const element = elements[i];
			element.Parent = parentElement;

			if (element.ElementType === 'GenericEtw') {
				element.fullEtwType = element.ActionType;
				element.ActionType = this.genericEtwService.getEtwEventType(element);
				if (
					!this.genericEtwService.isEtwSupported(element.ActionType) ||
					!this.genericEtwService.getEtwAlertProcessTreeDetails(element.ActionType)
				) {
					continue;
				}
			}

			const item: any = {
				icon: this.getNodeIcon(element),
				url: this.getNodeUrl(element),
				depth: depth,
				isAssociatedWithAlert: element.IsAssociatedWithAlert,
				weight: element.Weight,
				name: this.getNodeName(element),
				element: element,
				elementId: element.ElementId,
				additionalText: '',
				isFirstChild: i === 0,
			};

			// set additionalText and optionally additionalText2 fields
			item.additionalText = this.getNodeAdditionalText(element, item);

			if (element.children || (element.SubElements && element.SubElements.length)) {
				item._children = this.buildGraphData(element.SubElements, depth + 1, element);
				item.hasChildren = true;
				item.childsCount = item._children.length;
			}

			data.push(item);
		}

		return data;
	}

	private getSvgObject() {
		return select(this.treeContainerEl.nativeElement.querySelector('svg'));
	}

	private renderGraph(graphData) {
		this.graphDataWithTrees = [];

		this.totalHeight = defualtHeight; // default for start - will immediately change when we call the update function

		const container = select(this.treeContainerEl.nativeElement);

		container.select('*').remove();

		container
			.append('svg')
			.attr('width', '100%')
			.attr('height', defualtHeight + margin.top + margin.bottom);

		let startY = 0;
		for (let i = 0; i < graphData.length; i++) {
			const data = graphData[i];
			this.graphDataWithTrees.push(this.createTree(data, i, startY));
			startY += 200;
		}

		this.update(false);

		this.graphDataWithTrees.forEach(t => {
			this.collapseUnrelatedNodes([t.mappedTree], false, t.root.weight);
		});

		this.update(false);

		select(self.frameElement).style('height', '100%');
	}

	private collapseUnrelatedNodes(graphData, alertFoundInAncestors, treeWeight) {
		let alertFoundInDescendants = '';
		if (graphData && graphData.length) {
			for (let i = 0; i < graphData.length; i++) {
				const node = graphData[i];
				let alertInDirectDescendants = '';
				if (node.data.hasChildren) {
					alertInDirectDescendants = this.collapseUnrelatedNodes(
						node.children || node._children,
						alertFoundInAncestors || node.data.isAssociatedWithAlert,
						treeWeight
					);
				}

				// Keep Expanded:
				// All nodes (only very small trees)
				// Alert evidence
				// Direct paths to evidence
				// Descendants of evidence that do not have evidence (only for small trees)
				if (
					treeWeight < 10 ||
					node.data.isAssociatedWithAlert ||
					alertInDirectDescendants ||
					(alertFoundInAncestors && treeWeight < 20)
				) {
					// keep expanded. do nothing.
				} else {
					this.collapseNode(node);
				}

				alertFoundInDescendants =
					alertFoundInDescendants || alertInDirectDescendants || node.data.isAssociatedWithAlert;
			}
		}

		return alertFoundInDescendants;
	}

	private createTree(data, index, startY) {
		const svgObject = this.getSvgObject();
		this.totalHeight = defualtHeight; // default for start - will immediately change when we call the update function
		this.updateNodeIds([data], '', 0);
		const containerEl: HTMLElement = <HTMLElement>document.querySelector('#processTree-container'),
			width = containerEl ? containerEl.clientWidth : 960,
			height = this.totalHeight;

		this.tree = tree().size([height, width]);
		const mappedTree = hierarchy(data, d => d.children || d._children);

		const svg = svgObject
			.append('g')
			.attr('id', 'tree_' + index)
			.attr('transform', 'translate(' + margin.left + ',' + startY + ')');

		// Make sure the line is at the bottom!
		const processTreeLine = svg.append('g').attr('class', 'processTreeLine');

		const processTreeData = svg.append('g').attr('class', 'processTreeData');

		const processTreeIcons = svg.append('g').attr('class', 'processTreeIcons');

		return {
			mappedTree: mappedTree,
			root: data,
			tree: tree,
			svg: svg,
			processTreeLine: processTreeLine,
			processTreeData: processTreeData,
			processTreeIcons: processTreeIcons,
		};
	}

	private getFileLink(fileId: string, fileName?: string) {
		return this.filesService.getFileLink(fileId, null, fileName);
	}

	private getNodeIcon(node) {
		if (!node.ElementType) {
			return;
		}

		switch (node.ElementType) {
			case 'File':
				return '\uE160'; // icon-Page
			case 'Registry':
				return '\uE74C'; //icon-OEM
			case 'Process':
				return '\uE115'; // icon-Settings
			case 'Module':
				return '\uE9F5'; // icon-Processing
			case 'Url':
				return '\uE167'; // icon-Link
			case 'NetworkConnection':
				return '\uE93E'; // icon-Streaming
			case 'GenericEtw':
				return this.genericEtwService.getEtwIcons(node.ActionType).alertProcessTreeIconCode;
			case 'Machine':
				return '\uE7F8'; // icon-DeviceLaptopNoPic
			case 'User':
				return '\uEE05'; // icon of user login
			default:
				return '\uE115'; // icon-Settings
		}
	}

	private getNodeUrl(node) {
		if (!node.ElementType) {
			return '';
		}

		switch (node.ElementType) {
			case 'GenericEtw':
				return this.genericEtwService.getEtwAlertNodeUrl(node);
			case 'File':
			case 'Process':
			case 'Module':
				if (node.Sha1 && RegExpService.sha1.test(node.Sha1)) {
					return this.getFileLink(node.Sha1, node.FileName);
				}
				break;
			case 'Registry':
				// No profile page for registry keys/values - so no link
				return '';
			case 'Url':
				return this.urlsService.getUrlLink(node.Url);
			case 'NetworkConnection':
				const ipUrl = node.IpAddress ? this.ipsService.getIpLink(node.IpAddress) : '';
				const domainUrl = node.Domain ? this.urlsService.getUrlLink(node.Domain) : '';
				return { ip: ipUrl, domain: domainUrl };
			case 'Machine':
				if (node.RemoteMachineId === 'multiple') {
					return `/search/machines/${node.RemoteMachineName}`;
				} else if (node.RemoteMachineId) {
					// If a machine ID was found, link to that machine page
					// Use as timestamp the powershell process timestamp + 5 minute
					// (this delay is to show events that occur afterwards, to account for unsync clocks and because the Powershell script might take time to run)
					const timestamp: Date = new Date(node.ActionTime);
					timestamp.setMinutes(timestamp.getMinutes() + 5);

					return this.machinesService.getMachineLink(
						node.RemoteMachineId,
						true,
						new Date(timestamp)
					);
				} else {
					// If no machine with that name was found, return no link.
					return '';
				}
		}

		return '';
	}

	private truncateStringWithElipsis(text, maxLength) {
		return text !== null ? text.substring(0, maxLength) + (text.length > maxLength ? '.....' : '') : text;
	}

	private getNodeAdditionalText(node, item) {
		if (!node.ActionType) {
			return '';
		}

		if (!node.InitiatingProcessName) {
			node.InitiatingProcessName = 'Unknown process';
		}

		switch (node.ActionType) {
			case 'UserLogon':
				if (node.AnomalousDescription) {
					item.additionalText2 = node.AnomalousDescription;
				}
				return node.LogonType + ' logon occurred before the detected activities';
			case 'CreateFile':
				if (node.ParentProcessType === 'Email') {
					return `Email attachment written to disk`;
				} else if (node.FileMotwHostUrl || node.FileMotwHostIp) {
					let downloadAddressDescription = node.FileMotwHostIp;
					if (node.FileMotwHostUrl) {
						downloadAddressDescription = this.truncateStringWithElipsis(node.FileMotwHostUrl, 30);
					}
					return (
						node.InitiatingProcessName +
						' downloaded ' +
						node.FileName +
						' from ' +
						downloadAddressDescription
					);
				} else if (node.FileMotwReferrerUrl) {
					return node.InitiatingProcessName + ' extracted ' + node.FileName;
				} else {
					return node.InitiatingProcessName + ' created file ' + node.FileName;
				}
			case 'ShellStartLink':
				if (node.InitiatingProcessName) {
					const lowerParent = node.InitiatingProcessName.toLowerCase();
					if (
						lowerParent === 'outlook.exe' ||
						lowerParent === 'winword.exe' ||
						lowerParent === 'excel.exe' ||
						lowerParent === 'powerpnt.exe' ||
						lowerParent === 'skype.exe'
					) {
						// These known processes don't open links in the browser, so we know it was clicked by the user
						return 'User clicked on link';
					} else {
						// Unknown process - we don't know if url was opened due to a user click or not, so use more vague terms
						return node.InitiatingProcessName + ' opened link';
					}
				} else {
					// With lack of process details, default to a user-friendly description, even if not 100% accurate.
					return 'User clicked on link';
				}
			case 'FileSpecifiedInCommandline':
				const parentProcessName = node.InitiatingProcessName.toLowerCase();
				if (parentProcessName === 'winword.exe' || parentProcessName === 'powerpnt.exe') {
					return node.InitiatingProcessName + ' opened ' + node.FileName;
				} else if (
					parentProcessName.indexOf('7z') === 0 ||
					parentProcessName.indexOf('rar') !== -1 ||
					parentProcessName.indexOf('zip') !== -1
				) {
					return node.InitiatingProcessName + ' extracted ' + node.FileName;
				} else {
					return node.InitiatingProcessName + ' executed ' + node.FileName;
				}
			case 'CreateProcess':
				if (node.ParentProcessType === 'LogicalElevate') {
					return (
						'Process ' +
						node.FileName +
						' has been executed with elevated permissions via svchost.exe'
					);
				}
				if (node.ParentProcessType === 'LogicalWmi') {
					return (
						'Process ' +
						node.InitiatingProcessName +
						' executed ' +
						node.FileName +
						' via a WMI call'
					);
				}
				if (node.ParentProcessType === 'LogicalWmiRemote') {
					return (
						'Process ' +
						node.FileName +
						' was executed via a remote WMI call from ' +
						node.Parent.RemoteMachineName
					);
				}
				return '';
			case 'ConnectionSuccess':
			case 'TcpConnectionSuccess': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
			case 'ConnectionFailed':
			case 'TcpConnectionFailed': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
			case 'InboundConnectionAccepted':
			case 'InboundTcpConnectionAccepted': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
			case 'ConnectionRequest':
			case 'TcpConnectionRequest': // TODO -remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
			case 'ConnectionObserved': // TODO - backward support. remove when this value will disappear from network event type column in kusto (~March 19)
			case 'ConnectionFound':
				return (
					node.InitiatingProcessName +
					' ' +
					mapNetworkEventTypeToMessage(node.ActionType) +
					' ' +
					this.getNodeName(node) +
					' using port ' +
					node.Port
				);
			case 'RemotePowershellCommand':
				return (
					node.InitiatingProcessName +
					' executed Powershell commands on remote device ' +
					node.RemoteMachineName
				);
			case 'RemoteScQuery':
				return (
					node.InitiatingProcessName +
					' queried service details on remote device ' +
					node.RemoteMachineName
				);
			case 'RemoteScManage':
				return (
					node.InitiatingProcessName + ' managed service on remote device ' + node.RemoteMachineName
				);
			case 'Registry':
				let registryOperationDescription = 'modified registry';
				switch (node.RegistryOperation) {
					case 'DeleteValue':
						registryOperationDescription = 'deleted registry value';
						break;
					case 'DeleteKey':
						registryOperationDescription = 'deleted registry key';
						break;
					case 'CreateKey':
						registryOperationDescription = 'created registry key';
						break;
					case 'RenameKey':
						registryOperationDescription = 'renamed registry key';
						break;
					case 'SetValue':
						registryOperationDescription = 'set registry value';
						break;
				}

				return node.InitiatingProcessName + ' ' + registryOperationDescription;
			case 'LoadImage':
				return node.InitiatingProcessName + ' loaded module ' + node.FileName;
			default:
				if (node.ElementType === 'GenericEtw') {
					const etwProcessTreeDetails = this.genericEtwService.getEtwAlertProcessTreeDetails(
						node.ActionType
					);
					if (etwProcessTreeDetails && etwProcessTreeDetails.nodeDescription != null) {
						return etwProcessTreeDetails.nodeDescription(node);
					}
				}
				return '';
		}
	}

	private getNodeName(node) {
		if (node.ElementType === 'Registry') {
			let registryFullPath = node.RegistryKey;
			if (node.RegistryValueName) {
				registryFullPath = node.RegistryKey + '\\' + node.RegistryValueName;
			}
			const maxRegistryLength = 70;
			if (registryFullPath.length <= maxRegistryLength) {
				// If registry path is short enough - return as-is
				return registryFullPath;
			}

			// Display up to maxRegistryLength characters from the end of the registry path
			const registryTokens = registryFullPath.split('\\');
			let shortenedRegisteryPath = registryTokens[registryTokens.length - 1];
			for (let i = registryTokens.length - 2; i > 0; i--) {
				const concatenatedPath = registryTokens[i] + '\\' + shortenedRegisteryPath;
				if (concatenatedPath.length > maxRegistryLength) {
					break;
				} else {
					shortenedRegisteryPath = concatenatedPath;
				}
			}

			return '...\\' + shortenedRegisteryPath;
		}

		if (node.ElementType === 'Machine') {
			return node.RemoteMachineName;
		}

		if (node.ElementType === 'User') {
			return node.Domain + '\\' + node.User;
		}

		if (node.ElementType === 'Url') {
			return this.truncateStringWithElipsis(node.Url, 70);
		}

		if (node.ElementType === 'GenericEtw') {
			const processTreeDetails = this.genericEtwService.getEtwAlertProcessTreeDetails(node.ActionType);
			if (processTreeDetails.nodeTitle != null) {
				return processTreeDetails.nodeTitle(node);
			}
		}

		if (node.ElementType === 'NetworkConnection') {
			const dnsString = node.Domain ? node.Domain + ' ' : '';
			const ipString = node.IpAddress
				? node.Domain
					? ' (' + node.IpAddress + ')'
					: node.IpAddress
				: '';
			return dnsString + ipString;
		}

		if (!node.FileName && node.ElementType === 'Process') {
			return 'Unknown process';
		}

		return node.FileName;
	}

	private isValidSha1(str) {
		return RegExpService.sha1.test(str);
	}

	private isDetectionEvent(node) {
		return (
			node.element.ActionType === 'WDAVDetection' ||
			((node.element.ActionType === 'WDAVDefenderDataConsumer' ||
				node.element.ActionType === 'IRSpynetReport') &&
				node.element.EtwEventPropertiesAsJson &&
				node.element.EtwEventPropertiesAsJson.ThreatName)
		);
	}

	//function returns an array of all the shas in the graph
	private getGraphShasAsArray(graphData, shasArray) {
		for (let i = 0; i < graphData.length; i++) {
			const node = graphData[i];
			if (node.element && node.element.Sha1 && !this.isDetectionEvent(node)) {
				shasArray = shasArray.concat([node.element.Sha1]);
			} else if (node.element && node.element.Sha256 && !this.isDetectionEvent(node)) {
				shasArray = shasArray.concat([node.element.Sha256]);
			}
			//recursive call for node with visible children in the process tree
			if (node.children) {
				shasArray = this.getGraphShasAsArray(node.children, shasArray);
			}
			//recursive call for node with hidden children in the process tree (collapsed nodes)
			if (node._children) {
				shasArray = this.getGraphShasAsArray(node._children, shasArray);
			}
		}
		return shasArray;
	}

	private firstSeenInArray(val, index, array) {
		return array.indexOf(val) == index;
	}

	private getGraphDistinctShas(graphData) {
		const graphShaAsArray = this.getGraphShasAsArray(graphData, []);
		return graphShaAsArray.filter((x, i, a) => {
			return this.firstSeenInArray(x, i, a);
		});
	}

	private lookupAndReturnIndex(name, objArray, propertyName) {
		for (let i = 0; i < objArray.length; i++) {
			if (objArray[i][propertyName] === name) return i;
		}
		return -1;
	}

	private getNodeHeight(node) {
		const additionalText = node.additionalText ? additionalTextHeight : 0;
		let additionalText2 = node.additionalText2 ? additionalTextHeight : 0;
		additionalText2 = additionalText && additionalText2 ? additionalTextHeight2 : additionalText2;
		const padding = additionalText || additionalText2 ? iconPadding : iconPadding;
		return rowHeight + additionalText + additionalText2 + padding;
	}

	private getDetectedSha1s(virusTotaldetectionArray, defenderDetectionArray) {
		const res = [];
		virusTotaldetectionArray.forEach(item => {
			if (item.positives >= 2) {
				res.push(item.sha1);
			}
		});
		defenderDetectionArray.forEach(item => {
			res.push(item.Sha1);
		});
		return res.filter((x, i, a) => {
			return this.firstSeenInArray(x, i, a);
		});
	}

	private updateNodeIds(graphData, father, startId, isMappedNode = false) {
		//update the tree data, nodes order are determined by IDs (lower the ID nodes will be higher in the process tree representation)
		let currentIndex = startId;
		let lastChildHasAdditionalText = false;
		let lastChildHasDetectionAdditionalText = false;
		if (graphData && graphData.length) {
			for (let i = 0; i < graphData.length; i++) {
				const node = graphData[i];
				const prevNode = i == 0 ? father : graphData[i - 1];
				if (isMappedNode) {
					node.data.id = currentIndex;
					node.data.prevSibling = prevNode;
				} else {
					node.id = currentIndex;
					node.prevSibling = prevNode;
				}
				currentIndex++;

				if (node.children) {
					const retVal = this.updateNodeIds(node.children, node, currentIndex, isMappedNode);
					if (i == graphData.length - 1) {
						lastChildHasAdditionalText = retVal.lastChildHasAdditionalText;
						lastChildHasDetectionAdditionalText = retVal.lastChildHasDetectionAdditionalText;
					}

					currentIndex = retVal.newIndex;
				}
				lastChildHasAdditionalText =
					lastChildHasAdditionalText || graphData[graphData.length - 1].additionalText;
				lastChildHasDetectionAdditionalText =
					lastChildHasDetectionAdditionalText || graphData[graphData.length - 1].positives;
			}
		}

		return {
			newIndex: currentIndex,
			lastChildHasAdditionalText: lastChildHasAdditionalText,
			lastChildHasDetectionAdditionalText: lastChildHasDetectionAdditionalText,
		};
	}

	private update(shouldAddInfoAsync) {
		const svgObject = this.getSvgObject();
		let totalTreesSize = margin.top;
		for (let i = 0; i < this.graphDataWithTrees.length; i++) {
			const currentTree = this.graphDataWithTrees[i];
			this.updateSingleTree(currentTree, shouldAddInfoAsync);
			currentTree.svg
				.transition()
				.duration(duration)
				.attr('transform', 'translate(' + margin.left + ',' + totalTreesSize + ')');
			totalTreesSize += currentTree.tree.height + spacingBetweenTrees;
		}

		const heightDuration = 20;
		totalTreesSize = totalTreesSize - spacingBetweenTrees + margin.bottom;

		setTimeout(() => {
			svgObject.attr('height', totalTreesSize);
		}, heightDuration);
	}

	private updateSingleTree(data, shouldAddInfoAsync) {
		const updateData = this.updateNodeIds([data.mappedTree], '', 0, true);

		// Compute the new tree layout.

		const treeData = this.tree(data.mappedTree),
			nodes = treeData.descendants(),
			links = treeData.links();

		this.setUpNodeCalcuatedAttributes(nodes, data);

		let lineLength = data.tree.height + iconPadding;

		if (updateData.lastChildHasAdditionalText) {
			lineLength += additionalTextHeight - iconPadding;
		}
		if (updateData.lastChildHasDetectionAdditionalText) {
			lineLength += additionalTextHeight - iconPadding;
		}

		//will update the process tree async
		if (shouldAddInfoAsync) {
			this.updateBugIcon(nodes, data);
			this.updateDetectionText(nodes, data);
		}
		this.handleNodeUpdate(nodes, data.processTreeData, data.svg);
		this.drawSideLine(updateData.newIndex, lineLength, data.processTreeLine);
		this.addAlertIcons(nodes, data.processTreeIcons);
		this.handleLinkUpdates(nodes, links, data.processTreeData, data.svg);
	}

	private drawSideLine(nodeCount, lineHeight, processTreeLine) {
		// Add a straight line from the middle of the first connector to the bottom node

		const maxY = lineHeight;
		const startX = expandIconSize / 2;
		const startY = 0 - iconPadding;

		const horizonalLineEndX = expandIconSize;

		const verticalLinePath = 'M' + startX + ',' + startY + ' L' + startX + ',' + maxY;
		const bottomLinePath = 'M0,' + maxY + 'L' + horizonalLineEndX + ',' + maxY;
		const pathString = verticalLinePath + bottomLinePath;

		const existingLines = processTreeLine.selectAll('.processTree-sideLine').data([nodeCount]);

		if (nodeCount > 1) {
			existingLines
				.enter()
				.append('path')
				.attr('d', pathString)
				.attr('class', 'processTree-sideLine process-tree-line-color');

			existingLines
				.transition()
				.duration(duration)
				.attr('d', pathString);
		} else {
			existingLines
				.transition()
				.duration(duration)
				.remove();
		}

		existingLines
			.exit()
			.transition()
			.duration(duration)
			.remove();
	}

	private collapseNode(d) {
		if (d.children && d.children.length == 0) return;

		if (!d.data) d.data = {};
		if (!d.data._children) d.data._children = d._children;

		if (d.children) {
			d.children.forEach(child => this.collapseNode(child));
			d.data._children = d.children;
			d.children = null;
		}
	}

	private addAdditionalText(d, parentGroup) {
		let text;
		if (d.data.additionalText2) {
			const detectionOffset = d.data.additionalText ? additionalTextHeight2 : 0;
			const dy = textVerticalAlignment + textFontSize + textToAdditionalText + detectionOffset;
			text = parentGroup
				.append('text')
				.attr('dx', distanceToText)
				.attr('dy', dy)
				.attr('class', 'process-tree-addtional-text')
				.text(d => {
					return d.data.additionalText2;
				});

			//append an encyclopedia icon with a link and a tooltip
			if (d.data.threatName) {
				const textWidth = Math.ceil(text.node().getComputedTextLength());
				const readingIcon = parentGroup
					.append('svg:a')
					.attr('xlink:href', encyclopediaLink + d.data.threatName)
					.on('mouseover', function() {
						const parentNode = select(this.parentNode);
						const tooltip = parentNode
							.append('g')
							.attr('class', 'encyclopedia-tooltip-group')
							.attr(
								'transform',
								'translate(' +
									(distanceToText + textWidth + toolTipConfig.padding) +
									',' +
									dy +
									')'
							);

						const tooltipRect = tooltip
							.append('rect')
							.attr('class', 'process-tree-tooltip-rect')
							.attr('height', 20)
							.attr('width', 10);

						const tooltipText = tooltip
							.append('text')
							.attr('dx', 2)
							.attr('dy', 15)
							.text('Read more on Microsoft Encyclopedia');

						tooltipRect.attr(
							'width',
							Math.ceil((<any>tooltipText.node()).getComputedTextLength()) +
								toolTipConfig.rectPadding
						);
					})
					.on('mouseleave', function() {
						this.parentNode.selectAll('.encyclopedia-tooltip-group').remove();
					});

				readingIcon
					.append('text')
					.attr('class', 'icon alert-encyclopedia-icon')
					.attr('dx', distanceToText + textWidth + toolTipConfig.xPadding)
					.attr(
						'dy',
						textVerticalAlignment +
							textFontSize +
							textToAdditionalText +
							detectionOffset +
							toolTipConfig.yPadding
					)
					.text('\uE736'); //book icon
			}
		}
		if (d.data.additionalText3) {
			let detectionOffset2 =
				d.data.additionalText || d.data.additionalText2 ? additionalTextHeight2 : 0;
			detectionOffset2 =
				d.data.additionalText && d.data.additionalText2
					? 2 * additionalTextHeight2
					: detectionOffset2;
			text = parentGroup
				.append('text')
				.attr('dx', distanceToText)
				.attr('dy', textVerticalAlignment + textFontSize + textToAdditionalText + detectionOffset2)
				.attr('class', 'process-tree-addtional-text')
				.text(d => {
					return d.data.additionalText3;
				});
		}
	}

	private handleNodeUpdate(nodes, processTreeData, svg) {
		processTreeData.selectAll('g.processTree-node').remove();

		// Update the node set from the data
		const node = processTreeData.selectAll('g.processTree-node').data(nodes, d => {
			return d.data.elementId;
		});

		// NEW NODES - JUST TURNED VISIBLE
		// Enter any new nodes at the correct x / y according to id
		const nodeEnter = node
			.enter()
			.append('g')
			.attr('class', 'processTree-node side-pane-container')
			.attr('transform', d => {
				return 'translate(' + d.x + ',' + d.y + ')';
			});

		// g nodes don't response to mouse events - this invisible rect only catches clicks so the hover css will take effect
		nodeEnter
			.append('rect')
			.attr('class', 'process-tree-hover-group')
			.style('visibility', 'hidden')
			.attr('y', -1 * (iconFontSize + iconPadding))
			.attr('x', d => {
				return -1 * (d.x + margin.left);
			})
			.attr('width', '100%')
			.attr('height', d => {
				return this.getNodeHeight(d);
			});

		// Add expand / collapse icon
		const expandGroup = nodeEnter
			.append('g')
			.attr('class', 'icon alert-process-tree-expand-icon-group')
			.on('click', d => {
				this.toggle(d, true);

				if (event) {
					event.stopPropagation();
				}
			});

		expandGroup.each(function(d) {
			const thisGroup = select(this);
			if (d.data.hasChildren) {
				const text = '\uE833';
				let path = '';

				if (d.children) {
					path = '';
				} else {
					path = 'M5.5,-9L5.5,-3.5';
				}

				thisGroup
					.append('text')
					.attr('class', 'icon alert-process-tree-expand-icon')
					.text(text);
				thisGroup
					.append('path')
					.attr('d', path)
					.attr('class', 'process-tree-line-color alert-process-tree-plus-line');

				if (d.depth == 0 && d.data.isAssociatedWithAlert) {
					thisGroup.attr('transform', 'translate(' + -1 * margin.left + ',0)');
				}
			}
		});

		// Add node icon
		nodeEnter
			.append('text')
			.attr('class', 'icon alert-process-tree-node-icon')
			.attr('dx', distanceToIconNode)
			.attr('dy', iconOffset)
			.text(d => {
				return d.data.icon;
			});

		this.addBugIcon(nodeEnter);

		// Add node name
		const texts = nodeEnter.append('g');
		const component = this;
		texts.each(function(d) {
			// Add name and URL
			const thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);
			let text;
			if (d.data.url) {
				if (d.data.element.ElementType === 'NetworkConnection') {
					let currentTextDistance = distanceToText;
					if (d.data.element.Domain) {
						let domainText: Selection<BaseType, any, HTMLElement, any> = thisGroup
							.append('svg:a')
							// .attr('xlink:href', d => {
							// 	return d.data.url.domain;
							// })
							.on('click', d => component.sidePaneActivate(event, d))
							.append('svg:text')
							.attr('dx', currentTextDistance)
							.attr('dy', textVerticalAlignment + 2)
							.attr('class', 'graph-link icon domain-info-icon')
							.text(d => {
								return '\uE946';
							}); // icon-info

						let domainTextLen = (<any>domainText.node()).getComputedTextLength();
						currentTextDistance += domainTextLen + 1;

						domainText = thisGroup
							.append('text')
							.attr('dx', currentTextDistance)
							.attr('dy', textVerticalAlignment)
							.text(d => {
								return d.data.element.Domain;
							});

						domainTextLen = (<any>domainText.node()).getComputedTextLength();
						currentTextDistance += domainTextLen + 1;
					}

					if (d.data.element.IpAddress) {
						const ipText = d.data.element.Domain
							? ' (' + d.data.element.IpAddress + ')'
							: d.data.element.IpAddress;

						thisGroup
							.append('svg:a')
							.on('click', d => component.sidePaneActivate(event, d))
							.append('svg:text')
							.attr('dx', currentTextDistance)
							.attr('dy', textVerticalAlignment)
							.attr('class', 'graph-link')
							.text(d => {
								return ipText;
							});
					}
				} else {
					text = thisGroup
						.append('svg:a')
						.on('click', d => component.sidePaneActivate(event, d))
						.append('svg:text')
						.attr('dx', distanceToText)
						.attr('dy', textVerticalAlignment)
						.attr('class', 'graph-link')
						.text(d => {
							return d.data.name && d.data.name.length > 100
								? d.data.name.substring(0, 97) + '...'
								: d.data.name;
						});
				}
			} else {
				text = thisGroup
					.append('text')
					.attr('dx', distanceToText)
					.attr('dy', textVerticalAlignment)
					.text(d => {
						return d.data.name;
					});
			}

			// Add additinal text
			if (d.data.additionalText) {
				text = thisGroup
					.append('text')
					.attr('dx', distanceToText)
					.attr('dy', textVerticalAlignment + textFontSize + textToAdditionalText)
					.attr('class', 'process-tree-addtional-text')
					.text(d => {
						return d.data.additionalText;
					});
			}

			component.addAdditionalText(d, thisGroup);
			d.data.textLength = text ? (<any>text.node()).getComputedTextLength() : 0;
		});

		nodeEnter
			.transition()
			.duration(duration)
			.attr('transform', d => {
				return 'translate(' + d.x + ',' + d.y + ')';
			});

		// EXISTING NODES
		// Transition nodes to their new position.
		const nodeUpdate = nodeEnter
			.transition()
			.duration(duration)
			.attr('transform', d => {
				return 'translate(' + d.x + ',' + d.y + ')';
			});

		// Set up expand / collapse icon
		const expandIconPlus = svg.selectAll('.alert-process-tree-plus-line').data(nodes, function(n) {
			return n.elementId;
		});
		expandIconPlus.transition().attr('d', d => {
			if (d.data.hasChildren && !d.children) return 'M5.5,-9L5.5,-3.5';
			return '';
		});

		// EXITING NODES - HIDDEN
		// Remove exiting nodes
		const nodeExit = nodeEnter
			.exit()
			.transition()
			.duration(duration)
			.remove();

		// Stash the old positions for transition.
		nodes.forEach(d => {
			d.x0 = d.x;
			d.y0 = d.y;
		});
	}

	private isValidForSidePane(node) {
		if (node.data.element && node.data.element.ElementType === 'GenericEtw') {
			const etwIsValidForSidePaneFunction = this.genericEtwService.getEtwSidePaneDetails(
				node.data.element.ActionType
			).isEtwValidForSidePane;
			return etwIsValidForSidePaneFunction ? etwIsValidForSidePaneFunction(node.data.element) : false;
		}

		return (
			node &&
			node.data &&
			node.data.element &&
			(node.data.element.Sha1 ||
				node.data.element.FileName ||
				node.data.element.IpAddress ||
				node.data.element.Domain ||
				node.data.element.RegistryOperation ||
				node.data.element.Url)
		);
	}

	private addAlertIcons(nodes, processTreeIcons) {
		const biggerCircleRadiusDiff = 2;
		const translateY =
			iconFontSize / 2 - (biggerCircleRadiusDiff + alertCircleMargin * 2 - iconFontSize) - 2; // 2 for the icon padding-bottom

		// Update the node set from the data
		const node = processTreeIcons.selectAll('.processTree-alertIconNode').data(nodes, d => {
			return d.data.elementId;
		});

		// NEW NODES - JUST TURNED VISIBLE
		// Enter any new nodes at the correct x / y according to id
		const nodeEnter = node
			.enter()
			.append('g')
			.attr('class', 'processTree-alertIconNode')
			.attr('transform', d => {
				return 'translate( 0,' + (d.y - iconOffset) + ')';
			});

		const nodesElements: Selection<BaseType, any, HTMLElement, any> = selectAll(
			'.processTree-alertIconNode'
		);

		const alert = this.alert;

		nodesElements.each(function(node) {
			if (node.data.isAssociatedWithAlert) {
				let associatedAlertTitles = node.data.element.AssociatedAlertTitles;
				if (!alert.groupedAlertsCount || alert.groupedAlertsCount <= 1) {
					associatedAlertTitles = ['Alert evidence'];
				}

				const textIndentation = 5;
				let longestAlertTitle = 15; // length of "Alert evidence" - min width of this tooltip text box
				let tooltipWidth = 100;
				for (let i = 0; i < associatedAlertTitles.length; i++) {
					if (associatedAlertTitles[i].length > longestAlertTitle) {
						longestAlertTitle = associatedAlertTitles[i].length;
						tooltipWidth = textIndentation + Math.ceil(6.8 * longestAlertTitle);
					}
				}

				const group = select(this);

				const alertIconCircle = group
					.append('g')
					.attr('class', 'alert-icon-circle')
					.on('mouseover', function() {
						//will expand the element to original size when the mouse enter the circle object
						const parentNode = select<SVGGElement, any>(<SVGGElement>(
							(<SVGGElement>this).parentNode
						));
						parentNode.selectAll('.process-tree-tooltip-rect').attr('width', tooltipWidth);
						parentNode.selectAll('.associated-alert-titles').attr('textLength', null);
					})
					.on('mouseleave', function() {
						//will minimize the element when the mouse leaves the circle object (to avoid overlapping)
						const parentNode = select<SVGGElement, any>(<SVGGElement>(
							(<SVGGElement>this).parentNode
						));
						parentNode.selectAll('.process-tree-tooltip-rect').attr('width', 10);
						parentNode.selectAll('.associated-alert-titles').attr('textLength', 10);
					});

				alertIconCircle
					.append('circle')
					.attr('class', 'alert-icon-node-circle-outer')
					.attr('cx', alertCircleRadius - 1)
					.attr('r', alertCircleRadius + biggerCircleRadiusDiff);

				//TODO support link from event to original alert (with different severity icon per node)
				alertIconCircle
					.append('circle')
					.attr('class', 'alert-icon-node-circle-' + node.data.element.AssociatedAlertSeverity)
					.attr('cx', alertCircleRadius - 1)
					.attr('r', alertCircleRadius);

				alertIconCircle
					.append('text')
					.attr(
						'class',
						'icon alert-icon-node-text alert-icon-node-text-' +
							node.data.element.AssociatedAlertSeverity
					)
					.text('\uE945') //icon-LightningBolt
					.attr('dx', alertCircleRadius / 2 - 2)
					.attr('dy', alertCircleRadius / 2 + (alertIconFontSize - alertCircleRadius) / 2);

				const tooltipGroup = group
					.append('g')
					.attr('class', 'tooltip-group')
					.attr('transform', 'translate(15,0)');

				//width is set to 10 to prevent elements overlapping
				tooltipGroup
					.append('rect')
					.attr('height', associatedAlertTitles.length * 20)
					.attr('width', 10)
					.attr('class', 'process-tree-tooltip-rect');

				const tooltipText = tooltipGroup.append('text');

				for (let i = 0; i < associatedAlertTitles.length; i++) {
					//textLength is set to 10 to prevent elements overlapping
					tooltipText
						.append('tspan')
						.text(associatedAlertTitles[i])
						.attr('class', 'associated-alert-titles')
						.attr('textLength', 10)
						.attr('x', textIndentation)
						.attr('dy', i == 0 ? 15 : 20);
				}
			}
		});

		// EXISTING NODES
		// Transition nodes to their new position.
		const nodeUpdate = node
			.transition()
			.duration(duration)
			.attr('transform', d => {
				return 'translate( 0,' + (d.y - iconOffset) + ')';
			});

		// EXITING NODES - HIDDEN
		// Remove exiting nodes
		const nodeExit = node
			.exit()
			.transition()
			.duration(duration)
			.remove();
	}

	private handleLinkUpdates(nodes, links, processTreeData, svg) {
		// Update the node set from the data
		const link = processTreeData.selectAll('.process-tree-path').data(links, d => {
			return 'source' + d.source.elementId + 'target' + d.target.elementId;
		});

		// Enter any new nodes at the correct x / y according to id
		const linkEnter = link
			.enter()
			.append('path')
			.attr('class', 'process-tree-path process-tree-line-color')
			.transition()
			.duration(duration)
			.attr('d', l => {
				return this.getProcessTreeConnectorLine(l, nodes);
			});

		// update exsiting links
		svg.selectAll('.process-tree-path')
			.transition()
			.duration(duration)
			.attr('d', l => {
				return this.getProcessTreeConnectorLine(l, nodes);
			});

		// Remove exiting links
		link.exit()
			.transition()
			.duration(duration)
			.remove();
	}

	private toggle(d, runUpdate) {
		if (d.children && d.children.length == 0) return;

		if (!d.data) d.data = {};
		if (!d.data._children) d.data._children = d._children;

		//saves the data for later use (d3 will show only children and will not show _children)
		if (d.children) {
			d.data._children = d.children;
			d.children = null;
			if (runUpdate) {
				this.update(false);
			}
		} else {
			d.children = d.data._children;
			d.data._children = null;

			if (runUpdate) {
				this.update(false);
			}
		}
	}

	private calculateAdditionalTextHeight(node) {
		const firstLine = node && node.data && node.data.additionalText ? additionalTextHeight : 0;
		let secondLine = node && node.data && node.data.additionalText2 ? additionalTextHeight : 0;
		secondLine = firstLine && secondLine ? additionalTextHeight2 : secondLine;
		let thirdLine = node && node.data && node.data.additionalText3 ? additionalTextHeight : 0;
		thirdLine = (firstLine && thirdLine) || (secondLine && thirdLine) ? additionalTextHeight2 : thirdLine;
		return firstLine + secondLine + thirdLine;
	}

	private setUpNodeCalcuatedAttributes(nodes, data) {
		//calculate new nodes positions and update the tree height
		nodes.sort(function(a, b) {
			return a.data.id - b.data.id;
		});
		for (let i = 0; i < nodes.length; i++) {
			const currentNode = nodes[i];
			let prevNode: any = '';
			if (i > 0) {
				prevNode = nodes[i - 1];
			}

			currentNode.x = currentNode.depth * xLevelDistance;

			//calculate node's Y position
			const prevNodeAdditionalTextHeight = this.calculateAdditionalTextHeight(prevNode);
			const levelHeight = rowHeight + iconPadding + prevNodeAdditionalTextHeight;
			const startFrom = prevNode ? prevNode.y : 0;
			currentNode.y = i == 0 ? 0 : startFrom + levelHeight;
			data.tree.height = currentNode.y;
		}
	}

	private getProcessTreeConnectorLine(link, nodes) {
		const source = link.source;
		const target = link.target;

		const arrowMarginFromIcons = 2;

		// start point X: source x + enough to be in the middle of the node icon
		const startX = Math.ceil(source.x + distanceToIconNode + iconFontSize / 2);

		// start point Y:
		// if the previous sibling has an expand icon (or this is the first node), take a margin
		// otherwise - just start where it starts
		let prevSiblingY = 0;
		if (target.data.prevSibling) {
			const prevNode = nodes.find(d => d.data.elementId === target.data.prevSibling.data.elementId);
			prevSiblingY = prevNode ? prevNode.y : 0;
		}
		const prevSiblingHasChildren = target.data.prevSibling
			? target.data.prevSibling.data.hasChildren
			: false;

		const startY = Math.ceil(
			target.data.isFirstChild
				? prevSiblingY + iconPadding + arrowMarginFromIcons + iconOffset
				: prevSiblingHasChildren
				? prevSiblingY + arrowMarginFromIcons
				: prevSiblingY - iconOffset - 2
		);

		// end point X : target x with margin
		const endpointX = Math.ceil(target.x + distanceToIconNode - arrowMarginFromIcons * 2);

		// endpoint Y: middle of linetom
		const endpointY = Math.ceil(target.y - iconOffset - iconPadding);

		let pathString = 'M ' + startX + ',' + startY + ', ';

		// Target: if the target node as an expand icon, take a margin both vertically and horizontically
		if (target.data.hasChildren) {
			const lineHeight = target.y - arrowMarginFromIcons - iconFontSize - prevSiblingY;
			pathString +=
				'L ' + startX + ',' + Math.ceil(target.y - arrowMarginFromIcons - expandIconSize) + ', ';
			pathString +=
				'M ' + Math.ceil(startX + sidePaneIconSize - arrowMarginFromIcons) + ',' + endpointY + ', ';
		} else {
			pathString += 'L ' + startX + ',' + endpointY + ', ';
		}

		// Line from where we stopped to right before the node icon
		pathString += 'L ' + endpointX + ',' + endpointY;
		return pathString;
	}

	private updateVirusTotalDetecionRate(virusTotaldetectionArray, defenderDetectionArray, graphData) {
		let node;
		for (let i = 0; i < graphData.length; i++) {
			node = graphData[i].data || graphData[i];
			if ((node.element.Sha1 || node.element.Sha256) && !this.isDetectionEvent(node)) {
				const indexVtSha1 = this.lookupAndReturnIndex(
					node.element.Sha1,
					virusTotaldetectionArray,
					'sha1'
				);
				const indexVtSha256 = this.lookupAndReturnIndex(
					node.element.Sha256,
					virusTotaldetectionArray,
					'sha256'
				);
				const indexDefender = this.lookupAndReturnIndex(
					node.element.Sha1,
					defenderDetectionArray,
					'Sha1'
				);

				if (indexDefender >= 0 && defenderDetectionArray[indexDefender].Threat) {
					node.positives = 1;
					node.additionalText2 =
						'Detected as ' +
						defenderDetectionArray[indexDefender].Threat +
						' by Windows Defender AV';
					node.threatName = defenderDetectionArray[indexDefender].Threat;
				}

				if (indexVtSha1 >= 0 || indexVtSha256 >= 0) {
					const indexVt = indexVtSha1 >= 0 ? indexVtSha1 : indexVtSha256;
					node.positives = virusTotaldetectionArray[indexVt].positives;
					const totalDetectors = virusTotaldetectionArray[indexVt].total;
					if (virusTotaldetectionArray[indexVt].positives) {
						node.additionalText3 =
							'VirusTotal detection ratio: ' + node.positives + '/' + totalDetectors;
					}
				}
			}
			if (node.children) {
				this.updateVirusTotalDetecionRate(
					virusTotaldetectionArray,
					defenderDetectionArray,
					node.children
				);
			}
			if (node._children) {
				this.updateVirusTotalDetecionRate(
					virusTotaldetectionArray,
					defenderDetectionArray,
					node._children
				);
			}
		}
	}

	private addBugIcon(node) {
		const component = this;
		node.each(function(d) {
			if (d.data.positives) {
				const circleParam = component.getBugIconCircleParameters(d.data.element.ActionType);
				select(this)
					.append('circle')
					.attr('class', 'alert-icon-circle bug-icon-node-circle')
					.attr('cx', circleParam.cx)
					.attr('cy', circleParam.cy)
					.attr('r', circleParam.radius);
			}
		});

		node.each(function(d) {
			if (d.data.positives) {
				const bugIconParameters = component.getBugIconParameters(d.data.element.ActionType);
				select(this)
					.append('text')
					.attr('class', 'icon ' + bugIconParameters.bugIcon)
					.attr('dx', bugIconParameters.dx)
					.attr('dy', bugIconParameters.dy)
					.text('\uEBE8') //bug icon
					.style('fill', '#a80000');
			}
		});
	}

	private getBugIconCircleParameters(actionType) {
		if (actionType === 'LoadImage') {
			return {
				radius: bugCircleRadius - 1,
				cx: whiteCircleBugLocation + 3,
				cy: 1.5,
			};
		}
		return {
			radius: bugCircleRadius,
			cx: whiteCircleBugLocation,
			cy: 0,
		};
	}

	private getBugIconParameters(actionType) {
		if (actionType === 'LoadImage') {
			return {
				bugIcon: 'alert-VT-WDAV-bug-overlay-LoadImage',
				dx: bugXLocation + 2,
				dy: iconOffset + 2,
			};
		}
		return {
			bugIcon: 'alert-VT-WDAV-bug-overlay',
			dx: bugXLocation,
			dy: iconOffset + 3,
		};
	}

	private updateBugIcon(nodes, data) {
		const node = data.processTreeData.selectAll('g.processTree-node').data(nodes, d => {
			return d.data.elementId;
		});

		this.addBugIcon(node);
	}

	private updateDetectionText(nodes, data) {
		const node = data.processTreeData.selectAll('.graph-link').data(nodes, d => {
			return d.data.elementId;
		});
		const component = this;

		node.each(function(d) {
			component.addAdditionalText(d, select(this.parentNode.parentNode));
		});
	}
	private sidePaneActivate(event, node) {
		if (event) {
			event.stopPropagation();
		}

		if (!this.isValidForSidePane(node)) {
			return; // TODO: error msg? disable link?
		}

		this.nodeClickedSubject.next(node.data.element);
	}
}

type NetworkEventType =
	| 'ConnectionSuccess'
	| 'TcpConnectionSuccess' // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionFailed'
	| 'TcpConnectionFailed' // TODO -  remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'InboundConnectionAccepted'
	| 'InboundTcpConnectionAccepted' // TODO -  remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionRequest'
	| 'TcpConnectionRequest' // TODO -  remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionObserved' // TODO - backward support. remove when this value will disappear from network event type column in kusto (~March 19)
	| 'ConnectionFound';

function mapNetworkEventTypeToMessage(networkEventType: NetworkEventType | null) {
	let description = 'communicated with';
	switch (networkEventType) {
		case 'TcpConnectionSuccess': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'ConnectionSuccess': {
			description = 'successfully established connection with';
			break;
		}
		case 'TcpConnectionFailed': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'ConnectionFailed': {
			description = 'failed to establish connection with';
			break;
		}
		case 'InboundTcpConnectionAccepted': // TODO - remove once PR 2349491 (ine) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'InboundConnectionAccepted': {
			description = 'accepted connection from';
			break;
		}
	}
	return description;
}
