import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('highlight', highlightDirective);

function highlightDirective() {
	return {
		restrict: 'EA',
		scope: {},
		link: function(scope, element, attrs) {
			$(element).hover(
				function() {
					var id = $(element).attr('highlight');
					$('#' + id).addClass('highlight');
				},
				function() {
					var id = $(element).attr('highlight');
					$('#' + id).removeClass('highlight');
				}
			);
		},
	};
}

SevilleModule.directive('highlightToggle', highlightToggleDirective);

function highlightToggleDirective() {
	return {
		restrict: 'EA',
		scope: {},
		link: function(scope, element, attrs) {
			var elem = $(element);
			elem.click(function() {
				if (elem.attr('highlight-enabled') !== 'true') return;

				var id = elem.attr('highlight-toggle');
				var match = $('.' + id);
				if (match.length > 0) {
					match.toggleClass('highlight');
					elem.toggleClass('highlight');
					match.bind('$destroy', function() {
						elem.removeClass('highlight');
					});
				}
			});
		},
	};
}
