import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {
	AlertCategory,
	AlertClassification,
	AlertClassificationId,
	AlertStatus,
	DataSensitivity,
	GetIncidentQueueFiltersApiCall,
	IncidentAssignmentFilterValues,
	IncidentQueueFilters,
	IncidentSensitivityFilterValues,
	InvestigationStatus,
	Outbreak,
	Severity,
} from '@wcd/domain';
import {AppContextService, Feature, FeaturesService} from '@wcd/config';
import {catchError, map, tap} from 'rxjs/operators';
import {ApiCallModel, DataEntityType, Paris} from '@microsoft/paris';
import {I18nService} from '@wcd/i18n';
import {AuthService} from '@wcd/auth';
import {SourceFilterService} from '../../common/services/source-filter.service';
import {MachinesFiltersService, OsPlatformCategoriesFilter,} from '../../machines/services/machines.filters.service';
import {IncidentsBackendService} from './Incidents.backend.service';
import {isNil, omit, values} from 'lodash-es';
import {splitToSentenceCase} from '@wcd/shared';
import {AppInsightsService} from '../../../insights/services/app-insights.service';
import {EntityTagsService} from '../../common/services/entity-tags.service';
import {FilterValuesChecklistValueData} from '@wcd/ng-filters';

export const FILTER_PRIORITIES = {
	status: 0,
	severity: 10,
	incidentAssignment: 20,
	multipleAlert: 30,
	multipleServiceSource: 40,
	serviceSource: 50,
	tags: 55,
	multipleCategory: 60,
	category: 70,
	sensitivity: 80,
	rbacGroup: 90,
	osPlatform: 100,
	legacyIncidentTags: 110,
	classification: 120,
	investigationStates: 130,
	associatedThreats: 140,
	actorName: 150,
};

export interface DynamicFilter {
	queryName: string;
	filterName: string;
	feature: Feature;
	valuesMap: (result: any[]) => Array<FilterValuesChecklistValueData>;
}

const ASSOCIATED_THREATS_FILTER_NAME = 'IoaDefinitionIds';
const DYNAMIC_FILTERS_SELECTION = ['systemTag', 'customTag'];

@Injectable()
export class IncidentsFiltersService {
	private _incidentFilters: Record<string, any>;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private authService: AuthService,
		private featuresService: FeaturesService,
		private appContextService: AppContextService,
		private sourceFilterService: SourceFilterService,
		private machinesFiltersService: MachinesFiltersService,
		private incidentsBackendService: IncidentsBackendService,
		private entityTagsService: EntityTagsService,
		private appInsightsService: AppInsightsService
	) {}

	clearCache() {
		this._incidentFilters = null;
	}

	omitAssociatedThreatsFilter(filters: Record<string, any>): Record<string, any> {
		return omit(filters, ASSOCIATED_THREATS_FILTER_NAME);
	}

	getIncidentFilters(hideThreatsFilter: boolean = false): Observable<Record<string, any>> {
		return this.getIncidentFiltersInternal().pipe(
			map((filters) => (hideThreatsFilter ? this.omitAssociatedThreatsFilter(filters) : filters))
		);
	}

	private getIncidentFiltersInternal(): Observable<Record<string, any>> {
		if (this._incidentFilters) return of(this._incidentFilters);

		const filtersConfig: Record<string, any> = {
			severity: {
				count: null,
				values: (<DataEntityType>Severity).entityConfig.values
					.filter((severity: Severity) => severity.isSelectable)
					.map((severity: Severity) => {
						return {
							value: severity.id,
							name: this.i18nService.get(severity.nameI18nKey),
							count: null,
							priority: severity.priority,
						};
					}),
			},
			serviceSource: {
				count: null,
				values: this.sourceFilterService.getServiceSourceTreeFilterValues(),
			},
			AlertStatus: {
				count: null,
				values: (<DataEntityType>AlertStatus).entityConfig.values
					.filter((status: AlertStatus) => status.isSelectable)
					.map((status: AlertStatus) => {
						return {
							value: status.type,
							name: this.i18nService.get(status.nameI18nKey),
							count: null,
							priority: status.priority,
						};
					}),
			},
			classification: {
				count: null,
				values: (<DataEntityType>AlertClassification).entityConfig.values
					.filter((value) => value.id != AlertClassificationId.BenignPositive)
					.map((classification: AlertClassification) => {
						return {
							value: classification.type,
							name: this.i18nService.get(classification.nameI18nKey),
							count: null,
						};
					}),
			},
			osPlatform: {
				count: null,
				values: values(this.machinesFiltersService.osPlatformCategoriesMap).map(
					(osPlatform: OsPlatformCategoriesFilter) => {
						return {
							value: osPlatform.id,
							count: null,
						};
					}
				),
			},
			investigationStates: {
				count: null,
				values: (<DataEntityType>InvestigationStatus).entityConfig.values
					.filter((status: InvestigationStatus) => status.id >= 0)
					.filter((status: InvestigationStatus) =>
						this.featuresService.isEnabled(Feature.AirsApiOffloading)
							? !isNil(status.sevilleStatusId)
							: !status.isTempOffloadingStatus
					)
					.map((status: InvestigationStatus) => {
						return {
							value: status.id.toString(),
							name: status.name,
							count: null,
							priority: status.priority,
						};
					}),
			},
			incidentAssignment: {
				count: null,
				values: Object.keys(IncidentAssignmentFilterValues).map((incidentAssignmentKey: string) => ({
					value: incidentAssignmentKey,
					name: this.i18nService.get(
						IncidentAssignmentFilterValues[incidentAssignmentKey].nameI18nKey
					),
					count: null,
				})),
			},
		};

		const tagsDynamicFilters$: Observable<Array<Record<string, any>>> = this.paris
			.apiCall(GetIncidentQueueFiltersApiCall, DYNAMIC_FILTERS_SELECTION)
			.pipe(
				map((incidentQueueFilters: IncidentQueueFilters) => {
					const { systemTag, customTag } = incidentQueueFilters;
					return {
						tags: {
							count: null,
							values: this.entityTagsService.getIncidentsQueueTagsFilterValues([
								...systemTag.values,
								...customTag.values,
							]),
						} as Record<string, Array<FilterValuesChecklistValueData<string>>>,
					};
				}),
				catchError((err) => {
					this.appInsightsService.trackException(err);
					return of(null);
				})
			);

		const associatedThreatFilter$ = this.convertToFilter({
			observableOrApiCall: this.paris.getRepository<Outbreak>(Outbreak).allItems$.pipe(
				map((outbreaks) => {
					return outbreaks
						.filter((outbreak: Outbreak) => outbreak.ioaIds && outbreak.ioaIds.length > 0)
						.map((outbreak: Outbreak) => {
							return {
								value: outbreak.id,
								name: outbreak.displayName,
								count: null,
							};
						});
				})
			),
			filterName: ASSOCIATED_THREATS_FILTER_NAME,
			feature: Feature.ThreatAnalytics2,
		});

		const isMtp = this.appContextService.isMtp;
		const hasUpdatedIncidentQueue = this.featuresService.isEnabled(Feature.UpdatedIncidentQueue);
		const hasMtpFeatures = isMtp || hasUpdatedIncidentQueue;

		if (hasMtpFeatures) {
			filtersConfig.category = {
				count: null,
				values: (<DataEntityType>AlertCategory).entityConfig.values.map((category: AlertCategory) => {
					return {
						value: category.type,
						name: splitToSentenceCase(category.name),
						count: null,
					};
				}),
			};
		}

		if (hasUpdatedIncidentQueue && !isMtp) {
			// detection source filters is based on UpdatedIncidentQueue feature flag or on unifiedIncidentAlertQueue
			filtersConfig.detectionSource = {
				count: null,
				values: this.sourceFilterService.getDetectionSourcesFilterValues(),
			};
		}

		const filters$: Observable<Record<string, any>> = of(filtersConfig);

		const sensitivityFilter$: Observable<Record<string, any>> = this.convertToFilter({
			observableOrApiCall: IncidentSensitivityFilterValues,
			filterName: 'sensitivity',
			feature: Feature.IncidentSensitivity,
			valuesMap: (sensitivityLabels: Array<DataSensitivity>) => sensitivityLabels.map((s) => s.label),
		});

		const userExposedRbacGroupsFilter$ = this.convertToFilter({
			observableOrApiCall: this.machinesFiltersService.getUserExposedRbacGroups(),
			filterName: 'rbacGroup',
			feature: Feature.IncidentSensitivity,
		});

		return combineLatest([
			filters$,
			tagsDynamicFilters$,
			sensitivityFilter$,
			userExposedRbacGroupsFilter$,
			associatedThreatFilter$,
		]).pipe(
			map(
				([
					filters,
					dynamicFilters,
					sensitivityFilter,
					userExposedRbacGroupsFilter,
					associatedThreatFilter,
				]: [
					Record<string, any>,
					Record<string, any>,
					Record<string, any>,
					Record<string, any>,
					Record<string, any>
				]) =>
					Object.assign(
						{},
						filters,
						dynamicFilters,
						sensitivityFilter,
						userExposedRbacGroupsFilter,
						associatedThreatFilter
					)
			),
			tap((incidentFilters) => (this._incidentFilters = incidentFilters))
		);
	}

	private convertToFilter<T>(config: {
		observableOrApiCall: Observable<T[]> | (new () => ApiCallModel<Array<T>>);
		filterName: string;
		feature?: Feature;
		valuesMap?: (result: T[]) => string[];
	}): Observable<Record<string, any>> {
		const observable =
			typeof config.observableOrApiCall === 'function'
				? this.paris.apiCall(config.observableOrApiCall)
				: config.observableOrApiCall;
		return !config.feature || this.featuresService.isEnabled(config.feature)
			? observable.pipe(
					map((result: Array<T>) =>
						result && result.length
							? {
									[config.filterName]: {
										count: null,
										values: config.valuesMap ? config.valuesMap(result) : result,
									},
							  }
							: null
					),
					catchError((err) => {
						if (err.status !== 404) {
							this.appInsightsService.trackException(err);
						}
						return of(null);
					})
			  )
			: of(null);
	}
}
