/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./web-content-filtering-policy-new.component.ngfactory";
import * as i5 from "./web-content-filtering-policy-new.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i11 from "../../machines/services/machines.service";
import * as i12 from "../../../dialogs/services/dialogs.service";
import * as i13 from "../services/web-content-filtering-policy.service";
import * as i14 from "../../../shared/interfaces/disableable.interface";
import * as i15 from "../../../rbac/directives/rbac-control.directive";
import * as i16 from "../../../rbac/services/rbac-control.service";
import * as i17 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i18 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i19 from "./web-content-filtering-new.modal.component";
import * as i20 from "@angular/router";
var styles_WebContentFilteringPolicyNewModalComponent = [];
var RenderType_WebContentFilteringPolicyNewModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WebContentFilteringPolicyNewModalComponent, data: {} });
export { RenderType_WebContentFilteringPolicyNewModalComponent as RenderType_WebContentFilteringPolicyNewModalComponent };
export function View_WebContentFilteringPolicyNewModalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 11, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.closePanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, null, 1, 6, "web-content-filtering-policy-new", [], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.closePanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_WebContentFilteringPolicyNewComponent_0, i4.RenderType_WebContentFilteringPolicyNewComponent)), i0.ɵdid(6, 245760, null, 0, i5.WebContentFilteringPolicyNewComponent, [i6.Paris, i7.I18nService, i8.AuthService, i9.FeaturesService, i10.FlavorService, i11.MachinesService, i12.DialogsService, i0.ChangeDetectorRef, i13.WebContentFilteringPolicyService], null, { save: "save", cancel: "cancel" }), i0.ɵprd(2048, null, i14.DISABLEABLE_TOKEN, null, [i5.WebContentFilteringPolicyNewComponent]), i0.ɵdid(8, 4800512, null, 0, i15.RbacControlDirective, [i16.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i17.WicdSanitizerService, [6, i14.DISABLEABLE_TOKEN], i18.TooltipsService, i0.ViewContainerRef, i7.I18nService], { rbac: [0, "rbac"] }, null), i0.ɵpad(9, 1), i0.ɵpod(10, { permissions: 0, state: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); _ck(_v, 6, 0); var currVal_1 = _ck(_v, 10, 0, _ck(_v, 9, 0, "securitySettings"), "disabled"); _ck(_v, 8, 0, currVal_1); }, null); }
export function View_WebContentFilteringPolicyNewModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "web-content-filtering-policy-new-modal", [], null, null, null, View_WebContentFilteringPolicyNewModalComponent_0, RenderType_WebContentFilteringPolicyNewModalComponent)), i0.ɵdid(1, 245760, null, 0, i19.WebContentFilteringPolicyNewModalComponent, [i20.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WebContentFilteringPolicyNewModalComponentNgFactory = i0.ɵccf("web-content-filtering-policy-new-modal", i19.WebContentFilteringPolicyNewModalComponent, View_WebContentFilteringPolicyNewModalComponent_Host_0, { settings: "settings" }, {}, []);
export { WebContentFilteringPolicyNewModalComponentNgFactory as WebContentFilteringPolicyNewModalComponentNgFactory };
