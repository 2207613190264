import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { DataviewActionTypes } from './dataview-actions.model';

export interface DataviewActionPaginationConfig {
	onPageNumberChange: () => void;
	actionType: DataviewActionTypes;
}

@Component({
	selector: 'dataview-action-pagination',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-simple-pagination
			*ngIf="(dataViewModel.dataSet$ | async)"
			[total]="(dataViewModel.dataSet$ | async)?.count"
			[pageSize]="dataViewModel.pageSize$ | async"
			[className]="'command-bar-item-pagination'"
			[currentPageCount]="(dataViewModel.dataSet$ | async)?.items?.length"
			[page]="dataViewModel.page$ | async"
			[showOnSinglePage]="true"
			[shouldShowPageNumbers]="shouldShowPageNumbers"
			(pageChange)="onPageNumberChange($event.page)"
		>
		</wcd-simple-pagination>
	`,
})
export class DataviewActionPaginationComponent implements OnInit {
	@Input() paginationActionConfig: DataviewActionPaginationConfig;
	@Input() dataViewModel: DataViewModel;
	@Input() shouldShowPageNumbers?: boolean = false;

	onPageNumberChange: () => void;

	constructor(public i18nService: I18nService) {}

	ngOnInit() {
		this.onPageNumberChange = this.paginationActionConfig.onPageNumberChange;
	}
}
