import { Entity, EntityField, EntityModelBase, ModelBase, ValueObject } from '@microsoft/paris';
import { WcdPortalParisConfig } from '@wcd/domain';
import { HuntingTableColumnSchema } from './hunting-table-column-schema.value-object';

export type HuntingFunctionKind = 'user' | 'shared' | 'builtIn';

@ValueObject({
	pluralName: 'Input parameters of kusto function',
	singularName: 'Input parameter of kusto function',
	readonly: true,
})
export class KustoInputParameter extends ModelBase {
	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'CslType' })
	type: string;

	@EntityField({ data: 'DefaultValue' })
	defaultValue?: string;

	@EntityField({ data: 'Columns', arrayOf: KustoInputParameter })
	columns: Array<KustoInputParameter>;

	get isTabularParameter(): boolean {
		return !this.type;
	}
}

@Entity({
	pluralName: 'User defined kusto functions',
	singularName: 'User defined kusto function',
	endpoint: 'savedFunctions',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	saveMethod: ({ id }) => (id ? 'PATCH' : 'POST'),
	readonly: true,
})
export class HuntingFunctionSchema extends EntityModelBase<number> {
	@EntityField({ data: 'Id' })
	id: number;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Path', defaultValue: '' })
	path: string;

	@EntityField({ data: 'Description' })
	description?: string;

	@EntityField({ data: 'InputParameters', arrayOf: KustoInputParameter, defaultValue: [] })
	inputParameters: Array<KustoInputParameter>;

	@EntityField({ data: 'OutputSchemaStatements', serialize: false })
	outputSchemaStatements: string;

	@EntityField({
		data: 'OutputColumns',
		arrayOf: HuntingTableColumnSchema,
		serialize: false,
	})
	outputColumns: Array<HuntingTableColumnSchema>;

	@EntityField({
		data: '__self',
		parse: ({ IsShared, CreatedBy }) => (IsShared ? 'shared' : CreatedBy ? 'user' : 'builtIn'),
		serialize: false,
	})
	readonly kind: HuntingFunctionKind;

	@EntityField({ data: 'IsShared' })
	isShared: boolean;

	@EntityField({ data: 'Body' })
	body?: string;
}

export type HuntingFunction = HuntingFunctionSchema;
