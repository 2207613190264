export interface AlertCategoryValues {
	id: number;
	type: string;
	name: string;
	priority: number;
}

export enum AlertCategoryType {
	'CommandAndControl' = 'CommandAndControl',
	'LateralMovement' = 'LateralMovement',
	'Malware' = 'Malware',
	'Persistence' = 'Persistence',
	'PrivilegeEscalation' = 'PrivilegeEscalation',
	'Ransomware' = 'Ransomware',
	'SuspiciousActivity' = 'SuspiciousActivity',
	'UnwantedSoftware' = 'UnwantedSoftware',
	'Exploit' = 'Exploit',
	'InitialAccess' = 'InitialAccess',
	'Execution' = 'Execution',
	'Exfiltration' = 'Exfiltration',
	'Collection' = 'Collection',
	'CredentialAccess' = 'CredentialAccess',
	'DefenseEvasion' = 'DefenseEvasion',
	'Discovery' = 'Discovery',
	'Impact' = 'Impact'
}

export const alertCategoryValues: Array<AlertCategoryValues> = [
	{ id: 0, type: 'commandAndControl', name: AlertCategoryType.CommandAndControl, priority: 9 },
	{ id: 5, type: 'lateralMovement', name: AlertCategoryType.LateralMovement, priority: 12 },
	{ id: 6, type: 'malware', name: AlertCategoryType.Malware, priority: 14 },
	{ id: 7, type: 'persistence', name: AlertCategoryType.Persistence, priority: 6 },
	{ id: 8, type: 'privilegeEscalation', name: AlertCategoryType.PrivilegeEscalation, priority: 5 },
	{ id: 9, type: 'ransomware', name: AlertCategoryType.Ransomware, priority: 15 },
	{ id: 12, type: 'suspiciousActivity', name: AlertCategoryType.SuspiciousActivity, priority: 7 },
	{ id: 14, type: 'unwantedSoftware', name: AlertCategoryType.UnwantedSoftware, priority: 16 },
	{ id: 17, type: 'exploit', name: AlertCategoryType.Exploit, priority: 1 },
	{ id: 30, type: 'initialAccess', name: AlertCategoryType.InitialAccess, priority: 2 },
	{ id: 31, type: 'execution', name: AlertCategoryType.Execution, priority: 3 },
	{ id: 32, type: 'exfiltration', name: AlertCategoryType.Exfiltration, priority: 13 },
	{ id: 33, type: 'collection', name: AlertCategoryType.Collection, priority: 8 },
	{ id: 34, type: 'credentialAccess', name: AlertCategoryType.CredentialAccess, priority: 10 },
	{ id: 35, type: 'defenseEvasion', name: AlertCategoryType.DefenseEvasion, priority: 4 },
	{ id: 36, type: 'discovery', name: AlertCategoryType.Discovery, priority: 11 },
	{ id: 37, type: 'impact', name: AlertCategoryType.Impact, priority: 38 },
];
