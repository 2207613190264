import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { Lazy } from '@wcd/utils';
import { AuthService } from '@wcd/auth';
import { FlavorService } from '@wcd/config';
/**
 * Maps a `SearchRouteEntityTypeId` to `SearchEntityTypeId`.
 * Used for getting the relevant entity id from a search route.
 */
export var searchRouteIdEntityIdMap = {
    files: 'file',
    machines: 'machine',
    users: 'user',
    ip: 'ip',
    url: 'url',
    securityRecommendation: 'SecurityRecommendation',
    softwareInventory: 'software',
    vulnerability: 'vulnerability',
};
var MainSearchEntityTypesService = /** @class */ (function () {
    function MainSearchEntityTypesService(globalEntityTypesService, authService, flavorService) {
        var _this = this;
        this.globalEntityTypesService = globalEntityTypesService;
        this.authService = authService;
        this.flavorService = flavorService;
        this._allEntityTypes = new Lazy(function () {
            var globalEntityTypes = _this.globalEntityTypesService.entityTypes
                .filter(function (entityType) {
                if (!entityType.searchOptions)
                    return false;
                if (!entityType.searchOptions.requiredAllowedActions)
                    return true;
                if (entityType.searchOptions.flavorConfig && !_this.flavorService.isEnabled(entityType.searchOptions.flavorConfig))
                    return false;
                return _this.authService.currentUser.hasMdeAllowedUserRoleAction(entityType.searchOptions.requiredAllowedActions);
            })
                .sort(function (entity1, entity2) {
                return entity1.searchOptions.searchDropdownOrder - entity2.searchOptions.searchDropdownOrder;
            })
                .map(function (entityType) { return ({
                id: entityType.id,
                displayName: entityType.searchOptions.displayName,
                entityType: entityType.entity,
                getSearchParams: entityType.searchOptions.getSearchParams,
                resolveTypeFromSearchTerm: entityType.searchOptions.resolveTypeFromSearchTerm,
            }); });
            return globalEntityTypes;
        });
        this._defaultEntityTypes = new Lazy(function () {
            return _this._allEntityTypes.value.find(function (entityType) { return entityType.id === 'machine'; });
        });
    }
    Object.defineProperty(MainSearchEntityTypesService.prototype, "all", {
        get: function () {
            return this._allEntityTypes.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MainSearchEntityTypesService.prototype, "default", {
        get: function () {
            return this._defaultEntityTypes.value;
        },
        enumerable: true,
        configurable: true
    });
    return MainSearchEntityTypesService;
}());
export { MainSearchEntityTypesService };
