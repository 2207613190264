import {
	ElementRef,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	ViewEncapsulation,
	AfterViewInit,
	OnDestroy,
} from '@angular/core';
import { arc as d3Arc } from 'd3-shape';
import { select as d3Select, Selection as D3Selection, BaseType as D3BaseType } from 'd3-selection';
import * as ChartsUtils from '../charts.utils';
import { merge } from 'lodash-es';
import { GaugeNeedleGenerator, GaugeNeedleDatum } from './gauge.needle.generator';
import { Subscription, Observable } from 'rxjs';

export interface GaugeConfig {
	gaugeMargin: number;
	sections: Array<{ percent: number; label: string }>;
	labels: { show: boolean; needleLabelDistance: number };
	digital: { show: boolean; height: number };
	needleCircleRadius: number;
}

@Component({
	selector: 'wcd-gauge',
	template: '<div class="wcd-gauge"></div>',
	styleUrls: ['./gauge.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class GaugeComponent implements OnChanges, AfterViewInit, OnDestroy {
	@Input() config: GaugeConfig;
	@Input() percent: number;
	@Input() forceReRender$?: Observable<boolean>;

	private settings: GaugeConfig;
	private initialized: boolean = false;

	private rootEl: HTMLElement;
	private rootD3El: D3Selection<HTMLElement, {}, null, undefined>;
	private svgD3El: D3Selection<D3BaseType, {}, null, undefined>;
	private gGaugeAndLabels: D3Selection<D3BaseType, {}, null, undefined>;
	private gGauge: D3Selection<D3BaseType, {}, null, undefined>;
	private gLabels: D3Selection<D3BaseType, {}, null, undefined>;
	private width: number;
	private height: number;
	private gaugeOuterRadius: number;

	private _subscription: Subscription;

	constructor(protected elementRef: ElementRef) {}

	ngAfterViewInit() {
		this.initialized = true;

		this.rootEl = this.elementRef.nativeElement.querySelector('.wcd-gauge');
		this.rootD3El = d3Select(this.rootEl);

		this.drawInNextFrame(this.percent);

		this.forceReRender$ &&
			(this._subscription = this.forceReRender$.subscribe(() => {
				this.drawInNextFrame(this.percent);
			}));
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.config && changes.config.firstChange) {
			this.settings = merge({}, this.config);

			if (!this.settings.labels.show) {
				this.settings.gaugeMargin = 0;
				this.settings.labels.needleLabelDistance = 0;
			}
			if (!this.settings.digital.show) {
				this.settings.digital.height = 0;
			}
		}

		if (changes.percent) {
			this.drawInNextFrame(this.percent);
		}
	}

	ngOnDestroy() {
		if (this._subscription) this._subscription.unsubscribe();
	}

	private drawInNextFrame(percent: number) {
		setTimeout(() => {
			this.draw(this.percent);
		}, 0);
	}

	private draw(percent: number) {
		if (!this.initialized || percent < 0 || percent > 1 || percent === null || percent === undefined)
			return;

		this.rootD3El.html('');

		this.width = this.rootD3El['_groups'][0][0].offsetWidth;
		const gaugeAndLabelsHeight = this.width / 2 + this.settings.needleCircleRadius;
		this.height = gaugeAndLabelsHeight + this.settings.digital.height;
		this.gaugeOuterRadius = (this.width - 2 * this.settings.gaugeMargin) / 2;

		this.svgD3El = this.rootD3El
			.append('svg')
			.attr('width', this.width)
			.attr('height', this.height);

		this.gGaugeAndLabels = this.svgD3El
			.append('g')
			.attr(
				'transform',
				`translate(${this.width / 2}, ${gaugeAndLabelsHeight - this.settings.needleCircleRadius})`
			);

		this.gGauge = this.gGaugeAndLabels.append('g').attr('class', 'gauge');
		this.gLabels = this.gGaugeAndLabels.append('g').attr('class', 'labels');

		let relevantSectionIndex;
		const lastSectionIndex = this.settings.sections.length - 1;
		if (percent === this.settings.sections[lastSectionIndex].percent) {
			relevantSectionIndex = lastSectionIndex;
		} else {
			for (
				relevantSectionIndex = 1;
				relevantSectionIndex <= this.settings.sections.length;
				relevantSectionIndex++
			) {
				if (percent < this.settings.sections[relevantSectionIndex].percent) {
					break;
				}
			}
		}

		let currentPct = 0.75;
		const sectionPerc = percent / 2;
		const coloredArcStartRad = ChartsUtils.percToRad(currentPct);
		const coloredArcEndRad = coloredArcStartRad + ChartsUtils.percToRad(sectionPerc);
		currentPct += sectionPerc;

		//Add the arcs:
		const arcWidth = this.width * 0.13; //responsive, unconfigurable for now (no need)
		const gaugeInnerRadius = this.gaugeOuterRadius - arcWidth;
		// Colored arc:
		this.addArc(
			this.gGauge,
			this.gaugeOuterRadius,
			gaugeInnerRadius,
			coloredArcStartRad,
			coloredArcEndRad,
			`arc color-${relevantSectionIndex}`
		);
		// Inactive arc, unless we're at 100%:
		if (percent !== this.settings.sections[this.settings.sections.length - 1].percent) {
			const inactiveArcStartRad = ChartsUtils.percToRad(currentPct);
			const inactiveArcEndRad = ChartsUtils.percToRad(1.25);
			this.addArc(
				this.gGauge,
				this.gaugeOuterRadius,
				gaugeInnerRadius,
				inactiveArcStartRad,
				inactiveArcEndRad,
				`arc inactive-color`
			);
		}

		if (this.settings.labels.show) {
			this.addLabels();
		}

		if (this.settings.digital.show) {
			const gDigital = this.svgD3El
				.append('g')
				.attr('transform', `translate(${this.width / 2}, ${this.height})`);
			this.addDigital(gDigital, `digital color-${relevantSectionIndex}`);
		}

		this.addNeedle(this.gGaugeAndLabels);
	}

	private addLabels() {
		let currentPct = 0.5;
		this.settings.sections.forEach((section, idx) => {
			let sectionPerc = 0;
			let textXpos;
			let textYpos;
			if (idx === this.settings.sections.length - 1) {
				textXpos = this.gaugeOuterRadius + this.settings.labels.needleLabelDistance;
				textYpos = 0;
			} else {
				sectionPerc =
					(this.settings.sections[idx + 1].percent - this.settings.sections[idx].percent) / 2;
				textXpos =
					(this.gaugeOuterRadius + this.settings.labels.needleLabelDistance) *
					Math.cos(ChartsUtils.percToRad(currentPct));
				textYpos =
					(this.gaugeOuterRadius + this.settings.labels.needleLabelDistance) *
					Math.sin(ChartsUtils.percToRad(currentPct));
			}
			const label = this.settings.sections[idx]['label'];
			const textAnchor = currentPct < 0.75 ? 'end' : 'start';
			this.gLabels
				.append('text')
				.attr('x', textXpos)
				.attr('y', textYpos)
				.attr('text-anchor', textAnchor)
				.attr('dominant-baseline', 'baseline')
				.text(label);
			currentPct += sectionPerc;
		});
	}

	private addDigital(appendTo: D3Selection<D3BaseType, {}, null, undefined>, classes: string) {
		appendTo
			.append('text')
			.attr('x', 0)
			.attr('y', 0)
			.attr('text-anchor', 'middle')
			.attr('dominant-baseline', 'baseline')
			.attr('class', classes)
			.text(`${Math.round(this.percent * 100)}`)
			.append('tspan')
			.text('/100')
			.attr('class', 'inactive-color--text');
	}

	private addNeedle(appendTo: D3Selection<D3BaseType, {}, null, undefined>) {
		const needle = new GaugeNeedleGenerator();
		needle.generate(
			[
				{
					needleLength: this.gaugeOuterRadius,
					circleRadius: this.settings.needleCircleRadius,
					percent: this.percent / 2,
				} as GaugeNeedleDatum,
			],
			appendTo
		);
	}

	private addArc(
		d3Selection: D3Selection<D3BaseType, any, HTMLElement, any>,
		outerRadiusPX: number,
		innerRadiusPX: number,
		startAngleRAD: number,
		endAngleRAD: number,
		classes?: string
	) {
		const arc = d3Arc()
			.outerRadius(outerRadiusPX)
			.innerRadius(innerRadiusPX)
			.startAngle(startAngleRAD)
			.endAngle(endAngleRAD);

		d3Selection
			.append('path')
			.attr('class', classes)
			.attr('d', arc);
	}
}
