var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { IpsService } from '../services/ips.service';
import { ipReportModel } from './ip.overview.component';
var IpEntityComponent = /** @class */ (function (_super) {
    __extends(IpEntityComponent, _super);
    function IpEntityComponent(i18nService, featuresService, ipsService, router, route) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.ipsService = ipsService;
        _this.router = router;
        _this.route = route;
        _this.tabs = [];
        _this.featuresChangeSubscription = _this.featuresService.featureChanged$
            .pipe(filter(function (_a) {
            var featureId = _a.featureId;
            return featureId === Feature.UpgradeIpPage;
        }))
            .subscribe(function () {
            if (!featuresService.isEnabled(Feature.UpgradeIpPage)) {
                var params = _this.route.snapshot.queryParams;
                _this.router.navigateByUrl(_this.ipsService.getLegacyIpLink(_this.ip.id, new Date((params && params['to']) || Date.now())));
            }
        });
        _this.report = ipReportModel;
        return _this;
    }
    Object.defineProperty(IpEntityComponent.prototype, "ip", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    IpEntityComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs(this.ip);
    };
    IpEntityComponent.prototype.ngOnDestroy = function () {
        this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
    };
    IpEntityComponent.prototype.getTabs = function (ip) {
        var tabs = [
            {
                id: 'alerts',
                name: this.i18nService.get('ips.tabs.alerts'),
                routerLink: './alerts',
            },
            {
                id: 'observed',
                name: this.i18nService.get('ips.tabs.observed'),
                routerLink: './timeline',
            },
        ];
        tabs.unshift({
            id: 'overview',
            name: this.i18nService.get('ips.tabs.overview'),
            routerLink: './overview',
        });
        return tabs.map(function (tab) { return new TabModel(tab); });
    };
    return IpEntityComponent;
}(EntityComponentBase));
export { IpEntityComponent };
