import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineGroupSimpleRuleProperty } from './machine-group-simple-rule-property.entity';
import { MachineGroupSimpleRuleOperator } from './machine-group-simple-rule-operator.entity';

@ValueObject({
	singularName: 'Device Group Simple Rule',
	pluralName: 'Device Group Simple Rules',
})
export class MachineGroupSimpleRule extends ModelBase {
	@EntityField({ data: 'PropertyValue' })
	propertyValue: string;

	@EntityField({ data: 'OperatorType' })
	operator: MachineGroupSimpleRuleOperator;

	@EntityField({ data: 'Property' })
	property: MachineGroupSimpleRuleProperty;

	correlatedIndex: number;

	isDeleted = false;
}
