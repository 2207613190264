import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

export type PreviewExperienceContext = 'MdatpContext' | 'MtpContext';

@Entity({
	singularName: 'Preview experience settings',
	pluralName: 'Preview experience settings',
	endpoint: 'GetPreviewExperienceSetting',
	parseItemQuery: (itemId, entity, config, params = {}) =>
		`GetPreviewExperienceSetting?context=${params.context || 'MdatpContext'}`,
	parseSaveQuery: (value: Partial<PreviewExperienceSettings>) =>
		`SavePreviewExperienceSetting?context=${value.context || 'MdatpContext'}`,
	serializeItem: (value: PreviewExperienceSettings) => {
		return { IsOptIn: value.previewExperienceEnabled };
	},
	readonly: true,
	saveMethod: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.settings,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class PreviewExperienceSettings extends EntityModelBase {
	@EntityField({
		data: 'IsOptIn',
		parse: (value, data) =>
			data.SliceId === PreviewExperienceSlice.PublicPreview ||
			data.SliceId === PreviewExperienceSlice.GA ||
			data.SliceId === PreviewExperienceSlice.MtpPublicPreview ||
			data.SliceId === PreviewExperienceSlice.MtpGa
				? value
				: true,
		defaultValue: false,
	})
	previewExperienceEnabled: boolean;

	@EntityField({
		data: 'SliceId',
		parse: (value, data) =>
			value !== PreviewExperienceSlice.PublicPreview &&
			value !== PreviewExperienceSlice.GA &&
			value !== PreviewExperienceSlice.MtpPublicPreview &&
			value !== PreviewExperienceSlice.MtpGa,
		defaultValue: false,
	})
	forcedEnabled: boolean;

	@EntityField({
		data: 'SliceId',
	})
	sliceId: number;

	context?: PreviewExperienceContext;
}

enum PreviewExperienceSlice {
	PublicPreview = 40,
	MtpPublicPreview = 1040,
	GA = 100,
	MtpGa = 1100,
}
