var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { formatDate, getLocaleId } from "@angular/common";
import { LocaleConfigService } from './locale-config.service';
import { getTzString } from '../locale.utils';
import { sccHostService } from '@wcd/scc-interface';
import { FeaturesService, Feature } from "@wcd/config";
import * as i0 from "@angular/core";
import * as i1 from "./locale-config.service";
import * as i2 from "../../../../config/src/lib/services/features.service";
var ɵ0 = function (date) { return sccHostService.i18n.format(date, 'd') + ", " + sccHostService.i18n.format(date, 't'); }, ɵ1 = function (date) { return sccHostService.i18n.format(date, 'd') + ", " + sccHostService.i18n.format(date, 'T').replace(/ ([AP]M)/, "." + date.getMilliseconds().toString().padStart(3, '0') + " $1"); }, ɵ2 = function (date) { return sccHostService.i18n.format(date, 'D') + ", " + sccHostService.i18n.format(date, 'T'); }, ɵ3 = function (date) { return "" + sccHostService.i18n.format(date, 'd'); }, ɵ4 = function (date) { return "" + sccHostService.i18n.format(date, 'D'); }, ɵ5 = function (date) { return "" + sccHostService.i18n.format(date, 'D'); }, ɵ6 = function (date) { return "" + sccHostService.i18n.format(date, 't'); }, ɵ7 = function (date) { return "" + sccHostService.i18n.format(date, 'T'); }, ɵ8 = function (date) { return "" + sccHostService.i18n.format(date, 'm'); }, ɵ9 = function (date) { return "" + sccHostService.i18n.format(date, 'm'); }, ɵ10 = function (date) { return "" + sccHostService.i18n.format(date, 'd'); }, ɵ11 = function (date) { return sccHostService.i18n.format(date, 'd') + " " + sccHostService.i18n.format(date, 'T') + "." + date.getMilliseconds().toString().padStart(3, '0') + " $1`)}"; }, ɵ12 = function (date) { return "" + sccHostService.i18n.format(date, 'T').replace(/ ([AP]M)/, "." + date.getMilliseconds().toString().padStart(3, '0') + " $1"); }, ɵ13 = function (date) { return sccHostService.i18n.format(date, 'D') + " " + MDATPtoSCCDateFormat['h:mm:ss.SSS a'](date); }, ɵ14 = function (date) { return "" + sccHostService.i18n.format(date, 'F'); }, ɵ15 = function (date) { return "" + sccHostService.i18n.format(date, 'f'); };
var MDATPtoSCCDateFormat = {
    'short': ɵ0,
    'shortWithMillis': ɵ1,
    'medium': ɵ2,
    'shortDate': ɵ3,
    'mediumDate': ɵ4,
    'longDate': ɵ5,
    'shortTime': ɵ6,
    'mediumTime': ɵ7,
    'monthDayOnly': ɵ8,
    'MM/dd': ɵ9,
    'MM/dd/yyyy': ɵ10,
    'MM/dd/yyyy HH:mm:ss.SSS': ɵ11,
    'h:mm:ss.SSS a': ɵ12,
    'MMM d, y, h:mm:ss.SSS a': ɵ13,
    'longFullDateTime': ɵ14,
    'fullDateTime': ɵ15,
};
var DATE_TIME_FORMAT_OPTIONS = {
    year: "numeric",
    month: "numeric",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric"
};
var TzDateService = /** @class */ (function () {
    function TzDateService(localeConfigService, featuresService) {
        this.localeConfigService = localeConfigService;
        this.featuresService = featuresService;
    }
    TzDateService.prototype.format = function (value, format, timezone, locale) {
        format = format || 'mediumDate';
        locale = locale || this.localeConfigService.selectedLocale;
        var localeId;
        if (locale) {
            try {
                localeId = getLocaleId(locale);
            }
            catch (e) {
                // do nothing, will default to en-US;
            }
        }
        timezone =
            timezone ||
                (this.localeConfigService.isLocalTimeZone
                    ? null
                    : createTzStringForPipe(this.localeConfigService.selectedTimezone));
        if (sccHostService.isSCC &&
            this.featuresService.isEnabled(Feature.SccDateFormat) &&
            MDATPtoSCCDateFormat[format]) {
            return MDATPtoSCCDateFormat[format](value, format, localeId, timezone);
        }
        if (format !== 'shortWithMillis') {
            return formatDate(value, format, localeId, timezone);
        }
        var date = new Date(value);
        var options = timezone ? __assign({}, DATE_TIME_FORMAT_OPTIONS, { timeZone: moment.tz(timezone).format("z") }) : DATE_TIME_FORMAT_OPTIONS;
        return new Intl.DateTimeFormat(locale, options).format(date).replace(/( [AP]M)?$/, "." + date.getMilliseconds().toString().padStart(3, '0') + "$1");
    };
    TzDateService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TzDateService_Factory() { return new TzDateService(i0.ɵɵinject(i1.LocaleConfigService), i0.ɵɵinject(i2.FeaturesService)); }, token: TzDateService, providedIn: "root" });
    return TzDateService;
}());
export { TzDateService };
function createTzStringForPipe(tz) {
    return getTzString(tz * 60);
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
