import { Input, ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris, Entity } from '@microsoft/paris';
import {
	Asset,
	TvmEndPoint,
	SoftwareInstallationAgg,
	SecurityRecommendation,
	RecommendationCategory,
	SoftwareInstallationNetworkDevice,
	RecommendationInstalledNetworkDevicesRelationship,
} from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityType } from '../../models/entity-type.interface';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { DataviewField, DataViewConfig } from '@wcd/dataview';

@Component({
	selector: 'installed-assets-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './installed-assets.panel.html',
})
export class InstalledAssetsPanelComponent<T extends ModelBase> extends PanelContainer implements OnInit {
	entityType: EntityType<SoftwareInstallationAgg>;
	isExportEnabled: boolean;
	isNetworkGear: boolean;

	dataViewConfig: DataViewConfig;

	networkDeviceRelationshipRepository: RelationshipRepository<
		SecurityRecommendation,
		SoftwareInstallationNetworkDevice
	>;

	_repository: RelationshipRepository<T, SoftwareInstallationAgg>;
	_sourceValue: T;
	_installedAssetsTitle: string;
	_fields: Array<DataviewField<any>>;

	@Input()
	set sourceValue(value: T) {
		this._sourceValue = value;
	}
	get sourceValue(): T {
		return this._sourceValue;
	}

	@Input()
	set installedAssetsTitle(value: string) {
		this._installedAssetsTitle = value;
	}
	get installedAssetsTitle(): string {
		return this._installedAssetsTitle;
	}

	@Input()
	set repository(value: Function) {
		this._repository = this.paris.getRelationshipRepository(value);
	}

	constructor(
		private i18nService: I18nService,
		private tvmDownloadService: TvmDownloadService,
		private fieldsService: SecurityRecommendationFieldsService,
		private globalEntityTypesService: GlobalEntityTypesService,
		protected paris: Paris,
		private featuresService: FeaturesService,
		router: Router
	) {
		super(router);
		this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
	}

	ngOnInit() {
		if (this.sourceValue instanceof SecurityRecommendation) {
			this.isNetworkGear = this.sourceValue.category === RecommendationCategory.NetworkGear;
			this._fields = this.isNetworkGear
			? this.fieldsService.installedDevicesFields
			: this.fieldsService.installedAssetsFields;
			const allFieldsIds = this._fields.map(field => field.id);
			this.dataViewConfig = {
				id: `installedAssets(${allFieldsIds.join()})`, //must supply unique id for correct dataview caching purposes - unique id is based on the fields
			};
		} else {
			this._fields = this.fieldsService.installedAssetsFields;
			this.entityType = this.globalEntityTypesService.getEntityType(SoftwareInstallationAgg);
		}

		if (this.isNetworkGear) {
			this.networkDeviceRelationshipRepository = this.paris.getRelationshipRepository(
				RecommendationInstalledNetworkDevicesRelationship
			);
			if (this._sourceValue instanceof SecurityRecommendation) {
				this.networkDeviceRelationshipRepository.sourceItem = this._sourceValue;
			}
		}
	}

	exportCveData() {
		if (this.isNetworkGear) {
			this.tvmDownloadService.downloadCsvFromRelationshipRepository(
				this.networkDeviceRelationshipRepository,
				TvmEndPoint.Analytics,
				`${this.installedAssetsTitle} - ${this.i18nService.strings.tvm_common_installedDevices}`,
				undefined
			);
		} else {
			this.tvmDownloadService.downloadCsvFromRelationshipRepository(
				this._repository,
				TvmEndPoint.Analytics,
				`${this.installedAssetsTitle} - ${this.i18nService.strings.tvm_common_installedDevices}`,
				undefined
			);
		}
	}
}
