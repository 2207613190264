var _a;
import { PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { ExposureScoreCategory } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
var machineExposureLevelDisplayKeysMap = (_a = {},
    _a[ExposureScoreCategory.Low] = 'machineExposureLevel.low',
    _a[ExposureScoreCategory.Medium] = 'machineExposureLevel.medium',
    _a[ExposureScoreCategory.High] = 'machineExposureLevel.high',
    _a);
var MachineExposureLevelTypeDisplayTextPipe = /** @class */ (function () {
    function MachineExposureLevelTypeDisplayTextPipe(i18nService) {
        this.i18nService = i18nService;
        this.transform = memoize(this.transform);
    }
    MachineExposureLevelTypeDisplayTextPipe.prototype.transform = function (value) {
        if (!(value in machineExposureLevelDisplayKeysMap)) {
            return null;
        }
        return this.i18nService.get(machineExposureLevelDisplayKeysMap[value]);
    };
    return MachineExposureLevelTypeDisplayTextPipe;
}());
export { MachineExposureLevelTypeDisplayTextPipe };
