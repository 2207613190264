import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';
import { RemediatedThreat } from './remediated-threat.value-object';

@ValueObject({
	singularName: 'Remediated Threat Type',
	pluralName: 'Remediated Threat Types',
})
export class RemediatedThreatType extends ModelBase {
	@EntityField({ data: 'entity_type' })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'entities', arrayOf: RemediatedThreat })
	remediatedThreats: Array<RemediatedThreat>;
}
