import { FeaturesService, Feature } from '@wcd/config';
import {
	RecommendationExceptionStateValue,
	ExceptionJustification,
	RemediationType,
	RecommendationException,
	RecommendationExceptionAggregated,
	MdeUserRoleActionEnum,
	ExceptionType,
	AddMachineGroupsFilterQueryParam,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FabricIconNames } from '@wcd/scc-common';
import { DataEntityType, Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { Injectable } from '@angular/core';
import { of, Observable, Subject } from 'rxjs';
import { filter, flatMap, map, take } from 'rxjs/operators';
import { I18nService } from '../../../../../../../../../projects/i18n/src/public_api';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { ItemActionModel } from '../../../../../dataviews/models/item-action.model';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { TvmMachineGroupsFilterService } from '../../../../../tvm/services/tvm-machine-groups-filter.service';

@Injectable()
export class RecommendationExceptionService {
	private _recommendationExceptionFilters: Record<string, any>;
	private remediationTypesToIgnoreInFilter: RemediationType[];
	private isExceptionsPerRbacFeatureEnabled = false;
	private isCancelAllSelectedRemediationExceptionRbacsEnabled;

	rbacGroupIdToNameMap$: Observable<{ [id: number]: string }>;
	exceptionCancelled$: Subject<void> = new Subject();

	constructor(
		private i18nService: I18nService,
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private dialogsService: DialogsService,
		private entityPanelsService: EntityPanelsService,
		private tvmMachineGroupsFilterService: TvmMachineGroupsFilterService,
		private paris: Paris,
		featuresService: FeaturesService,
		rbacService: RbacService
	) {
		// TODO: update those values when supporting remediation of those types. VSTS 23421057
		this.remediationTypesToIgnoreInFilter = [RemediationType.AttentionRequired];
		this.getActions = this.getActions.bind(this);
		this.isExceptionsPerRbacFeatureEnabled =
			appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
		this.isCancelAllSelectedRemediationExceptionRbacsEnabled = featuresService.isEnabled(
			Feature.TvmCancelAllSelectedRemediationExceptionRbacs
		);
		this.rbacGroupIdToNameMap$ = rbacService.userExposedRbacGroups$.pipe(
			map(rbacGroups => {
				const machineGroupNameById: { [id: number]: string } = rbacGroups.reduce(
					(map, currMachineGroup) => {
						map[currMachineGroup.id] = currMachineGroup.name;
						return map;
					},
					{}
				);
				return machineGroupNameById;
			})
		);
	}

	getRbacGroupName(recommendationException: RecommendationException): Observable<string> {
		return this.rbacGroupIdToNameMap$.pipe(
			map(
				mapping =>
					mapping[recommendationException.rbacGroupId] || this.i18nService.get('notAvailable.short')
			),
			take(1)
		);
	}

	openRelatedExceptionsPanel(recommendationException: RecommendationException) {
		this.getRbacGroupName(recommendationException).subscribe(rbacGroupName => {
			const title = `${recommendationException.title} (${rbacGroupName})`;
			const recommendationExceptionCopy = { ...recommendationException, title: title };
			this.entityPanelsService.showEntity(RecommendationException, recommendationExceptionCopy, null, {
				back: {
					onClick: () => this.entityPanelsService.closeEntityPanel(RecommendationException),
				},
			});
		});
	}

	getActions(
		recommendationExceptions: Array<RecommendationException | RecommendationExceptionAggregated>
	): ReadonlyArray<ItemActionModel> | Observable<ReadonlyArray<ItemActionModel>> {
		if (recommendationExceptions.length !== 1) {
			return null;
		}

		const first = recommendationExceptions[0];
		let nameKey: string;
		let status: RecommendationExceptionStateValue;
		let userAllowedToEditExceptions = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.tvmExceptionHandling
		);
		let entityType: DataEntityType;

		if (first instanceof RecommendationExceptionAggregated) {
			const recommendationExceptionAggregated = first as RecommendationExceptionAggregated;

			if (!this.isCancelAllSelectedRemediationExceptionRbacsEnabled && !recommendationExceptionAggregated.isGlobalException) {
				return null;
			}
			status = recommendationExceptionAggregated.primaryStatus;
			nameKey = 'tvm.recommendationException.actions.cancelExceptionForAllDeviceGroups';

			if (recommendationExceptionAggregated.isGlobalException) {
				entityType = RecommendationException;
				userAllowedToEditExceptions = userAllowedToEditExceptions && this.authService.isGlobalAdmin;
			} else {
				entityType = RecommendationExceptionAggregated;
			}
		} else {
			entityType = RecommendationException;
			nameKey = 'tvm.recommendationException.actions.cancelException';
			status = (first as RecommendationException).status.value;
		}

		const isValidStatus =
			status === RecommendationExceptionStateValue.Active ||
			status === RecommendationExceptionStateValue.Suspended;
		let tooltip: string;
		if (isValidStatus) {
			if (userAllowedToEditExceptions) {
				tooltip = !this.appConfigService.isExposedToAllMachineGroups
					? this.i18nService.strings.tvm_recommendationException_notExposedToAllRbac
					: undefined;
			} else {
				tooltip = this.isExceptionsPerRbacFeatureEnabled
					? this.i18nService.strings.tvm_recommendationException_invalidRbacGlobal
					: this.i18nService.strings.tvm_recommendationException_invalidRbac;
			}
		} else {
			tooltip = this.i18nService.strings.tvm_recommendationException_canceledToolTip;
		}

		return [
			{
				id: 'cancelException',
				nameKey: nameKey,
				icon: FabricIconNames.ReceiptUndelivered,
				closeOnAction: true,
				disabled:
					!(
						this.isExceptionsPerRbacFeatureEnabled ||
						this.appConfigService.isExposedToAllMachineGroups
					) ||
					!userAllowedToEditExceptions ||
					!isValidStatus,
				tooltip: tooltip,
				method: (
					recommendationExceptions: Array<
						RecommendationException | RecommendationExceptionAggregated
					>
				) => {
					const updatedRecommendationException: Partial<
						RecommendationException | RecommendationExceptionAggregated
					> = {
						id: recommendationExceptions[0].id,
						status: {
							value: RecommendationExceptionStateValue.Cancelled,
						},
					};
					const repo = this.paris.getRepository(entityType);
					var selectedRbacs$;

					if (this.isCancelAllSelectedRemediationExceptionRbacsEnabled) {
						selectedRbacs$ = this.tvmMachineGroupsFilterService.machineGroupsFilter$
							.pipe(
								take(1),
								map(mgFilter => mgFilter.machineGroups),
								map(mgArr => mgArr.filter(mg => mg.isSelected).map(mg => mg.groupId))
							)
							.pipe(
								flatMap(mg =>
									repo.save(
										updatedRecommendationException,
										mg.length > 0
											? { params: { rbacGroups: `groups in (${mg.join()})` } }
											: undefined
									)
								)
							);
					} else {
						selectedRbacs$ = repo.save(updatedRecommendationException);
					}

					return selectedRbacs$
						.toPromise()
						.then(() =>
							this.dialogsService.showSnackbar({
								text: this.i18nService.get('tvm.recommendationException.pleaseWaitCancelled'),
							})
						)
						.then(() => this.exceptionCancelled$.next());
				},
				localRefreshOnResolve: true,
			},
		].map(itemActionConfig => new ItemActionModel(itemActionConfig));
	}

	getJustificationLabel(justification: ExceptionJustification): string {
		const justificationId =
			justification === ExceptionJustification.AcceptableRisk ? 'RiskAccepted' : justification;
		return this.i18nService.get(`tvm.recommendationException.justification.${justificationId}`);
	}

	getRelatedRecommendationId(
		recommendationException: RecommendationException | RecommendationExceptionAggregated,
		forNavigation = true
	) {
		return recommendationException.exceptionArgs.type === ExceptionType.ConfigurationChange
			? `${forNavigation ? 'sca-_-' : ''}${
					recommendationException.exceptionArgs.scaRecommendationArgs.scid
			  }`
			: `${forNavigation ? 'va-_-' : ''}${
					recommendationException.exceptionArgs.vaRecommendationArgs.productId
			  }`;
	}

	getCreationJustificationList() {
		return [
			ExceptionJustification.ThirdPartyControl,
			ExceptionJustification.AlternateMitigation,
			ExceptionJustification.AcceptableRisk,
			ExceptionJustification.PlannedRemediationPending,
		];
	}

	getFilterJustificationList() {
		return this.getCreationJustificationList();
	}

	getRecommendationExceptionFilters(): Observable<Record<string, any>> {
		if (!this._recommendationExceptionFilters) {
			this._recommendationExceptionFilters = {
				exceptionType: {
					count: null,
					values: Object.keys(RemediationType)
						.filter(
							type => this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0
						)
						.map(type => {
							return {
								value: type,
								name: this.i18nService.get(
									`tvm.remediationTask.type.${RemediationType[type]}`
								),
								count: null,
							};
						}),
				},
				status: {
					count: null,
					values: Object.keys(RecommendationExceptionStateValue).map(type => {
						return {
							value: type,
							name: this.i18nService.get(
								`tvm.recommendationException.status.${
									RecommendationExceptionStateValue[type]
								}`
							),
							count: null,
						};
					}),
				},
				exceptionJustification: {
					count: null,
					values: this.getFilterJustificationList().map(type => {
						return {
							value: type,
							name: this.getJustificationLabel(type),
							count: null,
						};
					}),
				},
			};
		}
		return of(
			Object.keys(this._recommendationExceptionFilters).reduce(
				(res, key) => Object.assign(res, { [key]: this._recommendationExceptionFilters[key] }),
				{}
			)
		);
	}
}
