import { from, isObservable, Observable, of } from 'rxjs';
import { isPromise } from 'rxjs/internal-compatibility';

export function toObservable<T>(value: T | PromiseLike<T> | Observable<T>): Observable<T> {
	return isObservable(value) ? value : isPromise(value) ? from(value) : of(value);
}

export function toPromise<T>(value: T | PromiseLike<T> | Observable<T>): PromiseLike<T> {
	return isPromise(value) ? value : isObservable(value) ? value.toPromise() : Promise.resolve(value);
}
