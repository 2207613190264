<div class="wcd-full-height wcd-padding-large-horizontal">

	<section class="wcd-padding-vertical">
		<incident-entity-details [entity]="incident"></incident-entity-details>
	</section>

	<wcd-collapsible-section
		*ngIf="isMtpEnabled && incident.impactedEntities"
		[noSideMargins]="true"
		[sectionId]="collapsibleID.ImpactedEntities"
		[label]="'incident.panel.impactedEntities' | i18n"
		(isExpandedChange)="$event && impactedEntities.updateTableHeaders()"
	>
		<impacted-entities-datatables
			#impactedEntities
			[openPanelForAllItems]="true"
			[showTitle]="false"
			[entities]="incident.impactedEntities">
		</impacted-entities-datatables>
	</wcd-collapsible-section>

	<wcd-collapsible-section
		[noSideMargins]="true"
		[sectionId]="collapsibleID.Alerts"
		[label]="('aadUsers.alerts.title' | i18n) + ' (' + incident.alertCount + ')'"
	>
		<!--[
		Added container with min-height to 1px - if the height is zero, no expansion will take place.
		incident-alerts has height=100%, which is being affected by the containers height.
		]-->
		<div [style.minHeight.px]="1">
			<div *ngIf="partialRelatedAlerts">
			<wcd-datatable
						   [items]="partialRelatedAlerts"
						   [columns]="fields"
						   class="wcd-flex-1"
						   [fixedTable]="true"
						   [allowResize]="false"
						   [selectEnabled]="false"
							[isItemSelectable]="isItemSelectable"
			[isItemClickable]="isItemSelectable">
			</wcd-datatable>
			<button *ngIf="incident.alertCount > maxAlerts" class="btn-inline btn-link no-padding" (click)="openAlerts()">{{'incident_alerts_view_all_alerts' | i18n }}</button>
			</div>

		</div>

	</wcd-collapsible-section>
</div>
