import { ReadOnlyIdentifiable } from '../../../data/models/readonly-identifiable.model';
import { MachineBackendDataBase, MachineBase } from './machine-base.interface';

export class MachineModel extends ReadOnlyIdentifiable<string> implements MachineBase {
	machineId: string;
	ip: string;
	group: string;
	os: MachineOs;
	domain: string;
	lastIp: string;
	lastExternalIp: string;
	firstSeen: Date;
	lastSeen: Date;

	constructor(data: MachineBackendData) {
		super(data);
	}

	setData(data: MachineBackendData) {
		super.setData(data);

		this.id = data.SenseMachineId;
		this.name = data.ComputerDnsName;
		this.os = {
			build: data.OsBuild,
			platform: data.OsPlatform,
			processor: data.OsProcessor,
			version: data.OsVersion || null,
		};

		this.group = data.MachineGroup;
		this.domain = data.Domain;
		this.lastIp = data.LastIpAddress;
		this.lastExternalIp = data.LastExternalIpAddress;
		this.firstSeen = data.FirstSeen ? new Date(data.FirstSeen) : null;
		this.lastSeen = data.LastSeen ? new Date(data.LastSeen) : null;
	}
}

export interface MachineBackendData extends MachineBackendDataBase {
	WcdMachineId?: string;
	SenseMachineId: string;
	InternalMachineId?: number;
	MachineId: string;
	MachineGuid: string;
	ComputerDnsName: string;
	FirstSeen?: string;
	LastSeen?: string;
	ComputerNetBIOSName?: string;
	OsPlatform?: string;
	OsVersion?: string;
	SystemManufacturer?: string;
	SystemProductName?: string;
	LastIpAddress?: string;
	LastExternalIpAddress?: string;
	OsProcessor?: string;
	IsDuplicate?: boolean;
	SenseMachineIdAlgorithm?: number;
	SenseClientVersion?: string;
	HealthStatus?: string;
	IsAadJoined?: boolean;
	OsBuild?: string;
	MachineGroup?: string;
	DefenderRunningMode?: string;
	SecurityScore?: number;
	Domain?: string;
	AlertMachinesMonitoringContexts?: any;
}

export interface MachineOs {
	build?: string;
	platform: string;
	processor?: string;
	version: string;
}

export enum MachineBackendIdType {
	Id = 'Id',
	SenseMachineId = 'SenseMachineId',
}

export enum DeviceCategory {
	Unknown = 'Unknown',
	Endpoint = 'Endpoint',
	IoT = 'IoT',
	NetworkDevice = 'NetworkDevice',
}
