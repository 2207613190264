/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../global_entities/components/entity-details/recommendation-exception-aggregated.entity-details.component.ngfactory";
import * as i2 from "../../../../../global_entities/components/entity-details/recommendation-exception-aggregated.entity-details.component";
import * as i3 from "../../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../../../tvm/services/tvm-texts.service";
import * as i5 from "@angular/router";
import * as i6 from "../services/recommendation-exception.service";
import * as i7 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../../../../global_entities/components/entity-details/recommendation-exception.entity-details.component.ngfactory";
import * as i9 from "../../../../../global_entities/components/entity-details/recommendation-exception.entity-details.component";
import * as i10 from "../../../../../shared/pipes/calculate-remaining-days.pipe";
import * as i11 from "@angular/common";
import * as i12 from "./recommendation-exception-aggregated.entity-panel.component";
var styles_RecommendationExceptionAggregatedEntityPanelComponent = [];
var RenderType_RecommendationExceptionAggregatedEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RecommendationExceptionAggregatedEntityPanelComponent, data: {} });
export { RenderType_RecommendationExceptionAggregatedEntityPanelComponent as RenderType_RecommendationExceptionAggregatedEntityPanelComponent };
function View_RecommendationExceptionAggregatedEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "recommendation-exception-aggregated-entity-details", [], null, null, null, i1.View_RecommendationExceptionAggregatedEntityDetailsComponent_0, i1.RenderType_RecommendationExceptionAggregatedEntityDetailsComponent)), i0.ɵdid(1, 245760, null, 0, i2.RecommendationExceptionAggregatedEntityDetailsComponent, [i3.ActivatedEntity, i4.TvmTextsService, i5.Router, i6.RecommendationExceptionService, i7.I18nService, i0.Injector], { recommendationExceptionAggregated: [0, "recommendationExceptionAggregated"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationExceptionAggregated; _ck(_v, 1, 0, currVal_0); }, null); }
function View_RecommendationExceptionAggregatedEntityPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "recommendation-exception-entity-details", [], null, null, null, i8.View_RecommendationExceptionEntityDetailsComponent_0, i8.RenderType_RecommendationExceptionEntityDetailsComponent)), i0.ɵdid(2, 245760, null, 0, i9.RecommendationExceptionEntityDetailsComponent, [i3.ActivatedEntity, i10.CalculateRemainingDaysPipe, i5.Router, i0.Injector, i4.TvmTextsService, i6.RecommendationExceptionService], { recommendationException: [0, "recommendationException"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.recommendationException; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_RecommendationExceptionAggregatedEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RecommendationExceptionAggregatedEntityPanelComponent_1)), i0.ɵdid(3, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["globalException", 2]], null, 0, null, View_RecommendationExceptionAggregatedEntityPanelComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isGlobalException; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_RecommendationExceptionAggregatedEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "recommendation-exception-aggregated-entity-panel", [], null, null, null, View_RecommendationExceptionAggregatedEntityPanelComponent_0, RenderType_RecommendationExceptionAggregatedEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i12.RecommendationExceptionAggregatedEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RecommendationExceptionAggregatedEntityPanelComponentNgFactory = i0.ɵccf("recommendation-exception-aggregated-entity-panel", i12.RecommendationExceptionAggregatedEntityPanelComponent, View_RecommendationExceptionAggregatedEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { RecommendationExceptionAggregatedEntityPanelComponentNgFactory as RecommendationExceptionAggregatedEntityPanelComponentNgFactory };
