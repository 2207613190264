var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { OperatingSystemPlatform } from './operating-system-platform.entity';
import { ReleaseVersion } from './release-version.entity';
import { OperatingSystemPlatformCategories } from '@wcd/domain';
var ɵ0 = function (processor) { return (processor === 'x32' ? 'x86' : processor); }, ɵ1 = function (isManagedByMdatp) { return isManagedByMdatp === false; };
var OperatingSystem = /** @class */ (function (_super) {
    __extends(OperatingSystem, _super);
    function OperatingSystem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(OperatingSystem.prototype, "platform", {
        get: function () {
            return this.osPlatformFriendlyName || (!this.isIot && this.osPlatform) || {
                id: this.osPlatformFriendlyNameString,
                name: this.osPlatformFriendlyNameString,
                categoryName: 'Other',
                category: OperatingSystemPlatformCategories.OtherPlatform,
                isBackendSupportedFilter: false
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperatingSystem.prototype, "osVersionFriendlyName", {
        get: function () {
            if ((this.osVersionFriendlyNameString === 'Other' && this.osPlatformFriendlyNameString === 'Other') ||
                // There is no official version name for WindowsServer2022, see https://docs.microsoft.com/en-us/windows/release-health/windows-server-release-info
                this.platform.category === OperatingSystemPlatformCategories.WindowsServer2022) {
                return null;
            }
            else {
                return this.osVersionFriendlyNameString;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(OperatingSystem.prototype, "fullBuild", {
        get: function () {
            return this.platform.category !== OperatingSystemPlatformCategories.macOS &&
                this.platform.category !== OperatingSystemPlatformCategories.Linux &&
                (this.build ? this.build + (this.revision ? '.' + this.revision : '') : '');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'OsBuild' }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "build", void 0);
    __decorate([
        EntityField({ data: 'OsRevision' }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "revision", void 0);
    __decorate([
        EntityField({ data: 'OsPlatform', required: true }),
        __metadata("design:type", OperatingSystemPlatform)
    ], OperatingSystem.prototype, "osPlatform", void 0);
    __decorate([
        EntityField({ data: 'OsPlatform', required: true }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "osPlatformString", void 0);
    __decorate([
        EntityField({ data: 'OsPlatformFriendlyName' }),
        __metadata("design:type", OperatingSystemPlatform)
    ], OperatingSystem.prototype, "osPlatformFriendlyName", void 0);
    __decorate([
        EntityField({ data: 'OsPlatformFriendlyName' }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "osPlatformFriendlyNameString", void 0);
    __decorate([
        EntityField({
            data: 'OsProcessor',
            parse: ɵ0,
        }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "processor", void 0);
    __decorate([
        EntityField({ data: 'OsVersion' }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "version", void 0);
    __decorate([
        EntityField({ data: 'OsVersionFriendlyName' }),
        __metadata("design:type", String)
    ], OperatingSystem.prototype, "osVersionFriendlyNameString", void 0);
    __decorate([
        EntityField({ data: 'ReleaseVersion' }),
        __metadata("design:type", ReleaseVersion)
    ], OperatingSystem.prototype, "releaseVersion", void 0);
    __decorate([
        EntityField({ data: 'IsManagedByMdatp', parse: ɵ1 }),
        __metadata("design:type", Boolean)
    ], OperatingSystem.prototype, "isIot", void 0);
    OperatingSystem = __decorate([
        ValueObject({
            singularName: 'Operating System',
            pluralName: 'Operating Systems',
            readonly: true,
        })
    ], OperatingSystem);
    return OperatingSystem;
}(ModelBase));
export { OperatingSystem };
export { ɵ0, ɵ1 };
