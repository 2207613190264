var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnInit, } from '@angular/core';
import { SuppressionRule, SuppressionRuleHistoryItem, SuppressionRulePredicateValueType, SuppressionRuleType, SuppressionRuleConditionType } from '@wcd/domain';
import { EntityDetailsComponentBase } from '../../../global_entities/components/entity-details/entity-details.component.base';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { SuppressionRulesBackendService } from '../services/suppression-rules.backend.service';
import { NgForm } from '@angular/forms';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { Paris } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { map } from 'rxjs/operators';
import { CommentModel } from '../../../comments/models/comment.model';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "suppression-rule-entity-details";
})(CollapsibleID || (CollapsibleID = {}));
var SuppressionRuleEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(SuppressionRuleEntityDetailsComponent, _super);
    function SuppressionRuleEntityDetailsComponent(injector, paris, changeDetectorRef, globalEntityTypesService, dialogsService, i18nService, suppressionRulesBackendService, appInsightsService, mtpPromotionService) {
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.suppressionRulesBackendService = suppressionRulesBackendService;
        _this.appInsightsService = appInsightsService;
        _this.mtpPromotionService = mtpPromotionService;
        _this.isSuppressionRuleEntityPanel = false;
        _this.urls = TryItUrls;
        _this.collapsibleID = CollapsibleID;
        _this.suppressionRuleType = SuppressionRuleType;
        _this.isSavingComment = false;
        _this.rulesRepo = paris.getRepository(SuppressionRule);
        _this.ruleHistoryItemsRepo = paris.getRepository(SuppressionRuleHistoryItem);
        _this.suppressionRuleConditionTypes = paris.getRepository(SuppressionRuleConditionType).entity.values;
        return _this;
    }
    SuppressionRuleEntityDetailsComponent.prototype.ngOnInit = function () {
        this.setCommentsHistory();
    };
    Object.defineProperty(SuppressionRuleEntityDetailsComponent.prototype, "rule", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SuppressionRuleEntityDetailsComponent.prototype, "isAdvancedRule", {
        get: function () {
            return this.rule.ruleType !== SuppressionRuleType.Default;
        },
        enumerable: true,
        configurable: true
    });
    SuppressionRuleEntityDetailsComponent.prototype.setMachineDetails = function () {
        this.machineLink = this.globalEntityTypesService.getRouteLinkModel('machine', this.rule.machine);
        this.machineName = this.globalEntityTypesService.getEntityName('machine', this.rule.machine);
    };
    SuppressionRuleEntityDetailsComponent.prototype.conditionalScopeText = function () {
        var machinesCount = 0;
        var usersCount = 0;
        var machineGroupCount = 0;
        this.rule.scopeConditions && this.rule.scopeConditions.forEach(function (group) { return group.forEach(function (condition) {
            switch (condition.valueType) {
                case SuppressionRulePredicateValueType.SenseMachineId:
                    machinesCount += condition.values.length;
                    break;
                case SuppressionRulePredicateValueType.UserSid:
                    usersCount += condition.values.length;
                    break;
                case SuppressionRulePredicateValueType.RbacGroupId:
                    machineGroupCount += condition.values.length;
                    break;
            }
        }); });
        var text = [];
        machinesCount && text.push(this.scopeText(machinesCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
        machineGroupCount && text.push(this.scopeText(machineGroupCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
        usersCount && text.push(this.scopeText(usersCount, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups'));
        return text.join();
    };
    SuppressionRuleEntityDetailsComponent.prototype.scopeText = function (numGroups, singularText, pluralText) {
        return numGroups ?
            numGroups != 1
                ? this.i18nService.get(pluralText, { numGroups: numGroups })
                : this.i18nService.get(singularText)
            : '';
    };
    SuppressionRuleEntityDetailsComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        this.setMachineDetails();
        this.changeDetectorRef.markForCheck();
    };
    SuppressionRuleEntityDetailsComponent.prototype.addCommentToRule = function (comment) {
        var _this = this;
        this.isSavingComment = true;
        var commentForSave = this.getCommentBackendData(comment);
        this.suppressionRulesBackendService.addCommentToRule(commentForSave).subscribe(function () {
            _this.isSavingComment = false;
            _this.suppressionRuleForm.form.markAsPristine();
            _this.appInsightsService.track({
                type: TrackingEventType.EntitySave,
                id: _this.rulesRepo.entity.singularName,
            });
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('suppressionRules.comment.saved'),
            });
            _this.setCommentsHistory();
        }, function (error) {
            _this.isSavingComment = false;
        });
    };
    SuppressionRuleEntityDetailsComponent.prototype.getCommentBackendData = function (comment) {
        return {
            ContextObjId: this.rule.id,
            NewValue: comment,
        };
    };
    SuppressionRuleEntityDetailsComponent.prototype.setCommentsHistory = function () {
        var _this = this;
        var ruleHistoryItemSubscriber = this.ruleHistoryItemsRepo
            .query({ where: { id: this.rule.id } }, { ignoreFieldsCasing: true })
            .pipe(map(function (dataSet) { return dataSet.items; }))
            .subscribe(function (items) {
            _this.history = items.map(function (ruleHistoryItem) {
                return new CommentModel({
                    id: ruleHistoryItem.id,
                    timestamp: ruleHistoryItem.timestamp,
                    user: ruleHistoryItem.userName,
                    message: ruleHistoryItem.message,
                    icon: ruleHistoryItem.type ? ruleHistoryItem.type.icon : 'Comment',
                });
            });
            _this.changeDetectorRef.detectChanges();
            ruleHistoryItemSubscriber.unsubscribe();
        });
    };
    return SuppressionRuleEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { SuppressionRuleEntityDetailsComponent };
