
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.mailCount">
				<ng-container *ngLet="(mailSubmission$ | async) as mailSubmission">
					<ng-container
						*ngIf="
							mailSubmission === LOADING_SYMBOL && emailClusterExtraDataEnabled;
							else detailedSubmissionField
						"
					>
						<div class="wcd-flex-center-all wcd-padding-all">
							<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
						</div>
					</ng-container>
					<ng-template #detailedSubmissionField>
						<ng-container *ngIf="mailSubmission && emailClusterExtraDataEnabled; else mailCount">
							<dt role="none">
								{{ i18nService.strings.airsEntities_emailCluster_fields_emailCount }}
							</dt>
							<dd role="none">{{ getMailSubmissionDetails(mailSubmission) }}</dd>
							<dt role="none">
								{{
									this.i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_mailSubmission_export
								}}
							</dt>
							<dd role="none">
								<button
									class="btn btn-link command-bar-item-button wcd-flex-center-vertical"
									data-track-type="Export"
									(click)="downloadMailSubmissionData(mailSubmission)"
									[wcdTooltip]="
										i18nService.strings
											.airsEntities_emailCluster_side_panel_view_fields_mailSubmission_export
									"
									#focusable
								>
									<wcd-shared-icon iconName="Download"></wcd-shared-icon>
								</button>
							</dd>
						</ng-container>
					</ng-template>
					<ng-template #mailCount>
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailCluster_fields_emailCount }}
						</dt>
						<dd role="none">{{ entity.mailCount }}</dd>
					</ng-template>
				</ng-container>
			</ng-container>
			<ng-container *ngLet="(actionLog$ | async) as actionLog">
				<ng-container *ngIf="actionLog === LOADING_SYMBOL; else actionLogField">
					<div class="wcd-flex-center-all wcd-padding-all">
						<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
					</div>
				</ng-container>
				<ng-template #actionLogField>
					<ng-container *ngIf="actionLog">
						<ng-container *ngIf="actionLog.isManualRerun">
							<dt role="none">
								{{
									i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_actionLogs_remediationRestarted
								}}
							</dt>
							<dd role="none">
								{{
									this.i18nService.get(
										'emailCluster_actionLog_manualRerun_' + actionLog.isManualRerun,
										null,
										true
									) || actionLog.isManualRerun
								}}
							</dd>
						</ng-container>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs
							}}
						</dt>
						<dd role="none">{{ getActionLogDetails(actionLog) }}</dd>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionsLogs_remediationId_field
							}}
						</dt>
						<dd role="none">{{ actionLog.remediationId }}</dd>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs_status
							}}
						</dt>
						<dd role="none">{{ actionLog.status }}</dd>
						<dt role="none">
							{{
								this.i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs_export
							}}
						</dt>
						<dd role="none">
							<button
								class="btn btn-link command-bar-item-button wcd-flex-center-vertical"
								data-track-type="Export"
								(click)="downloadActionLogData(actionLog)"
								[wcdTooltip]="
									i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_actionLogs_export
								"
								#focusable
							>
								<wcd-shared-icon iconName="Download"></wcd-shared-icon>
							</button>
						</dd>
					</ng-container>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="entity.entityName">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_name }}</dt>
				<dd role="none">{{ entity.entityName }}</dd>
			</ng-container>
			<ng-container *ngIf="!entity.showClusterAdditionalData; else clusterAdditionalData">
				<ng-container *ngIf="threatsDisplay">
					<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
					<dd role="none">{{ threatsDisplay }}</dd>
				</ng-container>
			</ng-container>

			<ng-template #clusterAdditionalData>
				<!-- Threats collapsible section-->
				<dt role="none">
					{{ i18nService.strings.airsEntities_emailCluster_fields_threats_sectionTitle }}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings.airsEntities_emailCluster_fields_threats_sectionTitle
						"
						[visible]="true"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container *ngIf="entity.emailClusterThreats">
								<dt role="none">
									{{ i18nService.strings.airsEntities_general_fields_threats }}
								</dt>
								<dd role="none">{{ threatsDisplay }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_malware }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.malwareCount || 0 }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_phish }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.phishCount || 0 }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_highConfidencePhish }}
								</dt>
								<dd role="none">
									{{ entity.emailClusterThreats.highConfidencePhishCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_spam }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.spamCount || 0 }}</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>

				<!-- Latest delivery locations collapsible section-->
				<dt role="none">
					{{
						i18nService.strings
							.airsEntities_emailCluster_fields_latestDeliveryLocations_sectionTitle
					}}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings
								.airsEntities_emailCluster_fields_latestDeliveryLocations_sectionTitle
						"
						[visible]="true"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container
								*ngIf="entity.emailClusterDeliveryLocations as emailClusterDeliveryLocations"
							>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_mailbox }}
								</dt>
								<dd role="none">{{ emailClusterDeliveryLocations.mailboxCount || 0 }}</dd>

								<dt role="none">
									{{ i18nService.strings.remediation_fields_notInMailbox }}
								</dt>
								<dd role="none">
									{{ emailClusterDeliveryLocations.notInMailboxCount || 0 }}
								</dd>

								<dt role="none">
									{{ i18nService.strings.remediation_fields_onPremOrExternal }}
								</dt>
								<dd role="none">
									{{ emailClusterDeliveryLocations.onPremOrExternalCount || 0 }}
								</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>

				<!-- Original delivery location collapsible section-->
				<dt role="none">
					{{
						i18nService.strings
							.airsEntities_emailCluster_fields_originalDeliveryLocations_sectionTitle
					}}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings
								.airsEntities_emailCluster_fields_originalDeliveryLocations_sectionTitle
						"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container
								*ngIf="
									entity.emailClusterOriginalDeliveryLocations as emailClusterOriginalDeliveryLocations
								"
							>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_delivered }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.deliveredCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_junked }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.junkedCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_replaced }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.replacedCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_blocked }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.blockedCount || 0 }}
								</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>
			</ng-template>
			<ng-container *ngIf="entity.volumeAnomaly">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_volumeAnomaly }}</dt>
				<dd role="none">{{ entity.volumeAnomaly }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.queryTime">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_queryTime }}</dt>
				<dd role="none">{{ entity.queryTime | date: 'short' }}</dd>
			</ng-container>
		</dl>
	