import { Component, OnDestroy, OnInit } from '@angular/core';
import { SystemExclusionsService } from '../services/system-exclusions.service';
import { merge, Subscription } from 'rxjs';
import { DataviewField } from '@wcd/dataview';
import { AuthService } from '@wcd/auth';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { DataViewConfig } from '@wcd/dataview';
import {
	SystemExclusion,
	SystemExclusionType,
	SystemExclusionTypeExclusionsRelationship,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { EntityEvent, Paris, RelationshipRepository } from '@microsoft/paris';
import { SystemExclusionBaseFields } from '../services/system-exclusion-base-fields.service';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { filter, tap } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { FeaturesService, Feature } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'system-exclusions-dataview',
	template: `
		<ng-container [ngSwitch]="true">
			<div class="wcd-full-height wcd-flex-vertical" *ngSwitchCase="!!tabsData">
				<div class="with-tabs wcd-flex-none wcd-padding-bottom wcd-padding-horizontal">
					<tabs [tabsData]="tabsData" [currentTab]="currentTabId"></tabs>
				</div>
				<dataview
					class="wcd-flex-1"
					*ngIf="currentSystemExclusionType"
					[fields]="fields"
					[id]="'system-exclusions-' + currentSystemExclusionType.id"
					[repository]="systemExclusionsRepository"
					[itemActions]="itemActions"
					[disableSelection]="!isUserAllowedActions"
					[dataViewConfig]="dataViewConfig"
					[isItemClickable]="isItemClickable"
					[allowAdd]="!currentSystemExclusionType.readonly && isUserAllowedActions"
					(onTableRenderComplete)="onTableRenderComplete()"
					(onNewItem)="addNewSystemExclusion()"
					(onItemClick)="
						systemExclusionsService.showEditSystemExclusionDialog(
							currentSystemExclusionType,
							$event.item
						)
					"
					[queueHeader]="true"
					[padLeft]="false"
					responsiveActionBar="true"
					responsiveLayout="true"
					[removePaddingRight]="isScc"
				>
					<p dataview-header class="wcd-padding-top">
						{{ 'systemExclusions.typeDescriptions.' + currentSystemExclusionType.id | i18n }}
					</p>
				</dataview>
			</div>
			<div class="wcd-full-height loading-overlay error-message" *ngSwitchCase="!!error">
				<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
				Error loading Allowed/Blocked list
			</div>
			<div class="wcd-full-height loading-overlay" *ngSwitchDefault>
				<i class="loader-icon wcd-margin-right"></i>
				Loading Allowed/Blocked list
			</div>
		</ng-container>
	`,
})
export class SystemExclusionsDataviewComponent implements OnInit, OnDestroy {
	tabsData: Array<TabModel>;
	currentTabId: string;
	currentSystemExclusionType: SystemExclusionType;
	dataViewConfig: DataViewConfig;
	fields: Array<DataviewField>;
	systemExclusionsRepository: RelationshipRepository<SystemExclusionType, SystemExclusion>;
	itemActions: Array<ItemActionModel>;
	error: any;
	isScc = sccHostService.isSCC;

	private _allSystemExclusionTypes: Array<SystemExclusionType>;
	private _systemExclusionTypeCountsSubscription: Subscription;
	private _systemExclusionsChangesSubscription: Subscription;
	private exclusionTypeSubscription: Subscription;

	constructor(
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private paris: Paris,
		private featuresService: FeaturesService,
		public systemExclusionsService: SystemExclusionsService,
		private systemExclusionBaseFields: SystemExclusionBaseFields,
		private performanceSccService: PerformanceSccService
	) {
		this.systemExclusionsRepository = paris.getRelationshipRepository(
			SystemExclusionTypeExclusionsRelationship
		);
		const allSystemExclusionTypes = this.paris.getRepository(SystemExclusionType).entity.values;
		this._allSystemExclusionTypes = allSystemExclusionTypes.filter(type => {
			if (
				this.featuresService.isEnabled(Feature.AclOffloadingPhase1) &&
				type.hideIfPhase1OffloadingEnabled
			) {
				return false;
			}
			if (
				this.featuresService.isEnabled(Feature.AclOffloadingPhase2) &&
				type.hideIfPhase2OffloadingEnabled
			) {
				return false;
			}
			return true;
		});
	}

	get isUserAllowedActions() {
		return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
	}

	ngOnInit() {
		this.route.params.subscribe((params: Params) => {
			this.ngOnDestroy();

			this.selectSystemExclusionType(params.systemExclusionType);
			this.setTabsWithCounts();
			this._systemExclusionsChangesSubscription = merge(this.paris.save$, this.paris.remove$)
				.pipe(
					filter((event: EntityEvent) => event.entity === SystemExclusion),
					// change dataview options so the data and filters are reloaded
					tap(() => this.setDataviewOptions())
				)
				.subscribe(
					() => this.setTabsWithCounts(),
					err => {
						this.error = err;
					}
				);
		});
	}

	ngOnDestroy() {
		this._systemExclusionTypeCountsSubscription &&
			this._systemExclusionTypeCountsSubscription.unsubscribe();
		this._systemExclusionsChangesSubscription && this._systemExclusionsChangesSubscription.unsubscribe();
		this.exclusionTypeSubscription && this.exclusionTypeSubscription.unsubscribe();
	}

	addNewSystemExclusion() {
		this.systemExclusionsService.showEditSystemExclusionDialog(this.currentSystemExclusionType);
	}

	setTabsWithCounts() {
		this._systemExclusionTypeCountsSubscription &&
			this._systemExclusionTypeCountsSubscription.unsubscribe();
		this._systemExclusionTypeCountsSubscription = this.systemExclusionsService
			.getSystemExclusionTypeCounts()
			.subscribe(
				counts => {
					this.setTabs(counts);
				},
				err => {
					this.error = err;
				}
			);
	}

	onTableRenderComplete(){
		this.performanceSccService.endNgPageLoadPerfSession('endpoints-process-memory-indicators');
	}

	private selectSystemExclusionType(systemExclusionTypeId) {
		const systemExclusionType: SystemExclusionType = this._allSystemExclusionTypes.find(
			(type: SystemExclusionType) => type.id === systemExclusionTypeId
		);
		if ((this.currentSystemExclusionType = systemExclusionType)) {
			this.systemExclusionsRepository.sourceItem = this.currentSystemExclusionType;
			this.itemActions = this.systemExclusionsService.getSystemExclusionsActions(
				this.currentSystemExclusionType
			);
			this.currentTabId = systemExclusionTypeId;
			this.setDataviewOptions();
		} else {
			this.router.navigate(['../' + this._allSystemExclusionTypes[0].id], {
				relativeTo: this.route,
				queryParams: {
					page: null,
					page_size: null,
					filters: null,
					search: null,
					ordering: null,
					fields: null,
				},
				queryParamsHandling: 'merge',
			});
		}
	}

	private setDataviewOptions() {
		this.fields = this.systemExclusionBaseFields.fields.concat(
			this.currentSystemExclusionType.properties.map(property => {
				return new DataviewField(
					Object.assign(
						{
							getDisplay: (item: SystemExclusion) => item.properties[property.id],
							className: 'nowrap wcd-text-overflow-medium-large',
						},
						property
					)
				);
			})
		);

		this.dataViewConfig = {
			id: 'systemExclusions_' + this.currentTabId,
			fixedFilterValues: { type: [this.currentSystemExclusionType.id] },
		};
	}

	isItemClickable(systemExclusion: SystemExclusion): boolean {
		return !systemExclusion.isReadonly;
	}

	private setTabs(counts: { [index: string]: number }) {
		this.tabsData = compact(
			this._allSystemExclusionTypes.map(systemExclusionType => {
				const value = counts[systemExclusionType.id.toString()];
				if (systemExclusionType.hideIfEmpty && !value) {
					return null;
				}

				return new TabModel({
					id: systemExclusionType.id.toString(),
					name: systemExclusionType.name,
					routerLink: `/preferences2/system_exclusions/${systemExclusionType.id}`,
					value: counts[systemExclusionType.id.toString()],
					icon: systemExclusionType.icon,
				});
			})
		);
	}
}
