import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { OutbreakEntityTypeService } from './services/outbreak.entity-type.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [OutbreakEntityTypeService],
})
export class OutbreakEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(OutbreakEntityTypeService);
	}
}
