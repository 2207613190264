import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Profile',
	pluralName: 'Profiles',
	endpoint: 'baseline/profiles',
	parseItemQuery: id => `baseline/profiles/${id}`,
	readonly: true,
})
export class BaselineProfileDetails extends EntityModelBase<string> {
	@EntityField() profileId: string;

	@EntityField() readonly name: string;

	@EntityField() readonly description: string;

	@EntityField() readonly benchmark: string;

	@EntityField() readonly version: string;

	@EntityField() readonly operatingSystem: string;

	@EntityField() readonly operatingSystemVersion: string;

	@EntityField() readonly compliantDevicesHistory: number[];

	@EntityField() readonly status: boolean;

	@EntityField() readonly complianceLevel: string;

	@EntityField() readonly settingsNumber: number;

	@EntityField() readonly createdBy: string;

	@EntityField() readonly lastUpdatedBy: string;

	@EntityField() readonly createdOnTimestampUTC: Date;

	@EntityField() readonly lastUpdateTimestampUTC: Date;

	@EntityField() readonly passedDevices: number;

	@EntityField() readonly totalDevices: number;

	@EntityField() readonly compliantDevices: number;

	@EntityField() readonly eTag: string;

	@EntityField() readonly checks: Array<string>;

	@EntityField() readonly rbacGroupIds: Array<number>;

	@EntityField() readonly rbacGroupNames: Array<string>;

	@EntityField() readonly deviceTags: Array<string>;
}
