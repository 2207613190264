var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
var MachineUserDetails = /** @class */ (function (_super) {
    __extends(MachineUserDetails, _super);
    function MachineUserDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(MachineUserDetails.prototype, "hasUsers", {
        get: function () {
            return this.loggedOnUsersCount !== 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineUserDetails.prototype, "isSingleUser", {
        get: function () {
            return (this.mostPrevalentUser &&
                (!this.leastPrevalentUser ||
                    (this.mostPrevalentUser.accountDomainName === this.leastPrevalentUser.accountDomainName &&
                        this.mostPrevalentUser.accountName === this.leastPrevalentUser.accountName)));
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'LastSeenUser' }),
        __metadata("design:type", LegacyUser)
    ], MachineUserDetails.prototype, "lastSeenUser", void 0);
    __decorate([
        EntityField({ data: 'MostPrevalentUser' }),
        __metadata("design:type", LegacyUser)
    ], MachineUserDetails.prototype, "mostPrevalentUser", void 0);
    __decorate([
        EntityField({ data: 'LeastPrevalentUser' }),
        __metadata("design:type", LegacyUser)
    ], MachineUserDetails.prototype, "leastPrevalentUser", void 0);
    __decorate([
        EntityField({ data: 'LoggedOnUsersCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetails.prototype, "loggedOnUsersCount", void 0);
    __decorate([
        EntityField({ data: 'InteractiveUsersCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetails.prototype, "interactiveUsersCount", void 0);
    __decorate([
        EntityField({ data: 'RemoteUsersCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetails.prototype, "remoteUsersCount", void 0);
    __decorate([
        EntityField({ data: 'InteractiveOrRemoteUsersCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetails.prototype, "interactiveOrRemoteUsersCount", void 0);
    __decorate([
        EntityField({ data: 'OtherUsersCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetails.prototype, "otherUsersCount", void 0);
    MachineUserDetails = __decorate([
        ValueObject({
            singularName: 'Device User Details',
            pluralName: 'Device User Details',
        })
    ], MachineUserDetails);
    return MachineUserDetails;
}(ModelBase));
export { MachineUserDetails };
