import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Mailbox } from '../mailbox/mailbox.entity';
import { Application } from '../application/application-entity';
import { MachineGroup } from '../rbac/machine-group.entity';

@ValueObject({
	singularName: 'Impacted Entity',
	pluralName: 'Impacted Entities',
	readonly: false,
})
export class ImpactedEntities extends ModelBase {
	@EntityField({ arrayOf: Machine, data: 'Machines' })
	machines?: Machine[];

	@EntityField({ arrayOf: MachineGroup, data: 'MachineGroups' })
	machineGroups?: MachineGroup[];

	@EntityField({ arrayOf: AadUser, data: 'Users' })
	users?: AadUser[];

	@EntityField({ arrayOf: Mailbox, data: 'Mailboxes' })
	mailboxes?: Mailbox[];

	@EntityField({ arrayOf: Application, data: 'Apps' })
	apps?: Application[];

	@EntityField({ data: 'MailboxCount', defaultValue: null })
	mailboxCount?: number;

	@EntityField({ data: 'MachineCount', defaultValue: null })
	machineCount?: number;

	@EntityField({ data: 'MachineGroupsCount', defaultValue: null })
	machineGroupsCount?: number;

	@EntityField({ data: 'UserCount', defaultValue: null })
	userCount?: number;

	@EntityField({ data: 'AppCount', defaultValue: null })
	appCount?: number;
}
