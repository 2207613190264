import { Injectable } from '@angular/core';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { Machine, Tag, SoftwareInstallation } from '@wcd/domain';
import { SoftwareInstallationEntityPanelComponent } from '../components/software-entity/software-installation.entity-panel.component';
import { MachineEntityTypeActionsService } from '../../../machines/services/machine.entity-type-actions.service';
import { FeaturesService, Feature } from '@wcd/config';
import { Paris, Repository } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { MachineEntityTypeService } from '../../../machines/services/machine.entity-type.service';
import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SoftwareInstallationEntityTypeService implements EntityTypeService<SoftwareInstallation> {
	repository: Repository<Machine>;
	machineEntity: EntityType<Machine>;

	constructor(
		paris: Paris,
		private readonly featuresService: FeaturesService,
		private readonly machinesEntityTypeActionsService: MachineEntityTypeActionsService,
		private readonly machineEntityTypeService: MachineEntityTypeService,
		private i18nService: I18nService
	) {
		this.repository = paris.getRepository(Machine);
		this.entityType.getActions = installations => {
			if (installations.length === 1) {
				return this.repository
					.getItemById(installations[0].assetId)
					.pipe(
						mergeMap(machine =>
							this.machinesEntityTypeActionsService.getEntityActions(
								[machine],
								machineEntityTypeService
							)
						)
					);
			}
		};
	}

	readonly entityType: EntityType<SoftwareInstallation> = {
		id: 'software-installation',
		entity: SoftwareInstallation,
		icon: FabricIconNames.System,
		getEntityName: (installation: SoftwareInstallation) => installation.assetName,
		singleEntityPanelComponentType: SoftwareInstallationEntityPanelComponent,
		getEntityDataviewLink: () => '/software-inventory',
		loadFullEntityInPanel: false,
		getActions: null,
		getEntitiesLinkText: () => this.i18nService.get('tvm_link_openDevicePage'),
		getEntitiesLink: (installations: ReadonlyArray<SoftwareInstallation>) => {
			if (installations.length === 1) {
				return this.featuresService.isEnabled(Feature.UpgradeMachinePage)
					? `/machines/${installations[0].assetId}`
					: `/_machine/${installations[0].assetId}`;
			}
		},
		getTags: (installations: SoftwareInstallation[]) => {
			const t = this.machineEntityTypeService.entityType.getTags;
			if (installations.length === 1) {
				return this.repository
					.getItemById(installations[0].assetId)
					.pipe(
						mergeMap(
							machine =>
								this.machineEntityTypeService.entityType.getTags([machine]) as Observable<
									ReadonlyArray<Tag>
								>
						)
					);
			}
		},
	};
}
