import { BehaviorSubject } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { SessionStorageService } from '@wcd/shared';

declare let InstallTrigger;

const BROWSER_ERROR_KEY = 'X-Bypass-Browser';

@Injectable({ providedIn: 'root' })
export class BrowserDetectionService implements OnDestroy {
	bypassed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
	private _isBypassedByUser: boolean;
	private _browserData: BrowserData;

	constructor(private sessionStorageService: SessionStorageService) {}

	get browserData(): BrowserData {
		if (this._browserData === undefined) this._browserData = this.detectBrowser();
		return this._browserData;
	}

	get supported(): boolean {
		return this.browserData.chrome || this.browserData.hexaditeReports;
	}

	get bypassedByUser(): boolean {
		if (this._isBypassedByUser === undefined)
			this._isBypassedByUser = !!this.sessionStorageService.getItem(BROWSER_ERROR_KEY);
		return this._isBypassedByUser;
	}

	/*
	 * set "bypassed by user" token on session storage and publish changes
	 */
	setBypassedByUser() {
		this._isBypassedByUser = true;
		this.sessionStorageService.setItem(BROWSER_ERROR_KEY, 'true');
		this.bypassed$.next(true);
	}

	private detectBrowser(): BrowserData {
		const browserData = {
			opera:
				(!!(<any>window).opr && !!(<any>window).opr.addons) ||
				!!(<any>window).opera ||
				navigator.userAgent.indexOf(' OPR/') >= 0,
			firefox: typeof InstallTrigger !== 'undefined',
			safari: Object.prototype.toString.call(HTMLElement).indexOf('Constructor') > 0,
			IE: /*@cc_on!@*/ false || !!(<any>document).documentMode,
			chrome: !!(<any>window).chrome && !!(<any>window).chrome.webstore,
			hexaditeReports: navigator.userAgent === 'Hexadite Reports',
			isEdge: false,
		};

		browserData.isEdge = !browserData.IE && !!(<any>window).StyleMedia;
		return browserData;
	}

	ngOnDestroy() {
		this.bypassed$.complete();
	}
}

export interface BrowserData {
	[browserName: string]: boolean;
}
