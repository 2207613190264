import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { CapacityState } from './custom-ti-indicator-capacity-state.enum';

@ValueObject({
	singularName: 'TI Indicators capacity',
	pluralName: 'TI Indicators capacities',
	readonly: true,
})
export class TiIndicatorsCapacity extends ModelBase {
	@EntityField({ data: ['limit'] })
	limit: number;

	@EntityField({ data: ['total'] })
	total: number;

	capacityAvailable: boolean;

	capacityState: CapacityState;

	constructor(data?) {
		super(data);

		this.capacityAvailable = !!((this.limit || this.limit === 0) && (this.total || this.total === 0));

		if (!this.capacityAvailable) {
			this.capacityState = CapacityState.Unavailable;
			return;
		}

		// check if capacity was not exceeded
		this.capacityAvailable = this.total < this.limit;
		this.capacityState = this.capacityAvailable ? CapacityState.Available : CapacityState.Exceeded;
	}
}
