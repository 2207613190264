import { Injectable } from '@angular/core';
import { LegacyUser, Machine } from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { FieldsService } from '../../../global_entities/models/entity-type.interface';
import { Lazy } from '@wcd/utils';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class LegacyUsersFieldsService implements FieldsService<LegacyUser> {
	constructor(
		private prettyNumberService: PrettyNumberService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private i18nService: I18nService
	) {}

	private readonly _fields = new Lazy<ReadonlyArray<DataviewField<LegacyUser>>>(() => {
		const fields: Array<DataviewFieldConfig<LegacyUser>> = [
			{
				id: 'user',
				name: this.i18nService.get('users_fields_user'),
				className: 'nowrap wcd-text-overflow-large',
				getDisplay: (user: LegacyUser) => user.fullName,
				icon: {
					fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(LegacyUser),
				},
				sort: { enabled: false },
				getLink: (user: LegacyUser) =>
					this.globalEntityTypesService.getEntityType(LegacyUser).getEntitiesLink([user]),
			},
			{
				id: 'lastSeen',
				name: this.i18nService.get('users_fields_lastSeen'),
				className: 'nowrap wcd-text-overflow-large',
				component: {
					type: TzDateComponent,
					getProps: (user: LegacyUser) => ({ date: user.lastSeen }),
				},
				sort: { enabled: false },
			},
			{
				id: 'loggedOnMachinesCount',
				name: this.i18nService.get('users_fields_TotalDevices'),
				getDisplay: (user: LegacyUser) =>
					this.prettyNumberService.prettyNumber(user.loggedOnMachinesCount),
				sort: { enabled: false },
				enabledByDefault: true,
			},
			{
				id: 'sid',
				name: this.i18nService.get('users_fields_SID'),
				getDisplay: (user: LegacyUser) => user.sid,
				sort: { enabled: false },
				enabledByDefault: false,
			},
		];

		return DataviewField.fromList<LegacyUser>(fields);
	});

	get fields(): ReadonlyArray<DataviewField<LegacyUser>> {
		return this._fields.value;
	}

	get fieldsWithIncident(): ReadonlyArray<DataviewField<LegacyUser>> {
		return this._fields.value;
	}
}
