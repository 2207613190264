var _a;
import { MachineRiskScoreType } from './machine-risk-score.models';
var ɵ0 = MachineRiskScoreType.None, ɵ1 = MachineRiskScoreType.Informational, ɵ2 = MachineRiskScoreType.Low, ɵ3 = MachineRiskScoreType.Medium, ɵ4 = MachineRiskScoreType.High;
export var machineRiskScoreValues = [
    {
        id: ɵ0,
        name: 'No known risks',
        nameI18nKey: 'machineRiskScore_None',
        priority: 5,
    },
    {
        id: ɵ1,
        name: 'Informational',
        nameI18nKey: 'machineRiskScore_Informational',
        priority: 4,
    },
    {
        id: ɵ2,
        name: 'Low',
        nameI18nKey: 'machineRiskScore_Low',
        priority: 3,
    },
    {
        id: ɵ3,
        name: 'Medium',
        nameI18nKey: 'machineRiskScore_Medium',
        priority: 2,
    },
    {
        id: ɵ4,
        name: 'High',
        nameI18nKey: 'machineRiskScore_High',
        priority: 1,
    },
];
export var riskScoreNumberToEntity = (_a = {},
    _a[0] = machineRiskScoreValues[0],
    _a[5] = machineRiskScoreValues[1],
    _a[10] = machineRiskScoreValues[2],
    _a[20] = machineRiskScoreValues[3],
    _a[30] = machineRiskScoreValues[4],
    _a);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
