var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SpinnerSize } from 'office-ui-fabric-react';
import { WebContentFilteringDomainFeedback, FeedbackPriority } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { finalize } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
var PRIORITY_VALUES = ['Low', 'Medium', 'High'];
var DisputeCategoryPanelComponent = /** @class */ (function (_super) {
    __extends(DisputeCategoryPanelComponent, _super);
    function DisputeCategoryPanelComponent(router, paris, dialogsService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this.priorityValues = Object.keys(FeedbackPriority).filter(function (key) { return isNaN(key) && FeedbackPriority[key] !== 0; });
        return _this;
    }
    DisputeCategoryPanelComponent.prototype.ngOnInit = function () {
        this.domain = this.domainName;
        this.categoryId = this.categoryIdAsInput;
        this.categoryName = this.categoryNameAsInput;
        this.currentPrioritySelected = this.priorityValues[0];
    };
    DisputeCategoryPanelComponent.prototype.updatePrioritySelected = function (newPriority) {
        this.currentPrioritySelected = newPriority;
    };
    DisputeCategoryPanelComponent.prototype.submitDispute = function () {
        var _this = this;
        this.isSending = true;
        this.paris
            .apiCall(WebContentFilteringDomainFeedback, {
            Comments: this.comments,
            Domain: this.domain,
            CategoryId: this.categoryId,
            CategoryName: this.categoryName,
            FeedbackPriority: FeedbackPriority[this.currentPrioritySelected]
        }).pipe(finalize(function () { return (_this.isSending = false); }))
            .subscribe(function () {
            _this.closePanel();
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('urls.fields.domain.disputeCategory.panel.submit.success'),
                icon: 'checkCircle',
                iconClassName: 'color-text-success',
            });
        }, function (error) {
            _this.closePanel();
            _this.dialogsService.showError({
                title: _this.i18nService.get('urls.fields.domain.disputeCategory.panel.submit.error'),
                data: error,
            });
        });
    };
    return DisputeCategoryPanelComponent;
}(PanelContainer));
export { DisputeCategoryPanelComponent };
