var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnChanges } from '@angular/core';
import { CertificateInfo, File } from '@wcd/domain';
import { isNil } from 'lodash-es';
export var CertificateInfoKind;
(function (CertificateInfoKind) {
    CertificateInfoKind[CertificateInfoKind["Unknown"] = 0] = "Unknown";
    CertificateInfoKind[CertificateInfoKind["Signed"] = 1] = "Signed";
    CertificateInfoKind[CertificateInfoKind["SignedInvalid"] = 2] = "SignedInvalid";
    CertificateInfoKind[CertificateInfoKind["Unsigned"] = 3] = "Unsigned";
    CertificateInfoKind[CertificateInfoKind["Revoked"] = 4] = "Revoked";
})(CertificateInfoKind || (CertificateInfoKind = {}));
var FileCertificateInfoComponent = /** @class */ (function () {
    function FileCertificateInfoComponent() {
        this.CertificateInfoKindEnum = CertificateInfoKind;
    }
    FileCertificateInfoComponent.prototype.ngOnChanges = function () {
        this.certificateInfo = FileCertificateInfoComponent.getCertificateInfo(this.file);
        this.setCertificateInfoKind();
    };
    FileCertificateInfoComponent.prototype.setCertificateInfoKind = function () {
        this.certificateInfoInitiliazed = false;
        if (this.dataEnriched === false && this.certificateInfo === null) {
            return;
        }
        // if certificateInfo.isValid is null or false the certificate is unknown
        // see https://dev.azure.com/microsoft/OS/_workitems/edit/26902432/
        if (this.certificateInfo === null || !this.certificateInfo.isValid) {
            this.certificateType = CertificateInfoKind.Unknown;
        }
        else if (this.certificateInfo.signer === null) {
            this.certificateType = CertificateInfoKind.Unsigned;
        }
        else {
            this.certificateType = CertificateInfoKind.Signed;
        }
        this.certificateInfoInitiliazed = true;
    };
    FileCertificateInfoComponent.getCertificateInfo = function (file) {
        if (isNil(file.certificateInfo)) {
            return null;
        }
        return __assign({}, file.certificateInfo);
    };
    return FileCertificateInfoComponent;
}());
export { FileCertificateInfoComponent };
