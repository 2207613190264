import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as Requests from '../machine-requests.models';
import { MachineCollectInvesigationPackageRequest } from './collect-investigation-request.value-object';

export type CancelCollectionInvestigationRawApiRequestBody = Requests.CancelApiRequestBody<
	'ForensicsRequest'
>;
export type CancelCollectionInvestigationParameters = Requests.CancelParameters;

@ApiCall({
	name: 'Cancel collect investigation package',
	endpoint: 'requests/cancelbyid',
	method: 'POST',
	type: MachineCollectInvesigationPackageRequest,
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		requestGuid,
		comment,
	}: CancelCollectionInvestigationParameters): HttpOptions<
		CancelCollectionInvestigationRawApiRequestBody
	> => ({
		params: {
			requestGuid: requestGuid,
			comment: comment,
		},
	}),
})
export class MachineCancelCollectInvestigationPackageApiCall extends ApiCallModel<
	MachineCollectInvesigationPackageRequest,
	CancelCollectionInvestigationParameters
> {}
