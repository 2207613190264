import { Injectable, ComponentRef, Type } from '@angular/core';
import { PanelContainer, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ModelBase, Paris } from '@microsoft/paris';
import {
	Vulnerability,
	MissingKbExposedAssetsRelationship,
	MissingKb,
	MissingKbRelatedVulnerabilitiesRelationship,
	SecurityRecommendation,
	RecommendationExposedAssetsRelationship,
	RecommendationRelatedVulnerabilitiesRelationship,
	VulnerabilityExposedAssetsRelationship,
	AssetRecommendation,
	AssetMissingKb,
	AssetMissingKbVulnerabilityRelationship,
	AssessmentJob,
	RecommendationInstalledAssetsRelationship,
	ChangeEvent,
	ChangeEventRelatedVulnerabilitiesRelationship,
	RecommendationContextType,
	AssetRecommendationContextRelationship,
	RecommendationContextKeyAndExposedMachinesCountRelationship,
	RecommendationContextKeyPanelType,
	MachineGroup,
	NetworkDevice,
	NetworkDeviceRelatedVulnerabilitiesRelationship,
	SoftwareVersion,
	SoftwareVersionInstalledAssetsRelationship,
	BaselineProfileConfiguration,
	BaselineProfileConfigurationsBaselineProfileDeviceRelationship,
} from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { RelatedCvesPanelComponent } from '../../../global_entities/components/entity-panels/related-cves.panel.component';
import { ExposedAssetsPanelComponent } from '../../../global_entities/components/entity-panels/exposed-assets.panel.component';
import { AssessmentJobEditPanelComponent } from '../authenticated-scans/components/assessment-job-edit-panel.component';
import { InstalledAssetsPanelComponent } from '../../../global_entities/components/entity-panels/installed-assets.panel.component';
import { RecommendationContextPropertiesPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-context-properties.panel.component';
import { Router } from '@angular/router';
import { RoutesService } from '@wcd/shared';
import { RecommendationContextKeyPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-context-key.panel.component';
import { RelatedExceptionsPanelComponent } from '../../../global_entities/components/entity-panels/related-exceptions.panel.component';
import { MachineGroupDataviewPanelComponent } from '../../../global_entities/components/entity-panels/machine-group-dataview.panel.component';
import { I18nService } from '@wcd/i18n';
import { RecommendationWorkaroundsPanelComponent } from '../../../global_entities/components/entity-panels/recommendation-workarounds.panel.component';
import { DisputeCategoryPanelComponent } from '../../../global_entities/components/entity-panels/dispute-category.panel.component';

@Injectable()
export class SidePanelService {
	private _exposedAssetsPanel: ComponentRef<PanelContainer>;
	private _installedAssetsPanel: ComponentRef<PanelContainer>;
	private _relatedCvesPanel: ComponentRef<PanelContainer>;
	private _editNetworkScanPanel: ComponentRef<PanelContainer>;
	private _relatedExceptionsPanel: ComponentRef<PanelContainer>;
	private _recommendationContextKeyAndExposedMachinesPanel: ComponentRef<PanelContainer>;
	private _recommendationContextKeyAndPropertiesPanel: ComponentRef<PanelContainer>;
	private _recommendationContextPropertiesPanel: ComponentRef<PanelContainer>;
	private _machineGroupDataviewPanel: ComponentRef<PanelContainer>;
	private _workaroundPanel: ComponentRef<PanelContainer>;
	private _disputeCategoryPanel: ComponentRef<PanelContainer>;

	private commonPanelConfig = {
		isModal: true,
		showOverlay: false,
		hasCloseButton: true,
		type: PanelType.large,
		noBodyPadding: true,
		scrollBody: true,
		isBlocking: false,
	};
	private readonly securityRecommendationRelationshipMap = new Map([
		['Assets', RecommendationExposedAssetsRelationship],
		['Installations', RecommendationInstalledAssetsRelationship],
	]);

	constructor(
		private dialogsService: DialogsService,
		private tvmTextsService: TvmTextsService,
		private paris: Paris,
		private router: Router,
		private routesService: RoutesService,
		private i18nService: I18nService
	) {}

	private getEntitiesMapping(
		entity: ModelBase,
		panel: 'Assets' | 'Installations' | 'Vulnerabilities'
	): { title: string; relationshipRepository: any } {
		if (entity instanceof AssetMissingKb) {
			if (panel === 'Vulnerabilities') {
				return {
					title: entity.vendorBulletin,
					relationshipRepository: AssetMissingKbVulnerabilityRelationship,
				};
			}
		} else if (entity instanceof SecurityRecommendation || entity instanceof AssetRecommendation) {
			const title = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, entity);
			return {
				title: title,
				relationshipRepository:
					this.securityRecommendationRelationshipMap.get(panel) ||
					RecommendationRelatedVulnerabilitiesRelationship,
			};
		} else if (entity instanceof Vulnerability) {
			if (panel === 'Assets') {
				return {
					title: entity.id,
					relationshipRepository: VulnerabilityExposedAssetsRelationship,
				};
			}
		} else if (entity instanceof MissingKb) {
			return {
				title: entity.vendorBulletin,
				relationshipRepository:
					panel === 'Assets'
						? MissingKbExposedAssetsRelationship
						: MissingKbRelatedVulnerabilitiesRelationship,
			};
		} else if (entity instanceof ChangeEvent) {
			return {
				title: this.tvmTextsService.getText(TextToken.ChangeEventActivity, entity),
				relationshipRepository:
					panel === 'Assets' ? null : ChangeEventRelatedVulnerabilitiesRelationship,
			};
		} else if (entity instanceof NetworkDevice) {
			return {
				title: entity.ipAddress,
				relationshipRepository: NetworkDeviceRelatedVulnerabilitiesRelationship,
			};
		} else if (entity instanceof SoftwareVersion) {
			return {
				title: entity.version,
				relationshipRepository: SoftwareVersionInstalledAssetsRelationship,
			};
		}
	}

	showAllExposedAssetsPanel(
		entity: ModelBase,
		isNetworkGear?: boolean,
		recommendationContextType?: RecommendationContextType
	) {
		const exposedAssetsTitle = this.getEntitiesMapping(entity, 'Assets').title;
		this.dialogsService
			.showPanel(
				ExposedAssetsPanelComponent,
				{
					id: 'exposed-assets-panel',
					headerText: exposedAssetsTitle,
					...this.commonPanelConfig,
					back: { onClick: () => this._exposedAssetsPanel && this._exposedAssetsPanel.destroy() },
				},
				{
					sourceValue: entity,
					exposedAssetsTitle,
					repository: this.getEntitiesMapping(entity, 'Assets').relationshipRepository,
					isNetworkGear: isNetworkGear,
					recommendationContextType: recommendationContextType,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._exposedAssetsPanel = panel;

				panel.onDestroy(() => {
					this._exposedAssetsPanel = null;
				});
			});
	}

	showAllInstalledAssetsPanel(entity: ModelBase) {
		const installedAssetsTitle = this.getEntitiesMapping(entity, 'Installations').title;
		this.dialogsService
			.showPanel(
				InstalledAssetsPanelComponent,
				{
					id: 'installed-assets-panel',
					headerText: installedAssetsTitle,
					...this.commonPanelConfig,
					back: {
						onClick: () => this._installedAssetsPanel && this._installedAssetsPanel.destroy(),
					},
				},
				{
					installedAssetsTitle,
					sourceValue: entity,
					repository: this.getEntitiesMapping(entity, 'Installations').relationshipRepository,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._installedAssetsPanel = panel;

				panel.onDestroy(() => {
					this._installedAssetsPanel = null;
				});
			});
	}

	navigateToUrlInContext(url: string, queryParams: any = {}): Promise<boolean> {
		const mdatpFromSccUrl = this.routesService.getMdatpFromSccLinkConfig(url);
		const updatedUrl = mdatpFromSccUrl ? mdatpFromSccUrl.url : url;
		if (this.routesService.shouldOpenExternally(mdatpFromSccUrl)) {
			window.open(updatedUrl);
		} else {
			return this.router.navigate([updatedUrl], { queryParams: queryParams });
		}
	}

	showAllRelatedCvesPanel(
		entity: ModelBase,
		includeExposedMachinesComponent: boolean = true,
		isNetworkGearCve?: boolean
	) {
		const repository = this.paris.getRelationshipRepository(
			this.getEntitiesMapping(entity, 'Vulnerabilities').relationshipRepository
		);
		const relatedCvesTitle = this.getEntitiesMapping(entity, 'Vulnerabilities').title;
		repository.sourceItem = entity;
		this.dialogsService
			.showPanel(
				RelatedCvesPanelComponent,
				{
					id: 'related-cves-panel',
					headerText: relatedCvesTitle,
					...this.commonPanelConfig,
					back: { onClick: () => this._relatedCvesPanel && this._relatedCvesPanel.destroy() },
				},
				{
					sourceValue: entity,
					relatedCvesTitle,
					repository: repository,
					includeExposedMachinesComponent: includeExposedMachinesComponent,
					isNetworkGearCve: isNetworkGearCve,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._relatedCvesPanel = panel;

				panel.onDestroy(() => {
					this._relatedCvesPanel = null;
				});
			});
	}

	showAllRelatedExceptionsPanel(entity: ModelBase) {
		this.dialogsService
			.showPanel(
				RelatedExceptionsPanelComponent,
				{
					id: 'related-exceptions-panel',
					...this.commonPanelConfig,
					back: {
						onClick: () => this._relatedExceptionsPanel && this._relatedExceptionsPanel.destroy(),
					},
				},
				{
					sourceItem: entity,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._relatedExceptionsPanel = panel;

				panel.onDestroy(() => {
					this._relatedExceptionsPanel = null;
				});
			});
	}

	showRecommendationContextKeyAndExposedMachinesCountPanel(
		entity: ModelBase,
		recommendationContextType: RecommendationContextType
	) {
		const recommendationCtxKeyTitle = this.getEntitiesMapping(entity, 'Assets').title;
		this.dialogsService
			.showPanel(
				RecommendationContextKeyPanelComponent,
				{
					id: 'recommendation-context-key-and-exposed-machines-panel',
					headerText: recommendationCtxKeyTitle,
					...this.commonPanelConfig,
					back: {
						onClick: () =>
							this._recommendationContextKeyAndExposedMachinesPanel &&
							this._recommendationContextKeyAndExposedMachinesPanel.destroy(),
					},
				},
				{
					panelType: RecommendationContextKeyPanelType.EXPOSED_MACHINES_COUNT,
					securityRecommendation: entity,
					recommendationCtxKeyTitle,
					panelSubTitle: this.tvmTextsService.recommendationContextToExposedSectionTitle[
						recommendationContextType
					],
					repository: this.paris.getRelationshipRepository(
						RecommendationContextKeyAndExposedMachinesCountRelationship
					),
					recommendationContextType: recommendationContextType,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._recommendationContextKeyAndExposedMachinesPanel = panel;

				panel.onDestroy(() => {
					this._recommendationContextKeyAndExposedMachinesPanel = null;
				});
			});
	}

	showRecommendationContextKeyAndPropertiesPanel(
		entity: ModelBase,
		recommendationContextType: RecommendationContextType
	) {
		const recommendationCtxKeyTitle = this.getEntitiesMapping(entity, 'Assets').title;
		this.dialogsService
			.showPanel(
				RecommendationContextKeyPanelComponent,
				{
					id: 'recommendation-context-key-and-properties-panel',
					headerText: recommendationCtxKeyTitle,
					...this.commonPanelConfig,
					back: {
						onClick: () =>
							this._recommendationContextKeyAndPropertiesPanel &&
							this._recommendationContextKeyAndPropertiesPanel.destroy(),
					},
				},
				{
					panelType: RecommendationContextKeyPanelType.RECOMMENDATION_CONTEXT_PROPERTIES,
					recommendationCtxKeyTitle,
					panelSubTitle: this.tvmTextsService.recommendationContextToExposedSectionTitle[
						recommendationContextType
					],
					repository: this.paris.getRelationshipRepository(AssetRecommendationContextRelationship),
					recommendationContextType: recommendationContextType,
					securityRecommendation: entity,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._recommendationContextKeyAndPropertiesPanel = panel;

				panel.onDestroy(() => {
					this._recommendationContextKeyAndPropertiesPanel = null;
				});
			});
	}

	showRecommendationContextPropertiesPanel(
		securityRecommendation: SecurityRecommendation,
		recommendationContextKey: any,
		recommendationContextType: RecommendationContextType
	) {
		this.dialogsService
			.showPanel(
				RecommendationContextPropertiesPanelComponent,
				{
					id: 'recommendation-context-properties-panel',
					...this.commonPanelConfig,
					back: {
						onClick: () =>
							this._recommendationContextPropertiesPanel &&
							this._recommendationContextPropertiesPanel.destroy(),
					},
				},
				{
					securityRecommendation: securityRecommendation,
					recommendationContextKey: recommendationContextKey,
					recommendationContextType: recommendationContextType,
				}
			)
			.subscribe((panel: ComponentRef<RecommendationContextPropertiesPanelComponent>) => {
				this._recommendationContextPropertiesPanel = panel;
				panel.onDestroy(() => {
					this._recommendationContextPropertiesPanel = null;
				});
			});
	}

	showAuthenticatedAssessmentJobWizard(assessmentJob?: AssessmentJob, readOnly?: boolean) {
		this.dialogsService.showPanel(
			AssessmentJobEditPanelComponent,
			{
				id: 'authenticated-scan-add-panel',
				...this.commonPanelConfig,
				isBlocking: true,
				showOverlay: true,
				disableOverlayClick: true,
				hasCloseButton: false,
				type: PanelType.wizard,
			},
			{
				assessmentJob: assessmentJob,
				readOnly: readOnly,
			}
		);
	}

	//TODO: (by: jomok) look into making the doneCallBack (that sends the machine groups back to the caller) be passed to the panel itself, and let him handle it.
	showRbacGroupsDataviewPanel(
		preselectedMachineGroups: number[],
		manuallySelectedMachineGroups: number[],
		hideMachineGroups: number[],
		dataviewTitle: string,
		containsFilteredGroups: boolean,
		doneCallBack: (groups: Array<MachineGroup>) => void
	) {
		return this.dialogsService
			.showPanel(
				MachineGroupDataviewPanelComponent,
				{
					id: 'machine-group-dataview-panel',
					...this.commonPanelConfig,
					back: {
						onClick: () =>
							this._machineGroupDataviewPanel && this._machineGroupDataviewPanel.destroy(),
					},
				},
				{
					preselectedMachineGroups: preselectedMachineGroups,
					manuallySelectedMachineGroups: manuallySelectedMachineGroups,
					hideMachineGroups: hideMachineGroups,
					dataviewTitle: dataviewTitle,
					containsFilteredGroups: containsFilteredGroups,
				}
			)
			.subscribe((panel: ComponentRef<MachineGroupDataviewPanelComponent>) => {
				this._machineGroupDataviewPanel = panel;
				panel.instance.onSaveGroups.subscribe((machineGroups: Array<MachineGroup>) => {
					this._machineGroupDataviewPanel.instance.closePanel();
					doneCallBack(machineGroups);
				});
				panel.onDestroy(() => {
					this._machineGroupDataviewPanel = null;
				});
			});
	}

	showWorkaroundSidePanel(securityRecommendation: SecurityRecommendation, softwareName: string) {
		this.dialogsService
			.showPanel(
				RecommendationWorkaroundsPanelComponent,
				{
					id: 'recommendation-workarounds-panel',
					...this.commonPanelConfig,
					back: {
						onClick: () => this._workaroundPanel && this._workaroundPanel.destroy(),
					},
					hasCloseButton: false,
				},
				{
					securityRecommendation: securityRecommendation,
					programName: softwareName,
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._workaroundPanel = panel;

				panel.onDestroy(() => {
					this._workaroundPanel = null;
				});
			});
	}

	showDisputeCategorySidePanel(
		domainName: string,
		categoryId: number,
		category: string
	) {
		this.dialogsService
			.showPanel(
				DisputeCategoryPanelComponent,
				{
					id: 'dispute-category-panel',
					...this.commonPanelConfig,
					hasCloseButton: true,
				},
				{
					domainName: domainName,
					categoryIdAsInput: categoryId,
					categoryNameAsInput: category
				}
			)
			.subscribe((panel: ComponentRef<PanelContainer>) => {
				this._disputeCategoryPanel = panel;

				panel.onDestroy(() => {
					this._disputeCategoryPanel = null;
				});
			});
	}
}
