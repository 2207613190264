import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentLinkedByEntity } from './incident-linked-by-entity.value-object';

@ValueObject({
	singularName: 'Alert linked by incident',
	pluralName: 'Alerts linked by incident',
	readonly: true,
})
export class IncidentLinkedByAlert extends ModelBase {
	/**
	 * TODO: required should be `true`, but currently we manually construct this object in some cases, so it has no id.
	 * Should be made required when deprecation is complete.
	 */
	@EntityField({ data: 'Id', required: false })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	/**
	 * Less-preferred entity to show to the user, if available.
	 * On legacy data (pre ~Sept. 2018) this won't be available.
	 */
	@EntityField({ data: 'SourceEntity' })
	readonly sourceEntity?: IncidentLinkedByEntity;

	/**
	 * Preferred entity to show to the user, if available.
	 * In some cases the target entity will not be available. Fallback to `sourceEntity`.
	 */
	@EntityField({ data: 'TargetEntity' })
	readonly targetEntity?: IncidentLinkedByEntity;
}
