import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import * as CreateRequest from '../machine-requests.models';
import { RestrictAppExecutionRawApiRequestBodyBase } from './restrict-app-execution.models';

export type RestrictAppExecutionRawApiRequestBody = RestrictAppExecutionRawApiRequestBodyBase<'Restrict'>;
export type RestrictAppExecutionRawApiResponse = CreateRequest.RawApiResponse;

export type RestrictAppExecutionParameters = CreateRequest.Parameters;
export type RestrictAppExecutionResponse = CreateRequest.Response;

@ApiCall({
	name: 'Restrict app execution',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
	}: RestrictAppExecutionParameters): HttpOptions<RestrictAppExecutionRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			ClientVersion: machine.senseClientVersion,
			PolicyType: 'Restrict',
			Type: 'RestrictExecutionRequest',
		},
	}),
	parseData: (rawData: RestrictAppExecutionRawApiResponse): RestrictAppExecutionResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.Id,
	}),
})
export class MachineRestrictAppExecutionApiCall extends ApiCallModel<
	RestrictAppExecutionResponse,
	RestrictAppExecutionParameters
> {}
