import { Directive, HostListener } from '@angular/core';
import { pickBy } from 'lodash-es';
import { TelemetryService } from './telemetry.service';
import { TrackingAttributes } from './models/tracking-attributes.models';
import { TelemetryEvent, TrackingEventType } from './models/telemetry-event.model';

const TRACK_SELECTOR = `[${TrackingAttributes.Id}]`;

@Directive({ selector: '[wcdTelemetryDelegate]' })
export class TelemetryDelegateDirective {
	constructor(private readonly telemetryService: TelemetryService) {}

	@HostListener('window:mousedown', ['$event'])
	onMouseDown(e: MouseEvent) {
		const nonLeftMouseButtonPressed = (e.button != null && e.button !== 0) || e.which !== 1;
		const nonRelevantModifierPressed = e.altKey || e.shiftKey;
		if (nonLeftMouseButtonPressed || nonRelevantModifierPressed) {
			return;
		}

		const element = (e.target as HTMLElement).closest(TRACK_SELECTOR);
		if (!element) {
			return;
		}

		const eventTypeAttributeValue = element.getAttribute(TrackingAttributes.Type);
		const eventType: TrackingEventType = TrackingEventType[eventTypeAttributeValue];
		if (!eventType && eventTypeAttributeValue) {
			console.warn(`Unknown tracking event type: '${eventTypeAttributeValue}', not tracking.`);
			return;
		}

		const eventId = element.getAttribute(TrackingAttributes.Id);
		if (!eventType || !eventId) {
			return;
		}

		const telemetryEvent: TelemetryEvent<string> = {
			type: eventType,
			id: eventId,
			...pickBy(
				{
					package: getClosestAttributeValue(element, TrackingAttributes.Package),
					component: getClosestAttributeValue(element, TrackingAttributes.Component),
					componentType: getClosestAttributeValue(element, TrackingAttributes.ComponentType),
					payload: element.getAttribute(TrackingAttributes.Value),
				},
				property => property !== undefined
			),
		};

		this.telemetryService.trackEvent(telemetryEvent);
	}
}

function getClosestAttributeValue(element: Element, attribute: TrackingAttributes): string | undefined {
	const closestElementWithAttribute = element.closest(`[${attribute}]`);
	return closestElementWithAttribute && closestElementWithAttribute.getAttribute(attribute);
}
