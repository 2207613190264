import * as i0 from "@angular/core";
var Ie11Service = /** @class */ (function () {
    function Ie11Service() {
        // Ie-11 does not support dispatch of custom Event, the workaround inject the detail directly into the event
        this.createIe11CustomEvent = function (eventName, _a) {
            var detail = _a.detail, _b = _a.bubbles, bubbles = _b === void 0 ? false : _b, _c = _a.cancelable, cancelable = _c === void 0 ? false : _c;
            var customEvent = document.createEvent('HTMLEvents');
            customEvent.initEvent(eventName, bubbles, cancelable);
            customEvent.detail = detail;
            return customEvent;
        };
        this.detectIE();
    }
    Ie11Service.prototype.detectIE = function () {
        var userAgent = window.navigator.userAgent;
        this.isIe11 = userAgent.includes('MSIE') || userAgent.includes('Trident/');
    };
    Ie11Service.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function Ie11Service_Factory() { return new Ie11Service(); }, token: Ie11Service, providedIn: "root" });
    return Ie11Service;
}());
export { Ie11Service };
