/* tslint:disable:template-accessibility-label-for */
import { Component, OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { AssessmentJobCompletedComponent } from './assessment-job-completed.component';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { Observable, of } from 'rxjs';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './assessment-job-review-step.component.html',
})
export class AssessmentJobReviewStepComponent extends WizardBaseStep<AssessmentJobModel> implements OnInit {
	MessageBarType = MessageBarType;

	constructor(
		public i18n: I18nService,
		public assessmentJobService: AssessmentJobService,
		private dialogsService: DialogsService
	) {
		super();
	}

	ngOnInit(): void {
		this.setStepValidation(!this.data.readOnly);
		this.setOnNext(this.onNext);
	}

	navigateToJobStep() {
		if (this.assessmentJobService.isWindowsScanFeatureEnabled()) {
			this.goToStep(1);
		} else {
			this.goToStep(0);
		}
	}

	navigateToScanStep() {
		this.previousStep();
	}

	isAllSelected(): boolean {
		if (this.data.assessmentJob.target === this.data.assessmentJob.originalTargetRanges) {
			return true;
		}

		return (
			this.assessmentJobService.countIpAddresses(this.data.assessmentJob.originalTargetRanges) ===
			this.data.selectedIps.length
		);
	}

	onNext = (): Observable<boolean> => {
		const next: Function = () => {
			this.markWizardCompleted(AssessmentJobCompletedComponent);
		};
		const errorHandling: Function = error => {
			this.dialogsService.showError({
				title: this.i18n.get('tvm.authenticatedScan.creationPanel.creationErrorTitle'),
				data: error,
			});
		};

		this.assessmentJobService.saveItem(this.data.assessmentJob, next, errorHandling);

		return of<boolean>(true);
	};
}
