var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { Investigation } from './investigation.entity';
import { InvestigatedMachine } from './investigated-machine.entity';
import { InvestigationAction } from './actions/investigation-action.entity';
import { InfectedEntityType } from '../airs_entity/infected-entity-type.value-object';
import { Alert } from '../alert/alert.entity';
import { RemediatedThreatsStatuses } from '../remediation/remediated-threats-statuses.value-object';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { ExternalSourceResults } from '../services/external-source-results.value-object';
import { EvidenceEntity } from '../evidence/evidence.entity';
var InvestigationMachinesRelationship = /** @class */ (function () {
    function InvestigationMachinesRelationship() {
    }
    InvestigationMachinesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: InvestigatedMachine,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], InvestigationMachinesRelationship);
    return InvestigationMachinesRelationship;
}());
export { InvestigationMachinesRelationship };
var ɵ0 = function (config, query) {
    return query.where && query.where['useVNextAssociatedAlertsApi'] ? 'Alerts' : 'AssociatedAlerts';
}, ɵ1 = function (config, query) {
    return query.where && query.where['useVNextAssociatedAlertsApi'] ? config.data.serviceUrls.alertsApiService + '/Evidences/investigation' : config.data.serviceUrls.threatIntel;
};
var InvestigationAlertsRelationship = /** @class */ (function () {
    function InvestigationAlertsRelationship() {
    }
    InvestigationAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: Alert,
            foreignKey: 'InvestigationId',
            endpoint: ɵ0,
            allItemsProperty: 'Items',
            baseUrl: ɵ1,
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], InvestigationAlertsRelationship);
    return InvestigationAlertsRelationship;
}());
export { InvestigationAlertsRelationship };
var InvestigationActionsRelationship = /** @class */ (function () {
    function InvestigationActionsRelationship() {
    }
    InvestigationActionsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: InvestigationAction,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], InvestigationActionsRelationship);
    return InvestigationActionsRelationship;
}());
export { InvestigationActionsRelationship };
var ɵ2 = function (config) { return config.data.serviceUrls.automatedIr; };
var InvestigationInfectedEntitiesRelationship = /** @class */ (function () {
    function InvestigationInfectedEntitiesRelationship() {
    }
    InvestigationInfectedEntitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: InfectedEntityType,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
            endpoint: 'entities/infected',
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'data',
            baseUrl: ɵ2,
        })
    ], InvestigationInfectedEntitiesRelationship);
    return InvestigationInfectedEntitiesRelationship;
}());
export { InvestigationInfectedEntitiesRelationship };
var ɵ3 = function (config, query) {
    return "entities/evidence/investigation/" + query.where['investigation_id'] + "/unified";
}, ɵ4 = function (config) { return config.data.serviceUrls.automatedIr; };
var InvestigationEvidenceRelationship = /** @class */ (function () {
    function InvestigationEvidenceRelationship() {
    }
    InvestigationEvidenceRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: EvidenceEntity,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
            endpoint: ɵ3,
            allItemsEndpointTrailingSlash: false,
            allItemsProperty: 'data',
            separateArrayParams: true,
            baseUrl: ɵ4,
        })
    ], InvestigationEvidenceRelationship);
    return InvestigationEvidenceRelationship;
}());
export { InvestigationEvidenceRelationship };
var ɵ5 = function (config) { return config.data.serviceUrls.automatedIr; };
var InvestigationRemediatedThreatsRelationship = /** @class */ (function () {
    function InvestigationRemediatedThreatsRelationship() {
    }
    InvestigationRemediatedThreatsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: RemediatedThreatsStatuses,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToOne],
            endpoint: 'entities/summary',
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ5,
        })
    ], InvestigationRemediatedThreatsRelationship);
    return InvestigationRemediatedThreatsRelationship;
}());
export { InvestigationRemediatedThreatsRelationship };
var ɵ6 = function (config) { return config.data.serviceUrls.automatedIr; };
var InvestigationEntityResultsRelationship = /** @class */ (function () {
    function InvestigationEntityResultsRelationship() {
    }
    InvestigationEntityResultsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: AirsEntityTypeResults,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
            endpoint: 'entities/entity_results',
            allItemsProperty: 'data',
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ6,
        })
    ], InvestigationEntityResultsRelationship);
    return InvestigationEntityResultsRelationship;
}());
export { InvestigationEntityResultsRelationship };
var ɵ7 = function (config) { return config.data.serviceUrls.automatedIr; };
var InvestigationExternalSourceResultsRelationship = /** @class */ (function () {
    function InvestigationExternalSourceResultsRelationship() {
    }
    InvestigationExternalSourceResultsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Investigation,
            dataEntity: ExternalSourceResults,
            foreignKey: 'investigation_id',
            allowedTypes: [RelationshipType.OneToMany],
            endpoint: 'services/tracking_data',
            allItemsProperty: 'data',
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ7,
        })
    ], InvestigationExternalSourceResultsRelationship);
    return InvestigationExternalSourceResultsRelationship;
}());
export { InvestigationExternalSourceResultsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
