import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityStatusCount } from './airs-entity-status-count.value-object';
import { RemediationStatusType } from './airs-entity-status-types.enum';

const TOTAL = 'total';
type TOTAL_TYPE = typeof TOTAL;

@ValueObject({
	singularName: 'Entity Type Results',
	pluralName: 'Entity Type Results',
	readonly: true,
})
export class AirsEntityTypeResults extends ModelBase {
	@EntityField({ data: 'type_id' })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'data', arrayOf: AirsEntityStatusCount })
	results: Array<AirsEntityStatusCount>;

	private _entityCounts: Partial<Record<RemediationStatusType | TOTAL_TYPE, number>> = {};
	private _maliciousCount: { count?: number } = {};

	get totalEntityCount(): number {
		return this.getEntityCountByStatus(TOTAL);
	}

	get remediatedEntitiesCount(): number {
		return this.getEntityCountByStatus(RemediationStatusType.Remediated);
	}

	getEntityCountByStatus(status: RemediationStatusType | TOTAL_TYPE): number {
		if (this._entityCounts[status] === undefined) {
			this._entityCounts[status] = this.results.reduce(
				(total: number, currentStatus: AirsEntityStatusCount) =>
					status === TOTAL || (currentStatus.status && currentStatus.status.type === status)
						? total + currentStatus.count
						: total,
				0
			);
		}
		return this._entityCounts[status];
	}

	get maliciousEntitiesCount(): number {
		if (this._maliciousCount.count === undefined)
			this._maliciousCount.count = this.results.reduce(
				(total: number, currentStatus: AirsEntityStatusCount) =>
					currentStatus.status && currentStatus.status.isMalicious
						? total + currentStatus.count
						: total,
				0
			);

		return this._maliciousCount.count;
	}

	get entityTypeId(): number {
		return this.entityType.id;
	}
}
