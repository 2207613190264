import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { DownloadFilesSearchCsvParams, FilesBackendService } from './files.backend.service';
import { FilesFiltersService } from './files.filters.service';
import { File, FileSearchCriterion } from '@wcd/domain';
import { PanelSettings, PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { FileActionCenterPanelComponent } from '../components/file.action-center/file.action-center.panel.component';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { RegExpService } from '@wcd/shared';
import { recursiveDecodeURIComponent, encodeRoutePath } from '../../../utils/url-encoding/url-encoding-utils';

const MAX_NAME_LENGTH = 400;

@Injectable()
export class FilesService {
	constructor(
		private readonly backendService: FilesBackendService,
		private readonly appInsightsService: AppInsightsService,
		private readonly filtersService: FilesFiltersService,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private featuresService: FeaturesService,
		private router: Router
	) {}

	downloadCsv(params: DownloadFilesSearchCsvParams): Promise<void> {
		this.appInsightsService.trackEvent('UsageTrack', {
			ButtonType: 'ExportToCsv',
			Page: 'File',
			Component: 'FileSearchExportToCsv',
		});

		return this.backendService.downloadCsv(params);
	}

	getFilesFilters(): Observable<Record<string, any>> {
		return this.filtersService.getFilesFilters();
	}

	showFileActionCenter(file: File) {
		const panelSettings: PanelSettings = {
			id: 'file-action-center',
			type: PanelType.large,
			headerText: this.i18nService.get('file.actionCenter.title'),
		};

		this.dialogsService
			.showPanel(FileActionCenterPanelComponent, panelSettings, {
				file,
			})
			.subscribe();
	}

	getFileLinkParams(fileName: string) {
		return fileName ? { name: recursiveDecodeURIComponent(fileName).substring(0, MAX_NAME_LENGTH) } : {};
	}

	getFileLink(file: string, alertLastEventTime?: Date, fileName?: string): string {
		if (this.featuresService.isEnabled(Feature.UpgradeFilePage)) {
			const route: Array<string | {}> = ['files', file];

			if (fileName) {
				// File names need to be encoded twice, since they are a part of the URL path and encoded characters in URL path are automatically decoded.
				// See https://stackoverflow.com/questions/1957115/is-a-slash-equivalent-to-an-encoded-slash-2f-in-the-path-portion-of-a
				route.push({
					name: encodeRoutePath(encodeRoutePath(fileName.substring(0, MAX_NAME_LENGTH))),
				});
			}

			if (alertLastEventTime) {
				route.push('timeline');
			}

			return this.router.serializeUrl(this.router.createUrlTree(route));
		}

		return this.getLegacyFileLink(file, alertLastEventTime);
	}

	getFileSearchCriterion(fileIdentifier) {
		const searchTerm = String(fileIdentifier).split(';name=')[0];

		if (RegExpService.md5.test(searchTerm)) {
			return FileSearchCriterion.Md5;
		}

		if (RegExpService.sha1.test(searchTerm)) {
			return FileSearchCriterion.Sha1;
		}

		if (RegExpService.sha256.test(searchTerm)) {
			return FileSearchCriterion.Sha256;
		}

		return null;
	}

	getLegacyFileLink(file: string, alertLastEventTime: Date) {
		return `/file/${file}${alertLastEventTime ? '/' + alertLastEventTime.toISOString() : ''}`;
	}
}
