var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { ExtendedOutbreak, ExtendedOutbreaksApiCall, GetNewOutbreaksCountAPICall, PatchOutbreakUserStateAPICall, ThreatReportType, outbreakTags, getReportTypeI18nKey, ThreatExposureType, } from '@wcd/domain';
import { BehaviorSubject, EMPTY, of, Subject, throwError } from 'rxjs';
import { AuthService } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService, FlavorService, PreferencesService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
import { I18nService } from '@wcd/i18n';
import { HUNTING_ROUTE, LocalStorageService } from '@wcd/shared';
import { DEFAULT_THREAT_ANALYTICS_PREFERENCES, OutbreakSortOption, } from '../threat.model';
import { sccHostService } from '@wcd/scc-interface';
import { clone, cloneDeep } from 'lodash-es';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
var THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY = 'threatAnalytics_dashboard';
var OutbreakService = /** @class */ (function () {
    function OutbreakService(paris, authService, appConfigService, featuresService, preferencesService, router, knownColorsService, i18nService, localStorageService, flavorService, activatedRoute, appInsightsService) {
        var _this = this;
        this.paris = paris;
        this.authService = authService;
        this.appConfigService = appConfigService;
        this.featuresService = featuresService;
        this.preferencesService = preferencesService;
        this.router = router;
        this.knownColorsService = knownColorsService;
        this.i18nService = i18nService;
        this.localStorageService = localStorageService;
        this.flavorService = flavorService;
        this.activatedRoute = activatedRoute;
        this.appInsightsService = appInsightsService;
        this.extOutbreakUpdates$ = new Subject();
        this.extOutbreaksSource$ = new BehaviorSubject(null);
        this.allOutbreaksSource$ = new Subject();
        this.notFilteredOutbreaksSource$ = new Subject();
        this.changeCountBehavior$ = new BehaviorSubject(0);
        this.changeCount$ = this.changeCountBehavior$.asObservable();
        this.allOutbreaks$ = this.allOutbreaksSource$.asObservable();
        this.notFilteredOutbreaks$ = this.notFilteredOutbreaksSource$.asObservable();
        this.extOutbreaks$ = this.extOutbreaksSource$.asObservable();
        this.getRedColor = function () {
            return sccHostService.isSCC
                ? _this.knownColorsService.knownColorsMap['redDark']
                : _this.knownColorsService.knownColorsMap['red'];
        };
        this.getGreenColor = function () {
            return sccHostService.isSCC
                ? _this.knownColorsService.knownColorsMap['green']
                : _this.knownColorsService.knownColorsMap['greenLight'];
        };
        this.convertMdatpOutbreakToStretchedDonutChartItem = function (outbreak) {
            var outbreakImpact = outbreak.impact;
            return {
                id: outbreak.id,
                title: outbreak.displayName,
                value: outbreakImpact && outbreakImpact.compromisedMachines,
                total: outbreakImpact && outbreakImpact.compromisedMachines + outbreakImpact.blockedMachines,
                valueColor: outbreakImpact
                    ? _this.getActiveColor(outbreak)
                    : _this.knownColorsService.knownColorsMap['neutralTertiary'],
                totalColor: outbreakImpact
                    ? _this.getResolvedColor(outbreak)
                    : _this.knownColorsService.knownColorsMap['neutralTertiary'],
                width: '100%',
                height: 8,
                noDataText: outbreakImpact
                    ? ''
                    : _this.i18nService.get('dashboard.threatAnalytics.landingPage.tabs.threats.notSupportedThreat'),
            };
        };
        this.convertMtpOutbreakToStretchedDonutChartItem = function (outbreak) {
            var totalAlertsCount = outbreak.alertsCount.activeAlertsCount + outbreak.alertsCount.resolvedAlertsCount;
            return {
                id: outbreak.id,
                title: outbreak.displayName,
                value: outbreak.alertsCount.activeAlertsCount,
                total: totalAlertsCount,
                valueColor: totalAlertsCount
                    ? _this.getRedColor()
                    : _this.knownColorsService.knownColorsMap['neutralTertiary'],
                totalColor: totalAlertsCount
                    ? _this.getGreenColor()
                    : _this.knownColorsService.knownColorsMap['neutralTertiary'],
                width: '100%',
                height: 8,
                noDataText: outbreak.impact
                    ? ''
                    : _this.i18nService.get('dashboard.threatAnalytics.landingPage.tabs.threats.notSupportedThreat'),
            };
        };
        this.convertThreatExposureToStretchedDonutChartItem = function (outbreak) {
            var noAvailableText = _this.i18nService.get('dashboard_threatAnalytics_mtp_exposureNotAvailable');
            var customText = outbreak.exposureSeverity.type === ThreatExposureType.None
                ? noAvailableText
                : outbreak.exposureScore + " - " + outbreak.exposureSeverity.type;
            return {
                id: outbreak.id,
                title: outbreak.displayName,
                value: outbreak.exposureScore,
                total: 100,
                valueColor: _this.knownColorsService.knownColorsMap[outbreak.exposureSeverity.className],
                totalColor: _this.knownColorsService.knownColorsMap['neutralTertiary'],
                width: '100%',
                height: 8,
                useCustomDisplay: true,
                noDataText: customText,
            };
        };
        this.isThreatAnalyticsMdeEnabled = function () {
            return (_this.appConfigService.isMdatpActive &&
                _this.flavorService.isEnabled(AppFlavorConfig.threatAnalytics.mde));
        };
        var threatAnalyticsMtpFeatures = [
            Feature.ThreatAnalyticsMTPForMDATP,
            Feature.ThreatAnalyticsMTP,
        ];
        this.isMtpFeatureEnabled = featuresService.isAnyEnabled(threatAnalyticsMtpFeatures);
        this.isK8sMigrationEnabled = featuresService.isEnabled(Feature.K8SMigrationThreatAnalytics);
        this.init();
    }
    OutbreakService.prototype.init = function () {
        if (!this.appConfigService.isOnboardingComplete ||
            this.appConfigService.isSuspended ||
            this.appConfigService.isDeleted) {
            return;
        }
        this.getOutbreaksChangeCountAsync().subscribe();
    };
    OutbreakService.prototype.getExtOutbreaks = function () {
        var _this = this;
        this.getLastVisitTimes();
        return this.paris
            .apiCall(ExtendedOutbreaksApiCall, {
            isMtp: this.isMtpFeatureEnabled,
            isK8sMigration: this.isK8sMigrationEnabled,
        })
            .pipe(map(function (outbreaksData) {
            return __assign({}, outbreaksData, { items: ExtendedOutbreak.adjustOutbreaksData(outbreaksData.items, _this.lastVisitTimes) });
        }), tap(function (outbreaksCalculation) {
            _this.extOutbreaksSource$.next(outbreaksCalculation);
        }));
    };
    OutbreakService.prototype.getOutbreakById = function (outbreakId) {
        return this.extOutbreaks$.pipe(map(function (extOutbreaks) {
            var outbreak = extOutbreaks && extOutbreaks.items.find(function (extOutbreak) { return extOutbreak.id === outbreakId; });
            return outbreak;
        }));
    };
    OutbreakService.prototype.getAllImpact = function () {
        var _this = this;
        return this.extOutbreaksSource$.pipe(map(function (extOutbreaks) { return extOutbreaks.items.map(function (extOutbreak) { return extOutbreak.impact; }); }), tap(function (allImpact) { return (_this.outbreakAllImpact = allImpact); }));
    };
    OutbreakService.prototype.extractImpactFromExtOutbreaks = function (extOutbreaks) {
        return extOutbreaks.map(function (extOutbreak) { return extOutbreak.impact; });
    };
    OutbreakService.prototype.setUserFavoriteState = function (outbreakId, isFavorite) {
        return this.extOutbreaksSource$.pipe(tap(function (extOutbreaks) {
            extOutbreaks.items.find(function (extOutbreak) { return extOutbreak.id === outbreakId; }).userState.isFavorite = isFavorite;
        }));
    };
    OutbreakService.prototype.getOutbreaksChangeCountAsync = function () {
        var _this = this;
        return this.paris
            .apiCall(GetNewOutbreaksCountAPICall)
            .pipe(tap(function (changeCount) { return _this.changeCountBehavior$.next(changeCount); }));
    };
    OutbreakService.prototype.markAllOutbreaksRead = function (outbreaks) {
        var _this = this;
        // TODO: update when change count has changed
        var affectedOutbreaks = (outbreaks || []).filter(function (o) { return o.isNew; });
        if (!affectedOutbreaks || affectedOutbreaks.length < 1) {
            return EMPTY;
        }
        var markAllReadPayload = affectedOutbreaks.map(function (o) { return ({
            OutbreakId: o.id,
            LastVisitTime: new Date(),
            IsFavorite: o.userState.isFavorite,
        }); });
        return this.paris
            .apiCall(PatchOutbreakUserStateAPICall, markAllReadPayload)
            .pipe(tap(function () { return _this.changeCountBehavior$.next(0); }));
    };
    OutbreakService.prototype.onOutbreakVisit = function (outbreakId) {
        var _this = this;
        return this.paris
            .apiCall(PatchOutbreakUserStateAPICall, [{ OutbreakId: outbreakId, LastVisitTime: new Date() }])
            .pipe(switchMap(function () { return _this.getOutbreaksChangeCountAsync(); }), tap(function (changeCount) { return _this.changeCountBehavior$.next(changeCount); }), mergeMap(function () { return EMPTY; }), catchError(function (err) {
            return throwError('Failed to set outbreak ' + outbreakId + 'as visited: ' + err.toString());
        }));
    };
    OutbreakService.prototype.getLastVisitTimes = function () {
        if (!this.lastVisitTimes) {
            // parse json object to map, because mistakenly I used map as json object i.e map["key"] = val instead of map.set("key", val)
            var data = this.localStorageService.getItem(this.getStorageKey());
            this.lastVisitTimes = new Map();
            if (data) {
                var jsonObj = JSON.parse(data);
                for (var key in jsonObj) {
                    if (jsonObj.hasOwnProperty(key)) {
                        this.lastVisitTimes.set(key, new Date(jsonObj[key]));
                    }
                }
            }
        }
        return this.lastVisitTimes;
    };
    OutbreakService.prototype.getStorageKey = function () {
        var currentUserName = this.authService.currentUser
            ? this.authService.currentUser.name
            : 'defaultUser';
        return currentUserName + "_outbreaks.lastVisit";
    };
    OutbreakService.prototype.sortOutbreaks = function (outbreaks, sortOptions) {
        var _this = this;
        if (!outbreaks)
            return;
        if (sortOptions === OutbreakSortOption.impact) {
            outbreaks.sort(function (o1, o2) {
                return _this.isMtpFeatureEnabled
                    ? _this.compareMtpOutbreaksImpact(o1, o2, true)
                    : _this.compareMdatpOutbreaksImpact(o1, o2, true);
            });
        }
        else if (sortOptions === OutbreakSortOption.date) {
            outbreaks.sort(this.compareOutbreaksLastUpdated.bind(this));
        }
        else if (sortOptions === OutbreakSortOption.exposure) {
            outbreaks.sort(this.compareOutbreaksExposure.bind(this));
        }
        // save sorting preferences
        var preferences = this.getDashboardPreferences();
        if (preferences.sortOptions !== sortOptions) {
            preferences.sortOptions = sortOptions;
            this.saveDashboardPreferences(preferences);
        }
    };
    OutbreakService.prototype.getDashboardPreferences = function () {
        return (this.preferencesService.getPreference(THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY) ||
            DEFAULT_THREAT_ANALYTICS_PREFERENCES);
    };
    OutbreakService.prototype.saveDashboardPreferences = function (preferences) {
        this.preferencesService.setPreference(THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY, preferences);
    };
    OutbreakService.prototype.getActiveColor = function (outbreak) {
        return outbreak.impact.compromisedMachines > 0
            ? sccHostService.isSCC
                ? this.knownColorsService.knownColorsMap['redDark']
                : this.knownColorsService.knownColorsMap['red']
            : this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'];
    };
    OutbreakService.prototype.getResolvedColor = function (outbreak) {
        return outbreak.impact.blockedMachines > 0
            ? sccHostService.isSCC
                ? this.knownColorsService.knownColorsMap['green']
                : this.knownColorsService.knownColorsMap['greenLight']
            : this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'];
    };
    OutbreakService.prototype.compareMdatpOutbreaksImpact = function (o1, o2, sortDescending) {
        if (sortDescending === void 0) { sortDescending = false; }
        var impact1 = o1.impact;
        var impact2 = o2.impact;
        var result;
        if (impact1 && impact2) {
            result =
                impact1.compromisedMachines - impact2.compromisedMachines ||
                    impact1.blockedMachines - impact2.blockedMachines ||
                    this.compareOutbreaksLastUpdated(o1, o2);
        }
        else {
            result = impact1 ? 1 : impact2 ? -1 : this.compareOutbreaksLastUpdated(o1, o2);
        }
        return sortDescending ? result * -1 : result;
    };
    OutbreakService.prototype.compareMtpOutbreaksImpact = function (o1, o2, sortDescending) {
        if (sortDescending === void 0) { sortDescending = false; }
        var alertsCount1 = o1.alertsCount;
        var alertsCount2 = o2.alertsCount;
        var result = alertsCount1.activeAlertsCount - alertsCount2.activeAlertsCount ||
            alertsCount1.resolvedAlertsCount - alertsCount2.resolvedAlertsCount ||
            this.compareOutbreaksLastUpdated(o1, o2);
        return sortDescending ? result * -1 : result;
    };
    OutbreakService.prototype.compareOutbreaksLastUpdated = function (o1, o2) {
        return o2.lastUpdatedOn.getTime() - o1.lastUpdatedOn.getTime() || (o1.id > o2.id ? -1 : 1);
    };
    OutbreakService.prototype.compareOutbreaksExposure = function (o1, o2) {
        return o1.exposureScore > o2.exposureScore ? -1 : 1;
    };
    OutbreakService.prototype.getTopThreats$ = function (outbreakSortOption) {
        var _this = this;
        return this.notFilteredOutbreaksSource$.pipe(map(function (outbreaksData) {
            var items = cloneDeep(outbreaksData);
            _this.sortOutbreaks(items, outbreakSortOption);
            if (outbreakSortOption === OutbreakSortOption.exposure) {
                return _this.getThreatExposureStretchedDonutChartItems(items.filter(function (item) { return item.cveAndScidNotDefined == false; }).slice(0, 4));
            }
            return _this.getStretchedDonutChartItems(items.slice(0, 4));
        }));
    };
    OutbreakService.prototype.getThreatExposureStretchedDonutChartItems = function (outbreaks) {
        var data = outbreaks.map(this.convertThreatExposureToStretchedDonutChartItem);
        return data.filter(function (item) { return item; });
    };
    OutbreakService.prototype.getStretchedDonutChartItems = function (outbreaks) {
        var data = outbreaks.map(this.isMtpFeatureEnabled
            ? this.convertMtpOutbreakToStretchedDonutChartItem
            : this.convertMdatpOutbreakToStretchedDonutChartItem);
        return data.filter(function (item) { return item; });
    };
    OutbreakService.prototype.goToThreat = function (outbreakId) {
        this.router.navigate(['/threatanalytics3', outbreakId]);
    };
    OutbreakService.prototype.goToHunting = function (encodedQuery) {
        this.router.navigate(["/" + HUNTING_ROUTE], {
            queryParams: {
                query: encodedQuery,
                runQuery: 'true',
            },
        });
    };
    OutbreakService.prototype.setOutbreaksData = function (dataSet) {
        var status = dataSet.meta;
        this.calculationStatus = status;
        this.allOutbreaksSource$.next(dataSet);
    };
    OutbreakService.prototype.setNotFilteredOutbreaks = function () {
        var _this = this;
        this.paris
            .apiCall(ExtendedOutbreaksApiCall, {
            isMtp: this.isMtpFeatureEnabled,
            isK8sMigration: this.isK8sMigrationEnabled,
        })
            .pipe(tap(function (outbreaks) {
            _this.calculationStatus = {
                devicesCalculationStatus: outbreaks.devicesCalculationStatus,
                mailboxesCalculationStatus: outbreaks.mailboxesCalculationStatus,
                threatExposureCalculationStatus: outbreaks.threatExposureCalculationStatus,
            };
            _this.notFilteredOutbreaksSource$.next(outbreaks.items);
            _this.distributionData$ = _this.createSummaryObservableForOutbreakTags(outbreaks.items);
        }))
            .subscribe();
    };
    OutbreakService.prototype.getOutbreaksCalculationStatus = function () {
        return this.calculationStatus;
    };
    OutbreakService.prototype.getFilters = function () {
        var _this = this;
        var filterMappings = {
            tags: {
                values: Object.values(outbreakTags.map(function (tag) {
                    return {
                        value: tag.type,
                        name: _this.i18nService.get(tag.nameI18nKey),
                        priority: tag.priority,
                    };
                })),
            },
            reportType: {
                values: Object.values(ThreatReportType).map(function (reportType) {
                    return {
                        value: reportType,
                        name: _this.i18nService.get(getReportTypeI18nKey(reportType)),
                    };
                }),
            },
        };
        return of(filterMappings);
    };
    OutbreakService.prototype.filterByTag = function (tag) {
        var _this = this;
        var outbreakTag = outbreakTags.find(function (t) { return _this.i18nService.get(t.nameI18nKey) == tag.title; });
        if (!outbreakTag) {
            return;
        }
        this.appInsightsService.track({
            id: 'DistributionFiltersChanged',
            component: 'OutbreakDistributionTags',
            type: TrackingEventType.Filter,
            value: outbreakTag.type,
        });
        var queryParams = clone(this.activatedRoute.snapshot.queryParams);
        queryParams.filters += ",tags=" + outbreakTag.type;
        this.router.navigate(['./threatanalytics3'], {
            relativeTo: this.activatedRoute,
            queryParams: queryParams,
            queryParamsHandling: 'merge',
            replaceUrl: true,
        });
    };
    OutbreakService.prototype.createSummaryObservableForOutbreakTags = function (data) {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.ThreatAnalyticsOutbreakTags)) {
            return of(null);
        }
        var result = outbreakTags
            .map(function (tag) {
            return {
                title: _this.i18nService.get(tag.nameI18nKey),
                barColor: _this.knownColorsService.knownColorsMap[tag.color].name,
                mainText: data ? data.filter(function (o) { return o.tags.includes(tag.type); }).length : 0,
                priority: tag.priority,
            };
        })
            .sort(function (a, b) { return (a.priority < b.priority ? -1 : 1); });
        return of(result);
    };
    OutbreakService.prototype.ngOnDestroy = function () {
        this.extOutbreakUpdates$.complete();
        this.extOutbreaksSource$.complete();
        this.outbreakUpdatesSubscription && this.outbreakUpdatesSubscription.unsubscribe();
    };
    return OutbreakService;
}());
export { OutbreakService };
