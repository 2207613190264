<div class="wcd-padding-vertical wcd-scroll-vertical wcd-flex-1">
	<wcd-collapsible-section
		[sectionId]="collapsibleID.Details"
		[label]="'customTiIndicator.detailsSidePane.sections.tidetails.title' | i18n">
		<dl [class.key-values]="asKeyValueList" role="none">
			<ng-container *ngIf="customTiIndicator.application">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.application.title' | i18n}}</dt>
				<dd>
					{{customTiIndicator.application}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.version">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.version.title' | i18n}}</dt>
				<dd>
					{{customTiIndicator.version}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.publisher">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.publisher.title' | i18n}}</dt>
				<dd>
					{{customTiIndicator.publisher}}
				</dd>
			</ng-container>
			<dt>{{ "customTiIndicator.dataview.entity.fields.createdBy" | i18n}}</dt>
			<dd>
							<span *ngIf="customTiIndicator.isMcasIndicator;else internalSource">
								<a data-track-type="ExternalLink" data-track-id="FollowOriginLink" [href]="customTiIndicator.originLink"
								   target="_blank">{{ customTiIndicator.createdByDisplayName }}</a>
							</span>
				<ng-template #internalSource>
					{{ customTiIndicator.createdByDisplayName }}
				</ng-template>
			</dd>
			<dt>{{ 'customTiIndicator.dataview.entity.fields.tiIndicatorType.' + customTiIndicator.tiIndicatorType.name.toLowerCase() + '.title' | i18n}}</dt>
			<dd>
				<fab-icon [iconName]="customTiIcon" contentClass="small-icon"></fab-icon>
				<route-link *ngIf='entityRouteLink'
							[routeLink]="{ routerLink: [entityRouteLink], id: [entityRouteLinkId] }">
					{{customTiIndicator.tiIndicator}}
				</route-link>
				<span *ngIf='!entityRouteLink'>{{customTiIndicator.tiIndicator}}</span>
			</dd>
			<ng-container *ngIf="customTiIndicator.serial">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.certificate.serial' | i18n}}</dt>
				<dd>
					{{customTiIndicator.serial}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.issuer">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.certificate.issuer' | i18n}}</dt>
				<dd class="nowrap wcd-text-overflow-large" [wcdTooltip]="customTiIndicator.issuer">
					{{customTiIndicator.issuer}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.subject">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.certificate.subject' | i18n}}</dt>
				<dd class="nowrap wcd-text-overflow-large" [wcdTooltip]="customTiIndicator.subject">
					{{customTiIndicator.subject}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.processMemoryProcessName">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.memory_content.processMemoryProcessName' | i18n}}</dt>
				<dd class="nowrap wcd-text-overflow-large" [wcdTooltip]="customTiIndicator.processMemoryProcessName">
					{{customTiIndicator.processMemoryProcessName}}
				</dd>
			</ng-container>
			<ng-container *ngIf="customTiIndicator.processMemoryActualSize">
				<dt>{{ 'customTiIndicator.dataview.entity.fields.memory_content.processMemoryActualSize' | i18n}}</dt>
				<dd>
					{{customTiIndicator.processMemoryActualSize}}
				</dd>
			</ng-container>
			<dt>{{ 'customTiIndicator.dataview.entity.fields.expiresOn.title' | i18n}}</dt>
			<dd>
							<span *ngIf="isExpired;else datetemplate">
								 <wcd-shared-icon class="color-text-warning-dark wcd-margin-bottom" iconName="warningSolid"> </wcd-shared-icon >
								{{"customTiIndicator.dataview.entity.fields.expiresOn.values.expired" | i18n}}
							</span>

				<ng-template #datetemplate>
								<span *ngIf="customTiIndicator.expirationTime;else neverexpired">
								   {{utcExpirationAsLocal | date:'mediumDate' }}
								</span>
					<ng-template #neverexpired>
						{{"customTiIndicator.dataview.entity.fields.expiresOn.values.never" | i18n}}
					</ng-template>
				</ng-template>
			</dd>
		</dl>
	</wcd-collapsible-section>
	<wcd-collapsible-section
		[sectionId]="collapsibleID.ResponseAction"
		[label]="'customTiIndicator.detailsSidePane.sections.responseaction.title' | i18n">
		<div class="form-group">
			<wcd-radiolist [values]="indicatorMachineComponentService.actionOptions"
						name="custom-ti-indicator-action-options"
						data-field-label="Actions"
						(ngModelChange)="onActionOptionsChange($event)"
						[(ngModel)]="indicatorMachineComponentService.currentActionOption"
						[isDisabled] = "customTiIndicatorType.readonly || !isEditable || customTiIndicator.isMcasIndicator"></wcd-radiolist>
		</div>
		<div class="wcd-margin-vertical">
			<wcd-checkbox #generateAlert *ngIf="tvmApplicationBlockEnabled && customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate"
				[ngModel]="customTiIndicator.generateAlert"
				name="custom-ti-generate-alert"
				[label]="i18nService.strings.customTiIndicator_dataview_entity_fields_generateAlert_title"
				[isDisabled]="isGenerateAlertDisabled || customTiIndicator.isMcasIndicator"
				(ngModelChange)="onGenerateAlertChange($event)"
				[wcdTooltip]="i18nService.strings.customTiIndicator_dataview_entity_fields_generateAlert_title">
			</wcd-checkbox>
		</div>

		<div class="form-group">
			<label for="custom-ti-alert-title" class="wcd-required">{{ 'customTiIndicator.dataview.entity.fields.tiTitle.' + (indicatorMachineComponentService.isAlertable ? 'alerttitle' : 'title') | i18n}}</label>
			<input type="text"
				   id="custom-ti-alert-title"
				   [(ngModel)]="customTiIndicator.title"
				   name="custom-ti-alert-title"
				   class="form-control"
				   (ngModelChange) ="isDirty = true"
				   maxlength="300"
				   [disabled] = "customTiIndicatorType.readonly || !isEditable"
				   [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }"
				   required/>
		</div>
		<div *ngIf="indicatorMachineComponentService.isAlertable">
			<div class="form-group" *ngIf="historicalDetectionEnabled">
				<div class="wcd-margin-small-top wrap-text wcd-flex-horizontal" *ngIf="customTiIndicator.historicalDetection">
					<div class="wcd-flex-none wcd-width-small-medium wcd-font-weight-semibold wcd-margin-right">
						{{ i18nService.strings.customTiIndicator_dataview_entity_fields_historicalDetection_title }}
					</div>
					<div class="wcd-flex-1">
						{{ customTiIndicator.historicalDetection ? i18nService.strings.common_yes: i18nService.strings.common_no }}
					</div>
				</div>
				<div class="wcd-margin-small-top wrap-text wcd-flex-horizontal" *ngIf="customTiIndicator.historicalDetection">
					<div class="wcd-flex-none wcd-width-small-medium wcd-font-weight-semibold wcd-margin-right">
						{{ i18nService.strings.customTiIndicator_dataview_entity_fields_lookBackPeriod_title }}
					</div>
					<div class="wcd-flex-1">
						{{ 'customTiIndicator_dataview_entity_fields_lookBackPeriod_' + customTiIndicator.lookBackPeriod | i18n }}
					</div>
				</div>
			</div>

			<div class="form-group" >
		    	<label for="custom-ti-severity" class="wcd-required">{{ "customTiIndicator.dataview.entity.fields.alertSeverity.title" | i18n}}</label>
		    	<fancy-select
		    		[isBordered]="true"
		    		labelClass="dropdown-width-medium"
		    		[(ngModel)]="customTiIndicator.severity"
		    		name="custom-ti-severity"
		    		[formatLabel]="indicatorMachineComponentService.getFormatSeverityLabel"
		    		trackBy="id"
		    		(ngModelChange) ="isDirty = true"
		    		[values]="severityList"
		    		[isDisabled] = "customTiIndicatorType.readonly || !isEditable"
					[rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }"
					[dropdownAreaRole]="'combobox'"
					[ariaLabel]="'customTiIndicator.dataview.entity.fields.alertSeverity.title' | i18n"></fancy-select>
		    </div>
		    <div class="form-group" *ngIf="mitreTechniquesEnabled">
		        <label for="custom-ti-category">{{ 'customTiIndicator_dataview_entity_fields_alertCategory_title' | i18n }}</label>
		        <fancy-select
		        	[isBordered]="true"
		        	labelClass="dropdown-width-medium"
		        	[(ngModel)]="customTiIndicator.category"
		        	name="custom-ti-category"
		        	[formatLabel]="indicatorMachineComponentService.getFormatCategoryLabel"
		        	[ariaLabel]="'customTiIndicator.dataview.entity.fields.alertCategory.title' | i18n"
		        	[trackBy]="id"
		        	[values]="indicatorMachineComponentService.categoryList"
					[isDisabled] = "customTiIndicatorType.readonly || !isEditable"
					(ngModelChange)="onCategoryChanged($event)"
					[dropdownAreaRole]="'combobox'">
				</fancy-select>
			</div>
			<div class="form-group" *ngIf="mitreTechniquesEnabled && indicatorMachineComponentService.selectableMitreTechniques && indicatorMachineComponentService.selectableMitreTechniques.length">
				<label for="alertMitreTechnique">{{ 'hunting_scheduledMonitorSidePane_fields_alertMitreTechniques_title' | i18n }}</label>
				<wcd-checklist-dropdown
							[(ngModel)]="customTiIndicator.mitreTechniques"
							name="alertMitreTechnique"
							labelClass="dropdown-width-medium"
							[buttonText]="getMitreTechniqueDropDownPlaceHolder()"
							[values]="indicatorMachineComponentService.selectableMitreTechniques"
							[isDisabled] = "customTiIndicatorType.readonly || !isEditable"
							(ngModelChange) ="isDirty = true">
				</wcd-checklist-dropdown>
			</div>
		</div>
		<div *ngIf="customTiIndicator.action.id === CustomTiIndicatorActionsTypes.Warn">
			<div class="form-group" *ngIf="customTiIndicatorType.id !== CustomTiIndicatorsTypes.Files">
				<label for="custom-ti-bypassduration" >{{ "customTiIndicator.dataview.entity.fields.bypassDuration" | i18n}}</label>
				<input #bypassduration
					   type="number"
					   id="custom-ti-bypassduration"
					   [(ngModel)]="customTiIndicator.bypassDurationHours"
					   (ngModelChange) ="isDirty = true"
					   name="custom-ti-bypassduration"
					   class="form-control"
					   min="1"
					   [max]="MAX_BYPASSDURATION"
					   maxlength="3"
					   pattern="(^$|^[0-9]{1,3}$)"
					   [disabled] = "customTiIndicatorType.readonly || !isEditable"
					   [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }"/>
			</div>
			<div class="form-group">
				<label for="custom-ti-educateurl">{{ "customTiIndicator.dataview.entity.fields.educateUrl" | i18n}}</label>
				<input type="text"
					   id="custom-ti-educateurl"
					   [(ngModel)]="customTiIndicator.educateUrl"
					   (ngModelChange) ="isDirty = true"
					   name="custom-ti-educateurl"
					   class="form-control"
					   maxlength="100"
					   [pattern]="RegExpService.url"
					   [disabled] = "customTiIndicatorType.readonly || !isEditable"
					   [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }"/>
			</div>
		</div>
		<div class="form-group">
			<label for="custom-ti-description-title" class="wcd-required">{{ "customTiIndicator.dataview.entity.fields.description" | i18n}}</label>
			<textarea #description type="text"
					  id="custom-ti-description-title"
					  name="custom-ti-description-title"
					  class="form-control wcd-no-resize"
					  rows="4"
					  [(ngModel)]="customTiIndicator.description"
					  (ngModelChange) ="isDirty = true"
					  required
					  [pattern]="RegExpService.notEmpty"
					  [disabled] = "customTiIndicatorType.readonly || !isEditable"
					  [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }">
						</textarea>
		</div>
		<div class="form-group" *ngIf="indicatorMachineComponentService.isAlertable">
			<label for="custom-ti-recommendations-title">{{ "customTiIndicator.dataview.entity.fields.recommendations" | i18n}}</label>
			<textarea type="text"
					  id="custom-ti-recommendations-title"
					  name="custom-ti-recommendations-title"
					  class="form-control wcd-no-resize"
					  rows="4"
					  [(ngModel)]="customTiIndicator.recommendedActions"
					  (ngModelChange) ="isDirty = true"
					  [disabled] = "customTiIndicatorType.readonly || !isEditable"
					  [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }">
					</textarea>
		</div>
	</wcd-collapsible-section>
	<wcd-collapsible-section *ngIf='isOrganizationalScopeVisible'
		[sectionId]="collapsibleID.OrganizationalScope"
		[label]="'customTiIndicator.detailsSidePane.sections.organizationalscope.title' | i18n">
		<div *ngIf="indicatorMachineComponentService.loadingMachineGroups; else machineGroups">
			<i class="loader-icon margin-right"></i>
			{{'notificationRules.machineGroups.loading' | i18n}}
		</div>
		<ng-template #machineGroups>
			<ng-container *ngIf="indicatorMachineComponentService.allowAllMachineGroups">
				<wcd-radiolist [values]="indicatorMachineComponentService.selectableMachineGroupScopes"
							name="custom-ti-machine-group-options"
							data-field-label="Machine groups"
							(ngModelChange)="onMachineGroupScopeChange($event)"
							[(ngModel)]="indicatorMachineComponentService.currentMachineGroupScope"
							[isDisabled] = "customTiIndicatorType.readonly || !isEditable">
				</wcd-radiolist>
			</ng-container>
			<ng-container *ngIf="indicatorMachineComponentService.allowSpecificMachineGroups && indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.specific">
				<wcd-checklist-dropdown
					[id]="indicatorMachineComponentService.machineGroupsFieldId"
					[buttonText]="indicatorMachineComponentService.labelText"
					name="custom-ti-machine-groups"
					[buttonTooltip]="indicatorMachineComponentService.labelText"
					[selectById]="false"
					[ngModel]="indicatorMachineComponentService.currentMachineGroups"
					[isFullWidth]="true"
					(ngModelChange)="onMachineGroupsChange($event)"
					[values]="indicatorMachineComponentService.availableMachineGroups"
					[isDisabled] = "customTiIndicatorType.readonly || !isEditable">
				</wcd-checklist-dropdown>
			</ng-container>
		</ng-template>
	</wcd-collapsible-section>
</div>
<footer class="wcd-flex-none wcd-padding-large-horizontal wcd-padding-vertical wcd-border-top wcd-flex-horizontal">
	<div class="wcd-flex-1 wcd-margin-right" [rbac]="{ permissions: ['securitySettings', 'remediationActions'], state: 'disabled' }">
		<fab-primary-button
				className="wcd-margin-small-right"
				data-track-id="Save"
				data-track-type="Button"
				data-track-component="CustomTiIndicatorDetails"
				[disabled]="!isValid || !isDirty || !isEditable"
				(onClick)="save()">
			{{ 'buttons.save' | i18n }}
			<fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall" className="wcd-margin-xsmall-horizontal"></fab-spinner>
		</fab-primary-button>
	</div>
</footer>
