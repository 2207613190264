import { AfterViewInit, ElementRef } from '@angular/core';
import { DynamicScriptService } from '@wcd/code-editor';
import { Paris } from '@microsoft/paris';
import { SupportCentralApiCall } from '@wcd/domain';
import { LocaleConfigService } from '@wcd/localization';
import { of, from } from 'rxjs';
import { AppInsightsService } from '../insights/services/app-insights.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { getPortalLanguage } from '@wcd/i18n';
var SUPPORT_CENTRAL_MDATP_APP_NAME = 'mdatp';
var SUPPORT_CENTRAL_SUCCESS_EVENT_NAME = 'LoadSuccess';
var SUPPORT_CENTRAL_SCRIPT_PATH = '/js/support/concierge/external/supportcentralbootstrap.js';
var COLLAPSE_BUTTON_RIGHT_POSITION_PX = 20;
var COLLAPSE_BUTTON_WIDTH_PX = 32;
var SupportCentralComponent = /** @class */ (function () {
    function SupportCentralComponent(dynamicScriptService, paris, localeConfigService, appInsightsService) {
        var _this = this;
        this.dynamicScriptService = dynamicScriptService;
        this.paris = paris;
        this.localeConfigService = localeConfigService;
        this.appInsightsService = appInsightsService;
        this.withCollapseButton = false;
        this.isCollapsed = false;
        this.collapseButtonRightPositionPx = COLLAPSE_BUTTON_RIGHT_POSITION_PX;
        this.collapseButtonWidthPx = COLLAPSE_BUTTON_WIDTH_PX;
        this.handleEvent = function (event) {
            if (window['SupportCentral'] && window['SupportCentral'].HostingEvents) {
                var loadSuccessEvent = window['SupportCentral'].HostingEvents[SUPPORT_CENTRAL_SUCCESS_EVENT_NAME];
                if (event === loadSuccessEvent) {
                    _this.showCollapseButton();
                }
            }
        };
        this.refreshToken = function () {
            return _this.getConfigurationFromBackend()
                .pipe(map(function (configResponse) {
                return configResponse.token;
            }), catchError(function (err) {
                _this.appInsightsService.trackException(err, 'SupportCentralRefreshTokenFailed');
                return of(null);
            }))
                .toPromise();
        };
    }
    Object.defineProperty(SupportCentralComponent.prototype, "widgetParentDisplay", {
        get: function () {
            return this.isCollapsed ? 'none' : 'unset';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SupportCentralComponent.prototype, "collapseButtonIcon", {
        get: function () {
            return this.isCollapsed ? 'ChevronUp' : 'ChevronDown';
        },
        enumerable: true,
        configurable: true
    });
    SupportCentralComponent.prototype.showCollapseButton = function () {
        this.withCollapseButton = true;
    };
    SupportCentralComponent.prototype.toggleCollapse = function () {
        this.isCollapsed = !this.isCollapsed;
    };
    SupportCentralComponent.prototype.ngAfterViewInit = function () {
        this.loadSupportCentral();
    };
    SupportCentralComponent.prototype.loadSupportCentral = function () {
        var _this = this;
        this.getConfigurationFromBackend()
            .pipe(switchMap(function (configResponse) {
            return from(_this.loadScript(configResponse.endpointUrl + SUPPORT_CENTRAL_SCRIPT_PATH).then(function () {
                return configResponse;
            }));
        }))
            .subscribe(function (configResponse) {
            if (window['SupportCentral'] && window['SupportCentral'].init) {
                window['SupportCentral'].init(_this.getConfigurationObject(configResponse.token, configResponse.endpointUrl));
            }
            else {
                _this.appInsightsService.trackException(new Error('Failed to Load Support Central'));
            }
        }, function (err) {
            _this.appInsightsService.trackException(err, 'loadSupportCentral');
        });
    };
    SupportCentralComponent.prototype.loadScript = function (scriptUrl) {
        return this.dynamicScriptService.loadScripts({ urls: [scriptUrl] });
    };
    SupportCentralComponent.prototype.getConfigurationObject = function (token, endpointUrl) {
        return {
            parentDiv: this.parentElement.nativeElement,
            appName: SUPPORT_CENTRAL_MDATP_APP_NAME,
            auth: {
                endpointUrl: endpointUrl,
                token: token,
                tokenRefresh: this.refreshToken,
            },
            settings: {
                disableMinimization: false,
                maximizeByDefault: false,
                locale: getPortalLanguage(),
            },
            styles: {
                minStateStyle: {
                    position: 'absolute',
                    right: this.collapseButtonRightPositionPx + this.collapseButtonWidthPx + "px",
                    bottom: '0',
                },
                maxStateStyle: {
                    position: 'absolute',
                    height: '100%',
                    width: '485px',
                    right: '0',
                    bottom: '0',
                },
            },
            eventListener: this.handleEvent,
        };
    };
    SupportCentralComponent.prototype.getConfigurationFromBackend = function () {
        return this.paris.apiCall(SupportCentralApiCall);
    };
    return SupportCentralComponent;
}());
export { SupportCentralComponent };
