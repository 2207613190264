import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { Registry, RegistryModificationDetails } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';

const REGISTRY_PROPERTIES = ['key', 'valueName', 'valueData', 'valueType'];

@Component({
	selector: 'registry-modification-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './registry-modification.entity-details.component.html',
})
export class RegistryModificationEntityDetailsComponent extends EntityDetailsComponentBase<
	RegistryModificationDetails
> {
	registryProperties = REGISTRY_PROPERTIES;

	get current(): Registry {
		return this.entity && this.entity.current;
	}

	get previous(): Registry {
		return this.entity && this.entity.previous;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
