import { Injectable } from '@angular/core';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Investigation, InvestigationStatus, LiveResponseSession, MtpInvestigation } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { InvestigationGraphService } from '../../../graph/airs-investigation/services/investigation-graph.service';
import { distinctUntilChanged, switchMap, takeWhile } from 'rxjs/operators';
import { InvestigationEntityPanelComponent } from '../components/investigation.entity-panel.component';
import { InvestigationsService } from './investigations.service';
import { WcdIconNames } from '@wcd/icons';
import { AppContextService, Feature, FeaturesService, PollingService } from '@wcd/config';
import { MtpInvestigationsService } from '../../mtp_investigations/services/mtp-investigations.service';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class InvestigationEntityTypeService implements EntityTypeService<Investigation> {
	constructor(
		private paris: Paris,
		private investigationsService: InvestigationsService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private appContextService: AppContextService,
		private appConfigService: AppConfigService,
		private pollingService: PollingService,
		private featuresService: FeaturesService
	) {}

	readonly entityType: EntityType<Investigation> = {
		id: 'investigation',
		icon: WcdIconNames.investigationoutline,
		entity: Investigation,
		getItem: (investigation, loadedEntity$, options, allowCache) => {
			if (
				investigation.isOfficeInvestigation &&
				this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)
			) {
				return this.paris.getItemById(
					MtpInvestigation,
					investigation.id,
					{ allowCache: allowCache },
					{
						tenantId: this.appConfigService.tenantId,
					}
				);
			}
			return loadedEntity$;
		},
		getItemParams: (entity, options) =>
			this.appContextService.isSCC ? { tenantId: sccHostService.loginUser.tenantId } : null,
		getEntityPolling: entityGetter$ =>
			this.pollingService.poll(5000, 5000).pipe(
				switchMap(() => entityGetter$),
				distinctUntilChanged(
					(prev, curr) =>
						!Object.keys(InvestigationGraphService.getInvestigationDifference(prev, curr)).length
				),
				takeWhile(i => i.isRunning, true)
			),
		getImage: (investigations: Array<Investigation>) => {
			return GlobalEntityTypesService.getCommonValue(
				investigations,
				investigation => investigation.status.image
			);
		},
		getIcon: (investigations: Array<Investigation>) => {
			return (
				GlobalEntityTypesService.getCommonValue(investigations, investigation => {
					if (investigation.isLiveResponse) {
						return this.globalEntityTypesService.getEntityTypeIcon(LiveResponseSession);
					}
					return investigation.status && investigation.status.iconName;
				}) || WcdIconNames.investigationoutline
			);
		},
		getIconCssClass: (investigations: Array<Investigation>) => {
			const commonStatus: InvestigationStatus = GlobalEntityTypesService.getCommonValue(
				investigations,
				investigation => investigation.status
			);
			if (commonStatus) return `color-text-${commonStatus.className}`;
		},
		singleEntityPanelComponentType: InvestigationEntityPanelComponent,
		getEntityClassName: (investigation: Investigation) => investigation.status.className,
		getEntityName: (investigation: Investigation) =>
			investigation.title || (investigation.alertRuleNames && investigation.alertRuleNames[0]),
		getEntityDataviewLink: () => '/investigations',
		getEntitiesLink: (investigations: Array<Investigation>) =>
			investigations && investigations.length === 1
				? (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)
						? MtpInvestigationsService
						: InvestigationsService
				  ).getInvestigationLink(investigations[0])
				: null,

		getNavigationModel: (investigation: Investigation) => {
			if (investigation.isOfficeInvestigation && !investigation.doesInvestigationExist) return null;
			if (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)) {
				return {
					routerLink: [this.entityType.getEntitiesLink([investigation])],
				};
			} else {
				return (
					(investigation.externalInvestigationPageUrl || investigation.id) && {
						externalLink: investigation.externalInvestigationPageUrl,
						routerLink: !investigation.externalInvestigationPageUrl
							? [this.entityType.getEntitiesLink([investigation])]
							: null,
						openInNewTab:
							this.appContextService.isMtp &&
							Boolean(investigation.externalInvestigationPageUrl),
					}
				);
			}
		},
		getUseExternalRouting: (investigations: Array<Investigation>) =>
			!this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
			investigations &&
			investigations.length === 1 &&
			Boolean(investigations[0].externalInvestigationPageUrl),
		getActions: (investigations: Array<Investigation>) =>
			this.investigationsService.getInvestigationsActions(investigations),
	};
}
