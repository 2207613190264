import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { PreferencesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "../../insights/services/app-insights.service";
import * as i2 from "../../../../../../projects/config/src/lib/services/preferences.service";
import * as i3 from "../../../../../../projects/config/src/lib/services/features.service";
import * as i4 from "../../../../../../projects/config/src/lib/services/app-context.service";
var DISMISS_STORAGE_KEY = 'mtpPromotion-dismissed';
var MtpPromotionService = /** @class */ (function () {
    function MtpPromotionService(appInsights, preferenceService, featuresService, appContextService) {
        this.appInsights = appInsights;
        this.preferenceService = preferenceService;
        this.featuresService = featuresService;
        this.appContextService = appContextService;
        this.promotionVisibleSubject = new BehaviorSubject(false);
        this._isMtpBannerFeatureEnabled = false;
        this.promoWidgetVisible$ = this.promotionVisibleSubject.asObservable();
        this._mtpPromotionDismissed = !!this.preferenceService.getPreference(DISMISS_STORAGE_KEY);
        this._isMtpBannerFeatureEnabled =
            !this.appContextService.isSCC && this.featuresService.isEnabled(Feature.ExperienceMtp);
        this.promotionVisibleSubject.next(this._isMtpBannerFeatureEnabled ? !this._mtpPromotionDismissed : false);
    }
    Object.defineProperty(MtpPromotionService.prototype, "mtpPromotionWidgetDismissed", {
        get: function () {
            return this._mtpPromotionDismissed;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpPromotionService.prototype, "isMtpBannerVisible", {
        get: function () {
            return (this._isMtpBannerFeatureEnabled &&
                !this._mtpPromotionDismissed);
        },
        enumerable: true,
        configurable: true
    });
    MtpPromotionService.prototype.dismissMtpPromotion = function (trackEvent) {
        if (trackEvent === void 0) { trackEvent = true; }
        this._mtpPromotionDismissed = true;
        this.preferenceService.setPreference(DISMISS_STORAGE_KEY, 'true');
        this.promotionVisibleSubject.next(false);
        if (trackEvent) {
            this.trackMtpPromotionWidgetEvent(true);
        }
    };
    MtpPromotionService.prototype.trackMtpPromotionWidgetEvent = function (isDecline) {
        this.appInsights.trackEvent("EndpointMtpPromotion-DashboardTile" + (isDecline ? 'Dismiss' : 'Button'), {
            Page: 'dashboard',
        });
    };
    MtpPromotionService.prototype.ngOnDestroy = function () {
        this.promotionVisibleSubject.complete();
    };
    MtpPromotionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MtpPromotionService_Factory() { return new MtpPromotionService(i0.ɵɵinject(i1.AppInsightsService), i0.ɵɵinject(i2.PreferencesService), i0.ɵɵinject(i3.FeaturesService), i0.ɵɵinject(i4.AppContextService)); }, token: MtpPromotionService, providedIn: "root" });
    return MtpPromotionService;
}());
export { MtpPromotionService };
