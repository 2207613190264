/* tslint:disable:template-accessibility-alt-text */
import { Component, OnInit } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { EndpointManagementService } from '../../services/endpoint-management.service';

@Component({
	selector: 'manage-android-first-party-onboarding',
	templateUrl: './manage-android-first-party-onboarding.component.html',
})
export class ManageAndroidFirstPartyOnboardingComponent implements OnInit {
	readonly apkFileName = 'ms-agent.apk';
	isAndroidEnabledGA: boolean;

	constructor(
		private featuresService: FeaturesService,
		private endPointManagementService: EndpointManagementService
	) {}

	ngOnInit() {
		this.isAndroidEnabledGA = this.featuresService.isEnabled(Feature.AndroidEndPointManagement);
	}

	downloadApk() {
		this.endPointManagementService.downloadApk(this.apkFileName);
	}
}
