var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ComponentRef } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AlertStatus, DomainCategory, Severity } from '@wcd/domain';
import { of } from 'rxjs';
import { DomainExposedMachinesFullComponent } from '../components/domain-exposed-machines-full.dataview.component';
import { PanelType } from '@wcd/panels';
import { DomainUrlsFullComponent } from '../components/domain-urls-full.dataview.component';
import { HttpClient } from '@angular/common/http';
import { CsvService } from '../../../shared/services/csv.service';
import { DownloadService } from '../../../shared/services/download.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
var EXPORT_TO_CSV_TOKEN_URL = 'domains/ExportDomainsList';
var EXPORT_TO_CSV_URL = 'domains/DownloadDomainsList';
var DomainsService = /** @class */ (function (_super) {
    __extends(DomainsService, _super);
    function DomainsService(dialogsService, http, downloadService, csvService, serviceUrlsService, i18nService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.dialogsService = dialogsService;
        _this.csvService = csvService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(DomainsService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.webThreatProtection;
        },
        enumerable: true,
        configurable: true
    });
    DomainsService.prototype.getDomainsFilters = function () {
        var _this = this;
        var filters$ = of({
            severity: {
                count: null,
                values: Severity.entityConfig.values
                    .filter(function (severity) { return severity.isSelectable; })
                    .map(function (severity) {
                    return {
                        value: severity.id,
                        name: _this.i18nService.get(severity.nameI18nKey),
                        count: null,
                        priority: severity.priority,
                    };
                }),
            },
            webThreatCategory: {
                count: null,
                values: DomainCategory.entityConfig.values.map(function (category) {
                    return {
                        value: category.type,
                        name: category.name,
                        count: null,
                        priority: category.priority,
                    };
                }),
            },
            status: {
                count: null,
                values: AlertStatus.entityConfig.values
                    .filter(function (status) { return status.isSelectable; })
                    .map(function (status) {
                    return {
                        value: status.id,
                        name: _this.i18nService.get(status.nameI18nKey),
                        count: null,
                        priority: status.priority,
                    };
                }),
            },
        });
        return filters$;
    };
    DomainsService.prototype.showAllExposedMachines = function (domainIdSelected) {
        return this.dialogsService.showPanel(DomainExposedMachinesFullComponent, {
            id: 'exposed-machines-panel',
            headerText: this.i18nService.get('reporting.webThreatReport.domainList.exposedMachinesTitle'),
            type: PanelType.largeFixed,
            isModal: true,
            scrollBody: true,
            noShadow: true,
            width: 500,
            persistOnNavigate: false,
            noBodyPadding: true,
        }, {
            domainIdSelected: domainIdSelected,
        });
    };
    DomainsService.prototype.showAllDomains = function (domainIdSelected) {
        return this.dialogsService.showPanel(DomainUrlsFullComponent, {
            id: 'domain-urls-panel',
            headerText: this.i18nService.get('reporting.webThreatReport.domainList.urlTitle'),
            type: PanelType.largeFixed,
            isModal: true,
            scrollBody: true,
            noShadow: true,
            width: 500,
            persistOnNavigate: false,
            noBodyPadding: true,
        }, {
            domainIdSelected: domainIdSelected,
        });
    };
    DomainsService.prototype.downloadCsv = function (options) {
        return this.csvService.downloadCsv({
            tokenUrl: this.apiUrl + "/" + EXPORT_TO_CSV_TOKEN_URL,
            apiUrl: this.apiUrl + "/" + EXPORT_TO_CSV_URL,
            params: options,
        });
    };
    return DomainsService;
}(StoreBackendBase));
export { DomainsService };
