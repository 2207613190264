import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
	template: `
		<div class="wcd-padding-horizontal">
			<h1 class="wcd-padding-small-bottom">
				Getting authorization code completed with {{ status }} status
			</h1>
			<h3>[If this window does not close automatically in few seconds, please close it manually]</h3>
			<form>
				<input id="status" type="hidden" [value]="status" />
				<input id="authCode" type="hidden" [value]="authCode" />
				<input id="error" type="hidden" [value]="error" />
			</form>
		</div>
	`,
})
export class AuthCodeRetrievalComponent {
	error: string = '';
	authCode: string = '';
	status: 'Failure' | 'Success';

	constructor(route: ActivatedRoute) {
		const { error, authCode } = route.snapshot.queryParams;
		if (error || !authCode) {
			this.error = error || '';
			this.status = 'Failure';
		} else {
			this.authCode = authCode;
			this.status = 'Success';
		}
	}
}
