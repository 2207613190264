import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Undo evidence remediation',
	endpoint: 'autoir/entities/evidence/v2/undo',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	parseQuery: entityData => ({
		data: {
			evidence_data: entityData.map(e => ({ merge_by_key: e.mergeByKey, alert_id: e.alertId })),
		},
	}),
})
export class UndoEvidenceRemediationApiCall extends ApiCallModel<
	void,
	Array<{ mergeByKey: string; alertId: string }>
> {}
