import { Alert } from '@wcd/domain';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { ModelBase, Paris } from '@microsoft/paris';
import { EntityPanelWithMachineComponent } from './entity-panel-with-machine.component.base';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { switchMap } from 'rxjs/operators';

export class EntityPanelWithAlertComponent<T extends ModelBase = ModelBase>
	extends EntityPanelWithMachineComponent<T>
	implements OnDestroy {
	alert: Alert;
	isLoadingAlert: boolean = false;

	private _alertSubscription: Subscription;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		rbacService: RbacService,
		machinesService: MachinesService
	) {
		super(changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService);
	}

	initEntity(entity: T, isExtendedData: boolean = false) {
		this.alert = null;
		this.isLoadingAlert = false;
		super.initEntity(entity, isExtendedData);
	}

	ngOnDestroy() {
		this._alertSubscription && this._alertSubscription.unsubscribe();
	}

	setAlert(alertId: string) {
		this.alert = null;
		this.isLoadingAlert = true;
		this.changeDetectorRef.markForCheck();

		this._alertSubscription = this.rbacService
			.isUserExposedToEntity(this.globalEntityTypesService.getEntityType(Alert), alertId)
			.pipe(
				switchMap(userExposureResult => {
					if (userExposureResult.isExposed)
						return this.paris.getRepository(Alert).getItemById(alertId);
					return of(null);
				})
			)
			.subscribe(
				(alert: Alert) => {
					this.isLoadingAlert = false;
					this.alert = alert;
					this.changeDetectorRef.markForCheck();
				},
				error => {
					this.isLoadingAlert = false;
					this.changeDetectorRef.markForCheck();
				}
			);
	}
}
