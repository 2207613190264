import { AirsEntityType } from './airs-entity-type.entity';
import { AlertV3EntityTypes } from './alertV3/alertV3-entity-types.enum';

export const airsEntityTypeConfigs = [
	{
		id: AirsEntityType.MailCluster,
		name: 'Email Clusters',
		alertV3Name: AlertV3EntityTypes.MailCluster,
		singularName: 'Email Cluster',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.Mailbox,
		name: 'Mailboxes',
		alertV3Name: AlertV3EntityTypes.Mailbox,
		singularName: 'Mailbox',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.MailMessage,
		name: 'Emails',
		alertV3Name: AlertV3EntityTypes.MailMessage,
		singularName: 'Email',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.SubmissionMail,
		name: 'Email Submissions',
		alertV3Name: AlertV3EntityTypes.SubmissionMail,
		singularName: 'Email Submission',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.MailboxConfiguration,
		name: 'Mailbox configurations',
		alertV3Name: AlertV3EntityTypes.MailboxConfiguration,
		singularName: 'Mailbox configuration',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.UserActivity,
		name: 'User Activities',
		alertV3Name: AlertV3EntityTypes.UserActivity,
		singularName: 'User Activity',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.File,
		name: 'Files',
		alertV3Name: AlertV3EntityTypes.File,
		singularName: 'File',
		systemExclusionType: 'files',
	},
	{
		id: AirsEntityType.Process,
		name: 'Processes',
		alertV3Name: AlertV3EntityTypes.Process,
		singularName: 'Process',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.Service,
		name: 'Services',
		alertV3Name: AlertV3EntityTypes.Service,
		singularName: 'Service',
		systemExclusionType: null,
		extendedModeOnly: true,
	},
	{
		id: AirsEntityType.Driver,
		name: 'Drivers',
		alertV3Name: AlertV3EntityTypes.Driver,
		singularName: 'Driver',
		systemExclusionType: null,
		extendedModeOnly: true,
	},
	{
		id: AirsEntityType.IP,
		name: 'IP Addresses',
		alertV3Name: AlertV3EntityTypes.IP,
		singularName: 'IP Address',
		systemExclusionType: 'ip',
	},
	{
		id: AirsEntityType.WebPage,
		name: 'URLs',
		alertV3Name: AlertV3EntityTypes.WebPage,
		singularName: 'URL',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.URL,
		name: 'URLs',
		alertV3Name: AlertV3EntityTypes.URL,
		singularName: 'URL',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.User,
		name: 'Users',
		alertV3Name: AlertV3EntityTypes.User,
		singularName: 'User',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.AppendedFile,
		name: 'Appended Files',
		alertV3Name: AlertV3EntityTypes.AppendedFile,
		singularName: 'Appended File',
		systemExclusionType: 'files',
	},
	{
		id: AirsEntityType.EmailUrl,
		name: 'Email links',
		alertV3Name: AlertV3EntityTypes.EmailUrl,
		singularName: 'Email link',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.PersistenceMethod,
		name: 'Persistence Methods',
		alertV3Name: AlertV3EntityTypes.PersistenceMethod,
		singularName: 'Persistence Method',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.Account,
		name: 'Accounts',
		alertV3Name: AlertV3EntityTypes.Account,
		singularName: 'Account',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.SecurityGroup,
		name: 'Security Groups',
		alertV3Name: AlertV3EntityTypes.SecurityGroup,
		singularName: 'Security group',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.RegistryKey,
		name: 'Registry Keys',
		alertV3Name: AlertV3EntityTypes.RegistryKey,
		singularName: 'Registry Key',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.RegistryValue,
		name: 'Registry Values',
		alertV3Name: AlertV3EntityTypes.RegistryValue,
		singularName: 'Registry Value',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.CloudApplication,
		name: 'Cloud Applications',
		alertV3Name: AlertV3EntityTypes.CloudApplication,
		singularName: 'Cloud Application',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.CloudLogonSession,
		name: 'Cloud Logon Sessions',
		alertV3Name: AlertV3EntityTypes.CloudLogonSession,
		singularName: 'Cloud Logon Session',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.OauthApplication,
		name: 'OAuth Applications',
		alertV3Name: AlertV3EntityTypes.OauthApplication,
		singularName: 'OAuth Application',
		systemExclusionType: null,
	},
	{
		id: AirsEntityType.ActiveDirectoryDomain,
		name: 'Active Directory Domains',
		alertV3Name: AlertV3EntityTypes.ActiveDirectoryDomain,
		singularName: 'Active Directory Domain',
		systemExclusionType: null,
	},
];
