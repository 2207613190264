import { ComponentRef } from '@angular/core';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { WeaknessSeverity, AgeFilterOptions, FilterImpactedAssets } from '@wcd/domain';
import { VulnerabilityPanelComponent } from '../components/vulnerability-panel.component';
import { of } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { ThreatInfoService } from '../../software-inventory/services/threat-info.service';
var VulnerabilityService = /** @class */ (function () {
    function VulnerabilityService(dialogsService, i18nService, threatInfoService, featuresService) {
        var _a;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.threatInfoService = threatInfoService;
        this._weaknessSeverityTypesToIgnore = [WeaknessSeverity.None, WeaknessSeverity.Unknown];
        this._threatFilterValues = [0, 1];
        if (featuresService.isEnabled(Feature.TvmExploits)) {
            (_a = this._threatFilterValues).push.apply(_a, [2, 3]);
        }
    }
    VulnerabilityService.prototype.showVulnerabilityPanel = function (vulnerability, includeExposedMachinesComponent, isNetworkGearCve) {
        var _this = this;
        this.dialogsService
            .showPanel(VulnerabilityPanelComponent, {
            id: 'vulnerabilities-panel',
            isModal: true,
            showOverlay: false,
            hasCloseButton: true,
            type: PanelType.large,
            noBodyPadding: true,
            scrollBody: true,
            isBlocking: false,
            back: {
                onClick: function () { return _this._vulnerabilityPanel && _this._vulnerabilityPanel.destroy(); },
            },
        }, {
            vulnerability: vulnerability,
            includeExposedMachinesComponent: includeExposedMachinesComponent,
            isNetworkGearCve: isNetworkGearCve,
        })
            .subscribe(function (panel) {
            _this._vulnerabilityPanel = panel;
            panel.onDestroy(function () {
                _this._vulnerabilityPanel = null;
            });
        });
    };
    VulnerabilityService.prototype.getFiltersData = function () {
        var _this = this;
        if (!this._vulnerabilitiesFilter) {
            this._vulnerabilitiesFilter = {
                numOfImpactedAssets: {
                    values: [
                        {
                            value: FilterImpactedAssets.hasEffect,
                            name: this.i18nService.get('tvm.vulnerabilitiesPage.filters.numOfImpactedAssets.hasEffect'),
                        },
                        {
                            value: FilterImpactedAssets.noEffect,
                            name: this.i18nService.get('tvm.vulnerabilitiesPage.filters.numOfImpactedAssets.noEffect'),
                        },
                        {
                            value: FilterImpactedAssets.notAvailable,
                            name: this.i18nService.get('notAvailable.long'),
                        },
                    ],
                },
                severity: {
                    values: Object.keys(WeaknessSeverity)
                        .filter(function (type) { return _this._weaknessSeverityTypesToIgnore.indexOf(WeaknessSeverity[type]) < 0; })
                        .map(function (type) {
                        return {
                            value: type,
                            name: WeaknessSeverity[type],
                            priority: Object.keys(WeaknessSeverity).indexOf(type) + 1,
                        };
                    }),
                },
                threats: { values: this.threatInfoService.getFiltersData(true) },
                age: {
                    values: Object.keys(AgeFilterOptions).map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.vulnerabilitiesPage.filters.age." + AgeFilterOptions[type]),
                        };
                    }),
                },
            };
        }
        return of(Object.keys(this._vulnerabilitiesFilter).reduce(function (res, key) {
            var _a;
            return Object.assign(res, (_a = {}, _a[key] = _this._vulnerabilitiesFilter[key], _a));
        }, {}));
    };
    return VulnerabilityService;
}());
export { VulnerabilityService };
