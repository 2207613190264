
		<div class="wcd-flex wcd-flex-wrap">
			<dl role="none">
				<!--Submission time-->
				<dt role="none">{{ i18nService.strings.actionsHistory_panel_section_submissionTime }}</dt>
				<dd role="none">
					{{
						(actionHistory.startTime || actionHistory.updateTime | date: 'medium') ||
							i18nService.strings.notAvailable_short
					}}
				</dd>

				<!--Submitted by-->
				<dt role="none">{{ i18nService.strings.actionsHistory_panel_section_submittedBy }}</dt>
				<dd role="none">{{ actionHistory.decidedBy || i18nService.strings.notAvailable_short }}</dd>
			</dl>
			<dl role="none">
				<!--Action status-->
				<dt role="none">{{ i18nService.strings.actionsHistory_panel_section_actionStatus }}</dt>
				<dd role="none"><wcd-status [status]="actionHistory.status"></wcd-status></dd>

				<!--Approval ID-->
				<ng-container *ngIf="showApprovalId">
					<dt role="none">{{ i18nService.strings.actionsHistory_panel_section_approvalId }}</dt>
					<dd role="none">{{ actionHistory.shortApprovalId }}</dd>
				</ng-container>
			</dl>
			<dl role="none">
				<!--Remediation name-->
				<ng-container *ngIf="showRemediationName">
					<dt role="none">
						{{ i18nService.strings.actionsHistory_panel_section_remediationName }}
					</dt>
					<dd role="none">{{ actionHistory.bulkName }}</dd>
				</ng-container>
			</dl>
			<dl role="none">
				<!--Action source-->
				<ng-container *ngIf="showActionSource">
					<dt role="none">
						{{ i18nService.strings.actionsHistory_panel_section_actionSource }}
					</dt>
					<dd role="none">
						{{
							i18nService.get(
								'actionCenter_fields_actionSourceType_' + actionHistory.actionSourceI18nKey,
								null,
								true
							) || actionHistory.actionSourceI18nKey
						}}
					</dd>
				</ng-container>
			</dl>
		</div>
	