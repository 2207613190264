var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function (itemId, entity, config, params) {
    var url = 'on-boarding/airs_status/query_airs_status';
    var sliceId = params && params['sliceId'];
    if (sliceId) {
        return url + "?slice_id=" + sliceId;
    }
    return url;
}, ɵ1 = function (value) {
    return "on-boarding/airs_status/toggle_airs_status?state=" + (value.isEnabled ? 'enable' : 'disable') +
        (value.sliceId ? "&slice_id=" + value.sliceId : '');
}, ɵ2 = function (config) { return config.data.serviceUrls.automatedIr; };
var AutomatedIrStatus = /** @class */ (function (_super) {
    __extends(AutomatedIrStatus, _super);
    function AutomatedIrStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'enabled',
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], AutomatedIrStatus.prototype, "isEnabled", void 0);
    __decorate([
        EntityField({
            data: 'slice_id',
        }),
        __metadata("design:type", Number)
    ], AutomatedIrStatus.prototype, "sliceId", void 0);
    AutomatedIrStatus = __decorate([
        Entity({
            singularName: 'Automated Investigation status',
            pluralName: 'Automated Investigation status',
            endpoint: 'on-boarding/airs_status',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            saveMethod: 'POST',
            readonly: true,
            baseUrl: ɵ2,
        })
    ], AutomatedIrStatus);
    return AutomatedIrStatus;
}(EntityModelBase));
export { AutomatedIrStatus };
export { ɵ0, ɵ1, ɵ2 };
