var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { AssetFieldsService } from './../../../../@entities/@tvm/asset/services/asset.fields.service';
import { PrettyNumberService } from './../../../../../../../../projects/prettify/src/lib/services/pretty-number.service';
import { I18nService } from './../../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { DataviewField } from './../../../../../../../../projects/dataview/src/lib/models/dataview-field.model';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { Asset } from '@wcd/domain';
import { TvmExposureScoreService } from '../../../services/tvm-exposure-score.service';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TextToken, TvmTextsService } from '../../../services/tvm-texts.service';
import { zip } from 'rxjs';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
var TvmTopExposedMachinesWidget = /** @class */ (function (_super) {
    __extends(TvmTopExposedMachinesWidget, _super);
    function TvmTopExposedMachinesWidget(reportsService, i18nService, tvmExposureScoreService, prettyNumberService, assetFieldsService, tvmTextsService, machineGroupsFilterService, router, paris) {
        var _this = _super.call(this, reportsService) || this;
        _this.i18nService = i18nService;
        _this.tvmTextsService = tvmTextsService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.router = router;
        _this.paris = paris;
        _this.fields = assetFieldsService.fields.map(function (field) { return new DataviewField(Object.assign({}, field, { sort: { enabled: false } })); });
        return _this;
    }
    Object.defineProperty(TvmTopExposedMachinesWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'topExposedMachinesWidget',
                name: this.i18nService.get('tvm.dashboard.topExposedDevices'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.dashboard.noData.topExposedMachines',
                        isGroupSelected: _this.isMgSelected,
                    });
                },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    var exposedMachines$ = _this.paris
                        .query(Asset, {
                        page: 1,
                        pageSize: 3,
                        sortBy: [
                            {
                                field: 'exposureScore',
                                direction: DataQuerySortDirection.descending,
                            },
                        ],
                    })
                        .pipe(map(function (ds) { return ds.items.sort(function (a, b) { return b.exposureScore - a.exposureScore; }).slice(0, 3); }));
                    return zip(mg$, exposedMachines$).pipe(map(function (_a) {
                        var mg = _a[0], exposedMachines = _a[1];
                        _this.isMgSelected = mg.isFiltering;
                        return exposedMachines;
                    }));
                },
                minHeight: '210px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmTopExposedMachinesWidget.prototype.openMachinesPage = function () {
        this.router.navigate(['/machines']);
    };
    TvmTopExposedMachinesWidget.prototype.openMachinePage = function (asset) {
        this.router.navigate(['/machines', asset.id]);
    };
    return TvmTopExposedMachinesWidget;
}(ReportWidgetComponent));
export { TvmTopExposedMachinesWidget };
