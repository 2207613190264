var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineGroupSimpleRule } from './machine-group-simple-rule.value-object';
import { MachineGroupAssignment } from './machine-group-assignment.value-object';
import { ProtectionLevel } from '../protection-level/protection-level.entity';
var ɵ0 = function (dataQuery) {
    var defaultParams = {
        addAadGroupNames: 'false',
        addMachineGroupCount: 'false',
    };
    if (dataQuery && typeof dataQuery.where === 'string') {
        throw new Error('Error in machine Groups entity: where is string and not an object');
    }
    return Object.assign({}, defaultParams, dataQuery && dataQuery.where);
}, ɵ1 = function (config) { return config.data.serviceUrls.rbacManagementApi; }, ɵ2 = function (items) {
    return { data: items };
};
var MachineGroup = /** @class */ (function (_super) {
    __extends(MachineGroup, _super);
    function MachineGroup() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'MachineGroupId' }),
        __metadata("design:type", Number)
    ], MachineGroup.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], MachineGroup.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], MachineGroup.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'AutoRemediationLevel' }),
        __metadata("design:type", ProtectionLevel)
    ], MachineGroup.prototype, "protectionLevel", void 0);
    __decorate([
        EntityField({ data: 'Priority' }),
        __metadata("design:type", Number)
    ], MachineGroup.prototype, "priority", void 0);
    __decorate([
        EntityField({ data: 'IsUnassignedMachineGroup', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], MachineGroup.prototype, "isUnassignedMachineGroup", void 0);
    __decorate([
        EntityField({ data: 'MachineCount', defaultValue: 0 }),
        __metadata("design:type", Number)
    ], MachineGroup.prototype, "machineCount", void 0);
    __decorate([
        EntityField({ data: 'LastUpdated' }),
        __metadata("design:type", Date)
    ], MachineGroup.prototype, "lastUpdatedDate", void 0);
    __decorate([
        EntityField({ data: 'LastUpdated' }),
        __metadata("design:type", String)
    ], MachineGroup.prototype, "lastUpdated", void 0);
    __decorate([
        EntityField({ data: 'GroupRules', arrayOf: MachineGroupSimpleRule }),
        __metadata("design:type", Array)
    ], MachineGroup.prototype, "rules", void 0);
    __decorate([
        EntityField({
            data: 'MachineGroupAssignments',
            arrayOf: MachineGroupAssignment,
        }),
        __metadata("design:type", Array)
    ], MachineGroup.prototype, "assignments", void 0);
    __decorate([
        EntityField({ data: 'OldMachineGroupId' }),
        __metadata("design:type", Number)
    ], MachineGroup.prototype, "oldMachineGroupId", void 0);
    MachineGroup = __decorate([
        Entity({
            singularName: 'Device Group',
            pluralName: 'Device Groups',
            endpoint: 'rbac/machine_groups',
            parseDataQuery: ɵ0,
            allItemsEndpointTrailingSlash: false,
            loadAll: true,
            allItemsProperty: 'items',
            baseUrl: ɵ1,
            saveMethod: 'PUT',
            parseSaveItemsQuery: ɵ2,
        })
    ], MachineGroup);
    return MachineGroup;
}(EntityModelBase));
export { MachineGroup };
export { ɵ0, ɵ1, ɵ2 };
