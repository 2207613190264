import { Entity, ModelBase, EntityField, DataQuery } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { ThreatInfo } from '../common/threat-info.value-object';
import { EolState } from '../software-version/eol-version-state.enum';

// TODO: there is a bug in paris - use of idProperty: 'assetId' has no affect and the id is null
@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	//enriching the query with the Asset's exposure score:
	parseDataQuery: (dataQuery: DataQuery) => ({
		...TvmAnalyticsSharedEntityConfigurations.parseDataQuery(dataQuery),
		$expand: 'Asset($select=ExposureScore)',
	}),
	singularName: 'installation',
	pluralName: 'installations',
	endpoint: 'installations',
	readonly: true,
})
export class SoftwareInstallationBase extends ModelBase {
	@EntityField({
		required: true,
	})
	readonly installationId: string;

	@EntityField({
		required: true,
		parse: (_, item) => parseEntityName(item.productName),
	})
	readonly name: string;

	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly productName: string;

	@EntityField({
		required: true,
		parse: parseEntityName,
	})
	readonly vendor: string;

	@EntityField({
		required: true,
	})
	readonly assetId: string;

	@EntityField({
		required: true,
	})
	readonly assetName: string;

	@EntityField({
		required: true,
	})
	readonly firstSeen: string;

	@EntityField({
		required: true,
	})
	readonly lastSeen: string;

	@EntityField({
		required: true,
	})
	readonly weaknesses: number;

	@EntityField({
		required: true,
	})
	readonly threatInfo: ThreatInfo;

	@EntityField({
		required: true,
	})
	readonly highSevAlert: boolean;

	@EntityField({
		required: true,
	})
	readonly osName: string;

	@EntityField({
		required: true,
	})
	readonly isNormalized: boolean;

	@EntityField()
	readonly eolSoftwareState: EolState;

	@EntityField()
	readonly eolSoftwareSinceDate: Date;

	@EntityField()
	readonly productNeverMatched: boolean;

	// We want to remove the version from the installationId, to get the productId, which ends in the last -_-
	// Example: apple-_-safari-_-3.3.2 => apple-_-safari
	getProductId(): string {
		const n = this.installationId.lastIndexOf('_');
		return this.installationId.substr(0, n - 1);
	}

	/*
	TODO: re-add when BE returns this data again (bug 20713425)
	@EntityField({
		parse: (_, data) => (data.asset && data.asset.exposureLevel ? data.asset.exposureLevel : null),
	})
	readonly exposureCategory: ExposureScoreCategory; */
}
