<div
	class="wcd-full-height wcd-scroll-vertical wcd-padding-large-left wcd-padding-large-bottom"
	data-track-component="FolderExclusions"
>
	<div [hidden]="!folderExclusionsStore.isLoading" class="wcd-full-height loading-contents center-text">
		<div>
			<i class="large-loader-icon"></i>
			<div>{{ i18nService.strings.admin_folderExclusions_loading }}</div>
		</div>
	</div>
	<div class="large-form" [hidden]="folderExclusionsStore.isLoading">
		<p class="wcd-margin-large-bottom">
			{{ i18nService.strings.folderExclusions_description }}
		</p>
		<div class="wcd-border-bottom">
			<fab-primary-button
				className=" wcd-margin-bottom"
				data-track-id="AddNew"
				data-track-type="Button"
				*ngIf="!newFolderExclusion"
				[rbac]="{ permissions: ['securitySettings'], state: 'hidden' }"
				(onClick)="addNewFolderExclusion()"
			>
				<wcd-shared-icon iconName="Add" [ariaLabel]="''"> </wcd-shared-icon>
				{{ i18nService.strings.admin_folderExclusions_addFolderExclusion }}
			</fab-primary-button>
		</div>
		<section class="form-section">
			<div *ngLet="(folderExclusionsItems$ | async) as folderExclusionsItems">
				<div
					class="no-data-message wcd-flex-center-vertical"
					[hidden]="newFolderExclusion || folderExclusionsItems?.length"
				>
					<div class="widget-nodata-message">
						{{ i18nService.strings.admin_folderExclusions_noFolderExclusions }}
					</div>
				</div>
				<ul class="form-list">
					<li *ngIf="newFolderExclusion" class="clearfix">
						<wcd-shared-icon [iconName]="'folderExclusion'" class="form-list-item-icon">
						</wcd-shared-icon>
						<div class="pull-left">
							<folder-exclusion-form
								[folderExclusion]="newFolderExclusion"
								(done)="clearNew()"
								(cancel)="clearNew()"
							></folder-exclusion-form>
						</div>
					</li>
					<li
						*ngFor="
							let folderExclusion of folderExclusionsItems;
							trackBy: folderExclusionsStore.trackItemId
						"
						class="clearfix"
					>
						<wcd-shared-icon [iconName]="'folderExclusion'" class="form-list-item-icon">
						</wcd-shared-icon>
						<div class="pull-left" style="width:600px; white-space:normal;">
							<div *ngIf="folderExclusion.isEditing">
								<folder-exclusion-form
									[folderExclusion]="folderExclusion"
									(done)="folderExclusion.isEditing = false"
								></folder-exclusion-form>
							</div>
							<div *ngIf="!folderExclusion.isEditing">
								<h3 class="wcd-margin-small-vertical">{{ folderExclusion.folder }}</h3>
								<p class="form-list-item-description" *ngIf="folderExclusion.description">
									{{ folderExclusion.description }}
								</p>
								<div>
									<strong>{{
										i18nService.strings.admin_folderExclusions_extensions
									}}</strong>
									{{
										folderExclusion.extensions ||
											i18nService.strings.admin_folderExclusions_any
									}}
								</div>
								<div>
									<strong>{{
										i18nService.strings.admin_folderExclusions_fileNames
									}}</strong>
									{{
										folderExclusion.fileNames ||
											i18nService.strings.admin_folderExclusions_any
									}}
								</div>
								<div>
									<small>
										{{
											'admin_folderExclusions_addedBy'
												| i18n
													: {
															creatorName: folderExclusion.creatorName,
															creationTime:
																folderExclusion.creationTime | date: 'medium'
													  }
										}}
									</small>
								</div>
								<div
									class="buttons-bar wcd-margin-top"
									[rbac]="{ permissions: ['securitySettings'], state: 'hidden' }"
								>
									<fab-default-button
										data-track-id="Remove"
										data-track-type="Button"
										(onClick)="folderExclusionsStore.remove(folderExclusion)"
									>
										<wcd-shared-icon
											[iconName]="'delete'"
											[ariaLabel]="folderExclusion.folder"
										>
										</wcd-shared-icon>
										{{ i18nService.strings.admin_folderExclusions_removeExclusion }}
									</fab-default-button>
									<fab-primary-button
										data-track-id="Edit"
										data-track-type="Button"
										(onClick)="folderExclusion.isEditing = true"
									>
										<wcd-shared-icon
											[iconName]="'edit'"
											[ariaLabel]="folderExclusion.folder"
										>
										</wcd-shared-icon>
										{{ i18nService.strings.admin_folderExclusions_editExclusion }}
									</fab-primary-button>
								</div>
							</div>
						</div>
					</li>
				</ul>
			</div>
		</section>
	</div>
</div>
