/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../comments/components/editable-comments-list/editable-comments-list.component.ngfactory";
import * as i2 from "../../../comments/components/editable-comments-list/editable-comments-list.component";
import * as i3 from "../../../comments/services/comments.store";
import * as i4 from "../../../insights/services/performance.scc.service";
import * as i5 from "../../../shared/interfaces/disableable.interface";
import * as i6 from "../../../rbac/directives/rbac-control.directive";
import * as i7 from "../../../rbac/services/rbac-control.service";
import * as i8 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i9 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./alert-comments.component";
import * as i12 from "../services/alerts.service";
import * as i13 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i14 from "@angular/cdk/a11y";
import * as i15 from "../../../dialogs/snackbars/services/snackbars.service";
var styles_AlertsCommentsComponent = [];
var RenderType_AlertsCommentsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertsCommentsComponent, data: {} });
export { RenderType_AlertsCommentsComponent as RenderType_AlertsCommentsComponent };
export function View_AlertsCommentsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 16777216, null, null, 3, "editable-comments-list", [], null, [[null, "save"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.saveComment($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_EditableCommentsListComponent_0, i1.RenderType_EditableCommentsListComponent)), i0.ɵdid(2, 573440, null, 0, i2.EditableCommentsListComponent, [i3.CommentsStore, i0.ChangeDetectorRef, i4.PerformanceSccService], { comments: [0, "comments"], isSaving: [1, "isSaving"], readonly: [2, "readonly"], isVisibleSnackBar$: [3, "isVisibleSnackBar$"] }, { save: "save" }), i0.ɵprd(2048, null, i5.DISABLEABLE_TOKEN, null, [i2.EditableCommentsListComponent]), i0.ɵdid(4, 4800512, null, 0, i6.RbacControlDirective, [i7.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i8.WicdSanitizerService, [6, i5.DISABLEABLE_TOKEN], i9.TooltipsService, i0.ViewContainerRef, i10.I18nService], { rbac: [0, "rbac"], readonly: [1, "readonly"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.comments; var currVal_1 = _co.isSavingComment; var currVal_2 = _co.readonly; var currVal_3 = _co.isVisibleSnackBar$; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.rbacSettings; var currVal_5 = _co.readonly; _ck(_v, 4, 0, currVal_4, currVal_5); }, null); }
export function View_AlertsCommentsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alerts-comments", [], null, null, null, View_AlertsCommentsComponent_0, RenderType_AlertsCommentsComponent)), i0.ɵdid(1, 638976, null, 0, i11.AlertsCommentsComponent, [i12.AlertsService, i0.ChangeDetectorRef, i13.AuthService, i10.I18nService, i14.LiveAnnouncer, i15.SnackBarsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsCommentsComponentNgFactory = i0.ɵccf("alerts-comments", i11.AlertsCommentsComponent, View_AlertsCommentsComponent_Host_0, { comments: "comments", alerts: "alerts", readonly: "readonly" }, {}, []);
export { AlertsCommentsComponentNgFactory as AlertsCommentsComponentNgFactory };
