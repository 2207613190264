import * as i0 from "@angular/core";
var propertyToAttributeMap = {
    component: 'data-track-component',
    componentType: 'data-track-component-type',
    id: 'data-track-id',
    type: 'data-track-type',
    value: 'data-track-value',
};
var TrackingEventsModelService = /** @class */ (function () {
    function TrackingEventsModelService() {
    }
    TrackingEventsModelService.prototype.convertEventToAttribute = function (trackingEventProperties) {
        return Object.keys(trackingEventProperties)
            .filter(function (key) { return key in propertyToAttributeMap; })
            .reduce(function (acc, key) {
            var _a;
            return Object.assign(acc, (_a = {},
                _a[propertyToAttributeMap[key]] = trackingEventProperties[key],
                _a));
        }, {});
    };
    TrackingEventsModelService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TrackingEventsModelService_Factory() { return new TrackingEventsModelService(); }, token: TrackingEventsModelService, providedIn: "root" });
    return TrackingEventsModelService;
}());
export { TrackingEventsModelService };
