
		<wcd-collapsible-section [label]="assetsSectionTitle">
			<wcd-contents-state [contentState]="mainEntitiesSectionState">
				<ng-container loading *ngTemplateOutlet="loading"></ng-container>
				<ng-container
					error
					*ngTemplateOutlet="error; context: { asset: assetsSectionTitle.toLowerCase() }"
				></ng-container>
				<ng-container empty *ngTemplateOutlet="entitiesEmpty"></ng-container>
				<ng-container complete *ngTemplateOutlet="entitiesComplete"></ng-container>

				<ng-template #entitiesEmpty>
					{{ 'hunting_results_record_panel_sections_assets_empty' | i18n }}
				</ng-template>

				<ng-template #entitiesComplete>
					<impacted-entities-datatables
						#impactedEntities
						*ngIf="mainEntities"
						[openPanelForAllItems]="true"
						[showTitle]="false"
						[entities]="mainEntities"
					>
					</impacted-entities-datatables>
				</ng-template>
			</wcd-contents-state>
		</wcd-collapsible-section>
		<wcd-collapsible-section [label]="processTreeSectionTitle">
			<wcd-contents-state [contentState]="processTreeSectionState">
				<ng-container loading *ngTemplateOutlet="loading"></ng-container>
				<ng-container
					error
					*ngTemplateOutlet="error; context: { asset: processTreeSectionTitle.toLowerCase() }"
				></ng-container>
				<ng-container complete *ngTemplateOutlet="complete"></ng-container>
				<ng-container empty *ngTemplateOutlet="unsupportedCyberEvent"></ng-container>

				<ng-template #complete>
					<cyber-event-entities-graph
						*ngIf="isSupportedCyberEventActionType; else unsupportedCyberEvent"
						[event]="cyberEvent"
						[collapseEntities]="'parents'"
					>
					</cyber-event-entities-graph>
				</ng-template>
				<ng-template #unsupportedCyberEvent>
					{{ 'hunting.results.record.panel.sections.processTree.unsupportedEvent' | i18n }}
				</ng-template>
			</wcd-contents-state>
		</wcd-collapsible-section>
		<wcd-collapsible-section [label]="'hunting_results_record_panel_sections_all_title' | i18n">
			<div
				class="wcd-full-height wcd-flex-vertical"
				data-track-component="HuntingQueryResultRecordEntityPanel"
			>
				<dl [class.key-values]="asKeyValueList" role="none">
					<ng-container *ngFor="let columnInfo of columnsInfo">
						<ng-container *ngIf="showPropertyInPanel(columnInfo)">
							<dt>{{ columnInfo.name }}</dt>
							<dd>
								<hunting-record-property-display
									[columnInfo]="columnInfo"
									[propertyData]="record[columnInfo.name]"
									[link]="
										(columnInfo.getRouteLinkModel &&
											columnInfo.getRouteLinkModel(columnInfo, record)) ||
										null
									"
									[presentObjectValuesAsTable]="true"
									(openEntitySidePanelClickEvent)="onOpenEntitySidePanelClicked($event)"
								>
								</hunting-record-property-display>
							</dd>
						</ng-container>
					</ng-container>
				</dl>
			</div>
		</wcd-collapsible-section>

		<ng-template #loading>
			<fab-spinner></fab-spinner>
		</ng-template>

		<ng-template #error let-asset="asset">
			<span>{{ 'help.externalLoadError' | i18n: { asset: asset } }}</span>
		</ng-template>

		<ng-template #missingIdentifiers let-text="text">
			{{ text }}
		</ng-template>
	