var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PanelContainer, PanelComponent } from '@wcd/panels';
import { Router } from '@angular/router';
import { Alert, SuppressionRule } from '@wcd/domain';
import { SuppressionRuleEditComponent } from './suppression-rule-edit.component';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject, from, of } from 'rxjs';
import { map } from 'rxjs/operators';
var SuppressionRulePanelComponent = /** @class */ (function (_super) {
    __extends(SuppressionRulePanelComponent, _super);
    function SuppressionRulePanelComponent(router, i18nService, dialogsService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.boundCanDeactivate = _this.canDeactivate.bind(_this);
        return _this;
    }
    SuppressionRulePanelComponent.prototype.canDeactivate = function () {
        if (this.ruleEditComponent.isDirty) {
            return from(this.dialogsService.confirm({
                title: this.i18nService.get('discardChanges'),
                text: this.i18nService.get('discardMessage.singular', {
                    itemName: 'suppression rule',
                }),
                confirmText: this.i18nService.get('buttons_discard'),
            })).pipe(map(function (e) { return e.confirmed; }));
        }
        else {
            return of(true);
        }
    };
    SuppressionRulePanelComponent.prototype.onClosePanel = function () {
        var _this = this;
        this.canDeactivate().subscribe(function (_canDeactivate) {
            if (_canDeactivate)
                _this.closePanel();
        });
    };
    SuppressionRulePanelComponent.prototype.closePanel = function () {
        var _this = this;
        this.startClose();
        setTimeout(function () { return _this.destroy(); }, 200);
    };
    return SuppressionRulePanelComponent;
}(PanelContainer));
export { SuppressionRulePanelComponent };
