var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { CustomTiIndicatorsType, CustomTiIndicatorTypeExclusionsRelationship, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { FeaturesService, Feature } from '@wcd/config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { sccHostService } from '@wcd/scc-interface';
var EntityCustomTiActionsConfigService = /** @class */ (function () {
    function EntityCustomTiActionsConfigService(paris, customTiIndicatorsService, i18nService, featureService) {
        this.paris = paris;
        this.customTiIndicatorsService = customTiIndicatorsService;
        this.i18nService = i18nService;
        this.featureService = featureService;
        // notification source for triggering an action refresh after an "Add" side-panel was closed
        this._onActionsRefresh$ = new BehaviorSubject(true);
    }
    EntityCustomTiActionsConfigService.prototype.getCommandBarActions = function (indicatorValue, indicatorTypeId, entityToIndicatorValueConverter, indicatorFilters, context) {
        var _this = this;
        if (!this.featureService.isEnabled(Feature.TiIndicators)) {
            return of(null);
        }
        indicatorFilters = indicatorFilters || [indicatorValue];
        var customIndicatorsRepo = this.paris.getRelationshipRepository(CustomTiIndicatorTypeExclusionsRelationship);
        var indicatorType = this.paris.getValue(CustomTiIndicatorsType, indicatorTypeId);
        customIndicatorsRepo.sourceItem = indicatorType;
        var addIndicatorActionConfig = {
            id: 'addIndicator',
            name: this.i18nService.get('entityCommon.commandBar.indicator.add.title', {
                type: indicatorType.name,
            }),
            tooltip: this.i18nService.get('entityCommon.commandBar.indicator.add.tooltip', {
                type: indicatorType.name,
            }),
            icon: FabricIconNames.Add,
            refreshOnResolve: false,
            rbac: [RbacMdeAllowedActions.remediationActions, RbacMdeAllowedActions.securitySettings],
            rbacState: RbacControlState.disabled,
            method: function (_a) {
                var entity = _a[0];
                return __awaiter(_this, void 0, void 0, function () {
                    var _this = this;
                    return __generator(this, function (_b) {
                        if (context == 'filePage') {
                            sccHostService.log.trackEvent('Improved file page - Add indicator');
                        }
                        this.customTiIndicatorsService
                            .showCustomTiIndicatorNewDialog(indicatorType, entityToIndicatorValueConverter(entity))
                            .subscribe(function (panel) {
                            panel.onDestroy(function () {
                                _this._onActionsRefresh$.next(true);
                            });
                        });
                        return [2 /*return*/];
                    });
                });
            },
        };
        var editIndicatorActionConfig = {
            id: 'editIndicator',
            name: this.i18nService.get('entityCommon.commandBar.indicator.edit.title', {
                type: indicatorType.name,
            }),
            tooltip: this.i18nService.get('entityCommon.commandBar.indicator.edit.tooltip', {
                type: indicatorType.name,
            }),
            icon: FabricIconNames.Edit,
            href: "/preferences2/custom_ti_indicators/" + indicatorType.id + "?filters=" +
                encodeURI('tiIndicator=' + indicatorFilters.join('|')),
            rbac: [RbacMdeAllowedActions.remediationActions],
            rbacState: RbacControlState.disabled,
        };
        return this._onActionsRefresh$.pipe(switchMap(function () {
            return _this.customIndicatorExists(indicatorFilters, indicatorType).pipe(map(function (result) { return (result ? editIndicatorActionConfig : addIndicatorActionConfig); }));
        }), catchError(function (err) { return of(null); }));
    };
    EntityCustomTiActionsConfigService.prototype.customIndicatorExists = function (indicatorFilters, indicatorType) {
        var customIndicatorsRepo = this.paris.getRelationshipRepository(CustomTiIndicatorTypeExclusionsRelationship);
        customIndicatorsRepo.sourceItem = this.paris.getValue(CustomTiIndicatorsType, indicatorType.id);
        // Search if a File based indicator exists for current File
        return customIndicatorsRepo
            .query({
            where: {
                tiIndicator: indicatorFilters,
            },
        })
            .pipe(map(function (dataSet) { return dataSet.items.length > 0; }));
    };
    EntityCustomTiActionsConfigService.prototype.getCustomIndicators = function (indicatorFilters, indicatorType) {
        var customIndicatorsRepo = this.paris.getRelationshipRepository(CustomTiIndicatorTypeExclusionsRelationship);
        customIndicatorsRepo.sourceItem = this.paris.getValue(CustomTiIndicatorsType, indicatorType.id);
        return customIndicatorsRepo
            .query({
            where: {
                tiIndicator: indicatorFilters,
            },
        })
            .pipe(map(function (dataSet) { return dataSet.items; }));
    };
    return EntityCustomTiActionsConfigService;
}());
export { EntityCustomTiActionsConfigService };
