import { Injectable } from '@angular/core';
import {MachineRiskScoreType, SeverityType} from '@wcd/domain';
import { KnownColorName } from '../../../shared/models/colors/known-colors.models';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
import {SeverityTypeColorService} from "../../../shared/services/severity-type-color.service";

@Injectable()
export class MachineRiskScoreColorService extends ColorsService<MachineRiskScoreType> {
	constructor(knownColorsService: KnownColorsService, severityTypeColorService: SeverityTypeColorService) {
		const riskScoreColorMap: Record<MachineRiskScoreType, KnownColorName> = {
			[MachineRiskScoreType.High]: severityTypeColorService.colorsMap.get(SeverityType.High).name,
			[MachineRiskScoreType.Medium]: severityTypeColorService.colorsMap.get(SeverityType.Medium).name,
			[MachineRiskScoreType.Low]: severityTypeColorService.colorsMap.get(SeverityType.Low).name,
			[MachineRiskScoreType.Informational]: severityTypeColorService.colorsMap.get(SeverityType.Informational).name,
			[MachineRiskScoreType.None]: 'neutralPrimary',
		};
		super(knownColorsService, riskScoreColorMap);
	}
}
