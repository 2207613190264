const LOADER_IMAGE_URL = 'assets/images/circle-loading.gif';

export enum InvestigationActionStatusType {
	Running = 'Running',
	Completed = 'Completed',
	Failed = 'Failed',
	Skipped = 'Skipped',
	Resumed = 'Resumed',
	Submitted = 'Submitted',
	Declined = 'Declined',
	Created = 'Created',
	Pending = 'Pending',
	WaitingForResource = 'WaitingForResource',
}

export const InvestigationActionStatusId = {
	[InvestigationActionStatusType.Running]: 0,
	[InvestigationActionStatusType.Completed]: 1,
	[InvestigationActionStatusType.Failed]: 2,
	[InvestigationActionStatusType.Skipped]: 3,
	[InvestigationActionStatusType.Resumed]: 4,
	[InvestigationActionStatusType.Submitted]: 5,
	[InvestigationActionStatusType.Declined]: 6,
	[InvestigationActionStatusType.Created]: 7,
	[InvestigationActionStatusType.Pending]: 129,
	[InvestigationActionStatusType.WaitingForResource]: 130,
};

export const investigationActionStatusValues = [
	{
		id: InvestigationActionStatusId.Running,
		type: 'running',
		name: 'Running',
		image: LOADER_IMAGE_URL,
		className: 'primary',
		isRunning: true,
	},
	{
		id: InvestigationActionStatusId.Completed,
		type: 'completed',
		name: 'Completed',
		iconName: 'Accept',
		className: 'success',
	},
	{
		id: InvestigationActionStatusId.Failed,
		type: 'failed',
		name: 'Failed',
		iconName: 'Cancel',
		className: 'error',
	},
	{
		id: InvestigationActionStatusId.Skipped,
		type: 'skipped',
		name: 'Skipped',
		iconName: 'Blocked',
		className: 'neutral',
	},
	{
		id: InvestigationActionStatusId.Resumed,
		type: 'resumed',
		name: 'Resumed',
		isRunning: true,
		isResumed: true,
		image: LOADER_IMAGE_URL,
		className: 'primary',
	},
	{
		id: InvestigationActionStatusId.Submitted,
		type: 'submitted',
		name: 'Queued',
		isRunning: true,
		iconName: 'BuildQueue',
		className: 'primary',
	},
	{
		id: InvestigationActionStatusId.Declined,
		type: 'declined',
		name: 'Declined',
		iconName: 'Dislike',
		className: 'error',
	},
	{
		id: InvestigationActionStatusId.Created,
		type: 'created',
		name: '(Just created)',
		isRunning: true,
		image: LOADER_IMAGE_URL,
		className: 'primary',
	},
	{
		id: InvestigationActionStatusId.Pending,
		type: 'pending',
		name: 'Pending approval',
		iconName: 'Pause',
		className: 'partial',
		isRunning: true,
		isPending: true,
	},
	{
		id: InvestigationActionStatusId.WaitingForResource,
		type: 'pendingResource',
		name: 'Waiting for device',
		wcdIconName: 'host_unavailable',
		className: 'partial',
		isPending: true,
		isRetriable: true,
		isRunning: true,
	},
];
