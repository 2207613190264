import { Component, Input, ChangeDetectionStrategy, EventEmitter, Output } from '@angular/core';
import { OnChanges } from '@wcd/angular-extensions';
import { KnownColorName } from '../../../models/colors/known-colors.models';
import { KnownColorsService } from '../../../services/known-colors.service';
import { DistributionData } from '../distribution.component';

export type DistributionElementSize = 'compact';

@Component({
	selector: 'distribution-element',
	styleUrls: ['./distribution-element.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			class="DistributionElement wcd-padding-small-left"
			[ngClass]="borderColorClass"
			[class.DistributionElement--compact]="size === 'compact'"
			(click)="onClick.emit()"
			(keydown.enter)="onClick.emit()"
		>
			<span [ngClass]="{'DistributionElement__clickable pointer': clickable}">
				<div class="DistributionElement__title" [ngStyle]="{ 'font-size': titleSize, color: titleColor }">
					{{ title }}
					<wcd-help *ngIf="helpText" [text]="helpText"></wcd-help>
				</div>
				<div class="wcd-flex wcd-flex-nowrap-horizontal DistributionElement__text-container">
					<div
						class="DistributionElement__main-text wcd-font-weight-bold"
						[ngStyle]="{ 'font-size': mainTextSize, color: mainTextColor }"
					>
						{{ mainText }}
					</div>
					<div *ngIf="secondaryText" class="DistributionElement__secondary-text"
						[ngStyle]="{ 'font-size': secondaryTextSize, 'font-weight': secondaryFontWeight, 'color': secondaryTextColor }">
						{{ secondaryText }}
					</div>
				</div>
			</span>
		</div>
	`,
})
export class DistributionElementComponent implements OnChanges<DistributionElementComponent> {
	@Input() barColor: KnownColorName;
	@Input() title: string;
	@Input() titleColor?: string;
	@Input() titleSize?: string;
	@Input() mainText: string;
	@Input() mainTextSize?: string;
	@Input() mainTextColor?: string;
	@Input() secondaryText?: string;
	@Input() secondaryTextSize?: string;
	@Input() secondaryFontWeight?: Number;
	@Input() secondaryTextColor?: string;
	@Input() size?: DistributionElementSize = 'compact';
	@Input() clickable?: boolean = false;
	@Input() helpText?: string;
	@Output() onClick = new EventEmitter<DistributionData>(false);

	borderColorClass: string;

	constructor(private readonly knownColorsService: KnownColorsService) {
	}

	ngOnChanges() {
		this.borderColorClass = this.knownColorsService.borderColorsClassMap[this.barColor];
	}
}
