import { EventEmitter, Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { I18nService } from '@wcd/i18n';
import {
	AirsEntity,
	AirsEntityStatus,
	AirsEntityType,
	AirsFile,
	CapacityState,
	CustomTiIndicatorsCapacityApiCall,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	EvidenceDetectionSourceTypeEnum,
	File,
	HuntingContext,
	Incident,
	RemediationAction,
	RemediationActionTypeTypes,
	RemediationStatusId,
	SystemExclusion,
	SystemExclusionType,
	SystemExclusionTypeEnum,
	Tag,
	UndoRemediationApiCall,
	UndoEvidenceRemediationApiCall,
} from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AirsEntityEntityPanelComponent } from '../components/airs-entity.entity-panel.component';
import { compact, flatMap, isNil } from 'lodash-es';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { combineLatest, defer, Observable, of, race } from 'rxjs';
import { map, mapTo, shareReplay, startWith, switchMap, take } from 'rxjs/operators';
import { RemediationActionsService } from '../../remediation/services/remediation-actions.service';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { Paris } from '@microsoft/paris';
import { AirsEntityTypesService } from './airs-entity-types.service';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { GoHuntAirsEntityService } from './go-hunt-airs-entity.service';
import { AuthService, tenantContextCache } from '@wcd/auth';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AirsEntityEntityPanelMultiComponent } from '../components/airs-entity.entity-panel-multi.component';
import { FabricIconNames } from '@wcd/scc-common';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { toObservable } from '../../../utils/rxjs/utils';

export const PENDING_ACTION_ID_STR = 'PendingAction';
export const UNDO_ACTION_ID_STR = 'entity-undo';
const SUPPORTED_UNDO_ENTITIES = [
	AirsEntityType.File,
	AirsEntityType.Service,
	AirsEntityType.Driver,
	AirsEntityType.PersistenceMethod,
] as const;

// data retention in office is 30 days
const OFFICE_DATA_RETENTION = 30;

@Injectable()
export class AirsEntityEntityTypeService implements EntityTypeService<AirsEntity> {
	constructor(
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private remediationActionsService: RemediationActionsService,
		private readonly paris: Paris,
		private goHuntAirsEntityService: GoHuntAirsEntityService,
		private customTiIndicatorService: CustomTiIndicatorsService,
		private entityTypesService: AirsEntityTypesService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private authService: AuthService,
		private confirmationService: ConfirmationService,
		private flavorService: FlavorService
	) {}

	readonly entityType: EntityType<AirsEntity> = {
		id: 'airs-entity',
		entity: AirsEntity,
		getIcon: (entities: Array<AirsEntity>) => {
			return GlobalEntityTypesService.getCommonValue(
				entities,
				entity => entity.type && entity.type.icon
			);
		},
		getItem: (
			entity: AirsEntity,
			loadedEntity$,
			options: { investigation_id: number; loadDetailsInOptOut: boolean }
		) => {
			const intEntityId = parseInt(entity && <string>entity.id);
			const isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
			const loadUsingMergeByKey =
				entity.mergeByKey && this.featuresService.isEnabled(Feature.EvidenceApiV2);

			if (entity.isOfficeEntity && !entity.isConvergedPlatform) {
				const monthAgo = new Date();
				monthAgo.setDate(monthAgo.getDate() - OFFICE_DATA_RETENTION);
				if (entity.firstSeen && entity.firstSeen < monthAgo) {
					// office data retention is 30 days, hence, we can't call to OATP APIs
					return of(entity);
				}
			}

			if (
				!loadUsingMergeByKey &&
				(isIdNegative ||
					(!(entity.investigation && entity.investigation.id) &&
						!(options && options.investigation_id) &&
						!(options && entity.isOfficeEntity)))
			) {
				return of(entity);
			}
			const entity$ = loadedEntity$.pipe(
				map(e => {
					e.useNewStatus = e.useNewStatus || entity.useNewStatus;
					return e;
				})
			);
			// entity details API is missing some properties, so it's not always best to use
			if (
				entity.isOfficeEntity &&
				options &&
				options.loadDetailsInOptOut === false &&
				sccHostService.isSCC &&
				!tenantContextCache.hasMtpConsent
			) {
				return of(entity);
			}
			return entity$;
		},
		getItemParams: (
			entity: AirsEntity,
			options?: {
				type_id: string | number;
				investigation_id: number;
			}
		) => {
			const typeId: string | number = !isNil(options && options.type_id)
				? options.type_id
				: entity.type && entity.type.id;
			if (!typeId) {
				throw new Error("Can't get Entity params, missing entity type ID.");
			}
			const investigationId: number | string = !isNil(options && options.investigation_id)
				? options.investigation_id
				: entity.investigation && entity.investigation.id;

			const useEvidenceApiV2 =
				this.featuresService.isEnabled(Feature.EvidenceApiV2) && entity.mergeByKey;

			// no need investigationId for office entity and for the new evidence service
			if (!investigationId && !entity.isOfficeEntity && !useEvidenceApiV2) {
				throw new Error("Can't get Entity params, missing entity investigation ID.");
			}

			// when 'convergedPlatorm' field is true - AIRS BE should be called, hence, don't use office entity api
			const useOfficeEntityApi =
				this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
				entity.isOfficeEntity &&
				!entity.isConvergedPlatform;

			return {
				type_id: typeId,
				investigation_id: investigationId,
				force_reload: true,
				mergeByKey: entity.mergeByKey,
				alertId:
					entity.detectionContext &&
					entity.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert &&
					entity.detectionContext.detectionId,
				tenantId: sccHostService.isSCC ? sccHostService.loginUser.tenantId : null,

				useOfficeEntityApi: useOfficeEntityApi,
				useEvidenceApiV2,
				hideOpenLink: () => !this.flavorService.isEnabled(AppFlavorConfig.incidents.fileOpenPage),
				evidenceV2: {
					callDetails: !entity.isOfficeEntity || !entity.isConvergedPlatform, // TODO: this is a temporary until we will fix admin action side panel details
					entityId:
						entity.additionalData &&
						entity.additionalData.evidenceV2 &&
						entity.additionalData.evidenceV2.entityId,
				},
			};
		},
		getIconCssClass: (entities: Array<AirsEntity>) => {
			const commonRemediationStatus: AirsEntityStatus = GlobalEntityTypesService.getCommonValue(
				entities,
				entity => entity.useNewStatus && entity.remediationStatus
			);

			if (commonRemediationStatus) {
				return `round-icon color-box-${commonRemediationStatus.className}`;
			}
			const commonVerdict: AirsEntityStatus = GlobalEntityTypesService.getCommonValue(
				entities,
				entity => (entity.useNewStatus ? entity.verdict : entity.status)
			);
			if (commonVerdict) {
				return `round-icon color-box-${commonVerdict.className}`;
			}
		},
		singleEntityPanelComponentType: AirsEntityEntityPanelComponent,
		multipleEntitiesComponentType: AirsEntityEntityPanelMultiComponent,
		getEntityClassName: (entity: AirsEntity) =>
			entity.useNewStatus && entity.verdict
				? entity.verdict.className
				: entity.status
				? entity.status.className
				: null,
		getEntityName: (entity: AirsEntity) => entity.entityName,
		getSubtitle: (entities: Array<AirsEntity>) =>
			GlobalEntityTypesService.getCommonValue(entities, entity => entity.type.singularName),
		getEntitiesLink: (entities: Array<AirsEntity>) => {
			const entity = entities.length === 1 && entities[0];
			if (entity) {
				if (
					!this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
					entity.deepLink
				) {
					return entity.deepLink;
				}
				if (entity.type.id === AirsEntityType.File && (<AirsFile>entity).sha1) {
					return this.globalEntityTypesService.getEntityLink(File, <Partial<File>>{
						id: (<AirsFile>entity).sha1,
					});
				}
			}

			return null;
		},
		getUseExternalRouting: (entities: Array<AirsEntity>) => {
			if (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)) {
				return;
			}
			return !!(entities && entities.length && entities[0].deepLink);
		},
		getNavigationModel: (entity: AirsEntity) => {
			const link = this.entityType.getEntitiesLink([entity]);
			return link ? { routerLink: [link] } : null;
		},
		getEntitiesLinkText: (entities: Array<AirsEntity>) =>
			entities && entities.length === 1
				? this.i18nService.get('entityCommon_commandBar_openPage', {
						entity: entities[0].type.singularName.toLowerCase(),
				  })
				: null,
		getActions: (
			entities: Array<AirsEntity>,
			options?: { incident: Incident; huntingContext?: HuntingContext }
		) => {
			let approvalActions = [];
			if (
				entities &&
				entities.every(
					entity =>
						entity.remediationStatus &&
						entity.remediationStatus.id === RemediationStatusId.PendingApproval
				)
			) {
				// approval actions only relevant if all the actions are in Pending Approval status
				approvalActions = this.getApprovalActions(entities);
			}

			const undoAction = this.getUndoAction(entities);
			const exclusionAction = this.getExclusionAction(entities);
			const goHuntAction = this.getGoHuntAction(entities, options);

			const actions = [...approvalActions, undoAction, exclusionAction, goHuntAction]
				.filter(Boolean)
				.map(toObservable);

			return combineLatest(actions).pipe(
				map(_actions =>
					compact(_actions).map(itemActionConfig => new ItemActionModel(itemActionConfig))
				)
			) as Observable<Array<ItemActionModel<AirsEntity>>>;
		},
		getTags: (entities: Array<AirsEntity>) => {
			const commonVerdict: AirsEntityStatus = GlobalEntityTypesService.getCommonValue(
				entities,
				entity => (entity.useNewStatus ? entity.verdict : entity.status)
			);
			if (commonVerdict) {
				const tags = [
					new Tag({
						id: commonVerdict.name,
						isEditable: false,
						name: commonVerdict.name,
						className: 'color-box-' + commonVerdict.className,
					}),
				];
				const commonRemediationStatus: AirsEntityStatus = GlobalEntityTypesService.getCommonValue(
					entities,
					entity => entity.useNewStatus && entity.remediationStatus
				);
				if (commonRemediationStatus) {
					tags.push(
						new Tag({
							id: commonRemediationStatus.name,
							isEditable: false,
							name: commonRemediationStatus.name,
							className: 'color-box-' + commonRemediationStatus.className,
						})
					);
				}
				return tags;
			}
		},
	};

	private getExclusionAction(entities: Array<AirsEntity>): Observable<ItemActionModelConfig<AirsEntity>> {
		const entitiesType = GlobalEntityTypesService.getCommonValue(entities, entity => entity.type);
		if (
			entitiesType &&
			entitiesType.enableSystemExclusion &&
			entities.every(entity => !entity.systemExclusionRule)
		) {
			const systemExclusionType: SystemExclusionType = this.paris.getValue(
				SystemExclusionType,
				entitiesType.systemExclusionType
			);
			const indicatorValue = GlobalEntityTypesService.getCommonValue(entities, entity => {
				const systemExclusion: SystemExclusion = this.entityTypesService.getSystemExclusionRule(
					entity,
					systemExclusionType,
					null
				);
				if (systemExclusionType.id === SystemExclusionTypeEnum.files) {
					return systemExclusion.properties.file_hash;
				} else if (systemExclusionType.id === SystemExclusionTypeEnum.ip) {
					return systemExclusion.properties.ip;
				}
			});

			if (indicatorValue) {
				return defer(async () => {
					if (!tenantContextCache.hasMtpConsent && !tenantContextCache.appConfig.IsMdatpActive) {
						return null;
					}
					try {
						const capacity = await this.paris
							.apiCall(CustomTiIndicatorsCapacityApiCall)
							.pipe(take(1))
							.toPromise();
						const canAddIndicator =
							capacity.capacityState !== CapacityState.Unavailable &&
							capacity.total + 1 <= capacity.limit;
						if (!canAddIndicator) {
							return null;
						}
						// TODO: refactor once entity_types moves to FE
						return {
							id: 'createEntityExclusionRule',
							name: this.i18nService.get('systemExclusions_addRule', {
								systemExclusionType: entitiesType.singularName.toLowerCase(),
							}),
							rbac: [
								RbacMdeAllowedActions.securitySettings,
								RbacMdeAllowedActions.remediationActions,
							],
							rbacState: RbacControlState.hidden,
							icon: 'add',
							method: () => {
								return this.customTiIndicatorService
									.showCustomTiIndicatorNewDialog(
										this.paris.getValue(
											CustomTiIndicatorsType,
											systemExclusionType.id === SystemExclusionTypeEnum.files
												? CustomTiIndicatorsTypes.Files
												: systemExclusionType.id === SystemExclusionTypeEnum.ip
												? CustomTiIndicatorsTypes.Ip
												: null
										),
										indicatorValue
									)
									.pipe(
										switchMap(panel =>
											race([
												panel.instance.save.pipe(mapTo(true)),
												panel.instance.cancel.pipe(mapTo(false)),
											]).pipe(take(1))
										)
									)
									.toPromise();
							},
							tooltip: this.i18nService.get('systemExclusions_generalDescription', {
								type: entitiesType.singularName,
							}),
							refreshOnResolve: true,
							closeOnAction: true,
						};
					} catch (e) {
						return null;
					}
				});
			}
		}
	}

	private getApprovalActions(
		entities: Array<AirsEntity>
	): Array<Observable<ItemActionModelConfig<AirsEntity>>> {

		//TODO: remove this if when evidenceV2 supports approve/decline actions
		if (this.featuresService.isEnabled(Feature.EvidenceApiV2) && this.featuresService.isEnabled(Feature.EvidenceApiV2DisableApprovalActions)) {
			return [];
		}

		if (
			this.featuresService.isEnabled(Feature.EvidenceApiV2) &&
			entities.every(
				e =>
					e.mergeByKey &&
					e.detectionContext &&
					e.detectionContext.detectionType &&
					e.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert
			)
		) {
			return this.getApprovalActionsV2(entities);
		}
		const remediationActionsArr = entities.map(e => e.remediationActions);
		const allRemediationActions = Array.from(new Set(compact(flatMap(remediationActionsArr))));
		if (
			remediationActionsArr &&
			// make sure every entity has remediation actions
			remediationActionsArr.every(_actions => !!(_actions && _actions.length)) &&
			allRemediationActions &&
			allRemediationActions.length
		) {
			const isSecAdmin = this.authService.currentUser.isSecAdmin;
			const isAllowed = isSecAdmin || allRemediationActions.every(a => !a.isOfficeInvestigation);

			const ignoreRbac =
				sccHostService.isSCC && allRemediationActions.every(a => a.isOfficeInvestigation);
			let isAllowed$: Observable<boolean>;

			if (!isAllowed && sccHostService.isSCC) {
				isAllowed$ = defer(() => sccHostService.auth.isInRole(SccRoles.searchAndPurge));
			} else {
				isAllowed$ = of(isAllowed);
			}

			isAllowed$ = isAllowed$.pipe(shareReplay({ bufferSize: 1, refCount: true }));

			const actionType = GlobalEntityTypesService.getCommonValue(
				allRemediationActions,
				action => action && action.remediationActionType
			);

			const approveAction = isAllowed$.pipe(
				map(hasPermission =>
					Object.assign(
						{
							id: `approve${PENDING_ACTION_ID_STR}-${(actionType && actionType.simpleName) ||
								'general'}`,
							name: actionType
								? this.i18nService.strings.remediationActions_approve +
								  ' ' +
								  actionType.simpleName
								: this.i18nService.strings.remediationActions_approveGeneric,
							icon: actionType ? actionType.icon : null,
							method: _entities => {
								return this.remediationActionsService.approvePendingActions(
									allRemediationActions
								);
							},
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_approve
								: this.i18nService.strings.common_permissions_noPermissionTooltip,
							refreshOnResolve: true,
							disabled: !hasPermission,
							allowRbacTooltipOverride: true,
						},
						ignoreRbac
							? null
							: {
									rbac: [RbacMdeAllowedActions.remediationActions],
									rbacState: RbacControlState.disabled,
							  }
					)
				)
			);

			const declineAction = isAllowed$.pipe(
				map(hasPermission =>
					Object.assign(
						{
							id: `decline${PENDING_ACTION_ID_STR}-${(actionType && actionType.simpleName) ||
								'general'}`,
							name: actionType
								? this.i18nService.strings.remediationActions_dismiss +
								  ' ' +
								  actionType.simpleName
								: this.i18nService.strings.remediationActions_dismissGeneric,
							icon: actionType ? actionType.icon : null,
							method: (_entities: Array<AirsEntity>) => {
								const fileSha1 =
									(actionType &&
										actionType.type === RemediationActionTypeTypes.file_quarantine &&
										GlobalEntityTypesService.getCommonValue(
											_entities,
											entity => entity && (<AirsFile>entity).sha1
										)) ||
									null;
								return this.remediationActionsService.confirmAndDismissPendingActionsByType(
									allRemediationActions,
									fileSha1
								);
							},
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_decline
								: this.i18nService.strings.common_permissions_noPermissionTooltip,
							refreshOnResolve: true,
							disabled: !hasPermission,
							allowRbacTooltipOverride: true,
						},
						ignoreRbac
							? null
							: {
									rbac: [RbacMdeAllowedActions.remediationActions],
									rbacState: RbacControlState.disabled,
							  }
					)
				)
			);
			return [approveAction, declineAction];
		}
	}

	private getApprovalActionsV2(
		entities: Array<AirsEntity>
	): Array<Observable<ItemActionModelConfig<AirsEntity>>> {
		const remediationStatus = GlobalEntityTypesService.getCommonValue(entities, e => e.remediationStatus);
		if (!(remediationStatus && remediationStatus.id === RemediationStatusId.PendingApproval)) {
			return;
		}
		const isSecAdmin = this.authService.currentUser.isSecAdmin;
		const isAllowed = isSecAdmin || entities.every(a => !a.isOfficeEntity);

		const ignoreRbac = sccHostService.isSCC && entities.every(a => a.isOfficeEntity);
		let isAllowed$: Observable<boolean>;

		if (!isAllowed && sccHostService.isSCC) {
			isAllowed$ = defer(() => sccHostService.auth.isInRole(SccRoles.searchAndPurge));
		} else {
			isAllowed$ = of(isAllowed);
		}

		isAllowed$ = isAllowed$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
		const approveAction = isAllowed$.pipe(
			map(hasPermission =>
				Object.assign(
					<ItemActionModelConfig<AirsEntity>>{
						id: `approve${PENDING_ACTION_ID_STR}-general`,
						name: this.i18nService.strings.remediationActions_approveGeneric,
						icon: null,
						method: _entities => {
							return this.remediationActionsService.approvePendingEntities(
								_entities.map(e => ({
									mergeByKey: e.mergeByKey,
									alertId: e.detectionContext.detectionId,
									type: e.type,
								}))
							);
						},
						tooltip: hasPermission
							? this.i18nService.strings.remediationActions_tooltips_approve
							: this.i18nService.strings.common_permissions_noPermissionTooltip,
						refreshOnResolve: true,
						disabled: !hasPermission,
						allowRbacTooltipOverride: true,
					},
					ignoreRbac
						? null
						: {
								rbac: [RbacMdeAllowedActions.remediationActions],
								rbacState: RbacControlState.disabled,
						  }
				)
			)
		);

		const declineAction = isAllowed$.pipe(
			map(hasPermission =>
				Object.assign(
					<ItemActionModelConfig<AirsEntity>>{
						id: `decline${PENDING_ACTION_ID_STR}-general`,
						name: this.i18nService.strings.remediationActions_dismissGeneric,
						icon: null,
						method: (_entities: Array<AirsEntity>) => {
							const fileSha1 =
								GlobalEntityTypesService.getCommonValue(
									_entities,
									entity =>
										entity &&
										entity.type.id === AirsEntityType.File &&
										(<AirsFile>entity).sha1
								) || null;
							return this.remediationActionsService.confirmAndDismissPendingActionsByEntities(
								_entities.map(e => ({
									mergeByKey: e.mergeByKey,
									alertId: e.detectionContext.detectionId,
									type: e.type,
								})),
								fileSha1
							);
						},
						tooltip: hasPermission
							? this.i18nService.strings.remediationActions_tooltips_decline
							: this.i18nService.strings.common_permissions_noPermissionTooltip,
						refreshOnResolve: true,
						disabled: !hasPermission,
						allowRbacTooltipOverride: true,
					},
					ignoreRbac
						? null
						: {
								rbac: [RbacMdeAllowedActions.remediationActions],
								rbacState: RbacControlState.disabled,
						  }
				)
			)
		);
		return [approveAction, declineAction];
	}

	private getUndoAction(entities: Array<AirsEntity>): ItemActionModelConfig<AirsEntity> {
		if (
			this.featuresService.isEnabled(Feature.EvidenceApiV2) &&
			entities.every(
				e =>
					e.mergeByKey &&
					e.detectionContext &&
					e.detectionContext.detectionType &&
					e.detectionContext.detectionType.id === EvidenceDetectionSourceTypeEnum.alert
			)
		) {
			const undoAction = this.getUndoActionV2(entities);
			if (undoAction) {
				return undoAction;
			}
		}
		const undoableActionsArr = entities.map(e => e.undoableActions);
		const allUndoableActions: Array<RemediationAction> = Array.from(
			new Set(compact(flatMap(undoableActionsArr)))
		);
		if (
			undoableActionsArr &&
			// make sure every entity has undoable actions
			undoableActionsArr.every(_actions => !!(_actions && _actions.length)) &&
			allUndoableActions &&
			allUndoableActions.length
		) {
			return {
				id: UNDO_ACTION_ID_STR,
				rbac: [RbacMdeAllowedActions.remediationActions],
				nameKey: 'actionsHistory_undo_action',
				icon: FabricIconNames.Undo,
				closeOnAction: true,
				allowRbacTooltipOverride: true,
				method: async _entities => {
					const done$ = new EventEmitter<boolean>();

					const e = await this.confirmationService.confirm({
						title: this.i18nService.strings.actionsHistory_undo_confirm_title,
						text: this.i18nService.strings.actionsHistory_undo_confirm_text,
						showLoaderAndCloseOnEvent: done$,
					});
					if (e.confirmed) {
						await this.paris
							.apiCall(
								UndoRemediationApiCall,
								Array.from(new Set(allUndoableActions.map(a => a.id)))
							)
							.toPromise();
						done$.next(true);
						done$.complete();
						return Promise.resolve({ confirmed: true, data: null });
					}

					return Promise.reject();
				},
			};
		}
	}

	private getGoHuntAction(
		entities: Array<AirsEntity>,
		options?: { incident: Incident; huntingContext?: HuntingContext }
	): Observable<ItemActionModelConfig> {
		if (this.flavorService.isEnabled(AppFlavorConfig.incidents.hunting) && entities.length == 1) {
			return this.goHuntAirsEntityService
				.getGoHuntAirsEntityObservable(entities[0], {
					incident: options && options.incident,
					huntingContext: options && options.huntingContext,
				})
				.pipe(startWith(null));
		}
	}

	private getUndoActionV2(entities: Array<AirsEntity>): ItemActionModelConfig<AirsEntity> {
		const remediationStatus = GlobalEntityTypesService.getCommonValue(entities, e => e.remediationStatus);
		if (!(remediationStatus && remediationStatus.id === RemediationStatusId.Remediated)) {
			return;
		}
		// TODO: the backend logic is more complex, we need to know the action type to make a better decision here
		if (!entities.every(e => e.type && SUPPORTED_UNDO_ENTITIES.includes(e.type.id))) {
			return;
		}
		return {
			id: UNDO_ACTION_ID_STR,
			rbac: [RbacMdeAllowedActions.remediationActions],
			nameKey: 'actionsHistory_undo_action',
			icon: FabricIconNames.Undo,
			closeOnAction: true,
			allowRbacTooltipOverride: true,
			method: async _entities => {
				const done$ = new EventEmitter<boolean>();

				const e = await this.confirmationService.confirm({
					title: this.i18nService.strings.actionsHistory_undo_confirm_title,
					text: this.i18nService.strings.actionsHistory_undo_confirm_text,
					showLoaderAndCloseOnEvent: done$,
				});
				if (e.confirmed) {
					await this.paris
						.apiCall(
							UndoEvidenceRemediationApiCall,
							_entities.map(e => ({
								mergeByKey: e.mergeByKey,
								alertId: e.detectionContext.detectionId,
							}))
						)
						.toPromise();
					done$.next(true);
					done$.complete();
					return Promise.resolve({ confirmed: true, data: null });
				}

				return Promise.reject();
			},
		};
	}
}
