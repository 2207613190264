import { Paris } from '@microsoft/paris';
import { WebThreatProtection, WebThreatProtectionData, WebThreatHealthType, WebThreatDimensionKeyType, WebThreatProtectionHealthStatusApiCall, WebThreatStatusSummary, WebThreatStatusSummaryApiCall, } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ReportsService } from '../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { mapValues, mapKeys, maxBy } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../rbac/services/rbac.service';
import { ChartType } from '../reporting.types';
import { DialogsService } from '../../dialogs/services/dialogs.service';
var MAX_LABELS = 4;
var WebThreatProtectionReportService = /** @class */ (function () {
    function WebThreatProtectionReportService(paris, tzDateService, prettyNumberService, i18nService, rbacService, dialogsService) {
        this.paris = paris;
        this.tzDateService = tzDateService;
        this.prettyNumberService = prettyNumberService;
        this.i18nService = i18nService;
        this.rbacService = rbacService;
        this.dialogsService = dialogsService;
    }
    WebThreatProtectionReportService.prototype.getWebThreatByHealthStatusData = function (lookBackInDays) {
        var params = {
            lookBackInDays: lookBackInDays,
        };
        return this.paris.apiCall(WebThreatProtectionHealthStatusApiCall, params);
    };
    WebThreatProtectionReportService.prototype.getLatestWebThreatStatusSummary = function () {
        return this.paris.apiCall(WebThreatStatusSummaryApiCall);
    };
    // TODO: Look into making common functions since reports (machine and web-threat) both make this kind of call
    WebThreatProtectionReportService.prototype.getSettingsOverTime = function (dimensionType, dimensionMapping, data) {
        var _this = this;
        var dimensionData = data && data.data && data.data.length && data.data;
        var maxWebThreatCount = dimensionData &&
            dimensionData.reduce(function (maxWebThreatCount, data) {
                return Math.max(maxBy(Object.values(data.values)), maxWebThreatCount);
            }, 0);
        return {
            options: {
                data: {
                    columns: this.getDimensionData(dimensionType, dimensionData, dimensionMapping),
                    colors: mapValues(mapKeys(dimensionMapping, function (value) { return value.label; }), function (value) { return value.knownColorsMap.trend.raw; }),
                },
                axis: {
                    y: {
                        max: maxWebThreatCount ? maxWebThreatCount : 0,
                        min: 0,
                        tick: {
                            values: dimensionData ? ReportsService.getYTicks(maxWebThreatCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                    x: {
                        type: 'category',
                        categories: this.getDimensionDates(dimensionData),
                        tick: {
                            multiline: false,
                            culling: {
                                max: dimensionData
                                    ? Math.min(dimensionData.length + 1, MAX_LABELS)
                                    : MAX_LABELS,
                            },
                        },
                    },
                },
                point: {
                    show: false,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    height: 200,
                },
            },
            disableCursor: true,
        };
    };
    WebThreatProtectionReportService.prototype.getSettingsDaily = function (type, dimensionMapping, data, getDimensionFilterData) {
        var _this = this;
        var dimensionData = data && data.data && data.data.length && data.data[0];
        var maxCount = dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
        var chartData = Object.entries(dimensionMapping).map(function (_a) {
            var key = _a[0], value = _a[1];
            return _this.getDimensionDataDaily(value.label, key, dimensionData);
        });
        var colorsPattern = Object.entries(dimensionMapping).map(function (_a) {
            var key = _a[0], value = _a[1];
            return value.knownColorsMap.daily.raw;
        });
        return {
            options: {
                color: {
                    pattern: colorsPattern,
                },
                data: {
                    columns: [
                        [
                            this.i18nService.get('reporting.webThreatReport.dailyTooltipDescription')
                        ].concat(chartData.map(function (data) { return data.value; })),
                    ],
                    groups: [
                        Object.entries(dimensionMapping).map(function (_a) {
                            var key = _a[0], value = _a[1];
                            return value.label;
                        }),
                    ],
                    type: 'bar',
                    labels: {
                        format: function (value) { return (maxCount > 0 ? _this.prettyNumberService.prettyNumber(value) : ''); },
                    },
                    color: function (inColor, data) {
                        if (data.index !== undefined) {
                            return colorsPattern[data.index];
                        }
                        return inColor;
                    },
                },
                tooltip: {
                    grouped: false,
                    contents: function (data) {
                        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"name\">" + data[0].value + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t</table>";
                    },
                },
                bar: {
                    width: {
                        max: 8,
                        ratio: 0.2,
                    },
                },
                axis: {
                    rotated: type === ChartType.Horizontal,
                    x: {
                        type: 'category',
                        categories: chartData.map(function (data) { return data.label; }),
                        tick: {
                            multiline: type === ChartType.Horizontal,
                            culling: {
                                max: 1,
                            },
                            rotate: 35,
                        },
                        padding: { right: 0.5 },
                    },
                    y: {
                        show: true,
                        max: maxCount ? maxCount : 0,
                        min: 0,
                        tick: {
                            values: data ? ReportsService.getYTicks(maxCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                padding: {
                    left: type === ChartType.Horizontal ? 100 : 50,
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                point: {
                    show: false,
                },
                legend: { show: false },
                size: {
                    height: 200,
                },
            },
        };
    };
    WebThreatProtectionReportService.prototype.getDimensionDates = function (dimensionData) {
        var _this = this;
        return dimensionData
            ? dimensionData.map(function (data) { return _this.tzDateService.format(data.date, 'MM/dd'); })
            : [];
    };
    WebThreatProtectionReportService.prototype.getDimensionData = function (type, dimensionData, dimensionMapping) {
        var _this = this;
        return Object.keys(type).map(function (key) {
            return _this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData);
        });
    };
    WebThreatProtectionReportService.prototype.getDimensionDataOvertime = function (label, dimensionType, dimensionData) {
        return [
            label
        ].concat((dimensionData &&
            dimensionData.map(function (data) { return (data.values[dimensionType] ? data.values[dimensionType] : 0); })));
    };
    WebThreatProtectionReportService.prototype.getDimensionDataDaily = function (label, dimensionType, dimensionData) {
        return {
            label: label,
            value: dimensionData &&
                (dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
        };
    };
    return WebThreatProtectionReportService;
}());
export { WebThreatProtectionReportService };
export var WebThreatType;
(function (WebThreatType) {
    WebThreatType["Phishing"] = "Phishing";
    WebThreatType["Malicious"] = "Malicious";
    WebThreatType["CustomIndicator"] = "CustomBlockList";
    WebThreatType["Unknown"] = "Unknown";
})(WebThreatType || (WebThreatType = {}));
