export class EvaluationWizardStepModel {
	id: number;
	name: string;
	className?: string;
	routerLink?: string | ReadonlyArray<string>;
	btnClick?: string;
	subSteps?: ReadonlyArray<EvaluationWizardStepModel>;
	badge?: boolean;

	constructor(data: EvaluationWizardStepModelConfig) {
		Object.assign(this, data);
	}
}

export interface EvaluationWizardStepModelConfig {
	id: number;
	name?: string;
	className?: string;
	routerLink?: string | ReadonlyArray<string>;
	btnClick?: string;
	subSteps?: ReadonlyArray<EvaluationWizardStepModel>;
	badge?: boolean;
}
