/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "./alert-reasons-field.component";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../services/alert-incident.service";
var styles_AlertReasonsFieldComponent = [];
var RenderType_AlertReasonsFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertReasonsFieldComponent, data: {} });
export { RenderType_AlertReasonsFieldComponent as RenderType_AlertReasonsFieldComponent };
export function View_AlertReasonsFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "span", [["wcdTooltip", ""]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 2).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(2, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], tooltipInnerComponent: [1, "tooltipInnerComponent"], innerComponentInput: [2, "innerComponentInput"] }, null), i0.ɵpod(3, { alertId: 0, linkedBy: 1, aggregatedLinkedBy: 2, showTooltip: 3 }), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ""; var currVal_1 = _co.alertLinkedByDetailsComponent; var currVal_2 = _ck(_v, 3, 0, ((_co.alert == null) ? null : _co.alert.id), ((_co.alert == null) ? null : _co.alert.aggregatedIncidentLinkedBy), _co.aggregatedLinkedBy, false); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.reasonsText; _ck(_v, 4, 0, currVal_3); }); }
export function View_AlertReasonsFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-reasons-field", [], null, null, null, View_AlertReasonsFieldComponent_0, RenderType_AlertReasonsFieldComponent)), i0.ɵdid(1, 114688, null, 0, i4.AlertReasonsFieldComponent, [i5.I18nService, i6.AlertIncidentService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertReasonsFieldComponentNgFactory = i0.ɵccf("alert-reasons-field", i4.AlertReasonsFieldComponent, View_AlertReasonsFieldComponent_Host_0, { alert: "alert" }, {}, []);
export { AlertReasonsFieldComponentNgFactory as AlertReasonsFieldComponentNgFactory };
