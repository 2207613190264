import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Observable } from 'rxjs';
import { PanelContainer } from '@wcd/panels';
import { MessageBarType } from 'office-ui-fabric-react';
import { MachineGroupsFilterData } from '../../../services/tvm-machine-groups-filter.service';
import { FiltersField, FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'machine-groups-filter-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine-groups-filter-panel.component.html',
	styleUrls: ['./machine-groups-filter-panel.component.scss'],
})
export class MachineGroupsFilterPanelComponent extends PanelContainer {
	_messageBarType = MessageBarType;
	_messageBarStyles = {
		root: {
			background: '#FDF1D9',
		},
	};

	private _subscription: Subscription;

	@Input() machineGroupsFilter$: Observable<MachineGroupsFilterData>;
	@Output() onFiltersChange = new EventEmitter<Array<number | string>>();

	_filterId = 'machineGroups';

	_wcdFiltersData = {};
	_wcdFiltersSelectedValues = new Map();
	_wcdFiltersFields: Array<FiltersField> = [
		{
			id: this._filterId,
			name: '',
			requiresData: false,
			component: {
				type: FilterValuesChecklistComponent,
			},
		},
	];

	constructor(router: Router, i18nService: I18nService) {
		super(router);

		this._wcdFiltersFields[0].name = i18nService.strings.tvm_machineGroupsFilter_panel_body_filterName;
	}

	ngOnInit() {
		super.ngOnInit();

		this._subscription = this.machineGroupsFilter$.subscribe((machineGroupsFilterData) => {
			if (!machineGroupsFilterData) return;

			const filtersAlternativeDataValues = [];
			const selectedValues = [];

			machineGroupsFilterData.machineGroups.forEach((data) => {
				filtersAlternativeDataValues.push({
					value: data.groupId,
					name: data.groupName,
				});

				if (data.isSelected) {
					selectedValues.push(data.groupId);
				}
			});

			if (selectedValues.length) {
				this._wcdFiltersSelectedValues.set(this._filterId, selectedValues);
			}

			this._wcdFiltersData = {};
			this._wcdFiltersData[this._filterId] = {
				values: filtersAlternativeDataValues,
			};
		});
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		this._subscription && this._subscription.unsubscribe();
	}

	onWcdFiltersChange(e) {
		const selectedGroups = e.selection[this._filterId] ? [...e.selection[this._filterId]] : [];
		this.onFiltersChange.emit(selectedGroups);
	}
}
