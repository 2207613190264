/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "./alert-status.component";
var styles_AlertStatusComponent = [];
var RenderType_AlertStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertStatusComponent, data: {} });
export { RenderType_AlertStatusComponent as RenderType_AlertStatusComponent };
export function View_AlertStatusComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 16, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "span", [["class", "wcd-severity wcd-margin-large-right"], ["role", "presentation"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(7, 147456, null, 0, i4.TooltipDirective, [i0.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(8, 1), i0.ɵppd(9, 1), (_l()(), i0.ɵted(10, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 4, "strong", [["role", "presentation"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 14).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 14).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 14).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 14).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(14, 147456, null, 0, i4.TooltipDirective, [i0.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(15, 1), (_l()(), i0.ɵted(16, null, ["", ""])), i0.ɵppd(17, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-severity wcd-margin-large-right"; var currVal_1 = ("wcd-severity-" + _co.alert.severity.type); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵinlineInterpolate(2, "", i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "alerts.severity")), ": ", i0.ɵunv(_v, 7, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), _co.alert.severity.nameI18nKey)), ""); _ck(_v, 7, 0, currVal_2); var currVal_4 = i0.ɵinlineInterpolate(2, "", i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v, 0), "alerts.status.title")), ": ", _co.alert.status.name, ""); _ck(_v, 14, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), _co.alert.severity.nameI18nKey)); _ck(_v, 10, 0, currVal_3); var currVal_5 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v, 0), _co.alert.status.nameI18nKey)); _ck(_v, 16, 0, currVal_5); }); }
export function View_AlertStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AlertStatusComponent_0, RenderType_AlertStatusComponent)), i0.ɵdid(1, 49152, null, 0, i7.AlertStatusComponent, [], null, null)], null, null); }
var AlertStatusComponentNgFactory = i0.ɵccf("ng-component", i7.AlertStatusComponent, View_AlertStatusComponent_Host_0, { entity: "entity" }, {}, []);
export { AlertStatusComponentNgFactory as AlertStatusComponentNgFactory };
