/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../entity-details/entity-details.section-header.component.ngfactory";
import * as i2 from "../entity-details/entity-details.section-header.component";
import * as i3 from "@angular/common";
import * as i4 from "../entity-details/global-entity-details.component.ngfactory";
import * as i5 from "../entity-details/global-entity-details.component";
import * as i6 from "./entity-with-context.entity-panel.component";
import * as i7 from "../../services/global-entity-types.service";
var styles_EntityWithContextPanelComponent = [];
var RenderType_EntityWithContextPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntityWithContextPanelComponent, data: {} });
export { RenderType_EntityWithContextPanelComponent as RenderType_EntityWithContextPanelComponent };
function View_EntityWithContextPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-details-section-header", [], null, null, null, i1.View_EntityDetailsSectionHeaderComponent_0, i1.RenderType_EntityDetailsSectionHeaderComponent)), i0.ɵdid(1, 49152, null, 0, i2.EntityDetailsSectionHeaderComponent, [], { titleKey: [0, "titleKey"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityContext.nameKey; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EntityWithContextPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "section", [["class", "wcd-margin-vertical wcd-margin-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityWithContextPanelComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "global-entity-details", [], null, null, null, i4.View_GlobalEntityDetailsComponent_0, i4.RenderType_GlobalEntityDetailsComponent)), i0.ɵdid(6, 114688, null, 0, i5.GlobalEntityDetailsComponent, [i0.Injector], { entity: [0, "entity"], showEntityPanelLink: [1, "showEntityPanelLink"], entityType: [2, "entityType"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityContext.nameKey; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entityContext.item; var currVal_2 = false; var currVal_3 = _co.entityContext.type; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_EntityWithContextPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { mainEntityDetailsPlaceholder: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityWithContextPanelComponent_1)), i0.ɵdid(4, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(7, 16777216, [[1, 3], ["mainEntityDetailsPlaceholder", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityContext; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_EntityWithContextPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-with-context-panel", [], null, null, null, View_EntityWithContextPanelComponent_0, RenderType_EntityWithContextPanelComponent)), i0.ɵdid(1, 4440064, null, 0, i6.EntityWithContextPanelComponent, [i0.ChangeDetectorRef, i7.GlobalEntityTypesService, i0.ComponentFactoryResolver, i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntityWithContextPanelComponentNgFactory = i0.ɵccf("entity-with-context-panel", i6.EntityWithContextPanelComponent, View_EntityWithContextPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { EntityWithContextPanelComponentNgFactory as EntityWithContextPanelComponentNgFactory };
