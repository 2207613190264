<section class="wcd-margin-large-bottom" data-track-component-type="Settings" data-track-component="Sense Onboarding">
	<h3 *ngIf="this.isDownlevel" class="wcd-padding-bottom">{{"endpointManagement.sense.onboarding.step1.downlevel.title" | i18n}}</h3>
	<h3 *ngIf="!this.isDownlevel" class="wcd-padding-bottom">{{"endpointManagement.sense.onboarding.step1.title" | i18n}}</h3>
	<div class="wcd-padding-bottom">
		<div *ngIf="error; else machineStatus" class="error-message">{{"endpointManagement.sense.onboarding.error" | i18n}}
		</div>
		<ng-template #machineStatus>
			{{"endpointManagement.sense.onboarding.step1.firstEndpoint" | i18n}}
			<span *ngIf="!loading; else loadingMachineStatus">
				{{(onboardingState?.machineOnboarded ? 'endpointManagement.onboardingState.completed' : 'endpointManagement.onboardingState.incomplete') | i18n }}
				 <wcd-shared-icon *ngIf="onboardingState?.machineOnboarded" iconName="success"
					  class="color-text-success-light ms-icon-top-2-fix"> </wcd-shared-icon >
			</span>
			<ng-template #loadingMachineStatus><i class="loader-icon"></i></ng-template>
		</ng-template>
	</div>
	<div class="wcd-padding-bottom">
		<markdown [data]="'endpointManagement.sense.onboarding.step1.description' | i18n"></markdown>
	</div>
	<div *ngIf="this.isDownlevel" class="wcd-padding-bottom">
		<markdown [data]="'endpointManagement.sense.onboarding.step1.downlevel.description' | i18n"></markdown>
	</div>
	<section class="form-section">
		<div class="form-group">
			<label for="onboard-method">{{'endpointManagement.sense.onboarding.step1.deploymentMethod' | i18n}}</label>
			<div>
				<fancy-select [(ngModel)]="currentMethod"
					listId="onboard-method"
					name="onboard-method"
					[formatLabel]="getDeploymentMethodLabel"
					[isBordered]="true"
					labelClass="dropdown-width-medium"
					[values]="packages"></fancy-select>
			</div>
		</div>
		<div class="wcd-margin-bottom wcd-padding-small-all color-box-gray-100">
			 <markdown [data]="'endpointManagement.deploymentMethods.' + currentMethod.name + '.description' | i18n"></markdown>
		</div>
		<div class="wcd-flex-horizontal">
			<div *ngIf="this.isDownlevel" class="wcd-padding-right">
				<button class="btn btn-primary wcd-padding-right"
						[disabled]="!currentMethod"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						data-track-id="DownloadInstallationPackage"
						data-track-type="Button"
						(click)="downloadInstallationPackage()">
					 <wcd-shared-icon iconName="download"> </wcd-shared-icon >
					{{"endpointManagement.sense.onboarding.step1.downloadInstallationPackage" | i18n}}
				</button>
			</div>
			<div>
				<button class="btn btn-primary"
						[disabled]="!currentMethod"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						data-track-id="DownloadSenseOnboarding"
						data-track-type="Button"
						(click)="downloadPackage()">
					 <wcd-shared-icon iconName="download"> </wcd-shared-icon >
					{{"endpointManagement.sense.onboarding.step1.download" | i18n}}
				</button>
			</div>
		</div>
	</section>
</section>
<section class="wcd-margin-large-bottom">
	<h3 class="wcd-padding-bottom">{{"endpointManagement.sense.onboarding.step2.title" | i18n}}</h3>
	<div class="wcd-padding-bottom">
		<div *ngIf="error; else testStatus" class="error-message">{{"endpointManagement.sense.onboarding.error" | i18n}}
		</div>
		<ng-template #testStatus>
			{{"endpointManagement.sense.onboarding.step2.testDetection" | i18n}}
			<span *ngIf="!loading; else loadingTestStatus">
				{{(onboardingState?.ranTestAlert ? 'endpointManagement.onboardingState.completed' : 'endpointManagement.onboardingState.incomplete') | i18n }}
				 <wcd-shared-icon *ngIf="onboardingState?.ranTestAlert" iconName="success"
					  class="color-text-success-light ms-icon-top-2-fix"> </wcd-shared-icon >
			</span>
			<ng-template #loadingTestStatus><i class="loader-icon"></i></ng-template>
		</ng-template>
	</div>
	<detection-test [settings]="detectionTestSettings"></detection-test>
</section>
