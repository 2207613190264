<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="changeEvent" class="wcd-full-height relative wcd-flex-vertical">
  <div class="flex-expand">
    <entity-panel-collapsible-section [title]="'tvm.changeEvent.eventDetails' | i18n" [collapsed]="false">
      <div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
        <div class="tvm-list__key_val_item">
          <dt>{{'tvm.common.date' | i18n}}</dt>
          <dd>{{(changeEvent.date | date:'shortDate':'+00:00')}}</dd>
        </div>
        <div class="tvm-list__key_val_item">
          <dt>{{'tvm.changeEvent.type' | i18n}}</dt>
          <dd>{{('tvm.changeEvent.eventType.' + changeEvent.eventType) | i18n}}</dd>
        </div>
        <ng-container *ngIf="!isVaEvent">
          <div *ngIf="showScaEventScoring" class="tvm-list__key_val_item">
            <dt>{{'tvm_changeEvent_configuration_score_impact_header' | i18n}}
              <span>
                <fab-icon iconName="Info" contentClass="wcd-margin-xsmall-right" [wcdTooltip]="scoreImpactTooltip">
                </fab-icon>
              </span>
            </dt>
            <dd>
              <span>
                <fab-icon iconName="CalculatorAddition" contentClass="wcd-font-size-mi sca-score-addition">
				</fab-icon>
				{{ scaEventAchievedScoreText }}
              </span>
              <br>
              <span>
                <fab-icon iconName="CalculatorAddition" contentClass="wcd-font-size-mi">
				</fab-icon>
                {{ 'tvm_changeEvent_configuration_score_achievable' | i18n: {points: changeEvent.achievablePoints} }}
              </span>
            </dd>
          </div>
          <div class="tvm-list__key_val_item">
            <dt>{{'tvm_common_securityRecommendation' | i18n}}</dt>
            <dd *ngIf="(securityRecommendation$ | async) as securityRecommendation ; else loading" class="recommendation-title">{{ securityRecommendation.title }}</dd>
          </div>
          <div class="tvm-list__key_val_item">
            <dt>{{'tvm_securityRecommendation_configurationChange_configurationID' | i18n}}</dt>
            <dd>{{changeEvent.scid}}</dd>
          </div>
        </ng-container>
        <div *ngIf="!isVaEvent" class="tvm-list__key_val_item">
          <dt>{{relatedComponentHeader}}</dt>
          <dd>
            <a
              data-track-id="eventsNavigateToRelatedComponent"
              data-track-type="Navigation"
              (click)="navigateToRelatedComponent()">
              {{relatedComponent}}
            </a>
          </dd>
        </div>
        <div class="tvm-list__key_val_item">
          <dt>{{'tvm.changeEvent.originallyImpactedMachines' | i18n}}</dt>
          <dd>{{assetsCount}}</dd>
        </div>
        <div *ngIf="currentAssetsCount" class="tvm-list__key_val_item">
          <dt>{{'tvm.changeEvent.currentlyImpactedMachines' | i18n}}</dt>
          <dd>{{currentAssetsCount}}</dd>
        </div>
        <div *ngIf="isVaEvent" class="tvm-list__key_val_item">
          <dt>{{relatedComponentHeader}}</dt>
          <dd>
            <a
              data-track-id="eventsNavigateToRelatedComponent"
              data-track-type="Navigation"
              (click)="navigateToRelatedComponent()">
              {{relatedComponent}}
            </a>
          </dd>
        </div>
        <div *ngIf="!isVaEvent" class="tvm-list__key_val_item">
          <dt>{{'tvm_securityRecommendation_exposedOperatingSystems_title' | i18n}}</dt>
          <dd>
            <div *ngIf="(exposedOperatingSystems$ | async) as operatingSystemsDataSet ; else loading"
              wcdTooltip
              [tooltipInnerComponent]="ExposedOperatingSystemsComponent"
              [innerComponentInput]="{exposedOperatingSystemsStatistics: operatingSystemsDataSet.items}">
              {{ generateExposedOperatingSystems(operatingSystemsDataSet.items) }}
            </div>
          </dd>
        </div>
      </div>
    </entity-panel-collapsible-section>
    <related-cves-details *ngIf="isVaEvent"
      [repository]="vulnerabilitiesRelationshipRepository"
      [title]="'tvm.common.relatedCVE.title' | i18n"
      [maxItems]="3"
      [customFields]="fields"
      [initiallyCollapsed]="false"
      (showMore)=openAllRelatedCves()
      (export)="exportCves()">
    </related-cves-details>
  </div>
</div>

<ng-template #loading>
<div class="wcd-padding-small-top wcd-padding-large-right">
  <fab-spinner
    [size]="SpinnerSize.small"
    className="wcd-padding-left">
  </fab-spinner>
</div>
</ng-template>