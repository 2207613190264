import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineRiskScore, MachineRiskScoreType } from './machine-risk-score.models';
import { machineRiskScoreValues } from './machine-risk-score.values';

@Entity({
	singularName: 'Device Risk Score',
	pluralName: 'Device Risk Scores',
	values: machineRiskScoreValues,
})
export class MachineRiskScore extends EntityModelBase<MachineRiskScoreType> implements IMachineRiskScore {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineRiskScoreType {
		return this.id;
	}
}
