var _a;
export var UserStatusTypes;
(function (UserStatusTypes) {
    UserStatusTypes["ACTIVE"] = "ACTIVE";
    UserStatusTypes["SUSPENDED"] = "SUSPENDED";
    UserStatusTypes["DELETED"] = "DELETED";
})(UserStatusTypes || (UserStatusTypes = {}));
export var AadUserStatus = (_a = {},
    _a[UserStatusTypes.ACTIVE] = {
        nameI18nKey: UserStatusTypes.ACTIVE,
        iconName: 'Completed',
        iconColor: 'green',
    },
    _a[UserStatusTypes.SUSPENDED] = {
        nameI18nKey: UserStatusTypes.SUSPENDED,
        iconName: 'Blocked',
        iconColor: 'red',
    },
    _a[UserStatusTypes.DELETED] = {
        nameI18nKey: UserStatusTypes.DELETED,
        iconName: 'Cancel',
        iconColor: 'redDark',
    },
    _a);
