import { Injectable } from '@angular/core';
import { NetworkEndpoint } from '@wcd/domain';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { NetworkEndpointEntityPanelComponent } from '../components/network-endpoint.entity-panel.component';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { IpsService } from '../../ips/services/ips.service';
import { UrlsService } from '../../urls/services/urls.service';

@Injectable()
export class NetworkEndpointEntityTypeService implements EntityTypeService<NetworkEndpoint> {
	readonly entityType: EntityType<NetworkEndpoint> = {
		id: 'networkEndpoint',
		entity: NetworkEndpoint,
		icon: 'globe',
		singleEntityPanelComponentType: NetworkEndpointEntityPanelComponent,
		loadFullEntityInPanel: false,
		getActions: endpoints => {
			const actions: Array<ItemActionModelConfig> = [];
			if (endpoints.length === 1) {
				if (endpoints[0].ip) {
					actions.push({
						id: 'openIpPage',
						nameKey: 'networkEndpoints.actions.openIp',
						href: this.ipsService.getIpLink(endpoints[0].ip.id),
					});
				}

				if (endpoints[0].url) {
					actions.push({
						id: 'openUrlPage',
						nameKey: 'networkEndpoints.actions.openUrl',
						href: this.urlsService.getUrlLink(endpoints[0].url.id),
					});
				}
			}
			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
		getEntityName: (entity: NetworkEndpoint) => entity.name,
		getEntitiesLink: endpoints => null,
	};

	constructor(private readonly ipsService: IpsService, private readonly urlsService: UrlsService) {}
}
