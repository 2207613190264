import { getLocaleId } from '@angular/common';
import { compact } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getUserTimeZone } from '../locale.utils';
import { Injectable, OnDestroy } from '@angular/core';
import { sccHostService } from '@wcd/scc-interface';

const DEFAULT_TIMEZONE_OFFSET = 0,
	DEFAULT_LOCALE = 'en-US';

/**
 * Service for configuration which is local to the current browser.
 * Currently used for timezone setting.
 */
@Injectable({
	providedIn: 'root',
})
export class LocaleConfigService implements OnDestroy {
	private _selectedTimezone$: BehaviorSubject<number>;
	selectedTimezone$: Observable<number>;
	private _selectedLocale$: BehaviorSubject<string>;
	selectedLocale$: Observable<string>;
	config$: Observable<Config>;

	private getUserLocales(): Set<string> {
		const locales = new Set([DEFAULT_LOCALE]);
		if ((<any>navigator).userLanguage) {
			locales.add((<any>navigator).userLanguage);
		}
		if (navigator.languages && navigator.languages.length) {
			navigator.languages.forEach(lang => locales.add(lang));
		}
		if (navigator.language) {
			locales.add(navigator.language);
		}
		return locales;
	}

	private getDefaultUserLocale(): string {
		return this.getSupportedLocales()[0];
	}

	getSupportedLocales(): Array<string> {
		const userLocales = this.getUserLocales();

		// remove locales that Angular can't resolve
		const foundLocales = new Set();
		return compact(
			Array.from(userLocales).map(loc => {
				try {
					const localeId = getLocaleId(loc);
					if (localeId && !foundLocales.has(localeId)) {
						foundLocales.add(localeId);
						return loc;
					}
				} catch (e) {}
			})
		);
	}

	get isLocalTimeZone(): boolean {
		return sccHostService.isSCC ?
			!sccHostService.i18n.alwaysUseUTC :
			this._selectedTimezone$.value === getUserTimeZone();
	}

	get selectedLocale(): string {
		return this._selectedLocale$.value;
	}

	get selectedTimezone(): number {
		return this._selectedTimezone$.value;
	}

	constructor() {
		this._selectedTimezone$ = new BehaviorSubject<number>(DEFAULT_TIMEZONE_OFFSET);
		this.selectedTimezone$ = this._selectedTimezone$.asObservable();
		this._selectedLocale$ = new BehaviorSubject<string>(this.getDefaultUserLocale());
		this.selectedLocale$ = this._selectedLocale$.asObservable();
		this.config$ = combineLatest([this.selectedTimezone$, this.selectedLocale$]).pipe(
			map(([_timeZone, _locale]) => {
				return {
					timeZone: _timeZone,
					locale: _locale,
				};
			})
		);
	}

	setSelectedTimezone(selectedTimezone: number) {
		if (this._selectedTimezone$.value !== selectedTimezone) {
			this._selectedTimezone$.next(selectedTimezone);
		}
	}

	setSelectedLocale(selectedLocale: string) {
		if (this._selectedLocale$.value !== selectedLocale) {
			this._selectedLocale$.next(selectedLocale);
		}
	}

	ngOnDestroy(): void {
		this._selectedLocale$.complete();
		this._selectedTimezone$.complete();
	}
}

export interface Config {
	timeZone: number;
	locale: string;
}
