/* tslint:disable:template-click-events-have-key-events */
/* tslint:disable:template-accessibility-label-for */
import { Component, Injector, OnInit } from '@angular/core';
import { lowerFirst } from 'lodash-es';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Incident } from '@wcd/domain';
import { IncidentsService } from '../../../@entities/incidents/services/incidents.service';
import { AuthService, MtpPermission } from '@wcd/auth';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';
import { I18nService } from '@wcd/i18n';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { RoutesService } from '@wcd/shared';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { EntityTags } from '../../../@entities/common/services/entity-tags.service';

enum CollapsibleID {
	Details = 'incident-entity-details',
	Tags = 'incident-entity-tags',
}

@Component({
	selector: 'incident-entity-details',
	templateUrl: './incident.entity-details.component.html',
	styleUrls: ['./incident.entity-details.component.scss'],
	// keep change detection default since incident can be updated from status and classification pane
})
export class IncidentEntityDetailsComponent extends EntityDetailsComponentBase<Incident> implements OnInit {
	public EntityPageViewMode = EntityPageViewMode;
	readonly urls = TryItUrls;
	public EntityDetailsMode = EntityDetailsMode;
	public readonly incidentGraphPOCEnabled: boolean;
	public isFullyMachineRbacExposed: boolean;
	showAssociatedIncidents: boolean;
	public phishingPlaybook: boolean = false;
	public ransomwarePlaybook: boolean = false;

	collapsibleID = CollapsibleID;

	get incident(): Incident {
		return this.entity;
	}

	get showAdditionalAlertsMessage(): boolean {
		return this.incident && this.incident.mtpAlertsExist && !this.appContextService.isMtp;
	}

	get SCCIncidentUrl(): string {
		return `${RoutesService.getSCCRootUrl()}/incidents/${this.incident.id}/overview`;
	}

	constructor(
		injector: Injector,
		private incidentsService: IncidentsService,
		public authService: AuthService,
		private featuresService: FeaturesService,
		public mtpPromotionService: MtpPromotionService,
		private appContextService: AppContextService,
		private i18nService: I18nService,
		private rbacControlService: RbacControlService,
		private flavorService: FlavorService,
		private appInsightsService: AppInsightsService
	) {
		super(injector);
		this.incidentGraphPOCEnabled =
			featuresService.isEnabled(Feature.IncidentGraphPoc) &&
			this.appContextService.isSCC && //link won't work outside of SCC
			flavorService.isEnabled(AppFlavorConfig.incidents.incidentGraph); // will only be seen to P2 license and other workloads
	}

	ngOnInit() {
		const rbacSettings = {
			mtpPermissions: [MtpPermission.SecurityData_Manage],
			mtpWorkloads: this.incident.mtpWorkloads,
			requireAllPermissions: true,
			ignoreNonActiveWorkloads: true,
		};
		this.isFullyMachineRbacExposed =
			this.incident.isFullyMachineRbacExposed &&
			this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
		this.showAssociatedIncidents = this.flavorService.isEnabled(
			AppFlavorConfig.incidents.associatedIncident
		);
		const SOCPlaybook = this.featuresService.isEnabled(Feature.SOCPlaybook);

		this.ransomwarePlaybook = window['SupportCentral'] && window['SupportCentral'].LinkTo && SOCPlaybook && this.isRansomware;
		this.phishingPlaybook =
			window['SupportCentral'] && window['SupportCentral'].LinkTo && SOCPlaybook && this.isPhishing;
	}

	showSetClassificationPanel(): void {
		this.incidentsService.showSetClassificationPanel([this.incident]).subscribe();
	}

	getClassification = (incident: Incident) => {
		return incident.classification
			? this.i18nService.get(incident.classification.nameI18nKey)
			: `(${this.i18nService.get('alerts.unclassified')})`;
	};

	getCategoryName = (category: string) =>
		this.i18nService.get('reporting_alertsByCategory_' + lowerFirst(category));

	get incidentStatusColor() {
		const incidentColor = this.incidentsService.getIncidentsCommonColorClass([this.incident]);
		return this.incident.status.className === 'success' ? 'color-box-success' : incidentColor;
	}

	get incidentGraphLink() {
		return `/incident-graph/${this.incident.id}`;
	}

	get isPhishing(): boolean {
		const phishing = (this.incident.name).match('/phish/im') || PhishingAlertTitles.includes(this.incident.name);
		phishing && this.appInsightsService.trackEvent('displaying_phishing_recommendations_card');
		return !!phishing;
	}

	get isRansomware(): boolean {
		const ransomware = (this.incident.name).match('/ransomware/im') || (this.incident.incidentTags.systemTags && this.incident.incidentTags.systemTags.includes(EntityTags.RANSOMWARE));
		ransomware && this.appInsightsService.trackEvent('displaying_ransomware_recommendations_card');
		return !!ransomware;
	}

	openSocPlayBook(searchTerm: string) {
		this.appInsightsService.trackEvent('soc_playbook_opened', {
			searchTerm: searchTerm,
		});
		window['SupportCentral'].LinkTo('SearchHelp', '', searchTerm);
	}
}
const PhishingAlertTitles = [
	"A potentially malicious URL click was detected",
	"Email messages containing malicious file removed after delivery",
	"Email messages containing malicious URL removed after delivery",
	"Email messages from a campaign removed after delivery",
	"Email messages removed after delivery",
	"Malware not zapped because ZAP is disabled",
	"Phish delivered due to an ETR override",
	"Phish delivered due to an IP allow policy",
	"Phish not zapped because ZAP is disabled"
]
