import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { SoftwareInstallation, Tag, EolState } from '@wcd/domain';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { TzDateService } from '@wcd/localization';
import { TvmTextsService, InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { ReportableBaseFieldComponent } from '../../../../tvm/components/report-inaccuracy/tvm-reportable-base-field.component';
import { FeaturesService, Feature } from '@wcd/config';
import { SoftwareInstallationNormalizedDisplayPipe } from '../../../../../app/tvm/pipes/software-installation-normalized-display.pipe';
import { I18nService } from '@wcd/i18n';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';

@Injectable()
export class SoftwareInstallationFieldsService implements FieldsService<SoftwareInstallation> {
	private _fields: Array<DataviewField<SoftwareInstallation>>;
	private _assetInstallationFields: Array<DataviewField<SoftwareInstallation>>;
	private _programsFilters: Record<string, any>;
	constructor(
		private tzDateService: TzDateService,
		private tvmIconBuilderService: TvmIconBuilderService,
		private readonly featuresService: FeaturesService,
		private softwareInstallationNormalizedDisplayPipe: SoftwareInstallationNormalizedDisplayPipe,
		private i18nService: I18nService,
		private tvmTagsService: TvmTagsService
	) {}

	get assetInstallationsFields(): Array<DataviewField<SoftwareInstallation>> {
		const naString = this.i18nService.get('notAvailable.short');
		if (!this._assetInstallationFields) {
			const installationsFieldsArr: Array<DataviewFieldConfig<SoftwareInstallation>> = [
				{
					id: 'productName',
					name: this.i18nService.get('tvm.common.name'),
					component: {
						type: ReportableBaseFieldComponent,
						getProps: (installation: SoftwareInstallation) => ({
							inaccuracyContext: InaccuracyContext.MachineInventory,
							contextObject: installation,
							title: installation.productName,
						}),
					},
					getLink: (installation: SoftwareInstallation) => {
						return `/software-inventory/${encodeURIComponent(installation.getProductId())}`;
					},
					getDisplay: (softwareInstallation: SoftwareInstallation) =>
						softwareInstallation.productName == '' ? naString : softwareInstallation.productName,
				},
				{
					id: 'vendor',
					name: this.i18nService.get('tvm.common.vendor'),
					getDisplay: (softwareInstallation: SoftwareInstallation) =>
						softwareInstallation.vendor == '' ? naString : softwareInstallation.vendor,
				},
				{
					id: 'installedVersion',
					name: this.i18nService.get('tvm.common.installedVersions'),
					getDisplay: (softwareInstallation: SoftwareInstallation) =>
						softwareInstallation.installedVersion == ''
							? naString
							: softwareInstallation.installedVersion,
				},
				{
					id: 'weaknesses',
					name: this.i18nService.get('tvm.common.weaknesses'),
					getDisplay: (softwareInstallation: SoftwareInstallation) =>
						softwareInstallation.productNeverMatched
							? this.i18nService.get('notAvailable.long')
							: softwareInstallation.weaknesses,
				},
				{
					id: 'threats',
					name: this.i18nService.get('tvm.common.threats'),
					sort: {
						enabled: false,
					},
					component: {
						type: TvmThreatIconsComponent,
						getProps: (installation: SoftwareInstallation) =>
							this.tvmIconBuilderService.configureIcons(
								installation.threatInfo,
								null,
								'software',
								false,
								null,
								true
							),
					},
				},
				// TODO: add first seen and last seen field when the BE will support it
			];

			if (this.featuresService.isEnabled(Feature.TvmNonCpePrograms)) {
				installationsFieldsArr.push({
					id: 'isNormalized',
					name: this.i18nService.get('tvm.software.productCode'),
					getDisplay: (softwareInstallation: SoftwareInstallation) => {
						return this.softwareInstallationNormalizedDisplayPipe.transform(softwareInstallation);
					},
				});
			}

			installationsFieldsArr.push({
				id: 'tags',
				name: this.i18nService.get('common.tags'),
				component: {
					type: TagsFieldComponent,
					getProps: (installation: SoftwareInstallation) => ({
						tags: this.tvmTagsService.getSoftwareInstallationTags(installation),
					}),
				},
				sort: { enabled: false },
			});

			this._assetInstallationFields = DataviewField.fromList<SoftwareInstallation>(
				installationsFieldsArr
			);
		}
		return this._assetInstallationFields;
	}

	get fields(): Array<DataviewField<SoftwareInstallation>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<SoftwareInstallation>([
				{
					id: 'assetName',
					name: this.i18nService.get('tvm.common.machine'),
				},
				{
					id: 'installedVersion',
					name: this.i18nService.get('tvm.common.installedVersion'),
				},
				{
					id: 'lastSeen',
					name: this.i18nService.get('tvm.software.lastSeen'),
					getDisplay: (softwareInstallation: SoftwareInstallation) => {
						return this.tzDateService.format(softwareInstallation.lastSeen, 'short');
					},
				},
				{
					id: 'highSevAlert',
					name: this.i18nService.get('tvm.software.highAlerts'),
					getDisplay: (softwareInstallation: SoftwareInstallation) => {
						return softwareInstallation.highSevAlert
							? this.i18nService.get('common.yes')
							: this.i18nService.get('common.no');
					},
				},

				/*
					TODO: re-add when BE returns this data again (bug 20713425)
{
					id: 'exposureLevel',
					name: this.i18nService.get('machines.report.widgets.securityAssessments.machineExposure')
					getDisplay: (softwareInstallation: SoftwareInstallation) =>
						softwareInstallation.exposureCategory || 'N/A',
					sort: {
						enabled: false,
					},
					icon: {
						fabricIcon: (softwareInstallation: SoftwareInstallation) =>
							softwareInstallation.exposureCategory ? FabricIconNames.Warning : null,
						className: (softwareInstallation: SoftwareInstallation) =>
							softwareInstallation.exposureCategory
								? this.tvmExposureScoreService.getExposureCategoryColorClass(
										softwareInstallation.exposureCategory
								  )
								: '',
					},
				}, */
			]);
		}

		return this._fields;
	}
}
