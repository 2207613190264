export class Lazy<T> {
	private _value: T;

	constructor(private readonly getValue: () => T, private readonly reuseValue = true) {}

	get value(): T {
		if (!this.reuseValue) {
			return this.getValue();
		}

		if (typeof this._value === 'undefined') {
			this._value = this.getValue();
		}

		return this._value;
	}
}
