var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
var ɵ0 = function (itemId, entity, config, params) {
    return "productEvidence?assetId=" + encodeURIComponent(params.assetId) + "&productId=" + encodeURIComponent(itemId) + "&productVersion=" + encodeURIComponent(params.productVersion) + "&nonCpeProgram=" + params.nonCpeProgram;
}, ɵ1 = function (value) {
    var valueInUtc = value ? value + ' UTC' : undefined;
    var dateParsed = Date.parse(valueInUtc);
    if (isNaN(dateParsed) === false) {
        return new Date(dateParsed);
    }
    return undefined;
};
var ProductEvidence = /** @class */ (function (_super) {
    __extends(ProductEvidence, _super);
    function ProductEvidence() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({}),
        __metadata("design:type", Array)
    ], ProductEvidence.prototype, "diskPaths", void 0);
    __decorate([
        EntityField({}),
        __metadata("design:type", Array)
    ], ProductEvidence.prototype, "registryPaths", void 0);
    __decorate([
        EntityField({
            // Time looks like 2019-01-25 00:03
            // As it doesn't include +00 or GMT its considered as local time, we add UTC
            parse: ɵ1,
        }),
        __metadata("design:type", Date)
    ], ProductEvidence.prototype, "lastSeenTimestampUtc", void 0);
    ProductEvidence = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Product evidence', pluralName: 'Product evidences', endpoint: 'productEvidence', parseItemQuery: ɵ0, readonly: true }))
    ], ProductEvidence);
    return ProductEvidence;
}(EntityModelBase));
export { ProductEvidence };
export { ɵ0, ɵ1 };
