import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';

@Component({
	selector: 'full-domain-url-list-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<domain-urls
				class="wcd-flex-1"
				[domainId]="domainIdSelected"
				[allowPaging]="false"
				[navigateOnChange]="false"
				[fullUrlList]="true"
				[hideControls]="false"
				[searchEnabled]="true"
				[defaultSortFieldId]="'accessCount'"
				[hideCommandBarOnEmptyData]="false"
			></domain-urls>
		</wcd-panel>
	`,
})
export class DomainUrlsFullComponent extends PanelContainer {
	domainIdSelected: string;

	constructor(router: Router) {
		super(router);
	}
}
