/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./impacted-entities-counter.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "@angular/common";
import * as i8 from "./impacted-entities-counter.component";
import * as i9 from "../../../global_entities/services/global-entity-types.service";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_ImpactedEntitiesCounterComponent = [i0.styles];
var RenderType_ImpactedEntitiesCounterComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_ImpactedEntitiesCounterComponent, data: {} });
export { RenderType_ImpactedEntitiesCounterComponent as RenderType_ImpactedEntitiesCounterComponent };
function View_ImpactedEntitiesCounterComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fab-icon", [["contentClass", "field-value-icon"]], null, null, null, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i1.ɵdid(1, 5816320, null, 0, i3.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null)], function (_ck, _v) { var currVal_0 = "field-value-icon"; var currVal_1 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_ImpactedEntitiesCounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [["class", "wcd-padding-small-right wcd-text-overflow-medium"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i4.TooltipDirective, [i1.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipSanitizeHtml: [1, "wcdTooltipSanitizeHtml"], wcdTooltipAllowHtmlRendering: [2, "wcdTooltipAllowHtmlRendering"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesCounterComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.tooltip; var currVal_1 = false; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = _v.context.$implicit.icon; _ck(_v, 4, 0, currVal_3); }, function (_ck, _v) { var currVal_4 = _v.context.$implicit.display; _ck(_v, 7, 0, currVal_4); }); }
export function View_ImpactedEntitiesCounterComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-impacted-entities-counter"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 4, "ul", [["class", "wcd-flex wcd-padding-none-all wcd-margin-none-all"]], [[2, "wcd-flex-horizontal", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesCounterComponent_1)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.entitiesList; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.layout === "row"); _ck(_v, 3, 0, currVal_0); }); }
export function View_ImpactedEntitiesCounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "impacted-entities-counter", [], null, null, null, View_ImpactedEntitiesCounterComponent_0, RenderType_ImpactedEntitiesCounterComponent)), i1.ɵdid(1, 770048, null, 0, i8.ImpactedEntitiesCounterComponent, [i9.GlobalEntityTypesService, i10.I18nService, i11.WicdSanitizerService, i12.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImpactedEntitiesCounterComponentNgFactory = i1.ɵccf("impacted-entities-counter", i8.ImpactedEntitiesCounterComponent, View_ImpactedEntitiesCounterComponent_Host_0, { entities: "entities", entitiesCount: "entitiesCount", layout: "layout" }, {}, []);
export { ImpactedEntitiesCounterComponentNgFactory as ImpactedEntitiesCounterComponentNgFactory };
