import { HttpClient, HttpParams } from '@angular/common/http';
import { isNil } from 'lodash-es';

export class ApiBaseModel {
	constructor(protected http: HttpClient) {}

	extractData(res: any) {
		return res.data;
	}

	getUrlParams(options: { [index: string]: any }): { params: HttpParams } {
		return ApiBaseModel.getUrlParams(options);
	}

	/**
	 * Prepares form data with files from the given object, so files can be uploaded
	 * @param data
	 * @returns {FormData}
	 */
	getFormData(data: Object): FormData {
		const postData = new FormData();

		for (const p in data) {
			const value = data[p];
			postData.append(value instanceof File ? 'file[]' : p, value);
		}

		return postData;
	}

	static getUrlParams(options: { [index: string]: any }): { params: HttpParams } {
		let params: HttpParams = new HttpParams();

		if (options) {
			for (const p in options) {
				if (options.hasOwnProperty(p)) {
					const value: any = options[p];
					if (value instanceof Array)
						value.forEach(_value => (params = params.append(p, String(_value))));
					else if (!isNil(value)) params = params.append(p, String(value));
				}
			}
		}

		return { params: params };
	}
}
