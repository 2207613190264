/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "../../../global_entities/components/entity-details/alerts-manage.component.ngfactory";
import * as i6 from "../../../global_entities/components/entity-details/alerts-manage.component";
import * as i7 from "../services/alerts.service";
import * as i8 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i9 from "@microsoft/paris/dist/lib/paris";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "../../../insights/services/app-insights.service";
import * as i12 from "../services/alert-page.service";
import * as i13 from "../../../shared/main/services/main-app-state.service";
import * as i14 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i15 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i16 from "../../../../../../../projects/expander/src/lib/collapsible-section.component.ngfactory";
import * as i17 from "../../../../../../../projects/expander/src/lib/collapsible-section.component";
import * as i18 from "./alert-comments.component.ngfactory";
import * as i19 from "./alert-comments.component";
import * as i20 from "@angular/cdk/a11y";
import * as i21 from "../../../dialogs/snackbars/services/snackbars.service";
import * as i22 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i23 from "./alerts.entity-panel.component";
var styles_AlertsEntityPanelComponent = [];
var RenderType_AlertsEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertsEntityPanelComponent, data: {} });
export { RenderType_AlertsEntityPanelComponent as RenderType_AlertsEntityPanelComponent };
function View_AlertsEntityPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_AlertsEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [["class", "disabled"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertsEntityPanelComponent_2)), i0.ɵdid(5, 540672, null, 0, i4.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "alerts.panel.cantManageAllAlerts")); _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 9); _ck(_v, 5, 0, currVal_1); }, null); }
function View_AlertsEntityPanelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "alerts-manage", [], null, [[null, "statusChanged"], [null, "classificationChanged"], [null, "determinationChanged"], [null, "assigneeChanged"], [null, "restored"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("statusChanged" === en)) {
        var pd_0 = (_co.onStatusChanged() !== false);
        ad = (pd_0 && ad);
    } if (("classificationChanged" === en)) {
        var pd_1 = (_co.onClassificationChanged() !== false);
        ad = (pd_1 && ad);
    } if (("determinationChanged" === en)) {
        var pd_2 = (_co.onDeterminationChanged() !== false);
        ad = (pd_2 && ad);
    } if (("assigneeChanged" === en)) {
        var pd_3 = (_co.onAssigneeChanged() !== false);
        ad = (pd_3 && ad);
    } if (("restored" === en)) {
        var pd_4 = (_co.refreshEntities() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i5.View_AlertsManageComponent_0, i5.RenderType_AlertsManageComponent)), i0.ɵdid(2, 638976, null, 0, i6.AlertsManageComponent, [i7.AlertsService, i8.AuthService, i0.ChangeDetectorRef, i9.Paris, i10.I18nService, i11.AppInsightsService, i12.AlertPageService, i13.MainAppStateService, i14.PreferencesService, i15.FeaturesService], { alerts: [0, "alerts"], readonly: [1, "readonly"] }, { restored: "restored", statusChanged: "statusChanged", classificationChanged: "classificationChanged", determinationChanged: "determinationChanged", assigneeChanged: "assigneeChanged" }), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entities; var currVal_1 = (_co.hasReadOnlyAlert || (i0.ɵunv(_v, 2, 1, i0.ɵnov(_v, 3).transform(_co.isUserExposed$)) !== true)); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AlertsEntityPanelComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "wcd-collapsible-section", [], null, null, null, i16.View_CollapsibleSectionComponent_0, i16.RenderType_CollapsibleSectionComponent)), i0.ɵdid(1, 49152, null, 0, i17.CollapsibleSectionComponent, [], { label: [0, "label"], sectionId: [1, "sectionId"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "alerts-comments", [], null, null, null, i18.View_AlertsCommentsComponent_0, i18.RenderType_AlertsCommentsComponent)), i0.ɵdid(5, 638976, null, 0, i19.AlertsCommentsComponent, [i7.AlertsService, i0.ChangeDetectorRef, i8.AuthService, i10.I18nService, i20.LiveAnnouncer, i21.SnackBarsService], { comments: [0, "comments"], alerts: [1, "alerts"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "entityCommon.panel.comments.header")); var currVal_1 = _co.collapsibleID.AlertComments; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.comments; var currVal_3 = _co.entities; _ck(_v, 5, 0, currVal_2, currVal_3); }, null); }
export function View_AlertsEntityPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i22.I18nPipe, [i10.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 8, "wcd-collapsible-section", [], null, null, null, i16.View_CollapsibleSectionComponent_0, i16.RenderType_CollapsibleSectionComponent)), i0.ɵdid(3, 49152, null, 0, i17.CollapsibleSectionComponent, [], { label: [0, "label"], sectionId: [1, "sectionId"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_AlertsEntityPanelComponent_1)), i0.ɵdid(7, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(0, [["alertsManage", 2]], 0, 0, null, View_AlertsEntityPanelComponent_3)), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AlertsEntityPanelComponent_4)), i0.ɵdid(13, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "alerts.panel.manageAlerts")); var currVal_1 = _co.collapsibleID.AlertsManage; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.hasReadOnlyAlert; var currVal_3 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_2, currVal_3); var currVal_4 = i0.ɵunv(_v, 13, 0, i0.ɵnov(_v, 14).transform(_co.isUserExposed$)); _ck(_v, 13, 0, currVal_4); }, null); }
export function View_AlertsEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alerts-entity-panel", [], null, null, null, View_AlertsEntityPanelComponent_0, RenderType_AlertsEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i23.AlertsEntityPanelComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsEntityPanelComponentNgFactory = i0.ɵccf("alerts-entity-panel", i23.AlertsEntityPanelComponent, View_AlertsEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { AlertsEntityPanelComponentNgFactory as AlertsEntityPanelComponentNgFactory };
