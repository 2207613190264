/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./icon-switch.component";
var styles_IconSwitchComponent = [];
var RenderType_IconSwitchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IconSwitchComponent, data: {} });
export { RenderType_IconSwitchComponent as RenderType_IconSwitchComponent };
export function View_IconSwitchComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { placeholder: 0 }), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵeld(2, 16777216, [[1, 3], ["placeholder", 1]], null, 0, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" "]))], null, null); }
export function View_IconSwitchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "icon-switch", [], null, null, null, View_IconSwitchComponent_0, RenderType_IconSwitchComponent)), i0.ɵdid(1, 573440, null, 0, i1.IconSwitchComponent, [i0.ComponentFactoryResolver], null, null)], null, null); }
var IconSwitchComponentNgFactory = i0.ɵccf("icon-switch", i1.IconSwitchComponent, View_IconSwitchComponent_Host_0, { iconName: "iconName", contentClass: "contentClass", ariaLabel: "ariaLabel", styles: "styles" }, {}, []);
export { IconSwitchComponentNgFactory as IconSwitchComponentNgFactory };
