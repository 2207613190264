import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { AppContextService } from '@wcd/config';
import { UrlThreatType, EmailUrlHelpers } from '@wcd/domain';

export interface EmailUrlsPanelInput {
	urls: Array<string>;
	potentiallyHarmfulUrls: Map<string, UrlThreatType>;
}

@Component({
	selector: 'email-urls-details-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-urls-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_emailMessage_urls_and_threats_title }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-medium-left">
				<wcd-datatable [items]="items" [columns]="tableFields" [selectEnabled]="false">
				</wcd-datatable>
			</div>
		</wcd-panel>
	`,
})
export class EmailUrlsDetailsPanelComponent extends PanelContainer {
	@Input() urls: Array<string>;
	@Input() potentiallyHarmfulUrls: Map<string, UrlThreatType>;

	items: Array<UrlAndThreat>;

	tableFields: Array<DataTableField>;

	ngOnInit() {
		super.ngOnInit();
		this.setItems();
	}

	setItems(): void {
		this.items = this.urls.map(
			(url: string): UrlAndThreat => {
				let threatTypeDisplayName: string;
				const urlThreatType = this.getUrlThreatType(url);
				switch (urlThreatType) {
					case UrlThreatType.Spam: {
						threatTypeDisplayName = this.i18nService.strings
							.airsEntities_emailMessage_urls_threat_types_spam;
						break;
					}
					case UrlThreatType.Phish: {
						threatTypeDisplayName = this.i18nService.strings
							.airsEntities_emailMessage_urls_threat_types_phish;
						break;
					}
					case UrlThreatType.Malware: {
						threatTypeDisplayName = this.i18nService.strings
							.airsEntities_emailMessage_urls_threat_types_malware;
						break;
					}
					default: {
						threatTypeDisplayName = this.i18nService.strings
							.airsEntities_emailMessage_urls_threat_types_none;
					}
				}
				return { url: url, threat: threatTypeDisplayName };
			}
		);
	}

	constructor(
		router: Router,
		public i18nService: I18nService,
		private appContextService: AppContextService
	) {
		super(router);
		const hasUrlPage = appContextService.isMtp || !appContextService.isSCC;
		const urlField = Object.assign(
			{
				id: 'url',
				name: this.i18nService.strings
					.airsEntities_emailMessage_fields_urls_and_threats_url_columnName,
				getTooltip: (item: UrlAndThreat): string => item.url,
				className: 'nowrap wcd-text-overflow-medium',
			},
			hasUrlPage
				? {
						onClick: (item: any) => {
							// open threatExplorer for the selected url in new tab
							window.open(
								`/threatexplorer?dltarget=Explorer&dlstorage=Url&viewid=allemail&query-CanonicalizedUrl=${encodeURIComponent(
									item.url
								)}`
							);
						},
						getCssClass: () => 'btn-link',
				  }
				: null
		);

		const threatsField = {
			id: 'threat',
			name: this.i18nService.strings
				.airsEntities_emailMessage_fields_urls_and_threats_threat_columnName,
			getTooltip: (item: UrlAndThreat): string => item.threat,
			className: 'nowrap wcd-text-overflow-small',
		};

		this.tableFields = DataTableField.fromList<UrlAndThreat>([urlField, threatsField]).map(column => {
			column.sort.enabled = false;
			return column;
		});
	}

	private getUrlThreatType(url: string) {
		const potentiallyHarmfulUrlsArray = Array.from(this.potentiallyHarmfulUrls);
		for (const [partialUrl, threatType] of potentiallyHarmfulUrlsArray) {
			if (EmailUrlHelpers.doesMatch(url, partialUrl)) {
				return threatType;
			}
		}
		return null;
	}
}

interface UrlAndThreat {
	url: string;
	threat: string;
}
