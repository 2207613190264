var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IpLocation } from '@wcd/domain';
import { Stream } from "../entity/stream.value-object";
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = ['IPAddress', 'IpAddress', 'id', 'address'], ɵ2 = ['IPAddress', 'IpAddress', 'address'], ɵ3 = function (data) { return new IpLocation(data); }, ɵ4 = ['Stream', 'stream'];
var Ip = /** @class */ (function (_super) {
    __extends(Ip, _super);
    function Ip() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ1 }) // IpAddress is deprecated. please use "IPAddress" if possible.
        ,
        __metadata("design:type", String)
    ], Ip.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ2 }) // IpAddress is deprecated. please use "IPAddress" if possible.
        ,
        __metadata("design:type", String)
    ], Ip.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'IpPageUrl', required: false }),
        __metadata("design:type", String)
    ], Ip.prototype, "externalIpPageUrl", void 0);
    __decorate([
        EntityField({
            data: 'location',
            parse: ɵ3,
        }),
        __metadata("design:type", IpLocation)
    ], Ip.prototype, "location", void 0);
    __decorate([
        EntityField({ data: 'startTimeUtc' }),
        __metadata("design:type", String)
    ], Ip.prototype, "signInTime", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Ip.prototype, "type", void 0);
    __decorate([
        EntityField({ data: 'Domain' }),
        __metadata("design:type", String)
    ], Ip.prototype, "domain", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Stream)
    ], Ip.prototype, "stream", void 0);
    __decorate([
        EntityField({ data: 'eventTime' }),
        __metadata("design:type", Date)
    ], Ip.prototype, "eventTime", void 0);
    Ip = __decorate([
        Entity({
            singularName: 'Ip address',
            pluralName: 'Ip addresses',
            endpoint: 'ips',
            allItemsProperty: 'results',
            baseUrl: ɵ0,
            cache: {
                time: 1000 * 60 * 5,
                max: 10,
            },
        })
    ], Ip);
    return Ip;
}(EntityModelBase));
export { Ip };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
