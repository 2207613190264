import * as CreateRequest from '../machine-requests.models';

/**
 * When querying (GET) The backend - we get back the "raw" value (i.e. as a number - either 0 or 1).
 * When making calls on it, the backend expects string values (i.e. either 'Restrict' or 'Unrestrict'). This is typed above as `AntivirusScanType`.
 */
export enum QueryExecutionPolicyTypeRaw {
	Restrict = 0,
	Unrestrict = 1,
}

export enum RawPolicyType {
	Restrict = 'Restrict',
	Unrestrict = 'Unrestrict',
}

export type RestrictPolicyType = keyof typeof RawPolicyType;

export interface RestrictAppExecutionRawApiRequestBodyBase<TPolicyType extends RestrictPolicyType>
	extends CreateRequest.RawApiRequestBody<'RestrictExecutionRequest'> {
	readonly ClientVersion: string;
	readonly PolicyType: TPolicyType;
}
