export enum NotificationFormatEnum {
	default = 0,
	customerName = 1,
	deeplink = 2,
	machineName = 4,
}

export const notificationFormatValues = [
	{
		id: NotificationFormatEnum.customerName,
		defaultValue: true,
		name: 'customerName',
	},
	{
		id: NotificationFormatEnum.deeplink,
		defaultValue: true,
		name: 'deeplink',
	},
	{
		id: NotificationFormatEnum.machineName,
		name: 'machineName',
		helpKey: 'notificationRules.formatOptionHelpers.machineName',
	},
];
