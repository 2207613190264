import { Injectable } from '@angular/core';
import { DownloadService } from '../../shared/services/download.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { ServiceUrlsService } from '@wcd/app-config';
import { DataQuery, RelationshipRepository } from '@microsoft/paris';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import {
	AddMachineGroupsFilterQueryParam,
	AddODataSearchQueryParam,
	AddODataFilterQueryParam,
	AddODataSortingQueryParam,
	DefaultApiVersion,
	fieldFiltersMap,
	ResolveEntityURLAndQueryParams,
	TvmEndPoint,
} from '@wcd/domain';

export interface DownloadFileConfig {
	url: string;
	fileName: string;
	dataQuery?: DataQuery;
	queryParams?: { [index: string]: any };
	alreadyHasQueryParamInUrl?: boolean;
	customQueryParams?: { [index: string]: any };
}

@Injectable()
export class TvmDownloadService {
	constructor(
		private downloadService: DownloadService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService,
		private serviceUrlsService: ServiceUrlsService
	) {}

	downloadCsvFromRelationshipRepository(
		repository: RelationshipRepository<any, any>,
		endpoint: TvmEndPoint,
		filename: string,
		dataQuery?: DataQuery,
		extraDataQuery?: { [index: string]: any },
		customFilter?: { [index: string]: any }
	) {
		const { url, queryParams } = ResolveEntityURLAndQueryParams({
			endPoint: endpoint,
			entityModelBaseOrRelationship: repository,
			sourceModel: repository.sourceItem,
			extraQueryData: extraDataQuery,
		});

		return this.downloadCsv({
			url: url,
			fileName: filename,
			dataQuery: dataQuery,
			queryParams: queryParams,
			customQueryParams: customFilter,
		});
	}

	downloadCsv(downloadCsvConfig: DownloadFileConfig) {
		const queryParams = downloadCsvConfig.queryParams || {};
		AddMachineGroupsFilterQueryParam(queryParams);
		const dataQuery = downloadCsvConfig.dataQuery;
		if (dataQuery) {
			AddODataSearchQueryParam(dataQuery, queryParams);
			AddODataFilterQueryParam(dataQuery, queryParams, fieldFiltersMap);
			AddODataSortingQueryParam(dataQuery, queryParams);
		}

		this.appInsightsService.trackEvent('tvm-export-csv-event', {
			fileName: downloadCsvConfig.fileName,
			url: downloadCsvConfig.url,
		});

		const customQueryParams = downloadCsvConfig.customQueryParams || {};

		return this.downloadFile(downloadCsvConfig, 'csv', { ...queryParams, ...customQueryParams });
	}

	downloadFile(
		downloadFileConfig: DownloadFileConfig,
		filetype?: string,
		queryParams?: { [index: string]: any }
	) {
		let endpoint = `${this.serviceUrlsService.tvm}/${downloadFileConfig.url}`;
		let fileName = `${downloadFileConfig.fileName}`;

		this.dialogsService.showSnackbar({
			text: this.i18nService.get('tvm.download.waitForDownload'),
			icon: 'Info',
		});
		if (filetype === 'csv') {
			endpoint =
				endpoint + (downloadFileConfig.alreadyHasQueryParamInUrl ? `&asCsv=true` : `?asCsv=true`);
			fileName = fileName + `.csv`;
		}
		return this.downloadService.downloadFromUrl(endpoint, {
			downloadedFileName: fileName,
			headers: DefaultApiVersion,
			params: queryParams,
		});
	}
}
