var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, EventEmitter, OnInit } from '@angular/core';
import { AppConfigService } from '@wcd/app-config';
import { map } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { OnboardingService } from '@wcd/onboarding';
var SAW_GEO_PANEL = 'MtpOnboarding-SawGeoPanel';
var CLICKED_START = 'MtpOnboarding-ClickedStartOnGeoPanel';
var CLICKED_CANCEL = 'MtpOnboarding-ClickedCancelOnGeoPanel';
var GeoSelectionComponent = /** @class */ (function (_super) {
    __extends(GeoSelectionComponent, _super);
    function GeoSelectionComponent(appConfigService, i18nService, router, onboardingService, changeDetectorRef) {
        var _this = _super.call(this, router) || this;
        _this.appConfigService = appConfigService;
        _this.i18nService = i18nService;
        _this.onboardingService = onboardingService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.onGeoSelected = new EventEmitter();
        return _this;
    }
    GeoSelectionComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.onboardingService.trackEvent(SAW_GEO_PANEL);
        this.values = map(this.appConfigService.supportedGeoRegions, function (geo) {
            return ({
                id: geo,
                name: _this.i18nService.get('endpointManagement.supportedGeoRegions.' + geo),
            });
        });
    };
    GeoSelectionComponent.prototype.onChange = function () {
        // Temporary hack to allow change detection before migration to React
        this.changeDetectorRef.detectChanges();
    };
    GeoSelectionComponent.prototype.onClose = function (geo) {
        if (!!geo) {
            this.onboardingService.trackEvent(CLICKED_START, geo.name);
        }
        else {
            this.onboardingService.trackEvent(CLICKED_CANCEL);
        }
        this.onGeoSelected.emit(geo);
        this.closePanel();
        this.changeDetectorRef.detectChanges();
    };
    return GeoSelectionComponent;
}(PanelContainer));
export { GeoSelectionComponent };
