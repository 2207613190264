import { SevilleModule } from '../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { FilesService } from '../../../@entities/files/services/files.service';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { IpsService } from '../../../@entities/ips/services/ips.service';

declare var angular: angular.IAngularStatic;

SevilleModule.directive('deepAnalysisSummaryId', deepAnalysisSummaryIdDirective);

deepAnalysisSummaryIdDirective.$inject = [
	'appConfig',
	'authenticationService',
	'filesService',
	'machinesService',
	'ipsService',
];

function deepAnalysisSummaryIdDirective(
	appConfig: AppConfigService,
	authenticationService,
	filesService: FilesService,
	machinesService: MachinesService,
	ipsService: IpsService
) {
	return {
		restrict: 'EA',
		scope: {
			sha1: '=',
		},
		template: `
		<div class="row border-default deepanalysis-summary-container" ng-if="summary" data-track-component="SevilleDeepAnalysisSummary">
        <div class="col-xs-12">
            <!--Title-->
            <div class="row pointer noselect" ng-click="expandResult()">
                <div class="row-same-height">
                    <div class="col-xs-5 col-xs-height col-middle">
                        <i class="icon deepanalysis-summary-title-icon icon-SiteScan"></i>
                        <b>Deep analysis summary</b>
                        <span> (latest available result: <span time-ago utc="{{summary.EndTime}}"></span>)</span>
                    </div>
                    <div class="col-xs-offset-6 col-xs-1 text-right deepanalysis-summary-expand-icon-container col-xs-height col-middle noselect">
                        <i class="icon deepanalysis-summary-expand-icon icon-AddTo" ng-if="!showresult"></i>
                        <i class="icon deepanalysis-summary-expand-icon icon-RemoveFrom" ng-if="showresult"></i>
                    </div>
                </div>
            </div>

            <!-- show detonated file is corrupted message -->
            <div ng-if="showresult && summary.AnalysisSubjectIsCorrupted" class="deepanalysis-summary-file-corrupted">
                <span>File was analyzed and identified as corrupted</span>
            </div>

            <!-- show detonation result summary -->
            <div ng-if="showresult && !summary.AnalysisSubjectIsCorrupted">
                <div class="deepanalysis-summary-result-title">
                    <b>Behaviors</b>
                </div>

                <!-- If no capabilities were identified, show empty message -->
                <div ng-if="summary.Capabilities.length == 0">
                    <span>No behaviors were observed</span>
                </div>

                <!-- Capabilities -->
                <div class="row" ng-repeat="capability in summary.Capabilities">
                    <div class="col-xs-12">
                        <table class="deepanalysis-summary-table-container deepanalysis-summary-content-lighter pointer" ng-class="{'deepanalysis-summary-table-container-separated': !$first}" ng-click="capability.expanded = !capability.expanded">
                            <tr>
                                <td class="deepanalysis-summary-table-icon-container noselect">
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronDownBold" ng-if="!capability.expanded"></i>
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronUpBold" ng-if="capability.expanded"></i>
                                </td>
                                <td class="deepanalysis-summary-table-container">
                                    <span class="deepanalysis-summary-title"><b>{{capability.Name}}</b></span>
                                </td>
                            </tr>
                        </table>

                        <!-- Behaviors -->
                        <div>
                            <div class="row deepanalysis-summary-behavior" ng-if="capability.expanded" ng-repeat="behavior in capability.Behaviors">
                                <div class="col-xs-12">
                                    <table class="pointer deepanalysis-summary-table-container deepanalysis-summary-content-lighter" ng-click="behavior.expanded = !behavior.expanded">
                                        <tr>
                                            <td class="deepanalysis-summary-table-icon-container noselect">
                                                <i class="icon deepanalysis-summary-icon icon-ScrollChevronDownBold" ng-if="!behavior.expanded"></i>
                                                <i class="icon deepanalysis-summary-icon icon-ScrollChevronUpBold" ng-if="behavior.expanded"></i>
                                            </td>
                                            <td class="deepanalysis-summary-table-container">
                                                <span class="deepanalysis-summary-title">
                                                    {{behavior.Name}}
                                                    <span class="deepanalysis-summary-table-icon-container noselect">
                                                        <i class="icon deepanalysis-summary-tooltip-icon icon-WhatsThis" ng-if="behavior.Description != behavior.Name" tooltips tooltip-size="large" tooltip-title="{{behavior.Description}}" tooltip-side="right"></i>
                                                    </span>
                                                </span>
                                            </td>
                                        </tr>
                                    </table>

                                    <!-- Events -->
                                    <div class="row deepanalysis-summary-details-header" ng-if="behavior.expanded">
                                        <span class="col-xs-1 deepanalysis-summary-details-cell">Time</span>
                                        <span class="col-xs-2 deepanalysis-summary-details-cell">Process [PID]</span>
                                        <span class="col-xs-1 deepanalysis-summary-details-cell">Operation</span>
                                        <span class="col-xs-5 deepanalysis-summary-details-cell">Target</span>
                                        <span class="col-xs-2 deepanalysis-summary-details-cell">Details</span>
                                        <span class="col-xs-1 deepanalysis-summary-details-cell">Result</span>
                                    </div>
                                    <div class="row nomargin deepanalysis-summary-details-row" ng-if="behavior.expanded" ng-repeat="event in behavior.Events">
                                        <div class="col-xs-1 deepanalysis-summary-details-cell">
                                            <date class="deepanalysis-summary-time" src="event.Time" type="time"></date>
                                        </div>
                                        <div class="col-xs-2 deepanalysis-summary-details-cell overflow-all">{{event.ProcessName}} [{{event.ProcessId}}]</div>
                                        <div class="col-xs-1 deepanalysis-summary-details-cell overflow-all">{{event.Name}}</div>
                                        <div class="col-xs-5 deepanalysis-summary-details-cell overflow-all">{{event.Target}}</div>
                                        <div class="col-xs-2 deepanalysis-summary-details-cell overflow-all">{{event.Details}}</div>
                                        <div class="col-xs-1 deepanalysis-summary-details-cell overflow-all">{{event.Result}}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="deepanalysis-summary-result-title">
                    <b>Observables</b>
                </div>

                <!-- If no dropped files or contacted ip's, show empty message -->
                <div ng-if="summary.DroppedFiles.length == 0 && summary.ContactedIps.length == 0">
                    <span>No dropped files or network communications were observed</span>
                </div>

                <!-- Dropped files -->
                <div class="row" ng-if="summary.DroppedFiles.length > 0">
                    <div class="col-xs-12">
                        <table class="deepanalysis-summary-table-container deepanalysis-summary-content-lighter pointer" ng-click="expandDroppedFiles()">
                            <tr>
                                <td class="deepanalysis-summary-table-icon-container noselect">
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronDownBold" ng-if="!summary.DroppedFiles.expanded"></i>
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronUpBold" ng-if="summary.DroppedFiles.expanded"></i>
                                </td>
                                <td class="deepanalysis-summary-table-container">
                                    <span class="deepanalysis-summary-title"><b>Dropped files [{{summary.DroppedFiles.length}}]</b></span>
                                </td>
                            </tr>
                        </table>
                        <div class="row deepanalysis-summary-details-header" ng-if="summary.DroppedFiles.expanded">
                            <span class="col-xs-4 deepanalysis-summary-details-cell">File</span>
                            <span class="col-xs-3 deepanalysis-summary-details-cell">Details</span>
                            <span class="col-xs-2 deepanalysis-summary-details-cell">Detection</span>
                            <span class="col-xs-3 deepanalysis-summary-details-cell">
                                Last seen in Org
                                <i class="icon deepanalysis-summary-small-tooltip-icon icon-WhatsThis" tooltips tooltip-size="small" tooltip-title="The machine where the file was last observed in the last 30 days. Click on the file link to observe the full list of machines." tooltip-side="left"></i>
                            </span>
                        </div>
                        <div id="droppedFileLoadingLayer" class="deepanalysis-summary-loading-layer" ng-if="summary.DroppedFiles.expanded && summary.DroppedFiles.loading">
                            <div class="deepanalysis-summary-loading-layer-dummy"></div>
                            <i class="icon icon-Sync icon-lg spin deepanalysis-summary-loading-icon"></i>
                        </div>
                        <!-- file details -->
                        <div id="droppedFileDetails">
                            <div class="row nomargin deepanalysis-summary-details-row" ng-if="summary.DroppedFiles.expanded" ng-repeat="droppedFile in summary.DroppedFiles">
                                <div class="col-xs-12 nopadding hover-default pointer" ng-class="{'deepanalysis-summary-dropped-files-container': !$first}" ng-click="droppedFile.expanded = !droppedFile.expanded">
                                    <div class="row nomargin">
                                        <div class="col-xs-4 deepanalysis-summary-details-cell overflow-all">
                                            <a ng-if="droppedFile.Sha1 && droppedFile.Sha1.toLowerCase() != sha1.toLowerCase()"
                                            	data-track-id="GoToFile"
												data-track-type="Navigation"
												href="{{::getFileLink(droppedFile.Sha1.toLowerCase(), droppedFile.Name)}}"
                                            	stop-propagation>{{droppedFile.Name}}</a>
                                            <span ng-if="!droppedFile.Sha1 || (droppedFile.Sha1 && droppedFile.Sha1.toLowerCase() == sha1.toLowerCase())">{{droppedFile.Name}}</span>
                                            <i ng-if="droppedFile.Sha1 && droppedFile.Sha1.toLowerCase() == sha1.toLowerCase()" class="icon deepanalysis-summary-small-tooltip-icon icon-Info" tooltips tooltip-size="small" tooltip-title="This is the original file in the current page that was submitted to deep analysis" tooltip-side="right"></i>
                                        </div>
                                        <div class="col-xs-3 deepanalysis-summary-details-cell overflow-all">
                                            <a ng-if="droppedFile.Sha1 && droppedFile.Sha1.toLowerCase() != sha1.toLowerCase()"
                                            	data-track-id="GoToFileFromDetails"
												data-track-type="Navigation"
												href="{{::getFileLink(droppedFile.Sha1.toLowerCase())}}" stop-propagation>{{droppedFile.Sha1 | lowercase}}</a>
                                            <span ng-if="droppedFile.Sha1 && droppedFile.Sha1 && droppedFile.Sha1.toLowerCase() == sha1.toLowerCase()">{{droppedFile.Sha1 | lowercase}}</span>
                                            <span ng-if="!droppedFile.Sha1">Hash could not be retrieved</span>
                                        </div>
                                        <div class="col-xs-2 deepanalysis-summary-details-cell overflow-all">
                                            <span>
                                                <i class="icon deepanalysis-summary-details-icon icon-Bug" ng-if="droppedFile.ThreatName && droppedFile.ThreatName.length > 0"></i>
                                                {{droppedFile.ThreatName}}
                                            </span>
                                        </div>
                                        <div class="col-xs-3 deepanalysis-summary-details-cell overflow-all">
                                            <span ng-if="summary.DroppedFiles.loaded && (!droppedFile.MachineName || !droppedFile.LastSeen)">&#8213;</span>
                                            <span ng-if="droppedFile.MachineName && droppedFile.LastSeen">
												<a href="{{::getMachineLink(droppedFile.MachineId)}}"
                                                	data-track-id="GoToMachine"
	   												data-track-type="Navigation"
                                                	stop-propagation>{{droppedFile.MachineName | parts:1}}</a> (<span time-ago utc="{{droppedFile.LastSeen}}"></span>)
                                            </span>
                                            <span class="text-right pointer">
                                                <i class="icon deepanalysis-summary-dropped-file-details icon-ScrollChevronDownBold" ng-if="!droppedFile.expanded"></i>
                                                <i class="icon deepanalysis-summary-dropped-file-details icon-ScrollChevronUpBold" ng-if="droppedFile.expanded"></i>
                                            </span>
                                        </div>
                                    </div>
                                    <!-- Dropped file details -->
                                    <div class="row nomargin" ng-if="droppedFile.expanded">
                                        <div class="col-xs-4 deepanalysis-summary-details-cell overflow-all">
                                            <div class="row nomargin" ng-repeat="droppedFilePath in droppedFile.Paths">
                                                <div class="col-xs-12 nopadding">
                                                    <i class="icon deepanalysis-summary-details-icon flip-vertical icon-ExitRight"></i>
                                                    <span>{{droppedFilePath}}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xs-3 deepanalysis-summary-details-cell overflow-all"></div>
                                        <div class="col-xs-2 deepanalysis-summary-details-cell overflow-all"></div>
                                        <div class="col-xs-3 deepanalysis-summary-details-cell overflow-all"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Contacted IPs -->
                <div class="row" ng-if="summary.ContactedIps.length > 0">
                    <div class="col-xs-12">
                        <table class="deepanalysis-summary-table-container deepanalysis-summary-table-container-separated deepanalysis-summary-content-lighter pointer" ng-click="summary.ContactedIps.expanded = !summary.ContactedIps.expanded ">
                            <tr>
                                <td class="deepanalysis-summary-table-icon-container noselect">
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronDownBold" ng-if="!summary.ContactedIps.expanded"></i>
                                    <i class="icon deepanalysis-summary-icon icon-ScrollChevronUpBold" ng-if="summary.ContactedIps.expanded"></i>
                                </td>
                                <td class="deepanalysis-summary-table-container">
                                    <span class="deepanalysis-summary-title"><b>Contacted IPs [{{summary.ContactedIps.length}}]</b></span>
                                </td>
                            </tr>
                        </table>

                        <!-- IP details -->
                        <div class="row nomargin deepanalysis-summary-details-row" ng-if="summary.ContactedIps.expanded" ng-repeat="contactedIp in summary.ContactedIps">
                            <div class="col-xs-12 deepanalysis-summary-details-cell" ng-if="contactedIp">
                                <a data-track-id="GoToIp"
									data-track-type="Navigation"
									href="{{::getIpLink(contactedIp)}}"
									stop-propagation>{{contactedIp}}</a>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Details download-->
                <div class="row" ng-if="summary.EnableRawResultsDownload">
                    <div class="col-xs-12 deepanalysis-summary-result-title text-right">
                        <button class="deepanalysis-request-submit-btn" ng-click="getRawEvents()">Download raw events</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
		`,
		controller: [
			'$scope',
			'$http',
			'$log',
			'$rootScope',
			function($scope, $http, $log, $rootScope) {
				$scope.alertsInvestigationAllowed = !authenticationService.isUserActionAllowed([
					RbacMdeAllowedActions.alertsInvestigation,
				]);

				function getDeepAnalysisSummary() {
					if ($scope.sha1 == null || $scope.sha1.length <= 0) {
						$log.debug('No valid sha1, skipping loading deep analysis summary');
						return;
					}

					var url = appConfig.serviceUrls.deepAnalysis + '/api/DeepAnalysisSummary';
					$log.debug('Loading deep analysis summary, for sha1=' + $scope.sha1);
					$http
						.get(url, {
							params: {
								sha1: $scope.sha1,
							},
						})
						.then(
							function(response) {
								if (response.status === 200) {
									$scope.summary = response.data;
									// If the result is expanded, expand the capabilities as well
									if ($scope.showresult) {
										$scope.expandCapabilities();
									}
									$log.debug(
										'deep analysis summary, for sha1=' +
											$scope.sha1 +
											' loaded successfully'
									);
								}
							},
							function(response) {
								// Set an error status for getting deep analysis request status
								delete $scope.summary;
								$log.error(
									'Error occur while loading deep analysis summary for sha1=' + $scope.sha1
								);
							}
						);
				}

				getDeepAnalysisSummary();

				$rootScope.$on('deepanalysis.request.completed', function() {
					$log.debug('received request completed event, refreshing summary');
					getDeepAnalysisSummary();
				});

				$scope.expandResult = function() {
					$scope.showresult = !$scope.showresult;
					if (!$scope.firstExpanded) {
						$scope.expandCapabilities();
					}
				};

				$scope.getFileLink = (fileId, fileName?) => filesService.getFileLink(fileId, null, fileName);

				$scope.getMachineLink = machineId => machinesService.getMachineLink(machineId);

				$scope.getIpLink = ip => ipsService.getIpLink(ip);

				$scope.expandCapabilities = function() {
					// For the first time when the result is expanded, expand all the capabilities as well
					if ($scope.summary.Capabilities.length > 0) {
						$scope.summary.Capabilities.forEach(function(element) {
							element.expanded = true;
						});
						$scope.firstExpanded = true;
					}
				};

				$scope.getRawEvents = function() {
					var url = appConfig.serviceUrls.deepAnalysis + '/api/DeepAnalysisRawEvents';
					$log.debug('Loading deep analysis result raw events, for sha1=' + $scope.sha1);

					$http
						.get(url, {
							params: {
								sha1: $scope.sha1,
							},
						})
						.then(
							function(response) {
								if (response.status === 200) {
									var data = response.data;
									if (data && data.ResultUri) {
										var a = document.createElement('a');
										a.href = data.ResultUri;
										a.download = 'true';
										a.style.visibility = 'hidden';
										document.body.appendChild(a);
										a.click();
										document.body.removeChild(a);
										$log.debug(
											'deep analysis result raw events, for sha1=' +
												$scope.sha1 +
												' downloaded successfully'
										);
									}
								}
							},
							function(response) {
								// Set an error status for getting deep analysis result raw events
								$log.error(
									'Error occur while downloading deep analysis result raw events for sha1=' +
										$scope.sha1
								);
							}
						);
				};

				$scope.$watch(
					function() {
						return angular.element('#droppedFileDetails').height();
					},
					function(newValue, oldValue) {
						if (newValue != oldValue) {
							if ($scope.summary.DroppedFiles.loading) {
								angular.element('#droppedFileLoadingLayer').height(newValue);
							}
						}
					}
				);

				$scope.expandDroppedFiles = function() {
					if (!$scope.summary.DroppedFiles.loading) {
						$scope.summary.DroppedFiles.expanded = !$scope.summary.DroppedFiles.expanded;
					}

					if ($scope.summary.DroppedFiles.length > 0 && !$scope.summary.DroppedFiles.loaded) {
						$scope.summary.DroppedFiles.loading = true;

						var sha1List = [];
						$scope.summary.DroppedFiles.forEach(function(file) {
							if (file.Sha1) {
								sha1List.push(file.Sha1);
							}
						});

						if (sha1List.length == 0) {
							$scope.summary.DroppedFiles.loaded = true;
							$scope.summary.DroppedFiles.loading = false;
							return;
						}

						var sha1s = sha1List.join(',');

						var url = appConfig.serviceUrls.deepAnalysis + '/api/ObservedFilesStats';
						$log.debug('Loading files stats, for sha1s=' + sha1List);
						$http
							.post(url, sha1List)
							.then(
								function(response) {
									if (response.status === 200) {
										$log.debug('files stats loaded successfully for sha1s=' + sha1s);

										// If we have any response data let's update the dropped files data
										if (response.data && response.data.length > 0) {
											for (var i = 0; i < response.data.length; i++) {
												var fileStats = response.data[i];
												for (var j = 0; j < $scope.summary.DroppedFiles.length; j++) {
													var droppedFile = $scope.summary.DroppedFiles[j];

													// Update the file stats if sha1 matches
													if (
														droppedFile.Sha1 &&
														droppedFile.Sha1.toLowerCase() ==
															fileStats.Sha1.toLowerCase()
													) {
														droppedFile.LastSeen = fileStats.LastSeen;
														droppedFile.MachineName = fileStats.MachineName;
														droppedFile.MachineId = fileStats.MachineId;
														break;
													}
												}
											}
										}
									} else {
										$log.error('Error occur while loading files stats for sha1=' + sha1s);
									}
								},
								function() {
									$log.error('Error occur while loading files stats for sha1=' + sha1s);
								}
							)
							.finally(function() {
								$scope.summary.DroppedFiles.loaded = true;
								$scope.summary.DroppedFiles.loading = false;
							});
					}
				};
			},
		],
	};
}
