import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	readonly: true,
	singularName: 'Logs collection download',
	pluralName: '',
})
export class MachineRequestLogsDownload extends ModelBase {
	@EntityField({ data: '__self' })
	readonly url: string;
}
