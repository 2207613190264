export const enum MailboxConfigurationEntityAlertV3Fields {
	ThreatAnalysisSummary = 'ThreatAnalysisSummary',
	ConfigType = 'ConfigType',
	MailboxPrimaryAddress = 'MailboxPrimaryAddress',
	Upn = 'Upn',
	ForwardingSmtpAddress = 'ForwardingSmtpAddress', // TODO: should be added to AlertV3 schema in the future
	DeliverToMailboxAndForward = 'DeliverToMailboxAndForward', // TODO: should be added to AlertV3 schema in the future
	PerformedBy = 'PerformedBy', // TODO: should be added to AlertV3 schema in the future
	DelegatedToUser = 'DelegatedToUser', // TODO: should be added to AlertV3 schema in the future
	AccessRights = 'AccessRights', // TODO: should be added to AlertV3 schema in the future
	AccessToMailbox = 'AccessToMailbox', // TODO: should be added to AlertV3 schema in the future
}

function parseNameFromUserString(userString: string): string {
	if (!userString) return '';
	const startIndex = userString.lastIndexOf('/', userString.lastIndexOf('/') - 1) + 1;
	return userString.substring(startIndex);
}

export function mapMailboxConfigurationEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case MailboxConfigurationEntityAlertV3Fields.ForwardingSmtpAddress:
					acc['forwarding_smtp_address'] = val;
					break;
				case MailboxConfigurationEntityAlertV3Fields.DeliverToMailboxAndForward:
					acc['deliver_to_mailbox_and_forward'] = val;
					break;
				case MailboxConfigurationEntityAlertV3Fields.PerformedBy:
					acc['performed_by'] = val;
					break;
				case MailboxConfigurationEntityAlertV3Fields.DelegatedToUser:
					acc['delegated_to_user'] = parseNameFromUserString(<string>val);
					break;
				case MailboxConfigurationEntityAlertV3Fields.AccessRights:
					acc['access_rights'] = val;
					break;
				case MailboxConfigurationEntityAlertV3Fields.AccessToMailbox:
					acc['access_to_mailbox'] = parseNameFromUserString(<string>val);
					break;
				case MailboxConfigurationEntityAlertV3Fields.ConfigType:
					acc['config_type'] = val;
					break;
				case MailboxConfigurationEntityAlertV3Fields.MailboxPrimaryAddress:
					acc['mailbox_primary_address'] = val;
					acc[key] = val; // need to save 'MailboxPrimaryAddress' in order to calculate the display_name
					break;
				case MailboxConfigurationEntityAlertV3Fields.Upn:
					acc['upn'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
