import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

export type ExploitType = 'WebApps' | 'Remote' | 'Local' | 'Dos';

@ValueObject({
	singularName: 'ThreatInfo',
	pluralName: 'ThreatInfo',
})
export class ThreatInfo extends ModelBase {
	@EntityField() readonly insights?: string[];

	@EntityField() readonly hasExploit?: boolean;

	@EntityField() readonly isThreatActive?: boolean;

	@EntityField() readonly isExploitVerified?: boolean;

	@EntityField() readonly isInExploitKit?: boolean;

	@EntityField() readonly exploitTypes?: (ExploitType)[];

	@EntityField() readonly exploitUris?: string[];

	@EntityField({
		data: 'isLinkedToThreat',
	})
	readonly isExploitLinkedToThreat?: boolean;
}
