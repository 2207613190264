var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ChangeDetectorRef, OnInit, OnDestroy, } from '@angular/core';
import { BaselineProfileDetails } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { filter, map } from 'rxjs/operators';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { EntityDetailsComponentBase } from '../../../../../global_entities/components/entity-details/entity-details.component.base';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
var BaselineProfileEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(BaselineProfileEntityDetailsComponent, _super);
    function BaselineProfileEntityDetailsComponent(cd, i18nService, activatedEntity, rbacService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.cd = cd;
        _this.i18nService = i18nService;
        _this.activatedEntity = activatedEntity;
        _this.rbacService = rbacService;
        return _this;
    }
    BaselineProfileEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.profile) {
            this._currentEntitySubscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof BaselineProfileDetails; }))
                .subscribe(function (profile) {
                _this.profile = profile;
                if (!_this.rbacGroupNames) {
                    _this._userExposedRbacGroupsSubscription = _this.rbacService.userExposedRbacGroups$.pipe(map(function (rbacGroups) {
                        var machineGroupNameById = rbacGroups.reduce(function (map, currMachineGroup) {
                            map[currMachineGroup.id] = currMachineGroup.name;
                            return map;
                        }, {});
                        return machineGroupNameById;
                    })).subscribe(function (machineGroupNameById) {
                        _this.rbacGroupNames = _this.profile.rbacGroupIds.filter(function (id) { return machineGroupNameById[id]; }).map(function (id) { return machineGroupNameById[id]; }).join(', ');
                        _this.cd.markForCheck();
                    });
                }
            });
        }
        this.osPlatform = this.profile.operatingSystem + " " + this.profile.operatingSystemVersion;
        this.deviceTags = this.profile.deviceTags.join(', ');
    };
    BaselineProfileEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._currentEntitySubscription && this._currentEntitySubscription.unsubscribe();
        this._userExposedRbacGroupsSubscription && this._userExposedRbacGroupsSubscription.unsubscribe();
    };
    return BaselineProfileEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { BaselineProfileEntityDetailsComponent };
