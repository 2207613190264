var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var VENDOR_LOGOS_FOLDER = 'assets/images/detection_systems';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; };
var ServiceTemplate = /** @class */ (function (_super) {
    __extends(ServiceTemplate, _super);
    function ServiceTemplate(data) {
        var _this = _super.call(this, data) || this;
        _this.logoUrl =
            _this.name.toLowerCase() === 'other'
                ? null
                : VENDOR_LOGOS_FOLDER + "/" + _this.vendor.toLowerCase() + ".png";
        return _this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ServiceTemplate.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ServiceTemplate.prototype, "vendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ServiceTemplate.prototype, "product", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], ServiceTemplate.prototype, "categories", void 0);
    ServiceTemplate = __decorate([
        Entity({
            singularName: 'Service Template',
            pluralName: 'Service Templates',
            endpoint: 'services/templates',
            loadAll: true,
            allItemsProperty: 'results',
            allItemsEndpoint: '/all',
            baseUrl: ɵ0,
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], ServiceTemplate);
    return ServiceTemplate;
}(EntityModelBase));
export { ServiceTemplate };
export { ɵ0 };
