/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tagged-field.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "../report-inaccuracy/tvm-report-inaccuracy-dropdown.component.ngfactory";
import * as i9 from "../report-inaccuracy/tvm-report-inaccuracy-dropdown.component";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "../../services/tvm-report-inaccuracy.service";
import * as i12 from "./tagged-field.component";
import * as i13 from "../../../insights/services/app-insights.service";
var styles_TaggedFieldComponent = [i0.styles];
var RenderType_TaggedFieldComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_TaggedFieldComponent, data: {} });
export { RenderType_TaggedFieldComponent as RenderType_TaggedFieldComponent };
function View_TaggedFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_TaggedFieldComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "field-value-button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onClick(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_2)), i1.ɵdid(3, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 3); _ck(_v, 3, 0, currVal_0); }, null); }
function View_TaggedFieldComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "wcd-flex-horizontal tag wcd-margin-small-right color-box-dark-medium-opacity"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(2, null, ["\n\t\t\t\t", "\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tooltip; _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tagText; _ck(_v, 2, 0, currVal_1); }); }
function View_TaggedFieldComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "fab-icon", [["iconName", "UserGauge"], ["wcdTooltip", ""]], null, [[null, "onDisplay"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("onDisplay" === en)) {
        var pd_4 = (_co.onTooltipDisplay() !== false);
        ad = (pd_4 && ad);
    } return ad; }, i6.View_FabIconComponent_0, i6.RenderType_FabIconComponent)), i1.ɵdid(1, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipAllowHtmlRendering: [1, "wcdTooltipAllowHtmlRendering"] }, { onDisplay: "onDisplay" }), i1.ɵdid(2, 5816320, null, 0, i7.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.productivityAssessmentInfo.productivityAssessmentTooltip; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = ((_co.productivityAssessmentInfo.iconColoring.iconColorClass + " tvm-productivity-assessment-icon ") + _co.productivityAssessmentInfo.iconColoring.backgroundColorClass); var currVal_3 = "UserGauge"; _ck(_v, 2, 0, currVal_2, currVal_3); }, null); }
function View_TaggedFieldComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-flex-1 wcd-flex-horizontal-align-items-center wcd-text-overflow-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_5)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_6)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(7, null, ["\n\t\t\t\t", "\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTag; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.productivityAssessmentInfo.isProductivityAssessmentIncluded; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.title; _ck(_v, 7, 0, currVal_2); }); }
function View_TaggedFieldComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [], [[8, "className", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 6).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 6).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 6).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(7, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.tooltip; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.tagColorClass + " tvm-tag-icon wcd-margin-small-left ") + _co.tagBackgroundColorClass); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.tagColorClass + " wcd-padding-small-left"); _ck(_v, 5, 0, currVal_1); var currVal_3 = _co.tagText; _ck(_v, 7, 0, currVal_3); }); }
function View_TaggedFieldComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-flex-1 wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "div", [["class", "wcd-text-overflow-ellipsis"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_8)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showTag; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 4, 0, currVal_0); }); }
function View_TaggedFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["afterTitle", 2]], null, 0, null, View_TaggedFieldComponent_7)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "tvm-report-inaccuracy-dropdown", [], null, null, null, i8.View_TvmReportInaccuracyDropdownComponent_0, i8.RenderType_TvmReportInaccuracyDropdownComponent)), i1.ɵdid(9, 49152, null, 0, i9.TvmReportInaccuracyDropdownComponent, [i10.I18nService, i11.TvmReportInaccuracyService], { contextObject: [0, "contextObject"], inaccuracyContext: [1, "inaccuracyContext"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.tagAfterText; var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.contextObject; var currVal_3 = _co.inaccuracyContext; _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_TaggedFieldComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_TaggedFieldComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n"])), (_l()(), i1.ɵand(0, [["taggedField", 2]], null, 0, null, View_TaggedFieldComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.onClick; var currVal_1 = i1.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TaggedFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tagged-field", [], null, null, null, View_TaggedFieldComponent_0, RenderType_TaggedFieldComponent)), i1.ɵdid(1, 49152, null, 0, i12.TaggedFieldComponent, [i13.AppInsightsService], null, null)], null, null); }
var TaggedFieldComponentNgFactory = i1.ɵccf("tagged-field", i12.TaggedFieldComponent, View_TaggedFieldComponent_Host_0, { inaccuracyContext: "inaccuracyContext", contextObject: "contextObject", title: "title", showTag: "showTag", tagAfterText: "tagAfterText", tagText: "tagText", tooltip: "tooltip", tagColorClass: "tagColorClass", tagBackgroundColorClass: "tagBackgroundColorClass", onClick: "onClick", value: "value", productivityAssessmentInfo: "productivityAssessmentInfo" }, {}, []);
export { TaggedFieldComponentNgFactory as TaggedFieldComponentNgFactory };
