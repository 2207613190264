import { ElementRef, AfterContentInit, OnDestroy, EventEmitter, } from '@angular/core';
import { Ie11Service } from '../services/ie11.service';
export var KeyboardNavigableElementAddedEventName = 'keyboard-navigable-element.added';
export var KeyboardNavigableElementRemoveEventName = 'keyboard-navigable-element.removed';
export var KeyboardNavigableElementSelectedExternallyEventName = 'keyboard-navigable-element.selected';
/*
    This directive should be used along with KeyboardNavigableContainer in order to enable selection of element
    (focus on elements) using arrow keys
 */
var KeyboardNavigableElementDirective = /** @class */ (function () {
    function KeyboardNavigableElementDirective(elementRef, ie11Service) {
        var _this = this;
        this.elementRef = elementRef;
        this.ie11Service = ie11Service;
        this.elementVisible = true;
        this.onTabIndexChange = new EventEmitter();
        this.notifyFocus = function () { return _this.fireEvent(KeyboardNavigableElementSelectedExternallyEventName); };
        this.nativeElement = elementRef.nativeElement;
        this.isIe11 = this.ie11Service.isIe11;
    }
    // FocusableOption interface
    KeyboardNavigableElementDirective.prototype.focus = function (_origin) {
        this.tabindex = 0 /* Stoppable */;
        if (_origin !== "program")
            this.nativeElement.focus();
    };
    KeyboardNavigableElementDirective.prototype.getLabel = function () {
        return this.nativeElement.innerText;
    };
    Object.defineProperty(KeyboardNavigableElementDirective.prototype, "disabled", {
        get: function () {
            return this.nativeElement.getAttribute('disabled') === 'true';
        },
        set: function (value) {
            if (!value) {
                this.nativeElement.removeAttribute('disabled');
            }
            else {
                this.nativeElement.setAttribute('disabled', 'true');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeyboardNavigableElementDirective.prototype, "tabindex", {
        // end FocusableOption interface
        get: function () {
            var tabindex = this.nativeElement.getAttribute('tabindex');
            return parseInt(tabindex);
        },
        set: function (value) {
            this.nativeElement.setAttribute('tabindex', value.toString());
            if (this.onTabIndexChange) {
                this.onTabIndexChange.emit(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeyboardNavigableElementDirective.prototype, "visible", {
        get: function () {
            var element = this.nativeElement;
            return this.elementVisible && !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(KeyboardNavigableElementDirective.prototype, "isStopPoint", {
        get: function () {
            return this.tabindex >= 0 /* Stoppable */;
        },
        enumerable: true,
        configurable: true
    });
    KeyboardNavigableElementDirective.prototype.deactivate = function () {
        this.tabindex = -1 /* Skippable */;
    };
    KeyboardNavigableElementDirective.prototype.fireEvent = function (eventName) {
        if (this.isIe11) {
            this.nativeElement.dispatchEvent(this.ie11Service.createIe11CustomEvent(eventName, {
                detail: this,
                bubbles: true,
            }));
        }
        else {
            this.nativeElement.dispatchEvent(new CustomEvent(eventName, {
                detail: this,
                bubbles: true,
            }));
        }
    };
    KeyboardNavigableElementDirective.prototype.ngAfterContentInit = function () {
        var tabindex = this.tabindex;
        if (tabindex == undefined || isNaN(tabindex)) {
            this.nativeElement.setAttribute('tabindex', -1 /* Skippable */.toString());
        }
        this.nativeElement.addEventListener('focus', this.notifyFocus);
        this.fireEvent(KeyboardNavigableElementAddedEventName);
    };
    KeyboardNavigableElementDirective.prototype.ngOnDestroy = function () {
        this.nativeElement.removeEventListener('focus', this.notifyFocus);
        this.fireEvent(KeyboardNavigableElementRemoveEventName);
    };
    return KeyboardNavigableElementDirective;
}());
export { KeyboardNavigableElementDirective };
