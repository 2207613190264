import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { CyberEvent, CyberEventActionTypeName, Tag, TagType } from '@wcd/domain';
import { TagsService } from '../../../tags/services/tags.service';
import { FeaturesService, Feature } from '@wcd/config';

@Injectable()
export class CyberEventsTagsService {
	constructor(
		private i18nService: I18nService,
		private tagsService: TagsService,
		featuresService: FeaturesService
	) {
		this.isDataSensitivityEnabled = featuresService.isEnabled(Feature.FileSensitivity);
	}

	private readonly isDataSensitivityEnabled: boolean;

	getEventAdditionslTags(event: CyberEvent): Array<Tag> {
		if (!event || !event.actionType) return [];

		return [
			event.actionType.id === CyberEventActionTypeName.SmartScreenUserOverride
				? new Tag({
						id: 'smartScreenUserOverride',
						name: this.i18nService.get('events.behaviors.smartScreen.override'),
						type: TagType.failure,
				  })
				: undefined,
			event.additionalFields && event.additionalFields.wasRemediated != null
				? new Tag({
						id: `wasRemediated_${event.additionalFields.wasRemediated}`,
						name: this.i18nService.get(
							`events.behaviors.remediation.${
								event.additionalFields.wasRemediated ? 'success' : 'failure'
							}`
						),
						type: event.additionalFields.wasRemediated ? TagType.success : TagType.failure,
				  })
				: undefined,
			event.containerId
				? new Tag({
						id: 'containedInAppGuard',
						name: this.i18nService.get('events.behaviors.appGuard.contained'),
						type: TagType.behavior,
				  })
				: undefined,
			event.additionalFields && event.additionalFields.wasExecutingWhileDetected
				? new Tag({
						id: 'wasExecutingWhileDetected',
						name: this.i18nService.get('events.behaviors.execution.executedDuringDetection'),
						type: TagType.behavior,
				  })
				: undefined,
			this.isDataSensitivityEnabled && event.file && event.file.sensitivity
				? this.tagsService.getDataSensitivityTag(
						event.file.sensitivity,
						this.i18nService.get('files.sensitivity.tag.tooltip'),
						false
				  )
				: undefined,
			event.actionType.id === CyberEventActionTypeName.AmsiScriptDetection
				? new Tag({
						id: 'script',
						name: this.i18nService.get('events.behaviors.script'),
						type: TagType.behavior,
				  })
				: null,
		].filter(Boolean);
	}
}
