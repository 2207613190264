var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { ContentState } from '@wcd/contents-state';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { HuntingDocTable } from '@wcd/domain';
import { take } from 'rxjs/operators';
import { HuntingDocumentationService } from '../services/hunting-documentation.service';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
var HuntingDocumentationTableReferencePanelComponent = /** @class */ (function (_super) {
    __extends(HuntingDocumentationTableReferencePanelComponent, _super);
    function HuntingDocumentationTableReferencePanelComponent(paris, changeDetectorRef, huntingDocumentationService, appContextService, featuresService, router) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.huntingDocumentationService = huntingDocumentationService;
        _this.appContextService = appContextService;
        _this.featuresService = featuresService;
        _this.contentState = ContentState.Loading;
        return _this;
    }
    HuntingDocumentationTableReferencePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.paris
            .getItemById(HuntingDocTable, this.tableName, null, { migrateToVNext: this.featuresService.isEnabled(Feature.HuntingEndpointMigrationHuntingDocumentation) })
            .pipe(take(1))
            .subscribe(function (huntingDocTable) {
            _this.tableReference = huntingDocTable;
            _this.contentState = !_this.tableReference ? ContentState.Empty : ContentState.Complete;
            _this.changeDetectorRef.markForCheck();
        }, function (_) {
            _this.contentState = ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        });
    };
    HuntingDocumentationTableReferencePanelComponent.prototype.sampleQueryClick = function (queryText) {
        this.huntingDocumentationService.runSampleQueryClicked(queryText);
    };
    return HuntingDocumentationTableReferencePanelComponent;
}(PanelContainer));
export { HuntingDocumentationTableReferencePanelComponent };
