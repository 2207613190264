import { mapMailMessageEntityToAirsEntity } from './airs-entity.mail-message.converter';

export const enum SubmissionMailEntityAlertV3Fields {
	SubmissionDate = 'SubmissionDate',
	ReportType = 'ReportType',
	SubmissionId = 'SubmissionId',
	Submitter = 'Submitter',
	SenderIp = 'SenderIp',
}

export function mapSubmissionMailEntityToAirsEntity(entityData): any {
	const baseMailMessageData = mapMailMessageEntityToAirsEntity(entityData);

	const submissionMailData = Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case SubmissionMailEntityAlertV3Fields.SubmissionDate:
					acc['reported_on'] = val;
					break;
				case SubmissionMailEntityAlertV3Fields.ReportType:
					acc['report_type'] = val;
					break;
				case SubmissionMailEntityAlertV3Fields.SubmissionId:
					acc['submission_id'] = val;
					break;
				case SubmissionMailEntityAlertV3Fields.Submitter:
					acc['submitter'] = val;
					break;
				case SubmissionMailEntityAlertV3Fields.SenderIp:
					// note that in MailMessage entity it's 'SenderIP' and not 'SenderIp', hence, we need this case
					acc['sender_ip'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});

	return Object.assign(baseMailMessageData, submissionMailData);
}
