import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { FilterItem } from './filter-item.value.object';

@ValueObject({
	singularName: 'Incident Queue Filters',
	pluralName: '',
})
export class IncidentQueueFilters extends ModelBase {

	@EntityField({ data: 'SystemTag' })
	systemTag?: FilterItem;

	@EntityField({ data: 'CustomTag' })
	customTag?: FilterItem;

}


@ApiCall({
	name: 'Get dynamic filters of the unified incidents queue',
	endpoint: 'incidents/filters',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.incidentQueue,
	parseQuery: (filters: string[]) => ({ params: { name: filters } }),
	type: IncidentQueueFilters,
	cache: {
		time: 1000 * 60,
	},
})
export class GetIncidentQueueFiltersApiCall extends ApiCallModel<IncidentQueueFilters, string[]> {}

