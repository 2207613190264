var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, ModelBase } from '@microsoft/paris';
var ɵ0 = function () { return 'settings/v2/MagellanFeatures'; }, ɵ1 = function () { return 'settings/MagellanFeatures'; }, ɵ2 = function () { return 'PUT'; }, ɵ3 = function (config) { return config.data.serviceUrls.k8s; };
var MagellanFeatures = /** @class */ (function (_super) {
    __extends(MagellanFeatures, _super);
    function MagellanFeatures() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MagellanFeatures_1 = MagellanFeatures;
    Object.defineProperty(MagellanFeatures, "Empty", {
        get: function () {
            return {
                isProactiveDiscoveryEnabled: false,
                get isProactiveDiscoveryEmpty() {
                    return true;
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    MagellanFeatures.From = function (response) {
        if (!response) {
            return MagellanFeatures_1.Empty;
        }
        var features = new MagellanFeatures_1();
        features.isProactiveDiscoveryEnabled = response.IsProactiveDiscoveryEnabled;
        features.isCorelightIntegrationEnabled = response.IsCorelightIntegrationEnabled;
        features.isLog4jScanningEnabled = response.IsLog4jScanningEnabled;
        return features;
    };
    Object.defineProperty(MagellanFeatures.prototype, "isProactiveDiscoveryEmpty", {
        get: function () {
            return typeof this.isProactiveDiscoveryEnabled !== 'boolean';
        },
        enumerable: true,
        configurable: true
    });
    var MagellanFeatures_1;
    __decorate([
        EntityField({ data: 'IsProactiveDiscoveryEnabled' }),
        __metadata("design:type", Boolean)
    ], MagellanFeatures.prototype, "isProactiveDiscoveryEnabled", void 0);
    __decorate([
        EntityField({ data: 'IsCorelightIntegrationEnabled' }),
        __metadata("design:type", Boolean)
    ], MagellanFeatures.prototype, "isCorelightIntegrationEnabled", void 0);
    __decorate([
        EntityField({ data: 'IsLog4jScanningEnabled' }),
        __metadata("design:type", Boolean)
    ], MagellanFeatures.prototype, "isLog4jScanningEnabled", void 0);
    MagellanFeatures = MagellanFeatures_1 = __decorate([
        Entity({
            singularName: 'Proactive device discovery settings',
            pluralName: 'Proactive device discovery settings',
            endpoint: 'settings/MagellanFeatures',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            saveMethod: ɵ2,
            baseUrl: ɵ3,
        })
    ], MagellanFeatures);
    return MagellanFeatures;
}(ModelBase));
export { MagellanFeatures };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
