
		<div>
			<route-link
				[routeLink]="{
					routerLink: [
						machinesService.getMachineLink(
							machineId || alert.machine.machineId,
							false,
							actionTime
						)
					],
					queryParams: machinesService.getMachineWithAlertParams(
						alert || { lastEventTime: actionTime }
					)
				}"
			>
				<fab-icon iconName="TimelineProgress"></fab-icon>
				{{ 'machines.entityDetails.fields.name.linkText' | i18n }}
			</route-link>
		</div>
	