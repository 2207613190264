/* tslint:disable:template-accessibility-alt-text */
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

const DROPDOWN_OPEN_CLASS = 'dropdown-open';
const MENU_OPEN_CLASS = 'open';
const MARGIN = 20;

@Component({
	selector: 'dropdown',
	template: `
		<div class="dropdown-directive" [attr.aria-label]="ariaLabel">
			<button
				type="button"
				[attr.id]="buttonId"
				(click)="toggle()"
				[wcdTooltip]="buttonTooltip"
				class="dropdown-directive-button"
				[class.dropdown-directive-button-arrow]="!buttonIcon || showIconDropdown"
				[ngClass]="buttonClass"
				(focus)="focus.emit()"
				(blur)="blur.emit()"
				[attr.aria-label]="ariaLabel"
				[disabled]="disabled"
				[attr.role]="buttonRole ? buttonRole : null"
			>
				<img class="dropdown-directive-button-image" *ngIf="buttonImage" [attr.src]="buttonImage" />
				<wcd-shared-icon
					[iconName]="buttonIcon"
					*ngIf="buttonIcon"
					class="dropdown-directive-button-icon"
					[ngClass]="buttonIconClass"
				>
				</wcd-shared-icon>
				<span class="dropdown-directive-label">{{ buttonText }}</span>
				<wcd-shared-icon class="dropdown-icon" *ngIf="showIconDropdown" [iconName]="'carets.down'">
				</wcd-shared-icon>
				<ng-content select="[button-content]"></ng-content>
			</button>
			<span [ngClass]="'dropdown-directive-button-badge ' + buttonBadgeClass" *ngIf="buttonBadge">{{
				buttonBadge
			}}</span>
			<div
				class="dropdown-directive-menu"
				[ngClass]="menuClass"
				[cdkTrapFocus]="isOpen"
				tabindex="-1"
				(keydown.escape)="close()"
			>
				<ng-content></ng-content>
			</div>
		</div>
	`,
})
export class DropdownComponent implements OnInit, OnDestroy {
	@Input() buttonId: string;
	@Input() buttonText: string;
	@Input() buttonClass: string = '';
	@Input() buttonIcon: string;
	@Input() buttonIconClass: string;
	@Input() buttonImage: string;
	@Input() buttonBadge: string;
	@Input() buttonTooltip: string;
	@Input() buttonBadgeClass: string;
	@Input() disabled: boolean = false;
	@Input() closeOnClick: boolean;
	@Input() showIconDropdown: boolean = true;
	@Input() menuClass: string = '';
	@Input() ariaLabel: string;
	@Input() buttonRole?: string = '';

	@Output() focus: EventEmitter<void> = new EventEmitter<void>();
	@Output() blur: EventEmitter<void> = new EventEmitter<void>();

	dropdownEl: HTMLElement;
	menuEl: HTMLElement;
	buttonEl: HTMLElement;
	isOpen: boolean = false;
	boundMouseDown: (e: Event) => boolean;
	boundMouseUp: (e: Event) => boolean;
	boundOnMouseWheel: (e: Event) => any;

	constructor(private elementRef: ElementRef) {}

	ngOnInit() {
		this.dropdownEl = this.elementRef.nativeElement;
		this.menuEl = <HTMLElement>this.dropdownEl.querySelector('.dropdown-directive-menu');
		this.buttonEl = <HTMLElement>this.dropdownEl.querySelector('button');

		this.boundMouseDown = this.onMouseDown.bind(this);
		this.boundMouseUp = this.onMouseUp.bind(this);
		this.boundOnMouseWheel = this.onMouseWheel.bind(this);

		if (this.menuEl) {
			this.menuEl.addEventListener('mouseup', this.boundMouseUp);
			document.body.appendChild(this.menuEl);
		}
	}

	ngOnDestroy() {
		try {
			document.body.removeEventListener('mousedown', this.boundMouseDown);
			window.removeEventListener('mousewheel', this.boundOnMouseWheel);
			if (this.menuEl) {
				this.menuEl.removeEventListener('mouseup', this.boundMouseUp);
				document.body.removeChild(this.menuEl);
			}
			this.dropdownEl && document.body.removeChild(this.dropdownEl);
			this.buttonEl && document.body.removeChild(this.buttonEl);
		} catch (e) {
			console.error('failed to destroy dropdown');
		}
	}

	open() {
		this.menuEl.classList.add(MENU_OPEN_CLASS);
		this.dropdownEl.classList.add(DROPDOWN_OPEN_CLASS);
		setTimeout(this.setMenuPositionAndShow.bind(this));
		document.body.addEventListener('mousedown', this.boundMouseDown);
		window.addEventListener('mousewheel', this.boundOnMouseWheel);
		this.isOpen = true;
	}

	close(e?: Event): boolean {
		this.isOpen = false;
		this.menuEl.classList.remove('visible');
		const self = this;

		setTimeout(() => {
			self.menuEl.classList.remove(MENU_OPEN_CLASS);
			self.dropdownEl.classList.remove(DROPDOWN_OPEN_CLASS);
			self.menuEl.style.removeProperty('height');
			self.menuEl.style.removeProperty('width');
			this.buttonEl.focus();
		});

		document.body.removeEventListener('mousedown', this.boundMouseDown);
		window.removeEventListener('mousewheel', this.boundOnMouseWheel);

		return true;
	}

	toggle() {
		if (this.isOpen) this.close();
		else this.open();
	}

	onMouseWheel(e) {
		if (!e.target.closest('.dropdown-directive-menu')) this.close();
	}

	onMouseDown(e): boolean {
		let el = e.target;
		do {
			if (el === document.documentElement) return this.close();

			if (el === this.menuEl) return true;
		} while ((el = el.parentNode));

		return true;
	}

	onMouseUp(e): boolean {
		const el = e.target;
		if (
			el.nodeName === 'A' ||
			(el.nodeName === 'BUTTON' && !el.classList.contains('dropdown-action-btn')) ||
			this.closeOnClick !== false
		)
			return this.close();

		return true;
	}

	setMenuPositionAndShow() {
		const buttonRect = this.buttonEl.getBoundingClientRect();

		this.menuEl.style.top = buttonRect.bottom + 'px';
		this.menuEl.style.left = buttonRect.left + 1 + 'px';
		this.menuEl.style.minWidth = buttonRect.width - 1 + 'px';

		const documentWidth = document.documentElement.clientWidth;
		const documentHeight = document.documentElement.clientHeight;
		let menuClientRect = this.menuEl.getBoundingClientRect();
		const maxWidth = documentWidth - MARGIN * 2;
		const maxHeight = documentHeight - MARGIN * 2;

		let recalculateRect, dontSetLeft, dontSetTop;

		if (menuClientRect.width > maxWidth) {
			this.menuEl.style.width = maxWidth + 'px';
			this.menuEl.style.left = MARGIN + 'px';
			recalculateRect = true;
			dontSetLeft = true;
		}

		if (menuClientRect.height > maxHeight) {
			this.menuEl.style.height = maxHeight + 'px';
			this.menuEl.style.top = MARGIN + 'px';
			recalculateRect = true;
			dontSetTop = true;
		}

		if (!dontSetLeft || !dontSetTop) {
			if (recalculateRect) menuClientRect = this.menuEl.getBoundingClientRect();

			if (!dontSetLeft) {
				const farthestPosition = documentWidth - MARGIN;
				if (menuClientRect.right > farthestPosition && menuClientRect.width < maxWidth) {
					const leftDelta = menuClientRect.right - farthestPosition;
					this.menuEl.style.left = buttonRect.left - leftDelta + 'px';
				}
			}

			if (!dontSetTop) {
				const lowestPosition = documentHeight - MARGIN;
				if (menuClientRect.bottom > lowestPosition && menuClientRect.height < maxHeight) {
					const bottomDelta = menuClientRect.bottom - lowestPosition;
					this.menuEl.style.top = buttonRect.top - bottomDelta + 'px';
				}
			}
		}

		this.menuEl.classList.add('visible');
		this.menuEl.focus();
	}
}
