import { Pipe, PipeTransform } from '@angular/core';
import { MachineRequestErrorCodeValue } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Pipe({
	name: 'machineRequestErrorCode',
})
export class MachineRequestErrorCodePipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {}

	transform(errorCode: MachineRequestErrorCodeValue): string {
		if (errorCode === '') {
			return '';
		}

		return this.i18nService.get(`requestErrorCode.${errorCode}`);
	}
}
