import { Component, Input, OnChanges } from '@angular/core';
import { EntityStatistics, File, Machine, MailStats } from '@wcd/domain';
import { PrettyNumberService } from '@wcd/prettify';
import { findLast } from 'lodash-es';
import { DataTableField } from '@wcd/datatable';
import { EntityNameComponent } from '../../entity-name/entity-name.component';
import { FileEntityTypeService } from '../../../../@entities/files/services/file.entity-type.service';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';

const LOW_PREVALENCE_LIMIT = 10;

@Component({
	selector: 'entity-prevalence-statistics',
	template: `
		<ng-container *ngIf="statistics">
			<ng-container *ngIf="!isLoadingOrganizationPrevalence; else loading">
					<div>
						<h4>{{'file_prevalence_30d' | i18n}}</h4>
					</div>
					<ng-container *ngIf="showLowPrevMessage">
						<div class="wcd-padding-medium-bottom">
							<wcd-shared-icon [iconName]="'warning'"></wcd-shared-icon>
							<span>{{'file_Org_prevalence' | i18n}}</span>
						</div>
					</ng-container>
					<div class="wcd-padding-large-bottom">
						<distribution-element
							[title]="'file_mailStats_emailsSummary' | i18n"
							[mainText]="transformToPrettyNumber(mailStats && mailStats.numberOfInboxes || 0)"
						></distribution-element>
						<distribution-element
							[title]="'reports_widgets_prevalence_org_device' | i18n"
							[mainText]="transformToPrettyNumber(statistics.organizationPrevalence || 0)"
						></distribution-element>
						<distribution-element
							[title]="'reports_widgets_prevalence_worldwide_device' | i18n"
							[mainText]="transformToPrettyNumber(statistics.worldwidePrevalence || 0 )"
						></distribution-element>
					</div>
					<ng-container *ngIf="statistics.organizationLastSeen">
						<div class="wcd-width-x-large">
							<wcd-datatable [items]="orgPrevItems" [columns]="orgPrevFields" [selectEnabled]="false">
							</wcd-datatable>
						</div>
					</ng-container>
					<ng-container *ngIf="statistics.worldwideLastSeen">
						<div class="wcd-width-x-large">
							<wcd-datatable [items]="worldwidePrevItems" [columns]="worldwidePrevFields"
										[selectEnabled]="false">
							</wcd-datatable>
						</div>
					</ng-container>
				<div class="wcd-padding-small-bottom">
						<route-link [routeLink]="{ routerLink: fileLink }">
							{{ 'reports_widgets_prevalence_go_to_observed_in_org' | i18n }}
						</route-link>
					</div>
					<div>
						<a
								*ngIf="mailStats && mailStats.deepLinkToO365Portal"
								(click)='log365PortalClickEvent()'
								href="{{ mailStats.deepLinkToO365Portal }}"
								data-track-id="deepLinkToO365Portal"
								data-track-type="Navigation"
							>
								{{ 'file.mailStats.openInO365' | i18n }}
						</a>
					</div>
			</ng-container>
		</ng-container>
		<ng-template #loading>
			<div class="wcd-margin-small-bottom wcd-m365-typography">
				<div class="wcd-flex-horizontal">
					<div [ngClass]="{ 'wcd-flex-2': isLoadingOrganizationPrevalence }">
						<fab-shimmer
							[isDataLoaded]="!isLoadingOrganizationPrevalence"
							[styles]="shimmerStyles"
						>
							<h4 class="wcd-margin-none-bottom">
								{{
								'file.prevalence.machinesOrganization'
									| i18n
									: {
										organizationPrevalence: transformToPrettyNumber(
											statistics.organizationPrevalence
										)
									}
								}}
							</h4>
						</fab-shimmer>
					</div>
				</div>
			</div>
		</ng-template>
		<ng-template #noData>
			<h4>{{ 'reports.widgets.prevalence.noData' | i18n }}</h4>
		</ng-template>
	`
})
export class EntityPrevalenceStatisticsComponent implements OnChanges {
	@Input() statistics: EntityStatistics;

	@Input() prevalenceBuckets: number[] = null;

	@Input() file: File;

	@Input() isLoadingOrganizationPrevalence: boolean;

	@Input() mailStats: MailStats;

	showLowPrevMessage: boolean;
	fileLink: string;
	orgPrevItems = [];
	worldwidePrevItems = [];
	shimmerStyles = shimmerStyles;
	orgPrevFields = DataTableField.fromList([
		{
			id: 'observed',
			name: this.i18n.get('file_fileInOrg_30days'),
		},
		{
			id: 'time',
			name: this.i18n.get('file_prevalence_time'),
		},
		{
			id: 'device',
			name: this.i18n.get('machine_entityType_singularName'),
			component: {
				type: EntityNameComponent,
				getProps: (data) => this.createPrevalenceDeviceData(data),
			},
		}
	]);
	worldwidePrevFields = DataTableField.fromList([
		{
			id: 'observed',
			name: this.i18n.get('files_entityDetails_sections_observedWorldwide_title'),
		},
		{
			id: 'time',
			name: this.i18n.get('file_prevalence_time'),
		}
	]);



	constructor(private readonly prettyNumberService: PrettyNumberService,
				private fileEntityTypeService: FileEntityTypeService,
				private i18n: I18nService
	) {
	}

	private log365PortalClickEvent() {
		sccHostService.log.trackEvent('Improved file page - Clicked 365 portal');
	}

	ngOnInit(){
		this.fileLink = this.file && this.fileEntityTypeService.entityType.getEntitiesLink([this.file], {tab: 'timeline'});
	}

	ngOnChanges() {
		this.createOrgPrevItems();
		this.createWorldWildPrevItems();
		this.showLowPrevMessage = this.statistics.organizationPrevalence ? (this.statistics.organizationPrevalence < LOW_PREVALENCE_LIMIT) : false;
	}

	transformToPrettyNumber(numb: number) {
		if (this.prevalenceBuckets && this.prevalenceBuckets.length && numb > this.prevalenceBuckets[0]) {
			const matchingBucket = findLast(this.prevalenceBuckets, bucket => bucket < numb);
			if (matchingBucket !== null) {
				return this.prettyNumberService.prettyNumber(matchingBucket) + '+';
			}
		}
		return this.prettyNumberService.prettyNumber(numb);
	}

	createOrgPrevItems() {
		const {organizationFirstSeen, organizationLastSeen} = this.statistics;
		if (!organizationFirstSeen) return;
		this.orgPrevItems = [
			{
				id:'firstSeen',
				observed: this.i18n.get('files_entityDetails_sections_observedOrganization_fields_firstSeen_title'),
				time: organizationFirstSeen.toDateString() +' '+ organizationFirstSeen.toLocaleTimeString(),
			},
			{
				id:'lastSeen',
				observed: this.i18n.get('files_entityDetails_sections_observedOrganization_fields_lastSeen_title'),
				time: organizationLastSeen.toDateString() +' '+ organizationLastSeen.toLocaleTimeString(),
			},
		]
	}

	createWorldWildPrevItems() {
		const {worldwideFirstSeen, worldwideLastSeen} = this.statistics;
		if (!worldwideFirstSeen || !worldwideLastSeen) return;
		this.worldwidePrevItems = [
			{
				id:'firstSeen',
				observed: this.i18n.get('files_entityDetails_sections_observedOrganization_fields_firstSeen_title'),
				time: worldwideFirstSeen.toDateString() +' '+ worldwideFirstSeen.toLocaleTimeString(),
			},
			{
				id:'lastSeen',
				observed: this.i18n.get('files_entityDetails_sections_observedOrganization_fields_lastSeen_title'),
				time: worldwideLastSeen.toDateString() +' '+ worldwideLastSeen.toLocaleTimeString(),
			},
		]
	}

	createPrevalenceDeviceData(data) {
		if(data.id === 'lastSeen') {
			const machine = new Machine({
				id: this.statistics.orgLastSeenMachineId,
				name: this.statistics.orgLastSeenDeviceName
			});
			return {
				entity: machine,
				entityName: this.statistics.orgLastSeenDeviceName,
				entityTypeId: Machine,
				openEntityPanel: true,
			};
		}
		else {
			const machine = new Machine({
				id: this.statistics.orgFirstSeenMachineId,
				name: this.statistics.orgFirstSeenDeviceName
			});

			return this.statistics.orgFirstSeenDeviceName ? {
					entity: machine,
					entityName: this.statistics.orgFirstSeenDeviceName,
					entityTypeId: Machine,
					openEntityPanel: true,
				}
			:
				{
					entityName: this.i18n.get('file_prevalence_unavailable')
				}
		}
	}
}
const shimmerStyles = {
	shimmerWrapper: { height: 28 }
}


