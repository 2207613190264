/**
 * Manages SCC router:
 * Redirects
 * Current state
 */
var SccStateSvc = /** @class */ (function () {
    function SccStateSvc(sccState) {
        this.sccState = sccState;
    }
    SccStateSvc.prototype.go = function (state, params, options) {
        return this.sccState.go(state, params, options);
    };
    SccStateSvc.prototype.href = function (state, params, options) {
        return this.sccState.href(state, params, options);
    };
    Object.defineProperty(SccStateSvc.prototype, "current", {
        get: function () {
            return this.sccState.current;
        },
        enumerable: true,
        configurable: true
    });
    SccStateSvc.prototype.transitionTo = function (url, params, options) {
        return this.sccState.transitionTo(url, params, options);
    };
    Object.defineProperty(SccStateSvc.prototype, "transitionService", {
        get: function () {
            return this.sccState.router.transitionService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccStateSvc.prototype, "params", {
        get: function () {
            return this.sccState.params;
        },
        enumerable: true,
        configurable: true
    });
    return SccStateSvc;
}());
export { SccStateSvc };
