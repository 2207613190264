import { ChangeDetectionStrategy, Component, ComponentRef, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import {
	EmailAttachmentsDetailsPanelComponent,
	EmailAttachmentsPanelInput,
	FormattedEmailAttachment,
} from './email-attachments-details.panel.component';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';

@Component({
	selector: 'email-entity-attachments-field',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="attachments?.length; else noAttachments">
			<a
				(click)="showMailMessageAttachments()"
				(keydown.enter)="showMailMessageAttachments()"
				data-track-id="ShowEmailAttachmentsPanel"
				data-track-type="Button"
				class="btn-inline btn-link no-padding"
				tabindex="0"
				role="link"
			>
				{{ 'airsEntities_emailMessage_fields_fields_attachments_seeAll' | i18n: { count: attachments.length } }}
			</a>
		</ng-container>
		<ng-template #noAttachments>
			{{ i18nService.strings.airsEntities_emailMessage_fields_attachments_noAttachmentsFound }}
		</ng-template>
	`,
})
export class EmailEntityAttachmentsFieldComponent {
	@Input() attachments: Array<FormattedEmailAttachment>;

	constructor(public readonly i18nService: I18nService, private dialogsService: DialogsService) {}

	showMailMessageAttachments(): Promise<ComponentRef<EmailAttachmentsDetailsPanelComponent>> {
		const panelSettings: PanelSettings = {
			id: 'email-attachments-panel',
			type: PanelType.large,
			isModal: true,
			showOverlay: false,
			isBlocking: true,
			noBodyPadding: true,
			scrollBody: true,
			headerElementId: 'email-attachments-panel-header',
		};

		const panelInputs: EmailAttachmentsPanelInput = {
			attachments: this.attachments,
		};

		return this.dialogsService
			.showPanel(EmailAttachmentsDetailsPanelComponent, panelSettings, panelInputs)
			.toPromise();
	}
}
