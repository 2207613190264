import { ReadOnlyIdentifiableConfig } from '../../../data/models/readonly-identifiable.model';
import { Identifiable } from '../../../data/models/identifiable.model';

export const ADVANCED_SETTINGS_ID: string = 'advanced';

export class AdvancedSettingsModel extends Identifiable<string> {
	uploadToCloudSettings: UploadToCloudSettings;

	// localQuarantineSettings: LocalQuarantineSettings;
	// phishingSettings: PhishingSettings;
	memoryContentAnalysisSettings: SettingsFieldsBase;

	get isPhisingValid() {
		return true;
		// return !this.phishingSettings.enabled || this.phishingSettings.mailbox;
	}

	constructor(data: AdvancedSettingsBackendData) {
		super(data);
	}

	setData(data: AdvancedSettingsBackendData) {
		super.setData(data);

		this.id = ADVANCED_SETTINGS_ID;

		this.uploadToCloudSettings = {
			enabled: data.cloud_upload,
			fileExtensions: data.computer_file_extension,
			// mailExtensions: data.mail_file_extension,
		};
		//
		// this.localQuarantineSettings = {
		// 	enabled: data.quarantine_on,
		// 	folder: data.quarantine_folder
		// };
		//
		// this.phishingSettings = {
		// 	enabled: data.phishing_enabled,
		// 	mailbox: data.phishing_mailbox
		// };
		//
		this.memoryContentAnalysisSettings = {
			enabled: data.upload_memory_content,
		};
	}

	getBackendData(): AdvancedSettingsBackendData {
		return {
			id: this.id,

			cloud_upload: this.uploadToCloudSettings.enabled,
			computer_file_extension: this.uploadToCloudSettings.fileExtensions,
			// mail_file_extension: this.uploadToCloudSettings.mailExtensions,

			// quarantine_on: this.localQuarantineSettings.enabled,
			// quarantine_folder: this.localQuarantineSettings.folder,

			// phishing_enabled: this.phishingSettings.enabled,
			// phishing_mailbox: this.phishingSettings.mailbox,

			upload_memory_content: this.memoryContentAnalysisSettings.enabled,
		};
	}
}

export interface AdvancedSettingsBackendData extends ReadOnlyIdentifiableConfig {
	cloud_upload: boolean;
	computer_file_extension?: string;
	// mail_file_extension?: string;
	// quarantine_on: boolean,
	// quarantine_folder?: string,
	// phishing_enabled: boolean,
	// phishing_mailbox?: string,
	upload_memory_content?: boolean;
}

export interface SettingsFieldsBase {
	enabled: boolean;
}

export interface UploadToCloudSettings extends SettingsFieldsBase {
	fileExtensions: string;
	// mailExtensions: string;
}

export interface ExportToSyslogSettings extends SettingsFieldsBase {
	port: string;
	server: string;
}

export interface LocalQuarantineSettings extends SettingsFieldsBase {
	folder: string;
}

export interface PhishingSettings extends SettingsFieldsBase {
	mailbox: string;
}
