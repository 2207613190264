import { Component, Input, OnInit } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';

const AVAILABLE_RESTORE_ALERTS_OPTIONS: Array<{ id: boolean; textKey: string }> = [
	{ id: true, textKey: 'restoreAlerts' },
	{ id: false, textKey: 'KeepAlertsSuppressed' },
];
@Component({
	selector: 'disable-rule-confirm',
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<form
				#disableRuleConfirmForm="ngForm"
				class="wcd-full-height wcd-flex-vertical"
				(submit)="submitDisable()"
				data-track-component="DisableSuppressionRuleConfirm"
			>
				<div class="wcd-flex-1 dialog-contents">
					<p>
						{{ 'suppressionRules.disable.description' | i18n }}
					</p>
					<div class="form-group">
						<wcd-radiolist
							[values]="selectableActions"
							name="restore-alerts-action"
							[(ngModel)]="restoreAlerts"
						></wcd-radiolist>
					</div>
					<div class="form-group">
						<textarea
							required
							rows="5"
							name="confirm-text"
							placeholder="Please add a comment"
							class="form-control wcd-full-width"
							[(ngModel)]="confirmText"
						></textarea>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none">
					<button
						type="submit"
						data-track-id="DisableRuleConfirm"
						data-track-type="Button"
						[disabled]="
							disableRuleConfirmForm.form.pristine ||
							!disableRuleConfirmForm.form.valid ||
							!isValid
						"
						class="btn btn-primary wcd-margin-right"
					>
						{{ confirmBtn }}
					</button>
					<button
						type="button"
						data-track-id="Cancel"
						data-track-type="Button"
						(click)="close()"
						class="btn btn-cancel"
					>
						{{ 'buttons.cancel' | i18n }}
					</button>
				</footer>
			</form>
		</modal>
	`,
})
export class DisableRuleConfirmModalComponent extends ModalContainer implements OnInit {
	@Input() confirm: (disablingData: { restoreAlerts: boolean; comment: string }) => any;
	@Input() cancel: Function;
	@Input() isDeleteMode: boolean;

	confirmText: string;
	restoreAlerts: ChecklistValue;
	selectableActions: Array<ChecklistValue>;
	confirmBtn: string;

	get isValid(): boolean {
		return !!(this.confirmText && this.restoreAlerts);
	}

	constructor(protected router: Router, private i18nService: I18nService) {
		super(router);

		this.setActionTexts();
	}

	private setActionTexts() {
		this.selectableActions = AVAILABLE_RESTORE_ALERTS_OPTIONS.map(
			(action: { id: boolean; textKey: string }) => {
				return {
					id: action.id,
					name: this.i18nService.get(`suppressionRules.disable.${action.textKey}`),
				};
			}
		);
	}
	ngOnInit() {
		if (this.isDeleteMode) {
			this.confirmBtn = this.i18nService.get(`suppressionRules.delete.confirmButton`);
		} else {
			this.confirmBtn = this.i18nService.get(`suppressionRules.disable.confirmButton`);
		}
	}

	submitDisable() {
		this.confirm({ restoreAlerts: this.restoreAlerts.id, comment: this.confirmText });
	}

	close() {
		this.cancel();
	}
}
