/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../shared/components/copyable-input/copyable-input.component.ngfactory";
import * as i3 from "../../../shared/components/copyable-input/copyable-input.component";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "./registry-modification.entity-details.component";
var styles_RegistryModificationEntityDetailsComponent = [];
var RenderType_RegistryModificationEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RegistryModificationEntityDetailsComponent, data: {} });
export { RenderType_RegistryModificationEntityDetailsComponent as RenderType_RegistryModificationEntityDetailsComponent };
function View_RegistryModificationEntityDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_RegistryModificationEntityDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RegistryModificationEntityDetailsComponent_3)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { fieldName: 0, current: 1, previous: 2 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _v.parent.context.$implicit, _co.current, _co.previous); var currVal_1 = i0.ɵnov(_v.parent.parent, 6); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_RegistryModificationEntityDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.current && _co.current[_v.context.$implicit]) || (_co.previous && _co.previous[_v.context.$implicit])); _ck(_v, 3, 0, currVal_0); }, null); }
function View_RegistryModificationEntityDetailsComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_RegistryModificationEntityDetailsComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RegistryModificationEntityDetailsComponent_7)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(4, { fieldName: 0, value: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 4, 0, _v.parent.parent.context.fieldName, _v.parent.parent.context.previous[_v.parent.parent.context.fieldName]); var currVal_1 = i0.ɵnov(_v.parent.parent.parent, 10); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_RegistryModificationEntityDetailsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "copyable-input", [["showFullValue", "true"]], null, null, null, i2.View_CopyableInputComponent_0, i2.RenderType_CopyableInputComponent)), i0.ɵdid(3, 49152, null, 0, i3.CopyableInputComponent, [], { value: [0, "value"], readonly: [1, "readonly"], showFullValue: [2, "showFullValue"], ariaLabel: [3, "ariaLabel"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_6)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.current[_v.parent.context.fieldName]; var currVal_1 = true; var currVal_2 = "true"; var currVal_3 = i0.ɵunv(_v, 3, 3, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), (("registry.fields." + _v.parent.context.fieldName) + ".title"))); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = (_v.parent.context.previous && (_v.parent.context.previous[_v.parent.context.fieldName] !== _v.parent.context.current[_v.parent.context.fieldName])); _ck(_v, 7, 0, currVal_4); }, null); }
function View_RegistryModificationEntityDetailsComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_RegistryModificationEntityDetailsComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_RegistryModificationEntityDetailsComponent_9)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(3, { fieldName: 0, previous: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.fieldName, _v.parent.context.previous); var currVal_1 = i0.ɵnov(_v.parent.parent, 8); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_RegistryModificationEntityDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 6, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_5)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["onlyPreviousValue", 2]], null, 0, null, View_RegistryModificationEntityDetailsComponent_8)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_1 = _v.context.current; var currVal_2 = i0.ɵnov(_v, 10); _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), (("registry.fields." + _v.context.fieldName) + ".title"))); _ck(_v, 2, 0, currVal_0); }); }
function View_RegistryModificationEntityDetailsComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "copyable-input", [["showFullValue", "true"]], null, null, null, i2.View_CopyableInputComponent_0, i2.RenderType_CopyableInputComponent)), i0.ɵdid(1, 49152, null, 0, i3.CopyableInputComponent, [], { value: [0, "value"], readonly: [1, "readonly"], showFullValue: [2, "showFullValue"], ariaLabel: [3, "ariaLabel"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var currVal_0 = _v.parent.context.previous[_v.parent.context.fieldName]; var currVal_1 = true; var currVal_2 = "true"; var currVal_3 = i0.ɵunv(_v, 1, 3, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), (("registry.fields." + _v.parent.context.fieldName) + ".title"))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_RegistryModificationEntityDetailsComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_11)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.context.previous; _ck(_v, 2, 0, currVal_0); }, null); }
function View_RegistryModificationEntityDetailsComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "copyable-input", [["class", "wcd-flex-1 subtle"], ["showFullValue", "true"]], null, null, null, i2.View_CopyableInputComponent_0, i2.RenderType_CopyableInputComponent)), i0.ɵdid(1, 49152, null, 0, i3.CopyableInputComponent, [], { value: [0, "value"], readonly: [1, "readonly"], showFullValue: [2, "showFullValue"], ariaLabel: [3, "ariaLabel"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var currVal_0 = _v.parent.context.value; var currVal_1 = true; var currVal_2 = "true"; var currVal_3 = i0.ɵunv(_v, 1, 3, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), (("registry.fields." + _v.parent.context.fieldName) + ".title"))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_RegistryModificationEntityDetailsComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "span", [["class", "wcd-flex-horizontal wcd-flex-center-vertical wcd-padding-small-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "span", [["class", "wcd-flex-none wcd-margin-small-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ":"])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_13)), i0.ɵdid(8, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_1 = _v.context.value; var currVal_2 = i0.ɵnov(_v.parent, 12); _ck(_v, 8, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "registry.fields.changedFrom")); _ck(_v, 4, 0, currVal_0); }); }
function View_RegistryModificationEntityDetailsComponent_14(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "span", [["class", "subtle"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["[", "]"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "registry.fields.empty")); _ck(_v, 1, 0, currVal_0); }); }
export function View_RegistryModificationEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 12, "dl", [["role", "none"]], [[2, "key-values", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RegistryModificationEntityDetailsComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["fieldDetails", 2]], null, 0, null, View_RegistryModificationEntityDetailsComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["onlyPreviousValueDisplay", 2]], null, 0, null, View_RegistryModificationEntityDetailsComponent_10)), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["previousDetails", 2]], null, 0, null, View_RegistryModificationEntityDetailsComponent_12)), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["empty", 2]], null, 0, null, View_RegistryModificationEntityDetailsComponent_14)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.registryProperties; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asKeyValueList; _ck(_v, 1, 0, currVal_0); }); }
export function View_RegistryModificationEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "registry-modification-entity-details", [], null, null, null, View_RegistryModificationEntityDetailsComponent_0, RenderType_RegistryModificationEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i6.RegistryModificationEntityDetailsComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RegistryModificationEntityDetailsComponentNgFactory = i0.ɵccf("registry-modification-entity-details", i6.RegistryModificationEntityDetailsComponent, View_RegistryModificationEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { RegistryModificationEntityDetailsComponentNgFactory as RegistryModificationEntityDetailsComponentNgFactory };
