import { ReportWidgetsLayoutConfig, ReportWidgetsLayoutModel } from './report-widgets-layout.base';
import {
	ReportWidgetsLayoutColumnConfig,
	ReportWidgetsLayoutColumnModel,
} from './report-widgets-layout.column.model';

export class ReportWidgetsLayoutRowModel extends ReportWidgetsLayoutModel {
	title: string;
	columns: Array<ReportWidgetsLayoutColumnModel>;

	get type(): string {
		return 'row';
	}

	constructor(config: ReportWidgetsLayoutRowConfig) {
		super(config);

		this.title = config.title;
		this.columns =
			config.columns && config.columns.length
				? config.columns.map(
						(columnConfig: ReportWidgetsLayoutColumnConfig) =>
							new ReportWidgetsLayoutColumnModel(columnConfig)
				  )
				: [];
	}
}

export interface ReportWidgetsLayoutRowConfig extends ReportWidgetsLayoutConfig {
	title?: string;
	columns?: Array<ReportWidgetsLayoutColumnConfig>;
}
