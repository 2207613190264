import {
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
	ChangeDetectionStrategy,
	ViewChild,
	ViewContainerRef,
	Injector,
	ComponentFactory,
	ComponentRef,
	ComponentFactoryResolver,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';

import { OnChanges, TypedChanges } from '@wcd/angular-extensions';

import { FilterFieldChange } from '../models/filter-field-change.interface';
import { FilterValuesComponent } from './filter-values.component';
import { FilterValuesChecklistComponent } from './filter-values/checklist/filter-values.checklist.component';
import { FilterSelectedValues } from '../models/filter-selected-values.type';
import { FiltersField } from '../models/filters-field.interface';
import { SerializedFilters } from '../models/serialized-filters.type';
import { clone } from 'lodash-es';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

const DEFAULT_FILTER_VALUES_COMPONENT = FilterValuesChecklistComponent;

@Component({
	selector: 'wcd-filters-field',
	templateUrl: './filters-field.component.html',
	styleUrls: ['./filters-field.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersFieldComponent<TFilterData, TFilterSelection, TConfig>
	implements OnInit, OnDestroy, OnChanges<FiltersFieldComponent<TFilterData, TFilterSelection, TConfig>> {
	@Input() readonly field: FiltersField<TFilterData>;
	@Input() readonly data: TFilterData;

	focus: boolean = false;
	private _selectedValues: TFilterSelection;
	@Input()
	set selectedValues(value: TFilterSelection) {
		this._selectedValues = clone(value);
		if (this._isInit) this.setSelectedValues();
	}

	@Input() fixedSelectedValues: SerializedFilters;

	@Output()
	filterChange: EventEmitter<FilterFieldChange<TFilterSelection>> = new EventEmitter<
		FilterFieldChange<TFilterSelection>
	>();

	@ViewChild('filterValues', { read: ViewContainerRef, static: true })
	filterValuesPlaceholder: ViewContainerRef;

	private _selectedValuesChangedSubscription: Subscription;
	private _filterValuesComponent: ComponentRef<
		FilterValuesComponent<TFilterData, TFilterSelection, TConfig>
	>;
	private _isInit: boolean;

	isMinimized = false;

	get hasSelectedValues(): boolean {
		return !!this._selectedValues;
	}

	constructor(private resolver: ComponentFactoryResolver,
				private changeDetectorRef: ChangeDetectorRef,
				private liveAnnouncer: LiveAnnouncer,
				private i18nService: I18nService) {}

	ngOnInit() {
		this.setFilterValuesComponent();
		this._isInit = true;
	}

	ngOnChanges(changes: TypedChanges<FiltersFieldComponent<TFilterData, TFilterSelection, TConfig>>) {
		if (changes.data && this._filterValuesComponent && this._filterValuesComponent.instance) {
			this._filterValuesComponent.instance.data = this.data;
		}
	}

	ngOnDestroy() {
		if (this._selectedValuesChangedSubscription) this._selectedValuesChangedSubscription.unsubscribe();
	}

	triggerChange(selectedValues: FilterSelectedValues<TFilterSelection>) {
		this.filterChange.emit({
			selectedValues,
			field: this.field,
		});
	}

	public setFocus(){
		this.focus = true
		this.changeDetectorRef.detectChanges();
	}
	public resetFocus(){
		this.focus = false;
		this.changeDetectorRef.detectChanges();
	}

	private setFilterValuesComponent() {
		const { component } = this.field;

		const injector = Injector.create({
				providers: [],
				parent: this.filterValuesPlaceholder.parentInjector,
			}),
			factory: ComponentFactory<
				FilterValuesComponent<TFilterData, TFilterSelection, TConfig>
			> = this.resolver.resolveComponentFactory(
				component && component.type ? component.type : DEFAULT_FILTER_VALUES_COMPONENT
			);

		this._filterValuesComponent = factory.create(injector);

		const fixedFilterValuesSelection: TFilterSelection = this.fixedSelectedValues
			? this._filterValuesComponent.instance.deserialize(this.fixedSelectedValues)
			: null;

		Object.assign(this._filterValuesComponent.instance, {
			fieldName: this.field.name,
			fieldId: this.field.id,
			config: component && component.config,
			fixedSelectedValues: fixedFilterValuesSelection,
		});

		// Setting data AFTER fieldId and config, since doing something with the data might require to first have a config available (in setter situations, for example).
		this._filterValuesComponent.instance.data = this.data;

		this._selectedValuesChangedSubscription = this._filterValuesComponent.instance.filterValuesChange.subscribe(
			$event => this.triggerChange($event)
		);

		this.setSelectedValues();
		this.filterValuesPlaceholder.insert(this._filterValuesComponent.hostView);
		this.changeDetectorRef.detectChanges();
	}

	setSelectedValues() {
		if (!this._filterValuesComponent) return;

		this._filterValuesComponent.instance.selectedValues = this._selectedValues;
		this._filterValuesComponent.instance.setSelectedValues(this._selectedValues);
	}

	/**
	 * Returns the selected values in this field as a a key/value (string value), using the filter component's `serialize` method.
	 */
	serialize(): SerializedFilters {
		const serialized = this._filterValuesComponent.instance.serialize();

		if (this.field.serializeFilterValues)
			return this.field.serializeFilterValues(
				this._filterValuesComponent.instance.selectedValues,
				serialized,
				this._filterValuesComponent.instance.value
			);

		return serialized;
	}

	deserialize(serializedSelection: SerializedFilters): Observable<TFilterSelection> {
		if (this.field.deserializeFilterValues) {
			const deserialized:
				| TFilterSelection
				| Observable<TFilterSelection> = this.field.deserializeFilterValues(serializedSelection);
			return deserialized instanceof Observable ? deserialized : of(deserialized);
		}

		return of(this._filterValuesComponent.instance.deserialize(serializedSelection));
	}

	minimizedClicked(event) {
		this.liveAnnouncer.announce(
			this.i18nService.get(!this.isMinimized ? "common.button.collapsed" : "common.button.expanded")
			, 'assertive', 1000).then(() => {
				setTimeout(()=>{
					this.isMinimized = !this.isMinimized;
					event.stopPropagation();
					event.preventDefault();
					this.changeDetectorRef.markForCheck();
				},200);
			});
	}
}
