var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { CustomActionEntityType, HuntingCustomActionEntity, HuntingCustomActionRequest, HuntingCustomActionResult, HuntingCustomActionsRequest, HuntingCustomActionsResponse, HuntingCustomActionStatusCode, HuntingQueryResultRecord, HuntingSupportedBulkActionsApiCall, RunHuntingBulkActionApiCall, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { concat, forkJoin } from 'rxjs';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { WizardStepModel } from '../../shared/components/wizard/wizard-step.model';
import { DataTableField } from '@wcd/datatable';
import { findLastIndex, flatMap, flatten, groupBy, pick, uniq, uniqBy } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ScheduledHuntingActionsFields } from '../../@entities/scheduled_hunting/scheduled-hunting.actions.fields';
import { catchError, map, shareReplay, take, tap } from 'rxjs/operators';
import { HuntingEntityNamesService } from '../services/hunting-entity-names.service';
import { ContentState } from '@wcd/contents-state';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { Feature, FeaturesService } from '@wcd/config';
var WizardStepId;
(function (WizardStepId) {
    WizardStepId[WizardStepId["SelectAction"] = 1] = "SelectAction";
    WizardStepId[WizardStepId["SelectEntities"] = 2] = "SelectEntities";
    WizardStepId[WizardStepId["Confirm"] = 3] = "Confirm";
    WizardStepId[WizardStepId["ActionStatus"] = 4] = "ActionStatus";
})(WizardStepId || (WizardStepId = {}));
var CustomActionRecord = /** @class */ (function (_super) {
    __extends(CustomActionRecord, _super);
    function CustomActionRecord() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return CustomActionRecord;
}(HuntingCustomActionResult));
var HuntingBulkActionsComponent = /** @class */ (function (_super) {
    __extends(HuntingBulkActionsComponent, _super);
    function HuntingBulkActionsComponent(router, featuresService, paris, i18nService, huntingActionsFields, huntingEntityNamesService, appInsightsService, changeDetector) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.huntingActionsFields = huntingActionsFields;
        _this.huntingEntityNamesService = huntingEntityNamesService;
        _this.appInsightsService = appInsightsService;
        _this.changeDetector = changeDetector;
        _this.WizardStepId = WizardStepId;
        _this.isEditingBulkName = false;
        _this.isEditingBulkComment = false;
        _this.didSave = false;
        _this.isActionCenterLinkEnabled = featuresService.isEnabled(Feature.ActionCenterBulkIdColumn);
        return _this;
    }
    HuntingBulkActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setWizardSteps();
        this.supportedActionsState = ContentState.Loading;
        this.supportedActions$ = this.paris
            .apiCall(HuntingSupportedBulkActionsApiCall, this.records[0])
            .pipe(take(1), tap(function () { return (_this.supportedActionsState = ContentState.Complete); }, function (err) { return (_this.supportedActionsState = ContentState.Error); }), shareReplay());
    };
    HuntingBulkActionsComponent.prototype.setWizardSteps = function () {
        var _this = this;
        this.stepsData = [
            new WizardStepModel({
                id: WizardStepId.SelectAction,
                subText: this.i18nService.get('hunting.bulkActions.chooseActions.title'),
                show: true,
                validation: function () { return _this.selectedActions && _this.selectedActions.length > 0; },
            }),
            new WizardStepModel({
                id: WizardStepId.SelectEntities,
                subText: this.i18nService.get('hunting.bulkActions.chooseEntities.title'),
                show: true,
                validation: function () { return Boolean(_this.selectedEntities && _this.selectedEntities.length); },
            }),
            new WizardStepModel({
                id: WizardStepId.Confirm,
                subText: this.i18nService.get('hunting.bulkActions.confirm.title'),
                actionButtonSettings: {
                    label: this.i18nService.get('hunting.bulkActions.submitActions'),
                    onActionButtonClick: function () {
                        _this.didSave = true;
                        _this.currentStep = _this.stepsData[_this.currentStep.id];
                        _this.changeDetector.detectChanges();
                        return _this.submitActions();
                    },
                },
                show: true,
            }),
            new WizardStepModel({
                id: WizardStepId.ActionStatus,
                subText: this.i18nService.get('hunting.bulkActions.actionStatus.title'),
                show: false,
            }),
        ];
        this.currentStep = this.stepsData[0];
    };
    HuntingBulkActionsComponent.prototype.previousStep = function () {
        var currentStepIndex = this.currentStep.id - 1;
        var nextStepIndex = findLastIndex(this.stepsData, function (step) { return step.show; }, currentStepIndex - 1);
        this.currentStep = this.stepsData[nextStepIndex];
    };
    HuntingBulkActionsComponent.prototype.nextStep = function () {
        switch (this.currentStep.id) {
            case WizardStepId.SelectAction:
                this.setEntities();
                break;
            case WizardStepId.SelectEntities:
                this.confirm();
                break;
        }
        this.currentStep = this.stepsData[this.currentStep.id];
    };
    HuntingBulkActionsComponent.prototype.onClosePanel = function (e) {
        event.preventDefault();
        event.stopPropagation();
        this.closePanel();
    };
    Object.defineProperty(HuntingBulkActionsComponent.prototype, "selectEntitiesStepSubtitle", {
        get: function () {
            var _this = this;
            var actionNames = (this.selectedActions || []).map(function (action) {
                return _this.i18nService.get("hunting_customDetections_actions." + action.actionType);
            });
            return this.i18nService.get('hunting_bulkActions_chooseEntities_subtitle', {
                actionName: actionNames.join(', '),
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingBulkActionsComponent.prototype, "showActionCenterLink", {
        get: function () {
            return this.isActionCenterLinkEnabled && this.didSave && Boolean(this.actionCenterBulkId);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingBulkActionsComponent.prototype, "actionCenterBulkIdDisplay", {
        get: function () {
            // Action center displays only the last 6 characters of the bulk ID
            return this.actionCenterBulkId && this.actionCenterBulkId.substring(this.actionCenterBulkId.length - 6);
        },
        enumerable: true,
        configurable: true
    });
    HuntingBulkActionsComponent.prototype.onRecordSelectionChange = function (_a) {
        var items = _a.items;
        this.selectedEntities = items;
    };
    HuntingBulkActionsComponent.prototype.setEntities = function () {
        var _this = this;
        if (!this.selectedActions || !this.selectedActions.length) {
            return;
        }
        var entities = flatten(this.selectedActions.map(function (action) { return _this.getDistinctEntities(action, _this.records); }));
        this.entities$ = this.getEntitiesWithPrettyNames(entities);
        this.entityFields = this.huntingActionsFields.bulkActionsFields.filter(function (field) {
            return ['impactedAsset', 'actionType', 'targetEntities'].includes(field.id);
        });
        this.onRecordSelectionChange({ items: entities });
    };
    HuntingBulkActionsComponent.prototype.getDistinctEntities = function (action, records) {
        var _this = this;
        var entityIdColumns = action.entities.map(function (e) { return e.entityIdField; });
        var entityColumns = uniq(entityIdColumns.concat(action.entities.map(function (e) { return e.entityNameField; }))).filter(Boolean);
        records = records
            .filter(function (record) { return entityIdColumns.every(function (idCol) { return record.dataItem[idCol]; }); })
            .map(function (record) {
            return new HuntingQueryResultRecord({
                id: entityIdColumns.map(function (c) { return record.dataItem[c]; }).join('_'),
                dataItem: pick(record.dataItem, entityColumns),
            });
        });
        var distinctEntities = uniqBy(records, 'id').map(function (record) {
            return new CustomActionRecord({
                record: record,
                actionType: action.actionType,
                entities: action.entities.map(function (e) {
                    return new HuntingCustomActionEntity({
                        id: record.dataItem[e.entityIdField],
                        name: record.dataItem[e.entityNameField],
                        entityType: _this.paris.getValue(CustomActionEntityType, e.entityType),
                    });
                }),
            });
        });
        return distinctEntities;
    };
    HuntingBulkActionsComponent.prototype.getEntitiesWithPrettyNames = function (customActions) {
        var _this = this;
        // We need to show pretty names of the action's impacted entities, e.g. machine name instead of machine ID
        // Call BE to obtain the names by ID
        var impactedAssets = customActions.map(function (action) { return action.impactedAsset; }).filter(Boolean);
        return this.huntingEntityNamesService.getEntitiesWithNames(impactedAssets).pipe(map(function (entitiesWithNames) {
            _this.copyEntityNames(entitiesWithNames, impactedAssets);
            // Return a copy of the modified array to trigger change detection
            return customActions.slice();
        }));
    };
    HuntingBulkActionsComponent.prototype.copyEntityNames = function (entitiesWithNames, entities) {
        for (var _i = 0, entitiesWithNames_1 = entitiesWithNames; _i < entitiesWithNames_1.length; _i++) {
            var entityWithName = entitiesWithNames_1[_i];
            // find matching entities (by ID and entity type) and assign their name
            for (var _a = 0, entities_1 = entities; _a < entities_1.length; _a++) {
                var entity = entities_1[_a];
                if (entity.id === entityWithName.id &&
                    entity.entityType.type === entityWithName.entityType.type) {
                    entity.name = entityWithName.name;
                }
            }
        }
    };
    HuntingBulkActionsComponent.prototype.confirm = function () {
        var _this = this;
        this.confirmationFields = this.huntingActionsFields.bulkActionsFields
            .filter(function (field) { return ['impactedAsset', 'actionType', 'targetEntities'].includes(field.id); })
            .concat([
            new DataTableField({
                id: 'status',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.status'),
                getDisplay: function (_) { return _this.i18nService.get('statuses.notSubmitted'); },
                className: 'color-text-neutralTertiary',
            }),
        ]);
    };
    HuntingBulkActionsComponent.prototype.submitActions = function () {
        return __awaiter(this, void 0, void 0, function () {
            var recordsByAction, actions, request, rawRequest, actionResults$, tempStatus$;
            var _this = this;
            return __generator(this, function (_a) {
                this.actionsFields = this.huntingActionsFields.bulkActionsFields;
                recordsByAction = groupBy(this.selectedEntities, function (record) { return record.actionType; });
                actions = Object.keys(recordsByAction).map(function (actionType) {
                    var action = _this.selectedActions.find(function (a) { return a.actionType === +actionType; });
                    var actionRecords = recordsByAction[actionType];
                    return new HuntingCustomActionRequest(__assign({}, action, { records: actionRecords.map(function (actionRecord) { return actionRecord.record.dataItem; }) }));
                });
                request = new HuntingCustomActionsRequest({
                    actions: actions,
                    remediationName: this.bulkName,
                    remediationComment: this.bulkComment,
                });
                rawRequest = this.paris.modeler.serializeModel(request, this.paris.getModelBaseConfig(HuntingCustomActionsRequest));
                actionResults$ = this.paris.apiCall(RunHuntingBulkActionApiCall, rawRequest).pipe(catchError(function (err) { return _this.createTemporaryResults(actions, HuntingCustomActionStatusCode.Failed); }), take(1), tap(function (actionResults) {
                    _this.trackActionsStatus(actionResults.results);
                    _this.actionCenterBulkId = actionResults.bulkId;
                    _this.changeDetector.markForCheck();
                }));
                tempStatus$ = this.createTemporaryResults(actions, HuntingCustomActionStatusCode.Running);
                this.actionsStatus$ = concat(tempStatus$, actionResults$).pipe(map(function (actionsResponse) { return actionsResponse && actionsResponse.results; }), tap(function (actionResults) {
                    return _this.copyEntityNames(_this.selectedEntities.map(function (r) { return r.impactedAsset; }), actionResults.map(function (a) { return a.impactedAsset; }));
                }));
                return [2 /*return*/];
            });
        });
    };
    HuntingBulkActionsComponent.prototype.createTemporaryResults = function (actionRequests, status) {
        var _this = this;
        // create temporary actions with a status to display until a real result is returned from the backend
        var actionResults = flatMap(actionRequests, function (action) {
            return action.records.map(function (actionRecord) {
                return _this.paris.createItem(HuntingCustomActionResult, {
                    ActionType: action.actionType,
                    Entities: action.entities.map(function (e) { return ({
                        EntityType: e.entityType,
                        EntityId: actionRecord[e.entityIdField],
                        EntityName: e.entityNameField && actionRecord[e.entityNameField],
                    }); }),
                    Status: status,
                });
            });
        });
        return forkJoin(actionResults).pipe(map(function (results) { return new HuntingCustomActionsResponse({ results: results }); }));
    };
    HuntingBulkActionsComponent.prototype.trackActionsStatus = function (actionResults) {
        this.appInsightsService.trackEvent('Hunting bulk actions', {
            actionResults: actionResults &&
                actionResults.map(function (result) { return ({
                    actionType: result.actionType,
                    status: result.status,
                    failureReason: result.failureReason,
                }); }),
        });
    };
    return HuntingBulkActionsComponent;
}(PanelContainer));
export { HuntingBulkActionsComponent };
