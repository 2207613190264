import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PanelsModule } from '@wcd/panels';
import { ComparisonResultEntityPanelComponent } from './components/comparison-result.entity-panel.component';
import { ComparisonResultEntityTypeService } from './services/comparison-result.entity-type.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, PanelsModule],
	declarations: [ComparisonResultEntityPanelComponent],
	exports: [ComparisonResultEntityPanelComponent],
	entryComponents: [ComparisonResultEntityPanelComponent],
	providers: [ComparisonResultEntityTypeService],
})
export class ComparisonResultEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ComparisonResultEntityTypeService);
	}
}
