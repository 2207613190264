import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { BaselineProfileDetails } from '@wcd/domain';
import { FieldsService } from '../../../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import _ from 'lodash';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService } from '../../../../../tvm/services/tvm-color.service';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { Router } from '@angular/router';
import { CompliantDevicesDataviewCellComponent } from '../components/compliant-devices-dataview-cell/compliant-devices-dataview-cell.component';

@Injectable()
export class ProfilesFieldsService implements FieldsService<BaselineProfileDetails> {
	private _fields: Array<DataviewField<BaselineProfileDetails>>;

	constructor(
		private activatedEntity: ActivatedEntity,
		private router: Router,
		private i18nService: I18nService,
		private tvmColorService: TvmColorService
	) { }

	get fields(): Array<DataviewField<BaselineProfileDetails>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<BaselineProfileDetails>([
				{
					id: 'name',
					name: this.i18nService.strings.tvm_common_name,
					onClick: (profile: BaselineProfileDetails) => {
						this.activatedEntity.setNextEntity(profile);
						this.router.navigate([`/baseline-compliance_t/profiles/${encodeURIComponent(profile.id)}`]);
					},
				},
				{
					id: 'benchmark',
					name: this.i18nService.strings.tvm_baseline_profiles_benchmark_title,
					sort: { enabled: false },
				},
				{
					id: 'version',
					name: this.i18nService.strings.tvm_baseline_profiles_version_title,
					sort: { enabled: false },
				},
				{
					id: 'baselineOsPlatform',
					getDisplay: (profile: BaselineProfileDetails) => `${profile.operatingSystem} ${profile.operatingSystemVersion}`,
					name: this.i18nService.strings.tvm_baseline_profiles_software_title,
					filter: { priority: 1 }
				},
				{
					id: 'compliantDevices',
					name: this.i18nService.strings.tvm_baseline_profiles_compliantDevices_title,
					component: {
						type: CompliantDevicesDataviewCellComponent,
						getProps: (profile: BaselineProfileDetails) => ({
							totalAssetCount: profile.totalDevices,
							assetsCount: profile.passedDevices,
							assetsCountHistory: profile.compliantDevicesHistory,
							profileId: profile.id,
						}),
					},
					allowResize: false,
					minWidth: 200,
					maxWidth: 200,
					sort: { enabled: false },
				},
				{
					id: 'profileStatus',
					name: this.i18nService.strings.tvm_baseline_profiles_status_title,
					sort: { enabled: true, sortDescendingByDefault: true },
					icon: {
						fabricIcon: FabricIconNames.StatusCircleInner,
						className: (profile: BaselineProfileDetails) =>
							this.tvmColorService.getStatusIcon(profile.status),
					},
					getDisplay: (profile: BaselineProfileDetails) =>
						profile.status
							? this.i18nService.strings.tvm_baseline_profiles_enabled
							: this.i18nService.strings.tvm_baseline_profiles_disabled,
					filter: { priority: 2 }
				},
				{
					id: 'complianceLevel',
					name: this.i18nService.strings.tvm_baseline_profiles_profileApplicability_title,
					sort: { enabled: false },
				},
			]);
		}

		return this._fields;
	}
}
