import { Injectable, Injector } from '@angular/core';
import { MtpInvestigationGraphDataModel } from '../models/mtp-investigation-graph-data.model';
import { AirsEntityTypeResults, InvestigatedMachine, MtpInvestigation } from '@wcd/domain';
import { DataSet, Paris } from '@microsoft/paris';
import { MtpInvestigationChangeEvent } from '../../../@entities/mtp_investigations/interfaces/mtp-investigation-change-event.interface';
import { PendingActionsService } from '../../../@entities/remediation/services/pending-actions.service';
import { MtpInvestigationsService } from '../../../@entities/mtp_investigations/services/mtp-investigations.service';

import { isEqual, keyBy } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { AirsEntitiesLoaderService } from '../../../@entities/airs_entities/services/airs-entities-loader.service';

@Injectable()
export class MtpInvestigationGraphService {
	current: MtpInvestigationGraphDataModel;

	constructor(
		private injector: Injector,
		private paris: Paris,
		private airsEntitiesLoaderService: AirsEntitiesLoaderService
	) {}

	getInvestigationGraphData(investigationId: string | number): MtpInvestigationGraphDataModel {
		const mtpInvestigationsService = this.injector.get(MtpInvestigationsService);
		const investigationGraphData: MtpInvestigationGraphDataModel = new MtpInvestigationGraphDataModel(
			mtpInvestigationsService.getInvestigationPolling(investigationId)
		);

		investigationGraphData.entityCount$.subscribe((data: number) => {
			if (!data || !investigationGraphData.investigation) return;

			this.airsEntitiesLoaderService
				.getEntitiesResultsForInvestigation({
					useOfficeEntityApi: investigationGraphData.investigation.isOfficeInvestigation,
					tenantId: sccHostService.loginUser.tenantId,
					investigation_urn: investigationId,
				})
				.subscribe(
					(entityResults: DataSet<AirsEntityTypeResults>) => {
						investigationGraphData.entityResults$.next(entityResults.items);
					},
					error => investigationGraphData.entityResults$.error(error)
				);
		});

		return investigationGraphData;
	}

	static getInvestigationDifference(
		current: MtpInvestigation,
		other: MtpInvestigation
	): MtpInvestigationChangeEvent {
		const differenceSet: MtpInvestigationChangeEvent = {};

		if (current.status !== other.status) differenceSet.status = current.status;

		if (current.requestedStatus !== other.requestedStatus)
			differenceSet.requestedStatus = current.requestedStatus;

		if (!MtpInvestigationGraphService.hostsAreEqual(current, other))
			differenceSet.machines = current.machines;

		if (!isEqual(current.investigatedUsers, other.investigatedUsers))
			differenceSet.investigatedUsers = current.investigatedUsers;

		if (
			!PendingActionsService.comparePendingActionTypes(
				current.pendingActionTypes,
				other.pendingActionTypes
			)
		)
			differenceSet.pendingActionTypes = current.pendingActionTypes;

		if (current.isRemediationDisabled !== other.isRemediationDisabled)
			differenceSet.isRemediationDisabled = current.isRemediationDisabled;

		if (current.totalAlertsCount !== other.totalAlertsCount)
			differenceSet.alertCount = current.totalAlertsCount;

		if (!isEqual(current.actionsSummary, other.actionsSummary))
			differenceSet.actionCount = current.actionsSummary.total;

		if (current.entityCount !== other.entityCount) differenceSet.entityCount = current.entityCount;

		if (current.entityResults !== other.entityResults)
			differenceSet.entityResults = current.entityResults;

		if (current.pendingType !== other.pendingType) differenceSet.pendingType = current.pendingType;

		if (current.commentCount !== other.commentCount) differenceSet.commentCount = current.commentCount;

		return differenceSet;
	}

	static hostsAreEqual(current: MtpInvestigation, otherInvestigation: MtpInvestigation): boolean {
		let hostsChanged: boolean =
			!current.machines || otherInvestigation.machines.length !== current.machines.length;

		if (!hostsChanged && current.machines) {
			const machinesIndex: { [id: string]: InvestigatedMachine } = keyBy(current.machines, 'id');

			hostsChanged = otherInvestigation.machines.some((machine: InvestigatedMachine) => {
				const oldMachine: InvestigatedMachine = machinesIndex[machine.id];
				return (
					!oldMachine ||
					machine.status !== oldMachine.status ||
					machine.users.length !== oldMachine.users.length
				);
			});
		}

		return !hostsChanged;
	}
}
