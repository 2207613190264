import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { Injectable } from '@angular/core';
import { VulnerabilityType } from '@wcd/domain';

declare const moment: typeof import('moment-timezone');

@Injectable()
export class TvmTaggedFieldService {
	zeroDayFeatureEnabled: boolean;
	private newPatchTagDateLimit = moment()
								.subtract(7, 'days')
								.toDate();

	constructor(
		featuresService: FeaturesService,
		private i18nService: I18nService
	) {
		this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
	}

	getZeroDayBaseProps(vulnerabilityType: VulnerabilityType, patchReleaseDate: Date, title: string, shouldShowNewPatchTag: boolean = false){
		if(!this.zeroDayFeatureEnabled){
			return {
				title: title,
				showTag: false,
				tagAfterText: true
			};
		}
		const isZeroDayTag = vulnerabilityType === VulnerabilityType.ZeroDay && !patchReleaseDate;
		const isNewPatchTag = vulnerabilityType === VulnerabilityType.ZeroDay && patchReleaseDate > this.newPatchTagDateLimit && shouldShowNewPatchTag;
		const showTag = isZeroDayTag || isNewPatchTag;
		const tagText = isZeroDayTag
			? this.i18nService.strings.tvm_zeroDay_tag
			: this.i18nService.strings.tvm_zeroDay_new_patch_tag;

		const tagColorClass = isZeroDayTag
			? 'ms-fontColor-mediumSeverity'
			: 'tvm-fontColor-blue';

		const tagBackgroundColorClass = isZeroDayTag
			? 'ms-bgColor-mediumSeverity'
			: 'tvm-bgColor-blue'


		return {
			title: title,
			showTag: showTag,
			tagAfterText: true,
			tagColorClass: tagColorClass,
			tagBackgroundColorClass: tagBackgroundColorClass,
			tagText: tagText,
		};
	}
}
