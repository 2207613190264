import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LiveResponseCommandParam } from './live-response-command-param.value-object';
import { LiveResponseCommandFlag } from './live-response-command-flag.value-object';

@ValueObject({
	singularName: 'Command type',
	pluralName: 'Command types',
	readonly: true,
})
export class LiveResponseCommandType extends ModelBase {
	@EntityField({ data: 'command_definition_id' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'display_name' })
	displayName: string;

	@EntityField() description?: string;

	@EntityField({ data: 'default_alias' })
	defaultAlias?: string;

	@EntityField() aliases?: Array<string>;

	@EntityField({ arrayOf: LiveResponseCommandParam })
	params?: Array<LiveResponseCommandParam>;

	@EntityField({ arrayOf: LiveResponseCommandFlag })
	flags?: Array<LiveResponseCommandFlag>;

	@EntityField() examples?: Array<Array<string>>;

	@EntityField({ defaultValue: false })
	isLocal?: boolean;
}
