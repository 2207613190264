import { Component, ViewEncapsulation } from '@angular/core';
import { PanelSettings } from '@wcd/panels';
import { AlertPageService, DetailsTabDisplayEntity } from '../services/alert-page.service';
import { BehaviorSubject } from 'rxjs';
import { TreeElementCyberEntityTypeEnum } from '@wcd/domain';

@Component({
	selector: 'details-tab',
	template: `
		<ng-container *ngIf="(detailsTab$ | async) as detailsTabDisplayEntity">
			<entity-panel
				[entity]="detailsTabDisplayEntity.entity"
				[entities]="[detailsTabDisplayEntity.entity]"
				[entityType]="detailsTabDisplayEntity.entityType"
				[settings]="entityPanelSettings"
				[extendEntity]="false"
				[disableAutoFocus]="true"
				[disableTrapFocus]="true"
				[options]="{
					hideOpenLink:
						detailsTabDisplayEntity.entityTypeName == TreeElementCyberEntityTypeEnum.alert &&
						detailsTabDisplayEntity.entity.id == (mainAlertPageId$ | async)
				}"
				contentClass="alert-entity-panel-adjust-for-zoom"
			>
			</entity-panel>
		</ng-container>
	`,
	styleUrls: ['./details-tab.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class DetailsTabComponent {
	detailsTab$: BehaviorSubject<DetailsTabDisplayEntity>;
	mainAlertPageId$: BehaviorSubject<string>;
	entityPanelSettings: PanelSettings = {
		isStatic: true,
		noShadow: true,
		hasCloseButton: false,
		noPadding: true,
		noBodyPadding: true,
	};
	readonly TreeElementCyberEntityTypeEnum = TreeElementCyberEntityTypeEnum;

	constructor(private alertPageService: AlertPageService) {
		this.detailsTab$ = this.alertPageService.detailsTab$;
		this.mainAlertPageId$ = this.alertPageService.mainAlertPageId$;
	}
}
