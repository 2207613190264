import { LocaleConfigService } from '@wcd/localization';
import '../../seville/services/seville.locale';
import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('sevilleDate', sevilleDateFilter);

sevilleDateFilter.$inject = ['$filter', 'localeService', 'localeConfigService'];

function sevilleDateFilter($filter, locale, localeConfigService: LocaleConfigService) {
	return function(input, format) {
		if (!input) {
			return '';
		}

		var type = format;
		if (!type) {
			type = 'full';
		}

		var localeDateFormat = locale.getDateFormatInUserLocale();
		var formattedDate = '';
		if (type == 'date') {
			formattedDate = localeDateFormat == 'MDY' ? 'MM.dd.yyyy' : 'dd.MM.yyyy';
		} else if (type == 'time') {
			formattedDate = 'HH:mm:ss';
		} else {
			formattedDate = localeDateFormat == 'MDY' ? 'MM.dd.yyyy | HH:mm:ss' : 'dd.MM.yyyy | HH:mm:ss';
		}

		if (localeConfigService.isLocalTimeZone) {
			var dt = new Date(input);
			dt.setMinutes(dt.getMinutes() - dt.getTimezoneOffset());
			return $filter('date')(dt, formattedDate, 'UTC');
		} else {
			return $filter('date')(input, formattedDate, 'UTC');
		}
	};
}
