
		<ng-container *ngIf="reportColumn.rows">
			<report-row
				*ngFor="let reportRow of reportColumn.rows; trackBy: trackById"
				[reportRow]="reportRow"
				[params]="params"
				[dataOptions]="dataOptions"
				[headerLevel]="++headerLevel"
				#row
				class="dashboard-row {{ reportRow.className }}"
			>
			</report-row>
		</ng-container>
		<report-widget
			*ngFor="let widgetComponent of reportColumn.widgetComponents"
			[params]="params"
			[dataOptions]="dataOptions"
			[widgetComponentType]="widgetComponent.type"
			[ngClass]="'wcd-flex-' + widgetComponent.weight"
			[headerLevel]="headerLevel"
		></report-widget>
	