/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i6 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i7 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i8 from "../../../../../../../projects/shared/src/lib/components/info-box.component.ngfactory";
import * as i9 from "../../../../../../../projects/shared/src/lib/components/info-box.component";
import * as i10 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i11 from "ngx-markdown";
import * as i12 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i13 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i14 from "./machine-entity-status.component";
import * as i15 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
var styles_MachineEntityStatusComponent = [];
var RenderType_MachineEntityStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineEntityStatusComponent, data: {} });
export { RenderType_MachineEntityStatusComponent as RenderType_MachineEntityStatusComponent };
function View_MachineEntityStatusComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "wcd-margin-large-right wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["class", "wcd-padding-small-right"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 7, "div", [["class", "wcd-font-weight-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "span", [["class", "wcd-severity"]], null, null, null, null, null)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(12, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(13, null, ["", ""])), i0.ɵppd(14, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "machines.riskLevel")); _ck(_v, 3, 0, currVal_0); var currVal_2 = "wcd-severity"; var currVal_3 = ("wcd-severity-" + _co.machine.riskScore.id.toLocaleLowerCase()); _ck(_v, 12, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), "machineRiskScore.title")); _ck(_v, 5, 0, currVal_1); var currVal_4 = i0.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i0.ɵnov(_v.parent.parent, 0), ("machineRiskScore." + _co.machine.riskScore.id))); _ck(_v, 13, 0, currVal_4); }); }
function View_MachineEntityStatusComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "span", [["class", "wcd-padding-small-right"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "wcd-shared-icon", [["class", "small-icon wcd-font-weight-semibold wcd-margin-xsmall-right"]], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i0.ɵprd(512, null, i4.ɵNgClassImpl, i4.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(10, 278528, null, 0, i4.NgClass, [i4.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(11, 573440, null, 0, i6.IconComponent, [i7.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "span", [["class", "wcd-font-weight-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(14, null, ["", ""])), i0.ɵppd(15, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "machines.exposureLevel")); _ck(_v, 3, 0, currVal_0); var currVal_2 = "small-icon wcd-font-weight-semibold wcd-margin-xsmall-right"; var currVal_3 = ("color-text-" + _co.machine.exposureScore.className); _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.machine.exposureScore.icon; _ck(_v, 11, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), "machineExposureLevel.title")); _ck(_v, 5, 0, currVal_1); var currVal_5 = i0.ɵunv(_v, 14, 0, _ck(_v, 15, 0, i0.ɵnov(_v.parent.parent, 0), ("machineExposureScoreType_" + _co.machine.exposureScore.id))); _ck(_v, 14, 0, currVal_5); }); }
function View_MachineEntityStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal wcd-padding-small-top wcd-flex-horizontal-align-items-center"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineEntityStatusComponent_2)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineEntityStatusComponent_3)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine.riskScore; _ck(_v, 3, 0, currVal_0); var currVal_1 = ((_co.withExposureLevel && _co.machine.senseMachineId) && _co.machine.exposureScore); _ck(_v, 6, 0, currVal_1); }, null); }
function View_MachineEntityStatusComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "wcd-margin-small-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "wcd-info-box", [], null, null, null, i8.View_InfoBoxComponent_0, i8.RenderType_InfoBoxComponent)), i0.ɵdid(4, 49152, null, 0, i9.InfoBoxComponent, [], null, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 0, 3, "markdown", [], null, null, null, i10.View_MarkdownComponent_0, i10.RenderType_MarkdownComponent)), i0.ɵdid(7, 4767744, null, 0, i11.MarkdownComponent, [i0.ElementRef, i11.MarkdownService], null, null), (_l()(), i0.ɵted(8, 0, [" ", ""])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), "machines.notOnboardedCta")); _ck(_v, 8, 0, currVal_0); }); }
export function View_MachineEntityStatusComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i12.I18nPipe, [i13.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineEntityStatusComponent_1)), i0.ɵdid(3, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["notOnboarded", 2]], null, 0, null, View_MachineEntityStatusComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine.senseMachineId; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_MachineEntityStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MachineEntityStatusComponent_0, RenderType_MachineEntityStatusComponent)), i0.ɵdid(1, 49152, null, 0, i14.MachineEntityStatusComponent, [i15.FlavorService], null, null)], null, null); }
var MachineEntityStatusComponentNgFactory = i0.ɵccf("ng-component", i14.MachineEntityStatusComponent, View_MachineEntityStatusComponent_Host_0, { entity: "entity" }, {}, []);
export { MachineEntityStatusComponentNgFactory as MachineEntityStatusComponentNgFactory };
