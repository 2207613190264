import { Injectable } from '@angular/core';
import { DownloadService } from '../../shared/services/download.service';
import { FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot } from '@angular/router';
import { CmsEntity, CmsLinkedReportDownloadUrlApiCall, CmsLinkedReportUploadApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';

export function getCmsLegacyUrlFromNewUrlRoute(route: ActivatedRouteSnapshot): ReadonlyArray<string> {
	const entity = route.url[0].path;
	const id = route.url[1] && route.url[1].path;
	return ['cms', entity === 'actors' ? 'threatintel' : 'outbreak', ...(id ? ['edit', id] : [])];
}

export function getCmsNewUrlFromLegacyUrlState($state): ReadonlyArray<string> {
	const isOutbreak = /outbreak/.test($state.current.name);
	return [
		'cms2',
		isOutbreak ? 'outbreaks' : 'actors',
		...($state.params && $state.params.id ? [$state.params.id] : []),
	];
}

@Injectable()
export class CmsService {
	constructor(
		private readonly paris: Paris,
		private readonly downloadService: DownloadService,
	) {}

	createEntity(form: FormGroup): CmsEntity {
		return {
			...form.value,
			updatedAt: undefined,
			sections: form.value.sections.map((section, index) => ({
				...section,
				content: section.content,
				order: index + 1,
				$parent: undefined,
			})),
		};
	}

	exportData(form: FormGroup) {
		const value = this.createEntity(form);
		const fileName = `${value.name}_Overview.json`;
		const blob = new Blob([JSON.stringify(value, null, '\t')], { type: 'application/json' });
		this.downloadService.download(of(blob), fileName);
	}

	// gets a link to private blob containing actor report and an error message to log if not succeeded.
	// address server to get link with access to the private blob and start download.
	downloadReportUsingSasToken(linkToReport): Observable<any> {
		return this.paris.apiCall(CmsLinkedReportDownloadUrlApiCall, linkToReport).pipe(
			tap((link: string) => {
				// start the download of the actor report.
				// will start the download and not open the file since the content type of this blobs is set to application/octet-stream.
				window.location.href = link;
			})
		);
	}

	uploadReport(actorName: string, file: File): Observable<any> {
		return this.paris.apiCall(CmsLinkedReportUploadApiCall, { file, actorName });
	}
}
