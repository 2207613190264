/* tslint:disable:template-click-events-have-key-events */
import { SoftwareFieldsService } from './../../../../@entities/@tvm/software-inventory/services/software.fields.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Software } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { map, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { zip } from 'rxjs';
import { DataviewField } from '@wcd/dataview';

@Component({
	selector: 'top-vulnerable-software-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './top-vulnerable-software.widget.html',
})
export class TvmTopVulnerableSoftwareWidget extends ReportWidgetComponent<Array<Software>> {
	isThreatActiveTooltip: string;
	noThreatActiveTooltip: string;
	isMgSelected: boolean;
	withTags: boolean;
	fields: DataviewField<Software>[];

	constructor(
		reportsService: ReportsService,
		private i18nService: I18nService,
		softwareFieldsService: SoftwareFieldsService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private router: Router,
		private paris: Paris,
		private tvmTextsService: TvmTextsService
	) {
		super(reportsService);
		this.isThreatActiveTooltip = this.i18nService.get('tvm.software.threatActiveTooltip');
		this.noThreatActiveTooltip = this.i18nService.get('tvm.software.noThreatActiveTooltip');
		this.fields = softwareFieldsService.topVulnerableSoftwareWidgetFields.map(
			field => new DataviewField(Object.assign({}, field, { sort: { enabled: false } }))
		);
	}

	get widgetConfig() {
		return {
			id: 'topVulnerableSoftwareWidget',
			name: this.i18nService.get('tvm.dashboard.topVulnerableSoftware'),
			noDataMessage: () =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.dashboard.noData.topVulnerableSoftware',
					isGroupSelected: this.isMgSelected,
				}),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () => {
				const mg$ = this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));

				// TODO[khaw]: Bring only pagesize=3 without sorting logic after supporting orderby in backend
				const software$ = this.paris
					.query(Software, {
						sortBy: [
							{
								field: 'riskScore',
								direction: DataQuerySortDirection.descending,
							},
						],
						pageSize: 100,
					})
					.pipe(
						map(ds =>
							ds.items
								.sort((a, b) => {
									return b.exposureScoreImprovement - a.exposureScoreImprovement;
								})
								.slice(0, 3)
						)
					);
				return zip(mg$, software$).pipe(
					map(([mg, software]) => {
						this.isMgSelected = mg.isFiltering;
						return software;
					})
				);
			},
			minHeight: '210px',
		};
	}

	openSoftwareVulnerabilities(software: Software): void {
		this.router.navigate(['/software-inventory', encodeURIComponent(software.id)]);
	}

	openSoftwareInventory(): void {
		this.router.navigate(['/software-inventory']);
	}
}
