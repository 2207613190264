<!-- tslint:disable:template-click-events-have-key-events -->
<div class="large-form wcd-full-height">
    <wcd-contents-state [contentState]="contentState" class="wcd-full-height wcd-flex-vertical">
        <ng-container loading>
            <div class="wcd-margin-xxLarge-top center-text">
                <fab-spinner></fab-spinner>
            </div>
        </ng-container>
        <ng-container complete>
            <form *ngIf="editedMachineGroup"
                  #machineGroupForm="ngForm"
                  (submit)="saveMachineGroup()"
                  (keydown.enter)="$event.preventDefault()"
                  data-track-component="MachineGroupEditEnhanced"
                  class="wcd-full-height wcd-flex-vertical">
                <header class="wcd-flex-none wcd-padding-all">
                    <tabs #deviceGroupTabs [tabsData]="tabs"
                          [currentTab]="currentTab.id"
                          (select)="currentTab = $event.tab">
                    </tabs>
                </header>
                <div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
                    <div [hidden]="currentTab.id !== 'details'">
                        <section class="wcd-margin-large-bottom">
                            <div class="wcd-padding-large-bottom">{{'machineGroup.edit.generalTabDescription' | i18n}}</div>
                            <div class="form-group wcd-input-placeholder">
                                <label class="required" for="machine-group-name">{{'machineGroup.edit.groupNameTitle' | i18n}}</label>
                                <input type="text"
                                       id="machine-group-name"
                                       [placeholder]="('machineGroup.edit.namePlaceholder' | i18n)"
                                       [(ngModel)]="editedMachineGroup.name"
                                       name="machine-group-name"
                                       class="form-control"
                                       maxlength="100"
                                       [disabled]="editedMachineGroup.isUnassignedMachineGroup"
                                       [rbac]="rbacControlSettings"
                                       autofocus
                                       required/>
                            </div>
                            <div class="form-group">
                                <label class="required" for="protection-level">{{'machineGroup.edit.automationLevelTitle' | i18n}}</label>
                                <div>
                                    <fancy-select [(ngModel)]="editedMachineGroup.protectionLevel"
                                                  *ngIf="allProtectionLevels"
                                                  listId="protection-level"
                                                  [isBordered]="true"
                                                  [isFullWidth]="true"
                                                  [placeholder]="('machineGroup.edit.selectRemediationLevel' | i18n)"
                                                  [formatLabel]="getProtectionLevelLabel"
                                                  name="protection-level"
                                                  [trackBy]="'id'"
                                                  [required]="true"
                                                  [values]="allProtectionLevels"
                                                  [rbac]="rbacControlSettings">
                                    </fancy-select>
                                </div>
                            </div>
                            <div class="form-group wcd-textarea-placeholder">
                                <label for="machine-group-description">{{'machineGroup.edit.descriptionTitle' | i18n}}</label>
                                <textarea
                                    id="machine-group-description"
                                    class="form-control wcd-full-width"
                                    name="description"
                                    rows="4"
                                    maxlength="1000"
                                    [placeholder]="('machineGroup.edit.descriptionPlaceholder' | i18n)"
                                    [disabled]="editedMachineGroup.isUnassignedMachineGroup"
                                    [(ngModel)]="editedMachineGroup.description"
                                    [rbac]="rbacControlSettings"></textarea>
                            </div>
                        </section>
                    </div>
                    <div [hidden]="currentTab.id !== 'members'">
                        <section class="wcd-margin-large-bottom wcd-input-placeholder">
                            <ng-container *ngIf="!editedMachineGroup.isUnassignedMachineGroup; else editRulesNotAllowed">
                                <div class="wcd-padding-large-bottom">{{'machineGroup.edit.configureAssociationRuleDescription' | i18n}}</div>
                                <table class="wcd-table small-padding wcd-full-width" *ngIf="editedMachineGroup.rules?.length">
                                    <thead>
                                    <th>{{'machineGroup_edit_ruleTableHeader_andOr' | i18n}}</th>
                                    <th>{{'machineGroup_edit_ruleTableHeader_condition' | i18n}}</th>
                                    <th>{{'machineGroup_edit_ruleTableHeader_operator' | i18n}}</th>
                                    <th>{{'machineGroup_edit_ruleTableHeader_value' | i18n}}</th>
                                    <th class="wcd-width-xxs-medium"></th>
                                    <th class="wcd-width-xxs-medium"></th>
                                    </thead>
                                    <tbody>
                                    <ng-container *ngFor="let property of properties; let isFirstProperty = first">
                                        <tr *ngFor="let rule of this.getPropertyNonDeletedRules(property.property); let index = index; let isFirstRuleWithinProperty = first; let islast = last"
                                            [class]="!islast ? 'noBorder' : ''">
                                            <td class="nowrap">
                                                {{(isFirstRuleWithinProperty && !isFirstProperty) ? 'And' : !isFirstRuleWithinProperty ? 'Or': ''}}
                                            </td>
                                            <td class="nowrap">
                                                {{rule.property.nameKey | i18n}}
                                            </td>
                                            <td class="wcd-width-medium-small">
                                                <ng-container *ngIf="rule.property.valuesEntity; else operatorDropdown">{{'machineGroup_rule_operator_in' | i18n}}</ng-container>
                                                <ng-template #operatorDropdown>
                                                    <fancy-select [(ngModel)]="editedMachineGroup.rules[rule.correlatedIndex].operator"
                                                                  [isBordered]="true"
                                                                  labelClass="dropdown-width-medium-small"
                                                                  [formatLabel]="getSelectableOperatorLabel"
                                                                  [name]="'operator_' + rule.correlatedIndex"
                                                                  [trackBy]="'id'"
                                                                  [required]="!editedMachineGroup.isUnassignedMachineGroup"
                                                                  [values]="selectableOperators"
                                                                  [rbac]="rbacControlSettings">
                                                    </fancy-select>
                                                </ng-template>
                                            </td>
                                            <td [ngSwitch]="rule.property.property"
                                                class="wcd-width-medium-small"
												[rbac]="rbacControlSettings">
                                                <wcd-checklist-dropdown
                                                    *ngSwitchCase="5"
                                                    [values]="deviceTypeOptions"
                                                    placeholder="Select..."
                                                    id="deviceType-machineGroupEdit"
                                                    class="dropdown-framed"
                                                    [name]="'rule_' + rule.correlatedIndex"
                                                    labelClass="dropdown-width-medium-small"
                                                    [buttonText]="deviceTypeButtonText"
                                                    [(ngModel)]="selectedDeviceTypeValues"
                                                    (ngModelChange)="setDeviceTypeValueOnChange(editedMachineGroup.rules[rule.correlatedIndex])"
                                                    [isDisabled]="!hasEditPermissions">
                                                </wcd-checklist-dropdown>
                                                <wcd-checklist-dropdown
                                                    *ngSwitchCase="4"
                                                    [values]="osPlatformOptions"
                                                    placeholder="Select..."
                                                    id="osVersion-machineGroupEdit"
                                                    class="dropdown-framed"
                                                    [name]="'rule_' + rule.correlatedIndex"
                                                    labelClass="dropdown-width-medium-small"
                                                    [buttonText]="osPlatformButtonText"
                                                    [(ngModel)]="selectedOsPlatformValues"
                                                    (ngModelChange)="setOsPropertyValueOnChange(editedMachineGroup.rules[rule.correlatedIndex])"
                                                    [isDisabled]="!hasEditPermissions">
                                                </wcd-checklist-dropdown>
                                                <input *ngSwitchDefault
                                                       type="text"
                                                       placeholder="{{'common_value' | i18n }}"
                                                       [attr.aria-label]="i18nService.strings.common_value"
                                                       class="form-control"
                                                       [(ngModel)]="editedMachineGroup.rules[rule.correlatedIndex].propertyValue"
                                                       [name]="'rule_' + rule.correlatedIndex"
                                                       [rbac]="rbacControlSettings" />
                                            </td>
                                            <td class="nowrap">
                                                <fab-icon-button *ngIf="!rule.property.valuesEntity && islast && index < 9"
                                                                 [iconProps]="{ iconName: 'Add' }"
                                                                 (click)="this.onAddRuleClick(property)"
                                                >
                                                </fab-icon-button>
                                            </td>
                                            <td class="nowrap">
                                                <fab-icon-button *ngIf="!rule.property.valuesEntity && !isFirstRuleWithinProperty"
                                                                 [iconProps]="{ iconName: 'Cancel' }"
                                                                 (click)="this.onRemoveRuleClick(rule)"
                                                >
                                                </fab-icon-button>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    </tbody>
                                </table>
                            </ng-container>
                            <ng-template #editRulesNotAllowed>
                                <p>{{'machineGroup.edit.ruleEditNotAllowed' | i18n}}</p>
                            </ng-template>
                        </section>
                    </div>
                    <div [hidden]="currentTab.id !== 'preview_members'">
                        <section *ngIf="!editedMachineGroup.isUnassignedMachineGroup" class="form-section wcd-padding-small-left">
                            <div class="wcd-padding-large-bottom">
                                <fab-message-bar>
                                    {{'machineGroup.edit.previewDescription' | i18n}}
                                </fab-message-bar>
                            </div>
                            <button type="button"
                                    class="btn-icon"
                                    data-track-id="PreviewMachineGroupMachines"
                                    data-track-type="Button"
                                    (click)="previewResults()"
                                    [disabled]="isLoadingPreviewResults || isSaving || !hasRules"
                                    [rbac]="rbacControlSettings">
                                <i class="loader-icon" [hidden]="!isLoadingPreviewResults"></i>
                                <wcd-shared-icon iconName="eye" [hidden]="isLoadingPreviewResults" class="small-icon"> </wcd-shared-icon >
                                {{(isLoadingPreviewResults ? 'machineGroup.edit.checkingMatches' : 'machineGroup.edit.showPreview') | i18n}}
                            </button>
                            <div class="error-message" *ngIf="previewError">{{'machineGroup.edit.previewError' | i18n}}</div>
                            <div *ngIf="previewedMachines" class="wcd-margin-top">
                                <div *ngIf="previewedMachines.length; else noPreviewResults">
                                    <div class="wcd-font-weight-bold wcd-padding-large-bottom">{{'machineGroup.edit.deviceName' | i18n}}</div>
                                    <ul class="unstyled">
                                        <li *ngFor="let previewMachine of previewedMachines" class="wcd-padding-bottom wcd-margin-bottom wcd-border-bottom-light">
                                            <wcd-shared-icon iconName="System" class="small-icon"> </wcd-shared-icon >
                                            {{previewMachine.domain}}\{{previewMachine.name}}
                                        </li>
                                    </ul>
                                </div>
                                <ng-template #noPreviewResults>
                                    <div class="subtle">{{ 'machineGroup.edit.noMachinesMatched' | i18n }}</div>
                                </ng-template>
                            </div>
                        </section>
                    </div>
                    <div [hidden]="currentTab.id !== 'assignment'" class="wcd-flex-vertical wcd-full-height">
                        <h4>{{'machineGroup.edit.exposeToGroups' | i18n}}</h4>
						<ng-container *ngIf="shouldShowAadGroupsWithAssignedRoles; else showAllAadGroups">
							<p class="form-section-description">
								<markdown [data]="'machineGroup.edit.exposeToGroupsDescription' | i18n"></markdown>
								<wcd-shared-icon iconName="Info" class="ms-icon-top-2-fix"> </wcd-shared-icon >
								{{'machineGroup.edit.globalAdminAccess' | i18n}}
							</p>
							<div *ngIf="isLoadingUserRoles; else loadedUserRoles"
								 class="wcd-full-height wcd-flex-center-all">
								<div>
									<i class="loader-icon"></i> Loading roles...
								</div>
							</div>
							<ng-template #loadedUserRoles>
								<multi-select
									*ngIf="selectedAadGroups?.length || availableAadGroups?.length; else noAadGroups"
									class="wcd-flex-vertical wcd-full-height multi-select"
									selectedDescription="machineGroup.selectedDescription"
									[selected]="selectedAadGroups"
									[available]="filteredAvailableAadGroups"
									[requiredAllowedActions]="['systemSettings']"
									[(chosenFromSelected)]="chosenSelectedAadGroups"
									[(chosenFromAvailable)]="chosenAvailableAadGroups"
									[canEdit]="hasEditPermissions"
									removeButtonText="userRoles.removeSelectedGroups"
									addButtonText="userRoles.addSelectedGroups"
									filterPlaceholder="userRoles.filterUserGroups"
									noSelectedItemsPlaceholder="machineGroup.noSelected"
									[virtualScrollAvailableItems] = "true"
									notFoundText="userRoles.emptyAadGroups"
									notFoundForFilterText="userRoles.emptyAadGroupsForFilter"
									(onRemoveChosen)="removeSelectedGroups()"
									(onAddChosen)="addSelectedGroups()"
									(onSetAvailableItems)="setFilterAndApply($event)">
								</multi-select>
								<ng-template #noAadGroups>
									<div class="wcd-padding-vertical color-text-gray-400">
										{{'machineGroup_edit_noRoles' | i18n}}&nbsp;<a
										(click)="goToUserRolesWithConfirm()">{{'machineGroup_edit_goToRoles' | i18n}}</a>
									</div>
								</ng-template>
							</ng-template>
						</ng-container>
						<ng-template #showAllAadGroups>
							<aad-groups-assignment-tab
								class="wcd-full-height"
								[hostRbacComponentType]="RbacComponentType.DeviceGroup"
								isGlobalAdmin="true"
								[assignedAadGroups]="selectedAadGroups"
								(applySelectedAadGroups)="applySelectedAadGroupsWithoutRoleAssignmnet($event)">
							</aad-groups-assignment-tab>
                        </ng-template>
                    </div>
                </div>
                <div class="wcd-flex-none wcd-flex-horizontal wcd-padding-all">
                    <div class="wcd-flex-1">
                        <button
							type="button"
							class="btn btn-secondary color-box-gray-200"
                            [disabled]="currentTab === tabs[0]"
                            (click)="onBack(); deviceGroupTabs.selectTab(currentTab)">
                            {{ 'buttons.back' | i18n}}
                        </button>
                        <button
							type="button"
							class="btn btn-primary color-box-primary"
                            *ngIf="currentTab.data < tabs.length-1"
                            (click)="onNext(); deviceGroupTabs.selectTab(currentTab)">
                            {{ 'buttons.next' | i18n}}
                        </button>
                        <button
							type="button"
							class="btn btn-primary color-box-primary"
                            *ngIf="currentTab.data === tabs.length-1"
                            data-track-id="Save"
                            data-track-type="Button"
                            [disabled]="machineGroupForm.form.pristine || !machineGroupForm.form.valid || isSaving || !isValid || isLoadingUserRoles"
                            type="submit">
                            <i class="loader-icon" [hidden]="!isSaving"></i>
                            {{ (isSaving ? 'buttons.saving' : 'buttons.done') | i18n}}
                        </button>
                    </div>
                    <div class="wcd-flex-none">
                        <button
							type="button"
							class="btn btn-cancel color-box-gray-200"
                            data-track-id="Cancel"
                            data-track-type="Button"
                            (click)="cancel.emit()">
                            {{'buttons.cancel' | i18n}}
                        </button>
                    </div>
                </div>
            </form>
        </ng-container>
    </wcd-contents-state>
</div>
