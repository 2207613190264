import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RecommendationExceptionStateValue } from './recommendation-exception-state-value.enum';

@ValueObject({
	singularName: 'Exception status',
	pluralName: 'Exceptions status',
})
export class RecommendationExceptionStatus extends ModelBase {
	@EntityField({
		data: 'value',
	})
	readonly value: RecommendationExceptionStateValue;

	@EntityField({
		data: 'lastModifiedOn',
	})
	readonly lastModifiedOn?: Date;
}
