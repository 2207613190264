import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Compliance alerts share status',
	pluralName: 'Compliance alerts share status',
	endpoint: 'compliance/alertSharing/status',
	parseItemQuery: () => 'compliance/alertSharing/status',
	saveMethod: 'POST',
	serializeItem: (item: ComplianceAlertsShareStatus, modelData) => {
		return item.complianceAlertsShareEnabled;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.wdatpInternalApi,
	readonly: true,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class ComplianceAlertsShareStatus extends EntityModelBase {
	@EntityField({
		parse: (value, data) => data === true,
		defaultValue: false,
	})
	complianceAlertsShareEnabled: boolean;

	failed: boolean = false;
}
