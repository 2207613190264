import { resetToUTC, shiftTime } from '../date-utils';
import {TzDateService} from "../services/tz-date.service";

export type SerializedDateRange = string;

export class DateRangeModel {
	private _from: Date;
	private _to: Date;
	private readonly _isForcedFullDays: boolean;

	constructor(private tzDateService: TzDateService,from?: Date, to?: Date, forceFullDays: boolean = true) {
		if (from && to && from > to) {
			const _to = to;
			to = from;
			from = _to;
		}
		this._isForcedFullDays = forceFullDays;
		this._from = from && (forceFullDays ? DateRangeModel.startOfDay(from) : new Date(from.valueOf()));
		this._to = to && (forceFullDays ? DateRangeModel.endOfDay(to) : new Date(to.valueOf()));

		this.checkRange();
	}

	get from() {
		return this._from;
	}

	set from(value: Date) {
		this._from = value;
		this.checkRange(true);
	}

	get to() {
		return this._to;
	}

	set to(value: Date) {
		this._to = value;
		this.checkRange();
	}

	get hasValue(): boolean {
		return !!(this.from || this.to);
	}

	static fromString(tzDateService: TzDateService, str: SerializedDateRange, forceFullDays: boolean = true): DateRangeModel {
		if (!str || typeof str !== 'string') return null;

		const rangeMatch = str.match(/^(\d+)?:(\d+)?$/);
		if (rangeMatch) {
			const [_, rawFrom, rawTo] = rangeMatch;
			return new DateRangeModel(tzDateService,
				rawFrom && new Date(parseInt(rawFrom, 10)),
				rawTo && new Date(parseInt(rawTo, 10)),
				forceFullDays
			);
		}

		return null;
	}

	static startOfDay(date: Date): Date {
		const newDate = new Date(date.valueOf());
		newDate.setHours(0);
		newDate.setMinutes(0);
		newDate.setSeconds(0);
		newDate.setMilliseconds(0);
		return newDate;
	}

	static endOfDay(date: Date): Date {
		const newDate = DateRangeModel.startOfDay(date);
		newDate.setDate(date.getDate() + 1);
		newDate.setMilliseconds(-1);
		return newDate;
	}

	static fromDays(
		tzDateService: TzDateService,
		days: number,
		startDate: Date = new Date(),
		goBackwards: boolean = true,
		forceFullDays: boolean = true
	): DateRangeModel {
		const daysDelta = days * (goBackwards ? -1 : 1);
		const millisecondDelta = daysDelta * 24 * 60 * 60 * 1000;
		const otherDate = new Date(startDate.valueOf() + millisecondDelta);
		return new DateRangeModel(tzDateService, startDate, otherDate, forceFullDays);
	}

	clone(): DateRangeModel {
		return new DateRangeModel(this.tzDateService, this.from, this.to, this._isForcedFullDays);
	}

	toString(): SerializedDateRange {
		if (!this.from && !this.to) return '';

		const fromStr = this.from ? this.from.valueOf() : '';
		const toStr = this.to ? this.to.valueOf() : '';

		return [fromStr, toStr].join(':');
	}

	format(format: string, locale: string): string {
		if (!this._from && !this._to) return '';
		return `${this.tzDateService.format(this._from, format, locale)} -
				${this.tzDateService.format(this._to, format, locale)}`;
	}

	shiftTime(milliseconds: number) {
		shiftTime(this.from, { type: 'milliseconds', amount: milliseconds });
		shiftTime(this.to, { type: 'milliseconds', amount: milliseconds });
	}

	shiftTimeByHours(hours: number) {
		shiftTime(this.from, { type: 'hours', amount: hours });
		shiftTime(this.to, { type: 'hours', amount: hours });
	}

	resetToUTC() {
		resetToUTC(this.from);
		resetToUTC(this.to);
	}

	private checkRange(changeTo?) {
		if (this._from && this._to && this._from >= this._to) {
			if (changeTo) this._to = DateRangeModel.endOfDay(this._from);
			else this._from = DateRangeModel.startOfDay(this._to);
		}
	}
}
