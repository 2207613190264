import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { EvaluationStep } from './evaluation-step.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Evaluation steps',
	endpoint: 'evaluation/steps',
	method: 'GET',
	type: EvaluationStep,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetEvaluationStepsApiCall extends ApiCallModel<Array<EvaluationStep>> {}
