var TimeRanges = /** @class */ (function () {
    function TimeRanges(ranges) {
        this.ranges = ranges || [];
    }
    /**
     * Returns the total time of all ranges, in milliseconds.
     * Takes into account parallel ranges
     */
    TimeRanges.prototype.getTotalTime = function () {
        var reducedTimeRanges = this.flatten();
        return reducedTimeRanges.ranges.reduce(function (totalTime, range) {
            return totalTime + range.duration;
        }, 0);
    };
    TimeRanges.prototype.flatten = function () {
        var sortedRanges = this.ranges.slice().sort(function (a, b) {
            var aVal = a.start.valueOf(), bVal = b.start.valueOf();
            if (aVal === bVal) {
                return 0;
            }
            return aVal > bVal ? 1 : -1;
        });
        var now = new Date();
        return new TimeRanges(sortedRanges.reduce(function (reducedTimeRanges, range) {
            var previousRange = reducedTimeRanges.length && reducedTimeRanges[reducedTimeRanges.length - 1], previousRangeEnd = previousRange && previousRange.end;
            range.end = range.end || now;
            if (range.end >= (previousRangeEnd || 0)) {
                if (range.start < previousRangeEnd) {
                    range.start = previousRangeEnd;
                }
                reducedTimeRanges.push(range);
            }
            return reducedTimeRanges;
        }, []));
    };
    return TimeRanges;
}());
export { TimeRanges };
var TimeRange = /** @class */ (function () {
    function TimeRange(start, end) {
        this.start = start;
        this.end = end;
    }
    Object.defineProperty(TimeRange.prototype, "duration", {
        get: function () {
            return (this.end || new Date()).valueOf() - this.start.valueOf();
        },
        enumerable: true,
        configurable: true
    });
    return TimeRange;
}());
export { TimeRange };
