import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { NetworkFiltersFields, NetworkFiltersFieldsApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { map } from 'rxjs/operators';

@Injectable()
export class NetworkDevicesService {
	private _networkDevicesFilter: Record<string, any>;

	constructor(private i18nService: I18nService, private paris: Paris) {
		if (!this._networkDevicesFilter) {
			this._networkDevicesFilter = {
				status: {
					values: [
						{
							value: 'Active',
							name: this.i18nService.get('tvm_network_devices_filters_active'),
						},
						{
							value: 'Inactive',
							name: this.i18nService.get('tvm_network_devices_filters_not_active'),
						},
					],
				},
				exposureLevel: {
					values: [
						{
							value: 'High',
							name: this.i18nService.get('tvm_network_devices_filters_high'),
							priority: 1,
						},
						{
							value: 'Medium',
							name: this.i18nService.get('tvm_network_devices_filters_medium'),
							priority: 2,
						},
						{
							value: 'Low',
							name: this.i18nService.get('tvm_network_devices_filters_low'),
							priority: 3,
						},
					],
				},
			};
		}
	}

	getFiltersData(): Observable<Record<string, any>> {
		return this.paris
			.apiCall<NetworkFiltersFields[]>(NetworkFiltersFieldsApiCall)
			.pipe(map(filtersFields => {
				const dynamicFilters : Record<string, any> = {}
					filtersFields.forEach(filterField => {
						dynamicFilters[filterField.name] = {
							values: Object.values(filterField.filters).map(filter => {
								return {
									value: filter.name,
									name: filter.name,
									// TODO[KHAW]: BUG 28567310 Fix filter in filter
										// children: filter.children.map(child => ({
										// 			value: child,
										// 			name: child,
										// })),
									};
								}),
							};
						})
						return { ...dynamicFilters, ...this._networkDevicesFilter}
					}));
		}
}
