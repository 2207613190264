import { VulnerabilityChangeEventType, SeverityValue } from '@wcd/domain';
import { EmailNotificationFormData } from '../../../../email-notifications/components/email-notification-model';

export class VulnerabilityNotificationRuleFormData extends EmailNotificationFormData {
	severityLevelForNewCve: SeverityValue;
	eventTypes: Map<VulnerabilityChangeEventType, boolean>;
	isEnabled: boolean;
	eTag: string;

	constructor(
		vulnerabilityNotificationRuleFormData = {
			id: undefined,
			name: '',
			description: '',
			recipients: [],
			severityLevelForNewCve: undefined,
			eventTypes: undefined,
			machineGroups: [],
			allRbacGroups: false,
			isEnabled: true,
			includeOrgName: true,
			eTag: '',
		}
	) {
		super(vulnerabilityNotificationRuleFormData);
		this.severityLevelForNewCve = vulnerabilityNotificationRuleFormData.severityLevelForNewCve;
		const eventTypesInitialState = new Map<VulnerabilityChangeEventType,boolean>();
		Object.keys(VulnerabilityChangeEventType).forEach(e => eventTypesInitialState[e] = false);
		this.eventTypes = vulnerabilityNotificationRuleFormData.eventTypes ? vulnerabilityNotificationRuleFormData.eventTypes : eventTypesInitialState;
		this.isEnabled = vulnerabilityNotificationRuleFormData.isEnabled;
		this.eTag = vulnerabilityNotificationRuleFormData.eTag;
	}
}
