
		<div *ngIf="entities" [ngClass]="className">
			<entity-name
				*ngFor="let entity of entities"
				[entity]="entity.entity"
				[entityTypeId]="entity.entityTypeId"
				[entityName]="entity.entityName"
				[tooltip]="entity.tooltip"
				[hideIcon]="hideIcon"
				[ngClass]="entityNameClass"
				[linkClass]="entity.linkClass"
			>
			</entity-name>
		</div>
	