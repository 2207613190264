var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase, queryToHttpOptions, } from '@microsoft/paris';
import { CustomTiIndicatorActionsTypes } from '../custom_ti_indicators/custom-ti-indicators-actions-type';
var ɵ0 = function (item, config) { return (item.id ? 'PUT' : 'POST'); }, ɵ1 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ2 = function (item, entity, config, options) {
    return 'webcategory/policy';
}, ɵ3 = function (query) {
    if (!query)
        return {};
    if (query.where && query.where['blockedCategories']) {
        var params_1 = {};
        Object.keys(query.where)
            .filter(function (key) { return key !== 'blockedCategories'; })
            .forEach(function (key) {
            params_1[key] = query.where[key];
        });
        params_1['IndicatorValue'] = query.where['blockedCategories'];
        params_1['Action'] = CustomTiIndicatorActionsTypes.AlertAndBlock;
        return params_1;
    }
    return queryToHttpOptions(query).params;
};
var WebContentFilteringPolicy = /** @class */ (function (_super) {
    __extends(WebContentFilteringPolicy, _super);
    function WebContentFilteringPolicy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'PolicyName' }),
        __metadata("design:type", String)
    ], WebContentFilteringPolicy.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'PolicyName' }),
        __metadata("design:type", String)
    ], WebContentFilteringPolicy.prototype, "policyName", void 0);
    __decorate([
        EntityField({ data: 'BlockedCategoryIds' }),
        __metadata("design:type", Array)
    ], WebContentFilteringPolicy.prototype, "blockedCategoryIds", void 0);
    __decorate([
        EntityField({ data: 'AuditCategoryIds' }),
        __metadata("design:type", Array)
    ], WebContentFilteringPolicy.prototype, "auditCategoryIds", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupIds' }),
        __metadata("design:type", Array)
    ], WebContentFilteringPolicy.prototype, "rbacGroupIds", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy' }),
        __metadata("design:type", String)
    ], WebContentFilteringPolicy.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'LastUpdateTime' }),
        __metadata("design:type", Date)
    ], WebContentFilteringPolicy.prototype, "lastUpdateTime", void 0);
    __decorate([
        EntityField({ data: 'IndicatorValueIdMappings' }),
        __metadata("design:type", Object)
    ], WebContentFilteringPolicy.prototype, "indicatorValueIdMappings", void 0);
    WebContentFilteringPolicy = __decorate([
        Entity({
            singularName: 'Policy',
            pluralName: 'Policies',
            endpoint: 'webcategory/policies',
            saveMethod: ɵ0,
            loadAll: true,
            baseUrl: ɵ1,
            parseSaveQuery: ɵ2,
            parseDataQuery: ɵ3,
            allItemsEndpointTrailingSlash: false,
            separateArrayParams: true,
        })
    ], WebContentFilteringPolicy);
    return WebContentFilteringPolicy;
}(EntityModelBase));
export { WebContentFilteringPolicy };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
