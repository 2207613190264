import { IAppFlavorConfig } from '../models';
import { AlertsConfig } from './alerts.config';
import { DevicesConfig } from './devices.config';
import { RoutesConfig } from './routes.config';
import { ThreatAnalyticsConfig } from './threat-analytics.config';
import { SearchConfig } from './search.config';
import { ReportsConfig } from './reports.config';
import { IncidentsConfig } from './incidents.config';
import { AlertsQueueConfig } from './alert-queue.config';
import { ThreatExpertConfig } from './threat-expert.config';
import { SettingsConfig } from './settings.config';
import { ActionCenterConfig } from './action-center.config';
import { SimulationsAndTutorialsConfig } from './simulations-and-tutorials.config';
import {EntitiesConfig} from "./entities.config";

export const AppFlavorConfig: IAppFlavorConfig = {
	devices: DevicesConfig,
	alerts: AlertsConfig,
	search: SearchConfig,
	routes: RoutesConfig,
	threatAnalytics: ThreatAnalyticsConfig,
	alertsQueue: AlertsQueueConfig,
	entities: EntitiesConfig,
	reports: ReportsConfig,
	incidents: IncidentsConfig,
	threatExpert: ThreatExpertConfig,
	settings: SettingsConfig,
	actionCenter: ActionCenterConfig,
	simulationsAndTutorials: SimulationsAndTutorialsConfig,
};
