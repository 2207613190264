import { SevilleModule } from '../../../../../app/seville/seville.module';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.factory('seville.settings.connectorsFactory', settingsConnectorsFactory);

settingsConnectorsFactory.$inject = ['$log', '$window', '$http', 'authenticationService'];

function settingsConnectorsFactory($log, $window, $http, authenticationService) {
	function openPopup(connectorType, urlNavigate, title, popUpWidth, popUpHeight) {
		try {
			var winLeft = window.screenLeft ? window.screenLeft : window.screenX;
			var winTop = window.screenTop ? window.screenTop : window.screenY;
			var width =
				window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
			var height =
				window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
			var left = width / 2 - popUpWidth / 2 + winLeft;
			var top = height / 2 - popUpHeight / 2 + winTop;

			var popupWindow = window.open(
				urlNavigate,
				title,
				'width=' + popUpWidth + ', height=' + popUpHeight + ', top=' + top + ', left=' + left
			);
			if (popupWindow.focus) {
				popupWindow.focus();
			}

			return popupWindow;
		} catch (e) {
			$log.error(connectorType + '- Error opening popup, ' + e.message);
			return null;
		}
	}

	function isSuccessfullCall(status) {
		return status >= 200 && status <= 299;
	}

	function generateApplication(connectorType, baseUrl, authCode) {
		var data = {
			ConnectorType: connectorType,
			Code: authCode,
		};

		return $http
			.post(baseUrl + '/CreateThirdPartyApplication', data, { timeout: 30000 })
			.then(function(successResponse) {
				if (isSuccessfullCall(successResponse.status)) {
					var appDetails = {
						appUri: successResponse.data.ApplicationUri,
						clientId: successResponse.data.ClientId,
						clientSecret: successResponse.data.ClientSecret,
						authZServerUrl: successResponse.data.AuthorizationServerUrl,
						resource: successResponse.data.Resource,
					};

					return appDetails;
				} else {
					throw 'err';
				}
			});
	}

	function handleGetAppDetailsError(connectorType, response) {
		if (response.status !== 404) {
			$log.error(
				connectorType +
					' - GetCreatedApplicationDetails() call has failed. msg: ' +
					response.message +
					', status code: ' +
					response.status
			);
			throw 'error';
		}

		return null;
	}

	function getCreatedAppDetails(connectorType, baseUrl) {
		var parameters = { connectorType: connectorType };

		return $http
			.get(baseUrl + '/GetCreatedApplicationDetails', {
				params: parameters,
				timeout: 30000,
			})
			.then(
				function(successResponse) {
					if (!isSuccessfullCall(successResponse.status)) {
						return handleGetAppDetailsError(connectorType, successResponse);
					}

					var appDetails = {
						appUri: successResponse.data.ApplicationUri,
						clientId: successResponse.data.ClientId,
						clientSecret: successResponse.data.ClientSecret,
						authZServerUrl: successResponse.data.AuthorizationServerUrl,
						resource: successResponse.data.Resource,
					};

					return appDetails;
				},
				function(errorResponse) {
					return handleGetAppDetailsError(connectorType, errorResponse);
				}
			);
	}

	function getAuthUrl(connectorType, baseUrl) {
		var parameters = {
			connectorType: connectorType,
			baseAddress: window.location.origin,
			// baseAddress: window.location.origin + "/" // use this for local debugging
		};

		return $http
			.get(baseUrl + '/GetAuthCodeFlowRequestUrl', {
				params: parameters,
				timeout: 30000,
			})
			.then(
				function(successResponse) {
					if (!isSuccessfullCall(successResponse.status)) {
						$log.error(
							connectorType + ' - Failed getting auth urls. status: ' + successResponse.status
						);
						throw 'error';
					} else {
						return successResponse.data;
					}
				},
				function(errorResponse) {
					$log.error(connectorType + ' - Failed getting auth url. status: ' + errorResponse.status);
				}
			);
	}

	function generateTokensFromAuthCode(connectorType, baseUrl, clientSecret, authCode) {
		var data = {
			ConnectorType: connectorType,
			Code: authCode,
			ClientSecret: clientSecret,
		};

		return $http
			.post(baseUrl + '/AcquireSampleTokens', data, { timeout: 30000 })
			.then(function(successResponse) {
				if (isSuccessfullCall(successResponse.status)) {
					var tokens = {
						accessToken: successResponse.data.AccessToken,
						refreshToken: successResponse.data.RefreshToken,
					};

					return tokens;
				} else {
					throw successResponse;
				}
			});
	}

	function adjustAuthUrl(authUrl, clientId) {
		// replace the original Client Id (from seville app) to the newly generated client app
		var startIndex = authUrl.indexOf('client_id=');
		var endIndex = authUrl.indexOf('&', startIndex + 1);
		return authUrl.substr(0, startIndex + 'client_id='.length) + clientId + authUrl.substr(endIndex);
	}

	function saveDetailsToFile(connectorType, baseUrl, clientSecret) {
		return $http
			.get(baseUrl + '/GetConnectorFileContext', { timeout: 30000 })
			.then(
				function(successResponse) {
					if (!isSuccessfullCall(successResponse.status)) {
						$log.error(
							connectorType +
								' - Failed invoking Api call to GetConnectorFileContext(). status: ' +
								successResponse.status
						);
						throw 'err';
					} else {
						$log.debug(
							connectorType +
								' - Received token from invoking Api call to GetConnectorFileContext().'
						);
						return successResponse.data;
					}
				},
				function(errorResponse) {
					$log.error(
						connectorType +
							' - Failed invoking Api call to GetConnectorFileContext(). status: ' +
							errorResponse.status
					);
				}
			)
			.then(function(token) {
				var url =
					baseUrl +
					'/GetConnectorFile?token=' +
					token +
					'&connectorType=' +
					connectorType +
					'&connectorFileType=AuthenticationProperties';
				if (clientSecret !== null && clientSecret !== '') {
					url += '&clientSecret=' + clientSecret;
				}

				$window.open(url, '_blank');
			});
	}

	var service = {
		isReadOnly: function() {
			return !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.systemSettings]);
		},
		openPopup: openPopup,
		generateApplication: generateApplication,
		getCreatedAppDetails: getCreatedAppDetails,
		getAuthUrl: getAuthUrl,
		generateTokensFromAuthCode: generateTokensFromAuthCode,
		adjustAuthUrl: adjustAuthUrl,
		saveDetailsToFile: saveDetailsToFile,
	};

	return service;
}
