import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { debounce } from 'lodash-es';

const et = new EventTarget();
const ResizeEventName = 'resize';

// store a single resize observer to be used by all cells and future cells, this is because creating ResizeObservers are expensive and one observer can be shared
//@ts-ignore
const resizeObserver = new ResizeObserver((resizeEvent) => {
	et.dispatchEvent(new CustomEvent(ResizeEventName, {detail: resizeEvent}))
});

@Component({
	selector: 'overflow-container',
	templateUrl: './overflow-container.component.html',
	styleUrls: ['./overflow-container.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowContainerComponent<T extends { ariaLabel?: string }> implements OnChanges, OnDestroy {

	/**
	 * Minimum amount of items that should be expanded
	 */
	@Input() minimumExpendedItems = 1;

	/**
	 * Data for the items in the container.
	 */
	@Input() items: ReadonlyArray<T>;

	/**
	 * The container which defines the available width for the items. The overflow component listens to
	 * changes in the elements width.
	 */
	@Input() itemsContainer: Element;

	/**
	 * The render template for each item. The item's data will be passed through context.
	 */
	@Input() itemTemplate: TemplateRef<{itemData: T}>;

	/**
	 * Dynamically create the data for the overflow item, based on which items are visible / hidden.
	 */
	@Input() createOverflowItemData: (visibleItems: T[], hiddenItems: T[]) => T;

	/**
	 * How much horizontal padding the items container has.
	 * Used in calculating the available space for the items.
	 */
	@Input() horizontalPadding = DEFAULT_HORIZONTAL_PADDING;

	/**
	 * Width of the overflow item.
	 * Used in calculating the available space for the rest of the items.
	 */
	@Input() overflowItemWidth = DEFAULT_OVERFLOW_ITEM_WIDTH;

	/**
	 * How much space should be added between items.
	 */
	@Input() itemsHorizontalSpacing = DEFAULT_ITEMS_SPACING;

	@ViewChild('itemsList', { static: true }) itemsListElementRef: ElementRef<HTMLUListElement>;

	visibleItems: T[];
	itemsCount: number;
	initComplete = false; // Fix for when not in SCC, the tags appear cut on first render.
	private _fullyRenderedItemsListWidth: number;
	private readonly _renderedItemsWidths: number[] = [];
	private readonly _onContainerResizeDebounced;

	constructor(private changeDetectionRef: ChangeDetectorRef) {
		this._onContainerResizeDebounced = debounce(this.onContainerResize, 500);
		et.addEventListener(ResizeEventName, this.onResize)
	}


	ngOnChanges(changes: SimpleChanges): void {
		if (this.initComplete || !changes.itemsContainer || !changes.itemsContainer.currentValue) return;
		requestAnimationFrame(() => {
			this.initResizeVars();
			this.onContainerResize();

			resizeObserver.observe(this.itemsContainer);

		})
		this.initComplete = true;
	}

	private onResize = (e: CustomEvent) => {
		if(e.detail.some(resizeEvent => resizeEvent.target === this.itemsContainer)){
			this.changeDetectionRef.detach();
			this._onContainerResizeDebounced();

		}
	}

	private initResizeVars(){
		const itemsListElement = this.itemsListElementRef.nativeElement;
		this.itemsCount = this.items.length;
		this._fullyRenderedItemsListWidth = itemsListElement.clientWidth;
		for (let i = 0; i < this.itemsCount; i++) {
			this._renderedItemsWidths.push(itemsListElement.children[i].clientWidth);
		}
	};

	private onContainerResize(){
		const clientWidth = this.itemsContainer.clientWidth - this.horizontalPadding;
		if (this._fullyRenderedItemsListWidth < clientWidth || this.itemsCount <= this.minimumExpendedItems) {
			this.expandAllItems();
		} else {
			this.overflowItems(clientWidth);
		}
		this.changeDetectionRef.detectChanges();
	};

	private overflowItems(containerWidth: number){
		let visibleItemsWidth = this.overflowItemWidth;
		for (let i = 0; i < this.itemsCount; i++) {
			if ((visibleItemsWidth + this._renderedItemsWidths[i] + this.itemsHorizontalSpacing) > containerWidth) {
				// Will always display at least 1 item
				this.overflowItemsPastIndex(i == 0 ?  this.minimumExpendedItems : i);
				break;
			} else {
				visibleItemsWidth += this._renderedItemsWidths[i] + DEFAULT_ITEMS_SPACING;
			}
		}
	};

	private expandAllItems(){
		this.visibleItems = this.items.slice();
	};

	private overflowItemsPastIndex(firstHiddenItemIndex: number){
		this.visibleItems = this.items.slice(0, firstHiddenItemIndex);
		this.visibleItems.push(this.createOverflowItemData(this.visibleItems, this.items.slice(firstHiddenItemIndex, this.items.length)));
	};

	ngOnDestroy(): void {
		resizeObserver.unobserve(this.itemsContainer);
		et.removeEventListener(ResizeEventName, this.onResize)
	}

}

const DEFAULT_OVERFLOW_ITEM_WIDTH = 32;
const DEFAULT_HORIZONTAL_PADDING = 32;
const DEFAULT_ITEMS_SPACING = 4;
