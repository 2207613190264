import { FabButtonModule, FabIconModule, FabMessageBarModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { ContentsStateModule } from '@wcd/contents-state';
import { DataTableModule } from '@wcd/datatable';
import { TooltipsModule } from '@wcd/dialogs';
import { ExpanderModule } from '@wcd/expander';
import { WcdFormsModule } from '@wcd/forms';
import { PrettifyModule } from '@wcd/prettify';
import { LocalizationModule } from '@wcd/localization';
import { PaginationModule } from '@wcd/pagination';
import { MarkdownModule, MarkdownService, MarkedOptions } from 'ngx-markdown';
import { DialogsModule } from '../dialogs/dialogs.module';
import { HexaditeFormsModule } from '../forms/forms.module';
import { I18nModule } from '@wcd/i18n';
import { NotificationsModule } from '../notifications/notifications.module';
import { RbacModule } from '../rbac/rbac.module';
import { DistributionModule } from './modules/distribution/distribution.module';
import { ActiveDirectoryPathComponent } from './components/active-directory-path.component';
import { WsdcCardComponent } from './components/card/card.component';
import { CommandBarModule } from './components/command-bar/command-bar.module';
import { CopyToClipboardComponent } from './components/copy-to-clipboard.component';
import { ColoredDotIndicationFieldComponent } from './components/colored-dot-indication-field.component';
import { CopyableInputComponent } from './components/copyable-input/copyable-input.component';
import { ValueWithExternalLinkComponent } from './components/value-with-external-link.component';
import { FileCertificateInfoComponent } from './components/file-certificate-info.component';
import { EventsSummaryBarComponent } from './components/events-summary-bar/events-summary-bar.component';
import { ExternalImageComponent } from './components/external-image.component';
import { FieldValuesComponent } from './components/field-values.component';
import { GenericObjectComponent } from './components/generic-object.component';
import { HistorySafeRedirectComponent } from './components/history-safe-redirect.component';
import { NonSupportedRedirectComponent } from './components/non-supported-redirect.component';
import { HostLinkComponent } from './components/machine-link.component';
import { MachineNameComponent } from './components/machine-name.component';
import { UserDisplayNameComponent } from './components/user-display-name.component';
import { InvestigatedMachinesListComponent } from './components/machines-list.component';
import { NavListComponent } from './components/nav-list.component';
import { PasswordInputDirective } from './components/password-input.directive';
import { ContactMailDisplayComponent } from './components/please-contact-hexadite-display.component';
import { ProgressIndicatorComponent } from './components/progress-indicator.component';
import { SeverityBarComponent } from './components/severity-summary/severity-bar.component';
import { SeveritySummaryComponent } from './components/severity-summary/severity-summary.component';
import { StopWatchComponent } from './components/stopwatch.component';
import { TabsModule } from './components/tabs/tabs.module';
import { TutorialsTeaserComponent } from './components/tutorials-teaser.component';
import { TzDateComponent } from './components/tz-date.component';
import { UserIconComponent } from './components/user-icon.component';
import { DynamicContentsDirective } from './directives/dynamic-contents.directive';
import { ScrollToDirective } from './directives/scroll_to.directive';
import { TextSelectDirective } from './directives/text-select.directive';
import { ToggleChangeclassDirective } from './directives/toggle-change-class.directive';
import { CanDeactivateForm } from './guards/can-deactivate-form.guard';
import { MainNavService } from './main/services/main-nav.service';
import { BooleanPipe } from './pipes/boolean.pipe';
import { ContextOnlyFieldsPipe } from './pipes/context-only-fields.pipe';
import { EnabledFieldsPipe } from './pipes/enabled-fields.pipe';
import { ExistsPipe } from './pipes/exists.pipe';
import { FieldsWithValuePipe } from './pipes/fields-with-value.pipe';
import { HasValuePipe } from './pipes/has-value.pipe';
import { HasPipe } from './pipes/has.pipe';
import { IsNotPipe } from './pipes/is-not.pipe';
import { IsPipe } from './pipes/is.pipe';
import { ObjectPathPipe } from './pipes/object-path.pipe';
import { PrettyTimePipe } from './pipes/pretty-time.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { DisplayTextPipe } from './pipes/display-text.pipe';
import { StringContainsPipe } from './pipes/string-contains.pipe';
import { TimeAgoPipe } from './pipes/time-ago.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UrlWithTokenPipe } from './pipes/url-with-token.pipe';
import { ValuePipe } from './pipes/value.pipe';
import { WithValuesFirstPipe } from './pipes/with-values-first.pipe';
import { WithValuesPipe } from './pipes/with-values.pipe';
import { BrowserDetectionService, WcdSharedModule, CookiesService, A11yAnnouncerService } from '@wcd/shared';
import { WcdIconsModule } from '@wcd/icons';
import { ClipboardService } from './services/clipboard.service';
import { CsvService } from './services/csv.service';
import { DownloadService } from './services/download.service';
import { SccExportService } from './services/scc-export.service';
import { SeverityTypeColorService } from './services/severity-type-color.service';
import { EvidenceTypeColorService } from './services/evidence-type-color.service';
import { TimeRangesService } from './services/time-ranges.service';
import { TitleService } from './services/title.service';
import { WcdIndicationIconsComponent } from './components/indication-icons/indication-icons.component';
import { CalculateRemainingDaysPipe } from './pipes/calculate-remaining-days.pipe';
import { VisibilityDirective } from './directives/visibility.directive';
import { CapitalizeStringPipe } from './pipes/capitalize.pipe';
import { DownloadFileComponent } from './components/download-file.component';
import { AsyncComponent } from './components/async.component';
import { AsyncDataTableFieldComponent } from './components/async-datatable-field.component';
import { QueryParamsCacheService } from './services/query-params-cache.service';
import { WizardComponent } from './components/wizard/wizard.component';
import { VisibleStepsPipe } from './components/wizard/visible-steps.pipe';
import { SafeSlicePipe } from './pipes/safe-slice.pipe';
import { A11yModule } from '@angular/cdk/a11y';
import {
	MitreTechniqueDisplayPipe,
	FilterInvalidMitreTechniques,
	MitreTechniqueLinkPipe,
} from './pipes/mitre-technique-display.pipe';
import { AlertStatusIconPipe } from './pipes/alert-status-display.pipe';
import { DetectionTechnologyDisplayPipe } from './pipes/detection-technology-display.pipe';
import {
	DetectionStatusDisplayPipe,
	DetectionStatusTooltipPipe,
	DetectionStatusIconPipe,
	DetectionStatusI18nNamePipe,
} from './pipes/detection-status-display.pipe';
import { StatusComponent } from './components/status.component';
import { DetailsListModule } from './components/details-list/details-list.module';
import { BannerComponent } from './components/banner/banner.component';
import { NotEmptyPipe } from './pipes/not-empty.pipe';
import { TextMaskDirective } from './directives/text-mask.directive';
import { TitlePipe } from './pipes/title.pipe';
import { DimOvertimeChart } from '../reporting/dim-overtime-chart';
import { TimeSeriesChartModule } from '@wcd/charts';
import { ReportingService } from '../reporting/reporting.service';
import { SummeryBarsWithLegendComponent } from './components/summery-bars-with-legend/summery-bars-with-legend.component';
import { OverflowContainerComponent } from './components/overflow-container/overflow-container.component';
import { WizardPanelComponent } from './components/wizard/wizard-panel/wizard-panel.component';
import { OnFocusFirstFocusableElementDirective } from './directives/on-focus-first-focusable-element';

@NgModule({
	imports: [
		HexaditeFormsModule,
		I18nModule,
		TooltipsModule,
		CommonModule,
		RouterModule,
		MarkdownModule.forRoot({
			markedOptions: {
				provide: MarkedOptions,
				useValue: { gfm: true, breaks: true },
			},
		}),
		DialogsModule,
		WcdIconsModule,
		TabsModule,
		DetailsListModule,
		CommandBarModule,
		FormsModule,
		RbacModule.forRoot(),
		FabIconModule,
		PrettifyModule,
		FabButtonModule,
		WcdFormsModule,
		DataTableModule,
		AngularExtensionsModule,
		ContentsStateModule,
		ExpanderModule,
		LocalizationModule,
		DistributionModule,
		PaginationModule,
		WcdSharedModule,
		A11yModule,
		TimeSeriesChartModule,
		FabMessageBarModule,
	],
	declarations: [
		ExistsPipe,
		HasPipe,
		HasValuePipe,
		PrettyTimePipe,
		StringContainsPipe,
		IsPipe,
		IsNotPipe,
		ObjectPathPipe,
		ValuePipe,
		WithValuesPipe,
		WithValuesFirstPipe,
		ContextOnlyFieldsPipe,
		EnabledFieldsPipe,
		FieldsWithValuePipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		TruncatePipe,
		TimeAgoPipe,
		UrlWithTokenPipe,
		BooleanPipe,
		DisplayTextPipe,
		CalculateRemainingDaysPipe,
		CapitalizeStringPipe,
		TitlePipe,
		VisibleStepsPipe,
		SafeSlicePipe,
		MitreTechniqueDisplayPipe,
		FilterInvalidMitreTechniques,
		MitreTechniqueLinkPipe,
		DetectionTechnologyDisplayPipe,
		AlertStatusIconPipe,
		DetectionStatusDisplayPipe,
		DetectionStatusTooltipPipe,
		DetectionStatusIconPipe,
		DetectionStatusI18nNamePipe,
		NotEmptyPipe,

		PasswordInputDirective,
		TextSelectDirective,
		ToggleChangeclassDirective,
		ScrollToDirective,
		VisibilityDirective,
		DynamicContentsDirective,
		TextMaskDirective,
		OnFocusFirstFocusableElementDirective,

		TzDateComponent,
		ExternalImageComponent,
		InvestigatedMachinesListComponent,
		MachineNameComponent,
		UserDisplayNameComponent,
		GenericObjectComponent,
		HistorySafeRedirectComponent,
		NonSupportedRedirectComponent,
		HostLinkComponent,
		StopWatchComponent,
		WsdcCardComponent,
		FieldValuesComponent,
		ContactMailDisplayComponent,
		ActiveDirectoryPathComponent,
		UserIconComponent,
		NavListComponent,
		CopyToClipboardComponent,
		ColoredDotIndicationFieldComponent,
		DownloadFileComponent,
		TutorialsTeaserComponent,
		ProgressIndicatorComponent,
		CopyableInputComponent,
		ValueWithExternalLinkComponent,
		FileCertificateInfoComponent,
		SeveritySummaryComponent,
		SeverityBarComponent,
		EventsSummaryBarComponent,
		WcdIndicationIconsComponent,
		AsyncComponent,
		AsyncDataTableFieldComponent,
		WizardComponent,
		WizardPanelComponent,
		StatusComponent,
		BannerComponent,
		DimOvertimeChart,
		SummeryBarsWithLegendComponent,
		OverflowContainerComponent,
	],
	entryComponents: [
		TzDateComponent,
		StopWatchComponent,
		MachineNameComponent,
		UserDisplayNameComponent,
		GenericObjectComponent,
		HistorySafeRedirectComponent,
		NonSupportedRedirectComponent,
		AsyncComponent,
		AsyncDataTableFieldComponent,
		StatusComponent,
		ColoredDotIndicationFieldComponent,
		DimOvertimeChart,
	],
	exports: [
		ExistsPipe,
		HasPipe,
		HasValuePipe,
		PrettyTimePipe,
		StringContainsPipe,
		IsPipe,
		IsNotPipe,
		ObjectPathPipe,
		ValuePipe,
		WithValuesPipe,
		WithValuesFirstPipe,
		SafeUrlPipe,
		SafeHtmlPipe,
		TruncatePipe,
		UrlWithTokenPipe,
		TimeAgoPipe,
		BooleanPipe,
		ContextOnlyFieldsPipe,
		EnabledFieldsPipe,
		FieldsWithValuePipe,
		DisplayTextPipe,
		CalculateRemainingDaysPipe,
		CapitalizeStringPipe,
		TitlePipe,
		VisibleStepsPipe,
		SafeSlicePipe,
		MitreTechniqueDisplayPipe,
		FilterInvalidMitreTechniques,
		MitreTechniqueLinkPipe,
		DetectionTechnologyDisplayPipe,
		AlertStatusIconPipe,
		DetectionStatusDisplayPipe,
		DetectionStatusTooltipPipe,
		DetectionStatusIconPipe,
		DetectionStatusI18nNamePipe,
		NotEmptyPipe,

		PasswordInputDirective,
		TextSelectDirective,
		ToggleChangeclassDirective,
		ScrollToDirective,
		VisibilityDirective,
		DynamicContentsDirective,
		TextMaskDirective,
		OnFocusFirstFocusableElementDirective,
		TzDateComponent,
		StopWatchComponent,
		WsdcCardComponent,
		InvestigatedMachinesListComponent,
		MachineNameComponent,
		UserDisplayNameComponent,
		GenericObjectComponent,
		HistorySafeRedirectComponent,
		HostLinkComponent,
		FieldValuesComponent,
		ContactMailDisplayComponent,
		ActiveDirectoryPathComponent,
		UserIconComponent,
		NavListComponent,
		CopyToClipboardComponent,
		ColoredDotIndicationFieldComponent,
		DownloadFileComponent,
		TutorialsTeaserComponent,
		ProgressIndicatorComponent,
		CopyableInputComponent,
		ValueWithExternalLinkComponent,
		FileCertificateInfoComponent,
		SeveritySummaryComponent,
		SummeryBarsWithLegendComponent,
		EventsSummaryBarComponent,
		WcdIndicationIconsComponent,
		AsyncComponent,
		WizardComponent,
		WizardPanelComponent,
		StatusComponent,
		BannerComponent,
		OverflowContainerComponent,

		CommonModule,
		RouterModule,
		WcdIconsModule,
		FormsModule,
		MarkdownModule,
		DialogsModule,
		RbacModule,
		HexaditeFormsModule,
		I18nModule,
		TabsModule,
		DetailsListModule,
		CommandBarModule,
		ExternalImageComponent,
		NotificationsModule,
		TooltipsModule,
		PrettifyModule,
		FabButtonModule,
		WcdFormsModule,
		FabIconModule,
		AngularExtensionsModule,
		ContentsStateModule,
		ExpanderModule,
		DistributionModule,
		LocalizationModule,
		PaginationModule,
		WcdSharedModule,
		DimOvertimeChart,
		TimeSeriesChartModule,
	],
})
export class SharedModule {
	constructor(markdownService: MarkdownService) {
		markdownService.renderer.link = (href: string, title: string, text: string) => {
			return `<a href="${href}" target="_blank" ${title ? 'title=' + title : ''}>${text}</a>`;
		};
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: SharedModule,
			providers: [
				CanDeactivateForm,
				BrowserDetectionService,
				CookiesService,
				MainNavService,
				TimeAgoPipe,
				DisplayTextPipe,
				CalculateRemainingDaysPipe,
				CapitalizeStringPipe,
				TitlePipe,
				MitreTechniqueDisplayPipe,
				FilterInvalidMitreTechniques,
				MitreTechniqueLinkPipe,
				DetectionTechnologyDisplayPipe,
				AlertStatusIconPipe,
				DetectionStatusDisplayPipe,
				DetectionStatusTooltipPipe,
				DetectionStatusIconPipe,
				DetectionStatusI18nNamePipe,

				TruncatePipe,
				ClipboardService,
				TitleService,
				DownloadService,
				SccExportService,
				MarkdownService,
				CsvService,
				TimeRangesService,
				SeverityTypeColorService,
				EvidenceTypeColorService,
				QueryParamsCacheService,
				ReportingService,
				A11yAnnouncerService,
			],
		};
	}
}
