/* tslint:disable:template-accessibility-alt-text */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';

import { EvaluationSimulationCatalog, EvaluationSimulatorAgent } from '@wcd/domain';
import { ContentState } from '@wcd/contents-state';

import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ReplaySubject } from 'rxjs';

@Component({
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" (startClose)="onClose.emit()">
			<wcd-contents-state [contentState]="contentState | async">
				<ng-container loading>
					<div class="wcd-margin-xxLarge-top center-text">
						<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
					</div>
				</ng-container>

				<ng-container complete>
					<div
						class="wcd-full-height wcd-flex-vertical wcd-padding-vertical wcd-padding-xLarge-horizontal"
					>
						<div>
							<span class="wcd-font-size-s wcd-padding-small-right">
								{{ 'simulationAndTutorials.poweredBy' | i18n }}
							</span>
							<img
								*ngIf="simulation?.simulator === EvaluationSimulatorAgent.SafeBreach"
								[src]="'/assets/images/partners/safebreach_logo.png' | basePath"
							/>
							<img
								*ngIf="simulation?.simulator === EvaluationSimulatorAgent.AttackIQ"
								[src]="'/assets/images/partners/aiq_logo.png' | basePath"
							/>
						</div>
						<h3 class="wcd-font-size-xxl wcd-font-weight-bold wcd-margin-large-top">
							{{ simulation?.name }}
						</h3>
						<markdown
							class="wcd-margin-large-vertical"
							[data]="simulation?.description"
						></markdown>
						<markdown
							[data]="
								'simulationAndTutorials.goToWebsite'
									| i18n
										: {
												name: simulation?.simulator,
												link:
													simulation?.simulator ===
													EvaluationSimulatorAgent.AttackIQ
														? 'https://attackiq.com'
														: 'https://safebreach.com'
										  }
							"
						></markdown>
					</div>
				</ng-container>

				<ng-container error>
					<div class="wcd-margin-top center-text">
						{{ 'simulationAndTutorials.simulationsCatalogError' | i18n }}
					</div>
				</ng-container>
			</wcd-contents-state>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimulationDescriptionPanelComponent extends PanelContainer implements OnInit {
	@Input() simulationId: number;
	@Output() onClose = new EventEmitter<void>();
	simulation: EvaluationSimulationCatalog;
	contentState = new ReplaySubject<ContentState>(1);
	SpinnerSize = SpinnerSize;
	EvaluationSimulatorAgent = EvaluationSimulatorAgent;

	constructor(router: Router, private evaluationService: EvaluationService) {
		super(router);
		this.contentState.next(ContentState.Loading);
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.simulationId) {
			this.handleError();
		}
		this.evaluationService.getSimulationCatalogById(this.simulationId).subscribe(
			simulation => {
				this.simulation = simulation;
				if (!this.simulation) {
					this.handleError();
				}
				this.contentState.next(ContentState.Complete);
			},
			() => {
				this.handleError();
			}
		);
	}

	private handleError() {
		this.contentState.next(ContentState.Error);
	}
}
