import { Routes } from '@angular/router';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { CustomTiIndicatorsDataviewComponent } from './components/custom-ti-indicators.dataview.component';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
import { sccHostService } from '@wcd/scc-interface';
export var customTiIndicatorsTitle = 'customTiIndicator_common_title';
export var customTiIndicatorsIcon = 'customTiIndicators';
var breadcrumbs = sccHostService.isSCC
    ? {
        addParentWhenEmpty: false,
        addItemOnEnter: false,
        resetListOnEnter: false,
        show: true,
    }
    : null;
export function getCustomTiIndicatorPageTitleKey(featuresService) {
    return customTiIndicatorsTitle;
}
var ɵ0 = {
    breadcrumbsConfig: breadcrumbs,
    pageTitle: customTiIndicatorsTitle,
    pageTitleIcon: customTiIndicatorsIcon,
    pageDescriptionKey: 'customTiIndicators.description',
}, ɵ1 = {
    redirectTo: 'files'
};
export var customTiIndicatorsRoutes = [
    {
        path: ':customTiIndicatorType',
        component: CustomTiIndicatorsDataviewComponent,
        resolve: {
            breadcrumbs: BreadcrumbsResolver,
        },
        data: ɵ0,
    },
    {
        path: '',
        component: HistorySafeRedirectComponent,
        data: ɵ1,
        pathMatch: 'full',
    },
];
var CustomTiIndicatorsModule = /** @class */ (function () {
    function CustomTiIndicatorsModule() {
    }
    return CustomTiIndicatorsModule;
}());
export { CustomTiIndicatorsModule };
export { ɵ0, ɵ1 };
