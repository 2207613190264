import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	OnDestroy,
} from '@angular/core';
import { Prettify } from '../../utils/services/prettify.service';

declare const moment: typeof import('moment');

@Component({
	selector: 'stopwatch',
	template: '<span class="stopwatch">{{display}}</span>',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StopWatchComponent implements OnDestroy, OnChanges {
	@Input() start: Date;
	@Input() end: Date;
	@Input() zeroText: string;
	@Input() prettify: boolean = true;

	display: string;

	private _displayTimeout;
	private _hasEnded: boolean = false;

	constructor(private changeDetectionRef: ChangeDetectorRef) {}

	ngOnDestroy() {
		clearTimeout(this._displayTimeout);
	}

	ngOnChanges(changes) {
		if (!this.end) {
			this.setDisplay(true);
			this._hasEnded = false;
		} else if (!this._hasEnded) {
			clearTimeout(this._displayTimeout);
			this.setDisplay(false);
			this._hasEnded = true;
		}
	}

	private setDisplay(update) {
		const spanMilliseconds: number = (this.end || new Date()).valueOf() - this.start.valueOf();

		this.display =
			spanMilliseconds <= 0 ? this.zeroText || this.getText(0) : this.getText(spanMilliseconds);

		if (update && !this.end)
			this._displayTimeout = setTimeout(() => {
				this.setDisplay(true);
				this.changeDetectionRef.markForCheck();
			}, 1000);
	}

	private getText(millisecondsValue: number): string {
		if (this.prettify) return Prettify.prettyTime(millisecondsValue / 1000);

		const duration = moment.duration(millisecondsValue),
			digits: Array<string> = [duration.hours(), duration.minutes(), duration.seconds()].map(value =>
				value.toString().padStart(2, '0')
			),
			days: number = duration.days();

		if (days) digits.splice(0, 0, days.toString());

		return digits.join(':');
	}
}
