var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ElementRef, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { isNil } from 'lodash-es';
import { Panel, PanelComponent, PanelType } from '@wcd/panels';
import { FabricIconNames } from '@wcd/scc-common';
import { FiltersComponent } from '@wcd/ng-filters';
export var FILTERS_PANEL_SETTINGS = new Panel({
    type: PanelType.smallFixedFar,
    headerText: 'Filters',
    showOverlay: false,
    isBlocking: false,
    isModal: true,
    isStatic: false,
    hasCloseButton: true,
    noBodyPadding: true,
});
var DataviewActionFilterComponent = /** @class */ (function () {
    function DataviewActionFilterComponent(i18nService) {
        var _this = this;
        this.i18nService = i18nService;
        this.smallScreenView = false;
        this.isInMoreMenu = true;
        this.icon = FabricIconNames.Filter;
        this.filtersOpened = false;
        this.toggleFilters = function (isOpen) {
            _this.disableFilterPanelAutoFocus = false;
            _this.filtersOpened = isNil(isOpen) ? !_this.filtersOpened : isOpen;
            if (!_this.filtersOpened) {
                setTimeout(function () { return _this.filterButton.nativeElement.focus(); }, 0);
            }
        };
        this.filtersPanelSettings = new Panel(FILTERS_PANEL_SETTINGS);
    }
    DataviewActionFilterComponent.prototype.buttonElementPicker = function () {
        return this.filterButton.nativeElement;
    };
    DataviewActionFilterComponent.prototype.getLocalizedTooltip = function () {
        return this.filtersOpened
            ? this.i18nService.get('grid.commands.filters.hide')
            : this.i18nService.get('grid.commands.filters.show');
    };
    DataviewActionFilterComponent.prototype.onFiltersChanged = function (filtersState) {
        if (!filtersState.selection || Object.keys(filtersState.selection).length === 0) {
            this.resetFilters(filtersState);
        }
        this.onFiltersChangedCallback(filtersState);
    };
    DataviewActionFilterComponent.prototype.resetFilters = function (filtersState) {
        filtersState.selection = this.defaultQueryFilters;
        filtersState.serialized = this.defaultQueryFilters;
        this._filtersComponent.resetSelectedValues(this.defaultQueryFilters);
    };
    DataviewActionFilterComponent.prototype.onKeydown = function ($event) {
        $event.stopPropagation();
    };
    DataviewActionFilterComponent.prototype.ngOnInit = function () {
        Object.assign(this, __assign({}, this.filterActionConfig));
    };
    return DataviewActionFilterComponent;
}());
export { DataviewActionFilterComponent };
