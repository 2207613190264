import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { OnChanges, TypedChanges } from '../types/angular/on-changes';

@Directive({
	selector: '[focusOn]',
})
export class FocusOnDirective implements OnChanges<FocusOnDirective>, AfterViewInit {
	@Input()
	focusOn: boolean;

	constructor(private elemRef: ElementRef) {}

	ngOnChanges(changes: TypedChanges<FocusOnDirective>) {
		if (changes.focusOn && changes.focusOn.currentValue) {
			this.elemRef.nativeElement.focus();
		}
	}

	ngAfterViewInit() {
		if (this.focusOn) {
			this.elemRef.nativeElement.focus();
		}
	}
}
