
		<wcd-panel #panel (close)="onClosePanel()" [settings]="settings">
			<wcd-wizard
				[steps]="steps"
				[data]="formData"
				wizardTitlePrefix="vulnerability.email.notification.wizard.breadcrumbs.prefix"
				[wizardTitle]="title"
				wizardTrackingName="IncidentEmailNotificationWizard"
				(onClose)="onClosePanel()"
				(onDone)="onRuleCreated()"
			></wcd-wizard>
		</wcd-panel>
	