import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../../app/seville/seville.module';

SevilleModule.factory('seville.settings.download.endpointonboardingstatus', endpointOnboardingStatusFactory);

endpointOnboardingStatusFactory.$inject = ['$log', '$http', 'appConfig'];

function endpointOnboardingStatusFactory($log, $http, appConfig: AppConfigService) {
	function getStatus() {
		return $http
			.get(appConfig.serviceUrls.management + '/EndpointOnboardingStatus', { timeout: 60000 })
			.then(
				function(successResponse) {
					if (successResponse.status === 200) {
						if (successResponse.data === null) {
							$log.error('EndpointOnboardingStatus return 200 but with data == null');
							throw successResponse;
						}

						$log.info('EndpointOnboardingStatus returned successfully');
						return successResponse.data;
					} else {
						$log.error('EndpointOnboardingStatus failed. status code: ' + successResponse.status);
						throw successResponse;
					}
				},
				function(errorResponse) {
					$log.error('EndpointOnboardingStatus call failed. status code: ' + errorResponse.status);
					throw errorResponse;
				}
			);
	}

	var service = {
		getStatus: getStatus,
	};

	return service;
}
