import { PrettyNumberService } from './../../../../../../../../projects/prettify/src/lib/services/pretty-number.service';
import { I18nService } from './../../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChangeEvent, VulnerabilityChangeEventType, ConfigurationChangeEventType, ChangeEventTypesForMdatp } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService, TvmColor } from './../../../../tvm/services/tvm-color.service';
import { WcdIconNames } from '@wcd/icons-font';

@Injectable()
export class ChangeEventService {
	private _changeEventFilters: Record<string, any>;

	constructor(
		private tvmColorService: TvmColorService,
		private i18nService: I18nService,
		private prettyNumberService: PrettyNumberService
	) {
		// Those functions are called from another context, bind them to the service instead
		this.getToolTip = this.getToolTip.bind(this);
		this.getScoreTrendIconClass = this.getScoreTrendIconClass.bind(this);
		this.getAssetsCountString = this.getAssetsCountString.bind(this);
	}

	getScoreTrendIconName(changeEvent: ChangeEvent): FabricIconNames {
		if (changeEvent.score > 0) {
			return FabricIconNames.CaretSolidUp;
		} else if (changeEvent.score < 0) {
			return FabricIconNames.CaretSolidDown;
		}
		return null;
	}

	getScoreTrendIconClass(changeEvent: ChangeEvent): string {
		if (changeEvent.score > 0) {
			return `color-text-${this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name}`;
		} else if (changeEvent.score < 0) {
			return `color-text-${this.tvmColorService.colorsMap.get(TvmColor.Positive).name}`;
		}
		return null;
	}

	getTitleIconName(changeEvent: ChangeEvent): WcdIconNames | FabricIconNames {
		switch (changeEvent.eventType) {
			case VulnerabilityChangeEventType.NewCve:
				return FabricIconNames.ReportWarning;
			case VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit:
				return FabricIconNames.Bug;
			case VulnerabilityChangeEventType.CveHasVerifiedExploit:
				return WcdIconNames.bug_warning;
			case VulnerabilityChangeEventType.CveHasExploitInKit:
				return WcdIconNames.bug_lightning_bolt;
			case ConfigurationChangeEventType.NewScid:
				return WcdIconNames.new_scid;
		}
		return null;
	}

	getToolTip(changeEvent: ChangeEvent): string {
		const prefix = 'tvm.changeEvent.scoreTrendValues.';
		if (changeEvent.score > 0) {
			return this.i18nService.get(`${prefix}negativeEffect`);
		} else if (changeEvent.score < 0) {
			return this.i18nService.get(`${prefix}positiveEffect`);
		}
		return this.i18nService.get(`${prefix}noEffect`);
	}

	getScoreText(changeEvent: ChangeEvent): string {
		return !changeEvent.score || changeEvent.score === 0 ? '–' : '';
	}

	getRelatedProductId(changeEvent: ChangeEvent): string {
		return `${changeEvent.vendor}-_-${changeEvent.productName}`;
	}

	getAssetsCountString(changeEvent: ChangeEvent): string {
		return changeEvent.assetsCount === null || changeEvent.assetsCount === undefined
			? null
			: `${this.prettyNumberService.prettyNumber(
					changeEvent.assetsCount
			  )} ${this.roundAndDisplayPercent(changeEvent.affectedAssetsPercent)}`;
	}

	getCurrentAssetsCountString(changeEvent: ChangeEvent): string {
		return changeEvent.currentAssetsCount === null || changeEvent.currentAssetsCount === undefined
			? null
			: `${this.prettyNumberService.prettyNumber(
					changeEvent.currentAssetsCount
			  )} ${this.roundAndDisplayPercent(changeEvent.currentAffectedAssetsPercent)}`;
	}

	getChangeEventFilters(): Observable<Record<string, any>> {
		if (!this._changeEventFilters) {
			this._changeEventFilters = {
				assetsCount: {
					// This filter is defined in the fields service - special component
					values: {},
				},
				eventType: {
					values: this.getFilterValues(),
				},
			};
		}
		return of(
			Object.keys(this._changeEventFilters).reduce(
				(res, key) => Object.assign(res, { [key]: this._changeEventFilters[key] }),
				{}
			)
		);
	}

	private getFilterValues() {
		const vaFilters = ChangeEventTypesForMdatp.map(type => ({
			value: type,
			name: this.i18nService.get(`tvm.changeEvent.eventType.${VulnerabilityChangeEventType[type]}`),
		}));

		const scaFilters = Object.keys(ConfigurationChangeEventType).map(type => ({
			value: type,
			name: this.i18nService.get(`tvm.changeEvent.eventType.${ConfigurationChangeEventType[type]}`),
		}));

		return scaFilters.concat(vaFilters);
	}

	private roundAndDisplayPercent(decimalPercent: number): string {
		const rounded = Math.round(decimalPercent);
		return decimalPercent >= 0 && rounded <= 100 ? `(${rounded === 0 ? '<1' : rounded}%)` : '';
	}
}
