/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./notification-rule-edit.component.ngfactory";
import * as i5 from "./notification-rule-edit.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i8 from "../../../dialogs/services/dialogs.service";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i12 from "../../../@entities/machines/services/machines.service";
import * as i13 from "@angular/cdk/a11y";
import * as i14 from "./notification-rule.panel.component";
import * as i15 from "@angular/router";
var styles_NotificationRulePanelComponent = [];
var RenderType_NotificationRulePanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NotificationRulePanelComponent, data: {} });
export { RenderType_NotificationRulePanelComponent as RenderType_NotificationRulePanelComponent };
export function View_NotificationRulePanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { ruleEditComponent: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { canDeactivate: [0, "canDeactivate"], settings: [1, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 2, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 1, 2, "notification-rule-edit", [["class", "wcd-flex-1 wcd-flex-vertical wcd-full-height"]], null, [[null, "save"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.cancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_NotificationRuleEditComponent_0, i4.RenderType_NotificationRuleEditComponent)), i0.ɵdid(7, 114688, [[1, 4]], 0, i5.NotificationRuleEditComponent, [i6.Paris, i7.AuthService, i8.DialogsService, i9.I18nService, i10.FeaturesService, i11.FlavorService, i12.MachinesService, i13.LiveAnnouncer, i0.ChangeDetectorRef], { rule: [0, "rule"] }, { save: "save", cancel: "cancel" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundCanDeactivate; var currVal_1 = _co.settings; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.rule; _ck(_v, 7, 0, currVal_2); }, null); }
export function View_NotificationRulePanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "notification-rule-panel", [], null, null, null, View_NotificationRulePanelComponent_0, RenderType_NotificationRulePanelComponent)), i0.ɵdid(1, 245760, null, 0, i14.NotificationRulePanelComponent, [i15.Router, i9.I18nService, i8.DialogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotificationRulePanelComponentNgFactory = i0.ɵccf("notification-rule-panel", i14.NotificationRulePanelComponent, View_NotificationRulePanelComponent_Host_0, { settings: "settings", rule: "rule", onDone: "onDone" }, {}, []);
export { NotificationRulePanelComponentNgFactory as NotificationRulePanelComponentNgFactory };
