import { FabIconModule, FabSpinnerModule, FabButtonModule, FabMessageBarModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { DataExportEntityTypeService } from './services/data-export.entity-type.service';
import { DataExportNewModalComponent } from './components/data-export-new.component';
import { PanelsModule } from '@wcd/panels';
import { DataExportSettingsDetailsComponent } from './components/data-export-details.component';
import { DataExportSettingsInfoBoxComponent } from './components/data-export-info-box.component';
import { DataviewRepositoryService } from '../../dataviews/services/dataview-repository.service';

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		FabIconModule,
		FabSpinnerModule,
		FabButtonModule,
		FabMessageBarModule,
		PanelsModule,
	],
	declarations: [DataExportNewModalComponent, DataExportSettingsDetailsComponent, DataExportSettingsInfoBoxComponent],
	providers: [DataExportEntityTypeService, DataviewRepositoryService],
	entryComponents: [DataExportNewModalComponent, DataExportSettingsDetailsComponent, DataExportSettingsInfoBoxComponent],
})
export class DataExportEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(DataExportEntityTypeService);
	}
}
