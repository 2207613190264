var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DataTableComponent, DataTableField } from '@wcd/datatable';
import { combineLatest, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { AlertIncidentService } from '../services/alert-incident.service';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertLinkedAlertsPanelComponent } from './linked-alerts/alert-linked-alerts-panel.component';
import { AlertLinkedEntityResolverService } from '../services/alert.linked-entity-resolver.service';
import { ImpactedEntitiesLinkedByComponent } from '../../../impacted-entities/components/impacted-entities-linked-by/impacted-entities-linked-by.component';
var AlertLinkedByDetailsComponent = /** @class */ (function () {
    function AlertLinkedByDetailsComponent(i18nService, alertIncidentService, globalEntityTypesService, paris, dialogsService, alertLinkedEntityResolverService) {
        var _this = this;
        this.i18nService = i18nService;
        this.alertIncidentService = alertIncidentService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.alertLinkedEntityResolverService = alertLinkedEntityResolverService;
        this.showTooltip = true;
        this.loading = true;
        this.tableFields = DataTableField.fromList([
            {
                id: 'category',
                name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.category.name'),
                getDisplay: function (item) { return _this.alertIncidentService.getCategoryDisplayText(item.category); },
                sort: { enabled: false },
            },
            {
                id: 'entity',
                name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.entity.name'),
                sort: { enabled: false },
                component: {
                    type: ImpactedEntitiesLinkedByComponent,
                    getProps: function (item) {
                        return item.entity
                            ? {
                                entity: item.entity,
                                entityTypeId: item.entityType.id,
                                entitySource: item.sourceEntity.entitySource,
                                showTooltip: _this.showTooltip,
                                targetEntities: item.targetEntities,
                            }
                            : null;
                    },
                },
                useDefaultEmptyFieldComponent: true,
            },
            {
                id: 'linkedAlertsCount',
                name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.linkedAlerts.name'),
                getCssClass: function (_item) { return 'btn-link'; },
                getDisplay: function (item) { return _this.getAlertCountText(item.linkedAlertsCount); },
                onClick: function (item) {
                    return _this.showLinkedAlerts(item);
                },
                sort: { enabled: false },
            },
        ]);
    }
    AlertLinkedByDetailsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes && changes.aggregatedLinkedBy) {
            this.tableItems$ = combineLatest(changes.aggregatedLinkedBy.currentValue.map(function (aggregatedLinkedByReason) {
                var entity$ = _this.alertLinkedEntityResolverService.getEntity(aggregatedLinkedByReason);
                return entity$.pipe(catchError(function (err) { return of(null); }), map(function (entity) { return (__assign({}, aggregatedLinkedByReason, { entity: entity, entityType: _this.alertLinkedEntityResolverService.getAppEntityType(aggregatedLinkedByReason.sourceEntity.type) })); }));
            }));
        }
    };
    AlertLinkedByDetailsComponent.prototype.updateTableView = function () {
        if (this.dataTable) {
            this.dataTable.updateHeaderCells();
        }
    };
    AlertLinkedByDetailsComponent.prototype.showLinkedAlerts = function (item) {
        var _this = this;
        this.dialogsService
            .showPanel(AlertLinkedAlertsPanelComponent, {
            id: 'alerts-linked-alerts',
            type: PanelType.large,
            isBlocking: false,
            headerText: this.getAlertCountText(item.linkedAlertsCount),
            back: {
                onClick: function () { return _this.linkedAlertsSidePanel.destroy(); },
            },
        }, {
            alertId: this.alertId,
            linkedByReason: item,
        })
            .subscribe(function (panel) {
            _this.linkedAlertsSidePanel = panel;
            panel.onDestroy(function () {
                _this.linkedAlertsSidePanel = null;
            });
        });
    };
    AlertLinkedByDetailsComponent.prototype.getAlertCountText = function (count) {
        var key = count === 1 ? 'singular' : 'plural';
        return this.i18nService.get("alerts.panel.linkedByIncidentDetails.alertCount." + key, {
            count: count,
        });
    };
    return AlertLinkedByDetailsComponent;
}());
export { AlertLinkedByDetailsComponent };
