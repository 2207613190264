var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmAnalyticsApiCallSharedConfigurations } from '../../analyticsEndPointUtils';
export var AssetsByExposureCategoriesNonSupported;
(function (AssetsByExposureCategoriesNonSupported) {
    AssetsByExposureCategoriesNonSupported["OS_NOT_SUPPORTED"] = "OsNotSupported";
    AssetsByExposureCategoriesNonSupported["OTHER"] = "Other";
})(AssetsByExposureCategoriesNonSupported || (AssetsByExposureCategoriesNonSupported = {}));
var Category;
(function (Category) {
    Category["Supported"] = "supported";
    Category["NotSupported"] = "notSupported";
})(Category || (Category = {}));
var Supported;
(function (Supported) {
    Supported["ExposureLevel"] = "exposureLevel";
    Supported["AssetCount"] = "assetCount";
})(Supported || (Supported = {}));
var NotSupported;
(function (NotSupported) {
    NotSupported["Reason"] = "reason";
    NotSupported["AssetCount"] = "assetCount";
})(NotSupported || (NotSupported = {}));
var ɵ0 = function (data) {
    var _a, _b, _c;
    //convert to new shape:
    if (Array.isArray(data)) {
        data = (_a = {},
            _a[Category.Supported] = data,
            _a[Category.NotSupported] = [
                (_b = {},
                    _b[NotSupported.Reason] = AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED,
                    _b[NotSupported.AssetCount] = 0,
                    _b),
                (_c = {},
                    _c[NotSupported.Reason] = AssetsByExposureCategoriesNonSupported.OTHER,
                    _c[NotSupported.AssetCount] = 0,
                    _c),
            ],
            _a);
    }
    return data[Category.Supported]
        .map(function (record) { return ({
        category: record[Supported.ExposureLevel],
        assetCount: record[Supported.AssetCount],
    }); })
        .concat(data[Category.NotSupported].map(function (record) { return ({
        category: record[NotSupported.Reason],
        assetCount: record[NotSupported.AssetCount],
    }); }));
};
var AssetsByExposureCategoryApiCall = /** @class */ (function (_super) {
    __extends(AssetsByExposureCategoryApiCall, _super);
    function AssetsByExposureCategoryApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AssetsByExposureCategoryApiCall = __decorate([
        ApiCall(__assign({}, TvmAnalyticsApiCallSharedConfigurations, { name: 'assets-by-exposure-category-api-call', method: 'GET', endpoint: 'assets/countByExposureLevel', cache: null, parse: ɵ0 }))
    ], AssetsByExposureCategoryApiCall);
    return AssetsByExposureCategoryApiCall;
}(ApiCallModel));
export { AssetsByExposureCategoryApiCall };
export { ɵ0 };
