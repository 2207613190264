var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { ColorsService } from '../../shared/services/colors.service';
import { KnownColorsService } from '../../shared/services/known-colors.service';
import { BaselineComplianceProfileStatusPercentage } from '../components/tvm-profile-compliance-status-bars/baseline-compliance-profile-status-percentage.enum';
var baselineComplianceProfileStatusColorName = (_a = {},
    _a[BaselineComplianceProfileStatusPercentage.MoreThan75PercentConfiguredDevices] = 'greenLight',
    _a[BaselineComplianceProfileStatusPercentage.MoreThan50LessThan75PercentConfiguredDevices] = 'lowSeverity',
    _a[BaselineComplianceProfileStatusPercentage.MoreThan25LessThan50PercentConfiguredDevices] = 'mediumSeverity',
    _a[BaselineComplianceProfileStatusPercentage.LessThan25PercentConfiguredDevices] = 'highSeverity',
    _a[BaselineComplianceProfileStatusPercentage.NoDataDevices] = 'neutralTertiary',
    _a);
var BaselineComplianceProfileStatusColorService = /** @class */ (function (_super) {
    __extends(BaselineComplianceProfileStatusColorService, _super);
    function BaselineComplianceProfileStatusColorService(knownColorsService) {
        return _super.call(this, knownColorsService, baselineComplianceProfileStatusColorName) || this;
    }
    return BaselineComplianceProfileStatusColorService;
}(ColorsService));
export { BaselineComplianceProfileStatusColorService };
