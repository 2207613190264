
		<div
			class="wcd-full-height wcd-flex-vertical"
			data-track-component="Suppression Rule comments Panel"
			data-track-component-type="Side Panel"
		>
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="page-header-icon wcd-flex-none">
					<wcd-shared-icon class="round-icon color-box-gray-200" iconName="suppressionRule">
					</wcd-shared-icon>
				</div>
				<div class="wcd-flex-1 wcd-flex-center-vertical">
					<h3 class="side-panel-title">Comments and history</h3>
				</div>
			</header>
			<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-vertical wcd-padding-large-horizontal">
				<ng-container *ngIf="isLoading || isError; else commentsList">
					<div class="wcd-full-height loading-overlay">
						<div class="error-message" *ngIf="error">
							<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
							Error loading history
						</div>
						<div *ngIf="isLoading">
							<i class="large-loader-icon"></i>
							Loading history...
						</div>
					</div>
				</ng-container>
				<ng-template #commentsList>
					<editable-comments-list
						[comments]="history"
						(save)="addCommentToRule($event)"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						[isDisabled]="rule.isReadOnly"
						[readonly]="rule.isReadOnly"
						[isSaving]="isSavingComment"
					></editable-comments-list>
				</ng-template>
			</div>
		</div>
	