import { ComponentRef, Injectable } from '@angular/core';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import {
	Incident,
	IncidentStatusValueIds,
	IncidentsUpdateApiCall,
	ServiceSourceType,
	Severity,
	Tag,
	TagType,
} from '@wcd/domain';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { AuthService, MtpPermission } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { IncidentEntityPanelComponent } from '../components/incident.entity-panel.component';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { IncidentEntityDetailsComponent } from '../../../global_entities/components/entity-details/incident.entity-details.component';
import { IncidentEntityComponent } from '../components/incident.entity.component';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { IncidentCommentsComponent } from '../components/incident-comments.component';
import { IncidentsService } from './incidents.service';
import { mergeMap, take } from 'rxjs/operators';
import { IncidentsEntityPanelComponent } from '../components/incidents.entity-panel.component';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { of } from 'rxjs';
import { countBy, flatMap, sortBy, uniq } from 'lodash-es';
import { IncidentManagePanelComponent } from '../components/incident.manage-panel.component';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { sccHostService } from '@wcd/scc-interface';
import { panelService } from '@wcd/shared';

@Injectable()
export class IncidentEntityTypeService implements EntityTypeService<Incident> {
	private _incidentManagePanel: ComponentRef<IncidentManagePanelComponent>;

	constructor(
		private authService: AuthService,
		private paris: Paris,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private incidentsService: IncidentsService,
		private featuresService: FeaturesService,
		private askThreatExpertService: AskThreatExpertService,
		private rbacControlService: RbacControlService,
		private entityTagsService: EntityTagsService,
	) {
		this._isUpdatedIncidentQueue = this.featuresService.isEnabled(Feature.UpdatedIncidentQueue);
	}

	readonly assignToMeButton: ItemActionModelConfig = {
		id: 'incidentAssignToMe',
		nameKey: 'incident.commandBar.actions.manageIncident.assignToMe',
		icon: 'users.userCheck',
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllPermissions: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) =>
			this.incidentsService.assignIncidentsToMe(incidents).toPromise(),
	};

	readonly unassignButton: ItemActionModelConfig = {
		id: 'incidentUnAssign',
		nameKey: 'incident.commandBar.actions.manageIncident.unassign',
		icon: 'users.user_add',
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllPermissions: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) => {
			return this.incidentsService.unassignIncidents(incidents).toPromise();
		},
	};

	readonly resolveButton: ItemActionModelConfig = {
		id: 'resolve',
		nameKey: 'incident.resolve.resolve',
		icon: 'check',
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllPermissions: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) => {
			return this.incidentsService
				.showSetClassificationPanel(incidents)
				.pipe(take(1)) // takes one to make the observable complete - otherwise Promise.then() will never be called
				.toPromise();
		},
	};

	readonly reactivateButton: ItemActionModelConfig = {
		id: 'reactivate',
		nameKey: 'incident.reactivate.reactivate',
		icon: 'undo',
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllPermissions: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) => {
			return this.incidentsService
				.showSetClassificationPanel(incidents)
				.pipe(take(1)) // takes one to make the observable complete - otherwise Promise.then() will never be called
				.toPromise();
		},
	};

	readonly commentsButton: ItemActionModelConfig = {
		id: 'comments',
		nameKey: 'incident.commandBar.commentsAndHistory.title',
		icon: 'comment',
		refreshOnResolve: false,
		method: (incidents: Array<Incident>) => {
			// TODO refactor explicit construction
			return new Promise<void>((resolve, reject) => {
				if (this._commentsPanel) {
					// Comments panel is already open and button was clicked - closing it
					this._commentsPanel.instance.destroy();
					this._commentsPanel = null;
					resolve();
				} else {
					this.dialogsService
						.showPanel(
							IncidentCommentsComponent,
							{
								id: 'incident-comments',
								type: PanelType.medium,
								isModal: true,
								headerText: this.i18nService.get(
									'incident.commandBar.commentsAndHistory.title'
								),
								showOverlay: false,
								isBlocking: false,
							},
							{
								incident: incidents.length === 1 ? incidents[0] : null,
							}
						)
						.subscribe(
							(panel: ComponentRef<IncidentCommentsComponent>) => {
								this._commentsPanel = panel;
								panel.onDestroy(() => {
									this._commentsPanel = null;
									resolve();
								});
							},
							error => {
								reject(error);
							}
						);
				}
			});
		},
	};

	readonly setStatusAndClassificationButton = {
		id: 'incidentSetClassificationAndDetermination',
		nameKey: 'incident_ManageIncidents',
		icon: FabricIconNames.SetAction,
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllWorkloads: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) => {
			return this.incidentsService
				.showSetClassificationPanel(incidents)
				.pipe(take(1))
				.toPromise();
		},
	};

	readonly manageIncidentButton: ItemActionModelConfig = {
		id: 'manageIncident',
		nameKey: 'incident.commandBar.manageIncident.title',
		icon: FabricIconNames.Edit,
		refreshOnResolve: true,
		rbacState: RbacControlState.hidden,
		rbacMtpPermission: [MtpPermission.SecurityData_Manage],
		rbacRequireAllPermissions: true,
		rbacIgnoreNonActiveWorkloads: true,
		method: (incidents: Array<Incident>) => {
			// TODO fix explicit construction
			return new Promise<void>((resolve, reject) => {
				if (sccHostService.isSCC) {
					if (incidents.length && incidents[0]) {
						let id = '';
						const PanelComponent = sccHostService.ui.render('ManageIncidentFlyoutWithAppBootstrap@wicd-ine/main', {
							incident: incidents[0],
							onDismiss: (shouldReloadActions) => {
								panelService.dismissPanel(id);
								!shouldReloadActions ? resolve() : reject();
							},
						});
						id = panelService.openPanel(PanelComponent);
					}
				}
				else {
					if (this._incidentManagePanel) {
						this._incidentManagePanel.instance.destroy();
						this._incidentManagePanel = null;
						resolve();
					} else {
						this.dialogsService
							.showPanel(
								IncidentManagePanelComponent,
								{
									id: 'incident-manage',
									type: PanelType.large,
									isModal: true,
									headerText: this.i18nService.get('incident.commandBar.manageIncident.title'),
									showOverlay: false,
									isBlocking: false,
								},
								{
									incident: incidents.length === 1 ? incidents[0] : null,
								}
							)
							.subscribe(
								(panel: ComponentRef<IncidentManagePanelComponent>) => {
									this._incidentManagePanel = panel;

									panel.onDestroy(() => {
										const wasSaving = this._incidentManagePanel.instance.isSaving;
										this._incidentManagePanel = null;
										if (wasSaving) {
											resolve();
										} else {
											reject();
										}
									});
								},
								error => {
									reject(error);
								}
							);
					}
				}
			});
		},
	};

	private _commentsPanel: ComponentRef<IncidentCommentsComponent>;
	private _isUpdatedIncidentQueue: boolean;

	readonly entityType: EntityType<Incident> = {
		id: 'incident',
		entity: Incident,
		icon: FabricIconNames.ATPLogo,
		getImage: (incidents: ReadonlyArray<Incident>) => {
			const commonSeverity: Severity = GlobalEntityTypesService.getCommonValue(
				incidents,
				incident => incident.severity
			);

			return `/assets/images/icons/entities/incident_${
				commonSeverity ? commonSeverity.name.toLowerCase() : 'informational'
			}.svg`;
		},
		entityDetailsComponentType: IncidentEntityDetailsComponent,
		entityContentsComponentType: IncidentEntityComponent,
		singleEntityPanelComponentType: IncidentEntityPanelComponent,
		multipleEntitiesComponentType: IncidentsEntityPanelComponent,
		getEntityName: (incident: Incident) => incident.name,
		entityPluralNameKey: 'incident_entityType_pluralName',
		entitySingularNameKey: 'incident_entityType_singularName',
		getEntityDataviewLink: () => '/incidents',
		getEntitiesLink: (incidents: Array<Incident>) =>
			incidents.length === 1 ? `/incidents/${incidents[0].id}` : null,
		maxNameLength: 50,
		getRenameFunction: (newName: string, incidentId: string) => {
			return this.paris
				.apiCall(IncidentsUpdateApiCall, {
					title: newName,
					incidentIds: [incidentId],
				})
				.pipe(mergeMap(() => this.paris.getItemById(Incident, incidentId)));
		},
		getEnableRename: (incident: Incident) => {
			const rbacSettings = {
				mtpPermissions: [MtpPermission.SecurityData_Manage],
				mtpWorkloads: incident.mtpWorkloads,
				requireAllPermissions: true,
				rbacIgnoreNonActiveWorkloads: true,
			};
			return incident.isFullyMachineRbacExposed && this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
		},
		getTags: (incidents: Array<Incident>) => {
			if (incidents.length === 1) {
				const incident = incidents[0];
				return incident.incidentTags ? of(this.entityTagsService.getIncidentTags(incident))
					: of([
						...incident.tags.map(tag => ({
							id: tag,
							name: tag,
							type: TagType.user,
						})),
					]);
			}

			const incidentsTagCounts = countBy(flatMap(incidents, incident => incident.tags));
			const incidentsTags = Object.entries(incidentsTagCounts).map(
				([tag, count]) => new Tag({ id: tag, name: tag, isPartial: count < incidents.length })
			);

			return of(sortBy(incidentsTags, tag => tag.name.toLowerCase()));
		},
		getNavigationModel: (incident: Incident) => {
			const link = this.entityType.getEntitiesLink([incident]);
			return link ? { routerLink: [link] } : null;
		},
		getActions: (incidents: Array<Incident>, options, entityViewType: EntityViewType) => {
			let actions: Array<ItemActionModelConfig> = [];

			const allAlertsAssignedToCurrentUser: boolean = incidents.every(incident =>
				this.authService.isCurrentUser(incident.assignedTo)
			);

			const isIncidentResolved =
				incidents.length === 1 &&
				incidents[0] &&
				incidents[0].status &&
				incidents[0].status.id &&
				incidents[0].status.id === IncidentStatusValueIds.IncidentResolvedStatusId;

			const userFullyExposed: boolean = incidents.every(incident => incident.isFullyMachineRbacExposed);
			if (entityViewType === EntityViewType.EntityPage) {
				actions = this.getEntityPageViewTypeActionButtonsArray(incidents, userFullyExposed);
			} else {
				// incident side panel actions
				actions = this.getEntitySidePanelActionButtonsArray(
					userFullyExposed,
					isIncidentResolved,
					allAlertsAssignedToCurrentUser,
					entityViewType,
					incidents
				);
			}

			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
		entityPageViewMode: EntityPageViewMode.Modern,
	};

	getChildLinks(incident: Incident) {
		return {
			mailboxes: `/incidents/${incident.id}/mailboxes`,
			users: `/incidents/${incident.id}/users`,
			machines: `/incidents/${incident.id}/machines`,
			evidence: `/incidents/${incident.id}/evidence`,
			apps: `/incidents/${incident.id}/apps`,
		};
	}

	private incidentsHaveServiceSource(serviceSourceType: ServiceSourceType, incidents: ReadonlyArray<Incident>) {
		incidents.forEach((incident) => {
			if (incident.serviceSources.find((serviceSource) => serviceSource.id === serviceSourceType)) {
				return true;
			}
		})
		return false;
	}

	private getEntityPageViewTypeActionButtonsArray(
		incidents: ReadonlyArray<Incident>,
		userFullyExposed: boolean
	): Array<ItemActionModelConfig> {
		const actions: Array<ItemActionModelConfig> = [this.commentsButton];
		const workloads = uniq(flatMap(incidents.map(inc => inc.mtpWorkloads)));

		// TODO: Remove this logic when management team support MAPG
		const extraRbacCheck = this.incidentsHaveServiceSource(ServiceSourceType.Mapg, incidents) ? this.authService.currentUser.hasDefaultManagePermissions: null;

		if (this.askThreatExpertService.shouldShowThreatExpertPanel()) {
			actions.unshift(
				this.askThreatExpertService.getAskTheExpertCommandConfig('incidentEntityCommandBar')
			);
		}

		if (userFullyExposed) {
			actions.unshift({ ...this.manageIncidentButton, rbacMtpWorkloads: workloads});
		}

		return actions;
	}

	private getEntitySidePanelActionButtonsArray(
		userFullyExposed: boolean,
		isIncidentResolved: boolean,
		allAlertsAssignedToCurrentUser: boolean,
		entityViewType: EntityViewType,
		incidents: Array<Incident>
	): Array<ItemActionModelConfig> {
		const actions: Array<ItemActionModelConfig> = [];
		if (!userFullyExposed) {
			return actions;
		}
		const workloads = uniq(flatMap(incidents.map(inc => inc.mtpWorkloads)));

		if (incidents.length===1) {
			actions.push({ ...this.manageIncidentButton, rbacMtpWorkloads: workloads });
		}

		if (!allAlertsAssignedToCurrentUser && !this.featuresService.isEnabled(Feature.AssignToSomeoneElse)) {
			actions.push({ ...this.assignToMeButton, rbacMtpWorkloads: workloads });
		}

		if (entityViewType === EntityViewType.MultipleEntitySidePanel) {
			actions.push({ ...this.setStatusAndClassificationButton, rbacMtpWorkloads: workloads });
			return actions;
		}


		return actions;
	}
}
