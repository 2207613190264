import { AirsEntity, AirsEntityType } from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
var EntityDetailsComponent = /** @class */ (function () {
    function EntityDetailsComponent(featuresService, i18nService) {
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.AirsEntityType = AirsEntityType;
        this.showDeepLink = !this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence);
    }
    Object.defineProperty(EntityDetailsComponent.prototype, "allowStatusSplit", {
        set: function (val) {
            var featuresEnabled = this.featuresService.isEnabled(Feature.RemediationStatusSplit);
            this.useNewStatus = val && featuresEnabled;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * This function will create a compile time error in case an entity type is omitted from the switch.
     * It is not called in runtime, it's just here to remind the developer to add new types to the component
     * mapping.
     **/
    EntityDetailsComponent.prototype.validateTypeComponent = function (type) {
        switch (type) {
            case 'File':
            case 'Process':
            case 'Driver':
            case 'Service':
            case 'URL':
            case 'IP':
            case 'AppendedFile':
            case 'EmailUrl':
            case 'User':
            case 'WebPage':
            case 'PersistenceMethod':
            case 'UserActivity':
            case 'MailCluster':
            case 'MailMessage':
            case 'Mailbox':
            case 'SubmissionMail':
            case 'MailboxConfiguration':
            case 'Account':
            case 'SecurityGroup':
            case 'RegistryKey':
            case 'RegistryValue':
            case 'CloudApplication':
            case 'CloudLogonSession':
            case 'OauthApplication':
            case 'ActiveDirectoryDomain':
                return true;
        }
        function assertType(x) {
            throw new Error("type '" + x + "' is not mapped to a component");
        }
        // if you got here, you should add your entity type to the component selector in the template above
        // and to the switch
        return assertType(type);
    };
    return EntityDetailsComponent;
}());
export { EntityDetailsComponent };
