import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Profiles filters',
	pluralName: 'Profiles filters',
	readonly: true,
})
export class ProfileFiltersFields extends ModelBase {
	@EntityField() name: string;
	@EntityField() filters: string[];
}
