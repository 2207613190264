import { Entity, EntityModelBase, EntityField, DataSet } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '@wcd/domain';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Comparison result',
	pluralName: 'Comparison results',
	endpoint: 'compare',
})
export class ComparisonResultSingularEntity extends EntityModelBase<string> {
	@EntityField({ data: 'cveId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField()
	readonly cveId: string;

	@EntityField()
	readonly otherRecommendationInsight: string;

	@EntityField()
	readonly machinesDetectedByOther: string[];

	@EntityField()
	readonly machinesDetectedByTvm: string[];

	@EntityField()
	readonly intersectionDetectedMachines: string[];

	@EntityField()
	readonly machinesDetectedOnlyByOther: string[];

	@EntityField()
	readonly machinesDetectedOnlyByTvm: string[];

	@EntityField()
	readonly matchPercent: number;

	@EntityField()
	readonly isFoundByTvm: boolean;

	@EntityField()
	readonly isFoundByOther: boolean;
}
