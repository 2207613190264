import { Entity } from '@microsoft/paris';
import { airsEntityStatusValues } from './airs-entity-status.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';

@Entity({
	singularName: 'Entity Verdict',
	pluralName: 'Entity Verdicts',
	values: airsEntityStatusValues.filter(s => s.isVerdict),
})
export class AirsEntityVerdict extends AirsEntityStatus {}
