import { ChangeDetectorRef, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { DeviceComplianceStatisticsApiCall, ProfilesStatistics } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
var CompliantDevicesDataviewCellComponent = /** @class */ (function () {
    function CompliantDevicesDataviewCellComponent(paris, changeDetectionRef, i18nService) {
        this.paris = paris;
        this.changeDetectionRef = changeDetectionRef;
        this.i18nService = i18nService;
        this.isImprovementNegative = false;
    }
    Object.defineProperty(CompliantDevicesDataviewCellComponent.prototype, "assetsCountHistory", {
        get: function () {
            return this._assetsCountHistory;
        },
        set: function (assetsCountHistory) {
            this._assetsCountHistory = assetsCountHistory && assetsCountHistory.length === 1 ? assetsCountHistory.concat(assetsCountHistory) :
                assetsCountHistory;
        },
        enumerable: true,
        configurable: true
    });
    CompliantDevicesDataviewCellComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (this.assetsCount === -1) {
            this.isLoading = true;
            var statistics$ = this.paris.apiCall(DeviceComplianceStatisticsApiCall, { profileId: this.profileId });
            this._subscription && this._subscription.unsubscribe();
            this._subscription = statistics$.subscribe(function (statistics) {
                _this.assetsCount = statistics.passedDevices;
                _this.totalAssetCount = statistics.totalDevices;
                _this.assetsCountHistory = [_this.assetsCount, _this.assetsCount];
                _this.isLoading = false;
                _this.changeDetectionRef.detectChanges();
            });
        }
        this.changeDetectionRef.detectChanges();
    };
    CompliantDevicesDataviewCellComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    return CompliantDevicesDataviewCellComponent;
}());
export { CompliantDevicesDataviewCellComponent };
