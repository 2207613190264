import { Component } from '@angular/core';

@Component({
	selector: 'tvm-dashboard-empty',
	template: `
		<section
			aria-labelledby="tvm-dashboard-header"
			class="tvm-dashboard-container wcd-full-height wcd-flex-horizontal"
		>
			<div class="wcd-full-height wcd-flex-center-all" data-track-component="tvmDashboardEmpty">
				<h4>
					{{ 'common.noDataToShow' | i18n }}
				</h4>
			</div>
			<tvm-machine-groups-filter-control></tvm-machine-groups-filter-control>
		</section>
	`,
	styleUrls: ['./tvm.dashboard-empty.component.scss'],
})
export class TvmDashboardEmptyComponent {}
