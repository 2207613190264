import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertClassification', alertClassificationFilter);

function alertClassificationFilter() {
	return function(input) {
		if (!input) {
			return '';
		}

		switch (input) {
			case 0:
			case '0':
			case 'Unknown':
				return 'Not set';
			case 10:
			case '10':
			case 'FalsePositive':
				return 'False alert';
			case 20:
			case '20':
			case 'TruePositive':
				return 'True alert';
			case 'Multiple':
				return 'Multiple';
			default:
				return null;
		}
	};
}
