<section class="wcd-margin-large-bottom" data-track-component-type="Settings" data-track-component="NonWindows FirstParty Offboarding">
	<div class="wcd-margin-bottom">
		<h3 class="wcd-padding-bottom">{{'endpointManagement.nonWindows.offboarding.firstParty.step1.title' | i18n}}</h3>
		<div class="wcd-padding-bottom">
			<markdown [data]="'endpointManagement.nonWindows.offboarding.firstParty.step1.descriptionA' | i18n"></markdown>
		</div>
		<div class="wcd-margin-bottom">
			<markdown [data]="'endpointManagement.nonWindows.offboarding.firstParty.step1.descriptionB' | i18n"></markdown>
		</div>
		<div class="wcd-margin-bottom">
			<markdown [data]="'endpointManagement.nonWindows.offboarding.firstParty.step1.descriptionC' | i18n"></markdown>
		</div>
	</div>
	<section class="form-section">
		<div class="form-group">
			<label for="onboard-method">{{'endpointManagement.nonWindows.offboarding.firstParty.step1.deploymentMethod' | i18n}}</label>
			<div>
				<fancy-select [(ngModel)]="currentMethod"
					listId="onboard-method"
					name="onboard-method"
					[formatLabel]="getDeploymentMethodLabel"
					[isBordered]="true"
					labelClass="dropdown-width-medium"
					[values]="packages"></fancy-select>
			</div>
		</div>
		<div class="wcd-margin-bottom wcd-padding-small-all color-box-gray-100">
			<markdown [data]="'endpointManagement.deploymentMethods.' + currentMethod.name + '.description' | i18n"></markdown>
		</div>
		<div>
			<button class="btn btn-primary"
					[disabled]="!currentMethod"
					[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
					data-track-id="DownloadMacOsOffboarding"
					data-track-type="Button"
					(click)="confirmAndDownloadPackage()">
				 <wcd-shared-icon iconName="download"> </wcd-shared-icon >
				{{"endpointManagement.nonWindows.offboarding.firstParty.step1.download" | i18n}}
			</button>
		</div>
	</section>
</section>
