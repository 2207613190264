import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { Machine, MachineLoggedOnUsers, MachineUserDetailsAccount, MachineBackendOptions } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { MachineUserDetailsAccountFields } from '../services/machine-user-details-account.fields';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'machine-logged-on-users',
	providers: [MachineUserDetailsAccountFields],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			class="wcd-full-height wcd-flex-vertical"
			data-track-component="Logged-on users panel"
			data-track-component-type="Side Panel"
		>
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="page-header-icon wcd-flex-none">
					<wcd-shared-icon class="round-icon color-box-gray-200" iconName="users.user">
					</wcd-shared-icon>
				</div>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="machine-logged-on-users-panel-header" #panelHeader>
						{{ 'machines.loggedOnUsers.title' | i18n }}
						<span class="ms-color-gray140">{{
							'machines.loggedOnUsers.subtitle' | i18n: { days: 30 }
						}}</span>
					</h3>
					<div class="page-subtitle">
						<fab-icon iconName="System" className="small-icon"></fab-icon>
						{{ globalEntityTypesService.getEntityType('machine').getEntityName(machine) }}
					</div>
				</div>
			</header>
			<dataview
				class="wcd-flex-1"
				*ngIf="machine"
				id="machine-logged-on-users"
				className="no-left-padding"
				[repository]="machineLoggedOnUsersRepository"
				[navigateOnChange]="false"
				[allowPaging]="false"
				[allowFilters]="false"
				[fields]="machineUserFields"
				[defaultSortFieldId]="'loginCount'"
				[disableSelection]="true"
				[label]="'machines.report.widgets.loggedOnUsers.data.plural' | i18n"
				[ignoreQueryParams]="true"
				[fixedOptions]="backendOptions"
			></dataview>
		</div>
	`,
})
export class MachineLoggedOnUsersComponent implements OnInit {
	@Input() machine: Machine;

	readonly machineLoggedOnUsersRepository: RelationshipRepository<Machine, MachineUserDetailsAccount>;
	machineUserFields: Array<DataviewField>;

	readonly backendOptions: MachineBackendOptions;

	constructor(
		paris: Paris,
		featuresService: FeaturesService,
		private readonly machineUserDetailsAccountFields: MachineUserDetailsAccountFields,
		public readonly globalEntityTypesService: GlobalEntityTypesService
	) {
		this.machineLoggedOnUsersRepository = paris.getRelationshipRepository(MachineLoggedOnUsers);
		this.backendOptions = { isUnifiedDevicePage: featuresService.isEnabled(Feature.NewDevicePage), isVnext:featuresService.isEnabled(Feature.HuntingEnableUserVnextMigration) };
	}

	ngOnInit() {
		this.machineLoggedOnUsersRepository.sourceItem = this.machine;
		this.machineUserFields = this.machineUserDetailsAccountFields.getFieldsByMachine(this.machine);
	}
}
