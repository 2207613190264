import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Device incident count',
	pluralName: 'Device incident count',
	readonly: true,
})
export class MachineIncidentCount extends ModelBase {
	@EntityField({ data: 'IncidentCount', defaultValue: 0 })
	count: number;
}
