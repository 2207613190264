import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileCurrentInstancesCount } from '../file.current-instances-count.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileCurrentInstancesCount,
	foreignKey: 'sha1',
	fixedData: { resultsLimit: 1000 },
	endpoint: (_, query) => query.where['filesPrefix'] ? 'files/FileObservedMachines' : 'FileObservedMachines',
	baseUrl: config => config.data.serviceUrls.fileprevalence,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	parseData: (rawData: FileCurrentInstancesRawData) => ({
		machineCount: rawData.length,
		fileCount: rawData.reduce((_fileCount, machine) => _fileCount + machine.Files.length, 0),
	}),
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
})
export class FileCurrentInstancesRelationship
	implements EntityRelationshipRepositoryType<File, FileCurrentInstancesCount> {}

type FileCurrentInstancesRawData = Array<{
	Files: Array<{}>;
}>;
