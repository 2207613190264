var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentLinkedByEntity } from './incident-linked-by-entity.value-object';
var ɵ0 = ['Category', 'category'], ɵ1 = ['SourceEntity', 'sourceEntity'], ɵ2 = ['TargetEntities', 'targetEntities'], ɵ3 = ['LinkedAlertsCount', 'linkedAlertsCount'];
var AggregatedIncidentLinkedBy = /** @class */ (function (_super) {
    __extends(AggregatedIncidentLinkedBy, _super);
    function AggregatedIncidentLinkedBy() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ0, required: true }),
        __metadata("design:type", String)
    ], AggregatedIncidentLinkedBy.prototype, "category", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", IncidentLinkedByEntity)
    ], AggregatedIncidentLinkedBy.prototype, "sourceEntity", void 0);
    __decorate([
        EntityField({ data: ɵ2, arrayOf: IncidentLinkedByEntity }),
        __metadata("design:type", Array)
    ], AggregatedIncidentLinkedBy.prototype, "targetEntities", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", Number)
    ], AggregatedIncidentLinkedBy.prototype, "linkedAlertsCount", void 0);
    AggregatedIncidentLinkedBy = __decorate([
        ValueObject({
            singularName: 'Aggregated incident link reason',
            pluralName: 'Aggregated incident link reasons',
            readonly: true,
        })
    ], AggregatedIncidentLinkedBy);
    return AggregatedIncidentLinkedBy;
}(ModelBase));
export { AggregatedIncidentLinkedBy };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
