import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { LogType } from '../enums/log-type.enum';
var MS_TENANTS = [
    'f839b112-d9d7-4d27-9bf6-94542403f21c',
    'b3c1b5fc-828c-45fa-a1e1-10d74f6d6e9c',
    '72f988bf-86f1-41af-91ab-2d7cd011db47',
    'f686d426-8d16-42db-81b7-ab578e110ccd',
    '8ae383c5-8624-4ea3-9849-fc2bbd8fa79c',
];
var DEPRECATED_LOG_TYPES = [];
var DataExportService = /** @class */ (function () {
    function DataExportService(i18nService, authService) {
        var _this = this;
        this.i18nService = i18nService;
        this.authService = authService;
        this.availableLogTypes = Object.keys(LogType)
            .filter(function (lt) { return LogType[lt] !== LogType.DynamicEventCollection || _this.isMsTenant; })
            .map(function (logType) { return _this.logTypeToChecklistValue(logType); });
        this.notDeprecatedAvailableLogTypes = Object.keys(LogType)
            .filter(function (lt) {
            return (LogType[lt] !== LogType.DynamicEventCollection || _this.isMsTenant) &&
                !_this.isDeprecatedLogType(LogType[lt]);
        })
            .map(function (logType) { return _this.logTypeToChecklistValue(logType); });
    }
    DataExportService.prototype.logTypeToChecklistValue = function (logTypeKey) {
        return {
            id: logTypeKey,
            name: this.i18nService.get("endpointManagement.nonWindows.applications.dataExport.logTypes." + LogType[logTypeKey].replace('-', '') // converting the enum value (kebab-case) to our locstring format (snake_case or CamelCase)
            ),
        };
    };
    DataExportService.prototype.isDeprecatedLogType = function (logTypeValue) {
        return !!DEPRECATED_LOG_TYPES.find(function (lt) { return lt === logTypeValue; });
    };
    Object.defineProperty(DataExportService.prototype, "isMsTenant", {
        get: function () {
            var currTenant = this.authService.tenantId;
            return !!MS_TENANTS.find(function (tenant) { return tenant.toLowerCase() === currTenant.toLowerCase(); });
        },
        enumerable: true,
        configurable: true
    });
    return DataExportService;
}());
export { DataExportService };
