import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupModule } from '@progress/kendo-angular-popup';

import { ClickOutsideDirective } from './directives/click-outside.directive';
import {
	ContextMenuActionDirective,
	ContextMenuComponent,
	ContextMenuTargetDirective,
} from './context-menu/context-menu.component';
import { HuntingSettingsService } from './services/settings.service';

const declarations = [ClickOutsideDirective];
const exportedDeclarations = [ContextMenuComponent, ContextMenuActionDirective, ContextMenuTargetDirective];

@NgModule({
	imports: [CommonModule, PopupModule],
	declarations: [...declarations, ...exportedDeclarations],
	exports: [...exportedDeclarations],
	providers: [HuntingSettingsService],
})
export class HuntingSharedModule {}
