import { AfterViewInit, ChangeDetectorRef, ElementRef, OnInit, ViewContainerRef, } from '@angular/core';
import { TooltipDirective, TooltipsService } from '@wcd/dialogs';
import { Disableable } from '../../shared/interfaces/disableable.interface';
import { RbacControlService } from '../services/rbac-control.service';
import { RbacControlState } from '../models/rbac-control-settings.model';
import { ReactWrapperComponent } from '@angular-react/core';
import { FabActionButtonComponent, FabCommandBarButtonComponent, FabCompoundButtonComponent, FabDefaultButtonComponent, FabIconButtonComponent, FabMessageBarButtonComponent, FabPrimaryButtonComponent, FabSplitButtonComponent, } from '@angular-react/fabric';
import { castArray } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { WicdSanitizerService } from '@wcd/shared';
var disabledClassName = 'disabled-not-allowed';
// TODO: The base button type is not exposed from @angular-react/fabric - can remove this when it's exposed.
var FabButtonTypes = [
    FabDefaultButtonComponent,
    FabPrimaryButtonComponent,
    FabIconButtonComponent,
    FabActionButtonComponent,
    FabCompoundButtonComponent,
    FabMessageBarButtonComponent,
    FabSplitButtonComponent,
    FabCommandBarButtonComponent,
];
var RbacControlDirective = /** @class */ (function () {
    function RbacControlDirective(rbacControlService, element, changeDetectorRef, domSanitizer, disableableComponent, tooltipsService, vcr, i18nService) {
        this.rbacControlService = rbacControlService;
        this.element = element;
        this.changeDetectorRef = changeDetectorRef;
        this.domSanitizer = domSanitizer;
        this.disableableComponent = disableableComponent;
        this.tooltipsService = tooltipsService;
        this.vcr = vcr;
        this.i18nService = i18nService;
        this.noPermissionTooltip = this.i18nService.get('common.permissions.noPermissionTooltip');
    }
    RbacControlDirective.prototype.ngOnInit = function () {
        if (!this.rbac) {
            return;
        }
        this.hasRequiredPermission = this.rbacControlService.hasRequiredRbacPermissions(this.rbac);
        if (!this.hasRequiredPermission) {
            if (this.rbac.state === RbacControlState.hidden) {
                this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
            }
            else {
                this.disableHost();
            }
        }
    };
    RbacControlDirective.prototype.ngOnChanges = function (changes) {
        if (this.hasRequiredPermission === false) {
            this.disableHost();
        }
    };
    RbacControlDirective.prototype.ngAfterViewInit = function () {
        if (this.element.nativeElement.blur) {
            this.element.nativeElement.blur();
        }
    };
    RbacControlDirective.prototype.onMouseDown = function ($event) {
        this.tooltip.onMouseLeave();
        $event.preventDefault();
        $event.stopPropagation();
    };
    RbacControlDirective.prototype.disableHost = function () {
        // if the host is a component that implements Disableable, let it handle how it disables everything
        if (this.disableableComponent) {
            this.disableDisableableComponent();
        }
        else if (this.isReactWrapperComponent(this.hostComponent)) {
            this.disableReactElement(this.hostComponent);
            this.setHostTooltip();
        }
        else {
            this.disableElement();
            this.setHostTooltip();
        }
    };
    RbacControlDirective.prototype.disableDisableableComponent = function () {
        if (this.disableableComponent) {
            this.disableableComponent.isDisabled = true;
            this.disableableComponent.readonly = true;
            this.disableableComponent.disableReason = this.noPermissionTooltip;
            this.changeDetectorRef.markForCheck();
        }
    };
    RbacControlDirective.prototype.disableElement = function () {
        if (!this.element.nativeElement.classList.contains(disabledClassName)) {
            this.element.nativeElement.classList.add(disabledClassName);
        }
    };
    RbacControlDirective.prototype.setHostTooltip = function () {
        var _this = this;
        this.tooltip = new TooltipDirective(this.element, this.tooltipsService, this.domSanitizer);
        this.tooltip.tooltipHTML = this.noPermissionTooltip;
        [
            'mouseover',
            'mouseenter',
            'mousemove',
            'mousedown',
            'mouseup',
            'click',
            'keydown',
            'keypress',
            'keyup',
        ].forEach(function (eventName) {
            _this.element.nativeElement.removeAllListeners(eventName);
        });
        this.element.nativeElement.addEventListener('mouseenter', this.tooltip.onMouseEnter.bind(this.tooltip));
        this.element.nativeElement.addEventListener('mouseleave', this.tooltip.onMouseLeave.bind(this.tooltip));
        ['mousedown', 'mouseup', 'click'].forEach(function (e) {
            _this.element.nativeElement.addEventListener(e, _this.onMouseDown.bind(_this));
        });
        this.changeDetectorRef.markForCheck();
    };
    Object.defineProperty(RbacControlDirective.prototype, "hostComponent", {
        get: function () {
            // this is a private API to access the underlying component in Angular - unfortunately, there's no public API for accessing a dynamic
            // component (i.e. not knowing which one it'll at compile-time) from a directive, or even one that's inherited from.
            return (this.vcr['_data'] && this.vcr['_data'].componentView && this.vcr['_data'].componentView.component);
        },
        enumerable: true,
        configurable: true
    });
    RbacControlDirective.prototype.isReactWrapperComponent = function (component) {
        return this.hostComponent && this.hostComponent instanceof ReactWrapperComponent;
    };
    RbacControlDirective.prototype.isFabButton = function (component) {
        return FabButtonTypes.some(function (ButtonType) { return component instanceof ButtonType; });
    };
    RbacControlDirective.prototype.disableReactElement = function (component) {
        if (this.isFabButton(component)) {
            this.disableFabButton(component);
        }
        else {
            this.setClassOnReactElement(component, disabledClassName);
        }
    };
    RbacControlDirective.prototype.disableFabButton = function (component) {
        // We want to always have the button disabled - override the `disabled` field
        Object.defineProperty(component, 'disabled', {
            get: function () {
                return true;
            },
            set: function (_val) { },
        });
    };
    RbacControlDirective.prototype.setClassOnReactElement = function (component, className) {
        var existingClasses = (component.contentClass && castArray(component.contentClass)) || [];
        component.contentClass = existingClasses.concat([className]);
    };
    return RbacControlDirective;
}());
export { RbacControlDirective };
