<div class="wcd-full-height wcd-flex-vertical">
	<div *ngIf="isWebCategoryEnabled$ | async as isWebCategoryEnabled; else notEnabledBlock" class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
		<section class="wcd-padding-bottom">
			<h4 class="definition-list-title">
				{{ "webContentFilteringPolicy.sidePane.tabs.action.title" | i18n}}
			</h4>
			<dl [class.key-values]="true" role="none">
				<dt>{{ "webContentFilteringPolicy.sidePane.sections.action.description.selected" | i18n}}</dt>
				<dd>
					<wcd-filter-values-checklist
						[config]="categoryChecklistConfig"
						[fieldId]="'web-content-filtering-category-checklist'"
						[disableSelectAll]="true"
						(filterValuesChange)="onBlockedCategoriesChange($event)"
						[elementVisible]="isWebCategoryEnabled$ | async"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }">
					</wcd-filter-values-checklist>
				</dd>
			</dl>
		</section>
		<section class="wcd-padding-bottom" *ngIf='isScopeVisible'>
			<h4 class="wcd-margin-small-bottom">
				{{ "webContentFilteringPolicy.sidePane.tabs.scope.title" | i18n}}
			</h4>
			<dl [class.key-values]="true" role="none">
				<dt>{{ "webContentFilteringPolicy.sidePane.sections.organizationalscope.description.selected" | i18n}}</dt>
				<dd>
					<ng-container *ngIf="loadingMachineGroups; else machineGroupsFields">
						<fab-icon iconName="ProgressRingDots" class="wcd-margin-right"></fab-icon>
						{{'webContentFilteringPolicy.sidePane.sections.organizationalscope.groups.loading' | i18n}}
					</ng-container>

					<ng-template #machineGroupsFields>
						<ng-container *ngIf="allowEdit; else showSelectedMachineGroupsNames">
							<ng-container *ngIf="allowAllMachineGroups">
								<wcd-radiolist [values]="selectableMachineGroupScopes"
									name="web-content-filtering-policy-machine-group-options"
									data-field-label="Machine groups"
									(ngModelChange)="onMachineGroupChange($event)"
									[(ngModel)]="selectedMachineGroupScope">
								</wcd-radiolist>
							</ng-container>
							<ng-container *ngIf="allowSpecificMachineGroups && selectedMachineGroupScope.id === ScopeTypesEnum.Specific">
								<wcd-checklist-dropdown
									[id]="machineGroupsFieldId"
									[buttonText]="getLabelText(machineGroupsFieldId)"
									name="web-content-filtering-policy-machine-groups"
									[buttonTooltip]="getLabelText(machineGroupsFieldId)"
									[selectById]="false"
									[ngModel]="selectedMachineGroups"
									[isFullWidth]="true"
									(ngModelChange)="onMachineGroupsChange($event)"
									[values]="availableMachineGroups">
								</wcd-checklist-dropdown>
							</ng-container>
						</ng-container>

						<ng-template #showSelectedMachineGroupsNames>
							<ng-container *ngIf="!selectedMachineGroupsNames || !selectedMachineGroupsNames.length">
								<div class="dialog-control-description subtle">
									{{ 'webContentFilteringPolicy.sidePane.sections.organizationalscope.description.organization' | i18n}}
								</div>
							</ng-container>

							<div class="wcd-margin-small-bottom" *ngFor="let name of selectedMachineGroupsNames">{{name}}</div>
						</ng-template>
					</ng-template>
				</dd>
			</dl>
		</section>
	</div>
	<ng-template #notEnabledBlock>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
			<section class="wcd-padding-bottom">
				<h4 class="definition-list-title">
					{{ "webContentFilteringPolicy.sidePane.tabs.details.title" | i18n}}
				</h4>
				<dl [class.key-values]="true" role="none">
					<dt>{{ "webContentFilteringPolicy.dataview.entity.fields.policyName" | i18n}}</dt>
					<dd><span>{{policy.policyName}}</span></dd>
				</dl>
			</section>
			<section class="wcd-padding-bottom">
				<h4 class="definition-list-title">
					{{ "webContentFilteringPolicy.sidePane.tabs.action.title" | i18n}}
				</h4>
				<dl [class.key-values]="true" role="none">
					<dt>{{ "webContentFilteringPolicy.sidePane.sections.action.description.selected" | i18n}}</dt>
					<dd>
						<ng-container *ngIf="blockedCategories && blockedCategories.length; else noBlockedCategoies">
							<div class="wcd-margin-small-bottom" *ngFor="let categoryName of blockedCategories">{{categoryName}}</div>
						</ng-container>

						<ng-template #noBlockedCategoies>
							<span>{{ "webContentFilteringPolicy.sidePane.sections.action.description.noBlockedCategories" | i18n}}</span>
						</ng-template>
					</dd>
				</dl>
			</section>
			<section class="wcd-padding-bottom">
				<h4 class="wcd-margin-small-bottom">
					{{ "webContentFilteringPolicy.sidePane.tabs.scope.title" | i18n}}
				</h4>
				<dl [class.key-values]="true" role="none">
					<dt>{{ "webContentFilteringPolicy.sidePane.sections.organizationalscope.description.selected" | i18n}}</dt>
					<dd>
						<div *ngIf="selectedMachineGroups && selectedMachineGroups.length; else selectedMachineGroupsReview">
							<div *ngFor="let group of selectedMachineGroups" class="wcd-margin-small-bottom">{{group.name}}</div>
						</div>
						<ng-template #selectedMachineGroupsReview>
							{{ 'webContentFilteringPolicy.sidePane.sections.organizationalscope.description.organization' | i18n}}
						</ng-template>
					</dd>
				</dl>
			</section>
		</div>
	</ng-template>

	<footer class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
		<div *ngIf="isWebCategoryEnabled$ | async" class="wcd-flex-1 wcd-margin-right" [rbac]="{ permissions: ['securitySettings'], state: 'disabled' }">
			<button
					type="button"
					class="btn btn-primary color-box-primary"
					data-track-id="Save"
					data-track-type="Button"
					data-track-component="WebContentFilteringPolicyEdit"
					[disabled]="!isValid || !isDirty || isSaving"
					[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
					(click)="save()">
				<fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall" className="wcd-margin-xsmall-horizontal"></fab-spinner>
				{{ isSaving ? '' : 'buttons.save' | i18n }}
			</button>
		</div>
	</footer>
</div>


