import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	Directive,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	QueryList,
} from '@angular/core';
import { Offset } from '@progress/kendo-angular-popup';

// tslint:disable-next-line:directive-selector
@Directive({ selector: 'wcd-hunting-context-menu menu-item' })
export class ContextMenuActionDirective<TContext> {
	@Input() label: string;

	@Output() readonly trigger = new EventEmitter<ContextMenuTargetDirective<TContext>>();
}

@Directive({ selector: '[wcdHuntingContextMenuTarget]' })
export class ContextMenuTargetDirective<TContext> {
	@Input('wcdHuntingContextMenuTarget') context?: TContext;

	constructor(readonly elementRef: ElementRef<HTMLElement>) {}
}

@Component({
	selector: 'wcd-hunting-context-menu',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./context-menu.component.scss'],
	template: `
		<!-- using a span instead of a div here so the element doesn't have an height -->
		<span (contextmenu)="onContextMenu($event)">
			<ng-content></ng-content>

			<kendo-popup
				*ngIf="enableContextMenu !== false && show"
				[offset]="offset"
				class="context-menu"
				(wcdClickOutside)="show = false"
			>
				<ul
					class="context-menu__list"
					[class.context-menu__list--capped-width]="maximumLabelLength != null"
				>
					<li *ngFor="let action of actions" (click)="onActionClicked(action)">
						{{
							maximumLabelLength && action.label.length > maximumLabelLength
								? (action.label | slice: 0:maximumLabelLength) + '...'
								: action.label
						}}
					</li>
				</ul>
			</kendo-popup>
		</span>
	`,
})
export class ContextMenuComponent<TContext> {
	@ContentChildren(ContextMenuActionDirective)
	readonly actions: QueryList<ContextMenuActionDirective<TContext>>;
	@ContentChildren(ContextMenuTargetDirective, { descendants: true })
	readonly targets: QueryList<ContextMenuTargetDirective<TContext>>;

	@Input() maximumLabelLength?: number;

	/**
	 * Enable or disable the context menu.
	 * @default `true`
	 * @note This is needed and not just a simple `[ngIf]` since some users of this component want to render the same content with or
	 * without the context menu (in different configurations), leading to the following [example] code:
	 * ```html
	 * <ng-template #content>some content</ng-template>
	 *
	 * <wcd-hunting-context-menu *ngIf="someCondition;else content">
	 *   <ng-container *ngTemplateOutlet="content"></ng-container>
	 * </wcd-hunting-context-menu>
	 * ```
	 * However, `ViewChildren` nested inside the templates only get the context of the actual hierarchy in the template, not the DOM - and thus
	 * any element with `[wcdHuntingContextMenuTarget]` won't be found in `ViewChildren` if the `ng-template` definition is outside the context-menu component.
	 */
	@Input() enableContextMenu?: boolean;

	@Output() readonly beforeContextMenuShown = new EventEmitter<ContextMenuTargetDirective<TContext>>(false);

	show = false;
	offset: Offset;
	targetItem: ContextMenuTargetDirective<TContext>;

	constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

	onContextMenu(event: MouseEvent) {
		if (this.enableContextMenu === false) {
			return;
		}

		const triggerElement = (event.target || event.srcElement || event.currentTarget) as Node;
		this.targetItem = this.targets.find(target =>
			target.elementRef.nativeElement.contains(triggerElement)
		);

		if (!this.targetItem) {
			return;
		}

		event.preventDefault();

		this.beforeContextMenuShown.emit(this.targetItem);

		this.offset = {
			top: event.clientY,
			left: event.clientX,
		};

		this.show = true;

		this.changeDetectorRef.markForCheck();
	}

	onActionClicked(action: ContextMenuActionDirective<TContext>) {
		action.trigger.emit(this.targetItem);

		this.show = false;
		this.changeDetectorRef.markForCheck();
	}
}
