import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Related Evidence',
	pluralName: 'Related Evidences',
	readonly: true,
})
export class RelatedEvidenceCount extends ModelBase {
	@EntityField({ data: 'Mailbox' })
	readonly mailboxes?: number;

	@EntityField({ data: 'Url' })
	readonly urls?: number;

	@EntityField({ data: 'User' })
	readonly users?: number;

	@EntityField({ data: 'File' })
	readonly files?: number;

	@EntityField({ data: 'Machine' })
	readonly machines?: number;

	@EntityField({ data: 'Ip' })
	readonly ips?: number;

	@EntityField({ data: 'Process' })
	readonly processes?: number;

	@EntityField({ data: 'MailCluster' })
	readonly mailClusters?: number;

	@EntityField({ data: 'MailMessage' })
	readonly mailMessages?: number;
}
