import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, Input } from '@angular/core';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { AuthService, MtpPermission } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { EmailNotificationFormData } from './email-notification-model';
import { ApiCallType } from '@microsoft/paris/dist/lib/api/api-calls/api-call.model';
import { LiveAnnouncer } from '@angular/cdk/a11y';

enum SendTestStatuses {
	notStarted = 0,
	pending = 1,
	success = 2,
	error = 3,
}

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './email-notification-recipients-step.component.html',
})
export class EmailNotificationRecipientsStepComponent<T extends EmailNotificationFormData>
	extends WizardBaseStep<T>
	implements OnInit {
	isUserAllowedActions: boolean;
	currentEmail: string;
	sendTestStatus: SendTestStatuses = SendTestStatuses.notStarted;

	/*
		Configurations for test mail.
		If testMailEnabled == true, the form data is translated to the relevant entity using getTestMailData,
		and the translated data is sent as ApiCall, whose type is determined by apiCallTypeForTestMail
	*/
	@Input() testMailEnabled: boolean = false;
	@Input() getTestMailData: (data: T) => any;
	@Input() apiCallTypeForTestMail: ApiCallType;
	@Input() introductionText: string;
	@ViewChild('emailEl', { static: false }) emailEl: ElementRef<HTMLInputElement>;

	get SendStatuses() {
		return SendTestStatuses;
	}

	get isValid(): boolean {
		return this.data.recipients.length > 0;
	}

	constructor(
		public i18n: I18nService,
		private authService: AuthService,
		private paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		private liveAnnouncer: LiveAnnouncer
	) {
		super();
		this.isUserAllowedActions = this.authService.currentUser.hasRequiredMtpPermission(
			MtpPermission.SecurityConfig_Manage
		);
	}

	ngOnInit(): void {
		this.checkValidation();
	}

	addEmail(email: string) {
		this.updateEmail(email);
		this.emailEl.nativeElement.focus();
	}

	updateEmail(email: string) {
		const emailExists = ~this.data.recipients.indexOf(email);
		if (!emailExists) {
			this.data.recipients.splice(0, 0, email);
			this.announceEmailListUpdated();
		}
		this.currentEmail = '';
		this.checkValidation();
		this.clearSendTestStatus();
	}

	removeEmail(email: string) {
		const emailIndex = this.data.recipients.indexOf(email);
		if (~emailIndex) {
			this.data.recipients.splice(emailIndex, 1);
			this.announceEmailListUpdated();
		}
		this.checkValidation();
		this.clearSendTestStatus();
	}

	sendTestEmail() {
		this.sendTestStatus = SendTestStatuses.pending;
		const dataToSend = this.getTestMailData(this.data);

		this.paris.apiCall(this.apiCallTypeForTestMail, dataToSend).subscribe(
			() => {
				this.sendTestStatus = SendTestStatuses.success;
				this.changeDetectorRef.detectChanges();
			},
			error => {
				this.sendTestStatus = SendTestStatuses.error;
				this.changeDetectorRef.detectChanges();
			}
		);
		this.changeDetectorRef.detectChanges();
	}

	private clearSendTestStatus() {
		this.sendTestStatus = SendTestStatuses.notStarted;
		this.changeDetectorRef.detectChanges();
	}

	private checkValidation() {
		this.setStepValidation(this.data.recipients.length > 0);
	}

	private announceEmailListUpdated() {
		this.liveAnnouncer.announce(this.i18n.get('notificationRules.ruleEmails.updated'), 'assertive', 300);
	}
}
