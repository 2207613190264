import { Injectable, OnDestroy } from '@angular/core';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { DataCache, DataEntityType, ModelBase, Paris } from '@microsoft/paris';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import { merge, Observable, of, Subject } from 'rxjs';
import { map, mergeMap, shareReplay, take, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ConfirmationService } from '../../dialogs/confirm/confirm.service';
import { MachineGroup, UserExposedRbacGroups } from '@wcd/domain';
import { omit } from 'lodash-es';

const CACHE_DURATION: number = 5 * 60 * 1000;

interface IsUserExposedToEntity {
	isExposed: boolean;
	reason?: string;
}

@Injectable()
export class RbacService implements OnDestroy {
	refreshUserExposedRbacGroups$: Subject<void> = new Subject<void>(); // can be used to force getting rbac exposed groups from backend (flush client cache).
	userExposedRbacGroups$: Observable<Array<MachineGroup>>;
	isFilteringNecessary$: Observable<boolean>;
	private entityExposureCache = new DataCache<IsUserExposedToEntity>({ max: 50, time: CACHE_DURATION });

	constructor(
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private paris: Paris,
		private confirmationService: ConfirmationService,
		private pollingService: PollingService
	) {
		if (this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
			this.userExposedRbacGroups$ = merge(
				this.pollingService.poll(0, CACHE_DURATION),
				this.refreshUserExposedRbacGroups$
			).pipe(
				mergeMap(() => this.paris.getRepository(UserExposedRbacGroups).queryItem(null)),
				map((groupsEntity: UserExposedRbacGroups) => groupsEntity.groups)
			);
			const machineGroupsRepo = this.paris.getRepository(MachineGroup);
			this.isFilteringNecessary$ = this.pollingService
				.poll(0, CACHE_DURATION)
				.pipe(
					mergeMap(() =>
						machineGroupsRepo.allItems$.pipe(
							map((groups: Array<MachineGroup>) => groups.length > 0)
						)
					)
				);
		} else {
			this.userExposedRbacGroups$ = of([]);
			this.isFilteringNecessary$ = of(false);
		}
		this.userExposedRbacGroups$ = this.userExposedRbacGroups$.pipe(shareReplay(1));
		this.isFilteringNecessary$ = this.isFilteringNecessary$.pipe(shareReplay(1));
	}

	isUserExposedToEntity<T extends ModelBase>(
		entityType: EntityType<T>,
		entity: ModelBase | string
	): Observable<IsUserExposedToEntity> {
		if (!entityType) throw new Error("Can't check if user is exposed to entity, entity type is missing.");

		if (!this.featuresService.isEnabled(Feature.RbacMachineGroups)) return of({ isExposed: true });

		const cacheKey: string = `${entityType.entity.pluralName}_${
			typeof entity === 'string' ? entity : entity.id
		}`;

		return this.entityExposureCache.get(cacheKey).pipe(
			mergeMap(
				(cachedExposureState: IsUserExposedToEntity): Observable<IsUserExposedToEntityResult> => {
					if (cachedExposureState) return of(cachedExposureState);

					let isUserExposedFunction: (
							e: ModelBase | string
						) => Observable<boolean | IsUserExposedToEntityResult> =
							entityType.isUserExposedToEntityById,
						param: ModelBase | string = entity;

					if (typeof entity !== 'string') {
						if (entityType.isUserExposedToEntity)
							isUserExposedFunction = entityType.isUserExposedToEntity;
						else param = entity.id;
					}

					if (!isUserExposedFunction) return of({ isExposed: true });

					return isUserExposedFunction(param).pipe(
						take(1),
						map(
							(res: boolean | IsUserExposedToEntityResult): IsUserExposedToEntityResult => {
								if (typeof res === 'boolean') return { isExposed: res };
								return res;
							}
						),
						tap((res: IsUserExposedToEntityResult) => {
							if (res.shouldCache !== false)
								this.entityExposureCache.add(cacheKey, omit(res, 'shouldCache'));
						}),
						map((res: IsUserExposedToEntityResult) => omit(res, 'shouldCache'))
					);
				}
			),
			shareReplay(1)
		);
	}

	addToExposureCache(type: DataEntityType, id: string, isUserExposedToEntity: IsUserExposedToEntity) {
		this.entityExposureCache.add(`${type.pluralName}_${id}`, isUserExposedToEntity);
	}

	showNoAccessModal(description: string) {
		this.confirmationService.confirm({
			title: this.i18nService.get('rbac.accessDenied.title'),
			text: description,
			showConfirm: false,
			cancelText: 'Confirm',
		});
	}

	ngOnDestroy(): void {
		this.refreshUserExposedRbacGroups$.complete();
	}
}

export interface IsUserExposedToEntityResult {
	isExposed: boolean;
	shouldCache?: boolean;
	reason?: string;
}
