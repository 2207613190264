import { SevilleModule } from '../../../seville/seville.module';
import '../../services/seville.threatintel.demoTenantResolver';
import { RegExpService } from '@wcd/shared';
import { BaseType, event, select, Selection } from 'd3-selection';
import { line } from 'd3-shape';
import { hierarchy, tree } from 'd3-hierarchy';
import { FilesService } from '../../../../../@entities/files/services/files.service';
import { IpsService } from '../../../../../@entities/ips/services/ips.service';
import { MachinesService } from '../../../../../@entities/machines/services/machines.service';
import { truncate } from 'lodash-es';

SevilleModule.directive('scopeOfBreach', scopeOfBreachDirective);

scopeOfBreachDirective.$inject = [];

function scopeOfBreachDirective() {
	return {
		restrict: 'E',
		template: `
		<div ng-if="!graph.error">
        <div class="row noselect">
            <div class="col-sm-12 nopadding">
                <div class="entity-section-title">
                    <span>Incident graph</span>
                </div>
            </div>
        </div>
        <div class="row noselect">
            <div id="graph-container" class="col-sm-12 border-default">
                <div>
                    <span class="pull-right graph-time-indication">{{graph.lookback}} Days</span>
                </div>
                <div class="graph-loading text-center" ng-if="graph.loading">
                    <img src="/assets/images/circle-loading.gif" />
                </div>
            </div>
        </div>
    </div>
    <div class="graph-error" ng-if="graph.error && !graph.loading">
        <i class="icon icon-Error"></i>
        Incident graph is not available for this alert
    </div>
		`,
		controllerAs: 'graph',
		bindToController: true,
		scope: {
			alert: '=',
			alertid: '=',
			flightingOverride: '=',
			onLoaded: '&?',
		},
		controller: [
			'$scope',
			'$http',
			'$state',
			'$log',
			'$filter',
			'$rootScope',
			'$q',
			'appStateService',
			'urlMapping',
			'demoTenantResolverService',
			'featuresService',
			'filesService',
			'ipsService',
			'machinesService',
			function(
				$scope,
				$http,
				$state,
				$log,
				$filter,
				$rootScope,
				$q,
				appState,
				urlMapping,
				demoTenantResolverService,
				featuresService,
				filesService: FilesService,
				ipsService: IpsService,
				machinesService: MachinesService
			) {
				var vm = this,
					partsFilter = $filter('parts'),
					limitToFilter = $filter('limitTo'),
					duration = 300,
					i = 0,
					numOfAllowedChildren = 5,
					isInit: boolean = false;

				this.$onInit = function() {
					if (vm.loading || isInit) return;

					vm.loading = true;

					vm.alertId = vm.alert.AlertId;
					vm.lookback = demoTenantResolverService.isDemoTenant()
						? demoTenantResolverService.demoTenantLookback
						: 30;
					vm.processTreeBetaFeaturesIsEnabled = featuresService.isEnabled(
						'ProcessTree-BetaFeatures'
					);

					$log.debug('loading alert scope of breach - id: ' + vm.alertId);
					vm.error = false;

					var url = urlMapping.getThreatIntelUrl() + '/ScopeOfBreach';
					var buildInitialGraphPromise = $http
						.get(url, {
							params: {
								id: vm.alertId,
								lookbackInDays: vm.lookback,
							},
						})
						.then(
							function(response) {
								if (response.status == 200) {
									$log.debug('alert scope of breach loaded successfully');
									var data = response.data;
									vm.scopeOfBreachData = data;
									if (!data) {
										vm.loading = false;
										vm.error = true;
										$log.debug('alert scope of breach loading failed');
									} else {
										var graphData = buildGraphData(data.Elements, 1);
										vm.loading = false;
										if (graphData) {
											openFirstLevel(graphData);
											vm.renderGraph(graphData);
										}
									}
								} else {
									vm.loading = false;
									vm.error = true;
									$log.debug('alert scope of breach loading failed');
								}

								if (vm.onLoaded) {
									vm.onLoaded();
								}

								isInit = true;
							},
							function(response) {
								$log.debug('alert scope of breach loading failed');
								if (vm.onLoaded) {
									vm.onLoaded();
								}
							}
						);

					if (vm.processTreeBetaFeaturesIsEnabled) {
						var unregisterProcessTreeCalculated = $rootScope.$on(
							'PROCESS_TREE_CALCULATED',
							function(e, data) {
								var url = urlMapping.getThreatIntelUrl() + '/ExpandScopeOfBreachByTree';
								var expandByTree = $http.get(url, {
									params: {
										id: vm.alertId,
										lookbackInDays: vm.lookback,
										flightingOverride: vm.flightingOverride,
									},
								});

								function handleExpandByTree(responses) {
									var expandByTreeResponse = responses[1];
									if (expandByTreeResponse.status == 200) {
										$log.debug('expanding scope of breach based on process tree data');
										expandProcessTree(expandByTreeResponse.data);
										$log.debug(
											'done expanding scope of breach based on process tree data'
										);
									} else {
										$log.error(
											'expanding scope of breach based on process tree data failed'
										);
									}
								}

								buildInitialGraphPromise = $q
									.all([buildInitialGraphPromise, expandByTree])
									.then(handleExpandByTree, handleExpandByTree);
							}
						);

						var unregisterProcessTreeInfectedFiles = $rootScope.$on(
							'PROCESS_TREE_WDAV_AND_VT_CALCULATED',
							function(e, sha1List) {
								if (sha1List) {
									var url =
										urlMapping.getThreatIntelUrl() + '/ExpandScopeOfBreachInfectedFiles';
									var expandInfected = $http.get(url, {
										params: {
											id: vm.alertId,
											lookbackInDays: vm.lookback,
											flightingOverride: vm.flightingOverride,
											sha1List: sha1List,
										},
									});

									buildInitialGraphPromise = $q
										.all([buildInitialGraphPromise, expandInfected])
										.then(handleExpandByInfectedFiles, handleExpandByInfectedFiles);
								}
							}
						);

						var unregisterSimilarFilesLookup = $rootScope.$on('PROCESS_TREE_CALCULATED', function(
							e,
							data
						) {
							var url = urlMapping.getThreatIntelUrl() + '/ExpandScopeOfBreachBySimilarFiles';
							$http.get(url, {
								params: {
									id: vm.alertId,
									lookbackInDays: vm.lookback,
									flightingOverride: vm.flightingOverride,
								},
							});
						});

						$scope.$on('$destroy', function() {
							unregisterProcessTreeInfectedFiles();
							unregisterProcessTreeCalculated();
							unregisterSimilarFilesLookup();
						});
					}
				}

				function handleExpandByInfectedFiles(responses) {
					var expandByInfectedFilesResponse = responses[1];
					if (expandByInfectedFilesResponse.status == 200) {
						$log.debug('expanding scope of breach based on infected files data');
						expandProcessTree(expandByInfectedFilesResponse.data);
						$log.debug('done expanding scope of breach based on infected files data');
					} else {
						$log.error('expanding scope of breach based on infected files data failed');
					}
				}

				function addNewElementsToGraph(children) {
					children.forEach(function(childToInsert) {
						var shouldInsert = true;
						vm.root.children.forEach(function(rootChild) {
							if (rootChild.data.Sha1 === childToInsert.Sha1) {
								shouldInsert = false;
							}
						});
						if (shouldInsert && vm.root.children.length < numOfAllowedChildren) {
							addNodesToTree(vm.root, childToInsert);
						}
					});
				}

				function expandProcessTree(data) {
					if (!data) {
						$log.debug('expand scope of breach - no further information is available');
					} else {
						$log.debug('updating alert scope of breach');
						var graphData = buildGraphData(data.Elements, 1);
						if (graphData) {
							//if root exist, only add additional info to it
							if (vm.root) {
								addNewElementsToGraph(graphData[0]._children);
								openFirstLevel(vm.root.children);
								update(vm.root, true);
								//else create the incident graph from scratch
							} else {
								openFirstLevel(graphData);
								vm.renderGraph(graphData);
							}
						}
					}
				}

				function addNodesToTree(parent, childToInsert) {
					const newNode = hierarchy(childToInsert);
					newNode.parent = parent;
					Object.assign(newNode, {
						depth: parent.depth + 1,
						height: parent.height - 1,
					});

					if (childToInsert.children || childToInsert._children) {
						(childToInsert.children || childToInsert._children).forEach(child =>
							addNodesToTree(newNode, child)
						);
					}

					if (!parent.children) {
						parent.children = [];
						parent.data.children = [];
					}

					parent.children.push(newNode);
					parent.data.children.push(newNode.data);
				}

				function diagonal(s, d) {
					return `M ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x}, ${(s.y + d.y) / 2} ${d.x}, ${d.y} ${
						d.x
					}`;
				}

				function update(source, newRootTree = false) {
					function getAriaLabelText(url, displayName, hasChildren) {
						const type = url.startsWith('/machines/') ? 'device' : 'file';
						return `${type} ${displayName} ${hasChildren ? 'with children' : ''}`;
					}
					// Compute the new tree layout.
					if (newRootTree) {
						vm.svg.selectAll('g.graph-node').remove();
						vm.svg.selectAll('path.graph-path').remove();
					}
					const treeData = vm.tree(vm.root);

					const nodes = treeData.descendants(),
						links = treeData.links();

					// Normalize for fixed-depth.
					nodes.forEach(function(d) {
						d.y = d.depth * 180;
					});

					// Update the nodes…
					const node = vm.svg.selectAll('g.graph-node').data(nodes, function(d) {
						return d.id || (d.id = ++i);
					});

					// Enter any new nodes at the parent's previous position.
					const nodeEnter = node
						.enter()
						.append('g')
						.attr('class', 'graph-node')
						.attr('transform', function(d) {
							return 'translate(' + source.y0 + ',' + source.x0 + ')';
						})
						.on('click', toggle);

					// Parent node icon
					nodeEnter
						.append('text')
						.attr('class', function(d) {
							if (!d.data.hasChilds) {
								return 'icon graph-sat-sm-icon-sm';
							}

							return 'icon graph-sat-lg-icon-sm';
						})
						.attr('y', function(d) {
							if (!d.data.hasChilds) {
								return -6;
							}

							return -45;
						})
						.attr('x', function(d) {
							if (!d.data.hasChilds) {
								return 4;
							}

							return -100;
						})
						.text(function(d) {
							return d.data.parentIcon;
						});

					// Parent display name / link
					nodeEnter.each(function(d) {
						var thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);

						if (d.data.parentUrl) {
							var parentLink = thisGroup
								.append('svg:a')
								.attr('xlink:href', function(d) {
									return d.data.parentUrl;
								})
								.attr('aria-label', function(d) {
									return getAriaLabelText(
										d.data.parentUrl,
										d.data.parentDisplayName,
										d.data.hasChilds
									);
								})
								.append('svg:text')
								.attr('class', 'graph-link')
								.text(function(d) {
									return d.data.parentDisplayName;
								})
								.attr('dy', function(d) {
									return d.data.hasChilds ? -55 : -13;
								})
								.attr('dx', function(d) {
									return d.data.hasChilds ? -50 : 35;
								});

							if (!parentLink) {
								$log.debug('no link!');
							} else {
								if (d.data.IsAssociatedWithAlerts) {
									parentLink.on('click', function(d) {
										if (event) {
											event.preventDefault();
											event.stopPropagation();
										}

										goToMachineFromAlert(d.data.SatalliteEntityId);
									});
								}
							}
						}
					});

					// Main entity
					var itemNode = nodeEnter.append('g');

					itemNode.each(function(d) {
						var thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);
						if (d.data.url) {
							thisGroup
								.append('svg:a')
								.attr('xlink:href', function(d) {
									return d.data.url;
								})
								.attr('aria-label', function(d) {
									return getAriaLabelText(d.data.url, d.data.displayName, d.data.hasChilds);
								})
								.append('svg:text')
								.attr('class', 'graph-link')

								.text(function(d) {
									return d.data.displayName;
								})
								.attr('dy', function(d) {
									if (d.data.hasChilds) {
										if (d.data.parentIcon) {
											return 35;
										} else {
											if (d.depth == 0) return 30;
											else {
												if (
													(d.data.children && d.data.children.length == 0) ||
													(d.data._children && d.data._children.length == 0)
												) {
													return 30;
												} else {
													return 35;
												}
											}
										}
									} else {
										return 17;
									}
								})
								.attr('dx', function(d) {
									if (d.data.hasChilds) {
										if (d.data.parentIcon) {
											return -33;
										} else {
											if (d.depth == 0) return -35;
											else {
												if (
													(d.data.children && d.data.children.length == 0) ||
													(d.data._children && d.data._children.length == 0)
												) {
													return 35;
												} else if (
													(d.data.children && d.data.children.length == 1) ||
													(d.data._children && d.data._children.length == 1)
												) {
													return -32; //30;
												} else {
													return -30;
												}
											}
										}
									} else {
										return 50;
									}
								})
								.attr('text-anchor', function(d) {
									return d.data.hasChilds ? 'middle' : 'left';
								})
								.append('title')
								.text(function(d) {
									return d.data.displayNameTitle;
								});
						} else {
							thisGroup
								.append('text')
								.text(function(d) {
									return d.data.displayName;
								})
								.attr('dy', function(d) {
									return d.data.hasChilds ? 37 : 17;
								})
								.attr('dx', function(d) {
									return d.data.hasChilds ? -33 : 50;
								})
								.attr('text-anchor', function(d) {
									return d.data.hasChilds ? 'middle' : 'left';
								})
								.append('title')
								.text(function(d) {
									return d.data.displayNameTitle;
								});
						}

						if (d.data.AdditionalText) {
							thisGroup
								.append('text')
								.text(function(d) {
									return d.data.AdditionalText;
								})
								.attr('dy', function(d) {
									return d.data.hasChilds ? 57 : 37;
								})
								.attr('dx', function(d) {
									if (d.data.hasChilds) {
										if (d.data.parentIcon) {
											return -33;
										} else {
											if (d.depth == 0) return -35;
											else {
												if (
													(d.data.children && d.data.children.length == 0) ||
													(d.data._children && d.data._children.length == 0)
												) {
													return 35;
												} else {
													return 0;
												}
											}
										}
									} else {
										return 50;
									}
								})
								.attr('text-anchor', function(d) {
									return d.data.hasChilds ? 'middle' : 'left';
								})
								.append('title')
								.text(function(d) {
									return d.data.AdditionalText;
								});
						}

						thisGroup
							.append('text')
							.attr('class', function(d) {
								if (!d.data.hasChilds) {
									return 'icon graph-icon-sm-sm';
								}

								return 'icon graph-icon-lg-lg';
							})
							.attr('dx', function(d) {
								if (!d.data.hasChilds) {
									return 25;
								}

								if (d.data.parentIcon) {
									return -50;
								} else {
									if (d.depth == 0) return -50;
									else if (
										(d.data.children && d.data.children.length == 0) ||
										(d.data._children && d.data._children.length == 0)
									) {
										return 20;
									} else if (
										(d.data.children && d.data.children.length == 1) ||
										(d.data._children && d.data._children.length == 1)
									) {
										return -48; //15;
									} else {
										return -45;
									}
								}
							})
							.attr('dy', function(d) {
								if (!d.data.hasChilds) {
									return 20;
								}

								if (d.data.parentIcon) {
									return 15;
								} else {
									if (d.data.depth == 0) return 17;
									else if (
										(d.data.children && d.data.children.length == 0) ||
										(d.data._children && d.data._children.length == 0)
									) {
										return 15; //13;
									} else {
										return 15;
									}
								}
							})
							.text(function(d) {
								return d.data.icon;
							});

						// Attach icon and other symbols
						thisGroup
							.append('text')
							.attr('class', function(d) {
								return d.data.hasChilds
									? 'icon graph-sat-lg-icon-esm'
									: 'icon graph-sat-sm-icon-esm';
							})
							.attr('dx', function(d) {
								if (d.data.hasChilds) {
									if (d.data.parentIcon) {
										return -70;
									} else {
										if (d.depth == 0) {
											return -20;
										} else if (
											(d.data.children && d.data.children.length == 1) ||
											(d.data._children && d.data._children.length == 1)
										) {
											return 30;
										} else {
											return 20;
										}
									}
								} else {
									return -17;
								}
							})
							.attr('dy', function(d) {
								if (d.data.hasChilds) {
									if (d.data.parentIcon) {
										return -70;
									} else {
										if (d.depth == 0) {
											return -10;
										} else {
											return -65;
										}
									}
								} else {
									return -8;
								}
							})
							.text(function(d) {
								return d.data.parentSmallIcon;
							});
					});

					nodeEnter
						.append('path')
						.attr('class', 'graph-icon-path')
						.attr('stroke', 'gray')
						.attr('fill', 'none')
						.attr('d', function(d) {
							var data = d.data.hasChilds
								? [
										{
											x: '-83',
											y: '-46',
										},
										{
											x: '-83',
											y: '0',
										},
										{ x: '-56', y: '0' },
								  ]
								: [
										{
											x: '15',
											y: '-8',
										},
										{
											x: '15',
											y: '12',
										},
										{ x: '25', y: '12' },
								  ];
							if (d.data.parentIcon)
								return line()(data.map(point => <[number, number]>[+point.x, +point.y]));
							else {
								return null;
							}
						});

					nodeEnter
						.append('circle')
						.attr('r', '4px')
						.style('fill', function(d) {
							return d.data._children && d.data._children.length > 0
								? 'lightsteelblue'
								: '#fff';
						});

					// Transition nodes to their new position.
					var nodeUpdate = nodeEnter
						.transition()
						.duration(duration)
						.attr('transform', function(d) {
							return 'translate(' + d.y + ',' + d.x + ')';
						});

					nodeUpdate
						.select('circle')
						.attr('r', '4px')
						.style('fill', function(d) {
							return d.data._children && d.data._children.length > 0
								? 'lightsteelblue'
								: '#fff';
						});

					nodeUpdate.select('text').style('fill-opacity', 1);

					// Transition exiting nodes to the parent's new position.
					var nodeExit = node
						.exit()
						.transition()
						.duration(duration)
						.attr('transform', function(d) {
							return 'translate(' + source.y + ',' + source.x + ')';
						})
						.remove();

					nodeExit.select('circle').attr('r', '4px');

					nodeExit.select('text').style('fill-opacity', 1e-6);

					// Update the links…
					var link = vm.svg.selectAll('path.graph-path').data(links, function(d) {
						return d.target.id;
					});

					// Enter any new links at the parent's previous position.
					const linkEnter = link
						.enter()
						.insert('path', 'g')
						.attr('class', function(d) {
							return 'graph-path';
						})
						.attr('d', d => getLinkPath(d, source, true));

					// Transition links to their new position.
					linkEnter
						.transition()
						.duration(duration)
						.attr('d', d => getLinkPath(d, source));

					// Transition exiting nodes to the parent's new position.
					link.exit()
						.transition()
						.duration(duration)
						.attr('d', d => getLinkPath(d, source, true))
						.remove();

					// Stash the old positi	ons for transition.
					nodes.forEach(function(d) {
						d.x0 = d.x;
						d.y0 = d.y;
					});
				}

				function getLinkPath(d, source, fixToParent = false) {
					// please notice x and y in the data obj are opposite here (y represents left-to-right position). x0 and y0 are in the correct order.
					if (
						(d.source.children && d.source.children.length == 1) ||
						(d.source._children && d.source._children.length == 1)
					) {
						var xVal = d.target.y;
						if (
							(d.target.children && d.target.children.length > 0) ||
							(d.target._children && d.target._children.length > 0)
						) {
							xVal -= 60;
						}
						return line()(
							[
								{
									x: d.source.y,
									y: d.source.x,
								},
								{
									x: xVal,
									y: d.target.x,
								},
							].map(point => <[number, number]>[+point.x, +point.y])
						);
					} else {
						if (fixToParent) {
							var o = {
								x: source.x0,
								y: source.y0,
							};
							return diagonal(o, o);
						} else return diagonal(d.source, d.target);
					}
				}

				// Toggle children
				function toggle(d) {
					if ((d.children && d.children.length == 0) || (d._children && d._children.length == 0))
						return;

					if (d.children) {
						d._children = d.children;
						d.children = null;
						update(d);
					} else {
						var siblingsUpdated = closeSiblings(d);
						d.children = d._children;
						d._children = null;

						if (siblingsUpdated) {
							setTimeout(function() {
								update(d);
							}, 350);
						} else {
							update(d);
						}
					}
				}

				function closeSiblings(d) {
					if (!d.parent) return; // root case
					var updated = false;
					d.parent.children.forEach(function(d1) {
						if (d1 === d || !d1.children) return;
						d1._children = d1.children;
						d1.children = null;
						update(d1);

						updated = true;
					});

					return updated;
				}

				function buildGraphData(elements, depth) {
					var data = [];

					if (elements && elements.length) {
						for (var i = 0; i < elements.length; i++) {
							var element = elements[i];
							var item: any = {
								icon: getIconByType(element.PrimaryEntityType),
								parentIcon: getIconByType(element.SatalliteEntityType),
								parentSmallIcon: element.IsAssociatedWithAlerts
									? getIconByType('Alert')
									: null,
								parentDisplayName: formatDisplayName(
									element.SatalliteEntityType,
									element.SatalliteEntityDisplayName
								),
								parentUrl: getEntityUrl(
									element.SatalliteEntityType,
									element.SatalliteEntityId,
									element.SatalliteEntityDisplayName
								),
								displayName: formatDisplayName(
									element.PrimaryEntityType,
									element.PrimaryEntityDisplayName
								),
								displayNameTitle: element.PrimaryEntityDisplayName,
								url: getEntityUrl(
									element.PrimaryEntityType,
									element.PrimaryEntityId,
									element.PrimaryEntityDisplayName
								),
								depth: depth,
								IsAssociatedWithAlerts: element.IsAssociatedWithAlerts,
								SatalliteEntityId: element.SatalliteEntityId,
								AdditionalText: getAdditionalText(element),
								Sha1: element.PrimaryEntityId,
							};

							if (element.SubElements) {
								item._children = buildGraphData(element.SubElements, depth + 1);
								item.hasChilds = true;
								item.childsCount = item._children.length;
							}

							data.push(item);
						}
					}

					return data;
				}

				function openFirstLevel(graphData) {
					if (!graphData || graphData.length == 0) return;

					for (var i = 0; i < graphData.length; i++) {
						openFirstLevel(graphData[i]._children);

						if (graphData[i].children) break;

						if (
							graphData[i]._children &&
							graphData[i]._children.length > 0 &&
							!graphData[i].children
						) {
							graphData[i].children = graphData[i]._children;
							graphData[i]._children = null;

							break;
						}
					}
				}

				function getEntityUrl(type, id, name?) {
					if (id) {
						switch (type) {
							case 'File':
							case 'Commandline':
								if (RegExpService.sha1.test(id)) {
									return filesService.getFileLink(id, null, name);
								}
								return null;
							case 'Machine':
								return machinesService.getMachineLink(id);
							case 'IP':
								return ipsService.getIpLink(id);
							case 'Alert':
								return $state.href('alert', { id: id });
						}
					}

					return null;
				}

				function getIconByType(type) {
					switch (type) {
						case 'File':
							return '\uE7C3';
						case 'Process':
							return '\uE115';
						case 'Machine':
							return '\uE7F8';
						case 'IP':
							return '\uE93E';
						case 'Alert':
							return '\uE945';
						case 'Commandline':
							return '\uE756';
					}

					return null;
				}

				function getAdditionalText(element) {
					if (element.PrimaryEntityType === 'Commandline') {
						// Main single file
						if (element.IsAssociatedWithAlerts) {
							return 'ran command';
						} else {
							// same command on other machines
							if (element.SatalliteEntityType && element.SatalliteEntityType == 'Machine') {
								return 'ran same command';
							}

							// other commandlines on the first machine
							return 'ran command';
						}
					}
				}

				function formatDisplayName(type, name) {
					if (!name) return null;
					if (type == 'Machine') {
						name = partsFilter(name, 1);
					}

					name = truncate(name, { length: 20 });

					return name;
				}

				function getGraphDepth(graphData) {
					var depth = graphData.children
						? graphData.children.length
						: graphData._children
						? graphData._children.length
						: 1;
					if (graphData.children) {
						for (var i = 0; i < graphData.children.length; i++) {
							var childDepth = getGraphDepth(graphData.children[i]);
							if (childDepth > 0) {
								depth *= childDepth;
							}
						}
					} else if (graphData._children) {
						for (var j = 0; j < graphData._children.length; j++) {
							var childDepth = getGraphDepth(graphData._children[j]);
							if (childDepth > 0) {
								depth *= childDepth;
							}
						}
					}

					return depth;
				}

				function getGraphHeight(graphData) {
					var depth = getGraphDepth(graphData[0]);

					if (depth / 6 > 2) return 750;
					if (depth / 6 > 1) return 600;
					if (depth / 6 > 0.8) return 550;

					return 450;
				}

				function goToMachineFromAlert(machineId) {
					if (machineId && machineId.length > 0) {
						var state = $state.get('machine');
						if (!state.data) {
							state.data = {};
						}

						state.data.activeAlertId = vm.alert.AlertId;
						state.data.activeAlertSeverity = vm.alert.Severity;
						state.data.activeAlertTime = vm.alert.LastEventTime || vm.alert.LastSeen;
						$state.get('machine').data.slider = new Date(state.data.activeAlertTime);

						$state.go('machine', { id: machineId });
					}
				}

				vm.renderGraph = function(graphData) {
					var containerEl: HTMLElement = <HTMLElement>document.querySelector('#graph-container'),
						margin = { top: 20, right: 120, bottom: 20, left: 160 },
						width = containerEl ? containerEl.clientWidth : 960, //960 - margin.right - margin.left,
						height = getGraphHeight(graphData) - margin.top - margin.bottom;

					// Reset the container's contents to avoid duplication.
					if (containerEl) containerEl.innerHTML = '';

					vm.tree = tree().size([height, width]);

					vm.svg = select('#graph-container')
						.append('svg')
						.attr('width', '100%')
						.attr('height', height + margin.top + margin.bottom)
						.append('g')
						.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

					vm.root = hierarchy(graphData[0]);
					vm.root.x0 = height / 2;
					vm.root.y0 = 0;

					update(vm.root);

					select(self.frameElement).style('height', '100%');
				};
			},
		],
	};
}
