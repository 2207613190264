import { I18nService } from '@wcd/i18n';
import { ChangeEventService } from './change-event.service';
import { ChangeEvent } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { DataviewField } from '@wcd/dataview';
import { TzDateService, getTzString } from '@wcd/localization';
import { isWcdIconName } from '@wcd/icons-font';
import { isFabricIconName } from '@wcd/scc-common';
import { FilterValuesRangeSliderComponent } from '@wcd/ng-filters';
import { ChangeEventTextService } from '../../../../tvm/services/tvm-change-events-texts.service';

@Injectable()
export class ChangeEventFieldsService implements FieldsService<ChangeEvent> {
	private _fields: Array<DataviewField<ChangeEvent>>;
	private _singleSoftwareFields: Array<DataviewField<ChangeEvent>>;
	private _topEventsWidgetFields: Array<DataviewField<ChangeEvent>>;

	constructor(
		private tzDateService: TzDateService,
		private changeEventTextService: ChangeEventTextService,
		private i18nService: I18nService,
		private changeEventService: ChangeEventService
	) {}
	get fields(): Array<DataviewField<ChangeEvent>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<ChangeEvent>([
				{
					id: 'date',
					name: this.i18nService.get('tvm.changeEvent.date'),
					getDisplay: (event: ChangeEvent) =>
						this.tzDateService.format(event.date, 'shortDate', getTzString(0)),
					sort: {
						sortDescendingByDefault: true,
					},
				},
				{
					id: 'event',
					name: this.i18nService.get('tvm.common.event'),
					getDisplay: (event: ChangeEvent) => this.changeEventTextService.getChangeEventText(event),
					icon: {
						fabricIcon: (event: ChangeEvent) => {
							const iconName = this.changeEventService.getTitleIconName(event);
							return isFabricIconName(iconName) ? iconName : null;
						},
						wcdIcon: (event: ChangeEvent) => {
							const iconName = this.changeEventService.getTitleIconName(event);
							return isWcdIconName(iconName) ? iconName : null;
						},
					},
					sort: { enabled: false },
				},
				{
					id: 'score',
					name: this.i18nService.get('tvm.common.scoreTrend'),
					description: this.i18nService.get('tvm.changeEvent.scoreTrendTooltip'),
					getDisplay: this.changeEventService.getScoreText,
					enabledByDefault: false,
					icon: {
						fabricIcon: this.changeEventService.getScoreTrendIconName,
						className: this.changeEventService.getScoreTrendIconClass,
					},
					getTooltip: this.changeEventService.getToolTip,
				},
				{
					id: 'relatedComponent',
					name: this.i18nService.get('tvm.common.relatedComponent'),
					getDisplay: (event: ChangeEvent) =>
						this.changeEventTextService.getRelatedComponent(event),
					sort: { enabled: false },
				},
				{
					id: 'assetsCount',
					name: this.i18nService.strings.tvm_changeEvent_originallyImpactedMachines,
					getDisplay: (changeEvent: ChangeEvent) =>
						this.changeEventService.getAssetsCountString(changeEvent) ||
						this.i18nService.strings.notAvailable_long,
					filter: {
						component: {
							type: FilterValuesRangeSliderComponent,
							config: {
								min: 0,
								max: 100,
								valueFormat: (value: number) => `${value}%`,
							},
						},
					},
				},
				{
					id: 'currentAssetsCount',
					name: this.i18nService.strings.tvm_changeEvent_currentlyImpactedMachines,
					getDisplay: (changeEvent: ChangeEvent) =>
						this.changeEventService.getCurrentAssetsCountString(changeEvent) ||
						this.i18nService.strings.notAvailable_short,
				},
				{
					id: 'eventType',
					name: this.i18nService.get('tvm.changeEvent.type'),
					getDisplay: (event: ChangeEvent) =>
						this.i18nService.get(`tvm.changeEvent.eventType.${event.eventType}`),
				},
			]);
		}

		return this._fields;
	}

	get singleSoftwareFields(): Array<DataviewField<ChangeEvent>> {
		if (!this._singleSoftwareFields) {
			this._singleSoftwareFields = this.fields.filter((field) => field.id !== 'relatedComponent');
		}

		return this._singleSoftwareFields;
	}

	get topEventsWidgetFields(): Array<DataviewField<ChangeEvent>> {
		if (!this._topEventsWidgetFields) {
			this._topEventsWidgetFields = this.fields.filter(
				(field) => field.id === 'date' || field.id === 'event' || field.id === 'assetsCount'
			);
		}

		return this._topEventsWidgetFields;
	}
}
