var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityRelatedWidgetBase } from '../entity-related-widget.base';
export var ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS = 180;
var AlertsSummaryWidgetBase = /** @class */ (function (_super) {
    __extends(AlertsSummaryWidgetBase, _super);
    function AlertsSummaryWidgetBase(paris, activatedEntity, reportsService, i18nService) {
        var _this = _super.call(this, paris, activatedEntity, reportsService) || this;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(AlertsSummaryWidgetBase.prototype, "entityRelatedWidgetConfig", {
        get: function () {
            return {
                id: this.id,
                name: this.i18nService.get('reports.widgets.alertsSummary.title'),
                rangeInDays: ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS,
                entityType: this.entityType,
                entityRelationshipType: this.entityAlertsSummaryRelationship,
                getDataQuery: function () { return ({ where: { lookingBackInDays: ALERTS_SUMMARY_WIDGET_RANGE_IN_DAYS } }); },
                parseData: function (entityAlertsSummary) { return ({
                    alertsSeveritySummary: entityAlertsSummary.relatedData,
                }); },
            };
        },
        enumerable: true,
        configurable: true
    });
    return AlertsSummaryWidgetBase;
}(EntityRelatedWidgetBase));
export { AlertsSummaryWidgetBase };
