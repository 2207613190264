import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KnownColorsService, KnownColorName } from '@wcd/shared';
import { FabricIconNames } from '@wcd/scc-common';
import { StretchedDonutBarItem } from './stretched-donut-bars.component';

const WIDTH_DEFAULT = 146;
const HEIGHT_DEFAULT = 12;

@Component({
	selector: 'wcd-bar',
	templateUrl: './bar.component.html',
})
export class BarComponent {
	/**
	 * A number which reflects the completed part of the bar. For example, '30' in: "30/50"
	 */
	@Input() value?;

	/**
	 * Flag to show value as percentage out of total
	 */
	@Input() showValueAsPercentage = false;

	/**
	 * A number which reflects the total part of the bar. For example, '50' in: "30/50"
	 */
	@Input() total?;

	/**
	 * unit of measurement. For example 's' in "90/100s"
	 */
	@Input() unit?: string;

	/**
	 * fabric icon to be displayed near the title
	 */
	@Input() icon?: FabricIconNames;

	/**
	 * text to be displayed when hovering on the icon which is displayed near the title
	 */
	@Input() iconTooltip?: string;

	/**
	 * name of CSS class for the title
	 */
	@Input() barTitleCssClass?: string;

	/**
	 * text for title which is displayed above the bar
	 */
	@Input() title?: string;

	/**
	 * text to be displayed instead of the numbers (X/Y)
	 */
	@Input() noDataText?: string;

	/**
	 * true to use noDataText as custom display instead of the numbers (X/Y)
	 */
	@Input() useCustomDisplay?: boolean = false;

	/**
	 * prefix to be displayed when hovering on the completed part of the bar, for example, 'completed' in: "completed: 30/50"
	 */
	@Input() valuePrefix?: string;

	/**
	 * prefix to be displayed when hovering on the incomplete part of the bar, for example, 'incomplete' in: "incomplete: 30/50"
	 */
	@Input() restPrefix?: string;

	/**
	 * A number between 0-1, the ratio of the bar that should be filled
	 */
	@Input() fillRatio: number;

	/**
	 * The knwon color name of the color for the bar's fill (should be present in colors.scss)
	 * @default 'themePrimary'
	 */
	@Input() colorName: KnownColorName = 'themePrimary';

	/**
	 * The known color  name of the color for the bar's background (should be present in colors.scss)
	 * @default 'neutralQuaternaryAlt'
	 */
	@Input() backgroundColorName: KnownColorName = 'neutralQuaternaryAlt';

	/**
	 * For number - the width of the bar in pixels
	 * For string - passed as is e.g. "100%" or "2rem"
	 * @default '146'
	 */
	@Input() width: number | string = WIDTH_DEFAULT;

	/**
	 * The height of the bar
	 * @default '12'
	 */
	@Input() height: number = HEIGHT_DEFAULT;

	/**
	 * Space between the bars
	 */
	@Input() spaceBetweenBars: string = '0px';

	/**
	 * tooltip when hovering over the bar
	 */
	@Input() barTooltip: string;

	@Input() tabIndex: string = null;

	@Input() valueAriaLabel: string = null;

	@Input() totalAriaLabel: string = null;

	@Output() onClick = new EventEmitter<StretchedDonutBarItem>(false);

	constructor(private colorsService: KnownColorsService) {}

	getBackgroundColor(knownColorName: KnownColorName) {
		return this.colorsService.backgroundColorsClassMap[knownColorName];
	}

	get actualWidth() {
		const width = this.width;
		return typeof width === 'number' ? `${width}px` : this.width ? this.width : `${WIDTH_DEFAULT}px`;
	}

	get actualHeight() {
		const height = this.height;
		return typeof height === 'number' ? `${height}px` : height ? height : `${HEIGHT_DEFAULT}px`;
	}

	valueTooltip(value: number, total: number, unit?: string, valuePrefix?: string) {
		if (value >= 0) {
			return (
				(valuePrefix ? `${valuePrefix}: ` : '') +
				`${value.toLocaleString()}/${total.toLocaleString() + (unit ? unit : '')}`
			);
		}
	}

	totalTooltip(value: number, total: number, unit?: string, restPrefix?: string) {
		if (value >= 0) {
			return (
				(restPrefix ? `${restPrefix}: ` : '') +
				`${(total - value).toLocaleString()}/${total.toLocaleString() + (unit ? unit : '')}`
			);
		}
	}

	calculatePercentage(): number {
		return this.total > 0 ? Math.round((this.value * 100) / this.total) : 0;
	}
}
