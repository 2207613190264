var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { DomainsService } from '../services/domains.service';
var DomainEntityPanelComponent = /** @class */ (function (_super) {
    __extends(DomainEntityPanelComponent, _super);
    function DomainEntityPanelComponent(domainsService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.domainsService = domainsService;
        return _this;
    }
    Object.defineProperty(DomainEntityPanelComponent.prototype, "domain", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    DomainEntityPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this._onActionSubscription = this.action$.subscribe(function ($event) { return _this.onAction($event.action, $event.data); });
        this.domainIdSelected = this.domain.id;
    };
    DomainEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._onActionSubscription && this._onActionSubscription.unsubscribe();
    };
    DomainEntityPanelComponent.prototype.onAction = function (action, data) {
        this.changeDetectorRef.markForCheck();
    };
    DomainEntityPanelComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, entity);
        this.changeDetectorRef.markForCheck();
    };
    return DomainEntityPanelComponent;
}(EntityPanelComponentBase));
export { DomainEntityPanelComponent };
