import {Entity, EntityField, ParisConfig} from '@microsoft/paris';
import { HuntingDocEntity, HuntingDocQuery, HuntingDocRecord } from './hunting-documentation.entity.base';
import {HuntingDataQuery} from "../hunting-dataQuery";

const DOCUMENTATION_EXTERNAL_LINK: string =
	'https://docs.microsoft.com/microsoft-365/security/mtp/advanced-hunting-';

@Entity({
	singularName: 'Hunting table Reference',
	pluralName: 'Hunting tables References',
	endpoint:(config: ParisConfig, query: HuntingDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return 'documentation/TableDocumentation';
		}
		return 'hunting/documentation/tableReference';
	},
	baseUrl: (config: ParisConfig, query: HuntingDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return config.data.serviceUrls.huntingService;
		}
		return config.data.serviceUrls.threatIntel;
	}
})
export class HuntingDocTable extends HuntingDocEntity {
	@EntityField({ data: 'Fields', arrayOf: HuntingDocRecord })
	fields: Array<HuntingDocRecord>;

	@EntityField({ data: 'ActionTypes', arrayOf: HuntingDocRecord })
	actionTypes: Array<HuntingDocRecord>;

	@EntityField({ data: 'Queries', arrayOf: HuntingDocQuery })
	queries: Array<HuntingDocQuery>;

	@EntityField({ data: 'HasExternalLink' })
	HasExternalLink: boolean;

	getExternalLink(): string {
		if (this.HasExternalLink) {
			return `${DOCUMENTATION_EXTERNAL_LINK}${this.name}-table`;
		}
		return null;
	}
}
