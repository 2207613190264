import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { RbacControlService } from '../../../../../apps/portal/src/app/rbac/services/rbac-control.service';
import { RbacControlSettings } from '../../../../../apps/portal/src/app/rbac/models/rbac-control-settings.model';

interface MtpRbacPermissionsGuardConfig {
	RbacControlSettings: RbacControlSettings;
	NavigateToOnAuthorized: string;
	FallbackOnUnauthorized: string;
	AbsolutePath: boolean;
}

@Injectable()
export class MtpRbacPermissionsGuard implements CanActivate {
	constructor(
		private appContext: AppContextService,
		private readonly authService: AuthService,
		private router: Router,
	    private readonly rbacControlService: RbacControlService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const config: MtpRbacPermissionsGuardConfig = route.data['MtpRbacPermissionsGuardConfig'];

		if (!this.authService.currentUser) {
			// No users signed in to check allowed actions
			return false;
		} else if (this.rbacControlService.hasRequiredRbacPermissions(config.RbacControlSettings)) {
			if (config.NavigateToOnAuthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.NavigateToOnAuthorized}`,
				]);
			}
			return true;
		} else {
			if (config.FallbackOnUnauthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.FallbackOnUnauthorized}`,
				]);
			} else {
				sccHostService.isSCC
					? sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' })
					: this.router.navigate(['/Error/UnauthorizedOnThisPage'], {
							queryParams: { path: state.url },
					  });
			}
			return false;
		}
	}
}
