var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { MachineStatus } from '../machine/machine-status.entity';
import { MachineGroup } from '../rbac/machine-group.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.automatedIr; }, ɵ1 = ['id', 'machine_id', 'machineId', 'Id'], ɵ2 = ['name', 'Name'], ɵ3 = ['machine_id', 'machineId'], ɵ4 = ['logged_in_user', 'users', 'Users'], ɵ5 = function (os) { return (os && (typeof os === 'string' ? os : os.name)) || ''; }, ɵ6 = ['status', 'Status'], ɵ7 = ['ip', 'Ip'];
var InvestigatedMachine = /** @class */ (function (_super) {
    __extends(InvestigatedMachine, _super);
    function InvestigatedMachine() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(InvestigatedMachine.prototype, "allowCancelInvestigation", {
        get: function () {
            return this._allowCancelInvestigation !== null && this._allowCancelInvestigation !== undefined
                ? this._allowCancelInvestigation
                : this.status.allowCancelInvestigation;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(InvestigatedMachine.prototype, "usersLabel", {
        get: function () {
            if (!this.users)
                return 'N/A';
            return this.users.join(', ');
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "name", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "machineId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", MachineGroup)
    ], InvestigatedMachine.prototype, "group", void 0);
    __decorate([
        EntityField({ data: 'rbac_group_id' }),
        __metadata("design:type", Number)
    ], InvestigatedMachine.prototype, "rbacGroupId", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Array)
    ], InvestigatedMachine.prototype, "users", void 0);
    __decorate([
        EntityField({ data: 'os', parse: ɵ5 }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "osName", void 0);
    __decorate([
        EntityField({ data: 'connectivity', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigatedMachine.prototype, "isConnected", void 0);
    __decorate([
        EntityField({ data: 'is_unmanaged', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], InvestigatedMachine.prototype, "isUnmanaged", void 0);
    __decorate([
        EntityField({ data: 'is_canceled_investigation', defaultValue: false }),
        __metadata("design:type", Object)
    ], InvestigatedMachine.prototype, "isCanceledInvestigation", void 0);
    __decorate([
        EntityField({ data: 'protection_level_name' }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "protectionLevelName", void 0);
    __decorate([
        EntityField({ data: 'protection_level_type' }),
        __metadata("design:type", Number)
    ], InvestigatedMachine.prototype, "protectionLevelId", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", MachineStatus)
    ], InvestigatedMachine.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'last_update' }),
        __metadata("design:type", Date)
    ], InvestigatedMachine.prototype, "lastUpdate", void 0);
    __decorate([
        EntityField({ data: 'last_investigated' }),
        __metadata("design:type", Date)
    ], InvestigatedMachine.prototype, "lastInvestigated", void 0);
    __decorate([
        EntityField({ data: 'investigation_count' }),
        __metadata("design:type", Number)
    ], InvestigatedMachine.prototype, "investigationCount", void 0);
    __decorate([
        EntityField({ data: 'alert_count' }),
        __metadata("design:type", Number)
    ], InvestigatedMachine.prototype, "alertCount", void 0);
    __decorate([
        EntityField({ data: 'entity_count' }),
        __metadata("design:type", Number)
    ], InvestigatedMachine.prototype, "entityCount", void 0);
    __decorate([
        EntityField({ data: 'reason' }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "unknownStatusReason", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], InvestigatedMachine.prototype, "ip", void 0);
    InvestigatedMachine = __decorate([
        Entity({
            singularName: 'Investigated Device',
            pluralName: 'Investigated Devices',
            endpoint: 'investigatedhost',
            allItemsProperty: 'results',
            readonly: true,
            separateArrayParams: true,
            baseUrl: ɵ0,
        })
    ], InvestigatedMachine);
    return InvestigatedMachine;
}(EntityModelBase));
export { InvestigatedMachine };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
