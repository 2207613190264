import { Pipe, PipeTransform } from '@angular/core';
import { WicdSanitizerService } from '@wcd/shared';

@Pipe({ name: 'safeUrl' })
export class SafeUrlPipe implements PipeTransform {
	constructor(private sanitizer: WicdSanitizerService) {}

	transform(url: string) {
		return this.sanitizer.bypassSecurityTrustResourceUrl(url);
	}
}
