/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i6 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i7 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i8 from "./file.action-center.collection.component.ngfactory";
import * as i9 from "./file.action-center.collection.component";
import * as i10 from "../../services/files.backend.service";
import * as i11 from "../../../../insights/services/app-insights.service";
import * as i12 from "@angular/common";
import * as i13 from "./file.action-center.quarantine.component.ngfactory";
import * as i14 from "./file.action-center.quarantine.component";
import * as i15 from "@microsoft/paris/dist/lib/paris";
import * as i16 from "../../../../dialogs/confirm/confirm.service";
import * as i17 from "./file.action-center.panel.component";
import * as i18 from "@angular/router";
var styles_FileActionCenterPanelComponent = [];
var RenderType_FileActionCenterPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileActionCenterPanelComponent, data: {} });
export { RenderType_FileActionCenterPanelComponent as RenderType_FileActionCenterPanelComponent };
function View_FileActionCenterPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "hr", [], null, null, null, null, null))], null, null); }
function View_FileActionCenterPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "fab-message-bar", [], null, null, null, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(1, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], styles: [1, "styles"] }, null), i0.ɵpod(2, { marginBottom: 0 }), i0.ɵpod(3, { text: 0 }), (_l()(), i0.ɵted(4, 0, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(5, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.MessageBarType.info; var currVal_1 = _ck(_v, 3, 0, _ck(_v, 2, 0, 16)); _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "file.actionCenter.noActions")); _ck(_v, 4, 0, currVal_2); }); }
export function View_FileActionCenterPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 16, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i5.View_PanelComponent_0, i5.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i6.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i7.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 1, 1, "file-action-center-collection", [], null, [[null, "foundActions"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("foundActions" === en)) {
        var pd_0 = ((_co.foundSampleCollectionActions = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_FileActionCenterCollectionComponent_0, i8.RenderType_FileActionCenterCollectionComponent)), i0.ɵdid(7, 114688, null, 0, i9.FileActionCenterCollectionComponent, [i10.FilesBackendService, i11.AppInsightsService, i4.I18nService], { file: [0, "file"] }, { foundActions: "foundActions" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_FileActionCenterPanelComponent_1)), i0.ɵdid(10, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, 1, 1, "file-action-center-quarantine", [], null, [[null, "foundActions"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("foundActions" === en)) {
        var pd_0 = ((_co.foundQuarantineActions = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i13.View_FileActionCenterQuarantineComponent_0, i13.RenderType_FileActionCenterQuarantineComponent)), i0.ɵdid(13, 114688, null, 0, i14.FileActionCenterQuarantineComponent, [i15.Paris, i16.ConfirmationService, i4.I18nService, i10.FilesBackendService], { file: [0, "file"] }, { foundActions: "foundActions" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_FileActionCenterPanelComponent_2)), i0.ɵdid(17, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.file; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.foundQuarantineActions && _co.foundSampleCollectionActions); _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.file; _ck(_v, 13, 0, currVal_3); var currVal_4 = ((((_co.foundQuarantineActions !== null) && (_co.foundSampleCollectionActions !== null)) && !_co.foundSampleCollectionActions) && !_co.foundQuarantineActions); _ck(_v, 17, 0, currVal_4); }, null); }
export function View_FileActionCenterPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "file-action-center-panel", [], null, null, null, View_FileActionCenterPanelComponent_0, RenderType_FileActionCenterPanelComponent)), i0.ɵdid(1, 245760, null, 0, i17.FileActionCenterPanelComponent, [i18.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileActionCenterPanelComponentNgFactory = i0.ɵccf("file-action-center-panel", i17.FileActionCenterPanelComponent, View_FileActionCenterPanelComponent_Host_0, { settings: "settings", onDone: "onDone", onCancel: "onCancel", file: "file" }, {}, []);
export { FileActionCenterPanelComponentNgFactory as FileActionCenterPanelComponentNgFactory };
