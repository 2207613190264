import { Pipe, PipeTransform } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { DataviewFieldId } from '../models/dataview.model';

@Pipe({ name: 'selectableDataviewFields' })
export class SelectableDataviewFieldsPipe implements PipeTransform {
	transform(fields: Array<DataviewField>, selectableFieldIds?: Array<DataviewFieldId>) {
		if (selectableFieldIds && selectableFieldIds.length) {
			return fields.filter(field => !field.alwaysDisplay && selectableFieldIds.includes(field.id));
		}
		return fields.filter(field => !field.alwaysDisplay);
	}
}
