var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { forkJoin, of } from 'rxjs';
import { map, filter, switchMap, take, tap } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { CountVulnerabilitiesBySeverityApiCall, CountMisconfigurationsBySeverityApiCall, Software, EolState, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ReportsService } from '../../../../../../../shared-reports/services/reports.service';
import { ReportWidgetComponent } from '../../../../../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../../../../../../global_entities/services/activated-entity.service';
import { PrettyNumberService } from '@wcd/prettify';
import { SoftwareCommonService } from '../../../../services/software.common.service';
import { TvmTextsService, TextToken } from '../../../../../../../tvm/services/tvm-texts.service';
var WeaknessesDistributionWidget = /** @class */ (function (_super) {
    __extends(WeaknessesDistributionWidget, _super);
    function WeaknessesDistributionWidget(reportsService, paris, activatedEntity, i18nService, prettyNumberService, softwareCommonService, tvmTextsService) {
        var _this = _super.call(this, reportsService) || this;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.i18nService = i18nService;
        _this.prettyNumberService = prettyNumberService;
        _this.softwareCommonService = softwareCommonService;
        _this.tvmTextsService = tvmTextsService;
        _this.widgetEosTooltip = _this.i18nService.strings.tvm_securityRecommendation_eosTooltip;
        return _this;
    }
    Object.defineProperty(WeaknessesDistributionWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'weaknessesDistributionWidget',
                name: this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.initial'),
                getName: function (data) {
                    if (!data)
                        return _this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.initial');
                    var weaknessesCount = data.totalCount;
                    return weaknessesCount === 1
                        ? _this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.data.singular')
                        : _this.i18nService.get('tvm.softwarePage.report.widgets.weaknesses.title.data.plural', {
                            count: _this.prettyNumberService.prettyNumber(weaknessesCount),
                        });
                },
                loadData: function () {
                    return _this.activatedEntity.currentEntity$.pipe(filter(function (currentEntity) { return currentEntity instanceof Software; }), switchMap(function (software) {
                        var productName = _this.tvmTextsService.getText(TextToken.SoftwareNameFromId, software.id);
                        var isEolSoftware = software.eolSoftwareState === EolState.AlreadyEOL;
                        var vulnerabilitiesCountBySeverity$ = _this.paris
                            .apiCall(CountVulnerabilitiesBySeverityApiCall, {
                            product: _this.softwareCommonService.getDecapitalizedString(productName),
                            vendor: _this.softwareCommonService.getDecapitalizedString(software.vendor),
                        })
                            .pipe(take(1));
                        var misconfigurationsCountBySeverity$ = _this.paris
                            .apiCall(CountMisconfigurationsBySeverityApiCall, {
                            product: _this.softwareCommonService.getDecapitalizedString(productName),
                            vendor: _this.softwareCommonService.getDecapitalizedString(software.vendor),
                        })
                            .pipe(take(1));
                        return forkJoin(vulnerabilitiesCountBySeverity$, misconfigurationsCountBySeverity$).pipe(map(function (_a) {
                            var vulnerabilitiesCountBySeverity = _a[0], misconfigurationsCountBySeverity = _a[1];
                            var vulnerabilitiesCount = vulnerabilitiesCountBySeverity.reduce(function (acc, curr) { return acc + curr.weaknesses; }, 0);
                            var misconfigurationsCount = misconfigurationsCountBySeverity.reduce(function (acc, curr) { return acc + curr.weaknesses; }, 0);
                            var weaknessesCount = vulnerabilitiesCount + misconfigurationsCount;
                            return {
                                totalCount: weaknessesCount,
                                vulnerabilities: {
                                    totalCount: vulnerabilitiesCount,
                                    severityDistribution$: of(_this.softwareCommonService.generateSeveritiesDistributionData(vulnerabilitiesCountBySeverity)),
                                    isEolSoftware: isEolSoftware,
                                },
                                misconfigurations: {
                                    totalCount: misconfigurationsCount,
                                    severityDistribution$: of(_this.softwareCommonService.generateSeveritiesDistributionData(misconfigurationsCountBySeverity)),
                                },
                            };
                        }));
                    }), tap(function (weaknessesDistributionData) {
                        _this.vulnerabilitiesCountDisplayText = _this.tvmTextsService.getText(TextToken.VulnerabilitiesCount, weaknessesDistributionData.vulnerabilities.totalCount);
                        _this.misconfigurationsCountDisplayText = _this.tvmTextsService.getText(TextToken.MisconfigurationsCount, weaknessesDistributionData.misconfigurations.totalCount);
                        return weaknessesDistributionData;
                    }));
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    return WeaknessesDistributionWidget;
}(ReportWidgetComponent));
export { WeaknessesDistributionWidget };
