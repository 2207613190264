import {
	Component,
	EventEmitter,
	forwardRef,
	OnDestroy,
	OnInit,
	Output,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
} from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { combineLatest, Observable, Subscription, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import {
	MachineGroup,
	WebContentFilteringPolicy,
	WebContentFilteringCategoryType,
	WebContentFilteringCategoryTypes,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { MachinesService } from '../../machines/services/machines.service';
import {
	WebContentFilteringPolicyService,
	ScopeTypesEnum,
} from '../services/web-content-filtering-policy.service';
import { FilterValuesCheckListConfig } from '@wcd/ng-filters';
import { SpinnerSize } from 'office-ui-fabric-react';
import { AppFlavorConfig } from '@wcd/scc-common';

enum Tabs {
	details = 'details',
	action = 'action',
	groups = 'groups',
	review = 'review',
}

@Component({
	selector: 'web-content-filtering-policy-new',
	templateUrl: './web-content-filtering-policy-new.component.html',
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => WebContentFilteringPolicyNewComponent),
		},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebContentFilteringPolicyNewComponent implements OnInit, OnDestroy {
	@Output() save: EventEmitter<WebContentFilteringPolicy> = new EventEmitter<WebContentFilteringPolicy>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	readonly Tabs = Tabs;
	readonly ScopeTypesEnum = ScopeTypesEnum;
	readonly SpinnerSize = SpinnerSize;
	readonly isScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.webContentFiltering);

	tabs: Array<TabModel> = [
		{
			id: Tabs.details,
			name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.details.title'),
		},
		{
			id: Tabs.action,
			name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.action.title'),
			disabled: true,
		},
		...(this.isScopeVisible
			? [
					{
						id: Tabs.groups,
						name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.scope.title'),
						disabled: true,
					},
			  ]
			: []),
		{
			id: Tabs.review,
			name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.summary.title'),
			disabled: true,
		},
	].map((tab) => new TabModel(tab));

	currentTabIndex = 0;
	currentTab = this.tabs[this.currentTabIndex];

	policy: WebContentFilteringPolicy;
	isSaving: boolean = false;

	selectableMachineGroupScopes: Array<ChecklistValue>;
	selectedMachineGroupScope: ChecklistValue;

	loadingMachineGroups: boolean = true;
	allowSpecificMachineGroups: boolean = false; // True if #available machine groups > 0
	allowAllMachineGroups: boolean; // True if user is admin

	selectedMachineGroups: Array<ChecklistValue> = []; // Selected machine groups
	availableMachineGroups: Array<ChecklistValue> = []; // User accesible machine groups

	private _policyRepo: Repository<WebContentFilteringPolicy>;
	private _savePolicySubscription: Subscription;

	private _machineGroupsRepo: Repository<MachineGroup>;
	private _allMachineGroups: Array<MachineGroup>; // All machine groups before applying rbac
	private _rbacMachineGroupsEnabled: boolean;

	private _machineGroupSubscription: Subscription;
	private _onMachineGroupsChangedSubscription: Subscription;

	private _valuesLabelTexts: Map<string, string> = new Map<string, string>();

	private _categoryList: Array<WebContentFilteringCategoryType>;

	categoryChecklistConfig: FilterValuesCheckListConfig<string>;
	currentBlockedCategories: Array<string> = [];
	supportedCategoryIds: Array<number> = [];

	get machineGroupsFieldId(): string {
		return 'web-content-filtering-policy-machine-groups';
	}

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private authService: AuthService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
		private machinesService: MachinesService,
		private dialogsService: DialogsService,
		private changeDetectorRef: ChangeDetectorRef,
		private policyService: WebContentFilteringPolicyService
	) {
		this._machineGroupsRepo = paris.getRepository(MachineGroup);
		this._policyRepo = paris.getRepository(WebContentFilteringPolicy);

		this._rbacMachineGroupsEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroups);
	}

	ngOnInit(): void {
		this.policy = this._policyRepo.createNewItem();
		this.selectableMachineGroupScopes = this.policyService.getSelectableMachineGroupScopes();
		this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];

		const machineGroupsObservable: Observable<Array<MachineGroup>> = this._rbacMachineGroupsEnabled
			? this.setMachineGroups()
			: of(null);

		this._machineGroupSubscription = machineGroupsObservable.subscribe(
			() => {
				this.loadingMachineGroups = false;
				if (this._rbacMachineGroupsEnabled) {
					this._setSelectedMachineGroups(this.policy.rbacGroupIds);
				} else {
					this._setTabVisibility(Tabs.groups, false);
				}
			},
			(error) => {
				this.loadingMachineGroups = false;
			}
		);

		this._categoryList = this.paris
			.getRepository(WebContentFilteringCategoryType)
			.entity.values.filter((c) => this.policyService.filterCategory(c));
		this.categoryChecklistConfig = this.policyService.getCategoryChecklistConfig();

		this.supportedCategoryIds = this._categoryList.map((category) => category.id);
		this.policy.auditCategoryIds = this.supportedCategoryIds;
	}

	ngOnDestroy(): void {
		this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
		this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
		this._savePolicySubscription && this._savePolicySubscription.unsubscribe();
	}

	onMachineGroupChange = (newOption: ChecklistValue) => {
		this.allowSpecificMachineGroups =
			newOption === this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];

		if (!this.allowSpecificMachineGroups) {
			this._setSelectedMachineGroups((this.policy.rbacGroupIds = this.selectedMachineGroups = []));
		}
	};

	onNavButtonPressed = (isNext: boolean) => {
		if (isNext) {
			this.currentTabIndex++;
			while (!this.tabs[this.currentTabIndex].isVisible && this.currentTabIndex < this.tabs.length) {
				this.currentTabIndex++;
			}

			this.tabs[this.currentTabIndex].disabled = false;
		} else {
			this.currentTab.disabled = true;
			this.currentTabIndex--;
			while (!this.tabs[this.currentTabIndex].isVisible && this.currentTabIndex >= 0) {
				this.currentTabIndex--;
			}
		}

		this.currentTab = this.tabs[this.currentTabIndex];
	};

	onTabChanged = (newTab: TabModel) => {
		this.currentTab.disabled = true;
		this.currentTab = newTab;
		this.currentTab.disabled = false;
		this.currentTabIndex = this.tabs.findIndex(
			(tabModel: TabModel) => tabModel.id === this.currentTab.id
		);
	};

	onBlockedCategoriesChange(selectedIds: Array<number>) {
		this.policy.blockedCategoryIds = selectedIds;
		this.policy.auditCategoryIds = this.supportedCategoryIds.filter((id) => !selectedIds.includes(id));

		this.currentBlockedCategories = this._categoryList
			.filter((category: WebContentFilteringCategoryType) => {
				return selectedIds.includes(category.id);
			})
			.map((category: WebContentFilteringCategoryType) => {
				return this.i18nService.get('webCategories.categories.' + category.name);
			});
	}

	savePolicy = () => {
		this.isSaving = true;
		this._savePolicySubscription = this._policyRepo.save(this.policy).subscribe(
			() => {
				this.isSaving = false;
				this.save.emit(this.policy);
			},
			(error) => {
				this.isSaving = false;
				this.dialogsService
					.showError({
						title: this.i18nService.get(
							'webContentFilteringPolicy.dataview.actions.new.errorMessage'
						),
						data: error,
					})
					.then(() => this.changeDetectorRef.markForCheck());
			}
		);
	};

	getLabelText(fieldId: string): string {
		return (
			this._valuesLabelTexts.get(fieldId) ||
			this.i18nService.get(
				'webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues'
			)
		);
	}

	setLabelText(fieldId: string, values: Array<ChecklistValue>) {
		const labelText: string = this._setLabelText(values),
			currentValue: string = this._valuesLabelTexts.get(fieldId);

		if (!currentValue || currentValue !== labelText) this._valuesLabelTexts.set(fieldId, labelText);
	}

	setMachineGroups(): Observable<Array<MachineGroup>> {
		return this._machineGroupsRepo.allItems$.pipe(
			tap((groups: Array<MachineGroup>) => {
				this._allMachineGroups = groups;
				this._setAvailableScopes();
			}),
			mergeMap(() => this._getUserExposedRbacGroups())
		);
	}

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!selectedMachineGroups || !selectedMachineGroups.length)
			this._setSelectedMachineGroups((this.policy.rbacGroupIds = []));
		else {
			this._onMachineGroupsChangedSubscription = combineLatest(
				selectedMachineGroups.map((selectedGroup: ChecklistValue) =>
					this._machineGroupsRepo.getItemById(selectedGroup.id)
				)
			).subscribe((machineGroups: Array<MachineGroup>) => {
				const machineGroupIds: Array<number> = machineGroups.map((group: MachineGroup) => group.id);
				this._setSelectedMachineGroups((this.policy.rbacGroupIds = machineGroupIds));
			});
		}
	}

	isValid(): boolean {
		switch (this.currentTab.id) {
			case Tabs.details: {
				return this.isPolicyNameValid();
			}
			case Tabs.action: {
				return true;
			}
			case Tabs.groups: {
				return this.isGroupsValid();
			}
			case Tabs.review: {
				return this.isPolicyNameValid() && this.isGroupsValid();
			}

			default:
				return false;
		}
	}

	isPolicyNameValid(): boolean {
		return !!this.policy.policyName;
	}

	isGroupsValid(): boolean {
		return (
			!this.loadingMachineGroups &&
			((this.selectedMachineGroupScope && this.selectedMachineGroupScope.id === ScopeTypesEnum.All) ||
				!!this.policy.rbacGroupIds.length)
		);
	}

	private _setSelectedMachineGroups(machineGroupIds: Array<number>) {
		this.selectedMachineGroups = this._allMachineGroups
			.filter((machineGroup: MachineGroup) => machineGroupIds.includes(machineGroup.id))
			.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));

		this.setLabelText(this.machineGroupsFieldId, this.selectedMachineGroups);
	}

	private _setLabelText(values: Array<ChecklistValue>): string {
		if (values.length > 3) return `${values.length} values`;

		const _values = values.map((value) => value.name);
		if (_values.length) return _values.join(', ');

		return this.i18nService.get(
			`webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues`
		);
	}

	private _setAvailableScopes() {
		if (this._allMachineGroups.length) {
			this.allowSpecificMachineGroups = true;
			if (this.authService.currentUser.isMdeAdmin) {
				this.allowAllMachineGroups = true;
			} else {
				this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
			}
		} else {
			this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
		}
	}

	private _getUserExposedRbacGroups(): Observable<Array<MachineGroup>> {
		return this.machinesService.getFullUserExposedMachineGroups().pipe(
			tap((userExposedMachineGroups: Array<MachineGroup>) => {
				this.availableMachineGroups = this._allMachineGroups
					.filter((machineGroup: MachineGroup) => {
						return (
							userExposedMachineGroups &&
							userExposedMachineGroups.some(
								(group: MachineGroup) => group.id === machineGroup.id
							)
						);
					})
					.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));
			})
		);
	}

	private _getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18nService.get('machineGroup.unassignedGroup.name')
				: machineGroup.name,
		};
	}

	private _setTabVisibility(tabId: Tabs, showTab: boolean) {
		const machineGroupTab: TabModel = this.tabs.find((tab: TabModel) => tab.id == tabId);
		machineGroupTab.show = () => showTab;
	}
}
