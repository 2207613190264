/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./machine-logged-on-users.component.ngfactory";
import * as i5 from "../services/machine-user-details-account.fields";
import * as i6 from "../../../global_entities/services/global-entity-types.service";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./machine-logged-on-users.component";
import * as i9 from "@microsoft/paris/dist/lib/paris";
import * as i10 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "./machine-logged-on-users.panel.component";
import * as i12 from "@angular/router";
var styles_MachineLoggedOnUsersPanelComponent = [];
var RenderType_MachineLoggedOnUsersPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineLoggedOnUsersPanelComponent, data: {} });
export { RenderType_MachineLoggedOnUsersPanelComponent as RenderType_MachineLoggedOnUsersPanelComponent };
export function View_MachineLoggedOnUsersPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 3, "machine-logged-on-users", [["class", "wcd-auto-height-responsive wcd-flex-1 wcd-flex-vertical wcd-full-height"]], null, null, null, i4.View_MachineLoggedOnUsersComponent_0, i4.RenderType_MachineLoggedOnUsersComponent)), i0.ɵprd(512, null, i5.MachineUserDetailsAccountFields, i5.MachineUserDetailsAccountFields, [i6.GlobalEntityTypesService, i7.I18nService]), i0.ɵdid(7, 114688, null, 0, i8.MachineLoggedOnUsersComponent, [i9.Paris, i10.FeaturesService, i5.MachineUserDetailsAccountFields, i6.GlobalEntityTypesService], { machine: [0, "machine"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.machine; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_MachineLoggedOnUsersPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-logged-on-users-panel", [], null, null, null, View_MachineLoggedOnUsersPanelComponent_0, RenderType_MachineLoggedOnUsersPanelComponent)), i0.ɵdid(1, 245760, null, 0, i11.MachineLoggedOnUsersPanelComponent, [i12.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineLoggedOnUsersPanelComponentNgFactory = i0.ɵccf("machine-logged-on-users-panel", i11.MachineLoggedOnUsersPanelComponent, View_MachineLoggedOnUsersPanelComponent_Host_0, { settings: "settings", machine: "machine" }, {}, []);
export { MachineLoggedOnUsersPanelComponentNgFactory as MachineLoggedOnUsersPanelComponentNgFactory };
