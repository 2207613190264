import { sccHostService } from '@wcd/scc-interface';
import { Feature, FeaturesService } from '@wcd/config';
import { DetectionSource } from '@wcd/domain';

const UnifiedAlertPageSupportedPrefixes = ['ar', 'da', 'fa', 'ea', 'ed'];

export function isUnifiedAlert(alertId: string, featuresService: FeaturesService, alertDetectionSource?: DetectionSource) {
	if (!featuresService.isEnabled(Feature.UnifiedExperienceConvergence) || !sccHostService.isSCC) {
		return false;
	}

	featuresService.isEnabled(Feature.AatpAlertPageEnabled) && UnifiedAlertPageSupportedPrefixes.push('aa');
	featuresService.isEnabled(Feature.AlertToEventAlertPage) && UnifiedAlertPageSupportedPrefixes.push('ra');
	featuresService.isAnyEnabled([Feature.AadIp2AlertPage,Feature.AadIp2AlertPageMtp]) && UnifiedAlertPageSupportedPrefixes.push('ad');
	featuresService.isEnabled(Feature.MteAlertPage) && UnifiedAlertPageSupportedPrefixes.push('ta');
	featuresService.isEnabled(Feature.DlpAlertPageEnabled) && UnifiedAlertPageSupportedPrefixes.push('dl');
	featuresService.isEnabled(Feature.McasAlertPage) && UnifiedAlertPageSupportedPrefixes.push('ca');
	return UnifiedAlertPageSupportedPrefixes.some(prefix => alertId.startsWith(prefix));
}
