<dl *ngIf="genericFields" class="key-values" role="none">
	<ng-container *ngFor="let genericField of genericFields">
		<dt [ngClass]="{'alert-process-tree-details': processTreeElementStyle,
					'alert-process-tree-details--key': processTreeElementStyle,
					'wcd-text-overflow-ellipsis-nowrap': processTreeElementStyle,
					'wcd-text-overflow-ellipsis': processTreeElementStyle}"
			[wcdTooltip]="genericField.key"
			role="none">
			{{ genericField.key }}
		</dt>
		<dd [ngClass]="getGenericFieldValueClass(genericField.valueType)"
			[wcdTooltip]="hasTooltipValue(genericField.valueType) ? genericField.value : null"
			role="none">
			<ng-container [ngSwitch]="genericField.valueType">
				<ng-container *ngSwitchCase="genericFieldType.String">{{ genericField.value }}</ng-container>
				<ng-container *ngSwitchCase="genericFieldType.DateTime">{{ genericField.value | date:'medium'}}</ng-container>
				<ng-container *ngSwitchCase="[genericFieldType.CopyPastableString, genericFieldType.Script].includes(genericField.valueType) ? genericField.valueType : ''">
					<ng-container *ngIf="!processTreeElementStyle; else processTreeCopyToClipboardInput">
						<copyable-input	[value]="genericField.value"></copyable-input>
					</ng-container>
					<ng-template #processTreeCopyToClipboardInput>
						<div class="wcd-flex-horizontal">
							<div class="wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis">
								{{ genericField.value }}
							</div>
							<copy-to-clipboard
								[ariaLabelFieldName]="genericField.key"
								[settings]="{ copyText: '' }"
								[content]="genericField.value"
								(click)="$event.stopPropagation()">
							</copy-to-clipboard>
						</div>
					</ng-template>
				</ng-container>
				<ng-container *ngSwitchCase="genericFieldType.LinkableString">
					<a [href]="genericField.valueLink" data-track-type="ExternalLink" target="_blank">
						{{ genericField.value }}
					</a>
				</ng-container>
				<value-with-external-link
					*ngSwitchCase="genericFieldType.VirusTotalFileReport"
					[context]="genericField.value"></value-with-external-link>
				<file-certificate-info
					*ngSwitchCase="genericFieldType.Signer"
					[file]="genericField.value"></file-certificate-info>
				<user-contact-details
					*ngSwitchCase="genericFieldType.ContactDetails"
					[user]="genericField.value"></user-contact-details>
				<ng-container *ngSwitchCase="genericFieldType.CyberEntity">
					<ng-container *ngIf="isEntityClickable(genericField); else cyberEntityDefault">
						<entity-link
							[entity]="getCyberEntity(genericField)"
							[entityType]="getCyberEntityType(genericField)"
							[linkText]="genericField.value"
							[linkLogContext]="linkLogContext">
						</entity-link>
					</ng-container>
					<ng-template #cyberEntityDefault>
						{{genericField.value}}
					</ng-template>
				</ng-container>
				<ng-container *ngSwitchCase="genericFieldType.MitreTechniques">
					<ng-container *ngIf="genericField.values | filterMitreTechniques; let mitreTechniques">
						<ng-container *ngIf="mitreTechniques.length">
							<span *ngFor="let mitreTechnique of mitreTechniques; let last = last">
								<a href="{{ mitreTechnique | mitreTechniqueLink }}"
									target="_blank">
									{{ mitreTechnique | mitreTechniqueDisplay }}
								</a>
								{{ last ? '' : '; ' }}
							</span>
						</ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</dd>
	</ng-container>
</dl>
