import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Component, Injector, ChangeDetectionStrategy } from '@angular/core';
import { FileActionDetails } from '@wcd/domain';

@Component({
	selector: 'file-action-entity-details',
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'alerts.events.details.actionTime' | i18n }}</dt>
			<dd>{{ action.actionTime | date: 'medium' }}</dd>

			<ng-container *ngIf="action.file?.folderPath">
				<dt>{{ 'files.entityDetails.sections.details.fields.folderPath.title' | i18n }}</dt>
				<dd>
					{{ action.file.folderPath }}
				</dd>
			</ng-container>
			<ng-container *ngIf="action.fileOrigin?.url?.name">
				<dt>{{ 'files.entityDetails.sections.details.fields.fileOriginUrl.title' | i18n }}</dt>
				<dd>
					<copyable-input
						[value]="action.fileOrigin.url.name"
						ariaLabel="{{
							'files.entityDetails.sections.details.fields.fileOriginUrl.title' | i18n
						}}"
					>
					</copyable-input>
				</dd>
			</ng-container>
			<ng-container *ngIf="action.fileOrigin?.ip?.name">
				<dt>{{ 'files.entityDetails.sections.details.fields.fileOriginIp.title' | i18n }}</dt>
				<dd>
					<copyable-input
						[value]="action.fileOrigin.ip.name"
						ariaLabel="{{
							'files.entityDetails.sections.details.fields.fileOriginIp.title' | i18n
						}}"
					>
					</copyable-input>
				</dd>
			</ng-container>
			<ng-container *ngIf="action.fileOriginReferer?.url?.name">
				<dt>
					{{ 'files.entityDetails.sections.details.fields.fileOriginReferer.title' | i18n }}
				</dt>
				<dd>
					<copyable-input
						[value]="action.fileOriginReferer.url.name"
						ariaLabel="{{
							'files.entityDetails.sections.details.fields.fileOriginReferer.title' | i18n
						}}"
					>
					</copyable-input>
				</dd>
			</ng-container>
		</dl>

		<antivirus-block-entity-details
			*ngIf="action.blockDetails"
			[entity]="action.blockDetails"
			[asKeyValueList]="asKeyValueList"
		></antivirus-block-entity-details>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileActionEntityDetailsComponent extends EntityDetailsComponentBase<FileActionDetails> {
	get action(): FileActionDetails {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
