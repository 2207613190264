
		<div class="wcd-full-height wcd-scroll-vertical">
			<div *ngIf="alert; else loader" class="wcd-flex-1 wcd-flex-horizontal">
				<div class="wcd-block wcd-padding-all wcd-flex-1">
					<h3 class="wcd-margin-bottom">{{ 'alert.tabs.description.title' | i18n }}</h3>
					<markdown [innerHTML]="alert.description"></markdown>
					<markdown [data]="alert.extendedDescription"></markdown>
				</div>
				<div class="wcd-block wcd-padding-all wcd-flex-1">
					<h3 class="wcd-margin-bottom">
						{{ 'alert.tabs.description.recommendedActions' | i18n }}
					</h3>
					<markdown [data]="alert.recommendedAction"></markdown>
				</div>
			</div>
			<ng-template #loader>
				<div class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</ng-template>
		</div>
	