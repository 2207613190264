import { AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { DynamicScriptService } from '@wcd/code-editor';
import { Paris } from '@microsoft/paris';
import { SupportCentralApiCall, SupportCentralResponse } from '@wcd/domain';
import { LocaleConfigService } from '@wcd/localization';
import { Observable, of, from } from 'rxjs';
import { AppInsightsService } from '../insights/services/app-insights.service';
import { map, catchError, switchMap } from 'rxjs/operators';
import { getPortalLanguage } from '@wcd/i18n';

const SUPPORT_CENTRAL_MDATP_APP_NAME = 'mdatp';
const SUPPORT_CENTRAL_SUCCESS_EVENT_NAME = 'LoadSuccess';
const SUPPORT_CENTRAL_SCRIPT_PATH = '/js/support/concierge/external/supportcentralbootstrap.js';
const COLLAPSE_BUTTON_RIGHT_POSITION_PX = 20;
const COLLAPSE_BUTTON_WIDTH_PX = 32;

@Component({
	selector: 'support-central',
	template: `
		<div
			#parentElement
			class="support-central-parent"
			style="position: fixed; visibility: hidden;"
			[ngStyle]="{ display: widgetParentDisplay }"
		></div>
		<div
			*ngIf="withCollapseButton"
			class="support-central-collapse-button"
			[ngStyle]="{ 'right.px': collapseButtonRightPositionPx, 'width.px': collapseButtonWidthPx }"
		>
			<fab-icon-button
				[styles]="{ root: { height: 40 } }"
				[iconProps]="{ iconName: collapseButtonIcon }"
				(click)="toggleCollapse()"
			></fab-icon-button>
		</div>
	`,
	styleUrls: ['./support-central.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SupportCentralComponent implements AfterViewInit {
	@ViewChild('parentElement', { static: false }) parentElement: ElementRef;
	withCollapseButton = false;
	isCollapsed = false;
	collapseButtonRightPositionPx: number = COLLAPSE_BUTTON_RIGHT_POSITION_PX;
	collapseButtonWidthPx: number = COLLAPSE_BUTTON_WIDTH_PX;

	get widgetParentDisplay(): string {
		return this.isCollapsed ? 'none' : 'unset';
	}

	get collapseButtonIcon(): string {
		return this.isCollapsed ? 'ChevronUp' : 'ChevronDown';
	}

	showCollapseButton() {
		this.withCollapseButton = true;
	}

	toggleCollapse(): void {
		this.isCollapsed = !this.isCollapsed;
	}

	constructor(
		private dynamicScriptService: DynamicScriptService,
		private paris: Paris,
		private localeConfigService: LocaleConfigService,
		private appInsightsService: AppInsightsService
	) {}

	ngAfterViewInit(): void {
		this.loadSupportCentral();
	}

	loadSupportCentral(): void {
		this.getConfigurationFromBackend()
			.pipe(
				switchMap((configResponse: SupportCentralResponse) => {
					return from(
						this.loadScript(configResponse.endpointUrl + SUPPORT_CENTRAL_SCRIPT_PATH).then(() => {
							return configResponse;
						})
					);
				})
			)
			.subscribe(
				(configResponse: SupportCentralResponse) => {
					if (window['SupportCentral'] && window['SupportCentral'].init) {
						window['SupportCentral'].init(
							this.getConfigurationObject(configResponse.token, configResponse.endpointUrl)
						);
					} else {
						this.appInsightsService.trackException(new Error('Failed to Load Support Central'));
					}
				},
				err => {
					this.appInsightsService.trackException(err, 'loadSupportCentral');
				}
			);
	}

	loadScript(scriptUrl: string): Promise<void> {
		return this.dynamicScriptService.loadScripts({ urls: [scriptUrl] });
	}

	handleEvent = (event: number) => {
		if (window['SupportCentral'] && window['SupportCentral'].HostingEvents) {
			const loadSuccessEvent =
				window['SupportCentral'].HostingEvents[SUPPORT_CENTRAL_SUCCESS_EVENT_NAME];
			if (event === loadSuccessEvent) {
				this.showCollapseButton();
			}
		}
	};

	getConfigurationObject(token: string, endpointUrl: string): SupportCentralConfig {
		return {
			parentDiv: this.parentElement.nativeElement,
			appName: SUPPORT_CENTRAL_MDATP_APP_NAME,
			auth: {
				endpointUrl,
				token,
				tokenRefresh: this.refreshToken,
			},
			settings: {
				disableMinimization: false,
				maximizeByDefault: false,
				locale: getPortalLanguage(),
			},
			styles: {
				minStateStyle: {
					position: 'absolute',
					right: `${this.collapseButtonRightPositionPx + this.collapseButtonWidthPx}px`,
					bottom: '0',
				},
				maxStateStyle: {
					position: 'absolute',
					height: '100%',
					width: '485px',
					right: '0',
					bottom: '0',
				},
			},
			eventListener: this.handleEvent,
		};
	}

	getConfigurationFromBackend(): Observable<SupportCentralResponse> {
		return this.paris.apiCall(SupportCentralApiCall);
	}

	refreshToken = (): Promise<string> => {
		return this.getConfigurationFromBackend()
			.pipe(
				map((configResponse: SupportCentralResponse) => {
					return configResponse.token;
				}),
				catchError(err => {
					this.appInsightsService.trackException(err, 'SupportCentralRefreshTokenFailed');
					return of(null);
				})
			)
			.toPromise();
	};
}

interface SupportCentralConfig {
	parentDiv: HTMLElement;
	appName: string;
	auth: {
		endpointUrl: string;
		token: string;
		tokenRefresh: () => Promise<string>;
	};
	settings: {
		disableMinimization: boolean;
		maximizeByDefault: boolean;
		userQuery?: string;
		requestId?: string;
		locale: string;
	};
	styles: {
		minStateStyle?: object;
		maxStateStyle?: object;
	};
	eventListener?: (event: number) => void;
	clientContext?: () => any;
}
