import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationSimulatorAgent } from './evaluation-model';

@Entity({
	singularName: 'Evaluation lab',
	pluralName: 'Evaluation lab',
	endpoint: 'evaluation/labs',
	allItemsProperty: 'labs',
	loadAll: false,
	saveMethod: 'POST',
	parseItemQuery: () => 'evaluation/labs',
	parseSaveQuery: (value: EvaluationLab) => 'evaluation/labs',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class EvaluationLab extends EntityModelBase<number> {
	@EntityField({ data: 'machineExpirationInHours' })
	machineExpirationInHours: number;

	@EntityField({ data: 'maxNumberOfMachines' })
	maxNumberOfMachines: number;

	@EntityField({ data: 'simulatorsSettings' })
	simulatorsSettings: SimulatorsSettingsData;
}

export interface SimulatorsSettingsData {
	SafeBreach?: {
		type: EvaluationSimulatorAgent.SafeBreach;
		isEnabled: boolean;
		additionalInfo: SimulatorAdditionalSettingsData;
	};
	AttackIQ?: {
		type: EvaluationSimulatorAgent.AttackIQ;
		isEnabled: boolean;
		additionalInfo: SimulatorAdditionalSettingsData;
	};
}

export interface SimulatorAdditionalSettingsData {
	FirstName: string;
	LastName: string;
	EmailAddress: string;
}
