import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

export type MachineRequestState =
	| 'Unknown'
	| 'RequestCompleted'
	| 'ReverseRequestCompleted'
	| 'RequestPending'
	| 'ReverseRequestPending';

@ValueObject({
	readonly: true,
	singularName: 'Device Requests State',
	pluralName: '',
})
export class MachineRequestsState extends ModelBase {
	@EntityField({ data: 'States.ForensicsRequest' })
	readonly forensics: MachineRequestState;

	@EntityField({ data: 'States.IsolationRequest' })
	readonly isolation: MachineRequestState;

	@EntityField({ data: 'States.ScanRequest' })
	readonly scan: MachineRequestState;

	@EntityField({ data: 'States.RestrictExecutionRequest' })
	readonly restrictExecution: MachineRequestState;

	@EntityField({ data: 'States.TroubleshootRequest' })
	readonly troubleshoot: MachineRequestState;

	@EntityField({ data: 'States.LogsCollectionRequest' })
	readonly collectLogs: MachineRequestState;
}
