/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i6 from "@angular-react/fabric";
import * as i7 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i8 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i9 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i10 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i11 from "@angular/common";
import * as i12 from "./malware-alerts.component";
import * as i13 from "@angular/router";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "../../../global_entities/services/global-entity-types.service";
import * as i16 from "../services/file-verdict-alert.service";
var styles_MalwareAlertsComponent = [];
var RenderType_MalwareAlertsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MalwareAlertsComponent, data: {} });
export { RenderType_MalwareAlertsComponent as RenderType_MalwareAlertsComponent };
function View_MalwareAlertsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-datatable", [], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], label: [3, "label"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = false; var currVal_2 = _co.tableFields; var currVal_3 = i0.ɵunv(_v, 3, 3, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.title")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_MalwareAlertsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "fab-spinner", [], null, null, null, i5.View_FabSpinnerComponent_0, i5.RenderType_FabSpinnerComponent)), i0.ɵdid(2, 5816320, null, 0, i6.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { label: [0, "label"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "loading")); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MalwareAlertsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i7.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i8.View_PanelComponent_0, i8.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i9.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i10.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 2, null, View_MalwareAlertsComponent_1)), i0.ɵdid(7, 16384, null, 0, i11.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i11.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, 1, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(0, [["loadingTemplate", 2]], 1, 0, null, View_MalwareAlertsComponent_2)), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.tableItems$)); var currVal_2 = i0.ɵnov(_v, 10); _ck(_v, 7, 0, currVal_1, currVal_2); }, null); }
export function View_MalwareAlertsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "malware-alert", [], null, null, null, View_MalwareAlertsComponent_0, RenderType_MalwareAlertsComponent)), i0.ɵdid(1, 245760, null, 0, i12.MalwareAlertsComponent, [i13.Router, i14.Paris, i0.Injector, i4.I18nService, i15.GlobalEntityTypesService, i16.FileVerdictAlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MalwareAlertsComponentNgFactory = i0.ɵccf("malware-alert", i12.MalwareAlertsComponent, View_MalwareAlertsComponent_Host_0, { settings: "settings", sha1: "sha1", fileVerdict: "fileVerdict" }, {}, []);
export { MalwareAlertsComponentNgFactory as MalwareAlertsComponentNgFactory };
