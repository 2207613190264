import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: 'Event mark',
	pluralName: 'Event marks',
	readonly: true,
})
export class CyberEventMark extends ModelBase {
	@EntityField({ data: 'MarkTime' })
	flagTime: Date;

	@EntityField({ data: 'UserPrincipalName' })
	upn?: string;

	@EntityField({ data: 'EventTime' })
	actionTime: Date;

	@EntityField({ data: 'EventTime' })
	actionTimeIsoString: string;

	@EntityField({ data: 'ActionType' })
	actionType: string;

	@EntityField({ data: 'SenseMachineId' })
	deviceId?: string;

	@EntityField({ data: 'ReportIndex' })
	reportId?: number;

	@EntityField({ data: 'AlertId' })
	alertId?: string;

	@EntityField({ data: 'IsMarked' })
	isFlagged: boolean;

	@EntityField()
	flagColor?: string;

	@EntityField()
	name?: string;
}
