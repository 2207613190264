import { ChangeDetectionStrategy, Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { SerializedFilters } from '../../../models/serialized-filters.type';

@Component({
	template: `
		<fab-date-picker
			[isMonthPickerVisible]="!!config?.isMonthPickerVisible"
			(onSelectDate)="onSelectDate($event.date)"
			[showGoToToday]="!!config?.showGoToToday"
			[minDate]="config?.minDate"
			[maxDate]="config?.maxDate"
			[placeholder]="config?.placeholder || 'Select date...'"
			[initialPickerDate]="config?.initialDate || selectedValues"
			[value]="selectedValues"
		>
		</fab-date-picker>
	`,
	selector: 'wcd-filter-values-datepicker',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesDatePickerComponent extends FilterValuesComponent<
	void,
	Date,
	Date,
	FilterValuesDatePickerConfig
> {
	constructor(private changeDetectorRef: ChangeDetectorRef, private ngZone: NgZone) {
		super();
	}

	get value(): Date {
		return this.selectedValues;
	}

	setSelectedValues(selectedValues: Date) {
		if (!selectedValues) this.selectedValues = this.config && this.config.initialDate;

		this.changeDetectorRef.markForCheck();
	}

	serialize(): SerializedFilters {
		return this.selectedValues ? { [this.fieldId]: this.selectedValues.toISOString() } : null;
	}

	deserialize(serializedDate: SerializedFilters): Date {
		let strDate = serializedDate[this.fieldId];
		if (strDate instanceof Array) strDate = strDate[0];

		return strDate ? new Date(strDate) : null;
	}

	onSelectDate(date: Date) {
		this.ngZone.run(() => {
			this.selectedValues = date;
			this.filterValuesChange.emit(date);
		});
	}
}

export interface FilterValuesDatePickerConfig {
	isMonthPickerVisible?: boolean;
	maxDate?: Date;
	minDate?: Date;
	placeholder?: string;
	showGoToToday?: boolean;
	initialDate?: Date;
}
