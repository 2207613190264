import { FabIconModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { CyberEventEntitiesGraphComponent } from './components/cyber-event.entities-graph.component';
import { CyberEventEntityPanelComponent } from './components/cyber-event.entity-panel.component';
import { CyberEventsEntityPanelComponent } from './components/cyber-events.entity-panel.component';
import { CyberEventEntityTypeService } from './services/cyber-event.entity-type.service';
import { CyberEventsActionTypesService } from './services/cyber-events-action-types.service';
import { CyberEventsTagsService } from './services/cyber-events-tags.service';
import { CyberEventsUtilsService } from './services/cyber-events-utils.service';
import { CyberEventsFieldsService } from './services/cyber-events.fields';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, DataViewsModule, FabIconModule],
	providers: [
		CyberEventEntityTypeService,
		CyberEventsFieldsService,
		CyberEventsActionTypesService,
		CyberEventsTagsService,
		CyberEventsUtilsService,
	],
	declarations: [
		CyberEventEntityPanelComponent,
		CyberEventEntitiesGraphComponent,
		CyberEventsEntityPanelComponent,
	],
	entryComponents: [CyberEventEntityPanelComponent, CyberEventsEntityPanelComponent],
	exports: [CyberEventEntitiesGraphComponent],
})
export class CyberEventsModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(CyberEventEntityTypeService);
	}
}
