import { Entity, EntityField, EntityModelBase, DataQuery, DataQuerySortDirection } from '@microsoft/paris';
import { toPairs, mapKeys, omitBy, isUndefined } from 'lodash-es';
import { Machine } from '../../machine/machine.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';

export const NO_SID_FALLBACK = 'nosid';
export const USER_LOOKBACK_IN_DAYS = 180;

interface LegacyUserIdentifier {
	readonly sid: string;
	readonly userAccount: string;
	readonly userAccountDomain: string;
}

const mapAccountKeys = obj => {
	const legacyIdentifierKeyMap = {
		accountName: 'userAccount',
		accountDomainName: 'userAccountDomain',
	};
	return mapKeys(obj, (_, key) => legacyIdentifierKeyMap[key] || key);
};

const destructJsonIdentifier = (userId: string): LegacyUserIdentifier => {
	if (userId[0] !== '{') {
		return null;
	}
	try {
		return mapAccountKeys(JSON.parse(userId)) as LegacyUserIdentifier;
	} catch (e) {
		return null;
	}
};

const destructIdentifier = (userId: string): LegacyUserIdentifier => {
	const jsonIdentifier = destructJsonIdentifier(userId);

	if (jsonIdentifier) {
		return jsonIdentifier;
	}

	const userParts = userId.split('/');
	if (userParts.length === 1) {
		return null;
	}

	const [userAccount, userAccountDomain, sid] = userParts;
	return { userAccount, userAccountDomain, sid };
};

const constructIdentifier = (identifier: LegacyUserIdentifier): string => {
	return `${identifier.userAccount}/${identifier.userAccountDomain}/${identifier.sid}`;
};

@Entity({
	singularName: 'User',
	pluralName: 'Users',
	endpoint: 'SearchUserAccountName',
	allItemsEndpointTrailingSlash: false,
	parseDataQuery: (dataQuery: DataQuery) => {
		const { where, sortBy, page: pageIndex, pageSize }: any = dataQuery || {};
		const keyMap = {
			searchTerm: 'userAccount',
		};
		const query = {
			pageIndex,
			pageSize,
			...mapKeys(where, (_, key) => keyMap[key] || key),
			...(sortBy && sortBy.length
				? {
						sortByField: sortBy[0].field,
						sortOrder:
							sortBy[0].direction === DataQuerySortDirection.ascending
								? 'Ascending'
								: 'Descending',
				  }
				: undefined),
		};
		return omitBy(query, isUndefined);
	},
	parseItemQuery: (userId: string) => {
		const identifier = destructIdentifier(userId);
		// Not a structured identifier. Guessing userId is an SID
		if (!identifier) {
			return `user?sid=${userId}&lookingBackIndays=${USER_LOOKBACK_IN_DAYS}`;
		}

		const queryParams = toPairs(identifier)
			.map(([key, value]) => `${key}=${value}`)
			.join('&');
		return `user?${queryParams}&lookingBackIndays=${USER_LOOKBACK_IN_DAYS}`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60 * 5,
		max: 20,
	},
})
export class LegacyUser extends EntityModelBase<string> {
	private _id: string;

	// override a property with a getter/setter pair isn't allowed
	// @ts-expect-error
	get id(): string {
		if (!this._id) {
			this._id = constructIdentifier({
				sid: this.sid,
				userAccount: this.accountName,
				userAccountDomain: this.accountDomainName,
			});
		}

		return this._id;
	}

	set id(_: string) {
		/**
		 * Ignore setting the ID property, since it doesn't have a single key in the raw data.
		 * Paris calls this when constructing the entity, which we need to ignore since this is a computed property in this case.
		 **/
	}

	@EntityField({ data: ['UserSid', 'AccountSid', 'Sid'] })
	sid: string;

	@EntityField({
		// NOTE: `AccountDomain` is the property name received from the `GET /user` API, `AccountDomainName` is used in some other, older APIs and should be aligned to `AccountDomain` with time.
		data: ['AccountDomain', 'AccountDomainName', 'DomainName'],
	})
	accountDomainName: string;

	@EntityField({ data: ['AccountName', 'UserName'] })
	accountName: string;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({
		data: '__self',
		parse: data => ({
			MachineId: data.MostPrevalentSenseMachineId,
			SenseMachineId: data.MostPrevalentSenseMachineId,
			ComputerDnsName: data.MostPrevalentComputerDnsName,
		}),
	})
	mostPrevalentMachine: Machine;

	@EntityField({
		data: '__self',
		parse: data => ({
			MachineId: data.LeastPrevalentSenseMachineId,
			SenseMachineId: data.LeastPrevalentSenseMachineId,
			ComputerDnsName: data.LeastPrevalentComputerDnsName,
		}),
	})
	leastPrevalentMachine: Machine;

	@EntityField({ data: 'LoggedOnMachines' })
	loggedOnMachinesCount: number;

	@EntityField({ data: 'IsDomainAdmin' })
	isDomainAdmin: boolean;

	@EntityField({ data: 'IsLocalAdmin' })
	isLocalAdmin?: boolean;

	@EntityField({ data: 'IsLocalAdminMostPrevalent' })
	isLocalAdminMostPrevalent: boolean;

	@EntityField({ data: 'IsLocalAdminLeastPrevalent' })
	isLocalAdminLeastPrevalent: boolean;

	@EntityField({ data: 'IsOnlyNetworkUser' })
	isOnlyNetworkUser: boolean;

	get fullName(): string {
		return `${this.accountDomainName ? this.accountDomainName + '\\' : ''}${this.accountName}`;
	}
}
