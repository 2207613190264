import { RbacMdeAllowedActions } from '../enums/mde-allowed-actions.enum';
import { MtpPermission, UnifiedRbacPermission } from '@wcd/auth';
import { MtpWorkload } from '@wcd/domain';

export interface RbacControlSettings {
	state?: RbacControlState;
	permissions?: Array<keyof typeof RbacMdeAllowedActions>;
	mtpPermissions?: Array<MtpPermission>;
	unifiedPermissions?: Array<UnifiedRbacPermission>;
	mtpWorkloads?: Array<MtpWorkload>;
	requireAllPermissions?: boolean;
	// Enable this flag if permissions check should ignore non active workloads
	ignoreNonActiveWorkloads?: boolean;
	// Enable this flag to display the 'RBAC disabled' tooltip.
	// This is 'opt-in' in order to allow components to define 'disabled' or other tooltips without the RBAC overriding it.
	allowRbacTooltipOverride?: boolean;
	customCheckCallback?: () => boolean;
}

export enum RbacControlState {
	hidden = 'hidden',
	disabled = 'disabled',
}
