<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="AlertLinkedAlertsPanel">

	<wcd-info-box *ngIf="unexposedAlertsCount > 0" class="wcd-block wcd-margin-xLarge-bottom">
		{{ 'alerts.panel.linkedByIncidentDetails.hiddenAlertsMessage' | i18n: { count: unexposedAlertsCount } }}
	</wcd-info-box>

	<wcd-datatable
		*ngIf="alerts?.length; else loadingOrError"
		[items]="alerts"
		[columns]="tableFields"
		[selectEnabled]="false"
		[showHeaders]="true"
		[allowResize]="false"
		[fixedTable]="true"
		[fullHeight]="false"
	></wcd-datatable>

	<ng-template #loadingOrError>
		<ng-container *ngIf="loading; else noData">
			<i class="loader-icon"></i>
			{{ 'common.loading' | i18n }}
		</ng-container>
	</ng-template>
	<ng-template #noData>
		{{ 'common.noDataAvailable' | i18n }}
	</ng-template>
</wcd-panel>
