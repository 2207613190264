import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
	FabButtonModule,
	FabIconModule,
	FabCheckboxModule,
	FabSpinnerModule,
	FabMessageBarModule,
	FabProgressIndicatorModule,
	FabToggleModule,
} from '@angular-react/fabric';
import { MarkdownModule } from 'ngx-markdown';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@wcd/i18n';
import { WcdIconsModule } from '@wcd/icons';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { ContentsStateModule } from '@wcd/contents-state';
import { WcdWizardModule } from '@wcd/wizard';
import { SccOnboardingWelcomeComponent } from './components/welcome.component';
import { OnboardingService } from './services/onboarding.service';
import { MtpConsentModule } from '../mtp-consent/mtp-consent.module';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { MtpOnboardingComponent } from './components/mtp-onboarding.component';
import { GeoSelectionComponent } from './components/geo-selection.component';

const dynamicComponents = [
	MtpOnboardingComponent,
	GeoSelectionComponent,
];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		MarkdownModule,
		FabIconModule,
		FabButtonModule,
		FabCheckboxModule,
		FabSpinnerModule,
		FabMessageBarModule,
		FabProgressIndicatorModule,
		WcdIconsModule,
		I18nModule,
		PanelsModule,
		WcdFormsModule,
		ContentsStateModule,
		WcdWizardModule,
		MtpConsentModule,
		AngularExtensionsModule,
		FabToggleModule,
	],
	providers: [OnboardingService],
	declarations: [...dynamicComponents, SccOnboardingWelcomeComponent],
	entryComponents: [...dynamicComponents],
	exports: [SccOnboardingWelcomeComponent],
})
export class SccOnboardingModule {}
