import { InvestigationStatusEnum } from '../investigation/investigation-status.enum';

/*
 * TODO:
 *  There's 2 fields with typo - 'PartialyRemediated' & 'PartiallyInvesitagted'.
 *  The typo is in AlertV3 schema and should be fixed in the backend.
 *  This is a temporary solution and these 2 fields will be removed after the fix in the schema.
 */

export enum InvestigationStatusesMapping {
	TerminatedByUser = InvestigationStatusEnum.terminatedByUser,
	TerminatedBySystem = InvestigationStatusEnum.terminatedBySystem,
	SuccessfullyRemediated = InvestigationStatusEnum.fullyRemediated,
	FullyRemediated = InvestigationStatusEnum.fullyRemediated,
	Benign = InvestigationStatusEnum.benign,
	Pending = InvestigationStatusEnum.pending,
	Failed = InvestigationStatusEnum.failed,
	InnerFailure = InvestigationStatusEnum.innerFailure,
	PartiallyRemediated = InvestigationStatusEnum.partiallyRemediated,
	Running = InvestigationStatusEnum.running,
	PendingApproval = InvestigationStatusEnum.pendingUser,
	PendingResource = InvestigationStatusEnum.pendingResource,
	Disabled = InvestigationStatusEnum.disabled,
	Queued = InvestigationStatusEnum.queued,
	UnsupportedOs = InvestigationStatusEnum.unsupportedOs,
	UnsupportedAlertType = InvestigationStatusEnum.unsupportedAlertType,
	PreexistingAlert = InvestigationStatusEnum.preexistingAlert,
	SuppressedAlert = InvestigationStatusEnum.suppressedAlert,
	PartiallyInvestigated = InvestigationStatusEnum.partiallyInvestigated,

	PartialyRemediated = InvestigationStatusEnum.partiallyRemediated,
	PartiallyInvesitagted = InvestigationStatusEnum.partiallyInvestigated,
}

export enum InvestigationStatusToFilterQueryString {
	TerminatedByUser = InvestigationStatusEnum.terminatedByUser,
	TerminatedBySystem = InvestigationStatusEnum.terminatedBySystem,
	SuccessfullyRemediated = InvestigationStatusEnum.fullyRemediated,
	Benign = InvestigationStatusEnum.benign,
	Pending = InvestigationStatusEnum.pending,
	Failed = InvestigationStatusEnum.failed,
	InnerFailure = InvestigationStatusEnum.innerFailure,
	PartiallyRemediated = InvestigationStatusEnum.partiallyRemediated,
	Running = InvestigationStatusEnum.running,
	PendingApproval = InvestigationStatusEnum.pendingUser,
	PendingResource = InvestigationStatusEnum.pendingResource,
	Disabled = InvestigationStatusEnum.disabled,
	Queued = InvestigationStatusEnum.queued,
	UnsupportedOs = InvestigationStatusEnum.unsupportedOs,
	UnsupportedAlertType = InvestigationStatusEnum.unsupportedAlertType,
	PreexistingAlert = InvestigationStatusEnum.preexistingAlert,
	SuppressedAlert = InvestigationStatusEnum.suppressedAlert,
	PartiallyInvestigated = InvestigationStatusEnum.partiallyInvestigated,
}
