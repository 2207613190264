import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { map } from 'lodash-es';

export type MachineRequestErrorCodeRaw =
	| 2147942722
	| 2147942623
	| 2147943862
	| 2149122552
	| 2149122456
	| 2149122551
	| 32802
	| 32803
	| -2146992093
	| 2
	| -2147024894
	| 15301
	| -2147009595
	| -2147023890
	| 1006
	| -2145844840
	| 408
	| -2147024891
	| 5
	| -2147020579
	| 4317
	| -2147416390
	| 0;

export type MachineRequestErrorCodeValue =
	| 'DeviceHasNoResources'
	| 'FileTooLarge'
	| 'IncorrectSize'
	| 'GatewayTimeout'
	| 'RequestTimeout'
	| 'ServiceUnavailable'
	| 'RebootRequired'
	| 'FileSignedByTrustedPublisher'
	| 'fileNotFoundItMayHaveBeenDeletedOrMoved'
	| 'FileHasBeenModified'
	| 'FileEmptyOrInvalid'
	| 'MachineDidNotGetCommand'
	| 'AccessDeniedFileInUseOrReadOnly'
	| 'AntivirusScanAlreadyInProgress'
	| 'WindowsDefenderAntivirusDisabled'
	| ''
	| 'Unknown';

interface IMachineRequestErrorCode {
	readonly rawValue: MachineRequestErrorCodeRaw;
	readonly value: MachineRequestErrorCodeValue;
}

const rawToValueMap: Record<MachineRequestErrorCodeRaw, MachineRequestErrorCodeValue> = {
	2147942722: 'DeviceHasNoResources',
	2147942623: 'FileTooLarge',
	2147943862: 'IncorrectSize',
	2149122552: 'GatewayTimeout',
	2149122456: 'RequestTimeout',
	2149122551: 'ServiceUnavailable',
	32802: 'RebootRequired',
	32803: 'FileSignedByTrustedPublisher',
	'-2146992093': 'FileSignedByTrustedPublisher',
	2: 'fileNotFoundItMayHaveBeenDeletedOrMoved',
	'-2147024894': 'fileNotFoundItMayHaveBeenDeletedOrMoved',
	15301: 'FileHasBeenModified',
	'-2147009595': 'FileHasBeenModified',
	'-2147023890': 'FileEmptyOrInvalid',
	1006: 'FileEmptyOrInvalid',
	'-2145844840': 'MachineDidNotGetCommand',
	408: 'MachineDidNotGetCommand',
	'-2147024891': 'AccessDeniedFileInUseOrReadOnly',
	5: 'AccessDeniedFileInUseOrReadOnly',
	'-2147020579': 'AntivirusScanAlreadyInProgress',
	4317: 'AntivirusScanAlreadyInProgress',
	'-2147416390': 'WindowsDefenderAntivirusDisabled',
	0: '',
};

const values = map<typeof rawToValueMap, IMachineRequestErrorCode>(rawToValueMap, (value, key) => {
	const rawValue = Number(key) as MachineRequestErrorCodeRaw;
	return {
		id: rawValue,
		rawValue,
		value,
	};
});

@Entity({
	singularName: 'Device request error code',
	pluralName: 'Device request error codes',
	values: values,
	readonly: true,
})
export class MachineRequestErrorCode extends EntityModelBase<MachineRequestErrorCodeRaw> {
	@EntityField() readonly rawValue: MachineRequestErrorCodeRaw;

	@EntityField() readonly value: MachineRequestErrorCodeValue;
}
