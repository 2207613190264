/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component.ngfactory";
import * as i5 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "@angular/common";
import * as i10 from "./alert-investigation-status.component";
import * as i11 from "@microsoft/paris/dist/lib/paris";
import * as i12 from "../../../../../../../projects/config/src/lib/services/polling.service";
var styles_AlertInvestigationStatusComponent = [];
var RenderType_AlertInvestigationStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertInvestigationStatusComponent, data: {} });
export { RenderType_AlertInvestigationStatusComponent as RenderType_AlertInvestigationStatusComponent };
function View_AlertInvestigationStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "wcd-help", [], null, null, null, i4.View_HelpComponent_0, i4.RenderType_HelpComponent)), i0.ɵdid(7, 638976, null, 0, i5.HelpComponent, [i6.DomSanitizer], { text: [0, "text"] }, null), i0.ɵppd(8, 1), (_l()(), i0.ɵted(9, 0, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusInfo.investigationStatus.iconName; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), (("investigations.status." + _co.statusInfo.investigationStatus.type) + ".description"))); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.statusInfo.investigationStatus.name; _ck(_v, 9, 0, currVal_2); }); }
function View_AlertInvestigationStatusComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "large-loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_AlertInvestigationStatusComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i7.I18nPipe, [i8.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertInvestigationStatusComponent_1)), i0.ɵdid(3, 16384, null, 0, i9.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["loader", 2]], null, 0, null, View_AlertInvestigationStatusComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.statusInfo.ready; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_AlertInvestigationStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-investigation-status", [], null, null, null, View_AlertInvestigationStatusComponent_0, RenderType_AlertInvestigationStatusComponent)), i0.ɵdid(1, 245760, null, 0, i10.AlertInvestigationStatusComponent, [i0.ChangeDetectorRef, i11.Paris, i12.PollingService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertInvestigationStatusComponentNgFactory = i0.ɵccf("alert-investigation-status", i10.AlertInvestigationStatusComponent, View_AlertInvestigationStatusComponent_Host_0, { alertId: "alertId" }, {}, []);
export { AlertInvestigationStatusComponentNgFactory as AlertInvestigationStatusComponentNgFactory };
