import {
	Directive,
	ElementRef,
	AfterContentInit,
	OnDestroy,
	Output,
	EventEmitter,
	Input,
} from '@angular/core';
import { FocusableOption, FocusOrigin } from '@angular/cdk/a11y';
import { Ie11Service } from '../services/ie11.service';

const enum TabStop {
	Skippable = -1,
	Stoppable = 0,
}

export const KeyboardNavigableElementAddedEventName = 'keyboard-navigable-element.added';
export const KeyboardNavigableElementRemoveEventName = 'keyboard-navigable-element.removed';
export const KeyboardNavigableElementSelectedExternallyEventName = 'keyboard-navigable-element.selected';

/*
	This directive should be used along with KeyboardNavigableContainer in order to enable selection of element
	(focus on elements) using arrow keys
 */
@Directive({
	selector: '[keyboard-navigable-element]',
})
export class KeyboardNavigableElementDirective implements FocusableOption, AfterContentInit, OnDestroy {

	@Input() elementVisible: boolean = true;

	@Output() onTabIndexChange: EventEmitter<number> = new EventEmitter<number>();

	readonly nativeElement: HTMLElement;
	private readonly isIe11: boolean;

	constructor(
		readonly elementRef: ElementRef<HTMLElement>,
		private ie11Service: Ie11Service,
	) {
		this.nativeElement = elementRef.nativeElement;
		this.isIe11 = this.ie11Service.isIe11;
	}

	// FocusableOption interface
	focus(_origin: FocusOrigin): void {
		this.tabindex = TabStop.Stoppable;
		if (_origin !== "program" )
			this.nativeElement.focus();
	}

	getLabel(): string {
		return this.nativeElement.innerText;
	}

	get disabled(): boolean {
		return this.nativeElement.getAttribute('disabled') === 'true';
	}

	set disabled(value: boolean) {
		if (!value) {
			this.nativeElement.removeAttribute('disabled');
		} else {
			this.nativeElement.setAttribute('disabled', 'true');
		}
	}
	// end FocusableOption interface

	private get tabindex(): TabStop {
		const tabindex = this.nativeElement.getAttribute('tabindex');
		return parseInt(tabindex);
	}

	private set tabindex(value: TabStop) {
		this.nativeElement.setAttribute('tabindex', value.toString());
		if (this.onTabIndexChange) {
			this.onTabIndexChange.emit(value);
		}
	}

	get visible(): boolean {
		const element = this.nativeElement;
		return this.elementVisible && !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
	}

	get isStopPoint(): boolean {
		return this.tabindex >= TabStop.Stoppable;
	}

	deactivate() {
		this.tabindex = TabStop.Skippable;
	}

	fireEvent(eventName: string) {
		if (this.isIe11) {
			this.nativeElement.dispatchEvent(
				this.ie11Service.createIe11CustomEvent(eventName, {
					detail: this,
					bubbles: true,
				})
			);
		} else {
			this.nativeElement.dispatchEvent(
				new CustomEvent(eventName, {
					detail: this,
					bubbles: true,
				})
			);
		}
	}

	private notifyFocus = () => this.fireEvent(KeyboardNavigableElementSelectedExternallyEventName);

	ngAfterContentInit() {
		const tabindex = this.tabindex;

		if (tabindex == undefined || isNaN(tabindex)) {
			this.nativeElement.setAttribute('tabindex', TabStop.Skippable.toString());
		}
		this.nativeElement.addEventListener('focus', this.notifyFocus);
		this.fireEvent(KeyboardNavigableElementAddedEventName);
	}

	ngOnDestroy() {
		this.nativeElement.removeEventListener('focus', this.notifyFocus);
		this.fireEvent(KeyboardNavigableElementRemoveEventName);
	}
}
