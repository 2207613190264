
		<span [ngClass]="getCssClassFunc(value$ | async)">
			<wcd-shared-icon
				*ngIf="getIconNameFunc && getIconNameFunc(value$ | async)"
				[ngClass]="getIconCssClassFunc(value$ | async)"
				[iconName]="getIconNameFunc(value$ | async)"
			>
			</wcd-shared-icon>
			{{ getTextFunc(value$ | async) }}
		</span>
	