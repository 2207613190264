
		<div class="relative wcd-flex-vertical" [attr.aria-label]="description">
			<wcd-shared-icon
				[iconName]="iconName"
				[ngClass]="minimizedData"
				class="wcd-entity-panel-close-icon"
			></wcd-shared-icon>
			<div
				class="wcd-machine-minimized-indicators wcd-flex-horizontal wcd-flex-justify-content-space-between"
			>
				<div
					*ngIf="machineRiskClass"
					[ngClass]="machineRiskClass"
					class="machine-risk-indicator"
					[attr.aria-label]="riskDescription"
					[wcdTooltip]="riskDescription"
					tabindex="0"
				></div>
				<div
					class="machine-status-active"
					*ngIf="isActive"
					[attr.aria-label]="statusDescription"
					[wcdTooltip]="statusDescription"
					tabindex="0"
				></div>
			</div>
		</div>
	