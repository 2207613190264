/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./live-response-session.header-bottom.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../projects/angular-extensions/src/lib/directives/let.directive";
import * as i4 from "./live-response-session.header-bottom.component";
import * as i5 from "../../services/global-entity-types.service";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../@entities/live_response/services/live-response.service";
import * as i8 from "../../services/entity-panels.service";
import * as i9 from "@angular/cdk/a11y";
var styles_LiveResponseSessionHeaderBottomComponent = [i0.styles];
var RenderType_LiveResponseSessionHeaderBottomComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LiveResponseSessionHeaderBottomComponent, data: {} });
export { RenderType_LiveResponseSessionHeaderBottomComponent as RenderType_LiveResponseSessionHeaderBottomComponent };
function View_LiveResponseSessionHeaderBottomComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "span", [["class", "wcd-padding-none-vertical padding-vertical-sm-screens wcd-font-size-s"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "span", [["class", "wcd-badge"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "wcd-badge"; var currVal_1 = ("color-box-" + _v.context.ngLet.className); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.ngLet.text; _ck(_v, 8, 0, currVal_2); }); }
export function View_LiveResponseSessionHeaderBottomComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-flex-vertical fix-top-margin"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_LiveResponseSessionHeaderBottomComponent_1)), i1.ɵdid(4, 16384, null, 0, i3.LetDirective, [i1.ViewContainerRef, i1.TemplateRef], { ngLet: [0, "ngLet"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.connectionData$)); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_LiveResponseSessionHeaderBottomComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_LiveResponseSessionHeaderBottomComponent_0, RenderType_LiveResponseSessionHeaderBottomComponent)), i1.ɵdid(1, 114688, null, 0, i4.LiveResponseSessionHeaderBottomComponent, [i5.GlobalEntityTypesService, i6.I18nService, i7.LiveResponseService, i8.EntityPanelsService, i9.LiveAnnouncer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LiveResponseSessionHeaderBottomComponentNgFactory = i1.ɵccf("ng-component", i4.LiveResponseSessionHeaderBottomComponent, View_LiveResponseSessionHeaderBottomComponent_Host_0, { entity: "entity", entityType: "entityType" }, {}, []);
export { LiveResponseSessionHeaderBottomComponentNgFactory as LiveResponseSessionHeaderBottomComponentNgFactory };
