/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./modal-placeholder.component";
import * as i2 from "../services/modal.service";
var styles_ModalPlaceholderComponent = [];
var RenderType_ModalPlaceholderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ModalPlaceholderComponent, data: {} });
export { RenderType_ModalPlaceholderComponent as RenderType_ModalPlaceholderComponent };
export function View_ModalPlaceholderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { viewContainerRef: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 16777216, [[1, 3], ["modalplaceholder", 1]], null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_ModalPlaceholderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "modal-placeholder", [], null, null, null, View_ModalPlaceholderComponent_0, RenderType_ModalPlaceholderComponent)), i0.ɵdid(1, 114688, null, 0, i1.ModalPlaceholderComponent, [i2.ModalService, i0.Injector, i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ModalPlaceholderComponentNgFactory = i0.ɵccf("modal-placeholder", i1.ModalPlaceholderComponent, View_ModalPlaceholderComponent_Host_0, {}, {}, []);
export { ModalPlaceholderComponentNgFactory as ModalPlaceholderComponentNgFactory };
