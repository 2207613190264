import { OnInit } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { AdvancedSettingsStore } from '../services/advanced-settings.store';
import { RegExpService } from '@wcd/shared';
import { NgForm } from '@angular/forms';
import { FeaturesService, Feature } from '@wcd/config';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
var AdvancedSettingsComponent = /** @class */ (function () {
    function AdvancedSettingsComponent(advancedSettingsStore, authService, featuresService, performanceSccService) {
        this.advancedSettingsStore = advancedSettingsStore;
        this.authService = authService;
        this.featuresService = featuresService;
        this.performanceSccService = performanceSccService;
        this.isSaving = false;
        this.isMemoryContentEnabled = featuresService.isEnabled(Feature.AutomatedIRInvestigateProcess);
    }
    Object.defineProperty(AdvancedSettingsComponent.prototype, "regExpService", {
        get: function () {
            return RegExpService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AdvancedSettingsComponent.prototype, "isDirty", {
        get: function () {
            return this.settingsForm.dirty;
        },
        enumerable: true,
        configurable: true
    });
    AdvancedSettingsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.advancedSettingsStore.getAdvancedSettings().subscribe(function (settings) {
            _this.performanceSccService.endNgPageLoadPerfSession('endpoints-automation-uploads');
            _this.settings = settings;
        });
    };
    AdvancedSettingsComponent.prototype.saveSettings = function () {
        var _this = this;
        this.isSaving = true;
        this.advancedSettingsStore
            .save(this.settings)
            .then(function (settings) {
            _this.isSaving = false;
            _this.settingsForm.form.markAsPristine();
        })
            .catch(function () {
            _this.isSaving = false;
        });
    };
    AdvancedSettingsComponent.prototype.cancelEdit = function () {
        this.settings.restore();
        this.settingsForm.form.markAsPristine();
    };
    return AdvancedSettingsComponent;
}());
export { AdvancedSettingsComponent };
