import { Router } from '@angular/router';
import { LocalStorageService } from '@wcd/shared';
import { MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { MagellanFeaturesProvider } from '@wcd/domain';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { Paris } from '@microsoft/paris';
import { RoutesService } from '@wcd/shared';
import { AuthService, MtpPermission } from '@wcd/auth';
import { TzDateService } from '@wcd/localization';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';
import { AdvancedFeaturesService } from '../../../../admin/integration-settings/advanced-features.service';
import { MtpWorkload } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
var DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY = 'proactive-message-dismissed';
var StandardDiscoveryMessageComponent = /** @class */ (function () {
    function StandardDiscoveryMessageComponent(router, localStorageService, featuresService, i18nService, appInsightsService, authService, appContextService, tzDateService, advancedFeaturesService, appConfigService, paris) {
        this.router = router;
        this.localStorageService = localStorageService;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.authService = authService;
        this.appContextService = appContextService;
        this.tzDateService = tzDateService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.appConfigService = appConfigService;
        this.MessageBarType = MessageBarType;
        this.showProactiveDiscoveryAutoStartMessage = false;
        this.isAdmin = this.authService.currentUser.isMdeAdmin;
        this.magellanOptOut = this.appConfigService.magellanOptOut;
        this.magellanFeaturesProvider = new MagellanFeaturesProvider(paris);
        var hasAnyViewPermission = this.authService.currentUser.hasRequiredMtpPermissionInWorkload(MtpPermission.SecurityData_Read, MtpWorkload.Mdatp) ||
            this.authService.currentUser.hasRequiredMtpPermissionInWorkload(MtpPermission.TvmData_Read, MtpWorkload.Mdatp);
        if (hasAnyViewPermission &&
            !appConfigService.magellanOptOut &&
            !this.localStorageService.getItem(DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY) &&
            this.featuresService.isEnabled(Feature.AutoProactiveDiscoveryMessage)) {
            this.displayAutoProactiveDeviceDiscoveryNotificationIfNotSet();
        }
    }
    StandardDiscoveryMessageComponent.prototype.displayAutoProactiveDeviceDiscoveryNotificationIfNotSet = function () {
        var _this = this;
        this.magellanFeaturesProvider
            .getFeaturesWithDates()
            .then(function (_a) {
            var isProactiveDiscoveryEmpty = _a.isProactiveDiscoveryEmpty, proactiveDiscoveryEnablingDate = _a.proactiveDiscoveryEnablingDate, isProactiveDiscoveryEnablingDatePassed = _a.isProactiveDiscoveryEnablingDatePassed;
            _this.showProactiveDiscoveryAutoStartMessage =
                isProactiveDiscoveryEmpty && !isProactiveDiscoveryEnablingDatePassed;
            _this.proactiveDiscoveryAutoStartMessage = _this.i18nService.get('DevicesPageAutoProactiveMessage', { date: _this.tzDateService.format(proactiveDiscoveryEnablingDate, 'MMMM d, y') });
            _this.appInsightsService.trackEvent('magellan_settings_display_auto_standard_discovery_message_in_device_inventory', {
                component: 'DevicesComponent',
                showProactiveDiscoveryAutoStartMessage: _this
                    .showProactiveDiscoveryAutoStartMessage,
            });
        });
    };
    StandardDiscoveryMessageComponent.prototype.navigateToProactiveSettings = function () {
        this.appInsightsService.track({
            type: TrackingEventType.Navigation,
            id: 'securitysettings_device_discovery_edit',
            component: 'DevicesComponent',
        });
        if (this.appContextService.isSCC) {
            this.router.navigate(['/securitysettings/device_discovery']);
        }
        else {
            window.open(RoutesService.getSCCRootUrl() + "/securitysettings/device_discovery", '_blank');
        }
    };
    StandardDiscoveryMessageComponent.prototype.activateProactiveSettings = function () {
        this.appInsightsService.track({
            type: TrackingEventType.Navigation,
            id: 'securitysettings_device_discovery_setStandardDiscovery_true',
            component: 'DevicesComponent',
        });
        if (this.appContextService.isSCC) {
            this.router.navigate(['/securitysettings/device_discovery'], {
                queryParams: { setStandardDiscovery: true },
            });
        }
        else {
            window.open(RoutesService.getSCCRootUrl() + "/securitysettings/device_discovery?setStandardDiscovery=true", '_blank');
        }
    };
    StandardDiscoveryMessageComponent.prototype.hideProactiveDiscoveryAutoStartMessage = function () {
        this.localStorageService.setItem(DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY, '1');
        this.showProactiveDiscoveryAutoStartMessage = false;
    };
    return StandardDiscoveryMessageComponent;
}());
export { StandardDiscoveryMessageComponent };
