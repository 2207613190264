import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component, ComponentRef, ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	Output,
} from '@angular/core';
import { escapeRegExp, find } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Tag } from '@wcd/domain';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { ReadOnlyIdentifiableConfig } from '../../../data/models/readonly-identifiable.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { SearchResult, ISearchSettings } from '@wcd/forms';
import { Disableable, DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'tags-edit',
	templateUrl: './tags-edit.component.html',
	styleUrls: ['./tags-edit.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => TagsEditComponent),
		},
	],
})
export class TagsEditComponent implements OnChanges<TagsEditComponent>, Disableable {
	@Input() tags: Array<Tag>;
	@Input() readonly: boolean;
	@Input() settings: TagsEditSettings;
	@Input() callOnEscape: { escape: () => void };
	@Input() tagEditDialogOpen: boolean = false;
	@Input() dialogId: string = null;

	@Output() readonly changed = new EventEmitter<ReadonlyArray<Tag>>();

	localTags: ReadonlyArray<Tag>;

	boundSearchAndAddNew = this.searchAndAddNew.bind(this);

	constructor(
		private readonly dialogsService: DialogsService,
		private readonly changeDetector: ChangeDetectorRef,
		private readonly i18nService: I18nService
	) {}

	ngOnChanges(changes: TypedChanges<TagsEditComponent>) {
		this.localTags = this.tags ? [...this.tags] : [];
	}

	escapeClicked() {
		if (this.callOnEscape) {
			this.callOnEscape.escape();
		}
	}

	private onTagAdded(tag: Tag) {
		this.localTags = [...this.localTags, tag];
		this.changeDetector.detectChanges();

		this.setTags([tag], []).subscribe(
			() => {
				this.changed.emit(this.localTags);
			},
			() => {
				this.localTags = this.localTags.filter((_, i) => i !== this.localTags.length - 1);
				this.changeDetector.detectChanges();
			}
		);
	}

	onTagRemoved(tag: Tag) {
		const tagIndex = this.removeTagFromLocal(tag);
		this.setTags([], [tag]).subscribe(
			() => this.changed.emit(this.localTags),
			() => {
				const [firstElement, ...rest] = this.localTags;
				this.localTags = [tag, ...rest];
				this.changeDetector.detectChanges();
			}
		);
	}

	setTags(addedTags: ReadonlyArray<Tag>, removedTags: ReadonlyArray<Tag>): Observable<any> {
		return this.settings.setTags(this.localTags, addedTags, removedTags).pipe(
			tap(
				() => {
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.strings.tags_tagUpdated,
						icon: 'tag',
						focusableQuery: `#${this.dialogId}_searchbox-input`
					});
				},
				error => {
					this.dialogsService.showError({
						title: this.i18nService.strings.tags_failedToUpdate,
						data: error,
					});
				}
			)
		);
	}

	private removeTagFromLocal(tag: Tag): number {
		const tagIndex = this.localTags.findIndex(t => t.name === tag.name);
		if (tagIndex >= 0) {
			this.localTags = this.localTags.filter((_, i) => i !== tagIndex);
			this.changeDetector.detectChanges();
		}

		return tagIndex;
	}

	onTagAddClick(event: SearchResult) {
		if (event && event.item) {
			const tag: Tag = event.item;
			if (!tag.id) {
				// create new tag and then add to list
				this.settings.createNewTag(tag).subscribe(
					(createdTag: Tag) => {
						this.removeTagFromLocal(tag);
						this.onTagAdded(createdTag || tag);
					},
					error => this.removeTagFromLocal(tag)
				);
			} else {
				// add an existing tag to list
				const existingTag = this.localTags.find(t => t.id === tag.id);
				if (!existingTag) this.onTagAdded(tag);
				else if (existingTag.isPartial) {
					this.removeTagFromLocal(tag);
					this.onTagAdded(tag);
				}
			}
		}
	}

	searchAndAddNew(term): Observable<readonly Tag[] | readonly SearchResult[]>{
		return this.settings.searchFunction ? this.settings.searchFunction(term).pipe(map(results=>{
			return find(results, { label: term.trim()}) ? results : results.concat({
				value: '',
				label: `${term} (Create new)`,
				item: new Tag({ id: undefined, name: term }),
			});
		})) :
		this.localSearch(term).pipe(map(results=>{
			return  find(results, { label: term.trim()}) ? results : results.concat(new Tag({ id: undefined, name: `${term} (Create new)` }));
		}))
	}

	localSearch(term: string): Observable<ReadonlyArray<Tag>> {
		const termRegExp = new RegExp(escapeRegExp(term), 'i');
		const results = this.tags.filter(tag => termRegExp.test(tag.name));

		return of(results);
	}
}

export interface TagsEditSettings<T = any> {
	readonly getTags?: (input?: ReadonlyArray<T>) => Observable<ReadonlyArray<Tag>>;
	readonly setTags: (
		tags: ReadonlyArray<Tag>,
		addedTags?: ReadonlyArray<Tag>,
		removedTags?: ReadonlyArray<Tag>
	) => Observable<any>;
	readonly createNewTag: (tag: Tag) => Observable<ReadOnlyIdentifiableConfig>;
	readonly searchFunction?: (term: string) => Observable<ReadonlyArray<SearchResult>>;
	readonly searchSettings: ISearchSettings<Tag>;
}
