import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'PowerShell command',
	pluralName: 'PowerShell commands',
	readonly: true,
})
export class CommandLine extends EntityModelBase<string> {
	@EntityField({ data: 'Command' })
	commandText?: string;

	@EntityField({ data: 'Name' })
	name?: string;

	@EntityField({ data: 'EvidenceId', required: false })
	evidenceId?: string;

	@EntityField({ data: 'EntityPageUrl', required: false })
	entityPageUrl?: string;

	@EntityField({ data: 'AlertProduct', required: false })
	alertProduct?: string;
}
