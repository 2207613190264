import { ChangeDetectorRef, Component, Injector } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Machine } from '@wcd/domain';
import { MachinesService } from '../services/machines.service';
import { MachineEntityTypeOptions } from '../services/machine.entity-type.service';

@Component({
	selector: 'machine-entity-panel',
	templateUrl: './machine.entity-panel.component.html',
})
export class MachineEntityPanelComponent extends EntityPanelComponentBase<Machine, MachineEntityTypeOptions> {
	get machine(): Machine {
		return this.entity;
	}

	constructor(
		private injector: Injector,
		private machinesService: MachinesService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
	}

	showLoggedOnUsers() {
		this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
			noShadow: true,
			hasCloseButton: false,
		});
	}
}
