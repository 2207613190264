import { SevilleModule } from '../../../../../app/seville/seville.module';

declare let angular: angular.IAngularStatic;

SevilleModule.controller('seville.settings.siemconnector', settingsSiemConnectorController);

// register url route
SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		$stateProvider.state('preferences.siemconnector', {
			url: '/siemconnector',
			title: 'SIEM',
			parent: 'preferences',
			views: {
				SettingsView: {
					template: `
						<div class="settings-view-page">
                <div class="settings-page-title">
                    Enable SIEM connector to pull alerts to your security and event management (SIEM) solution or to pull alerts directly through the alerts REST API.<br />
                    See <a href="https://go.microsoft.com/fwlink/p/?linkid=845276" target="_blank">Pull alerts to your SIEM tools</a> section in the <a href="https://technet.microsoft.com/en-us/itpro/windows/keep-secure/windows-defender-advanced-threat-protection" target="_blank">Microsoft Defender for Endpoint guide</a> for configuration details.
            </div>
                <div ng-if="siemctrl.loadingProvisioningDetails">
                    <img class="settings-page-loading" src="/assets/images/linear-loader.gif" />
                </div>
                <div ng-if="siemctrl.getAppDetailsError" class="settings-error-message">
                    {{siemctrl.getAppDetailsError}}
                </div>
                <div id="settings.siemConnector.provisionBlock"
                	rbacjs state="'disabled'" permissions="['systemSettings']"
                	ng-if="!siemctrl.loadingProvisioningDetails && !siemctrl.getAppDetailsError">
                    <div id="settings.siemConnector.provisionControl">
                        <div ng-if="!siemctrl.applicationWasAlreadyProvisioned">
                            <button id="settings.siemConnector.startProvisionBtn" ng-click="siemctrl.startProvisioning()" class="settings-button settings-customerTI-button" ng-class="{ 'settings-button-disabled': (siemctrl.isProvisioningRunning || siemctrl.provisionCompleted), 'settings-button-enabled': !(siemctrl.isProvisioningRunning || siemctrl.provisionCompleted)}" ng-disabled="(siemctrl.isProvisioningRunning || siemctrl.provisionCompleted)">
                                Enable SIEM connector
                            </button>
                            <span ng-if="siemctrl.isProvisioningRunning">
                                <img class="settings-customerTI-loadingImg" src="/assets/images/circle-loading.gif" /> {{siemctrl.provisioningText}}
                            </span>
                            <div ng-if="siemctrl.provisioningError" class="settings-error-message settings-customerTI-error-message-spacing">
                                {{siemctrl.provisioningError}}
                            </div>
                        </div>
                        <div ng-if="siemctrl.applicationWasAlreadyProvisioned">
                            <div>
                                <i class="icon icon-lg icon-Completed nopadding"></i>
                                <div class="settings-customerTI-appProvisionedAlready">
                                    A dedicated application was created under you Azure Active Directory tenant.<br />
                                    Use the application details and generate tokens to configure your SIEM connector.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="settings.siemConnector.appDetails">
                        <h3 class="settings-customerTI-provisionSectionSpacing">
                            SIEM application details
                        </h3>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Application URI
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.applicationUri" ng-model="siemctrl.applicationUri" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-clientsideutils-copy-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }" ng-click="siemctrl.copyToClipboard('settings.siemConnector.applicationUri')">
                                    <i class="icon {{siemctrl.copyToClipboardAppUriIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Client ID
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.clientId" ng-model="siemctrl.clientId" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-clientsideutils-copy-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }" ng-click="siemctrl.copyToClipboard('settings.siemConnector.clientId')">
                                    <i class="icon {{siemctrl.copyToClipboardClientIdIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Authorization server URL
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.authZServer" ng-model="siemctrl.authZServerUrl" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-clientsideutils-copy-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }" ng-click="siemctrl.copyToClipboard('settings.siemConnector.authZServer')">
                                    <i class="icon {{siemctrl.copyToClipboardAuthZServerUrlIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Resource
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.resource" ng-model="siemctrl.resource" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-clientsideutils-copy-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }" ng-click="siemctrl.copyToClipboard('settings.siemConnector.resource')">
                                    <i class="icon {{siemctrl.copyToClipboardResourceIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Client secret
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <i class="icon icon-IncidentTriangle settings-customTI-clientSecret-Icon"></i>
                                <input id="settings.siemConnector.clientSecret" ng-model="siemctrl.clientSecret" placeholder="{{siemctrl.clientSecretPlaceholder}}" class="settings-customerTI-appProperty" ng-class="{'settings-customerTI-appPropertyDisabled': !siemctrl.applicationWasAlreadyProvisioned}" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) && siemctrl.clientSecret, 'settings-clientsideutils-copy-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) || !siemctrl.clientSecret }" ng-click="siemctrl.copyToClipboard('settings.siemConnector.clientSecret')">
                                    <i class="icon {{siemctrl.copyToClipboardClientSecretIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="settings-customTI-clientSecret-blockSpacing">
                            <span class="settings-customTI-clientSecret-warningText">
                                The client secret is displayed only once. Keep a copy safe. You need it to generate tokens.
                            </span>
                        </div>

                        <div class="settings-siemConnector-connectorSelection">
                            <span class="settings-customerTI-appSettingsTitle">Choose the SIEM you want to configure and download details to file:</span>
                            <form name="connectorTypeSelection"
                            	rbacjs state="'disabled'" permissions="['systemSettings']"
                            	ng-class="{'disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }">
                                <label>
                                    <input type="radio" ng-model="siemctrl.connectorType" value="ArcSight" ng-change="siemctrl.updateConnectorSelection()"> HP ArcSight
                                </label>
                                <label>
                                    <input type="radio" ng-model="siemctrl.connectorType" value="Splunk" ng-change="siemctrl.updateConnectorSelection()"> Splunk
                                </label>
                                <label>
                                    <input type="radio" ng-model="siemctrl.connectorType" value="Generic" ng-change="siemctrl.updateConnectorSelection()"> Generic API
                                </label>
                            </form>
                        </div>

                        <div class="settings-customerTI-saveDetailsBtn">
                            <button ng-click="siemctrl.saveDetailsToFile()" class="settings-button settings-customerTI-button" ng-disabled="!(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned)" ng-class="{ 'settings-button-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-button-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }">
                                Save details to file
                            </button>
                            <div ng-if="siemctrl.noSelectedConnector" class="settings-error-message settings-customerTI-error-message-spacing">Please select a connector</div>
                            <div ng-if="customti.saveDetailsToFileError" class="settings-error-message settings-customerTI-error-message-spacing">
                                {{siemctrl.saveDetailsToFileError}}
                            </div>
                            <div ng-if="siemctrl.showDisablePopupMsg" class="settings-customerTI-disablePopupBlocker">
                                Make sure that your browser pop-up blockers are disabled
                            </div>
                            <div class="settings-siemConnector-proceedToTechnetSpacing">
                                After creating the application and downloading the relevant files you can configure your SIEM, See the SIEM integration.
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                <div id="fetchTokenBlock" class="settings-customerTI-generateTokensBlock"
						rbacjs state="'disabled'" permissions="['systemSettings']"
						ng-disabled="!(siemctrl.applicationWasAlreadyProvisioned || siemctrl.provisionCompleted)"
                		ng-if="!siemctrl.loadingProvisioningDetails && !siemctrl.getAppDetailsError">
                    <h3>
                        API tokens generator
                    </h3>
                    <div class="settings-customerTI-appDetailsDesc">
                        Generate tokens for authenticating to the API.<br />
                        <i class="icon icon-Info settings-advanced-warning-icon"></i> You'll need to generate a new refresh token every 90 days.
                    </div>
                    <div class="settings-customerTI-generateTokensBtnSpacing">
                        <button ng-click="siemctrl.generateTokens()" class="settings-button settings-customerTI-button" ng-disabled="!(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned)" ng-class="{ 'settings-button-disabled': !(siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned), 'settings-button-enabled': (siemctrl.provisionCompleted || siemctrl.applicationWasAlreadyProvisioned) }">
                            Generate tokens
                        </button>
                        <span ng-if="siemctrl.isGeneratingTokens">
                            <img width="20" height="20" src="/assets/images/circle-loading.gif" /> Generating tokens...
                        </span>
                        <div ng-if="siemctrl.generateTokensError" class="settings-error-message settings-customerTI-error-message-spacing">
                            {{siemctrl.generateTokensError}}
                        </div>
                        <div ng-if="!siemctrl.generateTokensError && siemctrl.accessToken" class="settings-customerTI-disablePopupBlocker">
                            The access token expires after an hour.
                        </div>
                    </div>
                    <div id="tokenDetails">
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Access token
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.accessToken" ng-model="siemctrl.accessToken" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': siemctrl.accessToken, 'settings-clientsideutils-copy-disabled': !siemctrl.accessToken}" ng-click="siemctrl.copyToClipboard('settings.siemConnector.accessToken')">
                                    <i class="icon {{siemctrl.copyToClipboardAccessTokenIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left settings-customerTI-appSettingsTitle">
                                Refresh token
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 text-left">
                                <input id="settings.siemConnector.refreshToken" ng-model="siemctrl.refreshToken" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-customerTI-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': siemctrl.refreshToken, 'settings-clientsideutils-copy-disabled': !siemctrl.refreshToken}" ng-click="siemctrl.copyToClipboard('settings.siemConnector.refreshToken')">
                                    <i class="icon {{siemctrl.copyToClipboardRefreshTokenIcon}}"></i> Copy
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
						`,
					controller: 'seville.settings.siemconnector',
					controllerAs: 'siemctrl',
				},
			},
		});
	},
]);

settingsSiemConnectorController.$inject = [
	'$log',
	'$scope',
	'$q',
	'$timeout',
	'$interval',
	'seville.settings.connectorsFactory',
	'seville.settings.clientsideutils',
];

function settingsSiemConnectorController(
	$log,
	$scope,
	$q,
	$timeout,
	$interval,
	connectorsService,
	clientSideUtils
) {
	var vm = this;

	// general settings
	vm.baseUrl = $scope.$parent.settingsBaseUrl;
	vm.isReadOnly = connectorsService.isReadOnly();

	// provisioning \ tokens generation states
	vm.loadingProvisioningDetails = true;
	vm.applicationWasAlreadyProvisioned = false;
	vm.isProvisioningRunning = false;
	vm.isGeneratingTokens = false;
	vm.provisionCompleted = false;
	vm.provisioningText = 'Provisioning...';

	// errors state \ info
	vm.getAppDetailsError = '';
	vm.provisioningError = '';
	vm.saveDetailsToFileError = '';
	vm.generateTokensError = '';
	vm.noSelectedConnector = false;
	vm.showDisablePopupMsg = false;

	// 'copy to clipboard' icons (to manage states between copy \ copied)
	vm.copyToClipboardAppUriIcon = 'icon-Copy';
	vm.copyToClipboardClientIdIcon = 'icon-Copy';
	vm.copyToClipboardClientSecretIcon = 'icon-Copy';
	vm.copyToClipboardAuthZServerUrlIcon = 'icon-Copy';
	vm.copyToClipboardResourceIcon = 'icon-Copy';
	vm.copyToClipboardAccessTokenIcon = 'icon-Copy';
	vm.copyToClipboardRefreshTokenIcon = 'icon-Copy';

	// AAD application details
	vm.applicationUri = '';
	vm.clientId = '';
	vm.clientSecret = '';
	vm.authZServerUrl = '';
	vm.resource = '';
	vm.clientSecretPlaceholder = '';

	// tokens details
	vm.accessToken = '';
	vm.refreshToken = '';

	// local helper members
	vm.connectorType = '';
	var authUrl = '';
	var numGetAuthCodeAttemtps = 0;
	var authCodeReceived = false;
	var pollForAuthTokenCompletion = null;

	vm.startProvisioning = function() {
		numGetAuthCodeAttemtps = 0;
		vm.provisionCompleted = false;
		vm.provisioningError = '';
		vm.isProvisioningRunning = true;
		authCodeReceived = false;

		var popUp = connectorsService.openPopup('SIEMConnector', authUrl, 'Setup SIEM Connector', 483, 600);
		pollForAuthTokenCompletion = $interval(
			function() {
				numGetAuthCodeAttemtps++;

				// wait 2 second before attempting to start check for completion (allow short grace time for the auth code issuance & redirection to complete..)
				if (numGetAuthCodeAttemtps < 5) {
					return;
				}

				// either max number of attempts reached, or window was closed - stop the flow
				if (numGetAuthCodeAttemtps > 59 || !angular.isDefined(popUp) || popUp === null) {
					stopPollingToGetAuthCode(popUp, 'AppProvision');
					return;
				}

				var statusElement = popUp.document.getElementById('status');

				if (angular.isDefined(statusElement) && statusElement !== null) {
					var status = statusElement.value;

					if (status === 'Failure') {
						// trying to generate the auth code failed - stop the flow
						var error = popUp.document.getElementById('error').value;
						$log.error('Provisioning SIEM Connector - getting auth code Failed. Err: ' + error);
						stopPollingToGetAuthCode(popUp, 'AppProvision');
					} else if (status === 'Success') {
						// auth code generated successfully
						var authCode = popUp.document.getElementById('authCode').value;
						authCodeReceived = true;
						stopPollingToGetAuthCode(popUp, 'AppProvision');

						connectorsService.generateApplication('ArcSight', vm.baseUrl, authCode).then(
							function(appDetails) {
								vm.applicationUri = appDetails.appUri;
								vm.clientId = appDetails.clientId;
								vm.clientSecret = appDetails.clientSecret;
								vm.authZServerUrl = appDetails.authZServerUrl;
								vm.resource = appDetails.resource;

								// since once the AAD Graph Api returned the created App details it actually takes 10 - 15 seconds for the App to be available in the customer tenant, we need to stall some time
								// (If the user tries to generate tokens before the App is available, he will get an error)
								vm.provisioningText = 'Finalizing for a few seconds…';
								$timeout(function() {
									SetProvisioningCompleted();
								}, 15000);
							},
							function(err) {
								SetProvisioningFailed(
									err !== null && angular.isDefined(err) ? err.status : 'N/A'
								);
							}
						);
					}
				}
			},
			500,
			60
		);
	};

	vm.updateConnectorSelection = function() {
		vm.noSelectedConnector = false;
	};

	vm.saveDetailsToFile = function() {
		vm.saveDetailsToFileError = '';

		if (vm.connectorType === '') {
			vm.noSelectedConnector = true;
			return 0;
		}

		vm.showDisablePopupMsg = true;

		$timeout(function() {
			vm.showDisablePopupMsg = false;
		}, 3500);

		// currently, the server API only supports ArcSight & Splunk ('Splunk' matches the 'Generic' selection as well..)
		var translatedConnectorType = vm.connectorType === 'ArcSight' ? 'ArcSight' : 'Splunk';

		connectorsService.saveDetailsToFile(translatedConnectorType, vm.baseUrl, vm.clientSecret).then(
			function(success) {},
			function(err) {
				vm.saveDetailsToFileError =
					'A problem occurred while performing the operation. Please try again later. Contact your support provider if this problem persists.';
			}
		);
	};

	vm.generateTokens = function() {
		// case where the app was already provisioned, and the user did not enter the client secret
		if (vm.clientSecret === null || vm.clientSecret.length === 0) {
			vm.generateTokensError = 'You must enter your client secret to be able to generate tokens.';
			return;
		}

		numGetAuthCodeAttemtps = 0;
		vm.generateTokensError = '';
		authCodeReceived = false;
		vm.isGeneratingTokens = true;

		// replace the original Client Id (from seville app) to the newly generated client app
		var currAuthUrl = connectorsService.adjustAuthUrl(authUrl, vm.clientId);

		var popUp = connectorsService.openPopup('SIEMConnector', currAuthUrl, 'Genearate Tokens', 530, 600);
		pollForAuthTokenCompletion = $interval(
			function() {
				numGetAuthCodeAttemtps++;

				// wait 2 second before attempting to start check for completion (allow short grace time for the auth code issuance & redirection to complete..)
				if (numGetAuthCodeAttemtps < 5) {
					return;
				}

				// either max number of attempts reached, or window was closed - stop the flow
				if (numGetAuthCodeAttemtps > 59 || !angular.isDefined(popUp) || popUp === null) {
					stopPollingToGetAuthCode(popUp, 'GenerateToken');
					return;
				}

				var statusElement = popUp.document.getElementById('status');

				if (angular.isDefined(statusElement) && statusElement !== null) {
					var status = statusElement.value;
					if (status === 'Failure') {
						// trying to generate the auth code failed - stop the flow
						var error = popUp.document.getElementById('error').value;
						$log.error('Generate tokens - getting auth code Failed. Err: ' + error);
						stopPollingToGetAuthCode(popUp, 'GenerateToken');
					} else if (status === 'Success') {
						// auth code generated successfully
						var authCode = popUp.document.getElementById('authCode').value;
						authCodeReceived = true;
						stopPollingToGetAuthCode(popUp, 'GenerateToken');

						vm.generateTokensError = '';
						connectorsService
							.generateTokensFromAuthCode('ArcSight', vm.baseUrl, vm.clientSecret, authCode)
							.then(
								function(tokens) {
									vm.accessToken = tokens.accessToken;
									vm.refreshToken = tokens.refreshToken;

									SetTokensGenerationCompleted();
								},
								function(err) {
									SetTokensGenerationFailed(err.status, err.data);
								}
							);
					}
				}
			},
			500,
			60
		);
	};

	vm.copyToClipboard = function(elementId) {
		clientSideUtils.copyToClipboard('SIEMConnector', elementId, setCopyToClipboardIcon);
	};

	function init() {
		// Get existing AAD application details (if already created before) + the Url to authenticate the user \ generate auth code

		var getAppDetailsPromise = connectorsService.getCreatedAppDetails('ArcSight', vm.baseUrl);
		var getAuthUrlPromise = connectorsService.getAuthUrl('ArcSight', vm.baseUrl);

		$q.all([getAppDetailsPromise, getAuthUrlPromise])
			.then(
				function(results) {
					var appDetails = results[0];
					authUrl = results[1];

					if (appDetails != null && authUrl !== null) {
						vm.applicationUri = appDetails.appUri;
						vm.clientId = appDetails.clientId;
						vm.clientSecret = appDetails.clientSecret;
						vm.authZServerUrl = appDetails.authZServerUrl;
						vm.resource = appDetails.resource;

						vm.applicationWasAlreadyProvisioned = true;
						vm.clientSecretPlaceholder =
							'Enter the client secret you received after creating the API';
					}

					vm.loadingProvisioningDetails = false;
					$log.debug('SIEMConnector.Init() completed successfully');
				},
				function(error) {
					vm.loadingProvisioningDetails = false;
					vm.getAppDetailsError =
						'A problem occurred while retrieving siem connector application details. Please try again later. Contact your support provider if this problem persists.'; // note: the actual failure may be a result of either of the 2 api calls made.
					$log.error('SIEMConnector.Init() failed.');
				}
			)
			.then(function() {
				$scope.$parent.applyGetPreferencesSuccess(); // this call is to notify the parent settings page that loading initial data has completed (either failed or succeded)
			});
	}

	function setCopyToClipboardIcon(id, icon) {
		if (id === 'settings.siemConnector.applicationUri') {
			vm.copyToClipboardAppUriIcon = icon;
		}

		if (id === 'settings.siemConnector.clientId') {
			vm.copyToClipboardClientIdIcon = icon;
		}

		if (id === 'settings.siemConnector.authZServer') {
			vm.copyToClipboardAuthZServerUrlIcon = icon;
		}

		if (id === 'settings.siemConnector.resource') {
			vm.copyToClipboardResourceIcon = icon;
		}

		if (id === 'settings.siemConnector.clientSecret') {
			vm.copyToClipboardClientSecretIcon = icon;
		}

		if (id === 'settings.siemConnector.accessToken') {
			vm.copyToClipboardAccessTokenIcon = icon;
		}

		if (id === 'settings.siemConnector.refreshToken') {
			vm.copyToClipboardRefreshTokenIcon = icon;
		}
	}

	function stopPollingToGetAuthCode(popUp, actionType) {
		if (angular.isDefined(pollForAuthTokenCompletion) && pollForAuthTokenCompletion !== null) {
			$interval.cancel(pollForAuthTokenCompletion);
			pollForAuthTokenCompletion = undefined;
		}

		popUp.close();

		// if getting the auth code has failed (or timed out) - set provisioning status as failed
		if (!authCodeReceived) {
			if (actionType === 'AppProvision') {
				SetProvisioningFailed('N/A');
			} else {
				SetTokensGenerationFailed('N/A');
			}
		} else {
			$log.debug('SIEMConnector - auth code retrieved successfully');
		}
	}

	function SetProvisioningCompleted() {
		vm.provisionCompleted = true;
		vm.isProvisioningRunning = false;
		vm.provisioningError = '';

		$log.debug('SIEMConnector - Provisioning completed successfully');
	}

	function SetProvisioningFailed(status) {
		vm.isProvisioningRunning = false;
		vm.provisionCompleted = false;

		vm.provisioningError =
			'A problem occurred while enabling the application. Please try again later. Contact your support provider if this problem persists.';
		$log.error('SIEMConnector - Provisioning has failed. status: ' + status);
	}

	function SetTokensGenerationFailed(status, errorDetails?) {
		vm.isGeneratingTokens = false;

		vm.generateTokensError =
			'A problem occurred while generating tokens. Please try again later. Contact your support provider if this problem persists.';
		if (status === 400 && errorDetails !== null && errorDetails.Code === 1) {
			vm.generateTokensError = 'Invalid client secret';
		}

		$log.error('SIEMConnector - Tokens Generation has failed. status: ' + status);
	}

	function SetTokensGenerationCompleted() {
		vm.isGeneratingTokens = false;

		vm.generateTokensError = '';
		$log.debug('SIEMConnector - Tokens Generation completed successfully');
	}

	init();
}
