import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Office integration settings',
	pluralName: 'Office integration settings',
	endpoint: 'QueryOfficeAtpIntegrationStatus',
	parseItemQuery: () => 'QueryOfficeAtpIntegrationStatus',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.officeOptIn,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class OfficeIntegrationSettings extends EntityModelBase {
	@EntityField({ data: 'IsAuthorized', defaultValue: false })
	isAuthorized: boolean;

	@EntityField({ data: 'OfficeToAtpIntegrationEnabled', defaultValue: false })
	officeToAtpIntegrationEnabled: boolean;

	@EntityField({ data: 'OfficeLicenseEnabled', defaultValue: false })
	officeLicenseEnabled: boolean;

	@EntityField({ data: 'EnableOffice365Integration', defaultValue: false })
	enableOffice365Integration: boolean;

	@EntityField({ data: 'LicenseEnabled', defaultValue: false })
	licenseEnabled: boolean;

	get isE2EIntegrationEnabled(): boolean {
		return (
			this.isAuthorized &&
			this.officeLicenseEnabled &&
			this.officeToAtpIntegrationEnabled &&
			this.enableOffice365Integration
		);
	}
}
