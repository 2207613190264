
		<table class="wcd-margin-large-left">
			<tr>
				<th *ngFor="let header of contextTableHeaders">
					{{ 'tvm.securityRecommendation.recommendationContext.' + header | i18n }}
				</th>
			</tr>
			<tr *ngFor="let contextDataItem of _asset.recommendationContext">
				<td
					*ngFor="let header of contextTableHeaders; let first = first; let last = last"
					[class]="
						last
							? 'wcd-border-bottom wcd-font-size-s'
							: 'wcd-border-bottom full-width-cell wcd-font-size-s'
					"
					[wcdTooltip]="contextDataItem[header]"
				>
					<span
						*ngIf="first && recommendationContextIcon !== ''"
						[class]="recommendationContextIcon"
					></span>
					{{ contextDataItem[header] }}
				</td>
			</tr>
		</table>
	