var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, Injector, OnDestroy } from '@angular/core';
import { Alert, CyberEventActionTypeName, GetAlertsByIdsVNextApiCall, LegacyUser, Process, } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { CyberEventEntityDetailsComponent } from '../../../global_entities/components/entity-details/cyber-event.entity-details.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { AlertEntityDetailsComponent } from '../../../global_entities/components/entity-details/alert.entity-details.component';
import { Paris } from '@microsoft/paris';
import { CyberEventsActionTypesService, } from '../services/cyber-events-action-types.service';
import { isAccountLinkable } from '@wcd/scc-interface';
import { MdatpMitreService } from '@wcd/scc-common';
import { GetAlertsByIdsApiCall } from '@wcd/domain';
import { CyberEventsUtilsService } from '../services/cyber-events-utils.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { Feature, FeaturesService } from '@wcd/config';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "cyber-event-entity-panel";
    CollapsibleID["MitreTechniquesInfo"] = "cyber-event-entity-panel-mitre-techniques-info";
    CollapsibleID["DetectedAlert"] = "cyber-event-entity-panel-detected-alert";
    CollapsibleID["EntitiesDetails"] = "cyber-event-entity-panel-entities-details";
    CollapsibleID["AlertDetails"] = "cyber-event-entity-panel-alert-details";
    CollapsibleID["AlertDescription"] = "cyber-event-entity-panel-alert-description";
    CollapsibleID["AlertRecommendedAction"] = "cyber-event-entity-panel-alert-recommended-action";
})(CollapsibleID || (CollapsibleID = {}));
var CyberEventEntityPanelComponent = /** @class */ (function (_super) {
    __extends(CyberEventEntityPanelComponent, _super);
    function CyberEventEntityPanelComponent(changeDetectorRef, injector, cyberEventsActionTypesService, cyberEventsUtilsService, featuresService, paris) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.injector = injector;
        _this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        _this.cyberEventsUtilsService = cyberEventsUtilsService;
        _this.featuresService = featuresService;
        _this.paris = paris;
        _this.alertType = Alert;
        _this.collapsibleID = CollapsibleID;
        _this.isAlertRow = false;
        _this.entityPanelsService = _this.injector.get(EntityPanelsService);
        _this.setMitreTechniquesObjects();
        return _this;
    }
    Object.defineProperty(CyberEventEntityPanelComponent.prototype, "event", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    CyberEventEntityPanelComponent.prototype.ngOnDestroy = function () {
        this.activeAlertsSubscription && this.activeAlertsSubscription.unsubscribe();
    };
    CyberEventEntityPanelComponent.prototype.setEntity = function (entity, isExtendedData) {
        var _this = this;
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entity, isExtendedData);
        this.setMitreTechniquesObjects();
        this.isAlertRow = entity.actionType && entity.actionType.id === CyberEventActionTypeName.Alert;
        this.eventDetailsComponent && this.eventDetailsComponent.setEntity(entity);
        this.alertDetailsComponent && this.alertDetailsComponent.setEntity(entity.relatedAlert);
        if (!this.isAlertRow) {
            if (this.options && this.options['entityType'] === 'machines') {
                if (this.event.alertIds && this.event.alertIds.length) {
                    var getAlertsByIdsCall = this.featuresService.isEnabled(Feature.K8SMigrationLegacyAlertsByIds)
                        ? this.paris.apiCall(GetAlertsByIdsVNextApiCall, this.event.alertIds) : this.paris.apiCall(GetAlertsByIdsApiCall, this.event.alertIds);
                    this.activeAlertsSubscription = getAlertsByIdsCall
                        .subscribe(function (alertsList) {
                        _this.alerts = alertsList.alerts;
                        _this.changeDetectorRef.markForCheck();
                    });
                }
            }
            this.eventEntities = this.cyberEventsActionTypesService.getEventEntities(this.event);
        }
        else {
            this.alerts = [];
        }
        this.changeDetectorRef.markForCheck();
        this.cyberEventsUtilsService.trackCyberEventPanelEvent('CyberEventEntityPanelEvent', TrackingEventType.SidePaneToggleButton, [this.event]);
    };
    CyberEventEntityPanelComponent.prototype.openAlertPanel = function (alert) {
        var _this = this;
        this.entityPanelsService.showEntityById(Alert, alert.id, null, {
            back: {
                onClick: function () { return _this.entityPanelsService.closeEntityPanel(Alert); },
            },
        });
    };
    CyberEventEntityPanelComponent.prototype.isEntityClickable = function (eventEntity) {
        var isClickable = eventEntity.item &&
            (eventEntity.entityType === Process
                ? eventEntity.item.file && eventEntity.item.file.id
                : eventEntity.entityType === LegacyUser
                    ? isAccountLinkable(eventEntity.item)
                    : eventEntity.item.id);
        return !!isClickable;
    };
    CyberEventEntityPanelComponent.prototype.setMitreTechniquesObjects = function () {
        var mitreInfo = this.event && this.event.mitreInfo;
        if (mitreInfo) {
            this.mitreTechniquesObjects = MdatpMitreService.getFilteredMitreTechniquesObjectsFromMitreInfo(mitreInfo);
        }
    };
    CyberEventEntityPanelComponent.prototype.onLinkClick = function (id) {
        this.cyberEventsUtilsService.trackCyberEventPanelEvent(id, TrackingEventType.ExternalLink, [
            this.event,
        ]);
    };
    return CyberEventEntityPanelComponent;
}(EntityPanelComponentBase));
export { CyberEventEntityPanelComponent };
