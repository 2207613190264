/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./distribution.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./distribution-element/distribution-element.component.ngfactory";
import * as i3 from "@angular/common";
import * as i4 from "./distribution-element/distribution-element.component";
import * as i5 from "../../services/known-colors.service";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "./distribution.component";
import * as i9 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
var styles_DistributionComponent = [i0.styles];
var RenderType_DistributionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DistributionComponent, data: {} });
export { RenderType_DistributionComponent as RenderType_DistributionComponent };
function View_DistributionComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "distribution-element", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onClick.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_DistributionElementComponent_0, i2.RenderType_DistributionElementComponent)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(3, 573440, null, 0, i4.DistributionElementComponent, [i5.KnownColorsService], { barColor: [0, "barColor"], title: [1, "title"], titleColor: [2, "titleColor"], titleSize: [3, "titleSize"], mainText: [4, "mainText"], mainTextSize: [5, "mainTextSize"], mainTextColor: [6, "mainTextColor"], secondaryText: [7, "secondaryText"], secondaryTextSize: [8, "secondaryTextSize"], secondaryFontWeight: [9, "secondaryFontWeight"], secondaryTextColor: [10, "secondaryTextColor"], size: [11, "size"], clickable: [12, "clickable"], helpText: [13, "helpText"] }, { onClick: "onClick" }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.sameWidth ? "wcd-width-small-medium" : ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.barColor; var currVal_2 = _v.context.$implicit.title; var currVal_3 = _v.context.$implicit.titleColor; var currVal_4 = _v.context.$implicit.titleSize; var currVal_5 = (_v.context.$implicit.mainText || "-"); var currVal_6 = _v.context.$implicit.mainTextSize; var currVal_7 = _v.context.$implicit.mainTextColor; var currVal_8 = _v.context.$implicit.secondaryText; var currVal_9 = _v.context.$implicit.secondaryTextSize; var currVal_10 = _v.context.$implicit.secondaryFontWeight; var currVal_11 = _v.context.$implicit.secondaryTextColor; var currVal_12 = _co.size; var currVal_13 = _co.clickable; var currVal_14 = _v.context.$implicit.helpText; _ck(_v, 3, 1, [currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
function View_DistributionComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), i1.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DistributionComponent_2)), i1.ɵdid(5, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.verticalView ? "wcd-flex-vertical" : ""); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.ngIf; _ck(_v, 5, 0, currVal_1); }, null); }
function View_DistributionComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fab-spinner", [["labelPosition", "right"]], null, null, null, i6.View_FabSpinnerComponent_0, i6.RenderType_FabSpinnerComponent)), i1.ɵdid(2, 5816320, null, 0, i7.FabSpinnerComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { size: [0, "size"], label: [1, "label"], labelPosition: [2, "labelPosition"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.small; var currVal_1 = _co.spinnerLabel; var currVal_2 = "right"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_DistributionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DistributionComponent_1)), i1.ɵdid(2, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i3.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_DistributionComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.processedDistributionData$)); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DistributionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-distribution", [], null, null, null, View_DistributionComponent_0, RenderType_DistributionComponent)), i1.ɵdid(1, 49152, null, 0, i8.DistributionComponent, [i9.PrettyNumberService], null, null)], null, null); }
var DistributionComponentNgFactory = i1.ɵccf("wcd-distribution", i8.DistributionComponent, View_DistributionComponent_Host_0, { size: "size", verticalView: "verticalView", spinnerLabel: "spinnerLabel", sameWidth: "sameWidth", clickable: "clickable", distributionData$: "distributionData$" }, { onDistributionComplete: "onDistributionComplete", onClick: "onClick" }, []);
export { DistributionComponentNgFactory as DistributionComponentNgFactory };
