var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
var _a;
import { Paris } from '@microsoft/paris';
import { MachineHealthStatus, MachineRiskScore, MachineTagsApiCall, OperatingSystemPlatform, OperatingSystemPlatformCategories, MachineExposureScore, ReleaseVersion, AntivirusStatus, iotDeviceTypes, networkDeviceTypes, MachineVulnerabilitySeverityLevel, MachineVulnerabilityAgeLevel, MachineExploitLevel, onboardingStatuses, firstSeenValues, exclusionStates, machineManagedByValues, MachineManagedByType, } from '@wcd/domain';
import { combineLatest, of } from 'rxjs';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { RbacService } from '../../../rbac/services/rbac.service';
import { map, take, switchMap } from 'rxjs/operators';
import { clone, compact, keyBy, mapValues, merge, values, pick } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { DeviceCategory } from '../models/machine.model';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppConfigService } from '@wcd/app-config';
var MacinesFilterConfig = (_a = {},
    _a[0 /* MachineValue */] = {
        backendProperty: 'assetValue',
    },
    _a);
var MAX_TAGS_FOR_FILTER = 2000;
var MachinesFiltersService = /** @class */ (function () {
    function MachinesFiltersService(rbacService, featuresService, paris, i18nService, flavorService, appConfigService) {
        var _this = this;
        this.rbacService = rbacService;
        this.featuresService = featuresService;
        this.paris = paris;
        this.i18nService = i18nService;
        this.flavorService = flavorService;
        this.appConfigService = appConfigService;
        this.machineHealthStatusCategoriesMap = MachineHealthStatus.entityConfig.values
            .filter(function (status) { return status.category; })
            .reduce(function (res, status) {
            var category = status.category;
            if (!res[category]) {
                res[category] = {
                    id: category,
                    name: category,
                    values: [],
                    children: [],
                };
            }
            if (status.childOf === category)
                res[category].children.push(status);
            else
                res[category].values.push(status);
            return res;
        }, {});
        var relevantIotDevicesTypes = iotDeviceTypes;
        if (!featuresService.isEnabled(Feature.PetraNdrConvergenceUx)) {
            // TODO(khaw): Remove this line and NetworkDevice from iotDeviceTypes as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
            relevantIotDevicesTypes.unshift(networkDeviceTypes[0]);
        }
        this.deviceTypeCategoriesMap = relevantIotDevicesTypes.reduce(function (res, _a) {
            var i18nNameKey = _a.i18nNameKey, id = _a.id, priority = _a.priority;
            res[id] = {
                id: id,
                name: _this.i18nService.get(i18nNameKey),
                priority: priority,
            };
            return res;
        }, {});
        this.osPlatformCategoriesMap = OperatingSystemPlatform.entityConfig.values.reduce(function (res, osPlatform) {
            var category = osPlatform.category;
            if (([
                OperatingSystemPlatformCategories.Linux,
                OperatingSystemPlatformCategories.macOS,
            ].includes(category) &&
                !_this.featuresService.isEnabled(Feature.XPlatform)) ||
                ([
                    OperatingSystemPlatformCategories.Windows11,
                    OperatingSystemPlatformCategories.WindowsServer2022,
                ].includes(category) &&
                    !_this.featuresService.isEnabled(Feature.NewWindowsVersions))) {
                category = OperatingSystemPlatformCategories.OtherPlatform;
            }
            if (!res[category] && osPlatform.isBackendSupportedFilter)
                res[category] = {
                    id: category,
                    name: OperatingSystemPlatformCategories.OtherPlatform,
                    values: [],
                    priority: Infinity,
                };
            if (category === osPlatform.id)
                merge(res[category], {
                    name: osPlatform.categoryName,
                    priority: osPlatform.priority,
                });
            if (osPlatform.isBackendSupportedFilter) {
                res[category].values.push(osPlatform);
            }
            return res;
        }, {});
        this.defaultMachineFilters = {
            osPlatforms: {
                count: null,
                values: values(this.osPlatformCategoriesMap).map(function (osPlatform) {
                    return {
                        value: osPlatform.id,
                        count: null,
                    };
                }),
            },
            healthStatuses: {
                count: null,
                values: values(this.machineHealthStatusCategoriesMap).map(function (statusCategory) {
                    return {
                        value: statusCategory.id,
                        count: null,
                    };
                }),
            },
            securityPropertiesRequiringAttention: {
                count: null,
                values: AntivirusStatus.entityConfig.values.map(function (status) {
                    if (_this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus) &&
                        status.id == 'AntiVirusNotReporting') {
                        return {
                            value: status.id,
                            name: _this.i18nService.get("reporting.machineReport.antivirusStatus.unknownNew"),
                            count: null,
                        };
                    }
                    else {
                        return {
                            value: status.id,
                            name: status.name,
                            count: null,
                        };
                    }
                }),
            },
        };
        this.defaultMachineFilters.firstseen = {
            count: null,
            values: firstSeenValues.map(function (_a) {
                var i18nNameKey = _a.i18nNameKey, id = _a.id, priority = _a.priority;
                return ({
                    value: id,
                    priority: priority,
                    name: _this.i18nService.get(i18nNameKey),
                    count: null,
                });
            }),
        };
        if ((this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
            this.featuresService.isEnabled(Feature.EndpointConfigManagement)) ||
            this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach)) {
            this.defaultMachineFilters.managedByList = {
                count: null,
                values: machineManagedByValues.map(function (_a) {
                    var nameI18nKey = _a.nameI18nKey, id = _a.id, priority = _a.priority;
                    return ({
                        value: id,
                        priority: priority,
                        name: _this.i18nService.get(nameI18nKey),
                        count: null,
                    });
                }).filter(function (managedBy) {
                    return managedBy.value !== MachineManagedByType.Sccm || _this.featuresService.isEnabled(Feature.EndpointConfigManagementMdeWithSccm);
                }),
            };
        }
        if (this.featuresService.isEnabled(Feature.DevicesListIotV1) ||
            this.featuresService.isEnabled(Feature.DeviceInventoeryDisplayNotManagedDevices)) {
            this.defaultMachineFilters.onBoardingStatuses = {
                count: null,
                values: onboardingStatuses.map(function (_a) {
                    var id = _a.id, i18nNameKey = _a.i18nNameKey;
                    return ({
                        value: id,
                        name: _this.i18nService.get(i18nNameKey),
                        count: null,
                    });
                }),
            };
        }
        if (this.featuresService.isEnabled(Feature.ExcludedDevices)) {
            this.defaultMachineFilters.exclusionStates = {
                count: null,
                values: exclusionStates.map(function (_a) {
                    var id = _a.id, i18nNameKey = _a.i18nNameKey;
                    return ({
                        value: id,
                        name: _this.i18nService.get(i18nNameKey),
                        count: null,
                    });
                }),
            };
        }
        this.iotDeviceTypeFilters = {
            count: null,
            values: relevantIotDevicesTypes.map(function (_a) {
                var i18nNameKey = _a.i18nNameKey, id = _a.id, priority = _a.priority;
                return ({
                    value: id,
                    priority: priority,
                    name: _this.i18nService.get(i18nNameKey),
                    count: null,
                });
            }),
        };
        Object.assign(this.defaultMachineFilters, {
            riskScores: {
                count: null,
                values: MachineRiskScore.entityConfig.values.map(function (riskScore) {
                    return {
                        value: riskScore.id,
                        count: null,
                        priority: riskScore.priority,
                    };
                }),
            },
        });
        Object.assign(this.defaultMachineFilters, {
            vulnerabilitySeverityLevels: {
                count: null,
                values: MachineVulnerabilitySeverityLevel.entityConfig.values.map(function (vulnerabilitySeverityLevel) {
                    return {
                        value: vulnerabilitySeverityLevel.id,
                        count: null,
                        priority: vulnerabilitySeverityLevel.priority,
                    };
                }),
            },
        });
        Object.assign(this.defaultMachineFilters, {
            vulnerabilityAgeLevels: {
                count: null,
                values: MachineVulnerabilityAgeLevel.entityConfig.values.map(function (vulnerabilityAgeLevel) {
                    return {
                        value: vulnerabilityAgeLevel.id,
                        count: null,
                        priority: vulnerabilityAgeLevel.priority,
                    };
                }),
            },
        });
        Object.assign(this.defaultMachineFilters, {
            exploitLevels: {
                count: null,
                values: MachineExploitLevel.entityConfig.values.map(function (exploitLevel) {
                    return {
                        value: exploitLevel.id,
                        count: null,
                        priority: exploitLevel.priority,
                    };
                }),
            },
        });
        Object.assign(this.defaultMachineFilters, {
            exposureScores: {
                count: null,
                values: MachineExposureScore.entityConfig.values.map(function (exposureScore) {
                    return {
                        value: exposureScore.id,
                        count: null,
                        priority: exposureScore.priority,
                    };
                }),
            },
        });
        Object.assign(this.defaultMachineFilters, {
            releaseVersion: {
                count: null,
                values: ReleaseVersion.entityConfig.values.map(function (releaseVersion) {
                    return {
                        value: releaseVersion.name,
                        count: null,
                        priority: releaseVersion.priority,
                    };
                }),
            },
        });
    }
    MachinesFiltersService.prototype.clearMachineTagsApiCall = function () {
        this.paris.clearApiCallCache(MachineTagsApiCall);
    };
    MachinesFiltersService.prototype.getMachinesFiltersWithPseudoTags = function (options, pseudoTags) {
        return this.getMachinesFilters(options).pipe(switchMap(function (filters) {
            var clonedFilters = clone(filters);
            var tagsFilter = clonedFilters['tags'];
            if (tagsFilter) {
                pseudoTags.forEach(function (pt) {
                    tagsFilter.count++;
                    tagsFilter.values.splice(0, 0, {
                        value: pt.name,
                        name: pt.name,
                        custom: {
                            filterType: 0 /* MachineValue */,
                            filterData: pt,
                        },
                    });
                });
            }
            return of(clonedFilters);
        }));
    };
    MachinesFiltersService.prototype.getMachinesFilters = function (options) {
        var _this = this;
        return combineLatest(this.getUserExposedRbacGroups(), this.paris.apiCall(MachineTagsApiCall, {
            migrateToVNext: this.featuresService.isEnabled(Feature.MachinesControllerMigrationGetTags),
        }), function (rbacGroups, machineTags) {
            _this._machineFilters = _this.getDefaultMachineFilters(options, !_this.appConfigService.magellanOptOut);
            _this.addRbacGroupsFilter(_this._machineFilters, rbacGroups);
            /*
             * If there are "too many tags" we don't want to show tags filter as it hurts
             * the entire filter panel rendering and it's not usable anyway
             */
            var allMachineTags = machineTags ? machineTags.allTags : [];
            if (allMachineTags.length < MAX_TAGS_FOR_FILTER) {
                _this.addTagsFilter(_this._machineFilters, machineTags);
            }
            return _this._machineFilters;
        }).pipe(take(1));
    };
    MachinesFiltersService.prototype.addRbacGroupsFilter = function (filters, rbacGroups) {
        rbacGroups = rbacGroups ? compact(rbacGroups) : [];
        Object.assign(filters, {
            rbacGroupIds: {
                count: rbacGroups.length,
                values: rbacGroups,
            },
        });
    };
    MachinesFiltersService.prototype.addTagsFilter = function (filters, machineTags) {
        var tagFilterValuesIndex = mapValues(keyBy(machineTags.userDefinedTags, function (tag) { return tag.toLowerCase(); }), function (tag) { return ({
            value: tag,
            name: tag,
            custom: { isUserDefined: true },
        }); });
        machineTags.builtInTags.forEach(function (builtInTag) {
            var tagKey = builtInTag.toLowerCase();
            var existingTag = tagFilterValuesIndex[tagKey];
            if (existingTag)
                existingTag.custom.isBuiltIn = true;
            else
                tagFilterValuesIndex[tagKey] = {
                    value: builtInTag,
                    name: builtInTag,
                    custom: { isBuiltIn: true },
                };
        });
        var sortedAllTags = values(tagFilterValuesIndex);
        filters['tags'] = {
            count: sortedAllTags.length + 1,
            values: [{ value: null, name: null, priority: 0 }].concat(sortedAllTags),
        };
    };
    MachinesFiltersService.prototype.getDefaultMachineFilters = function (options, displayNotManagedDevicesFilters) {
        var filters = this.isIotOrNetworkCategory(options)
            ? this.getIotOrNetworkMachinesFilters(options)
            : this.defaultMachineFilters;
        var managedByList = filters.managedByList, onBoardingStatuses = filters.onBoardingStatuses, exclusionStates = filters.exclusionStates, fixedFilters = __rest(filters, ["managedByList", "onBoardingStatuses", "exclusionStates"]);
        var defaultFilters = fixedFilters;
        var isEndpointManagementEnabled = this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
            this.featuresService.isEnabled(Feature.EndpointConfigManagement);
        var isSmbEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach);
        if ((isEndpointManagementEnabled || isSmbEnabled) && managedByList) {
            defaultFilters = __assign({ managedByList: managedByList }, defaultFilters);
        }
        if (displayNotManagedDevicesFilters && onBoardingStatuses) {
            defaultFilters = __assign({ onBoardingStatuses: onBoardingStatuses }, defaultFilters);
        }
        if (exclusionStates) {
            defaultFilters = __assign({ exclusionStates: exclusionStates }, defaultFilters);
        }
        return defaultFilters;
    };
    MachinesFiltersService.prototype.getIotOrNetworkMachinesFilters = function (options) {
        var _a = this.defaultMachineFilters, riskScores = _a.riskScores, vulnerabilitySeverityLevels = _a.vulnerabilitySeverityLevels, vulnerabilityAgeLevels = _a.vulnerabilityAgeLevels, exploitLevels = _a.exploitLevels, exposureScores = _a.exposureScores, firstseen = _a.firstseen, exclusionStates = _a.exclusionStates;
        var iotFilters = {
            deviceTypes: this.isIot(options) ? this.iotDeviceTypeFilters : null,
            riskScores: riskScores,
            vulnerabilitySeverityLevels: vulnerabilitySeverityLevels,
            vulnerabilityAgeLevels: vulnerabilityAgeLevels,
            exploitLevels: exploitLevels,
            exposureScores: exposureScores,
            firstseen: firstseen,
            exclusionStates: exclusionStates,
        };
        return iotFilters;
    };
    MachinesFiltersService.prototype.isIot = function (options) {
        return options && options.mainCategory && options.mainCategory === DeviceCategory.IoT;
    };
    MachinesFiltersService.prototype.isIotOrNetworkCategory = function (options) {
        return (options &&
            options.mainCategory &&
            (options.mainCategory === DeviceCategory.IoT ||
                options.mainCategory === DeviceCategory.NetworkDevice));
    };
    MachinesFiltersService.prototype.searchFilterValues = function (field, term, options) {
        if (field.id === 'rbacGroupIds') {
            return this.searchMachineGroupFilterValues(term, options);
        }
        return Promise.resolve([]);
    };
    MachinesFiltersService.prototype.searchMachineGroupFilterValues = function (term, options) {
        term = term.toLowerCase();
        return this.getUserExposedRbacGroups()
            .pipe(take(1), map(function (filterValues) {
            return filterValues.filter(function (v) { return v.name && v.name.toLowerCase().includes(term); });
        }))
            .toPromise();
    };
    MachinesFiltersService.prototype.clearMachinesFilters = function () {
        this._machineFilters = null;
    };
    MachinesFiltersService.prototype.getUserExposedRbacGroups = function () {
        return this.rbacService.userExposedRbacGroups$.pipe(map(function (data) {
            return data &&
                data
                    .filter(function (item) { return item.id && item.name; })
                    .map(function (item) {
                    return {
                        value: item.id,
                        name: item.name,
                        count: null,
                    };
                });
        }));
    };
    MachinesFiltersService.prototype.serializeFilterValues = function (filterSelection, listValues) {
        var _a;
        if (!filterSelection || !filterSelection.length)
            return null;
        var selectedValuesIndex = pick(keyBy(listValues, function (listValue) { return listValue.id; }), filterSelection);
        var selectedValues = compact(filterSelection).map(function (tag) { return selectedValuesIndex[tag]; });
        var machineGroups = [];
        var userTags = [];
        var machineValues = [];
        if (selectedValues.length) {
            selectedValues.forEach(function (value) {
                if (value.data) {
                    if (value.data.isBuiltIn)
                        machineGroups.push(value.id);
                    if (value.data.isUserDefined)
                        userTags.push(value.id);
                    if (value.data.filterType === 0 /* MachineValue */) {
                        machineValues.push(value.data.filterData.id);
                    }
                }
            });
        }
        return _a = {
                machineGroups: machineGroups.length ? machineGroups : null,
                machineTags: userTags.length ? userTags : null,
                noTags: filterSelection.includes(null) ? 'true' : null
            },
            _a[MacinesFilterConfig[0 /* MachineValue */].backendProperty] = machineValues.length
                ? machineValues
                : null,
            _a;
    };
    MachinesFiltersService.prototype.deserializeFilterValues = function (serializedValues) {
        var deserialized = [];
        if (serializedValues['noTags'])
            deserialized.push(null);
        var userTags = serializedValues['machineTags'];
        var machineGroups = serializedValues['machineGroups'];
        deserialized = deserialized.concat(userTags ? userTags : [], machineGroups ? machineGroups : [], this.deserializePseudoTags(serializedValues));
        return deserialized;
    };
    MachinesFiltersService.prototype.deserializePseudoTags = function (serializedValues) {
        var _this = this;
        var machineValues = (serializedValues[MacinesFilterConfig[0 /* MachineValue */].backendProperty]);
        return machineValues
            ? machineValues.map(function (mv) {
                return _this.i18nService.get('machines_entityDetails_actions_machineValue_title') + ": " + mv;
            })
            : [];
    };
    return MachinesFiltersService;
}());
export { MachinesFiltersService };
