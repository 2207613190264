
		<div *ngIf="shouldShowRange()" [ngClass]="'wcd-simple-pagination ' +  className" role="navigation"
			[attr.aria-label]="i18nService.strings.simplePagination_navigation">
			<span
				*ngIf="(showCount && !shouldShowPageNumbers); else pageNumbers"
				class="wcd-simple-pagination__position"
			>
				{{ firstIndex }}-{{ lastIndex }}
				<span *ngIf="total">
					<ng-container i18n>{{ i18nService.strings.simplePagination_of }} </ng-container>
					<span data-test-id="paging-total">{{ total }}</span>
				</span>
			</span>
			<ng-template #pageNumbers>
				<span class="wcd-simple-pagination__position">
					<ng-container i18n>{{ i18nService.strings.page }} </ng-container>
					{{ page }}
				</span>
			</ng-template>

			<button
				*ngIf="!(pageCount && pageCount <= 1)"
				[attr.aria-label]="calculatePagesNavigationAriaLabel('simplePagination_previous_page', ariaPagePrevIndex)"
				class="wcd-simple-pagination wcd-margin-xxsmall-right"
				[disabled]="page === 1"
				(click)="prev()"
				[wcdTooltip]="i18nService.strings.simplePagination_previous_page"
				data-track-id="SimplePagination__Previous"
				data-track-type="Button"
			>
				<fab-icon [iconName]="'ChevronLeft'" contentClass="wcd-simple-pagination-icon"></fab-icon>
			</button>

			<button
				*ngIf="!(pageCount && pageCount <= 1)"
				[attr.aria-label]="calculatePagesNavigationAriaLabel('simplePagination_next_page', ariaPageNextIndex)"
				class="wcd-simple-pagination"
				[disabled]="isLastPage"
				(click)="next()"
				[wcdTooltip]="i18nService.strings.simplePagination_next_page"
				data-track-id="SimplePagination__Next"
				data-track-type="Button"
			>
				<fab-icon [iconName]="'ChevronRight'" contentClass="wcd-simple-pagination-icon"></fab-icon>
			</button>
		</div>
	