import { Component } from '@angular/core';
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { Machine, machineHealthStatuses } from '@wcd/domain';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

@Component({
	template: `
		<div class="relative wcd-flex-vertical" [attr.aria-label]="description">
			<div class="wcd-flex-horizontal">
				<ng-container *ngIf="hasMdeLicenseWorkloads && entity.senseMachineId && entity.riskScore">
					<div class="wcd-flex-horizontal wcd-padding-medium-right machine-risk">
						<span
							class="wcd-severity"
							[ngClass]="'wcd-severity-' + entity.riskScore.id.toLocaleLowerCase()"
						>
							{{ 'machineRiskScore.' + entity.riskScore.id | i18n }}
						</span>
					</div>
				</ng-container>
				<ng-container *ngIf="isActive && hasStatus">
					<div
						class="machine-status-active"
						[attr.aria-label]="statusDescription"
						[wcdTooltip]="statusDescription"
					></div>
					<span class="machine-status">{{ statusDescription }}</span>
				</ng-container>
			</div>
		</div>
	`,
	styleUrls: ['./machine.header-bottom.component.scss'],
})
export class MachineHeaderBottomComponent extends EntityHeaderBottomComponentBase<Machine> {
	hasStatus: boolean;
	isActive: boolean;
	description: string;
	statusDescription: string;
	hasMdeLicenseWorkloads: boolean;

	constractur(flavorService: FlavorService) {
		this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
	}

	setEntity(entity) {
		super.setEntity(entity);
		const status = this.entity.status;
		this.hasStatus = !!status;

		this.isActive = status && status.id && status.id === machineHealthStatuses.Active.id;

		this.description =
			status &&
			this.i18nService.get('machines.entityDetails.bottomHeader.tooltip', {
				exposure:
					this.i18nService.strings['machine_details_exposureScore_' + entity.exposureScore.id] ||
					this.entity.exposureScore.name,
				status:
					this.i18nService.strings['machine_details_status_' + this.entity.status.id + '_name'] ||
					this.entity.status.name,
			});

		if (this.hasStatus) {
			this.statusDescription =
				this.i18nService.strings['machine_details_status_' + this.entity.status.id + '_name'] ||
				this.entity.status.name;
		}
	}
}
