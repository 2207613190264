var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { File } from '@wcd/domain';
import { MessageBarType } from 'office-ui-fabric-react';
var FileCollectSamplePanelComponent = /** @class */ (function (_super) {
    __extends(FileCollectSamplePanelComponent, _super);
    function FileCollectSamplePanelComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.MessageBarType = MessageBarType;
        _this.onCollectSample = new EventEmitter();
        _this.onDestroyPanel = new EventEmitter();
        _this.collectPressed = false;
        return _this;
    }
    FileCollectSamplePanelComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.onDestroyPanel.emit(this.collectPressed);
    };
    FileCollectSamplePanelComponent.prototype.isValid = function () {
        return !!this.sampleCollectionReason;
    };
    FileCollectSamplePanelComponent.prototype.collectSample = function (event) {
        event.preventDefault();
        this.onCollectSample.emit();
        this.collectPressed = true;
        this.closePanel();
    };
    return FileCollectSamplePanelComponent;
}(PanelContainer));
export { FileCollectSamplePanelComponent };
