import { ChangeDetectionStrategy, Component } from '@angular/core';
import { combineLatest, concat, Observable, of, Subject, throwError } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { File } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { ReportWidgetConfig } from '../../../../../reports/models/report-widget.model';
import { I18nService } from '@wcd/i18n';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<file-prevalence [file]="data$ | async" (error)="onError($event)" supportNewLayout="true" ></file-prevalence>
	`,
})
export class FilePrevalenceWidgetComponent extends ReportWidgetComponent<File> {
	private errorSubject$ = new Subject();
	constructor(
		reportsService: ReportsService,
		private readonly activatedEntity: ActivatedEntity,
		private I18nService: I18nService
	) {
		super(reportsService);
	}

	get widgetConfig(): ReportWidgetConfig<File> {
		return {
			id: 'filePrevalence',
			name: this.I18nService.get('file.prevalence'),
			loadData: () =>
				combineLatest(
					this.activatedEntity.currentEntity$.pipe(
						filter(currentEntity => currentEntity instanceof File)
					),
					this.errorSubject$.asObservable().pipe(startWith(undefined))
				).pipe(
					switchMap(([file, error]) => {
						if (error) {
							return concat(of(null), throwError(error));
						}
						return of(file);
					})
				) as Observable<File>,
		};
	}

	onError(err: Error) {
		this.errorSubject$.next(err);
	}
}
