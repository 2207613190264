import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Get query ID by custom detection',
	endpoint: 'hunting/rules/queryId',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (alert: Alert) => ({ params: { alertId: alert.id } }),
})
export class ScheduledHuntingByAlertApiCall extends ApiCallModel<string, Alert> {}
