<ng-container *ngIf="quarantineActions$ | async as quarantineActions; else loading">
	<section *ngIf="quarantineActions.request || quarantineActions.cancel">
		<h3>{{ 'file.quarantine.title' | i18n }}</h3>
		<dl class="wcd-margin-bottom" role="none">
			<dt>{{'file.quarantine.submissionTime' | i18n}}</dt>
			<dd>
				<time [attr.datetime]="quarantineActions.request.time.toISOString()">
					{{ quarantineActions.request.time | date: 'medium' }}
				</time>
			</dd>
			<ng-container *ngFor="let status of quarantineActions.statuses">
				<dt>{{status.id}}</dt>
				<dd>
					<fab-icon iconName="System"></fab-icon>
					{{ status.count }}
				</dd>
			</ng-container>
		</dl>
		<ng-container *ngIf="quarantineActions.isCancelable">
			<fab-default-button
				data-track-id="CancelQuarantineFile"
				[iconProps]="{ iconName: 'Cancel' }"
				data-track-type="Button"
				[text]="'file.quarantine.cancel.title' | i18n"
				(onClick)="cancelQuarantineAction()">
			</fab-default-button>
			<p>
				<small>
					<fab-icon iconName="Info"></fab-icon>
					{{ 'file.actionCenter.actionWarning' | i18n}}
				</small>
			</p>
		</ng-container>
		<div *ngIf="quarantineActions.request" class="wcd-margin-small-top">
			<fab-action-button [text]="'export' | i18n"
							   [iconProps]="{ iconName: 'ExcelLogo', className: 'ms-color-green' }"
							   (onClick)="exportQuarantineActionsResults()"></fab-action-button>
		</div>
		<section>
			<ng-container *ngFor="let commentType of ['cancel', 'request']">
				<ng-container *ngIf="quarantineActions[commentType]">
					<ng-container *ngTemplateOutlet="quarantineAction;context:{ action: quarantineActions[commentType], type: commentType }"></ng-container>
				</ng-container>
			</ng-container>
		</section>
	</section>
</ng-container>
<ng-template #quarantineAction let-action="action" let-type="type">
	<article class="wcd-flex-horizontal">
		<div class="wcd-flex-none wcd-padding-small-right">
			<fab-icon iconName="Clock"></fab-icon>
		</div>
		<div class="wcd-flex-1">
			<h4>{{ ('file.quarantine.comment.' + type) | i18n }}</h4>
			<time [attr.datetime]="action.time.toISOString()">{{ action.time | date: 'medium' }}</time>
			<address>{{action.user}}</address>
			<blockquote>{{action.comment}}</blockquote>
		</div>
	</article>
</ng-template>
<ng-template #loading>
	<fab-spinner [label]="'file.quarantine.loading' | i18n"></fab-spinner>
</ng-template>
