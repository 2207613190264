import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnInit,
	Output,
} from '@angular/core';
import { CommentModel } from '../../../comments/models/comment.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationActionsService } from '../../investigation_actions/services/investigation-actions.service';
import { CommentsStore } from '../../../comments/services/comments.store';
import { Disableable, DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { InvestigationGraphDataModel } from '../../../graph/airs-investigation/models/investigation-graph-data.model';
import { Investigation } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'investigation-comments',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './investigation-comments.component.html',
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => InvestigationCommentsComponent),
		},
	],
})
export class InvestigationCommentsComponent implements OnInit, Disableable {
	@Input() readonly: boolean = false;
	@Input() investigationGraphData: InvestigationGraphDataModel;
	@Input() investigation: Investigation;
	@Input() panelId: string;
	@Output() select: EventEmitter<CommentModel> = new EventEmitter<CommentModel>();

	commentsOptions: { investigation: number | string };
	isInit: boolean = false;
	isSavingComment: boolean = false;
	getLinkTitle = () => this.i18nService.strings.investigation_comments_alertLink;

	constructor(
		private changeDetectionRef: ChangeDetectorRef,
		private dialogsService: DialogsService,
		public investigationActionsService: InvestigationActionsService,
		private commentsStore: CommentsStore,
		public i18nService: I18nService
	) {}

	ngOnInit() {
		this.commentsOptions = { investigation: this.investigation.id };

		if (!this.isInit) {
			this.isInit = true;
		}
	}

	showLink(comment: CommentModel) {
		return comment.linkedObjects['investigation_action'];
	}

	saveNewComment(commentText: string): void {
		this.isSavingComment = true;
		this.changeDetectionRef.markForCheck();
		const comment: CommentModel = this.commentsStore.createComment(commentText, {
			investigation: this.investigation.id,
		});
		const focusableQuery = `#${this.panelId} .side-panel div`;
		this.commentsStore.save(comment, null, true, focusableQuery).then(
			() => {
				this.investigationGraphData.commentCount$.next(this.investigation.commentCount + 1);
				this.isSavingComment = false;
				this.investigationGraphData.refresh();
				this.changeDetectionRef.markForCheck();
			},
			error => {
				this.isSavingComment = false;
				this.dialogsService.showError({
					title: error.message || error,
					message: this.i18nService.strings.comments_save_error,
					data: error.data,
				});
				this.changeDetectionRef.markForCheck();
			}
		);
	}
}
