import { SevilleModule } from './app/seville/seville.module';
import { HybridRoutingService } from '../hybrid-routing.service';

SevilleModule.directive('routerLink', routerLinkDirective);

routerLinkDirective.$inject = ['ng2router'];

function routerLinkDirective(ng2router: HybridRoutingService) {
	return {
		restrict: 'A',
		scope: {
			routerLink: '=',
		},
		link: function(scope, element, attrs) {
			element[0].addEventListener('click', e => {
				e.preventDefault();

				ng2router.navigate(scope.routerLink);
			});
		},
	};
}
