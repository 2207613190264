
		<div class="wcd-full-height wcd-flex-vertical">
			<div
				class="wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal
				wcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens"
			>
				<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
			</div>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	