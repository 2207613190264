import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { machineStatusValues } from './machine-status.entity.values';

@Entity({
	singularName: 'Device Status',
	pluralName: 'Device Status',
	values: machineStatusValues,
})
export class MachineStatus extends StatusModel {
	@EntityField({ defaultValue: false })
	isRunning: boolean;
	@EntityField({ defaultValue: false })
	allowCancelInvestigation: boolean;
}
