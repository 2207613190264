
		<div>
			<span
				class="wcd-severity wcd-margin-large-right"
				[ngClass]="'wcd-severity-' + alert.severity.type"
				role="presentation"
				wcdTooltip="{{ 'alerts.severity' | i18n }}: {{ alert.severity.nameI18nKey | i18n }}"
			>
				{{ alert.severity.nameI18nKey | i18n }}
			</span>
			<strong
				role="presentation"
				wcdTooltip="{{ 'alerts.status.title' | i18n }}: {{ alert.status.name }}"
				>{{ alert.status.nameI18nKey | i18n }}</strong
			>
		</div>
	