var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Observable } from 'rxjs';
import { EventEmitter, OnInit, } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { ChecklistDropdownComponent, Positions } from '@wcd/forms';
import { FabricIconNames } from '@wcd/scc-common';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var DataviewActionColumnsCustomizationComponent = /** @class */ (function () {
    function DataviewActionColumnsCustomizationComponent(i18nService, liveAnnouncer) {
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.smallScreenView = false;
        this.openMenuPosition = Positions.Default;
        this.toggleColumnCustomizationWizardState = new EventEmitter();
        this.icon = FabricIconNames.ColumnOptions;
    }
    DataviewActionColumnsCustomizationComponent.prototype.buttonElementPicker = function () {
        return this.checkListDropdown.dropdown.buttonEl;
    };
    DataviewActionColumnsCustomizationComponent.prototype.toggleDropdownState = function (isOpen) {
        this.toggleColumnCustomizationWizardState.emit(isOpen);
    };
    DataviewActionColumnsCustomizationComponent.prototype.ngOnInit = function () {
        Object.assign(this, __assign({}, this.columnsCustomizationActionConfig));
    };
    DataviewActionColumnsCustomizationComponent.prototype.resetVisibleFieldsAndFocus = function () {
        var _this = this;
        this.resetVisibleFields();
        this.liveAnnouncer
            .announce(this.i18nService.get('common.resetColumnsComplete'), 'assertive')
            .then(function () {
            setTimeout(function () {
                _this.checkListDropdown.setFocusOnAllOrNone();
            });
        });
    };
    return DataviewActionColumnsCustomizationComponent;
}());
export { DataviewActionColumnsCustomizationComponent };
