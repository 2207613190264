var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { FeaturesService, Feature } from '@wcd/config';
import { RecommendationExceptionStateValue, ExceptionJustification, RemediationType, RecommendationException, RecommendationExceptionAggregated, MdeUserRoleActionEnum, ExceptionType, } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FabricIconNames } from '@wcd/scc-common';
import { Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { of, Subject } from 'rxjs';
import { flatMap, map, take } from 'rxjs/operators';
import { I18nService } from '../../../../../../../../../projects/i18n/src/public_api';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { ItemActionModel } from '../../../../../dataviews/models/item-action.model';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { TvmMachineGroupsFilterService } from '../../../../../tvm/services/tvm-machine-groups-filter.service';
var RecommendationExceptionService = /** @class */ (function () {
    function RecommendationExceptionService(i18nService, authService, appConfigService, dialogsService, entityPanelsService, tvmMachineGroupsFilterService, paris, featuresService, rbacService) {
        this.i18nService = i18nService;
        this.authService = authService;
        this.appConfigService = appConfigService;
        this.dialogsService = dialogsService;
        this.entityPanelsService = entityPanelsService;
        this.tvmMachineGroupsFilterService = tvmMachineGroupsFilterService;
        this.paris = paris;
        this.isExceptionsPerRbacFeatureEnabled = false;
        this.exceptionCancelled$ = new Subject();
        // TODO: update those values when supporting remediation of those types. VSTS 23421057
        this.remediationTypesToIgnoreInFilter = [RemediationType.AttentionRequired];
        this.getActions = this.getActions.bind(this);
        this.isExceptionsPerRbacFeatureEnabled =
            appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
        this.isCancelAllSelectedRemediationExceptionRbacsEnabled = featuresService.isEnabled(Feature.TvmCancelAllSelectedRemediationExceptionRbacs);
        this.rbacGroupIdToNameMap$ = rbacService.userExposedRbacGroups$.pipe(map(function (rbacGroups) {
            var machineGroupNameById = rbacGroups.reduce(function (map, currMachineGroup) {
                map[currMachineGroup.id] = currMachineGroup.name;
                return map;
            }, {});
            return machineGroupNameById;
        }));
    }
    RecommendationExceptionService.prototype.getRbacGroupName = function (recommendationException) {
        var _this = this;
        return this.rbacGroupIdToNameMap$.pipe(map(function (mapping) {
            return mapping[recommendationException.rbacGroupId] || _this.i18nService.get('notAvailable.short');
        }), take(1));
    };
    RecommendationExceptionService.prototype.openRelatedExceptionsPanel = function (recommendationException) {
        var _this = this;
        this.getRbacGroupName(recommendationException).subscribe(function (rbacGroupName) {
            var title = recommendationException.title + " (" + rbacGroupName + ")";
            var recommendationExceptionCopy = __assign({}, recommendationException, { title: title });
            _this.entityPanelsService.showEntity(RecommendationException, recommendationExceptionCopy, null, {
                back: {
                    onClick: function () { return _this.entityPanelsService.closeEntityPanel(RecommendationException); },
                },
            });
        });
    };
    RecommendationExceptionService.prototype.getActions = function (recommendationExceptions) {
        var _this = this;
        if (recommendationExceptions.length !== 1) {
            return null;
        }
        var first = recommendationExceptions[0];
        var nameKey;
        var status;
        var userAllowedToEditExceptions = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmExceptionHandling);
        var entityType;
        if (first instanceof RecommendationExceptionAggregated) {
            var recommendationExceptionAggregated = first;
            if (!this.isCancelAllSelectedRemediationExceptionRbacsEnabled && !recommendationExceptionAggregated.isGlobalException) {
                return null;
            }
            status = recommendationExceptionAggregated.primaryStatus;
            nameKey = 'tvm.recommendationException.actions.cancelExceptionForAllDeviceGroups';
            if (recommendationExceptionAggregated.isGlobalException) {
                entityType = RecommendationException;
                userAllowedToEditExceptions = userAllowedToEditExceptions && this.authService.isGlobalAdmin;
            }
            else {
                entityType = RecommendationExceptionAggregated;
            }
        }
        else {
            entityType = RecommendationException;
            nameKey = 'tvm.recommendationException.actions.cancelException';
            status = first.status.value;
        }
        var isValidStatus = status === RecommendationExceptionStateValue.Active ||
            status === RecommendationExceptionStateValue.Suspended;
        var tooltip;
        if (isValidStatus) {
            if (userAllowedToEditExceptions) {
                tooltip = !this.appConfigService.isExposedToAllMachineGroups
                    ? this.i18nService.strings.tvm_recommendationException_notExposedToAllRbac
                    : undefined;
            }
            else {
                tooltip = this.isExceptionsPerRbacFeatureEnabled
                    ? this.i18nService.strings.tvm_recommendationException_invalidRbacGlobal
                    : this.i18nService.strings.tvm_recommendationException_invalidRbac;
            }
        }
        else {
            tooltip = this.i18nService.strings.tvm_recommendationException_canceledToolTip;
        }
        return [
            {
                id: 'cancelException',
                nameKey: nameKey,
                icon: FabricIconNames.ReceiptUndelivered,
                closeOnAction: true,
                disabled: !(this.isExceptionsPerRbacFeatureEnabled ||
                    this.appConfigService.isExposedToAllMachineGroups) ||
                    !userAllowedToEditExceptions ||
                    !isValidStatus,
                tooltip: tooltip,
                method: function (recommendationExceptions) {
                    var updatedRecommendationException = {
                        id: recommendationExceptions[0].id,
                        status: {
                            value: RecommendationExceptionStateValue.Cancelled,
                        },
                    };
                    var repo = _this.paris.getRepository(entityType);
                    var selectedRbacs$;
                    if (_this.isCancelAllSelectedRemediationExceptionRbacsEnabled) {
                        selectedRbacs$ = _this.tvmMachineGroupsFilterService.machineGroupsFilter$
                            .pipe(take(1), map(function (mgFilter) { return mgFilter.machineGroups; }), map(function (mgArr) { return mgArr.filter(function (mg) { return mg.isSelected; }).map(function (mg) { return mg.groupId; }); }))
                            .pipe(flatMap(function (mg) {
                            return repo.save(updatedRecommendationException, mg.length > 0
                                ? { params: { rbacGroups: "groups in (" + mg.join() + ")" } }
                                : undefined);
                        }));
                    }
                    else {
                        selectedRbacs$ = repo.save(updatedRecommendationException);
                    }
                    return selectedRbacs$
                        .toPromise()
                        .then(function () {
                        return _this.dialogsService.showSnackbar({
                            text: _this.i18nService.get('tvm.recommendationException.pleaseWaitCancelled'),
                        });
                    })
                        .then(function () { return _this.exceptionCancelled$.next(); });
                },
                localRefreshOnResolve: true,
            },
        ].map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
    };
    RecommendationExceptionService.prototype.getJustificationLabel = function (justification) {
        var justificationId = justification === ExceptionJustification.AcceptableRisk ? 'RiskAccepted' : justification;
        return this.i18nService.get("tvm.recommendationException.justification." + justificationId);
    };
    RecommendationExceptionService.prototype.getRelatedRecommendationId = function (recommendationException, forNavigation) {
        if (forNavigation === void 0) { forNavigation = true; }
        return recommendationException.exceptionArgs.type === ExceptionType.ConfigurationChange
            ? "" + (forNavigation ? 'sca-_-' : '') + recommendationException.exceptionArgs.scaRecommendationArgs.scid
            : "" + (forNavigation ? 'va-_-' : '') + recommendationException.exceptionArgs.vaRecommendationArgs.productId;
    };
    RecommendationExceptionService.prototype.getCreationJustificationList = function () {
        return [
            ExceptionJustification.ThirdPartyControl,
            ExceptionJustification.AlternateMitigation,
            ExceptionJustification.AcceptableRisk,
            ExceptionJustification.PlannedRemediationPending,
        ];
    };
    RecommendationExceptionService.prototype.getFilterJustificationList = function () {
        return this.getCreationJustificationList();
    };
    RecommendationExceptionService.prototype.getRecommendationExceptionFilters = function () {
        var _this = this;
        if (!this._recommendationExceptionFilters) {
            this._recommendationExceptionFilters = {
                exceptionType: {
                    count: null,
                    values: Object.keys(RemediationType)
                        .filter(function (type) { return _this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0; })
                        .map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.remediationTask.type." + RemediationType[type]),
                            count: null,
                        };
                    }),
                },
                status: {
                    count: null,
                    values: Object.keys(RecommendationExceptionStateValue).map(function (type) {
                        return {
                            value: type,
                            name: _this.i18nService.get("tvm.recommendationException.status." + RecommendationExceptionStateValue[type]),
                            count: null,
                        };
                    }),
                },
                exceptionJustification: {
                    count: null,
                    values: this.getFilterJustificationList().map(function (type) {
                        return {
                            value: type,
                            name: _this.getJustificationLabel(type),
                            count: null,
                        };
                    }),
                },
            };
        }
        return of(Object.keys(this._recommendationExceptionFilters).reduce(function (res, key) {
            var _a;
            return Object.assign(res, (_a = {}, _a[key] = _this._recommendationExceptionFilters[key], _a));
        }, {}));
    };
    return RecommendationExceptionService;
}());
export { RecommendationExceptionService };
