import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.preferences.retention', onboardingRetentionController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.retention', {
			url: '/retention',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Set up your preferences</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position">
                        <div class="page-header-title onboarding-title-margin">Select the data retention policy</div>
                        <div>
                            This will determine the period of time we retain your data in your cloud instance.
                            <br/>
                            Note this does not refer to expiration or cancellation of your Microsoft Defender for Endpoint contract.
                            <br/>
                            For more information, see the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733763&amp;clcid=0x409" target="_blank">Data storage and privacy</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                        </div>
                        <br />
                        <div class="btn-group onboarding-dropdown-custom onboarding-dropdown-margin-top" style="text-align: right" uib-dropdown>
                            <button class="onboarding-dropdown" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle>
                                {{retention.retentionPolicy}}
                                <span class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                            </button>
                            <ul class="dropdown-menu-shadow dropdown-menu onboarding-dropdown-custom" role="menu">
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'30 days'"><a href="#">30 days</a></li>
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'60 days'"><a href="#">60 days</a></li>
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'90 days'"><a href="#">90 days</a></li>
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'120 days'"><a href="#">120 days</a></li>
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'150 days'"><a href="#">150 days</a></li>
                                <li ng-model="retention.retentionPolicy" uib-btn-radio="'180 days'"><a href="#">180 days</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="onboarding-navigation">
                        <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-icon onboarding-paging-icon-back-margin" ng-click="retention.MoveBack()"></i>
                        Back
                    </div>
                    <div class="onboarding-navigation onboarding-paging-icon-margin">
                        Next
                        <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin" ng-click="retention.MoveNext()"></i>
                    </div>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.retention',
					controllerAs: 'retention',
				},
			},
		});
	},
]);

onboardingRetentionController.$inject = ['$scope', '$state'];

function onboardingRetentionController($scope, $state) {
	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;

		var retentionPolicyDays = {
			'30 days': 30,
			'60 days': 60,
			'90 days': 90,
			'120 days': 120,
			'150 days': 150,
			'180 days': 180,
		};

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPreferencesInProgress = true;
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
		});

		vm.retentionPolicy = $scope.$parent.retentionPolicy.toString() + ' days';

		$scope.$parent.stepPreferencesInProgress = true;

		vm.MoveBack = function() {
			($scope.$parent.retentionPolicy = retentionPolicyDays[vm.retentionPolicy]),
				$state.go('onboarding.geo');
		};

		vm.MoveNext = function() {
			($scope.$parent.retentionPolicy = retentionPolicyDays[vm.retentionPolicy]),
				$state.go('onboarding.orgsize');
		};
	}
}
