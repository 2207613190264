
		<h2 [attr.aria-aria-level]="headerLevel" *ngIf="reportRow.title" class="dashboard-row-title">{{ reportRow.title }}</h2>
		<div class="dashboard-columns">
			<report-column
				*ngFor="let reportColumn of reportRow.columns"
				#column
				[reportColumn]="reportColumn"
				[dataOptions]="dataOptions"
				[params]="params"
				[style.width]="reportColumn.width ? reportColumn.width + 'px' : null"
				[ngClass]="
					'wcd-flex-' +
					(reportColumn.width ? 'none' : reportColumn.weight) +
					(reportColumn.className ? ' ' + reportColumn.className : '')
				"
				class="dashboard-column fit-to-row"
				[headerLevel]="++headerLevel"
			>
			</report-column>
		</div>
		<report-widget
			*ngFor="let widgetComponent of reportRow.widgetComponents"
			[params]="params"
			[dataOptions]="dataOptions"
			[widgetComponentType]="widgetComponent.type"
			[ngClass]="'wcd-flex-' + widgetComponent.weight"
			[headerLevel]="headerLevel"
		></report-widget>
	