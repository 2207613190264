import { Injectable, OnDestroy } from '@angular/core';
import { ModelBase } from '@microsoft/paris';
import { isEqual } from 'lodash-es';
import { BehaviorSubject, Subject } from 'rxjs';

const RECENT_TIME_FRAME = 400; // milliseconds

@Injectable()
export class ActivatedEntity implements OnDestroy {
	readonly currentEntity$: BehaviorSubject<ModelBase> = new BehaviorSubject<ModelBase>(null);
	readonly fullScreenEntity$: Subject<boolean> = new Subject<boolean>();

	private _recentEntity: ModelBase;
	private _removeRecentEntityTimeout;

	get recentEntity(): ModelBase {
		return this._recentEntity;
	}

	setCurrentEntity(entity: ModelBase) {
		const currentEntity = this.currentEntity$.getValue();

		if (entity !== currentEntity && !isEqual(entity, currentEntity)) this.currentEntity$.next(entity);
	}

	setNextEntity(entity: ModelBase) {
		this._recentEntity = entity;
		clearTimeout(this._removeRecentEntityTimeout);

		this._removeRecentEntityTimeout = setTimeout(() => (this._recentEntity = null), RECENT_TIME_FRAME);
	}

	ngOnDestroy() {
		clearTimeout(this._removeRecentEntityTimeout);
		this.currentEntity$.complete();
		this.fullScreenEntity$.complete();
	}
}
