var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { RiskLevelEnum } from './mailbox-risk-level.values';
import { uniq } from 'lodash-es';
var ɵ0 = function (dataQuery) {
    if (!dataQuery)
        return {};
    var pageSettings = {};
    if (dataQuery.page) {
        pageSettings.pageIndex = dataQuery.page;
    }
    if (dataQuery.pageSize) {
        pageSettings.pageSize = dataQuery.pageSize;
    }
    return Object.assign({}, dataQuery.where, pageSettings);
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = ['Upn', 'MailboxPrimaryAddress'], ɵ3 = function (keys) {
    return uniq(keys);
};
var Mailbox = /** @class */ (function (_super) {
    __extends(Mailbox, _super);
    function Mailbox() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Upn' }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "upn", void 0);
    __decorate([
        EntityField({ data: 'DisplayName' }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "displayName", void 0);
    __decorate([
        EntityField({ data: 'MailboxPageUrl' }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "mailboxPageUrl", void 0);
    __decorate([
        EntityField({ data: 'MailboxPrimaryAddress' }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "mailboxPrimaryAddress", void 0);
    __decorate([
        EntityField({ data: 'Urn', required: false }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "urn", void 0);
    __decorate([
        EntityField({ data: 'RiskLevel', required: false }),
        __metadata("design:type", String)
    ], Mailbox.prototype, "riskLevel", void 0);
    __decorate([
        EntityField({
            data: 'RiskKeys',
            parse: ɵ3,
            required: false,
        }),
        __metadata("design:type", Array)
    ], Mailbox.prototype, "riskI18nKeys", void 0);
    __decorate([
        EntityField({ data: 'RiskyActivities', required: false, defaultValue: 0 }),
        __metadata("design:type", Number)
    ], Mailbox.prototype, "riskyActivities", void 0);
    Mailbox = __decorate([
        Entity({
            singularName: 'Mailbox',
            pluralName: 'Mailboxes',
            endpoint: 'mailboxes',
            parseDataQuery: ɵ0,
            allItemsProperty: 'results',
            baseUrl: ɵ1,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], Mailbox);
    return Mailbox;
}(EntityModelBase));
export { Mailbox };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
