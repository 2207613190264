import { EntityField, EntityModelBase } from '@microsoft/paris';
import { IStatus } from './status.interface';

export class StatusModel<TId extends number | string = string, TType extends string = string>
	extends EntityModelBase<TId>
	implements IStatus<TId, TType> {
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField({ type: String } as any) id: TId;

	name: string;
	/**
	 * When provided, consumers of this model should use this for display purposes
	 * instead of the name field.
	 */
	nameI18nKey?: string;
	type?: TType;
	className?: string;
	iconName?: string;
	wcdIconName?: string;
	image?: string;
	priority?: number;
	helpKey?: string;
}
