import { Injectable } from '@angular/core';
import { HttpXsrfTokenExtractor } from '@angular/common/http';

const FORM_ID = '_data_export_';
const IFRAME_ID = FORM_ID + '_iframe_';

export interface SccExportFormInput {
	name: string;
	value: any;
}

@Injectable()
export class SccExportService {
	constructor(private httpXsrfTokenExtractor: HttpXsrfTokenExtractor) {}

	exportSccData(url: string, inputs?: Array<SccExportFormInput>) {
		// adapted from Nibiru (ExportService.ts)
		const xsrfToken = this.httpXsrfTokenExtractor.getToken();

		const formEle = document.getElementById(FORM_ID);
		if (formEle) {
			formEle.remove();
		}

		const newFormElem = document.createElement('form');
		newFormElem.setAttribute('id', FORM_ID);
		newFormElem.setAttribute('method', 'post');
		newFormElem.setAttribute('action', url);
		newFormElem.setAttribute('enctype', 'application/x-www-form-urlencoded');
		newFormElem.setAttribute('target', IFRAME_ID);
		newFormElem.setAttribute('style', 'display: none');

		const inputElem = document.createElement('input');
		inputElem.setAttribute('type', 'hidden');
		inputElem.setAttribute('name', 'XSRF-TOKEN');
		inputElem.setAttribute('value', xsrfToken);

		const iframeElem = document.createElement('iframe');
		iframeElem.setAttribute('id', IFRAME_ID);
		iframeElem.setAttribute('name', IFRAME_ID);
		iframeElem.setAttribute('style', 'display: none');

		inputs &&
			inputs.forEach(formInput => {
				const formInputElem = document.createElement('input');
				formInputElem.setAttribute('type', 'hidden');
				formInputElem.setAttribute('name', formInput.name);
				formInputElem.setAttribute('value', formInput.value);
				newFormElem.appendChild(formInputElem);
			});
		newFormElem.appendChild(inputElem);
		newFormElem.appendChild(iframeElem);

		document.body.appendChild(newFormElem);
		newFormElem.submit();
	}
}
