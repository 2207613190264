import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ChangeDetectorRef,
	ViewChild,
	HostListener,
	ContentChildren,
	QueryList,
	AfterViewInit, SimpleChanges, OnChanges,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { skip, filter } from 'rxjs/operators';
import { Focusable } from '../models/focusable.interface';
import { IIconStyles } from 'office-ui-fabric-react';

const MARGIN = 20;
const FOCUS_CSS_CLASS = 'focused';

export enum Positions {
	Right,
	Left,
	Default
}

let lastId = 0;
@Component({
	selector: 'wcd-dropdown',
	templateUrl: './dropdown-base.component.html',
	styleUrls: ['./dropdown.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DropdownComponent implements OnInit, OnDestroy, OnChanges, AfterViewInit, Focusable {
	@Input() buttonId: string = `dropdown-button-${lastId++}`;
	@Input() buttonText: string;
	@Input() buttonClass = '';
	@Input() buttonIcon: string;
	@Input() buttonIconClass: string;
	@Input() iconButtonOnly: boolean = false;
	@Input() buttonImage: string;
	@Input() buttonTooltip: string;
	@Input() disabled = false;
	@Input() closeOnClick = true; // closeOnClick controls the behavior of the enter key press and should be equal to closeOnMouseUp and they both need to be false in case of sub menu
	@Input() closeOnMouseUp = true;
	@Input() showIconDropdown = true;
	@Input() menuClass = '';
	@Input() isBordered = true;
	@Input() isFullWidth = false;
	@Input() focusButtonOnClose = true;
	@Input() ariaLabel: string;
	@Input() ariaRole: string = 'combobox';
	@Input() ariaHaspopup: string = null;
	@Input() ariaOwns: string = null;
	@Input() position: Positions = Positions.Default;
	@Input() optionAriaSetSize: string = null;
	@Input() optionAriaRole: string = null;
	@Input() optionAriaPositionInSet: number = null;

	@Input() navigateUsingArrowKeysOnly: boolean = false;
	@Input() navigateUsingTab: boolean = false;
	@Input() allowFocusOnMenu = -1
	@Input() focusOnInit: boolean = false;

	@Input() focusHelper?: (any)=>{ any };
	@Input() fixDropdownItems?: Function;
	@Input() required?;


	@Output() focus: EventEmitter<void> = new EventEmitter<void>();
	@Output() blur: EventEmitter<void> = new EventEmitter<void>();
	@Output() exit: EventEmitter<void> = new EventEmitter<void>();
	@Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('menu', { static: true })
	set menu(menu: ElementRef) {
		this.menuEl = menu.nativeElement;

		if (this.menuEl) {
			this.menuEl.addEventListener('mouseup', e => this.onMouseUp(e));

			/**
			 * Taking the menu out of the component, and into the body, for positioning.
			 */
			document.body.appendChild(this.menuEl);
		}
	}

	@ViewChild('dropdownButton', { static: true })
	set dropdownButton(button: ElementRef) {
		this.buttonEl = button.nativeElement;
	}

	@ContentChildren('accessibleListItem', { descendants: true }) dropdownItems: QueryList<ElementRef>;

	setFocus = () => {
		this.buttonEl.focus();
	};

	positions = Positions;
	focusedElementRef: ElementRef;
	dropdownEl: HTMLElement;
	menuEl: HTMLElement;
	buttonEl: HTMLElement;
	isOpen = false;
	isVisible = false;
	keyboardSubscription: Subscription;
	boundMouseDown: (e: Event) => boolean;
	boundOnMouseWheel: (e: Event) => any;
	chevronIconStyle: IIconStyles = {
		root: {
			fontWeight: 'bold',
		},
	};

	isCombobox: boolean = true;

	constructor(private elementRef: ElementRef, protected changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		this.dropdownEl = this.elementRef.nativeElement;
		this.boundMouseDown = this.onMouseDown.bind(this);
		this.boundOnMouseWheel = this.onMouseWheel.bind(this);
		this.isCombobox = this.ariaRole === 'combobox';
	}

	ngOnDestroy() {
		if (this.menuEl) document.body.removeChild(this.menuEl);
		if (this.keyboardSubscription) {
			this.keyboardSubscription.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.ariaRole) {
			this.isCombobox = changes.ariaRole.currentValue === 'combobox';
		}
	}

	ngAfterViewInit() {
		if (this.focusOnInit){
			setTimeout(()=>{
				this.setFocus();
			})
			this.focusOnInit = false;
		}
	}

	open() {
		setTimeout(() => this.setMenuPositionAndShow());
		document.body.addEventListener('mousedown', this.boundMouseDown);
		window.addEventListener('mousewheel', this.boundOnMouseWheel);
		document.body.addEventListener('mousedown', this.boundMouseDown);

		this.keyboardSubscription = fromEvent(document.body, 'keydown')
			.pipe(
				filter((e: KeyboardEvent) => e.key === 'Enter')
			)
			.subscribe(e => {
				if(!this.isMenuParentOfTarget(e)){
					this.close();
				}
			});

		this.isOpen = true;
		this.setFocusedElement(this.dropdownItems.first);
		this.changeDetectorRef.detectChanges();
		this.onToggle.emit(this.isOpen);
	}

	close(actionCanceled = false): boolean {
		this.isVisible = false;

		setTimeout(() => {
			this.isOpen = false;
			this.menuEl.style.removeProperty('height');
			this.menuEl.style.removeProperty('width');
			if (this.focusButtonOnClose || actionCanceled) {
				this.buttonEl.focus();
			}
			this.changeDetectorRef.detectChanges();
			this.onToggle.emit(this.isOpen);
			if (this.keyboardSubscription) {
				this.keyboardSubscription.unsubscribe();
			}
		});

		document.body.removeEventListener('mousedown', this.boundMouseDown);
		window.removeEventListener('mousewheel', this.boundOnMouseWheel);

		return true;
	}

	/*
	This function allows the tags-edit component to close the dropdown
	by hitting on escape when search text is empty.
	 */
	escape() {
		this.close();
	}

	toggle() {
		if (this.isOpen) this.close();
		else this.open();
	}

	onMouseWheel(e) {
		if (!e.target.closest('.wcd-dropdown--menu')) this.close();
	}

	onMouseDown(e): boolean {
		if(!this.isMenuParentOfTarget(e)){
			this.close();
		}
		return true;
	}

	onMouseUp(e): boolean {
		if (!this.closeOnMouseUp) {
			return;
		}
		const el = e.target;
		if (
			el.nodeName === 'A' ||
			(el.nodeName === 'BUTTON' && !el.classList.contains('dropdown-action-btn')) ||
			this.closeOnClick !== false
		) {
			if (el.nodeName === 'DIV' && el.classList.contains('wcd-dropdown--menu__open')) {
				return true;
			}
			return this.close();
		}

		return true;
	}

	getFocusedElementRef(): ElementRef {
		if (!this.focusedElementRef) {
			this.focusedElementRef = this.dropdownItems && this.dropdownItems.first;
		}
		// this.dropdownItems can be empty, there is no guarantee there is a focusedElement
		return this.focusedElementRef;
	}

	// handle switching search types using up/down keys
	onKeydownUpDown(e: KeyboardEvent, isUp = false) {
		this.UpdateFocusedElement(isUp);
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	UpdateFocusedElement(isUp = false){
		const focusedElementRef = this.getFocusedElementRef();
		if (focusedElementRef && this.isOpen) {
			const itemsArray = !this.fixDropdownItems ? this.dropdownItems.toArray() : this.fixDropdownItems(this.dropdownItems.toArray());
			let index: number = itemsArray.indexOf(focusedElementRef);
			const lastItemIndex = this.dropdownItems.length - 1;
			if (isUp) {
				index = index - 1 < 0 ? lastItemIndex : index - 1;
			} else {
				index = index + 1 > lastItemIndex ? 0 : index + 1;
			}
			this.setFocusedElement(this.dropdownItems.find((_elemRef, elemIndex) => index === elemIndex));
		}
	}

	// selecting item using enter key
	onKeydownEnter(e: KeyboardEvent) {
		if (!this.isOpen) {
			return true;
		}
		const focusedElementRef = this.getFocusedElementRef();
		if (focusedElementRef) {
			focusedElementRef.nativeElement.click();
		}
		if (this.closeOnClick) {
			return this.close();
		}
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	// cancel action and close drop-down using escape key
	onKeydownEsc(e: KeyboardEvent) {
		if (!this.isOpen) {
			return true;
		}
		this.close(true);
		this.exit.emit();
		e.preventDefault();
		e.stopPropagation();
		return false;
	}

	onKeydownTab(e: KeyboardEvent) {
		if (this.navigateUsingArrowKeysOnly) {
			return this.onKeydownEsc(e);
		}
		if (this.navigateUsingTab) {
			const isUp = e.shiftKey;
			this.UpdateFocusedElement(isUp);
			e.preventDefault();
			e.stopPropagation();
		}
	}

	setFocusedElement(newElement) {
		if (this.focusHelper){
			newElement = this.focusHelper(newElement);
		}
		if (!newElement) {
			return;
		}

		if (this.focusedElementRef) {
			this.focusedElementRef.nativeElement.classList.remove(FOCUS_CSS_CLASS);
		}
		this.focusedElementRef = newElement;
		this.focusedElementRef.nativeElement.classList.add(FOCUS_CSS_CLASS);
		this.focusedElementRef.nativeElement.focus();
	}

	setMenuPositionAndShow() {
		const buttonRect = this.buttonEl.getBoundingClientRect();

		this.menuEl.style.top = buttonRect.bottom + 'px';
		this.menuEl.style.left = buttonRect.left + 1 + 'px';
		this.menuEl.style.minWidth = buttonRect.width - 1 + 'px';

		const documentWidth = document.documentElement.clientWidth,
			documentHeight = document.documentElement.clientHeight,
			maxWidth = documentWidth - MARGIN * 2,
			maxHeight = documentHeight - MARGIN * 2;

		let menuClientRect = this.menuEl.getBoundingClientRect();
		let recalculateRect, dontSetLeft, dontSetTop;
		if (this.position === Positions.Left) {
			this.menuEl.style.left = Math.max(buttonRect.left - menuClientRect.width -1, MARGIN) + 'px';
			recalculateRect = true;
		}
		if (this.position === Positions.Right) {
			let rectLeft = buttonRect.left + buttonRect.width + 1;
			if( rectLeft + menuClientRect.width >  documentWidth - MARGIN){
				rectLeft = documentWidth - menuClientRect.width - MARGIN
			}
			this.menuEl.style.left = rectLeft + 'px';
			recalculateRect = true;
		}

		if (menuClientRect.width > maxWidth) {
			this.menuEl.style.width = maxWidth + 'px';
			this.menuEl.style.left = MARGIN + 'px';
			recalculateRect = true;
			dontSetLeft = true;
		}

		if (menuClientRect.height > maxHeight) {
			this.menuEl.style.height = maxHeight + 'px';
			this.menuEl.style.top = MARGIN + 'px';
			recalculateRect = true;
			dontSetTop = true;
		}

		if (!dontSetLeft || !dontSetTop) {
			if (recalculateRect) menuClientRect = this.menuEl.getBoundingClientRect();

			if (!dontSetLeft) {
				const farthestPosition = documentWidth - MARGIN;
				if (menuClientRect.right > farthestPosition && menuClientRect.width < maxWidth) {
					const leftDelta = menuClientRect.right - farthestPosition;
					this.menuEl.style.left = buttonRect.left - leftDelta + 'px';
				}
			}

			if (!dontSetTop) {
				const lowestPosition = documentHeight - MARGIN;
				if (menuClientRect.bottom > lowestPosition && menuClientRect.height < maxHeight) {
					const bottomDelta = menuClientRect.bottom - lowestPosition;
					this.menuEl.style.top = Math.max(buttonRect.top - bottomDelta, MARGIN)  + 'px';
				}
			}
		}

		this.isVisible = true;
		this.changeDetectorRef.detectChanges();
		// if getFocusedElementRef return value this element is a dropdown and focus should be on the first element in the dropdown.
		// if getFocusedElementRef return null this is a hover-card styled element and focus needs to be on the menuEL.
		const focusedElement = this.getFocusedElementRef();
		const nativeFocusedElement = focusedElement ? focusedElement.nativeElement : this.menuEl;
		nativeFocusedElement.focus();
	}

	isMenuParentOfTarget(e){
		let el = e.target;
		do {
			if (el === document.documentElement) return false;

			if (el === this.menuEl) return true;
		} while ((el = el.parentNode));
		return true;
	}
}
