import { Entity, EntityField } from '@microsoft/paris';
import { FileInstance } from '../file/file-instance.entity'

@Entity({
	singularName: 'File alert evidence',
	pluralName: '',
	readonly: true,
	endpoint: `files`,
})
export class FileAlertEvidence extends FileInstance {
	@EntityField() relatedAlertIds: string[];

	get uniqueEvidenceId(): string {
		if (this.uniqueEntityId) {
			const relatedAlertsIdsString = this.relatedAlertIds.sort().toString();
			return this.uniqueEntityId.concat(relatedAlertsIdsString);
		}
		return null;
	}

	entityType = 'fileInstance';
}
