// Can't extend native Map, as of Typescript 1.8... :(

/**
 * @deprecated Use `Map` instead.
 */
export class Dictionary<T extends number | string, U> {
	private keys: Map<T, U>;

	constructor() {
		this.keys = new Map<T, U>();
	}

	get isEmpty(): boolean {
		return !this.keys.size;
	}

	get hasValues(): boolean {
		return !!this.keys.size;
	}

	get size(): number {
		return this.keys.size;
	}

	/**
	 * @param key
	 * @param value
	 * @returns {Dictionary}
	 * @deprecated
	 */
	add(key: T, value: U): Dictionary<T, U> {
		this.keys.set(key, value);
		return this;
	}

	set(key: T, value: U): Dictionary<T, U> {
		this.keys.set(key, value);
		return this;
	}

	delete(key: T): U {
		const value = this.get(key);
		if (value !== undefined) {
			this.keys.delete(key);
			return value;
		}

		return undefined;
	}

	/**
	 * If the Dictionary already has the specified key, return its value. Otherwise, add the key with the specified value and return the new value.
	 * @param key
	 * @param value
	 * @returns {U}
	 */
	addIfNotExists(key: T, value: U): U {
		if (this.has(key)) return this.get(key);

		this.set(key, value);
		return value;
	}

	addList(values: ReadonlyArray<U>, keyProperty?: string, stringifyKey: boolean = false): Dictionary<T, U> {
		if (values)
			values.forEach((value: U) =>
				this.set(
					keyProperty ? (stringifyKey ? String(value[keyProperty]) : value[keyProperty]) : value,
					value
				)
			);

		return this;
	}

	has(key: T): boolean {
		return this.keys.has(key);
	}

	clear(): Dictionary<T, U> {
		this.keys.clear();
		return this;
	}

	get(key: T): U {
		return this.keys.get(key);
	}

	getList(keys: Array<T>): Array<U> {
		return keys.map(key => this.get(key)).filter(value => !!value);
	}

	first(): U {
		if (!this.hasValues) return null;

		return this.toArray()[0];
	}

	toObject(): { [index: string]: U } {
		const obj: { [index: string]: U } = {};

		this.keys.forEach((value, key) => {
			obj[String(key)] = value;
		});

		return obj;
	}

	toArray(): Array<U> {
		const valuesArray: Array<U> = [],
			values = this.keys.values();

		let value: U;

		while ((value = values.next().value)) valuesArray.push(value);

		return valuesArray;
	}

	equals(otherDictionary: Dictionary<T, U>): boolean {
		if (!otherDictionary) return false;

		if (otherDictionary.size !== this.size) return false;

		if (this.some((value, key) => !otherDictionary.has(key))) return false;

		return true;
	}

	equalsWith(
		otherDictionary: Dictionary<T, U>,
		callbackFn: (thisValue?: U, otherValue?: U, key?: T) => boolean
	): boolean {
		if (!this.equals(otherDictionary)) return false;

		return this.every((value, key) => callbackFn(value, otherDictionary.get(key), key));
	}

	getKeys(): Array<T> {
		const keysArray: Array<T> = [],
			keys = this.keys.keys();

		let key: T;

		while ((key = keys.next().value)) keysArray.push(key);

		return keysArray;
	}

	some(callbackfn: (value: U, key: T) => boolean): boolean {
		const keys = this.keys.keys();
		let result: IteratorResult<T>;

		while (!(result = keys.next()).done) {
			if (callbackfn(this.get(result.value), result.value)) return true;
		}

		return false;
	}

	stringify(): string {
		return JSON.stringify(this.toObject());
	}

	every(callbackfn: (value: U, key: T) => boolean): boolean {
		const keys = this.keys.keys();
		let result: IteratorResult<T>;

		while (!(result = keys.next()).done) {
			if (!callbackfn(this.get(result.value), result.value)) return false;
		}

		return true;
	}

	find(callbackfn: (value: U, key: T) => boolean): U {
		const keys = this.keys.keys();
		let result: IteratorResult<T>;

		while (!(result = keys.next()).done) {
			const value: U = this.get(result.value);
			if (callbackfn(value, result.value)) return value;
		}

		return null;
	}

	map<V>(callbackfn: (value?: U, index?: T, indexNumber?: number) => V): Array<V> {
		const mapped: Array<V> = new Array<V>(this.keys.size),
			keys = this.keys.keys();

		let key: T,
			result: IteratorResult<T>,
			indexNumber = 0;

		while (!(result = keys.next()).done) {
			key = result.value;
			mapped[indexNumber] = callbackfn(this.keys.get(key), key, indexNumber);
			indexNumber++;
		}

		return mapped;
	}

	forEach(callbackfn: (value?: U, index?: T, indexNumber?: number) => any): Dictionary<T, U> {
		const keys = this.keys.keys();

		let key: T,
			result: IteratorResult<T>,
			indexNumber = 0;

		while (!(result = keys.next()).done) {
			key = result.value;
			callbackfn(this.keys.get(key), key, indexNumber++);
		}

		return this;
	}

	filter(callbackfn: (value?: U, key?: T, index?: number) => boolean): Dictionary<T, U> {
		const filteredDictionary: Dictionary<T, U> = new Dictionary<T, U>();
		const keys = this.keys.keys();
		let value: U;
		let key: T;
		let result: IteratorResult<T>;
		let indexNumber = 0;

		while (!(result = keys.next()).done) {
			key = result.value;
			value = this.keys.get(key);
			if (callbackfn(value, key, indexNumber++)) filteredDictionary.set(key, value);
		}

		return filteredDictionary;
	}

	/**
	 * Creates a dictionary from an array of objects, indexes it according to the specified property of each object
	 * @param values An array of objects
	 * @param keyProperty The name of the property in each object to use as Dictionary key
	 * @param stringifyKey {boolean = false} If true, the value of the ID property is converted to a String, so the keys of the Dictionary are always strings
	 * @returns {Dictionary<T, U>}
	 */
	static fromList<T extends number | string, U>(
		values: ReadonlyArray<U>,
		keyProperty?: string,
		stringifyKey: boolean = false
	): Dictionary<T, U> {
		const dictionary = new Dictionary<T, U>();
		return dictionary.addList(values, keyProperty, stringifyKey);
	}

	static fromRawList<T extends number | string, U>(
		V: new (data: any) => U,
		rawList: Array<any>,
		keyProperty?: string
	): Dictionary<T, U> {
		return Dictionary.fromList<T, U>(rawList.map(item => new V(item)), keyProperty);
	}

	static fromIndex<U>(keysIndex: { [key: string]: U }): Dictionary<string, U> {
		const dictionary = new Dictionary<string, U>();

		if (keysIndex) {
			for (const key in keysIndex) {
				if (keysIndex.hasOwnProperty(key)) dictionary.set(key, keysIndex[key]);
			}
		}

		return dictionary;
	}

	static fromDictionary<T extends number | string, U, V extends number | string>(
		dictionary: Dictionary<V, U>,
		keyProperty: string
	): Dictionary<T, U> {
		return Dictionary.fromList<T, U>(dictionary.toArray(), keyProperty);
	}
}
