import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { ProfileComplianceStatus } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import {
	EventModel,
	EventsSummaryBarSize,
} from '../../../shared/components/events-summary-bar/events-summary-bar.component';
import {
	SummeryBarEvents,
	SummeryBarSetting,
} from '../../../shared/components/summery-bars-with-legend/summery-bars-with-legend.component';
import { TvmProfilesComplianceStatusBarsService } from '../../services/tvm-profiles-compliance-status-bars.service';
import { BaselineComplianceProfileStatusPercentage } from './baseline-compliance-profile-status-percentage.enum';

@Component({
	selector: 'tvm-profiles-compliance-status-bars',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tvm-profiles-compliance-status-bars.component.html',
})
export class TvmProfilesComplianceStatusBarsComponent implements OnChanges {
	@Input() profilesComplianceStatus: ProfileComplianceStatus[];
	@Input() showTitle: boolean = true;

	settingsArray: SummeryBarSetting<BaselineComplianceProfileStatusPercentage>;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public tvmProfilesComplianceStatusBarsService: TvmProfilesComplianceStatusBarsService,
		public i18nService: I18nService,
		private router: Router
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.profilesComplianceStatus.currentValue) {
			this.settingsArray = this.createSummeryBarSetting();
			this.changeDetectorRef.detectChanges();
		}
	}

	private createSummeryBarSetting(): SummeryBarSetting<BaselineComplianceProfileStatusPercentage> {
		return {
			events: this.profilesComplianceStatus.map(status => this.createSummeryBarEvent(status)),
			colorMap: this.tvmProfilesComplianceStatusBarsService.complianceStatusesColorMap,
			textFormatter: this.tvmProfilesComplianceStatusBarsService.complianceStatusesTextFormatter,
			size: EventsSummaryBarSize.Medium,
		};
	}

	private createSummeryBarEvent(
		profileComplianceStatus: ProfileComplianceStatus
	): SummeryBarEvents<BaselineComplianceProfileStatusPercentage> {
		return {
			id: profileComplianceStatus.profileId,
			events: this.tvmProfilesComplianceStatusBarsService.statusPercentageKeys
				.map(key => {
					const count = profileComplianceStatus[key];
					return this.createBarConfig(key, count);
				})
				.filter(Boolean),
			title: this.showTitle ? profileComplianceStatus.profileDisplayName : undefined,
			amount: profileComplianceStatus.compliantDevices,
			total: profileComplianceStatus.applicableDevices,
		};
	}

	private createBarConfig(
		percentageType: BaselineComplianceProfileStatusPercentage,
		count: number
	): EventModel<BaselineComplianceProfileStatusPercentage> {
		if (count === 0) return null;

		const barConfig: EventModel<BaselineComplianceProfileStatusPercentage> = {
			kind: percentageType,
			count: count,
		};

		return barConfig;
	}

	onClickOnProfile(profileComplianceStatus: SummeryBarEvents<BaselineComplianceProfileStatusPercentage>) {
		this.router.navigate([`/baseline-compliance_t/profiles/${encodeURIComponent(profileComplianceStatus.id)}`]);
	}
}
