
		<div class="wcd-full-height wcd-flex-vertical">
			<fab-message-bar
				*ngIf="showProactiveDiscoveryAutoStartMessage"
				dismissable="true"
				(onDismiss)="hideProactiveDiscoveryAutoStartMessage()"
				[dismissButtonAriaLabel]="'dismiss' | i18n"
				[messageBarType]="MessageBarType.info"
				[renderActions]="actions"
			>
				<div>
					<span> {{ proactiveDiscoveryAutoStartMessage }}</span>
					<a href="https://aka.ms/AAbknru" target="_blank" rel="noopener noreferrer">{{
						'DevicesPageAutoProactiveMessageLearnMore' | i18n
					}}</a>
				</div>
			</fab-message-bar>
			<ng-template #actions>
				<span class="wcd-padding-mediumSmall-right">
					<fab-messagebar-button
						[text]="'EnableProactiveDeviceDiscoveryButtonText' | i18n"
						(onClick)="activateProactiveSettings()"
						[disabled]="!isAdmin"
					></fab-messagebar-button>
					<fab-messagebar-button
						[text]="'GoToProactiveDeviceDiscoverySettingsButtonText' | i18n"
						(onClick)="navigateToProactiveSettings()"
					></fab-messagebar-button>
				</span>
			</ng-template>
		</div>
	