var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { keyBy, mapKeys, mapValues, uniq, values } from 'lodash-es';
import { of } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { InvestigationPendingType, InvestigationStatus, InvestigationStatusEnum } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { getUserTimeZone } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
var WIDGET_API_URL = 'dashboard/active_automated_investigations';
var STATUS_MAPPING = {
    running: {
        className: 'primary',
        color: '#0078d4',
        name: 'Running',
        name18nKey: 'activeAutomatedInvestigationsWidget_running',
        status: InvestigationStatusEnum.running,
        legacyStatus: InvestigationStatusEnum.running,
    },
    pending_approval: {
        className: 'warning-dark',
        color: '#F2610C',
        name: 'Pending action',
        name18nKey: 'activeAutomatedInvestigationsWidget_pending_action',
        status: InvestigationStatusEnum.pendingUser,
        legacyStatus: InvestigationStatusEnum.pending,
    },
    pending_resource: {
        className: 'gray-200',
        color: '#D9D9D9',
        name: 'Waiting for device',
        name18nKey: 'activeAutomatedInvestigationsWidget_waiting_for_device',
        status: InvestigationStatusEnum.pendingResource,
        legacyStatus: InvestigationStatusEnum.pending,
    },
};
var ActiveAutomatedInvestigationsWidget = /** @class */ (function (_super) {
    __extends(ActiveAutomatedInvestigationsWidget, _super);
    function ActiveAutomatedInvestigationsWidget(reportsService, router, paris, featuresService, serviceUrlsService, appConfigService, prettyNumberService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.router = router;
        _this.paris = paris;
        _this.featuresService = featuresService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.appConfigService = appConfigService;
        _this.prettyNumberService = prettyNumberService;
        _this.i18nService = i18nService;
        _this.pieChartTitle$ = _this.data$.pipe(map(function (data) {
            return _this.prettyNumberService.prettyNumber(data
                ? data
                    .map(function (a) { return a.value; })
                    .reduce(function (a, b) { return a + b; }, 0)
                : 0);
        }));
        _this.legendItems$ = _this.data$.pipe(filter(Boolean), map(function (data) {
            return data.map(function (item) { return ({
                name: item.name,
                value: Number(item.value),
                iconClassName: "color-text-" + STATUS_MAPPING[item.id].className,
            }); });
        }));
        _this.activeAutomatedInvestigationPieSettings = {
            columnName: 'name',
            columnValue: 'value',
            options: {
                data: {
                    colors: mapValues(mapKeys(STATUS_MAPPING, function (value) { return value.name18nKey; }), function (value) { return value && value.color; }),
                    onclick: function (value) {
                        _this.goToInvestigationsPage(value);
                    },
                },
                tooltip: {
                    format: {
                        value: function (value, ratio) { return value; },
                    },
                },
                donut: {
                    title: _this.i18nService.get('activeAutomatedInvestigationsWidget_active'),
                },
                legend: {
                    show: false,
                },
                size: {
                    width: 250,
                    height: 200,
                },
            },
        };
        return _this;
    }
    Object.defineProperty(ActiveAutomatedInvestigationsWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var rangeInDays = this.appConfigService.widgetLookback;
            return Object.assign({
                id: 'activeAutomatedInvestigations',
                name: this.i18nService.get('widget_title_automatedInvestgations'),
                rangeInDays: rangeInDays,
                minHeight: '200px',
            }, this.appConfigService.isAutomatedIrEnabled
                ? {
                    parseData: this.parseData.bind(this),
                    api: this.featuresService.isEnabled(Feature.AirsApiOffloading)
                        ? {
                            url: function () { return _this.serviceUrlsService.threatIntel + "/" + WIDGET_API_URL; },
                            isExternal: true,
                        }
                        : {
                            url: WIDGET_API_URL,
                            params: {
                                range: rangeInDays,
                                timezone: getUserTimeZone(),
                            },
                        },
                }
                : {
                    loadData: function () { return of([null]); },
                });
        },
        enumerable: true,
        configurable: true
    });
    ActiveAutomatedInvestigationsWidget.prototype.parseData = function (data) {
        var _this = this;
        return Object.keys(data).map(function (key) {
            return { id: key, name: _this.i18nService.get(STATUS_MAPPING[key].name18nKey), value: data[key] };
        });
    };
    ActiveAutomatedInvestigationsWidget.prototype.goToInvestigationsPage = function (status) {
        var _this = this;
        var investigationStatusStr, pendingTypeFilter;
        if (status) {
            var statusObj = keyBy(STATUS_MAPPING, 'name')[status.id];
            var investigationStatus_1 = this.paris.getValue(InvestigationStatus, statusObj.status);
            if (this.featuresService.isEnabled(Feature.AirsApiOffloading)) {
                investigationStatusStr = String(investigationStatus_1.sevilleStatusId);
            }
            else {
                investigationStatusStr = String(statusObj.legacyStatus);
                if (investigationStatus_1.isPending) {
                    var pendingTypes = this.paris
                        .getRepository(InvestigationPendingType)
                        .entity.values.filter(function (pendingType) {
                        return investigationStatus_1.isPendingUser
                            ? pendingType.isUserPending
                            : !pendingType.isUserPending;
                    });
                    pendingTypeFilter = ",pending_type=" + pendingTypes
                        .map(function (pendingType) { return pendingType.id; })
                        .join('|');
                }
            }
        }
        else {
            investigationStatusStr = uniq(values(STATUS_MAPPING).map(function (value) {
                var investigationStatus = _this.paris.getValue(InvestigationStatus, value.status);
                return _this.featuresService.isEnabled(Feature.AirsApiOffloading)
                    ? investigationStatus.sevilleStatusId
                    : value.legacyStatus;
            })).join('|');
        }
        this.router.navigate(['/investigations'], {
            queryParams: Object.assign({}, investigationStatusStr && {
                filters: "status=" + investigationStatusStr + (pendingTypeFilter || ''),
            }, { range: this.widgetConfig.rangeInDays }),
        });
    };
    return ActiveAutomatedInvestigationsWidget;
}(ReportWidgetComponent));
export { ActiveAutomatedInvestigationsWidget };
