import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import {
	RemediationStatusId,
	RemediationStatusType,
	VerdictStatusType,
} from './airs-entity-status-types.enum';

export const airsEntityStatusValues: Array<AirsEntityStatus> = [
	{
		id: RemediationStatusId.Remediated,
		name: 'Remediated',
		helpKey: null,
		type: RemediationStatusType.Remediated,
		className: 'success',
		paletteColor: 'green',
		priority: 2,
		iconName: 'shield',
		isImportant: true,
		isRemediated: true,
	},
	{
		id: 1,
		name: 'Malicious',
		helpKey: null,
		type: VerdictStatusType.Malicious,
		className: 'highSeverity',
		paletteColor: 'highSeverity',
		priority: 1,
		isImportant: true,
		iconName: 'malware',
		isMalicious: true,
		isVerdict: true,
	},
	{
		id: 2,
		name: 'Suspicious',
		helpKey: null,
		type: VerdictStatusType.Suspicious,
		className: 'mediumSeverity',
		paletteColor: 'mediumSeverity',
		priority: 3,
		isImportant: true,
		iconName: 'suspicious',
		isSuspicious: true,
		isVerdict: true,
	},
	{
		id: 3,
		name: 'Verified',
		helpKey: null,
		type: VerdictStatusType.Verified,
		className: 'benign',
		paletteColor: 'blueDark',
		priority: 4,
		iconName: 'check',
		isLegacy: true,
		isVerdict: true,
	},
	{
		id: 4,
		name: 'No threats found',
		helpKey: null,
		type: VerdictStatusType.Clean,
		className: 'benign',
		paletteColor: 'blueDark',
		priority: 4,
		iconName: 'safe',
		isVerdict: true,
	},
	{
		id: 5,
		name: 'Unknown',
		helpKey: null,
		type: VerdictStatusType.Unknown,
		priority: 5,
		iconName: 'help',
		isVerdict: true,
		className: 'neutralPrimaryAlt',
		paletteColor: 'neutralTertiaryAlt',
	},
	{
		id: RemediationStatusId.NotFound,
		name: 'Not found',
		helpKey: null,
		type: RemediationStatusType.NotFound,
		priority: 6,
		iconName: 'help',
		className: 'neutralPrimaryAlt',
		paletteColor: 'neutralTertiaryAlt',
	},
	{
		id: RemediationStatusId.Unremediated,
		name: 'Unremediated',
		paletteColor: 'orangeLighter',
		helpKey: null,
		type: RemediationStatusType.Unremediated,
		priority: 7,
		isImportant: true,
		isVerdict: false,
	},
	{
		id: RemediationStatusId.PartiallyRemediated,
		name: 'Partially Remediated',
		helpKey: null,
		type: RemediationStatusType.PartiallyRemediated,
		className: 'warning',
		paletteColor: 'yellow',
		priority: 0,
		isImportant: true,
		isVerdict: false,
	},
];

/**
 * @deprecated use AirsEntityVerdict/AirsEntityRemediationStatus instead
 */
@Entity({
	singularName: 'Entity Status',
	pluralName: 'Entity Statuses',
	values: airsEntityStatusValues,
})
export class AirsEntityStatus extends StatusModel<number> {
	@EntityField({ defaultValue: false })
	isRemediated?: boolean;

	@EntityField({ defaultValue: false })
	isImportant?: boolean;

	@EntityField({ defaultValue: false })
	isMalicious?: boolean;

	@EntityField({ defaultValue: false })
	isSuspicious?: boolean;

	@EntityField({ defaultValue: false })
	isLegacy?: boolean;

	@EntityField({ defaultValue: false })
	isVerdict?: boolean;

	@EntityField()
	paletteColor: string;
}
