import {Alert} from '../../alert/alert.entity';
import {DataQuery, EntityRelationship, EntityRelationshipRepositoryType, RelationshipType} from '@microsoft/paris';
import {EvidenceAlertsSummary, Mailbox, severityValues, WcdPortalParisConfig} from '@wcd/domain';

@EntityRelationship({
	sourceEntity: Mailbox,
	dataEntity: EvidenceAlertsSummary,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (mailbox: Mailbox) => ({ id: mailbox.id }),
	fixedData: { lookBackInDays: 180, sortOrder: 'Asc', pageSize: 20, sortByField: 'FirstSeen' },
	endpoint: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useMailboxAlertsVnextApi'] ?
			`Evidences/mailbox/${query.where['id']}/AssociatedAlerts` :
			`evidence/mailbox/${query.where['id']}/alerts`
	},
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useMailboxAlertsVnextApi'] ?
			config.data.serviceUrls.alertsApiService :
			config.data.serviceUrls.threatIntel;
	},
	parseData: (data, _, query) =>
		query && query.where && query.where['useMailboxAlertsVnextApi'] ?
			({
				Items: data.items && data.items.map((securityAlert) => ({
						AlertId: securityAlert.alertId,
						Title: securityAlert.alertDisplayName,
						Categories: [securityAlert.category],
						FirstEventTime: new Date(securityAlert.startTimeUtc),
						LastEventTime: new Date(securityAlert.endTimeUtc),
						Severity: severityValues.find((value) => value.name === securityAlert.severity).id,
					})
				),
				IncidentCount: data.incidentCount,
				AlertCount: data.alertCount
			}) : data
})
export class MailboxAlertsRelationship implements EntityRelationshipRepositoryType<Mailbox, Alert> {}
