
		<ng-container *ngIf="item && itemEntityType">
			<!-- Network endpoint links are rendederd as 2 links: Ip and Url -->
			<ng-container *ngIf="originalEntityType !== networkEndpointType; else ipAndUrlLinks">
				<ng-template *ngTemplateOutlet="linkContent"></ng-template>
			</ng-container>
		</ng-container>
		<ng-template #linkContent>
			<a [href]="link" (click)="openEntityPanel($event)"
				>{{ getLinkText() }}
				<wcd-shared-icon
					*ngIf="showExternalLinkIcon"
					iconName="popOut"
					class="field-value-icon field-additional-icon"
				></wcd-shared-icon>
			</a>
		</ng-template>
		<ng-template #ipAndUrlLinks>
			<entity-link *ngIf="item.ip" [entity]="item.ip" [entityType]="ipType"></entity-link>
			<ng-container *ngIf="item.ip && item.url; else urlLinkContent">
				(<ng-template *ngTemplateOutlet="urlLinkContent"></ng-template>)
			</ng-container>
		</ng-template>
		<ng-template #urlLinkContent>
			<entity-link *ngIf="item.url" [entity]="item.url" [entityType]="urlType"></entity-link>
		</ng-template>
	