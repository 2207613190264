var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityPanelComponentBase } from './entity-panel.component.base';
import { Machine } from '@wcd/domain';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
var EntityPanelWithMachineComponent = /** @class */ (function (_super) {
    __extends(EntityPanelWithMachineComponent, _super);
    function EntityPanelWithMachineComponent(changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.rbacService = rbacService;
        _this.machinesService = machinesService;
        _this.isLoadingMachine = false;
        return _this;
    }
    EntityPanelWithMachineComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        this.machine = null;
        this.isLoadingMachine = false;
        this.changeDetectorRef.markForCheck();
        _super.prototype.initEntity.call(this, entity, isExtendedData);
    };
    EntityPanelWithMachineComponent.prototype.ngOnDestroy = function () {
        this._machineSubscription && this._machineSubscription.unsubscribe();
    };
    EntityPanelWithMachineComponent.prototype.setMachine = function (machineId) {
        var _this = this;
        this.machine = null;
        this.isLoadingMachine = true;
        this.changeDetectorRef.markForCheck();
        this._machineSubscription = this.rbacService
            .isUserExposedToEntity(this.globalEntityTypesService.getEntityType(Machine), machineId)
            .pipe(switchMap(function (userExposureResult) {
            if (userExposureResult.isExposed)
                return _this.paris.getRepository(Machine).getItemById(machineId);
            return of(null);
        }))
            .subscribe(function (machine) {
            _this.isLoadingMachine = false;
            _this.machine = machine;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isLoadingMachine = false;
            _this.changeDetectorRef.markForCheck();
        });
    };
    EntityPanelWithMachineComponent.prototype.showLoggedOnUsers = function () {
        this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
            noShadow: true,
            hasCloseButton: false,
        });
    };
    return EntityPanelWithMachineComponent;
}(EntityPanelComponentBase));
export { EntityPanelWithMachineComponent };
