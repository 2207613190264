<ng-container *ngIf="rule">
	<div *ngIf="isSuppressionRuleEntityPanel && rule.ruleType === suppressionRuleType.MDE" class="wcd-padding-large-left wcd-padding-large-right">
		<promotion-banner *ngIf="mtpPromotionService.isMtpBannerVisible"
			trackId="MtpPromotionSuppressionRuleEntity" textMessageKey="mtpPromotion.suppressionRule"
			[url]="urls.SuppressionRule + rule.id">
		</promotion-banner>
	</div>
	<wcd-collapsible-section [sectionId]="collapsibleID.Details" [label]="'suppressionRules_label' | i18n">
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{'suppressionRules_status' | i18n}}</dt>
			<dd>
				{{('suppressionRules_' + (rule.isEnabled ? 'Enabled' : 'Disabled')) | i18n }}
			</dd>
			<dt>{{'suppressionRules_ruleType' | i18n}}</dt>
			<dd>
				{{ (isAdvancedRule ? 'suppressionRules_ruleType_Advanced' : 'suppressionRules_ruleType_Simple') | i18n  }}
			</dd>
			<dt>{{"suppressionRules_scope" | i18n}}</dt>
			<dd>
				<ng-container [ngSwitch]="rule.scope.type">
					<div *ngSwitchCase="'AllOrganization'">
						{{'suppressionRules.scopeType.organization' | i18n}}
					</div>
					<div *ngSwitchCase="'Machine'">
						<wcd-shared-icon iconName="System" class="small-icon"></wcd-shared-icon>
						<route-link *ngIf="machineLink; else machineNameLabel"
							data-track-id="SuppressionRulePageOpenMachinePage" [routeLink]="machineLink">
							<ng-container *ngTemplateOutlet="machineNameLabel"></ng-container>
						</route-link>
						<ng-template #machineNameLabel>{{machineName}}</ng-template>
					</div>
					<div *ngSwitchCase="'MachineGroups'">
						{{ rule.deserializedRbacGroupIds && scopeText(rule.deserializedRbacGroupIds.length, 'suppressionRules.scopeType.machineGroups.single', 'suppressionRules.scopeType.machineGroups') }}
					</div>
					<div *ngSwitchCase="'Conditional'">
						{{'suppressionRules.scopeType.conditional' | i18n}}: {{ conditionalScopeText() }}
					</div>
				</ng-container>
			</dd>
			<dt>{{'suppressionRules_action' | i18n}}</dt>
			<dd>
				{{rule.action.nameI18nKey | i18n}}
			</dd>
			<dt>{{'suppressionRules_matching_alerts' | i18n}}</dt>
			<dd>
				{{rule.alertsCount}}
			</dd>
			<dt>{{'suppressionRules_created_by' | i18n}}</dt>
			<dd>
				{{rule.createdBy}}
			</dd>
			<dt>{{'suppressionRules_created_on' | i18n}}</dt>
			<dd>
				{{rule.createdOn | date:'medium'}}
			</dd>

		</dl>
	</wcd-collapsible-section>
	<wcd-collapsible-section *ngIf="isSuppressionRuleEntityPanel" [sectionId]="collapsibleID.Details" [label]="'suppressionRules.display.title' | i18n">
		<ng-container *ngIf="rule.ruleType === suppressionRuleType.MDE; else ruleConditions">
			<ng-container *ngIf="rule.iocConditionsNotEmpty; else noIocUseLabel">
				<mde-suppression-rule-ioc-conditions [mdeConditions]="rule.complexConditions" [conditionTypes]="suppressionRuleConditionTypes"></mde-suppression-rule-ioc-conditions>
			</ng-container>
			<ng-template #noIocUseLabel>
				<div>
					{{'suppressionRules.edit.noIocUse' | i18n}}
				</div>
			</ng-template>
		</ng-container>
		<ng-template #ruleConditions>
			<form class="wcd-full-height wcd-flex-vertical" #suppressionRuleForm="ngForm">
				<div class="wcd-flex-1">
					<section class="wcd-margin-bottom">
						<div class="form-group">
							<div class="wcd-full-width wcd-scroll-horizontal"
								*ngIf="rule.iocConditionsNotEmpty; else noIocUseLabel">
								<table class="wcd-table small-padding">
									<thead>
										<th>{{'suppressionRules.page.conditionTable.condition' | i18n}}</th>
										<th>{{'suppressionRules.page.conditionTable.operator'| i18n}}</th>
										<th>{{'suppressionRules.page.conditionTable.value'| i18n}}</th>
									</thead>
									<tbody>
										<tr *ngFor="let condition of rule.conditions">
											<td class="nowrap">
												{{condition.type.name}}
											</td>
											<td>
												<div>{{'suppressionRules.page.conditionTable.equals'| i18n}}</div>
											</td>
											<td>
												{{condition.value}}
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							<ng-template #noIocUseLabel>
								<div>
									{{'suppressionRules.edit.noIocUse' | i18n}}
								</div>
							</ng-template>
						</div>
					</section>
				</div>
			</form>
		</ng-template>
	</wcd-collapsible-section>
	<wcd-collapsible-section *ngIf="isSuppressionRuleEntityPanel"
		[sectionId]="collapsibleID.Details"
		[label]="'suppressionRules_edit_rule_comment_and_history_field' | i18n">
		<section class="wcd-margin-bottom wcd-padding-vertical">
			<editable-comments-list [comments]="history"
									(save)="addCommentToRule($event)"
									[rbac]="rbacSettingsDisabled"
									[isDisabled]="rule.isReadOnly"
									[readonly]="rule.isReadOnly"
									[isSaving]="isSavingComment">
			</editable-comments-list>
		</section>
	</wcd-collapsible-section>
</ng-container>
