import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MailClusterUtils } from '../mailbox/mail-cluster.utils';
import { RiskLevelEnum } from '../mailbox/mailbox-risk-level.values';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';

@ValueObject({
	singularName: 'Remediation Action Entity',
	pluralName: 'Remediation Action Entities',
	readonly: true,
})
export class RemediationActionEntity extends ModelBase {
	@EntityField({ data: 'id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField()
	entityTypeId: AirsEntityType;

	@EntityField() created: Date;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'threat_type' })
	threatType: string;

	@EntityField({ data: 'Threats' })
	threats: Array<string>;

	@EntityField({ data: 'hash_sha1' })
	sha1: string;

	@EntityField() path: string;

	@EntityField({ data: 'product_vendor' })
	productVendor: string;

	@EntityField() name: string;

	@EntityField() pid: number;

	@EntityField({ data: ['username', 'user'] })
	username: string;

	@EntityField() address: string;

	@EntityField({ data: 'bin_path' })
	binPath: string;

	@EntityField({ data: 'display_name' })
	displayName: string;

	@EntityField({ data: 'driver_type' })
	driverType: string;

	@EntityField({ data: 'command_line' })
	commandLine: string;

	@EntityField({ data: 'user_object_id' })
	identityId: string;

	@EntityField({ data: 'Url' })
	url: string;

	@EntityField({ data: 'MailboxPrimaryAddress' })
	mailboxPrimaryAddress: string;

	@EntityField({ data: 'Sender' })
	sender: string;

	@EntityField({ data: 'P1Sender' })
	p1Sender: string;

	@EntityField({ data: 'Recipient' })
	recipient: string;

	@EntityField({ data: 'Subject' })
	subject: string;

	@EntityField({ data: 'ClusterBy' })
	clusterBy: string;

	@EntityField({ data: 'ClusterByValue' })
	clusterByValue: string;

	@EntityField({ data: 'PhishCount' })
	phishCount: number;

	@EntityField({ data: 'MalwareCount' })
	malwareCount: number;

	@EntityField({ data: 'HighConfidencePhishCount' })
	highConfidencePhishCount: number;

	@EntityField({ data: 'SpamCount' })
	spamCount: number;

	@EntityField({ data: 'DeliveredCount' })
	deliveredCount: number;

	@EntityField({ data: 'JunkedCount' })
	junkedCount: number;

	@EntityField({ data: 'ReplacedCount' })
	replacedCount: number;

	@EntityField({ data: 'BlockedCount' })
	blockedCount: number;

	@EntityField({ data: 'MailboxCount' })
	mailboxCount: number;

	@EntityField({ data: 'NotInMailboxCount' })
	notInMailboxCount: number;

	@EntityField({ data: 'OnPremOrExternalCount' })
	onPremOrExternalCount: number;

	@EntityField({ data: 'MailCount' })
	mailCount: number;

	@EntityField({ data: 'Operation' })
	operation: string;

	@EntityField({ data: 'AccessRights' })
	accessRights: string;

	@EntityField({ data: 'ForwardingSmtpAddress' })
	forwardingSmtpAddress: string;

	@EntityField({ data: 'CreatedBy' })
	createdBy: string;

	@EntityField({ data: 'RiskLevel' })
	riskLevel: RiskLevelEnum;

	@EntityField({ data: 'ThreatsKeys' })
	threatsI18nKeys: Array<string>;

	@EntityField({ data: 'Sid' })
	sid: string;

	@EntityField({ data: 'AadUserId' })
	aadUserId: string;

	displayQuery: string;

	constructor(props) {
		super(props);
		this.displayQuery = MailClusterUtils.getDisplayQuery(this.clusterBy, this.clusterByValue);
	}
}
