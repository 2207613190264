import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataEntityType, ModelBase } from '@microsoft/paris';
import { Component, Input, OnChanges } from '@angular/core';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { NavItemModel } from '@wcd/shared';
import { ServiceSourceType } from '@wcd/domain';
import { EntityPanelsService } from '../../services/entity-panels.service';

@Component({
	selector: 'entity-name',
	template: `
		<ng-container *ngIf="entityType; else entityName">
			<wcd-shared-icon
				*ngIf="!hideIcon"
				[iconName]="
					iconName ||
					(entityType && (entityType.getIcon ? entityType.getIcon([entity]) : entityType.icon))
				"
				[iconClass]="iconClass"
				[ariaLabel]="displayAriaLabelOnIcon ? (iconName ||
					(entityType && (entityType.getIcon ? entityType.getIcon([entity]) : entityType.icon))) : null"
			></wcd-shared-icon>
			<ng-container *ngIf="!openEntityPanel; else entityPanel">
				<route-link
					*ngIf="entityRoute; else entityName"
					[trackId]="dataTrackId"
					[routeLink]="entityRoute"
					[className]="linkClass"
					[linkAriaLabel]="linkAriaLabel"
					[linkTabIndex]="linkTabIndex"
				>
					<ng-container *ngTemplateOutlet="entityName"></ng-container>
				</route-link>
			</ng-container>
		</ng-container>
		<ng-template #entityName>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="tooltipDisplay" >{{ entityDisplayName }}</span>
		</ng-template>
		<ng-template #entityPanel>
		<a
			(click)="onOpenEntityPanel()"
			(keydown)="onOpenEntityPanel()"
			[attr.aria-labelledby]="linkAriaLabel"
			[attr.tabindex]="linkTabIndex"
		>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="tooltipDisplay">{{
				entityDisplayName
			}}</span>
		</a>
		</ng-template>
	`,
})
export class EntityNameComponent<T extends ModelBase, U> implements OnChanges {
	@Input()
	entity?: T;

	@Input()
	entityTypeId?: string | number | DataEntityType<T>;

	@Input()
	entityName?: string;

	@Input()
	entitySource?: ServiceSourceType;

	@Input()
	showTooltip?: boolean = true;

	@Input()
	tooltip?: string;

	@Input()
	hideIcon: boolean;

	@Input()
	iconName?: string;

	@Input()
	iconClass?: string = 'field-value-icon';

	@Input()
	linkClass?: string = 'field-value-link';

	@Input()
	trackId?: string;

	@Input()
	openEntityPanel?: boolean = false;

	@Input()
	displayAriaLabelOnIcon: boolean = true;

	@Input()
	linkAriaLabel?: string;

	@Input() linkTabIndex: 0 | -1 | null= -1;

	static readonly entityNameDefaultCssClass = 'nowrap wcd-text-overflow-large';
	entityType: EntityType<T, U>;
	dataTrackId: string;
	entityDisplayName: string;
	tooltipDisplay: string;
	entityRoute: Partial<NavItemModel>;

	constructor(public globalEntityTypesService: GlobalEntityTypesService,
				private entityPanelsService: EntityPanelsService
	) {}

	ngOnChanges() {
		this.entityType = this.entityTypeId && this.globalEntityTypesService.getEntityType(this.entityTypeId);
		this.dataTrackId = this.trackId || (this.entityTypeId && this.entityTypeId + '_NameLinkToEntityPage');
		this.entityDisplayName =
			this.entityName || (this.entityType && this.entityType.getEntityName(this.entity));
		this.tooltipDisplay = this.showTooltip ? this.tooltip || this.entityDisplayName : null;
		this.entityRoute = this.getValidRouteLink();
	}

	onOpenEntityPanel(){
		this.entityPanelsService.showEntity(this.entityTypeId as DataEntityType, this.entity)
	}

	private getValidRouteLink(): Partial<NavItemModel> {
		const routeLink =
			this.entityType && this.entityType.getNavigationModel(this.entity, this.entitySource);

		if (routeLink && routeLink.sccInternalRedirectDefinition instanceof Function) {
			return routeLink;
		}

		if (!routeLink || (!routeLink.externalLink && !routeLink.routerLink)) {
			return null;
		}

		return routeLink;
	}
}
