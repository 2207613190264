
		<icon-switch
			*ngIf="isFabricReactOrWcdIcon; else fallback"
			[iconName]="parsedIconName"
			[contentClass]="iconDynamicClass"
			[ariaLabel]="ariaLabel"
			[styles]="styles"
		>
		</icon-switch>
		<ng-template #fallback>
			<i [className]="iconTypeClass" [innerText]="text" [ngClass]="iconDynamicClass"></i>
		</ng-template>
	