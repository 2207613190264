import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';

const reputationStatusMapping = {
	verified: 3,
	clean: 4,
	infected: 1,
	suspicious: 2,
	not_found: 5,
};

@ValueObject({
	singularName: 'Entity Report Provider',
	pluralName: 'Entity Report Providers',
	readonly: true,
})
export class AirsEntityReportProvider extends ModelBase {
	@EntityField({
		data: 'status',
		parse: (statusType: string) => reputationStatusMapping[statusType],
	})
	reputation: AirsEntityStatus;

	@EntityField({ data: 'source' })
	name: string;

	@EntityField() link?: string;

	@EntityField({ data: 'private_id', defaultValue: false })
	isInternal: boolean;

	@EntityField({ data: 'detected_by' })
	detectedBy?: string;

	@EntityField() categories?: string;

	@EntityField() summary?: Array<string>;

	@EntityField({
		data: 'scan_time',
		parse: scanTimeInSeconds => scanTimeInSeconds && scanTimeInSeconds * 1000,
	})
	scanTime?: Date;

	get hasDetails() {
		return this.link || this.detectedBy || this.categories || this.summary;
	}
}
