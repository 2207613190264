<section class="wcd-margin-large-bottom wcd-full-height wcd-flex-vertical wcd-padding-large-bottom" data-track-component-type="Settings" data-track-component="Sense Offboarding">
	<div *ngIf="isLicenseExpired" class="wcd-flex-0">
		<div class="wcd-margin-large-vertical wcd-flex-center-horizontal wcd-border-bottom">
			<div class="wcd-margin-large-right">
				<div class="suspended-icon">
					 <wcd-shared-icon iconName="shield"> </wcd-shared-icon >
					<span>!</span>
				</div>
			</div>
			<div style="max-width:800px;">
				<div>
					<h1 class="wcd-padding-bottom">{{"suspended.offboarding.title" | i18n}}</h1>
				</div>
				<div>
					<markdown [data]="'suspended.offboarding.description' | i18n"></markdown>
				</div>
				<div class="wcd-margin-large-vertical">
					<markdown [data]="'suspended.offboarding.contactSupport' | i18n"></markdown>
				</div>
			</div>
		</div>
	</div>
	<div class="wcd-full-height wcd-padding-large-left wcd-padding-large-bottom wcd-flex-1" data-track-component-type="Settings" data-track-component="Machine management">
		<div class="wcd-full-height">
			<section class="large-form wcd-border-bottom-light">
				<div class="form-group wcd-margin-bottom">
					<label for="os-type">
						{{onboarding ? ('endpointManagement.osType.onboarding.title' | i18n): ('endpointManagement.osType.offboarding.title' | i18n)}}
					</label>
					<div>
						<fancy-select [(ngModel)]="currentOsType"
							listId="os-type"
							name="os-type"
							[formatLabel]="getProcessLabel"
							[isBordered]="true"
							labelClass="dropdown-width-medium"
							[values]="osTypes"></fancy-select>
					</div>
				</div>
			</section>
			<section class="wcd-full-height wcd-scroll-vertical wcd-padding-vertical" [class.wcd-padding-right]="!isScc">
				<div class="large-form">
					<section [ngSwitch]="currentOsType?.processType.id">
						<ng-container *ngIf="onboarding; else offboarding">
							<sense-onboarding *ngSwitchCase="'sense'" [osType]="currentOsType"></sense-onboarding>
							<agent-onboarding *ngSwitchCase="'agent'" [isServer]="currentOsType.isServer"></agent-onboarding>
							<manage-non-windows-onboarding *ngSwitchCase="'nonWindows'"></manage-non-windows-onboarding>
							<manage-non-windows-onboarding *ngSwitchCase="'macOS'"></manage-non-windows-onboarding>
							<manage-linux-onboarding [isServer]="currentOsType.isServer" *ngSwitchCase="'linuxServer'"></manage-linux-onboarding>
							<manage-linux-onboarding [isServer]="currentOsType.isServer" *ngSwitchCase="'linuxWorkstation'"></manage-linux-onboarding>
							<manage-android-onboarding *ngSwitchCase="'android'"></manage-android-onboarding>
							<manage-non-windows-third-party *ngSwitchCase="'nonWindows3rdParty'"></manage-non-windows-third-party>
						</ng-container>
						<ng-template #offboarding>
							<sense-offboarding *ngSwitchCase="'sense'" [osType]="currentOsType"></sense-offboarding>
							<agent-offboarding *ngSwitchCase="'agent'"></agent-offboarding>
							<manage-non-windows-offboarding *ngSwitchCase="'nonWindows'"></manage-non-windows-offboarding>
							<manage-non-windows-offboarding *ngSwitchCase="'android'"></manage-non-windows-offboarding>
							<manage-non-windows-offboarding *ngSwitchCase="'macOS'"></manage-non-windows-offboarding>
							<manage-linux-offboarding [isServer]="currentOsType.isServer" *ngSwitchCase="'linuxServer'"></manage-linux-offboarding>
							<manage-linux-offboarding [isServer]="currentOsType.isServer" *ngSwitchCase="'linuxWorkstation'"></manage-linux-offboarding>
							<manage-non-windows-third-party *ngSwitchCase="'nonWindows3rdParty'"></manage-non-windows-third-party>
						</ng-template>
					</section>
					<section class="wcd-margin-large-vertical" *ngIf="tutorialsEnabled && onboarding">
						<tutorials-teaser></tutorials-teaser>
					</section>
				</div>
			</section>
		</div>
	</div>
</section>
