import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { GuidedQueryView } from './hunting-guided-query.models';
import { GuidedQueryFilter } from './guided-hunting-filter.value-object';

@ValueObject({ singularName: 'Guided hunting query', pluralName: 'Guided hunting queries' })
export class GuidedHuntingQuery extends ModelBase {
	@EntityField({ data: 'FilteredViews' })
	views: GuidedQueryView[];

	@EntityField({ data: 'Filters', arrayOf: GuidedQueryFilter, defaultValue: [] })
	filters: Array<GuidedQueryFilter>;
}
