import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { TimeRangeId, TimeRangeValue, SpecificTimeRangeValue } from '@wcd/date-time-picker';
import { IContextualMenuProps, IContextualMenuItem } from 'office-ui-fabric-react';
import { TimeRangesService } from '../../../../shared/services/time-ranges.service';
import { EntityStatistics } from '@wcd/domain';
import { PrettyNumberService } from '@wcd/prettify';
import { findLast } from 'lodash-es';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

@Component({
	selector: 'entity-statistics',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="statistics">
			<ng-container *ngIf="!summaryView; else summary">
				<div class="wcd-margin-small-bottom wcd-m365-typography">
					<div class="wcd-flex-horizontal">
						<div [ngClass]="{ 'wcd-flex-2': isLoadingOrganizationPrevalence }">
							<fab-shimmer
								[isDataLoaded]="!isLoadingOrganizationPrevalence"
								[styles]="{
									shimmerWrapper: { height: 28 }
								}"
							>
								<h4 class="wcd-margin-none-bottom">
									{{
										'file.prevalence.machinesOrganization'
											| i18n
												: {
														organizationPrevalence: transformToPrettyNumber(
															statistics.organizationPrevalence
														)
												  }
									}}
								</h4>
							</fab-shimmer>
						</div>
						<div class="range-selector wcd-flex-1 wcd-margin-xsmall-left">
							<fab-default-button
								[disabled]="isLoadingOrganizationPrevalence"
								[text]="getDateRangeContextItem(timeRange).text"
								[menuProps]="dateRangeMenuOptions"
								[styles]="{
									root: {
										backgroundColor: 'inherit',
										height: 28,
										paddingLeft: 4,
										paddingRight: 4
									},
									menuIcon: {
										height: 11
									}
								}"
							>
							</fab-default-button>
						</div>
					</div>
					<fab-shimmer
						[isDataLoaded]="!isLoadingOrganizationPrevalence"
						[styles]="secondaryTextShimmerStyle"
					>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="statistics.organizationPrevalence > 0">
								<ng-container
									*ngTemplateOutlet="
										genericFirstSeen;
										context: { ago: statistics.organizationFirstSeen }
									"
								>
								</ng-container>
								|
								<ng-container
									*ngTemplateOutlet="
										genericLastSeen;
										context: { ago: statistics.organizationLastSeen }
									"
								>
								</ng-container>
							</ng-container>
						</div>
					</fab-shimmer>
				</div>
				<ng-container *ngIf="statistics?.worldwidePrevalence != null">
					<div class="wcd-margin-small-bottom">
						<h4 class="wcd-margin-xsmall-bottom">
							{{
								'file.prevalence.machinesWorldwide'
									| i18n
										: {
												worldwidePrevalence: transformToPrettyNumber(
													statistics.worldwidePrevalence
												)
										  }
							}}
						</h4>
						<div
							*ngIf="statistics.worldwidePrevalence > 0"
							class="wcd-secondary-text wcd-margin-xsmall-top"
						>
							<ng-container
								*ngTemplateOutlet="
									genericFirstSeen;
									context: { ago: statistics.worldwideFirstSeen }
								"
							>
							</ng-container>
							|
							<ng-container
								*ngTemplateOutlet="
									genericLastSeen;
									context: { ago: statistics.worldwideLastSeen }
								"
							>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template #summary>
				<div class="wcd-flex-horizontal" style="justify-content: space-evenly">
					<ng-container *ngIf="withOrgPrevalence">
						<ng-container
							*ngTemplateOutlet="
								prevalenceData;
								context: {
									prevalence: statistics?.organizationPrevalence,
									title: 'file.prevalence.machinesOrganizationSummary' | i18n,
									firstSeen: statistics?.organizationFirstSeen,
									lastSeen: statistics?.organizationLastSeen
								}
							"
						></ng-container>
					</ng-container>
					<ng-container
						*ngTemplateOutlet="
							prevalenceData;
							context: {
								prevalence: statistics?.worldwidePrevalence,
								title: 'file.prevalence.machinesWorldwideSummary' | i18n,
								firstSeen: statistics?.worldwideFirstSeen,
								lastSeen: statistics?.worldwideLastSeen
							}
						"
					></ng-container>
				</div>
				<ng-template
					#prevalenceData
					let-prevalence="prevalence"
					let-title="title"
					let-firstSeen="firstSeen"
					let-lastSeen="lastSeen"
				>
					<div *ngIf="prevalence != null">
						<distribution-element
							[title]="title"
							[mainText]="transformToPrettyNumber(prevalence)"
						>
						</distribution-element>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="prevalence > 0">
								<ng-container
									*ngTemplateOutlet="genericFirstSeen; context: { ago: firstSeen }"
								>
								</ng-container>
								<br />
								<ng-container *ngTemplateOutlet="genericLastSeen; context: { ago: lastSeen }">
								</ng-container>
							</ng-container>
						</div>
					</div>
				</ng-template>
			</ng-template>
		</ng-container>
		<ng-template #genericFirstSeen let-ago="ago">
			{{ 'files.fields.firstSeen.title' | i18n }}:
			{{ ago | timeAgo }}
		</ng-template>
		<ng-template #genericLastSeen let-ago="ago">
			{{ 'files.fields.lastSeen.title' | i18n }}:
			{{ ago | timeAgo }}
		</ng-template>
	`,
	styles: [
		`
			.range-selector {
				height: 22px;
				min-width: 80px;
			}
		`,
	],
})
export class EntityStatisticsComponent implements OnInit {
	@Input() statistics: EntityStatistics;

	@Input() isLoadingOrganizationPrevalence: boolean;

	@Output() timeRangeChange: EventEmitter<SpecificTimeRangeValue> = new EventEmitter<
		SpecificTimeRangeValue
	>();

	@Input() summaryView: boolean;

	@Input() timeRange: TimeRangeId = TimeRangeId.month;

	@Input() prevalenceBuckets: number[] = null;

	dateRangeMenuOptions: IContextualMenuProps;
	selectedDateRange: IContextualMenuItem;
	withOrgPrevalence: boolean;

	secondaryTextShimmerStyle = {
		shimmerWrapper: {
			height: 10,
			maxWidth: '65%',
		},
		root: {
			marginTop: 4,
		},
	};

	constructor(
		private readonly timeRangeService: TimeRangesService,
		private readonly prettyNumberService: PrettyNumberService,
		flavorService: FlavorService
	) {
		this.withOrgPrevalence = flavorService.isEnabled(AppFlavorConfig.incidents.evidenceOrgPrevalence);
	}

	ngOnInit() {
		this.dateRangeMenuOptions = {
			items: this.timeRangeService.standard.map((timeRange: TimeRangeValue) => ({
				key: timeRange.id,
				text: timeRange.name,
				data: timeRange,
				onClick: () => this.onTimeRangeChange(timeRange.id),
			})),
		};
	}

	transformToPrettyNumber(numb: number) {
		if (this.prevalenceBuckets && this.prevalenceBuckets.length) {
			const matchingBucket = findLast(this.prevalenceBuckets, (bucket) => bucket < numb);
			if (matchingBucket != null) {
				return this.prettyNumberService.prettyNumber(matchingBucket) + '+';
			}
		}
		return this.prettyNumberService.prettyNumber(numb);
	}

	getDateRangeContextItem = (timeRangeId: TimeRangeId) =>
		this.dateRangeMenuOptions.items.find(
			(menuOption: IContextualMenuItem) => menuOption.key === timeRangeId
		);

	private onTimeRangeChange(timeRangeId: TimeRangeId) {
		this.timeRange = timeRangeId;
		this.timeRangeChange.emit(<SpecificTimeRangeValue>this.getDateRangeContextItem(timeRangeId).data);
	}
}
