
		<div
			class="wcd-flex-items-center"
			*ngIf="userStatusType && AadUserStatus[userStatusType] as userStatusData"
		>
			<wcd-shared-icon
				class="icon small-icon wcd-margin-small-right"
				[iconName]="userStatusData.iconName"
				[iconColor]="userStatusData.iconColor"
			>
			</wcd-shared-icon>
			{{
				i18nService.get('aadUser_status_' + userStatusData.nameI18nKey, null, true) ||
					userStatusData.nameI18nKey
			}}
		</div>
	