import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'data-export-info-box',
	template: `
			<div class="color-box-gray-100 wcd-flex-horizontal wcd-padding-small-all wcd-flex-center-vertical data-export-info-box">
				<div class="wcd-flex-none wcd-padding-small-right">
					<wcd-shared-icon iconName="Warning" class="small-icon"> </wcd-shared-icon >
				</div>
				<markdown class="wcd-flex-1">{{ 'endpointManagement.nonWindows.applications.dataExport.deprecationMessage' | i18n}}</markdown>
			</div>
		`,
	styleUrls:['./data-export-info-box.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,

})
export class DataExportSettingsInfoBoxComponent {}
