export enum DetectionSourceType {
	ThirdPartySensors = 0,
	WDATP = 1,
	AV = 2,
	SmartScreen = 4,
	Bitdefender = 16,
	CustomerTI = 32,
	Ziften = 64,
	Lookout = 128,
	SentinelOne = 256,
	OATP = 512,
	AutomatedInvestigation = 1024,
	ThreatExperts = 2048,
	CustomDetection = 4096,
	AATP = 8192,
	MCAS = 16384,
	MTP = 32768,
	AAD = 65536,
	MAPG = 131072,
	Manual = 262144,
	DLP = 524288,
	AppGPolicy = 1048576,
	AppGDetection = 2097152,
}
