import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'user-icon',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<span
			class="user-icon"
			[ngClass]="className"
			[style.background-image]="imageUrl ? 'url(' + imageUrl + ')' : null"
		>
			<wcd-shared-icon iconName="users.user" *ngIf="!imageUrl && !initials"> </wcd-shared-icon>
			{{ imageUrl ? '' : initials }}
		</span>
	`,
})
export class UserIconComponent {
	@Input() userName: string;
	@Input() imageUrl: string;
	@Input() className: string;

	get initials(): string {
		const initialsMatch: RegExpMatchArray = this.userName && this.userName.match(/\b(\w)/g);
		return initialsMatch ? initialsMatch.slice(0, 2).join('') : '';
	}
}
