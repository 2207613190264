var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { ThreatInfo } from '../common/threat-info.value-object';
import { AssetsStatistics } from '../common/assets-statistics.value-object';
import { EolState } from '../software-version/eol-version-state.enum';
import { VulnerabilityType } from '../common/vulnerability/vulnerability-type.enum';
import { TvmSupportedOsPlatform } from '../common/tvm-supported-os-platform.enum';
var ɵ0 = function (id) { return "products/product?productId=" + id; };
var Software = /** @class */ (function (_super) {
    __extends(Software, _super);
    function Software() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            required: true,
            parse: parseEntityName,
        }),
        __metadata("design:type", String)
    ], Software.prototype, "name", void 0);
    __decorate([
        EntityField({
            required: true,
            parse: parseEntityName,
        }),
        __metadata("design:type", String)
    ], Software.prototype, "vendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Software.prototype, "latestVersion", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Number)
    ], Software.prototype, "discoveredVulnerabilities", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Date)
    ], Software.prototype, "lastUpdated", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'riskScore',
        }),
        __metadata("design:type", Number)
    ], Software.prototype, "exposureScoreImprovement", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", ThreatInfo)
    ], Software.prototype, "threatInfo", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", AssetsStatistics)
    ], Software.prototype, "assetsStatistics", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], Software.prototype, "threats", void 0);
    __decorate([
        EntityField({
            required: false,
            defaultValue: true,
        }),
        __metadata("design:type", Boolean)
    ], Software.prototype, "isNormalized", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Software.prototype, "eolSoftwareState", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], Software.prototype, "eolSoftwareSinceDate", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Software.prototype, "hasEolVersions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Software.prototype, "hasUpcomingEolVersions", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Software.prototype, "category", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Software.prototype, "isKbProduct", void 0);
    __decorate([
        EntityField({
            data: 'vulnerabilityWorkarounds',
        }),
        __metadata("design:type", Array)
    ], Software.prototype, "workarounds", void 0);
    __decorate([
        EntityField({
            data: 'mostSevereVulnerabilityType',
        }),
        __metadata("design:type", String)
    ], Software.prototype, "mostSevereVulnerabilityType", void 0);
    __decorate([
        EntityField({
            data: 'zeroDayAssetsAtRisk',
        }),
        __metadata("design:type", Number)
    ], Software.prototype, "zeroDayAssetsAtRisk", void 0);
    __decorate([
        EntityField({
            data: 'patchReleaseDate',
        }),
        __metadata("design:type", Date)
    ], Software.prototype, "patchReleaseDate", void 0);
    __decorate([
        EntityField({
            required: false,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], Software.prototype, "productNeverMatched", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Software.prototype, "osPlatform", void 0);
    Software = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Software', pluralName: 'Software', endpoint: 'products', parseItemQuery: ɵ0, readonly: true }))
    ], Software);
    return Software;
}(EntityModelBase));
export { Software };
export { ɵ0 };
