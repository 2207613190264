
		<wcd-simple-pagination
			*ngIf="(dataViewModel.dataSet$ | async)"
			[total]="(dataViewModel.dataSet$ | async)?.count"
			[pageSize]="dataViewModel.pageSize$ | async"
			[className]="'command-bar-item-pagination'"
			[currentPageCount]="(dataViewModel.dataSet$ | async)?.items?.length"
			[page]="dataViewModel.page$ | async"
			[showOnSinglePage]="true"
			[shouldShowPageNumbers]="shouldShowPageNumbers"
			(pageChange)="onPageNumberChange($event.page)"
		>
		</wcd-simple-pagination>
	