<!-- tslint:disable:template-accessibility-label-for -->
<div class="large-form"
	data-track-component="AirsNotificationRuleEdit">
	<section class="form-section">
		<form *ngIf="notificationRule" #notificationRuleForm="ngForm" (submit)="saveRule()">
			<div class="form-group">
				<label for="notification-rule-name">Rule Name
					<wcd-help [text]="'airsNotificationRules.ruleName' | i18n"></wcd-help>
				</label>
				<input type="text"
					   id="notification-rule-name"
					   [(ngModel)]="notificationRule.name"
					   name="rule-name"
					   class="form-control"
					   autofocus
					   required/>
			</div>
			<div class="form-group">
				<label for="notification-rule-email">Email Address(es)
					<wcd-help [text]="'airsNotificationRules.ruleEmail' | i18n"></wcd-help>
				</label>
				<input type="text"
					   id="notification-rule-email"
					   class="form-control wcd-margin-small-bottom"
					   input-control
					   list-input
					   name="email"
					   #email="ngModel"
					   #emailEl
					   validateRegExp="email"
					   [(ngModel)]="notificationRule.email"/>
				<div class="error-message" *ngIf="notificationRule.email && !email.valid">Invalid email address
				</div>
				<ul class="inline-list unstyled wcd-margin-top-small">
					<li *ngFor="let email of notificationRule.emails">
				<span class="chip color-box-success">
					 <wcd-shared-icon [iconName]="'email'" class="small-icon"> </wcd-shared-icon >
					{{email}}
					<button type="button"
							data-track-id="RemoveEmail"
							data-track-type="Button"
							(click)="notificationRule.removeEmail(email); notificationRuleForm.form.markAsDirty()"
							[wcdTooltip]="'Remove this email address'">
						 <wcd-shared-icon [iconName]="'delete'" class="small-icon"> </wcd-shared-icon >
					</button>
				</span>
					</li>
				</ul>
				<button type="button"
						data-track-id="AddEmail"
						data-track-type="Button"
						[disabled]="!notificationRule.email || !email.valid"
						class="btn small-button color-box-primary"
						(click)="notificationRule.updateEmail() && (emailEl.focus() && notificationRuleForm.form.markAsDirty())">
					 <wcd-shared-icon iconName="Add" class="small-icon"> </wcd-shared-icon >
					Add another email
				</button>
			</div>
			<div *ngIf="conditionGroups">
				<div *ngIf="notificationRule.type.allowConditions" class="wcd-margin-large-bottom wcd-margin-top-large">
					<h4>Notify when Microsoft Defender for Endpoint matches:
						<wcd-help [text]="'airsNotificationRules.conditions.description' | i18n"></wcd-help>
					</h4>
					<ul class="form-list">
						<li *ngFor="let condition of notificationRule.conditions; let conditionIndex = index">
							<div class="wcd-margin-small-bottom">
								<fancy-select [ngModel]="condition.group$ | async"
											  [name]="'condition-group-' + conditionIndex"
											  [isBordered]="true"
											  labelClass="dropdown-width-medium"
											  [trackBy]="'id'"
											  (ngModelChange)="onConditionGroupChange(condition, $event)"
											  [placeholder]="'airsNotificationRules.selectConditionGroup' | i18n"
											  [label]="'name'"
											  [values]="getUnusedConditionGroups(condition)">
								</fancy-select>
							</div>
							<div class="wcd-margin-small-bottom">
								<wcd-checklist-dropdown
									*ngIf="condition.group"
									[buttonText]="getConditionValuesText(condition)"
									[name]="'condition-group-values-' + conditionIndex"
									[buttonTooltip]="'Select values'"
									[isFullWidth]="true"
									[(ngModel)]="condition.values"
									[values]="condition.group.getAllValues(condition.values)"></wcd-checklist-dropdown>
							</div>
							<button class="btn btn-inline"
									type="button"
									data-track-id="Remove"
									data-track-type="Button"
									*ngIf="notificationRule.conditions && notificationRule.conditions.length > 1"
									(click)="removeCondition(condition)"
								 wcdTooltip="Remove this condition">
								 <wcd-shared-icon [iconName]="'delete'"> </wcd-shared-icon >
								Remove
							</button>
						</li>
					</ul>
					<div class="form-list-actions"
						 *ngIf="notificationRule.conditions && unusedConditionGroups.length">
						<button class="btn btn-inline"
								data-track-id="AddCondition"
								data-track-type="Button"
								type="button"
								(click)="notificationRule.addCondition()">
							 <wcd-shared-icon iconName="Add"> </wcd-shared-icon >
							Add Condition
						</button>
					</div>
				</div>
				<div *ngIf="notificationRule.type.requireSchedule" class="wcd-margin-large-bottom small-form">
					<div class="form-group">
						<label for="report-type">Report Type
							<wcd-help [text]="'airsNotificationRules.schedule.reportType.description' | i18n"></wcd-help>
						</label>
						<wcd-checklist-dropdown
							id="report-type"
							[buttonText]="getScheduleReportTypeValuesText()"
							name="report-type"
							validateLength
							[(ngModel)]="notificationRule.reportSchedule.reportTypes"
							[values]="scheduleConfiguration.reports"></wcd-checklist-dropdown>
					</div>
					<div class="form-group">
						<label [for]="'report-range'">Get data for the last:</label>
						<fancy-select [(ngModel)]="notificationRule.reportSchedule.range"
							[name]="'report-range'"
							[listId]="'report-range'"
							[isBordered]="true"
							[label]="'name'"
							[values]="scheduleConfiguration.ranges">
						</fancy-select>
					</div>
					<section>
						<h4 class="section-title">Notify by the following schedule:</h4>
						<div class="form-group">
							<label>Repeats:</label>
							<fancy-select [(ngModel)]="notificationRule.reportSchedule.repeats"
								(ngModelChange)="notificationRule.reportSchedule.repeatEvery = 1"
								[name]="'report-repeats'"
								[listId]="'report-repeats'"
								[isBordered]="true"
								[label]="'name'"
								[values]="scheduleConfiguration.repeats">
							</fancy-select>
						</div>
						<div class="form-group">
							<label>Repeat Every:</label>
							<fancy-select [(ngModel)]="notificationRule.reportSchedule.repeatEvery"
								[name]="'report-repeat-every'"
								[listId]="'report-repeat-every'"
								[isBordered]="true"
								[values]="notificationRule.reportSchedule.repeats.repeatEvery">
							</fancy-select>
							{{notificationRule.reportSchedule.repeats[notificationRule.reportSchedule.repeatEvery
							=== 1 ? 'repeatEveryNameSingular' : 'repeatEveryNamePlural']}}
						</div>
						<div class="form-group" *ngIf="notificationRule.reportSchedule.repeats.requireDays">
							<label>Repeat On
								<wcd-help [text]="'airsNotificationRules.schedule.days.description' | i18n"></wcd-help>
							</label>
							<wcd-checklist-dropdown
								id="report-days"
							   [buttonText]="getScheduleReportDaysValuesText()"
							   name="report-days"
							   [buttonTooltip]="'Select days'"
							   validateLength
							   [(ngModel)]="notificationRule.reportSchedule.repeatDays"
							   [values]="scheduleConfiguration.repeatOn"></wcd-checklist-dropdown>
						</div>
						<div class="form-group">
							<label for="report-time">Time
								<wcd-help [text]="'airsNotificationRules.schedule.time.description' | i18n"></wcd-help>
							</label>
							<div class="form-control-wrapper-large">
								<input id="report-time"
									   type="text"
									   name="report-time"
									   class="form-control small"
									   required
									   validateHour
									   #reportTime="ngModel"
									   [(ngModel)]="notificationRule.reportSchedule.time"
									   placeholder="Time"/>
								<div class="error-message"
									 *ngIf="notificationRule.reportSchedule.time && !reportTime.valid">
									Invalid time,
									please enter the hour in 24-hour format
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>
			<div class="wcd-flex-horizontal">
				<div class="wcd-flex-1">
					<button class="btn btn-cancel"
						data-track-id="CancelEdit"
						data-track-type="Button"
						type="button"
						(click)="cancel.emit()">
						{{'buttons.cancel' | i18n}}
					</button>
				</div>
				<div class="wcd-flex-none">
					<button class="btn btn-primary"
							data-track-id="Save"
							data-track-type="Button"
							[disabled]="notificationRuleForm.form.pristine || !notificationRuleForm.form.valid || !notificationRule.isValid"
							type="submit">
						<i class="loader-icon" [hidden]="!notificationRule.isSaving"></i>
						{{ 'buttons.' + (notificationRule.isSaving ? 'saving' : 'save') | i18n }}
					</button>
				</div>
			</div>
		</form>
	</section>
</div>
