/* tslint:disable:template-click-events-have-key-events */
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Actor } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import { CmsService } from '../../../cms/services/cms.service';
import { I18nService } from '@wcd/i18n';


@Component({
	providers: [CmsService],
	selector: 'alerted-actor',
	templateUrl: './alerted-actor.component.html',
})
export class AlertedActorComponent implements OnChanges {
	@Input() actorName: string;

	actor: Actor;
	actorSubscription: Subscription;

	constructor(private readonly paris: Paris,
				private i18nService: I18nService,
				private readonly cmsService: CmsService) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.actorName && this.actorName) {
			this.actorSubscription = this.paris.getItemById(Actor, this.actorName).subscribe(
				(actor: Actor) => {
					this.actor = actor;
					this.actor.sections.sort((s1, s2) => s1.order - s2.order);
				}
			);
		}
	}

	onDownloadReport() {
		this.cmsService.downloadReportUsingSasToken(this.actor.linkToReport).subscribe();
	}
}
