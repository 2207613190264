import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleConditionIocType } from './suppression-rule-condition-ioc-type.enum';
import { SuppressionRuleConditionIdType } from './suppression-rule-condition-id-type.enum';
import { SuppressionRuleType } from './suppression-rule-type.enum';

@Entity({
	singularName: 'Suppression Rule Condition Type',
	pluralName: 'Suppression Rule Condition Types',
	values: [
		{
			id: SuppressionRuleConditionIdType.FileName,
			name: 'File name',
			type: SuppressionRuleConditionIocType.File,
			supportIocType: [ SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
			dataFieldName: 'FileName',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: true,
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.FolderPath,
			name: 'Folder path',
			type: SuppressionRuleConditionIocType.File,
			supportIocType: [ SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
			dataFieldName: 'FolderPath',
			propertyName: 'path',
			allowEdit: true,
			allowWildCard: true,
			pattern: 'folderPath',
			patternErrorText: 'Folder path must end with \\, *, or /',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Sha1,
			name: 'SHA1',
			type: SuppressionRuleConditionIocType.File,
			supportIocType: [ SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
			dataFieldName: 'Sha1',
			propertyName: 'sha1',
			allowEdit: false,
			pattern: 'sha1',
			patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Ip,
			name: 'IP',
			type: SuppressionRuleConditionIocType.Ip,
			supportIocType: [ SuppressionRuleConditionIocType.Ip],
			dataFieldName: 'Ip',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: false,
			pattern: 'ip',
			patternErrorText: 'Ip address is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Url,
			name: 'URL',
			type: SuppressionRuleConditionIocType.Url,
			supportIocType: [ SuppressionRuleConditionIocType.Url],
			dataFieldName: 'Url',
			propertyName: 'name',
			allowEdit: true,
			allowWildCard: true,
			patternErrorText: 'Url address is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.CommandLine,
			name: 'Command line',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: 'CommandLine',
			propertyName: 'command',
			allowEdit: true,
			allowWildCard: true,
			patternErrorText: 'Command line is not valid',
			supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.UserSid,
			name: 'User',
			type: SuppressionRuleConditionIocType.User,
			supportIocType: [ SuppressionRuleConditionIocType.User, SuppressionRuleConditionIocType.Process],
			dataFieldName: 'UserSid',
			propertyName: 'userSid',
			allowEdit: true,
			allowWildCard: false,
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ContentSha256,
			name: 'Script SHA256',
			type: SuppressionRuleConditionIocType.AmsiScript,
			supportIocType: [ SuppressionRuleConditionIocType.AmsiScript],
			dataFieldName: '20BA6007-0793-49E8-A609-10E33066DFC7',
			propertyName: 'SHA256',
			allowEdit: true,
			allowWildCard: false,
			pattern: 'sha256',
			patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.FileSha256,
			name: 'SHA256',
			type: SuppressionRuleConditionIocType.File,
			supportIocType: [ SuppressionRuleConditionIocType.File],
			dataFieldName: 'CE3AB7EE-6002-4ADE-B956-78E4327BCE7A',
			propertyName: 'SHA256',
			allowEdit: true,
			allowWildCard: false,
			pattern: 'sha256',
			patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ProcessSha256,
			name: 'SHA256',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: '3D6BE1D4-264C-4DAC-9DEE-AAAFDD5D498E',
			propertyName: 'SHA256',
			allowEdit: true,
			allowWildCard: false,
			pattern: 'sha256',
			patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.AmsiContent,
			name: 'Script content',
			type: SuppressionRuleConditionIocType.AmsiScript,
			supportIocType: [ SuppressionRuleConditionIocType.AmsiScript],
			dataFieldName: 'FE5EB4B1-2415-41C9-B351-99EB729A3926',
			allowWildCard: false,
			propertyName: 'script',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.UserName,
			name: 'User name',
			type: SuppressionRuleConditionIocType.User,
			supportIocType: [ SuppressionRuleConditionIocType.User],
			dataFieldName: '17FC6469-9914-48F0-80A6-68B1128EAC72',
			allowWildCard: false,
			propertyName: 'userName',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Consumer,
			name: 'Consumer',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: 'DD39759A-5102-4869-B67F-38F4F567717B',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'consumer',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Ess,
			name: 'Ess',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: '29CE382C-C613-4623-84F8-9317DA5C7A2A',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'ess',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.Namespace,
			name: 'Namespace',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: 'A27CAEEF-15A1-409C-A9A1-E829E7C18BAC',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'namespace',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.PossibleCause,
			name: 'Possible cause',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: '2997B7D2-3208-4AC6-83FF-C7CF74762615',
			allowEdit: true,
			allowWildCard: false,
			propertyName: 'possibleCause',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.TaskName,
			name: 'Task name',
			type: SuppressionRuleConditionIocType.ScheduledTask,
			supportIocType: [ SuppressionRuleConditionIocType.ScheduledTask],
			dataFieldName: 'FA8284B0-3F19-4895-9909-31C1F7DDBE48',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'task',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ParentProcessSha1,
			name: 'Parent process SHA1',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: 'C27C7233-8926-41D8-8E9D-59ED83E3C017',
			allowWildCard: false,
			allowEdit: false,
			pattern: 'sha1',
			patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
			propertyName: 'parentProcessSha1',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ParentProcessSha256,
			name: 'Parent process SHA256',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: '1BC91DAA-D8C9-490C-802B-81DE4A551327',
			allowWildCard: false,
			allowEdit: false,
			pattern: 'sha256',
			patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
			propertyName: 'parentProcessSha256',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ParentProcessFolderPath,
			name: 'Parent process folder path',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: '2B1598AB-4A8F-4505-8579-18404BDE7837',
			allowEdit: true,
			allowWildCard: true,
			pattern: 'folderPath',
			patternErrorText: 'Folder path must end with \\, *, or /',
			propertyName: 'parentProcessFolderPath',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ParentProcessFileName,
			name : 'Parent process file name',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: 'E88C36A5-FBA6-4968-8F75-E78DF4730FC0',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'parentProcessFileName',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.ParentProcessCommandLine,
			name: 'Parent process command line',
			type: SuppressionRuleConditionIocType.Process,
			supportIocType: [ SuppressionRuleConditionIocType.Process],
			dataFieldName: '7684343D-EF0B-4860-993A-B3B4D52C545B',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'parentProcessCommandLine',
			patternErrorText: 'Command line is not valid',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.TaskExecutables,
			name: 'Task executables',
			type: SuppressionRuleConditionIocType.ScheduledTask,
			supportIocType: [ SuppressionRuleConditionIocType.ScheduledTask],
			dataFieldName: '47497D74-F603-4AF3-B18E-001430F665E9',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'taskExecutables',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.LdapSearchQuery,
			name: 'LDAP search query',
			type: SuppressionRuleConditionIocType.LdapQuery,
			supportIocType: [ SuppressionRuleConditionIocType.LdapQuery],
			dataFieldName: '94f538af-b9da-405c-82ae-1d7bfc7f792e',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'ldapSearchQuery',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.LdapDistinguishedName,
			name: 'Distinguished name',
			type: SuppressionRuleConditionIocType.LdapQuery,
			supportIocType: [ SuppressionRuleConditionIocType.LdapQuery],
			dataFieldName: '8f289831-9013-4e1c-85f5-6b29c0eff340',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'distinguishedName',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.RegistryValueName,
			name: 'Name',
			type: SuppressionRuleConditionIocType.Registry,
			supportIocType: [ SuppressionRuleConditionIocType.Registry],
			dataFieldName: 'e28009cc-40a5-412f-b5da-a28642d75f13',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'registryValueName',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.RegistryKey,
			name: 'Key',
			type: SuppressionRuleConditionIocType.Registry,
			supportIocType: [ SuppressionRuleConditionIocType.Registry],
			dataFieldName: '2841587a-eb98-4e0d-8145-ff1a2ffa6c6f',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'registryValueKey',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.RegistryHive,
			name: 'Registry hive',
			type: SuppressionRuleConditionIocType.Registry,
			supportIocType: [ SuppressionRuleConditionIocType.Registry],
			dataFieldName: '4d541e70-8320-4dc3-b269-224cf55d99ea',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'registryValueHive',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.WmiQuery,
			name: 'WMI query',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: '16a86019-fc26-4d48-9c71-9442a67a6ebe',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'wmiQuery',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
		{
			id: SuppressionRuleConditionIdType.WmiClass,
			name: 'WMI class',
			type: SuppressionRuleConditionIocType.WmiEvent,
			supportIocType: [ SuppressionRuleConditionIocType.WmiEvent],
			dataFieldName: 'db88ebfb-be75-428a-af70-0e4d7681264b',
			allowEdit: true,
			allowWildCard: true,
			propertyName: 'wmiClass',
			supportRuleTypes: [SuppressionRuleType.MDE],
		},
	],
})
export class SuppressionRuleConditionType extends EntityModelBase<number> {
	@EntityField() name: string;
	@EntityField() supportIocType: SuppressionRuleConditionIocType[];
	@EntityField() type: SuppressionRuleConditionIocType;
	@EntityField() dataFieldName: string;
	@EntityField() propertyName: string;
	@EntityField({ defaultValue: true })
	allowEdit: boolean;
	@EntityField({ defaultValue: false })
	allowWildCard: boolean;
	@EntityField() pattern?: string;
	@EntityField() patternErrorText?: string;
	@EntityField() supportRuleTypes?: SuppressionRuleType[];
}
