import { NgModule } from '@angular/core';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { LiveResponseSessionEntityTypeService } from './services/live-response-session.entity-type.service';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { LiveResponseScriptUploadComponent } from './components/live-response-script-upload.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	declarations: [LiveResponseScriptUploadComponent],
	entryComponents: [LiveResponseScriptUploadComponent],
	providers: [LiveResponseSessionEntityTypeService],
})
export class LiveResponseSessionEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(LiveResponseSessionEntityTypeService);
	}
}
