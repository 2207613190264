import { Paris } from '@microsoft/paris';
import { of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { MachinePermissionsApiCall, OperatingSystemPlatformCategories, } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
export var MACHINE_RESPONSE_FEATURE_MAP = {
    ForensicsResponse: Feature.ResponseForensic,
    IsolationResponse: Feature.ResponseIsolation,
    ScanResponse: Feature.ResponseScan,
    RestrictExecutionResponse: Feature.ResponseExecutionPolicy,
    TroubleshootResponse: Feature.TroubleshootingMachine,
    LogsCollectionResponse: Feature.LogsCollection,
};
// TODO: This should really be moved to the backend
var supportedSelectiveIsolationSenseClientVersionMinorVersion = 3630;
var SELECTIVE_ISOLATION_OS_EXCLUSIONS = [
    OperatingSystemPlatformCategories.Linux,
    OperatingSystemPlatformCategories.macOS,
    OperatingSystemPlatformCategories.Android,
    OperatingSystemPlatformCategories.iOS
];
var MachineRequestsPermissionsService = /** @class */ (function () {
    function MachineRequestsPermissionsService(paris, features) {
        this.paris = paris;
        this.features = features;
    }
    MachineRequestsPermissionsService.prototype.getPermissions = function (machine) {
        try {
            return this.paris.apiCall(MachinePermissionsApiCall, machine, {
                allowCache: true,
            });
        }
        catch (error) {
            // This error happens when there's a problem parsing the machine, since all of this is synchronous Paris doesn't even create an Observable for it yet,
            // but still throw an error. However, getPermissions returns an Observable, and we don't want every one of the consumers of this API have to deal with both a catchError and a regular try/catch.
            // Therefore catching it "regularly" here and wrapping it in an Observable.
            return throwError(error);
        }
    };
    MachineRequestsPermissionsService.prototype.getFeaturesStatus = function (machine) {
        var _this = this;
        return this.getPermissions(machine).pipe(map(function (_a) {
            var ForensicsRequest = _a.ForensicsRequest, IsolationRequest = _a.IsolationRequest, ScanRequest = _a.ScanRequest, RestrictExecutionRequest = _a.RestrictExecutionRequest, TroubleshootRequest = _a.TroubleshootRequest, LogsCollectionRequest = _a.LogsCollectionRequest;
            return ({
                ForensicsResponse: _this._evaluateApiAndFeature(ForensicsRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.ForensicsResponse)),
                IsolationResponse: _this._evaluateApiAndFeature(IsolationRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.IsolationResponse)),
                ScanResponse: _this._evaluateApiAndFeature(ScanRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.ScanResponse)),
                RestrictExecutionResponse: _this._evaluateApiAndFeature(RestrictExecutionRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.RestrictExecutionResponse)),
                TroubleshootResponse: _this._evaluateApiAndFeature(TroubleshootRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.TroubleshootResponse)),
                LogsCollectionResponse: _this._evaluateApiAndFeature(LogsCollectionRequest, _this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.LogsCollectionResponse)),
            });
        }));
    };
    MachineRequestsPermissionsService.prototype.isResponseFeatureEnabled = function (machine) {
        var isAnyValueTrue = function (permissions) {
            return Object.values(permissions).some(function (val) { return val; });
        };
        return this.getFeaturesStatus(machine).pipe(map(function (permissions) { return isAnyValueTrue(permissions); }), catchError(function () { return of(false); }));
    };
    MachineRequestsPermissionsService.prototype.isSelectiveIsolationSupported = function (machine) {
        var doesMachineSupportSelectiveIsolation = function () {
            var _a = machine.senseClientVersion.split('.'), _major = _a[0], minor = _a[1];
            return parseInt(minor) >= supportedSelectiveIsolationSenseClientVersionMinorVersion;
        };
        var doesOSSupportSelectiveIsolation = function () {
            return !SELECTIVE_ISOLATION_OS_EXCLUSIONS.includes(machine.os.osPlatform.id) && machine.isManagedByMdatp;
        };
        return (this.features.isEnabled(Feature.ResponseSelectiveIsolation) &&
            doesMachineSupportSelectiveIsolation() &&
            doesOSSupportSelectiveIsolation());
    };
    MachineRequestsPermissionsService.prototype._evaluateApiAndFeature = function (result, featureEnabled) {
        return featureEnabled && (result === 'Unknown' || result === 'Allowed');
    };
    return MachineRequestsPermissionsService;
}());
export { MachineRequestsPermissionsService };
