import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Sha1, Sha256 } from './file-id.type';

@ValueObject({
	singularName: 'VirusTotal File Report',
	pluralName: '',
	readonly: true,
})
export class VirusTotalFileReport extends ModelBase {
	@EntityField() total: number;

	@EntityField() positives: number;

	@EntityField() permalink: string;

	@EntityField() sha1: Sha1;

	@EntityField() sha256: Sha256;
}
