import { hierarchy } from 'd3-hierarchy';
import { line } from 'd3-shape';
import { BaseType, event, select, Selection } from 'd3-selection';
import { Injectable } from '@angular/core';
import { RegExpService } from '@wcd/shared';
import { FilesService } from '../../files/services/files.service';
import { MachinesService } from '../../machines/services/machines.service';
import { IpsService } from '../../ips/services/ips.service';
import { AlertsService } from './alerts.service';
import { Router } from '@angular/router';
import { truncate } from 'lodash-es';

const duration = 300;
const numOfAllowedChildren = 5;
let idGen = 0;

@Injectable()
export class AlertIncidentGraphHelperService {
	constructor(
		private readonly filesService: FilesService,
		private readonly machinesService: MachinesService,
		private readonly ipsService: IpsService,
		private readonly alertsService: AlertsService,
		private readonly router: Router
	) {}

	public openFirstLevel(graphData) {
		return openFirstLevel(graphData);
	}

	public getGraphHeight(graphData) {
		return getGraphHeight(graphData);
	}

	public update(source, svg, tree, root, newRootTree = false) {
		return update(source, svg, tree, root, this.router, this.machinesService, newRootTree);
	}
	public buildGraphData(elements, depth) {
		const data = [];

		if (elements && elements.length) {
			for (let i = 0; i < elements.length; i++) {
				const element = elements[i];
				const item: any = {
					icon: getIconByType(element.PrimaryEntityType),
					parentIcon: getIconByType(element.SatalliteEntityType),
					parentSmallIcon: element.IsAssociatedWithAlerts ? getIconByType('Alert') : null,
					parentDisplayName: this.formatDisplayName(
						element.SatalliteEntityType,
						element.SatalliteEntityDisplayName
					),
					parentUrl: this.getEntityUrl(
						element.SatalliteEntityType,
						element.SatalliteEntityId,
						element.SatalliteEntityDisplayName
					),
					displayName: this.formatDisplayName(
						element.PrimaryEntityType,
						element.PrimaryEntityDisplayName
					),
					displayNameTitle: element.PrimaryEntityDisplayName,
					url: this.getEntityUrl(
						element.PrimaryEntityType,
						element.PrimaryEntityId,
						element.PrimaryEntityDisplayName
					),
					depth: depth,
					IsAssociatedWithAlerts: element.IsAssociatedWithAlerts,
					SatalliteEntityId: element.SatalliteEntityId,
					AdditionalText: getAdditionalText(element),
					Sha1: element.PrimaryEntityId,
				};

				if (element.SubElements) {
					item._children = this.buildGraphData(element.SubElements, depth + 1);
					item.hasChilds = true;
					item.childsCount = item._children.length;
				}

				data.push(item);
			}
		}

		return data;
	}

	private getEntityUrl(type, id, name?) {
		if (id) {
			switch (type) {
				case 'File':
				case 'Commandline':
					if (RegExpService.sha1.test(id)) {
						return this.filesService.getFileLink(id, null, name);
					}
					return null;
				case 'Machine':
					return this.machinesService.getMachineLink(id);
				case 'IP':
					return this.ipsService.getIpLink(id);
				case 'Alert':
					return this.alertsService.getAlertLink(id);
			}
		}

		return null;
	}

	private formatDisplayName(type, name) {
		if (!name) {
			return null;
		}
		if (type === 'Machine') {
			name = partsFilter(name, 1);
		}

		name = truncate(name, { length: 20 });

		return name;
	}
}

function addNewElementsToGraph(children, root) {
	children.forEach(function(childToInsert) {
		let shouldInsert = true;
		root.children.forEach(function(rootChild) {
			if (rootChild.data.Sha1 === childToInsert.Sha1) {
				shouldInsert = false;
			}
		});
		if (shouldInsert && root.children.length < numOfAllowedChildren) {
			addNodesToTree(root, childToInsert);
		}
	});
}

function addNodesToTree(parent, childToInsert) {
	const newNode = hierarchy(childToInsert);
	newNode.parent = parent;
	Object.assign(newNode, {
		depth: parent.depth + 1,
		height: parent.height - 1,
	});

	if (childToInsert.children || childToInsert._children) {
		(childToInsert.children || childToInsert._children).forEach(child => addNodesToTree(newNode, child));
	}

	if (!parent.children) {
		parent.children = [];
		parent.data.children = [];
	}

	parent.children.push(newNode);
	parent.data.children.push(newNode.data);
}

function diagonal(s, d) {
	return `M ${s.y} ${s.x} C ${(s.y + d.y) / 2} ${s.x}, ${(s.y + d.y) / 2} ${d.x}, ${d.y} ${d.x}`;
}

function getLinkPath(d, source, fixToParent = false) {
	// please notice x and y in the data obj are opposite here (y represents left-to-right position). x0 and y0 are in the correct order.
	if (
		(d.source.children && d.source.children.length == 1) ||
		(d.source._children && d.source._children.length == 1)
	) {
		let xVal = d.target.y;
		if (
			(d.target.children && d.target.children.length > 0) ||
			(d.target._children && d.target._children.length > 0)
		) {
			xVal -= 60;
		}
		return line()(
			[
				{
					x: d.source.y,
					y: d.source.x,
				},
				{
					x: xVal,
					y: d.target.x,
				},
			].map(point => <[number, number]>[+point.x, +point.y])
		);
	} else {
		if (fixToParent) {
			const o = {
				x: source.x0,
				y: source.y0,
			};
			return diagonal(o, o);
		}
		return diagonal(d.source, d.target);
	}
}

// Toggle children
function toggle(d) {
	let siblingsUpdated = false;
	if ((d.children && d.children.length === 0) || (d._children && d._children.length === 0)) {
		return;
	}

	if (d.children) {
		d._children = d.children;
		d.children = null;
	} else {
		siblingsUpdated = closeSiblings(d);
		d.children = d._children;
		d._children = null;
	}

	return siblingsUpdated;
}

function closeSiblings(d) {
	if (!d.parent) {
		return;
	}

	let updated = false;
	d.parent.children.forEach(function(d1) {
		if (d1 === d || !d1.children) {
			return;
		}
		d1._children = d1.children;
		d1.children = null;
		updated = true;
	});

	return updated;
}

function openFirstLevel(graphData) {
	if (!graphData || graphData.length === 0) {
		return;
	}

	for (let i = 0; i < graphData.length; i++) {
		openFirstLevel(graphData[i]._children);

		if (graphData[i].children) {
			break;
		}

		if (graphData[i]._children && graphData[i]._children.length > 0 && !graphData[i].children) {
			graphData[i].children = graphData[i]._children;
			graphData[i]._children = null;

			break;
		}
	}
}

function getIconByType(type) {
	switch (type) {
		case 'File':
			return '\uE7C3';
		case 'Process':
			return '\uE115';
		case 'Machine':
			return '\uE7F8';
		case 'IP':
			return '\uE93E';
		case 'Alert':
			return '\uE945';
		case 'Commandline':
			return '\uE756';
	}

	return null;
}

function getAdditionalText(element) {
	if (element.PrimaryEntityType === 'Commandline') {
		// Main single file
		if (element.IsAssociatedWithAlerts) {
			return 'ran command';
		} else {
			// same command on other machines
			if (element.SatalliteEntityType && element.SatalliteEntityType == 'Machine') {
				return 'ran same command';
			}

			// other commandlines on the first machine
			return 'ran command';
		}
	}
}

function getGraphDepth(graphData) {
	let depth = graphData.children
		? graphData.children.length
		: graphData._children
		? graphData._children.length
		: 1;
	if (graphData.children) {
		for (let i = 0; i < graphData.children.length; i++) {
			const childDepth = getGraphDepth(graphData.children[i]);
			if (childDepth > 0) {
				depth *= childDepth;
			}
		}
	} else if (graphData._children) {
		for (let j = 0; j < graphData._children.length; j++) {
			const childDepth = getGraphDepth(graphData._children[j]);
			if (childDepth > 0) {
				depth *= childDepth;
			}
		}
	}

	return depth;
}

function getGraphHeight(graphData) {
	const depth = getGraphDepth(graphData[0]);

	if (depth / 6 > 2) {
		return 750;
	}
	if (depth / 6 > 1) {
		return 600;
	}
	if (depth / 6 > 0.8) {
		return 550;
	}

	return 450;
}

function update(source, svg, tree, root, router, machinesService, newRootTree = false) {
	function getAriaLabelText(url, displayName, hasChildren) {
		const type = url.startsWith('/machines/') ? 'device' : 'file';
		return `${type} ${displayName} ${hasChildren ? 'with children' : ''}`;
	}

	// Compute the new tree layout.
	if (newRootTree) {
		svg.selectAll('g.graph-node').remove();
		svg.selectAll('path.graph-path').remove();
	}
	const treeData = tree(root);

	const nodes = treeData.descendants(),
		links = treeData.links();

	// Normalize for fixed-depth.
	nodes.forEach(function(d) {
		d.y = d.depth * 180;
	});

	// Update the nodes…
	const node = svg.selectAll('g.graph-node').data(nodes, function(d) {
		return d.id || (d.id = ++idGen);
	});

	// Enter any new nodes at the parent's previous position.
	const nodeEnter = node
		.enter()
		.append('g')
		.attr('class', 'graph-node')
		.attr('transform', function(d) {
			return 'translate(' + source.y0 + ',' + source.x0 + ')';
		})
		.on('click', d => {
			const siblingsUpdated = toggle(d);
			if (siblingsUpdated) {
				setTimeout(function() {
					update(d, svg, tree, root, router, machinesService, false);
				}, 350);
			} else {
				update(d, svg, tree, root, router, machinesService, false);
			}
		});

	// Parent node icon
	nodeEnter
		.append('text')
		.attr('class', function(d) {
			if (!d.data.hasChilds) {
				return 'icon graph-sat-sm-icon-sm';
			}

			return 'icon graph-sat-lg-icon-sm';
		})
		.attr('y', function(d) {
			if (!d.data.hasChilds) {
				return -6;
			}

			return -45;
		})
		.attr('x', function(d) {
			if (!d.data.hasChilds) {
				return 4;
			}

			return -100;
		})
		.text(function(d) {
			return d.data.parentIcon;
		});

	// Parent display name / link
	nodeEnter.each(function(d) {
		const thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);

		if (d.data.parentUrl) {
			const parentLink = thisGroup
				.append('svg:a')
				.attr('xlink:href', function(d) {
					return d.data.parentUrl;
				})
				.attr('aria-label', function(d) {
					return getAriaLabelText(d.data.parentUrl, d.data.parentDisplayName, d.data.hasChilds);
				})
				.append('svg:text')
				.attr('class', 'graph-link')
				.text(function(d) {
					return d.data.parentDisplayName;
				})
				.attr('dy', function(d) {
					return d.data.hasChilds ? -55 : -13;
				})
				.attr('dx', function(d) {
					return d.data.hasChilds ? -50 : 35;
				});

			if (!parentLink) {
				// $log.debug('no link!');
			} else {
				if (d.data.IsAssociatedWithAlerts) {
					parentLink.on('click', function(d) {
						if (event) {
							event.preventDefault();
							event.stopPropagation();
						}

						const machineUrl = machinesService.getMachineLink(d.data.SatalliteEntityId);
						router.navigateByUrl(machineUrl);
						//todo :  goToMachineFromAlert(d.data.SatalliteEntityId);
					});
				}
			}
		}
	});

	// Main entity
	const itemNode = nodeEnter.append('g');

	itemNode.each(function(d) {
		const thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);

		if (d.data.url) {
			thisGroup
				.append('svg:a')
				.attr('xlink:href', function(d) {
					return d.data.url;
				})
				.attr('aria-label', function(d) {
					return getAriaLabelText(d.data.url, d.data.displayName, d.data.hasChilds);
				})
				.append('svg:text')
				.attr('class', 'graph-link')
				.text(d => d.data.displayName)
				.attr('dy', function(d) {
					if (d.data.hasChilds) {
						if (d.data.parentIcon) {
							return 35;
						} else {
							if (d.depth === 0) {
								return 30;
							} else {
								if (
									(d.data.children && d.data.children.length === 0) ||
									(d.data._children && d.data._children.length === 0)
								) {
									return 30;
								} else {
									return 35;
								}
							}
						}
					} else {
						return 17;
					}
				})
				.attr('dx', function(d) {
					if (d.data.hasChilds) {
						if (d.data.parentIcon) {
							return -33;
						} else {
							if (d.depth === 0) {
								return -35;
							} else {
								if (
									(d.data.children && d.data.children.length === 0) ||
									(d.data._children && d.data._children.length == 0)
								) {
									return 35;
								} else if (
									(d.data.children && d.data.children.length === 1) ||
									(d.data._children && d.data._children.length === 1)
								) {
									return -32; //30;
								} else {
									return -30;
								}
							}
						}
					} else {
						return 50;
					}
				})
				.attr('text-anchor', function(d) {
					return d.data.hasChilds ? 'middle' : 'left';
				})
				.append('title')
				.text(function(d) {
					return d.data.displayNameTitle;
				});
		} else {
			thisGroup
				.append('text')
				.text(function(d) {
					return d.data.displayName;
				})
				.attr('dy', function(d) {
					return d.data.hasChilds ? 37 : 17;
				})
				.attr('dx', function(d) {
					return d.data.hasChilds ? -33 : 50;
				})
				.attr('text-anchor', function(d) {
					return d.data.hasChilds ? 'middle' : 'left';
				})
				.append('title')
				.text(function(d) {
					return d.data.displayNameTitle;
				});
		}

		if (d.data.AdditionalText) {
			thisGroup
				.append('text')
				.text(function(d) {
					return d.data.AdditionalText;
				})
				.attr('dy', function(d) {
					return d.data.hasChilds ? 57 : 37;
				})
				.attr('dx', function(d) {
					if (d.data.hasChilds) {
						if (d.data.parentIcon) {
							return -33;
						} else {
							if (d.depth === 0) {
								return -35;
							} else {
								if (
									(d.data.children && d.data.children.length === 0) ||
									(d.data._children && d.data._children.length === 0)
								) {
									return 35;
								} else {
									return 0;
								}
							}
						}
					} else {
						return 50;
					}
				})
				.attr('text-anchor', function(d) {
					return d.data.hasChilds ? 'middle' : 'left';
				})
				.append('title')
				.text(function(d) {
					return d.data.AdditionalText;
				});
		}

		thisGroup
			.append('text')
			.attr('class', function(d) {
				if (!d.data.hasChilds) {
					return 'icon graph-icon-sm-sm';
				}

				return 'icon graph-icon-lg-lg';
			})
			.attr('dx', function(d) {
				if (!d.data.hasChilds) {
					return 25;
				}

				if (d.data.parentIcon) {
					return -50;
				} else {
					if (d.depth === 0) {
						return -50;
					} else if (
						(d.data.children && d.data.children.length === 0) ||
						(d.data._children && d.data._children.length === 0)
					) {
						return 20;
					} else if (
						(d.data.children && d.data.children.length === 1) ||
						(d.data._children && d.data._children.length === 1)
					) {
						return -48; //15;
					} else {
						return -45;
					}
				}
			})
			.attr('dy', function(d) {
				if (!d.data.hasChilds) {
					return 20;
				}

				if (d.data.parentIcon) {
					return 15;
				} else {
					if (d.data.depth === 0) {
						return 17;
					} else if (
						(d.data.children && d.data.children.length === 0) ||
						(d.data._children && d.data._children.length === 0)
					) {
						return 15; //13;
					} else {
						return 15;
					}
				}
			})
			.text(function(d) {
				return d.data.icon;
			});

		// Attach icon and other symbols
		thisGroup
			.append('text')
			.attr('class', function(d) {
				return d.data.hasChilds ? 'icon graph-sat-lg-icon-esm' : 'icon graph-sat-sm-icon-esm';
			})
			.attr('dx', function(d) {
				if (d.data.hasChilds) {
					if (d.data.parentIcon) {
						return -70;
					} else {
						if (d.depth === 0) {
							return -20;
						} else if (
							(d.data.children && d.data.children.length === 1) ||
							(d.data._children && d.data._children.length === 1)
						) {
							return 30;
						} else {
							return 20;
						}
					}
				} else {
					return -17;
				}
			})
			.attr('dy', function(d) {
				if (d.data.hasChilds) {
					if (d.data.parentIcon) {
						return -70;
					} else {
						if (d.depth === 0) {
							return -10;
						} else {
							return -65;
						}
					}
				} else {
					return -8;
				}
			})
			.text(function(d) {
				return d.data.parentSmallIcon;
			});
	});

	nodeEnter
		.append('path')
		.attr('class', 'graph-icon-path')
		.attr('stroke', 'gray')
		.attr('fill', 'none')
		.attr('d', function(d) {
			const data = d.data.hasChilds
				? [
						{
							x: '-83',
							y: '-46',
						},
						{
							x: '-83',
							y: '0',
						},
						{ x: '-56', y: '0' },
				  ]
				: [
						{
							x: '15',
							y: '-8',
						},
						{
							x: '15',
							y: '12',
						},
						{ x: '25', y: '12' },
				  ];
			if (d.data.parentIcon) {
				return line()(data.map(point => <[number, number]>[+point.x, +point.y]));
			}
			return null;
		});

	nodeEnter
		.append('circle')
		.attr('r', '4px')
		.style('fill', function(d) {
			return d.data._children && d.data._children.length > 0 ? 'lightsteelblue' : '#fff';
		});

	// Transition nodes to their new position.
	const nodeUpdate = nodeEnter
		.transition()
		.duration(duration)
		.attr('transform', function(d) {
			return 'translate(' + d.y + ',' + d.x + ')';
		});

	nodeUpdate
		.select('circle')
		.attr('r', '4px')
		.style('fill', function(d) {
			return d.data._children && d.data._children.length > 0 ? 'lightsteelblue' : '#fff';
		});

	nodeUpdate.select('text').style('fill-opacity', 1);

	// Transition exiting nodes to the parent's new position.
	const nodeExit = node
		.exit()
		.transition()
		.duration(duration)
		.attr('transform', function(d) {
			return 'translate(' + source.y + ',' + source.x + ')';
		})
		.remove();

	nodeExit.select('circle').attr('r', '4px');

	nodeExit.select('text').style('fill-opacity', 1e-6);

	// Update the links…
	const link = svg.selectAll('path.graph-path').data(links, function(d) {
		return d.target.id;
	});

	// Enter any new links at the parent's previous position.
	const linkEnter = link
		.enter()
		.insert('path', 'g')
		.attr('class', function(d) {
			return 'graph-path';
		})
		.attr('d', d => getLinkPath(d, source, true));

	// Transition links to their new position.
	linkEnter
		.transition()
		.duration(duration)
		.attr('d', d => getLinkPath(d, source));

	// Transition exiting nodes to the parent's new position.
	link.exit()
		.transition()
		.duration(duration)
		.attr('d', d => getLinkPath(d, source, true))
		.remove();

	// Stash the old positi	ons for transition.
	nodes.forEach(function(d) {
		d.x0 = d.x;
		d.y0 = d.y;
	});
}

function partsFilter(input, length) {
	if (!input || length <= 0) {
		return '';
	}

	const parts = input.split('.');
	if (parts.length === 1) {
		return input;
	}

	let result = '';
	for (let i = 0; i < parts.length && i < length; i++) {
		result += '.' + parts[i];
	}

	return result.slice(1);
}
