// tslint:disable:template-click-events-have-key-events
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { RequestStatus } from '@wcd/domain';
import { ActionCenterStatus } from './models/action-center-status.model';
import { CommentModel } from '../../../../../comments/models/comment.model';

export interface ActionCenterSubmission {
	readonly dateTime: Date;
	readonly status: RequestStatus;
	readonly allowCancellation: boolean;
}

@Component({
	selector: 'action-center-section',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host {
				display: block;
			}
		`,
	],
	template: `
		<table
			class="wcd-full-width wcd-margin-small-bottom"
			data-track-component="MachineActionCenterSection"
		>
			<tr>
				<th>{{ 'machines.actionCenter.statusActions.table.headers.time' | i18n }}</th>
				<th>{{ 'machines.actionCenter.statusActions.table.headers.status' | i18n }}</th>
				<th *ngIf="submission.allowCancellation"></th>
			</tr>

			<tr class="wcd-font-size-s-plus">
				<td>
					<span>{{ submission.dateTime | date: 'medium' }}</span>
				</td>
				<td>
					<ng-container [ngTemplateOutlet]="statusCellTemplate"></ng-container>
				</td>
				<td *ngIf="submission.allowCancellation">
					<fab-action-button
						data-track-id="Cancel"
						data-track-type="Button"
						[text]="'machines.actionCenter.statusActions.cancelButton' | i18n"
						[iconProps]="{ iconName: 'Cancel' }"
						(onClick)="onCancelSubmissionActionClicked.emit()"
					>
					</fab-action-button>
				</td>
			</tr>
		</table>

		<comments-list [comments]="comments" defaultCommentIcon="Clock"></comments-list>

		<ng-template #statusCellTemplate>
		<a
				tabindex="0"
			    *ngIf="status.onClick; else statusContent"
			    data-track-id="ActionCenterActionClick"
			    data-track-type="Action"
				(click)="status.onClick()"
			    (keydown.enter) ="status.onClick()"
			>
				<ng-container [ngTemplateOutlet]="statusContent"></ng-container>
			</a>

			<ng-template #statusContent>
				<fab-icon *ngIf="status.iconName" [iconName]="status.iconName"></fab-icon>
				<wcd-help *ngIf="status.helpText; else simpleText" [text]="status.helpText">{{
					status.text
				}}</wcd-help>

				<ng-template #simpleText>
					<span>{{ status.text }}</span>
				</ng-template>
			</ng-template>
		</ng-template>
	`,
})
export class ActionCenterSectionComponent {
	@Input() submission: ActionCenterSubmission;
	@Input() comments: ReadonlyArray<CommentModel>;
	@Input() status: ActionCenterStatus;

	@Output() readonly onCancelSubmissionActionClicked = new EventEmitter<void>();
}
