var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    if (query && query.where && query.where['useV3Api']) {
        return config.data.serviceUrls.k8s;
    }
    // We want to call the cluster url directly because SCC proxy doesn't allow for large requests,
    // and this request size can be up to 250 MB
    var baseUrl = (query && query.where && query.where['automatedIrBaseUrl']) ||
        config.data.serviceUrls.automatedIr;
    if (query && query.where && query.where['useV2Api']) {
        return baseUrl + "/v2";
    }
    return baseUrl;
}, ɵ1 = function (config, query) {
    if (query && query.where && query.where['useV3Api']) {
        return 'cloud/live_response/library/get_files';
    }
    return 'live_response/library/get_files';
}, ɵ2 = function (item, entity, config, options) {
    return options && options.params && options.params.useV3Api ? 'cloud/live_response/library/upload_file' : 'live_response/library/upload_file';
}, ɵ3 = function (items) {
    var item = items[0];
    return item.useV3Api ? 'cloud/live_response/library/delete_file' : 'live_response/library/delete_file';
}, ɵ4 = function (script, serializedItem, entity, config, serializationData) {
    var fieldData = {
        description: script.description,
        has_parameters: script.hasParams,
        parameters_description: script.paramsDescription,
        override_if_exists: serializationData && serializationData.overwriteExisting,
    };
    for (var k in fieldData) {
        if (fieldData[k] === undefined || fieldData[k] === null) {
            delete fieldData[k];
        }
    }
    if (script.file) {
        var formData = new FormData();
        formData.append('file[]', script.file);
        if (Object.keys(fieldData).length) {
            formData.append('file_fields', JSON.stringify(fieldData));
        }
        return formData;
    }
    return {
        file_fields: fieldData,
    };
}, ɵ5 = function (files) {
    if (files.length > 1) {
        throw new Error('Only one file can be removed at a time.');
    }
    var file = files[0];
    return {
        file_id: file.id,
        last_updated_time: file.updatedAt,
        file_name: file.fileName,
    };
};
var LiveResponseScript = /** @class */ (function (_super) {
    __extends(LiveResponseScript, _super);
    function LiveResponseScript() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'file_id' }),
        __metadata("design:type", Number)
    ], LiveResponseScript.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'file_name' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "fileName", void 0);
    __decorate([
        EntityField({ data: 'originating_user' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "creatingUser", void 0);
    __decorate([
        EntityField({ data: 'creation_time' }),
        __metadata("design:type", Date)
    ], LiveResponseScript.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'last_updated_user' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "updatingUser", void 0);
    __decorate([
        EntityField({ data: 'last_updated_time' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "updatedAt", void 0);
    __decorate([
        EntityField({ data: 'description' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'blob_text' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "blobText", void 0);
    __decorate([
        EntityField({ data: 'sha256' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "sha256", void 0);
    __decorate([
        EntityField({ data: 'has_parameters' }),
        __metadata("design:type", Boolean)
    ], LiveResponseScript.prototype, "hasParams", void 0);
    __decorate([
        EntityField({ data: 'parameters_description' }),
        __metadata("design:type", String)
    ], LiveResponseScript.prototype, "paramsDescription", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", File)
    ], LiveResponseScript.prototype, "file", void 0);
    LiveResponseScript = __decorate([
        Entity({
            singularName: 'Script',
            pluralName: 'Scripts',
            baseUrl: ɵ0,
            endpoint: ɵ1,
            allItemsEndpointTrailingSlash: false,
            parseSaveQuery: ɵ2,
            parseRemoveQuery: ɵ3,
            serializeItem: ɵ4,
            getRemoveData: ɵ5,
            // 5 minutes
            timeout: 1000 * 60 * 5,
        })
    ], LiveResponseScript);
    return LiveResponseScript;
}(EntityModelBase));
export { LiveResponseScript };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
