import { Injectable } from '@angular/core';

export interface LoadScriptsOptions {
	loadConcurrently?: boolean;
}

export interface LoadScriptsRelativeOptions extends LoadScriptsOptions {
	baseUrl: string;
	relativePaths: string[];
}

export interface LoadScriptsAbsoluteOptions extends LoadScriptsOptions {
	urls: string[];
}
/**
 * **INTERNAL**
 */
@Injectable({ providedIn: 'root' })
export class DynamicScriptService {
	async loadScripts(options: LoadScriptsRelativeOptions | LoadScriptsAbsoluteOptions): Promise<void> {
		const uris =
			(options as LoadScriptsAbsoluteOptions).urls ||
			(options as LoadScriptsRelativeOptions).relativePaths.map(
				relativePath => `${(options as LoadScriptsRelativeOptions).baseUrl}/${relativePath}`
			);

		if (options.loadConcurrently) {
			await Promise.all(uris.map(uri => this.loadJavascriptDynamically(uri)));
			return;
		}

		for (const path of uris) {
			await this.loadJavascriptDynamically(path);
		}
	}

	/**
	 * Load and return a require.js instance.
	 * If one is already loaded - it'll be reused.
	 * @param baseUrl the url to load require.js from.
	 * @returns Tuple with the require.js instance, and a boolean indicating if this is the first time it was loaded.
	 */
	async loadRequireJS(url: string): Promise<any> {
		if (!window['require']) {
			if (!url) {
				throw new Error("Failed to load require.js. no 'url' provided");
			}
			await this.loadScripts({ urls: [url] });
		}

		return window['require'];
	}

	private loadJavascriptDynamically(uri: string): Promise<void> {
		return new Promise(resolve => {
			let scriptEl = document.querySelector<HTMLScriptElement>(`script[src="${uri}"]`);
			if (!scriptEl) {
				scriptEl = document.createElement('script');
				scriptEl.type = 'text/javascript';
				scriptEl.src = uri;
				document.body.appendChild(scriptEl);
			}

			scriptEl.addEventListener('load', () => resolve());
		});
	}
}
