/* tslint:disable:template-mouse-events-have-key-events */
import { ChangeDetectorRef, OnInit, ElementRef, AfterViewInit, } from '@angular/core';
import { Location } from '@angular/common';
import { NavigationEnd, Router, UrlHandlingStrategy } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { MainAppStateService } from '../services/main-app-state.service';
import { MainNavConfigService } from '../services/main-nav-config.service';
import { MainNavService } from '../services/main-nav.service';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeaturesService, AppContextService } from '@wcd/config';
var MainNavComponent = /** @class */ (function () {
    function MainNavComponent(router, mainAppStateService, changeDetectionRef, authService, location, urlHandlingStrategy, mainNavConfigService, mainNavService, featuresService, appContextService, document) {
        var _this = this;
        this.router = router;
        this.mainAppStateService = mainAppStateService;
        this.changeDetectionRef = changeDetectionRef;
        this.authService = authService;
        this.location = location;
        this.urlHandlingStrategy = urlHandlingStrategy;
        this.mainNavConfigService = mainNavConfigService;
        this.mainNavService = mainNavService;
        this.featuresService = featuresService;
        this.appContextService = appContextService;
        this.document = document;
        this.subNavsState = {};
        this.submenuScrollAmount = 0;
        this.submenuTop = 0;
        this.showItems = true;
        this.isExpanded = false;
        mainAppStateService.state$.subscribe(function (mainAppState) {
            _this.mainAppState = mainAppState;
            _this.changeDetectionRef.markForCheck();
        });
        merge(router.events.pipe(filter(function (event) { return event instanceof NavigationEnd; })), this.mainAppStateService.navChange$).subscribe(function () {
            setTimeout(function () { return _this.changeDetectionRef.markForCheck(); });
        });
        location.subscribe(function () { return setTimeout(function () { return _this.changeDetectionRef.markForCheck(); }); });
        this.featuresService.featureChanged$.subscribe(function () {
            _this.mainNavConfigService.update();
            _this.navSections = _this.mainNavConfigService.mainNavConfig;
            _this.changeDetectionRef.markForCheck();
        });
        this.isMobileSize = window.innerWidth < 480;
    }
    MainNavComponent.prototype.onResize = function (event) {
        this.calculateHasScroll();
        this.isMobileSize = window.innerWidth < 480;
    };
    MainNavComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setNavItems();
        this.changeDetectionRef.markForCheck();
        //accessibility requirement MAS1.4.13, requires a mechanism (here: hitting escape) to dismiss additional content that hides the underneath content (here: the menu pop ups)
        window.addEventListener('keyup', function (e) {
            if (e.keyCode === 27) {
                //escape key
                _this.closeAllSubNavsBySections();
                _this.changeDetectionRef.markForCheck();
            }
        });
    };
    MainNavComponent.prototype.ngAfterViewInit = function () {
        this.calculateHasScroll();
    };
    MainNavComponent.prototype.onFolderClickMobile = function (navItem, $event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey)
            return;
        $event.preventDefault();
        if (!this.isExpanded) {
            this.toggleMainNav();
        }
        this.toggleSubNavState(navItem, true);
    };
    MainNavComponent.prototype.onFolderClick = function (navItem, $event) {
        if ($event.ctrlKey || $event.shiftKey || $event.altKey)
            return;
        $event.preventDefault();
        if (this.mainAppState.mainNavIsExpanded) {
            navItem.isExpanded = !navItem.isExpanded;
            this.toggleSubNavState(navItem);
        }
        else {
            var urlTree = this.router.parseUrl(navItem.routerLink[0]);
            var isUpgradedLink = this.urlHandlingStrategy.shouldProcessUrl(urlTree);
            if (!isUpgradedLink)
                this.location.go(navItem.routerLink[0]);
            this.router.navigate(navItem.routerLink);
            navItem.isExpanded = false;
        }
    };
    MainNavComponent.prototype.onNavFocus = function ($event, navItem) {
        $event.stopPropagation();
        this.focusElement(navItem.id);
    };
    MainNavComponent.prototype.closeAllSubNavsBySections = function () {
        var _this = this;
        this.navSections.forEach(function (navSection) { return _this.closeAllSubNavs(navSection.items); });
    };
    MainNavComponent.prototype.closeAllSubNavs = function (navItems) {
        var _this = this;
        navItems
            .filter(function (navItem) { return navItem.isFolder; })
            .forEach(function (navItemFolder) {
            navItemFolder.isExpanded = false;
            _this.subNavsState[navItemFolder.name].visibility = 'hidden';
        });
    };
    MainNavComponent.prototype.toggleMainNav = function () {
        var _this = this;
        this.closeAllSubNavsBySections();
        setTimeout(function () {
            _this.mainAppStateService.toggleMainNav(!_this.mainAppState.mainNavIsExpanded);
        }, 100);
    };
    MainNavComponent.prototype.toggleFloatingSubNav = function ($event, navItem, value) {
        $event.stopPropagation();
        if (navItem.isFolder && !this.mainAppState.mainNavIsExpanded) {
            if (value) {
                this.updateSubmenuTop($event.currentTarget, navItem);
            }
            navItem.isExpanded = value; //only controls the caret icon
            this.toggleSubNavState(navItem, value);
        }
    };
    MainNavComponent.prototype.toggleSubNavState = function (navItem, value) {
        this.subNavsState[navItem.name].visibility =
            value || this.subNavsState[navItem.name].visibility === 'hidden' ? 'shown' : 'hidden';
    };
    MainNavComponent.prototype.openSubNav = function (navItem) {
        navItem.isExpanded = true;
        this.subNavsState[navItem.name].visibility = 'shown';
    };
    MainNavComponent.prototype.closeSubNav = function (navItem) {
        navItem.isExpanded = false;
        this.subNavsState[navItem.name].visibility = 'hidden';
    };
    MainNavComponent.prototype.isActive = function (navItem) {
        var _this = this;
        return (!navItem.isAlias &&
            (this.location.path().startsWith('/' + navItem.route) ||
                (navItem.children && navItem.children.some(function (subNavItem) { return _this.isActive(subNavItem); }))));
    };
    MainNavComponent.prototype.getAllNavItem = function () {
        return [].concat.apply([], this.navSections.map(function (section) { return section.items; }));
    };
    /**** Keyboard accessibility:
    - Definitions
        Visually we have the following types of "nav bar items" (ignoring the currently not used "sections" feature):
        - "root item": a link in the root level
        - "folder item": appears in the root level, when "clicked" shows/hides a "sub nav" that holds "sub items"
        - "sub nav": the section that holds the "sub items"
        - "sub item": a link inside a "sub nav"


    - Design goals
        1.	We need to provide single tab stop for the entire navbar, and it should put the focus on the first root level item in the navbar.
        2.	We should then enable easy navigation inside the navbar using the 4 arrow keys.
        3.	Pressing enter on any nav item will perform the action that a normal click provide, be it hitting a link or showing/hiding sub navs (this requirement is already implemented as the browser treats "enter" as if a mouse click happened).
        4.	We need to handle cyclic navigation where required.
        5.	Our current navbar design offers two different modes: collapsed and expanded, so we must support both:
            a.	Collapsed mode:
                i.	Entering and leaving sub menus using right & left arrows respectively. auto focusing on the first sub item when entering sub menu.
                ii.	Navigating between nav items (either root or sub items) in the *same level* using up & down keys, with cyclic behavior on the edges (on the root level edges as well as the sub nav edges)
            b.	Expanded mode:
                i.	Opening / closing sub menus using the enter key (already implemented with the usual 'click' handler)
                ii.	Expanded mode is one vertical column of items, so navigating between nav items (root items & sub items) using up & down keys will also transition between root items and sub items. Cyclic behavior will happen only on the root level edges.
        6.	We need to make sure that a focused element shows a proper visual feedback (in terms of color, contrast etc.).

    - implementation overview
        - We are not changing the current navbar config data structure. This data structure is an array of section definitions, where each section holds its root items, and some root items (folders) hold their sub items (folder's nav items)
        - We see that each nav bar item renders a single anchor tag (<a>)
        - We implement goal #1 by setting the attribute tabIndex=0 on the anchor tag of the first root level, and setting tabIndex=-1 on all the rest anchor tags. we also make sure all other native tab stopping elements (anchors, buttons, etc.) have tabIndex=-1 to eliminate them as tab stops.
        - We implement goals #2 to #6 using the following approach:
            - we make sure every anchor has its own unique id attribute (will help us to later focus on the required anchor)
            - we make sure every anchor emits event on either button up/down/right/left event
            - we then have 4 corresponding event handlers (onNavItemArrowRight, onNavItemArrowLeft...) that given the current navbar mode (collapsed/expanded) and the source element that produced the event, decide and set focus to the proper nav item (anchor).
    */
    /* user clicked right while nav item is in focus  */
    MainNavComponent.prototype.onNavItemArrowRight = function (_a) {
        var $event = _a.$event, navItem = _a.navItem, sectionIndex = _a.sectionIndex, navItemIndex = _a.navItemIndex, parentNavItem = _a.parentNavItem, subNavItemIndex = _a.subNavItemIndex;
        $event.stopPropagation();
        if (navItem.isFolder &&
            !this.mainAppState.mainNavIsExpanded &&
            this.subNavsState[navItem.name].visibility !== 'shown') {
            this.updateSubmenuTop($event.currentTarget, navItem);
            /* the menu is collapsed, it's a folder, and the subNav is hidden, so open the subNav:  */
            this.openSubNav(navItem);
            /* and set the focus to the first child */
            var children = navItem.children.filter(function (child) { return !child.isDisabled; });
            var firstChildId = children.length && children[0].id;
            if (firstChildId) {
                this.focusElement(firstChildId);
            }
        }
    };
    /* user clicked left while nav item is in focus  */
    MainNavComponent.prototype.onNavItemArrowLeft = function (_a) {
        var $event = _a.$event, navItem = _a.navItem, sectionIndex = _a.sectionIndex, navItemIndex = _a.navItemIndex, parentNavItem = _a.parentNavItem, subNavItemIndex = _a.subNavItemIndex;
        $event.stopPropagation();
        if (!navItem.isFolder &&
            !this.mainAppState.mainNavIsExpanded &&
            parentNavItem &&
            this.subNavsState[parentNavItem.name].visibility === 'shown') {
            /* the menu is collapsed, it is a folder's leaf (part of a subNav), we should close the subNav and set the focus on the parent:  */
            this.closeSubNav(parentNavItem);
            this.focusElement(parentNavItem.id);
        }
    };
    MainNavComponent.prototype.onNavItemArrowUp = function (_a) {
        var $event = _a.$event, navItem = _a.navItem, sectionIndex = _a.sectionIndex, navItemIndex = _a.navItemIndex, parentNavItem = _a.parentNavItem, subNavItemIndex = _a.subNavItemIndex;
        $event.stopPropagation();
        $event.preventDefault();
        if (!this.mainAppState.mainNavIsExpanded) {
            var previousSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'previous')
                .id;
            this.focusElement(previousSiblingId);
        }
        else {
            var prev = void 0;
            var prevIsOpen = void 0;
            if (subNavItemIndex === undefined &&
                (prev = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'previous')) &&
                (prevIsOpen = prev.isFolder) &&
                this.subNavsState[prev.name].visibility === 'shown') {
                //if root item, and previous item is an *open* folder, go to this folder's last item
                var prevLastChildId = prev.children[prev.children.length - 1].id;
                this.focusElement(prevLastChildId);
            }
            else {
                var nextSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'previous', false).id;
                this.focusElement(nextSiblingId);
            }
        }
    };
    MainNavComponent.prototype.onNavItemArrowDown = function (_a) {
        var $event = _a.$event, navItem = _a.navItem, sectionIndex = _a.sectionIndex, navItemIndex = _a.navItemIndex, parentNavItem = _a.parentNavItem, subNavItemIndex = _a.subNavItemIndex;
        $event.stopPropagation();
        $event.preventDefault();
        if (!this.mainAppState.mainNavIsExpanded) {
            var nextSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'next').id;
            this.focusElement(nextSiblingId);
        }
        else {
            if (subNavItemIndex === undefined &&
                this.subNavsState[navItem.name] &&
                this.subNavsState[navItem.name].visibility === 'shown') {
                //if folder and open, focus first child
                var children = navItem.children.filter(function (child) { return !child.isDisabled; });
                var firstChildId = children.length && children[0].id;
                if (firstChildId) {
                    this.focusElement(firstChildId);
                }
            }
            else {
                var nextSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'next', false).id;
                this.focusElement(nextSiblingId);
            }
        }
    };
    MainNavComponent.prototype.onScroll = function (event) {
        var _this = this;
        requestAnimationFrame(function () {
            _this.submenuScrollAmount =
                (event.currentTarget && event.currentTarget.scrollTop) || _this.submenuScrollAmount;
        });
    };
    MainNavComponent.prototype.updateSubmenuTop = function (submenuItem, navItem) {
        if (this.selectedNavItem === navItem) {
            return;
        }
        var submenuHeight = submenuItem.querySelector('ul.nav-submenu').offsetHeight;
        var menuHeight = this.navScrollable.nativeElement.offsetHeight;
        this.submenuScrollAmount = this.navScrollable.nativeElement.scrollTop || 0;
        var submenuParentTop = submenuItem.offsetTop;
        this.selectedNavItem = navItem;
        var originalPos = submenuParentTop - this.submenuScrollAmount;
        if (menuHeight - submenuHeight - originalPos < 0) {
            this.submenuTop = menuHeight - submenuHeight;
        }
        else {
            this.submenuTop = Math.max(originalPos, 0);
        }
    };
    MainNavComponent.prototype.calculateHasScroll = function () {
        if (!this.navScrollable || !this.navScrollable.nativeElement) {
            this.hasScrollbar = false;
            return;
        }
        var scrollbarElement = this.navScrollable.nativeElement;
        this.hasScrollbar = scrollbarElement.scrollHeight - scrollbarElement.offsetHeight > 0;
    };
    /**
     * Returns the NavItemModel ("sibling") that is the next (or previous) sibling of the provided NavItemModel ("source") in the navSection data structure, where the source is provided via it's address in the array: the section index, the item index, and, if the item is a sub item, the sub item index
     * @param {number} sectionIndex the section index of the source NavItemModel
     * @param {number} navItemIndex (if the source is root level item) the index of the source NavItemModel, or (if the source is a sub nav item) the index of the source' parent NavItemModel
     * @param {number} subNavItemIndex (if the source is sub item) the index of the source NavItemModel
     * @param {'next' | 'previous'} direction if to return the next or previous sibling
     * @param {boolean} cycleSubNavItems
     * 		true (default): the function returns a sub nav item's sibling in a cyclic fashion (e.g. the next sibling of the last item in a sub group is the first item in the group) a cyclic through
     * 		false: e.g. the next sibling of the last item in a sub group is the next sibling of the item's parent
     * @returns {NavItemModel}
     */
    MainNavComponent.prototype.getSibling = function (sectionIndex, navItemIndex, subNavItemIndex, direction, cycleSubNavItems) {
        if (cycleSubNavItems === void 0) { cycleSubNavItems = true; }
        var itemsArr;
        var sectionLength = this.navSections[sectionIndex].items.length;
        if (subNavItemIndex === undefined && navItemIndex === 0 && direction === 'previous') {
            if (sectionIndex === 0) {
                // (cyclic behaviour on the root level)
                itemsArr = this.navSections[this.navSections.length - 1].items;
            }
            else {
                itemsArr = this.navSections[--sectionIndex].items;
            }
            return itemsArr[itemsArr.length - 1];
        }
        else if (subNavItemIndex === undefined &&
            navItemIndex === sectionLength - 1 &&
            direction === 'next') {
            if (sectionIndex === this.navSections.length - 1) {
                // (cyclic behaviour on the root level)
                itemsArr = this.navSections[0].items;
            }
            else {
                itemsArr = this.navSections[++sectionIndex].items;
            }
            return itemsArr[0];
        }
        else {
            if (subNavItemIndex !== undefined && !cycleSubNavItems) {
                //sub item, no cycling, if we're on the edges need to escape to the parent level
                if (direction === 'next' &&
                    subNavItemIndex === this.navSections[sectionIndex].items[navItemIndex].children.length - 1) {
                    //last sub item and requesting next, escape to the parent's next sibling
                    return this.getSibling(sectionIndex, navItemIndex, undefined, 'next');
                }
                else if (direction === 'previous' && subNavItemIndex === 0) {
                    //first sub item and requesting previous, escape to the parent
                    return this.navSections[sectionIndex].items[navItemIndex];
                }
            }
            var currItemIndex = void 0;
            if (subNavItemIndex === undefined) {
                currItemIndex = navItemIndex;
                itemsArr = this.navSections[sectionIndex].items;
            }
            else {
                currItemIndex = subNavItemIndex;
                itemsArr = this.navSections[sectionIndex].items[navItemIndex].children;
            }
            return itemsArr[(currItemIndex + (direction === 'next' ? 1 : -1) + itemsArr.length) % itemsArr.length];
        }
    };
    MainNavComponent.prototype.focusElement = function (elementId) {
        var el = this.document.getElementById(elementId);
        if (el) {
            el.focus();
        }
    };
    MainNavComponent.prototype.onNavItemsKeydown = function (e) {
        if (e.keyCode === 9) {
            //on pressing Tab (or Shift+Tab), and before navigating away from the nav, we should close any open sub menu
            this.closeAllSubNavsBySections();
        }
    };
    /*******(end of Keyboard accessibility******/
    MainNavComponent.prototype.setNavItems = function () {
        var _this = this;
        this.navSections = this.mainNavConfigService.mainNavConfig;
        this.navSections.forEach(function (navSection) { return _this.setChildrenNavItem(navSection.items); });
    };
    MainNavComponent.prototype.setChildrenNavItem = function (navItems) {
        var _this = this;
        navItems.forEach(function (navItem) {
            if (navItem.children)
                _this.subNavsState[navItem.name] = {
                    visibility: 'hidden',
                    parent: navItem,
                };
        });
    };
    return MainNavComponent;
}());
export { MainNavComponent };
