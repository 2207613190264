/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { KeyValue } from '@angular/common';
import { Alert, CyberEvent, CyberEventActionTypeName } from '@wcd/domain';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Paris } from '@microsoft/paris';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { CyberEventsUtilsService } from '../services/cyber-events-utils.service';
import { FULL_DATE_WITH_MILLISECONDS_FORMAT } from '@wcd/localization';
import { CyberEventPanelOptions } from './cyber-event.entity-panel.component';
import { CyberEventsActionTypesService } from '../services/cyber-events-action-types.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';

enum CollapsibleID {
	EventDetails = 'cyber-events-entity-panel-event-details',
	EventGraph = 'cyber-events-entity-panel-event-graph',
	AlertDetails = 'cyber-events-entity-panel-alert-details',
	AlertDescription = 'cyber-event-entity-panel-alert-description',
	AlertRecommendedAction = 'cyber-event-entity-panel-alert-recommended-action',
}

@Component({
	selector: 'cyber-events-entity-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cyber-events.entity-panel.component.html',
})
export class CyberEventsEntityPanelComponent extends EntitiesPanelComponentBase<
	CyberEvent,
	CyberEventPanelOptions
> {
	alertActionTypeId = CyberEventActionTypeName.Alert;
	entityPanelsService: EntityPanelsService;
	extraDataLoaded: Set<CyberEvent> = new Set<CyberEvent>();
	extraDataLoadRequestSent: Set<string> = new Set<string>();
	eventsMap: Map<CyberEvent['id'], CyberEvent>;
	readonly fullDateFormat = FULL_DATE_WITH_MILLISECONDS_FORMAT;
	private officeTenantPrefix: string;
	collapsibleID = CollapsibleID;

	constructor(
		private readonly injector: Injector,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly appInsightsService: AppInsightsService,
		private readonly cyberEventsUtilsService: CyberEventsUtilsService,
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService
	) {
		super();
		this.entityPanelsService = this.injector.get(EntityPanelsService);
	}

	setEntities(entities: Array<CyberEvent>): void {
		super.setEntities(entities);
		this.appInsightsService.trackEvent('Multiple events panel', {
			entitiesCount: entities.length,
		});

		this.eventsMap = new Map(entities.map<[CyberEvent['id'], CyberEvent]>(entity => [entity.id, entity]));

		this.entities
			.filter(
				(event: CyberEvent) =>
					!this.extraDataLoaded.has(event) && !this.extraDataLoadRequestSent.has(event.id)
			)
			.forEach((event: CyberEvent) => {
				if (!(event.machine && event.machine.id)) {
					const err = new Error(
						`Event graph cannot get extended data for event because machine id is missing: ${event}`
					);
					console.error(err);
					this.appInsightsService.trackException(err);
					return;
				}
				if (this.cyberEventsActionTypesService.isOneCyberEvent(event)) {
					return;
				}
				this.extraDataLoadRequestSent.add(event.id);
				this.addExtraDataSubscription(
					this.paris
						.getItemById(
							CyberEvent,
							event.id,
							null,
							this.cyberEventsUtilsService.createParamsForGetEvent(event, {
								machineId: event.machine.id,
								actionTime: event.actionTime,
								relatedAlert: event.relatedAlert,
								reportId: event.reportId,
							})
						)
						.subscribe(
							(_event: CyberEvent) => {
								this.eventsMap.set(event.id, _event);
								this.extraDataLoaded.add(event);
								this.extraDataLoadRequestSent.delete(event.id);
								this.changeDetectorRef.markForCheck();
							},
							() => {
								this.extraDataLoadRequestSent.delete(event.id);
								this.changeDetectorRef.markForCheck();
							}
						)
				);
			});

		this.changeDetectorRef.markForCheck();
		this.cyberEventsUtilsService.trackCyberEventPanelEvent('CyberEventEntityPanelEvent', TrackingEventType.SidePaneToggleButton, this.entities)
	}

	openAlertPanel(alert: Alert) {
		this.entityPanelsService.showEntityById(Alert, alert.id, null, {
			back: {
				onClick: () => this.entityPanelsService.closeEntityPanel(Alert),
			},
		});
	}

	sortEventsByDateDesc = (a: KeyValue<string, CyberEvent>, b: KeyValue<string, CyberEvent>): number => {
		return b.value.actionTime.valueOf() - a.value.actionTime.valueOf();
	};
}
