import { Injectable, OnDestroy } from '@angular/core';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { Notification, NotificationConfig } from '../../../notifications/models/notification.model';
import { RemediationActionsBackendService } from './remediation-actions.backend.service';
import { BehaviorSubject, Observable, of, Subscription, timer } from 'rxjs';
import {
	catchError,
	distinctUntilChanged,
	map,
	mergeMap,
	publishReplay,
	refCount,
	share,
	switchMap,
	tap,
} from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RemediationActionTypeActionCount, MdeUserRoleActionEnum, WcdPortalParisConfigData } from '@wcd/domain';
import { RemediationActionsService } from './remediation-actions.service';
import { DataSet, Paris, ParisConfig } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import { differenceBy } from 'lodash-es';
import { AjaxError } from 'rxjs/ajax';
import { AirsEntitiesService } from '../../airs_entities/services/airs-entities.service';
import { FeaturesService, Feature } from '@wcd/config';
import { PollingService } from '@wcd/config';
import { AppContextService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

const PENDING_NOTIFICATION_GROUP = 'Pending Actions';
export const PENDING_USER_NOTIFICATION_ID = 'pendingUser';
const PENDING_RESOURCE_NOTIFICATION_ID = 'pendingResource';
const PENDING_ACTIONS_PAGE_URL: Array<string> = ['/pending'];
const PENDING_ACTIONS_CENTER_PAGE_URL: Array<string> = ['/action-center', 'pending'];
const PENDING_ACTIONS_REFRESH_RATE = 60 * 1000;
const PENDING_ACTIONS_ERROR_REFRESH_RATE = 10 * 1000;

@Injectable()
export class PendingActionsService implements OnDestroy {
	private _previousPendingActions: PendingActionTypes;
	private _pendingActions$: Observable<PendingActionTypes | Error>;
	private _startPendingActionsTimer$: BehaviorSubject<any> = new BehaviorSubject(null);
	private isRemediationDisabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private timerSub: Subscription;
	isRemediationDisabled$: Observable<boolean> = this.isRemediationDisabledSubject$.asObservable();
	remediationDisabledMessage: string;

	get pendingActions$(): Observable<PendingActionTypes | Error> {
		if (!this._pendingActions$) this.setPendingActionsObservable();

		return this._pendingActions$;
	}

	constructor(
		private remediationActionsBackendService: RemediationActionsBackendService,
		private entitiesService: AirsEntitiesService,
		private remediationActionsService: RemediationActionsService,
		private notificationsService: NotificationsService,
		private paris: Paris,
		private dialogsService: DialogsService,
		private authService: AuthService,
		private featuresService: FeaturesService,
		private pollingService: PollingService,
		private appContextService: AppContextService,
		private i18nService: I18nService
	) {}

	/**
	 * Resets the polling, so that pending actions are polled immediately, then the timer is restarted.
	 */
	resetPolling(): void {
		this._startPendingActionsTimer$.next(null);
	}

	private setPendingActionsObservable(): Observable<PendingActionTypes | Error> {
		return (this._pendingActions$ = new Observable<PendingActionTypes | Error>(observer => {
			let resetSubscription: Subscription;
			const sub: Subscription = this._startPendingActionsTimer$
				.asObservable()
				.pipe(
					switchMap(() => this.pollingService.poll(0, PENDING_ACTIONS_REFRESH_RATE)),
					mergeMap(() => {
						return this.getPendingRemediationActionTypes().pipe(
							map((typeCounts: Array<RemediationActionTypeActionCount>) =>
								this.getPendingActionTypes(typeCounts)
							),
							tap((data: PendingActionTypes) => {
								this.setNotifications(data);
								this._previousPendingActions = data;
								this.isRemediationDisabledSubject$.next(false);
								this.remediationDisabledMessage = null;
							}),
							catchError((error: Error | AjaxError) => {
								resetSubscription = this.handleDataLoadError(<AjaxError>error, true);
								this.notificationsService.setNotification(PENDING_USER_NOTIFICATION_ID, null);
								this.notificationsService.setNotification(
									PENDING_RESOURCE_NOTIFICATION_ID,
									null
								);
								this._previousPendingActions = null;
								return of(error);
							})
						);
					}),
					distinctUntilChanged(
						(previousValue: PendingActionTypes, currentValues: PendingActionTypes) => {
							return (
								this.serializePendingActions(previousValue) ===
								this.serializePendingActions(currentValues)
							);
						}
					)
				)
				.subscribe(observer);

			return () => {
				sub.unsubscribe();
				resetSubscription && resetSubscription.unsubscribe();
				this.notificationsService.setNotification(PENDING_USER_NOTIFICATION_ID, null);
				this.notificationsService.setNotification(PENDING_RESOURCE_NOTIFICATION_ID, null);
			};
		}).pipe(
			share(),
			publishReplay(1),
			refCount()
		));
	}

	getPendingRemediationActionTypes(): Observable<Array<RemediationActionTypeActionCount>> {
		return this.paris
			.callQuery<RemediationActionTypeActionCount>(
				RemediationActionTypeActionCount,
				Object.assign(
					{
						allItemsEndpointTrailingSlash: false,
					},
					this.featuresService.isEnabled(Feature.AirsApiOffloading)
						? {
								endpoint: 'investigations/pending_actions_summary',
								baseUrl: (config: ParisConfig<WcdPortalParisConfigData>) =>
									config.data.serviceUrls.threatIntel,
						  }
						: {
								endpoint: 'remediation_actions/pending_actions',
								baseUrl: (config: ParisConfig<WcdPortalParisConfigData>) =>
									config.data.serviceUrls.automatedIr,
						  }
				)
			)
			.pipe(map((dataSet: DataSet<RemediationActionTypeActionCount>) => dataSet.items));
	}

	private serializePendingActions(pendingActionTypes: PendingActionTypes): string {
		const actionIds: Array<string> =
			pendingActionTypes.all &&
			pendingActionTypes.all.reduce(
				(actionIds: Array<string>, actionType: RemediationActionTypeActionCount) => {
					return actionIds.concat(
						`${actionType.actionCount}_${actionType.remediationActionType.id}`
					);
				},
				[]
			);

		return actionIds ? actionIds.join(',') : '';
	}

	setNotifications(pendingActions: PendingActionTypes) {
		if (this.featuresService.isEnabled(Feature.DisablePortalRemediationNotifications)) {
			this.notificationsService.setHiddenHighPriorityNotificationId(PENDING_USER_NOTIFICATION_ID);
		}

		if (this.shouldDisplayNotifications()) {
			if (
				!PendingActionsService.pendingActionsAreEqual(
					this._previousPendingActions && this._previousPendingActions.user,
					pendingActions.user
				)
			)
				this.notificationsService.setNotification(
					PENDING_USER_NOTIFICATION_ID,
					pendingActions.user.length
						? this.getPendingUserActionsNotification(pendingActions.user)
						: null
				);

			if (
				!PendingActionsService.pendingActionsAreEqual(
					this._previousPendingActions && this._previousPendingActions.resource,
					pendingActions.resource
				)
			)
				this.notificationsService.setNotification(
					PENDING_RESOURCE_NOTIFICATION_ID,
					pendingActions.resource.length
						? this.getPendingResourceActionsNotification(pendingActions.resource)
						: null
				);
		}
	}

	shouldDisplayNotifications() {
		return (
			!(this.appContextService.isSCC && this.featuresService.isEnabled(Feature.PortedSccPages)) &&
			this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.remediationActions)
		);
	}

	private static pendingActionsAreEqual(
		pendingActions1: Array<RemediationActionTypeActionCount>,
		pendingActions2: Array<RemediationActionTypeActionCount>
	): boolean {
		if (!pendingActions1 && !pendingActions2) return true;

		if (!pendingActions1 || !pendingActions2) return false;

		if (pendingActions1.length !== pendingActions2.length) return false;

		const difference = differenceBy(
			pendingActions1,
			pendingActions2,
			(action: RemediationActionTypeActionCount) => {
				return `${action.actionCount}_${action.remediationActionType.id}`;
			}
		);

		return !difference.length;
	}

	private getPendingActionTypes(data: Array<RemediationActionTypeActionCount>): PendingActionTypes {
		const pendingActions: PendingActionTypes = {
			user: [],
			resource: [],
			all: [],
		};

		data.forEach((actionType: RemediationActionTypeActionCount) => {
			if (actionType.actionCount) {
				// The backend sends actions with count=0. It's hard to filter data using Python.
				(actionType.remediationActionType.isUserPending
					? pendingActions.user
					: pendingActions.resource
				).push(actionType);
				pendingActions.all.push(actionType);
			}
		});

		return pendingActions;
	}

	/**
	 * Compares two collections of pending action types. Returns true if both have the same types and counts, false otherwise.
	 * @param actionTypes1 {Array<RemediationActionTypeActionCount>}
	 * @param actionTypes2 {Array<RemediationActionTypeActionCount>}
	 */
	static comparePendingActionTypes(
		actionTypes1: Array<RemediationActionTypeActionCount>,
		actionTypes2: Array<RemediationActionTypeActionCount>
	): boolean {
		if (actionTypes1.length !== actionTypes2.length) return false;

		const sortedActionTypes1 = actionTypes1.slice(0).sort(sortTypes),
			sortedActionTypes2 = actionTypes2.slice(0).sort(sortTypes);

		for (let i = 0; i < actionTypes1.length; i++) {
			if (
				sortedActionTypes1[i].remediationActionType !== sortedActionTypes2[i].remediationActionType ||
				sortedActionTypes1[i].actionCount !== sortedActionTypes2[i].actionCount
			)
				return false;
		}

		return true;

		function sortTypes(a, b) {
			if (a.remediationActionType === b.remediationActionType) return 0;

			return a.remediationActionType.id > b.remediationActionType.id ? 1 : -1;
		}
	}

	getPendingUserActionsNotification(data: Array<RemediationActionTypeActionCount>): NotificationConfig {
		const totalActionsCount = data.reduce((total, actionCount) => total + actionCount.actionCount, 0);

		if (!totalActionsCount) return null;

		const notificationTitle =
			data.length === 1
				? data[0].remediationActionType.pendingText(data[0].actionCount)
				: this.i18nService.get('remediation_pendingActions_attentionRequired', {
						itemCount: totalActionsCount,
				  });

		return {
			id: PENDING_USER_NOTIFICATION_ID,
			title: notificationTitle,
			count: totalActionsCount,
			group: PENDING_NOTIFICATION_GROUP,
			iconName: 'investigations.status.paused',
			iconClassName: 'color-text-warning-dark',
			link: this.featuresService.isEnabled(Feature.ActionHistory)
				? PENDING_ACTIONS_CENTER_PAGE_URL
				: PENDING_ACTIONS_PAGE_URL,
			priority: 3,
			actions: [],
			contents:
				data.length === 1
					? null
					: data.map((actionTypeCount: RemediationActionTypeActionCount) => {
							return {
								text: actionTypeCount.remediationActionType.pendingText(
									actionTypeCount.actionCount
								),
							};
					  }),
		};
	}

	getPendingResourceActionsNotification(data: Array<RemediationActionTypeActionCount>): NotificationConfig {
		const totalActionsCount = data.reduce(function(total, actionCount) {
			return total + actionCount.actionCount;
		}, 0);

		if (!totalActionsCount) return null;

		const notificationTitle =
			data.length === 1
				? data[0].remediationActionType.pendingText(data[0].actionCount)
				: `${totalActionsCount} Pending resource items`;

		return {
			id: PENDING_RESOURCE_NOTIFICATION_ID,
			group: PENDING_NOTIFICATION_GROUP,
			title: notificationTitle,
			count: totalActionsCount,
			iconName: 'hosts.uncoveredHost',
			iconClassName: 'partial',
			link: PENDING_ACTIONS_PAGE_URL,
			priority: 4,
			actions: [
				{
					name: 'Retry All',
					method: (notification: Notification) => {
						notification.text = 'Retrying...';
						notification.actionsDisabled = true;

						this.remediationActionsService
							.retryPendingActions()
							.toPromise()
							.then(() => {
								this.notificationsService.removeNotification(
									PENDING_RESOURCE_NOTIFICATION_ID
								);
								// TODO: Request from websocket
							});
					},
					iconName: 'refresh',
				},
			],
			contents:
				data.length === 1
					? null
					: data.map((actionTypeCount: RemediationActionTypeActionCount) => {
							return {
								text: actionTypeCount.remediationActionType.pendingText(
									actionTypeCount.actionCount
								),
							};
					  }),
		};
	}

	handleDataLoadError(error: AjaxError, resetPolling: boolean = false): Subscription {
		if (error.status === 405) {
			this.isRemediationDisabledSubject$.next(true);
			this.remediationDisabledMessage = error.response;
		} else {
			this.isRemediationDisabledSubject$.next(false);
			this.remediationDisabledMessage = null;

			if (resetPolling) {
				console.warn(
					`pending actions polling failed. trying again in ${PENDING_ACTIONS_ERROR_REFRESH_RATE /
						1000} seconds.`
				);
				this.timerSub && this.timerSub.unsubscribe();
				return (this.timerSub = timer(PENDING_ACTIONS_ERROR_REFRESH_RATE).subscribe(() =>
					this.resetPolling()
				));
			}
		}
	}

	ngOnDestroy(): void {
		this.timerSub && this.timerSub.unsubscribe();
		this._startPendingActionsTimer$.complete();
		this.isRemediationDisabledSubject$.complete();
	}
}

export interface PendingActionTypes {
	user: Array<RemediationActionTypeActionCount>;
	resource: Array<RemediationActionTypeActionCount>;
	all: Array<RemediationActionTypeActionCount>;
}
