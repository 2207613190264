var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isUndefined, omitBy } from 'lodash-es';
import { combineLatest, Observable, Subject, Subscription, merge } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { TextHighlightDirective } from '@wcd/angular-extensions';
import { TitleService } from '../../shared/services/title.service';
import { breadcrumbsStateService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { RoutesService } from '@wcd/shared';
import { Machine, ServiceSourceType } from '@wcd/domain';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AdvancedFeaturesService } from '../../admin/integration-settings/advanced-features.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { MachinesService } from '../../@entities/machines/services/machines.service';
import { AppConfigService } from '@wcd/app-config';
var Range30Days = 30;
var Range180Days = 180;
var SearchResultsComponent = /** @class */ (function () {
    function SearchResultsComponent(route, router, i18nService, titleService, changeDetectorRef, routesService, advancedFeaturesService, globalEntityTypesService, machinesService, appConfigService, liveAnnouncer) {
        var _this = this;
        this.route = route;
        this.router = router;
        this.i18nService = i18nService;
        this.changeDetectorRef = changeDetectorRef;
        this.routesService = routesService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.machinesService = machinesService;
        this.appConfigService = appConfigService;
        this.liveAnnouncer = liveAnnouncer;
        this.isFocused = false;
        this.isScc = sccHostService.isSCC;
        this.subscriptions = [];
        this.resultsCount$ = new Subject();
        this.subscriptions.push(this.route.queryParamMap
            .pipe(map(function (queryParams) { return queryParams.get('range'); }), filter(Boolean), map(Number))
            .subscribe(function (range) { return (_this.range = range); }));
        this.searchTerm$ = route.paramMap.pipe(map(function (paramMap) { return paramMap.get('term'); }));
        this.entityType$ = route.data.pipe(map(function (data) { return data.entityType; }));
        this.subscriptions.push(this.searchTerm$.subscribe(function (term) { return (_this.currentSearchTerm = term); }));
        this.header$ = merge(this.searchTerm$, this.resultsCount$).pipe(map(function (resultsCount) {
            if (typeof resultsCount === 'string') {
                return '';
            }
            if (resultsCount === 1) {
                if (_this.entityTypeId === 'user') {
                    var link = _this.currentResultData.items[0];
                    var _a = _this.getNavigationModel(link, ServiceSourceType.Wdatp), routerLink = _a.routerLink, queryParams = _a.queryParams;
                    _this.router.navigate(routerLink, { queryParams: queryParams });
                }
                else {
                    _this.router.navigate([_this.routesService.getMdatpFromSccUrl(_this.getEntitiesLink(_this.currentResultData.items))]);
                }
                return '';
            }
            if ((!_this.range || _this.range === Range30Days) &&
                resultsCount === 0 &&
                _this.entityTypeId === 'machine') {
                _this.router.navigate(['.'], {
                    relativeTo: _this.route,
                    queryParams: Object.assign({ range: Range180Days }, { pageIndex: null }),
                    queryParamsHandling: 'merge',
                });
            }
            var key = resultsCount === 0
                ? 'main.searchResults.header.empty'
                : resultsCount > 100
                    ? 'main.searchResults.header.capped'
                    : 'main.searchResults.header.full';
            var params = {
                term: _this.currentSearchTerm,
                count: resultsCount > 100 ? 100 : resultsCount,
            };
            return i18nService.get(key, params);
        }));
        this.subscriptions.push(this.header$.subscribe(function (message) {
            setTimeout(function () {
                if (message) {
                    liveAnnouncer.announce(message, 'assertive');
                }
            });
        }));
        this.subscriptions.push(this.entityType$.subscribe(function (entityType) {
            _this.getEntitiesLink = entityType.getEntitiesLink;
            _this.getNavigationModel = entityType.getNavigationModel;
            _this.entityTypeId = entityType.id;
            titleService.setState({
                pageTitle: i18nService.get('main.searchResults.pageTitle', {
                    entityTypeName: entityType.entity.pluralName,
                }),
            });
        }));
        this.dataViewOptions$ = combineLatest(this.entityType$, this.searchTerm$).pipe(map(function (_a) {
            var entityType = _a[0], searchTerm = _a[1];
            var originalDataViewOptions = entityType.dataViewOptions;
            var exportOptions = originalDataViewOptions.exportOptions, fixedOptions = originalDataViewOptions.fixedOptions;
            var dataViewOptions = originalDataViewOptions;
            if (entityType.id === _this.globalEntityTypesService.getEntityType(Machine).id) {
                dataViewOptions = _this.machinesService.getSearchViewConfig(originalDataViewOptions, _this.appConfigService.magellanOptOut);
            }
            return omitUndefinedProperties(__assign({}, dataViewOptions, { fixedOptions: fixedOptions &&
                    (function (currentDateRange) {
                        return originalDataViewOptions.fixedOptions(currentDateRange, searchTerm);
                    }), exportOptions: exportOptions && {
                    showModalOnExport: exportOptions.showModalOnExport,
                    exportResults: exportOptions.exportResults &&
                        (function (options) {
                            return exportOptions.exportResults(options, _this.fixedOptions, searchTerm);
                        }),
                } }));
        }));
        this.dataviewLeftPadding = !sccHostService.isSCC;
    }
    SearchResultsComponent.prototype.ngOnDestroy = function () {
        if (this.subscriptions) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    SearchResultsComponent.prototype.onFixedOptionsChanged = function (fixedOptions) {
        this.fixedOptions = fixedOptions;
        this.updateTextHighlight();
    };
    SearchResultsComponent.prototype.onDataChanged = function (_a) {
        var _this = this;
        var data = _a.data;
        this.currentResultData = data;
        this.resultsCount$.next(data.count || data.items.length);
        this.isFocused = false;
        // Focus the search result header, so after a search the narrator would announce the search result summary
        setTimeout(function () {
            _this.isFocused = true;
            _this.changeDetectorRef.markForCheck();
        }, 50);
        this.updateTextHighlight();
        this.setBreadcrumbs();
    };
    SearchResultsComponent.prototype.updateTextHighlight = function () {
        this.textHighlightDirective && this.textHighlightDirective.updateHighlights();
    };
    SearchResultsComponent.prototype.setBreadcrumbs = function () {
        if (sccHostService.isSCC) {
            return;
        }
        // Since there are several components that handle the search results and breadcrumbs themselves
        // (e.g. - users search results, software, etc.), the breadcrumbs for search results can't be
        // set in the module router using the breadcrumb config.
        // In addition, setting the breadcrumb here, only works for times where it is not handled elsewhere.
        var snapshot = this.route.snapshot;
        if (snapshot && snapshot.params && snapshot.params['term']) {
            breadcrumbsStateService.set({
                id: 'searchResults',
                label: this.i18nService.get('main.searchResults.title'),
                url: snapshot.url.map(function (urlSegment) { return "/" + urlSegment.path; }).join(''),
                queryParams: this.route.snapshot.queryParams,
            });
        }
    };
    return SearchResultsComponent;
}());
export { SearchResultsComponent };
var omitUndefinedProperties = function (obj) { return omitBy(obj, isUndefined); };
var ɵ0 = omitUndefinedProperties;
export { ɵ0 };
