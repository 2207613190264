var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ViewContainerRef, ComponentRef, Injector, ComponentFactoryResolver, Type, ComponentFactory, OnDestroy, AfterViewInit, } from '@angular/core';
import { EntityPanelComponentBase } from './entity-panel.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { Subject, combineLatest } from 'rxjs';
var EntityWithContextPanelComponent = /** @class */ (function (_super) {
    __extends(EntityWithContextPanelComponent, _super);
    function EntityWithContextPanelComponent(changeDetectorRef, globalEntityTypesService, resolver, injector) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.resolver = resolver;
        _this.injector = injector;
        _this.afterViewInit$ = new Subject();
        _this.setEntity$ = new Subject();
        _this.setEntitySubscription = combineLatest(_this.afterViewInit$, _this.setEntity$).subscribe(function (_a) {
            var afterViewInit = _a[0], isExtendedData = _a[1];
            return _this.setEntityInternal(isExtendedData);
        });
        return _this;
    }
    Object.defineProperty(EntityWithContextPanelComponent.prototype, "entityContext", {
        get: function () {
            return this.entity && this.entity.entityContext;
        },
        enumerable: true,
        configurable: true
    });
    EntityWithContextPanelComponent.prototype.ngAfterViewInit = function () {
        this.afterViewInit$.next(true);
    };
    EntityWithContextPanelComponent.prototype.ngOnDestroy = function () {
        this.setEntitySubscription && this.setEntitySubscription.unsubscribe();
        this.destroyMainEntityDetailsComponent();
    };
    EntityWithContextPanelComponent.prototype.setEntity = function (entityWithContext, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entityWithContext, isExtendedData);
        // postpone entity rendering until AfterViewInit by queueing a message in setEntity$
        this.setEntity$.next(isExtendedData);
    };
    EntityWithContextPanelComponent.prototype.setEntityInternal = function (isExtendedData) {
        this.createMainEntityDetailsComponent(this.entity.mainEntity, isExtendedData);
        this.changeDetectorRef.markForCheck();
    };
    EntityWithContextPanelComponent.prototype.createMainEntityDetailsComponent = function (mainEntity, isExtendedData) {
        if (!this.mainEntityDetailsPlaceholder)
            return;
        this.destroyMainEntityDetailsComponent();
        var entityType = mainEntity && mainEntity.type
            ? this.globalEntityTypesService.getEntityType(mainEntity.type)
            : null, entityPanelType = entityType
            ? entityType.singleEntityPanelComponentType
            : null, factory = entityPanelType
            ? this.resolver.resolveComponentFactory(entityPanelType)
            : null, entityPanelRef = factory
            ? factory.create(this.injector)
            : null;
        this.mainEntityDetailsComponent = entityPanelRef;
        if (!entityPanelRef)
            return;
        this.mainEntityDetailsPlaceholder.insert(this.mainEntityDetailsComponent.hostView);
        this.mainEntityDetailsComponent.instance.setEntity(mainEntity.item, isExtendedData);
    };
    EntityWithContextPanelComponent.prototype.destroyMainEntityDetailsComponent = function () {
        this.mainEntityDetailsComponent && this.mainEntityDetailsComponent.destroy();
        this.mainEntityDetailsPlaceholder && this.mainEntityDetailsPlaceholder.clear();
    };
    return EntityWithContextPanelComponent;
}(EntityPanelComponentBase));
export { EntityWithContextPanelComponent };
