import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Alert } from '../alert.entity'
import { IncidentLinkedByEntity } from './incident-linked-by-entity.value-object'
import { convertImpactedEntitiesV3ToLegacy } from '../alert.utils';
import { detectionSourceValues } from '../sources/detection-source.entity.values';
import { DetectionSourceType } from '../sources/detection-source.enum';
import { severityValues } from '../../severity/severity.entity.values';

@ValueObject({
	singularName: 'Linked entity alert',
	pluralName: 'Linked entity alerts',
	readonly: true,
})
export class LinkedEntityAlert extends ModelBase {
	@EntityField({
		data: 'Alert',
		parse: (data: any) => data.AlertDisplayName ? new Alert({
			id: data.AlertId,
			name: data.AlertDisplayName,
			actor: data.ActorName,
			detectionSource: detectionSourceValues.find((value) => value.id === DetectionSourceType[data.DetectionSource]),
			severity: severityValues.find((value) => value.name === data.Severity),
			impactedEntities: convertImpactedEntitiesV3ToLegacy(data.ImpactedEntities, data.ImpactedEntitiesSummary),
			firstEventTime: new Date(data.StartTimeUtc),
			lastEventTime: new Date(data.EndTimeUtc),
			firstSeen: new Date(data.TimeGenerated),
		}) : data,
	})
	readonly alert: Alert;

	@EntityField({
		data: 'Entity',
	})
	readonly entity?: IncidentLinkedByEntity;
}
