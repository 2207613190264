/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../rbac/directives/rbac-control.directive";
import * as i2 from "../../../rbac/services/rbac-control.service";
import * as i3 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "@angular/common";
import * as i9 from "../../../dialogs/modals/components/modal.component.ngfactory";
import * as i10 from "../../../dialogs/modals/components/modal.component";
import * as i11 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i12 from "ngx-markdown";
import * as i13 from "../../../../../../../projects/angular-extensions/src/lib/directives/let.directive";
import * as i14 from "./add-files-to-allowlist.modal";
import * as i15 from "@microsoft/paris/dist/lib/paris";
import * as i16 from "@angular/router";
import * as i17 from "../../custom_ti_indicators/services/custom-ti-indicators.service";
var styles_AddFilesToAllowlistModal = [];
var RenderType_AddFilesToAllowlistModal = i0.ɵcrt({ encapsulation: 2, styles: styles_AddFilesToAllowlistModal, data: {} });
export { RenderType_AddFilesToAllowlistModal as RenderType_AddFilesToAllowlistModal };
function View_AddFilesToAllowlistModal_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 7, "button", [["class", "btn-link"], ["data-track-id", "SetSystemExclusionRule"], ["data-track-type", "Button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setSystemExclusionRule() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 4800512, null, 0, i1.RbacControlDirective, [i2.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i3.WicdSanitizerService, [8, null], i4.TooltipsService, i0.ViewContainerRef, i5.I18nService], { rbac: [0, "rbac"] }, null), i0.ɵpad(2, 1), i0.ɵpod(3, { permissions: 0, state: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "fab-icon", [["iconName", "OpenInNewWindow"]], null, null, null, i6.View_FabIconComponent_0, i6.RenderType_FabIconComponent)), i0.ɵdid(6, 5816320, null, 0, i7.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(7, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _ck(_v, 2, 0, "securitySettings"), "disabled"); _ck(_v, 1, 0, currVal_0); var currVal_1 = "OpenInNewWindow"; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.i18nService.strings.remediationActions_openAllowListPanel; _ck(_v, 7, 0, currVal_2); }); }
function View_AddFilesToAllowlistModal_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "span", [["class", "wcd-padding-left color-text-error"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngLet; _ck(_v, 2, 0, currVal_0); }); }
function View_AddFilesToAllowlistModal_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddFilesToAllowlistModal_3)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(0, [["showTiError", 2]], null, 0, null, View_AddFilesToAllowlistModal_4)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = !_v.parent.context.ngLet; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_AddFilesToAllowlistModal_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AddFilesToAllowlistModal_2)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.ngLet !== _co.loadingSymbol); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AddFilesToAllowlistModal_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 19, "modal", [], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.cancel.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_ModalComponent_0, i9.RenderType_ModalComponent)), i0.ɵdid(2, 180224, null, 0, i10.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 5, "div", [["class", "wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 2, "markdown", [], null, null, null, i11.View_MarkdownComponent_0, i11.RenderType_MarkdownComponent)), i0.ɵdid(7, 4767744, null, 0, i12.MarkdownComponent, [i0.ElementRef, i12.MarkdownService], null, null), (_l()(), i0.ɵted(8, 0, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\n\t\t\t"])), (_l()(), i0.ɵeld(11, 0, null, 0, 8, "footer", [["class", "dialog-footer wcd-flex-none right-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["data-track-id", "DontAddToWhitelist"], ["data-track-type", "Button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(14, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AddFilesToAllowlistModal_1)), i0.ɵdid(17, 16384, null, 0, i13.LetDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngLet: [0, "ngLet"] }, null), i0.ɵpid(131072, i8.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_3 = i0.ɵunv(_v, 17, 0, i0.ɵnov(_v, 18).transform(_co.tiCapacityData$)); _ck(_v, 17, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.message; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.i18nService.strings.buttons_done; _ck(_v, 14, 0, currVal_2); }); }
export function View_AddFilesToAllowlistModal_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "add-files-to-allowlist", [], null, null, null, View_AddFilesToAllowlistModal_0, RenderType_AddFilesToAllowlistModal)), i0.ɵdid(1, 245760, null, 0, i14.AddFilesToAllowlistModal, [i15.Paris, i16.Router, i17.CustomTiIndicatorsService, i5.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddFilesToAllowlistModalNgFactory = i0.ɵccf("add-files-to-allowlist", i14.AddFilesToAllowlistModal, View_AddFilesToAllowlistModal_Host_0, { settings: "settings", message: "message", sha1: "sha1", hashes: "hashes", onDone: "onDone" }, { cancel: "cancel" }, []);
export { AddFilesToAllowlistModalNgFactory as AddFilesToAllowlistModalNgFactory };
