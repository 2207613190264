var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Identifiable } from '../../data/models/identifiable.model';
/**
 * old tag model. please use "Tag" entity.
 * @deprecated
 */
var TagModel = /** @class */ (function (_super) {
    __extends(TagModel, _super);
    function TagModel(data) {
        var _this = _super.call(this, typeof data === 'string' ? null : data) || this;
        _this.isPartial = false;
        _this.isEditable = true;
        if (typeof data === 'string')
            _this.name = data;
        else
            _this.isEditable = data.isEditable !== false;
        return _this;
    }
    TagModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
    };
    TagModel.prototype.getBackendData = function () {
        return {
            name: this.name,
        };
    };
    return TagModel;
}(Identifiable));
export { TagModel };
