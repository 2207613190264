import { ExploitGuardInnerRule } from './exploit-guard-inner-rule.value-object';
import { ExploitGuardInnerRuleName } from './exploit-guard-inner-rule-name.enum';

export const exploitGuardInnerRules: Array<ExploitGuardInnerRule> = [
	{
		id: '9e6c4e1f-7d60-472f-ba1a-a39ef669e4b2',
		name: ExploitGuardInnerRuleName.credentialStealingFromLsass,
	},
	{
		id: 'be9ba2d9-53ea-4cdc-84e5-9b1eeee46550',
		name: ExploitGuardInnerRuleName.executingEmailAttachment,
	},
	{
		id: 'd3e037e1-3eb8-44c8-a917-57927947596d',
		name: ExploitGuardInnerRuleName.executingPayLoad,
	},
	{
		id: '5737d832-9e2c-4922-9623-48a220290dcb',
		name: ExploitGuardInnerRuleName.folderGuard,
	},
	{
		id: 'c1db55ab-c21a-4637-bb3f-a12568109d35',
		name: ExploitGuardInnerRuleName.launchingUnverifiedWindowsExecutable,
	},
	{
		id: 'd4f940ab-401b-4efc-aadc-ad5f3c50688a',
		name: ExploitGuardInnerRuleName.officeCreatingChild,
	},
	{
		id: 'e6db77e5-3df2-4cf1-b95a-636979351e5b',
		name: ExploitGuardInnerRuleName.persistenceThroughWmi,
	},
	{
		id: '3b576869-a4ec-4529-8536-b80a7769e899',
		name: ExploitGuardInnerRuleName.officeCreatingExecutable,
	},
	{
		id: '75668c1f-73b5-4cf0-bb93-3ecf5cb7cc84',
		name: ExploitGuardInnerRuleName.officeInjection,
	},
	{
		id: 'd1e49aac-8f56-4280-b9ba-993a6d77406c',
		name: ExploitGuardInnerRuleName.processCreationFromWMI,
	},
	{
		id: '5beb7efe-fd9a-4556-801d-275e5ffc04cc',
		name: ExploitGuardInnerRuleName.runningObfuscatedCode,
	},
	{
		id: '01443614-cd74-433a-b99e-2ecdc07bfc25',
		name: ExploitGuardInnerRuleName.runningUntrustedExecutable,
	},
	{
		id: 'b2b3f03d-6a65-4f7b-a9c7-1c7ef74a9ba4',
		name: ExploitGuardInnerRuleName.untrustedProcessFromUSB,
	},
	{
		id: '92e97fa1-2edf-4476-bdd6-9dd0b4dddc7b',
		name: ExploitGuardInnerRuleName.windowsCallsFromOfficeMacro,
	},
];
