var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { Process } from '../../entity/process.entity';
import { File } from '../../file/file.entity';
import { RegistryOperation } from '../../entity/registry-operation.entity';
var ɵ0 = function (data) { return data.FileName || data.Sha1; };
/**
 * old alert timeline element. please use "cyber-event" model.
 * @deprecated
 */
var AlertTimelineElement = /** @class */ (function (_super) {
    __extends(AlertTimelineElement, _super);
    function AlertTimelineElement() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AlertTimelineElement_1 = AlertTimelineElement;
    Object.defineProperty(AlertTimelineElement.prototype, "descriptions", {
        // get name(): string {
        // 	let titleItems: Array<CyberEventEntityTitleItem> = this.titleItems;
        // 	return titleItems && titleItems.length && titleItems[0].name;
        // }
        // get typeName(): string {
        // 	return this.actionType && this.actionType.name || this.type.name;
        // }
        //
        // get icon(): string {
        // 	return this.actionType && this.actionType.icon || this.type.icon;
        // }
        get: function () {
            // if (this._descriptions === undefined && this.actionType && this.actionType.getDescriptions)
            // 	this._descriptions = this.actionType.getDescriptions(this) || null;
            return this._descriptions;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertTimelineElement.prototype, "depth", {
        get: function () {
            // if (this._depth === undefined) {
            // 	if (!this.$parent || !(this.$parent instanceof AlertTimelineElement))
            // 		this._depth = 0;
            // 	else
            // 		this._depth = (<AlertTimelineElement>this.$parent).depth + 1;
            // }
            return this._depth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertTimelineElement.prototype, "hasChildren", {
        get: function () {
            return this.children && this.children.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    var AlertTimelineElement_1;
    __decorate([
        EntityField({ data: 'ElementId' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'IpAddress' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "ipAddress", void 0);
    __decorate([
        EntityField({ data: 'Protocol' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "protocol", void 0);
    __decorate([
        EntityField({ data: 'Port' }),
        __metadata("design:type", Number)
    ], AlertTimelineElement.prototype, "port", void 0);
    __decorate([
        EntityField({ data: 'Domain' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "domain", void 0);
    __decorate([
        EntityField({ data: 'AnomalousDescription' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "anomalousDescription", void 0);
    __decorate([
        EntityField({ data: 'IsAssociatedWithAlert', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AlertTimelineElement.prototype, "isAssociatedWithAlert", void 0);
    __decorate([
        EntityField({ data: 'SubElements', arrayOf: AlertTimelineElement_1 }),
        __metadata("design:type", Array)
    ], AlertTimelineElement.prototype, "children", void 0);
    __decorate([
        EntityField({ data: 'Url' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "url", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcessName' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "initiatingProcessName", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcessUser' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "initiatingProcessUser", void 0);
    __decorate([
        EntityField({ data: 'InitiatingProcessDomain' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "initiatingProcessDomain", void 0);
    __decorate([
        EntityField({ data: '__self', require: 'ProcessId' }),
        __metadata("design:type", Process)
    ], AlertTimelineElement.prototype, "process", void 0);
    __decorate([
        EntityField({ data: '__self', require: ɵ0 }),
        __metadata("design:type", File)
    ], AlertTimelineElement.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'RegistryOperation' }),
        __metadata("design:type", RegistryOperation)
    ], AlertTimelineElement.prototype, "registryOperation", void 0);
    __decorate([
        EntityField({ data: 'RiskScore' }),
        __metadata("design:type", Number)
    ], AlertTimelineElement.prototype, "riskScore", void 0);
    __decorate([
        EntityField({ data: 'ReportIndex' }),
        __metadata("design:type", String)
    ], AlertTimelineElement.prototype, "reportIndex", void 0);
    __decorate([
        EntityField({ data: 'EventTime' }),
        __metadata("design:type", Date)
    ], AlertTimelineElement.prototype, "eventTime", void 0);
    AlertTimelineElement = AlertTimelineElement_1 = __decorate([
        ValueObject({
            singularName: 'Alert Timeline Element',
            pluralName: 'Alert Timeline Elements',
        })
    ], AlertTimelineElement);
    return AlertTimelineElement;
}(ModelBase));
export { AlertTimelineElement };
export { ɵ0 };
