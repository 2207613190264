/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./process.entity-details.component.ngfactory";
import * as i2 from "./process.entity-details.component";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i6 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i7 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i8 from "@angular/common";
import * as i9 from "./process-action.entity-details.component";
var styles_ProcessActionEntityDetailsComponent = [];
var RenderType_ProcessActionEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProcessActionEntityDetailsComponent, data: {} });
export { RenderType_ProcessActionEntityDetailsComponent as RenderType_ProcessActionEntityDetailsComponent };
function View_ProcessActionEntityDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "process-entity-details", [], null, null, null, i1.View_ProcessEntityDetailsComponent_0, i1.RenderType_ProcessEntityDetailsComponent)), i0.ɵdid(3, 114688, null, 0, i2.ProcessEntityDetailsComponent, [i0.Injector], { entity: [0, "entity"], asKeyValueList: [1, "asKeyValueList"], showEntityPanelLink: [2, "showEntityPanelLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.action.process; var currVal_1 = _co.asKeyValueList; var currVal_2 = false; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProcessActionEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "dl", [["role", "none"]], [[2, "key-values", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 2, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpid(131072, i5.TzDatePipe, [i6.TzDateService, i7.LocaleConfigService, i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProcessActionEntityDetailsComponent_1)), i0.ɵdid(14, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.action.process; _ck(_v, 14, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asKeyValueList; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "alerts.events.details.actionTime")); _ck(_v, 5, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.action.actionTime, "medium")); _ck(_v, 9, 0, currVal_2); }); }
export function View_ProcessActionEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "process-action-entity-details", [], null, null, null, View_ProcessActionEntityDetailsComponent_0, RenderType_ProcessActionEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i9.ProcessActionEntityDetailsComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProcessActionEntityDetailsComponentNgFactory = i0.ɵccf("process-action-entity-details", i9.ProcessActionEntityDetailsComponent, View_ProcessActionEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { ProcessActionEntityDetailsComponentNgFactory as ProcessActionEntityDetailsComponentNgFactory };
