// taken from https://o365exchange.visualstudio.com/DefaultCollection/O365%20Core/_git/Nibiru?path=%2Fsources%2Fdev%2Fm365scc%2Fsrc%2FClient%2FApp%2Fcommon%2FUrlHelpers.ts&_a=contents&version=GBmaster
// from Nibiru repo
export class EmailUrlHelpers {
	private static noFlag = 0;
	private static urlFlag = 8192;
	/*
	 excluding characterMaskLookup numbers array from prettier because
	 it puts every element on a separate line, whereas we want them grouped
	 as is delineated by the embedded comments
	 */
	// prettier-ignore
	private static characterMaskLookup: number[] = [
		// 0 - 7
		32768, 0, 0, 0, 0, 0, 0, 0,
		// 8 - 10
		0, 61440, 61440,
		// 11 - 15
		0, 61440, 61440, 0, 0,
		// 16 - 23
		0, 0, 0, 0, 0, 0, 0, 0,
		// 24 - 31
		0, 0, 0, 0, 0, 0, 0, 0,
		// 32 - 36 Space - $
		61440, 32768, 45056, 32768, 32768,
		// 37 - 41 % - )
		32768, 32768, 45056, 32768, 32768,
		// 42 - 47 * - /
		32768, 32768, 32768, 32768, 40960, 45056,
		// 48 - 57 0 - 9
		0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
		// 58 - 64 : - @
		32768, 45056, 45056, 32768, 45056, 32768, 45056,
		// 65 - 72 A - H
		4, 4, 8, 8, 16, 16, 32, 32,
		// 73 - 80 I - P
		64, 64, 128, 128, 128, 256, 256, 256,
		// 81 - 90 Q - Z
		512, 512, 512, 1024, 1024, 1024, 2048, 2048, 2048, 2048,
		// 91 - 96 [ - `
		32768, 32768, 32768, 32768, 32768, 32768,
		// 97 - 122 a - z
		4, 4, 8, 8, 16, 16, 32, 32, 64, 64, 128, 128, 128, 256, 256, 256, 512, 512, 512, 1024, 1024, 1024, 2048, 2048, 2048, 2048,
		// 123 - 127 { - Delete
		32768, 32768, 32768, 32768, 0];

	/// <summary>
	/// Takes a full URL and a partial/matched URL and returns whether the complete URL "matches" the partial URL.
	///
	/// Use case: URL matching algorithm matches any input URL against its trie of conditions/rules. Wherever it matches, it returns the URL built by the path up to that node.
	/// So, we don't always get a full URL. This method determines whether given URL can match the partial URL returned by the trie algorithm.
	///
	/// In addition to being a sub-string, partialUrl should be a valid substring of the URL. That means that this substring should start and end with sensible URL delimitters.
	/// For example, google.com should match google.com/phishUrl but not mygoogle.com.
	/// </summary>
	public static doesMatch(url: string, partialUrl: string) {
		if (url && partialUrl) {
			let isMatch: boolean = true;
			const startIdx: number = url.toUpperCase().indexOf(partialUrl.toUpperCase());

			if (startIdx < 0) {
				return false;
			}

			const endIdx: number = startIdx + partialUrl.length;
			if (startIdx > 0) {
				const startChar = url.charAt(startIdx - 1);
				isMatch = isMatch && EmailUrlHelpers.isLhsDelimiter(startChar);
			}
			if (endIdx <= url.length - 1) {
				const endChar = url.charAt(endIdx);
				isMatch = isMatch && EmailUrlHelpers.isRhsDelimiter(endChar);
			}

			return isMatch;
		}

		return false;
	}

	public static isLetterOrDigit(ch: string) {
		return (ch >= '0' && ch <= '9') || (ch >= 'A' && ch <= 'Z') || (ch >= 'a' && ch <= 'z');
	}

	public static isLhsDelimiter(ch: string) {
		/// In addition to being a sub-string, partialUrl should be a valid substring of the URL. That means that this substring should start and end with sensible URL delimitters.
		/// For example, google.com should match google.com/phishUrl but not mygoogle.com.
		return (EmailUrlHelpers.findMask(ch) & EmailUrlHelpers.urlFlag) != 0;
	}

	public static isRhsDelimiter(ch: string) {
		// the right hand side character should be a valid URL ending character such as '/'
		// Few negative examples:
		// google.co should not match google.com because both are not the same URLs
		// a.b.com should not match a.b/something but it should match a.b.com/something
		// So, make sure that the right hand delimiter is a valid URL separator
		return ch != '.' && ch != '@' && ch != '-' && !EmailUrlHelpers.isLetterOrDigit(ch);
	}

	private static findMask(ch: string) {
		if (ch.length != 1) {
			return EmailUrlHelpers.noFlag;
		} else {
			const charNum: number = ch.charCodeAt(0);
			if (charNum < EmailUrlHelpers.characterMaskLookup.length) {
				return EmailUrlHelpers.characterMaskLookup[charNum];
			} else {
				return EmailUrlHelpers.noFlag;
			}
		}
	}
}
