import { DataQuery, DataSet, EntityConfig, DataQuerySortDirection, ApiCallConfig } from '@microsoft/paris';
import {
	AddMachineGroupsFilterQueryParam,
	Cache,
	DefaultApiVersion,
	TvmEndPoint,
	AddODataFilterQueryParam,
	FiltersMapValue,
} from './commonEndPointUtils';
import { ODataFilterQueryBuilder } from './oDataFilterQueryBuilder';
import { EolState } from './software-version/eol-version-state.enum';
import { PermissionRisk, VulnerabilityType } from '..';

declare const moment: typeof import('moment');

const rangeRegex: RegExp = /^(\d+):(\d+)$/;

const dayInMilliseconds = 86400000;

export enum OsBuildNumber {
	Future = 'Future',
	H121 = '21H1',
	H220 = '20H2',
	H120 = '2004',
	H219 = '1909',
	RS6 = '1903',
	RS5 = '1809',
	RS4 = '1803',
	RS3 = '1709',
	RS2 = '1703',
	RS1 = '1607',
}

export const enum TimeRangeId {
	'day' = 'day',
	'3days' = '3days',
	'week' = 'week',
	'month' = 'month',
	'3months' = '3months',
	'6months' = '6months',
	'custom' = 'custom',
}

export enum AgeFilterOptions {
	ZeroToThirty = 'ZeroToThirty',
	ThirtyToSixty = 'ThirtyToSixty',
	SixtyToNinety = 'SixtyToNinety',
	NinetyOrMore = 'NinetyOrMore',
}

enum ValidDatesFiltersOptions {
	Expired = 'Expired',
	EarlyIssueDate = 'EarlyIssueDate',
	ValidDate = 'ValidDate',
	ExpiringIn30Days = 'ExpiringIn30Days',
	ExpiringIn60Days = 'ExpiringIn60Days',
	ExpiringIn90Days = 'ExpiringIn90Days',
}

export interface ITvmAnalyticsResponse<T> {
	currentLink: string;
	results: Array<T>;
	numOfResults: number;
	meta: object;
}

type FieldFilters =
	| 'threats'
	| 'discoveredVulnerabilities'
	| 'weaknesses'
	| 'relatedComponent'
	| 'remediationType'
	| 'status'
	| 'numOfImpactedAssets'
	| 'severity'
	| 'isNormalized'
	| 'filterTags'
	| 'recommendationTags'
	| 'age'
	| 'matchPercent'
	| 'isFoundByTvm'
	| 'isFoundByOther'
	| 'date'
	| 'eventType'
	| 'assetsCount'
	| 'versionDistributionTags'
	| 'deviceType'
	| 'vendor'
	| 'os'
	| 'osName'
	| 'exposureLevel'
	| 'name'
	| 'productIdFilter'
	| 'onboardingStatus'
	| 'osPlatform'
	| 'osDistribution'
	| 'baselineOsPlatform'
	| 'compliantConfigurations'
	| 'profileStatus'
	| 'category'
	| 'complianceLevels'
	| 'deviceCompliant'
	| 'configurationCompliant'
	| 'compliantDevices'
	| 'softwareMissingKbsTags'
	| 'softwareMissingKbsOsVersion'
	| 'validDates'
	| 'selfSigned'
	| 'keySize'
	| 'signatureAlgorithm'
	| 'keyUsage'
	| 'targetSoftwareFilter'
	| 'aggregatePermissionsRiskValue'
	| 'permissionsRiskValue';

export enum FilterDiscoveredVulnerabilities {
	hasWeaknesses = 'HasWeaknesses',
	noWeaknesses = 'NoWeaknesses',
	notAvailable = 'NotAvailable',
}

export enum FilterWeaknesses {
	hasWeaknesses = 'HasWeaknesses',
	noWeaknesses = 'NoWeaknesses',
	notAvailable = 'NotAvailable',
}

export enum FilterImpactedAssets {
	hasEffect = 'HasEffect',
	noEffect = 'NoEffect',
	notAvailable = 'NotAvailable',
}

export enum FILTER_TAG {
	none = 'None',
	alreadyEOL = 'AlreadyEOL',
	// upcomingEOL = 'UpcomingEOL', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
	networkGear = 'NetworkGear',
	hasEolVersions = 'HasEolVersions',
	hasUpcomingEolVersions = 'HasUpcomingEolVersions',
	zeroDay = 'ZeroDay',
}

export enum VA_RECOMMENDATION_FILTER_TAG {
	alreadyEOL = 'AlreadyEOL',
	// upcomingEOL = 'UpcomingEOL', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
	hasEolVersions = 'HasEolVersions',
	hasUpcomingEolVersions = 'HasUpcomingEolVersions',
	zeroDay = 'ZeroDay',
}

export enum SCA_RECOMMENDATION_FILTER_TAG {
	covid19 = 'covid19',
	userImpactAssessment = 'userImpactAssessment',
	humanOperatedRansomware = 'humanOperatedRansomware',
}

export enum VERSION_DISTRIBUTION_FILTER_TAG {
	none = 'None',
	alreadyEOL = 'AlreadyEOL',
	upcomingEOL = 'UpcomingEOL',
}

export enum SOFTWARE_MISSING_KBS_FILTER_TAG {
	none = 'None',
	alreadyEOL = 'AlreadyEOL',
	upcomingEOL = 'UpcomingEOL',
}

export enum ANALYTICS_FIELDS {
	alreadyEOL = 'isEol',
	// upcomingEOL = 'isUpcomingEol', TODO: When adding filter on UpcomingEolSoftware - uncomment this line
	eolSoftwareState = 'eolSoftwareState',
	hasEolVersions = 'hasEolVersions',
	hasUpcomingEolVersions = 'hasUpcomingEolVersions',
	category = 'category',
	application = 'Application',
	recommendationCategory = 'recommendationCategory',
	eolVersionState = 'eolVersionState',
	mostSevereVulnerabilityType = 'mostSevereVulnerabilityType',
	patchReleaseDate = 'patchReleaseDate',
	osVersion = 'osVersion',
}

const getExploitsRoute = (filterValues: string[]) => {
	const queryBuilder = new ODataFilterQueryBuilder('or');
	const predicatePerFilterValue = {
		'0': 'ThreatInfo/IsThreatActive',
		'1': 'ThreatInfo/HasExploit',
		'2': 'ThreatInfo/IsExploitVerified',
		'3': 'ThreatInfo/IsInExploitKit',
	};
	filterValues.forEach(filterValue => {
		if (predicatePerFilterValue[filterValue]) {
			return queryBuilder.equals(predicatePerFilterValue[filterValue], true);
		} else {
			throw new Error(`Invalid filter value`);
		}
	});
	return queryBuilder.toQuery();
};

/*
	register FieldFilter in the map in order to enable filtering the field.
	available values:
		true: filtering is enabled, and will use the common oDataFilterFormatter (see below) to format the field's filtering query param using standard oData syntax
		false: filtering is disabled for the field
		CB: provide a CB that returns a custom filtering query param. if null is returned, the field will not be filtered.
*/

// TODO: some of those filters have boolean values, it makes no sense to send a query like: "x eq true or x eq false", may affect performance
export const fieldFiltersMap: Record<FieldFilters, FiltersMapValue> = {
	threats: [
		{
			predicate: getExploitsRoute,
		},
		{
			context: 'vulnerability',
			predicate: getExploitsRoute,
		},
	],
	filterTags: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');

		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case FILTER_TAG.none:
					queryBuilder.and(qb =>
						qb
							.equals(ANALYTICS_FIELDS.eolSoftwareState, EolState.NotEOL)
							.equals(ANALYTICS_FIELDS.category, ANALYTICS_FIELDS.application)
							.equals(ANALYTICS_FIELDS.hasEolVersions, false)
							.equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, false)
					);
					break;
				case FILTER_TAG.alreadyEOL:
					// case FILTER_TAG.upcomingEOL:  TODO: When adding filter on UpcomingEolSoftware - uncomment this line
					queryBuilder.equals(ANALYTICS_FIELDS.eolSoftwareState, filterVal);
					break;
				case FILTER_TAG.hasEolVersions:
					queryBuilder.equals(ANALYTICS_FIELDS.hasEolVersions, true);
					break;
				case FILTER_TAG.hasUpcomingEolVersions:
					queryBuilder.equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, true);
					break;
				case FILTER_TAG.networkGear:
					queryBuilder.equals(ANALYTICS_FIELDS.category, FILTER_TAG.networkGear);
					break;
				case FILTER_TAG.zeroDay:
					queryBuilder.and(qb =>
						qb
							.equals(ANALYTICS_FIELDS.mostSevereVulnerabilityType, VulnerabilityType.ZeroDay)
							.equals(ANALYTICS_FIELDS.patchReleaseDate, null)
					);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	recommendationTags: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');

		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case VA_RECOMMENDATION_FILTER_TAG.alreadyEOL:
					//case VA_RECOMMENDATION_FILTER_TAG.upcomingEOL:  TODO: When adding filter on UpcomingEolSoftware - uncomment this line
					queryBuilder.equals(ANALYTICS_FIELDS.eolSoftwareState, filterVal);
					break;
				case VA_RECOMMENDATION_FILTER_TAG.hasEolVersions:
					queryBuilder.equals(ANALYTICS_FIELDS.hasEolVersions, true);
					break;
				case VA_RECOMMENDATION_FILTER_TAG.hasUpcomingEolVersions:
					queryBuilder.equals(ANALYTICS_FIELDS.hasUpcomingEolVersions, true);
					break;
				case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
					queryBuilder.and(qb =>
						qb
							.equals(ANALYTICS_FIELDS.mostSevereVulnerabilityType, VulnerabilityType.ZeroDay)
							.equals(ANALYTICS_FIELDS.patchReleaseDate, null)
					);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	versionDistributionTags: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');

		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case VERSION_DISTRIBUTION_FILTER_TAG.none:
					queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, EolState.NotEOL);
					break;
				case VERSION_DISTRIBUTION_FILTER_TAG.alreadyEOL:
				case VERSION_DISTRIBUTION_FILTER_TAG.upcomingEOL:
					queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, filterVal);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	softwareMissingKbsTags: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');

		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case SOFTWARE_MISSING_KBS_FILTER_TAG.none:
					queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, EolState.NotEOL);
					break;
				case SOFTWARE_MISSING_KBS_FILTER_TAG.alreadyEOL:
				case SOFTWARE_MISSING_KBS_FILTER_TAG.upcomingEOL:
					queryBuilder.equals(ANALYTICS_FIELDS.eolVersionState, filterVal);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	softwareMissingKbsOsVersion: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');

		filterValue.forEach((filterVal: string) => {
			queryBuilder.equals(ANALYTICS_FIELDS.osVersion, filterVal);
		});

		return queryBuilder.toQuery();
	},
	discoveredVulnerabilities: (filterValue: FilterDiscoveredVulnerabilities[]) => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case FilterDiscoveredVulnerabilities.hasWeaknesses:
					queryBuilder.greaterThan('discoveredVulnerabilities', 0);
					break;
				case FilterDiscoveredVulnerabilities.noWeaknesses:
					queryBuilder.and(qb =>
						qb.equals('discoveredVulnerabilities', 0).equals('productNeverMatched', false)
					);
					break;
				case FilterDiscoveredVulnerabilities.notAvailable:
					queryBuilder.and(qb =>
						qb.equals('productNeverMatched', true).equals('assetsStatistics/assetsAtRiskCount', 0)
					);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	weaknesses: (filterValue: FilterDiscoveredVulnerabilities[]) => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('and');
		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case FilterDiscoveredVulnerabilities.hasWeaknesses:
					queryBuilder.greaterThan('weaknesses', 0).equals('productNeverMatched', false);
					break;
				case FilterDiscoveredVulnerabilities.noWeaknesses:
					queryBuilder.and(qb => qb.equals('weaknesses', 0).equals('productNeverMatched', false));
					break;
				case FilterDiscoveredVulnerabilities.notAvailable:
					queryBuilder.and(qb => qb.equals('productNeverMatched', true));
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	relatedComponent: (filterValues: string[]) => {
		//if MDATP
		if (filterValues[0].includes(':')) {
			return filterValues.map((field: string) => field.split(':').join(' ')).join(' or ');
		}

		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValues.forEach((filterValue: string) => {
			//check if nested
			if (filterValue.includes('-')) {
				const [category, subCategory] = filterValue.split('-');
				queryBuilder.and(qb =>
					qb.equals('recommendationCategory', category).equals('subCategory', subCategory)
				);
			} else {
				queryBuilder.equals('recommendationCategory', filterValue);
			}
		});

		return queryBuilder.toQuery();
	},
	remediationType: true,
	status: true,
	numOfImpactedAssets: (filterValue: FilterImpactedAssets[]) => {
		if (!(filterValue && filterValue[0])) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => {
			switch (filterVal) {
				case FilterImpactedAssets.hasEffect:
					queryBuilder.greaterThan('numOfImpactedAssets', 0);
					break;
				case FilterImpactedAssets.noEffect:
					queryBuilder.equals('numOfImpactedAssets', 0);
					break;
				case FilterImpactedAssets.notAvailable:
					queryBuilder.equals('numOfImpactedAssets', null);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	severity: true,
	isNormalized: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) =>
			queryBuilder.equals('isNormalized', filterVal === 'Available')
		);
		return queryBuilder.toQuery();
	},
	productIdFilter: filterValue => {
		const queryBuilder = new ODataFilterQueryBuilder('and');
		const vendorProduct = filterValue[0].split('-_-');
		queryBuilder.equals('vendor', vendorProduct[0]);
		queryBuilder.equals('productName', vendorProduct[1]);
		return queryBuilder.toQuery();
	},
	age: (filterValues: AgeFilterOptions[]) => {
		const fieldName = 'publishedOn';
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValues.forEach(filterValue => {
			switch (filterValue) {
				case AgeFilterOptions.ZeroToThirty:
					queryBuilder.greaterThan(fieldName, getCurrentDateWithDaysAgo(30));
					break;
				case AgeFilterOptions.ThirtyToSixty:
					queryBuilder.and(qb =>
						qb
							.lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(30))
							.greaterThan(fieldName, getCurrentDateWithDaysAgo(60))
					);
					break;
				case AgeFilterOptions.SixtyToNinety:
					queryBuilder.and(qb =>
						qb
							.lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(60))
							.greaterThan(fieldName, getCurrentDateWithDaysAgo(90))
					);
					break;
				case AgeFilterOptions.NinetyOrMore:
					queryBuilder.lessThanOrEquals(fieldName, getCurrentDateWithDaysAgo(90));
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	matchPercent: filterValue => {
		if (!(filterValue && filterValue[0])) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		const matchPercentStr = 'matchPercent';
		filterValue.forEach((filterVal: string) => {
			if (filterVal === '\u2265 90%') {
				// \u2265 is unicode for >=
				queryBuilder.greaterThanOrEquals(matchPercentStr, 90);
			}
			if (filterVal === '\u2265 50%') {
				queryBuilder.greaterThanOrEquals(matchPercentStr, 50);
			}
			if (filterVal === '\u2265 70%') {
				queryBuilder.greaterThanOrEquals(matchPercentStr, 70);
			}
			if (filterVal === '\u2264 50%') {
				// \u2264 is unicode for <=
				queryBuilder.lessThanOrEquals(matchPercentStr, 50);
			}
		});
		return queryBuilder.toQuery();
	},
	isFoundByTvm: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('isFoundByTvm', filterVal === 'True'));
		return queryBuilder.toQuery();
	},
	isFoundByOther: filterValue => {
		if (!filterValue) return null;
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) =>
			queryBuilder.equals('isFoundByOther', filterVal === 'True')
		);
		return queryBuilder.toQuery();
	},
	date: (filterValue: string) => {
		if (!filterValue) return null;
		const fieldName = 'date';
		const queryBuilder = new ODataFilterQueryBuilder();
		switch (filterValue as TimeRangeId) {
			case TimeRangeId.day:
				queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(1));
				break;
			case TimeRangeId.week:
				queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(7));
				break;
			case TimeRangeId.month:
				queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(30));
				break;
			case TimeRangeId['3months']:
				queryBuilder.greaterThanOrEquals(fieldName, getCurrentDateWithDaysAgo(90));
				break;
			default:
				//SCC workaround - DatetimeRangeSection format isn't editable
				if (filterValue.length === 2) {
					//adding one day to fit SCC DatetimeRangeSection to the previous date format
					const startDate = Date.parse(filterValue[0]) + dayInMilliseconds;
					const endDate = Date.parse(filterValue[1]) + dayInMilliseconds;
					filterValue = `${startDate}:${endDate}`;
				}
				// if range is 'custom', this will match X:Y (where X and Y are unix timestamps in milliseconds)
				const customRangeMatch = filterValue.match(rangeRegex);
				if (customRangeMatch)
					queryBuilder
						.greaterThanOrEquals(
							fieldName,
							getDateFromUnixTimestamp(Number(customRangeMatch[1]) / 1000)
						)
						.lessThanOrEquals(
							fieldName,
							getDateFromUnixTimestamp(Number(customRangeMatch[2]) / 1000)
						);
				else return null;
				break;
		}
		return queryBuilder.toQuery();
	},
	eventType: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('type', filterVal));
		return queryBuilder.toQuery();
	},
	assetsCount: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder();
		let rangeMatch = filterValue[0].match(rangeRegex);
		if (!rangeMatch) {
			rangeMatch = ['dummy', filterValue[0], '100'];
		}

		const from = Number(rangeMatch[1]);
		const to = Number(rangeMatch[2]);
		return queryBuilder
			.greaterThanOrEquals('affectedAssetsPercent', from)
			.lessThanOrEquals('affectedAssetsPercent', to)
			.toQuery();
	},
	name: (filterValue: string) => {
		const queryBuilder = new ODataFilterQueryBuilder();
		return queryBuilder.contains('name', filterValue).toQuery();
	},
	deviceType: true,
	vendor: true,
	onboardingStatus: true,
	os: true,
	osPlatform: true,
	osDistribution: true,
	exposureLevel: true,
	baselineOsPlatform: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		const osPlatformQbs = filterValue.map((filterVal: string) => {
			const splitted = filterVal.split('-_-');
			const qb = new ODataFilterQueryBuilder('and');
			qb.equals('OperatingSystem', splitted[0]);
			qb.equals('OperatingSystemVersion', splitted[1]);
			return qb;
		});
		osPlatformQbs.forEach((qb: ODataFilterQueryBuilder) => queryBuilder.join(qb));
		return queryBuilder.toQuery();
	},
	compliantConfigurations: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder();
		const rangeMatch = filterValue[0].match(rangeRegex);
		const from = Number(rangeMatch[1]);
		const to = Number(rangeMatch[2]);
		return queryBuilder
			.greaterThanOrEquals('compliantConfigurationsPercent', from)
			.lessThanOrEquals('compliantConfigurationsPercent', to)
			.toQuery();
	},
	profileStatus: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('status', filterVal == 'true'));
		return queryBuilder.toQuery();
	},
	category: true,
	complianceLevels: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		const complianceLevelsQbs = filterValue.map((filterVal: string) => {
			const qb = new ODataFilterQueryBuilder();
			return qb.any('complianceLevels', filterVal);
		});
		complianceLevelsQbs.forEach((qb: ODataFilterQueryBuilder) => queryBuilder.join(qb));
		return queryBuilder.toQuery();
	},
	deviceCompliant: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) =>
			queryBuilder.equals('compliantConfigurations', parseInt(filterVal))
		);
		return queryBuilder.toQuery();
	},
	configurationCompliant: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) =>
			queryBuilder.equals('compliantDevices', parseInt(filterVal))
		);
		return queryBuilder.toQuery();
	},
	compliantDevices: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) =>
			queryBuilder.equals('compliantDevices', parseInt(filterVal))
		);
		return queryBuilder.toQuery();
	},
	validDates: (filterValues: ValidDatesFiltersOptions[]) => {
		const notAfterFieldName = 'notAfter';
		const notBeforeFieldName = 'notBefore';
		const queryBuilder = new ODataFilterQueryBuilder('or');
		const currentDate = getCurrentDateInMoreDays(0);
		filterValues.forEach(filterValue => {
			switch (filterValue) {
				case ValidDatesFiltersOptions.Expired:
					queryBuilder.lessThanOrEquals(notAfterFieldName, currentDate);
					break;
				case ValidDatesFiltersOptions.ExpiringIn30Days:
					queryBuilder.and(qb =>
						qb
							.greaterThan(notAfterFieldName, currentDate)
							.lessThan(notAfterFieldName, getCurrentDateInMoreDays(31))
					);
					break;
				case ValidDatesFiltersOptions.ExpiringIn60Days:
					queryBuilder.and(qb =>
						qb
							.greaterThan(notAfterFieldName, currentDate)
							.lessThan(notAfterFieldName, getCurrentDateInMoreDays(61))
					);
					break;
				case ValidDatesFiltersOptions.ExpiringIn90Days:
					queryBuilder.and(qb =>
						qb
							.greaterThan(notAfterFieldName, currentDate)
							.lessThan(notAfterFieldName, getCurrentDateInMoreDays(91))
					);
					break;
				case ValidDatesFiltersOptions.ValidDate:
					queryBuilder.and(qb =>
						qb
							.greaterThan(notAfterFieldName, currentDate)
							.lessThanOrEquals(notBeforeFieldName, currentDate)
					);
					break;
				case ValidDatesFiltersOptions.EarlyIssueDate:
					queryBuilder.greaterThan(notBeforeFieldName, currentDate);
					break;
			}
		});
		return queryBuilder.toQuery();
	},
	selfSigned: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('selfSigned', filterVal === 'true'));
		return queryBuilder.toQuery();
	},
	keySize: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('keySize', parseInt(filterVal)));
		return queryBuilder.toQuery();
	},
	signatureAlgorithm: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('signatureAlgorithm', filterVal));
		return queryBuilder.toQuery();
	},
	targetSoftwareFilter: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('targetSoftware', filterVal));
		return queryBuilder.toQuery();
	},
	keyUsage: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.any('keyUsage', filterVal));
		return queryBuilder.toQuery();
	},
	osName: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: string) => queryBuilder.equals('osName', filterVal));
		return queryBuilder.toQuery();
	},
	aggregatePermissionsRiskValue: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: PermissionRisk) =>
			queryBuilder.equals(
				'aggregatePermissionsRiskValue',
				Object.values(PermissionRisk).indexOf(filterVal)
			)
		);
		return queryBuilder.toQuery();
	},
	permissionsRiskValue: (filterValue: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValue.forEach((filterVal: PermissionRisk) =>
			queryBuilder.equals('permissionsRiskValue', Object.values(PermissionRisk).indexOf(filterVal))
		);
		return queryBuilder.toQuery();
	},
};

const getDateFromUnixTimestamp = (timestamp: number) => {
	return moment.unix(timestamp).toDate();
};

const getCurrentDateWithDaysAgo = (daysAgo: number, useFullDays: boolean = true) => {
	const _moment = moment()
		.utc(false)
		.add(-daysAgo, 'days');
	if (useFullDays) {
		_moment.startOf('day');
	}
	return _moment.toDate();
};

const getCurrentDateInMoreDays = (moreDays: number, useFullDays: boolean = true) =>
	getCurrentDateWithDaysAgo(-moreDays, useFullDays);

const fieldIdOrderMap = {
	impactInfo: ['exposureScoreImprovement', 'secureScoreImprovement'],
	age: ['publishedOn'],
	eventType: ['type'],
	exposedDevicesAfterException: ['assetsStatistics/assetsAtRiskCountAfterApplyingExceptions'],
	profileStatus: ['status'],
	compliantDevices: ['compliancePct', 'name'],
	deviceCompliant: ['compliantConfigurations'],
	configurationCompliant: ['compliantDevices'],
	baselineOsPlatform: ['operatingSystem', 'operatingSystemVersion'],
};

export const TvmAnalyticsBaseUrl = config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.Analytics}`;

export const SharedConfig: Partial<EntityConfig<any>> | Partial<ApiCallConfig<any>> = {
	cache: Cache,
	baseUrl: TvmAnalyticsBaseUrl,
	customHeaders: DefaultApiVersion,
};

export const TvmAnalyticsApiCallSharedConfigurations: Partial<ApiCallConfig<any>> = {
	...SharedConfig,
	parseQuery: input => {
		const params = {};
		if (input.hasOwnProperty('product') && input.hasOwnProperty('vendor')) {
			params['$filter'] = new ODataFilterQueryBuilder()
				.equals('ProductName', input.product)
				.equals('Vendor', input.vendor)
				.toQuery();
		}

		if (input.hasOwnProperty('profileId')) {
			params['profileId'] = input.profileId;
		}

		AddMachineGroupsFilterQueryParam(params);

		return {
			params: params,
		};
	},
};

export const AddODataSearchQueryParam = (dataQuery: DataQuery, queryParams) => {
	if (dataQuery.where && dataQuery.where.search) {
		Object.assign(queryParams, { search: dataQuery.where.search });
	}
};

export const AddODataSortingQueryParam = (dataQuery: DataQuery, queryParams) => {
	if (dataQuery.sortBy && dataQuery.sortBy.length) {
		queryParams['$orderby'] = dataQuery.sortBy
			.map(field => ({
				direction: field.direction === DataQuerySortDirection.descending ? 'desc' : 'asc',
				fields: fieldIdOrderMap[field.field] || [field.field],
			}))
			.map(obj => obj.fields.map((field: string) => `${field} ${obj.direction}`).join(','))
			.join(',');
	}
};

const SUPPORTED_SCA_TAGS = [
	SCA_RECOMMENDATION_FILTER_TAG.covid19,
	SCA_RECOMMENDATION_FILTER_TAG.userImpactAssessment,
	SCA_RECOMMENDATION_FILTER_TAG.humanOperatedRansomware,
];

const AddScaTagsQueryParam = (dataQuery: DataQuery, queryParams) => {
	const filterTagsDataQueryKey = 'recommendationTags';
	if (!dataQuery || !dataQuery.where || !dataQuery.where[filterTagsDataQueryKey]) {
		return;
	}

	const filterTags = dataQuery.where[filterTagsDataQueryKey];
	const recommendationTags = [];
	filterTags.forEach(t => {
		// Not very nice code - will be removed when BE support for tags will align (preserving current behavior)
		// Tag should be either SCA and in supported array or one of the 3 currently supported VA tags
		if (t in SCA_RECOMMENDATION_FILTER_TAG && SUPPORTED_SCA_TAGS.includes(t)) {
			recommendationTags.push(t);
		} else {
			// Handling current supported VA tags
			switch (t) {
				case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
					recommendationTags.push(VulnerabilityType.ZeroDay);
					break;
				case VA_RECOMMENDATION_FILTER_TAG.alreadyEOL:
					recommendationTags.push(ANALYTICS_FIELDS.alreadyEOL);
					break;
				/*
				TODO: When adding filter on upcomingEolSoftware - uncomment this line
				case VA_RECOMMENDATION_FILTER_TAG.upcomingEOL:
					recommendationTags.push(ANALYTICS_FIELDS.upcomingEOL);
					break;
				*/
				case VA_RECOMMENDATION_FILTER_TAG.hasEolVersions:
					recommendationTags.push(ANALYTICS_FIELDS.hasEolVersions);
					break;
				case VA_RECOMMENDATION_FILTER_TAG.hasUpcomingEolVersions:
					recommendationTags.push(ANALYTICS_FIELDS.hasUpcomingEolVersions);
					break;
			}
		}
	});
	// Removing the used recommendations tags to stop the next flow of OData $filter param construction
	delete dataQuery.where[filterTagsDataQueryKey];
	queryParams['tags'] = recommendationTags.join(',');
};

export const ParseDataQuery = (dataQuery: DataQuery, context?: string, nonPagedQuery: boolean = false) => {
	const params: any = {};

	AddMachineGroupsFilterQueryParam(params);

	if (!dataQuery) return params;

	if (dataQuery.pageSize !== undefined && !nonPagedQuery) {
		params.pageIndex = dataQuery.page || 1;
		params.pageSize = dataQuery.pageSize;
	}
	// a temp solution the call must be made before the OData filter query params since SCA tags are using the same
	// tags mechanism and this call will remove SCA tags from data query to not damage the next flow
	AddScaTagsQueryParam(dataQuery, params);

	AddODataSearchQueryParam(dataQuery, params);

	AddODataFilterQueryParam(dataQuery, params, fieldFiltersMap, context);

	AddODataSortingQueryParam(dataQuery, params);

	AddIdParam(dataQuery, params);

	return params;
};

export const AddIdParam = (dataQuery: DataQuery, queryParams) => {
	const idDataQueryKeys = [
		'productId',
		'recommendationId',
		'installationId',
		'extensionId',
		'evidenceId',
		'internalPathId',
	];
	if (!dataQuery || !dataQuery.where || !idDataQueryKeys.some(idKey => dataQuery.where[idKey])) {
		return;
	}

	idDataQueryKeys.forEach(idKey => {
		if (dataQuery.where[idKey]) {
			queryParams[idKey] = dataQuery.where[idKey];
		}
	});
};

export const FilterByNameInsteadOfSearch = (dataQuery: DataQuery) => {
	if (dataQuery.where.search) {
		dataQuery.where['name'] = dataQuery.where.search;
		delete dataQuery.where.search;
	}
	return dataQuery;
};

export const TvmAnalyticsSharedEntityConfigurations: Partial<EntityConfig<any>> = {
	...SharedConfig,
	parseDataQuery: dataQuery => ParseDataQuery(dataQuery),
	parseDataSet: (response: ITvmAnalyticsResponse<any>): DataSet<any> => {
		return {
			count: response.numOfResults,
			items: response.results,
			meta: response.meta,
		};
	},
};

export const TvmAnalyticsSharedEntityRelationshipConfigurations = {
	...SharedConfig,
	parseDataQuery: dataQuery => ParseDataQuery(dataQuery),
};

/**
 * we are stopping manipulating display texts in our domain. use tvm-texts.service.ts instead.
 * @deprecated
 */
export const parseEntityName = (rawName: string) => {
	return rawName
		.split('_')
		.map(s => s.charAt(0).toUpperCase() + s.substring(1))
		.join(' ')
		.replace(' For Mac', ' for Mac')
		.replace(' For Linux', ' for Linux'); // TODO: clean this up once we have OS Kind column (Task: 26044745)
};
