import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Get Email body',
	endpoint: (config, query) =>
		query && query.where && query.where['EnableNewMailItemAPI']
			? 'MailItem/GetMessageBody'
			: 'MailItem/GetMailMessageBody',
	baseUrl: '<dgs>',
	method: 'POST',
	parseQuery: (params: GetEmailBodyApiCallParams) => {
		return {
			data: JSON.stringify({
				internetMessageId: params.internetMessageId,
				mailboxId: params.recipient,
				receivedTime: params.receivedTime ? params.receivedTime.toISOString() : undefined,
			}),
			params: {
				EnableNewMailItemAPI: params.EnableNewMailItemAPI,
			},
		};
	},
})
export class GetEmailBodyApiCall extends ApiCallModel<void, GetEmailBodyApiCallParams> {}

interface GetEmailBodyApiCallParams {
	internetMessageId: string;
	recipient: string;
	receivedTime?: Date;
	EnableNewMailItemAPI: boolean;
}
