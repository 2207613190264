import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Device Group Device',
	pluralName: 'Device Group Device',
	readonly: true,
})
export class MachineGroupMachine extends ModelBase {
	@EntityField({ data: 'Name' })
	name: string;
	@EntityField({ data: 'Domain' })
	domain: string;
}
