import { Observable } from 'rxjs';
import { ReadOnlyIdentifiableConfig } from '../../data/models/readonly-identifiable.model';
import { ReportOptions } from '../../shared-reports/services/reports.service';
import { ReportWidgetMenuItem } from './report-widget-menu-item.interface';
import { MdeUserRoleActionEnum } from '@wcd/domain';

export class ReportWidgetModel<TData = any, TRawData = TData, TDataOptions = {}>
	implements ReadOnlyIdentifiableConfig {
	/**
	 * The unique ID of the widget
	 */
	id: string;

	/**
	 * The name of the widget - will be displayed in the widget's header
	 */
	name?: string;

	/**
	 * The help of the widget - will be displayed in the widget's header near the name
	 */
	help?: string;

	/**
	 * If the widget header is allowed to render the tooltip string as HTML, `helpTooltipAllowHtmlRendering` for the widget allows to render the tooltip string as HTML.
	 * @default false
	 */
	helpTooltipAllowHtmlRendering?: boolean;

	/**
	 * If specified, the widget's data will be fetched from this URL (or URLs, it may be multiple calls)
	 */
	api?: ApiConfig | Array<ApiConfig>;

	/**
	 * A class name to give to the widget's content's HTML element
	 */
	bodyClassName?: string;

	/**
	 * A class name to give to the widget's HTML element
	 */
	className?: string;

	/**
	 * A function that returns the name of the widget - use when the widget's title should be affected
	 * by the data
	 */
	getName?: ReportWidgetPropertyFunction<TData>;

	/**
	 * HTML to place opposite the widget's title.
	 * Note - this HTML will be sanitized.
	 */
	extraHeaderHtml?: ReportWidgetPropertyFunction<TData>;

	/**
	 * Whether to skip reloading new data
	 */
	shouldLoadData?: (options?: TDataOptions) => boolean;

	/**
	 * A function that returns an Observable for loading the widget's data.
	 * Overrides the api property, if exists.
	 * Return null for TData to specify that the widget is considered empty.
	 */
	loadData?: (options?: TDataOptions) => Observable<TRawData>;

	/**
	 * A specific height for the widget
	 */
	height?: string;

	/**
	 * Whether to not display the widget when its data is null.
	 * @default false
	 */
	hideOnNoData?: boolean;

	/**
	 * A maximum width, in pixels, to allow the widget to stretch horizontally
	 */
	maxWidth?: string;

	/**
	 * The maximum height of the widget, in pixels
	 */
	maxHeight?: string;

	/**
	 * The minimum width allowed for the widget, in pixels
	 */
	minWidth?: string;

	/**
	 * The minimum height allowed for the widget, in pixels
	 */
	minHeight?: string;

	/**
	 * An array of items for the widget's dropdown menu.
	 * If no items are specified, the widget doesn't have a menu.
	 */
	menu?: ReadonlyArray<ReportWidgetMenuItem>;

	/**
	 * A message to display in the widget, in the case that the data is null.
	 * There is a default message, so this is optional.
	 */
	noDataMessage?: string | (() => string);

	/**
	 * The name of an icon to display in the widget in case it has no data.
	 */
	noDataIcon?: string;

	/**
	 * Whether to not display icon and align text to the left when widget data is null.
	 */
	NoIconLeftAlign?: boolean;

	/**
	 * A function used to map the widget's data after it's been fetched.
	 * The parse happens both for when the data is fetched using the api property, or loadData.
	 * @param data
	 * @returns {TData}
	 */
	parseData?: (data: TRawData) => TData;

	/**
	 * Whether to display the widget's header
	 * @default true
	 */
	showHeader?: boolean;

	/**
	 * Some state-specific params for the widget's data - range and timezone, used to fetch data.
	 */
	state?: ReportOptions;

	/**
	 * If true, the widget will be displayed as disabled, and no data will be fetched.
	 * @default false
	 */
	isDisabled?: boolean;

	/**
	 * The number of days this widget's data represents
	 * Note - this is display-only, this parameter won't be sent to the widget's api.
	 */
	rangeInDays?: number | ReportWidgetPropertyFunction<TData, number>;

	/**
	 * Required permission to view the data
	 */
	requiredPermissions?: MdeUserRoleActionEnum;

	/**
	 * The date of the data this widget's represents
	 * Note - this is display-only, this parameter won't be sent to the widget's api.
	 */
	getDateOfWidget?: (data?: TData) => Date;
	getDateRangeOfWidget?: (data?: TData) => [Date, Date];

	constructor(config: ReportWidgetConfig<TData, TRawData, TDataOptions>) {
		Object.assign(this, config);

		this.showHeader = this.showHeader !== false;

		Object.freeze(this);
	}
}

export type ReportWidgetConfig<
	TData = any,
	TRawData = TData,
	TDataOptions = {}
> = ReadOnlyIdentifiableConfig & Partial<ReportWidgetModel<TData, TRawData, TDataOptions>>;

export type ReportWidgetPropertyFunction<TData = any, TReturnType = string> = (
	data: TData,
	params?: { [index: string]: any }
) => TReturnType;

export interface ApiConfig {
	url: string | Function;
	/**
	 * Prefix the url with the `automatedIr` service.
	 */
	isExternal?: boolean;
	params?: { [index: string]: any };
}
