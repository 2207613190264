import { DataviewField } from '@wcd/dataview';
import {
	RemediationAction,
	RemediationActionEntity,
	RemediationActionTypeTypes,
	OfficeUtils,
} from '@wcd/domain';
import { uniq } from 'lodash-es';
import { FabricIconNames } from '@wcd/scc-common';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { MachineNameComponent } from '../../../shared/components/machine-name.component';
import { MailboxDetailsFieldComponent } from '../../mailboxes/components/mailbox-details-field.component';
import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../mtp_investigations/services/threats-display-name-field.service';

@Injectable()
export class RemediationActionTypeFieldsService {
	constructor(
		private i18nService: I18nService,
		private threatsDisplayNameService: ThreatsDisplayNameService
	) {}

	private commonRemediationActionTypeFields: { [index: string]: DataviewField<RemediationAction> } = {
		investigation: new DataviewField<RemediationAction>({
			id: 'investigationId',
			name: this.i18nService.strings.actionCenter_fields_InvestigationId,
			getDisplay: (action: RemediationAction) => OfficeUtils.getShortId(action.investigationId),
			getLink: (action: RemediationAction) => {
				const investigationStr = action.isOfficeInvestigation ? 'mtp-investigation' : 'investigation';
				return `/${investigationStr}/${action.investigationId}`;
			},
			sort: { enabled: false },
		}),
		machine: new DataviewField<RemediationAction>({
			id: 'endpoint_name',
			name: this.i18nService.strings.machine,
			component: {
				type: MachineNameComponent,
				getProps: (action: RemediationAction) => ({ machine: action.machine, showIcon: true }),
			},
			sort: { enabled: false },
		}),
		loggedInUser: new DataviewField<RemediationAction>({
			id: 'logged_in_user',
			name: this.i18nService.strings.remediation_fields_loggedInUsers,
			listValues: {
				path: 'host.users',
				isCommaSeparated: false,
				field: {
					id: 'user',
					name: 'User',
					icon: {
						fabricIcon: FabricIconNames.Contact,
					},
					getCssClass: () => 'nowrap',
				},
			},
			custom: {
				isLongContents: true,
			},
			sort: { enabled: false },
		}),
		threatType: new DataviewField<RemediationAction>({
			id: 'threat_type',
			name: this.i18nService.strings.remediation_fields_threatType,
			getDisplay: (action: RemediationAction) => action.file.threatType,
			getCssClass: action => (action.file.threatType ? 'status-label color-box-malware' : null),
			sort: { enabled: false },
		}),
		created: new DataviewField<RemediationAction>({
			id: 'created',
			name: this.i18nService.strings.remediation_fields_timeCreated,
			component: {
				type: TzDateComponent,
				getProps: (action: RemediationAction) => ({ date: action.file && action.file.created }),
			},
			sort: { enabled: false },
		}),
		processName: new DataviewField<RemediationAction>({
			id: 'name',
			name: this.i18nService.strings.remediation_fields_processName,
			getDisplay: (action: RemediationAction) => action.process.name,
			getLink: (action: RemediationAction) => '.',
			getLinkQueryParams: (action: RemediationAction) => {
				return { show_entity: `processes.${action.process.id}` };
			},
			custom: {
				isFieldName: true,
				getName: action => action.process.name,
				isLongContents: true,
			},
			sort: { enabled: false },
		}),
		processId: new DataviewField<RemediationAction>({
			id: 'processId',
			name: this.i18nService.strings.remediation_fields_processId,
			getDisplay: (action: RemediationAction) => action.process.pid,
			getLink: (action: RemediationAction) => '.',
			getLinkQueryParams: (action: RemediationAction) => {
				return { show_entity: `processes.${action.process.id}` };
			},
			sort: { enabled: false },
		}),
		processUser: new DataviewField<RemediationAction>({
			id: 'processUser',
			name: this.i18nService.strings.remediation_fields_processUser,
			getDisplay: (action: RemediationAction) => action.process.username,
			sort: { enabled: false },
		}),
		processPath: new DataviewField<RemediationAction>({
			id: 'processPath',
			name: this.i18nService.strings.remediation_fields_filePath,
			getDisplay: (action: RemediationAction) => action.process.path,
			custom: {
				isLongContents: true,
			},
			sort: { enabled: false },
		}),
		filePath: new DataviewField<RemediationAction>({
			id: 'path',
			name: this.i18nService.strings.remediation_fields_filePath,
			getDisplay: (action: RemediationAction) => action.file.path,
			className: 'break',
			custom: {
				isFieldName: true,
				isLongContents: true,
				getName: (action: RemediationAction) => action.file.path,
			},
			sort: { enabled: false },
		}),
		remediationPackageEntitiesCount: new DataviewField<RemediationAction>({
			id: 'entities_count',
			name: this.i18nService.strings.actionCenter_fields_remediationBundle,
			getDisplay: (action: RemediationAction) =>
				!!(action.relatedEntities && action.relatedEntities.length)
					? action.relatedEntities.length > 1
						? this.i18nService.get('actionsHistory_remediationBundle_plural', {
								count: action.relatedEntities.length,
						  })
						: this.i18nService.strings.actionsHistory_remediationBundle_singular
					: '',
			icon: {
				fabricIcon: (action: RemediationAction) =>
					action.relatedEntities ? FabricIconNames.FabricFormLibrary : null,
			},
			custom: {
				isLongContents: true,
			},
		}),
		reason: new DataviewField<RemediationAction>({
			id: 'reason',
			name: this.i18nService.strings.remediation_fields_reason,
			custom: {
				isLongContents: true,
			},
			sort: { enabled: false },
		}),
		userName: new DataviewField<RemediationAction>({
			id: 'username',
			name: this.i18nService.strings.remediation_fields_username,
			getDisplay: (action: RemediationAction) => action.user.username,
			custom: {
				isFieldName: true,
				getName: action => action.user.username,
			},
			sort: { enabled: false },
		}),
		userId: new DataviewField<RemediationAction>({
			id: 'id',
			name: this.i18nService.strings.remediation_fields_userId,
			getDisplay: (action: RemediationAction) => action.user.id,
			sort: { enabled: false },
		}),
		machinesCount: new DataviewField<RemediationAction>({
			id: 'machinesCount',
			name: this.i18nService.strings.machines,
			getDisplay: (action: RemediationAction) =>
				action.machines
					? this.i18nService.get('investigations_fields_deviceCount', {
							deviceCount: action.machines.length,
					  })
					: '',
			sort: { enabled: false },
		}),
		machineNames: new DataviewField<RemediationAction>({
			id: 'hosts',
			name: this.i18nService.strings.machines,
			getDisplay: (action: RemediationAction) => {
				if (!action.machines) return '';

				return action.machines.length < 4
					? action.machines.map(machine => machine.name).join(', ')
					: this.i18nService.get('investigations_fields_deviceCount', {
							deviceCount: action.machines.length,
					  });
			},
			sort: { enabled: false },
		}),
		entityType: new DataviewField<RemediationAction>({
			id: 'entityType',
			name: this.i18nService.strings.actionCenter_fields_EntityType,
			getDisplay: (action: RemediationAction) => {
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster
					? this.i18nService.strings.remediation_fields_mailCluster
					: this.i18nService.strings.remediation_fields_mailMessage;
			},
			sort: { enabled: false },
		}),
		firstSeenMailboxConfig: new DataviewField<RemediationAction>({
			id: 'firstSeen',
			name: this.i18nService.strings.remediation_fields_firstSeen,
			component: {
				type: TzDateComponent,
				getProps: (action: RemediationAction) => ({
					date: action.mailboxConfiguration && action.mailboxConfiguration.firstSeen,
				}),
			},
			sort: { enabled: false },
		}),
		firstSeenMailMessageAndCluster: new DataviewField<RemediationAction>({
			id: 'firstSeen',
			name: this.i18nService.strings.remediation_fields_firstSeen,
			component: {
				type: TzDateComponent,
				getProps: (action: RemediationAction) => ({
					date: action.mailMessage ? action.mailMessage.firstSeen : action.mailCluster.firstSeen,
				}),
			},
			sort: { enabled: false },
		}),
		mailboxPrimaryAddress: new DataviewField<RemediationAction>({
			id: 'mailbox',
			name: this.i18nService.strings.investigation_graph_tabs_mailboxes_singular,
			getDisplay: (action: RemediationAction) => {
				if (!action.mailboxConfiguration) return '';
				return action.mailboxConfiguration.mailboxPrimaryAddress;
			},
			sort: { enabled: false },
		}),
	};

	private emailRemediationActionFields: Array<DataviewField<RemediationAction>> = [
		this.commonRemediationActionTypeFields.investigation,
		this.commonRemediationActionTypeFields.firstSeenMailMessageAndCluster,
		new DataviewField<RemediationAction>({
			id: 'details',
			name: this.i18nService.strings.actionCenter_fields_details,
			className: 'nowrap wcd-text-overflow-medium',
			component: {
				type: MailboxDetailsFieldComponent,
				getProps: (action: RemediationAction) => ({
					displayQuery: action.mailCluster ? action.mailCluster.displayQuery : '',
					senderEmailAddress: action.mailMessage ? action.mailMessage.sender : '',
					recipientEmailAddress: action.mailMessage ? action.mailMessage.recipient : '',
					showTooltip: true,
				}),
			},
			sort: { enabled: false },
		}),
		new DataviewField<RemediationAction>({
			id: 'email_count',
			name: this.i18nService.strings.airsEntities_emailCluster_fields_emailCount,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Email count field not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.mailCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'malware_count',
			name: this.i18nService.strings.remediation_fields_malware,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of malware emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.malwareCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'phish_count',
			name: this.i18nService.strings.remediation_fields_phish,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of phish emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.phishCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'high_confidence_phish_count',
			name: this.i18nService.strings.remediation_fields_highConfidencePhish,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of high confidence phish emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.highConfidencePhishCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'spam_count',
			name: this.i18nService.strings.remediation_fields_spam,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of spam emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.spamCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'delivered_count',
			name: this.i18nService.strings.remediation_fields_delivered,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of delivered emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.deliveredCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'junked_count',
			name: this.i18nService.strings.remediation_fields_junked,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of junked emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.junkedCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'replaced_count',
			name: this.i18nService.strings.remediation_fields_replaced,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of replaced emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.replacedCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'blocked_count',
			name: this.i18nService.strings.remediation_fields_blocked,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of blocked emails not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.blockedCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'mailbox_count',
			name: this.i18nService.strings.remediation_fields_mailbox,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of mails that exists in the mailbox not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.mailboxCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'not_in_mailbox_count',
			name: this.i18nService.strings.remediation_fields_notInMailbox,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Amount of mails that not exists in the mailbox not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.notInMailboxCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'on_prem_or_external_count',
			name: this.i18nService.strings.remediation_fields_onPremOrExternal,
			getDisplay(action: RemediationAction) {
				// EmailRemediation action is for MailMessage and MailCluster
				// Delivered to on-prem or external count not relevant for EmailMessage, hence, value should be '-'
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.onPremOrExternalCount : '-';
			},
		}),
		new DataviewField<RemediationAction>({
			id: 'mailbox',
			name: this.i18nService.strings.investigation_graph_tabs_mailboxes_singular,
			getDisplay: (action: RemediationAction) => {
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster
					? action.mailCluster.mailboxPrimaryAddress
					: action.mailMessage.mailboxPrimaryAddress;
			},
			sort: { enabled: false },
		}),
		this.commonRemediationActionTypeFields.entityType,
		new DataviewField<RemediationAction>({
			id: 'threat_type',
			name: this.i18nService.strings.remediation_fields_threatType,
			getDisplay: (action: RemediationAction) =>
				this.threatsDisplayNameService.getThreatsDisplayName(
					(action.mailCluster && action.mailCluster.threatsI18nKeys) ||
						(action.mailMessage && action.mailMessage.threatsI18nKeys) ||
						[]
				),
			getCssClass: (action: RemediationAction) =>
				this.threatsDisplayNameService.getThreatsDisplayName(
					(action.mailCluster && action.mailCluster.threatsI18nKeys) ||
						(action.mailMessage && action.mailMessage.threatsI18nKeys) ||
						[]
				)
					? 'status-label color-box-malware'
					: null,
			sort: { enabled: false },
		}),
		new DataviewField<RemediationAction>({
			id: 'subject',
			name: this.i18nService.strings.remediation_fields_mailSubject,
			getDisplay: (action: RemediationAction) => {
				if (!action.mailCluster && !action.mailMessage) return '';
				return action.mailCluster ? action.mailCluster.subject : action.mailMessage.subject;
			},
			sort: { enabled: false },
		}),
	];

	private remediationActionTypeFields = {
		common: this.commonRemediationActionTypeFields,
		process: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			this.commonRemediationActionTypeFields.processName,
			this.commonRemediationActionTypeFields.processId,
			this.commonRemediationActionTypeFields.remediationPackageEntitiesCount,
			new DataviewField<RemediationAction>({
				id: 'user',
				name: this.i18nService.strings.remediation_fields_processUser,
				getDisplay: (action: RemediationAction) => action.process.username,
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) => action.process.threatType,
				getCssClass: action => (action.process.threatType ? 'status-label color-box-malware' : null),
				sort: { enabled: false },
			}),
		],
		processes: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			new DataviewField<RemediationAction>({
				id: 'processes',
				name: this.i18nService.strings.remediation_fields_processNames,
				listValues: {
					path: 'processes',
					field: {
						id: 'process',
						name: 'Process',
						getDisplay: (process: RemediationActionEntity) => `${process.name} (${process.pid})`,
						className: 'nowrap',
					},
				},
				custom: {
					isFieldName: true,
					isLongContents: true,
					getName: (action: RemediationAction) => {
						if (!action.processes) return '';

						return action.processes.length < 4
							? action.processes.map(process => process.name).join(', ')
							: this.i18nService.strings.remediation_fields_processCount;
					},
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'processes_paths',
				name: this.i18nService.strings.remediation_fields_processPaths,
				listValues: {
					path: 'processes',
					field: {
						id: 'process',
						name: 'Process',
						getDisplay: (process: RemediationActionEntity) => process.path,
					},
				},
				custom: {
					isLongContents: true,
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) =>
					uniq(action.processes.map(process => process.threatType)).join(', '),
				getCssClass: action =>
					uniq(action.processes.map(process => process.threatType)).join(', ')
						? 'status-label color-box-malware'
						: null,
				sort: { enabled: false },
			}),
		],
		service: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			new DataviewField<RemediationAction>({
				id: 'name',
				name: this.i18nService.strings.actionCenter_fields_serviceName,
				getDisplay: (action: RemediationAction) => action.service.name,
				custom: {
					isFieldName: true,
					getName: function(action) {
						return action.service.name;
					},
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'bin_path',
				name: this.i18nService.strings.remediation_fields_path,
				getDisplay: (action: RemediationAction) => action.service.binPath,
				sort: { enabled: false },
			}),
			this.commonRemediationActionTypeFields.remediationPackageEntitiesCount,
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) => action.service.threatType,
				getCssClass: action => (action.service.threatType ? 'status-label color-box-malware' : null),
				sort: { enabled: false },
			}),
		],
		driver: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			new DataviewField<RemediationAction>({
				id: 'name',
				name: this.i18nService.strings.remediation_fields_path,
				getDisplay: (action: RemediationAction) => action.driver.name,
				custom: {
					isFieldName: true,
					getName: function(action) {
						return action.driver.name;
					},
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'bin_path',
				name: this.i18nService.strings.remediation_fields_path,
				getDisplay: (action: RemediationAction) => action.driver.binPath,
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_path,
				getDisplay: (action: RemediationAction) => action.driver.threatType,
				getCssClass: action => (action.driver.threatType ? 'status-label color-box-malware' : null),
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'driver_type',
				name: this.i18nService.strings.actionCenter_fields_driverType,
				getDisplay: (action: RemediationAction) => action.driver.driverType,
				sort: { enabled: false },
			}),
		],
		file: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			this.commonRemediationActionTypeFields.filePath,
			this.commonRemediationActionTypeFields.remediationPackageEntitiesCount,
			new DataviewField<RemediationAction>({
				id: 'created',
				name: this.i18nService.strings.remediation_fields_fileCreatedDate,
				className: 'nowrap',
				sort: { enabled: false },
				component: {
					type: TzDateComponent,
					getProps: (action: RemediationAction) => ({ date: action.file && action.file.created }),
				},
			}),
			this.commonRemediationActionTypeFields.threatType,
		],
		user: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.userName,
			this.commonRemediationActionTypeFields.machine,
		],
		fileProcess: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.machine,
			this.commonRemediationActionTypeFields.processUser,
			this.commonRemediationActionTypeFields.filePath,
			this.commonRemediationActionTypeFields.processName,
			this.commonRemediationActionTypeFields.processId,
			this.commonRemediationActionTypeFields.threatType,
		],
		ipAddress: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'address',
				name: this.i18nService.strings.remediation_fields_ipAddress,
				getDisplay: (action: RemediationAction) => action.address.address,
				custom: {
					isFieldName: true,
					getName: (action: RemediationAction) => action.address.address,
				},
				sort: { enabled: false },
			}),
		],
		url: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'address',
				name: this.i18nService.strings.entity_type_display_name_url,
				getDisplay: (action: RemediationAction) => action.address.address,
				getLinkQueryParams: (action: RemediationAction) => {
					return { show_entity: `urls.${action.address.id}` };
				},
				custom: {
					isFieldName: true,
					getName: (action: RemediationAction) => action.address.address,
				},
				sort: { enabled: false },
			}),
		],
		connection: [
			new DataviewField<RemediationAction>({
				id: 'host',
				name: this.i18nService.strings.machine,
				getDisplay: (action: RemediationAction) => action.machine.name,
			}),
			new DataviewField<RemediationAction>({
				id: 'address',
				name: this.i18nService.strings.remediation_fields_ipAddress,
				getDisplay: (action: RemediationAction) => action.address.address,
			}),
			this.commonRemediationActionTypeFields.investigation,
		],
		persistenceMethod: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'name',
				name: this.i18nService.strings.actionCenter_fields_persistenceMethodName,
				getDisplay: (action: RemediationAction) => action.persistenceMethod.name,
				getLink: (action: RemediationAction) => '.',
				getLinkQueryParams: (action: RemediationAction) => {
					return { show_entity: `persistence_methods.${action.persistenceMethod.id}` };
				},
				custom: {
					isFieldName: true,
					getName: function(action) {
						return action.persistenceMethod.name;
					},
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'command_line',
				name: this.i18nService.strings.actionCenter_fields_commandLine,
				getDisplay: (action: RemediationAction) => action.persistenceMethod.commandLine,
				sort: { enabled: false },
			}),
			this.commonRemediationActionTypeFields.remediationPackageEntitiesCount,
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) => action.persistenceMethod.threatType,
				getCssClass: action =>
					action.persistenceMethod.threatType ? 'status-label color-box-malware' : null,
				sort: { enabled: false },
			}),
		],
		multipleInvestigations: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.reason,
			this.commonRemediationActionTypeFields.machineNames,
			new DataviewField<RemediationAction>({
				id: 'entity',
				name: this.i18nService.strings.remediation_fields_entityFound,
				getDisplay: (action: RemediationAction) => {
					if (action.address) return action.address.address;

					if (action.file) return action.file.path;

					if (action.appendedFile)
						return action.appendedFile.name ? action.appendedFile.name : action.appendedFile.sha1;

					return '';
				},
				getLink: () => '.',
				getLinkQueryParams: (action: RemediationAction) => {
					if (action.address) return { show_entity: `ip_addresses.${action.address.id}` };

					if (action.file) return { show_entity: `files.${action.file.id}` };

					if (action.appendedFile)
						return { show_entity: `appended files.${action.appendedFile.sha1}` };

					return null;
				},
				custom: {
					isLongContents: true,
				},
			}),
		],
		resource: [
			this.commonRemediationActionTypeFields.reason,
			this.commonRemediationActionTypeFields.machine,
			new DataviewField<RemediationAction>({
				id: 'recommendation',
				name: this.i18nService.strings.remediation_fields_recommendation,
				getDisplay: (action: RemediationAction) => action.details.recommendation,
			}),
			this.commonRemediationActionTypeFields.investigation,
		],
		investigatedUser: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'upn',
				name: this.i18nService.strings.remediation_fields_username,
				getDisplay: (action: RemediationAction) => action.investigatedUser.upn,
			}),
			new DataviewField<RemediationAction>({
				id: 'full_name',
				name: this.i18nService.strings.remediation_fields_userFullName,
				getDisplay: (action: RemediationAction) => action.investigatedUser.fullName,
			}),
			new DataviewField<RemediationAction>({
				id: 'user_object_id',
				name: this.i18nService.strings.remediation_fields_userAadId,
				getDisplay: (action: RemediationAction) => action.investigatedUser.aadId,
			}),
		],
		mailForwardingRuleRemediation: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'firstSeen',
				name: this.i18nService.strings.remediation_fields_firstSeen,
				component: {
					type: TzDateComponent,
					getProps: (action: RemediationAction) => ({
						date: action.mailboxConfiguration
							? action.mailboxConfiguration.firstSeen
							: action.mailbox.firstSeen,
					}),
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'smtpAddress',
				name: this.i18nService.strings.remediation_fields_externalSMTPAddress,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration && !action.mailbox) return '';
					return action.mailboxConfiguration
						? action.mailboxConfiguration.forwardingSmtpAddress
						: action.mailbox.forwardingSmtpAddress;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'createdBy',
				name: this.i18nService.strings.remediation_fields_createdBy,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration && !action.mailbox) return '';
					return action.mailboxConfiguration
						? action.mailboxConfiguration.createdBy
						: action.mailbox.createdBy;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'mailbox',
				name: this.i18nService.strings.investigation_graph_tabs_mailboxes_singular,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration && !action.mailbox) return '';
					return action.mailboxConfiguration
						? action.mailboxConfiguration.mailboxPrimaryAddress
						: action.mailbox.mailboxPrimaryAddress;
				},
				sort: { enabled: false },
			}),
		],
		forcePasswordResetRemediation: [
			this.commonRemediationActionTypeFields.investigation,
			new DataviewField<RemediationAction>({
				id: 'firstSeen',
				name: this.i18nService.strings.remediation_fields_firstSeen,
				component: {
					type: TzDateComponent,
					getProps: (action: RemediationAction) => ({
						date: action.mailbox && action.mailbox.firstSeen,
					}),
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'mailbox',
				name: this.i18nService.strings.investigation_graph_tabs_mailboxes_singular,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailbox) return '';
					return action.mailbox.mailboxPrimaryAddress;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'riskLevel',
				name: this.i18nService.strings.remediation_fields_riskLevel,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailbox) return '';
					return action.mailbox.riskLevel;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) =>
					this.threatsDisplayNameService.getThreatsDisplayName(
						(action.mailbox && action.mailbox.threatsI18nKeys) || []
					),
				getCssClass: (action: RemediationAction) =>
					this.threatsDisplayNameService.getThreatsDisplayName(
						(action.mailbox && action.mailbox.threatsI18nKeys) || []
					)
						? 'status-label color-box-malware'
						: null,
				sort: { enabled: false },
			}),
		],
		userInboxRulesRemediation: [
			this.commonRemediationActionTypeFields.firstSeenMailboxConfig,
			this.commonRemediationActionTypeFields.mailboxPrimaryAddress,
			new DataviewField<RemediationAction>({
				id: 'threat_type',
				name: this.i18nService.strings.remediation_fields_threatType,
				getDisplay: (action: RemediationAction) =>
					this.threatsDisplayNameService.getThreatsDisplayName(
						(action.mailboxConfiguration && action.mailboxConfiguration.threatsI18nKeys) || []
					),
				getCssClass: (action: RemediationAction) =>
					this.threatsDisplayNameService.getThreatsDisplayName(
						(action.mailboxConfiguration && action.mailboxConfiguration.threatsI18nKeys) || []
					)
						? 'status-label color-box-malware'
						: null,
				sort: { enabled: false },
			}),
		],
		generic: [this.commonRemediationActionTypeFields.investigation],
		quarantineRegistryKey: [this.commonRemediationActionTypeFields.investigation],
		blockSender: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.firstSeenMailMessageAndCluster,
			new DataviewField<RemediationAction>({
				id: 'details',
				name: this.i18nService.strings.actionCenter_fields_details,
				component: {
					type: MailboxDetailsFieldComponent,
					getProps: (action: RemediationAction) => ({
						displayQuery: action.mailCluster ? action.mailCluster.displayQuery : '',
						senderEmailAddress: action.mailMessage
							? action.mailMessage.sender || action.mailMessage.p1Sender
							: '',
						recipientEmailAddress: action.mailMessage ? action.mailMessage.recipient : '',
					}),
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'mailbox',
				name: this.i18nService.strings.investigation_graph_tabs_mailboxes_singular,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailCluster && !action.mailMessage) return '';
					return action.mailCluster
						? action.mailCluster.mailboxPrimaryAddress
						: action.mailMessage.mailboxPrimaryAddress;
				},
				sort: { enabled: false },
			}),
			this.commonRemediationActionTypeFields.entityType,
		],
		blockSenderDomain: [this.commonRemediationActionTypeFields.investigation],
		blockSpoFile: [this.commonRemediationActionTypeFields.investigation],
		emailRemediation: this.emailRemediationActionFields,
		ewsSettingChangeRemediation: [this.commonRemediationActionTypeFields.investigation],
		mailDelegationRemediation: [
			this.commonRemediationActionTypeFields.investigation,
			this.commonRemediationActionTypeFields.firstSeenMailboxConfig,
			new DataviewField<RemediationAction>({
				id: 'operation',
				name: this.i18nService.strings.remediation_fields_operation,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration) return '';
					return action.mailboxConfiguration.operation;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'accessRights',
				name: this.i18nService.strings.remediation_fields_accessRights,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration) return '';
					return action.mailboxConfiguration.accessRights;
				},
				sort: { enabled: false },
			}),
			new DataviewField<RemediationAction>({
				id: 'createdBy',
				name: this.i18nService.strings.remediation_fields_createdBy,
				getDisplay: (action: RemediationAction) => {
					if (!action.mailboxConfiguration) return '';
					return action.mailboxConfiguration.createdBy;
				},
				sort: { enabled: false },
			}),
			this.commonRemediationActionTypeFields.mailboxPrimaryAddress,
		],
		owaSettingChangeRemediation: [this.commonRemediationActionTypeFields.investigation],
		hardEmailRemediation: this.emailRemediationActionFields,
		moveToInbox: this.emailRemediationActionFields,
		moveToJunk: this.emailRemediationActionFields,
		moveToDeletedItems: this.emailRemediationActionFields,
		deleteAttachment: this.emailRemediationActionFields,
		submitForDetonation: this.emailRemediationActionFields,
		enableUser: [this.commonRemediationActionTypeFields.investigation],
		disableUser: [this.commonRemediationActionTypeFields.investigation],
	};

	remediationTypeFields: Partial<Record<RemediationActionTypeTypes, Array<DataviewField>>> = {
		file_quarantine: this.remediationActionTypeFields.file,
		service_quarantine: this.remediationActionTypeFields.service,
		driver_quarantine: this.remediationActionTypeFields.driver,
		release_file: this.remediationActionTypeFields.fileProcess,
		kill_process: this.remediationActionTypeFields.process,
		kill_processes: this.remediationActionTypeFields.processes,
		// freeze_process: remediationActionTypeFields.process,
		// close_connection: remediationActionTypeFields.connection,
		block_ip: this.remediationActionTypeFields.ipAddress,
		block_url: this.remediationActionTypeFields.url,
		pending_resource: this.remediationActionTypeFields.resource,
		initiate_dlp: this.remediationActionTypeFields.user,
		limit_user_privileges_info: this.remediationActionTypeFields.user,
		remove_user_from_local_group: this.remediationActionTypeFields.user,
		start_multiple_investigations: this.remediationActionTypeFields.multipleInvestigations,
		persistence_method_quarantine: this.remediationActionTypeFields.persistenceMethod,
		confirm_compromised_user: this.remediationActionTypeFields.investigatedUser,
		// host_not_found: [
		// 	this.commonRemediationActionTypeFields.machine,
		// 	this.commonRemediationActionTypeFields.investigation,
		// ],
		generic: this.remediationActionTypeFields.generic,
		quarantine_registry_key: this.remediationActionTypeFields.quarantineRegistryKey,
		block_sender: this.remediationActionTypeFields.blockSender,
		block_sender_domain: this.remediationActionTypeFields.blockSenderDomain,
		block_spo_file: this.remediationActionTypeFields.blockSpoFile,
		email_remediation: this.remediationActionTypeFields.emailRemediation,
		ews_setting_change_remediation: this.remediationActionTypeFields.ewsSettingChangeRemediation,
		mail_delegation_remediation: this.remediationActionTypeFields.mailDelegationRemediation,
		mail_forwarding_rule_remediation: this.remediationActionTypeFields.mailForwardingRuleRemediation,
		force_password_reset_remediation: this.remediationActionTypeFields.forcePasswordResetRemediation,
		user_inbox_rules_remediation: this.remediationActionTypeFields.userInboxRulesRemediation,
		owa_setting_change_remediation: this.remediationActionTypeFields.owaSettingChangeRemediation,
		hard_email_remediation: this.remediationActionTypeFields.hardEmailRemediation,
		move_to_inbox: this.remediationActionTypeFields.moveToInbox,
		move_to_junk: this.remediationActionTypeFields.moveToJunk,
		move_to_deleted_items: this.remediationActionTypeFields.moveToDeletedItems,
		delete_attachment: this.remediationActionTypeFields.deleteAttachment,
		submit_for_detonation: this.remediationActionTypeFields.submitForDetonation,
		enable_user: this.remediationActionTypeFields.enableUser,
		disable_user: this.remediationActionTypeFields.disableUser,
	};
	getFieldsByRemediationType(
		type: RemediationActionTypeTypes,
		includeInvestigationField: boolean
	): Array<DataviewField<RemediationAction>> {
		const allFields = this.remediationTypeFields[type];

		return includeInvestigationField
			? allFields
			: allFields.filter(field => field !== this.commonRemediationActionTypeFields.investigation);
	}
}
