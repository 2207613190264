import { Injectable, ComponentRef } from '@angular/core';
import { HuntingQueryResultRecord } from '@wcd/domain';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import {
	HuntingQueryResultRecordEntityPanelComponent,
	HuntingQueryResultRecordPanelOptions,
} from '../components/hunting-query-result-record.entity-panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { HuntingBulkActionsComponent } from '../../../hunting-custom-actions/components/hunting-bulk-actions.component';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { AuthService, MtpPermission } from '@wcd/auth';

@Injectable()
export class HuntingQueryResultRecordEntityTypeService
	implements EntityTypeService<HuntingQueryResultRecord> {
	constructor(
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private featuresService: FeaturesService,
		private authService: AuthService
	) {}

	readonly entityType: EntityType<HuntingQueryResultRecord> = {
		id: 'huntingQueryResultRecord',
		entity: HuntingQueryResultRecord,
		singleEntityPanelComponentType: HuntingQueryResultRecordEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: (huntingQueryResultRecord: HuntingQueryResultRecord) =>
			this.i18nService.get('hunting.results.record.panel.title'),
		getActions: (
			records: Array<HuntingQueryResultRecord>,
			options: HuntingQueryResultRecordPanelOptions
		) => {
			const areBulkActionsAllowed = this.authService.currentUser.hasRequiredMtpPermission(
				MtpPermission.SecurityData_Remediate
			);

			return [
				this.featuresService.isEnabled(Feature.HuntingBulkActions) ||
				this.featuresService.isEnabled(Feature.HuntingBulkActionsMtp)
					? new ItemActionModel({
							id: 'bulkActions',
							nameKey: 'hunting.bulkActions.title',
							icon: FabricIconNames.F12DevTools,
							disabled: !areBulkActionsAllowed,
							tooltip: areBulkActionsAllowed
								? null
								: this.i18nService.get('hunting.bulkActions.noPermissions'),
							refreshOnResolve: false,
							method: async (records: Array<HuntingQueryResultRecord>) => {
								const panelSettings: PanelSettings = {
									id: 'hunting-bulk-actions-panel',
									type: PanelType.wizard,
									noBodyPadding: true,
									disableOverlayClick: true,
									className: 'panel-wizard',
								};
								return new Promise<void>((resolve, reject) => {
									const subscription = this.dialogsService
										.showPanel(HuntingBulkActionsComponent, panelSettings, {
											records,
											columnsInfo: options.columnsInfo,
										})
										.subscribe((panel: ComponentRef<HuntingBulkActionsComponent>) => {
											panel.onDestroy(() => {
												resolve();
												subscription.unsubscribe();
											});
										});
								});
							},
					  })
					: null,
			].filter(Boolean);
		},
	};
}
