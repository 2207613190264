var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ReportModel, M365_DASHBOARD_WIDGET_CLASS } from '../../../models/report.model';
import { ActiveAlertsWidget } from './components/active-alerts.widget';
import { ActiveAutomatedInvestigationsWidget } from './components/active-automated-investigations.widget';
import { AutomatedInvestigationsStatisticsWidget } from './components/automated-investigations-statistics.widget';
import { SuspiciousActivitiesWidget } from './components/suspicious-activities.widget';
import { MachinesAtRiskWidget } from './components/machines-at-risk.widget';
import { UsersAtRiskWidget } from './components/users-at-risk.widget';
import { DailyMachineReportingWidget } from './components/daily-machine-reporting.widget';
import { SensorHealthWidget } from './components/sensor-health.widget';
import { OnboardingWidget } from './components/onboarding.widget';
import { PromotionBannerWidget } from './components/promotion-banner.widget';
import { ActiveIncidentsWidgetComponent } from './components/active-incidents.widget';
import { Feature } from '@wcd/config';
import { ExperienceMtpWidgetComponent } from './components/experience-mtp.widget/experience-mtp.widget.component';
import { ThreatProtectionDetectionSourceDashboardWidget } from '../../../../shared-reports/threat-protection-detection-source-dashboard-widget';
import { MachineProtectionActiveStatusOvertimeWidget } from '../../../../shared-reports/machine-protection-active-status-overtime-widget';
var securityOperationsReportSettings = {
    id: 'securityoperationsdashboard',
    name: 'Security operations dashboard',
    maxWidth: 1920,
    titleNameKey: 'securityOperations.title',
    allowExport: false,
    allowRangeSelect: false,
};
var messagesRow = {
    widgets: [PromotionBannerWidget]
};
var activeAlertsColumn = {
    widgets: [
        {
            type: OnboardingWidget,
            weight: 0
        },
        {
            type: ExperienceMtpWidgetComponent,
            custom: {
                features: [Feature.ExperienceMtp],
            },
        },
        {
            type: ActiveAlertsWidget,
            custom: {
                disableFeatures: [Feature.IncidentsPhase2],
            },
        },
    ],
};
var activeAlertsColumnInScc = {
    widgets: [
        {
            type: OnboardingWidget,
            weight: 0,
        },
        {
            type: ExperienceMtpWidgetComponent,
            custom: {
                features: [Feature.ExperienceMtp],
            },
        },
        {
            type: ActiveIncidentsWidgetComponent,
            custom: {
                features: [Feature.IncidentsDashboardWidget],
            },
        },
        {
            type: ActiveAlertsWidget,
            custom: {
                disableFeatures: [Feature.IncidentsPhase2],
            },
        },
    ],
};
var automatedIrColumn = {
    widgets: [ActiveAutomatedInvestigationsWidget, AutomatedInvestigationsStatisticsWidget],
};
var healthRow = {
    widgets: [SensorHealthWidget],
};
var atRiskRow = {
    columns: [{ widgets: [MachinesAtRiskWidget] }, { widgets: [UsersAtRiskWidget] }],
};
var atRiskColumn = {
    widgets: [MachinesAtRiskWidget, UsersAtRiskWidget],
};
var alertsAutoIrRow = {
    columns: [activeAlertsColumn, automatedIrColumn],
};
var alertsAndAtRiskRow = {
    columns: [activeAlertsColumnInScc, atRiskColumn],
};
var activeDevicesRow = {
    widgets: [
        {
            type: DailyMachineReportingWidget,
            custom: {
                disableFeatures: [Feature.ReplaceDailyMachineCountInDashboard],
            },
        },
        {
            type: MachineProtectionActiveStatusOvertimeWidget,
            custom: {
                features: [Feature.ReplaceDailyMachineCountInDashboard],
            },
        },
    ],
};
var activeStatusOvertimeRow = {
    widgets: [
        {
            type: MachineProtectionActiveStatusOvertimeWidget,
            custom: {
                features: [Feature.ReplaceDailyMachineCountInDashboard],
            },
        },
    ]
};
var SuspiciousActivitiesColumn = {
    widgets: [
        {
            type: SuspiciousActivitiesWidget,
            custom: {
                disableFeatures: [Feature.ReplaceSuspiciousActivitiesInDashboard],
            },
        },
        {
            type: ThreatProtectionDetectionSourceDashboardWidget,
            custom: {
                features: [Feature.ReplaceSuspiciousActivitiesInDashboard],
            },
        },
    ],
};
export var SecurityOperationsDashboardReportInScc = new ReportModel(__assign({}, securityOperationsReportSettings, { rows: [
        messagesRow,
        alertsAndAtRiskRow,
        healthRow,
        activeStatusOvertimeRow,
    ], className: M365_DASHBOARD_WIDGET_CLASS }));
export var SecurityOperationsDashboardReportNoRbacRowsInScc = [
    messagesRow,
    alertsAndAtRiskRow,
    {
        columns: [
            healthRow,
            SuspiciousActivitiesColumn,
        ],
    },
    activeDevicesRow,
];
export var SecurityOperationsDashboardReport = new ReportModel(__assign({}, securityOperationsReportSettings, { rows: [
        messagesRow,
        alertsAutoIrRow,
        atRiskRow,
        healthRow,
        activeStatusOvertimeRow
    ], className: M365_DASHBOARD_WIDGET_CLASS }));
export var SecurityOperationsDashboardReportNoRbacRows = [
    messagesRow,
    alertsAutoIrRow,
    atRiskRow,
    {
        columns: [
            {
                rows: [healthRow],
            },
            SuspiciousActivitiesColumn,
        ],
    },
    activeDevicesRow
];
