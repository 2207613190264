import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { BaselineConfiguration } from './baseline-profile-configuration/baseline-configuration.entity';
import { BaselineProfileDevice } from './baseline-profile-device/baseline-profile-device.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: BaselineConfiguration,
	dataEntity: BaselineProfileDevice,
	endpoint: (_, query) => `baseline/allSettings/${query.where['id']}/devices`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (configuration: BaselineConfiguration) => ({ id: configuration.id }),
})
export class BaselineConfigurationBaselineProfileDeviceRelationship
	implements EntityRelationshipRepositoryType<BaselineConfiguration, BaselineProfileDevice> {}
