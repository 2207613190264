
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_registryValue_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.valueType">
				<dt role="none">{{ i18nService.strings.airsEntities_registryValue_fields_type }}</dt>
				<dd role="none">{{ entity.valueType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.valueData">
				<dt role="none">{{ i18nService.strings.airsEntities_registryValue_fields_data }}</dt>
				<dd role="none">{{ entity.valueData }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.keyData">
				<dt role="none">{{ i18nService.strings.airsEntities_registryKey_fields_key }}</dt>
				<dd role="none">{{ entity.keyData }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.hive">
				<dt role="none">{{ i18nService.strings.airsEntities_registryKey_fields_hive }}</dt>
				<dd role="none">{{ entity.hive }}</dd>
			</ng-container>
		</dl>
	