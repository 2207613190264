import { Entity, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';
import { BaselineConfiguration } from './baseline-configuration.entity';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Configuration',
	pluralName: 'Configurations',
	endpoint: 'baseline/configuration',
	readonly: true,
})
export class BaselineProfileConfiguration extends BaselineConfiguration {
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField({ defaultValue: true }) readonly isProfileConfiguration: boolean
}
