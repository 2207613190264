var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { ReportWidgetComponent } from '../reports/components/report-widget.component.base';
import { WidgetType } from '../reports/components/report-with-filters.component';
var BaseReportWidgetComponent = /** @class */ (function (_super) {
    __extends(BaseReportWidgetComponent, _super);
    function BaseReportWidgetComponent(widgetId, titleKey, reportsService, i18nService, customWidgetConfig, help, range) {
        if (customWidgetConfig === void 0) { customWidgetConfig = {}; }
        if (help === void 0) { help = undefined; }
        if (range === void 0) { range = undefined; }
        var _this = _super.call(this, reportsService) || this;
        _this.i18nService = i18nService;
        _this.customWidgetConfig = customWidgetConfig;
        _this.help = help;
        _this.range = range;
        _this.height = 300;
        _this.today = new Date();
        _this.chartSettings$ = _this.data$.pipe(map(function (data) { return data && _this.getSettings(data); }));
        _this.widgetId = widgetId;
        _this.titleKey = titleKey;
        return _this;
    }
    Object.defineProperty(BaseReportWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return __assign({}, this.customWidgetConfig, { id: this.widgetId, name: this.i18nService.get(this.titleKey), help: this.help == undefined ? undefined : this.i18nService.get(this.help), getDateOfWidget: function () {
                    return _this.widgetType === WidgetType.Daily && _this.currentDataDate;
                }, getDateRangeOfWidget: this.range
                    ? undefined
                    : function () {
                        return _this.widgetType === WidgetType.Trend && _this.currentDataDateRange;
                    }, rangeInDays: this.range, height: this.getHeight(), loadData: function (filtersState) {
                    var serializedFilters = _this.reportsService.fixDataIfNeeded(filtersState, _this.widgetType);
                    _this.currentFilters = serializedFilters;
                    _this.widgetType === WidgetType.Daily
                        ? (_this.currentDataDate = _this.reportsService.calcCurrentDataDateForDaily(serializedFilters, _this.today))
                        : (_this.currentDataDateRange = _this.reportsService.calcCurrentDataDateRangeForTrend(serializedFilters));
                    return _this.dataFunc(serializedFilters, _this.widgetType, _this.dimensionType).pipe(map(function (res) {
                        return _this.widgetType === WidgetType.Daily && Array.isArray(res) ? res[0] : res;
                    }));
                } });
        },
        enumerable: true,
        configurable: true
    });
    BaseReportWidgetComponent.prototype.getHeight = function () {
        return this.height + "px";
    };
    return BaseReportWidgetComponent;
}(ReportWidgetComponent));
export { BaseReportWidgetComponent };
