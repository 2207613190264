export function addTenantIdUrlParam(url: string) {
	const urlParams = new URLSearchParams(window.location.href);
	const tid = urlParams.get('tid');
	if (!tid) {
		return url;
	}
	return `${url}${url.includes('?') ? '&' : '?'}tid=${tid}`;
}

export function getTenantIdUrlParam(): { tid?: string } {
	const urlParams = new URLSearchParams(window.location.href);
	const tid = urlParams.get('tid');
	return tid ? { tid } : {};
}
