import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { ExpanderArrowPosition } from './expander-button/expander-button.component';

@Component({
	selector: 'wcd-collapsible-section',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<section
			class="collapsible-section"
			[class.no-side-margin]="noSideMargins"
			[class.no-border]="!showTopBorder"
			[class.no-padding-bottom]="noBottomPadding"
		>
			<wcd-expander
				buttonRole="button"
				[label]="label"
				[arrowPosition]="arrowPosition"
				[isExpanded]="isExpanded"
				[sectionId]="sectionId"
				(isExpandedChange)="isExpandedChange.emit($event)"
				buttonRole="button"
			>
				<ng-content></ng-content>
			</wcd-expander>
		</section>
	`,
	styleUrls: ['./collapsible-section.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class CollapsibleSectionComponent {
	@Input() label: string;
	@Input() arrowPosition?: ExpanderArrowPosition = 'right';
	@Input() isExpanded = true;
	@Input() showTopBorder?: boolean = true;
	@Input() sectionId: string;
	@Input() noSideMargins: boolean;
	@Input() noBottomPadding = false;
	@Output() readonly isExpandedChange = new EventEmitter<boolean>();
}
