
		<wcd-datatable
			[items]="data$ | async"
			[columns]="tableColumns"
			[hidden]="error$ | async"
			[selectEnabled]="false"
			[showHeaders]="false"
			[label]="'dashboard_threatAnalytics_userAtRisk_widget_internal_grid_title'| i18n"
			class="table-with-legend"
		></wcd-datatable>
	