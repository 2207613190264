import { ChangeDetectorRef, Component } from '@angular/core';
import { Process } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';

@Component({
	selector: 'process-entity-panel',
	template: `
		<ng-container *ngIf="process.id">
			<section class="wcd-margin-vertical wcd-margin-large-horizontal">
				<process-entity-details [entity]="process"></process-entity-details>
			</section>
			<file-entity-panel [entity]="process.file"></file-entity-panel>
		</ng-container>
	`,
})
export class ProcessEntityPanelComponent extends EntityPanelComponentBase<Process> {
	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	get process(): Process {
		return this.entity;
	}
}
