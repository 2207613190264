var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { EvaluationLab, EvaluationMachineOperatingSystem, MACHINE_OS_VALUES, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { from, of } from 'rxjs';
import { map } from 'rxjs/operators';
var DEFAULT_MACHINE_OS = EvaluationMachineOperatingSystem.Windows10;
var EvaluationAddMachinePanelComponent = /** @class */ (function (_super) {
    __extends(EvaluationAddMachinePanelComponent, _super);
    function EvaluationAddMachinePanelComponent(router, i18nService, evaluationService, changeDetectorRef, dialogsService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.evaluationService = evaluationService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.dialogsService = dialogsService;
        _this.SpinnerSize = SpinnerSize;
        _this.MessageBarType = MessageBarType;
        _this.machineOsValues = MACHINE_OS_VALUES;
        _this.machineOs = MACHINE_OS_VALUES.find(function (os) { return os.id === DEFAULT_MACHINE_OS; });
        _this.gettingConnectionDetails = false;
        _this.machineUserName = _this.evaluationService.getMachineUserName();
        _this.boundCanDeactivate = _this.canDeactivate.bind(_this);
        _this.getMachineOsLabel = _this.getMachineOsLabel.bind(_this);
        return _this;
    }
    EvaluationAddMachinePanelComponent.prototype.getDisclaimer = function () {
        return this.i18nService.get("evaluation.dashboard.widgets.evaluationMachinesStatus.machinesLimitation", {
            maxMachinesCount: this.lab.maxNumberOfMachines,
            machineExpirationInHours: this.lab.machineExpirationInHours,
        });
    };
    EvaluationAddMachinePanelComponent.prototype.getDescription = function () {
        return this.provisionedMachines + 1 === this.lab.maxNumberOfMachines
            ? this.i18nService.get("evaluation.dashboard.dataView.commandBar.addMachine.descriptionLastMachine")
            : this.i18nService.get("evaluation.dashboard.dataView.commandBar.addMachine.description", {
                provisionedMachinesCount: this.provisionedMachines,
                maxMachinesCount: this.lab.maxNumberOfMachines,
            });
    };
    EvaluationAddMachinePanelComponent.prototype.addEvaluationMachine = function () {
        var _this = this;
        this.gettingConnectionDetails = true;
        this.evaluationService.addEvaluationMachineByOs(this.machineOs.id, this.totalMachines).subscribe(function (evaluationMachine) {
            _this.evaluationMachine = evaluationMachine;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.gettingConnectionDetails = false;
            _this.changeDetectorRef.markForCheck();
            _this.dialogsService.showError({
                title: _this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.error'),
                data: error,
            });
        }, function () { return (_this.gettingConnectionDetails = false); });
    };
    EvaluationAddMachinePanelComponent.prototype.canDeactivate = function () {
        if (this.gettingConnectionDetails) {
            return from(this.confirmationDialog()).pipe(map(function (e) { return !e.confirmed; }));
        }
        else {
            return of(true);
        }
    };
    EvaluationAddMachinePanelComponent.prototype.closeAddMachinePanel = function () {
        var _this = this;
        if (this.evaluationMachine) {
            return this.destroy();
        }
        if (this.gettingConnectionDetails) {
            return this.confirmationDialog().then(function (e) { return !e.confirmed && _this.destroy(); });
        }
        return this.destroy();
    };
    EvaluationAddMachinePanelComponent.prototype.getMachineOsLabel = function (machineOs) {
        return this.i18nService.get(machineOs.keyName);
    };
    EvaluationAddMachinePanelComponent.prototype.getConnectionDetailsTitle = function () {
        return this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.saveConnectionDetails');
    };
    EvaluationAddMachinePanelComponent.prototype.confirmationDialog = function () {
        return this.dialogsService.confirm({
            title: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseTitle'),
            text: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseText'),
            confirmText: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseOk'),
            cancelText: this.i18nService.get('evaluation.dashboard.dataView.commandBar.addMachine.confirmCloseCancel'),
        });
    };
    return EvaluationAddMachinePanelComponent;
}(PanelContainer));
export { EvaluationAddMachinePanelComponent };
