import { ApiCall, ApiCallModel, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { Alert } from '../alert/alert.entity';

@ValueObject({
	singularName: 'Array of alerts',
	pluralName: '',
})
export class Alerts extends ModelBase {
	@EntityField({ arrayOf: Alert })
	alerts: Array<Alert>;
}

// TODO: Delete this after vNext migration is complete (controlled by K8SMigrationLegacyAlertsByIds feature flag).
@ApiCall({
	name: 'Get alerts by list of ids vnow',
	parseQuery: (alertsIds: Array<string>) => {
		return {
			params: {
				alertsIds: alertsIds.map(alertId => 'alertsids='+alertId).join('&')
			},
		};
	},
	endpoint: (_config, query) => {
		return `AlertsByIds?${query.where['alertsIds']}`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseData: rawData => ({ alerts: rawData }),
	type: Alerts,
})
export class GetAlertsByIdsApiCall extends ApiCallModel<Alerts, Array<string>> {}

@ApiCall({
	name: 'Get alerts by list of ids vnext',
	parseQuery: (alertsIds: Array<string>) => ({ data: { AlertsIds: alertsIds } }),
	endpoint: 'LegacyAlertsByIds',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.alerts,
	parseData: rawData => ({ alerts: rawData }),
	type: Alerts,
})
export class GetAlertsByIdsVNextApiCall extends ApiCallModel<Alerts, Array<string>> {}
