<!-- tslint:disable:template-click-events-have-key-events -->
<h2 class="wcd-font-weight-bold">{{ 'tvm_authenticatedScan_wizard_job_type_step_title' | i18n }}</h2>

<div class="wcd-margin-large-top wcd-input-placeholder wcd-textarea-placeholder" *ngIf="data && data.assessmentJob">
	<div class="wcd-margin-medium-top wcd-margin-mediumSmall-bottom">
        {{ 'tvm_authenticatedScan_wizard_job_type_description' | i18n }}
    </div>

    <div class="wcd-margin-largeMedium-top">
        <wcd-radiolist
			[values]="assessmentTypeValues"
			[(ngModel)]="selectedAssessmentType"
			name="assessmentJobType"
			[isDisabled]="!data.isNewDraft"
			[defaultValue]="selectedAssessmentType"
			(ngModelChange)="onAssessmentTypeChange()">
        </wcd-radiolist>
    </div>

	<div class="wcd-flex-items-center" *ngIf="assessmentJobService.isWindowsScanFeatureEnabled() && !assessmentJobService.isWindowsScanLicenseEnabled()">
		<wcd-shared-icon class="icon small-icon wcd-margin-small-right" iconColor="themeDarkAlt" [iconName]="'GiftboxOpen'"></wcd-shared-icon>
		<span><a href="https://aka.ms/StartPremiumTrial">{{ 'tvm_authenticatedScan_wizard_premiumScanTypes_upsell' | i18n }}</a></span>
    </div>
</div>
