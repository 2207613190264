
		<wcd-banner
			*ngIf="navigateTo"
			[text]="text"
			[linkOptions]="{
				id: 'mtpPromotion_TryNow',
				buttonTextKey: 'mtpPromotion.tryNow',
				navigateTo: navigateTo
			}"
			(onClose)="close()"
		>
		</wcd-banner>
	