import { Component, Input, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Incident } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { WicdSanitizerService } from '@wcd/shared';

@Component({
	selector: 'mte-recommended-panel',
	templateUrl: './mte-recommended.panel.component.html'
})
export class MteRecommendedPanelComponent extends PanelContainer {
	@Input() incident: Incident;

	constructor(protected router: Router, private domSanitizer: WicdSanitizerService) {
		super(router);
	}

	get description() {
		return this.incident && this.sanitize(this.incident.description);
	}

	get recommendedActions() {
		return this.incident && this.sanitize(this.incident.recommendedActions);
	}

	private sanitize = text => this.domSanitizer.sanitize(SecurityContext.HTML, text)

}
