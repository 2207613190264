
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_fileName }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="file.path">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_filePath }}</dt>
				<dd role="none">
					<entity-name
						[entity]="entity"
						[entityName]="file.path"
						[entityTypeId]="'airs-entity'"
					></entity-name>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.mimeType">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_fileType }}</dt>
				<dd role="none">{{ entity.mimeType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.size">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_fileSize }}</dt>
				<dd role="none">{{ entity.size | prettyBytes }}</dd>
			</ng-container>
			<ng-container *ngIf="appendedFile.subject">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_emailSubject }}</dt>
				<dd role="none">{{ appendedFile.subject }}</dd>
			</ng-container>
			<ng-container *ngIf="file.createdTime">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_createdDate }}</dt>
				<dd role="none">{{ file.createdTime | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="file.directoryPath">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_directory }}</dt>
				<dd role="none">{{ file.directoryPath }}</dd>
			</ng-container>
			<ng-container *ngIf="file.hostOs">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_deviceOperatingSystem }}</dt>
				<dd role="none">{{ file.hostOs }}</dd>
			</ng-container>
			<ng-container *ngIf="file.hostGroup">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_deviceGroupName }}</dt>
				<dd role="none">{{ file.hostGroup }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.productVendor">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_vendor }}</dt>
				<dd role="none">{{ entity.productVendor }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.digitalSignature">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_digitalSignature }}</dt>
				<dd role="none">
					<generic-object
						[fieldName]="i18nService.strings.airsEntities_file_fields_digitalSignature"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container *ngIf="entity.digitalSignature.publisher">
								<dt role="none">
									{{
										i18nService.strings
											.airsEntities_file_fields_digitalSignature_publisher
									}}
								</dt>
								<dd role="none">{{ entity.digitalSignature.publisher }}</dd>
							</ng-container>
							<ng-container *ngIf="entity.digitalSignature.valid_from">
								<dt role="none">
									{{
										i18nService.strings
											.airsEntities_file_fields_digitalSignature_validFrom
									}}
								</dt>
								<dd role="none">{{ entity.digitalSignature.valid_from | date: 'short' }}</dd>
							</ng-container>

							<ng-container *ngIf="entity.digitalSignature.valid_to">
								<dt role="none">
									{{
										i18nService.strings.airsEntities_file_fields_digitalSignature_validTo
									}}
								</dt>
								<dd role="none">{{ entity.digitalSignature.valid_to | date: 'short' }}</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.sha1 || entity.md5 || entity.sha256">
				<dt role="none">{{ i18nService.strings.airsEntities_file_fields_hashes }}</dt>
				<dd role="none">
					<generic-object [fieldName]="i18nService.strings.airsEntities_file_fields_hashes">
						<dl class="key-values clearfix" role="none">
							<ng-container *ngIf="entity.md5">
								<dt role="none">
									{{ i18nService.strings.airsEntities_file_fields_hashes_md5 }}
								</dt>
								<dd role="none">{{ entity.md5 }}</dd>
							</ng-container>
							<ng-container *ngIf="entity.sha1">
								<dt role="none">
									{{ i18nService.strings.airsEntities_file_fields_hashes_sha1 }}
								</dt>
								<dd role="none">{{ entity.sha1 }}</dd>
							</ng-container>
							<ng-container *ngIf="entity.sha256">
								<dt role="none">
									{{ i18nService.strings.airsEntities_file_fields_hashes_sha256 }}
								</dt>
								<dd role="none">{{ entity.sha256 }}</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>
			</ng-container>
			<ng-container *ngIf="(vtData$ | async) as vtData">
				<dt role="none">{{ i18nService.strings.entities_panelSections_virusTotal }}</dt>
				<dd role="none">
					<button
						*ngIf="vtData.permalink; else vtStr"
						class="field-value-button"
						type="button"
						(click)="openInNewTab(vtData.permalink)"
					>
						<fab-icon [iconName]="'OpenInNewWindow'" [contentClass]="'btn-inline'"></fab-icon>
						<ng-container *ngTemplateOutlet="vtStr"></ng-container>
					</button>
					<ng-template #vtStr>{{ vtData.positives + '/' + vtData.total }}</ng-template>
				</dd>
			</ng-container>
			<ng-container *ngIf="(fileStats$ | async) as fileStats">
				<dt role="none">{{ i18nService.strings.airsEntities_details_filePrevalence_worldwide }}</dt>
				<dd role="none">{{ fileStats.worldwidePrevalence | prettyNumber }}</dd>
				<dt role="none">
					{{ i18nService.strings.airsEntities_details_filePrevalence_organization }}
				</dt>
				<dd role="none">{{ fileStats.organizationPrevalence | prettyNumber }}</dd>
			</ng-container>
		</dl>
	