export const enum MailMessageEntityAlertV3Fields {
	Sender = 'Sender',
	SenderIP = 'SenderIP',
	Recipient = 'Recipient',
	InternetMessageId = 'InternetMessageId',
	NetworkMessageId = 'NetworkMessageId',
	DeliveryAction = 'DeliveryAction',
	DeliveryLocation = 'DeliveryLocation',
	Files = 'Files',
	Urls = 'Urls',
	Subject = 'Subject',
	ReceivedDate = 'ReceivedDate',
	P1SenderDisplayName = 'P1SenderDisplayName',
	Language = 'Language',
	Threats = 'Threats',
}

export function mapMailMessageEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case MailMessageEntityAlertV3Fields.Sender:
					acc['sender'] = val;
					break;
				case MailMessageEntityAlertV3Fields.SenderIP:
					acc['sender_ip'] = val;
					break;
				case MailMessageEntityAlertV3Fields.Recipient:
					acc['recipient'] = val;
					break;
				case MailMessageEntityAlertV3Fields.InternetMessageId:
					acc['internet_message_id'] = val;
					break;
				case MailMessageEntityAlertV3Fields.NetworkMessageId:
					acc['network_message_id'] = val;
					break;
				case MailMessageEntityAlertV3Fields.DeliveryAction:
					acc['delivery_status'] = val;
					break;
				case MailMessageEntityAlertV3Fields.DeliveryLocation:
					acc['delivery_location'] = val;
					break;
				case MailMessageEntityAlertV3Fields.Files:
					const attachmentsData =
						val &&
						(<Array<{ [key: string]: any }>>val).reduce((res, file) => {
							if (file.Type === 'file') {
								const sha256 =
									file.FileHashes &&
									file.FileHashes.find(hash => hash.Algorithm === 'SHA256');

								res.push({
									fileName: file.Name,
									SHA256: sha256 && sha256.Value,
									malwareFamily: file.MalwareFamily,
								});
							}
							return res;
						}, []);

					acc['attachments'] = attachmentsData || null;
					break;
				case MailMessageEntityAlertV3Fields.Urls:
					acc['urls'] = val;
					break;
				case MailMessageEntityAlertV3Fields.Subject:
					acc['subject'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case MailMessageEntityAlertV3Fields.ReceivedDate:
					acc['received_date'] = val;
					break;
				case MailMessageEntityAlertV3Fields.P1SenderDisplayName:
					acc['sender_display_name'] = val;
					break;
				case MailMessageEntityAlertV3Fields.Language:
					acc['language'] = val;
					break;
				case MailMessageEntityAlertV3Fields.Threats:
					acc['threats'] = <Array<{ [key: string]: any }>>val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
