var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { isEqual } from 'lodash-es';
var FilterValuesRangeSliderComponent = /** @class */ (function (_super) {
    __extends(FilterValuesRangeSliderComponent, _super);
    function FilterValuesRangeSliderComponent(changeDetectorRef, ngZone) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.ngZone = ngZone;
        return _this;
    }
    Object.defineProperty(FilterValuesRangeSliderComponent.prototype, "value", {
        get: function () {
            return this.selectedValues;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesRangeSliderComponent.prototype.setSelectedValues = function (selectedValues) {
        if (!this.config.initialValue) {
            this.config.initialValue = {
                from: this.config.min,
                to: this.config.max,
            };
        }
        if (!selectedValues)
            this.selectedValues = __assign({}, this.config.initialValue);
        this.changeDetectorRef.markForCheck();
    };
    FilterValuesRangeSliderComponent.prototype.serialize = function () {
        var _a;
        return this.selectedValues && !isEqual(this.selectedValues, this.config && this.config.initialValue)
            ? (_a = {}, _a[this.fieldId] = this.selectedValues.from + ":" + this.selectedValues.to, _a) : null;
    };
    FilterValuesRangeSliderComponent.prototype.deserialize = function (serializedRange) {
        var strRange = serializedRange[this.fieldId];
        if (strRange instanceof Array)
            strRange = strRange[0];
        if (!strRange)
            return null;
        var matched = strRange.match(/^(\d+):(\d+)$/);
        if (matched) {
            var from = Number(matched[1]);
            var to = Number(matched[2]);
            return { from: from, to: to };
        }
        return null;
    };
    FilterValuesRangeSliderComponent.prototype.onChange = function (range) {
        var _this = this;
        this.ngZone.run(function () {
            _this.selectedValues = range;
            _this.filterValuesChange.emit(range);
        });
    };
    return FilterValuesRangeSliderComponent;
}(FilterValuesComponent));
export { FilterValuesRangeSliderComponent };
