import { HttpClient } from '@angular/common/http';
import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris, Repository } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';
import { DataViewConfig } from '@wcd/dataview';
import {DateRangeModel, TzDateService} from '@wcd/localization';
import { compact, merge } from 'lodash-es';
import {
	Investigation,
	InvestigationPendingType,
	InvestigationStatus,
	InvestigationStatusEnum,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FeaturesService, PreferencesService } from '@wcd/config';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { isCustomTimeRangeValue, TimeRangeId, TimeRangeValue } from '@wcd/date-time-picker';
import { DownloadService } from '../../../shared/services/download.service';
import { InvestigationsService } from '../services/investigations.service';
import { SevilleInvestigationFields } from '../services/seville-investigation.fields';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';

const TIME_RANGE_DEFAULT_PREFERENCE_ID = 'investigations_time_range_default';

@Component({
	selector: 'seville-investigations-dataview',
	providers: [SevilleInvestigationFields],
	template: `
		<div class="wcd-full-height">
			<dataview
				class="wcd-full-height"
				id="investigations"
				[repository]="investigationsRepo"
				[entityType]="entityType"
				[dataViewConfig]="dataviewConfig"
				[fields]="investigationFields.fields"
				[setItemActions]="setInvestigationActions"
				defaultSortFieldId="start_date"
				[fixedOptions]="fixedOptions"
				[disableSelection]="authService.currentUser.isReadonly"
				[selectAllEnabled]="true"
				[label]="
					i18nService.strings.evaluation_dashboard_widgets_evaluationReportOverview_investigations
				"
				disableFilterPanelAutoFocus="true"
				focusOnTable="true"
			>
				<ng-container dataview-controls>
					<fancy-select
						[ngModel]="currentRange"
						(ngModelChange)="onRangeTypeSelect($event)"
						class="command-bar-item-dropdown"
						[buttonIcon]="'calendar'"
						[label]="'name'"
						[values]="timeRanges"
					></fancy-select>
					<daterange
						*ngIf="isCustomTimeRangeValue(currentRange)"
						[(ngModel)]="customDateRange"
						(ngModelChange)="onRangeSelect()"
						class="inline-daterange wcd-padding-small-left"
					></daterange>
				</ng-container>
				<div class="page-header" dataview-header>
					<h2>{{ i18nService.strings.investigations_title }}</h2>
				</div>
			</dataview>
		</div>
	`,
})
export class SevilleInvestigationsDataviewComponent {
	readonly isCustomTimeRangeValue = isCustomTimeRangeValue;

	dataviewConfig: DataViewConfig;
	customDateRange: DateRangeModel;
	investigationsRepo: Repository<Investigation>;
	setInvestigationActions: (items: Array<any>) => Array<ItemActionModel>;
	entityType: EntityType<Investigation> = this.globalEntityTypesService.getEntityType(Investigation);
	timeRanges: Array<TimeRangeValue>;

	private _currentRange: TimeRangeValue;
	private _fixedOptions: { [field: string]: Array<string> };
	private _inputFixedOptions: { [field: string]: Array<string> };

	@Input()
	set fixedOptions(fixedOptions: { [field: string]: Array<string> }) {
		this._inputFixedOptions = fixedOptions;
		this.setFixedOptions();
	}

	get fixedOptions(): { [field: string]: Array<string> } {
		return this._fixedOptions;
	}

	get currentRange(): TimeRangeValue {
		return this._currentRange;
	}

	set currentRange(range: TimeRangeValue) {
		this._currentRange = range;

		if (!isCustomTimeRangeValue(range)) {
			this.customDateRange = DateRangeModel.fromDays(this.tzDateService, range.value);
		}
	}

	constructor(
		private preferencesService: PreferencesService,
		public investigationFields: SevilleInvestigationFields,
		investigationsService: InvestigationsService,
		public authService: AuthService,
		private route: ActivatedRoute,
		private router: Router,
		private downloadService: DownloadService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private entityPanelsService: EntityPanelsService,
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService,
		private http: HttpClient,
		private paris: Paris,
		public i18nService: I18nService,
		private timeRangesService: TimeRangesService,
		private tzDateService: TzDateService,
	) {
		this.setTimeRanges();
		this.investigationsRepo = paris.getRepository(Investigation);
		this.setInvestigationActions = investigationsService.getInvestigationActions.bind(
			investigationsService
		);

		let currentRange: TimeRangeValue =
			this.timeRanges.find(
				timeRange =>
					timeRange.id ===
					(this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID) ||
						(this.appConfigService.isDemoTenant && '1year'))
			) || this.timeRanges[1];
		const locationRange = route.snapshot.queryParams.range;
		const locationCustomRangeMatch = locationRange && locationRange.match(/^(\d+)?:(\d+)?$/);

		if (locationRange) {
			const foundRange: TimeRangeValue = this.timeRanges.find(range => {
				return (
					range.id === locationRange ||
					(!isCustomTimeRangeValue(range) && range.value === Number(locationRange)) ||
					(locationCustomRangeMatch && isCustomTimeRangeValue(range))
				);
			});

			if (foundRange) {
				currentRange = foundRange;

				if (isCustomTimeRangeValue(currentRange) && locationCustomRangeMatch)
					this.customDateRange = new DateRangeModel(this.tzDateService,
						locationCustomRangeMatch[1] && new Date(parseInt(locationCustomRangeMatch[1])),
						locationCustomRangeMatch[2] && new Date(parseInt(locationCustomRangeMatch[2]))
					);
			}
		}

		this.setDataviewConfig();
		this.currentRange = currentRange;
		this.setFixedOptions();
	}

	onRangeTypeSelect(newRange: TimeRangeValue) {
		const oldCustomRange: DateRangeModel = this.customDateRange;
		this.currentRange = newRange;

		if (!isCustomTimeRangeValue(this.currentRange)) {
			this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentRange.id);
		}

		if (this.customDateRange.toString() !== oldCustomRange.toString()) this.onRangeSelect();
	}

	onRangeSelect() {
		let paramRange: string = this.currentRange.id;
		if (isCustomTimeRangeValue(this.currentRange)) {
			// create a new DateRangeModel so with full days instead of exact time
			this.customDateRange = new DateRangeModel(this.tzDateService, this.customDateRange.from, this.customDateRange.to);
			paramRange = this.customDateRange.toString();
		}
		this.router.navigate(['.'], {
			relativeTo: this.route,
			queryParams: {
				page: null,
				range: paramRange,
			},
			queryParamsHandling: 'merge',
		});

		this.setFixedOptions();
	}

	private setFixedOptions() {
		this._fixedOptions = merge(
			{
				fromDate: this.customDateRange.from.toISOString(),
				toDate: this.customDateRange.to.toISOString(),
				useSevilleApi: true,
			},
			this._inputFixedOptions
		);
	}

	private setDataviewConfig() {
		const config: DataViewConfig = {
			id: 'newInvestigations_dataview',
			getFiltersData: (options?: { [index: string]: any }) => {
				let endpointUrl = this.investigationsRepo.getEndpointUrl({ where: options });
				endpointUrl = endpointUrl.replace(/\/list$/, '');
				return this.http.get<Record<string, any>>(`${endpointUrl}/filters`, {
					params: Object.assign({}, options, this.fixedOptions),
				});
			},
		};

		if (this.appConfigService.isExposedToAllMachineGroups) {
			this.dataviewConfig = Object.assign({}, config, {
				exportResults: this.exportResults.bind(this),
			});
		} else {
			this.dataviewConfig = config;
		}
	}

	private exportResults(options) {
		const statusList: Array<string> =
			options.status && (Array.isArray(options.status) ? options.status : [options.status]);
		const airsStatuses: Array<InvestigationStatus> =
			statusList &&
			compact(
				statusList.map(status => InvestigationsService.getInvestigationStatusBySevilleStatus(status))
			);
		const pendingTypes =
			airsStatuses &&
			airsStatuses
				.filter(status => status.isPending)
				.map(status =>
					this.paris.getValue(InvestigationPendingType, type => type.equivalentStatus === status.id)
				);

		return this.downloadService.downloadFromUrl(`${this.investigationsRepo.getEndpointUrl()}/all.csv`, {
			params: Object.assign(
				{},
				options,
				{
					start_date: this.customDateRange.toString(),
				},
				airsStatuses && {
					status: Array.from(
						new Set(
							airsStatuses.map(status => {
								// re-map to actual AIRS statuses (in the AIRS BE there are no separate pending types)
								if (status.isPending) {
									return InvestigationStatusEnum.pending;
								}
								return status.id;
							})
						)
					),
				},
				pendingTypes &&
					pendingTypes.length && {
						pending_type: pendingTypes.map(type => type.id),
					},
				options.originatingAlertTitles && {
					names: options.originatingAlertTitles,
				},
				// not supported
				// options.detectionSource && {
				// 	detection_system: options.detectionSource,
				// },
				options.machineNames && {
					hosts: options.machineNames,
				}
			),
		});
	}

	private setTimeRanges() {
		this.timeRanges = this.timeRangesService
			.pick([TimeRangeId.day, TimeRangeId.week, TimeRangeId.month])
			.concat(
				this.appConfigService.isDemoTenant
					? this.timeRangesService.pick([TimeRangeId['6months']]).concat(({
							id: '1year',
							value: 365,
							name: this.i18nService.get('datetime_year_singular_template', { amount: 1 }),
					  } as unknown) as TimeRangeValue)
					: []
			)
			.concat(this.timeRangesService.pick([TimeRangeId.custom])) as Array<TimeRangeValue>;
	}
}
