<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngIf="(tags$ | async) as tags">
		<wcd-collapsible-section
			[label]="'common.tags' | i18n"
			[sectionId]="collapsibleID.Tags"
			*ngIf="!asKeyValueList">
			<ng-container *ngTemplateOutlet="tagsList"></ng-container>
		</wcd-collapsible-section>
		<ng-container *ngIf="asKeyValueList">
			<dt>{{'common.tags' | i18n}}</dt>
			<dd><ng-container *ngTemplateOutlet="tagsList; context: {tags:tags}"></ng-container></dd>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="isNewFilePageEnabled">
		<ng-container *ngIf="quarantineActionStatus">
			<fab-message-bar [messageBarType]="MessageBarType.severeWarning">
				{{'files_entityDetails_sections_actions_quarantine' | i18n:{user: quarantineActionStatus.user, time: quarantineActionStatus.time} }}
			</fab-message-bar>
		</ng-container>

		<ng-container *ngIf="customIndicatorData">
			<fab-message-bar [messageBarType]="MessageBarType.severeWarning">
				{{'files_entityDetails_sections_actions_indicator' | i18n:{indicator: customIndicatorData.action.name, user: customIndicatorData.createdByDisplayName, timeAgo: customIndicatorData.creationTime} }}
				<a [href]="customIndicatorData.href">{{'files_entityDetails_sections_actions_editIndicator' | i18n}}</a>
			</fab-message-bar>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="fileInstance && showFileInstanceDetails">
		<ng-container *ngIf="fileInstance.fileOrigin?.url?.name">
			<dt>{{ 'files.entityDetails.sections.details.fields.fileOriginUrl.title' | i18n }}</dt>
			<dd>
				<copyable-input
					ariaLabel="{{ 'files.entityDetails.sections.details.fields.fileOriginUrl.title' | i18n }}"
					[value]="fileInstance.fileOrigin.url.name">
				</copyable-input>
			</dd>
		</ng-container>
		<ng-container *ngIf="fileInstance.fileOrigin?.ip?.name">
			<dd>{{ 'files.entityDetails.sections.details.fields.fileOriginIp.title' | i18n }}</dd>
			<dd>
				<copyable-input
					ariaLabel="{{ 'files.entityDetails.sections.details.fields.fileOriginIp.title' | i18n }}"
					[value]="fileInstance.fileOrigin.ip.name">
				</copyable-input>
			</dd>
		</ng-container>
		<ng-container *ngIf="fileInstance.fileOriginReferer?.url?.name">
			<dt>{{ 'files.entityDetails.sections.details.fields.fileOriginReferer.title' | i18n }}</dt>
			<dd>
				<copyable-input
					ariaLabel="{{ 'files.entityDetails.sections.details.fields.fileOriginReferer.title' | i18n }}"
					[value]="fileInstance.fileOriginReferer.url.name">
				</copyable-input>
			</dd>
		</ng-container>
	</ng-container>
	<wcd-collapsible-section
		[label]="'files.entityDetails.sections.details.title' | i18n"
		[sectionId]="collapsibleID.Details"
		*ngIf="!asKeyValueList"
	>
	<ng-container *ngTemplateOutlet="fileInstanceDetailsWrapper"></ng-container>
	</wcd-collapsible-section>
	<ng-container *ngIf="asKeyValueList">
		<ng-container *ngTemplateOutlet="fileInstanceDetails"></ng-container>
	</ng-container>

	<ng-container *ngIf="isNewFilePageEnabled && file.isPeFile">
		<wcd-collapsible-section
			[label]="'file_peMetadata_title' | i18n"
			[sectionId]="collapsibleID.PeMetadata">
			<dl class="key-values" role="none">
				<dt>{{'file_peMetadata_originalName' | i18n}}</dt>
				<dd>
					{{file.originalName}}
				</dd>
				<dt>{{'file_peMetadata_publisher' | i18n}}</dt>
				<dd>
					{{file.publisher}}
				</dd>
				<dt>{{'file_peMetadata_companyName' | i18n}}</dt>
				<dd>
					{{file.companyName}}
				</dd>
				<dt>{{'file_peMetadata_productName' | i18n}}</dt>
				<dd>
					{{file.productName}}
				</dd>
				<dt>{{'file_peMetadata_fileDescription' | i18n}}</dt>
				<dd>
					{{file.fileDescription}}
				</dd>
			</dl>
		</wcd-collapsible-section>
	</ng-container>

	<ng-container *ngIf="isDataSensitivityEnabled">
		<wcd-collapsible-section
			[label]="'files.entityDetails.sections.protectionInformation.title' | i18n"
			[sectionId]="collapsibleID.ProtectionInformation"
			*ngIf="!asKeyValueList">
			<ng-container *ngTemplateOutlet="dataSensitivityWrapper"></ng-container>
		</wcd-collapsible-section>
		<ng-container *ngIf="asKeyValueList">
			<ng-container *ngTemplateOutlet="dataSensitivity"></ng-container>
		</ng-container>
	</ng-container>

</dl>


<ng-template #dataSensitivityWrapper>
	<dl class="key-values" role="none">
		<ng-container *ngTemplateOutlet="dataSensitivity"></ng-container>
	</dl>
</ng-template>

<ng-template #dataSensitivity>
	<ng-container *ngIf="fileInstance && showFileInstanceDetails">
		<ng-container *ngIf="sensitivityTag">
			<dt>{{ 'files.entityDetails.sections.details.fields.sensitivity.title' | i18n }}</dt>
			<dd>
				<ul class="tags-list">
					<li [class]="sensitivityTag.className">
						<span class="tag-text" [wcdTooltip]="sensitivityTag.tooltip">{{ sensitivityTag.name }}</span>
					</li>
				</ul>
			</dd>
		</ng-container>
		<ng-container *ngIf="isWindowsInfoProtectionApplied != null">
			<dt>{{ 'files.entityDetails.sections.details.fields.isWipProtected.title' | i18n }}</dt>
			<dd>
				<ng-container *ngTemplateOutlet="yesNo; context: {value: isWindowsInfoProtectionApplied}">
				</ng-container>
			</dd>
		</ng-container>
	</ng-container>

	<ng-container *ngIf="isAzureInfoProtectionApplied != null">
		<dt>{{ 'files.entityDetails.sections.details.fields.isAipProtected.title' | i18n }}</dt>
		<dd>
			<ng-container *ngTemplateOutlet="yesNo; context: {value: isAzureInfoProtectionApplied}"></ng-container>
		</dd>
	</ng-container>
</ng-template>

<ng-template #fileInstanceDetailsWrapper>
	<dl class="key-values" role="none">
		<ng-container *ngTemplateOutlet="fileInstanceDetails"></ng-container>
	</dl>
</ng-template>

<ng-template #fileInstanceDetails>
	<ng-container *ngIf="fileInstance && showFileInstanceDetails">
		<dt>{{ 'files.entityDetails.sections.details.fields.name.title' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="showEntityPanelLink && fileInstance?.id; else fileName">
				<entity-link [entity]="fileInstance"
							 [entityType]="fileInstanceType"
							 [linkText]="fileInstance.name"></entity-link>
			</ng-container>
			<ng-template #fileName>{{fileInstance.name}}</ng-template>
		</dd>
		<ng-container *ngIf="fileInstance.fullPath">
			<dt>{{ 'files.entityDetails.sections.details.fields.fullPath.title' | i18n }}</dt>
			<dd>
				{{ fileInstance.fullPath }}
			</dd>
		</ng-container>
	</ng-container>

	<dt>{{ 'files.entityDetails.sections.details.fields.sha1.title' | i18n }}</dt>
	<dd>
		<ng-container *ngIf="file.sha1; else noSha1">
			<copyable-input
				ariaLabel="{{ 'files.entityDetails.sections.details.fields.sha1.title' | i18n }}"
				[value]="file.sha1" [readonly]="true"></copyable-input>
		</ng-container>
		<ng-template #noSha1>{{'files.entityDetails.sections.details.fields.sha1.notFound' | i18n}}</ng-template>
	</dd>

	<ng-container *ngIf="file.sha256">
		<dt>{{ 'files.entityDetails.sections.details.fields.sha256.title' | i18n }}</dt>
		<dd>
			<copyable-input
				ariaLabel="{{ 'files.entityDetails.sections.details.fields.sha256.title' | i18n }}"
				[value]="file.sha256" [readonly]="true"></copyable-input>
		</dd>
	</ng-container>

	<ng-container *ngIf="file.md5">
		<dt>{{ 'files.entityDetails.sections.details.fields.md5.title' | i18n }}</dt>
		<dd>
			<copyable-input
				ariaLabel="{{ 'files.entityDetails.sections.details.fields.md5.title' | i18n }}"
				[value]="file.md5" [readonly]="true"></copyable-input>
		</dd>
	</ng-container>

	<ng-container *ngIf="file.size">
		<dt>{{ 'files.entityDetails.sections.details.fields.size.title' | i18n }}</dt>
		<dd>
			{{ file.size | prettyBytes }}
		</dd>
	</ng-container>

	<ng-container *ngIf="file.sha1">
		<dt>{{ 'files.entityDetails.sections.details.fields.signer.title' | i18n }}</dt>
		<dd>
			<file-certificate-info [file]="file" [dataEnriched]="dataEnriched"></file-certificate-info>
		</dd>

		<ng-container *ngIf="file.certificateInfo?.issuer">
			<dt>{{ 'files.entityDetails.sections.details.fields.issuer.title' | i18n }}</dt>
			<dd>
				{{ file.certificateInfo.issuer }}
			</dd>
		</ng-container>
	</ng-container>

	<ng-container>
		<dt>{{'files_entityDetails_sections_details_fields_isPe' | i18n}}</dt>
		<dd>
			{{file.isPeFile ? ('boolean_true' | i18n) : ('boolean_false' | i18n)}}
		</dd>
	</ng-container>

	<ng-container *ngIf="showFileVerdict">
		<dt>{{ 'file.malwareDetection' | i18n }}</dt>
		<dd>
			<file-detections-summary [file]="file" [showLinkBelow]="true"></file-detections-summary>
		</dd>
	</ng-container>
</ng-template>

<ng-template #tagsList let-tags="tags">
	<div class="wcd-padding-largeMedium-bottom">
		<tags-list *ngIf="tags?.length"
				   [tags]="tags"
				   [editable]="false"
		></tags-list>
	</div>
</ng-template>

<ng-template #yesNo let-value="value">
	 <wcd-shared-icon *ngIf="value; else offIcon" iconName="success" class="nudge-up color-text-success-light"> </wcd-shared-icon >
	<ng-template #offIcon>
		 <wcd-shared-icon iconName="errorFull" class="nudge-up color-text-gray-125"> </wcd-shared-icon >
	</ng-template>
	{{ 'files.entityDetails.sections.details.fields.isAipProtected.' + (value ? 'yes' : 'no') | i18n }}
</ng-template>

