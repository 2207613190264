import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'ServiceNow config metadata',
	pluralName: 'ServiceNow config metadata',
})
export class ServiceNowConfigMetadata extends ModelBase {
	@EntityField({
		data: 'version',
	})
	readonly version: string;

	@EntityField({
		data: 'integrationUser',
	})
	readonly integrationUser: string;

	@EntityField({
		data: 'integrationUserRoles',
	})
	readonly integrationUserRoles: string[];
}
