import { Injectable, OnDestroy } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	Event,
	NavigationEnd,
	Router,
	RoutesRecognized,
} from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { BehaviorSubject, Subscription } from 'rxjs';
import { FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

const PRODUCT_NAME = sccHostService.isSCC ? 'Microsoft 365 security' : 'Microsoft Defender for Endpoint';
const DEFAULT_STATE_DATA: StateData = {
	pageTitle: PRODUCT_NAME,
	showPageTitle: false,
};

@Injectable()
export class TitleService implements OnDestroy {
	state$: BehaviorSubject<StateData> = new BehaviorSubject<StateData>(DEFAULT_STATE_DATA);
	pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>(DEFAULT_STATE_DATA.pageTitle);

	private eventsSub: Subscription;
	private _lastStateData: StateData;

	constructor(
		router: Router,
		route: ActivatedRoute,
		private featuresService: FeaturesService,
		private liveAnnouncer: LiveAnnouncer,
		private i18nService: I18nService
	) {
		this.innerSetState(this.getRouteData(route.snapshot));
		this.eventsSub = router.events.subscribe((event: Event) => {
			if (event instanceof RoutesRecognized) this._lastStateData = this.getRouteData(event.state.root);
			else if (event instanceof NavigationEnd && this._lastStateData)
				this.setState(this._lastStateData);
		});
	}

	private getLocalizePageTitle(state: StateData): string {
		const pageTitleKey = state.getPageTitleKey
			? state.getPageTitleKey(this.featuresService)
			: state.pageTitleKey;
		if (pageTitleKey) {
			return this.i18nService.get(pageTitleKey);
		}
		if (state.pageTitle) {
			return state.pageTitle;
		}
		return DEFAULT_STATE_DATA.pageTitle;
	}

	setState(state: StateData): void {
		const oldPageTitle = document.title;
		this.innerSetState(state);
		// checking title has not changed to filter out changes in route
		// made by the dataview which are not real page loads
		// and leads to announcing page change when no needed
		// see bug https://microsoft.visualstudio.com/OS/_workitems/edit/25734637
		if (oldPageTitle !== document.title) {
			const title = this.pageTitle$.getValue();
			this.liveAnnouncer.announce(
				this.i18nService.get('page.loaded.announcement', { title }),
				'assertive',
				300
			);
		}
	}

	private innerSetState(state: StateData): void {
		if (state) {
			const stateCopy = cloneDeep(state);
			const localizedTitle = this.getLocalizePageTitle(stateCopy);
			document.title = `${localizedTitle} - ${PRODUCT_NAME}`;
			this._lastStateData = state;
			this.state$.next(stateCopy);
			this.pageTitle$.next(localizedTitle);
		} else {
			this.setState(DEFAULT_STATE_DATA);
		}
	}

	private getRouteData(rootState: ActivatedRouteSnapshot): StateData {
		if (rootState.children && rootState.children.length) return this.getRouteData(rootState.children[0]);

		return <StateData>rootState.data;
	}

	ngOnDestroy() {
		this.state$.complete();
		this.eventsSub && this.eventsSub.unsubscribe();
	}
}

export interface StateData {
	/**
	 * @deprecated Kept here for backward compatibility.
	 * When setting stateData please set pageTitleKey so that the title will be localized,
	 * You should set pageTitle field on stateData for app insights monitoring only (see trackPageView method in app-insights.service for context)
	 * When fetching the pageTitle please use pageTitle$ from titleService directly to get the localized page title.
	 */
	pageTitle?: string;
	getPageTitleKey?: (featuresService: FeaturesService) => string;
	pageTitleKey?: string;
	pageTitleIcon?: string;
	showPageTitle?: boolean;
	pageDescriptionKey?: string;
	menuDescriptionKey?: string;
	settingsGroup?: string;
	breadcrumbs?: Array<StateDataPath>;
}

export interface StateDataPath {
	url: string;
	name: string;
	icon: string;
}
