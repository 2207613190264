<!-- tslint:disable:template-accessibility-label-for -->
<div *ngIf="editedRule" class="large-form wcd-full-height wcd-flex-vertical"
	 data-track-component="Suppression Rule Sidepane"
	 data-track-component-type="Side Pane">
	<header class="wcd-flex-none wcd-flex-center-vertical wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
		<div class="wcd-flex-1">
			<h3 class="side-panel-title wcd-text-overflow-ellipsis wcd-margin-small-bottom wcd-font-weight-bold header-height" *ngIf="createRuleMode">{{'suppressionRules_create_header' | i18n}}</h3>
			<h3 class="side-panel-title wcd-text-overflow-ellipsis wcd-margin-small-bottom wcd-font-weight-bold header-height" *ngIf="isEditRuleMode">{{'suppressionRules_edit_header' | i18n}}<br> {{editedRule.name}}</h3>
			<h3 class="side-panel-title wcd-text-overflow-ellipsis wcd-margin-small-bottom wcd-font-weight-bold header-height" *ngIf="!isEditRuleMode && !createRuleMode">{{editedRule.name}}</h3>
		</div>
	</header>
	<div *ngIf="!createRuleMode && !isEditRuleMode" class="wcd-flex-none wcd-flex-horizontal">
		<command-bar *ngIf="!rule.isReadOnly && this.isSuppressionRuleEditDeleteEnabled"
					 [items]="commandBarItems"
					 [styles]="overFlowCommandBarStyles"
					 [disableResponsiveBehavior]="true">
		</command-bar>
	</div>
	<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-vertical wcd-padding-large-horizontal">
		<form class="wcd-full-height wcd-flex-vertical" #suppressionRuleForm="ngForm">
			<div *ngIf="isEditRuleMode && rule.ruleType === suppressionRuleType.MDE; else ruleForm">
				<promotion-banner *ngIf="mtpPromotionService.isMtpBannerVisible; else editRuleBanner"
					trackId="MtpPromotionSuppressionRuleEntity"
					textMessageKey="mtpPromotion.suppressionRule"
					[url]="urls.SuppressionRule + rule.id">
				</promotion-banner>
				<ng-template #editRuleBanner>
					<wcd-banner [text]="i18nService.strings.suppressionRules_complex_rule_not_supported"
						[showCloseButton]="false"></wcd-banner>
				</ng-template>
			</div>
			<ng-template #ruleForm>
				<div class="wcd-flex-1">
					<section class="wcd-margin-bottom">
						<h4 *ngIf="createRuleMode; else ruleDetailsTitle">1.
							{{'suppressionRules.edit.step1Instructions' | i18n}}</h4>
						<ng-template #ruleDetailsTitle *ngIf="!isEditRuleMode">
							<h4 class="definition-list-title">
								{{'suppressionRules_edit_rule_details_header' | i18n}}
							</h4>
						</ng-template>
						<div class="form-group" *ngIf="createRuleMode || isEditRuleMode; else alertTitleLabel">
							<label>{{'suppressionRules_edit_rule_alert_field' | i18n}}</label>
							<div>{{editedRule.alertTitle}}</div>
						</div>
						<ng-template #alertTitleLabel>
							<dl class="key-values" role="none">
								<dt>{{'suppressionRules_edit_rule_status_field' | i18n}}</dt>
								<dd>{{editedRule.isEnabled ? 'On' : 'Off'}}</dd>
								<dt>{{'suppressionRules_edit_rule_alert_title_field' | i18n}}</dt>
								<dd>{{editedRule.alertTitle}}</dd>
								<dt>{{'suppressionRules_edit_rule_action_field' | i18n}}</dt>
								<dd>
									<div>{{editedRule.action.nameI18nKey | i18n}}</div>
								</dd>
								<dt>{{'suppressionRules_edit_rule_suppression_scope_field' | i18n}}</dt>
								<dd>
									<ng-container [ngSwitch]="editedRule.scope.type">
										<div *ngSwitchCase="'Machine'">
											{{'suppressionRules_edit_rule_alert_on_machine_list_header' | i18n}}
											<wcd-shared-icon iconName="System" class="small-icon"> </wcd-shared-icon >
											<route-link *ngIf="machineLink; else machineNameLabel"
														data-track-id="SuppressionRulePanelOpenMachinePage"
														[routeLink]="machineLink">
												<ng-container *ngTemplateOutlet="machineNameLabel"></ng-container>
											</route-link>
											<ng-template #machineNameLabel>
												<span> {{machineName}}</span>
											</ng-template>
										</div>
										<div *ngSwitchCase="'AllOrganization'">
											{{getScopeTitle(editedRule.scope)}}
										</div>
										<div *ngSwitchCase="'Conditional'">
											{{getScopeTitle(editedRule.scope)}}
										</div>
										<div *ngSwitchDefault>
											<wcd-help [text]="relevantRuleGroups">{{getScopeTitle(editedRule.scope)}} </wcd-help>
										</div>
									</ng-container>
								</dd>
							</dl>
						</ng-template>
						<div class="form-group" *ngIf="createRuleMode || isEditRuleMode; else conditionsLabels">
							<label class="required">{{'suppressionRules.page.triggeringIocTitle'| i18n}}</label>
							<wcd-help class="wcd-padding-small-left" [text]="'suppressionRules.edit.IocSelectInstructions' | i18n"></wcd-help>
							<div>
								<fancy-select [(ngModel)]="selectedIoc"
											[ariaLabel]="'Triggering IOC'"
											listId="indication-of-compromise"
											[isBordered]="true"
											labelClass="dropdown-width-medium"
											[placeholder]="placeHolderIoc"
											label="context.name"
											name="indication-of-compromise"
											data-field-label="Triggering IOC"
											required
											(ngModelChange)="setAvailableConditions()"
											[values]="availableIocs"
											[disabled]="true"
								>
								</fancy-select>
							</div>
							<ng-container *ngIf="!!editedRule.threatFamilyName">
								<div class="wcd-margin-bottom wcd-margin-small-top" *ngIf="isEditRuleMode || createRuleMode">
									<wcd-checkbox class="wcd-checkbox"
												[(ngModel)]="isThreatFamilyNameRelevant"
												name="useThreatFamilyName"
												[label]="'suppressionRules.page.threatFamilyName' | i18n: {threatFamilyName : editedRule.threatFamilyName}">
									</wcd-checkbox>
								</div>
							</ng-container>
							<div class="ioc-inputs wcd-padding-top"
								*ngIf="selectedIoc && selectedIoc.type !== noIocUsedOption.type && currentAvailableConditions">
								<div class="form-group">
									<label class="required">{{'suppressionRules.page.conditionsTitle'| i18n}}</label>
									<wcd-help class="wcd-padding-small-left" *ngIf="createRuleMode || isEditRuleMode"
											[text]="'suppressionRules.edit.suppressionConditionsInstructions' | i18n"></wcd-help>
									<div>
										<table class="wcd-table small-padding wcd-full-width">
											<thead>
											<th></th>
											<th>{{'suppressionRules.page.conditionTable.condition'| i18n}}</th>
											<th>{{'suppressionRules.page.conditionTable.operator'| i18n}}</th>
											<th>{{'suppressionRules.page.conditionTable.value'| i18n}}</th>
											</thead>
											<tbody>
											<tr *ngFor="let condition of currentAvailableConditions"
												[class.disabled]="!condition.enabled">
												<td class="nowrap">
													<wcd-checkbox [(ngModel)]="condition.enabled"
																[isDisabled]="!createRuleMode && !isEditRuleMode"
																[name]="'condition_' + condition.properties.name + '_enabled'"></wcd-checkbox>
												</td>
												<td class="nowrap">
													{{condition.properties.name}}
													<wcd-help class="wcd-padding-xsmall-left"
															[text]="'suppressionRules.edit.allowWildcard' | i18n"
															*ngIf="condition.properties.allowWildCard"></wcd-help>
												</td>
												<td>
													<div>{{'Equals'}}</div>
												</td>
												<td style="width:360px">
													<input type="text"
														placeholder="Please enter a value or uncheck the condition"
														class="form-control"
														[attr.data-field-label]="condition.properties.name"
														[disabled]="!condition.properties.allowEdit"
														[(ngModel)]="condition.context[condition.properties.propertyName]"
														#conditionValueInput="ngModel"
														[required]="condition.enabled"
														[validateRegExp]="condition.properties.pattern"
														wcdTooltip="{{condition.context[condition.properties.propertyName]}}"
														[name]="'condition_' + condition.properties.name + '_value'"/>
													<span class="error-message"
														*ngIf="conditionValueInput.dirty && !conditionValueInput.valid">
													{{condition.properties.patternErrorText}}
												</span>
												</td>
											</tr>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
						<ng-template #conditionsLabels>
							<h4 class="wcd-margin-large-top definition-list-title">{{'suppressionRules.display.title' |
								i18n}}</h4>
							<div
								*ngIf="useSelectedIoc && editedRule.iocConditionsNotEmpty; else noIocUseLabel">
								<table class="wcd-table small-padding wcd-full-width">
									<thead>
									<th>{{'suppressionRules.page.conditionTable.condition' | i18n}}</th>
									<th>{{'suppressionRules.page.conditionTable.operator'| i18n}}</th>
									<th>{{'suppressionRules.page.conditionTable.value'| i18n}}</th>
									</thead>
									<tbody>
									<tr *ngFor="let condition of editedRule.conditions">
										<td class="nowrap">
											{{condition.type.name}}
										</td>
										<td>
											<div>{{'Equals'}}</div>
										</td>
										<td>
											{{condition.value}}
										</td>
									</tr>
									</tbody>
								</table>
							</div>
							<ng-template #noIocUseLabel>
								<div>
									{{'suppressionRules.edit.noIocUse' | i18n}}
								</div>
							</ng-template>
						</ng-template>
					</section>
					<section class="wcd-margin-bottom wcd-margin-large-top">
						<div *ngIf="createRuleMode || isEditRuleMode">
							<h4 class="form-section-title">2. {{'suppressionRules.edit.step2Instructions' | i18n}}</h4>
							<div class="form-group">
								<label class="required">{{'suppressionRules_edit_rule_action_field' | i18n}}</label>
								<wcd-radiolist [values]="selectableActions"
											name="suppression-rule-action"
											data-field-label="Action"
											[isDisabled]="useSelectedIoc && !selectedIoc"
											(ngModelChange)="setRuleNameTemplate($event, editedRule.scope)"
											[(ngModel)]="editedRule.action"
											[ariaLabel]="('suppressionRules.edit.step2Instructions' | i18n) + ': ' + ('suppressionRules.edit.step2Instructions.action' | i18n)"
											></wcd-radiolist>
							</div>
							<div class="form-group">
								<label class="required">{{'suppressionRules.edit.step2Instructions.SuppressionScope' | i18n}}</label>
								<wcd-radiolist [values]="selectableScopes"
											name="suppression-rule-scope"
											data-field-label="Suppression scope"
											[isDisabled]="useSelectedIoc && !selectedIoc"
											[labelFieldName]="'title'"
											(ngModelChange)="setRuleNameTemplateChangedScope(editedRule.action, $event)"
											[(ngModel)]="editedRule.scope"
											[ariaLabel]="('suppressionRules.edit.step2Instructions' | i18n) + ': ' + ('suppressionRules.edit.step2Instructions.SuppressionScope' | i18n)"
											></wcd-radiolist>
							</div>
							<div *ngIf="isLoadingScopes">
								<wcd-shared-icon class="loader-icon wcd-margin-small-right ms-icon-top-2-fix"> </wcd-shared-icon >
								{{"entity.loadingOptions" | i18n}}
							</div>
							<ng-container *ngIf="isRbacFilteringNecessary && !isLoadingScopes && userExposedMachineGroups.length && isDeviceScopeEnabled">
								<wcd-checklist-dropdown
									[(ngModel)]="currentSelectedMachineGroupsIds"
									(ngModelChange)="setRuleNameTemplate(editedRule.action, editedRule.scope, $event)"
									[isFullWidth]="true"
									placeholder="{{groupsDropdownPlaceholder}}"
									name="select-groups-for-scope"
									[disableDropdown]="disableGroupsDropdown"
									[values]="userExposedMachineGroups"
									[required]="!disableGroupsDropdown"
									[ariaLabel]="machineGroup.title"
									optionAriaRole="option"
									ariaRole="listbox"
									ariaHaspopup="listbox"
									>
								</wcd-checklist-dropdown>
								<div *ngIf="!disableGroupsDropdown && !currentSelectedMachineGroupsIds.length && additionalDetailsTouched"
									class="error-message">
									{{'suppressionRules.edit.unselectGroupsError' | i18n}}
								</div>
							</ng-container>
							<div *ngIf="errorLoadingScopeTypes" class="error-message">
								{{'suppressionRules.scope.errorMessage' | i18n}}
							</div>
						</div>
					</section>
					<section *ngIf="createRuleMode || isEditRuleMode" class="wcd-margin-bottom wcd-margin-large-top">
						<h4 class="form-section-title">3. {{'suppressionRules.edit.step3Instructions' | i18n}}</h4>
						<div class="form-group">
							<wcd-help [text]="'suppressionRules.edit.ruleNameAutoGenerate' | i18n">
								<label class="required">{{'suppressionRules_edit_rule_rule_name_field' | i18n}}</label>
							</wcd-help>
							<textarea
								aria-label="Rule name"
								rows="2"
								required
								id="rule-name"
								class="form-control"
								name="rule-name"
								data-field-label="Rule name"
								(ngModelChange)="setRuleNameEditedFromView()"
								[(ngModel)]="editedRule.name"></textarea>
						</div>
						<div class="form-group wcd-margin-top wcd-textarea-placeholder">
							<label class="required">{{'suppressionRules_edit_rule_comment_field' | i18n}}</label>
							<textarea
								aria-label="Comment"
								rows="3"
								id="rule-comment"
								name="rule-comment"
								data-field-label="Comment"
								placeholder="Add your comment"
								required
								class="form-control"
								(ngModelChange)="setCommentEditedFromView()"
								[(ngModel)]="comment"></textarea>
						</div>
					</section>
					<section class="wcd-margin-bottom">
						<div *ngIf="createRuleMode">
							<wcd-shared-icon [iconName]="'info'" class="color-text-primary ms-icon-top-2-fix"> </wcd-shared-icon >
							{{'suppressionRules.edit.futureAlertsOnly' | i18n}}
						</div>
						<div *ngIf="isEditRuleMode">
							<wcd-shared-icon [iconName]="'info'" class="color-text-primary ms-icon-top-2-fix"> </wcd-shared-icon >
							{{'suppressionRules.edit.futureAlertsOnlyEdit' | i18n}}
						</div>
						<div class="wcd-padding-vertical" *ngIf="isError">
							<div class="error-message">{{'suppressionRules.edit.saveError' | i18n}}</div>
						</div>
					</section>
					<section class="wcd-margin-bottom wcd-padding-vertical" *ngIf="!createRuleMode && !isEditRuleMode">
						<h4 class="definition-list-title">
							{{'suppressionRules_edit_rule_comment_and_history_field' | i18n}}
						</h4>
						<editable-comments-list [comments]="history"
												(save)="addCommentToRule($event)"
												[rbac]="rbacSettingsDisabled"
												[isDisabled]="editedRule.isReadOnly"
												[readonly]="editedRule.isReadOnly"
												[isSaving]="isSavingComment"></editable-comments-list>
					</section>
				</div>
			</ng-template>
		</form>
	</div>
	<div *ngIf="(createRuleMode || isEditRuleMode) && suppressionRuleForm.form.dirty && (!suppressionRuleForm.form.valid || !isValid)"
		 class="color-box-error wcd-flex-none wcd-padding-small-vertical wcd-padding-horizontal">
		{{formValidationMessage}}
	</div>
	<div *ngIf="createRuleMode || isEditRuleMode" class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
		<div class="wcd-flex-none wcd-margin-small-right">
			<fab-primary-button
					data-track-id="SaveSuppressionRule"
					data-track-type="Button"
					[disabled]="suppressionRuleForm.form.pristine || !suppressionRuleForm.form.valid || isSaving || !isValid || (!disableGroupsDropdown && !currentSelectedMachineGroupsIds.length)"
					(onClick)="saveSuppressionRule()">
				<i class="loader-icon" [hidden]="!isSaving"></i>
				{{ 'buttons.' + (isSaving ? 'saving' : 'save') | i18n }}
			</fab-primary-button>
		</div>
		<div class="wcd-flex-none">
			<fab-default-button
					data-track-id="Cancel"
					data-track-type="Button"
					(onClick)="closePanel.emit()">{{'buttons.cancel' | i18n}}</fab-default-button>
		</div>
	</div>
</div>
