import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { FabricIconNames } from '@wcd/scc-common';
import { KnownColor } from '@wcd/shared';

@Component({
	selector: 'wcd-stretched-donut-bars',
	templateUrl: './stretched-donut-bars.component.html',
	styleUrls: ['./stretched-donut-bars.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StretchedDonutBarsComponent {
	@Input() data: Array<StretchedDonutBarItem>;
	@Input() barTitleCssClass = 'wcd-font-weight-bold';
	@Input() barCssClass? = 'wcd-margin-xsmall-bottom';
	@Input() ariaLabelledby: string = null;
	@Input() valueAriaLabel: string = null;
	@Input() totalAriaLabel: string = null;

	@Output() onClick = new EventEmitter<StretchedDonutBarItem>(false);

	trackById(index, item: StretchedDonutBarItem) {
		return item.id;
	}
}

export interface StretchedDonutBarItem {
	id: string;

	/**
	 * Flag to show value as percentage out of total
	 */
	showValueAsPercentage?: boolean;

	/**
	 * text for title which is displayed above the bar
	 */
	title?: string;

	/**
	 * A number which reflects the completed part of the bar. For example, '30' in: "30/50"
	 */
	value?: number;

	/**
	 * A number which reflects the total part of the bar. For example, '50' in: "30/50"
	 */
	total?: number;

	/**
	 * unit of measurement. For example 's' in "90/100s"
	 */
	unit?: string;

	/**
	 * known color to use as the background color of the completed part of the bar
	 */
	valueColor?: KnownColor;

	/**
	 * known color to use as the background color of the incomplete part of the bar
	 */
	totalColor?: KnownColor;

	/**
	 * For number - the width of the bar in pixels
	 * For string - passed as is e.g. "100%" or "2rem"
	 */
	width?: number | string;

	/**
	 * The height of the bar
	 */
	height?: number | string;

	/**
	 * fabric icon to be displayed near the title
	 */
	icon?: FabricIconNames;

	/**
	 * text to be displayed when hovering on the icon which is displayed near the title
	 */
	iconTooltip?: string;

	/**
	 * is there an option to click on the bar or not
	 */
	disableClick?: boolean;

	/**
	 * text to be displayed instead of the numbers (X/Y)
	 */
	noDataText?: string;

	/**
	 * true to use noDataText as custom display instead of the numbers (X/Y)
	 */
	useCustomDisplay?: boolean;

	/**
	 * prefix to be displayed when hovering on the completed part of the bar, for example, 'completed' in: "completed: 30/50"
	 */
	valuePrefix?: string;

	/**
	 * prefix to be displayed when hovering on the incomplete part of the bar, for example, 'incomplete' in: "incomplete: 30/50"
	 */
	restPrefix?: string;

	/**
	 * should the bar be full or empty when there is no data (0/0)
	 */
	isDefaultFull?: boolean;

	tabIndex?: string;
}
