var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, EventEmitter, OnInit, } from '@angular/core';
import { EncodeQueryTextApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MessageBarType } from 'office-ui-fabric-react';
import { MagellanIsolationStatusService } from '../../services/magellan-isolation.service';
var IsolationModalWarningsComponent = /** @class */ (function () {
    function IsolationModalWarningsComponent(paris, changeDetector, magellanIsolationStatusProvider) {
        this.paris = paris;
        this.changeDetector = changeDetector;
        this.magellanIsolationStatusProvider = magellanIsolationStatusProvider;
        this.onReady = new EventEmitter();
        this.MessageBarType = MessageBarType;
        this.devicesWithSameIp = [];
        this.devicesWithSameIpLink = '/hunting';
    }
    IsolationModalWarningsComponent.prototype.ngOnInit = function () {
        this.init();
    };
    IsolationModalWarningsComponent.prototype.init = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, _b, e_1, _c;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        _d.trys.push([0, 3, 8, 9]);
                        _a = this;
                        return [4 /*yield*/, this.magellanIsolationStatusProvider.getDevicesWithSameIps(this.deviceId)];
                    case 1:
                        _a.devicesWithSameIp = _d.sent();
                        _b = this;
                        return [4 /*yield*/, this.getQueryLink()];
                    case 2:
                        _b.devicesWithSameIpLink = _d.sent();
                        return [3 /*break*/, 9];
                    case 3:
                        e_1 = _d.sent();
                        _d.label = 4;
                    case 4:
                        _d.trys.push([4, , 6, 7]);
                        _c = this;
                        return [4 /*yield*/, this.getQueryLinkFromDeviceId()];
                    case 5:
                        _c.devicesWithSameIpLink = _d.sent();
                        return [3 /*break*/, 7];
                    case 6:
                        this.errorOnDevicesWithSameIpRequest = true;
                        return [7 /*endfinally*/];
                    case 7: return [3 /*break*/, 9];
                    case 8:
                        this.changeDetector.detectChanges();
                        this.onReady.emit();
                        return [7 /*endfinally*/];
                    case 9: return [2 /*return*/];
                }
            });
        });
    };
    IsolationModalWarningsComponent.prototype.getQueryLinkFromDeviceId = function () {
        var queryText = "let IsolatedDeviceId = \"" + this.deviceId + "\";\n\t\t\t\tlet deviceIps = DeviceNetworkInfo\n\t\t\t\t| where DeviceId == IsolatedDeviceId\n\t\t\t\t| summarize arg_max(Timestamp, *) by DeviceId\n\t\t\t\t| join DeviceNetworkInfo on ReportId, DeviceId\n\t\t\t\t| project DeviceId, IPAddresses\n\t\t\t\t| mvexpand parse_json(IPAddresses )\n\t\t\t\t| project DeviceId, IPAddress = tostring(IPAddresses.IPAddress);\n\t\t\t\tlet otherDevices = DeviceNetworkInfo\n\t\t\t\t| where DeviceId != IsolatedDeviceId\n\t\t\t\t| summarize arg_max(Timestamp, *) by DeviceId\n\t\t\t\t| join DeviceNetworkInfo on ReportId, DeviceId\n\t\t\t\t| project DeviceId, IPAddresses, Timestamp\n\t\t\t\t| mvexpand parse_json(IPAddresses )\n\t\t\t\t| project DeviceId, Timestamp, IPAddress = tostring(IPAddresses.IPAddress);\n\t\t\t\t// results\n\t\t\t\tdeviceIps\n\t\t\t\t| join kind=rightsemi otherDevices on IPAddress\n\t\t\t\t| summarize arg_max(Timestamp, *) by DeviceId";
        return this.getAdvancedHuntingLink(queryText);
    };
    IsolationModalWarningsComponent.prototype.getQueryLink = function () {
        if (this.devicesWithSameIp.length <= 1) {
            return '';
        }
        var queryText = "DeviceInfo\n\t\t| where DeviceId in (\"" + this.devicesWithSameIp.join('", "') + "\")\n\t\t| summarize arg_max(Timestamp, *) by DeviceId";
        return this.getAdvancedHuntingLink(queryText);
    };
    IsolationModalWarningsComponent.prototype.getAdvancedHuntingLink = function (queryText) {
        return __awaiter(this, void 0, void 0, function () {
            var encodedQuery;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.paris
                            .apiCall(EncodeQueryTextApiCall, queryText)
                            .toPromise()];
                    case 1:
                        encodedQuery = _a.sent();
                        return [2 /*return*/, "/hunting?query=" + encodedQuery + "&timeRangeId=" + "month" /* month */];
                }
            });
        });
    };
    return IsolationModalWarningsComponent;
}());
export { IsolationModalWarningsComponent };
