import { ValueObject, ModelBase, EntityField, ApiCall, ApiCallModel } from '@microsoft/paris';
import { HuntingTableSchema } from './hunting-table-schema.entity';
import { HuntingFunctionSchema } from './hunting-function-schema.value-object';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ValueObject({
	pluralName: 'Hunting database schemas',
	singularName: 'Hunting database schema',
	readonly: true,
})
export class HuntingDatabaseSchema extends ModelBase {
	@EntityField({ data: 'Tables', arrayOf: HuntingTableSchema, defaultValue: [] })
	tables: Array<HuntingTableSchema>;

	@EntityField({ data: 'Functions', arrayOf: HuntingFunctionSchema, defaultValue: [] })
	functions: Array<HuntingFunctionSchema>;
}

@ApiCall({
	name: 'Get hunting database schema',
	method: 'GET',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	endpoint: 'schema',
	type: HuntingDatabaseSchema,
})
export class HuntingDatabaseSchemaApiCall extends ApiCallModel<HuntingDatabaseSchema> {}
