var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityStatusComponentBase } from '../../../global_entities/components/entity-status/entity-status.component.base';
var AlertStatusComponent = /** @class */ (function (_super) {
    __extends(AlertStatusComponent, _super);
    function AlertStatusComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AlertStatusComponent.prototype, "alert", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return AlertStatusComponent;
}(EntityStatusComponentBase));
export { AlertStatusComponent };
