import { IMachineVulnerabilityAgeLevel, MachineVulnerabilityAgeLevelType } from './machine-vulnerability-age-level.models';

export const machineVulnerabilityAgeLevelValues: Array<IMachineVulnerabilityAgeLevel> = [
	{
		id: MachineVulnerabilityAgeLevelType.None,
		name: 'None',
		nameI18nKey: 'machineVulnerabilityAgeLevel_None',
		priority: 5,
	},
	{
		id: MachineVulnerabilityAgeLevelType.Age0To30days,
		name: 'Age0To30days',
		nameI18nKey: 'machineVulnerabilityAgeLevel_Age0To30days',
		priority: 4,
	},
	{
		id: MachineVulnerabilityAgeLevelType.Age31To60days,
		name: 'Age31To60days',
		nameI18nKey: 'machineVulnerabilityAgeLevel_Age31To60days',
		priority: 3,
	},
	{
		id: MachineVulnerabilityAgeLevelType.Age61To90days,
		name: 'Age61To90days',
		nameI18nKey: 'machineVulnerabilityAgeLevel_Age61To90days',
		priority: 2,
	},
	{
		id: MachineVulnerabilityAgeLevelType.AgeMoreThan90days,
		name: 'AgeMoreThan90days',
		nameI18nKey: 'machineVulnerabilityAgeLevel_AgeMoreThan90days',
		priority: 1,
	},
];
