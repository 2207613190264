/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./progress-indicator.component";
var styles_ProgressIndicatorComponent = [".progress-track[_ngcontent-%COMP%] {\n\t\t\t\theight: 2px;\n\t\t\t\tmargin: 4px 0;\n\t\t\t}"];
var RenderType_ProgressIndicatorComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_ProgressIndicatorComponent, data: {} });
export { RenderType_ProgressIndicatorComponent as RenderType_ProgressIndicatorComponent };
function View_ProgressIndicatorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_ProgressIndicatorComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { content: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "div", [["class", "progress-indicator"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProgressIndicatorComponent_1)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 3, "div", [["class", "progress-track color-box-gray-175"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(9, 0, [[1, 0], ["content", 1]], null, 0, "div", [["aria-valuemax", "100"], ["aria-valuemin", "0"], ["class", "color-box-primary wcd-full-height"], ["role", "progressbar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_ProgressIndicatorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "progress-indicator", [], null, null, null, View_ProgressIndicatorComponent_0, RenderType_ProgressIndicatorComponent)), i0.ɵdid(1, 638976, null, 0, i2.ProgressIndicatorComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProgressIndicatorComponentNgFactory = i0.ɵccf("progress-indicator", i2.ProgressIndicatorComponent, View_ProgressIndicatorComponent_Host_0, { time: "time", percent: "percent", timeStart: "timeStart", label: "label" }, { end: "end" }, []);
export { ProgressIndicatorComponentNgFactory as ProgressIndicatorComponentNgFactory };
