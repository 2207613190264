import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { TagType } from './tag-type.enum';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Tag',
	pluralName: 'Tags',
	endpoint: 'tags',
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	supportedEntityGetMethods: [EntityGetMethod.query],
})
export class Tag extends EntityModelBase {
	@EntityField({ parse: id => String(id) })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField() name?: string;

	@EntityField() nameKey?: string;

	@EntityField() type?: TagType;

	@EntityField() className?: string;

	@EntityField() tooltip?: string;

	@EntityField() ariaLabel?: string;

	@EntityField({ defaultValue: false })
	isPartial?: boolean;

	@EntityField({ defaultValue: true })
	isEditable?: boolean;
}
