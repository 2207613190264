<!-- tslint:disable:template-accessibility-alt-text -->
<div class="wcd-flex-horizontal-spaced">

  <div>
    <h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">
      {{ 'mtpPromotion.widgetTitle' | i18n }}
    </h2>
    <div class="wcd-padding-bottom wcd-font-size-s">
      {{ 'mtpPromotion.widgetDescription' | i18n }}
    </div>

    <div class="wcd-font-weight-bold wcd-font-size-s">
      <markdown>{{ 'mtpPromotion.widgetFooter' | i18n }}</markdown>
    </div>

    <div class="try-it">
      <fab-primary-button contentClass="wcd-margin-top" [text]="'mtpPromotion.tryNow' | i18n"
        data-track-id="mtpPromotion_TryNow" data-track-type="Button" [ariaLabel]="'mtpPromotion.tryNow' | i18n"
        [href]="incidentsUrl" target='_blank'
        ></fab-primary-button>
    </div>

    <div class="wcd-padding-small-top">
		<a
			(click)="dismiss()"
			(keydown.enter)="dismiss()"
			data-track-id="mtpPromotionWidget"
			data-track-value="hideCard"
			role="button"
			tabindex="0"
			>{{ 'mtpPromotion.hideCard' | i18n }}
		</a>
	</div>

  </div>
  <div class="promotion-img wcd-margin-right wcd-margin-bottom" >
    <img [src]="'/assets/images/promotion/promotion-widget.svg'" />
  </div>
</div>
