var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
// tslint:disable:template-click-events-have-key-events
import { AfterViewInit, ChangeDetectorRef, OnInit } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { HuntingDocumentationService } from '../services/hunting-documentation.service';
var HuntingDocumentationSchemaTableListReferencePanelComponent = /** @class */ (function (_super) {
    __extends(HuntingDocumentationSchemaTableListReferencePanelComponent, _super);
    function HuntingDocumentationSchemaTableListReferencePanelComponent(huntingDocumentationService, router, changeDetectorRef) {
        var _this = _super.call(this, router) || this;
        _this.huntingDocumentationService = huntingDocumentationService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.filterTerm = null;
        _this.loading = true;
        return _this;
    }
    HuntingDocumentationSchemaTableListReferencePanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.tableNamesToShow = this.tableNameList;
    };
    HuntingDocumentationSchemaTableListReferencePanelComponent.prototype.ngAfterViewInit = function () {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
    };
    HuntingDocumentationSchemaTableListReferencePanelComponent.prototype.searchTermChanged = function () {
        var _this = this;
        this.tableNamesToShow = this.tableNameList.filter(function (value) {
            return value.toLowerCase().includes(_this.filterTerm.toLowerCase());
        });
    };
    HuntingDocumentationSchemaTableListReferencePanelComponent.prototype.openTableReferenceSidePanelKeyboard = function ($event, tableName) {
        $event.preventDefault();
        $event.stopPropagation();
        this.openTableReferenceSidePanel(tableName);
    };
    HuntingDocumentationSchemaTableListReferencePanelComponent.prototype.openTableReferenceSidePanel = function (tableName) {
        this.huntingDocumentationService.openDocTableSidePanel(tableName);
    };
    return HuntingDocumentationSchemaTableListReferencePanelComponent;
}(PanelContainer));
export { HuntingDocumentationSchemaTableListReferencePanelComponent };
