export enum OutbreakTagTypes {
	'Ransomware' = 'Ransomware',
	'Phishing' = 'Phishing',
	'CVE' = 'CVE',
	'TrackedActor' = 'TrackedActor',
}

export interface OutbreakTag
{
	name: string;
	type: OutbreakTagTypes;
	color: string,
	class: string,
	nameI18nKey: string,
	priority: number;
}

export const outbreakTags: Array<OutbreakTag> = [
	{
		name: 'Ransomware',
		type: OutbreakTagTypes.Ransomware,
		color:"red",
		class: "color-box-red",
		nameI18nKey: 'dashboard_threatAnalytics_outbreakTag_ransomware',
		priority: 1
	 },
	{
		name: 'Phishing',
		type: OutbreakTagTypes.Phishing,
		color:"blue",
		class: "color-box-gray-900",
		nameI18nKey: 'dashboard_threatAnalytics_outbreakTag_phishing',
		priority: 2
	},
	{
		 name: 'CVE',
		 type: OutbreakTagTypes.CVE,
		 color:"orange",
		 class: "color-box-gray-900",
		 nameI18nKey: 'dashboard_threatAnalytics_outbreakTag_cve',
		 priority: 3
	},
	{
		name: 'TrackedActor',
		type: OutbreakTagTypes.TrackedActor,
		color:"blueMid",
		class: "color-box-gray-900",
		nameI18nKey: 'dashboard_threatAnalytics_outbreakTag_trackedActor',
		priority: 4
	}
];
