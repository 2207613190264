
		<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<notification-rule-edit
				class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
				[rule]="rule"
				(save)="onDone($event)"
				(cancel)="cancel()"
			>
			</notification-rule-edit>
		</wcd-panel>
	