import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirsDriver } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'airs-driver-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.displayName">
				<dt role="none">{{ i18nService.strings.airsEntities_driver_fields_displayName }}</dt>
				<dd role="none">{{ entity.displayName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_general_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.binPath">
				<dt role="none">{{ i18nService.strings.airsEntities_driver_fields_driverPath }}</dt>
				<dd role="none">{{ entity.binPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.startType">
				<dt role="none">{{ i18nService.strings.airsEntities_driver_fields_startMode }}</dt>
				<dd role="none">{{ entity.startType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.driverType">
				<dt role="none">{{ i18nService.strings.airsEntities_driver_fields_type }}</dt>
				<dd role="none">{{ entity.driverType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.serviceState">
				<dt role="none">{{ i18nService.strings.airsEntities_driver_fields_serviceState }}</dt>
				<dd role="none">{{ entity.serviceState }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsDriverDetailsComponent {
	@Input() entity: AirsDriver;

	constructor(public i18nService: I18nService) {}
}
