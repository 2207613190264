import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Email headers',
	endpoint: (config, query) =>
		query && query.where && query.where['EnableNewMailItemAPI']
			? 'MailItem/GetMessageHeaders'
			: 'MailItem/GetMailItemHeaders',
	baseUrl: '<dgs>',
	method: 'POST',
	parseQuery: (params: MailHeadersParams) => {
		return {
			data: {
				internetMessageId: params.internetMessageId,
				mailboxId: params.mailboxId,
			},
			params: {
				EnableNewMailItemAPI: params.EnableNewMailItemAPI,
			},
		};
	},
	parse: data => {
		return (
			data &&
			data.InternetMessageHeaders &&
			data.InternetMessageHeaders.map(h => `${h.Name}: ${h.Value}`)
		);
	},
	cache: {
		time: 1000 * 60 * 10,
	},
})
export class EmailHeadersApiCall extends ApiCallModel<Array<string>, MailHeadersParams> {}

interface MailHeadersParams {
	internetMessageId: string;
	mailboxId: string;
	EnableNewMailItemAPI: boolean;
}
