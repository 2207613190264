var _a;
export var AlertStatusType;
(function (AlertStatusType) {
    AlertStatusType["Unspecified"] = "Unspecified";
    AlertStatusType["New"] = "New";
    AlertStatusType["InProgress"] = "InProgress";
    AlertStatusType["Resolved"] = "Resolved";
    AlertStatusType["Hidden"] = "Hidden";
    AlertStatusType["ResolvedValid"] = "Resolved [Valid]";
    AlertStatusType["ResolvedValidAllowed"] = "Resolved [Valid - Allowed]";
    AlertStatusType["ResolvedFalseAlarm"] = "Resolved [False Alarm]";
})(AlertStatusType || (AlertStatusType = {}));
export var AlertV3StatusType;
(function (AlertV3StatusType) {
    AlertV3StatusType["Unknown"] = "Unknown";
    AlertV3StatusType["New"] = "New";
    AlertV3StatusType["Resolved"] = "Resolved";
    AlertV3StatusType["Dismissed"] = "Dismissed";
    AlertV3StatusType["InProgress"] = "InProgress";
})(AlertV3StatusType || (AlertV3StatusType = {}));
export var AlertStatusTypeId = {
    Unspecified: 1,
    New: 2,
    InProgress: 4,
    Resolved: 8,
    Hidden: 16,
    ResolvedValid: 32,
    ResolvedValidAllowed: 64,
    ResolvedFalseAlarm: 128,
};
/*
    TODO:
    'Dismissed' status in AlertV3 is not part of the existing Alert statuses.
    For now it will be mapped to 'Resolved' but it's still an open issue on PM side.
*/
export var AlertV3StatusTypeMapping = (_a = {},
    _a[AlertV3StatusType.Unknown] = AlertStatusType.Unspecified,
    _a[AlertV3StatusType.New] = AlertStatusType.New,
    _a[AlertV3StatusType.Resolved] = AlertStatusType.Resolved,
    _a[AlertV3StatusType.Dismissed] = AlertStatusType.Resolved,
    _a[AlertV3StatusType.InProgress] = AlertStatusType.InProgress,
    _a);
export var ActiveAlertStatuses = AlertStatusTypeId.New | AlertStatusTypeId.InProgress;
