import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Setting',
	pluralName: 'Settings',
	endpoint: 'baseline/allSettings',
	readonly: true,
})
export class BaselineConfiguration extends EntityModelBase {
	@EntityField() readonly name: string;

	@EntityField() readonly description: string;

	@EntityField() readonly category: string;

	@EntityField() readonly subCategory: string;

	@EntityField() readonly compliantDevices: number;

	@EntityField() readonly applicableDevices: number;

	@EntityField() readonly compliancePct: number;

	@EntityField() readonly complianceLevels: string[];

	@EntityField() readonly cce: string; // maybe needs data

	@EntityField() readonly scored: boolean;

	@EntityField() readonly rationale: string;

	@EntityField() readonly impact: string;

	@EntityField() readonly path: string;

	@EntityField() readonly remediation: string;

	@EntityField() readonly audit: string;

	@EntityField({
		defaultValue: false
	}) readonly isProfileConfiguration: boolean;

	@EntityField() readonly detectedValues?: string[];

	@EntityField() readonly sourceValues?: string[];

	@EntityField() readonly expectedValues?: string[];
}
