import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { WizardStepConfig } from '@wcd/wizard';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';
import { ProfileNameStepComponent } from './profile-name-step.component';
import { ProfileSettingsStepComponent } from './profile-settings-step.component';
import { ProfileConfigurationsAssignemntStepComponent } from './profile-configurations-assignment-step.component';
import { ProfileReviewStepComponent } from './profile-review-step.component';

@Component({
	selector: 'profile-wizard',
	template: `
		<wcd-panel #panel (close)="onClosePanel()" [settings]="settings">
			<wcd-wizard
				[steps]="steps"
				[data]="formData"
				wizardTitlePrefix="baseline_profile_wizard_breadcrumbs_prefix"
				[wizardTitle]="title"
				wizardTrackingName= "ProfileCreationWizard"
				(onClose)="onClosePanel()"
				(onDone)="onRuleCreated()"
			></wcd-wizard>
		</wcd-panel>
	`,
})
export class ProfileWizardComponent extends PanelContainer implements OnInit {
	@ViewChild(PanelComponent, { static: false }) panel: PanelComponent;
	@Input() onDone: Function;
	@Input() onClose: Function;
	@Input() data: ProfileForCreationFormData;
	@Input() isUpdateFlow: boolean;

	title: string;
	steps: WizardStepConfig<ProfileForCreationFormData>[];
	formData: ProfileForCreationFormData;

    constructor(
		router: Router,
	) {
		super(router);
		this.setSteps();
    }

	ngOnInit()
	{
		this.title = this.isUpdateFlow
			? 'baseline_profile_wizard_breadcrumbs_update'
			: 'baseline_profile_wizard_breadcrumbs_creation';
		this.formData = { isUpdateFlow: this.isUpdateFlow, ...this.data };
		this.steps[3].nextButtonText = this.isUpdateFlow
			? 'baseline_profile_wizard_update_profile'
			: 'baseline_profile_wizard_create_profile';
	}

	onRuleCreated() {
		this.closePanel();
		this.onDone();
	}

	onClosePanel() {
		this.closePanel();
		this.onClose();
    }

	setSteps() {
		this.steps = [
			{
				id: 1,
				name: 'ProfileName',
				verticalStepKeyName: 'baseline_profile_wizard_profile_name_step_title',
				component: ProfileNameStepComponent,
			},
			{
				id: 2,
				name: 'ProfileSettings',
				verticalStepKeyName: 'baseline_profile_wizard_settings_step_title',
				component: ProfileSettingsStepComponent,
			},
			{
				id: 3,
				name: 'ConfigurationAssignment',
				verticalStepKeyName: 'baseline_profile_wizard_profile_configurations_assignment_label',
				component: ProfileConfigurationsAssignemntStepComponent,
				wideStep: true,
			},
			{
				id: 4,
				name: 'ReviewProfile',
				verticalStepKeyName: 'baseline_profile_wizard_review_profile_step_title',
				component: ProfileReviewStepComponent,
				onNext: true,
				nextButtonText: 'baseline_profile_wizard_create_profile',
			},
		];
	}
}
