<dl [class.key-values]="asKeyValueList" role="none">
	<dt>{{ 'events.fields.description.title' | i18n }}</dt>
	<dd>
		{{description}}
	</dd>
	<ng-container *ngIf="showEventDate">
		<dt>{{ 'events.fields.date.title' | i18n }}</dt>
		<dd>
			<tz-date [date]="event.actionTime" [dateFormat]="'shortWithMillis'"></tz-date>
		</dd>
	</ng-container>
	<ng-container *ngIf="showActionType">
		<dt>{{ 'events.fields.actionTypes.title' | i18n }}</dt>
		<dd>
			{{ actionType }}
		</dd>
	</ng-container>
	<ng-container *ngIf="tags && tags.length">
		<dt>{{ 'events.fields.details.title' | i18n }}</dt>
		<dd>
			<tags-list [tags]="tags"></tags-list>
		</dd>
	</ng-container>
	<ng-container *ngIf="event.initiatingUser">
		<dt>{{ 'events.fields.user.title' | i18n }}</dt>
		<dd>
			 <wcd-shared-icon iconName="users.user"> </wcd-shared-icon >
			<ng-container *ngIf="isUserLinkAllowed; else userName">
				<entity-link [entity]="event.initiatingUser"
							 [linkLogContext]="contextLog"
							 [entityType]="userType"
							 [linkText]="event.initiatingUser.fullName"></entity-link>
			</ng-container>
			<ng-template #userName>
				{{event.initiatingUser.fullName}}
			</ng-template>
		</dd>
	</ng-container>
	<ng-container *ngIf="showMachineLink">
		<dt>{{ 'events.fields.machine.title' | i18n }}</dt>
		<dd>
			<fab-icon iconName="System" contentClass="small-icon"></fab-icon>
			<!--TODO: The if will be necessary till the next BE deployment (17.2.19). it can be removed after it-->
			<ng-container *ngIf="event.machine.machineId; else machineName">
					<entity-link [entity]="event.machine"
								 [linkLogContext]="contextLog"
								 [entityType]="machineType"
								 [linkText]="globalEntityTypesService.getEntityType(machineType).getEntityName(event.machine)"
								 [actionTime]="event.actionTime"></entity-link>
					<machine-timeline-link
						[machineId]="event.machine.machineId"
						[actionTime]="event.actionTime">
					</machine-timeline-link>
			</ng-container>
			<ng-template #machineName>
				{{globalEntityTypesService.getEntityType(machineType).getEntityName(event.machine)}}
			</ng-template>
		</dd>
	</ng-container>
	<ng-container *ngIf="event.email?.linkToO365Portal">
		<dt>{{ 'events.fields.source.title' | i18n }}</dt>
		<dd>
			<a [href]="event.email.linkToO365Portal"
			   data-track-id="O365DeepLinkToEmail"
			   data-track-type="ExternalLink">{{ 'events.fields.source.o365LinkTitle' | i18n }}</a>
		</dd>
	</ng-container>
	<ng-container *ngIf="mitreTechniquesObjects?.length">
		<dt>{{ 'events.fields.mitreTechniques.title' | i18n }}</dt>
		<dd>
			<div *ngFor="let mitreTechniqueObject of mitreTechniquesObjects">
				<a href="{{ mitreTechniqueObject.link }}"
					target="_blank">{{ mitreTechniqueObject.nameWithId }}</a>
			</div>
		</dd>
	</ng-container>
</dl>

<generic-fields-list *ngIf="event.genericFields" [genericFields]="event.genericFields" [linkLogContext]="contextLog"></generic-fields-list>
