import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('sidePaneCopyBlock', sidePaneCopyBlockDirective);

sidePaneCopyBlockDirective.$inject = [];

function sidePaneCopyBlockDirective() {
	return {
		restrict: 'EA',
		scope: {
			title: '=title',
			key: '=key',
			text: '=text',
		},
		controllerAs: 'block',
		bindToController: true,
		template: `
		<table class="side-pane-copy-table">
        <tr>
            <td>
                <div class="side-pane-property-copy-container">
                    <textarea readonly rows="1" class="side-pane-property-copy-text" id="{{block.id}}" ng-model="block.text" aria-label="{{block.key}}"></textarea>
                </div>
            </td>
            <td class="side-pane-copy-icon-cell">
                <i class="icon side-pane-property-copy-icon icon-button {{block.icon}}" title="Copy to clipboard" ng-click="block.copyText()"></i>
            </td>
        </tr>
    </table>
		`,
		controller: [
			'$log',
			'$timeout',
			function($log, $timeout) {
				var vm = this;
				vm.id = (vm.title + '-' + vm.key).replace(' ', '_');
				vm.icon = 'icon-Copy';

				vm.copyText = function() {
					try {
						// Focus and select the text box
						var textArea: HTMLTextAreaElement = <HTMLTextAreaElement>$('#' + vm.id)[0];
						textArea.select();
						// Send the text to clipboard
						document.execCommand('copy');

						$log.debug('copied from ' + vm.id);

						vm.icon = 'icon-CheckMarkZeroWidth side-pane-copy-icon-animation';

						$timeout(function() {
							vm.icon = 'icon-Copy';
						}, 1100);
					} catch (e) {
						$log.debug('could not copy from ' + vm.id + ' ' + e);
					}
				};
			},
		],
	};
}
