<wcd-panel (close)="destroy()" [settings]="settings"
		   class="wcd-full-height "
		   data-track-component="hunting-documentation-fields-schema-list-panel"
		   data-track-component-type="Side Panel">
	<wcd-contents-state [contentState]="contentState" class="content-container wcd-full-height">
		<fab-spinner loading></fab-spinner>
		<ng-container complete *ngTemplateOutlet="completeOrEmpty"></ng-container>
		<ng-container empty *ngTemplateOutlet="completeOrEmpty"></ng-container>
		<ng-container error>{{'hunting.results.errors.internalServerError.title' | i18n}}</ng-container>
	</wcd-contents-state>

	<ng-template #completeOrEmpty>
		<ng-container *ngIf="tableReference">
			<div class="wcd-padding-large-horizontal  wcd-full-height wcd-flex-vertical ">
				<header class="wcd-padding-largeMedium-vertical">
					<h3 class="wcd-documentation-tab_title">
						{{tableName}}
					</h3>
				</header>
				<div class="wcd-scroll-vertical  wcd-flex-vertical wcd-full-height">
					<div *ngIf="tableReference.description">
						<dt role="none">
							{{'hunting.documentation.table.reference.panel.description.section.title' | i18n}}
						</dt>
						<dd  role="none">
							{{tableReference.description}}
						</dd>
					</div>
					<ng-container *ngIf="tableReference.fields?.length">
						<ng-container *ngTemplateOutlet="tableFields"></ng-container>
					</ng-container>
					<ng-container *ngIf="tableReference.actionTypes?.length">
						<ng-container *ngTemplateOutlet="actionTypes"></ng-container>
					</ng-container>
					<ng-container *ngIf="tableReference.queries?.length">
						<ng-container *ngTemplateOutlet="queries"></ng-container>
					</ng-container>
				</div>
				<div
					class="wcd-padding-medium-vertical wcd-padding-small-left wcd-full-width">
					<a
						type="button"
						data-track-id="fullDocumentationButton"
						data-track-type="Button"
						class="btn-link"
						[href]="tableReference.HasExternalLink ? tableReference.getExternalLink() : huntingDocumentationService.getHelpLink() "
						target="_blank">
						<wcd-shared-icon class="btn-text" iconName="ReadingMode"></wcd-shared-icon>
						{{'hunting.documentation.see.full.documentation.button' | i18n}}
					</a>
				</div>
			</div>
		</ng-container>


		<ng-template #tableFields>
			<hunting-documentation-record-list
				class="wcd-padding-large-top "
				[records]="tableReference.fields"
				[header]="'hunting.documentation.table.reference.panel.fields.section.title'"
				[description]="'hunting.documentation.table.reference.panel.fields.section.description'"
				(recordNameClicked$) = "huntingDocumentationService.onFieldOrActionTypeClick($event)"
			>
			</hunting-documentation-record-list>
		</ng-template>

		<ng-template #actionTypes>
			<div class="wcd-padding-large-top">
				<hunting-documentation-record-list
					[records]="tableReference.actionTypes"
					[header]="'hunting.documentation.table.reference.panel.actionTypes.section.title'"
					[description]="'hunting.documentation.table.reference.panel.actionTypes.section.description'"
					(recordNameClicked$) = "huntingDocumentationService.onFieldOrActionTypeClick(' | where ActionType == \'' + $event + '\'')"
				>
				</hunting-documentation-record-list>
			</div>
		</ng-template>

		<ng-template #queries>
			<div class="wcd-flex-items-start-vertical" role="group">
				<dt  role="none" class="wcd-padding-large-top">
					{{'hunting.documentation.table.reference.panel.run.sample.queries.section.title' | i18n}}
				</dt>
				<ng-container *ngFor="let query of tableReference.queries | slice:0:3">
					<button data-track-id="query.id"
							data-track-type="Button"
							class="btn-link wcd-padding-none-left wcd-padding-small-bottom"
							[wcdTooltip]="query.description"
							(click)="sampleQueryClick(query.queryText)"
							[attr.aria-label]="('hunting.documentation.table.reference.panel.run.sample.queries.section.title' | i18n) + ': ' + query.name"
							>
						{{query.name}}
					</button>
				</ng-container>
			</div>
		</ng-template>
	</ng-template>
</wcd-panel>

