var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { Machine, AlertUser, LegacyUser } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { MachinesFieldsService } from '../../machines/services/machines.fields';
import { DataTableField } from '@wcd/datatable';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { LegacyUsersFieldsService } from '../../users/services/legacy-users.fields.service';
var MACHINE_FIELDS = {
    machinename: 1,
    riskScores: 1,
};
var USER_FIELDS = {
    user: 1,
};
var AlertImpactedAssetsComponent = /** @class */ (function () {
    function AlertImpactedAssetsComponent(i18nService, machineFieldsService, usersFieldsService, entityPanelsService) {
        this.i18nService = i18nService;
        this.machineFieldsService = machineFieldsService;
        this.usersFieldsService = usersFieldsService;
        this.entityPanelsService = entityPanelsService;
        this.machineFields = new Array();
        this.userFields = new Array();
    }
    AlertImpactedAssetsComponent.prototype.ngOnInit = function () {
        this.setMachineFields();
        this.setUserFields();
    };
    AlertImpactedAssetsComponent.prototype.setMachineFields = function () {
        this.machineFields = this.machineFieldsService.fields
            .filter(function (field) { return MACHINE_FIELDS[field.id]; })
            .map(function (field) { return (__assign({}, field, { useDefaultEmptyFieldComponent: true })); })
            .map(function (field) { return new DataTableField(field); });
    };
    AlertImpactedAssetsComponent.prototype.setUserFields = function () {
        this.userFields = this.usersFieldsService.fieldsWithIncident
            .filter(function (field) { return USER_FIELDS[field.id]; })
            .map(function (field) { return (__assign({}, field, { useDefaultEmptyFieldComponent: true })); })
            .map(function (field) { return new DataTableField(field); });
    };
    AlertImpactedAssetsComponent.prototype.showMachineSidePanel = function (machine) {
        this.entityPanelsService.showEntities(Machine, [machine]);
    };
    AlertImpactedAssetsComponent.prototype.showUserSidePanel = function (user) {
        this.entityPanelsService.showEntities(LegacyUser, [user]);
    };
    return AlertImpactedAssetsComponent;
}());
export { AlertImpactedAssetsComponent };
