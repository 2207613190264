var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { forkJoin, of } from 'rxjs';
import { map, take, tap, mergeMap } from 'rxjs/operators';
import { get } from 'lodash-es';
import { FabricIconNames } from '@wcd/scc-common';
import { Router } from '@angular/router';
import { FeaturesService, Feature } from '@wcd/config';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { AssetsByExposureCategoryApiCall, ExposureScoreCategory, OperatingSystemPlatform, MachineOsPlatformApiCall, AssetsByExposureCategoriesNonSupported, } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { TvmColor, TvmColorService } from '../../../services/tvm-color.service';
import { TvmMachineGroupsFilterService, } from '../../../services/tvm-machine-groups-filter.service';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { TvmOsSupportService } from '../../../services/tvm-os-support.service';
import { WidgetType } from '../../../../reports/components/report-with-filters.component';
var TvmOsPlatformsCategory;
(function (TvmOsPlatformsCategory) {
    TvmOsPlatformsCategory["Supported"] = "Supported";
    TvmOsPlatformsCategory["NotSupported"] = "NotSupported";
})(TvmOsPlatformsCategory || (TvmOsPlatformsCategory = {}));
var TvmDevicesExposureDistributionWidget = /** @class */ (function (_super) {
    __extends(TvmDevicesExposureDistributionWidget, _super);
    function TvmDevicesExposureDistributionWidget(reportsService, tvmColorsService, prettyNumberService, paris, i18nService, router, tvmTextsService, machineGroupsFilterService, featuresService, tvmOsSupportService) {
        var _a;
        var _this = _super.call(this, reportsService) || this;
        _this.tvmColorsService = tvmColorsService;
        _this.prettyNumberService = prettyNumberService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.router = router;
        _this.tvmTextsService = tvmTextsService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.featuresService = featuresService;
        _this.tvmOsSupportService = tvmOsSupportService;
        _this.pieConfig = (_a = {},
            _a[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED] = {
                color: _this.tvmColorsService.colorsMap.get(TvmColor.Neutral).raw,
                name: _this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.categories.OS_NOT_SUPPORTED'),
                legendHtml: "\n\t\t\t\t<span>" + _this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.categories.OS_NOT_SUPPORTED') + "</span>\n\t\t\t\t<a href=\"https://docs.microsoft.com/en-us/windows/security/threat-protection/microsoft-defender-atp/tvm-supported-os\"\n\t\t\t\t\tdata-track-id=\"TvmSupportedOsReadMore\"\n\t\t\t\t\tdata-track-type=\"ExternalLink\"\n\t\t\t\t\ttarget=\"_blank\">\n\t\t\t\t\t" + _this.i18nService.get('common.learnMore') + "\n\t\t\t\t</a>\n\t\t\t",
                order: 1,
                ignoreOnZero: true,
            },
            _a[AssetsByExposureCategoriesNonSupported.OTHER] = {
                color: _this.tvmColorsService.colorsMap.get(TvmColor.NeutralAlt).raw,
                name: _this.i18nService.get('common.noDataAvailable'),
                order: 2,
                ignoreOnZero: true,
            },
            _a[ExposureScoreCategory.Low] = {
                color: _this.tvmColorsService.colorsMap.get(TvmColor.LowRisk).raw,
                name: _this.i18nService.get('tvm.dims.riskLevel.members.low'),
                order: 3,
            },
            _a[ExposureScoreCategory.Medium] = {
                color: _this.tvmColorsService.colorsMap.get(TvmColor.MediumRisk).raw,
                name: _this.i18nService.get('tvm.dims.riskLevel.members.medium'),
                order: 4,
            },
            _a[ExposureScoreCategory.High] = {
                color: _this.tvmColorsService.colorsMap.get(TvmColor.HighRisk).raw,
                name: _this.i18nService.get('tvm.dims.riskLevel.members.high'),
                order: 5,
            },
            _a);
        _this.upperTitle$ = _this.data$.pipe(map(function (_) {
            if (!_this._origData)
                return '';
            return "" + _this.prettyNumberService.prettyNumber(_this.totalDevicesCount);
        }));
        _this.data_ = _this.data$.pipe(map(function (data) {
            return data.map(function (dataPoint, index) {
                var realRatio = _this.totalDevicesCount ? dataPoint.value / _this.totalDevicesCount : 0;
                var text = _this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.device.prettyValue', {
                    prettyNumber: _this.prettyNumberService.prettyNumber(dataPoint.value),
                    realRatio: Math.round(realRatio * 100),
                });
                _this.prettyNumberService.prettyNumber(dataPoint.value) + " devices (" + Math.round(realRatio * 100) + "%)";
                var ariaLabel = dataPoint.name + ": " + text;
                return __assign({}, dataPoint, { ariaLabel: ariaLabel });
            });
        }));
        /* data manipulation explanation:
            we want the donut to render a visible sector for each severity cateogry with more than 0 devices,
            but if for some cateogry(s) the device count is very small relative to the total count (cateogryDevicesCount / "totalDevicesCount" < "_thresholdPct"), the sector is virtually invisible (0 or 1 px wide).
            so in such case we provide a fakeDevicesCount to force the donut to show a small sector, and we treat the tooltips to show the original reading, not the fake.
        */
        _this._thresholdPct = 0.01;
        _this.legendItems = [
            {
                name: _this.pieConfig.Low.name,
                iconColor: _this.pieConfig.Low.color,
            },
            {
                name: _this.pieConfig.Medium.name,
                iconColor: _this.pieConfig.Medium.color,
            },
            {
                name: _this.pieConfig.High.name,
                iconColor: _this.pieConfig.High.color,
            },
        ];
        return _this;
    }
    TvmDevicesExposureDistributionWidget.prototype.setup = function (data) {
        this._origData = data; //we save the original array for the tooltips to show real data
        this.totalDevicesCount = data.reduce(function (prevVal, currVal) { return prevVal + currVal.value; }, 0);
        this._fakeDevicesCount = Math.ceil(this.totalDevicesCount * this._thresholdPct);
        this.showOsNotSupportedLegend = this._origData.some(function (entry) { return entry.id === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED; });
        this.showOtherNotSupportedLegend = this._origData.some(function (entry) { return entry.id === AssetsByExposureCategoriesNonSupported.OTHER; });
        this.secondaryLegendItems = []; //setup is being called whenever user changes machine groups, so we reset the array here
        if (this.showOsNotSupportedLegend) {
            this.secondaryLegendItems.push({
                nameHtml: this.pieConfig[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED].legendHtml,
                iconColor: this.pieConfig[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED].color,
            });
        }
        if (this.showOtherNotSupportedLegend) {
            this.secondaryLegendItems.push({
                name: this.pieConfig[AssetsByExposureCategoriesNonSupported.OTHER].name,
                iconColor: this.pieConfig[AssetsByExposureCategoriesNonSupported.OTHER].color,
                helpKey: "tvm.dashboard.devicesExposureDistributionWidget.otherNonSupportedHelp",
            });
        }
        this.setUpPieSettings();
    };
    TvmDevicesExposureDistributionWidget.prototype.setUpPieSettings = function () {
        var _this = this;
        this.pieSettings = {
            columnName: 'name',
            columnValue: 'value',
            options: {
                color: {
                    pattern: Object.keys(this.pieConfig)
                        .filter(function (k) {
                        return (!(k === AssetsByExposureCategoriesNonSupported.OTHER) ||
                            _this.showOtherNotSupportedLegend) &&
                            (!(k === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED) ||
                                _this.showOsNotSupportedLegend);
                    })
                        .map(function (k) { return _this.pieConfig[k]['color']; }),
                },
                data: {
                    order: function (dataObj1, dataObj2) {
                        var order1key = Object.keys(_this.pieConfig).find(function (key) { return _this.pieConfig[key]['name'] === dataObj1['id']; });
                        var order1 = _this.pieConfig[order1key].order;
                        var order2key = Object.keys(_this.pieConfig).find(function (key) { return _this.pieConfig[key]['name'] === dataObj2['id']; });
                        var order2 = _this.pieConfig[order2key].order;
                        return order1 - order2;
                    },
                    onclick: function (value) {
                        _this.openMachinesPage(value.id);
                    },
                },
                tooltip: {
                    contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
                        var data = d[0];
                        if (!data.value || data.value === '0')
                            return null;
                        var realRatio = this.totalDevicesCount
                            ? this._origData[data.index].value / this.totalDevicesCount
                            : 0;
                        var text = this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.device.prettyValue', {
                            prettyNumber: this._origData[data.index].value,
                            realRatio: Math.round(realRatio * 100),
                        });
                        return "<div class=\"wcd-tooltip wcd-tooltip__visible\" style=\"line-height:1.3\">\n\t\t\t\t\t\t\t\t<div><span style=\"display:inline-block;width:12px;height:12px;font-size:14px;margin-right:6px;background-color:" + color(data) + "\"></span>" + data.name + "</div>\n\t\t\t\t\t\t\t\t<div>" + text + "</div>\n\t\t\t\t\t\t\t</div>";
                    }.bind(this),
                },
                donut: {
                    title: this.i18nService.get('tvm_common_total'),
                    width: 36,
                    label: {
                        show: false,
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    width: 220,
                    height: 220,
                },
                padding: {
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                },
            },
        };
    };
    Object.defineProperty(TvmDevicesExposureDistributionWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'devicesExposureDistributionWidget',
                name: this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.title'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.dashboard.noData.topExposedMachines',
                        isGroupSelected: _this.isMgSelected,
                    });
                },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    /**
                     * Untill a new TVM flow that counts the unsupported machines will get setup, Non supported machines counts will be calculated by querying the Machine Health report data:
                     * 1. Compute the total count of TVM supported machines ("tvmSupported")
                     * 2. Using the Machine Health Report compute the total MDATP machines count ("total"), and the total machines count of machines with non-supported OS platforms ("OsNotSupported")
                     * 3. Then the total count of non-supported machines due to other reasons ("Other") is total-tvmSupported-OsNotSupported
                     */
                    return _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1), mergeMap(function (machineGroupsFilterData) {
                        _this.isMgSelected = machineGroupsFilterData.isFiltering;
                        /**
                         * We're querying the MGMT Reporting API : Machine Health Report with their ApiCall that fetches Machine count by Os Platform ("MachineOsPlatformApiCall")
                         * We prepare here the Api Call params, to exactly match their query for fetching today's data (rather than for the history data)
                         * We add TVMs machine groups as filters in case the TVM user prmopted to see only specific machine groups
                         */
                        var mgmtReportParams = __assign({}, _this.reportsService.fixDataIfNeeded(null, WidgetType.Daily), { alignToPeriod: 'true' }, (machineGroupsFilterData.isFiltering
                            ? {
                                rbacGroupIds: machineGroupsFilterData.machineGroups
                                    .filter(function (mg) { return mg.isSelected; })
                                    .map(function (mg) { return mg.groupId; }),
                            }
                            : null));
                        // For when the feature is disabled we don't want to retrieve the breakdown into platforms (no need)
                        // the feature flag is disabled also for environments which don't have the management endpoint (like FF)
                        var mgmtQuery = _this.featuresService.isEnabled(Feature.TvmUnsupportedMachinesUI) ?
                            _this.paris.apiCall(MachineOsPlatformApiCall, mgmtReportParams) :
                            of({ data: [{ values: {} }] });
                        return forkJoin([
                            //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
                            _this.paris.apiCall(AssetsByExposureCategoryApiCall, {}),
                            mgmtQuery,
                        ]).pipe(map(function (data) {
                            var _a;
                            var exposureScoreCategories = Object.keys(ExposureScoreCategory);
                            var assetsByCategoryArr = data[0].filter(function (AssetsByCategory) {
                                return exposureScoreCategories.includes(AssetsByCategory.category);
                            });
                            var machinesByOsPlatform = data[1]['data'][0]['values']; //we DO want to break if MGMT Api breaks.
                            var machinesStats = (_a = {
                                    tvmSupported: assetsByCategoryArr.reduce(function (prev, curr) { return (prev += curr.assetCount); }, 0)
                                },
                                _a[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED] = 0,
                                _a[AssetsByExposureCategoriesNonSupported.OTHER] = 0,
                                _a.total = 0,
                                _a);
                            var tvmSupportedPlatforms = _this.getTvmOsPlatforms(TvmOsPlatformsCategory.Supported);
                            machinesStats = Object.keys(machinesByOsPlatform).reduce(function (prev, osPlatformKey) {
                                var _a;
                                var osCount = machinesByOsPlatform[osPlatformKey];
                                var total = prev.total + osCount;
                                var notSupported = prev[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED];
                                if (!tvmSupportedPlatforms.some(function (nonSupportedPlatform) {
                                    return nonSupportedPlatform.category === osPlatformKey;
                                })) {
                                    notSupported = notSupported + osCount;
                                }
                                return _a = {
                                        tvmSupported: prev.tvmSupported
                                    },
                                    _a[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED] = notSupported,
                                    _a[AssetsByExposureCategoriesNonSupported.OTHER] = 0,
                                    _a.total = total,
                                    _a;
                            }, machinesStats);
                            machinesStats[AssetsByExposureCategoriesNonSupported.OTHER] =
                                machinesStats.total -
                                    machinesStats.tvmSupported -
                                    machinesStats[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED];
                            return assetsByCategoryArr.concat([
                                {
                                    category: AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED,
                                    assetCount: machinesStats[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED],
                                },
                                {
                                    category: AssetsByExposureCategoriesNonSupported.OTHER,
                                    assetCount: machinesStats[AssetsByExposureCategoriesNonSupported.OTHER],
                                },
                            ]);
                        }), map(function (assetsByCategoryArr) {
                            if (!assetsByCategoryArr ||
                                !assetsByCategoryArr.some(function (e) { return e.assetCount > 0; })) {
                                return null;
                            }
                            if (!_this.featuresService.isEnabled(Feature.TvmUnsupportedMachinesUI)) {
                                assetsByCategoryArr.find(function (entry) {
                                    return entry.category ===
                                        AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED;
                                }).assetCount = 0;
                                assetsByCategoryArr.find(function (entry) {
                                    return entry.category === AssetsByExposureCategoriesNonSupported.OTHER;
                                }).assetCount = 0;
                            }
                            return Object.keys(_this.pieConfig)
                                .map(function (key) {
                                var assetByExposureCategory = assetsByCategoryArr.find(function (entry) { return entry.category === key; });
                                if ((!assetByExposureCategory ||
                                    !assetByExposureCategory.assetCount) &&
                                    _this.pieConfig[key].ignoreOnZero)
                                    return;
                                var value = assetByExposureCategory
                                    ? assetByExposureCategory.assetCount
                                    : 0;
                                return {
                                    id: key,
                                    name: _this.pieConfig[key].name,
                                    value: value,
                                };
                            })
                                .filter(Boolean);
                        }), tap(function (data) {
                            if (data) {
                                //saving the orig data for the tooltips manipulation:
                                _this.setup(data);
                            }
                            return data;
                        }), tap(function (data) {
                            var _a, _b, _c;
                            if (data && _this.totalDevicesCount) {
                                var countProperty = 'value'; // TODO: use safe-nav op when TS is upgraded to 3.7.*...
                                var osNonSupportedCount = get(data.find(function (o) {
                                    return o.id ===
                                        AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED;
                                }), countProperty);
                                var otherNonSupportedCount = get(data.find(function (o) { return o.id === AssetsByExposureCategoriesNonSupported.OTHER; }), countProperty);
                                var lowCount = get(data.find(function (o) { return o.id === ExposureScoreCategory.Low; }), countProperty);
                                var mediumCount = get(data.find(function (o) { return o.id === ExposureScoreCategory.Medium; }), countProperty);
                                var highCount = get(data.find(function (o) { return o.id === ExposureScoreCategory.High; }), countProperty);
                                _this.accessibleChartData = __assign({}, (osNonSupportedCount && (_a = {},
                                    _a[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED] = {
                                        count: osNonSupportedCount,
                                        percent: Math.round((osNonSupportedCount / _this.totalDevicesCount) * 100),
                                    },
                                    _a)), (otherNonSupportedCount && (_b = {},
                                    _b[AssetsByExposureCategoriesNonSupported.OTHER] = {
                                        count: otherNonSupportedCount,
                                        percent: Math.round((otherNonSupportedCount / _this.totalDevicesCount) * 100),
                                    },
                                    _b)), (_c = {}, _c[ExposureScoreCategory.Low] = {
                                    count: lowCount,
                                    percent: Math.round((lowCount / _this.totalDevicesCount) * 100),
                                }, _c[ExposureScoreCategory.Medium] = {
                                    count: mediumCount,
                                    percent: Math.round((mediumCount / _this.totalDevicesCount) * 100),
                                }, _c[ExposureScoreCategory.High] = {
                                    count: highCount,
                                    percent: Math.round((highCount / _this.totalDevicesCount) * 100),
                                }, _c));
                            }
                            return data;
                        }), map(function (data) {
                            if (!data) {
                                return [];
                            }
                            return data.map(function (el) {
                                if (el.value === 0 ||
                                    _this.totalDevicesCount === 0 ||
                                    el.value / _this.totalDevicesCount > _this._thresholdPct) {
                                    return __assign({}, el);
                                }
                                //manipulating the data as described:
                                return {
                                    name: el.name,
                                    value: _this._fakeDevicesCount,
                                };
                            });
                        }));
                    }));
                },
                minHeight: '400px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmDevicesExposureDistributionWidget.prototype.getTvmOsPlatforms = function (mode) {
        var _this = this;
        return OperatingSystemPlatform.entityConfig.values.filter(function (osPlatformObj) {
            var supported = _this.tvmOsSupportService.supportedOsPlatformCategories.some(function (category) { return category === osPlatformObj.category; });
            return mode === TvmOsPlatformsCategory.Supported ? supported : !supported;
        });
    };
    TvmDevicesExposureDistributionWidget.prototype.onTitleClick = function () {
        this.openMachinesPage();
    };
    TvmDevicesExposureDistributionWidget.prototype.onAccessibilityLink = function (cat) {
        this.openMachinesPage(cat);
    };
    TvmDevicesExposureDistributionWidget.prototype.buildOsQuery = function (OperatingSystemPlatformArr) {
        return OperatingSystemPlatformArr.reduce(function (acc, osPlatformObj) {
            var osPlatformCategory = osPlatformObj.category;
            var osPlatform = osPlatformObj.id;
            var obj = acc.find(function (o) { return o.category === osPlatformCategory; });
            if (!obj) {
                obj = {
                    category: osPlatformCategory,
                    platforms: [],
                };
                acc.push(obj);
            }
            obj.platforms.push(osPlatform);
            return acc;
        }, [])
            .map(function (o) { return o.platforms.join(); })
            .join('|');
    };
    TvmDevicesExposureDistributionWidget.prototype.openMachinesPage = function (category) {
        var _this = this;
        var filtersArr = [];
        if (category) {
            var key = Object.keys(this.pieConfig).find(function (key) { return _this.pieConfig[key]['name'] === category; });
            if (key === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED) {
                var query = this.buildOsQuery(this.getTvmOsPlatforms(TvmOsPlatformsCategory.NotSupported));
                if (query) {
                    var filter = "osPlatforms=" + encodeURIComponent(query);
                    filtersArr.push(filter);
                }
            }
            else if (key === AssetsByExposureCategoriesNonSupported.OTHER) {
                var query = this.buildOsQuery(this.getTvmOsPlatforms(TvmOsPlatformsCategory.Supported));
                if (query) {
                    var filter = "osPlatforms=" + encodeURIComponent(query) + ",exposureScores=None";
                    filtersArr.push(filter);
                }
            }
            else {
                var filter = "exposureScores=" + category;
                filtersArr.push(filter);
            }
        }
        this.machineGroupsFilterService.machineGroupsFilter$
            .pipe(take(1))
            .subscribe(function (machineGroupsFilterData) {
            if (machineGroupsFilterData && machineGroupsFilterData.isFiltering) {
                var groupIds = machineGroupsFilterData.machineGroups
                    .filter(function (mg) { return mg.isSelected; })
                    .map(function (mg) { return mg.groupId; })
                    .join('|');
                filtersArr.push("rbacGroupIds=" + groupIds);
            }
            var filters = filtersArr.reduce(function (prev, curr, idx) { return "" + prev + (prev ? ',' : '') + curr; }, '');
            _this.router.navigate(['/machines'], {
                queryParams: { filters: filters || null },
            });
        });
    };
    return TvmDevicesExposureDistributionWidget;
}(ReportWidgetComponent));
export { TvmDevicesExposureDistributionWidget };
