import { SoftwareChangeEventsRelationship } from './../../../../../../../../../packages/@wcd/domain/src/tvm/software/software.relationships';
import { Component, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, ViewChild } from '@angular/core';
import { TabModel, TabModelConfig } from '../../../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../../../global_entities/components/entity-contents.component.base';
import {
	Software,
	SoftwareSecurityRecommendationRelationship,
	SoftwareVersionRelationship,
	SoftwareMissingKbsRelationship,
	SoftwareInstallationAggRelationship,
	TvmSupportedOsPlatform,
} from '@wcd/domain';
import { ReportModel } from '../../../../../reports/models/report.model';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { take, filter, map } from 'rxjs/operators';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ActivatedRoute, Router } from '@angular/router';
import { FeaturesService, Feature } from '@wcd/config';
import { SoftwareEntityReport } from './software-entity.report';
import { I18nService } from '@wcd/i18n';
import { TabsComponent } from '../../../../../shared/components/tabs/tabs.component';

export interface RelationshipCountProvider {
	itemCount$: Observable<number>;
	relationshipType: any;
}

export enum SOFTWARE_CATEGORY {
	NetworkGear = 'NetworkGear',
}

@Component({
	selector: 'software-entity',
	templateUrl: './software.entity.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SoftwareEntityComponent extends EntityComponentBase<Software> implements OnDestroy {
	readonly SpinnerSize = SpinnerSize;

	private _subscription: Subscription;
	private _countSubscription: Subscription;
	private _tabNameSubscription: Subscription;
	private _routeToTabIdMap: Record<string, string> = {
		'event-insights': 'changeEvents',
	};

	softwareEntityReport: ReportModel;
	tabs: Array<TabModel> = [];

	@ViewChild(TabsComponent, { static: false }) private tabsComponent: TabsComponent;

	private readonly countData = {
		securityRecommendations: null,
		vulnerabilities: null,
		installations: null,
		versions: null,
		missingKbs: null,
		changeEvents: null,
	};

	private isMissingKbsEnabled: boolean;

	/*
	TODO: We're hiding the total weakness count feature for now. uncomment when required.
	@ViewChild(ReportComponent) report: ReportComponent;
	totalWeaknessesCount$: Observable<number>; <see comment below>
	*/

	constructor(
		private activatedRoute: ActivatedRoute,
		private paris: Paris,
		private activatedEntity: ActivatedEntity,
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		private i18nService: I18nService,
		featuresService: FeaturesService
	) {
		super();
		this.isMissingKbsEnabled = featuresService.isEnabled(Feature.TvmMissingKbs);

		this._tabNameSubscription = this.activatedEntity.currentEntity$.subscribe((software: Software) => {
			if (!software) return;
			this.tabs = this.getTabs(software);
		});
		this.softwareEntityReport = SoftwareEntityReport();
	}

	onActivate(currentCountProvider: RelationshipCountProvider) {
		/*
		Select the right tab, when navigating
		*/
		if (this.tabsComponent && this.tabs) {
			const id = this.router.url
				.split('?')[0]
				.split('/')
				.pop();
			this.tabsComponent.selectTab(
				this.tabs.find(x => x.id.toLowerCase() === id || x.id === this._routeToTabIdMap[id])
			);
		}
		if (this._countSubscription) this._countSubscription.unsubscribe();

		if (currentCountProvider.itemCount$) {
			this._countSubscription = currentCountProvider.itemCount$
				.pipe(filter(count => count !== null))
				.subscribe(itemCount => {
					this.fetchCountData(currentCountProvider.relationshipType, itemCount);
				});
		}
	}

	private fetchCountData(relationship: any, itemCount: number) {
		if (this._subscription) {
			return; // Already fetched
		}

		this._subscription = this.activatedEntity.currentEntity$.subscribe((software: Software) => {
			if (!software) return;

			this.countData.vulnerabilities = software.discoveredVulnerabilities;
			const supportMissingKbs = software.isKbProduct && this.isMissingKbsEnabled;

			const countObservablesArray = [
				SoftwareSecurityRecommendationRelationship,
				SoftwareVersionRelationship,
				SoftwareInstallationAggRelationship,
			];
			if (supportMissingKbs) {
				countObservablesArray.push(SoftwareMissingKbsRelationship);
			}

			countObservablesArray.push(SoftwareChangeEventsRelationship);

			const countObservables = countObservablesArray.map(relationshipClass => {
				if (relationshipClass === relationship) {
					return of(itemCount);
				}

				const repo = this.paris.getRelationshipRepository(relationshipClass);
				repo.sourceItem = software;

				const countObservable = repo
					.query({
						page: 1,
						pageSize: 30,
					})
					.pipe(
						take(1),
						map(data => data.count)
					);

				return countObservable;
			});

			forkJoin(countObservables).subscribe(data => {
				this.countData.securityRecommendations = data[0];
				this.countData.versions = data[1];
				this.countData.installations = data[2];
				this.countData.missingKbs = data[3];
				this.countData.changeEvents = data[supportMissingKbs ? 4 : 3];
				this.tabs = this.getTabs(software);
				this.changeDetectorRef.markForCheck();
			});
		});
	}

	private getTabs(software: Software): Array<TabModel> {
		const tabsArray: TabModelConfig[] = [
			{
				id: 'overview',
				name: this.i18nService.get('tvm.common.overview'),
				routerLink: './overview',
				disabled: software.productNeverMatched,
				shouldForceActive: () => {
					return (
						this.router.url
							.split('?')[0]
							.split('/')
							.pop() === 'overview'
					);
				},
			},
			{
				id: 'recommendations',
				name: this.i18nService.get('tvm.common.securityRecommendations'),
				value: this.countData.securityRecommendations,
				routerLink: './recommendations',
				routerLinkQueryParams: { filters: 'status=Active' },
				disabled: software.productNeverMatched && this.countData.securityRecommendations === 0,
			},
			{
				id: 'vulnerabilities',
				name: this.i18nService.get('tvm.common.discoveredVulnerabilities'),
				value: this.countData.vulnerabilities,
				routerLink: './vulnerabilities',
				disabled: software.productNeverMatched,
			},
			{
				id: 'installations',
				name:
					software.category === SOFTWARE_CATEGORY.NetworkGear
						? this.i18nService.get('tvm.common.installedNetworkDevices')
						: this.i18nService.get('tvm.common.installedDevices'),
				value: this.countData.installations,
				routerLink: './installations',
			},
			{
				id: 'versions',
				name: this.i18nService.get('tvm.common.versionDistribution'),
				value: this.countData.versions,
				routerLink: './versions',
			},
			{
				id: 'missingKbs',
				name:
					software.osPlatform === TvmSupportedOsPlatform.Linux
						? this.i18nService.strings.tvm_common_securityBulletins
						: this.i18nService.strings.tvm_common_missingKbs,
				value: this.countData.missingKbs,
				routerLink: './missingkbs',
				disabled: !(software.isKbProduct && this.isMissingKbsEnabled),
			},
			{
				id: 'changeEvents',
				name: this.i18nService.strings.tvm_common_eventTimeline,
				value: this.countData.changeEvents,
				routerLink: './event-insights',
				disabled: software.productNeverMatched,
			},
		];

		return tabsArray.filter(tab => !tab.disabled).map(tab => new TabModel(tab));
	}

	/*
	TODO: We're hiding the total weakness count feature for now. uncomment when required.
	ngAfterViewInit() {

		const observables = [];

		this.report.allWidgets
			.filter(
				widget =>
					widget instanceof MisconfigurationsDistributionWidget ||
					widget instanceof VulnerabilitiesDistributionWidget
			)
			.map(widget => observables.push(widget.data$));

		this.totalWeaknessesCount$ = zip(...observables).pipe(
			map((values: SeverityDistributionMap[]) =>
				values.reduce((prevCount, severityDistributionMap) => {
					if (!severityDistributionMap) return null;
					const currCount = Object.keys(severityDistributionMap).reduce(
						(prev, curr) => (prev += severityDistributionMap[curr]),
						0
					);
					return prevCount + currCount;
				}, 0)
			)
		);
	}
	*/

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
		this._countSubscription && this._countSubscription.unsubscribe();
		this._tabNameSubscription && this._tabNameSubscription.unsubscribe();
	}
}
