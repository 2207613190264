/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./machine-event-mark.component";
import * as i7 from "../../cyber_events/services/cyber-events-utils.service";
import * as i8 from "../../../dialogs/services/dialogs.service";
import * as i9 from "../../../insights/services/app-insights.service";
var styles_MachineEventMarkComponent = [];
var RenderType_MachineEventMarkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineEventMarkComponent, data: {} });
export { RenderType_MachineEventMarkComponent as RenderType_MachineEventMarkComponent };
function View_MachineEventMarkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "fab-icon-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onFlagClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabIconButtonComponent_0, i1.RenderType_FabIconButtonComponent)), i0.ɵdid(1, 6012928, null, 1, i2.FabIconButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { styles: [0, "styles"], ariaLabel: [1, "ariaLabel"], tabIndex: [2, "tabIndex"], menuIconProps: [3, "menuIconProps"] }, null), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), i0.ɵppd(3, 1), i0.ɵpod(4, { iconName: 0 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getFlagButtonStyle(); var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "events.markedEvents.filterText")); var currVal_2 = (0 - 1); var currVal_3 = _ck(_v, 4, 0, "Flag"); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_MachineEventMarkComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineEventMarkComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSupportedActionType; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MachineEventMarkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "event-mark", [], null, null, null, View_MachineEventMarkComponent_0, RenderType_MachineEventMarkComponent)), i0.ɵdid(1, 114688, null, 0, i6.MachineEventMarkComponent, [i7.CyberEventsUtilsService, i0.ChangeDetectorRef, i8.DialogsService, i4.I18nService, i9.AppInsightsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MachineEventMarkComponentNgFactory = i0.ɵccf("event-mark", i6.MachineEventMarkComponent, View_MachineEventMarkComponent_Host_0, { cyberEvent: "cyberEvent" }, {}, []);
export { MachineEventMarkComponentNgFactory as MachineEventMarkComponentNgFactory };
