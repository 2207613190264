var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { AlertsSeveritySummary } from '../../alert/alerts-severity-summary.value-object';
import { Ip } from '../ip.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.activeAlertsSummary; }, ɵ1 = function (ip) { return ({
    Ip: ip.id,
}); }, ɵ2 = function (dataQuery) {
    return Object.assign({ lookingBackInDays: 180 }, dataQuery && dataQuery.where);
};
var IpActiveAlertsSummaryRelationship = /** @class */ (function () {
    function IpActiveAlertsSummaryRelationship() {
    }
    IpActiveAlertsSummaryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Ip,
            dataEntity: AlertsSeveritySummary,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            endpoint: 'activeAlertsSummary',
            baseUrl: ɵ0,
            getRelationshipData: ɵ1,
            parseDataQuery: ɵ2,
        })
    ], IpActiveAlertsSummaryRelationship);
    return IpActiveAlertsSummaryRelationship;
}());
export { IpActiveAlertsSummaryRelationship };
export { ɵ0, ɵ1, ɵ2 };
