var MAX_OFFICE_ID_LENGTH = 6;
var ID_KEY = '$id';
var REF_KEY = '$ref';
var OfficeUtils = /** @class */ (function () {
    function OfficeUtils() {
    }
    OfficeUtils.getShortId = function (id) {
        if (typeof id === 'string') {
            if (isNaN(Number(id)) && id.length > MAX_OFFICE_ID_LENGTH) {
                return id.substr(id.length - MAX_OFFICE_ID_LENGTH);
            }
        }
        return id;
    };
    OfficeUtils.createIds = function (obj) {
        var _this = this;
        if (obj == null || typeof obj !== 'object') {
            return {};
        }
        if (Array.isArray(obj)) {
            return obj.reduce(function (acc, nested) {
                return Object.assign({}, acc, _this.createIds(nested));
            }, {});
        }
        return Object.entries(obj).reduce(function (acc, _a) {
            var k = _a[0], v = _a[1];
            if (k === ID_KEY) {
                acc[v] = obj;
            }
            else if (typeof v === 'object') {
                Object.assign(acc, _this.createIds(v));
            }
            return acc;
        }, {});
    };
    OfficeUtils.replaceRefs = function (refs, obj, replacements) {
        var _this = this;
        if (obj == null || typeof obj !== 'object') {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map(function (nested, idx) {
                var repl = _this.replaceRefs(refs, nested, replacements);
                replacements.push({ k: idx, obj: obj, val: repl });
                return repl;
            });
        }
        return Object.entries(obj).reduce(function (acc, _a) {
            var k = _a[0], v = _a[1];
            if (k === REF_KEY) {
                return refs[v];
            }
            acc[k] = _this.replaceRefs(refs, v, replacements);
            return acc;
        }, {});
    };
    OfficeUtils.convertDataFromAlertV3 = function (rawData) {
        var _this = this;
        var replacements = [];
        var ids = this.createIds(rawData);
        // replace reference to objects inside the ids array
        Object.entries(ids).forEach(function (_a) {
            var k = _a[0], v = _a[1];
            ids[k] = _this.replaceRefs(ids, v, replacements);
        });
        // replace reference in the original rawData object
        rawData = this.replaceRefs(ids, rawData, replacements);
        replacements.forEach(function (r) {
            r.obj[r.k] = r.val;
        });
        return rawData;
    };
    return OfficeUtils;
}());
export { OfficeUtils };
