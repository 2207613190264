var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { of } from 'rxjs';
import { ApplicationInsights, SeverityLevel, DistributedTracingModes, } from '@microsoft/applicationinsights-web';
import { TitleService } from '../../shared/services/title.service';
import { AppConfigService } from '@wcd/app-config';
import { ErrorsService } from '../../shared/services/errors.service';
import { TelemetryService } from '@wcd/telemetry';
import { TrackingEventType } from '../models/tracking-event-type.enum';
import { AppContextService, FeaturesService } from '@wcd/config';
import { isObject, isString } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/auth/src/lib/services/auth.service";
import * as i2 from "../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i3 from "../../shared/services/title.service";
import * as i4 from "../../../../../../projects/telemetry/src/lib/telemetry.service";
import * as i5 from "../../../../../../projects/config/src/lib/services/app-context.service";
import * as i6 from "../../../../../../projects/config/src/lib/services/features.service";
var ENVELOPE_PRIVATE_DATA_FIELDS = [
    'name',
    'data',
    'url',
    'page',
    'PageUrl',
    'refUri',
    'uri',
]; // fields in appInsights tracking data that may contain PII and should be cleaned before sent.
var ENVELOPE_EXT_TRACE_PRIVATE_DATA_FIELDS = ['name', 'url']; // inner appInsights fields that are sent with the tracking data. please notice that "operation" field is the original browser url when app loaded and is not changed during app navigating.
var appInsightsRef;
var appInsightsOn = false;
/**
 * API for AppInsights
 * Full documentation at: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
 */
var AppInsightsService = /** @class */ (function () {
    function AppInsightsService(authService, appConfigService, titleService, telemetryService, appContextService, featuresService) {
        this.authService = authService;
        this.appConfigService = appConfigService;
        this.titleService = titleService;
        this.telemetryService = telemetryService;
        this.appContextService = appContextService;
        this.featuresService = featuresService;
        this._firstLoadFired = false;
        this.sccInsights = sccHostService.isSCC;
        // Maintain reference of binded function to later clear by reference
        this.telemetryInitializers = [
            this.addDetailsToTracking.bind(this),
            this.cleanEnvelopeFromPrivateData.bind(this),
        ];
    }
    Object.defineProperty(AppInsightsService.prototype, "appReadyListener$", {
        set: function (value) {
            var _this = this;
            if (!this._firstLoadFired && !this._appReady$) {
                this._appReady$ = value;
                this._appReadySubscription = this._appReady$.subscribe(function (time) {
                    return _this.trackAppReadyEvent(null, { time: time });
                });
            }
        },
        enumerable: true,
        configurable: true
    });
    AppInsightsService.prototype.init = function () {
        var _this = this;
        if (this.sccInsights) {
            this.appInsights = true;
            this.initSubscription();
            return of(true);
        }
        if (!this.appInsights &&
            this.appConfigService.useAppInsights &&
            this.appConfigService.appInsightsInstrumentationKey) {
            appInsightsOn = true;
            var config = {
                instrumentationKey: this.appConfigService.appInsightsInstrumentationKey,
                endpointUrl: this.appConfigService.appInsightsEndpointUrl || undefined,
                accountId: this.appConfigService.tenantId,
                disableTelemetry: false,
                disableExceptionTracking: false,
                disableAjaxTracking: false,
                disableFetchTracking: false,
                autoTrackPageVisitTime: true,
                enableAutoRouteTracking: true,
                distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
                namePrefix: this.appContextService.getCurrentAppPrefix(),
            };
            if (appInsightsRef) {
                this.appInsights = appInsightsRef;
            }
            else {
                var init = new ApplicationInsights({ config: config });
                this.appInsights = appInsightsRef = init.loadAppInsights();
                this.appInsights.addTelemetryInitializer(function () {
                    // Ignore calls when not in MDATP pages
                    return appInsightsOn;
                });
                if (this.authService.currentUser) {
                    this.appInsights.setAuthenticatedUserContext(this.authService.currentUser.aadUserId);
                    this.trackEvent('UI Auth', {
                        buildVersion: this.appConfigService.buildVersion,
                        dataCenter: this.appConfigService.dataCenter,
                        isAdmin: this.authService.currentUser.isMdeAdmin,
                        isReadonly: this.authService.currentUser.isReadonly,
                        tenantId: this.appConfigService.tenantId,
                        session: this.authService.sessionId,
                        userId: this.authService.currentUser && this.authService.currentUser.aadUserId,
                    }, { count: 1 });
                }
            }
            this.telemetryInitializers.forEach(function (initializer) {
                return _this.appInsights.addTelemetryInitializer(initializer);
            });
            this.initSubscription();
        }
        return of(true);
    };
    AppInsightsService.prototype.initSubscription = function () {
        var _this = this;
        var trackingEventTypeToAiType = {
            Action: TrackingEventType.Action,
            Button: TrackingEventType.Button,
            Close: TrackingEventType.Close,
            Export: TrackingEventType.Export,
            ExternalLink: TrackingEventType.ExternalLink,
            Filter: TrackingEventType.Filter,
            Navigation: TrackingEventType.Navigation,
            Save: TrackingEventType.Saving,
            Search: TrackingEventType.Search,
            Selection: TrackingEventType.Selection,
            Toggle: TrackingEventType.Toggle,
        };
        this._telemetryEventsSubscription = this.telemetryService.events$.subscribe(function (event) {
            _this.track({
                component: event.component,
                componentType: event.componentType,
                id: event.id,
                type: event.type && trackingEventTypeToAiType[event.type],
                value: event.payload,
            }, event.measurements);
        });
        var telemetrySeverityToAiSeverity = {
            Verbose: SeverityLevel.Verbose,
            Information: SeverityLevel.Information,
            Warning: SeverityLevel.Warning,
            Error: SeverityLevel.Error,
            Critical: SeverityLevel.Critical,
        };
        this._telemetryErrorsSubscription = this.telemetryService.errors$.subscribe(function (error) {
            _this.trackException(error.error, error.handledAt, { package: error.package }, null, error.severity && telemetrySeverityToAiSeverity[error.severity]);
        });
    };
    AppInsightsService.prototype.trackEvent = function (name, properties, measurements) {
        if (this.appConfigService.useAppInsights && this.appInsights) {
            if (this.sccInsights) {
                sccHostService.log.trackEvent(name, __assign({}, this.getDefaultTrackingProperties(), this.parseProperties(properties)));
            }
            else {
                this.appInsights.trackEvent({
                    name: name,
                    properties: __assign({}, this.getDefaultTrackingProperties(), this.parseProperties(properties)),
                    measurements: measurements,
                });
            }
        }
    };
    AppInsightsService.prototype.track = function (properties, measurements) {
        this.trackEvent('UI', properties, measurements);
    };
    AppInsightsService.prototype.trackPageView = function (name, uri, properties, measurements, duration) {
        if (this.sccInsights) {
            return;
        }
        if (this.appConfigService.useAppInsights && this.appInsights) {
            this.appInsights.trackPageView({
                name: name || this.titleService.state$.getValue().pageTitle,
                uri: uri,
                properties: __assign({}, properties, { duration: duration }),
                measurements: measurements,
            });
        }
    };
    AppInsightsService.prototype.trackException = function (exception, handledAt, properties, measurements, severityLevel) {
        if (this.appConfigService.useAppInsights && this.appInsights) {
            var exceptionStr = void 0;
            try {
                exceptionStr = JSON.stringify(exception);
            }
            catch (err) {
                exceptionStr = ErrorsService.getErrorMessage(exception);
            }
            properties = Object.assign(this.getDefaultTrackingProperties(), properties, {
                error: exceptionStr,
                severityLevel: severityLevel
            });
            if (this.sccInsights) {
                sccHostService.log.trackException(exception, __assign({}, this.parseProperties(properties), { handledAt: handledAt }));
            }
            else {
                this.appInsights.trackException({
                    exception: exception,
                    properties: __assign({}, this.parseProperties(properties), { handledAt: handledAt }),
                    measurements: measurements,
                    severityLevel: severityLevel,
                });
            }
        }
    };
    // this is our benchmark for app load (finished static files load, preload, data load).
    // it will be fired only once, from the first page that loaded fully and registered appReadyListener$
    AppInsightsService.prototype.trackAppReadyEvent = function (properties, measurements) {
        if (this.sccInsights) {
            return;
        }
        if (this._firstLoadFired)
            return;
        else {
            this.trackEvent('UI App Ready', properties, measurements);
            this._firstLoadFired = true;
            this._appReadySubscription && this._appReadySubscription.unsubscribe();
        }
    };
    AppInsightsService.prototype.parseProperties = function (properties) {
        var parsedProperties = {};
        if (properties) {
            for (var p in properties) {
                if (properties.hasOwnProperty(p)) {
                    var value = properties[p];
                    parsedProperties[p] = typeof value === 'object' ? JSON.stringify(value) : String(value);
                }
            }
        }
        return parsedProperties;
    };
    AppInsightsService.prototype.cleanEnvelopeFromPrivateData = function (envelope) {
        if (envelope.data) {
            envelope.data = this.removePiiDataFromObject(envelope.data, ENVELOPE_PRIVATE_DATA_FIELDS);
        }
        if (envelope.baseData) {
            envelope.baseData = this.removePiiDataFromObject(envelope.baseData, ENVELOPE_PRIVATE_DATA_FIELDS);
            if (envelope.baseData.properties) {
                envelope.baseData.properties = this.removePiiDataFromObject(envelope.baseData.properties, ENVELOPE_PRIVATE_DATA_FIELDS);
            }
        }
        if (envelope.ext && envelope.ext.trace) {
            envelope.ext.trace = this.removePiiDataFromObject(envelope.ext.trace, ENVELOPE_EXT_TRACE_PRIVATE_DATA_FIELDS);
        }
    };
    AppInsightsService.prototype.removePiiDataFromObject = function (data, fieldNames) {
        var _this = this;
        Object.keys(data).forEach(function (prop) {
            if (isObject(data[prop])) {
                return (data[prop] = _this.removePiiDataFromObject(data[prop], fieldNames));
            }
            if (isString(data[prop]) && fieldNames.includes(prop)) {
                data[prop] = data[prop]
                    .replace(/(.*)(\/user[s]?)(\/.*)?(\/.*)(\/)?/gi, function (src, prefix, user, isId, suffix) {
                    return _this.piiReplace('user', isId, prefix, user, suffix);
                })
                    .replace(/(.*)(\/file[s:]?)(\/.*)?(\/.*)(\/)?/gi, function (src, prefix, file, isId, suffix) {
                    return _this.piiReplace('file', isId, prefix, file, suffix);
                })
                    .replace(/(.*)(\/[_]?machine[s]?)(\/.*)?(\/.*)(\/)?/gi, function (src, prefix, machine, isId, suffix) {
                    return _this.piiReplace('machine', isId, prefix, machine, suffix);
                })
                    .replace(/(.*)(\/ip[s]?)(\/.*)?(\/.*)(\/)?/gi, function (src, prefix, user, isId, suffix) {
                    return _this.piiReplace('ip', isId, prefix, user, suffix);
                })
                    .replace(/(.*)(\/mailbox(es)?)(\/.*)?(\/.*)(\/)?/gi, function (src, prefix, mailbox, isId, suffix) {
                    return _this.piiReplace('mailbox', isId, prefix, mailbox, suffix);
                })
                    .replace(/(computerDnsName=)([^&]+)/gi, '$1_machine_pii_removed')
                    .replace(/(userAccount=|AccountName=|AccountDomainName=|AccountDomain=|userAccountDomain=)([^&]+)/gi, '$1_user_pii_removed');
            }
        });
        return data;
    };
    // piiReplace examples:
    //  http://stg.localhost:4200/machines/macine-name-to-remove/overview => http://stg.localhost:4200/machines/macine-_pii_removed/overview
    AppInsightsService.prototype.piiReplace = function (entityName, isId, prefix, entity, suffix) {
        suffix = (suffix || '').replace(/(.*)(\?)(.*)/gi, '$1'); // Remove all text after '?' in case the suffix contains PII.
        return isId ?
            "" + prefix + entity + "/_" + entityName + "_pii_removed" + suffix :
            "" + prefix + entity + "/_" + entityName + "_pii_removed";
    };
    AppInsightsService.prototype.addDetailsToTracking = function (envelope) {
        var item = envelope.baseData;
        item.properties = Object.assign(this.getDefaultTrackingProperties(), item.properties);
        if (envelope.baseType === 'RemoteDependencyData') {
            // set custom properties:
            item.properties['duration'] = item['duration'];
            item.properties['name'] = item['name'];
            // set custom metrics:
            item.measurements = item.measurements || {};
            item.measurements['success'] = +item['success'];
            item.measurements['duration'] = item['duration'];
        }
    };
    AppInsightsService.prototype.getDefaultTrackingProperties = function () {
        var page = this.titleService.state$.getValue().pageTitle;
        if (!page) {
            var pathParts = window.location.pathname.split('/');
            page = (pathParts && pathParts[1]) || '/';
        }
        var data = {
            buildVersion: this.appConfigService.buildVersion,
            dataCenter: this.appConfigService.dataCenter,
            origin: window.location.origin,
            url: window.location.href,
            page: page,
            session: this.authService.sessionId,
            tenantId: this.appConfigService.tenantId,
            userId: this.authService.currentUser && this.authService.currentUser.aadUserId,
            pckVersion: sccHostService.isSCC
                ? sccHostService.resource.version('wdatp')
                : window.config.buildVersion,
        };
        if (this.sccInsights) {
            //ts-ignore
            this.cleanEnvelopeFromPrivateData({ data: data });
        }
        return data;
    };
    AppInsightsService.prototype.ngOnDestroy = function () {
        var _this = this;
        this._telemetryEventsSubscription && this._telemetryEventsSubscription.unsubscribe();
        this._telemetryErrorsSubscription && this._telemetryErrorsSubscription.unsubscribe();
        if (this.sccInsights) {
            return;
        }
        this._appReadySubscription && this._appReadySubscription.unsubscribe();
        if (!this.appInsights)
            return;
        this.appInsights.flush(false);
        this.appInsights.appInsights._telemetryInitializers = this
            .appInsights.appInsights._telemetryInitializers.filter(function (initializer) { return !_this.telemetryInitializers.includes(initializer); });
        appInsightsOn = false;
    };
    AppInsightsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppInsightsService_Factory() { return new AppInsightsService(i0.ɵɵinject(i1.AuthService), i0.ɵɵinject(i2.AppConfigService), i0.ɵɵinject(i3.TitleService), i0.ɵɵinject(i4.TelemetryService), i0.ɵɵinject(i5.AppContextService), i0.ɵɵinject(i6.FeaturesService)); }, token: AppInsightsService, providedIn: "root" });
    return AppInsightsService;
}());
export { AppInsightsService };
