<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
	<div class="wcd-full-height wcd-flex-vertical">
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-large-left wcd-padding-xxLarge-right">
			<div class="wcd-font-weight-bold wcd-font-size-xl wcd-margin-xsmall-bottom">
				{{ 'incident.overview.mte.advanced.query.title' | i18n }}
			</div>
			<div class="wcd-margin-medium-top wcd-margin-large-bottom wcd-font-size-base">
				{{ 'incident.overview.mte.advanced.query.description' | i18n }}
			</div>
			<ng-container *ngIf="incident">
				<div class="query wcd-flex-horizontal wcd-margin-bottom wcd-padding-mediumSmall-bottom" *ngFor="let item of incident.recommendedAHQs">
					<div class="wcd-flex-1 query-inner" [innerHTML]="sanitize(item)"> </div>
					<div class="wcd-flex-0">
						<copy-to-clipboard [content]="item" [settings]="{ copyText: '' }">
						</copy-to-clipboard>
					</div>
				</div>
			</ng-container>
		</div>
	</div>
</wcd-panel>