/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "./baseline-profile.header-bottom.component";
import * as i4 from "../../services/global-entity-types.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../tvm/services/tvm-color.service";
var styles_BaselineProfileHeaderBottomComponent = [];
var RenderType_BaselineProfileHeaderBottomComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BaselineProfileHeaderBottomComponent, data: {} });
export { RenderType_BaselineProfileHeaderBottomComponent as RenderType_BaselineProfileHeaderBottomComponent };
export function View_BaselineProfileHeaderBottomComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "fab-icon", [["iconName", "StatusCircleInner"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(4, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(5, null, ["\n\t\t\t", "\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "wcd-vertical-align-bottom ", _co.iconClass, ""); var currVal_1 = "StatusCircleInner"; _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.display; _ck(_v, 5, 0, currVal_2); }); }
export function View_BaselineProfileHeaderBottomComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_BaselineProfileHeaderBottomComponent_0, RenderType_BaselineProfileHeaderBottomComponent)), i0.ɵdid(1, 114688, null, 0, i3.BaselineProfileHeaderBottomComponent, [i4.GlobalEntityTypesService, i5.I18nService, i6.TvmColorService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaselineProfileHeaderBottomComponentNgFactory = i0.ɵccf("ng-component", i3.BaselineProfileHeaderBottomComponent, View_BaselineProfileHeaderBottomComponent_Host_0, { entity: "entity", entityType: "entityType" }, {}, []);
export { BaselineProfileHeaderBottomComponentNgFactory as BaselineProfileHeaderBottomComponentNgFactory };
