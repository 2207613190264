import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
declare const moment: typeof import('moment');

@Component({
	templateUrl: './extended-fab-date-picker.component.html',
	selector: 'extended-fab-date-picker',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExtendedFabDatePickerComponent {
	constructor(
		private readonly localeConfigService: LocaleConfigService,
		private tzDateService: TzDateService
		) {
			this.formatDate = this.formatDate.bind(this);
			this.emitDate = this.emitDate.bind(this);
		}
	@Input() ariaLabel: string;

	@Input() disabled = false;

	@Input() // Assuming minDate is in localTimezone
	get minDate(): Date { return this._minDate; }
	set minDate(minDate: Date) {
	  this._minDate = this.calculateDateInRightTimeZone(minDate);
	}
	private _minDate: Date = null;

	@Input() // Assuming maxDate is in localTimezone
	get maxDate(): Date { return this._maxDate; }
	set maxDate(maxDate: Date) {
	  this._maxDate = this.calculateDateInRightTimeZone(maxDate);
	}
	private _maxDate: Date = null;

	@Input() placeHolder : string;

	@Input() isMonthPickerVisible = false;

	@Input() format: (Date?) => string;

	@Output() onSelectDate = new EventEmitter<Date>();

	@Input() disableAutoFocus: boolean = true;

	translateDateToCorrectTimezone(date) {
		// fab-date-picker returns 00:00 of the chosen date in local time
		// When user runs with "UTC timezone", we should return 00:00 in UTC time
		// For example:
		// If user runs in UTC and selects 20/07/15 - datepicker returns 20/07/15 00:00 in Israeli time, which is the same as 20/07/14 21:00 in UTC.
		// This function will return 20/07/15 00:00 in UTC which is the same as 20/07/15 03:00 in Israeli time
		// If user runs in Israeli time and selects 20/07/15 - datepicker returns 20/07/15 00:00 in Israeli time, which is the same as 20/07/14 21:00 in UTC.
		// This function will return 20/07/14 21:00 in UTC which is the same as 20/07/15 00:00 in Israeli time
		return this.localeConfigService.isLocalTimeZone? date : this.convertLocalToUTCDate(date);
	}

	emitDate(event) {
		this.onSelectDate.emit(this.translateDateToCorrectTimezone(event.date));
	}

	formatDate(date?: Date): string {
		if (!date){
			return '';
		}
		const convertedDate = this.translateDateToCorrectTimezone(date);
		return this.format ? this.format(convertedDate) : this.tzDateService.format(convertedDate, 'shortDate');
	}

	private calculateDateInRightTimeZone(date: Date) : Date {
		// fab-date-picker is always in localTimezone.
		// Therefore, when we get min/maxDate, we should convert it to UTC, if user chooses to use UTC in his preferences.
		return this.localeConfigService.isLocalTimeZone ? date : moment([
			date.getUTCFullYear(),
			date.getUTCMonth(),
			date.getUTCDate(),
			date.getUTCHours(),
			date.getUTCMinutes(),
			date.getUTCSeconds(),
		]).toDate();
	}

	private convertLocalToUTCDate(date: Date): Date {
		return moment.utc([
			date.getFullYear(),
			date.getMonth(),
			date.getDate(),
			0,
			0,
			0,
		]).toDate();
	}
}
