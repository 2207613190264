
		<ng-container *ngIf="context">
			<span>{{ context.value }}</span>
			<a
				[href]="context.link"
				[attr.aria-label]="context.title"
				target="_blank"
				class="wcd-margin-xsmall-left"
				data-track-id="OpenLink"
				data-track-type="Navigation"
			>
				<wcd-shared-icon
					[iconName]="context.icon"
					[verticalAlign]="context.verticalAlign"
				></wcd-shared-icon>
			</a>
		</ng-container>
	