var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { Paris } from '@microsoft/paris';
import { BilbaoRegistrationApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { Feature, FeaturesService } from '@wcd/config';
var BilbaoRegistrationComponent = /** @class */ (function (_super) {
    __extends(BilbaoRegistrationComponent, _super);
    function BilbaoRegistrationComponent(router, authService, paris, i18nService, dialogsService, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.featuresService = featuresService;
        _this.registration = {
            email: '',
            name: '',
            tenantId: '',
            useProvisioningStore: false
        };
        _this.isSaving = false;
        _this.registration.email = authService.currentUser.username;
        _this.registration.tenantId = authService.tenantId;
        _this.registration.useProvisioningStore = _this.featuresService.isEnabled(Feature.EnableMteClassicPSFlow);
        return _this;
    }
    BilbaoRegistrationComponent.prototype.submit = function () {
        var _this = this;
        this.isSaving = true;
        this.paris
            .apiCall(BilbaoRegistrationApiCall, this.registration)
            .pipe(finalize(function () {
            _this.isSaving = false;
        }))
            .subscribe(function () {
            _this.closeModal();
            _this.dialogsService.confirm({
                title: '',
                text: _this.i18nService.get('settings.advancedFeatures.features.managedThreatHuntingRegistration.confirm.text'),
                showConfirm: false,
                cancelText: _this.i18nService.get('settings.advancedFeatures.features.managedThreatHuntingRegistration.confirm.close'),
            });
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('settings.advancedFeatures.features.managedThreatHuntingRegistration.error.title'),
                text: _this.i18nService.get('settings.advancedFeatures.features.managedThreatHuntingRegistration.error.text'),
                data: error,
            });
        });
    };
    return BilbaoRegistrationComponent;
}(ModalContainer));
export { BilbaoRegistrationComponent };
