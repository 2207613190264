var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { RecommendationContextType, } from '@wcd/domain';
import { RelationshipRepository } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { SpinnerSize } from 'office-ui-fabric-react';
import { MessageBarType } from 'office-ui-fabric-react';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { FeaturesService, Feature } from '@wcd/config';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { Router } from '@angular/router';
var RecommendationContextPropertiesEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(RecommendationContextPropertiesEntityDetailsComponent, _super);
    function RecommendationContextPropertiesEntityDetailsComponent(tvmTextsService, featuresService, recommendationContextFieldsService, injector, router) {
        var _this = _super.call(this, injector) || this;
        _this.tvmTextsService = tvmTextsService;
        _this.featuresService = featuresService;
        _this.recommendationContextFieldsService = recommendationContextFieldsService;
        _this.router = router;
        _this.MessageBarType = MessageBarType;
        _this.SpinnerSize = SpinnerSize;
        return _this;
    }
    Object.defineProperty(RecommendationContextPropertiesEntityDetailsComponent.prototype, "recommendationContextType", {
        set: function (value) {
            this._recommendationContextType = value;
            this.fields = this.recommendationContextFieldsService.getRecommendationContextProperties(value);
            var allFieldsIds = this.fields.map(function (field) { return field.id; });
            this.dataViewConfig = {
                //must supply unique id for correct dataview caching purposes - unique id is based on the fields
                id: "recommendationContextPropertiesDetails(" + allFieldsIds.join() + ")",
            };
        },
        enumerable: true,
        configurable: true
    });
    RecommendationContextPropertiesEntityDetailsComponent.prototype.ngOnInit = function () {
        this.loadingMessageText =
            this.tvmTextsService.recommendationContextToContextKeyPaneLoadingTitle[this._recommendationContextType] +
                ' ' +
                this.recommendationContextKey;
    };
    RecommendationContextPropertiesEntityDetailsComponent.prototype.openMachinePage = function (item) {
        var link = this.featuresService.isEnabled(Feature.UpgradeMachinePage)
            ? "/machines/" + item.name
            : "/machine/" + item.name;
        this.router.navigate([link]);
    };
    return RecommendationContextPropertiesEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { RecommendationContextPropertiesEntityDetailsComponent };
