import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { FilesService } from '../../files/services/files.service';
import { UrlsService } from '../../urls/services/urls.service';
import { EntityModelBase, DataEntityType } from '@microsoft/paris';
import { find, remove } from 'lodash-es';
import { Script } from '@wcd/domain';
import { RegExpService } from '@wcd/shared';

const etwEventNameAndFlightingFeatureMap = ['AlertEtw', Feature.AmsiScriptDetection];

// Icons
const iconSettingsName = 'icon-Settings';
const iconSettingsCode = '\uE115';
const iconDeviceGuardName = 'icon-MixedMediaBadge';
const iconDeviceGuardCode = '\uEA0D';
const iconFireWallName = 'wcd-icons--wall';
const iconFireWallCode = '\uF1F9';
const iconExploitGuardName = 'icon-Admin';
const iconExploitGuardCode = '\uE1A7';
const iconPage = 'icon-Page';
const iconShieldName = 'icon-Shield';
const iconShieldCode = '\uEA18';

const userDecisionEventTypeName = 'UserDecision';

// Other constants
const microsoftEncyclopediaLinkPrefix =
	'https://www.microsoft.com/security/portal/threat/encyclopedia/Entry.aspx?Name=';
const exploitGuardInsideRules = {
	OfficeInjection: '75668c1f-73b5-4cf0-bb93-3ecf5cb7cc84',
	OfficeCreatingExecutable: '3b576869-a4ec-4529-8536-b80a7769e899',
	OfficeCreatingChild: 'd4f940ab-401b-4efc-aadc-ad5f3c50688a',
	ExecutingPayLoad: 'd3e037e1-3eb8-44c8-a917-57927947596d',
	RunningObfuscatedCode: '5beb7efe-fd9a-4556-801d-275e5ffc04cc',
	ExecutingEmailAttachment: 'be9ba2d9-53ea-4cdc-84e5-9b1eeee46550',
	FolderGuard: '5737d832-9e2c-4922-9623-48a220290dcb',
	RunningUntrustedExecutable: '01443614-cd74-433a-b99e-2ecdc07bfc25',
	LaunchingUnverifiedWindowsExecutable: 'c1db55ab-c21a-4637-bb3f-a12568109d35',
	ProcessCreationFromWMI: 'd1e49aac-8f56-4280-b9ba-993a6d77406c',
	CredentilStealingFromLsass: '9e6c4e1f-7d60-472f-ba1a-a39ef669e4b2',
	WindowsCallsFromOfficeMacro: '92e97fa1-2edf-4476-bdd6-9dd0b4dddc7b',
	UntrustedProcessFromUSB: 'b2b3f03d-6a65-4f7b-a9c7-1c7ef74a9ba4',
};

const eventNames = {
	wdavDetection: 'WDAVDetection',
	wdavReport: 'WdavReport',
};

export interface EntityPanelSettings<T extends EntityModelBase<string | number>> {
	entityConstructor: DataEntityType<T>;
	entity: T;
	options?: any;
}

@Injectable()
export class AlertsEtwService {
	etwDictionary: any;

	readonly genericEtwElementType = 'GenericEtw';

	constructor(
		private readonly featuresService: FeaturesService,
		private readonly filesService: FilesService,
		private readonly urlsService: UrlsService
	) {
		this.etwDictionary = {
			Default: {
				icons: {
					timelineRowIcon: iconSettingsName, // icon in timeline row
					alertProcessTreeIconCode: iconSettingsCode, // icon in alert processtree
					sidePaneIcon: iconSettingsName, // icon at the top of the side pane
					machineProcessTreeNodeIcon: function() {
						return null;
					}, // icon of the machine process tree node (if null using the timelineRowIcon)
					machineProcessTreeContentHeaderIcon: null, // icon inside the content of the machine process tree (if null doesn't show any icon)
				},
				// return the description to be shown in the machine timeline row of the event
				machineTimelineDescription: function() {
					return null;
				},
				// return the description to be shown near the node of the event in the machine timeline process tree
				machineProcessTreeNodeDescription: function(event) {
					return null;
				},
				// return the description prefix to be shown before the initiating process file element (if exists).
				machineTimelineDescriptionPrefix: function(event) {
					return null;
				},
				// return the description suffix to be shown after the target file element (if exists).
				machineTimelineDescriptionSuffix: function(event) {
					return null;
				},
				// returns array of objects that each is constructed from icon and data to appear in a row of etw event that has no process tree
				machineTimelineNoProcesTreeElements: function(event) {
					return null;
					/* example of array to return:
					[
					{
						"icon": "icon-ExitRight",
						"data": 123
					},
					{
						"icon": "icon-settings",
						"data": event.threatName
					}
					];*/
				},
				// hide the initiating process name in the machine timeline event row.
				// Can be a constant value, or a function with the signature: function(event) : boolean
				machineTimelineHideInitiatingProcessInDescription: false,
				machineTimelineHideUriInDescription: false, // hide uri if exists in json bag in the machine timeline event row
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					// gets the event and an array of all the names to appear in the details column, may add / modify this array.
				},
				// return the process tree details column data sha
				machineProcessTreeDetailsColumnDataSha: function() {
					return null;
				},
				sidePaneDetails: {
					sidePaneTitle: function(event) {
						return null;
					}, // sidepane title (if null will be the default title - usually the file name).
					sectionTitle: null, // event section title in sidepane.
					sections: function(event) {
						return '';
					}, // sections of the event in sidepane.
					isSingleSectionSidePane: function(eventItem) {
						return false;
					}, // if true, sidepane will only contain the event section.
					isEtwValidForSidePane: function(node) {
						return false;
					}, // determine if this event has a sidepane or not.
					sidePaneExistingType: function(event) {
						return event.SidePaneType;
					}, //function that may return a name of none-etw event that already has a side pane defenition and this event will use it (for example returns 'CreateProcess'). default returns the name of the event.
					getNewSidePaneDetails: (
						event: any
					): EntityPanelSettings<EntityModelBase<string | number>> => {
						return null;
					}, // specify this if you want to show the new entity panel instead of the old one
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						// description line to appear below the node title in alert process tree
						return null;
					},
					eventTypeFriendlyName: 'other', // text to apear below process tree to describe unpresented events types
					nodeTitle: function(node) {
						return null; // process tree node title. if function is undefined / nodeTitle is null - will be FileName as defined in alert.processTree.js
					},
					getNodeLink: null, // function that returns the link to open when clicking on the node. Default: node entity link (Url / IP / file etc.)
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						// node title - if null will be file name as defined in events.js
						return null;
					},
					Threat: function(event) {
						// threat to appear in the node content
						return null;
					},
					EncyclopediaLink: function(event) {
						return null;
					},
					undirectChildRemoteText: function(event) {
						// text to appear in machine process tree remote text (like in process injection event)
						return null;
					},
					disableProcessTree: function(event) {
						// if true - will not show the process tree in machine timeline for this event
						return false;
					},
					showNodeContentOnly: function(event) {
						// if true - will not show the sha1 and dir in node content process tree
						return false;
					},
					nodeContent: function(event) {
						// returns an array of objects, each object is constructed from "icon" and "data" element to appear in the machine process tree node content and and optional "sidePaneCondition" that holds a condition to allow sidepane before that data
						return null;
					},
				},
			},

			OpenProcess: {
				icons: {
					timelineRowIcon: 'icon-ProvisioningPackage',
					alertProcessTreeIconCode: iconSettingsCode, // using process icon instead
					sidePaneIcon: iconSettingsName,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function() {
					return 'opened process handle of:';
				},
				sidePaneDetails: {
					sidePaneTitle: null,
					sectionTitle: 'Target process details',
					sections: getOpenProcessSidePaneSections,
					isSingleSectionSidePane: function() {
						return false;
					},
					isEtwValidForSidePane: isNodeValidForSidePane,
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return node.InitiatingProcessName + ' opened process handle of ' + node.FileName;
					},
					eventTypeFriendlyName: 'process handle open',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Open process';
					},
				},
			},

			MemAllocForHighRiskProcesses: {
				icons: {
					timelineRowIcon: 'icon-SmartcardVirtual',
					alertProcessTreeIconCode: '\uE964',
				},
				machineTimelineDescription: function() {
					return null; // MemAlloc requires description that is built with different html structure. can be found in events.html.
				},
				machineProcessTreeNodeDescription: function(event) {
					return 'Anomalous memory allocation in ' + event.processName + ' process memory';
				},
				machineProcessTreeDetails: {
					nodeName: function() {
						return 'Memory allocation';
					},
				},
				sidePaneDetails: {
					sidePaneTitle: function() {
						return 'Process memory allocation';
					},
					sectionTitle: 'Memory allocation details',
					sections: getMemAllocForHighRiskProcessesSidePaneSections,
					isSingleSectionSidePane: function() {
						return true;
					},
					isEtwValidForSidePane: function(node) {
						return true;
					},
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							'Anomalous memory allocation in ' + node.InitiatingProcessName + ' process memory'
						);
					},
					eventTypeFriendlyName: 'process memory allocation',
					nodeTitle: function() {
						return 'Process memory allocation';
					},
				},
			},

			PowerShellCommand: {
				icons: {
					timelineRowIcon: 'icon-CommandPrompt',
					alertProcessTreeIconCode: '\uE756',
					machineProcessTreeContentHeaderIcon: 'icon-CommandPrompt',
				},
				machineTimelineDescription: function(event) {
					return (
						'ran Powershell command ' +
						(event.etwJsonProperties && event.etwJsonProperties.Command
							? event.etwJsonProperties.Command
							: '')
					);
				},
				machineProcessTreeNodeDescription: function(event) {
					return event.etwJsonProperties.Command;
				},
				machineProcessTreeDetails: {
					nodeName: function() {
						return 'Powershell command';
					},
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							node.InitiatingProcessName +
							' ran Powershell command ' +
							node.EtwEventPropertiesAsJson.Command
						);
					},
					eventTypeFriendlyName: 'powershell command execution',
					nodeTitle: function(node) {
						return node.EtwEventPropertiesAsJson.Command;
					},
				},
			},

			TokenModification: {
				icons: {
					timelineRowIcon: 'icon-Permissions',
					alertProcessTreeIconCode: '\uE192',
				},
				machineTimelineDescription: function(event) {
					const isChangedToSystemToken =
						event.jsonProperties &&
						event.jsonProperties.TokenModificationProperties &&
						JSON.parse(event.jsonProperties.TokenModificationProperties).isChangedToSystemToken;
					return (
						'Access token was modified' + (isChangedToSystemToken ? ' to the SYSTEM token' : '')
					);
				},
				machineProcessTreeNodeDescription: function(event) {
					return event.processName + ' ' + event.desc;
				},
				machineProcessTreeDetails: {
					nodeName: function() {
						return 'Access token';
					},
				},
				sidePaneDetails: {
					sidePaneTitle: function() {
						return 'Access token in kernel';
					},
					sectionTitle: 'Token modification details',
					sections: getTokenModificationSidePaneSections,
					isSingleSectionSidePane: function() {
						return true;
					},
					isEtwValidForSidePane: function(node) {
						return (
							node.EtwEventPropertiesAsJson &&
							node.EtwEventPropertiesAsJson.TokenModificationProperties
						);
					},
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						let isChangedToSystemToken = false;
						if (
							node.EtwEventPropertiesAsJson &&
							node.EtwEventPropertiesAsJson.TokenModificationProperties
						) {
							const tokenModificationProperties = JSON.parse(
								node.EtwEventPropertiesAsJson.TokenModificationProperties
							);
							isChangedToSystemToken = tokenModificationProperties.isChangedToSystemToken;
						}
						return (
							'The ' +
							node.InitiatingProcessName +
							' access token was modified' +
							(isChangedToSystemToken ? ' to the SYSTEM token' : '')
						);
					},
					eventTypeFriendlyName: 'access token modification',
					nodeTitle: function() {
						return 'Access token modified';
					},
				},
			},

			DeviceGuard: {
				icons: {
					timelineRowIcon: iconDeviceGuardName,
					alertProcessTreeIconCode: iconDeviceGuardCode,
				},
				machineTimelineDescription: getDeviceGuardMachineTimelineDescription,
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Device guard';
					},
				},
			},

			CreateUser: {
				icons: {
					timelineRowIcon: 'icon-AddFriend',
					alertProcessTreeIconCode: '\uE1E2',
				},
				machineTimelineDescription: function(event) {
					return `User account created: ${resolveUserName(
						event.TargetProcess_Account_Name,
						event.TargetProcess_Account_DomainName
					)}`;
				},
				machineProcessTreeNodeDescription: function(event) {
					return resolveUserName(
						event.TargetProcess_Account_Name,
						event.TargetProcess_Account_DomainName
					);
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						// node title - if null will be file name as defined in events.js
						return 'User account created';
					},
				},
				machineTimelineHideInitiatingProcessInDescription: true,
			},

			ProcessInjection: {
				icons: {
					timelineRowIcon: 'icon-Vaccination',
					alertProcessTreeIconCode: '\uEAE0',
					sidePaneIcon: iconSettingsName,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return 'injected to' + (!event.filename ? ' unknown' : '') + ' process';
				},
				machineProcessTreeNodeDescription: function(event) {
					return !event.filename ? 'Unknown process' : null;
				},
				sidePaneDetails: {
					sidePaneTitle: null,
					sectionTitle: 'Target process details',
					sections: getProcessInjectionSidePaneSections,
					isSingleSectionSidePane: function() {
						return false;
					},
					isEtwValidForSidePane: isNodeValidForSidePane,
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return node.InitiatingProcessName + ' injected to process ' + node.FileName;
					},
					eventTypeFriendlyName: 'process injection',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Inject to process';
					},
				},
			},

			ProcessBlockGeneratingDynamicCode: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' generating dynamic code'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				machineProcessTreeNodeDescription: function() {
					return ' ';
				}, // empty node (no content and not showing file name)
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return exploitGuardAlertProcessTreeDescription(node, 'generating dynamic code');
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
					nodeName: function() {
						return 'Dynamic code';
					},
				},
			},

			LoadNonMicrosoftSignedBinary: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' loading non-Microsoft signed binary'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const eventDescription =
							'loading non-Microsoft signed binary' +
							(node.FileName ? ' ' + node.FileName : '');
						return exploitGuardAlertProcessTreeDescription(node, eventDescription);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			CallWin32k: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' calling Win32 API'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				machineProcessTreeNodeDescription: function() {
					return ' ';
				}, // empty node (no content and not showing file name)
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return exploitGuardAlertProcessTreeDescription(node, 'calling Win32 API');
					},
					eventTypeFriendlyName: 'ExploitGuard',
					nodeTitle: function(node) {
						return node.InitiatingProcessName;
					},
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
					nodeName: function() {
						return 'Win32 API';
					},
				},
			},

			LoadBinaryFromRemoteShare: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconPage;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' loading file from remote share'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				machineProcessTreeNodeDescription: function() {
					return ' ';
				}, // empty node (no content and not showing file name)
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return exploitGuardAlertProcessTreeDescription(
							node,
							'loading file from remote share'
						);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
					nodeName: function() {
						return 'File';
					},
				},
			},

			ProcessBlockCreatingChildProcess: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' creating child process'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const eventDescription =
							'creating child process' + (node.FileName ? ' ' + node.FileName : '');
						return exploitGuardAlertProcessTreeDescription(node, eventDescription);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			LoadLowILImage: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Processing';
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' loading low integrity image'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const eventDescription =
							'loading low integrity image' + (node.FileName ? ' ' + node.FileName : '');
						return exploitGuardAlertProcessTreeDescription(node, eventDescription);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			ExportAddressFilter: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' accessing the Export Address Table for module'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const eventDescription =
							'accessing the Export Address Table' +
							(node.FileName ? ' for module ' + node.FileName : '');
						return exploitGuardAlertProcessTreeDescription(node, eventDescription);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			ImportAddressFilter: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Cloud';
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' accessing the Import Address Table of API'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return exploitGuardAlertProcessTreeDescription(
							node,
							'accessing the Import Address Table of API'
						);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						return event.filename ? event.filename : 'API';
					},
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			Rop: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Cloud';
					},
				},
				machineTimelineDescription: function(event) {
					return (
						'was ' +
						auditedOrBlocked(event.etwJsonProperties && event.etwJsonProperties.IsAudit, {
							isUsedInDescription: true,
						}) +
						' calling the API due to return-oriented programming (ROP) exploit indications'
					);
				},
				machineTimelineDescriptionSuffix: function() {
					return 'by ExploitGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const eventDescription =
							'calling the API due to return-oriented programming (ROP) exploit indications';
						return exploitGuardAlertProcessTreeDescription(node, eventDescription);
					},
					eventTypeFriendlyName: 'ExploitGuard',
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						return event.etwJsonProperties.ApiName ? event.etwJsonProperties.ApiName : 'API';
					},
					undirectChildRemoteText: exploitguardRemoteText,
				},
			},

			CiRevokedDriverNotLoaded: {
				icons: {
					timelineRowIcon: iconDeviceGuardName,
					alertProcessTreeIconCode: iconDeviceGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Processing';
					},
				},
				machineTimelineDescription: function() {
					return 'was blocked from loading revoked driver';
				},
				// return the description suffix to be shown after the target file element (if exists).
				machineTimelineDescriptionSuffix: function(event) {
					return 'By DeviceGuard';
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						// description line to appear below the node title in alert process tree
						return 'blocked from loading revoked driver ' + node.FileName + 'by DeviceGuard';
					},
					eventTypeFriendlyName: 'DeviceGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Blocked by DeviceGuard';
					},
				},
			},

			CiRevokedImageNotLoaded: {
				icons: {
					timelineRowIcon: iconDeviceGuardName,
					alertProcessTreeIconCode: iconDeviceGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Processing';
					},
				},
				machineTimelineDescription: function() {
					return 'was blocked from loading revoked image';
				},
				// return the description suffix to be shown after the target file element (if exists).
				machineTimelineDescriptionSuffix: function(event) {
					return 'By DeviceGuard';
				},
				machineProcessTreeUndirectChildRemoteText: 'Blocked by DeviceGuard',
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						// description line to appear below the node title in alert process tree
						return 'blocked from loading revoked image' + node.FileName + 'by DeviceGuard';
					},
					eventTypeFriendlyName: 'DeviceGuard',
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Blocked by DeviceGuard';
					},
				},
			},

			WDAVDetection: {
				icons: {
					timelineRowIcon: 'icon-Bug',
					alertProcessTreeIconCode: '\uEBE8',
					machineProcessTreeNodeIcon: function(event) {
						if (isWdavRegistryEvent(event.etwJsonProperties)) {
							return 'icon-OEM';
						}

						if (isWdavServiceEvent(event.etwJsonProperties)) {
							return 'icon-Settings';
						}

						return iconPage;
					},
				},
				machineTimelineDescriptionSuffix: function(eventItem) {
					if (!eventItem.etwJsonProperties || !eventItem.etwJsonProperties.ThreatName) {
						return 'detection of a threat by Antivirus';
					}

					var registryOrServiceDescriptionToDisplay = '';
					if (isWdavServiceEvent(eventItem.etwJsonProperties)) {
						// etw wdav service event
						registryOrServiceDescriptionToDisplay = eventItem.etwJsonProperties.Service + ' ';
					}

					// none wdav registry/service will have target process name showing before the description suffix
					// wdav registry/service has no target process name and will show the registry/service description instead
					return (
						registryOrServiceDescriptionToDisplay +
						'was detected as ' +
						eventItem.etwJsonProperties.ThreatName +
						' by Antivirus'
					);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				machineTimelineHideUriInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						if (!node.EtwEventPropertiesAsJson || !node.EtwEventPropertiesAsJson.ThreatName) {
							return 'detection of a threat by Antivirus';
						}

						var nameInDescription = node.FileName;

						if (isWdavRegistryEvent(node.EtwEventPropertiesAsJson)) {
							nameInDescription = getWdavRegistryDescription(node.EtwEventPropertiesAsJson);
						}

						if (isWdavServiceEvent(node.EtwEventPropertiesAsJson)) {
							nameInDescription = node.EtwEventPropertiesAsJson.Service;
						}

						return (
							nameInDescription +
							' detected as ' +
							node.EtwEventPropertiesAsJson.ThreatName +
							' by Antivirus' // talpert show ReportSource friendly name (3rd party by reportSource field)
						);
					},
					eventTypeFriendlyName: 'Windows Defender AV',
					nodeTitle: function(node) {
						if (isWdavRegistryEvent(node.EtwEventPropertiesAsJson)) {
							return getWdavRegistryDescription(node.EtwEventPropertiesAsJson);
						}

						if (isWdavServiceEvent(node.EtwEventPropertiesAsJson)) {
							return node.EtwEventPropertiesAsJson.Service;
						}

						return node.FileName;
					},
				},
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					if (isWdavServiceEvent(event.etwJsonProperties)) {
						arrayOfNamesToAppearInDetailsColumn.push(event.etwJsonProperties.Service);
					}
				},
				machineProcessTreeDetails: {
					Threat: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? event.etwJsonProperties.ThreatName
							: null;
					},
					EncyclopediaLink: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? microsoftEncyclopediaLinkPrefix + event.etwJsonProperties.ThreatName
							: null;
					},
					undirectChildRemoteText: function() {
						return 'Detected by Antivirus';
					},
					nodeName: function(event) {
						if (isWdavRegistryEvent(event.etwJsonProperties)) {
							return event.etwJsonProperties.RegistryValueName;
						}

						if (isWdavServiceEvent(event.etwJsonProperties)) {
							return event.etwJsonProperties.Service;
						}

						if (isWdavServiceEvent(event.etwJsonProperties)) {
							return event.etwJsonProperties.Service;
						}

						if (isWdavServiceEvent(event.etwJsonProperties)) {
							return event.etwJsonProperties.Service;
						}

						return event.filename ? event.filename : 'Unknown';
					},
					nodeContent: function(event) {
						const etwJsonProperties = event.etwJsonProperties;
						return [
							{
								data: etwJsonProperties.Service ? etwJsonProperties.Service : null,
							},
							{
								data:
									etwJsonProperties.Uri &&
									etwJsonProperties.DownloadedViaWebFile &&
									event.filename
										? event.filename
										: null,
							},
							{
								icon: 'icon-Folder',
								sidePaneCondition: isWdavRegistryEvent(etwJsonProperties),
								data: etwJsonProperties.RegistryKey ? etwJsonProperties.RegistryKey : null,
							},
							{
								icon: 'icon-Permissions',
								data: etwJsonProperties.RegistryValueName
									? 'Value name: ' + etwJsonProperties.RegistryValueName
									: null,
							},
							{
								icon: 'icon-Permissions',
								data: etwJsonProperties.RegistryValueData
									? 'Value data: ' + etwJsonProperties.RegistryValueData
									: null,
							},
							{
								icon: 'icon-ResetView',
								data: etwJsonProperties.Container
									? 'Container: ' + etwJsonProperties.Container
									: null,
							},
						];
					},
				},
				sidePaneDetails: {
					isEtwValidForSidePane: function(node) {
						if (isWdavServiceEvent(node.EtwEventPropertiesAsJson)) {
							return false;
						}

						return true;
					},
					sidePaneTitle: function(eventItem) {
						const jsonProperties =
							eventItem.etwJsonProperties || eventItem.EtwEventPropertiesAsJson;
						if (isWdavRegistryEvent(jsonProperties)) {
							return getWdavRegistrySidePaneTitle(jsonProperties);
						}

						// will be set as fileName for regular wdav etw events
						return null;
					},
					sectionTitle: 'Block Details',
					sections: getWDAVDetectionSidePaneSections,
					isSingleSectionSidePane: function(eventItem) {
						// if the event has no sha1 - file details, detections and observations are not available -> show single section only.
						// wdav registry events currently has no sha1.
						return eventItem.Sha1 ? false : true;
					},
				},
			},

			WdavReport: {
				icons: {
					timelineRowIcon: iconPage,
					alertProcessTreeIconCode: '\uE160',
				},
				machineTimelineDescription: function() {
					return 'file observed on host';
				},
				machineTimelineNoProcesTreeElements: function(event) {
					return [
						{
							icon: 'icon-ExitRight',
							data: `${event.path}${event.filename ? '\\' + event.filename : ''}`,
						},
					];
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				machineTimelineHideUriInDescription: true,
				machineProcessTreeDetailsColumnDataSha: function(event) {
					return event.sha1 ? event.sha1 : null;
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return 'file observed on host';
					},
					eventTypeFriendlyName: 'Windows Defender AV',
					nodeTitle: function(node) {
						return node.FileName;
					},
				},
				machineProcessTreeDetails: {
					disableProcessTree: function(event) {
						return true;
					},
				},
			},

			IRSpynetReport: {
				icons: {
					timelineRowIcon: 'icon-Bug',
					alertProcessTreeIconCode: '\uEBE8',
					machineProcessTreeNodeIcon: function(event) {
						return iconPage;
					},
				},
				machineTimelineDescriptionSuffix: function(eventItem) {
					const threat = eventItem.etwJsonProperties && eventItem.etwJsonProperties.ThreatName;
					const fileName = eventItem.filename;

					// file name and threat
					if (threat && fileName) {
						return `was detected as ${threat} by Antivirus`;
					}

					// no file name
					if (threat && !fileName) {
						return `detection of ${threat} by Antivirus`;
					}

					// no threat
					if (!threat && fileName) {
						return 'was detected as a threat by Antivirus';
					}

					// no file name and no threat
					return 'detection of a threat by Antivirus';
				},
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					remove(arrayOfNamesToAppearInDetailsColumn, function(x) {
						return x === 'Unknown';
					});
				},
				machineProcessTreeDetailsColumnDataSha: function(event) {
					return event.sha1 ? event.sha1 : null;
				},
				machineTimelineNoProcesTreeElements: function(event) {
					const etwJsonProperties = event.etwJsonProperties;
					return [
						{
							sha: event.sha1 ? event.sha1 : null,
						},
						{
							icon: 'flip-vertical icon-ExitRight',
							data: event.path ? event.path : null,
						},
						{
							icon: 'icon-ReadingMode',
							encyclopedia:
								etwJsonProperties && etwJsonProperties.ThreatName
									? microsoftEncyclopediaLinkPrefix + etwJsonProperties.ThreatName
									: null,
						},
					];
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				machineTimelineHideUriInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						if (!node.EtwEventPropertiesAsJson || !node.EtwEventPropertiesAsJson.ThreatName) {
							return 'detection of a threat by Antivirus';
						}

						const nameInDescription = node.FileName;
						return (
							nameInDescription +
							' detected as ' +
							node.EtwEventPropertiesAsJson.ThreatName +
							' by Antivirus'
						);
					},
					eventTypeFriendlyName: 'Windows Defender AV',
					nodeTitle: function(node) {
						return node.FileName;
					},
				},
				machineProcessTreeDetails: {
					disableProcessTree: function(event) {
						if (event.sha1 && event.filename && event.parentProcessName) {
							return false;
						}

						// will not show the process tree in machine timeline for this event
						return true;
					},
					Threat: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? event.etwJsonProperties.ThreatName
							: null;
					},
					EncyclopediaLink: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? microsoftEncyclopediaLinkPrefix + event.etwJsonProperties.ThreatName
							: null;
					},
					undirectChildRemoteText: function() {
						return 'Detected by Antivirus';
					},
					nodeName: function(event) {
						return event.filename ? event.filename : 'Unknown';
					},
				},
				sidePaneDetails: {
					isEtwValidForSidePane: function() {
						return true;
					},
					sidePaneTitle: function(eventItem) {
						return null;
					},
					sectionTitle: 'Block Details',
					sections: getWDAVDetectionSidePaneSections,
					isSingleSectionSidePane: function(eventItem) {
						// if the event has no sha1 - file details, detections and observations are not available -> show single section only.
						return eventItem.Sha1 ? false : true;
					},
				},
			},

			BitdefenderDetection: {
				icons: {
					timelineRowIcon: 'icon-Bug',
					alertProcessTreeIconCode: '\uEBE8',
				},
				machineTimelineDescriptionSuffix: function(eventItem) {
					if (
						!eventItem.filename ||
						!eventItem.etwJsonProperties ||
						!eventItem.etwJsonProperties.ThreatName
					) {
						return 'detection of a threat by Bitdefender';
					}

					return 'was detected as ' + eventItem.etwJsonProperties.ThreatName + ' by Bitdefender';
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						if (
							!node.FileName ||
							!node.EtwEventPropertiesAsJson ||
							!node.EtwEventPropertiesAsJson.ThreatName
						) {
							return 'detection of a threat by Bitdefender';
						}

						return (
							node.FileName +
							' detected as ' +
							node.EtwEventPropertiesAsJson.ThreatName +
							' by Bitdefender'
						);
					},
					eventTypeFriendlyName: 'Bitdefender',
				},
				machineProcessTreeDetails: {
					Threat: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? event.etwJsonProperties.ThreatName
							: null;
					},
					disableProcessTree: function() {
						return true;
					},
				},
				machineTimelineNoProcesTreeElements: function(event) {
					return [
						{
							icon: 'flip-vertical icon-ExitRight',
							data: event.path + event.filename,
						},
					];
				},
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					remove(arrayOfNamesToAppearInDetailsColumn, function(x) {
						return x === 'Unknown';
					});
				},
				sidePaneDetails: {
					sidePaneExistingType: function(event) {
						return event.Uri ? event.fullEtwType : 'CreateFile';
					},
					isEtwValidForSidePane: function(node) {
						return true;
					},
				},
			},

			PartnerDetection: {
				icons: {
					timelineRowIcon: 'icon-Bug',
					alertProcessTreeIconCode: '\uEBE8',
				},
				machineTimelineDescriptionSuffix: function(eventItem) {
					if (
						!eventItem.filename ||
						!eventItem.etwJsonProperties ||
						!eventItem.etwJsonProperties.ThreatName ||
						!eventItem.etwJsonProperties.ReportSource
					) {
						return 'detection of a threat';
					}

					return (
						'was detected as ' +
						eventItem.etwJsonProperties.ThreatName +
						' by ' +
						eventItem.etwJsonProperties.ReportSource
					);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						if (
							!node.FileName ||
							!node.EtwEventPropertiesAsJson ||
							!node.EtwEventPropertiesAsJson.ThreatName ||
							!node.EtwEventPropertiesAsJson.ReportSource
						) {
							return 'detection of a threat';
						}

						return (
							node.FileName +
							' detected as ' +
							node.EtwEventPropertiesAsJson.ThreatName +
							' by ' +
							node.EtwEventPropertiesAsJson.ReportSource
						);
					},
					eventTypeFriendlyName: 'PartnerDetection',
				},
				machineProcessTreeDetails: {
					Threat: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? event.etwJsonProperties.ThreatName
							: null;
					},
					disableProcessTree: function() {
						return true;
					},
				},
				machineTimelineNoProcesTreeElements: function(event) {
					return [
						{
							icon: 'flip-vertical icon-ExitRight',
							data: event.path + event.filename,
						},
					];
				},
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					remove(arrayOfNamesToAppearInDetailsColumn, function(x) {
						return x === 'Unknown';
					});
				},
				sidePaneDetails: {
					sidePaneExistingType: function(event) {
						return event.Uri ? event.fullEtwType : 'CreateFile';
					},
					isEtwValidForSidePane: function(node) {
						return true;
					},
				},
			},

			ContainerOperation: {
				icons: {
					timelineRowIcon: 'icon-DeviceGuard',
					alertProcessTreeIconCode: '\uF0EF',
				},
				machineTimelineDescription: function(event, fullType) {
					return getAppGuardContainerOperationMachineTimelineDescription(
						event.etwJsonProperties.RelatedContainerId,
						fullType
					);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					arrayOfNamesToAppearInDetailsColumn.push('Application Guard Container');
				},
				machineProcessTreeDetailsColumnDataSha: function(event) {
					return event.sha1 ? event.sha1 : null;
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return getAppGuardContainerOperationMachineTimelineDescription(
							node.EtwEventPropertiesAsJson.RelatedContainerId,
							node.fullEtwType
						);
					},
					eventTypeFriendlyName: 'Application Guard',
					nodeTitle: function() {
						return 'Application Guard container';
					},
				},
				machineProcessTreeDetails: {
					disableProcessTree: function(event) {
						if (event.sha1 && event.filename && event.parentProcessName) {
							return false;
						}
						// will not show the process tree in machine timeline for this event
						return true;
					},
					Threat: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? event.etwJsonProperties.ThreatName
							: null;
					},
					EncyclopediaLink: function(event) {
						return event.etwJsonProperties && event.etwJsonProperties.ThreatName
							? microsoftEncyclopediaLinkPrefix + event.etwJsonProperties.ThreatName
							: null;
					},
					undirectChildRemoteText: function() {
						return 'Detected by Antivirus';
					},
					nodeName: function(event) {
						return event.filename ? event.filename : 'Unknown';
					},
					nodeContent: function(event) {
						if (!event.etwJsonProperties.RelatedContainerId) {
							return '';
						}

						const processTreeNodeContentArray = [
							{
								icon: null,
								data: 'Container Id: ' + event.etwJsonProperties.RelatedContainerId,
							},
						];

						if (event.fullEtwType === 'LaunchDocumentInContainer') {
							if (!event.etwJsonProperties.Url) {
							} else {
								processTreeNodeContentArray.push({
									icon: null,
									data: 'Redirected URL: ' + event.etwJsonProperties.Url,
								});
							}
						}
						return processTreeNodeContentArray;
					},
				},
			},

			UserDecision: {
				icons: {
					timelineRowIcon: 'icon-Shield',
					alertProcessTreeIconCode: '\uEA18',
					machineProcessTreeNodeIcon: function(event) {
						return event.Uri ? 'icon-Link' : iconSettingsName;
					},
				},
				machineTimelineDescription: function(event) {
					return 'User disregarded warning by SmartScreen';
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const Uri = getUriFromNode(node);
						if (Uri) {
							return (
								getTrimmedUriIfTooLong(Uri, { desiredMaxLength: 60 }) +
								' was navigated to by user despite SmartScreen warning' +
								(node.EtwEventPropertiesAsJson.Experience
									? ' as ' + node.EtwEventPropertiesAsJson.Experience
									: '')
							);
						}

						return node.FileName + ' was run by user despite SmartScreen warning as malicious';
					},
					eventTypeFriendlyName: 'SmartScreen',
					nodeTitle: function(node) {
						const Uri = getUriFromNode(node);
						if (Uri) {
							return getTrimmedUriIfTooLong(Uri, { desiredMaxLength: 60 });
						}

						return node.FileName;
					},
				},
				sidePaneDetails: {
					sidePaneExistingType: function(event) {
						return event.uri ? event.fullEtwType : 'CreateFile';
					},
					isEtwValidForSidePane: function(node) {
						// only AppLookup events currently have sidepane
						return !getUriFromNode(node);
					},
				},
				machineTimelineNoProcesTreeElements: function() {
					// show only if the related lookup event was not found
					return [
						{
							icon: 'icon-IncidentTriangle',
							data:
								'Related Uri/application that was blocked by smart screen could not be found.',
						},
					];
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						if (event.Uri) {
							return getTrimmedUriIfTooLong(event.Uri, { desiredMaxLength: 24 });
						}
						return event.filename;
					},
					nodeContent: function(event) {
						if (event.Uri) {
							return [{}]; // avoid showing a title
						}
						return null;
					},
					undirectChildRemoteText: function() {
						return 'Blocked by SmartScreen';
					},
					disableProcessTree: function(event) {
						// if the related lookup event found - the etwType will be updated to "UriLookup"/"AppLookup" and process tree will be enabled
						// if it was not found - don't show process tree
						return isSmartScreenUserDecisionEvent(event.etwType);
					},
				},
			},

			AppLookup: {
				icons: {
					timelineRowIcon: iconShieldName,
					alertProcessTreeIconCode: iconShieldCode,
					machineProcessTreeNodeIcon: function() {
						return iconPage;
					},
				},
				machineTimelineDescriptionSuffix: function(event) {
					if (isSmartScreenUserDecisionEvent(event.fullEtwType)) {
						return 'User disregarded warning by SmartScreen';
					}

					return smartScreenLookupEventDescription(event.etwJsonProperties.Experience);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				sidePaneDetails: {
					sidePaneExistingType: function() {
						return 'CreateFile';
					},
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							node.FileName +
							smartScreenLookupEventDescription(node.EtwEventPropertiesAsJson.Experience)
						);
					},
					eventTypeFriendlyName: 'SmartScreen',
				},
				machineProcessTreeNodeDescription: function(event) {
					return isSmartScreenUserDecisionEvent(event.fullEtwType)
						? 'User disregarded warning by SmartSreen'
						: null;
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function() {
						return 'Blocked by SmartScreen';
					},
				},
			},

			UriLookup: {
				icons: {
					timelineRowIcon: iconShieldName,
					alertProcessTreeIconCode: iconShieldCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Link';
					},
				},
				machineTimelineDescription: function(event) {
					if (isSmartScreenUserDecisionEvent(event.fullEtwType)) {
						return 'User disregarded warning by SmartScreen';
					}

					return smartScreenLookupEventDescription(event.etwJsonProperties.Experience);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							getTrimmedUriIfTooLong(getUriFromNode(node), { desiredMaxLength: 60 }) +
							smartScreenLookupEventDescription(node.EtwEventPropertiesAsJson.Experience)
						);
					},
					eventTypeFriendlyName: 'SmartScreen',
					nodeTitle: function(node) {
						return getUriFromNode(node);
					},
				},
				machineProcessTreeNodeDescription: function(event) {
					return isSmartScreenUserDecisionEvent(event.fullEtwType)
						? 'User disregarded warning by SmartSreen'
						: null;
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						return getTrimmedUriIfTooLong(event.Uri, { desiredMaxLength: 24 });
					},
					undirectChildRemoteText: function() {
						return 'Blocked by SmartScreen';
					},
					nodeContent: function(event) {
						return [{}]; // avoid showing a title
					},
				},
			},

			SploitStopper: {
				icons: {
					timelineRowIcon: iconShieldName,
					alertProcessTreeIconCode: iconShieldCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Link';
					},
				},
				machineTimelineDescription: function(event) {
					return 'navigation was blocked by SmartScreen';
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return getTrimmedUriIfTooLong(getUriFromNode(node), { desiredMaxLength: 60 });
					},
					eventTypeFriendlyName: 'SmartScreen',
					nodeTitle: function(node) {
						return getUriFromNode(node);
					},
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						return getTrimmedUriIfTooLong(event.Uri, { desiredMaxLength: 24 });
					},
					undirectChildRemoteText: function() {
						return 'Blocked by SmartScreen';
					},
					nodeContent: function(event) {
						return [{}]; // avoid showing a title
					},
				},
				sidePaneDetails: {
					sidePaneTitle: function(event) {
						return event.Uri;
					},
					sectionTitle: 'Block Details',
					sections: function(event) {
						return [
							{
								key: 'Block time',
								value: event.ActionTime,
								type: 'datetime',
								order: 1,
							},
							{
								key: 'Blocked URI',
								value: event.Uri,
								type: 'scrollable-block',
								order: 2,
							},
							{
								key: 'Referrer URI',
								value: event.EtwEventPropertiesAsJson.ReferrerUri,
								type: 'scrollable-block',
								order: 3,
							},
							{
								key: 'Redirected URI',
								value: event.EtwEventPropertiesAsJson.RedirectUri,
								type: 'scrollable-block',
								order: 4,
							},
						];
					},
					isSingleSectionSidePane: function(eventItem) {
						return true;
					},
					isEtwValidForSidePane: function(node) {
						return true;
					},
				},
			},

			NetworkFilterLookup: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Link';
					},
				},
				machineTimelineDescription: function(event) {
					return (
						' was ' +
						auditedOrBlocked(event.etwJsonProperties.IsAudit, { lowerCase: true }) +
						' by ExploitGuard'
					);
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							getTrimmedUriIfTooLong(getUriFromNode(node), { desiredMaxLength: 60 }) +
							' was ' +
							auditedOrBlocked(node.EtwEventPropertiesAsJson.IsAudit, {
								lowerCase: true,
							}) +
							' by ExploitGuard'
						);
					},
					eventTypeFriendlyName: 'ExploitGuard',
					nodeTitle: function(node) {
						return getUriFromNode(node);
					},
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						return getTrimmedUriIfTooLong(event.Uri, { desiredMaxLength: 24 });
					},
					undirectChildRemoteText: function(event) {
						return auditedOrBlocked(event.etwJsonProperties.IsAudit) + ' by ExploitGuard';
					},
					nodeContent: function(event) {
						return [{}]; // avoid showing a title
					},
				},
			},

			ExploitGuardHips: {
				icons: {
					timelineRowIcon: iconExploitGuardName,
					alertProcessTreeIconCode: iconExploitGuardCode,
					machineProcessTreeNodeIcon: function(event) {
						switch (event.etwJsonProperties.RuleId.toLowerCase()) {
							case exploitGuardInsideRules.FolderGuard:
								return 'icon-Folder';
							case exploitGuardInsideRules.WindowsCallsFromOfficeMacro:
								return 'icon-Page';
							default:
								return iconSettingsName;
						}
					},
				},
				machineTimelineDescriptionPrefix: event =>
					getExploitGuardHipsDescriptionPrefix(event.etwJsonProperties.RuleId),
				machineTimelineDescription: function(event) {
					return getExploitGuardHipsDescription(
						event.etwJsonProperties.RuleId,
						event.etwJsonProperties.IsAudit
					);
				},
				machineTimelineDescriptionSuffix: function(event) {
					return getExploitGuardHipsDescriptionSuffix(
						event.etwJsonProperties.RuleId,
						event.path,
						event.etwJsonProperties.IsAudit,
						{
							trimPathInDescription: true,
						}
					);
				},
				machineProcessTreeNodeDescription: function(event) {
					return event.etwJsonProperties.RuleId.toLowerCase() ===
						exploitGuardInsideRules.FolderGuard
						? ' '
						: event.filename;
				},
				machineTimelineHideInitiatingProcessInDescription: event =>
					isExploitGuardRuleHideInitiatingProcess(event.etwJsonProperties.RuleId),
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						const descriptionPrefix = getExploitGuardHipsDescriptionPrefix(
							node.EtwEventPropertiesAsJson.RuleId
						);
						const initiatingProcessName = isExploitGuardRuleHideInitiatingProcess(
							node.EtwEventPropertiesAsJson.RuleId
						)
							? ''
							: node.InitiatingProcessName || 'Unknown process';
						const mainDescription = getExploitGuardHipsDescription(
							node.EtwEventPropertiesAsJson.RuleId,
							node.EtwEventPropertiesAsJson.IsAudit
						);
						const targetProcessNameOrFolder =
							node.EtwEventPropertiesAsJson.RuleId.toLowerCase() ===
							exploitGuardInsideRules.FolderGuard
								? '' // folder guard events show no file name but the folder path (in extra description)
								: node.FileName
								? node.FileName
								: ' Unknown';
						const rulesExtraDescription = getExploitGuardHipsDescriptionSuffix(
							node.EtwEventPropertiesAsJson.RuleId,
							node.FolderPath,
							node.EtwEventPropertiesAsJson.IsAudit,
							{ trimPathInDescription: false }
						);

						return (
							(descriptionPrefix ? descriptionPrefix + ' ' : '') +
							initiatingProcessName +
							' ' +
							mainDescription +
							' ' +
							targetProcessNameOrFolder +
							' ' +
							rulesExtraDescription
						);
					},
					eventTypeFriendlyName: 'Exploit Guard',
					nodeTitle: function(node) {
						return node.EtwEventPropertiesAsJson.RuleId.toLowerCase() ===
							exploitGuardInsideRules.FolderGuard
							? node.FolderPath
							: node.FileName || 'Unknown';
					},
				},
				sidePaneDetails: {
					sidePaneExistingType: function() {
						return 'CreateFile';
					},
				},
				machineProcessTreeDetails: {
					undirectChildRemoteText: function(event) {
						return auditedOrBlocked(event.etwJsonProperties.IsAudit) + ' by ExploitGuard';
					},
					nodeName: function(event) {
						if (
							event.etwJsonProperties.RuleId.toLowerCase() ===
							exploitGuardInsideRules.FolderGuard
						) {
							return 'Folder';
						}
						return event.filename ? event.filename : 'Unknown process';
					},
				},
			},

			FireWall5031: {
				icons: {
					timelineRowIcon: iconFireWallName,
					alertProcessTreeIconCode: iconFireWallCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Streaming';
					},
				},
				machineTimelineDescription: function(event) {
					return getFirewall5031Description(event.etwJsonProperties.Profiles);
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return (
							node.InitiatingProcessName +
							' ' +
							getFirewall5031Description(node.EtwEventPropertiesAsJson.Profiles)
						);
					},
					nodeTitle: function(node) {
						return node.InitiatingProcessName;
					},
					eventTypeFriendlyName: 'Firewall',
				},
				machineProcessTreeDetails: {
					nodeName: function() {
						return 'Incoming communication';
					},
					undirectChildRemoteText: function() {
						return 'Blocked by Firewall';
					},
					nodeContent: function(event) {
						// dont show any content
						return [{}];
					},
				},
			},
			FireWall5157: {
				icons: {
					timelineRowIcon: iconFireWallName,
					alertProcessTreeIconCode: iconFireWallCode,
					machineProcessTreeNodeIcon: function() {
						return 'icon-Streaming';
					},
				},
				machineTimelineDescription: function(event) {
					return ' was blocked from communicating with';
				},
				machineTimelineDescriptionSuffix: function(event) {
					return ' by Windows Firewall';
				},
				machineProcessTreeDetailsColumnData: function(event, arrayOfNamesToAppearInDetailsColumn) {
					if (arrayOfNamesToAppearInDetailsColumn.length > 0) {
						arrayOfNamesToAppearInDetailsColumn.pop();
					}
					arrayOfNamesToAppearInDetailsColumn.push(
						event.etwJsonProperties.RemoteAddress + ':' + event.etwJsonProperties.RemotePort
					);
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						let description = '';
						if (node.EtwEventPropertiesAsJson.RemoteAddress) {
							description +=
								node.InitiatingProcessName +
								' was blocked from communicating with ' +
								node.EtwEventPropertiesAsJson.RemoteAddress;
							if (node.EtwEventPropertiesAsJson.RemotePort) {
								description += ':' + +node.EtwEventPropertiesAsJson.RemotePort;
							}
							description += ' by Windows Firewall';
							return description;
						}
						return 'Blocked from communicating.';
					},
					nodeTitle: function(node) {
						return node.InitiatingProcessName;
					},
					eventTypeFriendlyName: 'Firewall',
				},
				machineProcessTreeDetails: {
					nodeName: function(event) {
						const etwJsonProperties = event.etwJsonProperties;
						return etwJsonProperties.RemoteAddress && etwJsonProperties.RemotePort
							? etwJsonProperties.RemoteAddress + ':' + etwJsonProperties.RemotePort
							: ' ';
					},
					undirectChildRemoteText: function() {
						return 'Blocked by Firewall';
					},
					showNodeContentOnly: function(event) {
						return true;
					},
					nodeContent: function(event) {
						const etwJsonProperties = event.etwJsonProperties;
						return [
							{
								Ip: etwJsonProperties.RemoteAddress ? etwJsonProperties.RemoteAddress : null,
								Port: etwJsonProperties.RemotePort ? etwJsonProperties.RemotePort : null,
							},
						];
					},
				},
			},
			AlertEtw: {
				icons: {
					timelineRowIcon: 'icon-Favicon',
					alertProcessTreeIconCode: '\uE737',
				},
				machineTimelineDescription: function(event) {
					const description = event.etwJsonProperties.Description || '';
					const fileNameIndexInDescription = description.indexOf(event.filename);
					if (fileNameIndexInDescription !== -1) {
						// alert etw description contains the target file name inside it - splitting the description
						// so the file name will appear with sha1 and side pane ( as part of the html set in events.html).
						return description.substring(0, fileNameIndexInDescription);
					}

					return event.etwJsonProperties.Description;
				},
				machineTimelineDescriptionSuffix: function(event) {
					const description = event.etwJsonProperties.Description || '';
					const fileNameIndexInDescription = description.indexOf(event.filename);
					if (fileNameIndexInDescription !== -1) {
						// alert etw description contains the target file name inside it - splitting the description
						// so the file name will appear with sha1 and side pane ( as part of the html set in events.html).
						return description.substring(fileNameIndexInDescription + event.filename.length);
					}

					return '';
				},
				machineTimelineHideInitiatingProcessInDescription: true,
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						// if there is no entity name - the description will be shown as title instead of the entity name.
						return node.FileName ? node.EtwEventPropertiesAsJson.Description : '';
					},
					nodeTitle: function(node) {
						// if there is no entity name - the description will be shown as title instead of the entity name.
						return node.FileName || node.EtwEventPropertiesAsJson.Description;
					},
					eventTypeFriendlyName: 'other',
				},
				machineProcessTreeNodeDescription: function(event) {
					if (!event.filename) {
						// if there is no file name, description will be shown in the node content and nothing below the node icon
						return event.desc;
					}
					return event.filename;
				},
				machineProcessTreeDetails: {
					nodeName: function(node) {
						if (!node.filename) {
							// if there is no file name, description will be shown in the node content and nothing below the node icon
							return ' ';
						}

						return node.filename;
					},
				},
				sidePaneDetails: {
					isEtwValidForSidePane: function(node) {
						return (node.FileName || node.filename) && (node.Sha1 || node.sha1);
					},
					sectionTitle: 'Event details',
					sections: function(event) {
						return [
							{
								key: 'Event time',
								value: event.ActionTime,
								type: 'datetime',
								order: 1,
							},
							{
								key: 'Path',
								value: event.FolderPath,
								type: 'primitive',
								order: 2,
							},
						];
					},
				},
			},
			Email: {
				// The 'Email' event type is based on data queried from O365 API
				// It is not a real ETW event
				// It's supported only in the alert process tree - not supported in the machine timeline
				icons: {
					timelineRowIcon: 'icon-Mail',
					alertProcessTreeIconCode: '\uE715',
				},
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						if (node.EtwEventPropertiesAsJson && node.EtwEventPropertiesAsJson.Sender) {
							return `Email received from ${truncateStringWithElipsis(
								node.EtwEventPropertiesAsJson.Sender
							)}`;
						} else {
							return null;
						}
					},
					nodeTitle: function(node) {
						let subject = '(No subject)';
						if (node.EtwEventPropertiesAsJson && node.EtwEventPropertiesAsJson.Subject) {
							subject = node.EtwEventPropertiesAsJson.Subject;
						}

						return `Subject: ${truncateStringWithElipsis(subject)}`;
					},
					eventTypeFriendlyName: 'other',
				},
				sidePaneDetails: {
					isEtwValidForSidePane: function(node) {
						return true;
					},
					isSingleSectionSidePane: function(eventItem) {
						return true;
					},
					sidePaneTitle: function(eventItem) {
						return 'Email';
					},
					sectionTitle: 'Email details',
					sections: function(event) {
						return [
							{
								key: 'Sender',
								value: event.EtwEventPropertiesAsJson.Sender,
								type: 'primitive',
								order: 1,
							},
							{
								key: 'Sender IP',
								value: event.EtwEventPropertiesAsJson.SenderIp,
								type: 'primitive',
								order: 2,
							},
							{
								key: 'Subject',
								value: event.EtwEventPropertiesAsJson.Subject,
								type: 'primitive',
								order: 3,
							},
							{
								key: 'Recipients',
								value: event.EtwEventPropertiesAsJson.Recipients,
								type: 'primitive',
								order: 4,
							},
							{
								key: 'Received',
								value: event.EtwEventPropertiesAsJson.ReceivedDate,
								type: 'datetime',
								order: 5,
							},
						];
					},
				},
			},
			AmsiScriptDetection: {
				alertProcessTreeDetails: {
					nodeDescription: function(node) {
						return node.EtwEventPropertiesAsJson.Description;
					},
					nodeTitle: function(node) {
						return 'Script';
					},
					eventTypeFriendlyName: 'other',
					getNodeLink: node => '', // disable link as most scripts do not have a file profile
				},
				sidePaneDetails: {
					isEtwValidForSidePane: function(node) {
						return (
							(node.Sha1 && RegExpService.sha1.test(node.Sha1)) ||
							(node.Sha256 && RegExpService.sha256.test(node.Sha256))
						);
					},
					getNewSidePaneDetails: (event: any): EntityPanelSettings<Script> => {
						const script: Script = new Script({
							sha1: event['Sha1'],
							sha256: event['Sha256'],
							id: null,
						});
						return {
							entityConstructor: Script,
							entity: script,
							options: {
								actionTime: new Date(event['ActionTime']),
							},
						};
					},
				},
			},
		};
		// remove any etw events that are in flighting and disabled for this tenant
		etwEventNameAndFlightingFeatureMap.forEach(featureName => {
			if (!featuresService.isEnabled(featureName)) {
				delete this.etwDictionary[featureName];
			}
		});
	}

	getEtwIcons(etwType) {
		return getEtwDetailsObjectByType(this.etwDictionary, etwType, 'icons');
	}

	isEtwSupported(etwType) {
		return this.etwDictionary.hasOwnProperty(etwType);
	}

	getEtwEventType(event) {
		// When reached from alert process tree - EtwEventType is not defined but populated in ActionType.
		// Since now ActionType is also defined when reached from machine timeline - EtwEventType will be taken.
		// Once ActionType is fully used and EtwEventType is deprecated - use only ActionType.
		const etwType = event.EtwEventType || event.ActionType;
		if (etwType.indexOf('AppLocker') > -1 || etwType.indexOf('CodeIntegrity') > -1) {
			return 'DeviceGuard';
		}

		switch (etwType) {
			case 'CreateRemoteThread':
			case 'QueueUserQueueUserApcRemoteApiCallApcRemote':
			case 'SetThreadContextRemote':
			case 'MapViewRemote':
				return 'ProcessInjection';
			case 'CreateVMEnd':
			case 'ContainerStopped':
			case 'SuspendComputeSystemEnd':
			case 'ResumeComputeSystemEnd':
			case 'LaunchDocumentInContainer':
				return 'ContainerOperation';
			case 'IRSpynetReport':
			case 'WDAVDefenderDataConsumer':
				return 'IRSpynetReport';
			// wdav RS1-RS2 events
			case 'WDAVDefenderDataConsumerLoFi':
			case 'WDAVDefenderDataConsumerMemory':
			case 'WdavBootReport':
			case 'WDAVDefenderDataConsumerRootkit':
				// NewActionType is temporary property returned by alert process tree. once we change the api to use actionType (from kusto) it can be removed
				let actionType = event.NewActionType || event.ActionType;
				actionType =
					actionType.toLowerCase() === eventNames.wdavDetection.toLocaleLowerCase()
						? eventNames.wdavDetection
						: eventNames.wdavReport; // wdav lofis action type is 'WdavReport' / 'WdavDetection'
				return actionType;
			default:
				return etwType;
		}
	}

	// the description to be shown in the machine timeline row of the event
	getEtwMachineTimelineDescription(eventItem) {
		const etwTimelineDescription = getEtwDetailsObjectByType(
			this.etwDictionary,
			eventItem.etwType,
			'machineTimelineDescription'
		);
		if (etwTimelineDescription !== null) {
			return etwTimelineDescription(eventItem, eventItem.fullEtwType);
		}

		return null;
	}

	// the description prefix to be shown before the initiating process file element (if exists).
	getEtwMachineTimelineDescriptionPrefix(eventItem) {
		const etwTimelineDescription = getEtwDetailsObjectByType(
			this.etwDictionary,
			eventItem.etwType,
			'machineTimelineDescriptionPrefix'
		);
		if (etwTimelineDescription !== null) {
			return etwTimelineDescription(eventItem);
		}

		return null;
	}

	// the description suffix to be shown after the target file element (if exists).
	getEtwMachineTimelineDescriptionSuffix(eventItem) {
		const etwTimelineDescription = getEtwDetailsObjectByType(
			this.etwDictionary,
			eventItem.etwType,
			'machineTimelineDescriptionSuffix'
		);
		if (etwTimelineDescription !== null) {
			return etwTimelineDescription(eventItem);
		}

		return null;
	}

	// the FileName and the dir to be shown
	getEtwShowNodeContentOnly(eventItem) {
		const machineProcessTreeDetails = getEtwDetailsObjectByType(
			this.etwDictionary,
			eventItem.fullEtwType,
			'machineProcessTreeDetails'
		);
		if (machineProcessTreeDetails && machineProcessTreeDetails.showNodeContentOnly) {
			return machineProcessTreeDetails.showNodeContentOnly(eventItem);
		}
		return false;
	}

	// return true if the side pane event should be used in the machine timeline process tree
	getEtwEventAvoidUrlSidePane(eventItem) {
		const sidePaneDetails = getEtwDetailsObjectByType(
			this.etwDictionary,
			eventItem.fullEtwType,
			'sidePaneDetails'
		);
		if (sidePaneDetails && sidePaneDetails.isEtwValidForSidePane) {
			return sidePaneDetails.isEtwValidForSidePane(eventItem);
		}
		return false;
	}

	// the description to be shown near the node of the event in the machine timeline process tree
	getEtwMachineProcessTreeNodeDescription(event) {
		const etwMachineProcessTreeNodeDescription = getEtwDetailsObjectByType(
			this.etwDictionary,
			event.etwType,
			'machineProcessTreeNodeDescription'
		);

		if (etwMachineProcessTreeNodeDescription !== null) {
			return etwMachineProcessTreeNodeDescription(event);
		}

		return null;
	}

	// text to appear below the node icon instead of file name
	getEtwMachineProcessTreeNodeName(event) {
		return (
			getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'nodeName') ||
			event.filename ||
			'Unknown'
		);
	}

	getEtwMachineProcessTreeUndirectChildRemoteText(event) {
		return getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'undirectChildRemoteText');
	}

	getEtwMachineProcessTreeEncyclopediaLink(event) {
		return getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'EncyclopediaLink');
	}

	getEtwMachineProcessTreeThreat(event) {
		return getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'Threat');
	}

	// content to appear in the node (where we show the sha1, command,  etc
	getEtwMachineProcessTreeNodeContent(event) {
		return getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'nodeContent');
	}

	// hide indicator of the initiating process name in the machine timeline event row
	getEtwMachineTimelineHideInitiatingProcessInDescription(event) {
		const setting = getEtwDetailsObjectByType(
			this.etwDictionary,
			event.etwType,
			'machineTimelineHideInitiatingProcessInDescription'
		);

		// setting might be a function or constant
		return typeof setting === 'function' ? setting(event) : setting;
	}

	getEtwMachineTimelineHideUriInDescription(etwType) {
		return getEtwDetailsObjectByType(this.etwDictionary, etwType, 'machineTimelineHideUriInDescription');
	}

	getEtwSidePaneDetails(etwType) {
		return getEtwDetailsObjectByType(this.etwDictionary, etwType, 'sidePaneDetails');
	}

	getEtwAlertProcessTreeDetails(etwType) {
		return this.etwDictionary[etwType] && this.etwDictionary[etwType]['alertProcessTreeDetails']
			? this.etwDictionary[etwType]['alertProcessTreeDetails']
			: null;
	}

	getEtwSidePaneType(event) {
		// lets an etw event use side pane settings of an existing event type (for example CreateProcess)
		return event.EtwSidePaneDetails && event.EtwSidePaneDetails.sidePaneExistingType
			? event.EtwSidePaneDetails.sidePaneExistingType(event)
			: event.SidePaneType;
	}

	getEtwSidePaneIcon(etwType) {
		const etwIconDetails = this.getEtwIcons(etwType);
		return etwIconDetails.sidePaneIcon ? etwIconDetails.sidePaneIcon : etwIconDetails.timelineRowIcon;
	}

	getEtwSidePaneTitle(event, defaultTitle) {
		const sidepaneTitle =
			event.EtwSidePaneDetails && event.EtwSidePaneDetails.sidePaneTitle
				? event.EtwSidePaneDetails.sidePaneTitle(event)
				: null;

		return sidepaneTitle || defaultTitle;
	}

	getEtwSidePaneSectionTitle(event, defaultTitle) {
		return event.EtwSidePaneDetails && event.EtwSidePaneDetails.sectionTitle
			? event.EtwSidePaneDetails.sectionTitle
			: defaultTitle;
	}

	isSingelSectionEtwSidepane(event) {
		return event.EtwSidePaneDetails && event.EtwSidePaneDetails.isSingleSectionSidePane
			? event.EtwSidePaneDetails.isSingleSectionSidePane(event)
			: null;
	}

	getEtwSidePaneSections(event, defaultSection) {
		return event.EtwSidePaneDetails && event.EtwSidePaneDetails.sections
			? event.EtwSidePaneDetails.sections(event)
			: defaultSection;
	}

	// returns machine process tree node icon if defined for an etw event.
	// if not defined / was not an etw event / not a supported etw event - returns
	// the default icon or icon-settings.
	getEtwMachineProcessTreeNodeIcon(event) {
		const eventDefaultIcon = event.icon ? event.icon : 'icon-Settings';
		let etwNodeIcon;

		const etwProcessTreeNodeIcon = this.getEtwIcons(event.etwType).machineProcessTreeNodeIcon;
		if (etwProcessTreeNodeIcon) {
			etwNodeIcon = etwProcessTreeNodeIcon(event);
		}

		return etwNodeIcon ? etwNodeIcon : eventDefaultIcon;
	}

	isProcessTreeDisabled(event) {
		return getPropertyFromMachineProcessTreeDetails(this.etwDictionary, event, 'disableProcessTree');
	}

	getEtwNoProcessTreeElements(event) {
		if (!this.isProcessTreeDisabled(event)) {
			return null;
		}

		const noProcessTreeElements = getEtwDetailsObjectByType(
			this.etwDictionary,
			event.etwType,
			'machineTimelineNoProcesTreeElements'
		);

		return noProcessTreeElements ? noProcessTreeElements(event) : null;
	}

	// details column text (by default:  initiating parent process > initiating process > file name)
	getEtwDetailsColumnData(event) {
		const arrayOfNamesToAppearInDetailsColumn = [];

		// add initiating process parent name first if exists
		if (event.parentProcessName && !event.isParentProcessAbsent) {
			arrayOfNamesToAppearInDetailsColumn.push(event.parentProcessName);
		}

		// add initiating process name
		arrayOfNamesToAppearInDetailsColumn.push(event.processName || 'Unknown');

		// add current file name
		if (event.filename) {
			arrayOfNamesToAppearInDetailsColumn.push(event.filename);
		}

		// modify or add a name for specific event if needed
		const etwdetailsColumnData = getEtwDetailsObjectByType(
			this.etwDictionary,
			event.etwType,
			'machineProcessTreeDetailsColumnData'
		);
		if (etwdetailsColumnData !== null) {
			etwdetailsColumnData(event, arrayOfNamesToAppearInDetailsColumn);
		}

		const detailsColumn = arrayOfNamesToAppearInDetailsColumn.join(' > ');
		return detailsColumn;
	}

	getEtwMachineProcessTreeDetailsColumnDataSha(event) {
		const etwdetailsColumnDataSha = getEtwDetailsObjectByType(
			this.etwDictionary,
			event.etwType,
			'machineProcessTreeDetailsColumnDataSha'
		);
		if (etwdetailsColumnDataSha !== null) {
			return etwdetailsColumnDataSha(event);
		}
		return null;
	}
	getEtwAlertNodeUrl(node) {
		const processTreeDetails = getEtwDetailsObjectByType(
			this.etwDictionary,
			node.fullEtwType,
			'alertProcessTreeDetails'
		);
		if (processTreeDetails && processTreeDetails.getNodeLink) {
			return processTreeDetails.getNodeLink(node);
		}

		const Uri = getUriFromNode(node);
		if (Uri) {
			return this.urlsService.getUrlLink(Uri);
		}

		if (node.Sha1 && RegExpService.sha1.test(node.Sha1)) {
			return this.filesService.getFileLink(node.Sha1, null, node.FileName || node.filename);
		}

		if (node.Sha256 && RegExpService.sha256.test(node.Sha256)) {
			return this.filesService.getFileLink(node.Sha256, null, node.FileName || node.filename);
		}

		if (node.EtwEventPropertiesAsJson && node.EtwEventPropertiesAsJson.DeepLinkToO365Portal) {
			return node.EtwEventPropertiesAsJson.DeepLinkToO365Portal;
		}

		return '';
	}

	setEtwEventPropertiesFromRecord(itemToSet, record) {
		itemToSet.sha256 = record.TargetProcess_File_Sha256;
		itemToSet.sha1 = record.TargetProcess_File_Sha1;
		itemToSet.sha = itemToSet.sha1 || itemToSet.sha256;
		itemToSet.valid =
			itemToSet.sha &&
			(itemToSet.sha.length === 64 || // sha256
				(itemToSet.sha.length === 40 && RegExpService.sha1.test(itemToSet.sha1))); // sha1;
		itemToSet.isShaValid = itemToSet.valid;
		itemToSet.filename =
			record.TargetProcess_File_FileName || record.TargetProcess_ProcessName || record.FileName;
		itemToSet.path = record.TargetProcess_File_FilePath || record.RealPath;
		itemToSet.processName = record.InitiatingProcess_ProcessName;
		itemToSet.processId = record.InitiatingProcess_ProcessId;
		itemToSet.processCreationTime = record.InitiatingProcess_CreationTime;
		itemToSet.processCommand = record.InitiatingProcess_CommandLine;
		itemToSet.processPath = record.InitiatingProcess_File_FilePath;
		itemToSet.parentProcessName = record.InitiatingProcess_ParentProcessName;
		itemToSet.processSha1 = record.InitiatingProcess_File_Sha1;
		itemToSet.processSha256 = record.InitiatingProcess_File_Sha256;
	}
}

/* Machine timeline event type functions */
function isSmartScreenUserDecisionEvent(etwType) {
	return etwType === userDecisionEventTypeName;
}

function getFirewall5031Description(firewallEventProfiles) {
	let description = 'was blocked from accepting connections by Windows Firewall';
	if (firewallEventProfiles) {
		description += firewallEventProfiles.indexOf(',') > -1 ? ' profiles: ' : ' profile: ';
		description += firewallEventProfiles;
	}
	return description;
}

function getDeviceGuardMachineTimelineDescription(event, fullType) {
	if (fullType.indexOf('8022') > -1) {
		return 'packaged application was prevented from being deployed by AppLocker policy';
	}
	if (fullType.indexOf('8025') > -1) {
		return 'packaged application was prevented from executing by AppLocker policy';
	}
	if (fullType.indexOf('3079') > -1 || fullType.indexOf('3081') > -1) {
		return 'was prevented from executing by Applocker policy';
	}

	switch (fullType) {
		case 'AppLockerBlockExecutable':
			return 'process/DLL was prevented from executing by AppLocker policy';
		case 'AppLockerBlockScript':
			return 'installer/script was prevented from executing by AppLocker policy';
		case 'CodeIntegritySiPolicy':
			return 'was prevented from executing by DeviceGuard code integrity policy';
		default:
			return 'was blocked from executing by Device Guard';
	}
}

function getAppGuardContainerOperationMachineTimelineDescription(containerId, fullType) {
	if (!containerId) {
		return '';
	}

	let description = "Application Guard container '" + containerId.substring(0, 8) + "...' was ";
	switch (fullType) {
		case 'CreateVMEnd':
			description += 'created';
			break;
		case 'ContainerStopped':
			description += 'destroyed';
			break;
		case 'SuspendComputeSystemEnd':
			description += 'suspended';
			break;
		case 'ResumeComputeSystemEnd':
			description += 'resumed';
			break;
		case 'LaunchDocumentInContainer':
			description =
				"Browsing session was redirected to Application Guard Container '" +
				containerId.substring(0, 8) +
				"...'";
			break;
	}

	return description;
}

function exploitguardRemoteText(event) {
	return auditedOrBlocked(event.etwJsonProperties.IsAudit) + ' by Exploit Guard';
}

function auditedOrBlocked(isAudit, props?) {
	const useLowerCase = props && props.lowerCase;
	const isUsedInDescription = props && props.isUsedInDescription;
	const blockedOrAudited = isAudit ? 'Audited' : 'Blocked' + (isUsedInDescription ? ' from' : '');

	return isUsedInDescription || useLowerCase ? blockedOrAudited.toLowerCase() : blockedOrAudited;
}

function exploitGuardAlertProcessTreeDescription(node, eventDescription) {
	return (
		(node.InitiatingProcessName ? node.InitiatingProcessName : 'Unknown process') +
		' was ' +
		auditedOrBlocked(node.EtwEventPropertiesAsJson && node.EtwEventPropertiesAsJson.IsAudit, {
			isUsedInDescription: true,
		}) +
		' ' +
		eventDescription +
		' by ExploitGuard'
	);
}

function getExploitGuardHipsDescriptionPrefix(ruleId: string) {
	if (ruleId.toLowerCase() === exploitGuardInsideRules.WindowsCallsFromOfficeMacro) {
		return 'Office application';
	}
	return '';
}

function getExploitGuardHipsDescription(ruleId: string, isAudit: boolean) {
	const auditOrBlockedText: string = auditedOrBlocked(isAudit, { isUsedInDescription: true });
	switch (ruleId.toLowerCase()) {
		case exploitGuardInsideRules.OfficeInjection:
			return `Office application was ${auditOrBlockedText} injecting into other process`;
		case exploitGuardInsideRules.OfficeCreatingExecutable:
			return `Office application/Macro was ${auditOrBlockedText} creating executable content`;
		case exploitGuardInsideRules.OfficeCreatingChild:
			return `Office application was ${auditOrBlockedText} creating child process`;
		case exploitGuardInsideRules.ExecutingPayLoad:
			return `js/vbs was ${auditOrBlockedText} executing payload`;
		case exploitGuardInsideRules.RunningObfuscatedCode:
			return `was ${auditOrBlockedText} running obfuscated js/vbs/ps/macro code`;
		case exploitGuardInsideRules.ExecutingEmailAttachment:
			return `was ${auditOrBlockedText} executing email attachment`;
		case exploitGuardInsideRules.FolderGuard:
			return `was ${auditOrBlockedText} trying to make changes to files in folder `;
		case exploitGuardInsideRules.WindowsCallsFromOfficeMacro:
			return ` was ${auditOrBlockedText} by Exploit Guard opening`;
		case exploitGuardInsideRules.CredentilStealingFromLsass:
			return `was ${auditOrBlockedText} extracting credentials from`;
		case exploitGuardInsideRules.RunningUntrustedExecutable:
		case exploitGuardInsideRules.LaunchingUnverifiedWindowsExecutable:
		case exploitGuardInsideRules.ProcessCreationFromWMI:
		case exploitGuardInsideRules.UntrustedProcessFromUSB:
			return '';
		default:
			return `was ${auditOrBlockedText}`;
	}
}

function getUriFromNode(node) {
	return (node.EtwEventPropertiesAsJson && node.EtwEventPropertiesAsJson.Uri) || node.Uri;
}

function getExploitGuardHipsDescriptionSuffix(ruleId: string, path: string, isAudit: boolean, props) {
	const auditOrBlockedText = auditedOrBlocked(isAudit, { lowerCase: true });
	let specificRuleSuffix = '';
	ruleId = ruleId.toLowerCase();

	switch (ruleId) {
		case exploitGuardInsideRules.ExecutingPayLoad:
			specificRuleSuffix = 'downloaded from Internet ';
			break;
		case exploitGuardInsideRules.FolderGuard:
			if (!path) {
				break;
			}

			if (!props.trimPathInDescription) {
				// do not trim the path (seen in full in alert page)
				specificRuleSuffix = path + ' ';
				break;
			}

			// trim path from the beginning
			const pathStart = Math.max(0, path.length - 40);
			specificRuleSuffix = (pathStart > 0 ? '...' : '') + path.substring(pathStart) + ' ';
			break;
		case exploitGuardInsideRules.RunningUntrustedExecutable:
			specificRuleSuffix = `, a new, rare, and untrusted application, was ${auditOrBlockedText}`;
			break;
		case exploitGuardInsideRules.LaunchingUnverifiedWindowsExecutable:
			specificRuleSuffix = `was ${auditOrBlockedText} exhibiting ransomware characteristics`;
			break;
		case exploitGuardInsideRules.ProcessCreationFromWMI:
			specificRuleSuffix = `was ${auditOrBlockedText} by Exploit Guard while being launched by PsExec or WMI`;
			break;
		case exploitGuardInsideRules.WindowsCallsFromOfficeMacro:
			specificRuleSuffix = `, which ran macro code that called Win32 APIs`;
			break;
		case exploitGuardInsideRules.UntrustedProcessFromUSB:
			specificRuleSuffix = `was ${auditOrBlockedText} running from a USB drive`;
			break;
	}

	const isKnownRule = find(exploitGuardInsideRules, val => val === ruleId);
	if (isKnownRule) {
		if (
			ruleId === exploitGuardInsideRules.ProcessCreationFromWMI ||
			ruleId === exploitGuardInsideRules.WindowsCallsFromOfficeMacro
		) {
			return specificRuleSuffix;
		}
		return `${specificRuleSuffix} by ExploitGuard`;
	} else {
		return `by ExploitGuard using rule "${ruleId.toUpperCase()}"`;
	}
}

function isExploitGuardRuleHideInitiatingProcess(ruleId: string) {
	switch (ruleId.toLowerCase()) {
		case exploitGuardInsideRules.RunningUntrustedExecutable:
		case exploitGuardInsideRules.LaunchingUnverifiedWindowsExecutable:
		case exploitGuardInsideRules.ProcessCreationFromWMI:
		case exploitGuardInsideRules.UntrustedProcessFromUSB:
			return true;
	}
	return false;
}

function isWdavRegistryEvent(etwJsonProperties) {
	return etwJsonProperties && etwJsonProperties.RegistryKey;
}

function isWdavServiceEvent(etwJsonProperties) {
	return etwJsonProperties && etwJsonProperties.Service;
}

function getWdavRegistryDescription(etwJsonProperties) {
	// we show value data if exists - otherwise value name if exists - otherwise registry key.
	let registryDescriptionToDisplay =
		etwJsonProperties.RegistryValueData ||
		etwJsonProperties.RegistryValueName ||
		etwJsonProperties.RegistryKey;
	if (registryDescriptionToDisplay.length > 40) {
		registryDescriptionToDisplay =
			'...' + registryDescriptionToDisplay.substring(registryDescriptionToDisplay.length - 40);
	}
	registryDescriptionToDisplay += ' ';

	return registryDescriptionToDisplay;
}

function truncateStringWithElipsis(text: string, maxLength: number = 70): string {
	return text !== null ? text.substring(0, maxLength) + (text.length > maxLength ? '.....' : '') : text;
}

function getWdavRegistrySidePaneTitle(etwJsonProperties) {
	if (etwJsonProperties.RegistryValueData) {
		return 'Registry value data detection';
	}

	if (etwJsonProperties.RegistryValueName) {
		return 'Registry value name detection';
	}

	return 'Registry key detection';
}

function getTrimmedUriIfTooLong(Uri, props) {
	return Uri.length > props.desiredMaxLength ? Uri.substring(0, props.desiredMaxLength - 3) + '...' : Uri;
}

function smartScreenLookupEventDescription(experience) {
	return ' was blocked' + (experience ? ' as ' + experience + ' ' : ' ') + 'by SmartScreen';
}

/* Side pane sections */

// Token modification sidepane sections
function getTokenModificationSidePaneSections(event) {
	const tokenModificationDetails = JSON.parse(event.EtwEventPropertiesAsJson.TokenModificationProperties);
	const tokenSidePanePropertiesArray = [
		{
			key: 'Token modification time',
			value: event.ActionTime,
			type: 'datetime',
			order: 1,
		},
		{
			key: 'New integrity level',
			value: tokenModificationDetails.currentTokenIntegrityLevelName.toUpperCase(),
			type: 'primitive',
			order: 3,
		},
		{
			key: 'Previous integrity level',
			value: tokenModificationDetails.originalTokenIntegrityLevelName.toUpperCase(),
			type: 'primitive',
			order: 4,
		},
	];
	if (tokenModificationDetails.isChangedToSystemToken) {
		tokenSidePanePropertiesArray.push({
			key: 'New token',
			value: 'SYSTEM',
			type: 'primitive',
			order: 2,
		});
	}

	if (tokenModificationDetails.privilegesFlags.length > 0) {
		tokenSidePanePropertiesArray.push({
			key: 'Added privileges',
			value: tokenModificationDetails.privilegesFlags.join('\n'),
			type: 'scrollable-block',
			order: 5,
		});
	}

	return tokenSidePanePropertiesArray;
}

// ProcessInjection sidepane sections
function getProcessInjectionSidePaneSections(event) {
	const targetProcessProperties = event.EtwEventPropertiesAsJson.TargetProcess;
	return [
		{
			key: 'Process injection time',
			value: event.ActionTime,
			type: 'datetime',
			order: 1,
		},
		{
			key: 'Process execution time',
			value: new Date(targetProcessProperties.CreationTimeUtc),
			type: 'datetime',
			order: 2,
		},
		{
			key: 'Folder path',
			value: event.FolderPath,
			type: 'primitive',
			order: 3,
		},
		{
			key: 'Process ID',
			value: targetProcessProperties.ProcessId,
			type: 'primitive',
			order: 4,
		},
		{
			key: 'Command line',
			value: targetProcessProperties.CommandLine,
			type: 'scrollable-block',
			order: 5,
		},
	];
}

function getOpenProcessSidePaneSections(event) {
	const targetProcessProperties = event.EtwEventPropertiesAsJson.TargetProcess;
	return [
		{
			key: 'Open process handle time',
			value: event.ActionTime,
			type: 'datetime',
			order: 1,
		},
		{
			key: 'Process execution time',
			value: new Date(targetProcessProperties.CreationTimeUtc),
			type: 'datetime',
			order: 2,
		},
		{
			key: 'Folder path',
			value: event.FolderPath,
			type: 'primitive',
			order: 3,
		},
		{
			key: 'User',
			value: event.ProcessAccount,
			icon: 'icon-Contact event-side-pane-user-icon',
			type: 'primitive',
			style: 'white-space',
			order: 4,
		},
		{
			key: 'Process ID',
			value: targetProcessProperties.ProcessId,
			type: 'primitive',
			order: 5,
		},
		{
			key: 'Command line',
			value: targetProcessProperties.CommandLine,
			type: 'scrollable-block',
			order: 6,
		},
	];
}

// MemAllocForHighRiskProcesses side pane sections
function getMemAllocForHighRiskProcessesSidePaneSections(event) {
	return [
		{
			key: 'Memory allocation time',
			value: event.ActionTime,
			type: 'datetime',
			order: 1,
		},
		{
			key: 'Process execution time',
			value: event.InitiatingProcessCreationTime,
			type: 'datetime',
			order: 2,
		},
	];
}

// WDAVDetection side pane sections
function getWDAVDetectionSidePaneSections(event) {
	let remediationStatus = null;
	let wasActive = null;
	let threatName = null;
	let remediationAction = null;
	if (event.EtwEventPropertiesAsJson) {
		if (event.EtwEventPropertiesAsJson.WasRemediated !== null) {
			remediationStatus = event.EtwEventPropertiesAsJson.WasRemediated ? 'Success' : 'Failed';
		}

		if (event.EtwEventPropertiesAsJson.WasExecutingWhileDetected !== null) {
			wasActive = event.EtwEventPropertiesAsJson.WasExecutingWhileDetected ? 'True' : 'False';
		}

		threatName = event.EtwEventPropertiesAsJson.ThreatName;

		if (event.EtwEventPropertiesAsJson.Action !== null) {
			switch (event.EtwEventPropertiesAsJson.Action) {
				case 1:
					remediationAction = 'Clean';
					break;
				case 2:
					remediationAction = 'Quarantine';
					break;
				case 3:
					remediationAction = 'Remove';
					break;
				case 10:
					remediationAction = 'Block';
					break;
				default:
					remediationAction = null;
			}
		}
	}

	return [
		{
			key: 'Block time',
			value: event.ActionTime,
			type: 'datetime',
			order: 1,
		},
		{
			key: 'Folder path',
			value: event.FolderPath,
			type: 'primitive',
			order: 2,
		},
		{
			key: 'Container',
			value: event.EtwEventPropertiesAsJson.Container,
			type: 'primitive',
			order: 3,
		},
		{
			key: 'Registry key',
			value:
				event.EtwEventPropertiesAsJson && event.EtwEventPropertiesAsJson.RegistryKey
					? event.EtwEventPropertiesAsJson.RegistryKey
					: null,
			type: 'scrollable-block',
			order: 4,
		},
		{
			key: 'Value name',
			value:
				event.EtwEventPropertiesAsJson && event.EtwEventPropertiesAsJson.RegistryValueName
					? event.EtwEventPropertiesAsJson.RegistryValueName
					: null,
			type: 'scrollable-block',
			order: 5,
		},
		{
			key: 'Value data',
			value:
				event.EtwEventPropertiesAsJson && event.EtwEventPropertiesAsJson.RegistryValueData
					? event.EtwEventPropertiesAsJson.RegistryValueData
					: null,
			type: 'scrollable-block',
			order: 6,
		},
		{
			key: 'Threat name',
			value: threatName,
			type: 'primitive',
			link: {
				icon: 'ReadingMode',
				href:
					'https://www.microsoft.com/security/portal/threat/encyclopedia/Entry.aspx?Name=' +
					threatName,
				newWindow: true,
			},
			order: 7,
		},
		{
			key: 'Remediation action',
			value: remediationAction,
			type: 'primitive',
			order: 8,
		},
		{
			key: 'Remediation status',
			value: remediationStatus,
			type: 'primitive',
			order: 9,
		},
		{
			key: 'Was active',
			value: wasActive,
			type: 'primitive',
			order: 10,
		},
	];
}

// 	/* End Side pane sections */

// valid for sidePane
function isNodeValidForSidePane(node) {
	return (
		node &&
		(node.Sha1 || node.FileName || node.IpAddress || node.Domain || node.RegistryOperation || node.Url)
	);
}

// 	// Gets etw type name and the requested detail
// 	// return an object from etw dictionary contains the requested details or the default behavior if this type is not supported
function getEtwDetailsObjectByType(etwDictionary, etwType, requestedObjectDetail) {
	if (!etwDictionary.hasOwnProperty(etwType) || etwDictionary[etwType] === null) {
		return etwDictionary.Default[requestedObjectDetail];
	}

	if (etwDictionary[etwType].hasOwnProperty(requestedObjectDetail)) {
		return etwDictionary[etwType][requestedObjectDetail];
	}

	return etwDictionary.Default[requestedObjectDetail];
}

// get a property out of the machine process tree details object if defined in etw event configuration (in dictionary above)
function getPropertyFromMachineProcessTreeDetails(etwDictionary, event, propertyName) {
	const machineProcessTreeDetails = getEtwDetailsObjectByType(
		etwDictionary,
		event.etwType,
		'machineProcessTreeDetails'
	);
	if (machineProcessTreeDetails && machineProcessTreeDetails[propertyName]) {
		return machineProcessTreeDetails[propertyName](event);
	}

	return null;
}

function resolveUserName(accountName, accountDomainName) {
	if (accountName == null && accountDomainName == null) {
		return '';
	}

	if (accountDomainName == null) {
		return accountName;
	}

	return accountDomainName + '\\' + accountName;
}
