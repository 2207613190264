/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dataviews/components/dataview.component.ngfactory";
import * as i2 from "../../../dataviews/components/dataview.component";
import * as i3 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i4 from "../../../dialogs/services/dialogs.service";
import * as i5 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i6 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../dataviews/services/dataview-repository.service";
import * as i9 from "../../../insights/services/app-insights.service";
import * as i10 from "../../../dialogs/services/errors-dialog.service";
import * as i11 from "../../../global_entities/services/entity-panels.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i13 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "../../../dialogs/confirm/confirm.service";
import * as i17 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i18 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i19 from "@angular/common";
import * as i20 from "../../applications/services/application.fields.service";
import * as i21 from "./incident.apps.component";
import * as i22 from "../../../global_entities/services/global-entity-types.service";
import * as i23 from "../../../global_entities/services/activated-entity.service";
import * as i24 from "./incident.entity.component";
import * as i25 from "../../../insights/services/performance.scc.service";
var styles_IncidentAppsComponent = [];
var RenderType_IncidentAppsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentAppsComponent, data: {} });
export { RenderType_IncidentAppsComponent as RenderType_IncidentAppsComponent };
function View_IncidentAppsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "dataview", [["class", "wcd-full-height"], ["className", "no-left-padding wcd-entity-page-content"], ["id", "incident-apps"]], null, [[null, "onTableRenderComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onTableRenderComplete" === en)) {
        var pd_0 = (_co.onTableRenderComplete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataViewComponent_0, i1.RenderType_DataViewComponent)), i0.ɵdid(1, 245760, null, 0, i2.DataViewComponent, [i3.WicdSanitizerService, i4.DialogsService, i5.PanelService, i6.PreferencesService, i7.ActivatedRoute, i8.DataviewRepositoryService, i7.Router, i0.ChangeDetectorRef, i9.AppInsightsService, i10.ErrorsDialogService, i11.EntityPanelsService, i12.FeaturesService, i13.AppContextService, i14.Paris, i15.LiveAnnouncer, i16.ConfirmationService, i17.I18nService], { allowFilters: [0, "allowFilters"], className: [1, "className"], id: [2, "id"], removePadding: [3, "removePadding"], label: [4, "label"], responsiveActionBar: [5, "responsiveActionBar"], fields: [6, "fields"], repository: [7, "repository"], entityType: [8, "entityType"], fixedOptions: [9, "fixedOptions"] }, { onTableRenderComplete: "onTableRenderComplete" }), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = "no-left-padding wcd-entity-page-content"; var currVal_2 = "incident-apps"; var currVal_3 = true; var currVal_4 = i0.ɵunv(_v, 1, 4, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "incident.apps.title")); var currVal_5 = true; var currVal_6 = _co.fields; var currVal_7 = _co.incidentAppsRepository; var currVal_8 = _co.applicationEntityType; var currVal_9 = _co.dataviewFixedOptions; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, null); }
function View_IncidentAppsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-full-height wcd-flex-center-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "div", [["class", "widget-nodata-message"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "incident.apps.titleNoData")); _ck(_v, 4, 0, currVal_0); }); }
export function View_IncidentAppsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i18.I18nPipe, [i17.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentAppsComponent_1)), i0.ɵdid(3, 16384, null, 0, i19.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["noData", 2]], null, 0, null, View_IncidentAppsComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.incidentAppsRepository == null) ? null : _co.incidentAppsRepository.sourceItem); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_IncidentAppsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "incident-apps", [], null, null, null, View_IncidentAppsComponent_0, RenderType_IncidentAppsComponent)), i0.ɵprd(512, null, i20.ApplicationFieldsService, i20.ApplicationFieldsService, [i17.I18nService]), i0.ɵdid(2, 114688, null, 0, i21.IncidentAppsComponent, [i14.Paris, i22.GlobalEntityTypesService, i20.ApplicationFieldsService, i23.ActivatedEntity, [2, i24.IncidentEntityComponent], i25.PerformanceSccService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var IncidentAppsComponentNgFactory = i0.ɵccf("incident-apps", i21.IncidentAppsComponent, View_IncidentAppsComponent_Host_0, { incident: "incident" }, {}, []);
export { IncidentAppsComponentNgFactory as IncidentAppsComponentNgFactory };
