import { Injectable } from '@angular/core';
import { TvmColorService, TvmColor } from './tvm-color.service';
import { WeaknessSeverity } from '@wcd/domain';
import { KnownColorName } from '../../shared/models/colors/known-colors.models';

export const severityTypeColorName: ReadonlyMap<WeaknessSeverity, KnownColorName> = new Map<WeaknessSeverity, KnownColorName>([
	[WeaknessSeverity.Critical, 'darkBrown'],
	[WeaknessSeverity.High, 'highSeverity'],
	[WeaknessSeverity.Medium, 'mediumSeverity'],
	[WeaknessSeverity.Low, 'lowSeverity'],
]);

@Injectable()
export class TvmWeaknessSeverityService {
	public backgroundColorsClassMap: ReadonlyMap<TvmColor, string>;
	private fontColorsClassMap: ReadonlyMap<TvmColor, string>;

	constructor(tvmColorService: TvmColorService) {
		this.fontColorsClassMap = new Map<TvmColor,string>(tvmColorService.fontColorsClassMap);
		this.backgroundColorsClassMap = new Map<TvmColor,string>(tvmColorService.backgroundColorsClassMap);
	}

	getWeaknessColorClass(severity: WeaknessSeverity): string {

		if (severity) {
			return this.fontColorsClassMap.get(this.mapSeverityToColor(severity));
		}
		return null;
	}

	mapSeverityToColor(severity: WeaknessSeverity): TvmColor {
		switch (severity) {
			case WeaknessSeverity.Critical:
				return TvmColor.CriticalRisk;
			case WeaknessSeverity.High:
				return TvmColor.HighRisk;
			case WeaknessSeverity.Medium:
				return TvmColor.MediumRisk;
			case WeaknessSeverity.Low:
				return TvmColor.LowRisk;
			default:
				return null;
		}
	}
}
