import { padStart, round } from 'lodash-es';

declare const moment: typeof import('moment');

const AVG_MONTH_DAYS = 365 / 12;

/**
 * Formats an int representing number of seconds into a time string, such as 1:34h, 30m, 20s, etc.
 */
export function prettyTime(
	diffSeconds: number,
	longForm: boolean = false,
	i18nDictionary: { [key: string]: string } = null
): string {
	if (isNaN(diffSeconds)) {
		throw new Error('Invalid value for prettyTime, diffSeconds should be a number.');
	}

	i18nDictionary = i18nDictionary || {};

	const pluralHours = i18nDictionary.hours || 'Hours';
	const singularHour = i18nDictionary.hour || 'Hour';
	const hourShortText = i18nDictionary.h || 'h';
	const duration = moment.duration(diffSeconds * 1000);
	const days = duration.asDays();
	const roundedDays = Math.sign(days) * Math.floor(Math.abs(days));
	if (roundedDays) {
		if (Math.abs(roundedDays) > 1) {
			return prettyDays(roundedDays, longForm, i18nDictionary);
		}

		const hours = duration.asHours();
		return (
			Math.sign(hours) * Math.floor(Math.abs(hours)) + (longForm ? ' ' + pluralHours : hourShortText)
		);
	}

	const pluralMinutes = i18nDictionary.minutes || 'Minutes';
	const singularMinute = i18nDictionary.minute || 'Minute';
	const minuteShortText = i18nDictionary.M || 'm';
	const hours: number = duration.hours(),
		minutes: number = duration.minutes(),
		seconds: number = duration.seconds(),
		milliseconds: number = duration.milliseconds();

	if (hours) {
		if (longForm) {
			return (
				hours +
				(hours > 1 ? ' ' + pluralHours : ' ' + singularHour) +
				(minutes ? ', ' + minutes + (minutes > 1 ? ' ' + pluralMinutes : singularMinute) : '')
			);
		}
		return hours + (minutes ? ':' + padStart(minutes.toString(), 2, '0') : ':00') + hourShortText;
	}

	if (minutes) {
		const minutesStr = minutes + (seconds ? `:${padStart(seconds.toString(), 2, '0')}` : ':00');

		if (longForm) {
			return `${minutesStr} ${seconds || minutes > 1 ? pluralMinutes : singularMinute}`;
		}

		return minutesStr + minuteShortText;
	}

	const pluralSeconds = i18nDictionary.seconds || 'Seconds';
	const singularSecond = i18nDictionary.second || 'Second';
	const secondShortText = i18nDictionary.s || 's';

	if (seconds) {
		if (longForm) {
			return seconds + ' ' + (seconds > 1 ? pluralSeconds : singularSecond);
		}

		return seconds + secondShortText;
	}

	if (milliseconds) {
		return (milliseconds / 1000).toFixed(2) + secondShortText;
	}

	return '0';
}

export function prettyDays(
	days: number,
	longForm: boolean = false,
	i18nDictionary: { [key: string]: string } = null
): string {
	i18nDictionary = i18nDictionary || {};

	const pluralYears = i18nDictionary.years || 'Years';
	const yearShortText = i18nDictionary.y || 'y';

	const years = round(days / 365, 1);

	if (Math.abs(years) > 1) {
		return `${years}${longForm ? ' ' + pluralYears : yearShortText}`;
	}

	const pluralMonths = i18nDictionary.months || 'Months';
	const monthShortText = i18nDictionary.m || 'm';

	const months = round(days / AVG_MONTH_DAYS, 1);

	if (Math.abs(months) > 1) {
		return `${months}${longForm ? ' ' + pluralMonths : monthShortText}`;
	}

	const pluralDays = i18nDictionary.days || 'Days';
	const singularDay = i18nDictionary.day || 'Day';
	const dayShortText = i18nDictionary.d || 'd';

	return Math.abs(days) === 1
		? `${days} ${singularDay}`
		: `${round(days, 1)}${longForm ? ' ' + pluralDays : dayShortText}`;
}
