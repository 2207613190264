var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
var ɵ0 = function (itemData) { return ({
    Upn: itemData,
}); };
var IncidentHistoryItem = /** @class */ (function (_super) {
    __extends(IncidentHistoryItem, _super);
    function IncidentHistoryItem() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.icon = 'comment';
        return _this;
    }
    __decorate([
        EntityField({
            data: 'CreatedBy',
            parse: ɵ0,
        }),
        __metadata("design:type", Object)
    ], IncidentHistoryItem.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'NewValue' }),
        __metadata("design:type", String)
    ], IncidentHistoryItem.prototype, "message", void 0);
    __decorate([
        EntityField({ data: 'Timestamp' }),
        __metadata("design:type", Date)
    ], IncidentHistoryItem.prototype, "timestamp", void 0);
    IncidentHistoryItem = __decorate([
        ValueObject({
            singularName: "Incident's history item",
            pluralName: "Incident's history items",
        })
    ], IncidentHistoryItem);
    return IncidentHistoryItem;
}(ModelBase));
export { IncidentHistoryItem };
export { ɵ0 };
