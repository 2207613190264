import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { NetworkEndpointEntityTypeService } from './services/network-endpoint.entity-type.service';
import { NetworkEndpointEntityPanelComponent } from './components/network-endpoint.entity-panel.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [NetworkEndpointEntityTypeService],
	declarations: [NetworkEndpointEntityPanelComponent],
	entryComponents: [NetworkEndpointEntityPanelComponent],
})
export class NetworkEndpointEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(NetworkEndpointEntityTypeService);
	}
}
