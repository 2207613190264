import { SevilleModule } from '../../app/seville/seville.module';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

SevilleModule.controller('seville.onboarding.download.warning', onboardingTileDismissModalController);

onboardingTileDismissModalController.$inject = ['$scope', 'appInsights', '$uibModalInstance'];

function onboardingTileDismissModalController($scope, appInsights: AppInsightsService, $modalInstance) {
	$scope.ok = function() {
		$modalInstance.close();
		appInsights.trackEvent('EndpointOnboarding-ProceedAnyway', {
			Page: 'OnboardingDownloadWarning',
		});
	};

	$scope.cancel = function() {
		$modalInstance.dismiss();
		appInsights.trackEvent('EndpointOnboarding-Cancel', { Page: 'OnboardingDownloadWarning' });
	};
}
