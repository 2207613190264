import { ComponentRef, Injectable, Injector } from '@angular/core';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationCommentsPanelComponent } from '../../investigations_common/components/investigation-comments.panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { InvestigationStatus, MtpInvestigation } from '@wcd/domain';
import { InvestigationsBackendService } from '../../investigations/services/investigations.backend.service';
import { CancelInvestigationModalComponent } from '../../investigations_common/components/cancel-investigation.modal';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { never, Observable, of } from 'rxjs';
import { DataEntityType, Paris } from '@microsoft/paris';
import { TagsStore } from '../../../tags/services/tags.store';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Router } from '@angular/router';
import { catchError, mergeMap } from 'rxjs/operators';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { MtpInvestigationGraphDataModel } from '../../../graph/mtp-investigation/models/mtp-investigation-graph-data.model';
import { compact, keyBy } from 'lodash-es';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { sccHostService } from '@wcd/scc-interface';

export const INVESTIGATION_REFRESH_RATE = 5000;
const SEVILLE_STATUS_TO_STATUS = keyBy(
	(<DataEntityType>InvestigationStatus).entityConfig.values,
	'sevilleStatusId'
);

@Injectable()
export class MtpInvestigationsService {
	private _commentsPanel: ComponentRef<InvestigationCommentsPanelComponent>;
	constructor(
		private dialogsService: DialogsService,
		private authService: AuthService,
		private tagsStore: TagsStore,
		private paris: Paris,
		private injector: Injector,
		private router: Router,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private backendService: InvestigationsBackendService,
		private pollingService: PollingService
	) {}

	showCurrentInvestigationCommentsPanel(
		investigation: MtpInvestigation,
		investigationGraphData: MtpInvestigationGraphDataModel
	) {
		const panelSettings: PanelSettings = {
			id: 'investigation-comments-panel',
			type: PanelType.medium,
			isModal: true,
			showOverlay: false,
			isBlocking: true,
			noBodyPadding: true,
			scrollBody: false,
		};

		const panelInputs: { [index: string]: any } = {
			investigation: investigation,
			investigationGraphData: investigationGraphData,
		};

		this.dialogsService
			.showPanel(InvestigationCommentsPanelComponent, panelSettings, panelInputs)
			.subscribe((panel: ComponentRef<InvestigationCommentsPanelComponent>) => {
				this._commentsPanel = panel;

				panel.onDestroy(() => {
					this._commentsPanel = null;
				});
			});
	}

	getInvestigationPolling(investigationId: string | number): Observable<MtpInvestigation | Error> {
		const poll$: Observable<MtpInvestigation | Error> = this.pollingService
			.poll(0, INVESTIGATION_REFRESH_RATE)
			.pipe(
				mergeMap(() =>
					this.paris
						.getItemById(MtpInvestigation, investigationId, null, {
							tenantId: sccHostService.loginUser.tenantId,
						})
						.pipe(
							catchError(error => {
								if (
									error.status === 403 &&
									this.featuresService.isEnabled(Feature.RbacMachineGroups)
								) {
									const rbacService = this.injector.get(RbacService);
									rbacService.addToExposureCache(
										<DataEntityType>MtpInvestigation,
										String(investigationId),
										{ isExposed: false }
									);
									rbacService.showNoAccessModal(
										this.i18nService.strings.rbac_accessDenied_description_investigation
									);
									this.router.navigate(['/investigations']);
									return never();
								} else {
									console.warn(
										`Investigation polling failed. trying again in ${INVESTIGATION_REFRESH_RATE /
											1000} seconds.`
									);
									return of(error);
								}
							})
						)
				)
			);

		return poll$;
	}

	getInvestigationActions(investigation: MtpInvestigation): Array<ItemActionModel> {
		return this.getInvestigationsActions([investigation]);
	}

	getInvestigationsActions(investigations: Array<MtpInvestigation>): Array<ItemActionModel> {
		const actions: Array<ItemActionModel> = [
			// new ItemActionModel({
			// 	id: 'investigationTags',
			// 	rbac: ['alertsInvestigation'],
			// 	nameKey: 'tags.manage',
			// 	type: ItemActionType.Tags,
			// 	icon: 'tag',
			// 	refreshOnResolve: true,
			// 	options: {
			// 		tags: this.getInvestigationTagsSettings(investigations),
			// 	},
			// }),
		];

		if (
			investigations.every(
				investigation => investigation.isRunning && !investigation.isOfficeInvestigation
			)
		)
			actions.push(this._investigationDataViewActions.cancel);

		return compact(actions);
	}

	private _investigationDataViewActionsIndex: { [actionId: string]: ItemActionModel };

	private get _investigationDataViewActions(): { [actionId: string]: ItemActionModel } {
		if (!this._investigationDataViewActionsIndex) {
			const investigationActions = compact([
				!this.authService.currentUser.isReadonly
					? {
							id: 'cancel',
							nameKey: 'cancel',
							icon: 'stopInvestigation',
							method: this.cancelInvestigations.bind(this),
							tooltip: this.i18nService.strings.investigations_actions_cancel_tooltip,
							refreshOnResolve: true,
							rbac: [RbacMdeAllowedActions.alertsInvestigation],
					  }
					: null,
			]).map(itemActionConfig => new ItemActionModel(itemActionConfig));

			this._investigationDataViewActionsIndex = keyBy(investigationActions, 'id');
		}

		return this._investigationDataViewActionsIndex;
	}

	cancelInvestigations(investigations: Array<MtpInvestigation>, options?: Object): Promise<any> {
		const runningInvestigations: Array<MtpInvestigation> =
			investigations &&
			investigations.filter(
				investigation => investigation.isRunning && !investigation.isOfficeInvestigation
			);

		return new Promise((resolve, reject) => {
			this.getCancelInvestigationComment(
				'investigation',
				runningInvestigations ? runningInvestigations.length : null
			).then(
				(cancelData: { reason: number; comment: string }) => {
					this.backendService
						.cancelInvestigations(
							runningInvestigations
								? runningInvestigations.map(investigation => investigation.id)
								: null,
							cancelData,
							options
						)
						.subscribe(
							(result: { count: number }) => {
								const count: number =
										result && result.count !== undefined
											? result.count
											: (investigations && investigations.length) || 0,
									notificationText: string = count
										? count > 1
											? this.i18nService.get(
													'investigations_actions_cancel_successMessage_plural',
													{
														investigationCount: count,
													}
											  )
											: this.i18nService.strings
													.investigations_actions_cancel_successMessage_singular
										: this.i18nService.strings
												.investigations_actions_cancel_successMessage_general;

								this.dialogsService.showSnackbar({
									text: notificationText,
									icon: 'stopInvestigation',
								});

								resolve(result);
							},
							error => {
								const title = this.i18nService.strings
									.investigations_actions_cancel_errorMessage;

								this.dialogsService.showError({
									title: title,
									data: error,
								});

								reject(title);
							}
						);
				},
				error => reject(error)
			);
		});
	}

	private getCancelInvestigationComment(
		cancelReasonsType: string,
		investigationCount: number,
		hostName?: string
	): Promise<{ reason?: number; comment: string }> {
		return new Promise((resolve, reject) => {
			let modal: ComponentRef<any>;

			this.dialogsService
				.showModal(
					CancelInvestigationModalComponent,
					{
						id: 'cancel-investigation-modal',
						dimensions: new DimensionsModel(480, 350),
						title:
							cancelReasonsType === 'host'
								? investigationCount > 1
									? this.i18nService.strings
											.investigations_actions_cancel_approvalMessage_plural_host
									: this.i18nService.strings
											.investigations_actions_cancel_approvalMessage_singular_host
								: investigationCount > 1
								? this.i18nService.strings
										.investigations_actions_cancel_approvalMessage_plural
								: this.i18nService.strings
										.investigations_actions_cancel_approvalMessage_singular,
					},
					{
						investigationCount: investigationCount,
						reasonsType: cancelReasonsType,
						confirm: (cancelData: { reason?: number; comment: string }) => {
							resolve(cancelData);
							modal.destroy();
						},
						cancel: () => modal.destroy(),
						hostName: hostName,
					}
				)
				.subscribe(_modal => {
					modal = _modal;
					_modal.onDestroy(() => reject());
				});
		});
	}

	/**
	 * This is only for AngularJS, which can't access Paris directly.
	 * @param {string} investigationStatusTypeName
	 * @returns {InvestigationStatus}
	 */
	getInvestigationStatusByTypeName(investigationStatusTypeName: string): InvestigationStatus {
		return this.paris.getValue(
			InvestigationStatus,
			(investigationStatus: InvestigationStatus) =>
				investigationStatus.type.toLowerCase() === investigationStatusTypeName.toLowerCase()
		);
	}

	static getInvestigationStatusBySevilleStatus(sevilleStatus: string): InvestigationStatus {
		const statusIdInt: number = parseInt(sevilleStatus, 10);
		if (isNaN(statusIdInt)) {
			return null;
		}

		return SEVILLE_STATUS_TO_STATUS[statusIdInt];
	}

	static getInvestigationLink(investigation: {
		isOfficeInvestigation?: boolean;
		isLiveResponse?: boolean;
		id: number | string;
		externalInvestigationPageUrl?: string;
	}) {
		const url =
			investigation && investigation.isOfficeInvestigation ? 'mtp-investigation' : 'investigation';
		return investigation
			? (investigation.isLiveResponse && `/live-response/${investigation.id}`) ||
					`/${url}/${investigation.id}`
			: null;
	}
}
