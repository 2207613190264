import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';

interface LetContext<T> {
	ngLet: T;
}

/**
 * Allows declaring variables inside templates.
 * Example usages:
 * 1. `async` pipe, when you don't have a condition for `*ngIf`.
 * 	  To avoid creating multiple subscriptions (when you want to use the async pipe and
 * 	  use the variable in multiple places in the template)
 * 2. Local template variables.
 *
 * This code is taken from the following article: https://medium.com/@AustinMatherne/angular-let-directive-a168d4248138
 */
@Directive({
	// tslint:disable-next-line:directive-selector
	selector: '[ngLet]',
})
export class LetDirective<T> {
	private _context: LetContext<T> = { ngLet: null };

	constructor(_viewContainer: ViewContainerRef, _templateRef: TemplateRef<LetContext<T>>) {
		_viewContainer.createEmbeddedView(_templateRef, this._context);
	}

	@Input()
	set ngLet(value: T) {
		this._context.ngLet = value;
	}
}
