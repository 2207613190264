import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';
import { ComplianceOverTime } from './compliance-over-time.interface';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'compliance-over-time-api-call',
	method: 'GET',
	endpoint: 'baseline/complianceOverTime',
	cache: null,
})
export class ComplianceOverTimeApiCall extends ApiCallModel<ComplianceOverTime> {}
