import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { AlertsSeveritySummary, LegacyUser, LegacyUserAlertsSeveritySummaryRelationship } from '@wcd/domain';
import { OnChanges, TypedChanges, wasChangedAndExists } from '@wcd/angular-extensions';
import { Paris } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { SeveritiesCountMap } from '../../../shared/components/severity-summary/severity-summary.component';
import { Observable } from 'rxjs';

export type UserSecurityInfoDetailsViewKind = 'compact' | 'full';

@Component({
	selector: 'user-security-info-details',
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'users.entityDetails.sections.securityInfo.incidents.title' | i18n }}</dt>
			<dd>{{ openIncidentsCount$ | async }}</dd>

			<dt>{{ 'users.entityDetails.sections.securityInfo.activeAlerts.title' | i18n }}</dt>
			<dd>
				<ng-container [ngSwitch]="kind">
					<severity-summary
						*ngSwitchCase="'full'"
						size="compact"
						[severitiesCountMap]="severitiesCountMap$ | async"
					></severity-summary>

					<span *ngSwitchCase="'compact'">
						{{ alertsCount$ | async }}
					</span>
				</ng-container>
			</dd>
		</dl>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserSecurityInfoDetailsComponent implements OnChanges<UserSecurityInfoDetailsComponent> {
	@Input() user: LegacyUser;
	@Input() kind: UserSecurityInfoDetailsViewKind;
	@Input() asKeyValueList = false;

	severitiesCountMap$: Observable<SeveritiesCountMap>;
	alertsCount$: Observable<number>;
	openIncidentsCount$: Observable<number>;

	constructor(private readonly changeDetectorRef: ChangeDetectorRef, private readonly paris: Paris) {}

	ngOnChanges(changes: TypedChanges<UserSecurityInfoDetailsComponent>) {
		if (wasChangedAndExists(changes.user)) {
			const alertsSeveritySummary$ = this.paris.getRelatedItem<LegacyUser, AlertsSeveritySummary>(
				LegacyUserAlertsSeveritySummaryRelationship,
				changes.user.currentValue
			);

			this.severitiesCountMap$ = alertsSeveritySummary$.pipe(
				map(({ high, medium, low, info }) => ({
					high: high.count,
					medium: medium.count,
					low: low.count,
					informational: info.count,
				}))
			);

			this.alertsCount$ = alertsSeveritySummary$.pipe(map(summary => summary.alertsCount));

			this.openIncidentsCount$ = alertsSeveritySummary$.pipe(map(summary => summary.incidentsCount));
		}
	}
}
