import { Injectable } from '@angular/core';
import { Machine } from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { MachinesFiltersService } from './machines.filters.service';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { Feature, FeaturesService } from '@wcd/config';

const IOT_FIELDS = [
	'ips',
	'lastMacAddress',
	'deviceTypes',
	'vendors',
	'models',
	'machinename',
	'domain',
	'riskScores',
	'exposureScores',
	'osDistribution',
	'osVersion',
	'lastseen',
	'lastIpV6Address',
	'firstseen',
	'exclusionStates',
	'tags',
];

const NETWORK_DEVICE_ONLY_FIELDS = [
	'ips',
	'lastMacAddress',
	'vendors',
	'models',
	'machinename',
	'domain',
	'riskScores',
	'exposureScores',
	'osDistribution',
	'osVersion',
	'lastseen',
	'lastIpV6Address',
	'firstseen',
	'exclusionStates',
	'tags',
];

@Injectable()
export class IotDevicesFieldsService {
	readonly iotOnlyFields: Array<DataviewField>;
	constructor(
		private machinesFiltersService: MachinesFiltersService,
		private featuresService: FeaturesService,
		private i18nService: I18nService
	) {
		this.iotOnlyFields = this.getIotOnlyFields();
	}

	getFields(
		endpointsFields: Array<DataviewField<Machine>>,
		isNetworkOnlyFields: boolean
	): ReadonlyArray<DataviewField<Machine>> {
		const disabledFields = this.getDisabledFieldsByFeatureFlag();

		const fields = isNetworkOnlyFields ? NETWORK_DEVICE_ONLY_FIELDS : IOT_FIELDS;

		return fields
			.filter((field) => !disabledFields.includes(field))
			.reduce((fields, fieldId) => {
				const field =
					this.iotOnlyFields.find((f) => f.id === fieldId) ||
					endpointsFields.find((f) => f.id === fieldId);
				return field ? fields.concat(field) : fields;
			}, []);
	}

	getIotOnlyFields(): Array<DataviewField> {
		return DataviewField.fromList<Machine>([
			{
				id: 'ips',
				name: this.i18nService.strings.entity_type_display_name_ip,
				getDisplay: (machine: Machine) => machine.lastIp || machine.lastExternalIp,
				sort: { enabled: false },
				enabledByDefault: true,
				className: 'nowrap wcd-text-overflow-small',
			},
			{
				id: 'deviceTypes',
				name: this.i18nService.strings.machines_dataView_fields_deviceType,
				enabledByDefault: true,
				getDisplay: (machine: Machine) => {
					const deviceType = this.machinesFiltersService.deviceTypeCategoriesMap[
						machine.deviceType
					];
					return deviceType
						? deviceType.name
						: this.i18nService.strings.devices_page_device_type_value_unknown;
				},
				filter: {
					priority: -1,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (deviceTypeId: string) => {
								const deviceType = this.machinesFiltersService.deviceTypeCategoriesMap[
									deviceTypeId
								];
								return {
									id: deviceType.id,
									rawValue: deviceType.id,
									name: deviceType.name,
									priority: deviceType.priority,
								};
							},
						},
					},
				},
				sort: { enabled: true },
			},
			{
				id: 'vendors',
				name: this.i18nService.strings.tvm_common_vendor,
				enabledByDefault: true,
				getDisplay: (machine: Machine) => machine.vendor,
				sort: { enabled: false },
			},
			{
				id: 'models',
				name: this.i18nService.strings.machines_dataView_fields_model,
				enabledByDefault: true,
				getDisplay: (machine: Machine) => machine.model,
				sort: { enabled: false },
			},
			{
				id: 'lastMacAddress',
				name: this.i18nService.strings.machines_dataView_fields_mac_address,
				enabledByDefault: false,
				sort: { enabled: false },
			},
			{
				id: 'lastIpV6Address',
				name: this.i18nService.strings.machines_dataView_fields_ipv6,
				enabledByDefault: false,
				sort: { enabled: false },
			},
			{
				id: 'osVersion',
				name: this.i18nService.strings.machines_dataView_fields_osVersion2,
				getDisplay: (machine: Machine) => machine.os.osVersionFriendlyNameString,
				enabledByDefault: true,
				sort: { enabled: false },
			},
			{
				id: 'osDistribution',
				name: this.i18nService.strings.machines_dataView_fields_osDistribution,
				getDisplay: (machine: Machine) => machine.os.osPlatformFriendlyNameString,
				enabledByDefault: true,
				sort: { enabled: false },
			},
			{
				id: 'aadDeviceId',
				name: this.i18nService.strings.machines_entityDetails_fields_deviceAadId,
				getDisplay: (machine: Machine) => machine.aadDeviceId,
				sort: { enabled: false },
			},
			{
				id: 'deviceOwner',
				name: this.i18nService.strings.machines_entityDetails_fields_deviceOwner,
				getDisplay: (machine: Machine) => machine.deviceOwner,
				sort: { enabled: false },
			},
		]);
	}

	getDisabledFieldsByFeatureFlag(): string[] {
		let disabledFields = [];

		if (!this.featuresService.isEnabled(Feature.IotDevicesListMacAndIpv6)) {
			disabledFields = disabledFields.concat(['lastMacAddress', 'lastIpV6Address']);
		}

		return disabledFields;
	}
}
