import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('parts', partsFilter);

function partsFilter() {
	return function(input, length) {
		if (!input || length <= 0) {
			return '';
		}

		var parts = input.split('.');
		if (parts.length === 1) {
			return input;
		}

		var result = '';
		for (var i = 0; i < parts.length && i < length; i++) {
			result += '.' + parts[i];
		}

		return result.slice(1);
	};
}
