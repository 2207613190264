import { config } from '@wcd/scc-common';
import { DirRoleType } from './dir-role-type';
import { MtpPermission } from './mtp-permission-enum';
import { uniq } from 'lodash-es';
import { RbacPermission } from './unified-rbac-permissions.enum';
import { MtpWorkload } from './mtp-workload-enum';
import { MdeUserRoleActionEnum } from './user-role-action';
export interface AuthUserInfo {
	UserName: string;
	AadUserId?: string;
	TenantId?: string;
	TenantName?: string;
	AntiForgeryToken?: string;
	TokenExpirationDate?: string;
	MdeAllowedActions?: number;
	IsItpActive?: boolean;
	IsOatpActive?: boolean;
	IsMdatpActive?: boolean;
	IsMapgActive?: boolean;
	IsAadIpActive?: boolean;
	IsDlpActive?: boolean;
	IsMdiActive?: boolean;
	ItpMtpPermissions?: Array<RbacPermission>;
	OatpMtpPermissions?: Array<RbacPermission>;
	MdatpMtpPermissions?: Array<RbacPermission>;
	MapGMtpPermissions?: Array<RbacPermission>;
	AadIpMtpPermissions?: Array<RbacPermission>;
	DlpMtpPermissions?: Array<RbacPermission>;
	MdiMtpPermissions?: Array<RbacPermission>;
	DirRoles?: Array<string>;
}

export class AuthUser {
	aadUserId: string;
	name: string;
	shortName: string;
	isMdeAdmin: boolean;
	isReadonly: boolean;
	username: string;
	image: string;
	mdeAllowedActions: number; // bitwise value
	isSecAdmin: boolean;
	itpMtpPermissions: Array<RbacPermission>;
	oatpMtpPermissions: Array<RbacPermission>;
	mdatpMtpPermissions: Array<RbacPermission>;
	mapgMtpPermissions: Array<RbacPermission>;
	aadIpMtpPermissions: Array<RbacPermission>;
	dlpMtpPermissions: Array<RbacPermission>;
	mdiMtpPermissions: Array<RbacPermission>;

	private _imageUrl: string;
	private _mtpPermissionsAllActiveWorkloads: Array<RbacPermission>;
	private _mtpPermissionsOfMainActiveWorkloads: Array<RbacPermission>;
	private _dirRoles: string[];

	get imageCss(): string {
		if (!this._imageUrl) return null;

		return `url("${this._imageUrl}")`;
	}

	constructor(authUserInfo: AuthUserInfo) {
		this.aadUserId = authUserInfo.AadUserId;
		this.name = authUserInfo.UserName;
		const usernameMatch = this.name && this.name.match(/^(\w+)@.+/);
		this.shortName = usernameMatch ? usernameMatch[1] : '';
		this.mdeAllowedActions = authUserInfo.MdeAllowedActions || 0;
		this.mdatpMtpPermissions = authUserInfo.MdatpMtpPermissions || [];
		this.itpMtpPermissions = authUserInfo.ItpMtpPermissions || [];
		this.oatpMtpPermissions = authUserInfo.OatpMtpPermissions || [];
		this.mapgMtpPermissions = authUserInfo.MapGMtpPermissions || [];
		this.aadIpMtpPermissions = authUserInfo.AadIpMtpPermissions || [];
		this.dlpMtpPermissions = authUserInfo.DlpMtpPermissions || [];
		this.mdiMtpPermissions = authUserInfo.MdiMtpPermissions || [];
		const activeMainWorkloadPermissions: Array<RbacPermission> = [].concat(
			authUserInfo.IsMdatpActive ? this.mdatpMtpPermissions : [],
			authUserInfo.IsOatpActive ? this.oatpMtpPermissions : [],
			authUserInfo.IsItpActive ? this.itpMtpPermissions : [],
			authUserInfo.IsDlpActive ? this.dlpMtpPermissions : [],
			authUserInfo.IsMdiActive ? this.mdiMtpPermissions : []
		);
		const allActiveWorkloadPermissions: Array<RbacPermission> = activeMainWorkloadPermissions.concat(
			authUserInfo.IsMapgActive ? this.mapgMtpPermissions : [],
			authUserInfo.IsAadIpActive ? this.aadIpMtpPermissions : []
		);
		// MTP *main* workloads are the ones that determine eligibility for MTP experiences
		this._mtpPermissionsAllActiveWorkloads = uniq(allActiveWorkloadPermissions);
		this._mtpPermissionsOfMainActiveWorkloads = uniq(activeMainWorkloadPermissions);
		this.isMdeAdmin = this.mdeAllowedActions === MdeUserRoleActionEnum.admin;
		this.isReadonly =
			this.mdeAllowedActions === MdeUserRoleActionEnum.viewData ||
			this.mdeAllowedActions === MdeUserRoleActionEnum.tvmViewData ||
			this.mdeAllowedActions === MdeUserRoleActionEnum.viewData + MdeUserRoleActionEnum.tvmViewData ||
			this._mtpPermissionsAllActiveWorkloads.every(
				(permission) =>
					permission === MtpPermission.SecurityData_Read ||
					permission === MtpPermission.TvmData_Read
			);
		this._dirRoles = authUserInfo.DirRoles;
		this.isSecAdmin = this._dirRoles
			? this._dirRoles.includes(DirRoleType.SecurityAdministrator) ||
			  this._dirRoles.includes(DirRoleType.GlobalAdministrator)
			: false;
		this.username = authUserInfo.UserName;
		this._imageUrl = this.isMdeAdmin ? config.images.adminAvatar : config.images.userAvatar;

		Object.freeze(this);
	}

	hasMdeAllowedUserRoleAction(userRoleAction: any | number): boolean {
		const role: number = typeof userRoleAction === 'number' ? userRoleAction : userRoleAction.id;
		return this.isMdeAdmin || (role !== MdeUserRoleActionEnum.admin && !!(this.mdeAllowedActions & role));
	}

	//ToDo: rename hasRequiredMtpPermission to hasRequiredPermission
	hasRequiredMtpPermission(permission: RbacPermission): boolean {
		return this._mtpPermissionsAllActiveWorkloads.includes(permission);
	}

	//ToDo: rename hasRequiredMtpPermissionsFromMainWorkloads to hasRequiredPermissionsFromMainWorkloads
	hasRequiredMtpPermissionsFromMainWorkloads(permission: RbacPermission): boolean {
		return this._mtpPermissionsOfMainActiveWorkloads.includes(permission);
	}

	hasDefaultManagePermissions = () => {
		return (
			this._dirRoles.includes(DirRoleType.GlobalAdministrator) ||
			this._dirRoles.includes(DirRoleType.SecurityAdministrator) ||
			this._dirRoles.includes(DirRoleType.SecurityOperator)
		);
	};

	//ToDo: rename hasRequiredMtpPermissionInWorkload to hasRequiredPermissionInWorkload
	hasRequiredMtpPermissionInWorkload(permission: RbacPermission, mtpWorkload: MtpWorkload): boolean {
		switch (mtpWorkload) {
			case MtpWorkload.Itp:
				return this.itpMtpPermissions.includes(permission);
			case MtpWorkload.Oatp:
				return this.oatpMtpPermissions.includes(permission);
			case MtpWorkload.Mdatp:
				return this.mdatpMtpPermissions.includes(permission);
			case MtpWorkload.MapG:
				return this.mapgMtpPermissions.includes(permission);
			case MtpWorkload.AadIp:
				return this.aadIpMtpPermissions.includes(permission);
			case MtpWorkload.Dlp:
				return this.dlpMtpPermissions.includes(permission);
			case MtpWorkload.Mdi:
				return this.mdiMtpPermissions.includes(permission);
			default:
				return false;
		}
	}
}
