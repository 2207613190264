import { Injectable, SecurityContext } from '@angular/core';
import { ErrorMessageOptions } from '../errors/error-message-options.interface';
import { ModalService } from '../modals/services/modal.service';
import { ErrorsService } from '../../shared/services/errors.service';
import { ConfirmationService } from '../confirm/confirm.service';
import { I18nService } from '@wcd/i18n';
import { WicdSanitizerService } from '@wcd/shared';

@Injectable()
export class ErrorsDialogService {
	constructor(
		private readonly modalsService: ModalService,
		private readonly confirmationService: ConfirmationService,
		private readonly i18nService: I18nService,
		private domSanitizer: WicdSanitizerService
	) {}

	showError(options: ErrorMessageOptions): Promise<null | undefined> {
		if (!options) {
			console.warn('trying to display error, but not options specified.');
			return new Promise(null);
		}

		if (options.constructor === String) {
			options = {
				data: options,
			};
		}

		let errorMessage: string =
			options.data instanceof Array
				? options.data.map(data => ErrorsService.getErrorMessage(data, this.i18nService)).join('\n')
				: ErrorsService.getErrorMessage(options.data, this.i18nService);

		console.log('Error data: ', options.data);
		errorMessage = this.domSanitizer.sanitize(SecurityContext.HTML, errorMessage);

		return this.confirmationService
			.confirm({
				iconCssClass: 'color-text-error',
				title: options.title,
				icon: 'errorOutline',
				showConfirm: false,
				cancelText: this.i18nService.strings.ok,
				text: `${options.errorTitle ? options.errorTitle + '\n' : ''}${errorMessage || ''}  `,
			})
			.then(() => null);
	}
}
