import { EntityField, ValueObject } from '@microsoft/paris';
import { EntityStatistics } from '../entity/entity-statistics.value-object';

@ValueObject({
	singularName: 'File stats',
	pluralName: '',
	readonly: true,
})
export class FileStats extends EntityStatistics {
	@EntityField({ data: 'TopFileNames' })
	readonly topFileNames: ReadonlyArray<string>;

	@EntityField({ data: 'OrgLastSeenMachineId' })
	readonly orgLastSeenMachineId: string
}
