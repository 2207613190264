/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../forms/src/lib/components/search.component.ngfactory";
import * as i2 from "../../../../../../forms/src/lib/components/search.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "../checklist/filter-values.checklist.component.ngfactory";
import * as i7 from "../checklist/filter-values.checklist.component";
import * as i8 from "./filter-values.checklist-with-search.component";
var styles_FilterValuesChecklistWithSearchComponent = [];
var RenderType_FilterValuesChecklistWithSearchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterValuesChecklistWithSearchComponent, data: {} });
export { RenderType_FilterValuesChecklistWithSearchComponent as RenderType_FilterValuesChecklistWithSearchComponent };
function View_FilterValuesChecklistWithSearchComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-margin-small-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "wcd-search", [], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.addSearchValue($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_SearchComponent_0, i1.RenderType_SearchComponent)), i0.ɵdid(3, 114688, null, 0, i2.SearchComponent, [i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService], { placeholder: [0, "placeholder"], settings: [1, "settings"], fullWidth: [2, "fullWidth"], searchFunction: [3, "searchFunction"], ariaLabel: [4, "ariaLabel"], closeResultsWhenSearchCleared: [5, "closeResultsWhenSearchCleared"] }, { select: "select" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.config.search.placeholder || _co.i18nService.strings.filters_search_title); var currVal_1 = _co.config.search.settings; var currVal_2 = true; var currVal_3 = _co.config.search.method; var currVal_4 = _co.fieldName; var currVal_5 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_FilterValuesChecklistWithSearchComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(402653184, 1, { checklist: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FilterValuesChecklistWithSearchComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "wcd-filter-values-checklist", [], null, [[null, "filterValuesChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterValuesChange" === en)) {
        var pd_0 = (_co.filterValuesChange.emit($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FilterValuesChecklistComponent_0, i6.RenderType_FilterValuesChecklistComponent)), i0.ɵdid(6, 245760, [[1, 4]], 0, i7.FilterValuesChecklistComponent, [i0.ChangeDetectorRef, i4.I18nService, i3.LiveAnnouncer], { data: [0, "data"], fieldId: [1, "fieldId"], config: [2, "config"], selectedValues: [3, "selectedValues"] }, { filterValuesChange: "filterValuesChange" }), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_co.config == null) ? null : _co.config.search) && (((_co.config == null) ? null : ((_co.config.search == null) ? null : _co.config.search.alwaysDisplay)) || (((_co.allValues == null) ? null : _co.allValues.count) > ((_co.allValues == null) ? null : ((_co.allValues.values == null) ? null : _co.allValues.values.length))))); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.allValues; var currVal_2 = _co.fieldId; var currVal_3 = _co.config; var currVal_4 = _co.selectedValues; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FilterValuesChecklistWithSearchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-filter-values-checklist-with-search", [], null, null, null, View_FilterValuesChecklistWithSearchComponent_0, RenderType_FilterValuesChecklistWithSearchComponent)), i0.ɵdid(1, 49152, null, 0, i8.FilterValuesChecklistWithSearchComponent, [i0.ChangeDetectorRef, i4.I18nService], null, null)], null, null); }
var FilterValuesChecklistWithSearchComponentNgFactory = i0.ɵccf("wcd-filter-values-checklist-with-search", i8.FilterValuesChecklistWithSearchComponent, View_FilterValuesChecklistWithSearchComponent_Host_0, { data: "data", fieldId: "fieldId", config: "config", selectedValues: "selectedValues", fixedSelectedValues: "fixedSelectedValues", fieldName: "fieldName" }, { filterValuesChange: "filterValuesChange" }, []);
export { FilterValuesChecklistWithSearchComponentNgFactory as FilterValuesChecklistWithSearchComponentNgFactory };
