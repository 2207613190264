<ng-container *ngIf="(commandBarItems$ | async) as commandBarItems">
	<ng-container *ngIf="commandBarItems?.length">
		<div class="entity-command-bar" [class.overflow-actions]="overFlowActionsView">
			<command-bar class="entity-command-bar"
						 [class.command-bar]="overFlowActionsView"
						 [class.button]="overFlowActionsView"
						 [id]="entityType.entity.singularName + '_EntityCommandBar'"
						 [items]="commandBarItems"
						 [styles]="overFlowActionsView ? overFlowCommandBarStyles : null">
			</command-bar>
		</div>
	</ng-container>
</ng-container>

<ng-template #tagsItem let-item="item">
	<wcd-dropdown #dropDownRef
				  [closeOnClick]="false"
				  [rbac]="item.data.rbacSettings"
				  [buttonIcon]="item.iconProps.iconName"
				  [buttonTooltip]="item.title"
				  class="command-bar-item command-bar-item-dropdown"
				  [attr.data-track-id]="item.key"
				  data-track-type="TagStart"
				  [showIconDropdown]="false"
				  [buttonId]="item.key + '-tags'"
				  [isBordered]="false"
				  ariaRole="menuitem"
				  [ariaOwns]="item.key + '-tags-dialog'"
				  (onToggle)="tagsEditorToggled($event)">
		<span button-content>{{ 'tags.manage' | i18n }}</span>
		<tags-edit [dialogId]="item.key + '-tags-dialog'"
				   [tags]="tags$ | async"
				   [readonly]="!(isUserExposed$ | async)"
				   [settings]="item.data.itemAction.options.tags"
				   (changed)="onChangeTags($event, item.data.itemAction)"
				   [callOnEscape]="dropDownRef"
				   [tagEditDialogOpen]="tagEditOpen"></tags-edit>
	</wcd-dropdown>
</ng-template>
