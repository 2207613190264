import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Observable } from 'rxjs';

@Component({
	selector: 'wcd-async',
	template: `
		{{ value$ | async }}
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsyncComponent {
	@Input() value$: Observable<any>;
}
