export const airsEntityActionStatusValues = [
	{
		id: 'declined',
		name: 'Remediation declined',
	},
	{
		id: 'approved',
		name: 'Remediation approved',
	},
];
