var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { UserStatusTypes } from './aad.user-status';
export var stringToNumberParse = function (value) {
    return typeof value === 'string' ? parseInt(value, 10) : value;
};
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = ['UserSid', 'AadUserId'];
var AadUser = /** @class */ (function (_super) {
    __extends(AadUser, _super);
    function AadUser() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", String)
    ], AadUser.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'AadUserId' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "aadUserId", void 0);
    __decorate([
        EntityField({ data: 'DisplayName' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "displayName", void 0);
    __decorate([
        EntityField({ data: 'AccountName' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "accountName", void 0);
    __decorate([
        EntityField({ data: 'DomainName' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "accountDomainName", void 0);
    __decorate([
        EntityField({ data: 'DomainName' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "domainName", void 0);
    __decorate([
        EntityField({ data: 'JobTitle' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "jobTitle", void 0);
    __decorate([
        EntityField({ data: 'UserSid' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "sid", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationPriority',
            parse: stringToNumberParse,
        }),
        __metadata("design:type", Number)
    ], AadUser.prototype, "investigationPriority", void 0);
    __decorate([
        EntityField({ data: 'McasPercentile', parse: stringToNumberParse }),
        __metadata("design:type", Number)
    ], AadUser.prototype, "percentile", void 0);
    __decorate([
        EntityField({ data: 'UserPageUrl' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "userPageUrl", void 0);
    __decorate([
        EntityField({ data: 'Mail' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "mail", void 0);
    __decorate([
        EntityField({ data: 'Department' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "department", void 0);
    __decorate([
        EntityField({ data: 'UserGroups' }),
        __metadata("design:type", Array)
    ], AadUser.prototype, "groups", void 0);
    __decorate([
        EntityField({ data: 'UserStatus' }),
        __metadata("design:type", String)
    ], AadUser.prototype, "userStatus", void 0);
    AadUser = __decorate([
        Entity({
            singularName: 'User',
            pluralName: 'Users',
            endpoint: 'users',
            allItemsProperty: 'results',
            baseUrl: ɵ0,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            separateArrayParams: true,
        })
    ], AadUser);
    return AadUser;
}(EntityModelBase));
export { AadUser };
export { ɵ0, ɵ1 };
