var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { cloneDeep } from 'lodash-es';
import { combineLatest, of } from 'rxjs';
import { mergeMap, switchMap, tap } from 'rxjs/operators';
import { MachineGroup, MdeUserRoleActionEnum, WebContentFilteringCategoryType, WebContentFilteringPolicy, } from '@wcd/domain';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { MachinesService } from '../../machines/services/machines.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ScopeTypesEnum, WebContentFilteringPolicyService, } from '../services/web-content-filtering-policy.service';
import { AuthService } from '@wcd/auth';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { AppFlavorConfig } from '@wcd/scc-common';
var WebContentFilteringPolicyEditComponent = /** @class */ (function (_super) {
    __extends(WebContentFilteringPolicyEditComponent, _super);
    function WebContentFilteringPolicyEditComponent(paris, authService, dialogsService, changeDetectorRef, machinesService, featuresService, flavorService, entityPanelsService, policyService, i18nService, advancedFeaturesService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.authService = authService;
        _this.dialogsService = dialogsService;
        _this.machinesService = machinesService;
        _this.featuresService = featuresService;
        _this.flavorService = flavorService;
        _this.entityPanelsService = entityPanelsService;
        _this.policyService = policyService;
        _this.i18nService = i18nService;
        _this.advancedFeaturesService = advancedFeaturesService;
        _this.ScopeTypesEnum = ScopeTypesEnum;
        _this.SpinnerSize = SpinnerSize;
        _this.isSaving = false;
        _this.isDirty = false;
        _this.allowEdit = true;
        _this.supportedCategoryIds = [];
        // Machine group
        _this.loadingMachineGroups = true;
        _this.allowSpecificMachineGroups = false;
        _this.selectedMachineGroups = [];
        _this.selectedMachineGroupsNames = [];
        _this.availableMachineGroups = [];
        _this._valuesLabelTexts = new Map();
        _this.isScopeVisible = _this.flavorService.isEnabled(AppFlavorConfig.settings.webContentFiltering);
        _this.onMachineGroupChange = function (newOption) {
            _this.allowSpecificMachineGroups =
                newOption === _this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
            if (!_this.allowSpecificMachineGroups) {
                _this._setSelectedMachineGroups((_this.policy.rbacGroupIds = _this.selectedMachineGroups = []));
                _this.isDirty = true;
            }
        };
        _this.machineGroupsRepo = paris.getRepository(MachineGroup);
        _this.policyRepo = paris.getRepository(WebContentFilteringPolicy);
        _this._rbacMachineGroupsEnabled = _this.featuresService.isEnabled(Feature.RbacMachineGroups);
        _this.allowEdit = _this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        _this.supportedCategoryIds = _this.paris
            .getRepository(WebContentFilteringCategoryType)
            .entity.values.filter(function (category) { return _this.policyService.filterCategory(category); })
            .map(function (category) { return category.id; });
        return _this;
    }
    Object.defineProperty(WebContentFilteringPolicyEditComponent.prototype, "machineGroupsFieldId", {
        get: function () {
            return 'web-content-filtering-policy-machine-groups';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebContentFilteringPolicyEditComponent.prototype, "policy", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebContentFilteringPolicyEditComponent.prototype, "blockedCategories", {
        get: function () {
            return this.policyService.getCategoryNames(this.policy);
        },
        enumerable: true,
        configurable: true
    });
    WebContentFilteringPolicyEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.selectableMachineGroupScopes = this.policyService.getSelectableMachineGroupScopes();
        var machineGroupsObservable = this._rbacMachineGroupsEnabled
            ? this.setMachineGroups()
            : of(null);
        this.machineGroupSubscription = machineGroupsObservable.subscribe(function () {
            _this.loadingMachineGroups = false;
            if (_this._rbacMachineGroupsEnabled) {
                if (_this.allowEdit) {
                    _this._setSelectedMachineGroups(_this.policy.rbacGroupIds);
                }
                else {
                    _this._setSelectedMachineGroupsNames(_this.policy.rbacGroupIds);
                }
            }
        }, function (error) {
            _this.loadingMachineGroups = false;
        });
        this.isWebCategoryEnabled$ = this.advancedFeaturesService
            .getAdvancedFeaturesSettings()
            .pipe(switchMap(function (advancedFeatures) { return of(advancedFeatures.webCategoriesEnabled); }));
        if (this.policy.rbacGroupIds && this.policy.rbacGroupIds.length) {
            this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
        }
        else {
            this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
        }
        this.categoryChecklistConfig = this.policyService.getCategoryChecklistConfig();
    };
    WebContentFilteringPolicyEditComponent.prototype.ngOnDestroy = function () {
        this.machineGroupSubscription && this.machineGroupSubscription.unsubscribe();
        this.onMachineGroupsChangedSubscription && this.onMachineGroupsChangedSubscription.unsubscribe();
        this.savePolicySubscription && this.savePolicySubscription.unsubscribe();
    };
    WebContentFilteringPolicyEditComponent.prototype.ngAfterViewInit = function () {
        // Set category to block checklist initial values
        if (this.categoryChecklist) {
            this.categoryChecklist.setSelectedValues(this.policy.blockedCategoryIds);
        }
    };
    WebContentFilteringPolicyEditComponent.prototype.ngAfterViewChecked = function () {
        // make sure the selection gets updated if template view change
        if (this.categoryChecklist) {
            this.categoryChecklist.setSelectedValues(this.policy.blockedCategoryIds);
        }
    };
    WebContentFilteringPolicyEditComponent.prototype.setEntity = function (entity) {
        var _this = this;
        var clonedEtity = cloneDeep(entity);
        _super.prototype.setEntity.call(this, clonedEtity);
        // Filter out unsupported categories
        this.policy.blockedCategoryIds = this.policy.blockedCategoryIds.filter(function (id) {
            return _this.supportedCategoryIds.includes(id);
        });
        this.policy.auditCategoryIds = this.supportedCategoryIds.filter(function (id) { return !_this.policy.blockedCategoryIds.includes(id); });
    };
    WebContentFilteringPolicyEditComponent.prototype.setMachineGroups = function () {
        var _this = this;
        return this.machineGroupsRepo.allItems$.pipe(tap(function (groups) {
            _this._allMachineGroups = groups;
            _this._setAvailableScopes();
        }), mergeMap(function () { return _this._getUserExposedRbacGroups(); }));
    };
    WebContentFilteringPolicyEditComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!selectedMachineGroups || !selectedMachineGroups.length)
            this._setSelectedMachineGroups((this.policy.rbacGroupIds = []));
        else {
            this.onMachineGroupsChangedSubscription = combineLatest(selectedMachineGroups.map(function (selectedGroup) {
                return _this.machineGroupsRepo.getItemById(selectedGroup.id);
            })).subscribe(function (machineGroups) {
                var machineGroupIds = machineGroups.map(function (group) { return group.id; });
                _this._setSelectedMachineGroups((_this.policy.rbacGroupIds = machineGroupIds));
            });
        }
        this.isDirty = true;
    };
    WebContentFilteringPolicyEditComponent.prototype.onBlockedCategoriesChange = function (selectedIds) {
        this.policy.blockedCategoryIds = selectedIds;
        this.policy.auditCategoryIds = this.supportedCategoryIds.filter(function (id) { return !selectedIds.includes(id); });
        this.isDirty = true;
    };
    WebContentFilteringPolicyEditComponent.prototype.getLabelText = function (fieldId) {
        return (this._valuesLabelTexts.get(fieldId) ||
            this.i18nService.get('webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues'));
    };
    WebContentFilteringPolicyEditComponent.prototype.setLabelText = function (fieldId, values) {
        var labelText = this._setLabelText(values), currentValue = this._valuesLabelTexts.get(fieldId);
        if (!currentValue || currentValue !== labelText)
            this._valuesLabelTexts.set(fieldId, labelText);
    };
    WebContentFilteringPolicyEditComponent.prototype._setSelectedMachineGroups = function (machineGroupIds) {
        var _this = this;
        this.selectedMachineGroups = this._allMachineGroups
            .filter(function (machineGroup) { return machineGroupIds.includes(machineGroup.id); })
            .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        this.setLabelText(this.machineGroupsFieldId, this.selectedMachineGroups);
    };
    WebContentFilteringPolicyEditComponent.prototype._setSelectedMachineGroupsNames = function (machineGroupIds) {
        this.selectedMachineGroupsNames = this._allMachineGroups
            .filter(function (machineGroup) { return machineGroupIds.includes(machineGroup.id); })
            .map(function (machineGroup) { return machineGroup.name; });
    };
    WebContentFilteringPolicyEditComponent.prototype._setLabelText = function (values) {
        if (values.length > 3) {
            return values.length + " values";
        }
        var _values = values.map(function (value) { return value.name; });
        if (_values.length) {
            return _values.join(', ');
        }
        return this.i18nService.get("webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues");
    };
    WebContentFilteringPolicyEditComponent.prototype._setAvailableScopes = function () {
        if (this._allMachineGroups.length) {
            this.allowSpecificMachineGroups = true;
            if (this.authService.currentUser.isMdeAdmin) {
                this.allowAllMachineGroups = true;
            }
            else {
                this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
            }
        }
        else {
            this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
        }
    };
    WebContentFilteringPolicyEditComponent.prototype._getUserExposedRbacGroups = function () {
        var _this = this;
        return this.machinesService.getFullUserExposedMachineGroups().pipe(tap(function (userExposedMachineGroups) {
            _this.availableMachineGroups = _this._allMachineGroups
                .filter(function (machineGroup) {
                return (userExposedMachineGroups &&
                    userExposedMachineGroups.some(function (group) { return group.id === machineGroup.id; }));
            })
                .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        }));
    };
    WebContentFilteringPolicyEditComponent.prototype._getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18nService.get('machineGroup.unassignedGroup.name')
                : machineGroup.name,
        };
    };
    Object.defineProperty(WebContentFilteringPolicyEditComponent.prototype, "isValid", {
        get: function () {
            if (this.loadingMachineGroups ||
                (this.selectedMachineGroupScope.id == ScopeTypesEnum.Specific &&
                    (!this.policy.rbacGroupIds || !this.policy.rbacGroupIds.length))) {
                return false;
            }
            return true;
        },
        enumerable: true,
        configurable: true
    });
    WebContentFilteringPolicyEditComponent.prototype.save = function () {
        var _this = this;
        this.isSaving = true;
        this.savePolicySubscription = this.policyRepo.save(this.policy).subscribe(function () {
            _this.isSaving = false;
            _this.entityPanelsService.closeEntityPanel(WebContentFilteringPolicy);
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService
                .showError({
                title: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.edit.errorMessage', { itemName: _this.policy.policyName }),
                data: error,
            })
                .then(function () { return _this.changeDetectorRef.markForCheck(); });
        });
    };
    return WebContentFilteringPolicyEditComponent;
}(EntityPanelComponentBase));
export { WebContentFilteringPolicyEditComponent };
