var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Alert, DEFAULT_LOOKBACK_IN_DAYS_VALUE, Incident, IncidentAlertsRelationship, RelatedAlertsApiCall, } from '@wcd/domain';
import { AppContextService } from '@wcd/config';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DataviewField } from '@wcd/dataview';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { forkJoin, of } from 'rxjs';
import { IncidentEntityComponent } from './incident.entity.component';
import { AlertsService } from '../../alerts/services/alerts.service';
import { alertTypes, alertTypesArray } from '../../alerts/models/alert-type.model';
import { PreferencesService, FeaturesService, Feature } from '@wcd/config';
import { FiltersService } from '@wcd/ng-filters';
import { ActivatedRoute } from '@angular/router';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { sccHostService } from '@wcd/scc-interface';
import { DataViewModel } from '../../../dataviews/models/dataview.model';
var SUPPORTED_FILTER_FIELD_NAMES = [
    'status',
    'AssignedTo',
    'detectionSource',
    'serviceSource',
    'severity',
    'investigationStates',
    'category',
];
var IncidentAlertsComponent = /** @class */ (function () {
    function IncidentAlertsComponent(paris, globalEntityTypesService, alertsFields, activatedEntity, appContextService, alertsService, preferencesService, featuresService, incidentEntityComponent, route, performanceSccService) {
        var _this = this;
        this.paris = paris;
        this.alertsFields = alertsFields;
        this.activatedEntity = activatedEntity;
        this.appContextService = appContextService;
        this.alertsService = alertsService;
        this.preferencesService = preferencesService;
        this.featuresService = featuresService;
        this.incidentEntityComponent = incidentEntityComponent;
        this.route = route;
        this.performanceSccService = performanceSccService;
        this.alertTypeValues = alertTypesArray;
        this.Feature = Feature;
        this.hideControls = false;
        this.allowPaging = true;
        this.allowFilters = true;
        this.navigateOnChange = true;
        this.allowGrouping = true;
        this.dataViewId = 'incident-alerts-updated';
        this.isScc = sccHostService.isSCC;
        this.groupedSorting = {
            sortField: 'firstEventTime',
            sortDescending: true,
        };
        this.boundGetContainedAlerts = this.getContainedAlerts.bind(this);
        this.alertEntityType = globalEntityTypesService.getEntityType(Alert);
        this.alertsViewType = this.enableGrouping() ? alertTypes.grouped : alertTypes.flat;
        this.originalFields = (this.appContextService.isMtp ? alertsFields.mtpFields : alertsFields.allFields)
            .map(function (field) {
            switch (field.id) {
                case 'investigationStates':
                    return __assign({}, field, { enabledByDefault: false, sort: { enabled: true } });
                case 'title':
                case 'category':
                case 'detectionSource':
                case 'AssignedTo':
                case 'status':
                case 'classification':
                case 'determination':
                case 'impactedEntities':
                    return __assign({}, field, { sort: { enabled: true } });
                case 'firstEventTime':
                    return __assign({}, field, { sort: { sortDescendingByDefault: false } });
                default:
                    return field;
            }
        })
            .map(function (field) { return new DataviewField(field); });
        /*this.alertsViewType must be initialized before calling initDataTable because it uses its value inside*/
        this.alertViewTypePreferenceId = this.dataViewId + "_alertsViewType";
        var alertsViewPreference = this.preferencesService.getPreference(this.alertViewTypePreferenceId);
        this.alertsViewType =
            this.enableGrouping() && alertsViewPreference !== alertTypes.flat.type
                ? alertTypes.grouped
                : alertTypes.flat;
        this.initDataTable();
        this.filterWithHttpPostEnabled = this.featuresService.isEnabled(Feature.DataviewFilterWithHttpPost);
        var showServiceSources = appContextService.isMtp;
        this.dataViewConfig = this.filterWithHttpPostEnabled
            ? {
                getFiltersData: function () {
                    return _this.alertsService.getAlertsFilters(SUPPORTED_FILTER_FIELD_NAMES, showServiceSources);
                },
                filtersHiddenByDefault: true,
                loadResults: function (options) {
                    var configOptions = DataViewModel.getDataQueryFromOptions(_this.dataViewConfig, options);
                    configOptions.where['IncidentId'] =
                        _this.incidentAlertsRepository &&
                            _this.incidentAlertsRepository.sourceItem &&
                            _this.incidentAlertsRepository.sourceItem.id;
                    configOptions['groupType'] = 'GroupHash';
                    configOptions['lookBackInDays'] = 180;
                    return _this.getItems(configOptions).pipe(map(function (_a) {
                        var data = _a[0], count = _a[1];
                        return ({ count: count, items: data.items });
                    }));
                },
            }
            : {
                getFiltersData: function () {
                    return _this.alertsService.getAlertsFilters(SUPPORTED_FILTER_FIELD_NAMES, showServiceSources);
                },
                filtersHiddenByDefault: true,
            };
    }
    IncidentAlertsComponent.prototype.onSortChanged = function (event) {
        this.groupedSorting = {
            sortField: event.sortField.id,
            sortDescending: event.sortDescending,
        };
    };
    IncidentAlertsComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('alerts');
    };
    IncidentAlertsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.incident) {
            this.setAlertsRepository(this.incident);
        }
        else {
            this._currentEntitySubscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof Incident; }))
                .subscribe(function (incident) {
                _this.setAlertsRepository(incident);
            });
        }
    };
    IncidentAlertsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (changes.filteredFields) {
            this.fields = this.alertsFields.fields.filter(function (field) {
                return changes.filteredFields.currentValue.includes(field.id);
            });
            if (this.sortDisabledFields && this.sortDisabledFields.length) {
                this.fields.map(function (field) {
                    return _this.sortDisabledFields.includes(field.id)
                        ? field.clone({ sort: { enabled: false } })
                        : field;
                });
            }
        }
        if (changes.incident && this.incidentAlertsRepository) {
            this.incidentAlertsRepository.sourceItem = changes.incident.currentValue;
            if (!changes.incident.firstChange &&
                changes.incident.currentValue.id !== changes.incident.previousValue.id) {
                this.dataViewComponent.refreshData();
            }
        }
        if (changes.allowGrouping != null) {
            this.initDataTable();
        }
    };
    IncidentAlertsComponent.prototype.ngOnDestroy = function () {
        this._currentEntitySubscription && this._currentEntitySubscription.unsubscribe();
    };
    IncidentAlertsComponent.prototype.isGroup = function (alert) {
        return alert.isGroup;
    };
    IncidentAlertsComponent.prototype.onAlertTypeChange = function () {
        this.preferencesService.setPreference(this.alertViewTypePreferenceId, this.alertsViewType.type);
        this.initDataTable();
    };
    IncidentAlertsComponent.prototype.initDataTable = function () {
        this.setFixedOptions();
        this.setFields();
    };
    IncidentAlertsComponent.prototype.setFixedOptions = function () {
        this.dataviewFixedOptions = {
            alertType: this.isGroupedView() ? alertTypes.grouped.id : alertTypes.flat.id,
            includeExtendedAlertData: true,
        };
    };
    IncidentAlertsComponent.prototype.enableGrouping = function () {
        return this.featuresService.isEnabled(Feature.NestedAlertsInIncident) && this.allowGrouping;
    };
    IncidentAlertsComponent.prototype.isGroupedView = function () {
        return this.enableGrouping() && this.alertsViewType.isGrouped;
    };
    IncidentAlertsComponent.prototype.setFields = function () {
        var _this = this;
        if (!this.filteredFields) {
            this.fields = this.originalFields.filter(function (field) { return _this.isGroupedView() || field.id !== 'expand'; });
        }
    };
    IncidentAlertsComponent.prototype.getContainedAlerts = function (alert) {
        var queryFilters = this.getQuerySerializedFilters();
        return this.alertsService.getContainedAlerts(alert, __assign({ sortByField: this.groupedSorting.sortField, sortOrder: this.groupedSorting.sortDescending, incidentId: alert.incidentId, lookBackInDays: DEFAULT_LOOKBACK_IN_DAYS_VALUE, includeExtendedAlertData: true }, queryFilters, (this.filtersState ? this.filtersState.selection : {})), true);
    };
    IncidentAlertsComponent.prototype.setAlertsRepository = function (incident) {
        if (incident.alertCount) {
            this.incidentAlertsRepository = this.paris.getRelationshipRepository(IncidentAlertsRelationship);
            this.incidentAlertsRepository.sourceItem = incident;
        }
    };
    IncidentAlertsComponent.prototype.onFiltersChanged = function (filtersState) {
        this.filtersState = filtersState;
    };
    IncidentAlertsComponent.prototype.getQuerySerializedFilters = function () {
        return FiltersService.filtersQueryParamToSerializedFilters(this.route.snapshot.queryParams['filters']);
    };
    IncidentAlertsComponent.prototype.getItems = function (options) {
        var _this = this;
        var entities$ = this.paris.apiCall(RelatedAlertsApiCall, options);
        return entities$.pipe(mergeMap(function (rawDataSet) {
            return forkJoin([
                _this.paris.modeler.rawDataToDataSet(rawDataSet.dataset, Alert, null),
                of(rawDataSet.count),
            ]);
        }));
    };
    return IncidentAlertsComponent;
}());
export { IncidentAlertsComponent };
