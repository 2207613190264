import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { SpinnerSize } from 'office-ui-fabric-react';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { Machine, MachineRequest, MachineResponseType, TroubleshootApiCall, TroubleshootParameters, TroubleshootResponse } from '@wcd/domain';
import { PanelContainer, PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlSettings, RbacControlState } from '../../../../../rbac/models/rbac-control-settings.model';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { Section } from '../action-center/action-center.component';

enum CollapsibleID {
	Details = 'troubleshooting-mode-details',
}

const privacyAndCookiesLink = "https://www.microsoft.com";

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)='destroy()' [settings]='settings'>
			<form
				#troubleshootingForm='ngForm'
				class='wcd-auto-height-responsive wcd-full-height wcd-flex-vertical'
			>
				<div class='wcd-padding-large-horizontal wcd-flex-1 wcd-scroll-vertical wcd-padding-all'>
					<header
						class='wcd-flex-none wcd-padding-vertical wcd-flex-horizontal'
					>
						<div class='wcd-flex-1 wcd-flex-center-vertical'>
							<h3 #panelHeader class='side-panel-title wcd-font-weight-bold'>
								<fab-icon iconName="Warning" contentClass="color-text-warning"></fab-icon>
								{{ 'machines.entityDetails.actions_turnOnTroubleshootingMode.panel.title' | i18n }}
							</h3>
						</div>
					</header>
					<div class='wcd-margin-largeMedium-top wcd-margin-small-bottom'>
						<ng-container>
							<ul>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item1' | i18n }}
								</li>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item2' | i18n }}
								</li>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item3' | i18n }}
								</li>
							</ul>
						</ng-container>
					</div>
					<div class='wcd-margin-largeMedium-top wcd-font-weight-bold'>
						{{ 'machines.entityDetails.actions.turnOnTroubleshootingMode.panel.diagnosticData.title' | i18n }}
					</div>
					<div class='wcd-margin-small-bottom'>
						<p>
							{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_diagnosticData_description_1' | i18n }}
						</p>
						<p>
							{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_diagnosticData_description_2' | i18n }}
						</p>
					</div>
					<div class='wcd-padding-large-vertical'>
						<a [href]='cookiesLink' target='_blank' class='wcd-padding-xsmall-left'>
							{{ "machines.entityDetails.actions.turnOnTroubleshootingMode.panel.privacyAndCookiesLink.text" | i18n }}
						</a>
					</div>

				</div>

				<footer
					class='wcd-padding-large-horizontal wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal'>
					<fab-primary-button
						data-track-id='Submit'
						data-track-type='Button'
						[disabled]='isSaving'
						(onClick)='submitRequest()'
						[text]="('buttons.submit' | i18n)">
						<fab-spinner *ngIf='isSaving' [size]='SpinnerSize.xSmall'></fab-spinner>
					</fab-primary-button>
				</footer>
			</form>
		</wcd-panel>
	`,
})
export class TroubleshootingModePanelComponent extends PanelContainer implements OnInit, OnDestroy {
	readonly SpinnerSize = SpinnerSize;

	@Input() machine?: Machine;

	collapsibleID = CollapsibleID;

	readonly investigationPackageCollectionRbac: RbacControlSettings = {
		state: RbacControlState.disabled,
		permissions: [RbacMdeAllowedActions.alertsInvestigation],
	};
	readonly requests$ = new BehaviorSubject<ReadonlyArray<MachineRequest>>([]);
	sections: ReadonlyArray<Section<MachineResponseType>>;
	currentMachine$: Observable<Machine>;
	isLoading: boolean = true;
	isSaving: boolean = false;

	private _dataSetSubscription: Subscription;

	tsDescription: string;
	tsDescription2: string;
	cookiesLink: string;

	constructor(
		router: Router,
		private paris: Paris,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private changeDetectionRef: ChangeDetectorRef
	) {
		super(router);
	}

	ngOnInit() {
		this.settings = {
			...this.settings,
			showOverlay: true,
			hasCloseButton: true,
			type: PanelType.large,
			noBodyPadding: true,
			isBlocking: true,
			className: this.settings.className || '',
		};

		this.cookiesLink = privacyAndCookiesLink;
		super.ngOnInit();
	}

	ngOnDestroy() {
		if (this._dataSetSubscription) {
			this._dataSetSubscription.unsubscribe();
		}
	}

	submitRequest() {
		if (this.isSaving) {
			return;
		}
		this.isSaving = true;
		const startTime = new Date();
		startTime.setSeconds(startTime.getSeconds() + 2);
		const expirationDate = new Date();
		// setHours and setMinutes mutates the expirationDate but returns a number. Will need to do in 2 steps
		expirationDate.setHours(startTime.getHours() + 3);
		expirationDate.setMinutes(startTime.getMinutes());
		this.paris.apiCall<TroubleshootResponse, TroubleshootParameters>(TroubleshootApiCall, {
			machine: this.machine,
			comment: `Start TroubleshootMode on machineId: ${this.machine.id}`,
			troubleshootExpirationDateTimeUtc: expirationDate,
			troubleshootStartDateTimeUtc: startTime,
			paramsJsonFormatVersion: 1,
			troubleshootState: 1,
		}).subscribe(() => {
			this.isSaving = false;
			this.dialogsService.showSuccessSnackbar({
				text: this.i18nService.get(
					'machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submitSuccess.snackbar'
				),
				icon: 'CheckMark',
			});
			this.changeDetectionRef.markForCheck();
			this.closePanel();
			}, (error) => {
				this.isSaving = false;
				this.dialogsService.showError({
					title: this.i18nService.get(
						'machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submit.failure.title'
					),
					data: this.i18nService.get(
						'machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submit.failure.description'
					),
				});
			},
		)
	}
}
