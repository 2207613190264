var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit, OnDestroy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { WebContentFilteringCategoryReport, WebContentFilteringWebCategoryRelationship, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { ActivatedRoute } from '@angular/router';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { TzDateService } from '@wcd/localization';
import { FabricIconNames } from '@wcd/scc-common';
var WebContentFilteringCategoryReportDetailsComponent = /** @class */ (function (_super) {
    __extends(WebContentFilteringCategoryReportDetailsComponent, _super);
    function WebContentFilteringCategoryReportDetailsComponent(paris, globalEntityTypesService, route, i18nService, tzDateService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.route = route;
        _this.i18nService = i18nService;
        _this.tzDateService = tzDateService;
        _this.loadingPolicies = true;
        _this.topDomainsFields = DataTableField.fromList([
            {
                id: 'domain',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.domain'),
                getDisplay: function (item) { return item.name; },
                getLink: function (item) { return "url/" + item.name; },
            },
            {
                id: 'count',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.accessCount'),
                getDisplay: function (item) { return item.count; },
            },
        ]);
        _this.topDevicesFields = DataTableField.fromList([
            {
                id: 'device',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.device'),
                getDisplay: function (item) { return item.name; },
                getLink: function (item) { return "machines/" + item.deviceId + "/main"; },
                icon: {
                    fabricIcon: function () { return FabricIconNames.System; },
                },
            },
            {
                id: 'deviceCount',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.deviceCount'),
                getDisplay: function (item) { return item.count; },
            },
        ]);
        _this.relatedPoliciesFields = DataTableField.fromList([
            {
                id: 'policy',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.policyName'),
                getDisplay: function (policy) { return policy.policyName; },
            },
            {
                id: 'action',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.name'),
                getDisplay: function (policy) {
                    if (policy.blockedCategoryIds.includes(_this.entity.id)) {
                        return _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.values.block');
                    }
                    else {
                        return _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.action.values.audit');
                    }
                },
            },
            {
                id: 'dateModified',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.dateApplied'),
                getDisplay: function (policy) { return _this.tzDateService.format(policy.lastUpdateTime, 'MM/dd/yyyy'); },
            },
            {
                id: 'editPolicy',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.columns.source'),
                getDisplay: function (policy) {
                    return _this.i18nService.get('reporting.webContentFilteringReport.pivots.webCategories.sections.relatedPolicies.editPolicy');
                },
                getLink: function (policy) { return "preferences2/web_content_filtering_policy/" + policy.id; },
            },
        ]);
        _this.entityType = _this.globalEntityTypesService.getEntityType(WebContentFilteringCategoryReport);
        _this.policyRepository = _this.paris.getRelationshipRepository(WebContentFilteringWebCategoryRelationship);
        return _this;
    }
    Object.defineProperty(WebContentFilteringCategoryReportDetailsComponent.prototype, "report", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebContentFilteringCategoryReportDetailsComponent.prototype, "trendsDeltaPercentageAsAbs", {
        get: function () {
            return Math.abs(this.entity.trendsDeltaPercentage);
        },
        enumerable: true,
        configurable: true
    });
    WebContentFilteringCategoryReportDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.categoryName = this.entityType.getEntityName(this.report).toLowerCase();
        var currentTimeRange = this.route.snapshot.queryParams.lookBackInDays;
        this.timeRange = currentTimeRange ? Number(currentTimeRange) : 30;
        this.policySubscription = this.policyRepository.queryForItem(this.entity).subscribe(function (data) {
            _this.relatedPolicies = data.items.sort(function (x, y) {
                return x.blockedCategoryIds.includes(_this.entity.id) ? -1 : 1;
            });
            _this.loadingPolicies = false;
        }, function (error) {
            _this.loadingPolicies = false;
        });
    };
    WebContentFilteringCategoryReportDetailsComponent.prototype.ngOnDestroy = function () {
        this.policySubscription && this.policySubscription.unsubscribe();
    };
    return WebContentFilteringCategoryReportDetailsComponent;
}(EntityPanelComponentBase));
export { WebContentFilteringCategoryReportDetailsComponent };
