var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, QueryList, OnDestroy, OnInit, } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { ListFilterValue } from '../list-filter-value.model';
import { flatMap, isEqual, isEqualWith, uniq, uniqWith } from 'lodash-es';
import { combineLatest, Observable, of, from } from 'rxjs';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var SERIALIZED_VALUE_SEPARATOR = '|';
/**
 * Just a very large number, no special meaning to the specific number.
 */
var LARGEST_FILTER_VALUE_PRIORITY = Infinity;
var FilterValuesChecklistComponent = /** @class */ (function (_super) {
    __extends(FilterValuesChecklistComponent, _super);
    function FilterValuesChecklistComponent(changeDetectorRef, i18nService, liveAnnouncer) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.i18nService = i18nService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.setKeyboardContainer = true;
        /**
         * Set to `true` to not have an 'Select All' option that selects all values.
         */
        _this.disableSelectAll = false;
        _this.elementVisible = true;
        _this.selectedValuesSet = new Set();
        _this.parentSelectedValuesSet = new Set();
        _this.partialValues = new Set();
        _this.values = [];
        _this.expandedValues = new Set();
        _this.childrenTabIndex = [];
        _this._categoryValues = new Map();
        _this.allFirstTabIndex = false;
        _this.firstTabIndex = true;
        _this.selectAllText = _this.i18nService.strings.filters_values_checklist_select_all;
        return _this;
    }
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "data", {
        set: function (data) {
            this._rawData = data;
            this.setValues();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "value", {
        get: function () {
            return this.values;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "config", {
        get: function () {
            return this._config;
        },
        set: function (config) {
            var _this = this;
            this._config = config;
            if (config) {
                var fixedValues = config.fixedValues, values = config.values;
                this.fixedValues = new Set(fixedValues && fixedValues.map(function (fixedValue) { return String(fixedValue); }));
                if (values) {
                    if (values instanceof Observable) {
                        this.getValuesSubscription = from(values).subscribe(function (_values) {
                            _this._rawValues = _values;
                            _this.setValues();
                        });
                    }
                    else {
                        this._rawValues = values;
                        this.setValues();
                    }
                }
            }
            else
                this.fixedValues = new Set();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "allowSingleValueDeselection", {
        get: function () {
            return this.config && this.config.allowSingleValueDeselection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "isAll", {
        get: function () {
            return this.selectedValuesSet && this.selectedValuesSet.size === this.values.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "selectionMode", {
        get: function () {
            return this.selectedValuesSet.size === this.values.length
                ? FilterValuesChecklistSelectionMode.All
                : !this.selectedValuesSet.size
                    ? FilterValuesChecklistSelectionMode.None
                    : FilterValuesChecklistSelectionMode.Some;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesChecklistComponent.prototype.ngOnInit = function () {
        this.setShowSelectAllCheckbox();
        this.changeDetectorRef.markForCheck();
    };
    FilterValuesChecklistComponent.prototype.ngOnDestroy = function () {
        if (this._getFilterValuesSubscription)
            this._getFilterValuesSubscription.unsubscribe();
        if (this.getValuesSubscription)
            this.getValuesSubscription.unsubscribe();
    };
    FilterValuesChecklistComponent.prototype.setValues = function () {
        var _this = this;
        if (this._getFilterValuesSubscription)
            this._getFilterValuesSubscription.unsubscribe();
        if (this._rawData || this._rawValues || this.selectedValuesSet.size) {
            var extraValues = (!this.parentValue &&
                this.config &&
                this.config.allowUnknownValues &&
                this.getExtraValues(Array.from(this.selectedValuesSet))) ||
                [];
            var rawValues = ((this._rawData && this._rawData.values) || this._rawValues || []).concat(extraValues);
            this._getFilterValuesSubscription = this.getFilterValues(rawValues).subscribe(function (values) {
                if (!isEqual(_this.values, values)) {
                    _this.values = values;
                    _this.setShowSelectAllCheckbox();
                    _this.changeDetectorRef.markForCheck();
                }
            });
        }
        else
            this.values = [];
        this.changeDetectorRef.detectChanges();
    };
    FilterValuesChecklistComponent.prototype.isValueSelected = function (filterValue) {
        var stringId = String(filterValue.id);
        return (this.selectedValuesSet.has(stringId) ||
            this.parentSelectedValuesSet.has(stringId) ||
            (this.fixedValues && this.fixedValues.has(stringId)) ||
            (!this.allowSingleValueDeselection && this.values.length === 1));
    };
    FilterValuesChecklistComponent.prototype.isValueDisabled = function (filterValue) {
        return ((this.fixedValues && this.fixedValues.has(String(filterValue.id))) ||
            (!this.allowSingleValueDeselection && this.values.length === 1));
    };
    FilterValuesChecklistComponent.prototype.isPartiallySelected = function (filterValue) {
        return this.partialValues.has(String(filterValue.id));
    };
    FilterValuesChecklistComponent.prototype.getCategoryValues = function (category) {
        var existingCategoryValues = this._categoryValues.get(category);
        if (!existingCategoryValues) {
            existingCategoryValues = { values: category.values };
            this._categoryValues.set(category, existingCategoryValues);
        }
        return existingCategoryValues;
    };
    FilterValuesChecklistComponent.prototype.toggleAll = function () {
        if (this.isAll) {
            this.setSelectedValues(null);
        }
        else {
            this.selectAllValues();
        }
        this.triggerChange();
    };
    Object.defineProperty(FilterValuesChecklistComponent.prototype, "isPartialSelected", {
        get: function () {
            return this.selectedValuesSet.size > 0 && this.selectedValuesSet.size < this.values.length;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesChecklistComponent.prototype.setSelectedValues = function (selectedValues) {
        if (this.childChecklists) {
            if (selectedValues && selectedValues.length) {
                var childValues_1 = new Set(flatMap(this.childChecklists.map(function (childChecklist) {
                    return childChecklist.values.map(function (value) { return String(value.id); });
                })));
                var nonChildSelectedValues = selectedValues.filter(function (value) { return !childValues_1.has(String(value)); });
                var relevantSelectedValues = nonChildSelectedValues.map(function (value) { return String(value); });
                if (this.parentValue)
                    relevantSelectedValues = this.removeUnknownValues(relevantSelectedValues);
                this.selectedValuesSet = new Set(relevantSelectedValues);
            }
            else
                this.selectedValuesSet = new Set([]);
            this.childChecklists.forEach(function (child) { return child.setSelectedValues(selectedValues); });
        }
        else
            this.selectedValuesSet = new Set(selectedValues ? selectedValues.map(function (value) { return String(value); }) : []);
        this.setValues();
        this.setParentValuesSelection();
    };
    FilterValuesChecklistComponent.prototype.removeUnknownValues = function (values) {
        var knownValuesSet = new Set(this.values.map(function (value) { return String(value.id); }));
        return values.filter(function (value) { return knownValuesSet.has(String(value)); });
    };
    FilterValuesChecklistComponent.prototype.selectAllValues = function () {
        var allValues = this.values.reduce(function (allSelectedValues, value) {
            var allChildValues = value.childCategories
                ? flatMap(value.childCategories, function (category) { return category.values; })
                : [];
            return allSelectedValues.concat(allChildValues.map(function (listValue) { return listValue.id; }), [value.id]);
        }, []);
        this.setSelectedValues(allValues);
    };
    FilterValuesChecklistComponent.prototype.serialize = function () {
        var _a;
        var mainSelectedValues = this.getSerializedSelectedValues();
        var childSelectedValues = flatMap(this.childChecklists.map(function (childChecklist) { return childChecklist.getSerializedSelectedValues(); }));
        var allSelectedValues = mainSelectedValues.concat(childSelectedValues);
        return allSelectedValues.length
            ? (_a = {},
                _a[this.fieldId] = allSelectedValues,
                _a) : null;
    };
    FilterValuesChecklistComponent.prototype.getSerializedSelectedValues = function () {
        return this.selectedValuesSet && this.selectedValuesSet.size
            ? Array.from(this.selectedValuesSet)
            : [];
    };
    FilterValuesChecklistComponent.prototype.deserialize = function (serializedSelectedValues) {
        var checklistSerializedValues = serializedSelectedValues && serializedSelectedValues[this.fieldId];
        if (checklistSerializedValues) {
            return checklistSerializedValues instanceof Array
                ? checklistSerializedValues
                : checklistSerializedValues.split(SERIALIZED_VALUE_SEPARATOR);
        }
        return [];
    };
    FilterValuesChecklistComponent.prototype.deselectAllValues = function () {
        this.setSelectedValues(null);
        this.partialValues.clear();
        this.triggerChange();
    };
    /**
     * Sets values that are in this.selectedValuesSet but aren't in this.values
     */
    FilterValuesChecklistComponent.prototype.getExtraValues = function (selectedValues) {
        var _this = this;
        if (!this._rawData)
            return selectedValues || [];
        return selectedValues.filter(function (selectedValue) {
            var strValue = String(selectedValue);
            return (!_this._rawData.values.find(function (value) { return String(value.value) === strValue; }) &&
                !_this.valuesContainValue(_this.values, strValue));
        });
    };
    FilterValuesChecklistComponent.prototype.valuesContainValue = function (values, value) {
        var _this = this;
        return values.some(function (_value) {
            if (String(_value.rawValue) === value || String(_value.id) === value)
                return true;
            if (_value.hasChildren) {
                var isChildValue = _this.valuesContainValue(flatMap(_value.childCategories, function (category) { return category.values; }), value);
                if (isChildValue)
                    return true;
            }
            return false;
        });
    };
    FilterValuesChecklistComponent.prototype.onToggleFieldValue = function (filterValue) {
        var filterValueId = String(filterValue.id);
        if (filterValue.hasChildren) {
            var parentNewValue = !this.parentSelectedValuesSet.has(filterValueId) && !this.partialValues.has(filterValueId);
            if (parentNewValue)
                this.parentSelectedValuesSet.add(filterValueId);
            else
                this.parentSelectedValuesSet.delete(filterValueId);
            this.partialValues.delete(filterValueId);
            this.setChildrenSelectedValues(filterValue, parentNewValue);
        }
        else {
            var newValue = !this.selectedValuesSet.has(filterValueId);
            var changedValues = new Map();
            changedValues.set(filterValue, newValue || null);
            if (newValue)
                this.selectedValuesSet.add(filterValueId);
            else
                this.selectedValuesSet.delete(filterValueId);
        }
        this.triggerChange();
    };
    FilterValuesChecklistComponent.prototype.setChildrenSelectedValues = function (filterValue, isSelected) {
        if (!filterValue.hasChildren)
            return;
        this.partialValues.delete(String(filterValue.id));
        var childChecklists = this.getFilterValueChildChecklists(filterValue);
        childChecklists.forEach(function (checklist) {
            if (isSelected)
                checklist.selectAllValues();
            else
                checklist.setSelectedValues([]);
        });
    };
    FilterValuesChecklistComponent.prototype.getFilterValueChildChecklists = function (filterValue) {
        return this.childChecklists
            ? this.childChecklists.filter(function (childChecklist) { return childChecklist.parentValue === filterValue; })
            : [];
    };
    FilterValuesChecklistComponent.prototype.onChildChecklistChange = function (filterValue) {
        this.triggerChange();
        this.setParentFilterValueSelectionMode(filterValue);
    };
    /**
     * Sets the selection state of values that have children - selected, partially selected or not selected.
     */
    FilterValuesChecklistComponent.prototype.setParentValuesSelection = function () {
        var _this = this;
        if (!this.childChecklists)
            return;
        this.values.forEach(function (filterValue) { return _this.setParentFilterValueSelectionMode(filterValue); });
    };
    FilterValuesChecklistComponent.prototype.setParentFilterValueSelectionMode = function (filterValue) {
        if (filterValue.hasChildren) {
            var selectionMode = this.getParentFilterValueSelectionMode(filterValue);
            var filterValueId = String(filterValue.id);
            this.partialValues.delete(filterValueId);
            switch (selectionMode) {
                case FilterValuesChecklistSelectionMode.None:
                    this.parentSelectedValuesSet.delete(filterValueId);
                    break;
                case FilterValuesChecklistSelectionMode.Some:
                    this.partialValues.add(filterValueId);
                default:
                    this.parentSelectedValuesSet.add(filterValueId);
            }
        }
    };
    FilterValuesChecklistComponent.prototype.getParentFilterValueSelectionMode = function (filterValue) {
        var allChildrenValues = this.getFilterValueChildChecklists(filterValue);
        if (!allChildrenValues.length)
            return null;
        return allChildrenValues.reduce(function (filterValueSelectionMode, checklist) {
            return checklist.selectionMode === filterValueSelectionMode
                ? filterValueSelectionMode
                : FilterValuesChecklistSelectionMode.Some;
        }, allChildrenValues[0].selectionMode);
    };
    FilterValuesChecklistComponent.prototype.triggerChange = function () {
        this.filterValuesChange.emit((this.selectedValues = this.getSelectedValues()));
    };
    /**
     * Returns the IDs of all selected values (including child lists) that don't have children.
     */
    FilterValuesChecklistComponent.prototype.getSelectedValues = function () {
        var _this = this;
        var selectedValues = Array.from(this.selectedValuesSet)
            .map(function (selectedValue) { return _this.values.find(function (value) { return selectedValue === String(value.id); }); })
            .filter(function (value) {
            return value && (!value.childCategories || !value.childCategories.length);
        })
            .map(function (value) { return value.id; });
        var childSelectedValues = this.childChecklists
            ? flatMap(this.childChecklists.map(function (childChecklist) { return childChecklist.getSelectedValues(); }))
            : [];
        return uniq(selectedValues.concat(childSelectedValues));
    };
    /**
     * Formats the raw values into values used by the component to render the checkboxes.
     */
    FilterValuesChecklistComponent.prototype.getFilterValues = function (rawFilterValues) {
        var _this = this;
        if (!rawFilterValues || !rawFilterValues.length)
            return of([]);
        var filterValues$ = rawFilterValues.map(function (value) {
            return ListFilterValue.fromValue(value, _this.config);
        });
        return combineLatest(filterValues$).pipe(map(function (filterValues) {
            var uniqueValues = uniqWith(filterValues, function (filterValueA, filterValueB) {
                return isEqualWith(filterValueA, filterValueB, function () { return filterValueA.id === filterValueB.id; });
            });
            return uniqueValues.sort(function (a, b) {
                if (!isNaN(a.priority) || !isNaN(b.priority)) {
                    var aPriority = a.priority || LARGEST_FILTER_VALUE_PRIORITY, bPriority = b.priority || LARGEST_FILTER_VALUE_PRIORITY;
                    return aPriority === bPriority ? 0 : aPriority > bPriority ? 1 : -1;
                }
                if (a.count === b.count)
                    return a.displayName > b.displayName ? 1 : -1;
                return b.count - a.count;
            });
        }));
    };
    FilterValuesChecklistComponent.prototype.setShowSelectAllCheckbox = function () {
        this.showSelectAllCheckbox =
            this.values.length > 1 &&
                (!this.config || (this.config && !this.config.disableSelectAll)) &&
                !this.disableSelectAll;
    };
    // Aria label type of "2 of 5 <filter value>"
    FilterValuesChecklistComponent.prototype.getCheckboxAriaLabel = function (i, fieldValue, hasChildren) {
        var selectAllAddition = this.showSelectAllCheckbox ? 1 : 0;
        var filterValuesCount = this.value.length + selectAllAddition;
        var ariaLabel = this.i18nService.get('common_filter_checkbox_aria_text', {
            index: i + 1 + selectAllAddition,
            total: filterValuesCount,
            filterValue: fieldValue,
        });
        if (hasChildren) {
            ariaLabel += ' ' + this.i18nService.get('common_filter_checkbox_children_available');
        }
        return ariaLabel;
    };
    FilterValuesChecklistComponent.prototype.toggleValue = function (event, value) {
        var _this = this;
        var strId = String(value.id);
        var expended = this.expandedValues.has(strId);
        this.liveAnnouncer.announce(this.i18nService.get(!expended ? "common.button.expanded" : "common.button.collapsed"), 'assertive', 1000).then(function () {
            setTimeout(function () {
                if (expended)
                    _this.expandedValues.delete(strId);
                else
                    _this.expandedValues.add(strId);
                event.stopPropagation();
                event.preventDefault();
                _this.changeDetectorRef.markForCheck();
            }, 200);
        });
    };
    FilterValuesChecklistComponent.prototype.isExpanded = function (value) {
        return this.expandedValues.has(String(value.id));
    };
    FilterValuesChecklistComponent.prototype.setChildrenTabIndex = function ($event, valueId, hasChildren) {
        if (hasChildren) {
            this.childrenTabIndex[valueId] = $event;
        }
    };
    FilterValuesChecklistComponent.prototype.getFirstTabIndex = function () {
        var isFirstTabIndex = this.firstTabIndex;
        this.firstTabIndex = false;
        return isFirstTabIndex;
    };
    return FilterValuesChecklistComponent;
}(FilterValuesComponent));
export { FilterValuesChecklistComponent };
var FilterValuesChecklistSelectionMode;
(function (FilterValuesChecklistSelectionMode) {
    FilterValuesChecklistSelectionMode[FilterValuesChecklistSelectionMode["All"] = 0] = "All";
    FilterValuesChecklistSelectionMode[FilterValuesChecklistSelectionMode["Some"] = 1] = "Some";
    FilterValuesChecklistSelectionMode[FilterValuesChecklistSelectionMode["None"] = 2] = "None";
})(FilterValuesChecklistSelectionMode || (FilterValuesChecklistSelectionMode = {}));
