var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    if (query && query.where && query.where['useV3Api']) {
        return 'cloud/live_response/create_session_canceled';
    }
    return query && query.where && query.where['useV2Api']
        ? 'v2/live_response/create_session_canceled'
        : 'live_response/create_session_canceled';
}, ɵ1 = function (config, query) { return query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr; }, ɵ2 = function (requestParams) {
    return {
        params: {
            machine_id: requestParams.machineId,
            machine_last_seen: requestParams.machineLastSeen,
            useV2Api: requestParams.useV2Api,
            useV3Api: requestParams.useV3Api,
        },
    };
};
var CancelCreateLiveResponseSessionApiCall = /** @class */ (function (_super) {
    __extends(CancelCreateLiveResponseSessionApiCall, _super);
    function CancelCreateLiveResponseSessionApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CancelCreateLiveResponseSessionApiCall = __decorate([
        ApiCall({
            name: 'API for tracking purpose - User cancel create live response session',
            endpoint: ɵ0,
            method: 'POST',
            baseUrl: ɵ1,
            parseQuery: ɵ2,
        })
    ], CancelCreateLiveResponseSessionApiCall);
    return CancelCreateLiveResponseSessionApiCall;
}(ApiCallModel));
export { CancelCreateLiveResponseSessionApiCall };
export { ɵ0, ɵ1, ɵ2 };
