/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { MainAppState, MainAppStateService } from '../services/main-app-state.service';
import { StateData, TitleService } from '../../services/title.service';
import { AuthService } from '@wcd/auth';
import {
	ExternalLink,
	HELP_LINKS_CONFIG,
	SCC_BASE_TAB_NAME,
	SCC_BASE_URL, SUPPORT_LINK_I18N_KEY,
} from '../services/main-nav-config.service';
import { getClientTimeZoneString, getUserTimeZone, LocaleConfigService, Config } from '@wcd/localization';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { FeedbackService } from '../../../feedback/services/feedback.service';
import { PreferencesService } from '@wcd/config';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { EnvironmentName } from '@wcd/domain';
import { SupportCentralService } from '../../../support-central/support-central.service';
import { I18nService } from '@wcd/i18n';

export const SELECTED_TIMEZONE_KEY = 'selectedTimezone';
export const SELECTED_LOCALE_KEY = 'selectedLocale';

@Component({
	selector: 'main-header',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './main-header.component.html',
	styleUrls: ['./main-header.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MainHeaderComponent {
	routeState: StateData;
	mainAppState: MainAppState;
	timeZoneOptions: Array<{ id: string; text: string; value: number }>;
	isInit: boolean = false;
	helpLinks: Array<ExternalLink>;
	enableSimulations: boolean;
	communityCount: number;
	availableLocales: Array<string> = this.localeConfigService.getSupportedLocales();
	showLocalization: boolean =
		getUserTimeZone() !== 0 || (this.availableLocales && this.availableLocales.length > 1);
	showMainSearch: boolean;
	evaluationEnabled: boolean;
	expertsOnDemandEnabled: boolean;
	expertsOnDemandEnabled2: boolean;
	isMtp: boolean;
	isFairfax: boolean;
	showMobileHeader: boolean;
	supportLinkI18nKey = SUPPORT_LINK_I18N_KEY;
	readonly sccBaseUrl = SCC_BASE_URL;
	readonly sccTabName = SCC_BASE_TAB_NAME;

	get mainAppLogoLink() {
		return {
			routerLink: this.isMtp ? null : '/dashboards_junction',
			externalLink: this.isMtp
				? this.sccBaseUrl
				: this.authService.isLoggedIn
				? null
				: '/dashboards_junction',
			externalLinkTarget: this.isMtp ? this.sccTabName : '_self',
			showExternalLinkIcon: false,
		};
	}

	toggleMobileMenu() {
		this.showMobileHeader = !this.showMobileHeader;
	}

	constructor(
		private changeDetectionRef: ChangeDetectorRef,
		private preferencesService: PreferencesService,
		public supportCentralService: SupportCentralService,
		public mainAppStateService: MainAppStateService,
		public feedbackService: FeedbackService,
		public askThreatExpertService: AskThreatExpertService,
		public authService: AuthService,
		public appConfigService: AppConfigService,
		public localeConfigService: LocaleConfigService,
		public notificationsService: NotificationsService,
		titleService: TitleService,
		featuresService: FeaturesService,
		appContextService: AppContextService,
		private evaluationEnabledGuard: EvaluationEnabledGuard,
		private i18nService: I18nService,
	) {
		this.isFairfax = appConfigService.environmentName === EnvironmentName.Fairfax;
		titleService.state$.subscribe((state: StateData) => {
			this.routeState = state;
			this.changeDetectionRef.markForCheck();
			this.isInit = true;
		});

		mainAppStateService.state$.subscribe((mainAppState: MainAppState) => {
			this.mainAppState = mainAppState;
			this.communityCount = +this.mainAppState.mainNavItemBadges.community;
			this.changeDetectionRef.markForCheck();
		});

		this.isMtp = appContextService.isMtp;
		this.helpLinks = HELP_LINKS_CONFIG.filter(link => !this.isMtp || link.isMtp).filter(link =>
			this.isFairfax ? link.isFairfax !== false : link.isFairfax !== true
		).filter(link => !supportCentralService.withSupportCentral || !link.isExternalSupportLink);
		this.showMainSearch =
			(!this.isMtp || featuresService.isEnabled(Feature.ShowMainSearch)) &&
			this.isInit &&
			appConfigService.isOnboardingComplete &&
			!appConfigService.isSuspended &&
			!appConfigService.isDeleted;
		this.enableSimulations = featuresService.isEnabled(Feature.SimulationsAndTutorials);
		this.evaluationEnabled = this.evaluationEnabledGuard.canActivate() as boolean;
		this.expertsOnDemandEnabled =
			featuresService.isEnabled(Feature.BilbaoExpertsOnDemand) &&
			this.appConfigService.isExposedToAllMachineGroups;
		this.expertsOnDemandEnabled2 = featuresService.isEnabled(Feature.BilbaoExpertsOnDemand2);

		this.timeZoneOptions = [
			{
				id: 'utc',
				text: this.i18nService.get('datepicker_timezoneUTC'),
				value: 0,
			},
			{
				id: 'local',
				text: this.i18nService.get('datepicker_timezoneLocalUTC', { clientTimeZone: getClientTimeZoneString() }),
				value: getUserTimeZone(),
			},
		];

		this.localeConfigService.config$.subscribe((config: Config) => {
			this.changeDetectionRef.markForCheck();
		});
	}

	private onTimezoneChange(timezone: number): void {
		this.localeConfigService.setSelectedTimezone(timezone);
		this.preferencesService.setPreference(SELECTED_TIMEZONE_KEY, timezone);
	}

	private onLocaleChange(locale: string): void {
		this.localeConfigService.setSelectedLocale(locale);
		this.preferencesService.setPreference(SELECTED_LOCALE_KEY, locale);
	}
}
