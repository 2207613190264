declare var angular: angular.IAngularStatic;

import { SevilleDowngradeModule } from '../../../seville.downgrade';
import { AppConfigService } from '@wcd/app-config';

export let SevilleUrlMappingModule = angular
	.module('seville.urlMapping', [SevilleDowngradeModule.name])
	.factory('urlMapping', urlMappingService);

urlMappingService.$inject = ['$log', 'appConfig'];

function urlMappingService($log, appConfig: AppConfigService) {
	var threatIntelUrl = appConfig.serviceUrls && appConfig.serviceUrls.threatIntel;
	var cyberDataUrl = appConfig.serviceUrls.cyberData;
	var investigationsUrlsMap = {
		base: threatIntelUrl + '/',
		relatives: {
			notifications: '/pending',
			investigations: '/investigations',
			investigation: '/investigation/',
			create: '/manual_investigation',
		},
	};

	var investigationsStateMap = {
		Terminated: 'Terminated',
		TerminatedByUser: 'TerminatedByUser',
		TerminatedBySystem: 'TerminatedBySystem',
		SuccessfullyRemediated: 'SuccessfullyRemediated',
		Benign: 'Benign',
		Failed: 'Failed',
		InnerFailure: 'Failed',
		PartiallyRemediated: 'PartiallyRemediated',
		Running: 'Running',
		PendingApproval: 'PendingApproval',
		PendingResource: 'PendingResource',
		Disabled: 'Disabled',
		Queued: 'Queued',
		UnsupportedAlertType: 'UnsupportedAlertType',
		UnsupportedOs: 'UnsupportedOs',
		PreexistingAlert: 'PreexistingAlert',
		SuppressedAlert: 'SuppressedAlert',
		PartiallyInvestigated: 'PartiallyInvestigated',
	};

	$log.debug('Threat intel service url mapped to: ' + threatIntelUrl);

	var service = {
		getThreatIntelUrl: function() {
			return threatIntelUrl;
		},
		getActiveAlertsSummaryUrl: function() {
			return appConfig.serviceUrls.activeAlertsSummary;
		},
		getIsUserExposedToAlertUrl: function() {
			return appConfig.serviceUrls.isUserExposedToAlert;
		},
		getAuditHistoryUrl: function() {
			return appConfig.serviceUrls.auditHistory;
		},
		investigationsUrls: investigationsUrlsMap,
		investigationsStates: investigationsStateMap,
		cyberDataUrl: cyberDataUrl,
	};

	return service;
}
