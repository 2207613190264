import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as Requests from '../machine-requests.models';
import { MachineLogsCollectionRequest } from './logs-collection-request.value-object';

export type CancelLogsCollectionRawApiRequestBody = Requests.CancelApiRequestBody<
	'LogsCollectionRequest'
>;
export type CancelLogsCollectionParameters = Requests.CancelParameters;

@ApiCall({
	name: 'Cancel logs collection',
	endpoint: 'requests/cancelbyid',
	method: 'POST',
	type: MachineLogsCollectionRequest,
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		requestGuid,
		comment,
	}: CancelLogsCollectionParameters): HttpOptions<
		CancelLogsCollectionRawApiRequestBody
	> => ({
		params: {
			requestGuid: requestGuid,
			comment: comment,
		},
	}),
})
export class MachineCancelLogsCollectionApiCall extends ApiCallModel<
	MachineLogsCollectionRequest,
	CancelLogsCollectionParameters
> {}
