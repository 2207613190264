import { TvmTopEventsWidget } from './dashboard/widgets/top-events/top-events.widget';
import { TvmDashboardComponent } from './dashboard/tvm.dashboard.component';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { ReportsModule } from '../reports/reports.module';
import { ActiveAlertsCardComponent } from './software/components/active-alerts-card/active-alerts-card.component';
import { AssetsAtRiskComponent } from './software/components/assets-at-risk-card/assets-at-risk-card.component';
import { TvmOrgExposureScoreWidget } from './dashboard/widgets/org-exposure-score/org-exposure-score.widget';
import { TvmSecureConfigurationScoreWidget } from './dashboard/widgets/secure-configuration-score/secure-configuration-score.widget';
import { TvmTopVulnerableSoftwareWidget } from './dashboard/widgets/top-vulnerable-software/top-vulnerable-software.widget';
import { TvmTopExposedMachinesWidget } from './dashboard/widgets/top-exposed-machines/top-exposed-machines.widget';
import { TvmActiveRemediationTasksWidget } from './dashboard/widgets/active-remediation-tasks/tvm-active-remediation-tasks.widget';
import { TvmThreatCampaignsWidget } from './dashboard/widgets/threat-campaigns/threat-campaigns.widget';
import { TvmDevicesExposureDistributionWidget } from './dashboard/widgets/devices-exposure-distribution/devices-exposure-distribution.widget';
import { FabIconModule, FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import {
	BarsChartModule,
	DonutChartModule,
	GaugesModule,
	LegendModule,
	PieChartModule,
	TimeSeriesChartModule,
	LineChartModule,
	AreaChartModule,
} from '@wcd/charts';
import { TagsModule } from '../tags/tags.module';
import { TvmThreatIconsComponent } from './components/tvm-threat-icons/tvm-threat-icons.component';
import { TvmReportInaccuracyDropdownComponent } from './components/report-inaccuracy/tvm-report-inaccuracy-dropdown.component';
import { TvmColorService } from './services/tvm-color.service';
import { TvmExposureScoreService } from './services/tvm-exposure-score.service';
import { TvmWeaknessSeverityService } from './services/tvm-vulnerability-severity.service';
import { TvmSecurityRecommendationCardComponent } from './dashboard/security-recommendations/security-recommendation-card/security-recommendation-card.component';
import { TvmSecurityRecommendationsComponent } from './dashboard/security-recommendations/security-recommendations.component';
import { TvmSeverityDistributionComponent } from './modules/tvm-severity-distribution/tvm-severity-distribution.component';
import { TvmImpactScoreComponent } from './components/tvm-impact-score/tvm-impact-score.component';
import { TvmHistoryTrendComponent } from './components/tvm-history-trend/tvm-history-trend.component';
import { RemediationTaskFixedAssetsPipe } from './pipes/remediation-task-fixed-assets.pipe';
import { SoftwareInstallationNormalizedDisplayPipe } from './pipes/software-installation-normalized-display.pipe';
import { RemediationTaskDisplayRemainingDaysPipe } from './pipes/remediation-task-display-remaining-days.pipe';
import { TvmTextsService } from './services/tvm-texts.service';
import { TvmDownloadService } from './services/tvm-download.service';
import { BreadcrumbsResolver } from '../breadcrumbs/services/breadcrumbs-resolver.service';
import {
	MdeAllowedActionsGuard,
	AuthenticatedGuard,
	OnboardGuard,
	FeatureEnabledGuard,
	FlavorGuard,
} from '@wcd/auth';
import { TvmIconBuilderService } from './services/tvm-icon-builder.service';
import { Feature } from '@wcd/config';
import { TvmMachineGroupsFilterControlComponent } from './components/machine-groups-filter/machine-groups-filter-control/machine-groups-filter-control.component';
import { MachineGroupsFilterPanelComponent } from './components/machine-groups-filter/machine-groups-filter-panel/machine-groups-filter-panel.component';
import { RemediationTaskStatusBarComponent } from './components/remediation-task.status-bar/remediation-task.status-bar.component';
import { FiltersModule } from '@wcd/ng-filters';
import { TvmAssetsStatisticsComponent } from './components/tvm-assets-statistics/tvm-assets-statistics.component';
import { TaggedFieldComponent } from './components/tagged-field/tagged-field.component';
import { ReportableBaseFieldComponent } from './components/report-inaccuracy/tvm-reportable-base-field.component';
import { PanelsModule } from '@wcd/panels';
import { TvmReportInaccuracyService } from './services/tvm-report-inaccuracy.service';
import { TvmReportInaccuracyPanelComponent } from './components/report-inaccuracy/report-inaccuracy-panel/report-inaccuracy-panel.component';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { TvmOsSupportService } from './services/tvm-os-support.service';
import { TvmDashboardEmptyComponent } from './dashboard/tvm.dashboard-empty.component';
import { TvmTagsService } from './services/tvm-tags.service';
import { TvmProductivityImpactService } from './services/tvm-productivity-impact.service';
import { ExposedOperatingSystemsComponent } from './components/tooltips/exposed-operating-systems/exposed-operating-systems.component';
import { DataTableModule } from '@wcd/datatable';
import { TvmRecommendationContextService } from './services/tvm-recommendation-context.service';
import { TvmChartTooltipComponent } from './components/tooltips/events/tvm-chart-tooltip.component';
import { TvmMachineGroupsDropdownComponent } from './components/machine-groups-dropdown/machine-groups-dropdown.component';
import { TvmScoreTrendComponent } from './components/tvm-score-trend/tvm-score-trend.component';
import { TvmCountHeaderComponent } from './components/tvm-count-header/tvm-count-header.component';
import { TvmTopChangeEventsService } from './services/tvm-top-change-events.service';
import { ChangeEventTextService } from './services/tvm-change-events-texts.service';
import { TvmTaggedFieldService } from './services/tagged-field.service';
import { BaselineComplianceProfileStatusColorService } from './services/baseline-compliance-profile-status-color.service';
import { CalculatePercentagePipe } from './pipes/calculate-percentage.pipe';
import { TvmProfilesComplianceStatusBarsComponent } from './components/tvm-profile-compliance-status-bars/tvm-profiles-compliance-status-bars.component';
import { TvmProfilesComplianceStatusBarsService } from './services/tvm-profiles-compliance-status-bars.service';
import { TvmNotificationsTextsService } from './services/tvm-notifications-texts.service';
import { BaselineCompliantBarComponent } from './components/baseline-compliant-bar/baseline-compliant-bar.component';
import { BaselineCompliantColorService } from './services/baseline-compliant-color.service';
import { BaselineCompliantBarService } from './services/baseline-compliant-bar.service';
import { ConvergencePromotionModule } from '../convergence-promotion/convergence-promotion.module';
import { AppFlavorConfig } from '@wcd/scc-common';
import { BaselineDetectedValuesService } from './services/baseline-detected-value.service';

const tvmRoutes: Routes = [
	{
		path: 'tvm_dashboard',
		canActivate: [
			FeatureEnabledGuard,
			AuthenticatedGuard,
			OnboardGuard,
			MdeAllowedActionsGuard,
			FlavorGuard,
		],
		component: TvmDashboardComponent,
		data: {
			breadcrumbsConfig: {
				itemConfig: {
					id: `tvmDashboard`,
					label: 'Threat and Vulnerability Management',
					icon: 'dashboard',
					url: '/tvm_dashboard',
				},
				resetListOnEnter: true,
				show: false,
			},
			features: [Feature.TvmPages],
			pageTitleKey: 'menuSections.tvm',
			MdeAllowedActionsGuardConfig: {
				AllowedActions: MdeUserRoleActionEnum.tvmViewData,
			},
			flavorConfig: AppFlavorConfig.routes.tvm,
		},
		resolve: {
			breadcrumbs: BreadcrumbsResolver,
		},
	},
];

@NgModule({
	imports: [
		RouterModule.forChild(tvmRoutes),
		SharedModule,
		ReportsModule,
		FabIconModule,
		GaugesModule,
		TagsModule,
		FiltersModule,
		FabSpinnerModule,
		FabMessageBarModule,
		DonutChartModule,
		BarsChartModule,
		LegendModule,
		TimeSeriesChartModule,
		PieChartModule,
		PanelsModule,
		DataTableModule,
		LineChartModule,
		AreaChartModule,
		ConvergencePromotionModule,
	],
	declarations: [
		TvmDashboardComponent,
		ActiveAlertsCardComponent,
		AssetsAtRiskComponent,
		TvmSecurityRecommendationCardComponent,
		TvmSecurityRecommendationsComponent,
		TvmSeverityDistributionComponent,
		TvmActiveRemediationTasksWidget,
		TvmOrgExposureScoreWidget,
		TvmSecureConfigurationScoreWidget,
		TvmTopVulnerableSoftwareWidget,
		TvmTopExposedMachinesWidget,
		TvmTopEventsWidget,
		TvmThreatCampaignsWidget,
		TvmDevicesExposureDistributionWidget,
		TvmReportInaccuracyDropdownComponent,
		TvmThreatIconsComponent,
		TvmImpactScoreComponent,
		TvmAssetsStatisticsComponent,
		TvmHistoryTrendComponent,
		TvmScoreTrendComponent,
		TvmMachineGroupsFilterControlComponent,
		MachineGroupsFilterPanelComponent,
		TvmReportInaccuracyPanelComponent,
		RemediationTaskStatusBarComponent,
		RemediationTaskFixedAssetsPipe,
		SoftwareInstallationNormalizedDisplayPipe,
		RemediationTaskDisplayRemainingDaysPipe,
		CalculatePercentagePipe,
		TaggedFieldComponent,
		ReportableBaseFieldComponent,
		TvmDashboardEmptyComponent,
		TvmChartTooltipComponent,
		ExposedOperatingSystemsComponent,
		TvmMachineGroupsDropdownComponent,
		TvmCountHeaderComponent,
		TvmProfilesComplianceStatusBarsComponent,
		BaselineCompliantBarComponent,
	],
	entryComponents: [
		TvmThreatCampaignsWidget,
		TvmActiveRemediationTasksWidget,
		TvmOrgExposureScoreWidget,
		TvmSecureConfigurationScoreWidget,
		TvmTopVulnerableSoftwareWidget,
		TvmTopExposedMachinesWidget,
		TvmTopEventsWidget,
		TvmDevicesExposureDistributionWidget,
		TvmReportInaccuracyDropdownComponent,
		TvmThreatIconsComponent,
		TvmImpactScoreComponent,
		TvmAssetsStatisticsComponent,
		TvmHistoryTrendComponent,
		TvmScoreTrendComponent,
		MachineGroupsFilterPanelComponent,
		TvmReportInaccuracyPanelComponent,
		RemediationTaskStatusBarComponent,
		TaggedFieldComponent,
		ReportableBaseFieldComponent,
		TvmDashboardEmptyComponent,
		TvmChartTooltipComponent,
		ExposedOperatingSystemsComponent,
		TvmProfilesComplianceStatusBarsComponent,
		BaselineCompliantBarComponent,
	],
	exports: [
		RouterModule,
		ActiveAlertsCardComponent,
		AssetsAtRiskComponent,
		TvmSeverityDistributionComponent,
		TvmReportInaccuracyDropdownComponent,
		TvmThreatIconsComponent,
		TvmImpactScoreComponent,
		TvmAssetsStatisticsComponent,
		TvmMachineGroupsFilterControlComponent,
		TvmScoreTrendComponent,
		TvmHistoryTrendComponent,
		RemediationTaskStatusBarComponent,
		RemediationTaskFixedAssetsPipe,
		RemediationTaskDisplayRemainingDaysPipe,
		CalculatePercentagePipe,
		TaggedFieldComponent,
		ReportableBaseFieldComponent,
		ExposedOperatingSystemsComponent,
		TvmMachineGroupsDropdownComponent,
		TvmCountHeaderComponent,
		TvmProfilesComplianceStatusBarsComponent,
		BaselineCompliantBarComponent,
	],
	providers: [
		TvmReportInaccuracyService,
		TvmExposureScoreService,
		TvmWeaknessSeverityService,
		TvmColorService,
		BaselineComplianceProfileStatusColorService,
		BaselineCompliantColorService,
		TvmProductivityImpactService,
		TvmProfilesComplianceStatusBarsService,
		BaselineDetectedValuesService,
		BaselineCompliantBarService,
		TvmRecommendationContextService,
		TvmTopChangeEventsService,
		ChangeEventTextService,
		TvmTextsService,
		TvmDownloadService,
		TvmIconBuilderService,
		RemediationTaskFixedAssetsPipe,
		SoftwareInstallationNormalizedDisplayPipe,
		CalculatePercentagePipe,
		RemediationTaskDisplayRemainingDaysPipe,
		TvmOsSupportService,
		TvmTagsService,
		TvmTaggedFieldService,
		TvmNotificationsTextsService,
	],
})
export class TvmModule {}
