import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { combineLatest, merge, Observable, of, throwError } from 'rxjs';
import { Dictionary } from '@wcd/config';
import { map, mergeMap, share, tap } from 'rxjs/operators';
import { intersection, isEqual } from 'lodash-es';
var ReadOnlyStore = /** @class */ (function () {
    function ReadOnlyStore(backendService, ngZone, itemConstructor, options) {
        this.backendService = backendService;
        this.ngZone = ngZone;
        this.itemConstructor = itemConstructor;
        this.options = options;
        this._isLoading = false;
        this._isInit = false;
        this._itemActions = {};
        this._isInit = options.requiresInit === false;
        if (!this.options.requiresInit)
            this.setItemsObservable();
    }
    Object.defineProperty(ReadOnlyStore.prototype, "isLoading", {
        get: function () {
            return this._isLoading;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadOnlyStore.prototype, "cachedItems", {
        get: function () {
            return this._items;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadOnlyStore.prototype, "isEmpty", {
        get: function () {
            return !this._items || !this._items.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadOnlyStore.prototype, "isInit", {
        get: function () {
            return this._isInit;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReadOnlyStore.prototype, "itemsDictionary", {
        get: function () {
            if (this._items && !this._itemsDictionary)
                this.setDictionary();
            return this._itemsDictionary;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Modifies an item's data before it's passed to the T constructor
     * @param itemData
     * @returns {any}
     */
    ReadOnlyStore.prototype.parseData = function (itemData) {
        return itemData;
    };
    ReadOnlyStore.prototype.createItem = function (itemData) {
        var parsedItemData = this.parseData(itemData) || itemData;
        return new (Function.prototype.bind.apply(this.itemConstructor, [null].concat(this.options.itemParams || [])))(parsedItemData);
    };
    ReadOnlyStore.prototype.updateItem = function (item, itemData) {
        if (isEqual(item.__data, itemData))
            return false;
        item.setData.apply(item, [itemData].concat(this.options.itemParams));
        return true;
    };
    ReadOnlyStore.prototype.cloneItem = function (item) {
        return this.createItem(item.__data);
    };
    ReadOnlyStore.prototype.getCachedItem = function (itemId) {
        return this._itemsDictionary ? this._itemsDictionary.get(itemId) : null;
    };
    ReadOnlyStore.prototype.trackItemId = function (index, item) {
        return String(item.id);
    };
    ReadOnlyStore.prototype.parseItems = function (items) {
        return items;
    };
    ReadOnlyStore.prototype.getItemById = function (itemId, options, fromCache, refreshItems) {
        var _this = this;
        if (refreshItems === void 0) { refreshItems = true; }
        if ((this.options.cacheItems && fromCache !== false) || (!this.options.cacheItems && fromCache)) {
            return new Observable(function (observer) {
                if (_this.itemsDictionary) {
                    var cacheItem = _this.itemsDictionary.get(itemId);
                    if (cacheItem) {
                        observer.next(cacheItem);
                        return;
                    }
                }
                if (refreshItems !== false) {
                    _this.items$.subscribe(function () {
                        if (!_this._itemsDictionary)
                            _this.setDictionary();
                        observer.next(_this._itemsDictionary.get(itemId));
                    });
                }
                else
                    observer.next(null);
            });
        }
        else
            return this.backendService.getItem(itemId, options).pipe(mergeMap(function (itemData) {
                try {
                    return of(_this.createItem(itemData));
                }
                catch (e) {
                    return throwError(e);
                }
            }));
    };
    ReadOnlyStore.prototype.getItemsByIds = function (itemIds) {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.itemsDictionary) {
                observer.next(_this.itemsDictionary.getList(itemIds));
                return observer.complete();
            }
            _this.items$.subscribe(function () {
                if (!_this._itemsDictionary)
                    _this.setDictionary();
                observer.next(_this._itemsDictionary.getList(itemIds));
            });
        });
    };
    ReadOnlyStore.prototype.initDependencies = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        return new Observable(function (observer) {
            if (!_this.options.dependencies || !_this.options.dependencies.length) {
                observer.next();
                observer.complete();
            }
            else {
                combineLatest(_this.options.dependencies.map(function (service) { return service.init(forceRefresh); })).subscribe(function (values) {
                    observer.next(values);
                    observer.complete();
                    return values;
                }, function (error) { return observer.error(error); });
            }
        });
    };
    ReadOnlyStore.prototype.setItemsObservable = function () {
        var _this = this;
        var itemsObservable = new Observable(function (observer) {
            _this._itemsObserver = observer;
        }).pipe(share());
        var loaderObservable = new Observable(function (observer) {
            return observer.next([]);
        }).pipe(tap(function () {
            _this._isLoading = true;
        }));
        var mergedObservable = merge(itemsObservable, this.backendService.getAllResults().pipe(map(function (itemsDataSet) {
            if (!itemsDataSet.items)
                throw _this.options.itemNameSingular + " loaded with no 'items' property.";
            _this._items = itemsDataSet.items.map(function (itemData) { return _this.createItem(itemData); });
            _this._items = _this.parseItems(_this._items);
            if (_this._itemsDictionary || _this.options.cacheItems)
                _this.setDictionary();
            _this.ngZone.run(function () {
                _this._isLoading = false;
            });
            return _this._items;
        }), share()));
        this.items$ = loaderObservable.pipe(mergeMap(function () { return mergedObservable; }));
    };
    ReadOnlyStore.prototype.init = function (forceRefresh) {
        var _this = this;
        if (forceRefresh === void 0) { forceRefresh = false; }
        if (this.options.requiresInit)
            this.setItemsObservable();
        var initObservable = new Observable(function (observer) {
            if (_this.isInit && !forceRefresh) {
                observer.next(_this._items);
                observer.complete();
            }
            else {
                _this.items$.subscribe(function (items) {
                    _this._isInit = true;
                    observer.next(items);
                    observer.complete();
                }, function (error) {
                    var errorMessage = "Error preloading " + _this.options.itemNamePlural + ".";
                    console.error(errorMessage, error);
                    var err = {
                        message: errorMessage,
                        error: error,
                    };
                    observer.error(err);
                }, function () { return observer.complete(); });
            }
        });
        return this.initDependencies(forceRefresh).pipe(mergeMap(function () { return initObservable; }));
    };
    ReadOnlyStore.prototype.getItemsDataSet = function (options) {
        var _this = this;
        var getResultsObservable = this.backendService.getAllResults(options).pipe(map(function (rawDataSet) {
            return {
                count: rawDataSet.count,
                items: rawDataSet.items.map(function (itemData) { return _this.createItem(itemData); }),
                options: options,
            };
        }));
        return getResultsObservable;
    };
    ReadOnlyStore.prototype.setDictionary = function () {
        this._itemsDictionary = Dictionary.fromList(this._items, 'id');
    };
    ReadOnlyStore.prototype.getExportItemAction = function () {
        var _this = this;
        return new ItemActionModel({
            id: 'export',
            name: 'Export',
            icon: 'export',
            method: function (items) { return _this.exportItems(items); },
            tooltip: 'Export the selected ' + this.options.itemNamePlural,
            refreshOnResolve: true,
        });
    };
    /**
     * Returns all the actions the are common to the given items
     * @param items
     */
    ReadOnlyStore.prototype.getItemsActions = function (items) {
        var _this = this;
        return intersection.apply(this, items.map(function (item) { return _this.getItemActions(item); }));
    };
    /**
     * Returns the actions that are supported by the given item
     * @param item
     */
    ReadOnlyStore.prototype.getItemActions = function (item) {
        return [];
    };
    ReadOnlyStore.prototype.exportAllResults = function (options, format) {
        if (format === void 0) { format = 'csv'; }
        return this.backendService.exportAllResults(options, format);
    };
    ReadOnlyStore.prototype.exportItems = function (items, apiOptions) {
        return this.backendService.export(items.map(function (item) { return item.id; }), apiOptions);
    };
    ReadOnlyStore.prototype.getAvailableAction = function (actionId, getNewAction) {
        var action = this._itemActions[actionId];
        if (!action)
            action = this._itemActions[actionId] = getNewAction.bind(this)();
        return action;
    };
    return ReadOnlyStore;
}());
export { ReadOnlyStore };
