import {
	Directive,
	ElementRef,
	HostListener,
	Input,
	OnDestroy,
	EventEmitter,
	Output,
	SecurityContext,
} from '@angular/core';
import { Dimensions } from '../shared/dimensions.interface';
import { TooltipsService } from './tooltips.service';
import { Position } from '../shared/position.interface';
import { DomSanitizer } from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';

const TOOLTIP_DELAY = 600;
const TOOLTIP_HIDE_DELAY = 300;
const ESC = 27;
const SPACE = 32;

@Directive({
	selector: '[wcdTooltip]',
})
export class TooltipDirective implements OnDestroy {
	private el: HTMLElement;
	private tooltipDimensions: Dimensions;
	private _tooltipCssClass = '';
	private showTimeout;
	private hideTimeout;
	private _ignoreMouseLeave = false;
	private ownsTooltip = false;

	updateTooltipRemoved() {
		this.ownsTooltip = false;
	}

	@Input('wcdTooltip') tooltipHTML: string;
	@Input() tooltipInnerComponent: any;
	@Input() innerComponentInput: any;
	@Input() wcdTooltipShowOnOverflowOnly = false;
	@Input() wcdTooltipEnableKeyboardEvents = false;

	@Input()
	set tooltipCssClass(className: string) {
		this._tooltipCssClass = className || '';
	}
	@Input()
	includeWcdTooltipAnnouncer = false;

	@Input() filterHtml = false;
	// Important note about wcdTooltipSanitizeHtml and wcdTooltipAllowHtmlRendering:
	// wcdTooltipSanitizeHtml must be TRUE for all cases except a rare case when
	// the tooltip must be rendered as HTML and the content is safe or already sanitizied.
	// Note that if wcdTooltipAllowHtmlRendering is enabled, the tooltip is treated as HTML and exposed to XSS vulnerability.
	// So setting wcdTooltipSanitizeHtml to true (or not specifying it and keep the default) ensures the tooltip will be sanitized.
	@Input() wcdTooltipSanitizeHtml = true;
	@Input() wcdTooltipAllowHtmlRendering = false;

	@Output() onDisplay = new EventEmitter<void>();

	constructor(
		el: ElementRef,
		private tooltipsService: TooltipsService,
		private domSanitizer: DomSanitizer
	) {
		this.el = el.nativeElement;
	}

	ngOnDestroy() {
		this.hideTooltip();
	}

	@HostListener('focus', ['$event'])
	onFocus(evt) {
		if (
			!document.body.classList.contains('ms-Fabric--isFocusHidden') &&
			!this.wcdTooltipEnableKeyboardEvents
		) {
			this._ignoreMouseLeave = true;
			setTimeout(() => {
				this._ignoreMouseLeave = false;
			}, 1000);
			this.setUpTooltip();
		}
	}

	@HostListener('mouseenter', ['$event'])
	onMouseEnter(evt) {
		this.setUpTooltip(evt);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(evt) {
		if (evt.keyCode == ESC) {
			if (this.ownsTooltip && !this._ignoreMouseLeave) {
				this.preventDefault(evt);
			}
			this.onMouseLeave();
		}
		if (this.wcdTooltipEnableKeyboardEvents && evt.keyCode === SPACE) {
			this.setUpTooltip();
			this.preventDefault(evt);
		}
	}

	@HostListener('mouseleave')
	onMouseLeave() {
		if (this._ignoreMouseLeave) return;

		clearTimeout(this.showTimeout);

		if (!this.ownsTooltip) return;

		this.hideTimeout = setTimeout(() => {
			clearTimeout(this.hideTimeout);
			if (!this.tooltipsService.isMouseOver) {
				this.hideTooltip();
			}
		}, TOOLTIP_HIDE_DELAY);
	}

	hideTooltip() {
		clearTimeout(this.showTimeout);
		clearTimeout(this.hideTimeout);
		if (!this.ownsTooltip) return;
		this.ownsTooltip = false;
		this.tooltipsService.toggleVisibility(false);
		this.tooltipsService.unregisterTooltipEvents();
	}

	private get tooltipText(): string {
		if (this.wcdTooltipAllowHtmlRendering) {
			if (this.filterHtml && this.isContainsHtml(this.tooltipHTML)) {
				return '';
			}
		}
		return this.wcdTooltipAllowHtmlRendering && this.wcdTooltipSanitizeHtml
			? this.sanatizeHTML(this.tooltipHTML)
			: this.tooltipHTML;
	}

	private sanatizeHTML(html) {
		if (sccHostService.isSCC) {
			return sccHostService.ui.sanitize(html as string);
		}
		return this.domSanitizer.sanitize(SecurityContext.HTML, html);
	}

	private setUpTooltip(evt?: MouseEvent) {
		if (this.tooltipHTML || this.tooltipInnerComponent) {
			clearTimeout(this.showTimeout);
			if (this.wcdTooltipShowOnOverflowOnly && !this.isOverflown) {
				return;
			}
			if (this.tooltipInnerComponent) {
				this.tooltipsService.setCustomComponentTooltip(
					this.tooltipInnerComponent,
					this.updateTooltipRemoved.bind(this),
					this.innerComponentInput
				);
			} else {
				this.tooltipsService.setTextTooltip(
					this.tooltipText,
					this.updateTooltipRemoved.bind(this),
					this.wcdTooltipAllowHtmlRendering
				);
			}
			this.tooltipsService.setTooltipClassName(this._tooltipCssClass);
			if (this.includeWcdTooltipAnnouncer) {
				this.tooltipsService.announce(this.tooltipText);
			}
			this.tooltipDimensions = this.tooltipsService.getTooltipDimensions();
			this.showTimeout = setTimeout(() => {
				this.onDisplay.emit();
				this.ownsTooltip = true;
				this.tooltipsService.toggleVisibility(true);
				this.setTooltipPosition(evt);
			}, TOOLTIP_DELAY);
		}
	}

	private setTooltipPosition(e?: MouseEvent) {
		if (e) {
			//set the tooltip next to the mouse
			this.tooltipsService.setTooltipPosition(
				{ top: e.clientY, left: e.clientX },
				this.tooltipDimensions
			);
		} else {
			//set the tooltip next to the host element
			const elOffset = this.offset(this.el);
			this.tooltipsService.setTooltipPosition(
				{ top: elOffset.top, left: elOffset.left },
				this.tooltipDimensions
			);
		}
	}

	private offset(el: Element): Position {
		const rect = el.getBoundingClientRect(),
			scrollLeft = window.pageXOffset || document.documentElement.scrollLeft,
			scrollTop = window.pageYOffset || document.documentElement.scrollTop;
		return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
	}

	private preventDefault(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}

	get isOverflown(): boolean {
		const tooltipTmpElement: HTMLElement = document.createElement('span');
		if (this.wcdTooltipAllowHtmlRendering) {
			tooltipTmpElement.innerHTML = this.tooltipText;
		} else {
			tooltipTmpElement.innerText = this.tooltipText;
		}
		this.el.ownerDocument.body.appendChild(tooltipTmpElement);
		const ellipsisActive: boolean = this.el.scrollWidth < tooltipTmpElement.offsetWidth;
		tooltipTmpElement.parentNode.removeChild(tooltipTmpElement);
		return ellipsisActive;
	}

	isContainsHtml(value: string) {
		if (!value || typeof value !== 'string') {
			return false;
		}
		return /<((?=!\-\-)!\-\-[\s\S]*\-\-|((?=\?)\?[\s\S]*\?|((?=\/)\/[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*|[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:\s[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:=(?:"[^"]*"|'[^']*'|[^'"<\s]*))?)*)\s?\/?))>/.test(
			value
		);
	}
}
