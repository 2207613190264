import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';
import { AuthService } from '../services/auth.service';

@Injectable()
export class MdatpGuard implements CanActivate {
	constructor(private authService: AuthService,
		private appConfigService: AppConfigService,
		private router: Router) {}

	canActivate(): boolean {		
		if (sccHostService.isSCC  &&  !this.appConfigService.isMdatpActive)
		{			
			return false;
		}

		return true;
	}	
}
