/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "./horizontal-bars-chart.component";
var styles_HorizontalBarsChartComponent = [];
var RenderType_HorizontalBarsChartComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HorizontalBarsChartComponent, data: {} });
export { RenderType_HorizontalBarsChartComponent as RenderType_HorizontalBarsChartComponent };
export function View_HorizontalBarsChartComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), i0.ɵqud(671088640, 1, { horizontalBarsChart: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "div", [["class", "chart"]], [[1, "aria-label", 0]], null, null, null, null)), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "charts.horizontalBarsChart")); _ck(_v, 3, 0, currVal_0); }); }
export function View_HorizontalBarsChartComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-horizontal-bars-chart", [], null, null, null, View_HorizontalBarsChartComponent_0, RenderType_HorizontalBarsChartComponent)), i0.ɵdid(1, 770048, null, 0, i3.HorizontalBarsChartComponent, [i0.ElementRef, i2.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HorizontalBarsChartComponentNgFactory = i0.ɵccf("wcd-horizontal-bars-chart", i3.HorizontalBarsChartComponent, View_HorizontalBarsChartComponent_Host_0, { settings: "settings", data: "data", change: "change", enableRenderOnSettingsChanges: "enableRenderOnSettingsChanges" }, {}, []);
export { HorizontalBarsChartComponentNgFactory as HorizontalBarsChartComponentNgFactory };
