import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import {
	AlertDetermination,
	AlertClassification,
	Incident,
	IncidentStatus,
	IncidentsUpdateOptions,
	IncidentStatusValueIds,
	IncidentStatusType,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Feature, PreferencesService, FeaturesService } from '@wcd/config';
import { NgForm } from '@angular/forms';
import { IDropdownOption, IPersonaProps } from 'office-ui-fabric-react';
import { AssigneePickerComponent } from '../../../forms/components/assignee-picker.component';
import { combinedClassifications, getCombinedClassificationMap } from '../../alerts/services/alerts.service';

const MULTIPLE_ASSIGNEES= 'multipleAssignees';

let lastId = 0;

@Component({
	selector: 'incident-set-classification',
	templateUrl: './incident-set-classification.component.html',
})
export class IncidentSetClassificationComponent extends PanelContainer implements OnInit {
	@Input() incidents: Array<Incident>;

	@Output()
	updateIncidentsEvent: EventEmitter<IncidentsUpdateOptions> = new EventEmitter<IncidentsUpdateOptions>();

	@ViewChild('classificationForm', { static: true })
	classificationForm: NgForm;

	incidentStatuses: Array<IncidentStatus>;
	incidentStatus: IncidentStatus;
	incidentClassification: AlertClassification;
	selectableAlertClassifications: Array<AlertClassification>;
	determination: AlertDetermination;
	updatedDetermination: boolean = false;
	comment: string;
	allAlertDeterminations: Array<AlertDetermination>;
	isSaving: boolean;
	isSavingComment: boolean = false;
	commentPlaceholder: string;
	submitButtonText: string;
	assignedTo: IPersonaProps = {};
	assignedToUpdated = false;
	isAssignToSomeoneElseEnabled: boolean = false;
	isCombinedClassificationEnabled = false;
	classificationUpdated: boolean = false;
	combinedClassificationMap: IDropdownOption[] = [];
	selectedCombinedClassification;
	readonly labelId = `editable-comment-label-${lastId++}`;
	isInProgressIncidentStatusEnabled: boolean;

	constructor(
		router: Router,
		paris: Paris,
		private i18nService: I18nService,
		private changeDetection: ChangeDetectorRef,
		private preferencesService: PreferencesService,
		private featuresService: FeaturesService
	) {
		super(router);
		this.isAssignToSomeoneElseEnabled = this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
		this.isCombinedClassificationEnabled = this.featuresService.isEnabled(Feature.CombinedClassification);
		this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(value => this.isCombinedClassificationEnabled || !value.combinedClassification);
		this.selectableAlertClassifications = paris.getRepository(AlertClassification).entity.values.filter(value => this.isCombinedClassificationEnabled || !value.combinedClassification);
		this.isInProgressIncidentStatusEnabled = this.featuresService.isEnabled(Feature.InProgressIncidentStatus);
		this.incidentStatuses = this.isInProgressIncidentStatusEnabled ? paris.getRepository(IncidentStatus).entity.values :
			paris.getRepository(IncidentStatus).entity.values.filter((status) => status.type !== IncidentStatusType.InProgress) ;
		this.commentPlaceholder = i18nService.get('incident.setClassification.commentPlaceHolder');
		this.combinedClassificationMap = getCombinedClassificationMap(this.i18nService);
		this.selectedCombinedClassification = this.combinedClassificationMap[0].key;
	}

	ngOnInit(): void {
		if (this.incidents.length === 1) {
			this.incidentStatus = this.incidents[0].status;
			this.incidentClassification = this.incidents[0].classification;
			this.determination = this.incidents[0].determination;
			const foundClassification = this.combinedClassificationMap.find(classification => {
				return classification.key === this.incidents[0].determination.type
			});
			this.selectedCombinedClassification = foundClassification && foundClassification.key;
		}
		this.assignedTo = this.getAssignedTo();

		this.isSaving = false;

		this.submitButtonText = this.i18nService.get('incident.setClassification.save');

		this.submitButtonText = this.i18nService.get('incident.setClassification.save');
	}

	onSelectAssignee = (assignee) => {
		this.assignedTo = assignee;
		this.assignedToUpdated = true;
		this.changeDetection.detectChanges();
	};

	getAssignedTo() {
		const assignee = this.incidents.length === 1 ? this.incidents[0].assignedTo :
			this.incidents.every(incident => incident.assignedTo === this.incidents[0].assignedTo) ? this.incidents[0].assignedTo : MULTIPLE_ASSIGNEES;
		return assignee && {
			secondaryText: assignee
		}
	}

	onNewClassificationDropdownChange(event) {
		this.selectedCombinedClassification = event.option.key;
		const { classification, determination } = combinedClassifications[event.option.key];
		this.incidentClassification = this.selectableAlertClassifications.find(alertClass => alertClass.id === classification);
		this.determination = this.allAlertDeterminations.find(alertDet => alertDet.id === determination);
		this.classificationUpdated = true;
		this.changeDetection.detectChanges();
	}

	onClassificationChange(param: any) {
		if (this.incidentClassification.id === '0') {
			this.determination = null;
			this.updatedDetermination = true;
		}
	}

	/**
	 * Once the save button was pressed - raising an event to update the related incidents
	 * (the incidents that the panel was open for).
	 */
	updateIncidents(): void {
		this.isSaving = true;

		this.updateIncidentsEvent.emit(
			Object.assign(
				{
					incidentIds: this.incidents.map((incident) => incident.id),
					comment: this.comment,
				},
				this.incidentClassification &&
					this.incidents.some(
						(incident) =>
							!incident.classification ||
							incident.classification.id !== this.incidentClassification.id
					)
					? { classification: this.incidentClassification }
					: null,
				this.determination &&
					this.incidents.some(
						incident =>
							!incident.determination || incident.determination.id !== this.determination.id
					)
					? { determination: this.determination }
					: null,
				this.incidentStatus &&
					this.incidents.some(
						incident => !incident.status || incident.status.id !== this.incidentStatus.id
					)
					? { status: this.incidentStatus }
					: null,
				this.assignedToUpdated
					? { assignedTo: this.assignedTo ? this.assignedTo.secondaryText : '' }
					: null
			)
		);

		AssigneePickerComponent.updateUserPreferredAssignees(this.assignedTo, this.preferencesService);

	}

	/**
	 * updates the incident object with the user selected values.
	 * applied after incident update was triggered.
	 */
	updateIncidentsDetails = (incident: Incident) => {
		if (this.incidentClassification) {
			incident.classification = this.incidentClassification;
		}
		if (this.determination || this.updatedDetermination) {
			incident.determination = this.determination;
			this.updatedDetermination = false;
		}
		if (this.incidentStatus) {
			incident.status = this.incidentStatus;
		}
		if (this.assignedToUpdated) {
			incident.assignedTo = this.assignedTo ? this.assignedTo.secondaryText : '';
		}
	};

	get isSubmitDisabled() {
		return this.isSaving || (!this.classificationForm.dirty && !this.assignedToUpdated && !this.classificationUpdated)
	}

	getIncidentStatusWarning(): string {
		const reactivateWarningKey = 'incident.reactivate.reactivateWarning';
		const resolveWarningKey = 'incident.resolve.resolveWarning';
		const inProgressWarningKey = 'incident.inProgress.inProgressWarning';
		// on multiple incidents - user picks the status
		if(this.incidentStatus)
			switch(this.incidentStatus.id){
				case IncidentStatusValueIds.IncidentActiveStatusId:
					return this.i18nService.get(reactivateWarningKey)
				case IncidentStatusValueIds.IncidentResolvedStatusId:
					return this.i18nService.get(resolveWarningKey)
				case IncidentStatusValueIds.IncidentInProgressStatusId:
					return this.i18nService.get(inProgressWarningKey)
			}
		return null;
	}
}
