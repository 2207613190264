// tslint:disable:template-click-events-have-key-events
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import {
	ActionHistory,
	InvestigationAction,
	Machine,
	RemediationActionType,
	RemediationActionTypeTypes,
	ActionFailureReason,
} from '@wcd/domain';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { Feature, FeaturesService } from '@wcd/config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';

const MANAGE_ACTION_ACCOUNT_DOC_URL =
	'https://docs.microsoft.com/en-us/defender-for-identity/manage-action-accounts';

@Component({
	selector: 'action-history-entity-panel',
	template: `
		<section
			*ngIf="undoneActionId || undoActionId"
			data-track-component="ActionHistoryEntityPanel"
			class="wcd-padding-vertical wcd-padding-large-horizontal"
		>
			<span *ngIf="undoneActionId">
				{{ i18nService.strings.actionsHistory_undo_undone_undoAction }}
				<a data-track-id="OpenOriginalAction" data-track-type="Action" (click)="openOriginalAction()">
					{{
						'actionHistory_panel_actionId'
							| i18n
								: {
										actionId: undoneActionId
								  }
					}}
				</a>
			</span>
			<span *ngIf="undoActionId">
				{{ i18nService.strings.actionsHistory_undo_undone_originalAction }}
				<a data-track-id="OpenUndoAction" data-track-type="Action" (click)="openUndoAction()">
					{{
						'actionHistory_panel_actionId'
							| i18n
								: {
										actionId: undoActionId
								  }
					}}
				</a>
			</span>
		</section>

		<div *ngIf="actionHistory.actionFailureI18nKey" class="wcd-padding-large-horizontal">
			<ng-container *ngIf="showLinkInFailureBanner; else simpleFailureText">
				<fab-message-bar [messageBarType]="messageBarType.warning">
					<markdown>
						{{
							i18nService.get(
								'actionHistory_panel_failureBanner_' + actionHistory.actionFailureI18nKey,
								{
									url: manageActionAccountUrl
								},
								true
							) || actionHistory.actionFailureI18nKey
						}}
					</markdown>
				</fab-message-bar>
			</ng-container>
			<ng-template #simpleFailureText>
				<fab-message-bar [messageBarType]="messageBarType.warning">
					{{
						i18nService.get(
							'actionHistory_panel_failureBanner_' + actionHistory.actionFailureI18nKey,
							null,
							true
						) || actionHistory.actionFailureI18nKey
					}}
				</fab-message-bar>
			</ng-template>
		</div>

		<action-history-base-panel
			[entity]="entity"
			[options]="options"
			[isInit]="isInit"
			[moreActionsFilter]="moreActionsFilter"
			[moreActionsEnabled]="moreActionsEnabled"
			(reset)="onResetData()"
			(investigationActionLoad)="onInvestigationActionLoad($event)"
			(requestMetadataRefresh)="requestMetadataRefresh.emit()"
		>
			<ng-container action-post-description>
				<wcd-collapsible-section
					[label]="i18nService.strings.actionHistory_panel_actionDetails"
					[sectionId]="'action-history-panel-action-Details'"
				>
					<action-history-details [actionHistory]="actionHistory"></action-history-details>
				</wcd-collapsible-section>
			</ng-container>
		</action-history-base-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionHistoryEntityPanelComponent extends EntityPanelComponentBase<ActionHistory> {
	undoneActionId: number;
	undoActionId: number;
	moreActionsEnabled: boolean;
	moreActionsFilter = (obs$: Observable<Array<ActionHistory>>) =>
		obs$.pipe(map(actions => actions && actions.filter(a => a.isUndoable)));
	machine$: Observable<Machine>;

	messageBarType = MessageBarType;
	manageActionAccountUrl = MANAGE_ACTION_ACCOUNT_DOC_URL;

	showLinkInFailureBanner: boolean = false;

	get actionHistory(): ActionHistory {
		return this.entity;
	}

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private featuresService: FeaturesService,
		private entityPanelsService: EntityPanelsService,
		private paris: Paris,
		public i18nService: I18nService
	) {
		super(changeDetectorRef);
	}

	protected initEntity(entity: ActionHistory, isExtendedData: boolean = false): void {
		super.initEntity(entity, isExtendedData);
		this.machine$ =
			this.actionHistory.machine && this.actionHistory.machine.id
				? this.paris.getItemById(Machine, this.actionHistory.machine.id)
				: null;
	}

	setEntity(entity: ActionHistory, isExtendedData: boolean = false): void {
		super.setEntity(entity, isExtendedData);
		this.moreActionsEnabled =
			this.featuresService.isEnabled(Feature.BulkFileUndo) && this.actionHistory.isUndoable;

		const actionFailureI18nKey = this.actionHistory.actionFailureI18nKey;
		this.showLinkInFailureBanner =
			actionFailureI18nKey &&
			(actionFailureI18nKey === ActionFailureReason.GmsaInsufficientPermissions ||
				actionFailureI18nKey === ActionFailureReason.NoGmsaForRequestedDomain);
		this.changeDetectorRef.markForCheck();
	}

	openOriginalAction() {
		this.openAction(
			new ActionHistory({
				id: this.undoneActionId,
				undoState: 'undone',
			})
		);
	}

	openUndoAction() {
		this.openAction(
			new ActionHistory({
				id: this.undoActionId,
				actionType: this.paris.getValue(
					RemediationActionType,
					(type: RemediationActionType) =>
						type.type === RemediationActionTypeTypes.undo_clean_resource
				),
			})
		);
	}

	private openAction(action: ActionHistory) {
		const currentAction = this.actionHistory;
		this.entityPanelsService.showEntity(
			ActionHistory,
			action,
			{},
			{
				back: {
					onClick: () => this.entityPanelsService.showEntity(ActionHistory, currentAction),
				},
			}
		);
	}

	onResetData() {
		this.undoActionId = this.undoneActionId = null;
		this.changeDetectorRef.markForCheck();
	}

	onInvestigationActionLoad(action: InvestigationAction) {
		this.undoActionId = action.undoActionId;
		this.undoneActionId = action.undoneActionId;
		this.changeDetectorRef.markForCheck();
	}
}
