import { I18nService } from '@wcd/i18n';
import { PrettyNumberService } from '@wcd/prettify';
import { DataviewField } from '@wcd/dataview';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmExposureScoreService } from '../../../../tvm/services/tvm-exposure-score.service';
var AssetFieldsService = /** @class */ (function () {
    function AssetFieldsService(i18nService, tvmExposureScoreService, prettyNumberService) {
        this.i18nService = i18nService;
        this.tvmExposureScoreService = tvmExposureScoreService;
        this.prettyNumberService = prettyNumberService;
    }
    Object.defineProperty(AssetFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.get('tvm.common.assetNameTitle'),
                        getTooltip: function (asset) { return asset.name; },
                    },
                    {
                        id: 'osPlatform',
                        name: this.i18nService.get('tvm_common_osPlatform'),
                        sort: { enabled: false },
                        getDisplay: function (asset) {
                            return asset.osPlatform
                                ? _this.i18nService.get("tvm_common_osPlatform_" + asset.osPlatform)
                                : _this.i18nService.strings.notAvailable_short;
                        },
                    },
                    {
                        id: 'securityRecommendations',
                        name: this.i18nService.get('tvm.common.securityRecommendations'),
                    },
                    {
                        id: 'discoveredVulnerabilities',
                        name: this.i18nService.get('tvm.common.discoveredVulnerabilities'),
                    },
                    {
                        id: 'exposureCategory',
                        name: this.i18nService.get('evaluation.summary.exposureLevel.title'),
                        getDisplay: function (asset) {
                            return asset.exposureLevel + " (" + _this.prettyNumberService.prettyNumber(Math.floor(asset.exposureScore)) + ")";
                        },
                        icon: {
                            fabricIcon: FabricIconNames.Warning,
                            className: function (asset) {
                                return _this.tvmExposureScoreService.getExposureCategoryColorClass(asset.exposureLevel);
                            },
                        },
                    },
                ]).filter(Boolean);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return AssetFieldsService;
}());
export { AssetFieldsService };
