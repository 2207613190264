import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { CmsSectionBase } from './cms.models';

@ValueObject({
	singularName: 'Cms Actor Section',
	pluralName: 'Cms Actor Sections',
})
export class CmsActorSection extends CmsSectionBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'ThreatIntelName' })
	threatIntelName: string;
}
