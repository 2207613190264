import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('filename', filenameFilter);

function filenameFilter() {
	return function(input) {
		if (!input || input == '') return '';

		return input
			.split('\\')
			.pop()
			.split('/')
			.pop();
	};
}
