var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { NetworkDevice, SecurityRecommendation } from '@wcd/domain';
var ɵ0 = function (_, query) { return "assets/" + encodeURIComponent(query.where['networkDeviceId']) + "/vulnerabilities"; }, ɵ1 = function (networkDevice) {
    return {
        networkDeviceId: networkDevice.id,
    };
};
var NetworkDeviceRelatedVulnerabilitiesRelationship = /** @class */ (function () {
    function NetworkDeviceRelatedVulnerabilitiesRelationship() {
    }
    NetworkDeviceRelatedVulnerabilitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: NetworkDevice,
            dataEntity: Vulnerability,
            endpoint: ɵ0,
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ1,
        })
    ], NetworkDeviceRelatedVulnerabilitiesRelationship);
    return NetworkDeviceRelatedVulnerabilitiesRelationship;
}());
export { NetworkDeviceRelatedVulnerabilitiesRelationship };
var ɵ2 = function (_, query) { return "assets/" + encodeURIComponent(query.where['networkDeviceId']) + "/recommendations"; }, ɵ3 = function (networkDevice) {
    return {
        networkDeviceId: networkDevice.id,
    };
};
var NetworkDeviceRelatedSecurityRecommendationsRelationship = /** @class */ (function () {
    function NetworkDeviceRelatedSecurityRecommendationsRelationship() {
    }
    NetworkDeviceRelatedSecurityRecommendationsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: NetworkDevice,
            dataEntity: SecurityRecommendation,
            endpoint: ɵ2,
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ3,
        })
    ], NetworkDeviceRelatedSecurityRecommendationsRelationship);
    return NetworkDeviceRelatedSecurityRecommendationsRelationship;
}());
export { NetworkDeviceRelatedSecurityRecommendationsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
