export enum ItsmTool {
	Intune = 'Intune',
	ServiceNow = 'ServiceNow',
}

export function getEndpointForItsmTool(itsmTool: ItsmTool): string {
	switch (itsmTool) {
		case ItsmTool.Intune:
			return 'intune';
		case ItsmTool.ServiceNow:
			return 'service-now';
		default:
			return '';
	}
}
