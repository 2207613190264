import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LinkedEntityAlert } from './linked-entity-alert.value-object';

@ValueObject({
	singularName: 'Linked alert',
	pluralName: 'Linked alerts',
	readonly: true,
})
export class LinkedAlerts extends ModelBase {
	@EntityField({ data: 'UnexposedAlertsCount' })
	readonly unexposedAlertsCount: number;

	@EntityField({
		data: ['LinkedAlertsList', 'LinkedAlertsAndEntities'],
		arrayOf: LinkedEntityAlert
	})
	readonly linkedAlertsList?: Array<LinkedEntityAlert>;
}
