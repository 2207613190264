import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AlertsSeveritySummary } from '@wcd/domain';

@Component({
	selector: 'alerts-severity-incident-summary',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			*ngIf="alertsSeveritySummary"
			class="wcd-full-height wcd-flex-vertical wcd-flex-spaceBetween-horizontal"
		>
			<alerts-severity-incident-summary-title
				[alertsSeveritySummary]="alertsSeveritySummary"
				[asText]="asText"
			></alerts-severity-incident-summary-title>

			<div class="wcd-margin-small-vertical">
				<alerts-severity-summary
					[alertsSeveritySummary]="alertsSeveritySummary"
				></alerts-severity-summary>
			</div>
		</div>
	`,
})
export class AlertsSeverityIncidentSummaryComponent {
	@Input() alertsSeveritySummary: AlertsSeveritySummary;
	@Input() asText: boolean = false;
}
