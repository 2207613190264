import { SevilleModule } from '../../../seville/seville.module';

declare const moment: typeof import('moment');

SevilleModule.controller('seville.threatintel.exporttocsvdialog', exportToCsvController);

exportToCsvController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$q',
	'$uibModalInstance',
	'urlMapping',
	'authenticationService',
	'exportToCsvOptions',
	'coldStorage',
];

/* The exportToCsvOptions are:
 * title:        the dialog title
 * maxDays:      maximum days of data to exports
 * maxDaysInColdStorage: maximum days of data in cold storage to exports. Optional, defaults to maxDays
 * timelineDate: the date currently selected/ displayed. Optional, defaults to today - in UTC.
 * initialStartDate: initial value in the "from date" field. Optional, defaults to start of the day of timelineDate
 * initialEndDate: initial value in the "to date" field. Optional, defaults to end of the day timelineDate
 * model:        any custom user data, such as get parameters. Is passed later to the exports callback.
 * exportCallback: a callback that is called when the user clicks on the exports button. Should actually perform the exports.
 *     Its signature should be: exportCallback(model, fromDate, toDate, canceler)
 *         model: the same object passed to the function
 *         fromDate and toDate hold the date range selected by the user - in UTC
 *         canceler: a deferred object, that is resolved when the user requests to cancel the operation. You should stop once it is resolved.
 *     Return value should be a promise that represents the operation. When the promise is either resolved or rejected, the dialog window is closed.
 *     To keep it open, return an object as the promise result, with properties keepDialogOpen:true and message:<your message>.
 */
function exportToCsvController(
	$log,
	$scope,
	$state,
	$http,
	$q,
	$uibModalInstance,
	urlMapping,
	authenticationService,
	exportToCsvOptions,
	coldStorage
) {
	var vm = this;
	vm.exportInProgress = false;

	vm.title = exportToCsvOptions.title;
	vm.model = exportToCsvOptions.model;
	vm.maxDays = exportToCsvOptions.maxDays || 7;
	vm.maxDaysInColdStorage =
		exportToCsvOptions.maxDaysInColdStorage <= vm.maxDays
			? exportToCsvOptions.maxDaysInColdStorage
			: vm.maxDays;

	vm.minFromDate =
		vm.maxDaysInColdStorage > 0
			? moment.utc().subtract(6, 'months')
			: moment(coldStorage.getColdStorageStartDate())
					.startOf('day')
					.utc();
	vm.maxFromDate = moment.utc().endOf('day');
	vm.maxToDate = moment(vm.maxFromDate);
	vm.timelineDate = moment.utc(exportToCsvOptions.timelineDate || new Date());

	vm.toDate = moment(exportToCsvOptions.initialEndDate || vm.timelineDate)
		.utc()
		.endOf('day');
	vm.toDate = moment.min(vm.toDate, vm.maxToDate);
	vm.fromDate = moment(exportToCsvOptions.initialStartDate || vm.timelineDate)
		.utc()
		.startOf('day');
	vm.fromDate = moment.max(vm.fromDate, vm.minFromDate);
	updateFromDate();

	vm.exportCallback = exportToCsvOptions.exportCallback;
	vm.failureMessage = null;

	function updateToDate() {
		var today = moment.utc().endOf('day');
		var fromDatePlusMaxDays = vm.fromDate.clone().add(effectiveMaxDays(vm.fromDate), 'days');
		vm.maxToDate = moment.min(fromDatePlusMaxDays, today).endOf('day');

		if (vm.toDate > vm.maxToDate || vm.toDate < vm.fromDate) {
			vm.toDate = vm.maxToDate;
		}
	}

	function updateFromDate() {
		// Set fromDate to the earliest date allowed behind toDate
		var coldStorageStartDate = moment(coldStorage.getColdStorageStartDate()).startOf('day');
		var daysRemainingInHotSorage = vm.toDate.diff(coldStorageStartDate, 'days');
		var maxDaysInHotStorage = Math.min(vm.maxDays, daysRemainingInHotSorage);
		var maxDaysBehindToDate = Math.max(maxDaysInHotStorage, vm.maxDaysInColdStorage);
		vm.fromDate = moment.max(
			vm.fromDate,
			vm.minFromDate,
			vm.toDate.clone().subtract(maxDaysBehindToDate, 'days')
		);
		vm.fromDate = vm.fromDate.startOf('day');
	}

	function effectiveFromDate() {
		return vm.currentDateChosen ? moment(vm.timelineDate).startOf('day') : moment(vm.fromDate);
	}

	function effectiveToDate() {
		return vm.currentDateChosen ? moment(vm.timelineDate).endOf('day') : moment(vm.toDate);
	}

	function effectiveMaxDays(date) {
		var coldStorageStartDate = moment(coldStorage.getColdStorageStartDate()).startOf('day');
		var isInColdStorage = date.isBefore(coldStorageStartDate);
		return isInColdStorage ? vm.maxDaysInColdStorage : vm.maxDays;
	}

	function isInColdStorage(date) {
		var coldStorageStartDate = moment(coldStorage.getColdStorageStartDate()).startOf('day');
		return moment(date).isBefore(coldStorageStartDate);
	}

	vm.chooseTimelineDate = function() {
		vm.currentDateChosen = true;
		vm.failureMessage = null;
	};

	vm.chooseDates = function() {
		vm.currentDateChosen = false;
		vm.failureMessage = null;
	};

	if (exportToCsvOptions.initialStartDate && exportToCsvOptions.initialEndDate) {
		vm.chooseDates();
	} else {
		vm.chooseTimelineDate();
	}

	vm.cancel = function() {
		if (vm.exportInProgress) {
			vm.canceler.resolve();
		}
		$uibModalInstance.dismiss('cancel');
	};

	vm.export = function() {
		if (vm.exportInProgress) {
			return;
		}

		vm.failureMessage = null;
		vm.exportInProgress = true;
		// A canceler, used to cancel the (asynchronous) operation upon user's request. It is resolved to signal cancellation.
		vm.canceler = $q.defer();
		// Call user defined export function, passing the date range and the supplied model, which can hold any user specific information
		// The function is supposed to return a promise representing the (asynchronous) operation
		vm.exportCallback(vm.model, effectiveFromDate(), effectiveToDate(), vm.canceler)
			.then(function(result) {
				// Export process succeded - close the dialog.
				$uibModalInstance.close({ $value: result });
			})
			.catch(function(result) {
				if (result && result.keepDialogOpen && result.message) {
					// User signaled to keep the dialog open
					vm.failureMessage = result.message;
				} else {
					// TODO change to the new log service once implemented
					$log.error('Error while trying to export machine timeline to csv' + result);
					vm.failureMessage = 'Could not export the selected content';
				}
			})
			.finally(function() {
				vm.exportInProgress = false;
				delete vm.canceler;
			});
	};

	vm.isStoredInColdStorage = function() {
		return moment().diff(effectiveFromDate(), 'days') > 30;
	};

	$scope.$watch(
		function() {
			return vm.fromDate;
		},
		function(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				updateToDate();
			}
		}
	);
}
