/* tslint:disable:template-click-events-have-key-events */
import { I18nService } from './../../../../../../../projects/i18n/src/lib/services/i18n.service';
import { TvmTextsService, TextToken } from './../../../tvm/services/tvm-texts.service';
import { ChangeEventService } from './../../../@entities/@tvm/events/services/change-event.service';
import { ChangeDetectionStrategy, Component, Input, Injector, ViewEncapsulation } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import {
	ChangeEventRelatedVulnerabilitiesRelationship,
	Vulnerability,
	TvmEndPoint,
	ChangeEvent,
	ChangeEventCategory,
	ExposedOperatingSystemsStatistics,
	ChangeEventExposedOsRelationship,
	SecurityRecommendation,
	ChangeEventRecommendationRelationship,
} from '@wcd/domain';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris, RelationshipRepository, DataSet } from '@microsoft/paris';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { Router } from '@angular/router';
import { VulnerabilityFieldsService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { DataviewFieldConfig } from '@wcd/dataview';
import { ChangeEventTextService } from '../../../tvm/services/tvm-change-events-texts.service';
import { Observable } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ExposedOperatingSystemsComponent } from '../../../tvm/components/tooltips/exposed-operating-systems/exposed-operating-systems.component';

//TODO: - Task 27819447: Break change event entity details sections into VA sections and SCA sections lists

@Component({
	selector: 'change-event-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './change-event.entity-details.component.html',
	styleUrls: ['./change-event.entity-details.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ChangeEventEntityDetailsComponent extends EntityDetailsComponentBase<ChangeEvent> {
	private _changeEvent: ChangeEvent;
	private _relatedProductId: string;
	SpinnerSize = SpinnerSize;
	ExposedOperatingSystemsComponent = ExposedOperatingSystemsComponent;

	vulnerabilitiesRelationshipRepository: RelationshipRepository<ChangeEvent, Vulnerability>;
	exposedOsRelationshipRepository: RelationshipRepository<ChangeEvent, ExposedOperatingSystemsStatistics>;
	recommendationRelationshipRepository: RelationshipRepository<ChangeEvent, SecurityRecommendation>;

	relatedComponent: string;
	relatedComponentHeader: string;
	scaConfigurationCategory: string;
	iconName: string;
	iconClass: string;
	scoreText: string;
	assetsCount: string;
	currentAssetsCount: string;
	scoreTooltip: string;
	fields: Array<DataviewFieldConfig<Vulnerability>>;
	isVaEvent: boolean;

	showScaEventScoring: boolean;
	scaEventAchievedScoreText: string;
	scoreImpactTooltip: string;
	exposedOperatingSystems$: Observable<DataSet<ExposedOperatingSystemsStatistics>>;
	securityRecommendation$: Observable<SecurityRecommendation>;

	@Input()
	set changeEvent(changeEvent: ChangeEvent) {
		this._changeEvent = changeEvent;
		this.vulnerabilitiesRelationshipRepository.sourceItem = changeEvent;
		//TODO: update this when adding more categories
		this.isVaEvent = changeEvent.category === ChangeEventCategory.Vulnerability;
		this._relatedProductId = this.changeEventService.getRelatedProductId(changeEvent);
		this.relatedComponent = this.changeEventTextService.getRelatedComponent(changeEvent);
		this.scaConfigurationCategory = changeEvent.configurationCategory; // for different mapping from SCA BE category to display e.g. OS -> Operating system
		this.iconName = this.changeEventService.getScoreTrendIconName(changeEvent);
		this.iconClass = this.changeEventService.getScoreTrendIconClass(changeEvent);
		this.scoreText = this.changeEventService.getScoreText(changeEvent);
		this.assetsCount =
			this.changeEventService.getAssetsCountString(changeEvent) ||
			this.i18nService.strings.notAvailable_long;
		this.currentAssetsCount = this.changeEventService.getCurrentAssetsCountString(changeEvent);
		this.scoreTooltip = this.changeEventService.getToolTip(changeEvent);
		let headerKey = 'tvm_common_relatedComponent';
		if (!this.isVaEvent) {
			this.exposedOsRelationshipRepository.sourceItem = changeEvent;
			this.exposedOperatingSystems$ = this.exposedOsRelationshipRepository.query();

			this.recommendationRelationshipRepository.sourceItem = changeEvent;
			this.securityRecommendation$ = this.recommendationRelationshipRepository.queryItem();
			headerKey = 'tvm_securityRecommendation_configurationChange_category';
			const achievablePoints = changeEvent.achievablePoints;
			const reductionPoints = changeEvent.reductionPoints;
			this.showScaEventScoring = reductionPoints >= 0 && achievablePoints > 0; // achievable points must be positive (comes from scid severity)
			if (this.showScaEventScoring) {
				this.scaEventAchievedScoreText =
					reductionPoints === achievablePoints
						? this.i18nService.strings.tvm_changeEvent_configuration_no_score_achieved
						: this.i18nService.get('tvm_changeEvent_configuration_score_achieved', {
								points: (achievablePoints - reductionPoints).toFixed(2),
								percentage: Math.round(100 - changeEvent.currentAffectedAssetsPercent),
						  });
			}
		}
		this.relatedComponentHeader = this.i18nService.get(headerKey);
	}

	get changeEvent(): ChangeEvent {
		return this._changeEvent;
	}

	constructor(
		private paris: Paris,
		private router: Router,
		injector: Injector,
		private sidePanelService: SidePanelService,
		private tvmDownloadService: TvmDownloadService,
		private changeEventService: ChangeEventService,
		private changeEventTextService: ChangeEventTextService,
		private tvmTextsService: TvmTextsService,
		private i18nService: I18nService,
		vulnerabilityFieldsService: VulnerabilityFieldsService
	) {
		super(injector);
		this.vulnerabilitiesRelationshipRepository = this.paris.getRelationshipRepository(
			ChangeEventRelatedVulnerabilitiesRelationship
		);
		this.exposedOsRelationshipRepository = this.paris.getRelationshipRepository(
			ChangeEventExposedOsRelationship
		);
		this.recommendationRelationshipRepository = this.paris.getRelationshipRepository(
			ChangeEventRecommendationRelationship
		);
		this.fields = vulnerabilityFieldsService.changeEventFieldsConfig;
		this.scoreImpactTooltip = this.i18nService.get('tvm_changeEvent_configuration_score_impact_tooltip');
	}

	openAllRelatedCves() {
		this.sidePanelService.showAllRelatedCvesPanel(this.changeEvent);
	}

	exportCves() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.vulnerabilitiesRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(
				TextToken.ChangeEventActivity,
				this.changeEvent
			)} - ${this.i18nService.get('tvm.common.relatedCVE.title')}`
		);
	}

	// TODO: evhvoste: refactor the exposed operating system to a component (for this and recommendation entity details component)
	// Task 27307409: TVM Portal - Refactor exposed os into a component
	generateExposedOperatingSystems(operatingSystems: Array<ExposedOperatingSystemsStatistics>): string {
		let res = '';
		if (operatingSystems.length > 0) {
			res += this.tvmTextsService.getOsPlatformLabel(operatingSystems[0].osName);
		}
		if (operatingSystems.length > 1) {
			res += `, ${this.tvmTextsService.getOsPlatformLabel(operatingSystems[1].osName)}`;
		}
		if (operatingSystems.length > 2) {
			res += ` (+${operatingSystems.length - 2} more)`;
		}
		return res;
	}

	navigateToRelatedComponent() {
		if (this.isVaEvent) {
			this.router.navigate(['/software-inventory', this._relatedProductId]);
		} else {
			this.router.navigate(['/security-recommendations'], {
				queryParams: {
					filters: `relatedComponent=recommendationCategory:eq:'${this.scaConfigurationCategory}'`,
				},
			});
		}
	}
}
