import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('onScrollDown', onScrollDirective);

function onScrollDirective() {
	return {
		restrict: 'EA',
		scope: {
			onScrollDown: '&',
		},
		link: function(scope, element, attrs) {
			var current = element[0].scrollTop;
			element.bind('scroll', function() {
				if (element[0].scrollTop - current >= 100) {
					current = element[0].scrollTop;
					scope.onScrollDown();
					scope.$apply();
				} else if (element[0].scrollTop - current <= -10) {
					current = element[0].scrollTop;
				}
			});
		},
	};
}
