import {
	IConfirmationDialogContentComponent,
	IConfirmationDialogHandlers,
} from '../models/confirmation-dialog-options.model';

export function isHandlersObject<TContentComponent extends IConfirmationDialogContentComponent, TData>(
	obj: any
): obj is IConfirmationDialogHandlers<TContentComponent, TData> {
	const maybeHandlersObj: IConfirmationDialogHandlers<TContentComponent, TData> = obj;
	return typeof obj === 'object' && typeof maybeHandlersObj.onSubmit === 'function';
}
