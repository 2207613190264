var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-click-events-have-key-events */
import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { AadGroup, DeviceTypeEntity, MachineGroup, MachineGroupAssignment, MachineGroupRuleProperty, MachineGroupSimpleRule, MachineGroupSimpleRuleOperator, MachineGroupSimpleRuleProperty, OperatingSystemPlatform, ProtectionLevel, ProtectionLevelEnum, protectionLevelValues, UserRole, MdeUserRoleActionEnum, MtpWorkload, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { TabModel } from '../../../../shared/components/tabs/tab.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { MachineGroupsService } from '../../services/machine-groups.service';
import { cloneDeep, find, flatMap, omit } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { AuthService, UnifiedRbacPermission, UserAuthEnforcementMode } from '@wcd/auth';
import { groupBy } from '../../../../../../../../projects/hunting/src/lib/utils/group-by';
import { ContentState } from '@wcd/contents-state';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { RbacComponentType } from '../shared/aad-groups-assignment-tab';
import { AppConfigService } from '@wcd/app-config';
import { RbacControlState } from '../../../../rbac/models/rbac-control-settings.model';
var AzureAadLink = "https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview";
var MachineGroupEditEnhancedComponent = /** @class */ (function () {
    function MachineGroupEditEnhancedComponent(paris, dialogsService, machineGroupsService, router, authService, i18nService, featureService, flavorService, appConfigService) {
        var _this = this;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.machineGroupsService = machineGroupsService;
        this.router = router;
        this.authService = authService;
        this.i18nService = i18nService;
        this.appConfigService = appConfigService;
        this.saveChanges = new EventEmitter();
        this.cancel = new EventEmitter();
        this.RbacComponentType = RbacComponentType;
        this.contentState = ContentState.Loading;
        this.isSaving = false;
        this.isLoadingPreviewResults = false;
        this.isLoadingUserRoles = false;
        this.shouldShowAadGroupsWithAssignedRoles = false;
        this.tabs = [
            {
                id: 'details',
                name: this.i18nService.get('machineGroup.edit.general'),
                data: 0,
            },
            {
                id: 'members',
                name: this.i18nService.get('machineGroup.edit.members'),
                data: 1,
            },
            {
                id: 'preview_members',
                name: this.i18nService.get('machineGroup.edit.previewMembers'),
                data: 2,
            },
            {
                id: 'assignment',
                name: this.i18nService.get('machineGroup.edit.userAccess'),
                data: 3,
            },
        ].map(function (tab) { return new TabModel(tab); });
        this.currentTab = this.tabs[0];
        this.hasEditPermissions = false;
        this.availableAadGroups = [];
        this.filteredAvailableAadGroups = [];
        this.selectedAadGroups = [];
        this.filter = '';
        this.onAddRuleClick = function (machineGroupSimpleRuleProperty) {
            // Add both to the editedMachineGroup and to the rulesByProperties
            var newMachineGroupSimpleRule = new MachineGroupSimpleRule();
            newMachineGroupSimpleRule.property = machineGroupSimpleRuleProperty;
            newMachineGroupSimpleRule.operator = new MachineGroupSimpleRuleOperator({ id: 0, nameKey: 'machineGroup_rule_operator_startsWith' });
            newMachineGroupSimpleRule.propertyValue = '';
            newMachineGroupSimpleRule.correlatedIndex = _this.editedMachineGroup.rules.length;
            _this.editedMachineGroup.rules.push(newMachineGroupSimpleRule);
            _this.rulesByProperties.find(function (group) { return group.key === machineGroupSimpleRuleProperty.property; }).items.push(newMachineGroupSimpleRule);
            _this.machineGroupForm.form.markAsDirty();
        };
        this.onRemoveRuleClick = function (machineGroupSimpleRule) {
            machineGroupSimpleRule.isDeleted = true;
            _this.machineGroupForm.form.markAsDirty();
        };
        this.onBack = function () {
            _this.currentTab = _this.tabs[_this.currentTab.data - 1];
        };
        this.onNext = function () {
            _this.currentTab = _this.tabs[_this.currentTab.data + 1];
        };
        this.getSelectableOperatorLabel = function (operator) {
            return _this.i18nService.get(operator.nameKey);
        };
        this.getProtectionLevelLabel = function (protectionLevel) {
            return _this.i18nService.get(protectionLevel.nameKey);
        };
        this.repository = paris.getRepository(MachineGroup);
        this.allAutomationLevelEnabled = flavorService.isEnabled(AppFlavorConfig.settings.deviceGroupsAutomationLevel);
        this.allProtectionLevels = paris
            .getRepository(ProtectionLevel)
            .entity.values.slice()
            .sort(function (a, b) {
            return a.order > b.order ? 1 : -1;
        })
            .filter(function (protectionLevel) { return _this.allAutomationLevelEnabled ||
            protectionLevel.order === protectionLevelValues.find(function (protectionLevel) { return protectionLevel.id === ProtectionLevelEnum.NotProtected; }).order; });
        var supportDeviceType = featureService.isEnabled(Feature.DeviceGroupsSupportDeviceType);
        var supportedUserAuthEnforcementModeforUrbac = [UserAuthEnforcementMode.RbacV2, UserAuthEnforcementMode.UnifiedRbac];
        this.shouldShowAadGroupsWithAssignedRoles =
            !featureService.isEnabled(Feature.UnifiedRbac) ||
                !supportedUserAuthEnforcementModeforUrbac.includes(appConfigService.userAuthEnforcementMode);
        this.operators = (paris.getRepository(MachineGroupSimpleRuleOperator).entity.values);
        this.selectableOperators = this.operators.filter(function (operator) { return !operator.valueIsList; });
        this.properties = (paris.getRepository(MachineGroupSimpleRuleProperty).entity.values.filter(function (p) { return supportDeviceType ? true : p.property != MachineGroupRuleProperty.DeviceType; }));
        this.osPlatformOptions = paris.getRepository(OperatingSystemPlatform).entity.values.filter(function (os) { return os.isBackendSupportedFilter; });
        this.deviceTypeOptions = paris.getRepository(DeviceTypeEntity).entity.values;
        this.getDeviceTypeNames();
        if (appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac) {
            //This is granularity change if the Tenant moved to UnifiedRbac
            this.hasEditPermissions = authService.currentUser.hasRequiredMtpPermissionInWorkload(UnifiedRbacPermission.ConfigurationAuthorizationManage, MtpWorkload.Mdatp);
            this.rbacControlSettings = {
                unifiedPermissions: [UnifiedRbacPermission.ConfigurationAuthorizationManage],
                state: RbacControlState.disabled,
                mtpWorkloads: [MtpWorkload.Mdatp]
            };
        }
        else {
            this.hasEditPermissions = authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.systemSettings);
            this.rbacControlSettings = { permissions: ['systemSettings'], state: RbacControlState.disabled };
        }
    }
    Object.defineProperty(MachineGroupEditEnhancedComponent.prototype, "isDirty", {
        get: function () {
            return this.machineGroupForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineGroupEditEnhancedComponent.prototype, "hasRules", {
        get: function () {
            return !!(this.editedMachineGroup.rules.length &&
                this.editedMachineGroup.rules.filter(function (rule) {
                    return !rule.isDeleted &&
                        ((!rule.operator.valueIsList && !!rule.propertyValue) ||
                            (rule.operator.valueIsList &&
                                !!rule.propertyValue &&
                                JSON.parse(rule.propertyValue).length));
                }).length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineGroupEditEnhancedComponent.prototype, "isValid", {
        get: function () {
            return !!((this.editedMachineGroup.isUnassignedMachineGroup ||
                (this.editedMachineGroup.name && this.hasRules)) &&
                this.editedMachineGroup.protectionLevel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineGroupEditEnhancedComponent.prototype, "confirmDeactivateText", {
        get: function () {
            return this.machineGroup.id ? this.i18nService.get('machineGroup_areYouSure') : this.i18nService.get('machineGroup_areYouSureForNewDeviceGroup');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineGroupEditEnhancedComponent.prototype, "userAccessDescription", {
        get: function () {
            return this.shouldShowAadGroupsWithAssignedRoles
                ? this.i18nService.get('machineGroup.edit.exposeToGroupsDescription_unifiedRbacSupport')
                : this.i18nService.get('machineGroup.edit.exposeToGroupsDescription');
        },
        enumerable: true,
        configurable: true
    });
    MachineGroupEditEnhancedComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.machineGroup) {
            this.editedMachineGroup = cloneDeep(this.machineGroup);
            if (this.editedMachineGroup.isUnassignedMachineGroup) {
                this.editedMachineGroup.name = this.i18nService.get('machineGroup.unassignedGroup.name');
                this.editedMachineGroup.description = this.i18nService.get('machineGroup.unassignedGroup.description');
            }
            this.editedMachineGroup.rules.forEach(function (rule) {
                if (rule.property.valuesEntity) {
                    var propertyValue = rule.propertyValue;
                    var parsedValue = propertyValue;
                    try {
                        parsedValue = JSON.parse(propertyValue);
                    }
                    catch (e) {
                        // Property value is not a JSON - it is a simple string.
                    }
                    // Older rules defined on OsPlatform may have been defined using a single string value (from simple dropdown)
                    // In the transition to support array of strings (from dropdown checklist) the old rule is fixed to the new format:
                    // The value becomes an array with one object, and the operator is changed from Equals to In.
                    if (typeof parsedValue === 'string' && parsedValue) {
                        parsedValue = new Array(parsedValue);
                        rule.propertyValue = JSON.stringify(parsedValue);
                    }
                    rule.operator = _this.operators.find(function (operator) { return operator.valueIsList; });
                    if (rule.property.property === MachineGroupRuleProperty.Os) {
                        _this.selectedOsPlatformValues = parsedValue;
                        _this.osPlatformButtonText = _this.getButtonTextForOsDropdown();
                    }
                    else if (rule.property.property === MachineGroupRuleProperty.DeviceType) {
                        _this.selectedDeviceTypeValues = parsedValue;
                        _this.deviceTypeButtonText = _this.getButtonTextForDeviceTypeDropdown();
                    }
                }
            });
        }
        else {
            this.editedMachineGroup = this.repository.createNewItem();
            this.editedMachineGroup.rules = this.properties.map(function (property) {
                return new MachineGroupSimpleRule({
                    property: property,
                    operator: property.valuesEntity
                        ? _this.operators.find(function (operator) { return operator.valueIsList; })
                        : _this.operators[0],
                });
            });
        }
        if (!this.allAutomationLevelEnabled) {
            this.editedMachineGroup.protectionLevel = new ProtectionLevel(protectionLevelValues.find(function (protectionLevel) { return protectionLevel.id === ProtectionLevelEnum.NotProtected; }));
        }
        this.deviceTypeButtonText = this.getButtonTextForDeviceTypeDropdown();
        this.editedMachineGroup.rules.forEach(function (rule, index) { return rule.correlatedIndex = index; });
        // API returns a flat list of rules - group by property:
        this.deviceTypeOptions.forEach(function (deviceTypeOption) { return deviceTypeOption.name = _this.i18nService.get(deviceTypeOption.i18nNameKey); });
        this.rulesByProperties = groupBy(this.editedMachineGroup.rules, function (machineGroupRule) { return machineGroupRule.property.property; });
        this.contentState = ContentState.Complete;
        if (this.shouldShowAadGroupsWithAssignedRoles) {
            this.setUserGroups();
        }
        else {
            this.selectedAadGroups = this.editedMachineGroup.assignments.map(function (mg) { return mg.aadGroup; });
        }
    };
    MachineGroupEditEnhancedComponent.prototype.getPropertyNonDeletedRules = function (machineGroupRuleProperty) {
        var propertyGroup = this.rulesByProperties.filter(function (group) { return group.key === machineGroupRuleProperty; })[0];
        if (propertyGroup) {
            return propertyGroup.items.filter(function (rule) { return !rule.isDeleted; });
        }
    };
    MachineGroupEditEnhancedComponent.prototype.getDeviceTypeNames = function () {
        var _this = this;
        this.deviceTypeOptions.forEach(function (deviceTypeOption) { return deviceTypeOption.name = _this.i18nService.get(deviceTypeOption.i18nNameKey); });
    };
    MachineGroupEditEnhancedComponent.prototype.saveMachineGroup = function () {
        var _this = this;
        if (!this.selectedAadGroups.length) {
            this.dialogsService
                .confirm({
                title: this.i18nService.get('machineGroup.noUserGroupsSelected'),
                text: this.i18nService.get('machineGroup.allUsersWillHave'),
                confirmText: this.i18nService.get('buttons.close'),
                cancelText: this.i18nService.get('buttons.cancel'),
            })
                .then(function (e) { return e.confirmed && _this.doSave(); });
        }
        else
            this.doSave();
    };
    MachineGroupEditEnhancedComponent.prototype.goToUserRolesWithConfirm = function () {
        var _this = this;
        if (this.isDirty) {
            this.dialogsService
                .confirm({
                title: this.i18nService.get('discardChanges'),
                text: this.i18nService.get('machineGroup.areYouSure'),
                confirmText: this.i18nService.get('buttons.discard'),
            })
                .then(function (e) { return e.confirmed && _this.router.navigate(['/preferences2/user_roles']); });
        }
        else
            this.router.navigate(['/preferences2/user_roles']);
    };
    MachineGroupEditEnhancedComponent.prototype.doSave = function () {
        this.isSaving = true;
        this.editedMachineGroup.assignments = this.selectedAadGroups.map(function (aadGroup) {
            return new MachineGroupAssignment({ aadGroup: new AadGroup(omit(aadGroup, ['helpText', 'exists'])) });
        });
        this.editedMachineGroup.rules = this.editedMachineGroup.rules.filter(function (rule) { return !rule.isDeleted; });
        this.saveChanges.emit(this.editedMachineGroup);
    };
    MachineGroupEditEnhancedComponent.prototype.previewResults = function () {
        var _this = this;
        this.isLoadingPreviewResults = true;
        this.previewError = false;
        var previewMachineGroup = this.editedMachineGroup;
        previewMachineGroup.rules = previewMachineGroup.rules.filter(function (rule) { return !rule.isDeleted; });
        this.machineGroupsService.getMachineGroupMachinesPreview(previewMachineGroup).subscribe(function (machinesPreview) {
            _this.previewedMachines = flatMap(machinesPreview, function (machineGroup) { return machineGroup.machines; });
            _this.isLoadingPreviewResults = false;
        }, function (error) {
            _this.previewError = true;
            _this.isLoadingPreviewResults = false;
        });
    };
    MachineGroupEditEnhancedComponent.prototype.getButtonTextForOsDropdown = function () {
        var _this = this;
        if (this.selectedOsPlatformValues && this.selectedOsPlatformValues.length > 0) {
            return this.selectedOsPlatformValues
                .map(function (id) {
                var osPlatform = find(_this.osPlatformOptions, function (osPlatform) { return osPlatform.id === id; });
                return osPlatform ? osPlatform.name : '';
            })
                .filter(function (osPlatformName) { return osPlatformName; })
                .toString();
        }
        return null;
    };
    MachineGroupEditEnhancedComponent.prototype.getButtonTextForDeviceTypeDropdown = function () {
        var _this = this;
        if (this.selectedDeviceTypeValues && this.selectedDeviceTypeValues.length > 0) {
            return this.selectedDeviceTypeValues
                .map(function (id) {
                var deviceType = find(_this.deviceTypeOptions, function (deviceType) { return deviceType.id === id; });
                return deviceType ? _this.i18nService.get(deviceType.i18nNameKey) : '';
            })
                .filter(function (deviceTypeName) { return deviceTypeName; })
                .toString();
        }
        return null;
    };
    MachineGroupEditEnhancedComponent.prototype.setOsPropertyValueOnChange = function (rule) {
        rule.propertyValue = JSON.stringify(this.selectedOsPlatformValues);
        this.osPlatformButtonText = this.getButtonTextForOsDropdown();
    };
    MachineGroupEditEnhancedComponent.prototype.setDeviceTypeValueOnChange = function (rule) {
        rule.propertyValue = JSON.stringify(this.selectedDeviceTypeValues);
        this.deviceTypeButtonText = this.getButtonTextForDeviceTypeDropdown();
    };
    MachineGroupEditEnhancedComponent.prototype.isAadGroupSelected = function (aadGroupId) {
        return this.editedMachineGroup.assignments.some(function (assignment) { return assignment.aadGroup.id === aadGroupId; });
    };
    MachineGroupEditEnhancedComponent.prototype.setUserGroups = function () {
        var _this = this;
        var userRolesRepo = this.paris.getRepository(UserRole);
        this.isLoadingUserRoles = true;
        userRolesRepo.allItems$.subscribe(function (userRoles) {
            var aadGroupsMap = new Map();
            userRoles.forEach(function (userRole) {
                userRole.assignments.forEach(function (userRoleAssignment) {
                    var aadGroupId = String(userRoleAssignment.aadGroup.id);
                    var groupUserRoles = aadGroupsMap.get(aadGroupId);
                    if (!groupUserRoles) {
                        groupUserRoles = {
                            aadGroup: userRoleAssignment.aadGroup,
                            userRoles: [userRole],
                        };
                        aadGroupsMap.set(aadGroupId, groupUserRoles);
                        if (_this.isAadGroupSelected(aadGroupId)) {
                            _this.selectedAadGroups.push(userRoleAssignment.aadGroup);
                        }
                        else {
                            _this.availableAadGroups.push(userRoleAssignment.aadGroup);
                        }
                    }
                    else if (!~groupUserRoles.userRoles.indexOf(userRole))
                        groupUserRoles.userRoles.push(userRole);
                });
            }, []);
            var assignmentsDoNotExist = _this.editedMachineGroup.assignments.filter(function (assignment) {
                return !_this.selectedAadGroups.find(function (group) { return group.id === assignment.aadGroup.id; }) &&
                    !_this.availableAadGroups.find(function (group) { return group.id === assignment.aadGroup.id; });
            });
            if (assignmentsDoNotExist.length) {
                var groups = assignmentsDoNotExist.map(function (assignment) { return assignment.aadGroup; });
                _this.selectedAadGroups = _this.selectedAadGroups.concat(groups);
            }
            _this.selectedAadGroups = _this.formatUserGroups(_this.selectedAadGroups, aadGroupsMap);
            _this.availableAadGroups = _this.formatUserGroups(_this.availableAadGroups, aadGroupsMap).filter(function (aadGroup) { return aadGroup.exists; });
            _this.filterAadGroups();
            _this.isLoadingUserRoles = false;
        }, function (error) {
            _this.isLoadingUserRoles = false;
        });
    };
    MachineGroupEditEnhancedComponent.prototype.generateHelpTextFromUserRoles = function (userRoles) {
        return userRoles.length
            ? userRoles.reduce(function (currHelpText, userRole) { return currHelpText + '\n' + userRole.name; }, this.i18nService.get('userGroups.assignedRoles'))
            : this.i18nService.get('userGroups.notAssigned');
    };
    MachineGroupEditEnhancedComponent.prototype.formatUserGroups = function (aadGroups, aadGroupsMap) {
        var _this = this;
        return aadGroups.map(function (aadGroup) {
            var groupExists = !!aadGroup.name;
            var groupUserRoles = aadGroupsMap.get(aadGroup.id);
            var userRoles = groupUserRoles ? groupUserRoles.userRoles : [];
            return __assign({}, aadGroup, { name: groupExists ? aadGroup.name : _this.i18nService.get('userRoles.groupDoesNotExist'), helpText: groupExists
                    ? _this.generateHelpTextFromUserRoles(userRoles)
                    : _this.i18nService.get('userRoles.groupDoesNotExistHelp'), exists: groupExists });
        });
    };
    MachineGroupEditEnhancedComponent.prototype.setFilterAndApply = function (filter) {
        this.filter = filter;
        this.filterAadGroups();
        this.filterChosen();
    };
    MachineGroupEditEnhancedComponent.prototype.filterAadGroups = function () {
        var _this = this;
        if (!this.filter) {
            this.filteredAvailableAadGroups = this.availableAadGroups.slice();
        }
        else {
            this.filteredAvailableAadGroups = this.availableAadGroups.filter(function (aadGroup) { return aadGroup.name.toLowerCase().indexOf(_this.filter.toLowerCase()) > -1; });
        }
    };
    MachineGroupEditEnhancedComponent.prototype.filterChosen = function () {
        var _this = this;
        if (this.chosenAvailableAadGroups) {
            this.chosenAvailableAadGroups = this.chosenAvailableAadGroups.filter(function (aadGroup) { return !!_this.filteredAvailableAadGroups.find(function (group) { return group.id === aadGroup.id; }); });
        }
    };
    MachineGroupEditEnhancedComponent.prototype.addSelectedGroups = function () {
        var _this = this;
        this.selectedAadGroups = this.selectedAadGroups.concat(this.chosenAvailableAadGroups);
        this.availableAadGroups = this.availableAadGroups.filter(function (aadGroup) { return !_this.selectedAadGroups.find(function (group) { return group.id === aadGroup.id; }); });
        this.chosenAvailableAadGroups = [];
        this.filterAadGroups();
        this.machineGroupForm.form.markAsDirty();
    };
    MachineGroupEditEnhancedComponent.prototype.removeSelectedGroups = function () {
        var _this = this;
        this.availableAadGroups = this.availableAadGroups.concat(this.chosenSelectedAadGroups);
        this.selectedAadGroups = this.selectedAadGroups.filter(function (aadGroup) { return !_this.availableAadGroups.find(function (group) { return group.id === aadGroup.id; }); });
        this.chosenSelectedAadGroups = [];
        this.filterAadGroups();
        this.machineGroupForm.form.markAsDirty();
    };
    // called when URBAC feature is enabled
    MachineGroupEditEnhancedComponent.prototype.applySelectedAadGroupsWithoutRoleAssignmnet = function (aadGroups) {
        this.selectedAadGroups = aadGroups;
        this.machineGroupForm.form.markAsDirty();
    };
    return MachineGroupEditEnhancedComponent;
}());
export { MachineGroupEditEnhancedComponent };
