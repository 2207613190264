import { Injectable } from '@angular/core';
import { WebContentFilteringDomainReport } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';

@Injectable()
export class WebContentFilteringDomainEntityTypeService
	implements EntityTypeService<WebContentFilteringDomainReport> {
	readonly entityType: EntityType<WebContentFilteringDomainReport> = {
		id: 'webContentFilteringDomainList',
		entity: WebContentFilteringDomainReport,
		loadFullEntityInPanel: false,
		getEntityName: (report: WebContentFilteringDomainReport) => report.domainName,
	};
}
