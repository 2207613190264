/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./indication-icons.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i4 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i5 from "@angular/platform-browser";
import * as i6 from "@angular-react/fabric";
import * as i7 from "@angular/common";
import * as i8 from "./indication-icons.component";
var styles_WcdIndicationIconsComponent = [i0.styles];
var RenderType_WcdIndicationIconsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_WcdIndicationIconsComponent, data: {} });
export { RenderType_WcdIndicationIconsComponent as RenderType_WcdIndicationIconsComponent };
function View_WcdIndicationIconsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "fab-icon", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i1.ɵdid(1, 147456, null, 0, i3.TooltipDirective, [i1.ElementRef, i4.TooltipsService, i5.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipAllowHtmlRendering: [1, "wcdTooltipAllowHtmlRendering"] }, null), i1.ɵdid(2, 5816320, null, 0, i6.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.context.$implicit.tooltip ? _v.parent.context.$implicit.tooltip : null); var currVal_1 = _co.allowTooltipHTMLRender; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.$implicit.contentClass; var currVal_3 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit.icon == null) ? null : _v.parent.context.$implicit.icon.name), ""); _ck(_v, 2, 0, currVal_2, currVal_3); }, null); }
function View_WcdIndicationIconsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-margin-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcdIndicationIconsComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.context.$implicit.show; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_WcdIndicationIconsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex indicationIcons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_WcdIndicationIconsComponent_1)), i1.ɵdid(3, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._indicationIcons; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_WcdIndicationIconsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-indication-icons", [], null, null, null, View_WcdIndicationIconsComponent_0, RenderType_WcdIndicationIconsComponent)), i1.ɵdid(1, 573440, null, 0, i8.WcdIndicationIconsComponent, [], null, null)], null, null); }
var WcdIndicationIconsComponentNgFactory = i1.ɵccf("wcd-indication-icons", i8.WcdIndicationIconsComponent, View_WcdIndicationIconsComponent_Host_0, { indicationIcons: "indicationIcons", allowTooltipHTMLRender: "allowTooltipHTMLRender" }, {}, []);
export { WcdIndicationIconsComponentNgFactory as WcdIndicationIconsComponentNgFactory };
