import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { BehaviorSubject, concat, from, Observable, of, throwError, timer } from 'rxjs';
import {
	delayWhen,
	first,
	last,
	map,
	retryWhen,
	scan,
	share,
	skipWhile,
	switchMap,
	tap,
} from 'rxjs/operators';

import {
	AdditionalPlatformProvisionApiCall,
	ComponentProvisioningApiCall,
	ComponentProvisioningStatus,
	ComponentProvisioningStatusModel,
	GetComponentProvisioningStatusApiCall,
	GetPlatformProvisionStatusApiCall,
	OnboardingSettings,
	PlatformProvisionApiCall,
	PlatformProvisionSettings,
	PlatformProvisionStatus,
	PlatformProvisionStatusModel,
} from '@wcd/domain';
import { isNullOrUndefined } from 'util';
import { SccOnboardingData } from '../models/onboarding.models';
import { HttpClient } from '@angular/common/http';
import { tenantContextCache } from '@wcd/auth';

import { AppInsightsService } from '../../../../../../apps/portal/src/app/insights/services/app-insights.service';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService } from '@wcd/config';
import { ChecklistValue } from '@wcd/forms';
import { sccHostService } from '@wcd/scc-interface';

export const TrackingProperties = [
	'IsMtpEligible',
	'IsNominated',
	'IsOnboardingComplete',
	'IsPermittedOnboarding',
	'AccountType',
	'MtpConsent',
];
const POLLING_INTERVAL = 15000;

@Injectable()
export class OnboardingService {
	private provisionStatusSubject$ = new BehaviorSubject<PlatformProvisionStatus>(null);

	get platformProvisionStatus$(): Observable<PlatformProvisionStatus> {
		if (isNullOrUndefined(this.provisionStatusSubject$.getValue())) {
			this.getPlatformProvisionStatus()
				.pipe(
					tap(status => {
						this.provisionStatusSubject$.next(status);
					})
				)
				.subscribe();
		}
		return this.provisionStatusSubject$.pipe(skipWhile(val => isNullOrUndefined(val)));
	}

	constructor(
		private paris: Paris,
		private http: HttpClient,
		private appInsightsService: AppInsightsService,
		private appConfigService: AppConfigService,
		private featureService: FeaturesService
	) {}

	private getPlatformProvisionStatus(): Observable<PlatformProvisionStatus> {
		return this.paris
			.apiCall(GetPlatformProvisionStatusApiCall)
			.pipe(map(platformProvisionModel => platformProvisionModel.platformProvision));
	}

	onboardTenant(
		data: SccOnboardingData,
		setMtpConsent: boolean = false
	): Observable<void> {
		const onboardObservable: Observable<PlatformProvisionStatus | ComponentProvisioningStatus> = from(this.onboardTenantV2(data, setMtpConsent));

		return onboardObservable.pipe(
			last(),
			// Convert to observable of void
			switchMap(() => of(undefined)),
			retryWhen((errors: Observable<any>) => {
				return errors.pipe(
					scan((retries: number, err) => {
						if (retries > 2) throw err;
						return retries + 1;
					}, 0),
					delayWhen(() => timer(1500))
				);
			}),
			share()
		);
	}

	private async onboardTenantV2(
		data: SccOnboardingData,
		setMtpConsent: boolean
	): Promise<PlatformProvisionStatus | ComponentProvisioningStatus> {
		const status = await this.provisionStatusSubject$.pipe(first()).toPromise();
		await concat(...this.getRequiredOperationsForPlatformProvisioning(data, status, setMtpConsent))
			.toPromise();
		if (this.featureService.isEnabled(Feature.ComponentProvisioningInScc))
		{
			return concat(...this.getRequiredOperationsForComponentProvisioning())
				.toPromise();
		}
	}

	optInTenant(): Observable<boolean> {
		// Do not use Paris here since Paris is not updated with the serviceUrls post tenant onboarding
		const optInPromise = sccHostService.ajax.post(this.getOptInUrl())
			.then(() => {
				this.appConfigService.updateChangeableProperties({
					mtpConsent: true,
					isOnboardingComplete: true,
				});
				return true;
			})

		return from(optInPromise);
	}

	private getOptInUrl(): string {
		return sccHostService.mock.isMockMode ? `${sccHostService.mock.mockHost}/api/mtp/workloads/consent` : '<mtp>/k8s/mtp/workloads/consent';
	}

	private getRequiredOperationsForPlatformProvisioning(
		data: SccOnboardingData,
		status: PlatformProvisionStatus,
		setMtpConsent: boolean
	): Observable<PlatformProvisionStatus>[] {
		const operations: Observable<PlatformProvisionStatus>[] = [];

		if (status === PlatformProvisionStatus.NotProvisioned) {
			const settings = this.getDefaultOnboardingSettings(data.dataCenterRegion, setMtpConsent);
			operations.push(this.platformProvisioning(settings));
			operations.push(this.pollPlatformProvision());
			operations.push(this.additionalPlatformProvisioning());
		} else if (status === PlatformProvisionStatus.InProgress) {
			operations.push(this.pollPlatformProvision());
			operations.push(this.additionalPlatformProvisioning());
		} else if (status === PlatformProvisionStatus.PlatformProvisioned) {
			operations.push(this.additionalPlatformProvisioning());
		} else if (status === PlatformProvisionStatus.AdditionalPlatformProvisioned) {
			// Support MDATP-provisioned tenants with PlatformProvisionStatus.AdditionalPlatformProvisioned and IsOnboardingComplete == false
			operations.push(of(status));
		}

		return operations;
	}

	private platformProvisioning(settings: PlatformProvisionSettings): Observable<PlatformProvisionStatus> {
		return this.paris
			.apiCall(PlatformProvisionApiCall, settings)
			.pipe(map(() => PlatformProvisionStatus.InProgress));
	}

	private pollPlatformProvision(): Observable<PlatformProvisionStatus> {
		return this.paris.apiCall(GetPlatformProvisionStatusApiCall).pipe(
			switchMap((response: PlatformProvisionStatusModel) => {
				const status = response.platformProvision;
				if (status === PlatformProvisionStatus.PlatformProvisioned) {
					return of(PlatformProvisionStatus.PlatformProvisioned);
				} else if (status === PlatformProvisionStatus.InProgress) {
					return timer(POLLING_INTERVAL).pipe(switchMap(() => this.pollPlatformProvision()));
				} else {
					return throwError('Platform provision failed');
				}
			})
		);
	}

	private additionalPlatformProvisioning(): Observable<PlatformProvisionStatus> {
		return this.paris
			.apiCall(AdditionalPlatformProvisionApiCall)
			.pipe(map(() => PlatformProvisionStatus.AdditionalPlatformProvisioned));
	}

	private getRequiredOperationsForComponentProvisioning(): Observable<ComponentProvisioningStatus>[] {
		return [this.componentProvisioning(), this.pollComponentProvisioningStatus()];
	}

	private componentProvisioning(): Observable<ComponentProvisioningStatus> {
		return this.paris
			.apiCall(ComponentProvisioningApiCall)
			.pipe(map(() => ComponentProvisioningStatus.InProgress));
	}

	private pollComponentProvisioningStatus(): Observable<ComponentProvisioningStatus> {
		return this.paris.apiCall(GetComponentProvisioningStatusApiCall).pipe(
			switchMap((response: ComponentProvisioningStatusModel) => {
				const status = response.componentProvisioning;
				if (status === ComponentProvisioningStatus.Provisioned) {
					return of(ComponentProvisioningStatus.Provisioned);
				} else if (status === ComponentProvisioningStatus.InProgress) {
					return timer(POLLING_INTERVAL).pipe(switchMap(() => this.pollComponentProvisioningStatus()));
				} else {
					return throwError('Component provisioning failed');
				}
			})
		);
	}

	private getDefaultOnboardingSettings(
		selectedGeoRegion?: ChecklistValue,
		setMtpConsent?: boolean
	): OnboardingSettings {
		const geoRegion = selectedGeoRegion ? selectedGeoRegion.id : undefined;
		const mtpConsentValue = !!setMtpConsent;
		return {
			geoRegion,
			maxEndpoints: 1000,
			minEndpoints: 0,
			optInPublicPreviewFeatures: false,
			retentionPolicy: 180,
			mtpConsent: mtpConsentValue,
		};
	}

	trackEvent = (name: string, value?: string) => {
		this.appInsightsService.trackEvent(name, {
			value: value,
			additionalTrackingProperties: this.getTrackingProperties(),
		});
	};

	private getTrackingProperties(): Map<string, string> {
		const appConfigTracking: Map<string, string> = Object.create(null);
		TrackingProperties.map(
			(p, index) =>
				(appConfigTracking[TrackingProperties[index]] =
					tenantContextCache.appConfig[TrackingProperties[index]])
		);

		return appConfigTracking;
	}
}
