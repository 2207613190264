import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('halloEditor', halloEditorDirective);

function halloEditorDirective() {
	return {
		restrict: 'A',
		require: '?ngModel',
		link: function(scope, element, attrs, ngModel) {
			if (!ngModel) {
				return;
			}

			element.hallo({
				plugins: {
					halloformat: {},
					hallolists: {},
					halloheadings: [1, 2, 3],
					hallolink: {},
					//'hallojustify': {},
				},
				editable: true,
			});

			ngModel.$render = function() {
				element.html(ngModel.$viewValue || '');
			};

			element.on('hallodeactivated', function() {
				ngModel.$setViewValue(element.html());
				scope.$apply();
			});
		},
	};
}
