/**
 * Service for opening React tabs from Angular.
 * Under the hood it works using React.Portals (https://reactjs.org/docs/portals.html) and react-reverse-portal lib (https://github.com/httptoolkit/react-reverse-portal).
 * For rendering implementation see react-portal-tab-wrapper.tsx.
 * For usage example see app-bootstrap.scc.tsx / incident-overview-tab.component.ts.
 */
class ReactTabsService {
	private onTabChange: (tab: ReactTabConfig | null, onTabStateChange) => void;

	/**
	 * Register a callback for changes in the currently open tab.
	 * @param onTabChange callback to call when the currently open tab changes. See ReactPortalTabWrapper.
	 */
	setOnTabChange(onTabChange: (tab: ReactTabConfig | null, onTabStateChange) => void) {
		this.onTabChange = onTabChange;
	}

	/**
	* Renders a react tab.
	* The callback "onTabChange" is responsible for passing the component details into the ReactPortalTabWrapper.
	 */
	renderTab = (tab: ReactTabConfig | null): Promise<void> => {
		return new Promise<void>((resolve) => {
			this.onTabChange(tab, async () => {
				if (tab) {
					// Poll until we make sure that the OutPortal is populated, since setState and OutPortal's are logic are async.
					await poll(isReactPortalNodePopulated)
				}
				resolve();
			});
		});
	};
}

export const reactTabsService = new ReactTabsService();

export interface ReactTabConfig {
	componentName: string;
}

async function poll(fn, retries = 10, interval = 200) {
	while (!fn() && retries--) {
		await new Promise((resolve) => setTimeout(resolve, interval));
	}
}

export function isReactPortalNodePopulated() {
	return window.mdatp && window.mdatp.reactTabPortalNode && window.mdatp.reactTabPortalNode.props.node.element.children.length > 0;
}





