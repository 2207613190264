import * as React from 'react';
import { useCallback } from 'react';
import { PanelConfig, PanelNavigationConfig, panelService } from '@wcd/shared';
import { IPanelProps, Panel, PanelType } from 'office-ui-fabric-react';

export const AngularWrapperPanels: React.FC<AngularWrapperPanelsProps> = ({ panels }) => {

	const onRenderNavigationContent = useCallback((config: PanelConfig, panelProps: IPanelProps, defaultRender: (panelProps?: IPanelProps) => JSX.Element) =>
		config.navigationConfig ?
			<AngularWrapperPanelNavigation {...config.navigationConfig} panelKey={config.id} onDismiss={panelProps.onDismiss}/>
			: defaultRender(panelProps), []);

	return (panels && panels.length > 0) ?
		<>
			{
				(panels.map((panel: PanelConfig) =>
					<Panel
						key={panel.id}
						type={PanelType.medium}
						onDismiss={() => panelService.dismissPanel(panel.id)}
						onRenderNavigationContent={(props, defaultRender) => onRenderNavigationContent(panel, props, defaultRender)}
						hasCloseButton={true}
						isFooterAtBottom={true}
						isBlocking={false}
						isOpen={true}
						styles={panelStyles}
					>
						{panel.content}
					</Panel>))
			}
		</> : null;
};


const AngularWrapperPanelNavigation: React.FC<PanelNavigationProps & {panelKey: string}> = ({
																								panelKey,
																								upButtonOnClick,
																								upButtonDisabled,
																								upButtonLabel,
																								downButtonOnClick,
																								downButtonDisabled,
																								downButtonLabel,
																								dismissButtonLabel,
																								onDismiss,
																							}) => {
	const { DetailPanelV2Nav } = window.M365SccControls.AdminControls;
	const upLabel = upButtonLabel || 'Move up';
	const downLabel = downButtonLabel || 'Move down';
	const dismissLabel = dismissButtonLabel || 'Close';
	const items = [
		{
			key: 'up',
			name: upLabel,
			iconProps: { iconName: 'up' },
			ariaLabel: upLabel,
			onClick: () => {
				panelService.dismissPanel(panelKey);
				upButtonOnClick();
			},
			disabled: upButtonDisabled,
		},
		{
			key: 'down',
			name: downLabel,
			iconProps: { iconName: 'down' },
			ariaLabel: downLabel,
			onClick: () => {
				panelService.dismissPanel(panelKey);
				downButtonOnClick();
			},
			disabled: downButtonDisabled,
		},
	];

	return (
		<DetailPanelV2Nav
			closeButtonProps={{
				onClick: onDismiss,
				ariaLabel: dismissLabel,
			}}
			closeButtonToolTipHostProps={{ content: dismissLabel }}
			extraButtons={items}
		/>
	);
};


export interface AngularWrapperPanelsProps {
	panels: PanelConfig[];
}

type PanelNavigationProps = PanelNavigationConfig & { onDismiss: () => void };


const takeOverScreenAtBreakpoint = {
	'@media only screen and (max-width: 640px)': {
		width: '100%',
	},
};

const panelStyles = {
	main: takeOverScreenAtBreakpoint,
	content: { height: '100%', padding: 0, boxSizing: 'border-box' },
	scrollableContent: { minHeight: '50%', paddingBottom: 16, paddingLeft: 32, paddingRight: 32 },
};
