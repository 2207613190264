var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { I18nService } from '@wcd/i18n';
import { RemediationActionsBackendService } from './remediation-actions.backend.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { QuarantineFilesRemediationDeclineModalComponent } from '../components/quarantine-files-remediation-decline.modal';
import { AirsEntityType, EntityApprovalApiCall, RemediationActionTypeTypes, } from '@wcd/domain';
import { take, tap } from 'rxjs/operators';
import { RemediationDeclineModalComponent } from '../components/remediation-decline.modal';
import { AddFilesToAllowlistModal } from '../components/add-files-to-allowlist.modal';
import { FabricIconNames } from '@wcd/scc-common';
import { Paris } from '@microsoft/paris';
var RemediationActionsService = /** @class */ (function () {
    function RemediationActionsService(backendService, dialogsService, i18nService, paris) {
        var _this = this;
        var _a, _b;
        this.backendService = backendService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.paris = paris;
        this.approveDeclineTypes = (_a = {},
            _a[RemediationActionTypeTypes.file_quarantine] = {
                decline: this.declineFileQuarantine.bind(this),
            },
            _a[RemediationActionTypeTypes.pending_resource] = {
                approve: function (actions) {
                    var actionIds = actions.reduce(function (_actionIds, action) {
                        return _actionIds.concat(action.actionIds || [action.id]);
                    }, []);
                    return _this.retryPendingActions(actionIds)
                        .toPromise()
                        .then(function (data) { return ({ confirmed: true, data: data }); });
                },
            },
            _a);
        this.approveDeclineEntityTypes = (_b = {},
            _b[AirsEntityType.File] = {
                decline: this.declineFileQuarantineByEvidence.bind(this),
            },
            _b);
    }
    RemediationActionsService.prototype.declineFileQuarantine = function (actions, sha1) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogsService
                            .showModal(QuarantineFilesRemediationDeclineModalComponent, {
                            id: 'approve-decline-file-modal',
                            title: actions.length === 1
                                ? this.i18nService.strings
                                    .remediation_declineModal_fileQuarantine_decline_singular
                                : this.i18nService.get('remediation_declineModal_fileQuarantine_decline_plural', {
                                    fileCount: actions.length,
                                }),
                        }, {
                            inputs: actions,
                            dismissPending: function (_actions, rejectData) { return __awaiter(_this, void 0, void 0, function () {
                                var _modal_1, e_1;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 3, , 4]);
                                            return [4 /*yield*/, this.dismissPendingActions(_actions, rejectData).toPromise()];
                                        case 1:
                                            _a.sent();
                                            modal.destroy();
                                            return [4 /*yield*/, this.dialogsService
                                                    .showModal(AddFilesToAllowlistModal, {
                                                    id: 'add-file-to-allowlist-modal',
                                                    title: this.i18nService.strings.common_success,
                                                    titleIcon: FabricIconNames.Accept,
                                                }, {
                                                    message: this.i18nService.get('remediationActions_addToWhitelist', { fileCount: _actions.length }),
                                                    sha1: sha1,
                                                    hashes: actions && actions.map(function (a) { return a.file && a.file.sha1; }),
                                                    onDone: function () {
                                                        resolve({ confirmed: true, data: null });
                                                        _modal_1.destroy();
                                                    },
                                                })
                                                    .pipe(take(1))
                                                    .toPromise()];
                                        case 2:
                                            _modal_1 = _a.sent();
                                            _modal_1.instance.cancel.subscribe(function () {
                                                resolve({ confirmed: true, data: null });
                                                _modal_1.destroy();
                                            });
                                            return [3 /*break*/, 4];
                                        case 3:
                                            e_1 = _a.sent();
                                            reject(e_1);
                                            return [3 /*break*/, 4];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); },
                        })
                            .pipe(take(1))
                            .toPromise()];
                    case 1:
                        modal = _a.sent();
                        modal.instance.cancel.subscribe(function () {
                            resolve({ confirmed: false });
                            modal.destroy();
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    RemediationActionsService.prototype.declineFileQuarantineByEvidence = function (entities, sha1) {
        var _this = this;
        return new Promise(function (resolve, reject) { return __awaiter(_this, void 0, void 0, function () {
            var modal;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.dialogsService
                            .showModal(QuarantineFilesRemediationDeclineModalComponent, {
                            id: 'approve-decline-file-modal',
                            title: entities.length === 1
                                ? this.i18nService.strings
                                    .remediation_declineModal_fileQuarantine_decline_singular
                                : this.i18nService.get('remediation_declineModal_fileQuarantine_decline_plural', {
                                    fileCount: entities.length,
                                }),
                        }, {
                            inputs: entities,
                            dismissPending: function (_entities, rejectData) { return __awaiter(_this, void 0, void 0, function () {
                                var _modal_2, e_2;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            _a.trys.push([0, 3, , 4]);
                                            return [4 /*yield*/, this.dismissPendingEntities(_entities, rejectData).toPromise()];
                                        case 1:
                                            _a.sent();
                                            modal.destroy();
                                            if (!sha1) {
                                                resolve({ confirmed: true, data: null });
                                                return [2 /*return*/];
                                            }
                                            return [4 /*yield*/, this.dialogsService
                                                    .showModal(AddFilesToAllowlistModal, {
                                                    id: 'add-file-to-allowlist-modal',
                                                    title: this.i18nService.strings.common_success,
                                                    titleIcon: FabricIconNames.Accept,
                                                }, {
                                                    message: this.i18nService.get('remediationActions_addToWhitelist', { fileCount: _entities.length }),
                                                    sha1: sha1,
                                                    hashes: [],
                                                    onDone: function () {
                                                        resolve({ confirmed: true, data: null });
                                                        _modal_2.destroy();
                                                    },
                                                })
                                                    .pipe(take(1))
                                                    .toPromise()];
                                        case 2:
                                            _modal_2 = _a.sent();
                                            _modal_2.instance.cancel.subscribe(function () {
                                                resolve({ confirmed: true, data: null });
                                                _modal_2.destroy();
                                            });
                                            return [3 /*break*/, 4];
                                        case 3:
                                            e_2 = _a.sent();
                                            reject(e_2);
                                            return [3 /*break*/, 4];
                                        case 4: return [2 /*return*/];
                                    }
                                });
                            }); },
                        })
                            .pipe(take(1))
                            .toPromise()];
                    case 1:
                        modal = _a.sent();
                        modal.instance.cancel.subscribe(function () {
                            resolve({ confirmed: false });
                            modal.destroy();
                        });
                        return [2 /*return*/];
                }
            });
        }); });
    };
    RemediationActionsService.prototype.getApproveMethod = function (actions) {
        var actionTypes = Array.from(new Set(actions.map(function (a) { return a.remediationActionType; })));
        return (actionTypes.length === 1 &&
            this.approveDeclineTypes[actionTypes[0].type] &&
            this.approveDeclineTypes[actionTypes[0].type].approve);
    };
    RemediationActionsService.prototype.getDeclineMethod = function (actions) {
        var actionTypes = Array.from(new Set(actions.map(function (a) { return a.remediationActionType; })));
        return (actionTypes.length === 1 &&
            this.approveDeclineTypes[actionTypes[0].type] &&
            this.approveDeclineTypes[actionTypes[0].type].decline);
    };
    RemediationActionsService.prototype.getEvidenceApproveMethod = function (entities) {
        var entityTypes = Array.from(new Set(entities.map(function (a) { return a.type; })));
        return (entityTypes.length === 1 &&
            this.approveDeclineEntityTypes[entityTypes[0].id] &&
            this.approveDeclineEntityTypes[entityTypes[0].id].approve);
    };
    RemediationActionsService.prototype.getEvidenceDeclineMethod = function (entities) {
        var entityTypes = Array.from(new Set(entities.map(function (a) { return a.type; })));
        return (entityTypes.length === 1 &&
            this.approveDeclineEntityTypes[entityTypes[0].id] &&
            this.approveDeclineEntityTypes[entityTypes[0].id].decline);
    };
    RemediationActionsService.prototype.getActionDataFromActions = function (actions) {
        return actions
            ? actions.reduce(function (_actionData, action) {
                return _actionData.concat((action.actionIds ? action.actionIds : [action.id]).map(function (id) { return ({
                    action_id: id,
                    investigation_id: action.investigationId,
                    action_type: action.remediationActionType,
                }); }));
            }, [])
            : null;
    };
    RemediationActionsService.prototype.dismissPendingActions = function (actions, declineData) {
        var actionData = this.getActionDataFromActions(actions);
        return this.backendService.dismissActions(actionData, declineData.reason.id, declineData.comment);
    };
    RemediationActionsService.prototype.dismissPendingEntities = function (entities, declineData) {
        return this.paris.apiCall(EntityApprovalApiCall, {
            entityData: entities,
            status: 'declined',
            declineReason: declineData.reason.id,
            comment: declineData.comment,
        });
    };
    RemediationActionsService.prototype.confirmAndDismissPendingActionsByType = function (actions, data, allowCustomDecline) {
        var _this = this;
        if (allowCustomDecline === void 0) { allowCustomDecline = true; }
        var onError = function (error) {
            if (error) {
                _this.dialogsService.showError({
                    title: _this.i18nService.strings[actions.length > 1
                        ? 'investigation_error_FailedDeclinePendingActions'
                        : 'investigation_error_FailedDeclinePendingAction'],
                    data: error,
                });
            }
        };
        if (allowCustomDecline !== false) {
            var declineMethod = this.getDeclineMethod(actions);
            if (declineMethod) {
                return declineMethod(actions, data).catch(onError);
            }
        }
        var actionType = actions[0].remediationActionType;
        if (actions.some(function (a) { return a.remediationActionType !== actionType; })) {
            actionType = null;
        }
        return this.dialogsService.confirm({
            componentType: RemediationDeclineModalComponent,
            inputs: {
                title: actionType && actionType.dismissText
                    ? actionType.dismissText(actions)
                    : this.i18nService.get('remediation_declineModal_declineAction', {
                        actionCount: actions.length,
                    }),
            },
            onConfirm: function (payload) {
                return _this.dismissPendingActions(actions, payload)
                    .toPromise()
                    .catch(onError);
            },
        });
    };
    RemediationActionsService.prototype.confirmAndDismissPendingActionsByEntities = function (entities, data) {
        var _this = this;
        var onError = function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.strings[entities.length > 1
                    ? 'investigation_error_FailedDeclinePendingActions'
                    : 'investigation_error_FailedDeclinePendingAction'],
                data: error,
            });
        };
        var declineMethod = this.getEvidenceDeclineMethod(entities);
        if (declineMethod) {
            return declineMethod(entities, data).catch(onError);
        }
        return this.dialogsService.confirm({
            componentType: RemediationDeclineModalComponent,
            inputs: {
                title: this.i18nService.get('remediation_declineModal_declineAction', {
                    actionCount: entities.length,
                }),
            },
            onConfirm: function (payload) { return __awaiter(_this, void 0, void 0, function () {
                var e_3;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            _a.trys.push([0, 2, , 3]);
                            return [4 /*yield*/, this.dismissPendingEntities(entities, payload).toPromise()];
                        case 1:
                            _a.sent();
                            return [3 /*break*/, 3];
                        case 2:
                            e_3 = _a.sent();
                            onError(e_3);
                            return [3 /*break*/, 3];
                        case 3: return [2 /*return*/];
                    }
                });
            }); },
        });
    };
    /**
     * Approves pending RemediationActions on the server
     * @returns {*}
     */
    RemediationActionsService.prototype.approvePendingActions = function (actions, showNotification) {
        var _this = this;
        if (showNotification === void 0) { showNotification = true; }
        var approveMethod = this.getApproveMethod(actions);
        if (approveMethod) {
            return approveMethod(actions).then(onApprove.bind(this), function (error) {
                onError.call(_this, error);
                return Promise.reject(error);
            });
        }
        var actionData = this.getActionDataFromActions(actions);
        return this.backendService
            .approveActions(actionData)
            .pipe(tap(onApprove.bind(this), function (error) {
            onError.call(_this, error);
        }))
            .toPromise()
            .then(function (data) { return ({ confirmed: true, data: data }); });
        function onApprove() {
            if (showNotification) {
                this.showNotificationOnApprove(actions);
            }
        }
        function onError(error) {
            if (error) {
                this.dialogsService.showError({
                    title: this.i18nService.strings[actions.length > 1
                        ? 'investigation_error_FailedApprovePendingActions'
                        : 'investigation_error_FailedApprovePendingAction'],
                    data: error,
                });
            }
        }
    };
    RemediationActionsService.prototype.approvePendingEntities = function (entities) {
        return __awaiter(this, void 0, void 0, function () {
            var approveMethod, e_4;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        approveMethod = this.getEvidenceApproveMethod(entities);
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 6, , 7]);
                        if (!approveMethod) return [3 /*break*/, 3];
                        return [4 /*yield*/, approveMethod(entities)];
                    case 2:
                        _a.sent();
                        return [3 /*break*/, 5];
                    case 3: return [4 /*yield*/, this.paris
                            .apiCall(EntityApprovalApiCall, {
                            entityData: entities,
                            status: 'approved',
                        })
                            .toPromise()];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        this.dialogsService.showSuccessSnackbar({
                            text: this.i18nService.get('remediation_pendingActions_approved_generic', {
                                itemCount: entities.length,
                            }),
                        });
                        return [2 /*return*/, { confirmed: true, data: null }];
                    case 6:
                        e_4 = _a.sent();
                        this.dialogsService.showError({
                            title: this.i18nService.strings[entities.length > 1
                                ? 'investigation_error_FailedApprovePendingActions'
                                : 'investigation_error_FailedApprovePendingAction'],
                            data: e_4,
                        });
                        throw e_4;
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    RemediationActionsService.prototype.retryPendingActions = function (actionIds) {
        return this.backendService.retryPendingActions(actionIds);
    };
    RemediationActionsService.prototype.showNotificationOnApprove = function (remediationActions) {
        var actionsType = remediationActions[0].remediationActionType;
        var isMultipleRemediationActionTypes = remediationActions.length > 1 &&
            remediationActions.some(function (a) { return a.remediationActionType !== actionsType; });
        var text;
        if (isMultipleRemediationActionTypes) {
            text = this.i18nService.get('remediation_pendingActions_approved_generic', {
                itemCount: remediationActions.length,
            });
        }
        else {
            text = this.i18nService.get(remediationActions.length === 1
                ? 'remediation_pendingActions_approved_specific_singular'
                : 'remediation_pendingActions_approved_specific_plural', {
                actionName: actionsType.name,
            });
            text = text.replace('remediation remediation', 'remediation');
        }
        this.dialogsService.showSuccessSnackbar({
            text: text,
        });
    };
    RemediationActionsService.getSuccessOrFailureTextI18nKey = function (remediationActionType, entityCount, isFailed, avRemediationStatus, isRemediated) {
        // this text is relevant if the entity was remediated by av or if the entity is not remediated at all
        if (avRemediationStatus && (!isRemediated || avRemediationStatus.isRemediated)) {
            return "remediationActions.avRemediationStatus." + avRemediationStatus.type;
        }
        return "remediationActions.actions." + remediationActionType.type + "." + (isFailed ? 'failure' : 'success') + (entityCount === 1 ? 'Singular' : 'Plural');
    };
    return RemediationActionsService;
}());
export { RemediationActionsService };
