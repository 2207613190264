var _a;
import { OnChanges } from '@angular/core';
import { RemediationStatusType } from '@wcd/domain';
import { EvidenceTypeColorService } from '../../shared/services/evidence-type-color.service';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
export var OTHER_STATUS_TYPE_NAME = 'other';
var evidenceTypeDisplayKeysMap = (_a = {},
    _a[RemediationStatusType.Remediated] = 'incident_overview_evidence_data_categories_remediated',
    _a[RemediationStatusType.NotFound] = 'incident_overview_evidence_data_categories_notFound',
    _a[RemediationStatusType.Unremediated] = 'incident_overview_evidence_data_categories_unremediated',
    _a[RemediationStatusType.Failed] = 'incident_overview_evidence_data_categories_failed',
    _a[RemediationStatusType.Prevented] = 'incident_overview_evidence_data_categories_prevented',
    _a[RemediationStatusType.Blocked] = 'incident_overview_evidence_data_categories_blocked',
    _a[RemediationStatusType.PendingApproval] = 'incident_overview_evidence_data_categories_pendingApproval',
    _a[RemediationStatusType.Declined] = 'incident_overview_evidence_data_categories_declined',
    _a[RemediationStatusType.Running] = 'incident_overview_evidence_data_categories_running',
    _a[RemediationStatusType.Active] = 'incident_overview_evidence_data_categories_active',
    _a[RemediationStatusType.PartiallyRemediated] = 'incident_overview_evidence_data_categories_partially_remediated',
    _a[OTHER_STATUS_TYPE_NAME] = 'incident_overview_evidence_data_categories_other',
    _a);
var EvidenceSummaryComponent = /** @class */ (function () {
    function EvidenceSummaryComponent(evidenceTypeColorService, displayTextPipe) {
        this.evidenceTypeColorService = evidenceTypeColorService;
        this.displayTextPipe = displayTextPipe;
        this.evidenceColorMap = {
            type: 'class',
            map: evidenceTypeColorService.backgroundColorsClassMap,
        };
        this.barTextFormatter = function (evidenceStatus) {
            return displayTextPipe.transform(evidenceStatus, evidenceTypeDisplayKeysMap);
        };
        this.legendTextFormatter = function (count) { return "(" + count + ")"; };
    }
    EvidenceSummaryComponent.prototype.ngOnChanges = function () {
        this.evidence = this.createEvidenceConfig(this.evidenceSummary);
    };
    EvidenceSummaryComponent.prototype.createEvidenceConfig = function (airsEntityTypeResults) {
        var _this = this;
        var groupedResults = airsEntityTypeResults.reduce(function (acc, current) {
            Object.values(RemediationStatusType).forEach(function (status) {
                acc[status] = (acc[status] || 0) + current.getEntityCountByStatus(status);
            });
            return acc;
        }, {});
        var evidenceConfigList = Object.entries(groupedResults)
            .filter(function (_a) {
            var _ = _a[0], count = _a[1];
            return !!count;
        })
            .map(function (_a) {
            var status = _a[0], count = _a[1];
            return ({
                kind: status,
                count: count,
                name: _this.displayTextPipe.transform(status, evidenceTypeDisplayKeysMap),
                iconClassName: _this.evidenceTypeColorService.fontColorsClassMap.get(status),
                value: count,
            });
        });
        var othersCount = this.countSummary
            ? this.countSummary.totalEvidenceCount - this.countSummary.totalRemediationStatusCount
            : 0;
        if (othersCount > 0) {
            evidenceConfigList.push({
                kind: OTHER_STATUS_TYPE_NAME,
                count: othersCount,
                name: this.displayTextPipe.transform(OTHER_STATUS_TYPE_NAME, evidenceTypeDisplayKeysMap),
                iconClassName: this.evidenceTypeColorService.fontColorsClassMap.get(OTHER_STATUS_TYPE_NAME),
                value: othersCount,
            });
        }
        return evidenceConfigList;
    };
    return EvidenceSummaryComponent;
}());
export { EvidenceSummaryComponent };
