const byteCountMultiplies: ReadonlyArray<{ value: number; suffix: string }> = [
	{ value: Math.pow(10, 12), suffix: 'TB' },
	{ value: Math.pow(10, 9), suffix: 'GB' },
	{ value: Math.pow(10, 6), suffix: 'MB' },
	{ value: Math.pow(10, 3), suffix: 'KB' },
	{ value: 0, suffix: 'b' },
];

/**
 * Given a number representing number of bytes, returns a well-formatted string for that byte count.
 * Example: 1234 => "1.23k"
 */
export function prettyBytes(byteCount: number): string {
	if (isNaN(byteCount)) return byteCount.toString();

	let floatNumber: string;

	for (let i = 0, multiplier; (multiplier = byteCountMultiplies[i]); i++) {
		if (byteCount >= multiplier.value) {
			floatNumber = (byteCount / (multiplier.value || 1))
				.toFixed(2)
				.toString()
				.replace(/(^.*\..*?)0+$/, '$1')
				.replace(/\.$/, '');
			return floatNumber + ' ' + multiplier.suffix;
		}
	}

	return byteCount.toString();
}
