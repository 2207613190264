import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Investigation Action Extra Data',
	pluralName: 'Investigation Action Extra Data',
	readonly: true,
})
export class InvestigationActionExtraData extends ModelBase {
	@EntityField({ data: 'general_description' })
	generalDescription: string;

	@EntityField({ data: 'technical_information' })
	technicalInformation: string;

	@EntityField() input: string;

	@EntityField({ data: 'input_summary' })
	inputSummary: string;

	@EntityField({ data: 'input_partial', defaultValue: false })
	inputIsPartial: boolean;

	@EntityField() output: string;

	@EntityField({ data: 'output_summary' })
	outputSummary: string;

	@EntityField({ data: 'output_partial', defaultValue: false })
	outputIsPartial: boolean;
}
