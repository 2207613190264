import { EvaluationWizardStepModel } from '../models/evaluation-wizard-step.model';
import { EvaluationStepType } from '@wcd/domain';

export const EvaluationWizardSteps: ReadonlyArray<EvaluationWizardStepModel> = [
	{
		id: 1,
		name: EvaluationStepType.Setup,
		className: 'wcd-padding-large-top',
		subSteps: [
			{
				id: 1,
				name: EvaluationStepType.SetupInProgress,
				className: 'wcd-padding-large-top',
			},
		],
	},
	{
		id: 2,
		name: EvaluationStepType.Evaluation,
		className: 'wcd-padding-medium-top clickable wcd-border-left',
		badge: true,
		subSteps: [
			{
				id: 2.1,
				name: EvaluationStepType.EvaluationConnectToMachine,
			},
			{
				id: 2.2,
				name: EvaluationStepType.EvaluationRunSimulations,
				routerLink: '/tutorials',
			},
			{
				id: 2.3,
				name: EvaluationStepType.EvaluationInvestigations,
				routerLink: '/investigations',
			},
			{
				id: 2.4,
				name: EvaluationStepType.EvaluationHunt,
				routerLink: '/hunting',
			},
			{
				id: 2.5,
				name: EvaluationStepType.EvaluationThreats,
				routerLink: '/threatanalytics3',
			},
		],
	},
	{
		id: 3,
		className: 'wcd-padding-medium-top clickable wcd-border-left',
		name: EvaluationStepType.EvaluationEnd,
		subSteps: [
			{
				id: 3.1,
				name: EvaluationStepType.EvaluationEndSummary,
				routerLink: '/evaluation/summary',
			},
			{
				id: 3.2,
				name: EvaluationStepType.EvaluationEndFeedback,
				btnClick: 'openFeedbackPanel',
			},
		],
	},
];
