import { SevilleModule } from '../seville.module';
import './seville.investigation';

SevilleModule.provider('search', searchProvider);

function searchProvider() {
	var provider = this;

	provider.entitySearchProviders = [];
	provider.entitySearchProviderNames = [];

	provider.hasActiveProviders = function() {
		return provider.entitySearchProviders.length > 0;
	};

	provider.getRegisteredSearchProvidersEntities = function() {
		return provider.entitySearchProviderNames;
	};

	provider.resolveEntityType = function(searchTerm) {
		for (var i = 0; i < provider.entitySearchProviders.length; i++) {
			var entitySearchProvider = provider.entitySearchProviders[i];
			if (entitySearchProvider.resolver && entitySearchProvider.resolver(searchTerm)) {
				return entitySearchProvider.displayName;
			}
		}

		return null;
	};

	provider.registerEntitySearchProvider = function(entityDisplayName, resolverCallback, searchCallback) {
		provider.entitySearchProviderNames.push(entityDisplayName);
		provider.entitySearchProviders.push({
			displayName: entityDisplayName,
			resolver: resolverCallback,
			search: searchCallback,
		});
	};

	provider.performSearch = function(providerDisplayName, searchTerm, $state) {
		for (var i = 0; i < provider.entitySearchProviders.length; i++) {
			var entitySearchProvider = provider.entitySearchProviders[i];
			if (entitySearchProvider.displayName == providerDisplayName) {
				entitySearchProvider.search(searchTerm, $state);

				break;
			}
		}
	};

	return {
		registerEntitySearchProvider: provider.registerEntitySearchProvider,

		$get: [
			'$state',
			'$rootScope',
			'$localStorage',
			'hotkeys',
			'investigationService',
			function($state, $rootScope, $localStorage, hotkeys, investigationService) {
				$rootScope.appSearchVisible =
					provider.hasActiveProviders() && ($localStorage.appSearchVisible || false);

				function toggleDialog() {
					if (!provider.hasActiveProviders() || $rootScope.stickyOnScrollActive) {
						$rootScope.appSearchVisible = false;

						return;
					}

					$rootScope.appSearchVisible = !$rootScope.appSearchVisible;
					$localStorage.appSearchVisible = $rootScope.appSearchVisible;
				}

				if (provider.hasActiveProviders()) {
					hotkeys.add({
						combo: 'ctrl+s',
						description: 'Opens the search dialog',
						callback: function(event, hotkey) {
							event.preventDefault();
							toggleDialog();
						},
					});
				}

				return {
					performSearch: function(displayName, searchTerm) {
						investigationService.clearFlow();
						provider.performSearch(displayName, searchTerm, $state);
					},
					resolveEntityType: provider.resolveEntityType,
					hasActiveProviders: provider.hasActiveProviders,
					getRegisteredSearchProvidersEntities: provider.getRegisteredSearchProvidersEntities,
				};
			},
		],
	};
}
