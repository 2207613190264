var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActiveIncidentsApiCall, ActiveIncidentsIncidentsPhase2ApiCall, Incident, } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { IncidentFields } from '../../../../../@entities/incidents/services/incident.fields';
import { DataviewField } from '@wcd/dataview';
import { map, tap, take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { SeverityTypeColorService } from '../../../../../shared/services/severity-type-color.service';
import { merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { Router } from '@angular/router';
import { FeaturesService, Feature } from '@wcd/config';
var ActiveIncidentsWidgetComponent = /** @class */ (function (_super) {
    __extends(ActiveIncidentsWidgetComponent, _super);
    function ActiveIncidentsWidgetComponent(reportsService, paris, globalEntityTypesService, incidentFields, severityTypeColorService, changeDetectorRef, i18nService, router, featuresService) {
        var _this = _super.call(this, reportsService) || this;
        _this.paris = paris;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.incidentFields = incidentFields;
        _this.severityTypeColorService = severityTypeColorService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.i18nService = i18nService;
        _this.router = router;
        _this.featuresService = featuresService;
        return _this;
    }
    ActiveIncidentsWidgetComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.incidentEntityType = this.globalEntityTypesService.getEntityType(Incident);
        this.fields = this.incidentFields.dashboardWidgetFields.map(function (field) {
            var renameTitle = field.id === 'impactedEntities' ? _this.i18nService.get('incident_scope') : field.name;
            var noSortField = merge({}, field, { name: renameTitle }, { sort: { enabled: false } });
            return new DataviewField(noSortField);
        });
        this.dataViewConfig = {
            id: 'active-incidents-widget-dataview',
            loadResults: function () {
                return _this.data$.pipe(map(function (activeIncidents) { return ({
                    items: activeIncidents.topIncidents,
                    count: activeIncidents.topIncidents.length,
                }); }));
            },
        };
    };
    Object.defineProperty(ActiveIncidentsWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'activeIncidents',
                name: this.i18nService.get('incidents_title'),
                loadData: function () {
                    var incidentsDashboardWidget = _this.featuresService.isEnabled(Feature.IncidentsDashboardWidget);
                    return _this.paris.apiCall(incidentsDashboardWidget ? ActiveIncidentsIncidentsPhase2ApiCall : ActiveIncidentsApiCall).pipe(tap(function (data) {
                        _this.data = data;
                        _this.changeDetectorRef.markForCheck();
                    }), take(1));
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    ActiveIncidentsWidgetComponent.prototype.viewAllIncidentClicked = function () {
        this.router.navigate(['/incidents']);
    };
    return ActiveIncidentsWidgetComponent;
}(ReportWidgetComponent));
export { ActiveIncidentsWidgetComponent };
