import { Paris } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { CustomTiIndicatorActionsType, CustomTiIndicatorActionsTypes, CustomTiIndicatorsTypes, IndicatorAlertCategory, MachineGroup, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { lowerFirst, sortBy } from 'lodash-es';
import { mergeMap, tap } from 'rxjs/operators';
import { MitreTechniqueDisplayPipe } from '../../../shared/pipes/mitre-technique-display.pipe';
import { CATEGORY_TO_MITRE_TECHNIQUES_MAPPING } from '@wcd/scc-common';
import { MachinesService } from '../../machines/services/machines.service';
import { RegExpService } from '@wcd/shared';
export var ScopeTypesEnum;
(function (ScopeTypesEnum) {
    ScopeTypesEnum[ScopeTypesEnum["all"] = 0] = "all";
    ScopeTypesEnum[ScopeTypesEnum["specific"] = 1] = "specific";
})(ScopeTypesEnum || (ScopeTypesEnum = {}));
export var MAX_BYPASSDURATION = 999;
var CustomTiIndicatorComponentService = /** @class */ (function () {
    function CustomTiIndicatorComponentService(paris, authService, i18nService, machinesService, featuresService, mitreTechniqueDisplayPipe) {
        var _this = this;
        this.paris = paris;
        this.authService = authService;
        this.i18nService = i18nService;
        this.machinesService = machinesService;
        this.featuresService = featuresService;
        this.mitreTechniqueDisplayPipe = mitreTechniqueDisplayPipe;
        this._valuesLabelTexts = new Map();
        this._lastGenerateAlertState = false;
        this.selectableMachineGroupScopes = [
            {
                id: ScopeTypesEnum.all,
                name: this.i18nService.get('customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.values.allmachines'),
            },
            {
                id: ScopeTypesEnum.specific,
                name: this.i18nService.get('customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.values.selectedgroups'),
            },
        ];
        this.actionOptions = [
            {
                id: CustomTiIndicatorActionsTypes.Allow,
                name: this.i18nService.strings.customTiIndicator_dataview_entity_fields_action_values_allow_title,
            },
            {
                id: CustomTiIndicatorActionsTypes.Alert,
                name: this.i18nService.strings.customTiIndicator_dataview_entity_fields_action_values_alert_title,
            },
            {
                id: CustomTiIndicatorActionsTypes.AlertAndBlock,
                name: this.i18nService.strings
                    .customTiIndicator_dataview_entity_fields_action_values_alertandblock_title,
            },
        ];
        this.lookBackPeriodList = [30, 60, 90];
        this.allowSpecificMachineGroups = false;
        this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
        this.currentMachineGroups = [];
        this.loadingMachineGroups = true;
        this.availableMachineGroups = [];
        this.currentActionOption = this.actionOptions[CustomTiIndicatorActionsTypes.Allow];
        this.setGenerateAlertChange = function (shouldGenenerateAlert, customTiIndicator) {
            customTiIndicator.generateAlert = shouldGenenerateAlert;
            _this._isGenerateAlertDirty = true;
            _this._lastGenerateAlertState = shouldGenenerateAlert;
            _this.setIsAlertable(customTiIndicator);
            _this.resetRedundantFields(customTiIndicator);
        };
        this._machineGroupsRepo = paris.getRepository(MachineGroup);
        this._breakTheGlassEnabled = this.featuresService.isEnabled(Feature.BreakTheGlass);
        this._tvmApplicationBlockEnabled = this.featuresService.isEnabled(Feature.TVMApplicationBlock);
        this._tvmApplicationControl = this.featuresService.isEnabled(Feature.TvmApplicationControl);
        this._xplatIndicatorEnabled = this.featuresService.isEnabled(Feature.XplatIndicators);
    }
    CustomTiIndicatorComponentService.prototype.init = function (customTiIndicator, customTiIndicatorType) {
        if (this._tvmApplicationBlockEnabled) {
            this.actionOptions = [
                this.actionOptions[CustomTiIndicatorActionsTypes.Allow],
                {
                    id: CustomTiIndicatorActionsTypes.Audit,
                    name: this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_values_audit_title,
                },
            ];
            if (customTiIndicatorType.id !== CustomTiIndicatorsTypes.Files ||
                (customTiIndicatorType.id === CustomTiIndicatorsTypes.Files && this._tvmApplicationControl)) {
                this.actionOptions.splice(2, 0, {
                    id: CustomTiIndicatorActionsTypes.Block,
                    name: this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_values_blockexecution_title,
                    helpText: customTiIndicatorType.id === CustomTiIndicatorsTypes.Files &&
                        this._xplatIndicatorEnabled
                        ? this.i18nService.strings
                            .customTiIndicator_dataview_entity_fields_action_windows_only_description
                        : null,
                });
            }
            if (customTiIndicatorType.id === CustomTiIndicatorsTypes.Files) {
                this.actionOptions.splice(this._tvmApplicationControl ? 3 : 2, 0, {
                    id: CustomTiIndicatorActionsTypes.BlockAndRemediate,
                    name: this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_values_blockandremediate_title,
                });
            }
        }
        if ((this._breakTheGlassEnabled &&
            (customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
                customTiIndicatorType.id === CustomTiIndicatorsTypes.Url)) ||
            (this._tvmApplicationBlockEnabled &&
                this._tvmApplicationControl &&
                customTiIndicatorType.id === CustomTiIndicatorsTypes.Files)) {
            this.actionOptions.splice(2, 0, {
                id: CustomTiIndicatorActionsTypes.Warn,
                name: this.i18nService.strings
                    .customTiIndicator_dataview_entity_fields_action_values_warn_title,
                helpText: customTiIndicatorType.id === CustomTiIndicatorsTypes.Files && this._xplatIndicatorEnabled
                    ? this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_windows_only_description
                    : null,
            });
        }
        if (customTiIndicatorType.id === CustomTiIndicatorsTypes.Certificate) {
            var certificateBlock = this._tvmApplicationBlockEnabled
                ? {
                    id: CustomTiIndicatorActionsTypes.BlockAndRemediate,
                    name: this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_values_blockandremediate_title,
                }
                : {
                    id: CustomTiIndicatorActionsTypes.Block,
                    name: this.i18nService.strings
                        .customTiIndicator_dataview_entity_fields_action_values_block_title,
                };
            this.actionOptions = [this.actionOptions[CustomTiIndicatorActionsTypes.Allow], certificateBlock];
        }
        this.currentActionOption = this.actionOptions.find(function (action) { return action.id === customTiIndicator.action.id; });
        this.categoryList = Object.keys(IndicatorAlertCategory)
            .filter(function (k) { return typeof IndicatorAlertCategory[k] === 'number'; })
            .map(function (k) { return IndicatorAlertCategory[k]; });
        this.selectableMitreTechniques = this.getSelectableMitreTechniques(customTiIndicator.category || IndicatorAlertCategory.SuspiciousActivity);
    };
    CustomTiIndicatorComponentService.prototype.destroy = function () {
        this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
    };
    CustomTiIndicatorComponentService.prototype._setAvailableScopes = function (customTiIndicator) {
        if (this._allMachineGroups.length && this._allMachineGroups.length > 0) {
            this.allowSpecificMachineGroups = true;
            if (this.authService.currentUser.isMdeAdmin) {
                this.allowAllMachineGroups = true;
                this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
                if (!customTiIndicator.isNew &&
                    customTiIndicator.rbacGroupIds &&
                    customTiIndicator.rbacGroupIds.length > 0) {
                    this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.specific];
                }
            }
            else {
                this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.specific];
                if (!customTiIndicator.isNew &&
                    (!customTiIndicator.rbacGroupIds || customTiIndicator.rbacGroupIds.length <= 0)) {
                    this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
                    this.allowSpecificMachineGroups = false;
                    this.allowAllMachineGroups = true;
                }
            }
        }
        else {
            this.currentMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.all];
        }
    };
    CustomTiIndicatorComponentService.prototype._setLabelText = function (values) {
        if (values.length > 3)
            return values.length + " values";
        var _values = values.map(function (value) { return value.name; });
        if (_values.length)
            return _values.join(', ');
        return this.i18nService.get("customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.selectValues");
    };
    CustomTiIndicatorComponentService.prototype._getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18nService.get('machineGroup.unassignedGroup.name')
                : machineGroup.name,
        };
    };
    CustomTiIndicatorComponentService.prototype._getUserExposedRbacGroups = function () {
        var _this = this;
        return this.machinesService.getFullUserExposedMachineGroups().pipe(tap(function (userExposedMachineGroups) {
            _this._exposedMachineGroups = _this._allMachineGroups.filter(function (machineGroup) {
                return (userExposedMachineGroups &&
                    userExposedMachineGroups.some(function (group) { return group.id == machineGroup.id; }));
            });
            _this.availableMachineGroups = _this._exposedMachineGroups.map(function (machineGroup) {
                return _this._getMachineGroupCheckListValue(machineGroup);
            });
        }));
    };
    Object.defineProperty(CustomTiIndicatorComponentService.prototype, "machineGroupsFieldId", {
        get: function () {
            return 'custom-ti-machine-groups';
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorComponentService.prototype.setCurrentMachineGroups = function (machineGroupIds) {
        var _this = this;
        this.currentMachineGroups = this._allMachineGroups
            .filter(function (machineGroup) { return machineGroupIds.includes(machineGroup.id); })
            .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        this.setLabelText(this.machineGroupsFieldId, this.currentMachineGroups);
    };
    CustomTiIndicatorComponentService.prototype.setLabelText = function (fieldId, values) {
        var labelText = this._setLabelText(values), currentValue = this._valuesLabelTexts.get(fieldId);
        if (!currentValue || currentValue !== labelText)
            this._valuesLabelTexts.set(fieldId, labelText);
        this.labelText =
            this._valuesLabelTexts.get(fieldId) ||
                this.i18nService.get('customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.selectValues');
    };
    CustomTiIndicatorComponentService.prototype.setMachineGroups = function (customTiIndicator) {
        var _this = this;
        return this._machineGroupsRepo.allItems$.pipe(tap(function (groups) {
            _this.loadingMachineGroups = false;
            _this._allMachineGroups = groups;
            _this._setAvailableScopes(customTiIndicator);
        }), mergeMap(function () { return _this._getUserExposedRbacGroups(); }));
    };
    CustomTiIndicatorComponentService.prototype.setIsAlertable = function (customTiIndicator) {
        this._isAlertable =
            customTiIndicator.action.id === CustomTiIndicatorActionsTypes.Alert ||
                customTiIndicator.action.id === CustomTiIndicatorActionsTypes.AlertAndBlock ||
                customTiIndicator.generateAlert;
    };
    Object.defineProperty(CustomTiIndicatorComponentService.prototype, "isAlertable", {
        get: function () {
            return !!this._isAlertable;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorComponentService.prototype, "isGenerateAlertDirty", {
        get: function () {
            return !!this._isGenerateAlertDirty;
        },
        set: function (value) {
            this._isGenerateAlertDirty = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorComponentService.prototype, "lastGenerateAlertState", {
        get: function () {
            return this._lastGenerateAlertState;
        },
        set: function (value) {
            this._lastGenerateAlertState = value;
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorComponentService.prototype.onActionOptionsChange = function (newOption, customTiIndicator, customTiIndicatorType, generateAlertCheckbox) {
        customTiIndicator.action = this.paris.getValue(CustomTiIndicatorActionsType, function (action) { return action.id == newOption.id; });
        if (this._tvmApplicationBlockEnabled &&
            customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate) {
            switch (newOption.id) {
                case CustomTiIndicatorActionsTypes.Audit:
                    customTiIndicator.generateAlert = true;
                    break;
                case CustomTiIndicatorActionsTypes.Allow:
                    customTiIndicator.generateAlert = false;
                    break;
                case CustomTiIndicatorActionsTypes.BlockAndRemediate:
                case CustomTiIndicatorActionsTypes.Block:
                    if (!this._isGenerateAlertDirty) {
                        customTiIndicator.generateAlert = true;
                    }
                    else {
                        customTiIndicator.generateAlert = this._lastGenerateAlertState;
                    }
                    break;
                default:
                    if (!this._isGenerateAlertDirty) {
                        customTiIndicator.generateAlert = false;
                    }
                    else {
                        customTiIndicator.generateAlert = this._lastGenerateAlertState;
                    }
            }
            generateAlertCheckbox.checked = customTiIndicator.generateAlert;
        }
        this.setIsAlertable(customTiIndicator);
        if (this._isAlertable) {
            customTiIndicator.category =
                customTiIndicator.category || IndicatorAlertCategory.SuspiciousActivity;
            customTiIndicator.historicalDetection = customTiIndicator.historicalDetection || false;
        }
        this.resetRedundantFields(customTiIndicator);
    };
    CustomTiIndicatorComponentService.prototype.resetRedundantFields = function (customTiIndicator) {
        var actionType = customTiIndicator.action.id;
        if (!this._isAlertable) {
            customTiIndicator.severity = null;
            customTiIndicator.category = null;
            customTiIndicator.historicalDetection = null;
            customTiIndicator.lookBackPeriod = null;
            customTiIndicator.mitreTechniques = null;
        }
        if (actionType !== CustomTiIndicatorActionsTypes.Warn) {
            customTiIndicator.educateUrl = null;
            customTiIndicator.bypassDurationHours = null;
        }
    };
    CustomTiIndicatorComponentService.prototype.getFormatCategoryLabel = function (category) {
        var categoryName = IndicatorAlertCategory[category];
        return this.i18nService.get("reporting.alertsByCategory." + lowerFirst(categoryName));
    };
    CustomTiIndicatorComponentService.prototype.getFormatSeverityLabel = function (sevirityType) {
        return this.i18nService.get("customTiIndicator.dataview.entity.fields.alertSeverity.values." + sevirityType.name.toLowerCase() + ".title");
    };
    CustomTiIndicatorComponentService.prototype.getSelectableMitreTechniques = function (alertCategory) {
        var _this = this;
        var categoryName = IndicatorAlertCategory[alertCategory];
        return (categoryName &&
            CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[categoryName] &&
            sortBy(CATEGORY_TO_MITRE_TECHNIQUES_MAPPING[categoryName].map(function (technique) { return ({
                id: technique,
                name: _this.mitreTechniqueDisplayPipe.transform(technique, true),
            }); }), function (techniqueField) { return _this.mitreTechniqueDisplayPipe.transform(techniqueField.id, false); }));
    };
    CustomTiIndicatorComponentService.prototype.onCategoryChanged = function ($event, customTiIndicator) {
        customTiIndicator.category = $event;
        customTiIndicator.mitreTechniques = null; // on category change, reset the MITRE techniques selection
        this.selectableMitreTechniques = this.getSelectableMitreTechniques($event);
    };
    CustomTiIndicatorComponentService.prototype.getMitreTechniqueDropDownPlaceHolder = function (customTiIndicator) {
        if (!customTiIndicator.mitreTechniques || !customTiIndicator.mitreTechniques.length) {
            return this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholder');
        }
        return customTiIndicator.mitreTechniques.length > 1
            ? this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.plural', { count: customTiIndicator.mitreTechniques.length })
            : this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertMitreTechniques.placeholderWithSelection.singular');
    };
    CustomTiIndicatorComponentService.prototype.isValidUrl = function (url) {
        if (!url ||
            url === '' ||
            (!url.toLowerCase().startsWith('http://') && !url.toLowerCase().startsWith('https://'))) {
            return false;
        }
        var domain = this.extractDomainFromUrl(url);
        return RegExpService.ipOrDomain.test(domain);
    };
    CustomTiIndicatorComponentService.prototype.extractDomainFromUrl = function (url) {
        var domain;
        if (url.indexOf('://') > -1) {
            domain = url.split('/')[2];
        }
        else {
            domain = url.split('/')[0];
        }
        // find & remove port number
        domain = domain.split(':')[0];
        // find & remove "?"
        domain = domain.split('?')[0];
        return domain;
    };
    CustomTiIndicatorComponentService.prototype.isWarnDetailsValid = function (customTiIndicator) {
        if (customTiIndicator.action.id !== CustomTiIndicatorActionsTypes.Warn) {
            return true;
        }
        return ((!customTiIndicator.bypassDurationHours ||
            (customTiIndicator.bypassDurationHours > 0 &&
                customTiIndicator.bypassDurationHours <= MAX_BYPASSDURATION)) &&
            (!customTiIndicator.educateUrl || this.isValidUrl(customTiIndicator.educateUrl)));
    };
    return CustomTiIndicatorComponentService;
}());
export { CustomTiIndicatorComponentService };
