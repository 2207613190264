<wcd-collapsible-section [label]="'users.entityDetails.sections.details.title' | i18n">
	<aad-user-entity-details [entity]="aadUser" [mode]="EntityDetailsMode.SidePane"></aad-user-entity-details>
</wcd-collapsible-section>
<wcd-collapsible-section label="{{'alerts.active' | i18n}}">
	<ng-container *ngIf="(alertData$ | async) as alertData">
		<fab-shimmer [isDataLoaded]="alertData !== loadingSymbol">
			<ng-container *ngIf="alertData?.alertsSeveritySummary?.hasAlerts">
				<alerts-severity-incident-summary [alertsSeveritySummary]="alertData.alertsSeveritySummary"></alerts-severity-incident-summary>
			</ng-container>
			<alerts-dataview
					*ngIf="alertData.alerts?.length"
					dataViewId="aad-user-alerts"
					[dataViewConfig]="{ data: alertData.alerts, id:'aad-user-alerts' }"
					[showHeader]="false"
					[allowFilters]="false"
					[allowPaging]="false"
					[allowGrouping]="false"
					[defaultSortId]="DefaultSortBy"
					[allowTimeRangeSelect]="false"
					[hideControls]="true"
					[maxItems]="MaxAlertItems"
					[disableSelection]="true"
					[queueHeader]="false"
					[maxColumnWidth]="DefaultMaxColumnWidth"
					[onlyFields]="['title', 'severity', 'status', 'lasteventtime']"
					[sortDisabledFields]="['title', 'severity', 'status', 'lasteventtime']"
					[removePadding]="false"
			></alerts-dataview>

		</fab-shimmer>

	</ng-container>
	<div class="error-message" *ngIf="error">
		<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon >
		{{'alerts.error' | i18n}}}
	</div>

</wcd-collapsible-section>



