
		<wcd-panel (close)="destroy()" (save)="closePanel()" [settings]="settings">
			<form
				#sampleDownloadForm="ngForm"
				(keydown.enter)="$event.preventDefault()"
				class="wcd-full-height wcd-flex-vertical"
			>
				<div class="wcd-flex-1">
					{{ file.name }}
					<div class="subtle wcd-margin-large-bottom">
						{{ 'file.sample.download.fileIsReady' | i18n }}
					</div>
					<div class="wcd-margin-xsmall-bottom">
						{{ 'file.sample.download.reasonHeader' | i18n }}
					</div>
					<textarea
						class="form-control comment-input wcd-full-width wcd-margin-large-bottom wcd-required wcd-no-resize"
						[placeholder]="'file.sample.download.downloadReasonPlaceholder' | i18n"
						maxlength="300"
						autofocus
						required
						rows="5"
						[(ngModel)]="sampleDownloadReason"
						name="reason"
					></textarea>
					<div class="wcd-margin-small-bottom">
						{{ 'file.sample.download.passwordMessage' | i18n }}
					</div>
					<textarea
						type="text"
						maxlength="30"
						[(ngModel)]="zipPassword"
						class="wcd-padding-small-all wcd-width-medium wcd-required wcd-no-resize"
						required
						rows="1"
						name="password"
					></textarea>
				</div>
				<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
					<fab-primary-button
						[disabled]="!isValid()"
						(onClick)="downloadSample($event)"
						(keyup.enter)="downloadSample($event)"
						className="wcd-margin-small-right"
						data-track-type="Button"
						[text]="'file.sample.download.buttons.download' | i18n"
						type="submit"
					>
					</fab-primary-button>
					<fab-default-button
						(onClick)="closePanel()"
						(keyup.enter)="closePanel()"
						data-track-type="Button"
						[text]="'file.sample.download.buttons.cancel' | i18n"
					>
					</fab-default-button>
				</div>
			</form>
		</wcd-panel>
	