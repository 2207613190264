<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="entitesGraphInitilized">
	<section *ngFor="let entity of entities; let last = last"
			 class="event-graph--entity"
			 data-track-component="CyberEventsEntitiesGraph"
			 data-track-component-type="Graph">
		<ng-container [ngSwitch]="entity.relationToPrecedingEntity">
				<span *ngSwitchCase="CyberEventEntityRelationTypeEnum.Child"
					  class="event-graph--link__child-connector"
					  [ngStyle]="{'left.px': DEPTH_LEVEL_LEFT_PADDING * (entity.depth - 1)}"></span>
			<ng-container *ngSwitchCase="CyberEventEntityRelationTypeEnum.Outer" [ngTemplateOutlet]="relationText"></ng-container>
			<ng-container *ngSwitchCase="CyberEventEntityRelationTypeEnum.Parent" [ngTemplateOutlet]="relationText"></ng-container>
			<ng-template #relationText>
				<div class="event-graph--link__text-connector"
					 [ngStyle]="{'padding-left.px': DEPTH_LEVEL_LEFT_PADDING * entity.depth}">{{getEventEntityRelationText(entity)}}</div>
			</ng-template>
		</ng-container>
		<div [ngStyle]="{'padding-left.px': DEPTH_LEVEL_LEFT_PADDING * entity.depth}">
			<h5 class="event-graph--entity--title" tabindex="0" role="treeitem"
				[attr.aria-expanded]="minimizedSections.has(getEntityUniqueId(entity))" (keydown.enter)="toggleSections(entity)">
				 <wcd-shared-icon *ngIf="entity.icon" [iconName]="entity.icon" class="event-graph--entity--title--icon"> </wcd-shared-icon >
				<span>{{entity.name}}</span>
				<a (click)="toggleSections(entity)" class="wcd-margin-small-left"
				   [attr.data-track-id]="minimizedSections.has(getEntityUniqueId(entity)) ? 'Expand': 'Collapse'"
				   data-track-type="Toggle">
					<span class="event-graph--entity--toggle"
						  [class.event-graph--entity--toggle__minimized]="minimizedSections.has(getEntityUniqueId(entity))">
						<fab-icon iconName="ChevronUp"></fab-icon>
					</span>
				</a>
			</h5>
			<div [ngSwitch]="entity.entityType"
				 [hidden]="minimizedSections.has(getEntityUniqueId(entity))"
				 class="event-graph--entity--details"
				 [class.with-child]="!last">
				<global-entity-details [entity]="entity.item" [entityType]="entity.entityType" [showEntityPanelLink]="true" [dataEnriched]="dataChanged"></global-entity-details>
				<generic-fields-list *ngIf="entity.genericFields" [genericFields]="entity.genericFields"></generic-fields-list>
			</div>
		</div>
	</section>
</div>
