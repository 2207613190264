import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CertificateInfo, File, FileFileStatsRelationship } from '@wcd/domain';
import { isNil } from 'lodash-es';

export enum CertificateInfoKind {
	Unknown,
	Signed,
	SignedInvalid,
	Unsigned,
	Revoked
}

@Component({
	selector: 'file-certificate-info',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="!certificateInfoInitiliazed; else certificateTypeInitiliazed">
			<i class="loader-icon"></i>
		</ng-container>
		<ng-template #certificateTypeInitiliazed>
			<ng-container [ngSwitch]="certificateType">
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Signed">
					<fab-icon iconName="Certificate"></fab-icon>
					{{ certificateInfo.signer }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.SignedInvalid">
					<fab-icon iconName="Certificate" contentClass="ms-color-red"></fab-icon>
					{{ certificateInfo.signer }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Unsigned">
					<fab-icon iconName="BlockedSite"></fab-icon>
					{{ 'files.entityDetails.sections.details.fields.signer.unsigned' | i18n }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Unknown">
					<fab-icon iconName="Certificate"></fab-icon>
					{{ 'files.entityDetails.sections.details.fields.signer.unknown' | i18n }}
				</ng-container>
			</ng-container>
		</ng-template>
	`,
})
export class FileCertificateInfoComponent implements OnChanges {
	@Input() file: File;
	@Input() dataEnriched: boolean;
	certificateInfo: CertificateInfo;

	CertificateInfoKindEnum = CertificateInfoKind;
	certificateType: CertificateInfoKind;
	certificateInfoInitiliazed: boolean;

	ngOnChanges() {
		this.certificateInfo = FileCertificateInfoComponent.getCertificateInfo(this.file);
		this.setCertificateInfoKind();
	}

	setCertificateInfoKind() {
		this.certificateInfoInitiliazed = false;
		if (this.dataEnriched === false && this.certificateInfo === null) {
			return;
		}

		// if certificateInfo.isValid is null or false the certificate is unknown
		// see https://dev.azure.com/microsoft/OS/_workitems/edit/26902432/
		if (this.certificateInfo === null || !this.certificateInfo.isValid) {
			this.certificateType = CertificateInfoKind.Unknown;
		} else if (this.certificateInfo.signer === null) {
			this.certificateType = CertificateInfoKind.Unsigned;
		} else {
			this.certificateType = CertificateInfoKind.Signed;
		}

		this.certificateInfoInitiliazed = true;
	}

	static getCertificateInfo(file: File): CertificateInfo {
		if (isNil(file.certificateInfo)) {
			return null;
		}

		return {
			...file.certificateInfo,
		};
	}
}
