export const BUILT_IN_ACCOUNT_NAMES: ReadonlySet<string> = new Set([
	'SYSTEM',
	'LOCAL SERVICE',
	'NETWORK',
	'NETWORK SERVICE',
	'RDV GRAPHICS SERVICE',
	'TVSU_TMP',
	'ROOT',
]);

export const BUILT_IN_DOMAIN_ACCOUNT_NAMES: ReadonlySet<string> = new Set([
	'NT AUTHORITY',
	'NT-AUTORITÄT',
	'AUTORITE NT',
	'AUTORIDADE NT',
	'NT SERVICE',
	'SERVICE RÉSEAU',
	'NT VIRTUAL MACHINE',
]);

export const BUILT_IN_ACCOUNT_SIDS: ReadonlySet<string> = new Set([
	'S-1-5-18',
	'S-1-5-20',
	'S-1-5-19',
	'S-1-5-7',
	'S-1-0-0'
]);

export const isAccountLinkable = (account: {accountName?:string, accountDomainName?:string, sid?:string, aadUserId?:string}): boolean => {
	const hasSidOrNameAndDomain = account.sid || (account.accountName && account.accountDomainName);
	const isBuiltInAccountName = account.accountName && BUILT_IN_ACCOUNT_NAMES.has(account.accountName.toUpperCase());
	const isBuiltInDomainAccountName = account.accountDomainName && BUILT_IN_DOMAIN_ACCOUNT_NAMES.has(account.accountDomainName.toUpperCase());
	const isBuiltInAccountSid = account.sid && BUILT_IN_ACCOUNT_SIDS.has(account.sid.toUpperCase());

	return (hasSidOrNameAndDomain && !isBuiltInAccountName && !isBuiltInDomainAccountName && !isBuiltInAccountSid) || !!account.aadUserId;
}
