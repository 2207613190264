import { OnInit, EventEmitter } from '@angular/core';
import { DataTableField } from '@wcd/datatable';
import { isEmpty, isPlainObject, maxBy, isObjectLike, head, isString } from 'lodash-es';
var HuntingRecordPropertyDisplayComponent = /** @class */ (function () {
    function HuntingRecordPropertyDisplayComponent() {
        this.presentObjectValuesAsTable = false;
        this.openUserPanelEvent = new EventEmitter();
        this.openEntitySidePanelClickEvent = new EventEmitter();
        this.tableColumns = [];
        this.tableItems = [];
        this.showTableHeaders = false;
    }
    Object.defineProperty(HuntingRecordPropertyDisplayComponent.prototype, "link", {
        get: function () {
            return this._link;
        },
        set: function (value) {
            this._link = !!value && !isEmpty(value.routerLink) && !!head(value.routerLink) ? value : null;
        },
        enumerable: true,
        configurable: true
    });
    HuntingRecordPropertyDisplayComponent.prototype.ngOnInit = function () {
        this.presentValueAsTable = this.presentObjectValuesAsTable && this.shouldPresentValueInTable(this.propertyData);
        if (this.presentValueAsTable) {
            if (!isObjectLike(this.propertyData)) {
                var parsedJsonValue = void 0;
                try {
                    parsedJsonValue = JSON.parse(this.propertyData);
                }
                catch (_a) {
                    // invalid json
                    this.presentValueAsTable = false;
                    return;
                }
                this.propertyData = parsedJsonValue;
            }
            this.setDisplayColumns();
        }
    };
    HuntingRecordPropertyDisplayComponent.prototype.formatObjectString = function (value) {
        return typeof value === 'object' ? JSON.stringify(value) : value;
    };
    HuntingRecordPropertyDisplayComponent.prototype.shouldPresentValueInTable = function (value) {
        var parsedValue = value;
        if (isString(value)) {
            // Check for stringified object / array.
            try {
                parsedValue = JSON.parse(value);
            }
            catch (_a) {
                // string value that can't be parsed to an object / array. don't show as a table.
                return false;
            }
        }
        if (isObjectLike(parsedValue)) {
            // Object / array values are presented as tables as long as they aren't empty.
            return !isEmpty(parsedValue);
        }
        // Not an object / array - shouldn't be presented in a table.
        return false;
    };
    HuntingRecordPropertyDisplayComponent.prototype.setDisplayColumns = function () {
        var _this = this;
        if (Array.isArray(this.propertyData)) {
            this.setDisplayColumnsForArray();
        }
        else {
            // object type
            this.tableColumns = DataTableField.fromList([
                {
                    id: 'key',
                    name: 'Key',
                },
                {
                    id: 'value',
                    name: 'Value',
                    getTooltip: function (keyAndValueObject) {
                        return _this.formatObjectString(keyAndValueObject.value);
                    },
                },
            ]);
            this.tableItems = Object.keys(this.propertyData).map(function (key) { return ({
                key: key,
                value: _this.formatObjectString(_this.propertyData[key]),
            }); });
        }
    };
    HuntingRecordPropertyDisplayComponent.prototype.isArrayOfSimilarObjects = function (data) {
        if (!data.every(isPlainObject)) {
            return false;
        }
        /**
         * some arrays may include objects with "missing" keys when their values are null.
         * For example: [{ 'key1':1, 'key2':2 }, { 'key1':11 }].
         * Such arrays should show table with all possible keys.
         */
        var keysOfItemWithMaxKeys = Object.keys(maxBy(data, function (item) { return Object.keys(item).length; }));
        // Check that every object in the array has keys that are contained in the item with max number of keys.
        return data.every(function (item) { return Object.keys(item).every(function (key) { return keysOfItemWithMaxKeys.includes(key); }); });
    };
    HuntingRecordPropertyDisplayComponent.prototype.setDisplayColumnsForArray = function () {
        var _this = this;
        if (this.isArrayOfSimilarObjects(this.propertyData)) {
            // property data is array of objects with same keys.
            // set the table's column as the objects keys.
            var firstItemKeys = isPlainObject(this.propertyData[0]) && Object.keys(this.propertyData[0]);
            this.tableColumns = DataTableField.fromList(firstItemKeys.map(function (key) { return ({
                id: key,
                name: key,
                getTooltip: function (keyAndValueObject) {
                    return _this.formatObjectString(keyAndValueObject.value);
                },
            }); }));
            this.tableItems = this.propertyData;
            this.showTableHeaders = true;
            return;
        }
        this.tableColumns = DataTableField.fromList([
            {
                id: 'value',
                name: 'Value',
                getTooltip: function (keyAndValueObject) {
                    return _this.formatObjectString(keyAndValueObject.value);
                },
            },
        ]);
        this.tableItems = this.propertyData.map(function (item) { return ({
            value: _this.formatObjectString(item),
        }); });
    };
    Object.defineProperty(HuntingRecordPropertyDisplayComponent.prototype, "isUserEntity", {
        get: function () {
            return userCellProps.includes(this.columnInfo.name);
        },
        enumerable: true,
        configurable: true
    });
    HuntingRecordPropertyDisplayComponent.prototype.onUserCellClick = function ($event) {
        $event.stopPropagation();
        var name = this.columnInfo.name;
        var userProps;
        if (name === "AccountSid" || name === "InitiatingProcessAccountSid") {
            userProps = { sid: this.propertyData };
        }
        else {
            userProps = { aadUserId: this.propertyData };
        }
        this.openUserPanelEvent.emit(userProps);
    };
    return HuntingRecordPropertyDisplayComponent;
}());
export { HuntingRecordPropertyDisplayComponent };
export var userCellProps = ['AccountSid', 'AccountObjectId', 'InitiatingProcessAccountSid', 'InitiatingProcessAccountObjectId'];
