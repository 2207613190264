/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i8 from "../../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./aad-user-investigation-priority.component";
var styles_AadUserInvestigationPriorityComponent = [];
var RenderType_AadUserInvestigationPriorityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AadUserInvestigationPriorityComponent, data: {} });
export { RenderType_AadUserInvestigationPriorityComponent as RenderType_AadUserInvestigationPriorityComponent };
function View_AadUserInvestigationPriorityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "wcd-shared-icon", [["class", "icon small-icon wcd-margin-small-right"], ["iconName", "warning"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(3, { "color-text-warning": 0, "color-text-warning-dark": 1 }), i0.ɵdid(4, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "icon small-icon wcd-margin-small-right"; var currVal_1 = _ck(_v, 3, 0, ((_co.percentile > 0) && (_co.percentile < 90)), (_co.percentile >= 90)); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = "warning"; _ck(_v, 4, 0, currVal_2); }, null); }
export function View_AadUserInvestigationPriorityComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.I18nPipe, [i6.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "div", [["class", "wcd-flex-items-center"]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(3, 147456, null, 0, i7.TooltipDirective, [i0.ElementRef, i8.TooltipsService, i9.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AadUserInvestigationPriorityComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(8, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showTooltip ? i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v, 0), "incident.overview.scope.fields.riskLevel.tooltip")) : null); _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.priority; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.priorityText || i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v, 0), "common.noDataAvailable"))); _ck(_v, 8, 0, currVal_2); }); }
export function View_AadUserInvestigationPriorityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "aad-user-investigation-priority", [], null, null, null, View_AadUserInvestigationPriorityComponent_0, RenderType_AadUserInvestigationPriorityComponent)), i0.ɵdid(1, 49152, null, 0, i10.AadUserInvestigationPriorityComponent, [], null, null)], null, null); }
var AadUserInvestigationPriorityComponentNgFactory = i0.ɵccf("aad-user-investigation-priority", i10.AadUserInvestigationPriorityComponent, View_AadUserInvestigationPriorityComponent_Host_0, { priority: "priority", percentile: "percentile", showTooltip: "showTooltip" }, {}, []);
export { AadUserInvestigationPriorityComponentNgFactory as AadUserInvestigationPriorityComponentNgFactory };
