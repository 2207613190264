import { Observable } from 'rxjs';
var MAX_ITEMS_SHOWN = 4;
var BreadcrumbsComponent = /** @class */ (function () {
    function BreadcrumbsComponent() {
    }
    Object.defineProperty(BreadcrumbsComponent.prototype, "maxItems", {
        /**
         * Max number of items to be shown. Older items will be shown collapsed in a dropdown.
         */
        get: function () {
            return MAX_ITEMS_SHOWN;
        },
        enumerable: true,
        configurable: true
    });
    BreadcrumbsComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    return BreadcrumbsComponent;
}());
export { BreadcrumbsComponent };
