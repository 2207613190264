import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { AlertSeverityCount } from './alert-severity-count.value-object';
import { sumBy } from 'lodash-es';

@ValueObject({
	singularName: 'Alerts Severity Summary',
	pluralName: 'Alerts Severity Summaries',
	readonly: true,
})
export class AlertsSeveritySummary extends ModelBase {
	@EntityField({
		data: ['InformationalSevAlerts', 'Informational'],
		parse: (data) => ({ severity: 32, count: data }),
	})
	info: AlertSeverityCount;

	@EntityField({
		data: ['LowSevAlerts', 'Low'],
		parse: (data) => ({ severity: 1, count: data }),
	})
	low: AlertSeverityCount;

	@EntityField({
		data: ['MediumSevAlerts', 'Medium'],
		parse: (data) => ({ severity: 2, count: data }),
	})
	medium: AlertSeverityCount;

	@EntityField({
		data: ['HighSevAlerts', 'High'],
		parse: (data) => ({ severity: 4, count: data }),
	})
	high: AlertSeverityCount;

	@EntityField({ data: 'IncidentCount' })
	incidentsCount?: number;

	get alertsCount(): number {
		return sumBy(this.severitiesWithCount, ({ count }) => count);
	}

	get hasAlerts(): boolean {
		return this.severitiesWithCount.length !== 0;
	}

	get severitiesWithCount(): Array<AlertSeverityCount> {
		return [this.high, this.medium, this.low, this.info].filter((severityCount) => severityCount.count);
	}
}
