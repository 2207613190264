import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { FileInstance } from '../file/file-instance.entity';
import { CyberEventAdditionalFields } from '../event/cyber-event-additional-fields.value-object';


//force tests to run

@Entity({
	singularName: 'Process',
	pluralName: 'Processes',
	endpoint: `files`,
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
		return `files?searchBy=sha1&searchTerm=${params['fileHash']}`;
	},
	//TODO GET files
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	readonly: true,
})
export class Process extends EntityModelBase<number | string> {
	@EntityField({ data: ['ProcessId', 'Id', 'id'] })
		// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({ data: ['ProcessId', 'Id'] })
		// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	processId: number;

	// TODO: remove "Process" prefix
	@EntityField({ data: ['ProcessCreationTime', 'CreationTime'] })
	creationTime: Date;

	// TODO: remove "Process" prefix
	@EntityField({ data: ['ProcessCommandLine', 'Commandline', 'CommandLine'] })
	commandLine: string;

	// TODO: deprecated
	@EntityField({ data: 'ProcessAccount' })
	account: string;

	@EntityField({ data: ['Account', 'User'] })
	user: LegacyUser;

	@EntityField({ data: 'ImageFile', parse(file, rawData) {
			file && (file["deviceName"] = rawData.deviceName);
			file && (file["deviceId"] = rawData.deviceId);
			return file;
		},
	 })
	file: FileInstance;

	// TODO: use enum, remove "Process" prefix
	@EntityField({ data: ['ProcessIntegrityLevel', 'IntegrityLevel'] })
	integrityLevel: string;

	// TODO: remove "Process" prefix
	@EntityField({ data: ['ProcessTokenElevation', 'TokenElevation'] })
	tokenElevation: string | number;

	@EntityField({ data: 'PowershellDecodedCommand' })
	decodedCommandLine?: string;

	@EntityField({ data: 'CreatingProcessId' })
	creatingProcessId?: string;

	@EntityField({ data: 'CreatingProcessName' })
	creatingProcessName?: string;

	@EntityField({ data: 'name' })
	processName?: string;

	@EntityField({
		data: 'PowershellCommands',
		parse: commands => (Array.isArray(commands) ? commands.join('\n') : commands),
	})
	executedCommands?: string;

	get name(): string {
		return this.processName || (this.file ? this.file.name : this.account);
	}

	@EntityField({ data: 'AdditionalFields' })
	additionalFields?: CyberEventAdditionalFields;

	get fileId(): string {
		return this.file && this.file.id;
	}

	@EntityField({ data: 'MergeByKey' })
	readonly uniqueEntityId?: string;

	@EntityField({ data: 'deviceName' })
	readonly deviceName?: string;

	@EntityField({ data: 'deviceId' })
	readonly deviceId?: string;
}
