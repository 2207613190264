import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { BaselineProfileDetails, ProfileComplianceStatus } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ExternalProfileActions } from '../services/profile.entity-type.service';
import { AjaxError } from 'rxjs/ajax';

@Component({
	selector: 'profile-entity-panel',
	styleUrls: ['./profile.entity-panel.component.scss'],
	templateUrl: './profile.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileEntityPanelComponent extends EntityPanelComponentBase<BaselineProfileDetails> {

	readonly SpinnerSize = SpinnerSize;
	isLoading = true;
	noComplianceStatus = false;
	profilesComplianceStatus: ProfileComplianceStatus[];
	isEnabled: boolean;
	isSaving: boolean = false;

	get profile(): BaselineProfileDetails {
		return this.entity;
	}

	constructor(
		private readonly paris: Paris,
		changeDetectorRef: ChangeDetectorRef,
		public i18nService: I18nService
	) {
		super(changeDetectorRef);
	}

	initEntity(profile: BaselineProfileDetails, isExtendedData: boolean = false) {
		super.initEntity(profile, isExtendedData);
		this.isEnabled = this.profile.status;
		this.setProfilesComplianceStatus();
		this.changeDetectorRef.markForCheck();
	}

	private setProfilesComplianceStatus() {
		this.paris
			.getRepository(ProfileComplianceStatus).getItemById(this.profile.id)
			.subscribe(status => {
				this.profilesComplianceStatus = [status];
				this.isLoading = false;
				this.changeDetectorRef.detectChanges();
			},
			(e: AjaxError) => {
				this.isLoading = false;
				this.noComplianceStatus = true;
				this.changeDetectorRef.detectChanges();
			});
	}

	updateStatus() {
		this.isSaving = true;
		super.runEntityAction(ExternalProfileActions.UPDATE_STATUS_ACTION);
	}
}
