import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { ActionPostData, RemediationActionsBackendService } from './remediation-actions.backend.service';
import { Observable } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { QuarantineFilesRemediationDeclineModalComponent } from '../components/quarantine-files-remediation-decline.modal';
import {
	AirsEntityType,
	AirsEntityTypeConfig,
	AntivirusRemediationStatus,
	EntityApprovalApiCall,
	RemediationAction,
	RemediationActionType,
	RemediationActionTypeTypes,
} from '@wcd/domain';
import { take, tap } from 'rxjs/operators';
import { RemediationDeclineModalComponent } from '../components/remediation-decline.modal';
import { RemediationRejectData } from './remediation-reject-reason.interface';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { AddFilesToAllowlistModal } from '../components/add-files-to-allowlist.modal';
import { FabricIconNames } from '@wcd/scc-common';
import { Paris } from '@microsoft/paris';

type False = false;
type True = true;

@Injectable()
export class RemediationActionsService {
	constructor(
		protected backendService: RemediationActionsBackendService,
		private dialogsService: DialogsService,
		protected i18nService: I18nService,
		private paris: Paris
	) {}

	private approveDeclineTypes = {
		[RemediationActionTypeTypes.file_quarantine]: {
			decline: this.declineFileQuarantine.bind(this),
		},
		[RemediationActionTypeTypes.pending_resource]: {
			approve: (actions: Array<PendingActionBase>) => {
				const actionIds = actions.reduce((_actionIds, action) => {
					return _actionIds.concat(action.actionIds || [action.id]);
				}, []);

				return this.retryPendingActions(actionIds)
					.toPromise()
					.then(data => ({ confirmed: true, data: data }));
			},
		},
	} as const;

	private approveDeclineEntityTypes = {
		[AirsEntityType.File]: {
			decline: this.declineFileQuarantineByEvidence.bind(this),
		},
	} as const;

	private declineFileQuarantine(actions: Array<RemediationAction>, sha1: string) {
		return new Promise(async (resolve, reject) => {
			const modal = await this.dialogsService
				.showModal(
					QuarantineFilesRemediationDeclineModalComponent,
					{
						id: 'approve-decline-file-modal',
						title:
							actions.length === 1
								? this.i18nService.strings
										.remediation_declineModal_fileQuarantine_decline_singular
								: this.i18nService.get(
										'remediation_declineModal_fileQuarantine_decline_plural',
										{
											fileCount: actions.length,
										}
								  ),
					},
					{
						inputs: actions,
						dismissPending: async (
							_actions: Array<PendingActionBase>,
							rejectData: RemediationRejectData
						) => {
							try {
								await this.dismissPendingActions(_actions, rejectData).toPromise();
								modal.destroy();
								const _modal = await this.dialogsService
									.showModal(
										AddFilesToAllowlistModal,
										{
											id: 'add-file-to-allowlist-modal',
											title: this.i18nService.strings.common_success,
											titleIcon: FabricIconNames.Accept,
										},
										{
											message: this.i18nService.get(
												'remediationActions_addToWhitelist',
												{ fileCount: _actions.length }
											),
											sha1: sha1,
											hashes: actions && actions.map(a => a.file && a.file.sha1),
											onDone: () => {
												resolve({ confirmed: true, data: null });
												_modal.destroy();
											},
										}
									)
									.pipe(take(1))
									.toPromise();
								_modal.instance.cancel.subscribe(() => {
									resolve({ confirmed: true, data: null });
									_modal.destroy();
								});
							} catch (e) {
								reject(e);
							}
						},
					}
				)
				.pipe(take(1))
				.toPromise();
			modal.instance.cancel.subscribe(() => {
				resolve({ confirmed: false });
				modal.destroy();
			});
		});
	}

	private declineFileQuarantineByEvidence(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>,
		sha1: string
	) {
		return new Promise(async (resolve, reject) => {
			const modal = await this.dialogsService
				.showModal(
					QuarantineFilesRemediationDeclineModalComponent,
					{
						id: 'approve-decline-file-modal',
						title:
							entities.length === 1
								? this.i18nService.strings
										.remediation_declineModal_fileQuarantine_decline_singular
								: this.i18nService.get(
										'remediation_declineModal_fileQuarantine_decline_plural',
										{
											fileCount: entities.length,
										}
								  ),
					},
					{
						inputs: entities,
						dismissPending: async (
							_entities: Array<{
								mergeByKey: string;
								alertId: string;
								type: AirsEntityTypeConfig;
							}>,
							rejectData: RemediationRejectData
						) => {
							try {
								await this.dismissPendingEntities(_entities, rejectData).toPromise();
								modal.destroy();
								if (!sha1) {
									resolve({ confirmed: true, data: null });
									return;
								}
								const _modal = await this.dialogsService
									.showModal(
										AddFilesToAllowlistModal,
										{
											id: 'add-file-to-allowlist-modal',
											title: this.i18nService.strings.common_success,
											titleIcon: FabricIconNames.Accept,
										},
										{
											message: this.i18nService.get(
												'remediationActions_addToWhitelist',
												{ fileCount: _entities.length }
											),
											sha1: sha1,
											hashes: [],
											onDone: () => {
												resolve({ confirmed: true, data: null });
												_modal.destroy();
											},
										}
									)
									.pipe(take(1))
									.toPromise();
								_modal.instance.cancel.subscribe(() => {
									resolve({ confirmed: true, data: null });
									_modal.destroy();
								});
							} catch (e) {
								reject(e);
							}
						},
					}
				)
				.pipe(take(1))
				.toPromise();
			modal.instance.cancel.subscribe(() => {
				resolve({ confirmed: false });
				modal.destroy();
			});
		});
	}

	private getApproveMethod(actions: Array<PendingActionBase>) {
		const actionTypes: Array<RemediationActionType> = Array.from(
			new Set(actions.map(a => a.remediationActionType))
		);
		return (
			actionTypes.length === 1 &&
			this.approveDeclineTypes[actionTypes[0].type] &&
			this.approveDeclineTypes[actionTypes[0].type].approve
		);
	}

	private getDeclineMethod(actions: Array<PendingActionBase>) {
		const actionTypes: Array<RemediationActionType> = Array.from(
			new Set(actions.map(a => a.remediationActionType))
		);
		return (
			actionTypes.length === 1 &&
			this.approveDeclineTypes[actionTypes[0].type] &&
			this.approveDeclineTypes[actionTypes[0].type].decline
		);
	}

	private getEvidenceApproveMethod(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>
	) {
		const entityTypes = Array.from(new Set(entities.map(a => a.type)));
		return (
			entityTypes.length === 1 &&
			this.approveDeclineEntityTypes[entityTypes[0].id] &&
			this.approveDeclineEntityTypes[entityTypes[0].id].approve
		);
	}

	private getEvidenceDeclineMethod(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>
	) {
		const entityTypes = Array.from(new Set(entities.map(a => a.type)));
		return (
			entityTypes.length === 1 &&
			this.approveDeclineEntityTypes[entityTypes[0].id] &&
			this.approveDeclineEntityTypes[entityTypes[0].id].decline
		);
	}

	private getActionDataFromActions(actions: Array<PendingActionBase>): Array<ActionPostData> {
		return actions
			? actions.reduce(
					(_actionData, action: PendingActionBase) =>
						_actionData.concat(
							(action.actionIds ? action.actionIds : [action.id]).map(id => ({
								action_id: id,
								investigation_id: action.investigationId,
								action_type: action.remediationActionType,
							}))
						),
					[]
			  )
			: null;
	}

	dismissPendingActions(
		actions: Array<PendingActionBase>,
		declineData: RemediationRejectData
	): Observable<any> {
		const actionData = this.getActionDataFromActions(actions);

		return this.backendService.dismissActions(actionData, declineData.reason.id, declineData.comment);
	}

	private dismissPendingEntities(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>,
		declineData: RemediationRejectData
	): Observable<void> {
		return this.paris.apiCall(EntityApprovalApiCall, {
			entityData: entities,
			status: 'declined',
			declineReason: declineData.reason.id,
			comment: declineData.comment,
		});
	}

	/**
	 * Dismisses pending RemediationActions on the server, for a specific RemediationActionType
	 * @param remediationActionType {RemediationActionType}
	 * @param actions {Array<PendingActionBase>}
	 * @param data {Object} JSON to send to server when declining
	 * @param allowCustomDecline {boolean} whether to allow using the decline method of the remediation action type
	 * @returns {*}
	 */
	confirmAndDismissPendingActionsByType(
		actions: Array<PendingActionBase>,
		data: any,
		allowCustomDecline: False
	): Promise<any>;
	confirmAndDismissPendingActionsByType(
		actions: Array<RemediationAction>,
		data?: any,
		allowCustomDecline?: True
	): Promise<any>;
	confirmAndDismissPendingActionsByType(
		actions: Array<RemediationAction | PendingActionBase>,
		data?: any,
		allowCustomDecline: boolean = true
	): Promise<any> {
		const onError = error => {
			if (error) {
				this.dialogsService.showError({
					title: this.i18nService.strings[
						actions.length > 1
							? 'investigation_error_FailedDeclinePendingActions'
							: 'investigation_error_FailedDeclinePendingAction'
					],
					data: error,
				});
			}
		};
		if (allowCustomDecline !== false) {
			const declineMethod: (
				actions: Array<RemediationAction>,
				data: any
			) => Promise<any> = this.getDeclineMethod(actions);
			if (declineMethod) {
				return declineMethod(actions as Array<RemediationAction>, data).catch(onError);
			}
		}
		let actionType: RemediationActionType = actions[0].remediationActionType;
		if (actions.some(a => a.remediationActionType !== actionType)) {
			actionType = null;
		}
		return this.dialogsService.confirm({
			componentType: RemediationDeclineModalComponent,
			inputs: {
				title:
					actionType && actionType.dismissText
						? actionType.dismissText(actions)
						: this.i18nService.get('remediation_declineModal_declineAction', {
								actionCount: actions.length,
						  }),
			},
			onConfirm: (payload: RemediationRejectData) => {
				return this.dismissPendingActions(actions, payload)
					.toPromise()
					.catch(onError);
			},
		});
	}

	confirmAndDismissPendingActionsByEntities(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>,
		data?: any
	): Promise<any> {
		const onError = error => {
			this.dialogsService.showError({
				title: this.i18nService.strings[
					entities.length > 1
						? 'investigation_error_FailedDeclinePendingActions'
						: 'investigation_error_FailedDeclinePendingAction'
				],
				data: error,
			});
		};
		const declineMethod = this.getEvidenceDeclineMethod(entities);

		if (declineMethod) {
			return declineMethod(entities, data).catch(onError);
		}

		return this.dialogsService.confirm({
			componentType: RemediationDeclineModalComponent,
			inputs: {
				title: this.i18nService.get('remediation_declineModal_declineAction', {
					actionCount: entities.length,
				}),
			},
			onConfirm: async (payload: RemediationRejectData) => {
				try {
					await this.dismissPendingEntities(entities, payload).toPromise();
				} catch (e) {
					onError(e);
				}
			},
		});
	}

	/**
	 * Approves pending RemediationActions on the server
	 * @returns {*}
	 */
	approvePendingActions(
		actions: Array<PendingActionBase>,
		showNotification: boolean = true
	): Promise<ConfirmEvent> {
		const approveMethod = this.getApproveMethod(actions);

		if (approveMethod) {
			return approveMethod(actions).then(onApprove.bind(this), error => {
				onError.call(this, error);
				return Promise.reject(error);
			});
		}

		const actionData = this.getActionDataFromActions(actions);

		return this.backendService
			.approveActions(actionData)
			.pipe(
				tap(onApprove.bind(this), error => {
					onError.call(this, error);
				})
			)
			.toPromise()
			.then(data => ({ confirmed: true, data: data }));

		function onApprove() {
			if (showNotification) {
				this.showNotificationOnApprove(actions);
			}
		}

		function onError(error) {
			if (error) {
				this.dialogsService.showError({
					title: this.i18nService.strings[
						actions.length > 1
							? 'investigation_error_FailedApprovePendingActions'
							: 'investigation_error_FailedApprovePendingAction'
					],
					data: error,
				});
			}
		}
	}

	async approvePendingEntities(
		entities: Array<{ mergeByKey: string; alertId: string; type: AirsEntityTypeConfig }>
	): Promise<ConfirmEvent> {
		const approveMethod = this.getEvidenceApproveMethod(entities);

		try {
			if (approveMethod) {
				await approveMethod(entities);
			} else {
				await this.paris
					.apiCall(EntityApprovalApiCall, {
						entityData: entities,
						status: 'approved',
					})
					.toPromise();
			}
			this.dialogsService.showSuccessSnackbar({
				text: this.i18nService.get('remediation_pendingActions_approved_generic', {
					itemCount: entities.length,
				}),
			});
			return { confirmed: true, data: null };
		} catch (e) {
			this.dialogsService.showError({
				title: this.i18nService.strings[
					entities.length > 1
						? 'investigation_error_FailedApprovePendingActions'
						: 'investigation_error_FailedApprovePendingAction'
				],
				data: e,
			});
			throw e;
		}
	}

	retryPendingActions(actionIds?: Array<number>): Observable<any> {
		return this.backendService.retryPendingActions(actionIds);
	}

	showNotificationOnApprove(remediationActions: Array<PendingActionBase>): void {
		const actionsType: RemediationActionType = remediationActions[0].remediationActionType;
		const isMultipleRemediationActionTypes =
			remediationActions.length > 1 &&
			remediationActions.some(a => a.remediationActionType !== actionsType);

		let text: string;
		if (isMultipleRemediationActionTypes) {
			text = this.i18nService.get('remediation_pendingActions_approved_generic', {
				itemCount: remediationActions.length,
			});
		} else {
			text = this.i18nService.get(
				remediationActions.length === 1
					? 'remediation_pendingActions_approved_specific_singular'
					: 'remediation_pendingActions_approved_specific_plural',
				{
					actionName: actionsType.name,
				}
			);
			text = text.replace('remediation remediation', 'remediation');
		}

		this.dialogsService.showSuccessSnackbar({
			text: text,
		});
	}

	static getSuccessOrFailureTextI18nKey(
		remediationActionType: RemediationActionType,
		entityCount: number,
		isFailed: boolean,
		avRemediationStatus: AntivirusRemediationStatus,
		isRemediated: boolean
	): string {
		// this text is relevant if the entity was remediated by av or if the entity is not remediated at all
		if (avRemediationStatus && (!isRemediated || avRemediationStatus.isRemediated)) {
			return `remediationActions.avRemediationStatus.${avRemediationStatus.type}`;
		}
		return `remediationActions.actions.${remediationActionType.type}.${isFailed ? 'failure' : 'success'}${
			entityCount === 1 ? 'Singular' : 'Plural'
		}`;
	}
}

export interface PendingActionBase {
	actionIds?: Array<number>;
	id: number | string;
	investigationId: number | string;
	remediationActionType: RemediationActionType;
}
