import { SevilleModule } from '../../../../../app/seville/seville.module';
import { AppInsightsService } from '../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.general', settingsGeneralController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('preferences.general', {
			url: '/general',
			title: 'Data retention',
			parent: 'preferences',
			views: {
				SettingsView: {
					template: `
						<div class="settings-view-page">
                <div class="settings-page-title">
                    This section provides general settings that were previously defined as part of the onboarding process.
                </div>
                <div ng-if="$parent.loadingCurrentPreferences">
                    <img class="settings-page-loading img-responsive" src="/assets/images/linear-loader.gif" />
                </div>
                <div ng-if="!$parent.loadingCurrentPreferences && !$parent.loadingCurrentPreferencesFailed">
                    <h3 class="wcd-padding-bottom" colspan="2">Data storage</h3>
                    <table>
                        <tr>
                            <td class="icon icon-lg icon-Warning onboarding-warning-padding color-text-error"></td>
                            <td>
                                This option cannot be changed without completely offboarding from Microsoft Defender for Endpoint and completing a new enrollment process.
                                <br />
                                For more information, see the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733763&amp;clcid=0x409" target="_blank">Data storage and privacy</a>
                                section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                            </td>
                        </tr>
                    </table>
					<div ng-class="{'disabled': (general.isreadonlyuser)}">
					    <div class="onboarding-radio-block">
                            <label class="onboarding-radio onboarding-radio-disabled">
                                <input type="radio" name="geo" value="Canary" ng-model="general.geo" disabled />
                                US (Internal)
                            </label>
                        </div>
                        <div class="onboarding-radio-block">
                            <label class="onboarding-radio onboarding-radio-disabled">
                                <input type="radio" name="geo" value="Us" ng-model="general.geo" disabled />
                                US
                            </label>
                        </div>
                        <div class="onboarding-radio-block">
                            <label class="onboarding-radio onboarding-radio-disabled">
                                <input type="radio" name="geo" value="Europe" ng-model="general.geo" disabled />
                                Europe
                            </label>
                        </div>
                        <div class="onboarding-radio-block">
                            <label class="onboarding-radio onboarding-radio-disabled">
                                <input type="radio" name="geo" value="Uk" ng-model="general.geo" disabled />
                                UK
                            </label>
                        </div>
                    </div>
                    <hr />
                    <h3 class="wcd-padding-bottom">Data retention</h3>
                    <table class="onboarding-dropdown-margin-top">
                        <tr>
                            <td colspan="3">
                                This will determine the period of time we retain your data in your cloud instance.
                                <br />
                                Note this does not refer to expiration or cancellation of your Microsoft Defender for Endpoint contract.
                                <br />
                                For more information, see the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733763&amp;clcid=0x409" target="_blank">Data storage and privacy</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                            </td>
                        </tr>
                    </table>
                        <div ng-class="{'onboarding-dropdown-custom':!general.isreadonlyuser, 'onboarding-dropdown-custom-disabled':general.isreadonlyuser}" class="btn-group onboarding-dropdown-margin-top" style="text-align: right" uib-dropdown>
                            <button ng-class="{'onboarding-dropdown':!general.isreadonlyuser, 'onboarding-dropdown-disabled':general.isreadonlyuser}" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle ng-disabled="general.isreadonlyuser">
                                {{general.retentionPolicy}}
                                <span tabindex="0" class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                            </button>
                            <ul ng-class="{'onboarding-dropdown-custom':!isreadonlyuser, 'onboarding-dropdown-custom-disabled':general.isreadonlyuser}" class="dropdown-menu" role="menu">
                                <li tabindex="0" ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'30 days'"><a href="#">30 days</a></li>
                                <li ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'60 days'"><a href="#">60 days</a></li>
                                <li ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'90 days'"><a href="#">90 days</a></li>
                                <li ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'120 days'"><a href="#">120 days</a></li>
                                <li ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'150 days'"><a href="#">150 days</a></li>
                                <li ng-disabled="general.isreadonlyuser" ng-model="general.retentionPolicy" uib-btn-radio="'180 days'"><a href="#">180 days</a></li>
                            </ul>
                        </div>
                        <hr />
                        <div>
                            <form ng-submit="general.save()">
                                <button ng-disabled="$parent.$parent.applyingPreferencesChange || !general.preferencesChanged"
                                        class="settings-button ng-class:{'settings-button-enabled':!$parent.$parent.applyingPreferencesChange && general.preferencesChanged, 'settings-button-disabled settings-disabled':$parent.$parent.applyingPreferencesChange || !general.preferencesChanged}">
                                    Save preferences
                                </button>
                            </form>
                            <span ng-if="$parent.$parent.applyingPreferencesChange">
                                <img class="onboarding-inline-block settings-toggle-applying img-responsive" src="/assets/images/linear-loader.gif" />
                            </span>
                        </div>
                    </div>
                </div>
                <div class="ng-class:{'settings-error-message': $parent.savePreferencesFailed || $parent.loadingCurrentPreferencesFailed};">
                    {{$parent.messageLabel}}
                </div>
						`,
					controller: 'seville.settings.general',
					controllerAs: 'general',
				},
			},
		});
	},
]);

settingsGeneralController.$inject = ['$scope', '$http', 'authenticationService', 'appInsights'];

function settingsGeneralController($scope, $http, authenticationService, appInsights: AppInsightsService) {
	var vm = this;

	var retentionPolicyDays = {
		'30 days': 30,
		'60 days': 60,
		'90 days': 90,
		'120 days': 120,
		'150 days': 150,
		'180 days': 180,
	};

	vm.preferencesChanged = false;
	vm.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.systemSettings]);
	vm.industry1model = [];
	vm.industry2model = [];
	vm.industry3model = [];
	vm.industry4model = [];
	vm.industry5model = [];

	vm.industryExtraSettings = {
		buttonClasses: vm.isreadonlyuser ? 'onboarding-dropdown-disabled' : 'onboarding-dropdown',
		dynamicTitle: true,
		showCheckAll: false,
		showUncheckAll: false,
		scrollable: false,
		smartButtonMaxItems: 1,
		displayProp: 'label',
		idProp: 'id',
	};

	function checkPreferencesChanged() {
		$scope.$parent.resetMessageLabel();

		if ($scope.originalData === undefined) {
			vm.preferencesChanged = false;
			return;
		}

		vm.preferencesChanged = !($scope.originalData.RetentionPolicy === vm.retentionPolicy);
	}

	$scope.$watch(
		function() {
			return (
				vm.retentionPolicy +
				vm.industry1model +
				vm.industry2model +
				vm.industry3model +
				vm.industry4model +
				vm.industry5model
			);
		},
		function() {
			checkPreferencesChanged();
		}
	);

	function saveSettings() {
		$scope.$parent.savePreferencesReset();
		if (vm.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}

		vm.settings = {
			GeoRegion: vm.geo,
			RetentionPolicy: retentionPolicyDays[vm.retentionPolicy],
		};

		$http
			.post($scope.$parent.settingsBaseUrl + '/SaveTenantPreferences', vm.settings, {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'SaveTenantPreferences',
							Page: 'SettingsPreferences',
							ResponseStatus: response.status,
						});

						$scope.originalData = {
							RetentionPolicy: vm.retentionPolicy,
						};
						checkPreferencesChanged();
						$scope.$parent.showSavePreferencesSuccess();
					} else {
						console.log('status: ' + response.status);
						$scope.$parent.showSavePreferencesError();
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'SaveTenantPreferencesError',
							Page: 'SettingsPreferences',
							ResponseStatus: response.status,
							FailureType: 'API',
						});
					}
				},
				function(response) {
					console.log('status: ' + response.status);
					$scope.$parent.showSavePreferencesError();
					appInsights.trackEvent('ButtonClick', {
						ButtonType: 'SaveTenantPreferencesRequestFailure',
						Page: 'SettingsPreferences',
						ResponseStatus: response.status,
						FailureType: 'Request',
					});
				}
			);
	}

	function getCurrentSettings() {
		$http
			.get($scope.$parent.settingsBaseUrl + '/GetUserSettings', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data === null) {
							console.log('response data null');
							$scope.$parent.showGetPreferencesError();
						}

						vm.geo = response.data.GeoRegion;
						vm.retentionPolicy = response.data.RetentionPolicy.toString() + ' days';

						$scope.originalData = {
							RetentionPolicy: vm.retentionPolicy,
						};
						$scope.$parent.applyGetPreferencesSuccess();
					} else {
						console.log('GetUserSettings failed. status:' + response.status);
						$scope.$parent.showGetPreferencesError();
					}
				},
				function(response) {
					console.log('GetUserSettings call failed');
					$scope.$parent.showGetPreferencesError();
				}
			);
	}

	vm.save = function() {
		if (vm.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}
		saveSettings();
	};

	getCurrentSettings();
}
