import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Active directory domain',
	pluralName: 'Active directory domains',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsActiveDirectoryDomain,
})
export class AirsActiveDirectoryDomain extends AirsEntity {
	@EntityField({ data: 'active_directory_domain_name' })
	activeDirectoryDomainName: string;
}
