import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.preferences.industry', onboardingIndustryController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.industry', {
			url: '/industry',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page">
                <div class="onboarding-body-title">
                    <span>Set up your preferences</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position onboarding-industry">
                        <div>
                            <div class="page-header-title onboarding-title-margin">Select your industry</div>
                            <div>
                                Select the industry that best describes your organization. You can select more than one option. <br/>
                                This helps us identify the threat intelligence that is most relevant for your organization and industry.
                            </div>
                        </div>
                        <br />
                        <div>
                            <div class="onboarding-dropdown-multiselect">
                                <div class="onboarding-industry-block">
                                    <span class="onboarding-industry-block">Communication and media</span>
                                    <span class="onboarding-dropdown-margin" ng-dropdown-multiselect="1" selected-model="industry.industry1model" options="industry.industry1data" extra-settings="industry.IndustryExtraSettings" events="industry.industry1Events"></span>
                                </div>
                                <div class="onboarding-industry-block">
                                    <span class="onboarding-industry-block">Financial services</span>
                                    <span class="onboarding-dropdown-margin" ng-dropdown-multiselect="1" selected-model=" industry.industry2model" options="industry.industry2data" extra-settings="industry.IndustryExtraSettings" events="industry.industry2Events"></span>
                                </div>
                                <div class="onboarding-industry-block">
                                    <span class="onboarding-industry-block">Manufacturing and resources</span>
                                    <span class="onboarding-dropdown-margin" ng-dropdown-multiselect="1" selected-model=" industry.industry3model" options="industry.industry3data" extra-settings="industry.IndustryExtraSettings" events="industry.industry3Events"></span>
                                </div>
                                <div class="onboarding-industry-block">
                                    <span class="onboarding-industry-block">Public sector</span>
                                    <span class="onboarding-dropdown-margin" ng-dropdown-multiselect="1" selected-model="industry.industry4model" options="industry.industry4data" extra-settings="industry.IndustryExtraSettings" events="industry.industry4Events"></span>
                                </div>
                                <div class="onboarding-industry-block">
                                    <span class="onboarding-industry-block">Retail consumer products and services</span>
                                    <span class="onboarding-dropdown-margin" ng-dropdown-multiselect="1" selected-model="industry.industry5model" options="industry.industry5data" extra-settings="industry.IndustryExtraSettings" events="industry.industry5Events"></span>
                                </div>
                            </div>
                            </div>
                        </div>
                </div>
                <div class="onboarding-navigation">
                    <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-icon onboarding-paging-icon-back-margin" ng-click="industry.MoveBack()"></i>
                    Back
                </div>
                <div class="onboarding-navigation onboarding-paging-icon-margin">
                    Next
                    <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin" ng-click="industry.MoveNext()"></i>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.preferences.industry',
					controllerAs: 'industry',
				},
			},
		});
	},
]);

onboardingIndustryController.$inject = [
	'$scope',
	'$state',
	'$http',
	'$timeout',
	'$interval',
	'authenticationService',
	'$window',
	'$location',
	'$anchorScroll',
	'$rootScope',
	'$uibModal',
];

function onboardingIndustryController(
	$scope,
	$state,
	$http,
	$timeout,
	$interval,
	authenticationService,
	$window,
	$location,
	$anchorScroll,
	$rootScope,
	$modal
) {
	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
			$scope.$parent.stepPreferencesInProgress = true;
		});

		$location.hash('');
		$anchorScroll();

		vm.industry1model = $scope.$parent.CommunicationsAndMedia;
		vm.industry2model = $scope.$parent.FinancialServices;
		vm.industry3model = $scope.$parent.ManufacturingAndResources;
		vm.industry4model = $scope.$parent.PublicSector;
		vm.industry5model = $scope.$parent.RetailConsumerProductsAndService;
		vm.industry1data = [{ label: 'Media and cable' }, { label: 'Telecommunications' }];
		vm.industry2data = [{ label: 'Banking and capital markets' }, { label: 'Insurance' }];
		vm.industry3data = [
			{ label: 'Discrete manufacturing' },
			{ label: 'Power and utilities' },
			{ label: 'Process management and resources' },
		];
		vm.industry4data = [
			{ label: 'Education' },
			{ label: 'Government' },
			{ label: 'Health' },
			{ label: 'Public safety / National security' },
		];
		vm.industry5data = [
			{ label: 'Agriculture, forestry, and fishing' },
			{ label: 'Hospitality and travel' },
			{ label: 'Logistics' },
			{ label: 'Nonprofit' },
			{ label: 'Other or unsegmented' },
			{ label: 'Professional services' },
			{ label: 'Retail and consumer goods' },
		];

		vm.IndustryExtraSettings = {
			buttonDefaultText: 'Choose',
			defaultText: 'Choose',
			buttonClasses: 'onboarding-dropdown',
			dynamicTitle: true,
			showCheckAll: false,
			showUncheckAll: false,
			scrollable: false,
			smartButtonMaxItems: 1,
			displayProp: 'label',
			idProp: 'label',
		};

		var storeIndustrySettings = function() {
			$scope.$parent.CommunicationsAndMedia = vm.industry1model;
			$scope.$parent.FinancialServices = vm.industry2model;
			$scope.$parent.ManufacturingAndResources = vm.industry3model;
			$scope.$parent.PublicSector = vm.industry4model;
			$scope.$parent.RetailConsumerProductsAndService = vm.industry5model;
		};

		vm.MoveBack = function() {
			storeIndustrySettings();
			$state.go('onboarding.orgsize');
		};
		vm.MoveNext = function() {
			storeIndustrySettings();
			$state.go('onboarding.optinpreview');
		};
	}
}
