import { Injectable } from '@angular/core';
import { MainSearchEntityTypesService, SearchEntityType } from './main-search-entity-types.service';
import { Router, UrlHandlingStrategy, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { Observable, Subject } from 'rxjs';
import { RoutesService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';

export interface Search {
	term: string;
	type: SearchEntityType;
}

@Injectable()
export class SearchService {
	private _search$: Subject<Readonly<Search>>;
	search$: Observable<Readonly<Search>>;

	constructor(
		private urlHandlingStrategy: UrlHandlingStrategy,
		private location: Location,
		private router: Router,
		private readonly mainSearchEntityTypeService: MainSearchEntityTypesService,
		private routesService: RoutesService
	) {
		this._search$ = new Subject();
		this.search$ = this._search$.asObservable();
	}

	/*
	 * determines search entity type from a given search term (using regex)
	 */
	resolveType(searchTerm: string): SearchEntityType {
		return this.mainSearchEntityTypeService.all.find((type: SearchEntityType) => {
			return !!(type.resolveTypeFromSearchTerm && type.resolveTypeFromSearchTerm(searchTerm));
		});
	}

	onSearch(searchTerm: string, searchType: SearchEntityType) {
		const searchParams = searchType.getSearchParams(searchTerm);
		const urlTree = this.router.parseUrl(searchParams.url);
		this._search$.next({ term: searchTerm, type: searchType });

		if (sccHostService.isSCC) {
			const urlInContext = this.routesService.getMdatpFromSccUrl(searchParams.url);
			this.router.navigate([urlInContext], { queryParams: { search: searchParams.filter } });
		} else {
			if (this.urlHandlingStrategy.shouldProcessUrl(urlTree)) {
				this.router.navigate([searchParams.url], { queryParams: { search: searchParams.filter } });
			} else {
				this.location.go(
					searchParams.url,
					searchParams.filter ? `search=${searchParams.filter}` : ''
				);
			}
		}
	}
}
