import { NgModule } from '@angular/core';
import { TagsBackendService } from './services/tags.backend.service';
import { TagsStore } from './services/tags.store';
import { TagsEditComponent } from './components/tags-edit/tags-edit.component';
import { TagComponent } from './components/tag/tag.component';
import { TagsListComponent } from './components/tags-list/tags-list.component';
import { TagsFieldComponent } from './components/tags-field/tags-field.component';
import { SharedModule } from '../shared/shared.module';
import { TagsService } from './services/tags.service';
import { OverflowTagsList } from './components/tags-list/overflow-tags-list.component';

@NgModule({
	imports: [SharedModule],
	declarations: [TagsEditComponent, TagsListComponent, TagComponent, TagsFieldComponent, OverflowTagsList],
	exports: [TagsEditComponent, TagsListComponent, TagComponent, TagsFieldComponent, OverflowTagsList],
	providers: [TagsBackendService, TagsStore, TagsService],
	entryComponents: [TagsEditComponent, TagsListComponent, TagComponent, TagsFieldComponent, OverflowTagsList],
})
export class TagsModule {}
