import { EntityField, ValueObject } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import { HuntingQuerySchemaField } from '../query/hunting-query-schema.value-object';

@ValueObject({
	singularName: 'Hunting table column schema',
	pluralName: 'Hunting table columns schemas',
	readonly: true,
})
export class HuntingTableColumnSchema extends HuntingQuerySchemaField {
	@EntityField({
		data: 'Description',
		parse: value => (isNil(value) ? undefined : value),
	})
	readonly description?: string;
}
