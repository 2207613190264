import { ChangeDetectorRef, EventEmitter, OnDestroy, OnInit, } from '@angular/core';
import { getClientTimeZoneString, LocaleConfigService, TzDateService } from '@wcd/localization';
import { inRange, merge, padStart } from 'lodash-es';
import { FabTextFieldComponent } from '@angular-react/fabric';
import { wasChanged } from '@wcd/angular-extensions';
import { DateTimeDisplayService } from '../services/date-time-display.service';
import { I18nService } from '@wcd/i18n';
var sharedSpinButtonStyles = {
    root: { minWidth: 'inherit' },
    labelWrapper: { height: '100%' },
    labelWrapperStart: { marginRight: 0 },
    spinButtonWrapper: { minWidth: 'inherit' },
    input: { width: '40px', minWidth: '40px', maxWidth: '40px' },
};
var mainSpinButtonStyles = merge(sharedSpinButtonStyles, {
    icon: { paddingRight: '2px', paddingLeft: 0 },
});
var secondarySpinButtonStyles = merge(sharedSpinButtonStyles, {
    root: { display: 'flex', alignItems: 'center' },
    label: { margin: '0 2px' },
});
var DateTimePickerComponent = /** @class */ (function () {
    function DateTimePickerComponent(changeDetectorRef, localeConfigService, dateTimeDisplayService, tzDateService, i18nService) {
        var _this = this;
        this.changeDetectorRef = changeDetectorRef;
        this.localeConfigService = localeConfigService;
        this.dateTimeDisplayService = dateTimeDisplayService;
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.hourSpinButtonStyles = mainSpinButtonStyles;
        this.minuteSpinButtonStyles = secondarySpinButtonStyles;
        this.secondSpinButtonStyles = secondarySpinButtonStyles;
        this.dialogShown = false;
        this.allowTimeSelection = false;
        this.showGoToToday = true;
        this.isMonthPickerVisible = false;
        this.autoFocusOpen = true;
        this.valueChange = new EventEmitter();
        this.dialogShownChange = new EventEmitter();
        this._prevSelectedDate = null;
        this.setTimezoneLabel();
        this.setCalendarStrings();
        this._timezoneChangeSubscription = this.localeConfigService.config$.subscribe(function () {
            _this.setTimezoneLabel();
            _this.setCalendarStrings();
            _this.changeDetectorRef.markForCheck();
        });
    }
    Object.defineProperty(DateTimePickerComponent.prototype, "inputHtmlEl", {
        get: function () {
            if (!this._inputHtmlEl) {
                this._inputHtmlEl = this.inputEl.elementRef.nativeElement.querySelector('input');
            }
            return this._inputHtmlEl;
        },
        enumerable: true,
        configurable: true
    });
    DateTimePickerComponent.prototype.ngOnInit = function () {
        this.value = this.value || new Date();
        this.hours = this.getHours();
        this.minutes = this.getMinutes();
        this.seconds = this.getSeconds();
    };
    DateTimePickerComponent.prototype.ngOnChanges = function (changes) {
        if (wasChanged(changes.value)) {
            this.setDisplayValue(changes.value.currentValue);
        }
    };
    DateTimePickerComponent.prototype.ngOnDestroy = function () {
        if (this._timezoneChangeSubscription) {
            this._timezoneChangeSubscription.unsubscribe();
        }
    };
    DateTimePickerComponent.prototype.onInputFocused = function () {
        this.showDialog();
    };
    DateTimePickerComponent.prototype.showDialog = function () {
        if (this._prevSelectedDate) {
            this.hours = this._prevSelectedDate.getHours();
            this.minutes = this._prevSelectedDate.getMinutes();
            this.seconds = this._prevSelectedDate.getSeconds();
        }
        this.dialogShown = true;
        this.changeDetectorRef.detectChanges();
        this.dialogShownChange.emit(this.dialogShown);
    };
    DateTimePickerComponent.prototype.hideDialog = function () {
        var nativeInputEl = this.inputHtmlEl;
        nativeInputEl.selectionStart = 0;
        nativeInputEl.selectionEnd = 0;
        nativeInputEl.focus();
        this.dialogShown = false;
        this.changeDetectorRef.detectChanges();
        this.dialogShownChange.emit(this.dialogShown);
    };
    DateTimePickerComponent.prototype.revertAndDismiss = function () {
        this.value = this._prevSelectedDate;
        this.hideDialog();
    };
    DateTimePickerComponent.prototype.confirmAndDismiss = function () {
        var selectedDateTime = new Date(this.value.valueOf());
        if (this.allowTimeSelection) {
            selectedDateTime.setHours(this.hours, this.minutes, this.seconds);
        }
        else {
            selectedDateTime.setHours(0, 0, 0);
        }
        var displayDate = this.localeConfigService.isLocalTimeZone
            ? selectedDateTime
            : new Date(Date.UTC(selectedDateTime.getFullYear(), selectedDateTime.getMonth(), selectedDateTime.getDate(), selectedDateTime.getHours(), selectedDateTime.getMinutes(), selectedDateTime.getSeconds()));
        this.setDisplayValue(displayDate);
        this.changeDetectorRef.markForCheck();
        this._prevSelectedDate = selectedDateTime;
        this.valueChange.emit(displayDate);
        this.hideDialog();
    };
    DateTimePickerComponent.prototype.padValue = function (value) {
        return padStart(value.toString(), 2, '0');
    };
    DateTimePickerComponent.prototype.onIncrement = function (kind) {
        var _this = this;
        var maxValue = kind === 'hour' ? 23 : 59;
        return function (value) {
            var parsedValue = +value;
            var newValue = parsedValue >= maxValue ? parsedValue : parsedValue + 1;
            _this.updateValue(kind, newValue);
            return _this.padValue(newValue);
        };
    };
    DateTimePickerComponent.prototype.onDecrement = function (kind) {
        var _this = this;
        return function (value) {
            var parsedValue = +value;
            var newValue = parsedValue <= 0 ? parsedValue : parsedValue - 1;
            _this.updateValue(kind, newValue);
            return _this.padValue(newValue);
        };
    };
    DateTimePickerComponent.prototype.onValidate = function (kind) {
        var _this = this;
        var maxValue = kind === 'hour' ? 24 : 60;
        return function (value) {
            var parsedValue = +value;
            var newValue = inRange(parsedValue, 0, maxValue)
                ? parsedValue
                : parsedValue >= maxValue
                    ? maxValue - 1
                    : 0;
            _this.updateValue(kind, newValue);
            return _this.padValue(newValue);
        };
    };
    DateTimePickerComponent.prototype.updateValue = function (kind, value) {
        if (kind === 'hour')
            this.hours = value;
        if (kind === 'minute')
            this.minutes = value;
        if (kind === 'second')
            this.seconds = value;
    };
    DateTimePickerComponent.prototype.setTimezoneLabel = function () {
        this.timezoneLabel = this.localeConfigService.isLocalTimeZone
            ? this.i18nService.get('datepicker_timezoneLocalUTC', { clientTimeZone: getClientTimeZoneString() })
            : this.i18nService.get('datepicker_timezoneUTC');
    };
    DateTimePickerComponent.prototype.setDisplayValue = function (date) {
        var format = this.allowTimeSelection ? 'short' : 'shortDate';
        this.displayValue = this.tzDateService.format(date, format);
    };
    DateTimePickerComponent.prototype.setCalendarStrings = function () {
        this.calendarStrings = this.dateTimeDisplayService.getDateStrings(this.localeConfigService.selectedLocale);
    };
    // If UTC timezone is selected, we're showing the user what they expects, while keeping the real date in
    // local timezone, and parse it again before save.
    // If the user manually changes value, we're showing them the value they inserted and we're setting it
    // to the `value` member.
    DateTimePickerComponent.prototype.getHours = function () {
        return this.localeConfigService.isLocalTimeZone ? this.value.getHours() : this.value.getUTCHours();
    };
    DateTimePickerComponent.prototype.getMinutes = function () {
        return this.localeConfigService.isLocalTimeZone
            ? this.value.getMinutes()
            : this.value.getUTCMinutes();
    };
    DateTimePickerComponent.prototype.getSeconds = function () {
        return this.localeConfigService.isLocalTimeZone
            ? this.value.getSeconds()
            : this.value.getUTCSeconds();
    };
    return DateTimePickerComponent;
}());
export { DateTimePickerComponent };
