import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../app/seville/seville.module';
import { AppInsightsService } from '../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.nonwindows', settingsNonWindowsController);

settingsNonWindowsController.$inject = [
	'$scope',
	'$http',
	'appConfig',
	'authenticationService',
	'appInsights',
	'seville.settings.clientsideutils',
	'$interval',
	'$stateParams',
];

const NON_WINDOWS_PAGE_NAME = 'Preferences\\NonWindows';

function settingsNonWindowsController(
	$scope,
	$http,
	appConfig: AppConfigService,
	authenticationService,
	appInsights: AppInsightsService,
	clientSideUtils,
	$interval,
	$stateParams
) {
	var vm = this;
	vm.type = $stateParams.type.toLowerCase();
	vm.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);
	vm.settingsLoading = true;
	vm.loadStatusFailed = false;
	vm.partners = [];

	vm.partnersInformation = {
		Bitdefender: {
			partnerName: 'Bitdefender',
			instructions:
				'To verify proper integration, create an EICAR test file by saving the string below to an empty text file and introduce the test file to a device running the third-party antivirus solution. The file should trigger a detection and a corresponding alert on Microsoft Defender for Endpoint.',
			command: 'X5O!P%@AP[4\\PZX54(P^)7CC)7}$EICAR-STANDARD-ANTIVIRUS-TEST-FILE!$H+H*',
		},
		Ziften: {
			partnerName: 'Ziften',
			instructions:
				'On the macOS or Linux device, run the following command in a new terminal session.',
			command:
				'echo dGVzdD0vYmluL3NsZWVwO2NwIC9iaW4vc2xlZXAgL3RtcC9tYWxpY2lvdXNhdHRhY2tlcjsvdG1wL21hbGljaW91c2F0dGFja2VyIDU7cm0gL3RtcC9tYWxpY2lvdXNhdHRhY2tlciAK | base64 --decode | bash',
		},
		SentinelOne: {
			partnerName: 'SentinelOne',
			instructions:
				'To verify proper integration, create an EICAR test file by saving the string below to an empty text file and introduce the test file to a device running the third-party antivirus solution. The file should trigger a detection and a corresponding alert on Microsoft Defender for Endpoint.',
			command: 'X5O!P%@AP[4\\PZX54(P^)7CC)7}$EICAR-STANDARD-ANTIVIRUS-TEST-FILE!$H+H*',
		},
		Lookout: {
			partnerName: 'Lookout',
			instructions:
				'To verify proper integration, create an EICAR test file by saving the string below to an empty text file and introduce the test file to a device running the third-party antivirus solution. The file should trigger a detection and a corresponding alert on Microsoft Defender for Endpoint.',
			command: 'X5O!P%@AP[4\\PZX54(P^)7CC)7}$EICAR-STANDARD-ANTIVIRUS-TEST-FILE!$H+H*',
		},
	};

	vm.copyToClipboardDetectionTestCssClass = 'icon-Copy';

	vm.errorMessage = '';

	vm.uiMessages = {
		getSettingsFailed: 'Unable to load page.',
	};

	vm.copyToClipboard = function() {
		appInsights.trackEvent('ButtonClick', {
			Name: 'CopyGeneratedTokenButton',
			Page: NON_WINDOWS_PAGE_NAME,
		});
		clientSideUtils.copyToClipboard(
			'NonWindows',
			'settings.nonwindows.detectionTestString',
			setCopyToClipboardIcon
		);
	};

	function setCopyToClipboardIcon(id, icon) {
		vm.copyToClipboardDetectionTestCssClass = icon;
	}

	vm.getPartner = function(partnerName) {
		return vm.partners.find(function(partner) {
			return partner.partnerName == partnerName;
		});
	};

	function setGetSettingsFailure(msg?, responseStatus?, failureType?) {
		if (!msg && !responseStatus && !failureType) {
			vm.loadStatusFailed = false;
			vm.errorMessage = '';
		} else {
			console.log(msg);

			var eventData: any = { Name: 'GetPartnersSettings', Page: NON_WINDOWS_PAGE_NAME };
			if (responseStatus) {
				eventData.ResponseStatus = responseStatus;
			}
			if (failureType) {
				eventData.FailureType = failureType;
			}
			appInsights.trackEvent('AjaxCalls', eventData);

			vm.loadStatusFailed = true;
			vm.errorMessage = vm.uiMessages.getSettingsFailed;
		}
	}

	function tryParseSettingsResponse(responseData) {
		if (!responseData) {
			return false;
		}

		vm.partnersSettings = [];
		responseData.forEach(function(partner) {
			var setting = {
				partnerName: partner.PartnerName,
				isEnabledOrig: partner.IsEnabled,
			};

			vm.partnersSettings.push(setting);
		});

		return true;
	}

	var getNonWindowsSettings = function() {
		vm.settingsLoading = true;

		$http
			.get(`${appConfig.serviceUrls.management}/GetPartnersSettings`, {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data === null) {
							setGetSettingsFailure('response data is null', response.status, 'null');
							appInsights.trackEvent('AjaxCalls', {
								Name: 'GetPartnersSettings',
								Page: 'Preferences\\Advanced',
								ResponseStatus: response.status,
								FailureType: 'null',
							});
							return;
						}

						if (tryParseSettingsResponse(response.data)) {
							vm.partnersSettings.forEach(function(element) {
								element.isEnabled = element.isEnabledOrig;
							});

							setGetSettingsFailure(false);
						} else {
							setGetSettingsFailure(
								'failed to parse response data',
								response.status,
								'parsing error'
							);
							appInsights.trackEvent('AjaxCalls', {
								Name: 'GetPartnersSettings',
								Page: NON_WINDOWS_PAGE_NAME,
								ResponseStatus: response.status,
								FailureType: 'parsing',
							});
							return;
						}
					} else {
						setGetSettingsFailure('status: ' + response.status, response.status, 'API');
						appInsights.trackEvent('AjaxCalls', {
							Name: 'GetPartnersSettings',
							Page: NON_WINDOWS_PAGE_NAME,
							ResponseStatus: response.status,
							FailureType: 'API',
						});
					}
				},
				function(response) {
					setGetSettingsFailure('status: ' + response.status, response.status, 'Request');
				}
			)
			.then(
				function() {
					filterNotSupportedPartners();
				},
				function() {
					vm.settingsLoading = false;
				}
			);
	};

	var filterNotSupportedPartners = function() {
		if (!vm.partnersSettings) {
			vm.partnersSettings = [];
		}

		$http
			.get(appConfig.serviceUrls.userRequests + '/partners/supportedpartners', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status == 200 && response.data && Array.isArray(response.data)) {
						// Once supproted partners were retrived, add them to the filter
						var filteredPartners = [];
						if (response.data.length > 0) {
							response.data.forEach(function(partnerInfo) {
								var partner = vm.partnersSettings.find(function(partner) {
									return partner.partnerName == partnerInfo.PartnerName;
								});

								if (!partner) {
									partner = {
										partnerName: partnerInfo.PartnerName,
										IsEnabled: false,
										isEnabledOrig: false,
									};
								}

								filteredPartners.push(partner);
							});

							vm.partners = filteredPartners;
							return;
						}
					} else {
						setGetSettingsFailure(
							'Error occur while loading supported partners, Status: ' + response.status,
							response.status
						);
						appInsights.trackEvent('AjaxCalls', {
							Name: 'filterNotSupportedPartners',
							Page: NON_WINDOWS_PAGE_NAME,
							ResponseStatus: response.status,
							FailureType: 'API',
						});
					}
				},
				function(response) {
					setGetSettingsFailure('Error occur while loading supported partners');
				}
			)
			.then(
				function() {
					vm.settingsLoading = false;
				},
				function() {
					vm.settingsLoading = false;
				}
			);
	};

	getNonWindowsSettings();
}
