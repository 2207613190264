
		<dataview
			*ngIf="entityType && options"
			[id]="id || entityType.pluralName + '_list'"
			[className]="className"
			[defaultSortFieldId]="options.defaultSortFieldId"
			[repository]="repository"
			[entityType]="dataViewEntityType"
			[fields]="fields"
			[dataViewConfig]="dataViewConfig"
			[disableFilterPanelAutoFocus]="disableFilterPanelAutoFocus"
			[fixedOptions]="fixedOptions"
			[disabledFields]="options?.disabledFields"
			[getFilterQueryOptions]="options?.getFilterQueryOptions"
			(onData)="dataChanged.emit($event)"
			(onNewItem)="newItemAdded.emit($event)"
			(groupExpand)="groupExpanded.emit($event)"
			[label]="label"
			[focusOnTable]="focusOnTable"
			[queueHeader]="queueHeader"
			[padLeft]="padLeft"
			[commandBarNoPadding]="commandBarNoPadding"
			(afterTableInit)="!options?.dateRangeOptions ? afterTableInit($event) : null"
			(onTableRenderComplete)="onDataTableRenderComplete()"
			[tabIndex]="options?.dateRangeOptions && !isFirstInitDone ? null : -1"
			[focusOnFirstMenuItem]="!options?.dateRangeOptions"
			[responsiveActionBar]="responsiveActionBar"
			[responsiveLayout]="responsiveLayout"
			[customActionsRight]="commandBarRight"
			[removePadding]="removePadding"
			[removePaddingRight]="removePaddingRight"
			[customActionsLeft]="customActionsLeft ? customActionsLeft : []"
			[entitySidePanelSettings]="entitySidePanelSettings"
		>
			<ng-container
				*ngIf="!responsiveActionBar && options.dateRangeOptions as dateRangeOptions"
				dataview-controls
			>
				<fancy-select
					[(ngModel)]="currentRange"
					(ngModelChange)="onRangeTypeSelect()"
					class="command-bar-item-dropdown"
					buttonIcon="calendar"
					[label]="'name'"
					[values]="dateRangeOptions.supportedRanges"
					[ariaLabel]="i18nService.strings.dataview_timePeriod"
					[focusOnInit]="!isFirstInitDone"
					(focus)="afterFirstElementFocused()"
				></fancy-select>
			</ng-container>
			<ng-container dataview-header>
				<ng-content select="[dataview-header]"></ng-content>
			</ng-container>
		</dataview>
	