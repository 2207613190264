/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../global_entities/components/entity-details/user-entity-details.component.ngfactory";
import * as i2 from "../../../global_entities/components/entity-details/user-entity-details.component";
import * as i3 from "../../../admin/integration-settings/advanced-features.service";
import * as i4 from "@microsoft/paris/dist/lib/paris";
import * as i5 from "./user-entity-panel.component";
var styles_UserEntityPanelComponent = [];
var RenderType_UserEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserEntityPanelComponent, data: {} });
export { RenderType_UserEntityPanelComponent as RenderType_UserEntityPanelComponent };
export function View_UserEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-margin-vertical wcd-margin-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "user-entity-details", [], null, null, null, i1.View_UserEntityDetailsComponent_0, i1.RenderType_UserEntityDetailsComponent)), i0.ɵdid(3, 114688, null, 0, i2.UserEntityDetailsComponent, [i0.Injector, i0.ChangeDetectorRef, i3.AdvancedFeaturesService, i4.Paris], { entity: [0, "entity"], mode: [1, "mode"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.user; var currVal_1 = _co.EntityDetailsMode.SidePane; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_UserEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-entity-panel", [], null, null, null, View_UserEntityPanelComponent_0, RenderType_UserEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i5.UserEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserEntityPanelComponentNgFactory = i0.ɵccf("user-entity-panel", i5.UserEntityPanelComponent, View_UserEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { UserEntityPanelComponentNgFactory as UserEntityPanelComponentNgFactory };
