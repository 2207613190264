export enum ExploitGuardInnerRuleName {
	credentialStealingFromLsass = 'credentialStealingFromLsass',
	executingEmailAttachment = 'executingEmailAttachment',
	executingPayLoad = 'executingPayLoad',
	folderGuard = 'folderGuard',
	launchingUnverifiedWindowsExecutable = 'launchingUnverifiedWindowsExecutable',
	officeCreatingChild = 'officeCreatingChild',
	officeCreatingExecutable = 'officeCreatingExecutable',
	officeInjection = 'officeInjection',
	processCreationFromWMI = 'processCreationFromWMI',
	runningObfuscatedCode = 'runningObfuscatedCode',
	runningUntrustedExecutable = 'runningUntrustedExecutable',
	untrustedProcessFromUSB = 'untrustedProcessFromUSB',
	windowsCallsFromOfficeMacro = 'windowsCallsFromOfficeMacro',
	persistenceThroughWmi = 'persistenceThroughWmi',
}
