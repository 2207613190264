var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { I18nService } from '@wcd/i18n';
import { TvmTextsService } from '../../../../tvm/services/tvm-texts.service';
import { FeaturesService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { SecurityRecommendationFieldsServiceBase } from './security-recommendation.fields.service.base';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { TzDateService } from '@wcd/localization';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TvmProductivityImpactService } from '../../../../tvm/services/tvm-productivity-impact.service';
import { AuthService } from '@wcd/auth';
import { PrettyNumberService } from '@wcd/prettify';
import { SecurityRecommendationService } from './security-recommendation.service';
import { AppConfigService } from '@wcd/app-config';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';
var SingleAssetSecurityRecommendationFieldsService = /** @class */ (function (_super) {
    __extends(SingleAssetSecurityRecommendationFieldsService, _super);
    function SingleAssetSecurityRecommendationFieldsService(tvmTaggedFieldService, tzDateService, i18nService, tvmTextsService, featuresService, paris, tvmIconBuilderService, reportInaccuracyService, tvmTagsService, authService, productivityImpactService, prettyNumberService, securityRecommendationService, appConfigService) {
        var _this = _super.call(this, tvmTaggedFieldService, tzDateService, i18nService, tvmTextsService, featuresService, paris, tvmIconBuilderService, reportInaccuracyService, tvmTagsService, authService, productivityImpactService, prettyNumberService, securityRecommendationService, appConfigService) || this;
        _this.singleAssetRecommendation = true;
        return _this;
    }
    return SingleAssetSecurityRecommendationFieldsService;
}(SecurityRecommendationFieldsServiceBase));
export { SingleAssetSecurityRecommendationFieldsService };
