import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { NetworkDevice, ResolveEntityURL, TvmEndPoint } from '@wcd/domain';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { NetworkDevicesFieldsService } from './network-devices.fields.service';
import { NetworkDevicesService } from './network-devices.service';
import { NetworkDeviceEntityPanelComponent } from '../components/network-device.entity-panel.components';
import { NetworkDeviceEntityDetailsComponent } from '../../../../global_entities/components/entity-details/network-device.entity-details.component';
import { NetworkDeviceEntityStatusComponent } from '../components/network-device-entity-status.component';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';

export interface NetworkDevicesDataViewFixedOptions {}

@Injectable()
export class NetworkDeviceEntityTypeService implements EntityTypeService<NetworkDevice> {
	constructor(
		private networkDevicesFieldsService: NetworkDevicesFieldsService,
		private networkDevicesService: NetworkDevicesService,
		private tvmDownloadService: TvmDownloadService
	) {}

	readonly entityType: EntityType<NetworkDevice> = {
		id: 'network-device',
		entity: NetworkDevice,
		entityDetailsComponentType: NetworkDeviceEntityDetailsComponent,
		entityPanelStatusComponentType: NetworkDeviceEntityStatusComponent,
		singleEntityPanelComponentType: NetworkDeviceEntityPanelComponent,
		getEntityName: (networkDevice: NetworkDevice) => networkDevice.ipAddress,
		dataViewOptions: <EntityDataViewOptions<NetworkDevice, NetworkDevicesDataViewFixedOptions>>{
			fields: this.networkDevicesFieldsService.fields,
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, format, searchTerm, dataQuery) => {
					const url = ResolveEntityURL({
						endPoint: TvmEndPoint.Analytics,
						entityModelBaseOrRelationship: NetworkDevice,
					});

					return this.tvmDownloadService.downloadCsv({
						url: url,
						fileName: 'export-tvm-network-device',
						dataQuery: dataQuery,
					});
				},
			},
			dataViewConfig: {
				getFiltersData: () => this.networkDevicesService.getFiltersData(),
			},
		},
	};
}
