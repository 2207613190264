/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i5 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i6 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i7 from "@angular/common";
import * as i8 from "./dataview-action-button.component";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_DataviewActionButtonComponent = [];
var RenderType_DataviewActionButtonComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DataviewActionButtonComponent, data: {} });
export { RenderType_DataviewActionButtonComponent as RenderType_DataviewActionButtonComponent };
function View_DataviewActionButtonComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["buttonEl", 1]], null, 8, "button", [["class", "hidden-action command-bar-item-button"]], [[2, "disabled", null], [8, "id", 0], [1, "data-track-id", 0], [1, "aria-label", 0], [1, "data-track-type", 0], [8, "disabled", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.onClickCallback() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(1, 147456, null, 0, i1.TooltipDirective, [i0.ElementRef, i2.TooltipsService, i3.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 0, "i", [["class", "loader-icon wcd-margin-small-right"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "wcd-shared-icon", [], [[8, "hidden", 0]], null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i0.ɵdid(6, 573440, null, 0, i5.IconComponent, [i6.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(8, null, ["\n\t\t\t", "\n\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.localizedTooltip; _ck(_v, 1, 0, currVal_6); var currVal_9 = _co.icon; _ck(_v, 6, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.disabled || _co.isBusy); var currVal_1 = i0.ɵinlineInterpolate(1, "", (_co.elementId + (_co.isInMoreMenu ? "-more" : "")), ""); var currVal_2 = _co.dataTrackId; var currVal_3 = _co.localizedTooltip; var currVal_4 = (_co.dataTrackType || "Button"); var currVal_5 = (_co.disabled || _co.isBusy); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = !_co.isBusy; _ck(_v, 3, 0, currVal_7); var currVal_8 = _co.isBusy; _ck(_v, 5, 0, currVal_8); var currVal_10 = (_co.smallScreenView ? "" : (_co.isBusy ? _co.localizedBusyLabel : _co.localizedLabel)); _ck(_v, 8, 0, currVal_10); }); }
export function View_DataviewActionButtonComponent_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { buttonEl: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DataviewActionButtonComponent_1)), i0.ɵdid(3, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldRender; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_DataviewActionButtonComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dataview-action-button", [], null, null, null, View_DataviewActionButtonComponent_0, RenderType_DataviewActionButtonComponent)), i0.ɵdid(1, 376832, null, 0, i8.DataviewActionButtonComponent, [i9.I18nService, i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataviewActionButtonComponentNgFactory = i0.ɵccf("dataview-action-button", i8.DataviewActionButtonComponent, View_DataviewActionButtonComponent_Host_0, { buttonActionConfig: "buttonActionConfig", smallScreenView: "smallScreenView", isInMoreMenu: "isInMoreMenu" }, {}, []);
export { DataviewActionButtonComponentNgFactory as DataviewActionButtonComponentNgFactory };
