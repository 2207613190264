import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Driver',
	pluralName: 'Drivers',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsDriver,
})
export class AirsDriver extends AirsEntity {
	@EntityField({ data: 'display_name' })
	displayName: string;

	@EntityField({ data: 'is_loaded' })
	isLoaded: boolean;

	@EntityField({ data: 'service_state' })
	serviceState: string;

	@EntityField({ data: 'driver_type' })
	driverType: string;

	@EntityField({ data: 'bin_path' })
	binPath: string;

	@EntityField({ data: 'start_type' })
	startType: string;

	constructor(data) {
		super(data);
		this.entityName = this.displayName;
	}
}
