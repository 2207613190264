import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum ContentState {
	Loading = 'Loading',
	Error = 'Error',
	Complete = 'Complete',
	Empty = 'Empty',
}

@Component({
	selector: 'wcd-contents-state',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container [ngSwitch]="contentState">
			<ng-content *ngSwitchCase="'Loading'" select="[loading]"></ng-content>
			<ng-content *ngSwitchCase="'Error'" select="[error]"></ng-content>
			<ng-content *ngSwitchCase="'Complete'" select="[complete]"></ng-content>
			<ng-content *ngSwitchCase="'Empty'" select="[empty]"></ng-content>
		</ng-container>
	`,
})
export class ContentsStateComponent {
	@Input() contentState: ContentState = ContentState.Loading;
}
