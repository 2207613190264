import { ApiCall, ApiCallModel, ParisConfig } from '@microsoft/paris';

@ApiCall({
	name: 'Update outbreak user state',
	endpoint: 'outbreaks/userState',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.threatAnalytics,
	method: 'PATCH',
})
export class PatchOutbreakUserStateAPICall extends ApiCallModel<
	void,
	Array<{ OutbreakId: string; LastVisitTime?: Date; IsFavorite?: boolean }>
> {}
