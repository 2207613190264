import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineExposureScore, MachineExposureScoreType } from './machine-exposure-score.models';
import { machineExposureScoreValues } from './machine-exposure-score.values';

@Entity({
	singularName: 'Device Exposure Score',
	pluralName: 'Device Exposure Scores',
	values: machineExposureScoreValues,
})
export class MachineExposureScore extends EntityModelBase<MachineExposureScoreType>
	implements IMachineExposureScore {
	@EntityField() name: string;

	@EntityField() className: string;

	@EntityField() icon?: string;

	@EntityField() priority: number;

	get type(): MachineExposureScoreType {
		return this.id;
	}
}
