<div *ngIf="comparisonResult" class="wcd-scroll-vertical wcd-margin-large-top">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
		<div class="tvm-list__key_val_item">
			<dt>{{ 'tvm.common.cveId' | i18n }}</dt>
			<dd>{{comparisonResult.cveId || ('notAvailable.short' | i18n)}}</dd>
		</div>
	</div>
	<ng-container *ngIf="contextOptions && !contextOptions.isSingleAsset">
		<exposed-machines-details [includeProductivityAssessment]="showProductivityImpactDescription"
			[assets$]="intersectionAssets$"
			[title]="'tvm.common.exposedIntersectionMachines' | i18n"
			[loadingText]="exposedAssetsLoadingText"
			[openByMachineNameNewTab]="openByMachineNameNewTab"
			[includeOperatingSystem]="includeOperatingSystem"
			[isShowMore]="isShowMore"
			(export)="exportExposedIntersectionMachines()">
		</exposed-machines-details>
		<exposed-machines-details [includeProductivityAssessment]="showProductivityImpactDescription"
			[assets$]="tvmAssets$"
			[title]="'tvm.common.exposedTvmMachines' | i18n"
			[loadingText]="exposedAssetsLoadingText"
			[openByMachineNameNewTab]="openByMachineNameNewTab"
			[includeOperatingSystem]="includeOperatingSystem"
			[isShowMore]="isShowMore"
			(export)="exportExposedTvmMachines()">
		</exposed-machines-details>
		<exposed-machines-details [includeProductivityAssessment]="showProductivityImpactDescription"
			[assets$]="competitorAssets$"
			[title]="'tvm.common.exposedCompetitorMachines' | i18n"
			[loadingText]="exposedAssetsLoadingText"
			[openByMachineNameNewTab]="openByMachineNameNewTab"
			[includeOperatingSystem]="includeOperatingSystem"
			[isShowMore]="isShowMore"
			(export)="exportExposedOtherMachines()">
		</exposed-machines-details>
	</ng-container>
</div>



