/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/charts/src/lib/bars/stretched-donut-bars.component.ngfactory";
import * as i4 from "../../../../../../../projects/charts/src/lib/bars/stretched-donut-bars.component";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./remediation-task.status-bar.component";
import * as i9 from "../../../shared/services/known-colors.service";
var styles_RemediationTaskStatusBarComponent = [];
var RenderType_RemediationTaskStatusBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RemediationTaskStatusBarComponent, data: {} });
export { RenderType_RemediationTaskStatusBarComponent as RenderType_RemediationTaskStatusBarComponent };
function View_RemediationTaskStatusBarComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-font-size-xs wcd-padding-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["contentClass", "ms-color-blue"], ["iconName", "SquareShapeSolid"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(4, null, ["\n\t\t", "\n\t"])), i0.ɵppd(5, 1)], function (_ck, _v) { var currVal_0 = "ms-color-blue"; var currVal_1 = "SquareShapeSolid"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent.parent, 0), "tvm_activeRemediation_completed")); _ck(_v, 4, 0, currVal_2); }); }
function View_RemediationTaskStatusBarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "wcd-stretched-donut-bars", [["barTitleCssClass", ""], ["class", "wcd-full-width"]], null, null, null, i3.View_StretchedDonutBarsComponent_0, i3.RenderType_StretchedDonutBarsComponent)), i0.ɵdid(4, 49152, null, 0, i4.StretchedDonutBarsComponent, [], { data: [0, "data"], barTitleCssClass: [1, "barTitleCssClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemediationTaskStatusBarComponent_2)), i0.ɵdid(8, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.strechedDonutChartItem; var currVal_1 = ""; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.showLegend; _ck(_v, 8, 0, currVal_2); }, null); }
function View_RemediationTaskStatusBarComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "div", [["class", "wcd-flex-1 wcd-padding-small-top wcd-padding-small-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t", "\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 1, 0, currVal_0); }); }
function View_RemediationTaskStatusBarComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemediationTaskStatusBarComponent_5)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitleIfNoBar; _ck(_v, 3, 0, currVal_0); }, null); }
function View_RemediationTaskStatusBarComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemediationTaskStatusBarComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.showHyphen; var currVal_1 = i0.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_RemediationTaskStatusBarComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(4, null, ["\n\t\t", "\n\t"])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inDataTable ? "wcd-flex-1 wcd-padding-small-top wcd-padding-small-bottom" : ""); _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "tvm_remediationTask_notTracked")); _ck(_v, 4, 0, currVal_1); }); }
export function View_RemediationTaskStatusBarComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_RemediationTaskStatusBarComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(0, [["noBar", 2]], null, 0, null, View_RemediationTaskStatusBarComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(0, [["showHyphenAsBar", 2]], null, 0, null, View_RemediationTaskStatusBarComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.currentValue != null) && !_co.showHyphen); var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_RemediationTaskStatusBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "remediation-status-bar", [], null, null, null, View_RemediationTaskStatusBarComponent_0, RenderType_RemediationTaskStatusBarComponent)), i0.ɵdid(1, 49152, null, 0, i8.RemediationTaskStatusBarComponent, [i9.KnownColorsService], null, null)], null, null); }
var RemediationTaskStatusBarComponentNgFactory = i0.ɵccf("remediation-status-bar", i8.RemediationTaskStatusBarComponent, View_RemediationTaskStatusBarComponent_Host_0, { width: "width", showLegend: "showLegend", showTitleIfNoBar: "showTitleIfNoBar", title: "title", valuePrefix: "valuePrefix", restPrefix: "restPrefix", total: "total", value: "value", showHyphen: "showHyphen", inDataTable: "inDataTable" }, {}, []);
export { RemediationTaskStatusBarComponentNgFactory as RemediationTaskStatusBarComponentNgFactory };
