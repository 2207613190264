import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService } from '@wcd/config';

@Injectable()
export class MachineGroupAwareFeatureGuard implements CanActivate {
	constructor(private appConfigService: AppConfigService, private featuresService: FeaturesService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const isMachineGroupAwareFeatureEnabled: boolean =
			!route.routeConfig.data ||
			!route.routeConfig.data.machineGroupAwareFeature ||
			this.featuresService.isEnabled(route.routeConfig.data.machineGroupAwareFeature);

		return this.appConfigService.isExposedToAllMachineGroups || isMachineGroupAwareFeatureEnabled;
	}
}
