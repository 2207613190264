import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Entity statistics',
	pluralName: 'Entity statistics',
})
export class EntityStatistics extends ModelBase {
	public cloudAppsPrevalence: number;

	@EntityField({ data: 'OrgPrevalence', parse: (fieldData) => parseInt(fieldData)} )
	readonly organizationPrevalence: number;

	@EntityField({ data: 'OrgFirstSeen' })
	readonly organizationFirstSeen: Date;

	@EntityField({ data: 'OrgLastSeen' })
	readonly organizationLastSeen: Date;

	@EntityField({ data: 'WorldwidePrevalence', parse: (fieldData) => parseInt(fieldData) })
	readonly worldwidePrevalence: number;

	@EntityField({ data: 'WorldwideFirstSeen' })
	readonly worldwideFirstSeen: Date;

	@EntityField({ data: 'WorldwideLastSeen' })
	readonly worldwideLastSeen: Date;

	@EntityField({ data: 'OrgLastSeenDeviceName' })
	readonly orgLastSeenDeviceName: string;

	@EntityField({ data: 'OrgLastSeenMachineId' })
	readonly orgLastSeenMachineId: string;

	@EntityField({ data: 'OrgFirstSeenDeviceName' })
	readonly orgFirstSeenDeviceName?: string;

	@EntityField({ data: 'OrgFirstSeenMachineId' })
	readonly orgFirstSeenMachineId?: string;
}
