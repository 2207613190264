import { IMachineVulnerabilitySeverityLevel, MachineVulnerabilitySeverityLevelType } from './machine-vulnerability-severity-level.models';

export const machineVulnerabilitySeverityLevelValues: Array<IMachineVulnerabilitySeverityLevel> = [
	{
		id: MachineVulnerabilitySeverityLevelType.None,
		name: 'No known risks',
		nameI18nKey: 'machineVulnerabilitySeverityLevel_None',
		priority: 5,
	},
	{
		id: MachineVulnerabilitySeverityLevelType.Low,
		name: 'Low',
		nameI18nKey: 'machineVulnerabilitySeverityLevel_Low',
		priority: 4,
	},
	{
		id: MachineVulnerabilitySeverityLevelType.Medium,
		name: 'Medium',
		nameI18nKey: 'machineVulnerabilitySeverityLevel_Medium',
		priority: 3,
	},
	{
		id: MachineVulnerabilitySeverityLevelType.High,
		name: 'High',
		nameI18nKey: 'machineVulnerabilitySeverityLevel_High',
		priority: 2,
	},
	{
		id: MachineVulnerabilitySeverityLevelType.Critical,
		name: 'Critical',
		nameI18nKey: 'machineVulnerabilitySeverityLevel_Critical',
		priority: 1,
	},
];
