<h2 class="wcd-font-weight-bold">
    <wcd-shared-icon class="large-icon ms-color-greenLight wcd-vertical-align-bottom wcd-margin-small-right"
        iconName="SkypeCircleCheck">
    </wcd-shared-icon>
    {{ data.isNewDraft ? ("tvm_authenticatedScan_wizard_new_completed_step_title" | i18n) : "tvm_authenticatedScan_wizard_update_completed_step_title" | i18n }}
</h2>

<div class="wcd-margin-large-top wcd-margin-mediumSmall-bottom">
    {{ data.isNewDraft ? ("tvm_authenticatedScan_wizard_create_completed_step_description" | i18n) : "tvm_authenticatedScan_wizard_update_completed_step_description" | i18n }}
</div>

<div class="wcd-margin-xLarge-top wcd-font-weight-semibold">
    {{ "tvm_authenticatedScan_wizard_completed_step_related_features" | i18n }}
</div>
<a href="#" (click)="navigateToDeviceInventory()">{{ "tvm_authenticatedScan_wizard_completed_step_device_inventory" | i18n }}</a>
