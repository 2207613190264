/*
 * Public API Surface of shared
 */
export * from './lib/services/wicd-sanitizer.service';
export * from '../../../packages/wcd-scc-common/shared';
export * from './lib/shared-routes';
export * from './lib/shared.module';
export * from './lib/services/known-colors.service';
export { KnownColorName, KnownColor } from './lib/models/known-colors.models';

export * from './lib/enums/flex-direction.enum';
export * from './lib/enums/logical-operator.enum';
export * from './lib/interfaces/preload.interface';
export * from './lib/services/browser-detection.service';
export { NavItemModel, NavItemConfig } from './lib/models/nav-item.model';
export { RoutesService, ERROR_PAGE_MAPPING } from './lib/services/routes.service';
export { splitToSentenceCase } from './lib/string-utils';
export { isUnifiedAlert } from './lib/shared-utils';
export * from './lib/services/cookies.service';
export { MessageBarAction, MessageBarActionExecutionDetails } from './lib/components/message-bar.component';
export * from './lib/services/local-storage.service';
export * from './lib/services/session-storage.service';
export * from './lib/services/a11y-announcer.service';
export * from './lib/services/wicd-sanitizer.service';
