import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationIncidents } from './evaluation-incidents.value-object';

@ApiCall({
	name: 'Evaluation incidents and alerts',
	endpoint: 'evaluation/report/IncidentsAlerts',
	method: 'GET',
	type: EvaluationIncidents,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetEvaluationIncidentsApiCall extends ApiCallModel<EvaluationIncidents> {}
