export let userAccountService = {
	isAllowedAccount: function(accountName, accountDomainName, isAllowedDisplayOfAccount) {
		if (!isAllowedDisplayOfAccount) {
			return false;
		}

		accountName = (accountName || '').toLowerCase();
		accountDomainName = (accountDomainName || '').toLowerCase();

		var isAllowedAccountName = true;
		var isAllowedAccountDomainname = true;
		var builtInAccountNames = [
			'SYSTEM',
			'LOCAL SERVICE',
			'NETWORK',
			'NETWORK SERVICE',
			'RDV GRAPHICS SERVICE',
			'tvsu_tmp',
			'root',
		];
		if (builtInAccountNames.some((value: string) => value.toLowerCase() === accountName)) {
			isAllowedAccountName = false;
		}

		var builtInDomainAccountNames = [
			'NT AUTHORITY',
			'NT-AUTORITÄT',
			'AUTORITE NT',
			'AUTORIDADE NT',
			'NT SERVICE',
			'SERVICE RÉSEAU',
			'NT VIRTUAL MACHINE',
			'Window Manager',
			'Font Driver Host',
		];
		if (builtInDomainAccountNames.some((value: string) => value.toLowerCase() === accountDomainName)) {
			isAllowedAccountDomainname = false;
		}

		var results = isAllowedAccountName && isAllowedAccountDomainname;
		return results;
	},

	convertAccountNameBySid: function(accountName, accountSid) {
		var buildInAccountSids = {
			'S-1-5-18': 'SYSTEM',
			'S-1-5-20': 'NETWORK SERVICE',
			'S-1-5-19': 'LOCAL SERVICE',
			'S-1-5-7': 'ANONYMOUS LOGON',
		};

		var returnedAccountName = buildInAccountSids[accountSid];
		if (returnedAccountName != null && returnedAccountName != accountName) {
			return returnedAccountName;
		}

		return accountName;
	},

	resolveUserName: function(accountName, accountDomainName) {
		if (accountName == null && accountDomainName == null) {
			return '';
		}

		if (accountDomainName == null) {
			return accountName;
		}

		return accountDomainName + '\\' + accountName;
	},
};
