/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/charts/src/lib/bars/stacked-bars-chart.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/charts/src/lib/bars/stacked-bars-chart.component";
import * as i3 from "@angular/common";
import * as i4 from "./daily-machine-reporting.widget";
import * as i5 from "../../../../../shared-reports/services/reports.service";
import * as i6 from "../../../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i7 from "../../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i8 from "../../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i9 from "../../../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i10 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_DailyMachineReportingWidget = [];
var RenderType_DailyMachineReportingWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_DailyMachineReportingWidget, data: {} });
export { RenderType_DailyMachineReportingWidget as RenderType_DailyMachineReportingWidget };
export function View_DailyMachineReportingWidget_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { machineChart: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "wcd-stacked-bars-chart", [], [[8, "hidden", 0]], null, null, i1.View_StackedBarsChartComponent_0, i1.RenderType_StackedBarsChartComponent)), i0.ɵdid(3, 770048, [[1, 4], ["machineChart", 4]], 0, i2.StackedBarsChartComponent, [i0.ElementRef], { settings: [0, "settings"], data: [1, "data"], change: [2, "change"] }, null), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵpid(131072, i3.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.settings; var currVal_2 = i0.ɵunv(_v, 3, 1, i0.ɵnov(_v, 4).transform(_co.widgetData$)); var currVal_3 = i0.ɵunv(_v, 3, 2, i0.ɵnov(_v, 5).transform(_co.localeConfigService.config$)); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 6).transform(_co.error$)); _ck(_v, 2, 0, currVal_0); }); }
export function View_DailyMachineReportingWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "daily-machine-reporting-widget", [], null, null, null, View_DailyMachineReportingWidget_0, RenderType_DailyMachineReportingWidget)), i0.ɵdid(1, 245760, null, 0, i4.DailyMachineReportingWidget, [i5.ReportsService, i6.ServiceUrlsService, i7.PrettyNumberService, i8.TzDateService, i9.LocaleConfigService, i10.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DailyMachineReportingWidgetNgFactory = i0.ɵccf("daily-machine-reporting-widget", i4.DailyMachineReportingWidget, View_DailyMachineReportingWidget_Host_0, {}, {}, []);
export { DailyMachineReportingWidgetNgFactory as DailyMachineReportingWidgetNgFactory };
