import { Injectable } from '@angular/core';
import { DataEntityType, DataSet, EntityModelBase, RelationshipRepository } from '@microsoft/paris';
import { AirsEntityStatus, EvidenceDetectionSource, EvidenceEntity } from '@wcd/domain';
import { Observable, Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { isEqual, sortBy } from 'lodash-es';
import { SerializedFilters } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';

export type EvidenceFields = keyof EvidenceEntity;
export const EVIDENCE_FIELD_IDS: Record<EvidenceFields, EvidenceFields> = Array.from(
	(<DataEntityType>EvidenceEntity).entityConfig.fields.keys()
).reduce((acc, key) => {
	acc[key] = key;
	return acc;
}, <any>{});
const MAX_FILTER_VALUES = 5;

@Injectable({
	providedIn: 'root',
})
export class EvidenceService {
	private evidenceMap: { [index: string]: Observable<DataSet<EvidenceEntity>> } = {};
	private destroy$ = new Subject<void>();

	constructor(private i18nService: I18nService, private featuresService: FeaturesService) {}

	getEvidence<T extends EntityModelBase<string | number>>(
		evidenceRepo: RelationshipRepository<T, EvidenceEntity>
	): Observable<DataSet<EvidenceEntity>> {
		const cachedKey = `${evidenceRepo.sourceEntityType.singularName}_${evidenceRepo.sourceItem.id}`;

		const isEvidenceApiV2Enabled = this.featuresService.isEnabled(Feature.EvidenceApiV2);
		const evidence$ =
			this.evidenceMap[cachedKey] ||
			new Observable<DataSet<EvidenceEntity>>((subscriber) =>
				evidenceRepo
					.query({
						where: Object.assign(
							{ EvidenceApiV2: isEvidenceApiV2Enabled },
							isEvidenceApiV2Enabled ? { pageSize: 5000 } : null
						), // TODO: LIRAN - pageSize: 5000, it's a temp solution until pagination support
					})
					// ignore completion, only care about unsubscriptions
					.subscribe(
						(v) => subscriber.next(v),
						(err) => subscriber.error(err)
					)
			).pipe(
				takeUntil(this.destroy$),
				finalize(() => {
					delete this.evidenceMap[cachedKey];
				}),
				shareReplay({ bufferSize: 1, refCount: true })
			);
		this.evidenceMap[cachedKey] = evidence$;
		return evidence$;
	}

	destroyEvidence() {
		this.destroy$.next();
	}

	sortByField(fieldId: EvidenceFields, evidence: Array<EvidenceEntity>): Array<EvidenceEntity> {
		switch (fieldId) {
			case EVIDENCE_FIELD_IDS.name:
				return evidence.sort((a, b) => {
					const aName = (a.name || '').toLowerCase();
					const bName = (b.name || '').toLowerCase();
					if (aName !== bName) {
						return aName > bName ? 1 : -1;
					}
					return a.entityType.id > b.entityType.id ? 1 : -1;
				});
			case EVIDENCE_FIELD_IDS.verdict:
			case EVIDENCE_FIELD_IDS.state:
				return evidence.sort((a, b) => {
					const aFieldId = a[fieldId] && a[fieldId].id;
					const bFieldId = b[fieldId] && b[fieldId].id;
					if (aFieldId !== bFieldId) {
						if (aFieldId == null) {
							return -1;
						}
						if (bFieldId == null) {
							return 1;
						}
						return aFieldId > bFieldId ? 1 : -1;
					}
					return a.firstSeen > b.firstSeen ? 1 : -1;
				});
			case EVIDENCE_FIELD_IDS.detectionContexts:
				return evidence.sort((a, b) => {
					if (!isEqual(a.detectionContexts, b.detectionContexts)) {
						const firstDetectionContextA =
							a.detectionContexts && a.detectionContexts.length && a.detectionContexts[0];
						const firstDetectionContextB =
							b.detectionContexts && b.detectionContexts.length && b.detectionContexts[0];

						if (!isEqual(firstDetectionContextA, firstDetectionContextB)) {
							if (!firstDetectionContextA) {
								return -1;
							}

							if (!firstDetectionContextB) {
								return 1;
							}

							const aDetectionName = this.getEvidenceDetectionContextDisplayName(
								firstDetectionContextA
							);
							const bDetectionName = this.getEvidenceDetectionContextDisplayName(
								firstDetectionContextB
							);
							if (aDetectionName.toLowerCase() !== bDetectionName.toLowerCase()) {
								return aDetectionName > bDetectionName ? 1 : -1;
							}

							if (
								firstDetectionContextA.detectionType.id !==
								firstDetectionContextB.detectionType.id
							) {
								return firstDetectionContextA.detectionType.id >
									firstDetectionContextB.detectionType.id
									? 1
									: -1;
							}

							if (
								firstDetectionContextA.alertSeverity &&
								firstDetectionContextB.alertSeverity &&
								firstDetectionContextA.alertSeverity.id !==
									firstDetectionContextB.alertSeverity.id
							) {
								return firstDetectionContextA.alertSeverity.id >
									firstDetectionContextB.alertSeverity.id
									? 1
									: -1;
							}

							if (
								firstDetectionContextA.actionStatus &&
								firstDetectionContextB.actionStatus &&
								firstDetectionContextA.actionStatus.id !==
									firstDetectionContextB.actionStatus.id
							) {
								return firstDetectionContextA.actionStatus.id >
									firstDetectionContextB.actionStatus.id
									? 1
									: -1;
							}
						}
						if (a.detectionContexts.length !== b.detectionContexts.length) {
							return a.detectionContexts.length > b.detectionContexts.length ? 1 : -1;
						}
					}
					return a.firstSeen > b.firstSeen ? 1 : -1;
				});
			case EVIDENCE_FIELD_IDS.assets:
				return evidence.sort((a, b) => {
					if (a.assets.length !== b.assets.length) {
						return a.assets.length > b.assets.length ? 1 : -1;
					}
					if (a.assets.length === 1) {
						const lowerNameA = a.assets[0].displayName.toLowerCase();
						const lowerNameB = b.assets[0].displayName.toLowerCase();
						if (lowerNameA !== lowerNameB) {
							return lowerNameA > lowerNameB ? 1 : -1;
						}
					}
					return a.firstSeen > b.firstSeen ? 1 : -1;
				});
			default:
				return sortBy(evidence, EVIDENCE_FIELD_IDS.firstSeen);
		}
	}

	filterEvidence(
		evidence: Array<EvidenceEntity>,
		key: EvidenceFields,
		values: Array<string>
	): Array<EvidenceEntity> {
		const lowerValues = values.map((v) => v.toLowerCase());
		switch (key) {
			case EVIDENCE_FIELD_IDS.verdict:
			case EVIDENCE_FIELD_IDS.state:
				return evidence.filter((e) =>
					values.includes(e[key] ? String((<AirsEntityStatus>e[key]).id) : '')
				);
			case EVIDENCE_FIELD_IDS.detectionContexts: {
				return evidence.filter((e) =>
					(e.detectionContexts || []).some((detectionContext) =>
						lowerValues.includes(
							this.getEvidenceDetectionContextDisplayName(detectionContext).toLowerCase()
						)
					)
				);
			}
			case EVIDENCE_FIELD_IDS.assets: {
				return evidence.filter((e) =>
					lowerValues.some((v) => {
						if (e.assets && e.assets.length) {
							return e.assets.map((a) => a.displayName.toLowerCase()).includes(v);
						}
						return v === '';
					})
				);
			}
			default:
				return evidence.filter((e) => values.includes(<string>e[key]));
		}
	}

	getFiltersData(
		evidence: DataSet<EvidenceEntity>,
		options: SerializedFilters
	): Record<
		string,
		{
			values: Array<{
				id: string;
				name: string;
				count: number;
			}>;
			count: number;
		}
	> {
		let filteredEvidence = evidence.items;
		Object.entries(options).forEach(([k, v]) => {
			if (EVIDENCE_FIELD_IDS[k]) {
				filteredEvidence = this.filterEvidence(filteredEvidence, <any>k, <Array<string>>v);
			}
		});

		const evidenceEntity = filteredEvidence.reduce((acc, ev) => {
			Object.keys(EVIDENCE_FIELD_IDS).forEach((k) => {
				let values = (acc[k] && acc[k].values) || [];
				let filterData: Array<{
					id: string;
					name: string;
				}>;

				switch (k) {
					case EVIDENCE_FIELD_IDS.name: {
						filterData = [
							{
								id: ev.name,
								name: ev.name,
							},
						];
						break;
					}
					case EVIDENCE_FIELD_IDS.verdict:
					case EVIDENCE_FIELD_IDS.state: {
						filterData = [
							{
								id: ev[k] ? String((<AirsEntityStatus>ev[k]).id) : '',
								name: ev[k] ? String((<AirsEntityStatus>ev[k]).name) : '',
							},
						];
						break;
					}
					case EVIDENCE_FIELD_IDS.detectionContexts: {
						const detectionNames =
							ev.detectionContexts &&
							new Set(
								ev.detectionContexts.map((detectionContext) =>
									this.getEvidenceDetectionContextDisplayName(detectionContext)
								)
							);

						filterData =
							detectionNames &&
							Array.from(detectionNames).map((name) => ({
								id: name,
								name: name,
							}));
						break;
					}
					case EVIDENCE_FIELD_IDS.assets: {
						filterData =
							ev.assets && ev.assets.length
								? Array.from(new Set(ev.assets.map((a) => a.displayName))).map((name) => ({
										id: name,
										name,
								  }))
								: [{ id: '', name: '' }];
						break;
					}
					default:
						return;
				}
				const existingValues = values.filter((v) =>
					Object.values(filterData)
						.map((f) => f.id)
						.includes(v.id)
				);
				if (existingValues.length) {
					existingValues.forEach((v) => {
						v.count++;
					});
				} else {
					values = values.concat(
						filterData.map((f) => ({ id: f.id, name: f.name, value: f.id, count: 1 }))
					);
				}
				acc[k] = {
					values,
					count: values.length,
				};
			});
			return acc;
		}, {} as Record<string, { values: Array<{ id: string; name: string; count: number }>; count: number }>);

		return Object.entries(evidenceEntity).reduce((acc, [k, val]) => {
			if (val.values.length > MAX_FILTER_VALUES) {
				acc[k] = {
					...val,
					values: sortBy(val.values, '-count').slice(0, MAX_FILTER_VALUES),
				};
			} else {
				acc[k] = val;
			}
			return acc;
		}, {});
	}

	getEvidenceDetectionContextDisplayName(detectionContext: EvidenceDetectionSource): string {
		if (!detectionContext) {
			return '';
		}
		const detectionNameFromI18nKey =
			detectionContext.detectionNameI18nKey &&
			this.i18nService.get(detectionContext.detectionNameI18nKey, null, true);
		return detectionNameFromI18nKey || detectionContext.detectionName || '';
	}
}
