import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'General settings',
	pluralName: 'General settings',
	endpoint: 'GetUserSettings',
	parseItemQuery: () => 'GetUserSettings',
	parseSaveQuery: () => 'SaveTenantPreferences',
	saveMethod: 'POST',
	readonly: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.settings,
})
export class TenantSettings extends EntityModelBase {
	@EntityField({ data: 'GeoRegion', defaultValue: 'Us' })
	geoRegion: string;

	@EntityField({ data: 'RetentionPolicy', defaultValue: 180 })
	retentionPolicy: number;
}
