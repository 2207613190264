<wcd-panel
    (close)="destroy()"
    [settings]="settings"
    class="wcd-full-height wcd-flex-vertical"
    data-track-component="related-exceptions-panel"
    data-track-component-type="Side Panel">
    <div class="wcd-full-height wcd-flex-vertical">
        <h2 class="wcd-padding-large-horizontal wcd-padding-large-vertical">{{'tvm.common.machineGroups' | i18n}}</h2>
        <dataview
            *ngIf="fields"
            style="min-height: 1px;"
            [repository]="repository"
            [fields]="fields"
            [dataViewConfig]="dataViewConfig"
            [navigateOnChange]="false"
            [allowPaging]="true"
            [allowFilters]="false"
            [searchEnabled]="false"
            [hideControls]="true"
            [sortDisabledFields]="sortDisabledFieldsIds"
            [ignoreQueryParams]="true"
            [fixedTable]="true"
            [allowColumnCustomization]="false"
            [disableSelection]="true"
            [padLeft]="true"
            [label]="'tvm.common.machineGroups' | i18n"
            (onItemClick)="openRelatedExceptionPanel($event.item)">
        </dataview>
    </div>
</wcd-panel>
