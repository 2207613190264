
		<modal
			(close)="destroy()"
			[settings]="settings"
			data-html2canvas-ignore
			data-track-component="FeedbackModal"
		>
			<div class="wcd-full-height wcd-flex-vertical">
				<div class="wcd-flex-none dialog-contents">
					<tabs
						[tabsData]="tabs"
						[currentTab]="currentTabId"
						(select)="selectTab($event.tab)"
					></tabs>
				</div>
				<div class="wcd-flex-1 wcd-scroll-vertical">
					<ng-container *ngIf="currentTabId === 'collect'; else openTicket">
						<wcd-feedback
							[isEmailOptional]="false"
							[includeCause]="true"
							submitI18nKey="researcherAssistance.submit"
							i18nType="researcherAssistance."
							[submitApiCall]="submitApiCall"
							(done)="onCollect($event)"
						></wcd-feedback>
					</ng-container>
					<ng-template #openTicket>
						<div class="dialog-contents">
							<markdown [data]="'researcherAssistance.done.text' | i18n"></markdown>
							<p class="wcd-margin-top">
								<strong>
									{{ 'researcherAssistance.done.id' | i18n }}:
									<span class="color-text-primary">{{ ticketId }}</span>
								</strong>
							</p>
							<div class="wcd-margin-large-top">
								<fab-primary-button
									data-track-id="OpenTicket"
									data-track-type="Button"
									target="_blank"
									href="https://go.microsoft.com/fwlink/?linkid=2044716"
								>
									{{ 'researcherAssistance.openTicket' | i18n }}
								</fab-primary-button>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</modal>
	