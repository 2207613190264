import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DataTableFieldValueComponent } from './components/datatable-field-value.component';
import { DataTableFieldValueCustomComponent } from './components/datatable-field-value-custom-component.component';
import { DataTableComponent } from './components/datatable.component';
import { TooltipsModule } from '@wcd/dialogs';
import { FabButtonModule, FabIconModule, FabSpinnerModule } from '@angular-react/fabric';
import { WcdIconsModule } from '@wcd/icons';
import { DatatableCheckboxComponent } from './components/datatable-checkbox.component';
import { EmptyFieldComponent } from './components/empty-field.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { WcdSharedModule } from '@wcd/shared';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { I18nModule } from '@wcd/i18n';

const exportedComponents = [DataTableComponent, DataTableFieldValueComponent];

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		FabIconModule,
		TooltipsModule,
		WcdIconsModule,
		DragDropModule,
		FabSpinnerModule,
		FabButtonModule,
		WcdSharedModule,
		AngularExtensionsModule,
		I18nModule
	],
	declarations: [...exportedComponents, DatatableCheckboxComponent, EmptyFieldComponent, DataTableFieldValueCustomComponent],
	exports: exportedComponents,
	entryComponents: [EmptyFieldComponent],
})
export class DataTableModule {}
