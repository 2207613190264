/**
 * Manages SCC mock server config:
 */
var SccMockService = /** @class */ (function () {
    function SccMockService(config, loginUser) {
        this.config = config;
        this.loginUser = loginUser;
        this.DEFAULT_MOCK_HOST = 'http://localhost:5001';
        this.TEST_MODE_TENANT_ID = '00000000-0000-0000-0000-000000000000';
        this._mockHost = this.getMockHost();
        this._isMockMode = this.checkIsMockMode();
    }
    Object.defineProperty(SccMockService.prototype, "mockHost", {
        get: function () {
            return this._mockHost || this.getMockHost();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccMockService.prototype, "isMockMode", {
        get: function () {
            return this._isMockMode || this.checkIsMockMode();
        },
        enumerable: true,
        configurable: true
    });
    SccMockService.prototype.getMockHost = function () {
        var urlParams = new URLSearchParams(window.location.search);
        var devHost = urlParams.get('devhost') || '';
        var devParts = devHost.split('wicd-mockHost:');
        if (devParts.length <= 1) {
            return this.DEFAULT_MOCK_HOST;
        }
        var mockHost = devParts[1].split(',')[0];
        return mockHost || this.DEFAULT_MOCK_HOST;
    };
    SccMockService.prototype.checkIsMockMode = function () {
        // If tenantId is empty we are in SCC test mode
        return (this.loginUser.tenantId === this.TEST_MODE_TENANT_ID &&
            !this.config.test('EnableMockService') &&
            !location.search.includes('DisableWicdMock'));
    };
    SccMockService.prototype.getMockHostServiceUrls = function (serviceUrls) {
        var _this = this;
        // In case of not nominated tenant - first TenantContext call does not return ServiceUrls
        if (!this.isMockMode || !serviceUrls) {
            return serviceUrls;
        }
        var ret = Object.keys(serviceUrls)
            .filter(function (key) { return serviceUrls[key]; })
            .reduce(function (acc, key) {
            acc[key] = serviceUrls[key].replace(_this.DEFAULT_MOCK_HOST, _this.mockHost);
            return acc;
        }, {});
        return ret;
    };
    return SccMockService;
}());
export { SccMockService };
