var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { MachinesService } from '../../../machines/services/machines.service';
import { UsersService } from '../../../users/services/users.service';
import { I18nService } from '@wcd/i18n';
import { Machine } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
var AlertContextWidgetComponent = /** @class */ (function (_super) {
    __extends(AlertContextWidgetComponent, _super);
    function AlertContextWidgetComponent(activatedEntity, globalEntityTypesService, machinesService, userService, i18nService, reportsService) {
        var _this = _super.call(this, reportsService) || this;
        _this.activatedEntity = activatedEntity;
        _this.machinesService = machinesService;
        _this.userService = userService;
        _this.i18nService = i18nService;
        _this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
        return _this;
    }
    Object.defineProperty(AlertContextWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'alert-context-widget',
                name: this.i18nService.get('alert.report.widgets.alertContext.title'),
                loadData: function () {
                    return _this.activatedEntity.currentEntity$;
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    AlertContextWidgetComponent.prototype.getUserLink = function (_a) {
        var user = _a.user, machine = _a.machine;
        if (!user) {
            return null;
        }
        var alertTime = machine && new Date(machine.lastEventTime || machine.lastSeen);
        return this.userService.getUserLink({
            accountName: user.name,
            accountDomainName: user.domain,
            sid: user.id,
            alertTime: alertTime,
        });
    };
    AlertContextWidgetComponent.prototype.getMachineLink = function (alert) {
        var alertTime = new Date(alert.machine.lastEventTime || alert.machine.lastSeen);
        return this.machinesService.getMachineLink(alert.machine.id, false, alertTime, alert.id);
    };
    return AlertContextWidgetComponent;
}(ReportWidgetComponent));
export { AlertContextWidgetComponent };
