var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { WidgetType } from '../reports/components/report-with-filters.component';
import { ReportsService, reportWithFiltersFromDateProperty, reportWithFiltersToDateProperty, } from './services/reports.service';
import { MachineProtectionReportService } from './services/machine-protection-report.service';
import { I18nService } from '@wcd/i18n';
import { MachineProtection, HealthStatus, MachineDimensionType } from '@wcd/domain';
import { BaseReportWidgetComponent } from '../reporting/base-reporting-widget';
import { MachineProtectionStatusMapping } from './machine-protection-status-mapping';
import { forkJoin } from 'rxjs';
import { map } from 'rxjs/operators';
var MachineProtectionActiveStatusOvertimeWidget = /** @class */ (function (_super) {
    __extends(MachineProtectionActiveStatusOvertimeWidget, _super);
    function MachineProtectionActiveStatusOvertimeWidget(reportsService, i18nService, machineProtectionSvc, machineProtectionStatusMapping) {
        var _this = _super.call(this, 'dashboardActiveDevices', 'securityOperations_activeDevices_widgetTitle', reportsService, i18nService, {}, undefined, 30) || this;
        _this.machineProtectionSvc = machineProtectionSvc;
        _this.machineProtectionStatusMapping = machineProtectionStatusMapping;
        _this.widgetType = WidgetType.Trend;
        return _this;
    }
    Object.defineProperty(MachineProtectionActiveStatusOvertimeWidget.prototype, "dataFunc", {
        get: function () {
            var _this = this;
            var today = new Date();
            today.setUTCHours(0, 0, 0, 0);
            return function (serializedFilters) {
                var _a;
                return forkJoin([
                    _this.machineProtectionSvc.getMachineByHealthStatusData(__assign({}, serializedFilters, { healthStatus: HealthStatus.Active })),
                    _this.machineProtectionSvc.getMachineByHealthStatusData(__assign({}, serializedFilters, (_a = { healthStatus: HealthStatus.Active }, _a[reportWithFiltersFromDateProperty] = today.toISOString(), _a[reportWithFiltersToDateProperty] = today.toISOString(), _a))),
                ]).pipe(map(function (_a) {
                    var overTimeData = _a[0], dailyData = _a[1];
                    var machineProtection = new MachineProtection();
                    machineProtection.dimensionType = MachineDimensionType.HealthStatus;
                    machineProtection.data = overTimeData.data.concat(dailyData.data);
                    return machineProtection;
                }));
            };
        },
        enumerable: true,
        configurable: true
    });
    MachineProtectionActiveStatusOvertimeWidget.prototype.getSettings = function (data) {
        return this.machineProtectionSvc.getSettingsOverTime(HealthStatus, this.machineProtectionStatusMapping.healthStatusMapping, data, HealthStatus.Active);
    };
    return MachineProtectionActiveStatusOvertimeWidget;
}(BaseReportWidgetComponent));
export { MachineProtectionActiveStatusOvertimeWidget };
