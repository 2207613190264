import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { IntegrationSettingsComponent } from './integration-settings.component';
import { AdvancedFeaturesService } from './advanced-features.service';
import { BilbaoRegistrationComponent } from './bilbao-registration.component';
import { FabMessageBarModule } from '@angular-react/fabric';

@NgModule({
	imports: [SharedModule, FabMessageBarModule],
	declarations: [IntegrationSettingsComponent, BilbaoRegistrationComponent],
	exports: [IntegrationSettingsComponent],
	providers: [AdvancedFeaturesService],
	entryComponents: [BilbaoRegistrationComponent],
})
export class IntegrationSettingsModule {}
