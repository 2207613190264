var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportWidgetsLayoutModel } from './report-widgets-layout.base';
import { ReportWidgetsLayoutColumnModel, } from './report-widgets-layout.column.model';
var ReportWidgetsLayoutRowModel = /** @class */ (function (_super) {
    __extends(ReportWidgetsLayoutRowModel, _super);
    function ReportWidgetsLayoutRowModel(config) {
        var _this = _super.call(this, config) || this;
        _this.title = config.title;
        _this.columns =
            config.columns && config.columns.length
                ? config.columns.map(function (columnConfig) {
                    return new ReportWidgetsLayoutColumnModel(columnConfig);
                })
                : [];
        return _this;
    }
    Object.defineProperty(ReportWidgetsLayoutRowModel.prototype, "type", {
        get: function () {
            return 'row';
        },
        enumerable: true,
        configurable: true
    });
    return ReportWidgetsLayoutRowModel;
}(ReportWidgetsLayoutModel));
export { ReportWidgetsLayoutRowModel };
