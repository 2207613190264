
		<div *ngIf="statusInfo.ready; else loader">
			<wcd-shared-icon [iconName]="statusInfo.investigationStatus.iconName"> </wcd-shared-icon>
			<wcd-help
				[text]="
					'investigations.status.' + statusInfo.investigationStatus.type + '.description' | i18n
				"
			>
				{{ statusInfo.investigationStatus.name }}
			</wcd-help>
		</div>
		<ng-template #loader>
			<div>
				<i class="large-loader-icon"></i>
			</div>
		</ng-template>
	