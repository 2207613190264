import { DataEntityType } from '@microsoft/paris';
import {
	serializeDetectionSource,
	serializeServiceSource,
} from '../service-source-filters/service-source-filters.helpers';
import { DetectionSource } from '../service-source-filters/detection-source.entity';
import {
	detectionSourceValues,
	MANUAL_DETECTION_SOURCE_TYPE,
} from '../service-source-filters/detection-source.entity.values';
import { ServiceSourceType } from '../service-source-filters/service-source.enum';
import { ServiceSourceValue, serviceSourceValues } from '../service-source-filters/service-source.entity';
const THREAT_EXPERT_DETECTION_SOURCE_TYPE = 'ThreatExperts';
export function mdatpDetectionSources(
	isMtp: boolean,
	isThreatExpertEnabledForMdatp: boolean,
	includeAllDetectionSources = true,
	detectionSourceFiltersExtended = true,
	isSmbDetectionSource: boolean = false
): Array<DetectionSource> {
	return detectionSourceValues.filter((detectionSource) =>
		isSelectableDetectionSourceForMde(
			isMtp,
			detectionSource,
			isThreatExpertEnabledForMdatp,
			includeAllDetectionSources,
			detectionSourceFiltersExtended,
			detectionSource.isSmbDetectionSource && isSmbDetectionSource
		)
	);
}

export function isSelectableDetectionSourceForMde(
	isMtp: boolean,
	detectionSource: DetectionSource,
	isThreatExpertEnabledForMdatp: boolean,
	includeAllDetectionSources = true,
	detectionSourceFiltersExtended: boolean = true,
	isSmbDetectionSourceFilters: boolean = false
): boolean {
	return (
		detectionSource.isSelectable &&
		!detectionSource.isThirdParty &&
		(detectionSource.type !== THREAT_EXPERT_DETECTION_SOURCE_TYPE || // Threat experts detection source is exposed only to tenants that are exposed to bilbao
			isThreatExpertEnabledForMdatp) &&
		(detectionSource.isMdeDetectionSource ||
			(isMtp && includeAllDetectionSources) ||
			(isMtp && isSmbDetectionSourceFilters)) &&
		// For E3 sku remove filters
		(!!detectionSourceFiltersExtended || !detectionSource.isExtended)
	);
}

export const mtpDetectionSources = (
	detectionSources: DetectionSource[],
	isThreatExpertEnabledForMtp: boolean,
	isManualAlertsEnabled = false
): Array<DetectionSource> =>
	detectionSources.filter((detectionSource) => {
		switch (detectionSource.type) {
			case THREAT_EXPERT_DETECTION_SOURCE_TYPE:
				return isThreatExpertEnabledForMtp;
			case MANUAL_DETECTION_SOURCE_TYPE:
				return isManualAlertsEnabled;
			default:
				return true;
		}
	});

export function getDetectionSourceFilterValueByServiceSource(
	isThreatExpertEnabledForMtp: boolean,
	isThreatExpertEnabledForMdatp: boolean,
	isMtp: boolean,
	filterOatpServiceSource: boolean = false,
	filterItpServiceSource: boolean = false,
	areNonMdeFiltersHidden?: boolean,
	detectionSourceFiltersExtended?: boolean,
	mapgAlertsEnabled = false,
	aadAlertsEnabled = false,
	smbDetectionSourcesEnabled = false,
	manualAlertsEnabled = false,
	dlpAlertsEnabled = false
) {
	return serviceSourceValues
		.filter((serviceSource) => {
			if (serviceSource.isSelectable && serviceSource.id === ServiceSourceType.Oatp) {
				return !filterOatpServiceSource;
			} else if (
				(serviceSource.isSelectable && serviceSource.id === ServiceSourceType.Mcas) ||
				(serviceSource.isSelectable && serviceSource.id === ServiceSourceType.Aatp)
			) {
				return !filterItpServiceSource;
			} else if (serviceSource.id === ServiceSourceType.Mapg) {
				return mapgAlertsEnabled && !areNonMdeFiltersHidden;
			} else if (serviceSource.id === ServiceSourceType.Aad) {
				return aadAlertsEnabled && !areNonMdeFiltersHidden;
			} else if (serviceSource.id === ServiceSourceType.Dlp) {
				return dlpAlertsEnabled && !areNonMdeFiltersHidden;
			}

			return serviceSource.isSelectable;
		})
		.map((serviceSourceValue) => {
			const children: DetectionSource[] = getChildren(
				serviceSourceValue,
				isThreatExpertEnabledForMtp,
				isMtp,
				isThreatExpertEnabledForMdatp,
				detectionSourceFiltersExtended,
				smbDetectionSourcesEnabled,
				manualAlertsEnabled
			);
			const filterOption: any = {
				value: serializeServiceSource(serviceSourceValue.id),
				nameI18nKey: serviceSourceValue.nameI18nKey,
				children: children
					? children.map((detectionSource) => ({
							nameI18nKey: detectionSource.nameI18nKey,
							value: serializeDetectionSource(
								detectionSource.id,
								serviceSourceValue.id.toString()
							),
							priority: detectionSource.priority,
					  }))
					: null,
			};

			return filterOption;
		})
		.filter((filterOption) => {
			if (filterOption.nameI18nKey === 'serviceSourceType_Mtp') {
				return !areNonMdeFiltersHidden;
			}
			return true;
		});
}

function getChildren(
	serviceSourceValue: ServiceSourceValue,
	isThreatExpertEnabledForMtp,
	isMtp,
	isThreatExpertEnabledForMdatp: boolean,
	detectionSourceFiltersExtended?: boolean,
	isSmbDetectionSourcesEnabled: boolean = false,
	isManualAlertsEnabled = false
): DetectionSource[] {
	if (serviceSourceValue.children && serviceSourceValue.children.length <= 1) {
		return null;
	}

	if (serviceSourceValue.id === ServiceSourceType.Wdatp) {
		return mdatpDetectionSources(
			isMtp,
			isThreatExpertEnabledForMdatp,
			false,
			detectionSourceFiltersExtended,
			isSmbDetectionSourcesEnabled
		);
	}

	if (serviceSourceValue.id === ServiceSourceType.Mtp) {
		return mtpDetectionSources(
			serviceSourceValue.children,
			isThreatExpertEnabledForMtp,
			isManualAlertsEnabled
		);
	}

	return serviceSourceValue.children || null;
}
