<div class="wcd-flex-vertical wcd-full-height">
	<h2 class="wcd-flex-0 wcd-font-weight-bold">
		{{ i18n.strings.baseline_profile_wizard_profile_configurations_assignment_label }}
	</h2>
	<div class="wcd-margin-xLarge-top wcd-flex-horizontal">
		<div class="wcd-flex-1">
			{{ i18n.strings.baseline_profile_wizard_profile_configurations_assignment_description }}
		</div>
		<div class="wcd-flex-0">
			{{ getHowManySelectedText() }}
			<button class="btn btn-link clear-search" type="button"
					(click)="unselectAll()"
					[attr.aria-label]="'select.clear' | i18n"
					[wcdTooltip]="'select.clear' | i18n">
				<wcd-shared-icon iconColor="black" iconName="cancel"></wcd-shared-icon>
			</button>
		</div>
	</div>
	<div class="wcd-flex-1 wcd-margin-large-top">
		<dataview
			*ngIf="fields"
			id="profileConfigurationWizardDataview"
			[entityType]="entityType"
			[repository]="repository"
			[dataViewConfig]="dataViewConfig"
			[fields]="fields"
			[isItemGroup]="boundIsGroup"
			[allowGroupItems]="true"
			[getGroupItems]="boundGetContainedConfigurations"
			[loadGroupItemsOnLoad]="true"
			[isGroupExpandedOnInit]="boundIsGroupExpanded"
			[allowFilters]="false"
			[allowPaging]="false"
			[searchEnabled]="true"
			[ignoreQueryParams]="true"
			[forceGetDataFromApi]="true"
			[removePadding]="true"
			[datatablePadTop]="true"
			[allowMultipleSelection]="true"
			[allowColumnCustomization]=false
			[selectAllEnabled]="true"
			[isItemClickable]="boundIsGroup"
			[m365DataError]="true"
			[showDialogOnError]="false"
			[shouldShowEntityPanel]="false"
			(select)="selectSetting($event.items)"
			[isItemSelected]="boundIsSettingSelected"
			[navigateOnChange]="false"
			[localRefreshOn]="localRefreshOn"
			(onSearch)="onSearch($event)">
		</dataview>
	</div>
</div>
