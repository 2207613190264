import { Injectable } from '@angular/core';
import { ColorsService } from '../../shared/services/colors.service';
import { KnownColorsService } from '../../shared/services/known-colors.service';
import { KnownColorName } from '../../shared/models/colors/known-colors.models';
import { BaselineCompliant } from '../components/baseline-compliant-bar/baseline-compliant.enum';

const baselineComplianceProfileStatusColorName: Record<BaselineCompliant, KnownColorName> = {
	[BaselineCompliant.Compliant]: 'greenLight',
	[BaselineCompliant.NotCompliant]: 'highSeverity',
	[BaselineCompliant.DataUnavailable]: 'neutralTertiary',
};

@Injectable()
export class BaselineCompliantColorService extends ColorsService<BaselineCompliant> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, baselineComplianceProfileStatusColorName);
	}
}
