/**
 * Manages SCC i18n services
 */
export class SccI18nService {
	constructor(private sccI18n) {}

	get language(): string {
		return this.sccI18n && this.sccI18n.language;
	}

	get timezone(): string {
		return this.sccI18n.timezone;
	}

	get alwaysUseUTC(): boolean {
		return this.sccI18n.alwaysUseUTC;
	}

	format(date, format) {
		return this.sccI18n.format(date, format);
	}

	get timeFormat(): string {
		return this.sccI18n.timeFormat;
	}
}
