var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportableBaseFieldComponent } from '../report-inaccuracy/tvm-reportable-base-field.component';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
var HOVER_PRODUCTIVITY_ASSESSMENT_ICON_EVENT = 'TVM productivity assessment icon hover';
var TaggedFieldComponent = /** @class */ (function (_super) {
    __extends(TaggedFieldComponent, _super);
    function TaggedFieldComponent(appInsightsService) {
        var _this = _super.call(this) || this;
        _this.appInsightsService = appInsightsService;
        return _this;
    }
    TaggedFieldComponent.prototype.onTooltipDisplay = function () {
        this.appInsightsService.trackEvent(HOVER_PRODUCTIVITY_ASSESSMENT_ICON_EVENT, { title: this.title });
    };
    return TaggedFieldComponent;
}(ReportableBaseFieldComponent));
export { TaggedFieldComponent };
