import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationInvestigations } from './evaluation-investigations.value-object';

@ApiCall({
	name: 'Evaluation investigation',
	endpoint: 'evaluation/report/Investigations',
	method: 'GET',
	type: EvaluationInvestigations,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetEvaluationInvestigationsApiCall extends ApiCallModel<EvaluationInvestigations> {}
