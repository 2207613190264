
		<nav class="wcd-breadcrumbs" data-track-component="Main breadcrumbs">
			<ul class="wcd-breadcrumbs__list">
				<li class="wcd-breadcrumbs__overflow" *ngIf="(items$ | async)?.length > maxItems">
					<dropdown
						buttonId="breadcrumbs-older"
						buttonIcon="menu.horizontal"
						buttonClass="wcd-breadcrumbs__overflowButton"
						buttonIconClass="wcd-breadcrumbs__ellipses"
						buttonTooltip="{{ 'breadcrumbs.showOlder' | i18n }}"
						[showIconDropdown]="false"
						[closeOnClick]="true"
					>
						<ul class="dropdown-list">
							<li
								*ngFor="
									let crumb of (items$
										| async
										| slice: 0:(items$ | async).length - maxItems);
									trackBy: trackById
								"
								class="wcd-breadcrumbs__item collapsed"
							>
								<ng-container
									*ngTemplateOutlet="
										linkBreadcrumb;
										context: { crumb: crumb, collapsed: true }
									"
								></ng-container>
							</li>
						</ul>
					</dropdown>
					<ng-container *ngTemplateOutlet="chevron"></ng-container>
				</li>
				<ng-container *ngIf="(items$ | async).length < maxItems; else withCollapsed">
					<ng-container
						*ngTemplateOutlet="itemsShown; context: { items: items$ | async }"
					></ng-container>
				</ng-container>
				<ng-template #withCollapsed>
					<ng-container
						*ngTemplateOutlet="
							itemsShown;
							context: { items: items$ | async | slice: (items$ | async).length - maxItems }
						"
					></ng-container>
				</ng-template>
				<ng-template #itemsShown let-items="items">
					<li
						class="wcd-breadcrumbs__item"
						*ngFor="let crumb of items; index as i; trackBy: trackById; let last = last"
					>
						<ng-container
							*ngTemplateOutlet="
								last ? lastBreadcrumb : linkBreadcrumb;
								context: { crumb: crumb }
							"
						></ng-container>
					</li>
				</ng-template>
			</ul>
		</nav>
		<ng-template #lastBreadcrumb let-crumb="crumb">
			<span class="wcd-breadcrumbs__item--current" [wcdTooltip]="crumb.label">
				<ng-container *ngTemplateOutlet="breadcrumbLabel; context: { crumb: crumb }"></ng-container>
			</span>
		</ng-template>
		<ng-template #linkBreadcrumb let-crumb="crumb" let-collapsed="collapsed">
			<route-link
				class="wcd-breadcrumbs__itemLinkWrapper"
				className="wcd-breadcrumbs__itemLink"
				[routeLink]="{ routerLink: [crumb.url], queryParams: crumb.queryParams }"
				[wcdTooltip]="crumb.label"
				[attr.data-track-id]="'breadcrumb_' + crumb.id + (collapsed ? '_collapsed' : '')"
				data-track-type="Navigation"
			>
				<ng-container *ngTemplateOutlet="breadcrumbLabel; context: { crumb: crumb }"></ng-container>
			</route-link>
			<ng-container *ngIf="!collapsed">
				<ng-container *ngTemplateOutlet="chevron"></ng-container>
			</ng-container>
		</ng-template>
		<ng-template #breadcrumbLabel let-crumb="crumb">
			{{ crumb.label }}
		</ng-template>
		<ng-template #chevron>
			<wcd-shared-icon iconName="carets.right" verticalAlign="middle" class="wcd-breadcrumbs__chevron">
			</wcd-shared-icon>
		</ng-template>
	