import { Paris } from '@microsoft/paris';
import { HuntingDatabaseSchemaApiCall } from '@wcd/domain';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
var SchemaService = /** @class */ (function () {
    function SchemaService(paris) {
        this.paris = paris;
    }
    SchemaService.prototype.getSchema = function () {
        return this.paris.apiCall(HuntingDatabaseSchemaApiCall);
    };
    SchemaService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SchemaService_Factory() { return new SchemaService(i0.ɵɵinject(i1.Paris)); }, token: SchemaService, providedIn: "root" });
    return SchemaService;
}());
export { SchemaService };
