import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistData,
	FilterValuesChecklistSelection,
	FilterValuesChecklistValueData,
} from '../checklist/filter-values.checklist.component';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewChild } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { ISearchSettings } from '@wcd/forms';
import { ObservableInput } from 'rxjs';
import { FilterValuesCheckListConfig } from '../checklist/filter-values.checklist.config';
import { SerializedFilters } from '../../../models/serialized-filters.type';
import { ListFilterValue } from '../list-filter-value.model';
import { I18nService } from '@wcd/i18n';

@Component({
	template: `
		<div
			*ngIf="
				config?.search &&
				(config?.search?.alwaysDisplay || allValues?.count > allValues?.values?.length)
			"
			class="wcd-margin-small-vertical"
		>
			<wcd-search
				[placeholder]="config.search.placeholder || i18nService.strings.filters_search_title"
				[settings]="config.search.settings"
				[fullWidth]="true"
				(select)="addSearchValue($event)"
				[searchFunction]="config.search.method"
				[ariaLabel]="fieldName"
				[closeResultsWhenSearchCleared]="true"
			></wcd-search>
		</div>
		<wcd-filter-values-checklist
			(filterValuesChange)="filterValuesChange.emit($event)"
			[fieldId]="fieldId"
			[data]="allValues"
			[config]="config"
			[selectedValues]="selectedValues"
		></wcd-filter-values-checklist>
	`,
	selector: 'wcd-filter-values-checklist-with-search',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesChecklistWithSearchComponent<
	TValue extends string | number | boolean
> extends FilterValuesComponent<
	FilterValuesChecklistData<TValue>,
	FilterValuesChecklistSelection,
	ReadonlyArray<ListFilterValue<TValue>>,
	FilterValuesChecklistWithSearchConfig<TValue>
> {
	private searchValues: FilterValuesChecklistValueData<TValue>[];
	private _config: FilterValuesChecklistWithSearchConfig<TValue>;

	@Input() fieldName? : string = null;
	@Input()
	set data(data: FilterValuesChecklistData) {
		if (this.searchValues) {
			const { values, count } = data || { values: [], count: null };
			this._allValues = {
				count,
				values: [...values, ...this.searchValues],
			};
		} else {
			this._allValues = data;
		}
		this.changeDetectorRef.markForCheck();
	}

	@Input()
	set config(conf: FilterValuesChecklistWithSearchConfig<TValue>) {
		this._config = conf;
		this.changeDetectorRef.detectChanges();
	}

	get config(): FilterValuesChecklistWithSearchConfig<TValue> {
		return this._config;
	}

	@ViewChild(FilterValuesChecklistComponent, { static: true }) checklist: FilterValuesChecklistComponent<
		TValue
	>;

	private _allValues: FilterValuesChecklistData<TValue>;
	get allValues(): FilterValuesChecklistData<TValue> {
		return this._allValues;
	}

	get value(): ReadonlyArray<ListFilterValue<TValue>> {
		return this.checklist.value;
	}

	constructor(private changeDetectorRef: ChangeDetectorRef,
		public i18nService: I18nService) {
		super();
	}

	setSelectedValues(selectedValues: FilterValuesChecklistSelection) {
		this.checklist.setSelectedValues(selectedValues);
	}

	serialize(): SerializedFilters {
		return this.checklist.serialize();
	}

	deserialize(serializedSelectedValues: SerializedFilters): FilterValuesChecklistSelection {
		return this.checklist.deserialize(serializedSelectedValues);
	}

	addSearchValue(searchValue: FilterValuesChecklistValueData<TValue>) {
		const { values, count } = this._allValues || { values: [], count: null };
		this._allValues = {
			count,
			values: [...values, searchValue],
		};
		this.searchValues = this.searchValues ? [...this.searchValues, searchValue] : [searchValue];

		this.selectedValues = [...(this.selectedValues || []), searchValue.value];
		this.setSelectedValues(this.selectedValues);
		this.filterValuesChange.emit(this.checklist.getSelectedValues());

		this.changeDetectorRef.markForCheck();
	}
}

export interface FilterValuesChecklistWithSearchConfig<TValue extends string | number | boolean>
	extends FilterValuesCheckListConfig<TValue> {
	search: {
		alwaysDisplay?: boolean;

		method: (term: string) => ObservableInput<Array<FilterValuesChecklistValueData<TValue>>>;
		/**
		 * Placeholder text for the search input
		 */
		placeholder?: string;

		settings?: ISearchSettings<FilterValuesChecklistValueData<TValue>>;
	};
}
