import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { RemediationActionType } from '../remediation-action-type.entity';
import { PendingActionDecision } from './pending-action-decision.entity';
import { AirsEntityIdType } from '../../airs_entity/airs-entity-id-type.value-object';
import { AirsEntityTypeConfig } from '../../airs_entity/airs-entity-type-config.entity';
import { InvestigationPendingType } from '../../investigation/investigation-pending-type.entity';
import { investigationPendingTypeValues } from '../../investigation/investigation-pending-type.values';
import { InvestigationActionStatus } from '../../investigation/actions/investigation-action-status.entity';
import { InvestigationActionStatusId } from '../../investigation/actions/investigation-action-status.values';

@ValueObject({
	singularName: 'Pending Action',
	pluralName: 'Pending Actions',
})
export class PendingAction extends ModelBase {
	@EntityField({ data: 'entity_name' })
	entityName: string;

	@EntityField({ parse: decision => decision && decision.toLowerCase() })
	decision: PendingActionDecision;

	@EntityField() user: string;

	@EntityField({ data: 'action_type' })
	remediationActionType: RemediationActionType;

	@EntityField({ data: 'action_id' })
	actionId: number;

	@EntityField({ data: 'action_name' })
	actionName: string;

	@EntityField({ data: 'start_time' })
	startTime: Date;

	@EntityField({ data: 'end_time' })
	endTime: Date;

	@EntityField({ arrayOf: AirsEntityIdType })
	entities: Array<AirsEntityIdType>;

	@EntityField({
		data: 'PendingType',
		parse: type => {
			const pendingTypeValue = investigationPendingTypeValues.find(val => val.type === type);
			return type && pendingTypeValue ? pendingTypeValue.id : null;
		},
	})
	pendingType: InvestigationPendingType;

	@EntityField({
		data: 'ActionStatus',
		parse: (actionStatus: string) => actionStatus && InvestigationActionStatusId[actionStatus],
	})
	status: InvestigationActionStatus;

	@EntityField({ data: 'IsOfficeAction' })
	isOfficeAction: boolean;

	get entityType(): AirsEntityTypeConfig {
		return this.entities && this.entities.length ? this.entities[0].entityType : null;
	}

	get waitingTime(): number {
		return (this.endTime || new Date()).valueOf() - this.startTime.valueOf();
	}

	get isUserPending() {
		return this.remediationActionType.isUserPending;
	}
}
