
		<wcd-toggle
			[trueLabel]="config?.trueLabel"
			[falseLabel]="config?.falseLabel"
			(change)="onChange($event)"
			[checked]="selectedValues"
			[helpText]="config?.helpText"
			[ariaLabel]="config?.ariaLabel"
		>
		</wcd-toggle>
	