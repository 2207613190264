import { ChangeDetectionStrategy, Component, Input, EventEmitter, Output } from '@angular/core';
import { sumBy } from 'lodash-es';
import { OnChanges, TypedChanges, TypedChange } from '@wcd/angular-extensions';
import { prettyNumber } from '@wcd/prettify';

export interface EventModel<TKind extends string | number> {
	readonly kind: TKind;
	count: number;
}

export interface ColorMap<TKind extends string | number> {
	readonly type: 'direct' | 'class';
	readonly map: ReadonlyMap<TKind, string>;
}

export type KindTextFormatter<TKind> = (kind: TKind) => string;

export const enum EventsSummaryBarSize {
	Medium = 'medium',
	Large = 'large',
}

@Component({
	selector: 'events-summary-bars',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./events-summary-bar.component.scss'],
	template: `
		<div
			*ngFor="let item of eventsDisplayItems"
			class="summary-bar"
			[wcdTooltip]="getTooltip(item.metadata.event)"
			(click)="select.emit(item.metadata.event)"
			[class.events-summary-bars--selectable]="isSelectable"
			[ngClass]="[item.colorClass, 'events-summary-bars--' + size]"
			[style.background-color]="item.color"
			[style.flex]="item.ratio"
			(keyup.enter)="select.emit(item.metadata.event)"
			[attr.tabindex]="isSelectable ? '0' : undefined"
			[attr.role]="isSelectable ? 'button' : 'img'"
			aria-roledescription="tooltip"
			[attr.aria-label]="getTooltip(item.metadata.event)"
		></div>
		<div *ngIf="(!eventsDisplayItems || eventsDisplayItems.length == 0) && emptyColorClass"
			 class="summary-bar"
			 [ngClass]="[emptyColorClass, 'events-summary-bars--' + size]"
			 [style.flex]="1"
		></div>
	`,
})
export class EventsSummaryBarComponent<TKind extends string | number>
	implements OnChanges<EventsSummaryBarComponent<TKind>> {
	@Input() events: ReadonlyArray<EventModel<TKind>>;
	@Input() kindColorMap: ColorMap<TKind>;
	@Input() displayTextFormatter?: KindTextFormatter<TKind>;
	@Input() size?: EventsSummaryBarSize = EventsSummaryBarSize.Medium;
	@Input() isSelectable = false;
	@Input() hideTooltip = false;
	@Input() emptyColorClass: string;

	@Output() select: EventEmitter<EventModel<TKind>> = new EventEmitter();

	eventsDisplayItems: ReadonlyArray<DisplayItem<TKind>>;

	ngOnChanges(changes: TypedChanges<EventsSummaryBarComponent<TKind>>) {
		const wasChanged = <T>(change: TypedChange<T>) =>
			change && (change.isFirstChange() || change.currentValue !== change.previousValue);

		if (wasChanged(changes.events)) {
			this.eventsDisplayItems = this.itemsToRatios(changes.events.currentValue);
		}
	}

	getTooltip(eventModel: EventModel<TKind>): string {
		if (this.hideTooltip) {
			return undefined;
		}

		const kindDisplayText =
			(this.displayTextFormatter && this.displayTextFormatter(eventModel.kind)) || eventModel.kind;

		return `${kindDisplayText} (${prettyNumber(eventModel.count)})`;
	}

	private itemsToRatios(items: ReadonlyArray<EventModel<TKind>>): ReadonlyArray<DisplayItem<TKind>> {
		const totalCount = sumBy(items, item => item.count);

		const { map, type } = this.kindColorMap;

		return items.map(item => ({
			ratio: item.count / totalCount,
			color: type === 'direct' && map.get(item.kind),
			colorClass: type === 'class' && map.get(item.kind),
			metadata: {
				event: item,
			},
		}));
	}
}

interface DisplayItem<TKind extends string | number> {
	readonly ratio: number;
	readonly color?: string;
	readonly colorClass?: string;
	readonly metadata: {
		readonly event: EventModel<TKind>;
	};
}
