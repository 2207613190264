import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AlertsSeveritySummary, SeverityType, AlertSeverityCount } from '@wcd/domain';
import {
	EventModel,
	ColorMap,
	KindTextFormatter,
} from '../../shared/components/events-summary-bar/events-summary-bar.component';
import { LegendItem, ValueFormatter } from '@wcd/charts';
import { SeverityTypeColorService } from '../../shared/services/severity-type-color.service';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { Strings, I18nService } from '@wcd/i18n';

type SeverityConfig = EventModel<SeverityType> & LegendItem;

const severityTypeDisplayKeysMap: Record<SeverityType, keyof Strings> = {
	[SeverityType.Informational]: 'alert_severity_informational',
	[SeverityType.Low]: 'alert_severity_low',
	[SeverityType.Medium]: 'alert_severity_medium',
	[SeverityType.High]: 'alert_severity_high',
};

@Component({
	selector: 'alerts-severity-summary',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<events-summary-bars
			class="wcd-padding-small-bottom"
			[events]="severities"
			[kindColorMap]="severityColorMap"
			[displayTextFormatter]="barTextFormatter"
			size="large"
		>
		</events-summary-bars>
		<wcd-chart-legend
			class="wcd-full-width wcd-margin-small-top"
			orientation="horizontal"
			[wrap]="true"
			[showValues]="true"
			[valueFormatter]="legendTextFormatter"
			[items]="severities"
		></wcd-chart-legend>
	`,
})
export class AlertsSeveritySummaryComponent implements OnChanges {
	@Input() alertsSeveritySummary: AlertsSeveritySummary;

	severities: ReadonlyArray<SeverityConfig>;

	readonly severityColorMap: ColorMap<SeverityType>;

	readonly barTextFormatter: KindTextFormatter<SeverityType>;

	readonly legendTextFormatter: ValueFormatter;

	constructor(
		private readonly severityTypeColorService: SeverityTypeColorService,
		private i18nService: I18nService,
		displayTextPipe: DisplayTextPipe
	) {
		this.severityColorMap = {
			type: 'class',
			map: severityTypeColorService.backgroundColorsClassMap,
		};

		this.barTextFormatter = severity => displayTextPipe.transform(severity, severityTypeDisplayKeysMap);

		this.legendTextFormatter = count => `(${count})`;
	}

	ngOnChanges() {
		const { high, medium, low, info } = this.alertsSeveritySummary;

		this.severities = [
			this.createSeverityConfig(high),
			this.createSeverityConfig(medium),
			this.createSeverityConfig(low),
			this.createSeverityConfig(info),
		].filter(Boolean);
	}

	private createSeverityConfig({ severity, count }: AlertSeverityCount): SeverityConfig {
		if (count === 0) return null;

		const barConfig: EventModel<SeverityType> = {
			kind: severity.type,
			count: count,
		};

		const legendConfig: LegendItem = {
			name: this.i18nService.get(severityTypeDisplayKeysMap[severity.type]),
			iconClassName: this.severityTypeColorService.fontColorsClassMap.get(severity.type),
			value: count,
		};

		return { ...barConfig, ...legendConfig };
	}
}
