import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { airsEntityTypeConfigs } from './airs-entity-type-config.values';

@Entity({
	singularName: 'Entity Type Config',
	pluralName: 'Entity Type Configs',
	readonly: true,
	values: airsEntityTypeConfigs,
})
export class AirsEntityTypeConfig extends EntityModelBase<number> {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: number;

	@EntityField() name: string;

	@EntityField()
	singularName: string;

	@EntityField()
	typeName: string;

	@EntityField()
	icon: string;

	@EntityField()
	systemExclusionType: string;

	@EntityField()
	extendedModeOnly?: boolean;

	@EntityField()
	alertV3Name?: string;

	get enableSystemExclusion(): boolean {
		return !!this.systemExclusionType;
	}

	constructor(data) {
		super(data);

		this.typeName = this.name.toLowerCase();
		this.icon = `entities.${this.singularName.toLowerCase().replace(/\s/g, '_')}`;
	}
}
