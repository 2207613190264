import { Injectable } from '@angular/core';
import { KnownColorName } from '../../../shared/models/colors/known-colors.models';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
import { MachineExposureScoreType } from '@wcd/domain';

const exposureColorMap: Record<MachineExposureScoreType, KnownColorName> = {
	[MachineExposureScoreType.High]: 'red',
	[MachineExposureScoreType.Medium]: 'orangeLighter',
	[MachineExposureScoreType.Low]: 'yellow',
	[MachineExposureScoreType.None]: 'neutralQuaternary',
};
@Injectable()
export class MachineExposureLevelColorService extends ColorsService<MachineExposureScoreType> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, exposureColorMap);
	}
}
