
        <div class="wcd-flex-horizontal wcd-padding-small-top wcd-flex-horizontal-align-items-center">
            <wcd-shared-icon *ngIf=shouldShowStatusIcon(networkDevice)
                iconName="StatusCircleInner"
                [ngClass]="getStatusColorClass(networkDevice)"
                class="wcd-margin-xsmall-right">
            </wcd-shared-icon>
            <span class="wcd-font-weight-semibold wcd-margin-large-right">
                {{ getStatusText(networkDevice) }}
            </span>

            <ng-container *ngIf="networkDevice.exposureLevel">
                <span class="wcd-padding-small-right" [wcdTooltip]="'tvm_network_devices_exposure_level' | i18n">
                    {{ 'tvm_network_devices_exposure_level' | i18n }}
                </span>
                <wcd-shared-icon
                    iconName="warning"
                    [ngClass]=getWeaknessColorClass(networkDevice)
                    class="wcd-font-weight-semibold wcd-margin-xsmall-right">
                </wcd-shared-icon>
                <span class="wcd-font-weight-semibold">
                    {{ networkDevice.exposureLevel }}
                </span>
            </ng-container>
        </div>
	