import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Tag } from '../tag/tag.entity';
import { TagType } from '../tag/tag-type.enum';

@ValueObject({
	singularName: 'Device tags',
	pluralName: 'Device tags',
	readonly: true,
})
export class MachineTags extends ModelBase {
	@EntityField({ data: 'BuiltInTags' })
	builtInTags: Array<string>;

	@EntityField({ data: 'UserDefinedTags' })
	userDefinedTags: Array<string>;

	@EntityField({ data: 'McasTags' })
	mcasTags?: Array<string>;

	get allTags(): Array<Tag> {
		return [
			...(this.builtInTags || []).map(MachineTags.getSystemTag),
			...(this.mcasTags || []).map(MachineTags.getMcasTag),
			...(this.userDefinedTags || []).map(MachineTags.getUserTag),
		];
	}

	private static getSystemTag(tag: string): Tag {
		return new Tag({ id: tag, name: tag, type: TagType.system, isEditable: false });
	}

	private static getMcasTag(tag: string): Tag {
		// MCAS tags need to be translated
		return new Tag({ id: tag, name: '', nameKey: tag, type: TagType.system, isEditable: false });
	}

	private static getUserTag(tag: string): Tag {
		return new Tag({ id: tag, name: tag, type: TagType.user });
	}
}
