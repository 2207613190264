/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "@angular/router";
import * as i5 from "../../../../icons/src/lib/components/icon.component.ngfactory";
import * as i6 from "../../../../icons/src/lib/components/icon.component";
import * as i7 from "../../../../icons/src/lib/services/icons.service";
import * as i8 from "./enable-automated-ir.component";
var styles_EnableAutomatedIrComponent = [];
var RenderType_EnableAutomatedIrComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EnableAutomatedIrComponent, data: {} });
export { RenderType_EnableAutomatedIrComponent as RenderType_EnableAutomatedIrComponent };
function View_EnableAutomatedIrComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "page-header"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_EnableAutomatedIrComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EnableAutomatedIrComponent_1)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 16, "div", [["class", "wcd-flex-center-all wcd-full-height wcd-full-width wcd-full-height-from-width-800"]], null, null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(7, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 11, "a", [["class", "wcd-flex-center-vertical"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 10).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵdid(10, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i3.LocationStrategy], { routerLink: [0, "routerLink"] }, null), i0.ɵpad(11, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "wcd-shared-icon", [["class", "wcd-padding-small-right"]], null, null, null, i5.View_IconComponent_0, i5.RenderType_IconComponent)), i0.ɵdid(14, 573440, null, 0, i6.IconComponent, [i7.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(17, 0, null, null, 2, "h2", [["class", "wcd-font-size-l"]], null, null, null, null, null)), (_l()(), i0.ɵted(18, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(19, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_1 = "wcd-flex-center-all wcd-full-height wcd-full-width wcd-full-height-from-width-800"; var currVal_2 = _co.messageClass; _ck(_v, 7, 0, currVal_1, currVal_2); var currVal_5 = _ck(_v, 11, 0, "/preferences2", "integration"); _ck(_v, 10, 0, currVal_5); var currVal_6 = "investigationOutline"; _ck(_v, 14, 0, currVal_6); }, function (_ck, _v) { var currVal_3 = i0.ɵnov(_v, 10).target; var currVal_4 = i0.ɵnov(_v, 10).href; _ck(_v, 9, 0, currVal_3, currVal_4); var currVal_7 = i0.ɵunv(_v, 18, 0, _ck(_v, 19, 0, i0.ɵnov(_v, 0), "enableAirs.title")); _ck(_v, 18, 0, currVal_7); }); }
export function View_EnableAutomatedIrComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "enable-auto-ir", [], null, null, null, View_EnableAutomatedIrComponent_0, RenderType_EnableAutomatedIrComponent)), i0.ɵdid(1, 180224, null, 0, i8.EnableAutomatedIrComponent, [i4.ActivatedRoute], null, null)], null, null); }
var EnableAutomatedIrComponentNgFactory = i0.ɵccf("enable-auto-ir", i8.EnableAutomatedIrComponent, View_EnableAutomatedIrComponent_Host_0, { title: "title", messageClass: "messageClass" }, {}, []);
export { EnableAutomatedIrComponentNgFactory as EnableAutomatedIrComponentNgFactory };
