import { IconLibrary } from '../services/icon_libraries';

export class Icon {
	constructor(public name: string, public library: IconLibrary) {
		if (!name) throw new Error('Missing name for icon.');

		if (!library || !library.name) throw new Error('Missing library for icon.');

		Object.freeze(this);
	}
}
