import { EntityRelationship, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { AatpProfile } from '../../../azure-threat-analytics/aatp-profile.value-object';
import { LegacyUser } from '../legacy-user.entity';
import { WcdPortalParisConfig } from '../../../paris-config.interface';

@EntityRelationship({
	sourceEntity: LegacyUser,
	dataEntity: AatpProfile,
	getRelationshipData: (user: LegacyUser) => ({
		accountName: user.accountName,
		accountDomain: user.accountDomainName,
	}),
	endpoint: 'ti/aatp/AtaUserSummary',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class LegacyUserAatpProfileRelationship
	implements EntityRelationshipRepositoryType<LegacyUser, AatpProfile> {}
