import { combineLatest, forkJoin, Observable } from 'rxjs';
import { Injector, Type } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { FeaturesService } from '@wcd/config';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { CommunityService } from '../../community/services/community.service';
import { Location } from '@angular/common';
import { publishReplay, refCount, share } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';
var PreloadService = /** @class */ (function () {
    function PreloadService(location, injector, featuresService) {
        this.location = location;
        this.injector = injector;
        this.featuresService = featuresService;
        this.basicPreloadServices = [I18nService];
        this.allPreloadServices = {
            services: [AuthService],
            children: {
                services: [AppInsightsService, FeaturesService],
                children: {
                    services: [PreferencesService, CommunityService],
                    children: {
                        services: [NotificationsService],
                    },
                },
            },
        };
    }
    Object.defineProperty(PreloadService.prototype, "preload$", {
        get: function () {
            if (!this._preload$)
                this._preload$ = this.preload();
            return this._preload$;
        },
        enumerable: true,
        configurable: true
    });
    PreloadService.prototype.preload = function () {
        var _this = this;
        this.preloadStartTime = new Date();
        var basicPreloads$ = forkJoin(this.basicPreloadServices.map(function (service) {
            return _this.injector.get(service).init();
        }));
        var preloadObserver;
        var preloadObservable = new Observable(function (observer) { return (preloadObserver = observer); });
        var allPreloads$ = this.initServices(/^\/Error/.test(this.location.path())
            ? { services: [AppInsightsService] }
            : this.allPreloadServices);
        basicPreloads$.subscribe(function (value) { return preloadObserver.next; }, function (error) { return preloadObserver.error(error); }, function () {
            allPreloads$.subscribe(function (value) {
                preloadObserver.next(value);
            }, function (error) { return preloadObserver.error(error); }, function () {
                if (!_this.preloadTime)
                    _this.preloadTime = new Date().valueOf() - _this.preloadStartTime.valueOf();
                preloadObserver.complete();
            });
        });
        return preloadObservable.pipe(share(), publishReplay(1), refCount());
    };
    PreloadService.prototype.initServices = function (root) {
        var _this = this;
        return new Observable(function (observer) {
            var featuresEnabled = !root.features || _this.featuresService.isAnyEnabled(root.features);
            if (featuresEnabled && root.services.length) {
                combineLatest(root.services.map(function (service) { return _this.injector.get(service).init(); })).subscribe(function (values) {
                    observer.next(values);
                    if (root.children) {
                        _this.initServices(root.children).subscribe(function (values) {
                            observer.next(values);
                        }, function (error) {
                            if (root.onError) {
                                root.onError(error);
                                observer.complete();
                            }
                            else
                                observer.error(error);
                        }, function () {
                            observer.complete();
                        });
                    }
                    else
                        observer.complete();
                    return values;
                }, function (error) {
                    if (root.onError) {
                        root.onError(error);
                        observer.complete();
                    }
                    else
                        observer.error(error);
                });
            }
            else
                observer.complete();
        });
    };
    return PreloadService;
}());
export { PreloadService };
