import { Injectable, OnDestroy, Optional } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Notification } from '../../../notifications/models/notification.model';
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { cloneDeep, isNil } from 'lodash-es';
import { OutbreakService } from '../../../dashboards/threat-analytics3/services/outbreak.service';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { takeUntil } from 'rxjs/operators';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { THREAT_ANALYTICS_ROUTE, setTaSccNavNotificationIndicator } from '@wcd/domain';
// Loading NotificationsService before OfficeIntegrationService crashes the app, moved to bottom
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { sccHostService } from '@wcd/scc-interface';
import { IsPublicEnvironment } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
import { ScreenWidthBreakpoints } from '@wcd/shared';

export type WcdTheme = 'wcd-light-theme' | 'wcd-dark-theme';

const DEFAULT_MAIN_APP_STATE: MainAppState = {
	isInit: false,
	mainNavIsExpanded: false,
	mainSearchShown: false,
	showNotifications: false,
	notificationsAreOpen: false,
	showLoader: false,
	showBreadcrumbs: false,
	screenMaxWidthBreakpoint: null,
	pageContentMaxWidthBreakpoint: null,
	mainNavItemBadges: {},
	loadingComponent: null,
	pageMode: EntityPageViewMode.Default,
	wcdTheme: 'wcd-light-theme',
};



@Injectable()
export class MainAppStateService implements OnDestroy {
	state$: BehaviorSubject<MainAppState> = new BehaviorSubject<MainAppState>(DEFAULT_MAIN_APP_STATE);

	navChange$: Observable<string>;
	mainHeaderClick$: Observable<string>;

	private _navChange$: Subject<string>;
	private _mainHeaderClick$: Subject<string>;
	private destroy$: Subject<boolean> = new Subject<boolean>();
	constructor(
		notificationsService: NotificationsService,
		officeIntegrationService: OfficeIntegrationService,
		@Optional() outbreaksService: OutbreakService,
		appContext: AppContextService,
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService
	) {
		this._navChange$ = new Subject();
		this.navChange$ = this._navChange$.asObservable();
		const theme: WcdTheme =
			this.featuresService.isEnabled(Feature.EnableAngularDarkTheme) &&
			document.querySelector('body').classList.contains('darktheme')
				? 'wcd-dark-theme'
				: 'wcd-light-theme';
		this.toggleStateProperty('wcdTheme', theme);

		notificationsService.notificationGroups$
			.pipe(takeUntil(this.destroy$))
			.subscribe((notifications: Array<Notification>) => {
				const count: number = notifications.reduce(
						(total: number, notification: Notification) => total + notification.count,
						0
					),
					badgeValue: string = count ? count.toString() : null;

				// Currently these services are disabled in MTP, might change in the future
				if (sccHostService.isSCC) {
					return;
				}

				this.setMainNavBadge('investigations', badgeValue);
				this.setMainNavBadge('pending_actions', badgeValue);
				this.setMainNavBadge('actionCenter', badgeValue);
			});

		if (IsPublicEnvironment(this.appConfigService)) {
			outbreaksService &&
			outbreaksService.changeCount$
			.pipe(takeUntil(this.destroy$))
			.subscribe((changeCount) => {
					if (appContext.isSCC) {
						setTaSccNavNotificationIndicator(!!changeCount);
					} else {
						this.setMainNavBadge(THREAT_ANALYTICS_ROUTE, changeCount > 0 ? changeCount.toString() : null);
						this.setMainNavBadge(
							'dashboard',
							changeCount > 0 ? changeCount.toString() : null
						);
					}
				});
		}

		// Currently these services are disabled in MTP, might change in the future
		if (appContext.isSCC) {
			return;
		}

		officeIntegrationService.officeIntegrationWarning$
			.pipe(takeUntil(this.destroy$))
			.subscribe((isWarning: boolean) => this.setMainNavBadge('settings', isWarning ? '1' : null));

		this.mainHeaderClick$ = (this._mainHeaderClick$ = new Subject()).asObservable();
	}

	/**
	 * Used only for the upgrade process. Remove this and navChange$ once it's done.
	 * @param {string} route
	 */
	notifyNavChange(route: string) {
		this._navChange$.next(route);
	}

	notifyMainHeaderClick(id: string) {
		this._mainHeaderClick$.next(id);
	}

	toggleMainNav(isOpen?: boolean): void {
		const currentState: MainAppState = this.state$.getValue(),
			mainNavIsExpanded: boolean = isOpen !== undefined ? isOpen : !currentState.mainNavIsExpanded;

		this.toggleStateProperty('mainNavIsExpanded', mainNavIsExpanded);
		this.toggleResize();
	}

	toggleMainSearch(isOpen?: boolean): void {
		const currentState: MainAppState = this.state$.getValue(),
			mainSearchShown: boolean = isOpen !== undefined ? isOpen : !currentState.mainSearchShown;

		this.toggleStateProperty('mainSearchShown', mainSearchShown);
		this.toggleResize();
	}

	toggleResize() {
		setTimeout(() => {
			window.dispatchEvent(new Event('resize'));
		});
	}

	toggleStateProperty(property: keyof MainAppState, value?: any): boolean {
		return this.toggleStateProperties([{
			property: property,
			value: value
		} as MainAppStateProperty]);
	}

	toggleStateProperties(mainAppStateProperties: Array<MainAppStateProperty>): boolean{
		const currentState: MainAppState = cloneDeep(this.state$.getValue());
		let isStateChange = false;
		mainAppStateProperties.forEach((stateProperty)=>{
			const currentValue: any = currentState[stateProperty.property];
			if (currentValue === undefined) throw new Error(`Unknown state property, '${stateProperty.property}'.`);
			const newValue: any = isNil(stateProperty.value) ? !currentValue : stateProperty.value;
			if (newValue !== currentValue) {
				(currentState as any)[stateProperty.property] = newValue;
				isStateChange = true;
			}
		});
		if(isStateChange){
			this.state$.next(currentState);
		}
		return isStateChange;
	}

	openNotifications(): void {
		this.toggleStateProperty('showNotifications', true);
		setTimeout(() => this.toggleStateProperty('notificationsAreOpen', true), 50);
	}

	closeNotifications(): void {
		this.toggleStateProperty('notificationsAreOpen', false);
		setTimeout(() => this.toggleStateProperty('showNotifications', false), 500);
	}

	setMainNavBadge(navItemId: string, value: string): void {
		const currentState: MainAppState = cloneDeep(this.state$.getValue());

		if (currentState.mainNavItemBadges[navItemId] !== value) {
			currentState.mainNavItemBadges[navItemId] = value;
			this.state$.next(currentState);
		}
	}

	ngOnDestroy() {
		this.state$.complete();
		this.destroy$.next(true);
		// Now let's also unsubscribe from the subject itself:
		this.destroy$.unsubscribe();
	}
}

export interface MainAppState {
	isInit: boolean;
	mainNavIsExpanded: boolean;
	mainNavItemBadges: { [key: string]: string };
	mainSearchShown: boolean;
	showNotifications: boolean;
	notificationsAreOpen: boolean;
	showLoader: boolean;
	showBreadcrumbs: boolean;
	screenMaxWidthBreakpoint: keyof ScreenWidthBreakpoints;
	pageContentMaxWidthBreakpoint: keyof ScreenWidthBreakpoints;
	loadingComponent: LoadingComponentConfig;
	pageMode: EntityPageViewMode;
	wcdTheme?: WcdTheme;
}

export enum ComponentLoading {
	global = 'global',
	settings = 'settings',
}

export interface MainAppStateProperty {
	property: keyof MainAppState,
	value?: any
}

// entityTypeNameKey: is an optional entity type's i18n key, if provided then 'loading {Entity type}...' message will be shown.
// descriptionKey: is optional field, if provided then 'descriptionKey' will be used (only if entityTypeNameKey is empty).
// if both keys are not provided then a default 'loading...' message will be used.
// For example to show 'Loading alert...' use 'entityTypeNameKey: alert_entityType_singularName'.
// For example to show specific loading message use: 'descriptionKey: alert_loading_key' (any valid i18n key).
export interface LoadingComponentConfig {
	id: ComponentLoading;
	description?: string;
	descriptionKey?: string;
	entityTypeNameKey?: string;
	transparent?: boolean;
}
