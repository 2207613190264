import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, OnChanges } from '@angular/core';
import { Params, Router, UrlHandlingStrategy, UrlTree } from '@angular/router';
import { NavItemModel } from '../models/nav-item.model';
import { NgClassInput } from '@wcd/angular-extensions';
import { RoutesService } from '../services/routes.service';
import { isEmpty } from 'lodash-es';

/*
 * renders "inner" router-link element or an "external" anchor element, according to given link params
 */
@Component({
	selector: 'route-link',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="routeLink">
			<span
				[ngSwitch]="linkType"
				role="presentation"
				[ngClass]="classWrapper"
			>
				<a
					*ngSwitchCase="'upgraded'"
					[ngClass]="className"
					[routerLinkActive]="routerLinkActiveCssClass"
					(click)="onClick.emit($event)"
					[routerLink]="upgradedLink"
					[queryParams]="upgradedQueryParams"
					[attr.data-track-id]="dataTrackId"
					[attr.data-link-id]="dataTrackId"
					[attr.id]="linkId"
					data-track-type="Navigation"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-current]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
				<a
					*ngSwitchCase="'legacy'"
					[routerLink]="routeLink.routerLink"
					[queryParams]="routeLink.queryParams"
					[ngClass]="className"
					(click)="goToLegacyRoute($event)"
					data-legacy
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
				<a
					*ngSwitchCase="'external'"
					rel="noopener"
					[target]="routeLink.externalLinkTarget || '_blank'"
					(click)="onClick.emit($event)"
					[ngClass]="className"
					[href]="externalLink || mdatpFromSccUrl"
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
					<wcd-shared-icon
						*ngIf="routeLink.showExternalLinkIcon !== false"
						iconName="popOut"
						class="field-value-icon field-additional-icon"
					></wcd-shared-icon>
				</a>
				<a
					*ngSwitchCase="'sccInternal'"
					rel="noopener"
					[ngClass]="className"
					[href]="routeLink.routerLink"
					[attr.data-link-id]="dataTrackId"
					[attr.data-track-id]="dataTrackId"
					data-track-type="Navigation"
					[attr.id]="linkId"
					[attr.tabindex]="linkTabIndex"
					[attr.role]="linkAriaRole"
					[attr.aria-label]="linkAriaLabel"
					[attr.aria-selected]="linkAriaSelected"
					[attr.aria-level]="linkAriaLevel"
					[attr.aria-posinset]="linkAriaPosinset"
					[attr.aria-setsize]="linkAriaSetsize"
					(keydown.arrowright)="onArrowRight.emit($event)"
					(keydown.arrowleft)="onArrowLeft.emit($event)"
					(keydown.arrowup)="onArrowUp.emit($event)"
					(keydown.arrowdown)="onArrowDown.emit($event)"
					(focus)="onFocus.emit($event)"
					><ng-container *ngTemplateOutlet="innerContent"></ng-container>
				</a>
			</span>
		</ng-container>
		<ng-template #innerContent><ng-content></ng-content></ng-template>
	`,
})
export class RouteLinkComponent implements OnChanges {
	@Input() routeLink: NavItemModel;
	@Input() routerLinkActiveCssClass = 'active';
	@Input() className: string | NgClassInput = '';
	@Input() classWrapper: string | NgClassInput = '';
	@Input() trackId: string;
	@Input() showIcon = true;
	@Input() linkId?: string;
	@Input() linkTabIndex?: number | null;
	@Input() linkAriaRole?: string;
	@Input() linkAriaLabel?: string;
	@Input() linkAriaSelected?: boolean;
	@Input() linkAriaLevel?: number;
	@Input() linkAriaPosinset: number | null = null;
	@Input() linkAriaSetsize: number | null = null;



	@Output() onClick: EventEmitter<MouseEvent> = new EventEmitter();
	@Output() onArrowRight: EventEmitter<KeyboardEvent> = new EventEmitter();
	@Output() onArrowLeft: EventEmitter<KeyboardEvent> = new EventEmitter();
	@Output() onArrowUp: EventEmitter<KeyboardEvent> = new EventEmitter();
	@Output() onArrowDown: EventEmitter<KeyboardEvent> = new EventEmitter();
	@Output() onFocus: EventEmitter<FocusEvent> = new EventEmitter();

	linkType: LinkType;
	mdatpFromSccUrl: string;
	externalLink: string;
	upgradedQueryParams: Params;
	upgradedLink: string[];

	get dataTrackId(): string {
		return this.trackId || `InnerLinkTo_${this.routeLink.id}`;
	}

	constructor(
		private urlHandlingStrategy: UrlHandlingStrategy,
		private router: Router,
		private routesService: RoutesService
	) {}

	routerLinkExists(): boolean {
		return !isEmpty(this.routeLink.routerLink);
	}

	ngOnChanges() {
		if (!this.routeLink) {
			return;
		}

		if (this.routeLink.sccInternalRedirectDefinition) {
			const route = this.routeLink.sccInternalRedirectDefinition(this.routeLink.externalLink);
			if (route && route.startsWith('/user')) {
				this.routeLink = Object.assign(this.routeLink, { route });
				this.linkType = LinkType.upgraded;
				const { upgradedLink, upgradedQueryParams } = getUpgradedRouterLink(this.routeLink);
				this.upgradedQueryParams = upgradedQueryParams;
				this.upgradedLink = upgradedLink;
				return;
			}
		}

		if (this.routeLink.sccInternalRedirectDefinition) {
			this.linkType = LinkType.sccInternal;
			this.routeLink = Object.assign(this.routeLink, {
				routerLink: this.routeLink.sccInternalRedirectDefinition(this.routeLink.externalLink),
			});
			return;
		}

		// Mark link as external if needed
		if (this.routeLink.externalLink) {
			this.externalLink = this.routeLink.externalLink;
			this.linkType = LinkType.external;
			return;
		}

		// Transform link in case we are in SCC
		if (this.routerLinkExists()) {
			const mdatpFromSccLinkConfig = this.routesService.getMdatpFromSccLinkConfig(
				this.getRouterLinkAsString()
			);
			if (mdatpFromSccLinkConfig) {
				if (this.routesService.shouldOpenExternally(mdatpFromSccLinkConfig)) {
					this.linkType = LinkType.external;
					this.mdatpFromSccUrl = this.buildExternalLinkWithParams(mdatpFromSccLinkConfig.url);
					return;
				} else {
					if (mdatpFromSccLinkConfig.url.startsWith('/user')) {
						this.linkType = LinkType.upgraded;
						const { upgradedLink, upgradedQueryParams } = getUpgradedRouterLink(this.routeLink);
						this.upgradedQueryParams = upgradedQueryParams;
						this.upgradedLink = upgradedLink;
						return;
					}
					this.routeLink = Object.assign(this.routeLink, {
						routerLink: [mdatpFromSccLinkConfig.url],
					});
				}
			}
		}

		if (this.routeLink.openInNewTab && this.routerLinkExists()) {
			this.linkType = LinkType.external;
			this.externalLink = this.buildExternalLinkWithParams(this.getRouterLinkAsString());
			return;
		}

		this.linkType = this.getInternalLinkType();
		if (this.linkType === LinkType.upgraded) {
			const { upgradedLink, upgradedQueryParams } = getUpgradedRouterLink(this.routeLink);
			this.upgradedQueryParams = upgradedQueryParams;
			this.upgradedLink = upgradedLink;
		}
	}

	goToLegacyRoute($event: MouseEvent) {
		if (!$event.ctrlKey && !$event.shiftKey && !$event.altKey) {
			this.onClick.emit($event);
			this.router.navigateByUrl(this.routeLink.routerLink[0]);
		}
	}

	getInternalLinkType(): LinkType {
		const urlTree: UrlTree =
			this.routeLink.routerLink && this.router.parseUrl(this.routeLink.routerLink[0]);
		return urlTree && this.urlHandlingStrategy.shouldProcessUrl(urlTree)
			? LinkType.upgraded
			: LinkType.legacy;
	}

	private buildExternalLinkWithParams(routerLink: string): string {
		if (this.routeLink.queryParams) {
			const params = Object.keys(this.routeLink.queryParams).map(
				key => `${key}=${this.routeLink.queryParams[key]}`
			);
			return params.length ? `${routerLink}?${params.join('&')}` : (routerLink as string);
		}
		return routerLink as string;
	}

	private getRouterLinkAsString(): string {
		return Array.isArray(this.routeLink.routerLink)
			? this.routeLink.routerLink[0]
			: this.routeLink.routerLink;
	}
}

enum LinkType {
	upgraded = 'upgraded',
	legacy = 'legacy',
	external = 'external',
	sccInternal = 'sccInternal',
}

export function getUpgradedRouterLink(navItemModel: NavItemModel) {
	const getRouterLink = link => (Array.isArray(link) ? link[0] : link);
	const routeLink = navItemModel.route || getRouterLink(navItemModel.routerLink);

	const routeHasQuery = routeLink ? routeLink.indexOf('?') > 0 : false;
	const result = {
		upgradedLink: null,
		upgradedQueryParams: navItemModel.queryParams,
	};

	if (routeHasQuery) {
		const [url, query] = routeLink.split('?');
		result.upgradedLink = [url];

		const params = (query.match(/([^?=&]+)(=([^&]*))?/g) || []).reduce((result, each) => {
			const [key, value] = each.split('=');
			result[key] = value;
			return result;
		}, {});

		result.upgradedQueryParams = Object.assign({}, navItemModel.queryParams, params);
	} else {
		result.upgradedLink = navItemModel.routerLink;
	}

	return result;
}
