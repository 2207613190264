import { ImpactedEntities } from '@wcd/domain';
import { memoize } from 'lodash';

export const getEntities: (entities: ImpactedEntities, maxItems: number) => ImpactedEntities = memoize(
	(entities: ImpactedEntities, maxItems) => ({
		machines: entities.machines.slice(0, maxItems),
		users: entities.users.slice(0, maxItems),
		mailboxes: entities.mailboxes.slice(0, maxItems),
		apps: entities.apps.slice(0, maxItems),
	})
);

export function areEntitiesTruncated(
	entities: ImpactedEntities,
	entityType: string,
	maxItems: number
): boolean {
	return !!entities[entityType] && entities[entityType].length > maxItems;
}

export function areAnyEntitiesTruncated(entities: ImpactedEntities, maxItems: number): boolean {
	return Object.keys(entities).some(entity => areEntitiesTruncated(entities, entity, maxItems));
}
