import { LocalStorageService } from '@wcd/shared';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../shared/src/lib/services/local-storage.service";
// TODO: Move to it's own package
var HuntingMainLocalStorageService = /** @class */ (function () {
    function HuntingMainLocalStorageService(localStorageService) {
        this.localStorageService = localStorageService;
    }
    HuntingMainLocalStorageService.prototype.getItemFromStorage = function (key) {
        var item = this.localStorageService.getItem(key);
        if (!item) {
            return null;
        }
        return JSON.parse(item);
    };
    HuntingMainLocalStorageService.prototype.removeItemFromStorage = function (key) {
        this.localStorageService.removeItem(key);
    };
    HuntingMainLocalStorageService.prototype.setItemToStorage = function (key, item) {
        this.localStorageService.setItem(key, JSON.stringify(item));
    };
    HuntingMainLocalStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HuntingMainLocalStorageService_Factory() { return new HuntingMainLocalStorageService(i0.ɵɵinject(i1.LocalStorageService)); }, token: HuntingMainLocalStorageService, providedIn: "root" });
    return HuntingMainLocalStorageService;
}());
export { HuntingMainLocalStorageService };
