/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./standard-discovery.component";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../../../projects/shared/src/lib/services/local-storage.service";
import * as i9 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../../insights/services/app-insights.service";
import * as i11 from "../../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i12 from "../../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i13 from "../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i14 from "../../../../admin/integration-settings/advanced-features.service";
import * as i15 from "../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i16 from "@microsoft/paris/dist/lib/paris";
var styles_StandardDiscoveryMessageComponent = [];
var RenderType_StandardDiscoveryMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StandardDiscoveryMessageComponent, data: {} });
export { RenderType_StandardDiscoveryMessageComponent as RenderType_StandardDiscoveryMessageComponent };
function View_StandardDiscoveryMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "fab-message-bar", [["dismissable", "true"]], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.hideProactiveDiscoveryAutoStartMessage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(1, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], dismissButtonAriaLabel: [1, "dismissButtonAriaLabel"], renderActions: [2, "renderActions"], dismissable: [3, "dismissable"] }, { onDismiss: "onDismiss" }), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 8, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, [" ", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "a", [["href", "https://aka.ms/AAbknru"], ["rel", "noopener noreferrer"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["", ""])), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.MessageBarType.info; var currVal_1 = i0.ɵunv(_v, 1, 1, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "dismiss")); var currVal_2 = i0.ɵnov(_v.parent, 7); var currVal_3 = "true"; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.proactiveDiscoveryAutoStartMessage; _ck(_v, 7, 0, currVal_4); var currVal_5 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), "DevicesPageAutoProactiveMessageLearnMore")); _ck(_v, 10, 0, currVal_5); }); }
function View_StandardDiscoveryMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 11, "span", [["class", "wcd-padding-mediumSmall-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "fab-messagebar-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.activateProactiveSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarButtonComponent_0, i1.RenderType_FabMessageBarButtonComponent)), i0.ɵdid(4, 6012928, null, 1, i2.FabMessageBarButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], text: [1, "text"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "fab-messagebar-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.navigateToProactiveSettings() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarButtonComponent_0, i1.RenderType_FabMessageBarButtonComponent)), i0.ɵdid(9, 6012928, null, 1, i2.FabMessageBarButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { text: [0, "text"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 2, { menuItemsDirectives: 1 }), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isAdmin; var currVal_1 = i0.ɵunv(_v, 4, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent, 0), "EnableProactiveDeviceDiscoveryButtonText")); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), "GoToProactiveDeviceDiscoverySettingsButtonText")); _ck(_v, 9, 0, currVal_2); }, null); }
export function View_StandardDiscoveryMessageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_StandardDiscoveryMessageComponent_1)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["actions", 2]], null, 0, null, View_StandardDiscoveryMessageComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showProactiveDiscoveryAutoStartMessage; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_StandardDiscoveryMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "standard-discovery-message", [], null, null, null, View_StandardDiscoveryMessageComponent_0, RenderType_StandardDiscoveryMessageComponent)), i0.ɵdid(1, 49152, null, 0, i6.StandardDiscoveryMessageComponent, [i7.Router, i8.LocalStorageService, i9.FeaturesService, i4.I18nService, i10.AppInsightsService, i11.AuthService, i12.AppContextService, i13.TzDateService, i14.AdvancedFeaturesService, i15.AppConfigService, i16.Paris], null, null)], null, null); }
var StandardDiscoveryMessageComponentNgFactory = i0.ɵccf("standard-discovery-message", i6.StandardDiscoveryMessageComponent, View_StandardDiscoveryMessageComponent_Host_0, {}, {}, []);
export { StandardDiscoveryMessageComponentNgFactory as StandardDiscoveryMessageComponentNgFactory };
