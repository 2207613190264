import { mapMailMessageEntityToAirsEntity } from './airs-entity.mail-message.converter';
export function mapSubmissionMailEntityToAirsEntity(entityData) {
    var baseMailMessageData = mapMailMessageEntityToAirsEntity(entityData);
    var submissionMailData = Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "SubmissionDate" /* SubmissionDate */:
                    acc['reported_on'] = val;
                    break;
                case "ReportType" /* ReportType */:
                    acc['report_type'] = val;
                    break;
                case "SubmissionId" /* SubmissionId */:
                    acc['submission_id'] = val;
                    break;
                case "Submitter" /* Submitter */:
                    acc['submitter'] = val;
                    break;
                case "SenderIp" /* SenderIp */:
                    // note that in MailMessage entity it's 'SenderIP' and not 'SenderIp', hence, we need this case
                    acc['sender_ip'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
    return Object.assign(baseMailMessageData, submissionMailData);
}
