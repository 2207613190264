import { Component, ComponentRef, OnDestroy } from '@angular/core';
import { TitleService } from '../../../../shared/services/title.service';
import { UserRole } from '@wcd/domain';
import { Paris, Repository } from '@microsoft/paris';
import { UserRoleFields } from '../../services/user-role.fields';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { UserRoleEditPanelComponent } from './user-role-edit.panel.component';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { UserRolesService } from '../../services/user-roles.service';
import { AuthService, UserAuthEnforcementMode } from '@wcd/auth';
import { UserRoleActionsService } from '../../services/user-role-actions.service';
import { finalize } from 'rxjs/operators';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { FeaturesService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';

@Component({
	selector: 'user-roles-dataview',
	providers: [UserRoleFields, UserRolesService],
	template: `
		<dataview
			class="wcd-full-height"
			id="user-roles"
			[repository]="repository"
			[allowFilters]="false"
			[allowPaging]="false"
			[allowAdd]="!isUrbacEnabled &&
				authService.currentUser.hasMdeAllowedUserRoleAction(
					userRoleActionsService.userRoleActionsMap.get('systemSettings')
				)
			"
			[disableSelection]="
				!authService.currentUser.hasMdeAllowedUserRoleAction(
					userRoleActionsService.userRoleActionsMap.get('systemSettings')
				)
			"
			[refreshOn]="lastUpdate"
			[showEmptyMessage]="false"
			[hideCommandBarOnEmptyData]="true"
			[setItemActions]="boundSetItemActions"
			(onNewItem)="openEditPanel()"
			(onItemClick)="openEditPanel($event.item)"
			[fields]="userRoleFields.fields"
			[hideHeaderOnEmptyData]="true"
			[queueHeader]="true"
			[padLeft]="false"
			responsiveActionBar="true"
			responsiveLayout="true"
			[removePaddingRight]="isScc"
			[label]="'userRoles.role' | i18n"
			(onTableRenderComplete)="onUserRolesDataViewComplete()"

		>
			<div class="wcd-padding-top" dataview-header>
				{{ 'userRoles.description' | i18n }}
			</div>
			<div class="wcd-full-height wcd-full-width wcd-padding-horizontal" dataview-empty>
				<div>
					<h3 class="wcd-padding-small-bottom">Start using roles?</h3>
					<p class="wcd-padding-small-bottom">
						{{ 'userRoles.empty1' | i18n }}
					</p>
					<div class="wcd-flex-horizontal">
						<wcd-shared-icon iconName="error" class="ms-icon-top-2-fix"> </wcd-shared-icon>
						<span class="wcd-margin-large-bottom wcd-padding-small-left">
							<markdown [data]="'userRoles.empty2' | i18n"></markdown>
						</span>
					</div>
					<button
						(click)="initUserRoles()"
						class="btn btn-primary"
						data-track-id="InitUserRoles"
						data-track-type="Button"
						[rbac]="{ permissions: ['systemSettings'], state: 'disabled' }"
						[disabled]="initUserRolesInProgress"
					>
						Turn on roles
					</button>
				</div>
			</div>
		</dataview>
	`,
})
export class UserRolesDataviewComponent implements OnDestroy {
	repository: Repository<UserRole>;
	editPanel: ComponentRef<UserRoleEditPanelComponent>;
	itemActions: Array<ItemActionModel>;
	lastUpdate: Date;
	initUserRolesInProgress: boolean;
	boundSetItemActions: (userRoles: Array<UserRole>) => Array<ItemActionModel>;
	isScc = sccHostService.isSCC;
	isUrbacEnabled = false;

	constructor(
		public paris: Paris,
		public userRoleFields: UserRoleFields,
		public titleService: TitleService,
		private i18nService: I18nService,
		private userRolesService: UserRolesService,
		private dialogsService: DialogsService,
		public featuresService: FeaturesService,
		public authService: AuthService,
		public userRoleActionsService: UserRoleActionsService,
		private performanceSccService: PerformanceSccService,
		appConfigService: AppConfigService,
	) {
		this.repository = paris.getRepository(UserRole);
		this.itemActions = [
			{
				id: 'delete',
				name: this.i18nService.get('delete'),
				icon: 'delete',
				tooltip: this.i18nService.get('deleteSelectedItems', {
					itemsName: this.repository.entity.pluralName,
				}),
				method: (items: Array<UserRole>) => this.deleteItems(items),
				refreshOnResolve: true,
			},
		].map(itemActionConfig => new ItemActionModel(itemActionConfig));

		this.boundSetItemActions = this.setItemActions.bind(this);
		this.isUrbacEnabled = appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac;


		if (this.isScc && this.isUrbacEnabled) {
			sccHostService.ui.setBanner(sccHostService.ui.render("URbacBanner@wicd-foundational/main"));
		}
	}

	ngOnDestroy(): void {
		if (this.isScc && this.isUrbacEnabled) {
			sccHostService.ui.setBanner(null);
		}
	}

	deleteItems(items: Array<UserRole>): Promise<Array<UserRole>> {
		const itemName: string = (items.length === 1
			? this.repository.entity.singularName
			: this.repository.entity.pluralName
		).toLowerCase();
		return this.dialogsService
			.confirm({
				title: this.i18nService.get('deleteItem', { itemName: itemName }),
				text: this.i18nService.get('deleteConfirm', { itemPluralName: itemName }),
				confirmText: this.i18nService.get('delete'),
			})
			.then((e: ConfirmEvent) => {
				const lastUpdatedIds: { [index: string]: string } = {};
				items.forEach((item: UserRole) => (lastUpdatedIds[item.id] = item.lastUpdated));
				return (
					e.confirmed &&
					this.repository.remove(items, { data: { lastUpdatedByIds: lastUpdatedIds } }).toPromise()
				);
			});
	}

	openEditPanel(userRole?: UserRole): void {
		this.dialogsService
			.showPanel(
				UserRoleEditPanelComponent,
				{
					id: 'user-role-edit-panel',
					type: PanelType.large,
					isModal: true,
					showOverlay: true,
					isBlocking: true,
					disableOverlayClick: true,
					headerIcon: 'users.user',
					headerText: this.i18nService.get(userRole ? 'userRoles.editRole' : 'userRoles.addRole'),
					noBodyPadding: true,
				},
				{
					userRole: userRole,
					onCancel: () => this.editPanel.destroy(),
					onDone: () => {
						this.editPanel.destroy();
						this.lastUpdate = new Date();
					},
				}
			)
			.subscribe((panel: ComponentRef<UserRoleEditPanelComponent>) => {
				this.editPanel = panel;
				panel.onDestroy(() => {
					this.editPanel = null;
				});
			});
	}

	initUserRoles() {
		this.initUserRolesInProgress = true;
		this.userRolesService
			.initUserRoles()
			.pipe(finalize(() => (this.initUserRolesInProgress = false)))
			.subscribe(
				() => {
					this.lastUpdate = new Date();
				},
				error => {
					this.dialogsService.showError({
						title: 'Failed to turn on roles',
						data: error,
					});
				}
			);
	}

	setItemActions(userRoles: Array<UserRole>): Array<ItemActionModel> {
		const globalAdminSelected: boolean = userRoles.some((userRole: UserRole) => userRole.isGlobalAdmin);
		return globalAdminSelected ? [] : this.itemActions;
	}

	onUserRolesDataViewComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('settings-user-roles');
	}
}
