var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { RemediationTask } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { map, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { CalculateRemainingDaysPipe } from '../../../../shared/pipes/calculate-remaining-days.pipe';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../pipes/remediation-task-display-remaining-days.pipe';
import { TruncatePipe } from '../../../../shared/pipes/truncate.pipe';
import { RemediationTaskFixedAssetsPipe } from '../../../pipes/remediation-task-fixed-assets.pipe';
import { TvmColorService, TvmColor } from '../../../services/tvm-color.service';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { zip } from 'rxjs';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
var TvmActiveRemediationTasksWidget = /** @class */ (function (_super) {
    __extends(TvmActiveRemediationTasksWidget, _super);
    function TvmActiveRemediationTasksWidget(reportsService, router, machineGroupsFilterService, tvmTextsService, paris, i18nService, tvmColorService, calculateRemainingDaysPipe, truncatePipe, remediationTaskDisplayRemainingDaysPipe, remediationTaskFixedAssetsPipe) {
        var _this = _super.call(this, reportsService) || this;
        _this.router = router;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.tvmTextsService = tvmTextsService;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.tvmColorService = tvmColorService;
        _this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        _this.truncatePipe = truncatePipe;
        _this.remediationTaskDisplayRemainingDaysPipe = remediationTaskDisplayRemainingDaysPipe;
        _this.remediationTaskFixedAssetsPipe = remediationTaskFixedAssetsPipe;
        return _this;
    }
    Object.defineProperty(TvmActiveRemediationTasksWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'improvementOpportunitiesWidget',
                name: this.i18nService.get('tvm.dashboard.topRemediationActivities'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.activeRemediation.emptyExperienceMessage',
                        isGroupSelected: _this.isMgSelected,
                    });
                },
                NoIconLeftAlign: true,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    var remediationActivities$ = _this.paris.allItems(RemediationTask).pipe(map(function (tasksArray) {
                        var filteredTasks = tasksArray.filter(function (x) { return x.status.value !== 'Completed'; });
                        return _this.getStrechedDonutChartItems(filteredTasks.slice(0, 5));
                    }));
                    return zip(mg$, remediationActivities$).pipe(map(function (_a) {
                        var mg = _a[0], remediationActivities = _a[1];
                        _this.isMgSelected = mg.isFiltering;
                        return remediationActivities;
                    }));
                },
                noDataIcon: FabricIconNames.Trackers,
                minHeight: '466px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmActiveRemediationTasksWidget.prototype.getStrechedDonutChartItems = function (remediationTasks) {
        var _this = this;
        return remediationTasks.map(function (remediationTask) {
            return remediationTask && {
                id: remediationTask.id,
                title: _this.truncatePipe.transform(remediationTask.name, 35),
                valueColor: _this.tvmColorService.colorsMap.get(TvmColor.Blue),
                totalColor: _this.tvmColorService.colorsMap.get(TvmColor.GraphSeriesLight),
                value: _this.remediationTaskFixedAssetsPipe.transform(remediationTask, true),
                total: remediationTask.targetAssets,
                icon: _this.calculateRemainingDaysPipe.transform(remediationTask.dueDate) < 0
                    ? FabricIconNames.Warning
                    : null,
                iconTooltip: _this.displayDays(remediationTask),
                width: '100%',
                valuePrefix: _this.i18nService.get("tvm.remediationTask.monitoring.completed"),
                restPrefix: _this.i18nService.get("tvm.remediationTask.monitoring.incomplete"),
            };
        });
    };
    TvmActiveRemediationTasksWidget.prototype.openRemediationTask = function (id) {
        this.router.navigate(['remediation/remediation-activities', id]);
    };
    TvmActiveRemediationTasksWidget.prototype.openRemediationTasksScreen = function () {
        this.router.navigate(['remediation/remediation-activities']);
    };
    TvmActiveRemediationTasksWidget.prototype.displayDays = function (remediationTask) {
        return this.remediationTaskDisplayRemainingDaysPipe.transform(remediationTask, this.calculateRemainingDaysPipe.transform(remediationTask.dueDate));
    };
    return TvmActiveRemediationTasksWidget;
}(ReportWidgetComponent));
export { TvmActiveRemediationTasksWidget };
