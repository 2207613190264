/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "@angular/common";
import * as i4 from "./user-contact-details.component";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
var styles_UserContactDetailsComponent = [];
var RenderType_UserContactDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserContactDetailsComponent, data: {} });
export { RenderType_UserContactDetailsComponent as RenderType_UserContactDetailsComponent };
function View_UserContactDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "Mail"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["data-track-id", "ContactDetails__Email"], ["data-track-type", "ExternalLink"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "Mail"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ("mailto:" + _v.context.ngIf); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.ngIf; _ck(_v, 6, 0, currVal_2); }); }
function View_UserContactDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "Phone"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["data-track-id", "ContactDetails__Phone"], ["data-track-type", "ExternalLink"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "Phone"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = ("tel:" + _v.context.ngIf); _ck(_v, 5, 0, currVal_1); var currVal_2 = _v.context.ngIf; _ck(_v, 6, 0, currVal_2); }); }
function View_UserContactDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "SkypeForBusinessLogo"]], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "a", [["data-track-id", "ContactDetails__Skype"], ["data-track-type", "ExternalLink"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(6, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "SkypeForBusinessLogo"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.skypeUrl; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.skypeUrlText; _ck(_v, 6, 0, currVal_2); }); }
function View_UserContactDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "ul", [["class", "unstyled wcd-margin-none-all wcd-padding-none-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserContactDetailsComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserContactDetailsComponent_3)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserContactDetailsComponent_4)), i0.ɵdid(9, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contactDetails.email; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.contactDetails.phone; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.skypeUrl; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_UserContactDetailsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_UserContactDetailsComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contactDetails; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserContactDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "user-contact-details", [], null, null, null, View_UserContactDetailsComponent_0, RenderType_UserContactDetailsComponent)), i0.ɵdid(1, 573440, null, 0, i4.UserContactDetailsComponent, [i0.ChangeDetectorRef, i5.Paris, i6.WicdSanitizerService], null, null)], null, null); }
var UserContactDetailsComponentNgFactory = i0.ɵccf("user-contact-details", i4.UserContactDetailsComponent, View_UserContactDetailsComponent_Host_0, { user: "user" }, {}, []);
export { UserContactDetailsComponentNgFactory as UserContactDetailsComponentNgFactory };
