import { EntityConfig, DataSet } from '@microsoft/paris';
import { Cache, DefaultApiVersion, TvmEndPoint } from './commonEndPointUtils';

const BaseUrl = config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.AuthenticatedScans}`;

export const TvmAuthenticatedScanSharedEntityConfigurations: Partial<EntityConfig<any>> = {
	cache: Cache,
	parseDataSet: (response: any): DataSet<any> => ({
		count: response.numOfResults,
		items: response.results,
	}),
	readonly: true,
	customHeaders: DefaultApiVersion,
	baseUrl: BaseUrl,
	saveMethod: 'POST',
};
