var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Subject, of } from 'rxjs';
import { OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { EmailNotificationCompletedComponent } from './email-notification-completed.component';
import { catchError, map } from 'rxjs/operators';
var EmailNotificationReviewStepComponent = /** @class */ (function (_super) {
    __extends(EmailNotificationReviewStepComponent, _super);
    function EmailNotificationReviewStepComponent(i18n, dialogsService) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.dialogsService = dialogsService;
        _this.saving$ = new Subject();
        _this.createRule = function () {
            _this.setBackButtonEnabled(false);
            _this.setStepValidation(false);
            _this.saving$.next(true);
            return _this.saveRuleFunction(_this.data).pipe(map(function () {
                _this.markWizardCompleted(EmailNotificationCompletedComponent);
                _this.saving$.next(false);
                return true;
            }), catchError(function () {
                _this.dialogsService.showSnackbar({
                    icon: 'ErrorBadge',
                    text: _this.i18n.strings.email_notification_summaryStep_creationError,
                    className: 'color-box-failureBackground',
                });
                _this.setBackButtonEnabled(true);
                _this.setStepValidation(true);
                _this.saving$.next(false);
                return of(false);
            }));
        };
        return _this;
    }
    EmailNotificationReviewStepComponent.prototype.ngOnInit = function () {
        this.setOnNext(this.createRule);
        this.setStepValidation(true);
    };
    return EmailNotificationReviewStepComponent;
}(WizardBaseStep));
export { EmailNotificationReviewStepComponent };
