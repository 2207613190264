
		<div
			#overlay
			class="dialog-overlay"
			(click)="overlayClicked($event)"
			[id]="settings.id"
			[class.centered]="isCentered"
			[class.horizontal-centered]="isHorizontalCentered"
			[class.dialog-visible]="isInit"
			[class.dialog-disabled]="settings && settings.enableOverlay === false"
			[class.transparent]="settings && settings.showOverlay === false"
		>
			<div
				class="dialog wcd-flex-vertical wcd-scroll-vertical"
				[ngStyle]="dialogStyle"
				#dialog
				[class.ie11Patch]="ieFixedPosition"
				[class.ieFixedPosition]="ieFixedPosition"
				cdkTrapFocus
				role="dialog"
				[attr.aria-label]="ariaLabel ? ariaLabel : settings?.title"
			>
				<header class="dialog-header wcd-flex-horizontal wcd-flex-none" *ngIf="settings?.title">
					<h4 class="wcd-flex-1 dialog-title ie11Patch ie11Flex">
						<wcd-shared-icon *ngIf="settings.titleIcon" [iconName]="settings.titleIcon">
						</wcd-shared-icon>
						{{ settings.title }}
					</h4>
					<div class="wcd-flex-none dialog-header-controls">
						<button autofocus (click)="close.emit()" [attr.title]="'buttons_close' | i18n">
							<wcd-shared-icon [ariaLabel]="''" iconName="clear"> </wcd-shared-icon>
						</button>
					</div>
				</header>
				<div
					class="wcd-flex-1 dialog-body ie11Patch ie11Flex"
					[ngClass]="settings && settings.className"
				>
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	