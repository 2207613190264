import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Machine } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';

@Component({
	selector: 'machine-logged-on-users-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<machine-logged-on-users class="wcd-auto-height-responsive wcd-flex-1 wcd-flex-vertical wcd-full-height" [machine]="machine">
			</machine-logged-on-users>
		</wcd-panel>
	`,
})
export class MachineLoggedOnUsersPanelComponent extends PanelContainer {
	@Input() machine: Machine;

	constructor(router: Router) {
		super(router);
	}
}
