import { Injectable, OnDestroy } from '@angular/core';
import { DataSet, Paris } from '@microsoft/paris';
import { Observable, Subject } from 'rxjs';
import { AirsEntity, AirsEntityStatusCount, AirsEntityType, AirsEntityTypeResults } from '@wcd/domain';
import { map, shareReplay, takeUntil } from 'rxjs/operators';

@Injectable()
export class AirsEntitiesLoaderService implements OnDestroy {
	private entities$: Observable<DataSet<AirsEntity>>;
	private stop$ = new Subject<void>();
	constructor(private paris: Paris) {}

	private setEntities(options: { [index: string]: any }) {
		this.entities$ = this.paris
			.getRepository(AirsEntity)
			.query({ where: { ...options, isListView: true } })
			.pipe(
				shareReplay(1),
				takeUntil(this.stop$)
			);
	}

	getEntitiesForInvestigation(options: { [index: string]: any }): Observable<DataSet<AirsEntity>> {
		if (!this.entities$) {
			this.setEntities(options);
		}
		return this.entities$;
	}

	getEntitiesResultsForInvestigation(options: {
		[index: string]: any;
	}): Observable<DataSet<AirsEntityTypeResults>> {
		return this.getEntitiesForInvestigation(options).pipe(
			map(entities => {
				const res =
					entities &&
					entities.items &&
					entities.items.reduce(
						(acc, e) => {
							// mailbox is an asset, not evidence. this should be removed once Office remove it from the results
							if (e.type.id === AirsEntityType.Mailbox) {
								return acc;
							}
							const currRes = acc.find(r => r.entityType === e.type);
							if (!currRes) {
								acc.push(
									new AirsEntityTypeResults({
										entityType: e.type,
										results: [
											new AirsEntityStatusCount({
												_status: e.verdict,
												remediationStatus: e.remediationStatus,
												count: 1,
											}),
										],
									})
								);
							} else {
								const currStatus = currRes.results.find(
									d => d.status === e.remediationStatus || d.status === e.verdict
								);
								if (currStatus) {
									currStatus.count++;
								} else {
									currRes.results.push(
										new AirsEntityStatusCount({
											_status: e.verdict,
											remediationStatus: e.remediationStatus,
											count: 1,
										})
									);
								}
							}
							return acc;
						},
						[] as Array<AirsEntityTypeResults>
					);
				return {
					count: (res && res.length) || 0,
					items: res,
				};
			})
		);
	}

	stop() {
		this.stop$.next();
	}

	ngOnDestroy() {
		this.stop$.next();
		this.stop$.complete();
	}
}
