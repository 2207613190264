/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./network-device-icon.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./network-device-icon.component";
var styles_NetworkDeviceIconComponent = [i0.styles];
var RenderType_NetworkDeviceIconComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NetworkDeviceIconComponent, data: {} });
export { RenderType_NetworkDeviceIconComponent as RenderType_NetworkDeviceIconComponent };
export function View_NetworkDeviceIconComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "wcd-margin-xsmall-right tvm-network-device-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_NetworkDeviceIconComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "network-device-icon", [], null, null, null, View_NetworkDeviceIconComponent_0, RenderType_NetworkDeviceIconComponent)), i1.ɵdid(1, 49152, null, 0, i2.NetworkDeviceIconComponent, [], null, null)], null, null); }
var NetworkDeviceIconComponentNgFactory = i1.ɵccf("network-device-icon", i2.NetworkDeviceIconComponent, View_NetworkDeviceIconComponent_Host_0, {}, {}, []);
export { NetworkDeviceIconComponentNgFactory as NetworkDeviceIconComponentNgFactory };
