import { Type } from '@angular/core';
import {
	ReadOnlyIdentifiable,
	ReadOnlyIdentifiableConfig,
} from '../../data/models/readonly-identifiable.model';
import {
	ReportWidgetsLayoutColumnConfig,
	ReportWidgetsLayoutColumnModel,
} from './report-widgets-layout.column.model';
import { ReportWidgetsLayoutRowConfig, ReportWidgetsLayoutRowModel } from './report-widgets-layout.row.model';
import { ReportTimeRange } from './report-time-range.interface';

const DEFAULT_REFRESH_RATE = 60 * 1000;
const MIN_REFRESH_RATE = 5 * 1000;

export const M365_DASHBOARD_WIDGET_CLASS = 'm365-dashboard-report';
export type ReportOverflow = 'all' | 'vertical' | 'horizontal' | 'none';

export class ReportModel extends ReadOnlyIdentifiable<string> {
	titleIcon: string;
	className: string;
	columns: Array<ReportWidgetsLayoutColumnModel>;
	rows: Array<ReportWidgetsLayoutRowModel>;
	allowRangeSelect: boolean;
	rangeConfig: ReportRangeConfig;
	allowExport: boolean;
	maxWidth: number;
	params: { [index: string]: any };
	autoRefresh: boolean;
	refreshRate: number;
	fitToHeight: boolean;
	titleNameKey: string;
	title: string;
	descriptionKey: string;
	emptyDashboardComponent: Type<any>;
	enablePadding: boolean;
	enableHorizontalPadding: boolean;
	transparent: boolean;
	overflow: ReportOverflow;
	refreshOnQueryParamsChange: boolean;
	showLastLoadTime: boolean;

	constructor(config: ReportConfig) {
		super(config);

		this.columns = config.columns
			? config.columns.map(
					(columnConfig: ReportWidgetsLayoutColumnConfig) =>
						new ReportWidgetsLayoutColumnModel(columnConfig)
			  )
			: [];
		this.rows = config.rows
			? config.rows.map(
					(rowConfig: ReportWidgetsLayoutRowConfig) => new ReportWidgetsLayoutRowModel(rowConfig)
			  )
			: [];

		this.allowExport = config.allowExport !== false;
		this.allowRangeSelect = config.allowRangeSelect !== false;
		this.rangeConfig = config.rangeConfig;
		this.className = config.className;
		this.titleIcon = config.titleIcon;
		this.params = config.params;
		this.titleNameKey = config.titleNameKey;
		this.title = config.title;
		this.descriptionKey = config.descriptionKey;
		this.transparent = !!config.transparent;

		this.autoRefresh = config.autoRefresh !== false;

		if (config.maxWidth) this.maxWidth = Math.max(100, config.maxWidth);

		this.refreshRate = Math.max(MIN_REFRESH_RATE, config.refreshRate || DEFAULT_REFRESH_RATE);
		this.fitToHeight = config.fitToHeight === true;
		this.emptyDashboardComponent = config.emptyDashboardComponent;
		this.enablePadding = config.enablePadding !== false;
		this.enableHorizontalPadding = config.enableHorizontalPadding === true;
		this.overflow = config.overflow || 'all';
		this.refreshOnQueryParamsChange = config.refreshOnQueryParamsChange !== false;
		this.showLastLoadTime = config.showLastLoadTime !== false;
	}
}

export interface ReportConfig extends ReadOnlyIdentifiableConfig {
	/**
	 * Allow exporting the dashboard.
	 * @default true
	 */
	allowExport?: boolean;
	allowRangeSelect?: boolean;
	rangeConfig?: ReportRangeConfig;
	className?: string;
	columns?: Array<ReportWidgetsLayoutColumnConfig>;
	maxWidth?: number;
	params?: { [index: string]: any };
	rows?: Array<ReportWidgetsLayoutRowConfig>;
	title?: string;
	titleNameKey?: string;
	descriptionKey?: string;
	titleIcon?: string;

	/**
	 * Refresh interval (in ms) for the report data. Every time
	 * the refresh interval passes, the data in all widgets will be reloaded.
	 * @default 60 * 1000
	 */
	refreshRate?: number;

	/**
	 * Determines whether or not the widgets inside the report should have
	 * their data automatically refreshed every `refreshRate` milliseconds
	 * @default true
	 */
	autoRefresh?: boolean;

	transparent?: boolean;

	/**
	 * If true (which is the default), when query params change in the URL, the report reloads data for the widgets.
	 * @default true
	 */
	refreshOnQueryParamsChange?: boolean;

	/**
	 * Fit the dashboard to the height of the container.
	 * @default false
	 */
	fitToHeight?: boolean;
	emptyDashboardComponent?: Type<any>;

	/**
	 * Whether the report should have surrounding padding.
	 * @default true
	 */
	enablePadding?: boolean;

	/**
	 * Whether the report should have surrounding horizontal padding.
	 * @default false
	 */
	enableHorizontalPadding?: boolean;

	/**
	 * Should the report overflow and display scrolling indicators.
	 * @default 'all'
	 */
	overflow?: ReportOverflow;

	/**
	 * Whether to show the 'Last load time' on the top of the report, if `showControls` is `true`.
	 */
	showLastLoadTime?: boolean;
}

export interface ReportRangeConfig {
	allowExactTimeSelection?: boolean;
	earliestDateAllowedFrom?: Date;
	latestDateAllowedFrom?: Date;
	earliestDateAllowedTo?: Date;
	latestDateAllowedTo?: Date;
	timeRangeValues?: ReadonlyArray<ReportTimeRange>;
	allowCustomTimeRange?: boolean;
	defaultRange?: ReportTimeRange;
}
