/* tslint:disable:template-click-events-have-key-events */
import { Component, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { ReportModel } from '../../reports/models/report.model';
import { getTvmDashboardReport } from './tvm.dashboard.report';
import { FeaturesService, Feature } from '@wcd/config';
import { ReportComponent } from '../../reports/components/report.component';
import { TvmMachineGroupsFilterService } from '../services/tvm-machine-groups-filter.service';
import { TvmSecurityRecommendationsComponent } from './security-recommendations/security-recommendations.component';
import { Subscription, Observable, fromEvent } from 'rxjs';
import { map, debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { MessageBarType } from 'office-ui-fabric-react';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'tvm-dashboard',
	templateUrl: './tvm.dashboard.component.html',
	styleUrls: ['./tvm.dashboard.component.scss'],
})
export class TvmDashboardComponent implements OnDestroy, AfterViewInit {
	@ViewChild(ReportComponent, { static: false }) reportComponent: ReportComponent;
	@ViewChild(TvmSecurityRecommendationsComponent, { static: false })
	securityRecommendationsComponent: TvmSecurityRecommendationsComponent;
	enableThreatCampaigns: boolean;
	containThreatCampaigns: boolean;
	tvmDashboardReport: ReportModel;
	refresh$: Observable<boolean>;
	focus: boolean;

	showMessageBar: Boolean = true;
	readonly MessageBarType = MessageBarType;

	private _subscription: Subscription;

	constructor(
		featuresService: FeaturesService,
		machineGroupsFilterService: TvmMachineGroupsFilterService,
		private router: Router,
		private liveAnnouncer: LiveAnnouncer,
		private i18nService: I18nService
	) {
		this.tvmDashboardReport = getTvmDashboardReport(featuresService);

		this._subscription = machineGroupsFilterService.machineGroupsFilterChange$.subscribe(
			machineGroupsFilterData => {
				if (machineGroupsFilterData) {
					this.reportComponent.reloadWidgetsData(true);
					this.securityRecommendationsComponent.refreshData();
				}
			}
		);

		this.showMessageBar = featuresService.isEnabled(Feature.TvmDisplayMessageBar);
		// Once the message bar is removed the focus should be moved to the next actionable item on page
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	ngAfterViewInit() {
		this.liveAnnouncer.announce(this.i18nService.get('tvm_dashboard_widgets_loaded'));
		const dashboard = document.querySelector('.tvm-dashboard-container');
		this.refresh$ = fromEvent(dashboard, 'scroll').pipe(
			debounceTime(100),
			map(() => true)
		);

		setTimeout(() => (this.focus = true));
	}

	hideMessage() {
		this.showMessageBar = false;
	}

	navigateToASRPosture() {
		this.router.navigate([`/security-recommendations`], {
			queryParams: {
				search: `Attack surface reduction`,
				showMessageBar: false,
			},
		});
	}
}
