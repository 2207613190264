import { ChangeDetectorRef, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { WcdIconName, isWcdIconName } from '@wcd/icons-font';

@Component({
	selector: 'wcd-icon',
	template: `
		<i class="wcd-icons--{{ iconName }}" [ngClass]="className"></i>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WcdIconComponent {
	private _iconName: WcdIconName;

	@Input()
	set iconName(value: WcdIconName) {
		if (!isWcdIconName(String(value))) throw new Error(`Unknown WCD icon name, '${String(value)}'.`);

		this._iconName = value;
	}

	get iconName(): WcdIconName {
		return this._iconName;
	}

	@Input() className: string;

	constructor(public readonly changeDetectorRef: ChangeDetectorRef) {}
}
