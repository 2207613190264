<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="RecommendationExceptionCreation">

	<div *ngIf="!isExceptionsPerRbacFeatureEnabled || existingRecommendationExceptions; else loading" class="wcd-full-height wcd-flex-vertical wcd-padding-large-horizontal wcd-padding-vertical">

		<header class="wcd-flex-none wcd-flex-horizontal wcd-margin-large-bottom">
			<h3 class="side-panel-title wcd-text-overflow-ellipsis">
				<p>
					{{('tvm.securityRecommendation.recommendationExceptionCreation.title' | i18n)}}
				</p>
				<p>
					{{recommendationExceptionTitle}}
				</p>
			</h3>
		</header>

		<div class="wcd-flex-1 wcd-scroll-vertical tvm-list tvm-list--no-columns">

			<div *ngIf="warningText || shouldDisable" class="tvm-list__block_item">
				<fab-message-bar
					[messageBarType]="_messageBarType.warning" [styles]="messageBarStyles">
					<div class="spaced">{{warningText || defaultWarningText}}<a [href]="exceptionLink" *ngIf="shouldLinkToException">{{'tvm.securityRecommendation.remediationTaskCreation.viewException' | i18n}}</a></div>
				</fab-message-bar>
			</div>

			<div class="tvm-list__block_item">
				{{recommendationExceptionCreationDescription}}
			</div>

			<ng-container *ngIf="isExceptionsPerRbacFeatureEnabled">
				<div class="tvm-list__header" [ngClass]="{ 'disabled': shouldDisable }">
					{{'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_title' | i18n}}
				</div>
				<div class="tvm-list__block_item">
					<radio-list
						*ngIf="isGlobalExceptionCreationAllowed"
						[values]="exceptionCreationTypes"
						name="exception-creation-type-selection"
						[(ngModel)]="_selectedExceptionCreationType"
						[disabled]="!isUserAllowedToCreateExceptions">
						<ng-template #radioContent let-creationType>
							<ng-container *ngIf="creationType.id === 'global'; else byMachineGroupExceptionCreation">
								<p [ngClass]="{ 'disabled': existingActiveGlobalException }">
									{{'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_global_description' | i18n}}
								</p>
							</ng-container>
						</ng-template>
					</radio-list>

					<ng-container *ngIf="!isGlobalExceptionCreationAllowed">
						<ng-container *ngTemplateOutlet="byMachineGroupExceptionCreation"></ng-container>
					</ng-container>

					<ng-template #byMachineGroupExceptionCreation>
						<p [ngClass]="{ 'disabled': allRbacGroupsHaveAnActiveException }">
							{{'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_byMachineGroup_description' | i18n}}
						</p>
						<div class="tvm-remediation-dropdown">
							<machine-groups-dropdown
								[ariaLabel]="'tvm_securityRecommendation_recommendationExceptionCreation_exceptionCreationType_byMachineGroup' | i18n"
								[isDisabled]="shouldDisabledMachineGroupsDropdown || !isUserAllowedToCreateExceptions"
								[disabledMachineGroups]="existingActiveExceptionsRbacGroupIds"
								(groupChosen)="machineGroupSelected($event)">
							</machine-groups-dropdown>
						</div>
					</ng-template>
				</div>
			</ng-container>

			<div class="tvm-list__header" [ngClass]="{ 'disabled': shouldDisable }">{{'tvm.securityRecommendation.recommendationExceptionCreation.justification' | i18n}}</div>
			<div class="tvm-list__block_item">
				<fancy-select [(ngModel)]="exceptionJustification"
					[ariaLabel]="'tvm.securityRecommendation.recommendationExceptionCreation.justification' | i18n"
					[formatLabel]="getJustificationLabel"
					[labelClass]="'dropdown-width-medium'"
					[isBordered]="true"
					class="command-bar-item-dropdown"
					[ngClass]="{ 'disabled': shouldDisable }"
					(ngModelChange)="setJustificationContext()"
					[values]="exceptionJustificationList"
					[placeholder]="'tvm.securityRecommendation.recommendationExceptionCreation.justificationPlaceHolder' | i18n"
					[isDisabled] ="shouldDisable || !isUserAllowedToCreateExceptions">
				</fancy-select>
				<p role="alert" *ngIf="justificationNote" class="wcd-margin-small-top" [attr.aria-label]="justificationNote">
					<span class="wcd-font-size-base wcd-margin-small-right"><fab-icon iconName="Info"></fab-icon></span>{{ justificationNote }}
				</p>
			</div>

			<div class="tvm-list__header" [ngClass]="{ 'disabled': shouldDisable }">{{'tvm.securityRecommendation.recommendationExceptionCreation.justificationContext.title' | i18n}}</div>
			<div class="tvm-list__block_item">
				<textarea
					required
					class="comment-input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[(ngModel)]="justificationContext"
					[ngClass]="{ 'disabled': shouldDisable }"
					name="notes-text"
					maxlength="1000"
					rows="4"
					[disabled]="isSaving || shouldDisable || !exceptionJustification || !isUserAllowedToCreateExceptions"
					[attr.aria-label]="'tvm.securityRecommendation.recommendationExceptionCreation.justificationContext.title' | i18n">
				</textarea>
			</div>

			<div class="tvm-list__header" [ngClass]="{ 'disabled': shouldDisable }">{{exceptionDurationText}}</div>
			<div class="tvm-list__block_item date-picker">
				<fancy-select
					[ariaLabel]="exceptionDurationText"
					*ngIf="chosenDate !== dateCustomFromNow;else date_picker"
					[(ngModel)]="chosenDate"
					[formatLabel]="getTimeLabel"
					[labelClass]="'dropdown-width-small'"
					class="command-bar-item-dropdown"
					[isBordered]="true"
					[ngClass]="{ 'disabled': shouldDisable }"
					[values]="datesList"
					[isDisabled] ="isSaving || shouldDisable || !isUserAllowedToCreateExceptions"
					[placeholder]="'tvm.securityRecommendation.recommendationExceptionCreation.expiringOnPlaceHolder' | i18n">
				</fancy-select>
				<ng-template #date_picker>
					<extended-fab-date-picker
						[ariaLabel]="exceptionDurationText"
						[disabled] ="isSaving || shouldDisable || !isUserAllowedToCreateExceptions"
						[format]="formatDate"
						[isMonthPickerVisible]="true"
						[minDate]="minDate"
						[maxDate]="maxDate"
						(onSelectDate)="onSelectDate($event)"
						[placeHolder]="'tvm.securityRecommendation.recommendationExceptionCreation.expiringOnPlaceHolder' | i18n"
					></extended-fab-date-picker>
				</ng-template>
			</div>
		</div>

		<footer class="wcd-flex-none wcd-flex-horizontal wcd-border-top">
			<div class="wcd-flex-1 wcd-padding-small-top">
				<fab-primary-button
					data-track-id="Save"
					data-track-type="Button"
					[attr.data-track-value]="exceptionJustification"
					(onClick)="submitRequest()"
					[disabled]="isSaving || shouldDisable || !(chosenDate.date) || !exceptionJustification ||
							!justificationContext || (isExceptionsPerRbacFeatureEnabled && !selectedExceptionCreationType) || !isUserAllowedToCreateExceptions">
			<i class="loader-icon" [hidden]="!isSaving"></i>
			{{'tvm.securityRecommendation.recommendationExceptionCreation.submit' | i18n}}
				</fab-primary-button>
			</div>
		</footer>
	</div>

	<ng-template #loading>
		<fab-spinner
		[size]="SpinnerSize.large"
		[label]="'common_loading' | i18n">
		</fab-spinner>
	</ng-template>

</wcd-panel>
