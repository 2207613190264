/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "@angular/common";
import * as i4 from "./assignee-picker.component";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../projects/config/src/lib/services/preferences.service";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../../../../../projects/auth/src/lib/services/auth.service";
var styles_AssigneePickerComponent = [".assignee-selected[_ngcontent-%COMP%]     input {\n\t\t\t\t\twidth: 0;\n\t\t\t\t}"];
var RenderType_AssigneePickerComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AssigneePickerComponent, data: {} });
export { RenderType_AssigneePickerComponent as RenderType_AssigneePickerComponent };
function View_AssigneePickerComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-padding-small-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "fab-spinner", [], null, null, null, i1.View_FabSpinnerComponent_0, i1.RenderType_FabSpinnerComponent)), i0.ɵdid(3, 5816320, null, 0, i2.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { size: [0, "size"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.small; _ck(_v, 3, 0, currVal_0); }, null); }
function View_AssigneePickerComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "fab-action-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSelectedAssignees() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabActionButtonComponent_0, i1.RenderType_FabActionButtonComponent)), i0.ɵdid(2, 6012928, null, 1, i2.FabActionButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], styles: [1, "styles"], iconProps: [2, "iconProps"] }, null), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; var currVal_1 = _co.buttonStyles; var currVal_2 = _co.iconProps; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_AssigneePickerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "div", [["class", "wcd-flex"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "span", [["class", "wcd-padding-small-left"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AssigneePickerComponent_2)), i0.ɵdid(7, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(0, [["loaded", 2]], null, 0, null, View_AssigneePickerComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.isLoading; var currVal_2 = i0.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_v.context.item.secondaryText == _co.currentUserName) ? _v.context.item.secondaryText : _v.context.item.text); _ck(_v, 4, 0, currVal_0); }); }
export function View_AssigneePickerComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [], [[2, "assignee-selected", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "fab-people-picker", [["className", "people-picker"]], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.updateSelectedAssignees($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabPeoplePickerComponent_0, i1.RenderType_FabPeoplePickerComponent)), i0.ɵdid(4, 5881856, null, 0, i2.FabPeoplePickerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { resolveDelay: [0, "resolveDelay"], className: [1, "className"], disabled: [2, "disabled"], selectedItems: [3, "selectedItems"], inputProps: [4, "inputProps"], onEmptyInputFocus: [5, "onEmptyInputFocus"], onResolveSuggestions: [6, "onResolveSuggestions"], pickerSuggestionsOptions: [7, "pickerSuggestionsOptions"], renderItem: [8, "renderItem"] }, { onChange: "onChange" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(0, [["peoplePickerItemRender", 2]], null, 0, null, View_AssigneePickerComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.peoplePickerResolveDelay; var currVal_2 = "people-picker"; var currVal_3 = (!!_co.selectedAssignees.length || _co.isDisabled); var currVal_4 = _co.selectedAssignees; var currVal_5 = _co.assignInputProps; var currVal_6 = _co.onInputFocus; var currVal_7 = _co.peoplePickerInputChanged.bind(_co); var currVal_8 = _co.assigneesSuggestionsOptions; var currVal_9 = i0.ɵnov(_v, 8); _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !!_co.selectedAssignees.length; _ck(_v, 1, 0, currVal_0); }); }
export function View_AssigneePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "assignee-picker", [], null, null, null, View_AssigneePickerComponent_0, RenderType_AssigneePickerComponent)), i0.ɵdid(1, 638976, null, 0, i4.AssigneePickerComponent, [i5.Paris, i0.ChangeDetectorRef, i6.PreferencesService, i7.I18nService, i8.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssigneePickerComponentNgFactory = i0.ɵccf("assignee-picker", i4.AssigneePickerComponent, View_AssigneePickerComponent_Host_0, { isDisabled: "isDisabled", assignedTo: "assignedTo" }, { onSelectAssignee: "onSelectAssignee" }, []);
export { AssigneePickerComponentNgFactory as AssigneePickerComponentNgFactory };
