
		<span
			[id]="listId"
			[ngClass]="{ 'disabled-not-allowed': isDisabled }"
			[wcdTooltip]="isDisabled && disableReason"
		>
			<label *ngIf="dropdownLabel" class="dropdown-label">{{ dropdownLabel }}</label>
			<wcd-dropdown
				[position]="openMenuPosition"
				[buttonText]="useValueAsButtonText && value ? getLabel(value) : placeholder"
				[buttonClass]="labelClass"
				[buttonTooltip]="tooltip"
				[buttonIconClass]="getAttributeValue(value, iconColorClassName)"
				[buttonImage]="getAttributeValue(value, image) || buttonImage"
				[buttonIcon]="getAttributeValue(value, icon) || buttonIcon"
				[iconButtonOnly]="iconButtonOnly"
				(focus)="focus.emit()"
				(blur)="blur.emit()"
				(exit)="exit.emit()"
				(onToggle)="onToggle($event)"
				[closeOnClick]="true"
				[disabled]="isDisabled"
				[showIconDropdown]="showIconDropdown"
				[isBordered]="isBordered"
				[isFullWidth]="isFullWidth"
				[ariaLabel]="ariaLabel"
				[navigateUsingArrowKeysOnly]="navigateUsingArrowKeysOnly"
				[navigateUsingTab]="navigateUsingTab"
				[optionAriaSetSize]="(values?.length || 0) + (specialValues?.length || 0)"
				[optionAriaRole]=" ['combobox','button'].includes(dropdownAreaRole) ? null : 'option'"
				[optionAriaPositionInSet]="ariaPositinInSet"
				[ariaRole]="dropdownAreaRole"
				[buttonId]="buttonId"
				[ariaHaspopup]="ariaHaspopup"
				[focusOnInit]="focusOnInit"
				[required]="required"
			>
				<ul
					#dropDownContent
					class="dropdown-list"
					[attr.data-test-id]="listId + '-menu'"
					role="listbox"
					[attr.aria-labelledby]="buttonId + '_ariaLabel'"
				>
					<li
						*ngFor="let _value of values; let valueIndex = index"
						(click)="selectValue(_value, valueIndex)"
						[wcdTooltip]="getAttributeValue(_value, itemTooltip)"
						[attr.data-test-id]="getAttributeValue(_value, itemTestId)"
						[class.selected]="highlightSelected && _value === value"
						[ngClass]="getAttributeValue(_value, optionClass) || ''"
						#accessibleListItem
						tabindex="0"
						[attr.aria-selected]="_value === value"
						role="option"
						[attr.aria-posinset]="valueIndex + 1"
						[attr.aria-setsize]="values.length"
					>
						<div class="dropdown-list-item-contents">
							<wcd-shared-icon
								*ngIf="icon"
								[iconName]="getAttributeValue(_value, icon)"
								class="dropdown-list-icon"
								[ngClass]="getAttributeValue(_value, iconColorClassName)"
								aria-hidden="true"
							>
							</wcd-shared-icon>
							<img
								*ngIf="image"
								[attr.src]="getAttributeValue(_value, image)"
								class="dropdown-list-icon"
							/>
							{{ getLabel(_value) }}
						</div>
					</li>
					<li
						*ngFor="let specialValue of specialValues; let specialValueIndex = index"
						(click)="onSelectSpecialValue(specialValue, values.length + specialValueIndex)"
						[attr.data-test-id]="'btn-' + specialValue.id"
						#accessibleListItem
						tabindex="0"
						[attr.aria-selected]="_value === value"
						role="option"
						[attr.aria-posinset]="values.length + specialValueIndex + 1"
						[attr.aria-setsize]="values.length + specialValues.length"
					>
						<div class="dropdown-list-item-contents">
							<wcd-shared-icon
								*ngIf="specialValue.icon"
								[iconName]="specialValue.icon"
								class="dropdown-list-icon"
							>
							</wcd-shared-icon>
							{{ specialValue.name }}
						</div>
					</li>
				</ul>
			</wcd-dropdown>
		</span>
	