import {
	ApiCall,
	ApiCallModel,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
} from '@microsoft/paris';
import { AadUser } from './aad.user.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { AadUserMcasDetails } from './aad.user-mcas-details.entity';

@EntityRelationship({
	sourceEntity: AadUser,
	dataEntity: AadUserMcasDetails,
	getRelationshipData: (aadUser: AadUser) => ({ id: aadUser.aadUserId }),
	endpoint: (_, query) => `user/${query.where['id']}/mcas`,
	allowedTypes: [RelationshipType.OneToOne],
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class AadUserAadUserMcasDetailsRelationship
	implements EntityRelationshipRepositoryType<AadUser, AadUserMcasDetails> {}

@ApiCall({
	name: 'MCAS users data',
	endpoint: 'users/mcas',
	cache: {
		time: 1000 * 60,
	},
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (params: string[]) => ({ params: { aadUserIds: params } }),
})
export class AadUsersMcasDetailsApiCall extends ApiCallModel<Record<string, AadUserMcasDetails>> {}
