import { EntityModelBase, Entity, EntityField } from '@microsoft/paris';
import { EvaluationSimulatorAgent, WcdPortalParisConfig, ApiVersionHeaderName } from '@wcd/domain';

@Entity({
	singularName: 'Evaluation simulation catalog',
	pluralName: 'Evaluation simulations catalog',
	endpoint: 'evaluation/simulationsCatalog',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	loadAll: true,
	customHeaders: (data, _config) => {
		return { [ApiVersionHeaderName]: '2.0' };
	},
})
export class EvaluationSimulationCatalog extends EntityModelBase<string> {
	@EntityField({ data: 'simulationId' })
	simulationId: number;

	@EntityField({ data: 'simulator' })
	simulator: EvaluationSimulatorAgent;

	@EntityField({ data: 'name' })
	name: string;

	@EntityField({ data: 'description' })
	description: string;

	@EntityField({ data: 'isDeprecated' })
	isDeprecated: boolean;
}
