import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { File } from './file.entity';

@ApiCall({
	name: 'Quarantine a file',
	endpoint: 'remediation/remediate',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({ file, reason }: QuarantineFileData) => {
		return {
			data: {
				FileIdentifier: file.sha1,
				FileIdentifierType: 'Sha1',
				RequestorComment: reason,
			},
		};
	},
})
export class FileQuarantineApiCall extends ApiCallModel<any, QuarantineFileData> {}

export interface QuarantineFileData {
	file: File;
	reason: string;
}
