import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: 'input[type=number][min][ngModel],input[type=number][min][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => MinValidator), multi: true }],
})
export class MinValidator {
	@Input() min: number;

	validate(c: FormControl) {
		const value: number = parseInt(c.value, 10),
			minInt: number = parseInt(String(this.min), 10);

		return isNaN(value) || value < minInt ? { min: { valid: false } } : null;
	}
}
