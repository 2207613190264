var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';
var MAX_EXPORT_ROW_COUNT = 1000;
var ExportDataSetModalComponent = /** @class */ (function (_super) {
    __extends(ExportDataSetModalComponent, _super);
    function ExportDataSetModalComponent(router, appInsightsService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.appInsightsService = appInsightsService;
        _this.i18nService = i18nService;
        _this.isExporting = false;
        return _this;
    }
    ExportDataSetModalComponent.prototype.ngOnInit = function () {
        this.rowCount = this.maxRowCount = Math.min(MAX_EXPORT_ROW_COUNT, this.totalCount);
    };
    ExportDataSetModalComponent.prototype.exportDataSet = function () {
        var _this = this;
        this.isExporting = true;
        this.setExportCount(this.rowCount || MAX_EXPORT_ROW_COUNT).then(function () {
            _this.isExporting = false;
            _this.destroy();
        }, function (error) {
            _this.isExporting = false;
            _this.isError = true;
            _this.appInsightsService.trackException(new Error('Failed to export data'));
        });
    };
    return ExportDataSetModalComponent;
}(ModalContainer));
export { ExportDataSetModalComponent };
