import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'TI Indicator filter search result',
	pluralName: 'TI Indicator filter search results',
	readonly: true,
})
export class TiIndicatorFilterSearchResult<TValue extends string | number> extends ModelBase {
	@EntityField({ data: 'value' })
	readonly value: TValue;
}
