import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { Observable } from 'rxjs';
import { Tag, MachineValue } from '@wcd/domain';
import { CsvService } from '../../../shared/services/csv.service';
import { FeaturesService, Feature } from '@wcd/config';

const EDIT_MACHINE_TAGS_API_ENDPOINT = 'EditMachineTags';
const EDIT_MACHINE_TAGS_API_K8S_ENDPOINT = 'machines/editMachineTags';
const EDIT_MACHINES_VALUE_API_ENDPOINT_V1 = 'AssetValues';
const EDIT_MACHINES_VALUE_API_ENDPOINT_V2 = 'machines/assetValues';
const ALL_MACHINE_TAGS_URL = 'AllMachinesTags';
const ALL_MACHINE_TAGS_K8S_URL = 'machines/allMachinesTags';
const RBAC_GROUPS_URL = 'UserExposedRbacGroups';
const EXPORT_TO_CSV_TOKEN_URL = 'ExportMachinesQueueToCsv';
const EXPORT_TO_CSV_TOKEN_K8S_URL = 'machines/getExportToken';
const EXPORT_TO_CSV_URL = 'DownloadMachinesQueueCsv';
const EXPORT_TO_CSV_K8S_URL = 'machines/exportToFile'

const securityPropertyToAntiVirusStatusConvertion = {
	AntiVirusNotReporting: 'Unknown',
	AntiVirusEnabled: 'Disabled',
	AntiVirusSignatureVersion: 'NotUpdated',
} as const;

@Injectable()
export class MachinesBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private readonly csvService: CsvService,
		private serviceUrlsService: ServiceUrlsService,
		private featuresService: FeaturesService,
	) {
		super(http, downloadService);
	}

	protected get apiUrl(): string {
		return this.serviceUrlsService.threatIntel;
	}

	protected get getExportTokenApiUrl(): string {
		let baseUrl = this.apiUrl;
		let path = EXPORT_TO_CSV_TOKEN_URL;
		if (this.featuresService.isEnabled(Feature.MachinesApiServiceMigration)){
			baseUrl = this.serviceUrlsService.k8s;
			path = EXPORT_TO_CSV_TOKEN_K8S_URL;
		}

		return `${baseUrl}/${path}`;
	}

	protected get exportToFileApiUrl(): string {
		let baseUrl = this.apiUrl;
		let path = EXPORT_TO_CSV_URL;
		if (this.featuresService.isEnabled(Feature.MachinesApiServiceMigration)){
			baseUrl = this.serviceUrlsService.k8s;
			path = EXPORT_TO_CSV_K8S_URL;
		}

		return `${baseUrl}/${path}`;
	}

	getMachineGroups(): Observable<MachineTagsBackendData> {
		let baseUrl = this.apiUrl;
		let endpoint = ALL_MACHINE_TAGS_URL;
		if (this.featuresService.isEnabled(Feature.MachinesControllerMigrationGetTags)){
			baseUrl = this.serviceUrlsService.k8s;
			endpoint = ALL_MACHINE_TAGS_K8S_URL;
		}
		return this.http.get<MachineTagsBackendData>(baseUrl + '/' + endpoint);
	}

	getUserExposedRbacGroups(): Observable<UserExposedRbacGroupsBackendData> {
		return this.get<UserExposedRbacGroupsBackendData>(RBAC_GROUPS_URL);
	}

	downloadCsv(options?: Record<string, string>) {

		// We're transitioning to TVM AV Status, so if the feature is on we'll use TVM AV Status, if not we'll do it the old way
		// For TVM Av Status we introduced a new query parameter - avStatuses, we'll set it up and clear the current one - securityPropertiesRequiringAttention
		const useTvmMachinesAvStatus = (options && options['useTvmMachinesAvStatus']);
		if (useTvmMachinesAvStatus && options.securityPropertiesRequiringAttention) {
			options.avStatuses = Array.from(options.securityPropertiesRequiringAttention).map(property => securityPropertyToAntiVirusStatusConvertion[property]).join(',');
			delete options.securityPropertiesRequiringAttention;
			delete options.useTvmMachinesAvStatus;
		}

		return this.csvService.downloadCsv({
			tokenUrl: `${this.getExportTokenApiUrl}`,
			apiUrl: `${this.exportToFileApiUrl}`,
			params: options,
		});
	}

	updateMachinesTags(internalMachineIds: ReadonlyArray<string>, tags: ReadonlyArray<Tag>): Observable<any> {
		const userDefinedTags = tags.filter(tag => tag.isEditable !== false);

		const url = this.featuresService.isEnabled(Feature.MachinesControllerMigrationEditTags)
			? `${this.serviceUrlsService.k8s}/${EDIT_MACHINE_TAGS_API_K8S_ENDPOINT}`
			: `${this.apiUrl}/${EDIT_MACHINE_TAGS_API_ENDPOINT}`;

		return this.http.post(url, {
			InternalMachineIds: internalMachineIds,
			MachineTags: JSON.stringify(userDefinedTags.map(tag => tag.name.trim())),
		});
	}

	updateMachinesValues(senseMachineIds: ReadonlyArray<string>, value: MachineValue): Observable<any> {
		if (this.featuresService.isEnabled(Feature.UpdateMachinesValueMigration)) {
			return this.http.post(this.serviceUrlsService.k8s + '/' + EDIT_MACHINES_VALUE_API_ENDPOINT_V2, {
				SenseMachineIds: senseMachineIds,
				AssetValue: value,
			});
		} else {
			return this.post(EDIT_MACHINES_VALUE_API_ENDPOINT_V1, {
				SenseMachineIds: senseMachineIds,
				AssetValue: value,
			});
		}

	}
}

export interface MachineTagsBackendData {
	BuiltInTags: { [index: number]: string };
	UserDefinedTags: { [index: number]: string };
}

export type UserExposedRbacGroupsBackendData = Array<{
	id: string;
	label: string;
}>;
