import {
	SecurityRecommendation,
	Asset,
	RelatedComponentFilter,
	RecommendationFiltersApiCall,
	RemediationType,
	AssetRecommendation,
	SoftwareInstallationAgg,
	RecommendationCategory,
	MdeUserRoleActionEnum,
} from '@wcd/domain';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { TvmImpactScoreComponent } from '../../../../tvm/components/tvm-impact-score/tvm-impact-score.component';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { FabricIconNames } from '@wcd/scc-common';
import { FeaturesService, Feature } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { RelatedComponentEnum } from '../components/filter/related-component.enum';
import { map } from 'rxjs/operators';
import { ReplaySubject } from 'rxjs';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { TvmAssetsStatisticsComponent } from '../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TzDateService } from '@wcd/localization';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TvmProductivityImpactService } from '../../../../tvm/services/tvm-productivity-impact.service';
import { NetworkDeviceFieldComponent } from '../../../../global_entities/components/entity-details/network-device-field/network-device-field.component';
import { AuthService } from '@wcd/auth';
import { TvmProductivityImpactComponent } from '../../../../global_entities/components/entity-details/productivity-impact.component';
import { PrettyNumberService } from '@wcd/prettify';
import { SecurityRecommendationService } from './security-recommendation.service';
import { AppConfigService } from '@wcd/app-config';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';

const STATUS_FILTER_PRIORITY = 1;
const REMEDIATION_FILTER_PRIORITY = 2;
const RELATED_COMPONENT_FILTER_PRIORITY = 3;
const TAGS_FILTER_PRIORITY = 4;

export class SecurityRecommendationFieldsServiceBase implements FieldsService<SecurityRecommendation> {
	private _fields: Array<DataviewField<SecurityRecommendation>>;
	private _exposedAssetsFields: Array<DataviewField<Asset>>;
	private _exposedAssetsUserImpactFields: Array<DataviewField<Asset>>;
	private _exposedDevicesFields: Array<DataviewField<Asset>>;
	private _installedAssetsFields: Array<DataviewField<SoftwareInstallationAgg>>;
	private _installedDevicesFields: Array<DataviewField<SoftwareInstallationAgg>>;
	private _singleSoftwareRecommendation: boolean = false;
	private _singleAssetRecommendation: boolean = false;
	private _isExceptionsPerRbacFeatureEnabled: boolean = false;
	private _orgHasMachineGroups: boolean = false;

	private _relatedComponentFilter$: ReplaySubject<any> = new ReplaySubject();

	private exposedAssetsFieldsConfig = [
		{
			id: 'assetName',
			name: this.i18nService.get('tvm.common.assetNameTitle'),
			sort: { enabled: false },
			getDisplay: (asset: Asset) => asset.name,
			getLink: (asset: Asset) =>
				this.featuresService.isEnabled(Feature.UpgradeMachinePage)
					? `/machines/${asset.id}`
					: `/machine/${asset.id}`,
			icon: {
				fabricIcon: FabricIconNames.System,
			},
			className: 'nowrap wcd-text-overflow-medium',
		},
		this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems)
			? {
					id: 'osName',
					name: this.i18nService.get('os.operatingSystem'),
					sort: { enabled: false },
					getDisplay: (asset: Asset) => this.tvmTextsService.getOsPlatformLabel(asset.osName),
					className: 'nowrap wcd-text-overflow-medium',
			  }
			: null,
	].filter(Boolean);

	constructor(
		private tvmTaggedFieldService: TvmTaggedFieldService,
		private tzDateService: TzDateService,
		private i18nService: I18nService,
		private tvmTextsService: TvmTextsService,
		private featuresService: FeaturesService,
		paris: Paris,
		private tvmIconBuilderService: TvmIconBuilderService,
		private reportInaccuracyService: TvmReportInaccuracyService,
		private tvmTagsService: TvmTagsService,
		private authService: AuthService,
		private productivityImpactService: TvmProductivityImpactService,
		private prettyNumberService: PrettyNumberService,
		private securityRecommendationService: SecurityRecommendationService,
		appConfigService: AppConfigService
	) {
		this._isExceptionsPerRbacFeatureEnabled = featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
		this._orgHasMachineGroups = appConfigService.hasMachineGroups;
		paris
			.apiCall<RelatedComponentFilter[]>(RecommendationFiltersApiCall, {}) //TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
			.pipe(
				map((relatedComponents: RelatedComponentFilter[]) => {
					return Object.keys(RelatedComponentEnum)
						.filter((k) => k !== 'Other') //TODO: Requested to remove the 'Other' filter. leaving rest of logic as is for now, for the chance of bringing it back soon.
						.map((k) => {
							const filteredByCategory = relatedComponents.filter(
								(x) => x.category === RelatedComponentEnum[k]
							);
							const children =
								filteredByCategory.length == 0 ? [] : filteredByCategory[0].subCategories;
							return {
								key: k,
								value: children,
							};
						})
						.reduce((res, obj) => {
							res[obj.key] = obj.value;
							return res;
						}, {});
				})
			)
			.subscribe((filters) => this._relatedComponentFilter$.next(filters));
	}

	protected set singleAssetRecommendation(val: boolean) {
		this._singleAssetRecommendation = val;
	}

	protected set singleSoftwareRecommendation(val: boolean) {
		this._singleSoftwareRecommendation = val;
	}

	get fields(): Array<DataviewField<SecurityRecommendation>> {
		/*
		//TODO: consider:
		Ben Grynhaus
			FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
			Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
			It does exactly what the C# equivalent does.
		*/
		if (!this._fields) {
			this._fields = DataviewField.fromList<SecurityRecommendation>([
				{
					id: 'title',
					name: this.i18nService.get('tvm.common.securityRecommendation'),
					sort: { enabled: false },
					component: {
						type: TaggedFieldComponent,
						getProps: (recommendation: SecurityRecommendation) =>
							this.getTitleFieldsParams(recommendation),
					},
				},
				!this._singleAssetRecommendation
					? {
							id: 'osPlatform',
							name: this.i18nService.get('tvm_common_osPlatform'),
							sort: { enabled: false },
							getDisplay: (securityRecommendation: SecurityRecommendation) =>
								securityRecommendation.osPlatform
									? this.i18nService.get(
											`tvm_common_osPlatform_${securityRecommendation.osPlatform}`
									  )
									: this.i18nService.strings.notAvailable_short,
					  }
					: null,
				{
					id: 'weaknessesCount',
					name: this.i18nService.get('tvm.common.weaknesses'),
				},
				{
					id: 'relatedComponent',
					name: this.i18nService.get('tvm.common.relatedComponent'),
					filterOnly: this._singleSoftwareRecommendation,
					sort: { enabled: false },
					getDisplay: (securityRecommendation: SecurityRecommendation) => {
						return this.tvmTextsService.getText(
							TextToken.SecurityRecommendationRelatedComponent,
							securityRecommendation
						);
					},
					filterName: this.i18nService.strings.tvm_common_relatedComponent_filterTitle,
					filter: {
						priority: RELATED_COMPONENT_FILTER_PRIORITY,
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								allowSingleValueDeselection: true,
								mapFilterValue: (component) => {
									if (
										component == RecommendationCategory.NetworkGear ||
										RelatedComponentEnum[component]
									) {
										return this.getComponentMapFilterValues(component);
									}
								},
							},
						},
					},
				},
				{
					id: 'threats',
					name: this.i18nService.get('tvm.common.threats'),
					sort: { enabled: false },
					component: {
						type: TvmThreatIconsComponent,
						getProps: (securityRecommendation: SecurityRecommendation) => {
							let insightTooltip = '';
							if (
								securityRecommendation.remediationType === RemediationType.ConfigurationChange
							) {
								const properlyConfiguredInsight = this.tvmTextsService.getText(
									TextToken.SecurityRecommendationSCAProperlyConfiguredInsight,
									securityRecommendation
								);

								const benchmarksInsight = this.tvmTextsService.getText(
									TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight,
									securityRecommendation
								);

								insightTooltip =
									securityRecommendation instanceof AssetRecommendation
										? this.createInsights(null, benchmarksInsight)
										: this.createInsights(properlyConfiguredInsight, benchmarksInsight);
							}

							return this.tvmIconBuilderService.configureIcons(
								securityRecommendation.threatInfo,
								securityRecommendation.threats,
								'securityRecommendation',
								true,
								insightTooltip,
								true
							);
						},
					},
				},
				{
					id: 'exposedDevicesWithTrends',
					filterOnly: this._singleAssetRecommendation,
					name: this.i18nService.get('tvm.common.exposedDevices'),
					sort: { enabled: false }, //TODO: can't sort by complex objects yet, analytics layer doesn't support that.
					component: {
						type: TvmAssetsStatisticsComponent,
						getProps: (securityRecommendation: SecurityRecommendation) => ({
							totalAssetCount: securityRecommendation.assetsStatistics.totalAssetCount,
							assetsCount: securityRecommendation.assetsStatistics.assetsAtRiskCount,
							assetsCountHistory: securityRecommendation.assetsStatistics.assetsAtRiskHistory,
						}),
					},
					allowResize: false,
					minWidth: 200,
				},
				{
					id: 'status',
					name: this.i18nService.get('status'),
					filter: { priority: STATUS_FILTER_PRIORITY },
					enabledByDefault: true,
					getDisplay: (recommendation) =>
						this.securityRecommendationService.getRecommendationStatus(recommendation.status),
				},
				{
					id: 'remediationType',
					name: this.i18nService.get('tvm.common.remediationType'),
					filterOnly: this._singleSoftwareRecommendation,
					filter: { priority: REMEDIATION_FILTER_PRIORITY },
					getDisplay: (securityRecommendation: SecurityRecommendation) => {
						return this.i18nService.get(
							`tvm.remediationTask.type.${securityRecommendation.remediationType}`
						);
					},
				},
				{
					id: 'remediationTasks',
					name: this.i18nService.get('tvm.common.remediationActivities'),
					sort: { enabled: false },
					getDisplay: (securityRecommendation: SecurityRecommendation) => {
						if (!securityRecommendation.remediationTasks) {
							return this.i18nService.get('notAvailable.short');
						}
						const count = securityRecommendation.remediationTasks.length;
						return count;
					},
					getTooltip: (securityRecommendation: SecurityRecommendation) => {
						const statuses = securityRecommendation.remediationTasks;

						if (statuses.length != 0) {
							let list = '<ul>';
							list += statuses.reduce(
								(acc, curr) =>
									`${acc}<li>${this.tvmTextsService.getText(
										TextToken.RemediationTicketStatus,
										curr
									)}, ${this.i18nService
										.get('tvm.securityRecommendation.relatedRemediation.createdOn')
										.toLocaleLowerCase()}: ${this.tzDateService.format(
										curr.createdOn,
										'shortDate'
									)}</li>`,
								''
							);
							list += '</ul>';
							return list;
						}
						return '';
					},
					valueTooltipAllowHtmlRendering: true,
				},
				{
					id: 'impactInfo',
					filterOnly: this._singleAssetRecommendation,
					name: this.i18nService.get('tvm.common.impact'),
					description: this.tvmTextsService.getText(TextToken.ScoreImpactInfo),
					component: {
						type: TvmImpactScoreComponent,
						getProps: (securityRecommendation: SecurityRecommendation) => ({
							scoreData: {
								exposureScore: securityRecommendation.exposureScoreImprovement,
								secureScore: securityRecommendation.secureScoreImprovement,
								exposureScoreTooltip: this.tvmTextsService.getText(
									TextToken.ExposureScoreTooltip
								),
								secureScoreTooltip: this.tvmTextsService.getText(
									TextToken.ConfigurationScoreTooltip
								),
							},
						}),
					},
					descriptionTooltipAllowHtmlRendering: true,
				},
				this._isExceptionsPerRbacFeatureEnabled && this._orgHasMachineGroups
					? {
							id: 'exposedDevicesAfterException',
							name: this.i18nService.strings
								.tvm_securityRecommendation_exposedDevicesAfterException,
							description: this.i18nService.strings
								.tvm_securityRecommendation_exposedDevicesAfterException_tooltip,
							enabledByDefault: false,
							getDisplay: (securityRecommendation: SecurityRecommendation) =>
								this.prettyNumberService.prettyNumber(
									securityRecommendation.assetsStatistics
										.assetsAtRiskCountAfterApplyingExceptions
								),
					  }
					: null,
				this._isExceptionsPerRbacFeatureEnabled
					? {
							id: 'impactInfoAfterException',
							name: this.i18nService.strings
								.tvm_securityRecommendation_impactInfoAfterException,
							description: this.i18nService.strings
								.tvm_securityRecommendation_impactInfoAfterException_tooltip,
							sort: { enabled: false },
							enabledByDefault: false,
							component: {
								type: TvmImpactScoreComponent,
								getProps: (securityRecommendation: SecurityRecommendation) => ({
									scoreData: {
										exposureScore:
											securityRecommendation.exposureScoreImprovementAfterApplyingExceptions,
										secureScore:
											securityRecommendation.secureScoreImprovementAfterApplyingExceptions,
										exposureScoreTooltip: this.tvmTextsService.getText(
											TextToken.ExposureScoreTooltip
										),
										secureScoreTooltip: this.tvmTextsService.getText(
											TextToken.ConfigurationScoreTooltip
										),
									},
								}),
							},
					  }
					: null,
				{
					id: 'recommendationTags',
					name: this.i18nService.get('common.tags'),
					component: {
						type: TagsFieldComponent,
						getProps: (securityRecommendation: SecurityRecommendation) => ({
							tags: this.tvmTagsService.getRecommendationTags(securityRecommendation),
						}),
					},
					sort: { enabled: false },
					filter: {
						priority: TAGS_FILTER_PRIORITY,
					},
				},
			]).filter(Boolean);

			// recommendations for one asset presented for all user
			if (
				!this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmViewData)
			) {
				// remove the remediation activities column
				this._fields.splice(7, 1);
			}
		}
		return this._fields;
	}

	get exposedAssetsFields(): Array<DataviewField<Asset>> {
		if (this._exposedAssetsFields) {
			return this._exposedAssetsFields;
		}
		this._exposedAssetsFields = DataviewField.fromList<Asset>(this.exposedAssetsFieldsConfig);
		return this._exposedAssetsFields;
	}

	get exposedAssetsUserImpactFields(): Array<DataviewField<Asset>> {
		if (this._exposedAssetsUserImpactFields) {
			return this._exposedAssetsUserImpactFields;
		}
		this._exposedAssetsUserImpactFields = DataviewField.fromList<Asset>([
			...this.exposedAssetsFieldsConfig,
			{
				id: 'productivityImpactField',
				name: this.i18nService.get(
					'tvm.securityRecommendation.userImpact.productivityImpactFieldName'
				),
				sort: { enabled: false },
				component: {
					type: TvmProductivityImpactComponent,
					getProps: (asset: Asset) => ({
						isUserProductivityImpacted: asset.isUserProductivityImpacted,
					}),
				},
			},
		]);
		return this._exposedAssetsUserImpactFields;
	}

	get exposedDevicesFields(): Array<DataviewField<Asset>> {
		if (this._exposedDevicesFields) {
			return this._exposedDevicesFields;
		}
		this._exposedDevicesFields = DataviewField.fromList<Asset>([
			{
				id: 'assetName',
				name: this.i18nService.get('tvm.common.assetNameTitle'),
				sort: { enabled: false },
				//Temp solution until INE fix the bug in fabric font
				component: {
					type: NetworkDeviceFieldComponent,
					getProps: (asset: Asset) => {
						return { title: asset.name };
					},
				},
			},
			{
				id: 'lastSeen',
				name: this.i18nService.get('tvm.networkGear.networkDeviceReachableTitle'),
				sort: { enabled: false },
				getDisplay: (asset: Asset) =>
					new Date(asset.lastSeen).getTime() > new Date().getTime() - 60 * 60 * 24 * 1000
						? this.i18nService.get('common.yes')
						: this.i18nService.get('common.no'),
				className: 'nowrap wcd-text-overflow-medium',
			},
		]);

		return this._exposedDevicesFields;
	}

	get installedAssetsFields(): Array<DataviewField<SoftwareInstallationAgg>> {
		if (this._installedAssetsFields) {
			return this._installedAssetsFields;
		}
		this._installedAssetsFields = DataviewField.fromList<SoftwareInstallationAgg>(
			[
				{
					id: 'assetName',
					name: this.i18nService.get('tvm.common.assetNameTitle'),
					sort: { enabled: false },
					getDisplay: (softwareInstallation: SoftwareInstallationAgg) =>
						softwareInstallation.assetName,
					getLink: (softwareInstallation: SoftwareInstallationAgg) =>
						this.featuresService.isEnabled(Feature.UpgradeMachinePage)
							? `/machines/${softwareInstallation.assetId}`
							: `/machine/${softwareInstallation.assetId}`,
					icon: {
						fabricIcon: FabricIconNames.System,
					},
					className: 'nowrap wcd-text-overflow-medium',
				},
				this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems)
					? {
							id: 'osName',
							name: this.i18nService.get('os.operatingSystem'),
							sort: { enabled: false },
							getDisplay: (softwareInstallation: SoftwareInstallationAgg) =>
								this.tvmTextsService.getOsPlatformLabel(softwareInstallation.osName),
							className: 'nowrap wcd-text-overflow-medium',
					  }
					: null,
			].filter(Boolean)
		);
		return this._installedAssetsFields;
	}

	get installedDevicesFields(): Array<DataviewField<SoftwareInstallationAgg>> {
		if (this._installedDevicesFields) {
			return this._installedDevicesFields;
		}
		this._installedDevicesFields = DataviewField.fromList<SoftwareInstallationAgg>([
			{
				id: 'assetName',
				name: this.i18nService.get('tvm.common.assetNameTitle'),
				sort: { enabled: false },
				//Temp solution until INE fix the bug in fabric font
				component: {
					type: NetworkDeviceFieldComponent,
					getProps: (softwareInstallation: SoftwareInstallationAgg) => {
						return { title: softwareInstallation.assetName };
					},
				},
			},
		]);
		return this._installedDevicesFields;
	}

	private createInsights(properlyConfigured: string, supportedBenchmarks: string): string {
		if (!properlyConfigured && !supportedBenchmarks) {
			return '';
		}
		let insights = `<h3 class="wcd-margin-vertical">${this.i18nService.get(
			'tvm.common.recommendationsInsights'
		)}</h3>
						<ul>`;
		if (properlyConfigured) {
			insights += `<li>${properlyConfigured}</li>`;
		}
		if (supportedBenchmarks) {
			insights += `<li>${supportedBenchmarks}</li>`;
		}
		insights += '</ul>';

		return insights;
	}

	private getComponentMapFilterValues(component: any) {
		return this._relatedComponentFilter$.pipe(
			map((relatedComponentsChildren) => ({
				id:
					component === 'Other'
						? `productName:eq:'${component}'`
						: `recommendationCategory:eq:'${
								component == RecommendationCategory.NetworkGear
									? component
									: RelatedComponentEnum[component]
						  }'`,
				name:
					component === 'OS'
						? this.i18nService.get('tvm.common.operatingSystem')
						: RelatedComponentEnum[component],
				priority: component == 'Other' ? 0 : 1,
				children:
					component === 'OS'
						? this.getOsFilterChildren(relatedComponentsChildren[component])
						: relatedComponentsChildren[component] &&
						  relatedComponentsChildren[component].map((child) => ({
								id:
									component === 'Other'
										? `productName:eq:'${child}'`
										: `(recommendationCategory:eq:'${RelatedComponentEnum[component]}':and:subCategory:eq:'${child}')`,
								name: this.tvmTextsService.getText(TextToken.EntityName, child),
						  })),
			}))
		);
	}

	// An ad hoc fix for now for inability to filter on general OS recommendation
	// TODO: replace with actual filtering in the BE, VSTS bug - 24347723
	private getOsFilterChildren(children: any[]) {
		const osChildren = [{ id: `recommendationCategory:eq:'OS':or:subCategory:eq:'any'`, name: 'Any' }];
		if (Array.isArray(children)) {
			children.forEach((child) =>
				osChildren.push({
					id: `(recommendationCategory:eq:'OS':and:subCategory:eq:'${child}')`,
					name: this.tvmTextsService.getText(TextToken.EntityName, child),
				})
			);
		}
		return osChildren;
	}

	getTitleFieldsParams(recommendation: SecurityRecommendation) {
		let baseProps =
			recommendation.remediationType === RemediationType.ConfigurationChange
				? this.getBasePropsProductivityAssessment(recommendation)
				: this.getZeroDayBaseProps(recommendation);

		// To show report inaccuracy only for a single asset security recommendations
		if (this._singleAssetRecommendation) {
			const inaccuracyContext = {
				inaccuracyContext: this.reportInaccuracyService.evaluateContext(true, recommendation),
				contextObject: recommendation,
			};
			baseProps = {
				...baseProps,
				...inaccuracyContext,
			};
		}
		return baseProps;
	}

	private getBasePropsProductivityAssessment(recommendation: SecurityRecommendation) {
		const statistics = recommendation.assetsStatistics;
		const assetsAtRisk = statistics.assetsAtRiskCount;
		const nonImpactedAssets = statistics.nonProductivityImpactedAssets;

		const isSingleAsset = statistics.totalAssetCount === 0 && assetsAtRisk === 1; // A check for single asset recommendation

		const includeImpactTitleInsights =
			this.productivityImpactService.isDataviewTitleInsightsEnabled &&
			(isSingleAsset
				? recommendation.isProductivityImpacted === false // For now only showing the green icon for safe assets
				: this.productivityImpactService.isRecommendationContainsImpactAssessment(recommendation));

		const percentage =
			assetsAtRisk > 0 && nonImpactedAssets <= assetsAtRisk
				? (nonImpactedAssets / assetsAtRisk) * 100
				: 0; // setting to 0 - to better show red color (if shown) instead of crushing

		const iconColoring = isSingleAsset
			? this.productivityImpactService.getAssetSafeRecommendationColoring()
			: this.productivityImpactService.getOrgSafeRecommendationColoring(percentage);

		const productivityAssessmentTooltip = includeImpactTitleInsights
			? isSingleAsset
				? this.tvmTextsService.getText(TextToken.AssetRecommendationProductivityImpactTooltip)
				: this.tvmTextsService.getText(
						TextToken.OrgRecommendationProductivityImpactTooltip,
						statistics
				  )
			: undefined;
		return {
			title: this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, recommendation),
			showTag: false,
			tagAfterText: false,
			productivityAssessmentInfo: {
				isProductivityAssessmentIncluded: includeImpactTitleInsights,
				isSingleAsset: isSingleAsset,
				productivityAssessmentTooltip: productivityAssessmentTooltip,
				iconColoring: iconColoring,
			},
		};
	}

	private getZeroDayBaseProps(recommendation: SecurityRecommendation) {
		return this.tvmTaggedFieldService.getZeroDayBaseProps(
			recommendation.mostSevereVulnerabilityType,
			recommendation.patchReleaseDate,
			this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, recommendation),
			true
		);
	}
}
