import { ChangeEvent } from './change-event.entity';
import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Change events grouped by date',
	pluralName: 'Change events grouped by dates',
})
export class ChangeEventByDate extends ModelBase {
	@EntityField()
	readonly date: Date;

	@EntityField({
		arrayOf: ChangeEvent,
	})
	readonly changeEvents: ChangeEvent[];
}
