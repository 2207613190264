import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { SystemExclusionType } from './system-exclusion-type.entity';
import { SystemExclusion } from './system-exclusion.entity';

@EntityRelationship({
	sourceEntity: SystemExclusionType,
	dataEntity: SystemExclusion,
	foreignKey: 'type',
	allowedTypes: [RelationshipType.OneToMany],
})
export class SystemExclusionTypeExclusionsRelationship
	implements EntityRelationshipRepositoryType<SystemExclusionType, SystemExclusion> {}
