import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { machineHealthStatusValues } from './machine-health-status.entity.values';

@Entity({
	singularName: 'Device Health Status',
	pluralName: 'Device Health Statuses',
	values: machineHealthStatusValues,
})
export class MachineHealthStatus extends StatusModel {
	@EntityField() description?: string;

	@EntityField() category?: string;

	@EntityField() childOf?: string;
}
