/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../shared/pipes/safe-url.pipe";
import * as i5 from "../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i6 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "@angular/common";
import * as i9 from "./map.component";
var styles_MapComponent = [];
var RenderType_MapComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MapComponent, data: {} });
export { RenderType_MapComponent as RenderType_MapComponent };
function View_MapComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "div", [["class", "map"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "iframe", [], [[8, "src", 5]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onIframeLoad() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), _co.mapUrl)); _ck(_v, 2, 0, currVal_0); }); }
function View_MapComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 17, "div", [["class", "alert alert-danger"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [["class", "large-icon pull-left"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 10, "div", [["class", "with-large-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(9, null, ["", ""])), i0.ɵpod(10, { asset: 0 }), i0.ɵppd(11, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(13, 0, null, null, 2, "a", [["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), i0.ɵppd(14, 1), (_l()(), i0.ɵted(-1, null, ["location link"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 1), "help.externalLoadError", _ck(_v, 10, 0, _co.mapAsset))); _ck(_v, 9, 0, currVal_1); var currVal_2 = i0.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i0.ɵnov(_v.parent, 0), _co.externalMapUrl)); _ck(_v, 13, 0, currVal_2); }); }
export function View_MapComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.SafeUrlPipe, [i5.WicdSanitizerService]), i0.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "div", [["class", "external-map"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapComponent_1)), i0.ɵdid(6, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MapComponent_2)), i0.ɵdid(9, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isMapAvailable; _ck(_v, 6, 0, currVal_0); var currVal_1 = !_co.isMapAvailable; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_MapComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "external-map", [], null, null, null, View_MapComponent_0, RenderType_MapComponent)), i0.ɵdid(1, 114688, null, 0, i9.MapComponent, [i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MapComponentNgFactory = i0.ɵccf("external-map", i9.MapComponent, View_MapComponent_Host_0, { location: "location" }, { error: "error" }, []);
export { MapComponentNgFactory as MapComponentNgFactory };
