import { ElementRef, OnDestroy, EventEmitter, SecurityContext, } from '@angular/core';
import { TooltipsService } from './tooltips.service';
import { DomSanitizer } from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';
var TOOLTIP_DELAY = 600;
var TOOLTIP_HIDE_DELAY = 300;
var ESC = 27;
var SPACE = 32;
var TooltipDirective = /** @class */ (function () {
    function TooltipDirective(el, tooltipsService, domSanitizer) {
        this.tooltipsService = tooltipsService;
        this.domSanitizer = domSanitizer;
        this._tooltipCssClass = '';
        this._ignoreMouseLeave = false;
        this.ownsTooltip = false;
        this.wcdTooltipShowOnOverflowOnly = false;
        this.wcdTooltipEnableKeyboardEvents = false;
        this.includeWcdTooltipAnnouncer = false;
        this.filterHtml = false;
        // Important note about wcdTooltipSanitizeHtml and wcdTooltipAllowHtmlRendering:
        // wcdTooltipSanitizeHtml must be TRUE for all cases except a rare case when
        // the tooltip must be rendered as HTML and the content is safe or already sanitizied.
        // Note that if wcdTooltipAllowHtmlRendering is enabled, the tooltip is treated as HTML and exposed to XSS vulnerability.
        // So setting wcdTooltipSanitizeHtml to true (or not specifying it and keep the default) ensures the tooltip will be sanitized.
        this.wcdTooltipSanitizeHtml = true;
        this.wcdTooltipAllowHtmlRendering = false;
        this.onDisplay = new EventEmitter();
        this.el = el.nativeElement;
    }
    TooltipDirective.prototype.updateTooltipRemoved = function () {
        this.ownsTooltip = false;
    };
    Object.defineProperty(TooltipDirective.prototype, "tooltipCssClass", {
        set: function (className) {
            this._tooltipCssClass = className || '';
        },
        enumerable: true,
        configurable: true
    });
    TooltipDirective.prototype.ngOnDestroy = function () {
        this.hideTooltip();
    };
    TooltipDirective.prototype.onFocus = function (evt) {
        var _this = this;
        if (!document.body.classList.contains('ms-Fabric--isFocusHidden') &&
            !this.wcdTooltipEnableKeyboardEvents) {
            this._ignoreMouseLeave = true;
            setTimeout(function () {
                _this._ignoreMouseLeave = false;
            }, 1000);
            this.setUpTooltip();
        }
    };
    TooltipDirective.prototype.onMouseEnter = function (evt) {
        this.setUpTooltip(evt);
    };
    TooltipDirective.prototype.onKeyDown = function (evt) {
        if (evt.keyCode == ESC) {
            if (this.ownsTooltip && !this._ignoreMouseLeave) {
                this.preventDefault(evt);
            }
            this.onMouseLeave();
        }
        if (this.wcdTooltipEnableKeyboardEvents && evt.keyCode === SPACE) {
            this.setUpTooltip();
            this.preventDefault(evt);
        }
    };
    TooltipDirective.prototype.onMouseLeave = function () {
        var _this = this;
        if (this._ignoreMouseLeave)
            return;
        clearTimeout(this.showTimeout);
        if (!this.ownsTooltip)
            return;
        this.hideTimeout = setTimeout(function () {
            clearTimeout(_this.hideTimeout);
            if (!_this.tooltipsService.isMouseOver) {
                _this.hideTooltip();
            }
        }, TOOLTIP_HIDE_DELAY);
    };
    TooltipDirective.prototype.hideTooltip = function () {
        clearTimeout(this.showTimeout);
        clearTimeout(this.hideTimeout);
        if (!this.ownsTooltip)
            return;
        this.ownsTooltip = false;
        this.tooltipsService.toggleVisibility(false);
        this.tooltipsService.unregisterTooltipEvents();
    };
    Object.defineProperty(TooltipDirective.prototype, "tooltipText", {
        get: function () {
            if (this.wcdTooltipAllowHtmlRendering) {
                if (this.filterHtml && this.isContainsHtml(this.tooltipHTML)) {
                    return '';
                }
            }
            return this.wcdTooltipAllowHtmlRendering && this.wcdTooltipSanitizeHtml
                ? this.sanatizeHTML(this.tooltipHTML)
                : this.tooltipHTML;
        },
        enumerable: true,
        configurable: true
    });
    TooltipDirective.prototype.sanatizeHTML = function (html) {
        if (sccHostService.isSCC) {
            return sccHostService.ui.sanitize(html);
        }
        return this.domSanitizer.sanitize(SecurityContext.HTML, html);
    };
    TooltipDirective.prototype.setUpTooltip = function (evt) {
        var _this = this;
        if (this.tooltipHTML || this.tooltipInnerComponent) {
            clearTimeout(this.showTimeout);
            if (this.wcdTooltipShowOnOverflowOnly && !this.isOverflown) {
                return;
            }
            if (this.tooltipInnerComponent) {
                this.tooltipsService.setCustomComponentTooltip(this.tooltipInnerComponent, this.updateTooltipRemoved.bind(this), this.innerComponentInput);
            }
            else {
                this.tooltipsService.setTextTooltip(this.tooltipText, this.updateTooltipRemoved.bind(this), this.wcdTooltipAllowHtmlRendering);
            }
            this.tooltipsService.setTooltipClassName(this._tooltipCssClass);
            if (this.includeWcdTooltipAnnouncer) {
                this.tooltipsService.announce(this.tooltipText);
            }
            this.tooltipDimensions = this.tooltipsService.getTooltipDimensions();
            this.showTimeout = setTimeout(function () {
                _this.onDisplay.emit();
                _this.ownsTooltip = true;
                _this.tooltipsService.toggleVisibility(true);
                _this.setTooltipPosition(evt);
            }, TOOLTIP_DELAY);
        }
    };
    TooltipDirective.prototype.setTooltipPosition = function (e) {
        if (e) {
            //set the tooltip next to the mouse
            this.tooltipsService.setTooltipPosition({ top: e.clientY, left: e.clientX }, this.tooltipDimensions);
        }
        else {
            //set the tooltip next to the host element
            var elOffset = this.offset(this.el);
            this.tooltipsService.setTooltipPosition({ top: elOffset.top, left: elOffset.left }, this.tooltipDimensions);
        }
    };
    TooltipDirective.prototype.offset = function (el) {
        var rect = el.getBoundingClientRect(), scrollLeft = window.pageXOffset || document.documentElement.scrollLeft, scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
    };
    TooltipDirective.prototype.preventDefault = function (event) {
        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    };
    Object.defineProperty(TooltipDirective.prototype, "isOverflown", {
        get: function () {
            var tooltipTmpElement = document.createElement('span');
            if (this.wcdTooltipAllowHtmlRendering) {
                tooltipTmpElement.innerHTML = this.tooltipText;
            }
            else {
                tooltipTmpElement.innerText = this.tooltipText;
            }
            this.el.ownerDocument.body.appendChild(tooltipTmpElement);
            var ellipsisActive = this.el.scrollWidth < tooltipTmpElement.offsetWidth;
            tooltipTmpElement.parentNode.removeChild(tooltipTmpElement);
            return ellipsisActive;
        },
        enumerable: true,
        configurable: true
    });
    TooltipDirective.prototype.isContainsHtml = function (value) {
        if (!value || typeof value !== 'string') {
            return false;
        }
        return /<((?=!\-\-)!\-\-[\s\S]*\-\-|((?=\?)\?[\s\S]*\?|((?=\/)\/[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*|[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:\s[^.\-\d][^\/\]'"[!#$%&()*+,;<=>?@^`{|}~ ]*(?:=(?:"[^"]*"|'[^']*'|[^'"<\s]*))?)*)\s?\/?))>/.test(value);
    };
    return TooltipDirective;
}());
export { TooltipDirective };
