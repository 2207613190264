import { AfterViewInit, OnChanges } from '@angular/core';
import { AirsLinkBase, InterflowUrlDataApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';
import { Paris } from '@microsoft/paris';
import { EMPTY, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, map, startWith, switchMap } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { tenantContextCache } from '@wcd/auth';
var LOADING_SYMBOL = Symbol();
var AirsUrlDetailsComponent = /** @class */ (function () {
    function AirsUrlDetailsComponent(paris, i18nService, threatsDisplayNameService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.threatsDisplayNameService = threatsDisplayNameService;
        this.LOADING_SYMBOL = LOADING_SYMBOL;
        this.SpinnerSize = SpinnerSize;
        this.address$ = new Subject();
        this.interflowData$ = this.address$.pipe(distinctUntilChanged(), switchMap(function (address) {
            return !tenantContextCache.hasMtpConsent && !tenantContextCache.appConfig.IsMdatpActive
                ? EMPTY
                : of(address);
        }), switchMap(function (address) { return (address ? _this.paris.apiCall(InterflowUrlDataApiCall, { address: address }) : of(null)); }), map(function (interflowData) {
            return interflowData &&
                Object.values(interflowData).some(function (v) {
                    return typeof v === 'object' ? Object.values(v).some(Boolean) : !!v;
                })
                ? interflowData
                : null;
        }), catchError(function (err) { return of(null); }), startWith(LOADING_SYMBOL));
    }
    Object.defineProperty(AirsUrlDetailsComponent.prototype, "url", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsUrlDetailsComponent.prototype, "emailLink", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AirsUrlDetailsComponent.prototype.ngAfterViewInit = function () {
        this.setAddress();
    };
    AirsUrlDetailsComponent.prototype.ngOnChanges = function (changes) {
        this.setAddress();
    };
    AirsUrlDetailsComponent.prototype.setAddress = function () {
        this.address$.next(this.entity && this.entity.address);
    };
    return AirsUrlDetailsComponent;
}());
export { AirsUrlDetailsComponent };
