import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Outbreak impacted entities count',
	pluralName: 'Outbreak impacted entities count',
	readonly: true,
})
export class OutbreakImpactedEntitiesCount extends ModelBase {
	@EntityField({ data: 'ImpactedDevicesCount', defaultValue: 0 })
	readonly impactedDevicesCount: number;

	@EntityField({ data: 'ImpactedMailboxesCount', defaultValue: 0 })
	readonly impactedMailboxesCount: number;

	@EntityField({ data: 'HasDevicesDefinition', defaultValue: false })
	readonly hasDevicesDefinition?: boolean;

	@EntityField({ data: 'HasMailboxesDefinition', defaultValue: false })
	readonly hasMailboxesDefinition?: boolean;
}
