/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tvm.dashboard-empty.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../components/machine-groups-filter/machine-groups-filter-control/machine-groups-filter-control.component.ngfactory";
import * as i5 from "../components/machine-groups-filter/machine-groups-filter-control/machine-groups-filter-control.component";
import * as i6 from "../services/tvm-machine-groups-filter.service";
import * as i7 from "../../../../../../projects/config/src/lib/services/features.service";
import * as i8 from "./tvm.dashboard-empty.component";
var styles_TvmDashboardEmptyComponent = [i0.styles];
var RenderType_TvmDashboardEmptyComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TvmDashboardEmptyComponent, data: {} });
export { RenderType_TvmDashboardEmptyComponent as RenderType_TvmDashboardEmptyComponent };
export function View_TvmDashboardEmptyComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "section", [["aria-labelledby", "tvm-dashboard-header"], ["class", "tvm-dashboard-container wcd-full-height wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "wcd-full-height wcd-flex-center-all"], ["data-track-component", "tvmDashboardEmpty"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "tvm-machine-groups-filter-control", [], null, null, null, i4.View_TvmMachineGroupsFilterControlComponent_0, i4.RenderType_TvmMachineGroupsFilterControlComponent)), i1.ɵdid(12, 180224, null, 0, i5.TvmMachineGroupsFilterControlComponent, [i6.TvmMachineGroupsFilterService, i7.FeaturesService, i1.ChangeDetectorRef, i3.I18nService], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), "common.noDataToShow")); _ck(_v, 7, 0, currVal_0); }); }
export function View_TvmDashboardEmptyComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tvm-dashboard-empty", [], null, null, null, View_TvmDashboardEmptyComponent_0, RenderType_TvmDashboardEmptyComponent)), i1.ɵdid(1, 49152, null, 0, i8.TvmDashboardEmptyComponent, [], null, null)], null, null); }
var TvmDashboardEmptyComponentNgFactory = i1.ɵccf("tvm-dashboard-empty", i8.TvmDashboardEmptyComponent, View_TvmDashboardEmptyComponent_Host_0, {}, {}, []);
export { TvmDashboardEmptyComponentNgFactory as TvmDashboardEmptyComponentNgFactory };
