
		<dataview
			class="wcd-full-height"
			id="incident-apps"
			*ngIf="incidentAppsRepository?.sourceItem; else noData"
			className="no-left-padding wcd-entity-page-content"
			[repository]="incidentAppsRepository"
			[entityType]="applicationEntityType"
			[allowFilters]="false"
			[fields]="fields"
			[fixedOptions]="dataviewFixedOptions"
			[label]="'incident.apps.title' | i18n"
			[removePadding]="true"
			[responsiveActionBar]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
		>
		</dataview>
		<ng-template #noData>
			<div class="wcd-full-height wcd-flex-center-all">
				<div class="widget-nodata-message">{{ 'incident.apps.titleNoData' | i18n }}</div>
			</div>
		</ng-template>
	