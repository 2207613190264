import { BehaviorSubject } from 'rxjs';
import { findIndex } from 'lodash-es';
import { Breadcrumb } from './breadcrumb.interface';

/**
 * A service for exposing and managing current breadcrumbs list state
 */
window.mdatp = window.mdatp || ({} as any);
/**
 * Returns current list of breadcrumbs items
 * @type {BehaviorSubject<Array<Breadcrumb>>}
 */
window.mdatp.breadcrumbs$ = window.mdatp.breadcrumbs$ || new BehaviorSubject<Array<Breadcrumb>>([]);

class BreadcrumbsStateService {
	get breadcrumbs$() {
		return window.mdatp.breadcrumbs$;
	}

	constructor() {}
	/**
	 * Adds\Updates the last item in the breadcrumbs array.
	 * If the id of the added/updated item already exists in the breadcrumbs list, the list is spliced before the existing item, and the new item is added at the end.
	 * @param {Breadcrumb} breadcrumb
	 */
	public add(breadcrumb: Breadcrumb) {
		if (!breadcrumb) throw new Error('Breadcrumb is missing');

		let items: Array<Breadcrumb> = this.breadcrumbs$.value;

		if (items.length) {
			const exisitingItem = findIndex(items, (item) => item.id === breadcrumb.id);
			if (~exisitingItem) items = items.splice(0, exisitingItem);
		}

		this.breadcrumbs$.next([...items, breadcrumb]);
	}

	/**
	 * Clears the breadcrumbs list
	 */
	public reset() {
		this.breadcrumbs$.next([]);
	}

	/**
	 * Clears the breadcrumbs list and then adds an item to the list.
	 * @param {Breadcrumb} breadcrumb
	 */
	public set(breadcrumb: Breadcrumb) {
		if (!breadcrumb) throw new Error('Breadcrumb is missing');

		this.breadcrumbs$.next([breadcrumb]);
	}
}

export const breadcrumbsStateService = new BreadcrumbsStateService();
