
		<div *ngIf="fullWidth" class="wcd-flex-justify-end-horizontal wcd-flex-center-vertical">
			<ng-container *ngTemplateOutlet="controls"></ng-container>
		</div>
		<div class="wcd-flex-horizontal wcd-flex-center-vertical wcd-flex-1">
			<input
				#input
				class="copyable-input-content"
				[attr.aria-label]="ariaLabel"
				type="text"
				[ngModel]="value"
				[readonly]="readonly"
				[attr.aria-readonly]="readonly"
				[disabled]="disabled"
				[hidden]="showFullValue"
			/>
			<p
				*ngIf="showFullValue"
				class="copyable-input-content full-value"
				[style.max-height]="maxHeight ? maxHeight + 'px' : 'none'"
			>{{ value }}</p>
			<ng-container *ngIf="!fullWidth">
				<ng-container *ngTemplateOutlet="controls"></ng-container>
			</ng-container>
		</div>
		<ng-template #controls>
			<copy-to-clipboard
				[ariaLabelFieldName]="ariaLabel"
				[content]="value"
				[settings]="{ copyText: copyText }"
			></copy-to-clipboard>
			<download-file
				*ngIf="saveSettings?.allowSave"
				[filename]="saveSettings?.fileName"
				[fileContent]="value"
				[disabled]="!value"
			></download-file>
		</ng-template>
	