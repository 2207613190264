import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FolderExclusionsStore } from '../services/folder-exclusions.store';
import { FolderExclusionModel } from '../models/folder-exclusion.model';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'folder-exclusion-form',
	templateUrl: './folder-exclusion-form.component.html',
})
export class FolderExclusionFormComponent {
	@Input() folderExclusion: FolderExclusionModel;
	@Output() done: EventEmitter<{ result: boolean }> = new EventEmitter<{ result: boolean }>(false);
	@Output() cancel: EventEmitter<any> = new EventEmitter<any>(false);

	constructor(public folderExclusionsStore: FolderExclusionsStore, public i18nService: I18nService) {}

	save() {
		this.folderExclusionsStore.save(this.folderExclusion).then(() => {
			this.done.emit({ result: true });
		});
	}

	cancelEdit() {
		this.folderExclusion.cancelEdit();
		this.cancel.emit(false);
	}
}
