import { Entity, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { WebContentFilteringDevice } from './category-report.value-object';
import { WebContentFilteringReportBase } from './report.model';
import {
	WebContentFilteringCategoryTypes,
	WebContentFilteringParentCategoryTypes,
} from '../../web-content-filtering/web-content-filtering-web-category-type';

@Entity({
	singularName: 'Domain report',
	pluralName: 'Domain reports',
	endpoint: 'WebContentFiltering/Reports/DomainReports',
	readonly: true,
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	allItemsEndpointTrailingSlash: false,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class WebContentFilteringDomainReport extends WebContentFilteringReportBase {
	@EntityField({ data: 'DomainName' })
	domainName: string;

	@EntityField({ data: 'CategoryIds' })
	categoryIds: Array<WebContentFilteringCategoryTypes>;

	@EntityField({ data: 'ParentCategories' })
	parentCategories: Array<WebContentFilteringParentCategoryTypes>;

	@EntityField({ data: 'TotalDevices' })
	totalDevices: number;

	@EntityField({ data: 'TopDevices', arrayOf: WebContentFilteringDevice })
	topDevices: Array<WebContentFilteringDevice>;
}
