import { sccHostService } from '@wcd/scc-interface';

export const LANGUAGE_KEY = 'mdatp-language';
export const DEFAULT_LANGUAGE: string = 'en';

export const getPortalLanguage = (): string => {
	// user override (using flags page)
	const localStorageValue = localStorage.getItem(LANGUAGE_KEY);
	if (localStorageValue) return localStorageValue;

	// default value
	let result: string = DEFAULT_LANGUAGE;

	// Take SCC host language and ignore culture.
	if (sccHostService.isSCC && sccHostService.i18n.language) {
		result = sccHostService.i18n.language.split('-')[0];
	}

	return result;
};
