import { Icon } from '../models/icon.model';
import { iconLibraries } from './icon_libraries';
import { get, memoize } from 'lodash-es';
import { FabricIconName, isFabricIconName } from '@wcd/scc-common';

export class IconsService {
	icons: IconsLeaf;

	constructor() {
		this.setIcons();

		// getIcon is a pure function
		this.getIcon = memoize(this.getIcon);
	}

	getIcon(iconPathOrName: string | FabricIconName): Icon {
		if (isFabricIconName(iconPathOrName)) {
			return {
				name: iconPathOrName,
				library: iconLibraries.fabricReact,
			};
		}

		return get(this.icons, iconPathOrName, null);
	}

	getIconClassName(icon: Icon | string): string {
		if (typeof icon === 'string') icon = this.getIcon(icon);

		return icon
			? icon.library.name + (icon.library.useClass ? ' ' + icon.library.useClass + icon.name : '')
			: '';
	}

	private setIcons() {
		const icons: IconConfig = {
			children: {
				tvm: {
					children: {
						hasExploit: {
							name: 'Bug',
							library: iconLibraries.fabric,
						},
						insight: {
							name: 'Lightbulb',
							library: iconLibraries.fabric,
						},
						isThreatActive: {
							name: 'bullseye',
							library: iconLibraries.mdl2,
						},
					},
				},
				wdatp: {
					children: {
						logo: {
							name: 'ATPLogo',
							library: iconLibraries.fabric,
						},
						win_logo: {
							name: 'MicrosoftLogo',
							library: iconLibraries.mdl2,
						},
						service: {
							name: 'Cloudy',
							library: iconLibraries.fabric,
						},
					},
				},
				hourglass: {
					name: 'HourGlass',
					library: iconLibraries.mdl2,
				},
				shield: {
					name: 'Shield',
					library: iconLibraries.fabric,
				},
				forbidden: {
					name: 'BlockContact',
					library: iconLibraries.fabric,
				},
				newWindow: {
					name: 'OpenInNewWindow',
					library: iconLibraries.fabric,
				},
				calendar: {
					name: 'Calendar',
					library: iconLibraries.fabric,
				},
				signalBars: {
					name: 'FourBars',
					library: iconLibraries.mdl2,
				},
				boxWithArrows: {
					name: 'Remix3D',
					library: iconLibraries.mdl2,
				},
				statistics: {
					name: 'Hourglass',
					library: iconLibraries.mdl2,
				},
				actions: {
					name: 'Org',
					library: iconLibraries.fabric,
				},
				add: {
					name: 'Add',
					library: iconLibraries.fabric,
				},
				alert: {
					name: 'LightningBolt',
					library: iconLibraries.fabric,
				},
				alertClassification: {
					name: 'TriggerApproval',
					library: iconLibraries.fabric,
				},
				alertDetermination: {
					name: 'TriggerAuto',
					library: iconLibraries.fabric,
				},
				alertList: {
					name: 'SetAction',
					library: iconLibraries.fabric,
				},
				alertStatus: {
					name: 'WorkFlow',
					library: iconLibraries.fabric,
				},
				alertWithA: {
					name: 'FlashAuto',
					library: iconLibraries.mdl2,
				},
				alertMapping: {
					name: 'map_alert',
					library: iconLibraries.wcd,
				},
				apps: {
					children: {
						excel: {
							name: 'ExcelLogo ms-color-green',
							library: iconLibraries.fabric,
						},
					},
				},
				arrow: {
					children: {
						down: {
							name: 'Down',
							library: iconLibraries.fabric,
						},
						left: {
							name: 'Back',
							library: iconLibraries.fabric,
						},
						right: {
							name: 'Forward',
							library: iconLibraries.fabric,
						},
						up: {
							name: 'Up',
							library: iconLibraries.fabric,
						},
						sort: {
							children: {
								down: {
									name: 'SortDown',
									library: iconLibraries.fabric,
								},
								up: {
									name: 'SortUp',
									library: iconLibraries.fabric,
								},
							},
						},
					},
				},
				attachment: {
					name: 'Attach',
					library: iconLibraries.fabric,
				},
				back: {
					name: 'Back',
					library: iconLibraries.fabric,
				},
				block: {
					name: 'Blocked',
					library: iconLibraries.fabric,
				},
				box: {
					name: 'box',
					library: iconLibraries.wcd,
				},
				carets: {
					children: {
						left: {
							name: 'ChevronLeft',
							library: iconLibraries.fabric,
						},
						right: {
							name: 'ChevronRight',
							library: iconLibraries.fabric,
						},
						up: {
							name: 'ChevronUp',
							library: iconLibraries.fabric,
						},
						down: {
							name: 'ChevronDown',
							library: iconLibraries.fabric,
						},
					},
				},
				check: {
					name: 'Accept',
					library: iconLibraries.fabric,
				},
				checkCircle: {
					name: 'Completed',
					library: iconLibraries.fabric,
				},
				checkbox: {
					children: {
						checked: {
							name: 'CheckboxCompositeReversed',
							library: iconLibraries.fabric,
						},
						indeterminate: {
							name: 'indeterminate_checkbox',
							library: iconLibraries.wcd,
						},
						unchecked: {
							name: 'Checkbox',
							library: iconLibraries.fabric,
						},
					},
				},
				checkBold: {
					name: 'check_bold',
					library: iconLibraries.wcd,
				},
				chevrons: {
					children: {
						left: {
							name: 'ChevronLeft',
							library: iconLibraries.fabric,
						},
						right: {
							name: 'ChevronRight',
							library: iconLibraries.fabric,
						},
					},
				},
				clear: {
					name: 'Cancel',
					library: iconLibraries.fabric,
				},
				clock: {
					name: 'Clock',
					library: iconLibraries.fabric,
				},
				encyclopedia: {
					name: 'ReadingMode',
					library: iconLibraries.fabric,
				},
				circle: {
					name: 'CircleFill',
					library: iconLibraries.fabric,
				},
				close: {
					name: 'ChromeClose',
					library: iconLibraries.fabric,
				},
				cloud: {
					name: 'Cloud',
					library: iconLibraries.fabric,
				},
				cloudDownload: {
					name: 'CloudDownload',
					library: iconLibraries.fabric,
				},
				cloudUpload: {
					name: 'CloudUpload',
					library: iconLibraries.fabric,
				},
				columns: {
					name: 'ColumnOptions',
					library: iconLibraries.fabric,
				},
				comment: {
					name: 'Comment',
					library: iconLibraries.fabric,
				},
				communications: {
					name: 'Communications',
					library: iconLibraries.fabric,
				},
				connection: {
					name: 'PlugConnected',
					library: iconLibraries.fabric,
				},
				copy: {
					name: 'Copy',
					library: iconLibraries.fabric,
				},
				createInvestigation: {
					name: 'create_investigation',
					library: iconLibraries.wcd,
				},
				dashboard: {
					name: 'SpeedHigh',
					library: iconLibraries.fabric,
				},
				database: {
					name: 'databasde',
					library: iconLibraries.wcd,
				},
				datetime: {
					name: 'DateTime',
					library: iconLibraries.fabric,
				},
				dbSearch: {
					name: 'dbsearch',
					library: iconLibraries.wcd,
				},
				decline: {
					name: 'Dislike',
					library: iconLibraries.fabric,
				},
				delete: {
					name: 'Delete',
					library: iconLibraries.fabric,
				},
				defender: {
					name: 'defender',
					library: iconLibraries.wcd,
				},
				disconnect: {
					name: 'PlugDisconnected',
					library: iconLibraries.fabric,
				},
				download: {
					name: 'Download',
					library: iconLibraries.fabric,
				},
				edit: {
					name: 'EditSolid12',
					library: iconLibraries.fabric,
				},
				email: {
					name: 'Mail',
					library: iconLibraries.fabric,
				},
				phone: {
					name: 'Phone',
					library: iconLibraries.fabric,
				},
				cellphone: {
					name: 'CellPhone',
					library: iconLibraries.fabric,
				},
				poi: {
					name: 'POI',
					library: iconLibraries.fabric,
				},
				endpointManagement: {
					name: 'Sell',
					library: iconLibraries.mdl2,
				},
				entities: {
					_icons: ['certificate', 'connection', 'service', 'persistence_method'],
					library: iconLibraries.wcd,
					children: {
						deleteEmail: {
							name: 'Delete',
							library: iconLibraries.fabric,
						},
						blockSender: {
							name: 'BlockContact',
							library: iconLibraries.fabric,
						},
						mailForwarding: {
							name: 'MailForward',
							library: iconLibraries.fabric,
						},
						commandPrompt: {
							name: 'CommandPrompt',
							library: iconLibraries.fabric,
						},
						file: {
							name: 'Page',
							library: iconLibraries.fabric,
						},
						driver: {
							name: 'BranchFork2',
							library: iconLibraries.fabric,
						},
						email_link: {
							name: 'Link',
							library: iconLibraries.fabric,
						},
						ipaddress: {
							name: 'Streaming',
							library: iconLibraries.fabric,
						},
						module: {
							name: 'Processing',
							library: iconLibraries.fabric,
						},
						process: {
							name: 'Settings',
							library: iconLibraries.fabric,
						},
						registry: {
							name: 'OEM',
							library: iconLibraries.fabric,
						},
						service: {
							name: 'ServerProcesses',
							library: iconLibraries.fabric,
						},
						url: {
							name: 'Website',
							library: iconLibraries.fabric,
						},
						user_activity: {
							name: 'Contact',
							library: iconLibraries.fabric,
						},
						email: {
							name: 'Mail',
							library: iconLibraries.fabric,
						},
						email_cluster: {
							name: 'CreateMailRule',
							library: iconLibraries.fabric,
						},
						mailbox: {
							name: 'Inbox',
							library: iconLibraries.fabric,
						},
						email_submission: {
							name: 'MailAlert',
							library: iconLibraries.fabric,
						},
						mailbox_configuration: {
							name: 'ConfigurationSolid',
							library: iconLibraries.fabric,
						},
						security_group: {
							name: 'SecurityGroup',
							library: iconLibraries.fabric,
						},
						registry_key: {
							name: 'registry',
							library: iconLibraries.wcd,
						},
						registry_value: {
							name: 'registry',
							library: iconLibraries.wcd,
						},
						cloud_application: {
							name: 'Remote',
							library: iconLibraries.fabric,
						},
						cloud_logon_session: {
							name: 'AccountBrowser',
							library: iconLibraries.fabric,
						},
						oauth_application: {
							name: 'AppIconDefault',
							library: iconLibraries.fabric,
						},
						active_directory_domain: {
							name: 'Globe',
							library: iconLibraries.fabric,
						},
						account: {
							name: 'Contact',
							library: iconLibraries.fabric,
						},
					},
				},
				errorCircle: {
					name: 'ErrorBadge',
					library: iconLibraries.fabric,
				},
				errorOutline: {
					name: 'Error',
					library: iconLibraries.fabric,
				},
				events: {
					children: {
						alertRelatedEvent: {
							name: 'Favicon',
							library: iconLibraries.fabric,
						},
						fileCreated: {
							name: 'PageAdd',
							library: iconLibraries.fabric,
						},
						mixedSource: {
							name: 'Admin',
							library: iconLibraries.fabric,
						},
						logon: {
							name: 'UserSync',
							library: iconLibraries.fabric,
						},
					},
				},
				errorFull: {
					name: 'StatusErrorFull',
					library: iconLibraries.fabric,
				},
				exitFullScreen: {
					name: 'BackToWindow',
					library: iconLibraries.fabric,
				},
				export: {
					name: 'Download',
					library: iconLibraries.fabric,
				},
				externalSource: {
					name: 'NavigateExternalInline',
					library: iconLibraries.fabric,
				},
				eye: {
					name: 'RedEye',
					library: iconLibraries.fabric,
				},
				face: {
					children: {
						happy: {
							name: 'Emoji2',
							library: iconLibraries.fabric,
						},
						sad: {
							name: 'EmojiDisappointed',
							library: iconLibraries.fabric,
						},
					},
				},
				fail: {
					name: 'fail',
					library: iconLibraries.wcd,
				},
				feature: {
					name: 'FavoriteStarFill',
					library: iconLibraries.fabric,
				},
				feedback: {
					name: 'Chat',
					library: iconLibraries.fabric,
				},
				feedbackStar: {
					name: 'FavoriteStar',
					library: iconLibraries.fabric,
				},
				file: {
					name: 'Page',
					library: iconLibraries.fabric,
				},
				filter: {
					name: 'Filter',
					library: iconLibraries.fabric,
				},
				filtersOff: {
					name: 'ClearFilter',
					library: iconLibraries.fabric,
				},
				firstAid: {
					name: 'first_aid',
					library: iconLibraries.wcd,
				},
				flag: {
					name: 'Flag',
					library: iconLibraries.fabric,
				},
				folder: {
					name: 'FolderHorizontal',
					library: iconLibraries.fabric,
				},
				folderExclusion: {
					name: 'OpenFolderHorizontal',
					library: iconLibraries.fabric,
				},
				foreach: {
					name: 'foreach',
					library: iconLibraries.wcd,
				},
				forward: {
					name: 'Forward',
					library: iconLibraries.fabric,
				},
				fullScreen: {
					name: 'FullScreen',
					library: iconLibraries.fabric,
				},
				fullyRemediated: {
					name: 'fully_remediated',
					library: iconLibraries.wcd,
				},
				glimmer: {
					name: 'Glimmer',
					library: iconLibraries.fabric,
				},
				globe: {
					name: 'Globe',
					library: iconLibraries.fabric,
				},
				group: {
					name: 'Group',
					library: iconLibraries.fabric,
				},
				handle: {
					name: 'Like',
					library: iconLibraries.fabric,
				},
				health: {
					name: 'Health',
					library: iconLibraries.fabric,
				},
				help: {
					name: 'UnknownSolid',
					library: iconLibraries.fabric,
				},
				help_outline: {
					name: 'Unknown',
					library: iconLibraries.fabric,
				},
				help_fabric: {
					name: 'Help',
					library: iconLibraries.fabric,
				},
				wcd: {
					name: 'wcd',
					library: iconLibraries.wcd,
				},
				hide: {
					name: 'Hide',
					library: iconLibraries.fabric,
				},
				incident: {
					name: 'ATPLogo',
					library: iconLibraries.fabric,
				},
				view: {
					name: 'View',
					library: iconLibraries.fabric,
				},
				list: {
					children: {
						single: {
							name: 'BulletedList2',
							library: iconLibraries.fabric,
						},
						grouped: {
							name: 'ThumbnailView',
							library: iconLibraries.fabric,
						},
					},
				},
				tag: {
					name: 'Tag',
					library: iconLibraries.fabric,
				},
				hosts: {
					children: {
						host: {
							name: 'System',
							library: iconLibraries.fabric,
						},
						multiple: {
							name: 'computers',
							library: iconLibraries.wcd,
						},
						status: {
							children: {
								failed: {
									name: 'host_failed',
									library: iconLibraries.wcd,
								},
								remediated: {
									name: 'host_remediated',
									library: iconLibraries.wcd,
								},
								clean: {
									name: 'host_clean',
									library: iconLibraries.wcd,
								},
								infected: {
									name: 'host_infected',
									library: iconLibraries.wcd,
								},
								unavailable: {
									name: 'host_unavailable',
									library: iconLibraries.wcd,
								},
							},
						},
						run: {
							name: 'host_run',
							library: iconLibraries.wcd,
						},
						uncoveredHost: {
							name: 'uncovered_host',
							library: iconLibraries.wcd,
						},
					},
				},
				hunting: {
					name: 'hunting',
					library: iconLibraries.wcd,
				},
				import: {
					name: 'ImportMirrored',
					library: iconLibraries.fabric,
				},
				info: {
					name: 'Info',
					library: iconLibraries.fabric,
				},
				investigation: {
					name: 'investigation',
					library: iconLibraries.wcd,
				},
				investigationOutline: {
					name: 'investigationoutline',
					library: iconLibraries.wcd,
				},
				investigations: {
					children: {
						status: {
							children: {
								benign: {
									name: 'invstatus_zebra',
									library: iconLibraries.wcd,
								},
								failed: {
									name: 'invstatus_fail',
									library: iconLibraries.wcd,
								},
								partial: {
									name: 'invstatus_partial',
									library: iconLibraries.wcd,
								},
								paused: {
									name: 'invstatus_paused',
									library: iconLibraries.wcd,
								},
								success: {
									name: 'invstatus_success',
									library: iconLibraries.wcd,
								},
								systemTerminated: {
									name: 'invstatus_minus',
									library: iconLibraries.wcd,
								},
								userTerminated: {
									name: 'invstatus_minus',
									library: iconLibraries.wcd,
								},
								empty: {
									name: 'invstatus_empty',
									library: iconLibraries.wcd,
								},
							},
						},
					},
				},
				legal: {
					name: 'legal',
					library: iconLibraries.wcd,
				},
				lightbulb: {
					name: 'Lightbulb',
					library: iconLibraries.fabric,
				},
				link: {
					name: 'Link',
					library: iconLibraries.fabric,
				},
				lock: {
					name: 'Lock',
					library: iconLibraries.fabric,
				},
				logout: {
					name: 'NavigateExternalInline',
					library: iconLibraries.fabric,
				},
				mail: {
					children: {
						incoming: {
							name: 'incoming_mail',
							library: iconLibraries.wcd,
						},
						outgoing: {
							name: 'outgoing_mail',
							library: iconLibraries.wcd,
						},
					},
				},
				malware: {
					name: 'BugSolid',
					library: iconLibraries.fabric,
				},
				malwareOutline: {
					name: 'Bug',
					library: iconLibraries.fabric,
				},
				memory: {
					name: 'memory',
					library: iconLibraries.wcd,
				},
				menu: {
					children: {
						horizontal: {
							name: 'More',
							library: iconLibraries.fabric,
						},
						vertical: {
							name: 'MoreVertical',
							library: iconLibraries.fabric,
						},
					},
				},
				merge: {
					name: 'merge',
					library: iconLibraries.wcd,
				},
				message: {
					name: 'Message',
					library: iconLibraries.fabric,
				},
				mixedMediaBadge: {
					name: 'mixed_media_badge',
					library: iconLibraries.wcd,
				},
				navigateForward: {
					name: 'NavigateForward',
					library: iconLibraries.fabric,
				},
				network: {
					name: 'actions',
					library: iconLibraries.wcd,
				},
				notification: {
					name: 'Notification',
					library: iconLibraries.mdl2,
				},
				office: {
					name: 'OfficeLogo',
					library: iconLibraries.fabric,
				},
				organization: {
					name: 'CityNext',
					library: iconLibraries.mdl2,
				},
				os: {
					_icons: ['linux', 'mac', 'windows'],
					library: iconLibraries.wcd,
				},
				page: {
					children: {
						edit: {
							name: 'PageEdit',
							library: iconLibraries.fabric,
						},
					},
				},
				pane: {
					children: {
						openRight: {
							name: 'ClosePane',
							library: iconLibraries.fabric,
						},
					},
				},
				parallel: {
					name: 'CollapseMenu',
					library: iconLibraries.fabric,
				},
				pause: {
					name: 'Pause',
					library: iconLibraries.fabric,
				},
				permissions: {
					name: 'Permissions',
					library: iconLibraries.fabric,
				},
				phishing: {
					name: 'phishing',
					library: iconLibraries.wcd,
				},
				playbook: {
					name: 'playbook',
					library: iconLibraries.wcd,
				},
				playOutline: {
					name: 'MSNVideos',
					library: iconLibraries.fabric,
				},
				plug: {
					name: 'Plug',
					library: iconLibraries.fabric,
				},
				popOut: {
					name: 'OpenInNewWindow',
					library: iconLibraries.fabric,
				},
				preferences: {
					name: 'Equalizer',
					library: iconLibraries.fabric,
				},
				preferencesSetup: {
					name: 'Repair',
					library: iconLibraries.fabric,
				},
				provisioningPackage: {
					name: 'provisioning_package',
					library: iconLibraries.wcd,
				},
				protectionLevel: {
					children: {
						fullyAutomatic: {
							name: 'wcd',
							library: iconLibraries.wcd,
						},
						semiAutomatic: {
							name: 'semi_automatic',
							library: iconLibraries.wcd,
						},
						manual: {
							name: 'user',
							library: iconLibraries.wcd,
						},
						notFound: {
							name: 'Blocked',
							library: iconLibraries.fabric,
						},
					},
				},
				queue: {
					name: 'BuildQueue',
					library: iconLibraries.fabric,
				},
				radio: {
					children: {
						checked: {
							name: 'RadioBtnOn',
							library: iconLibraries.fabric,
						},
						unchecked: {
							name: 'RadioBtnOff',
							library: iconLibraries.fabric,
						},
					},
				},
				refresh: {
					name: 'Refresh',
					library: iconLibraries.fabric,
				},
				registry: {
					name: 'registry',
					library: iconLibraries.wcd,
				},
				remediationActions: {
					children: {
						blockIp: {
							name: 'Blocked',
							library: iconLibraries.fabric,
						},
						blockUrl: {
							name: 'block_url',
							library: iconLibraries.wcd,
						},
						closeConnection: {
							name: 'close_connection',
							library: iconLibraries.wcd,
						},
						killProcess: {
							name: 'kill_process',
							library: iconLibraries.wcd,
						},
						package: {
							name: 'FabricFormLibrary',
							library: iconLibraries.fabric,
						},
						pauseProcess: {
							name: 'pause_process',
							library: iconLibraries.wcd,
						},
						resumeProcess: {
							name: 'resume_process',
							library: iconLibraries.wcd,
						},
						quarantineFile: {
							name: 'quarantine_file',
							library: iconLibraries.wcd,
						},
						removeFile: {
							name: 'Delete',
							library: iconLibraries.fabric,
						},
						quarantineService: {
							name: 'quarantine_service',
							library: iconLibraries.wcd,
						},
						quarantineDriver: {
							name: 'quarantine_driver',
							library: iconLibraries.wcd,
						},
						quarantinePersistenceMethod: {
							name: 'quarantine_persistence_methods',
							library: iconLibraries.wcd,
						},
						undoCleanResource: {
							name: 'History',
							library: iconLibraries.fabric,
						},
					},
				},
				remoteHelp: {
					name: 'remote_help',
					library: iconLibraries.wcd,
				},
				remove: {
					name: 'Cancel',
					library: iconLibraries.fabric,
				},
				ring: {
					name: 'CircleRing',
					library: iconLibraries.fabric,
				},
				runQuery: {
					name: 'CaretRight',
					library: iconLibraries.fabric,
				},
				save: {
					name: 'Save',
					library: iconLibraries.fabric,
				},
				expand: {
					name: 'CircleAddition',
					library: iconLibraries.fabric,
				},
				expand_full: {
					name: 'CircleAdditionSolid',
					library: iconLibraries.fabric,
				},
				collapse: {
					name: 'Blocked2',
					library: iconLibraries.fabric,
				},
				collapse_full: {
					name: 'SkypeCircleMinus',
					library: iconLibraries.fabric,
				},
				schema: {
					name: 'schema',
					library: iconLibraries.wcd,
				},
				screenshot: {
					name: 'DesktopScreenshot',
					library: iconLibraries.fabric,
				},
				share: {
					name: 'Share',
					library: iconLibraries.fabric,
				},
				search: {
					name: 'Search',
					library: iconLibraries.fabric,
				},
				services: {
					children: {
						windowsdefenderatp: {
							name: 'AtpLogo',
							library: iconLibraries.fabric,
						},
						windowsdefenderav: {
							name: 'defender',
							library: iconLibraries.wcd,
						},
						windowsfirewall: {
							name: 'wall',
							library: iconLibraries.wcd,
						},
					},
				},
				settings: {
					name: 'Settings',
					library: iconLibraries.fabric,
				},
				skype: {
					name: 'SkypeForBusinessLogo',
					library: iconLibraries.fabric,
				},
				sortLines: {
					name: 'sortLines',
					library: iconLibraries.fabric,
				},
				square: {
					name: 'StopSolid',
					library: iconLibraries.fabric,
				},
				stop: {
					name: 'square',
					library: iconLibraries.wcd,
				},
				stopInvestigation: {
					name: 'stop_investigation',
					library: iconLibraries.wcd,
				},
				storage: {
					name: 'HardDriveGroup',
					library: iconLibraries.fabric,
				},
				success: {
					name: 'CompletedSolid',
					library: iconLibraries.fabric,
				},
				suppressionRule: {
					name: 'FlashOff',
					library: iconLibraries.mdl2,
				},
				suspicious: {
					name: 'Error',
					library: iconLibraries.fabric,
				},
				syringe: {
					name: 'syringe',
					library: iconLibraries.wcd,
				},
				table: {
					name: 'Table',
					library: iconLibraries.fabric,
				},
				timeEntry: {
					name: 'TimeEntry',
					library: iconLibraries.fabric,
				},
				timelapse: {
					name: 'Timer',
					library: iconLibraries.fabric,
				},
				timeout: {
					name: 'BufferTimeAfter',
					library: iconLibraries.fabric,
				},
				undo: {
					name: 'Undo',
					library: iconLibraries.fabric,
				},
				upload: {
					name: 'Upload',
					library: iconLibraries.fabric,
				},
				userFeedback: {
					name: 'Feedback',
					library: iconLibraries.fabric,
				},
				users: {
					_icons: ['user_attention', 'regular_user', 'admin_user'],
					library: iconLibraries.wcd,
					children: {
						user: {
							name: 'Contact',
							library: iconLibraries.fabric,
						},
						userCheck: {
							name: 'ReminderPerson',
							library: iconLibraries.fabric,
						},
						user_add: {
							name: 'AddFriend',
							library: iconLibraries.fabric,
						},
						domain: {
							name: 'People',
							library: iconLibraries.fabric,
						},
					},
				},
				visibility: {
					children: {
						off: {
							name: 'Hide',
							library: iconLibraries.fabric,
						},
						on: {
							name: 'RedEye',
							library: iconLibraries.fabric,
						},
					},
				},
				waffle: {
					name: 'Waffle',
					library: iconLibraries.fabric,
				},
				wall: {
					name: 'wall',
					library: iconLibraries.wcd,
				},
				warning: {
					name: 'Warning',
					library: iconLibraries.fabric,
				},
				warningSolid: {
					name: 'IncidentTriangle',
					library: iconLibraries.fabric,
				},
				lineChart: {
					name: 'LineChart',
					library: iconLibraries.fabric,
				},
				reportHacked: {
					name: 'ReportHacked',
					library: iconLibraries.mdl2,
				},
				castle: {
					name: 'Castle',
					library: iconLibraries.mdl2,
				},
				zip: {
					name: 'ZipFolder',
					library: iconLibraries.fabric,
				},
			},
		};

		// Aliases:
		icons.children['approve'] = icons.children['handle'];
		icons.children['cancel'] = icons.children['remove'] = icons.children['clear'];
		icons.children['protectionLevel'].children['notProtected'] = icons.children[
			'protectionLevel'
		].children['uncovered'] = icons.children['protectionLevel'].children['manual'];
		icons.children['protectionLevel'].children['limitedAutomation'] = icons.children[
			'protectionLevel'
		].children['restrictedAutomation'] = icons.children['protectionLevel'].children['userDefined'] =
			icons.children['protectionLevel'].children['semiAutomatic'];
		icons.children['error'] = icons.children['suspicious'];
		icons.children['notificationRule'] = icons.children['mail'].children['outgoing'];
		icons.children['exclusion'] = icons.children['stopInvestigation'];
		icons.children['customTiIndicators'] = icons.children['stopInvestigation'];

		icons.children['reports'] = {
			children: {
				investigations: icons.children['investigation'],
				hosts: icons.children['hosts'].children['multiple'],
				threats: icons.children['malware'],
				phishing: icons.children['phishing'],
			},
		};

		this.icons = this.parseIcons(icons);

		this.icons['entities']['ip_address'] = this.icons['entities']['ipaddress'];

		this.icons['entities']['properties'] = {
			is_downloaded: this.icons['download'],
			is_hidden: this.icons['visibility']['off'],
			is_packed: this.icons['box'],
			is_read_only: this.icons['lock'],
			is_loaded: this.icons['publish'],
		};
	}

	private parseIcons(root: IconConfig): IconsLeaf {
		const icons: IconsLeaf = {};

		if (root._icons) {
			root._icons.forEach((iconName) => {
				icons[iconName] = new Icon(iconName, root.library);
			});
		}

		if (root.children) {
			let icon: IconConfig;
			for (const iconName in root.children) {
				icon = root.children[iconName];

				if (icon.name) icons[iconName] = new Icon(icon.name, icon.library);
				else icons[iconName] = this.parseIcons(icon);
			}
		}

		return icons;
	}
}

interface IconsLeaf {
	[key: string]: Icon | IconsLeaf;
}

interface IconConfig {
	name?: string;
	library?: { name: string };
	_icons?: Array<string>;
	children?: { [index: string]: IconConfig };
}
