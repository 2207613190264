import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { registryOperationValues } from './registry-operation.values';

@Entity({
	singularName: 'Registry Operation',
	pluralName: 'Registry Operation',
	readonly: true,
	values: registryOperationValues,
})
export class RegistryOperation extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() operation: string;
}
