<modal (close)="destroy()" [settings]="settings" data-track-component="CancelInvestigationModal">
	<form
		#cancelInvestigationForm="ngForm"
		class="wcd-full-height wcd-flex-vertical"
		(submit)="submitCancelInvestigation()"
	>
		<div class="wcd-flex-1 dialog-contents">
			<p>
				{{ investigationCount === 1 ? (reasonsType === 'host' ?
				i18nService.strings.investigation_cancel_modal_title_singular_host :
				i18nService.strings.investigation_cancel_modal_title_singular ):
				i18nService.strings.investigation_cancel_modal_title_plural }}
			</p>
			<div class="form-group">
				<select
					class="form-control"
					[(ngModel)]="selectedReasonName"
					name="reason"
					required
					(ngModelChange)="setCurrentSelectedReason()"
				>
					<option value="" *ngIf="!selectedReasonName"
						>{{i18nService.strings.investigation_cancel_modal_reasonPlaceholder}}</option
					>
					<option *ngFor="let reason of cancelReasons" [value]="reason.name"
						>{{i18nService.strings[reason.i18nKey] || reason.name}}</option
					>
				</select>
			</div>
			<div class="form-group">
				<textarea
					[(ngModel)]="comment"
					*ngIf="selectedReason && selectedReason.requireText"
					class="form-control"
					rows="4"
					name="comment"
					required
					[placeholder]="this.i18nService.strings.investigation_cancel_modal_customPlaceholder"
				></textarea>
			</div>
		</div>
		<footer class="dialog-footer wcd-flex-none right-text">
			<button
				type="submit"
				data-track-id="CancelInvestigation"
				data-track-type="Button"
				[disabled]="!cancelInvestigationForm.form.valid"
				class="btn btn-primary"
			>
			{{i18nService.strings.buttons_confirm}}
			</button>
			<button
				type="button"
				data-track-id="CancelCancelInvestigation"
				data-track-type="Button"
				(click)="close()"
				class="btn btn-cancel"
			>
			{{i18nService.strings.buttons_cancel}}
			</button>
		</footer>
	</form>
</modal>
