import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'User Profile',
	pluralName: 'User Profiles',
	readonly: true,
})
export class LegacyUserProfile extends ModelBase {
	@EntityField({ data: 'DisplayName' })
	readonly name: string;

	@EntityField({ data: 'Email' })
	readonly email: string;

	@EntityField({ data: 'Sip' })
	readonly sip: string;

	@EntityField({ data: 'Phone' })
	readonly phone: string;

	@EntityField({ data: 'ThumbnailPhotoSrc' })
	readonly thumbnailPhotoSrc: string;

	@EntityField({ data: 'Title' })
	readonly title: string;

	@EntityField({ data: 'Department' })
	readonly department: string;
}
