import { Injectable } from '@angular/core';
import { ActionabilityType } from '@wcd/domain';
import { KnownColorName } from '../../../shared/models/colors/known-colors.models';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';

const actionabilityTypeColorName: Record<ActionabilityType, KnownColorName> = {
	[ActionabilityType.Actionable]: 'blue',
	[ActionabilityType.NonActionable]: 'neutralQuaternary',
};

@Injectable()
export class ActionabilityTypeColorService extends ColorsService<ActionabilityType> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, actionabilityTypeColorName);
	}
}
