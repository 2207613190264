/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./status.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../projects/shared/src/lib/pipes/basePath.pipe";
import * as i7 from "../../../../../../projects/config/src/lib/services/app-context.service";
import * as i8 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "./status.component";
var styles_StatusComponent = [i0.styles];
var RenderType_StatusComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_StatusComponent, data: {} });
export { RenderType_StatusComponent as RenderType_StatusComponent };
function View_StatusComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-shared-icon", [], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"], iconClass: [1, "iconClass"], ariaLabel: [2, "ariaLabel"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status.iconName; var currVal_1 = (_co.status.className ? ("color-text-" + _co.status.className) : ""); var currVal_2 = _co.ariaLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_StatusComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["class", "status-img"]], [[8, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent, 0), _co.status.image)); _ck(_v, 0, 0, currVal_0); }); }
function View_StatusComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_4)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status.image; _ck(_v, 2, 0, currVal_0); }, null); }
function View_StatusComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(0, [["checkImg", 2]], null, 0, null, View_StatusComponent_3)), (_l()(), i1.ɵted(6, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(7, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status.iconName; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = (_co.status.nameI18nKey ? i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 1), _co.status.nameI18nKey)) : _co.status.name); _ck(_v, 6, 0, currVal_2); }); }
export function View_StatusComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i6.BasePathPipe, [i7.AppContextService]), i1.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_StatusComponent_1)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.status; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_StatusComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-status", [], null, null, null, View_StatusComponent_0, RenderType_StatusComponent)), i1.ɵdid(1, 49152, null, 0, i10.StatusComponent, [], null, null)], null, null); }
var StatusComponentNgFactory = i1.ɵccf("wcd-status", i10.StatusComponent, View_StatusComponent_Host_0, { status: "status", ariaLabel: "ariaLabel" }, {}, []);
export { StatusComponentNgFactory as StatusComponentNgFactory };
