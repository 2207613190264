import { Entity, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { RecommendationBase } from '../common/recommendation/recommendation.entity.base';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Security Recommendation',
	pluralName: 'Security Recommendations',
	endpoint: 'recommendations',
	readonly: true,
})
export class AssetRecommendation extends RecommendationBase {
	@EntityField({ data: 'assetId', required: false })
	readonly assetId?: string;
}
