var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectorRef, OnInit, EventEmitter, } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { MachinesService } from '../../../services/machines.service';
import { TvmTextsService, TextToken } from '../../../../../tvm/services/tvm-texts.service';
var MachineValuePanelComponent = /** @class */ (function (_super) {
    __extends(MachineValuePanelComponent, _super);
    function MachineValuePanelComponent(router, machinesService, i18nService, tvmTextService, dialogsService, changeDetector) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.machinesService = machinesService;
        _this.i18nService = i18nService;
        _this.tvmTextService = tvmTextService;
        _this.dialogsService = dialogsService;
        _this.changeDetector = changeDetector;
        _this.SpinnerSize = SpinnerSize;
        _this.onMachineValueChanged = new EventEmitter();
        _this.isSaving = false;
        _this.formatLabel = function (machineValueConfig) { return machineValueConfig.displayName; };
        _this.dropdownLabel = i18nService.get('machines_entityDetails_actions_machineValue_title');
        _this.values = machinesService.machineValues;
        _this.description = tvmTextService.getText(TextToken.HighValueAssetDescription);
        return _this;
    }
    MachineValuePanelComponent.prototype.setUpValue = function () {
        var _this = this;
        var isAllMachinesSameValue = this.machines.reduce(function (prev, curr) {
            return prev && curr.assetValue === _this.machines[0].assetValue;
        }, true);
        if (isAllMachinesSameValue) {
            this.value = this.values.find(function (val) { return val.id.toUpperCase() === _this.machines[0].assetValue.toUpperCase(); });
        }
        this.referenceValue = this.value;
    };
    MachineValuePanelComponent.prototype.ngOnInit = function () {
        this.setUpValue();
        _super.prototype.ngOnInit.call(this);
    };
    MachineValuePanelComponent.prototype.onValueChange = function (e) {
        this.value = this.values.find(function (val) { return val.id === e.id; });
    };
    MachineValuePanelComponent.prototype.submitRequest = function () {
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        this.messageBarType = MessageBarType.info;
        this.messageBarText = this.i18nService.get('machines_entityDetails_actions_machineValue_submitting');
        this.onMachineValueChanged.emit(this.value.id);
    };
    MachineValuePanelComponent.prototype.requestSuccess = function () {
        this.isSaving = false;
        this.setUpValue();
        this.changeDetector.markForCheck();
        this.dialogsService.showSuccessSnackbar({
            text: this.i18nService.get(this.machines.length > 1
                ? "machines_entityDetails_actions_machineValue_saved_plural"
                : "machines_entityDetails_actions_machineValue_saved_singular"),
        });
    };
    MachineValuePanelComponent.prototype.requestFail = function (e) {
        this.isSaving = false;
        this.changeDetector.markForCheck();
        this.dialogsService.showError({
            title: this.i18nService.get('machines_entityDetails_actions_machineValue_saved_fail'),
            data: e,
        });
    };
    return MachineValuePanelComponent;
}(PanelContainer));
export { MachineValuePanelComponent };
