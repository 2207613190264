/* tslint:disable:template-click-events-have-key-events */
import { RecommendationExceptionService } from './../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnInit,
	OnDestroy,
	Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModelBase } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { CalculateRemainingDaysPipe } from '../../../shared/pipes/calculate-remaining-days.pipe';
import { RecommendationException, ExceptionJustification } from '@wcd/domain';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'recommendation-exception-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './recommendation-exception.entity-details.component.html',
})
export class RecommendationExceptionEntityDetailsComponent
	extends EntityDetailsComponentBase<RecommendationException>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	private _relatedRecommendationId: string;
	remediationType: string;
	relatedComponent: string;

	@Input() recommendationException?: RecommendationException;

	@Output()
	loggedOnUsersClick: EventEmitter<RecommendationException> = new EventEmitter<RecommendationException>();

	constructor(
		private activatedEntity: ActivatedEntity,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private router: Router,
		injector: Injector,
		private tvmTextsService: TvmTextsService,
		private recommendationExceptionService: RecommendationExceptionService
	) {
		super(injector);
	}
	ngOnInit() {
		if (!this.recommendationException) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof RecommendationException))
				.subscribe((recommendationException: RecommendationException) => {
					this.recommendationException = recommendationException;
					this.initPropertiesFromRemediationTask();
				});
		} else {
			this.initPropertiesFromRemediationTask();
		}
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	navigateToRecommendations(): void {
		this.router.navigate(['/security-recommendations', this._relatedRecommendationId], {
			queryParams: {
				search: `${this.recommendationExceptionService.getRelatedRecommendationId(
					this.recommendationException,
					false
				)}`,
			},
		});
	}

	getRemediationType() {
		return this.tvmTextsService.getText(TextToken.ExceptionRemediationType, this.recommendationException);
	}

	getJustificationLabel(justification: ExceptionJustification): string {
		return this.recommendationExceptionService.getJustificationLabel(justification);
	}

	navigateToRelatedComponent() {
		this.router.navigate([
			'/software-inventory',
			this.recommendationException.exceptionArgs.vaRecommendationArgs.productId,
		]);
	}

	private remainingDays(recommendationException: RecommendationException) {
		const remainingDays = this.calculateRemainingDaysPipe.transform(recommendationException.expiringOn);
		if (recommendationException.status.value !== 'Active') {
			return '-';
		} else {
			return `${remainingDays} days`;
		}
	}

	private initPropertiesFromRemediationTask() {
		this._relatedRecommendationId = this.recommendationExceptionService.getRelatedRecommendationId(
			this.recommendationException
		);
		this.remediationType = this.getRemediationType();
		this.relatedComponent = this.tvmTextsService.getText(
			TextToken.RecommendationExceptionRelatedComponent,
			this.recommendationException
		);
	}
}
