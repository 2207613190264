import { Input, Component, OnInit } from '@angular/core';
import { Machine, AlertUser, LegacyUser } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { MachinesFieldsService } from '../../machines/services/machines.fields';
import { DataTableField } from '@wcd/datatable';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { LegacyUsersFieldsService } from '../../users/services/legacy-users.fields.service';

const MACHINE_FIELDS = {
	machinename: 1,
	riskScores: 1,
};

const USER_FIELDS = {
	user: 1,
};

@Component({
	selector: 'alert-impacted-assets',
	templateUrl: './alert-impacted-assets.component.html',
})
export class AlertImpactedAssetsComponent implements OnInit {
	@Input() machine?: Machine;
	@Input() user?: AlertUser;

	machineFields: Array<DataTableField> = new Array<DataTableField>();
	userFields: Array<DataTableField> = new Array<DataTableField>();

	constructor(
		private i18nService: I18nService,
		private machineFieldsService: MachinesFieldsService,
		private usersFieldsService: LegacyUsersFieldsService,
		private entityPanelsService: EntityPanelsService
	) {}

	ngOnInit() {
		this.setMachineFields();
		this.setUserFields();
	}

	private setMachineFields() {
		this.machineFields = this.machineFieldsService.fields
			.filter(field => MACHINE_FIELDS[field.id])
			.map(field => ({
				...field,
				useDefaultEmptyFieldComponent: true,
			}))
			.map(field => new DataTableField<Machine>(field));
	}

	private setUserFields() {
		this.userFields = this.usersFieldsService.fieldsWithIncident
			.filter(field => USER_FIELDS[field.id])
			.map(field => ({
				...field,
				useDefaultEmptyFieldComponent: true,
			}))
			.map(field => new DataTableField<LegacyUser>(field));
	}

	showMachineSidePanel(machine: Machine) {
		this.entityPanelsService.showEntities(Machine, [machine]);
	}

	showUserSidePanel(user: LegacyUser) {
		this.entityPanelsService.showEntities(LegacyUser, [user]);
	}
}
