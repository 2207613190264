import { Injectable, ComponentRef, OnDestroy } from '@angular/core';
import { RbacService } from '../../rbac/services/rbac.service';
import { Observable, ReplaySubject, Subscription } from 'rxjs';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { MachineGroupsFilterPanelComponent } from '../components/machine-groups-filter/machine-groups-filter-panel/machine-groups-filter-panel.component';
import { PanelType } from '@wcd/panels';
import { tap, takeWhile, filter, skip } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import {
	OrgExposureScoreApiCall,
	CountVulnerabilitiesBySeverityApiCall,
	CountMisconfigurationsBySeverityApiCall,
	RecommendationFiltersApiCall,
	TotalSecureScoreApiCall,
	CategorySecureScoreApiCall,
	AssetsByExposureCategoryApiCall,
	ComplianceOverTimeApiCall,
	DeviceComplianceStatisticsApiCall,
} from '@wcd/domain';
import { Dictionary } from '@wcd/config';

export interface MachineGroupsFilterData {
	isFiltering: boolean; //helper that is true iff filtering is on and should be done - if at least one machineGroup is selected (but not all are)
	machineGroups: {
		groupId: number;
		groupName: string;
		isSelected: boolean; //whether the group is wanted when filtering is on.
	}[];
}

@Injectable({
	providedIn: 'root',
})
export class TvmMachineGroupsFilterService implements OnDestroy {
	private _machineGroupsFilterData: MachineGroupsFilterData;

	private _machineGroupsFilter$: ReplaySubject<MachineGroupsFilterData>;
	private _machineGroupPollSubscription: Subscription;
	get machineGroupsFilter$(): Observable<MachineGroupsFilterData> {
		return this._machineGroupsFilter$;
	}

	machineGroupsFilterChange$: Observable<MachineGroupsFilterData>;

	constructor(
		private rbacService: RbacService,
		private dialogsService: DialogsService,
		private paris: Paris
	) {
		this._machineGroupsFilter$ = new ReplaySubject<MachineGroupsFilterData>(1);

		this.machineGroupsFilterChange$ = this._machineGroupsFilter$.pipe(skip(1));

		let continueTaking = true;

		// Fetch current selection from window variable (in SCC this service is re-created on each page navigation)
		const previousState: MachineGroupsFilterData = (<any>window)._tvm_machineGroupsFilterData;
		const currentGroupSelection = new Map<number, boolean>();

		if (previousState && previousState.machineGroups) {
			previousState.machineGroups.forEach(group => {
				currentGroupSelection.set(group.groupId, group.isSelected);
			});
		}

		this._machineGroupPollSubscription = this.rbacService.userExposedRbacGroups$
			.pipe(
				filter(val => !!val),
				takeWhile(() => continueTaking),
				tap(() => (continueTaking = false))
			)
			.subscribe(machineGroups => {
				this._machineGroupsFilterData = {
					isFiltering: false,
					machineGroups: machineGroups.map(mg => ({
						groupId: mg.id,
						groupName: mg.name,
						isSelected: currentGroupSelection.get(mg.id) || false,
					})),
				};

				(<any>window)._tvm_machineGroupsFilterData = this._machineGroupsFilterData; //TODO: tmp bridge between TvmMachineGroupsFilterService and the domain (to be used by Entity's "parseDataQuery" to send the groups via query param). fix by extending Paris to pass ParisConfig.data as an arg to parseDataQuery => then we can assign this service instance to the data and use it on parseDataQuery.
				this._machineGroupsFilter$.next(this._machineGroupsFilterData);
			});
	}

	public openMachineGroupsFilterPanel() {
		this.dialogsService
			.showPanel(
				MachineGroupsFilterPanelComponent,
				{
					id: 'machine-groups-filter-panel',
					isModal: true,
					showOverlay: false,
					hasCloseButton: true,
					type: PanelType.large,
					role: 'none'
				},
				{
					machineGroupsFilter$: this.machineGroupsFilter$,
				}
			)
			.subscribe((panel: ComponentRef<any>) => {
				panel.instance.onFiltersChange.subscribe(
					(selectedMachineGroupIds: Array<number | string>) => {
						panel.destroy();
						this.handleFilteringChange(selectedMachineGroupIds);
					}
				);
			});
	}

	private handleFilteringChange(selectedMachineGroupIds) {
		const allSelected =
			selectedMachineGroupIds.length === this._machineGroupsFilterData.machineGroups.length;

		// if filtering is off and for some reason the user selected all groups, its not a real change so we shouldn't do nothing
		if (!this._machineGroupsFilterData.isFiltering && allSelected) {
			return;
		}

		this._machineGroupsFilterData.isFiltering =
			selectedMachineGroupIds.length && !allSelected ? true : false;

		this._machineGroupsFilterData.machineGroups.forEach(machineGroup => {
			machineGroup.isSelected = false;
			if (
				this._machineGroupsFilterData.isFiltering &&
				selectedMachineGroupIds.find(id => id === machineGroup.groupId)
			) {
				machineGroup.isSelected = true;
			}
		});

		//without clearing the apiCall caches paris will return cached, non filtered data
		this.paris.clearApiCallCache([
			OrgExposureScoreApiCall,
			CountVulnerabilitiesBySeverityApiCall,
			CountMisconfigurationsBySeverityApiCall,
			RecommendationFiltersApiCall,
			TotalSecureScoreApiCall,
			CategorySecureScoreApiCall,
			AssetsByExposureCategoryApiCall,
			ComplianceOverTimeApiCall,
			DeviceComplianceStatisticsApiCall
		]);

		this._machineGroupsFilter$.next(this._machineGroupsFilterData);
	}

	ngOnDestroy(): void {
		this._machineGroupsFilter$.complete();
		this._machineGroupPollSubscription && this._machineGroupPollSubscription.unsubscribe();
	}
}
