import { Injectable } from '@angular/core';
import { Dictionary } from '@wcd/config';
import { MdeUserRoleAction } from '@wcd/domain';
import { Paris, Repository } from '@microsoft/paris';

@Injectable()
export class UserRoleActionsService {
	private _userRoleActionsMap: Dictionary<string, MdeUserRoleAction>;

	get userRoleActionsMap(): Dictionary<string, MdeUserRoleAction> {
		if (!this._userRoleActionsMap) {
			const userRoleActionsRepo: Repository<MdeUserRoleAction> = this.paris.getRepository(MdeUserRoleAction);
			this._userRoleActionsMap = Dictionary.fromList(
				MdeUserRoleAction.flatten(userRoleActionsRepo.entity.values),
				'name'
			);
		}
		return this._userRoleActionsMap;
	}

	constructor(private paris: Paris) {}
}
