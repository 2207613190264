import { I18nService } from '@wcd/i18n';
import { SCC_BASE_URL } from '../../shared/main/services/main-nav-config.service';
import { TryItUrls } from '../enums/try-it-urls';
import { MtpPromotionService } from '../services/mtp-promotion.service';
var PromotionBannerComponent = /** @class */ (function () {
    function PromotionBannerComponent(i18nService, mtpPromotionService) {
        var _this = this;
        this.i18nService = i18nService;
        this.mtpPromotionService = mtpPromotionService;
        this.close = function () {
            _this.navigateTo = null;
            _this.mtpPromotionService.dismissMtpPromotion(true);
        };
    }
    Object.defineProperty(PromotionBannerComponent.prototype, "textMessageKey", {
        set: function (value) {
            this.text = this.i18nService.get(value) + this.i18nService.get('mtpPromotion.learnMoreLink');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PromotionBannerComponent.prototype, "url", {
        set: function (value) {
            this.navigateTo = SCC_BASE_URL + "/" + value;
        },
        enumerable: true,
        configurable: true
    });
    return PromotionBannerComponent;
}());
export { PromotionBannerComponent };
