import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EvaluationPasswordDialogComponent } from './components/evaluation-password-dialog.component';
import { FabTextFieldModule } from '@angular-react/fabric';

@NgModule({
	imports: [SharedModule, FabTextFieldModule],
	declarations: [EvaluationPasswordDialogComponent],
	entryComponents: [EvaluationPasswordDialogComponent],
	exports: [EvaluationPasswordDialogComponent],
})
export class EvaluationPasswordModule {}
