import { Params } from '@angular/router';
import { QueryParamsHandling } from '../tabs/tab.model';

export class WizardStepModel {
	id: number;
	name: string;
	text?: string;
	subText?: string;
	className?: string;
	icon?: string;
	iconClassName?: string;
	routerLink?: string | Array<string>;
	routerLinkQueryParams?: Params;
	routerQueryParamsHandling?: QueryParamsHandling;
	routerLinkActiveClass?: string;
	disabled?: boolean;
	data?: any;
	value?: any;
	tooltip?: string;
	show?: boolean;
	hideNavigationButtons?: boolean;
	hidePageTitle?: boolean;
	actionButtonSettings?: {
		onActionButtonClick: () => Promise<void>;
		label?: string;
	};
	validation?: () => boolean;

	constructor(data: WizardStepModelConfig) {
		Object.assign(this, data);
	}

	isValid?(): boolean {
		return this.validation ? this.validation() : true;
	}
}

export interface WizardStepModelConfig {
	id: number;
	name?: string;
	text?: string;
	subText?: string;
	className?: string;
	icon?: string;
	iconClassName?: string;
	routerLink?: string | Array<string>;
	routerLinkQueryParams?: Params;
	routerQueryParamsHandling?: QueryParamsHandling;
	routerLinkActiveClass?: string;
	disabled?: boolean;
	data?: any;
	value?: any;
	tooltip?: string;
	show?: boolean;
	hideNavigationButtons?: boolean;
	hidePageTitle?: boolean;
	actionButtonSettings?: {
		onActionButtonClick: () => Promise<void>;
		label?: string;
	};
	validation?: () => boolean;
}

export const StepStatus = {
	completed: "completed",
	current: "current",
	next: "next",
}
