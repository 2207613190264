import { mapFileEntityToAirsEntity } from './airs-entity.file.converter';

export const enum ProcessEntityAlertV3Fields {
	ImageFile = 'ImageFile',
	ProcessId = 'ProcessId',
	CreatedTimeUtc = 'CreatedTimeUtc',
	Account = 'Account',
	CommandLine = 'CommandLine',
	ParentProcess = 'ParentProcess',
	Host = 'Host',
}

export function mapProcessEntityToAirsEntity(entityData): any {
	const res = Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case ProcessEntityAlertV3Fields.ImageFile:
					acc['cwd'] = val['Directory'];
					acc['name'] = val['Name'];
					acc['main_module'] = mapFileEntityToAirsEntity(val);
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case ProcessEntityAlertV3Fields.ProcessId:
					acc['process_id'] = <string>val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case ProcessEntityAlertV3Fields.CreatedTimeUtc:
					acc['created'] = val;
					break;
				case ProcessEntityAlertV3Fields.Account:
					const ntDomain = val['NTDomain'];
					const name = val['Name'];

					acc['username'] =
						ntDomain && name ? `${ntDomain}\\${name}` : ntDomain ? ntDomain : name ? name : null;
					break;
				case ProcessEntityAlertV3Fields.CommandLine:
					acc['cmd'] = val;
					break;
				// case ProcessEntityAlertV3Fields.ParentProcess:
				// 	acc['parent_name'] = val['ProcessId']; TODO: missing parent_name data in the response - need to add to the BE (https://microsoft.visualstudio.com/OS/_workitems/edit/37518268)
				//	break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});

	return Object.assign(res, { additional_data: { name: res['name'], pid: res['process_id'] } });
}
