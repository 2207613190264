import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { InaccuracyContext } from '../../services/tvm-texts.service';
import { ModelBase } from '@microsoft/paris';

@Component({
	selector: 'reportable-base-field',
	template: `
		<div class="wcd-flex-center-vertical">
			<div class="wcd-flex-1 wcd-text-overflow-ellipsis">
				{{ title }}
			</div>
			<tvm-report-inaccuracy-dropdown
				[inaccuracyContext]="inaccuracyContext"
				[contextObject]="contextObject"
			></tvm-report-inaccuracy-dropdown>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReportableBaseFieldComponent {
	@Input() inaccuracyContext: InaccuracyContext;
	@Input() contextObject: ModelBase;
	@Input() title: string;
}
