/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i3 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "./file-malware-detected.component";
import * as i10 from "@microsoft/paris/dist/lib/paris";
import * as i11 from "../../../dialogs/services/dialogs.service";
import * as i12 from "../../../global_entities/services/global-entity-types.service";
import * as i13 from "../services/file-verdict-alert.service";
var styles_FileMalwareDetectedComponent = [];
var RenderType_FileMalwareDetectedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileMalwareDetectedComponent, data: {} });
export { RenderType_FileMalwareDetectedComponent as RenderType_FileMalwareDetectedComponent };
function View_FileMalwareDetectedComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), "files.entityDetails.sections.detections.fields.malwareDetected.none")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileMalwareDetectedComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var currVal_0 = _v.parent.parent.context.ngIf[0].threatName; _ck(_v, 1, 0, currVal_0); }); }
function View_FileMalwareDetectedComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), "files.entityDetails.sections.detections.fields.malwareDetected.multiple")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileMalwareDetectedComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "wcd-font-size-s wcd-padding-small-top"]], null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileMalwareDetectedComponent_3)), i0.ɵdid(4, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileMalwareDetectedComponent_4)), i0.ɵdid(7, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileMalwareDetectedComponent_5)), i0.ɵdid(10, 16384, null, 0, i1.NgSwitchDefault, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.length; _ck(_v, 1, 0, currVal_0); var currVal_1 = 0; _ck(_v, 4, 0, currVal_1); var currVal_2 = 1; _ck(_v, 7, 0, currVal_2); }, null); }
function View_FileMalwareDetectedComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-padding-small-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-datatable", [], null, null, null, i2.View_DataTableComponent_0, i2.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i3.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i4.LiveAnnouncer, i5.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], label: [3, "label"] }, null), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = false; var currVal_2 = _co.tableFields; var currVal_3 = i0.ɵunv(_v, 3, 3, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "files.entityDetails.sections.detections.fields.malwareDetected.title")); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_FileMalwareDetectedComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileMalwareDetectedComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileMalwareDetectedComponent_6)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSummary; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.context.ngIf.length; _ck(_v, 6, 0, currVal_1); }, null); }
function View_FileMalwareDetectedComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "fab-spinner", [], null, null, null, i6.View_FabSpinnerComponent_0, i6.RenderType_FabSpinnerComponent)), i0.ɵdid(2, 5816320, null, 0, i7.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_FileMalwareDetectedComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i8.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FileMalwareDetectedComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(0, [["loadingTemplate", 2]], null, 0, null, View_FileMalwareDetectedComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.tableItems$)); var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_FileMalwareDetectedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "file-malware-detected", [], null, null, null, View_FileMalwareDetectedComponent_0, RenderType_FileMalwareDetectedComponent)), i0.ɵdid(1, 114688, null, 0, i9.FileMalwareDetectedComponent, [i5.I18nService, i10.Paris, i11.DialogsService, i12.GlobalEntityTypesService, i13.FileVerdictAlertService, i1.Location], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileMalwareDetectedComponentNgFactory = i0.ɵccf("file-malware-detected", i9.FileMalwareDetectedComponent, View_FileMalwareDetectedComponent_Host_0, { file: "file", showSummary: "showSummary" }, {}, []);
export { FileMalwareDetectedComponentNgFactory as FileMalwareDetectedComponentNgFactory };
