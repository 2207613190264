/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef } from '@angular/core';
import { MainAppStateService } from '../services/main-app-state.service';
import { TitleService } from '../../services/title.service';
import { AuthService } from '@wcd/auth';
import { HELP_LINKS_CONFIG, SCC_BASE_TAB_NAME, SCC_BASE_URL, SUPPORT_LINK_I18N_KEY, } from '../services/main-nav-config.service';
import { getClientTimeZoneString, getUserTimeZone, LocaleConfigService } from '@wcd/localization';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { FeedbackService } from '../../../feedback/services/feedback.service';
import { PreferencesService } from '@wcd/config';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { EnvironmentName } from '@wcd/domain';
import { SupportCentralService } from '../../../support-central/support-central.service';
import { I18nService } from '@wcd/i18n';
export var SELECTED_TIMEZONE_KEY = 'selectedTimezone';
export var SELECTED_LOCALE_KEY = 'selectedLocale';
var MainHeaderComponent = /** @class */ (function () {
    function MainHeaderComponent(changeDetectionRef, preferencesService, supportCentralService, mainAppStateService, feedbackService, askThreatExpertService, authService, appConfigService, localeConfigService, notificationsService, titleService, featuresService, appContextService, evaluationEnabledGuard, i18nService) {
        var _this = this;
        this.changeDetectionRef = changeDetectionRef;
        this.preferencesService = preferencesService;
        this.supportCentralService = supportCentralService;
        this.mainAppStateService = mainAppStateService;
        this.feedbackService = feedbackService;
        this.askThreatExpertService = askThreatExpertService;
        this.authService = authService;
        this.appConfigService = appConfigService;
        this.localeConfigService = localeConfigService;
        this.notificationsService = notificationsService;
        this.evaluationEnabledGuard = evaluationEnabledGuard;
        this.i18nService = i18nService;
        this.isInit = false;
        this.availableLocales = this.localeConfigService.getSupportedLocales();
        this.showLocalization = getUserTimeZone() !== 0 || (this.availableLocales && this.availableLocales.length > 1);
        this.supportLinkI18nKey = SUPPORT_LINK_I18N_KEY;
        this.sccBaseUrl = SCC_BASE_URL;
        this.sccTabName = SCC_BASE_TAB_NAME;
        this.isFairfax = appConfigService.environmentName === EnvironmentName.Fairfax;
        titleService.state$.subscribe(function (state) {
            _this.routeState = state;
            _this.changeDetectionRef.markForCheck();
            _this.isInit = true;
        });
        mainAppStateService.state$.subscribe(function (mainAppState) {
            _this.mainAppState = mainAppState;
            _this.communityCount = +_this.mainAppState.mainNavItemBadges.community;
            _this.changeDetectionRef.markForCheck();
        });
        this.isMtp = appContextService.isMtp;
        this.helpLinks = HELP_LINKS_CONFIG.filter(function (link) { return !_this.isMtp || link.isMtp; }).filter(function (link) {
            return _this.isFairfax ? link.isFairfax !== false : link.isFairfax !== true;
        }).filter(function (link) { return !supportCentralService.withSupportCentral || !link.isExternalSupportLink; });
        this.showMainSearch =
            (!this.isMtp || featuresService.isEnabled(Feature.ShowMainSearch)) &&
                this.isInit &&
                appConfigService.isOnboardingComplete &&
                !appConfigService.isSuspended &&
                !appConfigService.isDeleted;
        this.enableSimulations = featuresService.isEnabled(Feature.SimulationsAndTutorials);
        this.evaluationEnabled = this.evaluationEnabledGuard.canActivate();
        this.expertsOnDemandEnabled =
            featuresService.isEnabled(Feature.BilbaoExpertsOnDemand) &&
                this.appConfigService.isExposedToAllMachineGroups;
        this.expertsOnDemandEnabled2 = featuresService.isEnabled(Feature.BilbaoExpertsOnDemand2);
        this.timeZoneOptions = [
            {
                id: 'utc',
                text: this.i18nService.get('datepicker_timezoneUTC'),
                value: 0,
            },
            {
                id: 'local',
                text: this.i18nService.get('datepicker_timezoneLocalUTC', { clientTimeZone: getClientTimeZoneString() }),
                value: getUserTimeZone(),
            },
        ];
        this.localeConfigService.config$.subscribe(function (config) {
            _this.changeDetectionRef.markForCheck();
        });
    }
    Object.defineProperty(MainHeaderComponent.prototype, "mainAppLogoLink", {
        get: function () {
            return {
                routerLink: this.isMtp ? null : '/dashboards_junction',
                externalLink: this.isMtp
                    ? this.sccBaseUrl
                    : this.authService.isLoggedIn
                        ? null
                        : '/dashboards_junction',
                externalLinkTarget: this.isMtp ? this.sccTabName : '_self',
                showExternalLinkIcon: false,
            };
        },
        enumerable: true,
        configurable: true
    });
    MainHeaderComponent.prototype.toggleMobileMenu = function () {
        this.showMobileHeader = !this.showMobileHeader;
    };
    MainHeaderComponent.prototype.onTimezoneChange = function (timezone) {
        this.localeConfigService.setSelectedTimezone(timezone);
        this.preferencesService.setPreference(SELECTED_TIMEZONE_KEY, timezone);
    };
    MainHeaderComponent.prototype.onLocaleChange = function (locale) {
        this.localeConfigService.setSelectedLocale(locale);
        this.preferencesService.setPreference(SELECTED_LOCALE_KEY, locale);
    };
    return MainHeaderComponent;
}());
export { MainHeaderComponent };
