import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { DataviewActionTypes } from './dataview-actions.model';
import { DataviewActionButtonConfig } from './dataview-action-button.component';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FabricIconNames } from '@wcd/scc-common';
import { CsvUtils } from '@wcd/shared';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

export interface DataviewActionSearchConfig {
	id: string;
	giveSearchBoxMoreSpace: boolean;
	searchMinLength: number;
	searchCallback: () => void;
	entityTypeName: string;
	actionType: DataviewActionTypes;
	allowMultipleWords?: boolean;
	searchLocalized: string;
}

@Component({
	selector: 'dataview-action-search',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container>
			<dataview-action-button
				*ngIf="!searchOpened"
				[buttonActionConfig]="searchButtonConfig"
				[smallScreenView]="smallScreenView"
			>
			</dataview-action-button>
			<div
				*ngIf="searchOpened"
				class="wcd-flex-center-all dataview-search-form"
				[ngClass]="giveSearchBoxMoreSpace ? 'wcd-flex-3' : 'wcd-flex-1'"
			>
				<div class="wcd-full-width search-div wcd-input-placeholder command-bar-item-button ">
					<button
						class="btn btn-link search-div__btn command-bar-item-button"
						type="button"
						[disabled]="!searchTerm || searchTerm === lastSearched"
						(click)="search(searchTerm)"
						[attr.aria-label]="'dataview.search' | i18n"
					>
						<wcd-shared-icon aria-hidden="true" [iconName]="icon"></wcd-shared-icon>
					</button>
					<input
						class="search-input"
						[(ngModel)]="searchTerm"
						name="dataview-search"
						[minlength]="searchMinLength"
						(keydown.enter)="search(searchTerm)"
						(keydown.escape)="cancelSearch()"
						(blur)="onSearchBlur()"
						[placeholder]="searchLocalized"
						[attr.size]="searchLocalized.length + SearchSafePadding"
						[attr.aria-label]="'dataview.search.item' | i18n: { itemType: entityTypeName }"
						autocomplete="off"
					/>
					<button
						class="btn btn-link clear-search command-bar-item-button"
						type="button"
						(click)="cancelSearch()"
						[attr.aria-label]="tooltip | i18n"
						[wcdTooltip]="tooltip | i18n"
					>
						<wcd-shared-icon iconColor="black" iconName="cancel"></wcd-shared-icon>
					</button>
				</div>
			</div>
		</ng-container>
	`,
})
export class DataviewActionSearchComponent implements OnInit {
	@Input() searchActionConfig: DataviewActionSearchConfig;
	@Input() dataViewModel: DataViewModel;
	@Input() smallScreenView: boolean = false;

	public icon: string = FabricIconNames.Search;
	public tooltip: string = 'select.clear';

	public id: string;
	public giveSearchBoxMoreSpace: boolean;
	public searchMinLength: number;
	public searchCallback: () => void;
	public entityTypeName: string;

	readonly SearchSafePadding = 2;
	lastSearched: string;
	searchTerm: string;
	ignoreQueryParams: boolean;
	searchOpened: boolean = false;
	allowMultipleWords: boolean = false;
	private _onSearchChangeSubscription: Subscription;

	openSearch = () => {
		this.searchOpened = true;
	};

	searchLocalized;

	searchButtonConfig: Partial<DataviewActionButtonConfig>;

	constructor(public i18nService: I18nService, private appInsightsService: AppInsightsService) {}

	ngOnInit() {
		Object.assign(this, { ...this.searchActionConfig });
		this.searchLocalized = this.searchActionConfig.searchLocalized;
		this.searchButtonConfig = {
			localizedTooltip: this.searchLocalized,
			onClickCallback: this.openSearch,
			icon: FabricIconNames.Search,
			localizedLabel: this.searchLocalized,
		};

		// When the first event is fired (if the page is loaded with search term in url) sets the value
		this._onSearchChangeSubscription = this.dataViewModel.searchTerm$.pipe(take(1)).subscribe(() => {
			if (this.dataViewModel.currentSearchTerm) {
				this.onSearchBlur();
				this.openSearch();
			}
		});
	}

	search(term) {
		// if ignoring the query params and the data isn't local, the search term won't be updated because navigate on change
		// won't happen so the search term must be set inside the data view model as the third argument (setCurrentSearchTerm)
		const trimmedTerm = term.trim();
		if (this.lastSearched !== trimmedTerm) {
			this.lastSearched = trimmedTerm;
			const setCurrentSearchTerm: boolean = this.ignoreQueryParams;
			this.dataViewModel.setSearchTerm(
				this.allowMultipleWords ? DataviewActionSearchComponent.parseWords(trimmedTerm) : trimmedTerm,
				true,
				setCurrentSearchTerm
			);
			this.trackEvent('DataviewSearchGo');
			setTimeout(() => this.searchActionConfig.searchCallback());
		}
	}

	cancelSearch() {
		this.searchOpened = false;
		this.search('');
		this.searchTerm = null;
		this.trackEvent('DataviewSearchClear');
	}

	onSearchBlur() {
		if (!this.searchTerm && this.dataViewModel.currentSearchTerm)
			this.searchTerm = this.dataViewModel.currentSearchTerm;
	}

	destroy() {
		if (this.dataViewModel) {
			this._onSearchChangeSubscription && this._onSearchChangeSubscription.unsubscribe();
		}
	}

	private trackEvent(eventId: string) {
		this.appInsightsService.track({
			id: eventId,
			component: `${this.searchActionConfig.id}_DataView`,
			type: TrackingEventType.Button,
			componentType: 'DataView',
		});
	}

	public static parseWords(searchTerm: string): string {
		let wordsToSearch: string[] = [];
		if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
			// if starts and ends with '"' - we should consider this string as a single word
			wordsToSearch = [searchTerm.slice(1, -1)]; // remove first and last '"'
		} else {
			wordsToSearch = searchTerm.match(/\S+/g) || [];
		}

		return wordsToSearch.map((word) => CsvUtils.encodeCsv(word)).join(',');
	}
}
