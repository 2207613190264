import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { I18nModule } from '@wcd/i18n';
import { MtpSettingsConsent } from './components/mtp-settings-consent.component';
import { MarkdownModule } from 'ngx-markdown';
import {
	FabButtonModule,
	FabCheckboxModule,
	FabMessageBarModule,
	FabSpinnerModule,
	FabToggleModule,
} from '@angular-react/fabric';
import { ContentsStateModule } from '@wcd/contents-state';
import { WcdFormsModule } from '@wcd/forms';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		I18nModule,
		MarkdownModule,
		FabCheckboxModule,
		FabButtonModule,
		FabSpinnerModule,
		FabMessageBarModule,
		ContentsStateModule,
		WcdFormsModule,
		FabToggleModule,
	],
	providers: [],
	declarations: [MtpSettingsConsent],
	entryComponents: [MtpSettingsConsent],
	exports: [MtpSettingsConsent],
})
export class MtpConsentModule {}
