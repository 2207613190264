export const enum CloudApplicationEntityAlertV3Fields {
	Name = 'Name',
	AppId = 'AppId',
	Risk = 'Risk',
}

export function mapCloudApplicationEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case CloudApplicationEntityAlertV3Fields.Name:
					acc['name'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case CloudApplicationEntityAlertV3Fields.AppId:
					acc['app_id'] = val;
					break;
				case CloudApplicationEntityAlertV3Fields.Risk:
					acc['risk'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
