import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'IP Address',
	pluralName: 'IP Addresses',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsIp,
})
export class AirsIp extends AirsEntity {
	@EntityField({ data: 'address' })
	address: string;

	@EntityField({ data: 'organization_asn' })
	organizationAsn: string;

	@EntityField({ data: 'organization_name' })
	organizationName: string;

	@EntityField({ data: 'location' })
	location: string;

	constructor(data) {
		super(data);
		this.entityName = this.address;
	}
}
