// This enum should be compatible with the BE counterpart- the WorkloadName enum that is defined in the MtpWorkloadsData.cs file.
// see https://microsoft.visualstudio.com/WDATP/_git/WcdLibs?path=%2FTenantStore%2FTenantStoreClient%2FMtpWorkloadsData.cs&version=GBmaster&_a=contents
export enum MtpWorkload {
	Mdatp = 1,
	Itp = 2,
	Oatp = 3,
	MapG = 4,
	AadIp = 5,
	Dlp = 6,
	Mdi = 7
}
