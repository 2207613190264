import { Subject } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { AjaxError } from 'rxjs/ajax';
import { OnDestroy } from '@angular/core';
import { ERROR_PAGE_MAPPING } from '@wcd/shared';

export class ErrorsService implements OnDestroy {
	errorPage$: Subject<void> = new Subject();

	public static getErrorMessage(error, i18nService?): string {
		const errorStr = this.getErrorString(error, i18nService);
		return errorStr && this.parseErrorStr(errorStr);
	}

	private static getErrorString(error, i18nService?): string {
		if (!error) return null;

		if (error.originalError) error = error.originalError;

		if (error instanceof HttpErrorResponse) {
			try {
				// prefer an object or array
				error =
					(error.error && typeof error.error === 'object'
						? error.error
						: JSON.parse(error.error)) || error.message;
			} catch (e) {
				error = error.message;
			}
		} else if (error instanceof AjaxError) {
			const errorState = (<AjaxError>error).status;

			if ((errorState === 408 || errorState === 0) && i18nService) {
				error = i18nService.get('common.error.httpTimeout');
			} else {
				error = error.message;
			}
		} else if (error instanceof Error) {
			error = error.message;
		}

		if (typeof error === 'string') return error;

		const errorData = error.data || error;

		if (errorData) {
			if (typeof errorData === 'string') return errorData;

			const errors: Array<any> = [];
			for (const p in errorData) {
				errors.push(errorData[p].message || errorData[p]);
			}

			return errors.join('\n\n'); // There's no standard in the backend for error messages :(
		}

		return null;
	}

	ngOnDestroy(): void {
		this.errorPage$.unsubscribe();
	}

	private static parseErrorStr(errorStr: string): string {
		// the matcher here is stateful so we need to define it inside this function
		const matcher = /<portal-link>(.*?)<\/portal-link>/g;
		let match: RegExpExecArray;
		const originalError = errorStr;
		try {
			while ((match = matcher.exec(originalError))) {
				try {
					const linkConfig = JSON.parse(match[1]);
					const newText = String(linkConfig.text || linkConfig.id);
					const path = ERROR_PAGE_MAPPING[linkConfig.page];
					const link = path && path + linkConfig.id;
					const newMarkdown = link
						? `<a href='${link}' ${
								linkConfig.newTab !== false ? 'target="_blank"' : ''
						  }>${newText}</a>`
						: newText;
					errorStr = errorStr.replace(match[0], newMarkdown);
				} catch (err) {}
			}
		} catch (err) {}
		return errorStr;
	}
}
