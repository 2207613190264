import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { AirsEntityActionUserType } from './airs-entity-action-user-type.entity';

@ValueObject({
	singularName: 'Entity Action User',
	pluralName: 'Entity Action Users',
	readonly: true,
})
export class AirsEntityActionUser extends ModelBase {
	@EntityField() name: string;

	@EntityField() type: AirsEntityActionUserType;
}
