import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { ApplicationEntityTypeService } from './services/application.entity-type.service';

@NgModule({
	imports: [GlobalEntitiesModule],
	providers: [ApplicationEntityTypeService],
})
export class ApplicationEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ApplicationEntityTypeService);
	}
}
