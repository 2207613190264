
		<div
			*ngIf="(processedDistributionData$ | async) as distributionData; else loading"
			[ngClass]="verticalView ? 'wcd-flex-vertical' : ''"
		>
			<distribution-element
				*ngFor="let distributionItem of distributionData"
				[ngClass]="sameWidth ? 'wcd-width-small-medium' : ''"
				[barColor]="distributionItem.barColor"
				[title]="distributionItem.title"
				[mainText]="distributionItem.mainText || '-'"
				[titleColor]="distributionItem.titleColor"
				[titleSize]="distributionItem.titleSize"
				[mainTextSize]="distributionItem.mainTextSize"
				[mainTextColor]="distributionItem.mainTextColor"
				[secondaryText]="distributionItem.secondaryText"
				[secondaryTextSize]="distributionItem.secondaryTextSize"
				[secondaryFontWeight]="distributionItem.secondaryFontWeight"
				[secondaryTextColor]="distributionItem.secondaryTextColor"
				[size]="size"
				[clickable]="clickable"
				[helpText] = "distributionItem.helpText"
				(onClick)="this.onClick.emit(distributionItem)"
			>
			</distribution-element>
		</div>

		<ng-template #loading>
			<fab-spinner
				[size]="SpinnerSize.small"
				[label]="spinnerLabel"
				labelPosition="right"
			></fab-spinner>
		</ng-template>
	