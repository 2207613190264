import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { Alert } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { AppContextService, FeaturesService, Feature, FlavorService } from '@wcd/config';
import { AlertEntityTypeService } from '../../../@entities/alerts/services/alert.entity-type.service';
import { Router } from '@angular/router';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { I18nService } from '@wcd/i18n';
import { RoutesService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';

@Component({
	selector: 'alert-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './alert.entity-details.component.html',
	styleUrls: ['./alert.entity-details.component.scss'],
})
export class AlertEntityDetailsComponent extends EntityDetailsComponentBase<Alert> implements OnInit {
	@Input() showTitle = true;
	@Input() alertDetailsTitle = 'Alert details';
	isScc: boolean;

	investigationEnabled: boolean;

	get alert(): Alert {
		return this.entity;
	}

	constructor(
		injector: Injector,
		private featuresService: FeaturesService,
		private router: Router,
		public alertEntityTypeService: AlertEntityTypeService,
		private appContextService: AppContextService,
		private i18nService: I18nService,
		private flavorService: FlavorService
	) {
		super(injector);
		this.isScc = sccHostService.isSCC;
	}

	ngOnInit() {
		this.investigationEnabled =
			this.mode !== EntityDetailsMode.SidePane &&
			this.flavorService.isEnabled(AppFlavorConfig.routes.investigation);
	}

	get showIncidentLink(): boolean {
		const pathSegments = this.router.url && this.router.url.split('/');
		const incidentsIndex = pathSegments.indexOf('incidents');
		const currentIncident = pathSegments.length > incidentsIndex + 1 && pathSegments[incidentsIndex + 1];
		return +currentIncident !== +this.alert.incidentId;
	}

	get showServiceSource(): boolean {
		return Boolean(this.appContextService.isMtp && this.alert.serviceSource);
	}

	get showDetectionSource(): boolean {
		return (
			this.alert.detectionSource &&
			(!this.appContextService.isMtp || this.detectionSourceDoesNotMatchServiceSource())
		);
	}

	get showAlertTechniques(): boolean {
		return this.featuresService.isEnabled(Feature.AlertDetailsMitreTechniques);
	}

	get SCCIncidentUrl(): string {
		return `${RoutesService.getSCCRootUrl()}/incidents/${this.alert.incidentId}/overview`;
	}

	private detectionSourceDoesNotMatchServiceSource() {
		return (
			this.alert.detectionSource &&
			(!this.alert.serviceSource || this.alert.detectionSource.name !== this.alert.serviceSource.name)
		);
	}
}
