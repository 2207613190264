// This service is the _legacy_ version of ui.svc.ts which is only used in the Angular code
// because that code is using an old version of TypeScript

/**
* interface used to represent a breadcrumb
*/
import { AnnouncerService } from './announcer.svc';

export interface IBreadcrumbInfo {
    // text to display to the user
    text: string;
    // state to navigate to when this breadcrumb is clicked (find the state name of the page in your manifest.json file)
    state: string;
    // any params that should be attached to the url ex. {"viewid": "application"}
    params?: any;
    // icons are currently not implemented, but will be in the future
    iconName?: string;
}

/**
* Manages SCC global ui services:
* Breadcrumbs
* Render React component
* Sanitization
*/
export class SccUiService {

    constructor(private sccUi, private announcer: AnnouncerService) {
    }

    sanitize(htmlContent?: string): string | undefined {
        return this.sccUi.sanitize(htmlContent);
    }

    render<T extends string>(name: T, props?: any , useRedux?: boolean, onLoadCompleted?: () => void): JSX.Element {
        return this.sccUi.render(name, props, useRedux, onLoadCompleted);
    }

    setBreadcrumbs(breadcrumbs: IBreadcrumbInfo[], concat?: boolean): void {
        return this.sccUi.setBreadcrumbs(breadcrumbs, concat);
    }

    setNavNotificationIndicator(urlState: string | string[], indicator?: boolean): void {
        return this.sccUi.setNavNotificationIndicator(urlState, indicator);
    }

    showError(error: Error | any, options?: any): Promise<string> {
        return this.sccUi.showError(error, options);
    }

    showNotification(message: string) {
        this.announcer.announce({ message });
        return this.sccUi.showNotification(message);
    }

    /**
    * Set the global commands. These are located in the top right of the content page area. Replaces current custom commands.
    * @param {ReactNode[]} commands the react nodes to use in the common controls command bar @see CommandBar
    */
    setGlobalCommands(commands: any[]): void {
        return this.sccUi.setGlobalCommands(commands);
    }

	 /**
	 * Set the banner which displays sticky to the top of the page
	 * @param banner the banner that what to show in the current page
	 */
  	setBanner(banner: React.ReactNode): void{
		return this.sccUi.setBanner(banner);
	}
}
