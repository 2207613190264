import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { Machine, DomainExposedMachine } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class DomainExposedMachinesFields {
	private _fields: Array<DataviewField>;

	constructor(
		private i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	get fields(): Array<DataviewField> {
		if (!this._fields) {
			this._fields = DataviewField.fromList([
				{
					id: 'title',
					name: this.i18nService.get(
						'reporting.webThreatReport.domainExposedMachines.machineNameHeader'
					),
					className: 'nowrap wcd-text-overflow-medium-large',
					icon: {
						fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Machine),
					},
					getLink: (machine: DomainExposedMachine) => `machines/${machine.name}/main`,
					getTooltip: (machine: DomainExposedMachine) => machine.name,
					getDisplay: (machine: DomainExposedMachine) => machine.name,
				},
				{
					id: 'accessCount',
					name: this.i18nService.get(
						'reporting.webThreatReport.domainExposedMachines.accessCountHeader'
					),
					className: 'nowrap',
					sort: {
						sortDescendingByDefault: true,
						getLocalSortValue: 'accessCount',
					},
				},
			]);
		}

		return this._fields;
	}
}
