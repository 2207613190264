var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { EvidenceAlertsSummary } from '../../incident/evidence-alerts-summary.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (file) { return ({ id: file.id }); }, ɵ2 = function (_, query) { return "evidence/file/" + query.where['id'] + "/alerts"; };
var FileIncidentAlertsRelationship = /** @class */ (function () {
    function FileIncidentAlertsRelationship() {
    }
    FileIncidentAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: EvidenceAlertsSummary,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ0,
            getRelationshipData: ɵ1,
            endpoint: ɵ2,
        })
    ], FileIncidentAlertsRelationship);
    return FileIncidentAlertsRelationship;
}());
export { FileIncidentAlertsRelationship };
export { ɵ0, ɵ1, ɵ2 };
