import { Component, Input } from '@angular/core';
import { DataEntityType, ModelBase } from '@microsoft/paris';
import { IncidentLinkedByEntity, ServiceSourceType } from '@wcd/domain';
import { HoverCardType } from 'office-ui-fabric-react';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';

const firstLetterToLowerCase = str => str.replace(/^\w/, (c) => c.toLowerCase());

@Component({
	selector: 'impacted-entities-linked-by',
	template: `
		<fab-hover-card
			*ngIf="!showEntityNameComponent"
			[type]="cardType"
			[plainCardOptions]="{renderPlainCard: card}"
		>
			<div class="wcd-impacted-entities-counter">
				<ul class="wcd-flex wcd-padding-none-all wcd-margin-none-all wcd-flex-horizontal">
					<li
						class="wcd-padding-small-right wcd-text-overflow-medium"
						*ngFor="let entity of entitiesList"
					>
						<fab-icon
							*ngIf="entity.icon"
							contentClass="field-value-icon"
							[iconName]="entity.icon"
						></fab-icon>
						<span>{{ entity.display }}</span>
					</li>
				</ul>
			</div>
		</fab-hover-card>
		<entity-name
			*ngIf="showEntityNameComponent"
			[entityTypeId]="entityTypeId"
			[entity]="entity"
			[entitySource]="entitySource"
			[showTooltip]="showTooltip"
		></entity-name>
		<ng-template #card>
			<div class="impacted-entities-linked-by-card-container" *ngIf="columns">
				<div class="card-title-height">Entities</div>
				<div class="card-body">
					<wcd-datatable
						[columns]="columns"
						[items]="items"
						[selectEnabled]="false"
						[fixedTable]="true"
						>
					</wcd-datatable>
				</div>
			</div>
		</ng-template>
	`,
})
export class ImpactedEntitiesLinkedByComponent<T extends ModelBase, U> {
	@Input()
	entity?: T;

	@Input()
	entityTypeId?: string | number | DataEntityType<T>;

	@Input()
	entitySource?: ServiceSourceType;

	@Input()
	showTooltip?: boolean = false;

	@Input()
	targetEntities: Array<IncidentLinkedByEntity>;

	cardType = HoverCardType.plain;
	columns: Array<DataTableField>;
	items: Array<any>;
	showEntityNameComponent: boolean = true;
	showCardComponent: boolean = true;

	entitiesList: { icon: string; display: string; }[];

	constructor(
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly i18nService: I18nService,
	) {
	}

	ngOnInit() {
		const entityCount = this.targetEntities.reduce((result: any, item: IncidentLinkedByEntity) => {
			result[item.type] = result[item.type] ? result[item.type] + 1 : 1;
			return result;
		}, {});
		this.entitiesList = Object.entries(entityCount)
			.map(entry => {
				const entityType = this.globalEntityTypesService.getEntityType(firstLetterToLowerCase(entry[0]));
				const pluralName = entityType.entityPluralNameKey
					? this.i18nService.get(entityType.entityPluralNameKey)
					: entityType.entity.pluralName;
				const singularName = entityType.entitySingularNameKey
					? this.i18nService.get(entityType.entitySingularNameKey)
					: entityType.entity.singularName;
				return {
					icon: entityType.icon,
					display:
						entry[1] > 1
							? `${entry[1]} ${pluralName}`
							: `${singularName}`,
				};
			});

		this.items = this.getItemsByEntity(this.entityTypeId);
		this.columns = this.getColumnsByEntityType(this.entityTypeId);
		this.showEntityNameComponent = !this.items;
	}


	getColumnsByEntityType(entityTypeId: string | number | DataEntityType<T>) {
		if (entityTypeId === 'mailMessage') {
			return DataTableField.fromList<any>([
				{
					id: 'name',
					name: this.i18nService.get('impacted_entities_linked_by_email_message', { count: this.items.length }),
					component: {
						type: EntityNameComponent,
						getProps: (item: any) => {
							return item ? {
									entity: item,
									entityTypeId: item.entityTypeId,
								}
								: null;
						},
					},
					sort: { enabled: false },
				},
			]);
		}
		if (entityTypeId === 'machine') {
			return DataTableField.fromList<any>([
				{
					id: 'name',
					name: this.i18nService.get('impacted_entities_linked_by_devices_title'),
					component: {
						type: EntityNameComponent,
						getProps: (item: any) => {
							return item ? {
									entity: item,
									entityTypeId: item.entityTypeId,
								}
								: null;
						},
					},
					sort: { enabled: false },
				},
				{
					id: 'direction',
					name: this.i18nService.get('impacted_entities_linked_by_direction_title'),
					sort: { enabled: false },
				},
			]);
		}
		return null;
	}

	getItemsByEntity(entityTypeId: string | number | DataEntityType<T>) {
		if (this.targetEntities && this.targetEntities.length) {
			if (entityTypeId === 'mailMessage') {
				return this.targetEntities.map(entity => ({
					name: entity.name,
					entityTypeId: firstLetterToLowerCase(entity.type),
				}));
			}
			if (entityTypeId === 'machine') {
				return [
					{
						name: (<any>this.entity).name,
						shortName: (<any>this.entity).name,
						entityTypeId,
						direction: this.i18nService.get('impacted_entities_linked_by_source_device_title'),
					},
					...this.targetEntities.map(entity => ({
						name: entity.name,
						shortName: entity.name,
						entityTypeId: firstLetterToLowerCase(entity.type),
						direction: this.i18nService.get('impacted_entities_linked_by_remote_device_title'),
					})),
				];
			}
		}
		return null;
	}
}
