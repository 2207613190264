var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeEventFieldsService } from './../../../../@entities/@tvm/events/services/change-event.fields.service';
import { Router } from '@angular/router';
import { map, take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { ChangeEvent } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TextToken, TvmTextsService } from '../../../services/tvm-texts.service';
import { zip } from 'rxjs';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { DataviewField } from '@wcd/dataview';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { I18nService } from '@wcd/i18n';
var TvmTopEventsWidget = /** @class */ (function (_super) {
    __extends(TvmTopEventsWidget, _super);
    function TvmTopEventsWidget(reportsService, changeEventFieldsService, tvmTextsService, machineGroupsFilterService, telemetryService, router, paris, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.tvmTextsService = tvmTextsService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.telemetryService = telemetryService;
        _this.router = router;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.fields = changeEventFieldsService.topEventsWidgetFields.map(function (field) { return new DataviewField(Object.assign({}, field, { sort: { enabled: false } })); });
        return _this;
    }
    Object.defineProperty(TvmTopEventsWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'topChangeEventsWidget',
                name: this.i18nService.get('tvm.dashboard.topEvents'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.dashboard.noData.topEvents',
                        isGroupSelected: _this.isMgSelected,
                    }) + "<a tabindex=\"0\" role=\"link\" href=\"/tvm-event-insights\">. " + _this.i18nService.strings.tvm_changeEvent_showAllEvents + "</a>";
                },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                rangeInDays: 7,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    var changeEvents$ = _this.paris
                        .query(ChangeEvent, {
                        page: 1,
                        pageSize: 3,
                        where: {
                            date: 'week',
                            source: 'mdatp'
                        },
                    })
                        .pipe(map(function (ds) { return ds.items.sort(function (a, b) { return b.date.getTime() - a.date.getTime(); })
                        .slice(0, 3); }));
                    return zip(mg$, changeEvents$).pipe(map(function (_a) {
                        var mg = _a[0], changeEvents = _a[1];
                        _this.isMgSelected = mg.isFiltering;
                        return changeEvents;
                    }));
                },
                minHeight: '210px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmTopEventsWidget.prototype.openChangeEventsPage = function () {
        this.router.navigate(['/tvm-event-insights']);
    };
    TvmTopEventsWidget.prototype.openChangeEventPage = function (changeEvent) {
        this.telemetryService.trackEvent({
            type: TrackingEventType.Navigation,
            id: 'OpenChangeEventsPage',
            component: this.widgetConfig.id,
            componentType: 'Widget',
        });
        this.router.navigate(['/tvm-event-insights', changeEvent.id]);
    };
    return TvmTopEventsWidget;
}(ReportWidgetComponent));
export { TvmTopEventsWidget };
