/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alert-severity-incident-summary.title.component.ngfactory";
import * as i2 from "./alert-severity-incident-summary.title.component";
import * as i3 from "./alerts-severity-summary.component.ngfactory";
import * as i4 from "./alerts-severity-summary.component";
import * as i5 from "../../shared/services/severity-type-color.service";
import * as i6 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../shared/pipes/display-text.pipe";
import * as i8 from "@angular/common";
import * as i9 from "./alerts-severity-incident-summary.component";
var styles_AlertsSeverityIncidentSummaryComponent = [];
var RenderType_AlertsSeverityIncidentSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertsSeverityIncidentSummaryComponent, data: {} });
export { RenderType_AlertsSeverityIncidentSummaryComponent as RenderType_AlertsSeverityIncidentSummaryComponent };
function View_AlertsSeverityIncidentSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical wcd-flex-spaceBetween-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "alerts-severity-incident-summary-title", [], null, null, null, i1.View_AlertSeverityIncidentSummaryTitleComponent_0, i1.RenderType_AlertSeverityIncidentSummaryTitleComponent)), i0.ɵdid(3, 49152, null, 0, i2.AlertSeverityIncidentSummaryTitleComponent, [], { alertsSeveritySummary: [0, "alertsSeveritySummary"], asText: [1, "asText"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "div", [["class", "wcd-margin-small-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "alerts-severity-summary", [], null, null, null, i3.View_AlertsSeveritySummaryComponent_0, i3.RenderType_AlertsSeveritySummaryComponent)), i0.ɵdid(8, 573440, null, 0, i4.AlertsSeveritySummaryComponent, [i5.SeverityTypeColorService, i6.I18nService, i7.DisplayTextPipe], { alertsSeveritySummary: [0, "alertsSeveritySummary"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertsSeveritySummary; var currVal_1 = _co.asText; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.alertsSeveritySummary; _ck(_v, 8, 0, currVal_2); }, null); }
export function View_AlertsSeverityIncidentSummaryComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertsSeverityIncidentSummaryComponent_1)), i0.ɵdid(2, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertsSeveritySummary; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertsSeverityIncidentSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alerts-severity-incident-summary", [], null, null, null, View_AlertsSeverityIncidentSummaryComponent_0, RenderType_AlertsSeverityIncidentSummaryComponent)), i0.ɵdid(1, 49152, null, 0, i9.AlertsSeverityIncidentSummaryComponent, [], null, null)], null, null); }
var AlertsSeverityIncidentSummaryComponentNgFactory = i0.ɵccf("alerts-severity-incident-summary", i9.AlertsSeverityIncidentSummaryComponent, View_AlertsSeverityIncidentSummaryComponent_Host_0, { alertsSeveritySummary: "alertsSeveritySummary", asText: "asText" }, {}, []);
export { AlertsSeverityIncidentSummaryComponentNgFactory as AlertsSeverityIncidentSummaryComponentNgFactory };
