var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReadOnlyIdentifiable } from '../../../data/models/readonly-identifiable.model';
var MachineModel = /** @class */ (function (_super) {
    __extends(MachineModel, _super);
    function MachineModel(data) {
        return _super.call(this, data) || this;
    }
    MachineModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
        this.id = data.SenseMachineId;
        this.name = data.ComputerDnsName;
        this.os = {
            build: data.OsBuild,
            platform: data.OsPlatform,
            processor: data.OsProcessor,
            version: data.OsVersion || null,
        };
        this.group = data.MachineGroup;
        this.domain = data.Domain;
        this.lastIp = data.LastIpAddress;
        this.lastExternalIp = data.LastExternalIpAddress;
        this.firstSeen = data.FirstSeen ? new Date(data.FirstSeen) : null;
        this.lastSeen = data.LastSeen ? new Date(data.LastSeen) : null;
    };
    return MachineModel;
}(ReadOnlyIdentifiable));
export { MachineModel };
export var MachineBackendIdType;
(function (MachineBackendIdType) {
    MachineBackendIdType["Id"] = "Id";
    MachineBackendIdType["SenseMachineId"] = "SenseMachineId";
})(MachineBackendIdType || (MachineBackendIdType = {}));
export var DeviceCategory;
(function (DeviceCategory) {
    DeviceCategory["Unknown"] = "Unknown";
    DeviceCategory["Endpoint"] = "Endpoint";
    DeviceCategory["IoT"] = "IoT";
    DeviceCategory["NetworkDevice"] = "NetworkDevice";
})(DeviceCategory || (DeviceCategory = {}));
