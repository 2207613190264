/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./line-chart.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./line-chart.component";
import * as i3 from "../../../../localization/src/lib/services/tz-date.service";
var styles_LineChartComponent = [i0.styles];
var RenderType_LineChartComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_LineChartComponent, data: {} });
export { RenderType_LineChartComponent as RenderType_LineChartComponent };
export function View_LineChartComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(402653184, 1, { tooltipPlaceholder: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["style", "position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "wcd-line-chart"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 16777216, [[1, 3], ["hoveredEventTooltipContainer", 1]], null, 0, "template", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.divId; _ck(_v, 4, 0, currVal_0); }); }
export function View_LineChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-line-chart", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_LineChartComponent_0, RenderType_LineChartComponent)), i1.ɵdid(1, 4374528, null, 0, i2.LineChartComponent, [i1.ComponentFactoryResolver, i3.TzDateService, i1.ElementRef], null, null)], null, null); }
var LineChartComponentNgFactory = i1.ɵccf("wcd-line-chart", i2.LineChartComponent, View_LineChartComponent_Host_0, { options: "options" }, {}, []);
export { LineChartComponentNgFactory as LineChartComponentNgFactory };
