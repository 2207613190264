import { MailMessage } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { FabricIconNames } from '@wcd/scc-common';
var MailMessageEntityTypeService = /** @class */ (function () {
    function MailMessageEntityTypeService() {
        this._entityType = new Lazy(function () { return ({
            entity: MailMessage,
            id: 'mailMessage',
            icon: FabricIconNames.Mail,
            getEntityName: function (MailMessage) { return MailMessage.name; },
            getEntitiesLink: function (MailMessages) {
                if (!MailMessages || MailMessages.length !== 1) {
                    return null;
                }
                var mailMailMessage = MailMessages[0];
                return mailMailMessage.mailMessagePageUrl;
            },
            getNavigationModel: function (MailMessage) { return ({
                externalLink: MailMessage.mailMessagePageUrl,
            }); },
            getUseExternalRouting: function (MailMessages) { return true; },
        }); });
    }
    Object.defineProperty(MailMessageEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return MailMessageEntityTypeService;
}());
export { MailMessageEntityTypeService };
