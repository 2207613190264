import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('stopPropagation', stopPropagationDirective);

function stopPropagationDirective() {
	return {
		restrict: 'EA',
		scope: {},
		compile: function(elm) {
			return function(scope, elm, attrs) {
				$(elm).click(function(event) {
					event.stopPropagation();
				});
			};
		},
	};
}
