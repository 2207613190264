import { DataviewField } from '@wcd/dataview';
import { Machine } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
var DomainExposedMachinesFields = /** @class */ (function () {
    function DomainExposedMachinesFields(i18nService, globalEntityTypesService) {
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
    }
    Object.defineProperty(DomainExposedMachinesFields.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'title',
                        name: this.i18nService.get('reporting.webThreatReport.domainExposedMachines.machineNameHeader'),
                        className: 'nowrap wcd-text-overflow-medium-large',
                        icon: {
                            fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Machine),
                        },
                        getLink: function (machine) { return "machines/" + machine.name + "/main"; },
                        getTooltip: function (machine) { return machine.name; },
                        getDisplay: function (machine) { return machine.name; },
                    },
                    {
                        id: 'accessCount',
                        name: this.i18nService.get('reporting.webThreatReport.domainExposedMachines.accessCountHeader'),
                        className: 'nowrap',
                        sort: {
                            sortDescendingByDefault: true,
                            getLocalSortValue: 'accessCount',
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return DomainExposedMachinesFields;
}());
export { DomainExposedMachinesFields };
