import { DataSet } from '@microsoft/paris';
import { FabricIconName } from '@wcd/scc-common';
import { TrackingEventPropertiesBase } from '../../insights/models/tracking-event.interface';
import { RbacControlState } from '../../rbac/models/rbac-control-settings.model';
import { TagsEditSettings } from '../../tags/components/tags-edit/tags-edit.component';
import { RbacMdeAllowedActions } from '../../rbac/enums/mde-allowed-actions.enum';
import { IButtonStyles } from 'office-ui-fabric-react';
import { MtpWorkload } from '@wcd/domain';
import { MtpPermission } from '@wcd/auth';

/**
 * An icon name (from IconsService) or a Fabric icon name.
 */
export type ItemActionIconName = string | FabricIconName;

export interface ItemActionIcon {
	/**
	 * The name of the icon
	 */
	readonly iconName: ItemActionIconName;

	/**
	 * An optional class name to pass to the icon, where supported.
	 */
	readonly className?: string;

	/**
	 * Any additional attributes that the icon element should have.
	 * These are passed as-is to the icon's DOM element (`<i>`).
	 * @example `data-` attributes.
	 */
	readonly [attribute: string]: string;
}

export type ItemActionIconNameOrConfig = ItemActionIconName | ItemActionIcon;

export class ItemActionModel<TData = any> implements ItemActionModelConfig<TData> {
	id: string;
	name: string;
	nameKey: string;
	href: string;
	openLinkInNewTab: boolean = false;
	icon: ItemActionIconNameOrConfig;
	image: string;
	tooltip: string;
	method: (
		items: Array<TData>,
		options?: ItemActionOptions,
		dataSet?: DataSet<TData>,
		itemActionValue?: ItemActionValue
	) => Promise<any>;
	refreshOnResolve: boolean = true;
	refreshEntityPanelOnResolve: boolean = false;
	localRefreshOnResolve: boolean;
	buttonClass: string;
	styles: IButtonStyles;
	state: { stateId: string; params: any };
	values?: Array<ItemActionValue>;
	selectedValue?: ItemActionValue;
	allowRbacTooltipOverride?: boolean;
	customCheckCallback?: () => boolean;
	rbac: Array<RbacMdeAllowedActions>;
	rbacState: RbacControlState;
	rbacMtpPermission: Array<MtpPermission>;
	rbacMtpWorkloads: Array<MtpWorkload>;
	rbacRequireAllPermissions: boolean;
	rbacIgnoreNonActiveWorkloads: boolean;
	disabled?: boolean;
	allowDisplayWhileLoading?: boolean;
	closeOnAction: boolean | ((data: any) => boolean);
	type: ItemActionType;
	options?: ItemActionOptions;
	tracking?: ItemActionTrackingOptions;
	ariaLabel?: string;
	constructor(data: ItemActionModelConfig<TData>) {
		Object.assign(this, data);

		if (!this.type) this.type = DEFAULT_ITEM_ACTION_TYPE;
	}
}

export interface ItemActionModelConfig<TData = any> {
	/**
	 * Unique identifier for this action.
	 * Must be unique within the item's actions list.
	 */
	id: string;

	/**
	 * The display text for the action.
	 * Mutually exclusive with `nameKey`.
	 */
	name?: string;

	/**
	 * A key to be used by the `i18nService` to look up the display text.
	 * Mutually exclusive with `name`.
	 */
	nameKey?: string;
	tooltip?: string;
	method?: (
		items: Array<TData>,
		options?: ItemActionOptions,
		dataSet?: DataSet<TData>,
		itemActionValue?: ItemActionValue<TData>
	) => Promise<any>;
	/**
	 * An optional URL to navigate to upon selection
	 */
	href?: string;
	/**
	 * When href is used, true value will open the link in new tab.
	 */
	openLinkInNewTab?: boolean;
	icon?: ItemActionIconNameOrConfig;
	image?: string;
	/**
	 * Should the entity this action relates to be refreshed when the action's `method` resolves.
	 */
	refreshOnResolve?: boolean;
	refreshEntityPanelOnResolve?: boolean;
	localRefreshOnResolve?: boolean;
	buttonClass?: string;
	state?: { stateId: string; params: any };
	values?: Array<ItemActionValue<TData>>;
	selectedValue?: ItemActionValue<TData>;
	placeholder?: string;
	/**
	 * Whether the item should be disabled.
	 * The final disabled state will be determined by this in addition to the RBAC state.
	 */
	disabled?: boolean;
	/**
	 * Allow action to be displayed in the command bar even if the entity is still loading.
	 */
	allowDisplayWhileLoading?: boolean;
	rbac?: Array<RbacMdeAllowedActions>;
	rbacState?: RbacControlState;
	allowRbacTooltipOverride?: boolean;
	rbacMtpPermission?: Array<MtpPermission>;
	rbacMtpWorkloads?: Array<MtpWorkload>;
	customCheckCallback?: () => boolean;
	rbacRequireAllPermissions?: boolean;
	rbacIgnoreNonActiveWorkloads?: boolean;
	closeOnAction?: boolean | ((data: any) => boolean);
	type?: ItemActionType;
	options?: ItemActionOptions;
	tracking?: ItemActionTrackingOptions;
	ariaLabel?: string;
}

export interface ItemActionValue<TData = any> {
	id: string;
	name: string;
	icon?: string;
	value?: any;
	method?: (
		items: Array<TData>,
		options?: ItemActionOptions,
		dataSet?: DataSet<TData>,
		itemActionValue?: ItemActionValue
	) => Promise<any>;
}

export enum ItemActionType {
	Button = 'Button',
	Tags = 'Tags',
	Hidden = 'Hidden',
}

const DEFAULT_ITEM_ACTION_TYPE: ItemActionType = ItemActionType.Button;

export interface ItemActionOptions<T = any> {
	tags?: TagsEditSettings<T>;
}

export type ItemActionTrackingOptions = Readonly<TrackingEventPropertiesBase>;
