/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../dialogs/modals/components/modal.component.ngfactory";
import * as i2 from "../../dialogs/modals/components/modal.component";
import * as i3 from "./feedback.component.ngfactory";
import * as i4 from "./feedback.component";
import * as i5 from "../../../../../../projects/auth/src/lib/services/auth.service";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../support-central/support-central.service";
import * as i9 from "../../dialogs/services/dialogs.service";
import * as i10 from "./feedback.modal.component";
import * as i11 from "@angular/router";
var styles_FeedbackModalComponent = [];
var RenderType_FeedbackModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FeedbackModalComponent, data: {} });
export { RenderType_FeedbackModalComponent as RenderType_FeedbackModalComponent };
export function View_FeedbackModalComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "modal", [["data-html2canvas-ignore", ""]], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(2, 180224, null, 0, i2.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 1, "wcd-feedback", [["i18nType", "feedback."]], null, [[null, "done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("done" === en)) {
        var pd_0 = (_co.onDone() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FeedbackComponent_0, i3.RenderType_FeedbackComponent)), i0.ɵdid(5, 49152, null, 0, i4.FeedbackComponent, [i0.NgZone, i5.AuthService, i6.Paris, i7.I18nService, i8.SupportCentralService, i9.DialogsService, i0.ChangeDetectorRef], { isEmailOptional: [0, "isEmailOptional"], i18nType: [1, "i18nType"], submitApiCall: [2, "submitApiCall"] }, { done: "done" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = true; var currVal_2 = "feedback."; var currVal_3 = _co.submitApiCall; _ck(_v, 5, 0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_FeedbackModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-feedback-modal", [], null, null, null, View_FeedbackModalComponent_0, RenderType_FeedbackModalComponent)), i0.ɵdid(1, 245760, null, 0, i10.FeedbackModalComponent, [i11.Router, i9.DialogsService, i7.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FeedbackModalComponentNgFactory = i0.ɵccf("wcd-feedback-modal", i10.FeedbackModalComponent, View_FeedbackModalComponent_Host_0, { settings: "settings" }, {}, []);
export { FeedbackModalComponentNgFactory as FeedbackModalComponentNgFactory };
