import { NgModule } from '@angular/core';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { LiveResponseCommandEntityTypeService } from './services/live-response-command.entity-type.service';
import { LiveResponseCommandEntityPanelComponent } from './components/live-response-command.entity-panel.component';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule],
	providers: [LiveResponseCommandEntityTypeService],
	declarations: [LiveResponseCommandEntityPanelComponent],
	entryComponents: [LiveResponseCommandEntityPanelComponent],
})
export class LiveResponseCommandEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(LiveResponseCommandEntityTypeService);
	}
}
