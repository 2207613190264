var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
var FilterItemValue = /** @class */ (function (_super) {
    __extends(FilterItemValue, _super);
    function FilterItemValue() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Key' }),
        __metadata("design:type", String)
    ], FilterItemValue.prototype, "key", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], FilterItemValue.prototype, "name", void 0);
    FilterItemValue = __decorate([
        ValueObject({
            singularName: 'Filter Item Value',
            pluralName: '',
        })
    ], FilterItemValue);
    return FilterItemValue;
}(ModelBase));
export { FilterItemValue };
var ɵ0 = ['FilterType', 'type'];
var FilterItem = /** @class */ (function (_super) {
    __extends(FilterItem, _super);
    function FilterItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Key' }),
        __metadata("design:type", String)
    ], FilterItem.prototype, "key", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], FilterItem.prototype, "name", void 0);
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", String)
    ], FilterItem.prototype, "filterType", void 0);
    __decorate([
        EntityField({ data: 'Values', arrayOf: FilterItemValue }),
        __metadata("design:type", Array)
    ], FilterItem.prototype, "values", void 0);
    FilterItem = __decorate([
        ValueObject({
            singularName: 'Filter Item',
            pluralName: '',
        })
    ], FilterItem);
    return FilterItem;
}(ModelBase));
export { FilterItem };
export { ɵ0 };
