import { SevilleModule } from '../../seville/seville.module';
import { map } from 'lodash-es';
import { FeaturesService } from '@wcd/config';

SevilleModule.factory('filtersLocalStorage', filtersLocalStorage);

filtersLocalStorage.$inject = ['$localStorage', 'featuresService'];

function filtersLocalStorage($localStorage, featuresService: FeaturesService) {
	var deafultUserContext = 'LogonUsers,System,Network,LocalService';
	var machineTimelineEventTypeFilterOptions = [
		{ id: 'Alerts', label: 'Alerts', icon: 'icon-LightningBolt' },
		{ id: 'WindowsDefenderAvEvents', label: 'Antivirus events', icon: 'icon-Castle' },
		{ id: 'AppGuard', label: 'Application Guard events', icon: 'icon-DeviceGuard' },
		{ id: 'DeviceGuard', label: 'Device Guard events', icon: 'icon-MixedMediaBadge' },
		{ id: 'ExploitGuard', label: 'Exploit Guard events', icon: 'icon-Admin' },
		{ id: 'SmartScreen', label: 'Smart Screen events', icon: 'icon-Shield' },
		{ id: 'Firewall', label: 'Firewall events', icon: 'wcd-icons--wall' },
		{ id: 'ResponseActions', label: 'Response actions', icon: 'icon-F12DevTools' },
		{ id: 'ProcessEvents', label: 'Process events', icon: 'icon-Settings' },
		{ id: 'NetworkEvents', label: 'Network events', icon: 'icon-Streaming' },
		{ id: 'FileEvents', label: 'File events', icon: 'icon-Page' },
		{ id: 'RegistryEvents', label: 'Registry events', icon: 'icon-OEM' },
		{ id: 'LoadImage', label: 'Load DLL events', icon: 'icon-Processing' },
		{ id: 'OtherEvents', label: 'Other events', icon: 'icon-ProvisioningPackage' },
	];

	if (featuresService.isEnabled('MachineTimelineLogonEvents')) {
		const logonEventsEntry = {
			id: 'LogonEvents',
			label: 'Logon events',
			icon: 'icon-SwitchUser',
		};
		machineTimelineEventTypeFilterOptions.splice(
			machineTimelineEventTypeFilterOptions.length - 1,
			0,
			logonEventsEntry
		);
	}

	// creating a list of filters from the id property in filter options object
	var defaultEventTypeFilter = map(machineTimelineEventTypeFilterOptions, 'id').join(',');

	var service = {
		getEventTypeFilterSavedOrDefault: function() {
			// validate that the default filter is up to date
			if ($localStorage.DefaultMachineTimelineEventTypesFilter !== defaultEventTypeFilter) {
				$localStorage.DefaultMachineTimelineEventTypesFilter = defaultEventTypeFilter;
				$localStorage.MachineTimelineEventTypesFilter = defaultEventTypeFilter;
				return defaultEventTypeFilter;
			}

			// if default filter is updated and saved filter exists - return the saved filter. otherwise return the default
			return $localStorage.MachineTimelineEventTypesFilter || defaultEventTypeFilter;
		},
		getUserContextSavedOrDefault: function() {
			// validate that the default filter is up to date
			if ($localStorage.DefaultMachineTimelineUserContextFilter !== deafultUserContext) {
				$localStorage.DefaultMachineTimelineUserContextFilter = deafultUserContext;
				$localStorage.MachineTimelineContextFilter = deafultUserContext;
				return deafultUserContext;
			}

			// if default filter is updated and saved filter exists - return the saved filter. otherwise return the default
			return $localStorage.MachineTimelineContextFilter || deafultUserContext;
		},
		defaultEventTypeFilter: defaultEventTypeFilter,
		deafultUserContext: deafultUserContext,
		machineTimelineEventTypeFilterOptions: machineTimelineEventTypeFilterOptions,
	};

	return service;
}
