import { ValueObject, EntityField, ModelBase, ParisConfig } from '@microsoft/paris';
import { AadGroup } from './aad-group.entity';

@ValueObject({
	singularName: 'Device Group Assignment',
	pluralName: 'Device Group Assignments',
})
export class MachineGroupAssignment extends ModelBase {
	@EntityField({ data: 'MachineGroupAssignmentId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({
		data: 'WcdAadGroup',
		serialize: (aadGroup: AadGroup) => {
			return {
				ObjectId: aadGroup.id,
				DisplayName: aadGroup.name,
			};
		},
	})
	aadGroup: AadGroup;

	@EntityField({ data: 'LastUpdated' })
	lastUpdated: Date;
}
