import { ChangeDetectorRef, Input, OnInit } from '@angular/core';
import { EntitiesPanelComponentBase } from './entities-panel.component.base';
import { ModelBase } from '@microsoft/paris';

export abstract class EntityPanelComponentBase<TEntity extends ModelBase = ModelBase, TOptions = {}>
	extends EntitiesPanelComponentBase<TEntity, TOptions>
	implements OnInit {
	@Input() entity: TEntity;
	@Input() contextLog: string;

	isInit: boolean;
	isNgOnInit: boolean = false;
	hasExtendedData: boolean = false;

	constructor(protected changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit() {
		this.isNgOnInit = true;
		this.setEntity(this.entity, this.hasExtendedData);
	}

	setEntity(entity: TEntity, isExtendedData: boolean = false): void {
		const isDifferentEntity: boolean = !this.entity || entity.id !== this.entity.id;

		this.entity = entity;
		this.hasExtendedData = isExtendedData;

		if (!this.isNgOnInit) return;

		if (this.isInit && isDifferentEntity) this.resetExtraDataSubscriptions();

		if (!this.isInit || isDifferentEntity || isExtendedData) {
			this.initEntity(entity, isExtendedData);
			this.isInit = true;
		}
	}

	protected initEntity(entity: TEntity, isExtendedData: boolean = false): void {}
}
