<wcd-panel (close)="destroy()" [settings]="settings"
	class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="recommendation-context-by-key-panel"
	data-track-component-type="Side Panel">
	<div class="wcd-flex-horizontal wcd-padding-large-horizontal">
		<h4 class="wcd-flex-1 wcd-padding-bottom side-panel-title">
			{{ panelSubTitle }}
		</h4>
		<button
			class="tvm-show-more-csv-export-button"
			*ngIf="isExportEnabled"
			(click)="exportCveData()"
			[wcdTooltip]="exportTooltipText || 'Export data to CSV'">
			<wcd-shared-icon iconName="Download"></wcd-shared-icon>
			<span>{{ 'export' | i18n }}</span>
		</button>
	</div>
	<dataview class="wcd-flex-1"
		*ngIf="securityRecommendation"
		[entityType]="entityType"
		[dataViewConfig]="dataViewConfig"
		[repository]="repository"
		[navigateOnChange]="false"
		[allowPaging]="true"
		[allowFilters]="false"
		[searchEnabled]="false"
		[loadingMessageText]="loadingMessageText"
		[ignoreQueryParams]="true"
		[allowColumnCustomization]="false"
		[padLeft]="true"
		[fields]="fields"
		[disableSelection]="true"
		(onItemClick)="openRecommendationContextItemPanel($event.item)">
	</dataview>
</wcd-panel>
