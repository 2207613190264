import { Injectable } from '@angular/core';
import { Observable, of, timer } from 'rxjs';
import { map, tap, retryWhen, scan, delayWhen } from 'rxjs/operators';
import {
	MtpEnableConsentApiCall,
	MtpDisableConsentApiCall,
	GetMtpConsent,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class MtpOnboardingService {
	constructor(private readonly paris: Paris, private readonly appConfigService: AppConfigService) {}

	getConsent(): Observable<boolean> {
		return this.paris.apiCall(GetMtpConsent).pipe(
			map(res => !!res.MtpConsent),
			tap(res => {
				this.appConfigService.updateChangeableProperties({ mtpConsent: res });
			}),
			retryWhen((errors: Observable<any>) => {
				return errors.pipe(
					scan((retries: number, err) => {
						if (retries > 2) throw err;
						return retries + 1;
						}, 0),
					delayWhen(() => timer(500))
				);
			})
		);
	}

	setConsent(optIn: boolean): Observable<boolean> {
		let setConsentObservable: Observable<boolean>;

		if (optIn) {
			setConsentObservable = this.paris.apiCall(MtpEnableConsentApiCall).pipe(
				map(res => !!res),
				tap(_ => {
					this.appConfigService.updateChangeableProperties({
						mtpConsent: true,
					});
				})
			);
		} else {
			setConsentObservable = this.paris.apiCall(MtpDisableConsentApiCall).pipe(
				map(res => !!res),
				tap(_ => {
					this.appConfigService.updateChangeableProperties({
						mtpConsent: false,
					});
				})
			);
		}

		return setConsentObservable.pipe(
			retryWhen((errors: Observable<any>) => {
				return errors.pipe(
					scan((retries: number, err) => {
						if (retries > 2) throw err;
						return retries + 1;
					}, 0),
					delayWhen(() => timer(500))
				);
			})
		);
	}
}
