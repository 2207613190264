var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { SpinnerSize } from 'office-ui-fabric-react';
import { BehaviorSubject } from 'rxjs';
import { Machine, TroubleshootApiCall } from '@wcd/domain';
import { PanelContainer, PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../../../rbac/models/rbac-control-settings.model';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "troubleshooting-mode-details";
})(CollapsibleID || (CollapsibleID = {}));
var privacyAndCookiesLink = "https://www.microsoft.com";
var TroubleshootingModePanelComponent = /** @class */ (function (_super) {
    __extends(TroubleshootingModePanelComponent, _super);
    function TroubleshootingModePanelComponent(router, paris, i18nService, dialogsService, changeDetectionRef) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.changeDetectionRef = changeDetectionRef;
        _this.SpinnerSize = SpinnerSize;
        _this.collapsibleID = CollapsibleID;
        _this.investigationPackageCollectionRbac = {
            state: RbacControlState.disabled,
            permissions: [RbacMdeAllowedActions.alertsInvestigation],
        };
        _this.requests$ = new BehaviorSubject([]);
        _this.isLoading = true;
        _this.isSaving = false;
        return _this;
    }
    TroubleshootingModePanelComponent.prototype.ngOnInit = function () {
        this.settings = __assign({}, this.settings, { showOverlay: true, hasCloseButton: true, type: PanelType.large, noBodyPadding: true, isBlocking: true, className: this.settings.className || '' });
        this.cookiesLink = privacyAndCookiesLink;
        _super.prototype.ngOnInit.call(this);
    };
    TroubleshootingModePanelComponent.prototype.ngOnDestroy = function () {
        if (this._dataSetSubscription) {
            this._dataSetSubscription.unsubscribe();
        }
    };
    TroubleshootingModePanelComponent.prototype.submitRequest = function () {
        var _this = this;
        if (this.isSaving) {
            return;
        }
        this.isSaving = true;
        var startTime = new Date();
        startTime.setSeconds(startTime.getSeconds() + 2);
        var expirationDate = new Date();
        // setHours and setMinutes mutates the expirationDate but returns a number. Will need to do in 2 steps
        expirationDate.setHours(startTime.getHours() + 3);
        expirationDate.setMinutes(startTime.getMinutes());
        this.paris.apiCall(TroubleshootApiCall, {
            machine: this.machine,
            comment: "Start TroubleshootMode on machineId: " + this.machine.id,
            troubleshootExpirationDateTimeUtc: expirationDate,
            troubleshootStartDateTimeUtc: startTime,
            paramsJsonFormatVersion: 1,
            troubleshootState: 1,
        }).subscribe(function () {
            _this.isSaving = false;
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submitSuccess.snackbar'),
                icon: 'CheckMark',
            });
            _this.changeDetectionRef.markForCheck();
            _this.closePanel();
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submit.failure.title'),
                data: _this.i18nService.get('machines.entityDetails.actions.turnOnTroubleshootingMode.panel.submit.failure.description'),
            });
        });
    };
    return TroubleshootingModePanelComponent;
}(PanelContainer));
export { TroubleshootingModePanelComponent };
