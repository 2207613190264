import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationType } from '../remediation/remediation-task-type.enum';

@ValueObject({
	singularName: 'SecurityConfiguration arguments',
	pluralName: 'SecurityConfigurations arguments',
})
export class SecurityConfigurationArgs extends ModelBase {
	@EntityField({
		required: true,
		data: 'scid',
	})
	readonly scid: string;

	@EntityField({
		data: 'type',
	})
	readonly taskType: RemediationType;
}
