import { Injectable } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
	CustomTiIndicator,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	DeleteTiIndicatorsApiCall,
	File,
	Ip,
	KnownRequestSource,
	MachineGroup,
	Url,
} from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorEditComponent } from '../components/custom-ti-indicator-details.component';
import { CustomTiIndicatorsService } from './custom-ti-indicators.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';

@Injectable()
export class CustomTiIndicatorEntityTypeService implements EntityTypeService<CustomTiIndicator> {
	private _machineRbacGroups: Array<MachineGroup>;
	private _rbacMachineGroupsEnabled: boolean;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private authService: AuthService,
		private machineService: MachinesService,
		private featureService: FeaturesService,
		private customTiIndicatorsService: CustomTiIndicatorsService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {
		this._rbacMachineGroupsEnabled = featureService.isEnabled(Feature.RbacMachineGroups);
	}

	setUserExposedMachineGroups(): Observable<Array<MachineGroup>> {
		if (this._rbacMachineGroupsEnabled && !this._machineRbacGroups) {
			return this.machineService.getFullUserExposedMachineGroups().pipe(
				tap((machineGroup: Array<MachineGroup>) => {
					this._machineRbacGroups = machineGroup;
				})
			);
		}

		return of(this._machineRbacGroups || []);
	}

	readonly entityType: EntityType<CustomTiIndicator> = {
		id: 'custom-ti-indicator',
		entity: CustomTiIndicator,
		getIcon: (indicators: Array<CustomTiIndicator>) => {
			const indicatorType = this.paris.getValue<CustomTiIndicatorsType, string>(
				CustomTiIndicatorsType,
				indicators[0].tiIndicatorType.type
			);

			return this.customTiIndicatorsService.getIcon(indicatorType.id);
		},
		loadFullEntityInPanel: false,
		getEntityName: (indicator: CustomTiIndicator) => {
			return this.paris.getValue<CustomTiIndicatorsType, string>(
				CustomTiIndicatorsType,
				indicator.tiIndicatorType.type
			).singularName;
		},
		singleEntityPanelComponentType: CustomTiIndicatorEditComponent,
		getItemParams: (indicator: CustomTiIndicator) => {
			return { id: indicator.id };
		},
		getEntitiesLink: (indicators: Array<CustomTiIndicator>) => {
			if (indicators.length !== 1 || !indicators[0].tiIndicator) {
				return null;
			}

			const [indicator] = indicators;
			const indicatorType = this.paris.getValue<CustomTiIndicatorsType, string>(
				CustomTiIndicatorsType,
				indicator.tiIndicatorType.type
			);

			switch (indicatorType.id) {
				case CustomTiIndicatorsTypes.Files:
					return this.globalEntityTypesService.getEntityLink(File, <Partial<File>>{
						id: indicator.tiIndicator,
					});
				case CustomTiIndicatorsTypes.Ip:
					return this.globalEntityTypesService.getEntityLink(Ip, <Partial<Ip>>{
						id: indicator.tiIndicator,
					});
				case CustomTiIndicatorsTypes.Url:
					return this.globalEntityTypesService.getEntityLink(Url, <Partial<Url>>{
						id: indicator.tiIndicator,
					});
			}
			return null;
		},
		getEntitiesLinkText: () => this.i18nService.get('customTiIndicator.dataview.actions.openPage.title'),
		getEntitiesLinkDisabled: (indicators: Array<CustomTiIndicator>) => {
			const [indicator] = indicators;
			return indicator.IsIpRange;
		},
		getActions: (indicators: Array<CustomTiIndicator>): ReadonlyArray<ItemActionModel> => {
			let actions: Array<ItemActionModelConfig> = [];

			let isRemovable = indicators.some((indicator: CustomTiIndicator) => {
				// in case that current user not admin but selected one of the records that was added by admin, don't allow to delete
				return !indicator.rbacGroupIds || indicator.rbacGroupIds.length === 0
					? this.authService.currentUser.isMdeAdmin
					: true;
			});

			isRemovable = isRemovable && indicators.every((indicator: CustomTiIndicator) => !indicator.createdBySource || (indicator.createdBySource && indicator.createdBySource.id !== KnownRequestSource.TVM))

			if (!isRemovable) {
				return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
			}

			actions = [
				...actions,
				{
					id: 'delete',
					nameKey: 'delete',
					icon: 'delete',
					method: (items: Array<CustomTiIndicator>) => {
						const deletedItems: Array<Number> = items.map(
							(item: CustomTiIndicator) =>  item.id
						);

						const repository: Repository<CustomTiIndicator> = this.paris.getRepository(
							CustomTiIndicator
						);

						const customTiIndicatorsType: CustomTiIndicatorsType = this.paris.getValue(
							CustomTiIndicatorsType,
							(type: CustomTiIndicatorsType) => type.id === indicators[0].tiIndicatorType.type
						);

						return new Promise((resolve, reject) => {
							const remove = () => {
								const doRemove: () => Observable<void> = () =>
								this.paris
								.apiCall<void, Array<Number>>(
									DeleteTiIndicatorsApiCall,
									deletedItems
								);

								doRemove()
									.toPromise()
									.then(items => {
										resolve(deletedItems);

										this.dialogsService.showSnackbar({
											text: `Deleted ${(deletedItems.length === 1
												? customTiIndicatorsType.singularName
												: deletedItems.length + ' ' + customTiIndicatorsType.name
											).toLowerCase()}`,
											icon: 'delete',
											iconClassName: 'color-text-error',
										});
									}, onError);
							};

							const onError = error => {
								this.dialogsService.showError({
									title: this.i18nService.get('customTiIndicator_error_FailedToDelete', { type:
										deletedItems && deletedItems.length === 1
											? customTiIndicatorsType.singularName
											: customTiIndicatorsType.name
									}),
									data: error,
								});

								reject(error);
							};

							const itemName: string =
								items.length === 1
									? `this ${customTiIndicatorsType.singularName}`
									: `these ${items.length} ${customTiIndicatorsType.name}`;

							return this.dialogsService
								.confirm({
									title: `Delete ${
										items.length === 1
											? customTiIndicatorsType.singularName
											: items.length + ' ' + customTiIndicatorsType.name
									}`,
									text: `Are you sure you wish to delete ${itemName.toLowerCase()}?`,
									confirmText: 'Delete',
								})
								.then((e: ConfirmEvent) => (e.confirmed ? remove() : resolve(false)));
						});
					},
					closeOnAction: true,
					rbac: [RbacMdeAllowedActions.securitySettings, RbacMdeAllowedActions.remediationActions],
					rbacState: RbacControlState.hidden,
					refreshOnResolve: true,
				},
			];

			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
	};
}
