import { ChangeDetectorRef } from '@angular/core';
import { ColorClassNames } from '@uifabric/styling';
import { compact } from 'lodash-es';
import { isFabricIconName } from '@wcd/scc-common';
import { IconsService } from '../services/icons.service';
import { iconLibraries } from '../services/icon_libraries';
import { isWcdIconName } from '@wcd/icons-font';
var verticalAlignMap = {
    top: 'wcd-vertical-align-top',
    middle: 'wcd-vertical-align-middle',
    bottom: 'wcd-vertical-align-bottom',
    baseline: 'wcd-vertical-align-baseline',
};
var IconComponent = /** @class */ (function () {
    function IconComponent(iconsService, _changeDetectionRef) {
        this.iconsService = iconsService;
        this._changeDetectionRef = _changeDetectionRef;
        this.verticalAlign = null;
        this.isFabricReactOrWcdIcon = false;
    }
    Object.defineProperty(IconComponent.prototype, "text", {
        get: function () {
            return (this.icon && !this.icon.library.useClass && this.icon.name) || '';
        },
        enumerable: true,
        configurable: true
    });
    IconComponent.prototype.ngOnChanges = function (changes) {
        if (changes.iconName && changes.iconName.currentValue) {
            this.setIcon(this.iconName);
        }
        var verticalAlignClass = changes.verticalAlign &&
            changes.verticalAlign.currentValue &&
            this.calculateVerticalAlignClass(changes.verticalAlign.currentValue);
        var colorClass = changes.iconColor &&
            changes.iconColor.currentValue &&
            this.calculateIconColorClass(changes.iconColor.currentValue);
        this.iconDynamicClass = this.combineClasses(verticalAlignClass, colorClass, this.iconClass);
        this.iconTypeClass = this.iconsService.getIconClassName(this.icon);
        this._changeDetectionRef.markForCheck();
    };
    IconComponent.prototype.calculateVerticalAlignClass = function (verticalAlign) {
        return verticalAlignMap[verticalAlign];
    };
    IconComponent.prototype.combineClasses = function () {
        var classNames = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            classNames[_i] = arguments[_i];
        }
        return compact(classNames).join(' ');
    };
    IconComponent.prototype.calculateIconColorClass = function (iconColor) {
        return ColorClassNames[iconColor];
    };
    IconComponent.prototype.setIcon = function (iconName) {
        if (!iconName) {
            return;
        }
        this.parsedIconName = this.getFabricOrWcdIconName(iconName);
        this.isFabricReactOrWcdIcon = !!this.parsedIconName;
        if (!this.isFabricReactOrWcdIcon) {
            this.icon = this.iconsService.getIcon(iconName) || null;
            if (!this.icon) {
                console.warn("Unknown icon: '" + iconName + "'.");
            }
        }
    };
    IconComponent.prototype.getFabricOrWcdIconName = function (iconName) {
        if (isFabricIconName(iconName) || isWcdIconName(iconName)) {
            return iconName;
        }
        var icon = this.iconsService.getIcon(iconName);
        if (icon && icon.library === iconLibraries.fabric) {
            return icon.name;
        }
        else if (icon && icon.library === iconLibraries.wcd) {
            return icon.name;
        }
        return null;
    };
    return IconComponent;
}());
export { IconComponent };
