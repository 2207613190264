import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { LegendItem } from '@wcd/charts';
import { I18nService } from '@wcd/i18n';
import {
	ColorMap,
	EventModel,
	EventsSummaryBarSize,
	KindTextFormatter,
} from '../../../shared/components/events-summary-bar/events-summary-bar.component';
import { CalculatePercentagePipe } from '../../pipes/calculate-percentage.pipe';
import { BaselineCompliantBarService } from '../../services/baseline-compliant-bar.service';
import { BaselineCompliantColorService } from '../../services/baseline-compliant-color.service';
import { BaselineCompliant } from './baseline-compliant.enum';

type BaselineCompliantConfig = LegendItem & EventModel<BaselineCompliant>;

@Component({
	selector: 'baseline-compliant-bar',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './baseline-compliant-bar.component.html',
	styleUrls: ['./baseline-compliant-bar.component.scss']
})
export class BaselineCompliantBarComponent implements OnInit, OnChanges {
	@Input() showLegend = true;
	@Input() size = EventsSummaryBarSize.Medium;
	@Input() compliantCount: number;
	@Input() notCompliantCount: number;
	@Input() dataUnavailableCount = 0;
	@Input() total: number;
	@Input() showPercentage: boolean;
	@Input() showNumbers: boolean;

	percentage: string;
	events: BaselineCompliantConfig[];
	colorMap: ColorMap<BaselineCompliant>;
	textFormatter: KindTextFormatter<BaselineCompliant>;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private baselineCompliantColorService: BaselineCompliantColorService,
		private displayCalculatePercentagePipe: CalculatePercentagePipe,
		private baselineCompliantBarService: BaselineCompliantBarService
	) {}

	ngOnInit(): void {
		this.events = this.baselineCompliantBarService.baselineCompliantKeys
			.map(key => {
				const count = this[key];
				return this.createBaselineCompliantConfig(key, count);
			})
			.filter(Boolean);
		this.colorMap = this.baselineCompliantBarService.compliantColorMap;
		this.textFormatter = this.baselineCompliantBarService.compliantTextFormatter;
		this.percentage = `(${this.displayCalculatePercentagePipe.transform(
			this.compliantCount,
			this.total
		)})`;
		this.changeDetectorRef.detectChanges();
	}

	ngOnChanges(_: SimpleChanges): void {
		this.changeDetectorRef.detectChanges();
	}

	private createBaselineCompliantConfig(
		baselineCompliantType: BaselineCompliant,
		count: number
	): BaselineCompliantConfig {
		if (count === 0) return null;

		const legendConfig: LegendItem = {
			name: this.baselineCompliantBarService.legendTextFormatter(baselineCompliantType, count),
			iconClassName: this.baselineCompliantColorService.fontColorsClassMap.get(baselineCompliantType),
		};

		const barConfig: EventModel<BaselineCompliant> = {
			kind: baselineCompliantType,
			count: count,
		};

		return { ...legendConfig, ...barConfig };
	}
}
