import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { Ip, NetworkEndpoint, Url } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';

@Component({
	selector: 'network-endpoint-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './network-endpoint.entity-details.component.html',
})
export class NetworkEndpointEntityDetailsComponent extends EntityDetailsComponentBase<NetworkEndpoint> {
	readonly ipType = Ip;
	readonly urlType = Url;

	get endpoint(): NetworkEndpoint {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
