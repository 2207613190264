import { NgModule } from '@angular/core';
import { EntityRelationsComponent } from './components/entity-relations.component';
import { EntityBehaviorComponent } from './components/entity-behavior.component';
import { EntityReportComponent } from './components/entity-report.component';
import { AirsEntitiesService } from './services/airs-entities.service';
import { AirsEntitiesDataViewComponent } from './components/airs-entities-dataview.component';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { MapsModule } from '../../maps/maps.module';
import { AirsEntityTypesModule } from './airs-entity-types.module';
import { MemoryContentsComponent } from './components/memory-contents.component';
import { SharedModule } from '../../shared/shared.module';
import { RelatedEntitiesService } from './services/related-entities.service';
import { AirsEntityEntityPanelComponent } from './components/airs-entity.entity-panel.component';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { AirsEntityEntityTypeService } from './services/airs-entity.entity-type.service';
import { GoHuntAirsEntityService } from './services/go-hunt-airs-entity.service';
import { EntityDetailsComponent } from './components/entity-details.component';
import { EntityActionComponent } from './components/entity-action.component';
import { EmailEntityAttachmentsFieldComponent } from './components/email-entity-attachments-field.component';
import { EmailAttachmentsDetailsPanelComponent } from './components/email-attachments-details.panel.component';
import { EmailEntityUrlsFieldComponent } from './components/email-entity-urls-field.component';
import { EmailUrlsDetailsPanelComponent } from './components/email-urls-details.panel.component';
import { VerdictIndicatorsComponent } from './components/verdict-indicators.component';
import { EntityTypesDataviewComponent } from './components/entity-types-dataview.component';
import { ReportsModule } from '../../reports/reports.module';
import { DataTableModule } from '@wcd/datatable';
import { AlertsModule } from '../alerts/alerts.module';
import { FileEntityTypeModule } from '../files/file.entity-type.module';
import { BarsChartModule } from '@wcd/charts';
import { AirsEntityFileDetailsComponent } from './components/airs-entity.file-details.component';
import { FabSpinnerModule } from '@angular-react/fabric';
import { ExpanderModule } from '@wcd/expander';
import { EvidenceModule } from '../evidence/evidence.module';
import { GoHuntModule } from '../../hunting-go-hunt/hunting-go-hunt.module';
import { QueryService } from '@wcd/hunting';
import { AirsEntitiesLoaderService } from './services/airs-entities-loader.service';
import { DownloadEmailPanelComponent } from './components/download-email.panel.component';
import { FabMessageBarModule } from '@angular-react/fabric';
import { AirsEntitiesFieldsService } from './services/airs-entities-fields.service';
import { AirsFileDetailsComponent } from './components/types/airs-file-details.component';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { AirsProcessDetailsComponent } from './components/types/airs-process-details.component';
import { AirsDriverDetailsComponent } from './components/types/airs-driver-details.component';
import { AirsServiceDetailsComponent } from './components/types/airs-service-details.component';
import { AirsUrlDetailsComponent } from './components/types/airs-url-details.component';
import { AirsIpDetailsComponent } from './components/types/airs-ip-details.component';
import { AirsUserDetailsComponent } from './components/types/airs-user-details.component';
import { AirsPersistenceMethodDetailsComponent } from './components/types/airs-persistence-method-details.component';
import { AirsEmailClusterDetailsComponent } from './components/types/airs-email-cluster-details.component';
import { AirsEmailMessageDetailsComponent } from './components/types/airs-email-message-details.component';
import { AirsMailboxDetailsComponent } from './components/types/airs-mailbox-details.component';
import { AirsUserActivityDetailsComponent } from './components/types/airs-user-activity-details.component';
import { AirsMailboxConfigurationDetailsComponent } from './components/types/airs-mailbox-configuration-details.component';
import { EmailHeadersPanelComponent } from './components/email-headers.panel.component';
import { AirsEntityEntityPanelMultiComponent } from './components/airs-entity.entity-panel-multi.component';
import { AirsAccountDetailsComponent } from './components/types/airs-account-details.component';
import { AirsSecurityGroupDetailsComponent } from './components/types/airs-security-group-details.component';
import { AirsRegistryKeyDetailsComponent } from './components/types/airs-registry-key-details.component';
import { AirsRegistryValueDetailsComponent } from './components/types/airs-registry-value-details.component';
import { AirsCloudApplicationDetailsComponent } from './components/types/airs-cloud-application-details.component';
import { AirsCloudLogonSessionDetailsComponent } from './components/types/airs-cloud-logon-session-details.component';
import { AirsOauthApplicationDetailsComponent } from './components/types/airs-oauth-application-details.component';
import { AirsActiveDirectoryDomainDetailsComponent } from './components/types/airs-active-directory-domain-details.component';
import { AadUserEntityTypeModule } from '../AAD/users/aad-user.entity-type.module';
import { EmailDetectionTechnologiesDisplayNameService } from './services/email-entity-detection-technologies-display-field.service';
import { AirsEntityUnifiedPanelService } from './services/airs-entity-unified-panel.service';

@NgModule({
	imports: [
		DataTableModule,
		DataViewsModule,
		SharedModule,
		MapsModule,
		AirsEntityTypesModule,
		AlertsModule,
		FileEntityTypeModule,
		ReportsModule,
		BarsChartModule,
		FabSpinnerModule,
		ExpanderModule,
		EvidenceModule,
		GoHuntModule,
		FabMessageBarModule,
		GlobalEntitiesModule,
		AadUserEntityTypeModule,
	],
	declarations: [
		EntityReportComponent,
		EntityBehaviorComponent,
		EntityRelationsComponent,
		AirsEntitiesDataViewComponent,
		EntityTypesDataviewComponent,
		MemoryContentsComponent,
		AirsEntityEntityPanelComponent,
		AirsEntityEntityPanelMultiComponent,
		EntityDetailsComponent,
		AirsEntityFileDetailsComponent,
		EntityActionComponent,
		EmailEntityAttachmentsFieldComponent,
		EmailAttachmentsDetailsPanelComponent,
		EmailEntityUrlsFieldComponent,
		EmailUrlsDetailsPanelComponent,
		VerdictIndicatorsComponent,
		DownloadEmailPanelComponent,
		EmailHeadersPanelComponent,

		AirsFileDetailsComponent,
		AirsProcessDetailsComponent,
		AirsDriverDetailsComponent,
		AirsServiceDetailsComponent,
		AirsUrlDetailsComponent,
		AirsIpDetailsComponent,
		AirsUserDetailsComponent,
		AirsPersistenceMethodDetailsComponent,
		AirsEmailClusterDetailsComponent,
		AirsEmailMessageDetailsComponent,
		AirsMailboxDetailsComponent,
		AirsUserActivityDetailsComponent,
		AirsMailboxConfigurationDetailsComponent,
		AirsAccountDetailsComponent,
		AirsSecurityGroupDetailsComponent,
		AirsRegistryKeyDetailsComponent,
		AirsRegistryValueDetailsComponent,
		AirsCloudApplicationDetailsComponent,
		AirsCloudLogonSessionDetailsComponent,
		AirsOauthApplicationDetailsComponent,
		AirsActiveDirectoryDomainDetailsComponent,
	],
	exports: [
		EntityReportComponent,
		EntityBehaviorComponent,
		AirsEntitiesDataViewComponent,
		EntityTypesDataviewComponent,
		MemoryContentsComponent,
		EntityRelationsComponent,
		AirsEntityEntityPanelComponent,
		AirsEntityEntityPanelMultiComponent,
	],
	providers: [
		AirsEntitiesService,
		RelatedEntitiesService,
		AirsEntityEntityTypeService,
		GoHuntAirsEntityService,
		QueryService,
		AirsEntitiesLoaderService,
		AirsEntitiesFieldsService,
		EmailDetectionTechnologiesDisplayNameService,
		AirsEntityUnifiedPanelService,
	],
	entryComponents: [
		AirsEntityEntityPanelComponent,
		AirsEntityEntityPanelMultiComponent,
		EmailAttachmentsDetailsPanelComponent,
		EmailUrlsDetailsPanelComponent,
		DownloadEmailPanelComponent,
		EmailHeadersPanelComponent,
	],
})
export class AirsEntitiesModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(AirsEntityEntityTypeService);
	}
}
