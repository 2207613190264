import {
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	SimpleChanges,
	ViewChild,
	ChangeDetectorRef,
	Output,
	EventEmitter,
	ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChecklistComponent, ChecklistValue } from './checklist.component';
import { DropdownComponent } from './dropdown.component';
import { Focusable } from '../models/focusable.interface';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Positions } from '@wcd/forms';

export const CHECKLIST_DROPDOWN_CUSTOM_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => ChecklistDropdownComponent),
	multi: true,
};

@Component({
	selector: 'wcd-checklist-dropdown',
	templateUrl: './checklist-dropdown.component.html',
	providers: [CHECKLIST_DROPDOWN_CUSTOM_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./checklist-dropdown.component.scss'],
})
export class ChecklistDropdownComponent<TValue extends string | number = any>
	extends ChecklistComponent<TValue>
	implements OnInit, OnChanges, ControlValueAccessor, Focusable {
	@Input() buttonText: string;
	@Input() buttonTooltip: string;
	@Input() buttonIcon: string;
	@Input() placeholder: string;
	@Input() showIconDropdown: boolean = true;
    @Input() openMenuPosition: Positions = Positions.Default;

	@ViewChild(DropdownComponent, { static: true }) dropdown: DropdownComponent;

	/**
	 * An optional second button to add, after the All/None button.
	 * Used, for example, to reset the selected values to default.
	 * The Input is the text for the button.
	 * Use the `secondButtonClick` Output to listed to clicks from this button.
	 */
	@Input() secondButton: string;
	@Input() trackComponent: string;
	@Input() disableDropdown: boolean;
	@Input() isBordered = true;
	@Input() isFullWidth = false;
	@Input() labelClass: string;

	@Input() navigateUsingArrowKeysOnly = false;


	@Input() ariaRole="button"
	@Input() optionAriaRole?: string;
	@Input() ariaHaspopup?: string;
	@Input() ariaLabel?: string;
	@Input() disableSecondButton: boolean = false;
	@Input() wrapItemsLabel: boolean = false;

	@Output() secondButtonClick: EventEmitter<void> = new EventEmitter<void>();
	@Output() onChecklistChangeEmit: EventEmitter<void> = new EventEmitter<void>();
	@Output() toggleDropdownState: EventEmitter<boolean> = new EventEmitter();

	@ViewChild(ChecklistComponent, { static: false }) checklist: ChecklistComponent;

	@ViewChild('selectAllOrNoneButton', { static: true }) selectAllOrNoneButton: ElementRef<HTMLButtonElement>;

	allValuesAreDisabled: boolean;
	visible = false;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		public i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer
	) {
		super(changeDetectorRef);
	}

	closeDropdown() {
		this.dropdown.toggle();
	}

	isVisible(): boolean {
		return this.dropdown.isVisible;
	}

	setVisible(visible){
		this.visible = visible;
		this.toggleDropdownState.emit(visible);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.values) {
			this.allValuesAreDisabled = !changes.values.currentValue.some(value => !value.disabled);
		}
	}

	onChecklistChange(event: Array<ChecklistValue<TValue> | TValue>) {
		this.onChange(event);
		if (this.disableSecondButton) {
			this.onChecklistChangeEmit.emit();
		}
	}

	selectAllOrNone() {
		const selectedCount = this.values.filter(value => this.selectedValues[value.id]).length;
		if (selectedCount === this.values.length) {
			this.selectNone();
			this.liveAnnouncer.announce(
				this.i18nService.get('common.uncheckedAllColumns', {
					column: this.values[0].name,
				}),
				'assertive',
				300
			);
		} else {
			this.liveAnnouncer.announce(this.i18nService.get('common.checkedAllColumns'), 'assertive', 300);
			this.selectAll();
		}
	}

	setFocusOnAllOrNone(){
		if (this.selectAllOrNoneButton && this.selectAllOrNoneButton.nativeElement)
			this.selectAllOrNoneButton.nativeElement.focus();
	}

	setFocus = () => {
		if (this.dropdown) {
			this.dropdown.setFocus();
		}
	};
}
