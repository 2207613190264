
		<div class='wcd-full-height wcd-flex-vertical'>
			<wcd-banner
				[text]="i18nService.strings.alerts_queue_m365_incidents_queue_promotion_banner"
				[linkOptions]="microsoft365DefenderPromotionToIncidentsQueueLink"
			>
			</wcd-banner>

			<convergence-promotion-banner sccPagePath='/incidents' *ngIf="promotionBannerFeatureEnabled"></convergence-promotion-banner>

			<div class="queue-layout-header wcd-padding-xxLarge-horizontal" dataview-header *ngIf="showHeader">
				<h2>{{ titleService.pageTitle$ | async }}</h2>
			</div>

			<div class="wcd-flex-1 wcd-full-height wcd-flex-vertical">
				<dataview
					*ngIf="fields"
					class="wcd-full-height"
					[class.wcd-padding-xxLarge-horizontal]="dataviewHorizontalPadding"
					[id]="dataViewId || 'alerts'"
					[className]="className"
					[repository]="repository"
					[entityType]="entityType"
					[fields]="fields"
					[disabledFields]="disabledFields"
					[sortDisabledFields]="sortDisabledFields"
					[itemSelectable]="isAlertSelectable"
					[defaultSortFieldId]="defaultSortId"
					[isItemGroup]="isGroup"
					[setItemActions]="boundSetItemActions"
					[allowGroupItems]="allowGrouping && alertType.isGrouped"
					[getFilterQueryOptions]="entityType?.dataViewOptions.getFilterQueryOptions"
					[allowPaging]="allowPaging"
					[refreshOn]="refreshOn"
					[allowFilters]="allowFilters"
					[hideControls]="hideControls"
					[disableSelection]="disableSelection"
					[responsiveLayout]="responsiveLayout"
					[padLeft]="false"
					[fixedOptions]="dataviewFixedOptions"
					[getGroupItems]="boundGetContainedAlerts"
					[dataViewConfig]="dataViewConfig"
					[showDialogOnError]="showDialogOnError"
					(onData)="onDataIn($event)"
					[visibleFields]="visibleFields"
					[maxItems]="maxItems"
					[label]="'alerts.title' | i18n"
					[fixedTable]="fixedTable"
					disableFilterPanelAutoFocus="true"
					commandBarNoPadding="true"
					[focusOnTable]="focusOnTable"
					[queueHeader]="queueHeader"
					[entityPanelOptions]="entityPanelOptions"
					(afterTableInit)="afterTableInit($event)"
					[tabIndex]="(allowTimeRangeSelect || allowGrouping) && !isFirstInitDone ? false : -1"
					[focusOnFirstMenuItem]="!allowTimeRangeSelect && !allowGrouping"
					[responsiveActionBar]="responsiveActionBar"
					[customActionsRight]="customActionsRight ? commandBarRight : []"
					[removePadding]="removePadding"
					[removePaddingRight]="removePaddingRight"
					[removePaddingLeftOnResize]="removePaddingLeftOnResize"
					[fullHeight]="fullHeight"
					[entitySidePanelSettings]="{ role: 'none'}"
					(onTableRenderComplete)="onTableRenderCompleteLoaded()">
					<!--need to remove fancy select button when all command bars that use alerts.dataview will be responsiveActionBars-->
					<ng-container dataview-controls>
						<fancy-select
							[(ngModel)]="currentTimeRange"
							*ngIf="allowTimeRangeSelect"
							(ngModelChange)="onRangeTypeSelect($event)"
							class="command-bar-item-dropdown"
							[buttonIcon]="'calendar'"
							[label]="'name'"
							[values]="timeRanges"
							[ariaLabel]="'dataview.timePeriod' | i18n"
							[focusOnInit]="!isFirstInitDone"
							(focus)="afterFirstElementFocused()"
						></fancy-select>
					</ng-container>
					<ng-container dataview-controls-right>
						<fancy-select
							*ngIf="allowGrouping"
							[(ngModel)]="alertType"
							(ngModelChange)="onAlertTypeChange()"
							class="command-bar-item-dropdown"
							icon="icon"
							[values]="alertTypes"
							[formatLabel]="boundFormatVal"
							[focusOnInit]="!allowTimeRangeSelect && !isFirstInitDone"
							(focus)="afterFirstElementFocused()"
						></fancy-select>
					</ng-container>
				</dataview>
			</div>
			<div>
				<button
					*ngIf="maxItems && alertsData?.items?.length >= maxItems"
					(click)="onShowMoreAlerts()"
					data-track-id="ShowMoreAlerts"
					data-track-type="Button"
					class="small-button btn-inline btn-link no-padding"
				>
					{{ 'alerts.showMore' | i18n }}
				</button>
			</div>
		</div>
	