/* tslint:disable:template-click-events-have-key-events */
import { ElementRef, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { Router } from '@angular/router';
import { SecurityRecommendation, RemediationType } from '@wcd/domain';
import { map, take, debounceTime } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmTextsService, TextToken } from '../../services/tvm-texts.service';
import { FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmMachineGroupsFilterService } from '../../services/tvm-machine-groups-filter.service';
import { TvmTagsService } from '../../../tvm/services/tvm-tags.service';
import { Observable, fromEvent } from 'rxjs';
var MAX_CARDS_TO_DISPLAY = 15;
//max number of pixels to switch to dashboard vertical mode, check tvm.dashboard.component
var VERTICAL_MODE_MAX_PIXELS = 800;
var TvmSecurityRecommendationsComponent = /** @class */ (function () {
    function TvmSecurityRecommendationsComponent(machineGroupsFilterService, paris, router, tvmTextsService, featuresService, changeDetectorRef, i18nService, tvmTagsService) {
        var _this = this;
        this.machineGroupsFilterService = machineGroupsFilterService;
        this.paris = paris;
        this.router = router;
        this.tvmTextsService = tvmTextsService;
        this.featuresService = featuresService;
        this.changeDetectorRef = changeDetectorRef;
        this.i18nService = i18nService;
        this.tvmTagsService = tvmTagsService;
        this.SpinnerSize = SpinnerSize;
        this.cardsVisibilityMap = new Map();
        this.verticalMode = window.innerWidth <= VERTICAL_MODE_MAX_PIXELS;
        this.maxCardsToDisplay = MAX_CARDS_TO_DISPLAY;
        this.refreshData();
        this._resizeSubscription = fromEvent(window, 'resize')
            .pipe(debounceTime(100), map(function (e) { return true; }))
            .subscribe(function () {
            _this.refreshCardsView();
        });
    }
    //for vertical mode we render 3 security recommendation cards
    TvmSecurityRecommendationsComponent.prototype.refreshCardsView = function () {
        var _this = this;
        this.checkDashboardDisplayMode();
        if (this.verticalMode) {
            this._cardsData.map(function (card, index) {
                card.showCard = index < 3 ? true : false;
            });
            Array.from(this.cardsVisibilityMap.entries()).map(function (entry, index) {
                _this.cardsVisibilityMap.set(entry[0], index < 3 ? true : false);
            });
            this.updateVisibaleCards();
        }
        else {
            this._cardsData.map(function (card) { return (card.showCard = true); });
        }
        this.changeDetectorRef.markForCheck();
    };
    TvmSecurityRecommendationsComponent.prototype.refreshData = function () {
        this._cardsData = null;
        this.changeDetectorRef.markForCheck();
        this.loadData();
    };
    TvmSecurityRecommendationsComponent.prototype.loadData = function () {
        var _this = this;
        this.paris
            .getRepository(SecurityRecommendation)
            .query({
            page: 1,
            pageSize: this.maxCardsToDisplay,
            where: { status: 'Active' },
            sortBy: [
                {
                    field: 'exposureScoreImprovement',
                    direction: DataQuerySortDirection.descending,
                },
                {
                    field: 'secureScoreImprovement',
                    direction: DataQuerySortDirection.descending,
                },
            ],
        })
            .pipe(map(function (ds) {
            _this.totalRecommendationsCount = ds.count;
            return ds.items
                .sort(function (a, b) {
                if (a.exposureScoreImprovement === b.exposureScoreImprovement) {
                    return (b.secureScoreImprovement || 0) - (a.secureScoreImprovement || 0);
                }
                return b.exposureScoreImprovement - a.exposureScoreImprovement;
            })
                .slice(0, _this.maxCardsToDisplay)
                .map(function (securityRecommendation) {
                var tags = new Array();
                var remediationTypeTag = { id: 'remediationTag' };
                switch (securityRecommendation.remediationType) {
                    case RemediationType.ConfigurationChange:
                        remediationTypeTag.name = _this.i18nService.get('tvm.securityRecommendation.remediationType.configurationChange');
                        break;
                    case RemediationType.Upgrade:
                        remediationTypeTag.name = _this.i18nService.get('tvm.securityRecommendation.remediationType.softwareUpgrade');
                        break;
                    case RemediationType.Update:
                        remediationTypeTag.name = _this.i18nService.get('tvm.securityRecommendation.remediationType.softwarePatch');
                        break;
                    case RemediationType.Uninstall:
                        remediationTypeTag.name = _this.i18nService.get('tvm.securityRecommendation.remediationType.uninstallSoftware');
                        break;
                }
                tags.push(remediationTypeTag);
                _this.tvmTagsService
                    .getRecommendationTags(securityRecommendation)
                    .forEach(function (t) { return tags.push(t); });
                var insight = '';
                if (securityRecommendation.remediationType ===
                    RemediationType.ConfigurationChange) {
                    var properlyConfiguredInsight = _this.tvmTextsService.getText(TextToken.SecurityRecommendationSCAProperlyConfiguredInsight, securityRecommendation);
                    var supportedBenchmarksInsight = _this.tvmTextsService.getText(TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight, securityRecommendation);
                    insight = "<div style=\"max-width:350px;\"><div style=\"white-space: nowrap\" class=\"wcd-tooltip__title\">Recommendation insights</div><ul><li>" + properlyConfiguredInsight + "</li>";
                    if (supportedBenchmarksInsight) {
                        insight += "<li>" + supportedBenchmarksInsight + "</li>";
                    }
                    insight += "</ul></div>";
                }
                return {
                    recommendationId: securityRecommendation.id,
                    isNew: false,
                    title: _this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, securityRecommendation),
                    exposedMachinesCount: securityRecommendation.assetsStatistics.assetsAtRiskCount,
                    scoreData: {
                        exposureScore: securityRecommendation.exposureScoreImprovement,
                        secureScore: securityRecommendation.secureScoreImprovement,
                    },
                    tags: tags,
                    threatInfo: securityRecommendation.threatInfo,
                    threats: securityRecommendation.threats,
                    insight: insight,
                };
            });
        }))
            .subscribe(function (cardsData) {
            _this._cardsData = cardsData;
            _this.refreshCardsView();
        });
    };
    Object.defineProperty(TvmSecurityRecommendationsComponent.prototype, "noRecommendationsMessage", {
        get: function () {
            var _this = this;
            return this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1)).pipe(map(function (mg) {
                return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                    noDataKey: 'tvm.dashboard.noData.topSecurityRecommendations',
                    isGroupSelected: mg.isFiltering,
                });
            }));
        },
        enumerable: true,
        configurable: true
    });
    TvmSecurityRecommendationsComponent.prototype.onVisibilityChange = function (visibilityChange) {
        this.cardsVisibilityMap.set(visibilityChange.elementRef, visibilityChange.visible);
        this.updateVisibaleCards();
    };
    TvmSecurityRecommendationsComponent.prototype.updateVisibaleCards = function () {
        this.visibleCards = Array.from(this.cardsVisibilityMap).reduce(function (acc, curr) { return acc + (curr[1] ? 1 : 0); }, 0);
    };
    TvmSecurityRecommendationsComponent.prototype.checkDashboardDisplayMode = function () {
        if ((!this.verticalMode && window.innerWidth <= VERTICAL_MODE_MAX_PIXELS) ||
            (this.verticalMode && window.innerWidth > VERTICAL_MODE_MAX_PIXELS)) {
            this.verticalMode = !this.verticalMode;
        }
    };
    TvmSecurityRecommendationsComponent.prototype.openRecommendationsPage = function () {
        this.router.navigate(['/security-recommendations'], {
            queryParams: {
                filters: 'status=Active',
            },
        });
    };
    TvmSecurityRecommendationsComponent.prototype.openFilteredRecommendationsPage = function () {
        this.router.navigate(['/security-recommendations'], {
            queryParams: {
                filters: 'status=Exception',
            },
        });
    };
    TvmSecurityRecommendationsComponent.prototype.ngOnDestroy = function () {
        this._resizeSubscription && this._resizeSubscription.unsubscribe();
    };
    return TvmSecurityRecommendationsComponent;
}());
export { TvmSecurityRecommendationsComponent };
