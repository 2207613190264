import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, ParseDataQuery } from '../../analyticsEndPointUtils';
import { ThreatInfo } from '../../common/threat-info.value-object';
import { WeaknessSeverity } from '../weakness-severity.enum';
import { VulnerabilityInstalledAffectedProduct } from './vulnerability-installed-affected-product.value-object';
import { VulnerabilitySourceInfo } from './vulnerability-source-info.value-object';
import { VulnerabilityWorkaround } from '../../common/vulnerability/vulnerability-workaround.value-object';
import { VulnerabilityType } from '../../common/vulnerability/vulnerability-type.enum';
import { ThreatDescriptor } from '@wcd/domain';
import { VulnerabilityAffectedProduct } from './vulnerability-affected-product.entity';

export class WeaknessesSummaryModel {
	totalVulnerabilityCount: number;
	exploitableVulnerabilitiesInOrgCount: number;
	criticalVulnerabilitiesInOrgCount: number;
	vulnerabilityInOrgCount: number;
	zeroDayVulnerabilitiesInOrgCount: number;
}

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Vulnerability',
	pluralName: 'Vulnerabilities',
	endpoint: 'vulnerabilities',
	parseItemQuery: id => {
		return `vulnerabilities/${id}`;
	},
	parseDataQuery: dataQuery => ParseDataQuery(dataQuery, 'vulnerability'),
	readonly: true,
})
export class Vulnerability extends EntityModelBase<string> {
	@EntityField({
		required: true,
	})
	readonly name: string;

	@EntityField({
		required: true,
	})
	readonly description: string;

	@EntityField() readonly severity: WeaknessSeverity;

	@EntityField({
		data: 'numOfImpactedAssets',
	})
	readonly impactedAssetsCount: number;

	@EntityField({
		data: 'publishedOn',
	})
	readonly published: Date;

	@EntityField({
		data: 'updatedOn',
	})
	readonly updated: Date;

	@EntityField({
		data: 'cvssV3',
		parse: cvssV3 => cvssV3.toFixed(1),
	})
	readonly cvss: number;

	@EntityField() readonly cvssVersion: string;

	@EntityField({
		required: true,
	})
	readonly threatInfo: ThreatInfo;

	// This is actually a set of vendor + product
	// We keep it for performance reasons, as the affectedProducts array can be very large
	@EntityField({
		data: 'productIds',
	})
	readonly productIds: Array<string>;

	@EntityField({
		data: 'cveSources',
		arrayOf: VulnerabilitySourceInfo,
	})
	readonly cveSources: Array<VulnerabilitySourceInfo>;

	@EntityField({
		data: 'installedAffectedProducts',
		arrayOf: VulnerabilityInstalledAffectedProduct,
	})
	readonly installedAffectedProducts: Array<VulnerabilityInstalledAffectedProduct>;

	@EntityField({
		data: 'osFeatures',
	})
	readonly osFeatures?: Array<string>;

	@EntityField({
		data: 'workarounds',
	})
	readonly workarounds?: Array<VulnerabilityWorkaround>;

	@EntityField({
		data: 'mostSevereVulnerabilityType',
	})
	readonly mostSevereVulnerabilityType?: VulnerabilityType;

	@EntityField({
		data: 'formalTemporaryId',
	})
	readonly formalTemporaryId?: string;

	@EntityField({
		data: 'patchReleaseDate',
	})
	readonly patchReleaseDate?: Date;

	@EntityField({
		required: false,
	})
	readonly threats: ThreatDescriptor[];
}
