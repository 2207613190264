import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationType } from '../remediation/remediation-task-type.enum';
import { Mitigation } from '../../common/remediation/remediation-mitigation.value-object';

@ValueObject({
	singularName: 'Software arguments',
	pluralName: 'Software arguments',
})
export class SoftwareArgs extends ModelBase {
	@EntityField({
		data: 'type',
	})
	readonly taskType: RemediationType;

	@EntityField({
		required: true,
		data: 'vendorId',
	})
	readonly vendorId: string;

	@EntityField({
		required: true,
		data: 'nameId',
	})
	readonly nameId: string;

	@EntityField({
		data: 'productId',
	})
	readonly productId: string;

	@EntityField({
		require: data => data.type === RemediationType.Update,
		data: 'recommendedVersion',
	})
	readonly recommendedVersion?: string;

	@EntityField() readonly recommendedVendor?: string;

	@EntityField() readonly recommendedProgram?: string;

	@EntityField({
		data: 'isEOL',
	})
	readonly isEOL?: boolean;

	@EntityField({
		data: 'isUpcomingEol',
	})
	readonly isUpcomingEol?: boolean;

	@EntityField()
	readonly hasEolVersions: boolean;

	@EntityField()
	readonly hasUpcomingEolVersions: boolean;

	@EntityField({
		data: 'mitigation',
	})
	readonly mitigation?: Mitigation;
}
