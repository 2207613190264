var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { RecommendationExceptionService } from './../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import { EventEmitter, Injector, OnInit, OnDestroy, } from '@angular/core';
import { filter } from 'rxjs/operators';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { CalculateRemainingDaysPipe } from '../../../shared/pipes/calculate-remaining-days.pipe';
import { RecommendationException } from '@wcd/domain';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
var RecommendationExceptionEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(RecommendationExceptionEntityDetailsComponent, _super);
    function RecommendationExceptionEntityDetailsComponent(activatedEntity, calculateRemainingDaysPipe, router, injector, tvmTextsService, recommendationExceptionService) {
        var _this = _super.call(this, injector) || this;
        _this.activatedEntity = activatedEntity;
        _this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        _this.router = router;
        _this.tvmTextsService = tvmTextsService;
        _this.recommendationExceptionService = recommendationExceptionService;
        _this.loggedOnUsersClick = new EventEmitter();
        return _this;
    }
    RecommendationExceptionEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.recommendationException) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof RecommendationException; }))
                .subscribe(function (recommendationException) {
                _this.recommendationException = recommendationException;
                _this.initPropertiesFromRemediationTask();
            });
        }
        else {
            this.initPropertiesFromRemediationTask();
        }
    };
    RecommendationExceptionEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    RecommendationExceptionEntityDetailsComponent.prototype.navigateToRecommendations = function () {
        this.router.navigate(['/security-recommendations', this._relatedRecommendationId], {
            queryParams: {
                search: "" + this.recommendationExceptionService.getRelatedRecommendationId(this.recommendationException, false),
            },
        });
    };
    RecommendationExceptionEntityDetailsComponent.prototype.getRemediationType = function () {
        return this.tvmTextsService.getText(TextToken.ExceptionRemediationType, this.recommendationException);
    };
    RecommendationExceptionEntityDetailsComponent.prototype.getJustificationLabel = function (justification) {
        return this.recommendationExceptionService.getJustificationLabel(justification);
    };
    RecommendationExceptionEntityDetailsComponent.prototype.navigateToRelatedComponent = function () {
        this.router.navigate([
            '/software-inventory',
            this.recommendationException.exceptionArgs.vaRecommendationArgs.productId,
        ]);
    };
    RecommendationExceptionEntityDetailsComponent.prototype.remainingDays = function (recommendationException) {
        var remainingDays = this.calculateRemainingDaysPipe.transform(recommendationException.expiringOn);
        if (recommendationException.status.value !== 'Active') {
            return '-';
        }
        else {
            return remainingDays + " days";
        }
    };
    RecommendationExceptionEntityDetailsComponent.prototype.initPropertiesFromRemediationTask = function () {
        this._relatedRecommendationId = this.recommendationExceptionService.getRelatedRecommendationId(this.recommendationException);
        this.remediationType = this.getRemediationType();
        this.relatedComponent = this.tvmTextsService.getText(TextToken.RecommendationExceptionRelatedComponent, this.recommendationException);
    };
    return RecommendationExceptionEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { RecommendationExceptionEntityDetailsComponent };
