import { ChangeDetectorRef, ElementRef } from '@angular/core';
import { ActionBaseEntity, RemediationActionTypeTypes } from '@wcd/domain';
var ActionHistoryDetailsFieldComponent = /** @class */ (function () {
    function ActionHistoryDetailsFieldComponent(elementRef, changeDetectorRef) {
        var _a;
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.RemediationActionTypeTypes = RemediationActionTypeTypes;
        this.getDisplayByActionType = (_a = {},
            _a[RemediationActionTypeTypes.file_quarantine] = function (entity) { return entity.folderPath; },
            _a[RemediationActionTypeTypes.service_quarantine] = function (entity) { return entity.serviceName; },
            _a[RemediationActionTypeTypes.driver_quarantine] = function (entity) { return entity.driverName; },
            _a[RemediationActionTypeTypes.persistence_method_quarantine] = function (entity) { return entity.methodCommandLine; },
            _a[RemediationActionTypeTypes.undo_clean_resource] = function (entity) {
                return entity.methodCommandLine || entity.driverName || entity.serviceName || entity.folderPath;
            },
            _a[RemediationActionTypeTypes.mail_forwarding_rule_remediation] = function (entity) { return entity.mailboxPrimaryAddress; },
            _a[RemediationActionTypeTypes.force_password_reset_remediation] = function (entity) {
                return entity.mailboxPrimaryAddress || entity.userDisplayName;
            },
            _a[RemediationActionTypeTypes.mail_delegation_remediation] = function (entity) { return entity.mailboxPrimaryAddress; },
            _a[RemediationActionTypeTypes.user_inbox_rules_remediation] = function (entity) { return entity.mailboxPrimaryAddress; },
            _a[RemediationActionTypeTypes.block_url] = function (entity) { return entity.url; },
            _a[RemediationActionTypeTypes.confirm_user_compromised] = function (entity) { return entity.userDisplayName; },
            _a[RemediationActionTypeTypes.suspend_user] = function (entity) { return entity.userDisplayName; },
            _a[RemediationActionTypeTypes.invalidate_all_refresh_tokens_for_a_user] = function (entity) {
                return entity.userDisplayName;
            },
            _a[RemediationActionTypeTypes.disable_user] = function (entity) { return entity.userDisplayName; },
            _a[RemediationActionTypeTypes.enable_user] = function (entity) { return entity.userDisplayName; },
            _a);
    }
    Object.defineProperty(ActionHistoryDetailsFieldComponent.prototype, "action", {
        get: function () {
            return this._action;
        },
        set: function (value) {
            this._action = value;
            this.changeDetectorRef.detectChanges();
            this.tooltip = this.elementRef.nativeElement.innerHTML;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    return ActionHistoryDetailsFieldComponent;
}());
export { ActionHistoryDetailsFieldComponent };
