import { SevilleAlertsModule } from '../alerts/seville.alerts.module';

SevilleAlertsModule.factory('alertFilterService', alertFilterService);

alertFilterService.$inject = ['$log', '$rootScope'];

function alertFilterService($log, $rootScope) {
	var alertModes = ['none', 'filter', 'highlight'];
	var activeAlerts = [];
	var activeAlertIds = [];
	var activeAlertTime = '';

	function registerToStateChangeEventAndClearAlerts() {
		$rootScope.$on('$stateChangeStart', function(
			event,
			toState,
			toParams,
			fromState,
			fromParams,
			options
		) {
			clearActiveFilters();
		});
	}

	function addRemoveAlerts(records, onlyAdd) {
		for (var i = 0; i < records.length; i++) {
			var alert = records[i];
			if (activeAlertIds.indexOf(alert.AlertId) >= 0) {
				if (onlyAdd) {
					continue;
				}
				activeAlerts = activeAlerts.filter(function(a) {
					return a.AlertId !== alert.AlertId;
				});
				var index = activeAlertIds.indexOf(alert.AlertId);
				activeAlertIds.splice(index, 1);
			} else {
				activeAlerts.push(alert);
				activeAlertIds.push(alert.AlertId);
			}
		}

		activeAlertTime = '';
		if (activeAlertIds && activeAlertIds.length > 0) {
			updateLastAlertTime();
		}
	}

	function clearActiveFilters() {
		activeAlerts = [];
		activeAlertIds = [];
		activeAlertTime = '';
	}

	function isExactSelection(selectedAlerts) {
		if (!activeAlertIds) return false;
		if (activeAlertIds.length != selectedAlerts.length) return false;

		for (var i = 0; i < selectedAlerts.length; i++) {
			if (activeAlertIds.indexOf(selectedAlerts[i].AlertId) < 0) return false;
		}

		return true;
	}

	function updateLastAlertTime() {
		if (activeAlertIds && activeAlertIds.length > 0) {
			for (var i = 0; i < activeAlerts.length; i++) {
				var alert = activeAlerts[i];
				if (!activeAlertTime) {
					activeAlertTime = alert.LastEventTime || alert.LastSeen;
				} else {
					var currAlertTime = alert.LastEventTime || alert.LastSeen;
					if (currAlertTime > activeAlertTime) {
						activeAlertTime = currAlertTime;
					}
				}
			}
		}
	}

	var service = {
		getActiveAlertIds: function() {
			return activeAlertIds;
		},
		getLastAlertTime: function() {
			return activeAlertTime;
		},
		selectAlerts: function(alerts, setToThese, skipBroadcast, onlyAdd) {
			var selectAll = isExactSelection(alerts);

			if (setToThese) {
				clearActiveFilters();
			}

			if (!setToThese || !selectAll) {
				addRemoveAlerts(alerts, onlyAdd);
			}

			if (!skipBroadcast) {
				$rootScope.$broadcast('alertFilter:changeDate', {
					activeAlertTime: activeAlertTime,
				});
			}
		},
		toggleAlertMode: function(alertId, alertTime, skipBroadcast) {
			if (!alertId) {
				console.log('no alert id passed to toggle alert');
				return;
			}

			if (!alertTime) {
				console.log('no time passed to toggle alert');
				return;
			}

			this.selectAlerts([{ AlertId: alertId, LastEventTime: alertTime }], false, skipBroadcast);
		},
		clearFilters: function() {
			clearActiveFilters();
			$log.debug('Alert filters cleared');
		},
		applyHighlight: function(event) {
			if (!activeAlertIds || !event.associatedAlertIds) return false;
			for (var i = 0; i < activeAlertIds.length; i++) {
				var activeAlertId = activeAlertIds[i];
				if (event.associatedAlertIds.indexOf(activeAlertId) >= 0) return true;
			}
			return false;
		},
		ishighlightActive: function(alertId) {
			if (!activeAlertIds || !alertId) return false;

			if (!Array.isArray(alertId)) return activeAlertIds.indexOf(alertId) >= 0;

			// both keys are arrays - return true if alertId is contained in activeAlertIds
			for (var i = 0; i < alertId.length; i++) {
				var keyPart = alertId[i];
				if (activeAlertIds.indexOf(keyPart) < 0) return false;
			}

			return true;
		},
	};

	registerToStateChangeEventAndClearAlerts();
	return service;
}
