import { HuntingCustomActionResult } from './hunting-custom-action-result.value-object';
import { ApiCallModel, EntityField, ApiCall, ValueObject, ModelBase } from '@microsoft/paris';
import {
	HuntingCustomAction,
	StartNewEmailReportSubmission,
	TriggerInvestigationSubmission,
} from './hunting-custom-action.value-object';
import { isArray } from 'lodash-es';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ValueObject({
	singularName: 'Hunting custom action request',
	pluralName: 'Hunting custom action requests',
})
export class HuntingCustomActionRequest extends HuntingCustomAction {
	@EntityField({ data: 'ResultSet' })
	records: Array<{ [index: string]: any }>;
}

@ValueObject({
	singularName: 'Hunting custom actions request',
	pluralName: 'Hunting custom actions request',
})
export class HuntingCustomActionsRequest extends ModelBase {
	@EntityField({ data: 'Actions', arrayOf: HuntingCustomActionRequest })
	actions: Array<HuntingCustomActionRequest>;

	@EntityField({ data: 'Title' })
	remediationName: string;

	@EntityField({ data: 'Comment' })
	remediationComment: string;
}

@ValueObject({
	singularName: 'Hunting custom actions response',
	pluralName: 'Hunting custom actions response',
})
export class HuntingCustomActionsResponse extends ModelBase {
	@EntityField({ data: 'Results', arrayOf: HuntingCustomActionResult })
	results: Array<HuntingCustomActionResult>;

	@EntityField({ data: 'BulkId' })
	bulkId?: string;
}

@ApiCall({
	name: 'Execute bulk actions on hunting records',
	endpoint: 'hunting/customActions',
	baseUrl: (config) => config.data.serviceUrls.threatIntel,
	method: 'POST',
	parseData: (rawData) => (isArray(rawData) ? { Results: rawData } : rawData),
	type: HuntingCustomActionsResponse,
})
export class RunHuntingBulkActionApiCall extends ApiCallModel<
	HuntingCustomActionsResponse,
	HuntingCustomActionsRequest
> {}

@ApiCall({
	name: 'Execute bulk actions on hunting records',
	endpoint: 'customactions',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
	parseData: (rawData) => (isArray(rawData) ? { Results: rawData } : rawData),
	type: HuntingCustomActionsResponse,
})
export class RunHuntingBulkActionApiCallVNext extends ApiCallModel<
	HuntingCustomActionsResponse,
	HuntingCustomActionsRequest
> {}

@ValueObject({
	singularName: 'start new email report submission response',
	pluralName: 'start new email report submission responses',
})
export class StartNewEmailReportSubmissionResponse extends ModelBase {
	@EntityField({ data: 'Results' })
	results: any;

	@EntityField({ data: 'BulkId' })
	bulkId?: string;
}

@ValueObject({
	singularName: 'start trigger investigation response',
	pluralName: 'start trigger investigation responses',
})
export class TriggerInvestigationResponse extends ModelBase {
	@EntityField({ data: 'Results' })
	results: any;

	@EntityField({ data: 'TenantId' })
	TenantId?: number;
}

@ApiCall({
	name: 'Execute New Email Report Submission',
	endpoint: 'SubmitThreat',
	baseUrl: (config: WcdPortalParisConfig) => '/api/ReportSubmission',
	method: 'PUT',
	parseData: (rawData) => {
		return { Results: rawData };
	},
	type: StartNewEmailReportSubmissionResponse,
})
export class StartNewEmailReportSubmissionApi extends ApiCallModel<
	StartNewEmailReportSubmissionResponse,
	StartNewEmailReportSubmission[]
> {}

@ApiCall({
	name: 'Execute Trigger Investigation For Email',
	endpoint: 'SaveEmail',
	baseUrl: (config: WcdPortalParisConfig) => '/api/AirModels',
	method: 'POST',
	parseData: (rawData) => {
		return { Results: rawData };
	},
	type: TriggerInvestigationResponse,
})
export class TriggerInvestigationEmailApi extends ApiCallModel<
	TriggerInvestigationResponse,
	TriggerInvestigationSubmission[]
> {}

@ApiCall({
	name: 'Execute Trigger Investigation For Recipient',
	endpoint: 'SaveUserInvestigation?target=re',
	baseUrl: (config: WcdPortalParisConfig) => '/api/AirModels',
	method: 'POST',
	parseData: (rawData) => {
		return { Results: rawData };
	},
	type: TriggerInvestigationResponse,
})
export class TriggerInvestigationRecipientApi extends ApiCallModel<
	TriggerInvestigationResponse,
	TriggerInvestigationSubmission[]
> {}
