
		<wcd-collapsible-section
			[sectionId]="collapsibleID.Details"
			[label]="i18nService.strings.investigation_panel_details_title"
		>
			<investigation-entity-details [investigation]="investigation"></investigation-entity-details>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			[sectionId]="collapsibleID.TriggeringAlertDetails"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_details"
			*ngIf="investigation.alert"
		>
			<div *ngIf="alert">
				<alert-entity-details [entity]="alert" [showTitle]="false"></alert-entity-details>
				<route-link
					*ngIf="alertEntityTypeService.entityType.getNavigationModel(alert) as alertRoute"
					[routeLink]="alertRoute"
				>
					{{ i18nService.strings.investigations_panel_openAlertPage }}
				</route-link>
			</div>
			<div *ngIf="isLoadingAlert">
				<i class="loader-icon"></i> {{ i18nService.strings.investigations_panel_loadingAlert }}
			</div>
			<a
				*ngIf="investigation.totalAlertsCount > 1"
				data-track-id="AlertsRelatedLink"
				data-track-type="Navigation"
				[routerLink]="['/investigation', investigation.id, 'alerts']"
			>
				{{
					'investigations_panel_relatedAlertCount'
						| i18n: { alertCount: investigation.totalAlertsCount }
				}}
			</a>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			*ngIf="investigation.alert && alert && alert.description"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_description"
			[sectionId]="collapsibleID.TriggeringAlertDescription"
		>
			<alerted-description
				[description]="alert.description"
			>
			</alerted-description>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			*ngIf="investigation.alert && alert && alert.recommendedAction"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_recommendedActions"
			[sectionId]="collapsibleID.TriggeringAlertRecommendedAction"
		>
			<alerted-description
				[recommendedAction]="alert.recommendedAction"
			>
			</alerted-description>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			[sectionId]="collapsibleID.relatedMachine"
			[label]="
				investigation.machines.length > 1
					? ('investigation_panel_relatedMachine_title_plural'
					  | i18n: { deviceCount: investigation.machines.length })
					: i18nService.strings.investigation_panel_relatedMachine_title_singular
			"
			*ngIf="
				(investigation.machines.length === 1 && machine) ||
				isLoadingMachine ||
				investigation.machines.length > 1
			"
			(isExpandedChange)="$event && updateAlertsTable()"
		>
			<ng-container>
				<ng-container *ngIf="investigation.machines.length === 1; else multipleMachines">
					<ng-container *ngIf="machine || isLoadingMachine">
						<span *ngIf="isLoadingMachine; else machineDetails">
							<i class="loader-icon"></i>
							{{ i18nService.strings.investigation_panel_loadingMachineDetails_title }}
						</span>
						<ng-template #machineDetails>
							<machine-entity-details
								#machineEntityCmp
								*ngIf="machine"
								[machine]="machine"
								[alert]="alert"
								(loggedOnUsersClick)="showLoggedOnUsers()"
								[includeMachineName]="true"
								[collapsibleSections]="false"
							></machine-entity-details>
						</ng-template>
					</ng-container>
				</ng-container>
			</ng-container>
		</wcd-collapsible-section>

		<ng-template #multipleMachines>
			<ng-container *ngIf="investigation.machines.length">
				<investigated-machines-table
					[investigatedMachines]="investigation.machines"
				></investigated-machines-table>
			</ng-container>
		</ng-template>
	