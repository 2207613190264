import { Component, ViewChild } from '@angular/core';
import { format } from 'd3-format';
import { find, merge } from 'lodash-es';
import { map } from 'rxjs/operators';
import { ChartSettings, StackedBarsChartComponent } from '@wcd/charts';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService, TimeSeriesDataItem } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';

import * as d3 from 'd3';

const WIDGET_RANGE = 30;

@Component({
	selector: 'daily-machine-reporting-widget',
	template: `
		<wcd-stacked-bars-chart
			#machineChart
			[data]="widgetData$ | async"
			[hidden]="error$ | async"
			[change]="localeConfigService.config$ | async"
			[settings]="settings"
		></wcd-stacked-bars-chart>
	`,
})
export class DailyMachineReportingWidget extends ReportWidgetComponent<
	DailyMachineReportingParsedData,
	[DailyMachineReportingBackendData, MachineCountBackendData]
> {
	widgetData$ = this.data$.pipe(map(data => data && data.widgetData));

	get widgetConfig(): ReportWidgetConfig<
		DailyMachineReportingParsedData,
		[DailyMachineReportingBackendData, MachineCountBackendData]
	> {
		return {
			id: 'dailyMachineReporting',
			name: this.i18nService.get('widget_title_dailyDevices'),
			rangeInDays: WIDGET_RANGE,
			api: [
				{
					url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/ReportingMachinesCounts`,
					isExternal: true,
					params: { readFromCache: String(true) },
				},
				{
					url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/TotalMachinesCount`,
					isExternal: true,
					params: { lookingBackInDays: WIDGET_RANGE, readFromCache: String(true) },
				},
			],
			getName: (data: DailyMachineReportingParsedData): string => {
				const count: number = (data && data.titleData && data.titleData.machinesCount) || 0;
				return (
					this.i18nService.get('widget_title_dailyDevices') +
					(count
						? ` ${this.i18nService.get('widget_title_monthlyUniqueDevices', {
								count: data.titleData.machinesCount,
						  })}`
						: '')
				);
			},
			parseData: this.parseData.bind(this),
		};
	}

	settings: ChartSettings = {
		stackGroups: false,
		useValues: false,
		setColors: true,
		series: [{ value: 'Count', name: 'Machines', color: config.color.secondary }],
		xProperty: 'label',
		options: {
			padding: {
				right: 0,
				top: 10,
			},
			data: {},
			bar: {
				width: {
					max: 35,
					ratio: 0.7,
				},
			},
			axis: {
				x: {
					tick: {
						format: (date: Date) => this.tzDateService.format(date, 'MMM d'),
					},
					type: 'timeseries',
					height: 35,
				},
				y: {
					tick: {
						format: value => this.prettyNumberService.prettyNumber(value),
						count: 2,
					},
					min: 0,
					padding: {
						bottom: 0,
					},
				},
			},
			onrendered: () => {
				const self = this;
				const machineChart = this.machineChart.getEl();

				d3.select(machineChart)
					.attr('role', 'document')
					.attr('aria-label', self.i18nService.get('widget_title_dailyDevices'));
				d3.selectAll(machineChart.querySelectorAll(`path.c3-bar`))
					.attr('tabindex', '0')
					.attr('focusable', 'true')
					.attr('role', 'img')
					.each((dataPoint: any, index: number, nodesList: SVGElement[]) => {
						d3.select(nodesList[index]).attr(
							'aria-label',
							`${self.tzDateService.format(dataPoint.x, 'MMM d')}: ${dataPoint.value}`
						);
					});
			},
			grid: {
				y: {
					show: true,
				},
			},
			tooltip: {
				format: {
					value: (n: string) => format(',')(parseInt(n, 10)),
					title: (date: Date) => this.tzDateService.format(date, 'shortDate'),
				},
			},
			legend: {
				show: false,
			},
			size: {
				height: 150,
			},
		},
	};

	@ViewChild('machineChart', { static: false }) public machineChart: StackedBarsChartComponent;
	constructor(
		reportsService: ReportsService,
		private serviceUrlsService: ServiceUrlsService,
		private prettyNumberService: PrettyNumberService,
		private tzDateService: TzDateService,
		public localeConfigService: LocaleConfigService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	parseData(
		data: [DailyMachineReportingBackendData, MachineCountBackendData]
	): DailyMachineReportingParsedData {
		const titleData: MachineCountBackendData = <MachineCountBackendData>(
			find(data, d => d['machinesCount'])
		);
		const widgetData: DailyMachineReportingBackendData = <DailyMachineReportingBackendData>(
			find(data, d => d['machinesCounts'])
		);
		const now: Date = new Date();
		const rangeStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
		const toDate: Date = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);

		rangeStartDate.setDate(
			now.getDate() -
				((widgetData && widgetData.machinesCounts && widgetData.machinesCounts.length) ||
					WIDGET_RANGE)
		);

		return {
			titleData: titleData,
			widgetData: ReportsService.padTimeSeriesDates(
				ReportsService.parseTimeSeriesData(
					widgetData && widgetData.machinesCounts
						? widgetData.machinesCounts.map((item: MachineReportingBackendData) => {
								return merge({ from: item.DateTimeUtc.split('T')[0] }, item);
						  })
						: []
				),
				rangeStartDate,
				toDate,
				'Count'
			),
		};
	}
}

interface MachineReportingBackendData {
	DateTimeUtc: string;
	Count: number;
}

interface DailyMachineReportingBackendData {
	machinesCounts: Array<MachineReportingBackendData>;
}

interface MachineCountBackendData {
	machinesCount: number;
}

interface DailyMachineReportingParsedData {
	titleData: MachineCountBackendData;
	widgetData: Array<TimeSeriesDataItem>;
}
