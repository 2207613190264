
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div class="large-form wcd-full-height">
				<form
					*ngIf="editedMachineGroup"
					#machineGroupForm="ngForm"
					(submit)="saveMachineGroup()"
					data-track-component="MachineGroupRemediationLevelEdit"
					class="wcd-full-height wcd-flex-vertical"
				>
					<dl *ngIf="editedMachineGroup.description" class="wcd-padding-all" role="none">
						<dt class="info-label semi-bold">
							{{ 'machineGroup.edit.descriptionTitle' | i18n }}
						</dt>
						<dd>
							{{ editedMachineGroup.description }}
						</dd>
					</dl>
					<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
						<section>
							<div class="form-group">
								<label for="protection-level" class="wcd-font-weight-bold">{{
									'machineGroup.edit.automationLevelTitle' | i18n
								}}</label>
								<div class="wcd-margin-top">
									<wcd-radiolist
										[values]="allProtectionLevels"
										[(ngModel)]="selectedProtectionLevel"
										[trackBy]="'id'"
										[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
										name="protection-level"
										required
										#automationLevelRadioList
										[ariaLabel]="'machineGroup.edit.automationLevelTitle' | i18n"
									>
									</wcd-radiolist>
								</div>
							</div>
						</section>
					</div>
					<span *ngIf="error" class="wcd-padding-all color-text-red">
						{{ error }}
					</span>
					<div class="wcd-flex-none wcd-flex-horizontal wcd-padding-all">
						<div class="wcd-flex-1">
						<fab-primary-button
								data-track-id="Save"
								data-track-type="Button"
								[disabled]="
									machineGroupForm.form.pristine || !machineGroupForm.form.valid || isSaving
								"
								type="submit"
							>
								<span role="alert">
									<i class="loader-icon" [hidden]="!isSaving" [attr.aria-label]="'buttons.saving' | i18n"></i>
								</span>
								{{ (isSaving ? 'buttons.saving' : 'buttons.save') | i18n }}
							</fab-primary-button>
						</div>
						<div class="wcd-flex-none">
							<fab-default-button
								className="btn-secondary"
								data-track-id="Cancel"
								data-track-type="Button"
								(onClick)="closePanel()"
							>
								{{ 'buttons.cancel' | i18n }}
							</fab-default-button>
						</div>
					</div>
				</form>
			</div>
		</wcd-panel>
	