var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField, EntityModelBase, } from '@microsoft/paris';
import { SuppressionRuleAction } from './suppression-rule-action.entity';
import { SuppressionRuleCondition } from './suppression-rule-condition.value-object';
import { SuppressionRuleScope } from './suppression-rule-scope.entity';
import { Machine } from '../machine/machine.entity';
import { omit } from 'lodash-es';
import { SuppressionRuleType } from './suppression-rule-type.enum';
import { SuppressionRuleComplexConditionType } from './suppression-rule-complex-condition-type.enum';
var ɵ0 = function (dataQuery) {
    if (!dataQuery)
        return {};
    return Object.assign({}, omit(dataQuery.where, ['page', 'page_size', 'ordering']), {
        pageIndex: dataQuery.page || 1,
        pageSize: dataQuery.pageSize || 200,
    }, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
}, ɵ1 = function (itemId, entity, config) {
    return entity.endpoint + "/" + itemId;
}, ɵ2 = function (config) { return config.data.serviceUrls.suppressionRulesService; }, ɵ3 = function (item, modelData, entity, config, serializationData) {
    var conditions = !!item.complexConditions ? item.complexConditions : item.conditions.map(function (condition) {
        return {
            Type: condition.type.dataFieldName,
            Value: condition.value,
        };
    });
    var serializedItem = omit(modelData, [
        'DeserializedConditions',
        'DeserializedComplexConditions',
        'DeserializedRbacGroupIds',
        'ScopeConditions',
        'CreationTime',
        'MatchingAlertsCount',
        'UpdateTime',
        '__self',
    ]);
    Object.assign(serializedItem, {
        ruleConditions: JSON.stringify(conditions),
        ScopeConditions: !!item.scopeConditions ? JSON.stringify(item.scopeConditions) : null,
        Scope: item.scope.id,
    });
    if (item.machine) {
        Object.assign(serializedItem, {
            senseMachineId: item.machine.id,
            internalMachineId: item.machine.internalMachineId,
            computerDnsName: item.machine.name,
        });
    }
    return {
        rule: serializedItem,
        comment: (serializationData && serializationData.comment) || '',
        originatingAlertId: (serializationData && serializationData.originatingAlertId) || null,
    };
}, ɵ4 = function (item, entity) {
    return item.id ? entity.endpoint + "/" + item.id : 'CreateRule';
}, ɵ5 = function (item, entity) {
    return entity.endpoint + "/" + item[0].id;
}, ɵ6 = function (item) {
    return item.id ? 'PUT' : 'POST';
}, ɵ7 = ['RuleTitle', 'Title'], ɵ8 = function (data) { return omit(data, ['Id']); }, ɵ9 = function (data) { return data.MachineId || data.SenseMachineId || data.ComputerDnsName; }, ɵ10 = function (_, rawData) { return rawData.RuleType !== SuppressionRuleType.MDE && rawData.RuleConditions && JSON.parse(rawData.RuleConditions); }, ɵ11 = function (_, rawData) { return rawData.RuleType === SuppressionRuleType.MDE && rawData.RuleConditions && JSON.parse(rawData.RuleConditions); }, ɵ12 = function (scopeConditions) { return scopeConditions && JSON.parse(scopeConditions); }, ɵ13 = function (_, rawData) {
    try {
        var additionalDetailsParsed = rawData.AdditionalDetails && JSON.parse(rawData.AdditionalDetails);
        return additionalDetailsParsed ? additionalDetailsParsed.originatingAlertId : null;
    }
    catch (_a) {
        console.warn('Failed to parse AdditionalDetails json of SuppressionRule.');
        return null;
    }
};
var SuppressionRule = /** @class */ (function (_super) {
    __extends(SuppressionRule, _super);
    function SuppressionRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SuppressionRule.prototype, "iocConditionsNotEmpty", {
        get: function () {
            if (this.ruleType === SuppressionRuleType.MDE) {
                return this.complexConditions && this.complexConditions.conditionType === SuppressionRuleComplexConditionType.Complex ?
                    this.complexConditions.conditions.length > 0 : !!this.complexConditions;
            }
            else {
                return this.conditions && this.conditions.length > 0;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SuppressionRule.prototype, "scopeConditionsNotEmpty", {
        get: function () {
            return this.ruleType === SuppressionRuleType.MDE &&
                this.scopeConditions &&
                this.scopeConditions.some(function (conditionGroup) {
                    return conditionGroup.length > 0 &&
                        conditionGroup.some(function (condition) { return condition.values && condition.values.length > 0; });
                });
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], SuppressionRule.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Scope' }),
        __metadata("design:type", SuppressionRuleScope)
    ], SuppressionRule.prototype, "scope", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ8,
            require: ɵ9,
        }),
        __metadata("design:type", Machine)
    ], SuppressionRule.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'Action' }),
        __metadata("design:type", SuppressionRuleAction)
    ], SuppressionRule.prototype, "action", void 0);
    __decorate([
        EntityField({ data: 'MatchingAlertsCount' }),
        __metadata("design:type", Number)
    ], SuppressionRule.prototype, "alertsCount", void 0);
    __decorate([
        EntityField({ data: 'AlertTitle' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "alertTitle", void 0);
    __decorate([
        EntityField({ data: 'IoaDefinitionId' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "ioaDefinitionId", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'LastActivity' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "lastActivity", void 0);
    __decorate([
        EntityField({ data: 'ThreatFamilyName' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "threatFamilyName", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], SuppressionRule.prototype, "createdOn", void 0);
    __decorate([
        EntityField({ data: 'UpdateTime' }),
        __metadata("design:type", Date)
    ], SuppressionRule.prototype, "lastUpdatedOn", void 0);
    __decorate([
        EntityField({ data: 'IsEnabled' }),
        __metadata("design:type", Boolean)
    ], SuppressionRule.prototype, "isEnabled", void 0);
    __decorate([
        EntityField({ data: 'IsReadOnly' }),
        __metadata("design:type", Boolean)
    ], SuppressionRule.prototype, "isReadOnly", void 0);
    __decorate([
        EntityField({ data: 'DeserializedConditions', arrayOf: SuppressionRuleCondition, parse: ɵ10 }),
        __metadata("design:type", Array)
    ], SuppressionRule.prototype, "conditions", void 0);
    __decorate([
        EntityField({ data: 'DeserializedComplexConditions', parse: ɵ11 }),
        __metadata("design:type", Object)
    ], SuppressionRule.prototype, "complexConditions", void 0);
    __decorate([
        EntityField({ data: 'ScopeConditions', parse: ɵ12 }),
        __metadata("design:type", Array)
    ], SuppressionRule.prototype, "scopeConditions", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupIds' }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "rbacGroupIds", void 0);
    __decorate([
        EntityField({ data: 'RuleType', defaultValue: SuppressionRuleType.Default }),
        __metadata("design:type", Number)
    ], SuppressionRule.prototype, "ruleType", void 0);
    __decorate([
        EntityField({ data: 'DeserializedRbacGroupIds' }),
        __metadata("design:type", Array)
    ], SuppressionRule.prototype, "deserializedRbacGroupIds", void 0);
    __decorate([
        EntityField({
            data: 'OriginatingAlertId', parse: ɵ13
        }),
        __metadata("design:type", String)
    ], SuppressionRule.prototype, "originatingAlertId", void 0);
    SuppressionRule = __decorate([
        Entity({
            singularName: 'Suppression rule',
            pluralName: 'Suppression rules',
            endpoint: 'suppressionRules',
            allItemsEndpointTrailingSlash: false,
            parseDataQuery: ɵ0,
            parseItemQuery: ɵ1,
            baseUrl: ɵ2,
            serializeItem: ɵ3,
            parseSaveQuery: ɵ4,
            parseRemoveQuery: ɵ5,
            saveMethod: ɵ6,
        })
    ], SuppressionRule);
    return SuppressionRule;
}(EntityModelBase));
export { SuppressionRule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13 };
