import { Entity, EntityField } from '@microsoft/paris';
import { MemoryContents } from '../memory-contents.value-object';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsFile } from './airs-entity.file';
import { AirsEntityType } from '../airs-entity-type.entity';

@Entity({
	...airsEntityConfig,
	singularName: 'Process',
	pluralName: 'Processes',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsProcess,
})
export class AirsProcess extends AirsEntity {
	@EntityField({ data: 'memory_contents', arrayOf: MemoryContents })
	memoryContents: Array<MemoryContents>;

	@EntityField({ data: 'process_id' })
	processId: string;

	@EntityField({ data: 'username' })
	username: string;

	@EntityField({ data: 'cwd' })
	cwd: string;

	@EntityField({ data: 'cmd' })
	cmd: string;

	@EntityField({ data: 'command_args' })
	commandArgs: string;

	@EntityField({ data: 'created' })
	created: Date;

	@EntityField({ data: 'parent_name' })
	parentName: string;

	@EntityField({
		data: 'main_module',
		parse: fileData => {
			if (fileData) {
				return Object.assign(fileData, { entity_type: AirsEntityType.File });
			}
		},
	})
	fileData: AirsFile;
}
