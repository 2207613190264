import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { LegacyUser, LegacyUserLegacyUserProfileRelationship, LegacyUserProfile } from '@wcd/domain';
import { WicdSanitizerService } from '@wcd/shared';

@Component({
	selector: 'user-contact-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ul *ngIf="contactDetails" class="unstyled wcd-margin-none-all wcd-padding-none-all">
			<li *ngIf="contactDetails.email as email">
				<fab-icon iconName="Mail"></fab-icon>
				<a
					data-track-id="ContactDetails__Email"
					data-track-type="ExternalLink"
					[href]="'mailto:' + email"
				>
					{{ email }}
				</a>
			</li>

			<li *ngIf="contactDetails.phone as phone">
				<fab-icon iconName="Phone"></fab-icon>
				<a
					data-track-id="ContactDetails__Phone"
					data-track-type="ExternalLink"
					[href]="'tel:' + phone"
				>
					{{ phone }}
				</a>
			</li>

			<li *ngIf="skypeUrl">
				<fab-icon iconName="SkypeForBusinessLogo"></fab-icon>
				<a data-track-id="ContactDetails__Skype" data-track-type="ExternalLink" [href]="skypeUrl">
					{{ skypeUrlText }}
				</a>
			</li>
		</ul>
	`,
})
export class UserContactDetailsComponent implements OnChanges<UserContactDetailsComponent> {
	@Input() user: LegacyUser;
	contactDetails: LegacyUserProfile;

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly sanitizer: WicdSanitizerService
	) {}

	async ngOnChanges(changes: TypedChanges<UserContactDetailsComponent>) {
		this.contactDetails = await this.paris
			.getRelatedItem<LegacyUser, LegacyUserProfile>(LegacyUserLegacyUserProfileRelationship, this.user)
			.pipe(take(1))
			.toPromise();

		this.changeDetectorRef.markForCheck();
	}

	get skypeUrl() {
		// `contactDetails.sip` is already in `sip:12345` format.
		return this.contactDetails && this.sanitizer.bypassSecurityTrustUrl(this.contactDetails.sip);
	}

	get skypeUrlText() {
		return this.contactDetails.sip && this.contactDetails.sip.split('sip:')[1];
	}
}
