/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AirsEntityBehaviorGroup, AirsEntityBehaviorGroupProperty } from '@wcd/domain';

@Component({
	selector: 'entity-behavior',
	templateUrl: './entity-behavior.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityBehaviorComponent implements OnInit {
	@Input() entityBehavior: Array<AirsEntityBehaviorGroup>;

	valuesFilter: string;
	collapsedBehavior: { [index: string]: boolean };
	currentBehaviorGroupProperty: AirsEntityBehaviorGroupProperty;
	currentBehaviorGroup: AirsEntityBehaviorGroup;

	ngOnInit() {
		this.collapsedBehavior = {};

		if (this.entityBehavior.length && this.entityBehavior[0].values.length) {
			this.currentBehaviorGroup = this.entityBehavior[0];
			this.currentBehaviorGroupProperty = this.currentBehaviorGroup.values[0];
		}
	}

	clearFilter() {
		this.valuesFilter = '';
	}

	setCurrentGroupProperty(
		behaviorGroup: AirsEntityBehaviorGroup,
		behaviorGroupProperty: AirsEntityBehaviorGroupProperty
	) {
		this.currentBehaviorGroupProperty = behaviorGroupProperty;
		this.currentBehaviorGroup = behaviorGroup;
	}

	toggleBehaviorGroup(behaviorGroup: AirsEntityBehaviorGroup) {
		this.collapsedBehavior[behaviorGroup.name] = !this.collapsedBehavior[behaviorGroup.name];
	}

	isArray(value: any): boolean {
		return value instanceof Array;
	}
}
