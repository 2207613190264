import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { FileActionPermissionResult, FileActionPermissionReason } from './file.action.model';

@ValueObject({
	singularName: 'File action permissions',
	pluralName: '',
	readonly: true,
})
export class FileActionPermission extends ModelBase {
	@EntityField({ data: 'Result' })
	result: FileActionPermissionResult;

	@EntityField({ data: 'Reason' })
	reason: FileActionPermissionReason;
}
