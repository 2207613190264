import { Component, Input } from '@angular/core';
import { IRepository, Paris } from '@microsoft/paris';
import { SuppressionRulesFields } from '../services/suppression-rules.fields';
import { SuppressionRule, MdeUserRoleActionEnum } from '@wcd/domain';
import { SESSION_NEW_SUPPRESSION, SuppressionRulesService } from '../services/suppression-rules.service';
import { AuthService } from '@wcd/auth';
import { Feature, FeaturesService } from '@wcd/config';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';

@Component({
	selector: 'suppression-rules-dataview',
	providers: [SuppressionRulesFields],
	template: `	
		<div class="wcd-flex-vertical wcd-full-height">
			<div class="wcd-flex-vertical wcd-flex-items-end wcd-padding-none-horizontal-important" *ngIf="isNewSuppressionEnabled">
				<wcd-toggle
					name="toggleNewSuppression"
					[checked]="isNewSuppressionToggleEnabled"
					(change)="toggleNewSuppression($event)"
					trueLabel="{{'flags_toggleFeature_UseNewSuppressionRules_turnedOn' | i18n}}"
					falseLabel="{{'flags_toggleFeature_UseNewSuppressionRules_turnedOff' | i18n}}">
				</wcd-toggle>
			</div>
			<dataview
				class="wcd-flex-1 wcd-padding-top"
				id="suppression-rules"
				[repository]="repository"
				[entityType]="entityType"
				[fields]="suppressionRulesFields.fields"
				[highlightedItems]="suppressionRulesService.displayedRules$ | async"
				[refreshOn]="suppressionRulesService.refreshOn$ | async"
				[allowFilters]="false"
				[allowPaging]="false"
				[disableSelection]="!isUserAllowedActions"
				[defaultSortFieldId]="'lastUpdatedOn'"
				[queueHeader]="true"
				[padLeft]="false"
				commandBarNoPadding="true"
				responsiveActionBar="true"
				responsiveLayout="true"
				[removePaddingRight]="isScc"
				(onTableRenderComplete)="onTableRenderComplete()"
			>
			</dataview>
		</div>
	`,
})
export class SuppressionRulesDataviewComponent {
	@Input() rule: SuppressionRule;
	entityType: EntityType<SuppressionRule>;
	isScc = sccHostService.isSCC;
	repository: IRepository;
	isNewSuppressionEnabled: boolean;
	isNewSuppressionToggleEnabled: boolean;

	constructor(
		public paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		public authService: AuthService,
		public suppressionRulesFields: SuppressionRulesFields,
		public suppressionRulesService: SuppressionRulesService,
		private performanceSccService: PerformanceSccService,
		private entityPanelsService: EntityPanelsService,
		featuresService: FeaturesService,
	) {
		this.entityType = globalEntityTypesService.getEntityType(SuppressionRule);
		this.repository = paris.getRepository(SuppressionRule);

		this.isNewSuppressionEnabled = this.isScc && featuresService.isEnabled(Feature.NewSuppressionRuleCreation);
		this.isNewSuppressionToggleEnabled = localStorage.getItem(SESSION_NEW_SUPPRESSION) !== 'false';
	}

	get isUserAllowedActions() {
		return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
	}

	onTableRenderComplete(){
		this.performanceSccService.endNgPageLoadPerfSession('queue');
	}

	toggleNewSuppression(useNewSuppression: boolean) {
		this.entityPanelsService.closeEntityPanel(SuppressionRule);
		sccHostService.log.trackEvent('suppression-switch-experience', { useNewSuppression });
		localStorage.setItem(SESSION_NEW_SUPPRESSION, String(useNewSuppression));
	}
}
