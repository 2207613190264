<ng-container *ngLet="(alerts$ | async) as alerts">
	<wcd-datatable
					[columns]="alertsColumns"
					[items]="alerts"
					(itemClick)="viewAlert($event)"
					[selectEnabled]="false">
	</wcd-datatable>
	<button
		*ngIf="incident.alertCount > MAX_ALERTS"
		(click)="onShowMoreAlerts()"
		data-track-id="AlertPanelAlertsIncidentDetailsSection_ShowMoreAlerts"
		data-track-type="Button"
		class="btn-inline btn-link no-padding"
	>
		{{ 'alerts.showMore' | i18n }}
	</button>
</ng-container>
