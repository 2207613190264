/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "./alert-linked-by-details.component";
import * as i8 from "../services/alert-incident.service";
import * as i9 from "../../../global_entities/services/global-entity-types.service";
import * as i10 from "@microsoft/paris/dist/lib/paris";
import * as i11 from "../../../dialogs/services/dialogs.service";
import * as i12 from "../services/alert.linked-entity-resolver.service";
var styles_AlertLinkedByDetailsComponent = [];
var RenderType_AlertLinkedByDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertLinkedByDetailsComponent, data: {} });
export { RenderType_AlertLinkedByDetailsComponent as RenderType_AlertLinkedByDetailsComponent };
function View_AlertLinkedByDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-datatable", [], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, [[1, 4]], 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fixedTable: [3, "fixedTable"], label: [4, "label"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.parent.context.ngIf; var currVal_1 = false; var currVal_2 = _co.tableFields; var currVal_3 = true; var currVal_4 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 1, 4, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "alerts.panel.linkedByIncidentDetails.header")), ""); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_AlertLinkedByDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertLinkedByDetailsComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.length; var currVal_1 = i0.ɵnov(_v.parent, 9); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_AlertLinkedByDetailsComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "common.loading")); _ck(_v, 3, 0, currVal_0); }); }
function View_AlertLinkedByDetailsComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertLinkedByDetailsComponent_4)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = i0.ɵnov(_v.parent, 9); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AlertLinkedByDetailsComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), "common.noDataAvailable")); _ck(_v, 0, 0, currVal_0); }); }
export function View_AlertLinkedByDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.I18nPipe, [i4.I18nService]), i0.ɵqud(671088640, 1, { dataTable: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AlertLinkedByDetailsComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i0.ɵand(0, [["loadingOrError", 2]], null, 0, null, View_AlertLinkedByDetailsComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["noData", 2]], null, 0, null, View_AlertLinkedByDetailsComponent_5)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.tableItems$)); var currVal_1 = i0.ɵnov(_v, 7); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_AlertLinkedByDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-linked-by-details", [], null, null, null, View_AlertLinkedByDetailsComponent_0, RenderType_AlertLinkedByDetailsComponent)), i0.ɵdid(1, 573440, null, 0, i7.AlertLinkedByDetailsComponent, [i4.I18nService, i8.AlertIncidentService, i9.GlobalEntityTypesService, i10.Paris, i11.DialogsService, i12.AlertLinkedEntityResolverService], null, null)], null, null); }
var AlertLinkedByDetailsComponentNgFactory = i0.ɵccf("alert-linked-by-details", i7.AlertLinkedByDetailsComponent, View_AlertLinkedByDetailsComponent_Host_0, { aggregatedLinkedBy: "aggregatedLinkedBy", alertId: "alertId", showTooltip: "showTooltip" }, {}, []);
export { AlertLinkedByDetailsComponentNgFactory as AlertLinkedByDetailsComponentNgFactory };
