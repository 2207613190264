import { prettyDays, prettyTime } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';

export class Prettify {
	static i18nDictionary: { [key: string]: string; }
	static prettyTime(diffSeconds: number, longForm: boolean = false): string {
		return prettyTime(diffSeconds, longForm, Prettify.i18nDictionary);
	}

	static prettyDays(days: number, longForm: boolean = false): string {
		return prettyDays(days, longForm, Prettify.i18nDictionary);
	}

	static init(i18nService: I18nService) {
		Prettify.i18nDictionary = {
			'years': i18nService.get('datetime_year_plural_value'),
			'year': i18nService.get('datetime_year_singular_value'),
			'months': i18nService.get('datetime_month_plural_value'),
			'month': i18nService.get('datetime_month_singular_value'),
			'days': i18nService.get('datetime_day_plural_value'),
			'day': i18nService.get('datetime_day_singular_value'),
			'hours': i18nService.get('datetime_hour_plural_value'),
			'hour': i18nService.get('datetime_hour_singular_value'),
			'minutes': i18nService.get('datetime_minute_plural_value'),
			'minute': i18nService.get('datetime_minute_singular_value'),
			'seconds': i18nService.get('datetime_second_plural_value'),
			'second': i18nService.get('datetime_second_singular_value'),
			'y': i18nService.get('datetime_year_short_value'),
			'm': i18nService.get('datetime_month_short_value'),
			'd': i18nService.get('datetime_day_short_value'),
			'h': i18nService.get('datetime_hour_short_value'),
			'M': i18nService.get('datetime_minute_short_value'),
			's': i18nService.get('datetime_second_short_value'),
		}
	}
}
