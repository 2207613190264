var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AlertsSummaryWidgetBase } from '../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.base';
import { LegacyUser, LegacyUserAlertsSeveritySummaryRelationship } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
var UserAlertsSummaryWidgetComponent = /** @class */ (function (_super) {
    __extends(UserAlertsSummaryWidgetComponent, _super);
    function UserAlertsSummaryWidgetComponent(paris, activatedEntity, i18nService, reportsService) {
        var _this = _super.call(this, paris, activatedEntity, reportsService, i18nService) || this;
        _this.id = 'user-alerts-summary';
        _this.entityType = LegacyUser;
        _this.entityAlertsSummaryRelationship = LegacyUserAlertsSeveritySummaryRelationship;
        return _this;
    }
    return UserAlertsSummaryWidgetComponent;
}(AlertsSummaryWidgetBase));
export { UserAlertsSummaryWidgetComponent };
