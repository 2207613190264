import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { RecommendationExceptionStatus } from './recommendation-exception-status.value-object';
import { ExceptionArgs } from './exception-args/exception-args.value-object';
import { ExceptionJustification } from './exception-justification.enum';
import {
	TvmRemediationSharedEntityConfigurations,
	ParseRemediationDataQuery,
} from '../remediationEndPointUtils';
import { UserInfo } from '../common/remediation/requester.value-object';
import { DefaultApiVersion, ApiVersionHeaderName } from '../commonEndPointUtils';

@Entity({
	...TvmRemediationSharedEntityConfigurations,
	singularName: 'Recommendation exception',
	pluralName: 'Recommendation Exceptions',
	endpoint: 'recommendationExceptions',
	parseItemQuery: id => {
		return `recommendationExceptions/${id}`;
	},
	parseDataQuery: dataQuery => ParseRemediationDataQuery(dataQuery),
	customHeaders: (data, _config) => {
		// TODO[omcheri]: This code is assuming this is a POST request,
		// figure out a way to know if this is a PATCH request
		return Array.isArray(data) ? { [ApiVersionHeaderName]: '2.0' } : { ...DefaultApiVersion };
	},
})
export class RecommendationException extends EntityModelBase<string> {
	@EntityField({
		data: 'title',
	})
	readonly title: string;

	@EntityField({
		data: 'justificationContext',
	})
	readonly justificationContext: string;

	@EntityField({
		data: 'exceptionJustification',
	})
	readonly exceptionJustification: ExceptionJustification;

	@EntityField({
		data: 'expiringOn',
	})
	readonly expiringOn: Date;

	@EntityField({
		data: 'createdOn',
		serialize: false,
	})
	readonly creationTime?: Date;

	@EntityField({
		data: 'exceptionArgs',
	})
	readonly exceptionArgs: ExceptionArgs;

	@EntityField({
		data: 'status',
	})
	readonly status?: RecommendationExceptionStatus;

	@EntityField({
		data: 'requester',
	})
	readonly requester?: UserInfo;

	@EntityField({
		data: 'rbacGroupId',
	})
	readonly rbacGroupId?: number;
}
