import {
	AfterViewInit,
	ChangeDetectorRef,
	Directive,
	ElementRef,
	Inject,
	Input,
	OnInit,
	Optional,
	Self,
	ViewContainerRef,
} from '@angular/core';
import { TooltipDirective, TooltipsService } from '@wcd/dialogs';
import { Disableable, DISABLEABLE_TOKEN } from '../../shared/interfaces/disableable.interface';
import { RbacControlService } from '../services/rbac-control.service';
import { RbacControlSettings, RbacControlState } from '../models/rbac-control-settings.model';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { ReactWrapperComponent } from '@angular-react/core';
import {
	FabActionButtonComponent,
	FabCommandBarButtonComponent,
	FabCompoundButtonComponent,
	FabDefaultButtonComponent,
	FabIconButtonComponent,
	FabMessageBarButtonComponent,
	FabPrimaryButtonComponent,
	FabSplitButtonComponent,
} from '@angular-react/fabric';
import { castArray } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { WicdSanitizerService } from '@wcd/shared';

const disabledClassName = 'disabled-not-allowed';

// TODO: The base button type is not exposed from @angular-react/fabric - can remove this when it's exposed.
const FabButtonTypes = [
	FabDefaultButtonComponent,
	FabPrimaryButtonComponent,
	FabIconButtonComponent,
	FabActionButtonComponent,
	FabCompoundButtonComponent,
	FabMessageBarButtonComponent,
	FabSplitButtonComponent,
	FabCommandBarButtonComponent,
];

type FabButton =
	| FabDefaultButtonComponent
	| FabPrimaryButtonComponent
	| FabIconButtonComponent
	| FabActionButtonComponent
	| FabCompoundButtonComponent
	| FabMessageBarButtonComponent
	| FabSplitButtonComponent
	| FabCommandBarButtonComponent;

@Directive({
	selector: '[rbac]',
})
export class RbacControlDirective implements OnInit, AfterViewInit, OnChanges<RbacControlDirective> {
	@Input() rbac: RbacControlSettings;
	// need these for change detection
	@Input() isDisabled: boolean;
	@Input() readonly: boolean;

	private tooltip: TooltipDirective;
	private hasRequiredPermission: boolean;
	private readonly noPermissionTooltip: string;

	constructor(
		private readonly rbacControlService: RbacControlService,
		private readonly element: ElementRef<HTMLElement>,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private domSanitizer: WicdSanitizerService,
		@Inject(DISABLEABLE_TOKEN)
		@Self()
		@Optional()
		private readonly disableableComponent: Disableable,
		private readonly tooltipsService: TooltipsService,
		private readonly vcr: ViewContainerRef,
		private i18nService: I18nService
	) {
		this.noPermissionTooltip = this.i18nService.get('common.permissions.noPermissionTooltip');
	}

	ngOnInit() {
		if (!this.rbac) {
			return;
		}

		this.hasRequiredPermission = this.rbacControlService.hasRequiredRbacPermissions(this.rbac);

		if (!this.hasRequiredPermission) {
			if (this.rbac.state === RbacControlState.hidden) {
				this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
			} else {
				this.disableHost();
			}
		}
	}

	ngOnChanges(changes: TypedChanges<RbacControlDirective>) {
		if (this.hasRequiredPermission === false) {
			this.disableHost();
		}
	}

	ngAfterViewInit() {
		if (this.element.nativeElement.blur) {
			this.element.nativeElement.blur();
		}
	}

	onMouseDown($event: MouseEvent) {
		this.tooltip.onMouseLeave();
		$event.preventDefault();
		$event.stopPropagation();
	}

	private disableHost() {
		// if the host is a component that implements Disableable, let it handle how it disables everything
		if (this.disableableComponent) {
			this.disableDisableableComponent();
		} else if (this.isReactWrapperComponent(this.hostComponent)) {
			this.disableReactElement(this.hostComponent);
			this.setHostTooltip();
		} else {
			this.disableElement();
			this.setHostTooltip();
		}
	}

	private disableDisableableComponent() {
		if (this.disableableComponent) {
			this.disableableComponent.isDisabled = true;
			this.disableableComponent.readonly = true;
			this.disableableComponent.disableReason = this.noPermissionTooltip;
			this.changeDetectorRef.markForCheck();
		}
	}

	private disableElement() {
		if (!this.element.nativeElement.classList.contains(disabledClassName)) {
			this.element.nativeElement.classList.add(disabledClassName);
		}
	}

	private setHostTooltip() {
		this.tooltip = new TooltipDirective(
			this.element,
			this.tooltipsService,
			this.domSanitizer
		);
		this.tooltip.tooltipHTML = this.noPermissionTooltip;
		[
			'mouseover',
			'mouseenter',
			'mousemove',
			'mousedown',
			'mouseup',
			'click',
			'keydown',
			'keypress',
			'keyup',
		].forEach(eventName => {
			(this.element.nativeElement as any).removeAllListeners(eventName);
		});
		this.element.nativeElement.addEventListener(
			'mouseenter',
			this.tooltip.onMouseEnter.bind(this.tooltip)
		);
		this.element.nativeElement.addEventListener(
			'mouseleave',
			this.tooltip.onMouseLeave.bind(this.tooltip)
		);
		['mousedown', 'mouseup', 'click'].forEach((e: string) => {
			this.element.nativeElement.addEventListener(e, this.onMouseDown.bind(this));
		});

		this.changeDetectorRef.markForCheck();
	}

	private get hostComponent(): unknown {
		// this is a private API to access the underlying component in Angular - unfortunately, there's no public API for accessing a dynamic
		// component (i.e. not knowing which one it'll at compile-time) from a directive, or even one that's inherited from.
		return (
			this.vcr['_data'] && this.vcr['_data'].componentView && this.vcr['_data'].componentView.component
		);
	}

	private isReactWrapperComponent(component: unknown): component is ReactWrapperComponent<any> {
		return this.hostComponent && this.hostComponent instanceof ReactWrapperComponent;
	}

	private isFabButton(component: ReactWrapperComponent<any>): component is FabButton {
		return FabButtonTypes.some(ButtonType => component instanceof ButtonType);
	}

	private disableReactElement(component: ReactWrapperComponent<any>) {
		if (this.isFabButton(component)) {
			this.disableFabButton(component);
		} else {
			this.setClassOnReactElement(component, disabledClassName);
		}
	}

	private disableFabButton(component: FabButton) {
		// We want to always have the button disabled - override the `disabled` field
		Object.defineProperty(component, 'disabled', {
			get(): boolean {
				return true;
			},
			set(_val: boolean) {},
		});
	}

	private setClassOnReactElement(component: ReactWrapperComponent<any>, className: string) {
		const existingClasses = (component.contentClass && castArray(component.contentClass)) || [];

		component.contentClass = [...existingClasses, className];
	}
}
