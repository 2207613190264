<div class="wcd-full-height wcd-flex-vertical">
	<tabs
		class="wcd-padding-vertical wcd-padding-large-horizontal"
		[tabsData]="tabs"
		(select)="setTab($event.tab)">
	</tabs>
	<div [ngSwitch]="currentTab.id" class="wcd-flex-vertical">
		<div
			*ngSwitchCase="tabIds.deviceDetails"
			class="wcd-padding-vertical wcd-padding-large-horizontal wcd-full-height">
			<baseline-profile-device-entity-details
				[baselineProfileDevice]="baselineProfileDevice"
				[machine]="machine">
			</baseline-profile-device-entity-details>
		</div>
		<div *ngSwitchCase="tabIds.configurations" class="wcd-full-height">
			<profile-device-configurations-dataview
				[profileName]="profile.name"
				[deviceName]="baselineProfileDevice.name"
				[sourceValue]="profileDeviceIds">
			</profile-device-configurations-dataview>
		</div>
	</div>
</div>
