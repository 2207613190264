/* tslint:disable:template-accessibility-alt-text */
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { TabModel } from './tabs/tab.model';

@Component({
	selector: 'nav-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ul class="nav-list">
			<li
				*ngFor="let navItem of navItems"
				[class.active]="navItem.id === currentNavItemId"
				[routerLinkActive]="currentRouteIsActive ? 'active' : ''"
			>
				<a
					(click)="!navItem.disabled && selectNavItem(navItem, $event)"
					*ngIf="navItem.routerLink; else noLink"
					[attr.data-track-id]="'NavlistLink_' + navItem.id"
					data-track-type="Navigation"
					[routerLink]="navItem.routerLink"
					[queryParams]="navItem.routerLinkQueryParams"
					[queryParamsHandling]="navItem.routerQueryParamsHandling"
					[ngClass]="getTabClassName(tab)"
				>
					<ng-container
						*ngTemplateOutlet="itemContents; context: { navItem: navItem }"
					></ng-container>
				</a>
				<ng-template #noLink>
					<a
						(click)="!navItem.disabled && selectNavItem(navItem, $event)"
						[attr.data-track-id]="'NavlistButton_' + navItem.id"
						data-track-type="Button"
						[ngClass]="getNavItemClassName(navItem)"
					>
						<ng-container
							*ngTemplateOutlet="itemContents; context: { navItem: navItem }"
						></ng-container>
					</a>
				</ng-template>
				<ng-template #itemContents let-navItem="navItem">
					<img *ngIf="navItem.image" [src]="navItem.image" />
					<wcd-shared-icon *ngIf="navItem.icon" [iconName]="navItem.icon"> </wcd-shared-icon>
					<span>{{ navItem.text || navItem.name }}</span>
					<span
						*ngIf="navItem.value !== undefined && navItem.value !== null"
						[ngClass]="navItem.valueClassName || 'navItem-value'"
						>({{ navItem.value | prettyNumber }})</span
					>
				</ng-template>
			</li>
		</ul>
	`,
})
export class NavListComponent implements OnChanges {
	@Input() navItems: Array<NavListItem>;
	@Input() currentNavItemId: string;
	@Input() currentRouteIsActive: boolean = false;

	@Output()
	select: EventEmitter<{ navItem: NavListItem }> = new EventEmitter<{ navItem: NavListItem }>(false);

	ngOnChanges(changes) {
		if (!this.currentRouteIsActive)
			this.currentNavItemId =
				this.currentNavItemId !== undefined
					? this.currentNavItemId
					: this.navItems.length && this.navItems[0].id;
	}

	selectNavItem(navItem: NavListItem, $event): boolean {
		if (!navItem.routerLink && !navItem.disabled) {
			$event.preventDefault();
			this.currentNavItemId = navItem.id;
			this.select.emit({ navItem: navItem });
			return false;
		}

		return true;
	}

	getNavItemClassName(navItem): string {
		let className = navItem.disabled ? 'disabled' : '';

		if (navItem.className) className += ` ${navItem.className}`;

		return className;
	}
}

export class NavListItem extends TabModel {}
