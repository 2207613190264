import { Url } from '@wcd/domain';
import { Resolve, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EntityResolver } from '../../../global_entities/services/entity.resolver';
import { Paris, DataEntityType } from '@microsoft/paris';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { AppContextService } from '@wcd/config';

@Injectable()
export class UrlEntityResolver extends EntityResolver<Url> implements Resolve<Url> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		globalEntityTypesService: GlobalEntityTypesService,
		router: Router,
		entityIdRouteService: EntityIdRouteService,
		appContext: AppContextService
	) {
		super(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext);
	}

	protected loadEntity(
		entityConstructor: DataEntityType<Url>,
		entityId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		params: { [key: string]: any }
	): Observable<Url> {
		// // The url in the route is encoded 2 times. To get the actual url we need to decode it 2 times
		return this.paris.createItem(Url, {Url: decodeURIComponent(decodeURIComponent(entityId))});
	}
}
