import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvidenceDetectionSource } from './evidence-detection-source.value-object';
import { AirsEntityRemediationStatus } from '../airs_entity/airs-entity-remediation-status.entity';
import { AirsEntityTypeConfig } from '../airs_entity/airs-entity-type-config.entity';
import { AirsEntityVerdict } from '../airs_entity/airs-entity-verdict.entity';
import { EvidenceAsset } from './evidence-asset.value-object';
import { compact, sortBy } from 'lodash-es';
import { OfficeUtils } from '../utils/office-utils';
import { mapAlertV3EntityToAirsEntity } from '../airs_entity/alertV3/converters/airs-entity.converter.utils';

@Entity({
	singularName: 'Evidence',
	pluralName: 'Evidence',
	endpoint: (_, query) => {
		if (query.where['EvidenceApiV2']) {
			return `evidenceapiservice/evidence/incident/${query.where['incident_id']}`;
		}
		return `entities/evidence/${query.where['incident_id']}/unified`;
	},
	parseData: (data, _, query) => {
		// since MDE investigation still not in AlertV3, we can't convert it from AlertV3.
		// the indication that it's not alertV3 is the structure of the data - in the regular model (not AlertV3)
		// the structure of 'data' is '{data: Array<>}' and in AlertV3 it's only 'Array<>'
		// Note that it's a temp solution, until MDE investigation will be returned in AlertV3 format.
		if (query && query.where['EvidenceApiV2'] && !data.data) {
			data = OfficeUtils.convertDataFromAlertV3(data);
			const convertedData =
				data && (Array.isArray(data) ? data : [data]).map(e => mapAlertV3EntityToAirsEntity(e));
			return compact(convertedData); // we might have unknown types so we need to filter out the falsey values
		}

		return data;
	},
	allItemsProperty: 'data',
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	timeout: 1000 * 60 * 5,
})
export class EvidenceEntity extends EntityModelBase<number | string> {
	@EntityField({
		data: ['urn', 'id'],
		parse: (_, rawData) => {
			const intEntityId = parseInt(<string>rawData.id);
			const isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
			// negative 'id' means that it's not AirsEntity and we don't want to use AIRS BE
			return isIdNegative && rawData.urn ? rawData.urn : rawData.id;
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({ data: 'merge_by_key' })
	mergeByKey: string;

	@EntityField({ data: 'urn' })
	urn: string;

	@EntityField({
		data: 'first_seen',
	})
	firstSeen: Date;

	@EntityField({ data: ['entity_type_id', 'type_id'] })
	entityType: AirsEntityTypeConfig;

	@EntityField({ data: 'display_name' })
	name: string;

	@EntityField({ data: 'impacted_assets', arrayOf: EvidenceAsset })
	assets: Array<EvidenceAsset>;

	@EntityField({
		data: 'entity_detection_src_list',
		arrayOf: EvidenceDetectionSource,
		parse: (detectionContexts, rawData) => {
			if (detectionContexts && detectionContexts.length) {
				return sortBy(detectionContexts, ['detection_source_name']);
			}
			const detectionContext = rawData['entity_detection_src'];
			return (detectionContext && [detectionContext]) || null;
		},
	})
	detectionContexts: Array<EvidenceDetectionSource>;

	@EntityField()
	verdict: AirsEntityVerdict;

	@EntityField({ data: 'remediation_status', require: 'remediation_status' })
	state: AirsEntityRemediationStatus;

	@EntityField({ data: 'status_details' })
	statusDetails: Array<string>;

	@EntityField({
		data: 'investigation',
		parse: investigation => (investigation && investigation.investigation_id) || null,
	})
	investigationId?: string | number;

	@EntityField({
		data: 'threats',
		parse: fieldData => (fieldData && fieldData.length > 0 ? fieldData.join(', ') : ''),
	})
	threatsDisplayName: string;

	@EntityField({ data: 'threatsKeys' })
	threatsI18nKeys: Array<string>;

	@EntityField({
		data: 'remediation_actions',
	})
	rawRemediationActions: any;

	@EntityField({
		data: 'all_actions',
	})
	rawAllActions: any;

	// if this value is true - need to call to AIRS apis
	@EntityField({ data: 'is_converged_platform' })
	isConvergedPlatform: boolean;

	@EntityField({ data: 'is_office_entity' })
	private _isOfficeEntity: boolean;

	@EntityField()
	_rawData: any;

	isOfficeEntity: boolean;

	assetsFieldName: string;

	constructor(data) {
		super(data);
		this.setAssets();
		//TODO: need to determine if it's office entity or not in case of evidence in incident
		this.isOfficeEntity = this._isOfficeEntity || !!this.urn;
	}

	setAssets(): void {
		this.assets = this.assets.filter(a => a.displayName);
		if (this.assets && this.assets.length) {
			if (this.assets.length === 1) {
				this.assetsFieldName = this.assets[0].displayName;
			} else {
				this.assetsFieldName = `${this.assets.length} assets`;
			}
		}
	}
}
