var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { from } from 'rxjs';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
import { v4 as uuid4 } from 'uuid';
var REMEDIATION_ACTIONS_API_ENDPOINT = '/remediation_actions';
var RemediationActionsBackendService = /** @class */ (function (_super) {
    __extends(RemediationActionsBackendService, _super);
    function RemediationActionsBackendService(http, downloadService, serviceUrlsService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        return _this;
    }
    Object.defineProperty(RemediationActionsBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.automatedIr + REMEDIATION_ACTIONS_API_ENDPOINT;
        },
        enumerable: true,
        configurable: true
    });
    RemediationActionsBackendService.prototype.oatpChangeApproval = function (postData) {
        return from(sccHostService.auth.getToken().then(function (token) {
            return sccHostService.ajax.request({
                method: 'post',
                url: '<di>/Save/AirApprovalBatch',
                params: {
                    tenantid: sccHostService.loginUser.tenantId,
                },
                headers: {
                    authorization: "Bearer " + token,
                },
                data: JSON.stringify(postData),
            });
        }));
    };
    RemediationActionsBackendService.prototype.approveActions = function (actionData) {
        if (sccHostService.isSCC &&
            !tenantContextCache.hasMtpConsent &&
            !tenantContextCache.appConfig.IsMdatpActive) {
            return this.oatpChangeApproval(this.oatpActionsToPostData(actionData, 1 /* Approve */));
        }
        return this.post('approve', this.mtpActionsToPostData(actionData));
    };
    RemediationActionsBackendService.prototype.dismissActions = function (actionData, reason, comment) {
        if (sccHostService.isSCC &&
            !tenantContextCache.hasMtpConsent &&
            !tenantContextCache.appConfig.IsMdatpActive) {
            return this.oatpChangeApproval(this.oatpActionsToPostData(actionData, 0 /* Decline */, comment));
        }
        return this.post('decline', Object.assign({}, comment && { comment: comment }, this.mtpActionsToPostData(actionData), reason && { decline_reason: reason }));
    };
    RemediationActionsBackendService.prototype.retryPendingActions = function (actionIds) {
        return this.post('retry', actionIds ? { action_ids: actionIds } : {});
    };
    RemediationActionsBackendService.prototype.mtpActionsToPostData = function (actions) {
        return actions
            ? {
                action_data: actions.map(function (a) {
                    var type = a.action_type;
                    return {
                        action_id: a.action_id,
                        investigation_id: a.investigation_id,
                        action_type_id: type.id,
                        action_type_name: type.type,
                    };
                }),
            }
            : {};
    };
    RemediationActionsBackendService.prototype.oatpActionsToPostData = function (actions, decision, comment) {
        if (comment === void 0) { comment = 'Action Approved'; }
        return actions
            ? {
                TenantId: sccHostService.loginUser.tenantId,
                Payload: actions.map(function (a) { return ({
                    // fact type is just a hard coded value
                    FactTypeId: 'cc68e1cb-4913-4fcf-9f5f-ec496dd33d1b',
                    ApproverUrn: sccHostService.loginUser.upn,
                    Decision: decision,
                    DecisionComments: comment,
                    TenantId: sccHostService.loginUser.tenantId,
                    ContainerUrn: a.investigation_id,
                    ParentUrn: a.action_id,
                    Urn: "urn:" + new Date().toISOString() + ":" + uuid4().replace(/-/g, ''),
                    InstanceName: 'ActionApproval',
                    InstanceValue: sccHostService.loginUser.upn,
                }); }),
            }
            : {};
    };
    return RemediationActionsBackendService;
}(StoreBackendBase));
export { RemediationActionsBackendService };
