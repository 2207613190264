import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { ImpactedEntities } from '@wcd/domain';

@Component({
	selector: 'impacted-entities-panel',
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<impacted-entities-datatables [showAllItems]="true" [showTitle]="false" [entities]="entities">
			</impacted-entities-datatables>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImpactedEntitiesPanelComponent extends PanelContainer {
	@Input() entities: ImpactedEntities;

	constructor(protected router: Router) {
		super(router);
	}
}
