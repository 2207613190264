import { InjectionToken, Type, ComponentFactoryResolver, ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';

import { PanelContainer, PanelSettings } from '@wcd/panels';
import { ChecklistValue } from '@wcd/forms';

export const PANEL_SERVICE_INJECTION_TOKEN = new InjectionToken<SccOnboardingPanelService>(
	'SccOnboardingPanelService'
);

export interface SccOnboardingPanelService {
	showPanel<T extends PanelContainer>(
		component: Type<T>,
		panelSettings?: PanelSettings,
		inputs?: Object,
		componentFactoryResolver?: ComponentFactoryResolver
	): Observable<ComponentRef<T>>;
}

export interface SccOnboardingData {
	isMatpEnabled?: boolean;
	dataCenterRegion?: ChecklistValue;
}

/** Unified onboarding state for both V1 and V2 */
export enum OnboardingState {
	NotConsentedNotOnboarded = 'NotConsentedNotOnboarded',
	ConsentedNotOnboarded = 'ConsentedNotOnboarded',
	NotConsentedDuringOnboarding = 'NotConsentedDuringOnboarding',
	ConsentedDuringOnboarding = 'ConsentedDuringOnboarding',
	PlatformProvisioningCompleted = 'PlatformProvisioningCompleted',
	EncounteredError = 'EncounteredError',
}
