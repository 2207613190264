import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'VulnerabilityProductFix',
	pluralName: 'VulnerabilityProductFix',
})
export class VulnerabilityProductFix extends ModelBase {
	@EntityField() readonly description?: string;

	@EntityField() readonly url?: string;

	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() readonly id?: string;
}
