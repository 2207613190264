import { ChangeDetectionStrategy, Component, ComponentRef, Input, ViewChild } from '@angular/core';
import { DataEntityType, Paris } from '@microsoft/paris';
import { DataTableComponent, DataTableField } from '@wcd/datatable';
import { combineLatest, Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AggregatedIncidentLinkedBy } from '@wcd/domain';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { AlertIncidentService } from '../services/alert-incident.service';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertLinkedAlertsPanelComponent } from './linked-alerts/alert-linked-alerts-panel.component';
import { AlertLinkedEntityResolverService } from '../services/alert.linked-entity-resolver.service';
import { ImpactedEntitiesLinkedByComponent } from '../../../impacted-entities/components/impacted-entities-linked-by/impacted-entities-linked-by.component';

interface AlertLinkedByDetailsItem extends AggregatedIncidentLinkedBy {
	entity: DataEntityType;
	entityType: EntityType;
}

@Component({
	selector: 'alert-linked-by-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingOrError">
			<wcd-datatable
				*ngIf="tableItems.length; else noData"
				[items]="tableItems"
				[columns]="tableFields"
				[selectEnabled]="false"
				[fixedTable]="true"
				label="{{ 'alerts.panel.linkedByIncidentDetails.header' | i18n }}"
			></wcd-datatable>
		</ng-container>

		<ng-template #loadingOrError>
			<ng-container *ngIf="loading; else noData">
				<i class="loader-icon"></i>
				{{ 'common.loading' | i18n }}
			</ng-container>
		</ng-template>
		<ng-template #noData>
			{{ 'common.noDataAvailable' | i18n }}
		</ng-template>
	`,
})
export class AlertLinkedByDetailsComponent implements OnChanges<AlertLinkedByDetailsComponent> {
	@Input() aggregatedLinkedBy: ReadonlyArray<AggregatedIncidentLinkedBy>;
	@Input() alertId: string;

	@Input()
	showTooltip?: boolean = true;
	@ViewChild(DataTableComponent, { static: false }) dataTable: DataTableComponent;

	tableItems$: Observable<ReadonlyArray<AggregatedIncidentLinkedBy>>;
	loading: boolean = true;
	readonly tableFields = DataTableField.fromList<AggregatedIncidentLinkedBy>([
		{
			id: 'category',
			name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.category.name'),
			getDisplay: item => this.alertIncidentService.getCategoryDisplayText(item.category),
			sort: { enabled: false },
		},
		{
			id: 'entity',
			name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.entity.name'),
			sort: { enabled: false },
			component: {
				type: ImpactedEntitiesLinkedByComponent,
				getProps: (item: AlertLinkedByDetailsItem) => {
					return item.entity
						? {
								entity: item.entity,
								entityTypeId: item.entityType.id,
								entitySource: item.sourceEntity.entitySource,
								showTooltip: this.showTooltip,
								targetEntities: item.targetEntities,
						  }
						: null;
				},
			},
			useDefaultEmptyFieldComponent: true,
		},
		{
			id: 'linkedAlertsCount',
			name: this.i18nService.get('alerts.panel.linkedByIncidentDetails.table.fields.linkedAlerts.name'),
			getCssClass: _item => 'btn-link',
			getDisplay: item => this.getAlertCountText(item.linkedAlertsCount),
			onClick: item => {
				return this.showLinkedAlerts(item);
			},
			sort: { enabled: false },
		},
	]);

	private linkedAlertsSidePanel: ComponentRef<AlertLinkedAlertsPanelComponent>;

	constructor(
		private readonly i18nService: I18nService,
		private readonly alertIncidentService: AlertIncidentService,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly paris: Paris,
		private readonly dialogsService: DialogsService,
		private readonly alertLinkedEntityResolverService: AlertLinkedEntityResolverService
	) {}

	ngOnChanges(changes: TypedChanges<AlertLinkedByDetailsComponent>) {
		if (changes && changes.aggregatedLinkedBy) {
			this.tableItems$ = combineLatest(
				changes.aggregatedLinkedBy.currentValue.map(aggregatedLinkedByReason => {
					const entity$ = this.alertLinkedEntityResolverService.getEntity(aggregatedLinkedByReason);

					return entity$.pipe(
						catchError(err => of(null)),
						map(entity => ({
							...aggregatedLinkedByReason,
							entity: entity,
							entityType: this.alertLinkedEntityResolverService.getAppEntityType(
								aggregatedLinkedByReason.sourceEntity.type
							),
						}))
					);
				})
			);
		}
	}

	updateTableView() {
		if (this.dataTable) {
			this.dataTable.updateHeaderCells();
		}
	}

	private showLinkedAlerts(item: AggregatedIncidentLinkedBy) {
		this.dialogsService
			.showPanel(
				AlertLinkedAlertsPanelComponent,
				{
					id: 'alerts-linked-alerts',
					type: PanelType.large,
					isBlocking: false,
					headerText: this.getAlertCountText(item.linkedAlertsCount),
					back: {
						onClick: () => this.linkedAlertsSidePanel.destroy(),
					},
				},
				{
					alertId: this.alertId,
					linkedByReason: item,
				}
			)
			.subscribe((panel: ComponentRef<AlertLinkedAlertsPanelComponent>) => {
				this.linkedAlertsSidePanel = panel;

				panel.onDestroy(() => {
					this.linkedAlertsSidePanel = null;
				});
			});
	}

	private getAlertCountText(count: number): string {
		const key = count === 1 ? 'singular' : 'plural';

		return this.i18nService.get(`alerts.panel.linkedByIncidentDetails.alertCount.${key}`, {
			count: count,
		});
	}
}
