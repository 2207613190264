/* tslint:disable:template-accessibility-alt-text */
import { Component } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { ConfirmEvent } from '../../../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { EndpointOnboardingStatus } from '../../../../../onboarding/models/endpoint-onboarding-status.interface';
import { OnboardingBackendService } from '../../../../../onboarding/services/onboarding.backend.service';
import { OnboardingService } from '../../../../../onboarding/services/onboarding.service';
import { ServiceUrlsService, AppConfigService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { MtpPromotionService } from '../../../../../mtp-promotion/services/mtp-promotion.service';
import { Subscription } from 'rxjs';
import { attempt, forEach } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'onboarding-widget',
	providers: [OnboardingService, OnboardingBackendService],

	template: `
		<div class="wcd-padding-medium-left wcd-padding-medium-top">
			<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">
				{{ 'onboarding.title' | i18n }}
			</h2>
			<div class="wcd-margin-vertical" *ngIf="appConfigService.isAscTenant">
				<h4>{{ 'onboarding.asc.title' | i18n }}</h4>
				<markdown>{{ 'onboarding.asc.description' | i18n }}</markdown>
			</div>
			<div class="wcd-padding-bottom">
				{{ 'onboarding.description' | i18n }}
			</div>
			<div *ngIf="evaluationEnabled">
				<ng-container
					*ngTemplateOutlet="itemText; context: { item: 'evaluateLab', routerLink: '/evaluation' }"
				></ng-container>
			</div>
			<div *ngIf="(data$ | async)?.MachineOnboarded; else machineNotOnboarded">
				<ng-container
					*ngTemplateOutlet="itemText; context: { item: 'runSimulation', routerLink: '/tutorials' }"
				></ng-container>
			</div>
			<ng-template #machineNotOnboarded>
				<ng-container
					*ngTemplateOutlet="
						itemText;
						context: { item: 'onboardMachine', routerLink: '/preferences2/onboarding' }
					"
				></ng-container>
			</ng-template>
			<ng-template #itemText let-item="item" let-routerLink="routerLink">
				<div class="wcd-flex-horizontal wcd-padding-small-top">
					<a
						[attr.data-track-id]="'GoTo' + item"
						data-track-type="Navigation"
						[routerLink]="routerLink"
					>
						<img [src]="getImage(item)" />
					</a>
					<div class="wcd-padding-medium-left">
						<div class="wcd-font-weight-bold">
							{{ 'onboarding.' + item + '.title' | i18n }}
						</div>
						{{ 'onboarding.' + item + '.description' | i18n }}
					</div>
				</div>
			</ng-template>
			<div class="wcd-padding-medium-top">
				<a
					(click)="confirmAndDismiss()"
					(keydown.enter)="confirmAndDismiss()"
					data-track-id="OnboardingWidget"
					data-track-value="hideCard"
					role="button"
					tabindex="0"
				>
					{{ 'onboarding.hideCard' | i18n }}
				</a>
			</div>
		</div>
	`,
})
export class OnboardingWidget extends ReportWidgetComponent<EndpointOnboardingStatus> {
	private subscriptions: Subscription[] = [];
	private promotionVisible = false;

	constructor(
		reportsService: ReportsService,
		private serviceUrlsService: ServiceUrlsService,
		private featuresService: FeaturesService,
		private dialogsService: DialogsService,
		private onboardingService: OnboardingService,
		private mtpPromotionService: MtpPromotionService,
		public appConfigService: AppConfigService,
		private evaluationEnabledGuard: EvaluationEnabledGuard,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	ngOnInit() {
		super.ngOnInit();
		this.subscriptions = [
			this.mtpPromotionService.promoWidgetVisible$.subscribe(visible => {
				this.promotionVisible = visible;
				this.widgetConfig$.next(this.widgetConfig);
			}),
		];
	}

	ngOnDestroy() {
		super.ngOnDestroy();
		forEach(this.subscriptions, sub => attempt(() => sub.unsubscribe()));
	}

	get widgetConfig(): ReportWidgetConfig<EndpointOnboardingStatus> {
		return {
			id: 'onboarding',
			showHeader: true,
			isDisabled: this.onboardingService.onboardingWidgetDismissed || this.promotionVisible,
			name: this.i18nService.get('onboarding_name'),
			hideOnNoData: true,
			api: {
				url: () => `${this.serviceUrlsService.downloadControllerService}/EndpointOnboardingStatus`,
				isExternal: true,
			},
		};
	}

	simulationAndTutorialsEnabled = this.featuresService.isEnabled(Feature.SimulationsAndTutorials);
	evaluationEnabled = this.evaluationEnabledGuard.canActivate();

	confirmAndDismiss() {
		this.dialogsService
			.confirm({
				title: this.i18nService.get('onboarding_dismiss_title'),
				confirmText: this.i18nService.get('onboarding_dismiss_confirmText'),
				cancelText: this.i18nService.get('onboarding_dismiss_cancelText'),
				text: this.i18nService.get('onboarding_dismiss_text'),
			})
			.then((e: ConfirmEvent) => e.confirmed && this.dismiss());
	}

	getImage(item: string): string {
		return `/assets/images/onboarding/${item}.svg`;
	}

	private dismiss(trackEvent: boolean = true) {
		this.onboardingService.dismissOnboardingWidget(this.data$.getValue(), trackEvent).subscribe(() => {});
		this.widgetConfig$.next(this.widgetConfig);
	}
}
