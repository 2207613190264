import { Component, Input, OnInit } from '@angular/core';
import { ChartSettings } from '@wcd/charts';
import { Machine, MachineSecurityAnalytics } from '@wcd/domain';
import { config } from '@wcd/shared';

@Component({
	selector: 'machine-score-pie-widget',
	template: `
		<div class="wcd-flex-horizontal">
			<wcd-pie-chart
				[data]="data"
				[settings]="settings"
				[allowTitleClick]="false"
				[labelsOutside]="false"
			>
				<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
					<div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>
								<wcd-shared-icon
									class="small-icon color-text-primary"
									[iconName]="'os.windows'"
								>
								</wcd-shared-icon>
							</div>
						</div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>Windows</div>
						</div>
						<div
							class="wcd-full-height wcd-full-width wcd-flex-center-all pie-chart-content-title"
						>
							<div>{{ machineSecurityLatestState.securityScore }}</div>
						</div>
						<div class="wcd-full-height wcd-full-width wcd-flex-center-all">
							<div>of {{ machineSecurityLatestState.maxPotentialSecurityScore }}</div>
						</div>
					</div>
				</div>
			</wcd-pie-chart>
		</div>
	`,
})
export class MachineScorePieChartComponent implements OnInit {
	@Input() machine: Machine;
	@Input() machineSecurityLatestState: MachineSecurityAnalytics;

	data: Array<ScoreItem>;

	settings: ChartSettings = {
		columnName: 'name',
		columnValue: 'value',
		options: {
			data: {
				colors: {
					SecurityScore: config.color.primary,
					MaxPotentialSecurityScore: config.color.neutral2,
				},
			},
			legend: {
				show: false,
			},
			size: {
				width: 500,
				height: 200,
			},
		},
	};

	ngOnInit() {
		this.data = [
			{
				name: 'SecurityScore',
				value: this.machineSecurityLatestState.securityScore,
			},
			{
				name: 'MaxPotentialSecurityScore',
				value:
					this.machineSecurityLatestState.maxPotentialSecurityScore -
					this.machineSecurityLatestState.securityScore,
			},
		];
	}
}

export interface ScoreItem {
	name: string;
	value: number;
}
