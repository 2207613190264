<h2 class="wcd-font-weight-bold">{{ i18n.strings.baseline_profile_wizard_review_profile_step_title }}</h2>
<div class="wcd-margin-large-top">
	<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom">
		{{ i18n.strings.baseline_profile_wizard_summary_note_part1 }}
		<span class="wcd-font-weight-bold" *ngIf="data.isUpdateFlow; else elseBlock">
			{{ i18n.strings.baseline_profile_wizard_summary_note_part2_update }}
		</span>
		<ng-template #elseBlock>
			<span class="wcd-font-weight-bold">
				{{ i18n.strings.baseline_profile_wizard_summary_note_part2_create }}
			</span>
		</ng-template>
		{{ i18n.strings.baseline_profile_wizard_summary_note_part3 }}
	</div>
	<wcd-dl class="wcd-margin-bottom wcd-width-x-large" [display]="dlDisplayMode">
        <dl role="none">
            <dt role="none">{{ i18n.strings.baseline_profile_wizard_profile_name_label }}</dt>
            <dd role="none">{{ name }}</dd>
        </dl>
        <dl role="none">
            <ng-container>
                <dt role="none">{{ i18n.strings.tvm_common_status }}</dt>
                <dd role="none">{{ status }}</dd>
            </ng-container>
        </dl>
        <dl role="none" class="span-2">
            <ng-container *ngIf="description">
                <dt role="none">{{ i18n.strings.baseline_profile_wizard_profile_description_label }}</dt>
                <dd role="none">{{ description }}</dd>
            </ng-container>
        </dl>
        <dl role="none" class="span-2">
            <dt role="none">{{ i18n.strings.baseline_profile_wizard_profile_software_label }}</dt>
			<dd role="none">{{ operatingSystem }}</dd>
		</dl>
        <dl role="none">
            <dt role="none">{{ i18n.strings.baseline_profile_wizard_profile_benchmark_label }}</dt>
			<dd role="none">{{ benchmark }}</dd>
		</dl>
        <dl role="none" class="span-2">
            <dt role="none">{{ i18n.strings.baseline_profile_wizard_profile_compliance_level_label }}</dt>
			<dd role="none">{{ complianceLevel }}</dd>
		</dl>
        <dl class="span-2" role="none">
            <dt role="none">{{ i18n.strings.baseline_profile_review_assigned_configurations_label }}</dt>
			<dd role="none">
				<div *ngFor="let item of configurationsAssignment | keyvalue">
					{{item.key}} ({{item.value}})
				</div>
			</dd>
		</dl>
    </wcd-dl>
</div>
