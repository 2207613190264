import { ApplicationInitStatus, Injector, Type } from '@angular/core';
var GlobalEntityTypesService = /** @class */ (function () {
    function GlobalEntityTypesService(injector, appInitStatus) {
        this.injector = injector;
        this.appInitStatus = appInitStatus;
        this._entityTypesByData = new Map();
        this._entityTypesById = new Map();
    }
    Object.defineProperty(GlobalEntityTypesService.prototype, "entityTypes", {
        get: function () {
            return Array.from(this._entityTypesByData.values());
        },
        enumerable: true,
        configurable: true
    });
    GlobalEntityTypesService.prototype.registerEntityType = function (entityTypeService) {
        var _this = this;
        this.appInitStatus.donePromise.then(function () {
            var entityType = _this.injector.get(entityTypeService).entityType;
            _this._entityTypesByData.set(entityType.entity, entityType);
            _this._entityTypesById.set(entityType.id, entityType);
        });
    };
    GlobalEntityTypesService.prototype.getEntityType = function (dataEntityTypeOrId) {
        if (typeof dataEntityTypeOrId === 'string' || typeof dataEntityTypeOrId === 'number') {
            return this._entityTypesById.get(String(dataEntityTypeOrId));
        }
        return this._entityTypesByData.get(dataEntityTypeOrId);
    };
    GlobalEntityTypesService.prototype.getEntityLink = function (dataEntityTypeOrId, entity, options) {
        if (!dataEntityTypeOrId || !entity) {
            return null;
        }
        var entityType = this.getEntityType(dataEntityTypeOrId);
        return entityType.getEntitiesLink && entityType.getEntitiesLink([entity]);
    };
    GlobalEntityTypesService.prototype.getEntityTypeIcon = function (dataEntityType) {
        if (!dataEntityType) {
            return null;
        }
        var entityType = this.getEntityType(dataEntityType);
        return entityType ? entityType.icon : null;
    };
    GlobalEntityTypesService.prototype.getEntityName = function (dataEntityTypeOrId, entity) {
        if (!dataEntityTypeOrId || !entity) {
            return null;
        }
        var entityType = this.getEntityType(dataEntityTypeOrId);
        return entityType.getEntityName && entityType.getEntityName(entity);
    };
    GlobalEntityTypesService.prototype.getRouteLinkModel = function (dataEntityTypeOrId, entity) {
        if (!dataEntityTypeOrId || !entity) {
            return null;
        }
        var entityType = this.getEntityType(dataEntityTypeOrId);
        return entityType.getNavigationModel && entityType.getNavigationModel(entity);
    };
    GlobalEntityTypesService.getCommonValue = function (items, getValue) {
        if (!items || !items.length)
            return null;
        var firstValue = getValue(items[0]);
        return items.slice(1).every(function (item) { return getValue(item) === firstValue; }) ? firstValue : null;
    };
    GlobalEntityTypesService.prototype.getEntityTooltip = function (dataEntityType, entity) {
        if (!dataEntityType || !entity) {
            return null;
        }
        var entityType = this.getEntityType(dataEntityType);
        return entityType.getEntityTooltip && entityType.getEntityTooltip(entity);
    };
    return GlobalEntityTypesService;
}());
export { GlobalEntityTypesService };
