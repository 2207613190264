import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Threat',
	pluralName: 'Threats',
	endpoint: 'threats',
	parseItemQuery: id => {
		return `threats/${id}`;
	},
	readonly: true,
})
export class Threat extends EntityModelBase<string> {
	@EntityField({
		required: true,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({
		required: true,
	})
	readonly name: string;

	@EntityField({
		required: true,
		data: 'numOfAffectedMachines',
	})
	readonly affectedDevicesCount: number;

	@EntityField({
		required: true,
		data: 'securityRecommendations',
	})
	readonly securityRecommendationsCount: string;

	@EntityField({
		required: true,
	})
	readonly exposureScore: number;

	@EntityField({
		required: true,
	})
	readonly secureScore: number;
}
