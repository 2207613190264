import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { dateStrToUtc } from '../../utils';

@Entity({
	...airsEntityConfig,
	singularName: 'Mailbox',
	pluralName: 'Mailboxes',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsMailbox,
})
export class AirsMailbox extends AirsEntity {
	@EntityField()
	upn: string;

	@EntityField({ data: 'display_name' })
	displayName: string;

	@EntityField({ data: 'primary_mail_address' })
	primaryMailAddress: string;

	// region fake fields
	// these fields do not exist in AIRS BE

	@EntityField({ data: 'fw_rule_name' })
	fwRuleName: string;

	@EntityField({ data: 'fw_rule_creation_time', parse: dateStr => dateStrToUtc(dateStr) })
	fwRuleCreationTime: Date;

	@EntityField({ data: 'fw_rule_creator_alias' })
	fwRuleCreatorAlias: string;

	@EntityField({ data: 'fw_rule_forwarding_SMTP_address' })
	fwRuleForwardingSMTPAddress: string;

	@EntityField({ data: 'risk_level' })
	riskLevel: string;

	@EntityField({ data: 'risk' })
	risk: string;

	@EntityField({ data: 'risky_activities' })
	riskyActivities: number;

	@EntityField({ data: 'aad_id' })
	aadId: string;

	// endregion fake fields

	constructor(data) {
		super(data);
		this.entityName = this.displayName || this.primaryMailAddress;
	}
}
