import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { HuntingRuleImpactedEntityType } from '../hunting-rule-impacted-entity.value-object';

export interface HuntingQueryMetadataRequest {
	Id?: number;
	QueryText?: string;
	ColumnNames?: string[];
}

@ApiCall({
	name: 'Get possible selections of impacted entities',
	endpoint: 'rules/impactedEntities/supported',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
})
export class HuntingQuerySupportedImpactedEntitiesApiCall extends ApiCallModel<
	{ [key in HuntingRuleImpactedEntityType]: string[] },
	HuntingQueryMetadataRequest
> {}
