import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../../status.model';
import { investigationActionStatusValues } from './investigation-action-status.values';

@Entity({
	singularName: 'Investigation Action Status',
	pluralName: 'Investigation Action Statuses',
	values: investigationActionStatusValues,
})
export class InvestigationActionStatus extends StatusModel<number> {
	@EntityField({ defaultValue: false })
	isPending: boolean;
	@EntityField({ defaultValue: false })
	isRetriable: boolean;
	@EntityField({ defaultValue: false })
	isRunning: boolean;
	@EntityField({ defaultValue: false })
	isResumed: boolean;
}
