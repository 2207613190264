
		<dataview
			id="domain-exposed-machines"
			className="no-left-padding wcd-flex-1"
			[fields]="domainExposedMachinesFields.fields"
			[entityType]="entityType"
			[repository]="domainExposedMachineRepository"
			[allowFilters]="false"
			[hideControls]="hideControls"
			[navigateOnChange]="navigateOnChange"
			[allowPaging]="allowPaging"
			[disableSelection]="disableSelection"
			[hideCommandBarOnEmptyData]="hideCommandBarOnEmptyData"
			[fixedOptions]="dataviewFixedOptions"
			[searchEnabled]="searchEnabled"
			[searchRequestText]="'Search in devices'"
			[defaultSortFieldId]="defaultSortFieldId"
			[allowColumnCustomization]="false"
			[label]="'reporting.webThreatReport.domainList.exposedMachinesTitle' | i18n"
		>
		</dataview>
		<a
			*ngIf="displayViewAllLink && !fullExposedMachineList"
			(click)="showAllExposedMachines()"
			class="color-text-gray-900 wcd-margin-left"
		>
			{{ 'reporting.webThreatReport.domainList.viewAllLink' | i18n }}
			<fab-icon iconName="CaretRight"></fab-icon>
		</a>
	