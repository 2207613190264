import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { PendingActionsService } from '../services/pending-actions.service';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { ActivatedRoute, Router } from '@angular/router';
import {
	HuntingContext,
	RemediationAction,
	RemediationActionTypeActionCount,
	Investigation,
} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'pending-actions',
	templateUrl: './pending-actions.component.html',
})
export class PendingActionsComponent implements OnChanges {
	@Input() pendingActionTypes: Array<RemediationActionTypeActionCount>;
	@Input() isLoading: boolean;
	@Input() error: any;
	@Input() pollingError: any;
	@Input() fixedOptions: { [index: string]: any };
	@Input() showInvestigationData: boolean = true;
	@Input() navClassName: string;
	@Input() isRemediationDisabled: boolean;
	@Input() allowStatusSplit: boolean = false;
	@Input() externalData: Array<RemediationAction>;
	@Input() huntingContext?: HuntingContext;
	@Input() investigation: Investigation;
	@Output() refreshClick = new EventEmitter<void>();

	selectedRemediationActionTypeActions: RemediationActionTypeActionCount;
	selectedExternalDataActions: Array<RemediationAction>;
	tabs: Array<TabModel>;
	lastChange: Date;

	constructor(
		public pendingActionsService: PendingActionsService,
		private router: Router,
		private route: ActivatedRoute,
		public i18nService: I18nService
	) {}

	ngOnChanges() {
		if (this.pendingActionTypes) {
			const previousType = this.selectedRemediationActionTypeActions;
			let allowSetTabs: boolean = true;
			if (!previousType) {
				this.selectedRemediationActionTypeActions = this.pendingActionTypes[0];
			} else {
				this.selectedRemediationActionTypeActions = this.pendingActionTypes.find(
					(type: RemediationActionTypeActionCount) =>
						type.remediationActionType ===
						this.selectedRemediationActionTypeActions.remediationActionType
				);
				if (!this.selectedRemediationActionTypeActions) {
					this.selectedRemediationActionTypeActions = previousType;
					// don't set tabs because the selected tab is now missing
					allowSetTabs = false;
				}
			}

			this.setSelectedExternalDataActions();

			this.lastChange = new Date();
			if (allowSetTabs) {
				this.setTabs();
			}
		}
	}

	setTab(tab: TabModel) {
		const resetParams = {
			page: null,
			page_size: null,
			filters: null,
			search: null,
			ordering: null,
			fields: null,
		};

		this.router.navigate(['.'], { relativeTo: this.route, queryParams: resetParams });
		this.selectedRemediationActionTypeActions = tab.data.remediationActionTypeActions;
		this.setSelectedExternalDataActions();
	}

	setTabs() {
		this.tabs = this.pendingActionTypes.map(
			(remediationActionTypeActions: RemediationActionTypeActionCount) => {
				return new TabModel({
					id: remediationActionTypeActions.remediationActionType.type,
					name: remediationActionTypeActions.remediationActionType.name,
					value: remediationActionTypeActions.actionCount,
					data: {
						remediationActionTypeActions: remediationActionTypeActions,
					},
				});
			}
		);
	}

	onRefresh() {
		this.selectedRemediationActionTypeActions =
			this.pendingActionTypes.find(
				(type: RemediationActionTypeActionCount) =>
					type.remediationActionType ===
					this.selectedRemediationActionTypeActions.remediationActionType
			) || this.pendingActionTypes[0];

		this.setSelectedExternalDataActions();
		this.setTabs();
		this.refreshClick.emit();
	}

	setSelectedExternalDataActions() {
		this.selectedExternalDataActions = this.externalData
			? this.externalData.filter(
					item =>
						item.remediationActionType.type ===
						this.selectedRemediationActionTypeActions.remediationActionType.type
			  )
			: null;
	}
}
