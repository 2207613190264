import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';

@Injectable()
export class IpsService {
	constructor(private featuresService: FeaturesService, private router: Router) {}

	getIpLink(ip: string, alertLastEventTime?: Date): string {
		if (this.featuresService.isEnabled(Feature.UpgradeIpPage)) {
			const route = ['ips', ip];

			if (alertLastEventTime) {
				route.push('timeline');
			}

			return this.router.serializeUrl(this.router.createUrlTree(route));
		}

		return this.getLegacyIpLink(ip, alertLastEventTime);
	}

	getLegacyIpLink(ip: string, alertLastEventTime: Date) {
		return `/ip/${ip}${alertLastEventTime ? '/' + alertLastEventTime.toISOString() : ''}`;
	}
}
