var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef } from '@angular/core';
import { Alert, AadUserAlertsRelationship, AadUserAadUserMcasDetailsRelationship, } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityDetailsMode } from '../../../../global_entities/models/entity-details-mode.enum';
import { catchError, startWith, take } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { of } from 'rxjs';
var loadingSymbol = Symbol();
var AadUserEntityPanelComponent = /** @class */ (function (_super) {
    __extends(AadUserEntityPanelComponent, _super);
    function AadUserEntityPanelComponent(changeDetectorRef, globalEntityTypesService, paris) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.paris = paris;
        _this.EntityDetailsMode = EntityDetailsMode;
        _this.MaxAlertItems = 5;
        _this.DefaultSortBy = 'severity';
        _this.DefaultMaxColumnWidth = 250;
        _this.loadingSymbol = loadingSymbol;
        _this.error = false;
        _this.alertEntityType = globalEntityTypesService.getEntityType(Alert);
        return _this;
    }
    Object.defineProperty(AadUserEntityPanelComponent.prototype, "aadUser", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AadUserEntityPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.alertData$ = this.paris
            .getRelatedItem(AadUserAlertsRelationship, this.aadUser)
            .pipe(startWith(loadingSymbol), catchError(function (err) {
            _this.error = true;
            return of(null);
        }));
        this.paris
            .getRelatedItem(AadUserAadUserMcasDetailsRelationship, this.aadUser)
            .pipe(take(1))
            .subscribe(function (mcasDetails) {
            _this.entity = __assign({}, _this.aadUser, mcasDetails);
            _this.changeDetectorRef.detectChanges();
        });
    };
    return AadUserEntityPanelComponent;
}(EntityPanelComponentBase));
export { AadUserEntityPanelComponent };
