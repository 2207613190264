import { Dictionary } from '@wcd/config';
import { SafeHtml } from '@angular/platform-browser';
import { Injectable } from '@angular/core';
import { CookiesService } from '@wcd/shared';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class ErrorTypesService {
	constructor(
		private cookiesService: CookiesService,
		private authService: AuthService,
		private i18nService: I18nService
	) {}

	DEFAULT_ERROR_TYPE: ErrorConfig = {
		type: 'General',
		getErrorTitle: () => this.i18nService.strings.error_default_title,
		getErrorSubtitle: () => this.i18nService.strings.error_default_subtitle,
		message: this.i18nService.strings.error_default_message,
		iconName: 'errorOutline',
		appInsightsCategory: 'LoginFailure',
	};

	GENERAL_ERROR_TYPES: Dictionary<string, ErrorConfig> = Dictionary.fromList<string, ErrorConfig>(
		[
			{
				type: '404',
				getErrorTitle: () => this.i18nService.strings.error_404_title,
				getErrorSubtitle: () => this.i18nService.strings.error_404_subtitle,
				message: this.i18nService.strings.error_404_message,
				iconName: 'message',
			},
			{
				type: '403',
				getErrorTitle: () => this.i18nService.strings.error_403_title,
				getErrorSubtitle: () => this.authService.currentUser && this.authService.currentUser.name,
				message: this.i18nService.strings.error_403_message,
				iconName: 'forbidden',
			},
			{
				type: 'IPNotFound',
				getErrorTitle: () => this.i18nService.strings.error_page_no_result_found_title,
				getErrorSubtitle: id =>
					id && id.length
						? this.i18nService.get('error_IPNotFound_ip_subtitle', { ip: id })
						: this.i18nService.strings.error_this_ip_subtitle,
				iconName: 'message',
			},
			{
				type: 'URLNotFound',
				getErrorTitle: () => this.i18nService.strings.error_page_no_result_found_title,
				getErrorSubtitle: id =>
					id && id.length
						? this.i18nService.get('error_URLNotFound_url_subtitle', {
								url: decodeURIComponent(id),
						  })
						: this.i18nService.strings.error_URLNotFound_this_subtitle,
				iconName: 'message',
			},
			{
				type: 'FileNotFound',
				getErrorTitle: () => this.i18nService.strings.error_page_no_result_found_title,
				getErrorSubtitle: id =>
					id && id.length
						? this.i18nService.get('error_FileNotFound_subtitle_file', { file: id })
						: this.i18nService.strings.error_FileNotFound_subtitle_this,
				iconName: 'message',
			},
			{
				type: 'MachineNotFound',
				getErrorTitle: () => this.i18nService.strings.error_page_no_result_found_title,
				getErrorSubtitle: id =>
					id && id.length
						? this.i18nService.get('error_MachineNotFound_machine_subtitle', { machine: id })
						: this.i18nService.strings.error_MachineNotFound_this_subtitle,
				iconName: 'message',
			},
			{
				type: 'UserNotFound',
				getErrorTitle: () => this.i18nService.strings.error_page_no_result_found_title,
				getErrorSubtitle: id =>
					id && id.length
						? this.i18nService.get('error_UserNotFound_user_subtitle', { user: id })
						: this.i18nService.strings.error_UserNotFound_this_subtitle,
				iconName: 'message',
			},
			{
				type: 'Unauthorized',
				getErrorTitle: () => this.i18nService.strings.error_Unauthorized_title,
				getErrorSubtitle: () => this.i18nService.strings.error_Unauthorized_subtitle,
				iconName: 'errorOutline',
				showHelp: true,
				appInsightsCategory: 'LoginFailure',
				getAppInsightsProperties: () => {
					return { Type: 'Unauthorized' };
				},
			},
			{
				type: 'NoLicense',
				getErrorTitle: () => this.i18nService.strings.error_NoLicense_title,
				errorSubtitleHtml:
					this.i18nService.strings.error_NoLicense_subtitle_line1 +
					'<br />' +
					this.i18nService.strings.error_NoLicense_subtitle_line2_see +
					'<a data-track-id="NoLicenseDocs" data-track-type="ExternalLink" href="https://www.microsoft.com/en-us/WindowsForBusiness/windows-atp" target="_blank">' +
					this.i18nService.strings.error_NoLicense_subtitle_line2_link +
					'</a>' +
					this.i18nService.strings.error_NoLicense_subtitle_line3 +
					'<br /><br />' +
					'<strong>' +
					this.i18nService.strings.error_NoLicense_subtitle_line4 +
					'</strong> <br />' +
					this.i18nService.strings.error_NoLicense_subtitle_line5,
				iconName: 'errorOutline',
				showHelp: true,
				appInsightsCategory: 'LoginFailure',
				getAppInsightsProperties: () => {
					return { Type: 'NoLicense' };
				},
			},
			{
				type: 'LoginFailure',
				getErrorTitle: () => {
					return !this.cookiesService.cookiesEnabled
						? this.i18nService.strings.error_LoginFailure_cookies_title
						: this.i18nService.strings.error_LoginFailure_Sign_in_title;
				},
				getErrorSubtitle: () => {
					return !this.cookiesService.cookiesEnabled
						? this.i18nService.strings.error_LoginFailure_cookies_subtitle
						: this.i18nService.strings.error_LoginFailure_Sign_in_subtitle;
				},
				iconName: 'errorOutline',
				showHelp: true,
				appInsightsCategory: 'LoginFailure',
				getAppInsightsProperties: () => {
					return {
						Type: !this.cookiesService.cookiesEnabled ? 'CookiesAreDisabled' : 'GeneralError',
					};
				},
			},
			{
				type: 'LicenseNotReady',
				getErrorTitle: () => this.i18nService.strings.error_LicenseNotReady_title,
				getErrorSubtitle: () => 'You will be redirected in a few minutes.',
				iconName: 'hourglass',
				showHelp: false,
				appInsightsCategory: 'LoginFailure',
				getAppInsightsProperties: () => {
					return { Type: 'LicenseNotReady' };
				},
				redirectToRoot: true,
			},
			{
				type: 'UnauthorizedOnThisPage',
				getErrorTitle: () => this.i18nService.strings.error_UnauthorizedOnThisPage_title,
				getErrorSubtitle: () => this.i18nService.strings.error_UnauthorizedOnThisPage_subtitle,
				showHelp: false,
				appInsightsCategory: 'PermissionsErrors',
				getAppInsightsProperties: queryParams => {
					return {
						Type: 'UnauthorizedOnThisPage',
						Path: queryParams.path,
					};
				},
				iconName: 'forbidden',
			},
			{
				type: 'NeedAccess',
				getErrorTitle: () => this.i18nService.get('sccError.noUserPermissions.title'),
				getErrorSubtitle: () => this.i18nService.get('sccError.noUserPermissions.description'),
				showHelp: false,
				appInsightsCategory: 'PermissionsErrors',
				getAppInsightsProperties: queryParams => {
					return {
						Type: 'NeedAccess',
						Path: queryParams.path,
					};
				},
			},
		],
		'type'
	);

	getErrorTypeConfig(type: string) {
		return this.GENERAL_ERROR_TYPES.get(type) || this.DEFAULT_ERROR_TYPE;
	}
}

export interface ErrorConfig {
	type: string;
	getErrorTitle: () => string;
	getErrorSubtitle?: (id: string) => string;
	errorSubtitleHtml?: SafeHtml;
	message?: string;
	iconName?: string;
	showHelp?: boolean;
	appInsightsCategory?: string;
	getAppInsightsProperties?: (queryParams?: any) => { [index: string]: string };
	redirectToRoot?: boolean;
}
