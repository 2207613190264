import { NgModule, Type } from '@angular/core';
import { Resolve, RouterModule, Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard } from '@wcd/auth';
import { Feature } from '@wcd/config';
import { SecurityRecommendation } from '@wcd/domain';
import { GlobalEntitiesModule } from '../global_entities/global-entities.module';
import { EntityType } from '../global_entities/models/entity-type.interface';
import { SharedModule } from '../shared/shared.module';
import { MainSearchComponent } from './components/main-search.component';
import { SearchPageComponent } from './components/search-page.component';
import { SearchResultsComponent } from './components/search-results.component';
import { MainSearchEntityTypesService } from './services/main-search-entity-types.service';
import { SearchEntityTypeResolver } from './services/search-entity-type.resolver';
import { SearchService } from './services/search.service';
import { SecurityRecommendationsComponent } from '../@entities/@tvm/security-recommendations/components/security-recommendations.component';
import { SearchRoutingModule } from './search-routing.module';
import { AngularExtensionsModule } from '@wcd/angular-extensions';

@NgModule({
	imports: [SearchRoutingModule, SharedModule, GlobalEntitiesModule, AngularExtensionsModule],
	declarations: [MainSearchComponent, SearchPageComponent, SearchResultsComponent],
	exports: [MainSearchComponent, SearchPageComponent],
	providers: [SearchService, MainSearchEntityTypesService, SearchEntityTypeResolver],
})
export class SearchModule {}
