import { Injectable } from '@angular/core';
import { Alert, Incident, FilterItemValue, IncidentTags, Tag, TagType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash';
import {
	FiltersFieldConfig,
	FilterValuesChecklistComponent,
	FilterValuesChecklistValueData,
} from '@wcd/ng-filters';
import { CsvUtils } from '@wcd/shared';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

export const EntityTags = {
	RANSOMWARE: "ransomware_tag",
	CED: "ced_tag", // Chain event detection (Alert to event)
	MTE: "mte_tag",
	MTE_CLASSIC: "mte_classic_tag", // Free service, "Endpoint Attack Notification"
	DEX: "dex_tag", // Paid service / legacy service, "Defender experts"
	HVE: "hve_tag",
	SPOOFING: "spoofing_tag",
}
export const PredefinedTagKeys = Object.values(EntityTags);
const PredefinedTagPriorities = {
	[EntityTags.RANSOMWARE]: 10,
	[EntityTags.SPOOFING]: 20,
	[EntityTags.HVE]: 30,
	[EntityTags.MTE_CLASSIC]: 38,
	[EntityTags.DEX]: 39,
	[EntityTags.MTE]: 40,
	[EntityTags.CED]: 50,
};

@Injectable()
export class EntityTagsService {
	_builtInTags = new Map<string, Tag>();
	_incidentsQueueLocalizedTags = new Map<string, string>();

	constructor(
		private readonly appInsightService: AppInsightsService,
		private readonly paris: Paris,
		private readonly i18nService: I18nService
	) {}

	getEntityTagsFieldProps(entity: Incident | Alert) {
		return {
			tags: entity instanceof Incident ? this.getIncidentTags(entity) : this.getAlertTags(entity),
			autoOverflow: true,
		};
	}

	getIncidentTags(incident: Incident): Tag[] {
		return incident.incidentTags ? this.getEntityTags(incident.incidentTags) : [];
	}

	private getAlertTags(alert: Alert): Tag[] {
		return alert.alertTags ? this.getEntityTags(alert.alertTags) : [];
	}

	private getEntityTags({
		incidentUserTags = [],
		actorNames,
		deviceTags,
		userTags,
		systemTags,
	}: IncidentTags) {
		const actors = (Array.isArray(actorNames) ? actorNames : (actorNames && [actorNames]) || []).filter(a => a && a !== "");
		const hasDexTag = systemTags.includes(EntityTags.DEX);
		const hasMteClassicTag = systemTags.includes(EntityTags.MTE_CLASSIC);
		// The order of the tags matters
		return compact([
			systemTags.includes(EntityTags.RANSOMWARE) && this.getRansomwareTag(),
			systemTags.includes(EntityTags.SPOOFING) && this.getTelemetrySpoofingTag(),
			...actors.map((tag) => this.createTagWithLabelAndType(tag, TagType.actor)),
			systemTags.includes(EntityTags.HVE) && this.getHveTag(),
			hasDexTag && this.getDexTag(),
			hasMteClassicTag && this.getMteClassicTag(),
			!hasDexTag && !hasMteClassicTag && systemTags.includes(EntityTags.MTE) && this.getMteTag(),
			systemTags.includes(EntityTags.CED) && this.getChainEventDetectionTag(),
			...incidentUserTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.user)),
			...deviceTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.system)),
			...userTags.map((tag) => this.createTagWithLabelAndType(tag, TagType.system)),
		]);
	}

	getIncidentQueueTagsFilterConfig(
		priority: number
	): FiltersFieldConfig<any, string[], any, FilterItemValue> {
		return {
			priority,
			component: {
				config: {
					allowSingleValueDeselection: true,
					allowUnknownValues: false,
				},
				type: FilterValuesChecklistComponent,
			},
			serializeFilterValues: (filterSelection: string[]) => {
				return filterSelection && filterSelection.length > 0
					? {
							tag: filterSelection
								.map((filterValue) => {
									if (
										this._incidentsQueueLocalizedTags.get(filterValue) &&
										this._incidentsQueueLocalizedTags.get(filterValue) !== filterValue
									) {
										// Add the localized value as well to the filter query
										const localizedFilterValue = CsvUtils.encodeCsv(
											this._incidentsQueueLocalizedTags.get(filterValue)
										);
										const additionalSearchTerms = (filterValue === EntityTags.MTE_CLASSIC || filterValue === EntityTags.DEX ? `,${EntityTags.MTE}` : "");
										return `${localizedFilterValue},${CsvUtils.encodeCsv(filterValue)}${additionalSearchTerms}`;
									}
									return CsvUtils.encodeCsv(filterValue);
								})
								.join(','),
					  }
					: null;
			},
			deserializeFilterValues: (serializedValues: { tag?: string[] }) => {
				return serializedValues.tag && CsvUtils.decodeCsv(serializedValues.tag[0]);
			},
		};
	}

	getIncidentsQueueTagsFilterValues(
		tags: Array<FilterItemValue>
	): FilterValuesChecklistValueData<string>[] {
		const existingTags = new Set<string>();
		const hasDexTag = tags.map(t => t.key).includes(EntityTags.DEX);
		return tags
			.filter((tag: FilterItemValue) => !hasDexTag || (hasDexTag && tag.key !== EntityTags.MTE))
			.map((tag: FilterItemValue) => {
				if (PredefinedTagKeys.includes(tag.key)) {
					const tagLabel = this.i18nService.strings[`incident_tags_${tag.key}`];
					existingTags.add(tagLabel);
					this._incidentsQueueLocalizedTags.set(tag.key, tagLabel);
					return {
						value: tag.key,
						name: tagLabel,
						priority: PredefinedTagPriorities[tag.key],
						count: null,
					};
				} else if (!existingTags.has(tag.key)) {
					// could be a user defined a tag like "Bedrohungsexperten" === this.i18nService.strings[`incident_tags_mte_tag`], need to dedup
					existingTags.add(tag.key);
					this._incidentsQueueLocalizedTags.set(tag.key, tag.key);
					return {
						value: tag.key,
						name: tag.key,
						priority: 0,
						count: null,
					};
				}
			})
			.filter(Boolean);
	}

	getIncidentSensitivityTagFieldProps(entity: Incident | Alert) {
		return {
			tags: entity instanceof Incident ? [entity.sensitivity] : [],
		};
	}

	private createTagWithLabelAndType(label: string, tagType: TagType) {
		return {
			id: label,
			name: label,
			ariaLabel: label,
			type: tagType,
		};
	}

	private getRansomwareTag() {
		if (this._builtInTags.has(EntityTags.RANSOMWARE)) return this._builtInTags.get(EntityTags.RANSOMWARE);
		const name = this.i18nService.strings.incident_tags_ransomware_tag;
		const ransomwareTag = {
			id: EntityTags.RANSOMWARE,
			name,
			type: TagType.ransomware,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.RANSOMWARE, ransomwareTag);
		return ransomwareTag;
	}

	private getMteTag() {
		if (this._builtInTags.has(EntityTags.MTE)) return this._builtInTags.get(EntityTags.MTE);
		const name = this.i18nService.strings.incident_tags_mte_tag;
		const mteTag = {
			id: EntityTags.MTE,
			name,
			type: TagType.threatExpert,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.MTE, mteTag);
		return mteTag;
	}

	private getDexTag() {
		if (this._builtInTags.has(EntityTags.DEX)) return this._builtInTags.get(EntityTags.DEX);
		const name = this.i18nService.strings.incident_tags_mte_tag;
		const mteTag = {
			id: EntityTags.DEX,
			name,
			type: TagType.threatExpert,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.DEX, mteTag);
		return mteTag;
	}

	private getMteClassicTag() {
		if (this._builtInTags.has(EntityTags.MTE_CLASSIC)) return this._builtInTags.get(EntityTags.MTE_CLASSIC);
		const name = this.i18nService.strings.incident_tags_mte_classic_tag;
		const mteTag = {
			id: EntityTags.MTE_CLASSIC,
			name,
			type: TagType.threatExpert,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.MTE_CLASSIC, mteTag);
		return mteTag;
	}

	// AKA Priority Account
	private getHveTag() {
		if (this._builtInTags.has(EntityTags.HVE)) return this._builtInTags.get(EntityTags.HVE);
		const name = this.i18nService.strings.incident_tags_hve_tag;
		const hveTag = {
			id: EntityTags.HVE,
			name,
			type: TagType.highValueAsset,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.HVE, hveTag);
		return hveTag;
	}

	private getTelemetrySpoofingTag() {
		if (this._builtInTags.has(EntityTags.SPOOFING)) return this._builtInTags.get(EntityTags.SPOOFING);
		const name = this.i18nService.strings.incident_tags_spoofing_tag;
		const spoofingTag = {
			id: EntityTags.SPOOFING,
			name,
			type: TagType.telemetrySpoofing,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.SPOOFING, spoofingTag);
		return spoofingTag;
	}

	// AKA Alert to Event
	private getChainEventDetectionTag() {
		if (this._builtInTags.has(EntityTags.CED)) return this._builtInTags.get(EntityTags.CED);
		const name = this.i18nService.strings.incident_tags_ced_tag;
		const cedTag = {
			id: EntityTags.CED,
			name,
			type: TagType.alertToEvent,
			ariaLabel: name,
		};
		this._builtInTags.set(EntityTags.CED, cedTag);
		return cedTag;
	}
}
