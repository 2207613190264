
		<ng-container *ngIf="allowMoreActions">
			<ng-container *ngIf="(moreActionsWithHash$ | async) as moreActionsWithHash">
				<section
					*ngIf="moreActionsWithHash === loadingSymbol || moreActionsWithHash?.length > 1"
					data-track-component="ActionHistoryEntityPanel"
					class="wcd-padding-vertical wcd-padding-large-horizontal color-box-gray-25"
				>
					<div class="wcd-flex-horizontal">
						<fab-spinner
							*ngIf="moreActionsWithHash === loadingSymbol; else showData"
							[label]="i18nService.strings.actionsHistory_bulkRemediateFile_finding"
							[labelPosition]="'right'"
							[styles]="{ root: { justifyContent: 'end' } }"
						></fab-spinner>
						<ng-template #showData>
							<span>
								<wcd-checkbox
									[label]="
										'actionsHistory_bulkRemediateFile_apply'
											| i18n: { instanceCount: moreActionsWithHash.length - 1 }
									"
									[(ngModel)]="applyToAllActions"
									(ngModelChange)="setApplyToAllActions()"
								></wcd-checkbox>
							</span>
						</ng-template>
					</div>
				</section>
			</ng-container>
		</ng-container>
		<remediation-action-entity-panel
			[partialEntities]="partialEntities"
			[fullEntities]="fullEntities"
			[options]="customOptions"
			[comments]="actionComments"
			[allowEditComments]="allowEditComments"
			[alertId]="alertId"
			[summarizeEntities]="true"
		>
			<ng-container action-post-description>
				<ng-content select="[action-post-description]"></ng-content>
			</ng-container>
		</remediation-action-entity-panel>
		<wcd-collapsible-section
			*ngIf="(machine$ | async) as machine"
			[label]="i18nService.strings.machines_entityDetails_sections_details"
			[sectionId]="'action-history-panel-device-details'"
		>
			<device-details [machine]="machine" [includeMachineName]="true"></device-details>
		</wcd-collapsible-section>
		<div *ngIf="isLoading" class="wcd-flex-center-all wcd-padding-all">
			<fab-spinner></fab-spinner>
		</div>
	