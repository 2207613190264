import { NgModule } from '@angular/core';
import { PrettyBytesPipe } from './pipes/pretty-bytes.pipe';
import { PrettyNumberPipe } from './pipes/pretty-number.pipe';
import { PrettyNumberService } from './services/pretty-number.service';

@NgModule({
	declarations: [PrettyBytesPipe, PrettyNumberPipe],
	providers: [PrettyNumberService],
	exports: [PrettyBytesPipe, PrettyNumberPipe],
})
export class PrettifyModule {}
