import { PanelNavigationConfig, panelService } from './panels.service';
import { sccHostService } from '@wcd/scc-interface';

/**
 * Entities that have a side panel in React
 */
export type EntityTypeWithPanelSupport =
	| 'alert'
	| 'machine'
	| 'host'
	| 'account'
	| 'mailbox'
	| 'mailMessage'
	| 'process'
	| 'file'
	| 'ip'
	| 'url'
	| 'unified-url'
	| 'url-domain'
	| 'domain'
	| 'other'
	| 'genericCustomDetectionEntity'
	| 'huntingRecord'
	| 'fileInstance'
	| 'processEvidence'
	| 'fileEvidence'
	| 'incident' //has to be strings to avoid rendering issues from other packages

/**
 * Entities that have a side panel in React, and can be opened with just a type + id.
 * Meaning - there's a BE endpoint which returns an entity data based on type + id.
 */
export const EntitiesWithIndependentPanelSupport = ['alert', 'machine', 'process', 'file', 'ip', 'url'];

/**
 * Props of a single entity side panel.
 * @field entityData: optional data to inject into the store for this entity, instead of fetching it from the BE by id.
 * should be the actual entity type (SecurityAlert, Machine, File...).
 * @field partialEntityData: optional data to inject into the store for this entity, to be displayed to the
 * user while data is fetched by the panel from the BE.
 * @field onMainEntityDataChanged: optional callback to listen to changes in the main entity data (it must be an observable for the callback to run)
 */
export interface SingleEntityPanelProps {
	entityId: string;
	entityType: EntityTypeWithPanelSupport;
	entityData?: any; // don't fetch from BE
	partialEntityData?: any; // fetch and merge
	onMainEntityDataChanged?: (entity: any) => void;
	isFlyout?: boolean;
	contextLog?: string; // A log message that describes the context that the entity panel is opened from
}

export interface McasUserPanelProps {
	aadUserId?: string;
	sid?: string;
	accountName?: string;
	accountDomain?: string;
}

export interface SingleEntityFlyoutProps extends SingleEntityPanelProps {
	onDismiss?: () => void;
	isBlocking?: boolean;
}

export const openEntityPanel = (
	props: SingleEntityPanelProps,
	onDismiss?: () => void,
	navigationConfig?: PanelNavigationConfig
) => {
	const PanelComponent = sccHostService.ui.render('EntityPanelWithAppBootstrap@wicd-ine/main', props);
	panelService.openPanel(PanelComponent, onDismiss, navigationConfig);
};

export const openAdIotUpSellPanel = () => {
	const PanelComponent = sccHostService.ui.render('AdIotUpsellSidePanel@wicd-magellan/main');
	panelService.openPanel(PanelComponent);
};

export const openDeviceReportInaccuracyPanel = (props) => {
	const newProps = {...props, onDismiss: () => panelService.closeAllPanels(), isFromDevicePage: true}
	const PanelComponent = sccHostService.ui.render('DeviceReportInaccuracyWithBootstrap@wicd-magellan/main', newProps);
	panelService.openPanel(PanelComponent);
};

export const openMcasUserPanel = (
	props: McasUserPanelProps,
	onDismiss?: () => void,
	navigationConfig?: PanelNavigationConfig
) => {
	const PanelComponent = sccHostService.ui.render('McasAccountDetailsPaneContent@wicd-ine/main', {
		entity: props,
	});

	/**
	 * The property $$inheritors gets injected to the Object.prototype.constructor when in advanced hunting pages,
	 * by a library they use (monaco-kusto - Bridge.NET). The issue happens When trying to open a MCAS user panel, they depend on
	 * a library `object-hash` that collides with Bridge.NET and causes an error preventing the component from loading,
	 * as a work around, deleting this prop from the constructor for the component object
	 */
	// @ts-ignore
	PanelComponent.constructor.$$inheritors && delete PanelComponent.constructor.$$inheritors;

	panelService.openPanel(PanelComponent, onDismiss, navigationConfig);
};

//Can't import type PanelSettings from '@wcd/panels' here, so using 'any'
export const panelSettingsToNavigationConfig = (
	panelSettings: any,
	upButtonLabel: string,
	downButtonLabel: string,
	dismissButtonLabel: string
) => {
	return panelSettings &&
		((panelSettings.previous && panelSettings.previous.onClick) ||
			(panelSettings.next && panelSettings.next.onClick))
		? {
				upButtonOnClick: panelSettings.previous ? panelSettings.previous.onClick : null,
				upButtonDisabled: panelSettings.previous ? panelSettings.previous.isDisabled : null,
				downButtonOnClick: panelSettings.next ? panelSettings.next.onClick : null,
				downButtonDisabled: panelSettings.next ? panelSettings.next.isDisabled : null,
				upButtonLabel,
				downButtonLabel,
				dismissButtonLabel,
		  }
		: null;
};
