import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { dateStrToUtc } from '../../utils';

@Entity({
	...airsEntityConfig,
	singularName: 'Mailbox Configuration',
	pluralName: 'Mailbox Configurations',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsMailboxConfiguration,
})
export class AirsMailboxConfiguration extends AirsEntity {
	@EntityField({ data: 'mailbox_primary_address' })
	mailboxPrimaryAddress: string;

	@EntityField({ data: 'config_type' })
	configType: string;

	@EntityField({ data: 'performed_by' })
	performedBy: string;

	@EntityField({ data: 'upn' })
	upn: string;

	@EntityField({ data: 'timestamp', parse: dateStr => dateStrToUtc(dateStr) })
	timestamp: Date;

	@EntityField({ data: 'forwarding_smtp_address' })
	forwardingSmtpAddress: string;

	@EntityField({ data: 'deliver_to_mailbox_and_forward' })
	deliverToMailboxAndForward: string;

	@EntityField({ data: 'threats' })
	threats: string;

	@EntityField({ data: 'delegated_to_user' })
	delegatedToUser: string;

	@EntityField({ data: 'access_rights' })
	accessRightsI18nKey: string;

	@EntityField({ data: 'access_to_mailbox' })
	accessToMailbox: string;

	constructor(data) {
		super(data);
		this.entityName = this.mailboxPrimaryAddress;
	}
}
