import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';
import { HuntingDocumentationTableReferencePanelComponent } from './components/hunting-documentation-table-reference.panel.component';
import { FabSpinnerModule } from '@angular-react/fabric';
import { HuntingDocumentationRecordListComponent } from './components/hunting-documentation-record-list.component';
import { HuntingDocumentationSchemaTableListReferencePanelComponent } from './components/hunting-documentation-schema-table-List-reference.panel.component';

@NgModule({
	imports: [CommonModule, SharedModule, FabSpinnerModule],
	providers: [],
	declarations: [
		HuntingDocumentationTableReferencePanelComponent,
		HuntingDocumentationRecordListComponent,
		HuntingDocumentationSchemaTableListReferencePanelComponent,
	],
	exports: [HuntingDocumentationTableReferencePanelComponent],
	entryComponents: [
		HuntingDocumentationTableReferencePanelComponent,
		HuntingDocumentationSchemaTableListReferencePanelComponent,
	],
})
export class HuntingDocumentationModule {}
