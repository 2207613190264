import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EnableAutomatedIrComponent } from './components/enable-automated-ir.component';
import { AirsEnabledGuard } from './guards/airs-enabled-guard.service';
import { PreferencesSetupSettingsService } from './services/preferences-setup-settings.service';
import { WcdIconsModule } from '@wcd/icons';
import { I18nModule } from '@wcd/i18n';
import { FabShimmerModule } from '@angular-react/fabric';
import { ConfirmationDialogModule } from '@wcd/dialogs';
import { MtpOnboardingService } from './services/mtp-onboarding.service';
import { AuthService } from '@wcd/auth';
import { OnboardingService } from '@wcd/onboarding';
import { TenantStatusGuard } from './guards/tenant-status-guard.service';
import { PreferencesService } from './services/preferences.service';
import { PreferencesSCCService } from './services/preferences.scc.service';
import { SccNotOnboardedGuard } from './guards/scc-not-onboarded-guard.service';
import { ConfigCommonModule } from './config-common.module';
import { FeatureEnabledPipe } from './pipes/feature-enabled.pipe';
import { FeaturesService } from './services/features.service';
import { EndpointsGuard } from './guards/endpoints-guard.service';
import { SccRedirectGuard } from './guards/scc-redirect-guard.service';
import { TvmLicensesAngularService } from './services/tvm-licenses-angular.service';

@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		WcdIconsModule,
		I18nModule,
		FabShimmerModule,
		ConfirmationDialogModule,
		ConfigCommonModule,
	],
	declarations: [EnableAutomatedIrComponent],
	exports: [EnableAutomatedIrComponent, FeatureEnabledPipe],
})
export class ConfigModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: ConfigModule,
			providers: [
				FeaturesService,
				PreferencesSetupSettingsService,
				AirsEnabledGuard,
				TenantStatusGuard,
				SccNotOnboardedGuard,
				SccRedirectGuard,
				EndpointsGuard,
				MtpOnboardingService,
				AuthService,
				OnboardingService,
				TvmLicensesAngularService,
			],
		};
	}
}
