import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { SoftwareInstallation, Tag, SoftwareInstallationAgg } from '@wcd/domain';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { TzDateService } from '@wcd/localization';
import { TvmTextsService, InaccuracyContext, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { ReportableBaseFieldComponent } from '../../../../tvm/components/report-inaccuracy/tvm-reportable-base-field.component';
import { FeaturesService, Feature } from '@wcd/config';
import { SoftwareInstallationNormalizedDisplayPipe } from '../../../../../app/tvm/pipes/software-installation-normalized-display.pipe';
import { I18nService } from '@wcd/i18n';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';

@Injectable()
export class SoftwareInstallationAggFieldsService implements FieldsService<SoftwareInstallationAgg> {
	private _fields: Array<DataviewField<SoftwareInstallationAgg>>;
	private _networkDeviceSoftwareInstallationFields: Array<DataviewField<SoftwareInstallationAgg>>;
	private _linuxSoftwareInstallationFields: Array<DataviewField<SoftwareInstallationAgg>>;

	private _assetInstallationFields: Array<DataviewField<SoftwareInstallationAgg>>;
	private _programsFilters: Record<string, any>;

	private fieldsConfig = [
		{
			id: 'assetName',
			name: 'Device',
		},
		{
			id: 'installedVersions',
			name: 'Installed Versions',
			getDisplay: (softwareInstallation: SoftwareInstallationAgg) =>
				this.tvmTextsService.getText(TextToken.InstallationAggVersions, softwareInstallation),
			getTooltip: (softwareInstallation: SoftwareInstallationAgg) =>
				this.tvmTextsService.getText(TextToken.InstallationAggVersionsTooltip, softwareInstallation),
			valueTooltipAllowHtmlRendering: true,
			sort: {
				enabled: false,
			},
		},
		{
			id: 'lastSeen',
			name: 'Last seen',
			getDisplay: (softwareInstallation: SoftwareInstallationAgg) => {
				return this.tzDateService.format(softwareInstallation.lastSeen, 'short');
			},
		},
		{
			id: 'highSevAlert',
			name: 'High Alerts (last 7d)',
			getDisplay: (softwareInstallation: SoftwareInstallationAgg) => {
				return softwareInstallation.highSevAlert ? 'Yes' : 'No';
			},
		},
	];

	constructor(
		private tzDateService: TzDateService,
		private tvmIconBuilderService: TvmIconBuilderService,
		private readonly featuresService: FeaturesService,
		private i18nService: I18nService,
		private tvmTextsService: TvmTextsService,
		private tvmTagsService: TvmTagsService
	) {}

	get fields(): Array<DataviewField<SoftwareInstallationAgg>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<SoftwareInstallationAgg>(this.fieldsConfig);
		}
		return this._fields;
	}

	get networkSoftwareInstallationFields(): Array<DataviewField<SoftwareInstallationAgg>> {
		if (!this._networkDeviceSoftwareInstallationFields) {
			this._networkDeviceSoftwareInstallationFields = DataviewField.fromList<SoftwareInstallationAgg>([
				...this.fieldsConfig,
				{
					id: 'ipAddress',
					name: 'Ip address',
				},
				{
					id: 'description',
					name: 'Description',
					maxWidth: 250,
					getTooltip: (softwareInstallation: SoftwareInstallationAgg) =>
						softwareInstallation.description,
				},
			]);
		}
		return this._networkDeviceSoftwareInstallationFields;
	}

	get linuxSoftwareInstallationFields(): Array<DataviewField<SoftwareInstallationAgg>> {
		if (!this._linuxSoftwareInstallationFields) {
			const linuxFields = [
				...this.fieldsConfig.slice(0, 1),
				{
					id: 'osDistribution',
					name: this.i18nService.get('tvm_common_osDistribution'),
					sort: { enabled: false },
					getDisplay: (softwareInstallation: SoftwareInstallationAgg) =>
						softwareInstallation.osDistribution
							? this.i18nService.get(
									`tvm_common_osDistribution_${softwareInstallation.osDistribution}`
							  )
							: this.i18nService.strings.notAvailable_short,
				},
				...this.fieldsConfig.slice(1),
			];
			this._linuxSoftwareInstallationFields = DataviewField.fromList<SoftwareInstallationAgg>(
				linuxFields
			);
		}
		return this._linuxSoftwareInstallationFields;
	}
}
