var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-alt-text */
import { EventEmitter, OnInit } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { EvaluationSimulatorAgent } from '@wcd/domain';
import { ContentState } from '@wcd/contents-state';
import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ReplaySubject } from 'rxjs';
var SimulationDescriptionPanelComponent = /** @class */ (function (_super) {
    __extends(SimulationDescriptionPanelComponent, _super);
    function SimulationDescriptionPanelComponent(router, evaluationService) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.onClose = new EventEmitter();
        _this.contentState = new ReplaySubject(1);
        _this.SpinnerSize = SpinnerSize;
        _this.EvaluationSimulatorAgent = EvaluationSimulatorAgent;
        _this.contentState.next(ContentState.Loading);
        return _this;
    }
    SimulationDescriptionPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.simulationId) {
            this.handleError();
        }
        this.evaluationService.getSimulationCatalogById(this.simulationId).subscribe(function (simulation) {
            _this.simulation = simulation;
            if (!_this.simulation) {
                _this.handleError();
            }
            _this.contentState.next(ContentState.Complete);
        }, function () {
            _this.handleError();
        });
    };
    SimulationDescriptionPanelComponent.prototype.handleError = function () {
        this.contentState.next(ContentState.Error);
    };
    return SimulationDescriptionPanelComponent;
}(PanelContainer));
export { SimulationDescriptionPanelComponent };
