
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3
						class="side-panel-title wcd-font-weight-bold"
						id="machine-directory-data-panel-header"
						#panelHeader
					>
						{{ 'machines.entityDetails.fields.directoryData.title' | i18n }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-large-left">
				<wcd-collapsible-section label="{{ 'machines.entityDetails.fields.uacFlags.title' | i18n }}">
					<machine-itp-uac-flags [uacFlags]="machine.uacFlags"></machine-itp-uac-flags>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					label="{{ 'machines.entityDetails.fields.directoryData.identifiers.title' | i18n }}"
				>
					<machine-itp-directory-data-identifiers
						[directoryDataIdentifiers]="directoryDataIdentifiers"
					></machine-itp-directory-data-identifiers>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					label="{{
						'machines.entityDetails.fields.spns.titleWithCount'
							| i18n: { count: machine.spns?.length }
					}}"
				>
					<machine-itp-spns [spns]="machine.spns"></machine-itp-spns>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					label="{{
						'machines.entityDetails.fields.groupMembership.titleWithCount'
							| i18n: { count: machine.groupMembership?.length }
					}}"
				>
					<machine-itp-group-membership
						[groupMembership]="machine.groupMembership"
					></machine-itp-group-membership>
				</wcd-collapsible-section>
			</div>
		</wcd-panel>
	