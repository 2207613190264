import { Injectable } from '@angular/core';
import { DimPeriodData, DimDailyData } from '@wcd/domain';
import { ChartSettings } from '@wcd/charts';
import { PrettyNumberService } from '@wcd/prettify';
import { maxBy, mapValues, mapKeys, merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ChartSeriesConfiguration, ChartSingleSeriesConfiguration, ChartType } from './reporting.types';
import { ReportsService } from '../shared-reports/services/reports.service';
import { TzDateService } from '@wcd/localization';
import { ChartConfiguration } from 'c3';

const MAX_LABELS = 4;

const COMMON_C3_CONFIG: Partial<ChartConfiguration> = {
	grid: {
		y: {
			show: true,
		},
	},
	point: {
		show: false,
	},
	legend: { show: false },
	size: {
		height: 250,
	},
};

@Injectable()
export class ReportingService {
	constructor(
		private prettyNumberService: PrettyNumberService,
		private i18nService: I18nService,
		private tzDateService: TzDateService
	) {}

	public getSettingsDaily<Dim extends string, DimMembers extends string>(
		type: ChartType,
		chartSeriesConfiguration: ChartSeriesConfiguration<DimMembers>,
		dimPeriodData?: DimPeriodData<Dim, DimMembers>,
		c3Options?: Partial<ChartConfiguration>
	): ChartSettings {
		const dimensionData =
			dimPeriodData && dimPeriodData.data && dimPeriodData.data.length && dimPeriodData.data[0];
		const maxCount: number =
			dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
		const chartData = Object.entries<ChartSingleSeriesConfiguration>(chartSeriesConfiguration).map(
			([key, value]) => this.getDimensionDataDaily(value.label, key, dimensionData)
		);

		const colorsPattern = Object.values<ChartSingleSeriesConfiguration>(chartSeriesConfiguration).map(
			value => {
				const raw = value.knownColor ? value.knownColor.raw : value.knownColorsMap.daily.raw;
				return raw;
			}
		);

		const defaultDailyConfig = {
			options: {
				color: {
					pattern: colorsPattern,
				},
				data: {
					columns: [[this.i18nService.get('common.all'), ...chartData.map(data => data.value)]],

					groups: [
						Object.values<ChartSingleSeriesConfiguration>(chartSeriesConfiguration).map(
							value => value.label
						),
					],
					type: 'bar',
					labels: {
						format: value => (maxCount > 0 ? this.prettyNumberService.prettyNumber(value) : ''), //c3 labels issue when all labels are zero, the labels are at a wrong position
					},

					color: (inColor, data) => {
						if (data.index !== undefined) {
							return colorsPattern[data.index];
						}
						return inColor;
					},
				},
				tooltip: {
					grouped: false,
					contents: data =>
						`<table class="c3-tooltip">
							<tbody>
								<tr>
									<td class="name">${data[0].value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")/* Add commas to numbers (e.g. 1,234,567)*/}</td>
								</tr>
							</tbody>
						</table>`,
				},
				bar: {
					width: {
						max: 8,
						ratio: 0.2,
					},
				},
				axis: {
					rotated: type === ChartType.Horizontal,
					x: {
						type: 'category',
						categories: chartData.map(data => data.label),
						tick: {
							multiline: type === ChartType.Horizontal,
							culling: {
								max: 1,
							},
							rotate: 75,
						},
						padding: { right: 0.5 },
					},
					y: {
						show: true,
						max: maxCount ? maxCount : 0,
						min: 0,
						tick: {
							values: dimPeriodData ? ReportsService.getYTicks(maxCount) : [],
							format: value => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
				},
				padding: {
					left: type === ChartType.Horizontal ? 100 : 50,
				},
			},
			disableCursor: true,
		};

		const settings = merge(defaultDailyConfig, { options: COMMON_C3_CONFIG }, { options: c3Options });

		return settings;
	}

	getSettingsOverTime<Dim extends string, DimMembers extends string>(
		dim: Record<DimMembers, string>,
		chartSeriesConfiguration: ChartSeriesConfiguration<DimMembers>,
		dimPeriodData: DimPeriodData<Dim, DimMembers>,
		c3Options?: Partial<ChartConfiguration>
	): ChartSettings {
		const dimensionData = dimPeriodData.data && dimPeriodData.data.length && dimPeriodData.data;

		const maxMachineCount =
			dimensionData &&
			dimensionData.reduce(
				(maxMachineCount, data) => Math.max(maxBy(Object.values(data.values)), maxMachineCount),
				0
			);

		const defaultOverTimeConfig = {
			options: {
				data: {
					columns: this.getDimensionData<Record<DimMembers, string>, DimMembers>(
						dim,
						dimensionData,
						chartSeriesConfiguration
					),
					colors: mapValues(mapKeys(chartSeriesConfiguration, value => value.label), value => {
						const raw = value.knownColor ? value.knownColor.raw : value.knownColorsMap.trend.raw;
						return raw;
					}),
				},
				axis: {
					y: {
						max: maxMachineCount ? maxMachineCount : 0,
						min: 0,
						tick: {
							values: dimensionData ? ReportsService.getYTicks(maxMachineCount) : [],
							format: value => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
					x: {
						type: 'category',
						categories: this.getDimensionDates(dimensionData),
						tick: {
							multiline: false,
							culling: {
								max: dimensionData
									? Math.min(dimensionData.length + 1, MAX_LABELS)
									: MAX_LABELS,
							},
						},
					},
				},
			},
			disableCursor: true,
		};

		const settings = merge(defaultOverTimeConfig, { options: COMMON_C3_CONFIG }, { options: c3Options });

		return settings;
	}

	private getDimensionData<DimMembersRecord extends Record<string, string>, DimMembers extends string>(
		dim: DimMembersRecord,
		dimDailyDataArr: Array<DimDailyData<DimMembers>>,
		chartSeriesConfiguration: ChartSeriesConfiguration<DimMembers>
	): (string | number | boolean)[][] {
		return Object.keys(dim)
			.filter(key => !!chartSeriesConfiguration[key])
			.map(key =>
				this.getSeriesData<DimMembers>(chartSeriesConfiguration[key].label, key, dimDailyDataArr)
			);
	}

	private getSeriesData<DimMembers extends string>(
		label: string,
		dimMember: string,
		dimDailyDataArr: Array<DimDailyData<DimMembers>>
	) {
		return [
			label,
			...(dimDailyDataArr &&
				dimDailyDataArr.map(data => (data.values[dimMember] ? data.values[dimMember] : 0))),
		];
	}

	private getDimensionDates<T extends string>(dimensionData: Array<DimDailyData<T>>): Array<string> {
		return dimensionData ? dimensionData.map(data => this.tzDateService.format(data.date, 'MM/dd')) : [];
	}

	private getDimensionDataDaily<T extends string>(
		label: string,
		dimMember: string,
		reportDailyData: DimDailyData<T>
	) {
		return {
			label: label,
			value:
				reportDailyData &&
				(reportDailyData.values[dimMember] ? reportDailyData.values[dimMember] : 0),
		};
	}
}
