import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import {
	AlertsSeveritySummary,
	EvidenceAlertsSummary,
	File,
	FileIncidentAlertsRelationship,
	FileVirusTotalFileReportRelationship,
	VirusTotalFileReport,
} from '@wcd/domain';
import { Observable, of, Subscription } from 'rxjs';
import { catchError, map, startWith } from 'rxjs/operators';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { PrettyNumberPipe } from '@wcd/prettify';
import { AlertsDataviewComponent } from '../../alerts/components/alerts.dataview';
import {Feature, FeaturesService, FlavorService} from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

enum CollapsibleID {
	ActiveAlerts = 'airs-entity-file-active-alerts',
	VirusTotal = 'airs-entity-file-virus-total',
	FileStats = 'airs-entity-file-stats',
	FilePrevalence = 'airs-entity-file-prevalence',
}

const loadingSymbol: unique symbol = Symbol();
type Loadable<T> = T | typeof loadingSymbol;

@Component({
	selector: 'airs-entity-file',
	template: `
		<ng-container *ngIf="(alertData$ | async) as alertData">
			<ng-container *ngIf="alertData !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="alertData?.alertsSeveritySummary?.hasAlerts || alertData?.alerts?.length"
					[sectionId]="collapsibleID.ActiveAlerts"
					(isExpandedChange)="$event && alertsDataview && alertsDataview.updateTableHeaders()"
					[label]="activeAlertsTitle"
				>
					<div class="wcd-padding-large-bottom">
						<alerts-severity-summary
							[alertsSeveritySummary]="alertData.alertsSeveritySummary"
						></alerts-severity-summary>
					</div>
					<alerts-dataview
						*ngIf="alertData.alerts"
						#alertsDataview
						[dataViewId]="'airs-entity-alert-dataview'"
						[dataViewConfig]="{ data: alertData.alerts, id: 'airs-entity-alert-dataview' }"
						[showHeader]="false"
						[allowFilters]="false"
						[allowPaging]="false"
						[allowGrouping]="false"
						[allowTimeRangeSelect]="false"
						[hideControls]="true"
						[disableSelection]="true"
						[fixedTable]="true"
						[onlyFields]="['title', 'severity']"
						[queueHeader]="false"
						[sortDisabledFields]="['title', 'severity']"
						[removePadding]="false"
					></alerts-dataview>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="(vtData$ | async) as vtData">
			<ng-container *ngIf="vtData !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="vtData.total"
					[sectionId]="collapsibleID.VirusTotal"
					[label]="i18nService.strings.entities_panelSections_virusTotal"
				>
					<div class="wcd-full-width wcd-flex-horizontal">
						<wcd-bar
							[fillRatio]="vtData.positives / vtData.total"
							[width]="'100%'"
							[colorName]="'yellow'"
							class="wcd-full-width wcd-margin-small-right"
						></wcd-bar>
						<span class="bold">{{ vtData.positives }}</span>
						<span class="color-text-neutralTertiary">/{{ vtData.total }}</span>
					</div>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="(fileStats$ | async) as fileStats">
			<ng-container *ngIf="fileStats !== loadingSymbol; else showLoader">
				<wcd-collapsible-section
					*ngIf="fileStats?.length && fileStats[0].prevalentThreatName"
					[sectionId]="collapsibleID.FileStats"
					(isExpandedChange)="$event && tableComponent.updateHeaderCells()"
					[label]="i18nService.strings.entities_panelSections_malware"
				>
					<!--[
					Added container with min-height to 1px - if the height is zero, no expansion will take place.
					wcd-datatable has height=100%, which is being affected by the containers height.
					]-->
					<div [style.minHeight.px]="1">
						<wcd-datatable
							#tableComponent
							[items]="fileStats"
							[columns]="malwareFields"
							[fixedTable]="true"
							[selectEnabled]="false"
						></wcd-datatable>
					</div>
				</wcd-collapsible-section>
			</ng-container>
		</ng-container>
		<wcd-collapsible-section
			*ngIf="file && isFilePrevalenceApiEnabled"
			[sectionId]="collapsibleID.FilePrevalence"
			[label]="i18nService.strings.entities_panelSections_filePrevalence"
		>
			<file-prevalence [file]="file" [summaryView]="true"></file-prevalence>
		</wcd-collapsible-section>
		<ng-template #showLoader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner></fab-spinner>
			</div>
		</ng-template>
	`,
	providers: [PrettyNumberPipe],
})
export class AirsEntityFileDetailsComponent implements OnDestroy {
	alertData$: Observable<Loadable<EvidenceAlertsSummary>>;
	fileStats$: Observable<Loadable<Array<File>>>;
	vtData$: Observable<Loadable<VirusTotalFileReport>>;
	malwareFields: Array<DataTableField> = DataTableField.fromList<File>([
		{
			id: 'malwareName',
			name: this.i18nService.strings
				.files_entityDetails_sections_detections_fields_malwareDetected_malware,
			getDisplay: f => f.prevalentThreatName,
			sort: { enabled: false },
		},
		{
			id: 'source',
			name: this.i18nService.strings
				.files_entityDetails_sections_detections_fields_malwareDetected_source,
			getDisplay: f =>
				this.i18nService.strings
					.files_entityDetails_sections_detections_fields_malwareDetected_sources_windowsDefenderAv,
			sort: { enabled: false },
		},
	]);
	loadingSymbol = loadingSymbol;
	activeAlertsTitle: string;
	isFilePrevalenceApiEnabled: boolean;
	collapsibleID = CollapsibleID;
	@ViewChild('alertsDataview', { static: false }) alertsDataview: AlertsDataviewComponent;
	private fileAlertRepo: RelationshipRepository<File, EvidenceAlertsSummary>;
	private alertsDataSubscription: Subscription;

	constructor(
		private paris: Paris,
		public i18nService: I18nService,
		private prettyNumberPipe: PrettyNumberPipe,
		flavorService: FlavorService,
		private readonly featuresService: FeaturesService,
	) {
		this.fileAlertRepo = this.paris.getRelationshipRepository(FileIncidentAlertsRelationship);
		this.isFilePrevalenceApiEnabled = flavorService.isEnabled(
			AppFlavorConfig.incidents.filePrevalenceApi
		);
	}

	private _file: File;

	get file(): File {
		return this._file;
	}

	@Input()
	set file(value: File) {
		this.setFileData(value);
		this._file = value;
	}

	ngOnDestroy(): void {
		if (this.alertsDataSubscription) {
			this.alertsDataSubscription.unsubscribe();
		}
	}

	private getAlertsTitle(alertsSeveritySummary: AlertsSeveritySummary): string {
		if (!alertsSeveritySummary || !alertsSeveritySummary.hasAlerts) {
			return this.i18nService.strings.reports_widgets_alertsSummary_noActiveAlerts;
		}
		const activeAlertsQuantity =
			alertsSeveritySummary.alertsCount === 1
				? this.i18nService.strings.reports_widgets_alertsSummary_activeAlerts_singular
				: this.i18nService.strings.reports_widgets_alertsSummary_activeAlerts_plural;
		const incidentQuantity =
			alertsSeveritySummary.incidentsCount === 1
				? this.i18nService.strings.reports_widgets_alertsSummary_incidents_singular
				: this.i18nService.strings.reports_widgets_alertsSummary_incidents_plural;

		return this.i18nService.get('reports_widgets_alertsSummary_activeAlertsInIncidents', {
			alertCount: this.prettyNumberPipe.transform(alertsSeveritySummary.alertsCount),
			activeAlerts: activeAlertsQuantity,
			incidentCount: this.prettyNumberPipe.transform(alertsSeveritySummary.incidentsCount),
			incident: incidentQuantity,
		});
	}

	private setFileData(file: File) {
		if (!(file && file.sha1)) {
			this.alertData$ = this.fileStats$ = this.vtData$ = null;
		} else if ((this.file && this.file.sha1) !== file.sha1) {
			this.alertData$ = this.fileAlertRepo.getRelatedItem(file).pipe(
				startWith(loadingSymbol),
				catchError(err => of(null))
			);
			this.fileStats$ = this.paris
				.getRepository(File)
				.getItemById(file.sha1, undefined, {['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW)})
				.pipe(
					map(f => [f]),
					startWith(loadingSymbol),
					catchError(err => of(null))
				);
			this.vtData$ = this.paris
				.getRelatedItem<File, VirusTotalFileReport>(FileVirusTotalFileReportRelationship, file)
				.pipe(
					startWith(loadingSymbol),
					catchError(err => of(null))
				);
		}
		if (this.alertData$) {
			this.alertsDataSubscription = this.alertData$.subscribe(alertData => {
				if (alertData !== loadingSymbol) {
					this.activeAlertsTitle = this.getAlertsTitle(alertData.alertsSeveritySummary);
				}
			});
		}
	}
}
