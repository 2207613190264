/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../shared/components/copyable-input/copyable-input.component.ngfactory";
import * as i4 from "../../../shared/components/copyable-input/copyable-input.component";
import * as i5 from "./scheduled-task.entity-details.component";
var styles_ScheduledTaskEntityDetailsComponent = [];
var RenderType_ScheduledTaskEntityDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScheduledTaskEntityDetailsComponent, data: {} });
export { RenderType_ScheduledTaskEntityDetailsComponent as RenderType_ScheduledTaskEntityDetailsComponent };
export function View_ScheduledTaskEntityDetailsComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 12, "dl", [["role", "none"]], [[2, "key-values", null]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "dt", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "dd", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "copyable-input", [["showFullValue", "true"]], null, null, null, i3.View_CopyableInputComponent_0, i3.RenderType_CopyableInputComponent)), i0.ɵdid(11, 49152, null, 0, i4.CopyableInputComponent, [], { value: [0, "value"], readonly: [1, "readonly"], showFullValue: [2, "showFullValue"], ariaLabel: [3, "ariaLabel"] }, null), i0.ɵppd(12, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.scheduledTask.taskName; var currVal_3 = true; var currVal_4 = "true"; var currVal_5 = i0.ɵunv(_v, 11, 3, _ck(_v, 12, 0, i0.ɵnov(_v, 0), "scheduledTasks.fields.taskName.title")); _ck(_v, 11, 0, currVal_2, currVal_3, currVal_4, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asKeyValueList; _ck(_v, 2, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "scheduledTasks.fields.taskName.title")); _ck(_v, 5, 0, currVal_1); }); }
export function View_ScheduledTaskEntityDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "scheduled-task-entity-details", [], null, null, null, View_ScheduledTaskEntityDetailsComponent_0, RenderType_ScheduledTaskEntityDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i5.ScheduledTaskEntityDetailsComponent, [i0.Injector], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledTaskEntityDetailsComponentNgFactory = i0.ɵccf("scheduled-task-entity-details", i5.ScheduledTaskEntityDetailsComponent, View_ScheduledTaskEntityDetailsComponent_Host_0, { entity: "entity", dataEnriched: "dataEnriched", asKeyValueList: "asKeyValueList", showEntityPanelLink: "showEntityPanelLink", mode: "mode", entityPageViewMode: "entityPageViewMode" }, {}, []);
export { ScheduledTaskEntityDetailsComponentNgFactory as ScheduledTaskEntityDetailsComponentNgFactory };
