import { MachineManagedByType } from './machine-managed-by.models';
import { keyBy } from 'lodash-es';
var ɵ0 = MachineManagedByType.Mdm, ɵ1 = MachineManagedByType.Sccm, ɵ2 = MachineManagedByType.Mde, ɵ3 = MachineManagedByType.Other;
export var machineManagedByValues = [
    {
        id: ɵ0,
        name: 'MDM',
        nameI18nKey: 'machineManagedBy_Mdm',
        priority: 1,
    },
    {
        id: ɵ1,
        name: 'SCCM',
        nameI18nKey: 'machineManagedBy_Sccm',
        priority: 2,
    },
    {
        id: ɵ2,
        name: 'MDE',
        nameI18nKey: 'machineManagedBy_Mde',
        priority: 3,
    },
    {
        id: ɵ3,
        name: 'Other',
        nameI18nKey: 'machineManagedBy_Other',
        priority: 4,
    },
];
var ɵ4 = function (o) { return o.id; };
export var machineManagedByValuesMap = keyBy(machineManagedByValues, ɵ4);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
