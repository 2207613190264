import { Injectable } from '@angular/core';
import { EntityType } from '../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../global_entities/models/entity-type-service.interface';
import { RecommendationExceptionAggregated } from '@wcd/domain';
import { RecommendationExceptionAggregatedEntityPanelComponent } from '../components/recommendation-exception-aggregated.entity-panel.component';
import { RecommendationExceptionAggregatedEntityDetailsComponent } from '../../../../../global_entities/components/entity-details/recommendation-exception-aggregated.entity-details.component';
import { RecommendationExceptionService } from './recommendation-exception.service';

@Injectable()
export class RecommendationExceptionAggregatedEntityTypeService
	implements EntityTypeService<RecommendationExceptionAggregated> {
	constructor(private recommendationExceptionService: RecommendationExceptionService) {}

	readonly entityType: EntityType<RecommendationExceptionAggregated> = {
		id: 'RecommendationExceptionAggregated',
		entity: RecommendationExceptionAggregated,
		entityDetailsComponentType: RecommendationExceptionAggregatedEntityDetailsComponent,
		singleEntityPanelComponentType: RecommendationExceptionAggregatedEntityPanelComponent,
		getEntityName: recommendationExceptionAggregated => recommendationExceptionAggregated.title,
		getEntityDataviewLink: () => '/recommendation-exception',
		getActions: this.recommendationExceptionService.getActions,
		loadFullEntityInPanel: false,
	};
}
