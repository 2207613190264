import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'IP data',
	endpoint: 'autoir/officeui/interflow/ip_addresses',
	baseUrl: config => config.data.serviceUrls.k8s,
	method: 'POST',
	parseQuery: input => ({
		data: {
			ip_addresses: [input['address']],
		},
	}),
	parseData: data => {
		const res = (data && data.length && data[0]) || null;
		return (
			res && {
				city: res.City,
				carrier: res.Carrier,
				country: res.Country,
				region: res.Region,
				postalCode: res.PostalCode,
				state: res.State,
				...(res.Latitude && res.Longitude
					? { coordinates: `${res.Latitude}, ${res.Longitude}` }
					: null),
			}
		);
	},
	cache: {
		time: 60 * 1000 * 60,
	},
})
export class InterflowIpDataApiCall extends ApiCallModel<InterflowIpData, { address: string }> {}

export interface InterflowIpData {
	carrier: string;
	city: string;
	country: string;
	postalCode: string;
	region: string;
	state: string;
	coordinates: string;
}
