var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { Incident } from '@wcd/domain';
import { AlertsService } from '../../alerts/services/alerts.service';
import { AppContextService } from '@wcd/config';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
var IncidentAlertsPanelComponent = /** @class */ (function (_super) {
    __extends(IncidentAlertsPanelComponent, _super);
    function IncidentAlertsPanelComponent(alertsService, appContextService, changeDetectorRef, router, _i18nService, alertsFields) {
        var _this = _super.call(this, router) || this;
        _this.alertsService = alertsService;
        _this.appContextService = appContextService;
        _this._i18nService = _i18nService;
        _this.alertsFields = alertsFields;
        _this.isMtpEnabled = _this.appContextService.isMtp;
        _this.i18nService = _i18nService;
        return _this;
    }
    IncidentAlertsPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    IncidentAlertsPanelComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
    };
    IncidentAlertsPanelComponent.prototype.export = function () {
        this.alertsService.downloadCsv(this.alerts, {
            columns: ['title', 'severity', 'status'],
            fields: this.alertsFields.allFields
        });
    };
    return IncidentAlertsPanelComponent;
}(PanelContainer));
export { IncidentAlertsPanelComponent };
