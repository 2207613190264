import { NgModule } from '@angular/core';
import { VulnerabilityNotificationRuleWizardComponent } from './components/vulnerability-notification-rule-wizard.component';
import { WcdWizardModule } from '@wcd/wizard';
import { VulnerabilityNotificationRulesService } from './services/vulnerability-notification-rules.service';
import { SharedModule } from '../../../shared/shared.module';
import { VulnerabilityEmailNotificationSettingsStepComponent } from './components/wizard/vulnerability-email-notification-settings-step.component';
import { EmailNotificationsModule } from '../../../email-notifications/email-notifications.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { VulnerabilityNotificationRulePanelComponent } from './components/vulnerability-notification-rule.panel.component';
import { VulnerabilityNotificationRuleEntityTypeService } from './services/vulnerability-notification-rule-entity-type.service';

@NgModule({
	imports: [SharedModule, WcdWizardModule, EmailNotificationsModule],
	declarations: [
		VulnerabilityNotificationRuleWizardComponent,
		VulnerabilityEmailNotificationSettingsStepComponent,
		VulnerabilityNotificationRulePanelComponent,
	],
	providers: [VulnerabilityNotificationRulesService, VulnerabilityNotificationRuleEntityTypeService],
	exports: [VulnerabilityNotificationRulePanelComponent],
	entryComponents: [
		VulnerabilityNotificationRulePanelComponent,
		VulnerabilityNotificationRuleWizardComponent,
		VulnerabilityEmailNotificationSettingsStepComponent,
	],
})
export class VulnerabilityNotificationRuleEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(VulnerabilityNotificationRuleEntityTypeService);
	}
}
