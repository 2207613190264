import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { File } from './file.entity';

@ApiCall({
	name: 'Top file names',
	endpoint: (config, query) => {
		return `files/${query.where['fileIdentifier']}/topFileNames`;
	},
	method: 'GET',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.fileprevalence,
	parseQuery: (file: File): HttpOptions<Array<string>> => ({
		params: {
			fileIdentifier: file.id,
		},
	}),
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
	timeout: 2 * 60 * 1000, // This request takes a long time on average
})
export class FileTopFileNamesApiCall extends ApiCallModel<Array<string>, File> {}
