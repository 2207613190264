import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'configuration profile',
	pluralName: 'configuration profiles',
	readonly: true,
})
export class ConfigurationProfile extends ModelBase {
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: string;
	@EntityField() name: string;
}
