var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
var ProfileNameStepComponent = /** @class */ (function (_super) {
    __extends(ProfileNameStepComponent, _super);
    function ProfileNameStepComponent(i18n) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.MessageBarType = MessageBarType;
        _this.nameLength = 50;
        _this.descriptionLength = 300;
        _this.nameRegExp = 'notEmpty';
        return _this;
    }
    ProfileNameStepComponent.prototype.ngOnInit = function () {
        this.setStepValidation(true);
    };
    return ProfileNameStepComponent;
}(WizardBaseStep));
export { ProfileNameStepComponent };
