import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { Router, ActivatedRoute } from '@angular/router';
import {DateRangeModel, TzDateService} from '@wcd/localization';

const NumberOfUrlDecodingsLimit = 5;

type DecodableParams = 'range';

@Injectable()
export class UrlsService {
	readonly ParamsDecodingMap: Record<DecodableParams, () => any> = {
		range: this.findRangeFromRoute,
	};

	constructor(
		private featuresService: FeaturesService,
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private tzDateService: TzDateService,
	) {}

	getUrlLink(url: string, alertLastEventTime?: Date): string {
		const decodedUrl = this.getDecodedUrl(url);
		if (this.featuresService.isEnabled(Feature.UpgradeUrlPage)) {
			const route = ['urls', decodedUrl];
			if (alertLastEventTime) {
				route.push('timeline');
			}

			return this.router.serializeUrl(this.router.createUrlTree(route));
		}

		return this.getLegacyUrlLink(encodeURIComponent(encodeURIComponent(decodedUrl)), alertLastEventTime);
	}

	getLegacyUrlLink(url: string, alertLastEventTime: Date) {
		return `/url/${url}${alertLastEventTime ? '/' + alertLastEventTime.toISOString() : ''}`;
	}

	/**
	 * Temporary work-around for BE decoding issue in APIs where the url is part of the API url (for example /urls/<url>).
	 * encoding 3 times to avoid routing errors, and since url is decoded again when used as parameter in the api url.
	 * Related BE bug: 22276304.
	 */
	encodeUrlParamForBEApis(url: string): string {
		return encodeURIComponent(encodeURIComponent(encodeURIComponent(url)));
	}

	decodeUrlParams(params: DecodableParams[]): Partial<Record<DecodableParams, any>> {
		const decodedParams: Partial<Record<DecodableParams, any>> = {};
		params.forEach(param => {
			const callback = this.ParamsDecodingMap[param];
			if (callback) {
				decodedParams[param] = callback.bind(this)();
			}
		});
		return decodedParams;
	}

	private findRangeFromRoute(): DateRangeModel | string {
		const locationRange: string = this.activatedRoute.snapshot.queryParams.range;

		if (!locationRange) return null;

		const locationCustomRangeMatch = locationRange.match(/^(\d+)?:(\d+)?$/);

		if (locationCustomRangeMatch && locationCustomRangeMatch[1] && locationCustomRangeMatch[2]) {
			return new DateRangeModel(this.tzDateService,
				new Date(parseInt(locationCustomRangeMatch[1])),
				new Date(parseInt(locationCustomRangeMatch[2]))
			);
		}

		return locationRange;
	}

	getDecodedUrl(url: string) {
		let decodingCycleNumber = 0;
		while (url != decodeURIComponent(url)) {
			decodingCycleNumber++;
			url = decodeURIComponent(url);
			if (decodingCycleNumber > NumberOfUrlDecodingsLimit) {
				throw new Error('Url exceeded expected number of encodings');
			}
		}

		return url;
	}
}
