import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { FilesService } from '../../files/services/files.service';

export interface FormattedEmailAttachment {
	fileName: string;
	SHA256: string;
	malwareFamily: string;
}

export interface EmailAttachmentsPanelInput {
	attachments: Array<FormattedEmailAttachment>;
}

@Component({
	selector: 'email-attachments-details-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-attachments-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_emailMessage_attachments_title }}
					</h3>
				</div>
			</header>
			<div class="wcd-padding-medium-left">
				<wcd-datatable
					[items]="attachments"
					[columns]="tableFields"
					[selectEnabled]="false"
					[fixedTable]="true"
				>
				</wcd-datatable>
			</div>
		</wcd-panel>
	`,
})
export class EmailAttachmentsDetailsPanelComponent extends PanelContainer {
	@Input() attachments: Array<FormattedEmailAttachment>;

	tableFields: Array<DataTableField>;

	constructor(router: Router, public i18nService: I18nService, private filesService: FilesService) {
		super(router);
		this.tableFields = DataTableField.fromList<FormattedEmailAttachment>([
			{
				id: 'fileName',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_attachments_fileName,
				getTooltip: (emailAttachment: FormattedEmailAttachment) => emailAttachment.fileName,
			},
			{
				id: 'SHA256',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_attachments_sha256,
				getTooltip: (emailAttachment: FormattedEmailAttachment) => emailAttachment.SHA256,
				getLink: (emailAttachment: FormattedEmailAttachment) =>
					this.filesService.getFileLink(emailAttachment.SHA256),
			},
			{
				id: 'malwareFamily',
				name: this.i18nService.strings.airsEntities_emailMessage_fields_attachments_malwareFamily,
				getTooltip: (emailAttachment: FormattedEmailAttachment) => emailAttachment.malwareFamily,
			},
		]);
	}
}
