import {
	AadUser,
	AirsEmail, AirsEmailCluster,
	AirsEntity,
	AirsEntityHuntingQueryRequest,
	AirsEntityType,
	AirsFile, AirsIp, AirsProcess, AirsUrl, HuntingContext, Incident,
	Machine,
	Mailbox,
	File,
	Ip,
} from '@wcd/domain';

export type AirsEntityToHuntingRequestConversionFunction = (
	baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
	entity: EntityOrEvidenceType,
	options?: GoHuntActionOptions
) => AirsEntityHuntingQueryRequest;

export interface EvidenceIdToConversionMethod {
	[evidenceId: string]: AirsEntityToHuntingRequestConversionFunction;
}

export type EntityOrEvidenceType = AirsEntity | Mailbox | Machine | AadUser | File | Ip;

export type GoHuntActionOptions = {
	incident?: Incident;
	huntingContext?: HuntingContext;
};

export const DEVICE_ENTITY_TYPE_GO_HUNT_ID = -19;

export const HUNTING_ROUTE = 'hunting';

export class GoHuntQueryBuilder {

	public getAirsEntityHuntingRequest(
		entity: EntityOrEvidenceType,
		evidenceTypeId: AirsEntityType,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		const conversionMethod = this.entityTypeIdToConversionMethod[evidenceTypeId.toString()];
		if (!conversionMethod) {
			// entity id is not supported
			return null;
		}

		const baseHuntingQueryRequest: AirsEntityHuntingQueryRequest = {
			evidenceId: entity.id,
			evidenceTypeId: evidenceTypeId,
			actionTime: (entity as AirsEntity).firstSeen,
			incidentId: options && options.incident && options.incident.id,
			context: options && options.huntingContext,
		};

		return conversionMethod(baseHuntingQueryRequest, entity, options);
	}

	private convertFileEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsFile,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		return Object.assign(baseHuntingQueryRequest, {
			sha1: entity.sha1,
			sha256: entity.sha256,
			fileName: entity.name,
			machineId: entity.machine && entity.machine.id,
			actionTime: entity.createdTime,
		});
	}

	private convertMailboxEvidence = (
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		mailbox: Mailbox,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest => {
		if (!baseHuntingQueryRequest.incidentId) {
			// Mailbox go hunt is available only in context of incident
			return null;
		}

		return Object.assign(baseHuntingQueryRequest, {
			upn: mailbox.upn,
			mailboxPrimaryAddress: mailbox.mailboxPrimaryAddress,
		});
	};

	private convertMachineEvidence = (
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		machine: Machine,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest => {
		return Object.assign(baseHuntingQueryRequest, {
			deviceId: machine.senseMachineId,
			deviceName: machine.name,
			evidenceTypeId: DEVICE_ENTITY_TYPE_GO_HUNT_ID,
		});
	};

	private convertEmailEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsEmail,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		const receivedDate = entity.receivedDate;

		return Object.assign(baseHuntingQueryRequest, {
			senderEmailAddress: entity.sender,
			recipientEmailAddress: entity.recipient,
			networkMessageIds: [entity.networkMessageId],
			actionTime: receivedDate && receivedDate.toISOString(),
			emailSubject: entity.subject,
		});
	}

	private convertEmailClusterEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsEmailCluster,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		return Object.assign(baseHuntingQueryRequest, {
			networkMessageIds: entity.networkMessageIds,
		});
	}

	private convertUserEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AadUser,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		if (!baseHuntingQueryRequest.incidentId) {
			// User go hunt is available only in context of incident
			return null;
		}

		return Object.assign(baseHuntingQueryRequest, {
			aadUserId: entity.aadUserId,
			accountSid: entity.sid,
			accountName: entity.accountName,
		});
	}

	private convertUrlEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsUrl,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		return Object.assign(baseHuntingQueryRequest, {
			url: entity.address,
		});
	}

	private convertIpEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsIp,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		return Object.assign(baseHuntingQueryRequest, {
			ip: entity.address || entity.id,
		});
	}

	private convertProcessEvidence(
		baseHuntingQueryRequest: AirsEntityHuntingQueryRequest,
		entity: AirsProcess,
		options?: GoHuntActionOptions
	): AirsEntityHuntingQueryRequest {
		return Object.assign(baseHuntingQueryRequest, {
			actionTime: entity.created,
			processId: entity.processId,
			deviceId: entity.machine && entity.machine.machineId,
			deviceName: entity.machine && entity.machine.name,
			fileName: entity.name,
		});
	}


	private entityTypeIdToConversionMethod: EvidenceIdToConversionMethod = {
		[AirsEntityType.File]: this.convertFileEvidence,
		[AirsEntityType.MailMessage]: this.convertEmailEvidence,
		[AirsEntityType.SubmissionMail]: this.convertEmailEvidence,
		[AirsEntityType.Mailbox]: this.convertMailboxEvidence,
		[AirsEntityType.MailCluster]: this.convertEmailClusterEvidence,
		[DEVICE_ENTITY_TYPE_GO_HUNT_ID]: this.convertMachineEvidence,
		[AirsEntityType.User]: this.convertUserEvidence,
		[AirsEntityType.URL]: this.convertUrlEvidence,
		[AirsEntityType.IP]: this.convertIpEvidence,
		[AirsEntityType.Process]: this.convertProcessEvidence,
	};
}
