/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./confirmation-dialog-text-input-content.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../forms/src/lib/components/text-field.component.ngfactory";
import * as i5 from "../../../../../forms/src/lib/components/text-field.component";
import * as i6 from "./confirmation-dialog-text-input-content.component";
var styles_ConfirmationDialogTextInputContentComponent = [i0.styles];
var RenderType_ConfirmationDialogTextInputContentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ConfirmationDialogTextInputContentComponent, data: {} });
export { RenderType_ConfirmationDialogTextInputContentComponent as RenderType_ConfirmationDialogTextInputContentComponent };
export function View_ConfirmationDialogTextInputContentComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵeld(1, 0, null, null, 2, "wcd-text-field", [["className", "wcd-full-width"]], null, [[null, "textChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("textChange" === en)) {
        var pd_0 = ((_co.text = $event) !== false);
        ad = (pd_0 && ad);
    } if (("textChange" === en)) {
        var pd_1 = (_co.onValueChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_TextFieldComponent_0, i4.RenderType_TextFieldComponent)), i1.ɵdid(2, 4243456, null, 0, i5.TextFieldComponent, [], { text: [0, "text"], placeholder: [1, "placeholder"], label: [2, "label"], className: [3, "className"], required: [4, "required"] }, { textChange: "textChange" }), i1.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; var currVal_1 = _co.placeholder; var currVal_2 = i1.ɵunv(_v, 2, 2, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "hunting.saveQueryDialog.queryNameLabel")); var currVal_3 = "wcd-full-width"; var currVal_4 = _co.required; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_ConfirmationDialogTextInputContentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-confirmation-dialog-text-input-content", [], null, null, null, View_ConfirmationDialogTextInputContentComponent_0, RenderType_ConfirmationDialogTextInputContentComponent)), i1.ɵdid(1, 114688, null, 0, i6.ConfirmationDialogTextInputContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ConfirmationDialogTextInputContentComponentNgFactory = i1.ɵccf("wcd-confirmation-dialog-text-input-content", i6.ConfirmationDialogTextInputContentComponent, View_ConfirmationDialogTextInputContentComponent_Host_0, { text: "text", placeholder: "placeholder", label: "label", required: "required" }, {}, []);
export { ConfirmationDialogTextInputContentComponentNgFactory as ConfirmationDialogTextInputContentComponentNgFactory };
