import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmMcmSharedEntityConfigurations } from '../../mcmEndPointUtils';
import { ConfgiurationPreviewDetails } from './confgiuration-preview-details.entity';

@Entity({
	...TvmMcmSharedEntityConfigurations,
	singularName: 'Configuration',
	pluralName: 'Configurations',
	endpoint: 'baseline-profiles/configurationsByCategory',
    readonly: true,
	parseDataQuery: dataQuery => {
		const params: any = {
			benchmarkName: dataQuery.where["benchmarkName"],
			benchmarkVersion: dataQuery.where["benchmarkVersion"],
			complianceLevel: dataQuery.where["complianceLevel"]
		};

		if (dataQuery && dataQuery.where && dataQuery.where.search) {
			Object.assign(params, { search: dataQuery.where.search });
		}

		return params;
    }
})
export class BaselineConfigurationByCategory extends EntityModelBase<string> {
    @EntityField() readonly category: string;

    @EntityField() readonly count: number;

	@EntityField() readonly configurations: ConfgiurationPreviewDetails[];

	@EntityField({ data: 'category', parse: category => `category-${category}`})
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

    @EntityField() readonly title: string;

    @EntityField() readonly complianceLevels: string[];

    @EntityField() readonly scored?: boolean;
}
