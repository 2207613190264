import { Injectable } from '@angular/core';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { PendingActionHistory, RemediationActionType, RemediationActionTypeTypes } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import {
	PendingActionBase,
	RemediationActionsService,
} from '../../remediation/services/remediation-actions.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { ActionFinderService } from './pending-action-finder.service';
import { map, switchMap, take } from 'rxjs/operators';
import { combineLatest, from, Observable, of } from 'rxjs';
import { compact, flatMap, omit, uniqBy } from 'lodash-es';
import { PendingActionHistoryEntityPanelComponent } from '../components/pending-action-history.entity-panel.component';
import { ActionHistoryConverterService } from './action-history-converter.service';
import { AuthService } from '@wcd/auth';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { InvestigationsService } from '../../investigations/services/investigations.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { MtpInvestigationsService } from '../../mtp_investigations/services/mtp-investigations.service';

@Injectable()
export class PendingActionHistoryEntityTypeService implements EntityTypeService<PendingActionHistory> {
	private readonly noPermissionTooltip: string;

	constructor(
		private remediationActionsService: RemediationActionsService,
		private i18nService: I18nService,
		private actionFinderService: ActionFinderService,
		private authService: AuthService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService
	) {
		this.noPermissionTooltip = this.i18nService.strings.common_permissions_noPermissionTooltip;
	}

	readonly entityType: EntityType<PendingActionHistory> = {
		id: 'PendingActionHistory',
		entity: PendingActionHistory,
		icon: FabricIconNames.History,
		getIcon: (actions: Array<PendingActionHistory>) => {
			let actionType: RemediationActionType;
			if (actions && actions.length) {
				actionType = actions[0].actionType;
				if (actions.some(a => a.actionType !== actionType)) {
					actionType = null;
				}
			}
			return actionType ? actionType.icon : FabricIconNames.History;
		},
		singleEntityPanelComponentType: PendingActionHistoryEntityPanelComponent,
		getEntityName: (pendingAction: PendingActionHistory) =>
			pendingAction.actionType && pendingAction.actionType.name,
		loadFullEntityInPanel: false,
		getEntitiesLink: (pendingActions: Array<PendingActionHistory>) => {
			const investigationId = pendingActions[0].investigationId;
			if (!investigationId || pendingActions.some(a => a.investigationId !== investigationId)) {
				return;
			}

			return (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence)
				? MtpInvestigationsService
				: InvestigationsService
			).getInvestigationLink({
				isOfficeInvestigation: pendingActions[0].isOfficeInvestigation,
				externalInvestigationPageUrl: pendingActions[0].investigationDeeplink,
				isLiveResponse: pendingActions[0].isLiveResponse,
				id: investigationId,
			});
		},
		getUseExternalRouting: (actions: Array<PendingActionHistory>) =>
			!this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
			!!actions[0].investigationDeeplink,
		getEntitiesLinkText: () => this.i18nService.strings.actionHistory_panel_button_openInvestigationPage,
		getActions: (pendingActions: Array<PendingActionHistory>) => {
			const isSecAdmin = this.authService.currentUser.isSecAdmin;
			const isAllowed = isSecAdmin || pendingActions.every(a => !a.isOfficeInvestigation);

			const ignoreRbac =
				this.appContextService.isSCC && pendingActions.every(a => a.isOfficeInvestigation);
			let isAllowed$: Observable<boolean>;

			if (!isAllowed && this.appContextService.isSCC) {
				isAllowed$ = from(<Promise<boolean>>sccHostService.auth.isInRole(SccRoles.searchAndPurge));
			} else {
				isAllowed$ = of(isAllowed);
			}

			return isAllowed$.pipe(
				map(hasPermission =>
					[
						{
							id: 'approveRemediationAction',
							nameKey: 'remediationActions.approve',
							icon: 'checkCircle',
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_approve
								: this.noPermissionTooltip,
							method: (_pendingActions?: Array<PendingActionHistory>) => {
								return this.pendingActionToExtraActions(_pendingActions)
									.pipe(
										switchMap(actions =>
											this.remediationActionsService.approvePendingActions(
												this.mapActionsToPendingBase(actions)
											)
										)
									)
									.toPromise();
							},
							refreshOnResolve: true,
							allowRbacTooltipOverride: true,
							rbac: [RbacMdeAllowedActions.remediationActions],
							closeOnAction: true,
							disabled: !hasPermission,
						},
						{
							id: 'rejectRemediationAction',
							nameKey: 'remediationActions.dismiss',
							icon: 'block',
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_decline
								: this.noPermissionTooltip,
							method: (_pendingActions?: Array<PendingActionHistory>) => {
								return this.pendingActionToExtraActions(_pendingActions)
									.pipe(
										switchMap(actions =>
											this.remediationActionsService.confirmAndDismissPendingActionsByType(
												actions.map(a =>
													ActionHistoryConverterService.getRemediationActionFromActionHistory(
														a
													)
												)
											)
										)
									)
									.toPromise();
							},
							refreshOnResolve: true,
							allowRbacTooltipOverride: true,
							rbac: [RbacMdeAllowedActions.remediationActions],
							closeOnAction: confirmData => confirmData && confirmData.confirmed,
							disabled: !hasPermission,
						},
					].map(
						itemActionConfig =>
							new ItemActionModel(
								ignoreRbac
									? omit(itemActionConfig, 'rbac', 'allowRbacTooltipOverride')
									: itemActionConfig
							)
					)
				)
			);
		},
	};

	private pendingActionToExtraActions(
		pendingActions: Array<PendingActionHistory>
	): Observable<Array<PendingActionHistory>> {
		if (pendingActions.some(a => a.actionType.type !== RemediationActionTypeTypes.file_quarantine)) {
			return of(pendingActions);
		}
		return combineLatest(
			pendingActions.map(a => this.actionFinderService.getMoreActionsForFile(a).pipe(take(1)))
		).pipe(
			map(d => {
				const actions: Array<PendingActionHistory> = compact(flatMap(d));
				return uniqBy(actions.concat(pendingActions), 'id');
			})
		);
	}

	private mapActionsToPendingBase(actions: Array<PendingActionHistory>): Array<PendingActionBase> {
		return uniqBy(actions, 'id').map(a => ({
			id: a.id,
			remediationActionType: a.actionType,
			investigationId: a.investigationId,
		}));
	}
}
