import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { BaselineConfigurationByCategory } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { compact } from 'lodash-es';

type PartialDataviewFiled = Partial<DataviewField>;

interface FieldsData {
	expand: PartialDataviewFiled;
	name: PartialDataviewFiled;
	complianceLevel: PartialDataviewFiled;
}

@Injectable()
export class ConfigurationsByCategoryFieldsService {
	readonly FIELDS_DATA: FieldsData;
    private _fields: Array<DataviewField>;
	private readonly categoryIdPrefix: string = "category-";

	constructor(private i18nService: I18nService) {
		this.FIELDS_DATA = this.getAllFieldsData();
    }

    get fields(): Array<DataviewField<BaselineConfigurationByCategory>> {
		if (!this._fields) {
            const fieldsData = this.FIELDS_DATA;
			this._fields = DataviewField.fromList<BaselineConfigurationByCategory>(
				compact(<Array<DataviewFieldConfig>>[
					fieldsData.expand,
                    fieldsData.name,
					fieldsData.complianceLevel,
                ])
            );
		}
		return this._fields;
    }

    private getAllFieldsData(): FieldsData {
        return {
			expand: {
				id: 'configurationByCategoryExpand',
				name: '',
				enabledByDefault: true,
				alwaysDisplay: true,
				allowResize: false,
				getFieldCssClass: (item: BaselineConfigurationByCategory) => item.id.startsWith(this.categoryIdPrefix) ? 'datatable-expand' : '',
				sort: { enabled: false },
				isTabbale: true,
			},
			name: {
				id: 'configurationByCategoryName',
				name: this.i18nService.strings.baseline_profile_fields_name_title,
				getFieldCssClass: () => `datatable-group-item-indent-left title-field`,
				getDisplay: (item: BaselineConfigurationByCategory) =>
					item.id.startsWith(this.categoryIdPrefix) ? `${item.category} (${item.count})` : item.title,
				getTooltip: (item: BaselineConfigurationByCategory) =>
					item.id.startsWith(this.categoryIdPrefix) ? "" : item.title,
                sort: { enabled: false },
				alwaysDisplay: true,
                enabledByDefault: true
            },
			complianceLevel: {
				id: 'configurationByCategoryComplianceLevel',
				name: this.i18nService.strings.baseline_profile_fields_level_title,
				getDisplay: (item: BaselineConfigurationByCategory) =>
				{
                    if(item.id.startsWith(this.categoryIdPrefix))
                        return "";
                    return item.complianceLevels.length == 1 ? item.complianceLevels[0] :
                    `${item.complianceLevels[0]} +(${item.complianceLevels.length - 1} more)`
                },
				getTooltip: (item: BaselineConfigurationByCategory) => {
					if(item.id.startsWith(this.categoryIdPrefix) || item.complianceLevels.length == 1)
						return "";
					let tooltip = "<ul>"
					tooltip += item.complianceLevels.map(level => `<li>${level}</li>`).join("\n")
					tooltip += "</ul>"
					return tooltip
				},
				valueTooltipAllowHtmlRendering: true,
				sort: { enabled: false },
				alwaysDisplay: true,
                enabledByDefault: true
            }
        }
    }

}
