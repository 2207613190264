<entity-panel-collapsible-section
	*ngIf="repository"
	[collapsed]="initiallyCollapsed"
	[loaded]="loaded"
	[loadingText]="loadingText"
	[title]="title"
	[count]="itemsCount"
	[enableExport]="export?.observers?.length > 0"
	(export)="export.emit()"
	(onCollapseChange)="onCollapseChange($event)">
	<dataview
		class="wcd-padding-bottom"
		[hideTable]="hideTable"
		[repository]="repository"
		[navigateOnChange]="false"
		[ignoreQueryParams]="true"
		[giveSearchBoxMoreSpace]="true"
		[disableSelection]="true"
		[hideControls]="true"
		[allowFilters]="false"
		[defaultSortFieldId]="defaultSortFieldId"
		[fields]="fields"
		[sortDisabledFields]="sortDisabledFieldsIds"
		[padLeft]="false"
		[maxItems]="maxItems"
		[label]="dataviewLabel"
		[fixedTable]="true"
		[dataViewConfig]="dataViewConfig"
		(onData)="onData($event.data)"
		(onItemClick)="!disableSelection && onItemClick.emit($event.item)">
	</dataview>
	<a *ngIf="itemsCount > maxItems"
		role="button"
		tabIndex="0"
		data-track-id="OpenAllRelatedItems"
		data-track-type="Navigation"
		(keydown.enter)="showMore.emit()"
		(click)="showMore.emit()">{{'common.showMore' | i18n}}</a>
</entity-panel-collapsible-section>
