import { ModelBase, EntityField, ValueObject } from '@microsoft/paris';
import { HasSecureScore } from '../common/secure-score/has-secure-score.interface';

@ValueObject({
	singularName: 'Category secure score',
	pluralName: 'Categories secure scores',
	readonly: true,
})
export class CategorySecureScore extends ModelBase implements HasSecureScore {
	@EntityField({
		required: true,
	})
	readonly category: string;

	@EntityField({
		required: true,
		data: 'currentScore',
	})
	readonly secureScore: number;

	@EntityField({
		required: true,
		data: 'maxScore',
	})
	readonly maxSecureScore: number;
}
