var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { SoftwareVersion } from './software-version.entity';
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { SoftwareInstallationAgg } from '../software-installation/software-installation-agg-versions.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations, } from '../analyticsEndPointUtils';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
var ɵ0 = function (_, query) {
    return "products/product/productversions/" + encodeURIComponent(query.where['productVersions']) + "/installations";
}, ɵ1 = function (softwareVersion) { return ({
    productId: softwareVersion.productId,
    productVersions: softwareVersion.version,
}); };
var SoftwareVersionInstalledAssetsRelationship = /** @class */ (function () {
    function SoftwareVersionInstalledAssetsRelationship() {
    }
    SoftwareVersionInstalledAssetsRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SoftwareVersion, dataEntity: SoftwareInstallationAgg, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1 }))
    ], SoftwareVersionInstalledAssetsRelationship);
    return SoftwareVersionInstalledAssetsRelationship;
}());
export { SoftwareVersionInstalledAssetsRelationship };
var ɵ2 = function (_, query) {
    return "products/product/productversions/" + encodeURIComponent(query.where['productVersions']) + "/vulnerabilities";
}, ɵ3 = function (softwareVersion) { return ({
    productId: softwareVersion.productId,
    productVersions: softwareVersion.version,
}); };
var SoftwareVersionVulnerabilitiesRelationship = /** @class */ (function () {
    function SoftwareVersionVulnerabilitiesRelationship() {
    }
    SoftwareVersionVulnerabilitiesRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAnalyticsSharedEntityRelationshipConfigurations, { sourceEntity: SoftwareVersion, dataEntity: Vulnerability, endpoint: ɵ2, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ3 }))
    ], SoftwareVersionVulnerabilitiesRelationship);
    return SoftwareVersionVulnerabilitiesRelationship;
}());
export { SoftwareVersionVulnerabilitiesRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
