import { Application, ApplicationType } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { baseUrlsSettings, MCAS_HOST } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';


const buildAppLink = (app: Application): string => {
	const mcasBase = sccHostService.isSCC ? baseUrlsSettings.MCASPortalUrls : MCAS_HOST;
	return `${mcasBase}/${app.linkToAppPage}`;
}

@Injectable()
export class ApplicationEntityTypeService implements EntityTypeService<Application> {

	private getIcon(apps: Application[]): string {
		return apps && apps.length > 0 && apps[0].type === ApplicationType.OAuthApplication
			? FabricIconNames.AppIconDefault
			: FabricIconNames.Cloud;
	}

	private readonly _entityType = new Lazy<Readonly<EntityType<Application>>>(() => ({
		entity: Application,
		id: 'app',
		getEntityName: app => app.name,
		getIcon: (apps: Array<Application>) => this.getIcon(apps),
		entityPluralNameKey: 'application_entityType_pluralName',
		entitySingularNameKey: 'application_entityType_singularName',
		getEntitiesLink: (apps: Application[]) => {
			if (!apps || apps.length !== 1) {
				return null;
			}
			const [app] = apps;
			return buildAppLink(app);
		},
		getNavigationModel: (app: Application) => ({
			externalLink: buildAppLink(app),
		}),
		getUseExternalRouting: (apps: Array<Application>) => true,
	}));

	get entityType(): Readonly<EntityType<Application>> {
		return this._entityType.value;
	}
}
