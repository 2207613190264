import { ApiCall, ApiCallModel, ParisConfig } from '@microsoft/paris';
import { omit } from 'lodash-es';

@ApiCall({
	name: 'Machine timeline prefetch',
	separateArrayParams: true,
	method: 'POST',
	endpoint: (_config, query) => {
		return `${query.where['entityType']}/${query.where['entityId']}/prefetch`;
	},
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.detectionDeviceTimeline,
	parseQuery: dataQuery => {
		if (!dataQuery) return {};

		return {
			params: Object.assign(
				{},
				omit(dataQuery.where as object, [
					'page',
					'page_size',
					'ordering',
				])
			)
		};
	},
})
export class MachineTimelinePrefetchApiCall extends ApiCallModel<void, any> {}
