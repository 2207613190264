import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { toObservable } from '../../utils/rxjs/utils';
import { take, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ErrorsDialogService } from '../../dialogs/services/errors-dialog.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { isMachineExportResponse, MachineExportResponse } from "../../@entities/machines/services/machines.service";

export interface DownloadOptions {
	params?: HttpParams | { [param: string]: string | string[] };
	isAuthenticated?: boolean;
	downloadedFileName?: string;
	headers?:
		| HttpHeaders
		| {
				[header: string]: string | string[];
		  };
}

@Injectable()
export class DownloadService {
	/**
	 * iframe element to be used for download files (instead of open a new tab for it)
	 */
	private _iframe: HTMLIFrameElement;

	constructor(
		private http: HttpClient,
		private errorsDialogService: ErrorsDialogService,
		private i18nService: I18nService,
		private dialogsService: DialogsService
	) {}

	download<T extends Blob>(blob$: Observable<T>, filename?: string): Promise<T> {
		return blob$
			.pipe(
				take(1),
				tap((data: T) => {
					// TODO: remove file-saver once we drop support for IE, and then use an anchor tag with
					// a 'download' property (HTML5 standard)
					FileSaver.saveAs(data, filename);
				})
			)
			.toPromise();
	}

	async downloadFromUrl(
		url: string | Observable<string> | PromiseLike<string>,
		{ params, isAuthenticated = false, downloadedFileName, headers }: DownloadOptions = {},
		showSnackbar: boolean = false
	): Promise<void> {
		if (showSnackbar) {
			this.dialogsService.showSnackbar({
				text: this.i18nService.get('grid_commands_exportToExcel_waitForDownload'),
				icon: 'Info',
			});
		}

		let urlStr: string = await toObservable(url)
			.pipe(take(1))
			.toPromise();
		if (isAuthenticated) {
			const downloadParams: string = this.getDownloadParams(params);
			urlStr = urlStr + (downloadParams ? '?' + downloadParams : '');
			this.downloadToIframe(urlStr);
		} else {
			// strip path and params from url to create a human-readable file name
			const urlParts: Array<string> = urlStr.split('/'),
				filename = downloadedFileName
					? downloadedFileName
					: urlParts[urlParts.length - 1].replace(/(\w+)\?.*/, '$1');
			await this.download(
				this.http.get(urlStr, {
					responseType: 'blob',
					headers: headers,
					params: params,
				}),
				filename
			);
		}
	}

	downloadFromResponseUri(urlStr: string): Promise<any> {
		return new Promise((resolve, reject) => {
			this.http.get(urlStr).subscribe(
				(response: string | MachineExportResponse) => {
					try {
						const downloadUrl = isMachineExportResponse(response) ? response.sasUri : response;
						this.downloadToIframe(downloadUrl);
						resolve(response);
					} catch (error) {
						reject(error);
					}
				},
				(error: any) => {
					if (error.status == 413) {
						this.errorsDialogService.showError({
							title: this.i18nService.strings.dataview_export_data_failed_title,
							data: this.i18nService.strings.dataview_export_data_failed_response_too_large,
						});
					} else {
						this.errorsDialogService.showError({
							title: this.i18nService.strings.dataview_export_data_failed_title,
							data: this.i18nService.strings.dataview_export_data_failed,
						});
					}

					reject(error);
				}
			);
		});
	}

	private downloadToIframe(urlStr: string) {
		try {
			if (!this._iframe) {
				this._iframe = document.createElement('iframe');
				this._iframe.style.display = 'none';
				document.body.appendChild(this._iframe);
			}
			// if url is authenticate (i.e with a token), open the link in a new tab and let the browser handle the download
			this._iframe.contentWindow.location.replace(urlStr);
		} catch (e) {
			if (this._iframe) {
				this._iframe.contentWindow.close();
			}

			throw e;
		}
	}

	dumpJsonFromUrlToNewTab(url: string): Observable<void> {
		return this.dumpJsonToNewTab(this.http.get(url));
	}

	dumpJsonToNewTab(data$: Observable<any>): Observable<void> {
		return new Observable<void>(observer => {
			const newTab: Window = window.open('', '_blank');
			newTab.document.write('Loading...');
			data$.subscribe(
				data => {
					newTab.document.open();
					newTab.document.write('<html><body><pre></pre></body></html>');
					const pre: HTMLElement = newTab.document.querySelector('pre');
					pre.innerText = JSON.stringify(data, null, '\t');
					newTab.document.close();
					newTab.focus();
					observer.next();
					observer.complete();
				},
				err => {
					newTab.close();
					observer.error(err);
				}
			);
		});
	}

	getDownloadParams(options: object): string {
		if (!options) return '';

		const params: Array<string> = [];
		for (const prop in options) {
			let val = options[prop];
			if (!(val instanceof Array)) {
				val = [val];
			}
			(<Array<any>>val).forEach(p => {
				params.push(`${prop}=${encodeURIComponent(p)}`);
			});
		}

		const paramsStr: string = params.join('&');
		return paramsStr || '';
	}
}
