<!-- tslint:disable:template-accessibility-alt-text -->
<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="!shouldHideBanner()" class="wcd-convergence-banner">
	<div class="wcd-flex-horizontal wcd-margin-small-bottom">
		<div class="wcd-flex-center-all  wcd-padding-small-all">
			<img src='/assets/images/promotion/promotion-banner-widget.svg' />
		</div>
		<div class='wcd-flex-vertical  wcd-padding-small-all'>
			<h2 class="wcd-font-weight-bold wcd-margin-xsmall-top wcd-margin-small-bottom wcd-font-size-xl">
				{{ title }}
			</h2>
			<div class="wcd-font-size-s">
				<markdown class="wcd-convergence-markdown" [data]="desc"></markdown>
			</div>

			<div class="wcd-padding-small-top wcd-flex-center-vertical wcd-flex-wrap-horizontal ">
				<div class="wcd-padding-medium-right wcd-flex-none">
					<fab-primary-button
						data-track-id="PromotionBannerExploreClick"
						data-track-type="Button"
						[href]='navigateTo()'
						target='_blank'>
						<wcd-shared-icon [iconName]="openSccIcon" class='wcd-padding-small-right'> </wcd-shared-icon>
						{{ buttonTitle }}
					</fab-primary-button>
				</div>
				<a *ngIf="!isL2Enabled"
					data-track-id="PromotionBannerDismissClick"
					data-track-type="Button"
					class='wcd-flex-none wcd-padding-small-vertical'
					(click)="close()">
					{{ 'promotion.action.remindme' | i18n }}
				</a>
			</div>
		</div>

		<button
			class="btn-icon-only btn-unstyled wcd-font-size-xs"
			data-track-id="PromotionBannerCloseClick"
			data-track-type="Button"
			(click)="close()">
			<wcd-shared-icon iconName="close"> </wcd-shared-icon>
		</button>
	</div>
</div>
