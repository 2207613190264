import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Investigation } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { EntityPanelWithAlertComponent } from '../../../global_entities/components/entity-panels/entity-panel-with-alert.component.base';
import { MachinesService } from '../../machines/services/machines.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { AlertEntityTypeService } from '../../alerts/services/alert.entity-type.service';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { I18nService } from '@wcd/i18n';

enum CollapsibleID {
	Details = 'investigation-entity-panel-details',
	TriggeringAlertDetails = 'investigation-entity-panel-triggering-alert-details',
	TriggeringAlertDescription = 'investigation-entity-panel-triggering-alert-description',
	TriggeringAlertRecommendedAction = 'investigation-entity-panel-triggering-alert-recommended-action',
	relatedMachine = 'investigation-entity-panel-related-machine',
}

@Component({
	selector: 'investigation-entity-panel',
	template: `
		<wcd-collapsible-section
			[sectionId]="collapsibleID.Details"
			[label]="i18nService.strings.investigation_panel_details_title"
		>
			<investigation-entity-details [investigation]="investigation"></investigation-entity-details>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			[sectionId]="collapsibleID.TriggeringAlertDetails"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_details"
			*ngIf="investigation.alert"
		>
			<div *ngIf="alert">
				<alert-entity-details [entity]="alert" [showTitle]="false"></alert-entity-details>
				<route-link
					*ngIf="alertEntityTypeService.entityType.getNavigationModel(alert) as alertRoute"
					[routeLink]="alertRoute"
				>
					{{ i18nService.strings.investigations_panel_openAlertPage }}
				</route-link>
			</div>
			<div *ngIf="isLoadingAlert">
				<i class="loader-icon"></i> {{ i18nService.strings.investigations_panel_loadingAlert }}
			</div>
			<a
				*ngIf="investigation.totalAlertsCount > 1"
				data-track-id="AlertsRelatedLink"
				data-track-type="Navigation"
				[routerLink]="['/investigation', investigation.id, 'alerts']"
			>
				{{
					'investigations_panel_relatedAlertCount'
						| i18n: { alertCount: investigation.totalAlertsCount }
				}}
			</a>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			*ngIf="investigation.alert && alert && alert.description"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_description"
			[sectionId]="collapsibleID.TriggeringAlertDescription"
		>
			<alerted-description
				[description]="alert.description"
			>
			</alerted-description>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			*ngIf="investigation.alert && alert && alert.recommendedAction"
			[label]="i18nService.strings.investigation_panel_triggeringAlert_recommendedActions"
			[sectionId]="collapsibleID.TriggeringAlertRecommendedAction"
		>
			<alerted-description
				[recommendedAction]="alert.recommendedAction"
			>
			</alerted-description>
		</wcd-collapsible-section>
		<wcd-collapsible-section
			[sectionId]="collapsibleID.relatedMachine"
			[label]="
				investigation.machines.length > 1
					? ('investigation_panel_relatedMachine_title_plural'
					  | i18n: { deviceCount: investigation.machines.length })
					: i18nService.strings.investigation_panel_relatedMachine_title_singular
			"
			*ngIf="
				(investigation.machines.length === 1 && machine) ||
				isLoadingMachine ||
				investigation.machines.length > 1
			"
			(isExpandedChange)="$event && updateAlertsTable()"
		>
			<ng-container>
				<ng-container *ngIf="investigation.machines.length === 1; else multipleMachines">
					<ng-container *ngIf="machine || isLoadingMachine">
						<span *ngIf="isLoadingMachine; else machineDetails">
							<i class="loader-icon"></i>
							{{ i18nService.strings.investigation_panel_loadingMachineDetails_title }}
						</span>
						<ng-template #machineDetails>
							<machine-entity-details
								#machineEntityCmp
								*ngIf="machine"
								[machine]="machine"
								[alert]="alert"
								(loggedOnUsersClick)="showLoggedOnUsers()"
								[includeMachineName]="true"
								[collapsibleSections]="false"
							></machine-entity-details>
						</ng-template>
					</ng-container>
				</ng-container>
			</ng-container>
		</wcd-collapsible-section>

		<ng-template #multipleMachines>
			<ng-container *ngIf="investigation.machines.length">
				<investigated-machines-table
					[investigatedMachines]="investigation.machines"
				></investigated-machines-table>
			</ng-container>
		</ng-template>
	`,
})
export class InvestigationEntityPanelComponent extends EntityPanelWithAlertComponent<Investigation> {
	get investigation(): Investigation {
		return this.entity;
	}

	collapsibleID = CollapsibleID;

	@ViewChild('machineEntityCmp', { static: false }) machineEntityCmp: MachineEntityDetailsComponent;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		rbacService: RbacService,
		machinesService: MachinesService,
		private alertEntityTypeService: AlertEntityTypeService,
		public i18nService: I18nService
	) {
		super(changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService);
	}

	// Update table when expanding collapsible - fixes table UI not aligned on expansion
	updateAlertsTable() {
		if (this.machineEntityCmp) {
			this.machineEntityCmp.updateTableView();
		}
	}

	initEntity(investigation: Investigation, isExtendedData: boolean = false) {
		super.initEntity(investigation, isExtendedData);

		if (investigation && isExtendedData) {
			if (investigation.machines.length === 1) this.setMachine(investigation.machines[0].machineId);

			if (investigation.alert) this.setAlert(investigation.alert.id);
		}
	}
}
