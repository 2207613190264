/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/indication-icons/indication-icons.component.ngfactory";
import * as i2 from "../../../shared/components/indication-icons/indication-icons.component";
import * as i3 from "./tvm-threat-icons.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_TvmThreatIconsComponent = [];
var RenderType_TvmThreatIconsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmThreatIconsComponent, data: {} });
export { RenderType_TvmThreatIconsComponent as RenderType_TvmThreatIconsComponent };
export function View_TvmThreatIconsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "wcd-indication-icons", [], [[1, "aria-label", 0]], null, null, i1.View_WcdIndicationIconsComponent_0, i1.RenderType_WcdIndicationIconsComponent)), i0.ɵdid(2, 573440, null, 0, i2.WcdIndicationIconsComponent, [], { indicationIcons: [0, "indicationIcons"], allowTooltipHTMLRender: [1, "allowTooltipHTMLRender"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co._indicationIcons; var currVal_2 = _co._allowTooltipHTMLRender; _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.label; _ck(_v, 1, 0, currVal_0); }); }
export function View_TvmThreatIconsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-threat-icons", [], null, null, null, View_TvmThreatIconsComponent_0, RenderType_TvmThreatIconsComponent)), i0.ɵdid(1, 49152, null, 0, i3.TvmThreatIconsComponent, [i4.IconsService, i5.I18nService], null, null)], null, null); }
var TvmThreatIconsComponentNgFactory = i0.ɵccf("tvm-threat-icons", i3.TvmThreatIconsComponent, View_TvmThreatIconsComponent_Host_0, { config: "config" }, {}, []);
export { TvmThreatIconsComponentNgFactory as TvmThreatIconsComponentNgFactory };
