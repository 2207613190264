import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import { EmailNotificationFormData, KeyValue } from './email-notification-model';
import { MachineGroup, NotificationRuleServiceSourceConditionValue } from '@wcd/domain';
import { DISPLAY_MODE as DETAILS_DISPLAY_MODE } from '../../shared/components/details-list/details-list.component';

@Component({
	selector: 'email-notification-summary',
	templateUrl: './email-notification-summary.component.html',
})
export class EmailNotificationSummaryComponent<T extends EmailNotificationFormData>
	implements OnInit, OnDestroy {
	@Input() data: T;
	/*
		Settings are different between different types of notifications.
		There are some common settings (name, description, device groups), and the rest depend on notification type.
		For example, Severity is a setting which is relevant only for single type of notifications,
		therefore a KeyValue ["severity", data.severity] will be included in its customSettings.
	*/
	@Input() customSettings: (data: T) => KeyValue[];

	/*
	 Settings related to inner rules - each rule has services sources and severities
	 */

	@Input() rulesSettings: (data: T) => {'serviceSources': NotificationRuleServiceSourceConditionValue[], 'severitiesText': string}[];
	/*
		Notification settings are general preferences of the user.
		For example, "show organization name in mail"
	*/
	@Input() notificationSettings: (data: T) => string[];
	@Input() shouldDisplayNotificationSettings: (data: T) => boolean;

	/*
		Not all notifications types have the notion of RBAC currently.
	*/
	@Input() shouldDisplayDeviceGroups = false;

	/*
		Supports service sources settings if enabled.
	 */
	@Input() isSettingsV2: boolean = false;

	/*
		Some types of notifications include status field.
		If status is defined, then the status column is displayed near the rule name
	*/
	@Input() status: (data: T) => string;

	/*
		In case of global notification, if explicitGlobalNotificationText == true, show 'Any device',
		otherwise - show 'All (number of device groups)'
	*/
	@Input() explicitGlobalNotificationText: boolean;

	/*
		Links to the previous steps
	*/
	@Input() goToStep: (step: number)=> void;

	dlDisplayMode: DETAILS_DISPLAY_MODE = DETAILS_DISPLAY_MODE.GRID;
	private machineSubscription: Subscription;
	machineDisplayText: string;
	customSettingsForData: { key: string; value: string }[];
	notificationSettingsForData: string[];
	shouldDisplayNotificationSettingsForData: boolean;
	statusForData: string;
	recipientsList: string[];
	rulesList: {'serviceSource': string, 'detectionSource': string, 'severity': string}[] = [];
	additionalRecipientsCount: number;
	additionalRecipientsText: string;
	editTitle: string;
	private rules: {'serviceSources': NotificationRuleServiceSourceConditionValue[], 'severitiesText': string}[];
	constructor(public i18n: I18nService, private paris: Paris) {}

	ngOnInit() {
		this.handleMachineDisplayText();
		this.customSettingsForData = this.customSettings ? this.customSettings(this.data) : [];
		this.notificationSettingsForData = this.notificationSettings
			? this.notificationSettings(this.data)
			: [];
		this.shouldDisplayNotificationSettingsForData = this.shouldDisplayNotificationSettings
			? this.shouldDisplayNotificationSettings(this.data)
			: false;
		this.statusForData = this.status ? this.status(this.data) : undefined;
		this.additionalRecipientsCount =
			this.data.recipients.length > 5 ? this.data.recipients.length - 5 : 0;
		this.recipientsList =
			this.additionalRecipientsCount > 0 ? this.data.recipients.slice(0, 5) : this.data.recipients;
		this.rules = this.rulesSettings ? this.rulesSettings(this.data) : [];
		this.rules.forEach(rule =>
		{
			rule.serviceSources.forEach(serviceSource => {
				const serviceSourceText = serviceSource.name;
				if (serviceSource.children && serviceSource.children.length > 0) {
					serviceSource.children.forEach(detectionSource => {
						const detectionSourceText = detectionSource.name;
						this.rulesList.push(
							{'serviceSource': serviceSourceText, 'detectionSource': detectionSourceText, 'severity': rule.severitiesText});
					})
				}
				else {
					this.rulesList.push(
						{
							'serviceSource': serviceSourceText,
							'detectionSource': '-',
							'severity': rule.severitiesText
						});
				}
			})
		});
		this.additionalRecipientsText = this.i18n.get('tvm_common_moreItems', {
			count: this.additionalRecipientsCount,
		});
		this.editTitle = this.i18n.strings.email_notification_wizard_edit;
	}

	ngOnDestroy() {
		this.machineSubscription && this.machineSubscription.unsubscribe();
	}

	private handleMachineDisplayText() {
		if (this.data.allRbacGroups) {
			if (this.explicitGlobalNotificationText) {
				this.machineDisplayText = this.i18n.strings.notifications_scope_global;
			} else {
				this.machineSubscription = this.paris
					.getRepository(MachineGroup)
					.allItems$.subscribe((groups: Array<MachineGroup>) => {
						this.machineDisplayText = `${this.i18n.strings.common_all} (${groups.length})`;
					});
			}
		} else if (this.data.machineGroups.length === 0) {
			this.machineDisplayText = this.i18n.get('notificationRules.table.noDeviceGroups');
		} else if (this.data.machineGroups.length > 1) {
			this.machineDisplayText = this.i18n.get('notificationRules.table.manyDeviceGroups', {
				length: this.data.machineGroups.length,
			});
		} else {
			this.machineDisplayText = `1 ${this.i18n.strings.common_group}`;
		}
	}
}
