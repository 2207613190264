import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Injector,
	ChangeDetectorRef,
	OnInit,
	OnChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { Asset, ComparisonResultSingularEntity } from '@wcd/domain';
import { DataSet } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsComponentBase } from '../../../../../app/global_entities/components/entity-details/entity-details.component.base';
import { TvmContextOptions } from '../../vulnerabilities/components/vulnerability.entity-panel.component';
import { DownloadService } from '../../../../../app/shared/services/download.service';

@Component({
	selector: 'comparison-result-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './comparison-result.entity-details.component.html',
})
export class ComparisonResultEntityDetailsComponent
	extends EntityDetailsComponentBase<ComparisonResultSingularEntity>
	implements OnChanges {
	@Input() contextOptions: TvmContextOptions;

	@Input() comparisonResult: ComparisonResultSingularEntity;

	isShowMore = false;
	openByMachineNameNewTab = true;
	includeOperatingSystem = false;

	intersectionAssets$: Observable<DataSet<Asset>>;
	tvmAssets$: Observable<DataSet<Asset>>;
	competitorAssets$: Observable<DataSet<Asset>>;

	constructor(
		private i18nService: I18nService,
		private downloadService: DownloadService,
		injector: Injector
	) {
		super(injector);
	}

	ngOnChanges() {
		if (this.contextOptions && this.contextOptions.isSingleAsset) {
			return;
		}

		this.tvmAssets$ = this.getExposedAssetsObserveable(this.comparisonResult.machinesDetectedOnlyByTvm);
		this.intersectionAssets$ = this.getExposedAssetsObserveable(
			this.comparisonResult.intersectionDetectedMachines
		);
		this.competitorAssets$ = this.getExposedAssetsObserveable(
			this.comparisonResult.machinesDetectedOnlyByOther
		);
	}

	exportExposedTvmMachines() {
		return this.exportExposedMachines(
			this.comparisonResult.machinesDetectedOnlyByTvm,
			this.i18nService.get(`tvm.common.exposedTvmMachines`)
		);
	}

	exportExposedOtherMachines() {
		return this.exportExposedMachines(
			this.comparisonResult.machinesDetectedOnlyByOther,
			this.i18nService.get(`tvm.common.exposedCompetitorMachines`)
		);
	}

	exportExposedIntersectionMachines() {
		return this.exportExposedMachines(
			this.comparisonResult.intersectionDetectedMachines,
			this.i18nService.get(`tvm.common.exposedIntersectionMachines`)
		);
	}

	private exportExposedMachines(machinesList: string[], fileName: string) {
		const blob = new Blob([this.getMachinesCsvStr(machinesList)], { type: 'text/csv;charset=utf-8;' });
		return this.downloadService.download(of(blob), fileName + '.csv');
	}

	private getExposedAssetsObserveable(machineList: string[]) {
		return of({
			count: machineList.length,
			items: machineList.map(
				machineDnsName =>
					new Asset({ id: machineDnsName, name: this.getNameFromDnsName(machineDnsName) })
			),
		});
	}

	private getNameFromDnsName(dnsName: string) {
		return dnsName.includes('.') ? dnsName.split('.')[0] : dnsName;
	}

	private getMachinesCsvStr(dnsNames: string[]) {
		const colName = 'Devices names\n';
		return colName + dnsNames.join('\n');
	}
}
