/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bars-chart.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../icons/src/lib/components/wcd-icon.component.ngfactory";
import * as i3 from "../../../../icons/src/lib/components/wcd-icon.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i6 from "../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../i18n/src/lib/services/i18n.service";
import * as i10 from "./bars-chart.component";
import * as i11 from "../../../../prettify/src/lib/services/pretty-number.service";
var styles_BarsChartComponent = [i0.styles];
var RenderType_BarsChartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BarsChartComponent, data: {} });
export { RenderType_BarsChartComponent as RenderType_BarsChartComponent };
function View_BarsChartComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "bars-chart-image"]], [[1, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.image; _ck(_v, 0, 0, currVal_0); }); }
function View_BarsChartComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-icon", [["class", "bars-chart-icon"]], null, null, null, i2.View_WcdIconComponent_0, i2.RenderType_WcdIconComponent)), i1.ɵdid(1, 49152, null, 0, i3.WcdIconComponent, [i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null)], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_BarsChartComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 21, "li", [["role", "button"], ["tabIndex", "0"]], [[2, "with-icon", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.select.emit(_v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarsChartComponent_2)), i1.ɵdid(3, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarsChartComponent_3)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "label", [], [[1, "data-object-type", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 9).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 147456, null, 0, i5.TooltipDirective, [i1.ElementRef, i6.TooltipsService, i7.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 8, "div", [["class", "bars-chart-bar"]], [[2, "outer-value", null]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 16).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 16).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 16).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 16).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i4.ɵNgStyleImpl, i4.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(14, 278528, null, 0, i4.NgStyle, [i4.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(15, { width: 0, background: 1 }), i1.ɵdid(16, 147456, null, 0, i5.TooltipDirective, [i1.ElementRef, i6.TooltipsService, i7.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(18, 0, null, null, 1, "span", [["class", "bars-chart-value"], ["tabIndex", "0"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵted(19, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_1 = _v.context.$implicit.image; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.icon; _ck(_v, 6, 0, currVal_2); var currVal_4 = _v.context.$implicit.itemNameTooltip; _ck(_v, 9, 0, currVal_4); var currVal_7 = _ck(_v, 15, 0, ((_v.context.$implicit.relativeSize * 100) + "%"), _v.context.$implicit.color); _ck(_v, 14, 0, currVal_7); var currVal_8 = _v.context.$implicit.tooltip; _ck(_v, 16, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.image || _v.context.$implicit.icon); _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.settings.objectType; _ck(_v, 8, 0, currVal_3); var currVal_5 = _v.context.$implicit.name; _ck(_v, 10, 0, currVal_5); var currVal_6 = (_v.context.$implicit.isOuterText || ((_co.settings == null) ? null : _co.settings.outerValues)); _ck(_v, 12, 0, currVal_6); var currVal_9 = _v.context.$implicit.valueDisplay; _ck(_v, 18, 0, currVal_9); var currVal_10 = _v.context.$implicit.valueDisplay; _ck(_v, 19, 0, currVal_10); }); }
function View_BarsChartComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "wcd-icon", [], [[4, "color", null]], null, null, i2.View_WcdIconComponent_0, i2.RenderType_WcdIconComponent)), i1.ɵdid(3, 49152, null, 0, i3.WcdIconComponent, [i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(4, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"]))], function (_ck, _v) { var currVal_1 = "square"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.color; _ck(_v, 2, 0, currVal_0); var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_BarsChartComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "ul", [["class", "bars-chart-legend"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarsChartComponent_5)), i1.ɵdid(3, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings.legend; var currVal_1 = _co.trackById; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_BarsChartComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 11, "div", [["class", "bars-chart"], ["tabIndex", "0"]], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 4, "ul", [["tabIndex", "0"]], [[2, "clickable", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarsChartComponent_1)), i1.ɵdid(8, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"], ngForTrackBy: [1, "ngForTrackBy"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BarsChartComponent_4)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.items; var currVal_3 = _co.trackById; _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_4 = _co.settings.legend; _ck(_v, 12, 0, currVal_4); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v, 0), "charts.barsChart")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.settings.isSelectable; _ck(_v, 5, 0, currVal_1); }); }
export function View_BarsChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-bars-chart", [], null, null, null, View_BarsChartComponent_0, RenderType_BarsChartComponent)), i1.ɵdid(1, 4833280, null, 0, i10.BarsChartComponent, [i1.ElementRef, i1.ChangeDetectorRef, i11.PrettyNumberService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BarsChartComponentNgFactory = i1.ɵccf("wcd-bars-chart", i10.BarsChartComponent, View_BarsChartComponent_Host_0, { settings: "settings", data: "data" }, { select: "select" }, []);
export { BarsChartComponentNgFactory as BarsChartComponentNgFactory };
