import { Injectable } from '@angular/core';
import { SystemExclusion, SystemExclusionListType, SystemExclusionType } from '@wcd/domain';

@Injectable()
export class AirsEntityTypesService {
	entitySystemExclusionPropertyMapping: any = {
		filename: entity => {
			const filenameMatch = entity.path && entity.path.match(/[\\\/]?([^\\\/]+)$/);

			return filenameMatch ? filenameMatch[1] : null;
		},
		file_hash: entity => {
			return entity.sha1;
		},
		product_vendor: entity => {
			return entity.productVendor || null;
		},
		product_name: entity => {
			return entity.productName || null;
		},
		product_version: entity => {
			return entity.productVersion || null;
		},
		ip: entity => {
			return entity.address;
		},
	};

	getSystemExclusionRule(
		entity,
		systemExclusionType: SystemExclusionType,
		systemExclusionListType: SystemExclusionListType
	): SystemExclusion {
		if (!entity || !systemExclusionType)
			throw new Error(
				'Error creating SystemExclusion from entity, both entity and systemExclusionType are required.'
			);

		const properties = {};

		systemExclusionType.properties.forEach(property => {
			const mapping = this.entitySystemExclusionPropertyMapping[property.id];
			properties[property.id] = mapping ? mapping(entity) : entity[property.id] || null;
		});

		return new SystemExclusion({
			id: undefined,
			creationTime: new Date(),
			creatorName: '',
			listType: systemExclusionListType,
			properties: properties,
			entityId: entity.id,
		});
	}
}
