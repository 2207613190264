var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
var RelatedEvidenceCount = /** @class */ (function (_super) {
    __extends(RelatedEvidenceCount, _super);
    function RelatedEvidenceCount() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Mailbox' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "mailboxes", void 0);
    __decorate([
        EntityField({ data: 'Url' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "urls", void 0);
    __decorate([
        EntityField({ data: 'User' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "users", void 0);
    __decorate([
        EntityField({ data: 'File' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "files", void 0);
    __decorate([
        EntityField({ data: 'Machine' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "machines", void 0);
    __decorate([
        EntityField({ data: 'Ip' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "ips", void 0);
    __decorate([
        EntityField({ data: 'Process' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "processes", void 0);
    __decorate([
        EntityField({ data: 'MailCluster' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "mailClusters", void 0);
    __decorate([
        EntityField({ data: 'MailMessage' }),
        __metadata("design:type", Number)
    ], RelatedEvidenceCount.prototype, "mailMessages", void 0);
    RelatedEvidenceCount = __decorate([
        ValueObject({
            singularName: 'Related Evidence',
            pluralName: 'Related Evidences',
            readonly: true,
        })
    ], RelatedEvidenceCount);
    return RelatedEvidenceCount;
}(ModelBase));
export { RelatedEvidenceCount };
