import { Injectable, ComponentFactoryResolver } from "@angular/core";
import { Paris, Repository } from '@microsoft/paris';
import { BaselineProfileDetails, BaselineProfileForSaving, ComplianceOverTimeApiCall, DeviceComplianceStatisticsApiCall, ProfileFiltersFields, ProfileFiltersFieldsApiCall } from "@wcd/domain";
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { ProfileWizardComponent } from "../../components/profile-wizard/profile-wizard.component";
import { ProfileForCreationFormData } from "../models/profile-for-creation.model";
import { Observable } from 'rxjs';
import { PanelType } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { map } from "rxjs/operators";
import { ActivatedEntity } from "../../../../../global_entities/services/activated-entity.service";
import { Router } from "@angular/router";

@Injectable()
export class ProfilesService {
	repo: Repository<BaselineProfileForSaving>;
	private _profileFilters: Record<string, any>;

	constructor(
		private dialogsService: DialogsService,
		private componentFactoryResolver: ComponentFactoryResolver,
		private i18nService: I18nService,
		private activatedEntity: ActivatedEntity,
		private router: Router,
		private paris: Paris
	) {
		this.saveBaselineProfile = this.saveBaselineProfile.bind(this);
		this.repo = paris.getRepository(BaselineProfileForSaving);
	}

	showWizardPanel(baselineProfileDetails?: BaselineProfileDetails): Promise<any> {
		let profileForCreationFormData;
		const isUpdateFlow = !!baselineProfileDetails;
		profileForCreationFormData = isUpdateFlow
			? this.convertBaselineProfileDetailsToFormData(
				baselineProfileDetails,
				profileForCreationFormData
			)
			: new ProfileForCreationFormData();
		return new Promise((resolve, reject) => {
			this.dialogsService.showPanel(
				ProfileWizardComponent,
				{
					id: 'profile-wizard',
					type: PanelType.wizard,
					hasCloseButton: false,
					noBodyPadding: true,
				},
				{
					onDone: resolve,
					onClose: resolve,
					data: profileForCreationFormData,
					isUpdateFlow,
				},
				this.componentFactoryResolver
			);
		});
	}

	convertBaselineProfileDetailsToFormData(
		baselineProfileDetails: BaselineProfileDetails,
		profileForCreationFormData: ProfileForCreationFormData) {
		profileForCreationFormData = new ProfileForCreationFormData({
			id: baselineProfileDetails.id,
			name: baselineProfileDetails.name,
			description: baselineProfileDetails.description,
			isEnabled: baselineProfileDetails.status,
			operatingSystem: baselineProfileDetails.operatingSystem,
			operatingSystemVersion: baselineProfileDetails.operatingSystemVersion,
			benchmark: baselineProfileDetails.benchmark,
			benchmarkVersion: baselineProfileDetails.version,
			checks: baselineProfileDetails.checks,
			complianceLevel: baselineProfileDetails.complianceLevel,
			eTag: baselineProfileDetails.eTag,
			isUpdateFlow: true,
			selectedSettings: []
		});
		return profileForCreationFormData;
	}

	convertBaselineProfileDetailsToSaveModel(baselineProfileDetails: BaselineProfileDetails): BaselineProfileForSaving {
		return new BaselineProfileForSaving({
			id: baselineProfileDetails.id,
			name: baselineProfileDetails.name,
			description: baselineProfileDetails.description,
			operatingSystem: baselineProfileDetails.operatingSystem,
			operatingSystemVersion: baselineProfileDetails.operatingSystemVersion,
			benchmark: baselineProfileDetails.benchmark,
			benchmarkVersion: baselineProfileDetails.version,
			complianceLevel: baselineProfileDetails.complianceLevel,
			isEnabled: baselineProfileDetails.status,
			checks: baselineProfileDetails.checks,
			eTag: baselineProfileDetails.eTag
		});
	}

	saveBaselineProfile(baselineProfileData: Partial<ProfileForCreationFormData>): Observable<BaselineProfileForSaving> {
		const itemToSave: any = {
			name: baselineProfileData.name,
			description: baselineProfileData.description,
			isEnabled: baselineProfileData.isEnabled,
			operatingSystem: baselineProfileData.operatingSystem,
			operatingSystemVersion: baselineProfileData.operatingSystemVersion,
			benchmark: baselineProfileData.benchmark,
			benchmarkVersion: baselineProfileData.benchmarkVersion,
			complianceLevel: baselineProfileData.complianceLevel,
			checks: baselineProfileData.checks,
			eTag: baselineProfileData.eTag,
		};

		itemToSave.id = baselineProfileData.id;
		const saveObservable$ = this.repo.save(itemToSave);

		this.paris.clearApiCallCache([
			ComplianceOverTimeApiCall,
			DeviceComplianceStatisticsApiCall
		]);

		return saveObservable$;
	}

	deleteProfile(profile: BaselineProfileDetails): Promise<BaselineProfileForSaving> {
		const profileToDelete = this.convertBaselineProfileDetailsToSaveModel(profile);
		const deleteObservable$ = this.repo.removeItem(profileToDelete, {
			data: { eTag: profile.eTag },
		});

		this.paris.clearApiCallCache([
			ComplianceOverTimeApiCall,
			DeviceComplianceStatisticsApiCall
		]);

		return deleteObservable$.toPromise();
	}

	updateBaselineProfile(profile: BaselineProfileDetails): Promise<BaselineProfileForSaving> {
		const profileToUpdate = this.convertBaselineProfileDetailsToSaveModel(profile);

		const saveObservable$ = this.repo.save(profileToUpdate);

		this.paris.clearApiCallCache([
			ComplianceOverTimeApiCall,
			DeviceComplianceStatisticsApiCall
		]);

		return saveObservable$.toPromise();
	}

	getFilters(): Observable<Record<string, any>> {
		if (!this._profileFilters) {
			this._profileFilters = {
				profileStatus: {
					count: null,
					values: [
						{
							value: true,
							name: this.i18nService.strings.tvm_baseline_profiles_enabled,
							priority: 1
						},
						{
							value: false,
							name: this.i18nService.strings.tvm_baseline_profiles_disabled,
							priority: 2
						}
					]
				}
			}
		}

		return this.paris
			.apiCall<ProfileFiltersFields[]>(ProfileFiltersFieldsApiCall)
			.pipe(map(filtersFields => {
				const dynamicFilters: Record<string, any> = {}
				filtersFields.forEach(filterField => {
					dynamicFilters[filterField.name] = {
						values: Object.values(filterField.filters).map(filter => new Object({
							value: filter,
							name: filter.split("-_-").join(" ")
						})),
					};
				})
				return { ...dynamicFilters, ...this._profileFilters }
			}));
	}

	navigateToProfilePage(profile: BaselineProfileDetails) {
		this.activatedEntity.setNextEntity(profile);
		return this.router.navigate([`/baseline-compliance_t/profiles/${encodeURIComponent(profile.id)}`]);
	}
}
