import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Investigation, InvestigationPendingType, InvestigationStatus, Machine, Service } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { Prettify } from '../../../utils/services/prettify.service';
import { AppConfigService } from '@wcd/app-config';
import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistWithSearchComponent,
	FilterValuesDatePickerComponent,
	ListFilterValueConfig,
} from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { map } from 'rxjs/operators';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { InvestigationEntityTypeService } from './investigation.entity-type.service';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class InvestigationsFields {
	private _fields: Array<DataviewField>;

	constructor(
		private paris: Paris,
		private appConfigService: AppConfigService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private iconsService: IconsService,
		private investigationEntityTypeService: InvestigationEntityTypeService,
		private i18nService: I18nService
	) {}

	get fields(): Array<DataviewField> {
		if (!this._fields) {
			this._fields = DataviewField.fromList([
				{
					id: 'names',
					name: this.i18nService.strings.investigations_fields_triggeringAlert,
					enabledByDefault: true,
					sort: { enabled: false },
					className: EntityNameComponent.entityNameDefaultCssClass,
					component: {
						type: EntityNameComponent,
						getProps: (investigationInput: Investigation) => {
							return {
								entity: investigationInput,
								entityTypeId: this.investigationEntityTypeService.entityType.id,
							};
						},
					},
					fluidWidth: 0.6,
				},
				{
					id: 'id',
					name: this.i18nService.strings.investigations_fields_investigationId,
					className: 'small-cell',
					enabledByDefault: !this.appConfigService.isDemoTenant,
				},
				{
					id: 'status',
					name: this.i18nService.strings.investigations_fields_investigationStatus,
					enabledByDefault: true,
					className: 'auto-size nowrap',
					getDisplay: (investigation: Investigation) =>
						investigation.isPending ? investigation.pendingType.name : investigation.status.name,
					icon: {
						wcdIcon: (investigation: Investigation) =>
							investigation.status.iconName &&
							WcdIconNames[this.iconsService.getIcon(investigation.status.iconName).name],
						className: (investigation: Investigation) =>
							`color-text-${investigation.status.className}`,
					},
					getImage: (investigation: Investigation) => investigation.status.image,
					sort: { enabled: false },
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (statusId: string): ListFilterValueConfig<number> => {
									const statusIdInt = parseInt(statusId, 10);
									if (isNaN(statusIdInt)) return null;

									const status: InvestigationStatus = this.paris.getValue(
										InvestigationStatus,
										statusIdInt
									);
									if (status) {
										return {
											icon: {
												wcdIconName: this.iconsService.getIcon(status.iconName).name,
												className: `color-text-${status.className}`,
											},
											image: status.image,
											name: status.name,
											id: status.id,
										};
									}

									return null;
								},
							},
						},
					},
					custom: {
						allowFilterValueTracking: true,
					},
				},
				{
					id: 'detection_system',
					name: this.i18nService.strings.investigations_fields_detectionSource,
					enabledByDefault: true,
					className: 'nowrap',
					getDisplay: (investigation: Investigation) => investigation.detectionSourceName,
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (value) => {
									const detectionSourceId: number = parseInt(value, 10);
									if (isNaN(detectionSourceId)) {
										return {
											id: value,
											name: 'Unknown',
										};
									}

									return this.paris.getItemById(Service, detectionSourceId).pipe(
										map((detectionSource: Service) => {
											return detectionSource
												? {
														name: detectionSource.name,
														id: detectionSource.id,
												  }
												: {
														id: value,
														name: 'Unknown',
												  };
										})
									);
								},
							},
						},
					},
					custom: {
						allowFilterValueTracking: true,
					},
				},
				{
					id: 'tags',
					name: this.i18nService.strings.investigations_fields_tags,
					enabledByDefault: true,
					listValues: {
						path: 'tags',
						field: {
							id: 'tag',
							name: 'Tag',
							getDisplay: (listItem) => listItem.name,
							className: 'tag',
						},
					},
				},
				{
					id: 'hosts',
					name: this.i18nService.strings.investigations_fields_entities,
					enabledByDefault: true,
					className: 'nowrap wcd-text-overflow-medium',
					getDisplay: (investigation: Investigation) =>
						investigation.machines.length
							? investigation.machines.length === 1
								? investigation.machines[0].name.toLowerCase()
								: this.i18nService.get('investigations_fields_deviceCount', {
										deviceCount: investigation.machines.length,
								  })
							: null,
					icon: {
						fabricIcon: (investigation: Investigation) =>
							investigation.machines.length
								? this.globalEntityTypesService.getEntityTypeIcon(Machine)
								: null,
					},
					getLink: (investigation: Investigation) => {
						if (investigation.machines.length === 1) {
							const id = investigation.machines[0].machineId;
							this.globalEntityTypesService.getEntityLink(Machine, <Partial<Machine>>{
								name: id,
								senseMachineId: id,
							});
						}
						return null;
					},
					filter: {
						component: {
							type: FilterValuesChecklistWithSearchComponent,
							config: {
								mapFilterValue: (machineName) => {
									return (
										machineName && {
											id: machineName,
											name: machineName.split('.')[0],
										}
									);
								},
							},
						},
					},
				},
				{
					id: 'start_date',
					name: this.i18nService.strings.investigations_fields_startDate,
					enabledByDefault: true,
					className: 'nowrap',
					sort: {
						sortDescendingByDefault: true,
					},
					filter: {
						component: {
							type: FilterValuesDatePickerComponent,
						},
					},
					component: {
						type: TzDateComponent,
						getProps: (investigation: Investigation) => ({ date: investigation.startDate }),
					},
				},
				{
					id: 'duration',
					name: this.i18nService.strings.investigations_fields_duration,
					enabledByDefault: true,
					getDisplay: (investigation: Investigation) => Prettify.prettyTime(investigation.duration),
					className: 'auto-size',
				},
				{
					id: 'error',
					name: this.i18nService.strings.investigations_fields_errorDescription,
					enabledByDefault: false,
					className: 'color-text-error',
					getDisplay: (investigation: Investigation) => investigation.errorDescription,
					sort: { enabled: false },
				},
				{
					id: 'reasons',
					name: this.i18nService.strings.investigations_fields_statusDetails,
					enabledByDefault: false,
					className: 'full-width-cell',
					listValues: {
						path: 'reasons',
						withBullets: true,
					},
					sort: { enabled: false },
				},
				{
					id: 'canceledBy',
					name: this.i18nService.strings.investigations_fields_canceledBy,
					enabledByDefault: false,
					getDisplay: (investigation: Investigation) =>
						investigation.canceledByUser ? investigation.canceledByUser.name : null,
					sort: { enabled: false },
				},
				{
					id: 'lastAction',
					name: this.i18nService.strings.investigations_fields_lastAction,
					enabledByDefault: false,
					fluidWidth: 0.4,
					getDisplay: (investigation: Investigation) =>
						investigation.lastAction ? investigation.lastAction.details : null,
					sort: { enabled: false },
				},
				{
					id: 'host_groups',
					name: this.i18nService.strings.investigations_fields_deviceGroups,
					enabledByDefault: false,
					className: 'auto-size',
					getDisplay: (investigation: Investigation) => investigation.hostGroups.join(', '),
					sort: { enabled: false },
				},
				{
					id: 'remediated_actions',
					name: this.i18nService.strings.investigations_fields_remediationActions,
					enabledByDefault: false,
					getDisplay: (investigation: Investigation) =>
						investigation.actionsSummary.remediated || '',
					className: 'no-wrap auto-size',
					getTooltip: (investigation: Investigation) =>
						this.i18nService.get('investigations_fields_remediationActionsTooltip', {
							remediationActionCount: investigation.actionsSummary.remediated,
							totalActionCount: investigation.actionsSummary.total,
						}),
					getCssClass: (investigation: Investigation) =>
						investigation.actionsSummary.remediated ? 'bold color-text-success' : null,
					sort: {
						sortDescendingByDefault: true,
					},
				},
				{
					id: 'pending_type',
					name: this.i18nService.strings.investigations_fields_pendingType,
					enabledByDefault: false,
					getDisplay: (investigation: Investigation) =>
						investigation.pendingType && investigation.pendingType.name,
					className: 'no-wrap',
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (
									pendingTypeId
								): { image?: string; id: number | string; name: string } => {
									const pendingType: InvestigationPendingType = this.paris.getValue(
										InvestigationPendingType,
										parseInt(pendingTypeId, 10)
									);

									if (pendingType) {
										return {
											name: pendingType.name,
											id: pendingType.id,
										};
									}

									return {
										id: pendingTypeId,
										name: 'Unknown',
									};
								},
							},
						},
					},
				},
				{
					id: 'comments',
					name: this.i18nService.strings.investigations_fields_comments,
					enabledByDefault: false,
					getDisplay: (investigation: Investigation) => investigation.commentCount,
					filter: {
						component: {
							type: FilterValuesChecklistComponent,
							config: {
								mapFilterValue: (
									hasComments: boolean
								): { id: number | string; name: string } => ({
									name: hasComments
										? this.i18nService.strings.investigations_fields_comments_yes
										: this.i18nService.strings.investigations_fields_comments_no,
									id: String(hasComments),
								}),
							},
						},
					},
					sort: {
						sortDescendingByDefault: true,
					},
				},
			]);
		}

		return this._fields;
	}
}
