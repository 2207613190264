import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Alert } from '../alert/alert.entity';
import { ActionBaseEntity } from '..';

@ValueObject({
	singularName: 'Incident timeline entity',
	pluralName: '',
	readonly: true,
})
export class IncidentTimelineEntity extends ModelBase {
	@EntityField({
		data: 'Alerts',
		arrayOf: Alert,
	})
	alerts: Array<Alert>;

	@EntityField({
		data: 'ResponseActions',
		arrayOf: ActionBaseEntity,
	})
	responseActions: Array<ActionBaseEntity>;
}

@ValueObject({
	singularName: 'Incident timeline device',
	pluralName: '',
	readonly: true,
})
export class IncidentTimelineMachine extends IncidentTimelineEntity {
	@EntityField({ data: 'Device' }) machine: Machine;
}

@ValueObject({
	singularName: 'Incident timeline user',
	pluralName: '',
	readonly: true,
})
export class IncidentTimelineUser extends IncidentTimelineEntity {
	@EntityField({ data: 'User' }) user: AadUser;
}

@ValueObject({
	singularName: 'Incident timeline response',
	pluralName: '',
	readonly: true,
})
export class IncidentTimelineResponse extends ModelBase {
	@EntityField({
		data: 'DeviceTimelineList',
		arrayOf: IncidentTimelineMachine,
	})
	incidentTimelineMachines: Array<IncidentTimelineMachine>;

	@EntityField({
		data: 'UserTimelineList',
		arrayOf: IncidentTimelineUser,
	})
	incidentTimelineUsers: Array<IncidentTimelineUser>;
}
