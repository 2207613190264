var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AuthService, MtpPermission } from '@wcd/auth';
import { AppContextService, Feature, FeaturesService, PreferencesService } from '@wcd/config';
import { AlertClassification, AlertDetermination, Incident, IncidentStatus, IncidentStatusValueIds, incidentStatusValues, IncidentsUpdateApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { breadcrumbsStateService } from '@wcd/shared';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { AssigneePickerComponent } from 'app/forms/components/assignee-picker.component';
import { escapeRegExp } from 'lodash-es';
import { MessageBarType } from 'office-ui-fabric-react';
import { of, zip } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { combinedClassifications, getCombinedClassificationMap } from '../../alerts/services/alerts.service';
import { IncidentsService } from '../services/incidents.service';
var RESOLVED_INCIDENT_STATUS_ID = IncidentStatusValueIds.IncidentResolvedStatusId;
var KEY_CODES = {
    13: 'ENTER',
    27: 'ESCAPE',
    38: 'UP',
    40: 'DOWN',
    9: 'TAB',
};
var lastId = 0;
var IncidentManagePanelComponent = /** @class */ (function (_super) {
    __extends(IncidentManagePanelComponent, _super);
    function IncidentManagePanelComponent(i18nService, router, route, paris, incidentsService, dialogsService, authService, telemetryService, changeDetection, rbacControlService, appContextService, preferencesService, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.route = route;
        _this.paris = paris;
        _this.incidentsService = incidentsService;
        _this.dialogsService = dialogsService;
        _this.authService = authService;
        _this.telemetryService = telemetryService;
        _this.changeDetection = changeDetection;
        _this.rbacControlService = rbacControlService;
        _this.appContextService = appContextService;
        _this.preferencesService = preferencesService;
        _this.featuresService = featuresService;
        _this.NOT_SET = '0';
        _this.isSaving = false;
        _this.assignedTo = {};
        _this.MessageBarType = MessageBarType;
        _this.tagPickerInputProps = {
            onKeyPress: function (e) {
                // do not allow !,@,-,+ in the start of the tag name
                if (/[!@\-+]/.test(e.key) && e.target.value === '' && !KEY_CODES[e.nativeEvent.keyCode]) {
                    e.preventDefault();
                }
            },
            'aria-label': _this.i18nService.strings.incident_commandBar_actions_manageIncident_tags,
        };
        _this.isReadOnly = false;
        _this.isAssignToSomeoneElseEnabled = false;
        _this.isCombinedClassificationEnabled = false;
        _this.combinedClassificationMap = [];
        _this.incidentStatusOptions = [];
        _this.onSelectAssignee = function (assignee) {
            _this.assignedTo = assignee;
            _this.changeDetection.detectChanges();
        };
        _this.updateRouteSnapshot = function () {
            var route = _this.route.snapshot;
            while (!!route) {
                var breadcrumbs = route.data['breadcrumbs'];
                if (!!breadcrumbs && breadcrumbs.length > 0) {
                    route.data['breadcrumbs'] = breadcrumbsStateService.breadcrumbs$.value;
                }
                route = route.firstChild;
            }
        };
        _this.onFilterChanged = function (filterText, tagList) {
            var termRegExp = new RegExp(escapeRegExp(filterText), 'i');
            var selectedTags = tagList.reduce(function (r, t) {
                var _a;
                return (__assign({}, r, (_a = {}, _a[t.name.toLowerCase()] = 1, _a)));
            }, {});
            var matchedUserDefinedTags = _this.incidentUserDefinedTags
                .filter(function (tag) { return !selectedTags[tag.name.toLowerCase()] && termRegExp.test(tag.name); })
                .sort(function (a, b) { return (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1); })
                .slice(0, 10) || [];
            var exactMatchingTags = matchedUserDefinedTags.filter(function (tag) { return tag.name.toLowerCase() === filterText.toLowerCase(); });
            if (exactMatchingTags.length === 0 && !selectedTags[filterText.toLowerCase()]) {
                return matchedUserDefinedTags.concat([{ key: filterText, name: filterText, isNewItem: true }]);
            }
            return matchedUserDefinedTags;
        };
        _this.id = String(++lastId);
        _this.currentUserName = authService.currentUser.username;
        _this.isAssignToSomeoneElseEnabled = _this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
        _this.isCombinedClassificationEnabled = _this.featuresService.isEnabled(Feature.CombinedClassification);
        _this.isInProgressIncidentStatusEnabled = _this.featuresService.isEnabled(Feature.InProgressIncidentStatus);
        _this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(function (value) { return _this.isCombinedClassificationEnabled || !value.combinedClassification; });
        _this.selectableAlertClassifications = paris.getRepository(AlertClassification).entity.values.filter(function (value) { return _this.isCombinedClassificationEnabled || !value.combinedClassification; });
        _this.combinedClassificationMap = getCombinedClassificationMap(_this.i18nService);
        _this.incidentStatusOptions = incidentsService.getIncidentStatusDropdownValues(_this.i18nService);
        _this.selectedCombinedClassification = _this.combinedClassificationMap[0].key;
        _this.tagsPickerSuggestionsProps = {
            suggestionsHeaderText: _this.i18nService.get('incident.commandBar.actions.manageIncident.suggestedTagsHeaderText'),
            noResultsFoundText: _this.i18nService.get('incident.commandBar.actions.manageIncident.noResultsFoundText'),
        };
        return _this;
    }
    IncidentManagePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.incident) {
            return;
        }
        this.incidentClassification = this.incident.classification;
        this.currentStatus = this.incident.status.id;
        this.selectedStatus = this.incident.status.type;
        this.determination = this.incident.determination;
        var foundClassification = this.incident.determination ?
            this.combinedClassificationMap.find(function (classification) { return classification.key === _this.incident.determination.type; })
            : this.combinedClassificationMap[0];
        this.selectedCombinedClassification = foundClassification && foundClassification.key;
        this.tags = this.incident.tags.map(function (tag) { return ({ name: tag, key: tag }); });
        this.assignedToMe = this.incident.assignedTo === this.currentUserName;
        if (this.isAssignToSomeoneElseEnabled) {
            this.initAssignedTo();
        }
        else {
            this.assignedToMail = this.incident.assignedTo;
        }
        this.incidentName = this.incident.name;
        var rbacSettings = {
            mtpPermissions: [MtpPermission.SecurityData_Manage],
            mtpWorkloads: this.incident.mtpWorkloads,
            requireAllPermissions: true,
            ignoreNonActiveWorkloads: true,
        };
        this.isReadOnly = !this.incident.isFullyMachineRbacExposed || !this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
        this.tagsSubscriptions = this.incidentsService.getAllUserDefinedIncidentTags().subscribe(function (tags) {
            return (_this.incidentUserDefinedTags = tags.map(function (tag) { return ({
                name: tag.name,
                key: tag.name,
                id: tag.name,
            }); }));
        });
        this.resolveIncident = this.incident.status.id === RESOLVED_INCIDENT_STATUS_ID;
    };
    IncidentManagePanelComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this.tagsSubscriptions.unsubscribe();
    };
    IncidentManagePanelComponent.prototype.saveIncident = function () {
        var _this = this;
        this.isSaving = true;
        var previousAssignee = this.incident.assignedTo;
        var newAssignee = this.isAssignToSomeoneElseEnabled ? (previousAssignee === null && (!this.assignedTo || !this.assignedTo.secondaryText)) ? null : this.assignedTo && this.assignedTo.secondaryText
            : (previousAssignee === null && !this.assignedToMail) ? null : this.assignedToMail;
        zip(this.hasTagsChanged(this.incident.tags, this.tags)
            ? this.incidentsService.updateIncidentTags([this.incident], this.tags)
            : of(null), !!this.incidentName && this.incidentName !== this.incident.name
            ? this.paris.apiCall(IncidentsUpdateApiCall, {
                title: this.incidentName,
                incidentIds: [this.incident.id],
            })
            : of(null), this.paris
            .apiCall(IncidentsUpdateApiCall, {
            incidentIds: [this.incident.id],
            determination: this.determination,
            assignedTo: newAssignee,
            classification: this.incidentClassification,
            status: this.currentStatus !== this.incident.status.id
                ? new IncidentStatus({
                    id: this.currentStatus,
                })
                : undefined,
            comment: this.comment,
        })
            .pipe(mergeMap(function () { return _this.paris.getItemById(Incident, _this.incident.id); }))).subscribe(function () {
            _this.trackResolveReactivate(_this.currentStatus);
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('incident.commandBar.actions.manageIncident.incidentUpdateSuccess'),
                icon: 'tag',
            });
            _this.incidentsService.clearCachedIncidents();
            _this.incidentsService.clearAllUserDefinedIncidentTags();
            if (_this.incidentName !== _this.incident.name) {
                var lastBreadcrumb = breadcrumbsStateService.breadcrumbs$.value[breadcrumbsStateService.breadcrumbs$.value.length - 1];
                lastBreadcrumb.label = _this.incidentName;
                breadcrumbsStateService.add(lastBreadcrumb);
                _this.updateRouteSnapshot();
            }
            _this.isAssignToSomeoneElseEnabled && AssigneePickerComponent.updateUserPreferredAssignees(_this.assignedTo, _this.preferencesService);
            _this.destroy();
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('incident.commandBar.actions.manageIncident.incidentUpdateError'),
                data: error,
            });
        });
    };
    IncidentManagePanelComponent.prototype.onNewClassificationDropdownChange = function (event) {
        this.selectedCombinedClassification = event.option.key;
        var _a = combinedClassifications[event.option.key], classification = _a.classification, determination = _a.determination;
        this.incidentClassification = this.selectableAlertClassifications.find(function (alertClass) { return alertClass.id === classification; });
        this.determination = this.allAlertDeterminations.find(function (alertDet) { return alertDet.id === determination; });
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.incidentStatusChange = function (event) {
        this.currentStatus = incidentStatusValues.filter(function (status) { return status.type === event.option.key; })[0].id;
        this.selectedStatus = incidentStatusValues.filter(function (status) { return status.type === event.option.key; })[0].type;
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.initAssignedTo = function () {
        this.assignedTo = this.incident.assignedTo ? {
            secondaryText: this.incident.assignedTo
        } : null;
    };
    IncidentManagePanelComponent.prototype.onAssignToMeChanges = function (newVal) {
        this.assignedToMe = newVal;
        if (newVal) {
            this.assignedToMail = this.currentUserName;
        }
        else if (!newVal && this.incident.assignedTo === this.currentUserName) {
            this.assignedToMail = '';
        }
        else {
            this.assignedToMail = this.incident.assignedTo;
        }
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.resolveIncidentChanges = function (newVal) {
        this.resolveIncident = newVal;
        this.currentStatus = newVal ? 2 : 1;
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.incidentClassificationChanges = function (newVal) {
        this.incidentClassification = newVal;
        if (newVal.id === this.NOT_SET) {
            this.resolveIncident = false;
            this.currentStatus = 1;
        }
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.determinationChanges = function (newVal) {
        this.determination = newVal;
        this.changeDetection.detectChanges();
    };
    IncidentManagePanelComponent.prototype.hasTagsChanged = function (originalTags, newTags) {
        if (originalTags.length === newTags.length) {
            var originalTagsMap_1 = originalTags.reduce(function (r, t) {
                var _a;
                return (__assign({}, r, (_a = {}, _a[t.toLowerCase()] = 1, _a)));
            }, {});
            return newTags.reduce(function (r, tag) { return r || !originalTagsMap_1[tag.name.toLowerCase()]; }, false);
        }
        return true;
    };
    IncidentManagePanelComponent.prototype.onTagsChange = function (ev) {
        this.tags = ev.items;
    };
    IncidentManagePanelComponent.prototype.trackResolveReactivate = function (currentStatus) {
        if (currentStatus !== this.incident.status.id) {
            this.telemetryService.trackEvent({
                type: TrackingEventType.Button,
                id: incidentStatusValues.filter(function (status) { return status.id === currentStatus; })[0].type,
                component: 'incident-manage side panel',
                componentType: 'Side Panel',
            });
        }
    };
    return IncidentManagePanelComponent;
}(PanelContainer));
export { IncidentManagePanelComponent };
