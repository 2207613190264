import { SevilleModule } from '../../seville/seville.module';

SevilleModule.factory('progressiveTimelineLoader', progressiveTimelineLoader);

progressiveTimelineLoader.$inject = ['$log'];

function progressiveTimelineLoader($log) {
	var getNewStartDate = function(telemetryToDate) {
		var newStartDate = new Date(telemetryToDate.getTime());
		var nowUtc = new Date().getTime();
		var diffSinceNowInHours = Math.abs(nowUtc - newStartDate.valueOf()) / 3600000;

		var daysToSubstractFromToDate = diffSinceNowInHours < 6 ? 2 : 1; // if toDate - 1 day is only up to 6 hours ago, reduce 2 days from to date - to make sure enough data is queried.

		newStartDate.setDate(telemetryToDate.getDate() - daysToSubstractFromToDate);
		return newStartDate;
	};

	var mergeResults = function(existingEvents, newResults, getRecordIdFunc) {
		var existingEventsIds = [];
		if (existingEvents.length > 0) {
			for (var i = 0; i < existingEvents.length; i++) {
				existingEventsIds.push(getRecordIdFunc(existingEvents[i]));
			}
		}

		var newEvents = [];
		newEvents = existingEvents.slice();

		for (var i = 0; i < newResults.length; i++) {
			var record = newResults[i];

			if (existingEventsIds.indexOf(getRecordIdFunc(record)) === -1) {
				newEvents.push(record);
			}
		}

		return newEvents;
	};

	var service = {
		loadTimelineProgressively: function(
			loadEventsFunc,
			telemetryFromDate,
			telemetryToDate,
			pageSize,
			vmEventsSetter,
			getRecordIdFunc
		) {
			var newStartDate = getNewStartDate(telemetryToDate);
			var fromDateOverride = telemetryFromDate;
			var timeframeOptimizationApplied = false;
			if (telemetryFromDate.getTime() < newStartDate.getTime()) {
				fromDateOverride = newStartDate;
				timeframeOptimizationApplied = true;
			}

			var fromDate = fromDateOverride ? fromDateOverride : telemetryFromDate;
			loadEventsFunc(fromDate, telemetryToDate)
				.then(function(firstQueryEvents) {
					var partialResultsLoaded =
						timeframeOptimizationApplied && pageSize > firstQueryEvents.length;
					vmEventsSetter(firstQueryEvents, partialResultsLoaded);
					return {
						events: firstQueryEvents,
						partialResultsLoaded: partialResultsLoaded,
					};
				})
				.then(function(firstQueryResults) {
					var firstQueryEvents = firstQueryResults.events;
					if (firstQueryResults.partialResultsLoaded) {
						var newEndDate = new Date(fromDateOverride.getTime());
						newEndDate.setMilliseconds(newEndDate.getMilliseconds() - 1);
						$log.debug('starting to load additional ' + pageSize + ' events');
						loadEventsFunc(telemetryFromDate, newEndDate).then(function(secondQueryEvents) {
							var mergedEvents = mergeResults(
								firstQueryEvents,
								secondQueryEvents,
								getRecordIdFunc
							);
							vmEventsSetter(mergedEvents);
						});
					}
				});
		},
	};

	return service;
}
