import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import {
	Incident,
	AssociatedIncidentResponse,
	IncidentAssociatedIncidentsApiCall,
	RelatedIncident,
} from '@wcd/domain';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AssociatedIncidentsFields } from '../incident.associated-incidents.fields';

@Component({
	providers: [AssociatedIncidentsFields],
	selector: 'associated-incidents-panel',
	templateUrl: './associated-incidents-panel.component.html',
})
export class AssociatedIncidentsPanelComponent extends PanelContainer implements OnInit {
	_originalIncident: Incident;
	@Input()
	set sourceValue(incident: Incident) {
		this._originalIncident = incident;
	}
	associatedIncidents$: Observable<RelatedIncident[]>;

	constructor(
		private readonly paris: Paris,
		protected router: Router,
		public associatedIncidentsFields: AssociatedIncidentsFields
	) {
		super(router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.associatedIncidents$ = this.paris
			.apiCall(IncidentAssociatedIncidentsApiCall, { id: this._originalIncident.id } as any)
			.pipe(map((result: AssociatedIncidentResponse) => result.relatedIncidents));
	}
}
