import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Save user preferences',
	endpoint: 'api/ine/userpreferencesservice/userPreference',
	method: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userPreferences,
	parseQuery: (options: SaveUserPreferencesOptions) => {
		return {
			data: {
				[options.key]: options.preferences,
			},
		};
	},
})
export class SaveUserPreferencesApiCall extends ApiCallModel<null, SaveUserPreferencesOptions> {}

export interface SaveUserPreferencesOptions {
	key: string;
	preferences: any;
}
