import { FabButtonModule, FabIconModule, FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PanelsModule } from '@wcd/panels';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { DataExportDataviewComponent } from './components/data-export.dataview.component';
import { DataExportEntityTypeModule } from './data-export.entity-type.module';
import { DataExportEntityTypeService } from './services/data-export.entity-type.service';
import { DataExportService } from './services/data-export.service';

@NgModule({
	imports: [
		SharedModule,
		PanelsModule,
		FormsModule,
		DataViewsModule,
		DataExportEntityTypeModule,
		FabIconModule,
		FabSpinnerModule,
		FabButtonModule,
		FabMessageBarModule,
	],
	declarations: [DataExportDataviewComponent],
	providers: [DataExportEntityTypeService, DataExportService],
	entryComponents: [DataExportDataviewComponent],
	exports: [],
})
export class DataExportModule {}
