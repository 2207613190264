import { I18nService } from '@wcd/i18n';
import { PrettyNumberService } from '@wcd/prettify';
import { Asset } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { DataviewField } from '@wcd/dataview';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmExposureScoreService } from '../../../../tvm/services/tvm-exposure-score.service';

@Injectable()
export class AssetFieldsService implements FieldsService<Asset> {
	private _fields: Array<DataviewField<Asset>>;

	constructor(
		private i18nService: I18nService,
		private tvmExposureScoreService: TvmExposureScoreService,
		private prettyNumberService: PrettyNumberService
	) {}

	get fields(): Array<DataviewField<Asset>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<Asset>([
				{
					id: 'name',
					name: this.i18nService.get('tvm.common.assetNameTitle'),
					getTooltip: (asset: Asset) => asset.name,
				},
				{
					id: 'osPlatform',
					name: this.i18nService.get('tvm_common_osPlatform'),
					sort: { enabled: false },
					getDisplay: (asset: Asset) =>
						asset.osPlatform
							? this.i18nService.get(`tvm_common_osPlatform_${asset.osPlatform}`)
							: this.i18nService.strings.notAvailable_short,
				},
				{
					id: 'securityRecommendations',
					name: this.i18nService.get('tvm.common.securityRecommendations'),
				},
				{
					id: 'discoveredVulnerabilities',
					name: this.i18nService.get('tvm.common.discoveredVulnerabilities'),
				},
				{
					id: 'exposureCategory',
					name: this.i18nService.get('evaluation.summary.exposureLevel.title'),
					getDisplay: (asset: Asset) =>
						`${asset.exposureLevel} (${this.prettyNumberService.prettyNumber(
							Math.floor(asset.exposureScore)
						)})`,
					icon: {
						fabricIcon: FabricIconNames.Warning,
						className: (asset: Asset) =>
							this.tvmExposureScoreService.getExposureCategoryColorClass(asset.exposureLevel),
					},
				},
			]).filter(Boolean);
		}

		return this._fields;
	}
}
