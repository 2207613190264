var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { HuntingCustomActionResult } from './hunting-custom-action-result.value-object';
import { ApiCallModel, EntityField, ApiCall, ValueObject, ModelBase } from '@microsoft/paris';
import { HuntingCustomAction, } from './hunting-custom-action.value-object';
import { isArray } from 'lodash-es';
var HuntingCustomActionRequest = /** @class */ (function (_super) {
    __extends(HuntingCustomActionRequest, _super);
    function HuntingCustomActionRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ResultSet' }),
        __metadata("design:type", Array)
    ], HuntingCustomActionRequest.prototype, "records", void 0);
    HuntingCustomActionRequest = __decorate([
        ValueObject({
            singularName: 'Hunting custom action request',
            pluralName: 'Hunting custom action requests',
        })
    ], HuntingCustomActionRequest);
    return HuntingCustomActionRequest;
}(HuntingCustomAction));
export { HuntingCustomActionRequest };
var HuntingCustomActionsRequest = /** @class */ (function (_super) {
    __extends(HuntingCustomActionsRequest, _super);
    function HuntingCustomActionsRequest() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Actions', arrayOf: HuntingCustomActionRequest }),
        __metadata("design:type", Array)
    ], HuntingCustomActionsRequest.prototype, "actions", void 0);
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], HuntingCustomActionsRequest.prototype, "remediationName", void 0);
    __decorate([
        EntityField({ data: 'Comment' }),
        __metadata("design:type", String)
    ], HuntingCustomActionsRequest.prototype, "remediationComment", void 0);
    HuntingCustomActionsRequest = __decorate([
        ValueObject({
            singularName: 'Hunting custom actions request',
            pluralName: 'Hunting custom actions request',
        })
    ], HuntingCustomActionsRequest);
    return HuntingCustomActionsRequest;
}(ModelBase));
export { HuntingCustomActionsRequest };
var HuntingCustomActionsResponse = /** @class */ (function (_super) {
    __extends(HuntingCustomActionsResponse, _super);
    function HuntingCustomActionsResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Results', arrayOf: HuntingCustomActionResult }),
        __metadata("design:type", Array)
    ], HuntingCustomActionsResponse.prototype, "results", void 0);
    __decorate([
        EntityField({ data: 'BulkId' }),
        __metadata("design:type", String)
    ], HuntingCustomActionsResponse.prototype, "bulkId", void 0);
    HuntingCustomActionsResponse = __decorate([
        ValueObject({
            singularName: 'Hunting custom actions response',
            pluralName: 'Hunting custom actions response',
        })
    ], HuntingCustomActionsResponse);
    return HuntingCustomActionsResponse;
}(ModelBase));
export { HuntingCustomActionsResponse };
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (rawData) { return (isArray(rawData) ? { Results: rawData } : rawData); };
var RunHuntingBulkActionApiCall = /** @class */ (function (_super) {
    __extends(RunHuntingBulkActionApiCall, _super);
    function RunHuntingBulkActionApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RunHuntingBulkActionApiCall = __decorate([
        ApiCall({
            name: 'Execute bulk actions on hunting records',
            endpoint: 'hunting/customActions',
            baseUrl: ɵ0,
            method: 'POST',
            parseData: ɵ1,
            type: HuntingCustomActionsResponse,
        })
    ], RunHuntingBulkActionApiCall);
    return RunHuntingBulkActionApiCall;
}(ApiCallModel));
export { RunHuntingBulkActionApiCall };
var ɵ2 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ3 = function (rawData) { return (isArray(rawData) ? { Results: rawData } : rawData); };
var RunHuntingBulkActionApiCallVNext = /** @class */ (function (_super) {
    __extends(RunHuntingBulkActionApiCallVNext, _super);
    function RunHuntingBulkActionApiCallVNext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    RunHuntingBulkActionApiCallVNext = __decorate([
        ApiCall({
            name: 'Execute bulk actions on hunting records',
            endpoint: 'customactions',
            baseUrl: ɵ2,
            method: 'POST',
            parseData: ɵ3,
            type: HuntingCustomActionsResponse,
        })
    ], RunHuntingBulkActionApiCallVNext);
    return RunHuntingBulkActionApiCallVNext;
}(ApiCallModel));
export { RunHuntingBulkActionApiCallVNext };
var StartNewEmailReportSubmissionResponse = /** @class */ (function (_super) {
    __extends(StartNewEmailReportSubmissionResponse, _super);
    function StartNewEmailReportSubmissionResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Results' }),
        __metadata("design:type", Object)
    ], StartNewEmailReportSubmissionResponse.prototype, "results", void 0);
    __decorate([
        EntityField({ data: 'BulkId' }),
        __metadata("design:type", String)
    ], StartNewEmailReportSubmissionResponse.prototype, "bulkId", void 0);
    StartNewEmailReportSubmissionResponse = __decorate([
        ValueObject({
            singularName: 'start new email report submission response',
            pluralName: 'start new email report submission responses',
        })
    ], StartNewEmailReportSubmissionResponse);
    return StartNewEmailReportSubmissionResponse;
}(ModelBase));
export { StartNewEmailReportSubmissionResponse };
var TriggerInvestigationResponse = /** @class */ (function (_super) {
    __extends(TriggerInvestigationResponse, _super);
    function TriggerInvestigationResponse() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Results' }),
        __metadata("design:type", Object)
    ], TriggerInvestigationResponse.prototype, "results", void 0);
    __decorate([
        EntityField({ data: 'TenantId' }),
        __metadata("design:type", Number)
    ], TriggerInvestigationResponse.prototype, "TenantId", void 0);
    TriggerInvestigationResponse = __decorate([
        ValueObject({
            singularName: 'start trigger investigation response',
            pluralName: 'start trigger investigation responses',
        })
    ], TriggerInvestigationResponse);
    return TriggerInvestigationResponse;
}(ModelBase));
export { TriggerInvestigationResponse };
var ɵ4 = function (config) { return '/api/ReportSubmission'; }, ɵ5 = function (rawData) {
    return { Results: rawData };
};
var StartNewEmailReportSubmissionApi = /** @class */ (function (_super) {
    __extends(StartNewEmailReportSubmissionApi, _super);
    function StartNewEmailReportSubmissionApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    StartNewEmailReportSubmissionApi = __decorate([
        ApiCall({
            name: 'Execute New Email Report Submission',
            endpoint: 'SubmitThreat',
            baseUrl: ɵ4,
            method: 'PUT',
            parseData: ɵ5,
            type: StartNewEmailReportSubmissionResponse,
        })
    ], StartNewEmailReportSubmissionApi);
    return StartNewEmailReportSubmissionApi;
}(ApiCallModel));
export { StartNewEmailReportSubmissionApi };
var ɵ6 = function (config) { return '/api/AirModels'; }, ɵ7 = function (rawData) {
    return { Results: rawData };
};
var TriggerInvestigationEmailApi = /** @class */ (function (_super) {
    __extends(TriggerInvestigationEmailApi, _super);
    function TriggerInvestigationEmailApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TriggerInvestigationEmailApi = __decorate([
        ApiCall({
            name: 'Execute Trigger Investigation For Email',
            endpoint: 'SaveEmail',
            baseUrl: ɵ6,
            method: 'POST',
            parseData: ɵ7,
            type: TriggerInvestigationResponse,
        })
    ], TriggerInvestigationEmailApi);
    return TriggerInvestigationEmailApi;
}(ApiCallModel));
export { TriggerInvestigationEmailApi };
var ɵ8 = function (config) { return '/api/AirModels'; }, ɵ9 = function (rawData) {
    return { Results: rawData };
};
var TriggerInvestigationRecipientApi = /** @class */ (function (_super) {
    __extends(TriggerInvestigationRecipientApi, _super);
    function TriggerInvestigationRecipientApi() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    TriggerInvestigationRecipientApi = __decorate([
        ApiCall({
            name: 'Execute Trigger Investigation For Recipient',
            endpoint: 'SaveUserInvestigation?target=re',
            baseUrl: ɵ8,
            method: 'POST',
            parseData: ɵ9,
            type: TriggerInvestigationResponse,
        })
    ], TriggerInvestigationRecipientApi);
    return TriggerInvestigationRecipientApi;
}(ApiCallModel));
export { TriggerInvestigationRecipientApi };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
