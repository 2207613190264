import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { RemediationDescriptor } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'related-tasks-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './related-tasks.details.component.html',
})
export class RelatedTasksDetailsComponent {
	SpinnerSize = SpinnerSize;
	isListCollapsed = true;
	@Input() remediationTasks: Array<RemediationDescriptor>;

	constructor(private tvmTextsService: TvmTextsService, private router: Router) {}

	openRelatedRemediation(task: RemediationDescriptor) {
		this.router.navigate(['remediation/remediation-activities', task.remediationId]);
	}

	getStatusText(remediationDescriptor: RemediationDescriptor) {
		return this.tvmTextsService.getText(TextToken.RemediationTicketStatus, remediationDescriptor);
	}
}
