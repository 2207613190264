import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineVulnerabilityAgeLevel, MachineVulnerabilityAgeLevelType } from './machine-vulnerability-age-level.models';
import { machineVulnerabilityAgeLevelValues } from './machine-vulnerability-age-level.values';

@Entity({
	singularName: 'Device Vulnerability Age Level',
	pluralName: 'Device Vulnerability Age Levels',
	values: machineVulnerabilityAgeLevelValues,
})
export class MachineVulnerabilityAgeLevel extends EntityModelBase<MachineVulnerabilityAgeLevelType> implements IMachineVulnerabilityAgeLevel {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineVulnerabilityAgeLevelType {
		return this.id;
	}
}
