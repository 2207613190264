
		<wcd-range-slider
			[max]="config?.max"
			[min]="config?.min"
			[valueFormat]="config?.valueFormat"
			[value]="selectedValues"
			[defaultValue]="config?.initialValue || selectedValues"
			(onChange)="onChange($event)"
		>
		</wcd-range-slider>
	