import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { AppConfigData } from '@wcd/app-config';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
import { HandleSmbTenantOnboardingWizardRedirection } from '@wcd/auth';

const DEFAULT_ENDPOINTS_PAGE = 'homepage';

@Injectable()
export class EndpointsGuard implements CanActivate {
	constructor() {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
		const redirectTo = (route.routeConfig && route.routeConfig.path) || DEFAULT_ENDPOINTS_PAGE;
		const tenantContext: AppConfigData = tenantContextCache.appConfig;

		const isTvmEligible = tenantContext.IsTvmEligible;
		if (!tenantContext.IsMtpEligible && !isTvmEligible) {
			return null;
		}

		const shouldGoToOnboarding =
			(tenantContext.IsMtpEligible && !tenantContext.IsOnboardingComplete) ||
			(isTvmEligible && !tenantContext.IsTvmOnboardingComplete);

		if (shouldGoToOnboarding) {
			const onboardPath = isTvmEligible && !tenantContext.IsTvmOnboardingComplete ? 'onboard' : 'onboard_mtp';
			sccHostService.state.go(onboardPath, { redirectTo });
			return false;
		}

		// This should be checked before the IsMatpActive check, since tenants with expired license get IsMdatpActive = false,
		// but we want to redirect them to the license expired page
		if (tenantContext.IsMdatpLicenseExpired && !isTvmEligible) {
			sccHostService.state.go('mdatp-licenseexpired');
			return false;
		}

		if (!tenantContext.IsMdatpActive&& !isTvmEligible) {
			// Getting here means the tenant has neither MDATP active nor an expired MDATP license - should not be allowed to proceed
			return false;
		}

		if (tenantContext.MdeFlavor === 'Smb') {
			return HandleSmbTenantOnboardingWizardRedirection(tenantContext, redirectTo);
		}

		return true;
	}
}
