/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./comparison-result.entity-details.component.ngfactory";
import * as i2 from "./comparison-result.entity-details.component";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../shared/services/download.service";
import * as i5 from "./comparison-result.entity-panel.component";
var styles_ComparisonResultEntityPanelComponent = [];
var RenderType_ComparisonResultEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ComparisonResultEntityPanelComponent, data: {} });
export { RenderType_ComparisonResultEntityPanelComponent as RenderType_ComparisonResultEntityPanelComponent };
export function View_ComparisonResultEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 1, "comparison-result-entity-details", [], null, null, null, i1.View_ComparisonResultEntityDetailsComponent_0, i1.RenderType_ComparisonResultEntityDetailsComponent)), i0.ɵdid(4, 638976, null, 0, i2.ComparisonResultEntityDetailsComponent, [i3.I18nService, i4.DownloadService, i0.Injector], { contextOptions: [0, "contextOptions"], comparisonResult: [1, "comparisonResult"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contextOptions; var currVal_1 = _co.comparisonResult; _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_ComparisonResultEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "comparison-result-entity-panel", [], null, null, null, View_ComparisonResultEntityPanelComponent_0, RenderType_ComparisonResultEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i5.ComparisonResultEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ComparisonResultEntityPanelComponentNgFactory = i0.ɵccf("comparison-result-entity-panel", i5.ComparisonResultEntityPanelComponent, View_ComparisonResultEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { ComparisonResultEntityPanelComponentNgFactory as ComparisonResultEntityPanelComponentNgFactory };
