export enum MachineVulnerabilityAgeLevelType {
	None = 'None',
	Age0To30days = 'Age0To30days',
	Age31To60days = 'Age31To60days',
	Age61To90days = 'Age61To90days',
	AgeMoreThan90days = 'AgeMoreThan90days',
}

export interface IMachineVulnerabilityAgeLevel {
	readonly id: MachineVulnerabilityAgeLevelType;
	name: string;
	nameI18nKey: string;
	priority: number;
}
