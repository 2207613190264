import { Entity, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { WebContentFilteringReportBase } from './report.model';
import { WebContentFilteringCategory } from './machine-report.value-object';
import {
	WebContentFilteringDevice,
	WebContentFilteringDomain,
	WebContentFilteringAccessTrend,
} from './category-report.value-object';

@Entity({
	singularName: 'Device list report',
	pluralName: 'Device list reports',
	endpoint: 'WebContentFiltering/Reports/MachineList',
	readonly: true,
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	allItemsEndpointTrailingSlash: false,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class WebContentFilteringMachineListReport extends WebContentFilteringReportBase {
	@EntityField({ data: 'DeviceGroupId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'DeviceGroupName' })
	name: string;

	@EntityField({ data: 'CategoryCount' })
	categoryCount: number;

	@EntityField({ data: 'DomainCount' })
	domainCount: number;

	@EntityField({ data: 'TopDevices', arrayOf: WebContentFilteringDevice })
	topDevices: Array<WebContentFilteringDevice>;

	@EntityField({ data: 'TopDomains', arrayOf: WebContentFilteringDomain })
	topDomains: Array<WebContentFilteringDomain>;

	@EntityField({ data: 'TopCategories', arrayOf: WebContentFilteringCategory })
	topCategories: Array<WebContentFilteringCategory>;

	@EntityField({ data: 'AccessTrends', arrayOf: WebContentFilteringAccessTrend })
	accessTrends: Array<WebContentFilteringAccessTrend>;
}
