var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
var IncidentUpdateStatusDialogComponent = /** @class */ (function (_super) {
    __extends(IncidentUpdateStatusDialogComponent, _super);
    function IncidentUpdateStatusDialogComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.resolveIncident = new EventEmitter();
        _this.resolving = false;
        return _this;
    }
    IncidentUpdateStatusDialogComponent.prototype.submitResolve = function () {
        this.resolving = true;
        this.resolveIncident.emit(true);
    };
    return IncidentUpdateStatusDialogComponent;
}(ModalContainer));
export { IncidentUpdateStatusDialogComponent };
