import {
	FabSpinnerModule,
	FabShimmerModule,
	FabMessageBarModule,
	FabButtonModule,
} from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { DataTableModule } from '@wcd/datatable';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { ReportsModule } from '../../reports/reports.module';
import { SharedModule } from '../../shared/shared.module';
import { BlockFileModalComponent } from './components/actions/block-file-modal.component';
import { QuarantineFileModalComponent } from './components/actions/quarantine-file-modal.component';
import { FileMalwareDetectedComponent } from './components/file-malware-detected.component';
import { FileActionCenterPanelComponent } from './components/file.action-center/file.action-center.panel.component';
import { FileActionCenterQuarantineComponent } from './components/file.action-center/file.action-center.quarantine.component';
import { FileActionCenterCollectionComponent } from './components/file.action-center/file.action-center.collection.component';
import { FileEntityPanelComponent } from './components/file.entity-panel/file.entity-panel.component';
import { FileEntityComponent } from './components/file.entity.component';
import { FilePrevalenceWidgetComponent } from './components/file.overview.report/widgets/file.prevalence.widget';
import { FileReputationWidgetComponent } from './components/file.overview.report/widgets/file.reputation.widgets/file.reputation.widget';
import { MalwareDetectionWidgetComponent } from './components/file.overview.report/widgets/file.reputation.widgets/malware.detection.widget';
import { VirusTotalWidgetComponent } from './components/file.overview.report/widgets/file.reputation.widgets/virus.total.widget';
import { MalwareAlertsComponent } from './components/malware-alerts.component';
import { FileInstanceEntityTypeService } from './services/file-instance.entity-type.service';
import { FileVerdictAlertService } from './services/file-verdict-alert.service';
import { FileActionsService } from './services/file.actions.service';
import { FileEntityTypeService } from './services/file.entity-type.service';
import { FilesBackendService } from './services/files.backend.service';
import { FilesFieldsService } from './services/files.fields.service';
import { FilesFiltersService } from './services/files.filters.service';
import { FilesService } from './services/files.service';
import { FileDownloadSamplePanelComponent } from '../files/components/file.download-sample/file.download-sample.panel.component';
import { FileCollectSamplePanelComponent } from '../files/components/file.collect-sample/file.collect-sample.panel.component';
import { FileDetectionsPanelComponent } from './components/file-detections-panel.component';
import { EntityCustomTiActionsConfigService } from '../common/services/entity.custom-ti-actionconfig.service';
import { ScriptPanelComponent } from './components/script.entity-panel.component';
import { ScriptEntityTypeService } from './services/script.entity-type.service';
import { FileAlertsSummaryWidgetComponent } from './components/file.overview.report/widgets/file.alerts-summary.widget';
import { BarsChartModule } from '@wcd/charts';
import { PanelsModule } from '@wcd/panels';
import { FileEntityResolver } from './services/file.entity.resolver';
import { FileBreadcrumbsResolver } from './services/file.breadcrumbs.resolver';
import { FilePrevalenceComponent } from './components/file.prevalence.component';
import { VirusTotalFileReportService } from './services/virus-total-file-report.service';
import { A11yModule } from '@angular/cdk/a11y';

const fileComponents = [
	FileEntityPanelComponent,
	FileEntityComponent,
	FilePrevalenceWidgetComponent,
	FileReputationWidgetComponent,
	BlockFileModalComponent,
	QuarantineFileModalComponent,
	FileActionCenterPanelComponent,
	FileMalwareDetectedComponent,
	MalwareAlertsComponent,
	FileDownloadSamplePanelComponent,
	FileCollectSamplePanelComponent,
	FileDetectionsPanelComponent,
	ScriptPanelComponent,
	FileAlertsSummaryWidgetComponent,
	FileActionCenterQuarantineComponent,
	FileActionCenterCollectionComponent,
	FilePrevalenceComponent,
	MalwareDetectionWidgetComponent,
	VirusTotalWidgetComponent,
];

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		FabSpinnerModule,
		ReportsModule,
		FabShimmerModule,
		FabButtonModule,
		DataTableModule,
		FabMessageBarModule,
		BarsChartModule,
		PanelsModule,
		A11yModule,
	],
	providers: [
		FileEntityResolver,
		FileBreadcrumbsResolver,
		FilesBackendService,
		FilesService,
		FileEntityTypeService,
		FileInstanceEntityTypeService,
		FilesFiltersService,
		FilesFieldsService,
		FileVerdictAlertService,
		FileActionsService,
		EntityCustomTiActionsConfigService,
		ScriptEntityTypeService,
		VirusTotalFileReportService,
	],
	declarations: fileComponents,
	entryComponents: fileComponents,
	exports: [FilePrevalenceComponent, FileEntityPanelComponent],
})
export class FileEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(FileEntityTypeService);
		globalEntityTypesService.registerEntityType(FileInstanceEntityTypeService);
		globalEntityTypesService.registerEntityType(ScriptEntityTypeService);
	}
}
