
		<dl role="none">
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.dnsName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.dnsName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.canonicalName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.canonicalName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.distinguishedName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.distinguishedName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.sid' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.sid }}
			</dd>
		</dl>
	