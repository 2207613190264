import { Injectable, SecurityContext } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { DateRangeModel } from '@wcd/localization';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import {
	DecodeQueryTextApiCall,
	EncodeQueryTextApiCall,
	HuntingQueryApiCall,
	HuntingQueryBase,
	HuntingQueryParameters,
	HuntingQueryResult,
	HuntingSavedQuery,
	HuntingScheduledQuery,
	HuntingSharedQuery,
	HuntingUserQuery,
} from '@wcd/domain';
import { ConfirmationDialogService } from '@wcd/dialogs';

import { SaveQueryContentComponent } from '../components/save-query-content.component';
import { Router, UrlTree } from '@angular/router';
import { HUNTING_ROUTE, WicdSanitizerService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import {
	ScheduledHuntingErrorParsingService,
	ScheduledQueryApiError,
} from '../../queries/services/scheduled-hunting.error-parsing.service';
import { FeaturesService, Feature } from '@wcd/config';

@Injectable()
export class QueryService {
	constructor(
		private readonly paris: Paris,
		private readonly confirmationDialogService: ConfirmationDialogService,
		private readonly scheduledQueriesErrorParsingService: ScheduledHuntingErrorParsingService,
		private readonly router: Router,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private domSanitizer: WicdSanitizerService
	) {}

	query(queryText: string, dateRange: DateRangeModel): Observable<HuntingQueryResult> {
		return this.paris.apiCall<HuntingQueryResult, HuntingQueryParameters>(HuntingQueryApiCall, {
			queryText,
			startTime: dateRange.from,
			endTime: dateRange.to,
		});
	}

	async saveQuery<TQuery extends HuntingEditableQuery>({ query }: { query: TQuery }) {
		const showSaveDialog = async (errorMessage?: string) => {
			const result = await this.confirmationDialogService.showDialog<
				SaveQueryContentComponent,
				HuntingEditableQuery
			>({
				title: this.i18nService.get('hunting.saveQueryDialog.title'),
				confirmButton: this.i18nService.get('hunting_saveQueryDialog_buttons_save'),
				dimensions: { width: 500, height: 290 },
				contentComponent: {
					type: SaveQueryContentComponent,
					props: {
						query,
						errorMessage,
					},
				},
				handlers: {
					onSubmit: async (contentComponent) => {
						contentComponent.isSaving = true;
						const { kind, folderPath } = contentComponent.selection;

						const modifiedQuery = await this.paris
							.createItem<HuntingEditableQuery>(
								kind === 'user' ? HuntingUserQuery : HuntingSharedQuery,
								{
									Name: query.name,
									QueryText: query.text,
									Path: folderPath,
									UserId: kind === 'user' ? query.userId : null,
									IsReadOnly: query.readonly,
								}
							)
							.pipe(take(1))
							.toPromise<HuntingEditableQuery>();

						try {
							return await this.saveQueryWithoutConfirmation(modifiedQuery);
						} catch (error) {
							contentComponent.errorMessage = this.scheduledQueriesErrorParsingService.parseError(
								error as ScheduledQueryApiError,
								true
							).errorMessage;
							throw error;
						} finally {
							contentComponent.isSaving = false;
						}
					},
				},
			});

			if (!result.confirmed) {
				return;
			}

			return result.data;
		};

		if (query.id && query.name && query.text) {
			try {
				return await this.saveQueryWithoutConfirmation(query);
			} catch (error) {
				const { errorMessage } = this.scheduledQueriesErrorParsingService.parseError(
					error as ScheduledQueryApiError,
					true
				);
				return await showSaveDialog(errorMessage);
			}
		} else {
			return await showSaveDialog();
		}
	}

	async renameQuery<TQuery extends HuntingEditableQuery>({
		query,
		newName,
	}: {
		query: TQuery;
		newName?: string;
	}) {
		if (newName) {
			return await this.renameQueryWithoutConfirmation(query, newName);
		}

		const result = await this.confirmationDialogService.showTextInputDialog<HuntingEditableQuery>({
			title: this.i18nService.get('hunting.renameQueryDialog.title'),
			confirmButton: this.i18nService.get('hunting_saveQueryDialog_buttons_save'),
			textInputProps: {
				label: 'Name',
				required: true,
				placeholder: query.name,
				text: query.name,
			},
			handlers: {
				onSubmit: ({ text }) => this.renameQueryWithoutConfirmation(query, text),
			},
			dimensions: { height: 200, width: 500 },
		});

		if (!result.confirmed) {
			return;
		}

		return result.data;
	}

	async deleteQuery<TQuery extends HuntingEditableQuery>({
		query,
		withUserConfirmation = true,
	}: {
		query: TQuery;
		withUserConfirmation?: boolean;
	}) {
		if (!withUserConfirmation) {
			return await this.deleteQueryWithoutConfirmation(query);
		}

		const result = await this.confirmationDialogService.showDialog({
			title: this.i18nService.get('hunting.deleteQueryDialog.title'),
			body: this.i18nService.get('hunting.deleteQueryDialog.verifyDeletion', { queryName: query.name }),
			confirmButton: this.i18nService.get('hunting_deleteQueryDialog_buttons_delete'),
			handlers: {
				onSubmit: () => this.deleteQueryWithoutConfirmation(query),
			},
		});

		if (!result.confirmed) {
			return;
		}

		return result.data;
	}

	async loadQuery<TQuery extends HuntingEditableQuery | HuntingScheduledQuery>(
		partialQuery: Pick<TQuery, 'id' | 'kind'>
	): Promise<TQuery> {
		return this.getRepository<TQuery>(partialQuery)
			.getItemById(partialQuery.id)
			.pipe(take(1))
			.toPromise<TQuery>();
	}

	getDeepLinkToQuery(queryOrText: HuntingQueryBase | string): Observable<UrlTree> {
		const query: HuntingQueryBase =
			typeof queryOrText === 'object'
				? queryOrText
				: new HuntingUserQuery({ id: undefined, text: queryOrText });

		return this.paris
			.apiCall<string, string>(EncodeQueryTextApiCall, query.text)
			.pipe(
				map((encodedQuery: string) =>
					this.router.createUrlTree([HUNTING_ROUTE], { queryParams: { query: encodedQuery } })
				)
			);
	}

	parseQueryFromDeepLink(encodedQuery: string): Observable<HuntingUserQuery> {
		return this.paris
			.apiCall<string, string>(DecodeQueryTextApiCall, encodedQuery)
			.pipe(map((decodedQuery) => new HuntingUserQuery({ id: undefined, text: decodedQuery })));
	}

	private saveQueryWithoutConfirmation<TQuery extends HuntingEditableQuery>(query: TQuery) {
		const name = this.domSanitizer.sanitize(SecurityContext.HTML, query.name);
		return this.getRepository<TQuery>(query)
			.save(Object.assign({}, query, { name }))
			.toPromise<HuntingEditableQuery>();
	}

	private deleteQueryWithoutConfirmation<TQuery extends HuntingEditableQuery>(query: TQuery) {
		return this.getRepository<TQuery>(query).removeItem(query).toPromise<HuntingEditableQuery>();
	}

	private renameQueryWithoutConfirmation<TQuery extends HuntingEditableQuery>(
		query: TQuery,
		newName: string
	) {
		const name = this.domSanitizer.sanitize(SecurityContext.HTML, newName);
		return this.getRepository<TQuery>(query)
			.save(Object.assign({}, query, { name }))
			.toPromise<HuntingEditableQuery>();
	}

	private getRepository<TQuery extends HuntingEditableQuery | HuntingScheduledQuery>(
		partialQuery: Pick<TQuery, 'kind'>
	): Repository<TQuery> {
		return this.paris.getRepository<HuntingEditableQuery | HuntingScheduledQuery>(
			this.getEntityType(partialQuery)
		) as Repository<TQuery>;
	}

	private getEntityType<TQuery extends HuntingEditableQuery | HuntingScheduledQuery>(
		partialQuery: Pick<TQuery, 'kind'>
	) {
		if (this.shouldMigrateToVNext()) {
			return HuntingSavedQuery;
		}
		if (partialQuery.kind === 'scheduled') {
			return HuntingScheduledQuery;
		} else if (partialQuery.kind === 'user') {
			return HuntingUserQuery;
		} else if (partialQuery.kind === 'shared') {
			return HuntingSharedQuery;
		}
	}

	private shouldMigrateToVNext() {
		return (
			this.featuresService.isEnabled(Feature.HuntingEndpointMigrationQueriesController) ||
			this.featuresService.isEnabled(Feature.HuntingEndpointMigrationQueriesControllerMtp)
		);
	}
}

export type HuntingEditableQuery = HuntingUserQuery | HuntingSharedQuery | HuntingSavedQuery;
