import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { CommandLine } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';

@Component({
	selector: 'command-line-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'commandLines.fields.command.title' | i18n }}</dt>
			<dd>
				<copyable-input
					[ariaLabel]="'commandLines.fields.command.title' | i18n"
					[value]="commandLine.commandText"
					showFullValue="true"
					[readonly]="true"
				></copyable-input>
			</dd>
		</dl>
	`,
})
export class CommandLineEntityDetailsComponent extends EntityDetailsComponentBase<CommandLine> {
	get commandLine(): CommandLine {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
