/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../dialogs/modals/components/modal.component.ngfactory";
import * as i2 from "../../../dialogs/modals/components/modal.component";
import * as i3 from "@angular/forms";
import * as i4 from "./remediation-decline-reason.component.ngfactory";
import * as i5 from "./remediation-decline-reason.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./quarantine-files-remediation-decline.modal";
import * as i8 from "@angular/router";
var styles_QuarantineFilesRemediationDeclineModalComponent = [];
var RenderType_QuarantineFilesRemediationDeclineModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_QuarantineFilesRemediationDeclineModalComponent, data: {} });
export { RenderType_QuarantineFilesRemediationDeclineModalComponent as RenderType_QuarantineFilesRemediationDeclineModalComponent };
export function View_QuarantineFilesRemediationDeclineModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 34, "modal", [], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.cancel.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ModalComponent_0, i1.RenderType_ModalComponent)), i0.ɵdid(2, 180224, null, 0, i2.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 30, "form", [["class", "wcd-full-height wcd-flex-vertical"], ["data-track-component", "DeclineQuarantineFiles"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } if (("submit" === en)) {
        var pd_2 = (_co.decline() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(6, 4210688, [["declineForm", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i0.ɵdid(8, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 12, "div", [["class", "wcd-flex-1 wcd-scroll-vertical dialog-contents"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 9, "div", [["class", "wcd-margin-bottom"], ["style", "min-width: 400px"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 6, "remediation-decline-reason", [["name", "quarantine-files-decline-reason"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = ((_co.declineReason = $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_RemediationDeclineReasonComponent_0, i4.RenderType_RemediationDeclineReasonComponent)), i0.ɵdid(15, 49152, null, 0, i5.RemediationDeclineReasonComponent, [i6.I18nService], null, null), i0.ɵprd(1024, null, i3.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i5.RemediationDeclineReasonComponent]), i0.ɵdid(17, 671744, null, 0, i3.NgModel, [[2, i3.ControlContainer], [8, null], [8, null], [6, i3.NG_VALUE_ACCESSOR]], { name: [0, "name"], model: [1, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i3.NgControl, null, [i3.NgModel]), i0.ɵdid(19, 16384, null, 0, i3.NgControlStatus, [[4, i3.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(24, 0, null, null, 9, "footer", [["class", "dialog-footer wcd-flex-none right-text"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(26, 0, null, null, 3, "button", [["class", "btn btn-primary"], ["data-track-id", "DeclineQuarantine"], ["data-track-type", "Button"], ["type", "submit"]], [[8, "disabled", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(28, 0, null, null, 0, "i", [["class", "loader-icon"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i0.ɵted(29, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(31, 0, null, null, 1, "button", [["class", "btn btn-cancel"], ["data-track-id", "Cancel"], ["data-track-type", "Button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(32, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_15 = "quarantine-files-decline-reason"; var currVal_16 = _co.declineReason; _ck(_v, 17, 0, currVal_15, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_3 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_4 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_5 = i0.ɵnov(_v, 8).ngClassValid; var currVal_6 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_7 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_8 = i0.ɵnov(_v, 19).ngClassUntouched; var currVal_9 = i0.ɵnov(_v, 19).ngClassTouched; var currVal_10 = i0.ɵnov(_v, 19).ngClassPristine; var currVal_11 = i0.ɵnov(_v, 19).ngClassDirty; var currVal_12 = i0.ɵnov(_v, 19).ngClassValid; var currVal_13 = i0.ɵnov(_v, 19).ngClassInvalid; var currVal_14 = i0.ɵnov(_v, 19).ngClassPending; _ck(_v, 14, 0, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14); var currVal_17 = ((i0.ɵnov(_v, 6).form.invalid || _co.isSaving) || !_co.declineReason); _ck(_v, 26, 0, currVal_17); var currVal_18 = !_co.isSaving; _ck(_v, 28, 0, currVal_18); var currVal_19 = (_co.isSaving ? _co.i18nService.strings.remediation_declineModal_pleaseWait : _co.i18nService.strings.remediation_declineModal_decline); _ck(_v, 29, 0, currVal_19); var currVal_20 = _co.i18nService.strings.buttons_cancel; _ck(_v, 32, 0, currVal_20); }); }
export function View_QuarantineFilesRemediationDeclineModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "quarantine-files-remediation-decline-modal", [], null, null, null, View_QuarantineFilesRemediationDeclineModalComponent_0, RenderType_QuarantineFilesRemediationDeclineModalComponent)), i0.ɵdid(1, 245760, null, 0, i7.QuarantineFilesRemediationDeclineModalComponent, [i8.Router, i6.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var QuarantineFilesRemediationDeclineModalComponentNgFactory = i0.ɵccf("quarantine-files-remediation-decline-modal", i7.QuarantineFilesRemediationDeclineModalComponent, View_QuarantineFilesRemediationDeclineModalComponent_Host_0, { settings: "settings", inputs: "inputs", onDone: "onDone", dismissPending: "dismissPending" }, { cancel: "cancel" }, []);
export { QuarantineFilesRemediationDeclineModalComponentNgFactory as QuarantineFilesRemediationDeclineModalComponentNgFactory };
