/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./scheduled-hunting.entity.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../shared/components/tabs/tabs.component.ngfactory";
import * as i3 from "../../../shared/components/tabs/tabs.component";
import * as i4 from "@angular/router";
import * as i5 from "./scheduled-hunting.entity.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_ScheduledHuntingEntityComponent = [i0.styles];
var RenderType_ScheduledHuntingEntityComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScheduledHuntingEntityComponent, data: {} });
export { RenderType_ScheduledHuntingEntityComponent as RenderType_ScheduledHuntingEntityComponent };
export function View_ScheduledHuntingEntityComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "tabs", [["class", "wcd-flex-0 wcd-padding-medium-all"]], null, null, null, i2.View_TabsComponent_0, i2.RenderType_TabsComponent)), i1.ɵdid(4, 4833280, null, 0, i3.TabsComponent, [i1.ChangeDetectorRef, i4.ActivatedRoute, i4.Router], { tabsData: [0, "tabsData"], currentRouteIsActive: [1, "currentRouteIsActive"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "wcd-flex-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(9, 212992, null, 0, i4.RouterOutlet, [i4.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; var currVal_1 = true; _ck(_v, 4, 0, currVal_0, currVal_1); _ck(_v, 9, 0); }, null); }
export function View_ScheduledHuntingEntityComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "scheduled-hunting-entity", [], null, null, null, View_ScheduledHuntingEntityComponent_0, RenderType_ScheduledHuntingEntityComponent)), i1.ɵdid(1, 114688, null, 0, i5.ScheduledHuntingEntityComponent, [i6.I18nService, i7.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScheduledHuntingEntityComponentNgFactory = i1.ɵccf("scheduled-hunting-entity", i5.ScheduledHuntingEntityComponent, View_ScheduledHuntingEntityComponent_Host_0, { entity: "entity", isFullScreen: "isFullScreen", action$: "action$", entityPageViewMode: "entityPageViewMode" }, { runAction$: "runAction$" }, []);
export { ScheduledHuntingEntityComponentNgFactory as ScheduledHuntingEntityComponentNgFactory };
