import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { FlavorService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class FlavorGuard implements CanActivate {
	constructor(private flavorService: FlavorService) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const data = route.routeConfig.data;
		const isEnabled = data && this.flavorService.isEnabled(data.flavorConfig);
		if (!isEnabled) {
			sccHostService.state.go('homepage');
		}
		return isEnabled;
	}
}
