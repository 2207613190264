
		<div *ngIf="dataLoaded; else loader" class="wcd-impacted-entities-datatable-container">
			<h4 class="definition-list-title" *ngIf="showTitle">
				{{ 'entities.impactedEntities.title' | i18n }} ({{ entitiesTotalCount }})
			</h4>
			<div *ngFor="let entityList of entities | keyvalue: keyDescOrder">
				<wcd-datatable
					*ngIf="entityList?.value?.length > 0"
					[items]="getDisplayedEntities(entityList)"
					[columns]="entitiesColumns[entityList.key]"
					[selectEnabled]="false"
					[isItemClickable]="isItemClickable"
					[fullHeight]="false"
					[label]="'entities.impactedEntities.tableAriaLabel.' + entityList.key | i18n"
				></wcd-datatable>
				<a
					*ngIf="!openPanelForAllItems && !showAllItems && areEntitiesTruncated(entityList.key)"
					class="relative wcd-flex-justify-end wcd-after-datatable-link"
					(click)="toggleShowAll(entityList.key)"
					(keydown.enter)="toggleShowAll(entityList.key)"
					tabindex="0"
					role="button"
				>
					{{
						(showAll[entityList.key]
							? 'entities.impactedEntities.showFewer'
							: 'entities.impactedEntities.showMore'
						) | i18n
					}}
				</a>
			</div>
			<div class="wcd-padding-top">
				<a
					*ngIf="openPanelForAllItems && areAnyEntitiesTruncated"
					data-track-type="SidePaneToggleButton"
					data-track-id="OpenAllImpactedEntitiesPanel"
					(click)="openPanel()"
					(keydown.enter)="openPanel()"
					tabindex="0"
					role="button"
				>
					{{ 'entities.impactedEntities.seeAll' | i18n }}
				</a>
			</div>
		</div>
		<ng-template #loader>
			<div class="wcd-flex-1 wcd-flex-center-all">
				<fab-spinner
					[size]="SpinnerSize.large"
					label="{{ 'entities.impactedEntities.loading' | i18n }}"
				></fab-spinner>
			</div>
		</ng-template>
	