import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Paris } from '@microsoft/paris';
import {
	MachineNetworkInfoRelationship,
	MachineNetworkInfo,
	IpAdapter,
	IpWithAdapter,
	AdapterStatus,
	Machine,
	MachineBackendOptions,
} from '@wcd/domain';
import { RegExpService } from '@wcd/shared';
import { finalize, map, tap } from 'rxjs/operators';
import { flatMap } from 'lodash-es';
import { Feature, FeaturesService } from '@wcd/config';

@Component({
	selector: 'machine-ips',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine-ips.component.html',
})
export class MachineIpsComponent implements OnInit {
	machineIps: Array<IpWithAdapter>;
	isLoading: boolean = true;
	hasError: boolean;
	isMacSupportedForMachine: boolean;

	@Input() machine: Machine;

	expandedIps: Set<IpWithAdapter> = new Set();

	readonly backendOptions: MachineBackendOptions;
	constructor(
		private paris: Paris,
		private changeDetector: ChangeDetectorRef,
		private featuresService: FeaturesService
	) {
		this.backendOptions = {
			isUnifiedDevicePage: featuresService.isEnabled(Feature.NewDevicePage),
		};
	}

	ngOnInit() {
		this.paris
			.getRelatedItem<Machine, MachineNetworkInfo>(MachineNetworkInfoRelationship, this.machine, {
				where: this.backendOptions,
			})
			.pipe(
				tap((machineNetworkInfo: MachineNetworkInfo) => {
					this.isMacSupportedForMachine = machineNetworkInfo.isMachineReportingMacAddress;
				}),
				map((machineNetworkInfo: MachineNetworkInfo) =>
					this.extractIps(machineNetworkInfo.ipAdapters).sort(this.compareIps)
				),
				finalize(() => {
					this.isLoading = false;
				})
			)
			.subscribe(
				(ips: Array<IpWithAdapter>) => {
					this.machineIps = ips;
					this.changeDetector.markForCheck();
				},
				(err: any) => {
					this.hasError = true;
					this.changeDetector.markForCheck();
				}
			);
	}

	toggleIp(ip: IpWithAdapter) {
		if (this.expandedIps.has(ip)) this.expandedIps.delete(ip);
		else this.expandedIps.add(ip);
	}

	private extractIps(ipAdapters: Array<IpAdapter>): Array<IpWithAdapter> {
		if (!ipAdapters) {
			return [];
		}

		return flatMap(ipAdapters, adapter => {
			return adapter.ipAddresses.map(ipAddress => ({ ...ipAddress, adapter }));
		});
	}

	private compareIps(a: IpWithAdapter, b: IpWithAdapter): number {
		// Show active adapters first, then with unknown status, and then those which are down
		if (a.adapter.status !== b.adapter.status) {
			switch (a.adapter.status) {
				case AdapterStatus.Up:
					return -1;
				case AdapterStatus.Down:
					return 1;
				case AdapterStatus.Unknown:
					return b.adapter.status === AdapterStatus.Down ? -1 : 1;
			}
		}

		const aAddress = a.address || '';
		const bAddress = b.address || '';
		const aIsIpv4: boolean = RegExpService.ipv4.test(aAddress),
			bIsIpv4: boolean = RegExpService.ipv4.test(bAddress);

		// Show IPv4 addresses first
		if (aIsIpv4 !== bIsIpv4) {
			return aIsIpv4 ? -1 : 1;
		}

		// Then sort by length
		if (aAddress.length !== bAddress.length) {
			return aAddress.length - bAddress.length;
		}
		// Fall back to lexicographic order
		return aAddress.localeCompare(bAddress);
	}
}
