import { DataviewField } from '@wcd/dataview';
import { RemediationCategory, RemediationTicketStateValue, RemediationType, RemediationTaskStateValue, RemediationTaskPriority, ItsmTool, } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { RemediationTaskFixedAssetsPipe } from '../../../../../tvm/pipes/remediation-task-fixed-assets.pipe';
import { RemediationTaskStatusBarComponent } from '../../../../../tvm/components/remediation-task.status-bar/remediation-task.status-bar.component';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../../../tvm/pipes/remediation-task-display-remaining-days.pipe';
import { CalculateRemainingDaysPipe } from '../../../../../shared/pipes/calculate-remaining-days.pipe';
import { TvmTextsService, TextToken } from '../../../../../tvm/services/tvm-texts.service';
import { TruncatePipe } from '../../../../../shared/pipes/truncate.pipe';
import { FeaturesService, Feature } from '@wcd/config';
import _ from 'lodash';
var RemediationTasksFieldsService = /** @class */ (function () {
    function RemediationTasksFieldsService(tzDateService, i18nService, remediationTaskFixedAssetsPipe, calculateRemainingDaysPipe, remediationTaskDisplayRemainingDaysPipe, tvmTextsService, truncatePipe, featuresService) {
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.remediationTaskFixedAssetsPipe = remediationTaskFixedAssetsPipe;
        this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        this.remediationTaskDisplayRemainingDaysPipe = remediationTaskDisplayRemainingDaysPipe;
        this.tvmTextsService = tvmTextsService;
        this.truncatePipe = truncatePipe;
        this.featuresService = featuresService;
    }
    Object.defineProperty(RemediationTasksFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            /*
            //TODO: consider:
            Ben Grynhaus
                FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
                Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
                It does exactly what the C# equivalent does.
            */
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.strings.tvm_common_remediationActivity,
                        minWidth: 350,
                    },
                    {
                        id: 'relatedComponent',
                        name: this.i18nService.strings.tvm_common_relatedComponent,
                        getDisplay: function (remediationTask) {
                            return _this.tvmTextsService.getText(TextToken.RemediationRelatedComponent, remediationTask);
                        },
                    },
                    {
                        id: 'remediationType',
                        name: this.i18nService.strings.tvm_common_remediationType,
                        getDisplay: function (remediationTask) {
                            return _this.i18nService.get("tvm_remediationTask_type_" + (remediationTask.taskArgs.category ===
                                RemediationCategory.SecurityConfiguration
                                ? remediationTask.taskArgs.securityConfigurationArgs.taskType
                                : remediationTask.taskArgs.softwareArgs.taskType));
                        },
                    },
                    {
                        id: 'priority',
                        name: this.i18nService.strings.tvm_common_priority,
                        getDisplay: function (remediationTask) {
                            return remediationTask.priority !== 0
                                ? _this.i18nService.get("tvm_remediationTask_priority_" + RemediationTaskPriority[remediationTask.priority])
                                : _this.i18nService.strings.notAvailable_short;
                        },
                        sort: {
                            sortCompareFunction: function (task1, task2) {
                                return task1.priority - task2.priority;
                            },
                        },
                    },
                    {
                        id: 'status',
                        name: this.i18nService.strings.tvm_remediationTask_taskDetailsDescription,
                        description: this.i18nService.strings.tvm_remediationTask_monitoring_description,
                        component: {
                            type: RemediationTaskStatusBarComponent,
                            getProps: function (remediationTask) { return ({
                                valuePrefix: _this.i18nService.strings.tvm_remediationTask_monitoring_completed,
                                restPrefix: _this.i18nService.strings.tvm_remediationTask_monitoring_incomplete,
                                width: '100%',
                                title: _this.getTruncatedTitle(_this.tvmTextsService.getText(TextToken.RemediationTaskStatusDescription, remediationTask)),
                                value: _this.remediationTaskFixedAssetsPipe.transform(remediationTask),
                                total: remediationTask.targetAssets,
                                showTitleIfNoBar: true,
                                // Hide behind flight to not ruin existing behaviour. We want to show hyphen if remediation type is type AttentionRequired
                                inDataTable: true,
                                showHyphen: remediationTask.taskArgs.softwareArgs &&
                                    remediationTask.taskArgs.softwareArgs.taskType ===
                                        RemediationType.AttentionRequired,
                            }); },
                        },
                        sort: { sortLocally: false },
                        minWidth: 250,
                    },
                    {
                        id: 'itsmService',
                        name: this.i18nService.strings.tvm_remediationTask_ticket_statusTitle,
                        icon: {
                            fabricIcon: function (remediationTask) {
                                if (remediationTask.ticket &&
                                    remediationTask.ticket.status === RemediationTicketStateValue.FailedToCreate) {
                                    return FabricIconNames.Error;
                                }
                                return null;
                            },
                            className: 'color-text-warning-dark',
                        },
                        getDisplay: function (remediationTask) {
                            if (!remediationTask.ticket ||
                                remediationTask.ticket.status === RemediationTicketStateValue.CompletedTask ||
                                (!_this.featuresService.isEnabled(Feature.TvmServiceNowIntegration) &&
                                    remediationTask.ticket.itsmTool === ItsmTool.ServiceNow)) {
                                return _this.i18nService.strings.tvm_remediationTask_noTickets;
                            }
                            else {
                                return _this.tvmTextsService.getText(TextToken.RemediationTicketStatusDescription, remediationTask.ticket);
                            }
                        },
                    },
                    {
                        id: 'createdBy',
                        name: this.i18nService.strings.tvm_common_createdBy,
                        getDisplay: function (remediationTask) {
                            return remediationTask.requester
                                ? remediationTask.requester.email
                                : _this.i18nService.strings.notAvailable_short;
                        },
                    },
                    {
                        id: 'completedBy',
                        name: this.i18nService.strings.tvm_common_completedBy,
                        getDisplay: function (remediationTask) {
                            return _this.tvmTextsService.getText(TextToken.RemediationTaskCompletedBy, remediationTask);
                        },
                    },
                    {
                        id: 'createdOn',
                        name: this.i18nService.strings.tvm_securityRecommendation_relatedRemediation_createdOn,
                        getDisplay: function (remediationTask) {
                            return _this.tzDateService.format(remediationTask.creationTime, 'shortDate');
                        },
                        sort: {
                            sortCompareFunction: function (task1, task2) {
                                return task1.creationTime.getTime() - task2.creationTime.getTime();
                            },
                        },
                    },
                    {
                        id: 'dueDate',
                        name: this.i18nService.strings.tvm_common_dueOn,
                        getDisplay: function (remediationTask) {
                            return remediationTask.taskArgs.softwareArgs &&
                                remediationTask.taskArgs.softwareArgs.taskType ===
                                    RemediationType.AttentionRequired
                                ? _this.i18nService.strings.tvm_remediationTask_noDate
                                : _this.tzDateService.format(remediationTask.dueDate, 'shortDate');
                        },
                        sort: {
                            sortCompareFunction: function (task1, task2) {
                                return task1.dueDate.getTime() - task2.dueDate.getTime();
                            },
                        },
                    },
                    {
                        id: 'remainingDays',
                        name: this.i18nService.strings.tvm_remediationTask_ticket_dueDate,
                        getDisplay: function (remediationTask) {
                            var remainingDays = _this.calculateRemainingDaysPipe.transform(remediationTask.dueDate);
                            return _this.remediationTaskDisplayRemainingDaysPipe.transform(remediationTask, remainingDays);
                        },
                        sort: {
                            sortCompareFunction: function (task1, task2) {
                                return task1.status.value === RemediationTaskStateValue.Completed
                                    ? 1
                                    : task2.status.value === RemediationTaskStateValue.Completed
                                        ? -1
                                        : task1.dueDate.getTime() - task2.dueDate.getTime();
                            },
                        },
                        icon: {
                            fabricIcon: function (remediationTask) {
                                var remainingDays = _this.calculateRemainingDaysPipe.transform(remediationTask.dueDate);
                                if (remediationTask.status.value !== RemediationTaskStateValue.Completed &&
                                    remainingDays < 0) {
                                    return FabricIconNames.Warning;
                                }
                            },
                            className: 'color-text-warning',
                        },
                    },
                ]).filter(function (field) { return !_.isNull(field); });
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    RemediationTasksFieldsService.prototype.getTruncatedTitle = function (title) {
        return this.truncatePipe.transform(title, 45);
    };
    return RemediationTasksFieldsService;
}());
export { RemediationTasksFieldsService };
