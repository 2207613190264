
		<span class="wcd-padding-small-right">
			<fab-icon iconName="System" contentClass="wcd-vertical-align-bottom"></fab-icon>
		</span>
		{{ 'evaluation.lab.configPanel.machines' | i18n: { machinesCount: configuration.numberOfMachines } }}
		<span class="wcd-margin-left ms-color-neutralTertiary ">{{
			'evaluation.lab.configPanel.for' | i18n
		}}</span>
		<span class="wcd-margin-left">
			<span class="wcd-padding-small-right">
				<fab-icon contentClass="wcd-vertical-align-bottom" iconName="Timer"></fab-icon>
			</span>
			{{
				'evaluation.lab.configPanel.hours' | i18n: { hoursPerMachine: configuration.hoursPerMachine }
			}}
		</span>
	