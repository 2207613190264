
		<ng-container *ngLet="(interflowData$ | async) as interflowData">
			<dl class="key-values clearfix" role="none">
				<ng-container *ngIf="entity.address">
					<dt role="none">{{ i18nService.strings.airsEntities_url_fields_address }}</dt>
					<dd role="none">{{ entity.address }}</dd>
				</ng-container>
				<ng-container *ngIf="emailLink.subject">
					<dt role="none">{{ i18nService.strings.airsEntities_url_fields_emailSubject }}</dt>
					<dd role="none">{{ emailLink.subject }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.domainStatus">
					<dt role="none">{{ i18nService.strings.airsEntities_url_fields_domainStatus }}</dt>
					<dd role="none">{{ entity.domainStatus }}</dd>
				</ng-container>
				<ng-container *ngIf="interflowData && interflowData !== LOADING_SYMBOL">
					<ng-container *ngIf="interflowData.created">
						<dt role="none">{{ i18nService.strings.airsEntities_url_fields_createdOn }}</dt>
						<dd role="none">{{ interflowData.created | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="interflowData.expires">
						<dt role="none">{{ i18nService.strings.airsEntities_url_fields_expiredOn }}</dt>
						<dd role="none">{{ interflowData.expires | date: 'short' }}</dd>
					</ng-container>
					<ng-container *ngIf="interflowData.updated">
						<dt role="none">{{ i18nService.strings.airsEntities_url_fields_updatedOn }}</dt>
						<dd role="none">{{ interflowData.updated | date: 'short' }}</dd>
					</ng-container>
					<dt role="none">{{ i18nService.strings.airsEntities_url_fields_registrant }}</dt>
					<dd role="none">
						<generic-object [fieldName]="i18nService.strings.airsEntities_url_fields_registrant">
							<dl class="key-values clearfix" role="none">
								<ng-container *ngIf="interflowData.registrant?.organization">
									<dt role="none">
										{{
											i18nService.strings
												.airsEntities_url_fields_registrant_organization
										}}
									</dt>
									<dd role="none">{{ interflowData.registrant.organization }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.registrant?.phone">
									<dt role="none">
										{{ i18nService.strings.airsEntities_url_fields_registrant_phone }}
									</dt>
									<dd role="none">{{ interflowData.registrant.phone }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.registrant?.email">
									<dt role="none">
										{{ i18nService.strings.airsEntities_url_fields_registrant_email }}
									</dt>
									<dd role="none">{{ interflowData.registrant.email }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.registrant?.owner">
									<dt role="none">
										{{ i18nService.strings.airsEntities_url_fields_registrant_owner }}
									</dt>
									<dd role="none">{{ interflowData.registrant.owner }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.registrant?.mailingAddress">
									<dt role="none">
										{{
											i18nService.strings
												.airsEntities_url_fields_registrant_mailingAddress
										}}
									</dt>
									<dd role="none">{{ interflowData.registrant.mailingAddress }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.registrar?.name">
									<dt role="none">
										{{ i18nService.strings.airsEntities_url_fields_registrant_registrar }}
									</dt>
									<dd role="none">{{ interflowData.registrar.name }}</dd>
								</ng-container>
							</dl>
						</generic-object>
					</dd>
				</ng-container>
				<ng-container
					*ngIf="threatsDisplayNameService.getThreatsDisplayName(url.threats) as threatsDisplay"
				>
					<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
					<dd role="none">{{ threatsDisplay }}</dd>
				</ng-container>
			</dl>
			<ng-container *ngIf="interflowData === LOADING_SYMBOL" [ngTemplateOutlet]="loader"></ng-container>
		</ng-container>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</ng-template>
	