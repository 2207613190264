var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTiIndicatorsType } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { of } from 'rxjs';
var CustomTiIndicatorNewModalComponent = /** @class */ (function (_super) {
    __extends(CustomTiIndicatorNewModalComponent, _super);
    function CustomTiIndicatorNewModalComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.save = new EventEmitter();
        _this.cancel = new EventEmitter();
        _this.boundCanDeactivate = _this.canDeactivate.bind(_this);
        return _this;
    }
    CustomTiIndicatorNewModalComponent.prototype.canDeactivate = function () {
        return of(true);
    };
    CustomTiIndicatorNewModalComponent.prototype.onSave = function () {
        this.save.emit();
        this.closePanel();
    };
    CustomTiIndicatorNewModalComponent.prototype.closePanel = function () {
        this.cancel.emit();
        _super.prototype.closePanel.call(this);
    };
    return CustomTiIndicatorNewModalComponent;
}(PanelContainer));
export { CustomTiIndicatorNewModalComponent };
