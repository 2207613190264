var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Paris } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { OnInit, ChangeDetectorRef, OnDestroy, } from '@angular/core';
import { Router } from '@angular/router';
import { RecommendationExceptionAggregated, RecommendationExceptionAggregatedRelationship, } from '@wcd/domain';
import { RecommendationExceptionsFieldsService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exceptions.fields.service';
import { RecommendationExceptionService } from '../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
var RelatedExceptionsPanelComponent = /** @class */ (function (_super) {
    __extends(RelatedExceptionsPanelComponent, _super);
    function RelatedExceptionsPanelComponent(router, recommendationExceptionsFieldsService, paris, changeDetectorRef, recommendationExceptionService) {
        var _this = _super.call(this, router) || this;
        _this.recommendationExceptionService = recommendationExceptionService;
        _this.repository = paris.getRelationshipRepository(RecommendationExceptionAggregatedRelationship);
        _this.dataViewConfig = {
            id: 'related-exceptions-panel-dataview',
        };
        recommendationExceptionsFieldsService.relatedExceptionsFields.subscribe(function (fields) {
            _this.fields = fields;
            _this.sortDisabledFieldsIds = fields.map(function (f) { return f.id; });
            changeDetectorRef.markForCheck();
        });
        _this._subscription = _this.recommendationExceptionService.exceptionCancelled$.subscribe(function (_) {
            return _this.destroy();
        });
        return _this;
    }
    RelatedExceptionsPanelComponent.prototype.ngOnInit = function () {
        this.settings.scrollBody = false;
        this.repository.sourceItem = this.sourceItem;
    };
    RelatedExceptionsPanelComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    RelatedExceptionsPanelComponent.prototype.openRelatedExceptionPanel = function (recommendationException) {
        this.recommendationExceptionService.openRelatedExceptionsPanel(recommendationException);
    };
    return RelatedExceptionsPanelComponent;
}(PanelContainer));
export { RelatedExceptionsPanelComponent };
