import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsCloudLogonSession } from '@wcd/domain';

@Component({
	selector: 'airs-cloud-logon-session-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudLogonSession_fields_firstSeen }}</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.sessionId">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudLogonSession_fields_sessionId }}</dt>
				<dd role="none">{{ entity.sessionId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.account as account">
				<ng-container *ngIf="account.username">
					<dt role="none">
						{{ i18nService.strings.airsEntities_cloudLogonSession_fields_username }}
					</dt>
					<dd role="none">{{ account.username }}</dd>
				</ng-container>
				<ng-container *ngIf="account.ntDomain">
					<dt role="none">
						{{ i18nService.strings.airsEntities_cloudLogonSession_fields_domain }}
					</dt>
					<dd role="none">{{ account.ntDomain }}</dd>
				</ng-container>
				<ng-container *ngIf="account.sid">
					<dt role="none">
						{{ i18nService.strings.airsEntities_cloudLogonSession_fields_userSid }}
					</dt>
					<dd role="none">{{ account.sid }}</dd>
				</ng-container>
				<ng-container *ngIf="account.userDisplayName">
					<dt role="none">
						{{ i18nService.strings.airsEntities_cloudLogonSession_fields_displayName }}
					</dt>
					<dd role="none">{{ account.userDisplayName }}</dd>
				</ng-container>
			</ng-container>
			<ng-container *ngIf="entity.userAgent">
				<dt role="none">
					{{ i18nService.strings.airsEntities_cloudLogonSession_fields_userAgent }}
				</dt>
				<dd role="none">{{ entity.userAgent }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsCloudLogonSessionDetailsComponent {
	@Input() entity: AirsCloudLogonSession;

	constructor(public i18nService: I18nService) {}
}
