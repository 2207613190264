var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnInit, ChangeDetectorRef, } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { filter } from 'rxjs/operators';
import { EntityModelBase, Paris } from '@microsoft/paris';
import { Machine, SendFeedbackApiCall, SoftwareInstallation, Vulnerability, RecommendationBase, Software, Asset, } from '@wcd/domain';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { TvmTextsService, TextToken, InaccuracyContext } from '../../../services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { TvmReportInaccuracyService } from '../../../services/tvm-report-inaccuracy.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
var ORG_CONTEXTS = [
    InaccuracyContext.OrgInventory,
    InaccuracyContext.OrgVulnerabilities,
    InaccuracyContext.OrgScaRecommendation,
    InaccuracyContext.OrgVaRecommendation,
];
var SOFTWARE_CONTEXTS = [
    InaccuracyContext.OrgInventory,
    InaccuracyContext.MachineInventory,
    InaccuracyContext.MachineVaRecommendation,
    InaccuracyContext.OrgVaRecommendation,
];
var TvmReportInaccuracyPanelComponent = /** @class */ (function (_super) {
    __extends(TvmReportInaccuracyPanelComponent, _super);
    function TvmReportInaccuracyPanelComponent(router, paris, authService, i18nService, dialogsService, activatedEntity, tvmTextsService, changeDetectorRef, reportInaccuracyService, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.paris = paris;
        _this.authService = authService;
        _this.i18nService = i18nService;
        _this.dialogsService = dialogsService;
        _this.activatedEntity = activatedEntity;
        _this.tvmTextsService = tvmTextsService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.reportInaccuracyService = reportInaccuracyService;
        _this.featuresService = featuresService;
        _this.isSending = false;
        _this.canSubmit = false;
        _this.includeEmail = true;
        _this.includeMachineName = true;
        _this.showDisclaimer = false;
        _this.messageBarType = MessageBarType;
        _this.messageBarStyles = {
            root: {
                background: '#FFF4CE',
            },
        };
        _this.reportSent = new EventEmitter();
        _this.submitApiCall = SendFeedbackApiCall;
        _this.activatedEntity.currentEntity$
            .pipe(filter(function (entity) { return entity instanceof Machine; }))
            .subscribe(function (machine) {
            _this.contextMachine = machine;
            if (_this.featuresService.isEnabled(Feature.TvmDataCookingEnableFastLane)) {
                _this.paris.getItemById(Asset, machine.id).subscribe(function (asset) { return _this.showFastlaneIndication = _this.isShowFastlaneIndication(asset); });
            }
        });
        _this.setEmail();
        _this.setMachineName();
        _this._softwareDisclaimer = _this.i18nService.get('tvm.reportInaccuracy.softwareDisclaimer');
        _this._configurationDisclaimer = _this.i18nService.get('tvm.reportInaccuracy.configurationDisclaimer');
        _this._alreadyAppliedReason = _this.i18nService.strings.tvm_reportInaccuracy_reasons_recommendations_alreadyFixed;
        _this.orgScaInaccuracyBlockText = _this.i18nService.strings.tvm_reportInaccuracy_orgScaInaccuracyBlock;
        _this._showSoftwareDisclaimerReasons = [
            _this._alreadyAppliedReason,
            _this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExistInOrg'),
            _this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExist'),
            _this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.wrongVersion'),
            _this.tvmTextsService.otherInaccuracyReason,
        ];
        _this._showConfigurationsDisclaimerReasons = [
            _this._alreadyAppliedReason,
            _this.tvmTextsService.otherInaccuracyReason,
        ];
        return _this;
    }
    Object.defineProperty(TvmReportInaccuracyPanelComponent.prototype, "inaccuracyContext", {
        set: function (context) {
            this._inaccuracyContext = context;
            this._isOrgContext = ORG_CONTEXTS.includes(context);
            this.reasons = this.tvmTextsService.getInaccuracyReasons(context);
            this.reasonsTitle = this.tvmTextsService.getText(TextToken.ReportInaccuracyReasonTitle, context);
            this._isSoftwareContext = SOFTWARE_CONTEXTS.includes(context);
            this.disclaimerText = this._isSoftwareContext
                ? this._softwareDisclaimer
                : this._configurationDisclaimer;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TvmReportInaccuracyPanelComponent.prototype, "contextObject", {
        set: function (obj) {
            this._contextObject = obj;
            if (obj instanceof RecommendationBase) {
                this.contextTitle = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, obj);
            }
            else if (obj instanceof SoftwareInstallation) {
                this.contextTitle = obj.productName;
            }
            else if (obj instanceof Vulnerability) {
                this.contextTitle = obj.name;
            }
            else if (obj instanceof Software) {
                this.contextTitle = obj.vendor + " - " + obj.name;
            }
        },
        enumerable: true,
        configurable: true
    });
    TvmReportInaccuracyPanelComponent.prototype.isMachineContext = function () {
        return this.contextMachine && !this._isOrgContext;
    };
    TvmReportInaccuracyPanelComponent.prototype.setMachineName = function () {
        var _this = this;
        if (this.isMachineContext()) {
            this.machineName = this.includeMachineName ? this.contextMachine.name : null;
            setTimeout(function () { return _this.changeDetectorRef.markForCheck(); });
        }
    };
    TvmReportInaccuracyPanelComponent.prototype.setEmail = function () {
        var _this = this;
        this.email = this.includeEmail ? this.authService.currentUser.name : null;
        setTimeout(function () { return _this.changeDetectorRef.markForCheck(); });
    };
    TvmReportInaccuracyPanelComponent.prototype.submitReport = function () {
        var _this = this;
        this.isSending = true;
        this._contextObject = this.reportInaccuracyService.removePIIandIrrelevantDataFromContextObject(this._contextObject);
        var report = {
            email: this.includeEmail && this.email,
            description: this.createDescription(),
        };
        this.paris.apiCall(this.submitApiCall, report).subscribe(function (result) { return _this.reportSent.emit(result); }, function (error) { return _this.dialogsService.showError({
            title: _this.i18nService.get('feedback.error.title'),
            text: _this.i18nService.get('feedback.error.text'),
            data: error,
        }); });
    };
    TvmReportInaccuracyPanelComponent.prototype.createDescription = function () {
        var descriptionStrings = [
            "'" + this._inaccuracyContext + "'",
            "reason - '" + this.currentReason + "'",
            this._isSoftwareContext ? "software - '" + this._contextObject.id + "'" : '',
            this.notes ? "user notes - '" + this.notes + "'" : '',
            "context object - '" + this.convertObjectToString(this._contextObject) + "'",
        ];
        if (this.isMachineContext()) {
            // machine name is currently can be selected to include with the feedback but it's not sent to the backend
            var machine = this.contextMachine;
            descriptionStrings = descriptionStrings.concat([
                "machine ID - '" + machine.machineId + "'",
                "os:build - '" + machine.os.osPlatformString + ":" + machine.os.fullBuild + "'",
                "first seen - '" + machine.firstSeen + "', last seen - '" + machine.lastSeen + "'",
                "status category - '" + machine.status.category + "'",
                machine.status.description ? "device description - '" + machine.status.description + "'" : '',
            ]);
        }
        return descriptionStrings.filter(Boolean).join(', ');
    };
    TvmReportInaccuracyPanelComponent.prototype.convertObjectToString = function (obj) {
        // function to avoid circular dependencies in the object
        var cache = [];
        var str = JSON.stringify(obj, function (key, value) {
            if (typeof value === 'object' && value !== null) {
                if (cache.indexOf(value) !== -1) {
                    // Duplicate reference found, discard key
                    return;
                }
                cache.push(value);
            }
            return value;
        });
        return str;
    };
    TvmReportInaccuracyPanelComponent.prototype.isShowFastlaneIndication = function (asset) {
        return this.isMachineContext() && asset.isFastlaneSource;
    };
    TvmReportInaccuracyPanelComponent.prototype.updateReasonSelected = function () {
        this.showDisclaimer = this._isSoftwareContext
            ? this._showSoftwareDisclaimerReasons.includes(this.currentReason)
            : this._showConfigurationsDisclaimerReasons.includes(this.currentReason);
        this.showReportForSpecificDeviceText = this._isOrgContext && !this._isSoftwareContext && this.currentReason === this._alreadyAppliedReason;
        this.updateCanSubmit();
    };
    TvmReportInaccuracyPanelComponent.prototype.updateCanSubmit = function () {
        this.canSubmit = this.showReportForSpecificDeviceText ?
            false :
            this.isOtherReasonSelected() ?
                !!this.notes :
                !!this.currentReason;
    };
    TvmReportInaccuracyPanelComponent.prototype.isOtherReasonSelected = function () {
        return this.currentReason === this.tvmTextsService.otherInaccuracyReason;
    };
    return TvmReportInaccuracyPanelComponent;
}(PanelContainer));
export { TvmReportInaccuracyPanelComponent };
