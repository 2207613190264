import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { releaseVersionValues } from './release-version.values';

@Entity({
	singularName: 'Release Version',
	pluralName: 'Release Versions',
	values: releaseVersionValues,
})
export class ReleaseVersion extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() priority?: number;
}
