import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

@Component({
	selector: 'manage-non-windows-third-party',
	templateUrl: './manage-non-windows-third-party.component.html',
})
export class ManageNonWindowsThirdPartyComponent {
	onboarding: boolean;

	constructor(
		private appInsights: AppInsightsService,
		private i18nService: I18nService,
		route: ActivatedRoute
	) {
		this.onboarding = route.snapshot.url.join('') === 'onboarding';
	}
}
