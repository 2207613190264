<!-- tslint:disable:template-click-events-have-key-events -->
<h2 class="wcd-font-weight-bold">{{ 'tvm_authenticatedScan_wizard_job_details_step_title' | i18n }}</h2>

<div class="wcd-margin-large-top wcd-input-placeholder wcd-textarea-placeholder" *ngIf="data && data.assessmentJob">
	<div class="wcd-padding-bottom" *ngIf="!isCredentialEditMode && !data.readOnly" >
		<fab-message-bar
			[messageBarType]="MessageBarType.info"
			[isMultiline]="false"
			>
			<div>
				{{"tvm.authenticatedScan.creationPanel.auth.exisitngEditMessage" | i18n}}
				<a
				data-track-id="editAuth"
				(click)="editExistingAuth()"
				>{{'tvm.authenticatedScan.creationPanel.auth.override' | i18n}}
				</a>
			</div>
		</fab-message-bar>
	</div>

    <div class="wcd-margin-medium-top wcd-margin-mediumSmall-bottom">
        {{ 'tvm_authenticatedScan_wizard_job_details_description' | i18n }}
    </div>

    <div class="wcd-margin-largeMedium-top">
        <wcd-checkbox
			[(ngModel)]="data.assessmentJob.active"
			[checked]="data.assessmentJob.active"
            [label]="'tvm_authenticatedScan_wizard_review_assessment_activate' | i18n"
            [isDisabled]="data.readOnly"
            name="aa">
        </wcd-checkbox>

        <div id="authenticated-scan-target-description" class="wcd-margin-xsmall-top">
            {{ 'tvm_authenticatedScan_wizard_active_description' | i18n }}
        </div>
    </div>

    <label for="authenticated-scan-assessment-name" class="wcd-margin-largeMedium-top wcd-font-weight-semibold required">
        {{ 'tvm_authenticatedScan_wizard_assessment_name_label' | i18n }}
	</label>

    <input
        id="authenticated-scan-assessment-name"
        type="text"
        name="name"
        class="form-control wcd-flex-1"
        maxlength="100"
        [placeholder]="'tvm_authenticatedScan_wizard_assessment_name_placeholder' | i18n"
        [(ngModel)]="data.assessmentJob.scanName"
		[disabled]="data.readOnly"
        required/>

    <label for="authenticated-scan-assessment-device" class="wcd-margin-largeMedium-top wcd-font-weight-semibold required">
        {{ 'tvm_authenticatedScan_wizard_assessment_device_label' | i18n }}
    </label>
    <fancy-select
        id="authenticated-scan-assessment-device"
        name="assessmentDevice"
        [placeholder]="data.assessmentJob.agentName || ('tvm_authenticatedScan_wizard_assessment_device_placeholder' | i18n)"
        [isFullWidth]="true"
        [isBordered]="true"
        [values]="agentNamesList"
        [(ngModel)]="data.assessmentJob.agentName"
		(ngModelChange)="onAgentNameChange($event)"
		[ngClass]="{ 'disabled': !data.isNewDraft }"
		[isDisabled]="!data.isNewDraft || data.readOnly"
        required
        [ariaLabel]="'tvm_authenticatedScan_wizard_assessment_device_label' | i18n"
        [dropdownAreaRole]="'combobox'">
    </fancy-select>

    <label for="authenticated-scan-target" class="wcd-margin-largeMedium-top wcd-font-weight-semibold required">
        {{ 'tvm_authenticatedScan_wizard_target_label' | i18n }}
    </label>
    <div id="authenticated-scan-target-description" class="wcd-margin-xsmall-top">
        {{ 'tvm_authenticatedScan_wizard_target_description' | i18n }}
        <br>
        {{ 'tvm_authenticatedScan_wizard_target_description_test_limit' | i18n: { allowedAddressesCount: assessmentJobService.maxAllowedIpAddressesForScan } }}
    </div>
    <textarea
        id="authenticated-scan-target"
        name="target"
        class="wcd-margin-mediumSmall-top form-control"
        aria-describedby="authenticated-scan-target-description"
        [placeholder]="'tvm_authenticatedScan_wizard_target_placeholder' | i18n"
        [(ngModel)]="data.assessmentJob.originalTargetRanges"
        (ngModelChange)="onAssessmentJobChange()"
		[disabled]="data.readOnly"
        required></textarea>

    <div *ngIf="displaySnmpCredentialComponent()" class="wcd-margin-largeMedium-top">
		<snmp-auth-type
			#snmpAuthTypeForm="snmpAuthType"
			[(auth)]="data.assessmentJob.auth"
			[label]="'tvm_authenticatedScan_wizard_auth_type_label' | i18n"
			(authChange)="reevaluateStepValidityForAuth($event)">
		</snmp-auth-type>
	</div>

	<div *ngIf="displayWindowsCredentialComponent()" class="wcd-margin-largeMedium-top">
		<windows-auth-type
			#windowsAuthTypeForm="windowsAuthType"
			[(auth)]="data.assessmentJob.auth"
			[label]="'tvm_authenticatedScan_wizard_auth_type_label' | i18n"
			(authChange)="reevaluateStepValidityForAuth($event)">
		</windows-auth-type>
	</div>
</div>
