import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { PendingActionHistory } from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';

@Component({
	selector: 'pending-action-history-panel',
	template: `
		<action-history-base-panel
			[entity]="entity"
			[options]="options"
			[isInit]="isInit"
			[moreActionsFilter]="moreActionsFilter"
			[moreActionsEnabled]="moreActionsEnabled"
			(requestMetadataRefresh)="requestMetadataRefresh.emit()"
		>
		</action-history-base-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PendingActionHistoryEntityPanelComponent extends EntityPanelComponentBase<PendingActionHistory> {
	moreActionsEnabled: boolean;
	moreActionsFilter = (obs$: Observable<Array<PendingActionHistory>>) =>
		obs$.pipe(map(actions => actions && actions.filter(action => action.status.isPending)));

	constructor(changeDetectorRef: ChangeDetectorRef, private featuresService: FeaturesService) {
		super(changeDetectorRef);
		this.moreActionsEnabled = this.featuresService.isEnabled(Feature.BulkFileRemediation);
	}
}
