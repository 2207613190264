// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
import { DataviewField } from '@wcd/dataview';
import { NetworkDevicesConnectionState } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { FabricIconNames } from '@wcd/scc-common';
import { I18nService } from '@wcd/i18n';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
var NetworkDevicesFieldsService = /** @class */ (function () {
    function NetworkDevicesFieldsService(tzDateService, i18nService, tvmWeaknessSeverityService, tvmColorService) {
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.tvmWeaknessSeverityService = tvmWeaknessSeverityService;
        this.tvmColorService = tvmColorService;
    }
    Object.defineProperty(NetworkDevicesFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'ipAddress',
                        name: this.i18nService.get('tvm_network_devices_ip'),
                    },
                    {
                        id: 'deviceName',
                        name: this.i18nService.get('tvm_network_devices_name'),
                        getDisplay: function (networkDevice) { return networkDevice.deviceName || _this.i18nService.get('tvm_network_devices_unknown_device_name'); }
                    },
                    {
                        id: 'status',
                        name: this.i18nService.get('tvm_network_devices_status'),
                        getDisplay: function (networkDevice) {
                            if (networkDevice.status === NetworkDevicesConnectionState.Active) {
                                return _this.i18nService.get('tvm_network_devices_active_status');
                            }
                            return _this.i18nService.get('tvm_network_devices_inactive_status');
                        },
                        icon: {
                            fabricIcon: function (networkDevice) { return networkDevice.status !== NetworkDevicesConnectionState.Active ? FabricIconNames.StatusCircleInner : null; },
                            className: function (networkDevice) { return networkDevice.status !== NetworkDevicesConnectionState.Active ? "ms-fontColor-" + _this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name + " field-value-font-size-mi" : ""; },
                        }
                    },
                    {
                        id: 'deviceType',
                        name: this.i18nService.get('tvm_network_devices_device_type'),
                    },
                    {
                        id: 'vendor',
                        name: this.i18nService.get('tvm_network_devices_vendor'),
                    },
                    {
                        id: 'os',
                        name: this.i18nService.get('tvm_network_devices_os'),
                    },
                    {
                        id: 'osVersion',
                        name: this.i18nService.get('tvm_network_devices_os_version'),
                    },
                    {
                        id: 'exposureLevel',
                        name: this.i18nService.get('tvm_network_devices_exposure_level'),
                        description: this.i18nService.get('machines.exposureLevel'),
                        icon: {
                            fabricIcon: function (networkDevice) { return networkDevice.exposureLevel ? FabricIconNames.Warning : null; },
                            className: function (networkDevice) { return _this.tvmWeaknessSeverityService.getWeaknessColorClass(networkDevice.exposureLevel); },
                        },
                    },
                    {
                        id: 'cvesCount',
                        name: this.i18nService.get('tvm_network_devices_cves'),
                    },
                    {
                        id: 'lastScanned',
                        name: this.i18nService.get('tvm_network_devices_last_scanned'),
                        getDisplay: function (networkDevice) {
                            return _this.tzDateService.format(networkDevice.lastScanned, 'short');
                        },
                    },
                    {
                        id: 'firstSeen',
                        name: this.i18nService.get('tvm_network_devices_first_seen'),
                        getDisplay: function (networkDevice) {
                            return _this.tzDateService.format(networkDevice.firstSeen, 'short');
                        },
                        enabledByDefault: false,
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return NetworkDevicesFieldsService;
}());
export { NetworkDevicesFieldsService };
