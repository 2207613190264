import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { EvaluationSetupModel } from '../../models/evaluation-setup.model';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	viewProviders: [SHARED_FORM_PROVIDER],
	template: `
		<simulator-agent-form-content
			[agentsConfig]="data"
			(onMsConsentToggled)="checkValidity()"
			(onAttackIQConsentToggled)="checkValidity()"
			(onProviderToggled)="checkValidity()"
		></simulator-agent-form-content>
	`,
})
export class SimulatorAgentStepComponent extends WizardBaseStep<EvaluationSetupModel> implements OnInit {
	ngOnInit() {
		this.setStepValidation(true);
	}

	checkValidity() {
		// none of the simulators selected - all is valid
		if (!this.data.withSafeBreach && !this.data.withAiq) {
			this.setStepValidation(true);
			return;
		}

		// at least one simulator is selected,
		// and at least one of the MS consent parts is not accepted
		// this is an invalid state
		if (!this.data.msTermsConsentAccepted || !this.data.msInfoSharingConsentAccepted) {
			this.setStepValidation(false);
			return;
		}

		// AttachIQ is selected but the AttackIQ consent was not accepted
		// this is an invalid state
		if (this.data.withAiq && !this.data.attackIQConsentAccepted) {
			this.setStepValidation(false);
			return;
		}

		this.setStepValidation(true);
	}
}
