function parseNameFromUserString(userString) {
    if (!userString)
        return '';
    var startIndex = userString.lastIndexOf('/', userString.lastIndexOf('/') - 1) + 1;
    return userString.substring(startIndex);
}
export function mapMailboxConfigurationEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "ForwardingSmtpAddress" /* ForwardingSmtpAddress */:
                    acc['forwarding_smtp_address'] = val;
                    break;
                case "DeliverToMailboxAndForward" /* DeliverToMailboxAndForward */:
                    acc['deliver_to_mailbox_and_forward'] = val;
                    break;
                case "PerformedBy" /* PerformedBy */:
                    acc['performed_by'] = val;
                    break;
                case "DelegatedToUser" /* DelegatedToUser */:
                    acc['delegated_to_user'] = parseNameFromUserString(val);
                    break;
                case "AccessRights" /* AccessRights */:
                    acc['access_rights'] = val;
                    break;
                case "AccessToMailbox" /* AccessToMailbox */:
                    acc['access_to_mailbox'] = parseNameFromUserString(val);
                    break;
                case "ConfigType" /* ConfigType */:
                    acc['config_type'] = val;
                    break;
                case "MailboxPrimaryAddress" /* MailboxPrimaryAddress */:
                    acc['mailbox_primary_address'] = val;
                    acc[key] = val; // need to save 'MailboxPrimaryAddress' in order to calculate the display_name
                    break;
                case "Upn" /* Upn */:
                    acc['upn'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
