import './app-config.scc'; // Keep first, so global config is initialized first
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { hmrBootstrap } from './hmr';
import appBootstrapScc from './app/app-bootstrap.scc';

if (environment.production || !window.mdatp.wdatpMode) {
	enableProdMode();
}

const bootstrap = () => platformBrowserDynamic().bootstrapModule(AppModule, { preserveWhitespaces: true });

if (environment.hmr) {
	if (module['hot']) {
		hmrBootstrap(module, bootstrap);
	} else {
		console.error('HMR is not enabled for webpack-dev-server!');
		console.log('Are you using the --hmr flag for ng serve?');
	}
} else if (window.mdatp.wdatpMode) {
	bootstrap();
} else {
	// Expose root component for SCC shell to consume
	window.WcdPortal = appBootstrapScc();
}
