<div class="wcd-full-height wcd-flex-vertical">
    <section class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-vertical wcd-scroll-vertical">
        <vulnerability-entity-details
            [vulnerability]="vulnerability"
            [contextOptions]="contextOptions"
        ></vulnerability-entity-details>
    </section>
    <footer *ngIf="entity.impactedAssetsCount" class="wcd-padding-small-top flex-bottom">
        <div class="wcd-flex-none wcd-padding-all wcd-padding-large-horizontal">
            <fab-primary-button
                data-track-id="navigateToRelatedSecurityRecommendation" data-track-type="Button"
                data-track-type="Navigation"
                (onClick)="navigateToSecurityRecommendation()"
                [text]="'tvm.changeEvent.goToRelatedRecommendations' | i18n"
                >
            </fab-primary-button>
        </div>
    </footer>
</div>