<wcd-panel (close)="destroy()" [settings]="settings">
	<div class="wcd-full-height wcd-flex-vertical">
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-large-horizontal">
			<div class="wcd-font-weight-bold wcd-font-size-xl">Alerts ({{incident.alertCount}})</div>
			<hr/>
			<div class="wcd-margin-medium-bottom">
				<fab-default-button
						(click)="export()"
						data-track-type="Export"
						[wcdTooltip]="i18nService.strings.dataview_export_to_csv">
					<wcd-shared-icon iconName="Export"></wcd-shared-icon>
					<span>{{i18nService.strings.dataview_export}}</span>
				</fab-default-button>
			</div>
			<incident-alerts  [incident]="incident"
							  #incidentAlertsComponent
							  class="wcd-flex-1"
							  [filteredFields]="['title', 'severity', 'status']"
							  [sortDisabledFields]="['title', 'severity', 'status']"
							  [hideControls]="true"
							  [allowPaging]="false"
							  [allowFilters]="false"
							  [fixedTable]="true"
							  [navigateOnChange]="false"
							  [ignoreQueryParams]="true"
							  [allowGrouping]="false"
							  dataViewId="incident-alerts-panel"
			></incident-alerts>
		</div>
	</div>
</wcd-panel>
