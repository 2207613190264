import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export interface MachineDirectoryDataIdentifiers {
	dnsName: string;
	canonicalName: string;
	distinguishedName: string;
	sid: string;
}

@Component({
	selector: 'machine-itp-directory-data-identifiers',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl role="none">
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.dnsName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.dnsName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.canonicalName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.canonicalName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.distinguishedName' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.distinguishedName }}
			</dd>
			<dt>
				{{ 'machines.entityDetails.fields.directoryData.identifiers.sid' | i18n }}
			</dt>
			<dd>
				{{ directoryDataIdentifiers.sid }}
			</dd>
		</dl>
	`,
})
export class MachineDirectoryDataIdentifiersComponent {
	@Input() directoryDataIdentifiers: MachineDirectoryDataIdentifiers;
}
