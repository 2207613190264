import { AirsNotificationRuleConditionGroupModel } from './airs-notification-rule-condition-group.model';
import { AirsNotificationRuleConditionGroupsStore } from '../services/airs-notification-rule-condition-groups.store';
import { BehaviorSubject, Observable } from 'rxjs';

export class AirsNotificationRuleConditionModel {
	values: Array<any> = [];

	private _groupBehaviorSubject: BehaviorSubject<
		AirsNotificationRuleConditionGroupModel
	> = new BehaviorSubject<AirsNotificationRuleConditionGroupModel>(null);
	private _group: AirsNotificationRuleConditionGroupModel;
	group$: Observable<AirsNotificationRuleConditionGroupModel> = this._groupBehaviorSubject.asObservable();

	get group(): AirsNotificationRuleConditionGroupModel {
		return this._group;
	}

	set group(group: AirsNotificationRuleConditionGroupModel) {
		this._group = group;
		this._groupBehaviorSubject.next(group);
	}

	get isEmpty(): boolean {
		return !this.group || !this.values.length;
	}

	get isValid(): boolean {
		return !this.group || !!this.values.length;
	}

	constructor(
		notificationRuleConditionGroupsStore: AirsNotificationRuleConditionGroupsStore,
		data?: AirsNotificationRuleConditionConfig
	) {
		if (data) {
			if (data.values) this.values = data.values;

			notificationRuleConditionGroupsStore
				.getItemById(data.group, null, true)
				.subscribe(group => (this.group = group));
		}
	}

	clearValues() {
		this.values = [];
	}
}

export interface AirsNotificationRuleConditionConfig {
	values: Array<any>;
	group: string;
}
