import { AfterViewChecked, Directive, ElementRef, Input, Renderer2 } from '@angular/core';
import { castArray, escapeRegExp, flatMap, without } from 'lodash-es';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';

// See `src/scss/_refactor/core/_directives.scss` for declaration.
const highlightClass = 'text-highlight-directive--highlighted-text';

@Directive({
	selector: '[textHighlight]',
})
export class TextHighlightDirective implements AfterViewChecked, OnChanges<TextHighlightDirective> {
	@Input('textHighlight') highlightText?: string;
	@Input() tags?: keyof HTMLElementTagNameMap | Array<keyof HTMLElementTagNameMap> = ['span', 'p', 'div'];

	private didFirstRender: boolean = false;

	// NOTE: using this flag to avoid reiterating the DOM on every view check - the component this is applied to might needlessly render without any changes to the actual DOM
	// (via changeDetection Default or otherwise), and we don't want to re-highlight unless told to (`updateHighlights()` is called).
	// This is similar to how the Angular renderer works (see `ChangeDetectorRef#markForCheck()`).
	private isHighlightPending: boolean = true;

	constructor(
		private readonly hostElement: ElementRef<HTMLElement>,
		private renderer: Renderer2
	) { }

	ngAfterViewChecked() {
		if (this.isHighlightPending) {
			this.highlightSubtree(this.highlightText, castArray(this.tags));
			this.isHighlightPending = false;
		}

		this.didFirstRender = true;
	}

	ngOnChanges(changes: TypedChanges<TextHighlightDirective>) {
		if (!this.didFirstRender) {
			return;
		}

		const highlightText =
			(changes.highlightText && changes.highlightText.currentValue) || this.highlightText;
		const tags = castArray<keyof HTMLElementTagNameMap>(
			(changes.tags && changes.tags.currentValue) || this.tags
		);

		if (highlightText && tags) {
			this.highlightSubtree(highlightText, tags);
		}
	}

	updateHighlights() {
		this.isHighlightPending = true;
	}

	private highlightSubtree(highlightText: string, tags: ReadonlyArray<keyof HTMLElementTagNameMap>) {
		this.resetHighlights();
		this.applyHighlights(highlightText, tags);
	}

	private resetHighlights() {
		Array.from(this.hostElement.nativeElement.querySelectorAll(`span.${highlightClass}`)).forEach(
			element => element.classList.remove(highlightClass)
		);
	}

	private applyHighlights(highlightText: string, tags: ReadonlyArray<keyof HTMLElementTagNameMap>) {
		const escapedHighlightText = escapeRegExp(highlightText);

		const textContainsHighlightInsensitive = (text: string) =>
			new RegExp(escapedHighlightText, 'ig').test(text);

		const elementsWithText = flatMap(tags, tag => {
			return Array.from(this.hostElement.nativeElement.querySelectorAll(tag)).filter(element =>
				textContainsHighlightInsensitive(element.textContent)
			);
		});

		const deepestElementsWithText = elementsWithText.filter(node =>
			without(elementsWithText, node).every(innerNode => !node.contains(innerNode))
		);

		const findElem = (n: HTMLElement) => {
			if (n.children && n.children.length) {
				Array.from(n.children).forEach(findElem);
			} else {
				if (n.innerHTML && textContainsHighlightInsensitive(n.innerHTML)) {
					// search the requires text and make sure it's not inside any HTML tag, taken from : https://stackoverflow.com/a/16679278
					// This one is the only one that work correctly in all browsers.
					const innerHTML = n.innerHTML.replace(
						new RegExp(`(?![^<]*>)${escapedHighlightText}`, 'ig'),
						text => `<span class="${highlightClass}">${text}</span>`);
					this.renderer.setProperty(n, 'innerHTML', innerHTML);
				}
			}
		};
		deepestElementsWithText.forEach(findElem);
	}
}
