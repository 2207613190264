<!-- tslint:disable:template-accessibility-alt-text -->
<ng-container *ngIf="status" >

  <ng-container *ngIf="showInPage; else showInTable">
    <ng-container *ngTemplateOutlet="contents"></ng-container>
    <span	[ngClass]="status.isError && status.className ? 'color-text-' + status.className : ''">
			{{getLastRunStatusText(true)}}
		</span>
  </ng-container>

  <ng-template #showInTable>
    <span class="nowrap wcd-text-overflow-small" [wcdTooltip]="tooltip"
      [ngClass]="(status.isError && status.className) ? ('color-text-' + status.className) : ''" >
      <ng-container *ngTemplateOutlet="contents"></ng-container>
      <span>{{ getLastRunStatusText() }}</span>
    </span>
  </ng-template>

  <ng-template #contents>
    <fab-icon *ngIf="status.iconName"
      [contentClass]="(showInPage ? 'wcd-margin-small-right' : 'field-value-icon-base') + (status.className ? ' color-text-' + status.className	: '')"
      [iconName]="status.iconName">
    </fab-icon>
    <img *ngIf="status.image"
      [attr.src]="status.image | basePath"
      [ngClass]="showInPage ? 'field-value-icon' : 'field-value-icon-base'" />
  </ng-template>

</ng-container>
