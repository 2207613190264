import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WcdIconsModule } from '@wcd/icons';
import { FabIconModule, FabSpinnerModule } from '@angular-react/fabric';
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { AngularExtensionsModule } from '@wcd/angular-extensions';

import { TooltipsModule } from '@wcd/dialogs';

import { CheckboxComponent } from './components/checkbox.component';
import { ChecklistComponent } from './components/checklist.component';
import { ChecklistDropdownComponent } from './components/checklist-dropdown.component';
import { DropdownComponent } from './components/dropdown.component';
import { NavDropdownComponent } from './components/nav-dropdown.component';
import { SelectComponent } from './components/select.component';
import { RadioListComponent } from './components/radiolist.component';
import { ToggleComponent } from './components/toggle.component';
import { SearchComponent } from './components/search.component';
import { RangeSliderComponent } from './components/range-slider.component';
import { I18nModule } from '@wcd/i18n';
import { TextFieldComponent } from './components/text-field.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { EditableTextComponent } from './components/editable-text.component';

const exportedComponents = [
	CheckboxComponent,
	ChecklistComponent,
	ChecklistDropdownComponent,
	DropdownComponent,
	NavDropdownComponent,
	SelectComponent,
	RadioListComponent,
	ToggleComponent,
	SearchComponent,
	RangeSliderComponent,
	TextFieldComponent,
	EditableTextComponent
];

@NgModule({
	imports: [
		CommonModule,
		FabIconModule,
		FabSpinnerModule,
		FormsModule,
		TooltipsModule,
		A11yModule,
		AngularExtensionsModule,
		WcdIconsModule,
		I18nModule,
		DragDropModule,
		ScrollingModule,
	],
	declarations: exportedComponents,
	exports: exportedComponents,
})
export class WcdFormsModule {}
