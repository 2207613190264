export enum MachineExposureScoreType {
	None = 'None',
	Low = 'Low',
	Medium = 'Medium',
	High = 'High',
}

export interface IMachineExposureScore {
	readonly id: MachineExposureScoreType;
	name: string;
	nameI18nKey?: string;
	className: string;
	icon?: string;
	priority: number;
}
