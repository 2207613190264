<ng-template #tagTemplate let-tagData="itemData">
	<tag [tag]="tagData"></tag>
</ng-template>
<span #overflowContainer *ngIf="!!tags?.length">
	<overflow-container
		[minimumExpendedItems]="minimumExpendedItems"
		[items]="tags"
		[itemsContainer]="tagsContainer"
		[itemTemplate]="tagTemplate"
		[createOverflowItemData]="createOverflowItem"
	>
	</overflow-container>
</span>
