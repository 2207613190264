/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/angular-extensions/src/lib/directives/let.directive";
import * as i4 from "@angular/common";
import * as i5 from "./user.minimized.details.component";
import * as i6 from "../../services/global-entity-types.service";
import * as i7 from "../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_UserMinimizedDetailsComponent = [];
var RenderType_UserMinimizedDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_UserMinimizedDetailsComponent, data: {} });
export { RenderType_UserMinimizedDetailsComponent as RenderType_UserMinimizedDetailsComponent };
function View_UserMinimizedDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [["class", "wcd-flex-center-horizontal wcd-padding-mediumSmall-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "fab-persona", [], null, null, null, i1.View_FabPersonaComponent_0, i1.RenderType_FabPersonaComponent)), i0.ɵdid(5, 5881856, null, 0, i2.FabPersonaComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { size: [0, "size"], imageUrl: [1, "imageUrl"], imageInitials: [2, "imageInitials"], hidePersonaDetails: [3, "hidePersonaDetails"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size24; var currVal_1 = _v.context.ngLet; var currVal_2 = _co.nameInitials; var currVal_3 = true; _ck(_v, 5, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_UserMinimizedDetailsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_UserMinimizedDetailsComponent_1)), i0.ɵdid(2, 16384, null, 0, i3.LetDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngLet: [0, "ngLet"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.iconImageUrl$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_UserMinimizedDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_UserMinimizedDetailsComponent_0, RenderType_UserMinimizedDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i5.UserMinimizedDetailsComponent, [i6.GlobalEntityTypesService, i7.ServiceUrlsService, i8.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var UserMinimizedDetailsComponentNgFactory = i0.ɵccf("ng-component", i5.UserMinimizedDetailsComponent, View_UserMinimizedDetailsComponent_Host_0, { entity: "entity", entityType: "entityType" }, {}, []);
export { UserMinimizedDetailsComponentNgFactory as UserMinimizedDetailsComponentNgFactory };
