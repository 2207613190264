var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { RemediationActionType } from '../remediation-action-type.entity';
import { PendingActionDecision } from './pending-action-decision.entity';
import { AirsEntityIdType } from '../../airs_entity/airs-entity-id-type.value-object';
import { InvestigationPendingType } from '../../investigation/investigation-pending-type.entity';
import { investigationPendingTypeValues } from '../../investigation/investigation-pending-type.values';
import { InvestigationActionStatus } from '../../investigation/actions/investigation-action-status.entity';
import { InvestigationActionStatusId } from '../../investigation/actions/investigation-action-status.values';
var ɵ0 = function (decision) { return decision && decision.toLowerCase(); }, ɵ1 = function (type) {
    var pendingTypeValue = investigationPendingTypeValues.find(function (val) { return val.type === type; });
    return type && pendingTypeValue ? pendingTypeValue.id : null;
}, ɵ2 = function (actionStatus) { return actionStatus && InvestigationActionStatusId[actionStatus]; };
var PendingAction = /** @class */ (function (_super) {
    __extends(PendingAction, _super);
    function PendingAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(PendingAction.prototype, "entityType", {
        get: function () {
            return this.entities && this.entities.length ? this.entities[0].entityType : null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PendingAction.prototype, "waitingTime", {
        get: function () {
            return (this.endTime || new Date()).valueOf() - this.startTime.valueOf();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PendingAction.prototype, "isUserPending", {
        get: function () {
            return this.remediationActionType.isUserPending;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'entity_name' }),
        __metadata("design:type", String)
    ], PendingAction.prototype, "entityName", void 0);
    __decorate([
        EntityField({ parse: ɵ0 }),
        __metadata("design:type", PendingActionDecision)
    ], PendingAction.prototype, "decision", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], PendingAction.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'action_type' }),
        __metadata("design:type", RemediationActionType)
    ], PendingAction.prototype, "remediationActionType", void 0);
    __decorate([
        EntityField({ data: 'action_id' }),
        __metadata("design:type", Number)
    ], PendingAction.prototype, "actionId", void 0);
    __decorate([
        EntityField({ data: 'action_name' }),
        __metadata("design:type", String)
    ], PendingAction.prototype, "actionName", void 0);
    __decorate([
        EntityField({ data: 'start_time' }),
        __metadata("design:type", Date)
    ], PendingAction.prototype, "startTime", void 0);
    __decorate([
        EntityField({ data: 'end_time' }),
        __metadata("design:type", Date)
    ], PendingAction.prototype, "endTime", void 0);
    __decorate([
        EntityField({ arrayOf: AirsEntityIdType }),
        __metadata("design:type", Array)
    ], PendingAction.prototype, "entities", void 0);
    __decorate([
        EntityField({
            data: 'PendingType',
            parse: ɵ1,
        }),
        __metadata("design:type", InvestigationPendingType)
    ], PendingAction.prototype, "pendingType", void 0);
    __decorate([
        EntityField({
            data: 'ActionStatus',
            parse: ɵ2,
        }),
        __metadata("design:type", InvestigationActionStatus)
    ], PendingAction.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'IsOfficeAction' }),
        __metadata("design:type", Boolean)
    ], PendingAction.prototype, "isOfficeAction", void 0);
    PendingAction = __decorate([
        ValueObject({
            singularName: 'Pending Action',
            pluralName: 'Pending Actions',
        })
    ], PendingAction);
    return PendingAction;
}(ModelBase));
export { PendingAction };
export { ɵ0, ɵ1, ɵ2 };
