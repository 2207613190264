import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('sidePaneIndicator', sidePaneIndicatorDirective);

sidePaneIndicatorDirective.$inject = ['sidePaneService'];

function sidePaneIndicatorDirective(sidePaneService) {
	return {
		restrict: 'C',
		link: function(scope, element, attrs, ctrl) {
			var vm = scope.pi;
			if (!vm) {
				throw new Error(
					"In order to use the side pane indicator you have to set bindToController=true and controllerAs='pi'"
				);
			}

			vm.selected = sidePaneService.isSelected(vm.key);
			vm.icon = vm.selected ? 'CompletedSolid' : 'CortanaLogoOuter';

			if (!vm.hideIndicator) {
				$(element)
					.first()
					.on('mouseenter', function() {
						if (!vm.selected) {
							scope.$apply(function() {
								vm.icon = 'Completed';
							});
						}
					});

				$(element)
					.first()
					.on('mouseleave', function() {
						if (!vm.selected) {
							scope.$apply(function() {
								vm.icon = vm.selected ? 'CompletedSolid' : 'CortanaLogoOuter';
							});
						}
					});
			}
		},
		controller: [
			'$rootScope',
			'$scope',
			function($rootScope, $scope) {
				var vm = $scope.pi;

				if (!vm.hideIndicator) {
					$rootScope.$on('sidePane:paneUpdated', function(event, payload) {
						if (!payload || !payload.opened || !payload.key) {
							vm.selected = false;
							vm.icon = 'CortanaLogoOuter';
						} else {
							if (payload.isSelected($scope.pi.key)) {
								vm.selected = true;
							} else {
								vm.selected = false;
							}

							vm.icon = vm.selected ? 'CompletedSolid' : 'CortanaLogoOuter';
						}
					});
				}

				vm.isActive = function() {
					return vm.selected;
				};

				$scope.toggle = function($event) {
					if (vm.ignoreClick) return;

					$event.stopPropagation();

					if (vm.isActive()) {
						sidePaneService.close();
						return;
					}

					if (typeof vm.togglePane !== 'function') {
						throw new Error(
							"togglePane function was not defined in your directive scope, pane can't be executed"
						);
					}

					vm.togglePane();
				};
			},
		],
	};
}
