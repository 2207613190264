import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { VulnerabilityEntityTypeService } from './services/vulnerability.entity-type.service';
import { VulnerabilityEntityPanelComponent } from './components/vulnerability.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { VulnerabilityFieldsService } from './services/vulnerabilities.fields.service';
import { VulnerabilityService } from './services/vulnerability.service';
import { VulnerabilityPanelComponent } from './components/vulnerability-panel.component';
import { PanelsModule } from '@wcd/panels';
import { TvmModule } from '../../../tvm/tvm.module';
import { TagsModule } from '../../../tags/tags.module';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, PanelsModule, TvmModule, TagsModule],
	declarations: [VulnerabilityPanelComponent, VulnerabilityEntityPanelComponent],
	exports: [VulnerabilityPanelComponent, VulnerabilityEntityPanelComponent],
	entryComponents: [VulnerabilityPanelComponent, VulnerabilityEntityPanelComponent],
	providers: [VulnerabilityService, VulnerabilityFieldsService, VulnerabilityEntityTypeService],
})
export class VulnerabilityEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(VulnerabilityEntityTypeService);
	}
}
