
		<div class="wcd-checkbox" [class.disabled]="isDisabled">
			<input
				type="checkbox"
				[checked]="checked"
				(change)="_onInteractionEvent($event)"
				[id]="id"
				[name]="name"
				[disabled]="isDisabled"
			/>

			<ng-container *ngIf="setKeyboardSupport; else noKeyboardSupport">
				<label
					[attr.role]="ariaRole"
					[attr.aria-checked]="checked"
					(keydown.space)="_onInteractionEvent($event)"
					[attr.for]="id"
					[wcdTooltip]="tooltipText || (isDisabled && disableReason)"
					[wcdTooltipShowOnOverflowOnly]="tooltipOnOverFlowOnly"
					class="wcd-flex-horizontal"
					[class.invalid]="isInvalid"
					keyboard-navigable-element
					[elementVisible]="elementVisible"
					(onTabIndexChange)="focusChange.emit($event)"
					[attr.aria-disabled]="isDisabled"
					[class.force-wrap-normal]="wrapLabel"
				>
					<ng-container *ngTemplateOutlet="checkboxContent"></ng-container>
				</label>
			</ng-container>
		</div>

		<ng-template #noKeyboardSupport>
			<label
				[attr.role]="ariaRole"
				tabindex="0"
				[attr.aria-checked]="!isPartiallyChecked ? checked : 'mixed'"
				(keydown.space)="_onInteractionEvent($event)"
				[attr.for]="id"
				[wcdTooltip]="tooltipText || (isDisabled && disableReason)"
				[wcdTooltipShowOnOverflowOnly]="tooltipOnOverFlowOnly"
				class="wcd-flex-horizontal"
				[class.invalid]="isInvalid"
				[attr.aria-disabled]="isDisabled"
				[class.force-wrap-normal]="wrapLabel"
			>
				<ng-container *ngTemplateOutlet="checkboxContent"></ng-container>
			</label>
		</ng-template>

		<ng-template #checkboxContent>
			<div class="wcd-flex-none">
				<i *ngIf="isPartiallyChecked; else checkboxIcon" class="wcd-checkbox--partial"></i>
				<ng-template #checkboxIcon>
					<fab-icon
						[iconName]="checked ? 'CheckboxCompositeReversed' : 'Checkbox'"
						[contentClass]="checked ? 'checked' : ''"
					></fab-icon>
				</ng-template>
			</div>
			<div class="wcd-flex-1 wcd-checkbox-label-contents">
				{{ label }}
				<ng-content></ng-content>
			</div>
		</ng-template>
	