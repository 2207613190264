<div *ngIf="remediationTask" class="wcd-scroll-vertical wcd-margin-medium-top">

	<div class="tvm-list__block_item" *ngIf="shouldShowMessageBar">
	<fab-message-bar  [messageBarType]="messageBarType" dismissable="true" (onDismiss)="hideMessage()">
		{{messageBarString}}
	</fab-message-bar>
</div>
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">

		<div class="tvm-list__header">{{'tvm.remediationTask.description' | i18n}}</div>
		<div class="tvm-list__block_item" [innerHTML]="remediationTask.description || ('notAvailable.short' | i18n)"></div>
		<ng-container *ngIf="shouldShowDeviceScope">
			<div class="tvm-list__header">{{'tvm.remediationTask.machineGroupsScopeHeader' | i18n}}</div>
			<div class="tvm-list__block_item">
				<div [title]="hoverMachineGroups" [innerHTML]="numberOfMachineGroupsText"></div>
			</div>
		</ng-container>
		<ng-container *ngIf="showTable">
			<div class="tvm-list__header">{{'tvm.remediationTask.ticket.itsmTools' | i18n}}</div>

			<div class="tvm-list__key_val_item">
				<dt>{{'tvm.remediationTask.ticket.serviceName' | i18n}}</dt>
				<dd>{{remediationTask.ticket.itsmTool}}</dd>
			</div>
			<div class="tvm-list__key_val_item">
				<dt>{{'tvm.remediationTask.ticket.statusTitle' | i18n}}</dt>
				<dd>
					<ng-container *ngIf="remediationTask.ticket.status == 'FailedToCreate'">
						<fab-icon iconName="Error" contentClass="color-text-warning-dark" [wcdTooltip]="'tvm.remediationTask.ticket.failedToCreateTooltip' | i18n"></fab-icon>
					</ng-container>
					{{remediationTicketStatus || ('notAvailable_short' | i18n)}}
				</dd>
			</div>

			<div *ngIf="remediationTask.ticket.deepLink" class="tvm-list__key_val_item">
				<dt>{{'tvm.remediationTask.ticket.deepLink' | i18n}}</dt>
				<a [href]="remediationTask.ticket.deepLink" target="_blank" rel="noopener noreferrer" style="align-self: flex-start">{{'tvm.remediationTask.ticket.goToTicket' | i18n}}</a>
			</div>

			<div *ngIf="remediationTask.ticket.assignedTo" class="tvm-list__key_val_item">
				<dt>{{'tvm.remediationTask.ticket.assignedTo' | i18n}}</dt>
				<dd>{{remediationTask.ticket.assignedTo}}</dd>
			</div>

			<div *ngIf="remediationTask.ticket.itNotes" class="tvm-list__key_val_item tvm-list__key_val_item--span-2">
				<dt>{{'tvm.remediationTask.ticket.notes' | i18n}}</dt>
				<dd>{{remediationTask.ticket.itNotes}}</dd>
			</div>
		</ng-container>

		<div class="tvm-list__header">{{'tvm.remediationTask.taskDetailsDescription' | i18n}}</div>

		<div class="tvm-list__block_item">
			<remediation-status-bar
				[title]="remediationStatusBarTitle"
				[valuePrefix]="'tvm.remediationTask.monitoring.completed' | i18n"
				[restPrefix]="'tvm.remediationTask.monitoring.incomplete' | i18n"
				[showLegend]="true"
				[showTitleIfNoBar]="false"
				[value]="remediationTask | remediationTaskFixedAssets"
				[total]="remediationTask.targetAssets"
				[showHyphen]="showHyphenForRemediationTaskStatusBar"
				[inDataTable]="false"
				width="100%">
			</remediation-status-bar>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.securityRecommendation.relatedRemediation.createdOn' | i18n}}</dt>
			<dd>{{(remediationTask.creationTime | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.common.createdBy' | i18n}}</dt>
			<dd>{{remediationTask.requester?.email || ('notAvailable.short' | i18n)}}</dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.common.priority' | i18n}}</dt>
			<dd>{{priorityText}}</dd>
		</div>

		<div *ngIf="remediationTask.taskArgs.category === 'Software'" class="tvm-list__key_val_item">
			<dt>{{'tvm.common.relatedComponent' | i18n}}</dt>
			<dd>
				<a
					data-track-id="remediationNavigateToRelatedComponent"
					data-track-type="Navigation"
					role="link"
					tabindex="0"
					(keydown.enter)="navigateToRelatedComponent()"
					(click)="navigateToRelatedComponent()">
					{{relatedComponent}}
				</a>
			</dd>
		</div>

		<div *ngIf="showRequestedForSection" class="tvm-list__key_val_item">
			<dt>{{'tvm.remediationTask.requestedFor' | i18n}}</dt>
			<dd [innerHTML]=appliedToProductivityImpactLabel></dd>
		</div>

		<ng-container *ngIf="remediationTask.status.value === 'Completed'">
			<div class="tvm-list__key_val_item">
				<dt>{{'tvm.securityRecommendation.relatedRemediation.completedOn' | i18n}}</dt>
				<dd>{{remediationTask.status.lastModifiedOn | date:'shortDate'}}</dd>
			</div>

			<div class="tvm-list__key_val_item">
				<dt>{{'tvm.common.completedBy' | i18n}}</dt>
				<dd>{{completedBy}}</dd>
			</div>
		</ng-container>

		<div *ngIf="showDueDate" class="tvm-list__key_val_item">
			<dt>{{'tvm.remediationTask.ticket.dueDate' | i18n}}</dt>
			<dd>{{remainingDays}} <ng-container *ngIf="remediationTask.status.value!=='Completed'">({{remediationTask.dueDate | date: 'shortDate'}})</ng-container></dd>
		</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.remediationTask.ticket.notes' | i18n}}</dt>
			<dd>{{remediationTask.requesterNotes || ('tvm.remediationTask.ticket.notes.none' | i18n)}}</dd>
		</div>

		<div class="tvm-list__block_item">
			<a
				data-track-id="navigateToRecommendations"
				data-track-type="Navigation"
				role="link"
				tabindex="0"
				(keydown.enter)="navigateToRecommendations()"
				(click)="navigateToRecommendations()"
				>{{'tvm.common.viewRecommendation' | i18n}}
			</a>
		</div>
	</div>
</div>
