import { Routes } from '@angular/router';
import { ErrorPageComponent } from './components/error-page.component';
var ɵ0 = {
    pageTitle: 'Errors page',
}, ɵ1 = {
    pageTitle: 'Errors page',
}, ɵ2 = {
    isLoginError: true,
    pageTitle: 'Error page',
}, ɵ3 = {
    isLoginError: true,
    pageTitle: 'Error page',
};
export var errorRoutes = [
    {
        path: 'errors',
        component: ErrorPageComponent,
        data: ɵ0,
    },
    {
        path: 'errors/:type',
        component: ErrorPageComponent,
        data: ɵ1,
    },
    {
        path: 'Error',
        component: ErrorPageComponent,
        data: ɵ2,
    },
    {
        path: 'Error/:type',
        component: ErrorPageComponent,
        data: ɵ3,
    },
];
var ErrorModule = /** @class */ (function () {
    function ErrorModule() {
    }
    return ErrorModule;
}());
export { ErrorModule };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
