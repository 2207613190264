/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "./impacted-entities-datatables.component";
import * as i10 from "@microsoft/paris/dist/lib/paris";
import * as i11 from "../../../dialogs/services/dialogs.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_ImpactedEntitiesDatatablesComponent = [];
var RenderType_ImpactedEntitiesDatatablesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ImpactedEntitiesDatatablesComponent, data: {} });
export { RenderType_ImpactedEntitiesDatatablesComponent as RenderType_ImpactedEntitiesDatatablesComponent };
function View_ImpactedEntitiesDatatablesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h4", [["class", "definition-list-title"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t", " (", ")\n\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "entities.impactedEntities.title")); var currVal_1 = _co.entitiesTotalCount; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ImpactedEntitiesDatatablesComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-datatable", [], null, null, null, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, [[1, 4]], 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], isItemClickable: [2, "isItemClickable"], columns: [3, "columns"], fullHeight: [4, "fullHeight"], label: [5, "label"] }, null), i0.ɵppd(2, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getDisplayedEntities(_v.parent.context.$implicit); var currVal_1 = false; var currVal_2 = _co.isItemClickable; var currVal_3 = _co.entitiesColumns[_v.parent.context.$implicit.key]; var currVal_4 = false; var currVal_5 = i0.ɵunv(_v, 1, 5, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), ("entities.impactedEntities.tableAriaLabel." + _v.parent.context.$implicit.key))); _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
function View_ImpactedEntitiesDatatablesComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "relative wcd-flex-justify-end wcd-after-datatable-link"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleShowAll(_v.parent.context.$implicit.key) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.toggleShowAll(_v.parent.context.$implicit.key) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent.parent, 0), (_co.showAll[_v.parent.context.$implicit.key] ? "entities.impactedEntities.showFewer" : "entities.impactedEntities.showMore"))); _ck(_v, 1, 0, currVal_0); }); }
function View_ImpactedEntitiesDatatablesComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesDatatablesComponent_4)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesDatatablesComponent_5)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (((_v.context.$implicit == null) ? null : ((_v.context.$implicit.value == null) ? null : _v.context.$implicit.value.length)) > 0); _ck(_v, 3, 0, currVal_0); var currVal_1 = ((!_co.openPanelForAllItems && !_co.showAllItems) && _co.areEntitiesTruncated(_v.context.$implicit.key)); _ck(_v, 6, 0, currVal_1); }, null); }
function View_ImpactedEntitiesDatatablesComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["data-track-id", "OpenAllImpactedEntitiesPanel"], ["data-track-type", "SidePaneToggleButton"], ["role", "button"], ["tabindex", "0"]], null, [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openPanel() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.openPanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "entities.impactedEntities.seeAll")); _ck(_v, 1, 0, currVal_0); }); }
function View_ImpactedEntitiesDatatablesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "wcd-impacted-entities-datatable-container"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesDatatablesComponent_2)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_ImpactedEntitiesDatatablesComponent_3)), i0.ɵdid(6, 278528, null, 0, i5.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i0.ɵpid(0, i5.KeyValuePipe, [i0.KeyValueDiffers]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 4, "div", [["class", "wcd-padding-top"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesDatatablesComponent_6)), i0.ɵdid(12, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showTitle; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.entities, _co.keyDescOrder)); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.openPanelForAllItems && _co.areAnyEntitiesTruncated); _ck(_v, 12, 0, currVal_2); }, null); }
function View_ImpactedEntitiesDatatablesComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-flex-1 wcd-flex-center-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "fab-spinner", [], null, null, null, i6.View_FabSpinnerComponent_0, i6.RenderType_FabSpinnerComponent)), i0.ɵdid(4, 5816320, null, 0, i7.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { size: [0, "size"], label: [1, "label"] }, null), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.large; var currVal_1 = i0.ɵinlineInterpolate(1, "", i0.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "entities.impactedEntities.loading")), ""); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_ImpactedEntitiesDatatablesComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.I18nPipe, [i4.I18nService]), i0.ɵqud(671088640, 1, { tables: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ImpactedEntitiesDatatablesComponent_1)), i0.ɵdid(4, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["loader", 2]], null, 0, null, View_ImpactedEntitiesDatatablesComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.dataLoaded; var currVal_1 = i0.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
export function View_ImpactedEntitiesDatatablesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "impacted-entities-datatables", [], null, null, null, View_ImpactedEntitiesDatatablesComponent_0, RenderType_ImpactedEntitiesDatatablesComponent)), i0.ɵdid(1, 638976, null, 0, i9.ImpactedEntitiesDatatablesComponent, [i10.Paris, i0.ChangeDetectorRef, i11.DialogsService, i4.I18nService, i12.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ImpactedEntitiesDatatablesComponentNgFactory = i0.ɵccf("impacted-entities-datatables", i9.ImpactedEntitiesDatatablesComponent, View_ImpactedEntitiesDatatablesComponent_Host_0, { entities: "entities", showTitle: "showTitle", showAllItems: "showAllItems", openPanelForAllItems: "openPanelForAllItems", maxItems: "maxItems" }, {}, []);
export { ImpactedEntitiesDatatablesComponentNgFactory as ImpactedEntitiesDatatablesComponentNgFactory };
