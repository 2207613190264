import { Component, OnInit, Type, Input, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { EmailNotificationFormData } from './email-notification-model';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './rule-name-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RuleNameStepComponent<T extends EmailNotificationFormData> extends WizardBaseStep<T>
	implements OnInit {
	MessageBarType = MessageBarType;

	/*
		Some types of notifications include status field.
		If includeStatus == true, then there is a checkbox which enables the user to enable/disable the created rule.

		Rule description is changed between different types of notifications.
	*/
	@Input() includeStatus = false;
	@Input() ruleNameDescription: string;
	@Input() nameLength = 100;
	@Input() descriptionLength = 1000;
	@Input() nameRegExp = 'notEmpty';
	@Input() descriptionRegExp = '';
	constructor(public i18n: I18nService, private changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.setStepValidation(true);
		setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
	}

	onModelChange() {
		this.changeDetectorRef.detectChanges();
	}
}
