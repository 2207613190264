var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { sccHostService } from '@wcd/scc-interface';
var SccLogService = /** @class */ (function () {
    function SccLogService(sccLogger) {
        this.sccLogger = sccLogger;
    }
    SccLogService.prototype.trackEvent = function (name, properties) {
        this.sccLogger.logEvent("wicd-" + name, properties);
    };
    SccLogService.prototype.trackException = function (exception, properties) {
        if (!exception || !exception.message) {
            this.sccLogger.logError(exception);
            return;
        }
        this.sccLogger.logError(exception.message || exception.name, __assign({ stack: exception.stack }, properties));
    };
    /**
     * Measures and logs the time in milliseconds an event took.
     * A call to markPerformanceEventStart with the same event key must take place at the event start, before calling this function.
     * @param event: key of the measured event
     * @param properties: additional data to append to the log.
     */
    SccLogService.prototype.trackPerformance = function (event, properties) {
        var eventName = "measurements-" + event;
        performance.measure(eventName, event);
        var entries = performance.getEntriesByName(eventName);
        if (!entries || entries.length === 0) {
            return;
        }
        var totalTime = entries.pop().duration;
        this.trackEvent(eventName, __assign({ totalTime: totalTime }, properties));
    };
    /**
     * Marks the start of a measured event, for performance tracking.
     * Call trackPerformance(event) To finish the measure and log the result.
     * @param event: key of the measured event
     */
    SccLogService.prototype.markPerformanceEventStart = function (event) {
        var events = Array.isArray(event) ? event : [event];
        events.forEach(function (e) { return performance.mark(e); });
    };
    SccLogService.prototype.getResourceLoadingByPkg = function (pkgName) {
        //@ts-ignore
        var items = performance.getEntries().filter(function (e) { return (e.initiatorType === 'script' || e.initiatorType === 'fetch') && e.name.includes("/" + pkgName + "/"); });
        return items.map(function (sc) { return ({
            file: sc.name.replace(/^.*[\\\/]/, ''),
            duration: sc.duration
        }); });
    };
    SccLogService.prototype.trackSccScriptLoadDurationsByPackage = function (pkgName) {
        try {
            var props = {
                pkgName: pkgName,
                pckVersion: sccHostService.resource.version(pkgName),
                measurements: this.getResourceLoadingByPkg(pkgName)
            };
            this.trackEvent('wcd-script-load-durations-' + pkgName, props);
        }
        catch (e) {
            console.warn('Failed to track scriptLoadDuration');
        }
    };
    SccLogService.prototype.registerUrlPiiScrubber = function (urlPiiScrubber, scrubberConfig) {
        this.sccLogger.registerUrlPiiScrubber(urlPiiScrubber, scrubberConfig);
    };
    SccLogService.prototype.registerHeadersPiiScrubber = function (headersPiiScrubber, scrubberConfig) {
        this.sccLogger.registerHeadersPiiScrubber(headersPiiScrubber, scrubberConfig);
    };
    SccLogService.prototype.registerApiErrorPiiScrubber = function (apiErrorPiiScrubber, scrubberConfig) {
        this.sccLogger.registerApiErrorPiiScrubber(apiErrorPiiScrubber, scrubberConfig);
    };
    SccLogService.prototype.registerScenarioPiiScrubber = function (scenarioPiiScrubber, scrubberConfig) {
        this.sccLogger.registerScenarioPiiScrubber(scenarioPiiScrubber, scrubberConfig);
    };
    SccLogService.prototype.scrubScenarioPii = function (data, scenarioName) {
        return this.sccLogger.scrubScenarioPii(data, scenarioName);
    };
    return SccLogService;
}());
export { SccLogService };
