<!-- tslint:disable:template-accessibility-alt-text -->
<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-full-height wcd-flex-horizontal wcd-convergence-widget">
	<div class="promotion-widget-banner-image wcd-flex-center-all wcd-padding-xLarge-horizontal wcd-padding-medium-vertical">
		<img src='/assets/images/promotion/promotion-banner-widget.svg' />
	</div>

	<div class='wcd-padding-large-all'>
		<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">
			{{ title }}
		</h2>
		<div class="wcd-padding-bottom">
			<markdown class="wcd-convergence-markdown" [data]="desc"></markdown>
		</div>

		<div class="wcd-padding-medium-top wcd-flex-center-vertical wcd-flex-wrap-horizontal ">
			<!--(click)="add.emit()"-->
			<div class="wcd-padding-medium-right wcd-flex-none">
				<fab-primary-button
					data-track-id="PromotionBannerExploreClick"
					data-track-type="Button"
					[href]='navigateToUrl'
					target='_blank'>
					<wcd-shared-icon [iconName]="openSccIcon" class='wcd-padding-small-right'> </wcd-shared-icon>
					{{ buttonTitle }}
				</fab-primary-button>
			</div>
			<a *ngIf="!isL2Enabled"
				data-track-id="PromotionBannerDismissClick"
				data-track-type="Button"
				class='wcd-flex-none wcd-padding-small-vertical'
				(click)='dismiss()'>
				{{ 'promotion.action.remindme' | i18n }}
			</a>
		</div>
	</div>

	<button *ngIf="!isL2Enabled"
		class="close-icon btn-icon-only btn-unstyled wcd-font-size-s wcd-margin-small-top"
		data-track-id="PromotionBannerCloseClick"
		data-track-type="Button"
		(click)="dismiss()">
		<wcd-shared-icon iconName="close"> </wcd-shared-icon>
	</button>

</div>
