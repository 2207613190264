import { InvestigationStatusEnum } from './investigation-status.enum';
import { PendingTypeEnum } from './pending-type.enum';

export const investigationPendingTypeValues = [
	{
		id: 1,
		type: 'pendingUncovered',
		name: 'Pending uncovered devices',
		isCoveragePending: true,
	},
	{
		id: 2,
		type: PendingTypeEnum.Resource,
		name: 'Waiting for device',
		equivalentStatus: InvestigationStatusEnum.pendingResource,
	},
	{
		id: 3,
		type: PendingTypeEnum.User,
		name: 'Pending approval',
		isUserPending: true,
		equivalentStatus: InvestigationStatusEnum.pendingUser,
	},
	{
		id: 4,
		name: 'Pending user retry',
		type: 'pendingUserRetry',
		allowRetry: true,
	},
	{
		id: 5,
		name: 'Invalid credentials',
		type: PendingTypeEnum.InvalidCredentials,
		allowRetry: true,
		allowEnterCredentials: true,
	},
	{
		id: 6,
		name: 'Host not found',
		type: PendingTypeEnum.HostNotFound,
		allowRetry: true,
		allowUnmanagedHost: true,
	},
];
