import { ChangeDetectorRef, EventEmitter, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Disableable } from '../models/disableable.interface';
var lastId = 0;
var CheckboxComponent = /** @class */ (function () {
    function CheckboxComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.id = "checkbox-" + lastId++;
        this.tooltipOnOverFlowOnly = true;
        this.isPartiallyChecked = false;
        this.isInvalid = false;
        this.setKeyboardSupport = false;
        this.ariaRole = "checkbox";
        this.wrapLabel = false;
        this.elementVisible = true;
        this.change = new EventEmitter();
        this.focusChange = new EventEmitter();
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(CheckboxComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            if (checked != this.checked) {
                this._checked = checked;
                this.changeDetectorRef.markForCheck();
            }
        },
        enumerable: true,
        configurable: true
    });
    CheckboxComponent.prototype.writeValue = function (checked) {
        this.checked = !!checked;
    };
    CheckboxComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    CheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Toggles the `checked` value between true and false
     */
    CheckboxComponent.prototype.toggle = function () {
        this.checked = !this.checked;
    };
    /**
     * Event handler for checkbox input element.
     * Toggles checked state if element is not disabled.
     * @param event
     */
    CheckboxComponent.prototype._onInteractionEvent = function (event) {
        // We always have to stop propagation on the change event.
        // Otherwise the change event, from the input element, will bubble up and
        // emit its event object to the `change` output.
        event.stopPropagation();
        event.preventDefault();
        if (!this.isDisabled) {
            this.toggle();
            this.onChange(this.checked);
            this.change.emit(this.checked);
        }
    };
    return CheckboxComponent;
}());
export { CheckboxComponent };
