export const enum ActiveDirectoryDomainEntityAlertV3Fields {
	ActiveDirectoryDomainName = 'ActiveDirectoryDomainName',
	Id = 'Id',
}

export function mapActiveDirectoryDomainEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case ActiveDirectoryDomainEntityAlertV3Fields.ActiveDirectoryDomainName:
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					acc['name'] = val;
					break;
				case ActiveDirectoryDomainEntityAlertV3Fields.Id:
					acc['id'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
