import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: 'Category report access trend',
	pluralName: 'category report access trends',
	readonly: true,
})
export class WebContentFilteringAccessTrend extends ModelBase {
	@EntityField({ data: 'Date' })
	date: Date;

	@EntityField({ data: 'Count' })
	count: number;
}

@ValueObject({
	singularName: 'Category report device',
	pluralName: 'Category report devices',
	readonly: true,
})
export class WebContentFilteringDevice extends ModelBase {
	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'DeviceId' })
	deviceId: string;

	@EntityField({ data: 'Count' })
	count: number;
}

@ValueObject({
	singularName: 'Category report domain',
	pluralName: 'Category report domains',
	readonly: true,
})
export class WebContentFilteringDomain extends ModelBase {
	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'Count' })
	count: number;
}
