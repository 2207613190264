import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';

@Entity({
	singularName: 'Antivirus Remediation status',
	pluralName: 'Antivirus Remediation statuses',
	values: [
		{
			id: 1,
			name: 'Remediated by AV',
			type: 'remediatedByAv',
			isRemediated: true,
		},
		{
			id: 2,
			name: 'Remediated by AV and restored',
			type: 'remediatedByAvAndRecreated',
		},
	],
})
export class AntivirusRemediationStatus extends StatusModel<
	number,
	'remediatedByAv' | 'remediatedByAvAndRecreated'
> {
	@EntityField() isRemediated?: boolean;
}
