var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { FeaturesService, Feature } from '@wcd/config';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { OnboardingService } from '../../../../../onboarding/services/onboarding.service';
import { ServiceUrlsService, AppConfigService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { EvaluationEnabledGuard } from '@wcd/auth';
import { MtpPromotionService } from '../../../../../mtp-promotion/services/mtp-promotion.service';
import { attempt, forEach } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
var OnboardingWidget = /** @class */ (function (_super) {
    __extends(OnboardingWidget, _super);
    function OnboardingWidget(reportsService, serviceUrlsService, featuresService, dialogsService, onboardingService, mtpPromotionService, appConfigService, evaluationEnabledGuard, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.featuresService = featuresService;
        _this.dialogsService = dialogsService;
        _this.onboardingService = onboardingService;
        _this.mtpPromotionService = mtpPromotionService;
        _this.appConfigService = appConfigService;
        _this.evaluationEnabledGuard = evaluationEnabledGuard;
        _this.i18nService = i18nService;
        _this.subscriptions = [];
        _this.promotionVisible = false;
        _this.simulationAndTutorialsEnabled = _this.featuresService.isEnabled(Feature.SimulationsAndTutorials);
        _this.evaluationEnabled = _this.evaluationEnabledGuard.canActivate();
        return _this;
    }
    OnboardingWidget.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.subscriptions = [
            this.mtpPromotionService.promoWidgetVisible$.subscribe(function (visible) {
                _this.promotionVisible = visible;
                _this.widgetConfig$.next(_this.widgetConfig);
            }),
        ];
    };
    OnboardingWidget.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        forEach(this.subscriptions, function (sub) { return attempt(function () { return sub.unsubscribe(); }); });
    };
    Object.defineProperty(OnboardingWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'onboarding',
                showHeader: true,
                isDisabled: this.onboardingService.onboardingWidgetDismissed || this.promotionVisible,
                name: this.i18nService.get('onboarding_name'),
                hideOnNoData: true,
                api: {
                    url: function () { return _this.serviceUrlsService.downloadControllerService + "/EndpointOnboardingStatus"; },
                    isExternal: true,
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    OnboardingWidget.prototype.confirmAndDismiss = function () {
        var _this = this;
        this.dialogsService
            .confirm({
            title: this.i18nService.get('onboarding_dismiss_title'),
            confirmText: this.i18nService.get('onboarding_dismiss_confirmText'),
            cancelText: this.i18nService.get('onboarding_dismiss_cancelText'),
            text: this.i18nService.get('onboarding_dismiss_text'),
        })
            .then(function (e) { return e.confirmed && _this.dismiss(); });
    };
    OnboardingWidget.prototype.getImage = function (item) {
        return "/assets/images/onboarding/" + item + ".svg";
    };
    OnboardingWidget.prototype.dismiss = function (trackEvent) {
        if (trackEvent === void 0) { trackEvent = true; }
        this.onboardingService.dismissOnboardingWidget(this.data$.getValue(), trackEvent).subscribe(function () { });
        this.widgetConfig$.next(this.widgetConfig);
    };
    return OnboardingWidget;
}(ReportWidgetComponent));
export { OnboardingWidget };
