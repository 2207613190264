export enum ServiceSourceType {
	Unspecified = -1,
	OatpSA = 0,
	Wdatp = 1, //=MDATP, can contain alerts from WDATP and OATP
	Aatp = 2, // also called ITP. formerly known as 'Aorato'.
	Mcas = 4, // also called ITP. formerly known as 'Adalom'.
	Oatp = 8, // =OfficeAtp
	Mtp = 16, //Microsoft 365 Defender
	Aad = 32,
	Mapg = 64,
	Dlp = 128
}
