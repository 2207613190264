/* tslint:disable:template-click-events-have-key-events */
/* tslint:disable:template-accessibility-alt-text */

import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { RoutesService } from '@wcd/shared';
import { ConvergencePromotionService } from '../services/convergence-promotion.service';
import { FabricIconNames } from '@wcd/scc-common';
import { Feature, FeaturesService } from '@wcd/config';

@Component({
	selector: 'convergence-promotion-widget',
	templateUrl: './convergence-promotion-widget.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./convergence-promotion-widget.component.scss'],
})
export class ConvergencePromotionWidgetComponent {
	@Output() public onDismiss: EventEmitter<any> = new EventEmitter();

	constructor(
		private readonly convergencePromotionService: ConvergencePromotionService,
		private readonly featuresService: FeaturesService
	) {}

	navigateToUrl: string = RoutesService.getSCCRootUrl();
	openSccIcon: string = FabricIconNames.OpenInNewWindow;

	title: string = this.convergencePromotionService.title;
	desc: string = this.convergencePromotionService.desc;
	buttonTitle: string = this.convergencePromotionService.buttonTitle;
	isL2Enabled: boolean = this.featuresService.isEnabled(Feature.ConvergencePromotionL2);

	dismiss = () => {
		this.onDismiss.emit();
	};
}
