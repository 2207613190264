import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { EvidenceDataviewComponent } from './components/evidence-dataview.component';
import { EvidenceFieldsService } from './services/evidence.fields.service';
import { EvidenceDetectionContextComponent } from './components/evidence-detection-context.component';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { EvidenceStatusDetailsComponent } from './components/evidence-status-details.component';
import { EvidenceDetectionContextTooltipComponent } from './components/evidence-detection-context-tooltip.component';
import { EvidenceDetectionContextMultiComponent } from './components/evidence-detection-context-multi.component';

@NgModule({
	imports: [DataViewsModule, SharedModule, GlobalEntitiesModule],
	declarations: [
		EvidenceDataviewComponent,
		EvidenceDetectionContextComponent,
		EvidenceDetectionContextMultiComponent,
		EvidenceStatusDetailsComponent,
		EvidenceDetectionContextTooltipComponent,
	],
	exports: [EvidenceDataviewComponent, EvidenceDetectionContextComponent],
	providers: [EvidenceFieldsService],
	entryComponents: [
		EvidenceDetectionContextComponent,
		EvidenceDetectionContextMultiComponent,
		EvidenceStatusDetailsComponent,
		EvidenceDetectionContextTooltipComponent,
	],
})
export class EvidenceModule {}
