import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';

@ValueObject({
	singularName: 'Entity ID/Type',
	pluralName: 'Entity ID/Types',
	readonly: true,
})
export class AirsEntityIdType extends ModelBase {
	@EntityField({ data: ['entity_id', 'id'] })
	entityId: string;

	@EntityField({ data: ['entity_type', 'type'] })
	entityType: AirsEntityTypeConfig;

	@EntityField() name: string;
}
