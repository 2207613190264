<form
	*ngIf="folderExclusion.isEditing"
	#folderExclusionForm="ngForm"
	style="width: 500px"
	data-track-component="FolderExclusionForm"
>
	<div class="form-group wcd-input-placeholder">
		<label [attr.for]="'folder-exclusion-' + folderExclusion.id + '-folder'">{{
			i18nService.strings.admin_folderExclusions_folder
		}}</label>
		<wcd-help [text]="i18nService.strings.folderExclusions_edit_folder_description"></wcd-help>
		<input
			class="form-control"
			[id]="'folder-exclusion-' + folderExclusion.id + '-folder'"
			[(ngModel)]="folderExclusion.folder"
			name="folder"
			#folder="ngModel"
			autofocus
			validateRegExp="notEmpty"
			required
		/>
		<div [hidden]="folder.valid || folder.pristine" class="error-message">
			{{ i18nService.strings.admin_folderExclusions_enterFolder }}
		</div>
	</div>
	<div class="form-group wcd-input-placeholder">
		<label [attr.for]="'folder-exclusion-' + folderExclusion.id + '-extensions'">{{
			i18nService.strings.admin_folderExclusions_extensions
		}}</label>
		<wcd-help [text]="i18nService.strings.folderExclusions_edit_extensions_description"></wcd-help>
		<input
			type="text"
			[id]="'folder-exclusion-' + folderExclusion.id + '-extensions'"
			class="form-control"
			[placeholder]="i18nService.strings.admin_folderExclusions_extensionsPlaceholder"
			name="extensions"
			validateRegExp="notEmpty"
			[(ngModel)]="folderExclusion.extensions"
		/>
	</div>
	<div class="form-group wcd-input-placeholder">
		<label [attr.for]="'folder-exclusion-' + folderExclusion.id + '-file-names'">{{
			i18nService.strings.admin_folderExclusions_fileNames
		}}</label>
		<wcd-help [text]="i18nService.strings.folderExclusions_edit_fileNames_description"></wcd-help>
		<input
			type="text"
			[id]="'folder-exclusion-' + folderExclusion.id + '-file-names'"
			class="form-control"
			[placeholder]="i18nService.strings.admin_folderExclusions_fileNamesPlaceholder"
			name="fileNames"
			validateRegExp="notEmpty"
			[(ngModel)]="folderExclusion.fileNames"
		/>
	</div>
	<div class="form-group wcd-input-placeholder">
		<label [attr.for]="'folder-exclusion-' + folderExclusion.id + '-description'">{{
			i18nService.strings.admin_folderExclusions_description
		}}</label>
		<input
			type="text"
			[id]="'folder-exclusion-' + folderExclusion.id + '-description'"
			class="form-control"
			name="description"
			required
			validateRegExp="notEmpty"
			[(ngModel)]="folderExclusion.description"
		/>
	</div>
	<div class="buttons-bar">
		<button
			type="button"
			class="btn btn-cancel"
			data-track-id="Cancel"
			data-track-type="Button"
			(click)="cancelEdit()"
		>
			<wcd-shared-icon [iconName]="'cancel'"> </wcd-shared-icon>
			{{ i18nService.strings.buttons_cancel }}
		</button>
		<button
			type="button"
			data-track-id="Save"
			data-track-type="Button"
			(click)="save()"
			[disabled]="!folderExclusionForm.form.valid || folderExclusion.isSaving"
			class="btn btn-primary"
		>
			<wcd-shared-icon [iconName]="'check'"> </wcd-shared-icon>
			{{
				folderExclusion.isSaving
					? i18nService.strings.buttons_saving
					: i18nService.strings.buttons_save
			}}
		</button>
	</div>
</form>
