import { Injectable } from '@angular/core';
import { VulnerabilityNotificationRule, MachineGroup } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { VulnerabilityNotificationRulePanelComponent } from '../components/vulnerability-notification-rule.panel.component';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { VulnerabilityNotificationRulesService } from './vulnerability-notification-rules.service';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { ItemActionModelConfig, ItemActionModel, ItemActionType } from '../../../../dataviews/models/item-action.model';
import { Observable } from 'rxjs';
import { MachinesService } from '../../../machines/services/machines.service';
import { AuthService } from '@wcd/auth';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';

export enum ExternalRuleActions {
	UPDATE_STATUS_ACTION = 'updateStatus',
}

@Injectable()
export class VulnerabilityNotificationRuleEntityTypeService
	implements EntityTypeService<VulnerabilityNotificationRule> {
	isUserAllowedActions: boolean;
	itemPluralName: string;
	isGlobalAdmin: boolean;

	constructor(
		paris: Paris,
		authService: AuthService,
		private vulnerabilityNotificationRulesService: VulnerabilityNotificationRulesService,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private machinesService: MachinesService
	) {
		this.itemPluralName = paris.getRepository(VulnerabilityNotificationRule).entity.singularName;
		this.isUserAllowedActions = vulnerabilityNotificationRulesService.isUserAllowedActions();
		this.isGlobalAdmin = authService.currentUser.isMdeAdmin;
	}

	readonly entityType: EntityType<VulnerabilityNotificationRule> = {
		id: 'VulnerabilityNotificationRule',
		entity: VulnerabilityNotificationRule,
		singleEntityPanelComponentType: VulnerabilityNotificationRulePanelComponent,
		getEntityName: (vulnerabilityNotificationRule: VulnerabilityNotificationRule) =>
			vulnerabilityNotificationRule.title,
		getActions: (
			vulnerabilityNotificationRules: Array<VulnerabilityNotificationRule>
		): Observable<ReadonlyArray<ItemActionModel>> => {
			if (vulnerabilityNotificationRules.length !== 1) return;

			const actions: Array<ItemActionModelConfig> = [];
			const isAllowedToEdit$: Observable<
				Array<MachineGroup>
			> = this.machinesService.getFullUserExposedMachineGroups();

			return isAllowedToEdit$.pipe(
				map(groups => {
					const disableEdit =
						!this.isUserAllowedActions ||
						!this.vulnerabilityNotificationRulesService.isUserCanEditRule(
							vulnerabilityNotificationRules[0],
							groups
						);
					const tooltip = disableEdit
						? this.i18nService.strings.tvm_notifications_permissions_tooltip
						: '';
					return [
						...actions,
						{
							id: 'editNotificationRule',
							nameKey: 'common_edit_rule',
							icon: FabricIconNames.Edit,
							closeOnAction: true,
							disabled: disableEdit,
							tooltip: tooltip,
							method: (notificationRules: Array<VulnerabilityNotificationRule>) =>
								this.vulnerabilityNotificationRulesService.showWizardPanel(
									notificationRules[0]
								),
						},
						{
							id: 'deleteNotificationRule',
							nameKey: 'common_delete',
							icon: FabricIconNames.Delete,
							closeOnAction: true,
							disabled: disableEdit,
							tooltip: tooltip,
							method: (notificationRules: Array<VulnerabilityNotificationRule>) => {
								const title = notificationRules[0].title;
								return this.dialogsService
									.confirm({
										title: this.i18nService.get('deleteItem', { itemName: title }),
										text: this.i18nService.get('deleteConfirm', {
											itemPluralName: this.itemPluralName,
										}),
										confirmText: this.i18nService.get('delete'),
									})
									.then((e: ConfirmEvent) => {
										if (e.confirmed)
											return this.vulnerabilityNotificationRulesService
												.deleteVulnerabilityNotificationRule(notificationRules[0])
												.catch(error => {
													this.dialogsService.showError({
														title: this.i18nService.get(
															'common_error_failedDeleteItem',
															{
																itemName: title,
															}
														),
														data: error,
													});
												});
									});
							},
						},
						{
							id: ExternalRuleActions.UPDATE_STATUS_ACTION,
							closeOnAction: true,
							disabled: true,
							method: (notificationRules: Array<VulnerabilityNotificationRule>) =>
								this.vulnerabilityNotificationRulesService
									.updateStatus(notificationRules[0], !notificationRules[0].isEnabled)
									.toPromise(),
							type: ItemActionType.Hidden
						},
					].map(itemActionConfig => new ItemActionModel(itemActionConfig));
				})
			);
		},
		loadFullEntityInPanel: false,
	};
}
