<label
  [class.confirm-dialog--required]="required"
  [class.confirm-dialog--label-disabled]="disabled"
  class="wcd-font-weight-semibold wcd-margin-none-vertical confirm-dialog--label confirm-dialog--padding-xsmall-vertical"
>{{label}}</label>

<div class="confirm-dialog--input-wrapper" [class.confirm-dialog--disabled]="disabled">
  <input type="text" id="confirm-dialog-input"
    class="wcd-padding-none-vertical wcd-padding-small-horizontal"
    [ngClass]="className"
    [disabled]="disabled"
    [(ngModel)]="text"	autocomplete="off"
    (ngModelChange)="onValueChange($event)"
    [placeholder]="placeholder"
    [required]="required"
	[focusOn]="focus"
	[attr.aria-label]="label"
  />
</div>
