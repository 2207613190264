import { SevilleModule } from '../../../seville/seville.module';
import { SevilleUtils } from '../../../common/services/seville.utils';

declare const moment: typeof import('moment');

SevilleModule.directive('timelineDatepicker', timelineDatepickerDirective);
SevilleModule.controller('seville.threatintel.timeline.datepicker', timelineDatepickerController);

timelineDatepickerDirective.$inject = [];

function timelineDatepickerDirective() {
	return {
		restrict: 'EA',
		scope: {
			fromDate: '=',
			toDate: '=',
			loadTimeline: '&',
			startDate: '=',
			endDate: '=',
			maskResults: '=',
			pickerTitle: '=',
			inColdStorage: '=?',
			coldStorageEnabled: '=?',
		},
		template: `
		<div class="datepicker-padding">
        <span class="seville-datepicker-title">
            {{timelinepicker.pickerTitle}}
        </span>
        <div class="timbar-datepicker-container"
             ng-click="timelinepicker.chooseDates()">
            <date-picker selected-date="timelinepicker.fromDate"
                         date-format="'MMM DD YYYY'"
                         max-date="timelinepicker.maxFromDate"
						 min-date="timelinepicker.minFromDate"
						 input-aria-label="'from'" />
        </div>
        <span class="seville-datepicker-title">
            to:
        </span>
        <div class="timbar-datepicker-container"
             ng-click="timelinepicker.chooseDates()">
            <date-picker selected-date="timelinepicker.toDate"
                         date-format="'MMM DD YYYY'"
                         min-date="timelinepicker.fromDate"
						 max-date="timelinepicker.maxToDate"
						 input-aria-label="'to'" />
        </div>
        <button ng-class="{'timebar-select-button-disabled' : !timelinepicker.maskResults, 'hero-button' : timelinepicker.maskResults }" class="btn btn-link timebar-select-button" data-toggle="dropdown" aria-expanded="true" aria-label="Find" type="button" ng-click="timelinepicker.loadTimelineResults()" ng-disabled="!timelinepicker.maskResults">
            <i class="shell-header-dropdown-label-icon icon icon-Sell rotate-270"></i>
        </button>
    </div>
		`,
		bindToController: true,
		controllerAs: 'timelinepicker',
		controller: 'seville.threatintel.timeline.datepicker',
	};
}

timelineDatepickerController.$inject = ['$scope', 'coldStorage', '$state', '$filter', '$stateParams'];

function timelineDatepickerController($scope, coldStorage, $state, $filter, $stateParams) {
	var vm = this;

	vm.maxFromDate = moment();
	vm.maxToDate = moment();
	var minDate = new Date();
	minDate.setMonth(minDate.getMonth() - 6);
	minDate.setHours(0, 0, 0, 0);
	vm.minFromDate = moment(minDate);
	vm.minToDate = moment(minDate);
	var isoStringFilter = $filter('isoString');

	vm.loadTimelineResults = function() {
		vm.maskResults = false;
		vm.loadTimeline();
	};

	vm.chooseCurrentDate = function() {
		if ($stateParams.time) {
			updateFromDate();
		} else {
			var fromDate = new Date();
			fromDate.setMonth(fromDate.getMonth() - 1);
			var toDate = new Date();
			var daysInCurrentMonth = calcDaysDiff(fromDate, toDate);

			vm.fromDate = moment(toDate).add(-daysInCurrentMonth, 'days');
			vm.toDate = moment(toDate);

			vm.startDate = vm.fromDate.toDate();
			vm.endDate = vm.toDate.toDate();
		}
	};

	vm.chooseCurrentDate();
	updateToDate();

	var scopeUnwatcher = $scope.$watch(
		function() {
			return selectedDatesChanged(vm.fromDate, vm.toDate);
		},
		function(newValue, oldValue) {
			if (newValue && newValue !== oldValue) {
				updateIsDatePickerInColdStorage();
				updateToDate();
				vm.maskResults = true;
			}
		}
	);

	this.$onDestroy = function() {
		scopeUnwatcher();
	};

	function updateIsDatePickerInColdStorage() {
		var dateToCheck = new Date(vm.fromDate);
		SevilleUtils.date.endOfDay(dateToCheck);
		vm.inColdStorage = coldStorage.isInColdStorage(dateToCheck) && vm.coldStorageEnabled;
	}

	function updateToDate() {
		var today = moment();
		var chosenFromDate = new Date(vm.fromDate.toDate());
		var chosenFromDatePlusMonth = new Date(vm.fromDate.toDate());
		chosenFromDatePlusMonth.setMonth(chosenFromDatePlusMonth.getMonth() + 1);
		var daysInCurrentMonth = calcDaysDiff(chosenFromDate, chosenFromDatePlusMonth);
		var fromDatePlusMonth = moment(vm.fromDate).add(daysInCurrentMonth, 'days');
		if (vm.inColdStorage) {
			fromDatePlusMonth = moment(
				coldStorage.overrideEndDateIfBeyondHotData(vm.fromDate.toDate(), daysInCurrentMonth)
			);
		}

		if (vm.toDate > fromDatePlusMonth || vm.toDate < vm.fromDate) {
			vm.toDate = fromDatePlusMonth;
		}
		if (vm.toDate > today) {
			vm.toDate = today;
		}

		vm.maxToDate = moment.max(vm.toDate, fromDatePlusMonth);
		var startDate = vm.fromDate.toDate();
		startDate.setHours(0, 0, 0, 0);
		var endDate = vm.toDate.toDate();
		endDate.setHours(23, 59, 59, 59);
		vm.startDate = startDate;
		vm.endDate = endDate;
		updateTimeInUrl(vm.endDate);
	}

	function updateFromDate() {
		var chosenToDate = new Date($stateParams.time);
		var chosenToDateMinusMonth = new Date(chosenToDate);
		chosenToDateMinusMonth.setMonth(chosenToDateMinusMonth.getMonth() - 1);
		var daysInCurrentMonth = calcDaysDiff(chosenToDate, chosenToDateMinusMonth);
		var fromDate = moment(chosenToDate).add(-daysInCurrentMonth, 'days');
		vm.inColdStorage = coldStorage.isInColdStorage(fromDate.toDate()) && vm.coldStorageEnabled;
		if (vm.inColdStorage) {
			fromDate = moment(coldStorage.overrideStartDateIfBeyondHotData(chosenToDate, daysInCurrentMonth));
		}

		var minDateToQuery = new Date();
		minDateToQuery.setMonth(minDateToQuery.getMonth() - 6);
		if (fromDate.toDate() < minDateToQuery) {
			fromDate = moment(minDateToQuery);
		}
		vm.fromDate = moment(fromDate);
		vm.toDate = moment(chosenToDate);

		vm.startDate = vm.fromDate.toDate();
		vm.endDate = vm.toDate.toDate();
	}

	function selectedDatesChanged(fromDate, toDate) {
		var selectedFromDate = new Date(fromDate);
		selectedFromDate.setHours(23, 59, 59, 59);
		var selectedToDate = new Date(toDate);
		selectedToDate.setHours(23, 59, 59, 59);

		return selectedFromDate.toString() + '-' + selectedToDate.toString();
	}

	function calcDaysDiff(firstDate, secondDate) {
		SevilleUtils.date.endOfDay(firstDate);
		SevilleUtils.date.endOfDay(secondDate);

		var day = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

		return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / day));
	}

	function updateTimeInUrl(date) {
		var updatedDateIsoString = isoStringFilter(date);
		$stateParams.time = updatedDateIsoString;
		$state.go($state.current.name, $stateParams, {
			notify: false,
			reload: false,
			location: 'replace',
			inherit: true,
		});
	}
}
