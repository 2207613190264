import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Manual action on user account',
	endpoint: 'autoir/officeui/mtp-action-center-ingestion/mdi-ingestion',
	method: 'POST',
	baseUrl: config => config.data.serviceUrls.k8s,
	parseQuery: (userActionRequest: ManualAdminUserActionRequest) => {
		return {
			data: userActionRequest,
		};
	},
})
export class ManualActionOnUserApiCall extends ApiCallModel<void, ManualAdminUserActionRequest> {}

export interface ManualAdminUserActionRequest {
	InitiatedByAccount: AadUserAccount;
	RemediationActions: [UserRemediationAction];
}

export interface UserRemediationAction {
	ActionOrigin: string;
	ActionSource: string;
	ActionType: ManualUserActionType;
	Entities: [AadUserAccount];
	ResourceIdentifiers: [AadResourceIdentifier];
	ApprovedBy: string;
	// this is type related metadata for BE use, and is not part of the core schema
	$type: string;
}

export interface AadUserAccount {
	// this is type related metadata for BE use, and is not part of the core schema
	$type: string;
	Name?: string;
	AadTenantId: string;
	AadUserId: string;
	NTDomain?: string;
	DnsDomain?: string;
	UPNSuffix?: string;
	Sid?: string;
	DisplayName?: string;
	FriendlyName?: string;
	UniqueName?: string;
	Type: string;
}

export enum ManualUserActionType {
	EnableUser = 'EnableUser',
	DisableUser = 'DisableUser',
}

export interface AadResourceIdentifier {
	AadTenantId: string;
	Type: string;
	// this is type related metadata for BE use, and is not part of the core schema
	$type: string;
}
