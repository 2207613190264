/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../forms/components/fancy-select.component.ngfactory";
import * as i2 from "../../../shared/interfaces/disableable.interface";
import * as i3 from "../../../forms/components/fancy-select.component";
import * as i4 from "../../../../../../../projects/shared/src/lib/services/browser-detection.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/forms";
import * as i7 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i8 from "@angular/common";
import * as i9 from "./tvm-report-inaccuracy-dropdown.component";
import * as i10 from "../../services/tvm-report-inaccuracy.service";
var styles_TvmReportInaccuracyDropdownComponent = [];
var RenderType_TvmReportInaccuracyDropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmReportInaccuracyDropdownComponent, data: {} });
export { RenderType_TvmReportInaccuracyDropdownComponent as RenderType_TvmReportInaccuracyDropdownComponent };
function View_TvmReportInaccuracyDropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "wcd-flex-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "fancy-select", [], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("ngModelChange" === en)) {
        var pd_0 = (_co.onSelect($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FancySelectComponent_0, i1.RenderType_FancySelectComponent)), i0.ɵprd(6144, null, i2.DISABLEABLE_TOKEN, null, [i3.FancySelectComponent]), i0.ɵdid(4, 573440, null, 0, i3.FancySelectComponent, [i0.ChangeDetectorRef, i4.BrowserDetectionService, i5.I18nService], { highlightSelected: [0, "highlightSelected"], buttonIcon: [1, "buttonIcon"], values: [2, "values"], showIconDropdown: [3, "showIconDropdown"], useValueAsButtonText: [4, "useValueAsButtonText"], ariaLabel: [5, "ariaLabel"], dropdownAreaRole: [6, "dropdownAreaRole"] }, null), i0.ɵppd(5, 1), i0.ɵprd(1024, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.FancySelectComponent]), i0.ɵdid(7, 671744, null, 0, i6.NgModel, [[8, null], [8, null], [8, null], [6, i6.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i0.ɵprd(2048, null, i6.NgControl, null, [i6.NgModel]), i0.ɵdid(9, 16384, null, 0, i6.NgControlStatus, [[4, i6.NgControl]], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = false; var currVal_8 = "MoreVertical"; var currVal_9 = _co.moreMenuValues; var currVal_10 = false; var currVal_11 = false; var currVal_12 = i0.ɵunv(_v, 4, 5, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "tvm.reportInaccuracy.moreOptions")); var currVal_13 = _co.role; _ck(_v, 4, 0, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_14 = _co.selection; _ck(_v, 7, 0, currVal_14); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 9).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 9).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 9).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 9).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 9).ngClassValid; var currVal_5 = i0.ɵnov(_v, 9).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 9).ngClassPending; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_TvmReportInaccuracyDropdownComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i7.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmReportInaccuracyDropdownComponent_1)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.inaccuracyContext && _co.contextObject); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TvmReportInaccuracyDropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-report-inaccuracy-dropdown", [], null, null, null, View_TvmReportInaccuracyDropdownComponent_0, RenderType_TvmReportInaccuracyDropdownComponent)), i0.ɵdid(1, 49152, null, 0, i9.TvmReportInaccuracyDropdownComponent, [i5.I18nService, i10.TvmReportInaccuracyService], null, null)], null, null); }
var TvmReportInaccuracyDropdownComponentNgFactory = i0.ɵccf("tvm-report-inaccuracy-dropdown", i9.TvmReportInaccuracyDropdownComponent, View_TvmReportInaccuracyDropdownComponent_Host_0, { contextObject: "contextObject", inaccuracyContext: "inaccuracyContext" }, {}, []);
export { TvmReportInaccuracyDropdownComponentNgFactory as TvmReportInaccuracyDropdownComponentNgFactory };
