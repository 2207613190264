import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ChecklistValue } from '@wcd/forms';
import { IButtonStyles } from 'office-ui-fabric-react';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { TypedChanges } from '@wcd/angular-extensions';

@Component({
	selector: 'multi-select',
	templateUrl: './multi-select.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./multi-slect.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MultiSelectComponent implements OnChanges{
	filter: string = '';
	appliedFilter: string = '';
	commandButtonStyles: IButtonStyles = {
		root: {
			width: '100%'
		}
	}

	@Input() selectedDescription: string;
	@Input() selected: Array<ChecklistValue>;
	@Input() available: Array<ChecklistValue>;
	@Input() requiredAllowedActions: Array<keyof typeof MdeUserRoleActionEnum>;
	@Input() canEdit: boolean;
	@Input() isDisabled = false;
	@Input() isLoadingAvailableItems: boolean = false;
	@Input() hasError: boolean = false;
	@Input() removeButtonText: string;
	@Input() addButtonText: string;
	@Input() filterPlaceholder: string;
	@Input() noSelectedItemsPlaceholder: string;
	@Input() notFoundForFilterText: string;
	@Input() notFoundText: string;
	@Input() virtualScrollAvailableItems: boolean = false;

	@Output() onRemoveChosen: EventEmitter<void> = new EventEmitter<void>();
	@Output() onAddChosen: EventEmitter<void> = new EventEmitter<void>();
	@Output() onSetAvailableItems: EventEmitter<string> = new EventEmitter<string>();

	@Input() chosenFromSelected: Array<ChecklistValue>;
	@Output() chosenFromSelectedChange: EventEmitter<Array<ChecklistValue>> = new EventEmitter<
		Array<ChecklistValue>
	>();

	setChosenFromSelected(chosen: Array<ChecklistValue>) {
		this.chosenFromSelected = chosen;
		this.chosenFromSelectedChange.emit(chosen);
	}

	@Input() chosenFromAvailable: Array<ChecklistValue>;
	@Output() chosenFromAvailableChange: EventEmitter<Array<ChecklistValue>> = new EventEmitter<
		Array<ChecklistValue>
	>();

	constructor(
		private readonly i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
		) {}

	ngOnChanges(changes: TypedChanges<MultiSelectComponent>): void {
		if (changes.selected && this.selected) {
			this.announceListState('common.list.updated');
		}
		if (changes.isLoadingAvailableItems && changes.isLoadingAvailableItems.currentValue) {
			this.announceListState('common_loading');
		}
	}

	setChosenFromAvailable(chosen: Array<ChecklistValue>) {
		this.chosenFromAvailable = chosen;
		this.chosenFromAvailableChange.emit(chosen);
	}

	get shouldApplyFilter() {
		return !this.appliedFilter || this.appliedFilter !== this.filter;
	}

	clearFilter() {
		this.filter = '';
		this.applyFilter();
	}

	applyFilter() {
		this.appliedFilter = this.filter;
		this.onSetAvailableItems.emit(this.filter);
	}

	private announceListState(key: string){
		this.liveAnnouncer.announce(
			this.i18nService.get(key),
			'assertive',
			300
		);
	}
}
