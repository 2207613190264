var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { CalculatePercentagePipe } from '../../pipes/calculate-percentage.pipe';
import { BaselineCompliantBarService } from '../../services/baseline-compliant-bar.service';
import { BaselineCompliantColorService } from '../../services/baseline-compliant-color.service';
var BaselineCompliantBarComponent = /** @class */ (function () {
    function BaselineCompliantBarComponent(changeDetectorRef, baselineCompliantColorService, displayCalculatePercentagePipe, baselineCompliantBarService) {
        this.changeDetectorRef = changeDetectorRef;
        this.baselineCompliantColorService = baselineCompliantColorService;
        this.displayCalculatePercentagePipe = displayCalculatePercentagePipe;
        this.baselineCompliantBarService = baselineCompliantBarService;
        this.showLegend = true;
        this.size = "medium" /* Medium */;
        this.dataUnavailableCount = 0;
    }
    BaselineCompliantBarComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.events = this.baselineCompliantBarService.baselineCompliantKeys
            .map(function (key) {
            var count = _this[key];
            return _this.createBaselineCompliantConfig(key, count);
        })
            .filter(Boolean);
        this.colorMap = this.baselineCompliantBarService.compliantColorMap;
        this.textFormatter = this.baselineCompliantBarService.compliantTextFormatter;
        this.percentage = "(" + this.displayCalculatePercentagePipe.transform(this.compliantCount, this.total) + ")";
        this.changeDetectorRef.detectChanges();
    };
    BaselineCompliantBarComponent.prototype.ngOnChanges = function (_) {
        this.changeDetectorRef.detectChanges();
    };
    BaselineCompliantBarComponent.prototype.createBaselineCompliantConfig = function (baselineCompliantType, count) {
        if (count === 0)
            return null;
        var legendConfig = {
            name: this.baselineCompliantBarService.legendTextFormatter(baselineCompliantType, count),
            iconClassName: this.baselineCompliantColorService.fontColorsClassMap.get(baselineCompliantType),
        };
        var barConfig = {
            kind: baselineCompliantType,
            count: count,
        };
        return __assign({}, legendConfig, barConfig);
    };
    return BaselineCompliantBarComponent;
}());
export { BaselineCompliantBarComponent };
