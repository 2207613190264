
		<ng-template *ngIf="colorMappingOnTop" [ngTemplateOutlet]="mapping"> </ng-template>
		<wcd-time-series-chart [data]="(data$ | async)?.data" [settings]="chartSettings$ | async">
		</wcd-time-series-chart>
		<ng-template *ngIf="!colorMappingOnTop" [ngTemplateOutlet]="mapping"> </ng-template>

		<ng-template #mapping>
			<div class="wcd-font-size-xs">
				<span *ngFor="let legendItem of legendItems" class="wcd-padding-small-left wcd-inline-block">
					<fab-icon iconName="SquareShapeSolid" [contentClass]="legendItem.colorClass"></fab-icon>
					{{ legendItem.label }}
				</span>
			</div>
		</ng-template>
	