import { Entity, EntityModelBase, EntityField, ParisConfig } from '@microsoft/paris';

@Entity({
	singularName: 'Entity Action User Type',
	pluralName: 'Entity Action User Types',
	values: [
		{
			id: 'user',
			name: 'User',
			icon: 'users.user',
			isSystem: false,
		},
		{
			id: 'system',
			name: 'System',
			icon: 'hexadite',
			isSystem: true,
		},
	],
})
export class AirsEntityActionUserType extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() icon: string;

	@EntityField() isSystem: boolean;
}
