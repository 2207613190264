import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Hunting query quota usage',
	pluralName: 'Hunting query quota usage',
	readonly: true,
})
export class HuntingQueryQuotaUsage extends ModelBase {
	@EntityField({ data: 'QueryCpuUsage' })
	readonly queryCpuUsage: number;

	@EntityField({ data: 'ExecutionTime' })
	readonly executionTime: string;

	@EntityField({ data: 'CpuLoad' })
	readonly cpuLoad: HuntingQueryCPULoad;
}

export enum HuntingQueryCPULoad {
	low = 0,
	medium = 1,
	high = 2,
}
