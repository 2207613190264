import {
	DataQuery,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
} from '@microsoft/paris';
import { SuppressionRule } from './suppression-rule.entity';
import { Alert } from '../alert/alert.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@EntityRelationship({
	sourceEntity: SuppressionRule,
	dataEntity: Alert,
	endpoint: (config: WcdPortalParisConfig, query: DataQuery) => 
		query && query.where && query.where['useDetectionAssociatedAlertsVnextApi'] ? 'Alerts' : `AssociatedAlerts`,
	allItemsEndpointTrailingSlash: false,
	foreignKey: 'suppressionRuleId',
	allItemsProperty: 'Items',
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useDetectionAssociatedAlertsVnextApi']
			? `${config.data.serviceUrls.alertsApiService}/Evidences/suppressionRule`
			: config.data.serviceUrls.threatIntel;
	},
})
export class SuppressionRuleAlertRelationship
	implements EntityRelationshipRepositoryType<SuppressionRule, Alert> {}
