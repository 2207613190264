var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnChanges, } from '@angular/core';
import { of } from 'rxjs';
import { Asset, ComparisonResultSingularEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsComponentBase } from '../../../../../app/global_entities/components/entity-details/entity-details.component.base';
import { DownloadService } from '../../../../../app/shared/services/download.service';
var ComparisonResultEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(ComparisonResultEntityDetailsComponent, _super);
    function ComparisonResultEntityDetailsComponent(i18nService, downloadService, injector) {
        var _this = _super.call(this, injector) || this;
        _this.i18nService = i18nService;
        _this.downloadService = downloadService;
        _this.isShowMore = false;
        _this.openByMachineNameNewTab = true;
        _this.includeOperatingSystem = false;
        return _this;
    }
    ComparisonResultEntityDetailsComponent.prototype.ngOnChanges = function () {
        if (this.contextOptions && this.contextOptions.isSingleAsset) {
            return;
        }
        this.tvmAssets$ = this.getExposedAssetsObserveable(this.comparisonResult.machinesDetectedOnlyByTvm);
        this.intersectionAssets$ = this.getExposedAssetsObserveable(this.comparisonResult.intersectionDetectedMachines);
        this.competitorAssets$ = this.getExposedAssetsObserveable(this.comparisonResult.machinesDetectedOnlyByOther);
    };
    ComparisonResultEntityDetailsComponent.prototype.exportExposedTvmMachines = function () {
        return this.exportExposedMachines(this.comparisonResult.machinesDetectedOnlyByTvm, this.i18nService.get("tvm.common.exposedTvmMachines"));
    };
    ComparisonResultEntityDetailsComponent.prototype.exportExposedOtherMachines = function () {
        return this.exportExposedMachines(this.comparisonResult.machinesDetectedOnlyByOther, this.i18nService.get("tvm.common.exposedCompetitorMachines"));
    };
    ComparisonResultEntityDetailsComponent.prototype.exportExposedIntersectionMachines = function () {
        return this.exportExposedMachines(this.comparisonResult.intersectionDetectedMachines, this.i18nService.get("tvm.common.exposedIntersectionMachines"));
    };
    ComparisonResultEntityDetailsComponent.prototype.exportExposedMachines = function (machinesList, fileName) {
        var blob = new Blob([this.getMachinesCsvStr(machinesList)], { type: 'text/csv;charset=utf-8;' });
        return this.downloadService.download(of(blob), fileName + '.csv');
    };
    ComparisonResultEntityDetailsComponent.prototype.getExposedAssetsObserveable = function (machineList) {
        var _this = this;
        return of({
            count: machineList.length,
            items: machineList.map(function (machineDnsName) {
                return new Asset({ id: machineDnsName, name: _this.getNameFromDnsName(machineDnsName) });
            }),
        });
    };
    ComparisonResultEntityDetailsComponent.prototype.getNameFromDnsName = function (dnsName) {
        return dnsName.includes('.') ? dnsName.split('.')[0] : dnsName;
    };
    ComparisonResultEntityDetailsComponent.prototype.getMachinesCsvStr = function (dnsNames) {
        var colName = 'Devices names\n';
        return colName + dnsNames.join('\n');
    };
    return ComparisonResultEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { ComparisonResultEntityDetailsComponent };
