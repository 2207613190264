<ng-container *ngIf="(data$ | async) as data" >
	<div class="wcd-m365-typography wcd-full-height"
		*ngIf="data.virusTotalReport?.total || data.showFileVerdict; else noData">
		<h4>
			<div class="wcd-flex-horizontal">
				<div class="wcd-flex-1">
				{{'file.reputation.virusTotal' | i18n}}
				</div>
				<small *ngIf="data.virusTotalReport?.total" class="wcd-flex-none wcd-margin-left">
					<strong>{{data.virusTotalReport.positives}}</strong>/{{data.virusTotalReport.total}}
				</small>
			</div>
		</h4>
		<div class="wcd-margin-small-bottom">
			<ng-container *ngIf="data.virusTotalReport?.total; else noVtData">
				<wcd-bar width="100%" [fillRatio]="data.virusTotalReport.positives / data.virusTotalReport.total"></wcd-bar>
				<a *ngIf="data.virusTotalReport.permalink" [href]="data.virusTotalReport.permalink" target="_blank">
					{{ 'file.reputation.viewInVirusTotal' | i18n }}
				</a>
			</ng-container>
			<ng-template #noVtData>
				<span class="wcd-font-size-s color-text-gray-700 wcd-font-weight-bold">
					{{ 'common.noDataAvailable' | i18n }}
				</span>
			</ng-template>
		</div>

		<ng-container *ngIf="isFileVerdictEnabled; else defenderDetections">
			<ng-container *ngIf="data.showFileVerdict">
				<h4>
					{{ 'file.malwareDetection' | i18n }}
				</h4>
				<file-detections-summary [file]="data.file" class="wcd-font-size-s color-text-gray-700 wcd-font-weight-bold"></file-detections-summary>
			</ng-container>
		</ng-container>
		<ng-template #defenderDetections>
			<h4 class="wcd-margin-none-bottom">
				{{'file.reputation.defender.title' | i18n}}
			</h4>
			<div class="wcd-margin-small-bottom">
				<ng-container *ngIf="data.defenderThreat; else noDefenderThreat">
					<div class="wcd-secondary-text">
						{{ data.defenderThreat }}
						<a [href]="'http://go.microsoft.com/fwlink/?LinkID=142185&Name=' + data.defenderThreat"
						data-track-id="MicrosoftEncyclopediaReadMore"
						data-track-type="ExternalLink"
						[wcdTooltip]="'file.reputation.defender.readMore' | i18n"
						target="_blank">
							<fab-icon iconName="ReadingMode"></fab-icon>
						</a>
					</div>
				</ng-container>
				<ng-template #noDefenderThreat>
					<span class="wcd-secondary-text">{{ 'file.reputation.defender.noDetection' | i18n }}</span>
				</ng-template>
			</div>
		</ng-template>
	</div>

	<ng-template #noData>
			<h4>{{ 'common.noDataAvailable' | i18n }}</h4>
		</ng-template>
</ng-container>
