import { SevilleModule } from '../../seville/seville.module';

declare const moment: typeof import('moment');

SevilleModule.directive('timeAgo', timeagoDirective);

timeagoDirective.$inject = ['$filter'];

function timeagoDirective($filter) {
	return {
		restrict: 'EA',
		scope: {},
		//scope: true,   // optionally create a child scope
		link: function(scope, element, attrs) {
			attrs.$observe('utc', function(val) {
				if (!val || val.length === 0) {
					$(element).text('');
					$(element).removeAttr('title');
					return;
				}

				var title = $filter('date')(val, 'MM/dd/yyyy @ h:mma', 'utc');
				var m = moment(val);

				var tonow = m.fromNow();

				$(element).text(tonow);
				$(element).attr('title', title);
			});
		},
	};
}
