<div class="report-in-responsive-entity-page wcd-full-height dashboard {{report.className}}"
	 role="region" [attr.aria-label]="reportTitle"
	 focus-first-focusable-element
	 [elementReady$]="widgetsLoadedSubject"
	 [class.wcd-scroll-all]="report.overflow === 'all'"
	 [class.wcd-scroll-vertical]="report.overflow === 'vertical'"
	 [class.wcd-scroll-none]="report.overflow === 'none'"
	 [class.fit-to-height]="report.fitToHeight"
	 [class.no-background]="report.transparent"
	 [ngClass]="{ 'dashboard-loaded': lastLoadTime }"
	 [class.wcd-padding-none-horizontal-important]="removePadding"
	 [class.wcd-padding-none-horizontal-left-important]="removePaddingLeft"
	 [class.wcd-padding-none-horizontal-right-important]="removePaddingRight"
	 [class.fit-to-zoom]="report.id">

	<message-bar *ngIf="showPollingError && isPollingErrorExist" [messageData]="'dashboard data'"></message-bar>

	<div class="wcd-full-height relative dashboard-contents"
		 [class.wcd-padding-large-all]="report.enablePadding"
		 [class.wcd-padding-none-horizontal-left-important]="removePaddingLeft"
		 [class.wcd-padding-none-horizontal-important]="removePadding"
		 [class.wcd-padding-large-horizontal]="report.enableHorizontalPadding && !removePadding"
		 [class.wcd-padding-xxLarge-horizontal]="enableLargePadding"
		 [style.max-width]="report.maxWidth ? report.maxWidth + 'px' : null">
		<div class="dashboard-nodata" [hidden]="!widgetsLoaded || !(isEmpty$ | async)">
			<ng-container #emptyComponentPlaceholder *ngIf="report.emptyDashboardComponent; else noDataMessage"></ng-container>
			<ng-template #noDataMessage>
				<div class="widget-nodata-message">No data to display</div>
			</ng-template>
		</div>
		<div class="dashboard-print-time print-only">
			<strong>Time:</strong> {{reportTimeTitle}} {{ timezone ? '' : ' UTC '}} |
			<strong>Time Range:</strong>
			<span *ngIf="reportsService.currentTimeRange.allowCustomRange; else nonCustomRangeDisplay">{{reportDatesTitle}}</span>
			<ng-template #nonCustomRangeDisplay>{{reportsService.currentTimeRange.name | i18n}}</ng-template>
		</div>
		<section
			*ngIf="showHeader && !(isEmpty$ | async)"
			class="dashboard-header"
			[attr.aria-labelledby]="(projectedHeaderRef?.children?.length === 0 && !reportTitle) ? titleDashboardId : '' ">
			<div #projectedHeaderRef><ng-content select="div.custom-header"></ng-content></div>
			<div class="wcd-flex-horizontal wcd-flex-wrap" *ngIf="projectedHeaderRef?.children?.length === 0">
				<div [attr.id]="titleDashboardId" class="wcd-flex-auto wcd-flex-vertical">
					<div #ref>
						<ng-content select="[report-custom-title]"></ng-content>
					</div>
					<h3 aria-level="2" *ngIf="ref.children.length == 0" class="dashboard-title">{{reportTitle}}</h3>
					<markdown *ngIf="report.descriptionKey" [data]="report.descriptionKey | i18n"></markdown>
				</div>
				<ng-container *ngIf="showControls">
					<div *ngIf="report.allowRangeSelect" class="wcd-flex-horizontal noprint wcd-flex-none wcd-flex-justify-end">
						<wcd-select
							[ngModel]="reportsService.currentTimeRange || report.rangeConfig?.defaultRange"
							(ngModelChange)="onRangeChange($event)"
							[isFullWidth]="false"
							[isBordered]="false"
							[isDisabled]="!widgetsLoaded"
							[dropdownId]="'report-time-range-select'"
							class="wcd-m365-dropdown"
							[label]="'name'"
							[values]="report.rangeConfig?.timeRangeValues || reportsService.timeRanges"
							class="wcd-flex-none wcd-m365-dropdown"
							[ariaLabel]="'report.timePeriod' | i18n"
							[buttonIcon]="icon"
							[useValueAsButtonText]="!smallScreenView"
							[showIconDropdown]="!smallScreenView"
							[formatLabel]="formatLabel"
						>
						</wcd-select>
						<wcd-panel
							(close)="togglePanel(false)"
							class="responsive-panel"
							[class.wcd-padding-large-top]="paddingTop"
							[settings]="panelSettings"
							*ngIf="customRangePanelOpened"
						>
							<div class="dashboard-panel-content">
								<daterange
									class="wcd-flex-1 wcd-scroll-vertical"
									[showInPanel]="true"
									[verticalDirection]="true"
									[(ngModel)]="customRange"
									[allowExactTimeSelection]="false"
									(ngModelChange)="onCustomRangeChange()"
								></daterange>
								<div
									class="wcd-flex-none wcd-padding-horizontal wcd-padding-small-vertical wcd-border-top"
								>
									<ng-container>
										<fab-primary-button
											[disabled]="!isDirty"
											(onClick)="apply()"
											[text]="i18nService.strings.buttons_apply"
											className="wcd-margin-xsmall-right"
										></fab-primary-button>
										<fab-default-button
											(onClick)="cancel()"
											[text]="i18nService.strings.buttons_cancel"
										></fab-default-button>
									</ng-container>
								</div>
							</div>
						</wcd-panel>
					</div>
					<div class="wcd-flex-none noprint">
						<ng-container *ngIf="report.showLastLoadTime !== false">
							<span class="last-update"
									[hidden]="!lastLoadTime">Last update: {{lastLoadTime | date:'HH:mm:ss'}}</span>
							<button class="btn btn-link refresh-button" type="button" (click)="reloadWidgetsData(showIndividualLoadersOnReload)"
									[disabled]="isLoading"
									wcdTooltip="Refresh all">
								 <wcd-shared-icon [iconName]="'refresh'" [ngClass]="{ spinner: isLoading }"> </wcd-shared-icon >
							</button>
						</ng-container>
						<button class="btn btn-link"
								type="button"
								(click)="exportReport()"
								[disabled]="isExporting"
								*ngIf="report.allowExport"
								wcdTooltip="Export as PDF">
							 <wcd-shared-icon [iconName]="'export'" [hidden]="isExporting"> </wcd-shared-icon >
							<span [hidden]="!isExporting">
								<i class="loader-icon"></i>
								Exporting...
							</span>
						</button>
					</div>
				</ng-container>
				<div class="wcd-flex-none noprint wcd-flex-items-end dashboard-custom-controls">
					<ng-content select="[dashboard-controls]"></ng-content>
				</div>
			</div>
		</section>
		<div [hidden]="isEmpty$ | async">
			<report-row
				*ngFor="let row of report.rows"
				[dataOptions]="dataOptions"
				[reportRow]="row"
				[params]="params"
				[headerLevel]="3"></report-row>
			<div *ngIf="report.columns.length" class="dashboard-columns">
				<report-column
					*ngFor="let column of report.columns"
					[params]="params"
					[dataOptions]="dataOptions"
					[style.width]="column.width ? column.width + 'px' : null"
					class="dashboard-column wcd-flex-{{column.width ? 'none' : column.weight}} {{column.className}}"
					[reportColumn]="column"
					[headerLevel]="2"></report-column>
			</div>
		</div>
	</div>

</div>

