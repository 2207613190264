import { OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { format } from 'd3-format';
import { I18nService } from '@wcd/i18n';
import { generateDatesForHistoryArray } from '../../tvm-utils';
import { ChartColor } from '@wcd/charts';
import { LocaleConfigService } from '@wcd/localization';
var EXPOSED_ASSETS_CHART_HEIGHT = 22;
var EXPOSED_ASSETS_CHART_WIDTH = 85;
var TvmAssetsStatisticsComponent = /** @class */ (function () {
    function TvmAssetsStatisticsComponent(changeDetectorRef, localeConfigService, i18nService) {
        this.changeDetectorRef = changeDetectorRef;
        this.localeConfigService = localeConfigService;
        this.i18nService = i18nService;
        this.destroyed = false;
        this.isImprovementNegative = true;
        this.productNeverMatchedTooltip = this.i18nService.strings.tvm_softwareInventory_productNeverMatchedTooltip;
    }
    Object.defineProperty(TvmAssetsStatisticsComponent.prototype, "assetsCountHistory", {
        get: function () {
            return this._assetsCountHistory;
        },
        set: function (newAssetsCountHistory) {
            this._assetsCountHistory = newAssetsCountHistory;
            if (this.newChartOptions) {
                // On new input - force re-render
                this.newChartOptions = null;
                this.changeDetectorRef.detectChanges();
            }
            this.setNewChartOptions(newAssetsCountHistory);
            this.changeDetectorRef.detectChanges();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(TvmAssetsStatisticsComponent.prototype, "productNeverMatched", {
        set: function (flag) {
            this._productNeverMatched = flag;
            this.resetView();
            this.renderChart();
        },
        enumerable: true,
        configurable: true
    });
    TvmAssetsStatisticsComponent.prototype.ngOnInit = function () {
    };
    TvmAssetsStatisticsComponent.prototype.ngOnDestroy = function () {
        TvmAssetsStatisticsComponent.unlocked = true;
        this.destroyed = true;
    };
    TvmAssetsStatisticsComponent.prototype.setNewChartOptions = function (assetsHistory) {
        var historyDataPoints = generateDatesForHistoryArray(assetsHistory, this.localeConfigService.isLocalTimeZone);
        var improvement = this.isImprovementNegative
            ? assetsHistory[0] < assetsHistory[assetsHistory.length - 1] // today's assets count is lower then when history started
            : assetsHistory[0] > assetsHistory[assetsHistory.length - 1]; // today's assets count is higher then when history started
        this.newChartOptions = {
            data: historyDataPoints,
            yMinValue: 0,
            yMaxValue: Math.max.apply(Math, assetsHistory),
            color: improvement ? ChartColor.Green : ChartColor.Red,
            width: EXPOSED_ASSETS_CHART_WIDTH,
            height: EXPOSED_ASSETS_CHART_HEIGHT,
            // We need id to be unique for all charts in each page because it raise accessability error.
            id: "areaChart-" + this.totalAssetCount + "-" + this.assetsCount,
        };
    };
    /**
     * Rendering chart takes time
     * Resetting old view so if angular re-use the component it looks good
     */
    TvmAssetsStatisticsComponent.prototype.resetView = function () {
        if (this._trendOptions) {
            this._trendOptions = null;
            this.changeDetectorRef.detectChanges();
        }
    };
    /**
     * Rendering multiple components like this can block browser thread
     * To resolve the issue - we make sure to have timeout between renders, so the browser can be more interactive
     */
    TvmAssetsStatisticsComponent.prototype.renderChart = function () {
        var _this = this;
        if (TvmAssetsStatisticsComponent.unlocked && !this.destroyed) {
            TvmAssetsStatisticsComponent.unlocked = false;
            this.setTrendOptions();
            this.changeDetectorRef.detectChanges();
            setTimeout(function () { return (TvmAssetsStatisticsComponent.unlocked = true); }, 0);
        }
        else if (!this.destroyed) {
            setTimeout(function () { return _this.renderChart(); }, 0);
        }
    };
    TvmAssetsStatisticsComponent.prototype.setTrendOptions = function () {
        if (!this.assetsCountHistory) {
            this._trendOptions = null;
            return;
        }
        this._trendOptions = this.getHistoryTrendOptions();
    };
    TvmAssetsStatisticsComponent.prototype.getHistoryTrendOptions = function () {
        return {
            data: this.assetsCountHistory,
            area: true,
            graphColor: {
                byImprovement: true,
            },
            xAxisValues: {
                show: false,
            },
            yAxisValues: {
                show: false,
            },
            showYGrid: false,
            height: 33,
            width: 103,
            advancement: {
                show: false,
            },
            showNumberOfDays: false,
            toolTipOptions: {
                format: {
                    value: function (n) { return format(',')(parseInt(n, 10)); },
                    title: function (n) { return null; },
                    name: function (n) { return ''; },
                },
                contents: function (data, defaultTitleFormat, defaultValueFormat, color) {
                    return "<div style=\"background: white;box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);opacity: 0.8;\">\n\t\t\t\t\t" + data[0].value + "</div>";
                },
            },
        };
    };
    TvmAssetsStatisticsComponent.unlocked = true;
    return TvmAssetsStatisticsComponent;
}());
export { TvmAssetsStatisticsComponent };
