var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import { map } from 'rxjs/operators';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
var PendingActionHistoryEntityPanelComponent = /** @class */ (function (_super) {
    __extends(PendingActionHistoryEntityPanelComponent, _super);
    function PendingActionHistoryEntityPanelComponent(changeDetectorRef, featuresService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.featuresService = featuresService;
        _this.moreActionsFilter = function (obs$) {
            return obs$.pipe(map(function (actions) { return actions && actions.filter(function (action) { return action.status.isPending; }); }));
        };
        _this.moreActionsEnabled = _this.featuresService.isEnabled(Feature.BulkFileRemediation);
        return _this;
    }
    return PendingActionHistoryEntityPanelComponent;
}(EntityPanelComponentBase));
export { PendingActionHistoryEntityPanelComponent };
