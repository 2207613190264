/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./incident.rbac.permission.message.component";
import * as i7 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i8 from "../../../rbac/services/rbac-control.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/app-context.service";
var styles_IncidentRbacPermissionMessageComponent = [];
var RenderType_IncidentRbacPermissionMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentRbacPermissionMessageComponent, data: {} });
export { RenderType_IncidentRbacPermissionMessageComponent as RenderType_IncidentRbacPermissionMessageComponent };
function View_IncidentRbacPermissionMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 3, "fab-message-bar", [], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.closeBar("showReadOnlyMessage") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(3, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], dismissable: [1, "dismissable"] }, { onDismiss: "onDismiss" }), (_l()(), i0.ɵted(4, 0, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(5, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.MessageBarType.info; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentClass; _ck(_v, 0, 0, currVal_0); var currVal_3 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "incident_rbac_permission_message_read_only")); _ck(_v, 4, 0, currVal_3); }); }
function View_IncidentRbacPermissionMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "fab-message-bar", [], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.closeBar("showIncidentRbacMessage") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(3, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], dismissable: [1, "dismissable"] }, { onDismiss: "onDismiss" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 0, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.MessageBarType.info; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentClass; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.rbacMessage; _ck(_v, 5, 0, currVal_3); }); }
function View_IncidentRbacPermissionMessageComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "div", [], [[8, "className", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "fab-message-bar", [], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.closeBar("showMTPAlertMessageBar") !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(3, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], dismissable: [1, "dismissable"] }, { onDismiss: "onDismiss" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "div", [], [[8, "innerHTML", 1]], null, null, null, null)), i0.ɵpod(6, { url: 0 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.MessageBarType.info; var currVal_2 = true; _ck(_v, 3, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.contentClass; _ck(_v, 0, 0, currVal_0); var currVal_3 = _co.i18nService.get("incident_MtpAlertsExistMessage", _ck(_v, 6, 0, _co.incidentUrl)); _ck(_v, 5, 0, currVal_3); }); }
export function View_IncidentRbacPermissionMessageComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentRbacPermissionMessageComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentRbacPermissionMessageComponent_2)), i0.ɵdid(6, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentRbacPermissionMessageComponent_3)), i0.ɵdid(9, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showReadOnlyMessage; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.showIncidentRbacMessage; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.showMTPAlertMessageBar; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_IncidentRbacPermissionMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "incident-rbac-permission-message", [], null, null, null, View_IncidentRbacPermissionMessageComponent_0, RenderType_IncidentRbacPermissionMessageComponent)), i0.ɵdid(1, 114688, null, 0, i6.IncidentRbacPermissionMessageComponent, [i7.FeaturesService, i4.I18nService, i0.ChangeDetectorRef, i8.RbacControlService, i9.AppContextService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidentRbacPermissionMessageComponentNgFactory = i0.ɵccf("incident-rbac-permission-message", i6.IncidentRbacPermissionMessageComponent, View_IncidentRbacPermissionMessageComponent_Host_0, { incident: "incident", contentClass: "contentClass" }, {}, []);
export { IncidentRbacPermissionMessageComponentNgFactory as IncidentRbacPermissionMessageComponentNgFactory };
