import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import * as CreateRequest from '../machine-requests.models';
import { AntivirusScanType } from './antivirus-scan.models';

export interface AntivirusScanRawApiRequestBody extends CreateRequest.RawApiRequestBody<'ScanRequest'> {
	readonly Params: {
		readonly ScanType: AntivirusScanType;
	};
}

export type AntivirusScanRawApiResponse = CreateRequest.RawApiResponse;

export interface AntivirusScanParameters extends CreateRequest.Parameters {
	readonly scanType: AntivirusScanType;
}

export type AntivirusScanResponse = CreateRequest.Response;

@ApiCall({
	name: 'Run antivirus scan',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
		scanType,
	}: AntivirusScanParameters): HttpOptions<AntivirusScanRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Params: {
				ScanType: scanType,
			},
			Type: 'ScanRequest',
		},
	}),
	parseData: (rawData: AntivirusScanRawApiResponse): AntivirusScanResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.Id,
	}),
})
export class MachineRunAntivirusScanApiCall extends ApiCallModel<
	AntivirusScanResponse,
	AntivirusScanParameters
> {}
