import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnInit,
	OnDestroy,
	Output,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import {
	RemediationTask,
	RemediationTicket,
	RemediationCategory,
	RemediationTicketStateValue,
	ItsmTool,
	RbacGroups,
	RemediationType,
	RemediationTaskPriority,
	RemediationTaskStateValue,
} from '@wcd/domain';
import { ModelBase } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../tvm/pipes/remediation-task-display-remaining-days.pipe';
import { CalculateRemainingDaysPipe } from '../../../shared/pipes/calculate-remaining-days.pipe';
import { Router } from '@angular/router';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { CommonRemediationService } from '../../../@entities/@tvm/remediation/common-remediation-service/common-remediation.service';
import { AppConfigService } from '@wcd/app-config';
import { TvmMachineGroupsFilterService } from '../../../tvm/services/tvm-machine-groups-filter.service';

@Component({
	selector: 'remediation-task-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './remediation-task.entity-details.component.html',
})
export class RemediationTaskEntityDetailsComponent extends EntityDetailsComponentBase<RemediationTask>
	implements OnInit, OnDestroy {
	private _subscription: Subscription;
	private _relatedRecommendationId: string;
	messageBarType: MessageBarType;
	shouldShowMessageBar = false;
	messageBarString: string;

	showRequestedForSection: boolean;
	appliedToProductivityImpactLabel: string;

	remediationTicketStatus: string;
	remediationStatusBarTitle: string;
	relatedComponent: string;
	isDeviceGroupEnabled: boolean;
	shouldShowDeviceScope: boolean;
	hoverMachineGroups: string;
	numberOfMachineGroupsText: string;
	private _remediationTask: RemediationTask;
	showHyphenForRemediationTaskStatusBar: boolean;
	remainingDays: string;
	priorityText: string;
	completedBy: string;
	isServiceNowEnabled: boolean;
	showTable: boolean;
	showDueDate: boolean;

	@Input()
	set remediationTask(task: RemediationTask) {
		this.showRequestedForSection =
			this.productivityImpactService.isProductivityImpactExposed &&
			!!task.taskArgs.productivityImpactRemediationType;
		if (this.showRequestedForSection) {
			this.appliedToProductivityImpactLabel = this.textsService.getSelectedProductivityRemediation(
				task.taskArgs.productivityImpactRemediationType
			);
		}

		if (
			task.taskArgs.softwareArgs &&
			task.taskArgs.softwareArgs.taskType == RemediationType.AttentionRequired
		) {
			this.showHyphenForRemediationTaskStatusBar = true;
		}

		this._remediationTask = task;
	}

	get remediationTask(): RemediationTask {
		return this._remediationTask;
	}

	@Output() loggedOnUsersClick: EventEmitter<RemediationTask> = new EventEmitter<RemediationTask>();

	constructor(
		private activatedEntity: ActivatedEntity,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private remediationTaskDisplayRemainingDaysPipe: RemediationTaskDisplayRemainingDaysPipe,
		private router: Router,
		featuresService: FeaturesService,
		injector: Injector,
		private i18nService: I18nService,
		private productivityImpactService: TvmProductivityImpactService,
		private textsService: TvmTextsService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private commonRemediationService: CommonRemediationService,
		private appConfigService: AppConfigService
	) {
		super(injector);
		this.isDeviceGroupEnabled = featuresService.isEnabled(Feature.TvmRemediationTaskDeviceGroups);
		this.isServiceNowEnabled = featuresService.isEnabled(Feature.TvmServiceNowIntegration);
	}

	ngOnInit() {
		this.shouldShowDeviceScope =
			this.appConfigService.hasMachineGroups &&
			this.isDeviceGroupEnabled;

		if (this.shouldShowDeviceScope) {
			this.getMachineGroupsFilterTextHover(this._remediationTask.displayedRbacGroupIds);
		}

		if (!this.remediationTask) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof RemediationTask))
				.subscribe((remediationTask: RemediationTask) => {
					this.remediationTask = remediationTask;
					this.initPropertiesFromRemediationTask();
				});
		} else {
			this.initPropertiesFromRemediationTask();
		}
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}

	navigateToRecommendations(): void {
		this.router.navigate(['/security-recommendations', this._relatedRecommendationId], {
			queryParams: {
				search: `${this.getRelatedRecommendationId(false)}`,
			},
		});
	}

	getRelatedRecommendationId(forNav = true) {
		return this._remediationTask.taskArgs.category === RemediationCategory.SecurityConfiguration
			? `${forNav ? 'sca-_-' : ''}${this._remediationTask.taskArgs.securityConfigurationArgs.scid}`
			: `${forNav ? 'va-_-' : ''}${this._remediationTask.taskArgs.softwareArgs.productId}`;
	}

	navigateToRelatedComponent() {
		this.router.navigate(['/software-inventory', this._remediationTask.taskArgs.softwareArgs.productId]);
	}

	hideMessage() {
		this.shouldShowMessageBar = false;
	}

	getMachineGroupsFilterTextHover(groups: RbacGroups) {
		this.commonRemediationService.rbacGroupIdToNameMap$.subscribe(rbacDictionary => {
			this.numberOfMachineGroupsText = this.i18nService.get(
				groups.value.length > 1 ? `tvm.remediationTask.machineGroupsScopeText.plural` : `tvm.remediationTask.machineGroupsScopeText`,
				{ count: groups.value.length }
			);

			const machineGroupNamesToShow = groups.value
				.map(rbacGroupId => rbacDictionary[rbacGroupId])
				.filter(Boolean);

			// Then show it in hover over, with a machine group per line
			this.hoverMachineGroups = machineGroupNamesToShow.join('\n');
		});
	}

	private setMessageBarProp() {
		if (
			this.isServiceNowEnabled &&
			this._remediationTask &&
			this._remediationTask.ticket &&
			this._remediationTask.ticket.itsmTool === ItsmTool.ServiceNow &&
			this._remediationTask.ticket.status === RemediationTicketStateValue.Unknown &&
			!this._remediationTask.ticket.rawStatus &&
			!this._remediationTask.ticket.ErrorDetails
		) {
			this.messageBarType = MessageBarType.warning;
			this.messageBarString = this.i18nService.strings.tvm_remediationTask_read_servicenow_errors_200_200;
			this.shouldShowMessageBar = true;
		}
	}

	private getRemainingDays(): string {
		const remainingDays = this.calculateRemainingDaysPipe.transform(this._remediationTask.dueDate);
		return this.remediationTaskDisplayRemainingDaysPipe.transform(this._remediationTask, remainingDays);
	}

	private shouldShowTable(ticket: RemediationTicket) {
		return (
			ticket &&
			ticket.status !== 'CompletedTask' &&
			(this.isServiceNowEnabled || ticket.itsmTool !== ItsmTool.ServiceNow)
		);
	}

	private getRemediationTicketStatus(): string {
		const ticket = this._remediationTask.ticket;
		if (!ticket) return null;

		return ticket.rawStatus
			? ticket.rawStatus
			: this.i18nService.get(`tvm.remediationTask.ticket.status.${ticket.status}`);
	}

	private getRemediationStatusBarTitle(): string {
		return this.i18nService.get(`tvm.remediationTask.status.${this.remediationTask.status.value}`);
	}

	private getRemediationPriorityText() {
		return this.remediationTask.priority !== 0
			? this.i18nService.get(
					`tvm_remediationTask_priority_${RemediationTaskPriority[this.remediationTask.priority]}`
			  )
			: this.i18nService.get('notAvailable.short');
	}

	private initPropertiesFromRemediationTask() {
		this._relatedRecommendationId = this.getRelatedRecommendationId();
		this.remediationTicketStatus = this.getRemediationTicketStatus();
		this.remediationStatusBarTitle = this.getRemediationStatusBarTitle();
		this.relatedComponent = this.textsService.getText(
			TextToken.RemediationRelatedComponent,
			this.remediationTask
		);
		this.remainingDays = this.getRemainingDays();
		this.priorityText = this.getRemediationPriorityText();
		this.setMessageBarProp();
		this.completedBy = this.textsService.getText(
			TextToken.RemediationTaskCompletedBy,
			this.remediationTask
		);
		this.showTable = this.shouldShowTable(this.remediationTask.ticket);
		this.showDueDate = !(
			this.remediationTask.status.value === RemediationTaskStateValue.Completed ||
			(this.remediationTask.taskArgs.softwareArgs &&
				this.remediationTask.taskArgs.softwareArgs.taskType === RemediationType.AttentionRequired)
		);
	}
}
