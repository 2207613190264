var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { combineLatest, concat, of, Subject, throwError } from 'rxjs';
import { filter, startWith, switchMap } from 'rxjs/operators';
import { File } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
var FilePrevalenceWidgetComponent = /** @class */ (function (_super) {
    __extends(FilePrevalenceWidgetComponent, _super);
    function FilePrevalenceWidgetComponent(reportsService, activatedEntity, I18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.activatedEntity = activatedEntity;
        _this.I18nService = I18nService;
        _this.errorSubject$ = new Subject();
        return _this;
    }
    Object.defineProperty(FilePrevalenceWidgetComponent.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'filePrevalence',
                name: this.I18nService.get('file.prevalence'),
                loadData: function () {
                    return combineLatest(_this.activatedEntity.currentEntity$.pipe(filter(function (currentEntity) { return currentEntity instanceof File; })), _this.errorSubject$.asObservable().pipe(startWith(undefined))).pipe(switchMap(function (_a) {
                        var file = _a[0], error = _a[1];
                        if (error) {
                            return concat(of(null), throwError(error));
                        }
                        return of(file);
                    }));
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    FilePrevalenceWidgetComponent.prototype.onError = function (err) {
        this.errorSubject$.next(err);
    };
    return FilePrevalenceWidgetComponent;
}(ReportWidgetComponent));
export { FilePrevalenceWidgetComponent };
