import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'User account control flags',
	pluralName: 'User account control flags',
})
export class DeviceUserAccountControlFlags extends ModelBase {
	@EntityField({ data: 'Script' })
	script: boolean;

	@EntityField({ data: 'AccountDisabled' })
	accountDisabled: boolean;

	@EntityField({ data: 'HomedirRequired' })
	homedirRequired: boolean;

	@EntityField({ data: 'AccountLocked' })
	accountLocked: boolean;

	@EntityField({ data: 'PasswordNotRequired' })
	passwordNotRequired: boolean;

	@EntityField({ data: 'CannotChangePassword' })
	cannotChangePassword: boolean;

	@EntityField({ data: 'EncryptedTextPasswordAllowed' })
	encryptedTextPasswordAllowed: boolean;

	@EntityField({ data: 'TemporaryDuplicateAccount' })
	temporaryDuplicateAccount: boolean;

	@EntityField({ data: 'NormalAccount' })
	normalAccount: boolean;

	@EntityField({ data: 'InterdomainTrustAccount' })
	interdomainTrustAccount: boolean;

	@EntityField({ data: 'WorkstationTrustAccount' })
	workstationTrustAccount: boolean;

	@EntityField({ data: 'ServerTrustAccount' })
	serverTrustAccount: boolean;

	@EntityField({ data: 'PasswordNeverExpires' })
	passwordNeverExpires: boolean;

	@EntityField({ data: 'MnsLogonAccount' })
	mnsLogonAccount: boolean;

	@EntityField({ data: 'SmartcardRequired' })
	smartcardRequired: boolean;

	@EntityField({ data: 'TrustedForDelegation' })
	trustedForDelegation: boolean;

	@EntityField({ data: 'NotDelegated' })
	notDelegated: boolean;

	@EntityField({ data: 'UseDesKeyOnly' })
	useDesKeyOnly: boolean;

	@EntityField({ data: 'DontRequirePreauthentication' })
	dontRequirePreauthentication: boolean;

	@EntityField({ data: 'PasswordExpired' })
	passwordExpired: boolean;

	@EntityField({ data: 'TrustedToAuthenticationForDelegation' })
	trustedToAuthenticationForDelegation: boolean;

	@EntityField({ data: 'PartialSecretsAccount' })
	partialSecretsAccount: boolean;

	@EntityField({ data: 'UseAesKeys' })
	useAesKeys: boolean;
}
