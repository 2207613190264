import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { FileInstance } from '../file/file-instance.entity';
import { NetworkEndpoint } from './network-endpoint.value-object';
import { Url } from '../url/url.entity';
import { Ip } from '../ip/ip.entity';
import { AntivirusBlockDetails } from './antivirus-block-details.value-object';

@ValueObject({
	singularName: 'File action details',
	pluralName: 'File action details',
})
export class FileActionDetails extends ModelBase {
	@EntityField({ data: 'ActionTime' })
	actionTime: Date;

	@EntityField({ data: 'ActionType' })
	actionType: string;

	@EntityField({
		data: '__self',
		parse: rawData => {
			const url = rawData.FileMotwHostUrl,
				ip = rawData.FileMotwHostIp;
			return url || ip
				? new NetworkEndpoint({
						id: url || ip,
						url: new Url({ id: url, name: url }),
						ip: new Ip({ id: ip, name: ip }),
				  })
				: null;
		},
	})
	fileOrigin: NetworkEndpoint;

	@EntityField({
		data: 'FileMotwReferrerUrl',
		parse: rawData =>
			rawData
				? new NetworkEndpoint({ id: rawData, url: new Url({ id: rawData, name: rawData }) })
				: null,
	})
	fileOriginReferer: NetworkEndpoint;

	@EntityField({ data: '__self' })
	file: FileInstance;

	@EntityField({ data: 'EtwEventPropertiesAsJson' })
	blockDetails?: AntivirusBlockDetails;
}
