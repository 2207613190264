
		<wcd-panel [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<custom-ti-indicator-new
				[customTiIndicatorType]="customTiIndicatorType"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(save)="onSave()"
				(cancel)="closePanel()"
				[indicatorValue]="indicatorValue"
			>
			</custom-ti-indicator-new>
		</wcd-panel>
	