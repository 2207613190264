import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@Entity({
	singularName: 'Exposed device',
	pluralName: 'Exposed devices',
	endpoint: 'webThreats/domains/exposedMachines',
	loadAll: true,
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class DomainExposedMachine extends EntityModelBase {
	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'AccessCount' })
	accessCount: number;
}
