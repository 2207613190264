/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i5 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i6 from "@angular/platform-browser";
import * as i7 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i8 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i9 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i10 from "./copy-to-clipboard.component";
import * as i11 from "../services/clipboard.service";
import * as i12 from "@angular/cdk/a11y";
var styles_CopyToClipboardComponent = [];
var RenderType_CopyToClipboardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CopyToClipboardComponent, data: {} });
export { RenderType_CopyToClipboardComponent as RenderType_CopyToClipboardComponent };
export function View_CopyToClipboardComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), i0.ɵqud(402653184, 1, { copyButton: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 13, "span", [["class", "copy-to-clipboard"]], null, null, null, null, null)), i0.ɵprd(512, null, i3.ɵNgClassImpl, i3.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i3.NgClass, [i3.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(7, 0, [[1, 0], ["copyBtn", 1]], null, 8, "button", [["class", "btn-link"], ["data-track-type", "Button"]], [[2, "copied", null], [8, "disabled", 0], [1, "data-track-id", 0], [1, "aria-label", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 8).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 8).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 8).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 8).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.copyToClipboard() !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i0.ɵdid(8, 147456, null, 0, i4.TooltipDirective, [i0.ElementRef, i5.TooltipsService, i6.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i0.ɵpod(9, { field: 0 }), i0.ɵppd(10, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "wcd-shared-icon", [], null, null, null, i7.View_IconComponent_0, i7.RenderType_IconComponent)), i0.ɵdid(13, 573440, null, 0, i8.IconComponent, [i9.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(15, null, ["\n\t\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "copy-to-clipboard"; var currVal_1 = _co.settings.className; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_6 = _co.tooltipText; _ck(_v, 8, 0, currVal_6); var currVal_7 = (_co.isCopied ? "SkypeCircleCheck" : "Copy"); _ck(_v, 13, 0, currVal_7); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.isCopied; var currVal_3 = !_co.content; var currVal_4 = ("CopyToClipboard-" + _co.ariaLabelFieldName); var currVal_5 = i0.ɵunv(_v, 7, 3, _ck(_v, 10, 0, i0.ɵnov(_v, 0), "common.copyFieldToClipboardLabel", _ck(_v, 9, 0, _co.ariaLabelFieldName))); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_8 = _co.settings.copyText; _ck(_v, 15, 0, currVal_8); }); }
export function View_CopyToClipboardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "copy-to-clipboard", [], null, null, null, View_CopyToClipboardComponent_0, RenderType_CopyToClipboardComponent)), i0.ɵdid(1, 4243456, null, 0, i10.CopyToClipboardComponent, [i11.ClipboardService, i0.ChangeDetectorRef, i2.I18nService, i12.LiveAnnouncer, i3.DOCUMENT], null, null)], null, null); }
var CopyToClipboardComponentNgFactory = i0.ɵccf("copy-to-clipboard", i10.CopyToClipboardComponent, View_CopyToClipboardComponent_Host_0, { content: "content", originId: "originId", settings: "settings", ariaLabelFieldName: "ariaLabelFieldName" }, { copyEvent: "copyEvent", errorEvent: "errorEvent" }, []);
export { CopyToClipboardComponentNgFactory as CopyToClipboardComponentNgFactory };
