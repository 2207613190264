<ng-container *ngIf="_totalCount !== undefined; else loading">
	<button
		tabindex="-1"
		[focusOn]="focusOnMachineGroup"
		class="btn btn-link wcd-font-weight-semibold allow-focus-outline"
		*ngIf="_totalCount > 1"
		(click)="openMachineGroupsFilterPanel()"
		[attr.aria-label]="aria_label">
		<fab-icon iconName="Devices2"></fab-icon>
		<span>{{ 'tvm.machineGroupsFilter.control.header' | i18n }}</span>
		<span>({{ _selectedCount }}<span class="ofCount">/{{ _totalCount }}</span>)</span>
	</button>
</ng-container>
<ng-template #loading>
	<fab-spinner
		[size]="SpinnerSize.small"
		label="{{'tvm.machineGroupsFilter.control.loading' | i18n}}"
		labelPosition="right"
	></fab-spinner>
</ng-template>
