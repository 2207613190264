import {
	ChangeDetectorRef,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	Input,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
	ChangeDetectionStrategy,
} from '@angular/core';
import { DataTableField, DataTableFieldComponent } from '../models/datatable-field.model';
import { generateChanges, OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { isNgChanges } from '@wcd/angular-extensions';

@Component({
	selector: 'wcd-datatable-field-value-custom-component',
	template: `
		<ng-container #customComponent></ng-container>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class DataTableFieldValueCustomComponent<TData = any>
	implements OnChanges<DataTableFieldValueCustomComponent<TData>> {
	@Input() field: DataTableField<TData>;
	@Input() value: TData;

	@ViewChild('customComponent', { read: ViewContainerRef, static: false })
	set customComponent(customComponentPlaceholder: ViewContainerRef) {
		if (customComponentPlaceholder) this.setCustomComponent(customComponentPlaceholder);
	}

	fieldCustomComponentRef: ComponentRef<any>;
	customComponentEmpty: boolean = false;

	constructor(private changeDetectorRef: ChangeDetectorRef, private resolver: ComponentFactoryResolver) {}

	ngOnChanges(changes: TypedChanges<DataTableFieldValueCustomComponent<TData>>) {
		if ((changes.field || changes.value) && this.field && this.value) {
			this.updateValue();
		}
	}

	private updateValue() {
		const fieldCustomComponent = this.getCustomComponent(this.value);

		if (this.fieldCustomComponentRef && fieldCustomComponent.getProps) {
			const props = fieldCustomComponent.getProps(this.value);
			const changes = generateChanges(this.fieldCustomComponentRef.instance, props);
			Object.assign(this.fieldCustomComponentRef.instance, props);
			if (isNgChanges(this.fieldCustomComponentRef.instance))
				this.fieldCustomComponentRef.instance.ngOnChanges(changes);

			this.fieldCustomComponentRef.changeDetectorRef.markForCheck();
		}
	}

	private setCustomComponent(customComponentPlaceholder: ViewContainerRef) {
		customComponentPlaceholder.clear();
		const injector = Injector.create({
			providers: [],
			parent: customComponentPlaceholder.parentInjector,
		});
		const component = this.getCustomComponent(this.value);
		const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(component.type);
		const fieldCustomComponentRef = (this.fieldCustomComponentRef = factory.create(injector));
		const props = component.getProps && component.getProps(this.value);
		if (component.getProps) Object.assign(fieldCustomComponentRef.instance, props);
		if (isNgChanges(this.fieldCustomComponentRef.instance))
			fieldCustomComponentRef.instance.ngOnChanges(generateChanges(props));
		customComponentPlaceholder.insert(fieldCustomComponentRef.hostView);
		this.changeDetectorRef.detectChanges();
	}

	private getCustomComponent(item: TData): DataTableFieldComponent<TData, any> {
		return (
			(this.field.useDefaultEmptyFieldComponent &&
				this.field.getIsEmptyField(item) &&
				this.field.defaultEmptyComponent) ||
			(this.field.getDynamicComponent && this.field.getDynamicComponent(item)) ||
			this.field.component
		);
	}
}
