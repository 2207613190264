import { NgModule } from '@angular/core';
import { MapComponent } from './components/map.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [MapComponent],
	exports: [MapComponent],
})
export class MapsModule {}
