var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { HttpClient } from '@angular/common/http';
import { ApiBaseModel } from '@wcd/data';
import { ServiceUrlsService } from '@wcd/app-config';
import { timeout } from 'rxjs/operators';
var OnboardingBackendService = /** @class */ (function (_super) {
    __extends(OnboardingBackendService, _super);
    function OnboardingBackendService(http, serviceUrlsService) {
        var _this = _super.call(this, http) || this;
        _this.serviceUrlsService = serviceUrlsService;
        return _this;
    }
    OnboardingBackendService.prototype.dismissOnboardingWidget = function () {
        return this.http
            .post(this.serviceUrlsService.management + "/DismissOnboardingTile", null)
            .pipe(timeout(60000));
    };
    return OnboardingBackendService;
}(ApiBaseModel));
export { OnboardingBackendService };
