import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { SoftwareVersionEntityTypeService } from './services/software-version.entity-type.service';
import { SoftwareVersionEntityPanelComponent } from './components/software-entity/software-version.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SoftwareVersionFieldsService } from './services/software-version.fields.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule],
	declarations: [SoftwareVersionEntityPanelComponent],
	exports: [SoftwareVersionEntityPanelComponent],
	entryComponents: [SoftwareVersionEntityPanelComponent],
	providers: [SoftwareVersionFieldsService, SoftwareVersionEntityTypeService],
})
export class SoftwareVersionEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SoftwareVersionEntityTypeService);
	}
}
