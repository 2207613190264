import { SevilleModule } from '../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.onboarding.permissions', onboardingPermissionsController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.permissions', {
			url: '/permissions',
			title: 'Onboarding',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page" ng-if="permissions.validateNavigationCompleted || permissions.isreadonlyuser">
                <div class="onboarding-body-title">
                    <span>Dear {{permissions.username}}</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position onboarding-permissions">
                        <div class="onboarding-body-text">
                            <p>
                                This wizard will guide you through the steps to onboard your organization onto the Microsoft Defender for Endpoint (MDE).
                            <p />
                            <p>
                            You need to be a global administrator or security administrator in your <a href="https://azure.microsoft.com/en-us/documentation/articles/active-directory-assign-admin-roles" target="_blank">Azure Active Directory</a> to complete this wizard.
                            For more information on how to setup the required user permissions, see the <a href="https://go.microsoft.com/fwlink/p/?linkid=822528" target="_blank">Assign user access to the portal</a> section in the in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                            <p />
                            <p>
                                Click Refresh when you've completed assigning user access roles.
                            </p>
            </div>
                    </div>
                </div>
                <div class="onboarding-navigation">
                    Refresh
                    <i class="icon icon-Refresh icon-md onboarding-paging-icon" ng-click="permissions.MoveNext()"></i>
                </div>
            </div>`,
					controller: 'seville.onboarding.permissions',
					controllerAs: 'permissions',
				},
			},
		});
	},
]);

onboardingPermissionsController.$inject = [
	'$scope',
	'$state',
	'authenticationService',
	'$http',
	'appConfig',
	'$window',
	'$location',
	'$anchorScroll',
	'$rootScope',
	'$uibModal',
];

function onboardingPermissionsController(
	$scope,
	$state,
	authenticationService,
	$http,
	appConfig: AppConfigService,
	$window,
	$location,
	$anchorScroll,
	$rootScope,
	$modal
) {
	var vm = this;
	vm.isAssigned = false;
	vm.validateNavigationCompleted = false;
	vm.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);
	vm.onboardingBaseUrl = appConfig.serviceUrls.onboarding;

	$location.hash('');

	$anchorScroll();

	var fullUserName = authenticationService.loggedInUser();
	if (fullUserName != null) {
		var endOfUserName = fullUserName.indexOf('@');
		vm.username = fullUserName.substring(0, endOfUserName);
	} else {
		vm.username = fullUserName;
	}

	$scope.$parent.resetStepsState(function() {
		$scope.$parent.stepPermissionsInProgress = true;
	});

	function showReadOnlyModal() {
		console.log('read only user');
		$modal.open({
			template: `
      <div class="onboarding-readonly-modal">
        <div class="page-header-title">
          Contact your administrator
        </div>
        <br/>
        <div>
          <table>
            <tr>
              <td class="icon icon-lg icon-Error onboarding-readonly-warning-padding"></td>
              <td>
                Service onboarding must be done before you can access the portal.
                <br/>
                Contact your administrator to complete the service onboarding so that you can access the portal.
              </td>
            </tr>
          </table>
        </div>
      </div>
      `,
			controller: 'seville.onboarding.readonlymodal',
			size: 'md',
		});
	}

	vm.MoveNext = function() {
		if (vm.isAssigned === false) {
			$window.location.reload();
		} else {
			if (vm.isreadonlyuser) {
				showReadOnlyModal();
			} else {
				$scope.$parent.stepPermissionsVisited = true;
				$state.onboardingStepPermissions = true;
				$scope.$parent.stepPermissionsDone = true;
				$state.go('onboarding.welcome');
			}
		}
	};

	function IsUserAssignedToRole(getUserCurrentState) {
		$http
			.get(vm.onboardingBaseUrl + '/IsUserAssigned', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data) {
							vm.isAssigned = true;
						} else {
							vm.isAssigned = false;
						}

						if (vm.isreadonlyuser) {
							showReadOnlyModal();
						} else {
							$scope.$parent.stepPermissionsVisited = true;
							getUserCurrentState();
						}
					} else {
						// In case IsUserAssigned failed, assuming the user is not assigned
						vm.validateNavigationCompleted = true;
						console.log('status: ' + response.status);
					}
				},
				function(response) {
					// In case IsUserAssigned failed, assuming the user is not assigned
					vm.validateNavigationCompleted = true;
					console.log('status: ' + response.status);
				}
			);
	}

	function GetUserCurrentState() {
		if (!vm.isAssigned) {
			console.log('skipping, not assigned');

			vm.validateNavigationCompleted = true;
			$scope.$parent.stepPermissionsInProgress = true;

			return;
		}
		$http
			.get(vm.onboardingBaseUrl + '/GetUserCurrentState', {
				withCredentials: true,
				timeout: 60000,
			})
			.then(
				function(response) {
					$scope.$parent.stepPreferencesInProgress = false;
					if (response.status === 200) {
						const status: number = response.data;
						$scope.$parent.currentOnboardingState = status;
						if (status < $scope.$parent.TenantOnboardingStatus.Configured) {
							$state.go('onboarding.welcome');
							$scope.$parent.resetStepsState(function() {
								$scope.$parent.stepPermissionsDone = true;
								$scope.$parent.stepWelcomeInProgress = true;
							});
						} else if (
							status >= $scope.$parent.TenantOnboardingStatus.Configured &&
							status < $scope.$parent.TenantOnboardingStatus.Packaged
						) {
							$state.go('onboarding.processing');
							$scope.$parent.resetStepsState(function() {
								$scope.$parent.stepPermissionsDone = true;
								$scope.$parent.stepWelcomeDone = true;
								$scope.$parent.stepPreferencesInProgress = true;
							});
						} else if (status >= $scope.$parent.TenantOnboardingStatus.Packaged) {
							$state.go('onboarding.download');
							$scope.$parent.resetStepsState(function() {
								$scope.$parent.stepPermissionsDone = true;
								$scope.$parent.stepWelcomeDone = true;
								$scope.$parent.stepPreferencesDone = true;
								$scope.$parent.stepDownloadInProgress = true;
							});
						}
					} else {
						console.log('status: ' + response.status);
					}
					vm.validateNavigationCompleted = true;
				},
				function(response) {
					// In case GetUserCurrentState failed, starting from permissions
					vm.validateNavigationCompleted = true;
					console.log('status: ' + response.status);
				}
			);
	}

	function StartWizard() {
		IsUserAssignedToRole(function() {
			GetUserCurrentState();
		});
	}

	StartWizard();
}
