/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i4 from "ngx-markdown";
import * as i5 from "./manage-non-windows-third-party.component";
import * as i6 from "../../../insights/services/app-insights.service";
import * as i7 from "@angular/router";
var styles_ManageNonWindowsThirdPartyComponent = [];
var RenderType_ManageNonWindowsThirdPartyComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ManageNonWindowsThirdPartyComponent, data: {} });
export { RenderType_ManageNonWindowsThirdPartyComponent as RenderType_ManageNonWindowsThirdPartyComponent };
function View_ManageNonWindowsThirdPartyComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_ManageNonWindowsThirdPartyComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 15, "section", [["class", "wcd-margin-large-bottom"], ["data-track-component", "Non Windows Onboarding"], ["data-track-component-type", "Settings"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "h3", [["class", "wcd-padding-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(4, null, ["", ""])), i0.ɵppd(5, 1), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 5, "div", [["class", "wcd-padding-large-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "markdown", [], null, null, null, i3.View_MarkdownComponent_0, i3.RenderType_MarkdownComponent)), i0.ɵdid(11, 4767744, null, 0, i4.MarkdownComponent, [i0.ElementRef, i4.MarkdownService], { data: [0, "data"] }, null), i0.ɵppd(12, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\t\n\t"])), (_l()(), i0.ɵand(0, [["results", 2]], null, 0, null, View_ManageNonWindowsThirdPartyComponent_1)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), "endpointManagement.nonWindows.onboarding.thirdParty.step1.description")); _ck(_v, 11, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.onboarding ? i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v, 0), "endpointManagement.nonWindows.onboarding.thirdParty.step1.title")) : i0.ɵunv(_v, 4, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "endpointManagement.nonWindows.offboarding.thirdParty.step1.title"))); _ck(_v, 4, 0, currVal_0); }); }
export function View_ManageNonWindowsThirdPartyComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "manage-non-windows-third-party", [], null, null, null, View_ManageNonWindowsThirdPartyComponent_0, RenderType_ManageNonWindowsThirdPartyComponent)), i0.ɵdid(1, 49152, null, 0, i5.ManageNonWindowsThirdPartyComponent, [i6.AppInsightsService, i2.I18nService, i7.ActivatedRoute], null, null)], null, null); }
var ManageNonWindowsThirdPartyComponentNgFactory = i0.ɵccf("manage-non-windows-third-party", i5.ManageNonWindowsThirdPartyComponent, View_ManageNonWindowsThirdPartyComponent_Host_0, {}, {}, []);
export { ManageNonWindowsThirdPartyComponentNgFactory as ManageNonWindowsThirdPartyComponentNgFactory };
