import { Component, ComponentRef, EventEmitter, Input, Output } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { SimpleDataListPanelComponent } from './simple-data-list.panel.component';
import { FabricIconName } from '@wcd/scc-common';
import { DialogsService } from '../../../dialogs/services/dialogs.service';

@Component({
	selector: 'simple-data-list-table',
	template: `
		<ul
			[ngClass]="{
				'wcd-margin-large-top': shouldShowTitle,
				'wcd-margin-medium-horizontal': fullPanelView,
				'clear-list-style': true
			}"
		>
			<li *ngIf="dataList.length > 0 && shouldShowTitle" class="data-row-title">
				{{ i18n.get(titleKey, { count: dataList.length }) }}
			</li>
			<ng-template ngFor let-dataItem [ngForOf]="dataList" let-i="index">
				<li *ngIf="!openPanelForAllItems || i < 3" class="data-row">
					<div class="wcd-flex-horizontal wcd-flex-center-vertical">
						<div class="wcd-width-large wcd-margin-small-right">
							<wcd-shared-icon
								*ngIf="shouldShowIcon"
								[iconName]="iconName"
								class="small-icon wcd-margin-small-right"
							></wcd-shared-icon>
							{{ dataItem }}
						</div>
						<button
							*ngIf="shouldShowDeleteButton"
							type="button"
							class="btn-link"
							data-track-id="RemoveEmail"
							data-track-type="Button"
							(click)="onDeleteClicked(dataItem)"
							wcdTooltip="{{ i18n.strings.notificationRules_ruleEmails_remove_description }}"
							[attr.aria-label]="
								('notificationRules.ruleEmails.remove.title' | i18n) + ' ' + dataItem
							"
						>
							<wcd-shared-icon [iconName]="'delete'" class="small-icon"></wcd-shared-icon>
						</button>
					</div>
				</li>
			</ng-template>
		</ul>
		<div *ngIf="openPanelForAllItems && dataList.length > 3">
			<a
				data-track-type="SidePaneToggleButton"
				data-track-id="OpenAllDataListEntitiesPanel"
				(click)="openPanel()"
				(keydown.enter)="openPanel()"
				tabindex="0"
				role="button"
			>
				{{ i18n.strings[seeAllKey] }}
			</a>
		</div>
	`,
	styleUrls: ['./simple-data-list-table.component.scss'],
})
export class SimpleDataListTableComponent {
	@Input() dataList: string[];
	@Input() shouldShowIcon: boolean = false;
	@Input() iconName: string | FabricIconName;
	@Input() shouldShowDeleteButton: boolean = false;
	@Input() openPanelForAllItems: boolean = false;
	@Input() shouldShowTitle: boolean = false;
	@Input() titleKey: string;
	@Input() fullPanelView: boolean = false;
	@Input() seeAllKey: string;

	@Output() onDeleteIconClick: EventEmitter<string> = new EventEmitter<string>();

	private _simpleDataListPanel: ComponentRef<SimpleDataListPanelComponent>;

	constructor(public i18n: I18nService, private dialogsService: DialogsService) {}

	openPanel() {
		const { dataList, shouldShowIcon, iconName } = this;

		this.dialogsService
			.showPanel(
				SimpleDataListPanelComponent,
				{
					headerText: `${this.i18n.get(this.titleKey, {
						count: this.dataList.length,
					})}`,
					headerClassName: 'wcd-padding-left',
					type: PanelType.large,
					hasCloseButton: false,
					back: { onClick: () => this._simpleDataListPanel.instance.closePanel() },
				},
				{ dataList, shouldShowIcon, iconName }
			)
			.subscribe((panel: ComponentRef<SimpleDataListPanelComponent>) => {
				this._simpleDataListPanel = panel;
				panel.onDestroy(() => {
					this._simpleDataListPanel = null;
				});
			});
	}

	public onDeleteClicked(dataItem) {
		this.onDeleteIconClick.emit(dataItem);
	}
}
