import {
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Injectable,
	Injector,
	OnDestroy,
	ViewContainerRef,
} from '@angular/core';
import { Position } from '../shared/position.interface';
import { fitPosition } from '../shared/position.service';
import { Dimensions } from '../shared/dimensions.interface';
import { generateChanges } from '@wcd/angular-extensions';
import {LiveAnnouncer} from "@angular/cdk/a11y";

const TOOLTIP_CLASS_NAME = 'wcd-tooltip';
const TOOLTIP_ID = 'wcd-tooltip';
const TOOLTIP_VISIBLE_CLASS_NAME = 'wcd-tooltip__visible';
const TOOLTIP_ACTIONABLE_CLASS_NAME = 'wcd-tooltip__actionable';

type UpdateTootipRemovedCallback = () => void;

@Injectable({ providedIn: 'root' })
export class TooltipsService implements OnDestroy {
	private tooltipTextElement: HTMLElement;
	private _isMouseOver = false;

	private innerComponentViewRef: ViewContainerRef;
	private innerComponentContainerElement: ElementRef<HTMLElement>;
	private tooltipRemovedCallback: UpdateTootipRemovedCallback;

	private detailsComponentRef: ComponentRef<any>;
	private tooltipElement: HTMLElement;
	private readonly mouseenterCallback = this.onmouseenter.bind(this);
	private readonly mouseleaveCallback = this.onmouseleave.bind(this);
	private readonly keypressCallback = this.onkeypress.bind(this);
	private readonly keydownCallback = this.onkeydown.bind(this);
	private readonly keyupCallback = this.onkeyup.bind(this);

	constructor(private injector: Injector, private resolver: ComponentFactoryResolver, private liveAnnouncer: LiveAnnouncer) { }

	private createTextTooltipElementContainer() {
		this.tooltipTextElement = document.createElement('div');
		this.tooltipTextElement.className = TOOLTIP_CLASS_NAME;
		this.tooltipTextElement.id = TOOLTIP_ID;
		document.body.appendChild(this.tooltipTextElement);
	}

	private registerEventListenersToTootip() {
		this.tooltipElement && this.tooltipElement.addEventListener('mouseenter', this.mouseenterCallback);
		this.tooltipElement && this.tooltipElement.addEventListener('mouseleave', this.mouseleaveCallback);
	}

	private unregisterEventListenersToTootip() {
		this.tooltipElement && this.tooltipElement.removeEventListener('mouseenter', this.mouseenterCallback);
		this.tooltipElement && this.tooltipElement.removeEventListener('mouseleave', this.mouseleaveCallback);
	}

	private registerEventListenersToWindow() {
		window.addEventListener('keypress', this.keypressCallback);
		window.addEventListener('keydown', this.keydownCallback);
		window.addEventListener('keyup', this.keyupCallback);
	}

	private unregisterEventListenersToWindow() {
		window.removeEventListener('keypress', this.keypressCallback);
		window.removeEventListener('keydown', this.keydownCallback);
		window.removeEventListener('keyup', this.keyupCallback);
	}

	registerViewContainerRef(vcRef: ViewContainerRef): void {
		this.innerComponentViewRef = vcRef;
	}

	registerTooltipContainerElement(vcRef: ElementRef): void {
		this.innerComponentContainerElement = vcRef;
		this.innerComponentContainerElement.nativeElement.addEventListener(
			'mouseenter',
			this.onmouseenter.bind(this)
		);
		this.innerComponentContainerElement.nativeElement.addEventListener(
			'mouseleave',
			this.onmouseleave.bind(this)
		);
	}

	unregisterViewContainerRef(): void {
		this.innerComponentViewRef.clear();
		this.innerComponentViewRef = null;
	}

	unregisterTooltipContainerElement(): void {
		this.innerComponentContainerElement = null;
	}

	ngOnDestroy() {
		this.clearLastTooltip();
		this.tooltipTextElement && this.tooltipTextElement.parentNode.removeChild(this.tooltipTextElement);
	}

	setCustomComponentTooltip(
		componentType: any,
		tooltipRemovedCallback: UpdateTootipRemovedCallback,
		componentInputs: any
	) {
		if (this.tooltipElement) {
			// We call this in case a new tooltip is added before last one is cleared due to race condition
			this.innerComponentViewRef.clear();
			this.clearLastTooltip();
		}
		this.tooltipRemovedCallback = tooltipRemovedCallback;

		const factory: ComponentFactory<any> = this.resolver.resolveComponentFactory<any>(componentType);
		this.detailsComponentRef = factory ? factory.create(this.injector) : null;

		if (this.detailsComponentRef) {
			Object.assign(
				this.detailsComponentRef.instance,
				{
					options: {},
				},
				componentInputs
			);
			const changes = generateChanges(this.detailsComponentRef.instance, componentInputs);
			this.detailsComponentRef.instance.ngOnChanges &&
				this.detailsComponentRef.instance.ngOnChanges(changes);

			this.innerComponentViewRef.insert(this.detailsComponentRef.hostView, 0);
			this.tooltipElement = this.innerComponentContainerElement.nativeElement;
			this.registerEventListenersToTootip();
			this.registerEventListenersToWindow();
		}
	}

	setTextTooltip(tooltip: string, tooltipRemovedCallback: UpdateTootipRemovedCallback, renderAsHtml: boolean) {
		if (!this.tooltipTextElement) {
			this.createTextTooltipElementContainer();
		}
		if (this.tooltipElement) {
			// We call this in case a new tooltip is added before last one is cleared due to race condition
			this.clearLastTooltip();
		}
		this.tooltipRemovedCallback = tooltipRemovedCallback;

		if (renderAsHtml) {
			this.tooltipTextElement.innerHTML = tooltip;
		} else {
			this.tooltipTextElement.innerText = tooltip;
		}
		this.tooltipElement = this.tooltipTextElement;
		this.registerEventListenersToTootip();
		this.registerEventListenersToWindow();
	}

	clearLastTooltip() {
		if (this.tooltipRemovedCallback) {
			this.tooltipRemovedCallback();
		}
		this.toggleVisibility(false);
		this.unregisterTooltipEvents();
	}

	unregisterTooltipEvents() {
		this.unregisterEventListenersToTootip();
		this.unregisterEventListenersToWindow();
		this.innerComponentViewRef && this.innerComponentViewRef.clear();
		this.tooltipRemovedCallback = null;
		this.tooltipElement = null;
	}

	setTooltipClassName(className: string) {
		this.tooltipElement.className = `${TOOLTIP_CLASS_NAME} ${className}`;
	}

	toggleVisibility(isTooltipVisible: boolean) {
		if (!this.tooltipElement) {
			return;
		}
		// The actionable tooltip can't stay when the tooltip is visible because the tooltip has display:block style even when it's not displayed
		// It's visibility is controlled by opacity. Therefore, when the the tooltip is hidden,
		// it's actually rendered and transparent and blocks mouse events on underlying elements
		// To prevent this blocking, pointer-events: none style has been added, which we remove for actionable tooltips
		// We need to remove pointer-events: none only while the tooltip is displayed,
		// I tried to change to display:none when tooltip isn't visible, but the it's rendered outside of page bounds when the tooltip is near the edge
		if (isTooltipVisible) {
			this.tooltipElement.classList.add(TOOLTIP_VISIBLE_CLASS_NAME);
			this.tooltipElement.classList.add(TOOLTIP_ACTIONABLE_CLASS_NAME);
		} else {
			this.tooltipElement.classList.remove(TOOLTIP_VISIBLE_CLASS_NAME, TOOLTIP_ACTIONABLE_CLASS_NAME);
		}
	}

	setTooltipPosition(position: Position, dimensions: Dimensions) {
		if (!this.tooltipElement) {
			return null;
		}
		const tooltipPosition: Position = fitPosition(position, dimensions, 10, 10);

		this.tooltipElement.style.top = tooltipPosition.top + 'px';
		this.tooltipElement.style.left = tooltipPosition.left + 'px';
	}

	getTooltipDimensions(): Dimensions {
		if (!this.tooltipElement) {
			return null;
		}
		this.tooltipElement.style.removeProperty('width');
		this.tooltipElement.style.removeProperty('height');

		return {
			width: this.tooltipElement.clientWidth,
			height: this.tooltipElement.clientHeight,
		};
	}

	onmouseenter() {
		this._isMouseOver = true;
	}

	onkeypress() {
		this.onmouseleave();
	}

	onkeydown() {
		this.onmouseleave();
	}

	onkeyup() {
		this.onmouseleave();
	}

	onmouseleave() {
		this._isMouseOver = false;
		this.toggleVisibility(false);
	}

	// This property only works correctly when isActionable is true because otherwise, the mouse events are disabled and we don't know whether the mouse is over the tooltip or not
	// When isActionable is false, this will always return false
	get isMouseOver(): boolean {
		return this._isMouseOver && this.isVisible;
	}

	get isVisible(): boolean {
		return this.tooltipElement.classList.contains(TOOLTIP_VISIBLE_CLASS_NAME);
	}

	announce(tooltipText: string) {
		this.liveAnnouncer.announce(tooltipText);
	}
}
