import { Component, OnDestroy, Input } from '@angular/core';
import {
	DetectionTestSettingsService,
	TestDetectionSettings,
} from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { SenseEndpointsManagementState } from '@wcd/domain';
import { Subscription } from 'rxjs';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import {
	MANAGEMENT_FLOWS,
	EndpointManagementProcessesService,
} from '../../services/endpoint-management-processes.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import {
	EndpointManagementProcess,
	EndpointManagementPackage,
} from '../../models/endpoint-management-process.model';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'sense-onboarding',
	templateUrl: './sense-onboarding.component.html',
})
export class SenseOnboardingComponent implements OnDestroy {
	@Input()
	set osType(process: EndpointManagementProcess) {
		this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.onboarding, process);
		this.currentMethod = this.packages[0];
		this.isDownlevel = process.isDownlevel;
	}
	@Input() handleErrorAsIncompleteStatus = false;

	loading: boolean = true;
	error: boolean = false;
	onboardingState: SenseEndpointsManagementState = new SenseEndpointsManagementState(null);
	detectionTestSettings: TestDetectionSettings = this.detectionTestSettingsService.all.sense;
	getOnboardingStateSubscription: Subscription;
	packages: Array<EndpointManagementPackage> = this.packagesService.getEnabledPackages(
		MANAGEMENT_FLOWS.onboarding,
		this.endpointManagementProcessesService.default
	);
	currentMethod: EndpointManagementPackage = this.packages && this.packages[0];
	isDownlevel: boolean = false;

	constructor(
		private endpointManagementService: EndpointManagementService,
		private endpointManagementProcessesService: EndpointManagementProcessesService,
		public packagesService: EndpointManagementSensePackagesService,
		private detectionTestSettingsService: DetectionTestSettingsService,
		private dialogsService: DialogsService,
		private appInsights: AppInsightsService,
		private i18nService: I18nService
	) {
		this.getOnboardingStateSubscription = this.endpointManagementService
			.getSenseOnboardingState()
			.subscribe(
				(state: SenseEndpointsManagementState) => {
					this.loading = false;
					this.setState(state);
				},
				error => {
					this.loading = false;
					if (this.handleErrorAsIncompleteStatus) {
						this.onboardingState.ranTestAlert = false;
						this.onboardingState.machineOnboarded = false;
					} else {
						this.error = true;
					}
					this.appInsights.trackEvent('EndpointOnboarding-Status', {
						ResponseStatus: String(error.status),
						FailureType: 'API',
						RanTestAlert: String(this.onboardingState.ranTestAlert),
						MachineOnboarded: String(this.onboardingState.machineOnboarded),
					});
				}
			);

		this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
	}

	ngOnDestroy() {
		this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
	}

	getDeploymentMethodLabel(managementPackage: EndpointManagementPackage) {
		return this.i18nService.get(
			'endpointManagement.deploymentMethods.' + managementPackage.name + '.title'
		);
	}

	downloadPackage() {
		this.endpointManagementService.downloadSensePackage(true, this.currentMethod.id).subscribe(
			(res: string) => {
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(200),
					RanTestAlert: String(this.onboardingState.ranTestAlert),
					MachineOnboarded: String(this.onboardingState.machineOnboarded),
				});
				window.location.href = res;
			},
			(error: HttpErrorResponse) => {
				this.dialogsService.showError({
					title: this.i18nService.get('endpointManagement.download.package.failed'),
					data: error,
				});
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(error.status),
					FailureType: 'API',
					RanTestAlert: String(this.onboardingState.ranTestAlert),
					MachineOnboarded: String(this.onboardingState.machineOnboarded),
				});
			}
		);
	}

	setState(state: SenseEndpointsManagementState) {
		this.onboardingState = state;
		this.appInsights.trackEvent('EndpointOnboarding-Status', {
			RanTestAlert: String(this.onboardingState.ranTestAlert),
			MachineOnboarded: String(this.onboardingState.machineOnboarded),
		});

		if (this.onboardingState.machineOnboarded && this.onboardingState.ranTestAlert) {
			this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
		}
	}

	downloadInstallationPackage() {
		window.location.href =
			'https://go.microsoft.com/fwlink/?linkid=2168294';
	}
}
