
		<wcd-panel (close)="destroy()" [settings]="settings">
			<div
				class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical"
				data-track-component="Machine action center Panel"
				data-track-component-type="Side Panel"
			>
				<header
					class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
				>
					<div class="page-header-icon wcd-flex-none">
						<wcd-shared-icon class="round-icon color-box-gray-200" iconName="F12DevTools">
						</wcd-shared-icon>
					</div>
					<div class="wcd-flex-1 wcd-flex-center-vertical">
						<h3 #panelHeader class="side-panel-title">
							{{ 'machines.entityDetails.actions.actionCenter.title' | i18n }}
						</h3>
					</div>
				</header>

				<div class="wcd-flex-1 wcd-flex-vertical wcd-padding-vertical wcd-padding-large-horizontal">
					<ng-container *ngIf="(currentMachine$ | async) as machine">
						<ng-container *ngIf="!isLoading; else loader">
							<main class="wcd-flex-auto wcd-scroll-vertical">
								<ng-container
									*ngFor="
										let section of sections;
										index as i;
										let first = first;
										let last = last
									"
								>
									<wcd-collapsible-section
										*ngIf="(section.state$ | async) as state"
										[label]="section.labelKey | i18n"
										[sectionId]="collapsibleID.Details + i"
										[showTopBorder]="!first"
									>
										<action-center-section
											[rbac]="investigationPackageCollectionRbac"
											[submission]="state.submission"
											[comments]="state.comments"
											[status]="state.status"
											(onCancelSubmissionActionClicked)="
												section.onCancelSubmission(machine)
											"
										></action-center-section>
									</wcd-collapsible-section>
								</ng-container>
							</main>

							<fab-message-bar *ngIf="sections" role="footer" className="wcd-margin-small-top">
								{{ 'machines.actionCenter.notice' | i18n }}
							</fab-message-bar>
						</ng-container>

						<ng-template #loader>
							<fab-spinner
								[size]="SpinnerSize.large"
								[label]="'machines.actionCenter.loading' | i18n"
							></fab-spinner>
						</ng-template>
					</ng-container>
				</div>
			</div>
		</wcd-panel>
	