import { DataEntityType, EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { AirsEntityRemediationStatus } from './airs-entity-remediation-status.entity';
import { AirsEntityVerdict } from './airs-entity-verdict.entity';

const verdictValues = (<DataEntityType>AirsEntityVerdict).entityConfig.values;
const remediationStatusValues = (<DataEntityType>AirsEntityRemediationStatus).entityConfig.values;

@ValueObject({
	singularName: 'Entity Status Count',
	pluralName: 'Entity Status Counts',
	readonly: true,
})
export class AirsEntityStatusCount extends ModelBase {
	@EntityField({ data: ['status', 'result'] })
	private _status: AirsEntityStatus;

	@EntityField({ data: ['remediation_status'] })
	remediationStatus: AirsEntityRemediationStatus;

	get status(): AirsEntityStatus {
		return (
			(this.remediationStatus &&
				remediationStatusValues.find(s => s.id === this.remediationStatus.id)) ||
			(this._status && verdictValues.find(s => s.id === this._status.id)) ||
			null
		);
	}

	@EntityField() count: number;
}
