var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DownloadService } from '../services/download.service';
import { Observable } from 'rxjs';
import { toObservable } from '../../utils/rxjs/utils';
import { map } from 'rxjs/operators';
import { EventEmitter } from '@angular/core';
var DownloadFileComponent = /** @class */ (function () {
    function DownloadFileComponent(downloadService) {
        this.downloadService = downloadService;
        this.disabled = false;
        this.onDownload = new EventEmitter();
    }
    DownloadFileComponent.prototype.downloadFile = function () {
        this.onDownload.emit(null);
        if (this.fileContent) {
            var blob$ = toObservable(this.fileContent).pipe(map(function (content) { return new Blob([content], { type: 'text/plain' }); }));
            return this.downloadService.download(blob$, this.filename);
        }
        else if (this.downloadSettings && this.downloadSettings.url) {
            var options = __assign({}, this.downloadSettings, { downloadedFileName: this.filename });
            return this.downloadService.downloadFromUrl(this.downloadSettings.url, options);
        }
    };
    return DownloadFileComponent;
}());
export { DownloadFileComponent };
