var _a;
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { BaselineCompliant } from '../components/baseline-compliant-bar/baseline-compliant.enum';
import { BaselineCompliantColorService } from './baseline-compliant-color.service';
var BaselineCompliantDisplayKeysMap = (_a = {},
    _a[BaselineCompliant.Compliant] = 'tvm_baseline_compliant_enum_compliant',
    _a[BaselineCompliant.NotCompliant] = 'tvm_baseline_compliant_enum_notCompliant',
    _a[BaselineCompliant.DataUnavailable] = 'tvm_baseline_compliant_enum_dataUnavailable',
    _a);
var BaselineCompliantBarService = /** @class */ (function () {
    function BaselineCompliantBarService(baselineCompliantColorService, displayTextPipe) {
        var _this = this;
        this.baselineCompliantColorService = baselineCompliantColorService;
        this.displayTextPipe = displayTextPipe;
        this.compliantColorMap = {
            type: 'class',
            map: this.baselineCompliantColorService.backgroundColorsClassMap,
        };
        this.baselineCompliantKeys = Object.values(BaselineCompliant);
        this.compliantTextFormatter = function (status) {
            return _this.displayTextPipe.transform(status, BaselineCompliantDisplayKeysMap);
        };
        this.legendTextFormatter = function (status, count) { return _this.compliantTextFormatter(status) + " (" + count + ")"; };
        this.legendItems = this.baselineCompliantKeys.map(function (key) { return ({
            name: _this.compliantTextFormatter(key),
            iconClassName: _this.baselineCompliantColorService.fontColorsClassMap.get(key),
        }); });
    }
    return BaselineCompliantBarService;
}());
export { BaselineCompliantBarService };
