import { Injectable } from '@angular/core';
import {
	ColorMap,
	KindTextFormatter,
} from '../../shared/components/events-summary-bar/events-summary-bar.component';
import { LegendItem } from '@wcd/charts';
import { Strings } from '@wcd/i18n';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { BaselineCompliant } from '../components/baseline-compliant-bar/baseline-compliant.enum';
import { BaselineCompliantColorService } from './baseline-compliant-color.service';

const BaselineCompliantDisplayKeysMap: Record<BaselineCompliant, keyof Strings> = {
	[BaselineCompliant.Compliant]: 'tvm_baseline_compliant_enum_compliant',
	[BaselineCompliant.NotCompliant]: 'tvm_baseline_compliant_enum_notCompliant',
	[BaselineCompliant.DataUnavailable]: 'tvm_baseline_compliant_enum_dataUnavailable',
};

@Injectable()
export class BaselineCompliantBarService {
	compliantColorMap: ColorMap<BaselineCompliant> = {
		type: 'class',
		map: this.baselineCompliantColorService.backgroundColorsClassMap,
	};

	baselineCompliantKeys: BaselineCompliant[] = Object.values(BaselineCompliant);

	compliantTextFormatter: KindTextFormatter<BaselineCompliant> = status =>
		this.displayTextPipe.transform(status, BaselineCompliantDisplayKeysMap);

	readonly legendTextFormatter = (status, count) => `${this.compliantTextFormatter(status)} (${count})`;

	legendItems: LegendItem[] = this.baselineCompliantKeys.map(key => ({
		name: this.compliantTextFormatter(key),
		iconClassName: this.baselineCompliantColorService.fontColorsClassMap.get(key),
	}));

	constructor(
		private baselineCompliantColorService: BaselineCompliantColorService,
		private displayTextPipe: DisplayTextPipe
	) {}
}
