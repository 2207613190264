import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { AlertTimelineElement } from './alert-timeline-element.value-object';
import { AlertTimelineMissingEventsSummary } from './alert-timeline-missing-events-summary';

/**
 * old alert timeline element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Timeline',
	pluralName: 'Alert Timelines',
})
export class AlertTimeline extends ModelBase {
	@EntityField({ data: 'RootElements', arrayOf: AlertTimelineElement })
	elements: Array<AlertTimelineElement>;

	@EntityField({ data: 'MissingEventsSummary' })
	readonly missingEventSummary: AlertTimelineMissingEventsSummary;

	@EntityField({ data: '__self' })
	raw: any;
}
