
		<span
			[wcdTooltip]="showTooltip ? tooltip : null"
			[wcdTooltipAllowHtmlRendering]="showTooltip ? true : null"
		>
			<ng-container *ngIf="displayQuery; else emailMessage">
				{{ displayQuery }}
			</ng-container>
			<ng-template #emailMessage>
				<ng-container *ngIf="senderEmailAddress || recipientEmailAddress">
					<span>
						<ng-container *ngIf="senderEmailAddress">
							<strong>{{ 'actionCenter.details.email_remediation.from' | i18n }}</strong>
							{{ senderEmailAddress }}
						</ng-container>
						<br />
						<ng-container *ngIf="recipientEmailAddress">
							<strong>{{ 'actionCenter.details.email_remediation.to' | i18n }}</strong>
							{{ recipientEmailAddress }}
						</ng-container>
					</span>
				</ng-container>
			</ng-template>
		</span>
	