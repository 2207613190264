<h2 class="wcd-font-weight-bold">
	{{ i18n.strings.email_notification_wizard_review_rule_step_title }}
</h2>
<div class="wcd-margin-large-top">
	<div class="wcd-margin-xLarge-top wcd-margin-mediumSmall-bottom" *ngIf="!isSettingsV2">
		{{ i18n.strings.email_notification_wizard_summary_note_part1 }}
		<span class="wcd-font-weight-bold" *ngIf="data.isUpdateFlow">
			{{ i18n.strings.email_notification_wizard_summary_note_part2_update }}
		</span>
		<span class="wcd-font-weight-bold" *ngIf="!data.isUpdateFlow">
			{{ i18n.strings.email_notification_wizard_summary_note_part2_create }}
		</span>
		{{ i18n.strings.email_notification_wizard_summary_note_part3 }}
	</div>
	<email-notification-summary
		[data]="data"
		[shouldDisplayNotificationSettings]="shouldDisplayNotificationSettings"
		[isSettingsV2]="isSettingsV2"
		[customSettings]="customSettings"
		[rulesSettings]="rulesSettings"
		[notificationSettings]="notificationSettings"
		[shouldDisplayDeviceGroups] = "shouldDisplayDeviceGroups"
		[status]="status"
		[explicitGlobalNotificationText]="explicitGlobalNotificationText"
		[goToStep] = "goToStep">
	</email-notification-summary>
</div>
