var _a;
export var AlertDeterminationType;
(function (AlertDeterminationType) {
    AlertDeterminationType["Apt"] = "Apt";
    AlertDeterminationType["Malware"] = "Malware";
    AlertDeterminationType["SecurityPersonnel"] = "SecurityPersonnel";
    AlertDeterminationType["SecurityTesting"] = "SecurityTesting";
    AlertDeterminationType["UnwantedSoftware"] = "UnwantedSoftware";
    AlertDeterminationType["Other"] = "Other";
})(AlertDeterminationType || (AlertDeterminationType = {}));
export var combinedAlertDeterminationType;
(function (combinedAlertDeterminationType) {
    combinedAlertDeterminationType["Apt"] = "Apt";
    combinedAlertDeterminationType["Malware"] = "Malware";
    combinedAlertDeterminationType["SecurityPersonnel"] = "SecurityPersonnel";
    combinedAlertDeterminationType["SecurityTesting"] = "SecurityTesting";
    combinedAlertDeterminationType["UnwantedSoftware"] = "UnwantedSoftware";
    combinedAlertDeterminationType["Other"] = "Other";
    combinedAlertDeterminationType["MultiStagedAttack"] = "MultiStagedAttack";
    combinedAlertDeterminationType["CompromisedUser"] = "CompromisedUser";
    combinedAlertDeterminationType["Phishing"] = "Phishing";
    combinedAlertDeterminationType["MaliciousUserActivity"] = "MaliciousUserActivity";
    combinedAlertDeterminationType["Clean"] = "Clean";
    combinedAlertDeterminationType["InsufficientData"] = "InsufficientData";
    combinedAlertDeterminationType["ConfirmedUserActivity"] = "ConfirmedUserActivity";
    combinedAlertDeterminationType["LineOfBusinessApplication"] = "LineOfBusinessApplication";
})(combinedAlertDeterminationType || (combinedAlertDeterminationType = {}));
export var CombinedAlertDeterminationId = (_a = {},
    _a[combinedAlertDeterminationType.Apt] = '10',
    _a[combinedAlertDeterminationType.Malware] = '20',
    _a[combinedAlertDeterminationType.SecurityPersonnel] = '30',
    _a[combinedAlertDeterminationType.SecurityTesting] = '40',
    _a[combinedAlertDeterminationType.UnwantedSoftware] = '50',
    _a[combinedAlertDeterminationType.Other] = '60',
    _a[combinedAlertDeterminationType.MultiStagedAttack] = '70',
    _a[combinedAlertDeterminationType.CompromisedUser] = '80',
    _a[combinedAlertDeterminationType.Phishing] = '90',
    _a[combinedAlertDeterminationType.MaliciousUserActivity] = '100',
    _a[combinedAlertDeterminationType.Clean] = '110',
    _a[combinedAlertDeterminationType.InsufficientData] = '120',
    _a[combinedAlertDeterminationType.ConfirmedUserActivity] = '130',
    _a[combinedAlertDeterminationType.LineOfBusinessApplication] = '140',
    _a);
export var alertDeterminationValues = [
    // id: 0 -> unset determination
    { id: '10',
        name: 'APT',
        nameI18nKey: 'alertDetermination_apt',
        type: AlertDeterminationType.Apt
    },
    {
        id: '20',
        name: 'Malware',
        nameI18nKey: 'alertDetermination_malware',
        type: AlertDeterminationType.Malware,
    },
    {
        id: '30',
        name: 'Security personnel',
        nameI18nKey: 'alertDetermination_security_personnel',
        type: AlertDeterminationType.SecurityPersonnel,
    },
    {
        id: '40',
        name: 'Security testing',
        nameI18nKey: 'alertDetermination_security_testing',
        type: AlertDeterminationType.SecurityTesting,
    },
    {
        id: '50',
        name: 'Unwanted software',
        nameI18nKey: 'alertDetermination_unwanted_software',
        type: AlertDeterminationType.UnwantedSoftware,
    },
    { id: '60', name: 'Other', nameI18nKey: 'alertDetermination_other', type: AlertDeterminationType.Other },
    {
        id: '60',
        name: 'Other',
        nameI18nKey: 'alertDetermination_other',
        type: AlertDeterminationType.Other,
        combinedClassification: true
    },
    {
        id: '70',
        name: 'Multi staged attack',
        nameI18nKey: 'alertDetermination_MultiStagedAttack',
        type: combinedAlertDeterminationType.MultiStagedAttack,
        combinedClassification: true
    },
    {
        id: '80',
        name: 'Compromised User',
        nameI18nKey: 'alertDetermination_CompromisedUser',
        type: combinedAlertDeterminationType.CompromisedUser,
        combinedClassification: true
    },
    {
        id: '90',
        name: 'Phishing',
        nameI18nKey: 'alertDetermination_Phishing',
        type: combinedAlertDeterminationType.Phishing,
        combinedClassification: true
    },
    {
        id: '100',
        name: 'Malicious user activity',
        nameI18nKey: 'alertDetermination_MaliciousUserActivity',
        type: combinedAlertDeterminationType.MaliciousUserActivity,
        combinedClassification: true
    },
    {
        id: '110',
        name: 'Clean',
        nameI18nKey: 'alertDetermination_Clean',
        type: combinedAlertDeterminationType.Clean,
        combinedClassification: true
    },
    {
        id: '120',
        name: 'Insufficient data',
        nameI18nKey: 'alertDetermination_InsufficientData',
        type: combinedAlertDeterminationType.InsufficientData,
        combinedClassification: true
    },
    {
        id: '130',
        name: 'Confirmed user activity',
        nameI18nKey: 'alertDetermination_ConfirmedUserActivity',
        type: combinedAlertDeterminationType.ConfirmedUserActivity,
        combinedClassification: true
    },
    {
        id: '140',
        name: 'Line of business application',
        nameI18nKey: 'alertDetermination_LineOfBusinessApplication',
        type: combinedAlertDeterminationType.LineOfBusinessApplication,
        combinedClassification: true
    },
];
