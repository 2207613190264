import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { Paris, Repository } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { Input, ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { MachineGroup } from '@wcd/domain';
import { Router } from '@angular/router';
import { EntityType } from '../../models/entity-type.interface';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { MachineGroupsFieldsService } from '../../../tvm/services/tvm-machine-groups.fields.service';
import { DataViewSelectEvent } from '../../../dataviews/components/dataview.component';
import { remove } from 'lodash-es';
import { MessageBarType } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'machine-group-dataview-panel',
	providers: [MachineGroupsFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine-group-dataview.panel.component.html',
})
export class MachineGroupDataviewPanelComponent extends PanelContainer {
	_messageBarType: MessageBarType;
	_repository: Repository<MachineGroup>;
	_entityType: EntityType<MachineGroup>;
	_dataViewConfig: DataViewConfig;
	_fields: Array<DataviewField<MachineGroup>>;
	selectedMachineGroups: Array<MachineGroup>;
	currentlyVisibleMachineGroups: Array<MachineGroup>;
	showSelectedGroupsMessage: boolean;
	selectedGroupsInfoText: string;
	manuallySelectedGroupsInfoText: string;
	isBeforeOnInit: boolean = true;

	selectById: (machineGroup: MachineGroup) => boolean;
	@Input() preselectedMachineGroups: number[] = [];
	@Input() dataviewTitle: string;
	@Input() manuallySelectedMachineGroups: number[] = [];
	@Input() hideMachineGroups: number[] = [];
	@Input() containsFilteredGroups: boolean = false;
	@Output() onSaveGroups: EventEmitter<any> = new EventEmitter<any>();

	ngOnInit() {
		super.ngOnInit();
		// TODO: (jomok) figure out a way to pre select existing rbac groups.
		//       This table (according to PMs) should only show selected RBACs. So when a user selects 6 groups in the tvm global filter,
		//		 there should only be 5 items in this table (with all of them selected). Probably a combination of ViewChild on the dataview, and onDataFetched
		// Note[omcheri]: currently "preselectedMachineGroups" will be removed from the list (instead of being selected and disabled)

		// Also, if user navigates in and out of this component, we need to remember the options they have selected previously
		// First convert array to number array and then select them as appropriate
		if (this.manuallySelectedMachineGroups && this.manuallySelectedMachineGroups.length > 0) {
			this.selectById = group => this.manuallySelectedMachineGroups.includes(group.id);
		} else if (this.isBeforeOnInit) {
			// We only want to select all when the panel first opens, and not on every subsequent search.
			this.selectById = () => true;
			this.isBeforeOnInit = false;
		}

		this.manuallySelectedGroupsInfoText = this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
			number: this.manuallySelectedMachineGroups  ? this.manuallySelectedMachineGroups.length : 0
		});
	}

	constructor(
		router: Router,
		paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		machineGroupsFieldsService: MachineGroupsFieldsService,
		private i18nService: I18nService
	) {
		super(router);
		this._repository = paris.getRepository(MachineGroup);
		this._entityType = globalEntityTypesService.getEntityType(MachineGroup);
		this._dataViewConfig = {
			showModalOnExport: false,
			allowFilters: false,
			id: 'machine-groups-dataview',
			defaultSortFieldId: 'name'
		};
		this._fields = machineGroupsFieldsService.fields;
	}

	selectedMachineGroup(machineGroupEvent: DataViewSelectEvent<MachineGroup>) {
		// this function will be called when a new search term is entered. Need to be a bit clever here.
		// If we get an empty array in machineGroupEvent.items, it is a new search term
		if (machineGroupEvent.items) {
			// If after searching and the currently selected items do not exist in the items that are currently being shown, don't remove them from the selected list!
			// First see what was selected, and see if it is in the currently visible items in the table
			// If an item was previously selected from before, then we do not want to overwrite the 'selectedMachineGroups'
			const distinctMachineGroups = this.selectedMachineGroups ? this.selectedMachineGroups.filter(group => !this.currentlyVisibleMachineGroups.includes(group)) : [];
			this.selectedMachineGroups = machineGroupEvent.items.concat(distinctMachineGroups);
		}

		this.manuallySelectedGroupsInfoText = this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
			number: this.selectedMachineGroups  ? this.selectedMachineGroups.length : 0
		});
	}

	applyMachineGroups() {
		this.onSaveGroups.emit(this.selectedMachineGroups);
	}

	onData(data: MachineGroup[]) {
		if (this.preselectedMachineGroups && this.preselectedMachineGroups.length > 0) {
			// If in this condition, that means that user pre-selected some groups. Show how many groups are being filtered
			this.showSelectedGroupsMessage = true;
			this.selectedGroupsInfoText = this.getSelectedGroupsInfoText(
				this.preselectedMachineGroups.length,
				data.length
			);

			const preselectedMachineGroupsSet = new Set(this.preselectedMachineGroups);
			remove(data, rbacGroup => !preselectedMachineGroupsSet.has(Number(rbacGroup.id)));
		}

		// If the user has requested to hide some groups (ex. due to exceptions), filter them out here
		const hideMachineGroups = new Set(this.hideMachineGroups);
		remove(data, rbacGroup => hideMachineGroups.has(Number(rbacGroup.id)));

		if (this.selectedMachineGroups) {
			this.selectById = group => this.selectedMachineGroups.includes(group);
		} else if (!this.isBeforeOnInit) {
			this.selectById = () => true;
		}

		this.manuallySelectedGroupsInfoText = this.getNumberOfDevicesInfoText(this.selectedMachineGroups);
		this.currentlyVisibleMachineGroups = data;
	}

	getNumberOfDevicesInfoText(machineGroups: MachineGroup[]) {
		return this.i18nService.get('tvm.common.machineGroupFilter.dataview.numberOfMachineGroupsSelectedManually', {
			number: machineGroups ? machineGroups.length : 0
		});
	}

	getSelectedGroupsInfoText(numOfGroupsFilteredByUser: number, numOfGroupsFilteredByUserTotal: number) {
		if (this.containsFilteredGroups && this.hideMachineGroups.length > 0) {
			this._messageBarType = MessageBarType.warning;
			return this.i18nService.get('tvm.common.machineGroupFilter.dataview.filterAndExceptionsDeviceGroupsInfoText', {
				numerator: numOfGroupsFilteredByUser,
				denominator: numOfGroupsFilteredByUserTotal,
			});
		}
		else if (this.containsFilteredGroups) {
			this._messageBarType = MessageBarType.info;
			return this.i18nService.get('tvm.common.machineGroupFilter.dataview.selectedGroupsInfoText', {
				numerator: numOfGroupsFilteredByUser,
				denominator: numOfGroupsFilteredByUserTotal,
			});
		}
		else {
			this._messageBarType = MessageBarType.warning;
			return this.i18nService.get('tvm.common.machineGroupFilter.dataview.exceptionsDeviceGroupsInfoText', {
				numerator: numOfGroupsFilteredByUser,
				denominator: numOfGroupsFilteredByUserTotal,
			});
		}
	}

	closeMachineGroups() {
		this.destroy();
	}

	shouldDisableSubmit() {
		// Need to check both since user may have some selected and de-selects them all. Can also force var to have default empty, both valid
		return this.selectedMachineGroups && this.selectedMachineGroups.length < 1;
	}
}
