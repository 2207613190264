var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, Type } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { AccessTokenModificationDetails, AntivirusBlockDetails, CommandLine, Email, File, FileActionDetails, FileInstance, LegacyUser, NetworkEndpoint, Process, ProcessActionDetails, RegistryModificationDetails, ScheduledTask, UsbEvent, } from '@wcd/domain';
var supportedEntityTypes = {
    accessToken: AccessTokenModificationDetails,
    commandLine: CommandLine,
    file: File,
    fileInstance: FileInstance,
    networkEndpoint: NetworkEndpoint,
    process: Process,
    registryModification: RegistryModificationDetails,
    scheduledTask: ScheduledTask,
    user: LegacyUser,
    email: Email,
    antivirusBlock: AntivirusBlockDetails,
    fileAction: FileActionDetails,
    processAction: ProcessActionDetails,
    usbEvent: UsbEvent,
};
var GlobalEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(GlobalEntityDetailsComponent, _super);
    function GlobalEntityDetailsComponent(injector) {
        var _this = _super.call(this, injector) || this;
        _this.supportedEntityTypes = supportedEntityTypes;
        return _this;
    }
    return GlobalEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { GlobalEntityDetailsComponent };
