import { ModelBase, EntityField, ValueObject } from '@microsoft/paris';
import { Process } from './process.entity';

@ValueObject({
	singularName: 'Process action details',
	pluralName: 'Process action details',
})
export class ProcessActionDetails extends ModelBase {
	@EntityField({ data: 'ActionTime' })
	actionTime: Date;

	@EntityField({ data: 'ActionType' })
	actionType: string;

	@EntityField({ data: '__self' })
	process: Process;
}
