import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.base', onboardingBaseController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding', {
			url: '/onboarding',
			abstract: true,
			title: 'Onboarding',
			views: {
				main: {
					template: `
						<div class="onboarding-wizard-page">
                <div class="onboarding-wizard-title">
                    <div class="onboarding-wizard-title-top150">
                        Windows
                    </div>
                    <div class="onboarding-wizard-title-top150">
                        Security Center
                    </div>
                    <div class="onboarding-wizard-title-top150">
                        <hr width="84%" align="left">
                    </div>
                    <div class="onboarding-wizard-title-top140">
                        Setup
                    </div>
                </div>
            </div>
            <div class="onboarding-wizard-page">
                <div class="onboarding-steps">
                    <div class="onboarding-step-padding">
                        <div class="row">
                            <div class="col onboarding-step-title">Step 1</div>
                            <div class="col icon onboarding-step-icon" ng-class="{'onboarding-complete-step-icon icon-CompletedSolid':stepPermissionsDone, 'icon-ToggleThumb':stepPermissionsInProgress, 'onboarding-inactive-step-icon icon-ToggleThumb':!stepPermissionsInProgress && !stepPermissionsDone}"></div>
                        </div>
                        <div class="row onboarding-step-details">Set up permissions</div>
                    </div>
                    <div class="onboarding-step-padding">
                        <div class="row">
                            <div class="col onboarding-step-title">Step 2</div>
                            <div class="col icon onboarding-step-icon" ng-class=" {'onboarding-complete-step-icon icon-CompletedSolid':stepWelcomeDone, 'icon-ToggleThumb':stepWelcomeInProgress, 'onboarding-inactive-step-icon icon-ToggleThumb':!stepWelcomeInProgress && !stepWelcomeDone}"></div>
                        </div>
                        <div class="row onboarding-step-details">Get started</div>
                    </div>
                    <div class="onboarding-step-padding">
                        <div class="row">
                            <div class="onboarding-step-title">Step 3</div>
                            <div class="col icon onboarding-step-icon" ng-class="{'onboarding-complete-step-icon icon-CompletedSolid':stepPreferencesDone, 'icon-ToggleThumb':stepPreferencesInProgress, 'onboarding-inactive-step-icon icon-ToggleThumb':!stepPreferencesInProgress && !stepPreferencesDone}"></div>
                        </div>
                        <div class="row onboarding-step-details">Set up preferences</div>
                    </div>
                    <div class="onboarding-step-padding">
                        <div class="row">
                            <div class="onboarding-step-title">Step 4</div>
                            <div class="col icon onboarding-step-icon" ng-class="{'onboarding-complete-step-icon icon-CompletedSolid':stepDownloadDone, 'icon-ToggleThumb':stepDownloadInProgress, 'onboarding-inactive-step-icon icon-ToggleThumb':!stepDownloadInProgress && !stepDownloadDone}"></div>
                        </div>
                        <div class="row onboarding-step-details">Onboard a device</div>
                    </div>
                </div>
                    <div ui-view="onboardingStepView"></div>
            </div>
						`,
					controller: 'seville.onboarding.base',
					controllerAs: 'base',
				},
			},
		});
	},
]);

onboardingBaseController.$inject = ['$scope', '$state'];

function onboardingBaseController($scope, $state) {
	$scope.createTenant = false;
	$scope.$parent.minEndpointsNumber = 0;
	$scope.$parent.maxEndpointsNumber = 1000;
	$scope.$parent.retentionPolicy = '180';
	$scope.$parent.geo = '';
	$scope.$parent.optInPreview = true;
	$scope.$parent.CommunicationsAndMedia = [];
	$scope.$parent.FinancialServices = [];
	$scope.$parent.ManufacturingAndResources = [];
	$scope.$parent.PublicSector = [];
	$scope.$parent.RetailConsumerProductsAndService = [];

	$scope.$parent.TenantOnboardingStatus = {
		// when upgrading this, please use "src/client/app/config/models/app-onboard-status.enum.ts"
		Onboarded: 0,
		Configured: 1,
		Provisioned: 2,
		CustomerTiProvisioned: 3,
		OmsProvisioned: 4,
		Packaged: 5,
		IntoroDataGenerated: 6, // seems like a typo. on backend it's "IntroDataGenerated"
		Downloaded: 7,
		completed: 8,
	};

	$scope.$parent.currentOnboardingState = $scope.$parent.TenantOnboardingStatus.Onboarded;

	if ($scope.$parent.stepPermissionsVisited !== true) {
		$scope.$parent.stepPermissionsVisited = false;
	}

	$scope.resetStepsState = function(callback) {
		$scope.stepPermissionsInProgress = false;
		$scope.stepWelcomeInProgress = false;
		$scope.stepPreferencesInProgress = false;
		$scope.stepProcessingVisited = false;
		$scope.stepDownloadInProgress = false;
		$scope.stepFinishInProgress = false;

		$scope.stepPermissionsDone = false;
		$scope.stepWelcomeDone = false;
		$scope.stepPermissionsDone = false;
		$scope.stepDownloadDone = false;
		$scope.stepFinishDone = false;

		callback();
	};

	$scope.resetStepsState(function() {});
}
