<header class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
	<div class="page-header-icon wcd-flex-none">
		 <wcd-shared-icon class="round-icon color-box-gray-200" iconName="entities.ipaddress"> </wcd-shared-icon >
	</div>
	<div class="wcd-flex-1 wcd-flex-center-vertical">
		<div>
			<h3 class="side-panel-title">{{'machine_ip_panel_title' | i18n }}</h3>
			<div class="page-subtitle">{{'machine_ip_panel_last_seen' | i18n}} {{machine.lastSeen | date:'short'}}</div>
		</div>
	</div>
</header>

<div *ngIf="isLoading; else finishedLoading" class="loading-overlay">
	<i class="large-loader-icon"></i>
</div>
<ng-template #finishedLoading>
	<div *ngIf="!machineIps?.length" class="text-center">
		<span>{{ (hasError ? 'machine_ip_panel_loading_error': 'machine_ip_panel_no_results') | i18n }}</span>
	</div>
</ng-template>
<div class="wcd-padding-all">
	<table class="wcd-table table-grouped no-group-indent wcd-full-width clickable-rows">
		<tbody *ngFor="let ip of machineIps">
		<tr class="table-group-header" (click)="toggleIp(ip)" tabindex="0" (keydown.enter)="toggleIp(ip)" role="button"
			[attr.aria-expanded]="expandedIps.has(ip)">
			<td [wcdTooltip]="ip.address" class="full-width-cell wcd-font-size-m">
				{{ ip.address }}
			</td>
			<td [wcdTooltip]="'machines.ips.status.' + ip.adapter.status + '.tooltip' | i18n" class="small-cell nowrap">
				 <wcd-shared-icon *ngIf="ip.adapter.status === 'Up'; else offIcon"
					  iconName="success"
					  class="nudge-up color-text-success-light"> </wcd-shared-icon >
				<ng-template #offIcon>
					 <wcd-shared-icon iconName="errorFull" class="nudge-up color-text-gray-125"> </wcd-shared-icon >
				</ng-template>
				<span>{{ 'machines.ips.status.' + ip.adapter.status + '.name' | i18n}}</span>
			</td>
			<td class="small-cell">
				 <wcd-shared-icon [iconName]="(expandedIps.has(ip) ? 'carets.up' : 'carets.down')"> </wcd-shared-icon >
			</td>
		</tr>
		<tr *ngIf="expandedIps.has(ip)" (dblclick)="toggleIp(ip)">
			<td colspan="3">
				<ip-with-adapter-entity-details [entity]="ip" [showIpAddress]="false" [showPhysicalAddressNotAvailableTooltip]="isMacSupportedForMachine === false"></ip-with-adapter-entity-details>
			</td>
		</tr>
		</tbody>
	</table>
</div>
