import { SevilleModule } from '../../seville/seville.module';

declare let angular: angular.IAngularStatic;

SevilleModule.factory('machineClassificationService', machineClassificationService);

machineClassificationService.$inject = ['hoverPaneService', 'urlMapping', '$q', '$http'];

function machineClassificationService(hoverPaneService, urlMapping, $q, $http) {
	var service = {
		getMachineGroups: function() {
			return $q(function(resolve, reject) {
				$http.get(urlMapping.getThreatIntelUrl() + '/AllMachinesTags', {}).then(
					function(response) {
						if (response.status === 200) {
							return resolve(response.data);
						} else {
							return reject(null);
						}
					},
					function(err) {
						reject(err);
					}
				);
			});
		},

		getTagsForMachine: function(id) {
			return $q(function(resolve, reject) {
				$http
					.get(urlMapping.getThreatIntelUrl() + '/MachineTags', {
						params: {
							internalMachineId: id,
						},
					})
					.then(
						function(response) {
							if (response.status === 200) {
								return resolve(response.data);
							} else {
								return reject(null);
							}
						},
						function(err) {
							reject(err);
						}
					);
			});
		},

		openMachineClassficationHoverPane: function(params) {
			hoverPaneService.open({
				template: `
				<div data-track-component="TagsPane">
            <div class="machine-tags-title-header overflow overflow-all ellipsis" tooltip-show-on-overflow-only tooltips tooltip-title="{{machineclassification.machine.computerDnsName | parts: 1}}">
                <i class="icon icon-DeviceLaptopNoPic"></i>
                {{machineclassification.machine.computerDnsName | parts: 1}}
            </div>
          <div ng-if="machineclassification.loading" class="text-center machine-tags-loading">
            <img src="/assets/images/circle-loading.gif" />
          </div>
            <div ng-if="!machineclassification.loading" class="machine-tags-header">
                <table class="machine-tags-table">
                    <tr>
                        <td class="machine-tags-title-cell">
                            <span class="machine-tags-title">Tags</span>
                            <i class="icon icon-Info suppression-info-icon" tooltips tooltip-size="medium" tooltip-html="<span>Tags to determine device classification</span>" tooltip-side="right"></i>
                        </td>
                        <td class="machine-tags-clear" rbacjs state="'disabled'" permissions="['alertsInvestigation']" >
                            <div ng-click="machineclassification.clearAll()" data-track-id="clearTags" data-track-type="Button" class="pointer">
                                <i class="icon icon-Cancel"></i>
                                <span>Clear all</span>
                            </div>
                        </td>
                    </tr>
                </table>


            </div>
            <div ng-if="!machineclassification.isTagsInputExposed && !machineclassification.loading" class="tags-cloud-tag add-machine-tag pointer"
				 rbacjs state="'disabled'" permissions="['alertsInvestigation']"
				 ng-click="machineclassification.focusInput()"
            	 data-track-id="addTag"
				 data-track-type="Button"
            	 tabindex="0">
                <span class="tags-cloud-tag-value machine-tag-text">Add tag</span>
                <i class="icon icon-Add"></i>
            </div>
            <div ng-if="machineclassification.isTagsInputExposed && !machineclassification.loading && machineclassification.alertsInvestigationAllowed"
				ng-init="machineclassification.focusInput()"
				class="add-machine-tags-container"
				data-track-id="MachineAddTag"
				data-track-type="Button"
				>
                <input class="add-machine-tags-input"
                       type="text"
                       ng-model="machineclassification.tag"
                       typeahead-show-hint="false"
                       typeahead-min-length="0"
					   ng-blur="machineclassification.addTag()"
                       ng-keydown="machineclassification.handleSearchKeyPress($event)"
                       id="tags-input">
			</div>
            <div ng-if="!machineclassification.loading && (machineclassification.tags.length > 0 || machineclassification.builtInTags)">
                <machine-tags tags="machineclassification.tags" read-only-tags="machineclassification.builtInTags" can-remove-tags="true" on-tag-removed="machineclassification.removeTag"></machine-tags>
            </div>
            <div class="machine-tags-button">
                <button class="pointer suppressed-alert-save-button"
						rbacjs state="'disabled'" permissions="['alertsInvestigation']"
						ng-click="machineclassification.saveTags()"
                		data-track-id="saveTags"
                		data-track-type="Button"
                		ng-class="{'machine-tags-button-disabled': !machineclassification.changesExist}">
                    <span>
                        Save and close
                    </span>
                </button>
                <button class="pointer suppressed-alert-close-button"
						data-track-id="cancelTags"
						data-track-type="Button"
                		ng-click="machineclassification.dismiss()">Cancel</button>
            </div>
        </div>
				`,
				controller: 'seville.threatintel.machine.classification',
				controllerAs: 'machineclassification',
				title: 'Manage tags',
				icon: 'Tag',
				size: 'xl',
				resolve: {
					params: function() {
						return params;
					},
				},
			});
		},

		editMachineTags: function(internalId, machineTags) {
			return $q(function(resolve, reject) {
				if (!internalId) return reject(null);

				if (machineTags == null) {
					machineTags = [];
				}

				var tags = angular.toJson(machineTags);
				const internalMachineIds = [internalId];
				$http
					.post(urlMapping.getThreatIntelUrl() + '/EditMachineTags', {
						InternalMachineIds: internalMachineIds,
						MachineTags: tags,
					})
					.then(
						function(response) {
							if (response.status === 200) {
								return resolve({ success: true });
							} else {
								return reject(null);
							}
						},
						function(err) {
							reject(err);
						}
					);
			});
		},
	};

	return service;
}
