import * as React from 'react';
import { FC, useMemo, useEffect } from 'react';
import { ReactTabConfig } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { createHtmlPortalNode, InPortal, OutPortal } from "react-reverse-portal";

/**
 *
 * A react component to render children without mounting into the DOM.
 * It works using React.Portals (https://reactjs.org/docs/portals.html) and react-reverse-portal lib (https://github.com/httptoolkit/react-reverse-portal).
 */
export const ReactPortalWrapper = (props: ReactTabConfig) => {
	// Creates a placeholder portal
	const portalNode = useMemo(
		() =>
		  	createHtmlPortalNode<FC<{}>>({
				attributes: {
					style: "height: 100%;"
				},
		  	}),
		[]
	);

	useEffect(() => {
		window.mdatp.reactTabPortalNode = props.componentName ? <OutPortal<FC<{}>> node={portalNode} /> : null;
	}, [portalNode]);

	// Renders the portal node: "InPortal" holds original rendered element, and "OutPortal" controls where it's attached to the DOM.
	return (
		<InPortal node={portalNode}>
			{sccHostService.ui.render(props.componentName, props)}
		</InPortal>
	);
}
