import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ErrorsDialogService } from './services/errors-dialog.service';
import { SnackBarsService } from './snackbars/services/snackbars.service';
import { SnackBarsComponent } from './snackbars/components/snackbars.component';
import { WcdIconsModule } from '@wcd/icons';
import { AngularExtensionsModule } from '@wcd/angular-extensions';

@NgModule({
	imports: [CommonModule, WcdIconsModule, AngularExtensionsModule],
	declarations: [SnackBarsComponent],
	exports: [SnackBarsComponent],
	providers: [ErrorsDialogService, SnackBarsService],
	entryComponents: [SnackBarsComponent],
})
export class BasicDialogsModule {}
