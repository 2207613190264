// TODO(khaw): Remove (if not used for the new component) as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'

import { Component } from '@angular/core';
import { NetworkDevice, NetworkDevicesConnectionState } from '@wcd/domain';
import { EntityStatusComponentBase } from '../../../../global_entities/components/entity-status/entity-status.component.base';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { I18nService } from '@wcd/i18n';

@Component({
    template: `
        <div class="wcd-flex-horizontal wcd-padding-small-top wcd-flex-horizontal-align-items-center">
            <wcd-shared-icon *ngIf=shouldShowStatusIcon(networkDevice)
                iconName="StatusCircleInner"
                [ngClass]="getStatusColorClass(networkDevice)"
                class="wcd-margin-xsmall-right">
            </wcd-shared-icon>
            <span class="wcd-font-weight-semibold wcd-margin-large-right">
                {{ getStatusText(networkDevice) }}
            </span>

            <ng-container *ngIf="networkDevice.exposureLevel">
                <span class="wcd-padding-small-right" [wcdTooltip]="'tvm_network_devices_exposure_level' | i18n">
                    {{ 'tvm_network_devices_exposure_level' | i18n }}
                </span>
                <wcd-shared-icon
                    iconName="warning"
                    [ngClass]=getWeaknessColorClass(networkDevice)
                    class="wcd-font-weight-semibold wcd-margin-xsmall-right">
                </wcd-shared-icon>
                <span class="wcd-font-weight-semibold">
                    {{ networkDevice.exposureLevel }}
                </span>
            </ng-container>
        </div>
	`,
})
export class NetworkDeviceEntityStatusComponent extends EntityStatusComponentBase<NetworkDevice> {
    get networkDevice() {
        return this.entity;
    }

    constructor(
        private tvmWeaknessSeverityService: TvmWeaknessSeverityService,
        private tvmColorService: TvmColorService,
        private i18nService: I18nService,
    ) {
        super();
    }

    getWeaknessColorClass(networkDevice: NetworkDevice): string {
        return this.tvmWeaknessSeverityService.getWeaknessColorClass(networkDevice.exposureLevel);
    }

    getStatusColorClass(networkDevice: NetworkDevice): string {
        if (networkDevice.status !== NetworkDevicesConnectionState.Active) {
            return `ms-fontColor-${this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name}`;
        }
        return "";
    }

    getStatusText(networkDevice: NetworkDevice): string {
        if (networkDevice.status === NetworkDevicesConnectionState.Active) {
            return this.i18nService.get('tvm_network_devices_active_status');
        }
        return this.i18nService.get('tvm_network_devices_inactive_status');
    }

    shouldShowStatusIcon(networkDevice: NetworkDevice): boolean {
        return networkDevice.status !== NetworkDevicesConnectionState.Active;
    }
}