
		<ng-container *ngIf="statistics">
			<ng-container *ngIf="!summaryView; else summary">
				<div class="wcd-margin-small-bottom wcd-m365-typography">
					<div class="wcd-flex-horizontal">
						<div [ngClass]="{ 'wcd-flex-2': isLoadingOrganizationPrevalence }">
							<fab-shimmer
								[isDataLoaded]="!isLoadingOrganizationPrevalence"
								[styles]="{
									shimmerWrapper: { height: 28 }
								}"
							>
								<h4 class="wcd-margin-none-bottom">
									{{
										'file.prevalence.machinesOrganization'
											| i18n
												: {
														organizationPrevalence: transformToPrettyNumber(
															statistics.organizationPrevalence
														)
												  }
									}}
								</h4>
							</fab-shimmer>
						</div>
						<div class="range-selector wcd-flex-1 wcd-margin-xsmall-left">
							<fab-default-button
								[disabled]="isLoadingOrganizationPrevalence"
								[text]="getDateRangeContextItem(timeRange).text"
								[menuProps]="dateRangeMenuOptions"
								[styles]="{
									root: {
										backgroundColor: 'inherit',
										height: 28,
										paddingLeft: 4,
										paddingRight: 4
									},
									menuIcon: {
										height: 11
									}
								}"
							>
							</fab-default-button>
						</div>
					</div>
					<fab-shimmer
						[isDataLoaded]="!isLoadingOrganizationPrevalence"
						[styles]="secondaryTextShimmerStyle"
					>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="statistics.organizationPrevalence > 0">
								<ng-container
									*ngTemplateOutlet="
										genericFirstSeen;
										context: { ago: statistics.organizationFirstSeen }
									"
								>
								</ng-container>
								|
								<ng-container
									*ngTemplateOutlet="
										genericLastSeen;
										context: { ago: statistics.organizationLastSeen }
									"
								>
								</ng-container>
							</ng-container>
						</div>
					</fab-shimmer>
				</div>
				<ng-container *ngIf="statistics?.worldwidePrevalence != null">
					<div class="wcd-margin-small-bottom">
						<h4 class="wcd-margin-xsmall-bottom">
							{{
								'file.prevalence.machinesWorldwide'
									| i18n
										: {
												worldwidePrevalence: transformToPrettyNumber(
													statistics.worldwidePrevalence
												)
										  }
							}}
						</h4>
						<div
							*ngIf="statistics.worldwidePrevalence > 0"
							class="wcd-secondary-text wcd-margin-xsmall-top"
						>
							<ng-container
								*ngTemplateOutlet="
									genericFirstSeen;
									context: { ago: statistics.worldwideFirstSeen }
								"
							>
							</ng-container>
							|
							<ng-container
								*ngTemplateOutlet="
									genericLastSeen;
									context: { ago: statistics.worldwideLastSeen }
								"
							>
							</ng-container>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-template #summary>
				<div class="wcd-flex-horizontal" style="justify-content: space-evenly">
					<ng-container *ngIf="withOrgPrevalence">
						<ng-container
							*ngTemplateOutlet="
								prevalenceData;
								context: {
									prevalence: statistics?.organizationPrevalence,
									title: 'file.prevalence.machinesOrganizationSummary' | i18n,
									firstSeen: statistics?.organizationFirstSeen,
									lastSeen: statistics?.organizationLastSeen
								}
							"
						></ng-container>
					</ng-container>
					<ng-container
						*ngTemplateOutlet="
							prevalenceData;
							context: {
								prevalence: statistics?.worldwidePrevalence,
								title: 'file.prevalence.machinesWorldwideSummary' | i18n,
								firstSeen: statistics?.worldwideFirstSeen,
								lastSeen: statistics?.worldwideLastSeen
							}
						"
					></ng-container>
				</div>
				<ng-template
					#prevalenceData
					let-prevalence="prevalence"
					let-title="title"
					let-firstSeen="firstSeen"
					let-lastSeen="lastSeen"
				>
					<div *ngIf="prevalence != null">
						<distribution-element
							[title]="title"
							[mainText]="transformToPrettyNumber(prevalence)"
						>
						</distribution-element>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="prevalence > 0">
								<ng-container
									*ngTemplateOutlet="genericFirstSeen; context: { ago: firstSeen }"
								>
								</ng-container>
								<br />
								<ng-container *ngTemplateOutlet="genericLastSeen; context: { ago: lastSeen }">
								</ng-container>
							</ng-container>
						</div>
					</div>
				</ng-template>
			</ng-template>
		</ng-container>
		<ng-template #genericFirstSeen let-ago="ago">
			{{ 'files.fields.firstSeen.title' | i18n }}:
			{{ ago | timeAgo }}
		</ng-template>
		<ng-template #genericLastSeen let-ago="ago">
			{{ 'files.fields.lastSeen.title' | i18n }}:
			{{ ago | timeAgo }}
		</ng-template>
	