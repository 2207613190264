import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityReport } from './airs-entity-report.value-object';
import { SystemExclusionListType } from '../system_exclusions/system-exclusion-list-type.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';

@ValueObject({
	singularName: 'Memory Contents',
	pluralName: 'Memory Contents',
	readonly: true,
})
export class MemoryContents extends ModelBase {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() id: number;

	@EntityField() address: number;

	@EntityField() size: number;

	@EntityField() detector: string;

	@EntityField() report: AirsEntityReport;

	@EntityField({ data: 'acl_type' })
	systemExclusionRuleListType: SystemExclusionListType;

	@EntityField({ data: 'allow_create_acl', defaultValue: true })
	allowCreateSystemExclusion: boolean;

	get status(): AirsEntityStatus {
		return this.report && this.report.status;
	}

	get addressHex(): string {
		return this.address ? '0x' + this.address.toString(16) : '';
	}
}
