
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{
					disabled: isSaving || !selectedScanType || !commentText
				}"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<p>{{ 'machines.entityDetails.actions.runAntivirusScan.dialog.content' | i18n }}</p>

				<fab-choice-group
					[label]="
						('machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.label'
							| i18n) + ':'
					"
					[required]="true"
					[options]="scanTypesOptions"
					(onChange)="onScanTypeChanged($event)"
				>
				</fab-choice-group>

				<label class="wcd-required"
					>{{ 'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n }}:</label
				>
				<textarea
					[attr.aria-label]="
						'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n
					"
					required
					rows="4"
					[placeholder]="
						'machines.entityDetails.actions.$general.dialog.form.comment.placeholder' | i18n
					"
					class="form-control dialog-textarea wcd-full-width wcd-no-resize-horizontal"
					[(ngModel)]="commentText"
				></textarea>
			</confirm-modal-content>
		</modal>
	