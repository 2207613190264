var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, EventEmitter, } from '@angular/core';
import { AssignedUsersApiCall, } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import * as _ from 'lodash-es';
import { Paris } from '@microsoft/paris';
import { PreferencesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
var PREFERRED_ASSIGNEES = 'userPreferredAssignees';
var USER_PREFERRED_ASSIGNEES_LIMIT = 10;
var AssigneePickerComponent = /** @class */ (function () {
    function AssigneePickerComponent(paris, changeDetectorRef, preferencesService, i18nService, authService) {
        var _this = this;
        this.paris = paris;
        this.changeDetectorRef = changeDetectorRef;
        this.preferencesService = preferencesService;
        this.i18nService = i18nService;
        this.authService = authService;
        this.onSelectAssignee = new EventEmitter();
        this.isDisabled = false;
        this.selectedAssignees = [];
        this.assignInputProps = { placeholder: this.i18nService.get('assigned_unassigned') };
        this.assigneesSuggestionsOptions = { noResultsFoundText: this.i18nService.get('error_page_no_result_found_title'), loadingText: this.i18nService.get('loading') };
        this.userPreferredList = [];
        this.isLoading = true;
        this.currentUserName = this.authService.currentUser.username;
        this.buttonStyles = { root: { height: 'auto' }, icon: { 'font-size': '10px' } };
        this.iconProps = { iconName: 'Clear' };
        this.peoplePickerResolveDelay = 200;
        this.SpinnerSize = SpinnerSize;
        this.onInputFocus = function () { return _this.initialList; };
    }
    Object.defineProperty(AssigneePickerComponent.prototype, "assignedTo", {
        set: function (value) {
            if (value && value.secondaryText) {
                if (this.getSelectedAssignees().secondaryText === value.secondaryText) {
                    this._assignedTo = this.getSelectedAssignees();
                }
                else {
                    this._assignedTo = value;
                }
                if (value && value.secondaryText === 'multipleAssignees') {
                    this._assignedTo.secondaryText = this.i18nService.get('incident_commandBar_manageIncident_multipleAssignees');
                }
            }
            this.isLoading = false;
        },
        enumerable: true,
        configurable: true
    });
    AssigneePickerComponent.prototype.ngOnInit = function () {
        this.userPreferredList = this.preferencesService.getPreference('preferredAssignees') || [];
        this.setAssignedTo();
        this.initialList = this.getInitialList();
    };
    AssigneePickerComponent.prototype.ngOnChanges = function () {
        this.setAssignedTo();
        this.assignInputProps = this.selectedAssignees.length ?
            { placeholder: '' } : this.assignInputProps = { placeholder: this.isLoading ? this.i18nService.get('common_loading') : this.i18nService.get('assigned_unassigned') };
    };
    AssigneePickerComponent.prototype.getInitialList = function () {
        var _this = this;
        var initialList = this.userPreferredList;
        var currentUser = {
            text: this.i18nService.get('incident_commandBar_manageIncident_assignTo_me'),
            secondaryText: this.currentUserName,
        };
        var currentUserIndex = this.userPreferredList.findIndex(function (item) { return item.secondaryText === currentUser.secondaryText; });
        this.userPreferredList.splice(currentUserIndex, 1);
        this.userPreferredList = [currentUser].concat(initialList);
        return this.paris.apiCall(AssignedUsersApiCall, '').toPromise().then(function (data) {
            initialList = _.unionBy(_this.userPreferredList, data, 'secondaryText');
            return initialList;
        });
    };
    AssigneePickerComponent.prototype.setAssignedTo = function () {
        var _this = this;
        if (!this._assignedTo)
            return;
        this._assignedTo = this.userPreferredList.find(function (user) { return user.secondaryText === (_this._assignedTo && _this._assignedTo.secondaryText); }) || this._assignedTo;
        if (!this._assignedTo.text) {
            this._assignedTo.text = this._assignedTo.secondaryText;
        }
        this.selectedAssignees = [this._assignedTo];
        this.assignInputProps = { placeholder: '' };
        if (this._assignedTo.secondaryText === 'multipleAssignees') {
            this._assignedTo.secondaryText = this.i18nService.get('incident_commandBar_manageIncident_multipleAssignees');
        }
    };
    AssigneePickerComponent.prototype.getUsersToAssign = function (searchTerm) {
        return __awaiter(this, void 0, void 0, function () {
            var searchResultList, filteredInitialList, mergedLists;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.paris.apiCall(AssignedUsersApiCall, searchTerm).toPromise()];
                    case 1:
                        searchResultList = _a.sent();
                        filteredInitialList = this.filterPreferredList(searchTerm);
                        mergedLists = filteredInitialList.concat(searchResultList);
                        return [2 /*return*/, _.unionBy(filteredInitialList, mergedLists, 'secondaryText')];
                }
            });
        });
    };
    AssigneePickerComponent.prototype.filterPreferredList = function (searchTerm) {
        if (!searchTerm)
            return [];
        return this.userPreferredList.filter(function (item) { return item && item.text && item.text.toLowerCase().startsWith(searchTerm.toLowerCase()); });
    };
    AssigneePickerComponent.prototype.peoplePickerInputChanged = function (searchTerm) {
        return this.getUsersToAssign(searchTerm);
    };
    AssigneePickerComponent.prototype.updateSelectedAssignees = function (items) {
        this.selectedAssignees = items.items;
        this.assignInputProps = this.selectedAssignees.length ?
            { placeholder: '' } : this.assignInputProps = { placeholder: this.isLoading ? 'Loading...' : this.i18nService.get('assigned_unassigned') };
        this.changeDetectorRef.markForCheck();
        this.onSelectAssignee.emit(this.getSelectedAssignees());
    };
    AssigneePickerComponent.prototype.getSelectedAssignees = function () {
        return this.selectedAssignees[0] || { secondaryText: '' };
    };
    AssigneePickerComponent.prototype.clearSelectedAssignees = function () {
        this._assignedTo = null;
        this.updateSelectedAssignees({ items: [] });
    };
    AssigneePickerComponent.updateUserPreferredAssignees = function (assignee, preferencesService) {
        if (!assignee || !assignee.secondaryText)
            return;
        var userPreferredAssignees = preferencesService.getPreference(PREFERRED_ASSIGNEES);
        if (userPreferredAssignees && Array.isArray(userPreferredAssignees)) {
            userPreferredAssignees = userPreferredAssignees.filter(function (item) { return item.secondaryText !== assignee.secondaryText; });
            userPreferredAssignees.unshift(assignee);
            userPreferredAssignees.length > USER_PREFERRED_ASSIGNEES_LIMIT && userPreferredAssignees.splice(-1, 1);
        }
        else {
            userPreferredAssignees = [assignee];
        }
        preferencesService.setPreference('preferredAssignees', userPreferredAssignees);
    };
    return AssigneePickerComponent;
}());
export { AssigneePickerComponent };
