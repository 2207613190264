import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { HttpClient } from '@angular/common/http';
import { DownloadService } from '../../../shared/services/download.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { getUserTimeZone } from '@wcd/localization';

const NOTIFICATION_RULES_REPORTS_API_ENDPOINT = '/notifications/report';

@Injectable()
export class AirsNotificationRulesReportsBackendService extends StoreBackendBase {
	protected getAllResultsParams: { [index: string]: any } = {
		time_offset: getUserTimeZone(),
	};

	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + NOTIFICATION_RULES_REPORTS_API_ENDPOINT;
	}
}
