import { merge } from 'lodash-es';
var HIGH_PRIORITY = 3;
var Notification = /** @class */ (function () {
    function Notification(config) {
        var _this = this;
        this.priority = 10;
        this.actionsDisabled = false;
        this._isMinimized = false;
        this._isNew = true;
        merge(this, config);
        this.timestamp = new Date();
        setTimeout(function () { return (_this._isNew = false); }, 5000);
    }
    Object.defineProperty(Notification.prototype, "isSelectable", {
        get: function () {
            return !!this.link;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Notification.prototype, "isNew", {
        get: function () {
            return this._isNew;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Notification.prototype, "isHighPriority", {
        get: function () {
            return this.priority <= HIGH_PRIORITY;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Notification.prototype, "isMinimized", {
        get: function () {
            return this._isMinimized;
        },
        enumerable: true,
        configurable: true
    });
    Notification.prototype.clone = function () {
        return new Notification(this);
    };
    Notification.prototype.minimize = function () {
        this._isMinimized = true;
    };
    return Notification;
}());
export { Notification };
