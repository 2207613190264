import { EntityField } from '@microsoft/paris';
import { Entity } from '@microsoft/paris';
import { EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Notification Rule Condition Type Item',
	pluralName: 'Notification Rule Condition Type Items',
	values: [{ id: 1, name: 'Alert severity', typeName: 'alertSeverity' }],
})
export class NotificationRuleConditionType extends EntityModelBase<number> {
	@EntityField({ data: 'RuleType' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;
	@EntityField() name: string;
	@EntityField() typeName: string;
}
