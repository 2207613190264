import { SevilleModule } from '../seville.module';

declare const moment: typeof import('moment');

SevilleModule.factory('localeService', localeService);

localeService.$inject = ['$log'];

function localeService($log) {
	var m = moment('2010-10-29T07:26:54Z');
	var localizedDate = m
		.toDate()
		.toLocaleDateString()
		.replace(/\D/g, '');
	var locale = 'MDY';
	if (localizedDate == '29102010' || localizedDate == '291010') {
		locale = 'DMY';
	} else if (localizedDate == '20101029' || localizedDate == '101029') {
		locale = 'YMD';
	}

	$log.debug('user locale is ' + locale);

	var service = {
		getDateFormatInUserLocale: function() {
			return locale;
		},

		getSpecificDateFormat: function(yearPattern, monthPattern, dayPattern, delimiter) {
			//builds a more specific date format that matches user locale
			var components = [];

			components[locale.indexOf('Y')] = yearPattern;
			components[locale.indexOf('M')] = monthPattern;
			components[locale.indexOf('D')] = dayPattern;
			return components.join(delimiter || '/');
		},
	};

	return service;
}
