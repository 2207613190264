var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as i0 from "@angular/core";
/**
 * **INTERNAL**
 */
var DynamicScriptService = /** @class */ (function () {
    function DynamicScriptService() {
    }
    DynamicScriptService.prototype.loadScripts = function (options) {
        return __awaiter(this, void 0, void 0, function () {
            var uris, _i, uris_1, path;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        uris = options.urls ||
                            options.relativePaths.map(function (relativePath) { return options.baseUrl + "/" + relativePath; });
                        if (!options.loadConcurrently) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all(uris.map(function (uri) { return _this.loadJavascriptDynamically(uri); }))];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                    case 2:
                        _i = 0, uris_1 = uris;
                        _a.label = 3;
                    case 3:
                        if (!(_i < uris_1.length)) return [3 /*break*/, 6];
                        path = uris_1[_i];
                        return [4 /*yield*/, this.loadJavascriptDynamically(path)];
                    case 4:
                        _a.sent();
                        _a.label = 5;
                    case 5:
                        _i++;
                        return [3 /*break*/, 3];
                    case 6: return [2 /*return*/];
                }
            });
        });
    };
    /**
     * Load and return a require.js instance.
     * If one is already loaded - it'll be reused.
     * @param baseUrl the url to load require.js from.
     * @returns Tuple with the require.js instance, and a boolean indicating if this is the first time it was loaded.
     */
    DynamicScriptService.prototype.loadRequireJS = function (url) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!window['require']) return [3 /*break*/, 2];
                        if (!url) {
                            throw new Error("Failed to load require.js. no 'url' provided");
                        }
                        return [4 /*yield*/, this.loadScripts({ urls: [url] })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/, window['require']];
                }
            });
        });
    };
    DynamicScriptService.prototype.loadJavascriptDynamically = function (uri) {
        return new Promise(function (resolve) {
            var scriptEl = document.querySelector("script[src=\"" + uri + "\"]");
            if (!scriptEl) {
                scriptEl = document.createElement('script');
                scriptEl.type = 'text/javascript';
                scriptEl.src = uri;
                document.body.appendChild(scriptEl);
            }
            scriptEl.addEventListener('load', function () { return resolve(); });
        });
    };
    DynamicScriptService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DynamicScriptService_Factory() { return new DynamicScriptService(); }, token: DynamicScriptService, providedIn: "root" });
    return DynamicScriptService;
}());
export { DynamicScriptService };
