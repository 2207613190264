import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { breadcrumbsStateService } from '@wcd/shared';

SevilleModule.controller('seville.threatintel.searchusers', searchusersController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		$stateProvider.state('search.users', {
			url: '/users/:userdomain/:id',
			params: {
				userdomain: {
					value: null,
					squash: true,
				},
			},
			data: { results: null },
			title: 'Search results - Users',
			template: `
				<div ng-if="!searchusers.UpgradeSearchUsers">
					<div class="loading-layer search-loading" ng-if="searchusers.loading">
						<i class="large-loader-icon"></i>
					</div>
					<div class="row seville-page-header user-search-header">
						<div ng-if="!searchusers.loading" class="col-sm-12">
							<div class="row page-header-title">
								Showing {{searchusers.data.length}} results for <b>"{{searchusers.userAccountName}}"</b>
							</div>
						</div>
					</div>
					<div data-track-component="UsersSearchResults"
						ng-class=":: {'entity-toolbox entity-toolbar': searchusers.exportToCsvEnabled, 'row seville-page-header alerts-filters text-right': !searchusers.exportToCsvEnabled}"
						ng-if="!searchusers.loading">
						<div class="dropdown inline" uib-dropdown>
							<span ng-if="::searchusers.exportToCsvEnabled">
								<b>Refine your results by:</b>
								<span class="machines-filter-padding">
									Time:
								</span>
							</span>
							<span ng-if="::!searchusers.exportToCsvEnabled">Filter by: </span>
							<button class="btn btn-link dropdown-toggle alerts-btn" uib-dropdown-toggle type="button" data-toggle="dropdown" aria-expanded="true" ng-disabled="searchfiles.loading">
								<span>
									<span ng-if="searchusers.telemetryLookback == 180">6 months</span>
									<span ng-if="searchusers.telemetryLookback == 1">1 day</span>
									<span ng-if="searchusers.telemetryLookback != 180 && searchusers.telemetryLookback > 1">
										{{searchusers.telemetryLookback}} days
									</span>
								</span>
								<span class="icon icon-sm icon-ChevronDown"></span>
							</button>
							<ul class="dropdown-menu dropdown-menu-shadow dropdown-margin pull-right">
								<li ng-class="{'dropdown-selected' : searchusers.telemetryLookback == '1'}"><a href="#"
									data-track-id="TelemetryLookback1d"
									data-track-type="Selection"
									ng-model="searchusers.telemetryLookback" uib-btn-radio="'1'">1 day</a></li>
								<li ng-class="{'dropdown-selected' : searchusers.telemetryLookback == '3'}"><a href="#"
									data-track-id="TelemetryLookback3d"
									data-track-type="Selection"
									ng-model="searchusers.telemetryLookback" uib-btn-radio="'3'">3 days</a></li>
								<li ng-class="{'dropdown-selected' : searchusers.telemetryLookback == '7'}"><a href="#"
									data-track-id="TelemetryLookback7d"
									data-track-type="Selection"
									ng-model="searchusers.telemetryLookback" uib-btn-radio="'7'">7 days</a></li>
								<li ng-class="{'dropdown-selected' : searchusers.telemetryLookback == '30'}"><a href="#"
									data-track-id="TelemetryLookback30d"
									data-track-type="Selection"
									ng-model="searchusers.telemetryLookback" uib-btn-radio="'30'">30 days</a></li>
								<li ng-class="{'dropdown-selected' : searchusers.telemetryLookback == '180'}"><a href="#"
									data-track-id="TelemetryLookback180d"
									data-track-type="Selection"
									ng-model="searchusers.telemetryLookback" uib-btn-radio="'180'">6 months</a></li>
							</ul>
						</div>
						<button ng-if="::searchusers.exportToCsvEnabled" ng-click="searchusers.exportResultsToCsv()" class="entity-toolbox-button entity-toolbar-button pull-right">
							<i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
						</button>
					</div>

					<div class="row table-header event-timeline-columnsheader" ng-if="searchusers.data && searchusers.data.length>0">
						<div class="col-md-4 search-results-header">Username</div>
						<div class="col-md-4 search-results-header">Last Seen <span class="icon machine-queue-header-icon icon-SortDown"></span></div>
						<div class="col-md-4 search-results-header">Total devices observed on</div>
					</div>

					<user-search-results results="searchusers.bestMatchData" match-title="'Exact Matches'" loading="searchusers.loading"></user-search-results>
					<user-search-results results="searchusers.otherMatchData" match-title="'Other Matches'" loading="searchusers.loading"></user-search-results>
				</div>
				`,
			controller: 'seville.threatintel.searchusers',
			controllerAs: 'searchusers',
		});
	},
]);

searchusersController.$inject = [
	'$log',
	'$stateParams',
	'$state',
	'$http',
	'urlMapping',
	'investigationService',
	'$scope',
	'featuresService',
	'exportToCsvService',
	'appInsights',
	'ng2router',
];

function searchusersController(
	$log,
	$stateParams,
	$state,
	$http,
	urlMapping,
	investigation,
	$scope,
	featuresService,
	exportToCsvService,
	appInsights: AppInsightsService,
	ng2router
) {
	var vm = this;
	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	vm.userAccountName = $stateParams.id;
	vm.userAccountDomainName = $stateParams.userdomain;
	vm.exportToCsvEnabled = featuresService.isEnabled('ExportEntityTimelinesToCsv');
	vm.UpgradeSearchUsers = featuresService.isEnabled('UpgradeSearchUsers');

	$scope.$watch(
		function() {
			return vm.telemetryLookback;
		},
		function() {
			if (vm.loading) {
				return;
			}

			loadUserAccounts();
		}
	);

	vm.telemetryLookback = '30';

	function loadUserAccounts() {
		if (vm.UpgradeSearchUsers) {
			return;
		}

		vm.loading = true;
		var url = urlMapping.getThreatIntelUrl() + '/SearchUserAccountName';
		$http
			.get(url, {
				params: {
					userAccount: vm.userAccountName,
					userAccountDomain: vm.userAccountDomainName,
					lookingBackIndays: vm.telemetryLookback,
				},
			})
			.then(
				function(response) {
					if (response.status == 404) {
						ng2router.navigate(['/errors/UserNotFound'], {
							queryParams: { itemId: $stateParams.id },
						});
						return;
					} else if (response.status == 500) {
						ng2router.navigate(['/errors']);
						return;
					} else if (response.status == 200) {
						if (!response.data || response.data.length == 0) {
							ng2router.navigate(['/errors/UserNotFound'], {
								queryParams: { itemId: $stateParams.id },
							});
						}
						if (response.data.length == 1) {
							ng2router.navigate([`/users/nosid/`], {
								queryParams: {
									accountName: response.data[0].AccountName,
									accountDomainName: response.data[0].AccountDomainName,
								},
							});
						}

						updateInvestigationFlow();
						vm.data = response.data;

						if (vm.data != null) {
							// filter data by the match type - exact match of other match
							vm.bestMatchData = vm.data.filter(function(item) {
								return item.IsExactMatch === true;
							});

							// show oher matches only if there are less than 100 results in the exact match - take the amount of missing results from other match.
							vm.otherMatchData =
								vm.bestMatchData && vm.bestMatchData.length < 100
									? vm.data.filter(function(item) {
											return item.IsExactMatch === false;
									  })
									: [];
						}

						vm.loading = false;
					}
				},
				function(response) {
					ng2router.navigate(['/errors']);
					vm.loading = false;
				}
			);
	}

	vm.exportResultsToCsv = function() {
		if (!vm.exportInProgress) {
			vm.exportInProgress = true;
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExportToCsv',
				Page: 'User',
				Component: 'UserSearchExportToCsv',
			});

			exportToCsvService
				.exportResults({
					params: {
						userAccount: vm.userAccountName,
						userAccountDomain: vm.userAccountDomainName,
						lookingBackInDays: vm.telemetryLookback,
					},
					getAuthTokenUrl: '/GetAuthToken?exportUrl=DownloadUserSearchResults',
					downloadUrl: '/DownloadUserSearchResults',
				})
				.finally(function() {
					vm.exportInProgress = false;
				});
		}
	};

	function updateInvestigationFlow() {
		var data = {
			Id: $stateParams.id,
			icon: 'icon-Search',
			state: 'search.users',
			displaystate: 'Search results',
		};

		investigation.setActive(data);
		breadcrumbsStateService.set({
			id: `searchUsers`,
			label: 'Search results',
			url: $state.href($state.current),
			queryParams: {},
		});
	}
}
