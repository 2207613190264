var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { ItsmTool } from './remediation-task-ticket-itsm-tool.enum';
import { RemediationTicketStateValue } from './remediation-ticket-state-value.enum';
import { RemediationErrorDetails } from './remediation-error-details.value-object';
var RemediationTicket = /** @class */ (function (_super) {
    __extends(RemediationTicket, _super);
    function RemediationTicket() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'itsmTool',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "itsmTool", void 0);
    __decorate([
        EntityField({
            data: 'status',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "status", void 0);
    __decorate([
        EntityField({
            data: 'itNotes',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "itNotes", void 0);
    __decorate([
        EntityField({
            data: 'deepLink',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "deepLink", void 0);
    __decorate([
        EntityField({
            data: 'assignedTo',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "assignedTo", void 0);
    __decorate([
        EntityField({
            data: 'rawStatus',
        }),
        __metadata("design:type", String)
    ], RemediationTicket.prototype, "rawStatus", void 0);
    __decorate([
        EntityField({
            data: 'errorDetails',
        }),
        __metadata("design:type", RemediationErrorDetails)
    ], RemediationTicket.prototype, "ErrorDetails", void 0);
    RemediationTicket = __decorate([
        ValueObject({
            singularName: 'Ticket',
            pluralName: 'Tickets',
        })
    ], RemediationTicket);
    return RemediationTicket;
}(ModelBase));
export { RemediationTicket };
