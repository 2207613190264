import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import { CyberEvent, LegacyUser, Machine, Tag } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { CyberEventsActionTypesService } from '../../../@entities/cyber_events/services/cyber-events-action-types.service';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { isAccountLinkable } from '@wcd/scc-interface';
import { MitreTechniqueObject } from '@wcd/scc-common';

@Component({
	selector: 'cyber-event-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cyber-event.entity-details.component.html',
})
export class CyberEventEntityDetailsComponent extends EntityDetailsComponentBase<CyberEvent> {
	readonly userType = LegacyUser;
	readonly machineType = Machine;

	@Input() showMachineLink: boolean = true;
	@Input() showEventDate: boolean = true;
	@Input() mitreTechniquesObjects?: Array<MitreTechniqueObject>;
	@Input() contextLog?: string;

	private _description: string;
	private _tags: ReadonlyArray<Tag>;
	private _isUserLinkAllowed: boolean;
	private _actionType: string;
	showActionType: boolean = true;
	entityPanelsService: EntityPanelsService;

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService,
		public readonly globalEntityTypesService: GlobalEntityTypesService,
		injector: Injector
	) {
		super(injector);
	}

	ngOnInit() {}

	get actionType(): string {
		if (!this._actionType) this.setActionType();

		return this._actionType;
	}

	get event(): CyberEvent {
		return this.entity;
	}

	get description(): string {
		if (!this._description) this.setEventDescription();

		return this._description;
	}

	get tags(): ReadonlyArray<Tag> {
		if (!this._tags) this.setEventTags();

		return this._tags;
	}

	get isUserLinkAllowed(): boolean {
		if (this._isUserLinkAllowed === undefined) this.setUserDetails();

		return this._isUserLinkAllowed;
	}

	setEntity(entity: CyberEvent) {
		super.setEntity(entity);

		this.showActionType = entity.isUnknownOrNotAlert;
		this.setEventDescription();
		this.setUserDetails();
		this.setEventTags();

		this.changeDetectorRef.markForCheck();
	}

	private setActionType(): void {
		this._actionType = this.cyberEventsActionTypesService.getActionType(this.event);
	}

	private setEventDescription(): void {
		this._description = this.cyberEventsActionTypesService.getEventDescription(this.event);
	}

	private setEventTags(): void {
		this._tags = this.cyberEventsActionTypesService.getEventTags(this.event);
	}

	setUserDetails() {
		this._isUserLinkAllowed = this.event.initiatingUser && isAccountLinkable(this.event.initiatingUser);
	}
}
