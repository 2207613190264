import { sccHostService, dummyPerf, } from '@wcd/scc-interface';
import { OnDestroy } from '@angular/core';
import { merge } from 'lodash-es';
import * as i0 from "@angular/core";
var PerformanceSccService = /** @class */ (function () {
    function PerformanceSccService() {
    }
    // Internal API's for wicd-ng
    PerformanceSccService.prototype.createNgPageLoadPerformanceSession = function (sessionId, options) {
        if (!sccHostService.isSCC) {
            return dummyPerf;
        }
        var defaultOptions = {
            isContainer: true,
        };
        this.clearCurrentSession();
        return this.currentNgPageSession = sccHostService.perf.createPerformanceSession(sessionId, 'ng-custom-page-load', Object.assign({}, defaultOptions, options));
    };
    PerformanceSccService.prototype.endNgPageLoadPerfSession = function (pageName, options) {
        if (!sccHostService.isSCC) {
            return;
        }
        var pageContext = sccHostService.state.current.name + "." + pageName;
        sccHostService.perf.endPerfSession(this.currentNgPageSession, merge({}, {
            removeFromContext: true,
            customProps: { pageContext: pageContext },
        }, options));
        this.clearCurrentSession();
    };
    PerformanceSccService.prototype.clearCurrentSession = function () {
        if (!sccHostService.isSCC || !this.currentNgPageSession) {
            return;
        }
        this.currentNgPageSession.removeFromContext();
        sccHostService.perf.cleanUpFinishedSessionFromCache();
        this.currentNgPageSession = null;
    };
    PerformanceSccService.prototype.ngOnDestroy = function () {
        this.clearCurrentSession();
    };
    PerformanceSccService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function PerformanceSccService_Factory() { return new PerformanceSccService(); }, token: PerformanceSccService, providedIn: "root" });
    return PerformanceSccService;
}());
export { PerformanceSccService };
