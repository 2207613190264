import { EntityField, EntityModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Ip location',
	pluralName: 'Ip location',
	readonly: true,
})
export class IpLocation extends EntityModelBase {
	@EntityField({ data: 'countryCode' })
	countryCode?: string;

	@EntityField({ data: 'state' })
	state?: string;

	@EntityField({ data: 'city' })
	city?: string;

	@EntityField({ data: 'longitude' })
	longitude?: number;

	get ipFullAddress(): string {
		return (
			this.city &&
			this.state &&
			this.countryCode &&
			[this.city, this.state, this.countryCode].join(', ')
		);
	}
}
