import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class Ie11Service {
  isIe11: boolean;

  constructor() {
    this.detectIE();
  }

  private detectIE() {
    const userAgent = window.navigator.userAgent;
    this.isIe11 = userAgent.includes('MSIE') || userAgent.includes('Trident/');
  }

  // Ie-11 does not support dispatch of custom Event, the workaround inject the detail directly into the event
  createIe11CustomEvent = (eventName: string, { detail, bubbles = false, cancelable = false} ): any => {
    const customEvent = document.createEvent('HTMLEvents');
    customEvent.initEvent(eventName, bubbles, cancelable);
    (customEvent as any).detail = detail;
    return customEvent;
  }

}
