import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { EnvironmentName } from '@wcd/domain';

@Injectable()
export class AppConfigValueGuard implements CanActivate {
	constructor(
		private appConfigService: AppConfigService,
		private router: Router,
		private featuresService: FeaturesService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return (
			!route.routeConfig.data ||
			!route.routeConfig.data.appConfigValidator ||
			route.routeConfig.data.appConfigValidator(this.appConfigService) ||
			(route.routeConfig.data.appConfigValidator == IsServiceNowIntegrationEnabled &&
				this.featuresService.isEnabled(Feature.TvmServiceNowIntegration))
		);
	}
}

export const IsLiveResponseEnabled = (appConfig: AppConfigService) => appConfig.isLiveResponseEnabled;
export const IsServiceNowIntegrationEnabled = (appConfig: AppConfigService) =>
	appConfig.IsServiceNowIntegrationEnabled;
export const IsPublicEnvironment = (appConfig: AppConfigService) =>
	[EnvironmentName.Production, EnvironmentName.Staging].includes(appConfig.environmentName);
