
		<span
			*ngIf="localTags?.length; else empty"
			[class.wcd-flex-items-center-horizontal]="orientation === FlexDirection.Horizontal"
		>
			<ul
				class="tags-list"
				[class.multi-line]="multiline"
				[class.inline-block]="orientation === FlexDirection.None"
				[class.wcd-flex-items-start-vertical]="orientation === FlexDirection.Vertical"
			>
				<li *ngFor="let tag of maxItems > 0 ? (localTags | slice: 0:maxItems) : localTags"
					[attr.aria-label]="tag.name || tag.label"
				>
					<tag [tag]="tag" (removeClick)="removeTag($event)" [removable]="editable"></tag>
				</li>
			</ul>
			<span *ngIf="maxItems && localTags.length > maxItems" class="tags-more">
				{{ 'tags.moreTags' | i18n: { count: localTags.length - maxItems } }}
			</span>
		</span>
		<ng-template #empty>
			<div class="subtle" *ngIf="allowEmptyState">
				{{ emptyMessageKey | i18n }}
			</div>
		</ng-template>
	