import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { RecommendationException } from '@wcd/domain';

@Component({
	selector: 'recommendation-exception-entity-panel',
	templateUrl: './recommendation-exception.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecommendationExceptionEntityPanelComponent extends EntityPanelComponentBase<
	RecommendationException
> {
	get recommendationException(): RecommendationException {
		return this.entity;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(recommendationException: RecommendationException, isExtendedData: boolean = false) {
		super.initEntity(recommendationException, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}
}
