import {Injectable} from '@angular/core';
import {DialogsService} from '../../../dialogs/services/dialogs.service';
import {
	Alert,
	AlertBase,
	AlertCategory,
	AlertClassification,
	AlertClassificationId,
	alertClassificationValues,
	AlertDetermination,
	alertDeterminationValues,
	AlertHistoryItem,
	AlertStatus,
	combinedAlertDeterminationType,
	InvestigationStatus,
	Outbreak,
	ServiceSourceType,
	Severity,
} from '@wcd/domain';
import {DataEntityType, DataSet, Paris, Repository} from '@microsoft/paris';
import {combineLatest, from, Observable, of, zip} from 'rxjs';
import {DataviewField} from '@wcd/dataview';
import {HttpClient} from '@angular/common/http';
import {AuthService, AuthUser} from '@wcd/auth';
import {ServiceUrlsService} from '@wcd/app-config';
import {map, switchMap, take, tap} from 'rxjs/operators';
import {ConfirmEvent} from '../../../dialogs/confirm/confirm.event';
import {Feature, FeaturesService} from '@wcd/config';
import {MachinesFiltersService, OsPlatformCategoriesFilter,} from '../../machines/services/machines.filters.service';
import {find, isNil, omit, values} from 'lodash-es';
import {I18nService} from '@wcd/i18n';
import {RbacService} from '../../../rbac/services/rbac.service';
import {EntityPanelsService} from '../../../global_entities/services/entity-panels.service';
import {FilterValuesChecklistValueData} from '@wcd/ng-filters';
import {SourceFilterService} from '../../common/services/source-filter.service';
import {ActivatedRouteSnapshot} from '@angular/router';
import * as FileSaver from 'file-saver';
import {sccHostService} from '@wcd/scc-interface';
import {DropdownMenuItemType} from 'office-ui-fabric-react';

export function routeToLegacyAlertRoute(route: ActivatedRouteSnapshot): ReadonlyArray<string> {
	const { id } = route.params;
	return ['alert', id];
}

const IncidentEntityType = 'IncidentEntity';

@Injectable()
export class AlertsService {
	private _alertFilters: Record<string, any>;

	private isIncidentEntity = (entityType: string): boolean => entityType === IncidentEntityType;

	private readonly alertHistoryMessageMap = {
		Feedback: (historyItem: AlertHistoryItem) => historyItem.newValue,
		AssignedTo: (historyItem: AlertHistoryItem) => {
			const entityType = this.i18nService.get(
				this.isIncidentEntity(historyItem.entityType)
					? 'incident_entityType_singularName'
					: 'alert_entityType_singularName'
			);
			return historyItem.newValue
				? this.i18nService.get('alert_history_change_assignedTo_message', {
						entityType,
						value: historyItem.newValue,
				  })
				: this.i18nService.get('alert_history_assignedTo_removed', { entityType });
		},
		Unassign: (historyItem: AlertHistoryItem) => historyItem.newValue,
		Suppressed: (historyItem: AlertHistoryItem) =>
			this.i18nService.get('alert_history_suppressed_rule', {
				rule: historyItem.newValue,
			}),
		Status: (historyItem: AlertHistoryItem) => {
			if (!historyItem.oldValue) {
				return historyItem.newValue;
			}
			// TODO: This is a temporary solution till BE will send status name in newValue and oldValue
			const incidentStatus: Record<string, string> = {
				'1': this.i18nService.get('alert_history_incident_status_active'),
				'2': this.i18nService.get('alert_history_incident_status_resolved'),
				'4': this.i18nService.get('alert_history_incident_status_inProgress'),
			};

			const toStatus = this.isIncidentEntity(historyItem.entityType)
				? incidentStatus[historyItem.newValue]
				: historyItem.newStatus
				? this.i18nService.get(historyItem.newStatus.nameI18nKey)
				: '';

			const fromText = this.i18nService.get('alert_history_from');

			const fromStatus = this.isIncidentEntity(historyItem.entityType)
				? historyItem.oldValue
					? ` ${fromText} '${incidentStatus[historyItem.oldValue]}'`
					: ''
				: historyItem.oldStatus
				? ` ${fromText} '${this.i18nService.get(historyItem.oldStatus.nameI18nKey)}'`
				: '';

			return this.i18nService.get('alert_history_status_change', { fromStatus, toStatus });
		},
		Classification: (historyItem: AlertHistoryItem) => {
			return historyItem.newClassification
				? this.i18nService.get('alert_history_change_classification_message', {
						value: this.i18nService.get(historyItem.newClassification.nameI18nKey),
				  })
				: this.i18nService.get('alert_history_classification_removed');
		},
		Determination: (historyItem: AlertHistoryItem) => {
			if (historyItem.newDetermination) {
				const determination = historyItem.newDetermination;
				const name = this.i18nService.get(determination.nameI18nKey);
				return this.i18nService.get('alert_history_change_determination_message', { name });
			} else {
				return this.i18nService.get('alert_history_determination_removed');
			}
		},
		Create: (_: AlertHistoryItem) => this.i18nService.get('alert_history_generated_message'),
		LinkAlertToIncident: (historyItem: AlertHistoryItem) => historyItem.newValue,
		UnlinkAlertFromIncident: (historyItem: AlertHistoryItem) => historyItem.newValue,
		Title: (historyItem: AlertHistoryItem) => {
			const fromText = this.i18nService.get('alert_history_from');
			const fromName = historyItem.oldValue ? ` '${fromText}' ${historyItem.oldValue}` : '';
			return this.i18nService.get('alert_history_title_name_change', {
				fromName,
				toName: historyItem.newValue,
			});
		},
	};

	constructor(
		private dialogsService: DialogsService,
		private paris: Paris,
		private http: HttpClient,
		private authService: AuthService,
		private machinesFiltersService: MachinesFiltersService,
		private serviceUrlsService: ServiceUrlsService,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private rbacService: RbacService,
		private entityPanelsService: EntityPanelsService,
		private sourceFilterService: SourceFilterService,
	) {}

	getAlertHistory(alertId: string): Observable<Array<AlertHistoryItem>> {
		const alertHistoryRepo: Repository<AlertHistoryItem> = this.paris.getRepository(AlertHistoryItem);
		return alertHistoryRepo
			.query({ where: { id: alertId }, pageSize: 100 })
			.pipe(map((dataSet: DataSet<AlertHistoryItem>) => dataSet.items));
	}

	/*
	 * @param subset (array of field names): if given, returns only filters that were defined in the subset
	 */
	getAlertsFilters(
		subset?: Array<string>,
		useNestedServiceSources: boolean = false
	): Observable<Record<string, any>> {
		if (this._alertFilters) return of(this._alertFilters);

		const rbacGroups$ = this.rbacService.isFilteringNecessary$.pipe(
			switchMap((isFilteringNecessary: boolean) =>
				isFilteringNecessary
					? this.machinesFiltersService.getUserExposedRbacGroups()
					: of<Array<FilterValuesChecklistValueData>>(null)
			)
		);

		const outbreaks$ = this.featuresService.isEnabled(Feature.ThreatAnalytics2)
			? this.paris.getRepository<Outbreak>(Outbreak).allItems$
			: of([]);

		return zip(rbacGroups$, outbreaks$).pipe(
			take(1),
			map(
				([rbacGroupFilterValues, outbreaks]: [
					Array<FilterValuesChecklistValueData>,
					Array<Outbreak>
				]) => {
					const alertFilters: Record<string, any> = {
						status: {
							count: null,
							values: (<DataEntityType>AlertStatus).entityConfig.values
								.filter((status: AlertStatus) => status.isSelectable)
								.map((status: AlertStatus) => {
									return {
										value: status.type,
										name: this.i18nService.get(status.nameI18nKey),
										count: null,
										priority: status.priority,
									};
								}),
						},
						AssignedTo: {
							count: null,
							values: [
								{
									value: this.authService.currentUser.username.toLowerCase(),
									name: 'Assigned to me',
									count: null,
								},
								{
									value: 'Automation',
									name: 'Assigned to automation',
									count: null,
								},
							].filter(Boolean),
						},
						detectionSource: useNestedServiceSources
							? null
							: {
									count: null,
									values: this.sourceFilterService.getDetectionSourcesFilterValues(),
							  },
						serviceSource: useNestedServiceSources
							? {
									count: null,
									values: this.sourceFilterService
										.getServiceSourceTreeFilterValues()
										.sort((a, b) => b.priority - a.priority),
							  }
							: null,
						severity: {
							count: null,
							values: (<DataEntityType>Severity).entityConfig.values
								.filter((severity: Severity) => severity.isSelectable)
								.map((severity: Severity) => {
									return {
										value: severity.name,
										name: this.i18nService.get(severity.nameI18nKey),
										count: null,
										priority: severity.priority,
									};
								}),
						},
						category: {
							count: null,
							values: (<DataEntityType>AlertCategory).entityConfig.values.map(
								(category: AlertCategory) => {
									return {
										value: category.type,
										name: category.name,
										count: null,
									};
								}
							),
						},
						osPlatform: {
							count: null,
							values: values(this.machinesFiltersService.osPlatformCategoriesMap).map(
								(osPlatform: OsPlatformCategoriesFilter) => {
									return {
										value: osPlatform.id,
										count: null,
									};
								}
							),
						},
						classification: {
							count: null,
							values: (<DataEntityType>AlertClassification).entityConfig.values.map(
								(classification: AlertClassification) => {
									return {
										value: classification.type,
										name: this.i18nService.get(classification.nameI18nKey),
										count: null,
									};
								}
							),
						},
						investigationStates: {
							count: null,
							values: (<DataEntityType>InvestigationStatus).entityConfig.values
								.filter((status: InvestigationStatus) => status.id >= 0)
								.filter((status: InvestigationStatus) =>
									this.featuresService.isEnabled(Feature.AirsApiOffloading)
										? !isNil(status.sevilleStatusId)
										: !status.isTempOffloadingStatus
								)
								.map((status: InvestigationStatus) => {
									return {
										value: status.id.toString(),
										name: status.name,
										count: null,
										priority: status.priority,
									};
								}),
						},
					};

					if (rbacGroupFilterValues) {
						Object.assign(alertFilters, {
							rbacGroup: {
								count: rbacGroupFilterValues.length,
								values: rbacGroupFilterValues,
							},
						});
					}

					if (this.featuresService.isEnabled(Feature.ThreatAnalytics2)) {
						Object.assign(alertFilters, {
							IoaDefinitionIds: {
								count: null,
								values: outbreaks
									.filter(
										(outbreak: Outbreak) => outbreak.ioaIds && outbreak.ioaIds.length > 0
									)
									.map((outbreak: Outbreak) => {
										return {
											value: outbreak.id,
											name: outbreak.displayName,
											count: null,
										};
									}),
							},
						});
					}

					return alertFilters;
				}
			),
			map((filters) => {
				if (!subset) return filters;

				return Object.keys(filters)
					.filter((filterName) => subset.includes(filterName))
					.reduce((filtersObj, filterName) => {
						filtersObj[filterName] = filters[filterName];
						return filtersObj;
					}, {});
			}),
			tap((filterValues: Record<string, any>) => {
				this._alertFilters = filterValues;
			})
		);
	}

	searchFilterValues(
		field: DataviewField,
		term: string,
		options?: any
	): Promise<Array<FilterValuesChecklistValueData>> {
		if (field.id === 'rbacGroup') {
			return this.machinesFiltersService.searchMachineGroupFilterValues(term, options);
		}
		return Promise.resolve([]);
	}

	assignAlertsToCurrentUser(alerts: Array<AlertBase>): Observable<AuthUser> {
		return this.updateAlerts(alerts).pipe(
			tap(() => {
				const entity = (<DataEntityType>alerts[0].constructor).entityConfig;

				this.dialogsService.showSuccessSnackbar({
					text: `${alerts.length > 1 ? entity.pluralName : entity.singularName} ${
						alerts.length > 1 ? ' were' : ' was'
					} assigned to ${this.authService.currentUser.name}`,
				});
			}),
			map(() => this.authService.currentUser)
		);
	}

	assignAlertsToSomeoneElse(alerts: Array<AlertBase>, assignee: string): Observable<any> {
		const updateData = {
			AlertIds: alerts.map((alert) => alert.id),
			AlertUpdateRequest: { assignedTo: assignee || ''}
		};
		return this.http
			.patch(`<mtpalert>/alerts`, updateData)
			.pipe(map(() => this.authService.currentUser));
	}

	setAlertsStatus(
		alerts: Array<Alert>,
		status: AlertStatus,
		requireConfirmNewStatus: boolean = true,
		focusableQuery?: string
	): Observable<AlertStatus> {
		const setAlertStatus$ = this.updateAlerts(alerts, { status: status.type }).pipe(
			tap(() => {
				const entityName = this.i18nService.get(
					alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName'
				);
				const text = this.i18nService.get('alerts_status_change', {
					entityName,
					status: this.i18nService.get(status.nameI18nKey),
				});
				this.dialogsService.showSuccessSnackbar({ text, focusableQuery });
			}),
			map(() => status)
		);

		if (requireConfirmNewStatus && status.type === 'New') {
			return from(
				this.dialogsService.confirm({
					title: this.i18nService.get('alerts.status.changeToNew.title'),
					text: this.i18nService.get('alerts.status.changeToNew.description'),
				})
			).pipe(
				switchMap((confirmed: ConfirmEvent) => (confirmed.confirmed ? setAlertStatus$ : of(null)))
			);
		}

		return setAlertStatus$;
	}

	setAlertsClassification(
		alerts: Array<Alert>,
		classification: AlertClassification,
		focusableQuery?: string
	): Observable<any> {
		return this.updateAlerts(alerts, {
			classification: classification.type,
		}).pipe(
			tap(() => {
				const entityName = `${this.i18nService.get(
					alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName'
				)}`;
				const value = `${this.i18nService.get(classification.nameI18nKey)}`;
				const text = this.i18nService.get('set_alertsClassification', { entityName, value });
				this.dialogsService.showSuccessSnackbar({ text, focusableQuery });
			})
		);
	}

	setAlertsDetermination(alerts: Array<Alert>, determination: AlertDetermination): Observable<any> {
		return this.updateAlerts(alerts, {
			determination: determination.type,
		}).pipe(
			tap(() => {
				const entityName = `${this.i18nService.get(
					alerts.length > 1 ? 'alert_entityType_pluralName' : 'alert_entityType_singularName'
				)}`;
				const value = `${this.i18nService.get(determination.nameI18nKey)}`;
				const text = this.i18nService.get('set_alertsDetermination', { entityName, value });
				this.dialogsService.showSuccessSnackbar({ text });
			})
		);
	}

	setAlertsClassificationAndDetermination(alerts: Array<Alert>, value) {
		const { classification, determination } = combinedClassifications[value];
		return this.updateAlerts(alerts, {
			classification: alertClassificationValues.find(value => value.id === classification).type,
		})
			.toPromise()
			.then(() => {
				this.updateAlerts(alerts, {
					determination: alertDeterminationValues.find(value => value.id === determination).type,
				})
					.toPromise()
					.then(() => {
						const text = this.i18nService.get('alerts_classification_changed');
						this.dialogsService.showSuccessSnackbar({ text });
					});
			});
	}

	restoreHiddenAlerts(alerts: Array<Alert>): Promise<any> {
		return this.dialogsService
			.confirm({
				title: 'Restore hidden alert',
				confirmText: 'Restore',
				text:
					"This alert will be moved to the 'New alerts' queue, assigned to you and no longer be hidden.",
			})
			.then((e: ConfirmEvent) => {
				return new Promise((resolve, reject) => {
					if (e.confirmed) {
						const newAlertStatus: AlertStatus = find(
							this.paris.getRepository(AlertStatus).entity.values,
							(alertStatus: AlertStatus) =>
								alertStatus.isSelectable && alertStatus.type === 'New'
						);
						this.setAlertsStatus(alerts, newAlertStatus, false).subscribe(
							() => {
								resolve();
							},
							(error) => reject(error)
						);
					}
				});
			});
	}

	addCommentToAlerts(alerts: Array<Alert>, comment: string): Observable<any> {
		return this.updateAlerts(alerts, { comment: comment });
	}

	private updateAlerts(
		alerts: Array<AlertBase>,
		data?: { [index: string]: any }
	): Observable<any> {
		const updateData: { [index: string]: any } = Object.assign(
			{
				AlertIds: alerts.map((alert) => alert.id),
				AlertUpdateRequest: data
			},
		);

		return this.http.patch(`<mtpalert>/alerts`, updateData);
	}

	showAlertsSidePanelByRawData(alertsRawData: Array<any>, options?: any) {
		const alerts$: Observable<Array<Alert>> = combineLatest(
			alertsRawData.map((alertData) => this.paris.createItem(Alert, alertData))
		);

		alerts$.subscribe((alerts) => this.entityPanelsService.showEntities(Alert, alerts, options));
	}

	getContainedAlerts(alert: Alert, options?: {}, groupByHash: boolean = false): Observable<Array<Alert>> {
		if (!alert.isGroup) {
			throw new Error('Alert must be a group');
		}

		const config = Object.assign(
			{ detectionSource: alert.detectionSource.type },
			groupByHash ? { groupHash: alert.groupHash } : { groupId: alert.groupId }
		);

		return this.paris
			.query<Alert>(Alert, {
				where: Object.assign({}, omit(options, ['alertType']), config),
				pageSize: alert.groupedAlertsCount,
			})
			.pipe(map((dataSet) => dataSet.items));
	}

	getAlertLink(id: string) {
		if (this.featuresService.isEnabled(Feature.UpgradeAlertPage)) {
			return `/alerts/${id}/details`;
		} else {
			return `/alert/${id}`;
		}
	}

	// Check if this alert can be opened in mdatp portal
	isAlertLinkableToMdatp(alert: Alert): boolean {
		return (
			!alert.isGroup &&
			(!sccHostService.isSCC ||
				(alert.serviceSource &&
					alert.serviceSource.id === ServiceSourceType.Wdatp &&
					!alert.isCustomDetection))
		);
	}

	getAlertHistoryMessage = (item: AlertHistoryItem): string => {
		var alertHistoryMessage: string = '';

		try {
			if (item && this.alertHistoryMessageMap[item.type.id]) {
				alertHistoryMessage = this.alertHistoryMessageMap[item.type.id](item);
			}
		} catch (error) {
			console.error(error);
		}

		return alertHistoryMessage;
	};

	downloadCsv(alerts: Alert[], options?: any) {
		if (options.columns && options.fields) {
			const fields = options.fields
				.filter((field) => options.columns.includes(field.id))
				.reduce((r, field) => ({ ...r, ...{ [field.id]: field } }), {});

			const titles = options.columns.map((column) => fields[column].name).join(',');

			const rows = alerts.map((alert: Alert) =>
				options.columns
					.map((column) => `"${fields[column].getDisplay(alert).replace(/"/g, '""')}"`)
					.join(',')
			);

			const csv = [titles, ...rows].join('\n');

			const blob = new Blob([csv]);
			FileSaver.saveAs(blob, 'sample-file.csv');
		}
	}
}

export interface AlertDetailsOptions {
	showInvestigation?: boolean;
	showMachine?: boolean;
}

type DeterminationNewValuesType = {
	[key in keyof typeof combinedAlertDeterminationType]: any;
};
type CombinedClassificationsType = {
	[key: string]: { determination: string; classification: string };
};

const determinationNewValues: DeterminationNewValuesType = alertDeterminationValues.reduce(
	(acc, currentValue) => {
		acc[currentValue.type] = currentValue.id;
		return acc;
	},
	{} as DeterminationNewValuesType
);

export const combinedClassifications: CombinedClassificationsType = {
	NotSet: { determination: 'NotSet', classification: AlertClassificationId.Unknown },
	MultiStagedAttack: {
		determination: determinationNewValues.MultiStagedAttack,
		classification: AlertClassificationId.TruePositive,
	},
	Malware: {
		determination: determinationNewValues.Malware,
		classification: AlertClassificationId.TruePositive,
	},
	MaliciousUserActivity: {
		determination: determinationNewValues.MaliciousUserActivity,
		classification: AlertClassificationId.TruePositive,
	},
	UnwantedSoftware: {
		determination: determinationNewValues.UnwantedSoftware,
		classification: AlertClassificationId.TruePositive,
	},
	Phishing: {
		determination: determinationNewValues.Phishing,
		classification: AlertClassificationId.TruePositive,
	},
	CompromisedUser: {
		determination: determinationNewValues.CompromisedUser,
		classification: AlertClassificationId.TruePositive,
	},
	TruePositiveOther: {
		determination: determinationNewValues.Other,
		classification: AlertClassificationId.TruePositive,
	},
	SecurityTesting: {
		determination: determinationNewValues.SecurityTesting,
		classification: AlertClassificationId.BenignPositive,
	},
	ConfirmedUserActivity: {
		determination: determinationNewValues.ConfirmedUserActivity,
		classification: AlertClassificationId.BenignPositive,
	},
	LineOfBusinessApplication: {
		determination: determinationNewValues.LineOfBusinessApplication,
		classification: AlertClassificationId.BenignPositive,
	},
	BenignPositiveOther: {
		determination: determinationNewValues.Other,
		classification: AlertClassificationId.BenignPositive,
	},
	Clean: {
		determination: determinationNewValues.Clean,
		classification: AlertClassificationId.FalsePositive,
	},
	InsufficientData: {
		determination: determinationNewValues.InsufficientData,
		classification: AlertClassificationId.FalsePositive,
	},
	FalsePositiveOther: {
		determination: determinationNewValues.Other,
		classification: AlertClassificationId.FalsePositive,
	},
};

export function getCombinedClassificationMap(i18nService: I18nService) {
	return [
		{ key: 'NotSet', text: i18nService.get('alerts_classification_dropdown_notSet') },
		{
			key: 'trueClassificationHeader',
			text: i18nService.get('reporting_alertsByClassification_trueAlert'),
			itemType: DropdownMenuItemType.Header,
		},
		{ key: 'MultiStagedAttack', text: i18nService.get('alertDetermination_MultiStagedAttack') },
		{ key: 'Malware', text: i18nService.get('alertDetermination_malware') },
		{ key: 'MaliciousUserActivity', text: i18nService.get('alertDetermination_MaliciousUserActivity') },
		{ key: 'UnwantedSoftware', text: i18nService.get('alertDetermination_unwanted_software') },
		{ key: 'Phishing', text: i18nService.get('alertDetermination_Phishing') },
		{ key: 'CompromisedUser', text: i18nService.get('alertDetermination_CompromisedUser') },
		{ key: 'TruePositiveOther', text: i18nService.get('alertDetermination_other') },
		{ key: 'divider_1', text: '-', itemType: DropdownMenuItemType.Divider },
		{
			key: 'benignClassificationHeader',
			text: i18nService.get('alerts_classification_dropdown_benign'),
			itemType: DropdownMenuItemType.Header,
		},
		{ key: 'SecurityTesting', text: i18nService.get('alertDetermination_security_testing') },
		{ key: 'ConfirmedUserActivity', text: i18nService.get('alertDetermination_ConfirmedUserActivity') },
		{
			key: 'LineOfBusinessApplication',
			text: i18nService.get('alertDetermination_LineOfBusinessApplication'),
		},
		{ key: 'BenignPositiveOther', text: i18nService.get('alertDetermination_other') },
		{ key: 'divider_2', text: '-', itemType: DropdownMenuItemType.Divider },
		{
			key: 'falseClassificationHeader',
			text: i18nService.get('reporting_alertsByClassification_falseAlert'),
			itemType: DropdownMenuItemType.Header,
		},
		{ key: 'Clean', text: i18nService.get('alertDetermination_Clean') },
		{ key: 'InsufficientData', text: i18nService.get('alertDetermination_InsufficientData') },
		{ key: 'FalsePositiveOther', text: i18nService.get('alertDetermination_other') },
	];
}
