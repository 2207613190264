import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType, DataQuery } from '@microsoft/paris';
import { HuntingRule } from '../hunting/hunting-rule.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { Alert } from './alert.entity';

@EntityRelationship({
    sourceEntity: Alert,
    dataEntity: HuntingRule,
    allowedTypes: [RelationshipType.OneToOne],
    baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
    getRelationshipData: (query: Alert) => ({ alertId: query.id}),
    endpoint: (config: WcdPortalParisConfig, query: DataQuery) =>
    `ine/huntingservice/alerts/${(query.where as any).alertId}/rule`,
})
export class AlertToHuntingRuleRelationship implements EntityRelationshipRepositoryType<Alert, HuntingRule> {}