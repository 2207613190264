import { Injectable, NgZone } from '@angular/core';
import { Store } from '../../data/models/store.base';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { TagModel } from '../models/tags.model';
import { TagsBackendService } from './tags.backend.service';
import { TaggedObjectType } from '../models/tagged-object-type.enum';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class TagsStore extends Store<TagModel, number> {
	constructor(
		protected backendService: TagsBackendService,
		ngZone: NgZone,
		dialogsService: DialogsService,
		i18nService: I18nService,
	) {
		super(backendService, ngZone, TagModel, dialogsService, i18nService, {
			itemNameSingular: 'Tag',
			itemNamePlural: 'Tags',
			iconName: 'tag',
			showSnackbarOnUpdate: false,
		});
	}

	addTagsToObjects(
		tag: TagModel,
		objectType: TaggedObjectType,
		objectsIds?: Array<number | string>,
		options?: any
	) {
		return this.backendService.addTagsToObjects(String(tag.id), objectType, objectsIds, options);
	}

	removeTagsFromObjects(
		tag: TagModel,
		objectType: TaggedObjectType,
		objectsIds?: Array<number | string>,
		options?: any
	) {
		return this.backendService.removeTagsFromObjects(String(tag.id), objectType, objectsIds, options);
	}
}
