import { NgModule } from '@angular/core';
import { PreloadService } from './services/preload.service';
import { NotificationsModule } from '../notifications/notifications.module';
import { AirsEntitiesModule } from '../@entities/airs_entities/airs-entities.module';
import { SharedModule } from '../shared/shared.module';
import { ConfigModule } from '@wcd/config';
import { CommunityModule } from '../community/community.module';

@NgModule({
	imports: [SharedModule, NotificationsModule, AirsEntitiesModule, ConfigModule, CommunityModule],
	providers: [PreloadService],
})
export class PreloadModule {}
