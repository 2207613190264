import { Injectable } from '@angular/core';
import { DataSet } from '@microsoft/paris';
import { Asset, RecommendationContextType } from '@wcd/domain';

const CSV_SUPPORTED_RECOMMENDATION_CONTEXT_TYPES = [
	RecommendationContextType.ContextWithServiceName,
	RecommendationContextType.ContextWithServiceNameAndServicePath,
	RecommendationContextType.ContextWithUserName,
	RecommendationContextType.ContextWithShareNameAndSharePath
];

const UNQUOTED_SERVICE_PATH_SECURITY_RECOMMENDATION_ID = 'scid-3001';
const PROTECTED_LOCATION_SERVICE_PATH_SECURITY_RECOMMENDATION_ID = 'scid-3002';
const CACHED_PASSWORD_SERVICE_SECURITY_RECOMMENDATION_ID = 'scid-3003';
const BUILT_IN_ADMIN_ACCOUNT_SECURITY_RECOMMENDATION_ID = 'scid-3010';
const BUILT_IN_GUEST_ACCOUNT_SECURITY_RECOMMENDATION_ID = 'scid-3011';
const OFFLINE_ACCESS_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4000';
const EVERYONE_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4001';
const ROOT_FOLDER_SHARE_SECURITY_RECOMMENDATION_ID = 'scid-4002';

@Injectable()
export class TvmRecommendationContextService {
	private _recommendationContextToContextKeyIconName: Record<RecommendationContextType, string> = {
		[RecommendationContextType.ContextWithServiceName]: 'wcd-icons--service',
		[RecommendationContextType.ContextWithServiceNameAndServicePath]: 'wcd-icons--service',
		[RecommendationContextType.ContextWithUserName]: 'wcd-icons--user',
		[RecommendationContextType.ContextWithShareNameAndSharePath]: 'wcd-icons--service',
		[RecommendationContextType.Unknown]: '',
	};

	constructor() {}

	isRecommendationContextNeededInCSV(recommendationContextType: RecommendationContextType): boolean {
		return CSV_SUPPORTED_RECOMMENDATION_CONTEXT_TYPES.includes(recommendationContextType);
	}

	getRecommendationContextTypeByAsset(asset: Asset) {
		const isUnknown = asset.recommendationContext === null || asset.recommendationContext.length === 0;
		return isUnknown ? RecommendationContextType.Unknown : asset.recommendationContext[0].contextType;
	}

	getRecommendationContextTypeByAssets(assets: DataSet<Asset>) {
		return assets.items.length === 0
			? RecommendationContextType.Unknown
			: this.getRecommendationContextTypeByAsset(assets.items[0]);
	}

	// This is temporarily till we add a field in the recommendations pre-agg table that indicates if there is a context defined or not
	getRecommendationContextTypeByScId(scId: string): RecommendationContextType {
		switch (scId) {
			case UNQUOTED_SERVICE_PATH_SECURITY_RECOMMENDATION_ID:
			case PROTECTED_LOCATION_SERVICE_PATH_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithServiceNameAndServicePath;
			}
			case CACHED_PASSWORD_SERVICE_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithServiceName;
			}
			case BUILT_IN_ADMIN_ACCOUNT_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithUserName;
			}
			case BUILT_IN_GUEST_ACCOUNT_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithUserName;
			}
			case OFFLINE_ACCESS_SHARE_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithShareNameAndSharePath;
			}
			case EVERYONE_SHARE_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithShareNameAndSharePath;
			}
			case ROOT_FOLDER_SHARE_SECURITY_RECOMMENDATION_ID: {
				return RecommendationContextType.ContextWithShareNameAndSharePath;
			}
			default: {
				return RecommendationContextType.Unknown;
			}
		}
	}

	// This function returns the headers names of the tables that show recommendation context, regular html tables and not dataview tables
	getRecommendationContextTableHeadersByType(recommendationContextType: RecommendationContextType) {
		const SERVICE_NAME_PROPERTY = 'serviceName';
		const SERVICE_PATH_PROPERTY = 'path';
		const USER_NAME_PROPERTY = 'userName';
		const SHARE_NAME_PROPERTY = 'shareName';
		const SHARE_PATH_PROPERTY = 'sharePath';

		switch (recommendationContextType) {
			case RecommendationContextType.ContextWithServiceNameAndServicePath:
				return [SERVICE_NAME_PROPERTY, SERVICE_PATH_PROPERTY];
			case RecommendationContextType.ContextWithServiceName:
				return [SERVICE_NAME_PROPERTY];
			case RecommendationContextType.ContextWithUserName:
				return [USER_NAME_PROPERTY];
			case RecommendationContextType.ContextWithShareNameAndSharePath:
				return [SHARE_NAME_PROPERTY, SHARE_PATH_PROPERTY];
			default:
				return [];
		}
	}

	getRecommendationContextKeyIconNameByType(recommendationContextType: RecommendationContextType) {
		return this._recommendationContextToContextKeyIconName[recommendationContextType];
	}
}
