import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { RegistryKey } from '@wcd/domain';
import { RegistryEntityPanelComponent } from '../components/registry.entity-panel.component';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Injectable()
export class RegistryKeyEntityTypeService implements EntityTypeService<RegistryKey> {
	readonly entityType: Readonly<EntityType<RegistryKey>> = {
		entity: RegistryKey,
		id: 'registryKey',
		icon: 'entities.registry',
		singleEntityPanelComponentType: RegistryEntityPanelComponent,
		loadFullEntityInPanel: false,
		getEntityName: registryKey => registryKey.name,
		getNavigationModel: () => {
			return {
				routerLink: null
			}
		},
		entityPageViewMode: EntityPageViewMode.Default,
		entityPluralNameKey: 'registryKey_entityType_pluralName',
		entitySingularNameKey: 'registryKey_entityType_singularName',
	};
}
