var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as i0 from "@angular/core";
var ReactPanelsService = /** @class */ (function () {
    function ReactPanelsService() {
        this.currentlyRenderedComponents = new Map();
        this.currentlyRenderedComponentsArray = [];
        this.idCounter = 0;
    }
    /**
     * Renders react component with the app.scc.component as the parent component.
     * @param componentConfig object of type ReactComponentConfig with the data needed to render the component
     * @return Unique id of the component, can be used to close the component
     **/
    ReactPanelsService.prototype.renderComponent = function (componentConfig) {
        var componentId = componentConfig.componentName + "_" + this.idCounter++;
        var configuredComponent = __assign({}, componentConfig, { onComponentDestroy: this.onComponentDestroy.bind(this, componentId), id: componentId, isOpen: true });
        this.currentlyRenderedComponents.set(componentId, configuredComponent);
        this.currentlyRenderedComponentsArray = Array.from(this.currentlyRenderedComponents.values());
        return componentId;
    };
    /**
     * Removes the component from the DOM, Should be used to close the component in case we need to close it via the service.
     * @param componentId: id of the component to be removed
     */
    ReactPanelsService.prototype.removeComponent = function (componentId) {
        var component = this.getComponentById(componentId);
        if (component) {
            component.isOpen = false;
        }
        else {
            console.warn('ReactPanelService.removeComponent: Wrong id or Tried to remove component that has been removed already');
        }
    };
    Object.defineProperty(ReactPanelsService.prototype, "renderedComponentsArray", {
        get: function () {
            return this.currentlyRenderedComponentsArray;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Get the component config object by id from  currently opened components array
     * @param id
     */
    ReactPanelsService.prototype.getComponentById = function (id) {
        return this.currentlyRenderedComponents.get(id);
    };
    /**
     * Removes the component from `currentlyRenderedComponents` and calls component's provided onDismiss.
     * Gets called by the angular placeholder when the wrapper emits onComponentDismiss event
     * @param componentId: id of the component to be removed
     */
    ReactPanelsService.prototype.onComponentDestroy = function (componentId) {
        var component = this.getComponentById(componentId);
        this.currentlyRenderedComponents.delete(componentId);
        this.currentlyRenderedComponentsArray = Array.from(this.currentlyRenderedComponents.values());
        component.props.onDismiss && component.props.onDismiss();
    };
    ReactPanelsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ReactPanelsService_Factory() { return new ReactPanelsService(); }, token: ReactPanelsService, providedIn: "root" });
    return ReactPanelsService;
}());
export { ReactPanelsService };
