import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../data/models/store.backend.base';
import { TaggedObjectType } from '../models/tagged-object-type.enum';
import { DownloadService } from '../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';

const TAGS_API_ENDPOINT = '/tags';

@Injectable()
export class TagsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + TAGS_API_ENDPOINT;
	}

	addTagsToObjects(
		tagId: string,
		objectType: TaggedObjectType,
		objectsIds?: Array<number | string>,
		options?: any
	) {
		return this.patchTags('add', tagId, objectType, objectsIds, options);
	}

	removeTagsFromObjects(
		tagId: string,
		objectType: TaggedObjectType,
		objectsIds?: Array<number | string>,
		options?: any
	) {
		return this.patchTags('remove', tagId, objectType, objectsIds, options);
	}

	private patchTags(
		method: string,
		tagId: string,
		objectType: TaggedObjectType,
		objectsIds?: Array<number | string>,
		options?: any
	) {
		const params: any = {
			method: method,
			tag_ids: [parseInt(tagId)],
			object_ids: objectsIds ? objectsIds : undefined,
			object_type: objectType,
		};

		return this.http.patch(`${this.apiUrl}/tag`, params, this.getUrlParams(options));
	}
}
