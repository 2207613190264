<entity-panel-collapsible-section [title]="'tvm.threatsInsights.title' | i18n" [collapsed] = false>
	<div class="tvm-list">
		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.threatsInsights.public' | i18n }}</dt>
			<dd>{{(threatInfo.hasExploit ? 'common.yes' : 'common.no') | i18n }}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.threatsInsights.verified' | i18n }}</dt>
			<dd>{{(threatInfo.isExploitVerified ? 'common.yes' : 'common.no') | i18n }}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.threatsInsights.exploitKit' | i18n }}</dt>
			<dd>{{(threatInfo.isInExploitKit ? 'common.available' : 'common.notAvailable') | i18n }}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.threatsInsights.type' | i18n }}</dt>
			<dd *ngIf="threatInfo.exploitTypes && threatInfo.exploitTypes.length > 0; else blankEntry">{{exploitTypesStr}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.threatsInsights.reference' | i18n }}</dt>
			<dd *ngIf="threatInfo.exploitUris.length > 0; else blankEntry" >
				<div *ngFor="let reference of threatInfo.exploitUris">
					<a [href]="reference" target="_blank">{{reference}}</a>
				</div>
			</dd>
		</dl>
		<ng-template #blankEntry>
			<dd>-</dd>
		</ng-template>
	</div>
</entity-panel-collapsible-section>
