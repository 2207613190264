var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReadOnlyIdentifiable } from './readonly-identifiable.model';
import { Observable } from 'rxjs';
var Identifiable = /** @class */ (function (_super) {
    __extends(Identifiable, _super);
    function Identifiable(data, params) {
        var _this = _super.call(this, data, params) || this;
        _this.isDeleting = false;
        _this.isSaving = false;
        _this.isEditing = false;
        _this._params = params;
        _this.changes$ = new Observable(function (observer) { return (_this._changesObservable = observer); });
        return _this;
    }
    Identifiable.prototype.restore = function () {
        this.setData(this._data, this._params);
    };
    Identifiable.prototype.cancelEdit = function () {
        if (!this.isNew)
            this.restore();
        this.isEditing = false;
    };
    /**
     * Triggers the changes$ observable with the specified changes.
     * @param changes
     */
    Identifiable.prototype.notifyOnChange = function (changes) {
        this._changesObservable.next(changes);
    };
    return Identifiable;
}(ReadOnlyIdentifiable));
export { Identifiable };
