import { Injectable } from '@angular/core';
import { ItsmTool, IntuneIntegrationStatus } from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { ItsmProvisioningService } from '../../itsm-provisioning/services/itsm-provisioning.service';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class RemediationItsmToolsService {
	private isIntuneConnected = false;
	private isIntuneLicenceExists = false;

	private scaSupportedItsmTools: ItsmTool[];
	private supportedItsmTools: ItsmTool[];
	private connectedItsmTools: ItsmTool[];

	private isServiceNowConnected = false;

	private isServiceNowFeatureEnabled: boolean;
	private isScaIntuneIntegrationEnabled: boolean;

	constructor(
		private itsmToolProvisioningService: ItsmProvisioningService,
		private appConfigService: AppConfigService,
		featuresService: FeaturesService,
		paris: Paris
	) {
		paris.getItemById(IntuneIntegrationStatus, 1).subscribe(status => {
			this.isIntuneConnected = status.intuneIntegrationEnabled;
			this.isIntuneLicenceExists = status.licenseEnabled;
		});
		this.isServiceNowFeatureEnabled =
			featuresService.isEnabled(Feature.TvmServiceNowIntegration) ||
			this.appConfigService.IsServiceNowIntegrationEnabled;
		this.getServiceNowState();

		this.isScaIntuneIntegrationEnabled = featuresService.isEnabled(Feature.TvmScaIntuneIntegration);
	}

	getScaSupportedItsmTools(): ItsmTool[] {
		if (this.scaSupportedItsmTools) {
			return this.scaSupportedItsmTools;
		}

		this.scaSupportedItsmTools = this.isScaIntuneIntegrationEnabled ? [ItsmTool.Intune] : [];
		if (this.isServiceNowFeatureEnabled) {
			this.scaSupportedItsmTools.push(ItsmTool.ServiceNow);
		}

		return this.scaSupportedItsmTools;
	}

	getSupportedItsmTools(): ItsmTool[] {
		if (!this.supportedItsmTools) {
			this.supportedItsmTools = [ItsmTool.Intune];

			if (this.isServiceNowFeatureEnabled) {
				this.supportedItsmTools.push(ItsmTool.ServiceNow);
			}
		}

		return this.supportedItsmTools;
	}

	getConnectedItsmTools(): ItsmTool[] {
		if (!this.connectedItsmTools) {
			this.connectedItsmTools = [];
		}

		this.getServiceNowState();

		if (this.isIntuneConnected) {
			this.pushConnected(ItsmTool.Intune);
		} else {
			this.deleteConnected(ItsmTool.Intune);
		}

		return this.connectedItsmTools;
	}

	private getServiceNowState() {
		if (!this.isServiceNowFeatureEnabled) {
			return;
		}

		if (!this.connectedItsmTools) {
			this.connectedItsmTools = [];
		}

		this.itsmToolProvisioningService.getItsmToolProvisioning(ItsmTool.ServiceNow).subscribe(
			status => {
				this.isServiceNowConnected = status.isProvisioned;

				if (this.isServiceNowConnected) {
					this.pushConnected(ItsmTool.ServiceNow);
				} else {
					this.deleteConnected(ItsmTool.ServiceNow);
				}
			},
			err => this.deleteConnected(ItsmTool.ServiceNow)
		);
	}

	private pushConnected(itsmTool: ItsmTool) {
		const index = this.connectedItsmTools.indexOf(itsmTool, 0);
		if (index === -1) {
			this.connectedItsmTools.push(itsmTool);
		}
	}

	private deleteConnected(itsmTool: ItsmTool) {
		const index = this.connectedItsmTools.indexOf(itsmTool, 0);
		if (index > -1) {
			this.connectedItsmTools.splice(index, 1);
		}
	}
}
