import { Injectable } from '@angular/core';
import { DataEntityType, Paris } from '@microsoft/paris';
import { find, keyBy } from 'lodash-es';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	Machine,
	MachineRiskScore,
	Outbreak,
	OutbreakMitigationType,
	MachineExposureScore,
	MachineVulnerabilitySeverityLevel,
	MachineVulnerabilityAgeLevel,
	MachineExploitLevel,
	onboardingStatusesMap,
	OnboardingStatus,
	firstSeenValuesMap,
	machineExclusionStateMap,
	MachineExclusionState,
	MachineManagedByType,
	machineManagedByValuesMap,
} from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { FieldsService } from '../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import {
	HealthStatusCategoryFilter,
	MachinesFiltersService,
	MachinesFilterType,
} from './machines.filters.service';
import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistSelection,
	FilterValuesChecklistValueData,
	FilterValuesDropdownComboComponent,
	FilterValuesDropdownComboSelection,
	ListFilterValue,
	ListFilterValueConfig,
	SerializedFilters,
} from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FabricIconNames } from '@wcd/scc-common';
import { FeaturesService, Feature, FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { MachinesDataviewTagsComponent } from '../components/machines-dataview-tags/machines-dataview-tags.component';
import { FirstSeen } from '../../../../../../../packages/@wcd/domain/src/machine/device-first-seen.enum';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TvmLicenseType } from '@wcd/scc-common';

@Injectable()
export class MachinesFieldsService implements FieldsService<Machine> {
	private _fields: Array<DataviewField>;
	private shouldDisplayNotManagedDevices: boolean;
	isMacAndIpv6Enabled: boolean;

	constructor(
		private machinesFiltersService: MachinesFiltersService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private paris: Paris,
		private flavorService: FlavorService,
		private tvmLicensesService: TvmLicensesAngularService
	) {
		this.shouldDisplayNotManagedDevices =
			(this.featuresService.isEnabled(Feature.DeviceInventoeryDisplayNotManagedDevices) ||
				this.featuresService.isEnabled(Feature.DevicesListIotV1)) &&
			this.flavorService.isEnabled(AppFlavorConfig.devices.onboardingStatus);
	}

	get fields(): Array<DataviewField<Machine>> {
		this._fields = this._fields || DataviewField.fromList<Machine>(this.getFields());
		return this._fields;
	}

	getFields(): Array<DataviewFieldConfig<Machine>> {
		return [
			{
				id: 'machinename',
				name: this.i18nService.strings.machines_dataView_fields_deviceName,
				enabledByDefault: true,
				className: EntityNameComponent.entityNameDefaultCssClass,
				component: {
					type: EntityNameComponent,
					getProps: (machine: Machine) => {
						return {
							entity: machine,
							entityTypeId: 'machine',
							hideIcon: true,
						};
					},
				},
			},
			{
				id: 'domain',
				name: this.i18nService.strings.machines_dataView_fields_domain,
				getDisplay: (machine: Machine) => machine.domain,
				getTooltip: (machine: Machine) => machine.domain,
				sort: { enabled: false },
			},
			{
				id: 'riskScores',
				name: this.i18nService.get('machines.dataView.fields.riskLevel'),
				description: this.i18nService.get('machines.riskLevel'),
				getDisplay: (machine: Machine) =>
					machine.riskScore && this.i18nService.get(machine.riskScore.nameI18nKey),
				className: 'nowrap',
				getCssClass: (machine: Machine) =>
					machine.riskScore
						? `wcd-severity wcd-severity-${machine.riskScore.id.toLocaleLowerCase()}`
						: 'disabled',
				sort: {
					sortDescendingByDefault: true,
				},
				filter: {
					priority: 0,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (riskScoreId: string): ListFilterValueConfig<string> => {
								const riskScore = keyBy(
									(<DataEntityType>MachineRiskScore).entityConfig.values,
									'id'
								)[riskScoreId];
								return {
									id: riskScore.id,
									rawValue: riskScore.id,
									name: this.i18nService.get(riskScore.nameI18nKey),
									nameClass: `wcd-severity wcd-severity-${riskScore.id.toLowerCase()}`,
									priority: riskScore.priority,
								};
							},
						},
					},
				},
			},
			!this.shouldDisplayTvmBasicFields()
				? null
				: {
						id: 'exposureScores',
						name: this.i18nService.get('machines.dataView.fields.exposureLevel'),
						description: this.i18nService.get('machines.exposureLevel'),
						getDisplay: (machine: Machine) =>
							machine.exposureScore &&
							this.i18nService.get('machineExposureScoreType_' + machine.exposureScore.id),
						icon: {
							fabricIcon: (machine: Machine) =>
								machine.exposureScore && machine.exposureScore.priority < 4
									? FabricIconNames.Warning
									: null,
							className: (machine: Machine) =>
								machine.exposureScore
									? 'ms-fontColor-' + machine.exposureScore.className
									: null,
						},
						className: 'nowrap',
						getCssClass: (machine: Machine) => (machine.exposureScore ? null : 'disabled'),
						filter: {
							priority: 0,
							component: {
								type: FilterValuesChecklistComponent,
								config: {
									mapFilterValue: (
										exposureScoreId: string
									): ListFilterValueConfig<string> => {
										const exposureScore = keyBy(
											(<DataEntityType>MachineExposureScore).entityConfig.values,
											'id'
										)[exposureScoreId];
										return {
											id: exposureScore.id,
											rawValue: exposureScore.id,
											name: this.i18nService.get(
												'machineExposureScoreType_' + exposureScore.id
											),
											icon: {
												iconName: exposureScore.icon,
												className: 'color-text-' + exposureScore.className,
											},
											priority: exposureScore.priority,
										};
									},
								},
							},
						},
				  },
			{
				id: 'osPlatforms',
				name: this.i18nService.get('machines.dataView.fields.osPlatform'),
				getDisplay: (machine: Machine) =>
					machine.os
						? machine.os.osPlatform
							? machine.os.osPlatform.name
							: machine.os.osPlatformString
						: '',
				filter: {
					priority: 1,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (category: string) => {
								const osPlatform = this.machinesFiltersService.osPlatformCategoriesMap[
									category
								];
								return {
									id: osPlatform.values.map(value => value.id).join(','),
									rawValue: osPlatform.id,
									name: osPlatform.name,
									priority: osPlatform.priority,
								};
							},
						},
					},
				},
				sort: { enabled: true },
			},
			{
				id: 'releaseVersion',
				name: this.i18nService.get('machines.dataView.fields.osVersion'),
				getDisplay: (machine: Machine) =>
					machine.os && machine.os.releaseVersion ? machine.os.releaseVersion.name : '',
			},
			{
				id: 'healthStatuses',
				name: this.i18nService.get('machines.dataView.fields.healthState'),
				className: 'nowrap wcd-text-overflow-medium',
				getDisplay: (machine: Machine) => this.i18nService.get(machine.status.nameI18nKey),
				filter: {
					priority: 2,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (status: string): ListFilterValueConfig<string> => {
								let healthStatus: HealthStatusCategoryFilter = this.machinesFiltersService
									.machineHealthStatusCategoriesMap[status];
								if (!healthStatus) {
									healthStatus = find(
										this.machinesFiltersService.machineHealthStatusCategoriesMap,
										category => {
											return category.children.map(child => child.id).includes(status);
										}
									);
								}
								if (!healthStatus) return null;

								return {
									id: healthStatus.id,
									name: healthStatus.name,
									children:
										healthStatus.children &&
										healthStatus.children.map(child => {
											return {
												id: child.id,
												name: this.i18nService.get(child.nameI18nKey),
											};
										}),
								};
							},
						},
					},
					serializeFilterValues: (
						filterSelection: FilterValuesChecklistSelection<string>,
						defaultSerialized
					) => {
						if (!filterSelection || !filterSelection.length) return {};

						return {
							healthStatuses: filterSelection.reduce((serializedValues, healthStatusId) => {
								const healthStatusCategory: HealthStatusCategoryFilter = this
									.machinesFiltersService.machineHealthStatusCategoriesMap[healthStatusId];
								if (healthStatusCategory)
									return [
										...serializedValues,
										...healthStatusCategory.values.map(value => value.id),
									];
								else return [...serializedValues, healthStatusId];
							}, []),
						};
					},
					deserializeFilterValues: ({ healthStatuses }: SerializedFilters) => {
						if (!healthStatuses) return [];

						const selectedValues: Array<string> =
							healthStatuses instanceof Array ? healthStatuses : [healthStatuses];

						return selectedValues.reduce((deserializedValues, selectedValue) => {
							let healthStatusCategory: HealthStatusCategoryFilter = this.machinesFiltersService
								.machineHealthStatusCategoriesMap[selectedValue];

							if (!healthStatusCategory) {
								const category: [string, HealthStatusCategoryFilter] = Object.entries(
									this.machinesFiltersService.machineHealthStatusCategoriesMap
								).find(([categoryId, healthStatusCategoryFilter]) =>
									healthStatusCategoryFilter.values
										.map(value => value.id)
										.includes(selectedValue)
								);

								if (category) healthStatusCategory = category[1];
							}

							return [
								...deserializedValues,
								healthStatusCategory ? healthStatusCategory.id : selectedValue,
								...((healthStatusCategory &&
									healthStatusCategory.children &&
									healthStatusCategory.children.map(child => child.id)) ||
									[]),
							];
						}, []);
					},
				},
			},
			...(this.shouldDisplayNotManagedDevices
				? [
						{
							id: 'onBoardingStatuses',
							name: this.i18nService.strings
								.machines_dataView_fields_onboardingState_field_name,
							enabledByDefault: true,
							getDisplay: (machine: Machine) => {
								const { i18nNameKey } =
									onboardingStatusesMap[machine.onboardingStatus] ||
									onboardingStatusesMap[OnboardingStatus.InsufficientInfo];
								return this.i18nService.get(i18nNameKey);
							},
							filter: {
								priority: 2.5,
								component: {
									type: FilterValuesChecklistComponent,
									config: {
										mapFilterValue: (onBoardingId: string) => {
											const { id, i18nNameKey, priority } = onboardingStatusesMap[
												onBoardingId
											];
											return {
												id,
												priority,
												rawValue: id,
												name: this.i18nService.get(i18nNameKey),
											};
										},
									},
								},
							},
							sort: { enabled: false },
						},
				  ]
				: []),
			{
				id: 'lastseen',
				name: this.i18nService.get('machines.dataView.fields.lastSeen'),
				description: this.i18nService.get('machines_lastSeen_description'),
				component: {
					type: TzDateComponent,
					getProps: (machine: Machine) => ({ date: machine.lastSeen }),
				},
			},
			{
				id: 'firstseen',
				name: this.i18nService.strings.machines_entityDetails_fields_firstSeen_title,
				enabledByDefault: false,
				component: {
					type: TzDateComponent,
					getProps: (machine: Machine) => ({ date: machine.firstSeen }),
				},
				sort: { enabled: true },
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (range: FirstSeen): ListFilterValueConfig<string> => {
								const { id, priority, i18nNameKey } = firstSeenValuesMap[range];
								return {
									id,
									priority,
									rawValue: id,
									name: this.i18nService.get(i18nNameKey),
								};
							},
						},
					},
				},
			},
			!(
				this.featuresService.isEnabled(Feature.EndpointConfigManagementFe) &&
				this.featuresService.isEnabled(Feature.EndpointConfigManagement)
			) && !this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach)
				? null
				: {
						id: 'managedByList',
						name: this.i18nService.get('machines.dataView.fields.managedBy'),
						description: this.i18nService.get('machines.dataView.fields.managedBy.field.tooltip'),
						getDisplay: (machine: Machine) => {
							return machine.managedBy && this.i18nService.get(machine.managedBy.nameI18nKey);
						},
						sort: { enabled: false },
						filter: {
							component: {
								type: FilterValuesChecklistComponent,
								config: {
									mapFilterValue: (
										range: MachineManagedByType
									): ListFilterValueConfig<string> => {
										const { id, priority, nameI18nKey } = machineManagedByValuesMap[
											range
										];
										return {
											id,
											priority,
											rawValue: id,
											name: this.i18nService.get(nameI18nKey),
										};
									},
								},
							},
						},
				  },
			...(this.featuresService.isEnabled(Feature.ExcludedDevices)
				? [
						{
							id: 'exclusionStates',
							name: this.i18nService.strings.machines_dataView_fields_exclusionState_field_name,
							description: this.i18nService.strings
								.machines_dataView_fields_exclusionState_field_tooltip,
							enabledByDefault: true,
							getDisplay: (machine: Machine) => {
								const { i18nNameKey } =
									machineExclusionStateMap[machine.exclusionState] ||
									machineExclusionStateMap[MachineExclusionState.Included];
								return this.i18nService.get(i18nNameKey);
							},
							filter: {
								helpText: this.i18nService.strings
									.machines_dataView_fields_exclusionState_field_tooltip,
								priority: 4,
								component: {
									type: FilterValuesChecklistComponent,
									config: {
										mapFilterValue: (exclusionState: string) => {
											const { id, i18nNameKey, priority } = machineExclusionStateMap[
												exclusionState
											];
											return {
												id,
												priority,
												rawValue: id,
												name: this.i18nService.get(i18nNameKey),
											};
										},
									},
								},
							},
							sort: { enabled: false },
						},
				  ]
				: []),
			{
				id: 'tags',
				name: this.i18nService.get('machines.dataView.fields.tags'),
				minWidth: 300,
				component: {
					type: MachinesDataviewTagsComponent,
					getProps: (machine: Machine) => ({ machine: machine }),
				},
				sort: { enabled: false },
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (
								tag: string,
								value: FilterValuesChecklistValueData<
									string,
									{
										isUserDefined?: boolean;
										isBuiltIn?: boolean;
										filterType?: MachinesFilterType;
									}
								>
							): ListFilterValueConfig<string> => ({
								id: tag,
								nameClass: tag === null ? 'subtle' : 'tag',
								name: tag || this.i18nService.strings.machines_dataView_filter_untagged,
								priority: tag ? 2 : 1,
								data: value.custom,
							}),
						},
					},
					serializeFilterValues: (
						filterSelection: FilterValuesChecklistSelection<string>,
						defaultSerialized,
						listValues: ReadonlyArray<
							ListFilterValue<
								string,
								{
									isUserDefined?: boolean;
									isBuiltIn?: boolean;
									filterType?: MachinesFilterType;
								}
							>
						>
					) => {
						return this.machinesFiltersService.serializeFilterValues(filterSelection, listValues);
					},
					deserializeFilterValues: (serializedValues: SerializedFilters) => {
						return this.machinesFiltersService.deserializeFilterValues(serializedValues);
					},
				},
			},
			{
				id: 'securityPropertiesRequiringAttention',
				name: this.i18nService.get('machines.dataView.fields.antivirusStatus'),
				filter: {
					helpText: this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus)
						? this.i18nService.get('machines.dataView.antivirusStatusHelp.SCA')
						: this.i18nService.get('machines.dataView.antivirusStatusHelp'),
					priority: 3,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (
								status: string,
								data: FilterValuesChecklistValueData<string>
							): ListFilterValueConfig<string> => {
								return {
									id: data.value,
									rawValue: data.value,
									name: data.name,
								};
							},
						},
					},
				},
			},
			{
				id: 'threatCategory',
				name: this.i18nService.get('machines.dataView.fields.malwareCategoryAlerts'),
			},
			{ id: 'rbacGroupIds', name: this.i18nService.get('machines.dataView.fields.rbacGroupId') },
			{
				id: 'vulnerabilitySeverityLevels',
				filterOnly: true,
				name: this.i18nService.get('machines.dataView.fields.vulnerabilitySeverityLevel'),
				filter: {
					priority: 6,
					isHidden: true,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (
								vulnerabilitySeverityLevelId: string
							): ListFilterValueConfig<string> => {
								const vulnerabilitySeverityLevel = keyBy(
									(<DataEntityType>MachineVulnerabilitySeverityLevel).entityConfig.values,
									'id'
								)[vulnerabilitySeverityLevelId];
								return {
									id: vulnerabilitySeverityLevel.id,
									rawValue: vulnerabilitySeverityLevel.id,
									name: this.i18nService.get(vulnerabilitySeverityLevel.nameI18nKey),
									priority: vulnerabilitySeverityLevel.priority,
								};
							},
						},
					},
				},
			},
			{
				id: 'vulnerabilityAgeLevels',
				filterOnly: true,
				name: this.i18nService.get('machines.dataView.fields.vulnerabilityAgeLevel'),
				filter: {
					priority: 7,
					isHidden: true,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (
								vulnerabilityAgeLevelId: string
							): ListFilterValueConfig<string> => {
								const vulnerabilityAgeLevel = keyBy(
									(<DataEntityType>MachineVulnerabilityAgeLevel).entityConfig.values,
									'id'
								)[vulnerabilityAgeLevelId];
								return {
									id: vulnerabilityAgeLevel.id,
									rawValue: vulnerabilityAgeLevel.id,
									name: this.i18nService.get(vulnerabilityAgeLevel.nameI18nKey),
									priority: vulnerabilityAgeLevel.priority,
								};
							},
						},
					},
				},
			},
			{
				id: 'exploitLevels',
				filterOnly: true,
				name: this.i18nService.get('machines.dataView.fields.exploitLevel'),
				filter: {
					priority: 8,
					isHidden: true,
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (exploitLevelId: string): ListFilterValueConfig<string> => {
								const exploitLevel = keyBy(
									(<DataEntityType>MachineExploitLevel).entityConfig.values,
									'id'
								)[exploitLevelId];
								return {
									id: exploitLevel.id,
									rawValue: exploitLevel.id,
									name: this.i18nService.get(exploitLevel.nameI18nKey),
									priority: exploitLevel.priority,
								};
							},
						},
					},
				},
			},
			...(!this.featuresService.isEnabled(Feature.ThreatAnalyticsTimnaMitigations)
				? [
						{
							id: 'mitigationTypes',
							name: this.i18nService.get('machines.dataView.fields.threatMitigationStatus'),
							filterOnly: true,
							filter: {
								priority: 4,
								requiresData: false,
								component: {
									type: FilterValuesDropdownComboComponent,
									config: {
										dropdown: {
											placeholder: this.i18nService.get(
												'machines.outbreakFilterPlaceholder'
											),
											label: 'displayName',
											getValues: () =>
												this.paris
													.getRepository<Outbreak>(Outbreak)
													.allItems$.pipe(
														map((allOutbreaks: Array<Outbreak>) =>
															allOutbreaks.filter(
																o =>
																	o &&
																	o.mitigationTypes &&
																	o.mitigationTypes.length > 0
															)
														)
													),
										},
										getValues: (outbreak: Outbreak) => [
											{
												values: [
													...outbreak.mitigationTypes,
													OutbreakMitigationType[OutbreakMitigationType.Protected],
												].map((mitigationType: OutbreakMitigationType) => ({
													value: OutbreakMitigationType[mitigationType].toString(),
													rawValue: mitigationType,
													name: this.i18nService.get(
														`machines.mitigationFilters.${mitigationType}`
													),
													priority: OutbreakMitigationType[mitigationType],
												})),
											},
										],
									},
								},
								serializeFilterValues: (
									selectedValues: FilterValuesDropdownComboSelection<
										number,
										{ id: string; name: string }
									>,
									defaultSerialized: { mitigationTypes: Array<string> }
								): SerializedFilters => {
									const serializedValues = selectedValues.dropdown
										? {
												...defaultSerialized,
												outbreakId: selectedValues.dropdown.id,
										  }
										: null;

									return serializedValues;
								},
								deserializeFilterValues: (
									serializedValues: SerializedFilters
								): Observable<FilterValuesDropdownComboSelection<string, Outbreak>> => {
									if (serializedValues) {
										const { mitigationTypes, outbreakId } = serializedValues;

										if (mitigationTypes && outbreakId) {
											return this.paris.getItemById(Outbreak, <string>outbreakId).pipe(
												map((outbreak: Outbreak) => {
													return {
														dropdown: outbreak,
														checklist:
															mitigationTypes instanceof Array
																? mitigationTypes
																: [mitigationTypes],
													};
												})
											);
										}
									}

									return null;
								},
							},
						},
				  ]
				: []),
		];
	}

	private shouldDisplayTvmBasicFields() {
		const tvmLicensesEnabled = this.featuresService.isEnabled(Feature.TvmPremium);
		const isEnabledByFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
		const isEnabledByTvmLicenses =
			this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic) ||
			// elkamin: delete this code once the nibiru code is in
			this.flavorService.isEnabled({ mdeFlavors: ['Smb'] });
		return tvmLicensesEnabled ? isEnabledByTvmLicenses : isEnabledByFlavor;
	}
}
