import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { Vulnerability, WeaknessSeverity } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TzDateService } from '@wcd/localization';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken, InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { PrettyNumberService } from '@wcd/prettify';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';

const MACHINE_VULNERABILITY_FIELDS = [
	'severity',
	'cvssV3',
	'productIds',
	'publishedOn',
	'updatedOn',
	'threats',
];

const DEFAULT_SIDE_PANEL_FIELDS = ['name', 'severity', 'numOfImpactedAssets'];

@Injectable()
export class VulnerabilityFieldsService {
	private _fields: Array<DataviewField<Vulnerability>>;
	private zeroDayEnabled: boolean;

	private _nameField: DataviewFieldConfig = {
		id: 'name',
		name: this.i18nService.get('tvm.common.name'),
		component: {
			type: TaggedFieldComponent,
			getProps: (vulnerability: Vulnerability) =>
				this.tvmTaggedFieldService.getZeroDayBaseProps(
					vulnerability.mostSevereVulnerabilityType,
					vulnerability.patchReleaseDate,
					vulnerability.name
				),
		},
	};

	private _nameFieldWithInaccuracyContext = {
		...this._nameField,
		component: {
			...this._nameField.component,
			getProps: (vulnerability: Vulnerability) => ({
				...this._nameField.component.getProps(vulnerability),
				inaccuracyContext: InaccuracyContext.MachineVulnerabilities,
				contextObject: vulnerability,
				title: vulnerability.name,
			}),
		},
	};

	private _severityField: DataviewFieldConfig = {
		id: 'severity',
		name: this.i18nService.get('tvm.common.severity'),
		getDisplay: (vulnerability: Vulnerability) =>
			[WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(vulnerability.severity)
				? this.i18nService.get('common.noDataAvailable')
				: vulnerability.severity,
		icon: {
			fabricIcon: (vulnerability: Vulnerability) =>
				[WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(vulnerability.severity)
					? null
					: FabricIconNames.Warning,
			className: (vulnerability: Vulnerability) =>
				this.tvmWeaknessSeverityService.getWeaknessColorClass(vulnerability.severity),
		},
		sort: {
			sortDescendingByDefault: true,
		},
	};

	private _impactedAssetsField: DataviewFieldConfig = {
		id: 'numOfImpactedAssets',
		name: this.i18nService.get('tvm.common.exposedDevices'),
		getDisplay: (vulnerability: Vulnerability) =>
			Number.isInteger(vulnerability.impactedAssetsCount)
				? this.prettyNumberService.prettyNumber(vulnerability.impactedAssetsCount)
				: this.i18nService.get('notAvailable.long'),
		sort: {
			sortDescendingByDefault: true,
		},
	};

	private _threatsField: DataviewFieldConfig = {
		id: 'threats',
		name: this.i18nService.get('tvm.common.threats'),
		sort: {
			enabled: false,
		},
		component: {
			type: TvmThreatIconsComponent,
			getProps: (vulnerability: Vulnerability) =>
				this.tvmIconBuilderService.configureIcons(
					vulnerability.threatInfo,
					null,
					'vulnerability',
					false,
					null,
					true
				),
		},
	};

	get defaultSidePanelFieldsConfig(): Array<DataviewFieldConfig> {
		return [this._nameField, this._severityField, this._impactedAssetsField];
	}

	get changeEventFieldsConfig(): Array<DataviewFieldConfig> {
		return [this._nameField, this._severityField, this._threatsField];
	}

	constructor(
		private prettyNumberService: PrettyNumberService,
		private tvmWeaknessSeverityService: TvmWeaknessSeverityService,
		private tzDateService: TzDateService,
		private i18nService: I18nService,
		private tvmTextsService: TvmTextsService,
		private tvmIconBuilderService: TvmIconBuilderService,
		private tvmTagsService: TvmTagsService,
		private tvmTaggedFieldService: TvmTaggedFieldService,
		featuresService: FeaturesService
	) {
		this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
	}

	get fields(): Array<DataviewField<Vulnerability>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<Vulnerability>([
				this._nameField,
				this._severityField,
				{
					id: 'cvssV3',
					name: this.i18nService.get('tvm.common.cvss'), //TODO: or/barak - preparing for new API: cvss + version fields. cvssV3 field will get deprecated.
					getDisplay: (vulnerability: Vulnerability) =>
						vulnerability.cvss || this.i18nService.get('notAvailable.short'),
				},
				{
					id: 'productIds',
					name: this.i18nService.get('tvm.vulnerability.relatedSoftware'),
					getDisplay: (vulnerability: Vulnerability) =>
						this.tvmTextsService.getText(TextToken.RelatedSoftware, vulnerability),
					getTooltip: (vulnerability: Vulnerability) =>
						this.tvmTextsService.getText(TextToken.RelatedSoftwareTooltip, vulnerability),
					valueTooltipAllowHtmlRendering: true,
					sort: {
						enabled: false,
					},
				},
				{
					id: 'age',
					name: this.i18nService.get('tvm.vulnerability.age'),
					getDisplay: (vulnerability: Vulnerability) =>
						this.tvmTextsService.getText(TextToken.VulnerabilityAge, vulnerability),
				},
				{
					id: 'publishedOn',
					name: this.i18nService.get('tvm.vulnerability.publishedOn'),
					getDisplay: (vulnerability: Vulnerability) =>
						this.tzDateService.format(vulnerability.published, 'shortDate'),
				},
				{
					id: 'updatedOn',
					name: this.i18nService.get('tvm.vulnerability.updatedOn'),
					getDisplay: (vulnerability: Vulnerability) =>
						this.tzDateService.format(vulnerability.updated, 'shortDate'),
				},
				this._threatsField,
				this._impactedAssetsField,
				this.zeroDayEnabled
					? {
							id: 'tags',
							name: this.i18nService.get('common.tags'),
							component: {
								type: TagsFieldComponent,
								getProps: (vulnerability: Vulnerability) => ({
									tags: this.tvmTagsService.getVulnerabilityTags(vulnerability),
								}),
							},
							sort: { enabled: false },
					  }
					: null,
			]).filter(Boolean);
		}

		return this._fields;
	}

	createClickableNameField(onNameClick: (v: Vulnerability) => any) {
		return {
			...this._nameField,
			component: {
				type: this._nameField.component.type,
				getProps: (vulnerability: Vulnerability) => ({
					...this._nameField.component.getProps(vulnerability),
					onClick: () => onNameClick(vulnerability),
				}),
			},
		};
	}

	get defaultSidePanelFields(): Array<DataviewField<Vulnerability>> {
		return this.fields.filter(field => DEFAULT_SIDE_PANEL_FIELDS.includes(field.id));
	}

	get machineVulnerabilityFields(): Array<DataviewField<Vulnerability>> {
		return [
			DataviewField.fromList<Vulnerability>([this._nameFieldWithInaccuracyContext])[0],
			...this.fields.filter(field => MACHINE_VULNERABILITY_FIELDS.includes(field.id)),
		];
	}
}
