import { DataviewField } from '@wcd/dataview';
import { SystemExclusionListType } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { FilterValuesDatePickerComponent, FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
var SystemExclusionBaseFields = /** @class */ (function () {
    function SystemExclusionBaseFields(paris, i18nService) {
        this.paris = paris;
        this.i18nService = i18nService;
    }
    Object.defineProperty(SystemExclusionBaseFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'acl_type',
                        name: this.i18nService.strings.systemExclusions_fields_listType,
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (listTypeId) {
                                        var listType = _this.paris.getValue(SystemExclusionListType, parseInt(listTypeId, 10));
                                        if (listType) {
                                            return {
                                                name: listType.name,
                                                id: listType.id,
                                            };
                                        }
                                        return null;
                                    },
                                },
                            },
                        },
                        getDisplay: function (item) { return item.listType.name; },
                        className: 'nowrap',
                    },
                    {
                        id: 'creation_time',
                        name: this.i18nService.strings.systemExclusions_fields_creationTime,
                        sort: { sortDescendingByDefault: true },
                        filter: {
                            component: {
                                type: FilterValuesDatePickerComponent,
                            },
                        },
                        component: {
                            type: TzDateComponent,
                            getProps: function (systemExclusion) { return ({
                                date: systemExclusion.creationTime,
                            }); },
                        },
                    },
                    {
                        id: 'creator_name',
                        name: this.i18nService.strings.systemExclusions_fields_creatorName,
                        getDisplay: function (item) { return item.creatorName; },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return SystemExclusionBaseFields;
}());
export { SystemExclusionBaseFields };
