var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { CertificateInfo } from '../entity/certificate-info.value-object';
import { FileAppType } from './file-app-type.entity';
import { fileAppTypesIndex } from './file-app-type.values';
import { FileDeterminationType } from './file.determination-type.enum';
import { MatchType } from './file.match-type.enum';
import { FileType } from './file.type.enum';
import { DataSensitivity } from './data-sensitivity.value-object';
export var FileSearchCriterion;
(function (FileSearchCriterion) {
    FileSearchCriterion["Sha1"] = "Sha1";
    FileSearchCriterion["TopFileNames"] = "TopFileNames";
    FileSearchCriterion["Md5"] = "Md5";
    FileSearchCriterion["Sha256"] = "Sha256";
})(FileSearchCriterion || (FileSearchCriterion = {}));
export var CertificateStatus;
(function (CertificateStatus) {
    CertificateStatus[CertificateStatus["Unknown"] = 0] = "Unknown";
    CertificateStatus[CertificateStatus["Valid"] = 1] = "Valid";
    CertificateStatus[CertificateStatus["Expired"] = 2] = "Expired";
    CertificateStatus[CertificateStatus["Revoked"] = 3] = "Revoked";
})(CertificateStatus || (CertificateStatus = {}));
// There is a bug in minifying decorators and static properties, moving the function out of the decorator solves this
export var parseFileItemQuery = function (itemId, entity, config, params) {
    var searchTerm = String(itemId).split(';name=')[0];
    var searchByParam = !params ||
        !params[File.fileSearchByParamName] ||
        !(params[File.fileSearchByParamName] in FileSearchCriterion)
        ? FileSearchCriterion.Sha1
        : params[File.fileSearchByParamName];
    return params['newFilesApi'] ? "profiles/files/" + searchTerm : "files?searchBy=" + searchByParam + "&searchTerm=" + searchTerm;
};
var ɵ0 = function (config, query) {
    if (query.where && query.where['searchBy'] === FileSearchCriterion.TopFileNames && query.where['newSearchApi']) {
        return 'files/search';
    }
    return 'files';
}, ɵ1 = function (config, query) {
    if (query.where && query.where['searchBy'] === FileSearchCriterion.TopFileNames && query.where['newSearchApi']) {
        return config.data.serviceUrls.fileprevalence; //resolves to entitypagesservice once the vnext migration is complete
    }
    // otherwise it belongs to cyberprofileservice (once vnext migration is complete) - newFilesApi: true --> use vnext
    return query.where && query.where['newFilesApi']
        ? config.data.serviceUrls.cyberProfileService
        : config.data.serviceUrls.threatIntel;
}, ɵ2 = ['Sha1', 'Sha256', 'id'], ɵ3 = ['FileName', 'Name', 'name'], ɵ4 = function (value) { return value || null; }, ɵ5 = function (value) { return value || null; }, ɵ6 = ['MaxTimestamp', 'LastObserved', 'LastSeen'], ɵ7 = ['MinTimestamp', 'FirstObserved'], ɵ8 = ['PrevalentThreatName', 'Threat', 'ThreatName'], ɵ9 = function (value) { return value || null; }, ɵ10 = ['FileName'], ɵ11 = function (fileName) {
    if (!fileName)
        return null;
    var fileExtension = fileName.match(/\.(\w+)$/);
    var appType = fileExtension && fileAppTypesIndex[fileExtension[1].toLowerCase()];
    return appType && appType.id;
}, ɵ12 = function (rawData) {
    return (rawData.Sha1 || rawData.Sha256 || rawData.id) + "-" + (rawData.FileName || rawData.Name || rawData.name);
};
var File = /** @class */ (function (_super) {
    __extends(File, _super);
    function File() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(File.prototype, "name", {
        get: function () {
            return this.fileName;
        },
        set: function (name) {
            this.fileName = name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(File.prototype, "fileId", {
        get: function () {
            return this.id;
        },
        enumerable: true,
        configurable: true
    });
    File.fileSearchByParamName = 'searchBy';
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", String)
    ], File.prototype, "id", void 0);
    __decorate([
        EntityField({
            data: ɵ3,
        }),
        __metadata("design:type", String)
    ], File.prototype, "fileName", void 0);
    __decorate([
        EntityField({ data: 'MatchType' }),
        __metadata("design:type", String)
    ], File.prototype, "matchType", void 0);
    __decorate([
        EntityField({
            data: 'Md5',
            parse: ɵ4,
        }),
        __metadata("design:type", String)
    ], File.prototype, "md5", void 0);
    __decorate([
        EntityField({ data: 'Sha1' }),
        __metadata("design:type", String)
    ], File.prototype, "sha1", void 0);
    __decorate([
        EntityField({
            data: 'Sha256',
            parse: ɵ5,
        }),
        __metadata("design:type", String)
    ], File.prototype, "sha256", void 0);
    __decorate([
        EntityField({
            data: ɵ6,
        }),
        __metadata("design:type", Date)
    ], File.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({
            data: ɵ7,
        }),
        __metadata("design:type", Date)
    ], File.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'TelemetryPrevalence' }),
        __metadata("design:type", Number)
    ], File.prototype, "estimatedWorldwidePrevalence", void 0);
    __decorate([
        EntityField({
            data: ɵ8,
            parse: ɵ9,
        }),
        __metadata("design:type", String)
    ], File.prototype, "prevalentThreatName", void 0);
    __decorate([
        EntityField({ data: 'Size' }),
        __metadata("design:type", Number)
    ], File.prototype, "size", void 0);
    __decorate([
        EntityField({ data: 'FileType' }),
        __metadata("design:type", String)
    ], File.prototype, "fileType", void 0);
    __decorate([
        EntityField({ data: 'IsPeFile' }),
        __metadata("design:type", Boolean)
    ], File.prototype, "isPeFile", void 0);
    __decorate([
        EntityField({ data: 'CertificateInfo' }),
        __metadata("design:type", CertificateInfo)
    ], File.prototype, "certificateInfo", void 0);
    __decorate([
        EntityField({ data: 'Signer' }),
        __metadata("design:type", String)
    ], File.prototype, "signer", void 0);
    __decorate([
        EntityField({ data: 'Issuer' }),
        __metadata("design:type", String)
    ], File.prototype, "issuer", void 0);
    __decorate([
        EntityField({ data: 'SignerHash' }),
        __metadata("design:type", String)
    ], File.prototype, "signerHash", void 0);
    __decorate([
        EntityField({ data: 'IsValidCertificate' }),
        __metadata("design:type", Boolean)
    ], File.prototype, "isValidCertificate", void 0);
    __decorate([
        EntityField({ data: 'IsCertificateDataPopulated' }),
        __metadata("design:type", Boolean)
    ], File.prototype, "isCertificateDataPopulated", void 0);
    __decorate([
        EntityField({ data: 'IsMicrosoftRoot' }),
        __metadata("design:type", Boolean)
    ], File.prototype, "isMicrosoftRoot", void 0);
    __decorate([
        EntityField({ data: 'DeterminationValue' }),
        __metadata("design:type", String)
    ], File.prototype, "determinationValue", void 0);
    __decorate([
        EntityField({ data: 'DeterminationType' }),
        __metadata("design:type", Number)
    ], File.prototype, "determinationType", void 0);
    __decorate([
        EntityField({ data: 'EntityName' }),
        __metadata("design:type", String)
    ], File.prototype, "entityName", void 0);
    __decorate([
        EntityField({ data: 'FileDescription' }),
        __metadata("design:type", String)
    ], File.prototype, "fileDescription", void 0);
    __decorate([
        EntityField({
            data: ɵ10,
            parse: ɵ11,
        }),
        __metadata("design:type", FileAppType)
    ], File.prototype, "appType", void 0);
    __decorate([
        EntityField({ data: 'Sensitivity' }),
        __metadata("design:type", DataSensitivity)
    ], File.prototype, "sensitivity", void 0);
    __decorate([
        EntityField({ data: 'IsWindowsInfoProtectionApplied' }),
        __metadata("design:type", Boolean)
    ], File.prototype, "isWindowsInfoProtectionApplied", void 0);
    __decorate([
        EntityField({ data: 'BlacklistStatus' }),
        __metadata("design:type", String)
    ], File.prototype, "blacklistStatus", void 0);
    __decorate([
        EntityField({ data: 'FilePageUrl', required: false }),
        __metadata("design:type", String)
    ], File.prototype, "externalFilePageUrl", void 0);
    __decorate([
        EntityField({ data: 'FileOriginalName', required: false }),
        __metadata("design:type", String)
    ], File.prototype, "originalName", void 0);
    __decorate([
        EntityField({ data: 'FilePublisher', required: false }),
        __metadata("design:type", String)
    ], File.prototype, "publisher", void 0);
    __decorate([
        EntityField({ data: 'CompanyName', required: false }),
        __metadata("design:type", String)
    ], File.prototype, "companyName", void 0);
    __decorate([
        EntityField({ data: 'FileProductName', required: false }),
        __metadata("design:type", String)
    ], File.prototype, "productName", void 0);
    __decorate([
        EntityField({ data: 'CertificateStatus' }),
        __metadata("design:type", Number)
    ], File.prototype, "certificateStatus", void 0);
    __decorate([
        EntityField({
            data: "__self",
            parse: ɵ12,
        }),
        __metadata("design:type", String)
    ], File.prototype, "searchUniqueId", void 0);
    File = __decorate([
        Entity({
            singularName: 'File',
            pluralName: 'Files',
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            readonly: true,
            endpoint: ɵ0,
            parseItemQuery: parseFileItemQuery,
            baseUrl: ɵ1,
        })
    ], File);
    return File;
}(EntityModelBase));
export { File };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12 };
