import { Asset } from '@wcd/domain';
import { TvmRecommendationContextService } from '../../../../tvm/services/tvm-recommendation-context.service';
var RecommendationContextComponent = /** @class */ (function () {
    function RecommendationContextComponent(recommendationContextService) {
        this.recommendationContextService = recommendationContextService;
        this.contextTableHeaders = [];
    }
    Object.defineProperty(RecommendationContextComponent.prototype, "asset", {
        set: function (asset) {
            this._asset = asset;
            this.recommendationContextType = this.recommendationContextService.getRecommendationContextTypeByAsset(asset);
            this.contextTableHeaders = this.recommendationContextService.getRecommendationContextTableHeadersByType(this.recommendationContextType);
            this.recommendationContextIcon = this.recommendationContextService.getRecommendationContextKeyIconNameByType(this.recommendationContextType);
        },
        enumerable: true,
        configurable: true
    });
    return RecommendationContextComponent;
}());
export { RecommendationContextComponent };
