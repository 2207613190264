import { Directive, ElementRef, HostListener } from '@angular/core';

const WARNING_TEXT = 'Caps Lock is on';

@Directive({
	selector: "input[type='password']",
})
export class PasswordInputDirective {
	private errorEl: HTMLDivElement;

	constructor(private elementRef: ElementRef) {}

	@HostListener('keyup', ['$event'])
	onKeyUp(e: KeyboardEvent) {
		if (e.key && /[a-z]/i.test(e.key) && e.key === e.key.toUpperCase() && !e.shiftKey) this.setWarning();
		else this.hideWarning();
	}

	private setWarning() {
		if (!this.errorEl) {
			this.errorEl = document.createElement('div');
			this.errorEl.className = 'error-message';
			this.errorEl.innerText = WARNING_TEXT;
			this.elementRef.nativeElement.parentNode.insertBefore(
				this.errorEl,
				this.elementRef.nativeElement.nextSibling
			);
		} else this.errorEl.style.display = 'block';
	}

	private hideWarning() {
		if (this.errorEl) this.errorEl.style.display = 'none';
	}
}
