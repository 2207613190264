var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// tslint:disable:template-click-events-have-key-events
import { EventEmitter, OnDestroy, OnInit, ChangeDetectorRef, SecurityContext, } from '@angular/core';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { combineLatest } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { File, FileCurrentInstancesRelationship, FileFileStatsRelationship, FileQuarantineApiCall, } from '@wcd/domain';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { DimensionsModel } from '../../../../dialogs/models/dimensions.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { FilesService } from '../../services/files.service';
import { FileActionsService } from '../../services/file.actions.service';
import { WicdSanitizerService } from '@wcd/shared';
import { FeaturesService } from '@wcd/config';
var MIN_ORG_PREVALENCE_TO_DISPLAY_WARNING = 20;
var MAX_FILE_NAMES_TO_DISPLAY = 4;
var QuarantineFileModalComponent = /** @class */ (function (_super) {
    __extends(QuarantineFileModalComponent, _super);
    function QuarantineFileModalComponent(router, paris, i18nService, filesService, fileActionsService, dialogsService, changeDetectorRef, domSanitizer, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.filesService = filesService;
        _this.fileActionsService = fileActionsService;
        _this.dialogsService = dialogsService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.domSanitizer = domSanitizer;
        _this.featuresService = featuresService;
        _this.isSaving = false;
        _this.openActionCenterOnSubmit = false;
        _this.onConfirm = new EventEmitter();
        _this.onCancel = new EventEmitter();
        _this.loading = false;
        _this.showHighOrgPrevalenceWarning = false;
        _this.sanitize = function (text) { return _this.domSanitizer.sanitize(SecurityContext.HTML, text); };
        return _this;
    }
    QuarantineFileModalComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.settings = __assign({}, this.settings, { className: 'wcd-flex-vertical', title: this.i18nService.get("file.quarantine.title"), dimensions: new DimensionsModel(640, 540) });
        this.loading = true;
        var filesPrefix = this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw');
        var fileCurrentInstances$ = this.paris.getRelatedItem(FileCurrentInstancesRelationship, this.file, { where: { filesPrefix: filesPrefix } });
        var fileStats$ = this.paris.getRelatedItem(FileFileStatsRelationship, this.file, { where: { filesPrefix: filesPrefix } });
        this._fileStatsAndInstancesSubscription = combineLatest(fileCurrentInstances$, fileStats$).subscribe(function (_a) {
            var instanceCount = _a[0], fileStats = _a[1];
            _this.setFileStatistics(instanceCount, fileStats);
            _this.loading = false;
            _this.changeDetectorRef.detectChanges();
        }, function (error) {
            _this.loading = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('file.quarantine.details.errors.failedLoading'),
                data: error,
            });
            _this.changeDetectorRef.detectChanges();
        });
    };
    QuarantineFileModalComponent.prototype.ngOnDestroy = function () {
        this._fileStatsAndInstancesSubscription && this._fileStatsAndInstancesSubscription.unsubscribe();
    };
    QuarantineFileModalComponent.prototype.onConfirmHandler = function () {
        this.onConfirm.emit({});
        this.onConfirm.complete();
    };
    QuarantineFileModalComponent.prototype.onCancelHandler = function () {
        this.onCancel.emit();
        this.onCancel.complete();
    };
    QuarantineFileModalComponent.prototype.submit = function () {
        var _this = this;
        this.isSaving = true;
        this.paris
            .apiCall(FileQuarantineApiCall, {
            file: this.file,
            reason: this.reason,
        })
            .pipe(finalize(function () {
            _this.isSaving = false;
            _this.onConfirmHandler();
        }))
            .subscribe(function () {
            if (_this.openActionCenterOnSubmit)
                _this.filesService.showFileActionCenter(_this.file);
        });
    };
    QuarantineFileModalComponent.prototype.downloadMachineListCsv = function () {
        this.fileActionsService.downloadFileObservedMachinesCsv(this.file.sha1);
    };
    QuarantineFileModalComponent.prototype.setFileStatistics = function (instanceCount, fileStats) {
        this.fileInstanceCount = instanceCount;
        this.fileStats = fileStats;
        this.showHighOrgPrevalenceWarning =
            instanceCount.machineCount > MIN_ORG_PREVALENCE_TO_DISPLAY_WARNING;
        this.setTopFileNamesHtmlForTooltip(fileStats.topFileNames);
    };
    QuarantineFileModalComponent.prototype.setTopFileNamesHtmlForTooltip = function (topFileNames) {
        var _this = this;
        var topNamesHtml = topFileNames
            .slice(0, Math.min(MAX_FILE_NAMES_TO_DISPLAY, topFileNames.length))
            .reduce(function (preValue, currValue) {
            return preValue ? _this.sanitize(preValue) + "<br>" + _this.sanitize(currValue) : _this.sanitize(currValue);
        });
        this.topFileNamesHtmlTooltip =
            topFileNames.length > MAX_FILE_NAMES_TO_DISPLAY
                ? this.i18nService.get('file.quarantine.details.topFileNamesTooltip', {
                    names: topNamesHtml,
                    amount: topFileNames.length - MAX_FILE_NAMES_TO_DISPLAY,
                })
                : topNamesHtml;
    };
    return QuarantineFileModalComponent;
}(ModalContainer));
export { QuarantineFileModalComponent };
