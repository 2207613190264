import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SharedModule } from '../../shared/shared.module';
import { MachineGroupsDataviewComponent } from './components/machine_groups/machine-groups.dataview';
import { UserRolesDataviewComponent } from './components/user_roles/user-roles.dataview';
import {
	MachineGroupEditEnhancedComponent,
} from './components/machine_groups/machine-group-edit-enhanced.component';
import { UserRoleEditComponent } from './components/user_roles/user-role-edit.component';
import { UserRoleEditPanelComponent } from './components/user_roles/user-role-edit.panel.component';
import { UserRoleActionsService } from './services/user-role-actions.service';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { UserRolePermissionsComponent } from './components/user_roles/user-role-permissions.component';
import { TooltipsModule } from '@wcd/dialogs';
import { MachineGroupsRemediationLevelDataviewComponent } from './components/machine_groups/machine-groups-remediation-level.dataview';
import { MachineGroupRemediationLevelEditPanelComponent } from './components/machine_groups/machine-group-remediation-level-edit.panel.component';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { MachineGroupEntityTypeService } from './services/machine-group.entity-type.service';
import { FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import { MachineGroupEditEnhancedPanelComponent } from './components/machine_groups/machine-group-edit-enhanced.panel.component';
import { AadGroupsAssignmentTab } from './components/shared/aad-groups-assignment-tab';

@NgModule({
	imports: [SharedModule, DataViewsModule, PanelsModule, WcdFormsModule, TooltipsModule, FabMessageBarModule, FabSpinnerModule],
	declarations: [
		AadGroupsAssignmentTab,
		MachineGroupsDataviewComponent,
		UserRolesDataviewComponent,
		MachineGroupEditEnhancedComponent,
		MachineGroupEditEnhancedPanelComponent,
		MachineGroupRemediationLevelEditPanelComponent,
		UserRoleEditComponent,
		UserRoleEditPanelComponent,
		UserRolePermissionsComponent,
		MachineGroupsRemediationLevelDataviewComponent,
	],
	providers: [UserRoleActionsService, MachineGroupEntityTypeService],
	exports: [
		AadGroupsAssignmentTab,
		MachineGroupsRemediationLevelDataviewComponent,
		UserRolesDataviewComponent,
		MachineGroupEditEnhancedPanelComponent,
		UserRoleEditPanelComponent,
	],
	entryComponents: [
		MachineGroupEditEnhancedPanelComponent,
		MachineGroupRemediationLevelEditPanelComponent,
		UserRoleEditPanelComponent,
	],
})
export class RbacEntitiesModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(MachineGroupEntityTypeService);
	}
}
