import { Injectable, OnDestroy } from '@angular/core';
import { combineLatest, fromEvent, merge, Observable, of, Subscription, timer, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';

const possibleHiddenProperties = [
	{ hidden: 'hidden', visibilityChange: 'visibilitychange' },
	{ hidden: 'webkitHidden', visibilityChange: 'webkitvisibilitychange' },
	{ hidden: 'msHidden', visibilityChange: 'msvisibilitychange' },
	{ hidden: 'mozHidden', visibilityChange: 'mozvisibilitychange' },
];

const hiddenData = possibleHiddenProperties.find(hiddenObj => document[hiddenObj.hidden] !== undefined);
const hiddenProperty: string = hiddenData.hidden;
const visibilityChangeProperty: string = hiddenData.visibilityChange;

@Injectable({ providedIn: 'root' })
export class PollingService implements OnDestroy {
	private destroy$: Subject<boolean> = new Subject<boolean>();
	/**
	 * Creates a polling observable that only emits when the document is in view
	 * (not minimized and is the active tab). If the doc is not in view for a while (longer than the interval period),
	 * then once it regains focus, the observable will emit immediately.
	 *
	 * @param start {number} how long from now to start polling (in milliseconds)
	 * @param interval {number} polling frequency (in milliseconds)
	 * @returns {Observable<number>}
	 */
	poll(start: number = 0, interval: number): Observable<number> {
		return new Observable(observer => {
			const timer$ = timer(start, interval);
			const focus$ = merge(fromEvent(document, visibilityChangeProperty), of(null));
			let handledIndex: number,
				handledAt: number,
				currentEmission: number = 0,
				nextEmissionSubscription: Subscription;

			const subscription: Subscription = combineLatest(timer$, focus$)
				.pipe(
					takeUntil(this.destroy$),
					tap((combinedValue: [number, Event]) => {
						// stop emissions if document is hidden
						if (document[hiddenProperty]) {
							nextEmissionSubscription && nextEmissionSubscription.unsubscribe();
							return;
						}
						const timerIndex: number = combinedValue[0];
						// this index was already handled
						if (handledIndex && handledIndex === timerIndex) return;

						// handle index
						handledIndex = timerIndex;

						// map to a new delayed timer
						nextEmissionSubscription = timer(
							handledAt ? interval - (Date.now() - handledAt) : 0
						).subscribe(
							() => {
								observer.next(currentEmission);
								currentEmission++;
								handledAt = Date.now();
							},
							err => observer.error(err)
						);
					})
				)
				.subscribe();
			return () => {
				nextEmissionSubscription && nextEmissionSubscription.unsubscribe();
				subscription && subscription.unsubscribe();
			};
		});
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
