import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Vulnerability } from '@wcd/domain';
import { Router } from '@angular/router';

@Component({
	selector: 'vulnerability-entity-panel',
	styleUrls: ['./vulnerability.entity-panel.component.scss'],
	templateUrl: './vulnerability.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class VulnerabilityEntityPanelComponent extends EntityPanelComponentBase<
	Vulnerability,
	TvmContextOptions
> {
	get vulnerability(): Vulnerability {
		return this.entity;
	}

	get contextOptions(): TvmContextOptions {
		return this.options;
	}

	constructor(changeDetectorRef: ChangeDetectorRef, private router: Router) {
		super(changeDetectorRef);
	}

	initEntity(vulnerability: Vulnerability, isExtendedData: boolean = false) {
		super.initEntity(vulnerability, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}

	navigateToSecurityRecommendation() {
		this.router.navigate(['/security-recommendations'], {
			queryParams: {
				search: this.entity.name,
			},
		});
	}
}

export interface TvmContextOptions {
	isSingleAsset?: boolean;
	machineId?: string;
	isNetworkGear?: boolean;
}