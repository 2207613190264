import { Component, Input, OnInit } from '@angular/core';
import { Alert, TreeElementAlertContext, TreeElementCyberEntityTypeEnum } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { AlertPageService, detailsTabSupportedEntityTypes } from '../../services/alert-page.service';
import { EntityModelBase, Paris } from '@microsoft/paris';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { BehaviorSubject } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

@Component({
	selector: 'associated-alert',
	templateUrl: './associated-alert.component.html',
	styleUrls: ['./associated-alert.component.scss'],
})
export class AssociatedAlertComponent implements OnInit {
	@Input() alertContext: TreeElementAlertContext;

	readonly TreeElementCyberEntityTypeEnum = TreeElementCyberEntityTypeEnum;
	readonly icon: string;
	readonly alertType: EntityType<EntityModelBase>;

	mainAlertPageId$: BehaviorSubject<string>;
	treeElementsUpdates$: BehaviorSubject<string>;

	constructor(
		readonly globalEntityTypesService: GlobalEntityTypesService,
		readonly alertPageService: AlertPageService,
		private readonly paris: Paris
	) {
		this.icon = this.globalEntityTypesService.getEntityTypeIcon(Alert);
		this.alertType = globalEntityTypesService.getEntityType(
			detailsTabSupportedEntityTypes[TreeElementCyberEntityTypeEnum.alert]
		);
		this.mainAlertPageId$ = this.alertPageService.mainAlertPageId$;
		this.treeElementsUpdates$ = this.alertPageService.treeElementsUpdates$;
	}

	ngOnInit() {
		this.treeElementsUpdates$
			.pipe(
				filter((alertId: string) => alertId == this.alertContext.alertId),
				switchMap(() => this.paris.getItemById(Alert, this.alertContext.alertId))
			)
			.subscribe(alert => (this.alertContext.cyberEntity = alert));
	}

	toAlertEntity(cyberEntity: EntityModelBase): Alert {
		return cyberEntity instanceof Alert ? <Alert>cyberEntity : null;
	}
}
