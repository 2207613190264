/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hunting-record-property-display.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i3 from "@angular-react/fabric";
import * as i4 from "../../../../../shared/src/lib/components/route-link.component.ngfactory";
import * as i5 from "../../../../../shared/src/lib/components/route-link.component";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../shared/src/lib/services/routes.service";
import * as i8 from "@angular/common";
import * as i9 from "../../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i10 from "../../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i11 from "@angular/platform-browser";
import * as i12 from "../../../../../localization/src/lib/pipes/tz-date.pipe";
import * as i13 from "../../../../../localization/src/lib/services/tz-date.service";
import * as i14 from "../../../../../localization/src/lib/services/locale-config.service";
import * as i15 from "../../../../../datatable/src/lib/components/datatable.component.ngfactory";
import * as i16 from "../../../../../datatable/src/lib/components/datatable.component";
import * as i17 from "@angular/cdk/a11y";
import * as i18 from "../../../../../i18n/src/lib/services/i18n.service";
import * as i19 from "./hunting-record-property-display.component";
var styles_HuntingRecordPropertyDisplayComponent = [i0.styles];
var RenderType_HuntingRecordPropertyDisplayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HuntingRecordPropertyDisplayComponent, data: {} });
export { RenderType_HuntingRecordPropertyDisplayComponent as RenderType_HuntingRecordPropertyDisplayComponent };
function View_HuntingRecordPropertyDisplayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "hunting-property-cell__entity-icon"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fab-icon", [], null, null, null, i2.View_FabIconComponent_0, i2.RenderType_FabIconComponent)), i1.ɵdid(3, 5816320, null, 0, i3.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnInfo.icon; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HuntingRecordPropertyDisplayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HuntingRecordPropertyDisplayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "route-link", [["class", "hunting-property-cell__link"], ["rel", "noopener"]], null, null, null, i4.View_RouteLinkComponent_0, i4.RenderType_RouteLinkComponent)), i1.ɵdid(1, 573440, null, 0, i5.RouteLinkComponent, [i6.UrlHandlingStrategy, i6.Router, i7.RoutesService], { routeLink: [0, "routeLink"], linkAriaLabel: [1, "linkAriaLabel"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_HuntingRecordPropertyDisplayComponent_3)), i1.ɵdid(4, 540672, null, 0, i8.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; var currVal_1 = ((_co.columnInfo.name + ": ") + _co.propertyData); _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v.parent, 9); _ck(_v, 4, 0, currVal_2); }, null); }
function View_HuntingRecordPropertyDisplayComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i9.TooltipDirective, [i1.ElementRef, i10.TooltipsService, i11.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i1.ɵpid(131072, i12.TzDatePipe, [i13.TzDateService, i14.LocaleConfigService, i1.ChangeDetectorRef]), (_l()(), i1.ɵted(3, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), i1.ɵpid(131072, i12.TzDatePipe, [i13.TzDateService, i14.LocaleConfigService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.propertyData, "MM/dd/yyyy HH:mm:ss.SSS")); _ck(_v, 1, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.propertyData, "M/d/yyyy H:mm:ss")); _ck(_v, 3, 0, currVal_1); }); }
function View_HuntingRecordPropertyDisplayComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "span", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i9.TooltipDirective, [i1.ElementRef, i10.TooltipsService, i11.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], filterHtml: [1, "filterHtml"] }, null), (_l()(), i1.ɵted(2, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.formatObjectString(_co.propertyData); var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.formatObjectString(_co.propertyData); _ck(_v, 2, 0, currVal_2); }); }
function View_HuntingRecordPropertyDisplayComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [["class", "hunting-property-cell__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEntitySidePanelClickEvent.emit({ ItemId: _co.propertyData, entityType: _co.columnInfo.entityType }) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyData; _ck(_v, 1, 0, currVal_0); }); }
function View_HuntingRecordPropertyDisplayComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [["class", "hunting-property-cell__link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onUserCellClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(2, null, ["\n\t\t\t\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyData; _ck(_v, 2, 0, currVal_0); }); }
function View_HuntingRecordPropertyDisplayComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_10)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t\t"])), (_l()(), i1.ɵand(0, [["userDataCell", 2]], null, 0, null, View_HuntingRecordPropertyDisplayComponent_11)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isUserEntity; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_HuntingRecordPropertyDisplayComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["\n\t\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyData; _ck(_v, 0, 0, currVal_0); }); }
function View_HuntingRecordPropertyDisplayComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "span", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i9.TooltipDirective, [i1.ElementRef, i10.TooltipsService, i11.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], filterHtml: [1, "filterHtml"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_9)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i1.ɵand(0, [["notClickable", 2]], null, 0, null, View_HuntingRecordPropertyDisplayComponent_12)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.propertyData; var currVal_1 = true; _ck(_v, 1, 0, currVal_0, currVal_1); var currVal_2 = _co.columnInfo.isClickable; var currVal_3 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
function View_HuntingRecordPropertyDisplayComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 11, null, null, null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i8.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_6)), i1.ɵdid(6, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_7)), i1.ɵdid(9, 278528, null, 0, i8.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_8)), i1.ɵdid(12, 16384, null, 0, i8.NgSwitchDefault, [i1.ViewContainerRef, i1.TemplateRef, i8.NgSwitch], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.columnInfo.type; _ck(_v, 3, 0, currVal_0); var currVal_1 = "date"; _ck(_v, 6, 0, currVal_1); var currVal_2 = "object"; _ck(_v, 9, 0, currVal_2); }, null); }
function View_HuntingRecordPropertyDisplayComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "wcd-datatable", [], null, null, null, i15.View_DataTableComponent_0, i15.RenderType_DataTableComponent)), i1.ɵdid(2, 13352960, null, 0, i16.DataTableComponent, [i1.ElementRef, i1.ChangeDetectorRef, i17.LiveAnnouncer, i18.I18nService, i1.ComponentFactoryResolver, i1.NgZone, i1.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], isSmallPadding: [2, "isSmallPadding"], columns: [3, "columns"], showHeaders: [4, "showHeaders"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tableItems; var currVal_1 = false; var currVal_2 = true; var currVal_3 = _co.tableColumns; var currVal_4 = _co.showTableHeaders; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_HuntingRecordPropertyDisplayComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_5)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(0, [["presentAsTable", 2]], null, 0, null, View_HuntingRecordPropertyDisplayComponent_13)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.presentValueAsTable; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_HuntingRecordPropertyDisplayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "wcd-full-height"]], [[2, "wcd-flex-center-vertical", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_1)), i1.ɵdid(4, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingRecordPropertyDisplayComponent_2)), i1.ɵdid(7, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(0, [["cellContentTemplate", 2]], null, 0, null, View_HuntingRecordPropertyDisplayComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.propertyData && _co.columnInfo.icon); _ck(_v, 4, 0, currVal_1); var currVal_2 = ((_co.link && _co.propertyData) && !_co.columnInfo.isClickable); var currVal_3 = i1.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.presentValueAsTable; _ck(_v, 1, 0, currVal_0); }); }
export function View_HuntingRecordPropertyDisplayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hunting-record-property-display", [], null, null, null, View_HuntingRecordPropertyDisplayComponent_0, RenderType_HuntingRecordPropertyDisplayComponent)), i1.ɵdid(1, 114688, null, 0, i19.HuntingRecordPropertyDisplayComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HuntingRecordPropertyDisplayComponentNgFactory = i1.ɵccf("hunting-record-property-display", i19.HuntingRecordPropertyDisplayComponent, View_HuntingRecordPropertyDisplayComponent_Host_0, { columnInfo: "columnInfo", propertyData: "propertyData", link: "link", presentObjectValuesAsTable: "presentObjectValuesAsTable" }, { openUserPanelEvent: "openUserPanelEvent", openEntitySidePanelClickEvent: "openEntitySidePanelClickEvent" }, []);
export { HuntingRecordPropertyDisplayComponentNgFactory as HuntingRecordPropertyDisplayComponentNgFactory };
