var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { Router } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import { find } from 'lodash-es';
import { AuthService, EvaluationEnabledGuard } from '@wcd/auth';
import { MdeUserRoleActionEnum, FilterImpactedAssets } from '@wcd/domain';
import { HUNTING_ROUTE, NavItemModel } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
var MainNavConfigService = /** @class */ (function () {
    function MainNavConfigService(featuresService, router, appConfigService, appContextService, authService, evaluationEnabledGuard, i18nService) {
        this.featuresService = featuresService;
        this.router = router;
        this.appConfigService = appConfigService;
        this.appContextService = appContextService;
        this.authService = authService;
        this.evaluationEnabledGuard = evaluationEnabledGuard;
        this.i18nService = i18nService;
        this.canUserViewSecopsData = this.authService.currentUser &&
            this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData);
        this.canUserViewTvmData = this.authService.currentUser &&
            this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmViewData);
        this.TvmSectionConfig = {
            title: MenuSections.TVM,
            isDisabled: !this.canUserViewTvmData || !this.featuresService.isEnabled(Feature.TvmPages),
            items: [
                {
                    route: 'tvm_dashboard',
                    isFolder: true,
                    id: 'tvm',
                    getImage: function () { return '/assets/images/icons/Timna.svg'; },
                    getImageClass: function () { return 'tvm-menu-image'; },
                    name: this.i18nService.strings.main_navigation_menu_item_threat_n_vulnerability_management,
                    children: [
                        {
                            route: 'tvm_dashboard',
                            id: 'tvm_dashboard',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_dashboard,
                        },
                        {
                            route: 'security-recommendations',
                            id: 'security-recommendations',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_security_recommendations,
                            queryParams: {
                                filters: 'status=Active',
                            },
                        },
                        {
                            route: 'remediation',
                            id: 'remediation',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_remediation,
                        },
                        {
                            route: 'software-inventory',
                            id: 'software-inventory',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_software_inventory,
                        },
                        {
                            route: 'vulnerabilities',
                            id: 'vulnerabilities',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_weaknesses,
                            queryParams: {
                                filters: "numOfImpactedAssets=" + FilterImpactedAssets.hasEffect,
                            },
                        },
                        {
                            route: 'tvm-event-insights',
                            id: 'event-timeline',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_event_timeline,
                        },
                        {
                            route: 'compare-kit',
                            id: 'compare-kit',
                            name: this.i18nService.strings
                                .main_navigation_menu_item_threat_n_vulnerability_management_compare_kit,
                            features: [Feature.TvmComparisonKit],
                        },
                    ],
                },
                {
                    route: 'baseline-compliance',
                    id: 'baseline-compliance',
                    icon: 'Trackers',
                    name: this.i18nService.strings.tvm_baseline_title,
                    features: [Feature.TvmBaselineCompliance],
                },
            ],
        };
        this.threatanalytics2Config = {
            route: 'threatanalytics2',
            id: 'threatanalytics2',
            name: this.i18nService.strings.main_navigation_menu_item_threat_analytics,
            disableFeatures: [Feature.ThreatAnalytics3],
            features: [Feature.ThreatAnalytics2],
        };
        this.threatanalytics3Config = {
            route: 'threatanalytics3',
            id: 'threatanalytics3',
            name: this.i18nService.strings.main_navigation_menu_item_threat_analytics,
            features: [Feature.ThreatAnalytics3],
        };
        this.securityOperationsConfig = {
            route: 'dashboard',
            id: 'secuirityoperations',
            name: this.i18nService.strings.main_navigation_menu_item_security_operations,
        };
        this.machinesConfig = {
            route: 'machines',
            id: 'machines',
            icon: 'hosts.host',
            name: this.i18nService.strings.main_navigation_menu_item_machines_list,
        };
        this.advancedHuntingConfig = {
            id: 'hunting',
            name: this.i18nService.strings.main_navigation_menu_item_advanced_hunting,
            route: HUNTING_ROUTE,
            icon: 'SearchData',
        };
        this.reportsConfig = {
            route: 'reports',
            isFolder: true,
            id: 'reports',
            icon: 'lineChart',
            name: this.i18nService.strings.main_navigation_menu_item_reports,
            isDisabled: !this.canUserViewSecopsData && !this.canUserViewTvmData,
            features: [Feature.ReportsPlatform],
            children: [
                {
                    route: 'reports/threatProtection',
                    id: 'threatProtection',
                    name: this.i18nService.strings.main_navigation_menu_item_reports_threat_protection,
                    features: [Feature.Reports],
                    isDisabled: !this.canUserViewSecopsData,
                },
                {
                    route: 'reports/threatProtection2',
                    id: 'threatProtection2',
                    name: this.i18nService.strings.main_navigation_menu_item_reports_threat_protection,
                    features: [Feature.ThreatReport],
                    isDisabled: !this.canUserViewSecopsData,
                },
                {
                    route: 'reports/machineHealth',
                    id: 'machineHealth',
                    name: this.i18nService.strings.main_navigation_menu_item_reports_machine_health_n_compliance,
                    features: [Feature.MachineHealthAndComplianceReport],
                    isDisabled: !this.canUserViewSecopsData,
                },
                {
                    route: 'reports/webThreatProtection',
                    id: 'webThreatProtection',
                    name: this.i18nService.strings.main_navigation_menu_item_reports_web_protection,
                    features: [Feature.WebThreatProtectionReport, Feature.WebContentFilteringPolicy],
                    isDisabled: !this.canUserViewSecopsData,
                },
                {
                    route: 'reports/vulnerability',
                    id: 'vulnerabilityReport',
                    name: this.i18nService.strings.main_navigation_menu_item_reports_vulnerability_report,
                    features: [Feature.TvmMachinesReport],
                    isDisabled: !this.canUserViewTvmData,
                },
            ],
        };
        this.interoperabilityConfig = {
            route: 'interoperability',
            isFolder: true,
            id: 'interoperability',
            icon: 'HomeGroup',
            name: this.i18nService.strings.main_navigation_menu_item_partners_n_apis,
            features: [Feature.Partners, Feature.ApiExplorer, Feature.AzureDataExportSupport, Feature.ConnectedApps],
            children: [
                {
                    route: 'interoperability/partners',
                    id: 'applications',
                    name: this.i18nService.strings.main_navigation_menu_item_partners_n_apis_partner_applications,
                    features: [Feature.Partners],
                },
                {
                    route: 'interoperability/connected',
                    id: 'connected-apps',
                    name: this.i18nService.strings
                        .main_navigation_menu_item_partners_n_apis_connected_applications,
                    features: [Feature.ConnectedApps],
                },
                {
                    route: 'interoperability/api-explorer',
                    id: 'api-explorer',
                    name: this.i18nService.strings.main_navigation_menu_item_partners_n_apis_api_explorer,
                    features: [Feature.ApiExplorer],
                    isDisabled: !this.canUserViewSecopsData,
                },
                {
                    route: 'interoperability/dataexport',
                    id: 'dataexport',
                    name: this.i18nService.strings.main_navigation_menu_item_partners_n_apis_data_export_settings,
                    features: [Feature.AzureDataExportSupport],
                    isDisabled: !this.canUserViewSecopsData,
                },
            ],
        };
        this.generalMenuConfig = {
            title: MenuSections.general,
            items: [
                {
                    route: 'tenanthealth',
                    id: 'serviceHealth',
                    icon: 'health',
                    name: this.i18nService.strings.main_navigation_menu_item_service_health,
                },
                {
                    getImage: function () { return '/assets/images/icons/configuration-management.svg'; },
                    route: 'configuration-management',
                    id: 'configuration-management',
                    name: this.i18nService.strings.main_navigation_menu_item_configuration_management,
                    features: [Feature.ConfigurationManagement],
                },
                {
                    route: 'preferences2',
                    id: 'settings',
                    icon: 'settings',
                    name: this.i18nService.strings.main_navigation_menu_item_settings,
                },
            ],
        };
        this.mainNavSecOpsConfig = [
            {
                title: MenuSections.securityOperations,
                items: [
                    {
                        route: 'dashboard',
                        isFolder: this.multipleDashboardsEnabled(),
                        id: 'dashboard',
                        icon: 'dashboard',
                        name: !this.multipleDashboardsEnabled()
                            ? this.i18nService.strings.main_navigation_menu_item_security_operations
                            : this.i18nService.strings.main_navigation_menu_item_dashboards,
                        children: !this.multipleDashboardsEnabled()
                            ? []
                            : [
                                this.securityOperationsConfig,
                                this.threatanalytics2Config,
                                this.threatanalytics3Config,
                            ],
                    },
                    {
                        route: 'incidents',
                        id: 'incidents',
                        name: this.i18nService.strings.main_navigation_menu_item_incidents,
                        icon: 'incident',
                        features: [Feature.Incidents],
                    },
                    this.machinesConfig,
                    {
                        route: 'alertsQueue',
                        id: 'alertsQueue',
                        name: this.i18nService.strings.main_navigation_menu_item_alerts_queue,
                        icon: 'alert',
                        queryParams: {
                            filters: 'status=New%7CInProgress',
                        },
                    },
                    {
                        route: 'investigations',
                        isFolder: true,
                        id: 'investigations',
                        icon: 'investigationOutline',
                        name: this.i18nService.strings.main_navigation_menu_item_automated_investigations,
                        children: [
                            {
                                id: 'investigationsList',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_automated_investigations_automated_investigations,
                                route: 'investigations',
                            },
                            {
                                id: 'pending_actions',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_automated_investigations_pending_actions,
                                route: 'pending',
                                disableFeatures: [Feature.ActionHistory],
                            },
                            {
                                id: 'actionCenter',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_automated_investigations_action_center,
                                route: 'action-center',
                                features: [Feature.ActionHistory],
                            },
                        ],
                    },
                    this.advancedHuntingConfig,
                    this.reportsConfig,
                    {
                        id: 'cms.actors',
                        route: 'cms/actors',
                        icon: 'edit',
                        isDisabled: !this.appConfigService.isCmsEnabled,
                        name: this.i18nService.strings.main_navigation_menu_item_actors_cms,
                    },
                    {
                        id: 'cms.outbreaks',
                        route: 'cms/outbreaks',
                        icon: 'edit',
                        isDisabled: !this.appConfigService.isOutbreakContentManagementEnabled,
                        name: this.i18nService.strings.main_navigation_menu_item_outbreaks_cms,
                    },
                    {
                        id: 'playbooks',
                        route: 'playbooks',
                        icon: 'playbook',
                        features: [Feature.Playbooks],
                        name: this.i18nService.strings.main_navigation_menu_item_playbooks,
                    },
                    this.interoperabilityConfig,
                ],
            },
            this.TvmSectionConfig,
            {
                title: MenuSections.Evaluation,
                isDisabled: !this.evaluationEnabledGuard.canActivate(),
                items: [
                    {
                        isFolder: true,
                        route: 'evaluation',
                        id: 'wdatp-evaluation',
                        name: this.i18nService.strings.main_navigation_menu_item_evaluation_n_tutorials,
                        icon: 'TestSuite',
                        children: [
                            {
                                route: 'evaluation',
                                id: 'evaluation',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_evaluation_n_tutorials_evaluation_lab,
                            },
                            {
                                route: 'tutorials',
                                id: 'tutorials',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_evaluation_n_tutorials_simulations_n_tutorials,
                            },
                        ],
                    },
                ],
            },
            this.generalMenuConfig,
        ];
        this.mainNavTvmConfig = [
            this.TvmSectionConfig,
            {
                title: MenuSections.securityOperations,
                items: [
                    {
                        route: 'securescore',
                        isFolder: this.multipleDashboardsEnabled(),
                        isDisabled: !this.multipleDashboardsEnabled(),
                        id: 'dashboard',
                        icon: 'dashboard',
                        name: this.i18nService.strings.main_navigation_menu_item_dashboards,
                        children: this.getMainTvmNavChildren(),
                    },
                    this.machinesConfig,
                    this.advancedHuntingConfig,
                    this.reportsConfig,
                    this.interoperabilityConfig,
                ],
            },
            this.generalMenuConfig,
        ];
        this.mainNavDefaultConfig = this.canUserViewSecopsData
            ? this.mainNavSecOpsConfig
            : this.mainNavTvmConfig;
        this.mainNavMTPConfig = [
            {
                title: MenuSections.SCC,
                items: [
                    {
                        id: 'homepage',
                        icon: 'Home',
                        name: this.i18nService.strings.main_navigation_menu_item_home,
                        externalLink: SCC_BASE_URL + "/homepage",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                    {
                        id: 'alerts',
                        icon: 'Warning',
                        name: this.i18nService.strings.main_navigation_menu_item_alerts,
                        externalLink: SCC_BASE_URL + "/alerts",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                    {
                        route: 'incidents',
                        id: 'incidents',
                        name: this.i18nService.strings.main_navigation_menu_item_incidents,
                        icon: 'incident',
                        isDisabled: this.featuresService.isEnabled(Feature.HuntingNavOnly),
                    },
                    {
                        id: 'actionCenter',
                        icon: 'investigationOutline',
                        name: this.i18nService.strings.main_navigation_menu_item_action_center,
                        route: 'action-center',
                        features: [Feature.ActionHistory],
                        isDisabled: this.featuresService.isEnabled(Feature.HuntingNavOnly),
                    },
                    {
                        id: 'reports',
                        icon: 'LineChart',
                        name: this.i18nService.strings.main_navigation_menu_item_monitoring_n_reporting,
                        externalLink: SCC_BASE_URL + "/reports",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                    {
                        id: 'secureScore',
                        icon: 'Trophy2',
                        name: this.i18nService.strings.main_navigation_menu_item_secure_score,
                        externalLink: SCC_BASE_URL + "/securescore",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                    {
                        id: 'hunting',
                        name: this.i18nService.strings.main_navigation_menu_item_advanced_hunting,
                        route: HUNTING_ROUTE,
                        icon: 'SearchData',
                    },
                    // Uncomment to show a link to SCC hunting page
                    // {
                    // 	id: 'hunting',
                    // 	icon: 'SearchData',
                    // 	name: 'Hunting',
                    // 	externalLink: `${SCC_BASE_URL}/hunting`,
                    // 	externalLinkTarget: SCC_BASE_TAB_NAME,
                    // 	showExternalLinkIcon: false,
                    // },
                    {
                        route: 'classification',
                        isFolder: true,
                        id: 'classification',
                        icon: 'Tag',
                        name: this.i18nService.strings.main_navigation_menu_item_classification,
                        children: [
                            {
                                id: 'labels',
                                name: this.i18nService.strings.main_navigation_menu_item_classification_labels,
                                externalLink: SCC_BASE_URL + "/labels",
                                externalLinkTarget: SCC_BASE_TAB_NAME,
                                showExternalLinkIcon: false,
                            },
                            {
                                id: 'labelPolicies',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_classification_label_policies,
                                externalLink: SCC_BASE_URL + "/labelpolicies",
                                externalLinkTarget: SCC_BASE_TAB_NAME,
                                showExternalLinkIcon: false,
                            },
                            {
                                id: 'sensitiveInfoTypes',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_classification_sensitive_info_types,
                                externalLink: SCC_BASE_URL + "/sensitiveTypes",
                                externalLinkTarget: SCC_BASE_TAB_NAME,
                                showExternalLinkIcon: false,
                            },
                            {
                                id: 'labelAnalytics',
                                name: this.i18nService.strings
                                    .main_navigation_menu_item_classification_label_analytics,
                                externalLink: SCC_BASE_URL + "/labelanalytics",
                                externalLinkTarget: SCC_BASE_TAB_NAME,
                                showExternalLinkIcon: false,
                            },
                        ],
                    },
                    {
                        id: 'policies',
                        icon: 'Equalizer',
                        name: this.i18nService.strings.main_navigation_menu_item_policies,
                        externalLink: SCC_BASE_URL + "/policies",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                ],
            },
            {
                title: MenuSections.SCCManage,
                items: [
                    {
                        id: 'permissions',
                        icon: 'Permissions',
                        name: this.i18nService.strings.main_navigation_menu_item_permissions,
                        externalLink: SCC_BASE_URL + "/permissions",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                    {
                        id: 'moreResources',
                        icon: 'Info',
                        name: this.i18nService.strings.main_navigation_menu_item_more_resources,
                        externalLink: SCC_BASE_URL + "/info",
                        externalLinkTarget: SCC_BASE_TAB_NAME,
                        showExternalLinkIcon: false,
                    },
                ],
            },
        ];
    }
    MainNavConfigService.prototype.getMainTvmNavChildren = function () {
        var children = [];
        if (this.multipleDashboardsEnabled()) {
            children.push(this.threatanalytics2Config);
            children.push(this.threatanalytics3Config);
        }
        return children;
    };
    Object.defineProperty(MainNavConfigService.prototype, "mainNavConfig", {
        get: function () {
            if (!this._mainNavConfig)
                this._mainNavConfig = this.getMainNavConfig();
            return this._mainNavConfig;
        },
        enumerable: true,
        configurable: true
    });
    MainNavConfigService.prototype.parseNavItem = function (navItemConfig) {
        var _this = this;
        if (!navItemConfig.name) {
            var route = find(this.router.config, function (_route) { return _route.path === navItemConfig.route; });
            if (route)
                navItemConfig.name = route.data.pageTitleKey
                    ? this.i18nService.get(route.data.pageTitleKey)
                    : route.data.pageTitle;
            else
                navItemConfig.name = navItemConfig.id;
        }
        if (navItemConfig.children) {
            navItemConfig.children = navItemConfig.children
                .filter(this.isNavItemEnabled.bind(this))
                .map(function (childConfig) { return _this.parseNavItem(childConfig); });
        }
        return navItemConfig;
    };
    /**
     * Updates the main nav config, in case features have changed, for example
     */
    MainNavConfigService.prototype.update = function () {
        this._mainNavConfig = null;
    };
    MainNavConfigService.prototype.getMainNavConfig = function () {
        var _this = this;
        return (this.appContextService.isMtp ? this.mainNavMTPConfig : this.mainNavDefaultConfig)
            .filter(this.isNavItemEnabled.bind(this))
            .map(function (navSectionConfig) {
            return __assign({}, navSectionConfig, { items: _this.getNavItemsModel(navSectionConfig.items) });
        });
    };
    MainNavConfigService.prototype.getNavItemsModel = function (navItemsConfig) {
        var _this = this;
        return navItemsConfig
            .filter(this.isNavItemEnabled.bind(this))
            .map(function (navItemConfig) { return new NavItemModel(_this.parseNavItem(navItemConfig)); });
    };
    MainNavConfigService.prototype.isNavItemEnabled = function (navItemConfig) {
        if (navItemConfig.isDisabled)
            return false;
        if (navItemConfig.features && !this.featuresService.isAnyEnabled(navItemConfig.features))
            return false;
        return (!navItemConfig.disableFeatures ||
            !this.featuresService.isAnyEnabled(navItemConfig.disableFeatures));
    };
    /**
     * This function can be removed once SecurityAnalyticsAggregationUX or ThreatAnalytics2 feature are enabled for all slices.
     * There will always be multiple dashboards in these cases.
     */
    MainNavConfigService.prototype.multipleDashboardsEnabled = function () {
        return (this.appConfigService.isExposedToAllMachineGroups ||
            this.featuresService.isEnabled(Feature.SecurityAnalyticsAggregationUX) ||
            this.featuresService.isEnabled(Feature.ThreatAnalytics2));
    };
    return MainNavConfigService;
}());
export { MainNavConfigService };
export var SUPPORT_LINK_I18N_KEY = 'main_header_help_link_microsoft_support';
export var HELP_LINKS_CONFIG = [
    {
        id: 'wdatp-guide',
        link: 'https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409',
        i18nKey: 'main_header_help_link_microsoft_Defender_atp_guide',
    },
    {
        id: 'ms-support',
        link: 'http://go.microsoft.com/fwlink/?LinkID=761093&clcid=0x409',
        isMtp: true,
        isFairfax: false,
        i18nKey: SUPPORT_LINK_I18N_KEY,
        isExternalSupportLink: true,
    },
    {
        id: 'ms-support',
        link: 'https://go.microsoft.com/fwlink/?linkid=2100020',
        isMtp: true,
        isFairfax: true,
        i18nKey: SUPPORT_LINK_I18N_KEY,
        isExternalSupportLink: true,
    },
    {
        id: 'premier-support',
        link: 'http://go.microsoft.com/fwlink/?LinkID=733758&clcid=0x409',
        isMtp: true,
        i18nKey: 'main_header_help_link_microsoft_premier_support',
        isExternalSupportLink: true,
    },
    {
        id: 'license',
        link: 'https://www.microsoftvolumelicensing.com/DocumentSearch.aspx?Mode=3&DocumentTypeId=46',
        isMtp: true,
        i18nKey: 'main_header_help_link_license',
    },
    {
        id: 'privacy',
        link: 'https://privacy.microsoft.com/en-us/privacystatement',
        isMtp: true,
        i18nKey: 'main_header_privacy',
    }
];
export var MenuSections;
(function (MenuSections) {
    MenuSections["configurationManagement"] = "menuSections.configurationManagement";
    MenuSections["general"] = "menuSections.general";
    MenuSections["SCC"] = "menuSections.scc";
    MenuSections["SCCManage"] = "menuSections.sccManage";
    MenuSections["securityOperations"] = "menuSections.securityOperations";
    MenuSections["TVM"] = "menuSections.tvm";
    MenuSections["Evaluation"] = "menuSections.evaluation";
})(MenuSections || (MenuSections = {}));
export var SCC_BASE_URL = 'https://security.microsoft.com';
export var SCC_BASE_TAB_NAME = 'SCC';
