import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { SoftwareInstallationAggEntityPanelComponent } from './components/software-entity/software-installation-agg.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SoftwareInstallationAggFieldsService } from './services/software-installation-agg-versions.fields.service';
import { SoftwareInstallationAggEntityTypeService } from './services/software-installation-agg.entity-type.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule],
	declarations: [SoftwareInstallationAggEntityPanelComponent],
	exports: [SoftwareInstallationAggEntityPanelComponent],
	entryComponents: [SoftwareInstallationAggEntityPanelComponent],
	providers: [SoftwareInstallationAggFieldsService, SoftwareInstallationAggEntityTypeService],
})
export class SoftwareInstallationAggEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SoftwareInstallationAggEntityTypeService);
	}
}
