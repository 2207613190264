/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../global_entities/components/entity-details/process.entity-details.component.ngfactory";
import * as i2 from "../../../global_entities/components/entity-details/process.entity-details.component";
import * as i3 from "../../files/components/file.entity-panel/file.entity-panel.component.ngfactory";
import * as i4 from "../../files/components/file.entity-panel/file.entity-panel.component";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i7 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i8 from "../../files/services/virus-total-file-report.service";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "@angular/common";
import * as i11 from "./process.entity-panel.component";
var styles_ProcessEntityPanelComponent = [];
var RenderType_ProcessEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProcessEntityPanelComponent, data: {} });
export { RenderType_ProcessEntityPanelComponent as RenderType_ProcessEntityPanelComponent };
function View_ProcessEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "section", [["class", "wcd-margin-vertical wcd-margin-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "process-entity-details", [], null, null, null, i1.View_ProcessEntityDetailsComponent_0, i1.RenderType_ProcessEntityDetailsComponent)), i0.ɵdid(5, 114688, null, 0, i2.ProcessEntityDetailsComponent, [i0.Injector], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "file-entity-panel", [], null, null, null, i3.View_FileEntityPanelComponent_0, i3.RenderType_FileEntityPanelComponent)), i0.ɵdid(9, 245760, null, 0, i4.FileEntityPanelComponent, [i0.ChangeDetectorRef, i5.Paris, i6.PrettyNumberService, i7.FeaturesService, i8.VirusTotalFileReportService, i9.I18nService], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.process; _ck(_v, 5, 0, currVal_0); var currVal_1 = _co.process.file; _ck(_v, 9, 0, currVal_1); }, null); }
export function View_ProcessEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProcessEntityPanelComponent_1)), i0.ɵdid(2, 16384, null, 0, i10.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.process.id; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ProcessEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "process-entity-panel", [], null, null, null, View_ProcessEntityPanelComponent_0, RenderType_ProcessEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i11.ProcessEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProcessEntityPanelComponentNgFactory = i0.ɵccf("process-entity-panel", i11.ProcessEntityPanelComponent, View_ProcessEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { ProcessEntityPanelComponentNgFactory as ProcessEntityPanelComponentNgFactory };
