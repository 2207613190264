
		<ng-container>
			<dataview-action-button
				*ngIf="!searchOpened"
				[buttonActionConfig]="searchButtonConfig"
				[smallScreenView]="smallScreenView"
			>
			</dataview-action-button>
			<div
				*ngIf="searchOpened"
				class="wcd-flex-center-all dataview-search-form"
				[ngClass]="giveSearchBoxMoreSpace ? 'wcd-flex-3' : 'wcd-flex-1'"
			>
				<div class="wcd-full-width search-div wcd-input-placeholder command-bar-item-button ">
					<button
						class="btn btn-link search-div__btn command-bar-item-button"
						type="button"
						[disabled]="!searchTerm || searchTerm === lastSearched"
						(click)="search(searchTerm)"
						[attr.aria-label]="'dataview.search' | i18n"
					>
						<wcd-shared-icon aria-hidden="true" [iconName]="icon"></wcd-shared-icon>
					</button>
					<input
						class="search-input"
						[(ngModel)]="searchTerm"
						name="dataview-search"
						[minlength]="searchMinLength"
						(keydown.enter)="search(searchTerm)"
						(keydown.escape)="cancelSearch()"
						(blur)="onSearchBlur()"
						[placeholder]="searchLocalized"
						[attr.size]="searchLocalized.length + SearchSafePadding"
						[attr.aria-label]="'dataview.search.item' | i18n: { itemType: entityTypeName }"
						autocomplete="off"
					/>
					<button
						class="btn btn-link clear-search command-bar-item-button"
						type="button"
						(click)="cancelSearch()"
						[attr.aria-label]="tooltip | i18n"
						[wcdTooltip]="tooltip | i18n"
					>
						<wcd-shared-icon iconColor="black" iconName="cancel"></wcd-shared-icon>
					</button>
				</div>
			</div>
		</ng-container>
	