var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, Injector, OnInit } from '@angular/core';
import { LegacyUser, LegacyUserLegacyUserProfileRelationship, LegacyUserAatpProfileRelationship, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { take, mergeMap, map } from 'rxjs/operators';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { ContentState } from '@wcd/contents-state';
import { of } from 'rxjs';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { isAccountLinkable } from '@wcd/scc-interface';
var UserEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(UserEntityDetailsComponent, _super);
    function UserEntityDetailsComponent(injector, changeDetectorRef, advancedFeaturesService, paris) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.advancedFeaturesService = advancedFeaturesService;
        _this.paris = paris;
        _this.EntityDetailsMode = EntityDetailsMode;
        _this.userType = LegacyUser;
        _this.asKeyValueList = false;
        _this.userProfileContentState = ContentState.Loading;
        _this.aatpDisplayDataContentState = ContentState.Loading;
        return _this;
    }
    Object.defineProperty(UserEntityDetailsComponent.prototype, "isClickable", {
        get: function () {
            return isAccountLinkable(this.user);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEntityDetailsComponent.prototype, "user", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEntityDetailsComponent.prototype, "showSecurityInfo", {
        get: function () {
            return this.isPageOrSidePane;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEntityDetailsComponent.prototype, "showNetworkActivity", {
        get: function () {
            return this.isPageOrSidePane;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEntityDetailsComponent.prototype, "showContactDetails", {
        get: function () {
            return this.isPageOrSidePane;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserEntityDetailsComponent.prototype, "isPageOrSidePane", {
        get: function () {
            return this.mode === EntityDetailsMode.EntityPage || this.mode === EntityDetailsMode.SidePane;
        },
        enumerable: true,
        configurable: true
    });
    UserEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userProfileContentState = ContentState.Loading;
        this.getUserProfile(this.user).subscribe(function (profile) {
            _this.userProfileContentState = profile ? ContentState.Complete : ContentState.Empty;
            _this.userProfile = profile;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.userProfileContentState = ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        });
        this.aatpDisplayDataContentState = ContentState.Loading;
        this.getAatpProfile(this.user).subscribe(function (data) {
            _this.aatpDisplayDataContentState = data ? ContentState.Complete : ContentState.Empty;
            _this.aatpDisplayData = data;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.aatpDisplayDataContentState = ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        });
    };
    UserEntityDetailsComponent.prototype.getUserProfile = function (user) {
        return this.paris
            .getRelatedItem(LegacyUserLegacyUserProfileRelationship, user)
            .pipe(take(1));
    };
    UserEntityDetailsComponent.prototype.getAatpProfile = function (user) {
        var _this = this;
        return this.advancedFeaturesService.getAdvancedFeaturesSettings().pipe(mergeMap(function (advancedFeatures) {
            if (!advancedFeatures.aatpIntegrationEnabled || !advancedFeatures.aatpWorkspaceExists) {
                return of(null);
            }
            return _this.paris
                .getRelatedItem(LegacyUserAatpProfileRelationship, user)
                .pipe(map(function (_a) {
                var securityAlertSummary = _a.securityAlertSummary, url = _a.url;
                return ({
                    count: securityAlertSummary.high +
                        securityAlertSummary.medium +
                        securityAlertSummary.low,
                    url: url,
                });
            }));
        }), take(1));
    };
    return UserEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { UserEntityDetailsComponent };
