import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { AirsEntityReportProvider } from './airs-entity-report-provider.value-object';

@ValueObject({
	singularName: 'Entity Report',
	pluralName: 'Entity Reports',
	readonly: true,
})
export class AirsEntityReport extends ModelBase {
	@EntityField() status: AirsEntityStatus;

	@EntityField({ data: 'relation_name' })
	relationName?: string;

	@EntityField({ arrayOf: AirsEntityReportProvider })
	providers: Array<AirsEntityReportProvider>;
}
