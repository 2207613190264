import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import {
	Ip,
	CustomTiIndicator,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	CustomTiIndicatorTypeExclusionsRelationship,
	MdeUserRoleActionEnum,
	ServiceSourceType,
} from '@wcd/domain';
import { IpEntityPanelComponent } from '../components/ip.entity-panel.component';
import { IpEntityComponent } from '../components/ip.entity.component';
import { IpEntityDetailsComponent } from '../../../global_entities/components/entity-details/ip.entity-details.component';
import { RegExpService } from '@wcd/shared';
import { IpsService } from './ips.service';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService } from '@wcd/config';
import { EntityCustomTiActionsConfigService } from '../../../@entities/common/services/entity.custom-ti-actionconfig.service';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { EntityMinimizedDetailsComponentBase } from '../../../global_entities/components/entity-minimized/entity-minimized-details.component.base';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { AppFlavorConfig } from '@wcd/scc-common';

@Injectable()
export class IpEntityTypeService implements EntityTypeService<Ip> {
	private _customIndicatorsRepo: RelationshipRepository<CustomTiIndicatorsType, CustomTiIndicator>;

	constructor(
		private readonly paris: Paris,
		private readonly customTiIndicatorsService: CustomTiIndicatorsService,
		private readonly ipsService: IpsService,
		private readonly i18nService: I18nService,
		private featuresService: FeaturesService,
		private readonly entityCustomTiActionsConfigService: EntityCustomTiActionsConfigService
	) {
		this._customIndicatorsRepo = this.paris.getRelationshipRepository(
			CustomTiIndicatorTypeExclusionsRelationship
		);

		this._customIndicatorsRepo.sourceItem = this.paris.getValue(
			CustomTiIndicatorsType,
			CustomTiIndicatorsTypes.Ip
		);
	}

	readonly entityType: Readonly<EntityType<Ip>> = {
		entity: Ip,
		id: 'ip',
		icon: FabricIconNames.Streaming,
		getIcon: () => FabricIconNames.Streaming,
		getImage: () => '/assets/images/icons/entities/ip.svg',
		entityContentsComponentType: IpEntityComponent,
		entityDetailsComponentType: IpEntityDetailsComponent,
		singleEntityPanelComponentType: IpEntityPanelComponent,
		entityMinimizedComponentType: EntityMinimizedDetailsComponentBase,
		getEntityName: ip => ip.name || ip.id,
		entityPluralNameKey: 'ip_entityType_pluralName',
		entitySingularNameKey: 'ip_entityType_singularName',
		getNavigationModel: (ip: Ip, serviceSource?: ServiceSourceType) =>
			(ip.externalIpPageUrl || ip.id) && {
				externalLink: ip.externalIpPageUrl ? ip.externalIpPageUrl : null,
				routerLink: !ip.externalIpPageUrl
					? this.featuresService.isEnabled(Feature.PortedSccPages) ||
					  !serviceSource ||
					  serviceSource === ServiceSourceType.Wdatp
						? [this.ipsService.getIpLink(ip.id)]
						: null
					: null,
			},
		getUseExternalRouting: (ips: Array<Ip>) =>
			ips && ips.length === 1 && Boolean(ips[0].externalIpPageUrl),
		getEntitiesLink: (ips: Array<Ip>) => {
			if (ips && ips.length === 1)
				if (ips[0].externalIpPageUrl) {
					return ips[0].externalIpPageUrl;
				} else if (ips[0].id) {
					return this.ipsService.getIpLink(ips[0].id);
				} else {
					return null;
				}
		},
		getActions: (ips: Array<Ip>, options: {}, entityViewType: EntityViewType) => {
			const [ip] = ips;
			return entityViewType === EntityViewType.EntityPage
				? this.entityCustomTiActionsConfigService
						.getCommandBarActions<Ip>(
							ip.name,
							CustomTiIndicatorsTypes.Ip,
							ipEntity => ipEntity.name
						)
						.pipe(map(config => [new ItemActionModel(config)]))
				: of(null);
		},
		loadFullEntityInPanel: false,
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_ip,
			resolveTypeFromSearchTerm: (searchTerm: string) => {
				return RegExpService.ip.test(searchTerm);
			},
			getSearchParams: (searchTerm: string) => {
				return {
					url: this.ipsService.getIpLink(searchTerm),
				};
			},
			searchDropdownOrder: 0,
			requiredAllowedActions: MdeUserRoleActionEnum.viewData,
			flavorConfig: AppFlavorConfig.search.commonEntities,

		},
		entityPageViewMode: EntityPageViewMode.Asset,
	};
}
