var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, OnInit, OnDestroy, Injector, } from '@angular/core';
import { filter } from 'rxjs/operators';
import { Software, SoftwareInstallation, ShouldShowLegalNoteFor, LegalNoteLinks, EolState, VulnerabilityType, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { SOFTWARE_CATEGORY } from '../../../@entities/@tvm/software-inventory/components/software-entity/software.entity.component';
import { MessageBarType } from 'office-ui-fabric-react';
import { Router } from '@angular/router';
var SoftwareEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(SoftwareEntityDetailsComponent, _super);
    function SoftwareEntityDetailsComponent(changeDetectorRef, activatedEntity, tvmTextsService, i18nService, featuresService, router, injector) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.activatedEntity = activatedEntity;
        _this.tvmTextsService = tvmTextsService;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.MessageBarType = MessageBarType;
        _this.messageBarStyles = MessageBarStyles;
        _this.messageBarState = {
            showBody: false,
        };
        _this._messageBarStyles = MessageBarStyles;
        _this.isProductEvidenceEnabled = _this.featuresService.isEnabled(Feature.TvmProductEvidence);
        _this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
        return _this;
    }
    SoftwareEntityDetailsComponent.prototype.generateTooltip = function (software) {
        this.tooltip = this.tvmTextsService.getText(TextToken.ExposureScoreForSoftware, software.name);
    };
    SoftwareEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        if (!this.software) {
            this._subscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof Software; }))
                .subscribe(function (software) {
                _this.software = software;
                _this.initializeSoftwareFields();
            });
        }
        else {
            this.initializeSoftwareFields();
        }
    };
    SoftwareEntityDetailsComponent.prototype.getExposedDevicesTitle = function (software) {
        return this.featuresService.isEnabled(Feature.TvmNetworkScan) &&
            software.category === SOFTWARE_CATEGORY.NetworkGear
            ? this.i18nService.get('tvm.common.exposedNetworkDevices')
            : this.i18nService.get('tvm.common.exposedDevices');
    };
    SoftwareEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this._subscription && this._subscription.unsubscribe();
    };
    SoftwareEntityDetailsComponent.prototype.getStyles = function (messageBarProps) {
        if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.messageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
        return this._messageBarStyles;
    };
    SoftwareEntityDetailsComponent.prototype.shouldShowEolMessage = function () {
        return (this.isEolSoftware ||
            this.isUpcomingEolSoftware ||
            this.software.hasEolVersions ||
            this.software.hasUpcomingEolVersions);
    };
    SoftwareEntityDetailsComponent.prototype.initializeSoftwareFields = function () {
        this.generateTooltip(this.software);
        this.isEolSoftware = this.software.eolSoftwareState === EolState.AlreadyEOL;
        this.isUpcomingEolSoftware = this.software.eolSoftwareState === EolState.UpcomingEOL;
        this.exposedDevicesTitle = this.getExposedDevicesTitle(this.software);
        this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.software.id);
        this.showEolMessage = this.shouldShowEolMessage();
        this.showEolDate = this.shouldShowEolDate();
        this.eolText = this.getEolText();
        this.legalNote = this.isEolSoftware
            ? this.tvmTextsService.getText(TextToken.SoftwareEolOsLegalNote, this.software.id)
            : this.tvmTextsService.getText(TextToken.HasEolVersionsMicrosoftLegalNote, this.software.id);
        this.showLegalNoteLink = Object.keys(LegalNoteLinks).includes(this.software.id);
        this.legalNoteLink = LegalNoteLinks[this.software.id];
        this.legalNoteLinkText = this.tvmTextsService.getText(TextToken.LegalNoteLinkText, this.software.id);
        this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
        this.messageBarType =
            this.software.hasEolVersions || this.isEolSoftware ? MessageBarType.warning : MessageBarType.info;
        this.showZeroDayMessage = this.shouldShowZeroDayMessage(this.software);
        this.osPlatformText = this.software.osPlatform
            ? this.i18nService.get("tvm_common_osPlatform_" + this.software.osPlatform)
            : this.i18nService.strings.notAvailable_short;
    };
    SoftwareEntityDetailsComponent.prototype.getEolText = function () {
        if (this.isEolSoftware) {
            return this.tvmTextsService.getText(TextToken.EolSoftwareText, this.software.eolSoftwareSinceDate);
        }
        if (this.isUpcomingEolSoftware) {
            return this.tvmTextsService.getText(TextToken.UpcomingEolSoftwareText, this.software.eolSoftwareSinceDate);
        }
        if (this.software.hasEolVersions && this.software.hasUpcomingEolVersions) {
            return this.i18nService.get('tvm.securityRecommendation.combinedEolVersionsText', {
                product: this.software.name,
            });
        }
        if (this.software.hasEolVersions) {
            return this.i18nService.get('tvm.securityRecommendation.hasEolVersionsText', {
                product: this.software.name,
            });
        }
        if (this.software.hasUpcomingEolVersions) {
            return this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersionsText', {
                product: this.software.name,
            });
        }
    };
    SoftwareEntityDetailsComponent.prototype.openSoftwareVersions = function () {
        var alreadyEOL = this.software.hasEolVersions ? 'AlreadyEOL' : '';
        var upcomingEOL = this.software.hasUpcomingEolVersions ? 'UpcomingEOL' : '';
        this.router.navigate(["/software-inventory/" + encodeURIComponent(this.software.id) + "/versions"], {
            queryParams: {
                filters: "versionDistributionTags=" + [alreadyEOL, upcomingEOL]
                    .filter(function (s) { return s.length > 0; })
                    .join('|'),
            },
        });
        this.changeDetectorRef.detectChanges();
    };
    SoftwareEntityDetailsComponent.prototype.getSoftwareVersionsLinkText = function () {
        return this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.openSoftwareVersions', { software: this.software.name });
    };
    SoftwareEntityDetailsComponent.prototype.shouldShowEolDate = function () {
        return this.software.eolSoftwareSinceDate && (this.isEolSoftware || this.isUpcomingEolSoftware);
    };
    SoftwareEntityDetailsComponent.prototype.shouldShowZeroDayMessage = function (software) {
        return (this.zeroDayFeatureEnabled &&
            software.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
            !software.patchReleaseDate);
    };
    return SoftwareEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { SoftwareEntityDetailsComponent };
