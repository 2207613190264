import { EventEmitter, OnChanges } from '@angular/core';
import { Router, UrlHandlingStrategy } from '@angular/router';
import { NavItemModel } from '../models/nav-item.model';
import { RoutesService } from '../services/routes.service';
import { isEmpty } from 'lodash-es';
/*
 * renders "inner" router-link element or an "external" anchor element, according to given link params
 */
var RouteLinkComponent = /** @class */ (function () {
    function RouteLinkComponent(urlHandlingStrategy, router, routesService) {
        this.urlHandlingStrategy = urlHandlingStrategy;
        this.router = router;
        this.routesService = routesService;
        this.routerLinkActiveCssClass = 'active';
        this.className = '';
        this.classWrapper = '';
        this.showIcon = true;
        this.linkAriaPosinset = null;
        this.linkAriaSetsize = null;
        this.onClick = new EventEmitter();
        this.onArrowRight = new EventEmitter();
        this.onArrowLeft = new EventEmitter();
        this.onArrowUp = new EventEmitter();
        this.onArrowDown = new EventEmitter();
        this.onFocus = new EventEmitter();
    }
    Object.defineProperty(RouteLinkComponent.prototype, "dataTrackId", {
        get: function () {
            return this.trackId || "InnerLinkTo_" + this.routeLink.id;
        },
        enumerable: true,
        configurable: true
    });
    RouteLinkComponent.prototype.routerLinkExists = function () {
        return !isEmpty(this.routeLink.routerLink);
    };
    RouteLinkComponent.prototype.ngOnChanges = function () {
        if (!this.routeLink) {
            return;
        }
        if (this.routeLink.sccInternalRedirectDefinition) {
            var route = this.routeLink.sccInternalRedirectDefinition(this.routeLink.externalLink);
            if (route && route.startsWith('/user')) {
                this.routeLink = Object.assign(this.routeLink, { route: route });
                this.linkType = LinkType.upgraded;
                var _a = getUpgradedRouterLink(this.routeLink), upgradedLink = _a.upgradedLink, upgradedQueryParams = _a.upgradedQueryParams;
                this.upgradedQueryParams = upgradedQueryParams;
                this.upgradedLink = upgradedLink;
                return;
            }
        }
        if (this.routeLink.sccInternalRedirectDefinition) {
            this.linkType = LinkType.sccInternal;
            this.routeLink = Object.assign(this.routeLink, {
                routerLink: this.routeLink.sccInternalRedirectDefinition(this.routeLink.externalLink),
            });
            return;
        }
        // Mark link as external if needed
        if (this.routeLink.externalLink) {
            this.externalLink = this.routeLink.externalLink;
            this.linkType = LinkType.external;
            return;
        }
        // Transform link in case we are in SCC
        if (this.routerLinkExists()) {
            var mdatpFromSccLinkConfig = this.routesService.getMdatpFromSccLinkConfig(this.getRouterLinkAsString());
            if (mdatpFromSccLinkConfig) {
                if (this.routesService.shouldOpenExternally(mdatpFromSccLinkConfig)) {
                    this.linkType = LinkType.external;
                    this.mdatpFromSccUrl = this.buildExternalLinkWithParams(mdatpFromSccLinkConfig.url);
                    return;
                }
                else {
                    if (mdatpFromSccLinkConfig.url.startsWith('/user')) {
                        this.linkType = LinkType.upgraded;
                        var _b = getUpgradedRouterLink(this.routeLink), upgradedLink = _b.upgradedLink, upgradedQueryParams = _b.upgradedQueryParams;
                        this.upgradedQueryParams = upgradedQueryParams;
                        this.upgradedLink = upgradedLink;
                        return;
                    }
                    this.routeLink = Object.assign(this.routeLink, {
                        routerLink: [mdatpFromSccLinkConfig.url],
                    });
                }
            }
        }
        if (this.routeLink.openInNewTab && this.routerLinkExists()) {
            this.linkType = LinkType.external;
            this.externalLink = this.buildExternalLinkWithParams(this.getRouterLinkAsString());
            return;
        }
        this.linkType = this.getInternalLinkType();
        if (this.linkType === LinkType.upgraded) {
            var _c = getUpgradedRouterLink(this.routeLink), upgradedLink = _c.upgradedLink, upgradedQueryParams = _c.upgradedQueryParams;
            this.upgradedQueryParams = upgradedQueryParams;
            this.upgradedLink = upgradedLink;
        }
    };
    RouteLinkComponent.prototype.goToLegacyRoute = function ($event) {
        if (!$event.ctrlKey && !$event.shiftKey && !$event.altKey) {
            this.onClick.emit($event);
            this.router.navigateByUrl(this.routeLink.routerLink[0]);
        }
    };
    RouteLinkComponent.prototype.getInternalLinkType = function () {
        var urlTree = this.routeLink.routerLink && this.router.parseUrl(this.routeLink.routerLink[0]);
        return urlTree && this.urlHandlingStrategy.shouldProcessUrl(urlTree)
            ? LinkType.upgraded
            : LinkType.legacy;
    };
    RouteLinkComponent.prototype.buildExternalLinkWithParams = function (routerLink) {
        var _this = this;
        if (this.routeLink.queryParams) {
            var params = Object.keys(this.routeLink.queryParams).map(function (key) { return key + "=" + _this.routeLink.queryParams[key]; });
            return params.length ? routerLink + "?" + params.join('&') : routerLink;
        }
        return routerLink;
    };
    RouteLinkComponent.prototype.getRouterLinkAsString = function () {
        return Array.isArray(this.routeLink.routerLink)
            ? this.routeLink.routerLink[0]
            : this.routeLink.routerLink;
    };
    return RouteLinkComponent;
}());
export { RouteLinkComponent };
var LinkType;
(function (LinkType) {
    LinkType["upgraded"] = "upgraded";
    LinkType["legacy"] = "legacy";
    LinkType["external"] = "external";
    LinkType["sccInternal"] = "sccInternal";
})(LinkType || (LinkType = {}));
export function getUpgradedRouterLink(navItemModel) {
    var getRouterLink = function (link) { return (Array.isArray(link) ? link[0] : link); };
    var routeLink = navItemModel.route || getRouterLink(navItemModel.routerLink);
    var routeHasQuery = routeLink ? routeLink.indexOf('?') > 0 : false;
    var result = {
        upgradedLink: null,
        upgradedQueryParams: navItemModel.queryParams,
    };
    if (routeHasQuery) {
        var _a = routeLink.split('?'), url = _a[0], query = _a[1];
        result.upgradedLink = [url];
        var params = (query.match(/([^?=&]+)(=([^&]*))?/g) || []).reduce(function (result, each) {
            var _a = each.split('='), key = _a[0], value = _a[1];
            result[key] = value;
            return result;
        }, {});
        result.upgradedQueryParams = Object.assign({}, navItemModel.queryParams, params);
    }
    else {
        result.upgradedLink = navItemModel.routerLink;
    }
    return result;
}
