import { EntityModelBase, Entity, EntityField } from '@microsoft/paris';
import { incidentGraphRelationTypeValues } from './incident-graph-relation-type.values';

@Entity({
	singularName: 'Incident graph relation type',
	pluralName: 'Incident graph relation type',
	values: incidentGraphRelationTypeValues,
})
export class IncidentGraphRelationType extends EntityModelBase<string> {
	@EntityField() name: string;

	/**
	 * The Fabric icons unicode value of the icon
	 */
	@EntityField() icon: string;

	/**
	 * The name of the icon (from icons.service)
	 */
	@EntityField() iconName: string;

	@EntityField() isDirectional: boolean;
}
