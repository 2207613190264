var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
export var RawMachinePermissions;
(function (RawMachinePermissions) {
    RawMachinePermissions[RawMachinePermissions["Unknown"] = -2] = "Unknown";
    RawMachinePermissions[RawMachinePermissions["Disallowed"] = -1] = "Disallowed";
    RawMachinePermissions[RawMachinePermissions["Allowed"] = 0] = "Allowed";
    RawMachinePermissions[RawMachinePermissions["AllowedWithWarning"] = 1] = "AllowedWithWarning";
})(RawMachinePermissions || (RawMachinePermissions = {}));
var ɵ0 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ1 = function (machine) { return ({
    params: {
        machineId: machine.machineId,
    },
}); }, ɵ2 = function (_a) {
    var Permissions = _a.Permissions;
    var typesMap = Permissions.reduce(function (map, currentItem) {
        var _a;
        return Object.assign(map, (_a = {},
            _a[currentItem.Type] = RawMachinePermissions[currentItem.Result],
            _a));
    }, {});
    return typesMap;
};
var MachinePermissionsApiCall = /** @class */ (function (_super) {
    __extends(MachinePermissionsApiCall, _super);
    function MachinePermissionsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    MachinePermissionsApiCall = __decorate([
        ApiCall({
            name: 'Machine permissions',
            endpoint: 'requests/permissions',
            method: 'GET',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
            parseData: ɵ2,
        })
    ], MachinePermissionsApiCall);
    return MachinePermissionsApiCall;
}(ApiCallModel));
export { MachinePermissionsApiCall };
export { ɵ0, ɵ1, ɵ2 };
