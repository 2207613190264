import { Component, ViewEncapsulation } from '@angular/core';
import { AlertPageService } from '../services/alert-page.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'import-alert',
	templateUrl: './import-alert.component.html',
	styleUrls: ['./import-alert.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ImportAlertComponent {
	newAlertProcessTreeJsonPlaceholder: string;
	newAlertProcessTreeJson: string;

	constructor(private alertPageService: AlertPageService, private i18nService: I18nService) {
		this.newAlertProcessTreeJsonPlaceholder = this.i18nService.get(
			'alert.page.import.alert.process.tree.enter.json'
		);
	}

	onSubmit() {
		this.alertPageService.updateProcessTreeJson(this.newAlertProcessTreeJson);
	}
}
