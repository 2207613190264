var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { ActivatedEntity } from './activated-entity.service';
import { take, switchMap } from 'rxjs/operators';
import { EntityIdRouteService } from './entity-id-route.service';
import { EntityResolver } from './entity.resolver';
import { TvmMachineGroupsFilterService } from '../../tvm/services/tvm-machine-groups-filter.service';
import { AppContextService } from '@wcd/config';
var TvmEntityResolver = /** @class */ (function (_super) {
    __extends(TvmEntityResolver, _super);
    function TvmEntityResolver(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, machineGroupsFilterService, appContext) {
        var _this = _super.call(this, paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext) || this;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.router = router;
        _this.entityIdRouteService = entityIdRouteService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.appContext = appContext;
        return _this;
    }
    TvmEntityResolver.prototype.resolve = function (route, state) {
        var _this = this;
        return this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1), switchMap(function (machineGroupsFilterData) {
            var params = {};
            if (machineGroupsFilterData && machineGroupsFilterData.isFiltering) {
                var groupIds = machineGroupsFilterData.machineGroups
                    .filter(function (mg) { return mg.isSelected; })
                    .map(function (mg) { return mg.groupId; })
                    .join();
                params['rbacGroups'] = "groups in (" + groupIds + ")";
            }
            return _super.prototype.resolve.call(_this, route, state, params);
        }));
    };
    return TvmEntityResolver;
}(EntityResolver));
export { TvmEntityResolver };
