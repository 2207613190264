/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./simulator-agent-form-content.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "./simulator-agent-form-content.component";
import * as i4 from "../../services/evaluation.service";
import * as i5 from "./simulator-agent-step.component";
var styles_SimulatorAgentStepComponent = [];
var RenderType_SimulatorAgentStepComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SimulatorAgentStepComponent, data: {} });
export { RenderType_SimulatorAgentStepComponent as RenderType_SimulatorAgentStepComponent };
export function View_SimulatorAgentStepComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "simulator-agent-form-content", [], null, [[null, "onMsConsentToggled"], [null, "onAttackIQConsentToggled"], [null, "onProviderToggled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onMsConsentToggled" === en)) {
        var pd_0 = (_co.checkValidity() !== false);
        ad = (pd_0 && ad);
    } if (("onAttackIQConsentToggled" === en)) {
        var pd_1 = (_co.checkValidity() !== false);
        ad = (pd_1 && ad);
    } if (("onProviderToggled" === en)) {
        var pd_2 = (_co.checkValidity() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i1.View_SimulatorAgentFormContentComponent_0, i1.RenderType_SimulatorAgentFormContentComponent)), i0.ɵprd(14336, null, i2.ControlContainer, null, [i2.NgForm]), i0.ɵdid(3, 114688, null, 0, i3.SimulatorAgentFormContentComponent, [i4.EvaluationService, i0.ComponentFactoryResolver, i0.ChangeDetectorRef], { agentsConfig: [0, "agentsConfig"] }, { onProviderToggled: "onProviderToggled", onMsConsentToggled: "onMsConsentToggled" }), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.data; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SimulatorAgentStepComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "ng-component", [], null, null, null, View_SimulatorAgentStepComponent_0, RenderType_SimulatorAgentStepComponent)), i0.ɵprd(14336, null, i2.ControlContainer, null, [i2.NgForm]), i0.ɵdid(2, 114688, null, 0, i5.SimulatorAgentStepComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var SimulatorAgentStepComponentNgFactory = i0.ɵccf("ng-component", i5.SimulatorAgentStepComponent, View_SimulatorAgentStepComponent_Host_0, { data: "data" }, {}, []);
export { SimulatorAgentStepComponentNgFactory as SimulatorAgentStepComponentNgFactory };
