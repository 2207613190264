import { HttpParams } from '@angular/common/http';
import { isNil } from 'lodash-es';
var ApiBaseModel = /** @class */ (function () {
    function ApiBaseModel(http) {
        this.http = http;
    }
    ApiBaseModel.prototype.extractData = function (res) {
        return res.data;
    };
    ApiBaseModel.prototype.getUrlParams = function (options) {
        return ApiBaseModel.getUrlParams(options);
    };
    /**
     * Prepares form data with files from the given object, so files can be uploaded
     * @param data
     * @returns {FormData}
     */
    ApiBaseModel.prototype.getFormData = function (data) {
        var postData = new FormData();
        for (var p in data) {
            var value = data[p];
            postData.append(value instanceof File ? 'file[]' : p, value);
        }
        return postData;
    };
    ApiBaseModel.getUrlParams = function (options) {
        var params = new HttpParams();
        if (options) {
            var _loop_1 = function (p) {
                if (options.hasOwnProperty(p)) {
                    var value = options[p];
                    if (value instanceof Array)
                        value.forEach(function (_value) { return (params = params.append(p, String(_value))); });
                    else if (!isNil(value))
                        params = params.append(p, String(value));
                }
            };
            for (var p in options) {
                _loop_1(p);
            }
        }
        return { params: params };
    };
    return ApiBaseModel;
}());
export { ApiBaseModel };
