import { EntityField, EntityModelBase, Entity } from '@microsoft/paris';
import { parseEntityName, TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Evidence distribution',
	pluralName: 'Evidence distributions',
	endpoint: 'evidenceDistribution',
	readonly: true,
})
export class EvidenceDistribution extends EntityModelBase<string> {
	@EntityField()
	diskPath: string;

	@EntityField({ parse: parseEntityName })
	productName: string;

	@EntityField({ parse: parseEntityName })
	vendor: string;

	@EntityField()
	productVersion: string;

	@EntityField()
	assetCount: number;

	@EntityField()
	assetsWithRunningFilesCount: number;

	@EntityField()
	assetsWithOpenPortCount: number;

	@EntityField()
	recommendationId?: string;

	@EntityField()
	vulnerabilities: string[];

	@EntityField()
	cveId?: string;

	@EntityField()
	hasInternalPaths: boolean;

	isInternalPathDistribution: boolean = false;

	mainDiskPath?: string = undefined;

	mainDiskPathId?: string = undefined;

	// for single asset we return these fields
	@EntityField()
	hasOpenPort?: boolean;

	@EntityField()
	hasBeenUsedRecently?: boolean;

	@EntityField()
	lastUsedTimestamp?: Date;

	@EntityField()
	pathLastSeenTimestamp?: Date;

	@EntityField()
	pathLastUsedWithOpenPortTimestamp?: Date;
}
