var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { DEFAULT_LAB_CONFIGURATION_ID, LAB_CONFIGURATION } from '@wcd/domain';
import { LabConfigurationStepComponent } from './lab-configuration-step.component';
import { SimulatorAgentStepComponent } from './simulator-agent-step.component';
import { SummaryStepComponent } from './summary-step.component';
import { EvaluationService } from '../../services/evaluation.service';
var EvaluationSetupPanelComponent = /** @class */ (function (_super) {
    __extends(EvaluationSetupPanelComponent, _super);
    function EvaluationSetupPanelComponent(router, evaluationService) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.data = {
            labConfiguration: LAB_CONFIGURATION.find(function (c) { return c.id === DEFAULT_LAB_CONFIGURATION_ID; }),
            withAiq: false,
            withSafeBreach: false,
        };
        _this.onLabCreated = function () {
            _this.evaluationService.notifyLabReady();
            _this.closePanel();
        };
        _this.steps = [
            {
                id: 1,
                name: 'LabConfiguration',
                verticalStepKeyName: 'evaluation.lab.setupPanel.configurationStep.title',
                component: LabConfigurationStepComponent,
            },
            {
                id: 2,
                name: 'SimulatorAgent',
                verticalStepKeyName: 'evaluation.lab.setupPanel.simulatorAgentStep.title',
                component: SimulatorAgentStepComponent,
            },
            {
                id: 3,
                name: 'Summary',
                verticalStepKeyName: 'evaluation.lab.setupPanel.summaryStep.title',
                component: SummaryStepComponent,
                onNext: true,
                nextButtonText: 'evaluation.lab.createLab',
                componentOutputs: new Map([['onLabCreated', _this.onLabCreated]]),
            },
        ];
        return _this;
    }
    return EvaluationSetupPanelComponent;
}(PanelContainer));
export { EvaluationSetupPanelComponent };
