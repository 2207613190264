<!-- tslint:disable:template-click-events-have-key-events -->
<div class="notification" [ngClass]="{ selectable: notification.isSelectable }" data-track-component="Notification">
	<div class="wcd-padding-small-all" (click)="notificationClick($event)">
		<div class="notification-icon">
			<div class="notification-icon-icon" [ngClass]="notification.iconClassName">
				 <wcd-shared-icon [iconName]="notification.iconName"> </wcd-shared-icon >
			</div>
		</div>
		<div class="notification-contents">
			<div>
				<h3 class="notification-title">{{notification.title}}</h3>
				<p class="notification-text" *ngIf="notification.text">{{notification.text}}</p>
				<ul class="notification-contents-list" *ngIf="notification.contents && notification.contents.length">
					<li *ngFor="let notificationContent of notification.contents" class="clear-both">
						 <wcd-shared-icon *ngIf="notificationContent.iconName" [iconName]="notificationContent.iconName"
							  class="small-icon nudge-up pull-left"> </wcd-shared-icon >
						<div [ngClass]="{ 'with-small-icon': notificationContent.iconName }">{{notificationContent.text}}
						</div>
					</li>
				</ul>
			</div>
		</div>
	</div>
	<div class="notification-actions wcd-padding-small-all">
		<button class="btn btn-link"
				data-track-id="Snooze"
				data-track-type="Button"
				(click)="snoozeAll()"
				[disabled]="notificationsService.snoozed$ | async">
			{{'remediation_pendingActions_snooze' | i18n}}
		</button>
	</div>
</div>
