import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { AirsEntityVerdict } from '../airs_entity/airs-entity-verdict.entity';

@ValueObject({
	singularName: 'Ip address details',
	pluralName: 'Ip addresses details',
})
export class IpDetails extends ModelBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: 'Id' })
	name: string;

	@EntityField({ data: 'Country' })
	country: string;

	@EntityField({ data: 'State' })
	state: string;

	@EntityField({ data: 'City' })
	city: string;

	@EntityField({ data: 'Organization' })
	organization: string;

	@EntityField({ data: 'Asn' })
	asn: string;

	@EntityField({ data: 'Verdict' })
	verdict: AirsEntityVerdict;

	@EntityField({ data: 'ISP' })
	isp: string;

	@EntityField({ data: 'Carrier' })
	carrier: string;

}
