
		<div class="wcd-full-height wcd-flex-1 wcd-flex-vertical">
			<div
				*ngIf="(events$ | async) as events; else loader"
				class="wcd-flex-1 wcd-margin-small-horizontal"
			>
				<wcd-datatable
					*ngIf="events.length; else noData"
					[items]="events"
					[columns]="tableFields"
					[selectEnabled]="false"
				></wcd-datatable>
			</div>
			<ng-template #loader>
				<div class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</ng-template>
			<ng-template #noData>
				<div class="wcd-padding-all">
					<i class="icon icon-Error"></i>
					{{ 'alert.tabs.timeline.noData' | i18n }}
				</div>
			</ng-template>
		</div>
	