<span
	*ngIf="computedTag"
	class="tag wcd-flex-horizontal"
	[ngClass]="getClass(tag)"
	[class.partial]="computedTag.isPartial"
	[wcdTooltip]="computedTag.tooltip || computedTag.name"
	[filterHtml]="true"
>
	<a
		(click)="removeButtonClick()"
		(keydown.enter)="removeButtonClick()"
		*ngIf="removable && computedTag.isEditable !== false"
		data-track-id="RemoveTag"
		data-track-type="Action"
		class="link-remove wcd-flex-none"
		[title]= "i18nService.strings.tags_removeTag"
		role="button"
		[attr.aria-label]="'tags.removeTagName' | i18n: { name: computedTag.name } "
		tabindex="0"
	>
		<wcd-shared-icon iconName="close"></wcd-shared-icon>
	</a>
	<span class="tag-name">
		{{ computedTag.name }}
	</span>
</span>
