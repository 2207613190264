import { Pipe, PipeTransform } from '@angular/core';

declare const moment: typeof import('moment');

@Pipe({
	name: 'timeAgo',
})
export class TimeAgoPipe implements PipeTransform {
	transform(value: string): string {
		return moment(value).fromNow();
	}
}
