import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'System Exclusion List Type',
	pluralName: 'System Exclusion List Types',
	values: [
		{
			id: 0,
			name: 'Allow',
			icon: 'check',
			label: 'white',
			className: 'success',
			helpKey: 'exclusion.whitelist',
			isWhitelist: true,
		},
		{
			id: 1,
			name: 'Block',
			icon: 'malware',
			label: 'black',
			className: 'malware',
			helpKey: 'exclusion.blacklist',
			isWhitelist: false,
		},
	],
})
export class SystemExclusionListType extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField() label: string;

	@EntityField() className: string;

	@EntityField() helpKey: string;

	@EntityField() isWhitelist: boolean;

	@EntityField() icon: string;
}
