var _a;
export var MachineIdType;
(function (MachineIdType) {
    MachineIdType["Id"] = "Id";
    MachineIdType["SenseMachineId"] = "SenseMachineId";
    MachineIdType["Guid"] = "Guid";
    MachineIdType["Name"] = "Name";
})(MachineIdType || (MachineIdType = {}));
export var machineIdTypeRegexMapping = (_a = {},
    _a[MachineIdType.Guid] = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/,
    _a[MachineIdType.SenseMachineId] = /^[A-Fa-f0-9]{40}$/,
    _a);
export function getMachineIdType(machineId) {
    for (var machineIdType in machineIdTypeRegexMapping) {
        if (machineIdTypeRegexMapping[machineIdType].test(machineId))
            return machineIdType;
    }
    return MachineIdType.Name;
}
