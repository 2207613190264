import {
	sccHostService,
	IPerfSession,
	IPerfSessionCreateOptions,
	IPerfSessionOptionsBase, dummyPerf,
} from '@wcd/scc-interface';
import { Injectable, OnDestroy } from '@angular/core';
import { merge } from 'lodash-es';

@Injectable({
	providedIn: 'root',
})
export class PerformanceSccService implements OnDestroy {
	currentNgPageSession: IPerfSession;

	// Internal API's for wicd-ng
	createNgPageLoadPerformanceSession(sessionId, options?: IPerfSessionCreateOptions): IPerfSession {
		if (!sccHostService.isSCC) {
			return dummyPerf;
		}
		const defaultOptions = {
			isContainer: true,
		};
		this.clearCurrentSession();
		return this.currentNgPageSession = sccHostService.perf.createPerformanceSession(sessionId, 'ng-custom-page-load', Object.assign({}, defaultOptions, options));
	}

	endNgPageLoadPerfSession(pageName: string, options?: IPerfSessionOptionsBase) {
		if (!sccHostService.isSCC) {
			return;
		}
		const pageContext = `${sccHostService.state.current.name}.${pageName}`;
		sccHostService.perf.endPerfSession(this.currentNgPageSession, merge({}, {
			removeFromContext: true,
			customProps: { pageContext },
		}, options));
		this.clearCurrentSession();
	}

	clearCurrentSession(): void {
		if (!sccHostService.isSCC || !this.currentNgPageSession) {
			return;
		}
		this.currentNgPageSession.removeFromContext();
		sccHostService.perf.cleanUpFinishedSessionFromCache();
		this.currentNgPageSession = null;
	}

	ngOnDestroy(): void {
		this.clearCurrentSession();
	}
}
