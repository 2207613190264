/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/shared/src/lib/components/route-link.component.ngfactory";
import * as i2 from "../../../../../../projects/shared/src/lib/components/route-link.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../../../../projects/shared/src/lib/services/routes.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i7 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i8 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i9 from "./machine-name.component.ngfactory";
import * as i10 from "./machine-name.component";
import * as i11 from "../../global_entities/services/global-entity-types.service";
import * as i12 from "./machine-link.component";
var styles_HostLinkComponent = [];
var RenderType_HostLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_HostLinkComponent, data: {} });
export { RenderType_HostLinkComponent as RenderType_HostLinkComponent };
function View_HostLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HostLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "route-link", [], null, null, null, i1.View_RouteLinkComponent_0, i1.RenderType_RouteLinkComponent)), i0.ɵdid(1, 573440, null, 0, i2.RouteLinkComponent, [i3.UrlHandlingStrategy, i3.Router, i4.RoutesService], { routeLink: [0, "routeLink"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 0, 1, null, View_HostLinkComponent_2)), i0.ɵdid(4, 540672, null, 0, i5.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machineRouteLinkModel; _ck(_v, 1, 0, currVal_0); var currVal_1 = i0.ɵnov(_v.parent, 6); _ck(_v, 4, 0, currVal_1); }, null); }
function View_HostLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_HostLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HostLinkComponent_4)), i0.ɵdid(2, 540672, null, 0, i5.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent, 6); _ck(_v, 2, 0, currVal_0); }, null); }
function View_HostLinkComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [["class", "small-icon color-text-gray-900"], ["iconName", "System"]], null, null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i7.IconComponent, [i8.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = "System"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_HostLinkComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "machine-name", [], null, null, null, i9.View_MachineNameComponent_0, i9.RenderType_MachineNameComponent)), i0.ɵdid(3, 49152, null, 0, i10.MachineNameComponent, [i11.GlobalEntityTypesService], { machine: [0, "machine"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine.name; _ck(_v, 3, 0, currVal_0); }, null); }
function View_HostLinkComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine.machineId; _ck(_v, 0, 0, currVal_0); }); }
function View_HostLinkComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HostLinkComponent_6)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HostLinkComponent_7)), i0.ɵdid(5, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["machineIdLabel", 2]], null, 0, null, View_HostLinkComponent_8)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showIcon; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.machine.name; var currVal_2 = i0.ɵnov(_v, 7); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
export function View_HostLinkComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_HostLinkComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["labelOnly", 2]], null, 0, null, View_HostLinkComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["linkContent", 2]], null, 0, null, View_HostLinkComponent_5)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.machine.machineId; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_HostLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "machine-link", [], null, null, null, View_HostLinkComponent_0, RenderType_HostLinkComponent)), i0.ɵdid(1, 114688, null, 0, i12.HostLinkComponent, [i11.GlobalEntityTypesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HostLinkComponentNgFactory = i0.ɵccf("machine-link", i12.HostLinkComponent, View_HostLinkComponent_Host_0, { machine: "machine", showIcon: "showIcon" }, {}, []);
export { HostLinkComponentNgFactory as HostLinkComponentNgFactory };
