
<div>
	<ng-container *ngIf='!isInProgress; else inProgress'>
	<dl class="tvm-list__key_val_item wcd-padding-xLarge-left" *ngIf="isDetectedValueEnabled && configuration.expectedValues">
		<dt>
			{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_recommendedState' | i18n }}
		</dt>
		<dd>
			{{ configuration.expectedValues[0] || ('notAvailable_long' | i18n)}}
		</dd>
	</dl>
	<dataview class="wcd-flex-1"
		id="basline-configuration-devices-dataview"
		[dataViewConfig]="dataViewConfig"
		[giveSearchBoxMoreSpace]="true"
		[repository]="repository"
		[navigateOnChange]="false"
		[allowPaging]="true"
		[allowFilters]="false"
		[searchEnabled]="true"
		[ignoreQueryParams]="true"
		[allowColumnCustomization]="false"
		[fields]="fields"
		[disableSelection]="true">
	</dataview>
	</ng-container>
	<ng-template #inProgress>
		<dl class="tvm-list__key_val_item wcd-padding-xLarge-left">
			<dd>
				{{'tvm_baseline_compliant_enum_in_progress_tooltip' | i18n}}
			</dd>
		</dl>
	</ng-template>
</div>
