import {
	DataQuery,
	DataQuerySortDirection,
	Entity,
	EntityField,
	EntityModelBase,
	ModelEntity,
	ParisConfig,
} from '@microsoft/paris';
import { SuppressionRuleAction } from './suppression-rule-action.entity';
import { SuppressionRuleCondition } from './suppression-rule-condition.value-object';
import { SuppressionRuleScope } from './suppression-rule-scope.entity';
import { Machine } from '../machine/machine.entity';
import { omit } from 'lodash-es';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { SuppressionRuleBaseCondition, SuppressionRuleComplexCondition } from './suppression-rule-condition';
import { SuppressionRuleType } from './suppression-rule-type.enum';
import { SuppressionRuleMultiValuePredicate } from './suppression-rule-predicate';
import { SuppressionRuleComplexConditionType } from './suppression-rule-complex-condition-type.enum';

@Entity({
	singularName: 'Suppression rule',
	pluralName: 'Suppression rules',
	endpoint: 'suppressionRules',
	allItemsEndpointTrailingSlash: false,
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};
		return Object.assign(
			{},
			omit(dataQuery.where as object, ['page', 'page_size', 'ordering']),
			{
				pageIndex: dataQuery.page || 1,
				pageSize: dataQuery.pageSize || 200,
			},
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
					sortByField: dataQuery.sortBy[0].field,
					sortOrder:
						dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
							? 'Ascending'
							: 'Descending',
				}
				: undefined
		);
	},
	parseItemQuery: (itemId: string | number, entity?: ModelEntity, config?: ParisConfig) => {
		return `${entity.endpoint}/${itemId}`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.suppressionRulesService,
	serializeItem: (
		item: SuppressionRule,
		modelData: {},
		entity,
		config,
		serializationData?: { comment: string, originatingAlertId: string }
	) => {
		const conditions = !!item.complexConditions ? item.complexConditions : item.conditions.map((condition: SuppressionRuleCondition) => {
			return {
				Type: condition.type.dataFieldName,
				Value: condition.value,
			};
		});
		const serializedItem = omit(modelData, [
			'DeserializedConditions',
			'DeserializedComplexConditions',
			'DeserializedRbacGroupIds',
			'ScopeConditions',
			'CreationTime',
			'MatchingAlertsCount',
			'UpdateTime',
			'__self',
		]);
		Object.assign(serializedItem, {
			ruleConditions: JSON.stringify(conditions),
			ScopeConditions: !!item.scopeConditions ? JSON.stringify(item.scopeConditions) : null,
			Scope: item.scope.id,
		});
		if (item.machine) {
			Object.assign(serializedItem, {
				senseMachineId: item.machine.id,
				internalMachineId: item.machine.internalMachineId,
				computerDnsName: item.machine.name,
			});
		}
		return {
			rule: serializedItem,
			comment: (serializationData && serializationData.comment) || '',
			originatingAlertId: (serializationData && serializationData.originatingAlertId) || null,
		};
	},
	parseSaveQuery: (item: SuppressionRule, entity?: ModelEntity) => {
		return item.id ? `${entity.endpoint}/${item.id}` : 'CreateRule';
	},
	parseRemoveQuery: (item: SuppressionRule[], entity?: ModelEntity) => {
		return `${entity.endpoint}/${item[0].id}`;
	},
	saveMethod: (item: SuppressionRule) => {
		return item.id ? 'PUT' : 'POST';
	},
})
export class SuppressionRule extends EntityModelBase<number> {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: ['RuleTitle', 'Title'] })
	name: string;

	@EntityField({ data: 'Scope' })
	scope: SuppressionRuleScope;

	@EntityField({
		data: '__self',
		parse: data => omit(data, ['Id']),
		require: data => data.MachineId || data.SenseMachineId || data.ComputerDnsName,
	})
	machine: Machine;

	@EntityField({ data: 'Action' })
	action: SuppressionRuleAction;

	@EntityField({ data: 'MatchingAlertsCount' })
	alertsCount: number;

	@EntityField({ data: 'AlertTitle' })
	alertTitle: string;

	@EntityField({ data: 'IoaDefinitionId' })
	ioaDefinitionId?: string;

	@EntityField({ data: 'CreatedBy' })
	createdBy: string;

	@EntityField({ data: 'LastActivity' })
	lastActivity: string;

	@EntityField({ data: 'ThreatFamilyName' })
	threatFamilyName: string;

	@EntityField({ data: 'CreationTime' })
	createdOn: Date;

	@EntityField({ data: 'UpdateTime' })
	lastUpdatedOn: Date;

	@EntityField({ data: 'IsEnabled' })
	isEnabled: boolean;

	@EntityField({ data: 'IsReadOnly' })
	isReadOnly: boolean;

	@EntityField({ data: 'DeserializedConditions', arrayOf: SuppressionRuleCondition, parse: (_, rawData) => rawData.RuleType !== SuppressionRuleType.MDE && rawData.RuleConditions && JSON.parse(rawData.RuleConditions) })
	conditions: Array<SuppressionRuleCondition>;

	@EntityField({ data: 'DeserializedComplexConditions', parse: (_, rawData) => rawData.RuleType === SuppressionRuleType.MDE && rawData.RuleConditions && JSON.parse(rawData.RuleConditions) })
	complexConditions: SuppressionRuleBaseCondition;

	@EntityField({ data: 'ScopeConditions', parse: (scopeConditions) => scopeConditions && JSON.parse(scopeConditions)})
	scopeConditions: Array<Array<Partial<SuppressionRuleMultiValuePredicate>>>;

	@EntityField({ data: 'RbacGroupIds' })
	rbacGroupIds: string;

	@EntityField({ data: 'RuleType', defaultValue: SuppressionRuleType.Default })
	ruleType: SuppressionRuleType;

	@EntityField({ data: 'DeserializedRbacGroupIds' })
	deserializedRbacGroupIds: Array<number>;

	@EntityField({
		data: 'OriginatingAlertId', parse: (_, rawData) => {
			try {
				const additionalDetailsParsed = rawData.AdditionalDetails && JSON.parse(rawData.AdditionalDetails);
				return additionalDetailsParsed ? additionalDetailsParsed.originatingAlertId : null;
			} catch {
				console.warn('Failed to parse AdditionalDetails json of SuppressionRule.');
				return null;
			}			
		}
	})
	originatingAlertId?: string;

	get iocConditionsNotEmpty(): boolean {
		if (this.ruleType === SuppressionRuleType.MDE) {
			return this.complexConditions && this.complexConditions.conditionType === SuppressionRuleComplexConditionType.Complex ?
				(this.complexConditions as SuppressionRuleComplexCondition).conditions.length > 0 : !!this.complexConditions;
		} else {
			return this.conditions && this.conditions.length > 0;
		}
	}

	get scopeConditionsNotEmpty(): boolean {
		return this.ruleType === SuppressionRuleType.MDE &&
			this.scopeConditions &&
			this.scopeConditions.some(conditionGroup =>
				conditionGroup.length > 0 &&
				conditionGroup.some(condition => condition.values && condition.values.length > 0));
	}
}
