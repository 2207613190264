import { SevilleModule } from '../../app/seville/seville.module';

SevilleModule.controller('seville.onboarding.welcome', onboardingWelcomeController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.welcome', {
			url: '/welcome',
			title: 'Onboarding',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page" ng-if="welcome.validateNavigation">
                <div class="onboarding-body-title">
                    <span>Welcome {{welcome.username}}</span>
                </div>
                <div class="onboarding-body">
                    <div class="onboarding-body-position onboarding-welcome">
                        <div class="onboarding-body-text">
                            <p>
                            This wizard will guide you through the steps to onboard your organization onto the Microsoft Defender for Endpoint service. <br/>
                                For more detailed help and information on the onboarding process, see the <a href="https://go.microsoft.com/fwlink/p/?linkid=822801" target="_blank">Onboard devices and set up access</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                            <p />
                            <p>
                                For more information about how Microsoft Defender for Endpoint stores and retains your data, see the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733763&amp;clcid=0x409" target="_blank">Data storage and privacy</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                            <p />
                            <p>
                                You need to set aside 10 to 20 minutes to complete the process, although it might take longer before all onboarded devices appear in the Microsoft Defender for Endpoint portal.
                            <p />
                            <p>
                                Click Next to start the onboarding process.
                            <p />
                        </div>
                    </div>
                </div>
                <div class="onboarding-navigation onboarding-paging-disabled">
                    <i class="icon icon-Sell rotate-90 icon-md onboarding-paging-disabled onboarding-paging-icon-back-margin" ng-click="welcome.MoveBack()"></i>
                    Back
                </div>
                <div class="onboarding-navigation onboarding-paging-icon-margin">
                    Next
                    <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-paging-icon-next-margin" ng-click="welcome.MoveNext()"></i>
                </div>
            </div>
						`,
					controller: 'seville.onboarding.welcome',
					controllerAs: 'welcome',
				},
			},
		});
	},
]);

onboardingWelcomeController.$inject = [
	'$scope',
	'$state',
	'authenticationService',
	'$location',
	'$anchorScroll',
];

function onboardingWelcomeController($scope, $state, authenticationService, $location, $anchorScroll) {
	var vm = this;
	vm.validateNavigation = false;

	if (!$scope.$parent.stepPermissionsVisited || $scope.$parent.stepProcessingVisited) {
		$state.go('onboarding.permissions');
		vm.validateNavigation = true;
	} else {
		vm.validateNavigation = true;
		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeInProgress = true;
		});

		$location.hash('');
		$anchorScroll();

		var fullUserName = authenticationService.loggedInUser();
		if (fullUserName != null) {
			var endOfUserName = fullUserName.indexOf('@');
			vm.username = fullUserName.substring(0, endOfUserName);
		} else {
			vm.username = 'user';
		}

		vm.MoveNext = function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
			$scope.$parent.stepWelcomeInProgress = false;
			$state.go('onboarding.geo');
		};
	}
}
