export enum AirsEntityRefNames {
	AirsFile = 'AirsFile',
	AirsProcess = 'AirsProcess',
	AirsDriver = 'AirsDriver',
	AirsService = 'AirsService',
	AirsUrl = 'AirsUrl',
	AirsIp = 'AirsIp',
	AirsAppendedFile = 'AirsAppendedFile',
	AirsEmailLink = 'AirsEmailLink',
	AirsUser = 'AirsUser',
	AirsPersistenceMethod = 'AirsPersistenceMethod',
	AirsUserActivity = 'AirsUserActivity',
	AirsEmailCluster = 'AirsEmailCluster',
	AirsEmailMessage = 'AirsEmailMessage',
	AirsEmailMessageSubmission = 'AirsEmailMessageSubmission',
	AirsMailbox = 'AirsMailbox',
	AirsMailboxConfiguration = 'AirsMailboxConfiguration',
	Account = 'Account',
	AirsSecurityGroup = 'AirsSecurityGroup',
	AirsRegistryKey = 'AirsRegistryKey',
	AirsRegistryValue = 'AirsRegistryValue',
	AirsCloudApplication = 'AirsCloudApplication',
	AirsCloudLogonSession = 'AirsCloudLogonSession',
	AirsOauthApplication = 'AirsOauthApplication',
	AirsActiveDirectoryDomain = 'AirsActiveDirectoryDomain',
}
