<div class="wcd-full-height wcd-flex-vertical" *ngIf="notificationRule" data-track-component="VulnerabilityNotificationRulePanel">
	<div class="wcd-scroll-vertical">
		<section *ngIf="notificationRule.description" class="wcd-padding-large-top wcd-padding-large-horizontal">
			<div class="tvm-list__header wcd-margin-small-bottom">{{ 'email_notification_description_label' | i18n }}</div>
			<div class="tvm-list__block_item">{{ notificationRule.description }}</div>
		</section>
		<section class="wcd-padding-large-horizontal wcd-margin-top">
			<div class="tvm-list__header_semi-bold">{{ 'status' | i18n }}</div>
			<wcd-checkbox
				[(ngModel)]="isEnabled"
				[isDisabled]="!isUserAllowedActions || !isUserCanEditRule"
				name="email-notification-enable"
				[label]="'email_notification_enable' | i18n">
			</wcd-checkbox>
		</section>
		<section class="wcd-padding-large-top wcd-padding-large-horizontal">
			<div class="tvm-list__header wcd-margin-small-bottom">{{ 'email_notification_wizard_settings_step_title' | i18n }}</div>
			<div class="tvm-list__header_semi-bold">{{ 'vulnerability_email_notification_wizard_eventType_summary' | i18n }}</div>
			<div class="tvm-list__block_item">{{ eventTypeTitle }}</div>
			<ng-container *ngIf="severityLevelForNewCve">
				<div class="tvm-list__header_semi-bold">{{ 'vulnerability_email_notification_wizard_severity_threshold_summary' | i18n }}</div>
				<div class="tvm-list__block_item">{{ severityThresholdTitle }}</div>
			</ng-container>
			<ng-container *ngIf="tenantHasMachineGroups">
				<div class="tvm-list__header_semi-bold">{{ 'notifications_ruleScope' | i18n }}</div>
				<div class="tvm-list__block_item">{{ ruleScope }}</div>
			</ng-container>
		</section>
		<wcd-collapsible-section [label]="recipientsTitle">
			<simple-data-list-table
				[dataList]="notificationRule.recipients"
				[shouldShowIcon]="true"
				[iconName]="'email'"
				[openPanelForAllItems]="true"
				[titleKey]="'email_notification_recipientsTable_title'"
				[seeAllKey]="'emailRecipients_seeAll_link'" >
			</simple-data-list-table>
		</wcd-collapsible-section>
		<wcd-collapsible-section *ngIf="!notificationRule.isGlobalNotification"
			[label]="deviceGroupLabel">
			<simple-data-list-table
				[dataList]="deviceGroupDisplay"
				[openPanelForAllItems]="true"
				[titleKey]="'email_notification_deviceGroupsTable_title'"
				[seeAllKey]="'deviceGroups_seeAll_link'"
			></simple-data-list-table>
		</wcd-collapsible-section>
		<section class="wcd-padding-large-horizontal">
			<wcd-dl [display]="dlDisplayMode">
				<div class="title">{{ 'email_notification_activity_title' | i18n }}</div>
				<dl role="none">
					<dt role="none">{{ 'tvm_common_createdOn' | i18n }}</dt>
					<dd role="none">{{ notificationRule.createdOn | date: 'shortDate' }}</dd>
				</dl>
				<dl role="none">
					<dt role="none">{{ 'tvm_common_createdBy' | i18n }}</dt>
					<dd role="none">{{ notificationRule.createdBy.email }}</dd>
				</dl>
				<dl role="none">
					<dt role="none">{{ 'tvm_common_lastUpdatedOn' | i18n }}</dt>
					<dd role="none">{{ lastModifiedDate | date: 'shortDate' }}</dd>
				</dl>
				<dl role="none">
					<dt role="none">{{ 'tvm_common_lastUpdatedBy' | i18n }}</dt>
					<dd role="none">{{ lastModifiedBy }}</dd>
				</dl>
			</wcd-dl>
		</section>
	</div>
	<footer class="wcd-flex-horizontal wcd-border-top flex-bottom wcd-padding-all wcd-padding-large-horizontal">
		<div *ngIf="isEnabled !== notificationRule.isEnabled" class="wcd-flex-1 wcd-padding-small-vertical">
			<fab-primary-button
				(onClick)="updateStatus()"
				[disabled]="isSaving">
				{{ 'buttons_save' | i18n }}
			</fab-primary-button>
		</div>
	</footer>
</div>
