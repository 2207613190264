var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { EvidenceAlertsSummary, Mailbox, severityValues } from '@wcd/domain';
var ɵ0 = function (mailbox) { return ({ id: mailbox.id }); }, ɵ1 = function (config, query) {
    return query && query.where && query.where['useMailboxAlertsVnextApi'] ?
        "Evidences/mailbox/" + query.where['id'] + "/AssociatedAlerts" :
        "evidence/mailbox/" + query.where['id'] + "/alerts";
}, ɵ2 = function (config, query) {
    return query && query.where && query.where['useMailboxAlertsVnextApi'] ?
        config.data.serviceUrls.alertsApiService :
        config.data.serviceUrls.threatIntel;
}, ɵ3 = function (data, _, query) {
    return query && query.where && query.where['useMailboxAlertsVnextApi'] ?
        ({
            Items: data.items && data.items.map(function (securityAlert) { return ({
                AlertId: securityAlert.alertId,
                Title: securityAlert.alertDisplayName,
                Categories: [securityAlert.category],
                FirstEventTime: new Date(securityAlert.startTimeUtc),
                LastEventTime: new Date(securityAlert.endTimeUtc),
                Severity: severityValues.find(function (value) { return value.name === securityAlert.severity; }).id,
            }); }),
            IncidentCount: data.incidentCount,
            AlertCount: data.alertCount
        }) : data;
};
var MailboxAlertsRelationship = /** @class */ (function () {
    function MailboxAlertsRelationship() {
    }
    MailboxAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Mailbox,
            dataEntity: EvidenceAlertsSummary,
            allowedTypes: [RelationshipType.OneToOne],
            getRelationshipData: ɵ0,
            fixedData: { lookBackInDays: 180, sortOrder: 'Asc', pageSize: 20, sortByField: 'FirstSeen' },
            endpoint: ɵ1,
            baseUrl: ɵ2,
            parseData: ɵ3
        })
    ], MailboxAlertsRelationship);
    return MailboxAlertsRelationship;
}());
export { MailboxAlertsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
