import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Alert User',
	pluralName: 'Alert Users',
	readonly: true,
})
export class AlertUser extends ModelBase {
	@EntityField({ data: ['UserSid', 'AccountSid'] })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
	@EntityField({ data: ['UserName', 'AccountName'] })
	name: string;
	@EntityField({ data: 'DomainName' })
	domain: string;

	constructor(...args) {
		super(...args);
		this.accountName = this.accountName || this.name;
		this.accountDomainName = this.accountDomainName || this.domain;
	}
	sid: string = this.id;

	accountName: string;

	accountDomainName: string;

	get fullName(): string {
		return `${this.domain ? this.domain + '\\' : ''}${this.name || ''}`;
	}
}
