import assertNever from 'assert-never';
import { getUserTimeZone } from './locale.utils';

export const FULL_DATE_WITH_MILLISECONDS_FORMAT = 'MMM d, y, h:mm:ss.SSS a';
export const FULL_TIME_WITH_MILLISECONDS_FORMAT = 'h:mm:ss.SSS a';

export function shiftTime(
	date: Date,
	options: {
		type: 'milliseconds' | 'hours';
		amount: number;
	}
) {
	if (!date || !options || isNaN(options.amount)) {
		console.warn("missing or incorrect parameters. can't shift time.");
		return date;
	}

	switch (options.type) {
		case 'hours':
			return shiftTimeHours(date, options.amount);
		case 'milliseconds':
			return shiftTimeMilliseconds(date, options.amount);
		default:
			assertNever(options.type);
	}
}

function shiftTimeMilliseconds(date: Date, milliseconds: number): Date {
	if (!date || isNaN(milliseconds)) {
		console.warn("missing or incorrect parameters. can't shift time.");
		return date;
	}
	date.setMilliseconds(date.getMilliseconds() + milliseconds);
	return date;
}

function shiftTimeHours(date: Date, hours: number): Date {
	return shiftTimeMilliseconds(date, hours * 60 * 60 * 1000);
}

export function resetToUTC(date: Date): Date {
	if (!date) {
		console.warn("missing parameters. can't reset date.");
		return date;
	}

	return shiftTimeHours(date, -getUserTimeZone());
}

export function resetFromUTC(date: Date): Date {
	if (!date) {
		console.warn("missing parameters. can't reset date.");
		return date;
	}

	return shiftTimeHours(date, getUserTimeZone() + 1);
}

