/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./action-history-base.entity-panel.component.ngfactory";
import * as i2 from "./action-history-base.entity-panel.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i5 from "../../../rbac/services/rbac-control.service";
import * as i6 from "../services/pending-action-finder.service";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "./pending-action-history.entity-panel.component";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
var styles_PendingActionHistoryEntityPanelComponent = [];
var RenderType_PendingActionHistoryEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PendingActionHistoryEntityPanelComponent, data: {} });
export { RenderType_PendingActionHistoryEntityPanelComponent as RenderType_PendingActionHistoryEntityPanelComponent };
export function View_PendingActionHistoryEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "action-history-base-panel", [], null, [[null, "requestMetadataRefresh"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("requestMetadataRefresh" === en)) {
        var pd_0 = (_co.requestMetadataRefresh.emit() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ActionHistoryBaseEntityPanelComponent_0, i1.RenderType_ActionHistoryBaseEntityPanelComponent)), i0.ɵdid(2, 180224, null, 0, i2.ActionHistoryBaseEntityPanelComponent, [i0.ChangeDetectorRef, i3.Paris, i4.AppContextService, i5.RbacControlService, i6.ActionFinderService, i7.I18nService], { moreActionsFilter: [0, "moreActionsFilter"], moreActionsEnabled: [1, "moreActionsEnabled"], options: [2, "options"], isInit: [3, "isInit"], entity: [4, "entity"] }, { requestMetadataRefresh: "requestMetadataRefresh" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.moreActionsFilter; var currVal_1 = _co.moreActionsEnabled; var currVal_2 = _co.options; var currVal_3 = _co.isInit; var currVal_4 = _co.entity; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_PendingActionHistoryEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pending-action-history-panel", [], null, null, null, View_PendingActionHistoryEntityPanelComponent_0, RenderType_PendingActionHistoryEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i8.PendingActionHistoryEntityPanelComponent, [i0.ChangeDetectorRef, i9.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PendingActionHistoryEntityPanelComponentNgFactory = i0.ɵccf("pending-action-history-panel", i8.PendingActionHistoryEntityPanelComponent, View_PendingActionHistoryEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { PendingActionHistoryEntityPanelComponentNgFactory as PendingActionHistoryEntityPanelComponentNgFactory };
