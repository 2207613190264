
		<fab-icon
			*ngIf="showIcon && displayName"
			[iconName]="FabricIconNames.Contact"
			[contentClass]="iconCssClass"
		></fab-icon>
		<route-link
			*ngIf="showLink && (sid || aadUserId); else userDisplayName"
			[routeLink]="userRouteLinkModel"
		>
			<ng-container *ngTemplateOutlet="userDisplayName"></ng-container>
		</route-link>
		<ng-template #userDisplayName>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="displayName">{{ displayName }}</span>
		</ng-template>
	