var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
var RecommendationExceptionEntityPanelComponent = /** @class */ (function (_super) {
    __extends(RecommendationExceptionEntityPanelComponent, _super);
    function RecommendationExceptionEntityPanelComponent(changeDetectorRef) {
        return _super.call(this, changeDetectorRef) || this;
    }
    Object.defineProperty(RecommendationExceptionEntityPanelComponent.prototype, "recommendationException", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    RecommendationExceptionEntityPanelComponent.prototype.initEntity = function (recommendationException, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, recommendationException, isExtendedData);
        this.changeDetectorRef.markForCheck();
    };
    return RecommendationExceptionEntityPanelComponent;
}(EntityPanelComponentBase));
export { RecommendationExceptionEntityPanelComponent };
