
		<div [ngClass]="verticalDirection ? 'vertical-daterange' : 'daterange'" *ngIf="dateRange">
			<label>
				<span
					[ngClass]="verticalDirection ? 'vertical-daterange-label' : 'daterange-label'"
					(ngModelChange)="onChange(dateRange)"
				>
					{{ 'common.datepicker.from' | i18n }}
				</span>
				<datepicker
					[(ngModel)]="dateRange.from"
					(ngModelChange)="onChange(dateRange)"
					[allowExactTimeSelection]="allowExactTimeSelection"
					[earliestDateAllowed]="earliestDateAllowed"
					[latestDateAllowed]="latestDateAllowed"
					[ariaLabel]="'common.datepicker.from' | i18n"
					[showInPanel]="showInPanel"
				></datepicker>
			</label>
			<label>
				<span [ngClass]="verticalDirection ? 'vertical-daterange-label' : 'daterange-label'">{{
					'common.datepicker.to' | i18n
				}}</span>
				<datepicker
					class="daterange-datepicker"
					[(ngModel)]="dateRange.to"
					(ngModelChange)="onChangeTo(dateRange)"
					[allowExactTimeSelection]="allowExactTimeSelection"
					[earliestDateAllowed]="earliestDateAllowedTo"
					[latestDateAllowed]="latestDateAllowedTo"
					[ariaLabel]="'common.datepicker.to' | i18n"
					[showInPanel]="showInPanel"
				></datepicker>
			</label>
		</div>
	