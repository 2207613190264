import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { SoftwareVersion, Tag, EolState, VERSION_DISTRIBUTION_FILTER_TAG } from '@wcd/domain';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { PrettyNumberService } from '@wcd/prettify';
import { FeaturesService, Feature } from '@wcd/config';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { FilterValuesChecklistComponent, ListFilterValueConfig } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SoftwareVersionFieldsService implements FieldsService<SoftwareVersion> {
	private _fields: Array<DataviewField<SoftwareVersion>>;
	private _linuxSoftwareInstallationFields: Array<DataviewField<SoftwareVersion>>;

	private _fieldsConfig: Array<DataviewFieldConfig<SoftwareVersion>> = [
		{
			id: 'version',
			name: this.i18nService.get('tvm.common.version'),
		},
		{
			id: 'installations',
			name: this.i18nService.get('tvm.versionDetails.installedOn'),
			sort: {
				sortDescendingByDefault: true,
			},
			getDisplay: (softwareVersion: SoftwareVersion) =>
				this.prettyNumberService.prettyNumber(softwareVersion.installations),
		},
		{
			id: 'discoveredVulnerabilities',
			name: this.i18nService.get('tvm.versionDetails.discoveredVulnerabilities'),
			sort: {
				sortDescendingByDefault: true,
			},
		},
		{
			id: 'tags',
			name: this.i18nService.get('common.tags'),
			component: {
				type: TagsFieldComponent,
				getProps: (version: SoftwareVersion) => ({
					tags: this.tvmTagsService.getSoftwareVersionTags(version),
				}),
			},
			sort: { enabled: false },
		},
		{
			id: 'versionDistributionTags',
			name: this.i18nService.get('common.tags'),
			filterOnly: true,
			filter: {
				component: {
					type: FilterValuesChecklistComponent,
					config: {
						mapFilterValue: (
							filterValue: any
						): ListFilterValueConfig<VERSION_DISTRIBUTION_FILTER_TAG> => {
							const values = this.tvmTagsService
								.getSoftwareVersionTagsForFiltering()
								.map((tag) => {
									return {
										value: VERSION_DISTRIBUTION_FILTER_TAG[tag],
										name: this.i18nService.get(
											`tvm_softwarePage_versionDistribution_filters_tags_${tag}`
										),
										count: null,
									};
								});
							const v = values.find(
								(val) => val.value === VERSION_DISTRIBUTION_FILTER_TAG[filterValue]
							);
							return {
								id: v.value,
								rawValue: v.value,
								name: v.name,
							};
						},
					},
				},
			},
		},
	];

	constructor(
		private prettyNumberService: PrettyNumberService,
		private readonly featuresService: FeaturesService,
		private tvmTagsService: TvmTagsService,
		private i18nService: I18nService
	) {}

	get fields(): Array<DataviewField<SoftwareVersion>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<SoftwareVersion>(this._fieldsConfig);
		}
		return this._fields;
	}

	get linuxSoftwareVersionFields(): Array<DataviewField<SoftwareVersion>> {
		if (!this._linuxSoftwareInstallationFields) {
			const linuxFields = [
				...this._fieldsConfig.slice(0, 1),
				{
					id: 'osDistribution',
					name: this.i18nService.get('tvm_common_osDistribution'),
					sort: { enabled: false },
					getDisplay: (softwareVersion: SoftwareVersion) =>
						softwareVersion.osDistribution
							? this.i18nService.get(
									`tvm_common_osDistribution_${softwareVersion.osDistribution}`
							  )
							: this.i18nService.strings.notAvailable_short,
				},
				...this._fieldsConfig.slice(1),
			];
			this._linuxSoftwareInstallationFields = DataviewField.fromList<SoftwareVersion>(linuxFields);
		}
		return this._linuxSoftwareInstallationFields;
	}
}
