var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { EvidenceAssetTypeEntity, EvidenceAssetTypeType } from './evidence-asset-type.entity';
var EvidenceAsset = /** @class */ (function (_super) {
    __extends(EvidenceAsset, _super);
    function EvidenceAsset(data) {
        var _this = _super.call(this, data) || this;
        _this.displayName =
            _this.name ||
                (_this.assetType && _this.assetType.id === EvidenceAssetTypeType.Machine ? _this.machineId : null);
        return _this;
    }
    __decorate([
        EntityField({ data: 'asset.name' }),
        __metadata("design:type", String)
    ], EvidenceAsset.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'asset_type' }),
        __metadata("design:type", EvidenceAssetTypeEntity)
    ], EvidenceAsset.prototype, "assetType", void 0);
    __decorate([
        EntityField({ data: 'asset.machine_id' }),
        __metadata("design:type", String)
    ], EvidenceAsset.prototype, "machineId", void 0);
    __decorate([
        EntityField({ data: 'asset.rbac_group_id' }),
        __metadata("design:type", Number)
    ], EvidenceAsset.prototype, "rbacGroupId", void 0);
    EvidenceAsset = __decorate([
        ValueObject({
            singularName: 'Evidence',
            pluralName: 'Evidence',
        }),
        __metadata("design:paramtypes", [Object])
    ], EvidenceAsset);
    return EvidenceAsset;
}(ModelBase));
export { EvidenceAsset };
