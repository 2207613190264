import { sccHostService } from '@wcd/scc-interface';
import { AxiosError } from 'axios-types';

export class SccLogService {

	constructor(private sccLogger) {
	}

	trackEvent(name: string, properties?: { [key: string]: any }) {
		this.sccLogger.logEvent(`wicd-${name}`, properties);
	}

	trackException(exception: Error, properties?: { [key: string]: any }) {
		if (!exception || !exception.message) {
			this.sccLogger.logError(exception);
			return;
		}
		this.sccLogger.logError(exception.message || exception.name, { stack: exception.stack, ...properties });
	}

	/**
	 * Measures and logs the time in milliseconds an event took.
	 * A call to markPerformanceEventStart with the same event key must take place at the event start, before calling this function.
	 * @param event: key of the measured event
	 * @param properties: additional data to append to the log.
	 */
	trackPerformance(event: string, properties?: { [key: string]: any }) {
		const eventName = `measurements-${event}`;
		performance.measure(eventName, event);
		const entries = performance.getEntriesByName(eventName);
		if (!entries || entries.length === 0) {
			return;
		}
		const totalTime = entries.pop().duration;
		this.trackEvent(eventName, { totalTime, ...properties });
	}

	/**
	 * Marks the start of a measured event, for performance tracking.
	 * Call trackPerformance(event) To finish the measure and log the result.
	 * @param event: key of the measured event
	 */
	markPerformanceEventStart(event: string | Array<string>) {
		const events = Array.isArray(event) ? event : [event];
		events.forEach(e => performance.mark(e));
	}

	getResourceLoadingByPkg(pkgName: string){
		//@ts-ignore
		const items = performance.getEntries().filter(e => (e.initiatorType === 'script' || e.initiatorType === 'fetch') && e.name.includes(`/${pkgName}/`));
		return items.map((sc) => ({
			file: sc.name.replace(/^.*[\\\/]/, ''),
			duration: sc.duration
		}))
	}

	trackSccScriptLoadDurationsByPackage(pkgName: string) {
		try {
			const props = {
				pkgName,
				pckVersion: sccHostService.resource.version(pkgName),
				measurements: this.getResourceLoadingByPkg(pkgName)
			}
			this.trackEvent('wcd-script-load-durations-' + pkgName, props);
		} catch (e) {
			console.warn('Failed to track scriptLoadDuration');
		}
	}

	registerUrlPiiScrubber(urlPiiScrubber: UrlPiiScrubberFunction, scrubberConfig: IPiiScrubberConfig){
		this.sccLogger.registerUrlPiiScrubber(urlPiiScrubber,scrubberConfig);
	}
	registerHeadersPiiScrubber(headersPiiScrubber: HeadersPiiScrubberFunction, scrubberConfig: IPiiScrubberConfig){
		this.sccLogger.registerHeadersPiiScrubber(headersPiiScrubber,scrubberConfig);
	}
	registerApiErrorPiiScrubber(apiErrorPiiScrubber: ApiErrorPiiScrubberFunction, scrubberConfig: IPiiScrubberConfig){
		this.sccLogger.registerApiErrorPiiScrubber(apiErrorPiiScrubber,scrubberConfig);
	}
	registerScenarioPiiScrubber<T extends any>(scenarioPiiScrubber: ScenarioPiiScrubberFunction<T>, scrubberConfig: IScenarioPiiScrubberConfig){
		this.sccLogger.registerScenarioPiiScrubber(scenarioPiiScrubber,scrubberConfig);
	}

	scrubScenarioPii<T extends any>(data: T, scenarioName: string): T{
		return this.sccLogger.scrubScenarioPii(data,scenarioName);
	}
}
export type UrlPiiScrubberFunction = (url: string) => string;
export type HeadersPiiScrubberFunction = (headers: { [key: string]: any }, scrubberContext) => { [key: string]: any };
export type ApiErrorPiiScrubberFunction = (apiError: AxiosError) => AxiosError;
export type ScenarioPiiScrubberFunction<T> = (logObject: T) => T;

export interface IPiiScrubberConfig {
	name: string;
	clientPackages: string[] | RegExp;
	routes?: string[] | RegExp;
}
export interface IScenarioPiiScrubberConfig extends IPiiScrubberConfig {
	scenario: string;
}
