
		<wcd-panel (close)="destroy()" [settings]="settings">
			<header class="wcd-flex-none wcd-padding-bottom wcd-padding-large-right wcd-flex-horizontal">
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="email-headers-panel-header" #panelHeader>
						{{ i18nService.strings.airsEntities_email_headers_panel_title }}
					</h3>
				</div>
			</header>
			<div>
				<markdown>
					{{ i18nService.strings.airsEntities_email_headers_panel_analyzerInfoLink }}
				</markdown>
			</div>
			<div class="wcd-padding-vertical">
				<span>
					<a target="_blank" href="https://go.microsoft.com/fwlink/?linkid=874390">
						{{ i18nService.strings.airsEntities_email_headers_panel_analyzer }}
						<fab-icon iconName="OpenInNewWindow" className="small-icon"></fab-icon>
					</a>
				</span>
			</div>
			<div
				*ngLet="(headers$ | async) as headers"
				class="wcd-padding-medium-left header-border wcd-scroll-vertical"
			>
				<ng-container
					*ngIf="headers === LOADING_SYMBOL; else checkHeaders"
					[ngTemplateOutlet]="loader"
				></ng-container>
				<ng-template #checkHeaders>
					<ng-container *ngIf="headers?.length; else noHeaders">
						<div class="wcd-padding-bottom">
							<copy-to-clipboard
								[content]="headers.join('\n')"
								[settings]="{
									copyText:
										i18nService.strings.airsEntities_email_headers_panel_copyToClipboard
								}"
							></copy-to-clipboard>
						</div>
						<div *ngFor="let header of headers">
							<p class="break-all">{{ header }}</p>
						</div>
					</ng-container>
				</ng-template>
				<ng-template #noHeaders>
					<div>{{ i18nService.strings.airsEntities_email_headers_panel_noHeadersFound }}</div>
				</ng-template>
			</div>
		</wcd-panel>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.large"></fab-spinner>
			</div>
		</ng-template>
	