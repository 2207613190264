/* tslint:disable:template-click-events-have-key-events */
/* tslint:disable:template-accessibility-alt-text */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ConvergencePromotionService } from '../services/convergence-promotion.service';
import { RoutesService } from '@wcd/shared';
import { FabricIconNames } from '@wcd/scc-common';
import { Feature, FeaturesService } from '@wcd/config';

@Component({
	selector: 'convergence-promotion-banner',
	templateUrl: './convergence-promotion-banner.component.html',
	styleUrls: ['./convergence-promotion-banner.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConvergencePromotionBannerComponent {
	@Input() sccPagePath: string = '/'; // The path to navigate to in SCC
	private hide: boolean = false;
	openSccIcon: string = FabricIconNames.OpenInNewWindow;

	isL2Enabled: boolean = this.featuresService.isEnabled(Feature.ConvergencePromotionL2);
	title: string = this.convergencePromotionService.title;
	desc: string = this.convergencePromotionService.shortDesc;
	buttonTitle: string = this.convergencePromotionService.buttonTitle;

	constructor(
		private readonly convergencePromotionService: ConvergencePromotionService,
		private readonly featuresService: FeaturesService
	) {}

	close = (): void => {
		this.convergencePromotionService.dismiss();
		this.hide = true;
	};

	shouldHideBanner = () => this.hide || !this.convergencePromotionService.shouldShowPromotionBanner();
	navigateTo = () => `${RoutesService.getSCCRootUrl()}${this.sccPagePath}`;
}
