/* tslint:disable:template-accessibility-alt-text */
import { OnDestroy } from '@angular/core';
import { AgentEndpointsManagementState } from '@wcd/domain';
import { DetectionTestSettingsService, } from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { map } from 'rxjs/operators';
var AgentOnboardingComponent = /** @class */ (function () {
    function AgentOnboardingComponent(paris, endpointManagementService, detectionTestSettingsService, dialogsService, i18nService) {
        this.paris = paris;
        this.endpointManagementService = endpointManagementService;
        this.detectionTestSettingsService = detectionTestSettingsService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.OsType = OsType;
        this.loading = true;
        this.error = false;
        this.onboardingState = new AgentEndpointsManagementState(null);
        this.detectionTestSettings = this.detectionTestSettingsService.all.agent;
        this.osTypeKey = OsType.client;
        this.getStateFromBackend();
    }
    Object.defineProperty(AgentOnboardingComponent.prototype, "isServer", {
        set: function (isServer) {
            this.osTypeKey = isServer ? OsType.server : OsType.client;
        },
        enumerable: true,
        configurable: true
    });
    AgentOnboardingComponent.prototype.ngOnDestroy = function () {
        this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
    };
    AgentOnboardingComponent.prototype.getStateFromBackend = function () {
        var _this = this;
        this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
        this.getOnboardingStateSubscription = this.endpointManagementService
            .getAgentOnboardingState()
            .subscribe(function (state) {
            _this.loading = false;
            _this.setState(state);
        }, function (error) {
            _this.loading = false;
            _this.error = true;
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.agent.onboarding.getMachineStatus.failure'),
                data: error,
            });
        });
    };
    AgentOnboardingComponent.prototype.setState = function (state) {
        this.onboardingState = state;
        if (this.onboardingState.status &&
            this.onboardingState.status !==
                this.endpointManagementService.getAgentOnboardingStatusByType('inProgress'))
            this.getOnboardingStateSubscription && this.getOnboardingStateSubscription.unsubscribe();
    };
    AgentOnboardingComponent.prototype.confirmAndTurnOnServerMonitoring = function () {
        var _this = this;
        this.dialogsService
            .confirm({
            title: this.i18nService.get("endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".confirm.title"),
            text: this.i18nService.get("endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".confirm.description"),
            confirmText: this.i18nService.get("endpointManagement.agent.onboarding.step1." + this.osTypeKey + ".confirm.yes"),
        })
            .then(function (e) { return e.confirmed && _this.doTurnOn(); });
    };
    AgentOnboardingComponent.prototype.doTurnOn = function () {
        var _this = this;
        this.endpointManagementService
            .turnOnServerMonitoring()
            .pipe(map(function (data) { return !!data; }))
            .subscribe(function (success) {
            _this.getStateFromBackend();
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.agent.onboarding.turnOnServer.failure'),
                data: error,
            });
        });
    };
    return AgentOnboardingComponent;
}());
export { AgentOnboardingComponent };
var OsType;
(function (OsType) {
    OsType["client"] = "client";
    OsType["server"] = "server";
})(OsType || (OsType = {}));
