import { Entity } from '@microsoft/paris';
import { StatusModel } from '../../status.model';
import { incidentStatusValues } from './incident-status.entity.values';
import { IncidentStatusId } from './incident-status.models';

@Entity({
	singularName: 'Incident Status',
	pluralName: 'Incident Statuses',
	values: incidentStatusValues,
})
export class IncidentStatus extends StatusModel<IncidentStatusId> {
	isOpen: boolean;
}
