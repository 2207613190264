import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { PackageFile } from './package-file.value-object';

@ApiCall({
	name: 'Android endpoint management download APK',
	endpoint: 'Content',
	method: 'GET',
	type: PackageFile,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
	parseQuery: (fileName: string) => {
		return { params: { query: new Array(fileName) } };
	},
})
export class AndroidEndpointManagementApiCall extends ApiCallModel<PackageFile[], string> {}
