/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, ElementRef, EventEmitter, OnDestroy, } from '@angular/core';
import { DimensionsModel } from '../../models/dimensions.model';
import { PositionModel } from '../../models/position.model';
import { PositionService } from '../../services/position.service';
var DIALOG_MARGIN = 70;
var ModalComponent = /** @class */ (function () {
    function ModalComponent(elementRef, changeDetectorRef) {
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.close = new EventEmitter();
        this.isInit = false;
        // Added by default to fix modal position on IE11. On special cases could be turned off by passing false
        this.ieFixedPosition = true;
    }
    Object.defineProperty(ModalComponent.prototype, "settings", {
        get: function () {
            return this._settings;
        },
        set: function (settings) {
            this.setSettings(settings);
        },
        enumerable: true,
        configurable: true
    });
    ModalComponent.prototype.onKeydown = function ($event) {
        if ($event.keyCode === 27) {
            // ESCAPE
            $event.preventDefault();
            $event.stopPropagation();
            this.close.emit();
            return false;
        }
        return true;
    };
    ModalComponent.prototype.overlayClicked = function (event) {
        if (event.target === this.modalOverlay.nativeElement) {
            this.close.emit();
        }
    };
    ModalComponent.prototype.getDimensions = function () {
        var clientRect = this._dialogBodyElement.nativeElement.getBoundingClientRect();
        return new DimensionsModel(clientRect.width, clientRect.height);
    };
    ModalComponent.prototype.setSettings = function (settings) {
        var _this = this;
        this._settings = settings;
        if (settings && !settings.position) {
            this.isCentered = true;
        }
        if (settings && settings.position && !settings.position.left) {
            this.isHorizontalCentered = true;
        }
        if (settings && (settings.position || settings.dimensions)) {
            setTimeout(function () {
                var settingsDimensions = settings.dimensions;
                if (settingsDimensions) {
                    if (settingsDimensions.width)
                        _this._dialogBodyElement.nativeElement.style.width = settingsDimensions.width + 'px';
                    if (settingsDimensions.height)
                        _this._dialogBodyElement.nativeElement.style.height = settingsDimensions.height + 'px';
                }
                if (!settingsDimensions || !settingsDimensions.width || !settingsDimensions.height) {
                    var calculatedDimensions = _this.getDimensions();
                    if (!settingsDimensions)
                        settingsDimensions = calculatedDimensions;
                    else {
                        if (!settingsDimensions.width)
                            settingsDimensions.width = calculatedDimensions.width;
                    }
                }
                var dimensions = settingsDimensions.constrainTo(null, 20);
                var position;
                if (settings.position) {
                    position = new PositionModel(settings.position.top, settings.position.left);
                    position = PositionService.fitPosition(position, dimensions, DIALOG_MARGIN);
                }
                setTimeout(function () {
                    _this.dialogStyle = {
                        left: position ? position.left + 'px' : undefined,
                        top: position ? position.top + 'px' : undefined,
                        'max-width': dimensions.width + 'px',
                        width: 'auto',
                        height: 'auto',
                        overflow: 'auto'
                    };
                    _this.changeDetectorRef.detectChanges();
                    setTimeout(function () {
                        _this.isInit = true;
                        _this.changeDetectorRef.detectChanges();
                    }, 40);
                });
            }, 40);
        }
        else {
            this.dialogStyle = null;
            setTimeout(function () {
                _this.isInit = true;
                _this.changeDetectorRef.detectChanges();
            }, 40);
        }
    };
    ModalComponent.prototype.ngOnDestroy = function () {
        try {
            this.changeDetectorRef.detectChanges();
        }
        catch (_a) { }
    };
    return ModalComponent;
}());
export { ModalComponent };
