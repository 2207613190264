import { DataQuery, Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

export interface ManagedThreatHuntingStatusParams {
	tenantId: string
}

interface ManagedThreatHuntingStatusDataQuery extends DataQuery {
	where: ManagedThreatHuntingStatusParams
}

@Entity({
	singularName: 'MTE bilbao enabled - status',
	pluralName: 'MTE bilbao enabled - status',
	// endpoint is used when update bilbao flag API is called
	endpoint: (_config: WcdPortalParisConfig, query: ManagedThreatHuntingStatusDataQuery) => `tenants/${query.where.tenantId}/mte-classic-setting`,
	// parseItemQuery is used when get bilbao flags API is called
	parseItemQuery: (_itemId, _entity, _config, params: ManagedThreatHuntingStatusParams) => `tenants/${params.tenantId}/mte-classic-setting`,
	saveMethod: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => `${config.data.serviceUrls.defenderExpertsService}/mte/provisioning-store/v1.2`,
	readonly: true,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class ManagedThreatHuntingStatus extends EntityModelBase {
	@EntityField({ data: 'bilbaoApproved', defaultValue: false })
	managedThreatHuntingApproved: boolean;

	@EntityField({ data: 'bilbaoEnabled', defaultValue: false })
	managedThreatHuntingEnabled: boolean;

	failed: boolean = false;
}
