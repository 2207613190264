
		<button [class.disabled]="disabled || isBusy"
			id="{{ elementId + (isInMoreMenu ? '-more' : '')}}"
			class="hidden-action command-bar-item-button"
			*ngIf="shouldRender"
			[attr.data-track-id]="dataTrackId"
			[attr.aria-label]="localizedTooltip"
			[attr.data-track-type]="dataTrackType || 'Button'"
			[wcdTooltip]="localizedTooltip"
			[disabled]="disabled || isBusy"
			(click)="onClickCallback()"
			#buttonEl
		>

			<i class="loader-icon wcd-margin-small-right" [hidden]="!isBusy"></i>
			<wcd-shared-icon [iconName]="icon" [hidden]="isBusy"> </wcd-shared-icon>
			{{ smallScreenView ? '' : isBusy ? localizedBusyLabel : localizedLabel }}

		</button>
	