import { Injector } from '@angular/core';
import { throwError, from, of } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { catchError, tap, switchMap } from 'rxjs/operators';
import { AuthBackendService } from '../services/auth.backend.service';
import { isUndefined } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { APC_HEADER_KEY } from '@wcd/auth';
var AuthInterceptor = /** @class */ (function () {
    function AuthInterceptor(injector) {
        this.injector = injector;
    }
    AuthInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        var _a;
        var authService = this.injector.get(AuthService); // can't get it in constructor because it creates circular dependency
        var authReq = req;
        if (isUndefined(authReq.withCredentials))
            authReq = authReq.clone({ withCredentials: true });
        if (authService.token)
            authReq = authReq.clone({
                setHeaders: { authorization: "Bearer " + authService.token },
            });
        if (authService.tenantId)
            authReq = authReq.clone({ setHeaders: { 'Tenant-Id': authService.tenantId } });
        if (authService.apcHeader)
            authReq = authReq.clone({ setHeaders: (_a = {}, _a[APC_HEADER_KEY] = authService.apcHeader, _a) });
        var intercept = !sccHostService.isSCC
            ? of(authReq)
            : from(sccHostService.auth.getToken().then(function (token) {
                return authReq.clone({
                    setHeaders: {
                        authorization: "Bearer " + token,
                    },
                });
            }));
        return intercept.pipe(switchMap(function (modifiedReq) {
            var newReq = req.clone(modifiedReq);
            return next.handle(newReq);
        }), tap(function (res) {
            var apc = res.headers && res.headers.get(APC_HEADER_KEY);
            if (apc)
                authService.apcHeader = apc;
        }), catchError(function (res) {
            if (res.status === 401) {
                var authBackendService = _this.injector.get(AuthBackendService), doNotCheckAuthUrls = [
                    authBackendService.loginUrl,
                    authBackendService.authValidationUrl,
                ];
                authService.handleAuthError(doNotCheckAuthUrls.every(function (url) { return url.toLowerCase() !== authReq.url.toLowerCase(); }));
            }
            return throwError(res);
        }));
    };
    return AuthInterceptor;
}());
export { AuthInterceptor };
