/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i6 from "../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i7 from "@angular/platform-browser";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "./dropdown.component";
var styles_DropdownComponent = [];
var RenderType_DropdownComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_DropdownComponent, data: {} });
export { RenderType_DropdownComponent as RenderType_DropdownComponent };
function View_DropdownComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [["class", "dropdown-directive-button-image"]], [[1, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.buttonImage; _ck(_v, 0, 0, currVal_0); }); }
function View_DropdownComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "wcd-shared-icon", [["class", "dropdown-directive-button-icon"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(3, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "dropdown-directive-button-icon"; var currVal_1 = _co.buttonIconClass; _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _co.buttonIcon; _ck(_v, 3, 0, currVal_2); }, null); }
function View_DropdownComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [["class", "dropdown-icon"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = "carets.down"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DropdownComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ("dropdown-directive-button-badge " + _co.buttonBadgeClass); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.buttonBadge; _ck(_v, 3, 0, currVal_1); }); }
export function View_DropdownComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 32, "div", [["class", "dropdown-directive"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 18, "button", [["class", "dropdown-directive-button"], ["type", "button"]], [[1, "id", 0], [2, "dropdown-directive-button-arrow", null], [1, "aria-label", 0], [8, "disabled", 0], [1, "role", 0]], [[null, "click"], [null, "focus"], [null, "blur"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 6).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 6).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.toggle() !== false);
        ad = (pd_4 && ad);
    } if (("focus" === en)) {
        var pd_5 = (_co.focus.emit() !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (_co.blur.emit() !== false);
        ad = (pd_6 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(6, 147456, null, 0, i5.TooltipDirective, [i0.ElementRef, i6.TooltipsService, i7.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownComponent_1)), i0.ɵdid(9, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownComponent_2)), i0.ɵdid(12, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(14, 0, null, null, 1, "span", [["class", "dropdown-directive-label"]], null, null, null, null, null)), (_l()(), i0.ɵted(15, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownComponent_3)), i0.ɵdid(18, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), i0.ɵncd(null, 0), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_DropdownComponent_4)), i0.ɵdid(24, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(26, 0, null, null, 6, "div", [["class", "dropdown-directive-menu"], ["tabindex", "-1"]], null, [[null, "keydown.escape"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown.escape" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(28, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵdid(29, 1458176, null, 0, i8.CdkTrapFocus, [i0.ElementRef, i8.FocusTrapFactory, i2.DOCUMENT], { enabled: [0, "enabled"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), i0.ɵncd(null, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = "dropdown-directive-button"; var currVal_7 = _co.buttonClass; _ck(_v, 5, 0, currVal_6, currVal_7); var currVal_8 = _co.buttonTooltip; _ck(_v, 6, 0, currVal_8); var currVal_9 = _co.buttonImage; _ck(_v, 9, 0, currVal_9); var currVal_10 = _co.buttonIcon; _ck(_v, 12, 0, currVal_10); var currVal_12 = _co.showIconDropdown; _ck(_v, 18, 0, currVal_12); var currVal_13 = _co.buttonBadge; _ck(_v, 24, 0, currVal_13); var currVal_14 = "dropdown-directive-menu"; var currVal_15 = _co.menuClass; _ck(_v, 28, 0, currVal_14, currVal_15); var currVal_16 = _co.isOpen; _ck(_v, 29, 0, currVal_16); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ariaLabel; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.buttonId; var currVal_2 = (!_co.buttonIcon || _co.showIconDropdown); var currVal_3 = _co.ariaLabel; var currVal_4 = _co.disabled; var currVal_5 = (_co.buttonRole ? _co.buttonRole : null); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_11 = _co.buttonText; _ck(_v, 15, 0, currVal_11); }); }
export function View_DropdownComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dropdown", [], null, null, null, View_DropdownComponent_0, RenderType_DropdownComponent)), i0.ɵdid(1, 245760, null, 0, i9.DropdownComponent, [i0.ElementRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DropdownComponentNgFactory = i0.ɵccf("dropdown", i9.DropdownComponent, View_DropdownComponent_Host_0, { buttonId: "buttonId", buttonText: "buttonText", buttonClass: "buttonClass", buttonIcon: "buttonIcon", buttonIconClass: "buttonIconClass", buttonImage: "buttonImage", buttonBadge: "buttonBadge", buttonTooltip: "buttonTooltip", buttonBadgeClass: "buttonBadgeClass", disabled: "disabled", closeOnClick: "closeOnClick", showIconDropdown: "showIconDropdown", menuClass: "menuClass", ariaLabel: "ariaLabel", buttonRole: "buttonRole" }, { focus: "focus", blur: "blur" }, ["[button-content]", "*"]);
export { DropdownComponentNgFactory as DropdownComponentNgFactory };
