import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';
import { Location } from '@angular/common';

@Injectable()
export class AirsEnabledGuard implements CanActivate {
	constructor(
		private appConfigService: AppConfigService,
		private router: Router,
		private location: Location
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		const data = route.routeConfig.data;
		let path = ['/enable_airs'];
		if (data && data.enableAirsRoute) path = data.enableAirsRoute;
		if (!this.appConfigService.isAutomatedIrEnabled) {
			this.router.navigate(path).then(() => {
				this.location.replaceState(state.url);
			});
			return false;
		}
		return true;
	}
}
