var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { TabModel } from '../../shared/components/tabs/tab.model';
import { ResearcherAssistanceApiCall } from '@wcd/domain';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { isNil } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
var AskThreatExpertModalComponent = /** @class */ (function (_super) {
    __extends(AskThreatExpertModalComponent, _super);
    function AskThreatExpertModalComponent(router, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.submitApiCall = ResearcherAssistanceApiCall;
        _this.currentTabId = 'collect';
        _this.setTabs();
        return _this;
    }
    AskThreatExpertModalComponent.prototype.onCollect = function (ticketId) {
        this.ticketId = ticketId;
        this.currentTabId = 'openTicket';
        this.setTabs();
    };
    AskThreatExpertModalComponent.prototype.selectTab = function (tab) {
        this.currentTabId = tab.id;
    };
    AskThreatExpertModalComponent.prototype.setTabs = function () {
        this.tabs = [
            {
                id: 'collect',
                name: "" + (this.ticketId ? '' : '1. ') + this.i18nService.get('researcherAssistance.tabs.collect'),
                icon: this.ticketId ? 'success' : null,
                iconClassName: 'ms-color-green',
            },
            {
                id: 'openTicket',
                name: "2. " + this.i18nService.get('researcherAssistance.tabs.openTicket'),
                disabled: isNil(this.ticketId),
            },
        ].map(function (tab) { return new TabModel(tab); });
    };
    return AskThreatExpertModalComponent;
}(ModalContainer));
export { AskThreatExpertModalComponent };
