import { ModuleWithProviders, NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { AlertsModule } from '../alerts/alerts.module';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { InvestigationActionsModule } from '../investigation_actions/investigation-actions.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { MtpInvestigationEntityTypeService } from './services/mtp-investigation.entity-type.service';
import { MtpInvestigationsService } from './services/mtp-investigations.service';
import { InvestigationsBackendService } from '../investigations/services/investigations.backend.service';
import { AirsEntitiesModule } from '../airs_entities/airs-entities.module';
import { PanelsModule } from '@wcd/panels';
import { WcdIconsModule } from '@wcd/icons';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { InvestigationsCommonModule } from '../investigations_common/investigations-common.module';
import { ThreatsDisplayNameService } from './services/threats-display-name-field.service';

@NgModule({
	imports: [
		RouterModule,
		SharedModule,
		DataViewsModule,
		AirsEntitiesModule,
		AlertsModule,
		InvestigationActionsModule,
		GlobalEntitiesModule,
		PanelsModule,
		WcdIconsModule,
		GlobalEntitiesModule,
		InvestigationsCommonModule,
	],
	declarations: [],
	exports: [RouterModule],
	entryComponents: [],
})
export class MtpInvestigationsModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(MtpInvestigationEntityTypeService);
	}

	static forRoot(): ModuleWithProviders {
		return {
			ngModule: MtpInvestigationsModule,
			providers: [
				InvestigationsBackendService,
				MtpInvestigationsService,
				MtpInvestigationEntityTypeService,
				ThreatsDisplayNameService,
			],
		};
	}
}
