import { Identifiable } from '../../data/models/identifiable.model';
import { ReadOnlyIdentifiableConfig } from '../../data/models/readonly-identifiable.model';
import { merge } from 'lodash-es';

export class CommentModel extends Identifiable<number> {
	user: string;
	action?: string;
	message: string;
	timestamp: Date;
	linkedObjects: { [index: string]: string | number };
	icon: string;

	constructor(data?: CommentConfig) {
		super(data);
	}

	setData(data: CommentConfig) {
		super.setData(data);

		this.user = data.user;
		this.message = data.message;
		this.linkedObjects = {
			investigation: data.investigation,
			investigation_action: data.investigation_action,
		};

		this.timestamp = data.timestamp
			? data.timestamp instanceof Date
				? data.timestamp
				: new Date(data.timestamp)
			: null;
		this.icon = data.icon;
		this.action = data.action;
	}

	getBackendData() {
		return merge({ message: this.message }, this.linkedObjects);
	}
}

export interface CommentConfig extends ReadOnlyIdentifiableConfig {
	timestamp?: string | Date;
	user?: string;
	message: string;
	investigation?: number;
	investigation_action?: number;
	icon?: string;
	action?: string;
}
