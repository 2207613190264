import { MissingKb } from '@wcd/domain';
import { Entity, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

//This entity is here only because currently there's no existing way to create a 3-leveled deep Paris relationship repository, e.g. in this case we need to get
//vulnerabilities by assetId(Asset entity) and productFixId(missingKb entity)
@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'AssetMissingKb',
	pluralName: 'AssetMisssingKbs',
	endpoint: 'AssetMissingKb',
	readonly: true,
})
export class AssetMissingKb extends MissingKb {
	@EntityField() readonly assetId: string;
}
