import { RecommendationExceptionService } from './recommendation-exception.service';
import { DataviewField } from '@wcd/dataview';
import { map } from 'rxjs/operators';
import { TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { CalculateRemainingDaysPipe } from '../../../../../shared/pipes/calculate-remaining-days.pipe';
import { RecommendationExceptionStateValue, } from '@wcd/domain';
import { TextToken, TvmTextsService } from '../../../../../tvm/services/tvm-texts.service';
var RecommendationExceptionsFieldsService = /** @class */ (function () {
    function RecommendationExceptionsFieldsService(tzDateService, i18nService, calculateRemainingDaysPipe, tvmTextsService, recommendationExceptionService) {
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.calculateRemainingDaysPipe = calculateRemainingDaysPipe;
        this.tvmTextsService = tvmTextsService;
        this.recommendationExceptionService = recommendationExceptionService;
    }
    Object.defineProperty(RecommendationExceptionsFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            /*
            //TODO: consider:
            Ben Grynhaus
                FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
                Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
                It does exactly what the C# equivalent does.
            */
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'title',
                        name: this.i18nService.get('tvm.recommendationException.name'),
                        minWidth: 350,
                    },
                    {
                        id: 'exceptionJustification',
                        name: this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.justification'),
                        getDisplay: function (recommendationException) {
                            return _this.recommendationExceptionService.getJustificationLabel(recommendationException.exceptionJustification);
                        },
                    },
                    {
                        id: 'exceptionType',
                        name: this.i18nService.get('tvm.recommendationException.type'),
                        getDisplay: function (recommendationException) {
                            return _this.tvmTextsService.getText(TextToken.ExceptionRemediationType, recommendationException);
                        },
                    },
                    {
                        id: 'status',
                        name: this.i18nService.get('status'),
                        getDisplay: function (recommendationException) {
                            return _this.i18nService.get("tvm.recommendationException.status." + recommendationException.status.value);
                        },
                    },
                    {
                        id: 'createdBy',
                        name: this.i18nService.get('tvm.common.createdBy'),
                        getDisplay: function (recommendationException) {
                            return recommendationException.requester
                                ? recommendationException.requester.email
                                : _this.i18nService.get('notAvailable.short');
                        },
                    },
                    {
                        id: 'creationTime',
                        name: this.i18nService.get('tvm.securityRecommendation.relatedRemediation.createdOn'),
                        getDisplay: function (recommendationException) {
                            return _this.tzDateService.format(recommendationException.creationTime, 'shortDate');
                        },
                        sort: {
                            sortCompareFunction: function (exception1, exception2) { return exception1.creationTime.getTime() - exception2.creationTime.getTime(); },
                        },
                    },
                    {
                        id: 'expiringOn',
                        name: this.i18nService.get('tvm.recommendationException.expiry.date'),
                        getDisplay: function (recommendationException) {
                            if (recommendationException.status.value ===
                                RecommendationExceptionStateValue.Cancelled) {
                                return _this.tzDateService.format(recommendationException.status.lastModifiedOn, 'shortDate');
                            }
                            else {
                                return _this.tzDateService.format(recommendationException.expiringOn, 'shortDate');
                            }
                        },
                        sort: {
                            sortCompareFunction: function (exception1, exception2) {
                                var expiringOn1 = exception1.expiringOn.getTime();
                                var expiringOn2 = exception2.expiringOn.getTime();
                                if (exception1.status.value === RecommendationExceptionStateValue.Cancelled) {
                                    expiringOn1 = exception1.status.lastModifiedOn.getTime();
                                }
                                if (exception2.status.value === RecommendationExceptionStateValue.Cancelled) {
                                    expiringOn2 = exception2.status.lastModifiedOn.getTime();
                                }
                                return expiringOn1 - expiringOn2;
                            },
                        },
                    },
                    {
                        id: 'remainingDays',
                        name: this.i18nService.get('tvm.remediationTask.ticket.dueDate'),
                        getDisplay: function (recommendationException) {
                            var remainingDays = _this.calculateRemainingDaysPipe.transform(recommendationException.expiringOn);
                            if (recommendationException.status.value !== RecommendationExceptionStateValue.Active) {
                                return '-';
                            }
                            else {
                                return remainingDays + " days";
                            }
                        },
                        sort: {
                            sortCompareFunction: function (exception1, exception2) {
                                if (exception1.status.value === RecommendationExceptionStateValue.Expired) {
                                    return 1;
                                }
                                if (exception2.status.value === RecommendationExceptionStateValue.Expired) {
                                    return -1;
                                }
                                if (exception1.status.value === RecommendationExceptionStateValue.Cancelled) {
                                    return 1;
                                }
                                if (exception2.status.value === RecommendationExceptionStateValue.Cancelled) {
                                    return -1;
                                }
                                return exception1.expiringOn.getTime() - exception2.expiringOn.getTime();
                            },
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecommendationExceptionsFieldsService.prototype, "relatedExceptionsFields", {
        get: function () {
            var _this = this;
            if (!this._relatedExceptionsFields) {
                this._relatedExceptionsFields = this.recommendationExceptionService.rbacGroupIdToNameMap$.pipe(map(function (rbacGroupIdToNameMap) {
                    var additionalFields = DataviewField.fromList([
                        {
                            id: 'machineGroup',
                            name: _this.i18nService.get('tvm.common.machineGroup'),
                            getDisplay: function (exception) {
                                return rbacGroupIdToNameMap[exception.rbacGroupId] ||
                                    _this.i18nService.get('notAvailable.short');
                            },
                        },
                    ]);
                    var fieldsIdMap = function (id) {
                        switch (id) {
                            case 'machineGroup':
                                return 0;
                            case 'createdBy':
                                return 1;
                            case 'status':
                                return 2;
                            case 'expiringOn':
                                return 3;
                            case 'exceptionJustification':
                                return 4;
                            default:
                                return Number.MAX_VALUE;
                        }
                    };
                    return _this.fields
                        .filter(function (field) {
                        return field.id === 'status' ||
                            field.id === 'expiringOn' ||
                            field.id === 'exceptionJustification';
                    })
                        .concat(additionalFields)
                        .sort(function (first, second) { return fieldsIdMap(first.id) - fieldsIdMap(second.id); });
                }));
            }
            return this._relatedExceptionsFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RecommendationExceptionsFieldsService.prototype, "aggregatedExceptionsFields", {
        get: function () {
            var _this = this;
            if (!this._aggregatedExceptionsFields) {
                this._aggregatedExceptionsFields = DataviewField.fromList([
                    {
                        id: 'title',
                        name: this.i18nService.get('tvm.recommendationException.name.singular'),
                        minWidth: 350,
                    },
                    {
                        id: 'exceptionScope',
                        name: this.i18nService.get('tvm.recommendationException.exceptionScope'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            return _this.tvmTextsService.getText(TextToken.AggregatedExceptionScope, recommendationExceptionAggregated);
                        },
                    },
                    {
                        id: 'exceptionJustification',
                        name: this.i18nService.get('tvm.securityRecommendation.recommendationExceptionCreation.justification'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            var distinctJustifications = recommendationExceptionAggregated.distinctJustifications.slice();
                            var justificationLabel = _this.recommendationExceptionService.getJustificationLabel(distinctJustifications.shift());
                            return (justificationLabel +
                                (distinctJustifications.length > 0
                                    ? " (" + _this.i18nService.get('tvm.common.plusMore', {
                                        amount: distinctJustifications.length,
                                    }) + ")"
                                    : ''));
                        },
                        getTooltip: function (recommendationExceptionAggregated) {
                            return recommendationExceptionAggregated.distinctJustifications
                                .map(function (justification) {
                                return _this.recommendationExceptionService.getJustificationLabel(justification);
                            })
                                .join('<br>');
                        },
                        valueTooltipAllowHtmlRendering: true
                    },
                    {
                        id: 'exceptionType',
                        name: this.i18nService.get('tvm.recommendationException.type'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            return _this.tvmTextsService.getText(TextToken.ExceptionRemediationType, recommendationExceptionAggregated);
                        },
                    },
                    {
                        id: 'status',
                        name: this.i18nService.get('status'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            var distinctStatus = recommendationExceptionAggregated.distinctStatusWithCount.slice().map(function (statusWithCount) { return statusWithCount.status; });
                            var statusLabel = _this.i18nService.get("tvm.recommendationException.status." + distinctStatus.shift());
                            return (statusLabel +
                                (distinctStatus.length > 0
                                    ? " (" + _this.i18nService.get('tvm.common.plusMore', {
                                        amount: distinctStatus.length,
                                    }) + ")"
                                    : ''));
                        },
                        getTooltip: function (recommendationExceptionAggregated) {
                            return recommendationExceptionAggregated.distinctStatusWithCount
                                .map(function (statusWithCount) {
                                return _this.i18nService.get("tvm.recommendationException.status." + statusWithCount.status);
                            })
                                .join('<br>');
                        },
                        valueTooltipAllowHtmlRendering: true
                    },
                    {
                        id: 'createdBy',
                        name: this.i18nService.get('tvm.common.createdBy'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            return _this.tvmTextsService.getText(TextToken.AggregatedExceptionRequester, recommendationExceptionAggregated);
                        },
                    },
                    {
                        id: 'createdOn',
                        name: this.i18nService.get('tvm.securityRecommendation.relatedRemediation.createdOn'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            return recommendationExceptionAggregated.createdOn
                                ? _this.tzDateService.format(recommendationExceptionAggregated.createdOn, 'shortDate')
                                : '';
                        },
                    },
                    {
                        id: 'expiringOn',
                        name: this.i18nService.get('tvm.recommendationException.expiry.date'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            return recommendationExceptionAggregated.expiringOn
                                ? _this.tzDateService.format(recommendationExceptionAggregated.expiringOn, 'shortDate')
                                : '';
                        },
                    },
                    {
                        id: 'remainingDays',
                        name: this.i18nService.get('tvm.remediationTask.ticket.dueDate'),
                        getDisplay: function (recommendationExceptionAggregated) {
                            if (recommendationExceptionAggregated.expiringOn) {
                                var remainingDays = _this.calculateRemainingDaysPipe.transform(recommendationExceptionAggregated.expiringOn);
                                return remainingDays >= 0 ? remainingDays + " days" : '-';
                            }
                            else {
                                return '';
                            }
                        },
                    },
                ]);
            }
            return this._aggregatedExceptionsFields;
        },
        enumerable: true,
        configurable: true
    });
    return RecommendationExceptionsFieldsService;
}());
export { RecommendationExceptionsFieldsService };
