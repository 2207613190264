export enum CustomTiIndicatorsTypes {
	Files = 'files',
	Ip = 'ip',
	Url = 'url',
	Certificate = 'certificate',
	MemoryContent = 'memory_content',
}

export enum CustomTiIndicatorsProperties {
	TiIndicator = 'tiIndicator',
	TiIndicatorType = 'tiIndicatorType',
	Issuer = 'issuer',
	Subject = 'subject',
	Serial = 'serial',
	ProcessMemoryProcessName = 'processMemoryProcessName',
	ProcessMemoryActualSize = 'processMemoryActualSize',
	CreatedByDisplayName = 'createdByDisplayName',
	Action = 'action',
	Severity = 'severity',
	RbacGroupIds = 'rbacGroupIds',
	ExpirationTime = 'expirationTime',
	CreationTime = 'creationTime',
	Title = 'title',
	Application = 'application',
	Version = 'version',
	Publisher = 'publisher',
	GenerateAlert = 'generateAlert',
}

export const customTiIndicatorsTypeValues = [
	{
		id: CustomTiIndicatorsTypes.Files,
		name: 'File hashes',
		singularName: 'File hash',
		displayName: 'file hash',
		properties: [
			{
				id: CustomTiIndicatorsProperties.TiIndicator,
				name: 'Hash',
				className: 'break',
				isRequired: true,
				pattern: 'fileHash',
				patternError:
					'Hash must be exactly 32, 40 or 64 characters long and contain only the letters A-F or numbers',
				sort: {
					enabled: true,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Application,
				name: 'Related software',
				placeholder: 'Related software',
				patternError: 'Invalid Related software',
				sort: {
					enabled: true,
				},
			},
			{
				id: CustomTiIndicatorsProperties.TiIndicatorType,
				name: 'Hash Type',
				editable: false,
				itemProperty: 'name',
				sort: {
					enabled: true,
				},
			},
		],
	},
	{
		id: CustomTiIndicatorsTypes.Ip,
		name: 'IP addresses',
		singularName: 'IP',
		displayName: 'IP',
		properties: [
			{
				id: CustomTiIndicatorsProperties.TiIndicator,
				name: 'IP',
				isRequired: true,
				pattern: 'ipOrSegment',
				placeholder: 'IP Address or segment',
				patternError: 'Invalid IP address or segment',
				sort: {
					enabled: true,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Application,
				name: 'Application',
				placeholder: 'Application name',
				patternError: 'Invalid application name',
				sort: {
					enabled: true,
				},
			},
		],
	},
	{
		id: CustomTiIndicatorsTypes.Url,
		name: 'URLs/Domains',
		singularName: 'URL',
		displayName: 'url',
		properties: [
			{
				id: CustomTiIndicatorsProperties.TiIndicator,
				name: 'URLs',
				isRequired: true,
				placeholder: 'Url/Domain',
				patternError: 'Invalid Url/Domain',
				sort: {
					enabled: true,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Application,
				name: 'Application',
				placeholder: 'Application name',
				patternError: 'Invalid application name',
				sort: {
					enabled: true,
				},
			},
		],
	},
	{
		id: CustomTiIndicatorsTypes.Certificate,
		name: 'Certificates',
		singularName: 'Certificate',
		displayName: 'certificate',
		icon: 'Certificate',
		properties: [
			{
				id: CustomTiIndicatorsProperties.TiIndicator,
				name: 'Thumbprint',
				sort: {
					enabled: true,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Issuer,
				name: 'Issuer',
				sort: {
					enabled: false,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Serial,
				name: 'Serial',
				sort: {
					enabled: false,
				},
			},
			{
				id: CustomTiIndicatorsProperties.Subject,
				name: 'Subject',
				sort: {
					enabled: false,
				},
			},
		],
	},
	{
		id: CustomTiIndicatorsTypes.MemoryContent,
		name: 'Process Memory',
		singularName: 'Process Memory',
		displayName: 'process memory',
		readonly: true,
		properties: [
			{
				id: CustomTiIndicatorsProperties.TiIndicator,
				name: 'Hash',
				className: 'truncate',
				isRequired: true,
				custom: {
					testProperty: true,
				},
				sort: { enabled: true },
			},
			{
				id: CustomTiIndicatorsProperties.ProcessMemoryProcessName,
				name: 'Process Name',
				sort: {
					enabled: false,
				},
			},
			{
				id: CustomTiIndicatorsProperties.ProcessMemoryActualSize,
				name: 'Actual Size',
				sort: {
					enabled: false,
				},
			},
		],
	},
];
