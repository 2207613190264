import { ElementRef, AfterViewInit, ViewContainerRef, ComponentFactoryResolver, OnDestroy, ComponentRef, Type, EventEmitter, } from '@angular/core';
import { TzDateService } from '@wcd/localization';
import { select as d3Select } from 'd3-selection';
import { scaleTime, scaleLinear } from 'd3-scale';
import { line } from 'd3-shape';
import { axisBottom, axisLeft, extent, timeFormat, mouse, bisector } from 'd3';
import { ReplaySubject } from 'rxjs';
export var ChartColor;
(function (ChartColor) {
    ChartColor["Grey"] = "#605e5c";
    ChartColor["Blue"] = "#0078d4";
    ChartColor["Red"] = "#990000";
    ChartColor["Green"] = "#00AA00";
})(ChartColor || (ChartColor = {}));
var LineChartComponent = /** @class */ (function () {
    function LineChartComponent(resolver, tzDateService, elementRef) {
        this.resolver = resolver;
        this.tzDateService = tzDateService;
        this.elementRef = elementRef;
        this.DEFAULT_PADDING = 30;
        this.CHART_PADDING_TOP = 10;
        this.CHART_PADDING_LEFT = 35;
        this.Y_AXIS_PADDING = 5;
        this.X_AXIS_PADDING = 7;
        this.DATE_PADDING = 5;
        this.PIXELS_PER_DATE = 55;
        this.MOUSE_LEFT_PADDING = -15;
        this.MOUSE_BOTTOM_PADDING = 5;
        this.OPACITY_TRANSITION_MS = 150;
        this.WIDTH_TO_HEIGHT_RATIO = 2.5;
        this.HIDE_TOOLTIP_TIMEOUT = 100;
        this.MOUSE_OUT_TIMEOUT = 150;
        this.SHOW_TOOLTIP_TIMEOUT = 90;
        this.SEARCH_NEIGHBOR_POINTS = 4;
        this.DEFAULT_HIGHLIGHT_POINT_RADIUS = 3;
        this.HOVER_HIGHLIGHT_POINT_RADIUS = 7;
        this.SHADOW_HIGHLIGHT_POINT_OPACITY = 0.6;
        this.DEFAULT_CHART_COLOR = ChartColor.Grey;
        this.DEFAULT_Y_AXIS_CONFIGURATION = {
            min: 0,
            max: 100,
            ticks: {
                values: [0, 30, 70, 100],
            },
        };
        this._afterViewInit = false;
        this.onNewDataPoint$ = new ReplaySubject();
        this.bisectDate = bisector(function (d, x) { return x - d.date; }).left;
    }
    Object.defineProperty(LineChartComponent.prototype, "options", {
        set: function (chartOptions) {
            var _this = this;
            if (!chartOptions) {
                return;
            }
            this._chartOptions = chartOptions;
            this._chartData = chartOptions.data;
            this._usePercentageValue = !!chartOptions.valueInPercent;
            this._tooltipComponent = chartOptions.tooltipComponent;
            this._chartColor = chartOptions.color || this.DEFAULT_CHART_COLOR;
            this._yAxisTickValuesWidth = chartOptions.yAxisTickValuesWidth || this.CHART_PADDING_LEFT;
            this._yAxisConfiguration = chartOptions.yAxisConfiguration || this.DEFAULT_Y_AXIS_CONFIGURATION;
            this.divId = chartOptions.id ? chartOptions.id : 'lineChart';
            if (this._afterViewInit) {
                setTimeout(function () { return _this.drawChart(); });
            }
        },
        enumerable: true,
        configurable: true
    });
    LineChartComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var elementId = this.divId ? '#' + this.divId : '#lineChart';
        this._rootEl = this.elementRef.nativeElement.querySelector(elementId);
        this._rootD3El = d3Select(this._rootEl);
        this.tooltipPlaceholder.clear();
        setTimeout(function () {
            _this.handleTooltipViewInit();
            _this.drawChart();
            _this._afterViewInit = true;
        }, 0);
    };
    LineChartComponent.prototype.handleTooltipViewInit = function () {
        var _this = this;
        if (!this._tooltipComponent) {
            this._useDefaultTooltip = true;
            return;
        }
        var factory = this.resolver.resolveComponentFactory(this._tooltipComponent);
        this._customTooltipComponentRef = this.tooltipPlaceholder.createComponent(factory);
        this._customTooltipComponentRef.instance.id = this._chartOptions.tooltipId;
        this._customTooltipComponentRef.instance.legend = this._chartOptions.legend;
        this._customTooltipComponentRef.instance.percentageDisplay = this._usePercentageValue;
        this._customTooltipComponentRef.instance.newDataPoint$ = this.onNewDataPoint$;
        this._customTooltipComponentRef.instance.onEnterTooltip.subscribe(function () {
            _this._insideTooltip = true;
            _this._focus.style('display', null);
        });
        this._customTooltipComponentRef.instance.onLeaveTooltip.subscribe(function () {
            _this._insideTooltip = false;
            _this.stopHoverExperience();
            _this._mouseCoordinates = null; // to avoid race conditions where receiving new width events after leaving the tooltip
        });
        this._customTooltipComponentRef.instance.onNewWidth.subscribe(function (newWidth) {
            if (!_this._mouseCoordinates) {
                return;
            }
            _this._insideTooltip = false; // set to false to avoid race conditions between resize and on enter events
            _this._tooltipWidth = newWidth;
            var leftPosition = _this.getLeftTooltipPosition(newWidth);
            var bottomPosition = _this.getBottomTooltipPosition();
            _this.setTooltipOpacityAndLocation(1, leftPosition, bottomPosition);
        });
    };
    LineChartComponent.prototype.onResize = function (_) {
        this.drawChart();
    };
    LineChartComponent.prototype.ngOnDestroy = function () {
        this._customTooltipComponentRef && this._customTooltipComponentRef.destroy();
    };
    LineChartComponent.prototype.calculateXIndexesAccordingToDataLength = function () {
        var dataLength = this._chartData.length;
        var ticksModulo = Math.floor(dataLength / (this._chartWidth / this.PIXELS_PER_DATE));
        var thirdArray = Math.floor(dataLength / 3);
        var quarterArray = Math.floor(dataLength / 4);
        var fifthArray = Math.floor(dataLength / 5);
        var twoThirds = Math.floor((dataLength * 2) / 3);
        var sections = 0; // the sections is responsible for dividing the x axis into relatively equals sections
        if (ticksModulo > twoThirds) {
            sections = 2;
        }
        else if (ticksModulo > thirdArray) {
            sections = 3;
        }
        else if (ticksModulo > quarterArray) {
            sections = 4;
        }
        else if (ticksModulo > fifthArray) {
            sections = 5;
        }
        else {
            sections = 6;
        }
        var indexes = [];
        var fraction = (dataLength - 1) / (sections - 1);
        for (var i = 0; i < sections; i++) {
            indexes.push(Math.floor(fraction * i));
        }
        return indexes;
    };
    LineChartComponent.prototype.stopHoverExperience = function () {
        this.hideTooltip();
        this._focus.style('display', 'none');
        if (this._currentShadow) {
            this._currentShadow.style('opacity', 0);
            this._currentShadow = null;
        }
    };
    LineChartComponent.prototype.drawChart = function () {
        var _this = this;
        if (!this._chartData) {
            return;
        }
        this._rootD3El.html('');
        this._totalWidth = this._rootEl.clientWidth;
        var height = this._chartOptions.height || this._totalWidth / this.WIDTH_TO_HEIGHT_RATIO;
        this._chartHeight = height - this.DEFAULT_PADDING - this.DATE_PADDING;
        this._chartWidth = this._totalWidth - (this._yAxisTickValuesWidth + this.DEFAULT_PADDING);
        this._svgD3El = this._rootD3El
            .append('svg')
            .attr('width', this._totalWidth)
            .attr('height', height)
            .append('g')
            .attr('transform', "translate(" + this._yAxisTickValuesWidth + "," + this.CHART_PADDING_TOP + ")");
        this._xScale = scaleTime()
            .range([0, this._chartWidth])
            .domain(extent(this._chartData, function (d) { return d.date; }));
        this._yScale = scaleLinear()
            .range([this._chartHeight, 0])
            .domain([this._yAxisConfiguration.min, this._yAxisConfiguration.max]);
        this.setHoverBehaviorInsideGraph();
        this.setChartAxis(height);
        this.setHighlightedPointsEvent();
        var valueLine = line()
            .x(function (d) { return _this._xScale(d['date']); })
            .y(function (d) { return _this._yScale(+d['value']); });
        this._svgD3El
            .append('path')
            .datum(this._chartData)
            .attr('d', valueLine)
            .attr('class', 'chart-line')
            .style('stroke', this._chartColor);
        this._tooltip = this._rootD3El
            .append('div')
            .attr('class', 'hover-tooltip')
            .style('opacity', 0);
        this._svgD3El
            .append('rect')
            .attr('transform', "translate(0,0)")
            .attr('class', 'overlay')
            .attr('width', this._chartWidth)
            .attr('height', this._chartHeight)
            .on('mouseout', function () {
            // to wait for event when hovered into the tooltip
            return setTimeout(function () {
                if (!_this._insideTooltip) {
                    // left the component (not inside the event tooltip)
                    _this.stopHoverExperience();
                }
            }, _this.MOUSE_OUT_TIMEOUT);
        })
            .on('mousemove', function (_, j, element) {
            if (_this._insideTooltip) {
                return;
            }
            _this._mouseCoordinates = mouse(element[j]);
            var pointIndex = _this.getHoveredPointIndex(_this._mouseCoordinates[0]);
            var hoveredDataPoint = _this._chartData[pointIndex];
            _this.setHighestNeighborPointValue(pointIndex);
            setTimeout(function () {
                if (!_this._mouseCoordinates) {
                    // To avoid race condition where hovered in event and left the area before timeout
                    return;
                }
                _this.onMouseMoveInEventRectangleHandler(hoveredDataPoint);
            }, _this.SHOW_TOOLTIP_TIMEOUT);
        });
    };
    LineChartComponent.prototype.setHighlightedPointsEvent = function () {
        var highlightedPoints = this._chartData.filter(function (p) { return Array.isArray(p.events) && p.events.length > 0; });
        this.addCirclesToChart(highlightedPoints, this.DEFAULT_HIGHLIGHT_POINT_RADIUS, 'static-events-dot');
        this.addCirclesToChart(highlightedPoints, this.HOVER_HIGHLIGHT_POINT_RADIUS, 'hovered-event-dot');
        this._eventDotsShadow = this._svgD3El.selectAll('.hovered-event-dot');
    };
    LineChartComponent.prototype.addCirclesToChart = function (highlightedPoints, radius, className) {
        var _this = this;
        this._svgD3El
            .selectAll(className)
            .data(highlightedPoints)
            .enter()
            .append('circle')
            .attr('class', className)
            .attr('r', radius)
            .attr('cx', function (d) { return _this._xScale(d.date); })
            .attr('cy', function (d) { return _this._yScale(d.value); })
            .style('fill', this._chartColor);
    };
    LineChartComponent.prototype.setHighestNeighborPointValue = function (pointIndex) {
        var point = this._chartData[pointIndex];
        for (var i = 0; i < this.SEARCH_NEIGHBOR_POINTS; i++) {
            var tmpIndex = pointIndex + i;
            if (tmpIndex < this._chartData.length && this._chartData[tmpIndex].value > point.value) {
                point = this._chartData[tmpIndex];
            }
            tmpIndex = pointIndex - i;
            if (tmpIndex >= 0 && this._chartData[tmpIndex].value > point.value) {
                point = this._chartData[tmpIndex];
            }
        }
        this._highestNeighborPointValue = point.value;
    };
    LineChartComponent.prototype.hideTooltip = function () {
        if (this._useDefaultTooltip) {
            this.setDefaultTooltipOpacity(0);
        }
        else {
            this.setTooltipOpacityAndLocation(0, 0, 0); // hide the component
        }
    };
    LineChartComponent.prototype.getHoveredPointIndex = function (mouseXCoordinate) {
        var hoveredDate = this._xScale.invert(mouseXCoordinate);
        var dateIndex = this.bisectDate(this._chartData, hoveredDate);
        var neighborIndex = dateIndex - 1 >= 0 ? dateIndex - 1 : 0;
        // d0 & d1 are the points closest to the hovered date -> the closest is set into d
        var d0 = this._chartData[dateIndex];
        var d1 = this._chartData[neighborIndex];
        return +hoveredDate - +d0.date > +d1.date - +hoveredDate ? neighborIndex : dateIndex;
    };
    LineChartComponent.prototype.setTooltipOpacityAndLocation = function (opacity, left, bottom) {
        this._customTooltipComponentRef.instance.opacity = opacity;
        this._customTooltipComponentRef.instance.left = left;
        this._customTooltipComponentRef.instance.bottom = bottom;
        this._customTooltipComponentRef.changeDetectorRef.markForCheck();
    };
    LineChartComponent.prototype.setDefaultTooltipOpacity = function (opacity) {
        this._tooltip
            .transition()
            .duration(this.OPACITY_TRANSITION_MS)
            .style('opacity', opacity);
    };
    LineChartComponent.prototype.onMouseMoveInEventRectangleHandler = function (dataPoint) {
        var _this = this;
        this.showHoveredFocusLine(dataPoint);
        var tooltipWidth = this._useDefaultTooltip
            ? this._tooltip.node().getBoundingClientRect().width
            : this._tooltipWidth;
        var leftPosition = this.getLeftTooltipPosition(tooltipWidth);
        var bottomPosition = this.getBottomTooltipPosition();
        if (this._useDefaultTooltip) {
            this._rootD3El
                .selectAll('.hover-tooltip')
                .html(function () { return _this.renderDefaultTooltipHtml(dataPoint); })
                .style('left', leftPosition + 'px')
                .style('bottom', bottomPosition + 'px');
            this.setDefaultTooltipOpacity(1);
        }
        else {
            if (!this._currentDataPoint || dataPoint !== this._currentDataPoint) {
                this._currentDataPoint = dataPoint;
                this.onNewDataPoint$.next(dataPoint);
            }
            this.handlePointShadow(dataPoint);
            this.setTooltipOpacityAndLocation(1, leftPosition, bottomPosition);
        }
    };
    LineChartComponent.prototype.handlePointShadow = function (dataPoint) {
        if (!this._currentShadow || this._currentShadow.data()[0].date !== dataPoint.date) {
            // either the first hovered point or the point is on an other date (new point)
            if (this._currentShadow) {
                this._currentShadow.style('opacity', 0); // removing the highlighted previous point
            }
            var newShadow = this._eventDotsShadow.filter(function (x) { return x.date === dataPoint.date; });
            if (newShadow.empty()) {
                this._currentShadow = null; // For when hovering over points without events
            }
            else {
                this._currentShadow = newShadow;
                this._currentShadow.style('opacity', this.SHADOW_HIGHLIGHT_POINT_OPACITY);
            }
        }
    };
    LineChartComponent.prototype.showHoveredFocusLine = function (d) {
        var hoverLineHeightBelow = this._chartHeight - this._yScale(d.value);
        this._focus.attr('transform', "translate(" + this._xScale(d.date) + "," + this._yScale(d.value) + ")");
        this._focus
            .select('.x-hover-line')
            .attr('y2', hoverLineHeightBelow)
            .attr('y1', -this._chartHeight + hoverLineHeightBelow);
        this._focus.style('display', null);
    };
    LineChartComponent.prototype.getBottomTooltipPosition = function () {
        return (this._rootEl.clientHeight -
            this._yScale(this._highestNeighborPointValue) +
            this.MOUSE_BOTTOM_PADDING);
    };
    LineChartComponent.prototype.getLeftTooltipPosition = function (tooltipWidth) {
        if (this._totalWidth < tooltipWidth) {
            // On narrow screen on large zoom start from parent 0 left
            return 0;
        }
        var xCoordinate = this._mouseCoordinates[0];
        var leftPosition = xCoordinate + this._yAxisTickValuesWidth + this.Y_AXIS_PADDING + this.MOUSE_LEFT_PADDING;
        var tooltipOverflow = this._totalWidth - xCoordinate - this._yAxisTickValuesWidth - this.Y_AXIS_PADDING - tooltipWidth <
            0;
        return tooltipOverflow ? this._totalWidth + this.MOUSE_LEFT_PADDING - tooltipWidth : leftPosition;
    };
    LineChartComponent.prototype.setChartAxis = function (totalHeight) {
        var _this = this;
        var displayIndexes = this.calculateXIndexesAccordingToDataLength();
        var xAxis = axisBottom(this._xScale)
            .tickFormat(timeFormat('%m/%d'))
            .tickValues(this._chartData.map(function (d) { return d.date; }).filter(function (d, i) { return displayIndexes.includes(i); }));
        var yAxis = axisLeft(this._yScale)
            .tickValues(this._yAxisConfiguration.ticks.values)
            .tickSizeOuter(0)
            .tickPadding(this.Y_AXIS_PADDING)
            .tickSize(-this._chartWidth)
            .tickFormat(function (yValue) { return (_this._usePercentageValue ? yValue + "%" : "" + yValue); });
        this._svgD3El
            .append('g')
            .attr('class', 'y-axis')
            .call(yAxis)
            .call(function (g) { return g.select('.domain').remove(); });
        this._svgD3El
            .append('g')
            .attr('class', 'x-axis')
            .attr('transform', "translate(0," + (totalHeight - this.DEFAULT_PADDING - this.DATE_PADDING) + ")")
            .call(xAxis);
        // Setting the padding for all x axis tick values
        this._svgD3El.selectAll('.x-axis .tick text').attr('transform', "translate(0, " + this.DATE_PADDING + ")");
        // Setting the padding for the first and last x axis tick values
        this._svgD3El
            .selectAll('.x-axis .tick:first-of-type text')
            .attr('transform', "translate(" + -this.X_AXIS_PADDING + ", " + this.DATE_PADDING + ")");
        this._svgD3El
            .selectAll('.x-axis .tick:last-of-type text')
            .attr('transform', "translate(" + this.X_AXIS_PADDING + ", " + this.DATE_PADDING + ")");
    };
    LineChartComponent.prototype.setHoverBehaviorInsideGraph = function () {
        this._focus = this._svgD3El
            .append('g')
            .attr('class', 'focus')
            .style('display', 'none');
        this._focus
            .append('line')
            .attr('class', 'x-hover-line hover-line')
            .attr('y1', 0)
            .attr('y2', -this._chartHeight);
        this._focus
            .append('circle')
            .attr('class', 'chart-hover-dot')
            .attr('r', 2)
            .style('fill', this._chartColor);
    };
    LineChartComponent.prototype.renderDefaultTooltipHtml = function (hoveredPoint) {
        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t<tbody>\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<th colspan=\"2\">" + this.tzDateService.format(hoveredPoint.date, 'MM/dd') + "</th>\n\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t<td class=\"name\">\n\t\t\t\t\t\t\t\t<span class=\"tooltip-legend\"></span>\n\t\t\t\t\t\t\t\t" + this._chartOptions.legend + "\n\t\t\t\t\t\t\t</td>\n\t\t\t\t\t\t\t<td class=\"value\">" + hoveredPoint.value + "</td>\n\t\t\t\t\t\t</tr>\n\t\t\t\t\t</tbody>\n\t\t\t\t</table>";
    };
    return LineChartComponent;
}());
export { LineChartComponent };
