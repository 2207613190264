import { Injectable } from '@angular/core';
import { TenantSettings } from '@wcd/domain';
import { Observable } from 'rxjs';
import { Paris, Repository } from '@microsoft/paris';

const ITEM_ID: number = 1;
export const RETENTION_PERIODS: ReadonlyArray<number> = [30, 60, 90, 120, 150, 180];

@Injectable()
export class GeneralSettingsService {
	private tenantSettingsRepository: Repository<TenantSettings>;

	constructor(paris: Paris) {
		this.tenantSettingsRepository = paris.getRepository<TenantSettings>(TenantSettings);
	}

	public getTenantSettings(): Observable<TenantSettings> {
		return this.tenantSettingsRepository.getItemById(ITEM_ID);
	}

	public saveTenantSettings(settings: TenantSettings): Observable<TenantSettings> {
		return this.tenantSettingsRepository.save({ ...settings, id: ITEM_ID.toString() });
	}
}
