/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./severity-summary.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./severity-bar.component.ngfactory";
import * as i3 from "./severity-bar.component";
import * as i4 from "../../services/severity-type-color.service";
import * as i5 from "@angular/common";
import * as i6 from "./severity-summary.component";
var styles_SeveritySummaryComponent = [i0.styles];
var RenderType_SeveritySummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SeveritySummaryComponent, data: {} });
export { RenderType_SeveritySummaryComponent as RenderType_SeveritySummaryComponent };
function View_SeveritySummaryComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "severity-bar", [["class", "SeveritySummary__bar"]], null, null, null, i2.View_SeverityBarComponent_0, i2.RenderType_SeverityBarComponent)), i1.ɵdid(1, 573440, null, 0, i3.SeverityBarComponent, [i4.SeverityTypeColorService], { size: [0, "size"], severity: [1, "severity"], count: [2, "count"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.size; var currVal_1 = _v.context.$implicit.type; var currVal_2 = _v.context.$implicit.count; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_SeveritySummaryComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex-horizontal"]], [[2, "wcd-flex-wrap-horizontal", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeveritySummaryComponent_2)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.severitiesCountArray; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.wrap !== false); _ck(_v, 0, 0, currVal_0); }); }
function View_SeveritySummaryComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "subtle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["No alerts"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_SeveritySummaryComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SeveritySummaryComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵand(0, [["noAlerts", 2]], null, 0, null, View_SeveritySummaryComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.severitiesCountArray; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_SeveritySummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "severity-summary", [], null, null, null, View_SeveritySummaryComponent_0, RenderType_SeveritySummaryComponent)), i1.ɵdid(1, 49152, null, 0, i6.SeveritySummaryComponent, [], null, null)], null, null); }
var SeveritySummaryComponentNgFactory = i1.ɵccf("severity-summary", i6.SeveritySummaryComponent, View_SeveritySummaryComponent_Host_0, { size: "size", wrap: "wrap", severitiesCountMap: "severitiesCountMap" }, {}, []);
export { SeveritySummaryComponentNgFactory as SeveritySummaryComponentNgFactory };
