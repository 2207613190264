var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Router, RoutesRecognized } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { OnDestroy } from '@angular/core';
import { FeaturesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "../../../../../../projects/config/src/lib/services/features.service";
/**
 * Monitors navigation events and tracks specific query params, if the same path is
 * visited again without query params the previously used query params are
 * reloaded.
 *
 * Query params are only tracked for a given route if they are listed in the route config
 */
var QueryParamsCacheService = /** @class */ (function () {
    function QueryParamsCacheService(router, featuresService) {
        this.router = router;
        this.featuresService = featuresService;
        this.queryParamCache = {};
    }
    QueryParamsCacheService.prototype.useCachedQueryParams = function () {
        var _this = this;
        this.subscription = this.router.events
            .pipe(filter(function (event) { return event instanceof RoutesRecognized; }), filter(function () { return !_this.isReloadingWithPreviousQuery(); }), map(function (event) {
            var _a = event.url.split('?'), nextPath = _a[0], nextQuery = _a[1];
            var _b = _this.router.url.split('?'), currentPath = _b[0], currentQuery = _b[1];
            var paramNames = _this.getCachedQueryParamsNames(event.state.root);
            return {
                nextPath: nextPath,
                nextQuery: nextQuery,
                currentPath: currentPath,
                currentQuery: currentQuery,
                paramNames: paramNames,
            };
        }), filter(function (data) { return !!data.paramNames.length; }))
            .subscribe(function (_a) {
            var nextPath = _a.nextPath, nextQuery = _a.nextQuery, currentPath = _a.currentPath, paramNames = _a.paramNames;
            // if any of the configured params are present, the query gets saved in the cache for next time,
            // otherwise we reload from cache if possible
            // but if the path didn't change and the params aren't present, it's because they were
            // explicitly cleared by the application, so delete them from the cache
            if (nextQuery && paramNames.some(function (paramName) { return nextQuery.includes(paramName); })) {
                _this.setQueryParamsForPath(nextQuery, paramNames, nextPath);
            }
            else if (nextPath !== currentPath) {
                _this.loadPathWithPreviousQueryParams(nextPath);
            }
            else {
                delete _this.queryParamCache[nextPath];
            }
        });
    };
    QueryParamsCacheService.prototype.destroy = function () {
        this.subscription.unsubscribe();
    };
    QueryParamsCacheService.prototype.setQueryParamsForPath = function (newQuery, paramNames, newPath) {
        var _this = this;
        this.queryParamCache[newPath] = paramNames.reduce(function (acc, paramName) {
            var _a;
            var queryParam = _this.getQueryParamValue(newQuery, paramName);
            return queryParam
                ? __assign({}, acc, (_a = {}, _a[paramName] = decodeURIComponent(queryParam), _a)) : acc;
        }, {});
    };
    QueryParamsCacheService.prototype.loadPathWithPreviousQueryParams = function (nextPath) {
        if (typeof this.queryParamCache[nextPath] !== 'undefined') {
            this.router.navigate([nextPath], {
                queryParams: this.queryParamCache[nextPath],
                state: {
                    reloadingWithPreviousQuery: true,
                },
            });
        }
    };
    QueryParamsCacheService.prototype.isReloadingWithPreviousQuery = function () {
        var currentNavigation = this.router.getCurrentNavigation();
        return (currentNavigation &&
            currentNavigation.extras.state &&
            currentNavigation.extras.state.reloadingWithPreviousQuery);
    };
    QueryParamsCacheService.prototype.getQueryParamValue = function (newQuery, paramName) {
        var param = newQuery.split('&').find(function (x) { return x.includes(paramName); });
        if (param) {
            var _a = param.split('='), _1 = _a[0], value = _a[1];
            return value;
        }
    };
    QueryParamsCacheService.prototype.getCachedQueryParamsNames = function (snapshot) {
        if (snapshot.data && snapshot.data.cachedQueryParams && (!snapshot.data.disableCachedQueryParamsFeatureId || !this.featuresService.isEnabled(snapshot.data.disableCachedQueryParamsFeatureId))) {
            return snapshot.data.cachedQueryParams;
        }
        if (snapshot.firstChild) {
            return this.getCachedQueryParamsNames(snapshot.firstChild);
        }
        return [];
    };
    QueryParamsCacheService.prototype.ngOnDestroy = function () {
        this.destroy();
    };
    QueryParamsCacheService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function QueryParamsCacheService_Factory() { return new QueryParamsCacheService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.FeaturesService)); }, token: QueryParamsCacheService, providedIn: "root" });
    return QueryParamsCacheService;
}());
export { QueryParamsCacheService };
