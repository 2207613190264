/**
 * Manages SCC mock server config:
 */
export class SccMockService {
	readonly DEFAULT_MOCK_HOST = 'http://localhost:5001';
	readonly TEST_MODE_TENANT_ID = '00000000-0000-0000-0000-000000000000';
	private _mockHost;
	private _isMockMode;

	get mockHost() {
		return this._mockHost || this.getMockHost();
	}

	get isMockMode(): boolean {
		return this._isMockMode || this.checkIsMockMode();
	}

	constructor(private config, private loginUser) {
		this._mockHost = this.getMockHost();
		this._isMockMode = this.checkIsMockMode();
	}

	getMockHost() {
		const urlParams = new URLSearchParams(window.location.search);
		const devHost = urlParams.get('devhost') || '';
		const devParts = devHost.split('wicd-mockHost:');
		if (devParts.length <= 1) {
			return this.DEFAULT_MOCK_HOST;
		}
		const mockHost = devParts[1].split(',')[0];
		return mockHost || this.DEFAULT_MOCK_HOST;
	}

	checkIsMockMode(): boolean {
		// If tenantId is empty we are in SCC test mode
		return (
			this.loginUser.tenantId === this.TEST_MODE_TENANT_ID &&
			!this.config.test('EnableMockService') &&
			!location.search.includes('DisableWicdMock')
		);
	}

	getMockHostServiceUrls(serviceUrls) {
		// In case of not nominated tenant - first TenantContext call does not return ServiceUrls
		if (!this.isMockMode || !serviceUrls) {
			return serviceUrls;
		}

		const ret: any = Object.keys(serviceUrls)
			.filter((key) => serviceUrls[key])
			.reduce((acc, key) => {
				acc[key] = serviceUrls[key].replace(this.DEFAULT_MOCK_HOST, this.mockHost);
				return acc;
			}, {});
		return ret;
	}
}
