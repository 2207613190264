/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tags-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/forms/src/lib/components/search.component.ngfactory";
import * as i3 from "../../../../../../../projects/forms/src/lib/components/search.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "../tags-list/tags-list.component.ngfactory";
import * as i10 from "../tags-list/tags-list.component";
import * as i11 from "../../../shared/interfaces/disableable.interface";
import * as i12 from "./tags-edit.component";
import * as i13 from "../../../dialogs/services/dialogs.service";
var styles_TagsEditComponent = [i0.styles];
var RenderType_TagsEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagsEditComponent, data: {} });
export { RenderType_TagsEditComponent as RenderType_TagsEditComponent };
function View_TagsEditComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-margin-bottom wcd-flex-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "wcd-search", [["class", "wcd-full-width"]], null, [[null, "select"], [null, "escOnClosedResult"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.onTagAddClick($event) !== false);
        ad = (pd_0 && ad);
    } if (("escOnClosedResult" === en)) {
        var pd_1 = (_co.escapeClicked() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_SearchComponent_0, i2.RenderType_SearchComponent)), i1.ɵdid(3, 114688, null, 0, i3.SearchComponent, [i1.ChangeDetectorRef, i4.LiveAnnouncer, i5.I18nService], { placeholder: [0, "placeholder"], settings: [1, "settings"], searchFunction: [2, "searchFunction"], ariaLabel: [3, "ariaLabel"], forceAlphanumeric: [4, "forceAlphanumeric"], setFocus: [5, "setFocus"], enableTopPosition: [6, "enableTopPosition"], role: [7, "role"], searchboxId: [8, "searchboxId"] }, { select: "select", escOnClosedResult: "escOnClosedResult" }), i1.ɵppd(4, 1), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent, 0), "tags.autoCompleteInstructions")); var currVal_1 = ((_co.settings == null) ? null : _co.settings.searchSettings); var currVal_2 = _co.boundSearchAndAddNew; var currVal_3 = i1.ɵunv(_v, 3, 3, _ck(_v, 5, 0, i1.ɵnov(_v.parent, 0), "tags.manage")); var currVal_4 = true; var currVal_5 = _co.tagEditDialogOpen; var currVal_6 = false; var currVal_7 = "none"; var currVal_8 = (_co.dialogId + "_searchbox"); _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_TagsEditComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i6.I18nPipe, [i5.I18nService]), (_l()(), i1.ɵeld(1, 0, null, null, 17, "form", [["class", "small-form tags-edit-form"], ["name", "editTagsForm"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i7.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(3, 4210688, [["hostForm", 4]], 0, i7.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i7.ControlContainer, null, [i7.NgForm]), i1.ɵdid(5, 16384, null, 0, i7.NgControlStatusGroup, [[4, i7.ControlContainer]], null, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "card-contents wcd-flex-vertical"], ["style", "height: 200px"]], [[1, "id", 0], [1, "aria-hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagsEditComponent_1)), i1.ɵdid(10, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 4, "div", [["class", "wcd-flex-1 wcd-scroll-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 1, "tags-list", [], null, [[null, "tagRemoved"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("tagRemoved" === en)) {
        var pd_0 = (_co.onTagRemoved($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i9.View_TagsListComponent_0, i9.RenderType_TagsListComponent)), i1.ɵdid(15, 573440, null, 0, i10.TagsListComponent, [], { tags: [0, "tags"], editable: [1, "editable"] }, { tagRemoved: "tagRemoved" }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_9 = !_co.readonly; _ck(_v, 10, 0, currVal_9); var currVal_10 = _co.localTags; var currVal_11 = !_co.readonly; _ck(_v, 15, 0, currVal_10, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 5).ngClassValid; var currVal_5 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_7 = _co.dialogId; var currVal_8 = !_co.tagEditDialogOpen; _ck(_v, 7, 0, currVal_7, currVal_8); }); }
export function View_TagsEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "tags-edit", [], null, null, null, View_TagsEditComponent_0, RenderType_TagsEditComponent)), i1.ɵprd(6144, null, i11.DISABLEABLE_TOKEN, null, [i12.TagsEditComponent]), i1.ɵdid(2, 573440, null, 0, i12.TagsEditComponent, [i13.DialogsService, i1.ChangeDetectorRef, i5.I18nService], null, null)], null, null); }
var TagsEditComponentNgFactory = i1.ɵccf("tags-edit", i12.TagsEditComponent, View_TagsEditComponent_Host_0, { tags: "tags", readonly: "readonly", settings: "settings", callOnEscape: "callOnEscape", tagEditDialogOpen: "tagEditDialogOpen", dialogId: "dialogId" }, { changed: "changed" }, []);
export { TagsEditComponentNgFactory as TagsEditComponentNgFactory };
