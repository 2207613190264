import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { MachineRiskScoreType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

const machineRiskScoreDisplayKeysMap: Record<MachineRiskScoreType, string> = {
	[MachineRiskScoreType.None]: 'machineRiskScore.none',
	[MachineRiskScoreType.Informational]: 'machineRiskScore.informational',
	[MachineRiskScoreType.Low]: 'machineRiskScore.low',
	[MachineRiskScoreType.Medium]: 'machineRiskScore.medium',
	[MachineRiskScoreType.High]: 'machineRiskScore.high',
};

@Pipe({
	name: 'machineRiskScoreTypeDisplayText',
})
export class MachineRiskScoreTypeDisplayTextPipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {
		this.transform = memoize(this.transform);
	}

	transform(value: MachineRiskScoreType): string | null {
		if (!(value in machineRiskScoreDisplayKeysMap)) {
			return null;
		}

		return this.i18nService.get(machineRiskScoreDisplayKeysMap[value]);
	}
}
