import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
	OnChanges,
	Output,
	EventEmitter,
	OnInit,
	SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { sccHostService } from '@wcd/scc-interface';

let lastId = 0;

@Component({
	selector: 'wcd-help',
	template: `
		<div
			*ngIf="wcdTooltipAllowHtmlRendering"
			style="display: none"
			[attr.id]="helpUniqueId"
			[innerHtml]="htmlTooltip"
		></div>
		<ng-container *ngIf="!!showIcon; else noIcon">
			<span class="wcd-help-icon">
				<ng-container *ngTemplateOutlet="content"></ng-container>
				<fab-icon
					iconName="Info"
					*ngIf="showIcon"
					[contentClass]="
						'wcd-help-icon__i' +
						(isLight ? ' wcd-help-icon__i--light' : isLarge ? ' wcd-help-icon__i--large' : '')
					"
					role="button"
					[ariaLabel]="wcdTooltipAllowHtmlRendering ? 'tooltip' : null"
					aria-roledescription="tooltip"
					[attr.tabindex]="tabIndexValue"
					[wcdTooltip]="helpHtml"
					[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
					[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
					[wcdTooltipEnableKeyboardEvents]="wcdTooltipEnableKeyboardEvents"
					[attr.aria-label]="wcdTooltipAllowHtmlRendering ? null : toolTipAriaLabel"
					[attr.aria-labelledby]="wcdTooltipAllowHtmlRendering ? helpUniqueId : null"
					(focus)="onFabFocus($event)"
					(blur)="onFabBlur($event)"
					(keyup.enter)="preventDefault($event)"
					(keydown.enter)="preventDefault($event)"
				></fab-icon>
			</span>
		</ng-container>
		<ng-template #noIcon>
			<span
				class="wcd-help-icon"
				role="button"
				aria-roledescription="tooltip"
				[attr.tabindex]="tabIndexValue"
				[wcdTooltip]="helpHtml"
				[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
				[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
				[attr.aria-label]="wcdTooltipAllowHtmlRendering ? null : toolTipAriaLabel"
				[attr.aria-labelledby]="wcdTooltipAllowHtmlRendering ? helpUniqueId : null"
			>
				<ng-container *ngTemplateOutlet="content"></ng-container>
			</span>
		</ng-template>

		<ng-template #content>
			<ng-content></ng-content>
		</ng-template>
	`,
	styleUrls: ['./help.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class HelpComponent implements OnChanges, OnInit {
	@Input() showIcon = true;
	@Input() title: string;
	@Input() text: string;
	@Input() isLight = false;
	@Input() tabIndexValue = 0;
	@Input() isLarge = false;
	@Input() ariaLabel?: string;
	@Input() wcdTooltipAllowHtmlRendering: boolean;
	@Input() wcdTooltipSanitizeHtml: boolean;
	@Input() wcdTooltipEnableKeyboardEvents = false;

	@Output() onFocus = new EventEmitter();
	@Output() onBlur = new EventEmitter();

	private _helpHtml: string;
	toolTipAriaLabel: string;
	readonly helpUniqueId = `help-${++lastId}`;
	readonly linkRegex = /\[(.*?)\]\((.*?)\)/gim;

	constructor(private domSanitizer: DomSanitizer) {}

	ngOnInit() {
		this.toolTipAriaLabel = this.ariaLabel ? this.ariaLabel : this.text;
	}

	ngOnChanges() {
		this._helpHtml = null;
	}

	get htmlTooltip() {
		if (sccHostService.isSCC) {
			return sccHostService.ui.sanitize(this.helpHtml as string);
		}
		return this.domSanitizer.sanitize(SecurityContext.HTML, this.helpHtml);
	}

	onFabFocus(event) {
		event.target.setAttribute('tabindex', '0');
		this.onFocus.emit();
	}

	onFabBlur(event) {
		event.target.setAttribute('tabindex', this.tabIndexValue);
		this.onBlur.emit();
	}

	get helpHtml() {
		if (!this._helpHtml) {
			this._helpHtml = (this.title ? `${this.title}:\n` : '') + (this.text ? `${this.text}` : '');
		}
		// Support markdown links
		this._helpHtml = this._helpHtml.replace(this.linkRegex, "<a href='$2'>$1</a>");

		return this._helpHtml.trim();
	}

	preventDefault(event: Event) {
		event.preventDefault();
		event.stopPropagation();
		event.stopImmediatePropagation();
	}
}
