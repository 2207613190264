var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, ChangeDetectorRef, OnInit, } from '@angular/core';
import { zip } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { SecurityRecommendation, RemediationTask, RemediationType, RemediationCategory, ItsmTool, RemediationTaskPriority, MdeUserRoleActionEnum, ProductivityImpactRemediationType, RecommendationCategory, RemediationErrorType, EolState, RecommendationToExceptionsRelationship, RecommendationExceptionStateValue, GlobalExceptionRbacGroupId, VulnerabilityType, TvmRecommendationOnboardingStatus, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { AuthService } from '@wcd/auth';
import { TvmMachineGroupsFilterService } from '../../../../tvm/services/tvm-machine-groups-filter.service';
import { take, map } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { RemediationItsmToolsService } from '../../remediation/remediation-tasks/services/remediation-itsm-tools-service';
import { AppConfigService } from '@wcd/app-config';
import { TabModel } from '../../../../shared/components/tabs/tab.model';
import { TvmProductivityImpactService } from '../../../../tvm/services/tvm-productivity-impact.service';
import { MessageBarStyles } from '../../../@tvm/common/styles';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
// Hard coded SCIDs that Intune supports.
var INTUNE_SUPPORTED_SCIDS = [
    'scid-2012',
    'scid-91',
    'scid-92',
    'scid-90',
    'scid-2016',
    'scid-2013',
    'scid-2003',
    'scid-2509',
    'scid-2513',
    'scid-2503',
    'scid-2502',
    'scid-2501',
    'scid-2506',
    'scid-2512',
    'scid-2505',
    'scid-2504',
    'scid-2510',
    'scid-2511',
    'scid-2507',
    'scid-2500',
    'scid-2508',
    'scid-2090',
    'scid-2021',
    'scid-20000',
];
export var Tabs;
(function (Tabs) {
    Tabs["update"] = "Update";
    Tabs["uninstall"] = "Uninstall";
    Tabs["none"] = "None";
})(Tabs || (Tabs = {}));
var TRUSTED_FILES_REASONS = [
    'FileCertificateMicrosoftRoot',
    'FileCertificateNonMsTrustedSigner',
];
var RemediationActionTabModel = /** @class */ (function (_super) {
    __extends(RemediationActionTabModel, _super);
    function RemediationActionTabModel() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    return RemediationActionTabModel;
}(TabModel));
var RemediationTaskCreationComponent = /** @class */ (function (_super) {
    __extends(RemediationTaskCreationComponent, _super);
    function RemediationTaskCreationComponent(router, paris, tvmTextsService, dialogsService, i18nService, changeDetectionRef, authService, machineGroupsFilterService, remediationItsmToolsService, appConfigService, productivityImpactService, appInsightsService, featuresService, rbacService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.tvmTextsService = tvmTextsService;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.changeDetectionRef = changeDetectionRef;
        _this.authService = authService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.remediationItsmToolsService = remediationItsmToolsService;
        _this.appConfigService = appConfigService;
        _this.productivityImpactService = productivityImpactService;
        _this.appInsightsService = appInsightsService;
        _this.rbacService = rbacService;
        _this._messageBarType = MessageBarType;
        _this.SpinnerSize = SpinnerSize;
        _this.shouldLinkToException = false;
        _this.remediationTaskPriority = RemediationTaskPriority.Medium;
        _this.earliestDateAllowed = moment()
            .add(24, 'hours')
            .toDate();
        _this.exportToCsv = false;
        _this.createTicketInIntune = false;
        _this.dataTrackMap = {};
        _this.submitDataTrackValue = '';
        _this.itsmNoneOption = 'None';
        _this.remediationTaskPriorityList = Object.keys(RemediationTaskPriority).filter(function (key) { return isNaN(key) && RemediationTaskPriority[key] !== 0; });
        _this.productivityImpactRemediationTypeList = Object.keys(ProductivityImpactRemediationType);
        _this.messageBarStyles = MessageBarStyles;
        _this.Tabs = Tabs;
        _this.isMicrosoftVendor = false;
        _this.isMac = false;
        _this.shouldHideMachineGroups = false;
        _this.existingActiveExceptionsRbacGroupIds = [];
        _this.done = new EventEmitter();
        _this.onRemediationDropdownChanged = function (newTab) {
            _this.selectedRemediationAction = newTab;
            // First check if user has selected the same entry two times in a row. In that case, do nothing.
            if (_this._remediationType == _this.selectedRemediationAction.data) {
                return;
            }
            if (newTab.id == Tabs.none) {
                _this._remediationType = RemediationType.AttentionRequired;
            }
            else {
                // Make sure it is their original remediation type since only 2 options.
                _this._remediationType = _this._originalRemediationType;
            }
            _this.shouldDisableDueToRemediationType = _this.shouldDisablePartialForms();
        };
        // TODO: remove after recommendation supports type 'Software Block'
        _this.parseEntityName = function (rawName) {
            return rawName
                .split('_')
                .map(function (s) { return s.charAt(0).toUpperCase() + s.substring(1); })
                .join(' ');
        };
        _this.isServiceNowFeatureEnabled =
            featuresService.isEnabled(Feature.TvmServiceNowIntegration) ||
                _this.appConfigService.IsServiceNowIntegrationEnabled;
        _this.isUserAllowedToCreateRemediation = _this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.tvmRemediationHandling);
        _this.isExceptionsPerRbacFeatureEnabled =
            appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
        _this.isZeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
        _this.isDeviceGroupFeatureEnabled = featuresService.isEnabled(Feature.TvmRemediationTaskDeviceGroups);
        return _this;
    }
    Object.defineProperty(RemediationTaskCreationComponent.prototype, "recommendation", {
        get: function () {
            return this._recommendation;
        },
        set: function (recommendation) {
            var assetStatistics = recommendation.assetsStatistics;
            this.showProductivityImpactComponents =
                this.productivityImpactService.isProductivityImpactExposed &&
                    !!assetStatistics.nonProductivityImpactedAssets;
            if (this.showProductivityImpactComponents) {
                this.applyRemediationSelection = this.tvmTextsService.getCountedProductivityImpactRemediations(assetStatistics);
                this.safeMachinesCounterText = this.tvmTextsService.getText(TextToken.RemediationTaskSafeMachines, assetStatistics);
            }
            this._recommendation = recommendation;
        },
        enumerable: true,
        configurable: true
    });
    RemediationTaskCreationComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this._remediationType = this._recommendation.remediationType;
        this._originalRemediationType = this._recommendation.remediationType;
        if (this.showProductivityImpactComponents && !this.productivityImpactRemediation) {
            this.productivityImpactRemediation = ProductivityImpactRemediationType.AllExposedAssets;
        }
        this.getPriorityLabel = this.getPriorityLabel.bind(this);
        this.getItsmToolLabel = this.getItsmToolLabel.bind(this);
        this.getUserImpactRemediationLabel = this.getUserImpactRemediationLabel.bind(this);
        this.isScaRemediation = this._recommendation.remediationType === RemediationType.ConfigurationChange;
        this.isVaUpdateRemediation = this._recommendation.remediationType === RemediationType.Update;
        this.isVaUninstallRemediation = this._recommendation.remediationType === RemediationType.Uninstall;
        this.isVaUpgradeRemediation = this._recommendation.remediationType === RemediationType.Upgrade;
        this.isVaAttentionRequiredRemediation =
            this._recommendation.remediationType === RemediationType.AttentionRequired;
        this.isVaRemediation =
            this.isVaUpdateRemediation ||
                this.isVaUninstallRemediation ||
                this.isVaUpgradeRemediation ||
                this.isVaAttentionRequiredRemediation;
        this.isRemediationOptionsSupported =
            (this.isZeroDayFeatureEnabled) && this.isVaRemediation;
        this.isZeroDayRecommendation =
            this._recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
                !this._recommendation.patchReleaseDate;
        this.remediationTaskTitle = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this._recommendation);
        this.warningText = this.getWarningText();
        this.shouldDisable = this.shouldDisableForms();
        this.shouldDisableDueToRemediationType = this.shouldDisablePartialForms();
        this.exceptionLink = "remediation/recommendation-exceptions/" + (this.isScaRemediation ? this._recommendation.scId : this._recommendation.productId);
        this.supportedItsmTools = this.isScaRemediation
            ? this.remediationItsmToolsService.getScaSupportedItsmTools()
            : this.remediationItsmToolsService.getSupportedItsmTools();
        this.connectedItsmTools = this.remediationItsmToolsService.getConnectedItsmTools();
        if (this.isScaRemediation) {
            this.connectedItsmTools = this.connectedItsmTools.filter(function (tool) {
                return _this.supportedItsmTools.includes(tool);
            });
            if (!INTUNE_SUPPORTED_SCIDS.includes(this._recommendation.scId)) {
                this.connectedItsmTools = this.connectedItsmTools.filter(function (tool) { return tool !== ItsmTool.Intune; });
            }
        }
        this.createSubmitDataTraceValue();
        this.updateSubmitDataTrackValue();
        this.initRemediationActionOptions();
        this.initActiveExceptionRbacGroups();
        var softwareName = this.parseEntityName(this._recommendation.productName);
        this.isNetworkDevice = this.recommendation.category === RecommendationCategory.NetworkGear;
        this.openIntuneTicketText =
            this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.NotOnboarded
                ? this.i18nService.strings
                    .tvm_securityRecommendation_remediationTaskCreation_openIntuneTicket_for_not_managed_devices
                : this.i18nService.strings
                    .tvm_securityRecommendation_remediationTaskCreation_openIntuneTicket;
    };
    RemediationTaskCreationComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this._savingSubscription && this._savingSubscription.unsubscribe();
        this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();
        this.queryIndicatorSubscription && this.queryIndicatorSubscription.unsubscribe();
    };
    RemediationTaskCreationComponent.prototype.isGlobalException = function (exception) {
        return (exception.rbacGroupId === null ||
            exception.rbacGroupId === undefined ||
            exception.rbacGroupId === GlobalExceptionRbacGroupId);
    };
    Object.defineProperty(RemediationTaskCreationComponent.prototype, "shouldDisabledMachineGroupsDropdown", {
        get: function () {
            return this.shouldDisable || this.allRbacGroupsHaveAnActiveException;
        },
        enumerable: true,
        configurable: true
    });
    RemediationTaskCreationComponent.prototype.submitRequest = function () {
        // Removed 'completing the remedition' as Elena mentioned it should no longer be done on the front end. Will re factor this code next time.
        this.createRemediationActivity();
    };
    // Only block remediation needs to complete remediation activity once it is created, so set 'complateTask' to false by default
    RemediationTaskCreationComponent.prototype.createRemediationActivity = function () {
        var _this = this;
        if (this.selectedItsmTool === ItsmTool.Intune) {
            this.dialogsService.showSnackbar({
                text: this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.pleaseWaitIntune'),
            });
        }
        if (this.isDeviceGroupFeatureEnabled) {
            this.submitRemediationActivity(this.machineGroups ? this.machineGroups.map(function (mg) { return mg.id; }) : []);
        }
        else {
            this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1)).subscribe(function (gr) {
                var groupIds = gr.machineGroups
                    .filter(function (mg) { return mg.isSelected; })
                    .map(function (mg) { return mg.groupId; })
                    .join();
                _this.submitRemediationActivity(groupIds);
            });
        }
    };
    RemediationTaskCreationComponent.prototype.submitRemediationActivity = function (groupIds) {
        var _this = this;
        this.isSaving = true;
        var newTask = this.createRemediationTask();
        var options = { params: [] };
        if (groupIds.length) {
            options.params['rbacGroups'] = "groups in (" + groupIds + ")";
        }
        this._savingSubscription = this.paris
            .getRepository(RemediationTask)
            .save(newTask, groupIds ? options : null)
            .subscribe(function (remediationTask) {
            _this.done.emit(_this.exportToCsv ? remediationTask : undefined);
            _this.dialogsService.showSuccessSnackbar({
                text: _this.i18nService.get('tvm.remediationTask.remediationCreated.remediationOnly.success'),
                icon: 'checkCircle',
                iconClassName: 'color-text-success',
            });
            _this.appInsightsService.trackEvent('tvm-remediation-submit', {
                ticket: newTask.ticket,
                taskArgs: newTask.taskArgs,
                export: _this.exportToCsv,
            });
        }, function (error) {
            var remediationError = error.response;
            var data = newTask.ticket &&
                newTask.ticket.itsmTool === ItsmTool.ServiceNow &&
                remediationError.type === RemediationErrorType.ExternalTicket &&
                remediationError.externalTicketError
                ? _this.getServicenowErrorMessageData(remediationError.externalTicketError)
                : _this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.creationFailMessage');
            _this.dialogsService.showError({
                title: _this.i18nService.strings.tvm_securityRecommendation_remediationTaskCreation_creationFailTitle,
                data: data,
            });
            _this.isSaving = false;
            _this.changeDetectionRef.markForCheck();
        });
    };
    RemediationTaskCreationComponent.prototype.machineGroupSelected = function (machineGroups) {
        this.machineGroups = machineGroups;
    };
    RemediationTaskCreationComponent.prototype.getUserImpactRemediationLabel = function (remediationType) {
        return this.applyRemediationSelection[remediationType];
    };
    RemediationTaskCreationComponent.prototype.getPriorityLabel = function (remediationTaskPriority) {
        return this.i18nService.get("tvm_remediationTask_priority_" + remediationTaskPriority);
    };
    RemediationTaskCreationComponent.prototype.getItsmToolLabel = function (itsmTool) {
        return this.i18nService.get("tvm_securityRecommendation_remediationTaskCreation_itsmToolSelection_" + itsmTool);
    };
    RemediationTaskCreationComponent.prototype.moveToSettingsPage = function (itsmTool) {
        var route = '';
        switch (itsmTool) {
            case ItsmTool.Intune:
                route = 'integration';
                break;
            case ItsmTool.ServiceNow:
                route = 'servicenow';
                break;
        }
        this.router.navigate(['/preferences2', route]);
    };
    RemediationTaskCreationComponent.prototype.getDisconnectedItsmTools = function () {
        var _this = this;
        return this.supportedItsmTools.filter(function (tool) { return !_this.connectedItsmTools.includes(tool); });
    };
    RemediationTaskCreationComponent.prototype.isItsmToolConnected = function (tool) {
        return this.connectedItsmTools.indexOf(tool) >= 0;
    };
    RemediationTaskCreationComponent.prototype.getEnableItsmToolText = function (itsmTool) {
        return this.i18nService.get("tvm.securityRecommendation.remediationTaskCreation.itsmToolEnable." + itsmTool);
    };
    RemediationTaskCreationComponent.prototype.onSelectDate = function (date) {
        if (date && date !== this.dueDate) {
            this.dueDate = date;
        }
    };
    RemediationTaskCreationComponent.prototype.updateSubmitDataTrackValue = function (event) {
        if (this.isServiceNowFeatureEnabled) {
            this.dataTrackMap['createItsmTicket'] = this.selectedItsmTool
                ? this.selectedItsmTool
                : this.itsmNoneOption;
        }
        else {
            this.selectedItsmTool = this.createTicketInIntune ? ItsmTool.Intune : null;
            this.dataTrackMap['createIntuneTicket'] = this.createTicketInIntune;
        }
        this.submitDataTrackValue = JSON.stringify(this.dataTrackMap);
    };
    RemediationTaskCreationComponent.prototype.getServicenowErrorMessageData = function (error) {
        var statusCodes = error.itsmStatusCode + "_" + error.externalServiceStatusCode;
        switch (statusCodes) {
            case '403_500':
                return this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_403_500_1 + "<br><br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_403_500_2;
            case '401_500':
                return this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_1 + " <a href=\"https://securitycenter.windows.com/preferences2/servicenow\">" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_link + "</a><br><br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_401_500_2;
            case '500_500':
                return this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_1 + "<br><br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_2 + "<a href=\"https://www.servicenow.com/support/contact-support.html\" target=\"_blank\" rel=\"noopener noreferrer\">" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_500_500_link + "</a>";
            case '200_500':
                return this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_1 + "<br><br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_2 + "<br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_200_500_3;
            default:
                return this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_default_1 + "<br><br>" + this.i18nService.strings.tvm_remediationTask_create_servicenow_errors_default_2;
        }
    };
    RemediationTaskCreationComponent.prototype.createSubmitDataTraceValue = function () {
        this.dataTrackMap = {
            remediationType: this.recommendation.remediationType,
            hasEolVersions: this.recommendation.hasEolVersions,
            hasUpcomingEolVersions: this.recommendation.hasUpcomingEolVersions,
        };
    };
    RemediationTaskCreationComponent.prototype.createRemediationTask = function () {
        var remediationTaskRelatedComponent = this.tvmTextsService.getText(TextToken.SecurityRecommendationRelatedComponent, this._recommendation);
        // Currently, there is a bug in back-end. DueDate is required for remediation request, however, in actuality, it is not required for AttentionRequired
        // Give it a date in the future for now. Email contact - jomok AND/OR elenaap
        // Since newTask is a const and I don't want to touch existing code, will override newTask here
        var futureDate = new Date();
        futureDate.setDate(futureDate.getDate() + 300);
        var remediationTask = {
            name: this.createRemediationTaskTitle(this._recommendation),
            description: this.createRemediationTaskDescription(this._recommendation),
            relatedComponent: remediationTaskRelatedComponent,
            requesterNotes: this.notes,
            potentialRisk: this.isScaRemediation ? this._recommendation.potentialRisk : null,
            recommendationDescription: this.isScaRemediation ? this._recommendation.description : null,
            dueDate: !this.isScaRemediation &&
                this.selectedRemediationAction &&
                this.selectedRemediationAction.id == 'None' &&
                this.dueDate == null
                ? futureDate
                : this.dueDate,
            taskArgs: this.createTaskArgs(this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.Onboarded),
            ticket: this.createTaskTicket(),
            priority: this.remediationTaskPriority,
        };
        return remediationTask;
    };
    RemediationTaskCreationComponent.prototype.shouldDisableForms = function () {
        return (this._recommendation.status === 'Exception' ||
            !this.isUserAllowedToCreateRemediation ||
            (this._recommendation.assetsStatistics.assetsAtRiskCount === 0 &&
                !this.isVaUninstallRemediation &&
                !this.isVaUpgradeRemediation));
    };
    RemediationTaskCreationComponent.prototype.shouldDisablePartialForms = function () {
        // Will probably add more conditions here as we iterate
        return this._remediationType === RemediationType.AttentionRequired;
    };
    RemediationTaskCreationComponent.prototype.shouldDisableSubmitButton = function () {
        if (this.isDeviceGroupFeatureEnabled) {
            // If user has de-selected all groups, disable submit button (no matter what the remediation type is)
            if (!this.shouldHideMachineGroups && this.machineGroups && this.machineGroups.length == 0) {
                return true;
            }
        }
        if (this._remediationType === RemediationType.AttentionRequired) {
            return false;
        }
        if (!this.dueDate) {
            return true;
        }
        return this.shouldDisable;
    };
    RemediationTaskCreationComponent.prototype.createTaskTicket = function () {
        return this.selectedItsmTool && this.selectedItsmTool !== this.itsmNoneOption
            ? {
                itsmTool: this.selectedItsmTool,
            }
            : null;
    };
    RemediationTaskCreationComponent.prototype.createTaskArgs = function (areAssetsManaged) {
        return this.isScaRemediation
            ? {
                productivityImpactRemediationType: this.productivityImpactRemediation,
                category: RemediationCategory.SecurityConfiguration,
                securityConfigurationArgs: {
                    scid: this._recommendation.scId,
                    taskType: this._recommendation.remediationType,
                },
                areAssetsManaged: areAssetsManaged,
            }
            : {
                productivityImpactRemediationType: this.productivityImpactRemediation,
                category: RemediationCategory.Software,
                softwareArgs: {
                    taskType: this._remediationType,
                    vendorId: this._recommendation.vendor,
                    nameId: this._recommendation.productName,
                    recommendedVersion: this._recommendation.recommendedVersion,
                    recommendedVendor: this._recommendation.recommendedVendor,
                    recommendedProgram: this._recommendation.recommendedProgram,
                    productId: this._recommendation.productId,
                    isEOL: this._recommendation.eolSoftwareState === EolState.AlreadyEOL,
                    isUpcomingEol: this._recommendation.eolSoftwareState === EolState.UpcomingEOL,
                    hasEolVersions: this._recommendation.hasEolVersions,
                    hasUpcomingEolVersions: this._recommendation.hasUpcomingEolVersions,
                    mitigation: null
                },
                areAssetsManaged: areAssetsManaged,
            };
    };
    RemediationTaskCreationComponent.prototype.getWarningText = function () {
        var text = '';
        if (!this.isUserAllowedToCreateRemediation) {
            text = text.concat(this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.NoPermission'));
        }
        if (this._recommendation.status === 'Exception') {
            text = text
                .concat(text.length === 0 ? '' : '\n\n')
                .concat(this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.recommendationUnderException'));
            this.shouldLinkToException = true;
        }
        if (this._recommendation.assetsStatistics.assetsAtRiskCount === 0 &&
            !this.isVaUninstallRemediation &&
            !this.isVaUpgradeRemediation) {
            text = text
                .concat(text.length === 0 ? '' : '\n\n')
                .concat(this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.noAssetsAtRisk'));
        }
        if (text.length === 0) {
            return this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.unknownError');
        }
        return text;
    };
    RemediationTaskCreationComponent.prototype.createRemediationTaskTitle = function (recommendation) {
        // Should use text service to get title, but we don't have a recommendation of type 'Block', and remediationType in recommendation is readonly, so need to use a hacky way here
        if (this._remediationType === RemediationType.AttentionRequired && !this.isZeroDayRecommendation) {
            return this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.attnSoftware', {
                software: this.parseEntityName(recommendation.productName),
            });
        }
        else {
            return this.remediationTaskTitle;
        }
    };
    RemediationTaskCreationComponent.prototype.createRemediationTaskDescription = function (recommendation) {
        var recommendationDescription = this.tvmTextsService.getText(TextToken.SecurityRecommendationDescription, { recommendation: recommendation, noHtmlTags: true });
        if (this.isScaRemediation) {
            return recommendation.remediationDescription;
        }
        else if (this._remediationType == RemediationType.AttentionRequired &&
            !this.isZeroDayRecommendation) {
            var fullProductName = this.tvmTextsService.getText(TextToken.EntityFullName, [
                this._recommendation.productName,
                this._recommendation.vendor,
            ]);
            return this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.attnSoftwareDescription', { software: "" + fullProductName });
        }
        else if (this.isVaUpdateRemediation ||
            this.isVaUninstallRemediation ||
            this.isVaUpgradeRemediation ||
            (this.isZeroDayRecommendation && this._remediationType == RemediationType.AttentionRequired)) {
            var vaDefinedRemediation = recommendation.remediationDescription;
            return vaDefinedRemediation ? vaDefinedRemediation : recommendationDescription;
        }
        else {
            throw new Error("'" + recommendation.remediationType + "' isn't a supported remediation type");
        }
    };
    RemediationTaskCreationComponent.prototype.initRemediationActionOptions = function () {
        var _this = this;
        if ((!this.isZeroDayFeatureEnabled) ||
            !this.isVaRemediation ||
            this.isVaUpgradeRemediation) {
            return;
        }
        var remediationActionOptions = [];
        if (this.isVaUpdateRemediation || this.isVaAttentionRequiredRemediation) {
            remediationActionOptions.push({
                id: Tabs.update,
                name: 'Update',
                data: RemediationType.Update,
            });
        }
        if (this.isVaUninstallRemediation || this.isVaAttentionRequiredRemediation) {
            remediationActionOptions.push({
                id: Tabs.uninstall,
                name: 'Uninstall',
                data: RemediationType.Uninstall,
            });
        }
        if (this.isZeroDayFeatureEnabled) {
            remediationActionOptions.push({
                id: Tabs.none,
                name: 'Attention required',
                data: RemediationType.AttentionRequired,
            });
        }
        this.selectableRemediationActions = remediationActionOptions.map(function (tab) { return new TabModel(tab); });
        var defaultRemediationAction = this.selectableRemediationActions.find(function (remediation) { return remediation.id === _this.defaultSelectedRemediationActionId; });
        this.onRemediationDropdownChanged(defaultRemediationAction || this.selectableRemediationActions[0]);
    };
    RemediationTaskCreationComponent.prototype.initActiveExceptionRbacGroups = function () {
        var _this = this;
        var isRbacGroupDropdownEnabled = this.isDeviceGroupFeatureEnabled;
        if (!this.isExceptionsPerRbacFeatureEnabled || !isRbacGroupDropdownEnabled) {
            return;
        }
        // Get related exceptions, so we can hide the machine groups that have been 'excepted'
        var repository = this.paris.getRelationshipRepository(RecommendationToExceptionsRelationship);
        repository.sourceItem = this._recommendation;
        zip(this.rbacService.userExposedRbacGroups$.pipe(map(function (res) { return res.map(function (machineGroup) { return machineGroup.id; }); })), repository.query().pipe(map(function (res) { return res.items; }))).subscribe(function (_a) {
            var userExposedRbacGroupIds = _a[0], existingRecommendationExceptions = _a[1];
            _this.userExposedRbacGroupIds = userExposedRbacGroupIds;
            _this.existingRecommendationExceptions = existingRecommendationExceptions;
            _this.existingActiveExceptionsRbacGroupIds = _this.existingRecommendationExceptions
                .filter(function (exception) {
                return exception.status.value === RecommendationExceptionStateValue.Active &&
                    !_this.isGlobalException(exception);
            })
                .map(function (exception) { return exception.rbacGroupId; });
            var existingActiveExceptionsRbacGroupIdsSet = new Set(_this.existingActiveExceptionsRbacGroupIds);
            _this.allRbacGroupsHaveAnActiveException = _this.userExposedRbacGroupIds.every(function (rbacGroupId) {
                return existingActiveExceptionsRbacGroupIdsSet.has(rbacGroupId);
            });
            if (_this.allRbacGroupsHaveAnActiveException) {
                // Under full exception, disable form
                _this.shouldDisable = true;
                _this.warningText = _this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.recommendationUnderException');
                _this.shouldLinkToException = true;
            }
            _this.changeDetectionRef.markForCheck();
        });
    };
    return RemediationTaskCreationComponent;
}(PanelContainer));
export { RemediationTaskCreationComponent };
