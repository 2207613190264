import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import {
	ItsmTool,
	ItsmProvisioning,
	getEndpointForItsmTool,
} from '../../../../../../../../../packages/@wcd/domain/src';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class ItsmProvisioningService {
	private provisionedItsmTools: Map<ItsmTool, Partial<ItsmProvisioning>> = new Map();

	constructor(private paris: Paris) {}

	public provisionItsmTool(item: Partial<ItsmProvisioning>): Observable<string> {
		return this.paris
			.getRepository(ItsmProvisioning)
			.save(item)
			.pipe(tap(itsmProvisioning => this.provisionedItsmTools.set(item.itsmTool, itsmProvisioning)))
			.pipe(map(itsmProvisioning => itsmProvisioning.redirect));
	}

	public deprovisionItsmTool(item: Partial<ItsmProvisioning>): Observable<any> {
		return this.paris
			.getRepository(ItsmProvisioning)
			.removeItem(<ItsmProvisioning>item)
			.pipe(
				tap(_ =>
					this.provisionedItsmTools.set(item.itsmTool, {
						isProvisioned: false,
					})
				)
			);
	}

	public getItsmToolProvisioning(itsmTool: ItsmTool): Observable<Partial<ItsmProvisioning>> {
		if (!this.provisionedItsmTools.has(itsmTool)) {
			return this.paris
				.getRepository(ItsmProvisioning)
				.getItemById(`${getEndpointForItsmTool(ItsmTool.ServiceNow)}/configurations`)
				.pipe(tap(result => this.provisionedItsmTools.set(itsmTool, result)));
		}
		return of(this.provisionedItsmTools.get(itsmTool));
	}
}
