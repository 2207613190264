import { MitreService } from './mitre.service';
import { i18nLoader } from '../../../../projects/i18n/src/lib/services/i18n.loader.svc';

export class MdatpMitreService extends MitreService {
	private static i18nService = i18nLoader;

	public static getMitreTechniqueFullDescription(mitreTechniqueId: string): string {
		return super.getMitreTechniqueFullDescription(mitreTechniqueId, this.i18nService);
	}
}
