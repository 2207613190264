import { Injectable } from '@angular/core';
import { LocalStorageService } from '@wcd/shared';

// TODO: Move to it's own package
@Injectable({ providedIn: 'root' })
export class HuntingMainLocalStorageService {
	constructor(private localStorageService: LocalStorageService) {}
	getItemFromStorage<T>(key: string): T | null {
		const item = this.localStorageService.getItem(key);
		if (!item) {
			return null;
		}

		return JSON.parse(item);
	}

	removeItemFromStorage(key: string) {
		this.localStorageService.removeItem(key);
	}

	setItemToStorage<T>(key: string, item: T) {
		this.localStorageService.setItem(key, JSON.stringify(item));
	}
}
