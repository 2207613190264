import { Component, Input } from '@angular/core';
import { IconsService } from '@wcd/icons';
import {
	IndicationIcon,
	IndicationIconState,
} from '../../../shared/components/indication-icons/indication-icons.component';
import { TvmIconsConfig } from '../../services/tvm-icon-builder.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'tvm-threat-icons',
	template: `
		<wcd-indication-icons [attr.aria-label]="label" [allowTooltipHTMLRender]="_allowTooltipHTMLRender" [indicationIcons]="_indicationIcons"></wcd-indication-icons>
	`,
})
export class TvmThreatIconsComponent {
	label: string;

	@Input()
	set config(threatIconsComponentConfig: TvmIconsConfig) {
		this.setLabel(threatIconsComponentConfig);
		this._allowTooltipHTMLRender = threatIconsComponentConfig.allowTooltipHTMLRender
		this._indicationIcons = [
			{
				show: threatIconsComponentConfig.activeThreatIcon.show,
				icon: this.iconsService.getIcon('tvm.isThreatActive'),
				state: threatIconsComponentConfig.activeThreatIcon.isThreatActive
					? IndicationIconState.On
					: IndicationIconState.Off,
				tooltip: threatIconsComponentConfig.activeThreatIcon.tooltip || '',
			},
			{
				show: threatIconsComponentConfig.exploitIcon.show,
				icon: this.iconsService.getIcon('tvm.hasExploit'),
				state: threatIconsComponentConfig.exploitIcon.hasExploit
					? IndicationIconState.On
					: IndicationIconState.Off,
				tooltip: threatIconsComponentConfig.exploitIcon.tooltip || '',
			},
			{
				show: threatIconsComponentConfig.insightIcon.show,
				icon: this.iconsService.getIcon('tvm.insight'),
				state: threatIconsComponentConfig.insightIcon.hasInsight
					? IndicationIconState.Neutral
					: IndicationIconState.Off,
				tooltip: threatIconsComponentConfig.insightIcon.tooltip || '',
			},
		];
	}

	_indicationIcons: Array<IndicationIcon>;
	_allowTooltipHTMLRender: boolean;

	constructor(
		private iconsService: IconsService,
		private i18nService: I18nService
	) { }

	private setLabel(threatIconsComponentConfig: TvmIconsConfig) {
		//setting the label with booleans depending on their values
		this.label = threatIconsComponentConfig.exploitIcon.hasExploit ? this.i18nService.get('tvm_threat_has_exploit') + ", " : "";
		this.label += threatIconsComponentConfig.insightIcon.hasInsight ? this.i18nService.get('tvm_threat_has_insight') + ", " : "";
		this.label += threatIconsComponentConfig.activeThreatIcon.isThreatActive ? this.i18nService.get('tvm_threat_is_active_threat') + ", " : "";
		//removing the last ", "
		this.label = this.label.slice(0, -2);
		
		if (this.label === "") {
			this.label = this.i18nService.get('tvm_threat_no_threats');
		}
	}
}
