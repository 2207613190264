var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediatedThreatStatusCount } from './remediation-threat-status-count';
import { RemediationStatusType } from '../airs_entity/airs-entity-status-types.enum';
var ɵ0 = function (statusCountArr) {
    return statusCountArr.filter(function (statusCount) {
        return statusCount.status === RemediationStatusType.Failed ||
            statusCount.status === RemediationStatusType.Remediated;
    });
};
var RemediationThreatStatusSummary = /** @class */ (function (_super) {
    __extends(RemediationThreatStatusSummary, _super);
    function RemediationThreatStatusSummary(data) {
        var _this = _super.call(this, data) || this;
        _this.totalEntityCount = _this.statusCount.reduce(function (acc, currentVal) { return acc + currentVal.entityCount; }, 0);
        return _this;
    }
    __decorate([
        EntityField({
            arrayOf: RemediatedThreatStatusCount,
            parse: ɵ0,
        }),
        __metadata("design:type", Array)
    ], RemediationThreatStatusSummary.prototype, "statusCount", void 0);
    RemediationThreatStatusSummary = __decorate([
        ValueObject({
            singularName: 'Remediated Threat Status Summary',
            pluralName: '',
            readonly: true,
        }),
        __metadata("design:paramtypes", [Object])
    ], RemediationThreatStatusSummary);
    return RemediationThreatStatusSummary;
}(ModelBase));
export { RemediationThreatStatusSummary };
export { ɵ0 };
