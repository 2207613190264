var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField } from '@microsoft/paris';
import { DetectionSource } from './sources/detection-source.entity';
import { MachineGroup } from '../rbac/machine-group.entity';
import { SuppressionRuleConditionIocType } from '../suppression-rule/suppression-rule-condition-ioc-type.enum';
import { AlertGroupType } from './alert-group-type.entity';
import { AlertStatus } from './alert-status/alert-status.entity';
import { AlertBase } from './alert.entity.base';
import { AlertFileContext } from './contexts/alert-file-context.value-object';
import { AlertIPContext } from './contexts/alert-ip-context.value-object';
import { AlertUrlContext } from './contexts/alert-url-context.value-object';
import { IncidentLinkedBy } from './linked-by/incident-linked-by.value-object';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';
import { RelatedEvidenceCount } from './related-evidence-count/related-evidence-count.value-object';
import { ServiceSource } from './sources/service-source.entity';
import { AggregatedIncidentLinkedBy } from './linked-by/aggregated-incident-linked-by.value-object';
import { EntityTags } from '../tag/entity-tags.value-object';
export var THREAT_EXPERT_DETECTION_SOURCE_TYPE = 'ThreatExperts';
export var MCAS_SOURCE_TYPE = 'MCAS';
export var CUSTOM_DETECTION_SOURCE_TYPE = 'CustomDetection';
export var MTP_SOURCE_TYPE = 'MTP';
var ɵ0 = function (itemId) {
    return "alerts?id=" + itemId;
}, ɵ1 = function (dataQuery) {
    if (!dataQuery)
        return {};
    var pageSettings = {};
    if (dataQuery.page)
        pageSettings.pageIndex = dataQuery.page;
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    return Object.assign({}, dataQuery.where, pageSettings, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
}, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ3 = function (alertId, rawData) {
    return alertId || (rawData.AlertCount > 1 && rawData.GroupId && "group_" + rawData.GroupId);
}, ɵ4 = ['Product', 'ProductSource'], ɵ5 = function (status) {
    if (!status)
        return status;
    var statusLog = Math.log2(status);
    return statusLog === Math.floor(statusLog) ? status : null;
}, ɵ6 = function (mitreTechniques) {
    return mitreTechniques && mitreTechniques.split(';').length ? mitreTechniques.split(';') : null;
}, ɵ7 = function (data) {
    var intInvId = parseInt(data.InvestigationId);
    // currently office investigationId can be string
    return isNaN(intInvId) || intInvId > 0;
}, ɵ8 = function (data) {
    var intInvId = parseInt(data.InvestigationId);
    // currently office investigationId can be string
    return isNaN(intInvId);
}, ɵ9 = function (rawValue) {
    // New format - needs no parsing
    if (typeof rawValue !== 'string') {
        return rawValue;
    }
    // Old format - "Automation"
    if (rawValue === 'Automation') {
        return [
            {
                Category: rawValue,
            },
        ];
    }
    // Old format - User UPN
    return [
        {
            Category: 'User',
            TargetAlert: {
                Id: null,
                SourceEntity: {
                    Type: 'User',
                    Id: null,
                    Name: rawValue,
                },
            },
        },
    ];
}, ɵ10 = ['AlertTags', 'AggregatedAlertTags'];
var Alert = /** @class */ (function (_super) {
    __extends(Alert, _super);
    function Alert(data) {
        return _super.call(this, data) || this;
    }
    Object.defineProperty(Alert.prototype, "availableIocs", {
        get: function () {
            return []
                .concat(this.fileContexts
                .filter(function (context) { return context.name || context.path || context.sha1; })
                .map(function (context) {
                return { type: SuppressionRuleConditionIocType.File, context: context };
            }))
                .concat(this.urlContexts
                .filter(function (context) { return context.name; })
                .map(function (context) {
                return { type: SuppressionRuleConditionIocType.Url, context: context };
            }))
                .concat(this.ipContexts
                .filter(function (context) { return context.name; })
                .map(function (context) {
                return { type: SuppressionRuleConditionIocType.Ip, context: context };
            }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "isGroup", {
        get: function () {
            return this.groupedAlertsCount > 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "isThreatExpertOriginated", {
        get: function () {
            return this.detectionSource && this.detectionSource.type === THREAT_EXPERT_DETECTION_SOURCE_TYPE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "isMcasOriginated", {
        get: function () {
            return this.detectionSource && this.detectionSource.type === MCAS_SOURCE_TYPE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Alert.prototype, "isCustomDetection", {
        get: function () {
            return this.detectionSource && this.detectionSource.type === CUSTOM_DETECTION_SOURCE_TYPE;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: 'AlertId',
            parse: ɵ3,
        }),
        __metadata("design:type", String)
    ], Alert.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'ParentAlertId' }),
        __metadata("design:type", String)
    ], Alert.prototype, "parentId", void 0);
    __decorate([
        EntityField({ data: 'FormattedUxDescription' }),
        __metadata("design:type", String)
    ], Alert.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'AdditionalUxDescription' }),
        __metadata("design:type", String)
    ], Alert.prototype, "extendedDescription", void 0);
    __decorate([
        EntityField({ data: 'ActorName' }),
        __metadata("design:type", String)
    ], Alert.prototype, "actor", void 0);
    __decorate([
        EntityField({ data: 'DetectionSource' }),
        __metadata("design:type", DetectionSource)
    ], Alert.prototype, "detectionSource", void 0);
    __decorate([
        EntityField({ data: 'DetectionTech' }),
        __metadata("design:type", String)
    ], Alert.prototype, "detectionTechnology", void 0);
    __decorate([
        EntityField({ data: 'BlockingStatus' }),
        __metadata("design:type", String)
    ], Alert.prototype, "detectionStatus", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", ServiceSource)
    ], Alert.prototype, "serviceSource", void 0);
    __decorate([
        EntityField({
            data: 'Status',
            parse: ɵ5,
        }),
        __metadata("design:type", AlertStatus)
    ], Alert.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'RecommendedAction' }),
        __metadata("design:type", String)
    ], Alert.prototype, "recommendedAction", void 0);
    __decorate([
        EntityField({ data: 'ThreatIntelProvider' }),
        __metadata("design:type", String)
    ], Alert.prototype, "threatInterProvider", void 0);
    __decorate([
        EntityField({ data: 'ThreatFamilyName' }),
        __metadata("design:type", String)
    ], Alert.prototype, "threatFamilyName", void 0);
    __decorate([
        EntityField({
            data: 'MitreTechniques',
            parse: ɵ6,
        }),
        __metadata("design:type", Array)
    ], Alert.prototype, "mitreTechniques", void 0);
    __decorate([
        EntityField({ data: 'LinkToEncyclopedia' }),
        __metadata("design:type", String)
    ], Alert.prototype, "encyclopediaUrl", void 0);
    __decorate([
        EntityField({ data: 'SuppressionRuleId' }),
        __metadata("design:type", Number)
    ], Alert.prototype, "suppressionRuleId", void 0);
    __decorate([
        EntityField({ data: 'SuppressionRuleTitle' }),
        __metadata("design:type", String)
    ], Alert.prototype, "suppressionRuleTitle", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationId',
            require: ɵ7,
        }),
        __metadata("design:type", Object)
    ], Alert.prototype, "investigationId", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationId',
            require: ɵ8,
        }),
        __metadata("design:type", Boolean)
    ], Alert.prototype, "isOfficeInvestigation", void 0);
    __decorate([
        EntityField({ data: 'InvestigationCount', defaultValue: 0 }),
        __metadata("design:type", Number)
    ], Alert.prototype, "investigationCount", void 0);
    __decorate([
        EntityField({ data: 'GroupId' }),
        __metadata("design:type", Number)
    ], Alert.prototype, "groupId", void 0);
    __decorate([
        EntityField({ data: 'GroupHash' }),
        __metadata("design:type", String)
    ], Alert.prototype, "groupHash", void 0);
    __decorate([
        EntityField({ data: 'GroupKey' }),
        __metadata("design:type", AlertGroupType)
    ], Alert.prototype, "groupedBy", void 0);
    __decorate([
        EntityField({ data: 'AlertCount' }),
        __metadata("design:type", Number)
    ], Alert.prototype, "groupedAlertsCount", void 0);
    __decorate([
        EntityField({ data: 'IoaDefinitionId' }),
        __metadata("design:type", String)
    ], Alert.prototype, "ioaDefinitionId", void 0);
    __decorate([
        EntityField({ data: 'FileMonitorContextList', arrayOf: AlertFileContext }),
        __metadata("design:type", Array)
    ], Alert.prototype, "fileContexts", void 0);
    __decorate([
        EntityField({ data: 'IPMonitorContextList', arrayOf: AlertIPContext }),
        __metadata("design:type", Array)
    ], Alert.prototype, "ipContexts", void 0);
    __decorate([
        EntityField({ data: 'URLMonitorContextList', arrayOf: AlertUrlContext }),
        __metadata("design:type", Array)
    ], Alert.prototype, "urlContexts", void 0);
    __decorate([
        EntityField({ data: 'ImpactedEntities' }),
        __metadata("design:type", ImpactedEntities)
    ], Alert.prototype, "impactedEntities", void 0);
    __decorate([
        EntityField({ data: 'IsReadOnly' }),
        __metadata("design:type", Boolean)
    ], Alert.prototype, "isReadOnly", void 0);
    __decorate([
        EntityField({
            data: 'IncidentLinkedBy',
            arrayOf: IncidentLinkedBy,
            parse: ɵ9,
        }),
        __metadata("design:type", Array)
    ], Alert.prototype, "incidentLinkedBy", void 0);
    __decorate([
        EntityField({
            data: 'AggregatedIncidentLinkedBy',
            arrayOf: AggregatedIncidentLinkedBy,
            required: false,
        }),
        __metadata("design:type", Array)
    ], Alert.prototype, "aggregatedIncidentLinkedBy", void 0);
    __decorate([
        EntityField({ data: 'IncidentId' }),
        __metadata("design:type", String)
    ], Alert.prototype, "incidentId", void 0);
    __decorate([
        EntityField({ data: 'IncidentTitle' }),
        __metadata("design:type", String)
    ], Alert.prototype, "incidentName", void 0);
    __decorate([
        EntityField({ data: 'IncidentCount' }),
        __metadata("design:type", Number)
    ], Alert.prototype, "incidentCount", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupId' }),
        __metadata("design:type", MachineGroup)
    ], Alert.prototype, "machineGroup", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupCount' }),
        __metadata("design:type", Number)
    ], Alert.prototype, "machineGroupCount", void 0);
    __decorate([
        EntityField({ data: 'RelatedEvidenceCount' }),
        __metadata("design:type", RelatedEvidenceCount)
    ], Alert.prototype, "relatedEvidenceCount", void 0);
    __decorate([
        EntityField({ data: 'AlertPageUrl' }),
        __metadata("design:type", String)
    ], Alert.prototype, "alertPageExternalUrl", void 0);
    __decorate([
        EntityField({ data: 'MachineTags' }),
        __metadata("design:type", Array)
    ], Alert.prototype, "systemTags", void 0);
    __decorate([
        EntityField({ data: ɵ10, required: false }),
        __metadata("design:type", EntityTags)
    ], Alert.prototype, "alertTags", void 0);
    __decorate([
        EntityField({ data: 'IncidentLinkedByCount', required: false }),
        __metadata("design:type", Number)
    ], Alert.prototype, "incidentLinkedByCount", void 0);
    Alert = __decorate([
        Entity({
            singularName: 'Alert',
            pluralName: 'Alerts',
            endpoint: 'alerts',
            parseItemQuery: ɵ0,
            parseDataQuery: ɵ1,
            baseUrl: ɵ2,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        }),
        __metadata("design:paramtypes", [Object])
    ], Alert);
    return Alert;
}(AlertBase));
export { Alert };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10 };
