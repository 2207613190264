var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter } from '@angular/core';
import { ChartComponent } from '../chart.component.base';
import { format } from 'd3-format';
import { select } from 'd3-selection';
import * as d3 from 'd3';
var StackedBarsChartComponent = /** @class */ (function (_super) {
    __extends(StackedBarsChartComponent, _super);
    function StackedBarsChartComponent(elementRef) {
        var _this = _super.call(this, elementRef) || this;
        _this.xLabelClick = new EventEmitter();
        _this.chartSettings = {
            chartType: 'bar',
            useValues: true,
            xProperty: 'label',
            setColors: true,
            stackGroups: true,
            data: {
                order: 'desc',
            },
            options: {
                data: {},
                axis: {
                    y: {
                        tick: {
                            format: format('d'),
                        },
                    },
                },
                onrendered: _this.onRendered.bind(_this),
                oninit: _this.onInit.bind(_this),
            },
        };
        return _this;
    }
    StackedBarsChartComponent.prototype.onRendered = function () {
        var _this = this;
        if (!this.allowXLabelClick)
            return;
        var divElement = select(this.el);
        var xAxis = divElement.select('.c3-axis-x');
        xAxis
            .selectAll('.tick')
            .select('text')
            .attr('class', 'btn-link ms-fill-blue')
            .on('click', function (index) {
            _this.xLabelClick.emit(index);
        });
    };
    StackedBarsChartComponent.prototype.onInit = function () {
        var _this = this;
        setTimeout(function () {
            var options = _this.getChartOptions();
            d3.selectAll(_this.el.querySelectorAll("path.c3-bar"))
                .attr('tabindex', '0')
                .attr('focusable', 'true')
                .each(function (dataPoint, index, nodesList) {
                d3.select(nodesList[index]).attr('aria-label', dataPoint.id + ", " + options.axis.x.categories[dataPoint.x] + ", " + dataPoint.value + ", ");
            });
        }, 0);
    };
    return StackedBarsChartComponent;
}(ChartComponent));
export { StackedBarsChartComponent };
