var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { DataTableField } from '@wcd/datatable';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { Alert, FileVerdict } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { FileVerdictAlertService } from '../services/file-verdict-alert.service';
import { AlertNameFieldComponent } from '../../alerts/components/alert-name-field.component';
var MalwareAlertsComponent = /** @class */ (function (_super) {
    __extends(MalwareAlertsComponent, _super);
    function MalwareAlertsComponent(router, paris, injector, i18nService, globalEntityTypesService, fileVerdictAlertService) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.paris = paris;
        _this.injector = injector;
        _this.i18nService = i18nService;
        _this.fileVerdictAlertService = fileVerdictAlertService;
        _this.tableFields = DataTableField.fromList([
            {
                id: 'detectionDate',
                name: _this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.detectionDate'),
                component: {
                    type: TzDateComponent,
                    getProps: function (alert) { return ({ date: alert.firstEventTime }); },
                },
            },
            {
                id: 'alertName',
                name: _this.i18nService.get('files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.alertName'),
                component: {
                    type: AlertNameFieldComponent,
                    getProps: function (alert) { return ({ alert: alert }); },
                },
            },
        ]);
        _this.entityPanelsService = _this.injector.get(EntityPanelsService);
        _this.alertEntityTypeService = globalEntityTypesService.getEntityType(Alert);
        return _this;
    }
    MalwareAlertsComponent.prototype.ngOnInit = function () {
        this.tableItems$ = this.fileVerdictAlertService.getFileVerdictAlerts(this.sha1, this.fileVerdict);
    };
    MalwareAlertsComponent.prototype.openAlertPage = function (alert) {
        this.router.navigateByUrl(this.alertEntityTypeService.getEntitiesLink([alert]));
    };
    return MalwareAlertsComponent;
}(PanelContainer));
export { MalwareAlertsComponent };
