import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { IncidentGraphNodeEntityType } from './incident-graph-node-entity-type.entity';
import { IncidentGraphCategory } from './incident-graph-category.entity';
import { NetworkEndpoint } from '../../entity/network-endpoint.value-object';
import { IncidentGraphNodeSource } from './incident-graph-node-source.entity';
import { IncidentGraphNodeEntityTypeId } from './incident-graph-node-entity-type.values';
import { File } from '../../file/file.entity';
import { LegacyUser } from '../../legacy/user/legacy-user.entity';
import { IncidentGraphGroupingLogics } from './incident-graph-grouping-logics.value-object';
import { Registry } from '../../entity/registry.value-object';
export type IncidentGraphNodeId = string;

@ValueObject({
	singularName: 'Incident graph node',
	pluralName: 'Incident graph nodes',
})
export class IncidentGraphNode extends ModelBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: IncidentGraphNodeId;

	@EntityField({ data: 'EntityType' })
	entityType: IncidentGraphNodeEntityType;

	@EntityField({ data: 'Machine' })
	machine: Machine;

	@EntityField({ data: 'File' })
	file: File;

	@EntityField({ data: 'NetworkEndpoint' })
	networkEndpoint: NetworkEndpoint;

	@EntityField({ data: 'Registry' })
	registry: Registry;

	@EntityField({ data: 'User' })
	user: LegacyUser;

	@EntityField({ data: 'ChildNodes', arrayOf: IncidentGraphNode })
	childNodes: Array<IncidentGraphNode>;

	@EntityField({ data: 'Category' })
	category: IncidentGraphCategory;

	@EntityField({ data: 'GroupName' })
	groupName: string;

	@EntityField({
		data: 'GroupingLogics',
		arrayOf: IncidentGraphGroupingLogics,
	})
	groupingLogics: Array<IncidentGraphGroupingLogics>;

	@EntityField({ data: 'Source' })
	source: IncidentGraphNodeSource;

	@EntityField({ data: 'RelatedResourcesNodeIds' })
	relatedResourcesNodeIds: Array<IncidentGraphNodeId>;

	get isGroup(): boolean {
		return !!this.groupName;
	}

	get name(): string {
		if (this.groupName) return this.groupName;

		if (!this.entityType) return this.id;

		switch (this.entityType.id) {
			case IncidentGraphNodeEntityTypeId.NetworkEndpoint:
				return this.networkEndpoint.name;
			case IncidentGraphNodeEntityTypeId.File:
				return this.file.fileName || this.file.name;
			case IncidentGraphNodeEntityTypeId.Machine:
				return this.machine.shortName || this.machine.name;
			case IncidentGraphNodeEntityTypeId.User:
				return this.user['name'];
			case IncidentGraphNodeEntityTypeId.Registry:
				const registryKeyLastToken = this.registry.key.split('\\').pop();
				return this.registry.valueName
					? `${registryKeyLastToken}->${this.registry.valueName}`
					: registryKeyLastToken;
			default:
				return this.id;
		}
	}

	get entity(): Machine | File | NetworkEndpoint | Registry {
		return this.machine || this.file || this.networkEndpoint || this.registry;
	}
}
