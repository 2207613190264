var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { get } from 'lodash-es';
var ɵ0 = function (config) { return config.data.serviceUrls.k8s; }, ɵ1 = function (input) {
    return {
        data: {
            domain: input['address'],
        },
    };
}, ɵ2 = function (data) {
    data = data && data.length && data[0];
    var registrant = get(data, 'parsed_whois.contacts.registrant');
    var mailingAddress = registrant
        ? [
            get(registrant, 'street[0]'),
            registrant['city'],
            [registrant['state'], registrant['postal']].filter(Boolean).join(' '),
            registrant['country'],
        ]
            .filter(Boolean)
            .join(', ')
        : null;
    var registrar = get(data, 'parsed_whois.registrar');
    var created = data['created'];
    created = created && (created.endsWith('Z') ? created : created + 'Z');
    var expires = data['expires'];
    expires = expires && (expires.endsWith('Z') ? expires : expires + 'Z');
    var updated = data['updated'];
    updated = updated && (updated.endsWith('Z') ? updated : updated + 'Z');
    return {
        registrant: {
            organization: get(registrant, 'org'),
            owner: get(registrant, 'name'),
            email: get(registrant, 'email'),
            phone: get(registrant, 'phone'),
            mailingAddress: mailingAddress,
        },
        registrar: {
            name: registrar && registrar['name'],
        },
        created: created && new Date(created),
        expires: expires && new Date(expires),
        updated: updated && new Date(updated),
    };
};
var InterflowUrlDataApiCall = /** @class */ (function (_super) {
    __extends(InterflowUrlDataApiCall, _super);
    function InterflowUrlDataApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    InterflowUrlDataApiCall = __decorate([
        ApiCall({
            name: 'URL data',
            endpoint: 'autoir/officeui/interflow/domain',
            baseUrl: ɵ0,
            method: 'POST',
            parseQuery: ɵ1,
            parseData: ɵ2,
            cache: {
                time: 60 * 1000 * 60,
            },
        })
    ], InterflowUrlDataApiCall);
    return InterflowUrlDataApiCall;
}(ApiCallModel));
export { InterflowUrlDataApiCall };
export { ɵ0, ɵ1, ɵ2 };
