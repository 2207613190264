import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class EntityIdRouteService {
	constructor(private readonly activatedRoute: ActivatedRoute) {}

	getRouteId({
		route = this.activatedRoute.snapshot,
		itemIdParamName = 'id',
	}: { route?: ActivatedRouteSnapshot; itemIdParamName?: string } = {}) {
		return route.data.entityIdResolver
			? route.data.entityIdResolver(route)
			: route.params[itemIdParamName];
	}
}
