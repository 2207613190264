import { Component, AfterViewInit } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { EndpointManagementProcessesService } from '../services/endpoint-management-processes.service';
import { EndpointManagementProcess } from '../models/endpoint-management-process.model';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../insights/services/performance.scc.service';

@Component({
	selector: 'endpoint-management-page',
	templateUrl: './endpoint-management-page.component.html',
})
export class EndpointManagementPageComponent implements AfterViewInit {
	onboarding: boolean;
	osTypes: Array<EndpointManagementProcess> = this.endpointManagementProcessesService.all;
	currentOsType: EndpointManagementProcess = this.endpointManagementProcessesService.default;
	tutorialsEnabled: boolean = this.featuresService.isEnabled(Feature.SimulationsAndTutorials);
	isLicenseExpired: boolean = false;
	isScc = sccHostService.isSCC;

	constructor(
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService,
		private endpointManagementProcessesService: EndpointManagementProcessesService,
		public i18nService: I18nService,
		route: ActivatedRoute,
		private performanceSccService: PerformanceSccService
	) {
		this.isLicenseExpired = route.snapshot.data['licenseExpired'] || this.appConfigService.isSuspended || this.appConfigService.isDeleted;

		this.onboarding = route.snapshot.url.join('') === 'onboarding';
		this.getProcessLabel = this.getProcessLabel.bind(this);
	}

	getProcessLabel(process: EndpointManagementProcess) {
		return this.i18nService.get('endpointManagement.osTypes.' + process.name + '.title');
	}

	ngAfterViewInit(): void {
		const pageName = this.onboarding ? 'onboarding' : 'offboarding';
		this.performanceSccService.endNgPageLoadPerfSession(`settings-${pageName}`);
	}
}
