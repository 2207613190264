import { Injectable, Injector } from '@angular/core';
import { InvestigationGraphDataModel } from '../models/investigation-graph-data.model';
import {
	AirsEntityTypeResults,
	InvestigatedMachine,
	Investigation,
	InvestigationEntityResultsRelationship,
	InvestigationRemediatedThreatsRelationship,
	RemediatedThreatsStatuses,
	ThreatTypeCount,
} from '@wcd/domain';
import { DataSet, Paris } from '@microsoft/paris';
import { InvestigationChangeEvent } from '../../../@entities/investigations/interfaces/investigation-change-event.interface';
import { PendingActionsService } from '../../../@entities/remediation/services/pending-actions.service';
import { InvestigationsService } from '../../../@entities/investigations/services/investigations.service';

import { isEqual, differenceBy, keyBy } from 'lodash-es';
import { merge } from 'rxjs';

@Injectable()
export class InvestigationGraphService {
	current: InvestigationGraphDataModel;

	constructor(private injector: Injector, private paris: Paris) {}

	getInvestigationGraphData(investigationId: number | string): InvestigationGraphDataModel {
		const investigationsService = this.injector.get(InvestigationsService);
		const investigationGraphData: InvestigationGraphDataModel = new InvestigationGraphDataModel(
			investigationsService.getInvestigationPolling(investigationId)
		);

		merge(
			investigationGraphData.entityCount$,
			investigationGraphData.threatTypes$,
			investigationGraphData.actionCount$
		).subscribe((data: number | Array<ThreatTypeCount>) => {
			if (!data || !investigationGraphData.investigation) return;

			this.paris
				.queryForItem(InvestigationEntityResultsRelationship, investigationGraphData.investigation)
				.subscribe(
					(entityResults: DataSet<AirsEntityTypeResults>) => {
						investigationGraphData.entityResults$.next(entityResults.items);
					},
					error => investigationGraphData.entityResults$.error(error)
				);
		});

		investigationGraphData.alertCount$.subscribe((alertCount: number) => {
			if (!alertCount) return;

			if (investigationGraphData.investigation.alert.detectionSource)
				investigationGraphData.alert$.next(investigationGraphData.investigation.alert);
		});

		investigationGraphData.end$.subscribe((investigationEnded: boolean) => {
			if (!investigationEnded) return;

			this.setRemediationEntities(investigationGraphData);
		});

		return investigationGraphData;
	}

	private setRemediationEntities(investigationGraphData: InvestigationGraphDataModel): void {
		this.paris
			.getRelatedItem(InvestigationRemediatedThreatsRelationship, investigationGraphData.investigation)
			.subscribe((remediatedThreatsStatuses: RemediatedThreatsStatuses) => {
				investigationGraphData.remediationEntities$.next(remediatedThreatsStatuses);
			});
	}

	static getInvestigationDifference(
		current: Investigation,
		other: Investigation
	): InvestigationChangeEvent {
		const differenceSet: InvestigationChangeEvent = {};

		if (current.status !== other.status) differenceSet.status = current.status;

		if (current.requestedStatus !== other.requestedStatus)
			differenceSet.requestedStatus = current.requestedStatus;

		if (!InvestigationGraphService.hostsAreEqual(current, other))
			differenceSet.machines = current.machines;

		if (!isEqual(current.investigatedUsers, other.investigatedUsers))
			differenceSet.investigatedUsers = current.investigatedUsers;

		if (
			!PendingActionsService.comparePendingActionTypes(
				current.pendingActionTypes,
				other.pendingActionTypes
			)
		)
			differenceSet.pendingActionTypes = current.pendingActionTypes;

		if (current.isRemediationDisabled !== other.isRemediationDisabled)
			differenceSet.isRemediationDisabled = current.isRemediationDisabled;

		if (!isEqual(current.threatTypes, other.threatTypes)) differenceSet.threatTypes = current.threatTypes;

		if (
			differenceBy(current.tags, other.tags, 'id').length ||
			differenceBy(other.tags, current.tags, 'id').length
		)
			differenceSet.tags = current.tags;

		if (current.totalAlertsCount !== other.totalAlertsCount)
			differenceSet.alertCount = current.totalAlertsCount;

		if (!isEqual(current.actionsSummary, other.actionsSummary))
			differenceSet.actionCount = current.actionsSummary.total;

		if (current.entityCount !== other.entityCount) differenceSet.entityCount = current.entityCount;

		if (current.pendingType !== other.pendingType) differenceSet.pendingType = current.pendingType;

		if (current.commentCount !== other.commentCount) differenceSet.commentCount = current.commentCount;

		return differenceSet;
	}

	static hostsAreEqual(current: Investigation, otherInvestigation: Investigation): boolean {
		let hostsChanged: boolean =
			!current.machines || otherInvestigation.machines.length !== current.machines.length;

		if (!hostsChanged && current.machines) {
			const machinesIndex: { [id: string]: InvestigatedMachine } = keyBy(current.machines, 'id');

			hostsChanged = otherInvestigation.machines.some((machine: InvestigatedMachine) => {
				const oldMachine: InvestigatedMachine = machinesIndex[machine.id];
				return (
					!oldMachine ||
					machine.status !== oldMachine.status ||
					machine.users.length !== oldMachine.users.length
				);
			});
		}

		return !hostsChanged;
	}
}
