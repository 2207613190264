import { DataQuery, Entity, EntityField, EntityModelBase, HttpOptions, ParisConfig } from '@microsoft/paris';
import { omit } from 'lodash-es';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { CustomTiIndicatorActionsType } from './custom-ti-indicators-actions-type';
import {
	CustomTiIndicatorIdentifierType,
	CustomTiIndicatorIdentifierTypes,
} from './custom-ti-indicators-identifier-type';
import { CustomTiIndicatorSeverityType } from './custom-ti-indicators-severity-type';
import { IndicatorsCreateBySourceType } from './custom-ti-indicators-created-by-source-type';

const filePropertyName: string = 'file';

export enum IndicatorAlertCategory {
	'Collection' = 600000000,
	'CommandAndControl' = 512,
	'CredentialAccess' = 700000000,
	'DefenseEvasion' = 800000000,
	'Discovery' = 900000000,
	'Execution' = 500000000,
	'Exfiltration' = 268435456,
	'Exploit' = 8,
	'InitialAccess' = 400000000,
	'LateralMovement' = 33554432,
	'Malware' = 2,
	'Persistence' = 128,
	'PrivilegeEscalation' = 64,
	'Ransomware' = 2048,
	'SuspiciousActivity' = 1,
	'UnwantedSoftware' = 524288, // not existed category, InE use PUA
}

@Entity({
	singularName: 'Indicator',
	pluralName: 'Indicators',
	endpoint: 'ti/indicators',
	saveMethod: (item: any, config?: ParisConfig) => (item.isNew ? 'POST' : 'PUT'),
	readonly: true,
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	allItemsEndpointTrailingSlash: false,
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

	    const data = {...omit(dataQuery.where as object, ['page', 'page_size', 'pagesize']),
				    PageIndex: dataQuery.page ? dataQuery.page - 1 : 0,
					PageSize: dataQuery.pageSize};

        if(!data.PageSize) {
			delete data.PageSize ;
		}

		return data;
	},
	parseSaveItemsQuery: (items: Array<any>, options?: HttpOptions) => {
		return { options: options, data: items};
	},
	parseSaveQuery: (item: CustomTiIndicator, entity, config, options?: HttpOptions) =>
		`ti/indicator${
			item.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.CertificateThumbprint
				? '/certificate'
				: ''
		}`,
	serializeItem: (item: CustomTiIndicator, modelData) => {
		const apiObj = modelData;

		for (const p in apiObj) {
			if (apiObj[p] === undefined || apiObj[p] === null) delete apiObj[p];
		}

		if (apiObj[filePropertyName]) {
			const formData = new FormData();

			formData.append(filePropertyName, apiObj[filePropertyName]);

			delete apiObj[filePropertyName];

			formData.append('TiIndicator', JSON.stringify(apiObj));

			return formData;
		}

		return apiObj;
	}
})
export class CustomTiIndicator extends EntityModelBase<number> {
	@EntityField({ data: 'IndicatorId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'IndicatorType' })
	tiIndicatorType: CustomTiIndicatorIdentifierType;

	@EntityField({
		data: 'IndicatorValue',
		parse: tiIndicator => (tiIndicator ? tiIndicator.toLowerCase() : null),
	})
	tiIndicator: string;

	@EntityField({ data: 'RbacGroupIds' })
	rbacGroupIds: Array<number>;

	@EntityField({ data: 'CreationTime' })
	creationTime: Date;

	@EntityField({ data: 'CreatedBy' })
	createdBy: string;

	@EntityField({ data: 'LastUpdateTime' })
	lastUpdateTime: Date;

	@EntityField({ data: 'LastUpdatedBy' })
	lastUpdatedBy: string;

	@EntityField({ data: 'IsEnabled' })
	isEnabled: boolean;

	@EntityField({ data: 'ExpirationTime' })
	expirationTime: Date;

	@EntityField({ data: 'Action' })
	action: CustomTiIndicatorActionsType;

	@EntityField({ data: 'IoaDefinitionId' })
	ioaDefinitionId: string;

	@EntityField({ data: 'Severity' })
	severity: CustomTiIndicatorSeverityType;

	@EntityField({ data: 'Title' })
	title: string;

	@EntityField({ data: 'Context' })
	context: string;

	@EntityField({ data: 'Category' })
	category: IndicatorAlertCategory;

	@EntityField({ data: 'Description' })
	description: string;

	@EntityField({ data: 'RecommendedActions' })
	recommendedActions: string;

	@EntityField({ data: [filePropertyName] })
	file: File;

	@EntityField({ data: 'Serial' })
	serial: string;

	@EntityField({ data: 'Issuer' })
	issuer: string;

	@EntityField({ data: 'Subject' })
	subject: string;

	@EntityField({ data: 'ProcessMemoryProcessName' })
	processMemoryProcessName: string;

	@EntityField({ data: 'ProcessMemoryActualSize' })
	processMemoryActualSize: number;

	@EntityField({ data: 'BypassDurationHours' })
	bypassDurationHours?: number;

	@EntityField({ data: 'EducateUrl' })
	educateUrl?: string;

	@EntityField({ data: 'Application' })
	application: string;

	@EntityField({ data: 'Version' })
	version: string;

	@EntityField({ data: 'Publisher' })
	publisher: string;

	@EntityField({ data: 'GenerateAlert' })
	generateAlert: boolean;

	@EntityField({ data: 'HistoricalDetection' })
	historicalDetection: boolean;

	@EntityField({ data: 'LookBackPeriod' })
	lookBackPeriod?: number;

	@EntityField({ data: 'MitreTechniques' })
	mitreTechniques: Array<string>;

	@EntityField({ data: 'CreatedByDisplayName' })
	createdByDisplayName: string;

	@EntityField({ data: 'CreatedBySource' })
	createdBySource: IndicatorsCreateBySourceType;

	get isNew(): boolean {
		return this.id === undefined;
	}

	get isMcasIndicator(): boolean {
		return (
			this.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.DomainName &&
			this.createdBy.toLowerCase() === 'ca7154ab-7eab-4bf1-ba32-f2e119637859' // MCAS
		);
	}

	get originLink(): string {
		return `https://portal.cloudappsecurity.com/#/catalog?text=eq(o:(searchType:i:2,adv:b:true),${
			this.tiIndicator
		})`;
	}

	get IsIpRange(): boolean {
		return (
			this.tiIndicatorType &&
			this.tiIndicator &&
			this.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.IpAddress &&
			this.tiIndicator.includes('/')
		);
	}
}
