import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Exposed Operating Systems Statistics',
	pluralName: 'Exposed Operating Systems Statistics',
	readonly: true,
})
export class ExposedOperatingSystemsStatistics extends ModelBase {
	@EntityField() osName: string;

	@EntityField() exposedAssetsCount: number;
}
