import { ElementRef, EventEmitter, OnDestroy, OnInit, ChangeDetectorRef, QueryList, AfterViewInit, SimpleChanges, OnChanges, } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
var MARGIN = 20;
var FOCUS_CSS_CLASS = 'focused';
export var Positions;
(function (Positions) {
    Positions[Positions["Right"] = 0] = "Right";
    Positions[Positions["Left"] = 1] = "Left";
    Positions[Positions["Default"] = 2] = "Default";
})(Positions || (Positions = {}));
var lastId = 0;
var DropdownComponent = /** @class */ (function () {
    function DropdownComponent(elementRef, changeDetectorRef) {
        var _this = this;
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.buttonId = "dropdown-button-" + lastId++;
        this.buttonClass = '';
        this.iconButtonOnly = false;
        this.disabled = false;
        this.closeOnClick = true; // closeOnClick controls the behavior of the enter key press and should be equal to closeOnMouseUp and they both need to be false in case of sub menu
        this.closeOnMouseUp = true;
        this.showIconDropdown = true;
        this.menuClass = '';
        this.isBordered = true;
        this.isFullWidth = false;
        this.focusButtonOnClose = true;
        this.ariaRole = 'combobox';
        this.ariaHaspopup = null;
        this.ariaOwns = null;
        this.position = Positions.Default;
        this.optionAriaSetSize = null;
        this.optionAriaRole = null;
        this.optionAriaPositionInSet = null;
        this.navigateUsingArrowKeysOnly = false;
        this.navigateUsingTab = false;
        this.allowFocusOnMenu = -1;
        this.focusOnInit = false;
        this.focus = new EventEmitter();
        this.blur = new EventEmitter();
        this.exit = new EventEmitter();
        this.onToggle = new EventEmitter();
        this.setFocus = function () {
            _this.buttonEl.focus();
        };
        this.positions = Positions;
        this.isOpen = false;
        this.isVisible = false;
        this.chevronIconStyle = {
            root: {
                fontWeight: 'bold',
            },
        };
        this.isCombobox = true;
    }
    Object.defineProperty(DropdownComponent.prototype, "menu", {
        set: function (menu) {
            var _this = this;
            this.menuEl = menu.nativeElement;
            if (this.menuEl) {
                this.menuEl.addEventListener('mouseup', function (e) { return _this.onMouseUp(e); });
                /**
                 * Taking the menu out of the component, and into the body, for positioning.
                 */
                document.body.appendChild(this.menuEl);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DropdownComponent.prototype, "dropdownButton", {
        set: function (button) {
            this.buttonEl = button.nativeElement;
        },
        enumerable: true,
        configurable: true
    });
    DropdownComponent.prototype.ngOnInit = function () {
        this.dropdownEl = this.elementRef.nativeElement;
        this.boundMouseDown = this.onMouseDown.bind(this);
        this.boundOnMouseWheel = this.onMouseWheel.bind(this);
        this.isCombobox = this.ariaRole === 'combobox';
    };
    DropdownComponent.prototype.ngOnDestroy = function () {
        if (this.menuEl)
            document.body.removeChild(this.menuEl);
        if (this.keyboardSubscription) {
            this.keyboardSubscription.unsubscribe();
        }
    };
    DropdownComponent.prototype.ngOnChanges = function (changes) {
        if (changes.ariaRole) {
            this.isCombobox = changes.ariaRole.currentValue === 'combobox';
        }
    };
    DropdownComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        if (this.focusOnInit) {
            setTimeout(function () {
                _this.setFocus();
            });
            this.focusOnInit = false;
        }
    };
    DropdownComponent.prototype.open = function () {
        var _this = this;
        setTimeout(function () { return _this.setMenuPositionAndShow(); });
        document.body.addEventListener('mousedown', this.boundMouseDown);
        window.addEventListener('mousewheel', this.boundOnMouseWheel);
        document.body.addEventListener('mousedown', this.boundMouseDown);
        this.keyboardSubscription = fromEvent(document.body, 'keydown')
            .pipe(filter(function (e) { return e.key === 'Enter'; }))
            .subscribe(function (e) {
            if (!_this.isMenuParentOfTarget(e)) {
                _this.close();
            }
        });
        this.isOpen = true;
        this.setFocusedElement(this.dropdownItems.first);
        this.changeDetectorRef.detectChanges();
        this.onToggle.emit(this.isOpen);
    };
    DropdownComponent.prototype.close = function (actionCanceled) {
        var _this = this;
        if (actionCanceled === void 0) { actionCanceled = false; }
        this.isVisible = false;
        setTimeout(function () {
            _this.isOpen = false;
            _this.menuEl.style.removeProperty('height');
            _this.menuEl.style.removeProperty('width');
            if (_this.focusButtonOnClose || actionCanceled) {
                _this.buttonEl.focus();
            }
            _this.changeDetectorRef.detectChanges();
            _this.onToggle.emit(_this.isOpen);
            if (_this.keyboardSubscription) {
                _this.keyboardSubscription.unsubscribe();
            }
        });
        document.body.removeEventListener('mousedown', this.boundMouseDown);
        window.removeEventListener('mousewheel', this.boundOnMouseWheel);
        return true;
    };
    /*
    This function allows the tags-edit component to close the dropdown
    by hitting on escape when search text is empty.
     */
    DropdownComponent.prototype.escape = function () {
        this.close();
    };
    DropdownComponent.prototype.toggle = function () {
        if (this.isOpen)
            this.close();
        else
            this.open();
    };
    DropdownComponent.prototype.onMouseWheel = function (e) {
        if (!e.target.closest('.wcd-dropdown--menu'))
            this.close();
    };
    DropdownComponent.prototype.onMouseDown = function (e) {
        if (!this.isMenuParentOfTarget(e)) {
            this.close();
        }
        return true;
    };
    DropdownComponent.prototype.onMouseUp = function (e) {
        if (!this.closeOnMouseUp) {
            return;
        }
        var el = e.target;
        if (el.nodeName === 'A' ||
            (el.nodeName === 'BUTTON' && !el.classList.contains('dropdown-action-btn')) ||
            this.closeOnClick !== false) {
            if (el.nodeName === 'DIV' && el.classList.contains('wcd-dropdown--menu__open')) {
                return true;
            }
            return this.close();
        }
        return true;
    };
    DropdownComponent.prototype.getFocusedElementRef = function () {
        if (!this.focusedElementRef) {
            this.focusedElementRef = this.dropdownItems && this.dropdownItems.first;
        }
        // this.dropdownItems can be empty, there is no guarantee there is a focusedElement
        return this.focusedElementRef;
    };
    // handle switching search types using up/down keys
    DropdownComponent.prototype.onKeydownUpDown = function (e, isUp) {
        if (isUp === void 0) { isUp = false; }
        this.UpdateFocusedElement(isUp);
        e.preventDefault();
        e.stopPropagation();
        return false;
    };
    DropdownComponent.prototype.UpdateFocusedElement = function (isUp) {
        if (isUp === void 0) { isUp = false; }
        var focusedElementRef = this.getFocusedElementRef();
        if (focusedElementRef && this.isOpen) {
            var itemsArray = !this.fixDropdownItems ? this.dropdownItems.toArray() : this.fixDropdownItems(this.dropdownItems.toArray());
            var index_1 = itemsArray.indexOf(focusedElementRef);
            var lastItemIndex = this.dropdownItems.length - 1;
            if (isUp) {
                index_1 = index_1 - 1 < 0 ? lastItemIndex : index_1 - 1;
            }
            else {
                index_1 = index_1 + 1 > lastItemIndex ? 0 : index_1 + 1;
            }
            this.setFocusedElement(this.dropdownItems.find(function (_elemRef, elemIndex) { return index_1 === elemIndex; }));
        }
    };
    // selecting item using enter key
    DropdownComponent.prototype.onKeydownEnter = function (e) {
        if (!this.isOpen) {
            return true;
        }
        var focusedElementRef = this.getFocusedElementRef();
        if (focusedElementRef) {
            focusedElementRef.nativeElement.click();
        }
        if (this.closeOnClick) {
            return this.close();
        }
        e.preventDefault();
        e.stopPropagation();
        return false;
    };
    // cancel action and close drop-down using escape key
    DropdownComponent.prototype.onKeydownEsc = function (e) {
        if (!this.isOpen) {
            return true;
        }
        this.close(true);
        this.exit.emit();
        e.preventDefault();
        e.stopPropagation();
        return false;
    };
    DropdownComponent.prototype.onKeydownTab = function (e) {
        if (this.navigateUsingArrowKeysOnly) {
            return this.onKeydownEsc(e);
        }
        if (this.navigateUsingTab) {
            var isUp = e.shiftKey;
            this.UpdateFocusedElement(isUp);
            e.preventDefault();
            e.stopPropagation();
        }
    };
    DropdownComponent.prototype.setFocusedElement = function (newElement) {
        if (this.focusHelper) {
            newElement = this.focusHelper(newElement);
        }
        if (!newElement) {
            return;
        }
        if (this.focusedElementRef) {
            this.focusedElementRef.nativeElement.classList.remove(FOCUS_CSS_CLASS);
        }
        this.focusedElementRef = newElement;
        this.focusedElementRef.nativeElement.classList.add(FOCUS_CSS_CLASS);
        this.focusedElementRef.nativeElement.focus();
    };
    DropdownComponent.prototype.setMenuPositionAndShow = function () {
        var buttonRect = this.buttonEl.getBoundingClientRect();
        this.menuEl.style.top = buttonRect.bottom + 'px';
        this.menuEl.style.left = buttonRect.left + 1 + 'px';
        this.menuEl.style.minWidth = buttonRect.width - 1 + 'px';
        var documentWidth = document.documentElement.clientWidth, documentHeight = document.documentElement.clientHeight, maxWidth = documentWidth - MARGIN * 2, maxHeight = documentHeight - MARGIN * 2;
        var menuClientRect = this.menuEl.getBoundingClientRect();
        var recalculateRect, dontSetLeft, dontSetTop;
        if (this.position === Positions.Left) {
            this.menuEl.style.left = Math.max(buttonRect.left - menuClientRect.width - 1, MARGIN) + 'px';
            recalculateRect = true;
        }
        if (this.position === Positions.Right) {
            var rectLeft = buttonRect.left + buttonRect.width + 1;
            if (rectLeft + menuClientRect.width > documentWidth - MARGIN) {
                rectLeft = documentWidth - menuClientRect.width - MARGIN;
            }
            this.menuEl.style.left = rectLeft + 'px';
            recalculateRect = true;
        }
        if (menuClientRect.width > maxWidth) {
            this.menuEl.style.width = maxWidth + 'px';
            this.menuEl.style.left = MARGIN + 'px';
            recalculateRect = true;
            dontSetLeft = true;
        }
        if (menuClientRect.height > maxHeight) {
            this.menuEl.style.height = maxHeight + 'px';
            this.menuEl.style.top = MARGIN + 'px';
            recalculateRect = true;
            dontSetTop = true;
        }
        if (!dontSetLeft || !dontSetTop) {
            if (recalculateRect)
                menuClientRect = this.menuEl.getBoundingClientRect();
            if (!dontSetLeft) {
                var farthestPosition = documentWidth - MARGIN;
                if (menuClientRect.right > farthestPosition && menuClientRect.width < maxWidth) {
                    var leftDelta = menuClientRect.right - farthestPosition;
                    this.menuEl.style.left = buttonRect.left - leftDelta + 'px';
                }
            }
            if (!dontSetTop) {
                var lowestPosition = documentHeight - MARGIN;
                if (menuClientRect.bottom > lowestPosition && menuClientRect.height < maxHeight) {
                    var bottomDelta = menuClientRect.bottom - lowestPosition;
                    this.menuEl.style.top = Math.max(buttonRect.top - bottomDelta, MARGIN) + 'px';
                }
            }
        }
        this.isVisible = true;
        this.changeDetectorRef.detectChanges();
        // if getFocusedElementRef return value this element is a dropdown and focus should be on the first element in the dropdown.
        // if getFocusedElementRef return null this is a hover-card styled element and focus needs to be on the menuEL.
        var focusedElement = this.getFocusedElementRef();
        var nativeFocusedElement = focusedElement ? focusedElement.nativeElement : this.menuEl;
        nativeFocusedElement.focus();
    };
    DropdownComponent.prototype.isMenuParentOfTarget = function (e) {
        var el = e.target;
        do {
            if (el === document.documentElement)
                return false;
            if (el === this.menuEl)
                return true;
        } while ((el = el.parentNode));
        return true;
    };
    return DropdownComponent;
}());
export { DropdownComponent };
