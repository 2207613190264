import { Component } from '@angular/core';
import { ReportModel } from '../../../reports/models/report.model';
import { IpAlertsSummaryWidgetComponent } from './widgets/ip.alerts-summary.widget';
import { IpPrevalenceWidgetComponent } from './widgets/ip.prevalence.widget';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

export const ipReportModel = new ReportModel({
	id: 'ip-overview',
	allowRangeSelect: false,
	allowExport: false,
	enablePadding: false,
	overflow: 'vertical',
	refreshOnQueryParamsChange: false,
	refreshRate: 1440 * 60 * 1000, // 24h - do not refresh
	rows: [
		{
			columns: [
				{ widgets: [IpAlertsSummaryWidgetComponent], weight: 2 },
				{ widgets: [IpPrevalenceWidgetComponent], weight: 1 },
			],
		},
	],
});

@Component({
	selector: 'ip-overview',
	template: `
		<report [report]="report" [showPollingError]="false" [showControls]="false" [removePaddingRight]="true" (onWidgetsLoaded)="onWidgetsLoaded()"> </report>
	`,
})
export class IpOverviewComponent {
	report: ReportModel;

	constructor(private performanceSccService: PerformanceSccService) {
		this.report = ipReportModel;
	}

	onWidgetsLoaded(){
		this.performanceSccService.endNgPageLoadPerfSession('overview');
	}
}
