import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabButtonModule, FabMessageBarModule } from '@angular-react/fabric';

import { SnackbarComponent } from './snackbar.component';
import { SnackbarMountingService } from './snackbar-mounting.service';

@NgModule({
	imports: [CommonModule, FabMessageBarModule, FabButtonModule],
	declarations: [SnackbarComponent],
	providers: [SnackbarMountingService],
	entryComponents: [SnackbarComponent],
})
export class SnackbarModule {
	constructor(mountingService: SnackbarMountingService) {
		if (!mountingService.isMounted) {
			mountingService.mount();
		}
	}
}
