import { Component, Input, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { Tag, ThreatInfo, ThreatDescriptor } from '@wcd/domain';
import { TvmImpactScoreData } from '../../../components/tvm-impact-score/tvm-impact-score.component';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { TvmIconBuilderService, TvmIconsConfig } from '../../../services/tvm-icon-builder.service';
import { FeaturesService, Feature } from '@wcd/config';
export interface SecurityRecommendationCardData {
	recommendationId: string;
	isNew?: boolean;
	title: string;
	exposedMachinesCount: number;
	scoreData: TvmImpactScoreData;
	tags?: Array<Pick<Tag, 'id' | 'name'>>;
	insight: string;
	threatInfo: ThreatInfo;
	threats: ThreatDescriptor[];
	showCard?: boolean;
}

@Component({
	selector: 'tvm-security-recommendation-card',
	templateUrl: './security-recommendation-card.component.html',
	styleUrls: ['./security-recommendation-card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmSecurityRecommendationCardComponent implements OnChanges {
	@Input() cardData: SecurityRecommendationCardData;
	exposureTooltip: string;
	configurationTooltip: string;
	threatIconsComponentConfig: TvmIconsConfig;
	tvmTextsService: TvmTextsService;

	constructor(
		private router: Router,
		private i18nService: I18nService,
		tvmTextsService: TvmTextsService,
		private featuresService: FeaturesService,
		private tvmIconBuilderService: TvmIconBuilderService
	) {
		this.exposureTooltip = tvmTextsService.getText(TextToken.ExposureScoreTooltip);
		this.configurationTooltip = tvmTextsService.getText(TextToken.ConfigurationScoreTooltip);
		this.tvmTextsService = tvmTextsService;
	}

	ngOnChanges() {
		const iconConfigs = this.tvmIconBuilderService.configureIcons(
			this.cardData.threatInfo,
			this.cardData.threats,
			'securityRecommendation',
			true,
			this.cardData.insight,
			true
		);
		this.threatIconsComponentConfig = iconConfigs.config;
	}

	navigateToRecommendations(recommendationId: string): void {
		this.router.navigate(['/security-recommendations', recommendationId], {
			queryParams: {
				filters: 'status=Active',
			},
		});
	}
}
