var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { omit } from 'lodash-es';
import { CustomTiIndicatorActionsType } from './custom-ti-indicators-actions-type';
import { CustomTiIndicatorIdentifierType, CustomTiIndicatorIdentifierTypes, } from './custom-ti-indicators-identifier-type';
import { CustomTiIndicatorSeverityType } from './custom-ti-indicators-severity-type';
import { IndicatorsCreateBySourceType } from './custom-ti-indicators-created-by-source-type';
var filePropertyName = 'file';
export var IndicatorAlertCategory;
(function (IndicatorAlertCategory) {
    IndicatorAlertCategory[IndicatorAlertCategory["Collection"] = 600000000] = "Collection";
    IndicatorAlertCategory[IndicatorAlertCategory["CommandAndControl"] = 512] = "CommandAndControl";
    IndicatorAlertCategory[IndicatorAlertCategory["CredentialAccess"] = 700000000] = "CredentialAccess";
    IndicatorAlertCategory[IndicatorAlertCategory["DefenseEvasion"] = 800000000] = "DefenseEvasion";
    IndicatorAlertCategory[IndicatorAlertCategory["Discovery"] = 900000000] = "Discovery";
    IndicatorAlertCategory[IndicatorAlertCategory["Execution"] = 500000000] = "Execution";
    IndicatorAlertCategory[IndicatorAlertCategory["Exfiltration"] = 268435456] = "Exfiltration";
    IndicatorAlertCategory[IndicatorAlertCategory["Exploit"] = 8] = "Exploit";
    IndicatorAlertCategory[IndicatorAlertCategory["InitialAccess"] = 400000000] = "InitialAccess";
    IndicatorAlertCategory[IndicatorAlertCategory["LateralMovement"] = 33554432] = "LateralMovement";
    IndicatorAlertCategory[IndicatorAlertCategory["Malware"] = 2] = "Malware";
    IndicatorAlertCategory[IndicatorAlertCategory["Persistence"] = 128] = "Persistence";
    IndicatorAlertCategory[IndicatorAlertCategory["PrivilegeEscalation"] = 64] = "PrivilegeEscalation";
    IndicatorAlertCategory[IndicatorAlertCategory["Ransomware"] = 2048] = "Ransomware";
    IndicatorAlertCategory[IndicatorAlertCategory["SuspiciousActivity"] = 1] = "SuspiciousActivity";
    IndicatorAlertCategory[IndicatorAlertCategory["UnwantedSoftware"] = 524288] = "UnwantedSoftware";
})(IndicatorAlertCategory || (IndicatorAlertCategory = {}));
var ɵ0 = function (item, config) { return (item.isNew ? 'POST' : 'PUT'); }, ɵ1 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ2 = function (dataQuery) {
    if (!dataQuery)
        return {};
    var data = __assign({}, omit(dataQuery.where, ['page', 'page_size', 'pagesize']), { PageIndex: dataQuery.page ? dataQuery.page - 1 : 0, PageSize: dataQuery.pageSize });
    if (!data.PageSize) {
        delete data.PageSize;
    }
    return data;
}, ɵ3 = function (items, options) {
    return { options: options, data: items };
}, ɵ4 = function (item, entity, config, options) {
    return "ti/indicator" + (item.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.CertificateThumbprint
        ? '/certificate'
        : '');
}, ɵ5 = function (item, modelData) {
    var apiObj = modelData;
    for (var p in apiObj) {
        if (apiObj[p] === undefined || apiObj[p] === null)
            delete apiObj[p];
    }
    if (apiObj[filePropertyName]) {
        var formData = new FormData();
        formData.append(filePropertyName, apiObj[filePropertyName]);
        delete apiObj[filePropertyName];
        formData.append('TiIndicator', JSON.stringify(apiObj));
        return formData;
    }
    return apiObj;
}, ɵ6 = function (tiIndicator) { return (tiIndicator ? tiIndicator.toLowerCase() : null); }, ɵ7 = [filePropertyName];
var CustomTiIndicator = /** @class */ (function (_super) {
    __extends(CustomTiIndicator, _super);
    function CustomTiIndicator() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(CustomTiIndicator.prototype, "isNew", {
        get: function () {
            return this.id === undefined;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicator.prototype, "isMcasIndicator", {
        get: function () {
            return (this.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.DomainName &&
                this.createdBy.toLowerCase() === 'ca7154ab-7eab-4bf1-ba32-f2e119637859' // MCAS
            );
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicator.prototype, "originLink", {
        get: function () {
            return "https://portal.cloudappsecurity.com/#/catalog?text=eq(o:(searchType:i:2,adv:b:true)," + this.tiIndicator + ")";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicator.prototype, "IsIpRange", {
        get: function () {
            return (this.tiIndicatorType &&
                this.tiIndicator &&
                this.tiIndicatorType.id === CustomTiIndicatorIdentifierTypes.IpAddress &&
                this.tiIndicator.includes('/'));
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'IndicatorId' }),
        __metadata("design:type", Number)
    ], CustomTiIndicator.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'IndicatorType' }),
        __metadata("design:type", CustomTiIndicatorIdentifierType)
    ], CustomTiIndicator.prototype, "tiIndicatorType", void 0);
    __decorate([
        EntityField({
            data: 'IndicatorValue',
            parse: ɵ6,
        }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "tiIndicator", void 0);
    __decorate([
        EntityField({ data: 'RbacGroupIds' }),
        __metadata("design:type", Array)
    ], CustomTiIndicator.prototype, "rbacGroupIds", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], CustomTiIndicator.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'LastUpdateTime' }),
        __metadata("design:type", Date)
    ], CustomTiIndicator.prototype, "lastUpdateTime", void 0);
    __decorate([
        EntityField({ data: 'LastUpdatedBy' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "lastUpdatedBy", void 0);
    __decorate([
        EntityField({ data: 'IsEnabled' }),
        __metadata("design:type", Boolean)
    ], CustomTiIndicator.prototype, "isEnabled", void 0);
    __decorate([
        EntityField({ data: 'ExpirationTime' }),
        __metadata("design:type", Date)
    ], CustomTiIndicator.prototype, "expirationTime", void 0);
    __decorate([
        EntityField({ data: 'Action' }),
        __metadata("design:type", CustomTiIndicatorActionsType)
    ], CustomTiIndicator.prototype, "action", void 0);
    __decorate([
        EntityField({ data: 'IoaDefinitionId' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "ioaDefinitionId", void 0);
    __decorate([
        EntityField({ data: 'Severity' }),
        __metadata("design:type", CustomTiIndicatorSeverityType)
    ], CustomTiIndicator.prototype, "severity", void 0);
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "title", void 0);
    __decorate([
        EntityField({ data: 'Context' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "context", void 0);
    __decorate([
        EntityField({ data: 'Category' }),
        __metadata("design:type", Number)
    ], CustomTiIndicator.prototype, "category", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'RecommendedActions' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "recommendedActions", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", File)
    ], CustomTiIndicator.prototype, "file", void 0);
    __decorate([
        EntityField({ data: 'Serial' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "serial", void 0);
    __decorate([
        EntityField({ data: 'Issuer' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "issuer", void 0);
    __decorate([
        EntityField({ data: 'Subject' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "subject", void 0);
    __decorate([
        EntityField({ data: 'ProcessMemoryProcessName' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "processMemoryProcessName", void 0);
    __decorate([
        EntityField({ data: 'ProcessMemoryActualSize' }),
        __metadata("design:type", Number)
    ], CustomTiIndicator.prototype, "processMemoryActualSize", void 0);
    __decorate([
        EntityField({ data: 'BypassDurationHours' }),
        __metadata("design:type", Number)
    ], CustomTiIndicator.prototype, "bypassDurationHours", void 0);
    __decorate([
        EntityField({ data: 'EducateUrl' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "educateUrl", void 0);
    __decorate([
        EntityField({ data: 'Application' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "application", void 0);
    __decorate([
        EntityField({ data: 'Version' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "version", void 0);
    __decorate([
        EntityField({ data: 'Publisher' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "publisher", void 0);
    __decorate([
        EntityField({ data: 'GenerateAlert' }),
        __metadata("design:type", Boolean)
    ], CustomTiIndicator.prototype, "generateAlert", void 0);
    __decorate([
        EntityField({ data: 'HistoricalDetection' }),
        __metadata("design:type", Boolean)
    ], CustomTiIndicator.prototype, "historicalDetection", void 0);
    __decorate([
        EntityField({ data: 'LookBackPeriod' }),
        __metadata("design:type", Number)
    ], CustomTiIndicator.prototype, "lookBackPeriod", void 0);
    __decorate([
        EntityField({ data: 'MitreTechniques' }),
        __metadata("design:type", Array)
    ], CustomTiIndicator.prototype, "mitreTechniques", void 0);
    __decorate([
        EntityField({ data: 'CreatedByDisplayName' }),
        __metadata("design:type", String)
    ], CustomTiIndicator.prototype, "createdByDisplayName", void 0);
    __decorate([
        EntityField({ data: 'CreatedBySource' }),
        __metadata("design:type", IndicatorsCreateBySourceType)
    ], CustomTiIndicator.prototype, "createdBySource", void 0);
    CustomTiIndicator = __decorate([
        Entity({
            singularName: 'Indicator',
            pluralName: 'Indicators',
            endpoint: 'ti/indicators',
            saveMethod: ɵ0,
            readonly: true,
            separateArrayParams: true,
            baseUrl: ɵ1,
            allItemsEndpointTrailingSlash: false,
            parseDataQuery: ɵ2,
            parseSaveItemsQuery: ɵ3,
            parseSaveQuery: ɵ4,
            serializeItem: ɵ5
        })
    ], CustomTiIndicator);
    return CustomTiIndicator;
}(EntityModelBase));
export { CustomTiIndicator };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
