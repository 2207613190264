var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy, OnInit, QueryList, } from '@angular/core';
import { fromEvent } from 'rxjs';
import { DropdownComponent } from './dropdown.component';
import { debounceTime } from 'rxjs/operators';
var MARGIN = 50;
var NavDropdownComponent = /** @class */ (function (_super) {
    __extends(NavDropdownComponent, _super);
    function NavDropdownComponent(elementRef, changeDetectorRef) {
        var _this = _super.call(this, elementRef, changeDetectorRef) || this;
        _this.changeDetectorRef = changeDetectorRef;
        return _this;
    }
    Object.defineProperty(NavDropdownComponent.prototype, "newDropdownItems", {
        set: function (dropdown) {
            this.dropdownItems.reset(dropdown.filter(function (item) {
                return item.nativeElement.classList.contains('dropdownNav');
            }));
        },
        enumerable: true,
        configurable: true
    });
    NavDropdownComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.onToggle.subscribe(function (isOpen) {
            if (isOpen && _this.focusedElementRef) {
                var focusedElement = _this.dropdownItems.find(function (item) {
                    return Boolean(JSON.parse(item.nativeElement.getAttribute('aria-selected')));
                });
                _this.setFocusedElement(focusedElement);
                setTimeout(function () { return _this.focusedElementRef.nativeElement.scrollIntoView(); });
            }
        });
    };
    NavDropdownComponent.prototype.ngAfterViewInit = function () {
        _super.prototype.ngAfterViewInit.call(this);
        this.initOnResizeSubscription();
    };
    NavDropdownComponent.prototype.ngOnDestroy = function () {
        _super.prototype.ngOnDestroy.call(this);
        this._onResizeSubscription.unsubscribe();
    };
    NavDropdownComponent.prototype.initOnResizeSubscription = function () {
        var _this = this;
        this._onResizeSubscription = fromEvent(window, 'resize')
            .pipe(debounceTime(500))
            .subscribe(function () {
            _this.setMenuPositionAndShow();
        });
    };
    NavDropdownComponent.prototype.setMenuPositionAndShow = function () {
        var buttonRect = this.buttonEl.getBoundingClientRect();
        this.menuEl.style.top = buttonRect.bottom + 'px';
        this.menuEl.style.left = buttonRect.left + 'px';
        this.menuEl.style.minWidth = buttonRect.width - 1 + 'px';
        this.menuEl.style.height = this.setHeightAuto ? 'auto' : buttonRect.height * 5 + 'px';
        var documentHeight = document.documentElement.clientHeight;
        var maxHeight = documentHeight - MARGIN;
        var menuClientRect = this.menuEl.getBoundingClientRect();
        if (menuClientRect.height > maxHeight - buttonRect.bottom) {
            var newHeight = maxHeight - buttonRect.bottom;
            var minHeight = this.buttonEl.offsetHeight;
            this.menuEl.style.height = newHeight + 'px';
            if (newHeight < minHeight) {
                this.menuEl.style.height = maxHeight + 'px';
                this.menuEl.style.top = MARGIN + 'px';
                this.menuEl.style.minWidth = buttonRect.width + 1 + 'px';
            }
        }
        this.isVisible = true;
        this.changeDetectorRef.detectChanges();
        // if getFocusedElementRef return value this element is a dropdown and focus should be on the first element in the dropdown.
        // if getFocusedElementRef return null this is a hover-card styled element and focus needs to be on the menuEL.
        var focusedElement = this.getFocusedElementRef();
        var nativeFocusedElement = focusedElement ? focusedElement.nativeElement : this.menuEl;
        nativeFocusedElement.focus();
    };
    return NavDropdownComponent;
}(DropdownComponent));
export { NavDropdownComponent };
