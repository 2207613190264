import { NgModule } from '@angular/core';
import { SharedModule } from '../../../shared/shared.module';
import { ActiveAlertsWidget } from './security-operations/components/active-alerts.widget';
import { ActiveAutomatedInvestigationsWidget } from './security-operations/components/active-automated-investigations.widget';
import { AutomatedInvestigationsStatisticsWidget } from './security-operations/components/automated-investigations-statistics.widget';
import { SuspiciousActivitiesWidget } from './security-operations/components/suspicious-activities.widget';
import { MachinesAtRiskWidget } from './security-operations/components/machines-at-risk.widget';
import { UsersAtRiskWidget } from './security-operations/components/users-at-risk.widget';
import { DailyMachineReportingWidget } from './security-operations/components/daily-machine-reporting.widget';
import { SensorHealthWidget } from './security-operations/components/sensor-health.widget';
import { AlertStatusesService } from '../../../@entities/alerts/services/alert-statuses.service';
import { OnboardingWidget } from './security-operations/components/onboarding.widget';
import { PromotionBannerWidget } from './security-operations/components/promotion-banner.widget';
import { ConfigModule } from '@wcd/config';
import { DataTableModule } from '@wcd/datatable';
import { ActiveIncidentsWidgetComponent } from './security-operations/components/active-incidents.widget';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { AlertsByDetectionSourceWidget } from './security-operations/components/alerts-by-detection-source.widget';
import { ExperienceMtpWidgetComponent } from './security-operations/components/experience-mtp.widget/experience-mtp.widget.component';
import { ConvergencePromotionModule } from '../../../convergence-promotion/convergence-promotion.module';

import {
	PieChartModule,
	LegendModule,
	ButtonsBarButtonModule,
	TimeSeriesChartModule,
	BarsChartModule,
} from '@wcd/charts';
import { MtpPromotionModule } from '../../../mtp-promotion/mtp-promotion.module';
import {ActiveIncidentsTrendsComponent} from './security-operations/components/active-incidents-trends.component';

export const sccDisabledWidgets = [
	ExperienceMtpWidgetComponent,
	ActiveAutomatedInvestigationsWidget,
	AutomatedInvestigationsStatisticsWidget,
];

const widgets = [
	ActiveAlertsWidget,
	ActiveAutomatedInvestigationsWidget,
	AutomatedInvestigationsStatisticsWidget,
	SuspiciousActivitiesWidget,
	MachinesAtRiskWidget,
	UsersAtRiskWidget,
	DailyMachineReportingWidget,
	SensorHealthWidget,
	OnboardingWidget,
	PromotionBannerWidget,
	ActiveIncidentsWidgetComponent,
	AlertsByDetectionSourceWidget,
	ExperienceMtpWidgetComponent,
	ActiveIncidentsTrendsComponent,
];

@NgModule({
	imports: [
		SharedModule,
		ConfigModule,
		DataTableModule,
		DataViewsModule,
		PieChartModule,
		LegendModule,
		ButtonsBarButtonModule,
		TimeSeriesChartModule,
		BarsChartModule,
		MtpPromotionModule,
		ConvergencePromotionModule
	],
	declarations: [widgets],
	entryComponents: [widgets],
	exports: [AlertsByDetectionSourceWidget, ActiveIncidentsTrendsComponent],
	providers: [AlertStatusesService],
})
export class DashboardsReportModule {}
