<wcd-panel [settings]="panelSettings" (close)="onClosePressed($event)">
	<div class="wizard-panel" data-track-component="WizardPanel">

		<header
			class="wizard-panel-header wcd-border-bottom wcd-padding-large-horizontal wcd-padding-mediumSmall-vertical">
			<span class="wcd-flex-1">{{ wizardTitle }}</span>
			<button
				class="wcd-padding-none-all btn-close"
				(click)="onClosePressed($event)"
				[attr.aria-label]="'buttons.close' | i18n"
				[wcdTooltip]="'buttons.close' | i18n"
				[focusOn]="true">
				<wcd-shared-icon [iconName]="'clear'" [iconClass]="'wcd-font-size-base'"></wcd-shared-icon>
			</button>
		</header>

		<div class="wizard-panel-container wcd-full-height">
			<div class="steps wcd-flex-0 wcd-border-right wcd-scroll-vertical">
				<wizard
					[currentStepId]="currentStep.id"
					[stepsData]="stepsData"
					[showProgressIcons]="true"
					[isPanel]="true"
					[isCompleted]="isCompleted"
					containerClassName="wcd-padding-top wcd-padding-large-horizontal"
				>
				</wizard>
			</div>

			<div class="wizard-panel-step">
				<div class="wizard-panel-content wcd-padding-large-vertical wcd-padding-large-horizontal">
					<ng-content></ng-content>
				</div>
				<footer class="wizard-panel-footer wcd-justify-self-end wcd-border-top">
					<div class="wcd-flex-1 wcd-margin-small-right">
						<fab-default-button
							className="btn-secondary"
							*ngIf="!isCompleted && currentStep.id !== stepsData[0].id"
							[disabled]="isLoading"
							(onClick)="onBackPressed()"
						>
							{{ 'buttons.back' | i18n}}
						</fab-default-button>
						<fab-primary-button
							*ngIf="currentStep.id !== stepsData[stepsData.length - 1].id && !currentStep.actionButtonSettings"
							[disabled]="!isNextEnabled || isLoading"
							(onClick)="onNextPressed()"
						>
							{{ 'buttons.next' | i18n}}
						</fab-primary-button>
					</div>

					<div class="wcd-flex-none">
						<fab-primary-button
							*ngIf="currentStep.actionButtonSettings"
							data-track-id="Save"
							data-track-type="Button"
							[disabled]="isLoading"
							(onClick)="currentStep.actionButtonSettings.onActionButtonClick()"
						>
							<i class="loader-icon wcd-margin-small-right" [hidden]="!isLoading"></i>
							{{ currentStep.actionButtonSettings.label || ((isLoading ? 'buttons.saving' : 'buttons.save') | i18n) }}
						</fab-primary-button>
						<fab-default-button
							className="btn-secondary"
							data-track-id="Cancel"
							data-track-type="Button"
							(onClick)="onClosePressed($event)"
						>
							{{ (isCompleted ? 'buttons.close' : 'buttons.cancel') | i18n }}
						</fab-default-button>
					</div>
				</footer>
			</div>
		</div>
	</div>
</wcd-panel>
