import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LogonType } from '../../user/logon-type.enum';

@ValueObject({
	singularName: 'User network information',
	pluralName: '',
	readonly: true,
})
export class LegacyUserNetworkInformation extends ModelBase {
	@EntityField({ data: 'IsDomainAdmin' })
	readonly isDomainAdmin: boolean;

	@EntityField({ data: 'IsLocalAdmin' })
	readonly isLocalAdmin: boolean;

	@EntityField({
		data: 'LogonTypes',
		parse: (data: string) => data.split(',').map(value => value.trim()),
	})
	readonly logonTypes: Array<LogonType>;

	get roles(): string[] {
		return ['isLocalAdmin', 'isDomainAdmin'].filter(role => this[role]);
	}
}
