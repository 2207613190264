var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { NextButtonModeEnum, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { AuthenticatedScanResult, PostAuthenticatedScanReachabilityTest, GetAuthenticatedScanReachabilityTestOutput, AuthenticatedScanSessionRelationship, AuthenticatedScanSession, ResolveEntityURL, TvmEndPoint, AuthenticatedScanSessionCsvRelationship, AssessmentType, } from '@wcd/domain';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { interval, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { AuthenticatedScanResultFieldsService } from '../../services/authenticated-scan-result.fields.service';
import { ScanButtonState } from './scan-button-state.enum';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmDownloadService } from '../../../../../tvm/services/tvm-download.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var AssessmentJobScanStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobScanStepComponent, _super);
    function AssessmentJobScanStepComponent(i18n, paris, dialogsService, changeDetection, assessmentJobService, globalEntityTypesService, fieldsService, tvmDownloadService, liveAnnouncer) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.paris = paris;
        _this.dialogsService = dialogsService;
        _this.changeDetection = changeDetection;
        _this.assessmentJobService = assessmentJobService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.fieldsService = fieldsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.getAuthenticatedScanReachabilityTestOutputInterval = 1500;
        _this.scanProgressMaxWaitingPercentage = 15;
        _this.scanProgressIncPercentage = 0.3;
        _this.MessageBarType = MessageBarType;
        _this.ScanButtonState = ScanButtonState;
        _this.scanButtonState = ScanButtonState.Start;
        _this.SpinnerSize = SpinnerSize;
        _this.isWithProgressBar = true;
        _this.fields = function () {
            return _this.data.assessmentJob.scanType === AssessmentType.WindowsAssessment
                ? _this.fieldsService.windowsScanResultFields
                : _this.fieldsService.networkScanResultFields;
        };
        _this.onNext = function () {
            // User will not select ips as test scan won't run
            if (!_this.isTestScanAllowed) {
                _this.data.assessmentJob.target = _this.data.assessmentJob.originalTargetRanges;
                return of(true);
            }
            if (!_this.isSkipped) {
                return of(true);
            }
            _this.dialogsService
                .confirm({
                title: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_title'),
                text: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_text'),
                confirmText: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_confirm_text'),
            })
                .then(function (e) {
                if (e.confirmed) {
                    // User will not select ips as test scan won't run
                    _this.data.assessmentJob.target = _this.data.assessmentJob.originalTargetRanges;
                    _this.goToStep(_this.reviewStep);
                }
            });
            return of(false);
        };
        _this.reviewStep = _this.assessmentJobService.isWindowsScanFeatureEnabled() ? 3 : 2;
        _this.entityType = _this.globalEntityTypesService.getEntityType(AuthenticatedScanResult);
        _this.repository = _this.paris.getRelationshipRepository(AuthenticatedScanSessionRelationship);
        _this.dataViewOptions = __assign({}, _this.entityType.dataViewOptions);
        return _this;
    }
    AssessmentJobScanStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setIsTestAllowed();
        this.setOnNext(this.onNext);
        this.shouldSelectIpAddress = function (authenticatedScanResult) {
            if (_this.data.selectedIps.length === 0 && authenticatedScanResult.scanSuccess) {
                return true;
            }
            return _this.data.selectedIps.includes(authenticatedScanResult.ip);
        };
        this.setNextMode();
    };
    AssessmentJobScanStepComponent.prototype.setIsTestAllowed = function () {
        this.isTestScanAllowed =
            this.assessmentJobService.countIpAddresses(this.data.assessmentJob.originalTargetRanges) <
                this.assessmentJobService.maxAllowedIpAddressesForScan + 1;
    };
    AssessmentJobScanStepComponent.prototype.ngOnDestroy = function () {
        this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
    };
    AssessmentJobScanStepComponent.prototype.testConnection = function () {
        return __awaiter(this, void 0, void 0, function () {
            var scan;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.data.canShowScans = false;
                        this.data.requiredNewScan = true;
                        this.resetProgressBar();
                        this.setNextMode();
                        this.scanButtonState = ScanButtonState.Stop;
                        this.data.startScanTime = new Date().toLocaleString();
                        return [4 /*yield*/, this.AuthenticatedScanObjectPreProcessingForTestRequest()];
                    case 1:
                        scan = _a.sent();
                        this.paris.apiCall(PostAuthenticatedScanReachabilityTest, scan).subscribe(function (sessionId) {
                            _this.authenticatedScanSession = new AuthenticatedScanSession();
                            _this.authenticatedScanSession.id = sessionId;
                            _this.testConnectionOutput(sessionId);
                        }, function (error) {
                            _this.changeDetection.markForCheck();
                            _this.dialogsService.showError({
                                title: _this.i18n.get('error.status.code', { status: error.status }),
                                data: _this.i18n.get('tvm.authenticatedScan.creationPanel.testRunError'),
                            });
                            _this.finishTestConnection();
                            _this.scanButtonState = ScanButtonState.Start;
                        });
                        this.changeDetection.detectChanges();
                        return [2 /*return*/];
                }
            });
        });
    };
    AssessmentJobScanStepComponent.prototype.AuthenticatedScanObjectPreProcessingForTestRequest = function () {
        return __awaiter(this, void 0, void 0, function () {
            var scan;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.assessmentJobService.preProcessAuthenticatedScanBeforeSendingToBackend(this.data.assessmentJob)];
                    case 1:
                        scan = _a.sent();
                        // For test scan we sent all the ranges in target
                        scan.target = scan.originalTargetRanges;
                        return [2 /*return*/, scan];
                }
            });
        });
    };
    AssessmentJobScanStepComponent.prototype.testConnectionOutput = function (sessionId) {
        var _this = this;
        this.changeDetection.markForCheck();
        this._testConnectionSubscription = interval(this.getAuthenticatedScanReachabilityTestOutputInterval)
            .pipe(flatMap(function () { return _this.paris.apiCall(GetAuthenticatedScanReachabilityTestOutput, sessionId); }))
            .subscribe(function (response) {
            if (response.endOfFile) {
                _this.scanButtonState = ScanButtonState.Restart;
                _this.repository.sourceItem = _this.authenticatedScanSession;
                _this.data.requiredNewScan = false;
                _this.data.canShowScans = true;
                _this.setNextMode();
                _this.setStepValidation(_this.data.selectedIps.length > 0 &&
                    _this.data.selectedIps.join(',').indexOf('/') < 0);
                _this.setDataViewConfig();
                _this.finishTestConnection();
            }
            else if (response.output.length > 0) {
                //if we are getting an output
                _this.refreshProgressBar(response);
            }
            else if (_this.scanProgressPercentage < _this.scanProgressMaxWaitingPercentage) {
                //while waiting for the agent to start scanning (can take around 40 seconds)
                _this.scanProgressPercentage += _this.scanProgressIncPercentage;
                _this.changeDetection.markForCheck();
            }
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18n.get('error.status.code', { status: error.status }),
                data: _this.i18n.get('tvm.authenticatedScan.creationPanel.testOutputError'),
            });
            _this.scanButtonState = ScanButtonState.Start;
            _this.finishTestConnection();
        });
        //give up after 15 minutes
        setTimeout(function () {
            _this.scanButtonState = ScanButtonState.Start;
            _this.finishTestConnection();
        }, 900000);
    };
    AssessmentJobScanStepComponent.prototype.finishTestConnection = function () {
        this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
        this.changeDetection.detectChanges();
    };
    AssessmentJobScanStepComponent.prototype.onStopClick = function () {
        this.scanButtonState = ScanButtonState.Start;
        this.finishTestConnection();
    };
    AssessmentJobScanStepComponent.prototype.onItemSelect = function ($event) {
        this.data.selectedIps = $event.items.map(function (item) { return item.ip; });
        this.data.assessmentJob.target = this.data.selectedIps.join(', ');
        // Prevent next on empty selection
        this.setStepValidation(this.data.selectedIps.length > 0);
    };
    AssessmentJobScanStepComponent.prototype.setDataViewConfig = function () {
        var _this = this;
        this.dataViewConfig = {
            showModalOnExport: false,
            exportResults: function (options, format, dataQuery) {
                var url = ResolveEntityURL({
                    endPoint: TvmEndPoint.AuthenticatedScans,
                    entityModelBaseOrRelationship: AuthenticatedScanSessionCsvRelationship,
                    id: _this.authenticatedScanSession.id,
                });
                return _this.tvmDownloadService.downloadCsv({
                    url: url,
                    fileName: 'export-device-assessment-job',
                    dataQuery: dataQuery,
                    alreadyHasQueryParamInUrl: true,
                });
            },
        };
    };
    AssessmentJobScanStepComponent.prototype.setNextMode = function () {
        if (!this.isTestScanAllowed) {
            this.setNextButtonMode(NextButtonModeEnum.Normal);
            this.setStepValidation(true);
            this.isSkipped = false;
        }
        else if (this.data.requiredNewScan) {
            this.setNextButtonMode(NextButtonModeEnum.Skip, 'tvm_authenticatedScan_wizard_scan_step_skip_button');
            this.setStepValidation(true);
            this.isSkipped = true;
        }
        else {
            this.setNextButtonMode(NextButtonModeEnum.Normal);
            this.setStepValidation(this.data.selectedIps.length > 0);
            this.isSkipped = false;
        }
    };
    AssessmentJobScanStepComponent.prototype.resetProgressBar = function () {
        this.sampledScanIPSuccess = '';
        this.sampledScanIPFailure = '';
        this.showedSampledScans = new Map();
        this.scanProgressPercentage = 0;
        this.progressBarLabel = this.i18n.get('tvm_authenticatedScan_wizard_scan_step_progress_bar_loading_text');
        this.announceAction('tvm_authenticatedScan_wizard_scan_step_progress_bar_loading_text');
    };
    AssessmentJobScanStepComponent.prototype.refreshProgressBar = function (response) {
        if (response.totalIpAddresses !== 0) {
            this.progressBarLabel = this.i18n.get('tvm_authenticatedScan_wizard_scan_step_progress_bar_scan_text');
            this.scanProgressPercentage = Math.max((response.scannedIpAddresses * 100) / response.totalIpAddresses, this.scanProgressMaxWaitingPercentage);
            this.announceAction('tvm_authenticatedScan_wizard_scan_step_progress_bar_scan_text');
            var failureTemp = '';
            var i = 0;
            //search successful scan
            while (i < response.output.length) {
                var scan = response.output[i];
                if (scan.scanSuccess && !this.showedSampledScans[scan.ip]) {
                    this.isSampledScanSuccess = true;
                    this.sampledScanIPSuccess = scan.ip;
                    this.showedSampledScans[scan.ip] = true;
                    break;
                }
                else if (failureTemp === '' && !this.showedSampledScans[scan.ip]) {
                    failureTemp = scan.ip;
                }
                i++;
            }
            //no successful scan was found
            if (i === response.output.length) {
                this.isSampledScanSuccess = false;
                this.sampledScanIPFailure = failureTemp;
                this.showedSampledScans[this.sampledScanIPFailure] = true;
            }
        }
        else {
            this.isWithProgressBar = false;
        }
        this.changeDetection.markForCheck();
    };
    AssessmentJobScanStepComponent.prototype.announceAction = function (key) {
        this.liveAnnouncer.announce(this.i18n.get(key), 'assertive', 300);
    };
    return AssessmentJobScanStepComponent;
}(WizardBaseStep));
export { AssessmentJobScanStepComponent };
