import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../../status.model';
import { alertStatusValues } from './alert-status.entity.values';
import { AlertStatusId, AlertStatusType, IAlertStatus } from './alert-status.models';

@Entity({
	singularName: 'Alert Status',
	pluralName: 'Alert Status',
	values: alertStatusValues,
})
export class AlertStatus extends StatusModel<AlertStatusId> implements IAlertStatus {
	@EntityField({ defaultValue: false })
	isUnmapped: boolean;

	@EntityField({ defaultValue: false })
	isUnknown: boolean;

	@EntityField({ defaultValue: false })
	isHidden: boolean;

	@EntityField({ defaultValue: false })
	isSelectable: boolean;

	@EntityField({ defaultValue: false })
	allowClassification: boolean;

	@EntityField() title: string;

	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() type: AlertStatusType;
}
