import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('tagsCloud', timeagoDirective);

function timeagoDirective() {
	return {
		restrict: 'EA',
		scope: {
			tags: '=',
			canRemoveTags: '=?',
			onTagRemoved: '&',
		},
		controllerAs: 'tvm',
		bindToController: true,
		template: `
		<div class="tags-cloud">
        <div class="tags-cloud-tag-container" ng-repeat="tag in tvm.tags">
            <div class="tags-cloud-tag">
                <span class="tags-cloud-tag-key bolder" ng-if="tag.key">{{tag.key}}:</span>
                <span class="tags-cloud-tag-value" ng-if="tag.value">{{tag.value}}</span>
                <i class="icon icon-Cancel" ng-if="tvm.canRemoveTags !== false" ng-click="tvm.removeTag(tag)" />
            </div>
            <span ng-if="!$last" class="tags-cloud-operator">and</span>
        </div>
    </div>
		`,
		controller: [
			function() {
				var vm = this;
				vm.removeTag = function(tag) {
					var index = vm.tags.indexOf(tag);
					if (index > -1) {
						vm.tags.splice(index, 1);
					}

					if (vm.onTagRemoved) {
						vm.onTagRemoved()(tag);
					}
				};
			},
		],
	};
}
