/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "@angular/forms";
import * as i4 from "./simulator-agent-form-content.component.ngfactory";
import * as i5 from "./simulator-agent-form-content.component";
import * as i6 from "../../services/evaluation.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i11 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i12 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i13 from "./edit-simulator-agents-panel.component";
import * as i14 from "@angular/router";
import * as i15 from "../../../../../../../projects/auth/src/lib/services/auth.service";
var styles_EditSimulatorAgentsPanelComponent = [];
var RenderType_EditSimulatorAgentsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EditSimulatorAgentsPanelComponent, data: {} });
export { RenderType_EditSimulatorAgentsPanelComponent as RenderType_EditSimulatorAgentsPanelComponent };
function View_EditSimulatorAgentsPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "fab-spinner", [["contentClass", "wcd-margin-xLarge-top"]], null, null, null, i1.View_FabSpinnerComponent_0, i1.RenderType_FabSpinnerComponent)), i0.ɵdid(1, 5816320, null, 0, i2.FabSpinnerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"] }, null)], function (_ck, _v) { var currVal_0 = "wcd-margin-xLarge-top"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EditSimulatorAgentsPanelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "fab-message-bar", [["contentClass", "wcd-margin-xLarge-top"]], null, null, null, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(1, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { contentClass: [0, "contentClass"], messageBarType: [1, "messageBarType"] }, null), (_l()(), i0.ɵted(2, 0, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), i0.ɵppd(3, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-margin-xLarge-top"; var currVal_1 = _co.MessageBarType.error; _ck(_v, 1, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent.parent, 0), "evaluation.lab.setupPanel.summaryStep.simulatorError")); _ck(_v, 2, 0, currVal_2); }); }
function View_EditSimulatorAgentsPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 30, "div", [["class", "wcd-flex-vertical wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 19, "div", [["class", "wcd-flex-1 wcd-padding-large-horizontal wcd-margin-small-vertical wcd-scroll-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 16, "form", [["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i0.ɵnov(_v, 6).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i0.ɵnov(_v, 6).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵdid(5, 16384, null, 0, i3.ɵangular_packages_forms_forms_z, [], null, null), i0.ɵdid(6, 4210688, [["agentsForm", 4]], 0, i3.NgForm, [[8, null], [8, null]], null, null), i0.ɵprd(2048, null, i3.ControlContainer, null, [i3.NgForm]), i0.ɵdid(8, 16384, null, 0, i3.NgControlStatusGroup, [[4, i3.ControlContainer]], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 2, "simulator-agent-form-content", [], null, [[null, "onProviderToggled"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onProviderToggled" === en)) {
        var pd_0 = (_co.onProviderToggled() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_SimulatorAgentFormContentComponent_0, i4.RenderType_SimulatorAgentFormContentComponent)), i0.ɵprd(14336, null, i3.ControlContainer, null, [i3.NgForm]), i0.ɵdid(12, 114688, null, 0, i5.SimulatorAgentFormContentComponent, [i6.EvaluationService, i0.ComponentFactoryResolver, i0.ChangeDetectorRef], { agentsConfig: [0, "agentsConfig"] }, { onProviderToggled: "onProviderToggled" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EditSimulatorAgentsPanelComponent_2)), i0.ɵdid(15, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_EditSimulatorAgentsPanelComponent_3)), i0.ɵdid(18, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i7.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(23, 0, null, null, 6, "div", [["class", "wcd-flex-justify-end wcd-flex-none wcd-padding-horizontal wcd-padding-top wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(25, 0, null, null, 3, "fab-primary-button", [["contentClass", "wcd-margin-small-horizontal"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.onUpdate() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabPrimaryButtonComponent_0, i1.RenderType_FabPrimaryButtonComponent)), i0.ɵdid(26, 6012928, null, 1, i2.FabPrimaryButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { contentClass: [0, "contentClass"], disabled: [1, "disabled"], text: [2, "text"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 2, { menuItemsDirectives: 1 }), i0.ɵppd(28, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.agentsConfig; _ck(_v, 12, 0, currVal_7); var currVal_8 = _co.saving; _ck(_v, 15, 0, currVal_8); var currVal_9 = (i0.ɵunv(_v, 18, 0, i0.ɵnov(_v, 19).transform(_co.error$)) && !_co.saving); _ck(_v, 18, 0, currVal_9); var currVal_10 = "wcd-margin-small-horizontal"; var currVal_11 = ((!i0.ɵnov(_v, 6).valid || _co.saving) || !_co.canUpdate); var currVal_12 = i0.ɵunv(_v, 26, 2, _ck(_v, 28, 0, i0.ɵnov(_v.parent, 0), "evaluation_lab_setupPanel_summaryStep_update")); _ck(_v, 26, 0, currVal_10, currVal_11, currVal_12); }, function (_ck, _v) { var currVal_0 = i0.ɵnov(_v, 8).ngClassUntouched; var currVal_1 = i0.ɵnov(_v, 8).ngClassTouched; var currVal_2 = i0.ɵnov(_v, 8).ngClassPristine; var currVal_3 = i0.ɵnov(_v, 8).ngClassDirty; var currVal_4 = i0.ɵnov(_v, 8).ngClassValid; var currVal_5 = i0.ɵnov(_v, 8).ngClassInvalid; var currVal_6 = i0.ɵnov(_v, 8).ngClassPending; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_EditSimulatorAgentsPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i10.View_PanelComponent_0, i10.RenderType_PanelComponent)), i0.ɵdid(3, 114688, null, 1, i11.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i12.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_EditSimulatorAgentsPanelComponent_1)), i0.ɵdid(7, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.agentsConfig; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_EditSimulatorAgentsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "simulator-agent-setup-panel", [], null, null, null, View_EditSimulatorAgentsPanelComponent_0, RenderType_EditSimulatorAgentsPanelComponent)), i0.ɵdid(1, 245760, null, 0, i13.EditSimulatorAgentsPanelComponent, [i14.Router, i6.EvaluationService, i15.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EditSimulatorAgentsPanelComponentNgFactory = i0.ɵccf("simulator-agent-setup-panel", i13.EditSimulatorAgentsPanelComponent, View_EditSimulatorAgentsPanelComponent_Host_0, { settings: "settings" }, {}, []);
export { EditSimulatorAgentsPanelComponentNgFactory as EditSimulatorAgentsPanelComponentNgFactory };
