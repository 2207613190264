import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MachineBase } from '../../@entities/machines/models/machine-base.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { Machine } from '@wcd/domain';
import { NavItemModel } from '@wcd/shared';

@Component({
	selector: 'machine-name',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<fab-icon
			*ngIf="showIcon && hasName"
			[iconName]="FabricIconNames.System"
			[contentClass]="iconCssClass"
		></fab-icon>
		<route-link *ngIf="showLink && machineId; else machineName" [routeLink]="machineRouteLinkModel">
			<ng-container *ngTemplateOutlet="machineName"></ng-container>
		</route-link>
		<ng-template #machineName>
			<span
				*ngIf="hasName"
				class="noprint na-on-empty"
				[wcdTooltip]="shortName !== fullName ? fullName : null"
			>
				{{ preferFullName ? fullName : shortName }}
			</span>
			<span class="print-only na-on-empty">{{ fullName }}</span>
		</ng-template>
	`,
})
export class MachineNameComponent {
	private _machine: string | MachineBase;
	shortName: string;
	fullName: string;
	machineId: string;
	hasName: boolean;
	FabricIconNames = FabricIconNames;

	constructor(private globalEntityTypesService: GlobalEntityTypesService) {}

	@Input() showIcon: boolean = false;
	@Input() iconCssClass: string = 'field-value-icon';
	@Input() showLink: boolean = false;
	@Input() preferFullName: boolean = false;

	@Input()
	set machine(value: string | MachineBase) {
		this._machine = value;
		this.setMachineData();
	}

	get machine(): string | MachineBase {
		return this._machine;
	}

	machineRouteLinkModel: Partial<NavItemModel>;

	private setMachineData() {
		if (this.machine) {
			let machineEntity: Machine;
			if (typeof this.machine === 'string') {
				machineEntity = new Machine({
					name: this.machine,
					id: null,
				});
			} else {
				const machineBase: MachineBase = <MachineBase>this.machine;
				machineEntity = new Machine({
					name: machineBase.name || machineBase.machineId || '',
					id: machineBase.machineId,
				});
			}
			this.fullName = machineEntity.name;
			this.shortName = this.globalEntityTypesService.getEntityName(Machine, machineEntity);
			this.machineId = machineEntity.id;
			this.machineRouteLinkModel = this.globalEntityTypesService.getRouteLinkModel(Machine, <
				Partial<Machine>
			>{ senseMachineId: this.machineId, name: this.machineId });
		}
		this.hasName = !!(this.fullName || this.shortName);
	}
}
