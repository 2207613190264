import { Entity, EntityField } from '@microsoft/paris';
import { airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsLinkBase } from './airs-entity.link-base';

@Entity({
	...airsEntityConfig,
	singularName: 'Email Link',
	pluralName: 'Email Links',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsEmailLink,
})
export class AirsEmailLink extends AirsLinkBase {
	@EntityField()
	subject: string;
}
