<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
	<div class="wcd-full-height wcd-flex-vertical">
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-large-horizontal">
			<div class="wcd-font-weight-bold wcd-font-size-xl wcd-margin-xLarge-bottom">
				{{ 'incident.overview.mte.title' | i18n }}</div>
			<ng-container *ngIf="incident">
				<wcd-collapsible-section *ngIf="incident.description" [noSideMargins]="true"
					[sectionId]="'incident.mte.executiveSummary'"
					[label]="'incident.overview.mte.executiveSummary' | i18n">
					<p [innerHTML]="description"></p>
				</wcd-collapsible-section>
				<wcd-collapsible-section *ngIf="incident.recommendedActions" [noSideMargins]="true"
					[sectionId]="'incident.mte.recommendationSummary'"
					[label]="'incident.overview.mte.recommendationSummary' | i18n">
					<p [innerHTML]="recommendedActions"></p>
				</wcd-collapsible-section>
			</ng-container>
		</div>
	</div>
</wcd-panel>