import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { SerializedFilters } from '@wcd/ng-filters';
import { Observable } from 'rxjs';
import {
	MachineProtection,
	MachineHealthStatusApiCall,
	MachineAntivirusStatusApiCall,
	MachineOsPlatformApiCall,
	MachineOsVersionApiCall,
	MachineProtectionData,
	OsVersion,
	HealthStatus,
	AvStatus,
	OsPlatform,
	MachineReportDimensionKeyType,
} from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { ChartSettings } from '@wcd/charts';
import { ReportsService } from '../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { Router } from '@angular/router';
import { mapValues, mapKeys, maxBy } from 'lodash-es';
import { KnownColor } from '../../shared/models/colors/known-colors.models';
import { MachineProtectionStatusMapping } from '../machine-protection-status-mapping';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { TrackingEventId } from '../../insights/models/tracking-event-Id.enum';
import { ChartType } from '../../reporting/reporting.types';
import { FeaturesService, Feature } from '@wcd/config';

const MAX_LABELS = 4;

@Injectable({
	providedIn: 'root',
})
export class MachineProtectionReportService {
	constructor(
		private paris: Paris,
		private tzDateService: TzDateService,
		private readonly appInsightsService: AppInsightsService,
		private prettyNumberService: PrettyNumberService,
		private router: Router,
		private machineProtectionStatusMapping: MachineProtectionStatusMapping,
		private i18nService: I18nService
	) {}

	getMachineByHealthStatusData(params: SerializedFilters): Observable<MachineProtection> {
		params.alignToPeriod = 'true';
		return this.paris.apiCall(MachineHealthStatusApiCall, params);
	}

	getMachineByAntivirusStatusData(params: SerializedFilters): Observable<MachineProtection> {
		params.alignToPeriod = 'true';

		const unknownEnumValue = 'Unknown';
		const unknownNewEnumValue = 'UnknownNew';

		if ('antivirusStatus' in params) {
			const antiVirusValues = params.antivirusStatus as string[];

			if (
				antiVirusValues.includes(unknownEnumValue) &&
				!antiVirusValues.includes(unknownNewEnumValue)
			) {
				antiVirusValues.push(unknownNewEnumValue);
			}

			params.antivirusStatus = antiVirusValues;
		}

		return this.paris.apiCall(MachineAntivirusStatusApiCall, params);
	}

	getMachineByOsPlatformData(params: SerializedFilters): Observable<MachineProtection> {
		params.alignToPeriod = 'true';
		return this.paris.apiCall(MachineOsPlatformApiCall, params);
	}

	getMachineByOsVersionData(params: SerializedFilters): Observable<MachineProtection> {
		params.alignToPeriod = 'true';
		return this.paris.apiCall(MachineOsVersionApiCall, params);
	}

	getSettingsOverTime<T extends MachineReportDimension>(
		dimensionType: MachineReportDimensionType,
		dimensionMapping: ReportMapping<T>,
		data?: MachineProtection,
		singleDimensionToDisplay?: MachineReportDimensionKeyType
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data;

		const maxMachineCount: number =
			dimensionData &&
			dimensionData.reduce(
				(maxMachineCount: number, data: MachineProtectionData) =>
					Math.max(maxBy(Object.values(data.values)), maxMachineCount),
				0
			);

		return {
			options: {
				data: {
					columns: this.getDimensionData(
						dimensionType,
						dimensionData,
						dimensionMapping,
						singleDimensionToDisplay
					),
					colors: mapValues(
						mapKeys(dimensionMapping, (value) => value.label),
						(value) => value.knownColorsMap.trend.raw
					),
				},
				axis: {
					y: {
						max: maxMachineCount ? maxMachineCount : 0,
						min: 0,
						tick: {
							values: dimensionData ? ReportsService.getYTicks(maxMachineCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
					x: {
						type: 'category',
						categories: this.getDimensionDates(dimensionData),
						tick: {
							multiline: false,
							culling: {
								max: dimensionData
									? Math.min(dimensionData.length + 1, MAX_LABELS)
									: MAX_LABELS,
							},
						},
					},
				},
				point: {
					show: false,
				},
				grid: {
					y: {
						show: true,
					},
				},
				legend: {
					show: false,
				},
				size: {
					height: 200,
				},
			},
			disableCursor: true,
		};
	}

	getSettingsDaily<T extends MachineReportDimension>(
		type: ChartType,
		dimensionMapping: ReportMapping<T>,
		data?: MachineProtection,
		getDimensionFilterData?: (dimensionKey: MachineReportDimensionKeyType) => DimensionFilterData
	): ChartSettings {
		const dimensionData = data && data.data && data.data.length && data.data[0];
		const maxCount: number =
			dimensionData && dimensionData.values && maxBy(Object.values(dimensionData.values));
		const chartData = Object.entries<MachineReportDimensionMapping>(
			dimensionMapping
		).map(([key, value]) => this.getDimensionDataDaily(value.label, key, dimensionData));

		const colorsPattern = Object.values<MachineReportDimensionMapping>(dimensionMapping).map(
			(value) => value.knownColorsMap.daily.raw
		);

		return {
			options: {
				color: {
					pattern: colorsPattern,
				},
				data: {
					columns: [
						[
							this.i18nService.get('reporting.machineReport.dailyTooltipDescription'),
							...chartData.map((data) => data.value),
						],
					],

					groups: [
						Object.values<MachineReportDimensionMapping>(dimensionMapping).map(
							(value) => value.label
						),
					],
					type: 'bar',
					labels: {
						format: (value) => (maxCount > 0 ? this.prettyNumberService.prettyNumber(value) : ''), //c3 labels issue when all labels are zero, the labels are at a wrong position
					},

					color: (inColor, data) => {
						if (data.index !== undefined) {
							return colorsPattern[data.index];
						}
						return inColor;
					},
					onclick: (e) => {
						const clickedValue = Object.keys(dimensionMapping)[e.index];
						this.onClick(getDimensionFilterData, clickedValue as MachineReportDimensionKeyType);
					},
				},
				tooltip: {
					grouped: false,
					contents: (data) =>
						`<table class="c3-tooltip">
							<tbody>
								<tr>
									<td class="name">${data[0].value}</td>
								</tr>
							</tbody>
						</table>`,
				},
				bar: {
					width: {
						max: 8,
						ratio: 0.2,
					},
				},
				axis: {
					rotated: type === ChartType.Horizontal,
					x: {
						type: 'category',
						categories: chartData.map((data) => data.label),
						tick: {
							multiline: type === ChartType.Horizontal,
							culling: {
								max: 1,
							},
							rotate: 35,
						},
						padding: { right: 0.5 },
					},
					y: {
						show: true,
						max: maxCount ? maxCount : 0,
						min: 0,
						tick: {
							values: data ? ReportsService.getYTicks(maxCount) : [],
							format: (value) => this.prettyNumberService.prettyNumber(value),
						},
						padding: {
							bottom: 0,
						},
					},
				},
				padding: {
					left: type === ChartType.Horizontal ? 100 : 50,
				},
				grid: {
					y: {
						show: true,
					},
				},
				point: {
					show: false,
				},
				legend: { show: false },
				size: {
					height: 200,
				},
			},
		};
	}

	private onClick(
		getDimensionFilterData: (dimensionKey) => DimensionFilterData,
		dimensionKey: MachineReportDimensionKeyType
	) {
		if (!getDimensionFilterData) {
			return; // Dimension doesn't support drill down
		}

		const parsedFilters: MachineListFilters = {};
		const dimensionFilterData = getDimensionFilterData(dimensionKey);

		Object.entries(dimensionFilterData.currentFilters).forEach(([key, values]) => {
			if (key !== dimensionFilterData.type) {
				switch (key) {
					case 'healthStatus': {
						const parsedValues = (<Array<string>>values).map((value) =>
							encodeURIComponent(
								this.machineProtectionStatusMapping.healthStatusMapping[value]
									.machineListFilter
							)
						);
						parsedFilters.healthStatuses = parsedValues.join('|');
						break;
					}
					case 'antivirusStatus': {
						const parsedValues = (<Array<string>>values)
							.map((value) =>
								encodeURIComponent(
									this.machineProtectionStatusMapping.avStatusMapping[value]
										.machineListFilter
								)
							)
							.filter((value) => value !== ''); // ignore filters that doesn't have corresponding filter on machines page
						parsedFilters.securityPropertiesRequiringAttention = parsedValues.join('|');
						break;
					}
					case 'osPlatform': {
						const parsedValues = (<Array<string>>values).map((value) =>
							encodeURIComponent(
								this.machineProtectionStatusMapping.osPlatformMapping[value].machineListFilter
							)
						);
						parsedFilters.osPlatforms = parsedValues.join('|');
						break;
					}
					case 'osVersion': {
						const parsedValues = (<Array<string>>values).map((value) =>
							encodeURIComponent(
								this.machineProtectionStatusMapping.osVersionMapping[value].machineListFilter
							)
						);
						parsedFilters.osVersions = parsedValues.join('|');
						break;
					}
					case 'rbacGroupIds': {
						parsedFilters.rbacGroupIds = dimensionFilterData.currentFilters[rbacGroupIdsFilter]
							? (<Array<string>>dimensionFilterData.currentFilters[rbacGroupIdsFilter]).join(
									'|'
							  )
							: '';
						break;
					}
				}
			}
		});

		const defaultOsPlatformsIfAv =
			dimensionFilterData.type === 'antivirusStatus' ? 'osPlatforms=Windows10' : '';

		const drillDownFilters = [
			dimensionFilterData.dimensionFilter ? dimensionFilterData.dimensionFilter : '',
			parsedFilters.securityPropertiesRequiringAttention
				? `securityPropertiesRequiringAttention=${parsedFilters.securityPropertiesRequiringAttention}`
				: '',
			parsedFilters.healthStatuses ? `healthStatuses=${parsedFilters.healthStatuses}` : '',
			parsedFilters.osPlatforms ? `osPlatforms=${parsedFilters.osPlatforms}` : defaultOsPlatformsIfAv,
			parsedFilters.osVersions ? `releaseVersion=${parsedFilters.osVersions}` : '',
			parsedFilters.rbacGroupIds ? `rbacGroupIds=${parsedFilters.rbacGroupIds}` : '',
		].filter((value) => value !== '');

		this.trackNavigation(dimensionKey, drillDownFilters, dimensionFilterData.type);

		// There is no drill down for updated antivirus
		dimensionKey !== AvStatus.Updated &&
			this.router.navigate(['/machines'], {
				queryParams: {
					filters: drillDownFilters.join(','),
					range: '180',
				},
			});
	}

	private trackNavigation(
		clickedValue: MachineReportDimensionKeyType,
		drillDownFilters: Array<string>,
		dimension: string
	) {
		this.appInsightsService.trackEvent('UsageTrack', {
			type: TrackingEventType.Navigation,
			id: TrackingEventId.DrillDownToMachines,
			dimension: dimension,
			column: clickedValue,
			filters: drillDownFilters,
		});
	}

	private getDimensionDates(dimensionData: Array<MachineProtectionData>): Array<string> {
		return dimensionData
			? dimensionData.map((data) =>
					this.tzDateService.format(
						Date.UTC(data.date.getFullYear(), data.date.getMonth(), data.date.getDate()),
						'MM/dd'
					)
			  )
			: [];
	}

	private getDimensionData<T extends MachineReportDimension>(
		type: MachineReportDimensionType,
		dimensionData: Array<MachineProtectionData>,
		dimensionMapping: ReportMapping<T>,
		singleDimensionToDisplay?: MachineReportDimensionKeyType
	): (string | number | boolean)[][] {
		return Object.keys(type)
			.filter((key) => (singleDimensionToDisplay ? key === singleDimensionToDisplay : true))
			.map((key) => this.getDimensionDataOvertime(dimensionMapping[key].label, key, dimensionData));
	}

	private getDimensionDataOvertime(
		label: string,
		dimensionType: string,
		dimensionData: Array<MachineProtectionData>
	) {
		return [
			label,
			...(dimensionData &&
				dimensionData.map((data) => (data.values[dimensionType] ? data.values[dimensionType] : 0))),
		];
	}

	private getDimensionDataDaily(
		label: string,
		dimensionType: string,
		dimensionData: MachineProtectionData
	) {
		return {
			label: label,
			value:
				dimensionData &&
				(dimensionData.values[dimensionType] ? dimensionData.values[dimensionType] : 0),
		};
	}
}

export interface MachineReportDimensionMapping {
	knownColorsMap: {
		daily: KnownColor;
		trend: KnownColor;
	};
	label: string;
	machineListFilter: string;
	priority?: number;
}

export declare type MachineReportDimension = HealthStatus | AvStatus | OsVersion | OsPlatform;
export declare type MachineReportDimensionType =
	| typeof HealthStatus
	| typeof AvStatus
	| typeof OsVersion
	| typeof OsPlatform;

export type ReportMapping<T extends MachineReportDimensionKeyType> = Record<T, MachineReportDimensionMapping>;

export const rbacGroupIdsFilter = 'rbacGroupIds';

export interface MachineListFilters {
	securityPropertiesRequiringAttention?: string;
	healthStatuses?: string;
	osPlatforms?: string;
	osVersions?: string;
	rbacGroupIds?: string;
}

export interface DimensionFilterData {
	currentFilters: SerializedFilters;
	type: string;
	dimensionFilter: string;
}
