import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ElementRef,
	ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { CustomConfirmComponent } from '../../../../dialogs/models/custom-confirm-options';
import { I18nService } from '@wcd/i18n';
import { Machine, MachineValue, DeviceCategory } from '@wcd/domain';

export interface IsolateModalPayload {
	readonly allowOutlookAndSkype?: boolean;
	readonly comment: string;
	readonly bulkName?: string;
}

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{ disabled: isDisabled }"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<p id="isolate-content">
					{{ dialogContentText }}
				</p>

				<isolation-modal-warnings
					*ngIf="isNotManagedDevice"
					[deviceId]="device.id"
					[isHighValueAsset]="device.assetValue === MachineValue.High"
					[isNetworkDevice]="device.deviceCategory === DeviceCategory.NetworkDevice"
					(onReady)="enableNotManagedDeviceModal()"
				></isolation-modal-warnings>

				<!-- styles here is set in order to override Bootstrap's 'label { max-width: 100% }' rule -->
				<fab-checkbox
					#outlookAndSkypeCheckbox
					[styles]="{ label: 'bootstrap-fix-cut-label' }"
					*ngIf="showAllowOutlookAndSkypeCheckbox"
					[(checked)]="isOutlookAndSkypeAllowed"
					[label]="
						'machines.entityDetails.actions.isolateMachine.dialog.isolate.form.allowOutlookAndSkype.label'
							| i18n
					"
				></fab-checkbox>

				<ng-container *ngIf="isMultipleSelection">
					<label class="wcd-required wcd-margin-small-top" for="isolateBulkName"
						>{{
							i18nService.strings
								.machines_entityDetails_actions_$general_dialog_form_remediationName_label
						}}:</label
					>
					<input
						type="text"
						#focusable
						id="isolateBulkName"
						[(ngModel)]="bulkName"
						[placeholder]="
							i18nService.strings
								.machines_entityDetails_actions_$general_dialog_form_remediationName_placeholder
						"
						name="isolate-device-bulk-name"
						class="form-control wcd-margin-small-bottom"
						maxlength="300"
						required
					/>
				</ng-container>
				<div class="wcd-flex-vertical wcd-flex-center-all" *ngIf="isNotManagedDevice">
					<img [src]="'/assets/images/icons/unmanaged_device_isolation.svg' | basePath" [attr.alt]="
						'machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_altText' | i18n
						"/>
				</div>
				<label class="wcd-required" for="isolateComment"
					>{{ 'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n }}:</label
				>
				<textarea
					required
					rows="4"
					id="isolateComment"
					[attr.placeholder]="
						'machines.entityDetails.actions.$general.dialog.form.comment.placeholder' | i18n
					"
					class="form-control dialog-textarea wcd-full-width wcd-margin-small-bottom wcd-no-resize-horizontal"
					aria-describedby="isolate-content"
					[(ngModel)]="commentText"
				></textarea>
				<fab-icon iconName="Info"></fab-icon>
				<span>{{ undoInfoText }}</span>
			</confirm-modal-content>
		</modal>
		`,
})
export class IsolateModalComponent extends ModalContainer
implements CustomConfirmComponent<IsolateModalPayload>, OnInit {
	@Input() machineName?: string;
	@Input() isSaving: boolean = false;
	@Input() showAllowOutlookAndSkypeCheckbox?: boolean;
	@Input() isMultipleSelection: boolean = false;
	@Input() device: Machine;

	@Output() readonly onConfirm = new EventEmitter<IsolateModalPayload>();
	@Output() readonly onCancel = new EventEmitter<void>();

	MachineValue = MachineValue;
	DeviceCategory = DeviceCategory;

	isNotManagedDeviceWarningsReady: boolean;
	isOutlookAndSkypeAllowed?: boolean;
	commentText: string;
	dialogContentText: string;
	bulkName: string;
	undoInfoText: string;

	@ViewChild('outlookAndSkypeCheckbox', { static: false }) outlookAndSkypeCheckbox: ElementRef;

	constructor(router: Router, private readonly i18nService: I18nService, private elementRef: ElementRef) {
		super(router);
	}

	ngOnInit() {
		this.dialogContentText = this.getDialogContentText();
		this.undoInfoText = this.getUndoInfoText();

		this.settings = {
			...this.settings,
			className: 'wcd-flex-vertical',
			title: this.getTitle(),
		};
	}

	get isDisabled() {
		return (
			this.isSaving ||
			!this.commentText ||
			(this.isNotManagedDevice && !this.isNotManagedDeviceWarningsReady)
			);
		}

		get isNotManagedDevice() {
			return this.device.isMdatp && !this.device.isManagedByMdatp && !this.isMultipleSelection;
		}

		private getTitle() {
			return this.isMultipleSelection
			? this.i18nService.strings
			.machines_entityDetails_actions_multipleIsolateMachines_dialog_isolate_title
			: this.device.isManagedByMdatp
			? this.i18nService.get('machines_entityDetails_actions_isolateMachine_dialog_isolate_title', {
				machineName: this.machineName,
			})
			: this.i18nService.get('machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_title', {
				machineName: this.machineName,
			});
		}

		private getDialogContentText() {
			return this.isMultipleSelection
			? this.i18nService.strings
			.machines_entityDetails_actions_multiIsolateMachines_dialog_isolate_content
			: this.device.isManagedByMdatp
			? this.i18nService.strings.machines_entityDetails_actions_isolateMachine_dialog_isolate_content
			: this.i18nService.strings.machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_content;
		}

		private getUndoInfoText() {
			return this.device.isManagedByMdatp
			? this.i18nService.strings.machines_entityDetails_actions_isolateMachine_dialog_isolate_messages_undo
			: this.i18nService.strings.machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_messages_undo;
		}

	onConfirmHandler() {
		this.onConfirm.emit(
			Object.assign(
				{
					comment: this.commentText,
					allowOutlookAndSkype: this.isOutlookAndSkypeAllowed,
				},
				this.isMultipleSelection ? { bulkName: this.bulkName } : null
			)
		);
		this.onConfirm.complete();
	}

	onCancelHandler() {
		this.onCancel.emit();
		this.onCancel.complete();
	}

	enableNotManagedDeviceModal() {
		this.isNotManagedDeviceWarningsReady = true;
	}
}
