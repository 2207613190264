import { ModelBase } from '@microsoft/paris';
import { Input, Output, EventEmitter } from '@angular/core';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { Observable } from 'rxjs';
import { EntityPageViewMode } from '../models/entity-page-view-mode.enum';

/**
 * Base class for entity page contents components
 */
export class EntityComponentBase<TEntity extends ModelBase> {
	@Input() entity: TEntity;
	@Input() isFullScreen: boolean;
	@Input() action$: Observable<{ action: ItemActionModel<TEntity>; data: any }>;

	@Output() runAction$: EventEmitter<{ actionId: string; data?: any }> = new EventEmitter();

	@Input() entityPageViewMode: EntityPageViewMode;

	runAction(actionId: string, data?: any) {
		this.runAction$.emit({ actionId, data });
	}
}
