import { DataQuery, Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { OutbreakDailyImpactedDevices } from './impact/outbreak-daily-impacted-devices.entity';
import { OutbreakUserState } from './outbreak-user-state.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { OutbreakAlertsCount } from './alerts/outbreak-alerts-count.value-object';
import { OutbreakImpactedEntitiesCount } from './impact/outbreak-impacted-entities-count.value-object';
import { ThreatReportType } from './threat-report-type.values';
import { sccHostService } from '@wcd/scc-interface';
import { OutbreakTagTypes } from './outbreak-tags-type.values';
import { ThreatExposureSeverity } from './threat-exposure-severity.entity'

interface IsNewDataHelper {
	lastVisitTime?: Date;
}

export const THREAT_ANALYTICS_ROUTE = 'threatanalytics3';

export const setTaSccNavNotificationIndicator = (indicator: boolean) => {
	sccHostService.ui.setNavNotificationIndicator([THREAT_ANALYTICS_ROUTE], indicator);
}

export interface ExtendedOutbreakDataQuery extends DataQuery {
	where: ExtendedOutbreakWhereQuery;
}

export interface ExtendedOutbreakWhereQuery {
	isMtp: boolean;
	isK8sMigration: boolean;
}

@Entity({
	singularName: 'Threat',
	pluralName: 'Threats',
	readonly: true,
	// Once MDATP portal is deprecated the endpoint is always outbreaksEnrichedDataMtp
	endpoint: (config, query: ExtendedOutbreakDataQuery) => {
		return query && query.where && query.where.isMtp ?
			query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedDataMtp' : 'v2/outbreaksEnrichedDataMtp'
			: query && query.where && query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedData' : 'outbreaksEnrichedData'
	},
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatAnalytics,
	parseDataSet: (rawData) => {
		return ({
			items: rawData['Items'] || rawData['items'],
			meta: {
				devicesCalculationStatus: rawData['DevicesCalculationStatus'],
				mailboxesCalculationStatus: rawData['MailboxesCalculationStatus'],
				threatExposureCalculationStatus: rawData['ThreatExposureCalculationStatus']
			},
			count: 0
	})}
})
export class ExtendedOutbreak extends EntityModelBase<string> {
	@EntityField({ data: 'Id' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'DisplayName' })
	displayName: string;

	@EntityField({ data: 'CreatedOn' })
	createdOn: Date;

	@EntityField({ data: 'StartedOn' })
	startedOn: Date;

	@EntityField({ data: 'LastUpdatedOn' })
	lastUpdatedOn: Date;

	@EntityField({ data: 'Tags' })
	tags: Array<OutbreakTagTypes>;

	// This field should be removed once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'Impact' })
	impact?: OutbreakDailyImpactedDevices;

	// This field should be removed once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'ImpactOvertime', arrayOf: OutbreakDailyImpactedDevices })
	impactOvertime?: Array<OutbreakDailyImpactedDevices>;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({
		data: 'AlertsCount',
		defaultValue: {
			activeAlertsCount: 0,
			resolvedAlertsCount: 0,
		}
	})
	alertsCount?: OutbreakAlertsCount;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({
		data: 'ImpactedEntitiesCount',
		defaultValue: {
			impactedDevicesCount: 0,
			impactedMailboxesCount: 0,
			hasDevicesDefinition: false,
			hasMailboxesDefinition: false,
		}
	})
	impactedEntitiesCount?: OutbreakImpactedEntitiesCount;

	@EntityField({ data: 'ExposureSeverity' })
	exposureSeverity?: ThreatExposureSeverity;

	@EntityField({ data: 'ExposureScore' })
	exposureScore?: number;

	@EntityField({ data: 'ExposedDevices' })
	exposedDevices?: number;

	@EntityField({ data: 'CveAndScidNotDefined' })
	cveAndScidNotDefined?: boolean;

	// This field will not be optional once ThreatAnalyticsMTP is in GA
	@EntityField({ data: 'ReportType' })
	reportType?: ThreatReportType;

	@EntityField({ data: 'UserState' })
	userState: OutbreakUserState;

	@EntityField({ data: 'CveNotDefined' })
	cveNotDefined?: boolean;

	@EntityField({ data: 'ScidNotDefined' })
	scidNotDefined?: boolean;

	@EntityField({ data: 'ScaExposedDevices' })
	scaExposedDevices?: number;

	@EntityField({ data: 'VaExposedDevices' })
	vaExposedDevices?: number;

	isNewDataHelper: IsNewDataHelper;

	get isNew(): boolean {
		return !this.isNewDataHelper.lastVisitTime || this.isNewDataHelper.lastVisitTime < this.lastUpdatedOn;
	}

	static adjustOutbreaksData(outbreaks: ExtendedOutbreak[], lastVisitTimes?: Map<string, Date>): ExtendedOutbreak[] {
		const userStateExistsInBackend: boolean = (outbreaks || []).some(
			outbreak => outbreak.userState.lastVisitTime != null
		);

		(outbreaks || []).forEach(outbreak => {
			outbreak.isNewDataHelper.lastVisitTime = userStateExistsInBackend
				? outbreak.userState.lastVisitTime
				: (lastVisitTimes && lastVisitTimes.get(outbreak.id));
		});

		return outbreaks;
	}

	constructor(data) {
		super(data);
		this.isNewDataHelper = {};
	}
}
