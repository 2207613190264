import { Component } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Machine, SeverityType } from '@wcd/domain';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { SeverityTypesService } from '../../../../../shared/services/severity-types.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { FeaturesService, Feature } from '@wcd/config';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'machines-at-risk-widget',
	template: `
		<wcd-datatable
			[items]="data$ | async"
			[columns]="tableColumns"
			[hidden]="error$ | async"
			[selectEnabled]="false"
			[showHeaders]="false"
			class="table-with-legend"
			(itemClick)="showMachineSidePanel($event.item)"
			[label]="
				'dashboard.threatAnalytics.mitigationRecommendation.affectedMachinesColumn'
					| i18n
			"
		></wcd-datatable>
	`,
})
export class MachinesAtRiskWidget extends ReportWidgetComponent<
	Array<MachineBackendData>,
	MachinesAtRiskBackendData
> {
	get widgetConfig(): ReportWidgetConfig<Array<MachineBackendData>, MachinesAtRiskBackendData> {
		return {
			id: 'machinesAtRisk',
			name: this.i18nService.get('widget_title_devicesAtRisk'),
			noDataMessage: this.i18nService.get('widget_title_devicesAtRisk_no_devices_at_risk'),
			noDataIcon: 'System',
			api: {
				url: () => `${this.serviceUrlsService.threatIntel}/Dashboard/GetTopAlertMachines`,
				isExternal: true,
				params: {
					lookBackInDays: String(this.appConfigService.widgetLookback),
					readFromCache: String(true),
				},
			},
			parseData: this.parseData.bind(this),
			extraHeaderHtml: () =>
				`<a data-track-id="GoToMachinesPage" data-track-type="Navigation" href="/machines">${this.i18nService.get('machine_at_risk_widget_machine_list')}</a>`,
		};
	}

	tableColumns: Array<DataTableField<MachineBackendData>> = DataTableField.fromList<MachineBackendData>([
		{
			id: 'machineName',
			name: this.i18nService.get('widget_title_devicesAtRisk_device_name'),
			icon: {
				fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Machine),
			},
			getDisplay: (item: MachineBackendData) => item.ComputerDnsName.split('.')[0],
			className: 'nowrap',
			getLink: (item: MachineBackendData) =>
				`/${this.featuresService.isEnabled(Feature.UpgradeMachinePage) ? 'machines' : '_machine'}/${
					item.MachineId
				}`,
			getTooltip: (item: MachineBackendData) => item.ComputerDnsName.split('.')[0],
			custom: {
				tracking: {
					id: 'machineLink',
					type: TrackingEventType.Navigation,
				},
			},
			fluidWidth: 1,
		},
		{
			id: 'highSeverityAlerts',
			name: this.i18nService.get('widget_title_devicesAtRisk_high_severity_alerts'),
			getDisplay: (item: MachineBackendData) => item.HighSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.High);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_high_severity_tooltip'),
		},
		{
			id: 'mediumSeverityAlerts',
			name: 'widget_title_devicesAtRisk_medium_severity_alerts',
			getDisplay: (item: MachineBackendData) => item.MediumSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Medium);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_medium_severity_tooltip'),
		},
		{
			id: 'lowSeverityAlerts',
			name: 'widget_title_devicesAtRisk_low_severity_alerts',
			getDisplay: (item: MachineBackendData) => item.LowSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Low);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_low_severity_tooltip'),
		},
		{
			id: 'infoSeverityAlerts',
			name: 'widget_title_devicesAtRisk_information_severity_alerts',
			getDisplay: (item: MachineBackendData) => item.InformationalSevAlerts,
			getFieldCssClass: () => {
				const severity = SeverityTypesService.getSeverityByType(SeverityType.Informational);
				return 'color-border-' + severity.className;
			},
			className: 'nowrap legend-item-cell',
			tooltip: this.i18nService.get('widget_title_information_severity_tooltip'),
		},
	]);

	showMachineSidePanel(rawMachine: MachineBackendData) {
		this.paris
			.getRepository(Machine)
			.createItem(rawMachine)
			.subscribe((machine: Machine) => {
				this.entityPanelsService.showEntities(Machine, [machine]);
			});
	}

	constructor(
		reportsService: ReportsService,
		private serviceUrlsService: ServiceUrlsService,
		private appConfigService: AppConfigService,
		private paris: Paris,
		private entityPanelsService: EntityPanelsService,
		private featuresService: FeaturesService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private i18nService: I18nService
	) {
		super(reportsService);
	}

	parseData(data: MachinesAtRiskBackendData): Array<MachineBackendData> {
		return data.Machines;
	}
}

interface MachineBackendData {
	HighSevAlerts: number;
	MediumSevAlerts: number;
	LowSevAlerts: number;
	InformationalSevAlerts: number;
	ComputerDnsName: string;
	MachineId: string;
}

interface MachinesAtRiskBackendData {
	Machines: Array<MachineBackendData>;
}
