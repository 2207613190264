/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Input,
	Output,
	OnDestroy,
	ViewChild,
} from '@angular/core';
import { DimensionsModel } from '../../models/dimensions.model';
import { PopupSettings } from '../../models/popup-settings';
import { PositionModel } from '../../models/position.model';
import { PositionService } from '../../services/position.service';

const DIALOG_MARGIN: number = 70;

@Component({
	selector: 'modal',
	template: `
		<div
			#overlay
			class="dialog-overlay"
			(click)="overlayClicked($event)"
			[id]="settings.id"
			[class.centered]="isCentered"
			[class.horizontal-centered]="isHorizontalCentered"
			[class.dialog-visible]="isInit"
			[class.dialog-disabled]="settings && settings.enableOverlay === false"
			[class.transparent]="settings && settings.showOverlay === false"
		>
			<div
				class="dialog wcd-flex-vertical wcd-scroll-vertical"
				[ngStyle]="dialogStyle"
				#dialog
				[class.ie11Patch]="ieFixedPosition"
				[class.ieFixedPosition]="ieFixedPosition"
				cdkTrapFocus
				role="dialog"
				[attr.aria-label]="ariaLabel ? ariaLabel : settings?.title"
			>
				<header class="dialog-header wcd-flex-horizontal wcd-flex-none" *ngIf="settings?.title">
					<h4 class="wcd-flex-1 dialog-title ie11Patch ie11Flex">
						<wcd-shared-icon *ngIf="settings.titleIcon" [iconName]="settings.titleIcon">
						</wcd-shared-icon>
						{{ settings.title }}
					</h4>
					<div class="wcd-flex-none dialog-header-controls">
						<button autofocus (click)="close.emit()" [attr.title]="'buttons_close' | i18n">
							<wcd-shared-icon [ariaLabel]="''" iconName="clear"> </wcd-shared-icon>
						</button>
					</div>
				</header>
				<div
					class="wcd-flex-1 dialog-body ie11Patch ie11Flex"
					[ngClass]="settings && settings.className"
				>
					<ng-content></ng-content>
				</div>
			</div>
		</div>
	`,
})
export class ModalComponent implements OnDestroy {
	@Output() readonly close: EventEmitter<void> = new EventEmitter();

	@Input() ariaLabel?: string;

	@Input()
	set settings(settings: PopupSettings) {
		this.setSettings(settings);
	}

	isInit: boolean = false;

	get settings(): PopupSettings {
		return this._settings;
	}

	private _settings: PopupSettings;

	// Added by default to fix modal position on IE11. On special cases could be turned off by passing false
	@Input() ieFixedPosition = true;

	dialogStyle: { [index: string]: string };
	isCentered: boolean;
	isHorizontalCentered: boolean;

	@ViewChild('dialog', { static: false }) private readonly _dialogBodyElement: ElementRef<HTMLElement>;

	@HostListener('keydown', ['$event'])
	onKeydown($event: KeyboardEvent): boolean {
		if ($event.keyCode === 27) {
			// ESCAPE
			$event.preventDefault();
			$event.stopPropagation();
			this.close.emit();
			return false;
		}

		return true;
	}

	@ViewChild('overlay', { static: true }) readonly modalOverlay: ElementRef<HTMLElement>;

	constructor(protected elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {}

	overlayClicked(event: Event) {
		if (event.target === this.modalOverlay.nativeElement) {
			this.close.emit();
		}
	}

	private getDimensions(): DimensionsModel {
		const clientRect = this._dialogBodyElement.nativeElement.getBoundingClientRect();
		return new DimensionsModel(clientRect.width, clientRect.height);
	}

	private setSettings(settings: PopupSettings): void {
		this._settings = settings;

		if (settings && !settings.position) {
			this.isCentered = true;
		}
		if (settings && settings.position && !settings.position.left) {
			this.isHorizontalCentered = true;
		}

		if (settings && (settings.position || settings.dimensions)) {
			setTimeout(() => {
				let settingsDimensions: DimensionsModel = settings.dimensions;

				if (settingsDimensions) {
					if (settingsDimensions.width)
						this._dialogBodyElement.nativeElement.style.width = settingsDimensions.width + 'px';

					if (settingsDimensions.height)
						this._dialogBodyElement.nativeElement.style.height = settingsDimensions.height + 'px';
				}

				if (!settingsDimensions || !settingsDimensions.width || !settingsDimensions.height) {
					const calculatedDimensions: DimensionsModel = this.getDimensions();

					if (!settingsDimensions) settingsDimensions = calculatedDimensions;
					else {
						if (!settingsDimensions.width) settingsDimensions.width = calculatedDimensions.width;
					}
				}

				const dimensions: DimensionsModel = settingsDimensions.constrainTo(null, 20);
				let position: PositionModel;

				if (settings.position) {
					position = new PositionModel(settings.position.top, settings.position.left);
					position = PositionService.fitPosition(position, dimensions, DIALOG_MARGIN);
				}

				setTimeout(() => {
					this.dialogStyle = {
						left: position ? position.left + 'px' : undefined,
						top: position ? position.top + 'px' : undefined,
						'max-width': dimensions.width + 'px',
						width: 'auto',
						height: 'auto',
						overflow: 'auto'
					};
					this.changeDetectorRef.detectChanges();

					setTimeout(() => {
						this.isInit = true;
						this.changeDetectorRef.detectChanges();
					}, 40);
				});
			}, 40);
		} else {
			this.dialogStyle = null;
			setTimeout(() => {
				this.isInit = true;
				this.changeDetectorRef.detectChanges();
			}, 40);
		}
	}

	ngOnDestroy(): void {
		try {
			this.changeDetectorRef.detectChanges();
		} catch {}
	}
}
