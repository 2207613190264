/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i4 from "@angular-react/fabric";
import * as i5 from "./command-bar.component";
import * as i6 from "../../main/services/main-app-state.service";
var styles_CommandBarComponent = [];
var RenderType_CommandBarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CommandBarComponent, data: {} });
export { RenderType_CommandBarComponent as RenderType_CommandBarComponent };
export function View_CommandBarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 7, "fab-command-bar", [["data-track-component-type", "Command Bar"]], [[1, "data-track-component", 0]], null, null, i3.View_FabCommandBarComponent_0, i3.RenderType_FabCommandBarComponent)), i0.ɵdid(3, 5947392, null, 3, i4.FabCommandBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { overflowButtonProps: [0, "overflowButtonProps"], ariaLabel: [1, "ariaLabel"], styles: [2, "styles"], items: [3, "items"], farItems: [4, "farItems"], overflowItems: [5, "overflowItems"] }, null), i0.ɵqud(335544320, 1, { itemsDirective: 0 }), i0.ɵqud(335544320, 2, { farItemsDirective: 0 }), i0.ɵqud(335544320, 3, { overflowItemsDirective: 0 }), i0.ɵppd(7, 1), i0.ɵpod(8, { onMenuClick: 0, role: 1, ariaLabel: 2 }), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 8, 0, _co.setCurrentMenuButton, "menuitem", i0.ɵunv(_v, 3, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), _co.overflowButtonAriaLabel))); var currVal_2 = (_co.isAllOverflowItems() ? i0.ɵunv(_v, 3, 1, _ck(_v, 9, 0, i0.ɵnov(_v, 0), "entityCommon.commandBar.instructions")) : ""); var currVal_3 = _co.customStyles; var currVal_4 = _co.getCommandBarItems(); var currVal_5 = _co.getCommandBarFarItems(); var currVal_6 = (_co.isNarrowLayout ? _co._visibleIconOnlyOverflowItems : _co._visibleOverflowItems); _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.id + "_CommandBar"); _ck(_v, 2, 0, currVal_0); }); }
export function View_CommandBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "command-bar", [], null, null, null, View_CommandBarComponent_0, RenderType_CommandBarComponent)), i0.ɵdid(1, 49152, null, 0, i5.CommandBarComponent, [i6.MainAppStateService, i0.ChangeDetectorRef], null, null)], null, null); }
var CommandBarComponentNgFactory = i0.ɵccf("command-bar", i5.CommandBarComponent, View_CommandBarComponent_Host_0, { id: "id", styles: "styles", overflowButtonAriaLabel: "overflowButtonAriaLabel", disableResponsiveBehavior: "disableResponsiveBehavior", forceFarItemsOnEmptyItems: "forceFarItemsOnEmptyItems", onlyIconsScreenBreakpoint: "onlyIconsScreenBreakpoint", items: "items", overflowItems: "overflowItems", farItems: "farItems" }, {}, []);
export { CommandBarComponentNgFactory as CommandBarComponentNgFactory };
