import { Entity, EntityField, EntityModelBase, DataQuery } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { RiskLevelEnum } from './mailbox-risk-level.values';
import { uniq } from 'lodash-es';

@Entity({
	singularName: 'Mailbox',
	pluralName: 'Mailboxes',
	endpoint: 'mailboxes',
	parseDataQuery: (dataQuery: DataQuery) => {
		if (!dataQuery) return {};

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) {
			pageSettings.pageIndex = dataQuery.page;
		}

		if (dataQuery.pageSize) {
			pageSettings.pageSize = dataQuery.pageSize;
		}

		return Object.assign({}, dataQuery.where, pageSettings);
	},
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class Mailbox extends EntityModelBase {
	@EntityField({ data: ['Upn', 'MailboxPrimaryAddress'] })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: 'Upn' })
	readonly upn: string;

	@EntityField({ data: 'DisplayName' })
	readonly displayName: string;

	@EntityField({ data: 'MailboxPageUrl' })
	readonly mailboxPageUrl: string;

	@EntityField({ data: 'MailboxPrimaryAddress' })
	readonly mailboxPrimaryAddress: string;

	@EntityField({ data: 'Urn', required: false })
	readonly urn?: string;

	@EntityField({ data: 'RiskLevel', required: false })
	readonly riskLevel?: RiskLevelEnum;

	@EntityField({
		data: 'RiskKeys',
		parse: keys => {
			return uniq(keys);
		},
		required: false,
	})
	readonly riskI18nKeys?: Array<string>;

	@EntityField({ data: 'RiskyActivities', required: false, defaultValue: 0 })
	readonly riskyActivities?: number;
}
