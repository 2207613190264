var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, ParseDataQuery } from '../../analyticsEndPointUtils';
import { ThreatInfo } from '../../common/threat-info.value-object';
import { WeaknessSeverity } from '../weakness-severity.enum';
import { VulnerabilityInstalledAffectedProduct } from './vulnerability-installed-affected-product.value-object';
import { VulnerabilitySourceInfo } from './vulnerability-source-info.value-object';
import { VulnerabilityType } from '../../common/vulnerability/vulnerability-type.enum';
var WeaknessesSummaryModel = /** @class */ (function () {
    function WeaknessesSummaryModel() {
    }
    return WeaknessesSummaryModel;
}());
export { WeaknessesSummaryModel };
var ɵ0 = function (id) {
    return "vulnerabilities/" + id;
}, ɵ1 = function (dataQuery) { return ParseDataQuery(dataQuery, 'vulnerability'); }, ɵ2 = function (cvssV3) { return cvssV3.toFixed(1); };
var Vulnerability = /** @class */ (function (_super) {
    __extends(Vulnerability, _super);
    function Vulnerability() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "name", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "description", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "severity", void 0);
    __decorate([
        EntityField({
            data: 'numOfImpactedAssets',
        }),
        __metadata("design:type", Number)
    ], Vulnerability.prototype, "impactedAssetsCount", void 0);
    __decorate([
        EntityField({
            data: 'publishedOn',
        }),
        __metadata("design:type", Date)
    ], Vulnerability.prototype, "published", void 0);
    __decorate([
        EntityField({
            data: 'updatedOn',
        }),
        __metadata("design:type", Date)
    ], Vulnerability.prototype, "updated", void 0);
    __decorate([
        EntityField({
            data: 'cvssV3',
            parse: ɵ2,
        }),
        __metadata("design:type", Number)
    ], Vulnerability.prototype, "cvss", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "cvssVersion", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", ThreatInfo)
    ], Vulnerability.prototype, "threatInfo", void 0);
    __decorate([
        EntityField({
            data: 'productIds',
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "productIds", void 0);
    __decorate([
        EntityField({
            data: 'cveSources',
            arrayOf: VulnerabilitySourceInfo,
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "cveSources", void 0);
    __decorate([
        EntityField({
            data: 'installedAffectedProducts',
            arrayOf: VulnerabilityInstalledAffectedProduct,
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "installedAffectedProducts", void 0);
    __decorate([
        EntityField({
            data: 'osFeatures',
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "osFeatures", void 0);
    __decorate([
        EntityField({
            data: 'workarounds',
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "workarounds", void 0);
    __decorate([
        EntityField({
            data: 'mostSevereVulnerabilityType',
        }),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "mostSevereVulnerabilityType", void 0);
    __decorate([
        EntityField({
            data: 'formalTemporaryId',
        }),
        __metadata("design:type", String)
    ], Vulnerability.prototype, "formalTemporaryId", void 0);
    __decorate([
        EntityField({
            data: 'patchReleaseDate',
        }),
        __metadata("design:type", Date)
    ], Vulnerability.prototype, "patchReleaseDate", void 0);
    __decorate([
        EntityField({
            required: false,
        }),
        __metadata("design:type", Array)
    ], Vulnerability.prototype, "threats", void 0);
    Vulnerability = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Vulnerability', pluralName: 'Vulnerabilities', endpoint: 'vulnerabilities', parseItemQuery: ɵ0, parseDataQuery: ɵ1, readonly: true }))
    ], Vulnerability);
    return Vulnerability;
}(EntityModelBase));
export { Vulnerability };
export { ɵ0, ɵ1, ɵ2 };
