import { ICommandBarItemOptions } from '@angular-react/fabric';
import { ICommandBarStyles, IContextualMenuItem, IRefObject } from 'office-ui-fabric-react';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ICommandBarItem } from './models/command-bar-item.models';
import { filter } from 'rxjs/operators';
import { MainAppState, MainAppStateService } from '../../main/services/main-app-state.service';
import { config } from '@wcd/shared';
import { memoize } from 'lodash-es';

export interface ItemKeysOptions {
	readonly itemsKeys?: ICommandBarItem['key'][];
	readonly farItemsKeys?: ICommandBarItem['key'][];
}

@Component({
	selector: 'command-bar',
	template: `
		<fab-command-bar
			data-track-component-type="Command Bar"
			[attr.data-track-component]="id + '_CommandBar'"
			[items]="getCommandBarItems()"
			[overflowItems]="isNarrowLayout ? _visibleIconOnlyOverflowItems : _visibleOverflowItems"
			[farItems]="getCommandBarFarItems()"
			[styles]="customStyles"
			[ariaLabel]="isAllOverflowItems() ? ('entityCommon.commandBar.instructions' | i18n) : ''"
			[overflowButtonProps]="{
				onMenuClick: setCurrentMenuButton,
				role: 'menuitem',
				ariaLabel: overflowButtonAriaLabel | i18n
			}"
		></fab-command-bar>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommandBarComponent {
	private _items: ReadonlyArray<ICommandBarItem>;
	private _overflowItems: ReadonlyArray<ICommandBarItem>;
	private _farItems: ReadonlyArray<ICommandBarItem>;
	private readonly commandBarStyle: ICommandBarStyles = { primarySet: { justifyContent: 'flex-end' } };
	isNarrowLayout: boolean = false;

	_visibleItems: ReadonlyArray<ICommandBarItem>;
	_visibleOverflowItems: ReadonlyArray<ICommandBarItem>;
	_visibleFarItems: ReadonlyArray<ICommandBarItem>;

	// Copies of the original item arrays, with 'iconOnly = true' property
	_visibleIconOnlyItems: ReadonlyArray<ICommandBarItem>;
	_visibleIconOnlyOverflowItems: ReadonlyArray<ICommandBarItem>;
	_visibleIconOnlyFarItems: ReadonlyArray<ICommandBarItem>;

	@Input() id: string;
	@Input() styles: ICommandBarStyles;
	@Input() overflowButtonAriaLabel: string = 'entityCommon.commandBar.moreOptions';
	// Adding this temporary input to disable the responsive behavior because its broken in some places
	// Needs to be removed when its fixed
	@Input() disableResponsiveBehavior: boolean = false;

	// Fix for bug https://microsoft.visualstudio.com/DefaultCollection/OS/_workitems/edit/25779016
	// Use Items instead of far Items to overcome Accessibility issue with empty items.
	// Empty Items add a default div that has role menubar with no children.
	// When this input is True, far items are used as items and the style changes to push the items to right.
	// Use it whenever the visible items might be empty.
	@Input() forceFarItemsOnEmptyItems = false;

	// Bellow this screen width, commands items will be rendered without labels - with icons only.
	@Input() onlyIconsScreenBreakpoint = config.msScreenSizeBreakpoints.small;

	currentMenuButton: HTMLElement;

	constructor(
		mainAppStateService: MainAppStateService,
		private readonly changeDetectorRef: ChangeDetectorRef
	) {
		mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return (
						this.isNarrowLayout !==
						config.widthBreakpointSmallerThan(
							state.screenMaxWidthBreakpoint,
							this.onlyIconsScreenBreakpoint
						)
					);
				})
			)
			.subscribe((state: MainAppState) => {
				this.isNarrowLayout = config.widthBreakpointSmallerThan(
					state.screenMaxWidthBreakpoint,
					this.onlyIconsScreenBreakpoint
				);
				this.changeDetectorRef.markForCheck();
			});
		this.updateVisibleItems = memoize(this.updateVisibleItems);
		this.updateVisibleOverflowItems = memoize(this.updateVisibleOverflowItems);
		this.updateVisibleFarItems = memoize(this.updateVisibleFarItems);
	}

	@Input()
	set items(value: ReadonlyArray<ICommandBarItem>) {
		this._items = this.hiddenMenuItemsFocusHandling(value);
		this.updateVisibleItems(value);
	}

	get items(): ReadonlyArray<ICommandBarItem> {
		return this._items;
	}

	@Input()
	set overflowItems(value: ReadonlyArray<ICommandBarItem>) {
		this._overflowItems = this.hiddenMenuItemsFocusHandling(value);
		this.updateVisibleOverflowItems(value);
	}

	get overflowItems(): ReadonlyArray<ICommandBarItem> {
		return this._overflowItems;
	}

	@Input()
	set farItems(value: ReadonlyArray<ICommandBarItem>) {
		this._farItems = this.hiddenMenuItemsFocusHandling(value);
		this.updateVisibleFarItems(value);
	}

	getCommandBarItems(): ReadonlyArray<ICommandBarItem> {
		if (this.disableResponsiveBehavior) {
			return this._visibleItems;
		}
		if (this.forceFarItemsOnEmptyItems && (!this._visibleItems || !this._visibleItems.length)) {
			return this.isNarrowLayout ? this._visibleIconOnlyFarItems : this._visibleFarItems;
		} else {
			return this.isNarrowLayout ? this._visibleIconOnlyItems : this._visibleItems;
		}
	}

	getCommandBarFarItems(): ReadonlyArray<ICommandBarItem> {
		if (!this.forceFarItemsOnEmptyItems || !this._visibleItems || this._visibleItems.length) {
			return this.isNarrowLayout ? this._visibleIconOnlyFarItems : this._visibleFarItems;
		}
	}

	updateVisibleItems(value) {
		if (value) {
			this._visibleItems = value.filter(item => !item.hidden);
			this._visibleIconOnlyItems = this.toIconOnlyItems(this._visibleItems);
		} else {
			this._visibleItems = null;
			this._visibleIconOnlyItems = null;
		}
	}

	updateVisibleOverflowItems(value) {
		if (value) {
			this._visibleOverflowItems = value.filter(item => !item.hidden);
			this._visibleIconOnlyOverflowItems = this.toIconOnlyItems(this._visibleOverflowItems);
		} else {
			this._visibleOverflowItems = null;
			this._visibleIconOnlyOverflowItems = null;
		}
	}

	updateVisibleFarItems(value) {
		if (value) {
			this._visibleFarItems = value.filter(item => !item.hidden);
			this._visibleIconOnlyFarItems = this.toIconOnlyItems(this._visibleFarItems);
		} else {
			this._visibleFarItems = null;
			this._visibleIconOnlyFarItems = null;
		}
	}

	get farItems(): ReadonlyArray<ICommandBarItem> {
		return this._farItems;
	}

	get visibleItems(): ReadonlyArray<ICommandBarItemOptions> {
		if (!this.items) return undefined;
		return this.items.filter(item => !item.hidden);
	}

	get visibleOverflowItems(): ReadonlyArray<ICommandBarItemOptions> {
		if (!this.overflowItems) return undefined;
		return this.overflowItems.filter(item => !item.hidden);
	}
	get visibleFarItems(): ReadonlyArray<ICommandBarItemOptions> {
		if (!this.farItems) return undefined;
		return this.farItems.filter(item => !item.hidden);
	}

	get customStyles(): Partial<ICommandBarStyles> {
		if (this.forceFarItemsOnEmptyItems && (!this.visibleItems || this.visibleItems.length === 0)) {
			if (!this.styles) {
				return this.commandBarStyle;
			}
			const customStyles: Partial<ICommandBarStyles> = this.styles as ICommandBarStyles;
			customStyles.primarySet = Object.assign(customStyles.primarySet || {}, {
				justifyContent: 'flex-end',
			});
			return customStyles;
		}
		return this.styles;
	}

	setFocusToCurrentMenuItem(element: HTMLElement) {
		if (element && element.classList && element.classList.contains('ms-ContextualMenu-link')) {
			this.currentMenuButton.focus();
		}
	}

	setCurrentMenuButton = (event: Event) => {
		this.currentMenuButton = <HTMLElement>event.currentTarget;
	};

	hiddenMenuItemsFocusHandling = (
		items: ReadonlyArray<ICommandBarItem>
	): ReadonlyArray<ICommandBarItem> => {
		return items
			? items.map(item => {
					if (item && item.onClick) {
						const originalOnClick = item.onClick;
						item.onClick = (
							ev?: React.MouseEvent<HTMLElement, MouseEvent> | React.KeyboardEvent<HTMLElement>,
							contextualMenuItem?: IContextualMenuItem
						) => {
							this.setFocusToCurrentMenuItem(ev.currentTarget);
							return originalOnClick(ev, contextualMenuItem);
						};
					}
					return item;
			  })
			: null;
	};

	toIconOnlyItems = (items: ReadonlyArray<ICommandBarItem>): ReadonlyArray<ICommandBarItem> =>
		items.map((item: ICommandBarItem) => ({
			...item,
			tooltipHostProps: {
				content: item.title,
			},
			ariaLabel: item.ariaLabel || item.title,
			title:'',
			iconOnly: true,
			cacheKey: `${item.key}_icon_only`,
		}));

	isAllOverflowItems(): boolean {
		if (!this._visibleItems || !this.visibleOverflowItems) {
			return false;
		}
		return this._visibleItems.length == this._visibleOverflowItems.length;
	}
}
