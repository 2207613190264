import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { WebContentFilteringPolicy } from './web-content-filtering-policy.entity';
import { WebContentFilteringCategoryReport } from '../reports/web-content-filtering/category-report.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@EntityRelationship({
	sourceEntity: WebContentFilteringCategoryReport,
	dataEntity: WebContentFilteringPolicy,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (report: WebContentFilteringCategoryReport) => ({ IndicatorValue: report.id }),
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	endpoint: 'webcategory/policies',
})
export class WebContentFilteringWebCategoryRelationship
	implements
		EntityRelationshipRepositoryType<WebContentFilteringCategoryReport, WebContentFilteringPolicy> {}
