export type NetworkEventType =
	| 'ConnectionSuccess'
	| 'TcpConnectionSuccess' // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionFailed'
	| 'TcpConnectionFailed' // TODO -  remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'InboundConnectionAccepted'
	| 'InboundTcpConnectionAccepted' // TODO -  remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionRequest'
	| 'TcpConnectionRequest' // TODO -  remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
	| 'ConnectionObserved' // TODO - backward support. remove when this value will disappear from network event type column in kusto (~March 19)
	| 'ConnectionFound';

export function mapNetworkEventTypeToMessage(networkEventType: NetworkEventType | null) {
	let description = 'communicated with';
	switch (networkEventType) {
		case 'TcpConnectionSuccess': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'ConnectionSuccess': {
			description = 'successfully established connection with';
			break;
		}
		case 'TcpConnectionFailed': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'ConnectionFailed': {
			description = 'failed to establish connection with';
			break;
		}
		case 'InboundTcpConnectionAccepted': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
		case 'InboundConnectionAccepted': {
			description = 'accepted connection from';
			break;
		}
	}
	return description;
}
