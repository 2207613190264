import { SevilleAlertsModule } from './seville.alerts.module';

var options = {
	template: `
	<table class="side-pane-props">
      <tbody ng-repeat="item in $ctrl.history track by $index" class="alerts-sidepane-history-item">
          <tr class="side-pane-property alerts-sidepane-history-record">
              <td class="alerts-sidepane-history-icon" ng-switch="item.Type">
                  <i class="icon icon-Comment alert-history-icon" ng-switch-when="Feedback"/>
                  <i class="icon icon-ReminderPerson alert-history-icon" ng-switch-when="AssignedTo" />
                  <i class="icon icon-Clock alert-history-icon" ng-switch-default />
              </td>
              <td class="side-pane-property-value">
                  {{item.Timestamp | sevilleDate}}
              </td>
          </tr>
          <tr class="side-pane-property alerts-sidepane-history-record">
              <td class="alerts-sidepane-history-icon"></td>
              <td class="side-pane-property-value">
                  <span ng-if="item.Type == 'Status' && item.OldValue">
                      {{item.CreatedBy}} Changed status from '{{item.OldValue | status}}' to '{{item.NewValue | status}}'.
                  </span>
                  <span ng-if="item.Type == 'Status' && !item.OldValue">
                      {{item.CreatedBy}} Changed status to '{{item.NewValue | status}}'.
                  </span>
                  <span ng-if="item.Type == 'Classification'">
                      <span ng-if="item.NewValue == 'Unknown'">
                          {{item.CreatedBy}} removed alert classification.
                      </span>
                      <span ng-if="item.NewValue != 'Unknown'">
                          {{item.CreatedBy}} Changed classification to '{{item.NewValue | alertClassification}}'.
                      </span>
                  </span>
                  <span ng-if="item.Type == 'Determination'">
                      {{item.CreatedBy}} Changed determination to '{{item.NewValue | alertDetermination}}'.
                  </span>
                  <span ng-if="item.Type == 'Feedback'">
                      {{item.CreatedBy}} {{item.NewValue}}
                  </span>
                  <span ng-if="item.Type == 'Suppressed'">
                      Alert was suppressed by suppression rule:
                      {{item.NewValue}}
				  </span>
				  <span ng-if="item.Type == 'LinkAlertToIncident'">
				  	  {{item.NewValue}}
                  </span>
                  <span ng-if="item.Type == 'AssignedTo'">
                      <span ng-if="item.NewValue && item.NewValue.length > 0">
                          Alert assigned to {{item.NewValue}}
                      </span>
                      <span ng-if="!item.NewValue || item.NewValue.length == 0">
                          {{item.CreatedBy}} removed alert user assignment.
                      </span>

                  </span>

              </td>
          </tr>
      </tbody>
      <tbody ng-if="$ctrl.firstSeen">
          <tr class="side-pane-property alerts-sidepane-history-record">
              <td class="alerts-sidepane-history-icon">
                  <i class="icon icon-Clock alert-history-icon" />
              </td>
              <td class="side-pane-property-value">
                  {{$ctrl.firstSeen | sevilleDate}}
              </td>
          </tr>
          <tr class="side-pane-property alerts-sidepane-history-record">
              <td class="alerts-sidepane-history-icon"></td>
              <td class="side-pane-property-value">
                  Alert generated.
              </td>
          </tr>
      </tbody>
  </table>
	`,
	bindings: {
		history: '<',
		firstSeen: '<',
	},
	controller: 'alertHistoryController',
};

alertHistoryController.$inject = [];

function alertHistoryController() {}

SevilleAlertsModule.component('alertHistory', options).controller(
	'alertHistoryController',
	alertHistoryController
);
