export enum MachineManagedByStatusType {
	Unknown = "Unknown",
	Success = "Success",
	GeneralError = "GeneralError",
	ConnectivityIssue = "ConnectivityIssue",
	GeneralHybridJoinFailure = "GeneralHybridJoinFailure",
	HybridErrorServiceConnectionPoint = "HybridErrorServiceConnectionPoint",
	DnsError = "DnsError",
	TenantMismatch = "TenantMismatch",
	CertificateError = "CertificateError",
	ClockSyncIssue = "ClockSyncIssue",
	LdapApiError = "LdapApiError",
	OnPremiseSyncIssue = "OnPremiseSyncIssue",
	MemConfigurationIssue = "MemConfigurationIssue",
	AadConnectMisconfiguration = "AadConnectMisconfiguration",
}

export interface IMachineManagedByStatus {
	readonly id: MachineManagedByStatusType;
	name?: string;
	nameI18nKey: string;
	priority: number;
}
