var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Entity } from '@microsoft/paris';
import { EntityModelBase } from '@microsoft/paris';
export var NotificationRuleSeverityConditionValues = [
    { id: 'Informational', name: 'Informational', value: 32 },
    { id: 'Low', name: 'Low', value: 64 },
    { id: 'Medium', name: 'Medium', value: 128 },
    { id: 'High', name: 'High', value: 256 },
];
var NotificationRuleSeverityConditionValue = /** @class */ (function (_super) {
    __extends(NotificationRuleSeverityConditionValue, _super);
    function NotificationRuleSeverityConditionValue() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", String)
    ], NotificationRuleSeverityConditionValue.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], NotificationRuleSeverityConditionValue.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], NotificationRuleSeverityConditionValue.prototype, "value", void 0);
    NotificationRuleSeverityConditionValue = __decorate([
        Entity({
            singularName: 'Notification Rule Severity Condition Value Item',
            pluralName: 'Notification Rule Severity Condition Value Items',
            values: NotificationRuleSeverityConditionValues,
        })
    ], NotificationRuleSeverityConditionValue);
    return NotificationRuleSeverityConditionValue;
}(EntityModelBase));
export { NotificationRuleSeverityConditionValue };
var NotificationRuleServiceSourceConditionValue = /** @class */ (function (_super) {
    __extends(NotificationRuleServiceSourceConditionValue, _super);
    function NotificationRuleServiceSourceConditionValue() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", String)
    ], NotificationRuleServiceSourceConditionValue.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], NotificationRuleServiceSourceConditionValue.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], NotificationRuleServiceSourceConditionValue.prototype, "value", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], NotificationRuleServiceSourceConditionValue.prototype, "children", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], NotificationRuleServiceSourceConditionValue.prototype, "disabled", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], NotificationRuleServiceSourceConditionValue.prototype, "allDetectionSources", void 0);
    return NotificationRuleServiceSourceConditionValue;
}(EntityModelBase));
export { NotificationRuleServiceSourceConditionValue };
var ServiceSourceSeverity = /** @class */ (function (_super) {
    __extends(ServiceSourceSeverity, _super);
    function ServiceSourceSeverity(alertSeverities, serviceSources) {
        var _this = _super.call(this) || this;
        _this.severities = alertSeverities;
        _this.serviceSources = serviceSources;
        return _this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], ServiceSourceSeverity.prototype, "severities", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], ServiceSourceSeverity.prototype, "serviceSources", void 0);
    ServiceSourceSeverity = __decorate([
        ValueObject({
            singularName: 'serviceSourceSeverity',
            pluralName: 'serviceSourcesSeverities',
        }),
        __metadata("design:paramtypes", [Array, Array])
    ], ServiceSourceSeverity);
    return ServiceSourceSeverity;
}(ModelBase));
export { ServiceSourceSeverity };
