/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./pending-actions.component.ngfactory";
import * as i3 from "./pending-actions.component";
import * as i4 from "../services/pending-actions.service";
import * as i5 from "@angular/router";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./pending-actions.page.component";
import * as i8 from "../../../notifications/services/notifications.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../shared/services/title.service";
var styles_PendingActionsPageComponent = [];
var RenderType_PendingActionsPageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PendingActionsPageComponent, data: {} });
export { RenderType_PendingActionsPageComponent as RenderType_PendingActionsPageComponent };
export function View_PendingActionsPageComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 12, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "header", [["class", "page-header wcd-flex-none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(5, null, ["", ""])), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "pending-actions", [["class", "wcd-flex-1"]], null, null, null, i2.View_PendingActionsComponent_0, i2.RenderType_PendingActionsComponent)), i0.ɵdid(10, 573440, null, 0, i3.PendingActionsComponent, [i4.PendingActionsService, i5.Router, i5.ActivatedRoute, i6.I18nService], { pendingActionTypes: [0, "pendingActionTypes"], isLoading: [1, "isLoading"], error: [2, "error"], pollingError: [3, "pollingError"], navClassName: [4, "navClassName"], isRemediationDisabled: [5, "isRemediationDisabled"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pendingActionTypes; var currVal_2 = _co.isLoading; var currVal_3 = _co.error; var currVal_4 = _co.error; var currVal_5 = "wcd-padding-large-left"; var currVal_6 = i0.ɵunv(_v, 10, 5, i0.ɵnov(_v, 11).transform(_co.pendingActionsService.isRemediationDisabled$)); _ck(_v, 10, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 5, 0, i0.ɵnov(_v, 6).transform(_co.titleService.state$)).pageTitle; _ck(_v, 5, 0, currVal_0); }); }
export function View_PendingActionsPageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "pending-actions-page", [], null, null, null, View_PendingActionsPageComponent_0, RenderType_PendingActionsPageComponent)), i0.ɵdid(1, 245760, null, 0, i7.PendingActionsPageComponent, [i4.PendingActionsService, i8.NotificationsService, i9.FeaturesService, i10.TitleService, i5.Router, i5.ActivatedRoute, i6.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PendingActionsPageComponentNgFactory = i0.ɵccf("pending-actions-page", i7.PendingActionsPageComponent, View_PendingActionsPageComponent_Host_0, {}, {}, []);
export { PendingActionsPageComponentNgFactory as PendingActionsPageComponentNgFactory };
