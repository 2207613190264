var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, EventEmitter, Injector, OnDestroy, OnInit, Type, ViewContainerRef, } from '@angular/core';
import { Router } from '@angular/router';
import { EntityModelBase, Paris } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import { BehaviorSubject, defer, merge, Observable, of, Subject, Subscription, throwError, combineLatest, } from 'rxjs';
import { every, finalize, share, startWith, switchMap, take, tap, shareReplay, map } from 'rxjs/operators';
import { Tag } from '@wcd/domain';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { RbacService } from '../../../rbac/services/rbac.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from "@angular/cdk/a11y";
var EntityPanelComponent = /** @class */ (function (_super) {
    __extends(EntityPanelComponent, _super);
    function EntityPanelComponent(router, resolver, paris, changeDetectorRef, i18nService, rbacService, liveAnnouncer) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.resolver = resolver;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.i18nService = i18nService;
        _this.rbacService = rbacService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.extendEntity = true;
        _this.disableTrapFocus = false;
        _this.disableAutoFocus = false;
        _this.actionRun = new EventEmitter();
        _this.loadEntityRbacError = false;
        _this._tagsSubject$ = new Subject();
        _this.isUserExposed$ = new BehaviorSubject(false);
        _this.onAction$ = new Subject();
        _this._isLoadingEntity = false;
        _this.isLoadingEntitySubject$ = new BehaviorSubject(false);
        return _this;
    }
    Object.defineProperty(EntityPanelComponent.prototype, "isLoadingEntity", {
        get: function () {
            return this._isLoadingEntity;
        },
        set: function (value) {
            this._isLoadingEntity = value;
            this.isLoadingEntitySubject$.next(value);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityPanelComponent.prototype, "isSingleEntity", {
        get: function () {
            return this.entities && this.entities.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityPanelComponent.prototype, "entitiesDetailsComponent", {
        get: function () {
            var entitiesPanelComponentRef = this.entityPanelComponent || this.entitiesPanelComponent;
            return entitiesPanelComponentRef && entitiesPanelComponentRef.instance;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityPanelComponent.prototype, "entitySingularName", {
        get: function () {
            var key = this.entityType.entitySingularNameKey;
            return key ? this.i18nService.get(key) : this.entityType.entity.singularName;
        },
        enumerable: true,
        configurable: true
    });
    EntityPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.update();
    };
    EntityPanelComponent.prototype.ngOnDestroy = function () {
        this._loadEntitySubscription && this._loadEntitySubscription.unsubscribe();
        this._entitiesExposureSubscription && this._entitiesExposureSubscription.unsubscribe();
        this.entities = null;
    };
    EntityPanelComponent.prototype.update = function () {
        if (this.entity && !this.entities) {
            this.entities = [this.entity];
        }
        this.setEntityDetailsComponent();
        this.setEntities(this.entities);
    };
    EntityPanelComponent.prototype.ngOnChanges = function (changes) {
        this.update();
    };
    EntityPanelComponent.prototype.setContextLog = function (context) {
        this.contextLog = context;
    };
    EntityPanelComponent.prototype.setEntity = function (entity, loadExtendedData, allowCache, isSameEntities) {
        if (loadExtendedData === void 0) { loadExtendedData = true; }
        if (allowCache === void 0) { allowCache = true; }
        if (isSameEntities === void 0) { isSameEntities = false; }
        this.setEntities([entity], loadExtendedData, allowCache, isSameEntities);
    };
    //TODO: setEntities gets called twice on panel opening (e.g. machine panel), causes bad jittering experience and added complexity!
    EntityPanelComponent.prototype.setEntities = function (entities, loadExtendedData, allowCache, isSameEntities) {
        var _this = this;
        if (loadExtendedData === void 0) { loadExtendedData = true; }
        if (allowCache === void 0) { allowCache = true; }
        if (isSameEntities === void 0) { isSameEntities = false; }
        loadExtendedData = entities && entities.length > 1 ? false : loadExtendedData;
        this.entities = entities;
        this.isLoadingEntity = false;
        this.updatePanelMetadata(loadExtendedData);
        var tagsSources = [
            this.entityType.getTags ? toObservable(this.entityType.getTags(this.entities)) : null,
            this._tagsSubject$,
        ];
        this.tags$ = merge.apply(void 0, tagsSources.filter(Boolean)).pipe(shareReplay(1));
        /**
         * the tags list shows merge of the tags and the pseudo tags. the tags edit component shows/edits only real tags.
         */
        var tagsWithPseudoTagsSources = [
            this.tags$,
            this.entityType.pseudoTags ? this.entityType.pseudoTags.get(entities) : null,
        ];
        this.tagsWithPseudoTags$ = combineLatest.apply(void 0, tagsWithPseudoTagsSources.filter(Boolean)).pipe(map(function (_a) {
            var realTags = _a[0], pseudoTags = _a[1];
            return (pseudoTags ? pseudoTags : []).concat(realTags ? realTags : []);
        }), shareReplay(1));
        this.entityImage$ = this.entityType.getPanelImage
            ? toObservable(this.entityType.getPanelImage(this.entities)).pipe(take(1), share())
            : null;
        // By update this property to the current date, entity-command-bar component will call to 'setActions' method.
        this.lastSetActionsTime = new Date();
        if (isSameEntities) {
            this.setEntityDetailsComponent(false, !loadExtendedData);
        }
        else {
            if (this._entitiesExposureSubscription) {
                this._entitiesExposureSubscription.unsubscribe();
            }
            if (entities && entities.length > 1) {
                this.isUserExposed$.next(false);
                this._entitiesExposureSubscription = merge.apply(void 0, entities.map(function (entity) {
                    return _this.rbacService.isUserExposedToEntity(_this.entityType, entity);
                })).pipe(every(function (item) { return item.isExposed; }))
                    .subscribe(function (isUserExposed) { return _this.isUserExposed$.next(isUserExposed); }, function (error) { return _this.isUserExposed$.error(error); });
            }
            if (this._loadEntitySubscription) {
                this._loadEntitySubscription.unsubscribe();
            }
            this.setEntityDetailsComponent(true, !loadExtendedData);
            if (loadExtendedData && this.entityType.loadFullEntityInPanel !== false) {
                this.loadEntity(allowCache);
            }
            else {
                this.setIsUserEntityIsExposedToEntity().subscribe();
            }
        }
        this.changeDetectorRef.markForCheck();
    };
    EntityPanelComponent.prototype.updatePanelMetadata = function (loadExtendedData) {
        var entityName = this.getName();
        this.entityName =
            ((loadExtendedData || this.entities.length > 1) && entityName) || this.entityName || '';
        this.entitySubtitle = this.entityType.getSubtitle ? this.entityType.getSubtitle(this.entities) : null;
        this.entityStatusPlaceholder.clear();
        if (this.entityType.entityPanelStatusComponentType && this.entities.length === 1) {
            var factory = this.resolver.resolveComponentFactory(this.entityType.entityPanelStatusComponentType);
            var statusComponentRef = this.entityStatusPlaceholder.createComponent(factory);
            statusComponentRef.instance.entity = this.entities[0];
        }
    };
    EntityPanelComponent.prototype.setIsUserEntityIsExposedToEntity = function () {
        var _this = this;
        this.isUserExposed$.next(false);
        return this.rbacService.isUserExposedToEntity(this.entityType, this.entities[0]).pipe(map(function (item) { return item.isExposed; }), tap(function (isUserExposedToEntity) { return _this.isUserExposed$.next(isUserExposedToEntity); }));
    };
    EntityPanelComponent.prototype.loadEntity = function (allowCache) {
        var _this = this;
        if (allowCache === void 0) { allowCache = true; }
        if (this._loadEntitySubscription) {
            this._loadEntitySubscription.unsubscribe();
        }
        this.loadEntityError = this.loadEntityRbacError = false;
        if (!this.entities || this.entities.length !== 1) {
            this.isLoadingEntity = false;
            this.changeDetectorRef.markForCheck();
            return;
        }
        this.isLoadingEntity = true;
        this.changeDetectorRef.detectChanges();
        this._loadEntitySubscription = this.setIsUserEntityIsExposedToEntity()
            .pipe(switchMap(function (isUserExposedToEntity) {
            if (isUserExposedToEntity) {
                return _this.getEntity(allowCache);
            }
            else {
                return throwError({ isUserExposed: false });
            }
        }), finalize(function () {
            _this.isLoadingEntity = false;
            _this.changeDetectorRef.markForCheck();
        }), switchMap(function (entity) {
            if (!_this.entityType.getEntityPolling) {
                return of(entity);
            }
            var entityGetter$ = _this.getEntity(false);
            if (entity) {
                entityGetter$ = entityGetter$.pipe(startWith(entity));
            }
            return _this.entityType.getEntityPolling(entityGetter$).pipe(startWith(entity));
        }))
            .subscribe(function (entity) {
            _this.setEntity(entity, false, undefined, true);
        }, function (error) {
            if (error && error.isUserExposed === false) {
                _this.loadEntityRbacError = true;
            }
            else {
                _this.loadEntityError = true;
            }
        });
    };
    EntityPanelComponent.prototype.getEntity = function (allowCache) {
        var _this = this;
        if (!this.extendEntity && this.entity) {
            return of(this.entity);
        }
        var loadedEntity$ = defer(function () {
            var params$ = toObservable(_this.entityType.getItemParams
                ? _this.entityType.getItemParams(_this.entities[0], _this.options)
                : null);
            return params$.pipe(switchMap(function (params) {
                return _this.paris.getItemById(_this.entityType.entity, (!isNil(_this.entityId) ? _this.entityId : _this.entities[0].id), { allowCache: allowCache }, params);
            }));
        });
        return this.entityType.getItem
            ? (toObservable(this.entityType.getItem(this.entities[0], loadedEntity$, this.options, allowCache)))
            : loadedEntity$;
    };
    EntityPanelComponent.prototype.onRunAction = function (action, data, tags) {
        this.actionRun.emit(action);
        var shouldClose;
        if (tags) {
            this._tagsSubject$.next(tags);
        }
        try {
            shouldClose =
                typeof action.closeOnAction === 'function'
                    ? action.closeOnAction(data)
                    : action.closeOnAction;
        }
        catch (_a) {
            shouldClose = false;
        }
        if (shouldClose)
            this.closePanel();
        else {
            this.onAction$.next({ action: action, data: data });
            if (action.refreshEntityPanelOnResolve) {
                this.update();
            }
        }
        this.changeDetectorRef.markForCheck();
    };
    EntityPanelComponent.prototype.setEntityDetailsComponent = function (destroy, isExtendedData) {
        if (destroy === void 0) { destroy = true; }
        if (destroy) {
            this.destroyEntityDetailsComponent();
            this.createEntitiesDetailsComponent();
        }
        else {
            if (this.entityPanelComponent && this.isSingleEntity) {
                this.entityPanelComponent.instance.setEntity(this.entities[0], isExtendedData);
            }
            else if (this.entitiesPanelComponent && !this.isSingleEntity) {
                this.entitiesPanelComponent.instance.setEntities(this.entities);
            }
            else {
                this.setEntityDetailsComponent(true);
            }
        }
    };
    EntityPanelComponent.prototype.createEntitiesDetailsComponent = function () {
        var _this = this;
        this.isLoadingEntity = true;
        this.changeDetectorRef.detectChanges();
        var injector = Injector.create({
            providers: [],
            parent: this.entityDetailsPlaceholder.parentInjector,
        }), detailsComponentType = this
            .isSingleEntity
            ? this.entityType.singleEntityPanelComponentType
            : this.entityType.multipleEntitiesComponentType, factory = detailsComponentType
            ? this.resolver.resolveComponentFactory(detailsComponentType)
            : null, detailsComponentRef = factory
            ? factory.create(injector)
            : null;
        this.entityPanelComponent = this.isSingleEntity
            ? detailsComponentRef
            : null;
        this.entitiesPanelComponent = this.isSingleEntity
            ? null
            : detailsComponentRef;
        if (detailsComponentRef) {
            var panelInputs = this.isSingleEntity
                ? { entity: this.entities[0], entities: this.entities, contextLog: this.contextLog }
                : { entities: this.entities };
            Object.assign(detailsComponentRef.instance, {
                options: this.options || {},
                action$: this.onAction$.asObservable(),
                isUserExposed$: this.isUserExposed$,
                isLoadingEntity$: this.isLoadingEntitySubject$.asObservable(),
            }, panelInputs);
            detailsComponentRef.instance.requestAction.subscribe(function ($event) {
                _this.detailsActionId = $event.actionId;
                _this.lastDetailsActionTime = new Date();
            });
            detailsComponentRef.instance.refreshOnResolve.subscribe(function ($event) {
                _this.actionRun.emit($event);
            });
            detailsComponentRef.instance.requestEntitiesRefresh.subscribe(function () { return _this.loadEntity(false); });
            detailsComponentRef.instance.requestMetadataRefresh.subscribe(function () {
                return _this.updatePanelMetadata(true);
            });
            this.entityDetailsPlaceholder.insert(detailsComponentRef.hostView);
        }
        setTimeout(function () {
            _this.isLoadingEntity = false;
            _this.changeDetectorRef.markForCheck();
        });
    };
    EntityPanelComponent.prototype.destroyEntityDetailsComponent = function () {
        if (this.entitiesDetailsComponent) {
            if (this.entityPanelComponent) {
                this.entityPanelComponent.destroy();
                this.entityPanelComponent = null;
            }
            if (this.entitiesPanelComponent) {
                this.entitiesPanelComponent.destroy();
                this.entitiesPanelComponent = null;
            }
        }
    };
    EntityPanelComponent.prototype.getName = function () {
        var count = this.entities.length;
        if (count === 1)
            return this.entityType.getEntityName
                ? this.entityType.getEntityName(this.entities[0])
                : this.entityType.entitySingularNameKey
                    ? this.i18nService.get(this.entityType.entitySingularNameKey)
                    : this.entityType.entity.singularName;
        var itemType = this.entityType.entityPluralNameKey
            ? this.i18nService.get(this.entityType.entityPluralNameKey)
            : this.entityType.entity.pluralName;
        return this.i18nService.get('panel.title.multiple.selected', { count: count, itemType: itemType });
    };
    return EntityPanelComponent;
}(PanelContainer));
export { EntityPanelComponent };
