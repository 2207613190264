import { Injectable } from '@angular/core';
import { AirsEntityRemediationStatus, RemediationStatusType } from '@wcd/domain';
import { ColorsService } from './colors.service';
import { KnownColorsService } from './known-colors.service';
import {
	OTHER_STATUS_TYPE,
	OTHER_STATUS_TYPE_NAME,
} from '../../reports/components/evidence-summary.component';
import { DataEntityType } from '@microsoft/paris';
import { IPalette } from 'office-ui-fabric-react';

const evidenceTypeColorName = (<DataEntityType>AirsEntityRemediationStatus).entityConfig.values.reduce(
	(acc, status) => {
		acc[status.type] = status.paletteColor;
		return acc;
	},
	{
		[OTHER_STATUS_TYPE_NAME]: 'neutralLight',
	} as Record<RemediationStatusType | OTHER_STATUS_TYPE, keyof IPalette>
);

@Injectable()
export class EvidenceTypeColorService extends ColorsService<RemediationStatusType | OTHER_STATUS_TYPE> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, evidenceTypeColorName);
	}
}
