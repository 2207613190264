
		<div class="wcd-padding-all wcd-padding-large-horizontal">
			<div *ngIf="associatedAlertsRepo?.sourceItem.iocConditionsNotEmpty; else noIocUseLabel">
				<div *ngIf="associatedAlertsRepo?.sourceItem.ruleType === suppressionRuleType.MDE; else defaultRuleConditions">
					<mde-suppression-rule-ioc-conditions [mdeConditions]="associatedAlertsRepo.sourceItem.complexConditions" [conditionTypes]="suppressionRuleConditionTypes"></mde-suppression-rule-ioc-conditions>
				</div>
				<ng-template #defaultRuleConditions>
					<div *ngIf="associatedAlertsRepo?.sourceItem.ioaDefinitionId; else noIocUseLabel">
						<wcd-datatable [items]="tableItems" [columns]="tableFields" [selectEnabled]="false"></wcd-datatable>
					</div>
				</ng-template>
			</div>
			<ng-template #noIocUseLabel>
				<div>
					{{'suppressionRules.edit.noIocUse' | i18n}}
				</div>
			</ng-template>
		</div>
	