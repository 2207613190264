import { ProtectionLevelEnum } from './protection-level-enum';

export const protectionLevelValues = [
	{
		id: ProtectionLevelEnum.NotProtected,
		nameKey: 'machineGroup_edit_noAutomated',
		hostEdit: false,
		descriptionKey: 'remediationLevel_description_noAutomated',
		order: 0,
	},
	{
		id: ProtectionLevelEnum.RequireApproval,
		nameKey: 'machineGroup_edit_allFolders',
		isDefault: true,
		hostEdit: true,
		descriptionKey: 'remediationLevel_description_allFolders',
		order: 1,
	},
	{
		id: ProtectionLevelEnum.FullyAutomated,
		nameKey: 'machineGroup_edit_threatsAuto',
		hostEdit: true,
		descriptionKey: 'remediationLevel_description_threatsAuto',
		order: 4,
	},
	{
		id: ProtectionLevelEnum.NonTempApproval,
		nameKey: 'machineGroup_edit_nonTempFolders',
		hostEdit: true,
		descriptionKey: 'remediationLevel_description_nonTempFolders',
		order: 2,
	},
	{
		id: ProtectionLevelEnum.CoreApproval,
		nameKey: 'machineGroup_edit_coreFolders',
		hostEdit: true,
		descriptionKey: 'remediationLevel_description_coreFolders',
		order: 3,
	},
];
