import { EventEmitter } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Disableable } from '../models/disableable.interface';
import { I18nService } from '@wcd/i18n';
var SPACE_KEY_CODE = 32;
var lastId = 0;
var ToggleComponent = /** @class */ (function () {
    function ToggleComponent(i18nService) {
        this.i18nService = i18nService;
        this.id = "checkbox-toggle-" + lastId++;
        this.trueLabel = this.i18nService.strings.common_yes;
        this.falseLabel = this.i18nService.strings.common_no;
        this.change = new EventEmitter();
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(ToggleComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            if (checked != this.checked)
                this._checked = checked;
        },
        enumerable: true,
        configurable: true
    });
    ToggleComponent.prototype.writeValue = function (checked) {
        this.checked = !!checked;
    };
    ToggleComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ToggleComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Toggles the `checked` value between true and false
     */
    ToggleComponent.prototype.toggle = function () {
        this.checked = !this.checked;
    };
    /**
     * Event handler for checkbox input element.
     * Toggles checked state if element is not disabled.
     * @param event
     */
    ToggleComponent.prototype._onInteractionEvent = function (event) {
        // We always have to stop propagation on the change event.
        // Otherwise the change event, from the input element, will bubble up and
        // emit its event object to the `change` output.
        event.stopPropagation();
        event.preventDefault();
        if (!this.isDisabled) {
            this.toggle();
            this.onChange(this.checked);
            this.change.emit(this.checked);
        }
    };
    ToggleComponent.prototype.onKeyDown = function ($event) {
        if ($event.keyCode === SPACE_KEY_CODE)
            this._onInteractionEvent(event);
    };
    return ToggleComponent;
}());
export { ToggleComponent };
