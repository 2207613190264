import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { ItsmTool } from './remediation-task-ticket-itsm-tool.enum';
import { RemediationTicketStateValue } from './remediation-ticket-state-value.enum';
import { RemediationErrorDetails } from './remediation-error-details.value-object';

@ValueObject({
	singularName: 'Ticket',
	pluralName: 'Tickets',
})
export class RemediationTicket extends ModelBase {
	@EntityField({
		data: 'itsmTool',
	})
	readonly itsmTool: ItsmTool;

	@EntityField({
		data: 'status',
	})
	readonly status?: RemediationTicketStateValue;

	@EntityField({
		data: 'itNotes',
	})
	readonly itNotes?: string;

	@EntityField({
		data: 'deepLink',
	})
	readonly deepLink?: string;

	@EntityField({
		data: 'assignedTo',
	})
	readonly assignedTo?: string;

	@EntityField({
		data: 'rawStatus',
	})
	readonly rawStatus?: string;

	@EntityField({
		data: 'errorDetails',
	})
	readonly ErrorDetails?: RemediationErrorDetails;
}
