/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "../../services/alert-incident-graph-helper.service";
import * as i8 from "../../../files/services/files.service";
import * as i9 from "../../../machines/services/machines.service";
import * as i10 from "../../../ips/services/ips.service";
import * as i11 from "../../services/alerts.service";
import * as i12 from "@angular/router";
import * as i13 from "./alert-incident-graph.component";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "../../../../global_entities/services/activated-entity.service";
var styles_AlertIncidentGraphComponent = [".graph-error[_ngcontent-%COMP%] {\n\t\t\t\tmargin-top: 20px;\n\t\t\t\tmargin-left: 15px;\n\t\t\t}"];
var RenderType_AlertIncidentGraphComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_AlertIncidentGraphComponent, data: {} });
export { RenderType_AlertIncidentGraphComponent as RenderType_AlertIncidentGraphComponent };
function View_AlertIncidentGraphComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "wcd-full-height loading-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "large-loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], null, null); }
function View_AlertIncidentGraphComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-padding-all"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "wcd-shared-icon", [["iconName", "error"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(5, 1)], function (_ck, _v) { var currVal_0 = "error"; _ck(_v, 3, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 4, 0, _ck(_v, 5, 0, i0.ɵnov(_v.parent, 0), "alert.tabs.graph.error")); _ck(_v, 4, 0, currVal_1); }); }
export function View_AlertIncidentGraphComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), i0.ɵqud(402653184, 1, { graphContainerEl: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, [[1, 0], ["graphContainer", 1]], null, 7, "div", [["class", "wcd-flex-1 wcd-scroll-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertIncidentGraphComponent_1)), i0.ɵdid(8, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertIncidentGraphComponent_2)), i0.ɵdid(11, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading && !_co.error); _ck(_v, 8, 0, currVal_0); var currVal_1 = _co.error; _ck(_v, 11, 0, currVal_1); }, null); }
export function View_AlertIncidentGraphComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "alert-incident-graph", [], null, null, null, View_AlertIncidentGraphComponent_0, RenderType_AlertIncidentGraphComponent)), i0.ɵprd(512, null, i7.AlertIncidentGraphHelperService, i7.AlertIncidentGraphHelperService, [i8.FilesService, i9.MachinesService, i10.IpsService, i11.AlertsService, i12.Router]), i0.ɵdid(2, 4440064, null, 0, i13.AlertIncidentGraphComponent, [i0.ChangeDetectorRef, i14.Paris, i15.ActivatedEntity, i7.AlertIncidentGraphHelperService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var AlertIncidentGraphComponentNgFactory = i0.ɵccf("alert-incident-graph", i13.AlertIncidentGraphComponent, View_AlertIncidentGraphComponent_Host_0, { alert: "alert" }, {}, []);
export { AlertIncidentGraphComponentNgFactory as AlertIncidentGraphComponentNgFactory };
