import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

export enum AuthenticationParamsSerializationType {
	SnmpAuthParams = 'SnmpAuthParams',
	WindowsAuthParams = 'WindowsAuthParams',
}

@ValueObject({
	singularName: 'Authentication parameters',
	pluralName: 'Authentication parameters',
})
export class AuthenticationParams extends ModelBase {
	constructor(type: AuthenticationParamsSerializationType, previous?: AuthenticationParams) {
		super();

		this.$type = type;

		if (!!previous) {
			this.isKeyVault = previous.isKeyVault;
			this.keyVaultUri = previous.keyVaultUri;
			this.keyVaultSecretName = previous.keyVaultSecretName;
		}
	}

	@EntityField()
	// this is type related metadata for BE use, and is not part of the core schema
	$type: AuthenticationParamsSerializationType;

	@EntityField({ serialize: false	})
	isKeyVault: boolean;

	@EntityField()
	keyVaultUri: string;

	@EntityField()
	keyVaultSecretName: string;
}
