<h2 class="wcd-font-weight-bold">
	{{ 'email_notification_wizard_settings_step_title' | i18n }}
</h2>
<div class="wcd-margin-large-top wcd-padding-large-bottom form-group">
	<div class="form-group" *ngIf="tenantHasMachineGroups && isDeviceScopeEnabled">
		<div class="wcd-font-weight-semibold spaced-title" id="deviceGroupScope_title">
			{{ 'email_notification_wizard_deviceGroupScope_title' | i18n }}
		</div>
		<div class="wcd-margin-large-bottom">
			<ng-container *ngIf="loadingMachineGroups; else machineGroupsFields">
				<div>
					<i class="loader-icon wcd-margin-right"></i>
					{{ 'notificationRules_machineGroups_loading' | i18n }}
				</div>
			</ng-container>
			<ng-template #machineGroupsFields>
				<ng-container *ngIf="allowSpecificMachineGroups; else allMachineGroupsLabel">
					<ng-container *ngIf="allowAllMachineGroups; else forceSpecificMachineGroupsSelect">
						<radio-list
							[values]="selectableMachineGroupScopes"
							name="notification-rule-machine-group-scope"
							(ngModelChange)="onMachineGroupScopeChange($event)"
							[(ngModel)]="currentMachineGroupScope"
							liCustomClass="spaced-li"
							ariaLabelledby="deviceGroupScope_title">
							<ng-template #radioContent let-scope>
								<div class="nested-component">
									{{ (scope.id === 'all' ? 'email_notification_wizard.deviceGroupScopeOptionDescription_all' : 'email_notification_wizard.deviceGroupScopeOptionDescription_specific') | i18n}}
								</div>
							</ng-template>
						</radio-list>
						<ng-container *ngIf="currentMachineGroupScope?.id === 'specific'">
							<ng-container *ngTemplateOutlet="specificMachineGroups"></ng-container>
						</ng-container>
					</ng-container>
					<ng-template #forceSpecificMachineGroupsSelect>
						<div class="wcd-margin-small-bottom">
							{{'email_notification_wizard.deviceGroupScopeOptionDescription_specific' | i18n}}
						</div>
						<ng-container *ngTemplateOutlet="specificMachineGroups"></ng-container>
					</ng-template>
				</ng-container>
				<ng-template #allMachineGroupsLabel>
					{{ 'notificationRules_machineGroups_allDevices' | i18n }}
				</ng-template>
				<ng-template #specificMachineGroups>
					<div [ngClass]="{ 'nested-component': allowAllMachineGroups }">
						<wcd-checklist-dropdown
							id="'notification-rule-machine-groups'"
							[buttonText]="machineGroupLabelText"
							name="notification-rule-machine-groups"
							[isFullWidth]="true"
							[selectById]="false"
							[ngModel]="currentMachineGroups"
							(ngModelChange)="onMachineGroupsChange($event)"
							[values]="availableMachineGroups">
						</wcd-checklist-dropdown>
					</div>
				</ng-template>
			</ng-template>
		</div>
	</div>
	<div class="wcd-font-weight-semibold spaced-title border-separator wcd-padding-large-top">
		{{ 'vulnerability_email_notification_wizard_eventType_title' | i18n }}
	</div>
	<ng-container *ngFor="let eventType of changeEventTypes">
		<div class="wcd-margin-medium-bottom">
			<wcd-checkbox
				[name]="eventType"
				[label]="eventTypeLabels[eventType]"
				(ngModelChange)="onEventTypeChange(eventType, $event)"
				[(ngModel)]="data.eventTypes[eventType]">
				<ng-container *ngIf="eventType === 'NewCve'">
					<wcd-help [text]="'tvm_notifications_vulnerability_NewCve_tooltip' | i18n"></wcd-help>
				</ng-container>
			</wcd-checkbox>
            <div *ngIf="eventType === 'NewCve'" class="nested-component wcd-flex-horizontal">
                <wcd-checkbox
                    name="new-vulnerability-severity-threshold-checkbox"
                    [label]="'vulnerability_notifications_severity_threshold' | i18n"
                    [isDisabled]="!shouldShowSeverityCheckBox"
                    [(ngModel)]="shouldShowSeveritySelection"
                    (ngModelChange)="onSeverityCheckboxSelection()"
                    class="nested-checkbox">
                </wcd-checkbox>
                <fancy-select
                    [values]="severityLevels"
                    isBordered="true"
                     labelClass="dropdown-width-small-medium"
                    [label]="'name'"
                    name="new-vulnerability-severity-threshold"
                    [isDisabled]="!shouldShowSeveritySelection || !shouldShowSeverityCheckBox"
                    class="fancy-select"
                    [(ngModel)]="currentSeverity"
                    (ngModelChange)="updateSeverityLevel()">
                </fancy-select>
                <div
                    class="nested-label"
                    [ngClass]="{ 'disabled': !shouldShowSeveritySelection || !shouldShowSeverityCheckBox }">
                    {{ severityNote }}
                </div>
            </div>
		</div>
	</ng-container>
	<div class="border-separator form-group wcd-padding-large-top">
		<wcd-checkbox
			[(ngModel)]="data.includeOrgName"
			name="vulnerability-email-notification-include-org-name"
			[label]="'email_notification_include_org_name' | i18n"
			(change)="onModelChange()">
		</wcd-checkbox>
	</div>
</div>
