var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AlertDetermination, AlertClassification, IncidentStatus, IncidentStatusValueIds, IncidentStatusType, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Feature, PreferencesService, FeaturesService } from '@wcd/config';
import { NgForm } from '@angular/forms';
import { AssigneePickerComponent } from '../../../forms/components/assignee-picker.component';
import { combinedClassifications, getCombinedClassificationMap } from '../../alerts/services/alerts.service';
var MULTIPLE_ASSIGNEES = 'multipleAssignees';
var lastId = 0;
var IncidentSetClassificationComponent = /** @class */ (function (_super) {
    __extends(IncidentSetClassificationComponent, _super);
    function IncidentSetClassificationComponent(router, paris, i18nService, changeDetection, preferencesService, featuresService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.changeDetection = changeDetection;
        _this.preferencesService = preferencesService;
        _this.featuresService = featuresService;
        _this.updateIncidentsEvent = new EventEmitter();
        _this.updatedDetermination = false;
        _this.isSavingComment = false;
        _this.assignedTo = {};
        _this.assignedToUpdated = false;
        _this.isAssignToSomeoneElseEnabled = false;
        _this.isCombinedClassificationEnabled = false;
        _this.classificationUpdated = false;
        _this.combinedClassificationMap = [];
        _this.labelId = "editable-comment-label-" + lastId++;
        _this.onSelectAssignee = function (assignee) {
            _this.assignedTo = assignee;
            _this.assignedToUpdated = true;
            _this.changeDetection.detectChanges();
        };
        /**
         * updates the incident object with the user selected values.
         * applied after incident update was triggered.
         */
        _this.updateIncidentsDetails = function (incident) {
            if (_this.incidentClassification) {
                incident.classification = _this.incidentClassification;
            }
            if (_this.determination || _this.updatedDetermination) {
                incident.determination = _this.determination;
                _this.updatedDetermination = false;
            }
            if (_this.incidentStatus) {
                incident.status = _this.incidentStatus;
            }
            if (_this.assignedToUpdated) {
                incident.assignedTo = _this.assignedTo ? _this.assignedTo.secondaryText : '';
            }
        };
        _this.isAssignToSomeoneElseEnabled = _this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
        _this.isCombinedClassificationEnabled = _this.featuresService.isEnabled(Feature.CombinedClassification);
        _this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(function (value) { return _this.isCombinedClassificationEnabled || !value.combinedClassification; });
        _this.selectableAlertClassifications = paris.getRepository(AlertClassification).entity.values.filter(function (value) { return _this.isCombinedClassificationEnabled || !value.combinedClassification; });
        _this.isInProgressIncidentStatusEnabled = _this.featuresService.isEnabled(Feature.InProgressIncidentStatus);
        _this.incidentStatuses = _this.isInProgressIncidentStatusEnabled ? paris.getRepository(IncidentStatus).entity.values :
            paris.getRepository(IncidentStatus).entity.values.filter(function (status) { return status.type !== IncidentStatusType.InProgress; });
        _this.commentPlaceholder = i18nService.get('incident.setClassification.commentPlaceHolder');
        _this.combinedClassificationMap = getCombinedClassificationMap(_this.i18nService);
        _this.selectedCombinedClassification = _this.combinedClassificationMap[0].key;
        return _this;
    }
    IncidentSetClassificationComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.incidents.length === 1) {
            this.incidentStatus = this.incidents[0].status;
            this.incidentClassification = this.incidents[0].classification;
            this.determination = this.incidents[0].determination;
            var foundClassification = this.combinedClassificationMap.find(function (classification) {
                return classification.key === _this.incidents[0].determination.type;
            });
            this.selectedCombinedClassification = foundClassification && foundClassification.key;
        }
        this.assignedTo = this.getAssignedTo();
        this.isSaving = false;
        this.submitButtonText = this.i18nService.get('incident.setClassification.save');
        this.submitButtonText = this.i18nService.get('incident.setClassification.save');
    };
    IncidentSetClassificationComponent.prototype.getAssignedTo = function () {
        var _this = this;
        var assignee = this.incidents.length === 1 ? this.incidents[0].assignedTo :
            this.incidents.every(function (incident) { return incident.assignedTo === _this.incidents[0].assignedTo; }) ? this.incidents[0].assignedTo : MULTIPLE_ASSIGNEES;
        return assignee && {
            secondaryText: assignee
        };
    };
    IncidentSetClassificationComponent.prototype.onNewClassificationDropdownChange = function (event) {
        this.selectedCombinedClassification = event.option.key;
        var _a = combinedClassifications[event.option.key], classification = _a.classification, determination = _a.determination;
        this.incidentClassification = this.selectableAlertClassifications.find(function (alertClass) { return alertClass.id === classification; });
        this.determination = this.allAlertDeterminations.find(function (alertDet) { return alertDet.id === determination; });
        this.classificationUpdated = true;
        this.changeDetection.detectChanges();
    };
    IncidentSetClassificationComponent.prototype.onClassificationChange = function (param) {
        if (this.incidentClassification.id === '0') {
            this.determination = null;
            this.updatedDetermination = true;
        }
    };
    /**
     * Once the save button was pressed - raising an event to update the related incidents
     * (the incidents that the panel was open for).
     */
    IncidentSetClassificationComponent.prototype.updateIncidents = function () {
        var _this = this;
        this.isSaving = true;
        this.updateIncidentsEvent.emit(Object.assign({
            incidentIds: this.incidents.map(function (incident) { return incident.id; }),
            comment: this.comment,
        }, this.incidentClassification &&
            this.incidents.some(function (incident) {
                return !incident.classification ||
                    incident.classification.id !== _this.incidentClassification.id;
            })
            ? { classification: this.incidentClassification }
            : null, this.determination &&
            this.incidents.some(function (incident) {
                return !incident.determination || incident.determination.id !== _this.determination.id;
            })
            ? { determination: this.determination }
            : null, this.incidentStatus &&
            this.incidents.some(function (incident) { return !incident.status || incident.status.id !== _this.incidentStatus.id; })
            ? { status: this.incidentStatus }
            : null, this.assignedToUpdated
            ? { assignedTo: this.assignedTo ? this.assignedTo.secondaryText : '' }
            : null));
        AssigneePickerComponent.updateUserPreferredAssignees(this.assignedTo, this.preferencesService);
    };
    Object.defineProperty(IncidentSetClassificationComponent.prototype, "isSubmitDisabled", {
        get: function () {
            return this.isSaving || (!this.classificationForm.dirty && !this.assignedToUpdated && !this.classificationUpdated);
        },
        enumerable: true,
        configurable: true
    });
    IncidentSetClassificationComponent.prototype.getIncidentStatusWarning = function () {
        var reactivateWarningKey = 'incident.reactivate.reactivateWarning';
        var resolveWarningKey = 'incident.resolve.resolveWarning';
        var inProgressWarningKey = 'incident.inProgress.inProgressWarning';
        // on multiple incidents - user picks the status
        if (this.incidentStatus)
            switch (this.incidentStatus.id) {
                case IncidentStatusValueIds.IncidentActiveStatusId:
                    return this.i18nService.get(reactivateWarningKey);
                case IncidentStatusValueIds.IncidentResolvedStatusId:
                    return this.i18nService.get(resolveWarningKey);
                case IncidentStatusValueIds.IncidentInProgressStatusId:
                    return this.i18nService.get(inProgressWarningKey);
            }
        return null;
    };
    return IncidentSetClassificationComponent;
}(PanelContainer));
export { IncidentSetClassificationComponent };
