
		<div class="wcd-flex-1">
			<label class="dropdown-label wcd-required">
				{{ i18nService.strings.remediationActions_rejectReasons_title }}
			</label>
			<wcd-select
				[placeholder]="i18nService.strings.remediationActions_rejectReasons_selectReason"
				[values]="remediationRejectReasons"
				[isFullWidth]="true"
				[label]="'label'"
				[(ngModel)]="selectedReason"
				(ngModelChange)="onSelectionChange()"
				[ariaLabel]="i18nService.strings.remediationActions_rejectReasons_title"
			>
			</wcd-select>
			<textarea
				*ngIf="selectedReason?.isCustom"
				rows="4"
				class="wcd-margin-vertical wcd-full-width"
				[(ngModel)]="customReason"
				(ngModelChange)="onSelectionChange()"
				autofocus
				name="remediation-decline-comment"
				required
				[placeholder]="i18nService.strings.remediationAction_panel_enterComment"
			></textarea>
		</div>
	