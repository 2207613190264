import { getThreatsNames } from '../../../mtp-investigation/mtp-investigation.entity';

export const enum MailboxEntityAlertV3Fields {
	DisplayName = 'DisplayName',
	MailboxPrimaryAddress = 'MailboxPrimaryAddress',
	RiskLevel = 'RiskLevel',
	ThreatAnalysisSummary = 'ThreatAnalysisSummary',
	AadId = 'AadId',
	Upn = 'Upn',
}

export function mapMailboxEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case MailboxEntityAlertV3Fields.DisplayName:
					acc['display_name'] = val;
					break;
				case MailboxEntityAlertV3Fields.MailboxPrimaryAddress:
					acc['primary_mail_address'] = val;
					acc[key] = val; // need to save 'MailboxPrimaryAddress' in order to calculate the display_name
					break;
				case MailboxEntityAlertV3Fields.RiskLevel:
					acc['risk_level'] = val;
					break;
				case MailboxEntityAlertV3Fields.ThreatAnalysisSummary:
					const threats = getThreatsNames(val);
					acc['risk'] = threats;
					acc['risky_activities'] = (threats && threats.length) || 0;
					break;
				case MailboxEntityAlertV3Fields.AadId:
					acc['aad_id'] = val;
					break;
				case MailboxEntityAlertV3Fields.Upn:
					acc['upn'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
