<div *ngIf="historyDays > 0" class="wcd-margin-bottom wcd-flex-baseline">
	<div class="wcd-font-weight-bold">
		{{ title }}
	</div>
	<fab-icon [iconName]="iconName" [contentClass]="iconColorClass + ' wcd-padding-small-left'">
	</fab-icon>
	<div class="ms-color-gray140 wcd-font-size-s">
		{{ advancementScore }}
	</div>
	<div class="wcd-padding-small-left ms-color-gray140 wcd-font-size-mi">
		{{ 'tvm.historyTrends.daysAnnotation' | i18n : { days: historyDays } }}
	</div>
</div>
