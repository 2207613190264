var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileVerdict } from '../file.file-verdict.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.files; }, ɵ1 = function (_, query) { return "files/" + query.where['sha1'] + "/FileVerdict"; }, ɵ2 = function (file) { return ({
    sha1: file.sha1,
}); }, ɵ3 = function (rawData) { return ({
    items: rawData.FileVerdicts,
    count: rawData.Count,
}); }, ɵ4 = function (query) { return null; };
var FileFileVerdictsRelationship = /** @class */ (function () {
    function FileFileVerdictsRelationship() {
    }
    FileFileVerdictsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileVerdict,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ0,
            endpoint: ɵ1,
            getRelationshipData: ɵ2,
            parseData: ɵ3,
            parseDataQuery: ɵ4,
            allItemsEndpointTrailingSlash: false,
            cache: {
                time: 30 * 1000,
            },
        })
    ], FileFileVerdictsRelationship);
    return FileFileVerdictsRelationship;
}());
export { FileFileVerdictsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
