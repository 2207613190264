export function mapEmailClusterEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                // TODO: this has a typo, should be removed
                case "IsVolumeAnamoly" /* IsVolumeAnamoly */:
                case "IsVolumeAnomaly" /* IsVolumeAnomaly */:
                    acc['volume_anomaly'] = val ? 'Yes' : 'No';
                    break;
                case "MailCount" /* MailCount */:
                    acc['mail_count'] = val;
                    break;
                case "CountByThreatType" /* CountByThreatType */:
                    acc['count_by_threat_type'] = val;
                    break;
                case "CountByProtectionStatus" /* CountByProtectionStatus */:
                    acc['count_by_protection_status'] = val;
                    break;
                case "CountByDeliveryLocation" /* CountByDeliveryLocation */:
                    acc['count_by_delivery_location'] = val;
                    break;
                case "ClusterBy" /* ClusterBy */:
                    acc['clustered_by'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "ClusterByValue" /* ClusterByValue */:
                    acc['source'] = val;
                    acc['cluster_by_value'] = val;
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "Query" /* Query */:
                    acc['query'] = val;
                    break;
                case "NetworkMessageIds" /* NetworkMessageIds */:
                    acc['network_message_ids'] = val;
                    break;
                case "InternalId" /* InternalId */:
                    acc['internal_id'] = val;
                    break;
                case "QueryTime" /* QueryTime */:
                    acc['query_time'] = val;
                    break;
                case "Threats" /* Threats */:
                    acc['threats'] = val;
                    break;
                case "MdoBatchId" /* MdoBatchId */:
                    acc['mdo_batch_id'] = val;
                    break;
                case "MdoRemediationId" /* MdoRemediationId */:
                    acc['mdo_remediation_id'] = val;
                    break;
                case "MdoInternalId" /* MdoInternalId */:
                    acc['mdo_internal_id'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
