var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { VulnerabilityNotificationRulesService } from '../services/vulnerability-notification-rules.service';
import { ExternalRuleActions } from '../services/vulnerability-notification-rule-entity-type.service';
import { MachinesService } from '../../../machines/services/machines.service';
import { TvmNotificationsTextsService } from '../../../../tvm/services/tvm-notifications-texts.service';
import { AppConfigService } from '@wcd/app-config';
var VulnerabilityNotificationRulePanelComponent = /** @class */ (function (_super) {
    __extends(VulnerabilityNotificationRulePanelComponent, _super);
    function VulnerabilityNotificationRulePanelComponent(changeDetectorRef, i18n, vulnerabilityNotificationRulesService, machinesService, notificationTextsService, appConfigService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.i18n = i18n;
        _this.vulnerabilityNotificationRulesService = vulnerabilityNotificationRulesService;
        _this.machinesService = machinesService;
        _this.notificationTextsService = notificationTextsService;
        _this.isSaving = false;
        _this.isUserAllowedActions = vulnerabilityNotificationRulesService.isUserAllowedActions();
        _this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
        return _this;
    }
    VulnerabilityNotificationRulePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.notificationRule = this.entity;
        this.machinesService.getFullUserExposedMachineGroups().subscribe(function (groups) {
            _this.isUserCanEditRule = _this.vulnerabilityNotificationRulesService.isUserCanEditRule(_this.notificationRule, groups);
        });
        this.isEnabled = this.notificationRule.isEnabled;
        this.lastModifiedDate = this.notificationRule.lastModifiedBy
            ? this.notificationRule.lastModifiedOn
            : this.notificationRule.createdOn;
        this.lastModifiedBy = this.notificationRule.lastModifiedBy
            ? this.notificationRule.lastModifiedBy.email
            : this.notificationRule.createdBy.email;
        this.recipientsTitle = this.i18n.get('email.notification.recipientsTable.title', {
            count: this.notificationRule.recipients.length,
        });
        this.eventTypeTitle = this.notificationRule.notificationRuleArgsCollection
            .map(function (args) { return _this.notificationTextsService.getChangeEventTypeLabel(args.eventType); })
            .join(', ');
        this.severityLevelForNewCve = this.vulnerabilityNotificationRulesService.getSeverityThreshold(this.notificationRule);
        this.severityThresholdTitle = this.severityLevelForNewCve
            ? this.i18n.get("tvm_notifications_vulnerability_" + this.vulnerabilityNotificationRulesService.getSeverityThreshold(this.notificationRule))
            : undefined;
        if (!this.notificationRule.isGlobalNotification) {
            this.updateDeviceGroupsDisplay();
            this.deviceGroupLabel = this.i18n.get('email.notification.deviceGroupsTable.title', {
                count: this.deviceGroupDisplay.length,
            });
        }
        this.ruleScope = this.notificationTextsService.getRuleScope(this.notificationRule);
        this.changeDetectorRef.markForCheck();
    };
    VulnerabilityNotificationRulePanelComponent.prototype.updateDeviceGroupsDisplay = function () {
        var _this = this;
        this.deviceGroupDisplay = this.notificationRule.rbacGroupIds.map(function (machineGroup) {
            return machineGroup && machineGroup.isUnassignedMachineGroup
                ? _this.i18n.strings.machineGroup_unassignedGroup_name
                : machineGroup.name;
        });
    };
    VulnerabilityNotificationRulePanelComponent.prototype.updateStatus = function () {
        this.isSaving = true;
        _super.prototype.runEntityAction.call(this, ExternalRuleActions.UPDATE_STATUS_ACTION);
    };
    return VulnerabilityNotificationRulePanelComponent;
}(EntityPanelComponentBase));
export { VulnerabilityNotificationRulePanelComponent };
