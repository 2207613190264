import { mergeWith } from 'lodash-es';

export class AirsNotificationRuleTypeModel {
	id: number;
	name: string;
	allowConditions: boolean;
	requireSchedule: boolean;

	constructor(data: AirsNotificatioRuleTypeConfig) {
		mergeWith(this, data, (currentValue, newValue, key) => {
			if (key === 'allowConditions' || key === 'requireSchedule') return !!newValue;

			return undefined;
		});
	}
}

interface AirsNotificatioRuleTypeConfig {
	id: number;
	name: string;
	allowConditions?: boolean;
	requireSchedule?: boolean;
}
