var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';
export var AdapterStatus;
(function (AdapterStatus) {
    AdapterStatus["Unknown"] = "Unknown";
    AdapterStatus["Up"] = "Up";
    AdapterStatus["Down"] = "Down";
})(AdapterStatus || (AdapterStatus = {}));
export var AdapterType;
(function (AdapterType) {
    AdapterType["Other"] = "Other";
    AdapterType["Ethernet"] = "Ethernet";
    AdapterType["Wireless80211"] = "Wireless80211";
    AdapterType["Tunnel"] = "Tunnel";
    AdapterType["Ppp"] = "Ppp";
    AdapterType["Wwanpp"] = "Wwanpp";
    AdapterType["Wwanpp2"] = "Wwanpp2";
    AdapterType["TokenRing"] = "TokenRing";
    AdapterType["Unknown"] = "Unknown";
    AdapterType["Atm"] = "Atm";
    AdapterType["HighPerformanceSerialBus"] = "HighPerformanceSerialBus";
})(AdapterType || (AdapterType = {}));
export var NetworkCategory;
(function (NetworkCategory) {
    NetworkCategory[NetworkCategory["Public"] = 0] = "Public";
    NetworkCategory[NetworkCategory["Private"] = 1] = "Private";
    NetworkCategory[NetworkCategory["Domain"] = 2] = "Domain";
})(NetworkCategory || (NetworkCategory = {}));
var IpAddress = /** @class */ (function (_super) {
    __extends(IpAddress, _super);
    function IpAddress() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Address' }),
        __metadata("design:type", String)
    ], IpAddress.prototype, "address", void 0);
    __decorate([
        EntityField({ data: 'IsInternal' }),
        __metadata("design:type", Boolean)
    ], IpAddress.prototype, "isInternal", void 0);
    IpAddress = __decorate([
        ValueObject({
            singularName: 'IP address',
            pluralName: 'IP addresses',
            readonly: true,
        })
    ], IpAddress);
    return IpAddress;
}(ModelBase));
export { IpAddress };
var NetworkInfo = /** @class */ (function (_super) {
    __extends(NetworkInfo, _super);
    function NetworkInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], NetworkInfo.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], NetworkInfo.prototype, "description", void 0);
    __decorate([
        EntityField({ data: 'Category' }),
        __metadata("design:type", Number)
    ], NetworkInfo.prototype, "category", void 0);
    NetworkInfo = __decorate([
        ValueObject({
            singularName: 'Network Info',
            pluralName: 'Network Info',
            readonly: true,
        })
    ], NetworkInfo);
    return NetworkInfo;
}(ModelBase));
export { NetworkInfo };
var ɵ0 = function (value, rawData) {
    var ipAddresses = rawData.IpAddresses || (rawData.Ipv4Addresses || []).concat(rawData.Ipv6Addresses || []);
    return ipAddresses.map(function (ip) {
        return { address: ip.Address, isInternal: ip.IsInternal };
    });
};
var IpAdapter = /** @class */ (function (_super) {
    __extends(IpAdapter, _super);
    function IpAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'PhysicalAddress' }),
        __metadata("design:type", String)
    ], IpAdapter.prototype, "physicalAddress", void 0);
    __decorate([
        EntityField({ data: 'InterfaceType' }),
        __metadata("design:type", String)
    ], IpAdapter.prototype, "adapterType", void 0);
    __decorate([
        EntityField({ data: 'DnsSuffix' }),
        __metadata("design:type", String)
    ], IpAdapter.prototype, "dnsSuffix", void 0);
    __decorate([
        EntityField({
            data: 'OperationalStatus',
            defaultValue: AdapterStatus.Unknown,
        }),
        __metadata("design:type", String)
    ], IpAdapter.prototype, "status", void 0);
    __decorate([
        EntityField({
            parse: ɵ0,
        }),
        __metadata("design:type", Array)
    ], IpAdapter.prototype, "ipAddresses", void 0);
    __decorate([
        EntityField({
            data: 'AdapterNetworks',
            arrayOf: NetworkInfo,
        }),
        __metadata("design:type", Array)
    ], IpAdapter.prototype, "connectedNetworks", void 0);
    IpAdapter = __decorate([
        ValueObject({
            singularName: 'IP adapter',
            pluralName: 'IP adapters',
            readonly: true,
        })
    ], IpAdapter);
    return IpAdapter;
}(ModelBase));
export { IpAdapter };
var MachineNetworkInfo = /** @class */ (function (_super) {
    __extends(MachineNetworkInfo, _super);
    function MachineNetworkInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'IpAdapters',
            arrayOf: IpAdapter,
        }),
        __metadata("design:type", Array)
    ], MachineNetworkInfo.prototype, "ipAdapters", void 0);
    __decorate([
        EntityField({ data: 'IsMacAddressReportedForMachine' }),
        __metadata("design:type", Boolean)
    ], MachineNetworkInfo.prototype, "isMachineReportingMacAddress", void 0);
    MachineNetworkInfo = __decorate([
        ValueObject({
            singularName: 'Device network info',
            pluralName: 'Device network info',
            readonly: true,
        })
    ], MachineNetworkInfo);
    return MachineNetworkInfo;
}(ModelBase));
export { MachineNetworkInfo };
var IpWithAdapter = /** @class */ (function (_super) {
    __extends(IpWithAdapter, _super);
    function IpWithAdapter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IpWithAdapter = __decorate([
        ValueObject({
            singularName: 'IP with adapter',
            pluralName: 'IPs with adapters',
            readonly: true,
        })
    ], IpWithAdapter);
    return IpWithAdapter;
}(IpAddress));
export { IpWithAdapter };
export { ɵ0 };
