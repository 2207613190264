import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Component, ChangeDetectionStrategy, Injector } from '@angular/core';
import { AntivirusBlockDetails, AntivirusRemediationAction } from '@wcd/domain';

@Component({
	selector: 'antivirus-block-entity-details',
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<ng-container *ngIf="block.container">
				<dt>{{ 'antivirusBlock.fields.container.title' | i18n }}</dt>
				<dd>{{ block.container }}</dd>
			</ng-container>

			<ng-container *ngIf="block.threatName">
				<dt>{{ 'antivirusBlock.fields.threatName.title' | i18n }}</dt>
				<dd>{{ block.threatName }}</dd>
			</ng-container>

			<ng-container *ngIf="block.remediationAction">
				<dt>{{ 'antivirusBlock.fields.remediationAction.title' | i18n }}</dt>
				<dd>{{ RemediationAction }}</dd>
			</ng-container>

			<ng-container *ngIf="block.wasRemediated !== null">
				<dt>{{ 'antivirusBlock.fields.wasRemediated.title' | i18n }}</dt>
				<dd>
					{{
						'antivirusBlock.fields.wasRemediated.' + (block.wasRemediated ? 'success' : 'failed')
							| i18n
					}}
				</dd>
			</ng-container>

			<ng-container *ngIf="block.wasExecutingWhileDetected !== null">
				<dt>{{ 'antivirusBlock.fields.wasExecutingWhileDetected.title' | i18n }}</dt>
				<dd>{{ block.wasExecutingWhileDetected }}</dd>
			</ng-container>
		</dl>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AntivirusBlockEntityDetailsComponent extends EntityDetailsComponentBase<AntivirusBlockDetails> {
	get block(): AntivirusBlockDetails {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}

	get RemediationAction(): string {
		return AntivirusRemediationAction[this.entity.remediationAction];
	}
}
