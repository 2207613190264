var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { AirsEntityRemediationStatus } from './airs-entity-remediation-status.entity';
import { AirsEntityVerdict } from './airs-entity-verdict.entity';
var verdictValues = AirsEntityVerdict.entityConfig.values;
var remediationStatusValues = AirsEntityRemediationStatus.entityConfig.values;
var ɵ0 = ['status', 'result'], ɵ1 = ['remediation_status'];
var AirsEntityStatusCount = /** @class */ (function (_super) {
    __extends(AirsEntityStatusCount, _super);
    function AirsEntityStatusCount() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(AirsEntityStatusCount.prototype, "status", {
        get: function () {
            var _this = this;
            return ((this.remediationStatus &&
                remediationStatusValues.find(function (s) { return s.id === _this.remediationStatus.id; })) ||
                (this._status && verdictValues.find(function (s) { return s.id === _this._status.id; })) ||
                null);
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", AirsEntityStatus)
    ], AirsEntityStatusCount.prototype, "_status", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", AirsEntityRemediationStatus)
    ], AirsEntityStatusCount.prototype, "remediationStatus", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], AirsEntityStatusCount.prototype, "count", void 0);
    AirsEntityStatusCount = __decorate([
        ValueObject({
            singularName: 'Entity Status Count',
            pluralName: 'Entity Status Counts',
            readonly: true,
        })
    ], AirsEntityStatusCount);
    return AirsEntityStatusCount;
}(ModelBase));
export { AirsEntityStatusCount };
export { ɵ0, ɵ1 };
