
		<fab-icon
			*ngIf="showIcon && hasName"
			[iconName]="FabricIconNames.System"
			[contentClass]="iconCssClass"
		></fab-icon>
		<route-link *ngIf="showLink && machineId; else machineName" [routeLink]="machineRouteLinkModel">
			<ng-container *ngTemplateOutlet="machineName"></ng-container>
		</route-link>
		<ng-template #machineName>
			<span
				*ngIf="hasName"
				class="noprint na-on-empty"
				[wcdTooltip]="shortName !== fullName ? fullName : null"
			>
				{{ preferFullName ? fullName : shortName }}
			</span>
			<span class="print-only na-on-empty">{{ fullName }}</span>
		</ng-template>
	