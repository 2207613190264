import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	Output,
} from '@angular/core';
import { DataSet } from '@microsoft/paris';
import { finalize, map } from 'rxjs/operators';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { Disableable, DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { CommentModel } from '../../models/comment.model';
import { CommentsStore } from '../../services/comments.store';
import { BehaviorSubject } from 'rxjs';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'editable-comments-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => EditableCommentsListComponent),
		},
	],
	styleUrls: ['./editable-comments-list.scss'],
	template: `
		<div
			class="noprint wcd-margin-large-bottom wcd-textarea-placeholder"
			*ngIf="!readonly"
			data-track-component="EditableCommentsList"
		>
			<form (submit)="onSubmit()">
				<textarea
					attr.aria-label="{{ 'common.textareaAriaLabelComment.title' | i18n }}"
					[required]="commentText.dirty"
					class="comment-input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[(ngModel)]="newComment"
					name="comment-text"
					[placeholder]="newCommentPlaceholderKey | i18n"
					[disabled]="isVisibleSnackBar$ | async"
					rows="4"
					#commentText="ngModel"
				></textarea>
				<fab-default-button
					className="btn-secondary pull-right"
					data-track-id="AddComment"
					data-track-type="Button"
					type="submit"
					[text]="'editable.comments.list.add.button' | i18n"
					[disabled]="!newComment || isSaving || (isVisibleSnackBar$ | async)"
				></fab-default-button>
			</form>
		</div>

		<ng-container *ngIf="isLoading; else checkComments">
			<div class="wcd-flex-center-all">
				<i class="loader-icon"></i>
			</div>
		</ng-container>

		<ng-template #checkComments>
			<comments-list
				*ngIf="comments"
				[class.wcd-padding-large-top]="!readonly"
				[comments]="comments"
				[showLink]="showLink"
				[getLinkTitle]="getLinkTitle"
				[defaultCommentIcon]="defaultCommentIcon"
				(commentLinkClick)="commentLinkClick.emit($event)"
			>
			</comments-list>
		</ng-template>
	`,
})
export class EditableCommentsListComponent implements Disableable, OnChanges<EditableCommentsListComponent> {
	@Input() comments: ReadonlyArray<CommentModel>;
	@Input() isSaving: boolean = false;
	@Input() newCommentPlaceholderKey: string = 'editable_comments_list_new';
	@Input() options: { [index: string]: any };
	@Input() refreshOn: any;
	@Input() readonly: boolean = false;
	@Input() showLink: (comment: CommentModel) => boolean;
	@Input() getLinkTitle: (comment: CommentModel) => string;
	@Input() defaultCommentIcon: string;
	@Input() isVisibleSnackBar$?: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() isLoading: boolean;

	@Output() readonly save = new EventEmitter<string>();
	@Output() readonly commentLinkClick = new EventEmitter<CommentModel>();

	newComment: string;

	constructor(
		public readonly commentsStore: CommentsStore,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private performanceSccService: PerformanceSccService
	) {}

	ngOnChanges(changes: TypedChanges<EditableCommentsListComponent>) {
		if ((changes.refreshOn || changes.options) && this.options) {
			this.isLoading = true;
			this.commentsStore
				.getItemsDataSet(this.options)
				.pipe(
					map((commentsDataSet: DataSet<CommentModel>) => commentsDataSet.items),
					finalize(() => (this.isLoading = false))
				)
				.subscribe((comments: ReadonlyArray<CommentModel>) => {
					this.comments = comments;
					this.isLoading = false;
					this.performanceSccService.endNgPageLoadPerfSession('comments');
					this.changeDetectorRef.markForCheck();
				});
		}
	}

	onSubmit() {
		this.save.emit(this.newComment);
		this.newComment = '';
		this.changeDetectorRef.markForCheck();
	}
}
