
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<header class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
				<h3 #panelHeader class="side-panel-title">
					{{ vulnerability.name }}
				</h3>
			</header>
			<section class="wcd-padding-vertical wcd-padding-large-horizontal">
				<vulnerability-entity-details
					[vulnerability]="vulnerability"
					[includeExposedMachinesComponent]="includeExposedMachinesComponent"
					[isNetworkGearCve]="isNetworkGearCve"
				></vulnerability-entity-details>
			</section>
		</wcd-panel>
	