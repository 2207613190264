import { Feature } from '@wcd/config';
import { LogicalOperator } from '../enums/logical-operator.enum';

export class NavItemModel {
	id: string;
	icon: string;
	getImage: () => string;
	getImageClass: () => string;
	route: string;
	isFolder: boolean;
	isDisabled: boolean;
	isExpanded = false;
	isLegacy: boolean;
	externalLink: string;
	externalLinkTarget: string;
	openInNewTab: boolean;
	sccInternalRedirectDefinition: (string) => string;
	showExternalLinkIcon: boolean;
	routeParams: Array<string>;
	name: string;
	children: Array<NavItemModel>;
	features: Array<Feature>;
	featuresLogicalOperator: LogicalOperator;
	disableFeatures: Array<Feature>;
	queryParams: { [index: string]: any };
	section: string;
	badgeId: string;
	isAlias: boolean;

	get routerLink(): Array<string> {
		return ['/' + this.route].concat(this.routeParams || []);
	}

	constructor(navItemConfig: NavItemConfig) {
		this.id = navItemConfig.id || navItemConfig.route;
		if (!this.id) throw new Error("Can't create NavMenuItem - no ID.");

		this.name = navItemConfig.name;
		if (!this.name) throw new Error("Can't create NavMenuItem, no menu or state title.");

		this.icon = navItemConfig.icon;
		this.getImage = navItemConfig.getImage;
		this.getImageClass = navItemConfig.getImageClass;
		this.route = navItemConfig.route || navItemConfig.path;
		this.isFolder = navItemConfig.isFolder === true;
		this.isDisabled = navItemConfig.isDisabled;
		this.isLegacy = !!navItemConfig.isLegacy;
		this.externalLink = navItemConfig.externalLink;
		this.externalLinkTarget = navItemConfig.externalLinkTarget;
		this.showExternalLinkIcon = navItemConfig.showExternalLinkIcon;
		this.routeParams = navItemConfig.routeParams;
		this.features = navItemConfig.features || null;
		this.featuresLogicalOperator = navItemConfig.featuresLogicalOperator;
		this.disableFeatures = navItemConfig.disableFeatures || null;
		this.section = navItemConfig.section;
		this.queryParams = navItemConfig.queryParams;
		this.badgeId = navItemConfig.badgeId;
		this.openInNewTab = navItemConfig.openInNewTab;
		this.isAlias = navItemConfig.isAlias;

		if (navItemConfig.isFolder && navItemConfig.children)
			this.children = navItemConfig.children.map(childConfig => new NavItemModel(childConfig));
	}
}

export interface NavItemConfig {
	id: string;
	icon?: string;
	getImage?: () => string;
	getImageClass?: () => string;
	route?: string;
	path?: string;
	name?: string;
	isFolder?: boolean;
	routeParams?: Array<string>;
	isDisabled?: boolean;
	isLegacy?: boolean;
	externalLink?: string;
	externalLinkTarget?: string;
	openInNewTab?: boolean;
	showExternalLinkIcon?: boolean;
	children?: Array<NavItemConfig>;
	features?: Array<Feature>;
	featuresLogicalOperator?: LogicalOperator;
	disableFeatures?: Array<Feature>;
	queryParams?: { [index: string]: any };
	section?: string;
	badgeId?: string;
	isAlias?: boolean;
}
