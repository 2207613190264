import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Command param',
	pluralName: 'Command params',
	readonly: true,
})
export class LiveResponseCommandParam extends ModelBase {
	@EntityField({ data: 'param_id' })
	paramId: string;

	@EntityField() name: string;

	@EntityField() description: string;

	@EntityField({ data: 'optional', defaultValue: true })
	isOptional: boolean;
}
