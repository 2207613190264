import { Input, Output, EventEmitter } from '@angular/core';
import { FiltersFieldId } from '../models/filters-field-id.type';
import { SerializedFilters } from '../models/serialized-filters.type';

export abstract class FilterValuesComponent<TFilterData, TFilterSelection, TValue, TConfig = any> {
	@Input() data: TFilterData;
	@Input() readonly fieldId: FiltersFieldId;
	@Input() readonly config: TConfig;
	@Input() selectedValues: TFilterSelection;
	@Input() fixedSelectedValues: TFilterSelection;

	@Output() filterValuesChange: EventEmitter<TFilterSelection> = new EventEmitter<TFilterSelection>();

	abstract get value(): TValue;
	abstract setSelectedValues(selectedValues: TFilterSelection);
	abstract serialize(): SerializedFilters | null;
	abstract deserialize(serializedValues: SerializedFilters): TFilterSelection;
}
