import { FilterSelectedValues } from '../models/filter-selected-values.type';
import { FiltersFieldId } from '../models/filters-field-id.type';
import { RawFiltersData } from '../models/raw-filters-data.type';
import { isNull, merge } from 'lodash-es';
import { Observable } from 'rxjs';
import { FilterValuesLoader } from '../models/filter-values-loader.interface';
import { SerializedFilters } from '../models/serialized-filters.type';
import { sccHostService } from '@wcd/scc-interface';

export class FiltersService {
	/**
	 * Loads data from server to populate the FilterResult's fields' _filterRawValues.
	 * @param noCache {boolean} Whether to allow caching
	 */
	static loadRawFilterValues(config: LoadFilterValuesConfig): Observable<RawFiltersData> {
		const options = FiltersService.getOptions(config);
		return config.filterValuesLoader(options);
	}

	/**
	 * Gets a JSON object to use as options for an API query, representing this filters
	 */
	static getOptions({
		selectedValues,
		fixedFilterValues,
		optionalParams,
	}: LoadFilterValuesConfig): Record<FiltersFieldId, string | Array<string>> {
		const options: Record<FiltersFieldId, string | Array<string>> = {};

		// TODO: Use this.selectedValues, not fieldFilterValues.
		// this._fieldFilterValues.forEach((fieldFilterValues, fieldId) => {
		// 	if (fieldFilterValues.hasSelectedValues) {
		// 		options[fieldId] = Array.from(fieldFilterValues.selectedValues.keys()).map(filterValue =>
		// 			this.getFilterValueParamValue(
		// 				filterValue,
		// 				fieldFilterValues.selectedValues.get(filterValue)
		// 			)
		// 		);
		// 	}
		// });

		return merge(options, optionalParams, fixedFilterValues);
	}

	static filtersQueryParamToSerializedFilters(filtersQueryParam: string): SerializedFilters {
		if (!filtersQueryParam) return {};

		return filtersQueryParam.split(',').reduce((serialized: SerializedFilters, param: string) => {
			const [key, value] = param.split('=');
			if (isNull(value) || value === "undefined" || value.length > 0 && value[0] === "undefined") {
				if (sccHostService.isSCC) {
					sccHostService.log.trackEvent("Undefined query filter value found", {param})
				}
				return serialized;
			}
			const decodedValue: string = decodeURIComponent(value);
			const serializedFilterValue: Array<string> = decodedValue
				.split('|')
				.map(_value => decodeURIComponent(_value));

			return {
				...serialized,
				[key]: serializedFilterValue,
			};
		}, {});
	}

	static getFiltersQueryParam(serializedFilters: SerializedFilters): string | null {
		if (!serializedFilters) return null;

		return (
			Object.keys(serializedFilters)
				.filter(param => {
					const value = serializedFilters[param];
					if (isNull(value) || value === 'undefined' || value.length > 0 && value[0] === "undefined") {
						if (sccHostService.isSCC) {
							sccHostService.log.trackEvent("Undefined serialized filter value found", {param})
						}
						return false;
					}
					return true;
				})
				.map(param => {
					const paramValue: string | Array<string> = serializedFilters[param];
					const queryParamValue: string =
						paramValue instanceof Array
							? paramValue.map(value => encodeURIComponent(value)).join('|')
							: paramValue;
					return `${param}=${encodeURIComponent(queryParamValue)}`;
				})
				.join(',') || null
		);
	}
}

export interface LoadFilterValuesConfig {
	filterValuesLoader: FilterValuesLoader;
	selectedValues: FilterSelectedValues;
	fixedFilterValues?: Record<FiltersFieldId, Array<any>>;
	optionalParams?: Record<string, string>;
}
