var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { AssessmentType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobService } from '../../services/assessment-job.service';
var AssessmentJobJobTypeStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobJobTypeStepComponent, _super);
    function AssessmentJobJobTypeStepComponent(i18nService, assessmentJobService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.assessmentJobService = assessmentJobService;
        _this.assessmentTypeValues = [
            {
                id: AssessmentType.NetworkAssessment,
                name: _this.i18nService.strings.tvm_authenticatedScan_wizard_networkScanType_title,
                description: _this.i18nService.strings.tvm_authenticatedScan_wizard_networkScanType_description,
            },
            {
                id: AssessmentType.WindowsAssessment,
                name: _this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_title,
                description: _this.assessmentJobService.isWindowsScanLicenseEnabled() ? _this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_description_withLicense : _this.i18nService.strings.tvm_authenticatedScan_wizard_windowsScanType_description_noLicense,
                disabled: !_this.assessmentJobService.isWindowsScanLicenseEnabled(),
            },
        ];
        return _this;
    }
    AssessmentJobJobTypeStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.data.assessmentJob.scanType) {
            this.selectedAssessmentType = this.assessmentTypeValues.find(function (value) { return value.id === _this.data.assessmentJob.scanType; });
        }
        this.setStepValidation(!!this.data.assessmentJob.scanType);
    };
    AssessmentJobJobTypeStepComponent.prototype.onAssessmentTypeChange = function () {
        this.data.assessmentJob.scanType = this.selectedAssessmentType.id;
        this.setStepValidation(!!this.data.assessmentJob.scanType);
    };
    return AssessmentJobJobTypeStepComponent;
}(WizardBaseStep));
export { AssessmentJobJobTypeStepComponent };
