var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { TitleService } from '../../../../shared/services/title.service';
import { MachineGroup, MdeUserRoleActionEnum, MtpWorkload } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MachineGroupFields } from '../../services/machine-group.fields';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { MachineGroupEditEnhancedPanelComponent } from './machine-group-edit-enhanced.panel.component';
import { ItemActionModel } from '../../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { AuthService, UnifiedRbacPermission, UserAuthEnforcementMode } from '@wcd/auth';
import { of, timer } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { catchError, distinctUntilChanged, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { cloneDeep, filter, maxBy, minBy, remove, some, sortBy } from 'lodash-es';
import { RbacService } from '../../../../rbac/services/rbac.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { AppConfigService } from '@wcd/app-config';
var IS_APPLYING_CHANGES_INTERVAL = 1000 * 20;
var IS_APPLYING_MACHINE_GROUPS_CHANGES_PATH = 'IsRbacReevaluationInProgress';
var MachineGroupsDataviewComponent = /** @class */ (function () {
    function MachineGroupsDataviewComponent(http, paris, machineGroupFields, titleService, dialogsService, i18nService, authService, serviceUrlsService, rbacService, pollingService, liveAnnouncer, featureService, performanceSccService, appConfigService) {
        var _this = this;
        this.http = http;
        this.paris = paris;
        this.machineGroupFields = machineGroupFields;
        this.titleService = titleService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.authService = authService;
        this.serviceUrlsService = serviceUrlsService;
        this.rbacService = rbacService;
        this.pollingService = pollingService;
        this.liveAnnouncer = liveAnnouncer;
        this.featureService = featureService;
        this.performanceSccService = performanceSccService;
        this.appConfigService = appConfigService;
        this.isScc = sccHostService.isSCC;
        this.repository = paris.getRepository(MachineGroup);
        this.isApplyingMachineGroupsBaseUrl = featureService.isEnabled(Feature.RbacGroupsProgressApiMigration)
            ? serviceUrlsService.k8s + "/machines"
            : serviceUrlsService.threatIntel;
        this.dataViewConfig = {
            id: "unappliedMachineGroups",
            loadResults: function (options) { return _this.getItems(options); },
        };
        this.itemActions = [
            {
                id: 'priorityIncrease',
                name: this.i18nService.get('machineGroup.promoteRank'),
                icon: 'arrow.sort.up',
                tooltip: this.i18nService.get('machineGroup.promoteRankTooltip'),
                method: this.changePriority.bind(this, false),
                refreshOnResolve: true,
            },
            {
                id: 'priorityDecrease',
                name: this.i18nService.get('machineGroup.demoteRank'),
                icon: 'arrow.sort.down',
                tooltip: this.i18nService.get('machineGroup.demoteRankTooltip'),
                method: this.changePriority.bind(this, true),
                refreshOnResolve: true,
            },
            {
                id: 'delete',
                name: this.i18nService.get('delete'),
                icon: 'delete',
                tooltip: this.i18nService.get('deleteSelectedItems', {
                    itemsName: this.repository.entity.pluralName,
                }),
                method: function (items) { return _this.deleteItems(items); },
                refreshOnResolve: true,
            },
        ].map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
        this.discardChangesAction = {
            id: 'discardChanges',
            name: this.i18nService.get('discardChanges'),
            tooltip: 'Discard the current changes to device groups',
            method: this.removeUnappliedChanges.bind(this),
            buttonClass: 'btn-cancel',
        };
        this.localChangesActions = [
            {
                id: 'applyChanges',
                name: this.i18nService.get('buttons.applyChanges'),
                tooltip: 'Apply the current changes to device groups',
                method: this.applyChanges.bind(this),
            },
            this.discardChangesAction,
        ];
        this.boundSetItemActions = this.setItemActions.bind(this);
    }
    Object.defineProperty(MachineGroupsDataviewComponent.prototype, "allowEdit", {
        get: function () {
            if (this.appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac) {
                //This is granularity change if the Tenant moved to UnifiedRbac
                return this.authService.currentUser.hasRequiredMtpPermissionInWorkload(UnifiedRbacPermission.ConfigurationAuthorizationManage, MtpWorkload.Mdatp);
            }
            return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.systemSettings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineGroupsDataviewComponent.prototype, "unappliedChangesExists", {
        get: function () {
            return !!(this.unappliedItems && this.unappliedItems.length);
        },
        enumerable: true,
        configurable: true
    });
    MachineGroupsDataviewComponent.prototype.ngOnInit = function () {
        this.setApplyingInProgressState();
        this.unappliedItems = this.getUnappliedChanges();
    };
    MachineGroupsDataviewComponent.prototype.ngOnDestroy = function () {
        this.applyingChangesSubscription && this.applyingChangesSubscription.unsubscribe();
    };
    MachineGroupsDataviewComponent.prototype.onDataFetched = function (event) {
        if ((!this.unappliedItems || !this.unappliedItems.length) && event.data && event.data.items)
            this.itemsFromBackend = event.data.items;
    };
    MachineGroupsDataviewComponent.prototype.setItemActions = function (machineGroups) {
        var unassignedGroupSelected = machineGroups.some(function (machineGroup) { return machineGroup.isUnassignedMachineGroup; });
        return unassignedGroupSelected ? [] : this.itemActions;
    };
    MachineGroupsDataviewComponent.prototype.openEditPanel = function (machineGroup) {
        var _this = this;
        this.dialogsService
            .showPanel(MachineGroupEditEnhancedPanelComponent, {
            id: 'machine-group-edit-panel',
            type: PanelType.extraLarge,
            isModal: true,
            showOverlay: true,
            disableOverlayClick: true,
            isBlocking: true,
            headerIcon: 'System',
            headerText: this.i18nService.get(machineGroup ? 'machineGroups.edit' : 'machineGroups.add'),
            noBodyPadding: true,
        }, {
            machineGroup: machineGroup,
            onCancel: function () { return _this.editPanel.destroy(); },
            onDone: function () {
                _this.editPanel.destroy();
                _this.lastUpdate = new Date();
            },
        })
            .subscribe(function (panel) {
            _this.editPanel = panel;
            panel.instance.onSaveChanges.subscribe(function (item) {
                _this.saveChangesLocally(item).subscribe(function () {
                    _this.editPanel.instance.closePanel();
                    _this.lastUpdate = new Date();
                });
            });
            panel.onDestroy(function () {
                _this.editPanel = null;
            });
        });
    };
    MachineGroupsDataviewComponent.prototype.getItems = function (options) {
        if (!this.unappliedChangesExists)
            return this.repository.query(__assign({}, options, { where: {
                    addAadGroupNames: 'true',
                    addMachineGroupCount: 'true',
                } }));
        else
            return of({
                count: this.unappliedItems && this.unappliedItems.length,
                items: this.unappliedItems || [],
            });
    };
    MachineGroupsDataviewComponent.prototype.deleteItems = function (items) {
        var _this = this;
        var itemName = (items.length === 1
            ? this.repository.entity.singularName
            : this.repository.entity.pluralName).toLowerCase();
        return this.dialogsService
            .confirm({
            title: this.i18nService.get('deleteItem', { itemName: itemName }),
            text: this.i18nService.get('machineGroup.delete.confirmText', {
                itemPluralName: itemName,
            }),
            confirmText: this.i18nService.get('delete'),
        })
            .then(function (e) {
            if (e.confirmed) {
                var itemsAfterDelete = filter(_this.unappliedChangesExists ? _this.unappliedItems : _this.itemsFromBackend, function (originalItem) {
                    return !some(items, function (itemToDelete) { return itemToDelete.id === originalItem.id; });
                });
                itemsAfterDelete
                    .sort(function (a, b) {
                    return a.priority > b.priority ? 1 : -1;
                })
                    .forEach(function (machineGroup, index) {
                    if (!machineGroup.isUnassignedMachineGroup)
                        machineGroup.priority = index + 1;
                });
                _this.setUnappliedItems(itemsAfterDelete);
            }
            return Promise.resolve(_this.unappliedChangesExists ? _this.unappliedItems : _this.itemsFromBackend);
        });
    };
    MachineGroupsDataviewComponent.prototype.changePriority = function (increase, machineGroups, options, dataSet) {
        var _this = this;
        return this.dialogsService
            .confirm({
            title: this.i18nService.get(increase ? 'machineGroup.decreaseRankBy' : 'machineGroup.increaseRankBy'),
            requireMessage: {
                placeholder: '1',
                type: 'number',
                min: 1,
                property: 'newPriority',
                defaultValue: '1',
            },
        })
            .then(function (e) {
            if (!e.confirmed)
                return false;
            var unassignedMachinesGroup = remove(dataSet.items, function (machineGroup) { return machineGroup.isUnassignedMachineGroup; })[0], priorityChange = e.data.newPriority ? parseInt(e.data.newPriority) : 1, selectedItemIds = new Set(machineGroups.map(function (machineGroup) { return String(machineGroup.id); }));
            var editedItems = sortBy(dataSet.items.map(function (machineGroup) { return new MachineGroup(machineGroup); }), function (machineGroup) { return machineGroup.priority; });
            if (increase) {
                var highest = maxBy(machineGroups, 'priority');
                var normalizedPriorityChange = Math.min(priorityChange, editedItems.length - highest.priority);
                for (var i = editedItems.length - 1; i >= 0; i--) {
                    if (selectedItemIds.has(String(editedItems[i].id))) {
                        var editedMachineGroup = editedItems[i];
                        // Change group priority
                        editedMachineGroup.priority += normalizedPriorityChange;
                        // Push effected groups and update their priority
                        for (var j = i; j < editedMachineGroup.priority - 1; j++) {
                            editedItems[j + 1].priority--;
                            editedItems[j] = editedItems[j + 1];
                        }
                        // Move group to new position
                        editedItems[editedMachineGroup.priority - 1] = editedMachineGroup;
                    }
                }
            }
            else {
                var lowest = minBy(machineGroups, 'priority');
                var normalizedPriorityChange = Math.min(priorityChange, lowest.priority - 1);
                for (var i = 0; i < editedItems.length; i++) {
                    if (selectedItemIds.has(String(editedItems[i].id))) {
                        var editedMachineGroup = editedItems[i];
                        // Change group priority
                        editedMachineGroup.priority -= normalizedPriorityChange;
                        // Push effected groups and update their priority
                        for (var j = i; j >= editedMachineGroup.priority; j--) {
                            editedItems[j - 1].priority++;
                            editedItems[j] = editedItems[j - 1];
                        }
                        // Move group to new position
                        editedItems[editedMachineGroup.priority - 1] = editedMachineGroup;
                    }
                }
            }
            editedItems.push(unassignedMachinesGroup);
            _this.setUnappliedItems(editedItems);
            return editedItems;
        });
    };
    MachineGroupsDataviewComponent.prototype.saveChangesLocally = function (editedMachineGroup) {
        var items = cloneDeep(this.unappliedChangesExists ? this.unappliedItems : this.itemsFromBackend);
        if (editedMachineGroup.id) {
            // edited item
            var originalItem = items.find(function (machineGroup) { return machineGroup.id === editedMachineGroup.id; });
            items[items.indexOf(originalItem)] = editedMachineGroup;
        }
        else {
            // new item
            var minIdItem = minBy(items, function (item) { return item.id; });
            editedMachineGroup.id = !minIdItem || minIdItem.id > 0 ? -1 : minIdItem.id - 1;
            var maxRankItem = maxBy(items, function (item) {
                return !item.isUnassignedMachineGroup ? item.priority : 0;
            });
            editedMachineGroup.priority =
                !maxRankItem || maxRankItem.isUnassignedMachineGroup ? 1 : maxRankItem.priority + 1;
            editedMachineGroup.lastUpdatedDate = new Date();
            items.splice(items.length - 1, 0, editedMachineGroup);
        }
        this.setUnappliedItems(items);
        return of(editedMachineGroup);
    };
    MachineGroupsDataviewComponent.prototype.applyChanges = function () {
        var _this = this;
        var itemsForSave = this.unappliedItems.map(function (machineGroup) {
            if (machineGroup.assignments && machineGroup.assignments.length)
                machineGroup.assignments.forEach(function (rule) {
                    return (rule.lastUpdated = new Date(rule.lastUpdated || new Date()));
                });
            machineGroup.lastUpdatedDate = new Date(machineGroup.lastUpdatedDate);
            return machineGroup;
        });
        this.liveAnnouncer.announce(this.i18nService.get('machineGroup.actions.applyingChanges'), 'assertive', 300);
        return this.repository
            .saveItems(itemsForSave)
            .pipe(tap(function () {
            _this.repository.clearAllValues();
            // currently backend has cache up to 90secs of the rbac exposed groups, so we poll it to make sure we get updated results once cache is flushed.
            // can be removed when backend cahce settigs changes
            _this.pollingService
                .poll(0, 10 * 1000)
                .pipe(takeUntil(timer(100 * 1000)))
                .subscribe(function () {
                _this.rbacService.refreshUserExposedRbacGroups$.next(null);
            });
            _this.setApplyingInProgressState();
            return _this.removeUnappliedChanges();
        }), catchError(function (err) {
            return _this.dialogsService.showError({
                title: _this.i18nService.get('machineGroup.actions.applyErrorTitle'),
                data: err && err.status === 409
                    ? _this.i18nService.get('machineGroup.actions.applyConflictErrorDescription')
                    : err.message || err.error,
            });
        }))
            .toPromise();
    };
    MachineGroupsDataviewComponent.prototype.removeUnappliedChanges = function () {
        this.unappliedItems = undefined;
        localStorage.removeItem(this.getStorageKey());
        this.lastUpdate = new Date();
        return Promise.resolve(null);
    };
    MachineGroupsDataviewComponent.prototype.setApplyingInProgressState = function () {
        var _this = this;
        if (this.applyingChangesSubscription) {
            this.applyingChangesSubscription.unsubscribe();
        }
        this.applyingChangesSubscription = this.pollingService
            .poll(0, IS_APPLYING_CHANGES_INTERVAL)
            .pipe(mergeMap(function () {
            return _this.http.get(_this.isApplyingMachineGroupsBaseUrl + "/" + IS_APPLYING_MACHINE_GROUPS_CHANGES_PATH);
        }), distinctUntilChanged())
            .subscribe(function (applyingInProgress) {
            if (_this.applyingChangesInProgress) {
                _this.lastUpdate = new Date();
            }
            _this.applyingChangesInProgress = applyingInProgress;
        });
    };
    MachineGroupsDataviewComponent.prototype.getLocalChangesMessageKey = function () {
        return "machineGroup.actions." + (this.applyingChangesInProgress
            ? this.unappliedChangesExists
                ? 'discardOnly'
                : 'applyingInProgress'
            : 'unappliedChanges');
    };
    MachineGroupsDataviewComponent.prototype.onActionFailed = function (details) {
        this.dialogsService.showError({
            title: this.i18nService.get('errors.action.failureTitle'),
            errorTitle: this.i18nService.get('errors.action.failureSubTitle', {
                actionName: details.action.name,
            }),
            data: details.error,
        });
    };
    MachineGroupsDataviewComponent.prototype.setUnappliedItems = function (unappliedItems) {
        this.unappliedItems = unappliedItems;
        // to make sure that the DataEntityType field is saved when stringify
        // (packages/@wcd/domain/src/rbac/machine-group-simple-rule-property.entity.ts)
        var fixedUnappliedItems = this.unappliedItems.map(function (item) { return (__assign({}, item, { rules: item.rules.map(function (rule) { return (__assign({}, rule, { property: __assign({}, rule.property, { valuesEntity: rule.property.valuesEntity
                        ? rule.property.valuesEntity.name
                        : rule.property.valuesEntity }) })); }) })); });
        localStorage.setItem(this.getStorageKey(), JSON.stringify(fixedUnappliedItems, function (key, value) {
            if (key == '$parent') {
                return value.id;
            }
            else
                return value;
        }));
    };
    MachineGroupsDataviewComponent.prototype.getUnappliedChanges = function () {
        var data = localStorage.getItem(this.getStorageKey());
        return data ? JSON.parse(data) : [];
    };
    MachineGroupsDataviewComponent.prototype.getStorageKey = function () {
        var currentUserName = this.authService.currentUser
            ? this.authService.currentUser.name
            : 'defaultUser';
        return currentUserName + "_rbac.unappliedMachineGroups";
    };
    MachineGroupsDataviewComponent.prototype.onMachineGroupsDataViewComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('settings-machines-groups');
    };
    return MachineGroupsDataviewComponent;
}());
export { MachineGroupsDataviewComponent };
