import { Entity, ModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { EolState } from '../software-version/eol-version-state.enum';

// TODO: there is a bug in paris - use of idProperty: 'assetId' has no affect and the id is null
@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'missing kb',
	pluralName: 'missing kbs',
	endpoint: 'missingkbs',
})
export class MissingKb extends ModelBase {
	@EntityField({
		parse: (_, MissingKb) => `${MissingKb.osVersion}-_-${MissingKb.productFixId}`,
	})
	readonly key: string;

	@EntityField({
		parse: (_, MissingKb) => MissingKb.productFixId + '-_-' + MissingKb.vendorBulletin,
	})
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({
		required: true,
	})
	readonly productFixId: string;

	@EntityField({
		required: false,
	})
	readonly productId: string;

	@EntityField({
		required: true,
	})
	readonly vendorBulletin: string;

	@EntityField({
		required: true,
	})
	readonly productFixUrl: string;

	@EntityField({
		required: true,
	})
	readonly osVersion: string;

	@EntityField({
		required: true,
		data: 'machineMissedOn',
	})
	readonly missingMachines: number;

	@EntityField({
		required: true,
		data: 'cveAddressed',
	})
	readonly cvesAddressed: number;

	@EntityField({
		required: true,
	})
	readonly productsNames: string[];

	@EntityField({
		data: 'eolVersionState',
	})
	readonly eolVersionState: EolState;

	@EntityField()
	readonly eolVersionSinceDate: Date;
}
