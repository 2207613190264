
		<wcd-panel [settings]="settings">
			<custom-ti-indicators-import
				[customTiIndicatorType]="customTiIndicatorType"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(done)="onDone()"
				(import)="onImport($event)"
			>
			</custom-ti-indicators-import>
		</wcd-panel>
	