import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ActionBaseEntity, RemediationActionTypeTypes, AlertV3ActionTypeMap } from '@wcd/domain';
import { Observable, of, Subject } from 'rxjs';
import { map, shareReplay, takeUntil } from 'rxjs/operators';
import { findKey } from 'lodash-es';
import { Feature, FeaturesService } from '@wcd/config';

const MAX_ACTION_COUNT = 1000;

@Injectable()
export class ActionFinderService {
	private actionCache = new WeakMap<
		ActionBaseEntity,
		{ useExtraActions: boolean; actions$: Observable<Array<ActionBaseEntity>> }
	>();
	private destroy$ = new Subject<void>();
	private fixedValues: { [index: string]: any };
	private readonly useActionCenterApiV2: boolean = false;

	constructor(private paris: Paris, private featuresService: FeaturesService) {
		this.useActionCenterApiV2 = this.featuresService.isEnabled(Feature.ActionCenterApiV2);
	}

	getMoreActionsForFile<T extends ActionBaseEntity>(action: T, set?: false): Observable<Array<T>>;
	getMoreActionsForFile<T extends ActionBaseEntity>(
		action: T,
		set: true,
		moreActionsTransform?: (obs$: Observable<Array<T>>) => Observable<Array<T>>
	): Observable<Array<T>>;
	getMoreActionsForFile<T extends ActionBaseEntity>(
		action: T,
		set = false,
		moreActionsTransform: (obs$: Observable<Array<T>>) => Observable<Array<T>> = null
	): Observable<Array<T>> {
		if (!set) {
			const entry = this.actionCache.get(action);
			if (entry && entry.useExtraActions) {
				return entry.actions$ || of(null);
			}
			return of(null);
		}
		const sha1 = this.getSha1(action);

		if (!sha1) {
			return of(null);
		}

		const actionType = this.useActionCenterApiV2
			? findKey(AlertV3ActionTypeMap, v => v === RemediationActionTypeTypes.file_quarantine)
			: RemediationActionTypeTypes.file_quarantine;

		let res$: Observable<Array<T>> = this.paris
			.query<T>(Object.getPrototypeOf(action).constructor, {
				pageSize: MAX_ACTION_COUNT,
				where: {
					SHA1: sha1,
					ActionType: actionType,
					...this.fixedValues,
				},
			})
			.pipe(
				takeUntil(this.destroy$),
				map(actions => actions && actions.items)
			);
		if (moreActionsTransform) {
			res$ = moreActionsTransform(res$);
		}
		res$ = res$.pipe(shareReplay({ bufferSize: 1, refCount: true }));
		this.actionCache.set(action, { useExtraActions: false, actions$: res$ });
		return res$;
	}

	private getSha1(action: ActionBaseEntity): string {
		if (!action.relatedEntities) {
			return null;
		}
		const sha1 =
			action.relatedEntities && action.relatedEntities.length && action.relatedEntities[0].sha1;
		if (!sha1 || action.relatedEntities.some(e => e.sha1 !== sha1)) {
			return null;
		}
		return sha1;
	}

	useExtraActionsForFile(action: ActionBaseEntity, use: boolean) {
		const entry = this.actionCache.get(action);
		if (entry) {
			entry.useExtraActions = use;
		}
	}

	invalidateCache() {
		this.destroy$.next();
		this.actionCache = new WeakMap<
			ActionBaseEntity,
			{ useExtraActions: boolean; actions$: Observable<Array<ActionBaseEntity>> }
		>();
	}

	setFixedValues(obj: { [index: string]: any }) {
		this.fixedValues = obj;
	}
}
