import { SevilleModule } from '../../../../../app/seville/seville.module';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { RbacControlState } from '../../../../../../rbac/models/rbac-control-settings.model';

const RBAC_TOOLTIP_MESSAGE = 'You do not have permission to perform this action.';

SevilleModule.directive('rbacjs', ['authService', rbacControlPermissionsDirective]);

function rbacControlPermissionsDirective(authService) {
	return {
		restrict: 'A',
		scope: {
			state: '=',
			permissions: '=',
		},
		link: function(scope, element) {
			const hasRequiredPermission = scope.permissions.some(permission => {
				return authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum[permission]);
			});

			function protectElement() {
				if (!hasRequiredPermission) {
					if (scope.state === RbacControlState.hidden) {
						element.remove();
					} else if (scope.state === RbacControlState.disabled) {
						element.attr('title', RBAC_TOOLTIP_MESSAGE);
						const children = element.find('*');

						element.addClass('disabled-not-allowed');
						children.addClass('cursor-not-allowed');

						// remove previous event handlers
						element.off();
						children.off();

						const disableClick = $event => {
							$event.preventDefault();
							$event.stopImmediatePropagation();
						};

						// subscribe to these events so if anyone adds an eventlistener to the element, it'll stop here
						const events =
							'mouseover mouseenter mousemove mousedown mouseup click keydown keypress keyup';
						element.on(events, disableClick);
						children.on(events, disableClick);
					}
				}
			}

			if (!hasRequiredPermission) {
				protectElement();

				// if any children/descendants are added, we will need to disable them as well
				const mutationObserver = new MutationObserver(() => protectElement());
				mutationObserver.observe(element[0], { childList: true, subtree: true });
			}
		},
	};
}
