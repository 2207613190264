var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { DataviewField } from '@wcd/dataview';
import { TzDateComponent } from '../../shared/components/tz-date.component';
import { EntityNameComponent } from '../../global_entities/components/entity-name/entity-name.component';
import { EntityNamesComponent } from '../../global_entities/components/entity-name/entity-names.component';
var ScheduledHuntingActionsFields = /** @class */ (function () {
    function ScheduledHuntingActionsFields(i18nService) {
        var _this = this;
        this.i18nService = i18nService;
        // field list, keyed by id. Use 'Map' as it preserves the insertion order
        this._fields = [
            {
                id: 'startTime',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.startTime'),
                sort: {
                    sortDescendingByDefault: true,
                    getLocalSortValue: 'startTime',
                },
                component: {
                    type: TzDateComponent,
                    getProps: function (actionRun) { return ({ date: actionRun.startTime }); },
                },
            },
            {
                id: 'impactedAsset',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.impactedAsset'),
                className: EntityNameComponent.entityNameDefaultCssClass,
                component: {
                    type: EntityNameComponent,
                    getProps: function (actionRun) {
                        return _this.getEntityProps(actionRun.impactedAsset);
                    },
                },
            },
            {
                id: 'actionType',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.actionType'),
                getDisplay: function (actionRun) {
                    return _this.i18nService.get("hunting.customDetections.actions." + actionRun.actionType);
                },
            },
            {
                id: 'targetEntities',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.targetEntity'),
                className: EntityNameComponent.entityNameDefaultCssClass,
                component: {
                    type: EntityNamesComponent,
                    getProps: function (actionRun) { return ({
                        entities: actionRun.entities.map(function (entity) {
                            return _this.getEntityProps(entity, { showPrettyName: false });
                        }),
                    }); },
                },
            },
            {
                id: 'status',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.status'),
                getDisplay: function (actionRun) {
                    return _this.i18nService.get(actionRun.status.nameI18nKey);
                },
                icon: {
                    fabricIcon: function (actionRun) { return actionRun.status.iconName; },
                    className: function (actionRun) {
                        return "color-text-" + actionRun.status.className;
                    },
                },
                getImage: function (actionRun) { return actionRun.status.image; },
                getTooltip: function (actionRun) { return actionRun.failureReason; },
            },
            {
                id: 'failureReason',
                name: this.i18nService.get('hunting.customDetections.actionRuns.fields.failureReason'),
                sort: { enabled: false },
            },
        ].reduce(function (map, x) { return map.set(x.id, x); }, new Map());
    }
    Object.defineProperty(ScheduledHuntingActionsFields.prototype, "dataTableFields", {
        get: function () {
            if (!this._dataTableFields) {
                this._dataTableFields = DataviewField.fromList([
                    this._fields.get('actionType'),
                    __assign({}, this._fields.get('targetEntities'), { maxWidth: 200 }),
                    this._fields.get('status'),
                ].map(function (field) { return (__assign({}, field, { sort: { enabled: false } })); }));
            }
            return this._dataTableFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingActionsFields.prototype, "dataviewFields", {
        get: function () {
            if (!this._dataviewFields) {
                this._dataviewFields = DataviewField.fromList(Array.from(this._fields.values()).filter(function (field) { return field.id != 'impactedAsset'; }));
            }
            return this._dataviewFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingActionsFields.prototype, "bulkActionsFields", {
        get: function () {
            if (!this._bulkActionsFields) {
                var wideColumnWidth = 230;
                var fields = [
                    __assign({}, this._fields.get('impactedAsset'), { maxWidth: wideColumnWidth }),
                    this._fields.get('actionType'),
                    __assign({}, this._fields.get('targetEntities'), { maxWidth: 500 }),
                    __assign({}, this._fields.get('status'), { minWidth: 130 }),
                    __assign({}, this._fields.get('failureReason'), { minWidth: wideColumnWidth }),
                ];
                this._bulkActionsFields = DataTableField.fromList(fields.map(function (field) { return (__assign({}, field, { sort: { enabled: false } })); }));
            }
            return this._bulkActionsFields;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingActionsFields.prototype.getEntityProps = function (entity, options) {
        if (options === void 0) { options = null; }
        return (entity && {
            entity: {
                senseMachineId: entity.id,
                id: entity.id,
                fileName: entity.id,
                displayName: entity.id,
                name: entity.id,
            },
            entityName: options && options.showPrettyName === false ? null : entity.name,
            entityTypeId: entity.entityType.type,
            linkClass: 'field-value-link',
        });
    };
    return ScheduledHuntingActionsFields;
}());
export { ScheduledHuntingActionsFields };
