import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	readonly: true,
	singularName: 'Device any requests active',
	pluralName: '',
})
export class MachineAnyRequestsActive extends ModelBase {
	@EntityField({ data: '__self' })
	readonly isActive: boolean;
}
