import { Template } from '@angular/compiler/src/render3/r3_ast';
import { ChangeDetectionStrategy, Component, Input, OnInit, TemplateRef } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataviewActionTypes } from './dataview-actions.model';

export interface DataviewActionContentConfig {
	template: TemplateRef<any>;
	actionType: DataviewActionTypes;
}

@Component({
	selector: 'dataview-action-content',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="hidden-action">
			<div class="template-wrapper">
				<ng-container
					[ngTemplateOutlet]="template"
					[ngTemplateOutletContext]="{ smallScreenView: smallScreenView }"
				></ng-container>
			</div>
		</div>
	`,
	styles: [
		`
			.template-wrapper {
				min-height: 40px;
				display: flex;
			}
		`,
	],
})
export class DataviewActionContentComponent {
	@Input() contentActionConfig: DataviewActionContentConfig;
	@Input() smallScreenView: boolean = false;
	@Input() isHidden: boolean = true;

	public template: TemplateRef<any>;

	constructor() {}

	ngOnInit() {
		Object.assign(this, { ...this.contentActionConfig });
	}
}
