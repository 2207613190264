import {
	FilterValuesChecklistComponent,
	FilterValuesChecklistData,
	FilterValuesChecklistSelection,
} from '../checklist/filter-values.checklist.component';
import {
	ChangeDetectionStrategy,
	Component,
	ViewChild,
	ChangeDetectorRef,
	Input,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { ObservableInput, from, Subject, Observable, Subscription } from 'rxjs';
import { FilterValuesCheckListConfig } from '../checklist/filter-values.checklist.config';
import { finalize } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { SerializedFilters } from '../../../models/serialized-filters.type';
import { ListFilterValue } from '../list-filter-value.model';

@Component({
	template: `
		<wcd-select
			[(ngModel)]="currentDropdownSelection"
			[values]="dropdownValues"
			[formatLabel]="config?.dropdown?.formatLabel"
			[label]="config?.dropdown?.label"
			[isFullWidth]="true"
			[placeholder]="config?.dropdown?.placeholder"
			(ngModelChange)="onDropdownSelect()"
		></wcd-select>
		<div class="wcd-padding-top">
			<fab-spinner
				*ngIf="(isLoading$ | async); else checklist"
				[label]="loadingText$ | async"
				[size]="spinnerSize"
			></fab-spinner>
			<ng-template #checklist>
				<span *ngIf="error; else checklistContents">{{ error }}</span>
				<ng-template #checklistContents>
					<wcd-filter-values-checklist
						(filterValuesChange)="onChecklistSelectionChange($event)"
						[fieldId]="fieldId"
						[data]="currentChecklistValues"
						[config]="checklistConfig"
						[selectedValues]="selectedValues.checklist"
					></wcd-filter-values-checklist>
				</ng-template>
			</ng-template>
		</div>
	`,
	selector: 'wcd-filter-values-dropdown-combo',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilterValuesDropdownComboComponent<
	TValue extends string | number | boolean,
	TDropdownValue = any
>
	extends FilterValuesComponent<
		Array<TDropdownValue>,
		FilterValuesDropdownComboSelection<TValue, TDropdownValue>,
		{ dropdown: TDropdownValue; checklist: ReadonlyArray<ListFilterValue<TValue>> },
		FilterValuesDropdownComboConfig<TValue, TDropdownValue>
	>
	implements OnInit, OnDestroy {
	currentDropdownSelection: TDropdownValue;
	private _dropdownValues: Array<TDropdownValue>;

	@Input()
	set data(data: Array<TDropdownValue>) {
		this._dropdownValues = data;
		this.changeDetectorRef.markForCheck();
	}

	get data(): Array<TDropdownValue> {
		return this._dropdownValues;
	}

	@Input() currentChecklistValues: FilterValuesChecklistData<TValue>;

	get value(): { dropdown: TDropdownValue; checklist: ReadonlyArray<ListFilterValue<TValue>> } {
		return {
			dropdown: this.currentDropdownSelection,
			checklist: this.checklist.value,
		};
	}

	error: string;
	readonly isLoading$: Subject<boolean> = new Subject<boolean>();
	readonly loadingText$: Subject<string> = new Subject<string>();
	readonly spinnerSize: SpinnerSize = SpinnerSize.small;

	get checklistConfig(): FilterValuesCheckListConfig {
		return {
			...((this.config && this.config.checklist) || {}),
			allowUnknownValues: false,
		};
	}

	get dropdownValues(): Array<TDropdownValue> {
		return (
			(this.config && this.config.dropdown && this.config.dropdown.values) || this._dropdownValues || []
		);
	}

	@ViewChild(FilterValuesChecklistComponent, { static: false }) checklist: FilterValuesChecklistComponent<
		TValue
	>;

	private _getDropdownValuesSubscription: Subscription;

	constructor(private changeDetectorRef: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		if (!this.selectedValues) this.selectedValues = { checklist: [] };

		if (this.config) {
			if (this.config.dropdown && this.config.dropdown.getValues) {
				this._getDropdownValuesSubscription = this.config.dropdown.getValues().subscribe(values => {
					this._dropdownValues = values;
				});
			}

			if (this.config.defaultValue) {
				this.selectedValues = {
					...this.selectedValues,
					dropdown: this.config.defaultValue,
				};

				this.currentDropdownSelection = this.config.defaultValue;
				this.onDropdownSelect();
			}

			if (typeof this.config.loadingText === 'string') this.loadingText$.next(this.config.loadingText);
		}
	}

	ngOnDestroy(): void {
		if (this._getDropdownValuesSubscription) this._getDropdownValuesSubscription.unsubscribe();
	}

	onDropdownSelect(clearChecklistSelection = true) {
		this.error = null;

		if (clearChecklistSelection) {
			this.setChecklistSelection([]);
			this.checklist.setSelectedValues([]);
		}

		this.currentChecklistValues = { values: [] };

		if (this.currentDropdownSelection) {
			this.isLoading$.next(true);

			if (this.config && this.config.loadingText instanceof Function)
				this.loadingText$.next(this.config.loadingText(this.currentDropdownSelection));

			from(this.config.getValues(this.currentDropdownSelection))
				.pipe(
					finalize(() => {
						this.isLoading$.next(false);
						this.changeDetectorRef.markForCheck();
					})
				)
				.subscribe(
					(checklistValues: FilterValuesChecklistData) => {
						this.currentChecklistValues = checklistValues;
						if (checklistValues.values.length === 1)
							this.checklist.setSelectedValues([checklistValues.values[0].value]);
					},
					error => (this.error = error)
				);
		}
	}

	onChecklistSelectionChange($event: FilterValuesChecklistSelection<TValue>) {
		this.setChecklistSelection($event);
	}

	setChecklistSelection(checklistSelection: FilterValuesChecklistSelection<TValue>) {
		this.selectedValues = {
			dropdown: this.currentDropdownSelection,
			checklist: checklistSelection,
		};

		this.filterValuesChange.emit(this.selectedValues);
	}

	setSelectedValues(selectedValues: FilterValuesDropdownComboSelection<TValue, TDropdownValue>) {
		this.selectedValues =
			selectedValues instanceof Array
				? { checklist: selectedValues }
				: selectedValues || { checklist: [] };
		this.currentDropdownSelection = this.selectedValues.dropdown;

		if (this.checklist) this.checklist.setSelectedValues(this.selectedValues.checklist);

		this.onDropdownSelect(false);
		this.changeDetectorRef.markForCheck();
	}

	serialize(): SerializedFilters {
		return this.checklist.serialize();
	}

	deserialize(
		serializedSelectedValues: SerializedFilters
	): FilterValuesDropdownComboSelection<TValue, TDropdownValue> {
		return {
			checklist: this.checklist ? this.checklist.deserialize(serializedSelectedValues) : null,
		};
	}
}

export interface FilterValuesDropdownComboConfig<
	TValue extends string | number | boolean,
	TDropdownValue = any
> {
	dropdown?: {
		formatLabel?: (value: TDropdownValue) => string;
		label?: keyof TDropdownValue;
		placeholder?: string;
		values?: Array<TDropdownValue>;
		getValues?: () => Observable<Array<TDropdownValue>>;
	};
	defaultValue?: TDropdownValue;
	checklist?: FilterValuesCheckListConfig;
	getValues: (dropdownValue: TDropdownValue) => ObservableInput<FilterValuesChecklistData<TValue>>;
	loadingText?: string | ((selectedDropdownValue: TDropdownValue) => string);
}

export interface FilterValuesDropdownComboSelection<TValue = any, TDropdownValue = any> {
	dropdown?: TDropdownValue;
	checklist: FilterValuesChecklistSelection<TValue>;
}
