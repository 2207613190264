import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { EffectiveVersions } from './remediation-mitigation-effective-versions.value-object';
import { MitigationType } from './remediation-mitigation-type.enum';
@ValueObject({
	singularName: 'Mitigation Action',
	pluralName: 'Mitigation Actions',
})
export class Mitigation extends ModelBase {
	@EntityField() readonly mitigationAction: MitigationType;

	@EntityField() readonly mitigationLevel: string;

	@EntityField() readonly effectiveVersions: EffectiveVersions[];

	@EntityField() readonly notificationBody: string;

	@EntityField() readonly effectiveMitigationAction: MitigationType;

	@EntityField() readonly notificationUrl: string;
}
