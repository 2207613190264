var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, EntityRelationship, EntityModelBase, RelationshipType, EntityField, ValueObject, } from '@microsoft/paris';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { airsEntityRemediationStatuses } from '../airs_entity/airs-entity-remediation-status.entity';
import { Alert } from '../alert/alert.entity';
import { Investigation } from '../investigation/investigation.entity';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { Machine } from '../machine/machine.entity';
import { IncidentGraph } from './graph/incident-graph.value-object';
import { Incident, convertInvestigationStatusParameter } from './incident.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Mailbox } from '../mailbox/mailbox.entity';
import { isNil, keyBy, omit } from 'lodash-es';
import { EvidenceEntity } from '../evidence/evidence.entity';
import { IncidentImpactedEntities } from "./incident.impacted-entities";
import { Application } from '../application/application-entity';
export var DEFAULT_LOOKBACK_IN_DAYS_VALUE = 180;
var ɵ0 = function (_, query) { return "incidents/" + query.where['id'] + "/impactedEntitiesView"; }, ɵ1 = function (config) { return config.data.serviceUrls.incidentImpactedEntitiesView; };
var IncidentImpactedEntitiesRelationship = /** @class */ (function () {
    function IncidentImpactedEntitiesRelationship() {
    }
    IncidentImpactedEntitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: IncidentImpactedEntities,
            endpoint: ɵ0,
            foreignKey: 'id',
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ1,
            cache: {
                time: 1000 * 60,
                max: 10,
            }
        })
    ], IncidentImpactedEntitiesRelationship);
    return IncidentImpactedEntitiesRelationship;
}());
export { IncidentImpactedEntitiesRelationship };
var ɵ2 = function (_, query) { return "incidents/" + query.where['IncidentId'] + "/devices"; }, ɵ3 = function (config) { return config.data.serviceUrls.incidentDevices; };
var IncidentMachinesRelationship = /** @class */ (function () {
    function IncidentMachinesRelationship() {
    }
    IncidentMachinesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: Machine,
            endpoint: ɵ2,
            foreignKey: 'IncidentId',
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ3,
        })
    ], IncidentMachinesRelationship);
    return IncidentMachinesRelationship;
}());
export { IncidentMachinesRelationship };
var parseDataViewQuery = function (dataQuery) {
    var where = (dataQuery && dataQuery.where ? dataQuery.where : {});
    var lookBackInDays = { lookBackInDays: DEFAULT_LOOKBACK_IN_DAYS_VALUE };
    var pageSettings = {};
    if (dataQuery.page)
        pageSettings.pageIndex = dataQuery.page;
    if (dataQuery.pageSize)
        pageSettings.pageSize = dataQuery.pageSize;
    if (where) {
        convertInvestigationStatusParameter(true, where);
    }
    return Object.assign({}, omit(where, ['ordering', 'page_size', 'page']), lookBackInDays, pageSettings, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
};
var ɵ4 = parseDataViewQuery;
var ɵ5 = function (dataView) {
    var query = parseDataViewQuery(dataView);
    return __assign({}, query, { groupType: 'GroupHash' });
}, ɵ6 = function (rawData) { return ({
    items: rawData.Items,
    count: rawData.TotalResults,
}); };
var IncidentAlertsRelationship = /** @class */ (function () {
    function IncidentAlertsRelationship() {
    }
    IncidentAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: Alert,
            endpoint: 'AssociatedAlerts',
            parseDataQuery: ɵ5,
            foreignKey: 'IncidentId',
            allowedTypes: [RelationshipType.OneToMany],
            parseData: ɵ6,
        })
    ], IncidentAlertsRelationship);
    return IncidentAlertsRelationship;
}());
export { IncidentAlertsRelationship };
var IncidentUsersRelationship = /** @class */ (function () {
    function IncidentUsersRelationship() {
    }
    IncidentUsersRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: LegacyUser,
            endpoint: 'AssociatedUsers',
            foreignKey: 'IncidentId',
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], IncidentUsersRelationship);
    return IncidentUsersRelationship;
}());
export { IncidentUsersRelationship };
var ɵ7 = function (_, query) { return "incidents/" + query.where['id'] + "/users"; }, ɵ8 = function (config) { return config.data.serviceUrls.incidentUsers; };
var IncidentAadUsersRelationship = /** @class */ (function () {
    function IncidentAadUsersRelationship() {
    }
    IncidentAadUsersRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: AadUser,
            foreignKey: 'id',
            endpoint: ɵ7,
            parseDataQuery: parseDataViewQuery,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ8,
        })
    ], IncidentAadUsersRelationship);
    return IncidentAadUsersRelationship;
}());
export { IncidentAadUsersRelationship };
var UsersSummary = /** @class */ (function (_super) {
    __extends(UsersSummary, _super);
    function UsersSummary() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'users', arrayOf: AadUser }),
        __metadata("design:type", Array)
    ], UsersSummary.prototype, "users", void 0);
    __decorate([
        EntityField({ data: 'userGroups' }),
        __metadata("design:type", Array)
    ], UsersSummary.prototype, "userGroups", void 0);
    UsersSummary = __decorate([
        ValueObject({
            singularName: 'Users summary',
            pluralName: 'Users summaries',
        })
    ], UsersSummary);
    return UsersSummary;
}(EntityModelBase));
export { UsersSummary };
var ɵ9 = function (_, query) { return "incidents/" + query.where['id'] + "/userSummary"; }, ɵ10 = function (config) { return config.data.serviceUrls.threatIntel; };
var IncidentUsersSummaryRelationship = /** @class */ (function () {
    function IncidentUsersSummaryRelationship() {
    }
    IncidentUsersSummaryRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: UsersSummary,
            foreignKey: 'id',
            endpoint: ɵ9,
            baseUrl: ɵ10,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
        })
    ], IncidentUsersSummaryRelationship);
    return IncidentUsersSummaryRelationship;
}());
export { IncidentUsersSummaryRelationship };
var ɵ11 = function (_, query) { return "incidents/" + query.where['id'] + "/mailboxes"; }, ɵ12 = function (config) { return config.data.serviceUrls.incidentMailboxes; };
var IncidentMailboxesRelationship = /** @class */ (function () {
    function IncidentMailboxesRelationship() {
    }
    IncidentMailboxesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: Mailbox,
            foreignKey: 'id',
            endpoint: ɵ11,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ12,
        })
    ], IncidentMailboxesRelationship);
    return IncidentMailboxesRelationship;
}());
export { IncidentMailboxesRelationship };
var ɵ13 = function (_, query) {
    if (query && query.where && query.where['useMtpApi']) {
        return "incidents/" + query.where['IncidentId'];
    }
    return 'AssociatedInvestigations';
}, ɵ14 = function (config, query) {
    if (query && query.where && query.where['useMtpApi']) {
        return config.data.serviceUrls.automatedIr;
    }
    return config.data.serviceUrls.threatIntel;
};
var IncidentInvestigationsRelationship = /** @class */ (function () {
    function IncidentInvestigationsRelationship() {
    }
    IncidentInvestigationsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: Investigation,
            endpoint: ɵ13,
            foreignKey: 'IncidentId',
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ14,
        })
    ], IncidentInvestigationsRelationship);
    return IncidentInvestigationsRelationship;
}());
export { IncidentInvestigationsRelationship };
var ɵ15 = function (config, query) {
    return "entities/evidence/" + query.where['incident_id'] + "/results";
}, ɵ16 = function (incident) { return ({
    incident_id: incident.id,
    investigation_id: incident.investigationIds,
}); }, ɵ17 = function (dataQuery) {
    return omit(dataQuery.where, ['investigation_id', 'allowed_statuses']);
}, ɵ18 = function (data, config, query) {
    if (query.where['RemediationStatusSplit']) {
        return { data: castStatusToRemediationStatus(data.data) };
    }
    return data;
}, ɵ19 = function (config) { return config.data.serviceUrls.automatedIr; };
var IncidentEntityResultsRelationship = /** @class */ (function () {
    function IncidentEntityResultsRelationship() {
    }
    IncidentEntityResultsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: AirsEntityTypeResults,
            allowedTypes: [RelationshipType.OneToMany],
            endpoint: ɵ15,
            allItemsProperty: 'data',
            separateArrayParams: true,
            getRelationshipData: ɵ16,
            parseDataQuery: ɵ17,
            parseData: ɵ18,
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ19,
        })
    ], IncidentEntityResultsRelationship);
    return IncidentEntityResultsRelationship;
}());
export { IncidentEntityResultsRelationship };
var IncidentEntityRelationship = /** @class */ (function () {
    function IncidentEntityRelationship() {
    }
    IncidentEntityRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: EvidenceEntity,
            foreignKey: 'incident_id',
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], IncidentEntityRelationship);
    return IncidentEntityRelationship;
}());
export { IncidentEntityRelationship };
var ɵ20 = function (_, query) { return "incidents/" + query.where['id'] + "/graph"; }, ɵ21 = function (config) { return config.data.serviceUrls.threatIntel; };
var IncidentGraphRelationship = /** @class */ (function () {
    function IncidentGraphRelationship() {
    }
    IncidentGraphRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: IncidentGraph,
            allowedTypes: [RelationshipType.OneToOne],
            foreignKey: 'id',
            endpoint: ɵ20,
            baseUrl: ɵ21,
            timeout: 240000,
        })
    ], IncidentGraphRelationship);
    return IncidentGraphRelationship;
}());
export { IncidentGraphRelationship };
var airsEntityRemediationStatusesMap = keyBy(airsEntityRemediationStatuses, 'id');
// remap legacy statuses to new remediation status
function castStatusToRemediationStatus(entityResults) {
    return entityResults.map(function (item) {
        return Object.assign({}, item, item.data && {
            data: item.data.map(function (r) {
                return Object.assign({}, r, {
                    remediation_status: isNil(r.result)
                        ? r.result
                        : airsEntityRemediationStatusesMap[r.result] && r.result,
                });
            }),
        });
    });
}
var ɵ22 = function (_, query) { return "incidents/" + query.where['id'] + "/apps"; }, ɵ23 = function (config) { return config.data.serviceUrls.incidentApps; };
var IncidentAppsRelationship = /** @class */ (function () {
    function IncidentAppsRelationship() {
    }
    IncidentAppsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Incident,
            dataEntity: Application,
            foreignKey: 'id',
            endpoint: ɵ22,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ23,
        })
    ], IncidentAppsRelationship);
    return IncidentAppsRelationship;
}());
export { IncidentAppsRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23 };
