import { combineLatest, forkJoin, Observable, Observer, Subscriber } from 'rxjs';
import { Injectable, Injector, Type } from '@angular/core';
import { IPreload, PreloadError } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import { NotificationsService } from '../../notifications/services/notifications.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { CommunityService } from '../../community/services/community.service';
import { Location } from '@angular/common';
import { publishReplay, refCount, share } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';

@Injectable()
export class PreloadService {
	preloadStartTime: Date;
	preloadTime: number;

	private _preload$: Observable<any>;
	private basicPreloadServices: Array<Type<IPreload>> = [I18nService];
	private allPreloadServices: PreloadTree = {
		services: [AuthService],
		children: {
			services: [AppInsightsService, FeaturesService],
			children: {
				services: [PreferencesService, CommunityService],
				children: {
					services: [NotificationsService],
				},
			},
		},
	};

	constructor(
		private location: Location,
		private injector: Injector,
		private featuresService: FeaturesService
	) {}

	get preload$(): Observable<any> {
		if (!this._preload$) this._preload$ = this.preload();

		return this._preload$;
	}

	private preload(): Observable<any> {
		this.preloadStartTime = new Date();

		const basicPreloads$ = forkJoin(
			this.basicPreloadServices.map(service => {
				return this.injector.get(service).init();
			})
		);
		let preloadObserver: Subscriber<any>;
		const preloadObservable: Observable<any> = new Observable(observer => (preloadObserver = observer));

		const allPreloads$ = this.initServices(
			/^\/Error/.test(this.location.path())
				? { services: [AppInsightsService] }
				: this.allPreloadServices
		);

		basicPreloads$.subscribe(
			(value: any) => preloadObserver.next,
			(error: Error | Response | PreloadError) => preloadObserver.error(error),
			() => {
				allPreloads$.subscribe(
					(value: any) => {
						preloadObserver.next(value);
					},
					(error: Error | Response | PreloadError) => preloadObserver.error(error),
					() => {
						if (!this.preloadTime)
							this.preloadTime = new Date().valueOf() - this.preloadStartTime.valueOf();

						preloadObserver.complete();
					}
				);
			}
		);

		return preloadObservable.pipe(
			share(),
			publishReplay(1),
			refCount()
		);
	}

	initServices(root: PreloadTree): Observable<any> {
		return new Observable((observer: Observer<any>) => {
			const featuresEnabled: boolean =
				!root.features || this.featuresService.isAnyEnabled(root.features);

			if (featuresEnabled && root.services.length) {
				combineLatest(root.services.map(service => this.injector.get(service).init())).subscribe(
					values => {
						observer.next(values);
						if (root.children) {
							this.initServices(root.children).subscribe(
								values => {
									observer.next(values);
								},
								error => {
									if (root.onError) {
										root.onError(error);
										observer.complete();
									} else observer.error(error);
								},
								() => {
									observer.complete();
								}
							);
						} else observer.complete();

						return values;
					},
					error => {
						if (root.onError) {
							root.onError(error);
							observer.complete();
						} else observer.error(error);
					}
				);
			} else observer.complete();
		});
	}
}

interface PreloadTree {
	services: Array<Type<IPreload>>;
	children?: PreloadTree;
	features?: Array<string>;
	onError?: (error: Error) => any;
}
