<div class="wcd-full-height large-form">
	<form #customTiIndicatorForm="ngForm" (submit)="saveRule()" (keydown.enter)="$event.preventDefault()" class="wcd-full-height wcd-flex-vertical">
		<header class="wcd-flex-none wcd-padding-all">
			<div class="wcd-flex-center-vertical wcd-flex-flex-horizontal">
				<div class="page-header-icon wcd-flex-none">
					 <div class="round-icon color-box-gray-200">
						<fab-icon  [iconName]="customTiIcon"></fab-icon>
					 </div>
				</div>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title">{{title}}</h3>
				</div>
			</div>
			<div class="wcd-margin-top">
				<tabs tabsKind="large"
						[tabsData]="tabs"
						[currentTab]="currentTab.id"
						(select)="onTabChanged($event.tab)"></tabs>
			</div>
		</header>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
			<div *ngIf="currentTab.id === Tabs.details">
				<section class="wcd-padding-bottom">
						 <h4 class="definition-list-title">
						 {{ "customTiIndicator.detailsSidePane.sections.tidetails.title" | i18n}}
					</h4>
					<div class="dialog-control form-group wcd-flex-horizontal">
						<p class="dialog-control-description subtle">
								{{ "customTiIndicator.detailsSidePane.sections.tidetails.helpKey" | i18n:{entityTypeName: entitySingularName} }}
						</p>
						<a [href]="indicatorDocUrl" target="_blank" class="wcd-padding-xsmall-left" [attr.aria-label]="'customTiIndicator.detailsSidePane.sections.tidetails.learnmoreAbout' | i18n:{entityTypeName: entitySingularName}">
							{{ "customTiIndicator.detailsSidePane.sections.tidetails.learnmore" | i18n }}
						</a>
					</div>
					<div class="form-group">
							<label for="custom-ti-indicator-value" class="wcd-required">{{entitySingularNameCapitalized}}</label>
							<ng-container *ngIf="customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate; else certificates">
							<input type="text"
									 id="custom-ti-indicator-value"
									 [(ngModel)]="customTiIndicator.tiIndicator"
									 (ngModelChange)="onIndicatorChange()"
									 [disabled]="indicatorValue"
									 name="custom-ti-indicator-value"
									 class="form-control"
									 maxlength="100"
									 autofocus
									 required />
								</ng-container>
								<fab-message-bar *ngIf="showMd5IsNotRecommendedWarning" [messageBarType]="MessageBarType.warning" [styles]="{ text: { marginBottom: 16 } }">
									{{ 'customTiIndicator.detailsSidePane.errorMessages.warningMd5IsNotRecommended' | i18n }}
								</fab-message-bar>
								<ng-template #certificates>
									<input #certificateInput type="file"
												 id="systemExclusion_file"
												 name="systemExclusionFile"
												 (change)="onFileSelect($event, customTiIndicatorForm)"/>
									<div *ngIf="showInvalidFileSizeMessage" class="color-box-gray-100 wcd-flex-horizontal wcd-padding-small-all wcd-margin-large-bottom">
										<fab-icon iconName="Warning" contentClass="color-text-error"></fab-icon>
										<span class="wcd-margin-xsmall-left">{{ 'customTiIndicator.detailsSidePane.errorMessages.certificateFileTooLarge' | i18n }}</span>
									</div>
								</ng-template>
					</div>
					<div class="form-group">
								<label for="custom-ti-indicator-expiration-options">{{ 'customTiIndicator.dataview.entity.fields.expiresOn.title' | i18n}}</label>
								<wcd-radiolist [values]="expirationOptions" #focusable
										name="custom-ti-indicator-expiration-options"
										data-field-label="Expiry date"
										(ngModelChange)="onExpirationOptionsChange($event)"
										[(ngModel)]="currentExpirationOption"
									   [ariaLabel]="'customTiIndicator.dataview.entity.fields.expiresOn.title' | i18n"
								></wcd-radiolist>
								<div *ngIf = "customTiIndicator.expirationTime">
										<datepicker
											#focusable
											[(ngModel)]="localExpiration"
											name="custom-ti-indicator-expiration"
											[allowFutureDates]="true"
											[rawDate]="true"
											[earliestDateAllowed]="earliestDateAllowed"
											[showInPanel]="true">
										</datepicker>
								</div>
						</div>
				</section>
				<section class="wcd-padding-bottom" *ngIf="showImpactSection">
						<h4 class="definition-list-title">
								{{ "customTiIndicator.detailsSidePane.sections.impact.title" | i18n}}
						</h4>
						<div class="dialog-control form-group wcd-flex-horizontal">
								 <fab-icon iconName="Info" contentClass="wcd-padding-small-right"></fab-icon>
								 <p class="dialog-control-description subtle">
									 {{ "customTiIndicator.detailsSidePane.sections.impact.helpKey" | i18n }}
								 </p>
						</div>
						<div class="dialog-control form-group wcd-flex-horizontal" [class.disabled]="!isValidData">
												 <button class="btn btn-link wcd-padding-none-left" data-track-id="ImpactPreview'" data-track-type="Button" type="button"
														(click)="isValidData && getImpactPreview()" [wcdTooltip]="'customTiIndicator.detailsSidePane.sections.impact.preview.title' | i18n"
														[disabled]="!isValidData">
													 <fab-icon iconName="View" iconColor="blue"></fab-icon>
													 <span class="dialog-control-description subtle">
									{{ "customTiIndicator.detailsSidePane.sections.impact.preview.title" | i18n }}
								</span>
										</button>
							<div *ngIf="loadingImpact">
							 <i  class="loader-icon margin-right margin-left"></i>
							</div>
						</div>
						<div *ngIf="showNoImpactFoundMessage" class="dialog-control form-group">{{ 'customTiIndicator.detailsSidePane.sections.impact.preview.noimpact' | i18n}}</div>
					</section>
			</div>
			<div *ngIf="currentTab.id === Tabs.action">
				<section class="wcd-padding-bottom">
					<h4 class="definition-list-title">
						{{ "customTiIndicator.detailsSidePane.sections.responseaction.title" | i18n}}
					</h4>
					<div class="dialog-control form-group wcd-flex-horizontal" *ngIf='isScopeVisible; else scope_not_visible'>
						<p class="dialog-control-description subtle">
								{{ "customTiIndicator.detailsSidePane.sections.responseaction.helpKey" | i18n:{entityTypeName: customTiIndicatorType.displayName} }}
						</p>
					</div>
					<div class="dialog-control form-group wcd-flex-horizontal" *ngIf="xplatIndicatorEnabled && (customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip || customTiIndicatorType.id === CustomTiIndicatorsTypes.Url)">
						<markdown>
							{{ "customTiIndicator.detailsSidePane.sections.responseaction.xplatContent" | i18n }}
						</markdown>
					</div>
					<ng-template #scope_not_visible>
						<div class="wcd-padding-mediumSmall-bottom subtle">
							<markdown>
								{{ "customTiIndicator.detailsSidePane.sections.responseaction.allDevices.helpKey" | i18n:{entityTypeName: customTiIndicatorType.displayName} }}
							</markdown>
						</div>
					</ng-template>
					<wcd-radiolist [values]="indicatorMachineComponentService.actionOptions" #focusable
						name="custom-ti-indicator-action-options"
						data-field-label="Actions"
						(ngModelChange)="onActionOptionsChange($event)"
						[(ngModel)]="indicatorMachineComponentService.currentActionOption"
						[wcdTooltipAllowHtmlRendering]="true"
						[ariaLabel]="'customTiIndicator.detailsSidePane.sections.responseaction.helpKey' | i18n:{entityTypeName: customTiIndicatorType.displayName}"></wcd-radiolist>
						<ng-container *ngTemplateOutlet="warnHelpKey"></ng-container>
				<ng-template #warnHelpKey>
					<fab-message-bar *ngIf="warningHelpKey" [messageBarType]="MessageBarType.warning" [styles]="{ text: { marginBottom: 16 } }">
						<markdown [data]="warningHelpKey | i18n"></markdown>
					</fab-message-bar>
				</ng-template>
				<div class="wcd-margin-top">
				 <wcd-checkbox #generateAlert *ngIf="tvmApplicationBlockEnabled && customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate"
				     [ngModel]="customTiIndicator.generateAlert"
				     name="custom-ti-generate-alert"
					 [label]="i18nService.strings.customTiIndicator_dataview_entity_fields_generateAlert_title"
					 [isDisabled]="isGenerateAlertDisabled"
					 (ngModelChange)="onGenerateAlertChange($event)"
				     [wcdTooltip]="i18nService.strings.customTiIndicator_dataview_entity_fields_generateAlert_title">
				 </wcd-checkbox>
				</div>
				</section>
				<section class="wcd-padding-bottom">
					<h4 class="definition-list-title">
						{{ 'customTiIndicator.detailsSidePane.sections.responseaction.' + (indicatorMachineComponentService.isAlertable ? 'alertdetails' :'details' ) + '.title' | i18n}}
					</h4>
					<div class="form-group">
					   <label for="custom-ti-alert-title" class="wcd-required">{{ 'customTiIndicator.dataview.entity.fields.tiTitle.' + (indicatorMachineComponentService.isAlertable ? 'alerttitle' :'title' ) | i18n}}</label>
					   <input type="text" #focusable
						    id="custom-ti-alert-title"
							[(ngModel)]="customTiIndicator.title"
							name="custom-ti-alert-title"
							class="form-control"
							maxlength="300"
							required/>
						</div>
				</section>
				<section class="wcd-padding-bottom" *ngIf="indicatorMachineComponentService.isAlertable">
					<div class="form-group" *ngIf="historicalDetectionEnabled">
						<wcd-checkbox #historicalDetection
						   [ngModel]="customTiIndicator.historicalDetection"
						   name="custom-ti-historicalDetection"
						   [label]="i18nService.strings.customTiIndicator_dataview_entity_fields_historicalDetection_title"
						   [wcdTooltip]="i18nService.strings.customTiIndicator_dataview_entity_fields_historicalDetection_title"
						   (ngModelChange)="onHistoricalDetectionChange($event)">
						 </wcd-checkbox>
						 <fab-message-bar [messageBarType]="MessageBarType.info" [styles]="{ text: { marginBottom: 16 } }" className="wcd-margin-small-top wrap-text">
							 {{ i18nService.strings.customTiIndicator_dataview_entity_fields_historicalDetection_helpKey }}
						 </fab-message-bar>
						 <div class="wcd-margin-small-top wrap-text" *ngIf="customTiIndicator.historicalDetection">
							 <div class="wcd-font-weight-semibold">
								 {{ i18nService.strings.customTiIndicator_dataview_entity_fields_lookBackPeriod_guideline }}
							 </div>
							 <div class="wcd-margin-small-top">
							   <fancy-select
							     name="custom-ti-lookBackPeriod"
								 [(ngModel)]="customTiIndicator.lookBackPeriod"
								 class="command-bar-item-dropdown"
								 labelClass="dropdown-width-small"
								 [isBordered]="true"
								 [values]="indicatorMachineComponentService.lookBackPeriodList"
								 [buttonIcon] ="'calendar'"
								 [formatLabel]="getLookBackPeriodLabel">
							   </fancy-select>
							 </div>
						 </div>
					 </div>
	 				 <div class="form-group">
							<label for="custom-ti-severity" class="wcd-required">{{ "customTiIndicator.dataview.entity.fields.alertSeverity.title" | i18n}}</label>
							<fancy-select
								[isBordered]="true"
								labelClass="dropdown-width-medium"
								[(ngModel)]="customTiIndicator.severity"
								name="custom-ti-severity"
								[formatLabel]="indicatorMachineComponentService.getFormatSeverityLabel"
								[ariaLabel]="'customTiIndicator.dataview.entity.fields.alertSeverity.title' | i18n"
								[trackBy]="id"
								[values]="severityList"></fancy-select>
					</div>
					<div class="form-group" *ngIf="mitreTechniquesEnabled">
						<label for="custom-ti-category">{{ i18nService.strings.customTiIndicator_dataview_entity_fields_alertCategory_title }}</label>
						<fancy-select
							[isBordered]="true"
							labelClass="dropdown-width-medium"
							[(ngModel)]="customTiIndicator.category"
							name="custom-ti-category"
							[formatLabel]="indicatorMachineComponentService.getFormatCategoryLabel"
							[ariaLabel]="i18nService.strings.customTiIndicator_dataview_entity_fields_alertCategory_title"
							[trackBy]="id"
							[values]="indicatorMachineComponentService.categoryList"
							(ngModelChange)="onCategoryChanged($event)">
						</fancy-select>
				    </div>

					<div class="form-group" *ngIf="mitreTechniquesEnabled && indicatorMachineComponentService.selectableMitreTechniques && indicatorMachineComponentService.selectableMitreTechniques.length">
						<label for="alertMitreTechnique">{{ i18nService.strings.hunting_scheduledMonitorSidePane_fields_alertMitreTechniques_title }}</label>
						<wcd-checklist-dropdown
					    			[(ngModel)]="customTiIndicator.mitreTechniques"
					    			name="alertMitreTechnique"
					    			labelClass="dropdown-width-medium"
					    			[buttonText]="getMitreTechniqueDropDownPlaceHolder()"
					    			[values]="indicatorMachineComponentService.selectableMitreTechniques">
					    </wcd-checklist-dropdown>
					</div>
					<div class="form-group">
						<label for="custom-ti-recommendations-title">{{ "customTiIndicator.dataview.entity.fields.recommendations" | i18n}}</label>
						<textarea type="text"
								id="custom-ti-recommendations-title"
								name="custom-ti-recommendations-title"
								class="form-control wcd-no-resize"
								rows="4"
								[(ngModel)]="customTiIndicator.recommendedActions">
						</textarea>
					</div>
				</section>
				<section class="wcd-padding-bottom" *ngIf="indicatorMachineComponentService.currentActionOption.id === CustomTiIndicatorActionsTypes.Warn">
				   <div class="form-group" *ngIf="customTiIndicatorType.id !== CustomTiIndicatorsTypes.Files">
				    	  <label for="custom-ti-bypassduration">{{ "customTiIndicator.dataview.entity.fields.bypassDuration" | i18n}}</label>
						  <input #bypassduration
						     type="number"
				    	     id="custom-ti-bypassduration"
							 [(ngModel)]="customTiIndicator.bypassDurationHours"
				    	     name="custom-ti-bypassduration"
				    	     class="form-control"
				    	     min="1"
							 [max]="MAX_BYPASSDURATION"
							 maxlength="3"
							 pattern="(^$|^[0-9]{1,3}$)"/>
				   </div>
				   <div class="form-group">
					    <label for="custom-ti-educateurl">{{ "customTiIndicator.dataview.entity.fields.educateUrl" | i18n}}</label>
				    	  <input type="text"
				    	     id="custom-ti-educateurl"
				      	     [(ngModel)]="customTiIndicator.educateUrl"
				    	     name="custom-ti-educateurl"
				    	     class="form-control"
							 maxlength="400"
							 [pattern]="RegExpService.url"/>
				   </div>
				</section>
				<section>
					<div class="form-group">
						<label for="custom-ti-description-title" class="wcd-required">{{ "customTiIndicator.dataview.entity.fields.description" | i18n}}</label>
						<textarea type="text"
								 id="custom-ti-description-title"
								 name="custom-ti-description-title"
								 class="form-control wcd-no-resize"
								 rows="4"
								 [(ngModel)]="customTiIndicator.description"
								 required>
						</textarea>
				</div>
				</section>
			</div>
			<div *ngIf="currentTab.id === Tabs.groups">
						<section class="form-section">
							<h4 class="definition-list-title">
								 {{ "customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.title" | i18n}}
							</h4>
							<div class="dialog-control form-group wcd-flex-horizontal">
								<p class="dialog-control-description subtle">
									{{ "customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.helpKey" | i18n}}
								</p>
							 </div>
							 <ng-container *ngIf="indicatorMachineComponentService.loadingMachineGroups; else machineGroupsFields">
								<i class="loader-icon margin-right"></i>
									{{'notificationRules.machineGroups.loading' | i18n}}
							 </ng-container>

							 <ng-template #machineGroupsFields>
								<ng-container *ngIf="indicatorMachineComponentService.allowAllMachineGroups">
									 <wcd-radiolist [values]="indicatorMachineComponentService.selectableMachineGroupScopes" #focusable
													 name="custom-ti-machine-group-options"
													 data-field-label="Machine groups"
													 (ngModelChange)="onMachineGroupChange($event)"
												 [(ngModel)]="indicatorMachineComponentService.currentMachineGroupScope"
												 [ariaLabel]="'customTiIndicator.detailsSidePane.sections.organizationalscope.machinegroups.helpKey' | i18n">
									</wcd-radiolist>
								</ng-container>
								<ng-container *ngIf="indicatorMachineComponentService.allowSpecificMachineGroups && indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.specific">
											 <wcd-checklist-dropdown #focusable
										[id]="machineGroupsFieldId"
										[buttonText]="indicatorMachineComponentService.labelText"
										name="custom-ti-machine-groups"
										[buttonTooltip]="indicatorMachineComponentService.labelText"
										[selectById]="false"
										[ngModel]="indicatorMachineComponentService.currentMachineGroups"
											 [isFullWidth]="true"
										(ngModelChange)="onMachineGroupsChange($event)"
										[values]="indicatorMachineComponentService.availableMachineGroups"></wcd-checklist-dropdown>
								</ng-container>
								</ng-template>
						</section>
			</div>
			<div *ngIf="currentTab.id === Tabs.review">
				<section class="wcd-padding-bottom" *ngIf="isValidData">
					<h4 class="definition-list-title">
						{{ "customTiIndicator.detailsSidePane.sections.tidetails.title" | i18n}}
					</h4>
					<dl [class.key-values]="asKeyValueList" role="none">
								<ng-container *ngIf="customTiIndicator.tiIndicatorType">
									 <dt>{{ 'customTiIndicator.dataview.entity.fields.tiIndicatorType.' + customTiIndicator.tiIndicatorType.name.toLowerCase() + '.title' | i18n}}</dt>
								 <dd>
										{{customTiIndicator.tiIndicator}}
								 </dd>
							</ng-container>
							<dt>{{ 'customTiIndicator.dataview.entity.fields.expiresOn.title' | i18n}}</dt>
							<dd>
								<span *ngIf="isExpired;else datetemlate">
									 <wcd-shared-icon class="color-text-warning-dark margin-bottom" iconName="warningSolid"> </wcd-shared-icon >
									{{"customTiIndicator.dataview.entity.fields.expiresOn.values.expired" | i18n}}
								</span>

								<ng-template #datetemlate>
										<span *ngIf="customTiIndicator.expirationTime;else neverexpired">
											 {{localExpiration | date:'mediumDate' }}
											</span>
											<ng-template #neverexpired>
												{{"customTiIndicator.dataview.entity.fields.expiresOn.values.never" | i18n}}
											</ng-template>
										</ng-template>
							</dd>
						</dl>
				</section>
				<section class="wcd-padding-bottom">
						<h4 class="definition-list-title">
							{{ "customTiIndicator.detailsSidePane.sections.responseaction.title" | i18n}}
						</h4>
						<dl [class.key-values]="asKeyValueList" role="none">
							<ng-container *ngIf="customTiIndicator.action">
								 <dt>{{ "customTiIndicator.dataview.entity.fields.action.title" | i18n}}</dt>
								 <dd>
										 {{ 'customTiIndicator.dataview.entity.fields.action.values.' + customTiIndicator.action.name.toLowerCase() + '.title'| i18n}}
								 </dd>
							</ng-container>
							<ng-container *ngIf="customTiIndicator.title">
									<dt>{{ 'customTiIndicator.dataview.entity.fields.tiTitle.' + (indicatorMachineComponentService.isAlertable ? 'alerttitle': 'title') | i18n}}</dt>
									<dd>
									    {{ customTiIndicator.title }}
								    </dd>
							</ng-container>
							<ng-container *ngIf="customTiIndicator.historicalDetection">
								<dt>{{ i18nService.strings.customTiIndicator_dataview_entity_fields_historicalDetection_title }}</dt>
								<dd>
									{{ customTiIndicator.historicalDetection ? i18nService.strings.common_yes: i18nService.strings.common_no }}
								</dd>
								<dt>{{ i18nService.strings.customTiIndicator_dataview_entity_fields_lookBackPeriod_title }}</dt>
								<dd>
									{{ 'customTiIndicator_dataview_entity_fields_lookBackPeriod_' + customTiIndicator.lookBackPeriod | i18n }}
								</dd>
						    </ng-container>
							<ng-container *ngIf="customTiIndicator.severity">
									<dt>{{ "customTiIndicator.dataview.entity.fields.alertSeverity.title" | i18n}}</dt>
									<dd [ngClass]="'wcd-severity wcd-severity-' + customTiIndicator.severity.type">
										 {{ 'customTiIndicator.dataview.entity.fields.alertSeverity.values.' + customTiIndicator.severity.name.toLowerCase() + '.title'| i18n}}
								    </dd>
							</ng-container>

							<ng-container *ngIf="mitreTechniquesEnabled && customTiIndicator.category">
								<dt>{{ i18nService.strings.customTiIndicator_dataview_entity_fields_alertCategory_title }}</dt>
								<dd>
									 {{ indicatorMachineComponentService.getFormatCategoryLabel(customTiIndicator.category) }}
								</dd>
    						</ng-container>
						</dl>

						<ng-container *ngIf="mitreTechniquesEnabled && customTiIndicator.mitreTechniques && customTiIndicator.mitreTechniques.length">
							<div class="dialog-control-description ms-color-neutralPrimaryAlt wcd-font-weight-semibold">
								{{ i18nService.strings.hunting_scheduledMonitorSidePane_fields_alertMitreTechniques_title}}
							</div>
						   <div class="wcd-margin-small" *ngFor="let technique of customTiIndicator.mitreTechniques">{{technique | mitreTechniqueDisplay:false }}</div>
					   </ng-container>

						<dl [class.key-values]="asKeyValueList" role="none" class="wcd-margin-top">
							<ng-container *ngIf="indicatorMachineComponentService.currentActionOption.id === CustomTiIndicatorActionsTypes.Warn">
								<dt>{{ "customTiIndicator.dataview.entity.fields.bypassDuration" | i18n}}</dt>
								<dd>
									 {{ customTiIndicator.bypassDurationHours}}
								</dd>
								<dt>{{ "customTiIndicator.dataview.entity.fields.educateUrl" | i18n}}</dt>
								<dd>
									 {{ customTiIndicator.educateUrl}}
								</dd>
						    </ng-container>
						</dl>
				</section>
				<section class="wcd-padding-bottom" *ngIf='isScopeVisible'>
					<h4 class="definition-list-title">
						{{ "customTiIndicator.detailsSidePane.sections.organizationalscope.title" | i18n}}
					</h4>
						<div *ngIf="loadingMachineGroups; else machineGroups">
							<i class="loader-icon margin-right"></i>
								{{'notificationRules.machineGroups.loading' | i18n}}
						</div>
						<ng-template #machineGroups>
							 <div class="dialog-control-description ms-color-neutralPrimaryAlt wcd-font-weight-semibold">
								 {{ 'customTiIndicator.detailsSidePane.sections.organizationalscope.description.' + (indicatorMachineComponentService.currentMachineGroups && indicatorMachineComponentService.currentMachineGroups.length > 0 ? 'selected' : 'organization') | i18n}}
							</div>
							<div class="wcd-margin-small-bottom" *ngFor="let group of indicatorMachineComponentService.currentMachineGroups">{{group.name}}</div>
						</ng-template>
			    </section>
			</div>
		</div>
		<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
			<div class="wcd-flex-1 wcd-margin-right">
				<fab-default-button
				(onClick)="onNavButtonPressed(false)"
				data-track-type="Button"
				className="btn-secondary wcd-width-xs-small wcd-margin-small-right"
				[text]="'buttons.previous' | i18n"
				[disabled]="currentTabIndex === 0 || isSaving">
				</fab-default-button>
				<fab-default-button
					*ngIf="currentTabIndex !== tabs.length-1"
					[disabled]="currentTabIndex === tabs.length-1 || !isValid() || isSaving"
					(onClick)="onNavButtonPressed(true)"
					className="btn-secondary wcd-width-xs-small wcd-margin-small-right"
					[text]="'buttons.next' | i18n">
				</fab-default-button>
				<fab-primary-button
					*ngIf="currentTabIndex === tabs.length-1"
					[disabled]="currentTabIndex !== tabs.length-1"
					className="wcd-width-xs-small wcd-margin-small-right"
					data-track-type="Button"
					data-track-id="SaveCustomTiIndicator"
					[text]="'buttons.save' | i18n"
					type="submit">
				</fab-primary-button>
			</div>
			<div class="wcd-flex-none">
				<fab-default-button
					className="btn-secondary"
					(onClick)="cancel.emit()"
					data-track-type="Button"
					[text]="'buttons.cancel' | i18n">
				</fab-default-button>
			</div>
		</div>
	</form>
</div>
