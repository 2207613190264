<section class="wcd-padding-vertical wcd-padding-large-horizontal">
	<recommendation-exception-aggregated-entity-details *ngIf="!isGlobalException; else globalException"
		[recommendationExceptionAggregated]="recommendationExceptionAggregated">
	</recommendation-exception-aggregated-entity-details>

	<ng-template #globalException>
		<recommendation-exception-entity-details
			[recommendationException]="recommendationException">
		</recommendation-exception-entity-details>
	</ng-template>
</section>
