import {
	Component,
	ContentChild,
	ElementRef,
	forwardRef,
	Input,
	OnChanges,
	OnInit,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ChecklistValue } from '@wcd/forms';
import { find, isEqual, isObject } from 'lodash-es';

const CUSTOM_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => RadioListComponent),
	multi: true,
};

@Component({
	selector: 'radio-list',
	providers: [CUSTOM_ACCESSOR],
	template: `
		<ul
			class="radio-list"
			[ngClass]="{ 'wcd-flex-horizontal': isHorizontal }"
			role="radiogroup"
			[attr.aria-labelledby]="ariaLabelledby"
			[attr.aria-activedescendant]="value && 'radio-' + name + '_' + value.id"
			#radiolist
		>
			<li
				*ngFor="let item of values; let i = index"
				[ngClass]="isHorizontal && itemWidth ? 'wcd-width-' + itemWidth : liCustomClass"
				[tabIndex]="getTabIndex(item)"
				(keydown.arrowDown)="selectItem(i + 1)"
				(keydown.arrowRight)="selectItem(i + 1)"
				(keydown.arrowUp)="selectItem(i - 1)"
				(keydown.arrowLeft)="selectItem(i - 1)"
				role="radio"
				[attr.aria-checked]="item === value"
			>
				<div [ngClass]="!!getItemLabel(item) ? 'wcd-flex-vertical': 'wcd-flex-horizontal'">
					<input
						type="radio"
						[name]="'radio-' + name + '_' + item.id"
						[id]="'radio-' + name + '_' + item.id"
						[attr.value]="item.id"
						(change)="selectValue(item)"
						[disabled]="disabled || item.disabled"
						[checked]="item === value"
					/>
					<label
						[attr.for]="'radio-' + name + '_' + item.id"
						[class.disabled]="disabled || item.disabled"
					>
						<wcd-shared-icon
							[iconName]="'radio.' + (item === value ? 'checked' : 'unchecked')"
							[ariaLabel]="''"
							class="radio-btn-icon"
							[ngClass]="{
								'color-text-secondary': item === value,
								disabled: disabled || item.disabled
							}"
						>
						</wcd-shared-icon>
						<wcd-shared-icon *ngIf="getItemIcon(item)" [iconName]="getItemIcon(item)">
						</wcd-shared-icon>
						<span class="radio-btn-text" [ngClass]="labelClass">{{ getItemLabel(item) }}</span>
						<wcd-help *ngIf="item.helpKey" [text]="item.helpKey | i18n"></wcd-help>
					</label>
					<label
						[attr.for]="'radio-' + name + '_' + item.id"
						[class.disabled]="disabled || item.disabled"
					>
						<ng-container
							*ngTemplateOutlet="radioTemplate; context: { $implicit: item }"
						></ng-container>
					</label>
				</div>
				<hr
					*ngIf="isBordered"
					class="ms-border-color-neutralQuaternaryAlt wcd-margin-small-vertical"
				/>
			</li>
		</ul>
	`,
	styleUrls: ['./radiolist.component.scss'],
})
export class RadioListComponent implements ControlValueAccessor, OnInit, OnChanges {
	@Input() values: Array<ChecklistValue>;
	@Input() name: string;
	@Input() labelFieldName: string;
	@Input() iconFieldName: string;
	@Input() trackBy: string;
	@Input() disabled: boolean;
	@Input() isHorizontal: boolean = false;
	@Input() isSpaced: boolean = false;
	@Input() itemWidth: string = 'small';
	@Input() defaultValue: string;
	@Input() isBordered: boolean = false;
	@Input() labelClass: string;
	@Input() liCustomClass: string = '';
	@Input() ariaLabelledby?: string;

	@ViewChild('radiolist', { static: false }) radioListElement: ElementRef;
	@ContentChild('radioContent', { static: false }) readonly radioTemplate: TemplateRef<any>;
	value: ChecklistValue;

	private _tempValue: ChecklistValue;

	ngOnInit() {
		if (this.defaultValue) {
			this.writeValue(this.defaultValue);
		}
	}

	getItemLabel(item: ChecklistValue) {
		return (this.labelFieldName && item[this.labelFieldName]) || item.name;
	}

	getItemIcon(item: ChecklistValue) {
		return this.iconFieldName && item[this.iconFieldName];
	}

	ngOnChanges(changes) {
		if (changes.values) {
			this.writeValue(this._tempValue || this.value);
		}
	}

	selectValue(value) {
		this.value = value;
		this.onChange(this.value);
	}

	selectItem(index: number) {
		if (index >= 0 && index < this.values.length) {
			this.selectValue(this.values[index]);
			this.radioListElement.nativeElement.children[index].focus();
		}
	}

	onChange = (_: any) => {
	};
	onTouched = () => {
	};

	writeValue(value: any): void {
		if (this.hasValue(value)) this.value = value;
		else if (!isObject(value) && this.trackBy && this.values)
			this.value = find(this.values, _value => _value[this.trackBy] === value);

		if (this.value) {
			if (!value) this.value = null;
		} else {
			if (!this.values) this._tempValue = value;
			else this.value = null;
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	private hasValue(value: any): boolean {
		if (!this.values || value === undefined || value === null) return false;

		return !!find(this.values, _value => {
			if (this.trackBy && isObject(value) && isObject(_value))
				return value[this.trackBy] === _value[this.trackBy];

			return this.valuesAreEqual(_value, value);
		});
	}

	/**
	 * Testing equality in values, but without private members!
	 * @param val1
	 * @param val2
	 * @returns {boolean}
	 */
	private valuesAreEqual(val1, val2): boolean {
		const equals: boolean = isEqual(val1, val2);
		// since lodash's isEqual compares the objects without inherited properties,
		// only make deep comparison in case it considers the objects equal
		if (equals) {
			if (isObject(val1) && isObject(val2)) {
				for (const p in val1) {
					if (!val1.hasOwnProperty(p)) return !val2.hasOwnProperty(p);
					else {
						if (!val2.hasOwnProperty(p)) return false;
						if (/^[^_]/.test(p) && !/\$parent/.test(p)) {
							if (!this.valuesAreEqual(val1[p], val2[p])) return false;
						}
					}
				}
				return true;
			}
		}
		return equals;
	}

	getTabIndex(item: ChecklistValue) {
		if (!this.value) {
			return item === this.values[0] ? TAB_STOP : NO_TAB_STOP;
		}
		return item === this.value ? TAB_STOP : NO_TAB_STOP;
	}
}

const TAB_STOP = 0;
const NO_TAB_STOP = -1;
