var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient } from '@angular/common/http';
import * as FileSaver from 'file-saver';
import { Observable } from 'rxjs';
import { toObservable } from '../../utils/rxjs/utils';
import { take, tap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ErrorsDialogService } from '../../dialogs/services/errors-dialog.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { isMachineExportResponse } from "../../@entities/machines/services/machines.service";
var DownloadService = /** @class */ (function () {
    function DownloadService(http, errorsDialogService, i18nService, dialogsService) {
        this.http = http;
        this.errorsDialogService = errorsDialogService;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
    }
    DownloadService.prototype.download = function (blob$, filename) {
        return blob$
            .pipe(take(1), tap(function (data) {
            // TODO: remove file-saver once we drop support for IE, and then use an anchor tag with
            // a 'download' property (HTML5 standard)
            FileSaver.saveAs(data, filename);
        }))
            .toPromise();
    };
    DownloadService.prototype.downloadFromUrl = function (url, _a, showSnackbar) {
        var _b = _a === void 0 ? {} : _a, params = _b.params, _c = _b.isAuthenticated, isAuthenticated = _c === void 0 ? false : _c, downloadedFileName = _b.downloadedFileName, headers = _b.headers;
        if (showSnackbar === void 0) { showSnackbar = false; }
        return __awaiter(this, void 0, void 0, function () {
            var urlStr, downloadParams, urlParts, filename;
            return __generator(this, function (_d) {
                switch (_d.label) {
                    case 0:
                        if (showSnackbar) {
                            this.dialogsService.showSnackbar({
                                text: this.i18nService.get('grid_commands_exportToExcel_waitForDownload'),
                                icon: 'Info',
                            });
                        }
                        return [4 /*yield*/, toObservable(url)
                                .pipe(take(1))
                                .toPromise()];
                    case 1:
                        urlStr = _d.sent();
                        if (!isAuthenticated) return [3 /*break*/, 2];
                        downloadParams = this.getDownloadParams(params);
                        urlStr = urlStr + (downloadParams ? '?' + downloadParams : '');
                        this.downloadToIframe(urlStr);
                        return [3 /*break*/, 4];
                    case 2:
                        urlParts = urlStr.split('/'), filename = downloadedFileName
                            ? downloadedFileName
                            : urlParts[urlParts.length - 1].replace(/(\w+)\?.*/, '$1');
                        return [4 /*yield*/, this.download(this.http.get(urlStr, {
                                responseType: 'blob',
                                headers: headers,
                                params: params,
                            }), filename)];
                    case 3:
                        _d.sent();
                        _d.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DownloadService.prototype.downloadFromResponseUri = function (urlStr) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(urlStr).subscribe(function (response) {
                try {
                    var downloadUrl = isMachineExportResponse(response) ? response.sasUri : response;
                    _this.downloadToIframe(downloadUrl);
                    resolve(response);
                }
                catch (error) {
                    reject(error);
                }
            }, function (error) {
                if (error.status == 413) {
                    _this.errorsDialogService.showError({
                        title: _this.i18nService.strings.dataview_export_data_failed_title,
                        data: _this.i18nService.strings.dataview_export_data_failed_response_too_large,
                    });
                }
                else {
                    _this.errorsDialogService.showError({
                        title: _this.i18nService.strings.dataview_export_data_failed_title,
                        data: _this.i18nService.strings.dataview_export_data_failed,
                    });
                }
                reject(error);
            });
        });
    };
    DownloadService.prototype.downloadToIframe = function (urlStr) {
        try {
            if (!this._iframe) {
                this._iframe = document.createElement('iframe');
                this._iframe.style.display = 'none';
                document.body.appendChild(this._iframe);
            }
            // if url is authenticate (i.e with a token), open the link in a new tab and let the browser handle the download
            this._iframe.contentWindow.location.replace(urlStr);
        }
        catch (e) {
            if (this._iframe) {
                this._iframe.contentWindow.close();
            }
            throw e;
        }
    };
    DownloadService.prototype.dumpJsonFromUrlToNewTab = function (url) {
        return this.dumpJsonToNewTab(this.http.get(url));
    };
    DownloadService.prototype.dumpJsonToNewTab = function (data$) {
        return new Observable(function (observer) {
            var newTab = window.open('', '_blank');
            newTab.document.write('Loading...');
            data$.subscribe(function (data) {
                newTab.document.open();
                newTab.document.write('<html><body><pre></pre></body></html>');
                var pre = newTab.document.querySelector('pre');
                pre.innerText = JSON.stringify(data, null, '\t');
                newTab.document.close();
                newTab.focus();
                observer.next();
                observer.complete();
            }, function (err) {
                newTab.close();
                observer.error(err);
            });
        });
    };
    DownloadService.prototype.getDownloadParams = function (options) {
        if (!options)
            return '';
        var params = [];
        var _loop_1 = function (prop) {
            var val = options[prop];
            if (!(val instanceof Array)) {
                val = [val];
            }
            val.forEach(function (p) {
                params.push(prop + "=" + encodeURIComponent(p));
            });
        };
        for (var prop in options) {
            _loop_1(prop);
        }
        var paramsStr = params.join('&');
        return paramsStr || '';
    };
    return DownloadService;
}());
export { DownloadService };
