import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('behavior', behaviorFilter);

function behaviorFilter() {
	return function(input, behaviorCount) {
		if (!input) {
			return '';
		}

		var formattedBehavior = input.replace('{0}', behaviorCount);

		return formattedBehavior;
	};
}
