import { ThreatInfo, ThreatDescriptor } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from './tvm-texts.service';

export interface TvmIconsConfig {
	allowTooltipHTMLRender: boolean,
	exploitIcon: {
		show: boolean;
		hasExploit: boolean;
		tooltip?: string;
	};
	insightIcon: {
		show: boolean;
		hasInsight: boolean;
		tooltip?: string;
	};
	activeThreatIcon: {
		show: boolean;
		isThreatActive: boolean;
		tooltip?: string;
	};
}

export interface TvmIconsConfigWrapper {
	config: TvmIconsConfig;
}

@Injectable()
export class TvmIconBuilderService {
	constructor(private i18nService: I18nService, private tvmTextsService: TvmTextsService) {}

	// This method returns a wrapper around the ThreatIconsComponentConfig because that's how it's commonly used.
	// It can probably be designed better, but the refactoring is already big enough
	configureIcons(
		threatInfo: ThreatInfo,
		threats: ThreatDescriptor[],
		entityType: string,
		showInsights: boolean = false,
		insightTooltip: string = null,
		allowTooltipHTMLRender?: boolean
	): TvmIconsConfigWrapper {
		return {
			config: {
				allowTooltipHTMLRender,
				activeThreatIcon: {
					show: true,
					isThreatActive: threatInfo.isThreatActive,
					tooltip: this.tvmTextsService.getText(TextToken.ActiveThreatIconTooltip, {
						isThreatActive: threatInfo.isThreatActive,
						threats: threats,
						entityType: entityType,
					}),
				},
				exploitIcon: {
					show: true,
					hasExploit: threatInfo.hasExploit || threatInfo.isExploitLinkedToThreat,
					tooltip: this.tvmTextsService.getText(TextToken.ExploitIconToolTip, {
						threatInfo: threatInfo,
						threats: threats,
						entityType: entityType,
					}),
				},
				insightIcon: {
					show: showInsights,
					hasInsight: !!insightTooltip,
					tooltip: insightTooltip || this.i18nService.get('common.noDataAvailable'),
				},
			},
		};
	}
}
