var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
var ɵ0 = function (data) { return (data && data.toLowerCase() !== 'null' ? data : null); };
var AlertIPContext = /** @class */ (function (_super) {
    __extends(AlertIPContext, _super);
    function AlertIPContext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], AlertIPContext.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'IpAddress' }),
        __metadata("design:type", String)
    ], AlertIPContext.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], AlertIPContext.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'LastSeen' }),
        __metadata("design:type", Date)
    ], AlertIPContext.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({
            data: 'Sha1',
            parse: ɵ0,
        }),
        __metadata("design:type", String)
    ], AlertIPContext.prototype, "sha1", void 0);
    __decorate([
        EntityField({ data: 'Signer' }),
        __metadata("design:type", String)
    ], AlertIPContext.prototype, "signer", void 0);
    __decorate([
        EntityField({ data: 'IpAddress' }),
        __metadata("design:type", String)
    ], AlertIPContext.prototype, "ip", void 0);
    AlertIPContext = __decorate([
        ValueObject({
            singularName: 'Alert IP Context Item',
            pluralName: 'Alert IP Context Items',
        })
    ], AlertIPContext);
    return AlertIPContext;
}(ModelBase));
export { AlertIPContext };
export { ɵ0 };
