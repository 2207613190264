import { Component } from '@angular/core';
import { DataEntityType, Paris } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import { of } from 'rxjs';
import { InvestigationStatus, Alert } from '@wcd/domain';
import { ButtonsBarButton } from '@wcd/charts';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { ServiceUrlsService } from '@wcd/app-config';
import { Prettify } from '../../../../../utils/services/prettify.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportWidgetConfig } from '../../../../models/report-widget.model';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { getUserTimeZone } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
const WIDGET_API_URL = 'dashboard/automated_investigations_statistics';

@Component({
	selector: 'automated-investigations-statistics-widget',
	template: `
		<wcd-buttons-bar-widget
			*ngIf="appConfigService.isAutomatedIrEnabled; else enableAutoIr"
			[buttons]="data$ | async"
			[attr.data-track-component]="widgetConfig.id"
			[hidden]="error$ | async"
		></wcd-buttons-bar-widget>
		<ng-template #enableAutoIr>
			<enable-auto-ir></enable-auto-ir>
		</ng-template>
	`,
})
export class AutomatedInvestigationsStatisticsWidget extends ReportWidgetComponent<
	Array<ButtonsBarButton>,
	AutomatedInvestigationStatisticsBackendData
> {
	private _filterableInvestigationIds: string;

	get widgetConfig(): ReportWidgetConfig<
		Array<ButtonsBarButton>,
		AutomatedInvestigationStatisticsBackendData
	> {
		const rangeInDays: number = this.appConfigService.isDemoTenant
			? this.appConfigService.widgetLookback
			: 7;
		return Object.assign(
			{
				id: 'automatedInvestigationsStatistics',
				name: this.i18nService.get('widget_title_automatedInvestgationsStatistics'),
				noDataMessage: 'No automated investigations performed',
				noDataIcon: 'investigationOutline',
				rangeInDays: rangeInDays,
			},
			this.appConfigService.isAutomatedIrEnabled
				? {
						api: this.featuresService.isEnabled(Feature.AirsApiOffloading)
							? {
									url: () => `${this.serviceUrlsService.threatIntel}/${WIDGET_API_URL}`,
									isExternal: true,
									params: {
										lookingBackInDays: rangeInDays,
									},
							  }
							: {
									url: WIDGET_API_URL,
									params: {
										range: rangeInDays,
										timezone: getUserTimeZone(),
									},
							  },
						parseData: this.parseData.bind(this),
				  }
				: {
						loadData: () => of([null]),
				  }
		);
	}

	constructor(
		reportsService: ReportsService,
		private featuresService: FeaturesService,
		private serviceUrlsService: ServiceUrlsService,
		private paris: Paris,
		public appConfigService: AppConfigService,
		private prettyNumberService: PrettyNumberService,
		private i18nService: I18nService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {
		super(reportsService);
		this._filterableInvestigationIds = (<DataEntityType>InvestigationStatus).entityConfig.values
			.filter((status: InvestigationStatus) => status.id >= 0)
			.filter((status: InvestigationStatus) =>
				this.featuresService.isEnabled(Feature.AirsApiOffloading)
					? !isNil(status.sevilleStatusId)
					: !status.isTempOffloadingStatus
			)
			.map((status: InvestigationStatus) => status.id)
			.join('|');
	}

	parseData(data: AutomatedInvestigationStatisticsBackendData): Array<ButtonsBarButton> {
		if (!data.investigations_number) return null;
		const alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
		return [
			{
				id: 'automatedInvestigations',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_automated_investigations'),
				value: this.prettyNumberService.prettyNumber(data.investigations_number || 0),
				icon: 'investigationOutline',
				url: '/investigations',
				queryParams: {
					range: this.widgetConfig.rangeInDays,
				},
			},
			{
				id: 'remediatedInvestigations',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_remediated_investigations'),
				value: this.prettyNumberService.prettyNumber(data.fully_remediated_investigations || 0),
				icon: 'investigations.status.success',
				iconClassName: 'color-text-success',
				url: '/investigations',
				queryParams: {
					filters: `status=${
						(<InvestigationStatus>(
							this.paris.getValue(
								InvestigationStatus,
								(status: InvestigationStatus) => status.type === 'fullyRemediated'
							)
						))[
							this.featuresService.isEnabled(Feature.AirsApiOffloading)
								? 'sevilleStatusId'
								: 'id'
						]
					}`,
					range: this.widgetConfig.rangeInDays,
				},
			},
			{
				id: 'avgInvestigationPendingTime',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_average_pending_time'),
				value: Prettify.prettyTime(data.investigations_pending_time || 0),
				icon: 'investigations.status.paused',
				iconClassName: 'color-text-warning-dark',
				trend:
					(data.investigations_pending_time || 0) ===
					(data.investigations_pending_time_previous_era || 0)
						? null
						: (data.investigations_pending_time || 0) >
						  (data.investigations_pending_time_previous_era || 0)
						? 'up'
						: 'down',
				get trendCssClass() {
					return `trend-${this.trend === 'up' ? 'down' : 'up'}`;
				},
			},
			{
				id: 'avgTimeToRemediation',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_average_time_to_remediate'),
				value: Prettify.prettyTime(data.avg_alert_to_remediation_time || 0),
				icon: 'clock',
				helpKey: 'dashboard.avgAlertToRemediationTime.description',
				trend:
					(data.avg_alert_to_remediation_time || 0) ===
					(data.avg_alert_to_remediation_time_previous_era || 0)
						? null
						: (data.avg_alert_to_remediation_time || 0) >
						  (data.avg_alert_to_remediation_time_previous_era || 0)
						? 'up'
						: 'down',
				get trendCssClass() {
					return `trend-${this.trend === 'up' ? 'down' : 'up'}`;
				},
			},
			{
				id: 'alertsInvestigated',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_alerts_investigated'),
				value: this.prettyNumberService.prettyNumber(data.alerts_investigated || 0),
				icon: 'alertWithA',
				url: alertEntityType.getEntityDataviewLink(),
				queryParams: {
					filters: `investigationStates=${this._filterableInvestigationIds}`,
				},
			},
			{
				id: 'hoursAutomated',
				text: this.i18nService.get('automatedInvestigationsStatisticsWidget_hours_automated'),
				value: this.prettyNumberService.prettyNumber(data.saved_time || 0),
				icon: 'timeEntry',
			},
		];
	}
}

interface AutomatedInvestigationStatisticsBackendData {
	investigations_number: number;
	fully_remediated_investigations: number;
	investigations_pending_time: number;
	investigations_pending_time_previous_era: number;
	avg_alert_to_remediation_time: number;
	avg_alert_to_remediation_time_previous_era: number;
	alerts_investigated: number;
	saved_time: number;
}
