import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { WebThreatProtection } from './web-threat-protection.value-object';
import { WebThreatProtectionReportOptions } from './web-threat-protection-options.interface';

@ApiCall({
	name: 'Web threat health',
	endpoint: 'webThreats/reports/webThreatHealthStatus',
	method: 'GET',
	type: WebThreatProtection,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	parseQuery: (options: WebThreatProtectionReportOptions) => {
		return { params: options };
	},
})
export class WebThreatProtectionHealthStatusApiCall extends ApiCallModel<
	WebThreatProtection,
	WebThreatProtectionReportOptions
> {}
