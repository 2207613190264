import { EntityConfig, DataQuery } from '@microsoft/paris';
import {
	AddMachineGroupsFilterQueryParam,
	Cache,
	DefaultApiVersion,
	TvmEndPoint,
	AddODataFilterQueryParam,
	FiltersMapValue,
} from './commonEndPointUtils';
import { ODataFilterQueryBuilder } from './oDataFilterQueryBuilder';

const BaseUrl = config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.RemediationTasks}`;
const idDelimiter = '-_-';
const vaRecommendationExceptionArgsPropertyName = 'exceptionArgs/VaRecommendationArgs';
const scaRecommendationExceptionArgsPropertyName = 'exceptionArgs/ScaRecommendationArgs';
const vaRemediationTasksArgsPropertyName = 'taskArgs/SoftwareArgs';
const scaRemediationTasksArgsPropertyName = 'taskArgs/SecurityConfigurationArgs';

type FieldFilters =
	| 'remediationType'
	| 'status'
	| 'exceptionType'
	| 'exceptionJustification'
	| 'mitigationType';

const fieldFiltersMap: Record<FieldFilters, FiltersMapValue> = {
	status: [
		{
			context: 'aggregatedRecommendationException',
			predicate: (filterValues: string[]) => {
				const queryBuilder = new ODataFilterQueryBuilder('or');
				filterValues.forEach((filterVal: string) => queryBuilder.equals('primaryStatus', filterVal));
				return queryBuilder.toQuery();
			},
		},
		{
			// remediation task or exception (non aggregated)
			predicate: (filterValues: string[]) => {
				const queryBuilder = new ODataFilterQueryBuilder('or');
				filterValues.forEach((filterVal: string) => queryBuilder.equals('status/value', filterVal));
				return queryBuilder.toQuery();
			},
		},
	],
	remediationType: (filterValues: string[]) => {
		return filterValues
			.map(filterValue => {
				return `(taskArgs/softwareArgs/type eq '${filterValue}' or taskArgs/securityConfigurationArgs/type eq '${filterValue}')`;
			})
			.join(' or ');
	},
	exceptionType: (filterValues: string[]) =>
		filterValues
			.map(filterValue =>
				filterValue === 'ConfigurationChange'
					? `exceptionArgs/type eq '${filterValue}'`
					: `exceptionArgs/VaRecommendationArgs/RecommendationType eq '${filterValue}'`
			)
			.join(' or '),
	exceptionJustification: [
		{
			context: 'aggregatedRecommendationException',
			predicate: (filterValues: string[]) => {
				const queryBuilder = new ODataFilterQueryBuilder('or');
				filterValues.forEach((filterVal: string) =>
					queryBuilder.equals('primaryJustification', filterVal)
				);
				return queryBuilder.toQuery();
			},
		},
		{
			predicate: true,
		},
	],
	mitigationType: (filterValues: string[]) => {
		const queryBuilder = new ODataFilterQueryBuilder('or');
		filterValues.forEach((filterVal: string) =>
			queryBuilder.equals('taskArgs/softwareArgs/mitigation/mitigationAction', filterVal)
		);
		return queryBuilder.toQuery();
	},
};

export const RecommendationExceptionByIdMap: Record<string, (filterValue: string) => string> = {
	id: (id: string) => {
		const queryBuilder = new ODataFilterQueryBuilder();

		const split = id.split(idDelimiter);
		if (split.length === 3) {
			// va exception - in the format 'productName-_-productVendor-_-recommendationType'
			queryBuilder
				.equals(`${vaRecommendationExceptionArgsPropertyName}/RecommendationType`, split[2])
				.and(qb =>
					qb.equals(
						`${vaRecommendationExceptionArgsPropertyName}/ProductId`,
						`${split[0]}${idDelimiter}${split[1]}`
					)
				);
		} else if (split.length === 1) {
			// sca exception - in the format 'scid'
			queryBuilder.equals(`${scaRecommendationExceptionArgsPropertyName}/Scid`, split[0]);
		} else {
			throw new Error(`Invalid id: ${id}`);
		}

		return queryBuilder.toQuery();
	},
};

// use in remediation related relationship when you want to filter on the results => will always filter on status eq active
export const RemediationTaskByIdMap: Record<string, (filterValue: string) => string> = {
	id: (id: string) => {
		const queryBuilder = new ODataFilterQueryBuilder();

		const split = id.split(idDelimiter);
		if (split.length === 2) {
			// va remediation - in the format 'productName-_-productVendor'
			queryBuilder.equals(
				`${vaRemediationTasksArgsPropertyName}/ProductId`,
				`${split[0]}${idDelimiter}${split[1]}`
			);
		} else if (split.length === 1) {
			// sca remediation - in the format 'scid'
			queryBuilder.equals(`${scaRemediationTasksArgsPropertyName}/Scid`, split[0]);
		} else {
			throw new Error(`Invalid id: ${id}`);
		}

		queryBuilder.equals(`status/Value`, 'Active');
		return queryBuilder.toQuery();
	},
};

export const TvmRemediationSharedEntityConfigurations: Partial<EntityConfig<any>> = {
	saveMethod: (item: any) => (item.id ? 'PATCH' : 'POST'),
	cache: Cache,
	parseDataQuery: (dataQuery: DataQuery) => {
		const params = {};
		AddMachineGroupsFilterQueryParam(params);
		return params;
	},
	readonly: true,
	customHeaders: DefaultApiVersion,
	baseUrl: BaseUrl,
};

export const ParseRemediationDataQuery = (dataQuery: DataQuery, context?: string) => {
	const params: any = {};

	AddMachineGroupsFilterQueryParam(params);

	if (!dataQuery) return params;

	AddODataFilterQueryParam(dataQuery, params, fieldFiltersMap, context);

	return params;
};

export const ParseRemediationBlockQuery = (dataQuery: DataQuery) => {
	// Get the Mitigation=Block&distinct=ProductName&sortBy=PublisherName,Publisher
	const params: any = {};

	Object.assign(params, dataQuery.where);

	AddMachineGroupsFilterQueryParam(params);

	return params;
};
