import { IReadonlyIdentifiable, ReadOnlyIdentifiable } from './readonly-identifiable.model';
import { Observable, Observer } from 'rxjs';

export abstract class Identifiable<T extends string | number> extends ReadOnlyIdentifiable<T>
	implements IIdentifiable {
	private _params: Array<any>;

	isDeleting: boolean = false;
	isSaving: boolean = false;
	isEditing: boolean = false;

	changes$: Observable<Object>;
	private _changesObservable: Observer<Object>;

	constructor(data: any, params?: Array<any>) {
		super(data, params);
		this._params = params;
		this.changes$ = new Observable<Object>(observer => (this._changesObservable = observer));
	}

	restore() {
		this.setData(this._data, this._params);
	}

	cancelEdit() {
		if (!this.isNew) this.restore();

		this.isEditing = false;
	}

	/**
	 * Returns the object's items, as can be saved to the server
	 */
	abstract getBackendData(): any;

	/**
	 * Triggers the changes$ observable with the specified changes.
	 * @param changes
	 */
	notifyOnChange(changes: Object) {
		this._changesObservable.next(changes);
	}
}

export interface IIdentifiable extends IReadonlyIdentifiable {
	isDeleting: boolean;
	isSaving: boolean;
	isEditing: boolean;
	changes$: Observable<Object>;
	restore: () => void;
	cancelEdit: () => void;
	getBackendData: () => any;
	notifyOnChange: (changes: Object) => void;
}
