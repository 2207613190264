import { AirsReportTypes } from './airs-report-types.service';

export var scheduleConfig = {
	reports: AirsReportTypes.ALL,
	ranges: [{ value: 1, name: 'Day' }, { value: 7, name: 'Week' }],
	repeats: [
		{
			value: 1,
			name: 'Daily',
			requireDays: false,
			repeatEvery: [1, 2, 3, 4, 5, 6, 7],
			repeatEveryNameSingular: 'day',
			repeatEveryNamePlural: 'days',
		},
		{
			value: 7,
			name: 'Weekly',
			requireDays: true,
			repeatEvery: [1, 2, 3, 4],
			repeatEveryNameSingular: 'week',
			repeatEveryNamePlural: 'weeks',
		},
	],
	repeatOn: [
		{ id: 0, name: 'Monday', abbr: 'Mon' },
		{ id: 1, name: 'Tuesday', abbr: 'Tue' },
		{ id: 2, name: 'Wednesday', abbr: 'Wed' },
		{ id: 3, name: 'Thursday', abbr: 'Thu' },
		{ id: 4, name: 'Friday', abbr: 'Fri' },
		{ id: 5, name: 'Saturday', abbr: 'Sat' },
		{ id: 6, name: 'Sunday', abbr: 'Sun' },
	],
};
