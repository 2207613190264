import { Component, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FeaturesService, MtpOnboardingService, Feature } from '@wcd/config';
import { Subject, combineLatest, from } from 'rxjs';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { tenantContextCache } from '@wcd/auth';
import { ContentState } from '@wcd/contents-state';
import { ChecklistValue } from '@wcd/forms';
import { map } from 'lodash-es';
import { AppConfigService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';
import { TenantSettings } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AppInsightsService } from '../../../../../../apps/portal/src/app/insights/services/app-insights.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';

const CANARY = 'Canary';

@Component({
	selector: 'mtp-settings-consent',
	template: `
		<h2 class="wcd-margin-xxLarge-top bold wcd-padding-medium-left">{{ 'sccSettings.account.title' | i18n }}</h2>
		<wcd-contents-state [contentState]="contentState">
			<ng-container loading *ngTemplateOutlet="loading"></ng-container>
			<ng-container error *ngTemplateOutlet="error"></ng-container>
			<ng-container complete *ngTemplateOutlet="completeOrEmpty"></ng-container>
			<ng-container empty *ngTemplateOutlet="completeOrEmpty"></ng-container>

			<ng-template #loading>
				<div class="wcd-full-height wcd-full-width">
					<fab-spinner [size]="SpinnerSize.xSmall"></fab-spinner>
				</div>
			</ng-template>

			<ng-template #error>
				<div class="wcd-full-height wcd-full-width">
					<span>{{ 'sccSettings.mtpConsent.loadError' | i18n }}</span>
				</div>
			</ng-template>

			<ng-template #completeOrEmpty>
				<div
					class="wcd-margin-top wcd-padding-medium-left wcd-flex-justify-content-space-between wcd-flex-vertical"
				>
					<div class="wcd-flex-1 wcd-scroll-vertical">
						<h4 class="wcd-margin-medium-vertical bold">
							{{ 'sccSettings.mtpConsent.descriptionTitle' | i18n }}
						</h4>
						<div>
							<markdown [data]="this.dataStorageLocationText"></markdown>
						</div>
						<div *ngIf="isExposedToOptOut">
							<div class="wcd-margin-top">
								<markdown
									[data]="'sccOnboarding.mtpOnboarding.description' | i18n"
								></markdown>
							</div>
							<div class="wcd-margin-medium-top">
								<fab-checkbox
									[disabled]="!hasPermissionToChangeConsent || saveInProgress"
									[checked]="mtpConsent"
									[label]="'sccOnboarding.mtpOnboarding.checkboxText' | i18n"
									(onChange)="onCheckboxChange()"
								></fab-checkbox>
							</div>
							<fab-message-bar
								contentClass="wcd-margin-top"
								[messageBarType]="MessageBarType.error"
								*ngIf="(error$ | async) && !saveInProgress"
							>
								{{ 'sccSettings_mtpConsent_toggleError' | i18n }}
							</fab-message-bar>
							<fab-message-bar
								contentClass="wcd-margin-top"
								[messageBarType]="MessageBarType.info"
								*ngIf="!hasPermissionToChangeConsent"
							>
								{{ 'sccSettings_mtpConsent_toggleNoPermissions' | i18n }}
							</fab-message-bar>
						</div>
					</div>
					<div *ngIf="isExposedToOptOut" class="wcd-padding-mediumSmall-top">
						<fab-primary-button
							[text]="'buttons.accept' | i18n"
							[disabled]="mtpConsent === initialMtpConsent || saveInProgress"
							(onClick)="saveConsent()"
						>
						</fab-primary-button>
					</div>
				</div>
			</ng-template>
		</wcd-contents-state>
		<section class="wcd-padding-medium-left">
			<h4 class="wcd-margin-medium-vertical bold">{{ 'sccSettings.account.tenantId.label' | i18n }}</h4>
			{{ appConfigService.tenantId }}
			<h4 class="wcd-margin-medium-vertical bold">{{ 'sccSettings.account.orgId.label' | i18n }}</h4>
			{{ appConfigService.orgId }}
		</section>
	`,
})
export class MtpSettingsConsent implements OnDestroy, OnInit {
	MessageBarType = MessageBarType;
	SpinnerSize = SpinnerSize;

	contentState: ContentState = ContentState.Loading;
	initialMtpConsent: boolean;
	mtpConsent: boolean;
	error$: Subject<boolean> = new Subject<boolean>();
	saveInProgress: boolean;
	dataCenterValues: Array<ChecklistValue>;
	dataStorageLocationText: string;
	hasPermissionToChangeConsent: boolean;
	isExposedToOptOut: boolean;

	constructor(
		private paris: Paris,
		public appConfigService: AppConfigService,
		private i18nService: I18nService,
		private mtpOnboardingService: MtpOnboardingService,
		private changeDetectorRef: ChangeDetectorRef,
		private appInsightsService: AppInsightsService,
		private featuresService: FeaturesService
	) {
		this.saveInProgress = false;
		this.isExposedToOptOut = this.featuresService.isEnabled(Feature.ExposedToOptOut);

		this.dataCenterValues = this.getDataCenterValues(this.appConfigService.supportedGeoRegions);
	}

	ngOnInit() {
		combineLatest(
			this.mtpOnboardingService.getConsent(),
			this.paris.getRepository<TenantSettings>(TenantSettings).getItemById(1),
			from(<Promise<boolean>>sccHostService.auth.isInRole(SccRoles.hasMtpOnboardingPermission))
		).subscribe(
			([consent, settings, hasPermissionToChangeConsent]) => {
				this.addUsInternalIfNecessary(settings.geoRegion); // Support Canary tenants
				const selectedDataCenter = this.dataCenterValues.find(
					checklistValue => checklistValue.id === settings.geoRegion
				);
				this.dataStorageLocationText = this.i18nService.get(
					'sccOnboarding.mtpOnboarding.geoRegionText',
					{
						geoRegion: selectedDataCenter.name,
					}
				);
				this.mtpConsent = this.initialMtpConsent = consent;
				this.hasPermissionToChangeConsent = hasPermissionToChangeConsent;

				this.contentState = ContentState.Complete;
				this.changeDetectorRef.detectChanges();
			},
			error => (this.contentState = ContentState.Error)
		);
	}

	private addUsInternalIfNecessary(geoRegion: string) {
		if (geoRegion === CANARY) {
			const supportedGeoRegionsIncludingCanary = [...this.appConfigService.supportedGeoRegions];
			supportedGeoRegionsIncludingCanary.unshift(CANARY);
			this.dataCenterValues = this.getDataCenterValues(supportedGeoRegionsIncludingCanary);
		}
	}

	private getDataCenterValues(supportedGeoRegions: string[]): Array<ChecklistValue> {
		return map(
			supportedGeoRegions,
			geo =>
				<ChecklistValue>{
					id: geo,
					name: this.i18nService.get('endpointManagement.supportedGeoRegions.' + geo),
				}
		);
	}

	onCheckboxChange() {
		this.mtpConsent = !this.mtpConsent;

		// Temporary hack to handle change detection in SCC
		this.changeDetectorRef.detectChanges();
	}

	saveConsent() {
		this.error$.next(false);
		this.saveInProgress = true;
		this.changeDetectorRef.detectChanges();
		this.mtpOnboardingService.setConsent(this.mtpConsent).subscribe(
			() => {
				this.appInsightsService.trackEvent('MtpSettings-ConsentChanged', {
					Consent: this.mtpConsent,
					AccountType: tenantContextCache.appConfig.AccountType,
				});
				this.initialMtpConsent = this.mtpConsent;
				this.saveInProgress = false;
				this.changeDetectorRef.detectChanges();
			},
			error => {
				this.mtpConsent = this.initialMtpConsent;
				this.error$.next(true);
				this.saveInProgress = false;
				this.changeDetectorRef.detectChanges();
			}
		);
	}

	ngOnDestroy() {
		this.error$ && this.error$.unsubscribe();
	}
}
