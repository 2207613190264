import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';
import { from, Observable } from 'rxjs';
import { BaselineConfigurationByCategory } from '@wcd/domain';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { ConfigurationsByCategoryFieldsService } from '../../profiles-tab/services/configurations-by-category.fields.service';
import { Paris, Repository } from '@microsoft/paris';
import { EntityDataViewOptions, EntityType } from '../../../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataViewComponent } from '../../../../../dataviews/components/dataview.component';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './profile-configurations-assignment-step.component.html',
})
export class ProfileConfigurationsAssignemntStepComponent
	extends WizardBaseStep<ProfileForCreationFormData>
	implements OnInit {
		MessageBarType = MessageBarType;
		readonly dataViewOptions: EntityDataViewOptions<BaselineConfigurationByCategory, {}>;

		@ViewChild(DataViewComponent, { static: false }) dataViewComponent: DataViewComponent;

		configurationsByCategory$: Observable<BaselineConfigurationByCategory>;
		fields: Array<DataviewField<BaselineConfigurationByCategory>>;
		repository: Repository<BaselineConfigurationByCategory>;
		defaultQueryFilters: Record<string, any>;
		entityType: EntityType<BaselineConfigurationByCategory>;
		dataViewConfig: DataViewConfig;
		boundGetContainedConfigurations: (conf: BaselineConfigurationByCategory) => Observable<Array<BaselineConfigurationByCategory>>;
		boundGetNameColumnDisplay: (conf: BaselineConfigurationByCategory) => string;
		boundIsGroupExpanded: (conf: BaselineConfigurationByCategory) => boolean;
		boundIsSettingSelected: (conf: BaselineConfigurationByCategory) => boolean;
		boundIsGroup: (conf: BaselineConfigurationByCategory) => boolean;
		localRefreshOn: number = 0;

		private currentlySelectedSettings: Array<BaselineConfigurationByCategory>;
		private previouslySelectedSettings: Array<BaselineConfigurationByCategory>;
		private onInit: boolean;
		private readonly categoryIdPrefix: string = "category-";

		constructor(
			public globalEntityTypesService: GlobalEntityTypesService,
			public paris: Paris,
			public i18n: I18nService,
			public configurationsByCategoryFieldsService: ConfigurationsByCategoryFieldsService,
			private changeDetectionRef: ChangeDetectorRef
		) {
			super();
			this.entityType = this.globalEntityTypesService.getEntityType(BaselineConfigurationByCategory);
		}

		ngOnInit(): void {
			this.setStepValidation(false);
			this.boundGetContainedConfigurations = this.getContainedConfigurations.bind(this);
			this.boundGetNameColumnDisplay = this.GetNameColumnDisplay.bind(this);
			this.boundIsGroupExpanded = this.isGroupExpanded.bind(this);
			this.boundIsSettingSelected = this.isSettingSelected.bind(this);
			this.boundIsGroup = this.isGroup.bind(this);
			this.fields = DataviewField.fromList<BaselineConfigurationByCategory>(this.configurationsByCategoryFieldsService.fields.map(field => (field.id === "configurationByCategoryName" ? ({
				...field,
				getDisplay: this.boundGetNameColumnDisplay,
			}) : field)));

			this.repository = this.paris.getRepository(BaselineConfigurationByCategory)
			this.dataViewConfig = {
				id: 'profileConfigurationsWizardConfig', //must supply unique id for correct dataview caching purposes
				fixedOptions: {
					benchmarkName: this.data.benchmark,
					benchmarkVersion: this.data.benchmarkVersion,
					complianceLevel: this.data.complianceLevel
				},
			};
			this.previouslySelectedSettings = this.data.selectedSettings;
			this.currentlySelectedSettings = [];
			this.onInit = true;
			this.setStepValidation(this.data.selectedSettings.length !== 0);
			this.changeDetectionRef.markForCheck();
		}

		unselectAll() {
			this.data.selectedSettings = [];
			this.currentlySelectedSettings = [];
			this.previouslySelectedSettings = [];
			this.dataViewComponent.unSelectAllItems();
		}

		getHowManySelectedText() {
			return this.i18n.get('baseline_profile_wizard_profile_configurations_assignment_selected', { selectedCount:  this.data.selectedSettings.length});
		}

		selectSetting(selectedSettings: Array<BaselineConfigurationByCategory>) {
			this.onInit = false;
			const flattenedSelectedSettings = selectedSettings.reduce((settings, currentSetting) => {
				if (currentSetting.id.startsWith(this.categoryIdPrefix)) return settings.concat(this.getGroupSettings(currentSetting));
				return [...settings, currentSetting];
            }, []);

			const distinctNewSelected = this.distinctSelectedSettings(flattenedSelectedSettings);
			this.removeSharedSettingsFromPreviousSelected(distinctNewSelected);
			this.currentlySelectedSettings = distinctNewSelected;
			this.data.selectedSettings = this.distinctSelectedSettings(this.currentlySelectedSettings.concat(this.previouslySelectedSettings));

			this.setStepValidation(this.data.selectedSettings.length !== 0);
			this.localRefreshOn++;
		}

		onSearch(term: string) {
			this.previouslySelectedSettings = this.data.selectedSettings;
			this.currentlySelectedSettings = [];
		}

		private isGroup(configuration: BaselineConfigurationByCategory) {
			return configuration && configuration.id.startsWith(this.categoryIdPrefix);
		}

		private removeSharedSettingsFromPreviousSelected(distinctNewSelected: Array<BaselineConfigurationByCategory>) {
			const ids = distinctNewSelected.map(item => item.id);
			this.previouslySelectedSettings = this.previouslySelectedSettings.filter(item => !ids.includes(item.id))
		}

		private distinctSelectedSettings(selectedSettings: Array<BaselineConfigurationByCategory>) {

			const distinctIds = Array.from(new Set(selectedSettings.map(item => item.id)));
			return distinctIds.map(id => selectedSettings.filter(item => item.id === id)[0]);
		}

		private isSettingSelected(setting: BaselineConfigurationByCategory) {
			const selectedIds = setting.id.startsWith(this.categoryIdPrefix) ? setting.configurations.map(conf => conf.id) : [setting.id];
			return selectedIds.filter(id => this.data.selectedSettings.every(item => item.id !== id )).length === 0;
		}

		private getContainedConfigurations(conf: BaselineConfigurationByCategory): Observable<Array<BaselineConfigurationByCategory>> {
			const res = !conf.complianceLevels ? from(new Array()) : from(new Array(this.getGroupSettings(conf)));
			return res;
		}

		private getGroupSettings(conf: BaselineConfigurationByCategory): Array<BaselineConfigurationByCategory> {
			return conf.configurations.map(configuration =>
				new BaselineConfigurationByCategory({
					id: configuration.id,
					title: configuration.title,
					complianceLevels: configuration.complianceLevels,
					category: conf.category
				}));
		}

		private isGroupExpanded(conf: BaselineConfigurationByCategory): boolean {
			if (this.onInit && this.data.checks && this.data.checks.length !== 0) this.addChecksToSelectedSettings(conf);
			const selectedChildrenCount = conf.configurations.filter(item => this.data.selectedSettings.some(setting => setting.id === item.id)).length;
			return selectedChildrenCount !== conf.count && selectedChildrenCount !== 0;
		}

		private addChecksToSelectedSettings(setting: BaselineConfigurationByCategory) {
			const settings = setting.id.startsWith(this.categoryIdPrefix) ? this.getGroupSettings(setting) : [setting];
			settings.forEach(item => {
				if (this.data.checks.includes(item.id)) {
					this.previouslySelectedSettings.push(item);
					this.data.selectedSettings.push(item);
				}
			});
		}

		private GetNameColumnDisplay(conf: BaselineConfigurationByCategory) {
			const selectedCount = this.currentlySelectedSettings.reduce((count, selectedSetting) => {
				if (selectedSetting.category !== conf.category)
					return count;
				return count + (selectedSetting.id.startsWith(this.categoryIdPrefix) ? selectedSetting.configurations.length : 1) ;
			}, 0);
			return conf.id.startsWith(this.categoryIdPrefix) ? `${conf.category} (${selectedCount}/${conf.count})` : conf.title
		}
}
