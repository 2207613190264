<div *ngIf="!isNarrowLayout || !isPanel else dropdownWizard" [ngClass]="containerClassName">
	<ng-container *ngTemplateOutlet="wizardSteps"></ng-container>
</div>

<ng-template #dropdownWizard>
	<button class="wizard-dropdown-step btn-unstyled wcd-padding-mediumSmall-vertical wcd-padding-large-horizontal"
			(click)="toggleWizardDropdown()"
			[attr.role]="'listbox'"
			[attr.aria-expanded]="wizardDropdownOpen"
			[attr.aria-label]="currentStepName"
	>
		<wcd-shared-icon class="progress-icon wcd-flex-0 wcd-font-size-base" [iconName]="isCompleted ?  'CompletedSolid': 'CircleFill'" ></wcd-shared-icon>
		<span class="wizard-dropdown-step-name wcd-flex-1 wcd-margin-mediumSmall-left wcd-font-weight-bold wcd-font-size-m">
			{{currentStepName}}
		</span>
		<wcd-shared-icon class="wcd-flex-0 wcd-font-size-base" [iconName]="wizardDropdownOpen ? 'ChevronUp' : 'ChevronDown'" aria-hidden="true"></wcd-shared-icon>
	</button>
	<div *ngIf="wizardDropdownOpen" class="wcd-padding-large-horizontal wcd-padding-small-top wcd-border-top">
		<ng-container *ngTemplateOutlet="wizardSteps"></ng-container>
	</div>
</ng-template>

<ng-template #wizardSteps>
	<ul class="wizard">
		<li
			*ngFor="let step of (stepsData | visibleSteps); last as $last"
			class="step"
			[routerLinkActive]="currentRouteIsActive ? (step.routerLinkActiveClass ? step.routerLinkActiveClass : '') : ''"
			[ngClass]="{ active: step.id === _currentStepId }"
			[attr.data-test-id]="step.id"
		>
			<wcd-shared-icon
				*ngIf="showProgressIcons"
				[iconName]="getIconForStatus(getStepStatus(step))"
				[class]="'progress-icon wcd-padding-right ' + getStepClassName(step)"
				[ngClass]="{ pending: step.id >= _currentStepId, last: $last, active: step.id === _currentStepId }"
				[attr.aria-label]="this.i18nService.strings['wizard_stepStatusAria_' + getStepStatus(step).valueOf()]"
			></wcd-shared-icon>
			<a
				[attr.data-track-id]="'WizardSelectStep_' + step.id"
				data-track-type="Navigation"
				(click)="!step.disabled && selectStep(step, $event)"
				*ngIf="step.routerLink; else noLink"
				[routerLink]="step.routerLink"
				[queryParams]="step.routerLinkQueryParams"
				[queryParamsHandling]="step.routerQueryParamsHandling"
				[wcdTooltip]="step.tooltip"
				[ngClass]="getStepClassName(step)"
			>
				<ng-container *ngTemplateOutlet="stepContents; context: { step: step }"></ng-container>
			</a>
			<ng-template #noLink>
				<a
					(click)="!step.disabled && selectStep(step, $event)"
					[attr.data-track-id]="'WizardSelectStep_' + step.id"
					data-track-type="Navigation"
					[wcdTooltip]="step.tooltip"
					[ngClass]="getStepClassName(step)"
					role="none"
				>
					<ng-container *ngTemplateOutlet="stepContents; context: { step: step }"></ng-container>
				</a>
			</ng-template>
			<ng-template #stepContents let-step="step">
				<wcd-shared-icon *ngIf="step.icon" [iconName]="step.icon" [ngClass]="step.iconClassName">
				</wcd-shared-icon>
				<span [attr.id]="'step-text-' + step.id">{{ step.text || step.name }}</span>
				<div [attr.id]="'step-sub-text-' + step.id" class="wcd-width-small wcd-font-size-m">
					{{ step.subText }}
				</div>
			</ng-template>
		</li>
	</ul>
</ng-template>
