var Lazy = /** @class */ (function () {
    function Lazy(getValue, reuseValue) {
        if (reuseValue === void 0) { reuseValue = true; }
        this.getValue = getValue;
        this.reuseValue = reuseValue;
    }
    Object.defineProperty(Lazy.prototype, "value", {
        get: function () {
            if (!this.reuseValue) {
                return this.getValue();
            }
            if (typeof this._value === 'undefined') {
                this._value = this.getValue();
            }
            return this._value;
        },
        enumerable: true,
        configurable: true
    });
    return Lazy;
}());
export { Lazy };
