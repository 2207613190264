/*
 * These known processes don't open links in the browser, so we know it was clicked by the user
 */
const BROWSER_FREE_KNOWN_PROCESSES = ['outlook.exe', 'winword.exe', 'excel.exe', 'powerpnt.exe', 'skype.exe'];

/**
 * Contains list of known process names that do not open links in the browser
 */
export class BrowserFreeKnownProcessesService {
	static isKnownProcess(processName: string, toLowerCase: boolean = true): boolean {
		const name = toLowerCase ? processName.toLowerCase() : processName;
		return BROWSER_FREE_KNOWN_PROCESSES.includes(name);
	}
}
