
		<wcd-panel (close)="onCancel()" [settings]="settings">
			<system-exclusion-edit
				[systemExclusion]="systemExclusion"
				[systemExclusionType]="systemExclusionType"
				[systemExclusionListTypes]="systemExclusionListTypes"
				[editableProperties]="editableProperties"
				[formIsDirtyOnInit]="formIsDirtyOnInit"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(done)="onDone($event)"
				(cancel)="onCancel()"
			></system-exclusion-edit>
		</wcd-panel>
	