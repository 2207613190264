import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Certificate info',
	pluralName: 'Certificates info',
	readonly: true,
})
export class CertificateInfo extends ModelBase {
	/**
	 * The signer's name, if exists.
	 * If nil, the certificate is unsigned.
	 */
	@EntityField({ data: 'Signer' })
	readonly signer?: string;

	/**
	 * The issuer's name, if exists.
	 * If nil, the certificate is unsigned.
	 *
	 * @todo doesn't exist yet - needs to be added by the BE
	 */
	@EntityField({ data: 'Issuer' })
	readonly issuer?: string;

	/**
	 * Whether the certificate is valid or not.
	 */
	@EntityField({ data: 'IsValidCertificate' })
	readonly isValid?: boolean;
}
