import { BehaviorSubject } from 'rxjs';
import { findIndex } from 'lodash-es';
/**
 * A service for exposing and managing current breadcrumbs list state
 */
window.mdatp = window.mdatp || {};
/**
 * Returns current list of breadcrumbs items
 * @type {BehaviorSubject<Array<Breadcrumb>>}
 */
window.mdatp.breadcrumbs$ = window.mdatp.breadcrumbs$ || new BehaviorSubject([]);
var BreadcrumbsStateService = /** @class */ (function () {
    function BreadcrumbsStateService() {
    }
    Object.defineProperty(BreadcrumbsStateService.prototype, "breadcrumbs$", {
        get: function () {
            return window.mdatp.breadcrumbs$;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Adds\Updates the last item in the breadcrumbs array.
     * If the id of the added/updated item already exists in the breadcrumbs list, the list is spliced before the existing item, and the new item is added at the end.
     * @param {Breadcrumb} breadcrumb
     */
    BreadcrumbsStateService.prototype.add = function (breadcrumb) {
        if (!breadcrumb)
            throw new Error('Breadcrumb is missing');
        var items = this.breadcrumbs$.value;
        if (items.length) {
            var exisitingItem = findIndex(items, function (item) { return item.id === breadcrumb.id; });
            if (~exisitingItem)
                items = items.splice(0, exisitingItem);
        }
        this.breadcrumbs$.next(items.concat([breadcrumb]));
    };
    /**
     * Clears the breadcrumbs list
     */
    BreadcrumbsStateService.prototype.reset = function () {
        this.breadcrumbs$.next([]);
    };
    /**
     * Clears the breadcrumbs list and then adds an item to the list.
     * @param {Breadcrumb} breadcrumb
     */
    BreadcrumbsStateService.prototype.set = function (breadcrumb) {
        if (!breadcrumb)
            throw new Error('Breadcrumb is missing');
        this.breadcrumbs$.next([breadcrumb]);
    };
    return BreadcrumbsStateService;
}());
export var breadcrumbsStateService = new BreadcrumbsStateService();
