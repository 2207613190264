import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { AlertsSeveritySummary } from '../alert/alerts-severity-summary.value-object';
import { Alert } from '../alert/alert.entity';

@ValueObject({
	singularName: 'Alerts Severity Summary',
	pluralName: 'Alerts Severity Summaries',
	readonly: true,
})
export class EvidenceAlertsSummary extends ModelBase {
	@EntityField({
		data: '__self',
		parse: data =>
			Object.assign(
				{
					IncidentCount: data.IncidentCount,
				},
				data.AlertCount && {
					InformationalSevAlerts: data.AlertCount.Informational,
					LowSevAlerts: data.AlertCount.Low,
					MediumSevAlerts: data.AlertCount.Medium,
					HighSevAlerts: data.AlertCount.High,
				}
			),
	})
	alertsSeveritySummary: AlertsSeveritySummary;

	@EntityField({
		data: 'Items',
		arrayOf: Alert,
	})
	alerts: Array<Alert>;
}
