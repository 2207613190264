var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
export var CustomTiIndicatorActionsTypes;
(function (CustomTiIndicatorActionsTypes) {
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["Allow"] = 0] = "Allow";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["Alert"] = 1] = "Alert";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["AlertAndBlock"] = 2] = "AlertAndBlock";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["Warn"] = 3] = "Warn";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["Block"] = 4] = "Block";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["Audit"] = 11] = "Audit";
    CustomTiIndicatorActionsTypes[CustomTiIndicatorActionsTypes["BlockAndRemediate"] = 12] = "BlockAndRemediate";
})(CustomTiIndicatorActionsTypes || (CustomTiIndicatorActionsTypes = {}));
var ɵ0 = CustomTiIndicatorActionsTypes.Allow, ɵ1 = CustomTiIndicatorActionsTypes.Alert, ɵ2 = CustomTiIndicatorActionsTypes.AlertAndBlock, ɵ3 = CustomTiIndicatorActionsTypes.Warn, ɵ4 = CustomTiIndicatorActionsTypes.Block, ɵ5 = CustomTiIndicatorActionsTypes.Audit, ɵ6 = CustomTiIndicatorActionsTypes.BlockAndRemediate;
var CustomTiIndicatorActionsType = /** @class */ (function (_super) {
    __extends(CustomTiIndicatorActionsType, _super);
    function CustomTiIndicatorActionsType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], CustomTiIndicatorActionsType.prototype, "name", void 0);
    CustomTiIndicatorActionsType = __decorate([
        Entity({
            singularName: 'Custom Ti Indicator Action Type',
            pluralName: 'Custom Ti Indicator Action Types',
            values: [
                {
                    id: ɵ0,
                    name: 'Allow',
                },
                {
                    id: ɵ1,
                    name: 'Alert',
                },
                {
                    id: ɵ2,
                    name: 'AlertAndBlock',
                },
                {
                    id: ɵ3,
                    name: 'Warn',
                },
                {
                    id: ɵ4,
                    name: 'Block',
                },
                {
                    id: ɵ5,
                    name: 'Audit',
                },
                {
                    id: ɵ6,
                    name: 'BlockAndRemediate',
                },
            ],
        })
    ], CustomTiIndicatorActionsType);
    return CustomTiIndicatorActionsType;
}(EntityModelBase));
export { CustomTiIndicatorActionsType };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
