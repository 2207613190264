var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Paris } from '@microsoft/paris';
import { Machine, SeverityType } from '@wcd/domain';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { EntityPanelsService } from '../../../../../global_entities/services/entity-panels.service';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { SeverityTypesService } from '../../../../../shared/services/severity-types.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { FeaturesService, Feature } from '@wcd/config';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
var MachinesAtRiskWidget = /** @class */ (function (_super) {
    __extends(MachinesAtRiskWidget, _super);
    function MachinesAtRiskWidget(reportsService, serviceUrlsService, appConfigService, paris, entityPanelsService, featuresService, globalEntityTypesService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.appConfigService = appConfigService;
        _this.paris = paris;
        _this.entityPanelsService = entityPanelsService;
        _this.featuresService = featuresService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        _this.tableColumns = DataTableField.fromList([
            {
                id: 'machineName',
                name: _this.i18nService.get('widget_title_devicesAtRisk_device_name'),
                icon: {
                    fabricIcon: _this.globalEntityTypesService.getEntityTypeIcon(Machine),
                },
                getDisplay: function (item) { return item.ComputerDnsName.split('.')[0]; },
                className: 'nowrap',
                getLink: function (item) {
                    return "/" + (_this.featuresService.isEnabled(Feature.UpgradeMachinePage) ? 'machines' : '_machine') + "/" + item.MachineId;
                },
                getTooltip: function (item) { return item.ComputerDnsName.split('.')[0]; },
                custom: {
                    tracking: {
                        id: 'machineLink',
                        type: TrackingEventType.Navigation,
                    },
                },
                fluidWidth: 1,
            },
            {
                id: 'highSeverityAlerts',
                name: _this.i18nService.get('widget_title_devicesAtRisk_high_severity_alerts'),
                getDisplay: function (item) { return item.HighSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.High);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_high_severity_tooltip'),
            },
            {
                id: 'mediumSeverityAlerts',
                name: 'widget_title_devicesAtRisk_medium_severity_alerts',
                getDisplay: function (item) { return item.MediumSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Medium);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_medium_severity_tooltip'),
            },
            {
                id: 'lowSeverityAlerts',
                name: 'widget_title_devicesAtRisk_low_severity_alerts',
                getDisplay: function (item) { return item.LowSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Low);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_low_severity_tooltip'),
            },
            {
                id: 'infoSeverityAlerts',
                name: 'widget_title_devicesAtRisk_information_severity_alerts',
                getDisplay: function (item) { return item.InformationalSevAlerts; },
                getFieldCssClass: function () {
                    var severity = SeverityTypesService.getSeverityByType(SeverityType.Informational);
                    return 'color-border-' + severity.className;
                },
                className: 'nowrap legend-item-cell',
                tooltip: _this.i18nService.get('widget_title_information_severity_tooltip'),
            },
        ]);
        return _this;
    }
    Object.defineProperty(MachinesAtRiskWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'machinesAtRisk',
                name: this.i18nService.get('widget_title_devicesAtRisk'),
                noDataMessage: this.i18nService.get('widget_title_devicesAtRisk_no_devices_at_risk'),
                noDataIcon: 'System',
                api: {
                    url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/GetTopAlertMachines"; },
                    isExternal: true,
                    params: {
                        lookBackInDays: String(this.appConfigService.widgetLookback),
                        readFromCache: String(true),
                    },
                },
                parseData: this.parseData.bind(this),
                extraHeaderHtml: function () {
                    return "<a data-track-id=\"GoToMachinesPage\" data-track-type=\"Navigation\" href=\"/machines\">" + _this.i18nService.get('machine_at_risk_widget_machine_list') + "</a>";
                },
            };
        },
        enumerable: true,
        configurable: true
    });
    MachinesAtRiskWidget.prototype.showMachineSidePanel = function (rawMachine) {
        var _this = this;
        this.paris
            .getRepository(Machine)
            .createItem(rawMachine)
            .subscribe(function (machine) {
            _this.entityPanelsService.showEntities(Machine, [machine]);
        });
    };
    MachinesAtRiskWidget.prototype.parseData = function (data) {
        return data.Machines;
    };
    return MachinesAtRiskWidget;
}(ReportWidgetComponent));
export { MachinesAtRiskWidget };
