import { LegacyUserObservedMachine } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { Lazy } from '../../../utils/lazy';
import { UserObservedMachinePanelComponent } from '../components/user-observed-machine-panel.component';
var UserObservedMachineEntityTypeService = /** @class */ (function () {
    function UserObservedMachineEntityTypeService() {
        this._entityType = new Lazy(function () { return ({
            id: 'legacyUserObservedMachine',
            entity: LegacyUserObservedMachine,
            icon: FabricIconNames.System,
            singleEntityPanelComponentType: UserObservedMachinePanelComponent,
            getEntityName: function (entity) { return entity.machine.name; },
        }); });
    }
    Object.defineProperty(UserObservedMachineEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return UserObservedMachineEntityTypeService;
}());
export { UserObservedMachineEntityTypeService };
