import { getThreatsNames } from '../../../mtp-investigation/mtp-investigation.entity';
export function mapMailboxEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "DisplayName" /* DisplayName */:
                    acc['display_name'] = val;
                    break;
                case "MailboxPrimaryAddress" /* MailboxPrimaryAddress */:
                    acc['primary_mail_address'] = val;
                    acc[key] = val; // need to save 'MailboxPrimaryAddress' in order to calculate the display_name
                    break;
                case "RiskLevel" /* RiskLevel */:
                    acc['risk_level'] = val;
                    break;
                case "ThreatAnalysisSummary" /* ThreatAnalysisSummary */:
                    var threats = getThreatsNames(val);
                    acc['risk'] = threats;
                    acc['risky_activities'] = (threats && threats.length) || 0;
                    break;
                case "AadId" /* AadId */:
                    acc['aad_id'] = val;
                    break;
                case "Upn" /* Upn */:
                    acc['upn'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
