import { Component, Input, OnInit, Optional, Host } from '@angular/core';
import { ModelBase, Paris, RelationshipRepository } from '@microsoft/paris';
import { filter } from 'rxjs/operators';
import { Application, Incident, IncidentAppsRelationship } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataviewField } from '@wcd/dataview';
import { IncidentEntityComponent } from './incident.entity.component';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { ApplicationFieldsService } from '../../applications/services/application.fields.service';

@Component({
	selector: 'incident-apps',
	providers: [ApplicationFieldsService],
	template: `
		<dataview
			class="wcd-full-height"
			id="incident-apps"
			*ngIf="incidentAppsRepository?.sourceItem; else noData"
			className="no-left-padding wcd-entity-page-content"
			[repository]="incidentAppsRepository"
			[entityType]="applicationEntityType"
			[allowFilters]="false"
			[fields]="fields"
			[fixedOptions]="dataviewFixedOptions"
			[label]="'incident.apps.title' | i18n"
			[removePadding]="true"
			[responsiveActionBar]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
		>
		</dataview>
		<ng-template #noData>
			<div class="wcd-full-height wcd-flex-center-all">
				<div class="widget-nodata-message">{{ 'incident.apps.titleNoData' | i18n }}</div>
			</div>
		</ng-template>
	`,
})
export class IncidentAppsComponent implements OnInit {
	@Input() incident: Incident;

	incidentAppsRepository: RelationshipRepository<Incident, Application>;
	applicationEntityType: EntityType<Application>;
	fields: ReadonlyArray<DataviewField<Application>>;
	readonly dataviewFixedOptions: { [index: string]: any } = { serviceContext: 'Mtp' };
	constructor(
		private paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		applicationFieldsService: ApplicationFieldsService,
		private activatedEntity: ActivatedEntity,
		@Optional()
		@Host()
		public incidentEntityComponent: IncidentEntityComponent,
		private performanceSccService: PerformanceSccService
	) {
		this.fields = applicationFieldsService.fields;
		this.applicationEntityType = globalEntityTypesService.getEntityType(Application);
	}

	ngOnInit() {
		if (this.incident) {
			this.setApplicationRepository(this.incident);
		} else {
			this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Incident))
				.subscribe((incident: Incident) => {
					this.setApplicationRepository(incident);
				});
		}
	}

	private setApplicationRepository(incident: Incident) {
		if (incident.impactedEntities && incident.impactedEntities.appCount) {
			this.incidentAppsRepository = this.paris.getRelationshipRepository(IncidentAppsRelationship);
			this.incidentAppsRepository.sourceItem = incident;
		}
	}

	private onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('apps');
	}
}

