import { Directive, forwardRef, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';
import { RegExpService } from '@wcd/shared';
import { compact } from 'lodash-es';

export function validateRegExpFactory(regExpName: string, isList?: boolean) {
	return (c: FormControl) => {
		if (!c.value || !regExpName) return null;

		const regExps = regExpName
			.split(/\s*\|\s*/)
			.map(_regExpName => RegExpService[_regExpName])
			.filter(regExp => regExp);
		const values: Array<string> = isList ? compact(c.value.split(/\s*,\s*/)) : [c.value];

		// If all values match any one of the possible regex patterns, there's no error.
		if (values.every(value => regExps.some(regExp => regExp.test(value)))) return null;

		// Otherwise, if there's at least one value that isn't match by any of the possible regex patters, the validator's result is invalid.
		return {
			validateRegExp: {
				valid: false,
			},
		};
	};
}

@Directive({
	selector: '[validateRegExp][ngModel],[validateRegExp][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => RegExpValidator), multi: true }],
})
export class RegExpValidator implements OnInit {
	@Input() validateRegExp: string;
	validator: Function;

	ngOnInit() {
		this.validator = validateRegExpFactory(this.validateRegExp);
	}

	validate(c: FormControl) {
		return this.validator(c);
	}
}
