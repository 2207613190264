import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { MtpPermission } from '@wcd/auth';
import { MtpWorkload } from '@wcd/domain';

interface MtpPermissionsGuardConfig {
	MtpPermission: MtpPermission;
	NavigateToOnAuthorized: string;
	FallbackOnUnauthorized: string;
	AbsolutePath: boolean;
	AllowAdditionalNonMainWorkloads: Array<MtpWorkload>;
}

@Injectable()
export class MtpPermissionsGuard implements CanActivate {
	constructor(
		private appContext: AppContextService,
		private readonly authService: AuthService,
		private router: Router
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		const config: MtpPermissionsGuardConfig = route.data['MtpPermissionsGuardConfig'];

		if (!this.authService.currentUser) {
			// No users signed in to check allowed actions
			return true;
		} else if (
			this.authService.currentUser.hasRequiredMtpPermissionsFromMainWorkloads(config.MtpPermission) ||
			(config.AllowAdditionalNonMainWorkloads !== undefined &&
				config.AllowAdditionalNonMainWorkloads.some((workload) =>
					this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
						config.MtpPermission,
						workload
					)
				))
		) {
			if (config.NavigateToOnAuthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.NavigateToOnAuthorized}`,
				]);
			}
			return true;
		} else {
			if (config.FallbackOnUnauthorized) {
				this.router.navigate([
					`${!config.AbsolutePath ? state.url : ''}${config.FallbackOnUnauthorized}`,
				]);
			} else {
				sccHostService.isSCC
					? sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' })
					: this.router.navigate(['/Error/UnauthorizedOnThisPage'], {
							queryParams: { path: state.url },
					  });
			}
			return false;
		}
	}
}
