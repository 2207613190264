import { Injectable } from '@angular/core';
import { ChangeEvent } from '@wcd/domain';
import { ChangeEventFieldsService } from './change-event.fields.service';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { ChangeEventEntityDetailsComponent } from '../../../../global_entities/components/entity-details/change-event.entity-details.component';
import { ChangeEventEntityPanelComponent } from '../components/change-event.entity-panel.component';
import { ChangeEventTextService } from '../../../../tvm/services/tvm-change-events-texts.service';

export interface ChangeEventDataViewFixedOptions {}

@Injectable()
export class ChangeEventEntityTypeService implements EntityTypeService<ChangeEvent> {
	constructor(private changeEventTextService: ChangeEventTextService) {}

	readonly entityType: EntityType<ChangeEvent> = {
		id: 'ChangeEvent',
		entity: ChangeEvent,
		entityDetailsComponentType: ChangeEventEntityDetailsComponent,
		singleEntityPanelComponentType: ChangeEventEntityPanelComponent,
		getEntityName: changeEvent => this.changeEventTextService.getChangeEventText(changeEvent),
		entitySingularNameKey: 'tvm_common_event',
		entityPluralNameKey: 'tvm_common_events',
		getEntityDataviewLink: () => '/tvm-event-insights',
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<ChangeEvent, ChangeEventDataViewFixedOptions>>{
			fields: ChangeEventFieldsService,
		},
	};
}
