
		<wcd-checklist-dropdown
			#checkListDropdown
			[buttonTooltip]="'dataview.customizeColumns' | i18n"
			[openMenuPosition]="openMenuPosition"
			[showIconDropdown]="!smallScreenView"
			[buttonIcon]="icon"
			[buttonText]="smallScreenView ? '' : ('dataview.customizeColumns' | i18n)"
			[ariaLabel]="!smallScreenView ? '' : ('dataview.customizeColumns' | i18n)"
			class="hidden-action command-bar-item-dropdown"
			[ngModel]="visibleFields | async"
			name="dataview-fields"
			[isBordered]="false"
			[disableEmptySelection]="true"
			[secondButton]="'reset' | i18n"
			(secondButtonClick)="resetVisibleFieldsAndFocus()"
			(ngModelChange)="onColumnsChange($event)"
			[values]="dataViewModel.dataTableFields | selectableDataviewFields: selectableFieldIds"
			[navigateUsingArrowKeysOnly]="true"
			[disableSecondButton]="dataViewModel.allDefaultFieldsSelected"
			(toggleDropdownState)="toggleDropdownState($event)"
		>
		</wcd-checklist-dropdown>
	