export const GEO_SPLITTERS: any = { bing: '~', google: ',' };

export class GeoCoordinates {
	lat: number;
	long: number;

	constructor(location?: string) {
		if (location) {
			const parts = location.split(',');
			this.lat = +parts[0];
			this.long = +parts[1];
		}
	}

	toString(splitter: string = ','): string {
		return `${this.lat + splitter + this.long}`;
	}
}
