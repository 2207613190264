import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation, HostBinding } from '@angular/core';

export const enum DISPLAY_MODE {
	GRID,
	COLUMN,
}

@Component({
	selector: 'wcd-dl',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./details-list.component.scss'],
	template: `
		<ng-content></ng-content>
	`,
	encapsulation: ViewEncapsulation.None,
})
export class DetailsListComponent {
	@Input() display = DISPLAY_MODE.GRID;

	@HostBinding('class.columnar')
	get columnar() {
		return this.display === DISPLAY_MODE.COLUMN;
	}
}
