var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { FileInstance } from '../file/file-instance.entity';
import { CyberEventAdditionalFields } from '../event/cyber-event-additional-fields.value-object';
var ɵ0 = function (itemId, entity, config, params) {
    return "files?searchBy=sha1&searchTerm=" + params['fileHash'];
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = ['ProcessId', 'Id', 'id'], ɵ3 = ['ProcessId', 'Id'], ɵ4 = ['ProcessCreationTime', 'CreationTime'], ɵ5 = ['ProcessCommandLine', 'Commandline', 'CommandLine'], ɵ6 = ['Account', 'User'], ɵ7 = ['ProcessIntegrityLevel', 'IntegrityLevel'], ɵ8 = ['ProcessTokenElevation', 'TokenElevation'], ɵ9 = function (commands) { return (Array.isArray(commands) ? commands.join('\n') : commands); };
//force tests to run
var Process = /** @class */ (function (_super) {
    __extends(Process, _super);
    function Process() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(Process.prototype, "name", {
        get: function () {
            return this.processName || (this.file ? this.file.name : this.account);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Process.prototype, "fileId", {
        get: function () {
            return this.file && this.file.id;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", Object)
    ], Process.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", Number)
    ], Process.prototype, "processId", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Date)
    ], Process.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: ɵ5 }),
        __metadata("design:type", String)
    ], Process.prototype, "commandLine", void 0);
    __decorate([
        EntityField({ data: 'ProcessAccount' }),
        __metadata("design:type", String)
    ], Process.prototype, "account", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", LegacyUser)
    ], Process.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'ImageFile', parse: function (file, rawData) {
                file && (file["deviceName"] = rawData.deviceName);
                file && (file["deviceId"] = rawData.deviceId);
                return file;
            },
        }),
        __metadata("design:type", FileInstance)
    ], Process.prototype, "file", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", String)
    ], Process.prototype, "integrityLevel", void 0);
    __decorate([
        EntityField({ data: ɵ8 }),
        __metadata("design:type", Object)
    ], Process.prototype, "tokenElevation", void 0);
    __decorate([
        EntityField({ data: 'PowershellDecodedCommand' }),
        __metadata("design:type", String)
    ], Process.prototype, "decodedCommandLine", void 0);
    __decorate([
        EntityField({ data: 'CreatingProcessId' }),
        __metadata("design:type", String)
    ], Process.prototype, "creatingProcessId", void 0);
    __decorate([
        EntityField({ data: 'CreatingProcessName' }),
        __metadata("design:type", String)
    ], Process.prototype, "creatingProcessName", void 0);
    __decorate([
        EntityField({ data: 'name' }),
        __metadata("design:type", String)
    ], Process.prototype, "processName", void 0);
    __decorate([
        EntityField({
            data: 'PowershellCommands',
            parse: ɵ9,
        }),
        __metadata("design:type", String)
    ], Process.prototype, "executedCommands", void 0);
    __decorate([
        EntityField({ data: 'AdditionalFields' }),
        __metadata("design:type", CyberEventAdditionalFields)
    ], Process.prototype, "additionalFields", void 0);
    __decorate([
        EntityField({ data: 'MergeByKey' }),
        __metadata("design:type", String)
    ], Process.prototype, "uniqueEntityId", void 0);
    __decorate([
        EntityField({ data: 'deviceName' }),
        __metadata("design:type", String)
    ], Process.prototype, "deviceName", void 0);
    __decorate([
        EntityField({ data: 'deviceId' }),
        __metadata("design:type", String)
    ], Process.prototype, "deviceId", void 0);
    Process = __decorate([
        Entity({
            singularName: 'Process',
            pluralName: 'Processes',
            endpoint: "files",
            parseItemQuery: ɵ0,
            //TODO GET files
            baseUrl: ɵ1,
            readonly: true,
        })
    ], Process);
    return Process;
}(EntityModelBase));
export { Process };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
