import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { SecurityAlertSummary } from './aatp-security-alert-summary.value-object';

@ValueObject({
	singularName: 'AATP Profile',
	pluralName: 'AATP Profiles',
	readonly: true,
})
export class AatpProfile extends ModelBase {
	@EntityField({ data: 'LastFailedAuthenticationTime' })
	readonly lastFailedAuthenticationTime?: Date | undefined;

	@EntityField({ data: 'Url' })
	readonly url: string;

	@EntityField({ data: 'LastSiteName' })
	readonly lastSiteName: string | null;

	@EntityField({ data: 'ParentGroupCount' })
	readonly parentGroupCount: number;

	@EntityField({ data: 'SecurityAlertSummary' })
	readonly securityAlertSummary: SecurityAlertSummary;
}
