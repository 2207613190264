import { SevilleModule } from '../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { AppInsightsService } from '../../../insights/services/app-insights.service';

SevilleModule.controller('seville.onboarding.download', onboardingDownloadController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('onboarding.download', {
			url: '/download',
			views: {
				onboardingStepView: {
					template: `
						<div class="onboarding-page"  style="left: 310px;margin-left: 310px;">
                <div class="onboarding-endpoint-mgmt-table-cell">
                    <img src="/assets/images/onboarding/img_onboarding.svg" class="onboarding-endpoint-mgmt-side-image" />
                </div>
                <div class="onboarding-endpoint-mgmt-table-cell onboarding-endpoint-mgmt-table-cell-main" >
                    <div class="onboarding-endpoint-title">
                        Microsoft Defender for Endpoint is almost ready
                    </div>
                    <div>To start experiencing Microsoft Defender for Endpoint, you need to onboard at least one device and run a detection test on that device. Ensure you:</div>
                    <div>
                        <download-endpoint-onboarding-body endpoint-onboarding-changed="download.endpointOnboardingChanged(machineOnboarded, ranTestAlert)"></download-endpoint-onboarding-body>
                    </div>
                    <div class="onboarding-navigation-finish">
                        <div>
                            <div ng-click="download.CheckEndpointOnboardingStatus()">
                                Start using Microsoft Defender for Endpoint
                                <i class="icon icon-Sell rotate-270 icon-md onboarding-paging-icon onboarding-finish-margin"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            `,
					controller: 'seville.onboarding.download',
					controllerAs: 'download',
				},
			},
		});
	},
]);

onboardingDownloadController.$inject = [
	'$scope',
	'$state',
	'$window',
	'$location',
	'$anchorScroll',
	'$http',
	'appConfig',
	'$uibModal',
	'appInsights',
];

function onboardingDownloadController(
	$scope,
	$state,
	$window,
	$location,
	$anchorScroll,
	$http,
	appConfig: AppConfigService,
	$modal,
	appInsights: AppInsightsService
) {
	if (!$scope.$parent.stepPermissionsVisited) {
		$state.go('onboarding.permissions');
	} else {
		var vm = this;
		appInsights.trackEvent('EndpointOnboarding-Page', { Page: 'OnboardingDownload' });

		vm.machineOnboarded = false;
		vm.ranTestAlert = false;

		vm.endpointOnboardingChanged = function(machineOnboarded, ranTestAlert) {
			vm.machineOnboarded = machineOnboarded;
			vm.ranTestAlert = ranTestAlert;
			appInsights.trackEvent('EndpointOnboarding-Status', {
				Page: 'OnboardingDownload',
				RanTestAlert: vm.ranTestAlert,
				MachineOnboarded: vm.machineOnboarded,
			});
		};

		vm.onboardingBaseUrl = appConfig.serviceUrls.onboarding;

		$scope.$parent.resetStepsState(function() {
			$scope.$parent.stepPermissionsDone = true;
			$scope.$parent.stepWelcomeDone = true;
			$scope.$parent.stepPreferencesDone = true;
			$scope.$parent.stepDownloadInProgress = true;
		});

		$location.hash('');
		$anchorScroll();

		vm.CheckEndpointOnboardingStatus = function() {
			if (vm.machineOnboarded && vm.ranTestAlert) {
				appInsights.trackEvent('EndpointOnboarding-MoveNext', {
					Page: 'OnboardingDownload',
					RanTestAlert: vm.ranTestAlert,
					MachineOnboarded: vm.machineOnboarded,
				});

				MoveNext();
			} else {
				$modal
					.open({
						template: `
          <div>
              <div class="onboarding-endpoint-modal-title">
                  <span class="icon icon-Info"></span>
                  Setup incomplete
              </div>
              <div class="onboarding-endpoint-modal-section">
                  To experience Microsoft Defender for Endpoint, you need to onboard and test at least one device. You can also onboard devices later.
              </div>
              <div class="text-right">
                  <button class="onboarding-button onboarding-button-enabled" ng-click="ok()">
                      Proceed anyway
                  </button>
                  <span>
                      <button type="reset" class="onboarding-button onboarding-endpoint-modal-cancel-button" ng-click="cancel()">Cancel</button>
                  </span>
              </div>
          </div>
          `,
						controller: 'seville.onboarding.download.warning',
						size: 'md',
					})
					.result.then(function() {
						appInsights.trackEvent('EndpointOnboarding-MoveNextAfterProceedAnyway', {
							Page: 'OnboardingDownload',
							RanTestAlert: vm.ranTestAlert,
							MachineOnboarded: vm.machineOnboarded,
						});
						MoveNext();
					});
			}
		};
	}

	function MoveNext() {
		$scope.$parent.stepPermissionsDone = true;
		$scope.$parent.stepWelcomeDone = true;
		$scope.$parent.stepPreferencesDone = true;
		$scope.$parent.stepDownloadDone = true;
		$scope.$parent.stepDownloadInProgress = false;
		$scope.$parent.stepFinishInProgress = true;
		SetStateToComplete();
	}

	function SetStateToComplete() {
		$http
			.get(vm.onboardingBaseUrl + '/SetOnboardingStateToComplete', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$scope.$parent.stepDownloadInProgress = false;
						$window.location.href = '/';
					} else {
						console.log('status: ' + response.status);
					}
				},
				function(response) {
					console.log('status: ' + response.status);
				}
			);
	}
}
