var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MachinesService } from './../services/machines.service';
import { OnInit } from '@angular/core';
import { Machine, MachineSecurityAnalytics } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
var MachineSecurityStatePanelComponent = /** @class */ (function (_super) {
    __extends(MachineSecurityStatePanelComponent, _super);
    function MachineSecurityStatePanelComponent(dialogsService, machineService, router) {
        var _this = _super.call(this, router) || this;
        _this.dialogsService = dialogsService;
        _this.machineService = machineService;
        _this.controls = [];
        return _this;
    }
    MachineSecurityStatePanelComponent.prototype.ngOnInit = function () {
        this.controls = this.extractSecurityControls(this.machineSecurityAnalytics);
    };
    MachineSecurityStatePanelComponent.prototype.doPropertyAction = function (propertyName) {
        // Open missing updates side pane
        if (this.ShouldPropertyDisplayAction(propertyName)) {
            this.machineService.showMachineCvesPanel(this.machineSecurityAnalytics.machineSecurityMissingKbs);
        }
    };
    MachineSecurityStatePanelComponent.prototype.ShouldPropertyBeDisplayed = function (propertyName) {
        // Excluding Poncherello
        return (propertyName !== 'SecurityUpdatesPoncherelloOs' &&
            propertyName !== 'SecurityUpdatesPoncherelloFirmware' &&
            propertyName !== 'SecurityUpdatesPoncherelloOsAndFirmware');
    };
    MachineSecurityStatePanelComponent.prototype.ShouldPropertyDisplayAction = function (propertyName) {
        return (propertyName == 'SecurityUpdatesUpToDate' &&
            this.machineSecurityAnalytics.machineSecurityMissingKbs.length > 0);
    };
    MachineSecurityStatePanelComponent.prototype.getAdditionalInfo = function (controlType) {
        if (controlType === 'AntiVirus') {
            return this.machineSecurityAnalytics.machineSecurityAvSigVersion;
        }
        return '';
    };
    MachineSecurityStatePanelComponent.prototype.extractSecurityControls = function (machineSecurityLatestState) {
        var machineSecurityAnalyticsControls = [];
        if (!machineSecurityLatestState) {
            return machineSecurityAnalyticsControls;
        }
        var controlMaxScore = machineSecurityLatestState.maxPotentialSecurityScore /
            machineSecurityLatestState.securityControls.length;
        machineSecurityLatestState.securityControls.map(function (control) {
            return machineSecurityAnalyticsControls.push({
                type: control.controlType,
                score: control.score,
                maxScore: controlMaxScore,
                overallStatus: control.overallStatus,
                properties: control.propertyImprovementScores,
            });
        });
        return machineSecurityAnalyticsControls;
    };
    return MachineSecurityStatePanelComponent;
}(PanelContainer));
export { MachineSecurityStatePanelComponent };
