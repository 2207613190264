import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { ParseDataQuery, TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';
import { ChangeEventByDate } from './change-event-by-date.value-object';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	cache: null,
	parseQuery: dataQuery => ({
		params: ParseDataQuery(dataQuery),
	}),
	name: 'Top change events per day by software',
	endpoint: (_, dataQuery) => `products/product/changeEvents/topPerDay`,
	type: ChangeEventByDate,
})
export class ChangeEventTopPerDayBySoftwareApiCall extends ApiCallModel<ChangeEventByDate[]> {}
