var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AddMachineGroupsFilterQueryParam, Cache, DefaultApiVersion, TvmEndPoint } from './commonEndPointUtils';
var BaseUrl = function (config) { return config.data.serviceUrls.tvm + "/" + TvmEndPoint.OrgSettings; };
var ɵ0 = BaseUrl;
var SharedConfig = {
    cache: Cache,
    baseUrl: BaseUrl,
    customHeaders: DefaultApiVersion,
};
var ɵ1 = function (item) { return (item.id ? 'PUT' : 'POST'); };
export var TvmOrgSettingsSharedEntityConfigurations = __assign({}, SharedConfig, { saveMethod: ɵ1, readonly: true });
var ɵ2 = function (input) {
    var params = {};
    if (input.hasOwnProperty('configurationId')) {
        params['configurationId'] = input.configurationId;
    }
    AddMachineGroupsFilterQueryParam(params);
    return {
        params: params,
    };
};
export var TvmOrgSettingsApiCallSharedConfigurations = __assign({}, SharedConfig, { parseQuery: ɵ2 });
export { ɵ0, ɵ1, ɵ2 };
