import { ICommandBarItemOptionsRenderContext } from '@angular-react/fabric';
import { ComponentFactoryResolver, Injectable, Injector, TemplateRef } from '@angular/core';
import { Params, Router } from '@angular/router';
import { compact, merge, omit } from 'lodash-es';
import { ICommandBarItemProps, IContextualMenuItem, IIconProps } from 'office-ui-fabric-react';
import { FabricIconNames, isFabricIconName } from '@wcd/scc-common';
import {
	ItemActionIconNameOrConfig,
	ItemActionModel,
	ItemActionType,
} from '../../../dataviews/models/item-action.model';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { TrackingEventPropertiesBase } from '../../../insights/models/tracking-event.interface';
import {
	TrackingAttributesMap,
	TrackingEventsModelService,
} from '../../../insights/services/tracking-events-model.service';
import { RbacControlSettings, RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { IconsService } from '@wcd/icons';
import { CommandBarCustomIconComponent } from './command-bar-custom-icon.component';
import { ICommandBarItem } from './models/command-bar-item.models';
import { IDatePickerCommandBarItemOptions } from './models/date-picker-command-bar-item.model';
import { sccHostService } from '@wcd/scc-interface';
import { MtpWorkload } from '@wcd/domain';

/**
 * Add a more specific type to an `ICommandBarItem`'s `data` property bag.
 * This types the `data` part of the following example:
 * @example
 * ```typescript
 * const item: WithData<ICommandBarItem, 'rbac', RbacControlSettings> = {
 *     key: 'item1',
 *     data: {
 *         rbac: {
 *             state: ...
 *             permissions: ...
 *         }
 *     }
 * };
 *
 * This type is what allows (type-)safe access to `item.data.rbac.state`.
 * ```
 */
export type WithData<
	TCommandBarItem extends ICommandBarItem,
	TDataKey extends string,
	TData
> = TCommandBarItem & {
	data: TCommandBarItem['data'] & { [K in TDataKey]: TData };
};

/**
 * Adds type-safe access to the RBAC settings in an `ICommandBarItem`.
 */
export type WithRbacSettings<T extends ICommandBarItem> = WithData<T, 'rbacSettings', RbacControlSettings>;

/**
 * Adds type-safe access to the item action model settings in an `ICommandBarItem`.
 */
export type WithItemActionModel<T extends ICommandBarItem> = WithData<T, 'itemAction', ItemActionModel>;

@Injectable()
export class CommandBarItemService {
	constructor(
		private readonly rbacControlService: RbacControlService,
		private readonly router: Router,
		private readonly factoryResolver: ComponentFactoryResolver,
		private readonly iconsService: IconsService,
		private readonly injector: Injector,
		private readonly trackingEventsModelService: TrackingEventsModelService,
		private readonly i18nService: I18nService
	) {}

	/**
	 * Applies RBAC permissions to a `ICommandBarItem`, making it disabled/hidden.
	 * Does not mutate the original object.
	 */
	withRbac<T extends ICommandBarItem>(item: T, rbacSettings: RbacControlSettings): WithRbacSettings<T> {
		const { state, allowRbacTooltipOverride } = rbacSettings;
		const hasRequiredPermissions: boolean = this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);

		const rbacDisabled = !hasRequiredPermissions && state !== RbacControlState.hidden;
		const rbacRespectingItem = Object.assign<{}, T, Pick<T, 'disabled' | 'hidden'>, { title: string }>(
			{},
			item,
			{
				disabled: item.disabled || rbacDisabled,
				hidden: !hasRequiredPermissions && state === RbacControlState.hidden,
			},
			allowRbacTooltipOverride && rbacDisabled
				? { title: this.i18nService.get('common.permissions.noPermissionTooltip') }
				: null
		);

		return this.withData(rbacRespectingItem, 'rbacSettings', rbacSettings);
	}

	/**
	 * Creates a date picker `ICommandBarItem` from the given template
	 */
	createDatePicker({
		key,
		templateRef,
	}: {
		key: ICommandBarItem['key'];
		templateRef: TemplateRef<{
			item: IDatePickerCommandBarItemOptions;
			dismissMenu: (ev?: any, dismissAll?: boolean) => void;
		}>;
	}): IDatePickerCommandBarItemOptions {
		return this.withCustomRendering({ key }, { templateRef });
	}

	/**
	 * Takes an `ICommandBarItem` and a `TemplateRef` and changes it to render as the given template.
	 */
	withCustomRendering<T extends ICommandBarItem>(
		item: T,
		options: { templateRef: TemplateRef<ICommandBarItemOptionsRenderContext> }
	): T {
		return Object.assign<{}, T, Pick<T, 'render'>>({}, item, {
			render: options.templateRef,
		});
	}

	/**
	 * Takes an `ICommandBarItem` and options for routing and returns a new `ICommandBarItem` that when clicked will navigate using the given route options.
	 */
	withRouting<T extends ICommandBarItem>(
		item: T,
		routingOptions: {
			link: string | ReadonlyArray<string>;
			isExternal?: boolean;
			queryParams?: Params;
		}
	): T {
		const { link, isExternal, queryParams } = routingOptions;
		const path = Array.isArray(link) ? link.join('/') : String(link);
		const paramsStr =
			queryParams &&
			Object.keys(queryParams)
				.map(p => `${p}=${queryParams[p]}`)
				.join('&');

		if (isExternal) {
			const iconProps: Partial<IIconProps> = {
				iconName: FabricIconNames.OpenInNewWindow,
			};
			item.iconProps = iconProps;
			this.withCustomIcon(item);
		}
		return Object.assign<{}, T, Pick<T, 'href' | 'target'>>({}, item, {
			href: `${path}${paramsStr ? '?' + paramsStr : ''}`,
			// Adds target="_blank" so that the external link will be opened in a new tab
			target: isExternal == true ? '_blank' : '',
		});
	}

	/**
	 * Adds support for rendering custom icons (as defined in `IconsService`).
	 * Icon will be chosen based on the string in `iconProps.iconName`.
	 */
	withCustomIcon<T extends ICommandBarItem>(item: T): T {
		return Object.assign<{}, T, Pick<T, 'renderIcon'>>({}, item, {
			renderIcon: {
				componentType: CommandBarCustomIconComponent,
				factoryResolver: this.factoryResolver,
				injector: this.injector,
			},
		});
	}

	/**
	 * Adds tracking (via the regular `data-track-*` attributes) to the given item.
	 */
	withTracking<T extends ICommandBarItem>(
		item: T,
		trackingEventProperties: TrackingEventPropertiesBase
	): T & TrackingAttributesMap {
		const trackingAttributesMap = this.trackingEventsModelService.convertEventToAttribute(
			trackingEventProperties
		);

		return Object.assign({}, item, trackingAttributesMap);
	}

	withHideUnderline<T extends ICommandBarItem>(item: T): T {
		return Object.assign({}, item, {
			buttonStyles: {
				rootHovered: { 'text-decoration': 'none' },
				rootFocused: { 'text-decoration': 'none' },
			},
		});
	}

	fromItemActionModel<TData = any>(options: {
		itemActionModel: ItemActionModel<TData>;
		onClick?: (itemActionModel: ItemActionModel<TData>) => void;
		tagsTemplateRef?: TemplateRef<ICommandBarItemOptionsRenderContext>;
	}):
		| WithItemActionModel<ICommandBarItem>
		| (WithItemActionModel<ICommandBarItem> & WithRbacSettings<ICommandBarItem>) {
		const { itemActionModel, onClick, tagsTemplateRef } = options;

		const getIconName = (icon: ItemActionModel['icon']) =>
			typeof icon === 'string' ? icon : icon.iconName;

		const createIconProps = (
			itemIcon: ItemActionIconNameOrConfig
		): { iconProps: IIconProps; isCustomIcon: boolean } => {
			const iconName = itemIcon && getIconName(itemIcon);
			const icon = itemIcon && this.iconsService.getIcon(iconName);
			if (!icon) {
				return {
					iconProps: {},
					isCustomIcon: false,
				};
			}

			// Although <icon> can handle Fabric icons (falls back to displaying <fab-icon>),
			// we can avoid this needless work by seeing if this is a Fabric icon first and adjusting only if it's not
			// The native Fabric icon also accepts more props, including class names, which can be specified on the `ItemActionModel`.
			if (isFabricIconName(icon.name)) {
				let iconProps: Partial<IIconProps> = {
					iconName: icon.name,
				};

				if (typeof itemActionModel.icon === 'object') {
					if (itemActionModel.icon.className) {
						iconProps = {
							...iconProps,
							styles: { root: itemActionModel.icon.className },
						};
					}

					iconProps = {
						...iconProps,
						...omit(itemActionModel.icon, 'className', 'iconName'), // Omit all known properties, so we add just the custom ones
					};
				}

				return { isCustomIcon: false, iconProps: iconProps };
			}

			return {
				isCustomIcon: true,
				iconProps: {
					iconName: iconName,
				},
			};
		};

		const { iconProps, isCustomIcon } = createIconProps(itemActionModel.icon);

		// Every item action gets assigned tracking by default (override-able)
		let item = this.withTracking<ICommandBarItem>(
			{
				key: itemActionModel.id,
				text: itemActionModel.nameKey
					? this.i18nService.get(itemActionModel.nameKey)
					: itemActionModel.name,
				iconProps: iconProps,
				ariaLabel: itemActionModel.ariaLabel,
				role: 'menuitem',
				href: itemActionModel.href,
				target: itemActionModel.href && itemActionModel.openLinkInNewTab ? '_blank' : null,
				disabled: itemActionModel.disabled || !onClick,
				title: itemActionModel.tooltip,
				onClick: () => {
					if (itemActionModel.href && !itemActionModel.openLinkInNewTab && sccHostService.isSCC) {
						this.router.navigateByUrl(itemActionModel.href);
					}

					if (itemActionModel.type === ItemActionType.Tags || !onClick) {
						return;
					}

					onClick(itemActionModel);
				},
				data: {
					itemAction: itemActionModel,
				},
				buttonStyles:
					itemActionModel.styles ||
					({
						// `.nowrap` is a workaround for office-ui-fabric-react issue #6168 (https://github.com/OfficeDev/office-ui-fabric-react/issues/6168)
						root: compact(['nowrap', itemActionModel.buttonClass]),
						rootDisabled: [
							itemActionModel.buttonClass,
							{
								'pointer-events': 'inherit',
							},
						],
						rootPressed: itemActionModel.buttonClass,
						rootHovered: itemActionModel.buttonClass,
						rootFocused: itemActionModel.buttonClass,
					} as ICommandBarItemProps['buttonStyles']),
				subMenuProps: itemActionModel.values
					? {
							items: itemActionModel.values.map<IContextualMenuItem>(itemActionValue => ({
								key: itemActionValue.id,
								text: itemActionValue.name,
								iconProps: itemActionValue.icon && {
									iconName: itemActionValue.icon,
								},
								data: {
									itemActionValue: itemActionValue,
								},
								onClick: () => {
									itemActionValue.method(null, null, null, itemActionValue);
								},
							})),
					  }
					: undefined,
			},
			{
				id: itemActionModel.id,
				type: TrackingEventType.Action,
			}
		);

		if (itemActionModel.rbac || itemActionModel.rbacMtpPermission) {
			item = this.withRbac(item, {
				permissions: itemActionModel.rbac,
				mtpPermissions: itemActionModel.rbacMtpPermission,
				mtpWorkloads: itemActionModel.rbacMtpWorkloads,
				requireAllPermissions: itemActionModel.rbacRequireAllPermissions,
				ignoreNonActiveWorkloads: itemActionModel.rbacIgnoreNonActiveWorkloads,
				state: itemActionModel.rbacState,
				allowRbacTooltipOverride: itemActionModel.allowRbacTooltipOverride,
				customCheckCallback: itemActionModel.customCheckCallback
			});
		}

		if (isCustomIcon) {
			item = this.withCustomIcon(item);
		}

		// If the item specified any specific tracking, use that instead of the default ones
		if (itemActionModel.tracking) {
			item = this.withTracking(item, itemActionModel.tracking);
		}

		if (itemActionModel.type === ItemActionType.Tags && tagsTemplateRef) {
			item = this.withCustomRendering(item, {
				templateRef: tagsTemplateRef,
			});
		}

		if (itemActionModel.href && itemActionModel.href.startsWith('/')) {
			item = this.withHideUnderline(item);
		}

		// Needs casting due to bad typing of `data` in office-ui-react-fabric (typed as `any`, making it hard to override)
		return item as WithItemActionModel<ICommandBarItem>;
	}

	private withData<TCommandBarItem extends ICommandBarItem, TDataKey extends string, TData>(
		item: TCommandBarItem,
		key: TDataKey,
		data: TData
	): WithData<TCommandBarItem, TDataKey, TData> {
		return merge({}, item, {
			data: {
				[key]: data,
			},
		});
	}
}
