
		<span *ngIf="detectionSource?.detectionType">
			<wcd-shared-icon
				[iconName]="detectionSource.detectionType.iconName"
				class="wcd-margin-xsmall-right"
			></wcd-shared-icon>
			<ng-container [ngSwitch]="detectionSource.detectionType.id">
				<ng-container *ngSwitchCase="EvidenceDetectionSourceTypeEnum.alert">
					<entity-name
						[entity]="alertEntity"
						[entityTypeId]="Alert"
						[hideIcon]="true"
						[showTooltip]="showTooltip"
					></entity-name>
					<span
						*ngIf="detectionSource.alertSeverity"
						class="wcd-severity wcd-margin-small-left"
						[ngClass]="'wcd-severity-' + detectionSource.alertSeverity.type"
					>
						{{ detectionSource.alertSeverity.nameI18nKey | i18n }}
					</span>
				</ng-container>
				<ng-container *ngSwitchCase="EvidenceDetectionSourceTypeEnum.investigation">
					<span>{{ investigationDetectionName }}</span>
					<span *ngIf="detectionSource.actionStatus">
						<div
							class="wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"
							[ngClass]="'color-box-' + detectionSource.actionStatus.className"
						></div>
						{{ detectionSource.actionStatus.name }}
					</span>
				</ng-container>
			</ng-container>
		</span>
	