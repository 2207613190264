var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { AdminService } from '../services/admin.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { Location } from '@angular/common';
import { filter } from 'rxjs/operators';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
var lastId = 0;
var AdminComponent = /** @class */ (function () {
    function AdminComponent(adminService, route, router, mainAppStateService, location, appContextService) {
        var _this = this;
        this.adminService = adminService;
        this.route = route;
        this.router = router;
        this.mainAppStateService = mainAppStateService;
        this.location = location;
        this.appContextService = appContextService;
        this.buttonId = "settings-nav-button-id-" + lastId++;
        this.resultCount = 0;
        this.valueIndex = 0;
        this.isScc = sccHostService.isSCC;
        mainAppStateService.state$.subscribe(function (mainAppState) {
            _this.mainAppState = mainAppState;
        });
        router.events
            .pipe(filter(function (event) { return event instanceof NavigationEnd; }))
            .subscribe(function (event) {
            _this.setActive(event.url);
        });
    }
    AdminComponent.prototype.ngOnInit = function () {
        var _a;
        this.settingsNav = this.adminService.getSettingsNav(this.route);
        var counter = 0;
        _a = this.settingsNav.reduce(function (_a, setting) {
            var indexedNav = _a[0], mappedNav = _a[1];
            var indexedStates = setting.states.reduce(function (_a, state) {
                var indexedStates = _a[0], mappedNav = _a[1];
                var indexState = __assign({}, state, { index: counter++ });
                mappedNav.push(indexState);
                indexedStates.push(indexState);
                return [indexedStates, mappedNav];
            }, [[], mappedNav])[0];
            var indexedSettingsNav = __assign({}, setting, { indexedStates: indexedStates });
            indexedNav.push(indexedSettingsNav);
            return [indexedNav, mappedNav];
        }, [[], []]), this.indexedSettingsNav = _a[0], this.mappedSettingNav = _a[1];
        this.resultCount = counter;
        if (this.mappedSettingNav)
            this.activeElement = this.getActive();
    };
    AdminComponent.prototype.setActive = function (path) {
        if (this.mappedSettingNav)
            this.activeElement = this.getActive(path);
    };
    AdminComponent.prototype.getActive = function (path) {
        if (path === void 0) { path = this.location.path(); }
        return this.mappedSettingNav.find(function (state) {
            return path.startsWith((state.isLegacy ? '' : '/preferences2/') + state.route);
        });
    };
    return AdminComponent;
}());
export { AdminComponent };
