import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../../../shared/shared.module';
import { DataViewsModule } from '../../../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { TvmModule } from '../../../../../tvm/tvm.module';
import { BaselineConfigurationFieldsService } from './services/baseline-configuration.fields.service';
import { ProfileConfigurationEntityTypeService } from './services/profile-configuration.entity-type.service';
import { ProfileConfigurationEntityPanelComponent } from './components/profile-configuration.entity-panel.component';
import { DataTableModule } from '@wcd/datatable';
import { ConfigurationDevicesBaseComponent } from './components/configurations-devices/configuration-devices.base/configuration-devices.base.component';
import { ProfileConfigurationDevicesComponent } from './components/configurations-devices/profile-configuration-devices.component';
import { GeneralConfigurationDevicesComponent } from './components/configurations-devices/general-configuration-devices.component';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		TvmModule,
		DataTableModule,
	],
	declarations: [
		ProfileConfigurationEntityPanelComponent,
		ConfigurationDevicesBaseComponent,
		ProfileConfigurationDevicesComponent,
		GeneralConfigurationDevicesComponent,
	],
	exports: [
		ProfileConfigurationEntityPanelComponent,
		ConfigurationDevicesBaseComponent,
		ProfileConfigurationDevicesComponent,
		GeneralConfigurationDevicesComponent,
	],
	entryComponents: [
		ProfileConfigurationEntityPanelComponent,
		ConfigurationDevicesBaseComponent,
		ProfileConfigurationDevicesComponent,
		GeneralConfigurationDevicesComponent,
	],
	providers: [BaselineConfigurationFieldsService, ProfileConfigurationEntityTypeService],
})
export class ProfileConfigurationEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ProfileConfigurationEntityTypeService);
	}
}
