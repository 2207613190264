import { keyBy } from 'lodash-es';
import { InternetFacingReason } from './internet-facing-reason.enum';
var ɵ0 = InternetFacingReason.ExternalIpAddress, ɵ1 = InternetFacingReason.ExternalNetworkConnection, ɵ2 = InternetFacingReason.PublicScan;
export var internetFacingReasons = [
    {
        id: ɵ0,
        i18nNameKey: 'InternetFacingTag_ExternalIpAddress_tooltip',
        priority: 1,
    },
    {
        id: ɵ1,
        i18nNameKey: 'InternetFacingTag_ExternalNetworkConnection_tooltip',
        priority: 2,
    },
    {
        id: ɵ2,
        i18nNameKey: 'InternetFacingTag_PublicScan_tooltip',
        priority: 3,
    }
];
var ɵ3 = function (o) { return o.id; };
export var internetFacingReasonMap = keyBy(internetFacingReasons, ɵ3);
export { ɵ0, ɵ1, ɵ2, ɵ3 };
