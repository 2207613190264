import { ChangeDetectorRef, OnDestroy, Optional, Pipe, PipeTransform } from '@angular/core';
import { LocaleConfigService } from '@wcd/localization';
import { isNil } from 'lodash-es';
import { Subscription } from 'rxjs';
import { PrettyNumberService } from '../services/pretty-number.service';

@Pipe({
	name: 'prettyNumber',
	pure: false,
})
export class PrettyNumberPipe implements PipeTransform, OnDestroy {
	private localeSubscription: Subscription;
	private currentTransformedValue: string;
	private value: number;

	constructor(
		private readonly prettyNumberService: PrettyNumberService,
		@Optional() private readonly localeConfigService: LocaleConfigService,
		@Optional() private readonly changeDetectorRef: ChangeDetectorRef
	) {}

	transform(number: number, locale?: string) {
		if (!this.changeDetectorRef || !this.localeConfigService) {
			return this.prettyNumberService.prettyNumber(number, locale);
		}

		if (isNil(this.value)) {
			if (!isNil(number)) {
				this.value = number;
				this.localeSubscription = this.localeConfigService.selectedLocale$.subscribe(() => {
					this.currentTransformedValue = this.prettyNumberService.prettyNumber(number, locale);
					this.changeDetectorRef.markForCheck();
				});
			}

			return this.currentTransformedValue;
		}

		// value changed, need to reset
		if (number !== this.value) {
			this.dispose();
			return this.transform(number, locale);
		}

		return this.currentTransformedValue;
	}

	private dispose() {
		this.localeSubscription && this.localeSubscription.unsubscribe();
		this.value = null;
		this.currentTransformedValue = null;
	}

	ngOnDestroy() {
		this.dispose();
	}
}
