import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService } from '../../../../../tvm/services/tvm-color.service';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';
import { Router } from '@angular/router';
import { CompliantDevicesDataviewCellComponent } from '../components/compliant-devices-dataview-cell/compliant-devices-dataview-cell.component';
var ProfilesFieldsService = /** @class */ (function () {
    function ProfilesFieldsService(activatedEntity, router, i18nService, tvmColorService) {
        this.activatedEntity = activatedEntity;
        this.router = router;
        this.i18nService = i18nService;
        this.tvmColorService = tvmColorService;
    }
    Object.defineProperty(ProfilesFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.strings.tvm_common_name,
                        onClick: function (profile) {
                            _this.activatedEntity.setNextEntity(profile);
                            _this.router.navigate(["/baseline-compliance_t/profiles/" + encodeURIComponent(profile.id)]);
                        },
                    },
                    {
                        id: 'benchmark',
                        name: this.i18nService.strings.tvm_baseline_profiles_benchmark_title,
                        sort: { enabled: false },
                    },
                    {
                        id: 'version',
                        name: this.i18nService.strings.tvm_baseline_profiles_version_title,
                        sort: { enabled: false },
                    },
                    {
                        id: 'baselineOsPlatform',
                        getDisplay: function (profile) { return profile.operatingSystem + " " + profile.operatingSystemVersion; },
                        name: this.i18nService.strings.tvm_baseline_profiles_software_title,
                        filter: { priority: 1 }
                    },
                    {
                        id: 'compliantDevices',
                        name: this.i18nService.strings.tvm_baseline_profiles_compliantDevices_title,
                        component: {
                            type: CompliantDevicesDataviewCellComponent,
                            getProps: function (profile) { return ({
                                totalAssetCount: profile.totalDevices,
                                assetsCount: profile.passedDevices,
                                assetsCountHistory: profile.compliantDevicesHistory,
                                profileId: profile.id,
                            }); },
                        },
                        allowResize: false,
                        minWidth: 200,
                        maxWidth: 200,
                        sort: { enabled: false },
                    },
                    {
                        id: 'profileStatus',
                        name: this.i18nService.strings.tvm_baseline_profiles_status_title,
                        sort: { enabled: true, sortDescendingByDefault: true },
                        icon: {
                            fabricIcon: FabricIconNames.StatusCircleInner,
                            className: function (profile) {
                                return _this.tvmColorService.getStatusIcon(profile.status);
                            },
                        },
                        getDisplay: function (profile) {
                            return profile.status
                                ? _this.i18nService.strings.tvm_baseline_profiles_enabled
                                : _this.i18nService.strings.tvm_baseline_profiles_disabled;
                        },
                        filter: { priority: 2 }
                    },
                    {
                        id: 'complianceLevel',
                        name: this.i18nService.strings.tvm_baseline_profiles_profileApplicability_title,
                        sort: { enabled: false },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return ProfilesFieldsService;
}());
export { ProfilesFieldsService };
