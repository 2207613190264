var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { SccResourceService } from './resource.svc';
import { SccAuthService } from './auth.svc';
import { SccStateSvc } from './state.svc';
import { SccUiService } from './ui.svc';
import { SccAjaxService } from './ajax.svc';
import { SccConfigService } from './config.svc';
import { SccMockService } from './mock.svc';
import { SccMTPService } from './mtp.svc';
import { SccI18nService } from './i18n.svc';
import { SccRolesService } from './scc-roles.svc';
import { SccLogService } from './log.svc';
import { SccPerfService } from './perf.svc';
import { AnnouncerService } from './announcer.svc';
import { AutoFocusService } from './auto-focus.svc';
function inferIsPortalMtoFromUrl() {
    var mtoParam = new URL(location.href).searchParams.get('mto');
    return (mtoParam && mtoParam !== 'false') || localStorage.getItem('mto');
}
var SccHostService = /** @class */ (function () {
    // When not in SCC default to null, stub for unit tests
    function SccHostService(sccHost) {
        if (sccHost === void 0) { sccHost = { loginUser: {} }; }
        this.sccHost = sccHost;
        this.loginUser = this.sccHost.loginUser;
        // TODO(bgruenbaum) remove once we have support from scc
        this.portalType = inferIsPortalMtoFromUrl() ? 'mto' : this.sccHost.portalType;
        this.auth = new SccAuthService(this.sccHost.auth);
        this.resource = new SccResourceService(this.sccHost.resource);
        this.state = new SccStateSvc(this.sccHost.state);
        this.announcer = new AnnouncerService();
        this.autoFocus = new AutoFocusService();
        this.ui = new SccUiService(this.sccHost.ui, this.announcer);
        this.config = new SccConfigService(this.sccHost.config);
        this.mock = new SccMockService(this.sccHost.config, this.loginUser);
        this.log = new SccLogService(this.sccHost.log);
        this.ajax = new SccAjaxService(this.sccHost.ajax, this.loginUser.tenantId, this.mock, this.sccHost.i18n, this.sccHost.ui);
        this.i18n = new SccI18nService(this.sccHost.i18n);
        this.mtp = new SccMTPService(this.resource);
        this.roles = new SccRolesService(this.sccHost.auth ? this.auth : null, this.sccHost.resource ? this.resource : null);
        this.perf = new SccPerfService(this.sccHost.perf);
        this.appLoadedOnce = false;
    }
    Object.defineProperty(SccHostService.prototype, "isSCC", {
        get: function () {
            return !this.mtpGlobal.wdatpMode;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccHostService.prototype, "isMTO", {
        get: function () {
            return this.portalType === "mto";
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccHostService.prototype, "mtpGlobal", {
        get: function () {
            if (!window.mdatp) {
                window.mdatp = {};
            }
            return window.mdatp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccHostService.prototype, "endpoints", {
        get: function () {
            return window.$host.endpoints;
        },
        enumerable: true,
        configurable: true
    });
    SccHostService.prototype.inSccRole = function (role) {
        return this.roles.inRole(role);
    };
    SccHostService.prototype.inSccRoleAsync = function (role) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                return [2 /*return*/, this.roles.inRoleAsync(role)];
            });
        });
    };
    SccHostService.prototype.getPackageBasePathByPkg = function (pkg) {
        if (pkg === void 0) { pkg = 'wdatp'; }
        return window.require.toUrl(pkg);
    };
    SccHostService.prototype.getPackageVersionByPkg = function (pkg) {
        if (pkg === void 0) { pkg = 'wdatp'; }
        return window.$host.resource.version(pkg);
    };
    SccHostService.prototype.getWicdResource = function (pkgName, packageResource) {
        return this.resource.load("wicd-" + pkgName + "/" + packageResource);
    };
    return SccHostService;
}());
export var sccHostService = new SccHostService(window.$host);
