import { Alert, EvidenceDetectionSource, EvidenceDetectionSourceTypeEnum } from '@wcd/domain';
import { EvidenceService } from '../services/evidence.service';
var EvidenceDetectionContextComponent = /** @class */ (function () {
    function EvidenceDetectionContextComponent(evidenceService) {
        this.evidenceService = evidenceService;
        this.EvidenceDetectionSourceTypeEnum = EvidenceDetectionSourceTypeEnum;
        this.Alert = Alert;
        this.showTooltip = true;
    }
    Object.defineProperty(EvidenceDetectionContextComponent.prototype, "detectionContext", {
        set: function (val) {
            this.detectionSource = val;
            if (val && val.detectionType) {
                switch (val.detectionType.id) {
                    case EvidenceDetectionSourceTypeEnum.alert:
                        var alertDetectionName = this.evidenceService.getEvidenceDetectionContextDisplayName(this.detectionSource);
                        this.alertEntity = new Alert({ id: val.detectionId, name: alertDetectionName });
                        break;
                    case EvidenceDetectionSourceTypeEnum.investigation:
                        this.investigationDetectionName = this.evidenceService.getEvidenceDetectionContextDisplayName(this.detectionSource);
                        break;
                    default:
                        break;
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    return EvidenceDetectionContextComponent;
}());
export { EvidenceDetectionContextComponent };
