<article
	class="relative wcd-entity-sidePanel wcd-flex-none wcd-flex-vertical wcd-full-height"
	[ngClass]="{
		'responsive-panel-in-responsive-entity-page': !minimized,
		'wcd-entity-idPanel': !minimized,
		'left-bordered': toggleButtonPosition === 'left' && !isNarrowLayout,
		'wcd-padding-xxLarge-right': rightSidePanel && !minimized && !removePaddingRight,
		'wcd-padding-xxLarge-left': !rightSidePanel && !minimized && !isScc && !isNarrowLayout,
		'wcd-border-right': !isNarrowLayout,
		'wcd-responsive-shell-padding-none-left-min-max-screens': isScc
	}"
>
	<div class="header-section"
		 [class.wcd-padding-large-bottom]="!minimized && showTitle">
		<h2 [hidden]="minimized" *ngIf="showTitle">
			{{ entityTitle }}
		</h2>
		<div class="responsive-side-panel-toggle-button">
			<fab-default-button
				[id]="'side-panel-toggle-button-' + paneUniqueId"
				*ngIf="showToggleButton && !isNarrowLayout"
				[iconProps]="{ iconName: toggleIconName }"
				(onClick)="toggleSidePanel()"
				[styles]="toggleButtonStyleSettings"
				[ariaLabel]="
					!!minimized
						? i18nService.strings.dataview_entity_details_panel_button_show
						: i18nService.strings.dataview_entity_details_panel_button_hide
				"
			>
			</fab-default-button>
		</div>
	</div>
	<div [hidden]="!minimized" class="wcd-entity-panel-minimized-content">
		<ng-container #minimizedEntityViewPlaceholder></ng-container>
	</div>

	<div
		[hidden]="minimized"
		class="wcd-flex-1 entity-details-responsive"
		[class.wcd-scroll-vertical]="!isNarrowLayout"
		[class.wcd-padding-xxLarge-left]="(isNarrowLayout && !isScc) || rightSidePanel"
		[class.wcd-padding-xxLarge-right]="!rightSidePanel && !isNarrowLayout"
	>
		<section #entityDetailsPlaceholder></section>
	</div>
</article>
