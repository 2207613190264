var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { getTheme } from '@uifabric/styling';
import { PrettyNumberService } from '@wcd/prettify';
import { maxBy } from 'lodash-es';
import { map } from 'rxjs/operators';
import { AlertsByDetectionSourceApiCall } from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { WidgetType } from '../../../../components/report-with-filters.component';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { Paris } from '@microsoft/paris';
var AlertsByDetectionSourceWidget = /** @class */ (function (_super) {
    __extends(AlertsByDetectionSourceWidget, _super);
    function AlertsByDetectionSourceWidget(reportsService, i18nService, prettyNumberService, router, appInsightsService, paris) {
        var _this = _super.call(this, reportsService) || this;
        _this.i18nService = i18nService;
        _this.prettyNumberService = prettyNumberService;
        _this.router = router;
        _this.appInsightsService = appInsightsService;
        _this.paris = paris;
        _this.widgetType = WidgetType.Daily;
        _this.today = new Date();
        _this.colorPalette = getTheme().palette;
        _this.detectionSrcIndexToName = new Map([
            [0, 'MTP'],
            [1, 'WindowsDefenderAtp'],
            [2, 'WindowsDefenderAv'],
            [3, 'WindowsDefenderSmartScreen'],
            [4, 'CustomerTI'],
            [5, 'OfficeATP'],
            [6, 'AutomatedInvestigation'],
            [7, 'ThreatExperts'],
            [8, 'CustomDetection'],
            [9, 'ThirdPartySensors'],
        ]);
        _this.titles = new Map([
            'all',
            'mtp',
            'edr',
            'antivirus',
            'smartScreen',
            'customTi',
            'officeAtp',
            'automatedInvestigation',
            'threatExperts',
            'customDetection',
            'thirdPartySensors',
        ].map(function (title) {
            return [title, _this.i18nService.get("reporting.alertsByDetectionSource." + title)];
        }));
        _this.chartSettings$ = _this.data$.pipe(map(function (data) { return data && _this.getSettings(data); }));
        _this.navigateToAlertsPage = function (detectionSrcIndex) {
            var parsedFilters = detectionSrcIndex + 1
                ? "detectionSource=" + _this.detectionSrcIndexToName.get(detectionSrcIndex)
                : '';
            _this.appInsightsService.trackEvent('UI', {
                type: TrackingEventType.Navigation,
                id: 'GoToAlertsQueue',
                component: 'Dashboard',
                widget: 'AlertsByDetectionSource',
                column: _this.detectionSrcIndexToName.get(detectionSrcIndex),
            });
            _this.router.navigate(['/alertsQueue'], {
                queryParams: { range: "6months" /* '6months' */, filters: parsedFilters },
            });
        };
        return _this;
    }
    Object.defineProperty(AlertsByDetectionSourceWidget.prototype, "widgetConfig", {
        get: function () {
            return this.getWidgetConfig();
        },
        enumerable: true,
        configurable: true
    });
    AlertsByDetectionSourceWidget.prototype.loadData = function (params) {
        return this.paris
            .apiCall(AlertsByDetectionSourceApiCall, params)
            .pipe(map(function (dataset) { return dataset.items[0]; }));
    };
    AlertsByDetectionSourceWidget.prototype.getWidgetConfig = function () {
        var _this = this;
        return {
            id: 'alertsByDetectionSourceDaily',
            name: this.i18nService.get('reporting.alertsByDetectionSource.dailyTitle'),
            getDateOfWidget: function () {
                return _this.currentDataDate;
            },
            loadData: function (filtersState) {
                var serializedFilters = _this.reportsService.fixDataIfNeeded(filtersState, _this.widgetType);
                _this.currentFilters = serializedFilters;
                _this.currentDataDate = _this.reportsService.calcCurrentDataDateForDaily(serializedFilters, _this.today);
                return _this.loadData(serializedFilters);
            },
        };
    };
    AlertsByDetectionSourceWidget.prototype.getSettings = function (data) {
        var _this = this;
        var _a;
        var maxCount = data && data.alertsCount && maxBy(Object.values(data.alertsCount));
        return {
            options: {
                data: {
                    columns: [
                        [
                            this.titles.get('all'),
                            data.alertsCount.mtp,
                            data.alertsCount.edr,
                            data.alertsCount.antivirus,
                            data.alertsCount.smartScreen,
                            data.alertsCount.customTi,
                            data.alertsCount.officeAtp,
                            data.alertsCount.automatedInvestigation,
                            data.alertsCount.threatExperts,
                            data.alertsCount.customDetection,
                            data.alertsCount.thirdPartySensors,
                        ],
                    ],
                    groups: [[this.titles.get('all')]],
                    colors: (_a = {},
                        _a[this.titles.get('all')] = this.colorPalette.tealLight,
                        _a),
                    labels: {
                        format: function (value) { return (maxCount > 0 ? _this.prettyNumberService.prettyNumber(value) : ''); },
                    },
                    onclick: function (e) { return _this.navigateToAlertsPage(e.index); },
                },
                tooltip: {
                    grouped: false,
                    contents: function (data) {
                        return "<table class=\"c3-tooltip\">\n\t\t\t\t\t\t\t<tbody>\n\t\t\t\t\t\t\t\t<tr>\n\t\t\t\t\t\t\t\t\t<td class=\"name\">" + data[0].value + "</td>\n\t\t\t\t\t\t\t\t</tr>\n\t\t\t\t\t\t\t</tbody>\n\t\t\t\t\t\t</table>";
                    },
                },
                bar: {
                    width: {
                        max: 50,
                        ratio: 0.1,
                    },
                },
                axis: {
                    y: {
                        max: maxCount ? maxCount : 0,
                        min: 0,
                        tick: {
                            values: data ? ReportsService.getYTicks(maxCount) : [],
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                        },
                        padding: {
                            bottom: 0,
                        },
                    },
                    x: {
                        type: 'category',
                        categories: [
                            this.titles.get('mtp'),
                            this.titles.get('edr'),
                            this.titles.get('antivirus'),
                            this.titles.get('smartScreen'),
                            this.titles.get('customTi'),
                            this.titles.get('officeAtp'),
                            this.titles.get('automatedInvestigation'),
                            this.titles.get('threatExperts'),
                            this.titles.get('customDetection'),
                            this.titles.get('thirdPartySensors'),
                        ],
                        tick: {
                            multiline: false,
                            culling: {
                                max: 1,
                            },
                            rotate: 20,
                        },
                        padding: { right: 0.7 },
                        height: 80,
                    },
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    height: 200,
                },
            },
        };
    };
    return AlertsByDetectionSourceWidget;
}(ReportWidgetComponent));
export { AlertsByDetectionSourceWidget };
