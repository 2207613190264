var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AlertHistoryItem, Incident } from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { Paris } from '@microsoft/paris';
import { filter, map } from 'rxjs/operators';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { IncidentsService } from '../services/incidents.service';
import { AuthService, MtpPermission } from '@wcd/auth';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AlertsService } from '../../alerts/services/alerts.service';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
var IncidentCommentsComponent = /** @class */ (function (_super) {
    __extends(IncidentCommentsComponent, _super);
    function IncidentCommentsComponent(router, paris, activatedEntity, incidentsService, alertsService, authService, changeDetectorRef, dialogsService, i18nService, liveAnnouncer) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.incidentsService = incidentsService;
        _this.alertsService = alertsService;
        _this.authService = authService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.comments = [];
        _this.isSavingComment = false;
        _this.isLoadingComments = false;
        return _this;
    }
    IncidentCommentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.incident) {
            this.incidentSubscription = this.activatedEntity.currentEntity$
                .pipe(filter(function (entity) { return entity instanceof Incident; }))
                .subscribe(function (incident) {
                _this.incident = incident;
                _this.setComments();
            });
        }
        else {
            this.setComments();
        }
    };
    IncidentCommentsComponent.prototype.ngOnDestroy = function () {
        this.incidentSubscription && this.incidentSubscription.unsubscribe();
    };
    IncidentCommentsComponent.prototype.setComments = function () {
        var _this = this;
        this.isLoadingComments = true;
        this.rbacSettings = {
            mtpPermissions: [MtpPermission.SecurityData_Manage],
            mtpWorkloads: this.incident.mtpWorkloads,
            requireAllPermissions: true,
            ignoreNonActiveWorkloads: true,
            state: RbacControlState.disabled,
        };
        this.paris
            .query(AlertHistoryItem, {
            where: { entityType: 'IncidentEntity', id: this.incident.id },
        })
            .pipe(map(function (dataSet) { return dataSet.items; }))
            .subscribe(function (items) {
            _this.comments = items.map(function (historyItem) {
                return new CommentModel({
                    id: historyItem.id,
                    timestamp: historyItem.timestamp,
                    user: historyItem.userName,
                    message: _this.alertsService.getAlertHistoryMessage(historyItem),
                    icon: historyItem.type.icon,
                });
            });
            _this.isLoadingComments = false;
            _this.changeDetectorRef.markForCheck();
        });
    };
    IncidentCommentsComponent.prototype.saveComment = function (comment) {
        var _this = this;
        this.isSavingComment = true;
        this.incidentsService.addComment([this.incident.id], comment).subscribe(function (commentItem) {
            _this.isSavingComment = false;
            _this.comments = [
                new CommentModel({
                    id: commentItem.id,
                    user: (commentItem.user && commentItem.user.Upn) || _this.authService.currentUser.name,
                    message: commentItem.message || comment,
                    timestamp: commentItem.timestamp || new Date(),
                    icon: commentItem.icon,
                })
            ].concat((_this.comments || []));
            _this.liveAnnouncer.announce(_this.i18nService.get('comments.save.success', {
                date: commentItem.timestamp,
            }));
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isSavingComment = false;
            _this.dialogsService.showError({
                title: error.name || '',
                message: _this.i18nService.get('comments.save.error'),
                data: error.message,
            });
        });
    };
    return IncidentCommentsComponent;
}(PanelContainer));
export { IncidentCommentsComponent };
