import { SevilleUrlMappingModule } from './seville.urlMapping';
import { AppConfigService } from '@wcd/app-config';
import { APC_HEADER_KEY, AuthService } from '@wcd/auth';
import { IHttpResponse, IRequestConfig } from 'angular';

declare var angular: angular.IAngularStatic;

export let SevilleAuthenticationModule = angular.module('seville.authentication', [
	'ui.router',
	SevilleUrlMappingModule.name,
]);

SevilleAuthenticationModule.config([
	'$httpProvider',
	function($httpProvider) {
		// this will catch global http error codes of 401 (Unauthorized) and will navigate to sign in state
		$httpProvider.interceptors.push([
			'$injector',
			function($injector) {
				return {
					responseError: function(rejection) {
						if (rejection.status === 401) {
							// this case is expected once the access token is expired.
							// in this case, the user should perform login once again
							const appStateService = $injector.get('appStateService');
							appStateService.setGlobalAlertMessage(
								'Your session has timed out. Please wait while we redirect you...'
							);

							const authService = $injector.get('authenticationService');
							authService.reset();
							authService.handleAuthError();
						}

						if (rejection.status === 403) {
							// this is an authZ error : redirect the user to the proper 'not allowed to use seville' page..
							var ng2router = $injector.get('ng2router');
							ng2router.navigate(['/errors/403']);
						}

						return rejection;
					},
					request: function(req: IRequestConfig) {
						const authService = $injector.get('authenticationService');
						req.headers[APC_HEADER_KEY] = authService.getApcHeader();
						return req;
					},
					response: function(res: IHttpResponse<any>) {
						const authService = $injector.get('authenticationService');
						const apcHeader = res.headers()[APC_HEADER_KEY];
						if (apcHeader) {
							authService.setApcHeader(apcHeader);
						}
						return res;
					},
				};
			},
		]);
	},
]).factory('authenticationService', authenticationService);

authenticationService.$inject = ['$http', 'authService', 'urlMapping', 'appConfig'];

function authenticationService($http, authService: AuthService, urlMapping, appConfig: AppConfigService) {
	init();

	return {
		loggedInUser,
		handleAuthError,
		logOff,
		reset,
		isReadOnlyUser,
		isUserActionAllowed,
		getApcHeader,
		setApcHeader,
	};

	function init() {
		setToken(authService.token);
		authService.token$.subscribe(token => setToken(token));
	}

	function getApcHeader() {
		return authService.apcHeader;
	}

	function setApcHeader(apc: string) {
		authService.apcHeader = apc;
	}

	function setToken(token: string) {
		$http.defaults.headers.common.Authorization = 'Bearer ' + token;
	}

	function loggedInUser() {
		return authService.currentUser && authService.currentUser.name;
	}

	function handleAuthError() {
		authService.handleAuthError();
	}

	function reset() {
		delete $http.defaults.headers.common.Authorization;
		delete $http.defaults.headers.common.apc;
	}

	function logOff() {
		reset();

		this.authService.logout();
	}

	function isReadOnlyUser() {
		return authService.currentUser && authService.currentUser.isReadonly;
	}

	function isUserActionAllowed(permissions) {
		var userRoleActionsMap = {
			viewData: { id: 1 },
			alertsInvestigation: { id: 2 },
			remediationActions: { id: 4 },
			systemSettings: { id: 8 },
			securitySettings: { id: 16 },
			tvmViewData: { id: 128 },
			tvmRemediationHandling: { id: 256 },
			tvmExceptionHandling: { id: 512 },
			configurationManagement: { id: 1024 },
		};

		return permissions.some(permission => {
			return authService.currentUser.hasMdeAllowedUserRoleAction(userRoleActionsMap[permission]);
		});
	}
}
