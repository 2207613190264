import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { EvaluationStepType, EvaluationStepStatus } from './evaluation-model';

@ValueObject({
	singularName: 'Evaluation step',
	pluralName: 'Evaluation step',
})
export class EvaluationStep extends ModelBase {
	@EntityField({ data: 'Name' })
	name: EvaluationStepType;

	@EntityField({ data: 'SubStepName' })
	subStepName: EvaluationStepType;

	@EntityField({ data: 'Status' })
	status: EvaluationStepStatus;
}
