import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { MachineGroup } from '@wcd/domain';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class MachineGroupEntityTypeService implements EntityTypeService<MachineGroup> {
	constructor(private router: Router, private authService: AuthService, private i18nService: I18nService) {}

	readonly entityType: EntityType<MachineGroup> = {
		id: 'machineGroup',
		entity: MachineGroup,
		loadFullEntityInPanel: false,
	};
}
