import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';

@ValueObject({
	pluralName: 'File download links',
	singularName: 'File download link',
	readonly: true,
})
export class FileDownloadLink extends ModelBase {
	@EntityField({ data: 'DownloadSasUri' })
	downloadUrl: string;
}
