import { Observable, forkJoin, of } from 'rxjs';
import { map, take, tap, mergeMap } from 'rxjs/operators';
import { get } from 'lodash-es';
import { FabricIconNames } from '@wcd/scc-common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { FeaturesService, Feature } from '@wcd/config';
import { ChartSettings, LegendItem } from '@wcd/charts';
import { PrettyNumberService } from '@wcd/prettify';
import { I18nService } from '@wcd/i18n';
import { Paris, DataEntityType } from '@microsoft/paris';
import {
	AssetsByExposureCategoryApiCall,
	ExposureScoreCategory,
	OperatingSystemPlatform,
	OperatingSystemPlatformCategories,
	MachineOsPlatformApiCall,
	MachineProtection,
	OsPlatformKeyType,
	AssetsByExposureCategories,
	AssetsByExposureCategoriesNonSupported,
	AssetsByCategory,
} from '@wcd/domain';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { TvmColor, TvmColorService } from '../../../services/tvm-color.service';
import {
	TvmMachineGroupsFilterService,
} from '../../../services/tvm-machine-groups-filter.service';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { TvmOsSupportService } from '../../../services/tvm-os-support.service';
import { WidgetType } from '../../../../reports/components/report-with-filters.component';

enum TvmOsPlatformsCategory {
	'Supported' = 'Supported',
	'NotSupported' = 'NotSupported',
}

@Component({
	selector: 'tvm-devices-exposure-distribution-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './devices-exposure-distribution.widget.html',
	styleUrls: ['./devices-exposure-distribution.widget.scss'],
})
export class TvmDevicesExposureDistributionWidget extends ReportWidgetComponent<any> {
	private readonly pieConfig: Record<
		AssetsByExposureCategories,
		{ color: string; name?: string; legendHtml?: string; order: number; ignoreOnZero?: boolean }
	> = {
			[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED]: {
				color: this.tvmColorsService.colorsMap.get(TvmColor.Neutral).raw,
				name: this.i18nService.get(
					'tvm.dashboard.devicesExposureDistributionWidget.categories.OS_NOT_SUPPORTED'
				),
				legendHtml: `
				<span>${this.i18nService.get(
					'tvm.dashboard.devicesExposureDistributionWidget.categories.OS_NOT_SUPPORTED'
				)}</span>
				<a href="https://docs.microsoft.com/en-us/windows/security/threat-protection/microsoft-defender-atp/tvm-supported-os"
					data-track-id="TvmSupportedOsReadMore"
					data-track-type="ExternalLink"
					target="_blank">
					${this.i18nService.get('common.learnMore')}
				</a>
			`,
				order: 1,
				ignoreOnZero: true,
			},
			[AssetsByExposureCategoriesNonSupported.OTHER]: {
				color: this.tvmColorsService.colorsMap.get(TvmColor.NeutralAlt).raw,
				name: this.i18nService.get('common.noDataAvailable'),
				order: 2,
				ignoreOnZero: true,
			},
			[ExposureScoreCategory.Low]: {
				color: this.tvmColorsService.colorsMap.get(TvmColor.LowRisk).raw,
				name: this.i18nService.get('tvm.dims.riskLevel.members.low'),
				order: 3,
			},
			[ExposureScoreCategory.Medium]: {
				color: this.tvmColorsService.colorsMap.get(TvmColor.MediumRisk).raw,
				name: this.i18nService.get('tvm.dims.riskLevel.members.medium'),
				order: 4,
			},
			[ExposureScoreCategory.High]: {
				color: this.tvmColorsService.colorsMap.get(TvmColor.HighRisk).raw,
				name: this.i18nService.get('tvm.dims.riskLevel.members.high'),
				order: 5,
			},
		};

	accessibleChartData: Record<AssetsByExposureCategories, { count: number; percent: number }>;

	pieSettings: ChartSettings;

	upperTitle$: Observable<string> = this.data$.pipe(
		map(_ => {
			if (!this._origData) return '';
			return `${this.prettyNumberService.prettyNumber(this.totalDevicesCount)}`;
		})
	);

	data_: Observable<any> = this.data$.pipe(
		map(data => {
			return data.map((dataPoint, index) => {
				const realRatio = this.totalDevicesCount ? dataPoint.value / this.totalDevicesCount : 0;
				const text = this.i18nService.get(
					'tvm.dashboard.devicesExposureDistributionWidget.device.prettyValue',
					{
						prettyNumber: this.prettyNumberService.prettyNumber(dataPoint.value),
						realRatio: Math.round(realRatio * 100),
					}
				);

				`${this.prettyNumberService.prettyNumber(dataPoint.value)} devices (${Math.round(
					realRatio * 100
				)}%)`;
				const ariaLabel = `${dataPoint.name}: ${text}`;
				return { ...dataPoint, ariaLabel };
			});
		})
	);

	private isMgSelected: boolean;

	constructor(
		reportsService: ReportsService,
		private tvmColorsService: TvmColorService,
		private prettyNumberService: PrettyNumberService,
		private paris: Paris,
		private i18nService: I18nService,
		private router: Router,
		private tvmTextsService: TvmTextsService,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private featuresService: FeaturesService,
		private tvmOsSupportService: TvmOsSupportService
	) {
		super(reportsService);
	}

	/* data manipulation explanation:
		we want the donut to render a visible sector for each severity cateogry with more than 0 devices,
		but if for some cateogry(s) the device count is very small relative to the total count (cateogryDevicesCount / "totalDevicesCount" < "_thresholdPct"), the sector is virtually invisible (0 or 1 px wide).
		so in such case we provide a fakeDevicesCount to force the donut to show a small sector, and we treat the tooltips to show the original reading, not the fake.
	*/
	private _thresholdPct = 0.01;
	private _fakeDevicesCount: number;
	totalDevicesCount: number;

	showOsNotSupportedLegend: boolean;
	showOtherNotSupportedLegend: boolean;

	private _origData: { id: string; name: string; value: number }[];

	private setup(data: { id: string; name: string; value: number }[]) {
		this._origData = data; //we save the original array for the tooltips to show real data
		this.totalDevicesCount = data.reduce((prevVal, currVal) => prevVal + currVal.value, 0);
		this._fakeDevicesCount = Math.ceil(this.totalDevicesCount * this._thresholdPct);
		this.showOsNotSupportedLegend = this._origData.some(
			entry => entry.id === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED
		);
		this.showOtherNotSupportedLegend = this._origData.some(
			entry => entry.id === AssetsByExposureCategoriesNonSupported.OTHER
		);
		this.secondaryLegendItems = []; //setup is being called whenever user changes machine groups, so we reset the array here
		if (this.showOsNotSupportedLegend) {
			this.secondaryLegendItems.push({
				nameHtml: this.pieConfig[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED].legendHtml,
				iconColor: this.pieConfig[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED].color,
			});
		}
		if (this.showOtherNotSupportedLegend) {
			this.secondaryLegendItems.push({
				name: this.pieConfig[AssetsByExposureCategoriesNonSupported.OTHER].name,
				iconColor: this.pieConfig[AssetsByExposureCategoriesNonSupported.OTHER].color,
				helpKey: `tvm.dashboard.devicesExposureDistributionWidget.otherNonSupportedHelp`,
			});
		}
		this.setUpPieSettings();
	}

	private setUpPieSettings() {
		this.pieSettings = {
			columnName: 'name',
			columnValue: 'value',
			options: {
				color: {
					pattern: Object.keys(this.pieConfig)
						.filter(
							k =>
								(!(k === AssetsByExposureCategoriesNonSupported.OTHER) ||
									this.showOtherNotSupportedLegend) &&
								(!(k === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED) ||
									this.showOsNotSupportedLegend)
						)
						.map(k => this.pieConfig[k]['color']),
				},
				data: {
					order: (dataObj1, dataObj2) => {
						const order1key = Object.keys(this.pieConfig).find(
							key => this.pieConfig[key]['name'] === dataObj1['id']
						) as AssetsByExposureCategories;
						const order1 = this.pieConfig[order1key].order;
						const order2key = Object.keys(this.pieConfig).find(
							key => this.pieConfig[key]['name'] === dataObj2['id']
						) as AssetsByExposureCategories;
						const order2 = this.pieConfig[order2key].order;
						return order1 - order2;
					},
					onclick: value => {
						this.openMachinesPage(value.id);
					},
				},
				tooltip: {
					contents: function (d, defaultTitleFormat, defaultValueFormat, color) {
						const data = d[0];
						if (!data.value || data.value === '0') return null;
						const realRatio = this.totalDevicesCount
							? this._origData[data.index].value / this.totalDevicesCount
							: 0;
						const text = this.i18nService.get(
							'tvm.dashboard.devicesExposureDistributionWidget.device.prettyValue',
							{
								prettyNumber: this._origData[data.index].value,
								realRatio: Math.round(realRatio * 100),
							}
						);
						return `<div class="wcd-tooltip wcd-tooltip__visible" style="line-height:1.3">
								<div><span style="display:inline-block;width:12px;height:12px;font-size:14px;margin-right:6px;background-color:${color(
							data
						)}"></span>${data.name}</div>
								<div>${text}</div>
							</div>`;
					}.bind(this),
				},
				donut: {
					title: this.i18nService.get('tvm_common_total'),
					width: 36,
					label: {
						show: false,
					},
				},
				legend: {
					show: false,
				},
				size: {
					width: 220,
					height: 220,
				},
				padding: {
					top: 0,
					right: 0,
					bottom: 0,
					left: 0,
				},
			},
		};
	}

	get widgetConfig() {
		return {
			id: 'devicesExposureDistributionWidget',
			name: this.i18nService.get('tvm.dashboard.devicesExposureDistributionWidget.title'),
			noDataMessage: () =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.dashboard.noData.topExposedMachines',
					isGroupSelected: this.isMgSelected,
				}),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () => {
				/**
				 * Untill a new TVM flow that counts the unsupported machines will get setup, Non supported machines counts will be calculated by querying the Machine Health report data:
				 * 1. Compute the total count of TVM supported machines ("tvmSupported")
				 * 2. Using the Machine Health Report compute the total MDATP machines count ("total"), and the total machines count of machines with non-supported OS platforms ("OsNotSupported")
				 * 3. Then the total count of non-supported machines due to other reasons ("Other") is total-tvmSupported-OsNotSupported
				 */
				return this.machineGroupsFilterService.machineGroupsFilter$.pipe(
					take(1),
					mergeMap(machineGroupsFilterData => {
						this.isMgSelected = machineGroupsFilterData.isFiltering;

						/**
						 * We're querying the MGMT Reporting API : Machine Health Report with their ApiCall that fetches Machine count by Os Platform ("MachineOsPlatformApiCall")
						 * We prepare here the Api Call params, to exactly match their query for fetching today's data (rather than for the history data)
						 * We add TVMs machine groups as filters in case the TVM user prmopted to see only specific machine groups
						 */
						const mgmtReportParams = {
							...this.reportsService.fixDataIfNeeded(null, WidgetType.Daily),
							alignToPeriod: 'true',
							...(machineGroupsFilterData.isFiltering
								? {
									rbacGroupIds: machineGroupsFilterData.machineGroups
										.filter(mg => mg.isSelected)
										.map(mg => mg.groupId),
								}
								: null),
						};

						// For when the feature is disabled we don't want to retrieve the breakdown into platforms (no need)
						// the feature flag is disabled also for environments which don't have the management endpoint (like FF)
						const mgmtQuery = this.featuresService.isEnabled(Feature.TvmUnsupportedMachinesUI) ?
							this.paris.apiCall<MachineProtection>(MachineOsPlatformApiCall, mgmtReportParams) :
							of({ data: [{ values: {} }] });

						return forkJoin([
							//TODO: bad practice ahead - the empty params is due to the fact that Paris fires the "parseQuery" CB only if arg is provided
							this.paris.apiCall<AssetsByCategory[]>(AssetsByExposureCategoryApiCall, {}),
							mgmtQuery,
						]).pipe(
							map(
								(data: [AssetsByCategory[], MachineProtection]): AssetsByCategory[] => {
									const exposureScoreCategories = Object.keys(ExposureScoreCategory);

									const assetsByCategoryArr = data[0].filter(AssetsByCategory =>
										exposureScoreCategories.includes(AssetsByCategory.category)
									);

									const machinesByOsPlatform = data[1]['data'][0]['values'] as Record<
										OsPlatformKeyType,
										number
									>; //we DO want to break if MGMT Api breaks.

									let machinesStats = {
										tvmSupported: assetsByCategoryArr.reduce(
											(prev, curr) => (prev += curr.assetCount),
											0
										),
										[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED]: 0,
										[AssetsByExposureCategoriesNonSupported.OTHER]: 0,
										total: 0,
									};

									const tvmSupportedPlatforms: Array<
										OperatingSystemPlatform
									> = this.getTvmOsPlatforms(TvmOsPlatformsCategory.Supported);

									machinesStats = Object.keys(machinesByOsPlatform).reduce(
										(
											prev: {
												tvmSupported: number;
												[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED]: number;
												[AssetsByExposureCategoriesNonSupported.OTHER]: number;
												total: number;
											},
											osPlatformKey
										) => {
											const osCount = machinesByOsPlatform[osPlatformKey];
											const total = prev.total + osCount;
											let notSupported =
												prev[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED];
											if (
												!tvmSupportedPlatforms.some(
													nonSupportedPlatform =>
														nonSupportedPlatform.category === osPlatformKey
												)
											) {
												notSupported = notSupported + osCount;
											}
											return {
												tvmSupported: prev.tvmSupported,
												[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED]: notSupported,
												[AssetsByExposureCategoriesNonSupported.OTHER]: 0,
												total: total,
											};
										},
										machinesStats
									);

									machinesStats[AssetsByExposureCategoriesNonSupported.OTHER] =
										machinesStats.total -
										machinesStats.tvmSupported -
										machinesStats[
										AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED
										];

									return [
										...assetsByCategoryArr,
										{
											category: AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED,
											assetCount:
												machinesStats[
												AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED
												],
										},
										{
											category: AssetsByExposureCategoriesNonSupported.OTHER,
											assetCount:
												machinesStats[AssetsByExposureCategoriesNonSupported.OTHER],
										},
									];
								}
							),
							map((assetsByCategoryArr: AssetsByCategory[]) => {
								if (
									!assetsByCategoryArr ||
									!assetsByCategoryArr.some(e => e.assetCount > 0)
								) {
									return null;
								}

								if (!this.featuresService.isEnabled(Feature.TvmUnsupportedMachinesUI)) {
									assetsByCategoryArr.find(
										entry =>
											entry.category ===
											AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED
									).assetCount = 0;
									assetsByCategoryArr.find(
										entry =>
											entry.category === AssetsByExposureCategoriesNonSupported.OTHER
									).assetCount = 0;
								}

								return Object.keys(this.pieConfig)
									.map(key => {
										const assetByExposureCategory = assetsByCategoryArr.find(
											entry => entry.category === key
										);

										if (
											(!assetByExposureCategory ||
												!assetByExposureCategory.assetCount) &&
											this.pieConfig[key].ignoreOnZero
										)
											return;

										const value = assetByExposureCategory
											? assetByExposureCategory.assetCount
											: 0;
										return {
											id: key,
											name: this.pieConfig[key].name,
											value: value,
										};
									})
									.filter(Boolean);
							}),
							tap((data: { id: string; name: string; value: number }[]) => {
								if (data) {
									//saving the orig data for the tooltips manipulation:
									this.setup(data);
								}
								return data;
							}),
							tap((data: { id: string; name: string; value: number }[]) => {
								if (data && this.totalDevicesCount) {
									const countProperty = 'value'; // TODO: use safe-nav op when TS is upgraded to 3.7.*...

									const osNonSupportedCount = get(
										data.find(
											o =>
												o.id ===
												AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED
										),
										countProperty
									);
									const otherNonSupportedCount = get(
										data.find(o => o.id === AssetsByExposureCategoriesNonSupported.OTHER),
										countProperty
									);
									const lowCount = get(
										data.find(o => o.id === ExposureScoreCategory.Low),
										countProperty
									);
									const mediumCount = get(
										data.find(o => o.id === ExposureScoreCategory.Medium),
										countProperty
									);
									const highCount = get(
										data.find(o => o.id === ExposureScoreCategory.High),
										countProperty
									);

									this.accessibleChartData = {
										...(osNonSupportedCount && {
											[AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED]: {
												count: osNonSupportedCount,
												percent: Math.round(
													(osNonSupportedCount / this.totalDevicesCount) * 100
												),
											},
										}),
										...(otherNonSupportedCount && {
											[AssetsByExposureCategoriesNonSupported.OTHER]: {
												count: otherNonSupportedCount,
												percent: Math.round(
													(otherNonSupportedCount / this.totalDevicesCount) * 100
												),
											},
										}),
										[ExposureScoreCategory.Low]: {
											count: lowCount,
											percent: Math.round((lowCount / this.totalDevicesCount) * 100),
										},
										[ExposureScoreCategory.Medium]: {
											count: mediumCount,
											percent: Math.round((mediumCount / this.totalDevicesCount) * 100),
										},
										[ExposureScoreCategory.High]: {
											count: highCount,
											percent: Math.round((highCount / this.totalDevicesCount) * 100),
										},
									};
								}
								return data;
							}),
							map(data => {
								if (!data) {
									return [];
								}

								return (data as {
									id: string;
									name: any;
									value: number;
								}[]).map(el => {
									if (
										el.value === 0 ||
										this.totalDevicesCount === 0 ||
										el.value / this.totalDevicesCount > this._thresholdPct
									) {
										return { ...el };
									}
									//manipulating the data as described:
									return {
										name: el.name,
										value: this._fakeDevicesCount,
									};
								});
							})
						);
					})
				);
			},
			minHeight: '400px',
		};
	}

	private getTvmOsPlatforms(mode: TvmOsPlatformsCategory) {
		return (<DataEntityType>OperatingSystemPlatform).entityConfig.values.filter(
			(osPlatformObj: OperatingSystemPlatform) => {
				const supported = this.tvmOsSupportService.supportedOsPlatformCategories.some(
					category => category === osPlatformObj.category
				);
				return mode === TvmOsPlatformsCategory.Supported ? supported : !supported;
			}
		);
	}

	legendItems: Array<LegendItem> = [
		{
			name: this.pieConfig.Low.name,
			iconColor: this.pieConfig.Low.color,
		},
		{
			name: this.pieConfig.Medium.name,
			iconColor: this.pieConfig.Medium.color,
		},
		{
			name: this.pieConfig.High.name,
			iconColor: this.pieConfig.High.color,
		},
	];

	secondaryLegendItems: Array<LegendItem>;

	onTitleClick(): void {
		this.openMachinesPage();
	}

	onAccessibilityLink(cat): void {
		this.openMachinesPage(cat);
	}

	private buildOsQuery(OperatingSystemPlatformArr: OperatingSystemPlatform[]) {
		return OperatingSystemPlatformArr.reduce(
			(
				acc: Array<{ category: OperatingSystemPlatformCategories; platforms: string[] }>,
				osPlatformObj: OperatingSystemPlatform
			): any => {
				const osPlatformCategory = osPlatformObj.category;
				const osPlatform = osPlatformObj.id;

				let obj = acc.find((o: any) => o.category === osPlatformCategory);
				if (!obj) {
					obj = {
						category: osPlatformCategory,
						platforms: [],
					};
					acc.push(obj);
				}
				obj.platforms.push(osPlatform);
				return acc;
			},
			[]
		)
			.map(o => o.platforms.join())
			.join('|');
	}

	openMachinesPage(category?: string): void {
		const filtersArr = [];
		if (category) {
			const key = Object.keys(this.pieConfig).find(key => this.pieConfig[key]['name'] === category);
			if (key === AssetsByExposureCategoriesNonSupported.OS_NOT_SUPPORTED) {
				const query = this.buildOsQuery(this.getTvmOsPlatforms(TvmOsPlatformsCategory.NotSupported));

				if (query) {
					const filter = `osPlatforms=${encodeURIComponent(query)}`;
					filtersArr.push(filter);
				}
			} else if (key === AssetsByExposureCategoriesNonSupported.OTHER) {
				const query = this.buildOsQuery(this.getTvmOsPlatforms(TvmOsPlatformsCategory.Supported));

				if (query) {
					const filter = `osPlatforms=${encodeURIComponent(query)},exposureScores=None`;
					filtersArr.push(filter);
				}
			} else {
				const filter = `exposureScores=${category}`;
				filtersArr.push(filter);
			}
		}

		this.machineGroupsFilterService.machineGroupsFilter$
			.pipe(take(1))
			.subscribe(machineGroupsFilterData => {
				if (machineGroupsFilterData && machineGroupsFilterData.isFiltering) {
					const groupIds: string = machineGroupsFilterData.machineGroups
						.filter(mg => mg.isSelected)
						.map(mg => mg.groupId)
						.join('|');
					filtersArr.push(`rbacGroupIds=${groupIds}`);
				}
				const filters = filtersArr.reduce(
					(prev, curr, idx) => `${prev}${prev ? ',' : ''}${curr}`,
					''
				);
				this.router.navigate(['/machines'], {
					queryParams: { filters: filters || null },
				});
			});
	}
}
