/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../forms/src/lib/components/range-slider.component.ngfactory";
import * as i2 from "../../../../../../forms/src/lib/components/range-slider.component";
import * as i3 from "./filter-values.range-slider.component";
var styles_FilterValuesRangeSliderComponent = [];
var RenderType_FilterValuesRangeSliderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterValuesRangeSliderComponent, data: {} });
export { RenderType_FilterValuesRangeSliderComponent as RenderType_FilterValuesRangeSliderComponent };
export function View_FilterValuesRangeSliderComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "wcd-range-slider", [], null, [[null, "onChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onChange" === en)) {
        var pd_0 = (_co.onChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_RangeSliderComponent_0, i1.RenderType_RangeSliderComponent)), i0.ɵdid(2, 4308992, null, 0, i2.RangeSliderComponent, [i0.ChangeDetectorRef], { max: [0, "max"], min: [1, "min"], defaultValue: [2, "defaultValue"], valueFormat: [3, "valueFormat"], value: [4, "value"] }, { onChange: "onChange" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.config == null) ? null : _co.config.max); var currVal_1 = ((_co.config == null) ? null : _co.config.min); var currVal_2 = (((_co.config == null) ? null : _co.config.initialValue) || _co.selectedValues); var currVal_3 = ((_co.config == null) ? null : _co.config.valueFormat); var currVal_4 = _co.selectedValues; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FilterValuesRangeSliderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-filter-values-slider", [], null, null, null, View_FilterValuesRangeSliderComponent_0, RenderType_FilterValuesRangeSliderComponent)), i0.ɵdid(1, 49152, null, 0, i3.FilterValuesRangeSliderComponent, [i0.ChangeDetectorRef, i0.NgZone], null, null)], null, null); }
var FilterValuesRangeSliderComponentNgFactory = i0.ɵccf("wcd-filter-values-slider", i3.FilterValuesRangeSliderComponent, View_FilterValuesRangeSliderComponent_Host_0, { data: "data", fieldId: "fieldId", config: "config", selectedValues: "selectedValues", fixedSelectedValues: "fixedSelectedValues" }, { filterValuesChange: "filterValuesChange" }, []);
export { FilterValuesRangeSliderComponentNgFactory as FilterValuesRangeSliderComponentNgFactory };
