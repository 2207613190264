/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./data-export-info-box.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i5 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i6 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i7 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i8 from "ngx-markdown";
import * as i9 from "./data-export-info-box.component";
var styles_DataExportSettingsInfoBoxComponent = [i0.styles];
var RenderType_DataExportSettingsInfoBoxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DataExportSettingsInfoBoxComponent, data: {} });
export { RenderType_DataExportSettingsInfoBoxComponent as RenderType_DataExportSettingsInfoBoxComponent };
export function View_DataExportSettingsInfoBoxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "color-box-gray-100 wcd-flex-horizontal wcd-padding-small-all wcd-flex-center-vertical data-export-info-box"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 5, "div", [["class", "wcd-flex-none wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "wcd-shared-icon", [["class", "small-icon"], ["iconName", "Warning"]], null, null, null, i4.View_IconComponent_0, i4.RenderType_IconComponent)), i1.ɵdid(7, 573440, null, 0, i5.IconComponent, [i6.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(11, 0, null, null, 3, "markdown", [["class", "wcd-flex-1"]], null, null, null, i7.View_MarkdownComponent_0, i7.RenderType_MarkdownComponent)), i1.ɵdid(12, 4767744, null, 0, i8.MarkdownComponent, [i1.ElementRef, i8.MarkdownService], null, null), (_l()(), i1.ɵted(13, 0, ["", ""])), i1.ɵppd(14, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var currVal_0 = "Warning"; _ck(_v, 7, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i1.ɵunv(_v, 13, 0, _ck(_v, 14, 0, i1.ɵnov(_v, 0), "endpointManagement.nonWindows.applications.dataExport.deprecationMessage")); _ck(_v, 13, 0, currVal_1); }); }
export function View_DataExportSettingsInfoBoxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "data-export-info-box", [], null, null, null, View_DataExportSettingsInfoBoxComponent_0, RenderType_DataExportSettingsInfoBoxComponent)), i1.ɵdid(1, 49152, null, 0, i9.DataExportSettingsInfoBoxComponent, [], null, null)], null, null); }
var DataExportSettingsInfoBoxComponentNgFactory = i1.ɵccf("data-export-info-box", i9.DataExportSettingsInfoBoxComponent, View_DataExportSettingsInfoBoxComponent_Host_0, {}, {}, []);
export { DataExportSettingsInfoBoxComponentNgFactory as DataExportSettingsInfoBoxComponentNgFactory };
