<section
	#overlay
	class="side-panel-overlay"
	(click)="overlayClicked($event)"
	[id]="settings.id"
	[attr.data-track-component]="settings.id + ' side panel'"
	data-track-component-type="Side Panel"
	[ngClass]="settings.className"
	[class.side-panel-overlay-disabled]="settings?.disableOverlayClick"
	[class.side-panel-static]="settings?.isStatic"
	[class.side-panel-visible]="isInit && isOpen !== false"
	[class.side-panel-nomodal]="settings?.isModal === false"
	[class.side-panel-disabled]="(settings && (settings.isBlocking === false || settings.isModal === false)) || isOpen === false"
	[class.no-padding]="settings?.noPadding"
	[class.transparent]="settings?.showOverlay === false || settings?.isModal === false"
	[attr.role]="settings?.role || 'dialog'"
	aria-modal="true"
>
	<div
		class="side-panel wcd-flex-vertical"
		[ngClass]="'side-panel-' + panelTypeName"
		[class.no-shadow]="settings?.noShadow"
		[ngStyle]="{ width: (settings.width && settings.width + 'px') || null }"
		[cdkTrapFocus]="isOpen && !disableTrapFocus"
	>
		<!--
			Added to prevent from NVDA screen reader to read the all dialog:
			https://microsoft.visualstudio.com/OS/_workitems/edit/25747166/
		-->
		<div
			[attr.tabindex]="!disableAutoFocus ? -1 : 0"
			[attr.role]="headerRole"
			[attr.aria-level]="headerAriaLevel"
		>
			<span #panelHiddenHeader hidden aria-hidden="true" id="side-panel-header-container-hidden">
				{{ 'sidePanel.defaultName' | i18n }}
			</span>

			<div class="side-panel-top-content">
				<ng-content select="[sidePanel-top-content]"></ng-content>
			</div>
		</div>
		<div
			class="side-panel-header side-panel-header-controls wcd-flex-none wcd-flex-horizontal"
			*ngIf="settings?.hasCloseButton !== false || settings?.back">
			<div class="wcd-flex-1">
				<button
					(click)="back()"
					data-track-id="PanelBack"
					data-track-type="Navigation"
					*ngIf="settings.back"
					[attr.aria-label]="'buttons.back' | i18n"
					[wcdTooltip]="settings.back.tooltip || ('buttons.back' | i18n)"
					class="side-panel-topbar-btn"
					#focusable>
					<wcd-shared-icon [iconName]="settings.back.iconName || 'back'"></wcd-shared-icon>
					{{ settings.back.text || '' }}
				</button>
			</div>
			<div class="wcd-flex-none side-panel-header-controls-right">
				<button
					(click)="previousClick()"
					*ngIf="settings.previous?.onClick"
					data-track-id="PanelUp"
					data-track-type="Navigation"
					[attr.aria-label]="'buttons.previous' | i18n"
					[wcdTooltip]="settings.previous.tooltip || ('buttons.previous' | i18n)"
					[class.disabled]="settings.previous.isDisabled"
					[disabled]="settings.previous.isDisabled"
				class="side-panel-topbar-btn"
					#focusable>
					<wcd-shared-icon [iconName]="settings.previous.iconName || 'arrow.up'">
					</wcd-shared-icon>
					{{ settings.previous.text || '' }}
				</button>
				<button
					(click)="nextClick()"
					*ngIf="settings.next?.onClick"
					data-track-id="PanelDown"
					data-track-type="Navigation"
					[attr.aria-label]="'buttons.next' | i18n"
					[wcdTooltip]="settings.next.tooltip || ('buttons.next' | i18n)"
					[class.disabled]="settings.next.isDisabled"
					[disabled]="settings.next.isDisabled"
				class="side-panel-topbar-btn"
					#focusable>
					<wcd-shared-icon [iconName]="settings.next.iconName || 'arrow.down'">
					</wcd-shared-icon>
					{{ settings.next.text || '' }}
				</button>
				<button
					(click)="closePanel()"
					*ngIf="settings.hasCloseButton !== false"
					[attr.aria-label]="'buttons.close' | i18n"
					[wcdTooltip]="'buttons.close' | i18n"
				class="side-panel-topbar-btn"
					#focusable>
					<wcd-shared-icon [iconName]="'clear'"></wcd-shared-icon>
				</button>
			</div>
		</div>
		<div class="wcd-responsive-panel-container">
			<div
				class="side-panel-header wcd-flex-none wcd-flex-horizontal"
				*ngIf="settings?.headerText">
				<div class="wcd-flex-none wcd-flex side-panel-header-icon" *ngIf="settings.headerIcon">
					<wcd-shared-icon [iconName]="settings.headerIcon"></wcd-shared-icon>
				</div>
				<div class="wcd-flex-1 side-panel-header-text">
					<header	class="wcd-flex-1">
							<h2 class="side-panel-title"
								[attr.id]="TITLE_ELEMENT_ID"
								[ngClass]="settings.headerClassName">
								{{ settings.headerText }}
							</h2>
					</header>
					<p *ngIf="settings.headerDescription" class="side-panel-description">
						{{ settings.headerDescription }}
					</p>
				</div>
				<div class="side-panel-header-controls">
					<wcd-dropdown
						*ngIf="selectableActions && selectableActions.length"
						[buttonIcon]="'menu.horizontal'"
						[closeOnClick]="true"
						[showIconDropdown]="false">
						<ul class="side-panel-header-actions-list with-links">
							<li *ngFor="let action of selectableActions">
								<a (click)="onMenuActionClick.emit(action)">
									<h5 class="action-title">
										<wcd-shared-icon
											*ngIf="action.iconName"
											[iconName]="action.iconName"
											class="dropdown-list-icon">
										</wcd-shared-icon>
										{{ action.title }}
									</h5>
									<p class="help-text with-icon" style="max-width: 300px;">
										{{ action.description }}
									</p>
								</a>
							</li>
						</ul>
					</wcd-dropdown>
				</div>
			</div>
			<div
				class="side-panel-body wcd-flex-1 wcd-margin-xsmall-bottom"
				[class.no-padding]="settings.noBodyPadding"
				[class.wcd-scroll-vertical]="settings.scrollBody !== false"
				[ngClass]="panelBodyClassName"
			>
				<ng-content></ng-content>
			</div>
		</div>
	</div>
</section>
