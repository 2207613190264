import { Machine } from "@wcd/domain";

export enum MemEnrollmentStatus {
	Unknown = 0,
	Enrolled = 1,
	NotEnrolled = 2,
	OtherAuthority_MDM = 3,
	OtherAuthority_SCCM = 4,
	EnrollmentChallengeFailure_MDE = 5,
	EnrollmentChallengeFailure_AAD = 6,
	EnrollmentRegistrationFailure_AAD = 7,
	EnrollmentRegistrationFailure_MEM = 8,
	EnrollmentRegistrationFailure_AADJ = 9,
	EnrollmentRegistrationFailure_HAAD = 10,
	EnrollmentRegistrationFailure_AAD_LABEL = 11,
	EnrollmentRegistrationFailure_UserLoginLimit = 12,
	EnrollmentRegistrationComFailure_MEM = 13,
	EnrollmentRegistrationComFailure_AAD = 14,
	EnrollmentRegistrationFailure_AAD_MismatchTenants = 15,
	EnrollmentRegistrationFailure_AAD_BAD_SCP = 16,
	EnrollmentRegistrationFailure_AAD_SCP_ENTERPRISE_DRS = 17,
	EnrollmentRegistrationFailure_AAD_DEVICE_CERT_WRONG_TENANT = 18,
	Checkin_Error = 19,
	Checkin_ComError = 20,
	OtherAuthority_MDM_Pending = 21,
	OtherAuthority_SCCM_Pending = 22,
	Unenrolled = 23,
	EnrollmentRegistrationComFailure_AAD_LABEL = 24,
	EnrollmentRegistrationComFailure_AAD_CHALLENGE = 25,
	EnrollmentRegistrationFailure_UserLoginLimit_UnjoinFailure = 26,
	EnrollmentChallengeFailure_Discovery_AAD = 27,
	EnrollmentChallengeFailure_Precreate_AAD = 28,
	EnrollmentRegistrationFailure_DeviceJoinedState_AAD = 29,
	EnrollmentRegistrationFailure_FinalRegistration_AAD = 30,
	EnrollmentRegistrationFailure_UnknownReason = 31,
	Enrolled_PendingUnenrollment = 32,
	EnrollmentRegistrationFailure_AAD_LABEL_UnjoinFailure = 33,
	Checkin_Error_Assignment = 34,
	Checkin_Error_Report = 35,
	EnrollmentRegistrationFailure_AAD_LDAP_ERROR = 36,
	EnrollmentRegistrationFailure_AAD_E_DEVICE_NOT_FOUND = 37,
	EnrollmentRegistrationFailure_AAD_ERROR_NO_SUCH_DOMAIN = 38,
	EnrollmentRegistrationFailure_AAD_LOG_MANIFEST_INSTALL_FAILED = 39,
	EnrollmentRegistrationFailure_AAD_TIME_SKEW_ERROR = 40,
	EnrollmentRegistrationFailure_AAD_NAME_NOT_RESOLVED = 41,
	EnrollmentRegistrationFailure_AADRT_OS_HYBRID_JOIN_FAILED = 42,
	EnrolledWithSccm = 43,
	EnrollmentRegistrationFailure_TenantNotOnboardedToMEM = 44,
}

// TODO: Remove machine and isSmbFlavor
export const shouldShowMdeRecommendationsAndNotifications = (
	memEnrollmentStatus?: MemEnrollmentStatus, machine?: Machine, isSmbFlavor?: boolean
): boolean => {
	switch (memEnrollmentStatus) {
		case MemEnrollmentStatus.Unknown:
		case MemEnrollmentStatus.Enrolled:
		case MemEnrollmentStatus.NotEnrolled:
		case MemEnrollmentStatus.OtherAuthority_MDM:
		case MemEnrollmentStatus.OtherAuthority_SCCM:
		case MemEnrollmentStatus.Checkin_Error:
		case MemEnrollmentStatus.OtherAuthority_MDM_Pending:
		case MemEnrollmentStatus.OtherAuthority_SCCM_Pending:
		case MemEnrollmentStatus.Unenrolled:
		case MemEnrollmentStatus.Checkin_Error_Assignment:
		case MemEnrollmentStatus.Checkin_Error_Report:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LOG_MANIFEST_INSTALL_FAILED:
		case MemEnrollmentStatus.EnrolledWithSccm:
			return false;
		case MemEnrollmentStatus.EnrollmentChallengeFailure_MDE:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_MEM:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AADJ:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_HAAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LABEL:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UserLoginLimit:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_MEM:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_MismatchTenants:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_BAD_SCP:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_SCP_ENTERPRISE_DRS:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_DEVICE_CERT_WRONG_TENANT:
		case MemEnrollmentStatus.Checkin_ComError:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD_LABEL:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD_CHALLENGE:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UserLoginLimit_UnjoinFailure:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_Discovery_AAD:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_Precreate_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_DeviceJoinedState_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_FinalRegistration_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UnknownReason:
		case MemEnrollmentStatus.Enrolled_PendingUnenrollment:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LABEL_UnjoinFailure:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LDAP_ERROR:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_E_DEVICE_NOT_FOUND:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_ERROR_NO_SUCH_DOMAIN:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_TIME_SKEW_ERROR:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_NAME_NOT_RESOLVED:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AADRT_OS_HYBRID_JOIN_FAILED:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_TenantNotOnboardedToMEM:
			return true;
		default:
			return true;
	}
};

export const MdeAttachErrorToShortMessageKey = (memEnrollmentStatus: MemEnrollmentStatus): string => {
	switch (memEnrollmentStatus) {
		case MemEnrollmentStatus.EnrollmentChallengeFailure_MDE:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AADJ:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LABEL:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UserLoginLimit:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UserLoginLimit_UnjoinFailure:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_Discovery_AAD:
		case MemEnrollmentStatus.EnrollmentChallengeFailure_Precreate_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_DeviceJoinedState_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_FinalRegistration_AAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_UnknownReason:
		case MemEnrollmentStatus.Enrolled_PendingUnenrollment:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LABEL_UnjoinFailure:
			return 'GeneralError';
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_MEM:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD:
		case MemEnrollmentStatus.Checkin_ComError:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD_LABEL:
		case MemEnrollmentStatus.EnrollmentRegistrationComFailure_AAD_CHALLENGE:
			return 'ConnectivityIssue';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_HAAD:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AADRT_OS_HYBRID_JOIN_FAILED:
			return 'GeneralHybridJoinFailure';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_BAD_SCP:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_SCP_ENTERPRISE_DRS:
			return 'HybridErrorServiceConnectionPoint';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_ERROR_NO_SUCH_DOMAIN:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_NAME_NOT_RESOLVED:
			return 'DnsError';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_MismatchTenants:
			return 'TenantMismatch';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_DEVICE_CERT_WRONG_TENANT:
			return 'CertificateError';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_TIME_SKEW_ERROR:
			return 'ClockSyncIssue';
		// TODO: Revert when ldap and on-premise return.
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LDAP_ERROR:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_E_DEVICE_NOT_FOUND:
			return 'AadConnectMisconfiguration';
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_MEM:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_TenantNotOnboardedToMEM:
			return 'MemConfigurationIssue';
		default:
			return 'GeneralError';
	}
};

export const getMdeAttachTitle = (machine: Machine): string => {
	const updatedSenseVersion = "10.8040";
	if (machine.memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
		const osName = machine.os.osPlatformString;
		if (osName.startsWith('Windows') && machine.senseClientVersion < updatedSenseVersion) {
			return 'MemEnrollment_Title_Note';
		}
		if (!osName.startsWith('Windows')) {
			return 'MemEnrollment_Title_Note';
		}
	}
	if (machine.memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || machine.memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
		return 'MemEnrollment_Title_Note';
	}
	return 'MemEnrollment_Title';
}

export const getMdeAttachErrorStringKey = (memEnrollmentStatus: MemEnrollmentStatus, osName?: string, senseClientVersion?: string): string => {
	const updatedSenseVersion = "10.8040";
	if (memEnrollmentStatus in MemEnrollmentStatus) {
		if (memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
			if (osName && osName.startsWith('Windows') && senseClientVersion < updatedSenseVersion) {
				 return 'MemEnrollmentStatus_UnknownWindows';
			}
			if (osName && !osName.startsWith('Windows')) {
				return 'MemEnrollmentStatus_Unknown_NotWindows';
			}
		}
		if (memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
			return 'MemEnrollmentStatus_NotEnrolled';
		}
		return `MemEnrollmentStatus_${MdeAttachErrorToShortMessageKey(memEnrollmentStatus)}`;
	}
	return 'MemEnrollmentStatus_GeneralError';
};

export const getManagedByStringKey = (memEnrollmentStatus: MemEnrollmentStatus, isEndpointConfigManagementMdeWithSccmFeatureEnabled: boolean): string => {
	switch (memEnrollmentStatus)
	{
		case MemEnrollmentStatus.Unknown:
		case MemEnrollmentStatus.NotEnrolled:
		case MemEnrollmentStatus.Unenrolled:
			return 'machineManagedBy_Other';
		case MemEnrollmentStatus.OtherAuthority_SCCM:
		case MemEnrollmentStatus.OtherAuthority_SCCM_Pending:
			return isEndpointConfigManagementMdeWithSccmFeatureEnabled ? 'machineManagedBy_Sccm' : 'machineManagedBy_Other';
		case MemEnrollmentStatus.OtherAuthority_MDM:
		case MemEnrollmentStatus.OtherAuthority_MDM_Pending:
			return 'machineManagedBy_Mdm'
		default:
			return 'machineManagedBy_Mde'
	}
}

export const getEnrollmentStatusStringKey = (memEnrollmentStatus: MemEnrollmentStatus): string => {
	switch (memEnrollmentStatus)
	{
		case MemEnrollmentStatus.Unknown:
		case MemEnrollmentStatus.NotEnrolled:
		case MemEnrollmentStatus.Unenrolled:
		case MemEnrollmentStatus.OtherAuthority_MDM:
		case MemEnrollmentStatus.OtherAuthority_MDM_Pending:
		case MemEnrollmentStatus.OtherAuthority_SCCM:
		case MemEnrollmentStatus.OtherAuthority_SCCM_Pending:
			return 'machineManagedByStatus_Unknown';
		case MemEnrollmentStatus.Enrolled:
		case MemEnrollmentStatus.Checkin_Error:
		case MemEnrollmentStatus.Checkin_Error_Assignment:
		case MemEnrollmentStatus.Checkin_Error_Report:
		case MemEnrollmentStatus.EnrollmentRegistrationFailure_AAD_LOG_MANIFEST_INSTALL_FAILED:
		case MemEnrollmentStatus.EnrolledWithSccm:
			return 'machineManagedByStatus_Success';
		default:
			return `machineManagedByStatus_${MdeAttachErrorToShortMessageKey(memEnrollmentStatus)}`;
	}
}

// Returns true in case we need to show "Note" messages in MDE-Attach section. Otherwise, returns false
export const shouldShowMdeNotes = (
	machine?: Machine, isMdeEnabled?: boolean
): boolean => {
	if (!isMdeEnabled) {
		return false;
	}
	const updatedSenseVersion = "10.8040";
	const memEnrollmentStatus = machine.memEnrollmentStatus;
	if (memEnrollmentStatus in MemEnrollmentStatus) {
		if (memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
			const osName = machine.os.osPlatformString;
			if (osName && osName.startsWith('Windows') && machine.senseClientVersion < updatedSenseVersion) {
				return true;
			}
			if (osName && !osName.startsWith('Windows')) {
				return true;
			}
		}
		if (memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
			return true;
		}
	}
	return false;
};
