import { ApiCall, ApiCallModel, ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
import { HuntingRuleCustomSupportedActionType } from '../hunting-rule-custom-action.entity';
import { HuntingQueryResultRecord } from '../hunting-query-result-record.value-object';
import { HuntingQueryMetadataRequest } from './hunting-query-supported-entities.api-call';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ValueObject({
	pluralName: 'Hunting entity identifiers',
	singularName: 'Hunting entity identifiers',
})
export class HuntingQueryEntityIdentifiers extends ModelBase {
	@EntityField({ data: 'EntityType' })
	entityType: HuntingRuleEntityType;

	@EntityField({ data: 'AllSingleIdentifiers' })
	idFields: string[];

	disabledIdFields?: string[];
}

@ValueObject({
	pluralName: 'Hunting supported actions',
	singularName: 'Hunting supported action',
})
export class HuntingQuerySupportedAction extends ModelBase {
	@EntityField({ data: 'ActionType' })
	actionType: HuntingRuleCustomSupportedActionType;

	@EntityField({ data: 'Entities', arrayOf: HuntingQueryEntityIdentifiers })
	entities: HuntingQueryEntityIdentifiers[];

	@EntityField({ data: 'IsEnabled', defaultValue: true })
	isEnabled: boolean;
}

@ApiCall({
	name: 'Get supported custom actions for hunting rule',
	endpoint: 'customactions/supported',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
	type: HuntingQuerySupportedAction,
})
export class HuntingQuerySupportedActionsApiCall extends ApiCallModel<
	Array<HuntingQuerySupportedAction>,
	Pick<HuntingQueryMetadataRequest, 'QueryText' | 'Id'>
> {}

@ApiCall({
	name: 'Get supported bulk actions for hunting result set',
	endpoint: 'customactions/bulkActions/supported',
	baseUrl: (config) => config.data.serviceUrls.huntingService,
	method: 'POST',
	type: HuntingQuerySupportedAction,
	parseQuery: (record: HuntingQueryResultRecord) => ({
		data: { ColumnNames: Object.keys(record.dataItem) },
	}),
})
export class HuntingSupportedBulkActionsApiCall extends ApiCallModel<
	Array<HuntingQuerySupportedAction>,
	HuntingQueryResultRecord
> {}

@ApiCall({
	name: 'Get supported bulk actions for hunting result set',
	endpoint: 'hunting/bulkActions/supported',
	baseUrl: (config) => config.data.serviceUrls.threatIntel,
	method: 'POST',
	type: HuntingQuerySupportedAction,
	parseQuery: (columnNames: string[]) => ({
		data: { ColumnNames: columnNames },
	}),
})
export class HuntingSupportedBulkActionsByColumnsApiCall extends ApiCallModel<
	HuntingQuerySupportedAction[],
	string[]
> {}

@ApiCall({
	name: 'Get supported bulk actions for hunting result set',
	endpoint: 'customactions/bulkActions/supported',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	method: 'POST',
	type: HuntingQuerySupportedAction,
	parseQuery: (columnNames: string[]) => ({
		data: { ColumnNames: columnNames },
	}),
})
export class HuntingSupportedBulkActionsByColumnsApiCallVNext extends ApiCallModel<
	HuntingQuerySupportedAction[],
	string[]
> {}
