import { getLocaleId } from '@angular/common';
import { compact } from 'lodash-es';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { getUserTimeZone } from '../locale.utils';
import { OnDestroy } from '@angular/core';
import { sccHostService } from '@wcd/scc-interface';
import * as i0 from "@angular/core";
var DEFAULT_TIMEZONE_OFFSET = 0, DEFAULT_LOCALE = 'en-US';
/**
 * Service for configuration which is local to the current browser.
 * Currently used for timezone setting.
 */
var LocaleConfigService = /** @class */ (function () {
    function LocaleConfigService() {
        this._selectedTimezone$ = new BehaviorSubject(DEFAULT_TIMEZONE_OFFSET);
        this.selectedTimezone$ = this._selectedTimezone$.asObservable();
        this._selectedLocale$ = new BehaviorSubject(this.getDefaultUserLocale());
        this.selectedLocale$ = this._selectedLocale$.asObservable();
        this.config$ = combineLatest([this.selectedTimezone$, this.selectedLocale$]).pipe(map(function (_a) {
            var _timeZone = _a[0], _locale = _a[1];
            return {
                timeZone: _timeZone,
                locale: _locale,
            };
        }));
    }
    LocaleConfigService.prototype.getUserLocales = function () {
        var locales = new Set([DEFAULT_LOCALE]);
        if (navigator.userLanguage) {
            locales.add(navigator.userLanguage);
        }
        if (navigator.languages && navigator.languages.length) {
            navigator.languages.forEach(function (lang) { return locales.add(lang); });
        }
        if (navigator.language) {
            locales.add(navigator.language);
        }
        return locales;
    };
    LocaleConfigService.prototype.getDefaultUserLocale = function () {
        return this.getSupportedLocales()[0];
    };
    LocaleConfigService.prototype.getSupportedLocales = function () {
        var userLocales = this.getUserLocales();
        // remove locales that Angular can't resolve
        var foundLocales = new Set();
        return compact(Array.from(userLocales).map(function (loc) {
            try {
                var localeId = getLocaleId(loc);
                if (localeId && !foundLocales.has(localeId)) {
                    foundLocales.add(localeId);
                    return loc;
                }
            }
            catch (e) { }
        }));
    };
    Object.defineProperty(LocaleConfigService.prototype, "isLocalTimeZone", {
        get: function () {
            return sccHostService.isSCC ?
                !sccHostService.i18n.alwaysUseUTC :
                this._selectedTimezone$.value === getUserTimeZone();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocaleConfigService.prototype, "selectedLocale", {
        get: function () {
            return this._selectedLocale$.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LocaleConfigService.prototype, "selectedTimezone", {
        get: function () {
            return this._selectedTimezone$.value;
        },
        enumerable: true,
        configurable: true
    });
    LocaleConfigService.prototype.setSelectedTimezone = function (selectedTimezone) {
        if (this._selectedTimezone$.value !== selectedTimezone) {
            this._selectedTimezone$.next(selectedTimezone);
        }
    };
    LocaleConfigService.prototype.setSelectedLocale = function (selectedLocale) {
        if (this._selectedLocale$.value !== selectedLocale) {
            this._selectedLocale$.next(selectedLocale);
        }
    };
    LocaleConfigService.prototype.ngOnDestroy = function () {
        this._selectedLocale$.complete();
        this._selectedTimezone$.complete();
    };
    LocaleConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LocaleConfigService_Factory() { return new LocaleConfigService(); }, token: LocaleConfigService, providedIn: "root" });
    return LocaleConfigService;
}());
export { LocaleConfigService };
