
		<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="AlertsDataviewPanel">
			<alerts-dataview
				*ngIf="alerts?.length"
				dataViewId="alerts-dataview-panel-view"
				[dataViewConfig]="{ data: alerts }"
				[responsiveLayout]="true"
				[showHeader]="false"
				[allowFilters]="false"
				[allowPaging]="false"
				[allowGrouping]="false"
				[defaultSortId]="defaultSortBy"
				[allowTimeRangeSelect]="false"
				[hideControls]="true"
				[disableSelection]="true"
				[onlyFields]="onlyFields"
				[fixedTable]="true"
				[sortDisabledFields]="['title', 'severity', 'status', 'lasteventtime']"
				[removePadding]="false"
			></alerts-dataview>
		</wcd-panel>
	