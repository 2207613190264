import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmEndPoint, DefaultApiVersion } from '../..';
import { ItsmTool, getEndpointForItsmTool } from '../remediation-task/remediation-task-ticket-itsm-tool.enum';
import { ServiceNowConfigDetails } from './servicenow-config-details.value-object';

@Entity({
	singularName: 'Itsm Provisioning',
	pluralName: 'Itsm Provisioning',
	endpoint: 'services',
	baseUrl: config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.ItsmProvisioning}`,
	saveMethod: 'PUT',
	customHeaders: { ...DefaultApiVersion, 'Cache-control': 'no-cache' },
	parseSaveQuery: (item: Partial<ItsmProvisioning>) =>
		`services/${getEndpointForItsmTool(item.itsmTool)}/configurations`,
	parseRemoveQuery: (items: ItsmProvisioning[]) =>
		`services/${getEndpointForItsmTool(items[0].itsmTool)}/configurations`,
})
export class ItsmProvisioning extends EntityModelBase<string> {
	//TODO: [elkamin]: remove when fixing contract in backend
	@EntityField({
		data: 'endpoint',
	})
	readonly endpoint: string;

	@EntityField({
		data: 'clientId',
	})
	readonly clientId: string;

	@EntityField({
		data: 'clientSecret',
	})
	readonly clientSecret: string;

	@EntityField({
		data: 'redirectToWhenCompleted',
	})
	readonly redirectToWhenCompleted: string;

	@EntityField({
		data: 'redirect',
	})
	readonly redirect: string;

	@EntityField({
		data: 'isProvisioned',
	})
	readonly isProvisioned: boolean;

	@EntityField({
		data: 'details',
	})
	readonly serviceNowDetails: ServiceNowConfigDetails;

	readonly itsmTool: ItsmTool;
}
