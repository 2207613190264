
		<ng-container *ngIf="entityType; else entityName">
			<wcd-shared-icon
				*ngIf="!hideIcon"
				[iconName]="
					iconName ||
					(entityType && (entityType.getIcon ? entityType.getIcon([entity]) : entityType.icon))
				"
				[iconClass]="iconClass"
				[ariaLabel]="displayAriaLabelOnIcon ? (iconName ||
					(entityType && (entityType.getIcon ? entityType.getIcon([entity]) : entityType.icon))) : null"
			></wcd-shared-icon>
			<ng-container *ngIf="!openEntityPanel; else entityPanel">
				<route-link
					*ngIf="entityRoute; else entityName"
					[trackId]="dataTrackId"
					[routeLink]="entityRoute"
					[className]="linkClass"
					[linkAriaLabel]="linkAriaLabel"
					[linkTabIndex]="linkTabIndex"
				>
					<ng-container *ngTemplateOutlet="entityName"></ng-container>
				</route-link>
			</ng-container>
		</ng-container>
		<ng-template #entityName>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="tooltipDisplay" >{{ entityDisplayName }}</span>
		</ng-template>
		<ng-template #entityPanel>
		<a
			(click)="onOpenEntityPanel()"
			(keydown)="onOpenEntityPanel()"
			[attr.aria-labelledby]="linkAriaLabel"
			[attr.tabindex]="linkTabIndex"
		>
			<span class="wcd-text-overflow-ellipsis" [wcdTooltip]="tooltipDisplay">{{
				entityDisplayName
			}}</span>
		</a>
		</ng-template>
	