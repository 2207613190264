import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirsPersistenceMethod } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'airs-persistence-method-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_general_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.methodType">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_methodType }}
				</dt>
				<dd role="none">{{ entity.methodType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.category">
				<dt role="none">{{ i18nService.strings.airsEntities_persistenceMethod_fields_category }}</dt>
				<dd role="none">{{ entity.category }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.commandLine">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_commandLine }}
				</dt>
				<dd role="none">{{ entity.commandLine }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.registryPath">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_registryPath }}
				</dt>
				<dd role="none">{{ entity.registryPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.registryValueName">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_registryValueName }}
				</dt>
				<dd role="none">{{ entity.registryValueName }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.registryClsidPath">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_registryClsidPath }}
				</dt>
				<dd role="none">{{ entity.registryClsidPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.lnkPath">
				<dt role="none">{{ i18nService.strings.airsEntities_persistenceMethod_fields_linkPath }}</dt>
				<dd role="none">{{ entity.lnkPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.lnkDescription">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_linkDescription }}
				</dt>
				<dd role="none">{{ entity.lnkDescription }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.iniPath">
				<dt role="none">{{ i18nService.strings.airsEntities_persistenceMethod_fields_iniFile }}</dt>
				<dd role="none">{{ entity.iniPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.iniSection">
				<dt role="none">
					{{ i18nService.strings.airsEntities_persistenceMethod_fields_iniSection }}
				</dt>
				<dd role="none">{{ entity.iniSection }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.iniKey">
				<dt role="none">{{ i18nService.strings.airsEntities_persistenceMethod_fields_iniKey }}</dt>
				<dd role="none">{{ entity.iniKey }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsPersistenceMethodDetailsComponent {
	@Input() entity: AirsPersistenceMethod;

	constructor(public i18nService: I18nService) {}
}
