var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Paris } from '@microsoft/paris';
import { LiveResponseScript } from '@wcd/domain';
import { defer, throwError } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { LiveResponseOutputParserService } from './live-response-output-parser.service';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../../../../../../projects/config/src/lib/services/features.service";
var LiveResponseScriptService = /** @class */ (function () {
    function LiveResponseScriptService(paris, featuresService) {
        var _this = this;
        this.paris = paris;
        this.featuresService = featuresService;
        this.cachedScripts = undefined;
        this.isTransitionFeatureEnabled = this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation);
        this.isCloudLiveResponseV3Enabled = this.featuresService.isEnabled(Feature.CloudLiveResponseV3);
        this.liveResponseScriptsRepo = this.paris.getRepository(LiveResponseScript);
        this.scripts$ = defer(function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        if (!!this.cachedScripts) return [3 /*break*/, 2];
                        _a = this;
                        return [4 /*yield*/, this.liveResponseScriptsRepo
                                .query({
                                where: {
                                    useV2Api: this.isTransitionFeatureEnabled,
                                    useV3Api: this.isCloudLiveResponseV3Enabled,
                                },
                            })
                                .pipe(take(1), map(function (ds) { return ds.items; }), catchError(function (err) { return throwError(LiveResponseOutputParserService.parseCommandError(err)); }))
                                .toPromise()];
                    case 1:
                        _a.cachedScripts = _b.sent();
                        _b.label = 2;
                    case 2: return [2 /*return*/, this.cachedScripts];
                }
            });
        }); });
    }
    LiveResponseScriptService.prototype.save = function (script, serializationData) {
        var _this = this;
        return defer(function () { return __awaiter(_this, void 0, void 0, function () {
            var httpOptions, _a, token, serviceUrls, overwriteExisting;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        httpOptions = {
                            params: {
                                useV2Api: this.isTransitionFeatureEnabled,
                                useV3Api: this.isCloudLiveResponseV3Enabled,
                            },
                        };
                        if (!(sccHostService.isSCC &&
                            this.featuresService.isEnabled(Feature.LiveResponseDirectFileUpload))) return [3 /*break*/, 2];
                        return [4 /*yield*/, Promise.all([
                                sccHostService.auth.getToken(),
                                sccHostService.ajax.get('<mtp>/k8s/mgmt/ServicesUrls').then(function (r) { return r.data.ServiceUrls; }),
                            ])];
                    case 1:
                        _a = _b.sent(), token = _a[0], serviceUrls = _a[1];
                        if (!(token && serviceUrls && serviceUrls.automatedIr)) {
                            throw new Error('An unexpected error has occurred');
                        }
                        httpOptions = {
                            customHeaders: {
                                authorization: "Bearer " + token,
                            },
                            params: {
                                automatedIrBaseUrl: serviceUrls.automatedIr,
                                useV2Api: this.isTransitionFeatureEnabled,
                                useV3Api: this.isCloudLiveResponseV3Enabled,
                            },
                        };
                        _b.label = 2;
                    case 2:
                        overwriteExisting = serializationData && serializationData.overwriteExisting;
                        return [2 /*return*/, this.liveResponseScriptsRepo
                                .save(script, httpOptions, {
                                overwriteExisting: overwriteExisting,
                            })
                                .pipe(take(1), tap(function (s) { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: 
                                        // make sure the value is populated
                                        return [4 /*yield*/, this.scripts$.toPromise()];
                                        case 1:
                                            // make sure the value is populated
                                            _a.sent();
                                            // Need to remove overwritten files from cache to avoid duplicate entries upon subsequent `library` commands
                                            if (overwriteExisting) {
                                                this.cachedScripts = this.cachedScripts.filter(function (f) { return f.fileName !== s.fileName; });
                                            }
                                            this.cachedScripts = (this.cachedScripts || []).concat([s]);
                                            return [2 /*return*/];
                                    }
                                });
                            }); }), catchError(function (err) { return throwError(LiveResponseOutputParserService.parseCommandError(err)); }))
                                .toPromise()];
                }
            });
        }); });
    };
    LiveResponseScriptService.prototype.removeItem = function (script) {
        var _this = this;
        script.useV3Api = this.isCloudLiveResponseV3Enabled;
        return this.liveResponseScriptsRepo
            .removeItem(script, {
            params: {
                useV2Api: this.isTransitionFeatureEnabled,
                useV3Api: this.isCloudLiveResponseV3Enabled,
            },
        })
            .pipe(tap(function () { return __awaiter(_this, void 0, void 0, function () {
            var itemIndex;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: 
                    // make sure the value is populated
                    return [4 /*yield*/, this.scripts$.toPromise()];
                    case 1:
                        // make sure the value is populated
                        _a.sent();
                        itemIndex = this.cachedScripts.findIndex(function (item) { return item.fileName === script.fileName; });
                        if (~itemIndex) {
                            this.cachedScripts.splice(itemIndex, 1);
                        }
                        return [2 /*return*/];
                }
            });
        }); }));
    };
    LiveResponseScriptService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LiveResponseScriptService_Factory() { return new LiveResponseScriptService(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.FeaturesService)); }, token: LiveResponseScriptService, providedIn: "root" });
    return LiveResponseScriptService;
}());
export { LiveResponseScriptService };
