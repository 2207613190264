var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
export var KnownRequestSource;
(function (KnownRequestSource) {
    KnownRequestSource[KnownRequestSource["Unspecified"] = 0] = "Unspecified";
    KnownRequestSource[KnownRequestSource["PublicApi"] = 1] = "PublicApi";
    KnownRequestSource[KnownRequestSource["Portal"] = 2] = "Portal";
    KnownRequestSource[KnownRequestSource["MCAS"] = 3] = "MCAS";
    KnownRequestSource[KnownRequestSource["TVM"] = 4] = "TVM";
    KnownRequestSource[KnownRequestSource["CustomDetection"] = 5] = "CustomDetection";
    KnownRequestSource[KnownRequestSource["BulkAction"] = 6] = "BulkAction";
    KnownRequestSource[KnownRequestSource["ISG"] = 7] = "ISG";
    KnownRequestSource[KnownRequestSource["NDR"] = 8] = "NDR";
})(KnownRequestSource || (KnownRequestSource = {}));
var ɵ0 = KnownRequestSource.Unspecified, ɵ1 = KnownRequestSource.PublicApi, ɵ2 = KnownRequestSource.Portal, ɵ3 = KnownRequestSource.MCAS, ɵ4 = KnownRequestSource.TVM, ɵ5 = KnownRequestSource.CustomDetection, ɵ6 = KnownRequestSource.BulkAction, ɵ7 = KnownRequestSource.ISG, ɵ8 = KnownRequestSource.NDR;
var IndicatorsCreateBySourceType = /** @class */ (function (_super) {
    __extends(IndicatorsCreateBySourceType, _super);
    function IndicatorsCreateBySourceType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], IndicatorsCreateBySourceType.prototype, "name", void 0);
    IndicatorsCreateBySourceType = __decorate([
        Entity({
            singularName: 'Indicators created by source type',
            pluralName: 'Indicators created by source types',
            values: [
                {
                    id: ɵ0,
                    name: 'Unspecified',
                },
                {
                    id: ɵ1,
                    name: 'PublicApi',
                },
                {
                    id: ɵ2,
                    name: 'Portal',
                },
                {
                    id: ɵ3,
                    name: 'MCAS',
                },
                {
                    id: ɵ4,
                    name: 'TVM',
                },
                {
                    id: ɵ5,
                    name: 'CustomDetection',
                },
                {
                    id: ɵ6,
                    name: 'BulkAction',
                },
                {
                    id: ɵ7,
                    name: 'ISG',
                },
                {
                    id: ɵ8,
                    name: 'NDR',
                },
            ],
        })
    ], IndicatorsCreateBySourceType);
    return IndicatorsCreateBySourceType;
}(EntityModelBase));
export { IndicatorsCreateBySourceType };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
