import { EntityField, ParisConfig, ModelBase, ValueObject } from '@microsoft/paris';
import { AlertIocContextBase } from './alert-ioc-context-base.interface';

@ValueObject({
	singularName: 'Alert File Context Item',
	pluralName: 'Alert File Context Items',
})
export class AlertFileContext extends ModelBase implements AlertIocContextBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'FileName' })
	name: string;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({
		data: 'Sha1',
		parse: data => (data && data.toLowerCase() !== 'null' ? data : null),
	})
	sha1: string;

	@EntityField({
		data: 'Sha256',
		parse: data => (data && data.toLowerCase() !== 'null' ? data : null),
	})
	sha256: string;

	@EntityField({
		data: '__self',
		parse: context => {
			const fileName: string = context.FileName;
			let filePath: string = context.FilePath;
			if (fileName != null && filePath != null) {
				if (filePath.indexOf(fileName) > -1) {
					filePath = filePath.substring(0, filePath.length - fileName.length);
					(filePath.charAt(filePath.length - 1) === '\\' || filePath.charAt(filePath.length - 1) === '/') ?
						filePath = filePath.substring(0,filePath.length-1) + '/' :
						filePath = filePath + '/';
				}
			}
			return filePath;
		},
	})
	path: string;

	@EntityField({ data: 'CommandLine' })
	command: string;
}
