import { Entity, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import {
	WebContentFilteringDevice,
	WebContentFilteringDomain,
	WebContentFilteringAccessTrend,
} from './category-report.value-object';
import { WebContentFilteringReportBase } from './report.model';
import {
	WebContentFilteringCategoryTypes,
	WebContentFilteringParentCategoryTypes,
} from '../../web-content-filtering/web-content-filtering-web-category-type';

@Entity({
	singularName: 'Category report',
	pluralName: 'Category reports',
	endpoint: 'WebContentFiltering/Reports/CategoryReports',
	readonly: true,
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
	allItemsEndpointTrailingSlash: false,
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class WebContentFilteringCategoryReport extends WebContentFilteringReportBase {
	@EntityField({ data: 'ParentCategory' })
	parentCategory: WebContentFilteringParentCategoryTypes;

	@EntityField({ data: 'CategoryId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: WebContentFilteringCategoryTypes;

	@EntityField({ data: 'TotalDevices' })
	totalDevices: number;

	@EntityField({ data: 'TotalDomains' })
	totalDomains: number;

	@EntityField({ data: 'TopDevices', arrayOf: WebContentFilteringDevice })
	topDevices: Array<WebContentFilteringDevice>;

	@EntityField({ data: 'TopDomains', arrayOf: WebContentFilteringDomain })
	topDomains: Array<WebContentFilteringDomain>;

	@EntityField({ data: 'AccessTrends', arrayOf: WebContentFilteringAccessTrend })
	accessTrends: Array<WebContentFilteringAccessTrend>;
}
