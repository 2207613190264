import { isNil } from 'lodash-es';
var AadUserInvestigationPriorityComponent = /** @class */ (function () {
    function AadUserInvestigationPriorityComponent() {
        this.percentile = 0;
        this.showTooltip = false;
    }
    Object.defineProperty(AadUserInvestigationPriorityComponent.prototype, "priorityText", {
        get: function () {
            return !isNil(this.priority) ? String(this.priority) : null;
        },
        enumerable: true,
        configurable: true
    });
    return AadUserInvestigationPriorityComponent;
}());
export { AadUserInvestigationPriorityComponent };
