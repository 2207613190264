import { Entity } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';
import { BaselineDeviceBase } from './baseline-device.entity.base';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Device',
	pluralName: 'Devices',
	endpoint: 'baseline/topFailingDevices',
	readonly: true,
})
export class BaselineProfileDevice extends BaselineDeviceBase {}
