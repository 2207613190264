export enum ThreatReportType {
	AttackCampaign = 'AttackCampaign',
	ToolOrTechnique = 'ToolOrTechnique',
	ActivityGroup = 'ActivityGroup',
	Vulnerability = 'Vulnerability',
	AttackSurface = 'AttackSurface',
}

export const getReportTypeI18nKey = (reportType: ThreatReportType) =>
	'dashboard_threatAnalytics_reportType_' + reportType
