var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, ChangeDetectorRef, ComponentFactoryResolver, ComponentRef, ElementRef, Injector, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { combineLatest, merge, Observable, ReplaySubject, Subject, Subscription, } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { CommandBarItemService } from '../../shared/components/command-bar/command-bar-item.service';
import { toObservable } from '../../utils/rxjs/utils';
import { EntityViewType } from '../models/entity-view-type.enum';
import { EntityPageViewMode } from '../models/entity-page-view-mode.enum';
import { ActivatedEntity } from '../services/activated-entity.service';
import { GlobalEntityTypesService } from '../services/global-entity-types.service';
import { FabricIconNames } from '@wcd/scc-common';
import { MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { config } from '@wcd/shared';
import { Panel, PanelType } from '@wcd/panels';
import { sccHostService } from '@wcd/scc-interface';
var buttonSize = 48;
// Styles used for AngularReact component.
// Need to be an object (unlike object literal where it's needed), as the change
// detection between Angular and React is a bit quirky and causes the page to stuck.
var commandBarStyles = {
    root: {
        backgroundColor: 'transparent !important',
        alignItems: 'flex-end',
        flexDirection: 'column',
    },
    rootHovered: {
        backgroundColor: '#EDEBE9 !important',
    },
    rootDisabled: {
        pointerEvents: 'bounding-box',
        backgroundColor: 'transparent',
    },
};
var assetCommandBarStyles = {
    root: {
        padding: 0,
        minWidth: buttonSize,
        height: buttonSize,
        backgroundColor: 'transparent',
        position: 'relative',
        right: '10px',
    },
    primarySet: {
        justifyContent: 'flex-end',
        paddingRight: '30px',
    },
};
var EntityComponent = /** @class */ (function () {
    function EntityComponent(i18nService, globalEntityTypesService, resolver, route, router, activatedEntity, paris, commandBarItemService, authService, changeDetectorRef, mainAppStateService) {
        var _this = this;
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.resolver = resolver;
        this.route = route;
        this.router = router;
        this.activatedEntity = activatedEntity;
        this.paris = paris;
        this.commandBarItemService = commandBarItemService;
        this.authService = authService;
        this.changeDetectorRef = changeDetectorRef;
        this.mainAppStateService = mainAppStateService;
        this.modernEntityPage = false;
        this.commandBarStyles = null;
        this.hideEntitySummaryPanelLink = false;
        this.EntityPageViewMode = EntityPageViewMode;
        this.entityPageViewMode = EntityPageViewMode.Default;
        this._tagsSubject$ = new Subject();
        this.afterViewInit$ = new ReplaySubject(1);
        this.tagEditOpen = false;
        this.openSummaryPanelNarrowScreen = false;
        this.isNarrowLayout = false;
        this.detailsPanelSettings = DETAILS_PANEL_SETTINGS;
        this.isScc = false;
        this.entityUpdateListener = function (e) {
            var _a = e.detail, newEntityId = _a.newEntityId, innerRoute = _a.innerRoute, params = _a.params;
            if (newEntityId !== _this.entity.id || innerRoute) { // If the entity changed or if the entity haven't changed but the inner route changed (tab for example)
                var entityTypePath = _this.route.parent.routeConfig.path;
                var commands = ['/' + entityTypePath, newEntityId, e.detail.entityData ? e.detail.entityData : {}];
                if (innerRoute)
                    commands.push(innerRoute);
                _this.router.navigate(commands, {
                    queryParams: params,
                    queryParamsHandling: "merge",
                });
            }
        };
        this.showSummaryPanel = function () { return !_this.modernEntityPage &&
            (!_this.isNarrowLayout || _this.openSummaryPanelNarrowScreen); };
        this.closeSummaryPaneButtonStyles = {
            root: {
                minWidth: buttonSize,
                height: buttonSize,
                backgroundColor: 'transparent',
                float: 'right'
            },
        };
        this.entityIconProps = {
            iconName: FabricIconNames.ChromeClose
        };
        mainAppStateService.state$.subscribe(function (mainAppState) {
            _this.mainAppState = mainAppState;
        });
        this.isScc = sccHostService.isSCC;
    }
    EntityComponent.prototype.ngOnInit = function () {
        var _this = this;
        this._fullScreenSubscription = this.activatedEntity.fullScreenEntity$.subscribe(function (isFullScreen) { return _this.toggleFullScreen(isFullScreen); });
        this._routeDataSubscription = this.route.data.subscribe(function (_a) {
            var entity = _a.entity, options = _a.options, hideEntitySummaryPanelLink = _a.hideEntitySummaryPanelLink;
            _this.entityType = _this.globalEntityTypesService.getEntityType(entity.constructor);
            _this.entityTypeName = _this.entityType.entity.entityConfig.singularName;
            _this.options = options;
            _this.isFullScreen = false;
            _this.entityPageViewMode = _this.getEntityPageViewMode();
            _this.setEntity(entity);
            _this.activatedEntity.setCurrentEntity(entity);
            _this.maxNameLength = _this.entityType.maxNameLength || 50;
            _this.isRenameEnabled = _this.entityType.getEnableRename
                ? _this.entityType.getEnableRename(_this.entity)
                : true;
            _this.hideEntitySummaryPanelLink = hideEntitySummaryPanelLink;
            _this.setupEntityUpdateListener();
        });
        this.mainAppStateService.state$
            .pipe(filter(function (state) {
            return (_this.isNarrowLayout !== config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.xl));
        }))
            .subscribe(function (state) {
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.xl);
            _this.openSummaryPanelNarrowScreen = false;
            _this.changeDetectorRef.detectChanges();
        });
    };
    EntityComponent.prototype.setupEntityUpdateListener = function () {
        if (this.entityType && this.entityType.id) {
            this.clearEntityUpdateListener();
            document.addEventListener(this.entityType.id + "EntityUpdated", this.entityUpdateListener);
        }
    };
    EntityComponent.prototype.clearEntityUpdateListener = function () {
        if (this.entityType && this.entityType.id) {
            document.removeEventListener(this.entityType.id + "EntityUpdated", this.entityUpdateListener);
        }
    };
    EntityComponent.prototype.toggleSummaryPanelInNarrowScreen = function (shouldOpen) {
        this.openSummaryPanelNarrowScreen = shouldOpen;
    };
    EntityComponent.prototype.ngOnDestroy = function () {
        if (this._routeDataSubscription) {
            this._routeDataSubscription.unsubscribe();
        }
        if (this._fullScreenSubscription) {
            this._fullScreenSubscription.unsubscribe();
        }
        this.clearEntityUpdateListener();
        this.activatedEntity.setCurrentEntity(null);
        this.afterViewInit$.complete();
        this._tagsSubject$.complete();
    };
    EntityComponent.prototype.ngAfterViewInit = function () {
        this.afterViewInit$.next();
    };
    EntityComponent.prototype.setEntity = function (entity) {
        this.entity = entity;
        // modernEntityPage is required for setting the action items (in "setEntityDetails"), so it should be set prior to it
        this.modernEntityPage = this.entityType.entityPageViewMode === EntityPageViewMode.Modern;
        this.assetPage = this.entityType.entityPageViewMode === EntityPageViewMode.Asset;
        this.setStyleSettings();
        this.setEntityDetails();
        this.setActions();
        this.setDynamicComponents();
    };
    EntityComponent.prototype.setStyleSettings = function () {
        if (this.modernEntityPage) {
            this.commandBarStyles = commandBarStyles;
        }
        if (this.assetPage) {
            this.commandBarStyles = assetCommandBarStyles;
        }
    };
    EntityComponent.prototype.setEntityDetails = function () {
        if (this.entityType.getTags) {
            this.tags$ = merge(toObservable(this.entityType.getTags([this.entity])), this._tagsSubject$).pipe(shareReplay(1));
        }
    };
    EntityComponent.prototype.setActions = function () {
        var _this = this;
        this.itemActions$ = toObservable(this.entityType.getActions
            ? this.entityType.getActions([this.entity], this.options, EntityViewType.EntityPage)
            : []);
        this.commandBarItems$ = combineLatest([this.itemActions$, this.afterViewInit$]).pipe(map(function (_a) {
            var itemActions = _a[0], _ = _a[1];
            return itemActions.map(function (itemAction) {
                return _this.actionToCommandBarItem(__assign({}, itemAction, { tooltip: itemAction.tooltip || itemAction.name || _this.i18nService.get(itemAction.nameKey) }));
            });
        }));
    };
    EntityComponent.prototype.actionToCommandBarItem = function (itemAction) {
        var _this = this;
        if ((this.modernEntityPage || this.assetPage) && !itemAction.styles) {
            itemAction.styles = commandBarStyles;
        }
        return this.commandBarItemService.fromItemActionModel({
            itemActionModel: itemAction,
            tagsTemplateRef: this.tagsItemTemplateRef,
            onClick: function (itemActionModel) {
                itemActionModel.method([_this.entity]).then(function () {
                    if (itemActionModel.refreshOnResolve) {
                        var params$ = toObservable(_this.entityType && _this.entityType.getItemParams
                            ? _this.entityType.getItemParams(_this.entity)
                            : null);
                        params$
                            .pipe(switchMap(function (params) {
                            return _this.paris.getItemById(_this.entityType.entity, _this.entity.id, null, params);
                        }))
                            .subscribe(function (entity) {
                            _this.setEntity(entity);
                            _this.activatedEntity.setCurrentEntity(entity);
                        });
                    }
                    _this.changeDetectorRef.detectChanges();
                });
            },
        });
    };
    EntityComponent.prototype.setDynamicComponents = function () {
        this.setEntityContentsComponent();
    };
    EntityComponent.prototype.setEntityContentsComponent = function () {
        var _this = this;
        var injector = Injector.create({
            providers: [],
            parent: this.entityContentsPlaceholder.parentInjector,
        });
        var factory = this.resolver.resolveComponentFactory(this.entityType.entityContentsComponentType);
        if (this.entityContentsPlaceholder.length > 0) {
            this.entityContentsPlaceholder.clear();
        }
        this.contentsComponentRef = this.entityContentsPlaceholder.createComponent(factory, this.entityContentsPlaceholder.length, injector);
        this.contentsComponentRef.instance.entityPageViewMode = this.entityPageViewMode;
        Object.assign(this.contentsComponentRef.instance, {
            entity: this.entity,
            isFullScreen: this.isFullScreen,
            entityPageViewMode: this.entityPageViewMode,
        });
        // run action requested from the entity component
        combineLatest([this.contentsComponentRef.instance.runAction$.asObservable(), this.itemActions$])
            .pipe(map(function (_a) {
            var event = _a[0], itemActions = _a[1];
            return ({
                action: itemActions.find(function (action) { return action.id === event.actionId; }),
                event: event,
            });
        }), filter(function (_a) {
            var action = _a.action;
            return Boolean(action);
        }))
            .subscribe(function (_a) {
            var event = _a.event, action = _a.action;
            return action.method([_this.entity], event.data);
        });
    };
    EntityComponent.prototype.setTags = function (tags, setTagsCallback) {
        this._tagsSubject$.next(tags);
        setTagsCallback(tags);
    };
    EntityComponent.prototype.tagsEditorToggled = function (open) {
        this.tagEditOpen = open;
    };
    EntityComponent.prototype.fireResizeEvent = function () {
        setTimeout(function () {
            window.dispatchEvent(new Event('resize'));
        }, 100);
    };
    EntityComponent.prototype.toggleFullScreen = function (isFullScreen) {
        this.isFullScreen = isFullScreen;
        this.changeDetectorRef.markForCheck();
        this.fireResizeEvent();
    };
    EntityComponent.prototype.getEntityPageViewMode = function () {
        return this.entityType.entityPageViewMode === EntityPageViewMode.Modern ||
            this.entityType.entityPageViewMode === EntityPageViewMode.Asset
            ? this.entityType.entityPageViewMode
            : EntityPageViewMode.Default;
    };
    return EntityComponent;
}());
export { EntityComponent };
export var DETAILS_PANEL_SETTINGS = new Panel({
    type: PanelType.large,
    showOverlay: false,
    isBlocking: false,
    isModal: true,
    isStatic: false,
    hasCloseButton: true,
});
