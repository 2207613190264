var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ThreatProtectionDetectionSourceWidget } from './threat-protection-detection-source.widget';
var ThreatProtectionDetectionSourceDashboardWidget = /** @class */ (function (_super) {
    __extends(ThreatProtectionDetectionSourceDashboardWidget, _super);
    function ThreatProtectionDetectionSourceDashboardWidget() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // this override const height of 300px of parent BaseReportWidgetComponent widget config
    ThreatProtectionDetectionSourceDashboardWidget.prototype.getHeight = function () {
        return undefined;
    };
    return ThreatProtectionDetectionSourceDashboardWidget;
}(ThreatProtectionDetectionSourceWidget));
export { ThreatProtectionDetectionSourceDashboardWidget };
