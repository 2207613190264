import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { SampleRequestInfo } from './file.sample-request-info.value-object';
import { SampleDisabledReason } from './file.sample-disabled-reason.enum';
import { FileActionType } from './file.action-type.enum';

@ValueObject({
	singularName: 'Sample action state response',
	pluralName: '',
	readonly: true,
})
export class SampleActionStateResponse extends ModelBase {
	@EntityField({ data: 'ActionType' })
	actionType: FileActionType;

	@EntityField({ data: 'IsEnabled' })
	isEnabled: boolean;

	@EntityField({ data: 'DisabledReason' })
	sampleDisabledReason: SampleDisabledReason;

	@EntityField({ data: 'SampleRequestHistory', arrayOf: SampleRequestInfo })
	sampleRequestHistory: Array<SampleRequestInfo>;
}
