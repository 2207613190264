// tslint:disable:template-click-events-have-key-events
import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { DomainUrl } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DomainUrlFields } from '../services/domain-urls.fields';
import { DomainsService } from '../services/domains.service';
import { of, Observable } from 'rxjs';
import { TimeRangeValue, isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '../../../utils/lazy';
import { PreferencesService } from '@wcd/config';
import {
	TIME_RANGE_DEFAULT_PREFERENCE_ID,
	WEB_THREAT_CATEGORY_FILTERS_ID,
	DEFAULT_WEB_THREAT_CATEGORY_FILTER,
	DOMAIN_TIME_RANGES,
	DEFAULT_DOMAIN_TIME_RANGE,
	DEFAULT_WEB_THREAT_STATUS_FILTER,
	DEFAULT_WEB_THREAT_SEVERITY_FILTER,
} from './domains.dataview';

@Component({
	selector: 'domain-urls',
	providers: [DomainUrlFields],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dataview
			id="domain-urls"
			className="no-left-padding wcd-flex-1"
			[fields]="domainUrlFields.fields"
			[entityType]="entityType"
			[repository]="domainRepository"
			[allowFilters]="false"
			[hideControls]="hideControls"
			[navigateOnChange]="navigateOnChange"
			[allowPaging]="allowPaging"
			[disableSelection]="disableSelection"
			[hideCommandBarOnEmptyData]="hideCommandBarOnEmptyData"
			[fixedOptions]="dataviewFixedOptions"
			[searchEnabled]="searchEnabled"
			[searchRequestText]="'Search in urls'"
			[defaultSortFieldId]="defaultSortFieldId"
			[allowColumnCustomization]="false"
			[label]="'reporting.webThreatReport.domainList.urlTitle' | i18n"
		>
		</dataview>
		<a
			*ngIf="displayViewAllLink && !fullUrlList"
			(click)="showAllDomains()"
			class="color-text-gray-900 wcd-margin-left"
		>
			{{ 'reporting.webThreatReport.domainList.viewAllLink' | i18n }}
			<fab-icon iconName="CaretRight"></fab-icon>
		</a>
	`,
})
export class DomainUrlsComponent implements OnInit {
	@Input() hideControls: boolean = true;
	@Input() allowPaging: boolean = true;
	@Input() navigateOnChange: boolean = true;
	@Input() disableSelection: boolean = true;
	@Input() hideCommandBarOnEmptyData: boolean = true;
	@Input() fixedOptions: { [index: string]: any };
	@Input() domainId: string;
	@Input() fullUrlList: boolean = false;
	@Input() searchEnabled: boolean = false;
	@Input() defaultSortFieldId: string;
	@Input() lookBackInDays: number;

	entityType: EntityType<DomainUrl>;
	domainRepository: Repository<DomainUrl>;
	dataviewFixedOptions: { [index: string]: any };
	displayViewAllLink: Observable<boolean>;
	currentTimeRange: TimeRangeValue;
	currentlySelectedWebThreatTypeFilters: string;
	currentlySelectedStatusFilters: string;
	currentlySelectedSeverityFilters: string;

	private readonly _timeRanges = new Lazy(() => {
		return this.timeRangesService.pick(DOMAIN_TIME_RANGES);
	});

	get timeRanges() {
		return this._timeRanges.value;
	}

	constructor(
		private paris: Paris,
		public domainUrlFields: DomainUrlFields,
		private domainsService: DomainsService,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly timeRangesService: TimeRangesService,
		private preferencesService: PreferencesService
	) {
		if (!this.domainRepository) this.domainRepository = paris.getRepository(DomainUrl);
		this.entityType = globalEntityTypesService.getEntityType(DomainUrl);
	}

	ngOnInit() {
		if (!this.domainRepository) this.domainRepository = this.paris.getRepository(DomainUrl);

		this.currentTimeRange =
			this.timeRanges.find(
				range => range.id === this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID)
			) || this.timeRanges[2];

		const filtersPreferences = this.preferencesService.getPreference(WEB_THREAT_CATEGORY_FILTERS_ID);
		if (filtersPreferences) {
			this.currentlySelectedWebThreatTypeFilters = filtersPreferences.webThreatCategory
				? filtersPreferences.webThreatCategory
				: DEFAULT_WEB_THREAT_CATEGORY_FILTER;

			this.currentlySelectedStatusFilters = filtersPreferences.status
				? filtersPreferences.status
				: DEFAULT_WEB_THREAT_STATUS_FILTER;

			this.currentlySelectedSeverityFilters = filtersPreferences.severity
				? filtersPreferences.severity
				: DEFAULT_WEB_THREAT_SEVERITY_FILTER;
		} else {
			this.currentlySelectedWebThreatTypeFilters = DEFAULT_WEB_THREAT_CATEGORY_FILTER;
			this.currentlySelectedStatusFilters = DEFAULT_WEB_THREAT_STATUS_FILTER;
			this.currentlySelectedSeverityFilters = DEFAULT_WEB_THREAT_SEVERITY_FILTER;
		}

		this.setFixedOptions();
	}

	private setFixedOptions() {
		this.dataviewFixedOptions = Object.assign(
			{
				id: this.domainId,
				fullList: this.fullUrlList,
				lookBackInDays: !isCustomTimeRangeValue(this.currentTimeRange)
					? this.currentTimeRange.value
					: DEFAULT_DOMAIN_TIME_RANGE,
				webThreatCategory: this.currentlySelectedWebThreatTypeFilters,
				status: this.currentlySelectedStatusFilters,
				severity: this.currentlySelectedSeverityFilters,
			},
			this.fixedOptions
		);

		this.domainRepository
			.query(
				{
					where: {
						id: this.domainId,
						fullList: this.fullUrlList,
						lookBackInDays: !isCustomTimeRangeValue(this.currentTimeRange)
							? this.currentTimeRange.value
							: DEFAULT_DOMAIN_TIME_RANGE,
						webThreatCategory: this.currentlySelectedWebThreatTypeFilters,
						status: this.currentlySelectedStatusFilters,
						severity: this.currentlySelectedSeverityFilters,
					},
				},
				{ allowCache: true }
			)
			.subscribe(val => {
				if (val != null) {
					if (val.count !== 0) {
						this.displayViewAllLink = of(true);
					}
				}
			});
	}

	showAllDomains() {
		this.domainsService.showAllDomains(this.domainId);
	}
}
