import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'manage-linux-onboarding',
	templateUrl: './manage-linux-onboarding.component.html',
})

export class ManageLinuxOnboardingComponent {
	@Input() isServer = false;
}
