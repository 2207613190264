import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('behaviorType', behaviorTypeFilter);

function behaviorTypeFilter() {
	return function(input, detectionRelatedBehavior) {
		if (!input && !detectionRelatedBehavior) {
			return '';
		}

		if (detectionRelatedBehavior) {
			return 'Bug';
		}

		switch (input) {
			case 'FileCreateBehaviors':
				return 'PageAdd';
			case 'NetworkConnectionBehaviors':
				return 'Streaming';
			case 'ProcessCreateBehaviors':
				return 'Settings';
			default:
				return 'Unknown';
		}
	};
}
