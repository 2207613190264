import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[validateHour][ngModel],[validateHour][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => HourValidator), multi: true }],
})
export class HourValidator {
	validate(c: FormControl) {
		const value = String(c.value);
		const hourMatch = value.match(/^(\d\d?):(\d\d)(:\d\d)?$/i);
		let error: boolean = false;

		if (!hourMatch) error = true;
		else {
			const hour = parseInt(hourMatch[1]),
				minutes = parseInt(hourMatch[2]);

			if (hour > 23 || minutes > 59) error = true;
		}

		if (error) {
			return {
				validateHour: {
					valid: false,
				},
			};
		}

		return null;
	}
}
