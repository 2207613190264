<ng-container *ngIf="urlDetails$ | async as urlDetails" >
  <wcd-collapsible-section 	*ngIf="urlDetails.incidentsCount != null || urlDetails.alertsCount != null"
    [label]="'urls.sections.securityInfo.title' | i18n"
    [sectionId]="collapsibleID.SecurityInfo"
    [noSideMargins]="true">
    <dl [class.key-values]="asKeyValueList" role="none">
      <div class="two-key-values-columns">
        <ng-container>
          <div *ngIf="urlDetails.incidentsCount != null">
			<dl>
				<dt>{{ 'urls.fields.incidentsCount.title' | i18n }}</dt>
				<dd>{{ urlDetails.incidentsCount | prettyNumber }}</dd>
			</dl>
          </div>
          <div *ngIf="urlDetails.alertsCount != null">
			<dl>
				<dt>{{ 'urls.fields.alertsCount.title' | i18n }}</dt>
				<dd>{{ urlDetails.alertsCount | prettyNumber }}</dd>
			</dl>
          </div>
        </ng-container>
      </div>
    </dl>
  </wcd-collapsible-section>
</ng-container>

<wcd-collapsible-section
	[label]="'urls.sections.domains.title' | i18n"
	[sectionId]="collapsibleID.DomainDetails"
	[noSideMargins]="true">
	<div class="title wcd-font-weight-semibold">
		{{ 'urls.fields.domain.label' | i18n}}
	</div>
	<div class="wcd-padding-xsmall-bottom">
		{{url.name}}
	</div>
	<a [href]="whoIsLink"
		data-track-id="WhoIsLink"
		data-track-type="ExternalLink"
		target="_blank">
		{{ 'urls.fields.whoIsLink.linkText' | i18n }}
	</a>
	<div class="wcd-padding-vertical" [hidden]="shouldHideIfLoadingOrNoData || !isWebContentFilteringFeatureEnabled">
		<div class="title wcd-font-weight-semibold">
			{{ 'urls.fields.domain.category' | i18n}}
		</div>
		<div class="wcd-padding-xsmall-bottom">
				{{categoryAsString}}
				<br>
				{{category2AsString}}
		</div>
		<a
		(click)="openDisputeCategory()"
		(keydown.enter)="openDisputeCategory()">
			{{ 'urls.fields.domain.disputeCategoryLink' | i18n}}
		</a>
	</div>
</wcd-collapsible-section>
<section *ngIf="showUrlStats" class="wcd-flex-none wcd-padding-vertical small-form dialog-controls">
	<h4 class="definition-list-title">
			{{'urls.details.stats.title' | i18n}}
		</h4>
		<div class="form-group wcd-flex-horizontal ng-star-inserted">
			<label for="rangeSelect">{{ 'urls.details.stats.filterTitle' | i18n }}:</label>
			<div class="form-control-wrapper">
					<fancy-select id="rangeSelect"
					[(ngModel)]="currentRange"
					(ngModelChange)="onStatRangeChange()"
					[isBordered]="true"
					labelClass="dropdown-width-small-medium"
					label='name'
					[values]="timeRanges"></fancy-select>
			</div>
		</div>
	<wcd-contents-state [contentState]="urlStatsContentState" *ngLet="urlStats$ | async as urlStats">
		<ng-container loading *ngTemplateOutlet="loading"></ng-container>
		<ng-container error *ngTemplateOutlet="error"></ng-container>
		<ng-container complete *ngTemplateOutlet="urlStatsShown"></ng-container>
		<ng-container empty *ngTemplateOutlet="urlStatsShown"></ng-container>

		<ng-template #urlStatsShown>
			<dl [class.key-values]="asKeyValueList" role="none">
				<ng-container *ngIf="urlStats?.organizationPrevalence">
					<dt>{{ 'networkEndpoints.details.stats.orgPrevalence.title' | i18n }}</dt>
					<dd>{{ urlStats.organizationPrevalence | prettyNumber }}</dd>
				</ng-container>
				<ng-container *ngIf="urlStats?.organizationFirstSeen">
					<dt>{{ 'networkEndpoints.details.stats.orgFirstSeen.title' | i18n }}</dt>
					<dd>{{ urlStats.organizationFirstSeen | date:'medium' }}</dd>
				</ng-container>
				<ng-container *ngIf="urlStats?.organizationLastSeen">
					<dt>{{ 'networkEndpoints.details.stats.orgLastSeen.title' | i18n }}</dt>
					<dd>{{ urlStats.organizationLastSeen | date:'medium' }}</dd>
				</ng-container>
			</dl>
		</ng-template>
		<ng-template #error>
			<span>{{ 'help.externalLoadError' | i18n:{ asset: dataAsset } }}</span>
		</ng-template>

		<ng-template #loading>
			<span><fab-spinner [label]="'loading' | i18n"></fab-spinner></span>
		</ng-template>
	</wcd-contents-state>
</section>
