import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { pendingActionDecisionValues } from './pending-action-decision.values';

@Entity({
	singularName: 'Pending Action Decision',
	pluralName: 'Pending Action Decisions',
	readonly: true,
	values: pendingActionDecisionValues,
})
export class PendingActionDecision extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() className: string;

	@EntityField() fabricIcon: string;
}
