import { Routes } from '@angular/router';
import { Alert, MdeUserRoleActionEnum } from '@wcd/domain';
import { MdeAllowedActionsGuard, AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard } from '@wcd/auth';
import { Feature } from '@wcd/config';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { AlertsDataviewComponent } from './components/alerts.dataview';
import { AlertEntityTypeService } from './services/alert.entity-type.service';
import { BreadcrumbsResolver } from '../../breadcrumbs/services/breadcrumbs-resolver.service';
import { AlertPageComponent } from './components/alert-page.component';
import { DetailsTabComponent } from './components/details-tab.component';
import { EntityResolver } from '../../global_entities/services/entity.resolver';
import { ImportAlertComponent } from './components/import-alert.component';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
var ɵ0 = {
    entity: Alert,
    breadcrumbsConfig: {
        resetListOnEnter: true,
        show: false,
    },
    pageTitle: 'Alerts queue',
    pageTitleKey: 'main_navigation_menu_item_alerts_queue',
    pageTitleIcon: 'alert',
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ1 = {
    entity: Alert,
    breadcrumbsConfig: {
        entity: EntityResolver,
        addParentWhenEmpty: true,
    },
    pageTitle: 'Alert',
    pageTitleIcon: 'alert',
}, ɵ2 = {
    pageTitle: 'Details',
}, ɵ3 = {
    pageTitle: 'Import alert',
    features: [Feature.ImportAlertProcessTree],
}, ɵ4 = {
    redirectTo: 'details',
};
export var alertsRoutes = [
    {
        path: 'alertsQueue',
        component: AlertsDataviewComponent,
        canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
        data: ɵ0,
    },
    {
        path: 'alerts/:id',
        component: AlertPageComponent,
        canActivate: [AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard],
        resolve: {
            entity: EntityResolver,
            breadcrumbs: BreadcrumbsResolver,
        },
        data: ɵ1,
        children: [
            {
                path: 'details',
                component: DetailsTabComponent,
                data: ɵ2,
            },
            {
                path: 'importAlert',
                component: ImportAlertComponent,
                canActivate: [FeatureEnabledGuard],
                data: ɵ3,
            },
            {
                path: '',
                component: HistorySafeRedirectComponent,
                data: ɵ4,
                pathMatch: 'full',
            },
        ],
    },
];
var AlertsModule = /** @class */ (function () {
    function AlertsModule(globalEntityTypesService) {
        globalEntityTypesService.registerEntityType(AlertEntityTypeService);
    }
    return AlertsModule;
}());
export { AlertsModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4 };
