import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	readonly: true,
	singularName: 'Device package download',
	pluralName: '',
})
export class MachineRequestPackageDownload extends ModelBase {
	@EntityField({ data: '__self' })
	readonly url: string;
}
