import { FlavorConfig } from '@wcd/scc-common';

export interface IActionCenterConfig {
	pendingActions: FlavorConfig;
	extendedFields: FlavorConfig;
	extendedFieldsLimited: FlavorConfig;
	extendedFilters: FlavorConfig;
	extendedFiltersLimited: FlavorConfig;
	stopAndQuarantinePanel: FlavorConfig;
}

export const ActionCenterConfig: IActionCenterConfig = {
	// Relevant to pending actions tab
	pendingActions: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	// Relevant to actions table columns
	extendedFields: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	// Relevant to actions table columns
	extendedFieldsLimited: {
		mdeFlavors: ['P2', 'Smb'],
	},
	// Relevant to filter by action type options
	extendedFilters: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true,
	},
	// Relevant to filter by action type options
	extendedFiltersLimited: {
		mdeFlavors: ['P2'],
	},
	// Relevant to 'stop and quarantine' panel
	stopAndQuarantinePanel: {
		mdeFlavors: ['P2'],
	},
};
