/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./suppression-rule-edit.component.ngfactory";
import * as i5 from "./suppression-rule-edit.component";
import * as i6 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../services/suppression-rules.backend.service";
import * as i9 from "../../../dialogs/services/dialogs.service";
import * as i10 from "../../../insights/services/app-insights.service";
import * as i11 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i12 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i13 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i14 from "../../machines/services/machines.service";
import * as i15 from "../../../rbac/services/rbac.service";
import * as i16 from "../../../global_entities/services/global-entity-types.service";
import * as i17 from "../services/suppression-rules.service";
import * as i18 from "@angular/router";
import * as i19 from "../../../mtp-promotion/services/mtp-promotion.service";
import * as i20 from "./suppression-rule.panel.component";
var styles_SuppressionRulePanelComponent = [];
var RenderType_SuppressionRulePanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuppressionRulePanelComponent, data: {} });
export { RenderType_SuppressionRulePanelComponent as RenderType_SuppressionRulePanelComponent };
export function View_SuppressionRulePanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { ruleEditComponent: 0 }), i0.ɵqud(671088640, 2, { panel: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 7, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(4, 114688, [[2, 4]], 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { canDeactivate: [0, "canDeactivate"], settings: [1, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 3, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, 1, 2, "suppression-rule-edit", [["class", "wcd-flex-1 wcd-flex-vertical wcd-full-height"]], null, [[null, "save"], [null, "changeStatus"], [null, "closePanel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("save" === en)) {
        var pd_0 = (_co.onDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("changeStatus" === en)) {
        var pd_1 = (_co.onChangeStatus($event) !== false);
        ad = (pd_1 && ad);
    } if (("closePanel" === en)) {
        var pd_2 = (_co.onClosePanel() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_SuppressionRuleEditComponent_0, i4.RenderType_SuppressionRuleEditComponent)), i0.ɵdid(8, 770048, [[1, 4]], 0, i5.SuppressionRuleEditComponent, [i0.ElementRef, i6.AuthService, i7.Paris, i8.SuppressionRulesBackendService, i9.DialogsService, i10.AppInsightsService, i11.I18nService, i12.FeaturesService, i13.FlavorService, i14.MachinesService, i15.RbacService, i16.GlobalEntityTypesService, i17.SuppressionRulesService, i18.Router, i19.MtpPromotionService], { rule: [0, "rule"], alert: [1, "alert"], refreshOn$: [2, "refreshOn$"], changingRuleStatus$: [3, "changingRuleStatus$"], isEditRuleMode: [4, "isEditRuleMode"] }, { save: "save", changeStatus: "changeStatus", closePanel: "closePanel" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.boundCanDeactivate; var currVal_1 = _co.settings; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.rule; var currVal_3 = _co.alert; var currVal_4 = _co.refreshOn$; var currVal_5 = _co.changingRuleStatus$; var currVal_6 = _co.isEditRuleMode; _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_SuppressionRulePanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "suppression-rule-panel", [], null, null, null, View_SuppressionRulePanelComponent_0, RenderType_SuppressionRulePanelComponent)), i0.ɵdid(1, 245760, null, 0, i20.SuppressionRulePanelComponent, [i18.Router, i11.I18nService, i9.DialogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppressionRulePanelComponentNgFactory = i0.ɵccf("suppression-rule-panel", i20.SuppressionRulePanelComponent, View_SuppressionRulePanelComponent_Host_0, { settings: "settings", rule: "rule", alert: "alert", refreshOn$: "refreshOn$", changingRuleStatus$: "changingRuleStatus$", onDone: "onDone", onChangeStatus: "onChangeStatus", onCancel: "onCancel", isEditRuleMode: "isEditRuleMode" }, {}, []);
export { SuppressionRulePanelComponentNgFactory as SuppressionRulePanelComponentNgFactory };
