<!-- tslint:disable:template-click-events-have-key-events -->
<section class="wcd-padding-vertical wcd-padding-large-horizontal wcd-border-bottom"
		 data-track-component="MultipleCyberEvents"
		 *ngFor="let eventItem of eventsMap | keyvalue: sortEventsByDateDesc">
	<ng-container *ngTemplateOutlet="eventDetails;context:{ event: eventItem.value }"></ng-container>
</section>

<ng-template #eventDetails let-event="event">
	<ng-container *ngIf="event.isUnknownOrNotAlert; else alertDetails">
		<h4>{{event.actionTime | date:fullDateFormat}}</h4>

		<wcd-collapsible-section [label]="'alerts_events_details_eventDetails' | i18n " [sectionId]="collapsibleID.EventDetails">
			<div class="wcd-margin-bottom">
				<cyber-event-entity-details [entity]="event"
											[showEventDate]="false"
											[showMachineLink]="this.options?.showMachineLink !== false"></cyber-event-entity-details>
			</div>
		</wcd-collapsible-section>

		<wcd-collapsible-section [label]="'events_details_entities' | i18n " [sectionId]="collapsibleID.EventGraph">
			<cyber-event-entities-graph
				*ngIf="options?.showEntitiesGraph !== false"
				[event]="event"
				[collapseEntities]="'all'">
			</cyber-event-entities-graph>
		</wcd-collapsible-section>

		<ng-container *ngIf="extraDataLoadRequestSent.has(event.id)">
			<i class="loader-icon wcd-margin-small-right"></i>
			{{"entity.loading" | i18n}}
		</ng-container>
	</ng-container>

	<ng-template #alertDetails>
		<wcd-collapsible-section [label]="'events_alert_details' | i18n"
								 [sectionId]="collapsibleID.AlertDetails">
			<alert-entity-details [entity]="event.relatedAlert"
								  [showTitle]="false">
			</alert-entity-details>
			<a (click)="openAlertPanel(event.relatedAlert)"
			   data-track-id="ManageAlertOpenAlertPanelLink"
			   data-track-type="Navigation">
				{{'events.details.openAlertPanel' | i18n}}
			</a>
		</wcd-collapsible-section>

		<wcd-collapsible-section *ngIf="event.relatedAlert.description"
								 [label]="'alerts.alertDescription' | i18n"
								 [sectionId]="collapsibleID.AlertDescription">
			<alerted-description [description]="event.relatedAlert.description"></alerted-description>
		</wcd-collapsible-section>

		<wcd-collapsible-section *ngIf="event.relatedAlert.recommendedAction"
								 [label]="'alerts.alert.recommendedActions' | i18n"
								 [sectionId]="collapsibleID.AlertRecommendedAction">
			<alerted-description [recommendedAction]="event.relatedAlert.recommendedAction"></alerted-description>
		</wcd-collapsible-section>
	</ng-template>
</ng-template>
