import { FlavorConfig } from '../models';

export interface IEntitiesConfig {
	urlDevicePivot: FlavorConfig;
	urlWebCategory: FlavorConfig;
}

export const EntitiesConfig: IEntitiesConfig = {
	urlDevicePivot: {
		mdeFlavors: ['P2'],
	},
	urlWebCategory: {
		mdeFlavors: ['P2'],
	}
}
