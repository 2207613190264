import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { ReportableBaseFieldComponent } from '../report-inaccuracy/tvm-reportable-base-field.component';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { ProductivityAssessmentInfo } from '../../services/tvm-productivity-impact.service';

const HOVER_PRODUCTIVITY_ASSESSMENT_ICON_EVENT = 'TVM productivity assessment icon hover';

@Component({
	selector: 'tagged-field',
	templateUrl: './tagged-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./tagged-field.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TaggedFieldComponent extends ReportableBaseFieldComponent {
	constructor(private appInsightsService: AppInsightsService) {
		super();
	}

	@Input() showTag: boolean;
	@Input() tagAfterText: boolean;
	@Input() tagText: string;
	@Input() tooltip: string;
	@Input() tagColorClass: string;
	@Input() tagBackgroundColorClass: string;
	@Input() onClick: (value: any) => any;
	@Input() value: any;

	@Input() productivityAssessmentInfo: ProductivityAssessmentInfo;

	onTooltipDisplay() {
		this.appInsightsService.trackEvent(HOVER_PRODUCTIVITY_ASSESSMENT_ICON_EVENT, { title: this.title });
	}
}
