import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediatedThreatStatus } from './remediated-threat-status.entity';
import { RemediatedThreatType } from './remediated-threat-type.value-object';

@ValueObject({
	singularName: 'Remediated Threat Status Entities',
	pluralName: 'Remediated Threat Status Entities',
	readonly: true,
})
export class RemediatedThreatStatusEntities extends ModelBase {
	@EntityField() status: RemediatedThreatStatus;

	@EntityField({ arrayOf: RemediatedThreatType })
	remediatedEntityTypes: Array<RemediatedThreatType>;

	@EntityField({
		parse: (value, rawData) =>
			rawData.remediatedEntityTypes &&
			rawData.remediatedEntityTypes.reduce(
				(entityCount, threatType) => entityCount + threatType.entities.length,
				0
			),
	})
	entityCount: number;
}
