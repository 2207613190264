/* tslint:disable:template-accessibility-elements-content */
import { Component, Input } from '@angular/core';
import { Machine, MachineSecurityKbToCve } from '@wcd/domain';

@Component({
	selector: 'machine-security-cve-panel',
	templateUrl: './machine-security-cve.panel.component.html',
})
export class MachineSecurityCvePanelComponent {
	@Input() machine: Machine;
	@Input() machineSecurityCves: Array<MachineSecurityKbToCve>;
}
