import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { SecurityRecommendation, RemediationType } from '@wcd/domain';

// Hard coded organizations ids to be earlier exposed to the feature then their exposure slice
const EXPOSE_PRODUCTIVITY_ORGS = [
	'38353bae-3349-448e-8c54-6c83a0d73a01',
	'e99296a6-c06d-4efd-928a-5f76b22e21d7',
	'2a5c34d2-452a-40fa-b165-a8c24cd82e27',
	'e350b136-411e-41a9-84a4-c033685acdc9',
	'8e41dda0-4948-402d-8b55-da7ef4f89ac7',
	'cf2f152c-8e4d-4153-aadd-39f9cbc7b6ac',
	'932f5304-5c67-4d54-b103-b78bf287a2c3',
	'76a16635-05d2-4b9f-9b4d-f4bcdf4b774d',
	'c4ac39f3-bc52-422a-8327-ad254785bb52',
	'2cbcc671-8def-4c88-82e0-89c6c75b1362',
	'0d3bac75-b457-46d8-87f7-7e78d07944f5',
	'f04ba4ba-40a9-4412-9353-9f59e1b528d1',
	'64032f4d-626a-4a92-ae42-d693ef078591',
	'308f0be7-74c1-4f7d-89cf-fafd4fd80374',
	'199bf4f2-c405-4ce7-8b33-e47ca09b41c9',
	'ee9fcc7a-1aa9-4549-886c-f663854b17de',
	'e7ed5c5c-3708-42c9-96cb-116dfcc1b96a',
];

const MIN_VALUE_FOR_SAFE_COLORING = 80;

const WHITE_ICON_COLOR = 'ms-fontColor-white';
const DARK_ICON_COLOR = 'ms-fontColor-neutralPrimaryAlt';

const GREEN_BACKGROUND_CLASS = 'ms-bgColor-green';
const GRAY_BACKGROUND_CLASS = 'ms-bgColor-neutralTertiaryAlt';

const SAFE_COLORING = {
	iconColorClass: WHITE_ICON_COLOR,
	backgroundColorClass: GREEN_BACKGROUND_CLASS,
};

const UNSAFE_COLORING = {
	iconColorClass: DARK_ICON_COLOR,
	backgroundColorClass: GRAY_BACKGROUND_CLASS,
};

export interface ProductivityAssessmentIconColoring {
	iconColorClass: string;
	backgroundColorClass: string;
}

export interface ProductivityAssessmentInfo {
	isProductivityAssessmentIncluded: boolean;
	productivityAssessmentTooltip?: string;
	isSingleAsset?: boolean;
	iconColoring: ProductivityAssessmentIconColoring;
}

@Injectable()
export class TvmProductivityImpactService {
	private _isProductivityImpactExposed: boolean;
	private _isDataviewTitleInsightsEnabled: boolean;

	constructor(featuresService: FeaturesService, private appConfigService: AppConfigService) {
		const isAsrRuleEnabled = featuresService.isEnabled(Feature.TvmExposeAsrRules);
		const isEarlyAdaptionOrg = EXPOSE_PRODUCTIVITY_ORGS.includes(this.appConfigService.orgId);

		this._isProductivityImpactExposed = isAsrRuleEnabled || isEarlyAdaptionOrg;
		this._isDataviewTitleInsightsEnabled =
			this._isProductivityImpactExposed &&
			featuresService.isEnabled(Feature.TvmTitleOrgConfigurationsAssessmentInsights);
	}

	get isProductivityImpactExposed(): boolean {
		return this._isProductivityImpactExposed;
	}

	get isDataviewTitleInsightsEnabled(): boolean {
		return this._isDataviewTitleInsightsEnabled;
	}

	// For recommendation of a single asset isProductivityImpacted will be true or false if had productivity impact assessment and null otherwise
	isRecommendationContainsImpactAssessment(recommendation: SecurityRecommendation) {
		return (
			recommendation.remediationType === RemediationType.ConfigurationChange &&
			(!!recommendation.assetsStatistics.nonProductivityImpactedAssets ||
				recommendation.isProductivityImpacted !== null)
		);
	}

	getAssetSafeRecommendationColoring(): ProductivityAssessmentIconColoring {
		return SAFE_COLORING;
	}

	getOrgSafeRecommendationColoring(percentage: number) {
		return percentage > MIN_VALUE_FOR_SAFE_COLORING ? SAFE_COLORING : UNSAFE_COLORING;
	}
}
