import { from } from 'rxjs';
import { i18nLoader } from './i18n.loader.svc';
var I18nService = /** @class */ (function () {
    function I18nService() {
    }
    Object.defineProperty(I18nService.prototype, "strings", {
        get: function () {
            return i18nLoader.strings;
        },
        enumerable: true,
        configurable: true
    });
    I18nService.prototype.init = function () {
        return from(i18nLoader.i18nDictionaryPromise);
    };
    I18nService.prototype.get = function (keyPath, params, allowNotFound) {
        if (allowNotFound === void 0) { allowNotFound = false; }
        return i18nLoader.get(keyPath, params, allowNotFound);
    };
    return I18nService;
}());
export { I18nService };
