/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../global_entities/components/entity-details/aad-user/aad-user-investigation-priority.component.ngfactory";
import * as i2 from "../../../../global_entities/components/entity-details/aad-user/aad-user-investigation-priority.component";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./aad-user-entity-status.component";
var styles_AadUserStatusComponent = [];
var RenderType_AadUserStatusComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AadUserStatusComponent, data: {} });
export { RenderType_AadUserStatusComponent as RenderType_AadUserStatusComponent };
function View_AadUserStatusComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "div", [["class", "wcd-flex wcd-flex-center-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "span", [["class", "wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, null, 1, "aad-user-investigation-priority", [], null, null, null, i1.View_AadUserInvestigationPriorityComponent_0, i1.RenderType_AadUserInvestigationPriorityComponent)), i0.ɵdid(9, 49152, null, 0, i2.AadUserInvestigationPriorityComponent, [], { priority: [0, "priority"], percentile: [1, "percentile"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = ((_co.aadUser == null) ? null : _co.aadUser.investigationPriority); var currVal_2 = ((_co.aadUser == null) ? null : _co.aadUser.percentile); _ck(_v, 9, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "users.entityDetails.sections.details.investigation.priority.title")); _ck(_v, 3, 0, currVal_0); }); }
export function View_AadUserStatusComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AadUserStatusComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.aadUser == null) ? null : _co.aadUser.investigationPriority); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AadUserStatusComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AadUserStatusComponent_0, RenderType_AadUserStatusComponent)), i0.ɵdid(1, 49152, null, 0, i6.AadUserStatusComponent, [], null, null)], null, null); }
var AadUserStatusComponentNgFactory = i0.ɵccf("ng-component", i6.AadUserStatusComponent, View_AadUserStatusComponent_Host_0, { entity: "entity" }, {}, []);
export { AadUserStatusComponentNgFactory as AadUserStatusComponentNgFactory };
