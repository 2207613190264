import {
	Component,
	ComponentFactoryResolver,
	Injector,
	OnInit,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { ModalService } from '../services/modal.service';

@Component({
	selector: 'modal-placeholder',
	template: `
		<div #modalplaceholder></div>
	`,
})
export class ModalPlaceholderComponent implements OnInit {
	@ViewChild('modalplaceholder', { read: ViewContainerRef, static: true })
	viewContainerRef;

	constructor(
		private modalService: ModalService,
		private injector: Injector,
		private _resolver: ComponentFactoryResolver
	) {}

	ngOnInit(): void {
		this.modalService.registerViewContainerRef(this.viewContainerRef);
		this.modalService.registerInjector(this.injector);
		this.modalService.registerResolver(this._resolver);
	}
}
