import { HuntingQueryBase } from './hunting-query.entity.base';
import { Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { QueryKind } from './hunting-query.type';

/**
 * @deprecated use HuntingSavedQuery instead
 */
@Entity({
	singularName: 'User query',
	pluralName: 'User queries',
	endpoint: 'hunting/queries',
	allItemsEndpoint: '/user',
	saveMethod: (item: Partial<HuntingUserQuery>) => (item.id ? 'PATCH' : 'POST'),
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class HuntingUserQuery extends HuntingQueryBase {
	readonly kind: QueryKind = 'user';
	readonly shared = false;
	readonly isScheduled = false;
}
