import { Component, Input } from '@angular/core';
import { Alert, EvidenceDetectionSource, EvidenceDetectionSourceTypeEnum } from '@wcd/domain';
import { EvidenceService } from '../services/evidence.service';

@Component({
	selector: 'evidence-detection-context',
	template: `
		<span *ngIf="detectionSource?.detectionType">
			<wcd-shared-icon
				[iconName]="detectionSource.detectionType.iconName"
				class="wcd-margin-xsmall-right"
			></wcd-shared-icon>
			<ng-container [ngSwitch]="detectionSource.detectionType.id">
				<ng-container *ngSwitchCase="EvidenceDetectionSourceTypeEnum.alert">
					<entity-name
						[entity]="alertEntity"
						[entityTypeId]="Alert"
						[hideIcon]="true"
						[showTooltip]="showTooltip"
					></entity-name>
					<span
						*ngIf="detectionSource.alertSeverity"
						class="wcd-severity wcd-margin-small-left"
						[ngClass]="'wcd-severity-' + detectionSource.alertSeverity.type"
					>
						{{ detectionSource.alertSeverity.nameI18nKey | i18n }}
					</span>
				</ng-container>
				<ng-container *ngSwitchCase="EvidenceDetectionSourceTypeEnum.investigation">
					<span>{{ investigationDetectionName }}</span>
					<span *ngIf="detectionSource.actionStatus">
						<div
							class="wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"
							[ngClass]="'color-box-' + detectionSource.actionStatus.className"
						></div>
						{{ detectionSource.actionStatus.name }}
					</span>
				</ng-container>
			</ng-container>
		</span>
	`,
})
export class EvidenceDetectionContextComponent {
	EvidenceDetectionSourceTypeEnum = EvidenceDetectionSourceTypeEnum;
	Alert = Alert;
	detectionSource: EvidenceDetectionSource;
	alertEntity: Alert;
	investigationDetectionName: string;

	@Input() showTooltip: boolean = true;

	@Input()
	set detectionContext(val: EvidenceDetectionSource) {
		this.detectionSource = val;

		if (val && val.detectionType) {
			switch (val.detectionType.id) {
				case EvidenceDetectionSourceTypeEnum.alert:
					const alertDetectionName = this.evidenceService.getEvidenceDetectionContextDisplayName(
						this.detectionSource
					);
					this.alertEntity = new Alert({ id: val.detectionId, name: alertDetectionName });
					break;
				case EvidenceDetectionSourceTypeEnum.investigation:
					this.investigationDetectionName = this.evidenceService.getEvidenceDetectionContextDisplayName(
						this.detectionSource
					);
					break;
				default:
					break;
			}
		}
	}

	constructor(private evidenceService: EvidenceService) {}
}
