import { WcdPortalParisConfig } from './../paris-config.interface';
import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Platform provisioning api call',
	endpoint: 'mtp/platform',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	parseQuery: (settings: PlatformProvisionSettings) => {
		return {
			data: {
				GeoRegion: settings.geoRegion,
				Retention: settings.retentionPolicy,
				MtpConsent: settings.mtpConsent,
			},
		};
	},
})
export class PlatformProvisionApiCall extends ApiCallModel<void, PlatformProvisionSettings> {}

export interface PlatformProvisionSettings {
	geoRegion: string;
	retentionPolicy: number;
	mtpConsent: boolean;
}
