var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField, EntityModelBase, queryToHttpOptions, } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { LegacyUserProfile } from '../legacy/user/legacy-user-profile.entity';
import { PendingAction } from '../remediation/pending_actions/pending-action.entity';
import { RemediationActionTypeActionCount } from '../remediation/remediation-action-type-action-count.value-object';
import { Tag } from '../tag/tag.entity';
import { ThreatTypeCount } from '../threats/threat-type-count.value-object';
import { InvestigationActionSummary } from './actions/investigation-action-summary.value-object';
import { InvestigatedMachine } from './investigated-machine.entity';
import { InvestigationActionsSummary } from './investigation-actions-summary.value-object';
import { InvestigationPendingType } from './investigation-pending-type.entity';
import { InvestigationStatus } from './investigation-status.entity';
import { sevilleInvestigationStatusToDisplayStatus } from './investigation-status.values';
import { InvestigatedUser } from './investigated-identity.value-object';
import { ServiceSource } from '../alert/sources/service-source.entity';
var ɵ0 = function (_, query) {
    var api = 'investigations';
    if (query && query.where && query.where['useSevilleApi']) {
        return api + "/list";
    }
    return api;
}, ɵ1 = function (config, query) {
    if (query && query.where && query.where['useSevilleApi']) {
        return config.data.serviceUrls.threatIntel;
    }
    return config.data.serviceUrls.automatedIr;
}, ɵ2 = function (investigation) { return (investigation.isRunning ? 2500 : 1000 * 60 * 2); }, ɵ3 = function (query) {
    if (query && query.where && query.where['useSevilleApi']) {
        var params_1 = {};
        if (query.pageSize) {
            params_1.pageSize = query.pageSize;
        }
        if (query.page) {
            params_1.pageIndex = query.page;
        }
        if (query.sortBy && query.sortBy.length) {
            params_1.sortByField = query.sortBy[0].field;
            params_1.sortOrder =
                query.sortBy[0].direction === DataQuerySortDirection.ascending
                    ? 'Ascending'
                    : 'Descending';
        }
        Object.keys(query.where)
            .filter(function (key) { return !~['pageSize', 'page', 'sortBy', 'useSevilleApi'].indexOf(key); })
            .forEach(function (key) {
            params_1[key] = query.where[key];
        });
        return params_1;
    }
    return queryToHttpOptions(query).params;
}, ɵ4 = function (fieldData, itemData, query) {
    if (query && query.where && query.where['useSevilleApi']) {
        var displayStatus = sevilleInvestigationStatusToDisplayStatus[fieldData];
        return displayStatus && displayStatus.id;
    }
    return fieldData;
}, ɵ5 = function (actionTypes) {
    return actionTypes && actionTypes.filter(function (type) { return type.count > 0; });
}, ɵ6 = function (user) {
    return user
        ? typeof user === 'string'
            ? { DisplayName: user }
            : {
                DisplayName: user.first_name && user.last_name
                    ? user.first_name + " " + user.last_name
                    : user.username,
                Email: user.mail,
                Phone: user.mobile || user.phone,
                ThumbnailPhotoSrc: user.picture ? "data:image/jpeg;base64," + user.picture : null,
                Title: user.user_title,
                Department: user.department,
            }
        : null;
}, ɵ7 = function (hosts, rawData, query) {
    if (hosts) {
        return hosts;
    }
    // Seville's api
    if (query && query.where && query.where['useSevilleApi'] && rawData.sense_machine_id) {
        return [
            {
                id: rawData.sense_machine_id,
                machine_id: rawData.sense_machine_id,
                name: rawData.computer_dns_name,
            },
        ];
    }
}, ɵ8 = function (value, rawData) {
    return (value || {
        total: rawData.total_action_count || rawData.total_actions || 0,
        remediated: rawData.remediation_actions
            ? rawData.remediation_actions.length
            : rawData.remediation_action_count || 0,
    });
}, ɵ9 = function (data) {
    return data &&
        data.map(function (threatType) {
            return typeof threatType === 'number' ? { threat_type: threatType } : threatType;
        });
}, ɵ10 = function (pageUrl) { return !!pageUrl; }, ɵ11 = function (fieldData, _, query) {
    if (query && query.where && query.where['useSevilleApi']) {
        return fieldData;
    }
    return null;
};
var Investigation = /** @class */ (function (_super) {
    __extends(Investigation, _super);
    function Investigation(data) {
        var _this = _super.call(this, data) || this;
        if (_this.doesInvestigationExist === undefined) {
            _this.doesInvestigationExist = true;
        }
        return _this;
    }
    Object.defineProperty(Investigation.prototype, "pendingUserActions", {
        get: function () {
            return (this.pendingActions || []).filter(function (pendingAction) { return pendingAction.isUserPending; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "pendingResourceActions", {
        get: function () {
            return (this.pendingActions || []).filter(function (pendingAction) { return !pendingAction.isUserPending; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "totalThreatCount", {
        get: function () {
            return this.threatTypes.reduce(function (total, threatTypeCount) {
                return total + threatTypeCount.count;
            }, 0);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "duration", {
        get: function () {
            var runningTime = this.runningTime;
            if (runningTime === null || runningTime === undefined)
                runningTime = ((this.endDate || new Date()).valueOf() - this.startDate.valueOf()) / 1000;
            return runningTime;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isPending", {
        get: function () {
            return this.status.isPending && this.pendingType !== null;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isPendingUser", {
        get: function () {
            return this.pendingType && this.pendingType.isUserPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isPendingSystem", {
        get: function () {
            return this.pendingType && !this.pendingType.isUserPending;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "wasPending", {
        get: function () {
            return !this.isRunning && this.pendingActions && this.pendingActions.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "wasPendingUser", {
        get: function () {
            return this.wasPending && this.pendingActions.some(function (pendingAction) { return pendingAction.isUserPending; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "wasPendingResource", {
        get: function () {
            return this.wasPending && this.pendingActions.some(function (pendingAction) { return !pendingAction.isUserPending; });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isStopping", {
        get: function () {
            return (this.requestedStatus && this.requestedStatus.isTerminated) || false;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isRunning", {
        get: function () {
            return this.status && this.status.isRunning;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isTerminated", {
        get: function () {
            return this.status.isTerminated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "hasPendingActions", {
        get: function () {
            return this.actionsSummary.pending > 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "isError", {
        get: function () {
            return this.status.isError;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "pendingTime", {
        get: function () {
            if (!this.pendingSince)
                return null;
            return (new Date().valueOf() - this.pendingSince.valueOf()) / 1000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "correlatedAlertsCount", {
        get: function () {
            return this.totalAlertsCount && this.totalAlertsCount > 1 ? this.totalAlertsCount - 1 : 0;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Investigation.prototype, "detectionSourceName", {
        get: function () {
            return this._detectionSourceName;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'end_date' }),
        __metadata("design:type", Date)
    ], Investigation.prototype, "endDate", void 0);
    __decorate([
        EntityField({ data: 'running_time' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "runningTime", void 0);
    __decorate([
        EntityField({ data: 'start_date' }),
        __metadata("design:type", Date)
    ], Investigation.prototype, "startDate", void 0);
    __decorate([
        EntityField({ data: 'title' }),
        __metadata("design:type", String)
    ], Investigation.prototype, "title", void 0);
    __decorate([
        EntityField({ data: 'is_live_response' }),
        __metadata("design:type", Boolean)
    ], Investigation.prototype, "isLiveResponse", void 0);
    __decorate([
        EntityField({
            parse: ɵ4,
        }),
        __metadata("design:type", InvestigationStatus)
    ], Investigation.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'requested_status' }),
        __metadata("design:type", InvestigationStatus)
    ], Investigation.prototype, "requestedStatus", void 0);
    __decorate([
        EntityField({ data: 'pending_type' }),
        __metadata("design:type", InvestigationPendingType)
    ], Investigation.prototype, "pendingType", void 0);
    __decorate([
        EntityField({
            data: 'pending_action_types.data',
            parse: ɵ5,
            arrayOf: RemediationActionTypeActionCount,
        }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "pendingActionTypes", void 0);
    __decorate([
        EntityField({ data: 'pending_action_types.is_remediation_disabled' }),
        __metadata("design:type", Boolean)
    ], Investigation.prototype, "isRemediationDisabled", void 0);
    __decorate([
        EntityField({ data: 'pending_actions', arrayOf: PendingAction }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "pendingActions", void 0);
    __decorate([
        EntityField({
            data: 'user',
            parse: ɵ6,
        }),
        __metadata("design:type", LegacyUserProfile)
    ], Investigation.prototype, "canceledByUser", void 0);
    __decorate([
        EntityField({
            data: 'hosts',
            arrayOf: InvestigatedMachine,
            parse: ɵ7,
        }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "machines", void 0);
    __decorate([
        EntityField({ data: 'user_activities', arrayOf: InvestigatedUser }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "investigatedUsers", void 0);
    __decorate([
        EntityField({ data: 'pending_since' }),
        __metadata("design:type", Date)
    ], Investigation.prototype, "pendingSince", void 0);
    __decorate([
        EntityField({
            data: 'actions',
            parse: ɵ8,
        }),
        __metadata("design:type", InvestigationActionsSummary)
    ], Investigation.prototype, "actionsSummary", void 0);
    __decorate([
        EntityField({ data: 'is_cleanup_applied' }),
        __metadata("design:type", Boolean)
    ], Investigation.prototype, "dataRetentionApplied", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], Investigation.prototype, "reasons", void 0);
    __decorate([
        EntityField({ data: 'affected_groups', require: 'affected_users' }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "groupNames", void 0);
    __decorate([
        EntityField({ data: 'tags', arrayOf: Tag }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "tags", void 0);
    __decorate([
        EntityField({ data: 'last_action' }),
        __metadata("design:type", InvestigationActionSummary)
    ], Investigation.prototype, "lastAction", void 0);
    __decorate([
        EntityField({ data: 'error_description' }),
        __metadata("design:type", String)
    ], Investigation.prototype, "errorDescription", void 0);
    __decorate([
        EntityField({ data: 'attention_required', defaultValue: 0 }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "attentionRequiredCount", void 0);
    __decorate([
        EntityField({
            data: 'threat_types',
            parse: ɵ9,
            arrayOf: ThreatTypeCount,
        }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "threatTypes", void 0);
    __decorate([
        EntityField({ data: 'names' }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "alertRuleNames", void 0);
    __decorate([
        EntityField({ data: 'alert_count' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "totalAlertsCount", void 0);
    __decorate([
        EntityField({
            require: 'alert',
        }),
        __metadata("design:type", Alert)
    ], Investigation.prototype, "alert", void 0);
    __decorate([
        EntityField({ data: 'alert_to_remediation_time' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "alertToRemediationTime", void 0);
    __decorate([
        EntityField({ data: 'remediation_pending_time' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "remediationPendingTime", void 0);
    __decorate([
        EntityField({ data: 'resource_pending_time' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "resourcePendingTime", void 0);
    __decorate([
        EntityField({ data: 'comments' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "commentCount", void 0);
    __decorate([
        EntityField({ data: 'entity_count' }),
        __metadata("design:type", Number)
    ], Investigation.prototype, "entityCount", void 0);
    __decorate([
        EntityField({ data: 'host_groups' }),
        __metadata("design:type", Array)
    ], Investigation.prototype, "hostGroups", void 0);
    __decorate([
        EntityField({ data: 'InvestigationPageUrl', required: false }),
        __metadata("design:type", String)
    ], Investigation.prototype, "externalInvestigationPageUrl", void 0);
    __decorate([
        EntityField({ data: 'InvestigationPageUrl', parse: ɵ10 }),
        __metadata("design:type", Boolean)
    ], Investigation.prototype, "isOfficeInvestigation", void 0);
    __decorate([
        EntityField({
            data: 'service_source',
        }),
        __metadata("design:type", ServiceSource)
    ], Investigation.prototype, "serviceSource", void 0);
    __decorate([
        EntityField({
            data: 'detection_system',
            parse: ɵ11,
        }),
        __metadata("design:type", String)
    ], Investigation.prototype, "_detectionSourceName", void 0);
    Investigation = __decorate([
        Entity({
            singularName: 'Investigation',
            pluralName: 'Investigations',
            endpoint: ɵ0,
            allItemsProperty: 'results',
            baseUrl: ɵ1,
            separateArrayParams: true,
            cache: {
                time: ɵ2,
                max: 10,
            },
            parseDataQuery: ɵ3,
        }),
        __metadata("design:paramtypes", [Object])
    ], Investigation);
    return Investigation;
}(EntityModelBase));
export { Investigation };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
