var _a;
var ALL_KNOWN_PERMISSIONS = [
    'audioCapture',
    'app.window.fullscreen.overrideEsc',
    'browsingData',
    'content_security_policy',
    'contentSettings',
    'copresence',
    'debugger',
    'declarativeNetRequest',
    'declarativeWebRequest',
    'downloads.open',
    'experimental',
    'hid',
    'history',
    'nativeMessaging',
    'pageCapture',
    'privacy',
    'proxy',
    'socket',
    'tabCapture',
    'tabs',
    'unsafe-eval',
    'usb',
    'usbDevices',
    'videoCapture',
    'vpnProvider',
    'web_accessible_resources',
    'webNavigation',
    'activeTab',
    'bookmarks',
    'clipboardRead',
    'clipboardWrite',
    'contextMenus',
    'cookies',
    'desktopCapture',
    'downloads',
    'fileSystem',
    'fileSystem.directory',
    'fileSystem.retainEntries',
    'fileSystem.write',
    'fileSystem.writeDirectory',
    'geolocation',
    'identity',
    'identity.email',
    'management',
    'processes',
    'sessions',
    'syncFileSystem',
    'system.storage',
    'topSites',
    'tts',
    'webRequest',
    'webRequestBlocking',
    'accessibilityFeatures.modify',
    'accessibilityFeatures.read',
    'alarms',
    'alwaysOnTopWindows',
    'app.window.alpha',
    'app.window.alwaysOnTop',
    'app.window.fullscreen',
    'app.window.shape',
    'certificateProvider',
    'declarativeContent',
    'documentScan',
    'enterprise.deviceAttributes',
    'enterprise.hardwarePlatform',
    'enterprise.platformKeys',
    'externally_connectable',
    'fileBrowserHandler',
    'fileSystemProvider',
    'fontSettings',
    'gcm',
    'homepage_url',
    'idle',
    'mediaGalleries',
    'networking.config',
    'notifications',
    'overrideEscFullscreen',
    'platformKeys',
    'power',
    'printerProvider',
    'signedInDevices',
    'storage',
    'system.memory',
    'system.cpu',
    'system.display',
    'ttsEngine',
    'unlimitedStorage',
    'wallpaper',
    'webview',
];
export var PermissionRisk;
(function (PermissionRisk) {
    PermissionRisk["None"] = "None";
    PermissionRisk["Low"] = "Low";
    PermissionRisk["Medium"] = "Medium";
    PermissionRisk["High"] = "High";
    PermissionRisk["VeryHigh"] = "VeryHigh";
})(PermissionRisk || (PermissionRisk = {}));
var SeverityMapping = (_a = {},
    _a[PermissionRisk.None] = 'None',
    _a[PermissionRisk.Low] = 'Low',
    _a[PermissionRisk.Medium] = 'Medium',
    _a[PermissionRisk.High] = 'High',
    _a[PermissionRisk.VeryHigh] = 'Critical',
    _a);
var filterPermissions = function (p) {
    return p.type === 'api' || p.permissionName === 'all_urls' || p.permissionName.indexOf('/') >= 0;
};
var ɵ0 = filterPermissions;
export var parsePermissionsData = function (permissions) {
    return (permissions || [])
        .map(function (p) {
        var isApiPermissions = ALL_KNOWN_PERMISSIONS.indexOf(p.name) >= 0;
        // if this is a known permission, replace permission name . (dot) and - (dash) to underscore to support locations keys in locstring file
        // otherwise this is a url, just remove '<' and '>' in case this is <all_urls>
        var renamedPermissions = isApiPermissions
            ? p.name.replace(/[.-]/g, '_')
            : p.name.replace(/[<>]/g, '');
        return {
            permissionName: renamedPermissions,
            permissionRisk: permissionRiskAsNumberToSeverityLevel(p.risk),
            isOptional: p.isOptional,
            type: isApiPermissions ? 'api' : 'host',
        };
    })
        .filter(filterPermissions);
};
export var permissionRiskAsNumberToSeverityLevel = function (risk) {
    return SeverityMapping[Object.keys(PermissionRisk)[risk]];
};
export var permissionRiskAsStringToExposureScoreCategory = function (risk) {
    return SeverityMapping[risk];
};
export { ɵ0 };
