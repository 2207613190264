/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../../projects/angular-extensions/src/lib/directives/let.directive";
import * as i8 from "./alert-incident-details.component";
import * as i9 from "@microsoft/paris/dist/lib/paris";
import * as i10 from "../../services/entity-panels.service";
import * as i11 from "../../../@entities/alerts/services/alerts.fields";
import * as i12 from "../../../dialogs/services/dialogs.service";
var styles_AlertIncidentDetailsComponent = [];
var RenderType_AlertIncidentDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertIncidentDetailsComponent, data: {} });
export { RenderType_AlertIncidentDetailsComponent as RenderType_AlertIncidentDetailsComponent };
function View_AlertIncidentDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "button", [["class", "btn-inline btn-link no-padding"], ["data-track-id", "AlertPanelAlertsIncidentDetailsSection_ShowMoreAlerts"], ["data-track-type", "Button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onShowMoreAlerts() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t", "\n\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "alerts.showMore")); _ck(_v, 1, 0, currVal_0); }); }
function View_AlertIncidentDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-datatable", [], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.viewAlert($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"] }, { itemClick: "itemClick" }), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertIncidentDetailsComponent_2)), i0.ɵdid(7, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngLet; var currVal_1 = false; var currVal_2 = _co.alertsColumns; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2); var currVal_3 = (_co.incident.alertCount > _co.MAX_ALERTS); _ck(_v, 7, 0, currVal_3); }, null); }
export function View_AlertIncidentDetailsComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i6.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AlertIncidentDetailsComponent_1)), i0.ɵdid(2, 16384, null, 0, i7.LetDirective, [i0.ViewContainerRef, i0.TemplateRef], { ngLet: [0, "ngLet"] }, null), i0.ɵpid(131072, i5.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.alerts$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_AlertIncidentDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-incident-details", [], null, null, null, View_AlertIncidentDetailsComponent_0, RenderType_AlertIncidentDetailsComponent)), i0.ɵdid(1, 114688, null, 0, i8.AlertIncidentDetailsComponent, [i9.Paris, i10.EntityPanelsService, i11.AlertsFields, i12.DialogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertIncidentDetailsComponentNgFactory = i0.ɵccf("alert-incident-details", i8.AlertIncidentDetailsComponent, View_AlertIncidentDetailsComponent_Host_0, { incident: "incident" }, {}, []);
export { AlertIncidentDetailsComponentNgFactory as AlertIncidentDetailsComponentNgFactory };
