<div class="wcd-full-height">
	<section>
		<h4 class="wcd-font-weight-semibold wcd-margin-left definition-list-title">{{ 'reporting.webThreatReport.domainList.urlTitle' | i18n }} {{ domain.urls }}</h4>
		<domain-urls
					 class="wcd-flex-1 wcd-margin-large-top"
					 [domainId]="domainIdSelected"
					 [allowPaging]="false"
					 [navigateOnChange]="false"
					 [fullUrlList]="false"
		></domain-urls>
		<section class="wcd-margin-large-top">
			<h4 class="wcd-font-weight-semibold wcd-margin-left definition-list-title">{{ 'reporting.webThreatReport.domainList.exposedMachinesTitle' |  i18n }} ({{ domain.machinesDetectedOn }})</h4>
			<domain-exposed-machines
						class="wcd-flex-1"
						[domainId]="domainIdSelected"
						[allowPaging]="false"
						[navigateOnChange]="false"
						[fullExposedMachineList]="false"
			></domain-exposed-machines>
		</section>
	</section>
</div>
