import { Injectable } from '@angular/core';
import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { AssessmentJob, ResolveEntityURL, TvmEndPoint } from '@wcd/domain';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';

@Injectable()
export class AssessmentJobEntityTypeService implements EntityTypeService<AssessmentJob> {
	constructor(private tvmDownloadService: TvmDownloadService) {}

	readonly entityType: EntityType<AssessmentJob> = {
		id: 'authenticated-scan',
		entity: AssessmentJob,
		getEntityName: (authenticatedScan: AssessmentJob) => authenticatedScan.id,
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<AssessmentJob, {}>>{
			defaultSortFieldId: 'agentId',
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, format, searchTerm, dataQuery) => {
					const url = ResolveEntityURL({
						endPoint: TvmEndPoint.AuthenticatedScans,
						entityModelBaseOrRelationship: AssessmentJob,
					});

					return this.tvmDownloadService.downloadCsv({
						url: url,
						fileName: 'export-tvm-assessment-jobs',
						dataQuery: dataQuery,
					});
				},
			},
		},
	};
}
