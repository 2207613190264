import { OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { FeaturesService, MtpOnboardingService, Feature } from '@wcd/config';
import { Subject, combineLatest, from } from 'rxjs';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { tenantContextCache } from '@wcd/auth';
import { ContentState } from '@wcd/contents-state';
import { map } from 'lodash-es';
import { AppConfigService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';
import { TenantSettings } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AppInsightsService } from '../../../../../../apps/portal/src/app/insights/services/app-insights.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
var CANARY = 'Canary';
var MtpSettingsConsent = /** @class */ (function () {
    function MtpSettingsConsent(paris, appConfigService, i18nService, mtpOnboardingService, changeDetectorRef, appInsightsService, featuresService) {
        this.paris = paris;
        this.appConfigService = appConfigService;
        this.i18nService = i18nService;
        this.mtpOnboardingService = mtpOnboardingService;
        this.changeDetectorRef = changeDetectorRef;
        this.appInsightsService = appInsightsService;
        this.featuresService = featuresService;
        this.MessageBarType = MessageBarType;
        this.SpinnerSize = SpinnerSize;
        this.contentState = ContentState.Loading;
        this.error$ = new Subject();
        this.saveInProgress = false;
        this.isExposedToOptOut = this.featuresService.isEnabled(Feature.ExposedToOptOut);
        this.dataCenterValues = this.getDataCenterValues(this.appConfigService.supportedGeoRegions);
    }
    MtpSettingsConsent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.mtpOnboardingService.getConsent(), this.paris.getRepository(TenantSettings).getItemById(1), from(sccHostService.auth.isInRole(SccRoles.hasMtpOnboardingPermission))).subscribe(function (_a) {
            var consent = _a[0], settings = _a[1], hasPermissionToChangeConsent = _a[2];
            _this.addUsInternalIfNecessary(settings.geoRegion); // Support Canary tenants
            var selectedDataCenter = _this.dataCenterValues.find(function (checklistValue) { return checklistValue.id === settings.geoRegion; });
            _this.dataStorageLocationText = _this.i18nService.get('sccOnboarding.mtpOnboarding.geoRegionText', {
                geoRegion: selectedDataCenter.name,
            });
            _this.mtpConsent = _this.initialMtpConsent = consent;
            _this.hasPermissionToChangeConsent = hasPermissionToChangeConsent;
            _this.contentState = ContentState.Complete;
            _this.changeDetectorRef.detectChanges();
        }, function (error) { return (_this.contentState = ContentState.Error); });
    };
    MtpSettingsConsent.prototype.addUsInternalIfNecessary = function (geoRegion) {
        if (geoRegion === CANARY) {
            var supportedGeoRegionsIncludingCanary = this.appConfigService.supportedGeoRegions.slice();
            supportedGeoRegionsIncludingCanary.unshift(CANARY);
            this.dataCenterValues = this.getDataCenterValues(supportedGeoRegionsIncludingCanary);
        }
    };
    MtpSettingsConsent.prototype.getDataCenterValues = function (supportedGeoRegions) {
        var _this = this;
        return map(supportedGeoRegions, function (geo) {
            return ({
                id: geo,
                name: _this.i18nService.get('endpointManagement.supportedGeoRegions.' + geo),
            });
        });
    };
    MtpSettingsConsent.prototype.onCheckboxChange = function () {
        this.mtpConsent = !this.mtpConsent;
        // Temporary hack to handle change detection in SCC
        this.changeDetectorRef.detectChanges();
    };
    MtpSettingsConsent.prototype.saveConsent = function () {
        var _this = this;
        this.error$.next(false);
        this.saveInProgress = true;
        this.changeDetectorRef.detectChanges();
        this.mtpOnboardingService.setConsent(this.mtpConsent).subscribe(function () {
            _this.appInsightsService.trackEvent('MtpSettings-ConsentChanged', {
                Consent: _this.mtpConsent,
                AccountType: tenantContextCache.appConfig.AccountType,
            });
            _this.initialMtpConsent = _this.mtpConsent;
            _this.saveInProgress = false;
            _this.changeDetectorRef.detectChanges();
        }, function (error) {
            _this.mtpConsent = _this.initialMtpConsent;
            _this.error$.next(true);
            _this.saveInProgress = false;
            _this.changeDetectorRef.detectChanges();
        });
    };
    MtpSettingsConsent.prototype.ngOnDestroy = function () {
        this.error$ && this.error$.unsubscribe();
    };
    return MtpSettingsConsent;
}());
export { MtpSettingsConsent };
