import { Component, Input } from '@angular/core';
import { Tag } from '@wcd/domain';
import { FlexDirection } from '@wcd/shared';

@Component({
	selector: 'tags-field',
	templateUrl: './tags-field.component.html',
})
export class TagsFieldComponent {
	@Input()
	set tags(value: ReadonlyArray<Tag | string>) {
		this.localTags = value;
	}

	@Input() maxItems = 2;
	@Input() autoOverflow = false; // only supported for horizontal orientation
	@Input() orientation: FlexDirection = FlexDirection.Horizontal;

	localTags: ReadonlyArray<Tag | string> = [];

	constructor() {}
}
