import { ChangeDetectionStrategy, Component, ComponentRef, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EmailUrlsDetailsPanelComponent, EmailUrlsPanelInput } from './email-urls-details.panel.component';
import { UrlThreatType } from '@wcd/domain';

@Component({
	selector: 'email-entity-urls-field',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="urls?.length; else noUrls">
			<a
				(click)="showMailMessageUrls()"
				(keydown.enter)="showMailMessageUrls()"
				data-track-id="ShowEmailUrlsPanel"
				data-track-type="Button"
				class="btn-inline btn-link no-padding btn-link-high-contrast"
				tabindex="0"
				role="link"
			>
				{{ 'airsEntities_emailMessage_fields_urls_and_threats_seeAll' | i18n: { count: urls.length } }}
			</a>
		</ng-container>
		<ng-template #noUrls>
			{{ i18nService.strings.airsEntities_emailMessage_fields_urls_and_threats_noUrlsAndThreatsFound }}
		</ng-template>
	`,
})
export class EmailEntityUrlsFieldComponent {
	@Input() urls: Array<string>;
	@Input() potentiallyHarmfulUrls: Map<string, UrlThreatType>;

	constructor(public readonly i18nService: I18nService, private dialogsService: DialogsService) {}

	showMailMessageUrls(): Promise<ComponentRef<EmailUrlsDetailsPanelComponent>> {
		const panelSettings: PanelSettings = {
			id: 'email-urls-panel',
			type: PanelType.large,
			isModal: true,
			showOverlay: false,
			isBlocking: true,
			noBodyPadding: true,
			scrollBody: true,
			headerElementId: 'email-urls-panel-header',
		};

		const panelInputs: EmailUrlsPanelInput = {
			urls: this.urls,
			potentiallyHarmfulUrls: this.potentiallyHarmfulUrls,
		};

		return this.dialogsService
			.showPanel(EmailUrlsDetailsPanelComponent, panelSettings, panelInputs)
			.toPromise();
	}
}
