
		<wcd-datatable
			*ngIf="groupMembership?.length; else noneFound"
			[items]="groupMembership"
			[columns]="tableFields"
			[selectEnabled]="false"
			[fieldWidths]="{
				name: resourceTableFieldsWidth,
				formattedLastSeen: resourceTableFieldsWidth,
				domainName: resourceTableFieldsWidth
			}"
		>
		</wcd-datatable>
		<ng-template #noneFound>
			{{ 'machines.entityDetails.fields.groupMembership.noneFound' | i18n }}
		</ng-template>
	