import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import {
	SecurityRecommendation,
	RemediationType,
	RemediationTask,
	Vulnerability,
	ExceptionJustification,
	RemediationTicket,
	RemediationTicketStateValue,
	ExploitType,
	ProductivityImpactRemediationType,
	AssetsStatistics,
	RemediationDescriptor,
	RemediationTaskStateValue,
	SoftwareInstallationAgg,
	RecommendationException,
	ExceptionType,
	ChangeEvent,
	RecommendationCategory,
	operatingSystemPlatformValues,
	VulnerabilityChangeEventType,
	VulnerabilityAffectedProduct,
	RecommendationContextType,
	EolWindows,
	VulnerabilityInstalledAffectedProduct,
	RemediationCategory,
	EolState,
	RecommendationExceptionAggregated,
	RemediationBlock,
	MicrosoftProductIdsWithLegalNote,
	VulnerabilityType,
	SoftwareVersion,
	RemediationTaskCompletionMethod,
} from '@wcd/domain';
import { PrettyNumberService } from '@wcd/prettify';
import { Feature, FeaturesService } from '@wcd/config';
import { TzDateService } from '@wcd/localization';
import { baselineBaseBenchmarkValues } from '../../../../../../packages/@wcd/domain/src/tvm/baseline-compliance/baseline-profile-creation/baseline-base-benchmark.values';
declare const moment: typeof import('moment');

const GREEN_COLOR = 'ms-color-green';

export enum InaccuracyContext {
	MachineScaRecommendation = 'Device SCA Recommendation',
	MachineVaRecommendation = 'Device VA Recommendation',
	MachineInventory = 'Device Inventory',
	MachineVulnerabilities = 'Device Vulnerabilities',
	OrgVaRecommendation = 'Org VA Recommendation',
	OrgScaRecommendation = 'Org SCA Recommendation',
	OrgInventory = 'Org Inventory',
	OrgVulnerabilities = 'Org Vulnerabilities',
}

export enum TextToken {
	SecurityRecommendationTitle,
	SecurityRecommendationRelatedComponent,
	SecurityRecommendationDescription,
	SecurityRecommendationSCAProperlyConfiguredInsight,
	SecurityRecommendationSCARecommendedBenchmarksInsight,
	SecurityRecommendationAttentionExplanation,
	SecurityRecommendationJustificationNotification,
	OrgRecommendationProductivityImpact,
	OrgRecommendationProductivityImpactTooltip,
	AssetRecommendationProductivityImpactTooltip,
	AssetRecommendationProductivityImpact,
	SecurityAssessmentsSecurityRecommendations,
	SecurityAssessmentsDiscoveredVulnerabilities,
	ExploitIconToolTip,
	ActiveThreatIconTooltip,
	RemediationTaskStatusDescription,
	RemediationTaskSafeMachines,
	RemediationRelatedComponent,
	ExposureScoreWidgetInfo,
	ExposureScoreWidgetInfoAriaLabel,
	ConfigurationScoreWidgetInfo,
	ScoreImpactInfo,
	ExposureScoreTooltip,
	ConfigurationScoreTooltip,
	ExposureScoreForSoftware,
	EntityName,
	EntityFullName,
	AffectedProduct,
	VulnerabilityInstalledAffectedProduct,
	VulnerabilityOsFeature,
	OsFeatureTooltip,
	RelatedSoftware,
	RelatedSoftwareTooltip,
	VulnerabilitiesCount,
	MisconfigurationsCount,
	ScoreWidgetNoData,
	NoDataForWidget,
	ReportInaccuracyReasonTitle,
	VulnerabilityAge,
	RemediationTicketStatusDescription,
	RemediationTicketStatus,
	InstallationAggVersionsTooltip,
	InstallationAggVersions,
	SoftwareVersionsLinkText,
	SoftwareVersionsName,
	SoftwareVersionsVendorNameAndVersion,
	ExceptionRemediationType,
	ChangeEventActivity,
	SoftwareEolOsLegalNote,
	SoftwareEolOsLegalNoteRecommendation,
	RecommendationExceptionRelatedComponent,
	HasEolVersionsMicrosoftLegalNote,
	HasEolVersionsMicrosoftLegalNoteRecommendation,
	HasEolVersionsText,
	HasUpcomingEolVersionsText,
	EolVersionKbLegalNote,
	UpcomingEolVersionKbLegalNote,
	CombinedEolVersionsText,
	UpgradeLinkText,
	EolSoftwareText,
	UpcomingEolSoftwareText,
	AggregatedExceptionRequester,
	AggregatedExceptionScope,
	RemediationBlockTitle,
	RemediationBlockSoftwareTitle,
	SoftwareNameFromId,
	MitigationDescription,
	HighValueAssetDescription,
	LegalNoteLinkText,
	RemediationTaskCompletedBy,
	RemediationBlockStatus,
	RedHatLegalNotice,
}

/**
 *    The service offers a way to avoid display-text manipulations in domain entities, and provides a DRY solution to complex text manipulation logic.
 * 	  It doesn't replaces the i18nService, rather, "extends" it when more complex logic is needed.
 *    Use it by adding TextTokens and implement a cb function that accepts any data bag and returns the text. the cb function should use the i18nService for translations.
 */
@Injectable()
export class TvmTextsService {
	private _noDataAvailableText: string = this.i18nService.get('common.noDataAvailable');
	private _otherInaccuracyReason: string;
	private _yesLabel: string;
	private _noLabel: string;
	private _unknownLabel: string;

	constructor(
		private i18nService: I18nService,
		private prettyNumberService: PrettyNumberService,
		private featuresService: FeaturesService,
		private tzDateService: TzDateService
	) {
		this._otherInaccuracyReason = this.i18nService.get('tvm.reportInaccuracy.reasons.otherReason');
		this._yesLabel = this.i18nService.get('common.yes');
		this._noLabel = this.i18nService.get('common.no');
		this._unknownLabel = this.i18nService.get('common.unknown');
		this.setContextToReasonsMap();
	}

	getProductivityImpactedLabel(productivityImpacted?: boolean) {
		if (productivityImpacted === null) {
			return this._unknownLabel;
		}
		return productivityImpacted ? this._yesLabel : this._noLabel;
	}

	get otherInaccuracyReason(): string {
		return this._otherInaccuracyReason;
	}

	private readonly textsMap: Record<TextToken, (data?: any) => string> = {
		[TextToken.VulnerabilitiesCount]: (vulnerabilitiesCount: number) => {
			return vulnerabilitiesCount === 1
				? this.i18nService.get('tvm.softwarePage.report.widgets.vulnerabilities.title.data.singular')
				: this.i18nService.get('tvm.softwarePage.report.widgets.vulnerabilities.title.data.plural', {
						count: this.prettyNumberService.prettyNumber(vulnerabilitiesCount),
				  });
		},
		[TextToken.MisconfigurationsCount]: (misconfigurationsCount: number) => {
			return misconfigurationsCount === 1
				? this.i18nService.get(
						'tvm.softwarePage.report.widgets.misconfigurations.title.data.singular'
				  )
				: this.i18nService.get(
						'tvm.softwarePage.report.widgets.misconfigurations.title.data.plural',
						{
							count: this.prettyNumberService.prettyNumber(misconfigurationsCount),
						}
				  );
		},
		[TextToken.SecurityAssessmentsSecurityRecommendations]: (recommendationsCount: number) => {
			return recommendationsCount === 1
				? this.i18nService.get(
						'machines.report.widgets.securityAssessments.singleSecurityRecommendations',
						{ count: '1' }
				  )
				: this.i18nService.get(
						'machines.report.widgets.securityAssessments.pluralSecurityRecommendations',
						{ count: recommendationsCount }
				  );
		},
		[TextToken.SecurityAssessmentsDiscoveredVulnerabilities]: (vulnerabilitiesCount: number) => {
			return vulnerabilitiesCount === 1
				? this.i18nService.get(
						'machines.report.widgets.securityAssessments.singleDiscoveredVulnerabilities',
						{ count: '1' }
				  )
				: this.i18nService.get(
						'machines.report.widgets.securityAssessments.pluralDiscoveredVulnerabilities',
						{ count: vulnerabilitiesCount }
				  );
		},
		[TextToken.SecurityRecommendationTitle]: (securityRecommendation: SecurityRecommendation) => {
			if (securityRecommendation.title) return securityRecommendation.title;

			if (securityRecommendation.remediationType === RemediationType.ConfigurationChange) return ''; //we expect SCA to bring recomm' title from the BE.

			const software = this.parseFullProductName(
				securityRecommendation.productName,
				securityRecommendation.vendor
			);

			const isZeroDayRecommendation =
				this.featuresService.isEnabled(Feature.TvmZeroDay) &&
				securityRecommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
				!securityRecommendation.patchReleaseDate;

			if (isZeroDayRecommendation) {
				return `${this.i18nService.strings.tvm_securityRecommendation_zeroDay_title} ${software}`;
			}

			const eolPostfix = securityRecommendation.isEOL
				? ` (${this.i18nService.get('tvm.securityRecommendation.isEol')})`
				: '';
			const versionToken = securityRecommendation.recommendedVersion
				? ` ${this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.toVersion')} ${
						securityRecommendation.recommendedVersion
				  }`
				: '';

			switch (securityRecommendation.remediationType) {
				case RemediationType.Upgrade:
					const upgradeToken = this.i18nService.get(
						'tvm.securityRecommendation.descriptionTemplates.upgradeSoftware',
						{ software: software }
					);

					const upgradeTo = this.getUpgradeTo(securityRecommendation);
					const upgradePostfix =
						upgradeTo !== null
							? this.i18nService.get(
									'tvm.securityRecommendation.descriptionTemplates.upgradeTo',
									{
										product: upgradeTo,
									}
							  )
							: '';
					return `${upgradeToken}${upgradePostfix}${versionToken}`;
				case RemediationType.Update:
					const updateToken = this.i18nService.get(
						'tvm.securityRecommendation.descriptionTemplates.updateSoftware',
						{ software: software }
					);
					const windowsOsProductIds = Object.values(this.osNameToProductId).filter(val =>
						val.startsWith('microsoft')
					); // Only microsoft OS
					const windowsPostfix = windowsOsProductIds.includes(securityRecommendation.productId)
						? this.i18nService.get(
								'tvm.securityRecommendation.descriptionTemplates.windowsPostfix'
						  )
						: '';
					return `${updateToken}${versionToken}${windowsPostfix}`;
				case RemediationType.Uninstall:
					return `${this.i18nService.get(
						'tvm.securityRecommendation.descriptionTemplates.uninstallSoftware',
						{ software: software }
					)}${eolPostfix}`;
				case RemediationType.AttentionRequired:
					return this.i18nService.get(
						'tvm.securityRecommendation.descriptionTemplates.attnSoftware',
						{ software: software }
					);
			}
		},

		[TextToken.SoftwareVersionsLinkText]: (productName: string) =>
			this.i18nService.get('tvm.securityRecommendation.remediationTaskCreation.openSoftwareVersions', {
				software: this.parseEntityName(productName),
			}),

		[TextToken.SoftwareVersionsName]: (softwareVersion: SoftwareVersion) =>
			this.i18nService.get('tvm_common_softwareVersionName', {
				softwareName: softwareVersion.name,
			}),

		[TextToken.SoftwareVersionsVendorNameAndVersion]: (softwareVersion: SoftwareVersion) =>
			this.i18nService.get('tvm_common_softwareVersionFileName', {
				softwareName: softwareVersion.name,
				softwareVendor: softwareVersion.vendor,
				softwareVersion: softwareVersion.version,
			}),

		[TextToken.SecurityRecommendationRelatedComponent]: (
			securityRecommendation: SecurityRecommendation
		) =>
			this.getRelatedComponentName(
				securityRecommendation.category,
				securityRecommendation.remediationType,
				securityRecommendation.productName,
				securityRecommendation.vendor,
				securityRecommendation.subCategory
			),

		[TextToken.SecurityRecommendationDescription]: (params: {
			recommendation: SecurityRecommendation;
			noHtmlTags: boolean;
		}) => {
			const securityRecommendation = params.recommendation;
			const noHtmlTags = params.noHtmlTags;
			if (securityRecommendation.description) return securityRecommendation.description;

			if (securityRecommendation.remediationType === RemediationType.ConfigurationChange) return ''; //we expect SCA to bring recomm' description from the BE.

			const software = this.parseEntityName(securityRecommendation.productName);

			const isZeroDayRecommendation =
				this.featuresService.isEnabled(Feature.TvmZeroDay) &&
				securityRecommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
				!securityRecommendation.patchReleaseDate;

			const isEol = securityRecommendation.eolSoftwareState === EolState.AlreadyEOL;
			const isUpcomingEol = securityRecommendation.eolSoftwareState === EolState.UpcomingEOL;
			const vulnerable = securityRecommendation.weaknessesCount > 0;
			const hasEolVersions = securityRecommendation.hasEolVersions;
			const hasUpcomingEolVersions = securityRecommendation.hasUpcomingEolVersions;

			if (isZeroDayRecommendation) {
				return this.getRecommendationZeroDayDescription(
					securityRecommendation,
					software,
					isEol,
					hasEolVersions,
					hasUpcomingEolVersions,
					noHtmlTags
				);
			}

			const supportNoAvailable = isEol
				? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_softwareEOL', {
						software: software,
				  })
				: '';

			const eolPostfix = isEol
				? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_softwareEOLPostfix')
				: '';

			const upcomingEolNote = isUpcomingEol
				? this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_upcomingEOLWithDate',
						{
							softwareName: software,
							eolSinceDate: this.tzDateService.format(
								securityRecommendation.eolSoftwareSinceDate,
								'shortDate'
							),
						}
				  )
				: '';
			const mitigateToken = vulnerable
				? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_toMitigateKnown', {
						vulnerabilitiesCount: securityRecommendation.vulnerabilitiesCount,
				  })
				: '';
			const vulnerabilitiesToken = vulnerable
				? securityRecommendation.vulnerabilitiesCount === 1
					? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_vulnerability')
					: this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_vulnerabilities')
				: '';
			const affectingYourDevicesToken = vulnerable
				? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_affectingYourDevices')
				: '';
			const hasEolVersionsString =
				hasEolVersions && hasUpcomingEolVersions
					? this.i18nService.get(
							'tvm_securityRecommendation_descriptionTemplates_combinedEolVersions'
					  )
					: hasEolVersions
					? this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_hasEolVersions')
					: hasUpcomingEolVersions
					? this.i18nService.get(
							'tvm_securityRecommendation_descriptionTemplates_hasUpcomingEolVersions'
					  )
					: '';

			switch (securityRecommendation.remediationType) {
				case RemediationType.Upgrade:
					if (EolWindows.includes(securityRecommendation.productId)) {
						const prefix = this.i18nService.get(
							'tvm_securityRecommendation_descriptionTemplates_osEolPrefix',
							{ software: software }
						);
						const upgradeTo = this.getUpgradeTo(securityRecommendation);
						const newProductSuggestion =
							upgradeTo !== null
								? this.i18nService.get(
										'tvm_securityRecommendation_descriptionTemplates_osEolSuggestion',
										{ product: upgradeTo }
								  )
								: '';
						const postfix = this.i18nService.get(
							'tvm_securityRecommendation_descriptionTemplates_osEolPostfix'
						);
						return `${prefix}${newProductSuggestion} ${postfix}`;
					}
				case RemediationType.Update:
					const updateToken = this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_updateSoftware',
						{ software: software }
					);
					const versionToken = this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_toTheLatestVersion'
					);
					const windowsPostfix =
						software === 'Windows 10'
							? this.i18nService.get(
									'tvm_securityRecommendation_descriptionTemplates_windowsDescriptionPostfix'
							  )
							: '';
					return `${updateToken} ${versionToken} ${securityRecommendation.recommendedVersion ||
						''} ${mitigateToken} ${vulnerabilitiesToken} ${affectingYourDevicesToken} ${hasEolVersionsString} ${windowsPostfix} ${upcomingEolNote}`;
				case RemediationType.Uninstall:
					const uninstallToken = this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_uninstallSoftware',
						{ software: software }
					);
					const helpLessenToken = vulnerable
						? this.i18nService.get(
								'tvm_securityRecommendation_descriptionTemplates_helpLessenVulnerable'
						  )
						: this.i18nService.get(
								'tvm_securityRecommendation_descriptionTemplates_helpLessenNotVulnerable'
						  );
					return `${supportNoAvailable} ${uninstallToken} ${helpLessenToken} ${eolPostfix}`;
			}
		},

		[TextToken.SecurityRecommendationSCAProperlyConfiguredInsight]: (
			securityRecommendation: SecurityRecommendation
		) => {
			let properlyConfiguredDevices = 0;
			if (securityRecommendation.assetsStatistics) {
				const stats = securityRecommendation.assetsStatistics;
				properlyConfiguredDevices = stats.totalAssetCount - stats.assetsAtRiskCount;
				if (properlyConfiguredDevices < 0) {
					// TODO: evhvoste raise alert if the value is negative (problem with data)
					properlyConfiguredDevices = 0;
				}
			}
			if (properlyConfiguredDevices === 0) {
				return this.i18nService.get('tvm.securityRecommendation.insights.noConfiguredDevices');
			}
			const properlyConfiguredDevicesPretty = this.prettyNumberService.prettyNumber(
				properlyConfiguredDevices
			);

			let configuredPercentage = 0;
			let configuredPercentageToken = '';
			const totalAssetCount = securityRecommendation.assetsStatistics.totalAssetCount;
			if (totalAssetCount > 0) {
				configuredPercentage = Math.round((properlyConfiguredDevices / totalAssetCount) * 100);
				if (configuredPercentage > 0) configuredPercentageToken = ` (${configuredPercentage}%)`;
			}

			const configuredDevicesToken =
				properlyConfiguredDevices === 1
					? this.i18nService.get('tvm.securityRecommendation.insights.configuredDevicesSingular')
					: this.i18nService.get('tvm.securityRecommendation.insights.configuredDevices');

			return `${properlyConfiguredDevicesPretty}${configuredPercentageToken} ${configuredDevicesToken}`;
		},

		[TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight]: (
			securityRecommendation: SecurityRecommendation
		) => {
			if (securityRecommendation.benchmarks && securityRecommendation.benchmarks.length) {
				const recommendedBenchmarkToken = this.i18nService.get(
					'tvm.securityRecommendation.insights.recommendedBenchmarks'
				);
				let supportedBenchmarksToken = '';
				if (securityRecommendation.benchmarks) {
					supportedBenchmarksToken = securityRecommendation.benchmarks.join(', ');
				}
				return `${recommendedBenchmarkToken}: ${supportedBenchmarksToken}`;
			} else {
				return '';
			}
		},

		[TextToken.SecurityRecommendationAttentionExplanation]: (
			securityRecommendation: SecurityRecommendation
		) => {
			const software = this.parseEntityName(securityRecommendation.productName);
			return `${this.i18nService.get('tvm.securityRecommendation.requiredAttentionExplanation', {
				software: software,
				version: securityRecommendation.recommendedVersion,
			})}`;
		},

		[TextToken.RemediationTaskStatusDescription]: (remediationTask: RemediationTask) => {
			return this.i18nService.get(`tvm.remediationTask.status.${remediationTask.status.value}`);
		},

		[TextToken.RemediationTaskCompletedBy]: (remediationTask: RemediationTask) => {
			switch (remediationTask.completionMethod) {
				case RemediationTaskCompletionMethod.Manual:
					return remediationTask.completer.email;
				case RemediationTaskCompletionMethod.Automatic:
					return this.i18nService.strings.tvm_completedRemediation_automatic;
				default:
					return this.i18nService.strings.notAvailable_short;
			}
		},

		[TextToken.RemediationTicketStatusDescription]: (remediationTicket: RemediationTicket) => {
			const ticketStatus = remediationTicket.status || RemediationTicketStateValue.Unknown;
			let ticketStatusValue = remediationTicket.rawStatus
				? remediationTicket.rawStatus
				: this.i18nService.get(`tvm.remediationTask.ticket.status.${ticketStatus}`, null, true);
			if (ticketStatusValue === '') {
				ticketStatusValue = ticketStatus;
			}
			return ticketStatusValue.concat(` (${remediationTicket.itsmTool})`);
		},

		[TextToken.VulnerabilityInstalledAffectedProduct]: (
			product: VulnerabilityInstalledAffectedProduct
		) => {
			try {
				return this.generateProductString(
					product.vendor,
					product.productName,
					product.productVersion,
					true
				);
			} catch (error) {
				this.i18nService.get('common.noDataAvailable');
			}
		},

		[TextToken.OsFeatureTooltip]: (vulnerability: Vulnerability) => {
			if (!vulnerability.osFeatures || vulnerability.osFeatures.length < 1) {
				return this.i18nService.get('common.noDataAvailable');
			}

			return `${vulnerability.osFeatures.join(`<br>`)}`;
		},

		[TextToken.RelatedSoftwareTooltip]: (vulnerability: Vulnerability) =>
			this.getFormattedRelatedSoftwareTooltip(vulnerability.productIds),
		[TextToken.InstallationAggVersionsTooltip]: (softwareInstallation: SoftwareInstallationAgg) => {
			try {
				return `<div>
					${softwareInstallation.installedVersions.reduce((prev, curr) => `${prev}<div>${curr}</div>`, '')}
				</div>
			`;
			} catch (error) {
				return this.i18nService.get('common.noDataAvailable');
			}
		},

		[TextToken.InstallationAggVersions]: (softwareInstallation: SoftwareInstallationAgg) => {
			try {
				const firstVersionName = softwareInstallation.installedVersions[0];
				const versionsCount = softwareInstallation.installedVersions.length;
				return versionsCount > 1
					? `${firstVersionName} (+${versionsCount - 1} more)`
					: firstVersionName;
			} catch (error) {
				this.i18nService.get('common.noDataAvailable');
			}
		},

		[TextToken.AffectedProduct]: (affectedProduct: VulnerabilityAffectedProduct) => {
			try {
				const array = [
					this.generateProductString(affectedProduct.vendor, affectedProduct.product, null, true),
				];

				if (affectedProduct.productVersion && affectedProduct.productVersion.length) {
					array.push(
						this.i18nService.get('tvm.detectionLogic.version'),
						affectedProduct.productVersion
					);
					return array.join(' ');
				}

				let minVersion = false;
				if (affectedProduct.productMinVersion && affectedProduct.productMinVersion.length) {
					if (affectedProduct.productMinVersion === affectedProduct.productMaxVersion) {
						// this is actually an exact version
						array.push(
							this.i18nService.get('tvm.detectionLogic.version'),
							affectedProduct.productMinVersion
						);
						return array.join(' ');
					}

					minVersion = true;
					const applyToVersion = affectedProduct.productMinVersionIsIncluded
						? this.i18nService.get('tvm.detectionLogic.includingVersion')
						: this.i18nService.get('tvm.detectionLogic.excludingVersion');
					array.push(
						this.i18nService.get('tvm.detectionLogic.versions'),
						affectedProduct.productMinVersion,
						'(' + applyToVersion + ')'
					);
				}
				if (affectedProduct.productMaxVersion && affectedProduct.productMaxVersion.length) {
					const applyToVersion = affectedProduct.productMaxVersionIsIncluded
						? this.i18nService.get('tvm.detectionLogic.includingVersion')
						: this.i18nService.get('tvm.detectionLogic.excludingVersion');
					if (minVersion) {
						array.push(
							this.i18nService.get('tvm.detectionLogic.upToVersion'),
							affectedProduct.productMaxVersion,
							'(' + applyToVersion + ')'
						);
					} else {
						array.push(
							affectedProduct.productMaxVersion,
							'(' + applyToVersion + ')',
							this.i18nService.get('tvm.detectionLogic.noLeftBoundary')
						);
					}

					return array.join(' ');
				}

				return '';
			} catch (error) {
				this.i18nService.get('common.noDataAvailable');
			}
		},

		[TextToken.VulnerabilityOsFeature]: (vulnerability: Vulnerability) => {
			if (!vulnerability.osFeatures || vulnerability.osFeatures.length < 1) {
				return this.i18nService.get('common.noDataAvailable');
			}

			const firstFeature = vulnerability.osFeatures[0];
			return vulnerability.osFeatures.length > 1
				? `${firstFeature} (${this.i18nService.get('tvm.common.plusMore', {
						amount: vulnerability.osFeatures.length - 1,
				  })})`
				: firstFeature;
		},
		[TextToken.RelatedSoftware]: (vulnerability: Vulnerability) =>
			this.getFormattedRelatedSoftware(vulnerability.productIds),
		[TextToken.VulnerabilityAge]: (vulnerability: Vulnerability) =>
			moment.duration(moment(new Date()).diff(moment(vulnerability.published))).humanize(),

		[TextToken.ExposureScoreWidgetInfo]: () =>
			`<p>${this.i18nService.get('tvm.exposureScoreWidget.info.pre')}</p>${this.generateList(
				'tvm.exposureScoreWidget.info.items'
			)}<p>${this.i18nService.get('tvm.exposureScoreWidget.info.post')}</p>`,

		[TextToken.ExposureScoreWidgetInfoAriaLabel]: () =>
			`${this.i18nService.get('tvm.exposureScoreWidget.info.pre')} ${this.generateAriaLabelList(
				'tvm.exposureScoreWidget.info.items'
			)} ${this.i18nService.get('tvm.exposureScoreWidget.info.post')}`,

		[TextToken.ConfigurationScoreWidgetInfo]: () => this.splitAndJoin('tvm.secureScoreWidget.info'),

		[TextToken.ScoreImpactInfo]: () => this.splitAndJoin('tvm.securityRecommendation.impactTooltip'),

		[TextToken.ExposureScoreTooltip]: () =>
			this.splitAndJoin('tvm.securityRecommendation.exposureScoreTooltip'),

		[TextToken.ConfigurationScoreTooltip]: () =>
			this.splitAndJoin('tvm.securityRecommendation.configurationScoreTooltip'),

		[TextToken.ExposureScoreForSoftware]: (data: string) => {
			return this.i18nService.get('tvm.securityRecommendation.exposureScoreSoftwareTooltip', {
				product: data,
			});
		},

		[TextToken.EntityName]: (rawName: string) => this.parseEntityName(rawName),

		[TextToken.EntityFullName]: ([productName, vendorName]) =>
			this.parseFullProductName(productName, vendorName),

		[TextToken.ExploitIconToolTip]: (toolTipInfo: any) => {
			const hasExploitToolTipKey = `tvm.${toolTipInfo.entityType}.exploitAvailableTooltip`;
			const hasDetailedExploitToolTip = `tvm.${toolTipInfo.entityType}.exploitToolTip`;
			const noExploitToolTipKey = `tvm.${toolTipInfo.entityType}.noExploitAvailableTooltip`;
			const exploitLinkedToThreatKey = `tvm.${toolTipInfo.entityType}.exploitLinkedToThreat`;
			const inExploitKitToolTipKey = `tvm.common.exploitInExploitKit`;
			const threatInfo = toolTipInfo.threatInfo;

			const isNewExploitExperienceEnabled = this.featuresService.isEnabled(Feature.TvmExploits);

			return `
			<div style="max-width:350px;">
				<div class="wcd-tooltip__title">Threat insights</div>
				<ul>
				${this.GetExploitToolTip(
					threatInfo.hasExploit && isNewExploitExperienceEnabled,
					threatInfo.isExploitVerified,
					threatInfo.exploitTypes,
					hasExploitToolTipKey,
					hasDetailedExploitToolTip
				)}
				${this.GetNoExploitText(!threatInfo.hasExploit && !threatInfo.isExploitLinkedToThreat, noExploitToolTipKey)}
				${this.GetOldExploit(
					threatInfo.hasExploit && !isNewExploitExperienceEnabled,
					hasExploitToolTipKey // delete this if after TvmExploit feature removal.
				)}
				${this.GetExploitKit(threatInfo.isInExploitKit && isNewExploitExperienceEnabled, inExploitKitToolTipKey)}
				${this.GetThreatDetails(threatInfo.isExploitLinkedToThreat, toolTipInfo.threats, exploitLinkedToThreatKey)}
				</ul></div>
				`;
		},

		[TextToken.ActiveThreatIconTooltip]: (toolTipInfo: any) => {
			let activeThreatBullet = '';
			if (toolTipInfo.isThreatActive) {
				// Show all alerts related to the threats over the past week, with any status.
				// Note that the parameter name is IoaDefinitionIds, but it actually refers to threatId/outbreakId (synonyms)
				let link = '';
				if (toolTipInfo.threats) {
					const threatIds = toolTipInfo.threats.map(t => t.threatId).join('|');
					const filters = `IoaDefinitionIds=${encodeURI(threatIds)}`;
					link = `alertsQueue?filters=${filters}&range=week`;
					activeThreatBullet = this.i18nService.get(
						`tvm.${toolTipInfo.entityType}.threatActiveTooltip`,
						{
							link: link,
						}
					);
				} else {
					activeThreatBullet = this.i18nService.get(
						`tvm.${toolTipInfo.entityType}.threatActiveTooltipNoLink`
					);
				}
			} else {
				activeThreatBullet = this.i18nService.get(
					`tvm.${toolTipInfo.entityType}.noThreatActiveTooltip`
				);
			}
			const tooltipHtml = `
			<div style="max-width:350px;">
				<div class="wcd-tooltip__title">Breach insights</div>
				<ul>
					<li>${activeThreatBullet}</li>
				</ul>
			</div>`;
			return tooltipHtml;
		},

		[TextToken.SecurityRecommendationJustificationNotification]: (
			exceptionJustification: ExceptionJustification
		) => {
			const isScoringRelevant =
				exceptionJustification === ExceptionJustification.ThirdPartyControl ||
				exceptionJustification === ExceptionJustification.AlternateMitigation;
			return isScoringRelevant
				? this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.justificationScore'
				  )
				: undefined;
		},

		[TextToken.NoDataForWidget]: (params: { noDataKey: string; isGroupSelected: boolean }) => {
			let defaultNoData = this.i18nService.get(params.noDataKey);
			if (params.isGroupSelected) {
				defaultNoData += this.i18nService.get('tvm.common.selectedGroups');
			}
			return defaultNoData;
		},

		[TextToken.ScoreWidgetNoData]: (isGroupSelected: boolean) =>
			this.textsMap[TextToken.NoDataForWidget]({
				noDataKey: 'tvm.common.noOnboardedMachines',
				isGroupSelected: isGroupSelected,
			}),

		[TextToken.ReportInaccuracyReasonTitle]: (context: InaccuracyContext) => {
			return this.i18nService.get('tvm.reportInaccuracy.inaccuracyReason', {
				context: this.i18nService.get(this.contextToKey[context]),
			});
		},

		[TextToken.OrgRecommendationProductivityImpact]: (stats: AssetsStatistics) => {
			const countHtml = this.countAndPercentBoldHtml(stats);
			if (countHtml === this._noDataAvailableText) {
				return this._noDataAvailableText;
			}
			const noImpactStr = this.i18nService.strings.tvm_securityRecommendation_userImpact_noImpactOrg;
			const noImpactHtml = this.addBoldHtml(noImpactStr, GREEN_COLOR) + '.';
			const descriptionWithCounter = this.i18nService.get(
				'tvm_securityRecommendation_userImpact_safeMachinesDescription',
				{
					counters: countHtml,
				}
			);
			return `${descriptionWithCounter} ${noImpactHtml}`;
		},

		[TextToken.OrgRecommendationProductivityImpactTooltip]: (stats: AssetsStatistics) => {
			const impactDescription = this.getText(TextToken.OrgRecommendationProductivityImpact, stats);
			return this.createProductivityImpactIconTooltip(impactDescription);
		},

		[TextToken.AssetRecommendationProductivityImpactTooltip]: () => {
			const impactDescription = this.getText(TextToken.AssetRecommendationProductivityImpact, false);
			return this.createProductivityImpactIconTooltip(impactDescription);
		},

		[TextToken.AssetRecommendationProductivityImpact]: (isProductivityImpacted: boolean) => {
			if (isProductivityImpacted === null) {
				this.i18nService.get('common.noDataAvailable');
			}
			const baseKey = 'tvm.securityRecommendation.userImpact';
			let htmlRisk: string, riskKey: string;
			if (isProductivityImpacted) {
				const suffix = this.i18nService.get(`${baseKey}.potentialRisk`);
				htmlRisk = this.addBoldHtml(suffix);
				riskKey = 'assetWithRisk';
			} else {
				const suffix = this.i18nService.get(`${baseKey}.noImpactAsset`);
				htmlRisk = this.addBoldHtml(suffix, GREEN_COLOR);
				riskKey = 'assetWithoutRisk';
			}
			return this.i18nService.get(`${baseKey}.${riskKey}`, { risk: htmlRisk }) + '.';
		},

		[TextToken.RemediationTaskSafeMachines]: (stats: AssetsStatistics) => {
			return this.countAndPercentBoldHtml(stats);
		},

		[TextToken.RemediationTicketStatus]: (remediationDescriptor: RemediationDescriptor) => {
			if (remediationDescriptor.status in (RemediationTicketStateValue || RemediationTaskStateValue)) {
				return this.i18nService.get(
					`tvm.securityRecommendation.relatedRemediation.remediationStatus.${
						remediationDescriptor.status
					}`
				);
			}
			return remediationDescriptor.status;
		},

		[TextToken.ExceptionRemediationType]: (
			recommendationException: RecommendationException | RecommendationExceptionAggregated
		) => {
			const type = recommendationException.exceptionArgs.type;
			const remediationType =
				type === ExceptionType.ConfigurationChange
					? type
					: recommendationException.exceptionArgs.vaRecommendationArgs.recommendationType;
			return this.i18nService.get(`tvm.remediationTask.type.${remediationType}`);
		},

		[TextToken.AggregatedExceptionRequester]: (
			recommendationExceptionAggregated: RecommendationExceptionAggregated
		) => {
			return recommendationExceptionAggregated.requesterCount > 1
				? `${recommendationExceptionAggregated.requesterCount} users`
				: recommendationExceptionAggregated.requester
				? recommendationExceptionAggregated.requester.email
				: this.i18nService.get('notAvailable.short');
		},

		[TextToken.AggregatedExceptionScope]: (
			recommendationExceptionAggregated: RecommendationExceptionAggregated
		) => {
			return recommendationExceptionAggregated.isGlobalException
				? 'Global'
				: `${recommendationExceptionAggregated.rbacGroupIdsCount} device groups`;
		},

		[TextToken.ChangeEventActivity]: (changeEvent: ChangeEvent) => {
			return this.generateChangeEventActivity(changeEvent);
		},

		[TextToken.SoftwareEolOsLegalNote]: (productId: string) => {
			return this.getSoftwareEolOsLegalNoteText(
				productId,
				this.i18nService.strings.tvm_securityRecommendation_notice
			);
		},

		[TextToken.SoftwareEolOsLegalNoteRecommendation]: (productId: string) => {
			return this.getSoftwareEolOsLegalNoteText(
				productId,
				this.i18nService.strings.tvm_securityRecommendation_securityRecommendation
			);
		},

		[TextToken.HasEolVersionsText]: (productName: string) => {
			return this.i18nService.get('tvm.securityRecommendation.hasEolVersionsText', {
				product: this.parseEntityName(productName),
			});
		},

		[TextToken.HasUpcomingEolVersionsText]: (productName: string) => {
			return this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersionsText', {
				product: this.parseEntityName(productName),
			});
		},

		[TextToken.CombinedEolVersionsText]: (productName: string) => {
			return this.i18nService.get('tvm.securityRecommendation.combinedEolVersionsText', {
				product: this.parseEntityName(productName),
			});
		},

		[TextToken.HasEolVersionsMicrosoftLegalNote]: (productId: string) => {
			return this.getHasEolVersionsMicrosoftLegalNoteText(
				productId,
				this.i18nService.get('tvm.securityRecommendation.notice')
			);
		},

		[TextToken.HasEolVersionsMicrosoftLegalNoteRecommendation]: (productId: string) => {
			return this.getHasEolVersionsMicrosoftLegalNoteText(
				productId,
				this.i18nService.strings.tvm_securityRecommendation_securityRecommendation
			);
		},

		[TextToken.LegalNoteLinkText]: (productId: string) => {
			return productId === MicrosoftProductIdsWithLegalNote.SqlServer2012 ||
				productId === MicrosoftProductIdsWithLegalNote.SqlServer2014 ||
				productId === MicrosoftProductIdsWithLegalNote.SqlServer2016
				? this.i18nService.get('tvm.securityRecommendation.legalNoteLinkTextSqlServer')
				: productId === MicrosoftProductIdsWithLegalNote.Windows10
				? this.i18nService.get('tvm.securityRecommendation.legalNoteLinkTextWindows10')
				: '';
		},

		[TextToken.EolVersionKbLegalNote]: (productId: string) =>
			this.i18nService.get('tvm.softwarePage.missingKb.eolText', {
				product: this.parseEntityName(productId),
			}),

		[TextToken.UpcomingEolVersionKbLegalNote]: (productId: string) =>
			this.i18nService.get('tvm.softwarePage.missingKb.upcomingEolText', {
				product: this.parseEntityName(productId),
			}),

		[TextToken.RemediationRelatedComponent]: (remediationTask: RemediationTask) =>
			remediationTask.taskArgs.category === RemediationCategory.SecurityConfiguration
				? remediationTask.relatedComponent
				: this.parseFullProductName(
						remediationTask.taskArgs.softwareArgs.nameId,
						remediationTask.taskArgs.softwareArgs.vendorId
				  ),

		[TextToken.RecommendationExceptionRelatedComponent]: (
			recommendationException: RecommendationException
		) => {
			return recommendationException.exceptionArgs.type === ExceptionType.SoftwarePatch
				? this.parseFullProductNameFromId(
						recommendationException.exceptionArgs.vaRecommendationArgs.productId
				  )
				: '';
		},

		[TextToken.UpgradeLinkText]: (SecurityRecommendation: SecurityRecommendation) => {
			const upgradeTo = this.getUpgradeTo(SecurityRecommendation);
			return upgradeTo !== null
				? this.i18nService.get(
						'tvm.securityRecommendation.descriptionTemplates.upgradeSoftwareToLatestVersionOf',
						{ software: upgradeTo }
				  )
				: this.i18nService.get('tvm.securityRecommendation.descriptionTemplates.readMore');
		},

		[TextToken.EolSoftwareText]: (eolSoftwareSinceDate: Date) =>
			eolSoftwareSinceDate
				? this.i18nService.get('tvm.securityRecommendation.eolTextWithDate', {
						date: this.tzDateService.format(eolSoftwareSinceDate, 'shortDate'),
				  })
				: this.i18nService.strings.tvm_securityRecommendation_eolText,

		[TextToken.UpcomingEolSoftwareText]: (eolSoftwareSinceDate: Date) =>
			eolSoftwareSinceDate
				? this.i18nService.get('tvm.securityRecommendation.upcomingEolTextWithDate', {
						date: this.tzDateService.format(eolSoftwareSinceDate, 'shortDate'),
				  })
				: this.i18nService.strings.tvm_securityRecommendation_upcomingEolText,

		[TextToken.RemediationBlockTitle]: (remediationBlock: RemediationBlock) => {
			const software = this.parseFullProductNameFromId(remediationBlock.id);

			const blockedSoftwareText = this.i18nService.get(
				'tvm.blockedApps.descriptionTemplates.blockSoftware',
				{ software: software }
			);

			return blockedSoftwareText;
		},

		[TextToken.RemediationBlockStatus]: (remediationBlock: RemediationBlock) => {
			return this.i18nService.get(`tvm_blockedApps_status_${remediationBlock.status}`);
		},

		[TextToken.RemediationBlockSoftwareTitle]: (remediationBlock: RemediationBlock) => {
			return this.parseFullProductNameFromId(remediationBlock.id);
		},

		[TextToken.SoftwareNameFromId]: (softwareId: string) => {
			return this.getProductNameAndVendorFromId(softwareId)[1];
		},

		[TextToken.MitigationDescription]: (blockedSince: Date) => {
			return this.i18nService.get('tvm.blockedApps.mitigationDescription', {
				time: this.tzDateService.format(blockedSince, 'shortDate'),
			});
		},

		[TextToken.HighValueAssetDescription]: () => {
			const description = this.i18nService.get(
				'machines.entityDetails.actions.machineValue.description'
			);
			const examplesTitle = this.i18nService.get(
				'machines.entityDetails.actions.machineValue.description.examples.title'
			);
			const examples = this.generateList(
				'machines.entityDetails.actions.machineValue.description.examples'
			);
			return `<p>${description}</p><br><p>${examplesTitle}${examples}</p>`;
		},
		[TextToken.RedHatLegalNotice]: () => {
			const aTagFirst = `<a target="_blank" rel="noopener noreferrer"
				href="https://access.redhat.com/documentation/en-us/red_hat_security_data_api/1.0/html-single/red_hat_security_data_api/index#idm140581130148368">`;
			const aTagSecond =
				'<a target="_blank" rel="noopener noreferrer" href="https://creativecommons.org/licenses/by/4.0/">';
			return `<span>${this.i18nService.strings.common_legal_notice_red_hat_first_link_prefix}
			${aTagFirst}${this.i18nService.strings.common_legal_notice_red_hat_first_link_text}</a>
			${this.i18nService.strings.common_legal_notice_red_hat_second_link_prefix}
			${aTagSecond}${this.i18nService.strings.common_legal_notice_red_hat_second_link_text}</a>
			${this.i18nService.strings.common_legal_notice_red_hat_second_link_suffix}</span>`;
		},
	};

	// TODO: ahsoboh - refactor all of the recommendation context maps to another one map with interface. VSTS 25149723
	readonly recommendationContextToCountHeader: Record<RecommendationContextType, string> = {
		[RecommendationContextType.ContextWithServiceName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.misconfiguredServicesHeader'
		),
		[RecommendationContextType.ContextWithServiceNameAndServicePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.misconfiguredServicesHeader'
		),
		[RecommendationContextType.ContextWithUserName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.misconfiguredUsersHeader'
		),
		[RecommendationContextType.ContextWithShareNameAndSharePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.misconfiguredSharesHeader'
		),
		[RecommendationContextType.Unknown]: this._noDataAvailableText,
	};

	readonly recommendationContextToExposedSectionTitle: Record<RecommendationContextType, string> = {
		[RecommendationContextType.ContextWithServiceName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedServices.title'
		),
		[RecommendationContextType.ContextWithServiceNameAndServicePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedServices.title'
		),
		[RecommendationContextType.ContextWithUserName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedUsers.title'
		),
		[RecommendationContextType.ContextWithShareNameAndSharePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedShares.title'
		),
		[RecommendationContextType.Unknown]: this._noDataAvailableText,
	};

	readonly recommendationContextToExposedSectionLoadingTitle: Record<RecommendationContextType, string> = {
		[RecommendationContextType.ContextWithServiceName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedServices.loadingTitle'
		),
		[RecommendationContextType.ContextWithServiceNameAndServicePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedServices.loadingTitle'
		),
		[RecommendationContextType.ContextWithUserName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedUsers.loadingTitle'
		),
		[RecommendationContextType.ContextWithShareNameAndSharePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.exposedShares.loadingTitle'
		),
		[RecommendationContextType.Unknown]: this._noDataAvailableText,
	};

	readonly recommendationContextToContextKeyPaneLoadingTitle: Record<RecommendationContextType, string> = {
		[RecommendationContextType.ContextWithServiceName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.servicePane.loadingTitle'
		),
		[RecommendationContextType.ContextWithServiceNameAndServicePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.servicePane.loadingTitle'
		),
		[RecommendationContextType.ContextWithUserName]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.userPane.loadingTitle'
		),
		[RecommendationContextType.ContextWithShareNameAndSharePath]: this.i18nService.get(
			'tvm.securityRecommendation.recommendationContext.sharePane.loadingTitle'
		),
		[RecommendationContextType.Unknown]: this._noDataAvailableText,
	};

	private readonly contextToKey: Record<InaccuracyContext, string> = {
		[InaccuracyContext.MachineScaRecommendation]: 'tvm.reportInaccuracy.contexts.recommendations',
		[InaccuracyContext.MachineVaRecommendation]: 'tvm.reportInaccuracy.contexts.recommendations',
		[InaccuracyContext.OrgScaRecommendation]: 'tvm.reportInaccuracy.contexts.recommendations',
		[InaccuracyContext.OrgVaRecommendation]: 'tvm.reportInaccuracy.contexts.recommendations',
		[InaccuracyContext.MachineInventory]: 'tvm.reportInaccuracy.contexts.inventory',
		[InaccuracyContext.OrgInventory]: 'tvm.reportInaccuracy.contexts.inventory',
		[InaccuracyContext.MachineVulnerabilities]: 'tvm.reportInaccuracy.contexts.vulnerabilities',
		[InaccuracyContext.OrgVulnerabilities]: 'tvm.reportInaccuracy.contexts.vulnerabilities',
	};

	private countAndPercentBoldHtml(stats: AssetsStatistics): string {
		const nonImpactedAssets = stats.nonProductivityImpactedAssets;
		if (!nonImpactedAssets || nonImpactedAssets > stats.assetsAtRiskCount) {
			return this._noDataAvailableText;
		}
		const prettyCounter = this.prettyNumberService.prettyNumber(nonImpactedAssets);
		const percentage = Math.round((nonImpactedAssets / stats.assetsAtRiskCount) * 100);

		const rawCounters = `${prettyCounter} (${percentage}%)`;
		return this.addBoldHtml(rawCounters, GREEN_COLOR);
	}

	private addBoldHtml(s: string, color: string = 'ms-color-black'): string {
		return `<span class="wcd-font-weight-semibold ${color}">${s}</span>`;
	}

	private createProductivityImpactIconTooltip(description: string) {
		if (description === this._noDataAvailableText) {
			return this._noDataAvailableText;
		}
		const title = this.i18nService.get('tvm.securityRecommendation.configurationChange.userImpact');
		return `<h5 class=wcd-font-weight-semibold>${title}</h5>${description}`;
	}

	private contextToReasons: Record<InaccuracyContext, Array<string>>;

	public readonly exploitTypesToText: Record<string, string> = {
		Remote: this.i18nService.get('tvm.common.remoteExploit'),
		WebApps: this.i18nService.get('tvm.common.webappExploit'),
		Local: this.i18nService.get('tvm.common.localExploit'),
		Dos: this.i18nService.get('tvm.common.dosExploit'),
	};

	setContextToReasonsMap() {
		const recommendationsBaseList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.alreadyFixed'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.missingActions'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.wrongCallForAction'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.details'),
			this._otherInaccuracyReason,
		];

		const vaRecommendationsList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.recommendations.wrongVersion'),
			...recommendationsBaseList,
		];

		const vulnerabilitiesBaseList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.details'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.missingExploit'),
			this._otherInaccuracyReason,
		];

		const orgVulnerabilitiesList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.noAffectOrg'),
			...vulnerabilitiesBaseList,
		];

		const machineVulnerabilitiesList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.vulnerabilities.noAffectMachine'),
			...vulnerabilitiesBaseList,
		];

		const inventoryBaseList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.details'),
			this._otherInaccuracyReason,
		];

		const orgInventoryList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExistInOrg'),
			...inventoryBaseList,
		];

		const machineInventoryList = [
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.wrongVersion'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExist'),
			...inventoryBaseList,
		];

		this.contextToReasons = {
			[InaccuracyContext.MachineScaRecommendation]: recommendationsBaseList,
			[InaccuracyContext.MachineVaRecommendation]: vaRecommendationsList,
			[InaccuracyContext.MachineInventory]: machineInventoryList,
			[InaccuracyContext.MachineVulnerabilities]: machineVulnerabilitiesList,
			[InaccuracyContext.OrgScaRecommendation]: recommendationsBaseList,
			[InaccuracyContext.OrgVaRecommendation]: vaRecommendationsList,
			[InaccuracyContext.OrgInventory]: orgInventoryList,
			[InaccuracyContext.OrgVulnerabilities]: orgVulnerabilitiesList,
		};
	}

	public readonly productivityImpactToText: Record<ProductivityImpactRemediationType, string> = {
		[ProductivityImpactRemediationType.NonImpactedAssets]: this.i18nService.get(
			'tvm.securityRecommendation.remediationTaskCreation.productivityImpactSelection.safeAssets'
		),
		[ProductivityImpactRemediationType.AllExposedAssets]: this.i18nService.get(
			'tvm.securityRecommendation.remediationTaskCreation.productivityImpactSelection.allAssets'
		),
	};

	getFormattedRelatedSoftware(products: string[]): string {
		try {
			const firstProductName = this.parseEntityName(products[0].split('-_-')[1]);
			return products.length > 1
				? `${firstProductName} (+${products.length - 1} more)`
				: firstProductName;
		} catch (error) {
			this.i18nService.get('common.noDataAvailable');
		}
	}

	getFormattedRelatedSoftwareTooltip(products: string[]): string {
		try {
			return `<div>
				${products
					.map(id => this.parseEntityName(id.split('-_-')[1]))
					.reduce((prev, curr) => `${prev}<div>${curr}</div>`, '')}
			</div>
		`;
		} catch (error) {
			return this.i18nService.get('common.noDataAvailable');
		}
	}

	getSelectedProductivityRemediation(impactType: ProductivityImpactRemediationType) {
		const impactStr = this.productivityImpactToText[impactType];

		return impactType === ProductivityImpactRemediationType.AllExposedAssets
			? this.addBoldHtml(impactStr)
			: this.addBoldHtml(impactStr, GREEN_COLOR);
	}

	getOsPlatformLabel(platformId: string): string {
		if (!platformId) return '';
		return operatingSystemPlatformValues.find(os => os.id === platformId).name;
	}

	hasOsPlatformLabel(platformId: string): boolean {
		return operatingSystemPlatformValues.map(({ id }) => id).includes(platformId);
	}

	getBaselineBenchmarkLabel(benchmarkId: string): string {
		if (!benchmarkId) return '';
		const benchmark = baselineBaseBenchmarkValues.find(benchmark => benchmark.id === benchmarkId);
		return benchmark ? benchmark.name : undefined;
	}

	getOsProductName(platformId: string): string {
		return this.osNameToProductId[platformId];
	}

	getCountedProductivityImpactRemediations(
		stats: AssetsStatistics
	): Record<ProductivityImpactRemediationType, string> {
		const nonImpactedAssets = stats.nonProductivityImpactedAssets;
		if (!stats || !nonImpactedAssets || nonImpactedAssets > stats.assetsAtRiskCount) {
			return null;
		}
		const safeCount = ` (${this.prettyNumberService.prettyNumber(stats.nonProductivityImpactedAssets)})`;
		const allExposedCount = ` (${this.prettyNumberService.prettyNumber(stats.assetsAtRiskCount)})`;

		const safeTxt = this.productivityImpactToText[ProductivityImpactRemediationType.NonImpactedAssets];
		const allText = this.productivityImpactToText[ProductivityImpactRemediationType.AllExposedAssets];

		return {
			[ProductivityImpactRemediationType.NonImpactedAssets]: safeTxt + safeCount,
			[ProductivityImpactRemediationType.AllExposedAssets]: allText + allExposedCount,
		};
	}

	private getMostSevereExploitType = (types: ExploitType[]) => {
		if (types.includes('Remote')) {
			return this.exploitTypesToText['Remote'];
		} else if (types.includes('WebApps')) {
			return this.exploitTypesToText['WebApps'];
		} else if (types.includes('Local')) {
			return this.exploitTypesToText['Local'];
		} else if (types.includes('Dos')) {
			return this.exploitTypesToText['Dos'];
		} else {
			return '';
		}
	};

	private generateChangeEventActivity(changeEvent: ChangeEvent): string {
		if (!changeEvent) {
			return this._noDataAvailableText;
		}

		const isSingular = changeEvent.cvesList.length === 1;
		const wasValue = isSingular ? 'was' : 'were';
		const vulnerabilityValue = isSingular ? 'vulnerability' : 'vulnerabilities';
		const eventHasAssetsCount = changeEvent.assetsCount !== null && changeEvent.assetsCount !== undefined;
		const currentlyToken = eventHasAssetsCount ? '' : ' currently';
		const machineCount = this.prettyNumberService.prettyNumber(
			eventHasAssetsCount ? changeEvent.assetsCount : changeEvent.currentAssetsCount
		);
		const machine = changeEvent.assetsCount === 1 ? 'device' : 'devices';
		const cveNum = changeEvent.cvesList.length;
		const aValue = cveNum === 1 ? 'a' : cveNum.toString();
		const productName = this.parseFullProductName(changeEvent.productName, changeEvent.vendor);
		const exploit = isSingular ? 'An exploit' : 'Exploits';

		switch (changeEvent.eventType) {
			case VulnerabilityChangeEventType.NewCve:
				return `${productName} has ${aValue} new ${vulnerabilityValue},${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit:
				return `${cveNum} ${vulnerabilityValue} in ${productName} became exploitable,${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasVerifiedExploit:
				return `${exploit} for ${cveNum} ${vulnerabilityValue} in ${productName} became verified,${currentlyToken} impacting ${machineCount} ${machine}`;
			case VulnerabilityChangeEventType.CveHasExploitInKit:
				return `${exploit} for ${cveNum} ${vulnerabilityValue} in ${productName} ${wasValue} added to an exploit kit,${currentlyToken} impacting ${machineCount} ${machine}`;
			default:
				return this._noDataAvailableText;
		}
	}

	private GetExploitToolTip(
		shouldDisplayExploitExperience: boolean,
		isExploitVerified: boolean,
		exploitTypes: ExploitType[],
		hasExploitToolTipKey: string,
		hasDetailedExploitToolTip: string
	) {
		if (!shouldDisplayExploitExperience) {
			return '';
		}

		const exploitToolTipText =
			!isExploitVerified && exploitTypes.length === 0 // should check if toolTipInfo.exploitTypes exists?
				? this.i18nService.get(hasExploitToolTipKey)
				: this.i18nService.get(hasDetailedExploitToolTip, {
						verified: isExploitVerified ? 'verified' : '',
						exploitType: this.getMostSevereExploitType(exploitTypes).toLocaleLowerCase(),
				  });
		return `<li>${exploitToolTipText}</li>`;
	}

	private GetNoExploitText(shouldDisplayNoExploitText: boolean, noExploitToolTipKey: string) {
		if (!shouldDisplayNoExploitText) {
			return '';
		}

		return `<li>${this.i18nService.get(noExploitToolTipKey)}</li>`;
	}

	private GetOldExploit(shouldDisplayExploit: boolean, hasExploitToolTipKey: string) {
		// delete this if after TvmExploit feature removal.
		if (!shouldDisplayExploit) {
			return '';
		}

		return `<li>${this.i18nService.get(hasExploitToolTipKey)}</li>`;
	}

	private GetExploitKit(isInExploitKit: boolean, inExploitKitToolTipKey: string) {
		if (!isInExploitKit) {
			return '';
		}

		return `<li>${this.i18nService.get(inExploitKitToolTipKey)}</li>`;
	}

	private GetThreatDetails(
		isExploitLinkedToThreat: boolean,
		threats: any,
		exploitLinkedToThreatKey: string
	) {
		if (!isExploitLinkedToThreat) {
			return '';
		}

		let exploit = `<li>${this.i18nService.get(exploitLinkedToThreatKey)}${threats ? ':' : '.'}</li>`;
		if (threats) {
			// Create an bullet item for each threat
			const threatLinks = threats.map(
				t => `<li><a href="threatanalytics3/${t.threatId}">${t.threatName}</a></li></li>`
			);
			exploit += `<ul style="list-style: none; margin: 0; padding: 0;">${threatLinks.join('')}</ul>`;
		}

		return exploit;
	}

	private generateProductString = (
		vendor: string,
		productName: string,
		version?: string,
		handleVendorProductDuplication?: boolean
	) => {
		const array = [this.parseEntityName(vendor)];
		// In cases like python:python, we don't want the double name
		if (handleVendorProductDuplication && vendor !== productName) {
			array.push(this.parseEntityName(productName));
		}

		if (version) {
			array.push(version);
		}

		return array.join(' ');
	};

	/**
	 * replacer for the domain's deprecated parseEntityName
	 */
	// TODO: consider using 'parseFullProductName' when using this to parse product names
	private parseEntityName = (rawName: string, separator: string = '_') => {
		return rawName
			.split(separator)
			.map(s => s.charAt(0).toUpperCase() + s.substring(1))
			.join(' ')
			.replace(' For Mac', ' for Mac')
			.replace(' For Linux', ' for Linux'); // TODO: clean this up once we have OS Kind column (Task: 26044745)
	};

	public parseFullProductName = (rawProductName: string, rawVendorName: string) => {
		const productName = this.parseEntityName(rawProductName);
		const regex = new RegExp('_|-', 'g');
		const noSpacesProdName = rawProductName.split(regex).join('');
		const noSpacesVendorName = rawVendorName.split(regex).join('');
		if (
			rawProductName === rawVendorName ||
			this.vendorAndProductNamesBlacklist[rawVendorName] === rawProductName ||
			noSpacesProdName.startsWith(noSpacesVendorName) ||
			noSpacesVendorName.startsWith(noSpacesProdName)
		) {
			return productName;
		}

		return `${this.parseEntityName(rawVendorName)} ${productName}`;
	};

	private parseFullProductNameFromId = (productId: string) => {
		const [vendor, name] = this.getProductNameAndVendorFromId(productId);
		return this.parseFullProductName(name, vendor);
	};

	private splitAndJoin = (token: string) =>
		this.i18nService
			.get(token)
			.split('\n')
			.join('<br/>');

	private generateList = (itemsToken: string): string => {
		const items = this.i18nService.get(itemsToken).split('<item>');
		items.shift();
		return this.generateListFromArray(items);
	};

	private generateListFromArray = (items: Array<string>): string => {
		if (!items.length) return '';
		return `<ul>${items.reduce((acc: string, curr: string) => `${acc}<li>${curr}</li>`, '')}</ul>`;
	};

	private generateAriaLabelList = (itemsToken: string): string => {
		const items = this.i18nService.get(itemsToken).split('<item>');
		return items.shift().toString();
	};

	getText(textToken: TextToken, data?: any): string {
		return this.textsMap[textToken](data) || '';
	}

	// note: this functionality is being used by Threat Analytics as well (for SCID's integration).
	getRelatedComponentName(
		category: RecommendationCategory,
		remediationType: RemediationType,
		productName?: string,
		vendor?: string,
		subCategory?: string
	): string {
		if (category === RecommendationCategory.NetworkGear) {
			return this.i18nService.get('tvm.common.authenticatedScanAssessment');
		}

		// TODO: Remove parse function when related software creation logic moves to backend
		if (remediationType !== RemediationType.ConfigurationChange) {
			return this.parseFullProductName(productName, vendor);
		}

		const subCategoryVal = subCategory ? ' (' + this.parseEntityName(subCategory) + ')' : '';
		let categoryVal = category.toString();
		if (categoryVal == 'OS') {
			categoryVal = this.i18nService.get('tvm.common.operatingSystem');
		}

		return `${categoryVal}${subCategoryVal}`;
	}

	getInaccuracyReasons(context: InaccuracyContext): Array<string> {
		return this.contextToReasons[context] || [''];
	}

	getSoftwareEolOsLegalNoteText(productId: string, recommendationOrNotice: string) {
		return productId === MicrosoftProductIdsWithLegalNote.Windows7
			? this.i18nService.get('tvm.securityRecommendation.eolLegalNote.windows7', {
					recommendationOrNotice: recommendationOrNotice,
			  })
			: productId === MicrosoftProductIdsWithLegalNote.WindowsServer2008
			? this.i18nService.get('tvm.securityRecommendation.eolLegalNote.windowsServer2008', {
					recommendationOrNotice: recommendationOrNotice,
			  })
			: '';
	}

	getHasEolVersionsMicrosoftLegalNoteText(productId: string, recommendationOrNotice: string) {
		return productId === MicrosoftProductIdsWithLegalNote.Windows10
			? this.i18nService.get('tvm.securityRecommendation.hasEolVersionsLegalNote.windows10', {
					recommendationOrNotice: recommendationOrNotice,
			  })
			: productId === MicrosoftProductIdsWithLegalNote.SqlServer2012 ||
			  productId === MicrosoftProductIdsWithLegalNote.SqlServer2014 ||
			  productId === MicrosoftProductIdsWithLegalNote.SqlServer2016
			? this.i18nService.get('tvm.securityRecommendation.hasEolVersionsLegalNote.sqlServer', {
					recommendationOrNotice: recommendationOrNotice,
			  })
			: '';
	}

	getUpgradeTo(securityRecommendation: SecurityRecommendation) {
		if (securityRecommendation.recommendedVendor && securityRecommendation.recommendedProgram) {
			return this.parseFullProductName(
				securityRecommendation.recommendedProgram,
				securityRecommendation.recommendedVendor
			);
		}
		return null;
	}

	get noDataAvailableToken() {
		return this._noDataAvailableText;
	}

	private osNameToProductId: Record<string, string> = {
		Windows10: 'microsoft-_-windows_10',
		WindowsServer2019: 'microsoft-_-windows_server_2019',
		WindowsServer2016: 'microsoft-_-windows_server_2016',
		WindowsServer2012R2: 'microsoft-_-windows_server_2012_r2',
		WindowsServer2008R2: 'microsoft-_-windows_server_2008_r2',
		macOS: 'apple-_-mac_os',
		// Add here more operating system when we support them(use names from the enum: OperatingSystemPlatformCategories)
	};

	// if a pair is in this blacklist, we display only the product name
	private vendorAndProductNamesBlacklist: Record<string, string> = {
		coupons: 'coupon_printer_for_windows',
		notepad_plus_plus: 'notepad++',
	};

	private getProductNameAndVendorFromId = (productId: string): [string, string] => {
		const productIdSplit = productId.split('-_-');
		return [productIdSplit[0], productIdSplit[1]];
	};

	private getRecommendationZeroDayDescription(
		securityRecommendation: SecurityRecommendation,
		softwareName: string,
		isEol: boolean,
		hasEolVersions: boolean,
		hasUpcomingEolVersions: boolean,
		noHtmlTags: boolean
	): string {
		const isOnlyZeroDay = securityRecommendation.weaknessesCount === 1;
		const otherVulnerabilitiesCount = securityRecommendation.weaknessesCount - 1;
		const breakLineTag = noHtmlTags ? '' : '<br><br>';
		let zeroDayDescriptionFirstPart = isOnlyZeroDay
			? this.i18nService.strings
					.tvm_securityRecommendation_descriptionTemplates_zeroDay_single_vulnerability
			: this.i18nService.get('tvm_securityRecommendation_descriptionTemplates_zeroDay', {
					otherVulnerabilitiesCount,
			  });
		let zeroDayDescriptionSecondPart = noHtmlTags
			? this.i18nService.strings
					.tvm_securityRecommendation_descriptionTemplates_zeroDay_secondPart_without_link
			: this.i18nService.strings
					.tvm_securityRecommendation_descriptionTemplates_zeroDay_secondPart_with_link;
		let eolNote: string;
		let link = noHtmlTags
			? ''
			: `<a href="/vulnerabilities?search=zero%20day">${
					this.i18nService.strings.tvm_securityRecommendation_descriptionTemplates_zeroDay_linkText
			  }</a>`;

		if (isEol) {
			link = '';
			zeroDayDescriptionSecondPart = '';
			const recommendationType = this.i18nService.get(
				`tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_recommendationType_${
					securityRecommendation.remediationType
				}`
			);
			zeroDayDescriptionFirstPart = isOnlyZeroDay
				? this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_firstPart_single_vulnerability',
						{
							recommendationType,
							softwareName,
						}
				  )
				: this.i18nService.get(
						'tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_firstPart',
						{
							recommendationType,
							softwareName,
							otherVulnerabilitiesCount,
						}
				  );
			eolNote = this.i18nService.get(
				'tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_software_note',
				{
					recommendationType,
				}
			);
		} else if (hasEolVersions && hasUpcomingEolVersions) {
			eolNote = this.i18nService.strings
				.tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_version_upcoming_eos_version_note;
		} else if (hasEolVersions) {
			eolNote = this.i18nService.strings
				.tvm_securityRecommendation_descriptionTemplates_zeroDay_eos_version_note;
		} else if (hasUpcomingEolVersions) {
			eolNote = this.i18nService.strings
				.tvm_securityRecommendation_descriptionTemplates_zeroDay_upcoming_eos_version_note;
		}

		eolNote = eolNote ? `${breakLineTag}${eolNote}` : '';
		return `${zeroDayDescriptionFirstPart}${zeroDayDescriptionSecondPart} ${link}${eolNote}`;
	}
}
