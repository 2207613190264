import { NgModule, ModuleWithProviders } from '@angular/core';
import { IncidentEntityTypeService } from './services/incident.entity-type.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { IncidentsEntityPanelComponent } from './components/incidents.entity-panel.component';
import { IncidentEntityPanelComponent } from './components/incident.entity-panel.component';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../shared/shared.module';
import { IncidentAlertsComponent } from './components/incident.alerts.component';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { IncidentUsersComponent } from './components/incident.users.component';
import { IncidentSetClassificationComponent } from './components/incident-set-classification.component';
import { IncidentInvestigationsComponent } from './components/incident.investigation.component';
import { IncidentUpdateStatusDialogComponent } from './components/incident-update-status-dialog.component';
import { IncidentsService } from './services/incidents.service';
import { CommentsModule } from '../../comments/comments.module';
import { IncidentCommentsComponent } from './components/incident-comments.component';
import { IncidentGraphNodeEntityTypeService } from './services/incident-graph-node.entity-type.service';
import { IncidentGraphNodeEntityPanelComponent } from './components/incident-graph/incident-graph-node.entity-panel.component';
import {
	FabSpinnerModule,
	FabIconModule,
	FabTagPickerModule,
	FabMessageBarModule,
	FabDropdownModule,
} from '@angular-react/fabric';
import { ConfigModule } from '@wcd/config';
import { DataTableModule } from '@wcd/datatable';
import { ImpactedEntitiesModule } from '../../impacted-entities/impacted-entities.module';
import { BarsChartModule, LegendModule } from '@wcd/charts';
import { PanelsModule } from '@wcd/panels';
import { IncidentsBackendService } from './services/Incidents.backend.service';
import { IncidentsFiltersService } from './services/incidents.filters.service';
import { IncidentGraphService } from './services/incident-graph.service';
import { IncidentManagePanelComponent } from './components/incident.manage-panel.component';
import { TagsModule } from '../../tags/tags.module';
import { A11yModule } from '@angular/cdk/a11y';
import { IncidentAlertsPanelComponent } from './components/incident.alerts.panel.component';
import { EntityTagsService } from '../common/services/entity-tags.service';
import { MteRecommendedPanelComponent } from './components/widgets/mte-recommended.panel.component';
import { MteAdvancedQueriesPanelComponent } from './components/widgets/mte-advanced-queries.panel.component';
import { IncidentAppsComponent } from './components/incident.apps.component';
import { IncidentDisruptBannerComponent } from './components/incident.disrupt.banner.component';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		CommentsModule,
		FabSpinnerModule,
		FabIconModule,
		BarsChartModule,
		LegendModule,
		ConfigModule,
		DataTableModule,
		ImpactedEntitiesModule,
		PanelsModule,
		TagsModule,
		FabTagPickerModule,
		FabMessageBarModule,
		A11yModule,
		FabDropdownModule,
	],
	declarations: [
		IncidentEntityPanelComponent,
		IncidentGraphNodeEntityPanelComponent,
		IncidentsEntityPanelComponent,
		IncidentAlertsComponent,
		IncidentUsersComponent,
		IncidentSetClassificationComponent,
		IncidentInvestigationsComponent,
		IncidentUpdateStatusDialogComponent,
		IncidentCommentsComponent,
		IncidentManagePanelComponent,
		IncidentAlertsPanelComponent,
		MteRecommendedPanelComponent,
		MteAdvancedQueriesPanelComponent,
		IncidentAppsComponent,
		IncidentDisruptBannerComponent,
	],
	exports: [
		IncidentAlertsComponent,
		IncidentUsersComponent,
		IncidentInvestigationsComponent,
		IncidentDisruptBannerComponent,
	],
	entryComponents: [
		IncidentEntityPanelComponent,
		IncidentsEntityPanelComponent,
		IncidentGraphNodeEntityPanelComponent,
		IncidentSetClassificationComponent,
		IncidentUpdateStatusDialogComponent,
		IncidentCommentsComponent,
		IncidentManagePanelComponent,
		IncidentAlertsPanelComponent,
		MteRecommendedPanelComponent,
		MteAdvancedQueriesPanelComponent,
		IncidentAppsComponent,
	],
})
export class IncidentEntityTypeModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: IncidentEntityTypeModule,
			providers: [
				IncidentEntityTypeService,
				IncidentsService,
				IncidentGraphNodeEntityTypeService,
				IncidentsBackendService,
				IncidentsFiltersService,
				IncidentGraphService,
				EntityTagsService,
			],
		};
	}

	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(IncidentEntityTypeService);
		globalEntityTypesService.registerEntityType(IncidentGraphNodeEntityTypeService);
	}
}
