import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('healthStatusCategory', healthStatusCategoryFilter);
healthStatusCategoryFilter.$inject = ['$log'];

function healthStatusCategoryFilter($log) {
	return function(input) {
		if (!input) {
			return '';
		}

		if (input.indexOf('Inactive', 0) === 0) {
			return 'Inactive';
		}

		if (input.indexOf('Misconfigured', 0) === 0) {
			return 'Misconfigured';
		}

		if (input.indexOf('Active', 0) === 0) {
			return 'Active';
		}

		$log.error('Unknown health status: ' + input);
		return '';
	};
}
