import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CustomTiIndicatorsType } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';

@Component({
	selector: 'custom-ti-indicator-import-panel',
	template: `
		<wcd-panel [settings]="settings">
			<custom-ti-indicators-import
				[customTiIndicatorType]="customTiIndicatorType"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(done)="onDone()"
				(import)="onImport($event)"
			>
			</custom-ti-indicators-import>
		</wcd-panel>
	`,
})
export class CustomTiIndicatorImportPanelComponent extends PanelContainer {
	@Input() customTiIndicatorType: CustomTiIndicatorsType;

	@Output() done: EventEmitter<boolean> = new EventEmitter<boolean>();

	private _importSucceeded: boolean = false;

	constructor(protected router: Router) {
		super(router);
	}

	onImport(importSucceeded: boolean) {
		this._importSucceeded = this._importSucceeded || importSucceeded;
	}

	onDone() {
		this.done.emit(this._importSucceeded);
		this.closePanel();
	}
}
