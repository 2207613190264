/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Injector,
	OnInit,
	ChangeDetectorRef,
} from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import {
	SecurityRecommendation,
	RemediationType,
	RecommendationInstalledAssetsRelationship,
	Asset,
	RecommendationContextType,
	RecommendationRelatedVulnerabilitiesRelationship,
	Vulnerability,
	RecommendatioMitigatedVulnerabilitiesRelationship,
	MitigatedVulnerability,
	RecommendationExposedAssetsRelationship,
	SoftwareInstallationAgg,
	TvmEndPoint,
	ProductivityImpactRemediationType,
	AssetsStatistics,
	RecommendationCategory,
	ExposedOperatingSystemsStatistics,
	RecommendationExposedOperatingSystemsRelationship,
	RecommendationContextKeyAndExposedMachinesCount,
	RecommendationContextKeyAndExposedMachinesCountRelationship,
	AssetRecommendationContext,
	AssetRecommendationContextRelationship,
	AssetRecommendation,
	ShouldShowLegalNoteFor,
	MicrosoftProductIdsWithLegalNote,
	LegalNoteLinks,
	EolState,
	VulnerabilityType,
	NetworkDevice,
	RecommendationExposedNetworkDeviceRelationship,
	SoftwareInstallationNetworkDevice,
	RecommendationInstalledNetworkDevicesRelationship,
	TvmRecommendationOnboardingStatus,
} from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris, RelationshipRepository, DataQuery, DataSet } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { FeaturesService, Feature } from '@wcd/config';
import { SpinnerSize } from 'office-ui-fabric-react';
import { SecurityRecommendationDetailsOptions } from '../../../@entities/@tvm/security-recommendations/components/security-recommendation.entity-panel.component';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { I18nService } from '@wcd/i18n';
import { TvmContextOptions } from '../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { SecurityRecommendationService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.service';
import { VulnerabilityService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { Router } from '@angular/router';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { TvmRecommendationContextService } from '../../../tvm/services/tvm-recommendation-context.service';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { ExposedOperatingSystemsComponent } from '../../../tvm/components/tooltips/exposed-operating-systems/exposed-operating-systems.component';
import { MessageBarType, IMessageBarStyleProps } from 'office-ui-fabric-react';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';
import { RemediationItsmToolsService } from '../../../@entities/@tvm/remediation/remediation-tasks/services/remediation-itsm-tools-service';
import { Tabs } from '../../../@entities/@tvm/security-recommendations/components/remediation-task-creation.component';
import { TzDateService } from '@wcd/localization';

declare const moment: typeof import('moment');

@Component({
	selector: 'security-recommendation-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './security-recommendation.entity-details.component.html',
	styleUrls: ['./security-recommendation.entity-details.component.scss'],
})
export class SecurityRecommendationEntityDetailsComponent
	extends EntityDetailsComponentBase<SecurityRecommendation>
	implements OnInit {
	readonly MessageBarType = MessageBarType;
	readonly MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;
	configurationTooltip: string;
	exposureTooltip: string;
	SpinnerSize = SpinnerSize;
	isAssetsMinimized = true;
	isCvesMinimized = true;
	remediationType = RemediationType;
	explanationText: string;
	exceptionLink: string;
	userImpactDescription: string;
	eolText: string;
	_messageBarStyles = MessageBarStyles;
	isRequiredAttentionEnabled: boolean;
	isExposedOperatingSystem: boolean;
	shouldShowLegalNote: boolean;
	isConfigurationChange: boolean;
	legalNote: string;
	showUpgradeLink: boolean;
	showProductivityImpactDescription: boolean;
	recommendationContextType = RecommendationContextType.Unknown;
	unknownRecommendationContextType = RecommendationContextType.Unknown;
	recommendationContextExposedMachinesTitle: string;
	recommendationContextExposedMachinesLoadingText: string;
	isRecommendationContextNeededInCSV = false;
	zeroDayFeatureEnabled: boolean;
	isZeroDayRecommendation: boolean;
	shouldShowWorkaround: boolean;
	newPatchMessage: string;
	isSingleVulnerability: boolean;

	recommendationRelatedComponent: string;
	recommendationDescription: string;
	assetRelationshipRepository: RelationshipRepository<SecurityRecommendation, Asset>;
	networkDeviceRelationshipRepository: RelationshipRepository<SecurityRecommendation, NetworkDevice>;
	installationNetworkDeviceRelationshipRepository: RelationshipRepository<
		SecurityRecommendation,
		SoftwareInstallationNetworkDevice
	>;
	installationRelationshipRepository: RelationshipRepository<
		SecurityRecommendation,
		SoftwareInstallationAgg
	>;
	vulnerabilitiesRelationshipRepository: RelationshipRepository<SecurityRecommendation, Vulnerability>;
	recommendationContextRelationshipRepository: RelationshipRepository<
		SecurityRecommendation,
		RecommendationContextKeyAndExposedMachinesCount
	>;
	assetRecommendationContextRelationshipRepository: RelationshipRepository<
		AssetRecommendation,
		AssetRecommendationContext
	>;
	mitigatedVulnerabilitiesRepository: RelationshipRepository<
		SecurityRecommendation,
		MitigatedVulnerability
	>;
	exposedOperatingSystemsStatisticsRepository: RelationshipRepository<
		SecurityRecommendation,
		ExposedOperatingSystemsStatistics
	>;

	isSingleAssetRecommendation: boolean;
	assetId: string;
	assets$: Observable<DataSet<Asset>>;
	installations$: Observable<DataSet<SoftwareInstallationAgg>>;
	vulnerabilities$: Observable<DataSet<Vulnerability>>;
	mitigatedVulnerabilities$: Observable<DataSet<MitigatedVulnerability>>;
	exposedOperatingSystemsStatistics$: Observable<DataSet<ExposedOperatingSystemsStatistics>>;

	_recommendationOptions: SecurityRecommendationDetailsOptions;
	showEolMessage: boolean;
	softwareVersionsLinkText: string;
	exposedAssetsTitle: string;
	exposedAssetsLoadingText: string;
	installedAssetsTitle: string;
	installedAssetsLoadingText: string;
	isNetworkDevice = false;
	includeLastSeenStatus: boolean;
	isNetworkGearCve: boolean;
	recommendationStatus: string;

	workaroundsMainText: string;

	messageBarState = {
		showBody: false,
	};

	ExposedOperatingSystemsComponent = ExposedOperatingSystemsComponent;
	upgradeLinkText: string;
	upgradeLink: string;
	legalNoteLinkText: string;
	legalNoteLink: string;
	showLegalNoteLink: boolean;
	messageBarType: MessageBarType;
	widgetEosTooltip: string;
	isEol: boolean;
	isUpcomingEol: boolean;
	hasWeaknesses: boolean;
	private windowsDescriptionLinks: Record<string, string> = {
		[MicrosoftProductIdsWithLegalNote.Windows7]:
			'https://www.microsoft.com/en-us/microsoft-365/windows/end-of-windows-7-support',
		[MicrosoftProductIdsWithLegalNote.WindowsServer2008]:
			'https://docs.microsoft.com/en-us/windows-server/get-started/modernize-windows-server-2008',
	};

	@Input()
	set recommendationOptions(options: SecurityRecommendationDetailsOptions) {
		this._recommendationOptions = options;
		this.isSingleAssetRecommendation = options && options.singleAssetRecommendation;
		if (options) {
			this.assetId = options.machineId;
		}
	}
	get recommendationOptions(): SecurityRecommendationDetailsOptions {
		return this._recommendationOptions;
	}

	private _recommendation: SecurityRecommendation;

	@Input()
	set recommendation(recommendation: SecurityRecommendation) {
		this._recommendation = recommendation;
		this.isEol = this.recommendation.eolSoftwareState === EolState.AlreadyEOL;
		this.isUpcomingEol = this.recommendation.eolSoftwareState === EolState.UpcomingEOL;
		this.isConfigurationChange = recommendation.remediationType === RemediationType.ConfigurationChange;

		this.loadNewAssets(recommendation);
		this.loadNewInstallations(recommendation);

		if (this.isConfigurationChange) {
			this.mitigatedVulnerabilitiesRepository.sourceItem = recommendation;
		} else {
			this.vulnerabilitiesRelationshipRepository.sourceItem = recommendation;
		}

		if (this.isExposedOperatingSystem) {
			this.loadExposedOperatingSystems(recommendation);
		}

		this.recommendationDescription = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationDescription,
			{ recommendation }
		);
		this.recommendationRelatedComponent = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationRelatedComponent,
			recommendation
		);

		this.setProductivityImpact(recommendation.assetsStatistics, recommendation.isProductivityImpacted);
		this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.recommendation.productId);
		this.showUpgradeLink =
			recommendation.remediationType === RemediationType.Upgrade &&
			Object.keys(this.windowsDescriptionLinks).includes(recommendation.productId);
		this.upgradeLinkText = this.tvmTextsService.getText(TextToken.UpgradeLinkText, this.recommendation);
		this.upgradeLink = this.windowsDescriptionLinks[recommendation.productId];
		this.showLegalNoteLink =
			recommendation.remediationType === RemediationType.Update &&
			Object.keys(LegalNoteLinks).includes(recommendation.productId);
		this.legalNoteLink = LegalNoteLinks[recommendation.productId];
		this.legalNoteLinkText = this.tvmTextsService.getText(
			TextToken.LegalNoteLinkText,
			recommendation.productId
		);
		this.messageBarType =
			this.recommendation.hasEolVersions || this.isEol ? MessageBarType.warning : MessageBarType.info;
		this.widgetEosTooltip = this.i18nService.strings.tvm_securityRecommendation_eosTooltip;
		this.hasWeaknesses = recommendation.weaknessesCount !== 0;
		this.recommendationStatus = this.securityRecommendationService.getRecommendationStatus(
			recommendation.status,
			true
		);

		this.isZeroDayRecommendation = this.shouldShowZeroDayMessage(recommendation);
		if (this.shouldShowNewPatchMessage(recommendation)) {
			this.newPatchMessage = this.i18nService.get(
				'tvm_securityRecommendation_zeroDay_newPatch_notification',
				{
					patchReleaseDate: this.tzDateService.format(recommendation.patchReleaseDate, 'shortDate'),
					softwareName: this.recommendationRelatedComponent,
				}
			);
		}
		this.shouldShowWorkaround = recommendation.workarounds && recommendation.workarounds.length > 0;
		this.isSingleVulnerability = recommendation.weaknessesCount === 1;
		this.workaroundsMainText = this.getWorkaroundsMainText();
	}

	get recommendation(): SecurityRecommendation {
		return this._recommendation;
	}

	constructor(
		private paris: Paris,
		private tvmTextsService: TvmTextsService,
		private featuresService: FeaturesService,
		public i18nService: I18nService,
		injector: Injector,
		private changeDetectorRef: ChangeDetectorRef,
		private sidePanelService: SidePanelService,
		private tvmDownloadService: TvmDownloadService,
		private securityRecommendationService: SecurityRecommendationService,
		private vulnerabilityService: VulnerabilityService,
		private productivityImpactService: TvmProductivityImpactService,
		private recommendationContextService: TvmRecommendationContextService,
		remediationItsmToolsService: RemediationItsmToolsService, // don't delete, this is used to pre-load the provisioned itsm tools
		private router: Router,
		private tzDateService: TzDateService
	) {
		super(injector);
		this.exposureTooltip = this.tvmTextsService.getText(TextToken.ExposureScoreTooltip);
		this.configurationTooltip = this.tvmTextsService.getText(TextToken.ConfigurationScoreTooltip);
		this.assetRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationExposedAssetsRelationship
		);
		this.networkDeviceRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationExposedNetworkDeviceRelationship
		);
		this.installationNetworkDeviceRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationInstalledNetworkDevicesRelationship
		);
		this.installationRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationInstalledAssetsRelationship
		);
		this.vulnerabilitiesRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationRelatedVulnerabilitiesRelationship
		);
		this.recommendationContextRelationshipRepository = this.paris.getRelationshipRepository(
			RecommendationContextKeyAndExposedMachinesCountRelationship
		);
		this.assetRecommendationContextRelationshipRepository = this.paris.getRelationshipRepository(
			AssetRecommendationContextRelationship
		);
		this.mitigatedVulnerabilitiesRepository = this.paris.getRelationshipRepository(
			RecommendatioMitigatedVulnerabilitiesRelationship
		);
		this.exposedOperatingSystemsStatisticsRepository = this.paris.getRelationshipRepository(
			RecommendationExposedOperatingSystemsRelationship
		);

		this.isRequiredAttentionEnabled = this.featuresService.isEnabled(Feature.TvmRequiredAttention);
		this.isExposedOperatingSystem = this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
		this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
	}

	ngOnInit() {
		super.ngOnInit();
		this.exceptionLink = `remediation/recommendation-exceptions/${
			this.recommendation.remediationType === RemediationType.ConfigurationChange
				? this.recommendation.scId
				: this.recommendation.productId
		}`;
		this.explanationText = this.tvmTextsService.getText(
			TextToken.SecurityRecommendationAttentionExplanation,
			this.recommendation
		);
		this.eolText = this.getEolText();
		this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
		this.showEolMessage = this.shouldShowEolMessage();
		this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedDevices');
		if (this.recommendation.category === RecommendationCategory.NetworkGear) {
			this.configureNetworkDeviceRecommendation();
		}
		this.legalNote = this.isEol
			? this.tvmTextsService.getText(
					TextToken.SoftwareEolOsLegalNoteRecommendation,
					this.recommendation.productId
			  )
			: this.tvmTextsService.getText(
					TextToken.HasEolVersionsMicrosoftLegalNoteRecommendation,
					this.recommendation.productId
			  );

		if (this.featuresService.isEnabled(Feature.TvmRecommendationContext)) {
			if (this.isSingleAssetRecommendation) {
				this.recommendationContextType = this.recommendationContextService.getRecommendationContextTypeByScId(
					this._recommendation.scId
				);
				if (this.recommendationContextType !== RecommendationContextType.Unknown) {
					this.setRecommendationContextSectionTitles();
					this.assetRecommendationContextRelationshipRepository.sourceItem = new AssetRecommendation(
						{
							id: this._recommendation.id,
							scId: this._recommendation.scId,
							assetId: this.assetId,
						}
					);
				}
			} else {
				this.assets$.subscribe(assets => {
					this.recommendationContextType = this.recommendationContextService.getRecommendationContextTypeByAssets(
						assets
					);
					if (this.recommendationContextType !== RecommendationContextType.Unknown) {
						this.isRecommendationContextNeededInCSV = this.recommendationContextService.isRecommendationContextNeededInCSV(
							this.recommendationContextType
						);
						this.setRecommendationContextSectionTitles();
						this.recommendationContextRelationshipRepository.sourceItem = this._recommendation;
					}
				});
			}
		}
	}

	setRecommendationContextSectionTitles() {
		this.recommendationContextExposedMachinesTitle = this.tvmTextsService.recommendationContextToExposedSectionTitle[
			this.recommendationContextType
		];
		this.recommendationContextExposedMachinesLoadingText = this.tvmTextsService.recommendationContextToExposedSectionLoadingTitle[
			this.recommendationContextType
		];
	}

	setProductivityImpact(assetsStatistics: AssetsStatistics, isProductivityImpacted: boolean) {
		this.showProductivityImpactDescription =
			this.productivityImpactService.isProductivityImpactExposed &&
			(this.isSingleAssetRecommendation
				? isProductivityImpacted || isProductivityImpacted === false
				: !!assetsStatistics.nonProductivityImpactedAssets);

		if (!this.showProductivityImpactDescription) {
			return;
		}
		this.userImpactDescription = this.isSingleAssetRecommendation
			? this.tvmTextsService.getText(
					TextToken.AssetRecommendationProductivityImpact,
					isProductivityImpacted
			  )
			: this.tvmTextsService.getText(TextToken.OrgRecommendationProductivityImpact, assetsStatistics);
	}

	loadNewAssets(recommendation: SecurityRecommendation) {
		const queryOptions: DataQuery = {
			page: 1,
			pageSize: MaxItemsInCollapseExpandTable,
		};
		this.assetRelationshipRepository.sourceItem = recommendation;
		this.assets$ = this.assetRelationshipRepository.query(queryOptions);
	}

	loadExposedOperatingSystems(recommendation: SecurityRecommendation) {
		this.exposedOperatingSystemsStatisticsRepository.sourceItem = recommendation;
		this.exposedOperatingSystemsStatistics$ = this.exposedOperatingSystemsStatisticsRepository.query();
	}

	loadNewInstallations(recommendation: SecurityRecommendation) {
		const queryOptions: DataQuery = {
			page: 1,
			pageSize: MaxItemsInCollapseExpandTable,
		};
		this.installationRelationshipRepository.sourceItem = recommendation;
		this.installations$ = this.installationRelationshipRepository.query(queryOptions);
	}

	openSafeAssetsRemediation() {
		this.securityRecommendationService.showRemediationTaskCreationPanel(
			this.recommendation,
			ProductivityImpactRemediationType.NonImpactedAssets
		);
	}

	get vulnerabilitiesTitle(): string {
		return this.isConfigurationChange
			? this.i18nService.get('tvm.securityRecommendation.mitigatedCVE.title')
			: this.i18nService.get('tvm.common.relatedCVE.title');
	}

	get properlyConfiguredInsight() {
		return this.tvmTextsService.getText(
			TextToken.SecurityRecommendationSCAProperlyConfiguredInsight,
			this.recommendation
		);
	}

	get supportedBenchmarksInsight() {
		return this.tvmTextsService.getText(
			TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight,
			this.recommendation
		);
	}

	get contextOptions(): TvmContextOptions {
		return {
			isSingleAsset: this.isSingleAssetRecommendation,
		};
	}

	openAllExposedMachines() {
		this.sidePanelService.showAllExposedAssetsPanel(
			this._recommendation,
			this._recommendation.category === RecommendationCategory.NetworkGear,
			this.recommendationContextType
		);
	}

	openWorkaroundPanel() {
		this.sidePanelService.showWorkaroundSidePanel(
			this.recommendation,
			this.recommendationRelatedComponent
		);
	}

	openZeroDayRemediation() {
		this.securityRecommendationService.showRemediationTaskCreationPanel(
			this.recommendation,
			null,
			Tabs.none
		);
	}

	openRemediationOption() {
		this.securityRecommendationService.showRemediationTaskCreationPanel(this.recommendation, null);
	}

	openAllInstalledMachines() {
		this.sidePanelService.showAllInstalledAssetsPanel(this._recommendation);
	}

	openAllRelatedCves() {
		this.sidePanelService.showAllRelatedCvesPanel(this._recommendation, undefined, this.isNetworkGearCve);
	}

	openAllRecommendationContextKeyAndExposedMachinesCountPanel() {
		this.sidePanelService.showRecommendationContextKeyAndExposedMachinesCountPanel(
			this._recommendation,
			this.recommendationContextType
		);
	}

	openAllRecommendationContextKeyAndPropertiesPanel() {
		this.sidePanelService.showRecommendationContextKeyAndPropertiesPanel(
			this._recommendation,
			this.recommendationContextType
		);
	}

	exportRecommendationContextData() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.isSingleAssetRecommendation
				? this.assetRecommendationContextRelationshipRepository
				: this.recommendationContextRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation)} - ${
				this.recommendationContextExposedMachinesTitle
			}`,
			undefined
		);
	}

	exportCveData() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.isConfigurationChange
				? this.mitigatedVulnerabilitiesRepository
				: this.vulnerabilitiesRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation)} - ${
				this.i18nService.strings.tvm_common_relatedCVE_title
			}`,
			undefined
		);
	}

	exportExposedMachines() {
		const where = { includeProductivityColumn: this.showProductivityImpactDescription };
		const isNotOnboardedRecommendation = this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.NotOnboarded
		const notOnboardedCustomQuery = isNotOnboardedRecommendation ? { exportExtendType: 'UnmanagedAssets' }: {}

		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.assetRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation)} - ${
				this.i18nService.strings.tvm_common_exposedDevices
			}`,
			{ where },
			{
				isRecommendationContextNeededInCSV: this.isRecommendationContextNeededInCSV,
			},
			notOnboardedCustomQuery
		);
	}

	exportInstalledMachines() {
		return this.tvmDownloadService.downloadCsvFromRelationshipRepository(
			this.installationRelationshipRepository,
			TvmEndPoint.Analytics,
			`${this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation)} - ${
				this.i18nService.strings.tvm_common_installedDevices
			}`,
			undefined
		);
	}

	openVulnerabilityPanel(cveId: string) {
		this.paris
			.getItemById(Vulnerability, cveId)
			.subscribe((v: Vulnerability) =>
				this.vulnerabilityService.showVulnerabilityPanel(v, true, this.isNetworkGearCve)
			);
	}

	shouldShowEolMessage() {
		return (
			this.isEol ||
			this.isUpcomingEol ||
			this.recommendation.hasEolVersions ||
			this.recommendation.hasUpcomingEolVersions
		);
	}

	getEolText() {
		if (this.isEol) {
			return this.tvmTextsService.getText(
				TextToken.EolSoftwareText,
				this.recommendation.eolSoftwareSinceDate
			);
		}
		if (this.isUpcomingEol) {
			return this.tvmTextsService.getText(
				TextToken.UpcomingEolSoftwareText,
				this.recommendation.eolSoftwareSinceDate
			);
		}
		if (this.recommendation.hasEolVersions && this.recommendation.hasUpcomingEolVersions) {
			return this.tvmTextsService.getText(
				TextToken.CombinedEolVersionsText,
				this.recommendation.productName
			);
		}
		if (this.recommendation.hasEolVersions) {
			return this.tvmTextsService.getText(
				TextToken.HasEolVersionsText,
				this.recommendation.productName
			);
		}
		if (this.recommendation.hasUpcomingEolVersions) {
			return this.tvmTextsService.getText(
				TextToken.HasUpcomingEolVersionsText,
				this.recommendation.productName
			);
		}
	}

	openSoftwareVersions() {
		const alreadyEOL = this.recommendation.hasEolVersions ? 'AlreadyEOL' : '';
		const upcomingEOL = this.recommendation.hasUpcomingEolVersions ? 'UpcomingEOL' : '';
		this.router.navigate(
			[`/software-inventory/${encodeURIComponent(this.recommendation.productId)}/versions`],
			{
				queryParams: {
					filters: `versionDistributionTags=${[alreadyEOL, upcomingEOL]
						.filter(s => s.length > 0)
						.join('|')}`,
				},
			}
		);
	}

	getSoftwareVersionsLinkText() {
		return this.tvmTextsService.getText(
			TextToken.SoftwareVersionsLinkText,
			this.recommendation.productName
		);
	}

	configureNetworkDeviceRecommendation() {
		this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedNetworkDevices');
		this.exposedAssetsLoadingText = this.i18nService.get(
			'tvm.securityRecommendation.loadingExposedNetworkDevices'
		);
		this.installedAssetsTitle = this.i18nService.get('tvm.common.installedNetworkDevices');
		this.installedAssetsLoadingText = this.i18nService.get(
			'tvm.securityRecommendation.loadingInstalledNetworkDevices'
		);
		this.isNetworkDevice = true;
		this.includeLastSeenStatus = true;
		this.isNetworkGearCve = true;
		this.networkDeviceRelationshipRepository.sourceItem = this._recommendation;
		this.installationNetworkDeviceRelationshipRepository.sourceItem = this._recommendation;
	}

	generateExposedOperatingSystems(operatingSystems: Array<ExposedOperatingSystemsStatistics>): string {
		var res = '';
		if (operatingSystems.length > 0) {
			res += this.tvmTextsService.getOsPlatformLabel(operatingSystems[0].osName);
		}
		if (operatingSystems.length > 1) {
			res += `, ${this.tvmTextsService.getOsPlatformLabel(operatingSystems[1].osName)}`;
		}
		if (operatingSystems.length > 2) {
			res += ` (+${operatingSystems.length - 2} more)`;
		}
		return res;
	}

	getStyles(messageBarProps: IMessageBarStyleProps) {
		if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
			this.messageBarState.showBody = messageBarProps.expandSingleLine;
			this.changeDetectorRef.detectChanges();
		}
		return this._messageBarStyles;
	}

	private shouldShowZeroDayMessage(recommendation: SecurityRecommendation) {
		return (
			this.zeroDayFeatureEnabled &&
			recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
			!recommendation.patchReleaseDate
		);
	}

	private shouldShowNewPatchMessage(recommendation: SecurityRecommendation) {
		const dateBrier = moment()
			.subtract(7, 'days')
			.utc()
			.toDate();
		return (
			this.zeroDayFeatureEnabled &&
			recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
			recommendation.patchReleaseDate > dateBrier
		);
	}

	private getWorkaroundsMainText(): string {
		const workaroundKeyBase = this.shouldShowWorkaround
			? 'tvm_securityRecommendation_va_zeroDay_card_text_with_workaround_postLink'
			: 'tvm_securityRecommendation_va_zeroDay_card_text_without_workaround';

		return this.i18nService.get(
			`${workaroundKeyBase}${this.isSingleAssetRecommendation ? '_singleAsset' : ''}`
		);
	}
}
