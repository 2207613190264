import {
	FabButtonModule,
	FabCalendarModule,
	FabCalloutModule,
	FabIconModule,
	FabSpinButtonModule,
	FabTextFieldModule,
} from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DateTimePickerComponent } from './date-time-picker/date-time-picker.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { DateTimeDisplayService } from './services/date-time-display.service';
import { I18nModule } from '@wcd/i18n';

const exportedComponents = [DateTimePickerComponent, DateRangePickerComponent];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FabCalloutModule,
		FabCalendarModule,
		FabIconModule,
		FabSpinButtonModule,
		FabTextFieldModule,
		FabButtonModule,
		I18nModule,
	],
	providers: [DateTimeDisplayService],
	declarations: [...exportedComponents],
	exports: [...exportedComponents],
})
export class DateTimePickerModule {}
