/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../tags-list/tags-list.component.ngfactory";
import * as i2 from "../tags-list/tags-list.component";
import * as i3 from "../tags-list/overflow-tags-list.component.ngfactory";
import * as i4 from "../tags-list/overflow-tags-list.component";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "./tags-field.component";
var styles_TagsFieldComponent = [];
var RenderType_TagsFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TagsFieldComponent, data: {} });
export { RenderType_TagsFieldComponent as RenderType_TagsFieldComponent };
function View_TagsFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tags-list", [], null, null, null, i1.View_TagsListComponent_0, i1.RenderType_TagsListComponent)), i0.ɵdid(1, 573440, null, 0, i2.TagsListComponent, [], { tags: [0, "tags"], editable: [1, "editable"], maxItems: [2, "maxItems"], orientation: [3, "orientation"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.localTags; var currVal_1 = false; var currVal_2 = _co.maxItems; var currVal_3 = _co.orientation; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_TagsFieldComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "overflow-tags-list", [], null, null, null, i3.View_OverflowTagsList_0, i3.RenderType_OverflowTagsList)), i0.ɵdid(2, 4243456, null, 0, i4.OverflowTagsList, [i0.ChangeDetectorRef, i5.I18nService], { tags: [0, "tags"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.localTags; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagsFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_TagsFieldComponent_1)), i0.ɵdid(1, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(0, [["overflowTags", 2]], null, 0, null, View_TagsFieldComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.autoOverflow; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_TagsFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tags-field", [], null, null, null, View_TagsFieldComponent_0, RenderType_TagsFieldComponent)), i0.ɵdid(1, 49152, null, 0, i7.TagsFieldComponent, [], null, null)], null, null); }
var TagsFieldComponentNgFactory = i0.ɵccf("tags-field", i7.TagsFieldComponent, View_TagsFieldComponent_Host_0, { tags: "tags", maxItems: "maxItems", autoOverflow: "autoOverflow", orientation: "orientation" }, {}, []);
export { TagsFieldComponentNgFactory as TagsFieldComponentNgFactory };
