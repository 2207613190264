var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Entity } from '@microsoft/paris';
import { ActionBaseEntity } from './action-base.entity';
import { mapActionFromApiV2ToActionHistory } from './action-center-api-v2.converter';
import { compact } from 'lodash-es';
var ɵ0 = function (_, query) {
    if (query.where['useActionCenterApiV2']) {
        return "actioncenterui/pending-actions";
    }
    return query.where && query.where['useMtpApi'] ? 'MtpPendingActions' : 'PendingActions';
}, ɵ1 = function (config, query) {
    var useActionCenterApiV2 = query && query.where && query.where['useActionCenterApiV2'];
    return useActionCenterApiV2
        ? config.data.serviceUrls.automatedIr
        : config.data.serviceUrls.threatIntel;
}, ɵ2 = function (data, _, query) {
    if (query && query.where['useActionCenterApiV2']) {
        // convert data from the new action center service format to the model format
        var results = data && data.Results;
        var convertedData = results &&
            (Array.isArray(results) ? results : [results]).map(function (action) {
                return mapActionFromApiV2ToActionHistory(action);
            });
        return { count: data.Count, results: compact(convertedData) };
    }
    return data;
};
var PendingActionHistory = /** @class */ (function (_super) {
    __extends(PendingActionHistory, _super);
    function PendingActionHistory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    PendingActionHistory = __decorate([
        Entity({
            singularName: 'Action',
            pluralName: 'Actions',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            parseData: ɵ2,
            allItemsProperty: 'results',
            separateArrayParams: true,
            parseDataQuery: ActionBaseEntity.convertActionCenterQueryToParams,
        })
    ], PendingActionHistory);
    return PendingActionHistory;
}(ActionBaseEntity));
export { PendingActionHistory };
export { ɵ0, ɵ1, ɵ2 };
