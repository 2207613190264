/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tabs/tabs.component.ngfactory";
import * as i2 from "../../../shared/components/tabs/tabs.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./file.entity.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../shared/services/title.service";
import * as i8 from "@microsoft/paris/dist/lib/paris";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../services/files.service";
var styles_FileEntityComponent = [];
var RenderType_FileEntityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileEntityComponent, data: {} });
export { RenderType_FileEntityComponent as RenderType_FileEntityComponent };
function View_FileEntityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "tabs", [], null, null, null, i1.View_TabsComponent_0, i1.RenderType_TabsComponent)), i0.ɵdid(3, 4833280, null, 0, i2.TabsComponent, [i0.ChangeDetectorRef, i3.ActivatedRoute, i3.Router], { tabsData: [0, "tabsData"], currentRouteIsActive: [1, "currentRouteIsActive"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf; var currVal_1 = true; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_FileEntityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 14, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "wcd-flex-none wcd-margin-xxLarge-horizontal wcd-flex-spaceBetween-horizontal\n\t\t\t\twcd-flex-center-vertical wcd-margin-large-bottom wcd-padding-none-left-sm-screens"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FileEntityComponent_1)), i0.ɵdid(6, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i4.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 4, "div", [["class", "wcd-flex-1  ms-background-color-white"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(12, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(13, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 6, 0, i0.ɵnov(_v, 7).transform(_co.tabs$)); _ck(_v, 6, 0, currVal_0); _ck(_v, 13, 0); }, null); }
export function View_FileEntityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_FileEntityComponent_0, RenderType_FileEntityComponent)), i0.ɵdid(1, 245760, null, 0, i5.FileEntityComponent, [i6.I18nService, i7.TitleService, i8.Paris, i9.FeaturesService, i3.Router, i3.ActivatedRoute, i10.FilesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileEntityComponentNgFactory = i0.ɵccf("ng-component", i5.FileEntityComponent, View_FileEntityComponent_Host_0, { entity: "entity", isFullScreen: "isFullScreen", action$: "action$", entityPageViewMode: "entityPageViewMode" }, { runAction$: "runAction$" }, []);
export { FileEntityComponentNgFactory as FileEntityComponentNgFactory };
