import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../app/seville/seville.module';
import { adminRoutes } from '../../../../../../admin/admin-routing.module';
import { AdminService } from '../../../../../../admin/_base/services/admin.service';
import { NavItemModel } from '@wcd/shared';
import {
	MainAppState,
	MainAppStateService,
} from '../../../../../../shared/main/services/main-app-state.service';
import { Subscription } from 'rxjs';

SevilleModule.controller('seville.settings', settingsController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('preferences', {
			url: '/preferences',
			redirectTo: 'preferences.general',
			title: 'Settings',
			views: {
				main: {
					template: `
						<div class="wcd-full-height wcd-flex-vertical wcd-full-height-from-width-800" style="margin: -20px -30px; height: calc(100% + 40px)">
							<header class="wcd-flex-none page-header">
								<h2>Settings</h2>
							</header>
							<div class="wcd-flex-1">
								<div class="wcd-flex-horizontal wcd-full-height">
									<nav class="wcd-flex-none wcd-border-right-light wcd-padding-large-left wcd-padding-large-bottom wcd-scroll-vertical">
										<section class="nav-list-section nested" ng-repeat="section in settingsSections">
											<h4 class="nav-list-section-title">{{section.name}}</h4>
											<ul class="nav-list small-spacing" [attr.data-track-component]="section.name + ' settings'">
											  <li ng-repeat="setting in section.states"
												data-test-id="{{::setting.path}}"
												ui-sref-active="active">
												<a ng-if="setting.isLegacy"
													ui-sref="{{getLegacySettingSref(setting)}}"
													class="nav-list-link"
													data-track-id="{{setting.route}}"
													data-track-type="Navigation">
													<span>{{::setting.name}}</span>
													<span class="wcd-badge wcd-badge-raised color-box-error" ng-if="mainAppState.mainNavItemBadges[setting.badgeId]">
														{{mainAppState.mainNavItemBadges[setting.badgeId]}}
													</span>
												</a>
												<a ng-if="!setting.isLegacy"
												  class="nav-list-link"
												  router-link="['/preferences2/' + setting.route]"
												  data-track-id="{{setting.route}}"
												  data-track-type="Navigation"
												  href="{{'/preferences2/' + setting.route}}">
												  <span>{{::setting.name}}</span>
												  <span class="wcd-badge wcd-badge-raised color-box-error" ng-if="mainAppState.mainNavItemBadges[setting.badgeId]">
														{{mainAppState.mainNavItemBadges[setting.badgeId]}}
													</span>
												</a>
											  </li>
											</ul>
										</section>
									</nav>
									<div class="wcd-flex-1 wcd-padding-large-left wcd-scroll-vertical">
										<div ng-if="mainAppState.loadingComponent.id !== 'settings'" ui-view="SettingsView"></div>
										<div ng-if="mainAppState.loadingComponent.id === 'settings'" class="wcd-full-height loading-overlay">
											<i class="large-loader-icon"></i>
										</div>
									</div>
								</div>
							</div>
						</div>
						`,
					controller: 'seville.settings',
					controllerAs: 'preferences',
				},
			},
		});
	},
]);

settingsController.$inject = [
	'$scope',
	'$state',
	'featuresService',
	'$window',
	'appConfig',
	'$rootScope',
	'adminService',
	'mainAppStateService',
];

function settingsController(
	$scope,
	$state,
	featuresService,
	$window,
	appConfig: AppConfigService,
	$rootScope,
	adminService: AdminService,
	mainAppStateService: MainAppStateService
) {
	var lastUrlSegment = $window.location.href.substr(window.location.href.lastIndexOf('/') + 1);
	let mainAppStateSubscription: Subscription;

	$scope.toggleStateOn = 'On';
	$scope.toggleStateOff = 'Off';
	$scope.toggleStatePending = 'Pending';

	mainAppStateSubscription = mainAppStateService.state$.subscribe(
		(mainAppState: MainAppState) => ($scope.mainAppState = mainAppState)
	);

	$scope.$on('$destroy', () => {
		mainAppStateSubscription && mainAppStateSubscription.unsubscribe();
	});

	$scope.settingsBaseUrl = appConfig.serviceUrls && appConfig.serviceUrls.management;
	$scope.userRequestsBaseUrl = appConfig.serviceUrls && appConfig.serviceUrls.userRequests;

	$scope.settingsSections = adminService.getStaticSettingsNav(adminRoutes[0]);

	// get enabled features status
	$scope.blockListFeatureEnabled = featuresService.isEnabled('Response-Blacklist');
	$scope.officeIntegrationFeatureEnabled = featuresService.isEnabled('OfficeAtpIntegration');
	$scope.emailNotificationsEnabled = featuresService.isEnabled('EmailNotifications');
	$scope.siemConnectorEnabled = featuresService.isEnabled('SiemOnboarding');
	$scope.skypeIntegrationEnabled = featuresService.isEnabled('SkypeIntegration');
	$scope.enableDartDataCollection = featuresService.isEnabled('EnableDartDataCollection');
	$scope.userDetailsEnabled = featuresService.isEnabled('UserDetails');
	$scope.powerBIFeatureEnabled = featuresService.isEnabled('PowerBI');
	$scope.securityAnalyticsFeatureEnabled = featuresService.isEnabled('SecurityAnalyticsRS3Controls');
	$scope.isAutoIRFeatureEnabled = true;

	$scope.$on('$stateChangeSuccess', function(event, toState) {
		pageLoadReset();
	});

	var showMessageLabel = function(message) {
		$scope.messageLabel = message;
		$scope.applyingPreferencesChange = false;
		$scope.loadingCurrentPreferences = false;
	};

	var pageLoadReset = function() {
		$scope.loadingCurrentPreferences = true;
		$scope.applyingPreferencesChange = false;
		$scope.loadingCurrentPreferencesFailed = false;
		$scope.savePreferencesFailed = false;
	};

	var resetAllSelected = function() {
		$scope.generalSelected = false;
		$scope.advancedSelected = false;
		$scope.emailSelected = false;
		$scope.customTISelected = false;
		$scope.siemConnectorSelected = false;
		$scope.previewExperienceSelected = false;
		$scope.powerBISelected = false;
		$scope.securityAnalyticsSelected = false;
	};

	// temp hack to allow download pages params. will be removed after endpoint management upgrade
	$scope.getLegacySettingSref = function(setting: NavItemModel): string {
		if (!setting) return '';
		return `${setting.id + (setting.routeParams ? '({type:"' + setting.routeParams + '"})' : '')}`;
	};

	// temp hack to allow download pages params. will be removed after endpoint management upgrade
	$scope.getLegacySettingSref = function(setting: NavItemModel): string {
		if (!setting) return '';
		return `${setting.id + (setting.routeParams ? '({type:"' + setting.routeParams + '"})' : '')}`;
	};

	$scope.resetMessageLabel = function() {
		$scope.messageLabel = '';
	};

	$scope.savePreferencesReset = function() {
		$scope.resetMessageLabel();
		$scope.loadingCurrentPreferencesFailed = false;
		$scope.savePreferencesFailed = false;
		$scope.applyingPreferencesChange = true;
	};

	$scope.showSavePreferencesErrorWithMessage = function(message) {
		$scope.savePreferencesFailed = true;
		showMessageLabel(message);
	};

	$scope.showSavePreferencesError = function() {
		$scope.showSavePreferencesErrorWithMessage('Unable to save preferences.');
	};

	$scope.showGetPreferencesErrorWithMessage = function(message) {
		$scope.loadingCurrentPreferencesFailed = true;
		showMessageLabel(message);
	};

	$scope.showGetPreferencesError = function() {
		$scope.showGetPreferencesErrorWithMessage('Unable to retrieve preferences.');
	};

	$scope.showSavePreferencesSuccess = function() {
		showMessageLabel('');
	};

	$scope.applyGetPreferencesSuccess = function() {
		$scope.loadingCurrentPreferences = false;
	};

	resetAllSelected();
	pageLoadReset();
}
