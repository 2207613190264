import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';


@ValueObject({
	singularName: 'Filter Item Value',
	pluralName: '',
})
export class FilterItemValue extends ModelBase {
	@EntityField({ data: 'Key' })
	key: string;

	@EntityField({ data: 'Name' })
	name: string;
}


@ValueObject({
	singularName: 'Filter Item',
	pluralName: '',
})
export class FilterItem extends ModelBase {
	@EntityField({ data: 'Key' })
	key: string;

	@EntityField({ data:'Name' })
	name: string;

	@EntityField({ data: ['FilterType', 'type'] })
	filterType: string;

	@EntityField({ data: 'Values', arrayOf: FilterItemValue})
	values: Array<FilterItemValue>;
}
