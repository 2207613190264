var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { File } from '@wcd/domain';
import { Router } from '@angular/router';
import { EntityResolver } from '../../../global_entities/services/entity.resolver';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { defer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { toObservable } from '../../../utils/rxjs/utils';
import { recursiveDecodeURIComponent } from '../../../utils/url-encoding/url-encoding-utils';
import { AppContextService } from '@wcd/config';
var FileEntityResolver = /** @class */ (function (_super) {
    __extends(FileEntityResolver, _super);
    function FileEntityResolver(paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext) {
        return _super.call(this, paris, activatedEntity, globalEntityTypesService, router, entityIdRouteService, appContext) || this;
    }
    FileEntityResolver.prototype.loadEntity = function (entityConstructor, entityId, route, state, params) {
        var _this = this;
        var namelessFile$ = defer(function () {
            return _super.prototype.loadEntity.call(_this, entityConstructor, entityId, route, state, params);
        });
        // by default, files have no name - they're identified by sha
        // in cases we reach the file page from a specific file context, the name is passed as a matrix parameter after the sha
        var fileNameParam = route.paramMap.get('name');
        if (fileNameParam) {
            var fileName = recursiveDecodeURIComponent(fileNameParam);
            var fileWithName$ = this.paris.createItem(File, { Id: entityId, FileName: fileName });
            var fileEntityType_1 = this.globalEntityTypesService.getEntityType(File);
            return fileWithName$.pipe(switchMap(function (file) { return toObservable(fileEntityType_1.getItem(file, namelessFile$)); }));
        }
        return namelessFile$;
    };
    return FileEntityResolver;
}(EntityResolver));
export { FileEntityResolver };
