import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Data sensitivity',
	pluralName: 'Data sensitivities',
})
export class DataSensitivity extends ModelBase {
	/**
	 * A label applied to a data piece by the user, classifying its sensitivity.
	 * The set of allowed labels is configured by the each org.
	 * @example 'Public', 'Confidential'.
	 */
	@EntityField({ data: ['SensitivityLabel', 'sensitivityLabel'] })
	label: string;

	/**
	 * A sub label under the main label, further classifying the sensitivity. Not all labels have sub labels.
	 * @example 'Recipients only' in the label 'Confidential / Recipients only'.
	 */
	@EntityField({ data: ['SensitivitySubLabel', 'sensitivitySubLabel'] })
	subLabel?: string;

	/**
	 * Gets whether endpoint protection is turned on for this label.
	 * Such labels are considered "more sensitive" and are highlighted in the portal
	 */
	@EntityField({ data: ['IsEndpointDlpApplied', 'isEndpointDlpApplied'] })
	isEndpointDlpApplied?: boolean;

	get displayText(): string {
		return `${this.label}${this.subLabel ? ' \\ ' + this.subLabel : ''}`;
	}
}
