import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataviewActionTypes } from './dataview-actions.model';
import { BehaviorSubject } from 'rxjs';
import { Positions } from '@wcd/forms';
import { FancySelectComponent } from '../../../forms/components/fancy-select.component';
import { DataViewActionElementButtonPicker } from './dataview-action-wrapper.component';

let lastId = 0;
export interface DataviewActionFancySelectConfig {
	onSelectionChanged: (pageSize: number) => void;
	currentSelection: BehaviorSubject<any>;
	selections: any[];
	icon: string;
	formatLabel?: (value: BehaviorSubject<any>) => string;
	ariaLabel?: string;
	dataTrackId?: string;
	listId?: string;
	focus?: () => any;
	focusOnInit?: boolean;
	propertyIdForLabel?: string;
	actionType: DataviewActionTypes;
}

@Component({
	selector: 'dataview-action-fancy-select',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<fancy-select
			[wcdTooltip]="getTooltip()"
			[ngModel]="currentSelection.value"
			[name]="dataTrackId"
			[openMenuPosition]="openMenuPosition"
			[label]="propertyIdForLabel"
			class="hidden-action command-bar-item-dropdown"
			[formatLabel]="formatLabel"
			(ngModelChange)="onSelectionChanged($event)"
			[values]="selections"
			[listId]="listId + (isInMoreMenu ? '-more' : '')"
			[buttonIcon]="icon"
			[ariaLabel]="ariaLabel"
			[navigateUsingArrowKeysOnly]="true"
			[navigateUsingTab]="true"
			[focusOnInit]="focusOnInit"
			(focus)="focus && focus()"
			[showIconDropdown]="!smallScreenView"
			[placeholder]=""
			[iconButtonOnly]="smallScreenView"
			[dropdownAreaRole]="'button'"
			#fancyElement
		>
		</fancy-select>
	`,
})
export class DataviewActionFancySelectComponent implements DataViewActionElementButtonPicker, OnInit {
	@Input() fancySelectActionConfig: DataviewActionFancySelectConfig;
	@Input() openMenuPosition: Positions = Positions.Default;
	@Input() smallScreenView: boolean = false;
	@Input() isInMoreMenu: boolean = false;

	@ViewChild(FancySelectComponent, { static: true }) fancyElement: FancySelectComponent;

	public onSelectionChanged: (pageSize: number) => void;
	public currentSelection: BehaviorSubject<any>;
	public selections: any[];
	public icon?: string;
	public formatLabel?: Function;
	public ariaLabel?: string;
	public dataTrackId?: string;
	public listId?: string = 'dataview-action-fancy-select-' + lastId++;
	public focus?: () => any;
	public focusOnInit?: boolean;
	public propertyIdForLabel?: string;
	constructor(public i18nService: I18nService) {}

	buttonElementPicker() {
		return this.fancyElement.dropdown.buttonEl;
	}

	ngOnInit(): void {
		Object.assign(this, { ...this.fancySelectActionConfig });
	}

	getTooltip() {
		if (this.currentSelection.value) {
			return this.formatLabel
				? this.formatLabel(this.currentSelection.value)
				: this.currentSelection.value[this.propertyIdForLabel];
		}
		return '';
	}
}
