/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "../../../../../../../projects/wizard/src/lib/components/wizard.component.ngfactory";
import * as i5 from "../../../../../../../projects/wizard/src/lib/components/wizard.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../insights/services/app-insights.service";
import * as i8 from "./evaluation-setup-panel.component";
import * as i9 from "@angular/router";
import * as i10 from "../../services/evaluation.service";
var styles_EvaluationSetupPanelComponent = [];
var RenderType_EvaluationSetupPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EvaluationSetupPanelComponent, data: {} });
export { RenderType_EvaluationSetupPanelComponent as RenderType_EvaluationSetupPanelComponent };
export function View_EvaluationSetupPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, [["panel", 4]], 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 1, "wcd-wizard", [["wizardTitle", "evaluation.lab.setupPanel.title"], ["wizardTrackingName", "EvaluationWizard"]], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; if (("onClose" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).closePanel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i4.View_WcdWizardComponent_0, i4.RenderType_WcdWizardComponent)), i0.ɵdid(6, 245760, null, 0, i5.WcdWizardComponent, [i6.I18nService, i0.ChangeDetectorRef, i0.ComponentFactoryResolver, i7.AppInsightsService], { steps: [0, "steps"], data: [1, "data"], wizardTitle: [2, "wizardTitle"], wizardTrackingName: [3, "wizardTrackingName"] }, { onClose: "onClose" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.steps; var currVal_2 = _co.data; var currVal_3 = "evaluation.lab.setupPanel.title"; var currVal_4 = "EvaluationWizard"; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_EvaluationSetupPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "evaluation-setup-panel", [], null, null, null, View_EvaluationSetupPanelComponent_0, RenderType_EvaluationSetupPanelComponent)), i0.ɵdid(1, 245760, null, 0, i8.EvaluationSetupPanelComponent, [i9.Router, i10.EvaluationService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EvaluationSetupPanelComponentNgFactory = i0.ɵccf("evaluation-setup-panel", i8.EvaluationSetupPanelComponent, View_EvaluationSetupPanelComponent_Host_0, { settings: "settings" }, {}, []);
export { EvaluationSetupPanelComponentNgFactory as EvaluationSetupPanelComponentNgFactory };
