import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	ViewChild,
} from '@angular/core';
import { Tag, TagType } from '@wcd/domain';
import { lowerFirst } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

export const OVERFLOW_TAGS_CONTAINER_CLASS = 'overflow-tags-list-container';

@Component({
	selector: 'overflow-tags-list',
	templateUrl: './overflow-tags-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OverflowTagsList implements AfterViewInit {
	@Input() minimumExpendedItems = 1;
	@Input() tags: ReadonlyArray<Tag | string>;
	@ViewChild('overflowContainer', { static: false }) overflowContainerRef: ElementRef<HTMLSpanElement>;
	tagsContainer: Element;

	constructor(private readonly changeDetector: ChangeDetectorRef, private i18nService: I18nService) {
	}

	ngAfterViewInit(): void {
		if (this.overflowContainerRef) {
			this.tagsContainer = this.overflowContainerRef.nativeElement.closest(`.${OVERFLOW_TAGS_CONTAINER_CLASS}`);
			this.changeDetector.detectChanges();
		}
	}

	createOverflowItem = (visibleItems: Tag[], hiddenItems: Tag[]): Tag => {
		const tooltip = hiddenItems.map(this.getTagLabel).join(', ');
		return ({
			id: 'overflow-count-tag',
			name: `+${hiddenItems.length}`,
			type: TagType.system,
			tooltip,
			ariaLabel: tooltip,
		});
	}

	private getTagLabel = (tag: Tag | string): string => {
		if (typeof tag === 'string') return tag;
		return tag.name || !tag.nameKey ? tag.name : this.i18nService.get(`tags.localized.${lowerFirst(tag.nameKey)}`);
	};

}
