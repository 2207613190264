import { Injectable } from '@angular/core';
import { SeverityType } from '@wcd/domain';
import { KnownColorName } from '../models/colors/known-colors.models';
import { ColorsService } from './colors.service';
import { KnownColorsService } from './known-colors.service';

const severityTypeColorName: Record<SeverityType, KnownColorName> = {
	[SeverityType.High]: 'highSeverity',
	[SeverityType.Medium]: 'mediumSeverity',
	[SeverityType.Low]: 'lowSeverity',
	[SeverityType.Informational]: 'informationalSeverity',
};

@Injectable()
export class SeverityTypeColorService extends ColorsService<SeverityType> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, severityTypeColorName);
	}
}
