<ng-container *ngIf="sampleRequestAction$ | async as sampleRequestAction; else loading">
	<section *ngIf="sampleRequestAction.requestGuid">
		<h3>{{'file.sample.collect.title' | i18n}}</h3>
		<dl class="wcd-margin-bottom" role="none">
			<dt>{{ 'file.sample.collect.actionCenter.status' | i18n }}</dt>
			<dd>
				{{ statusToString(sampleRequestAction.status) }}
			</dd>
			<dt>{{'file.sample.collect.actionCenter.submissionTime' | i18n}}</dt>
			<dd>
				<time [attr.datetime]="sampleRequestAction.creationTime.toISOString()">{{ sampleRequestAction.creationTime | date: 'medium' }}</time>
			</dd>
			<dt>{{'file.sample.collect.actionCenter.requestedBy' | i18n}}</dt>
			<dd>
				{{ sampleRequestAction.requestor }}
			</dd>
			<dt>{{'file.sample.collect.actionCenter.Comment' | i18n}}</dt>
			<dd>
				{{ sampleRequestAction.requestorComment }}
			</dd>
		</dl>
		<p>
			<small>
				<fab-icon iconName="Info"></fab-icon>
				{{'file.sample.collect.actionCenter.collectionInfo' | i18n}}
			</small>
		</p>
	</section>
</ng-container>
<ng-template #loading>
	<fab-spinner [label]="'file.sample.collect.actionCenter.loading' | i18n"></fab-spinner>
</ng-template>
