var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { Subject, of } from 'rxjs';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { catchError, map } from 'rxjs/operators';
import { BaselineProfileCompletedComponent } from './baseline-profile-completed.component';
import { ProfilesService } from '../../profiles-tab/services/profiles.service';
var ProfileReviewStepComponent = /** @class */ (function (_super) {
    __extends(ProfileReviewStepComponent, _super);
    function ProfileReviewStepComponent(i18n, dialogsService, profilesService) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.dialogsService = dialogsService;
        _this.profilesService = profilesService;
        _this.saving$ = new Subject();
        _this.dlDisplayMode = 0 /* GRID */;
        _this.createProfile = function () {
            _this.setBackButtonEnabled(false);
            _this.setStepValidation(false);
            _this.saving$.next(true);
            return _this.profilesService.saveBaselineProfile(_this.data).pipe(map(function () {
                _this.markWizardCompleted(BaselineProfileCompletedComponent);
                _this.saving$.next(false);
                return true;
            }), catchError(function () {
                _this.dialogsService.showSnackbar({
                    icon: 'ErrorBadge',
                    text: _this.i18n.strings.tvm_baseline_profile_summaryStep_creationError,
                    className: 'color-box-failureBackground',
                });
                _this.setBackButtonEnabled(true);
                _this.setStepValidation(true);
                _this.saving$.next(false);
                return of(false);
            }));
        };
        return _this;
    }
    ProfileReviewStepComponent.prototype.ngOnInit = function () {
        this.setOnNext(this.createProfile);
        this.setStepValidation(true);
        this.name = this.data.name;
        this.description = this.data.description;
        this.status = this.data.isEnabled ?
            this.i18n.strings.tvm_baseline_profile_wizard_status_enabled :
            this.i18n.strings.tvm_baseline_profile_wizard_status_disabled;
        this.operatingSystem = this.data.operatingSystem + " " + this.data.operatingSystemVersion;
        this.benchmark = this.data.benchmark + " " + this.data.benchmarkVersion;
        this.complianceLevel = this.data.complianceLevel;
        this.data.checks = this.data.selectedSettings.map(function (setting) { return setting.id; });
        this.configurationsAssignment = this.data.selectedSettings.reduce(function (assignmentsByCategory, selectedSetting) {
            if (!assignmentsByCategory[selectedSetting["category"]])
                assignmentsByCategory[selectedSetting["category"]] = 0;
            assignmentsByCategory[selectedSetting["category"]]++;
            return assignmentsByCategory;
        }, {});
    };
    return ProfileReviewStepComponent;
}(WizardBaseStep));
export { ProfileReviewStepComponent };
