import { Injectable } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { DimensionsModel } from '../../dialogs/models/dimensions.model';
import { I18nService } from '@wcd/i18n';
import { FeedbackModalComponent } from '../components/feedback.modal.component';

@Injectable()
export class FeedbackService {
	constructor(private dialogsService: DialogsService, private i18nService: I18nService) {}

	showFeedbackDialog(): void {
		this.dialogsService
			.showModal(FeedbackModalComponent, {
				id: 'feedback',
				title: this.i18nService.get('feedback.title'),
				dimensions: new DimensionsModel(800, 0),
				enableOverlay: false,
			})
			.subscribe();
	}
}
