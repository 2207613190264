import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { MACHINE_TEMPLATE } from '../actioncenter/seville.actioncenter.machine';

SevilleModule.controller('seville.threatintel.machine.isolate', isolateController);

isolateController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$uibModalInstance',
	'appConfig',
	'machine',
	'$uibModal',
	'hoverPaneService',
	'featuresService',
	'appInsights',
];

function isolateController(
	$log,
	$scope,
	$state,
	$http,
	$uibModalInstance,
	appConfig,
	machine,
	$modal,
	hoverPaneService,
	featuresService,
	appInsights: AppInsightsService
) {
	$scope.machine = machine;
	var vm = $scope;

	vm.selectiveIsolationEnabled = featuresService.isEnabled('Response-SelectiveIsolation');

	var parts = machine.SenseClientVersion.split('.');

	if (parts[1] < 3630) {
		vm.selectiveIsolationEnabled = false;
		$log.debug(
			'Selective isolation feature is not enabled since sense client version is below 10.3630.0.0. sense client version: ' +
				machine.SenseClientVersion
		);
	}

	machine.isSelectiveIsolation = false;

	vm.isolate = function() {
		if (vm.posting) return;

		if (!vm.machine.commentText || vm.machine.commentText == '') return;

		vm.posting = true;
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'ActionsButton',
			Page: 'Device',
			Component: 'IsolateMachine',
		});

		// Determine the isolation action
		var action;
		var newStatus;
		if (machine.IsolationStatus == 'RequestCompleted') {
			action = 'Unisolate';
			newStatus = 'ReverseRequestPending';
		} else {
			action = 'Isolate';
			newStatus = 'RequestPending';
		}

		var isolationType = 'Full';
		if (machine.isSelectiveIsolation) {
			isolationType = 'Selective';
		}

		$http
			.post(appConfig.serviceUrls.userRequests + '/requests/create', {
				MachineId: machine.SenseMachineId,
				Action: action,
				IsolationType: isolationType,
				RequestorComment: vm.machine.commentText,
				OsPlatform: vm.machine.OsPlatform,
				SenseClientVersion: vm.machine.SenseClientVersion,
				Type: 'IsolationRequest',
			})
			.then(function(response) {
				if (response.status == 200) {
					machine.ResponseActionExists = true;
					machine.IsolationStatus = newStatus;
					openActionCenter();
				} else {
					$modal.open({
						animation: $scope.animationsEnabled,
						template: ERROR_POPUP_TEMPLATE,
						size: 'md',
						windowClass: 'machine-actions-modal',
						backdrop: false,
					});
				}

				vm.machine.commentText = '';
				vm.posting = false;
				$uibModalInstance.dismiss();
			});
	};

	vm.close = function() {
		vm.machine.commentText = '';
		$uibModalInstance.dismiss();
	};

	vm.setIsolationType = function() {
		vm.machine.isSelectiveIsolation = !vm.machine.isSelectiveIsolation;
	};

	function openActionCenter() {
		hoverPaneService.open({
			template: MACHINE_TEMPLATE,
			controller: 'seville.actioncenter.machine',
			controllerAs: 'actioncentermachine',
			title: 'Action center',
			icon: 'F12DevTools',
			size: 'xl',
			resolve: {
				entity: function() {
					return machine;
				},
			},
		});
	}
}
