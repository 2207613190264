var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var _a;
import { AirsEntityRemediationStatus } from '@wcd/domain';
import { ColorsService } from './colors.service';
import { KnownColorsService } from './known-colors.service';
import { OTHER_STATUS_TYPE_NAME, } from '../../reports/components/evidence-summary.component';
var ɵ0 = function (acc, status) {
    acc[status.type] = status.paletteColor;
    return acc;
};
var evidenceTypeColorName = AirsEntityRemediationStatus.entityConfig.values.reduce(ɵ0, (_a = {},
    _a[OTHER_STATUS_TYPE_NAME] = 'neutralLight',
    _a));
var EvidenceTypeColorService = /** @class */ (function (_super) {
    __extends(EvidenceTypeColorService, _super);
    function EvidenceTypeColorService(knownColorsService) {
        return _super.call(this, knownColorsService, evidenceTypeColorName) || this;
    }
    return EvidenceTypeColorService;
}(ColorsService));
export { EvidenceTypeColorService };
export { ɵ0 };
