import { Injectable } from '@angular/core';
import { TrackingAttributesRaw } from '../models/tracking-attributes.models';
import { TrackingEventPropertiesBase } from '../models/tracking-event.interface';

export type TrackingAttributesMap = Record<TrackingAttributesRaw, any>;

const propertyToAttributeMap: Record<keyof TrackingEventPropertiesBase, TrackingAttributesRaw> = {
	component: 'data-track-component',
	componentType: 'data-track-component-type',
	id: 'data-track-id',
	type: 'data-track-type',
	value: 'data-track-value',
};

@Injectable({
	providedIn: 'root',
})
export class TrackingEventsModelService {
	convertEventToAttribute(
		trackingEventProperties: Readonly<TrackingEventPropertiesBase>
	): TrackingAttributesMap {
		return Object.keys(trackingEventProperties)
			.filter(key => key in propertyToAttributeMap)
			.reduce(
				(acc, key) =>
					Object.assign(acc, {
						[propertyToAttributeMap[key]]: trackingEventProperties[key],
					}),
				{} as any
			);
	}
}
