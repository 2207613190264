import { Injectable } from '@angular/core';
import {
	ActivatedRoute,
	ActivatedRouteSnapshot,
	CanActivate,
	Router,
	RouterStateSnapshot,
} from '@angular/router';
import { EntityIdRouteService } from './entity-id-route.service';
import { GlobalEntityTypesService } from './global-entity-types.service';
import { map, switchMap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { toObservable } from '../../utils/rxjs/utils';
import { sccHostService } from '@wcd/scc-interface';
import { AppContextService } from '@wcd/config';
import { RbacService } from '../../rbac/services/rbac.service';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class IncidentRedirectGuard implements CanActivate {
	constructor(
		private readonly paris: Paris,
		private readonly router: Router,
		private readonly activatedRoute: ActivatedRoute,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly entityIdRouteService: EntityIdRouteService,
		private readonly appContextService: AppContextService,
		private readonly rbacService: RbacService,
		private readonly i18nService: I18nService
	) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const entityType = this.globalEntityTypesService.getEntityType(route.data.entity);
		if (!entityType) throw new Error('Unknown entity type.');

		const routeId = this.entityIdRouteService.getRouteId({ route });

		const params$ = toObservable(
			entityType && entityType.getItemParams
				? entityType.getItemParams(route.data.entity, { routeId: routeId })
				: null
		);
		const entitySingularName: string = entityType.entity.singularName.toLowerCase();
		return params$.pipe(
			switchMap(params => this.paris.getItemById(route.data.entity, routeId, null, params)),
			catchError(error => {
				if (error.status === 404) {
					sccHostService.isSCC
						? setTimeout(() => sccHostService.state.go('Error.child', { path: '404' }))
						: this.router.navigateByUrl('/Error/404');
				} else if (error.status === 401 || error.status === 403) {
					this.appContextService.isSCC
						? sccHostService.state.go('Error.child', { path: 'UnauthorizedOnThisPage' })
						: this.rbacService.showNoAccessModal(
									this.i18nService.get(
										`rbac.accessDenied.description.${entitySingularName}`,
										null,
										true
									) ||
									this.i18nService.get('rbac.accessDenied.description.global')
							);
				} else {
					sccHostService.isSCC
					? setTimeout(() => sccHostService.state.go('Error.child', { path: '500' }))
					: this.router.navigateByUrl('/Error');
				}
				throw new Error('Coult not load entity due to request error');
			}),
			map(entity => {
				if (
					route.data.redirectOnInvalidId &&
					routeId !== undefined &&
					String(entity.id) !== routeId
				) {
					// Entity redirect should be done when the entity is returned with 200 OK status,
					// but with different entity data (= entity id in the response data is different than the requested entity id).
					// In this case, we want to trigger a new navigation to the new entity page
					const redirectRoute = route.pathFromRoot
						.map(routeSnapshot =>
							routeSnapshot.routeConfig ? routeSnapshot.routeConfig.path : ''
						)
						.join('/')
						.replace(':id', String(entity.id))
						.replace('//', '/');

					// Returning a UrlTree in order to trigger a new navigation
					return this.router.createUrlTree([redirectRoute], {
						relativeTo: this.activatedRoute,
					});
				}
				return true;
			})
		);
	}
}
