var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-click-events-have-key-events */
import { ElementRef, AfterViewChecked, EventEmitter, ChangeDetectorRef, OnInit, } from '@angular/core';
import { TzDateService } from '@wcd/localization';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';
var DATA_POINT_HOVER_EVENT = 'TVM hover data point';
var MIN_WIDTH_WITH_DATA = 270;
var MIN_WIDTH_WITHOUT_DATA = 110;
export var SECURE_SCORE_TOOLTIP_ID = 'secureScoreTooltip';
export var EXPOSURE_SCORE_TOOLTIP_ID = 'exposureScoreTooltip';
export var ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID = 'assetsStatisticsExpandedTooltip';
var TvmChartTooltipComponent = /** @class */ (function () {
    function TvmChartTooltipComponent(route, changeDetectorRef, appInsights, router, tzDateService, i18nService) {
        this.route = route;
        this.changeDetectorRef = changeDetectorRef;
        this.appInsights = appInsights;
        this.router = router;
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.percentageDisplay = false;
        this.onNewWidth = new EventEmitter();
        this.onEnterTooltip = new EventEmitter();
        this.onLeaveTooltip = new EventEmitter();
    }
    TvmChartTooltipComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.newDataPoint$) {
            this.newDataPoint$.subscribe(function (dataPoint) {
                _this.score = dataPoint.value;
                _this.date = dataPoint.date;
                _this.formattedDate = _this.tzDateService.format(dataPoint.date, 'MM/dd');
                _this.eventLines = dataPoint.events;
                _this.isDisplayingData = _this.eventLines && _this.eventLines.length > 0;
                _this.minWidth = _this.isDisplayingData ? MIN_WIDTH_WITH_DATA : MIN_WIDTH_WITHOUT_DATA;
                _this.linkText =
                    _this.id === ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID
                        ? _this.i18nService.strings.tvm_changeEvent_showAllEvents
                        : _this.i18nService.strings.tvm_changeEvent_showAllEventsOnDay;
                if (_this.percentageDisplay && 'nominator' in dataPoint) {
                    var percentPoint = dataPoint;
                    _this.percentNominator = percentPoint.nominator;
                    _this.percentDenominator = percentPoint.denominator;
                }
                _this.appInsights.trackEvent(DATA_POINT_HOVER_EVENT, __assign({ chartLegend: _this.legend }, dataPoint));
            });
        }
    };
    TvmChartTooltipComponent.prototype.openEventsPageOnDate = function () {
        var params = null;
        var route = 'tvm-event-insights';
        if (this.id !== ASSETS_STATISTICS_EXPANDED_TOOLTIP_ID) {
            var timeRange = this.date.getTime();
            params = { range: timeRange + ":" + timeRange };
            if (this.id === SECURE_SCORE_TOOLTIP_ID) {
                params['filters'] = 'eventType=NewScid';
            }
        }
        else {
            route = 'event-insights';
        }
        this.router.navigate([route], {
            queryParams: params,
            relativeTo: this.route.parent,
        });
    };
    TvmChartTooltipComponent.prototype.ngAfterViewChecked = function () {
        var _this = this;
        // For the client width to be updated
        this.changeDetectorRef.markForCheck();
        setTimeout(function () {
            var newWidth = _this.eventDiv.nativeElement.clientWidth;
            if (_this._width !== newWidth) {
                _this.onNewWidth.emit(newWidth);
                _this._width = newWidth;
            }
        }, 0);
    };
    return TvmChartTooltipComponent;
}());
export { TvmChartTooltipComponent };
