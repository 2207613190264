import { Injectable, NgZone } from '@angular/core';
import { FolderExclusionModel } from '../models/folder-exclusion.model';
import { FolderExclusionsBackendService } from './folder-exclusions.backend.service';
import { Store } from '../../../data/models/store.base';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class FolderExclusionsStore extends Store<FolderExclusionModel, number | string> {
	constructor(
		backendService: FolderExclusionsBackendService,
		ngZone: NgZone,
		dialogsService: DialogsService,
		i18nService: I18nService
	) {
		super(backendService, ngZone, FolderExclusionModel, dialogsService, i18nService, {
			itemNameSingular: i18nService.strings.admin_folderExclusions_singular,
			itemNamePlural: i18nService.strings.admin_folderExclusions_plural,
			iconName: 'folderExclusion',
		});
	}
}
