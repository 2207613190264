<section
	class="widget {{widget?.className || ''}} widget-top-header"
	[attr.aria-label]="widget?.name"
	[ngClass]="{ 'widget-hide-header': !widget?.showHeader }"
	[ngStyle]="{ 'min-height': widget?.minHeight || 0, 'min-width': widget?.minWidth, 'max-width': widget?.maxWidth, 'height': widget?.height }"
	[hidden]="widget?.hideOnNoData && (!data$ || (!(data$ | async)))"
	[attr.data-hidden]="widget?.hideOnNoData && (!data$ || !(data$ | async))"
	data-track-component-type="Widget"
	[attr.data-track-component]="widget?.id"
	[attr.data-widget-id]="widget?.id"
	[ngStyle]="{ 'min-height': widget?.minHeight, 'max-height': widget?.maxHeight, 'min-width': widget?.minWidth, 'max-width': widget?.maxWidth }"
	[attr.data-widget-loaded]="(data$ | async) ? 'true' : 'false'">
	<div
		class="widget-contents wcd-flex-vertical wcd-full-height">
		<report-widget-header
			*ngIf="widget"
			class="wcd-flex-none"
			[widget]="widget"
			[params]="params"
			[data$]="data$"
			[headerId]="reportWidgetHeaderId"
			[headerLevel]="headerLevel"></report-widget-header>
		<div
			class="widget-contents-body wcd-full-height relative"
			[attr.aria-label]="widget?.name +' '+('charts.widgetContent' | i18n)"
			*ngLet="(error$ | async) as isError"
			[ngClass]="widget?.bodyClassName">
			<div
				class="wcd-full-height loading-contents center-text wcd-padding-vertical"

				[hidden]="(loading$ | async) === false || isError">
				<accessible-spinner *ngIf="(loading$ | async) !== false && !isError"></accessible-spinner>
			</div>
			<div [hidden]="!(data$ | async) || (loading$ | async)" class="wcd-full-height">
				<div class="widget-view" dynamic-contents></div>
			</div>
			<div *ngIf="!(data$ | async) && (loading$ | async) === false">
				<ng-container *ngIf="widgetComponent.userHasPermission(); else noPermissions">
					<div [ngClass]="{ 'widget-nodata-message widget-error-message':isError}" style="text-align: left !important">
						<h4 class="wcd-margin-small-bottom wcd-font-weight-bold">
							{{isError ? ('dataUnavailable' | i18n) : ('common.noDataToShow' | i18n) }}
						</h4>
						<div *ngIf="!isError" class="wcd-margin-bottom" [innerHTML]="widgetNoDataMessage">
						</div>
					</div>
				</ng-container>
			</div>
		</div>
	</div>
</section>

<ng-template #noPermissions>
	<h4 class="wcd-margin-medium-bottom">{{ 'common.permissions.noPermissionTitle' | i18n }}</h4>
	{{ 'common.permissions.noPermissionDescription' | i18n }}
</ng-template>
