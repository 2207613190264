import { FeaturesService } from '@wcd/config';
import { Injectable } from '@angular/core';
import { SENSE_ENDPOINT_MANAGEMENT_PACKAGES } from './endpoint-management-processes.service';
import {
	EndpointManagementFlow,
	EndpointManagementProcess,
	EndpointManagementPackage,
} from '../models/endpoint-management-process.model';

@Injectable()
export class EndpointManagementSensePackagesService {
	constructor(private featuresService: FeaturesService) {}

	getEnabledPackages(
		flow: EndpointManagementFlow,
		process: EndpointManagementProcess
	): Array<EndpointManagementPackage> {
		return Object.values(SENSE_ENDPOINT_MANAGEMENT_PACKAGES).filter(endpointPackage =>
			this.isPackageEnabled(endpointPackage, flow, process)
		);
	}

	private isPackageEnabled(
		endpointPackage: EndpointManagementPackage,
		flow: EndpointManagementFlow,
		process: EndpointManagementProcess
	): boolean {
		if (endpointPackage.features && !this.featuresService.isAnyEnabled(endpointPackage.features))
			return false;

		if (
			endpointPackage.disableFeatures &&
			this.featuresService.isAnyEnabled(endpointPackage.disableFeatures)
		)
			return false;

		if (
			endpointPackage.disableFlow &&
			endpointPackage.disableFlow.some(
				(disabledForFlow: EndpointManagementFlow) => disabledForFlow.id === flow.id
			)
		)
			return false;

		if (
			process.unsupportedPackageTypes &&
			process.unsupportedPackageTypes.some(
				(mgmtPackage: EndpointManagementPackage) => mgmtPackage.name === endpointPackage.name
			)
		)
			return false;

		return true;
	}
}
