import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Antivirus block',
	pluralName: 'Antivirus block',
	readonly: true,
})
export class AntivirusBlockDetails extends EntityModelBase<string> {
	@EntityField({ data: 'Container' })
	container?: string;

	@EntityField({ data: 'ThreatName' })
	threatName: string;

	@EntityField({ data: 'WasRemediated' })
	wasRemediated?: boolean;

	@EntityField({ data: 'WasExecutingWhileDetected' })
	wasExecutingWhileDetected?: boolean;

	@EntityField({ data: 'Action' })
	remediationAction?: AntivirusRemediationAction;
}

export enum AntivirusRemediationAction {
	Clean = 1,
	Quarantine = 2,
	Remove = 3,
	Block = 10,
}
