import { Component, OnInit, ViewChild } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { VulnerabilityNotificationRulesFields } from '../services/vulnerability-notification-rules.fields';
import { VulnerabilityNotificationRulesService } from '../services/vulnerability-notification-rules.service';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { DataViewComponent } from '../../../../dataviews/components/dataview.component';
import { VulnerabilityNotificationRule } from '@wcd/domain';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'vulnerability-notification-rules-dataview',
	providers: [VulnerabilityNotificationRulesFields, VulnerabilityNotificationRulesService],
	templateUrl: './vulnerability-notification-rules.dataview.html',
})
export class VulnerabilityNotificationRulesDataviewComponent {
	@ViewChild(DataViewComponent, { static: false }) dataViewComponent: DataViewComponent;
	repository: Repository<VulnerabilityNotificationRule>;
	entityType: EntityType<VulnerabilityNotificationRule>;
	isUserAllowedActions: boolean;
	isScc = sccHostService.isSCC;

	constructor(
		private performanceSccService: PerformanceSccService,
		public vulnerabilityNotificationRulesFields: VulnerabilityNotificationRulesFields,
		public vulnerabilityNotificationRulesService: VulnerabilityNotificationRulesService,
		paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService
	) {
		this.repository = paris.getRepository(VulnerabilityNotificationRule);
		this.isUserAllowedActions = vulnerabilityNotificationRulesService.isUserAllowedActions();
		this.entityType = globalEntityTypesService.getEntityType(VulnerabilityNotificationRule);
	}

	openWizardPanel() {
		this.vulnerabilityNotificationRulesService.showWizardPanel().then(() => {
			this.dataViewComponent.reloadData();
		});
	}

	onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('vulnerability.notification-rules');
	}
}
