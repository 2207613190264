<div class="wcd-full-height wcd-flex-vertical">

	<header class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal wcd-flex-none">
		<div class="page-header-icon wcd-flex-none">
			 <wcd-shared-icon class="round-icon color-box-gray-200" [iconName]="'shield'"> </wcd-shared-icon >
		</div>
		<div class="wcd-flex-1 wcd-flex-center-vertical wcd-flex-none">
			<div class="wcd-flex-none">
				<h3 class="side-panel-title">Machine secure score</h3>
			</div>
		</div>
	</header>

	<div class="wcd-padding-vertical wcd-padding-large-horizontal">

		<div class="wcd-padding-vertical wcd-padding-large-horizontal">
			<machine-score-pie-widget style="pointer-events: none" [machine]="machine" [machineSecurityLatestState]="machineSecurityAnalytics">
			</machine-score-pie-widget>
		</div>

		<table class="wcd-table wcd-full-width table-grouped">
			<thead>
				<th style="width:25rem">Recomendation</th>
				<th>Score</th>
			</thead>
			<tbody *ngFor="let control of controls">
				<tr (click)="control.isExpanded = (!control.isExpanded && control.overallStatus=='1')" class="table-group-header">
					<td>
						{{"securityAnalytics." + control.type + ".uiLabel" | i18n}}
						<span>{{getAdditionalInfo(control.type)}}</span>
					</td>
					<td>
						 <wcd-shared-icon class="icon small-icon" [iconName]="control.overallStatus=='0' ? 'success' : 'warning'" [ngClass]="{ 'color-text-success-light': control.overallStatus=='0', 'color-text-warning-dark': control.overallStatus=='1' }"> </wcd-shared-icon >
						{{control.score}}/{{control.maxScore}}
						 <wcd-shared-icon *ngIf="control.properties.length > 0 && control.overallStatus=='1'" class="icon pull-right" [iconName]="control.isExpanded ? 'carets.up' : 'carets.down'"> </wcd-shared-icon >
					</td>
				</tr>
				<ng-container *ngIf="control.isExpanded && control.properties.length > 0">
					<ng-container *ngFor="let property of control.properties; let lastProperty = last">
						<tr *ngIf="ShouldPropertyBeDisplayed(property.propertyName)">
							<td (click)="doPropertyAction(property.propertyName)">
								{{"securityAnalytics." + control.type + ".properties." + property.propertyName | i18n}}
								<span *ngIf="ShouldPropertyDisplayAction(property.propertyName)">
									 <wcd-shared-icon [iconName]="'carets.right'">  </wcd-shared-icon >
								</span>
							</td>
							<td colspan=1>
								<ng-container *ngIf="property.improvementScore > 0">
									+ {{property.improvementScore}} points
								</ng-container>
							</td>
						</tr>
					</ng-container>
				</ng-container>
			</tbody>
		</table>
	</div>
</div>
