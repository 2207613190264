import { flatten } from 'lodash-es';
import { ServiceSourceValue, serviceSourceValues } from './service-source.entity';

const SERVICE_SOURCE_PREFIX = 'SS_';
const DETECTION_SOURCE_PREFIX = 'DS_';
const SOURCE_SEPARATOR = '___';

type SerializedFilters = Record<string, string | Array<string>>;

export function deserializeServiceSource(name: string): string {
	return name.replace(SERVICE_SOURCE_PREFIX, '');
}

export function deserializeDetectionSource(name: string): string {
	return name.replace(DETECTION_SOURCE_PREFIX, '');
}

export function serializeServiceSource(name: string | number): string {
	return `${SERVICE_SOURCE_PREFIX}${name}`;
}

export function serializeDetectionSource(detectionSourceName: string, serviceSourceId: string): string {
	return `${DETECTION_SOURCE_PREFIX}${serviceSourceId}${SOURCE_SEPARATOR}${detectionSourceName}`;
}

export function isServiceSource(value: string): boolean {
	return value.indexOf(SERVICE_SOURCE_PREFIX) === 0;
}

export function isDetectionSource(value: string): boolean {
	return value.indexOf(DETECTION_SOURCE_PREFIX) === 0;
}

export function serializeFilterValues(selectedFilterValues: string[]): { serviceSources: string } {
	if (!selectedFilterValues || !selectedFilterValues.length) {
		return null;
	}

	const serializedSelectedValues: Record<string, string[]> = {};

	populateValuesBySingleChildedSelectedServiceSources(selectedFilterValues, serializedSelectedValues);
	populateValuesBySelectedDetectionSources(selectedFilterValues, serializedSelectedValues);

	return {
		serviceSources: JSON.stringify(serializedSelectedValues),
	};
}

export function deserializeFilterValues(serializedValues: SerializedFilters): string[] {
	if (!(serializedValues && serializedValues.serviceSources && serializedValues.serviceSources[0])) {
		return null;
	}

	const serviceSources = JSON.parse(serializedValues.serviceSources[0]);
	const serviceSourceIds = Object.keys(serviceSources);
	// selection state for service sources with more than 1 child should be determined by the selection
	// of their children, and single-childed service sources should be explicitly marked as selected
	const singleChildedSelectedServiceSourceValues: string[] = extractSingleChildedServiceSourceValues(
		serviceSourceIds
	);
	const selectedDetectionSourcesValues = extractDetectionSourcesValues(serviceSources, serviceSourceIds);

	return [...singleChildedSelectedServiceSourceValues, ...selectedDetectionSourcesValues];
}

function populateValuesBySingleChildedSelectedServiceSources(
	selectedFilterValues: string[],
	serializedSelectedValues: Record<string, string[]>
) {
	selectedFilterValues
		.filter((selectedValue) => isServiceSource(selectedValue))
		.map((selectedValue) => deserializeServiceSource(selectedValue))
		.forEach((serviceSourceId) => {
			const selectedServiceSource: ServiceSourceValue = serviceSourceValues.find(
				(serviceSource) =>
					serviceSource.id.toString() === serviceSourceId &&
					isSingleChildedServiceSource(serviceSource)
			);

			if (selectedServiceSource) {
				serializedSelectedValues[serviceSourceId] = [selectedServiceSource.children[0].type];
			}
		});
}

function populateValuesBySelectedDetectionSources(
	selectedFilterValues: string[],
	serializedSelectedValues: Record<string, string[]>
) {
	selectedFilterValues
		.filter((selectedValue) => isDetectionSource(selectedValue))
		.map((selectedValue) => deserializeDetectionSource(selectedValue))
		.forEach((selectedValue) => {
			const serviceSourceId = extractServiceSourceId(selectedValue);
			const detectionSourceId = extractDetectionSourceId(selectedValue);

			serializedSelectedValues[serviceSourceId] = serializedSelectedValues[serviceSourceId] || [];
			serializedSelectedValues[serviceSourceId].push(detectionSourceId);
		});
}

function extractServiceSourceId(detectionSourceFilterValue: string): string {
	return detectionSourceFilterValue.split(SOURCE_SEPARATOR)[0];
}

function extractDetectionSourceId(detectionSourceFilterValue: string): string {
	return detectionSourceFilterValue.split(SOURCE_SEPARATOR)[1];
}

function isSingleChildedServiceSource(serviceSource: ServiceSourceValue): boolean {
	return serviceSource && serviceSource.children && serviceSource.children.length === 1;
}

function extractSingleChildedServiceSourceValues(serviceSourceIds: string[]): string[] {
	return serviceSourceIds
		.filter((serviceSourceId) =>
			serviceSourceValues.find(
				(serviceSource) =>
					serviceSource.id.toString() === serviceSourceId &&
					isSingleChildedServiceSource(serviceSource)
			)
		)
		.map((serviceSourceId) => serializeServiceSource(serviceSourceId));
}

function extractDetectionSourcesValues(
	serviceSources: Record<string, string[]>,
	selectedServiceSourcesIds: string[]
) {
	return flatten(
		selectedServiceSourcesIds.map((serviceSourceId) =>
			serviceSources[serviceSourceId].map((detectionSource) =>
				serializeDetectionSource(detectionSource, serviceSourceId)
			)
		)
	);
}
