import { Directive, forwardRef, Input } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[validateMinMax][ngModel],[validateMinMax][formControl]',
	providers: [
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => MinMaxNumberValidator),
			multi: true,
		},
	],
})
export class MinMaxNumberValidator {
	@Input() validateMinMax: MinMaxLimits;

	validate(c: FormControl) {
		if (c.value == null) return null;
		let isOutOfLimits: boolean = false;

		if (this.validateMinMax) {
			isOutOfLimits =
				(this.validateMinMax.min && c.value < this.validateMinMax.min) ||
				(this.validateMinMax.max && c.value > this.validateMinMax.max);
		}

		return isOutOfLimits
			? {
					validateMinMax: {
						valid: false,
					},
			  }
			: null;
	}
}

export interface MinMaxLimits {
	min?: number;
	max?: number;
}
