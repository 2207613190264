import { Component, ChangeDetectionStrategy, ChangeDetectorRef, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import {
	WebContentFilteringMachineListReport,
	WebContentFilteringDomain,
	WebContentFilteringDevice,
	WebContentFilteringCategory,
	WebContentFilteringCategoryType,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { FabricIconNames } from '@wcd/scc-common';

@Component({
	selector: 'web-content-filtering-machine-list-report-details',
	templateUrl: './web-content-filtering-machine-list-details.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebContentFilteringMachineListReportDetailsComponent
	extends EntityPanelComponentBase<WebContentFilteringMachineListReport>
	implements OnInit {
	private _categoryIdNameMappings: { [key: number]: string } = {};

	get report(): WebContentFilteringMachineListReport {
		return this.entity;
	}

	get trendsDeltaPercentageAsAbs(): number {
		return Math.abs(this.entity.trendsDeltaPercentage);
	}

	readonly topDomainsFields = DataTableField.fromList<WebContentFilteringDomain>([
		{
			id: 'domain',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.domain'
			),
			getDisplay: item => item.name,
			getLink: item => `url/${item.name}`,
		},
		{
			id: 'count',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.accessCount'
			),
			getDisplay: item => item.count,
		},
	]);

	readonly topDevicesFields = DataTableField.fromList<WebContentFilteringDevice>([
		{
			id: 'device',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.device'
			),
			getDisplay: item => item.name,
			getLink: item => `machines/${item.deviceId}/main`,
			icon: {
				fabricIcon: () => FabricIconNames.System,
			},
		},
		{
			id: 'deviceCount',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.deviceCount'
			),
			getDisplay: item => item.count,
		},
	]);

	readonly topCategoriesFields = DataTableField.fromList<WebContentFilteringCategory>([
		{
			id: 'category',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.machineList.sections.topCategories.columns.category'
			),
			getDisplay: item => this._categoryIdNameMappings[item.categoryId],
		},
		{
			id: 'count',
			name: this.i18nService.get(
				'reporting.webContentFilteringReport.pivots.machineList.sections.topCategories.columns.accessCount'
			),
			getDisplay: item => item.count,
		},
	]);

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
		const categoryList = this.paris.getRepository(WebContentFilteringCategoryType).entity.values;
		categoryList.forEach(
			(category: WebContentFilteringCategoryType) =>
				(this._categoryIdNameMappings[category.id] = this.i18nService.get(
					`webCategories.categories.${category.name}`
				))
		);
	}

	ngOnInit() {
		super.ngOnInit();
	}
}
