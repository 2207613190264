<!-- tslint:disable:template-accessibility-elements-content -->
<div class="wcd-full-height wcd-flex-vertical wcd-scroll-vertical">

	<header class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
		<div class="page-header-icon wcd-flex-none">
		</div>
		<div class="wcd-flex-1 wcd-flex-center-vertical">
			<div>
				<h3 class="side-panel-title">Missing security updates</h3>
			</div>
		</div>
	</header>

	<div class="wcd-padding-vertical wcd-padding-large-horizontal">
		<h3 class="side-panel-title"></h3>
		<div *ngFor="let cve of machineSecurityCves">
			<h4> {{cve.cveId + " (" + cve.kbFullName + ")"}} </h4>
	</div>
	</div>
</div>
