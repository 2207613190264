import { AirsEntityType } from '@wcd/domain';
export function mapCloudLogonSessionEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "Account" /* Account */:
                    acc['account'] = {
                        username: val['Name'],
                        nt_domain: val['NTDomain'],
                        sid: val['Sid'],
                        display_name: val['DisplayName'],
                        type_id: AirsEntityType.Account,
                    };
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    break;
                case "SessionId" /* SessionId */:
                    acc['session_id'] = val;
                    break;
                case "UserAgent" /* UserAgent */:
                    acc['user_agent'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
