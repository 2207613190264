import { Injectable } from '@angular/core';
import { VirusTotalFileReport } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PrettyNumberService } from '@wcd/prettify';
import { ValueWithExternalLinkContext } from '../../../shared/components/value-with-external-link.component';

@Injectable()
export class VirusTotalFileReportService {
	constructor(
		private readonly prettyNumberService: PrettyNumberService,
		private readonly i18nService: I18nService
	) {}

	hasVirusTotalData(virusTotalFileReport: VirusTotalFileReport): boolean {
		return (
			virusTotalFileReport && virusTotalFileReport.total > 0 && virusTotalFileReport.permalink != null
		);
	}

	toValueWithExternalLinkContext(virusTotalFileReport: VirusTotalFileReport): ValueWithExternalLinkContext {
		const { positives, total, permalink } = virusTotalFileReport;

		return {
			value: `${this.prettyNumberService.prettyNumber(
				positives
			)}/${this.prettyNumberService.prettyNumber(total)}`,
			icon: 'externalSource',
			verticalAlign: 'baseline',
			link: permalink,
			title: this.i18nService.get('files.entityDetails.sections.detections.fields.virusTotal.title'),
		};
	}
}
