import {
	EntityRelationship,
	RelationshipType,
	EntityRelationshipRepositoryType,
	DataQuery,
} from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { AssetMissingKb } from './assetMissingKb.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: AssetMissingKb,
	dataEntity: Vulnerability,
	endpoint: (_, query) => `assets/${encodeURIComponent(query.where['id'])}/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (assetMissingKb: AssetMissingKb) => {
		return { id: assetMissingKb.assetId, productFixId: assetMissingKb.productFixId };
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		const query = TvmAnalyticsSharedEntityRelationshipConfigurations.parseDataQuery(dataQuery);
		const productFixId = dataQuery.where['productFixId'];
		if (productFixId) {
			query['missingKb'] = productFixId;
		}
		return query;
	},
})
export class AssetMissingKbVulnerabilityRelationship
	implements EntityRelationshipRepositoryType<AssetMissingKb, Vulnerability> {}
