import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { HuntingDatabaseSchema, HuntingDatabaseSchemaApiCall } from '@wcd/domain';

@Injectable({ providedIn: 'root' })
export class SchemaService {
	constructor(private readonly paris: Paris) {}

	getSchema(): Observable<HuntingDatabaseSchema> {
		return this.paris.apiCall(HuntingDatabaseSchemaApiCall);
	}
}
