import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediationAction } from './remediation-action.entity';

@ValueObject({
	singularName: 'Remediation Action Type Main Entities',
	pluralName: 'Remediation Action Type Main Entities',
})
export class RemediationActionTypeMainEntities extends ModelBase {
	@EntityField() fieldName: keyof RemediationAction;
	@EntityField() entityTypeId: number;
	@EntityField() isList: boolean;
}
