/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./colored-dot-indication-field.component";
var styles_ColoredDotIndicationFieldComponent = [];
var RenderType_ColoredDotIndicationFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ColoredDotIndicationFieldComponent, data: {} });
export { RenderType_ColoredDotIndicationFieldComponent as RenderType_ColoredDotIndicationFieldComponent };
function View_ColoredDotIndicationFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(5, null, ["\n\t\t\t", "\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"; var currVal_1 = ("color-box-" + _co.fieldData.color); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.fieldData.name; _ck(_v, 5, 0, currVal_2); }); }
export function View_ColoredDotIndicationFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ColoredDotIndicationFieldComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.fieldData; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_ColoredDotIndicationFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "colored-dot-indication-field", [], null, null, null, View_ColoredDotIndicationFieldComponent_0, RenderType_ColoredDotIndicationFieldComponent)), i0.ɵdid(1, 49152, null, 0, i2.ColoredDotIndicationFieldComponent, [], null, null)], null, null); }
var ColoredDotIndicationFieldComponentNgFactory = i0.ɵccf("colored-dot-indication-field", i2.ColoredDotIndicationFieldComponent, View_ColoredDotIndicationFieldComponent_Host_0, { fieldData: "fieldData" }, {}, []);
export { ColoredDotIndicationFieldComponentNgFactory as ColoredDotIndicationFieldComponentNgFactory };
