import { Component, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { WizardStepConfig } from '@wcd/wizard';
import { VulnerabilityNotificationRuleFormData } from '../models/vulnerability-notification-rule.models';
import { VulnerabilityEmailNotificationSettingsStepComponent } from './wizard/vulnerability-email-notification-settings-step.component';
import { VulnerabilityNotificationRulesService } from '../services/vulnerability-notification-rules.service';
import { I18nService } from '@wcd/i18n';
import { EmailNotificationRecipientsStepComponent } from '../../../../email-notifications/components/email-notification-recipients-step.component';
import { EmailNotificationReviewStepComponent } from '../../../../email-notifications/components/email-notification-review-step.component';
import { RuleNameStepComponent } from '../../../../email-notifications/components/rule-name-step.component';
import { VulnerabilityChangeEventType } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { TvmNotificationsTextsService } from '../../../../tvm/services/tvm-notifications-texts.service';

@Component({
	selector: 'vulnerability-notification-rule-wizard',
	template: `
		<wcd-panel #panel (close)="onClosePanel()" [settings]="settings">
			<wcd-wizard
				[steps]="steps"
				[data]="formData"
				wizardTitlePrefix="vulnerability.email.notification.wizard.breadcrumbs.prefix"
				[wizardTitle]="title"
				wizardTrackingName="IncidentEmailNotificationWizard"
				(onClose)="onClosePanel()"
				(onDone)="onRuleCreated()"
			></wcd-wizard>
		</wcd-panel>
	`,
})
export class VulnerabilityNotificationRuleWizardComponent extends PanelContainer {
	@ViewChild(PanelComponent, { static: false }) panel: PanelComponent;
	@Input() onDone: Function;
	@Input() onClose: Function;
	@Input() data: VulnerabilityNotificationRuleFormData;

	title: string;
	steps: WizardStepConfig<VulnerabilityNotificationRuleFormData>[];
	formData: VulnerabilityNotificationRuleFormData;
	ruleNameComponentInputs: Map<string, any>;
	recipientsComponentInputs: Map<string, any>;
	reviewRuleComponentInputs: Map<string, any>;
	_isUpdateFlow: boolean;
	tenantHasMachineGroups: boolean;

	constructor(
		private vulnerabilityNotificationRulesService: VulnerabilityNotificationRulesService,
		private i18nService: I18nService,
		private notificationTextsService: TvmNotificationsTextsService,
		appConfigService: AppConfigService,
		router: Router
	) {
		super(router);
		this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
		this.ruleNameComponentInputs = new Map<string, any>([
			['includeStatus', true],
			[
				'ruleNameDescription',
				this.i18nService.strings.vulnerability_email_notification_rule_name_description,
			],
			['nameLength', 50],
			['descriptionLength', 300],
			['nameRegExp', 'tvmVulnerabilityNotificationsAllowedText'],
			['descriptionRegExp', 'tvmVulnerabilityNotificationsAllowedText'],
		]);
		this.recipientsComponentInputs = new Map<string, any>([
			['testMailEnabled', false],
			[
				'introductionText',
				this.i18nService.strings.vulnerability_notifications_recipients_introduction,
			],
		]);
		this.setReviewRuleComponentInputs();
		this.setSteps();
	}

	get isUpdateFlow(): boolean {
		return this._isUpdateFlow;
	}
	@Input() set isUpdateFlow(value: boolean) {
		this._isUpdateFlow = value;
		this.title = this._isUpdateFlow
			? 'vulnerability.email.notification.wizard.breadcrumbs.update'
			: 'vulnerability.email.notification.wizard.breadcrumbs.creation';
		this.formData = { isUpdateFlow: this._isUpdateFlow, ...this.data };
		this.steps[3].nextButtonText = this._isUpdateFlow
			? 'incident.email.notification.wizard.update.rule'
			: 'incident.email.notification.wizard.create.rule';
	}

	onRuleCreated() {
		this.closePanel();
		this.onDone();
	}

	onClosePanel() {
		this.closePanel();
		this.onClose();
	}

	setReviewRuleComponentInputs() {
		this.reviewRuleComponentInputs = new Map<string, any>([
			['shouldDisplayNotificationSettings', data => data.includeOrgName],
			[
				'customSettings',
				data => {
					const customSettingsArray = [
						{
							key: this.i18nService.strings
								.vulnerability_email_notification_wizard_eventType_summary,
							value: Object.values(VulnerabilityChangeEventType)
								.filter(e => data.eventTypes[e])
								.map(e => this.notificationTextsService.getChangeEventTypeLabel(e))
								.join('<br>'),
						},
					];

					if (data.severityLevelForNewCve) {
						customSettingsArray.push({
							key: this.i18nService.strings
								.vulnerability_email_notification_wizard_severity_threshold_summary,
							value: this.i18nService.get(
								`tvm_notifications_vulnerability_${data.severityLevelForNewCve}`
							),
						});
					}

					return customSettingsArray;
				},
			],
			[
				'notificationSettings',
				data => {
					const notificationSettingsArray = [];

					if (data.includeOrgName) {
						notificationSettingsArray.push(
							this.i18nService.strings.email_notification_include_org_name
						);
					}

					return notificationSettingsArray;
				},
			],
			['shouldDisplayDeviceGroups', this.tenantHasMachineGroups],
			['explicitGlobalNotificationText', true],
			[
				'saveRuleFunction',
				this.vulnerabilityNotificationRulesService.saveVulnerabilityNotificationRule,
			],
			[
				'status',
				data =>
					data.isEnabled
						? this.i18nService.strings.tvm_common_active
						: this.i18nService.strings.tvm_common_notActive,
			],
		]);
	}

	setSteps() {
		this.steps = [
			{
				id: 1,
				name: 'RuleName',
				verticalStepKeyName: 'email_notification_wizard_basics',
				component: RuleNameStepComponent,
				componentInputs: this.ruleNameComponentInputs,
			},
			{
				id: 2,
				name: 'VulnerabilityEmailNotificationSettings',
				verticalStepKeyName: 'email.notification.wizard.settings.step.title',
				component: VulnerabilityEmailNotificationSettingsStepComponent,
			},
			{
				id: 3,
				name: 'Recipients',
				verticalStepKeyName: 'email.notification.wizard.recipients.step.title',
				component: EmailNotificationRecipientsStepComponent,
				componentInputs: this.recipientsComponentInputs,
			},
			{
				id: 4,
				name: 'ReviewRule',
				verticalStepKeyName: 'email.notification.wizard.review.rule.step.title',
				component: EmailNotificationReviewStepComponent,
				onNext: true,
				nextButtonText: 'email.notification.wizard.create.rule',
				componentInputs: this.reviewRuleComponentInputs,
			},
		];
	}
}
