import { ComponentRef, OnDestroy } from '@angular/core';
import { RbacService } from '../../rbac/services/rbac.service';
import { ReplaySubject } from 'rxjs';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { MachineGroupsFilterPanelComponent } from '../components/machine-groups-filter/machine-groups-filter-panel/machine-groups-filter-panel.component';
import { PanelType } from '@wcd/panels';
import { tap, takeWhile, filter, skip } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { OrgExposureScoreApiCall, CountVulnerabilitiesBySeverityApiCall, CountMisconfigurationsBySeverityApiCall, RecommendationFiltersApiCall, TotalSecureScoreApiCall, CategorySecureScoreApiCall, AssetsByExposureCategoryApiCall, ComplianceOverTimeApiCall, DeviceComplianceStatisticsApiCall, } from '@wcd/domain';
import * as i0 from "@angular/core";
import * as i1 from "../../rbac/services/rbac.service";
import * as i2 from "../../dialogs/services/dialogs.service";
import * as i3 from "@microsoft/paris/dist/lib/paris";
var TvmMachineGroupsFilterService = /** @class */ (function () {
    function TvmMachineGroupsFilterService(rbacService, dialogsService, paris) {
        var _this = this;
        this.rbacService = rbacService;
        this.dialogsService = dialogsService;
        this.paris = paris;
        this._machineGroupsFilter$ = new ReplaySubject(1);
        this.machineGroupsFilterChange$ = this._machineGroupsFilter$.pipe(skip(1));
        var continueTaking = true;
        // Fetch current selection from window variable (in SCC this service is re-created on each page navigation)
        var previousState = window._tvm_machineGroupsFilterData;
        var currentGroupSelection = new Map();
        if (previousState && previousState.machineGroups) {
            previousState.machineGroups.forEach(function (group) {
                currentGroupSelection.set(group.groupId, group.isSelected);
            });
        }
        this._machineGroupPollSubscription = this.rbacService.userExposedRbacGroups$
            .pipe(filter(function (val) { return !!val; }), takeWhile(function () { return continueTaking; }), tap(function () { return (continueTaking = false); }))
            .subscribe(function (machineGroups) {
            _this._machineGroupsFilterData = {
                isFiltering: false,
                machineGroups: machineGroups.map(function (mg) { return ({
                    groupId: mg.id,
                    groupName: mg.name,
                    isSelected: currentGroupSelection.get(mg.id) || false,
                }); }),
            };
            window._tvm_machineGroupsFilterData = _this._machineGroupsFilterData; //TODO: tmp bridge between TvmMachineGroupsFilterService and the domain (to be used by Entity's "parseDataQuery" to send the groups via query param). fix by extending Paris to pass ParisConfig.data as an arg to parseDataQuery => then we can assign this service instance to the data and use it on parseDataQuery.
            _this._machineGroupsFilter$.next(_this._machineGroupsFilterData);
        });
    }
    Object.defineProperty(TvmMachineGroupsFilterService.prototype, "machineGroupsFilter$", {
        get: function () {
            return this._machineGroupsFilter$;
        },
        enumerable: true,
        configurable: true
    });
    TvmMachineGroupsFilterService.prototype.openMachineGroupsFilterPanel = function () {
        var _this = this;
        this.dialogsService
            .showPanel(MachineGroupsFilterPanelComponent, {
            id: 'machine-groups-filter-panel',
            isModal: true,
            showOverlay: false,
            hasCloseButton: true,
            type: PanelType.large,
            role: 'none'
        }, {
            machineGroupsFilter$: this.machineGroupsFilter$,
        })
            .subscribe(function (panel) {
            panel.instance.onFiltersChange.subscribe(function (selectedMachineGroupIds) {
                panel.destroy();
                _this.handleFilteringChange(selectedMachineGroupIds);
            });
        });
    };
    TvmMachineGroupsFilterService.prototype.handleFilteringChange = function (selectedMachineGroupIds) {
        var _this = this;
        var allSelected = selectedMachineGroupIds.length === this._machineGroupsFilterData.machineGroups.length;
        // if filtering is off and for some reason the user selected all groups, its not a real change so we shouldn't do nothing
        if (!this._machineGroupsFilterData.isFiltering && allSelected) {
            return;
        }
        this._machineGroupsFilterData.isFiltering =
            selectedMachineGroupIds.length && !allSelected ? true : false;
        this._machineGroupsFilterData.machineGroups.forEach(function (machineGroup) {
            machineGroup.isSelected = false;
            if (_this._machineGroupsFilterData.isFiltering &&
                selectedMachineGroupIds.find(function (id) { return id === machineGroup.groupId; })) {
                machineGroup.isSelected = true;
            }
        });
        //without clearing the apiCall caches paris will return cached, non filtered data
        this.paris.clearApiCallCache([
            OrgExposureScoreApiCall,
            CountVulnerabilitiesBySeverityApiCall,
            CountMisconfigurationsBySeverityApiCall,
            RecommendationFiltersApiCall,
            TotalSecureScoreApiCall,
            CategorySecureScoreApiCall,
            AssetsByExposureCategoryApiCall,
            ComplianceOverTimeApiCall,
            DeviceComplianceStatisticsApiCall
        ]);
        this._machineGroupsFilter$.next(this._machineGroupsFilterData);
    };
    TvmMachineGroupsFilterService.prototype.ngOnDestroy = function () {
        this._machineGroupsFilter$.complete();
        this._machineGroupPollSubscription && this._machineGroupPollSubscription.unsubscribe();
    };
    TvmMachineGroupsFilterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TvmMachineGroupsFilterService_Factory() { return new TvmMachineGroupsFilterService(i0.ɵɵinject(i1.RbacService), i0.ɵɵinject(i2.DialogsService), i0.ɵɵinject(i3.Paris)); }, token: TvmMachineGroupsFilterService, providedIn: "root" });
    return TvmMachineGroupsFilterService;
}());
export { TvmMachineGroupsFilterService };
