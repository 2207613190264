import {
	ChangeDetectorRef,
	Component,
	ComponentRef,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { TitleService } from '../../../shared/services/title.service';
import { Alert, MdeUserRoleActionEnum } from '@wcd/domain';
import { AlertsFields } from '../services/alerts.fields';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { isCustomTimeRangeValue, TimeRangeId, TimeRangeValue } from '@wcd/date-time-picker';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertDetailsOptions, AlertsService } from '../services/alerts.service';
import { AlertType, alertTypes, alertTypesArray } from '../models/alert-type.model';
import { Feature, FeaturesService, PreferencesService } from '@wcd/config';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { DataChangedPayload, DataViewComponent } from '../../../dataviews/components/dataview.component';
import { cloneDeep, compact, find } from 'lodash-es';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { take } from 'rxjs/operators';
import { Lazy } from '@wcd/utils';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { AppConfigService } from '@wcd/app-config';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertsDataviewPanelComponent } from './alerts.dataview-panel';
import { I18nService, Strings } from '@wcd/i18n';
import {
	DataviewAction,
	DataviewActionTypes,
} from '../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';

const TIME_RANGE_DEFAULT_PREFERENCE_ID = 'alerts_time_range_default';

@Component({
	selector: 'alerts-dataview',
	template: `
		<div class='wcd-full-height wcd-flex-vertical'>
			<wcd-banner
				[text]="i18nService.strings.alerts_queue_m365_incidents_queue_promotion_banner"
				[linkOptions]="microsoft365DefenderPromotionToIncidentsQueueLink"
			>
			</wcd-banner>

			<convergence-promotion-banner sccPagePath='/incidents' *ngIf="promotionBannerFeatureEnabled"></convergence-promotion-banner>

			<div class="queue-layout-header wcd-padding-xxLarge-horizontal" dataview-header *ngIf="showHeader">
				<h2>{{ titleService.pageTitle$ | async }}</h2>
			</div>

			<div class="wcd-flex-1 wcd-full-height wcd-flex-vertical">
				<dataview
					*ngIf="fields"
					class="wcd-full-height"
					[class.wcd-padding-xxLarge-horizontal]="dataviewHorizontalPadding"
					[id]="dataViewId || 'alerts'"
					[className]="className"
					[repository]="repository"
					[entityType]="entityType"
					[fields]="fields"
					[disabledFields]="disabledFields"
					[sortDisabledFields]="sortDisabledFields"
					[itemSelectable]="isAlertSelectable"
					[defaultSortFieldId]="defaultSortId"
					[isItemGroup]="isGroup"
					[setItemActions]="boundSetItemActions"
					[allowGroupItems]="allowGrouping && alertType.isGrouped"
					[getFilterQueryOptions]="entityType?.dataViewOptions.getFilterQueryOptions"
					[allowPaging]="allowPaging"
					[refreshOn]="refreshOn"
					[allowFilters]="allowFilters"
					[hideControls]="hideControls"
					[disableSelection]="disableSelection"
					[responsiveLayout]="responsiveLayout"
					[padLeft]="false"
					[fixedOptions]="dataviewFixedOptions"
					[getGroupItems]="boundGetContainedAlerts"
					[dataViewConfig]="dataViewConfig"
					[showDialogOnError]="showDialogOnError"
					(onData)="onDataIn($event)"
					[visibleFields]="visibleFields"
					[maxItems]="maxItems"
					[label]="'alerts.title' | i18n"
					[fixedTable]="fixedTable"
					disableFilterPanelAutoFocus="true"
					commandBarNoPadding="true"
					[focusOnTable]="focusOnTable"
					[queueHeader]="queueHeader"
					[entityPanelOptions]="entityPanelOptions"
					(afterTableInit)="afterTableInit($event)"
					[tabIndex]="(allowTimeRangeSelect || allowGrouping) && !isFirstInitDone ? false : -1"
					[focusOnFirstMenuItem]="!allowTimeRangeSelect && !allowGrouping"
					[responsiveActionBar]="responsiveActionBar"
					[customActionsRight]="customActionsRight ? commandBarRight : []"
					[removePadding]="removePadding"
					[removePaddingRight]="removePaddingRight"
					[removePaddingLeftOnResize]="removePaddingLeftOnResize"
					[fullHeight]="fullHeight"
					[entitySidePanelSettings]="{ role: 'none'}"
					(onTableRenderComplete)="onTableRenderCompleteLoaded()">
					<!--need to remove fancy select button when all command bars that use alerts.dataview will be responsiveActionBars-->
					<ng-container dataview-controls>
						<fancy-select
							[(ngModel)]="currentTimeRange"
							*ngIf="allowTimeRangeSelect"
							(ngModelChange)="onRangeTypeSelect($event)"
							class="command-bar-item-dropdown"
							[buttonIcon]="'calendar'"
							[label]="'name'"
							[values]="timeRanges"
							[ariaLabel]="'dataview.timePeriod' | i18n"
							[focusOnInit]="!isFirstInitDone"
							(focus)="afterFirstElementFocused()"
						></fancy-select>
					</ng-container>
					<ng-container dataview-controls-right>
						<fancy-select
							*ngIf="allowGrouping"
							[(ngModel)]="alertType"
							(ngModelChange)="onAlertTypeChange()"
							class="command-bar-item-dropdown"
							icon="icon"
							[values]="alertTypes"
							[formatLabel]="boundFormatVal"
							[focusOnInit]="!allowTimeRangeSelect && !isFirstInitDone"
							(focus)="afterFirstElementFocused()"
						></fancy-select>
					</ng-container>
				</dataview>
			</div>
			<div>
				<button
					*ngIf="maxItems && alertsData?.items?.length >= maxItems"
					(click)="onShowMoreAlerts()"
					data-track-id="ShowMoreAlerts"
					data-track-type="Button"
					class="small-button btn-inline btn-link no-padding"
				>
					{{ 'alerts.showMore' | i18n }}
				</button>
			</div>
		</div>
	`,
})
export class AlertsDataviewComponent implements OnInit, OnChanges {
	@Input() dataViewId: string;
	@Input() disabledFields: Array<string>;
	@Input() onlyFields: Array<string>;
	@Input() sortDisabledFields: Array<string>;
	@Input() responsiveLayout: boolean = false; // see dataview.component.ts for usage
	@Input() allowPaging: boolean = true;
	@Input() allowFilters: boolean = true;
	@Input() fixedOptions: { [index: string]: any };
	@Input() allowGrouping: boolean = true;
	@Input() allowTimeRangeSelect: boolean = true;
	@Input() hideControls: boolean = false;
	@Input() disableSelection: boolean = false;
	@Input() showHeader: boolean = true;
	@Input() className: string;
	@Input() alertDetailsOptions: AlertDetailsOptions;
	@Input() repository: Repository<Alert>;
	@Input() refreshOn: any;
	@Input() visibleFields: Array<string>;
	@Input() showDialogOnError: boolean = true;
	@Input() defaultSortId: string = 'lasteventtime';
	@Input() maxItems: number;
	@Input() maxColumnWidth: number;
	@Input() fixedTable: boolean;
	@Input() queueHeader: boolean = true;
	@Input() entityPanelOptions: { [index: string]: any };
	@Input() focusOnTable?: boolean = true;
	@Input() responsiveActionBar: boolean = false;
	@Input() customActionsRight: boolean = false;
	@Input() removePadding = true;
	@Input() removePaddingRight = false;
	@Input() removePaddingLeftOnResize = false;
	@Input() fullHeight = true;
	@Output() onData = new EventEmitter<DataChangedPayload<Alert>>();
	@Output() onTableRenderComplete: EventEmitter<any> = new EventEmitter();
	@Input() dataviewHorizontalPadding = true;

	alertTypePreferenceId: string;

	@ViewChild(DataViewComponent, { static: false }) dataView: DataViewComponent;
	entityType: EntityType<Alert, AlertDetailsOptions>;
	fields: Array<DataviewField<Alert>>;

	readonly alertTypes: Array<AlertType> = alertTypesArray;

	boundGetContainedAlerts: (alert: Alert) => Observable<Array<Alert>>;
	boundSetItemActions: (selectedAlerts: Array<Alert>) => Array<ItemActionModel>;
	boundFormatVal: Function;
	commandBarRight: DataviewAction[] = [];
	alertType: AlertType;
	alertsData: DataSet<Alert>;
	dataviewFixedOptions: { [index: string]: any };

	wasTableInit: boolean = false;
	isFirstElementBeenFocused: boolean = false;
	isFirstInitDone: boolean = false;

	promotionBannerFeatureEnabled: boolean = false;

	currentTimeRange: TimeRangeValue;
	currentRangeSubject: BehaviorSubject<TimeRangeValue>;
	private alertsSidePanel: ComponentRef<AlertsDataviewPanelComponent>;
	private _originalDataViewConfig: DataViewConfig;
	private readonly _timeRanges = new Lazy(() => {
		return this.timeRangesService.pick([
			TimeRangeId.day,
			TimeRangeId['3days'],
			TimeRangeId.week,
			TimeRangeId.month,
			TimeRangeId['6months'],
		]);
	});

	microsoft365DefenderPromotionToIncidentsQueueLink: { id: string; buttonTextKey: keyof Strings; navigateTo: string; } =	{
		id: 'Microsoft_365_Defender_Promotion_To_Incidents_Queue',
		buttonTextKey: 'alerts_queue_m365_incidents_queue_promotion_banner_link_title',
		navigateTo: 'https://security.microsoft.com/incidents'
	}

	constructor(
		public paris: Paris,
		public alertFields: AlertsFields,
		public alertsService: AlertsService,
		public titleService: TitleService,
		public authService: AuthService,
		private router: Router,
		private preferencesService: PreferencesService,
		private route: ActivatedRoute,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly timeRangesService: TimeRangesService,
		private rbacService: RbacService,
		private appConfigService: AppConfigService,
		private dialogsService: DialogsService,
		private changeDetectorRef: ChangeDetectorRef,
		private featuresService: FeaturesService,
		public i18nService: I18nService,
	) {
		this.entityType = globalEntityTypesService.getEntityType(Alert);
		this.boundGetContainedAlerts = this.getContainedAlerts.bind(this);
		this.boundSetItemActions = this.setItemActions.bind(this);
		this.boundFormatVal = this.formatVal.bind(this);
		this.promotionBannerFeatureEnabled = this.featuresService.isEnabled(Feature.ShowAlertsQueueBannerForM365Incidents);
	}

	get timeRanges() {
		return this._timeRanges.value;
	}

	get isUserAllowedActions() {
		return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.alertsInvestigation);
	}

	private _dataViewConfig: DataViewConfig;

	get dataViewConfig(): DataViewConfig {
		return this._dataViewConfig;
	}

	@Input()
	set dataViewConfig(value: DataViewConfig) {
		this._originalDataViewConfig = cloneDeep(value);
		this._dataViewConfig = this.getDataViewConfig();
	}

	ngOnInit() {
		if (!this.repository) this.repository = this.paris.getRepository(Alert);

		this.alertTypePreferenceId = `${this.dataViewId || 'alerts'}_alertsListType`;

		const currPreference = this.preferencesService.getPreference(this.alertTypePreferenceId);
		this.alertType =
			alertTypes[
				currPreference
					? currPreference
					: this.allowGrouping
					? alertTypes.grouped.type
					: alertTypes.flat.type
				];
		this.currentTimeRange = this.appConfigService.isDemoTenant
			? this.timeRanges[this.timeRanges.length - 1]
			: this.timeRanges.find(
			timeRange =>
				timeRange.id ===
				this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID),
		) || this.timeRanges[3];

		this.currentRangeSubject = new BehaviorSubject(this.currentTimeRange);
		if(this.responsiveActionBar)
			this.setCommandBarRight();

		this.route.queryParams.subscribe((params: Params) => {
			const locationRange = params.range;
			let locationRangeChange: boolean = false;

			if (locationRange) {
				const foundRange: TimeRangeValue = find(this.timeRanges, range => range.id === locationRange);

				locationRangeChange = foundRange !== this.currentTimeRange;
				if (locationRangeChange && foundRange) this.currentTimeRange = foundRange;
			}

			if (locationRangeChange) {
				this.setFixedOptions();
				this._dataViewConfig = this.getDataViewConfig();
			}
		});

		this.setFixedOptions();
		this._dataViewConfig = this.getDataViewConfig();
		// alert fields are affected by rbac status. Load them only when rbac status is known.
		this.rbacService.isFilteringNecessary$.pipe(take(1)).subscribe(val => this.setFields());
	}

	ngOnChanges(changes) {
		if (changes.fixedOptions) {
			this.setFixedOptions();
		}
	}

	afterTableInit(loading: boolean) {
		this.wasTableInit = loading;
		this.checkIfFirstInitDone();
	}

	afterFirstElementFocused() {
		this.isFirstElementBeenFocused = true;
		this.checkIfFirstInitDone();
	}

	/**
	 *  focusing on the first interactive element
	 *  Fixing bug: 27220333 (https://dev.azure.com/microsoft/OS/_workitems/edit/27220333/)
	 */
	checkIfFirstInitDone() {
		if (this.isFirstElementBeenFocused && this.wasTableInit && !this.isFirstInitDone) {
			this.isFirstInitDone = true;
		}
	}

	updateTableHeaders() {
		if (this.dataView) {
			this.dataView.updateHeaders();
		}
	}

	formatVal(value) {
		return this.i18nService.get(`alert.types.${value.id}`);
	}

	private setFields() {
		if (this.alertType.isGrouped) {
			this.fields = this.alertFields.fields;
		} else {
			this.fields = this.alertFields.fields.filter(field => field.id !== 'expand');
		}

		if (this.onlyFields) {
			this.fields = this.fields.filter(f => this.onlyFields.includes(f.id));
		}
		if (this.maxColumnWidth) {
			this.fields = this.fields.map((field: DataviewField) =>
				field.clone({ maxWidth: field.maxWidth || this.maxColumnWidth }),
			);
		}
		this.changeDetectorRef.markForCheck();
	}

	isAlertSelectable(alert: Alert) {
		return alert.id;
	}

	isGroup(alert: Alert) {
		return alert.isGroup;
	}

	onAlertTypeChange() {
		this.setFixedOptions();
		this.preferencesService.setPreference(this.alertTypePreferenceId, this.alertType.type);
		this.setFields();
	}

	onRangeTypeSelect(value) {
		this.currentTimeRange = value;
		if (this.currentTimeRange)
			this.preferencesService.setPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID, this.currentTimeRange.id);

		this.router.navigate(['.'], {
			relativeTo: this.route,
			queryParams: {
				page: null,
				range: this.currentTimeRange.id,
			},
			queryParamsHandling: 'merge',
		});

		this.setFixedOptions();
	}

	private setFixedOptions() {
		this.dataviewFixedOptions = Object.assign(
			{
				alertType: this.allowGrouping ? this.alertType.id : alertTypes.flat.id,
				lookBackInDays:
					this.allowTimeRangeSelect && !isCustomTimeRangeValue(this.currentTimeRange)
						? this.currentTimeRange.value
						: 180,
			},
			this.fixedOptions,
		);
	}

	private getContainedAlerts(alert: Alert, options?: {}): Observable<Array<Alert>> {
		return this.alertsService.getContainedAlerts(alert, options);
	}

	private getDataViewConfig(): DataViewConfig {
		if (this._originalDataViewConfig) {
			const dataViewConfig: DataViewConfig = cloneDeep(this._originalDataViewConfig);
			return dataViewConfig;
		} else {
			return {
				getFiltersData: () => this.alertsService.getAlertsFilters(),
			};
		}
	}

	private setItemActions(selectedAlerts: Array<Alert>): Array<ItemActionModel> {
		if (!selectedAlerts || !selectedAlerts.length) return [];

		const allAlertsAreAssignedToCurrentUser: boolean = selectedAlerts.every(
			(alert: Alert) => alert.assignedTo === this.authService.currentUser.username,
		);

		return compact([
			allAlertsAreAssignedToCurrentUser || !this.isUserAllowedActions
				? null
				: {
					id: 'assignToMe',
					icon: 'users.userCheck',
					name: 'Assign to me',
					method: (alerts: Array<Alert>) =>
						this.alertsService.assignAlertsToCurrentUser(alerts).toPromise(),
					tooltip: 'Assign the selected alerts to the logged-in user',
					refreshOnResolve: true,
				},
		]).map(itemAction => new ItemActionModel(itemAction));
	}

	onDataIn(alertsData: DataChangedPayload<Alert>) {
		this.alertsData = alertsData.data;
		this.onData.emit(alertsData);
	}

	onShowMoreAlerts() {
		this.dialogsService
			.showPanel(
				AlertsDataviewPanelComponent,
				{
					id: 'alerts-dataview-panel-dataview',
					type: PanelType.large,
					isModal: true,
					noBodyPadding: true,
					scrollBody: false,
					noShadow: true,
					back: {
						onClick: () => this.alertsSidePanel.destroy(),
					},
				},
				{
					alerts: this.alertsData.items,
					defaultSortBy: this.defaultSortId,
					onlyFields: this.onlyFields,
				},
			)
			.subscribe((panel: ComponentRef<AlertsDataviewPanelComponent>) => {
				this.alertsSidePanel = panel;

				panel.onDestroy(() => {
					this.alertsSidePanel = null;
				});
			});
	}

	private setCommandBarRight() {
		this.commandBarRight = [
			{
				onSelectionChanged: this.onRangeTypeSelect.bind(this),
				currentSelection: this.currentRangeSubject,
				selections: this.timeRanges,
				icon: FabricIconNames.Calendar,
				ariaLabel: this.i18nService.get('dataview.timePeriod'),
				dataTrackId: 'timeRange_IncidentsDataview',
				listId: 'timeRangeSelector',
				focus: this.afterFirstElementFocused.bind(this),
				focusOnInit: !this.isFirstInitDone,
				propertyIdForLabel: 'name',
				actionType: DataviewActionTypes.FancySelect,
			},
		];
	}

	onTableRenderCompleteLoaded(){
		this.onTableRenderComplete.emit();
	}
}
