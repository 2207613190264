import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertDetermination', alertDeterminationFilter);

function alertDeterminationFilter() {
	return function(input) {
		if (!input) {
			return '';
		}

		switch (input) {
			case 0:
			case '0':
			case 'NotAvailable':
				return null;
			case 10:
			case '10':
			case 'Apt':
				return 'APT';
			case 20:
			case '20':
			case 'Malware':
				return 'Malware';
			case 30:
			case '30':
			case 'SecurityPersonnel':
				return 'Security personnel';
			case 40:
			case '40':
			case 'SecurityTesting':
				return 'Security testing';
			case 50:
			case '50':
			case 'UnwantedSoftware':
				return 'Unwanted software';
			case 60:
			case '60':
			case 'Other':
				return 'Other';
			case 'Multiple':
				return 'Multiple';
			default:
				return null;
		}
	};
}
