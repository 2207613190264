import {
	EmailRemediationActionSubType,
	AlertV3ActionTypeMap,
} from '../../action-history/alertV3-action-type-types';
import { AirsEntityType, AirsEntityTypeValue } from '../../airs_entity/airs-entity-type.entity';
import { EngineReportParameters } from '../../airs_entity/engine-report-parameters.enum';
import {
	getConvertedEntityTypeName,
	getEngineReportData,
	getMailboxEntityCommonData,
	getThreatsNames,
} from '../../mtp-investigation/mtp-investigation.entity';
import { InvestigationProviderTypeEnum } from '../../mtp-investigation/investigation-provider.enum';
import { remediationActionTypeValues } from '../../remediation/remediation-action-type.values';
import { dateStrToUtc } from '../../utils';
import {
	getMailClusterMailboxCount,
	getMailClusterNotInMailboxCount,
	getMailClusterOnPremOrExternalCount,
} from '../../mailbox/mail-cluster.utils';

export function getConvertedOfficeActionType(officeAction: { [key: string]: any }): string {
	// this is a temp solution until office fix the response and send the type as ActionType and not as SubActionType
	const convertedActionType =
		officeAction.ActionType === EmailRemediationActionSubType.EmailRemediation &&
		officeAction.SubActionType
			? officeAction.SubActionType
			: officeAction.ActionType;

	// use EmailRemediation as a fallback in case 'SubActionType' value not exists in the mapping
	return AlertV3ActionTypeMap[convertedActionType] ? convertedActionType : officeAction.ActionType;
}

export enum OfficeThreatTypes {
	Phish = 'Phish',
	Malware = 'Malware',
	HighConfidencePhish = 'HighConfPhish',
	Spam = 'Spam',
}

export enum OfficeProtectionStatus {
	Unknown = 'Unknown',
	DeliveredAsSpam = 'DeliveredAsSpam',
	Delivered = 'Delivered',
	Blocked = 'Blocked',
	Replaced = 'Replaced',
}

export const enum OfficeDeliveryLocation {
	Unknown = 'Unknown',
	Inbox = 'Inbox',
	JunkFolder = 'JunkFolder',
	DeletedFolder = 'DeletedFolder',
	Quarantine = 'Quarantine',
	External = 'External',
	Failed = 'Failed',
	Dropped = 'Dropped',
	Forwarded = 'Forwarded',
	CloudMailBox = 'CloudMailBox',
	Others = 'Others',
}

export function mapOfficeActionToRemediationAction(officeAction, entityData) {
	if (!officeAction) return null;
	const convertedOfficeActionType = getConvertedOfficeActionType(officeAction);
	const investigationActionType = AlertV3ActionTypeMap[convertedOfficeActionType];
	const actionType = remediationActionTypeValues.find(s => s.type === investigationActionType);

	const remediationAction = {
		investigation_id: officeAction.InvestigationId,
		action_ids: [officeAction.ActionId],
		action_type: actionType,
		IsOfficeInvestigation: entityData.Source === InvestigationProviderTypeEnum.OATP,
		commentData: officeAction.ApproverComment
			? {
					approverComment: officeAction.ApproverComment,
					actionApprovedTime:
						officeAction.ActionApprovedTime &&
						(officeAction.ActionApprovedTime.endsWith('Z')
							? officeAction.ActionApprovedTime
							: officeAction.ActionApprovedTime + 'Z'),
					approvedBy: officeAction.ApprovedBy,
			  }
			: null,
	};

	// extract data of action from the entity
	const convertedEntityTypeCase = getConvertedEntityTypeName(entityData.Type);

	const convertedProtectionStatus = entityData.CountByProtectionStatus;

	switch (convertedEntityTypeCase) {
		case AirsEntityTypeValue.MailCluster:
			remediationAction['mailCluster'] = {
				ClusterBy: entityData.ClusterBy,
				ClusterByValue: entityData.ClusterByValue,
				FirstSeen: dateStrToUtc(entityData.FirstSeen),
				ThreatsKeys: getThreatsNames(entityData.ThreatAnalysisSummary),
				name: AirsEntityType[convertedEntityTypeCase],
				MailboxPrimaryAddress: entityData.MailboxPrimaryAddress,
				id: entityData.Urn,
				PhishCount:
					(entityData.CountByThreatType && entityData.CountByThreatType[OfficeThreatTypes.Phish]) ||
					null,
				MalwareCount:
					(entityData.CountByThreatType &&
						entityData.CountByThreatType[OfficeThreatTypes.Malware]) ||
					null,
				HighConfidencePhishCount:
					(entityData.CountByThreatType &&
						entityData.CountByThreatType[OfficeThreatTypes.HighConfidencePhish]) ||
					null,
				SpamCount:
					(entityData.CountByThreatType && entityData.CountByThreatType[OfficeThreatTypes.Spam]) ||
					null,
				MailCount: entityData.MailCount || 0,
				DeliveredCount:
					(convertedProtectionStatus &&
						convertedProtectionStatus[OfficeProtectionStatus.Delivered]) ||
					null,
				JunkedCount:
					(convertedProtectionStatus &&
						convertedProtectionStatus[OfficeProtectionStatus.DeliveredAsSpam]) ||
					null,
				ReplacedCount:
					(convertedProtectionStatus &&
						convertedProtectionStatus[OfficeProtectionStatus.Replaced]) ||
					null,
				BlockedCount:
					(convertedProtectionStatus &&
						convertedProtectionStatus[OfficeProtectionStatus.Blocked]) ||
					null,
				MailboxCount: getMailClusterMailboxCount(entityData.CountByDeliveryLocation),
				NotInMailboxCount: getMailClusterNotInMailboxCount(entityData.CountByDeliveryLocation),
				OnPremOrExternalCount: getMailClusterOnPremOrExternalCount(
					entityData.CountByDeliveryLocation
				),
			};
			break;
		case AirsEntityTypeValue.MailMessage:
		case AirsEntityTypeValue.SubmissionMail:
			remediationAction['mailMessage'] = {
				Sender: entityData.Sender,
				P1Sender: entityData.P1Sender,
				Recipient: entityData.Recipient,
				FirstSeen: dateStrToUtc(entityData.FirstSeen),
				MailboxPrimaryAddress: entityData.Recipient,
				Subject: entityData.Subject,
				ThreatsKeys: getThreatsNames(entityData.ThreatAnalysisSummary),
				name: AirsEntityType[convertedEntityTypeCase],
				id: entityData.Urn,
			};
			break;
		case AirsEntityTypeValue.URL:
			remediationAction['address'] = {
				address: entityData.Url,
				MailboxPrimaryAddress: entityData.MailboxPrimaryAddress,
				id: entityData.Urn,
			};
			break;
		case AirsEntityTypeValue.Mailbox:
			const mailboxData = getMailboxEntityCommonData(entityData);

			remediationAction['mailbox'] = {
				MailboxPrimaryAddress: mailboxData.MailboxPrimaryAddress,
				FirstSeen: dateStrToUtc(mailboxData.FirstSeen),
				ForwardingSmtpAddress: mailboxData.ForwardingSmtpAddress,
				CreatedBy: mailboxData.CreatedBy,
				RiskLevel: entityData.RiskLevel,
				ThreatsKeys: getThreatsNames(entityData.ThreatAnalysisSummary),
				id: mailboxData.Urn,
			};
			break;
		case AirsEntityTypeValue.MailboxConfiguration:
			const mailboxConfigData = getMailboxEntityCommonData(entityData);

			const engineReport = getEngineReportData(entityData.ThreatAnalysisSummary);

			const parameters = engineReport && engineReport.Parameters;

			const accessRightsParam =
				parameters &&
				parameters.find(param => param.ParameterName === EngineReportParameters.AccessRights);

			remediationAction['mailboxConfiguration'] = {
				MailboxPrimaryAddress: mailboxConfigData.MailboxPrimaryAddress,
				FirstSeen: dateStrToUtc(mailboxConfigData.FirstSeen),
				Operation: (engineReport && engineReport.Operation) || null,
				AccessRights: (accessRightsParam && accessRightsParam['ParameterValue']) || null,
				CreatedBy: mailboxConfigData.CreatedBy,
				ForwardingSmtpAddress: mailboxConfigData.ForwardingSmtpAddress,
				ThreatsKeys: getThreatsNames(entityData.ThreatAnalysisSummary),
				id: mailboxConfigData.Urn || entityData.ConfigId,
			};
			break;
	}

	return remediationAction;
}
