var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { SeverityType } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { upperFirst } from 'lodash-es';
import { SeverityTypeColorService } from '../../../shared/services/severity-type-color.service';
import { I18nService } from '@wcd/i18n';
var IncidentsEntityPanelComponent = /** @class */ (function (_super) {
    __extends(IncidentsEntityPanelComponent, _super);
    function IncidentsEntityPanelComponent(authService, changeDetectorRef, severityTypeColorService, i18nService) {
        var _this = _super.call(this) || this;
        _this.authService = authService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.severityTypeColorService = severityTypeColorService;
        _this.i18nService = i18nService;
        _this.severityColorMap = {
            type: 'class',
            map: severityTypeColorService.backgroundColorsClassMap,
        };
        return _this;
    }
    IncidentsEntityPanelComponent.prototype.setEntities = function (entities) {
        _super.prototype.setEntities.call(this, entities);
        this.classificationData = this.calcClassification();
        this.statusData = this.calcStatus();
        this.severityData = this.calcSeverity();
        this.changeDetectorRef.markForCheck();
    };
    IncidentsEntityPanelComponent.prototype.calcClassification = function () {
        var _this = this;
        var classificationsIndex = this.entities.reduce(function (classifications, incident) {
            var currClassificationName = _this.i18nService.get(incident.classification ?
                incident.classification.nameI18nKey : 'common_unclassified');
            if (!classifications[currClassificationName]) {
                classifications[currClassificationName] = 0;
            }
            classifications[currClassificationName]++;
            return classifications;
        }, {});
        return Object.entries(classificationsIndex).map(function (_a) {
            var name = _a[0], value = _a[1];
            return ({ name: name, value: value });
        });
    };
    IncidentsEntityPanelComponent.prototype.calcStatus = function () {
        var _this = this;
        var statusIndex = this.entities.reduce(function (status, incident) {
            if (!status[incident.status.nameI18nKey]) {
                status[incident.status.nameI18nKey] = 0;
            }
            status[incident.status.nameI18nKey]++;
            return status;
        }, {});
        return Object.entries(statusIndex).map(function (_a) {
            var name = _a[0], value = _a[1];
            return ({ name: _this.i18nService.get(name), value: value });
        });
    };
    IncidentsEntityPanelComponent.prototype.calcSeverity = function () {
        var severityIndex = this.entities.reduce(function (status, incident) {
            if (!status[incident.severity.type]) {
                status[incident.severity.type] = 0;
            }
            status[incident.severity.type]++;
            return status;
        }, {});
        return [SeverityType.Low, SeverityType.Medium, SeverityType.High, SeverityType.Informational].map(function (severityType) { return ({ kind: severityType, count: severityIndex[severityType] || 0 }); });
    };
    IncidentsEntityPanelComponent.prototype.calcSeverityLegend = function (index) {
        var _this = this;
        return index
            .map(function (severity) { return ({
            name: _this.i18nService.get("incident_severity_values_" + upperFirst(severity.kind)),
            iconClassName: _this.severityTypeColorService.fontColorsClassMap.get(severity.kind),
            value: severity.count,
        }); })
            .filter(function (legendItem) { return legendItem.value; });
    };
    return IncidentsEntityPanelComponent;
}(EntitiesPanelComponentBase));
export { IncidentsEntityPanelComponent };
