import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { ExtendedOutbreaksCalculation } from './extended-outbreaks-calculation.value-object';
import { ExtendedOutbreakDataQuery, WcdPortalParisConfig, ExtendedOutbreakWhereQuery } from '@wcd/domain';

@ApiCall({
	name: 'Get extended outbreaks',
	// Once MDATP portal is deprecated the endpoint is always outbreaksEnrichedDataMtp
	endpoint: (config, query: ExtendedOutbreakDataQuery) =>
	{
		return query && query.where && query.where.isMtp ?
			query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedDataMtp' : 'v2/outbreaksEnrichedDataMtp'
			: query && query.where && query.where.isK8sMigration ? 'outbreaks/outbreaksEnrichedData' : 'outbreaksEnrichedData'
	},
	method: 'GET',
	type: ExtendedOutbreaksCalculation,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatAnalytics,
	cache:{
		time: 1000 * 60
	}
})
export class ExtendedOutbreaksApiCall extends ApiCallModel<ExtendedOutbreaksCalculation, ExtendedOutbreakWhereQuery> {}
