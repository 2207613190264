import { Injectable, ComponentRef } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { TvmReportInaccuracyPanelComponent } from '../components/report-inaccuracy/report-inaccuracy-panel/report-inaccuracy-panel.component';
import { EntityModelBase } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { I18nService } from '@wcd/i18n';
import { InaccuracyContext } from './tvm-texts.service';
import { RecommendationBase, RemediationType, SoftwareInstallation } from '@wcd/domain';

@Injectable()
export class TvmReportInaccuracyService {
	_reportInaccuracyPanel: any;

	private _RECOMMENDATION_STRIPPED_FIELDS = ['description', 'remediationDescription', 'potentialRisk'];
	private _INSTALLATION_STRIPPED_FIELDS = ['assetName'];

	constructor(private dialogsService: DialogsService, private i18nService: I18nService) {}

	public openReportInaccuracyPanel(
		inaccuracyContext: InaccuracyContext,
		contextObject: EntityModelBase
	): Promise<void> {
		return new Promise(resolve =>
			this.dialogsService
				.showPanel(
					TvmReportInaccuracyPanelComponent,
					{
						id: 'report-inaccuracy-panel',
						isModal: true,
						showOverlay: false,
						hasCloseButton: true,
						type: PanelType.large,
						back: { onClick: () => this._reportInaccuracyPanel.instance.closePanel() },
						noBodyPadding: true,
					},
					{
						inaccuracyContext: inaccuracyContext,
						contextObject: contextObject,
					}
				)
				.subscribe((panel: ComponentRef<TvmReportInaccuracyPanelComponent>) => {
					this._reportInaccuracyPanel = panel;

					panel.instance.reportSent.subscribe(() => {
						panel.destroy();

						this.dialogsService.showSuccessSnackbar({
							icon: FabricIconNames.Completed,
							text: this.i18nService.get('tvm.reportInaccuracy.reportSent'),
						});
					});

					panel.onDestroy(() => {
						this._reportInaccuracyPanel = null;
						resolve();
					});
				})
		);
	}

	public evaluateContext(isSingleAsset: boolean, recommendation: RecommendationBase) {
		const isScaRecommendation = recommendation.remediationType === RemediationType.ConfigurationChange;
		if (isSingleAsset) {
			return isScaRecommendation
				? InaccuracyContext.MachineScaRecommendation
				: InaccuracyContext.MachineVaRecommendation;
		} else {
			// Org level
			return isScaRecommendation
				? InaccuracyContext.OrgScaRecommendation
				: InaccuracyContext.OrgVaRecommendation;
		}
	}

	public removePIIandIrrelevantDataFromContextObject(contextObject: EntityModelBase): EntityModelBase {
		let fieldsToStrip = [];

		if (contextObject instanceof RecommendationBase) {
			fieldsToStrip = this._RECOMMENDATION_STRIPPED_FIELDS;
		} else if (contextObject instanceof SoftwareInstallation) {
			fieldsToStrip = this._INSTALLATION_STRIPPED_FIELDS;
		}

		// For vulnerabilities the values of affectedProducts & installedAffectedProducts are used for machine
		// context - but it can be stripped from the org context - leaving for now not stripped

		return fieldsToStrip.length > 0
			? <EntityModelBase>this.copyContextObjectWithFieldsExclusion(fieldsToStrip, contextObject)
			: contextObject;
	}

	private copyContextObjectWithFieldsExclusion(fieldsToStrip: string[], contextObject: EntityModelBase) {
		const clone = {};

		Object.keys(contextObject).forEach(k => {
			if (!fieldsToStrip.includes(k)) {
				clone[k] = contextObject[k];
			}
		});

		this.removeParentFromKey('threatInfo', clone);
		this.removeParentFromKey('assetsStatistics', clone);

		return clone;
	}

	private removeParentFromKey(key: string, obj: any) {
		// The parent may contain PII data and it's not needed since we send the parent as context object
		const objectWithParent = obj[key];
		if (objectWithParent && '$parent' in objectWithParent) {
			delete objectWithParent.$parent;
		}
	}
}
