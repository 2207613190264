var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportWidgetsLayoutModel } from './report-widgets-layout.base';
import { ReportWidgetsLayoutRowModel } from './report-widgets-layout.row.model';
var ReportWidgetsLayoutColumnModel = /** @class */ (function (_super) {
    __extends(ReportWidgetsLayoutColumnModel, _super);
    function ReportWidgetsLayoutColumnModel(config) {
        var _this = _super.call(this, config) || this;
        _this.weight = 1;
        _this.width = config.width;
        _this.weight = config.weight || 1;
        _this.rows = config.rows
            ? config.rows.map(function (rowConfig) { return new ReportWidgetsLayoutRowModel(rowConfig); })
            : null;
        return _this;
    }
    Object.defineProperty(ReportWidgetsLayoutColumnModel.prototype, "type", {
        get: function () {
            return 'column';
        },
        enumerable: true,
        configurable: true
    });
    return ReportWidgetsLayoutColumnModel;
}(ReportWidgetsLayoutModel));
export { ReportWidgetsLayoutColumnModel };
