var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, ModelBase, DataQuerySortDirection } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, ParseDataQuery } from '../analyticsEndPointUtils';
import { VulnerabilityChangeEventType } from './vulnerability-change-event-type.enum';
import { ConfigurationChangeEventType } from './configuration-change-event-type.enum';
import { RecommendationCategory } from '../common/recommendation/recommendation-category.enum';
var ChangeEventSummaryModel = /** @class */ (function () {
    function ChangeEventSummaryModel() {
    }
    return ChangeEventSummaryModel;
}());
export { ChangeEventSummaryModel };
export var ChangeEventCategory;
(function (ChangeEventCategory) {
    ChangeEventCategory["Vulnerability"] = "Vulnerability";
    ChangeEventCategory["Configuration"] = "Configuration";
})(ChangeEventCategory || (ChangeEventCategory = {}));
export var ChangeEventTypesForMdatp = [
    VulnerabilityChangeEventType.NewCve,
    VulnerabilityChangeEventType.CveHasExploitInKit,
    VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit,
    VulnerabilityChangeEventType.CveHasVerifiedExploit,
    ConfigurationChangeEventType.NewScid,
];
var ɵ0 = function (dataQuery) {
    if (dataQuery.sortBy && dataQuery.sortBy.some(function (item) { return item.field === 'score'; })) {
        return ParseDataQuery(dataQuery);
    }
    var newSortBy = (dataQuery.sortBy || []).concat({
        field: 'score',
        direction: DataQuerySortDirection.descending,
    });
    if (dataQuery.where && typeof dataQuery.where === 'object' && dataQuery.where['source'] === 'mdatp') {
        dataQuery.where['eventType'] = ChangeEventTypesForMdatp;
    }
    var dataQueryWithOrderByScore = __assign({}, dataQuery, { sortBy: newSortBy });
    return ParseDataQuery(dataQueryWithOrderByScore);
}, ɵ1 = function (id) {
    return "changeEvents/" + id;
};
var ChangeEvent = /** @class */ (function (_super) {
    __extends(ChangeEvent, _super);
    function ChangeEvent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], ChangeEvent.prototype, "date", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "category", void 0);
    __decorate([
        EntityField({
            data: 'type',
        }),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "eventType", void 0);
    __decorate([
        EntityField({
            data: 'product',
        }),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "productName", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "vendor", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "assetsCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "currentAssetsCount", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], ChangeEvent.prototype, "cvesList", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "score", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "affectedAssetsPercent", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "currentAffectedAssetsPercent", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "scid", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ChangeEvent.prototype, "configurationCategory", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "reductionPoints", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], ChangeEvent.prototype, "achievablePoints", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], ChangeEvent.prototype, "isActiveEvent", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], ChangeEvent.prototype, "relatedEventIds", void 0);
    ChangeEvent = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Event', pluralName: 'Events', endpoint: 'changeEvents', parseDataQuery: ɵ0, parseItemQuery: ɵ1 }))
    ], ChangeEvent);
    return ChangeEvent;
}(ModelBase));
export { ChangeEvent };
export { ɵ0, ɵ1 };
