var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Identifiable } from '../../../data/models/identifiable.model';
export var ADVANCED_SETTINGS_ID = 'advanced';
var AdvancedSettingsModel = /** @class */ (function (_super) {
    __extends(AdvancedSettingsModel, _super);
    function AdvancedSettingsModel(data) {
        return _super.call(this, data) || this;
    }
    Object.defineProperty(AdvancedSettingsModel.prototype, "isPhisingValid", {
        get: function () {
            return true;
            // return !this.phishingSettings.enabled || this.phishingSettings.mailbox;
        },
        enumerable: true,
        configurable: true
    });
    AdvancedSettingsModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
        this.id = ADVANCED_SETTINGS_ID;
        this.uploadToCloudSettings = {
            enabled: data.cloud_upload,
            fileExtensions: data.computer_file_extension,
        };
        //
        // this.localQuarantineSettings = {
        // 	enabled: data.quarantine_on,
        // 	folder: data.quarantine_folder
        // };
        //
        // this.phishingSettings = {
        // 	enabled: data.phishing_enabled,
        // 	mailbox: data.phishing_mailbox
        // };
        //
        this.memoryContentAnalysisSettings = {
            enabled: data.upload_memory_content,
        };
    };
    AdvancedSettingsModel.prototype.getBackendData = function () {
        return {
            id: this.id,
            cloud_upload: this.uploadToCloudSettings.enabled,
            computer_file_extension: this.uploadToCloudSettings.fileExtensions,
            // mail_file_extension: this.uploadToCloudSettings.mailExtensions,
            // quarantine_on: this.localQuarantineSettings.enabled,
            // quarantine_folder: this.localQuarantineSettings.folder,
            // phishing_enabled: this.phishingSettings.enabled,
            // phishing_mailbox: this.phishingSettings.mailbox,
            upload_memory_content: this.memoryContentAnalysisSettings.enabled,
        };
    };
    return AdvancedSettingsModel;
}(Identifiable));
export { AdvancedSettingsModel };
