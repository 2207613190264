var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { MachineRiskScoreType, SeverityType } from '@wcd/domain';
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
import { SeverityTypeColorService } from "../../../shared/services/severity-type-color.service";
var MachineRiskScoreColorService = /** @class */ (function (_super) {
    __extends(MachineRiskScoreColorService, _super);
    function MachineRiskScoreColorService(knownColorsService, severityTypeColorService) {
        var _a;
        var _this = this;
        var riskScoreColorMap = (_a = {},
            _a[MachineRiskScoreType.High] = severityTypeColorService.colorsMap.get(SeverityType.High).name,
            _a[MachineRiskScoreType.Medium] = severityTypeColorService.colorsMap.get(SeverityType.Medium).name,
            _a[MachineRiskScoreType.Low] = severityTypeColorService.colorsMap.get(SeverityType.Low).name,
            _a[MachineRiskScoreType.Informational] = severityTypeColorService.colorsMap.get(SeverityType.Informational).name,
            _a[MachineRiskScoreType.None] = 'neutralPrimary',
            _a);
        _this = _super.call(this, knownColorsService, riskScoreColorMap) || this;
        return _this;
    }
    return MachineRiskScoreColorService;
}(ColorsService));
export { MachineRiskScoreColorService };
