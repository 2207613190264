<div class="wcd-flex indicationIcons">
	<div *ngFor="let indicationIcon of _indicationIcons" class="wcd-margin-small-right">
		<fab-icon
			[wcdTooltipAllowHtmlRendering]="allowTooltipHTMLRender"
			*ngIf="indicationIcon.show"
			iconName="{{indicationIcon.icon?.name}}"
			[contentClass]="indicationIcon.contentClass"
			[wcdTooltip]="indicationIcon.tooltip ? indicationIcon.tooltip : null">
		</fab-icon>
	</div>
</div>
