import { NgModule } from '@angular/core';
import { FabSpinnerModule } from '@angular-react/fabric';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { RemediationModule } from '../remediation/remediation.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { PendingActionHistoryEntityTypeService } from './services/pending-actions-history.entity-type.service';
import { FabMessageBarModule } from '@angular-react/fabric';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		RemediationModule,
		FabSpinnerModule,
		FabMessageBarModule,
	],
	providers: [PendingActionHistoryEntityTypeService],
})
export class PendingActionsHistoryEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(PendingActionHistoryEntityTypeService);
	}
}
