import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleScopeType } from './suppression-rule-scope-type.enum';

@Entity({
	singularName: 'Suppression Rule Scope',
	pluralName: 'Suppression Rule Scopes',
	values: [
		{
			id: 1,
			name: 'Device',
			nameI18nKey: 'SuppressionRuleScope_Device',
			type: SuppressionRuleScopeType.Machine,
		},
		{
			id: 2,
			name: 'Organization',
			nameI18nKey: 'SuppressionRuleScope_Organization',
			type: SuppressionRuleScopeType.AllOrganization,
		},
		{
			id: 3,
			name: 'MachineGroups',
			nameI18nKey: 'SuppressionRuleScope_MachineGroups',
			type: SuppressionRuleScopeType.MachineGroups,
		},
		{
			id: 4,
			name: 'Conditional',
			type: SuppressionRuleScopeType.Conditional,
		}
	],
})
export class SuppressionRuleScope extends EntityModelBase<number> {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;
	@EntityField() type: SuppressionRuleScopeType;
}
