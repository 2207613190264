import { IMachineExposureScore, MachineExposureScoreType } from './machine-exposure-score.models';

export const machineExposureScoreValues: Array<IMachineExposureScore> = [
	{
		id: MachineExposureScoreType.None,
		name: 'No data available',
		className: 'neutral',
		nameI18nKey: 'machineExposureScoreType_None',
		priority: 4,
	},
	{
		id: MachineExposureScoreType.Low,
		name: 'Low',
		icon: 'warning',
		className: 'lowSeverity',
		nameI18nKey: 'machineExposureScoreType_Low',
		priority: 3,
	},
	{
		id: MachineExposureScoreType.Medium,
		name: 'Medium',
		icon: 'warning',
		className: 'mediumSeverity',
		nameI18nKey: 'machineExposureScoreType_Medium',
		priority: 2,
	},
	{
		id: MachineExposureScoreType.High,
		name: 'High',
		icon: 'warning',
		className: 'highSeverity',
		nameI18nKey: 'machineExposureScoreType_High',
		priority: 1,
	},
];

export const exposureScoreNumberToEntity = {
	[0]: machineExposureScoreValues[0],
	[10]: machineExposureScoreValues[1],
	[20]: machineExposureScoreValues[2],
	[30]: machineExposureScoreValues[3],
};
