var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { ServiceSourceStringType, ServiceSourceType } from './service-source.enum';
import { detectionSourceValues } from './detection-source.entity.values';
var AZURE_ATP_NAME = 'MDI';
var MCAS_NAME = 'Microsoft Cloud App Security';
var OATP_NAME = 'MDO';
var MTP_NAME = 'Microsoft 365 Defender';
var CUSTOM_DETECTION_DETECTION_SOURCE = 'Custom detection';
var WDATP_NAME = 'Microsoft Defender for Endpoint';
var MAPG_NAME = 'Microsoft Application Protection and Governance';
var THREAT_EXPERTS = 'Microsoft Threat Experts';
var MANUAL = 'Manual';
var AAD_NAME = 'AAD Identity Protection';
var DLP_NAME = 'Data Loss Prevention'; //TODO: Verify name with Wissan
var APPG_POLICY_NAME = 'App governance Policy';
var APPG_DETECTION_NAME = 'App governance Detection';
var ɵ0 = ServiceSourceType.Aatp, ɵ1 = function (detectionSource) { return detectionSource.name === AZURE_ATP_NAME; }, ɵ2 = ServiceSourceType.Mcas, ɵ3 = function (detectionSource) { return detectionSource.name === MCAS_NAME; }, ɵ4 = ServiceSourceType.Wdatp, ɵ5 = ServiceSourceType.Mtp, ɵ6 = function (detectionSource) {
    return detectionSource.isSelectable &&
        [CUSTOM_DETECTION_DETECTION_SOURCE, MTP_NAME, THREAT_EXPERTS, MANUAL].includes(detectionSource.name);
}, ɵ7 = ServiceSourceType.Oatp, ɵ8 = function (detectionSource) { return detectionSource.name === OATP_NAME; }, ɵ9 = ServiceSourceType.Mapg, ɵ10 = function (detectionSource) {
    return detectionSource.isSelectable &&
        [APPG_POLICY_NAME, APPG_DETECTION_NAME].includes(detectionSource.name);
}, ɵ11 = ServiceSourceType.Aad, ɵ12 = function (detectionSource) { return detectionSource.name === AAD_NAME; }, ɵ13 = ServiceSourceType.Dlp, ɵ14 = function (detectionSource) { return detectionSource.name === DLP_NAME; };
export var serviceSourceValues = [
    {
        id: ɵ0,
        name: AZURE_ATP_NAME,
        nameI18nKey: 'serviceSourceType_Aatp',
        isSelectable: true,
        children: [detectionSourceValues.find(ɵ1)],
        type: ServiceSourceStringType.Aatp
    },
    {
        id: ɵ2,
        name: MCAS_NAME,
        nameI18nKey: 'serviceSourceType_Mcas',
        isSelectable: true,
        children: [detectionSourceValues.find(ɵ3)],
        type: ServiceSourceStringType.Mcas
    },
    {
        id: ɵ4,
        name: WDATP_NAME,
        nameI18nKey: 'serviceSourceType_Wdatp',
        isSelectable: true,
        type: ServiceSourceStringType.Wdatp
        // MDATP children are selected with some logic that involves appConfigService and featuresService
        // hence, actually children assignment is done in source-filter.service
    },
    {
        id: ɵ5,
        name: MTP_NAME,
        nameI18nKey: 'serviceSourceType_Mtp',
        isSelectable: true,
        children: detectionSourceValues.filter(ɵ6),
        type: ServiceSourceStringType.Mtp
    },
    {
        id: ɵ7,
        name: OATP_NAME,
        nameI18nKey: 'serviceSourceType_Oatp',
        isSelectable: true,
        children: [detectionSourceValues.find(ɵ8)],
        type: ServiceSourceStringType.Oatp
    },
    {
        id: ɵ9,
        name: MAPG_NAME,
        nameI18nKey: 'serviceSourceType_Mapg',
        isSelectable: true,
        children: detectionSourceValues.filter(ɵ10),
        type: ServiceSourceStringType.Mapg
    },
    {
        id: ɵ11,
        name: AAD_NAME,
        nameI18nKey: 'serviceSourceType_Aad',
        isSelectable: true,
        children: [detectionSourceValues.find(ɵ12)],
        type: ServiceSourceStringType.Aad
    },
    {
        id: ɵ13,
        name: DLP_NAME,
        nameI18nKey: 'serviceSourceType_Dlp',
        isSelectable: true,
        children: [detectionSourceValues.find(ɵ14)],
        type: ServiceSourceStringType.Dlp
    },
];
var ServiceSource = /** @class */ (function (_super) {
    __extends(ServiceSource, _super);
    function ServiceSource() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ServiceSource.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], ServiceSource.prototype, "nameI18nKey", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], ServiceSource.prototype, "isSelectable", void 0);
    ServiceSource = __decorate([
        Entity({
            singularName: 'Service Source',
            pluralName: 'Service Sources',
            values: serviceSourceValues,
        })
    ], ServiceSource);
    return ServiceSource;
}(EntityModelBase));
export { ServiceSource };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14 };
