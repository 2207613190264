import { Application, ApplicationType } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { FabricIconNames } from '@wcd/scc-common';
import { baseUrlsSettings, MCAS_HOST } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
var buildAppLink = function (app) {
    var mcasBase = sccHostService.isSCC ? baseUrlsSettings.MCASPortalUrls : MCAS_HOST;
    return mcasBase + "/" + app.linkToAppPage;
};
var ɵ0 = buildAppLink;
var ApplicationEntityTypeService = /** @class */ (function () {
    function ApplicationEntityTypeService() {
        var _this = this;
        this._entityType = new Lazy(function () { return ({
            entity: Application,
            id: 'app',
            getEntityName: function (app) { return app.name; },
            getIcon: function (apps) { return _this.getIcon(apps); },
            entityPluralNameKey: 'application_entityType_pluralName',
            entitySingularNameKey: 'application_entityType_singularName',
            getEntitiesLink: function (apps) {
                if (!apps || apps.length !== 1) {
                    return null;
                }
                var app = apps[0];
                return buildAppLink(app);
            },
            getNavigationModel: function (app) { return ({
                externalLink: buildAppLink(app),
            }); },
            getUseExternalRouting: function (apps) { return true; },
        }); });
    }
    ApplicationEntityTypeService.prototype.getIcon = function (apps) {
        return apps && apps.length > 0 && apps[0].type === ApplicationType.OAuthApplication
            ? FabricIconNames.AppIconDefault
            : FabricIconNames.Cloud;
    };
    Object.defineProperty(ApplicationEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return ApplicationEntityTypeService;
}());
export { ApplicationEntityTypeService };
export { ɵ0 };
