/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../global_entities/components/entity-details/ip.entity-details.component.ngfactory";
import * as i2 from "../../../global_entities/components/entity-details/ip.entity-details.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../shared/services/time-ranges.service";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../global_entities/components/entity-details/url.entity-details.component.ngfactory";
import * as i7 from "../../../global_entities/components/entity-details/url.entity-details.component";
import * as i8 from "../../@tvm/common/side-panel.service";
import * as i9 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i10 from "../../../global_entities/components/entity-details/network-endpoint.entity-details.component.ngfactory";
import * as i11 from "../../../global_entities/components/entity-details/network-endpoint.entity-details.component";
import * as i12 from "@angular/common";
import * as i13 from "./network-endpoint.entity-panel.component";
var styles_NetworkEndpointEntityPanelComponent = [];
var RenderType_NetworkEndpointEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NetworkEndpointEntityPanelComponent, data: {} });
export { RenderType_NetworkEndpointEntityPanelComponent as RenderType_NetworkEndpointEntityPanelComponent };
function View_NetworkEndpointEntityPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "section", [["class", "wcd-flex-none wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "ip-entity-details", [], null, null, null, i1.View_IpEntityDetailsComponent_0, i1.RenderType_IpEntityDetailsComponent)), i0.ɵdid(5, 114688, null, 0, i2.IpEntityDetailsComponent, [i0.Injector, i0.ChangeDetectorRef, i3.Paris, i4.TimeRangesService, i5.I18nService], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkEndpoint.ip; _ck(_v, 5, 0, currVal_0); }, null); }
function View_NetworkEndpointEntityPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "section", [["class", "wcd-flex-none wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "url-entity-details", [], null, null, null, i6.View_UrlEntityDetailsComponent_0, i6.RenderType_UrlEntityDetailsComponent)), i0.ɵdid(5, 114688, null, 0, i7.UrlEntityDetailsComponent, [i0.Injector, i0.ChangeDetectorRef, i3.Paris, i4.TimeRangesService, i5.I18nService, i8.SidePanelService, i9.FeaturesService], { entity: [0, "entity"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkEndpoint.url; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_NetworkEndpointEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "section", [["class", "wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "network-endpoint-entity-details", [], null, null, null, i10.View_NetworkEndpointEntityDetailsComponent_0, i10.RenderType_NetworkEndpointEntityDetailsComponent)), i0.ɵdid(5, 114688, null, 0, i11.NetworkEndpointEntityDetailsComponent, [i0.Injector], { entity: [0, "entity"], showEntityPanelLink: [1, "showEntityPanelLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityPanelComponent_1)), i0.ɵdid(9, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NetworkEndpointEntityPanelComponent_2)), i0.ɵdid(12, 16384, null, 0, i12.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.networkEndpoint; var currVal_1 = true; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.networkEndpoint.ip; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.networkEndpoint.url; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_NetworkEndpointEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "network-endpoint-entity-panel", [], null, null, null, View_NetworkEndpointEntityPanelComponent_0, RenderType_NetworkEndpointEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i13.NetworkEndpointEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NetworkEndpointEntityPanelComponentNgFactory = i0.ɵccf("network-endpoint-entity-panel", i13.NetworkEndpointEntityPanelComponent, View_NetworkEndpointEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { NetworkEndpointEntityPanelComponentNgFactory as NetworkEndpointEntityPanelComponentNgFactory };
