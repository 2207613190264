import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class ThreatInfoService {
	private isNewExploitExperienceEnabled: boolean;

	constructor(private i18nService: I18nService, featuresService: FeaturesService) {
		this.isNewExploitExperienceEnabled = featuresService.isEnabled(Feature.TvmExploits);
	}

	getFiltersData(withBreachFilter: boolean = false): { value: any; name: string; priority: number }[] {
		const filtersArray: { value: any; name: string; priority: number }[] = [];
		if (withBreachFilter) {
			filtersArray.push({
				value: 0,
				name: this.i18nService.get('tvm.softwarePage.filters.threats.hasBreach'),
				priority: 1,
			});
		}

		if (this.isNewExploitExperienceEnabled) {
			filtersArray.push(
				...[
					{
						value: 1,
						name: this.i18nService.get('tvm.softwarePage.filters.threats.exploits.available'),
						priority: 2,
					},
					{
						value: 2,
						name: this.i18nService.get('tvm.softwarePage.filters.threats.exploits.verified'),
						priority: 3,
					},
					{
						value: 3,
						name: this.i18nService.get('tvm.softwarePage.filters.threats.exploits.inKit'),
						priority: 4,
					},
				]
			);
		} else {
			filtersArray.push(
				...[
					{
						value: true,
						name: this.i18nService.get('tvm.softwarePage.filters.threats.hasExploit'),
						priority: 1,
					},
					{
						value: false,
						name: this.i18nService.get('tvm.softwarePage.filters.threats.noExploit'),
						priority: 2,
					},
				]
			);
		}

		return filtersArray;
	}
}
