<section class="wcd-flex-none wcd-padding-vertical"
		 *ngLet="ipDetails$ | async as ipDetails"
>
	<wcd-contents-state [contentState]="ipDetailsContentState">
		<ng-container loading *ngTemplateOutlet="loading"></ng-container>
		<ng-container error *ngTemplateOutlet="error"></ng-container>
		<ng-container complete *ngTemplateOutlet="ipDetailsShown"></ng-container>
		<ng-container empty *ngTemplateOutlet="ipDetailsShown"></ng-container>

		<ng-template #ipDetailsShown>
			<dl [class.key-values]="asKeyValueList && !showIpStats" role="none">
				<wcd-collapsible-section
					[label]="'ips.details.securityInfo' | i18n"
					[noSideMargins]="true"
					*ngIf="ipDetails?.incidentsCount !== null || ipDetails?.incidentsCount !== null"
				>
					<div class="two-key-values-columns">
						<div *ngIf="ipDetails?.incidentsCount != null">
							<dl>
								<dt>{{ 'ips.fields.details.incidentsCount.title' | i18n }}</dt>
								<dd>{{ ipDetails.incidentsCount | prettyNumber }}</dd>
							</dl>
						</div>
						<div *ngIf="ipDetails?.alertsCount != null">
							<dl>
								<dt>{{ 'ips.fields.details.alertsCount.title' | i18n }}</dt>
								<dd>{{ ipDetails.alertsCount | prettyNumber }}</dd>
							</dl>
						</div>
					</div>
				</wcd-collapsible-section>
				<wcd-collapsible-section
					[label]="'ips.details.ip' | i18n"
					[noSideMargins]="true"
				>
					<dl>
						<ng-container *ngIf="ipDetails?.organization">
							<dt>{{ 'ips.fields.details.organization.title' | i18n }}</dt>
							<dd>{{ ipDetails.organization | capitalizeString}}</dd>
						</ng-container>
						<ng-container *ngIf="ipDetails?.asn > 0">
							<dt>{{ 'ips.fields.details.asn.title' | i18n }}</dt>
							<dd>{{ ipDetails.asn }}</dd>
						</ng-container>
						<ng-container *ngIf="ipDetails?.country">
							<dt>{{ 'ips.fields.details.country.title' | i18n }}</dt>
							<dd>{{ ipDetails.country | capitalizeString}}</dd>
						</ng-container>
						<ng-container *ngIf="ipDetails?.state">
							<dt>{{ 'ips.fields.details.state.title' | i18n }}</dt>
							<dd>{{ ipDetails.state | capitalizeString}}</dd>
						</ng-container>
						<ng-container *ngIf="ipDetails?.city">
							<dt>{{ 'ips.fields.details.city.title' | i18n }}</dt>
							<dd>{{ ipDetails.city | capitalizeString }}</dd>
						</ng-container>
					</dl>
				</wcd-collapsible-section>
			</dl>
		</ng-template>
	</wcd-contents-state>
</section>
<section *ngIf="showIpStats" class="wcd-flex-none wcd-padding-vertical small-form dialog-controls">
	<h4 class="definition-list-title">
		{{ 'ips.details.stats.title' | i18n }}
	</h4>
	<div class="form-group wcd-flex-horizontal ng-star-inserted">
		<label for="rangeSelect">{{ 'ips.details.stats.filterTitle' | i18n }}:</label>
		<div class="form-control-wrapper">
				<fancy-select id="rangeSelect"
				[(ngModel)]="currentRange"
				(ngModelChange)="onStatRangeChange()"
				[isBordered]="true"
				labelClass="dropdown-width-small-medium"
				label='name'
				[values]="timeRanges"></fancy-select>
		</div>
	</div>

	<wcd-contents-state [contentState]="ipStatsContentState" *ngLet="ipStats$ | async as ipStats">
		<ng-container loading *ngTemplateOutlet="loading"></ng-container>
		<ng-container error *ngTemplateOutlet="error"></ng-container>
		<ng-container complete *ngTemplateOutlet="ipStatsShown"></ng-container>
		<ng-container empty *ngTemplateOutlet="ipStatsShown"></ng-container>

		<ng-template #ipStatsShown>
			<dl [class.key-values]="asKeyValueList" role="none">
				<ng-container *ngIf="ipStats?.organizationPrevalence">
					<dt>{{ 'networkEndpoints.details.stats.orgPrevalence.title' | i18n }}</dt>
					<dd>{{ ipStats.organizationPrevalence | prettyNumber }}</dd>
				</ng-container>
				<ng-container *ngIf="ipStats?.organizationFirstSeen">
					<dt>{{ 'networkEndpoints.details.stats.orgFirstSeen.title' | i18n }}</dt>
					<dd>{{ ipStats.organizationFirstSeen | date:'medium' }}</dd>
				</ng-container>
				<ng-container *ngIf="ipStats?.organizationLastSeen">
					<dt>{{ 'networkEndpoints.details.stats.orgLastSeen.title' | i18n }}</dt>
					<dd>{{ ipStats.organizationLastSeen | date:'medium' }}</dd>
				</ng-container>
			</dl>
		</ng-template>
	</wcd-contents-state>
</section>

<ng-template #error>
	<span>{{ 'help.externalLoadError' | i18n:{ asset: dataAsset } }}</span>
</ng-template>

<ng-template #loading>
	<span><fab-spinner [label]="'loading' | i18n"></fab-spinner></span>
</ng-template>
