import { ApiCall, ApiCallModel, HttpOptions, DataQuery } from '@microsoft/paris';
import { CyberEvent } from './cyber-event.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

export interface CyberEventMarkRequest {
	cyberEvent: CyberEvent;
	isMarked: boolean;
}

export interface CyberEventMarkBodyParams {
	isMarked: boolean;
	actionType: string;
	actionTimeIsoString: string;
	machineId: string;
	reportId?: number;
	alertId?: string;
}

/**
 * Mark cyber event
 */
@ApiCall({
	name: 'Cyber event mark',
	endpoint: (config, query) => {
		return `machines/${query.where['machineId']}/eventMark`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.markMachineTimelineEvent,
	method: 'POST',
	cache: false,
	parseQuery: (query: CyberEventMarkRequest): HttpOptions<CyberEventMarkBodyParams> => ({
		data: {
			isMarked: query.isMarked,
			actionTimeIsoString: query.cyberEvent.actionTimeIsoString,
			reportId: query.cyberEvent.reportId,
			actionType: query.cyberEvent.rawActionType,
			machineId: query.cyberEvent.machine.id,
			alertId: query.cyberEvent.relatedAlert && query.cyberEvent.relatedAlert.id,
		},
		params: {
			machineId: query.cyberEvent.machine.id,
		},
	}),
})
export class CyberEventMarkApiCall extends ApiCallModel<any, CyberEventMarkRequest> {}
