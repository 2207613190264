import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { ActionabilityType } from '@wcd/domain';

const ActionabilityTypeDisplayKeysMap: Record<ActionabilityType, string> = {
	[ActionabilityType.Actionable]: 'reporting.recommendationsByActionability.actionable',
	[ActionabilityType.NonActionable]: 'reporting.recommendationsByActionability.nonActionable',
};

@Pipe({
	name: 'exposureLevelDisplayText',
})
export class ExposureLevelDisplayTextPipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {
		this.transform = memoize(this.transform);
	}

	transform(value: ActionabilityType): string | null {
		if (!(value in ActionabilityTypeDisplayKeysMap)) {
			return null;
		}

		return this.i18nService.get(ActionabilityTypeDisplayKeysMap[value]);
	}
}
