import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef } from '@angular/core';
import {
	RecommendationExceptionAggregated,
	RecommendationException,
	RecommendationExceptionAggregatedRelationship,
} from '@wcd/domain';
import { RelationshipRepository, Paris } from '@microsoft/paris';
import { DataviewField } from '@wcd/dataview';
import { RecommendationExceptionsFieldsService } from './../../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exceptions.fields.service';
import { RecommendationExceptionService } from './../../../../@entities/@tvm/remediation/recommendation-exceptions/services/recommendation-exception.service';
import { SidePanelService } from './../../../../@entities/@tvm/common/side-panel.service';

@Component({
	selector: 'related-exceptions-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './related-exceptions.details.component.html',
})
export class RelatedExceptionsDetailsComponent implements OnInit {
	repository: RelationshipRepository<RecommendationExceptionAggregated, RecommendationException>;
	fields: Array<DataviewField<RecommendationException>>;

	@Input() sourceItem: RecommendationExceptionAggregated;

	constructor(
		paris: Paris,
		recommendationExceptionsFieldsService: RecommendationExceptionsFieldsService,
		changeDetectorRef: ChangeDetectorRef,
		private recommendationExceptionService: RecommendationExceptionService,
		private sidePanelService: SidePanelService
	) {
		this.repository = paris.getRelationshipRepository(RecommendationExceptionAggregatedRelationship);
		recommendationExceptionsFieldsService.relatedExceptionsFields.subscribe(fields => {
			this.fields = fields;
			changeDetectorRef.markForCheck();
		});
	}

	ngOnInit() {
		this.repository.sourceItem = this.sourceItem;
	}

	openRelatedExceptionPanel(recommendationException: RecommendationException) {
		this.recommendationExceptionService.openRelatedExceptionsPanel(recommendationException);
	}

	openAllRelatedExceptions() {
		this.sidePanelService.showAllRelatedExceptionsPanel(this.sourceItem);
	}
}
