/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "../../../../../../../../../projects/wizard/src/lib/components/wizard.component.ngfactory";
import * as i5 from "../../../../../../../../../projects/wizard/src/lib/components/wizard.component";
import * as i6 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../insights/services/app-insights.service";
import * as i8 from "./profile-wizard.component";
import * as i9 from "@angular/router";
var styles_ProfileWizardComponent = [];
var RenderType_ProfileWizardComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileWizardComponent, data: {} });
export { RenderType_ProfileWizardComponent as RenderType_ProfileWizardComponent };
export function View_ProfileWizardComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { panel: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onClosePanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(3, 114688, [[1, 4], ["panel", 4]], 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 2, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, 1, 1, "wcd-wizard", [["wizardTitlePrefix", "baseline_profile_wizard_breadcrumbs_prefix"], ["wizardTrackingName", "ProfileCreationWizard"]], null, [[null, "onClose"], [null, "onDone"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.onClosePanel() !== false);
        ad = (pd_0 && ad);
    } if (("onDone" === en)) {
        var pd_1 = (_co.onRuleCreated() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_WcdWizardComponent_0, i4.RenderType_WcdWizardComponent)), i0.ɵdid(7, 245760, null, 0, i5.WcdWizardComponent, [i6.I18nService, i0.ChangeDetectorRef, i0.ComponentFactoryResolver, i7.AppInsightsService], { steps: [0, "steps"], data: [1, "data"], wizardTitlePrefix: [2, "wizardTitlePrefix"], wizardTitle: [3, "wizardTitle"], wizardTrackingName: [4, "wizardTrackingName"] }, { onDone: "onDone", onClose: "onClose" }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.steps; var currVal_2 = _co.formData; var currVal_3 = "baseline_profile_wizard_breadcrumbs_prefix"; var currVal_4 = _co.title; var currVal_5 = "ProfileCreationWizard"; _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }, null); }
export function View_ProfileWizardComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "profile-wizard", [], null, null, null, View_ProfileWizardComponent_0, RenderType_ProfileWizardComponent)), i0.ɵdid(1, 245760, null, 0, i8.ProfileWizardComponent, [i9.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileWizardComponentNgFactory = i0.ɵccf("profile-wizard", i8.ProfileWizardComponent, View_ProfileWizardComponent_Host_0, { settings: "settings", onDone: "onDone", onClose: "onClose", data: "data", isUpdateFlow: "isUpdateFlow" }, {}, []);
export { ProfileWizardComponentNgFactory as ProfileWizardComponentNgFactory };
