import { Component, Input } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { ServiceUrlsService } from '@wcd/app-config';

@Component({
	selector: 'external-source-action-results-modal',
	template: `
		<modal (close)="destroy()" [settings]="settings" data-track-component="AirsExternalSourceResults">
			<div class="wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex">
				<pre class="code block">{{ result }}</pre>
				<a
					*ngIf="isPartial"
					data-track-id="ViewFullResults"
					data-track-type="Navigation"
					[attr.href]="ApiBaseUrl + '/services/result?result_id=' + resultId | urlWithToken"
					target="_blank"
				>
					<wcd-shared-icon [iconName]="'popOut'" class="small-icon"> </wcd-shared-icon>
					View Full Results
				</a>
			</div>
		</modal>
	`,
})
export class ExternalSourceActionResultsModalComponent extends ModalContainer {
	@Input() result: string;
	@Input() isPartial: boolean;
	@Input() resultId: number;

	get ApiBaseUrl(): string {
		return this.serviceUrlsService.automatedIr;
	}

	constructor(protected router: Router, private serviceUrlsService: ServiceUrlsService) {
		super(router);
	}
}
