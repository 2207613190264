export function userAssistanceParseQuery(data: UserAssistanceData) {
	return {
		Email: data.email || null,
		Description: data.description,
		Cause: data.cause,
		CurrentUrl: window.location.href,
		Screenshot: data.screenshot || null,
		AppCodeName: navigator.appCodeName,
		AppName: navigator.appName,
		AppVersion: navigator.appVersion,
		CookieEnabled: navigator.cookieEnabled,
		Online: navigator.onLine,
		Platform: navigator.platform,
		UserAgent: navigator.userAgent,
		Plugins: 'None',
	};
}

export interface UserAssistanceData {
	email?: string;
	description: string;
	screenshot?: string;
	cause: UserAssistanceCause;
}

export type UserAssistanceCause = string;
