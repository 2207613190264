export const enum UrlEntityAlertV3Fields {
	Url = 'Url',
}

export function mapUrlEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case UrlEntityAlertV3Fields.Url:
					acc['address'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
