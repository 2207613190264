var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ValueObject } from '@microsoft/paris';
import { EntityTags } from '../tag/entity-tags.value-object';
var ɵ0 = ["IncidentUserTags", "incidentUserTags"];
var IncidentTags = /** @class */ (function (_super) {
    __extends(IncidentTags, _super);
    function IncidentTags() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", Array)
    ], IncidentTags.prototype, "incidentUserTags", void 0);
    IncidentTags = __decorate([
        ValueObject({
            singularName: 'Incident tags',
            pluralName: '',
            readonly: true,
        })
    ], IncidentTags);
    return IncidentTags;
}(EntityTags));
export { IncidentTags };
export { ɵ0 };
