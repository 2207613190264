import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';

export enum EvaluationSimulatorStatusType {
	'Failed' = 'Failed',
	'PendingStart' = 'PendingStart',
	'Running' = 'Running',
	'Completed' = 'Completed',
}

export const evaluationSimulatorStatusValues: EvaluationSimulatorStatus[] = [
	{
		id: 'Failed',
		name: 'failed',
		className: 'ms-color-red',
		type: EvaluationSimulatorStatusType.Failed,
		priority: 0,
	},
	{
		id: 'PendingStart',
		name: 'pendingStart',
		className: 'ms-color-themeSecondary',
		type: EvaluationSimulatorStatusType.PendingStart,
		priority: 1,
	},
	{
		id: 'Running',
		name: 'running',
		className: 'ms-color-themeSecondary',
		type: EvaluationSimulatorStatusType.Running,
		priority: 2,
	},
	{
		id: 'Completed',
		name: 'completed',
		className: 'ms-color-greenLight',
		type: EvaluationSimulatorStatusType.Completed,
		priority: 3,
	},
];

@Entity({
	singularName: 'Simulator status',
	pluralName: 'Simulator status',
	values: evaluationSimulatorStatusValues,
})
export class EvaluationSimulatorStatus extends StatusModel<string> {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() type: EvaluationSimulatorStatusType;
}
