import { throwError } from 'rxjs';
import { tap } from 'rxjs/operators';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { SecurityAnalyticsSettings } from '@wcd/domain';
var SecurityAnalyticsService = /** @class */ (function () {
    function SecurityAnalyticsService(featuresService, i18nService, paris) {
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.paris = paris;
        this._isFresh = false;
    }
    SecurityAnalyticsService.prototype.getSecurityControlsSettings = function () {
        var _this = this;
        return this.paris
            .getRepository(SecurityAnalyticsSettings)
            .getItemById(1)
            .pipe(tap(function (controlSettings) {
            _this.SecurityAnalyticsControlSettings.map(function (control) { return (control.exclude = controlSettings.excludedControls.includes(control.name)); });
            _this._isFresh = true;
        }));
    };
    SecurityAnalyticsService.prototype.saveSecurityControlsSettings = function () {
        if (!this._isFresh) {
            return throwError('Cannot save Security Analytics settings without getting them first');
        }
        return this.paris.getRepository(SecurityAnalyticsSettings).save(new SecurityAnalyticsSettings({
            id: 1,
            excludedControls: this.SecurityAnalyticsControlSettings.filter(function (c) { return c.exclude; }).map(function (c) { return c.name; }),
        }));
    };
    Object.defineProperty(SecurityAnalyticsService.prototype, "SecurityAnalyticsControlSettings", {
        get: function () {
            var _this = this;
            if (!this._securityAnalyticsControlSettings) {
                this._securityAnalyticsControlSettings = [
                    {
                        name: 'AntiVirus',
                        exclude: false,
                    },
                    {
                        name: 'ExploitGuard',
                        exclude: false,
                    },
                    {
                        name: 'AppGuard',
                        exclude: false,
                    },
                    {
                        name: 'SmartScreen',
                        exclude: false,
                    },
                    {
                        name: 'Firewall',
                        exclude: false,
                        feature: Feature.SecurityAnalyticsFirewallControl,
                    },
                    {
                        name: 'CredentialGuard',
                        exclude: false,
                        feature: Feature.SecurityAnalyticsCredentialGuardControl,
                    },
                    {
                        name: 'WindowsHello',
                        exclude: false,
                        feature: Feature.SecurityAnalyticsWinHelloControl,
                    },
                    {
                        name: 'BitLocker',
                        exclude: false,
                        feature: Feature.SecurityAnalyticsBitLockerControl,
                    },
                    {
                        name: 'UpgradeableWindowsVersion',
                        exclude: false,
                        feature: Feature.SecurityAnalyticsOsVersionUpgradeControl,
                    },
                ].filter(function (control) {
                    return !control.feature || _this.featuresService.isEnabled(control.feature);
                });
            }
            return this._securityAnalyticsControlSettings;
        },
        enumerable: true,
        configurable: true
    });
    return SecurityAnalyticsService;
}());
export { SecurityAnalyticsService };
