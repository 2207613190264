<ng-container *ngIf="field; else noField">
	<ng-container *ngIf="getUseCustomComponent(value) ; else fieldValueContents">
		<wcd-datatable-field-value-custom-component
			[value]="value"
			[field]="field"></wcd-datatable-field-value-custom-component>
	</ng-container>
	<ng-template #fieldValueContents>
		<span [wcdTooltip]="tooltip"
			  [wcdTooltipAllowHtmlRendering]="field.valueTooltipAllowHtmlRendering"
			  [ngClass]="field.className">
			<ng-container *ngIf="field.listValues; else noList">
				<ul *ngIf="listItems"
					class="field-value-list"
					[class.text-list]="field.listValues.isArrowSeparated || field.listValues.isCommaSeparated || field.listValues.isInlineList"
					[class.comma-separated]="field.listValues.isCommaSeparated"
					[class.arrow-separated]="field.listValues.isArrowSeparated"
					[ngClass]="{ 'inline-list with-bullets': field.listValues.withBullets, unstyled: !field.listValues.withBullets || listItems && listItems.length === 1 }">
					<li *ngFor="let listItem of listItems">
						<wcd-datatable-field-value [field]="field.listValues.field" [value]="listItem"></wcd-datatable-field-value>
					</li>
				</ul>
			</ng-container>
			<ng-template #noList>
				<ng-container *ngIf="link">
					<route-link [routeLink]="{ routerLink: link, queryParams: linkQueryParams }" linkTabIndex="-1">
						<ng-container *ngTemplateOutlet="innerContent"></ng-container>
					</route-link>
					<ng-template #innerContent><wcd-datatable-field-value [field]="field" [value]="value" [allowLink]="false"></wcd-datatable-field-value></ng-template>
				</ng-container>
				<button *ngIf="!link && field.onClick && allowLink !== false" class="field-value-button" type="button" [disabled]="field.disabledInnerContent && field.disabledInnerContent(value)"
						(click)="field.onClick(value)" tabindex="-1" >
					<wcd-datatable-field-value [field]="field" [value]="value" [allowLink]="false"></wcd-datatable-field-value>
				</button>
				<span *ngIf="!link && !field.onClick || allowLink === false"
						[ngClass]="field.getCssClass && field.getCssClass(value)">
					<wcd-icon *ngIf="wcdIconName"
								[iconName]="wcdIconName"
								[className]="iconCssClass"></wcd-icon>
					<fab-icon *ngIf="fabricIconName"
								[contentClass]="iconCssClass"
								[iconName]="fabricIconName"></fab-icon>
					<img *ngIf="imageSrc" [attr.src]="imageSrc | basePath" alt="image" class="field-value-icon-base"/>
					<span [class.error-message]="isDisplayError">{{isDisplayError ? '(Error)' : display}}</span>
				</span>
			</ng-template>
			<wcd-help *ngIf="helpText" [text]="helpText"></wcd-help>
		</span>
	</ng-template>
</ng-container>
<ng-template #noField>
	{{value}}
</ng-template>
