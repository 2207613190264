import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { FieldsService } from '../../../global_entities/models/entity-type.interface';
import {HuntingRule, MachineGroup} from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { Lazy } from '@wcd/utils';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { sccHostService } from '@wcd/scc-interface';
import { ScheduledHuntingEntityTypeService } from './scheduled-hunting.entity-type.service';
import { ScheduledHuntingLastRunStatusComponent } from '../components/scheduled-hunting-last-run-status.component';
import { ScheduledHuntingRunTimeComponent } from '../components/scheduled-hunting-run-time.component';

@Injectable()
export class ScheduledHuntingFieldsService implements FieldsService<HuntingRule> {
	private readonly machineGroupsFieldId = 'MachineGroups';

	private readonly _allFields = new Lazy<Array<DataviewField>>(() => {
		const scheduledHuntingFields: Array<DataviewFieldConfig<HuntingRule>> = [
			{
				id: 'Name',
				name: this.i18nService.get('scheduledHunting.fields.name.title'),
				component: {
					type: EntityNameComponent,
					getProps: (huntingRule: HuntingRule) => {
						return {
							entity: huntingRule,
							entityTypeId: this.entityTypeService.entityType.id,
							entityName: huntingRule.name,
							hideIcon: sccHostService.isSCC,
						};
					},
				},
				className: EntityNameComponent.entityNameDefaultCssClass,
			},
			{
				id: 'Title',
				name: this.i18nService.get('scheduledHunting.fields.monitorName.title'),
				getDisplay: (rule: HuntingRule) => rule.alertTitle,
			},
			{
				id: 'Severity',
				name: this.i18nService.get(
					'hunting.scheduledMonitorSidePane.fields.alertSeverity.title'
				),
				enabledByDefault: true,
				getDisplay: (rule: HuntingRule) =>
					this.i18nService.get(rule.alertSeverity.nameI18nKey),
				getCssClass: (rule: HuntingRule) =>
					`wcd-severity wcd-severity-${rule.alertSeverity.type}`,
				sort: {
					sortDescendingByDefault: true,
				},
			},
			{
				id: 'CreationTime',
				name: this.i18nService.get('scheduledHunting.fields.createdOn.title'),
				component: {
					type: TzDateComponent,
					getProps: (huntingRule: HuntingRule) => ({ date: huntingRule.createdOn }),
				},
				className: 'nowrap',
				sort: {
					sortDescendingByDefault: true,
				},
			},
			{
				id: 'CreatedBy',
				name: this.i18nService.get('scheduledHunting.fields.createdBy.title'),
				getDisplay: (rule: HuntingRule) => rule.createdBy,
			},
			{
				id: 'Actions',
				name: this.i18nService.get('scheduledHunting.fields.actions.title'),
				getDisplay: (rule: HuntingRule) => {
					if (rule.actions && rule.actions.length) {
						return rule.actions.length === 1
							? this.i18nService.get(
									`hunting.customDetections.actions.${rule.actions[0].actionType}`
							  )
							: this.i18nService.get(
									'scheduledHunting.fields.actions.multipleActions',
									{
										count: rule.actions.length,
									}
							  );
					}
				},
				getTooltip: (rule: HuntingRule) => {
					if (rule.actions && rule.actions.length > 1) {
						return rule.actions
							.map(action =>
								this.i18nService.get(
									`hunting.customDetections.actions.${action.actionType}`
								)
							)
							.join(', ');
					}
				},
				sort: { enabled: false },
			},
			{
				id: 'LastRunTime',
				name: this.i18nService.get('scheduledHunting.fields.lastRunTime.title'),
				component: {
					type: ScheduledHuntingRunTimeComponent,
					getProps: (rule: HuntingRule) => ({ rule, runTime: rule.lastRunTime }),
				},
				className: 'nowrap',
			},
			{
				id: 'LastRunStatus',
				name: this.i18nService.get('scheduledHunting.fields.lastRunStatus.title'),
				component: {
					type: ScheduledHuntingLastRunStatusComponent,
					getProps: (huntingRule: HuntingRule) => ({ rule: huntingRule }),
				},
				sort: { enabled: false },
			},
			...(this.featuresService.isEnabled('HuntingRuleFrequency')
				? [
						{
							id: 'HuntingRuleFrequency',
							name: this.i18nService.get(
								'scheduledHunting.fields.huntingRuleFrequency.title'
							),
							getDisplay: (rule: HuntingRule) => {
								if(rule.intervalHours){
									return (
										this.i18nService.get(
											`hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.everyFrequencyHours.${
												+rule.intervalHours > 1 ? 'plural' : 'singular'
											}`,
											{ freq: rule.intervalHours }
										)
									);
								}
								if(rule.intervalHours === 0){
									return this.i18nService.get('hunting_scheduledMonitorSidePane_fields_huntingRuleFrequency_continuous');
								}
								return '';
							},
							className: 'nowrap',
						},
				  ]
				: []),
			{
				id: 'NextRunTime',
				name: this.i18nService.get('scheduledHunting.fields.nextRunTime.title'),
				component: {
					type: ScheduledHuntingRunTimeComponent,
					getProps: (rule: HuntingRule) => ({ rule, runTime: rule.nextRunTime }),
				},
				className: 'nowrap',
			},
			{
				id: 'LastUpdatedTime',
				name: this.i18nService.get('scheduledHunting.fields.lastUpdatedOn.title'),
				component: {
					type: TzDateComponent,
					getProps: (huntingRule: HuntingRule) => ({ date: huntingRule.lastUpdatedOn }),
				},
				className: 'nowrap',
				sort: {
					sortDescendingByDefault: true,
				},
			},
			{
				id: 'LastUpdatedBy',
				name: this.i18nService.get('scheduledHunting.fields.lastUpdatedBy.title'),
				getDisplay: (rule: HuntingRule) => rule.lastUpdatedBy,
				sort: { enabled: false },
			},
			{
				id: this.machineGroupsFieldId,
				name: this.i18nService.get('scheduledHunting.fields.machineGroups.title'),
				getDisplay: (rule: HuntingRule) => this.getMachineGroupsLabel(rule),
				sort: { enabled: false },
				getTooltip: (rule: HuntingRule) => this.getMachineGroupsTooltip(rule),
			},
			{
				id: 'IsEnabled',
				name: this.i18nService.get('scheduledHunting.fields.isEnabled.title'),
				getDisplay: (rule: HuntingRule) => (rule.isEnabled ? 'On' : 'Off'),
			},
			{
				id: 'ServiceSource',
				name: this.i18nService.get('scheduledHunting.fields.serviceSources.title'),
				enabledByDefault: true,
				getDisplay: (rule: HuntingRule) => {
					return (
						rule.serviceSources &&
						rule.serviceSources
							.filter(Boolean)
							.map(
								source =>
									this.i18nService.strings[`shorthand_${source.nameI18nKey}`] ||
									this.i18nService.get(source.nameI18nKey)
							)
							.join(', ')
					);
				},
				description: this.i18nService.get('scheduledHunting.fields.serviceSources.toolTip'),
				sort: { enabled: false },
			},
		];

		return DataviewField.fromList<HuntingRule>(scheduledHuntingFields);
	});
	constructor(
		private readonly i18nService: I18nService,
		private readonly featuresService: FeaturesService,
		private readonly entityTypeService: ScheduledHuntingEntityTypeService
	) {}

	get fields(): Array<DataviewField> {
		if (!this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
			const scheduledHuntingFields = this._allFields.value.filter(
				field => field.id !== this.machineGroupsFieldId
			);
			return scheduledHuntingFields;
		}

		return this._allFields.value;
	}

	getMachineGroupsLabel(rule: HuntingRule) {
		const groups: Array<MachineGroup> = rule.machineGroups || [];
		const params =
			groups.length === 0
				? {}
				: {
						numGroups: groups.length,
						plural: groups.length > 1 ? 's' : '',
				  };

		return groups.length !== 0 ?
			(groups.length > 1 ?
				this.i18nService.get('scheduledHunting.fields.machineGroups.specificGroups', params) :
				this.i18nService.get('scheduledHunting.fields.machineGroups.specificGroups.single')) :
			this.i18nService.get('scheduledHunting.fields.machineGroups.all');
	}

	getMachineGroupsTooltip(rule: HuntingRule) {
		return (rule.machineGroups || [])
			.filter(Boolean)
			.map(group => group.name)
			.join(', ');
	}
}
