/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./entity-name.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "./entity-name.component";
import * as i4 from "../../services/global-entity-types.service";
import * as i5 from "../../services/entity-panels.service";
import * as i6 from "./entity-names.component";
var styles_EntityNamesComponent = [];
var RenderType_EntityNamesComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntityNamesComponent, data: {} });
export { RenderType_EntityNamesComponent as RenderType_EntityNamesComponent };
function View_EntityNamesComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "entity-name", [], null, null, null, i1.View_EntityNameComponent_0, i1.RenderType_EntityNameComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 573440, null, 0, i3.EntityNameComponent, [i4.GlobalEntityTypesService, i5.EntityPanelsService], { entity: [0, "entity"], entityTypeId: [1, "entityTypeId"], entityName: [2, "entityName"], tooltip: [3, "tooltip"], hideIcon: [4, "hideIcon"], linkClass: [5, "linkClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityNameClass; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.entity; var currVal_2 = _v.context.$implicit.entityTypeId; var currVal_3 = _v.context.$implicit.entityName; var currVal_4 = _v.context.$implicit.tooltip; var currVal_5 = _co.hideIcon; var currVal_6 = _v.context.$implicit.linkClass; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_EntityNamesComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNamesComponent_2)), i0.ɵdid(5, 278528, null, 0, i2.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.className; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.entities; _ck(_v, 5, 0, currVal_1); }, null); }
export function View_EntityNamesComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityNamesComponent_1)), i0.ɵdid(2, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entities; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EntityNamesComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-names", [], null, null, null, View_EntityNamesComponent_0, RenderType_EntityNamesComponent)), i0.ɵdid(1, 49152, null, 0, i6.EntityNamesComponent, [], null, null)], null, null); }
var EntityNamesComponentNgFactory = i0.ɵccf("entity-names", i6.EntityNamesComponent, View_EntityNamesComponent_Host_0, { entities: "entities", hideIcon: "hideIcon", className: "className", entityNameClass: "entityNameClass" }, {}, []);
export { EntityNamesComponentNgFactory as EntityNamesComponentNgFactory };
