import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Sample request response',
	pluralName: '',
	readonly: true,
})
export class SampleRequestResponse extends ModelBase {
	@EntityField({ data: 'State' })
	readonly sha: string;

	@EntityField({ data: 'RequestGuid' })
	readonly requestGuid: string;
}
