import { Component, Input, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
	selector: 'enable-auto-ir',
	template: `
		<div class="page-header" *ngIf="title">
			<h2>{{ title }}</h2>
		</div>
		<div
			class="wcd-flex-center-all wcd-full-height wcd-full-width wcd-full-height-from-width-800"
			[ngClass]="messageClass"
		>
			<a [routerLink]="['/preferences2', 'integration']" class="wcd-flex-center-vertical">
				<wcd-shared-icon [iconName]="'investigationOutline'" class="wcd-padding-small-right">
				</wcd-shared-icon>
				<h2 class="wcd-font-size-l">
					{{ 'enableAirs.title' | i18n }}
				</h2>
			</a>
		</div>
	`,
})
export class EnableAutomatedIrComponent implements OnDestroy {
	private paramSubscription: Subscription;
	@Input() title: string;

	@Input() messageClass: string;

	constructor(private route: ActivatedRoute) {
		this.paramSubscription = this.route.params.subscribe(params => {
			this.title = params.title;
			this.messageClass = params.messageClass;
		});
	}

	ngOnDestroy() {
		this.paramSubscription && this.paramSubscription.unsubscribe();
	}
}
