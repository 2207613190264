import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { MissingKbFieldsService } from './services/missing-kb.fields.service';
import { MissingKbEntityTypeService } from './services/missing-kb.entity-type.service';
import { MissingKbEntityPanelComponent } from './components/software-entity/missing-kb.entity-panel.component';
import { SidePanelService } from '../common/side-panel.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule],
	declarations: [MissingKbEntityPanelComponent],
	exports: [MissingKbEntityPanelComponent],
	entryComponents: [MissingKbEntityPanelComponent],
	providers: [MissingKbFieldsService, MissingKbEntityTypeService, SidePanelService],
})
export class MissingKbEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(MissingKbEntityTypeService);
	}
}
