/* tslint:disable:template-click-events-have-key-events */
import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {
	Machine,
	TreeElementCyberEntityTypeEnum,
	Tag,
	Alert,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertPageService } from '../services/alert-page.service';
import { EntityCommandBarDisplayMode } from '../../../global_entities/models/entity-command-bar-display-mode.enum';

@Component({
	selector: 'alerted-machine-details',
	templateUrl: './alerted-machine-details.component.html',
	styleUrls: ['./alerted-machine-details.component.scss'],
})
export class AlertedMachineDetailsComponent implements OnInit {
	@Input() machine: Machine;
	@Input() alert: Alert;

	machineType: any;
	machineIcon: string;
	machineDetailsTabType: string;
	tags$: Observable<ReadonlyArray<Tag>>;
	isMinimized: boolean = true;
	entityCommandBarDisplayMode = EntityCommandBarDisplayMode;
	constructor(
		readonly globalEntityTypesService: GlobalEntityTypesService,
		readonly alertPageService: AlertPageService
	) {
		this.machineIcon = this.globalEntityTypesService.getEntityTypeIcon(Machine);
		this.machineType = this.globalEntityTypesService.getEntityType(Machine);
		this.machineDetailsTabType = TreeElementCyberEntityTypeEnum.machine;
	}

	ngOnInit() {
		this.tags$ = this.machineType.getTags([this.machine]);
	}
}
