import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { PlatformProvisionStatusModel } from './platform-provision-status.value-object';

@ApiCall({
	name: 'Get provisioning status',
	endpoint: 'mtp/platform',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetPlatformProvisionStatusApiCall extends ApiCallModel<PlatformProvisionStatusModel> {}
