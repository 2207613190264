import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { Vulnerability } from '@wcd/domain';

@Component({
	selector: 'vulnerability-panel-component',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<header class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
				<h3 #panelHeader class="side-panel-title">
					{{ vulnerability.name }}
				</h3>
			</header>
			<section class="wcd-padding-vertical wcd-padding-large-horizontal">
				<vulnerability-entity-details
					[vulnerability]="vulnerability"
					[includeExposedMachinesComponent]="includeExposedMachinesComponent"
					[isNetworkGearCve]="isNetworkGearCve"
				></vulnerability-entity-details>
			</section>
		</wcd-panel>
	`,
})
export class VulnerabilityPanelComponent extends PanelContainer {
	@Input() vulnerability: Vulnerability;
	@Input() includeExposedMachinesComponent: boolean;
	@Input() isNetworkGearCve: boolean;

	constructor(router: Router) {
		super(router);
	}
}
