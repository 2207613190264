import { Component, ChangeDetectionStrategy, Input, Injector } from '@angular/core';
import { IpWithAdapter } from '@wcd/domain';
import { EntityDetailsComponentBase } from '../../../global_entities/components/entity-details/entity-details.component.base';

@Component({
	selector: 'ip-with-adapter-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './ip-with-adapter.entity-details.component.html',
})
export class IpWithAdapterEntityDetailsComponent extends EntityDetailsComponentBase<IpWithAdapter> {
	@Input() showIpAddress: boolean = true;

	@Input() showPhysicalAddressNotAvailableTooltip: boolean = false;

	@Input() ipWithAdapter: IpWithAdapter;

	constructor(injector: Injector) {
		super(injector);
	}

	get ip(): IpWithAdapter {
		return this.entity;
	}
}
