import { Component } from '@angular/core';

@Component({
	selector: 'wcd-info-box',
	template: `
		<div
			class="color-box-gray-25 wcd-flex-none wcd-padding-small-left wcd-padding-top wcd-padding-right wcd-padding-bottom wcd-flex-horizontal wcd-font-size-s"
		>
			<div class="wcd-flex-none wcd-padding-small-right">
				<wcd-shared-icon class="small-icon" iconName="Info"></wcd-shared-icon>
			</div>
			<ng-content></ng-content>
		</div>
	`,
})
export class InfoBoxComponent {}
