var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';
import { DetectionSource } from '../alert/sources/detection-source.entity';
import { ExploitGuardInnerRule } from './exploit_guard/exploit-guard-inner-rule.value-object';
import { exploitGuardInnerRules } from './exploit_guard/exploit-guard-inner-rule.values';
import { detectionSourceValues } from '../alert/sources/detection-source.entity.values';
import { CommandLine } from '../entity/command-line.value-object';
import { AccessTokenModificationDetails } from '../entity/access-token-modification-details.value-object';
import { ScheduledTask } from '../entity/scheduled-task.value-object';
import { UsbEvent } from '../entity/usb-event.value-object';
var ɵ0 = function (fieldData) {
    if (!fieldData)
        return null;
    var parsedData;
    try {
        parsedData = JSON.parse(fieldData);
    }
    catch (e) {
        console.warn("Failed to parse TokenModificationProperties json. value: " + fieldData);
    }
    return parsedData;
}, ɵ1 = function (rawData) {
    return rawData.Command ? rawData : null;
}, ɵ2 = function (rawData) { return rawData.TaskName; }, ɵ3 = function (reportSource) {
    return detectionSourceValues.find(function (source) { return source.type === reportSource; });
}, ɵ4 = function (ruleId) {
    return exploitGuardInnerRules.find(function (rule) { return rule.id === ruleId; }) || { id: ruleId };
}, ɵ5 = function (profiles) {
    return profiles ? (Array.isArray(profiles) ? profiles : profiles.split(',')) : null;
};
var CyberEventAdditionalFields = /** @class */ (function (_super) {
    __extends(CyberEventAdditionalFields, _super);
    function CyberEventAdditionalFields() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'TokenModificationProperties',
            parse: ɵ0,
        }),
        __metadata("design:type", AccessTokenModificationDetails)
    ], CyberEventAdditionalFields.prototype, "accessTokenDetails", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ1,
        }),
        __metadata("design:type", CommandLine)
    ], CyberEventAdditionalFields.prototype, "command", void 0);
    __decorate([
        EntityField({
            data: '__self',
            require: ɵ2,
        }),
        __metadata("design:type", ScheduledTask)
    ], CyberEventAdditionalFields.prototype, "scheduledTask", void 0);
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "description", void 0);
    __decorate([
        EntityField({
            data: 'ReportSource',
            parse: ɵ3,
        }),
        __metadata("design:type", DetectionSource)
    ], CyberEventAdditionalFields.prototype, "detectionSource", void 0);
    __decorate([
        EntityField({ data: 'Experience' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "experience", void 0);
    __decorate([
        EntityField({
            data: 'RuleId',
            parse: ɵ4,
        }),
        __metadata("design:type", ExploitGuardInnerRule)
    ], CyberEventAdditionalFields.prototype, "exploitGuardInnerRule", void 0);
    __decorate([
        EntityField({
            data: 'Profiles',
            parse: ɵ5,
        }),
        __metadata("design:type", Array)
    ], CyberEventAdditionalFields.prototype, "firewallProfiles", void 0);
    __decorate([
        EntityField({ data: 'RemoteAddress' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "remoteAddress", void 0);
    __decorate([
        EntityField({ data: 'RemotePort' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "remotePort", void 0);
    __decorate([
        EntityField({ data: 'ThreatName' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "threatName", void 0);
    __decorate([
        EntityField({ data: 'WasRemediated' }),
        __metadata("design:type", Boolean)
    ], CyberEventAdditionalFields.prototype, "wasRemediated", void 0);
    __decorate([
        EntityField({ data: 'WasExecutingWhileDetected' }),
        __metadata("design:type", Boolean)
    ], CyberEventAdditionalFields.prototype, "wasExecutingWhileDetected", void 0);
    __decorate([
        EntityField({ data: 'ResponseCategory' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "responseCategory", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", UsbEvent)
    ], CyberEventAdditionalFields.prototype, "usbEvent", void 0);
    __decorate([
        EntityField({ data: 'Uri' }),
        __metadata("design:type", String)
    ], CyberEventAdditionalFields.prototype, "uri", void 0);
    CyberEventAdditionalFields = __decorate([
        ValueObject({
            singularName: 'Cyber event additional fields',
            pluralName: 'Cyber event additional fields',
            readonly: true,
        })
    ], CyberEventAdditionalFields);
    return CyberEventAdditionalFields;
}(ModelBase));
export { CyberEventAdditionalFields };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
