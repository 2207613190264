import { AlertCategoryType } from '../../alert/alert-category.entity.values';
import { AlertDeterminationType, combinedAlertDeterminationType } from '../../alert/alert-determination.entity.values';
import { AlertClassificationType } from '../../alert/alert-classification.entity.values';

export enum AlertSeverityType {
	'High' = 'High',
	'Medium' = 'Medium',
	'Low' = 'Low',
	'Informational' = 'Informational',
}

export enum AlertDetectionSourceType {
	'EDR' = 'EDR',
	'Antivirus' = 'Antivirus',
	'SmartScreen' = 'SmartScreen',
	'CustomTI' = 'CustomTI',
	'OfficeATP' = 'OfficeATP',
	'AutomatedInvestigation' = 'AutomatedInvestigation',
	'ThreatExperts' = 'ThreatExperts',
	'CustomDetection' = 'CustomDetection',
	'ThirdPartySensors' = 'ThirdPartySensors',
}

export enum AlertStatusReportType {
	New = 'New',
	InProgress = 'InProgress',
	Resolved = 'Resolved',
}

export type SeverityKeyType = keyof typeof AlertSeverityType;
export type DetectionSourceKeyType = keyof typeof AlertDetectionSourceType;
export type CategoryKeyType = keyof typeof AlertCategoryType;
export type AlertStatusKeyType = keyof typeof AlertStatusReportType;
export type AlertClassificationKeyType = keyof typeof AlertClassificationType;
export type AlertDeterminationKeyType = keyof typeof AlertDeterminationType;
export type combinedAlertDeterminationKeyType = keyof typeof combinedAlertDeterminationType;
export type AlertClassificationAndDeterminationKeyType =
	| keyof typeof AlertClassificationType
	| keyof typeof AlertDeterminationType;	
export type AlertClassificationAndCombinedDeterminationKeyType =
	| keyof typeof AlertClassificationType
	| keyof typeof combinedAlertDeterminationType;	

export type ThreatReportDimensionKeyType =
	| SeverityKeyType
	| DetectionSourceKeyType
	| CategoryKeyType
	| AlertStatusKeyType
	| AlertDeterminationKeyType
	| combinedAlertDeterminationKeyType
	| AlertClassificationKeyType
	| AlertClassificationAndDeterminationKeyType;	
