import { Component } from '@angular/core';
import { ReportModel } from '../../../reports/models/report.model';
import { UrlAlertsSummaryWidgetComponent } from './widgets/url.alerts-summary.widget';
import { UrlPrevalenceWidgetComponent } from './widgets/url.prevalence.widget';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

export const urlReportModel = new ReportModel({
	id: 'url-overview',
	allowRangeSelect: false,
	allowExport: false,
	enablePadding: false,
	overflow: 'vertical',
	refreshOnQueryParamsChange: false,
	refreshRate: 1440 * 60 * 1000, // 24h - do not refresh
	rows: [
		{
			columns: [
				{ widgets: [UrlAlertsSummaryWidgetComponent], weight: 2 },
				{ widgets: [UrlPrevalenceWidgetComponent], weight: 1 },
			],
		},
	],
});
@Component({
	selector: 'url-overview',
	template: `
		<report [report]="report" [showPollingError]="false" [showControls]="false" [removePaddingRight]="true" (onWidgetsLoaded)="onWidgetsLoaded()"
		[enableFocusOnFirstElement]="false"> </report>
	`,
})
export class UrlOverviewComponent {
	report: ReportModel;

	constructor(private performanceSccService: PerformanceSccService,) {
		this.report = urlReportModel;
	}

	onWidgetsLoaded(){
		this.performanceSccService.endNgPageLoadPerfSession('overview');
	}
}
