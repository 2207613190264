import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SystemExclusionTypeField } from './system-exclusion-type-field.interface';
import { SystemExclusionTypeFile } from './system-exclusion-type-file.interface';
import { systemExclusionTypeValues } from './system-exclusion-type.values';

@Entity({
	singularName: 'System Exclusion Type',
	pluralName: 'System Exclusion Types',
	values: systemExclusionTypeValues,
})
export class SystemExclusionType extends EntityModelBase<string> {
	@EntityField() name: string;

	@EntityField() icon: string;

	@EntityField() helpKey: string;

	@EntityField() file: SystemExclusionTypeFile;

	@EntityField() properties: Array<SystemExclusionTypeField>; //represents the needed fields for a specific rule type (for example, "file" needs "product_vendor" & "product_name" fields)

	@EntityField() readonly: boolean;

	@EntityField({ defaultValue: false })
	hideIfEmpty: boolean;

	@EntityField({ defaultValue: false })
	hideIfPhase1OffloadingEnabled: boolean;

	@EntityField({ defaultValue: false })
	hideIfPhase2OffloadingEnabled: boolean;
}
