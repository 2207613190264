import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

export enum CustomTiIndicatorIdentifierTypes {
	FileSha1 = 0,
	FileSha256 = 1,
	FileMd5 = 2,
	IpAddress = 3,
	DomainName = 4,
	Url = 5,
	CertificateThumbprint = 6,
	ProcessMemorySimilarityHash = 7,
}

@Entity({
	singularName: 'Custom Ti Indicator Identifier Type',
	pluralName: 'Custom Ti Indicator Identifier Types',
	values: [
		{
			id: CustomTiIndicatorIdentifierTypes.FileSha1,
			name: 'SHA1',
			type: 'files',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.FileSha256,
			name: 'SHA256',
			type: 'files',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.FileMd5,
			name: 'MD5',
			type: 'files',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.IpAddress,
			name: 'IP',
			type: 'ip',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.Url,
			name: 'Url',
			type: 'url',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.DomainName,
			name: 'Domain',
			type: 'url',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.CertificateThumbprint,
			name: 'Certificate',
			type: 'certificate',
		},
		{
			id: CustomTiIndicatorIdentifierTypes.ProcessMemorySimilarityHash,
			name: 'ProcessMemory',
			type: 'memory_content',
		},
	],
})
export class CustomTiIndicatorIdentifierType extends EntityModelBase<CustomTiIndicatorIdentifierTypes> {
	@EntityField() name: string;

	@EntityField() type: string;
}
