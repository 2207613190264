/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-device.entity-panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../global_entities/components/entity-details/baseline-profile-device.entity-details.component.ngfactory";
import * as i3 from "../../../../../../global_entities/components/entity-details/baseline-profile-device.entity-details.component";
import * as i4 from "@microsoft/paris/dist/lib/paris";
import * as i5 from "../../../../../../global_entities/services/activated-entity.service";
import * as i6 from "../../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./profile-device-configurations.dataview.component.ngfactory";
import * as i8 from "../services/profile-device.fields.service";
import * as i9 from "../../../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i10 from "../../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "../../../../../../rbac/services/rbac.service";
import * as i12 from "./profile-device-configurations.dataview.component";
import * as i13 from "../../../../../../tvm/services/tvm-download.service";
import * as i14 from "../../../../../../shared/components/tabs/tabs.component.ngfactory";
import * as i15 from "../../../../../../shared/components/tabs/tabs.component";
import * as i16 from "@angular/router";
import * as i17 from "@angular/common";
import * as i18 from "./profile-device.entity-panel.component";
var styles_ProfileDeviceEntityPanelComponent = [i0.styles];
var RenderType_ProfileDeviceEntityPanelComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileDeviceEntityPanelComponent, data: {} });
export { RenderType_ProfileDeviceEntityPanelComponent as RenderType_ProfileDeviceEntityPanelComponent };
function View_ProfileDeviceEntityPanelComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "wcd-padding-vertical wcd-padding-large-horizontal wcd-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "baseline-profile-device-entity-details", [], null, null, null, i2.View_BaselineProfileDeviceEntityDetailsComponent_0, i2.RenderType_BaselineProfileDeviceEntityDetailsComponent)), i1.ɵdid(3, 770048, null, 0, i3.BaselineProfileDeviceEntityDetailsComponent, [i4.Paris, i5.ActivatedEntity, i6.I18nService, i1.Injector], { baselineProfileDevice: [0, "baselineProfileDevice"], machine: [1, "machine"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.baselineProfileDevice; var currVal_1 = _co.machine; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_ProfileDeviceEntityPanelComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 3, "profile-device-configurations-dataview", [], null, null, null, i7.View_ProfileDeviceConfigurationsDataviewComponent_0, i7.RenderType_ProfileDeviceConfigurationsDataviewComponent)), i1.ɵprd(512, null, i8.ProfileDeviceFieldsService, i8.ProfileDeviceFieldsService, [i9.TzDateService, i6.I18nService, i10.FeaturesService, i11.RbacService]), i1.ɵdid(4, 114688, null, 0, i12.ProfileDeviceConfigurationsDataviewComponent, [i8.ProfileDeviceFieldsService, i13.TvmDownloadService, i4.Paris], { profileName: [0, "profileName"], deviceName: [1, "deviceName"], sourceValue: [2, "sourceValue"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profile.name; var currVal_1 = _co.baselineProfileDevice.name; var currVal_2 = _co.profileDeviceIds; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
export function View_ProfileDeviceEntityPanelComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "tabs", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, [[null, "select"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("select" === en)) {
        var pd_0 = (_co.setTab($event.tab) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i14.View_TabsComponent_0, i14.RenderType_TabsComponent)), i1.ɵdid(3, 4833280, null, 0, i15.TabsComponent, [i1.ChangeDetectorRef, i16.ActivatedRoute, i16.Router], { tabsData: [0, "tabsData"] }, { select: "select" }), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "wcd-flex-vertical"]], null, null, null, null, null)), i1.ɵdid(7, 16384, null, 0, i17.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileDeviceEntityPanelComponent_1)), i1.ɵdid(10, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileDeviceEntityPanelComponent_2)), i1.ɵdid(13, 278528, null, 0, i17.NgSwitchCase, [i1.ViewContainerRef, i1.TemplateRef, i17.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.currentTab.id; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.tabIds.deviceDetails; _ck(_v, 10, 0, currVal_2); var currVal_3 = _co.tabIds.configurations; _ck(_v, 13, 0, currVal_3); }, null); }
export function View_ProfileDeviceEntityPanelComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-configuration-entity-panel", [], null, null, null, View_ProfileDeviceEntityPanelComponent_0, RenderType_ProfileDeviceEntityPanelComponent)), i1.ɵdid(1, 245760, null, 0, i18.ProfileDeviceEntityPanelComponent, [i5.ActivatedEntity, i6.I18nService, i4.Paris, i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileDeviceEntityPanelComponentNgFactory = i1.ɵccf("profile-configuration-entity-panel", i18.ProfileDeviceEntityPanelComponent, View_ProfileDeviceEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { ProfileDeviceEntityPanelComponentNgFactory as ProfileDeviceEntityPanelComponentNgFactory };
