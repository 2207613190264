// tslint:disable:template-accessibility-label-for
import { Component, forwardRef } from '@angular/core';
import { RemediationRejectReason } from '../services/remediation-reject-reason.interface';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { I18nService } from '@wcd/i18n';

@Component({
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RemediationDeclineReasonComponent),
			multi: true,
		},
	],
	selector: 'remediation-decline-reason',
	template: `
		<div class="wcd-flex-1">
			<label class="dropdown-label wcd-required">
				{{ i18nService.strings.remediationActions_rejectReasons_title }}
			</label>
			<wcd-select
				[placeholder]="i18nService.strings.remediationActions_rejectReasons_selectReason"
				[values]="remediationRejectReasons"
				[isFullWidth]="true"
				[label]="'label'"
				[(ngModel)]="selectedReason"
				(ngModelChange)="onSelectionChange()"
				[ariaLabel]="i18nService.strings.remediationActions_rejectReasons_title"
			>
			</wcd-select>
			<textarea
				*ngIf="selectedReason?.isCustom"
				rows="4"
				class="wcd-margin-vertical wcd-full-width"
				[(ngModel)]="customReason"
				(ngModelChange)="onSelectionChange()"
				autofocus
				name="remediation-decline-comment"
				required
				[placeholder]="i18nService.strings.remediationAction_panel_enterComment"
			></textarea>
		</div>
	`,
})
export class RemediationDeclineReasonComponent implements ControlValueAccessor {
	selectedReason: RemediationRejectReason;
	customReason: string;
	onChange = _ => {};
	onTouched = () => {};

	remediationRejectReasons: ReadonlyArray<RemediationRejectReason> = Object.freeze([
		{
			id: 'wrong_entity_verdict',
			label: this.i18nService.strings.remediation_declineReason_wrongVerdict,
		},
		{
			id: 'keep_entity',
			label: this.i18nService.strings.remediation_declineReason_keepEntity,
		},
		{
			id: 'other',
			label: this.i18nService.strings.remediation_declineReason_other,
			isCustom: true,
		},
	]);

	constructor(public i18nService: I18nService) {}

	writeValue(item: RemediationRejectReason | string): void {
		let reason: RemediationRejectReason;
		if (typeof item === 'string') {
			reason = this.remediationRejectReasons.find(r => r.id === item);
			if (!reason) {
				reason = this.remediationRejectReasons.find(r => r.isCustom);
				this.customReason = item;
			}
		} else if (!this.remediationRejectReasons.includes(item)) {
			reason = null;
		}
		this.selectedReason = reason;
		this.onSelectionChange();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	onSelectionChange() {
		this.onChange(
			this.selectedReason &&
				(!this.selectedReason.isCustom || this.customReason) && {
					reason: this.selectedReason,
					comment: this.selectedReason.isCustom ? this.customReason : this.selectedReason.label,
				}
		);
	}
}
