import { File } from '../file.entity';
import { FileQuarantineActions } from '../file.quarantine-actions.value-object';
import { EntityRelationship, RelationshipType, EntityRelationshipRepositoryType } from '@microsoft/paris';

const statusMap = {
	SucceededPendingReboot: 'Succeeded',
	ReceivedByServer: 'Pending',
	ReceivedByClient: 'Pending',
};

const RECEIVED_BY_SERVER_STATUS = 'ReceivedByServer';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileQuarantineActions,
	foreignKey: 'FileIdentifier',
	fixedData: { FileIdentifierType: 0 },
	endpoint: 'remediation/latestforfile',
	baseUrl: config => config.data.serviceUrls.userRequests,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	parseData: data => {
		// The data that is returned from backend is a mess. It'd be nice to ask the backend people to return the data like this...

		const quarantineActions = data.reduce(
			(actionCenterData, result) => {
				if (!actionCenterData.cancel && result.CancellationDateTimeUtc) {
					actionCenterData.cancel = {
						time: new Date(result.CancellationDateTimeUtc),
						comment: result.CancellationComment,
						user: result.CancellationRequestor,
					};
				}

				if (!actionCenterData.request && result.CreationDateTimeUtc) {
					actionCenterData.request = {
						time: new Date(result.CreationDateTimeUtc),
						comment: result.RequestorComment,
						user: result.Requestor,
					};
				}

				if (!actionCenterData.isCancelable && result.RemediationStatus === RECEIVED_BY_SERVER_STATUS)
					actionCenterData.isCancelable = true;

				const status = statusMap[result.RemediationStatus] || result.RemediationStatus;
				actionCenterData.statuses[status] = (actionCenterData.statuses[status] || 0) + 1;
				return actionCenterData;
			},
			{ statuses: {} }
		);

		if (quarantineActions.cancel) {
			quarantineActions.statuses['Cancelled'] =
				(quarantineActions.statuses['Cancelled'] || 0) + (quarantineActions.statuses['Pending'] || 0);
			delete quarantineActions.statuses['Pending'];
		}

		quarantineActions.statuses = Object.entries(quarantineActions.statuses).map(([id, count]) => ({
			id,
			count,
		}));

		return quarantineActions;
	},
})
export class FileQuarantineActionsRelationship
	implements EntityRelationshipRepositoryType<File, FileQuarantineActions> {}
