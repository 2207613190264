<div *ngIf="(data$ | async) as data" class="wcd-full-height wcd-flex-vertical" style="justify-content: space-between">
	<div>
		<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">
			{{ 'tvm.secureScoreWidget.secureScore' | i18n }} {{ getScorePercentage(data.totalScore)}}%
			<br>
			<span class="ms-fontSize-xl">{{data.totalScore.secureScore}}
				<span class="ms-fontColor-gray140">/ {{data.totalScore.maxSecureScore}}</span>
			</span>
		</h2>
		<p class="wcd-margin-bottom">
			{{ 'tvm.secureScoreWidget.description' | i18n }} <wcd-help [text]="widgetTooltip" [ariaLabel]="widgetTooltipAriaLabel" [wcdTooltipAllowHtmlRendering]="true"></wcd-help>
			<ng-container *ngIf="isImpactedByExceptions">
				<br><span [innerHTML]="'tvm.secureScoreWidget.scoreImpactedByException' | i18n"></span>
			</ng-container>
			<ng-container *ngIf="userHasSecureScoreAccess">
				<br><span [innerHTML]="'tvm_secureScoreWidget_viewOverallLink' | i18n"></span>
			</ng-container>
		</p>
		<wcd-stretched-donut-bars class="secure-score-bars wcd-margin-xsmall-bottom"
			[data] = "data.donutItems"
			(onClick) = "openSecureRecommendations($event.title)"
			barCssClass = 'wcd-margin-small-bottom'>
		</wcd-stretched-donut-bars>
	</div>

	<tvm-score-trend *ngIf="trendOptions" class="wcd-margin-medium-top" [options]="trendOptions"></tvm-score-trend>
	<wcd-line-chart *ngIf="newChartOptions" [options]="newChartOptions"></wcd-line-chart>
</div>
