var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { AirsEntityAction } from './airs-entity-action.value-object';
import { AirsEntityReport } from './airs-entity-report.value-object';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { AirsRelatedEntityReport } from './airs-related-entity-report.value-object';
import { AirsRelatedEntity } from './airs-related-entity.value-object';
import { RemediationAction } from '../remediation/remediation-action.entity';
import { SystemExclusion } from '../system_exclusions/system-exclusion.entity';
import { AirsEntityRemediationStatus } from './airs-entity-remediation-status.entity';
import { AirsEntityVerdict } from './airs-entity-verdict.entity';
import { EvidenceDetectionSource } from '../evidence/evidence-detection-source.value-object';
import { compact, get } from 'lodash-es';
import { mapAlertV3EntityToAirsEntity } from './alertV3/converters/airs-entity.converter.utils';
import { OfficeUtils } from '../utils/office-utils';
import { AirsEntityType, AirsEntityTypeValue } from './airs-entity-type.entity';
import { AirsEntityRefNames } from './types/airs-entity-ref-names';
import { dateStrToUtc } from '../utils';
import { RemediationStatusId } from './airs-entity-status-types.enum';
import { sccHostService } from '@wcd/scc-interface';
var ɵ0 = function (config, query) {
    if (query.where['incident_id']) {
        if (query.where['EvidenceApiV2']) {
            return "evidenceapiservice/evidence/incident/" + query.where['incident_id'];
        }
        return "entities/evidence/" + query.where['incident_id'];
    }
    if (query.where['useOfficeEntityApi']) {
        return "Find/MtpBatch?tenantid=" + query.where['tenantId'] + "&PageSize=200&Filter=ModelType eq 0 and ContainerUrn eq '" + query.where['investigation_urn'] + "'";
    }
    return 'entities/';
}, ɵ1 = function (itemId, entity, config, params) {
    if (params && params.useEvidenceApiV2 && params.mergeByKey && params.alertId && params.type_id && params.evidenceV2.callDetails) {
        return "evidenceapiservice/evidence/details?AlertId=" + params.alertId + "&MergeByKey=" + params.mergeByKey;
    }
    if (params && params.useMdiEntityApi) {
        var sid = params.sid;
        var aadUserId = params.aadUserId;
        var url = "officeui/mdi/account-details";
        if (!sid) {
            throw new Error("Can't get entity data, missing sid.");
        }
        return aadUserId ? url + "?sid=" + sid + "&aadUserId=" + aadUserId : url + "?sid=" + sid;
    }
    var intEntityId = parseInt(itemId);
    var isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
    if (isIdNegative) {
        throw new Error('Entity id cannot be negative.');
    }
    if (params && params.useOfficeEntityApi) {
        // There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
        return "Find/MtpBatch?tenantid=" + params.tenantId + "&PageSize=50&Filter=ModelType eq 0 and UrnProp eq '" + itemId + "'";
    }
    if (!params || !params.type_id || !params.investigation_id) {
        throw new Error("Can't get entity, missing entity type.");
    }
    var entityId = params.useEvidenceApiV2 && params.evidenceV2.entityId ? params.evidenceV2.entityId : itemId;
    return "entities/" + entityId + "?type_id=" + params.type_id + "&investigation_id=" + params.investigation_id;
}, ɵ2 = function (config, query) {
    var useEvidenceApiV2 = query && query.where && (query.where['useEvidenceApiV2'] || query.where['EvidenceApiV2']);
    // when using the new evidence service (EvidenceApiV2 flag is enabled) we don't need to go through office to get the data
    if (query && query.where && query.where['useOfficeEntityApi'] && !useEvidenceApiV2) {
        return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
    }
    return config.data.serviceUrls.automatedIr;
}, ɵ3 = function (data, _, query) {
    var shouldConvertDataFromAlertV3 = query &&
        query.where &&
        (query.where['useEvidenceApiV2'] || query.where['EvidenceApiV2']) &&
        (!query.where['evidenceV2'] || query.where['evidenceV2'].callDetails);
    if (shouldConvertDataFromAlertV3 || (query && query.where['useOfficeEntityApi'])) {
        if (query.where['useOfficeEntityApi'] && !shouldConvertDataFromAlertV3) {
            data = get(data, 'ResultData[0].EntityBasePayload');
        }
        data = OfficeUtils.convertDataFromAlertV3(data);
        data = data && (Array.isArray(data) ? data : [data]).map(function (e) { return mapAlertV3EntityToAirsEntity(e); });
        data = compact(data); // we might have unknown types so we need to filter out the falsey values
        if (!query.where['isListView']) {
            return (data && data.length && data[0]) || null;
        }
        return data;
    }
    var useNewStatus = query && query.where['useNewStatus'];
    return Object.assign({}, data, data.results && {
        results: data.results.map(function (r) { return Object.assign({}, r, { useNewStatus: useNewStatus }); }),
    });
};
export var airsEntityConfig = Object.freeze({
    singularName: 'Entity',
    pluralName: 'Entities',
    allItemsProperty: 'results',
    allItemsEndpointTrailingSlash: false,
    separateArrayParams: true,
    endpoint: ɵ0,
    parseItemQuery: ɵ1,
    baseUrl: ɵ2,
    parseData: ɵ3,
});
var AirsEntityRefNameMap = (_a = {},
    _a[AirsEntityTypeValue.File] = AirsEntityRefNames.AirsFile,
    _a[AirsEntityTypeValue.Process] = AirsEntityRefNames.AirsProcess,
    _a[AirsEntityTypeValue.Driver] = AirsEntityRefNames.AirsDriver,
    _a[AirsEntityTypeValue.Service] = AirsEntityRefNames.AirsService,
    _a[AirsEntityTypeValue.URL] = AirsEntityRefNames.AirsUrl,
    _a[AirsEntityTypeValue.WebPage] = AirsEntityRefNames.AirsUrl,
    _a[AirsEntityTypeValue.IP] = AirsEntityRefNames.AirsIp,
    _a[AirsEntityTypeValue.AppendedFile] = AirsEntityRefNames.AirsAppendedFile,
    _a[AirsEntityTypeValue.EmailUrl] = AirsEntityRefNames.AirsEmailLink,
    _a[AirsEntityTypeValue.User] = AirsEntityRefNames.AirsUser,
    _a[AirsEntityTypeValue.PersistenceMethod] = AirsEntityRefNames.AirsPersistenceMethod,
    _a[AirsEntityTypeValue.UserActivity] = AirsEntityRefNames.AirsUserActivity,
    _a[AirsEntityTypeValue.MailCluster] = AirsEntityRefNames.AirsEmailCluster,
    _a[AirsEntityTypeValue.MailMessage] = AirsEntityRefNames.AirsEmailMessage,
    _a[AirsEntityTypeValue.Mailbox] = AirsEntityRefNames.AirsMailbox,
    _a[AirsEntityTypeValue.MailboxConfiguration] = AirsEntityRefNames.AirsMailboxConfiguration,
    _a[AirsEntityTypeValue.SubmissionMail] = AirsEntityRefNames.AirsEmailMessageSubmission,
    _a[AirsEntityTypeValue.Account] = AirsEntityRefNames.Account,
    _a[AirsEntityTypeValue.SecurityGroup] = AirsEntityRefNames.AirsSecurityGroup,
    _a[AirsEntityTypeValue.RegistryKey] = AirsEntityRefNames.AirsRegistryKey,
    _a[AirsEntityTypeValue.RegistryValue] = AirsEntityRefNames.AirsRegistryValue,
    _a[AirsEntityTypeValue.CloudApplication] = AirsEntityRefNames.AirsCloudApplication,
    _a[AirsEntityTypeValue.CloudLogonSession] = AirsEntityRefNames.AirsCloudLogonSession,
    _a[AirsEntityTypeValue.OauthApplication] = AirsEntityRefNames.AirsOauthApplication,
    _a[AirsEntityTypeValue.ActiveDirectoryDomain] = AirsEntityRefNames.AirsActiveDirectoryDomain,
    _a);
var ɵ4 = function (rawData, query) {
    var typeIdFromQuery = query &&
        query.where &&
        (query.where['type_id'] != null ? query.where['type_id'] : query.where['entity_type']);
    var typeId;
    if (typeIdFromQuery == null) {
        typeId = rawData && rawData['type_id'] != null ? rawData['type_id'] : rawData['entity_type'];
    }
    else {
        typeId = typeIdFromQuery;
    }
    if (typeId instanceof Array) {
        typeId = typeId[0];
    }
    var intTypeId = parseInt(typeId, 10);
    // reverse enum lookup
    var typeKey = AirsEntityType[intTypeId];
    return AirsEntityRefNameMap[typeKey];
}, ɵ5 = ['urn', 'id'], ɵ6 = function (id, rawData) {
    var intEntityId = parseInt(rawData.id);
    var isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
    // negative 'id' means that it's not AirsEntity and we don't want to use AIRS BE
    return isIdNegative && rawData.urn ? rawData.urn : rawData.id;
}, ɵ7 = ['entity_type', 'type_id'], ɵ8 = function (type, rawData, query) {
    if (type !== undefined && type !== null)
        return type;
    var typeId = query.where['type_id'];
    if (typeId instanceof Array) {
        return typeId[0];
    }
    return typeId;
}, ɵ9 = function (data) { return data; }, ɵ10 = function (data) { return data; }, ɵ11 = function (status) {
    /* 	remediation status should be empty in case of 'Active' status
        (see the following bug: https://microsoft.visualstudio.com/OS/_workitems/edit/31830546/)
    */
    if (status !== RemediationStatusId.Active) {
        return status;
    }
}, ɵ12 = function (data) { return data; }, ɵ13 = function (investigation, rawData) {
    return ((investigation && { id: investigation.investigation_id, title: investigation.title }) ||
        (rawData.AirsInvestigationId && { id: rawData.AirsInvestigationId, title: '' }));
}, ɵ14 = function (behavior) {
    var behaviorArray = [];
    var behaviorObject;
    for (var groupName in behavior) {
        behaviorObject = {
            name: groupName,
            values: [],
        };
        for (var propertyName in behavior[groupName]) {
            var propertyValues = behavior[groupName][propertyName];
            if (propertyValues && propertyValues.length) {
                behaviorObject.values.push({
                    name: propertyName,
                    values: propertyValues
                        .map(function (value) {
                        if (Object(value) === value) {
                            var objValues = [];
                            for (var objProperty in value)
                                objValues.push([objProperty, value[objProperty]]);
                            return objValues.length && objValues;
                        }
                        return value;
                    })
                        .filter(function (value) { return !!value; }),
                });
            }
        }
        if (behaviorObject.values.length)
            behaviorArray.push(behaviorObject);
    }
    return behaviorArray;
}, ɵ15 = function (allActions) { return allActions && allActions.filter(function (action) { return action['undo_state'] === 'undoable'; }); }, ɵ16 = function (allActions, rawData) {
    if (!allActions) {
        return rawData['remediation_actions'];
    }
    return allActions.filter(function (action) { return action['is_pending']; });
}, ɵ17 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ18 = function (data, rawData) {
    return (rawData.EntityId || rawData.id) > 0;
}, ɵ19 = function (data, rawData) {
    return {
        evidenceV2: {
            entityId: rawData.EntityId,
        },
    };
};
var AirsEntity = /** @class */ (function (_super) {
    __extends(AirsEntity, _super);
    function AirsEntity(data) {
        var _this = _super.call(this, data) || this;
        _this.isOfficeEntity = _this._isOfficeEntity || !!_this.urn;
        _this.entityName = _this.name;
        return _this;
    }
    Object.defineProperty(AirsEntity.prototype, "key", {
        get: function () {
            return this.type.typeName + "." + this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsEntity.prototype, "relationCount", {
        get: function () {
            if (isNaN(this._relationCount)) {
                if (!this.relations)
                    this._relationCount = 0;
                this._relationCount = this.relations.reduce(function (relationCount, relation) {
                    return relation.relationTypes.reduce(function (count, relationType) {
                        return count + relationType.entities.length;
                    }, relationCount);
                }, 0);
            }
            return this._relationCount;
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({
            data: ɵ5,
            parse: ɵ6,
        }),
        __metadata("design:type", String)
    ], AirsEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'merge_by_key' }),
        __metadata("design:type", String)
    ], AirsEntity.prototype, "mergeByKey", void 0);
    __decorate([
        EntityField({ data: 'urn' }),
        __metadata("design:type", String)
    ], AirsEntity.prototype, "urn", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], AirsEntity.prototype, "name", void 0);
    __decorate([
        EntityField({
            data: ɵ7,
            parse: ɵ8,
        }),
        __metadata("design:type", AirsEntityTypeConfig)
    ], AirsEntity.prototype, "type", void 0);
    __decorate([
        EntityField({ data: 'entity_status', require: ɵ9 }),
        __metadata("design:type", AirsEntityStatus)
    ], AirsEntity.prototype, "status", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], AirsEntity.prototype, "useNewStatus", void 0);
    __decorate([
        EntityField({
            data: 'remediation_status',
            require: ɵ10,
            parse: ɵ11,
        }),
        __metadata("design:type", AirsEntityRemediationStatus)
    ], AirsEntity.prototype, "remediationStatus", void 0);
    __decorate([
        EntityField({ data: 'verdict', require: ɵ12 }),
        __metadata("design:type", AirsEntityVerdict)
    ], AirsEntity.prototype, "verdict", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", AirsEntityAction)
    ], AirsEntity.prototype, "action", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", Object)
    ], AirsEntity.prototype, "_rawData", void 0);
    __decorate([
        EntityField({ data: 'hosts', arrayOf: InvestigatedMachine }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "machines", void 0);
    __decorate([
        EntityField({ data: 'host' }),
        __metadata("design:type", InvestigatedMachine)
    ], AirsEntity.prototype, "machine", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", AirsEntityReport)
    ], AirsEntity.prototype, "report", void 0);
    __decorate([
        EntityField({ data: 'related_reports', arrayOf: AirsRelatedEntityReport }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "relatedReports", void 0);
    __decorate([
        EntityField({ arrayOf: AirsRelatedEntity }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "relations", void 0);
    __decorate([
        EntityField({ data: 'status_details' }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "statusDetails", void 0);
    __decorate([
        EntityField({
            parse: ɵ13,
        }),
        __metadata("design:type", Object)
    ], AirsEntity.prototype, "investigation", void 0);
    __decorate([
        EntityField({
            parse: ɵ14,
        }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "behavior", void 0);
    __decorate([
        EntityField({
            data: 'all_actions',
            arrayOf: RemediationAction,
            parse: ɵ15,
        }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "undoableActions", void 0);
    __decorate([
        EntityField({
            data: 'all_actions',
            arrayOf: RemediationAction,
            parse: ɵ16,
        }),
        __metadata("design:type", Array)
    ], AirsEntity.prototype, "remediationActions", void 0);
    __decorate([
        EntityField({ data: 'acl_rule' }),
        __metadata("design:type", SystemExclusion)
    ], AirsEntity.prototype, "systemExclusionRule", void 0);
    __decorate([
        EntityField({ data: 'entity_page_url' }),
        __metadata("design:type", String)
    ], AirsEntity.prototype, "deepLink", void 0);
    __decorate([
        EntityField({ data: 'entity_detection_src' }),
        __metadata("design:type", EvidenceDetectionSource)
    ], AirsEntity.prototype, "detectionContext", void 0);
    __decorate([
        EntityField({ data: 'first_seen', parse: ɵ17 }),
        __metadata("design:type", Date)
    ], AirsEntity.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({
            parse: ɵ18,
        }),
        __metadata("design:type", Boolean)
    ], AirsEntity.prototype, "isConvergedPlatform", void 0);
    __decorate([
        EntityField({
            parse: ɵ19,
        }),
        __metadata("design:type", Object)
    ], AirsEntity.prototype, "additionalData", void 0);
    __decorate([
        EntityField({ data: 'is_office_entity' }),
        __metadata("design:type", Boolean)
    ], AirsEntity.prototype, "_isOfficeEntity", void 0);
    AirsEntity = __decorate([
        Entity(__assign({}, airsEntityConfig, { modelWith: ɵ4 })),
        __metadata("design:paramtypes", [Object])
    ], AirsEntity);
    return AirsEntity;
}(EntityModelBase));
export { AirsEntity };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19 };
