import { EntityField, ModelBase, Entity } from '@microsoft/paris';

export enum EvidenceAssetTypeType {
	Machine = 'Machine',
	User = 'User',
	Application = 'Application',
}

@Entity({
	singularName: 'Asset type',
	pluralName: 'Asset types',
	values: [
		{
			id: EvidenceAssetTypeType.Machine,
			icon: 'System',
		},
		{
			id: EvidenceAssetTypeType.User,
			icon: 'Contact',
		},
		{
			id: EvidenceAssetTypeType.Application,
			icon: 'CommandPrompt',
		},
	],
})
export class EvidenceAssetTypeEntity extends ModelBase {
	@EntityField()
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	// fabric icon name
	@EntityField()
	icon: string;
}
