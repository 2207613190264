import { Injectable, ComponentRef, Type } from '@angular/core';
import { RemediationTaskCreationComponent, Tabs } from '../components/remediation-task-creation.component';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import {
	SecurityRecommendation,
	RemediationType,
	ProductivityImpactRemediationType,
	RecommendationCategory,
	VA_RECOMMENDATION_FILTER_TAG,
	SCA_RECOMMENDATION_FILTER_TAG,
	RecommendationStatus,
	TvmSupportedOsPlatform,
} from '@wcd/domain';
import { PanelType, PanelContainer } from '@wcd/panels';
import { RecommendationExceptionCreationComponent } from '../components/recommendation-exception-creation.component';
import { RemediationTaskService } from '../../remediation/remediation-tasks/services/remediation-task.service';
import { of, Observable } from 'rxjs';
import { RelatedComponentEnum } from '../components/filter/related-component.enum';
import { FilterTypesEnum } from '../components/filter/filter-types.enum';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class SecurityRecommendationService {
	private _remediationCreationPanel: ComponentRef<PanelContainer>;
	private _done: boolean;
	private _recommendationFilters: Record<string, any>;
	private filterTypesMap: Record<string, FilterTypesEnum>;
	private remediationTypesToIgnoreInFilter: RemediationType[];
	private mockDataRandomValue: number;
	private _isExceptionsPerRbacFeatureEnabled = false;

	constructor(
		private dialogsService: DialogsService,
		private remediationTaskService: RemediationTaskService,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private tvmTagsService: TvmTagsService,
		appConfigService: AppConfigService
	) {
		this.filterTypesMap = {
			remediationType: FilterTypesEnum.RemediationType,
			relatedComponent: FilterTypesEnum.RelatedComponent,
			status: FilterTypesEnum.RecommendationStatus,
			recommendationTags: FilterTypesEnum.RecommendationTags,
			osPlatform: FilterTypesEnum.OsPlatform,
		};
		this.remediationTypesToIgnoreInFilter = [RemediationType.AttentionRequired];
		this._isExceptionsPerRbacFeatureEnabled =
			appConfigService.hasMachineGroups && featuresService.isEnabled(Feature.TvmExceptionsPerRbac);
		this.getRecommendationStatus = this.getRecommendationStatus.bind(this);
		this.mockDataRandomValue = Math.random();
		if (this.mockDataRandomValue < 0.2) {
			this.mockDataRandomValue += 0.2;
		}
	}

	getMockDataRandomValue() {
		return this.mockDataRandomValue;
	}

	showRemediationTaskCreationPanel(
		recommendation: SecurityRecommendation,
		remediationType: ProductivityImpactRemediationType,
		defaultRemediationActionId?: Tabs
	): Promise<boolean> {
		return this.showCreationPanel(
			{
				recommendation: recommendation,
				productivityImpactRemediation: remediationType,
				defaultSelectedRemediationActionId: defaultRemediationActionId,
			},
			'remediation-task-creation',
			RemediationTaskCreationComponent,
			(remediationTask, panel: ComponentRef<RemediationTaskCreationComponent>) => {
				if (!remediationTask) {
					panel.destroy();
				} else {
					this.remediationTaskService
						.downloadRemediationTaskCsv(remediationTask)
						.then(() => panel.destroy());
				}
			}
		);
	}

	showRecommendationExceptionCreationPanel(recommendation: SecurityRecommendation): Promise<boolean> {
		return this.showCreationPanel(
			{ securityRecommendation: recommendation },
			'recommendation-exception-creation',
			RecommendationExceptionCreationComponent,
			(model, panel: ComponentRef<RecommendationExceptionCreationComponent>) => {
				if (!model) {
					this._done = false;
				}
				panel.destroy();
			}
		);
	}

	private showCreationPanel(
		creationParams: any,
		componentId: string,
		creationComponent: Type<PanelContainer>,
		doneCallBack: (model, panel: ComponentRef<any>) => void
	): Promise<boolean> {
		// returns a promise with true when the panel closes after done is omitted and false otherwise
		return new Promise<boolean>((resolve, reject) => {
			this._done = false;

			this.dialogsService
				.showPanel(
					creationComponent,
					{
						id: componentId,
						isModal: true,
						showOverlay: false,
						hasCloseButton: true,
						type: PanelType.large,
						back: { onClick: () => this._remediationCreationPanel.instance.closePanel() },
						noBodyPadding: true,
					},
					creationParams
				)
				.subscribe((panel: ComponentRef<any>) => {
					this._remediationCreationPanel = panel;
					panel.onDestroy(() => {
						// this callback is called when the panel is destroyed
						resolve(this._done);
						this._remediationCreationPanel = null;
					});

					panel.instance.done.subscribe(model => {
						this._done = true;
						doneCallBack(model, panel);
					});
				});
		});
	}

	getRecommendationStatus(status: RecommendationStatus, addDetails: boolean = false): string {
		let key: string;
		if (this._isExceptionsPerRbacFeatureEnabled) {
			if (status === RecommendationStatus.Exception) {
				key = 'fullException';
			}
			if (addDetails && status === RecommendationStatus.PartialException) {
				key = 'partialExceptionWithExplanation';
			}
		}

		if (!key) {
			switch (status) {
				case RecommendationStatus.Active:
					key = 'active';
					break;
				case RecommendationStatus.Exception:
					key = 'exception';
					break;
				case RecommendationStatus.PartialException:
					key = 'partialException';
					break;
				default:
					break;
			}
		}

		return this.i18nService.get(`tvm.securityRecommendation.status.${key}`);
	}

	getFiltersByTypes(types: FilterTypesEnum[]): Observable<Record<string, any>> {
		if (!this._recommendationFilters) {
			this._recommendationFilters = {
				remediationType: {
					count: null,
					values: Object.keys(RemediationType)
						.filter(
							type => this.remediationTypesToIgnoreInFilter.indexOf(RemediationType[type]) < 0
						)
						.map(type => {
							return {
								value: type,
								name: this.i18nService.get(
									`tvm.remediationTask.type.${RemediationType[type]}`
								),
								count: null,
							};
						}),
				},
				relatedComponent: {
					count: null,
					values: Object.keys(RelatedComponentEnum)
						//TODO: Requested to remove the 'Other' filter. leaving rest of logic as is for now, for the chance of bringing it back soon.
						.filter(type => type !== 'Other')
						.filter(
							type =>
								type !== RecommendationCategory.NetworkGear ||
								this.featuresService.isEnabled(Feature.TvmNetworkScan)
						)
						.map((type: string) => {
							return {
								value: type,
								name: RelatedComponentEnum[type],
								count: null,
							};
						}),
				},
				status: {
					count: null,
					values: Object.keys(RecommendationStatus)
						.filter(
							(status: RecommendationStatus) =>
								status !== RecommendationStatus.PartialException ||
								this._isExceptionsPerRbacFeatureEnabled
						)
						.map((status: RecommendationStatus) => {
							return {
								value: status,
								name: this.getRecommendationStatus(status),
							};
						}),
				},
				recommendationTags: {
					count: null,
					values: this.tvmTagsService // Creating the tags from VA and SCA tags separately and then concatenating them
						.getVaRecommendationsTagsForFiltering()
						.map((tag: VA_RECOMMENDATION_FILTER_TAG) => {
							return {
								value: VA_RECOMMENDATION_FILTER_TAG[tag],
								name: this.i18nService.get(`tvm.softwarePage.filters.tags.${tag}`),
								count: null,
							};
						})
						.concat(
							this.tvmTagsService.scaRecommendationsTagsForFiltering.map(
								(tag: SCA_RECOMMENDATION_FILTER_TAG) => ({
									value: SCA_RECOMMENDATION_FILTER_TAG[tag],
									name: this.i18nService.get(`tvm.securityRecommendation.tag.${tag}`),
									count: null,
								})
							)
						),
				},
				osPlatform: {
					values: Object.keys(TvmSupportedOsPlatform).map(plat => {
						return {
							value: plat,
							name: this.i18nService.get(
								`tvm_common_osPlatform_${TvmSupportedOsPlatform[plat]}`
							),
						};
					}),
				},
			};
		}

		return of(
			Object.keys(this._recommendationFilters)
				.filter(filterName => types.includes(this.filterTypesMap[filterName]))
				.reduce((res, key) => Object.assign(res, { [key]: this._recommendationFilters[key] }), {})
		);
	}
}
