
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'accessTokens.fields.currentTokenIntegrityLevel.title' | i18n }}</dt>
			<dd>{{ accessToken.currentTokenIntegrityLevel }}</dd>
			<dt>{{ 'accessTokens.fields.originalTokenIntegrityLevel.title' | i18n }}</dt>
			<dd>{{ accessToken.originalTokenIntegrityLevel }}</dd>
			<ng-container *ngIf="accessToken.isChangedToSystemToken">
				<dt>{{ 'accessTokens.fields.isChangedToSystemToken.title' | i18n }}</dt>
				<dd>{{ 'accessTokens.fields.isChangedToSystemToken.value' | i18n }}</dd>
			</ng-container>
			<ng-container *ngIf="accessToken.privilegesFlags?.length">
				<dt>{{ 'accessTokens.fields.privilegesFlags.title' | i18n }}</dt>
				<dd>
					<div *ngFor="let privilege of accessToken.privilegesFlags">{{ privilege }}</div>
				</dd>
			</ng-container>
		</dl>
	