var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityStatusComponentBase } from '../../../global_entities/components/entity-status/entity-status.component.base';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var MachineEntityStatusComponent = /** @class */ (function (_super) {
    __extends(MachineEntityStatusComponent, _super);
    function MachineEntityStatusComponent(flavorService) {
        var _this = _super.call(this) || this;
        _this.flavorService = flavorService;
        _this.withExposureLevel = flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
        return _this;
    }
    Object.defineProperty(MachineEntityStatusComponent.prototype, "machine", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return MachineEntityStatusComponent;
}(EntityStatusComponentBase));
export { MachineEntityStatusComponent };
