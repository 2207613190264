/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./details-list.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./details-list.component";
var styles_DetailsListComponent = [i0.styles];
var RenderType_DetailsListComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DetailsListComponent, data: {} });
export { RenderType_DetailsListComponent as RenderType_DetailsListComponent };
export function View_DetailsListComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_DetailsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-dl", [], [[2, "columnar", null]], null, null, View_DetailsListComponent_0, RenderType_DetailsListComponent)), i1.ɵdid(1, 49152, null, 0, i2.DetailsListComponent, [], null, null)], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).columnar; _ck(_v, 0, 0, currVal_0); }); }
var DetailsListComponentNgFactory = i1.ɵccf("wcd-dl", i2.DetailsListComponent, View_DetailsListComponent_Host_0, { display: "display" }, {}, ["*"]);
export { DetailsListComponentNgFactory as DetailsListComponentNgFactory };
