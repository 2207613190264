var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { keyBy, transform, values } from 'lodash-es';
var StatusCategories;
(function (StatusCategories) {
    StatusCategories["Active"] = "Active";
    StatusCategories["Misconfigured"] = "Misconfigured";
    StatusCategories["Inactive"] = "Inactive";
})(StatusCategories || (StatusCategories = {}));
var statusConfigs = {
    active: {
        name: 'Active',
        nameI18nKey: 'machine_healthStatus_active',
        category: StatusCategories.Active,
    },
    impairedComms: {
        name: 'Impaired communications',
        nameI18nKey: 'machine_healthStatus_impairedCommunications',
        description: 'Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.',
        category: StatusCategories.Misconfigured,
    },
    noData: {
        name: 'No sensor data',
        nameI18nKey: 'machine_healthStatus_noSensorData',
        description: 'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available.',
        category: StatusCategories.Misconfigured,
    },
    misconfigured: {
        name: 'No sensor data, impaired communications',
        nameI18nKey: 'machine_healthStatus_misconfigured',
        description: 'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available. Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.',
        category: StatusCategories.Misconfigured,
    },
    inactive: {
        name: 'Inactive',
        nameI18nKey: 'machine_healthStatus_inactive',
        description: 'Device has completely stopped sending signals for more than 7 days.',
        category: StatusCategories.Inactive,
    },
};
var ɵ0 = function (result, value, key) {
    result[key] = Object.assign({}, value, { id: key });
};
export var machineHealthStatuses = transform({
    MisconfiguredCyberNoCnc: __assign({}, statusConfigs.impairedComms, { childOf: StatusCategories.Misconfigured }),
    MisconfiguredCncNoCyber: __assign({}, statusConfigs.noData, { childOf: StatusCategories.Misconfigured }),
    MisconfiguredDefenderOnly: statusConfigs.misconfigured,
    MisconfiguredUtcAndDefenderOnly: statusConfigs.misconfigured,
    MisconfiguredUtcOnly: statusConfigs.misconfigured,
    InactiveDefault: statusConfigs.inactive,
    InactiveRecent: statusConfigs.inactive,
    InactiveGoneDark: statusConfigs.inactive,
    Active: statusConfigs.active,
    ActiveDefault: statusConfigs.active,
}, ɵ0, {});
export var machineHealthStatusValues = values(machineHealthStatuses);
var ɵ1 = function (status) { return status.id; };
export var machineHealthStatusMap = keyBy(machineHealthStatusValues, ɵ1);
export { ɵ0, ɵ1 };
