var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { VirusTotalFileReport } from '../file.virus-total-file-report.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.virusFileReport; }, ɵ1 = function (file) { return ({
    fileIdentifier: file.id,
}); };
var FileVirusTotalFileReportRelationship = /** @class */ (function () {
    function FileVirusTotalFileReportRelationship() {
    }
    FileVirusTotalFileReportRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: VirusTotalFileReport,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ0,
            endpoint: 'VirusTotalFileReport',
            getRelationshipData: ɵ1,
        })
    ], FileVirusTotalFileReportRelationship);
    return FileVirusTotalFileReportRelationship;
}());
export { FileVirusTotalFileReportRelationship };
export { ɵ0, ɵ1 };
