// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
var NetworkDeviceEntityPanelComponent = /** @class */ (function (_super) {
    __extends(NetworkDeviceEntityPanelComponent, _super);
    function NetworkDeviceEntityPanelComponent(changeDetectorRef, paris) {
        return _super.call(this, changeDetectorRef) || this;
    }
    Object.defineProperty(NetworkDeviceEntityPanelComponent.prototype, "networkDevice", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    NetworkDeviceEntityPanelComponent.prototype.initEntity = function (device, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, device, isExtendedData);
        this.changeDetectorRef.markForCheck();
    };
    return NetworkDeviceEntityPanelComponent;
}(EntityPanelComponentBase));
export { NetworkDeviceEntityPanelComponent };
