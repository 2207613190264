
		<modal (close)="destroy()" [settings]="settings">
			<confirm-modal-content
				class="wcd-flex-1 ie11Patch ie11Flex"
				[confirmButtonOptions]="{ disabled: isDisabled }"
				[isSaving]="isSaving"
				(onCancel)="onCancelHandler()"
				(onConfirm)="onConfirmHandler()"
			>
				<p id="isolate-content">
					{{ dialogContentText }}
				</p>

				<isolation-modal-warnings
					*ngIf="isNotManagedDevice"
					[deviceId]="device.id"
					[isHighValueAsset]="device.assetValue === MachineValue.High"
					[isNetworkDevice]="device.deviceCategory === DeviceCategory.NetworkDevice"
					(onReady)="enableNotManagedDeviceModal()"
				></isolation-modal-warnings>

				<!-- styles here is set in order to override Bootstrap's 'label { max-width: 100% }' rule -->
				<fab-checkbox
					#outlookAndSkypeCheckbox
					[styles]="{ label: 'bootstrap-fix-cut-label' }"
					*ngIf="showAllowOutlookAndSkypeCheckbox"
					[(checked)]="isOutlookAndSkypeAllowed"
					[label]="
						'machines.entityDetails.actions.isolateMachine.dialog.isolate.form.allowOutlookAndSkype.label'
							| i18n
					"
				></fab-checkbox>

				<ng-container *ngIf="isMultipleSelection">
					<label class="wcd-required wcd-margin-small-top" for="isolateBulkName"
						>{{
							i18nService.strings
								.machines_entityDetails_actions_$general_dialog_form_remediationName_label
						}}:</label
					>
					<input
						type="text"
						#focusable
						id="isolateBulkName"
						[(ngModel)]="bulkName"
						[placeholder]="
							i18nService.strings
								.machines_entityDetails_actions_$general_dialog_form_remediationName_placeholder
						"
						name="isolate-device-bulk-name"
						class="form-control wcd-margin-small-bottom"
						maxlength="300"
						required
					/>
				</ng-container>
				<div class="wcd-flex-vertical wcd-flex-center-all" *ngIf="isNotManagedDevice">
					<img [src]="'/assets/images/icons/unmanaged_device_isolation.svg' | basePath" [attr.alt]="
						'machines_entityDetails_actions_isolateNotManagedDevice_dialog_isolate_altText' | i18n
						"/>
				</div>
				<label class="wcd-required" for="isolateComment"
					>{{ 'machines.entityDetails.actions.$general.dialog.form.comment.label' | i18n }}:</label
				>
				<textarea
					required
					rows="4"
					id="isolateComment"
					[attr.placeholder]="
						'machines.entityDetails.actions.$general.dialog.form.comment.placeholder' | i18n
					"
					class="form-control dialog-textarea wcd-full-width wcd-margin-small-bottom wcd-no-resize-horizontal"
					aria-describedby="isolate-content"
					[(ngModel)]="commentText"
				></textarea>
				<fab-icon iconName="Info"></fab-icon>
				<span>{{ undoInfoText }}</span>
			</confirm-modal-content>
		</modal>
		