import { ChangeDetectorRef, OnDestroy, OnInit, SecurityContext, } from '@angular/core';
import { Subject } from 'rxjs';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetModel } from '../models/report-widget.model';
import { WicdSanitizerService } from '@wcd/shared';
var ReportWidgetHeaderComponent = /** @class */ (function () {
    function ReportWidgetHeaderComponent(_changeDetectionRef, serviceUrlsService, sanitizer) {
        this._changeDetectionRef = _changeDetectionRef;
        this.serviceUrlsService = serviceUrlsService;
        this.sanitizer = sanitizer;
        this.headerId = null;
        this.headerLevel = 3;
        this.dropdownMenuStyles = {
            root: { height: '100%', width: '100%' },
            menuIcon: { margin: 0 },
        };
    }
    Object.defineProperty(ReportWidgetHeaderComponent.prototype, "ApiBaseUrl", {
        get: function () {
            return this.serviceUrlsService.automatedIr;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportWidgetHeaderComponent.prototype, "paramsString", {
        get: function () {
            var _this = this;
            return this.params
                ? Object.keys(this.params)
                    .map(function (key) { return key + "=" + encodeURIComponent(_this.params[key]); })
                    .join('&')
                : '';
        },
        enumerable: true,
        configurable: true
    });
    ReportWidgetHeaderComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.name = this.widget.name;
        this.help = this.widget.help;
        this.rangeInDays = typeof this.widget.rangeInDays === 'number' && this.widget.rangeInDays;
        this.menuItems =
            this.widget.menu &&
                this.widget.menu.map(function (item) { return ({
                    key: item.key,
                    href: _this.getMenuItemHref(item),
                    onClick: item.onClick ? function () { return item.onClick(); } : null,
                    target: item.target || '_self',
                    text: item.text,
                    iconProps: item.icon && {
                        iconName: item.icon,
                    },
                }); });
        if (this.widget.getName ||
            this.widget.extraHeaderHtml ||
            this.widget.getDateOfWidget ||
            this.widget.getDateRangeOfWidget) {
            this._dataSubscription = this.data$.subscribe(function (data) {
                if (_this.widget.getName) {
                    _this.name = _this.widget.getName(data, _this.params);
                }
                if (typeof _this.widget.rangeInDays === 'function') {
                    _this.rangeInDays = _this.widget.rangeInDays(data, _this.params);
                }
                if (_this.widget.extraHeaderHtml) {
                    _this.extraHtml = _this.sanitizer.sanitize(SecurityContext.HTML, _this.widget.extraHeaderHtml(data, _this.params));
                }
                if (_this.widget.getDateOfWidget) {
                    var currentDate = _this.widget.getDateOfWidget(data);
                    _this.dateOfWidget = currentDate && currentDate.toDateString();
                }
                if (_this.widget.getDateRangeOfWidget) {
                    var dateRange = _this.widget.getDateRangeOfWidget(data);
                    _this.dateRangeOfWidget = dateRange && _this.getDateRangeString(dateRange[0], dateRange[1]);
                }
                _this._changeDetectionRef.markForCheck();
            });
        }
    };
    ReportWidgetHeaderComponent.prototype.ngOnDestroy = function () {
        if (this._dataSubscription) {
            this._dataSubscription.unsubscribe();
        }
    };
    ReportWidgetHeaderComponent.prototype.getMenuItemHref = function (menuItem) {
        if (menuItem.getUrl) {
            return this.ApiBaseUrl + "/" + menuItem.getUrl(this.paramsString);
        }
        if (menuItem.url) {
            return menuItem.url;
        }
        return null;
    };
    ReportWidgetHeaderComponent.prototype.getDateRangeString = function (from, to) {
        return from.toDateString() + " - " + to.toDateString();
    };
    return ReportWidgetHeaderComponent;
}());
export { ReportWidgetHeaderComponent };
