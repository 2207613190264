var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { map } from 'lodash-es';
var rawToValueMap = {
    2147942722: 'DeviceHasNoResources',
    2147942623: 'FileTooLarge',
    2147943862: 'IncorrectSize',
    2149122552: 'GatewayTimeout',
    2149122456: 'RequestTimeout',
    2149122551: 'ServiceUnavailable',
    32802: 'RebootRequired',
    32803: 'FileSignedByTrustedPublisher',
    '-2146992093': 'FileSignedByTrustedPublisher',
    2: 'fileNotFoundItMayHaveBeenDeletedOrMoved',
    '-2147024894': 'fileNotFoundItMayHaveBeenDeletedOrMoved',
    15301: 'FileHasBeenModified',
    '-2147009595': 'FileHasBeenModified',
    '-2147023890': 'FileEmptyOrInvalid',
    1006: 'FileEmptyOrInvalid',
    '-2145844840': 'MachineDidNotGetCommand',
    408: 'MachineDidNotGetCommand',
    '-2147024891': 'AccessDeniedFileInUseOrReadOnly',
    5: 'AccessDeniedFileInUseOrReadOnly',
    '-2147020579': 'AntivirusScanAlreadyInProgress',
    4317: 'AntivirusScanAlreadyInProgress',
    '-2147416390': 'WindowsDefenderAntivirusDisabled',
    0: '',
};
var ɵ0 = function (value, key) {
    var rawValue = Number(key);
    return {
        id: rawValue,
        rawValue: rawValue,
        value: value,
    };
};
var values = map(rawToValueMap, ɵ0);
var MachineRequestErrorCode = /** @class */ (function (_super) {
    __extends(MachineRequestErrorCode, _super);
    function MachineRequestErrorCode() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], MachineRequestErrorCode.prototype, "rawValue", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineRequestErrorCode.prototype, "value", void 0);
    MachineRequestErrorCode = __decorate([
        Entity({
            singularName: 'Device request error code',
            pluralName: 'Device request error codes',
            values: values,
            readonly: true,
        })
    ], MachineRequestErrorCode);
    return MachineRequestErrorCode;
}(EntityModelBase));
export { MachineRequestErrorCode };
export { ɵ0 };
