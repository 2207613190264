import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'User state',
	pluralName: 'User states',
	endpoint: 'outbreaks/userState',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatAnalytics,
})
export class OutbreakUserState extends EntityModelBase<string> {
	@EntityField({ data: 'OutbreakId' })
	outbreakId: string;

	@EntityField({ data: 'LastVisitTime' })
	lastVisitTime?: Date;

	@EntityField({ data: 'IsFavorite' })
	isFavorite?: boolean;
}
