
		<ng-container *ngIf="urls?.length; else noUrls">
			<a
				(click)="showMailMessageUrls()"
				(keydown.enter)="showMailMessageUrls()"
				data-track-id="ShowEmailUrlsPanel"
				data-track-type="Button"
				class="btn-inline btn-link no-padding btn-link-high-contrast"
				tabindex="0"
				role="link"
			>
				{{ 'airsEntities_emailMessage_fields_urls_and_threats_seeAll' | i18n: { count: urls.length } }}
			</a>
		</ng-container>
		<ng-template #noUrls>
			{{ i18nService.strings.airsEntities_emailMessage_fields_urls_and_threats_noUrlsAndThreatsFound }}
		</ng-template>
	