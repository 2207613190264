import { Injector } from '@angular/core';
import { UpgradeModule } from '@angular/upgrade/static';
import { initializeIcons, loadTheme } from 'office-ui-fabric-react';
import { PreloadService } from './preloader/services/preload.service';
import { SevilleUpgradeModule } from './_seville/seville-upgrade.module';
import { registerLocales } from './app.locales';
import { m365Theme } from './shared/models/colors/m365-colors-palette.model';
import { setUpLocationSync } from '@angular/router/upgrade';
import { Paris } from '@microsoft/paris';
import { APC_HEADER_KEY, AuthService } from '@wcd/auth';
import { ServiceUrlsService } from '@wcd/app-config';
import { getPortalLanguage } from '@wcd/i18n';
export var appInit = function (preloadService, injector) { return function () {
    return preloadService.preload$.toPromise();
}; };
registerLocales();
var ɵ0 = function (auth, serviceUrlsService) {
    var _a;
    var headers = (_a = {
            get authorization() {
                return "Bearer " + auth.token;
            },
            get 'Tenant-Id'() {
                return auth.tenantId;
            }
        },
        Object.defineProperty(_a, APC_HEADER_KEY, {
            get: function () {
                return auth.apcHeader;
            },
            enumerable: true,
            configurable: true
        }),
        Object.defineProperty(_a, 'Accept-Language', {
            get: function () {
                return getPortalLanguage();
            },
            enumerable: true,
            configurable: true
        }),
        _a);
    return new Paris({
        apiRoot: '/api',
        data: {
            serviceUrls: serviceUrlsService,
        },
        http: {
            headers: headers,
            withCredentials: true,
        },
    });
};
var AppModule = /** @class */ (function () {
    function AppModule(upgrade) {
        this.upgrade = upgrade;
        initializeIcons('../assets/fonts/fabric-icons/');
        //@ts-ignore
        loadTheme(m365Theme);
    }
    AppModule.prototype.ngDoBootstrap = function () {
        this.upgrade.bootstrap(document.body, [SevilleUpgradeModule.name], {
            strictDi: true,
        });
        setUpLocationSync(this.upgrade);
    };
    return AppModule;
}());
export { AppModule };
export { ɵ0 };
