/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./network-device-icon.component.ngfactory";
import * as i2 from "./network-device-icon.component";
import * as i3 from "./network-device-field.component";
var styles_NetworkDeviceFieldComponent = [];
var RenderType_NetworkDeviceFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NetworkDeviceFieldComponent, data: {} });
export { RenderType_NetworkDeviceFieldComponent as RenderType_NetworkDeviceFieldComponent };
export function View_NetworkDeviceFieldComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "network-device-icon", [["class", "wcd-margin-xsmall-right"]], null, null, null, i1.View_NetworkDeviceIconComponent_0, i1.RenderType_NetworkDeviceIconComponent)), i0.ɵdid(2, 49152, null, 0, i2.NetworkDeviceIconComponent, [], null, null), (_l()(), i0.ɵted(3, null, ["", "\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); }); }
export function View_NetworkDeviceFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "network-device-field", [], null, null, null, View_NetworkDeviceFieldComponent_0, RenderType_NetworkDeviceFieldComponent)), i0.ɵdid(1, 49152, null, 0, i3.NetworkDeviceFieldComponent, [], null, null)], null, null); }
var NetworkDeviceFieldComponentNgFactory = i0.ɵccf("network-device-field", i3.NetworkDeviceFieldComponent, View_NetworkDeviceFieldComponent_Host_0, { title: "title" }, {}, []);
export { NetworkDeviceFieldComponentNgFactory as NetworkDeviceFieldComponentNgFactory };
