import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
import { LogonType } from '../user/logon-type.enum';

@ValueObject({
	singularName: 'Device User Details Account',
	pluralName: 'Device User Details Account',
	readonly: true,
})
export class MachineUserDetailsAccount extends ModelBase {
	@EntityField({ data: 'AccountName' })
	name: string;

	@EntityField({ data: 'AccountDomainName' })
	domain: string;

	@EntityField({ defaultValue: null, data: 'IsLocalAdmin' })
	isLocalAdmin: boolean;

	// NOTE: Model the user from the self data (AccountName + AccountDomainName)
	@EntityField({ data: '__self' })
	user: LegacyUser;

	@EntityField({ data: 'FirstSeen' })
	firstSeen: Date;

	@EntityField({ data: 'LastSeen' })
	lastSeen: Date;

	@EntityField({ data: 'LoginCount' })
	loginCount: number;

	@EntityField({
		data: 'LogonTypes',
		parse: (data: string) => (data ? data.split(',').map(logonType => logonType.trim()) : null),
	})
	logonTypes: Array<LogonType>;
}
