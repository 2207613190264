export const enum RegistryValueEntityAlertV3Fields {
	Name = 'Name',
	Value = 'Value',
	ValueType = 'ValueType',
	Key = 'Key',
}

export function mapRegistryValueEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case RegistryValueEntityAlertV3Fields.Name:
					acc['name'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case RegistryValueEntityAlertV3Fields.Value:
					acc['value_data'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case RegistryValueEntityAlertV3Fields.ValueType:
					acc['value_type'] = val;
					break;
				case RegistryValueEntityAlertV3Fields.Key:
					acc['key'] = val['Key'];
					acc['hive'] = val['Hive'];
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
