import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FileEntityTypeService } from './file.entity-type.service';
import { FileInstance } from '@wcd/domain';

@Injectable()
export class FileInstanceEntityTypeService implements EntityTypeService<FileInstance> {
	constructor(private readonly filesService: FileEntityTypeService) {}

	readonly entityType: Readonly<EntityType<FileInstance>> = {
		...this.filesService.entityType,
		id: 'fileInstance',
		entity: FileInstance,
		searchOptions: null,
	};
}
