import { AirsEntityStatus, AirsEntityType, AirsEntityTypeValue, } from '@wcd/domain';
import { MachineNameComponent } from '../../../shared/components/machine-name.component';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { DataviewField } from '@wcd/dataview';
import { Paris } from '@microsoft/paris';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { EvidenceDetectionContextComponent } from '../../evidence/components/evidence-detection-context.component';
import { WcdIconNames } from '@wcd/icons-font';
import { ThreatsDisplayNameService } from '../../mtp_investigations/services/threats-display-name-field.service';
var AirsEntitiesFieldsService = /** @class */ (function () {
    function AirsEntitiesFieldsService(paris, featuresService, i18nService, threatsDisplayNameService) {
        var _this = this;
        var _a;
        this.paris = paris;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.threatsDisplayNameService = threatsDisplayNameService;
        /**
         * @deprecated
         */
        this.entityTypeCommonDisplayFieldsOld = DataviewField.fromList([
            {
                id: 'entity_status',
                name: this.i18nService.strings.airsEntities_fields_verdict,
                getDisplay: function (item) { return (item.status ? item.status.name : ''); },
                getCssClass: function (item) {
                    return "wcd-padding-small-left color-border-" + ((item.status && item.status.className) || 'neutralQuaternaryAlt');
                },
                filter: {
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (entityStatusId) {
                                var entityStatus = _this.paris.getValue(AirsEntityStatus, parseInt(entityStatusId, 10));
                                return {
                                    id: entityStatusId,
                                    name: entityStatus.name,
                                    nameClass: "wcd-padding-small-left color-border-" + (entityStatus.className || 'gray-200'),
                                };
                            },
                        },
                    },
                },
                custom: {
                    allowFilterValueTracking: true,
                },
                sort: {
                    enabled: true,
                    sortDescendingByDefault: false,
                },
            },
        ]);
        this.entityTypeCommonDisplayFieldsNew = DataviewField.fromList([
            {
                id: 'verdict',
                name: this.i18nService.strings.airsEntities_fields_verdict,
                getDisplay: function (item) { return item.verdict && item.verdict.name; },
                getCssClass: function (item) {
                    return item.verdict
                        ? "wcd-padding-small-left color-border-" + (item.verdict.className || 'neutralQuaternaryAlt')
                        : null;
                },
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'remediation_status',
                name: this.i18nService.strings.airsEntities_fields_remediationStatus,
                getDisplay: function (item) { return item.remediationStatus && item.remediationStatus.name; },
                getCssClass: function (item) {
                    return item.remediationStatus
                        ? "wcd-padding-small-left color-border-" + (item.remediationStatus.className || 'neutralQuaternaryAlt')
                        : null;
                },
                sort: {
                    enabled: false,
                },
            },
        ]);
        this.entityFirstSeen = new DataviewField({
            id: 'first_seen',
            name: this.i18nService.strings.airsEntities_fields_firstSeen,
            enabledByDefault: false,
            component: {
                type: TzDateComponent,
                getProps: function (item) { return ({ date: item.firstSeen }); },
            },
            sort: { enabled: true },
        });
        this.entityDetectionContext = new DataviewField({
            id: 'detection_context',
            name: this.i18nService.strings.airsEntities_fields_detectionContext,
            enabledByDefault: false,
            component: {
                type: EvidenceDetectionContextComponent,
                getProps: function (item) { return ({
                    detectionContext: item.detectionContext,
                }); },
            },
            sort: { enabled: true },
        });
        this.investigationField = new DataviewField({
            id: 'investigation',
            name: this.i18nService.strings.airsEntities_fields_investigation,
            getDisplay: function (item) {
                return item.investigation ? item.investigation.title + " (" + item.investigation.id + ")" : '';
            },
            getLink: function (item) { return ['/investigation', String(item.investigation.id)]; },
            icon: {
                wcdIcon: WcdIconNames.investigation,
            },
            sort: { enabled: true },
        });
        this.addressField = new DataviewField({
            id: 'address',
            name: this.i18nService.strings.airsEntities_url_fields_address,
            sort: { enabled: true },
            enabledByDefault: true,
            getDisplay: function (entity) { return entity.address; },
        });
        this.hostField = new DataviewField({
            id: 'host',
            name: this.i18nService.strings.machine,
            enabledByDefault: true,
            component: {
                type: MachineNameComponent,
                getProps: function (entity) { return ({
                    machine: entity.machine,
                    showIcon: true,
                    showLink: true,
                    iconCssClass: 'small-icon',
                }); },
            },
            sort: { enabled: true },
        });
        this.statusDetailsField = new DataviewField({
            id: 'status_details',
            name: this.i18nService.strings.evidence_fields_statusDetails,
            sort: { enabled: false },
            enabledByDefault: false,
            getDisplay: function (entity) { return entity.statusDetails; },
        });
        this.fieldsByType = Object.entries((_a = {},
            _a[AirsEntityTypeValue.File] = [this.statusDetailsField]
                .concat(DataviewField.fromList([
                {
                    id: 'path',
                    name: this.i18nService.strings.airsEntities_file_fields_filePath,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.path; },
                },
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_file_fields_fileName,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
            ]))
                .concat([this.hostField]),
            _a[AirsEntityTypeValue.Process] = [this.statusDetailsField]
                .concat(DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_process_fields_processName,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
                {
                    id: 'process_id',
                    name: this.i18nService.strings.airsEntities_process_fields_processId,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.processId; },
                },
            ]))
                .concat([this.hostField])
                .concat(DataviewField.fromList([
                {
                    id: 'created',
                    name: this.i18nService.strings.airsEntities_process_fields_startTime,
                    sort: { enabled: true },
                    enabledByDefault: false,
                    component: {
                        type: TzDateComponent,
                        getProps: function (entity) { return ({ date: entity.created }); },
                    },
                },
            ])),
            _a[AirsEntityTypeValue.Driver] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_general_fields_name,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
                {
                    id: 'display_name',
                    name: this.i18nService.strings.airsEntities_driver_fields_displayName,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.displayName; },
                },
                {
                    id: 'bin_path',
                    name: this.i18nService.strings.airsEntities_driver_fields_driverPath,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.binPath; },
                },
            ]).concat([this.hostField]),
            _a[AirsEntityTypeValue.Service] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_service_fields_serviceName,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
                {
                    id: 'bin_path',
                    name: this.i18nService.strings.airsEntities_service_fields_servicePath,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.binPath; },
                },
            ]).concat([this.hostField]),
            _a[AirsEntityTypeValue.URL] = [this.addressField],
            _a[AirsEntityTypeValue.IP] = DataviewField.fromList([
                {
                    id: 'address',
                    name: this.i18nService.strings.airsEntities_ip_fields_address,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.address; },
                },
            ]),
            _a[AirsEntityTypeValue.AppendedFile] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_file_fields_fileName,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
                {
                    id: 'sha1',
                    name: this.i18nService.strings.airsEntities_file_fields_hashes_sha1,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.sha1; },
                },
            ]),
            _a[AirsEntityTypeValue.EmailUrl] = [this.addressField].concat(DataviewField.fromList([
                {
                    id: 'subject',
                    name: this.i18nService.strings.airsEntities_url_fields_emailSubject,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.subject; },
                },
            ])),
            _a[AirsEntityTypeValue.User] = DataviewField.fromList([
                {
                    id: 'username',
                    name: this.i18nService.strings.airsEntities_user_fields_username,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.username; },
                },
                {
                    id: 'email',
                    name: this.i18nService.strings.airsEntities_user_fields_emailAddress,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.email; },
                },
            ]).concat([this.statusDetailsField]),
            _a[AirsEntityTypeValue.WebPage] = [this.addressField],
            _a[AirsEntityTypeValue.PersistenceMethod] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_general_fields_name,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                },
                {
                    id: 'method_type',
                    name: this.i18nService.strings.airsEntities_persistenceMethod_fields_methodType,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.methodType; },
                },
                {
                    id: 'category',
                    name: this.i18nService.strings.airsEntities_persistenceMethod_fields_category,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.category; },
                },
                {
                    id: 'command_line',
                    name: this.i18nService.strings.airsEntities_persistenceMethod_fields_commandLine,
                    sort: { enabled: true },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.commandLine; },
                },
            ]).concat([this.hostField]),
            _a[AirsEntityTypeValue.UserActivity] = DataviewField.fromList([
                {
                    id: 'upn',
                    name: this.i18nService.strings.airsEntities_userActivity_fields_username,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.upn; },
                },
                {
                    id: 'full_name',
                    name: this.i18nService.strings.airsEntities_general_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.fullName; },
                },
                {
                    id: 'user_object_id',
                    name: this.i18nService.strings.airsEntities_userActivity_fields_aadId,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.userObjectId; },
                },
            ]),
            _a[AirsEntityTypeValue.MailCluster] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_emailCluster_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.entityName; },
                    className: 'nowrap wcd-text-overflow-medium',
                    getTooltip: function (entity) { return entity.entityName; },
                },
                {
                    id: 'threats',
                    name: this.i18nService.strings.airsEntities_general_fields_threats,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return _this.threatsDisplayNameService.getThreatsDisplayName(entity.threats); },
                },
                {
                    id: 'mail_count',
                    name: this.i18nService.strings.airsEntities_emailCluster_fields_emailCount,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.mailCount; },
                },
                {
                    id: 'malware_count',
                    name: this.i18nService.strings.remediation_fields_malware,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterThreats && entity.emailClusterThreats.malwareCount) || 0;
                    },
                },
                {
                    id: 'phish_count',
                    name: this.i18nService.strings.remediation_fields_phish,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterThreats && entity.emailClusterThreats.phishCount) || 0;
                    },
                },
                {
                    id: 'high_confidence_phish_count',
                    name: this.i18nService.strings.remediation_fields_highConfidencePhish,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterThreats && entity.emailClusterThreats.highConfidencePhishCount) || 0;
                    },
                },
                {
                    id: 'spam_count',
                    name: this.i18nService.strings.remediation_fields_spam,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterThreats && entity.emailClusterThreats.spamCount) || 0;
                    },
                },
                {
                    id: 'delivered_count',
                    name: this.i18nService.strings.remediation_fields_delivered,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterOriginalDeliveryLocations &&
                            entity.emailClusterOriginalDeliveryLocations.deliveredCount) ||
                            0;
                    },
                },
                {
                    id: 'junked_count',
                    name: this.i18nService.strings.remediation_fields_junked,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterOriginalDeliveryLocations &&
                            entity.emailClusterOriginalDeliveryLocations.junkedCount) ||
                            0;
                    },
                },
                {
                    id: 'replaced_count',
                    name: this.i18nService.strings.remediation_fields_replaced,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterOriginalDeliveryLocations &&
                            entity.emailClusterOriginalDeliveryLocations.replacedCount) ||
                            0;
                    },
                },
                {
                    id: 'blocked_count',
                    name: this.i18nService.strings.remediation_fields_blocked,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterOriginalDeliveryLocations &&
                            entity.emailClusterOriginalDeliveryLocations.blockedCount) ||
                            0;
                    },
                },
                {
                    id: 'mailbox_count',
                    name: this.i18nService.strings.remediation_fields_mailbox,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterDeliveryLocations &&
                            entity.emailClusterDeliveryLocations.mailboxCount) ||
                            0;
                    },
                },
                {
                    id: 'not_in_mailbox_count',
                    name: this.i18nService.strings.remediation_fields_notInMailbox,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterDeliveryLocations &&
                            entity.emailClusterDeliveryLocations.notInMailboxCount) ||
                            0;
                    },
                },
                {
                    id: 'on_prem_or_external_count',
                    name: this.i18nService.strings.remediation_fields_onPremOrExternal,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return (entity.emailClusterDeliveryLocations &&
                            entity.emailClusterDeliveryLocations.onPremOrExternalCount) ||
                            0;
                    },
                },
                {
                    id: 'volume_anomaly',
                    name: this.i18nService.strings.airsEntities_emailCluster_fields_volumeAnomaly,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.volumeAnomaly; },
                },
            ]),
            _a[AirsEntityTypeValue.Mailbox] = DataviewField.fromList([
                {
                    id: 'display_name',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_displayName,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.displayName; },
                },
                {
                    id: 'primary_mail_address',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_primaryEmailAddress,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.primaryMailAddress; },
                },
                {
                    id: 'upn',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_upn,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.upn; },
                },
                {
                    id: 'fw_rule_name',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleName,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.fwRuleName; },
                },
                {
                    id: 'fw_rule_creation_time',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreationTime,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    component: {
                        type: TzDateComponent,
                        getProps: function (entity) { return ({ date: entity.fwRuleCreationTime }); },
                    },
                },
                {
                    id: 'fw_rule_creator_alias',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleCreatorAlias,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.fwRuleCreatorAlias; },
                },
                {
                    id: 'fw_rule_forwarding_SMTP_address',
                    name: this.i18nService.strings.airsEntities_mailbox_fields_forwardingRuleSmtpAddress,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.fwRuleForwardingSMTPAddress; },
                },
            ]),
            _a[AirsEntityTypeValue.MailMessage] = DataviewField.fromList([
                {
                    id: 'received_date',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_emailReceivedDate,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    component: { type: TzDateComponent, getProps: function (entity) { return ({ date: entity.receivedDate }); } },
                },
                {
                    id: 'delivery_status',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_deliveryStatus,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.deliveryStatus; },
                },
                {
                    id: 'subject',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_subject,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.subject; },
                    className: 'nowrap wcd-text-overflow-medium',
                    getTooltip: function (entity) { return entity.subject; },
                },
                {
                    id: 'sender',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_sender,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.sender; },
                },
                {
                    id: 'recipient',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_recipient,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.recipient; },
                },
            ]),
            _a[AirsEntityTypeValue.SubmissionMail] = DataviewField.fromList([
                {
                    id: 'subject',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_subject,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.subject; },
                },
                {
                    id: 'sender',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_sender,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.sender; },
                },
                {
                    id: 'recipient',
                    name: this.i18nService.strings.airsEntities_emailMessage_fields_recipient,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.recipient; },
                },
                {
                    id: 'submitter',
                    name: this.i18nService.strings.airsEntities_emailSubmission_fields_reportedBy,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.submitter; },
                },
                {
                    id: 'reportType',
                    name: this.i18nService.strings.airsEntities_emailSubmission_fields_reportType,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return _this.i18nService.get("airsEntities_emailSubmission_fields_reportType_" + entity.reportTypeI18nKey, null, true) || entity.reportTypeI18nKey;
                    },
                },
            ]),
            _a[AirsEntityTypeValue.MailboxConfiguration] = DataviewField.fromList([
                {
                    id: 'config_type',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_configurationAction,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) {
                        return _this.i18nService.get("airsEntities_mailConfiguration_confType_" + entity.configType, null, true) || entity.configType;
                    },
                },
                {
                    id: 'performed_by',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_performedBy,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.performedBy; },
                },
                {
                    id: 'mailbox_primary_address',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_mailbox,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.mailboxPrimaryAddress; },
                },
                {
                    id: 'upn',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_upn,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.upn; },
                },
                {
                    id: 'forwardingSmtpAddress',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_forwardingSmtpAddress,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.forwardingSmtpAddress; },
                },
                {
                    id: 'actionTime',
                    name: this.i18nService.strings.airsEntities_mailboxConfiguration_fields_actionTime,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.timestamp; },
                },
            ]),
            _a[AirsEntityTypeValue.SecurityGroup] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_securityGroup_fields_displayName,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.name; },
                },
            ]),
            _a[AirsEntityTypeValue.RegistryKey] = DataviewField.fromList([
                {
                    id: 'key',
                    name: this.i18nService.strings.airsEntities_registryKey_fields_key,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.keyData; },
                },
                {
                    id: 'hive',
                    name: this.i18nService.strings.airsEntities_registryKey_fields_hive,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.hive; },
                },
            ]),
            _a[AirsEntityTypeValue.RegistryValue] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_registryValue_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.name; },
                },
                {
                    id: 'value_type',
                    name: this.i18nService.strings.airsEntities_registryValue_fields_type,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.valueType; },
                },
                {
                    id: 'value_data',
                    name: this.i18nService.strings.airsEntities_registryValue_fields_data,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.valueData; },
                },
                {
                    id: 'key',
                    name: this.i18nService.strings.airsEntities_registryKey_fields_key,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.keyData; },
                },
                {
                    id: 'hive',
                    name: this.i18nService.strings.airsEntities_registryKey_fields_hive,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.hive; },
                },
            ]),
            _a[AirsEntityTypeValue.CloudApplication] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_cloudApplication_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.name; },
                },
                {
                    id: 'app_id',
                    name: this.i18nService.strings.airsEntities_cloudApplication_fields_appId,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.appId; },
                },
                {
                    id: 'risk',
                    name: this.i18nService.strings.airsEntities_cloudApplication_fields_risk,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.risk; },
                },
            ]),
            _a[AirsEntityTypeValue.CloudLogonSession] = DataviewField.fromList([
                {
                    id: 'username',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_username,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.account && entity.account.username; },
                },
                {
                    id: 'nt_domain',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_domain,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.account && entity.account.ntDomain; },
                },
                {
                    id: 'session_id',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_sessionId,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.sessionId; },
                },
                {
                    id: 'sid',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_userSid,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.account && entity.account.sid; },
                },
                {
                    id: 'display_name',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_displayName,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.account && entity.account.userDisplayName; },
                },
                {
                    id: 'user_agent',
                    name: this.i18nService.strings.airsEntities_cloudLogonSession_fields_userAgent,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.userAgent; },
                },
            ]),
            _a[AirsEntityTypeValue.OauthApplication] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_oauthApplication_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.name; },
                },
                {
                    id: 'oauth_app_id',
                    name: this.i18nService.strings.airsEntities_oauthApplication_fields_oauthAppId,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.oAuthAppId; },
                },
                {
                    id: 'oauth_object_id',
                    name: this.i18nService.strings.airsEntities_oauthApplication_fields_oauthObjectId,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.oAuthObjectId; },
                },
                {
                    id: 'risk',
                    name: this.i18nService.strings.airsEntities_oauthApplication_fields_risk,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.risk; },
                },
            ]),
            _a[AirsEntityTypeValue.ActiveDirectoryDomain] = DataviewField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.strings.airsEntities_activeDirectoryDomain_fields_name,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.name; },
                },
                {
                    id: 'id',
                    name: this.i18nService.strings.airsEntities_activeDirectoryDomain_fields_id,
                    sort: { enabled: false },
                    enabledByDefault: true,
                    getDisplay: function (entity) { return entity.id; },
                },
            ]),
            _a)).reduce(function (acc, _a) {
            var k = _a[0], v = _a[1];
            acc[AirsEntityType[k]] = v;
            return acc;
        }, {});
    }
    AirsEntitiesFieldsService.prototype.getEntityTypeDisplayFields = function (entityType, allowInvestigationField, useNewStatus) {
        if (allowInvestigationField === void 0) { allowInvestigationField = true; }
        if (useNewStatus === void 0) { useNewStatus = false; }
        var fieldsByTypeElement = this.fieldsByType[entityType.id];
        return [].concat(this.featuresService.isEnabled(Feature.EntityDetectionContext)
            ? [this.entityFirstSeen]
            : [], useNewStatus ? this.entityTypeCommonDisplayFieldsNew : this.entityTypeCommonDisplayFieldsOld, fieldsByTypeElement, allowInvestigationField ? [this.investigationField] : [], this.featuresService.isEnabled(Feature.EntityDetectionContext)
            ? [this.entityDetectionContext]
            : []);
    };
    return AirsEntitiesFieldsService;
}());
export { AirsEntitiesFieldsService };
