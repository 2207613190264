import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
} from '@angular/core';

export interface MessageBarAction {
	id: string;
	icon?: string;
	name: string;
	method: () => Promise<any>;
	tooltip?: string;
	buttonClass?: string;
}

export interface MessageBarActionExecutionDetails {
	action: MessageBarAction;
	error?: any;
}

@Component({
	selector: 'message-bar',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div
			class="message-bar wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal "
			[ngClass]="className"
			data-track-component="Top Message Bar"
			data-track-component-type="Message Bar"
		>
			<wcd-shared-icon [iconName]="iconName" class="message-bar-icon"> </wcd-shared-icon>
			<div [attr.role]="messageRole" class="message-bar-text wcd-flex-1 message-bar-text-size" [wcdTooltip]="tooltipKey | i18n">
				<markdown [data]="messageKey | i18n: { data: messageData }"></markdown>
			</div>
			<div *ngIf="actions?.length" class="wcd-flex-none">
				<ng-container *ngFor="let action of actions">
					<button
						class="message-bar-item btn"
						[attr.data-track-id]="'MessageBar-' + action.id"
						data-track-type="Button"
						type="button"
						[wcdTooltip]="action.tooltip"
						[ngClass]="action.buttonClass || 'btn-primary'"
						[disabled]="isRunning(action)"
						(click)="runAction(action)"
					>
						<i class="loader-icon" [hidden]="!isRunning(action)"></i>
						<wcd-shared-icon
							*ngIf="action.icon && !isRunning(action)"
							[iconName]="action.icon"
							class="control-icon"
						>
						</wcd-shared-icon>
						<span class="command-bar-item-button-label">
							{{ action.name }}
						</span>
					</button>
				</ng-container>
			</div>
		</div>
	`,
	styleUrls: ['./message-bar.component.scss'],
})
export class MessageBarComponent {
	@Input() messageKey = 'polling.warning'; // message text key on i18 json. default is polling error.
	@Input() messageData = 'content'; // additional params for message text
	@Input() iconName = 'warning';
	@Input() className = 'color-box-warning-severe';
	@Input() tooltipKey?: string;
	@Input() actions: Array<MessageBarAction>;
	@Input() messageRole: string = 'none';

	@Output() actionFailed: EventEmitter<MessageBarActionExecutionDetails> = new EventEmitter<
		MessageBarActionExecutionDetails
	>();

	private _runningActions: Set<string> = new Set<string>();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	runAction(action: MessageBarAction) {
		this.addAction(action);

		try {
			action.method().then(
				() => {
					this.deleteAction(action);
				},
				error => {
					this.actionFailed.emit({ ...action, ...error });
					this.deleteAction(action);
				}
			);
		} catch (e) {
			this.actionFailed.emit({ ...action, ...e });
			this.deleteAction(action);
		}
	}

	isRunning(action): boolean {
		return this._runningActions.has(action.id);
	}

	private addAction(action: MessageBarAction) {
		this._runningActions.add(action.id);
		this.changeDetectorRef.markForCheck();
	}

	private deleteAction(action: MessageBarAction) {
		this._runningActions.delete(action.id);
		this.changeDetectorRef.markForCheck();
	}
}
