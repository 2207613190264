import { AlertV3ActionTypeMap } from './alertV3-action-type-types';
import { remediationActionTypeValues } from '../remediation/remediation-action-type.values';
import { InvestigationActionStatusId } from '../investigation/actions/investigation-action-status.values';
import { airsEntityTypeConfigs } from '../airs_entity/airs-entity-type-config.values';
import { AlertV3EntityTypes } from '../airs_entity/alertV3/alertV3-entity-types.enum';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';

enum ActionFields {
	ActionType = 'ActionType',
	ActionStatus = 'ActionStatus',
	ActionId = 'ActionId',
	EntityType = 'EntityType',
	RelatedEntities = 'RelatedEntities',
	ComputerName = 'ComputerName',
	MachineId = 'MachineId',
	Comment = 'Comment',
}

export function getEntityTypeIdByName(typeName): number {
	const entityType = airsEntityTypeConfigs.find(
		e => e.alertV3Name && e.alertV3Name === AlertV3EntityTypes[typeName]
	);

	const entityTypeId = entityType ? entityType.id : AirsEntityType[typeName];
	return <number>entityTypeId || null;
}

export function mapActionFromApiV2ToActionHistory(actionData): any {
	const mappedAction = Object.entries(actionData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case ActionFields.ActionType:
					const alertV3ActionType = AlertV3ActionTypeMap[<string>val];
					const actionType = remediationActionTypeValues.find(s => s.type === alertV3ActionType);
					acc['ActionType'] = actionType && actionType.id;
					break;
				case ActionFields.ActionStatus:
					acc['ActionStatus'] = InvestigationActionStatusId[<string>val];
					break;
				case ActionFields.ActionId:
					const intActionId = parseInt(<string>val, 10);
					acc['ActionId'] = isNaN(intActionId) ? val : intActionId;
					break;
				case ActionFields.EntityType:
					acc['EntityType'] = getEntityTypeIdByName(<string>val);
					break;
				case ActionFields.RelatedEntities:
					const updatedRelatedEntities =
						val &&
						(<Array<{ [key: string]: any }>>val).reduce((res, relatedEntity) => {
							res.push({
								...relatedEntity,
								EntityType: getEntityTypeIdByName(relatedEntity.EntityType),
							});
							return res;
						}, []);
					acc['RelatedEntities'] = updatedRelatedEntities;
					break;
				case ActionFields.ComputerName:
					acc['ComputerName'] = val;
					break;
				case ActionFields.MachineId:
					acc['MachineId'] = val;
					break;
				case ActionFields.Comment:
					// relevant only for action history entity
					acc['OriginalActionComment'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});

	const machineId = mappedAction['MachineId'];
	const computerName = mappedAction['ComputerName'] || '';

	if (machineId) {
		return Object.assign(mappedAction, {
			Machine: { MachineId: machineId, SenseMachineId: machineId, ComputerName: computerName },
		});
	}

	// Do not return action with unrecognized action type
	return mappedAction['ActionType'] ? mappedAction : null;
}
