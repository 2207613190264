import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { TvmModule } from '../../../tvm/tvm.module';
import { I18nModule } from '@wcd/i18n';
import { AssetFieldsService } from './services/asset.fields.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [],
	exports: [],
	entryComponents: [],
	providers: [I18nModule, AssetFieldsService],
})
export class AssetEntityTypeModule {}
