import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { File } from './file.entity';

@ApiCall({
	name: 'Cancel file remediation (quarantine)',
	endpoint: 'remediation/cancel',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({ file, comment }: FileRemediationCancelInputData) => ({
		data: {
			FileIdentifierType: 'Sha1',
			FileIdentifier: file.sha1,
			RequestorComment: comment,
		},
	}),
})
export class FileRemediationCancel extends ApiCallModel<any, FileRemediationCancelInputData> {}

export interface FileRemediationCancelInputData {
	file: File;
	comment: string;
}
