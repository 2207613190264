
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'scheduledTasks.fields.taskName.title' | i18n }}</dt>
			<dd>
				<copyable-input
					[ariaLabel]="'scheduledTasks.fields.taskName.title' | i18n"
					[value]="scheduledTask.taskName"
					showFullValue="true"
					[readonly]="true"
				></copyable-input>
			</dd>
		</dl>
	