var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, ModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations, parseEntityName } from '../analyticsEndPointUtils';
import { ThreatInfo } from '../common/threat-info.value-object';
import { EolState } from '../software-version/eol-version-state.enum';
var ɵ0 = function (dataQuery) { return (__assign({}, TvmAnalyticsSharedEntityConfigurations.parseDataQuery(dataQuery), { $expand: 'Asset($select=ExposureScore)' })); }, ɵ1 = function (_, item) { return parseEntityName(item.productName); };
// TODO: there is a bug in paris - use of idProperty: 'assetId' has no affect and the id is null
var SoftwareInstallationBase = /** @class */ (function (_super) {
    __extends(SoftwareInstallationBase, _super);
    function SoftwareInstallationBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    // We want to remove the version from the installationId, to get the productId, which ends in the last -_-
    // Example: apple-_-safari-_-3.3.2 => apple-_-safari
    SoftwareInstallationBase.prototype.getProductId = function () {
        var n = this.installationId.lastIndexOf('_');
        return this.installationId.substr(0, n - 1);
    };
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "installationId", void 0);
    __decorate([
        EntityField({
            required: true,
            parse: ɵ1,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "name", void 0);
    __decorate([
        EntityField({
            required: true,
            parse: parseEntityName,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "productName", void 0);
    __decorate([
        EntityField({
            required: true,
            parse: parseEntityName,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "vendor", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "assetId", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "assetName", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Number)
    ], SoftwareInstallationBase.prototype, "weaknesses", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", ThreatInfo)
    ], SoftwareInstallationBase.prototype, "threatInfo", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Boolean)
    ], SoftwareInstallationBase.prototype, "highSevAlert", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "osName", void 0);
    __decorate([
        EntityField({
            required: true,
        }),
        __metadata("design:type", Boolean)
    ], SoftwareInstallationBase.prototype, "isNormalized", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SoftwareInstallationBase.prototype, "eolSoftwareState", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], SoftwareInstallationBase.prototype, "eolSoftwareSinceDate", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], SoftwareInstallationBase.prototype, "productNeverMatched", void 0);
    SoftwareInstallationBase = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { 
            //enriching the query with the Asset's exposure score:
            parseDataQuery: ɵ0, singularName: 'installation', pluralName: 'installations', endpoint: 'installations', readonly: true }))
    ], SoftwareInstallationBase);
    return SoftwareInstallationBase;
}(ModelBase));
export { SoftwareInstallationBase };
export { ɵ0, ɵ1 };
