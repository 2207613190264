import { NgModule } from '@angular/core';
import { CopyDialogComponent } from './components/copy-dialog.component';
import {
	FabButtonModule,
	FabIconModule,
	FabDialogModule,
	FabTextFieldModule,
	FabSpinnerModule,
} from '@angular-react/fabric';
import { I18nModule } from '@wcd/i18n';
import { CopyDialogService } from './copy-dialog.service';
import { CommonModule } from '@angular/common';

const exportDeclarations = [CopyDialogComponent];

@NgModule({
	imports: [
		CommonModule,
		FabIconModule,
		FabButtonModule,
		FabDialogModule,
		FabSpinnerModule,
		FabTextFieldModule,
		I18nModule,
	],
	declarations: [...exportDeclarations],
	entryComponents: exportDeclarations,
	exports: [exportDeclarations],
	providers: [CopyDialogService],
})
export class CopyDialogModule {}
