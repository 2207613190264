
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">
					{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_firstSeen }}
				</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.id">
				<dt role="none">{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_id }}</dt>
				<dd role="none">{{ entity.id }}</dd>
			</ng-container>
		</dl>
	