import { Component, ViewEncapsulation } from '@angular/core';
import { SnackbarService } from './snackbar.service';
import { MessageBarType, ITheme, createTheme } from 'office-ui-fabric-react';
import { SnackbarOptions } from './snackbar.models';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';


// Same colors as Fabric, with more subtle the opacity
const messageBarTheme = createTheme({
	semanticColors: {
		errorBackground: 'rgba(232, 17, 35, 0.95)',
		blockingBackground: 'rgba(234, 67, 0, 0.95)',
		warningBackground: 'rgba(255, 185, 0, 0.95)',
		warningHighlight: '#ffb900',
		successBackground: 'rgba(186, 216, 10, 0.95)',
	},
});

@Component({
	selector: 'wcd-snackbar',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./snackbar.component.scss'],
	template: `
		<div class="wcd-snackbar__container" [class.messageVisitable]="currentSnackbar">
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="!currentSnackbar || currentSnackbar.type == MessageBarType.info"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.info"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.error"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.error"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.blocked"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.blocked"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="
					currentSnackbar && currentSnackbar.type == MessageBarType.severeWarning
				"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.severeWarning"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.success"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.success"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.warning"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.warning"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<ng-template #actions>
				<fab-messagebar-button
					*ngIf="currentSnackbar?.method"
					data-track-id="ApplyMethod"
					data-track-type="Button"
					[text]="currentSnackbar && currentSnackbar.method.text"
					[iconProps]="
						currentSnackbar &&
						currentSnackbar.method.icon && { iconName: currentSnackbar.method.icon }
					"
					(onClick)="applyMethod()"
				></fab-messagebar-button>
			</ng-template>
		</div>
	`,
})
export class SnackbarComponent {
	readonly MessageBarType = MessageBarType;
	readonly messageBarTheme: ITheme = messageBarTheme;

	currentSnackbar: SnackbarOptions = null;
	isRemovingSnackbar = false;

	constructor(public readonly snackbarService: SnackbarService, private liveAnnouncer: LiveAnnouncer, private i18nService: I18nService) {
		snackbarService.snackbarChange.subscribe(e => {
			this.currentSnackbar = this.snackbarService.visibleSnackbar;
			this.currentSnackbar &&
				this.liveAnnouncer.announce(
					this.i18nService.get('messageBar_type_alert')
					 + ', ' + this.snackbarService.visibleSnackbar.text,
					'assertive'
				);
		});

		snackbarService.snackbarIsRemovingVisible.subscribe(e => {
			this.isRemovingSnackbar = this.snackbarService.isRemovingVisibleSnackbar;
		});
	}

	applyMethod() {
		this.snackbarService.callVisibleSnackbarMethod();
	}
}
