var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, EntityModelBase } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { AlertClassification } from './alert-classification.entity';
import { AlertDetermination } from './alert-determination.entity';
import { AlertUser } from './alert-user.value-object';
import { Severity } from '../severity/severity.entity';
import { InvestigationStatusesMapping } from './alert-investiation-status-mapping.enum';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { isNil } from 'lodash-es';
import { OAuthAlertPageLinkDetails } from './alert-oAuth-alertPage-link-details.value-object';
var ɵ0 = function (id) { return (id === 0xff || isNil(id) ? null : String(id)); }, ɵ1 = function (value) { return value === 0xff; }, ɵ2 = function (category) { return category && category.replace(/([a-z])([A-Z])/g, '$1 $2'); }, ɵ3 = function (id) { return (id === 0x7fffffff || id === 0 ? null : id && String(id)); }, ɵ4 = function (value) { return value === 0x7fffffff; }, ɵ5 = function (status) {
    if (!status)
        return false;
    var statusLog = Math.log2(status);
    return statusLog !== Math.floor(statusLog);
}, ɵ6 = function (data) { return data.MachineId || data.SenseMachineId || data.ComputerDnsName; }, ɵ7 = function (data) { return data.UserSid || data.AccountSid; }, ɵ8 = function (state) { return InvestigationStatusesMapping[state]; };
var AlertBase = /** @class */ (function (_super) {
    __extends(AlertBase, _super);
    function AlertBase() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], AlertBase.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Severity' }),
        __metadata("design:type", Severity)
    ], AlertBase.prototype, "severity", void 0);
    __decorate([
        EntityField({ data: 'Classification', parse: ɵ0 }),
        __metadata("design:type", AlertClassification)
    ], AlertBase.prototype, "classification", void 0);
    __decorate([
        EntityField({ data: 'Classification', parse: ɵ1 }),
        __metadata("design:type", Boolean)
    ], AlertBase.prototype, "isMultipleClassifications", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], AlertBase.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'FirstEventTime' }),
        __metadata("design:type", Date)
    ], AlertBase.prototype, "firstEventTime", void 0);
    __decorate([
        EntityField({ data: 'LastSeen' }),
        __metadata("design:type", Date)
    ], AlertBase.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({ data: 'LastEventTime' }),
        __metadata("design:type", Date)
    ], AlertBase.prototype, "lastEventTime", void 0);
    __decorate([
        EntityField({ data: 'Categories' }),
        __metadata("design:type", Array)
    ], AlertBase.prototype, "categories", void 0);
    __decorate([
        EntityField({
            data: 'Category',
            parse: ɵ2,
        }),
        __metadata("design:type", String)
    ], AlertBase.prototype, "_category", void 0);
    __decorate([
        EntityField({
            data: 'Determination',
            parse: ɵ3,
        }),
        __metadata("design:type", AlertDetermination)
    ], AlertBase.prototype, "determination", void 0);
    __decorate([
        EntityField({ data: 'Determination', parse: ɵ4 }),
        __metadata("design:type", Boolean)
    ], AlertBase.prototype, "isMultipleDeterminations", void 0);
    __decorate([
        EntityField({
            data: 'Status',
            parse: ɵ5,
        }),
        __metadata("design:type", Boolean)
    ], AlertBase.prototype, "isMultipleStatuses", void 0);
    __decorate([
        EntityField({ data: 'AssignedTo' }),
        __metadata("design:type", String)
    ], AlertBase.prototype, "assignedTo", void 0);
    __decorate([
        EntityField({ data: 'AssignedToCount' }),
        __metadata("design:type", Number)
    ], AlertBase.prototype, "assignedToCount", void 0);
    __decorate([
        EntityField({
            data: '__self',
            require: ɵ6,
        }),
        __metadata("design:type", Machine)
    ], AlertBase.prototype, "machine", void 0);
    __decorate([
        EntityField({ data: 'MachineCount' }),
        __metadata("design:type", Number)
    ], AlertBase.prototype, "machineCount", void 0);
    __decorate([
        EntityField({ data: '__self', require: ɵ7 }),
        __metadata("design:type", AlertUser)
    ], AlertBase.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'OAuthAlertPageLinkDetails' }),
        __metadata("design:type", OAuthAlertPageLinkDetails)
    ], AlertBase.prototype, "oAuthAlertPageLinkDetails", void 0);
    __decorate([
        EntityField({ data: 'UserCount' }),
        __metadata("design:type", Number)
    ], AlertBase.prototype, "userCount", void 0);
    __decorate([
        EntityField({ data: 'MailboxCount' }),
        __metadata("design:type", Number)
    ], AlertBase.prototype, "mailboxCount", void 0);
    __decorate([
        EntityField({ data: 'MtpWorkloads' }),
        __metadata("design:type", Array)
    ], AlertBase.prototype, "mtpWorkloads", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationState',
            parse: ɵ8,
        }),
        __metadata("design:type", InvestigationStatus)
    ], AlertBase.prototype, "investigationStatus", void 0);
    return AlertBase;
}(EntityModelBase));
export { AlertBase };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8 };
