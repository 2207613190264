import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { SampleRequestResponse } from '../file/file.sample-request-response.value-object';
import { CollectSampleRequest } from '../file/file.collect-sample-request.value-object';

@ApiCall({
	name: 'Collect sample',
	endpoint: 'sampledownloadrequest/createcollectionrequest',
	method: 'POST',
	type: SampleRequestResponse,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
})
export class CollectSampleApiCall extends ApiCallModel<SampleRequestResponse, CollectSampleRequest> {}
