import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ElementRef,
	OnDestroy,
	ChangeDetectorRef,
	AfterViewInit,
	ViewChild,
} from '@angular/core';
import {
	Alert,
	AlertIncidentGraph,
	AlertIncidentGraphElement,
	AlertIncidentGraphRelationship,
} from '@wcd/domain';
import { Paris, ModelBase } from '@microsoft/paris';
import { of, Observable, Subscription, from } from 'rxjs';
import { filter, mergeMap, take, tap } from 'rxjs/operators';
import { ActivatedEntity } from '../../../../../app/global_entities/services/activated-entity.service';
import { hierarchy, tree } from 'd3-hierarchy';
import { select } from 'd3-selection';
import { AlertIncidentGraphHelperService } from '../../services/alert-incident-graph-helper.service';

@Component({
	selector: 'alert-incident-graph',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div #graphContainer class="wcd-flex-1 wcd-scroll-vertical">
				<div *ngIf="loading && !error" class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
				<div *ngIf="error" class="wcd-padding-all">
					<wcd-shared-icon iconName="error"></wcd-shared-icon>
					{{ 'alert.tabs.graph.error' | i18n }}
				</div>
			</div>
		</div>
	`,
	styles: [
		`
			.graph-error {
				margin-top: 20px;
				margin-left: 15px;
			}
		`,
	],
	providers: [AlertIncidentGraphHelperService],
})
export class AlertIncidentGraphComponent implements OnInit, OnDestroy, AfterViewInit {
	@Input() alert: Alert;
	@ViewChild('graphContainer', { static: true }) graphContainerEl: ElementRef<HTMLElement>;

	alertIncidentGraph$: Observable<AlertIncidentGraph>;

	subscription: Subscription;
	error: Error;
	loading = true;

	constructor(
		private readonly changeDetection: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly activatedEntity: ActivatedEntity,
		private readonly alertIncidentGraphHelper: AlertIncidentGraphHelperService
	) {}

	getAlert(): Observable<Alert> {
		if (this.alert) {
			return of(this.alert);
		}
		return this.activatedEntity.currentEntity$.pipe(
			filter((entity: ModelBase) => entity instanceof Alert),
			take(1),
			tap((alert: Alert) => {
				this.alert = alert;
			})
		);
	}

	ngOnInit(): void {
		this.alertIncidentGraph$ = this.getAlert().pipe(
			mergeMap((alert: Alert) =>
				this.paris
					.getRelationshipRepository<Alert, AlertIncidentGraph>(AlertIncidentGraphRelationship)
					.getRelatedItem(alert)
			)
		);
	}

	ngAfterViewInit(): void {
		this.subscription = this.alertIncidentGraph$.subscribe(
			data => {
				const graphData = this.alertIncidentGraphHelper.buildGraphData(data.raw.Elements, 1);
				this.alertIncidentGraphHelper.openFirstLevel(graphData);
				this.renderGraph(graphData);
			},
			error => {
				this.error = error;
				this.changeDetection.detectChanges();
			},
			() => {
				this.loading = false;
				this.changeDetection.detectChanges();
			}
		);
	}

	ngOnDestroy(): void {
		if (this.subscription) {
			this.subscription.unsubscribe();
		}
	}

	private renderGraph(graphData) {
		const containerEl = this.graphContainerEl.nativeElement;
		const margin = { top: 20, right: 120, bottom: 20, left: 160 };
		const width = containerEl ? containerEl.clientWidth : 960;
		const height = this.alertIncidentGraphHelper.getGraphHeight(graphData) - margin.top - margin.bottom;

		containerEl.innerHTML = '';

		const treeLayout = tree().size([height, width]);

		const svg = select(containerEl)
			.append('svg')
			.attr('width', '100%')
			.attr('height', height + margin.top + margin.bottom)
			.append('g')
			.attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

		const root = hierarchy(graphData[0]);
		(<any>root).x0 = height / 2;
		(<any>root).y0 = 0;

		this.alertIncidentGraphHelper.update(root, svg, treeLayout, root, true);

		select(self.frameElement).style('height', '100%');
	}
}
