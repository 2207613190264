var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TitleService } from '../../../shared/services/title.service';
import { sccHostService } from '@wcd/scc-interface';
var SuppressionRuleEntityComponent = /** @class */ (function (_super) {
    __extends(SuppressionRuleEntityComponent, _super);
    function SuppressionRuleEntityComponent(i18nService, titleService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.titleService = titleService;
        _this.tabs = [];
        _this.isScc = sccHostService.isSCC;
        return _this;
    }
    SuppressionRuleEntityComponent.prototype.ngOnInit = function () {
        this.titleService.setState({
            pageTitle: this.i18nService.get('suppressionRules.page.title', { name: this.entity.name }),
        });
        this.tabs = [
            {
                id: 'associatedAlerts',
                name: this.i18nService.get('suppressionRules.tabs.alerts'),
                routerLink: './alerts',
            },
            {
                id: 'conditions',
                name: this.i18nService.get('suppressionRules.tabs.conditions'),
                routerLink: './conditions',
            },
        ].map(function (tab) { return new TabModel(tab); });
    };
    return SuppressionRuleEntityComponent;
}(EntityComponentBase));
export { SuppressionRuleEntityComponent };
