<table class="wcd-table wcd-full-width">
	<thead>
		<tr>
			<th>{{ i18nService.strings.investigations_device_fields_deviceName }}</th>
			<th>{{ i18nService.strings.investigations_device_fields_operatingSystem_short }}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let investigatedMachine of investigatedMachines">
			<td class="nowrap wcd-text-overflow-medium-large">
				<wcd-shared-icon iconName="System" class="table-icon"> </wcd-shared-icon>
				<route-link [routeLink]="getMachineRouteLinkModel(investigatedMachine)">
					{{ investigatedMachine.name }}
				</route-link>
			</td>
			<td>
				{{ investigatedMachine.osName }}
			</td>
		</tr>
	</tbody>
</table>
