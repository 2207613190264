import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Command flag',
	pluralName: 'Command flags',
	readonly: true,
})
export class LiveResponseCommandFlag extends ModelBase {
	@EntityField({ data: 'flag_id' })
	flagId: string;

	@EntityField({ data: 'short_name' })
	shortName: string;

	@EntityField({ data: 'long_name' })
	longName: string;

	@EntityField({ data: 'description' })
	description: string;
}
