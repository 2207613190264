import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { RemediatedThreatStatusCount } from './remediation-threat-status-count';
import { RemediationStatusType } from '../airs_entity/airs-entity-status-types.enum';

@ValueObject({
	singularName: 'Remediated Threat Status Summary',
	pluralName: '',
	readonly: true,
})
export class RemediationThreatStatusSummary extends ModelBase {
	@EntityField({
		arrayOf: RemediatedThreatStatusCount,
		parse: statusCountArr =>
			statusCountArr.filter(
				statusCount =>
					statusCount.status === RemediationStatusType.Failed ||
					statusCount.status === RemediationStatusType.Remediated
			),
	})
	statusCount: Array<RemediatedThreatStatusCount>;

	totalEntityCount: number;

	constructor(data) {
		super(data);
		this.totalEntityCount = this.statusCount.reduce((acc, currentVal) => acc + currentVal.entityCount, 0);
	}
}
