import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../../paris-config.interface';
import { LegacyUserNetworkInformation } from '../legacy-user-network-information.value-object';
import { LegacyUser } from '../legacy-user.entity';

interface UserSidePaneDetailsParams {
	userAccount: string;
	userAccountDomain: string;
	lookingBackInDays?: number;
}

@EntityRelationship({
	sourceEntity: LegacyUser,
	dataEntity: LegacyUserNetworkInformation,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (user: LegacyUser): UserSidePaneDetailsParams => ({
		userAccount: user.accountName,
		userAccountDomain: user.accountDomainName,
	}),
	endpoint: 'UserSidePaneDetails',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class LegacyUserLegacyUserNetworkInformationRelationship
	implements EntityRelationshipRepositoryType<LegacyUser, LegacyUserNetworkInformation> {}
