
		<section
			class="collapsible-section"
			[class.no-side-margin]="noSideMargins"
			[class.no-border]="!showTopBorder"
			[class.no-padding-bottom]="noBottomPadding"
		>
			<wcd-expander
				buttonRole="button"
				[label]="label"
				[arrowPosition]="arrowPosition"
				[isExpanded]="isExpanded"
				[sectionId]="sectionId"
				(isExpandedChange)="isExpandedChange.emit($event)"
				buttonRole="button"
			>
				<ng-content></ng-content>
			</wcd-expander>
		</section>
	