import { Injectable } from '@angular/core';
import { TvmColorService, TvmColor } from './tvm-color.service';
import { ExposureScoreCategory } from '@wcd/domain';

@Injectable()
export class TvmExposureScoreService {
	constructor(private tvmColorService: TvmColorService) {}

	getExposureCategoryColorClass(exposureScore: ExposureScoreCategory): string {
		switch (exposureScore) {
			case ExposureScoreCategory.High:
				return this.tvmColorService.fontColorsClassMap.get(TvmColor.HighRisk);
			case ExposureScoreCategory.Medium:
				return this.tvmColorService.fontColorsClassMap.get(TvmColor.MediumRisk);
			case ExposureScoreCategory.Low:
				return this.tvmColorService.fontColorsClassMap.get(TvmColor.LowRisk);
		}
	}
}
