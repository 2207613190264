export enum MachineManagedByType {
	Other = 'Other',
	Mdm = 'Mdm',
	Sccm = 'Sccm',
	Mde = 'Mde',
}

export interface IMachineManagedBy {
	readonly id: MachineManagedByType;
	name: string;
	nameI18nKey: string;
	priority: number;
}
