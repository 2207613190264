export interface AnnounceMessage{
	message: string;
	politeness?: 'polite' | 'assertive';
	duration?: number;
}

export class AnnouncerService{

	private liveAnnouncerElement;
	private isAnnouncerHandlerRunning = false;
	private readonly queue : AnnounceMessage[] = [];
	private readonly delay = ms => new Promise(res => setTimeout(res, ms));

	constructor() {
		this.liveAnnouncerElement = this.creatLiveAnnouncerElement();
	}
	private creatLiveAnnouncerElement(){
		const elementId = 'global-live-announcer';
		let liveAnnouncerElement = document.querySelector<HTMLDivElement>(`#${elementId}`);
		if(liveAnnouncerElement)
			return liveAnnouncerElement;

		liveAnnouncerElement = document.createElement("div");
		liveAnnouncerElement.id = elementId
		liveAnnouncerElement.style.position = 'absolute'
		liveAnnouncerElement.style.overflow = 'hidden'
		liveAnnouncerElement.style.width = '1px'
		liveAnnouncerElement.style.height = '1px'
		liveAnnouncerElement.style.left = '-2px';
		liveAnnouncerElement.style.top = '-2px';
		liveAnnouncerElement.innerHTML = '';
		document.body.appendChild(liveAnnouncerElement);
		return liveAnnouncerElement;
	}

	announce(announceMessage: AnnounceMessage){
		announceMessage.politeness = announceMessage.politeness || 'assertive';
		announceMessage.duration = announceMessage.duration || 300;
		this.queueMessage(announceMessage);
	}

	private async queueMessage(announceMessage: AnnounceMessage) {
		this.queue.push(announceMessage);
		if (!this.isAnnouncerHandlerRunning) {
			await this.handleAnnounceQueue();
		}
	}

	private async handleAnnounceQueue() {
		this.isAnnouncerHandlerRunning = true;

		let announceMessage;
		while(announceMessage = this.queue.shift()) {
			this.liveAnnouncerElement.setAttribute('aria-live', announceMessage.politeness);
			this.liveAnnouncerElement.innerHTML = announceMessage.message;
			await this.delay(announceMessage.duration);
			this.liveAnnouncerElement.innerHTML =''
		}
		this.isAnnouncerHandlerRunning = false;
	}
}
