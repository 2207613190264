<div class="notifications wcd-full-height wcd-flex-vertical" *ngIf="!(notificationsService.snoozed$ | async)">
	<div class="wcd-flex-1 notifications-list">
		<notification *ngFor="let notification of notificationsService.highPriorityNotifications$ | async"
					  [ngClass]="{ 'new-notification': notification.isNew }"
					  [notification]="notification"
					  [isMinimizable]="true"
					  (minimize)="notificationsService.updateHighPriorityNotifications()"
					  (snooze)="notificationsService.snoozeNotifications()"
					  (select)="notificationsService.selectNotification(notification)"></notification>
	</div>
</div>
