import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import {
	OperatingSystemPlatformCategories,
	operatingSystemPlatformValues,
} from './operating-system-platform.values';

@Entity({
	singularName: 'Operating System Platform',
	pluralName: 'Operating System Platforms',
	values: operatingSystemPlatformValues,
})
export class OperatingSystemPlatform extends EntityModelBase {
	@EntityField() name: string;

	@EntityField() categoryName: string;

	@EntityField() category: OperatingSystemPlatformCategories;

	@EntityField() priority?: number;

	@EntityField() isUpgradeable?: boolean;

	@EntityField() isBackendSupportedFilter: boolean;
}
