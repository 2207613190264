import { BehaviorSubject, Observable } from 'rxjs';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	OnInit,
	ViewChild,
} from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel, DataviewFieldId } from '../../models/dataview.model';
import { ChecklistDropdownComponent, Positions } from '@wcd/forms';
import { DataviewActionTypes } from './dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DataViewActionElementButtonPicker } from './dataview-action-wrapper.component';

export interface DataviewActionColumnsCustomizationConfig {
	selectableFieldIds: Array<DataviewFieldId>;
	onColumnsChange: (columns: Array<DataviewFieldId>) => void;
	resetVisibleFields: () => void;
	actionType: DataviewActionTypes;
	visibleFields: Observable<Array<DataviewFieldId>>;
}

@Component({
	selector: 'dataview-action-columns-customization',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-checklist-dropdown
			#checkListDropdown
			[buttonTooltip]="'dataview.customizeColumns' | i18n"
			[openMenuPosition]="openMenuPosition"
			[showIconDropdown]="!smallScreenView"
			[buttonIcon]="icon"
			[buttonText]="smallScreenView ? '' : ('dataview.customizeColumns' | i18n)"
			[ariaLabel]="!smallScreenView ? '' : ('dataview.customizeColumns' | i18n)"
			class="hidden-action command-bar-item-dropdown"
			[ngModel]="visibleFields | async"
			name="dataview-fields"
			[isBordered]="false"
			[disableEmptySelection]="true"
			[secondButton]="'reset' | i18n"
			(secondButtonClick)="resetVisibleFieldsAndFocus()"
			(ngModelChange)="onColumnsChange($event)"
			[values]="dataViewModel.dataTableFields | selectableDataviewFields: selectableFieldIds"
			[navigateUsingArrowKeysOnly]="true"
			[disableSecondButton]="dataViewModel.allDefaultFieldsSelected"
			(toggleDropdownState)="toggleDropdownState($event)"
		>
		</wcd-checklist-dropdown>
	`,
})
export class DataviewActionColumnsCustomizationComponent
	implements DataViewActionElementButtonPicker, OnInit {
	@Input() columnsCustomizationActionConfig: DataviewActionColumnsCustomizationConfig;
	@Input() dataViewModel: DataViewModel;
	@Input() smallScreenView: boolean = false;
	@Input() openMenuPosition: Positions = Positions.Default;
	@Input() visibleFields$: Observable<Array<DataviewFieldId>>;
	@Output() toggleColumnCustomizationWizardState: EventEmitter<boolean> = new EventEmitter();

	public icon: string = FabricIconNames.ColumnOptions;
	public selectableFieldIds: Array<DataviewFieldId>;
	public onColumnsChange: (columns: Array<DataviewFieldId>) => void;
	public resetVisibleFields: () => void;

	visibleFields: any;

	@ViewChild('checkListDropdown', { static: false }) checkListDropdown: ChecklistDropdownComponent;

	constructor(public i18nService: I18nService, private liveAnnouncer: LiveAnnouncer) {}

	buttonElementPicker() {
		return this.checkListDropdown.dropdown.buttonEl;
	}

	toggleDropdownState(isOpen: boolean) {
		this.toggleColumnCustomizationWizardState.emit(isOpen);
	}

	ngOnInit() {
		Object.assign(this, { ...this.columnsCustomizationActionConfig });
	}

	resetVisibleFieldsAndFocus() {
		this.resetVisibleFields();
		this.liveAnnouncer
			.announce(this.i18nService.get('common.resetColumnsComplete'), 'assertive')
			.then(() => {
				setTimeout(() => {
					this.checkListDropdown.setFocusOnAllOrNone();
				});
			});
	}
}
