var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Alert } from '@wcd/domain';
import { of } from 'rxjs';
import { EntityPanelWithMachineComponent } from './entity-panel-with-machine.component.base';
import { switchMap } from 'rxjs/operators';
var EntityPanelWithAlertComponent = /** @class */ (function (_super) {
    __extends(EntityPanelWithAlertComponent, _super);
    function EntityPanelWithAlertComponent(changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService) {
        var _this = _super.call(this, changeDetectorRef, paris, globalEntityTypesService, rbacService, machinesService) || this;
        _this.isLoadingAlert = false;
        return _this;
    }
    EntityPanelWithAlertComponent.prototype.initEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        this.alert = null;
        this.isLoadingAlert = false;
        _super.prototype.initEntity.call(this, entity, isExtendedData);
    };
    EntityPanelWithAlertComponent.prototype.ngOnDestroy = function () {
        this._alertSubscription && this._alertSubscription.unsubscribe();
    };
    EntityPanelWithAlertComponent.prototype.setAlert = function (alertId) {
        var _this = this;
        this.alert = null;
        this.isLoadingAlert = true;
        this.changeDetectorRef.markForCheck();
        this._alertSubscription = this.rbacService
            .isUserExposedToEntity(this.globalEntityTypesService.getEntityType(Alert), alertId)
            .pipe(switchMap(function (userExposureResult) {
            if (userExposureResult.isExposed)
                return _this.paris.getRepository(Alert).getItemById(alertId);
            return of(null);
        }))
            .subscribe(function (alert) {
            _this.isLoadingAlert = false;
            _this.alert = alert;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isLoadingAlert = false;
            _this.changeDetectorRef.markForCheck();
        });
    };
    return EntityPanelWithAlertComponent;
}(EntityPanelWithMachineComponent));
export { EntityPanelWithAlertComponent };
