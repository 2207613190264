import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { FieldsService } from '../../../../global_entities/models/entity-type.interface';
import { PrettyNumberService } from '@wcd/prettify';
import { MissingKb, Asset } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { FeaturesService, Feature } from '@wcd/config';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken } from '../../../..//tvm/services/tvm-texts.service';

@Injectable()
export class MissingKbFieldsService implements FieldsService<MissingKb> {
	private _softwareMissingKbsFields: Array<DataviewField<MissingKb>>;
	private _machineMissingKbsFields: Array<DataviewField<MissingKb>>;
	private _exposedAssetsFields: Array<DataviewField<Asset>>;

	constructor(
		private prettyNumberService: PrettyNumberService,
		private featuresService: FeaturesService,
		private tvmTagsService: TvmTagsService,
		private i18nService: I18nService,
		private tvmTextsService: TvmTextsService
	) {}

	get fields(): Array<DataviewField<MissingKb>> {
		if (!this._softwareMissingKbsFields) {
			const softwareMissingKbsFieldsArr: Array<DataviewFieldConfig<MissingKb>> = [
				{
					id: 'vendorBulletin',
					name: this.i18nService.get('tvm.missingKb.bulletin'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'osVersion',
					name: this.i18nService.get('tvm.missingKb.osVersion'),
				},
				{
					id: 'missingMachines',
					name: this.i18nService.get('tvm.missingKb.machineMissedOn'),
					sort: {
						enabled: false,
					},
					getDisplay: (missingKbs: MissingKb) =>
						this.prettyNumberService.prettyNumber(missingKbs.missingMachines),
				},
				{
					id: 'cvesAddressed',
					name: this.i18nService.get('tvm.missingKb.cvesAddressed.title'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'productFixId',
					name: this.i18nService.get('tvm.missingKb.name'),
					sort: {
						sortDescendingByDefault: true,
					},
					onClick: (missingKbs: MissingKb) => window.open(missingKbs.productFixUrl),
					getCssClass: () => 'wcd-font-weight-bold',
				},
				{
					id: 'tags',
					name: this.i18nService.get('common.tags'),
					component: {
						type: TagsFieldComponent,
						getProps: (missingKb: MissingKb) => ({
							tags: this.tvmTagsService.getMissingKbTags(missingKb),
						}),
					},
					sort: { enabled: false },
				},
			];

			this._softwareMissingKbsFields = DataviewField.fromList<MissingKb>(
				this.linuxSoftwareMissingProductFixesFields()
			);
		}

		return this._softwareMissingKbsFields;
	}

	get exposedAssetsFields(): Array<DataviewField<Asset>> {
		if (this._exposedAssetsFields) {
			return this._exposedAssetsFields;
		}
		this._exposedAssetsFields = DataviewField.fromList<Asset>([
			{
				id: 'assetName',
				name: this.i18nService.get('tvm.common.assetNameTitle'),
				sort: { enabled: false },
				getDisplay: (asset: Asset) => asset.name,
				getLink: (asset: Asset) =>
					this.featuresService.isEnabled(Feature.UpgradeMachinePage)
						? `/machines/${asset.id}`
						: `/machine/${asset.id}`,
				icon: {
					fabricIcon: FabricIconNames.System,
				},
				className: 'nowrap wcd-text-overflow-medium',
			},
		]);
		return this._exposedAssetsFields;
	}

	get machineMissingKbsFields(): Array<DataviewField<MissingKb>> {
		if (!this._machineMissingKbsFields) {
			const missingKbsFieldsArr: Array<DataviewFieldConfig<MissingKb>> = [
				{
					id: 'vendorBulletin',
					name: this.i18nService.get('tvm.missingKb.bulletin'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'osVersion',
					name: this.i18nService.get('tvm.missingKb.osVersion'),
				},
				{
					id: 'productsNames',
					name: this.i18nService.strings.tvm_missingKb_products,
					getDisplay: (missingKb: MissingKb) =>
						this.tvmTextsService.getFormattedRelatedSoftware(
							missingKb.productsNames.map(name => `vendor-_-${name}`)
						),
					getTooltip: (missingKb: MissingKb) =>
						this.tvmTextsService.getFormattedRelatedSoftwareTooltip(
							missingKb.productsNames.map(name => `vendor-_-${name}`)
						),
					valueTooltipAllowHtmlRendering: true,
					sort: {
						enabled: false,
					},
				},
				{
					id: 'cvesAddressed',
					name: this.i18nService.get('tvm.missingKb.cvesAddressed.title'),
					sort: {
						enabled: false,
					},
				},
				{
					id: 'productFixId',
					name: this.i18nService.get('tvm.missingKb.name'),
					sort: {
						sortDescendingByDefault: true,
					},
					onClick: (missingKbs: MissingKb) => window.open(missingKbs.productFixUrl),
					getCssClass: () => 'wcd-font-weight-bold',
				},
				{
					id: 'tags',
					name: this.i18nService.get('common.tags'),
					component: {
						type: TagsFieldComponent,
						getProps: (missingKb: MissingKb) => ({
							tags: this.tvmTagsService.getMissingKbTags(missingKb),
						}),
					},
					sort: { enabled: false },
				},
			];

			this._machineMissingKbsFields = DataviewField.fromList<MissingKb>(
				this.linuxMachineMissingProductFixesFields()
			);
		}

		return this._machineMissingKbsFields;
	}

	private linuxMachineMissingProductFixesFields(): Array<DataviewFieldConfig<MissingKb>> {
		return [
			{
				id: 'vendorBulletin',
				name: this.i18nService.strings.tvm_missingKb_bulletin,
				sort: {
					enabled: false,
				},
			},
			{
				id: 'osVersion',
				name: this.i18nService.strings.tvm_missingKb_osVersion,
			},
			{
				id: 'productFixId',
				name: this.i18nService.strings.tvm_missingKb_id,
				sort: {
					sortDescendingByDefault: true,
				},
				onClick: (missingKbs: MissingKb) => window.open(missingKbs.productFixUrl),
				getCssClass: () => 'wcd-font-weight-bold',
			},
			{
				id: 'productsNames',
				name: this.i18nService.strings.tvm_missingKb_products,
				getDisplay: (missingKb: MissingKb) =>
					this.tvmTextsService.getFormattedRelatedSoftware(
						missingKb.productsNames.map(name => `vendor-_-${name}`)
					),
				getTooltip: (missingKb: MissingKb) =>
					this.tvmTextsService.getFormattedRelatedSoftwareTooltip(
						missingKb.productsNames.map(name => `vendor-_-${name}`)
					),
				valueTooltipAllowHtmlRendering: true,
				sort: {
					enabled: false,
				},
			},
			{
				id: 'cvesAddressed',
				name: this.i18nService.strings.tvm_missingKb_cvesAddressed_title,
				sort: {
					enabled: false,
				},
			},
			{
				id: 'tags',
				name: this.i18nService.strings.common_tags,
				component: {
					type: TagsFieldComponent,
					getProps: (missingKb: MissingKb) => ({
						tags: this.tvmTagsService.getMissingKbTags(missingKb),
					}),
				},
				sort: { enabled: false },
			},
		];
	}

	private linuxSoftwareMissingProductFixesFields(): Array<DataviewFieldConfig<MissingKb>> {
		return [
			{
				id: 'vendorBulletin',
				name: this.i18nService.strings.tvm_missingKb_bulletin,
				sort: {
					enabled: false,
				},
			},
			{
				id: 'osVersion',
				name: this.i18nService.strings.tvm_missingKb_osVersion,
			},
			{
				id: 'productFixId',
				name: this.i18nService.strings.tvm_missingKb_id,
				sort: {
					sortDescendingByDefault: true,
				},
				onClick: (missingKbs: MissingKb) => window.open(missingKbs.productFixUrl),
				getCssClass: () => 'wcd-font-weight-bold',
			},
			{
				id: 'missingMachines',
				name: this.i18nService.strings.tvm_missingKb_machineMissedOn,
				sort: {
					enabled: false,
				},
				getDisplay: (missingKbs: MissingKb) =>
					this.prettyNumberService.prettyNumber(missingKbs.missingMachines),
			},
			{
				id: 'cvesAddressed',
				name: this.i18nService.strings.tvm_missingKb_cvesAddressed_title,
				sort: {
					enabled: false,
				},
			},
			{
				id: 'tags',
				name: this.i18nService.strings.common_tags,
				component: {
					type: TagsFieldComponent,
					getProps: (missingKb: MissingKb) => ({
						tags: this.tvmTagsService.getMissingKbTags(missingKb),
					}),
				},
				sort: { enabled: false },
			},
		];
	}
}
