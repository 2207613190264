var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { BreadcrumbsResolver } from '../../../breadcrumbs/services/breadcrumbs-resolver.service';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { BreadcrumbsService } from '../../../breadcrumbs/services/breadcrumbs.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { Url } from '@wcd/domain';
import { AppContextService } from '@wcd/config';
import { Router } from '@angular/router';
import { RoutesService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
var UrlBreadcrumbsResolver = /** @class */ (function (_super) {
    __extends(UrlBreadcrumbsResolver, _super);
    function UrlBreadcrumbsResolver(paris, globalEntityTypesService, breadcrumbsService, activatedEntity, entityIdRouteService, appContext, router, routesService, i18nService) {
        return _super.call(this, paris, globalEntityTypesService, breadcrumbsService, activatedEntity, entityIdRouteService, appContext, router, routesService, i18nService) || this;
    }
    UrlBreadcrumbsResolver.prototype.getEntity = function (entityId, route, state) {
        // // The url in the route is encoded 2 times. To get the actual url we need to decode it 2 times
        return this.paris.createItem(Url, { Url: decodeURIComponent(decodeURIComponent(entityId)) });
    };
    return UrlBreadcrumbsResolver;
}(BreadcrumbsResolver));
export { UrlBreadcrumbsResolver };
