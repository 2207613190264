/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./message-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../icons/src/lib/components/icon.component";
import * as i4 from "../../../../icons/src/lib/services/icons.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i7 from "../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "../../../../i18n/src/lib/services/i18n.service";
import * as i11 from "../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i12 from "ngx-markdown";
import * as i13 from "./message-bar.component";
var styles_MessageBarComponent = [i0.styles];
var RenderType_MessageBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MessageBarComponent, data: {} });
export { RenderType_MessageBarComponent as RenderType_MessageBarComponent };
function View_MessageBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [["class", "control-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_MessageBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 12, "button", [["class", "message-bar-item btn"], ["data-track-type", "Button"], ["type", "button"]], [[1, "data-track-id", 0], [8, "disabled", 0]], [[null, "click"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 5).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 5).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.runAction(_v.context.$implicit) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(5, 147456, null, 0, i6.TooltipDirective, [i1.ElementRef, i7.TooltipsService, i8.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "i", [["class", "loader-icon"]], [[8, "hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBarComponent_3)), i1.ɵdid(10, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "span", [["class", "command-bar-item-button-label"]], null, null, null, null, null)), (_l()(), i1.ɵted(13, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = "message-bar-item btn"; var currVal_3 = (_v.context.$implicit.buttonClass || "btn-primary"); _ck(_v, 4, 0, currVal_2, currVal_3); var currVal_4 = _v.context.$implicit.tooltip; _ck(_v, 5, 0, currVal_4); var currVal_6 = (_v.context.$implicit.icon && !_co.isRunning(_v.context.$implicit)); _ck(_v, 10, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ("MessageBar-" + _v.context.$implicit.id); var currVal_1 = _co.isRunning(_v.context.$implicit); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_5 = !_co.isRunning(_v.context.$implicit); _ck(_v, 7, 0, currVal_5); var currVal_7 = _v.context.$implicit.name; _ck(_v, 13, 0, currVal_7); }); }
function View_MessageBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-flex-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBarComponent_2)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.actions; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MessageBarComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i9.I18nPipe, [i10.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 20, "div", [["class", "message-bar wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal "], ["data-track-component", "Top Message Bar"], ["data-track-component-type", "Message Bar"]], null, null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "wcd-shared-icon", [["class", "message-bar-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(7, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, [" "])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 8, "div", [["class", "message-bar-text wcd-flex-1 message-bar-text-size"]], [[1, "role", 0]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 11).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 11).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 11).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 11).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵdid(11, 147456, null, 0, i6.TooltipDirective, [i1.ElementRef, i7.TooltipsService, i8.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i1.ɵppd(12, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 3, "markdown", [], null, null, null, i11.View_MarkdownComponent_0, i11.RenderType_MarkdownComponent)), i1.ɵdid(15, 4767744, null, 0, i12.MarkdownComponent, [i1.ElementRef, i12.MarkdownService], { data: [0, "data"] }, null), i1.ɵpod(16, { data: 0 }), i1.ɵppd(17, 2), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MessageBarComponent_1)), i1.ɵdid(21, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "message-bar wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal "; var currVal_1 = _co.className; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = _co.iconName; _ck(_v, 7, 0, currVal_2); var currVal_4 = i1.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i1.ɵnov(_v, 0), _co.tooltipKey)); _ck(_v, 11, 0, currVal_4); var currVal_5 = i1.ɵunv(_v, 15, 0, _ck(_v, 17, 0, i1.ɵnov(_v, 0), _co.messageKey, _ck(_v, 16, 0, _co.messageData))); _ck(_v, 15, 0, currVal_5); var currVal_6 = ((_co.actions == null) ? null : _co.actions.length); _ck(_v, 21, 0, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.messageRole; _ck(_v, 10, 0, currVal_3); }); }
export function View_MessageBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "message-bar", [], null, null, null, View_MessageBarComponent_0, RenderType_MessageBarComponent)), i1.ɵdid(1, 49152, null, 0, i13.MessageBarComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var MessageBarComponentNgFactory = i1.ɵccf("message-bar", i13.MessageBarComponent, View_MessageBarComponent_Host_0, { messageKey: "messageKey", messageData: "messageData", iconName: "iconName", className: "className", tooltipKey: "tooltipKey", actions: "actions", messageRole: "messageRole" }, { actionFailed: "actionFailed" }, []);
export { MessageBarComponentNgFactory as MessageBarComponentNgFactory };
