<div class="wcd-full-height wcd-flex-vertical">
	<p *ngIf="isGlobalAdmin" class="form-section-description">
		<wcd-shared-icon iconName="Info" class="ms-icon-top-2-fix"> </wcd-shared-icon >
		{{globalAdminAccessNote | i18n}}
	</p>
	<multi-select
		class="wcd-full-height wcd-flex-vertical"
		[selectedDescription]="aadGroupsDescription"
		[selected]="assignedAadGroups"
		[available]="availableAadGroups"
		[requiredAllowedActions]="['systemSettings']"
		[(chosenFromSelected)]="selectedAssignedAadGroups"
		[(chosenFromAvailable)]="selectedAadGroups"
		[canEdit]="authService.currentUser.hasMdeAllowedUserRoleAction(userRoleActionsService.userRoleActionsMap.get('systemSettings'))"
		[isDisabled]="isDisabled"
		[isLoadingAvailableItems]="isLoadingAadGroups"
		[hasError]="isAadGroupsError"
		[virtualScrollAvailableItems] = "true"
		removeButtonText="userRoles.removeSelectedGroups"
		addButtonText="userRoles.addSelectedGroups"
		filterPlaceholder="userRoles.filterUserGroups"
		[noSelectedItemsPlaceholder]="emptyAssignmentsNote"
		notFoundText="userRoles.emptyAadGroups"
		notFoundForFilterText="userRoles.emptyAadGroupsForFilter"
		(onRemoveChosen)="removeSelectedGroups()"
		(onAddChosen)="addSelectedGroups()"
		(onSetAvailableItems)="setAvailableAadGroups($event)">
	</multi-select>
</div>
