import { Injectable } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';
import { TimeRangeId } from '@wcd/date-time-picker';
import { TzDateService } from '@wcd/localization';
import {
	ChangeEvent,
	ChangeEventTopPerDayApiCall,
	ChangeEventTopPerDayBySoftwareApiCall,
	ChangeEventByDate,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { ChangeEventTextService } from './tvm-change-events-texts.service';

export enum ChangeEventDomain {
	VA = 'va',
	SCA = 'sca',
}

const EVENTS_USED_DATE_FORMAT = 'MM/dd';

@Injectable()
export class TvmTopChangeEventsService {
	private _isScaChangeEventEnabled: boolean;

	constructor(
		featuresService: FeaturesService,
		private paris: Paris,
		private tzDateService: TzDateService,
		private changeEventTextService: ChangeEventTextService
	) {
		this._isScaChangeEventEnabled = featuresService.isEnabled(Feature.TvmScaEventInsights);
	}

	getChangeEvents$(domain: ChangeEventDomain): Observable<Map<string, string[]>> {
		if (!this._isScaChangeEventEnabled && domain === ChangeEventDomain.SCA) {
			return of(new Map());
		}
		const dataQuery = {
			where: { date: TimeRangeId.month, eventsDomain: domain, eventsPerDay: 2 },
		};

		return this.paris
			.apiCall<ChangeEventByDate[]>(ChangeEventTopPerDayApiCall, dataQuery)
			.pipe(map((eventsByDate: ChangeEventByDate[]) => this.getEventByDateMap(eventsByDate)));
	}

	getChangeEventsForSoftware$(productId: string): Observable<Map<string, string[]>> {
		return this.paris
			.apiCall<ChangeEventByDate[]>(ChangeEventTopPerDayBySoftwareApiCall, {
				where: { productId: productId, date: TimeRangeId.month },
			})
			.pipe(map((changeEvents: ChangeEventByDate[]) => this.getEventByDateMap(changeEvents)));
	}

	private getEventByDateMap(changeEventByDate: ChangeEventByDate[]): Map<string, string[]> {
		const dateToEvents: Map<string, string[]> = new Map();
		changeEventByDate.forEach(dayEvents => {
			const dateString = this.tzDateService.format(dayEvents.date, EVENTS_USED_DATE_FORMAT);
			const eventsString = dayEvents.changeEvents.map((e: ChangeEvent) =>
				this.changeEventTextService.getChangeEventText(e)
			);
			dateToEvents.set(dateString, eventsString);
		});

		return dateToEvents;
	}
}
