var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Identifiable } from '../../data/models/identifiable.model';
import { merge } from 'lodash-es';
var CommentModel = /** @class */ (function (_super) {
    __extends(CommentModel, _super);
    function CommentModel(data) {
        return _super.call(this, data) || this;
    }
    CommentModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
        this.user = data.user;
        this.message = data.message;
        this.linkedObjects = {
            investigation: data.investigation,
            investigation_action: data.investigation_action,
        };
        this.timestamp = data.timestamp
            ? data.timestamp instanceof Date
                ? data.timestamp
                : new Date(data.timestamp)
            : null;
        this.icon = data.icon;
        this.action = data.action;
    };
    CommentModel.prototype.getBackendData = function () {
        return merge({ message: this.message }, this.linkedObjects);
    };
    return CommentModel;
}(Identifiable));
export { CommentModel };
