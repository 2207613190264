import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Automated Investigation status',
	pluralName: 'Automated Investigation status',
	endpoint: 'on-boarding/airs_status',
	parseItemQuery: (
		itemId,
		entity?,
		config?,
		params?: {
			[index: string]: any;
		}
	) => {
		const url = 'on-boarding/airs_status/query_airs_status';
		const sliceId: string = params && params['sliceId'];
		if (sliceId) {
			return `${url}?slice_id=${sliceId}`;
		}
		return url;
	},
	parseSaveQuery: (value: AutomatedIrStatus) =>
		`on-boarding/airs_status/toggle_airs_status?state=${value.isEnabled ? 'enable' : 'disable'}` +
		(value.sliceId ? `&slice_id=${value.sliceId}` : ''),
	saveMethod: 'POST',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class AutomatedIrStatus extends EntityModelBase {
	@EntityField({
		data: 'enabled',
		defaultValue: false,
	})
	isEnabled: boolean;

	@EntityField({
		data: 'slice_id',
	})
	sliceId: number;
}
