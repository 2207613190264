/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/expander/src/lib/collapsible-section.component.ngfactory";
import * as i2 from "../../../../../../../../projects/expander/src/lib/collapsible-section.component";
import * as i3 from "@angular/common";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i7 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i8 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i9 from "./mte-recommended.panel.component";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
var styles_MteRecommendedPanelComponent = [];
var RenderType_MteRecommendedPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MteRecommendedPanelComponent, data: {} });
export { RenderType_MteRecommendedPanelComponent as RenderType_MteRecommendedPanelComponent };
function View_MteRecommendedPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "wcd-collapsible-section", [], null, null, null, i1.View_CollapsibleSectionComponent_0, i1.RenderType_CollapsibleSectionComponent)), i0.ɵdid(1, 49152, null, 0, i2.CollapsibleSectionComponent, [], { label: [0, "label"], sectionId: [1, "sectionId"], noSideMargins: [2, "noSideMargins"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "incident.overview.mte.executiveSummary")); var currVal_1 = "incident.mte.executiveSummary"; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.description; _ck(_v, 4, 0, currVal_3); }); }
function View_MteRecommendedPanelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "wcd-collapsible-section", [], null, null, null, i1.View_CollapsibleSectionComponent_0, i1.RenderType_CollapsibleSectionComponent)), i0.ɵdid(1, 49152, null, 0, i2.CollapsibleSectionComponent, [], { label: [0, "label"], sectionId: [1, "sectionId"], noSideMargins: [2, "noSideMargins"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, 0, 0, "p", [], [[8, "innerHTML", 1]], null, null, null, null)), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "incident.overview.mte.recommendationSummary")); var currVal_1 = "incident.mte.recommendationSummary"; var currVal_2 = true; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.recommendedActions; _ck(_v, 4, 0, currVal_3); }); }
function View_MteRecommendedPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MteRecommendedPanelComponent_2)), i0.ɵdid(3, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MteRecommendedPanelComponent_3)), i0.ɵdid(6, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.incident.description; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.incident.recommendedActions; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_MteRecommendedPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 16, "wcd-panel", [], null, [[null, "close"], [null, "startClose"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } if (("startClose" === en)) {
        var pd_2 = (_co.startClose() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i6.View_PanelComponent_0, i6.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i7.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i8.PanelService], { settings: [0, "settings"] }, { close: "close", startClose: "startClose" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵeld(5, 0, null, 1, 11, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "div", [["class", "wcd-flex-1 wcd-scroll-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 2, "div", [["class", "wcd-font-weight-bold wcd-font-size-xl wcd-margin-xLarge-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["\n\t\t\t\t", ""])), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MteRecommendedPanelComponent_1)), i0.ɵdid(14, 16384, null, 0, i3.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, 1, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.incident; _ck(_v, 14, 0, currVal_2); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "incident.overview.mte.title")); _ck(_v, 10, 0, currVal_1); }); }
export function View_MteRecommendedPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mte-recommended-panel", [], null, null, null, View_MteRecommendedPanelComponent_0, RenderType_MteRecommendedPanelComponent)), i0.ɵdid(1, 245760, null, 0, i9.MteRecommendedPanelComponent, [i10.Router, i11.WicdSanitizerService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MteRecommendedPanelComponentNgFactory = i0.ɵccf("mte-recommended-panel", i9.MteRecommendedPanelComponent, View_MteRecommendedPanelComponent_Host_0, { settings: "settings", incident: "incident" }, {}, []);
export { MteRecommendedPanelComponentNgFactory as MteRecommendedPanelComponentNgFactory };
