import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsCloudApplication } from '@wcd/domain';

@Component({
	selector: 'airs-cloud-application-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudApplication_fields_firstSeen }}</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudApplication_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.appId">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudApplication_fields_appId }}</dt>
				<dd role="none">{{ entity.appId }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.risk">
				<dt role="none">{{ i18nService.strings.airsEntities_cloudApplication_fields_risk }}</dt>
				<dd role="none">{{ entity.risk }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsCloudApplicationDetailsComponent {
	@Input() entity: AirsCloudApplication;

	constructor(public i18nService: I18nService) {}
}
