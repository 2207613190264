import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertDetectionSource', alertDetectionSourceFilter);

function alertDetectionSourceFilter() {
	return function(input) {
		if (!input) {
			return 'EDR';
		}

		switch (input) {
			case 0:
			case '0':
			case 'Unknown':
				return null;
			case 1:
			case 10:
			case '1':
			case 'WindowsDefenderAtp':
				return 'EDR';
			case 2:
			case 20:
			case '2':
			case 'WindowsDefenderAv':
				return 'Antivirus';
			case 4:
			case 'WindowsDefenderSmartScreen':
				return 'SmartScreen';
			case 8:
			case 'iSight':
				return 'iSight';
			case 16:
			case 'Bitdefender':
				return 'Bitdefender';
			case 32:
			case 'CustomerTI':
				return 'Custom TI';
			case 64:
			case 'Ziften':
				return 'Ziften';
			case 128:
			case 'Lookout':
				return 'Lookout';
			case 256:
			case 'SentinelOne':
				return 'SentinelOne';
			case 512:
			case 'OfficeATP':
				return 'MDO';
			case 1024:
			case 'AutomatedInvestigation':
				return 'Automated investigation';
			case 2048:
			case 'ThreatExperts':
				return 'Microsoft Threat Experts';
			case 4096:
			case 'CustomDetection':
				return 'Custom detection';
			case 8192:
			case 'AzureATP':
				return 'MDI';
			case 16384:
			case 'MCAS':
				return 'Microsoft Cloud App Security';
			case 32768:
			case 'MTP':
				return 'Microsoft 365 Defender';
			default:
				return null;
		}
	};
}
