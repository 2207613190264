import { NgModule } from '@angular/core';
import { SystemExclusionsDataviewComponent } from './components/system-exclusions.dataview.component';
import { SystemExclusionsService } from './services/system-exclusions.service';
import { SystemExclusionsBackendService } from './services/system-exclusions.backend.service';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { SystemExclusionEditComponent } from './components/system-exclusion-edit.component';
import { SystemExclusionEditModalComponent } from './components/system-exclusion-edit.modal.component';
import { Routes } from '@angular/router';
import { SharedModule } from '../../shared/shared.module';
import { SystemExclusionBaseFields } from './services/system-exclusion-base-fields.service';
import { PanelsModule } from '@wcd/panels';
import { FeaturesService } from '@wcd/config';
import { Feature } from '@wcd/config';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';

export const systemExclusionsTitle = 'exclusion_title';
export const systemExclusionsIcon = 'exclusion';

export function getSystemExclusionPageTitleKey(featuresService: FeaturesService): string {
	return featuresService.isEnabled(Feature.AclOffloadingPhase1) &&
		featuresService.isEnabled(Feature.AclOffloadingPhase2)
		? 'process_memory_indicators'
		: systemExclusionsTitle;
}

export const systemExclusionsRoutes: Routes = [
	{
		path: ':systemExclusionType',
		component: SystemExclusionsDataviewComponent,
		data: {
			pageTitleKey: systemExclusionsTitle,
			getPageTitleKey: getSystemExclusionPageTitleKey,
			pageTitleIcon: systemExclusionsIcon,
			pageDescriptionKey: 'exclusion.description',
		},
	},
	{
		path: '',
		component: HistorySafeRedirectComponent,
		data: {
			redirectTo: 'files'
		},
		pathMatch: 'full',
	},
];

@NgModule({
	imports: [SharedModule, DataViewsModule, PanelsModule],
	declarations: [
		SystemExclusionsDataviewComponent,
		SystemExclusionEditComponent,
		SystemExclusionEditModalComponent,
	],
	providers: [SystemExclusionsService, SystemExclusionsBackendService, SystemExclusionBaseFields],
	entryComponents: [
		SystemExclusionsDataviewComponent,
		SystemExclusionEditComponent,
		SystemExclusionEditModalComponent,
	],
})
export class SystemExclusionsModule {}
