import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { NotificationRulesDataviewComponent } from './components/notification-rules.dataview';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { NotificationRulePanelComponent } from './components/notification-rule.panel.component';
import { NotificationRuleEditComponent } from './components/notification-rule-edit.component';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';

@NgModule({
	imports: [SharedModule, DataViewsModule, PanelsModule, WcdFormsModule],
	declarations: [
		NotificationRulesDataviewComponent,
		NotificationRulePanelComponent,
		NotificationRuleEditComponent,
	],
	exports: [],
	entryComponents: [NotificationRulePanelComponent],
})
export class NotificationRulesModule {}
