import { RuleNameStepComponent } from './components/rule-name-step.component';
import { EmailNotificationReviewStepComponent } from './components/email-notification-review-step.component';
import { EmailNotificationRecipientsStepComponent } from './components/email-notification-recipients-step.component';
import { EmailNotificationCompletedComponent } from './components/email-notification-completed.component';
import { SimpleDataListTableComponent } from './components/simple-data-list/simple-data-list-table.component';
import { SimpleDataListPanelComponent } from './components/simple-data-list/simple-data-list.panel.component';
import { EmailNotificationSummaryComponent } from './components/email-notification-summary.component';
import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { I18nModule } from '@wcd/i18n';
import { DialogsModule } from '../dialogs/dialogs.module';

@NgModule({
	imports: [
		SharedModule,
		I18nModule,
		DialogsModule
	],
	declarations: [
		RuleNameStepComponent,
		EmailNotificationReviewStepComponent,
		EmailNotificationRecipientsStepComponent,
		EmailNotificationCompletedComponent,
		SimpleDataListTableComponent,
		SimpleDataListPanelComponent,
		EmailNotificationSummaryComponent
	],
	entryComponents: [
		RuleNameStepComponent,
		EmailNotificationReviewStepComponent,
		EmailNotificationRecipientsStepComponent,
		EmailNotificationCompletedComponent,
		SimpleDataListTableComponent,
		SimpleDataListPanelComponent,
		EmailNotificationSummaryComponent
	],
	exports: [
		SimpleDataListTableComponent,
		SimpleDataListPanelComponent,
	],
})
export class EmailNotificationsModule {}
