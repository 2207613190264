/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./area-chart.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./area-chart.component";
var styles_AreaChartComponent = [i0.styles];
var RenderType_AreaChartComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_AreaChartComponent, data: {} });
export { RenderType_AreaChartComponent as RenderType_AreaChartComponent };
export function View_AreaChartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "wcd-area-chart"]], [[8, "id", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.chartId; _ck(_v, 1, 0, currVal_0); }); }
export function View_AreaChartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-area-chart", [], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; if (("window:resize" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_AreaChartComponent_0, RenderType_AreaChartComponent)), i1.ɵdid(1, 4243456, null, 0, i2.AreaChartComponent, [i1.ElementRef], null, null)], null, null); }
var AreaChartComponentNgFactory = i1.ɵccf("wcd-area-chart", i2.AreaChartComponent, View_AreaChartComponent_Host_0, { options: "options" }, {}, []);
export { AreaChartComponentNgFactory as AreaChartComponentNgFactory };
