/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./alerts.dataview.ngfactory";
import * as i2 from "./alerts.dataview";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../services/alerts.fields";
import * as i5 from "../services/alerts.service";
import * as i6 from "../../../shared/services/title.service";
import * as i7 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i10 from "../../../global_entities/services/global-entity-types.service";
import * as i11 from "../../../shared/services/time-ranges.service";
import * as i12 from "../../../rbac/services/rbac.service";
import * as i13 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i14 from "../../../dialogs/services/dialogs.service";
import * as i15 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i16 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i17 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i18 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i19 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i20 from "@angular/common";
import * as i21 from "./alerts.dataview-panel";
var styles_AlertsDataviewPanelComponent = [];
var RenderType_AlertsDataviewPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertsDataviewPanelComponent, data: {} });
export { RenderType_AlertsDataviewPanelComponent as RenderType_AlertsDataviewPanelComponent };
function View_AlertsDataviewPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "alerts-dataview", [["dataViewId", "alerts-dataview-panel-view"]], null, null, null, i1.View_AlertsDataviewComponent_0, i1.RenderType_AlertsDataviewComponent)), i0.ɵdid(1, 638976, null, 0, i2.AlertsDataviewComponent, [i3.Paris, i4.AlertsFields, i5.AlertsService, i6.TitleService, i7.AuthService, i8.Router, i9.PreferencesService, i8.ActivatedRoute, i10.GlobalEntityTypesService, i11.TimeRangesService, i12.RbacService, i13.AppConfigService, i14.DialogsService, i0.ChangeDetectorRef, i15.FeaturesService, i16.I18nService], { dataViewId: [0, "dataViewId"], onlyFields: [1, "onlyFields"], sortDisabledFields: [2, "sortDisabledFields"], responsiveLayout: [3, "responsiveLayout"], allowPaging: [4, "allowPaging"], allowFilters: [5, "allowFilters"], allowGrouping: [6, "allowGrouping"], allowTimeRangeSelect: [7, "allowTimeRangeSelect"], hideControls: [8, "hideControls"], disableSelection: [9, "disableSelection"], showHeader: [10, "showHeader"], defaultSortId: [11, "defaultSortId"], fixedTable: [12, "fixedTable"], removePadding: [13, "removePadding"], dataViewConfig: [14, "dataViewConfig"] }, null), i0.ɵpad(2, 4), i0.ɵpod(3, { data: 0 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "alerts-dataview-panel-view"; var currVal_1 = _co.onlyFields; var currVal_2 = _ck(_v, 2, 0, "title", "severity", "status", "lasteventtime"); var currVal_3 = true; var currVal_4 = false; var currVal_5 = false; var currVal_6 = false; var currVal_7 = false; var currVal_8 = true; var currVal_9 = true; var currVal_10 = false; var currVal_11 = _co.defaultSortBy; var currVal_12 = true; var currVal_13 = false; var currVal_14 = _ck(_v, 3, 0, _co.alerts); _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14]); }, null); }
export function View_AlertsDataviewPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-panel", [["data-track-component", "AlertsDataviewPanel"]], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i17.View_PanelComponent_0, i17.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i18.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i19.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_AlertsDataviewPanelComponent_1)), i0.ɵdid(6, 16384, null, 0, i20.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_co.alerts == null) ? null : _co.alerts.length); _ck(_v, 6, 0, currVal_1); }, null); }
export function View_AlertsDataviewPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alerts-dataview-panel", [], null, null, null, View_AlertsDataviewPanelComponent_0, RenderType_AlertsDataviewPanelComponent)), i0.ɵdid(1, 245760, null, 0, i21.AlertsDataviewPanelComponent, [i8.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertsDataviewPanelComponentNgFactory = i0.ɵccf("alerts-dataview-panel", i21.AlertsDataviewPanelComponent, View_AlertsDataviewPanelComponent_Host_0, { settings: "settings", alerts: "alerts", defaultSortBy: "defaultSortBy", onlyFields: "onlyFields" }, {}, []);
export { AlertsDataviewPanelComponentNgFactory as AlertsDataviewPanelComponentNgFactory };
