<div class="wcd-full-height wcd-flex-vertical">
	<section class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal">
		<network-endpoint-entity-details [entity]="networkEndpoint"
										 [showEntityPanelLink]="true"></network-endpoint-entity-details>
	</section>
	<ng-container *ngIf="networkEndpoint.ip">
		<section class="wcd-flex-none wcd-padding-large-horizontal">
			<ip-entity-details [entity]="networkEndpoint.ip"></ip-entity-details>
		</section>
	</ng-container>
	<ng-container *ngIf="networkEndpoint.url">
		<section class="wcd-flex-none wcd-padding-large-horizontal">
			<url-entity-details [entity]="networkEndpoint.url"></url-entity-details>
		</section>
	</ng-container>
</div>
