import { TemplateRef, ViewContainerRef } from '@angular/core';
/**
 * Allows declaring variables inside templates.
 * Example usages:
 * 1. `async` pipe, when you don't have a condition for `*ngIf`.
 * 	  To avoid creating multiple subscriptions (when you want to use the async pipe and
 * 	  use the variable in multiple places in the template)
 * 2. Local template variables.
 *
 * This code is taken from the following article: https://medium.com/@AustinMatherne/angular-let-directive-a168d4248138
 */
var LetDirective = /** @class */ (function () {
    function LetDirective(_viewContainer, _templateRef) {
        this._context = { ngLet: null };
        _viewContainer.createEmbeddedView(_templateRef, this._context);
    }
    Object.defineProperty(LetDirective.prototype, "ngLet", {
        set: function (value) {
            this._context.ngLet = value;
        },
        enumerable: true,
        configurable: true
    });
    return LetDirective;
}());
export { LetDirective };
