<div class="wcd-range-slider">
    <div *ngIf="showValue" class="rail-label min">
        {{valueFormat(value.from)}}
    </div>

    <div class="rail" #rail>
        <div class="inactive left" [style.width]="getInactiveRailRelativeWidth('minElem') + '%'"></div>
        <div class="inactive right" [style.width]="getInactiveRailRelativeWidth('maxElem') + '%'"></div>

        <div class="boundary max" #maxBoundary [style.width]="getMaxBoundaryAbsoluteWidth() + 'px'">
            <div class="boundary min" #minBoundary [style.width]="getMinBoundaryAbsoluteWidth() + 'px'">
                <div #minElem
                    role="slider"
                    tabindex="0"
                    class="thumb"
                    style="left: 0"
                    cdkDrag cdkDragLockAxis="x"
                    [cdkDragBoundary]="minBoundary"
                    [cdkDragFreeDragPosition]="minThumbFreeDragPosition"
                    (cdkDragMoved)="onDragMoved('minElem')"
                    (cdkDragReleased)="onDragReleased()"
					(keyup)="keyPressed('minElem', $event)"
					[attr.aria-label]="'range_slider_minimum' | i18n"
                    [attr.aria-valuemin]="min"
                    [attr.aria-valuenow]="value.from"
                    [attr.aria-valuetext]="valueFormat(value.from)"
                    [attr.aria-valuemax]="max">
                </div>
                <div #maxElem
                    role="slider"
                    tabindex="0"
                    class="thumb"
                    style="right: 0"
                    cdkDrag cdkDragLockAxis="x"
                    [cdkDragBoundary]="maxBoundary"
                    [cdkDragFreeDragPosition]="maxThumbFreeDragPosition"
                    (cdkDragMoved)="onDragMoved('maxElem')"
                    (cdkDragReleased)="onDragReleased()"
					(keyup)="keyPressed('maxElem', $event)"
					[attr.aria-label]="'range_slider_maximum' | i18n"
                    [attr.aria-valuemin]="min"
                    [attr.aria-valuenow]="value.to"
                    [attr.aria-valuetext]="valueFormat(value.to)"
                    [attr.aria-valuemax]="max">
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="showValue" class="rail-label max">
        {{valueFormat(value.to)}}
    </div>
</div>
