import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
} from '@angular/core';
import { getClientTimeZoneString, LocaleConfigService, TzDateService } from '@wcd/localization';
import { inRange, merge, padStart } from 'lodash-es';
import { Subscription } from 'rxjs';
import { FabSpinButtonComponent, FabCalendarComponent, FabTextFieldComponent } from '@angular-react/fabric';
import { OnChanges, TypedChanges, wasChanged } from '@wcd/angular-extensions';
import { DateTimeDisplayService } from '../services/date-time-display.service';
import { I18nService } from '@wcd/i18n';

const sharedSpinButtonStyles: Partial<FabSpinButtonComponent['styles']> = {
	root: { minWidth: 'inherit' },
	labelWrapper: { height: '100%' },
	labelWrapperStart: { marginRight: 0 },
	spinButtonWrapper: { minWidth: 'inherit' },
	input: { width: '40px', minWidth: '40px', maxWidth: '40px' },
};

const mainSpinButtonStyles: Partial<FabSpinButtonComponent['styles']> = merge(sharedSpinButtonStyles, {
	icon: { paddingRight: '2px', paddingLeft: 0 },
});

const secondarySpinButtonStyles: Partial<FabSpinButtonComponent['styles']> = merge(sharedSpinButtonStyles, {
	root: { display: 'flex', alignItems: 'center' },
	label: { margin: '0 2px' },
});

@Component({
	selector: 'wcd-date-time-picker',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./date-time-picker.component.scss'],
	templateUrl: './date-time-picker.component.html',
})
export class DateTimePickerComponent implements OnInit, OnDestroy, OnChanges<DateTimePickerComponent> {
	readonly hourSpinButtonStyles = mainSpinButtonStyles;
	readonly minuteSpinButtonStyles = secondarySpinButtonStyles;
	readonly secondSpinButtonStyles = secondarySpinButtonStyles;

	calendarStrings: FabCalendarComponent['strings'];
	timezoneLabel: string;
	displayValue: string;
	dialogShown: boolean = false;

	@Input() allowTimeSelection?: boolean = false;
	@Input() value?: Date;
	@Input() minDate?: Date;
	@Input() maxDate?: Date;
	@Input() showGoToToday?: boolean = true;
	@Input() isMonthPickerVisible?: boolean = false;
	@Input() autoFocusOpen?: boolean = true;

	@Output() readonly valueChange = new EventEmitter<Date>();
	@Output() readonly dialogShownChange = new EventEmitter<boolean>();

	private readonly _timezoneChangeSubscription: Subscription;
	private _prevSelectedDate: Date = null;

	hours: number;
	minutes: number;
	seconds: number;


	@ViewChild('input', { static: true }) inputEl: FabTextFieldComponent;
	private _inputHtmlEl: HTMLInputElement;
	private get inputHtmlEl(): HTMLInputElement {
		if (!this._inputHtmlEl) {
			this._inputHtmlEl = this.inputEl.elementRef.nativeElement.querySelector('input');
		}
		return this._inputHtmlEl;
	}

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly localeConfigService: LocaleConfigService,
		private readonly dateTimeDisplayService: DateTimeDisplayService,
		private readonly tzDateService: TzDateService,
		private readonly i18nService: I18nService
	) {
		this.setTimezoneLabel();
		this.setCalendarStrings();

		this._timezoneChangeSubscription = this.localeConfigService.config$.subscribe(() => {
			this.setTimezoneLabel();
			this.setCalendarStrings();
			this.changeDetectorRef.markForCheck();
		});
	}

	ngOnInit() {
		this.value = this.value || new Date();
		this.hours = this.getHours();
		this.minutes = this.getMinutes();
		this.seconds = this.getSeconds();
	}

	ngOnChanges(changes: TypedChanges<DateTimePickerComponent>) {
		if (wasChanged(changes.value)) {
			this.setDisplayValue(changes.value.currentValue);
		}
	}

	ngOnDestroy() {
		if (this._timezoneChangeSubscription) {
			this._timezoneChangeSubscription.unsubscribe();
		}
	}

	onInputFocused() {
		this.showDialog();
	}

	showDialog() {
		if (this._prevSelectedDate) {
			this.hours = this._prevSelectedDate.getHours();
			this.minutes = this._prevSelectedDate.getMinutes();
			this.seconds = this._prevSelectedDate.getSeconds();
		}

		this.dialogShown = true;
		this.changeDetectorRef.detectChanges();
		this.dialogShownChange.emit(this.dialogShown);
	}

	hideDialog() {
		const nativeInputEl = this.inputHtmlEl;
		nativeInputEl.selectionStart = 0;
		nativeInputEl.selectionEnd = 0;
		nativeInputEl.focus();
		this.dialogShown = false;
		this.changeDetectorRef.detectChanges();

		this.dialogShownChange.emit(this.dialogShown);
	}

	revertAndDismiss() {
		this.value = this._prevSelectedDate;
		this.hideDialog();
	}

	confirmAndDismiss() {
		const selectedDateTime = new Date(this.value.valueOf());

		if (this.allowTimeSelection) {
			selectedDateTime.setHours(this.hours, this.minutes, this.seconds);
		} else {
			selectedDateTime.setHours(0, 0, 0);
		}

		const displayDate = this.localeConfigService.isLocalTimeZone
			? selectedDateTime
			: new Date(
					Date.UTC(
						selectedDateTime.getFullYear(),
						selectedDateTime.getMonth(),
						selectedDateTime.getDate(),
						selectedDateTime.getHours(),
						selectedDateTime.getMinutes(),
						selectedDateTime.getSeconds()
					)
			  );

		this.setDisplayValue(displayDate);
		this.changeDetectorRef.markForCheck();

		this._prevSelectedDate = selectedDateTime;
		this.valueChange.emit(displayDate);

		this.hideDialog();
	}

	padValue(value: number): string {
		return padStart(value.toString(), 2, '0');
	}

	onIncrement(kind: 'hour' | 'minute' | 'second'): FabSpinButtonComponent['increment'] {
		const maxValue = kind === 'hour' ? 23 : 59;

		return (value: string): string | void => {
			const parsedValue = +value;
			const newValue = parsedValue >= maxValue ? parsedValue : parsedValue + 1;

			this.updateValue(kind, newValue);
			return this.padValue(newValue);
		};
	}

	onDecrement(kind: 'hour' | 'minute' | 'second'): FabSpinButtonComponent['decrement'] {
		return (value: string): string | void => {
			const parsedValue = +value;
			const newValue = parsedValue <= 0 ? parsedValue : parsedValue - 1;

			this.updateValue(kind, newValue);
			return this.padValue(newValue);
		};
	}

	onValidate(kind: 'hour' | 'minute' | 'second'): FabSpinButtonComponent['validate'] {
		const maxValue = kind === 'hour' ? 24 : 60;
		return (value: string): string | void => {
			const parsedValue = +value;
			const newValue = inRange(parsedValue, 0, maxValue)
				? parsedValue
				: parsedValue >= maxValue
				? maxValue - 1
				: 0;

			this.updateValue(kind, newValue);
			return this.padValue(newValue);
		};
	}

	private updateValue(kind: 'hour' | 'minute' | 'second', value: number) {
		if (kind === 'hour') this.hours = value;
		if (kind === 'minute') this.minutes = value;
		if (kind === 'second') this.seconds = value;
	}

	private setTimezoneLabel() {
		this.timezoneLabel = this.localeConfigService.isLocalTimeZone
			? this.i18nService.get('datepicker_timezoneLocalUTC', { clientTimeZone: getClientTimeZoneString() })
			: this.i18nService.get('datepicker_timezoneUTC');
	}

	private setDisplayValue(date: Date) {
		const format = this.allowTimeSelection ? 'short' : 'shortDate';
		this.displayValue = this.tzDateService.format(date, format);
	}

	private setCalendarStrings() {
		this.calendarStrings = this.dateTimeDisplayService.getDateStrings(
			this.localeConfigService.selectedLocale
		);
	}

	// If UTC timezone is selected, we're showing the user what they expects, while keeping the real date in
	// local timezone, and parse it again before save.
	// If the user manually changes value, we're showing them the value they inserted and we're setting it
	// to the `value` member.
	private getHours(): number {
		return this.localeConfigService.isLocalTimeZone ? this.value.getHours() : this.value.getUTCHours();
	}

	private getMinutes(): number {
		return this.localeConfigService.isLocalTimeZone
			? this.value.getMinutes()
			: this.value.getUTCMinutes();
	}

	private getSeconds(): number {
		return this.localeConfigService.isLocalTimeZone
			? this.value.getSeconds()
			: this.value.getUTCSeconds();
	}
}
