var _a;
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, EventEmitter, OnChanges, } from '@angular/core';
import { StepStatus } from './wizard-step.model';
import { filter } from 'rxjs/operators';
import { MainAppStateService } from '../../main/services/main-app-state.service';
import { config } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
var WizardComponent = /** @class */ (function () {
    function WizardComponent(i18nService, changeDetectorRef, mainAppStateService) {
        var _this = this;
        this.i18nService = i18nService;
        this.changeDetectorRef = changeDetectorRef;
        this.currentRouteIsActive = false;
        this.showProgressIcons = false;
        this.isPanel = false;
        this.select = new EventEmitter(false);
        this.wizardDropdownOpen = false;
        mainAppStateService.state$
            .pipe(filter(function (state) {
            return _this.isNarrowLayout !== config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
        }))
            .subscribe(function (state) {
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
            _this.changeDetectorRef.markForCheck();
        });
    }
    WizardComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        if (!this.currentRouteIsActive) {
            this._currentStepId = this.currentStepId || (this.stepsData.length && this.stepsData[0].id);
            this.currentStepName = this.getStepDisplayName(this.stepsData.find(function (value) { return value.id === _this._currentStepId; }));
        }
        this.changeDetectorRef.detectChanges();
    };
    WizardComponent.prototype.selectStep = function (step, $event) {
        if (step.disabled) {
            return true;
        }
        this._currentStepId = step.id;
        this.currentStepName = this.getStepDisplayName(step);
        this.select.emit({ step: step });
        if (step.routerLink) {
            return true;
        }
        $event && $event.preventDefault();
        this.changeDetectorRef.detectChanges();
        return false;
    };
    WizardComponent.prototype.getStepClassName = function (step) {
        var className = step.disabled ? 'disabled' : '';
        if (step.className)
            className += " " + step.className;
        return className;
    };
    WizardComponent.prototype.getStepStatus = function (step) {
        if (step.id > this._currentStepId) {
            return StepStatus.next;
        }
        else if (step.id === this._currentStepId) {
            return StepStatus.current;
        }
        return StepStatus.completed;
    };
    WizardComponent.prototype.getIconForStatus = function (status) {
        return statusToIcon[status];
    };
    WizardComponent.prototype.toggleWizardDropdown = function () {
        this.wizardDropdownOpen = !this.wizardDropdownOpen;
    };
    WizardComponent.prototype.getStepDisplayName = function (_a) {
        var text = _a.text, subText = _a.subText;
        return text && subText ? text + ": " + subText : (text || subText);
    };
    return WizardComponent;
}());
export { WizardComponent };
var statusToIcon = (_a = {},
    _a[StepStatus.completed] = 'CompletedSolid',
    _a[StepStatus.current] = 'CircleFill',
    _a[StepStatus.next] = 'CircleRing',
    _a);
