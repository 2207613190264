var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AfterViewInit, OnChanges } from '@angular/core';
import { AirsEmail, AirsEntityType, EmailMetadataApiCall, } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';
import { Paris } from '@microsoft/paris';
import { combineLatest, defer, from, of, Subject } from 'rxjs';
import { catchError, distinctUntilChanged, filter, map, share, shareReplay, startWith, switchMap, takeUntil, } from 'rxjs/operators';
import { isEqual } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
import { SpinnerSize } from 'office-ui-fabric-react';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { HttpClient } from '@angular/common/http';
import { DownloadEmailPanelComponent } from '../download-email.panel.component';
import { PanelType } from '@wcd/panels';
import { EmailHeadersPanelComponent } from '../email-headers.panel.component';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { EmailDetectionTechnologiesDisplayNameService } from '../../services/email-entity-detection-technologies-display-field.service';
var LOADING_SYMBOL = Symbol();
var AirsEmailMessageDetailsComponent = /** @class */ (function () {
    function AirsEmailMessageDetailsComponent(paris, i18nService, dialogsService, http, appContextService, featuresService, threatsDisplayNameService, emailDetectionTechnologiesDisplayNameService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.http = http;
        this.appContextService = appContextService;
        this.featuresService = featuresService;
        this.threatsDisplayNameService = threatsDisplayNameService;
        this.emailDetectionTechnologiesDisplayNameService = emailDetectionTechnologiesDisplayNameService;
        this.LOADING_SYMBOL = LOADING_SYMBOL;
        this.SpinnerSize = SpinnerSize;
        this.destroy$ = new Subject();
        this.roles$ = defer(function () {
            return from(sccHostService.auth.isInRoles(['ucc:Preview///UserPermissions:MDO:microsoft.xdr/secops/rawdata/emailcontent/read', 'QuarantineViewAdmin']));
        }).pipe(map(function (_a) {
            var isInPreview = _a[0], isQuarantineViewAllowed = _a[1];
            return ({ isInPreview: isInPreview, isQuarantineViewAllowed: isQuarantineViewAllowed });
        }), shareReplay(1), takeUntil(this.destroy$));
        this.currentEmailSubject$ = new Subject();
        this.currentEmail$ = this.currentEmailSubject$.asObservable().pipe(share(), distinctUntilChanged(function (d1, d2) { return isEqual(d1, d2); }));
        this.isEmailSubmission = false;
        this.metadata$ = this.currentEmail$.pipe(switchMap(function (currentEmail) {
            if (!(currentEmail && currentEmail.networkMessageId)) {
                return of(null);
            }
            return _this.paris
                .apiCall(EmailMetadataApiCall, {
                tenantId: sccHostService.loginUser.tenantId,
                recipient: currentEmail.recipient,
                networkMessageId: currentEmail.networkMessageId,
                startTime: currentEmail.receivedTime,
                endTime: currentEmail.receivedTime,
            })
                .pipe(map(function (metadata) { return metadata && metadata.length && metadata[0]; }), startWith(LOADING_SYMBOL));
        }), catchError(function (err) { return of(null); }), shareReplay({ bufferSize: 1, refCount: true }));
        // adapted from Nibiru (MTPEmailEntityPage.tsx)
        this.emailData$ = this.currentEmail$.pipe(switchMap(function (currentEmail) {
            if (!currentEmail) {
                return of(null);
            }
            return combineLatest([
                _this.roles$,
                _this.metadata$.pipe(filter(function (metadata) { return metadata !== LOADING_SYMBOL; })),
            ]).pipe(switchMap(function (_a) {
                var roles = _a[0], metadata = _a[1];
                if (!metadata ||
                    !metadata.internetMessageId ||
                    !metadata.receivedTime ||
                    metadata.isDelivered) {
                    return of(__assign({}, roles, { quarantineMessage: null }));
                }
                // using Angular's http client because Paris doesn't pass the x-xsrf-token header
                return _this.http
                    .patch('/api/QuarantineMessage/QueryMessage', {
                    quarantineTypes: ['TransportRule', 'Bulk', 'Phish', 'Malware', 'Spam'],
                    policyTypes: [
                        'AntiMalwarePolicy',
                        'SafeAttachmentPolicy',
                        'AntiPhishPolicy',
                        'HostedContentFilterPolicy',
                        'TransportRule',
                    ],
                    messageId: metadata.internetMessageId,
                    receivedStartDateTime: new Date(metadata.receivedTime.valueOf() - 10 * 60 * 1000).toISOString(),
                    receivedEndDateTime: new Date(metadata.receivedTime.valueOf() + 10 * 60 * 1000).toISOString(),
                })
                    .pipe(catchError(function (err) { return of(null); }), map(function (quarantined) { return (__assign({}, roles, { quarantineMessage: quarantined && quarantined.length && quarantined[0] })); }));
            }), startWith(LOADING_SYMBOL), catchError(function (err) { return of(null); }));
        }));
    }
    Object.defineProperty(AirsEmailMessageDetailsComponent.prototype, "emailSubmission", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AirsEmailMessageDetailsComponent.prototype.ngAfterViewInit = function () {
        this.setEmail();
    };
    AirsEmailMessageDetailsComponent.prototype.ngOnChanges = function (changes) {
        this.setEmail();
    };
    AirsEmailMessageDetailsComponent.prototype.ngOnDestroy = function () {
        this.destroy$.next();
        this.destroy$.complete();
    };
    AirsEmailMessageDetailsComponent.prototype.setEmail = function () {
        this.isEmailSubmission =
            this.entity && this.entity.type && this.entity.type.id === AirsEntityType.SubmissionMail;
        var receivedTime = this.isEmailSubmission
            ? this.entity.timestamp
            : this.entity.receivedDate;
        this.currentEmailSubject$.next(this.entity && {
            networkMessageId: this.entity.networkMessageId,
            recipient: this.entity.recipient,
            receivedTime: receivedTime,
        });
        if (this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
            this.appContextService.isSCC &&
            receivedTime) {
            var receivedTimeStr = receivedTime.toISOString();
            var pathNames = window.location.pathname.split('/');
            var page = pathNames.length > 2 ? pathNames[1] : 'mtp';
            this.emailDetailsLink = "/emailentity?f=" + page + "&startTime=" + receivedTimeStr + "&endTime=" + receivedTimeStr + "&id=" + this.entity.networkMessageId + "&recipient=" + this.entity.recipient;
        }
        else {
            this.emailDetailsLink = null;
        }
    };
    AirsEmailMessageDetailsComponent.prototype.openDownloadEmailPanel = function (emailMetadata) {
        this.dialogsService
            .showPanel(DownloadEmailPanelComponent, {
            id: 'email-download-panel',
            type: PanelType.large,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            scrollBody: true,
        }, { emailMetadata: emailMetadata })
            .toPromise();
    };
    AirsEmailMessageDetailsComponent.prototype.goToQuarantinePage = function (internetMessageId) {
        window.open("#/quarantine?messageParams=" + internetMessageId, '_blank');
    };
    AirsEmailMessageDetailsComponent.prototype.openQuarantineEmailHeadersPanel = function (quarantineMessage) {
        this.openHeadersPanel({
            quarantineEmail: { identity: quarantineMessage && quarantineMessage.identity },
        });
    };
    AirsEmailMessageDetailsComponent.prototype.openEmailHeadersPanel = function (metadata) {
        this.openHeadersPanel({
            email: {
                internetMessageId: metadata.internetMessageId,
                mailboxId: this.entity.recipient,
            },
        });
    };
    AirsEmailMessageDetailsComponent.prototype.openHeadersPanel = function (headersObj) {
        this.dialogsService
            .showPanel(EmailHeadersPanelComponent, {
            id: 'email-headers-panel',
            type: PanelType.large,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            scrollBody: false,
        }, { headersObj: headersObj })
            .toPromise();
    };
    return AirsEmailMessageDetailsComponent;
}());
export { AirsEmailMessageDetailsComponent };
