var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Asset } from '../asset/asset.entity';
import { ParseDataQuery, FilterByNameInsteadOfSearch } from '../analyticsEndPointUtils';
import { MissingKb } from './missing-kb.entity';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
var ɵ0 = function (_, query) {
    return query.where['osVersion']
        ? "products/product/missingkbs/" + query.where['productFixId'] + "/" + query.where['osVersion'] + "/Assets"
        : "products/product/missingkbs/" + query.where['productFixId'] + "/Assets";
}, ɵ1 = function (missingKb) {
    return {
        productId: missingKb.productId,
        productFixId: missingKb.productFixId,
        osVersion: missingKb.osVersion,
    };
}, ɵ2 = function (dataQuery) {
    // when searching for missing kb assets we want to use OData filters
    dataQuery = FilterByNameInsteadOfSearch(dataQuery);
    return ParseDataQuery(dataQuery);
};
var MissingKbExposedAssetsRelationship = /** @class */ (function () {
    function MissingKbExposedAssetsRelationship() {
    }
    MissingKbExposedAssetsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: MissingKb,
            dataEntity: Asset,
            endpoint: ɵ0,
            allowedTypes: [RelationshipType.OneToMany],
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ1,
            parseDataQuery: ɵ2,
        })
    ], MissingKbExposedAssetsRelationship);
    return MissingKbExposedAssetsRelationship;
}());
export { MissingKbExposedAssetsRelationship };
var ɵ3 = function (_, query) {
    return "products/product/missingkbs/" + encodeURIComponent(query.where['productFixId']) + "/Vulnerabilities";
}, ɵ4 = function (missingKb) {
    return {
        productId: missingKb.productId,
        productFixId: missingKb.productFixId,
    };
}, ɵ5 = function (dataQuery) { return ParseDataQuery(dataQuery); };
var MissingKbRelatedVulnerabilitiesRelationship = /** @class */ (function () {
    function MissingKbRelatedVulnerabilitiesRelationship() {
    }
    MissingKbRelatedVulnerabilitiesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: MissingKb,
            dataEntity: Vulnerability,
            endpoint: ɵ3,
            allowedTypes: [RelationshipType.OneToMany],
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ4,
            parseDataQuery: ɵ5,
        })
    ], MissingKbRelatedVulnerabilitiesRelationship);
    return MissingKbRelatedVulnerabilitiesRelationship;
}());
export { MissingKbRelatedVulnerabilitiesRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
