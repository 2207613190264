var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from "@angular/core";
import { Paris } from "@microsoft/paris";
import { BaselineProfileConfigurationIds, BaselineProfileConfigurationsBaselineProfileDeviceRelationship, ResolveEntityURL, TvmEndPoint } from "@wcd/domain";
import { ConfigurationDevicesBaseComponent } from "./configuration-devices.base/configuration-devices.base.component";
import { BaselineConfigurationFieldsService } from "../../services/baseline-configuration.fields.service";
import { TvmDownloadService } from "../../../../../../../tvm/services/tvm-download.service";
import { BaselineDetectedValuesService } from '../../../../../../../tvm/services/baseline-detected-value.service';
var ProfileConfigurationDevicesComponent = /** @class */ (function (_super) {
    __extends(ProfileConfigurationDevicesComponent, _super);
    function ProfileConfigurationDevicesComponent(fieldsService, tvmDownloadService, detectedValuesService, paris) {
        return _super.call(this, fieldsService, tvmDownloadService, detectedValuesService, paris) || this;
    }
    ProfileConfigurationDevicesComponent.prototype.ngOnInit = function () {
        this.dataviewId = "baselineConfigurationDevices_" + this.profileId + "_" + this.configuration.id;
        _super.prototype.ngOnInit.call(this);
        this.sourceValue = new BaselineProfileConfigurationIds({
            id: this.configuration.id,
            configurationId: this.configuration.id,
            profileId: this.profileId
        });
        this.repository = this.paris.getRelationshipRepository(BaselineProfileConfigurationsBaselineProfileDeviceRelationship);
        this.repository.sourceItem = this.sourceValue;
        this.exportUrl = ResolveEntityURL({
            endPoint: TvmEndPoint.Analytics,
            entityModelBaseOrRelationship: BaselineProfileConfigurationsBaselineProfileDeviceRelationship,
            sourceModel: this.sourceValue,
        });
        this.exportFileName = this.profileName.split(' ').join('_') + "_" + this.configuration.id;
    };
    return ProfileConfigurationDevicesComponent;
}(ConfigurationDevicesBaseComponent));
export { ProfileConfigurationDevicesComponent };
