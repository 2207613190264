import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { EvidenceAssetTypeEntity, EvidenceAssetTypeType } from './evidence-asset-type.entity';

@ValueObject({
	singularName: 'Evidence',
	pluralName: 'Evidence',
})
export class EvidenceAsset extends ModelBase {
	@EntityField({ data: 'asset.name' })
	name: string;

	@EntityField({ data: 'asset_type' })
	assetType: EvidenceAssetTypeEntity;

	@EntityField({ data: 'asset.machine_id' })
	machineId?: string;

	@EntityField({ data: 'asset.rbac_group_id' })
	rbacGroupId?: number;

	displayName: string;

	constructor(data) {
		super(data);
		this.displayName =
			this.name ||
			(this.assetType && this.assetType.id === EvidenceAssetTypeType.Machine ? this.machineId : null);
	}
}
