import { KnownColor } from '../shared/models/colors/known-colors.models';

export enum ChartType {
	Horizontal,
	Vertical,
}

export interface ChartSingleSeriesConfiguration {
	knownColor?: KnownColor; //use this if same color is wanted for both daily and trend
	knownColorsMap?: {
		daily: KnownColor;
		trend: KnownColor;
	};
	label: string;
	priority?: number;
	machineListFilter?: string,
}

export type ChartSeriesConfiguration<T extends string> = Record<T, ChartSingleSeriesConfiguration>;
