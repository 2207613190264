import {
	ActionBaseEntity,
	ActionHistoryRelatedEntity,
	InvestigatedMachine,
	RemediationAction,
	RemediationActionEntity,
	RemediationActionType,
} from '@wcd/domain';

export class ActionHistoryConverterService {
	static getRemediationActionFromActionHistory(originalAction: ActionBaseEntity): RemediationAction {
		const entities: Array<RemediationActionEntity> =
				originalAction.relatedEntities &&
				originalAction.relatedEntities.map((_entity: ActionHistoryRelatedEntity) => {
					const entityConfig: Partial<Record<keyof RemediationActionEntity, any>> = {
						id: _entity.entityId,
						entityTypeId: _entity.entityType && _entity.entityType.id,
						created: _entity.processCreationTime,
						threatType: originalAction.threatType,
						sha1: _entity.sha1,
						path: _entity.folderPath,
						name: _entity.fileName,
						pid: _entity.processId,
						address: _entity.fileName,
						binPath: _entity.serviceBinPath || _entity.driverBinPath,
						displayName: _entity.serviceDisplayName || _entity.driverDisplayName,
						driverType: _entity.driverType,
						commandLine: _entity.methodCommandLine || _entity.processCommandLine,
						sid: _entity.sid,
						aadUserId: _entity.aadUserId,
					};
					return new RemediationActionEntity(entityConfig);
				}),
			stubActionConfig: Partial<Record<keyof RemediationAction, any>> = {
				id: originalAction.id,
				remediationActionType: originalAction.actionType,
				investigationId: originalAction.investigationId,
				isOfficeInvestigation: originalAction.isOfficeInvestigation,
				machine:
					originalAction.machine &&
					new InvestigatedMachine({
						id: originalAction.machine.machineId,
						machineId: originalAction.machine.machineId,
						name: originalAction.machine.name,
					}),
			};

		return this.getRemediationActionFromConfig(entities, originalAction.actionType, stubActionConfig);
	}

	static getRemediationActionFromConfig(
		entities: Array<RemediationActionEntity>,
		actionType: RemediationActionType,
		actionConfig: Partial<Record<keyof RemediationAction, any>>
	): RemediationAction {
		const firstEntity: RemediationActionEntity = entities && entities.length ? entities[0] : undefined;
		actionConfig.entity = firstEntity;

		if (actionType && actionType.mainEntities) {
			actionType.mainEntities.forEach(entityConfig => {
				if (entityConfig.isList) {
					actionConfig[entityConfig.fieldName] =
						entities && entities.filter(e => e.entityTypeId === entityConfig.entityTypeId);
				} else if (firstEntity && firstEntity.entityTypeId === entityConfig.entityTypeId) {
					actionConfig[entityConfig.fieldName] = firstEntity;
				}
			});
		}
		return new RemediationAction(<Record<keyof RemediationAction, any>>actionConfig);
	}
}
