import { Injectable } from '@angular/core';
import { WebContentFilteringMachineListReport } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { WebContentFilteringMachineListReportDetailsComponent } from '../components/web-content-filtering-machine-list-details.component';

@Injectable()
export class WebContentFilteringMachineListReportEntityTypeService
	implements EntityTypeService<WebContentFilteringMachineListReport> {
	readonly entityType: EntityType<WebContentFilteringMachineListReport> = {
		id: 'webContentFilteringWebMachineList',
		entity: WebContentFilteringMachineListReport,
		loadFullEntityInPanel: false,
		getEntityName: (report: WebContentFilteringMachineListReport) => report.name,
		singleEntityPanelComponentType: WebContentFilteringMachineListReportDetailsComponent,
	};
}
