import { SeverityType } from './severity-type.enum';
import { ISeverity } from './severity.interface';

export const severityValues: Array<ISeverity> = [
	{
		id: 1,
		name: 'Low', // Deprecated, should be in the app logic
		type: SeverityType.Low,
		nameI18nKey: 'alert_severity_low',
		className: 'warning', // Deprecated, should be in the app logic
		priority: 3,
		isSelectable: false,
	},
	{
		id: 2,
		name: 'Medium', // Deprecated, should be in the app logic
		type: SeverityType.Medium,
		nameI18nKey: 'alert_severity_medium',
		className: 'warning-dark', // Deprecated, should be in the app logic
		priority: 2,
		isSelectable: false,
	},
	{
		id: 4,
		name: 'High', // Deprecated, should be in the app logic
		type: SeverityType.High,
		nameI18nKey: 'alert_severity_high',
		className: 'attention', // Deprecated, should be in the app logic
		priority: 1,
		isSelectable: false,
	},
	{
		id: 32,
		name: 'Informational', // Deprecated, should be in the app logic
		type: SeverityType.Informational,
		nameI18nKey: 'alert_severity_informational',
		className: 'indicationOff', // Deprecated, should be in the app logic
		priority: 4,
		isSelectable: true,
	},
	{
		id: 64,
		name: 'Low', // Deprecated, should be in the app logic
		type: SeverityType.Low,
		nameI18nKey: 'alert_severity_low',
		className: 'lowSeverity', // Deprecated, should be in the app logic
		priority: 3,
		isSelectable: true,
	},
	{
		id: 128,
		name: 'Medium', // Deprecated, should be in the app logic
		type: SeverityType.Medium,
		nameI18nKey: 'alert_severity_medium',
		className: 'mediumSeverity', // Deprecated, should be in the app logic
		priority: 2,
		isSelectable: true,
	},
	{
		id: 256,
		name: 'High', // Deprecated, should be in the app logic
		type: SeverityType.High,
		nameI18nKey: 'alert_severity_high',
		className: 'highSeverity', // Deprecated, should be in the app logic
		priority: 1,
		isSelectable: true,
	},
];
