import { Injectable } from '@angular/core';
import { NavigationExtras, Router, UrlTree, UrlHandlingStrategy } from '@angular/router';

/**
 * Used to get router into AngularJS. Remove once upgrade is complete.
 */
@Injectable()
export class HybridRoutingService {
	constructor(private router: Router, private urlHandlingStrategy: UrlHandlingStrategy) {}

	createUrlTree(commands: any[], navigationExtras?: NavigationExtras): UrlTree {
		return this.router.createUrlTree(commands, navigationExtras);
	}

	navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigate(commands, extras);
	}

	navigateByUrl(url: string | UrlTree, extras?: NavigationExtras): Promise<boolean> {
		return this.router.navigateByUrl(url, extras);
	}

	shouldProcessUrl(url: string | UrlTree): boolean {
		const urlTree = url instanceof UrlTree ? url : this.router.parseUrl(url);
		return this.urlHandlingStrategy.shouldProcessUrl(urlTree);
	}
}
