import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertBlockingStatus', alertBlockingStatusFilter);
SevilleModule.filter('alertBlockingStatusTooltip', alertBlockingStatusTooltipFilter);

const defaultDetectionStatus = 'Detected';

interface IDetectionStatusDetails {
	name: string;
	nameI18nKey: string;
	tooltipI18nKey: string;
	iconName: string;
}

const detectionStatusDetails: { [id: string]: IDetectionStatusDetails } = {
	Detected: {
		name: 'Detected',
		nameI18nKey: 'alert.status.Detected',
		tooltipI18nKey: 'alert.status.Detected.tooltip',
		iconName: 'StatusCircleInner',
	},
	Prevented: {
		name: 'Prevented',
		nameI18nKey: 'alert.status.Prevented',
		tooltipI18nKey: 'alert.status.Prevented.tooltip',
		iconName: 'StatusCircleInner',
	},
	Blocked: {
		name: 'Blocked',
		nameI18nKey: 'alert.status.Blocked',
		tooltipI18nKey: 'alert.status.Blocked.tooltip',
		iconName: 'StatusCircleRing',
	},
};

export const getAlertBlockingStatus = alertBlockingStatusFilter();

function alertBlockingStatusFilter() {
	return function(input) {
		return getDisplayName(input);
	};
}

function getDisplayName(input: string): string {
	if (input in detectionStatusDetails) {
		return detectionStatusDetails[input].name;
	}
	return detectionStatusDetails[defaultDetectionStatus].name;
}

export const getAlertBlockingStatusI18nName = alertBlockingStatusI18nNameFilter();

function alertBlockingStatusI18nNameFilter() {
	return function(input) {
		return getI18nName(input);
	};
}

function getI18nName(input: string): string {
	if (input in detectionStatusDetails) {
		return detectionStatusDetails[input].nameI18nKey;
	}
	return detectionStatusDetails[defaultDetectionStatus].nameI18nKey;
}

export const getBlockingStatusTooltip = alertBlockingStatusTooltipFilter();

function alertBlockingStatusTooltipFilter() {
	return function(input) {
		return getTooltip(input);
	};
}

function getTooltip(input: string): string {
	if (input in detectionStatusDetails) {
		return detectionStatusDetails[input].tooltipI18nKey;
	}
	return detectionStatusDetails[defaultDetectionStatus].tooltipI18nKey;
}

export const getDetectionStatusIcon = detectionStatusIconFilter();

function detectionStatusIconFilter() {
	return function(input) {
		return getIcon(input);
	};
}

function getIcon(input: string): string {
	if (input in detectionStatusDetails) {
		return detectionStatusDetails[input].iconName;
	}
	return detectionStatusDetails[defaultDetectionStatus].iconName;
}
