import { Dictionary } from '@wcd/config';
import { AlertStatus } from '@wcd/domain';
var ɵ0 = function (statusConfig) { return new AlertStatus(statusConfig); };
var ALERT_STATUSES = Dictionary.fromList([
    { id: 1, name: 'Unspecified', type: 'Unspecified' },
    {
        id: 2,
        name: 'New',
        type: 'New',
        title: 'New Alerts',
        isSelectable: true,
        allowClassification: true,
    },
    {
        id: 4,
        name: 'In Progress',
        type: 'InProgress',
        title: 'Alerts In Progress',
        isSelectable: true,
        allowClassification: true,
    },
    {
        id: 8,
        name: 'Resolved',
        type: 'Resolved',
        title: 'Resolved Alerts',
        isSelectable: true,
        allowClassification: true,
    },
    { id: 16, name: 'Hidden', type: 'Hidden', isHidden: true },
    { id: 32, name: 'Resolved [Valid]', type: 'Resolved [Valid]' },
    { id: 64, name: 'Resolved [Valid - Allowed]', type: 'Resolved [Valid - Allowed]' },
    { id: 128, name: 'Resolved [False Alarm]', type: 'Resolved [False Alarm]' },
    { id: 256, name: 'Multiple', type: 'Multiple' },
].map(ɵ0), 'id');
var AlertStatusesService = /** @class */ (function () {
    function AlertStatusesService() {
    }
    Object.defineProperty(AlertStatusesService, "selectableStatuses", {
        get: function () {
            return ALERT_STATUSES.toArray().filter(function (status) { return status.isSelectable; });
        },
        enumerable: true,
        configurable: true
    });
    AlertStatusesService.getAlertStatusById = function (statusId) {
        return ALERT_STATUSES.get(statusId);
    };
    AlertStatusesService.getAlertStatusByType = function (statusType) {
        return ALERT_STATUSES.find(function (status) { return status.type === statusType; });
    };
    return AlertStatusesService;
}());
export { AlertStatusesService };
export { ɵ0 };
