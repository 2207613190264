import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('severity', statusFilter);

statusFilter.$inject = ['$log'];

function statusFilter($log) {
	return function(input) {
		if (!input) {
			return '';
		}

		switch (input) {
			case 32:
				return 'Informational';
			case 1:
			case 64:
				return 'Low';
			case 2:
			case 128:
				return 'Medium';
			case 4:
			case 256:
				return 'High';
			default:
				$log.error('Severity filter - unknown value - ' + input);
				return 'Unknown';
		}
	};
}
