import { Injectable } from '@angular/core';
import {
	AggregatedIncidentLinkedBy,
	File,
	IncidentLinkedByEntity,
	Ip,
	LegacyUser,
	Machine,
	Mailbox,
	MailCluster,
	MailMessage,
	Url,
  IncidentLinkedByEntityType,
	CommandLine,
	RegistryKey,
	RegistryValue,
} from '@wcd/domain';
import { Observable } from 'rxjs';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import { LinkedByEntityTypes } from './alert-incident.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';

@Injectable()
export class AlertLinkedEntityResolverService {
	constructor(
		private readonly paris: Paris,
		private readonly globalEntityTypesService: GlobalEntityTypesService
	) {}

	getEntityById(type: IncidentLinkedByEntityType, id: string): Observable<EntityModelBase> {
		return this.paris.getItemById(
			AlertLinkedEntityResolverService.getEntityType(type),
			id,
		);
	}

	getEntity(linkedByItem: AggregatedIncidentLinkedBy): Observable<EntityModelBase> {
		let entity: Observable<EntityModelBase>;
		switch (linkedByItem.sourceEntity.type) {
			case LinkedByEntityTypes.Url:
				entity = this.paris.createItem<Url>(Url, {
					Url: linkedByItem.sourceEntity.id,
					UrlPageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.File:
				entity = this.paris.createItem<File>(File, {
					Sha1: linkedByItem.sourceEntity.id,
					FileName: linkedByItem.sourceEntity.name,
					FilePageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.Mailbox:
				entity = this.paris.createItem<Mailbox>(Mailbox, {
					Upn: linkedByItem.sourceEntity.id,
					DisplayName: linkedByItem.sourceEntity.name,
					MailboxPageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.MailCluster:
				entity = this.paris.createItem<MailCluster>(MailCluster, {
					Id: linkedByItem.sourceEntity.id,
					Name: linkedByItem.sourceEntity.name,
					MailClusterPageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.MailMessage:
				entity = this.paris.createItem<MailMessage>(MailMessage, {
					Id: linkedByItem.sourceEntity.id,
					Name: linkedByItem.sourceEntity.name,
					MailMessagePageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.User:
				entity = linkedByItem.sourceEntity.name != linkedByItem.sourceEntity.id ?
					this.paris.createItem<LegacyUser>(LegacyUser, {
						UserSid: linkedByItem.sourceEntity.id,
						AccountName: linkedByItem.sourceEntity.name,
						AlertProduct: linkedByItem.sourceEntity.entitySource
					}) :
					this.getEntityById(linkedByItem.sourceEntity.type, linkedByItem.sourceEntity.id);
				break;
			case LinkedByEntityTypes.Machine:
				entity = linkedByItem.sourceEntity.name != linkedByItem.sourceEntity.id ?
					this.paris.createItem<Machine>(Machine, {
						Id: linkedByItem.sourceEntity.id,
						Name: linkedByItem.sourceEntity.name,
						AlertProduct: linkedByItem.sourceEntity.entitySource
					}) :
					this.getEntityById(linkedByItem.sourceEntity.type, linkedByItem.sourceEntity.id);
				break;
			case LinkedByEntityTypes.Ip:
				entity = this.paris.createItem<Ip>(Ip, {
					id: linkedByItem.sourceEntity.id,
					IPAddress: linkedByItem.sourceEntity.name,
					IpPageUrl: linkedByItem.sourceEntity.entityPageUrl,
					AlertProduct: linkedByItem.sourceEntity.entitySource
				});
				break;
			case LinkedByEntityTypes.CommandLine:
				entity = this.paris.createItem<CommandLine>(CommandLine, {
					Id: linkedByItem.sourceEntity.id,
					Name: linkedByItem.sourceEntity.name,
					EntityPageUrl: linkedByItem.sourceEntity.entityPageUrl,
					EvidenceId: linkedByItem.sourceEntity.evidenceId,
					AlertProduct: linkedByItem.sourceEntity.entitySource,
				});
				break;
			case LinkedByEntityTypes.RegistryKey:
				entity = this.paris.createItem<RegistryKey>(RegistryKey, {
					Id: linkedByItem.sourceEntity.name,
					Name: linkedByItem.sourceEntity.name,
					EvidenceId: linkedByItem.sourceEntity.evidenceId,
				});
				break;
			case LinkedByEntityTypes.RegistryValue:
				entity = this.paris.createItem<RegistryValue>(RegistryValue, {
					Id: linkedByItem.sourceEntity.name,
					Name: linkedByItem.sourceEntity.name,
					EvidenceId: linkedByItem.sourceEntity.evidenceId,
				});
				break;
			default:
				entity = this.getEntityById(linkedByItem.sourceEntity.type, linkedByItem.sourceEntity.id);
				break;
		}

		return entity;
	}

	getAppEntityType(modelType: IncidentLinkedByEntity['type']) {
		const entityModelType = AlertLinkedEntityResolverService.getEntityType(modelType);
		if (!entityModelType) {
			return null;
		}

		return this.globalEntityTypesService.getEntityType(entityModelType);
	}

	private static getEntityType(modelType: IncidentLinkedByEntity['type']): DataEntityType<EntityModelBase> {
		switch (modelType) {
			case LinkedByEntityTypes.File:
				return File;
			case LinkedByEntityTypes.Machine:
				return Machine;
			case LinkedByEntityTypes.User:
				return LegacyUser;
			case LinkedByEntityTypes.Url:
				return Url;
			case LinkedByEntityTypes.Ip:
				return Ip;
			case LinkedByEntityTypes.Mailbox:
				return Mailbox;
			case LinkedByEntityTypes.MailCluster:
				return MailCluster;
			case LinkedByEntityTypes.MailMessage:
				return MailMessage;
			case LinkedByEntityTypes.CommandLine:
				return CommandLine;
			case LinkedByEntityTypes.RegistryKey:
				return RegistryKey;
			case LinkedByEntityTypes.RegistryValue:
				return RegistryValue;
			default:
				return null;
		}
	}
}
