
		<wcd-panel (close)="destroy()" (save)="closePanel()" [settings]="settings">
			<form
				#partnerRecommendationForm="ngForm"
				class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical"
			>
				<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-all">
					<div class="subtle wcd-margin-large-bottom">
						{{
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.helpKey'
								| i18n
						}}
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-required">
						<label for="partnerName">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerName'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="partnerName"
							class="form-control wcd-padding-small-all wcd-full-width wcd-no-resize"
							required
							name="partnerName"
							id="partnerName"
							(keydown.enter)="$event.preventDefault()"
						/>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top wcd-required">
						<label for="partnerWebsite">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerWebsite'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="partnerWebsite"
							class="form-control wcd-padding-small-all wcd-full-width"
							required
							name="partnerWebsite"
							id="partnerWebsite"
							[pattern]="RegExpService.urlOrDomain"
							(keydown.enter)="$event.preventDefault()"
						/>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top">
						<label for="email">
							{{
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.title'
									| i18n
							}}
						</label>
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<input
							type="text"
							[(ngModel)]="email"
							class="form-control wcd-padding-small-all wcd-full-width wcd-margin-small-bottom"
							[placeholder]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.placeHolder'
									| i18n
							"
							name="email"
							id="email"
							validateRegExp="email"
							[disabled]="!includeEmail"
							(keydown.enter)="$event.preventDefault()"
						/>
						<wcd-checkbox
							[(ngModel)]="includeEmail"
							(ngModelChange)="setEmail()"
							name="recommendationIncludeEmail"
							[label]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.email.includeEmail'
									| i18n
							"
							></wcd-checkbox>
					</div>
					<div class="wcd-margin-xsmall-bottom wcd-margin-large-top wcd-required">
						{{
							'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.title'
								| i18n
						}}
					</div>
					<div class="wcd-margin-xsmall-bottom">
						<textarea
							class="form-control comment-input wcd-full-width wcd-margin-large-bottom"
							[placeholder]="
								'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.placeHolder'
									| i18n
							"
							maxlength="1000"
							required
							rows="5"
							[(ngModel)]="partnerDescription"
							name="partnerDescription"
							[attr.aria-label]="'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.partnerDescription.title' | i18n "
						></textarea>
						<div class="wcd-flex-justify-end-horizontal">
							<markdown [data]="'common.privacyStatement' | i18n"></markdown>
						</div>
					</div>
				</div>
				<footer class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
					<fab-primary-button
						[disabled]="!isValid || isSaving"
						(onClick)="submit()"
						className="wcd-margin-small-right"
						data-track-type="Button"
						[text]="
							(isSaving
								? 'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submitting'
								: 'endpointManagement.nonWindows.applications.partnersStore.recommendation.recommend.submit')
								| i18n
						"
						type="submit"
					>
						<fab-spinner
							*ngIf="isSaving"
							[size]="SpinnerSize.small"
							contentClass="wcd-margin-xsmall-right"
						>
						</fab-spinner>
					</fab-primary-button>
					<fab-default-button
						(onClick)="closePanel()"
						data-track-type="Button"
						[text]="'buttons.cancel' | i18n"
					>
					</fab-default-button>
				</footer>
			</form>
		</wcd-panel>
	