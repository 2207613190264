<!-- tslint:disable:template-accessibility-label-for -->
<div class="datepicker" [ngClass]="{ open: showDialog }">
	<label [attr.id]="datePickerId" hidden >{{ariaLabel + ' ' + dateAriaLabel}}</label>
	<input type="text"
		   [ngModel]="displayValue"
		   class="datepicker-input"
		   (keydown.enter)="toggleDialog()"
		   (keydown.esc)="hideDialogEsc($event)"
		   (click)="toggleDialog()"
		   role="button"
		   [focusOn]="!showDialog"
		   placeholder="({{ 'common.datepicker.placeholder' | i18n}})"
		   [attr.aria-describedby]="datePickerId"
		   aria-haspopup="dialog"
		   readonly
		   aria-readonly="false"/>
		<div class="datepicker-wrapper"
		   [ngClass]="{ open: showDialog, 'datepicker-dialog-wrapper': !showInPanel, 'datepicker-inline-wrapper': showInPanel }"
		   (click)="onWrapperClick($event)"
		   (keydown.esc)="hideDialogEsc($event)"
		   >
		   <div class="datepicker-content" role="dialog" [ngClass]="{'datepicker-dialog': !showInPanel}"
			 [ngStyle]="{ top: dialogPosition && dialogPosition.top + 'px', left: dialogPosition && dialogPosition.left + 'px' }">
			<div *ngIf="currentMonth" cdkTrapFocus>
				<header class="datepicker-header"
					(keydown.arrowleft)="prevMonth()"
				 	(keydown.arrowright)="nextMonth()"
					 [attr.aria-label]="'common.datepicker.instructions' | i18n"
					 tabindex="0"
					 [focusOn]="showDialog"
				 	>
					<button class="datepicker-prevMonth" (click)="prevMonth()" (keydown.enter)="prevMonth()" type="button" tabindex="0"  aria-label="previous month">
						 <wcd-shared-icon iconName="chevrons.left"> </wcd-shared-icon >
					</button>
					<button class="datepicker-nextMonth" (click)="nextMonth()" (keydown.enter)="nextMonth()" type="button" tabindex="0" aria-label="next month">
						 <wcd-shared-icon iconName="chevrons.right"> </wcd-shared-icon >
					</button>
					<h4 class="datepicker-title">
						<span class="wcd-block datepicker-year">{{currentMonth.year}}</span>
						<span class="wcd-block datepicker-month">{{currentMonth.name}}</span>
					</h4>
				</header>
				<div class="datepicker-body">
					<table class="datepicker-calendar" cellspacing="0" tabindex="0" (focus)="setDaysFocus($event)"
						[attr.aria-label]="currentMonth.name + ' ' + ('datetime.month.singular.value' | i18n)">
						<thead>
						<tr>
							<th *ngFor="let day of days">{{day}}</th>
						</tr>
						</thead>
						<tbody>
						<tr *ngFor="let week of currentMonth.weeks; let iWeek = index">
							<td *ngIf="week.padLeft" [attr.colspan]="week.padLeft" aria-hidden="true"></td>
							<td *ngFor="let day of week.days ; let iDay = index"
								(click)="selectDate(day.date, $event)"
								[attr.tabindex]="day.isFocused ? 0 : -1"
								[focusOn]="day.isFocused"
								(keydown)="setDayFocus(iDay, iWeek, $event)"
								(keydown.enter)="selectDate(day.date)"
								[ngClass]="{ today: day.isToday, selected: day.equals(selectedDate), disabled: isDayDisabled(day.date) }"
								[attr.aria-label]="(day.date | date:'fullDate') + (day.equals(selectedDate)? ' ' + ('datepicker_select_date' | i18n): '')"
								>
								<span aria-hidden="true">{{day.displayDate}}</span></td>
							<td *ngIf="week.padRight" [attr.colspan]="week.padRight" aria-hidden="true"></td>
						</tr>
						</tbody>
					</table>
					<div [hidden]="!allowExactTimeSelection">
						<div class="wcd-flex wcd-padding-small-top">
							 <wcd-shared-icon [iconName]="'clock'" class="large-icon datepicker-clock-icon"> </wcd-shared-icon >
							<div class="datepicker-time">
								<input
									aria-label="hours"
									class="datepicker-time-field"
									#hours
									type="number"
									min="0"
									max="23"
									[ngModel]="getDisplayHours() | pad:2:'0':'start'"
									(ngModelChange)="setTime($event)"
									[attr.aria-label]="'datetime.hour.plural.value' | i18n"
									aria-valuemin="0"
									aria-valuemax="23"
									tabindex="0"
								/>
								<span>:</span>
								<input
									aria-label="minutes"
									#minutes
									class="datepicker-time-field"
									type="number"
									min="0"
									max="59"
									[ngModel]="getDisplayMinutes() | pad:2:'0':'start'"
									(ngModelChange)="setTime(null, $event)"
									[attr.aria-label]="'datetime.minute.plural.value' | i18n"
									aria-valuemin="0"
									aria-valuemax="59"
									tabindex="0"
								/>
								<span class="datepicker-input-sep">:</span>
								<input
									aria-label="seconds"
									class="datepicker-time-field"
									#seconds
									type="number"
									min="0"
									max="59"
									[ngModel]="selectedDate.getSeconds() | pad:2:'0':'start'"
									(ngModelChange)="setTime(null, null, $event)"
									[attr.aria-label]="'datetime.second.plural.value' | i18n"
									aria-valuemin="0"
									aria-valuemax="59"
									tabindex="0"
								/>
							</div>
						</div>
						<div class="subtle wcd-flex-center-all datepicker-timezone-label">{{timezoneLabel}}</div>
						<div class="error-message"
							 *ngIf="!isTimeValid(hours.value, minutes.value, seconds.value)">
							 <wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon >
							{{'datepicker_invalid_time_format' | i18n}}
						</div>
					</div>
				</div>
				<div class="datepicker-actions">
					<button type="button" class="datepicker-close" (click)="revert()" tabindex="0" aria-label="Cancel">{{'cancel' | i18n }}</button>
					<button type="button"
							tabindex="0"
							class="datepicker-save"
							[disabled]="!isTimeValid(hours.value, minutes.value, seconds.value)"
							(click)="save()">{{'ok' | i18n}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>
