import { ApiCall, ApiCallModel, EntityField, ValueObject, ModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { WebThreatProtectionReportOptions } from './web-threat-protection-options.interface';

@ValueObject({
	singularName: 'Web threat protection',
	pluralName: 'Web threat protection',
	readonly: true,
})
export class WebThreatStatusSummary extends ModelBase {
	@EntityField({ data: 'Phishing' })
	readonly phishing: number;

	@EntityField({ data: 'Malicious' })
	readonly malicious: number;

	@EntityField({ data: 'CustomIndicator' })
	readonly customIndicator: number;

	@EntityField({ data: 'TotalThreats' })
	readonly totalThreats: number;

	@EntityField({ data: 'Unknown' })
	readonly unknown: number;

	@EntityField({ data: 'UpdateTime' })
	readonly updateTime: Date;
}

@ApiCall({
	name: 'Get web threat health status summary',
	endpoint: 'webThreats/reports/webThreatSummary',
	method: 'GET',
	type: WebThreatStatusSummary,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
})
export class WebThreatStatusSummaryApiCall extends ApiCallModel<
	WebThreatStatusSummary,
	WebThreatProtectionReportOptions
> {}
