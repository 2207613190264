
		<div class="wcd-snackbar__container" [class.messageVisitable]="currentSnackbar">
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="!currentSnackbar || currentSnackbar.type == MessageBarType.info"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.info"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.error"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.error"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.blocked"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.blocked"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="
					currentSnackbar && currentSnackbar.type == MessageBarType.severeWarning
				"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.severeWarning"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.success"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.success"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<div
				class="wcd-snackbar__snackbar"
				data-track-component="Snackbars"
				[class.is-removing]="isRemovingSnackbar"
				[class.showMessageBar]="currentSnackbar && currentSnackbar.type == MessageBarType.warning"
			>
				<fab-message-bar
					role="none"
					aria-hidden="true"
					[messageBarType]="MessageBarType.warning"
					[renderActions]="actions"
					[isMultiline]="false"
					[theme]="messageBarTheme"
				>
					{{ currentSnackbar && currentSnackbar.text }}
				</fab-message-bar>
			</div>
			<ng-template #actions>
				<fab-messagebar-button
					*ngIf="currentSnackbar?.method"
					data-track-id="ApplyMethod"
					data-track-type="Button"
					[text]="currentSnackbar && currentSnackbar.method.text"
					[iconProps]="
						currentSnackbar &&
						currentSnackbar.method.icon && { iconName: currentSnackbar.method.icon }
					"
					(onClick)="applyMethod()"
				></fab-messagebar-button>
			</ng-template>
		</div>
	