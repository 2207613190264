/**
 * Manages SCC router:
 * Redirects
 * Current state
 */
export class SccStateSvc {
	constructor(private sccState) {}

	go(state: string, params?: object, options?: object): Promise<any> {
		return this.sccState.go(state, params, options);
	}

	href(state: string, params?: object, options?: object): string {
		return this.sccState.href(state, params, options);
	}

	get current(): any {
		return this.sccState.current;
	}

	transitionTo(url: string, params?: object, options?: object): Promise<any> {
		return this.sccState.transitionTo(url, params, options);
	}

	get transitionService(): any {
		return this.sccState.router.transitionService;
	}

	get params(): any {
		return this.sccState.params;
	}
}
