import { SevilleModule } from '../../seville/seville.module';

SevilleModule.controller('seville.threatintel.searchresults', searchResultsController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		$stateProvider.state('search', {
			url: '/search',
			title: 'Search results',
			data: { results: null },
			views: {
				main: {
					template: '<div ui-view></div>',
					controller: 'seville.threatintel.searchresults',
					controllerAs: 'searchresults',
				},
			},
		});
	},
]);

searchResultsController.$inject = ['$log', '$state'];

function searchResultsController($log, $state) {}
