import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TaskArgs } from '../common/taskArgs/task-args.value-object';
import { RemediationTaskStatus } from './remediation-task-status.value-object';
import {
	TvmRemediationSharedEntityConfigurations,
	ParseRemediationDataQuery,
} from '../remediationEndPointUtils';
import { RbacGroups } from './rbac-groups.value-object';
import { UserInfo } from '../common/remediation/requester.value-object';
import { Mitigation } from '../common/remediation/remediation-mitigation.value-object';
import { RemediationTaskPriority } from './remediation-task-priority.enum';
import { RemediationTaskCompletionMethod } from './remediation-task-completion-method.enum';
import { RemediationTicket } from './remediation-task-ticket.value-object';

declare const moment: typeof import('moment');

@Entity({
	...TvmRemediationSharedEntityConfigurations,
	singularName: 'Remediation activity',
	pluralName: 'Remediation activities',
	endpoint: 'remediationTasks',
	parseItemQuery: id => {
		return `remediationTasks/${id}`;
	},
	parseDataQuery: dataQuery => ParseRemediationDataQuery(dataQuery),
})
export class RemediationTask extends EntityModelBase<string> {
	@EntityField({
		data: 'title',
	})
	readonly name: string;

	@EntityField({
		data: 'description',
	})
	readonly description: string;

	@EntityField({
		data: 'mitigationDescription',
	})
	readonly mitigationDescription: string;

	@EntityField({
		data: 'relatedComponent',
	})
	readonly relatedComponent: string;

	@EntityField({
		data: 'targetAssets',
	})
	readonly targetAssets: number;

	@EntityField({
		data: 'potentialRisk',
	})
	readonly potentialRisk: string;

	@EntityField({
		data: 'recommendationDescription',
	})
	readonly recommendationDescription: string;

	@EntityField({
		data: 'fixedAssets',
	})
	readonly fixedAssets?: number;

	@EntityField({
		data: 'dueOn',
	})
	readonly dueDate: Date;

	@EntityField({
		data: 'taskArgs',
	})
	readonly taskArgs: TaskArgs;

	@EntityField({
		data: 'requesterNotes',
	})
	readonly requesterNotes?: string;

	@EntityField({
		data: 'createdOn',
		serialize: false,
	})
	readonly creationTime?: Date;

	@EntityField({
		data: 'status',
	})
	readonly status?: RemediationTaskStatus;

	@EntityField({
		data: 'ticket',
	})
	readonly ticket?: RemediationTicket;

	@EntityField({
		data: 'originalRbacGroupIds',
	})
	readonly originalRbacGroupIds?: RbacGroups;

	@EntityField({
		data: 'displayedRbacGroupIds',
	})
	readonly displayedRbacGroupIds?: RbacGroups;

	@EntityField({
		data: 'requester',
	})
	readonly requester?: UserInfo;

	@EntityField({
		data: 'completer',
	})
	readonly completer?: UserInfo;

	@EntityField()
	readonly completionMethod?: RemediationTaskCompletionMethod

	@EntityField({
		data: 'priority',
		defaultValue: RemediationTaskPriority.None,
		parse: priority => RemediationTaskPriority[priority],
	})
	readonly priority: RemediationTaskPriority;
}
