import { ModuleWithProviders, NgModule } from '@angular/core';
import { RbacControlDirective } from './directives/rbac-control.directive';
import { RbacControlService } from './services/rbac-control.service';
import { RbacGroupGuard } from './services/rbac-group-guard.service';
import { RbacService } from './services/rbac.service';

@NgModule({
	declarations: [RbacControlDirective],
	exports: [RbacControlDirective],
})
export class RbacModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: RbacModule,
			providers: [RbacService, RbacControlService, RbacGroupGuard],
		};
	}
}
