<div *ngIf="loaded; else loading">
	<wcd-collapsible-section
		*ngIf="systemData || biosData || processorData"
		label="{{ 'tvm_hardware_and_firmware_section_title' | i18n }}"
	>
		<dl>
				<dt>{{ 'tvm_hardware_and_firmware_system_model_title' | i18n }}</dt>
				<dd>{{ systemText }}</dd>

				<dt>{{ 'tvm_hardware_and_firmware_processor_model_title' | i18n }}</dt>
				<dd>{{ processorText }}</dd>

				<dt>{{ 'tvm_hardware_and_firmware_bios_title' | i18n }}</dt>
				<dd>{{ biosText }}</dd>

			<a tabindex="0"
				role="button"
				data-track-id="OpenSeeAllDetails"
				data-track-type="Navigation"
				(keydown.enter)="openSeeAllDetails()"
				(click)="openSeeAllDetails()">
				{{'tvm_hardware_and_firmware_see_all_details' | i18n}}
			</a>
		</dl>
	</wcd-collapsible-section>
</div>

<ng-template #loading>
	<fab-spinner
		[size]="SpinnerSize.large"
		[label]="'common_loading' | i18n"
		labelPosition="right"
	></fab-spinner>
</ng-template>
