/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i3 from "../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i4 from "../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i5 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "./alert-severity-incident-summary.title.component";
var styles_AlertSeverityIncidentSummaryTitleComponent = [];
var RenderType_AlertSeverityIncidentSummaryTitleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertSeverityIncidentSummaryTitleComponent, data: {} });
export { RenderType_AlertSeverityIncidentSummaryTitleComponent as RenderType_AlertSeverityIncidentSummaryTitleComponent };
function View_AlertSeverityIncidentSummaryTitleComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(1, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 1, 0, currVal_0); }, null); }
function View_AlertSeverityIncidentSummaryTitleComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "h4", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 16777216, null, null, 1, null, null, null, null, null, null, null)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 4, 0, currVal_0); }, null); }
function View_AlertSeverityIncidentSummaryTitleComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertSeverityIncidentSummaryTitleComponent_2)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(0, [["title", 2]], null, 0, null, View_AlertSeverityIncidentSummaryTitleComponent_3)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.asText; var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_AlertSeverityIncidentSummaryTitleComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 3, "div", [["class", "wcd-inline-block bold wcd-font-size-base wcd-padding-mediumSmall-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\n\t\t\t\t", "\n\t\t\t\t", "\n\t\t\t"])), i0.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i0.ChangeDetectorRef]]), i0.ɵppd(4, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "wcd-inline-block bold wcd-font-size-base wcd-padding-mediumSmall-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(7, null, ["\n\t\t\t\t", "\n\t\t\t\t", "\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(8, 1), i0.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i0.ChangeDetectorRef]]), i0.ɵppd(10, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.alertsSeveritySummary.alertsCount)); var currVal_1 = i0.ɵunv(_v, 2, 1, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), ("reports.widgets.alertsSummary.activeAlerts." + ((_co.alertsSeveritySummary.alertsCount === 1) ? "singular" : "plural")))); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = i0.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i0.ɵnov(_v.parent, 0), "reports.widgets.alertsSummary.in")); var currVal_3 = i0.ɵunv(_v, 7, 1, i0.ɵnov(_v, 9).transform(_co.alertsSeveritySummary.incidentsCount)); var currVal_4 = i0.ɵunv(_v, 7, 2, _ck(_v, 10, 0, i0.ɵnov(_v.parent, 0), ("reports.widgets.alertsSummary.incidents." + ((_co.alertsSeveritySummary.incidentsCount === 1) ? "singular" : "plural")))); _ck(_v, 7, 0, currVal_2, currVal_3, currVal_4); }); }
export function View_AlertSeverityIncidentSummaryTitleComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.I18nPipe, [i6.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertSeverityIncidentSummaryTitleComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["text", 2]], null, 0, null, View_AlertSeverityIncidentSummaryTitleComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.alertsSeveritySummary == null) ? null : _co.alertsSeveritySummary.alertsCount); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_AlertSeverityIncidentSummaryTitleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alerts-severity-incident-summary-title", [], null, null, null, View_AlertSeverityIncidentSummaryTitleComponent_0, RenderType_AlertSeverityIncidentSummaryTitleComponent)), i0.ɵdid(1, 49152, null, 0, i7.AlertSeverityIncidentSummaryTitleComponent, [], null, null)], null, null); }
var AlertSeverityIncidentSummaryTitleComponentNgFactory = i0.ɵccf("alerts-severity-incident-summary-title", i7.AlertSeverityIncidentSummaryTitleComponent, View_AlertSeverityIncidentSummaryTitleComponent_Host_0, { alertsSeveritySummary: "alertsSeveritySummary", asText: "asText" }, {}, []);
export { AlertSeverityIncidentSummaryTitleComponentNgFactory as AlertSeverityIncidentSummaryTitleComponentNgFactory };
