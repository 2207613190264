import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';
import { FilesBackendService } from '../../../../@entities/files/services/files.backend.service';
import { FileProtectionInfo, File } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { RegExpService } from '@wcd/shared';

SevilleModule.component('sidePaneFile', {
	controllerAs: 'vm',
	bindings: {
		sha1: '=?',
		sha: '=?',
		filename: '=',
		hideIndicator: '=',
	},
	template: `
	<span class="side-pane-indicator-wrapper"
		ng-class="{ selected: vm.isSelectedInOldPane || vm.isSelectedInNewPane }"
		data-track-id="sidePaneButtonFile"
		data-track-type="SidePaneToggleButton"
		role="button"
		aria-label="Open file side pane"
		tabindex="0">
		<i class="ms-Icon pointer side-pane-indicator-icon"
			ng-click="vm.toggleSidePane($event)" ng-if="!vm.hideIndicator"></i>
	</span>
`,
	controller: sidePaneFileController,
});

sidePaneFileController.$inject = [
	'sidePaneService',
	'$rootScope',
	'$log',
	'demoTenantResolverService',
	'sidePaneDataProviderService',
	'appInsights',
	'filesBackendService',
	'featuresService',
	'entityPanelsService',
];

function sidePaneFileController(
	sidePaneService,
	$rootScope,
	$log,
	demoTenantResolverService,
	sidePaneDataProviderService,
	appInsights: AppInsightsService,
	filesBackendService: FilesBackendService,
	featuresService: FeaturesService,
	entityPanelsService: EntityPanelsService
) {
	var vm = this;


	this.$onInit = () => {
		vm.sha = vm.sha1 || vm.sha;
		vm.key = vm.sha;
		vm.isSelectedInOldPane = false;
		vm.isSelectedInNewPane = false;

		if (!vm.hideIndicator) {
			this.oldPaneUpdateUnsubscriber = $rootScope.$on('sidePane:paneUpdated', (event, sidePane) => {
				vm.isSelectedInOldPane = sidePaneService.isSelected(vm.key);
			});

			if (featuresService.isEnabled(Feature.UpgradeFileSidePane)) {
				this.currentlyDisplayedFilesSubscription = entityPanelsService
					.getCurrentlyDisplayedItems(File)
					.subscribe(displayedFiles => {
						const wasSelected: boolean = vm.isSelectedInNewPane;
						vm.isSelectedInNewPane =
							displayedFiles && displayedFiles.some(file => file.id === vm.key);
						if (vm.isSelectedInNewPane && !wasSelected) {
							// close old side pane
							sidePaneService.close();
						}
					});
			}
		}
	}

	this.$onDestroy = function() {
		this.oldPaneUpdateUnsubscriber && this.oldPaneUpdateUnsubscriber();

		this.currentlyDisplayedFilesSubscription && this.currentlyDisplayedFilesSubscription.unsubscribe();
	};

	this.toggleSidePane = function($event) {
		$event.stopPropagation();

		if (this.isSelectedInOldPane) {
			sidePaneService.close();
		} else if (this.isSelectedInNewPane) {
			entityPanelsService.closeEntityPanel(File);
		} else {
			if (featuresService.isEnabled(Feature.UpgradeFileSidePane)) {
				this.toggleNewPane();
			} else {
				this.toggleOldPane();
			}
		}
	};

	this.toggleNewPane = function() {
		const sha1 = RegExpService.sha1.test(vm.sha) ? vm.sha : null;
		const file: File = new File({ id: vm.sha, sha1: sha1, fileName: vm.filename });
		entityPanelsService.showEntity(File, file);
	};

	this.toggleOldPane = function() {
		var detectionOperations = 2;
		vm.instance = sidePaneService.open({
			key: vm.key,
			title: vm.filename || 'File',
			type: 'entity',
			icon: 'icon-Page',
			link: {
				text: 'Go to file page',
				state: 'file',
				params: { id: vm.sha },
			},
			sections: [
				{
					title: 'File details',
					order: 1,
				},
				{
					title: 'Detections',
					order: 1,
				},
				{
					title: 'Observed worldwide',
					order: 2,
				},
				{
					title: 'Observed in organization',
					subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
					order: 3,
				},
			],
		});

		function checkIfDetectionLoadingIsDone() {
			detectionOperations--;
			if (detectionOperations <= 0) {
				vm.instance.setSectionLoading('Detections', false);
			}
		}

		function loadProfile() {
			sidePaneDataProviderService.loadFileProfile(vm.sha, vm.instance).then(
				function(response) {
					if (response.error) {
						vm.instance.setError(response.error);
					}

					vm.profile = response.fileProfile;
					vm.instance.setSection('File details', response.section);
					vm.instance.setSectionLoading('File details', false);
					vm.instance.setLoading(false);

					sidePaneDataProviderService.loadVirusTotalData(vm.sha, vm.instance, vm.profile).then(
						function(vtData) {
							vm.instance.setSection('Detections', vtData.section);
							checkIfDetectionLoadingIsDone();
						},
						function(vtData) {
							if (vtData.error) {
								vm.instance.setSectionError('Detections', vtData.error);
							} else {
								vm.instance.setSectionError('Detections', 'Data could not be retrieved');
							}
						}
					);

					sidePaneDataProviderService.loadFileStats(vm.sha, vm.instance, vm.profile).then(
						function(stats) {
							vm.instance.setSection('Observed worldwide', stats['Observed worldwide']);
							vm.instance.setSection(
								'Observed in organization',
								stats['Observed in organization']
							);
							vm.instance.setSectionLoading('Observed worldwide', false);
							vm.instance.setSectionLoading('Observed in organization', false);
						},
						function(stats) {
							if (stats.error) {
								vm.instance.setSectionError('Observed worldwide', stats.error);
								vm.instance.setSectionError('Observed in organization', stats.error);
							} else {
								vm.instance.setSectionError(
									'Observed worldwide',
									'Data could not be retrieved'
								);
								vm.instance.setSectionError(
									'Observed in organization',
									'Data could not be retrieved'
								);
							}
						}
					);

					if (featuresService.isEnabled(Feature.FileSensitivity)) {
						filesBackendService.getFileProtectionInfo(vm.sha).then((info: FileProtectionInfo) => {
							if (!info) return;

							const section = vm.instance.getSection('File details');
							const properties = [
								info.sensitivity
									? {
											key: 'Sensitivity',
											value: {
												tags: [info.sensitivity.displayText],
											},
											type: 'tags',
											order: 7,
									  }
									: null,
								info.isAzureInfoProtectionApplied != null
									? {
											key: 'AIP Protected',
											value: info.isAzureInfoProtectionApplied ? 'Yes' : 'No',
											type: 'primitive',
											order: info.sensitivity ? 8 : 7,
									  }
									: null,
							].filter(Boolean);

							section.props = (section.props || []).concat(properties);
						});
					}
				},
				function(response) {
					if (response.error) {
						vm.instance.setError(response.error);
					} else {
						vm.instance.setError('Data could not be retrieved');
					}
				}
			);
		}

		function loadAlerts() {
			$log.debug('loading alerts, for file with id=' + vm.sha);
			sidePaneDataProviderService.loadAlertsForFile(vm.sha, vm.instance).then(
				function(alertsData) {
					vm.instance.setSection('Detections', alertsData.section);
					checkIfDetectionLoadingIsDone();
				},
				function(alertsData) {
					if (alertsData.error) {
						vm.instance.setSectionError('Detections', alertsData.error);
					} else {
						vm.instance.setSectionError('Detections', 'Data could not be retrieved');
					}
				}
			);
		}

		loadProfile();
		loadAlerts();
	};
}
