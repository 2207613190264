import { Component, Input } from '@angular/core';
import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { HttpErrorResponse } from '@angular/common/http';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import {
	MANAGEMENT_FLOWS,
	EndpointManagementProcessesService,
	PROCESS_TYPES,
} from '../../services/endpoint-management-processes.service';
import {
	EndpointManagementProcess,
	EndpointManagementPackage,
} from '../../models/endpoint-management-process.model';
import { TzDateService } from '@wcd/localization';

const OFFBOARDING_PACKAGE_EXPIRATION_IN_DAYS: number = 30;

@Component({
	selector: 'manage-linux-first-party-offboarding',
	templateUrl: './manage-linux-first-party-offboarding.component.html',
	styles: [
		`
			.suspended-icon {
				position: relative;
			}

			.suspended-icon icon {
				font-size: 3.75rem;
			}

			.suspended-icon span {
				font-size: 3rem;
				position: absolute;
				top: 13px;
				left: 21px;
			}
		`,
	],
})
export class ManageLinuxFirstPartyOffboardingComponent {
	@Input() isServer = false;

	@Input()
	set osType(process: EndpointManagementProcess) {
		this.packages = this.packagesService.getEnabledPackages(MANAGEMENT_FLOWS.offboarding, process);
		this.currentMethod = this.packages[0];
	}

	packages: Array<EndpointManagementPackage>;
	currentMethod: EndpointManagementPackage;

	constructor(
		private endpointManagementService: EndpointManagementService,
		private endpointManagementProcessesService: EndpointManagementProcessesService,
		public packagesService: EndpointManagementSensePackagesService,
		private appInsights: AppInsightsService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private tzDateService: TzDateService
	) {
		this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
	}

	ngOnInit() {
		this.packages = this.packagesService.getEnabledPackages(
			MANAGEMENT_FLOWS.onboarding,
			this.endpointManagementProcessesService.all.find(p => (this.isServer && p.name === 'linuxServer') || (!this.isServer && p.name === 'linuxWorkstation'))
		);
		
		this.currentMethod = this.packages && this.packages[0];
	}

	getDeploymentMethodLabel(managementPackage: EndpointManagementPackage) {
		return this.i18nService.get(
			'endpointManagement.deploymentMethods.' + managementPackage.name + '.title'
		);
	}

	confirmAndDownloadPackage() {
		const now: Date = new Date();
		now.setDate(now.getDate() + OFFBOARDING_PACKAGE_EXPIRATION_IN_DAYS);

		this.dialogsService
			.confirm({
				title: this.i18nService.get('endpointManagement.agent.offboarding.confirm.title'),
				text: this.i18nService.get('endpointManagement.agent.offboarding.confirm.description', {
					date: this.tzDateService.format(now, 'shortDate'),
				}),
				confirmText: this.i18nService.get('endpointManagement.agent.offboarding.confirm.yes'),
			})
			.then((e: ConfirmEvent) => e.confirmed && this.doDownload());
	}

	doDownload() {
		this.endpointManagementService.downloadSensePackage(false, this.currentMethod.id).subscribe(
			(res: string) => {
				this.appInsights.trackEvent('EndpointOffboarding-DownloadPackage', {
					ResponseStatus: String(200),
				});
				window.location.href = res;
			},
			(error: HttpErrorResponse) => {
				this.dialogsService.showError({
					title: this.i18nService.get('endpointManagement.download.package.failed'),
					data: error,
				});
				this.appInsights.trackEvent('EndpointOffboarding-DownloadPackage', {
					ResponseStatus: String(error.status),
					FailureType: 'API',
				});
			}
		);
	}
}
