
		<ng-container *ngIf="!certificateInfoInitiliazed; else certificateTypeInitiliazed">
			<i class="loader-icon"></i>
		</ng-container>
		<ng-template #certificateTypeInitiliazed>
			<ng-container [ngSwitch]="certificateType">
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Signed">
					<fab-icon iconName="Certificate"></fab-icon>
					{{ certificateInfo.signer }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.SignedInvalid">
					<fab-icon iconName="Certificate" contentClass="ms-color-red"></fab-icon>
					{{ certificateInfo.signer }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Unsigned">
					<fab-icon iconName="BlockedSite"></fab-icon>
					{{ 'files.entityDetails.sections.details.fields.signer.unsigned' | i18n }}
				</ng-container>
				<ng-container *ngSwitchCase="CertificateInfoKindEnum.Unknown">
					<fab-icon iconName="Certificate"></fab-icon>
					{{ 'files.entityDetails.sections.details.fields.signer.unknown' | i18n }}
				</ng-container>
			</ng-container>
		</ng-template>
	