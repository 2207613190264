import {
	DataQuery,
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
} from '@microsoft/paris';
import { AlertsSeveritySummary } from '../../alert/alerts-severity-summary.value-object';
import { File } from '../file.entity';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: AlertsSeveritySummary,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	baseUrl: config => config.data.serviceUrls.activeAlertsSummary,
	endpoint: 'activeAlertsSummary',
	getRelationshipData: (file: File) => (file.sha1 ? { Sha1: file.sha1 } : { Sha256: file.sha256 }),
	parseDataQuery: (dataQuery: DataQuery) =>
		Object.assign({ lookingBackInDays: 180 }, dataQuery && dataQuery.where),
})
export class FileActiveAlertsSummaryRelationship
	implements EntityRelationshipRepositoryType<File, AlertsSeveritySummary> {}
