import { SevilleModule } from '../../../../../app/seville/seville.module';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.email', settingsEmailController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('preferences.email', {
			url: '/email',
			title: 'Alert notifications',
			parent: 'preferences',
			views: {
				SettingsView: {
					template: `
						<div class="settings-view-page">
                <div class="settings-page-title">
                    Configure email notification settings. <br />
                    See the <a href="https://technet.microsoft.com/en-us/itpro/windows/keep-secure/configure-email-notifications-windows-defender-advanced-threat-protection" target="_blank">Configuring email notifications</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a>.
                </div>
                <div ng-class="{'disabled': isreadonlyuser}"
                	rbacjs state="'disabled'" permissions="['securitySettings']" >
                    <div ng-if="$parent.loadingCurrentPreferences">
                        <img class="settings-page-loading img-responsive" src="/assets/images/linear-loader.gif" />
                    </div>
                    <div ng-if="$parent.loadingCurrentPreferencesFailed" class="settings-error-message">
                        {{ $parent.$parent.messageLabel }}
                    </div>
                    <div ng-if="!$parent.loadingCurrentPreferences && !$parent.loadingCurrentPreferencesFailed" class="settings-mails-page">
                        <div class="ms-Toggle">
                            <input type="checkbox" id="toggle" class="order ms-Toggle-input" ng-click="$parent.mailEnabled = !$parent.mailEnabled" ng-checked="$parent.mailEnabled">
                            <label for="toggle" class="order ms-Toggle-field ob-common-toggle">
                            </label>
                            <div class="ms-Toggle-description ob-common-toggle-description-title">
                                <span class="ob-common-toggle-state">{{$parent.mailEnabled ? $parent.toggleStateOn : $parent.toggleStateOff}}</span>
                                Email notifications
                                <div class="ob-common-toggle-description">
                                    Get email notifications for new alerts.
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div class="settings-mails-section">
                                <h3 class="wcd-padding-bottom" colspan="2">Choose alert severity to be notified on:</h3>
                                <div ng-class="{ 'settings-disabled disabled-completely': !mailEnabled }">
                                    Select the level of alerts that all recipients will be notified of:
                                    <table class="settings-mails-checkbox">
                                        <tr ng-click="$parent.highSeverity = !$parent.highSeverity">
                                            <td>
                                                <span class="icon ng-class:{
                                                      'icon-CheckboxFillZeroWidth': !$parent.highSeverity && !$parent.mailEnabled,
                                                      'icon-CheckboxCompositeReversed': $parent.highSeverity,
                                                      'icon-Checkbox': !$parent.highSeverity && $parent.mailEnabled,
                                                      'settings-disabled-color': !$parent.highSeverity && !$parent.mailEnabled,
                                                      'settings-disabled-color-dark': $parent.highSeverity && !$parent.mailEnabled}"></span>
                                            </td>
                                            <td ng-class="{'settings-disabled-text': !mailEnabled}">High</td>
                                        </tr>
                                        <tr ng-click="$parent.mediumSeverity = !$parent.mediumSeverity">
                                            <td>
                                                <span class="icon ng-class:{
                                                      'icon-CheckboxFillZeroWidth': !$parent.mediumSeverity && !$parent.mailEnabled,
                                                      'icon-CheckboxCompositeReversed': $parent.mediumSeverity,
                                                      'icon-Checkbox': !$parent.mediumSeverity && $parent.mailEnabled,
                                                      'settings-disabled-color': !$parent.mediumSeverity && !$parent.mailEnabled,
                                                      'settings-disabled-color-dark': $parent.mediumSeverity && !$parent.mailEnabled}"></span>
                                            </td>
                                            <td ng-class="{'settings-disabled-text': !mailEnabled}">Medium</td>
                                        </tr>
                                        <tr ng-click="$parent.lowSeverity = !$parent.lowSeverity">
                                            <td>
                                                <span class="icon ng-class:{
                                                      'icon-CheckboxFillZeroWidth': !$parent.lowSeverity && !$parent.mailEnabled,
                                                      'icon-CheckboxCompositeReversed': $parent.lowSeverity,
                                                      'icon-Checkbox': !$parent.lowSeverity && $parent.mailEnabled,
                                                      'settings-disabled-color': !$parent.lowSeverity && !$parent.mailEnabled,
                                                      'settings-disabled-color-dark': $parent.lowSeverity && !$parent.mailEnabled}"></span>
                                            </td>
                                            <td ng-class="{'settings-disabled-text': !mailEnabled}">Low</td>
                                        </tr>
                                        <tr ng-click="$parent.informationalSeverity = !$parent.informationalSeverity">
                                            <td>
                                                <span class="icon ng-class:{
                                                      'icon-CheckboxFillZeroWidth': !$parent.informationalSeverity && !$parent.mailEnabled,
                                                      'icon-CheckboxCompositeReversed': $parent.informationalSeverity,
                                                      'icon-Checkbox': !$parent.informationalSeverity && $parent.mailEnabled,
                                                      'settings-disabled-color': !$parent.informationalSeverity && !$parent.mailEnabled,
                                                      'settings-disabled-color-dark': $parent.informationalSeverity && !$parent.mailEnabled}"></span>
                                            </td>
                                            <td ng-class="{'settings-disabled-text': !mailEnabled}">Informational</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="settings-mails-section">
                                <h3 class="wcd-padding-bottom" colspan="2">Email recipients to notify on new alerts:</h3>
                                <div ng-form name="formEmail" ng-class="{ 'settings-disabled disabled-completely': !mailEnabled }">
                                    Add an email address:
                                    <table class="settings-mails-table">
                                        <tr>
                                            <td>
                                                <input type="email"
                                                       name="newMailAddress"
                                                       ng-model="$parent.newMailAddress"
                                                       ng-class="{'settings-mails-error-input':email.newMailError !== '', 'settings-mails-input-disabled': !$parent.mailEnabled}"
                                                       required
                                                       ng-keyup="resetLabels() && $event.keyCode == 13 && addMailAddress()" />
                                            </td>
                                            <td class="settings-mail-icon ng-class:{'settings-disabled-text': !mailEnabled}" type="reset" ng-click="addMailAddress()">
                                                <span class="icon icon-Add"></span>Add recipient
                                            </td>
                                        </tr>
                                        <tr>
                                            <td class="settings-mails-error" colspan="2">
                                                {{ email.newMailError }}
                                            </td>
                                        </tr>
                                        <tr ng-repeat="mailAddress in mailAddresses track by $index">
                                            <td class="settings-mails-item ng-class:{'settings-disabled-text': !mailEnabled}">{{ mailAddress }}</td>
                                            <td class="settings-mail-icon ng-class:{'settings-disabled-text': !mailEnabled}" ng-click="removeMailAddress($index)"><span class="icon icon-Cancel"></span>Remove</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <hr />
                        </div>
                        <div>
                            <table class="settings-mails-button-table">
                                <tr>
                                    <td>
                                        <form ng-submit="saveSettings()">
                                            <button ng-disabled="$parent.$parent.applyingPreferencesChange || !email.preferencesChanged"
                                                    class="settings-button ng-class:{'settings-button-enabled':!$parent.$parent.applyingPreferencesChange && email.preferencesChanged, 'settings-button-disabled settings-disabled disabled-completely':$parent.$parent.applyingPreferencesChange || !email.preferencesChanged}">
                                                Save preferences
                                            </button><br />
                                        </form>
                                    </td>
                                    <td class="settings-mails-test-button-table">
                                        <form ng-submit="sendTestEmail()">
                                            <button class="settings-button ng-class:{'settings-mails-test-button':!email.sendingTestMail && mailEnabled,'settings-button-disabled settings-disabled disabled-completely':email.sendingTestMail || !mailEnabled}"
                                                    ng-disabled="email.sendingTestMail || !mailEnabled">
                                                Send test email
                                            </button><br />
                                        </form>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="ng-class:{'settings-error-message': $parent.savePreferencesFailed || $parent.loadingCurrentPreferencesFailed}">{{ $parent.$parent.messageLabel }}</span>
                                    </td>
                                    <td class="settings-mails-test-button-table">
                                        <span>{{ email.sendTestEmailSucess }}</span>
                                        <span class="settings-mails-error">{{ email.sendTestEmailError }}</span>
                                    </td>
                                </tr>
                            </table>
                            <img ng-if="$parent.$parent.applyingPreferencesChange || email.sendingTestMail"
                                 class="settings-toggle-applying img-responsive"
                                 src="/assets/images/linear-loader.gif" />
                        </div>
                    </div>
                </div>
            </div>
						`,
					controller: 'seville.settings.email',
					controllerAs: 'email',
				},
			},
		});
	},
]);

settingsEmailController.$inject = ['$scope', '$http', '$timeout', '$interval', 'authenticationService'];

function settingsEmailController($scope, $http, $timeout, $interval, authenticationService) {
	var vm = this;
	vm.sendingTestMail = false;
	vm.preferencesChanged = false;
	$scope.highSeverity = false;
	$scope.mediumSeverity = false;
	$scope.lowSeverity = false;
	$scope.informationalSeverity = false;
	$scope.mailEnabled = false;
	$scope.mailAddresses = [];

	function validateEmail(email) {
		var regex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
		return regex.test(email);
	}

	function mailAddressExists(mailAddress) {
		return $scope.mailAddresses.indexOf(mailAddress) !== -1;
	}

	$scope.resetLabels = function() {
		vm.sendTestEmailError = '';
		vm.sendTestEmailSucess = '';
		vm.newMailError = '';
		$scope.$parent.resetMessageLabel();
		return true;
	};

	function showNewMailError(message) {
		$scope.resetLabels();
		vm.newMailError = message;
	}

	function showSendTestMailError(message) {
		$scope.resetLabels();
		vm.sendTestEmailError = message;
	}

	function showSendTestMailSuccess(message) {
		$scope.resetLabels();
		vm.sendTestEmailSucess = message;
	}

	function showSaveMailSettingsError(message) {
		$scope.resetLabels();
		vm.saveSettingsError = message;
	}

	$scope.addMailAddress = function() {
		if ($scope.newMailAddress === '') {
			showNewMailError("Email address can't be empty.");
			return;
		}

		if (validateEmail($scope.newMailAddress)) {
			if (mailAddressExists($scope.newMailAddress)) {
				showNewMailError('Email address is already on the list.');
				return;
			}

			$scope.mailAddresses.push($scope.newMailAddress);
			$scope.newMailError = false;
			$scope.newMailAddress = null;
			checkPreferencesChanged();
		} else {
			showNewMailError('Specify a valid email address.');
		}
	};

	$scope.removeMailAddress = function(index) {
		$scope.mailAddresses.splice(index, 1);
		checkPreferencesChanged();
	};

	$scope.messageLabel = '';
	$scope.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);

	function mailAddressesChanged() {
		if ($scope.originalData.MailAddresses == null) {
			return $scope.mailAddresses.length !== 0;
		}

		if ($scope.originalData.MailAddresses.length !== $scope.mailAddresses.length) {
			return true;
		}

		var expectedCount = $scope.originalData.MailAddresses.length;
		var count = 0;
		$scope.originalData.MailAddresses.forEach(val => {
			count += mailAddressExists(val) ? 1 : 0;
		});

		return expectedCount !== count;
	}

	function checkPreferencesChanged() {
		$scope.resetLabels();
		if ($scope.originalData == undefined) {
			return;
		}

		if ($scope.originalData.MailEnabled === false) {
			vm.preferencesChanged = $scope.originalData.MailEnabled !== $scope.mailEnabled;
		} else {
			vm.preferencesChanged =
				$scope.originalData.MailEnabled !== $scope.mailEnabled ||
				$scope.originalData.HighSeverity !== $scope.highSeverity ||
				$scope.originalData.MediumSeverity !== $scope.mediumSeverity ||
				$scope.originalData.LowSeverity !== $scope.lowSeverity ||
				$scope.originalData.InformationalSeverity !== $scope.informationalSeverity ||
				mailAddressesChanged();
		}
	}

	function isSeverityChecked() {
		return (
			$scope.highSeverity || $scope.mediumSeverity || $scope.lowSeverity || $scope.informationalSeverity
		);
	}

	function isMailAddressEmpty() {
		return $scope.mailAddresses.length === 0;
	}

	$scope.sendTestEmail = function() {
		$scope.resetLabels();
		if ($scope.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}

		vm.sendingTestMail = true;
		if (isMailAddressEmpty() && !isSeverityChecked()) {
			showSendTestMailError(
				'Unable to send test email. Select at least one alert severity level and one email recipient.'
			);
			vm.sendingTestMail = false;
		} else if (!isSeverityChecked()) {
			showSendTestMailError('Unable to send test email. Select at least one alert severity level.');
			vm.sendingTestMail = false;
		} else if (isMailAddressEmpty()) {
			showSendTestMailError('Specify email recipients.');
			vm.sendingTestMail = false;
		} else {
			var mailSettings = {
				HighSeverity: $scope.highSeverity,
				MediumSeverity: $scope.mediumSeverity,
				LowSeverity: $scope.lowSeverity,
				InformationalSeverity: $scope.informationalSeverity,
				MailAddresses: $scope.mailAddresses,
				MailEnabled: $scope.mailEnabled,
			};

			$http
				.post($scope.$parent.settingsBaseUrl + '/SendTestEmail', mailSettings, {
					timeout: 60000,
				})
				.then(
					function(response) {
						if (response.status === 200) {
							showSendTestMailSuccess('Test email sent successfully.');
							vm.sendingTestMail = false;
						} else {
							console.log('status: ' + response.status);
							showSendTestMailError('Unable to send test email.');
							vm.sendingTestMail = false;
						}
					},
					function(response) {
						console.log('status: ' + response.status);
						showSendTestMailError('Unable to send test email.');
						vm.sendingTestMail = false;
					}
				);
		}
	};

	$scope.saveSettings = function() {
		$scope.resetLabels();
		$scope.$parent.savePreferencesReset();

		if ($scope.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}

		if (!vm.preferencesChanged) {
			console.log('Preferences did not changed, skipping');
			return;
		}

		var mailSettings;
		if ($scope.mailEnabled) {
			if (isMailAddressEmpty() && !isSeverityChecked()) {
				$scope.$parent.showSavePreferencesErrorWithMessage(
					'Unable to save settings. Select at least one alert severity level and one email recipient.'
				);
				return;
			}

			if (isMailAddressEmpty()) {
				$scope.$parent.showSavePreferencesErrorWithMessage(
					'Unable to save settings. Specify email recipients.'
				);

				return;
			}

			if (!isSeverityChecked()) {
				$scope.$parent.showSavePreferencesErrorWithMessage(
					'Unable to save settings. Select at least one alert severity level.'
				);

				return;
			}

			mailSettings = {
				HighSeverity: $scope.highSeverity,
				MediumSeverity: $scope.mediumSeverity,
				LowSeverity: $scope.lowSeverity,
				InformationalSeverity: $scope.informationalSeverity,
				MailAddresses: $scope.mailAddresses,
				MailEnabled: true,
			};
		} else {
			mailSettings = {
				MailEnabled: false,
			};
		}

		$http
			.post($scope.$parent.settingsBaseUrl + '/SaveTenantMailSettings', mailSettings, {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						$scope.originalData.MailEnabled = $scope.mailEnabled;
						$scope.originalData.HighSeverity = $scope.highSeverity;
						$scope.originalData.MediumSeverity = $scope.mediumSeverity;
						$scope.originalData.LowSeverity = $scope.lowSeverity;
						($scope.originalData.InformationalSeverity = $scope.informationalSeverity),
							($scope.originalData.MailAddresses = $scope.mailAddresses.slice(0));

						$scope.$parent.showSavePreferencesSuccess();
						checkPreferencesChanged();
					} else {
						console.log('status: ' + response.status);
						$scope.$parent.showSavePreferencesError();
					}
				},
				function(response) {
					console.log('status: ' + response.status);
					$scope.$parent.showSavePreferencesError();
				}
			);
	};

	function getCurrentMailSettings() {
		$http
			.get($scope.$parent.settingsBaseUrl + '/GetUserMailSettings', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data === null) {
							console.log('response data null');
							$scope.$parent.showGetPreferencesError();
							return;
						}

						$scope.originalData = JSON.parse(JSON.stringify(response.data));
						$scope.mailEnabled = response.data.MailEnabled;
						$scope.highSeverity = response.data.HighSeverity;
						$scope.mediumSeverity = response.data.MediumSeverity;
						$scope.lowSeverity = response.data.LowSeverity;
						$scope.informationalSeverity = response.data.InformationalSeverity;
						if (response.data.MailAddresses !== null && response.data.MailAddresses.count !== 0) {
							$scope.mailAddresses = $scope.mailAddresses.concat(response.data.MailAddresses);
						}

						$scope.$parent.applyGetPreferencesSuccess();
					} else {
						console.log('GetUserMailSettings failed');
						$scope.$parent.showGetPreferencesError();
					}
				},
				function(response) {
					console.log('GetUserMailSettings call is failed');
					$scope.$parent.showGetPreferencesError();
				}
			);
	}

	getCurrentMailSettings();
	$scope.$watch('[mailEnabled,highSeverity,mediumSeverity,lowSeverity,informationalSeverity]', function() {
		checkPreferencesChanged();
	});
}
