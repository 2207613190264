/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i6 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i7 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "./entity-report.component";
import * as i11 from "../../../../../../../projects/auth/src/lib/services/auth.service";
var styles_EntityReportComponent = [];
var RenderType_EntityReportComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntityReportComponent, data: {} });
export { RenderType_EntityReportComponent as RenderType_EntityReportComponent };
function View_EntityReportComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "wcd-margin-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t", "\n\t"])), i0.ɵpod(2, { entityType: 0 }), i0.ɵppd(3, 2), (_l()(), i0.ɵeld(4, 0, null, null, 3, "strong", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵted(8, null, ["\n\t", "\n"])), i0.ɵpod(9, { providerCount: 0 }), i0.ɵppd(10, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = ("color-text-" + _co.report.status.className); _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i0.ɵnov(_v.parent, 0), "airsEntities_details_report_prefix", _ck(_v, 2, 0, _co.entityType.singularName.toLowerCase()))); _ck(_v, 1, 0, currVal_0); var currVal_2 = _co.report.status.name.toLowerCase(); _ck(_v, 7, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i0.ɵnov(_v.parent, 0), ((_co.report.providers.length === 1) ? "airsEntities_details_report_suffix_singular" : "airsEntities_details_report_suffix_plural"), _ck(_v, 9, 0, _co.report.providers.length))); _ck(_v, 8, 0, currVal_3); }); }
function View_EntityReportComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [["class", "minimize-btn-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = "carets.right"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EntityReportComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_report_categorizedAs; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.categories; _ck(_v, 4, 0, currVal_1); }); }
function View_EntityReportComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_report_detectedBy; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.parent.context.$implicit.detectedBy; _ck(_v, 4, 0, currVal_1); }); }
function View_EntityReportComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "section", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(4, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i0.ɵpid(131072, i5.TzDatePipe, [i6.TzDateService, i7.LocaleConfigService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_report_analysisDate; _ck(_v, 3, 0, currVal_0); var currVal_1 = i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_v.parent.parent.context.$implicit.scanTime, "medium")); _ck(_v, 4, 0, currVal_1); }); }
function View_EntityReportComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit; _ck(_v, 1, 0, currVal_0); }); }
function View_EntityReportComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "button", [["class", "btn-inline"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleProviderSummary(_v.parent.parent.parent.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.showAllProviderSummaryItems[_v.parent.parent.parent.context.$implicit.name] ? _co.i18nService.strings.airsEntities_report_summary_showLess : _co.i18nService.strings.airsEntities_report_summary_showAll); _ck(_v, 1, 0, currVal_0); }); }
function View_EntityReportComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "section", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h5", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 4, "ul", [["class", "inline-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_10)), i0.ɵdid(8, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_11)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getProviderSummaryItems(_v.parent.parent.context.$implicit); _ck(_v, 8, 0, currVal_1); var currVal_2 = (_v.parent.parent.context.$implicit.summary.length > 5); _ck(_v, 12, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_report_summary; _ck(_v, 3, 0, currVal_0); }); }
function View_EntityReportComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "a", [["class", "noprint"], ["target", "_blank"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-shared-icon", [["class", "small-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i0.ɵpod(6, { providerName: 0 }), i0.ɵppd(7, 2)], function (_ck, _v) { var currVal_1 = "popOut"; _ck(_v, 3, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.parent.parent.context.$implicit.isInternal ? _co.authService.addTenantIdToUrl(_v.parent.parent.context.$implicit.link) : _v.parent.parent.context.$implicit.link); _ck(_v, 0, 0, currVal_0); var currVal_2 = i0.ɵunv(_v, 5, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent.parent.parent.parent, 0), "airsEntities_report_fullAnalysis", _ck(_v, 6, 0, _v.parent.parent.context.$implicit.name))); _ck(_v, 5, 0, currVal_2); }); }
function View_EntityReportComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "entity-report-details"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_6)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_7)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_8)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_9)), i0.ɵdid(12, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_12)), i0.ɵdid(15, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.$implicit.categories; _ck(_v, 3, 0, currVal_0); var currVal_1 = _v.parent.context.$implicit.detectedBy; _ck(_v, 6, 0, currVal_1); var currVal_2 = _v.parent.context.$implicit.scanTime; _ck(_v, 9, 0, currVal_2); var currVal_3 = ((_v.parent.context.$implicit.summary == null) ? null : _v.parent.context.$implicit.summary.length); _ck(_v, 12, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.link; _ck(_v, 15, 0, currVal_4); }, null); }
function View_EntityReportComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 24, "li", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 6, "div", [["class", "entity-report-status"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 3, "span", [["class", "status-label"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(6, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(7, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(10, 0, null, null, 13, "div", [["class", "entity-report-provider"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 7, "h4", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.providerHasDetails(_v.context.$implicit) && _co.toggleProvider(_v.context.$implicit)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(14, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(15, { minimized: 0, "minimize-btn": 1 }), (_l()(), i0.ɵted(16, null, ["\n\t\t\t\t\t", "\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_4)), i0.ɵdid(18, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_5)), i0.ɵdid(22, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "status-label"; var currVal_1 = ("color-box-" + (_v.context.$implicit.reputation.className || "gray-200")); _ck(_v, 6, 0, currVal_0, currVal_1); var currVal_3 = _ck(_v, 15, 0, !_co.expandedProviders[_v.context.$implicit.name], _co.providerHasDetails(_v.context.$implicit)); _ck(_v, 14, 0, currVal_3); var currVal_5 = _co.providerHasDetails(_v.context.$implicit); _ck(_v, 18, 0, currVal_5); var currVal_6 = _co.expandedProviders[_v.context.$implicit.name]; _ck(_v, 22, 0, currVal_6); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.reputation.name; _ck(_v, 7, 0, currVal_2); var currVal_4 = _v.context.$implicit.name; _ck(_v, 16, 0, currVal_4); }); }
function View_EntityReportComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "details-card entity-report"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "ul", [["class", "entity-report-providers unstyled"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_3)), i0.ɵdid(5, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.report.providers; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_EntityReportComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(5, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 8, "a", [["class", "minimize-btn toggle-link"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.viewProviders = !_co.viewProviders) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(9, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i0.ɵpod(10, { minimized: 0 }), (_l()(), i0.ɵted(11, null, ["\n\t\t", "\n\t\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 2, "wcd-shared-icon", [["class", "minimize-btn-icon small-icon"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(13, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityReportComponent_2)), i0.ɵdid(19, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showDescription; _ck(_v, 3, 0, currVal_0); var currVal_1 = "minimize-btn toggle-link"; var currVal_2 = _ck(_v, 10, 0, !_co.viewProviders); _ck(_v, 9, 0, currVal_1, currVal_2); var currVal_4 = "carets.right"; _ck(_v, 13, 0, currVal_4); var currVal_5 = _co.viewProviders; _ck(_v, 19, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.i18nService.strings.airsEntities_report_details; _ck(_v, 11, 0, currVal_3); }); }
export function View_EntityReportComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-report", [], null, null, null, View_EntityReportComponent_0, RenderType_EntityReportComponent)), i0.ɵdid(1, 114688, null, 0, i10.EntityReportComponent, [i11.AuthService, i9.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var EntityReportComponentNgFactory = i0.ɵccf("entity-report", i10.EntityReportComponent, View_EntityReportComponent_Host_0, { report: "report", entityType: "entityType", viewProvidersByDefault: "viewProvidersByDefault", showDescription: "showDescription" }, {}, []);
export { EntityReportComponentNgFactory as EntityReportComponentNgFactory };
