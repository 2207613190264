var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../../analyticsEndPointUtils';
var ɵ0 = function (id) { return "baseline/profiles/" + id; };
var BaselineProfileDetails = /** @class */ (function (_super) {
    __extends(BaselineProfileDetails, _super);
    function BaselineProfileDetails() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "profileId", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "description", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "benchmark", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "version", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "operatingSystem", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "operatingSystemVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineProfileDetails.prototype, "compliantDevicesHistory", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], BaselineProfileDetails.prototype, "status", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "complianceLevel", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineProfileDetails.prototype, "settingsNumber", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "createdBy", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "lastUpdatedBy", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], BaselineProfileDetails.prototype, "createdOnTimestampUTC", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], BaselineProfileDetails.prototype, "lastUpdateTimestampUTC", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineProfileDetails.prototype, "passedDevices", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineProfileDetails.prototype, "totalDevices", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineProfileDetails.prototype, "compliantDevices", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineProfileDetails.prototype, "eTag", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineProfileDetails.prototype, "checks", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineProfileDetails.prototype, "rbacGroupIds", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineProfileDetails.prototype, "rbacGroupNames", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineProfileDetails.prototype, "deviceTags", void 0);
    BaselineProfileDetails = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Profile', pluralName: 'Profiles', endpoint: 'baseline/profiles', parseItemQuery: ɵ0, readonly: true }))
    ], BaselineProfileDetails);
    return BaselineProfileDetails;
}(EntityModelBase));
export { BaselineProfileDetails };
export { ɵ0 };
