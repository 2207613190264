var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../data/models/store.backend.base';
import { DownloadService } from '../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
var TAGS_API_ENDPOINT = '/tags';
var TagsBackendService = /** @class */ (function (_super) {
    __extends(TagsBackendService, _super);
    function TagsBackendService(http, downloadService, serviceUrlsService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        return _this;
    }
    Object.defineProperty(TagsBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.automatedIr + TAGS_API_ENDPOINT;
        },
        enumerable: true,
        configurable: true
    });
    TagsBackendService.prototype.addTagsToObjects = function (tagId, objectType, objectsIds, options) {
        return this.patchTags('add', tagId, objectType, objectsIds, options);
    };
    TagsBackendService.prototype.removeTagsFromObjects = function (tagId, objectType, objectsIds, options) {
        return this.patchTags('remove', tagId, objectType, objectsIds, options);
    };
    TagsBackendService.prototype.patchTags = function (method, tagId, objectType, objectsIds, options) {
        var params = {
            method: method,
            tag_ids: [parseInt(tagId)],
            object_ids: objectsIds ? objectsIds : undefined,
            object_type: objectType,
        };
        return this.http.patch(this.apiUrl + "/tag", params, this.getUrlParams(options));
    };
    return TagsBackendService;
}(StoreBackendBase));
export { TagsBackendService };
