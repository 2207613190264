import { LegacyUser, LegacyUserLegacyUserNetworkInformationRelationship, Machine, } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Paris } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { LogonTypePipe } from '../../machines/pipes/logon-type.pipe';
var UserNetworkActivityDetailsComponent = /** @class */ (function () {
    function UserNetworkActivityDetailsComponent(globalEntityTypesService, i18nService, logonTypePipe, paris) {
        this.i18nService = i18nService;
        this.logonTypePipe = logonTypePipe;
        this.paris = paris;
        this.machineType = Machine;
        this.asKeyValueList = false;
        this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
    }
    UserNetworkActivityDetailsComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        var networkInformation$ = this.paris.getRelatedItem(LegacyUserLegacyUserNetworkInformationRelationship, changes.user.currentValue);
        var domainAdminTextKey = 'users.entityDetails.sections.details.role.values.domainAdmin';
        var localAdminTextTextKey = 'users.entityDetails.sections.details.role.values.localAdmin';
        // Calculates the text to show based on the user admin status (both domain, local).
        // If this is needed elsewhere - please extract to a `Pipe` (```user | userRoleKey | i18n``` or similar).
        this.role$ = networkInformation$.pipe(map(function (_a) {
            var isDomainAdmin = _a.isDomainAdmin, isLocalAdmin = _a.isLocalAdmin;
            return isDomainAdmin && isLocalAdmin
                ? [domainAdminTextKey, localAdminTextTextKey]
                : isDomainAdmin
                    ? [domainAdminTextKey]
                    : isLocalAdmin
                        ? [localAdminTextTextKey]
                        : [];
        }), map(function (translationKeys) { return translationKeys.map(function (key) { return _this.i18nService.get(key); }).join(', '); }));
        this.logonType$ = networkInformation$.pipe(map(function (_a) {
            var logonTypes = _a.logonTypes;
            return logonTypes.map(function (logonType) { return _this.logonTypePipe.transform(logonType); });
        }), map(function (logonTypesDisplayTexts) { return logonTypesDisplayTexts.join(', '); }));
    };
    return UserNetworkActivityDetailsComponent;
}());
export { UserNetworkActivityDetailsComponent };
