/* tslint:disable:template-accessibility-label-for */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	EventEmitter,
	Output,
} from '@angular/core';
import { Router } from '@angular/router';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { Machine, MachineValue } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { MachinesService, MachineValueConfig } from '../../../services/machines.service';
import { TvmTextsService, TextToken } from '../../../../../tvm/services/tvm-texts.service';

type MachineValueConfigWithEmptyState = MachineValueConfig | { id: 'mixed'; displayName: '' };

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine-value-panel.component.html',
})
export class MachineValuePanelComponent extends PanelContainer implements OnInit {
	readonly SpinnerSize = SpinnerSize;

	@Input() machines?: ReadonlyArray<Machine>;

	@Output() onMachineValueChanged = new EventEmitter<MachineValue>();

	messageBarType: MessageBarType;
	messageBarText: string;
	isSaving = false;
	value: MachineValueConfigWithEmptyState;
	values: MachineValueConfigWithEmptyState[];
	dropdownLabel: string;
	description: string;

	formatLabel = (machineValueConfig: MachineValueConfigWithEmptyState) => machineValueConfig.displayName;
	referenceValue: MachineValueConfigWithEmptyState;

	constructor(
		readonly router: Router,
		readonly machinesService: MachinesService,
		readonly i18nService: I18nService,
		readonly tvmTextService: TvmTextsService,
		private readonly dialogsService: DialogsService,
		private readonly changeDetector: ChangeDetectorRef
	) {
		super(router);
		this.dropdownLabel = i18nService.get('machines_entityDetails_actions_machineValue_title');
		this.values = machinesService.machineValues;
		this.description = tvmTextService.getText(TextToken.HighValueAssetDescription);
	}

	private setUpValue(): void {
		const isAllMachinesSameValue = this.machines.reduce((prev, curr) => {
			return prev && curr.assetValue === this.machines[0].assetValue;
		}, true);
		if (isAllMachinesSameValue) {
			this.value = this.values.find(
				val => val.id.toUpperCase() === this.machines[0].assetValue.toUpperCase()
			);
		}
		this.referenceValue = this.value;
	}

	ngOnInit() {
		this.setUpValue();
		super.ngOnInit();
	}

	onValueChange(e) {
		this.value = this.values.find(val => val.id === e.id);
	}

	submitRequest() {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;
		this.messageBarType = MessageBarType.info;
		this.messageBarText = this.i18nService.get('machines_entityDetails_actions_machineValue_submitting');
		this.onMachineValueChanged.emit(this.value.id as MachineValue);
	}

	requestSuccess() {
		this.isSaving = false;
		this.setUpValue();
		this.changeDetector.markForCheck();
		this.dialogsService.showSuccessSnackbar({
			text: this.i18nService.get(
				this.machines.length > 1
					? `machines_entityDetails_actions_machineValue_saved_plural`
					: `machines_entityDetails_actions_machineValue_saved_singular`
			),
		});
	}

	requestFail(e) {
		this.isSaving = false;
		this.changeDetector.markForCheck();
		this.dialogsService.showError({
			title: this.i18nService.get('machines_entityDetails_actions_machineValue_saved_fail'),
			data: e,
		});
	}
}
