<wcd-panel (close)="destroy()" [settings]="settings">
	<form
		class="wcd-auto-height-responsive wcd-full-height wcd-flex-vertical wcd-padding-vertical wcd-padding-xLarge-horizontal"
		data-track-component="CreateEvaluationSimulationComponent"
	>
		<h3 class="wcd-font-size-xxl wcd-font-weight-bold">
			{{'evaluation.dashboard.simulationDataView.commandBar.createSimulation.button' | i18n}}
		</h3>
		<div class="wcd-flex-1 wcd-scroll-vertical wcd-margin-large-top">
			<div class="wcd-font-weight-bold wcd-margin-bottom">
				{{ 'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectSimulator' | i18n }}
			</div>
			<fancy-select
				[(ngModel)]="selectedSimulator"
				(ngModelChange)="onSimulatorSelect()"
				[values]="getSimulators()"
				label="selectSimulator"
				name="select-evaluation-simulator"
				isBordered="true"
				labelClass="dropdown-width-medium-large"
				[ariaLabel]="'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectSimulator' | i18n"
				[placeholder]="('evaluation.dashboard.simulationDataView.commandBar.createSimulation.choose' | i18n)"
			></fancy-select>
			<div class="wcd-font-weight-bold wcd-margin-bottom wcd-margin-large-top">
				{{ 'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectSimulation' | i18n }}
			</div>
			<fancy-select
				[(ngModel)]="selectedSimulation"
				[values]="simulationsCatalog"
				[formatLabel]="getSimulationLabel"
				label="selectSimulation"
				name="select-simulation-machine"
				isBordered="true"
				labelClass="dropdown-width-medium-large"
				[ariaLabel]="'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectSimulation' | i18n"
				[placeholder]="(selectedSimulation ? selectedSimulation.name : 'evaluation.dashboard.simulationDataView.commandBar.createSimulation.choose' | i18n)"
				[isDisabled]="!simulationsCatalog.length"
			></fancy-select>
			<div class="wcd-font-weight-bold wcd-margin-bottom wcd-margin-large-top">
				{{ 'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectMachine' | i18n }}
			</div>
			<fancy-select
				[(ngModel)]="selectedMachine"
				[formatLabel]="getMachineLabel"
				[values]="getSimulatorRelatedMachines()"
				label="selectMachine"
				name="select-simulation-machine"
				isBordered="true"
				labelClass="dropdown-width-medium-large"
				[ariaLabel]="'evaluation.dashboard.simulationDataView.commandBar.createSimulation.selectMachine' | i18n"
				[placeholder]="('evaluation.dashboard.simulationDataView.commandBar.createSimulation.choose' | i18n)"
			></fancy-select>
			<fab-message-bar
				contentClass="wcd-margin-medium-top"
				[messageBarType]="MessageBarType.info">
				{{ "evaluation.dashboard.simulationDataView.commandBar.createSimulation.missingMachineInfo" | i18n}}
			</fab-message-bar>
			<div class="wcd-margin-xxLarge-top center-text wcd-padding-xxLarge-top" *ngIf="createInProgress">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</div>
		<div class="wcd-flex-none wcd-margin-vertical">
			<footer>
				<div
					class="wcd-flex-1 wcd-flex-horizontal wcd-padding-top"
					[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				>
					<fab-primary-button
						data-track-id="CreateSimulation"
						data-track-type="Button"
						[text]="('evaluation.dashboard.simulationDataView.commandBar.createSimulation.button' | i18n)"
						(onClick)="createSimulation()"
						[disabled]="createInProgress || !selectedMachine || !selectedSimulation || !canCreateSimulation"
						[wcdTooltip]="getTooltip()"
					>
					</fab-primary-button>
				</div>
			</footer>
		</div>
	</form>
</wcd-panel>
