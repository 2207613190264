import { Component } from '@angular/core';
import { WidgetType } from '../reports/components/report-with-filters.component';
import { ReportsService } from './services/reports.service';
import { ChartSettings } from '@wcd/charts';
import { I18nService } from '@wcd/i18n';
import { BaseReportWidgetComponent } from '../reporting/base-reporting-widget';
import { ChangeDetectionStrategy } from '@angular/core';
import { ThreatProtection, ThreatReportDimensionKeyType, ThreatDimensionType } from '@wcd/domain';
import { DimensionFilterData } from '../reporting/threat-protection2/threat-protection-report.model';
import { ChartType } from '../reporting/reporting.types';
import { ThreatProtectionReport2Service } from './services/threat-protection-report.service';
import { ThreatProtectionStatusMapping } from './threat-protection-status-mapping';

@Component({
	template: `
		<wcd-horizontal-bars-chart [data]="data$ | async" [settings]="chartSettings$ | async">
		</wcd-horizontal-bars-chart>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThreatProtectionDetectionSourceWidget extends BaseReportWidgetComponent<ThreatProtection> {
	readonly widgetType = WidgetType.Daily;
	readonly dimensionType = ThreatDimensionType.AlertDetectionSourceType;

	constructor(
		reportsService: ReportsService,
		i18nService: I18nService,
		private threatProtectionSvc: ThreatProtectionReport2Service,
		private threatProtectionStatusMapping: ThreatProtectionStatusMapping
	) {
		super(
			'threatProtectionDetectionSource',
			'reporting.alertsByDetectionSource.dailyTitleNew',
			reportsService,
			i18nService
		);
	}

	get dataFunc() {
		return this.threatProtectionSvc.getAlertsByDimension.bind(this.threatProtectionSvc);
	}


	getSettings(data?: ThreatProtection): ChartSettings {
		return this.threatProtectionSvc.getSettingsDaily(
			ChartType.Vertical,
			this.threatProtectionStatusMapping.detectionSourceMapping,
			data,
			detectionSource => this.getDimensionFilterData(detectionSource)
		);
	}


	getDimensionFilterData(detectionSource: ThreatReportDimensionKeyType): DimensionFilterData {
		return {
			currentFilters: this.currentFilters,
			type: 'detectionSource',
			dimensionFilter: `detectionSource=${encodeURIComponent(
				this.threatProtectionStatusMapping.detectionSourceMapping[detectionSource].alertListFilter
			)}`,
		};
	}
}
