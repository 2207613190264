var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
export var EvaluationMachineStatusType;
(function (EvaluationMachineStatusType) {
    EvaluationMachineStatusType["PendingCreation"] = "PendingCreation";
    EvaluationMachineStatusType["Creating"] = "Creating";
    EvaluationMachineStatusType["Created"] = "Created";
    EvaluationMachineStatusType["ProvisionFailure"] = "ProvisionFailure";
    EvaluationMachineStatusType["PendingPasswordReset"] = "PendingPasswordReset";
    EvaluationMachineStatusType["ExecutingPasswordReset"] = "ExecutingPasswordReset";
    EvaluationMachineStatusType["PasswordResetFailure"] = "PasswordResetFailure";
    EvaluationMachineStatusType["PendingDeletion"] = "PendingDeletion";
    EvaluationMachineStatusType["Deleting"] = "Deleting";
    EvaluationMachineStatusType["Deleted"] = "Deleted";
})(EvaluationMachineStatusType || (EvaluationMachineStatusType = {}));
export var evaluationMachineStatusValues = [
    {
        id: 'PendingCreation',
        name: 'pendingCreation',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.PendingCreation,
    },
    {
        id: 'Creating',
        name: 'creating',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.Creating,
    },
    {
        id: 'Created',
        name: 'created',
        className: 'ms-color-greenLight',
        type: EvaluationMachineStatusType.Created,
    },
    {
        id: 'ProvisionFailure',
        name: 'provisionFailure',
        className: 'ms-color-red',
        type: EvaluationMachineStatusType.ProvisionFailure,
    },
    {
        id: 'PendingPasswordReset',
        name: 'pendingPasswordReset',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.PendingPasswordReset,
    },
    {
        id: 'ExecutingPasswordReset',
        name: 'executingPasswordReset',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.ExecutingPasswordReset,
    },
    {
        id: 'PasswordResetFailure',
        name: 'passwordResetFailure',
        className: 'ms-color-red',
        type: EvaluationMachineStatusType.PasswordResetFailure,
    },
    {
        id: 'PendingDeletion',
        name: 'pendingDeletion',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.PendingDeletion,
    },
    {
        id: 'Deleting',
        name: 'deleting',
        className: 'ms-color-themeSecondary',
        type: EvaluationMachineStatusType.Deleting,
    },
    {
        id: 'Deleted',
        name: 'deleted',
        className: 'ms-color-neutralTertiary',
        type: EvaluationMachineStatusType.Deleted,
    },
];
var EvaluationMachineStatus = /** @class */ (function (_super) {
    __extends(EvaluationMachineStatus, _super);
    function EvaluationMachineStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], EvaluationMachineStatus.prototype, "type", void 0);
    EvaluationMachineStatus = __decorate([
        Entity({
            singularName: 'Device status',
            pluralName: 'Device status',
            values: evaluationMachineStatusValues,
        })
    ], EvaluationMachineStatus);
    return EvaluationMachineStatus;
}(StatusModel));
export { EvaluationMachineStatus };
