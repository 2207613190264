<div
	[focusOnFirst]="true"
	[elementSelector]="'main'"
	class="wcd-full-height wcd-flex-vertical wcd-entity responsive-entity-page"
	[ngClass]="{ modern: modernEntityPage }"
	[class.wcd-asset-page]="assetPage"
	[attr.aria-label]="(entityType.entity.name || '') + ': ' + (entity.shortName || entity.name || '')"
>
	<!--===================================entity page upper part==========================================================-->
	<ng-container *ngTemplateOutlet="entityUpperBar"></ng-container>
	<ng-container *ngTemplateOutlet="openSummaryPanelLink"></ng-container>

	<!--===================================entity page main part==========================================================-->
	<div
		class="wcd-full-height wcd-flex-horizontal responsive-entity"
		[class.wcd-scroll-vertical]="!isScc"
		[class.entity-page-wrapper]="!modernEntityPage"
		data-track-component="EntityComponent"
	>
		<!--===================================entity page summary pane==========================================================-->
		<wcd-panel
			*ngIf="openSummaryPanelNarrowScreen; else entityDetails"
			(close)="toggleSummaryPanelInNarrowScreen(false)"
			(open)="toggleSummaryPanelInNarrowScreen(true)"
			[settings]="detailsPanelSettings"
		>
			<ng-container *ngTemplateOutlet="entityDetails"></ng-container>
		</wcd-panel>

		<!--===================================entity page content==========================================================-->
		<article role="none" class="wcd-flex-1 wcd-flex-vertical">
			<!--If we are in responsive entity page, we need to add left padding to the main content if we are in a narrow screen and-->
			<!--if SCC is not responsive (thus, they don't add the padding for us). The reason is that in narrow screen when-->
			<!--entity page is responsive, the details pane is hidden and thus we need extra padding for the main content-->
			<div
				class="wcd-flex-1"
				[class.wcd-responsive-shell-padding-none-left]="isScc"
				[class.wcd-padding-xxLarge-left]="isNarrowLayout && !isScc"
			>
				<ng-container #entityContentsPlaceholder></ng-container>
			</div>
		</article>
	</div>
</div>

<ng-template #entityDetails>
	<entity-details-pane
		[isNarrowLayout]="isNarrowLayout"
		[class.wcd-full-width]="isNarrowLayout"
		*ngIf="showSummaryPanel()"
		[entity]="entity"
		[entityType]="entityType"
		[hidden]="isFullScreen"
		[showToggleButton]="assetPage"
		[entityPageViewMode]="entityPageViewMode"
		[topPadded]="!assetPage"
	>
	</entity-details-pane>
</ng-template>

<ng-template #entityUpperBar>
	<entity-upper-bar
		#entityUpperBar
		[commandBarItems$]="commandBarItems$"
		[entity]="entity"
		[hidden]="isFullScreen"
		[mainAppState]="mainAppState"
		[entityType]="entityType"
	>
	</entity-upper-bar>
</ng-template>

<ng-template #openSummaryPanelLink>
	<a
		*ngIf="!hideEntitySummaryPanelLink"
		role="button"
		tabindex="0"
		data-track-id="ShowEntitySummaryPanel"
		data-track-type="SidePaneToggleButton"
		(click)="toggleSummaryPanelInNarrowScreen(true)"
		(keydown.enter)="toggleSummaryPanelInNarrowScreen(true)"
		class="show-summary-button"
		[hidden]="isFullScreen"
		[class.wcd-responsive-shell-padding-none-left]="isScc"
	>
		{{ 'dataview_entity_details_panel_button_view' | i18n }}
	</a>
</ng-template>

<ng-template #tagsItem let-item="item">
	<wcd-dropdown
		#dropDownRef
		[closeOnClick]="false"
		[rbac]="item.data.rbacSettings"
		[buttonIcon]="item.iconProps.iconName"
		[buttonTooltip]="item.title"
		class="command-bar-item command-bar-item-dropdown"
		[attr.data-track-id]="item.key"
		data-track-type="TagStart"
		[showIconDropdown]="false"
		[disabled]="item.disabled"
		[buttonId]="item.key + '-tags'"
		ariaRole="menuitem"
		[isBordered]="false"
		(onToggle)="tagsEditorToggled($event)"
	>
		<span button-content>{{ 'tags.manage' | i18n }}</span>
		<tags-edit
			[tags]="tags$ | async"
			[settings]="item.data.itemAction.options.tags"
			[callOnEscape]="dropDownRef"
			[tagEditDialogOpen]="tagEditOpen"
		></tags-edit>
	</wcd-dropdown>
</ng-template>
