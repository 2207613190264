import { OnDestroy, OnInit } from '@angular/core';
import { SystemExclusionsService } from '../services/system-exclusions.service';
import { merge } from 'rxjs';
import { DataviewField } from '@wcd/dataview';
import { AuthService } from '@wcd/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { SystemExclusion, SystemExclusionType, SystemExclusionTypeExclusionsRelationship, MdeUserRoleActionEnum, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { SystemExclusionBaseFields } from '../services/system-exclusion-base-fields.service';
import { filter, tap } from 'rxjs/operators';
import { compact } from 'lodash-es';
import { FeaturesService, Feature } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
var SystemExclusionsDataviewComponent = /** @class */ (function () {
    function SystemExclusionsDataviewComponent(authService, route, router, paris, featuresService, systemExclusionsService, systemExclusionBaseFields, performanceSccService) {
        var _this = this;
        this.authService = authService;
        this.route = route;
        this.router = router;
        this.paris = paris;
        this.featuresService = featuresService;
        this.systemExclusionsService = systemExclusionsService;
        this.systemExclusionBaseFields = systemExclusionBaseFields;
        this.performanceSccService = performanceSccService;
        this.isScc = sccHostService.isSCC;
        this.systemExclusionsRepository = paris.getRelationshipRepository(SystemExclusionTypeExclusionsRelationship);
        var allSystemExclusionTypes = this.paris.getRepository(SystemExclusionType).entity.values;
        this._allSystemExclusionTypes = allSystemExclusionTypes.filter(function (type) {
            if (_this.featuresService.isEnabled(Feature.AclOffloadingPhase1) &&
                type.hideIfPhase1OffloadingEnabled) {
                return false;
            }
            if (_this.featuresService.isEnabled(Feature.AclOffloadingPhase2) &&
                type.hideIfPhase2OffloadingEnabled) {
                return false;
            }
            return true;
        });
    }
    Object.defineProperty(SystemExclusionsDataviewComponent.prototype, "isUserAllowedActions", {
        get: function () {
            return this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        },
        enumerable: true,
        configurable: true
    });
    SystemExclusionsDataviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.route.params.subscribe(function (params) {
            _this.ngOnDestroy();
            _this.selectSystemExclusionType(params.systemExclusionType);
            _this.setTabsWithCounts();
            _this._systemExclusionsChangesSubscription = merge(_this.paris.save$, _this.paris.remove$)
                .pipe(filter(function (event) { return event.entity === SystemExclusion; }), 
            // change dataview options so the data and filters are reloaded
            tap(function () { return _this.setDataviewOptions(); }))
                .subscribe(function () { return _this.setTabsWithCounts(); }, function (err) {
                _this.error = err;
            });
        });
    };
    SystemExclusionsDataviewComponent.prototype.ngOnDestroy = function () {
        this._systemExclusionTypeCountsSubscription &&
            this._systemExclusionTypeCountsSubscription.unsubscribe();
        this._systemExclusionsChangesSubscription && this._systemExclusionsChangesSubscription.unsubscribe();
        this.exclusionTypeSubscription && this.exclusionTypeSubscription.unsubscribe();
    };
    SystemExclusionsDataviewComponent.prototype.addNewSystemExclusion = function () {
        this.systemExclusionsService.showEditSystemExclusionDialog(this.currentSystemExclusionType);
    };
    SystemExclusionsDataviewComponent.prototype.setTabsWithCounts = function () {
        var _this = this;
        this._systemExclusionTypeCountsSubscription &&
            this._systemExclusionTypeCountsSubscription.unsubscribe();
        this._systemExclusionTypeCountsSubscription = this.systemExclusionsService
            .getSystemExclusionTypeCounts()
            .subscribe(function (counts) {
            _this.setTabs(counts);
        }, function (err) {
            _this.error = err;
        });
    };
    SystemExclusionsDataviewComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('endpoints-process-memory-indicators');
    };
    SystemExclusionsDataviewComponent.prototype.selectSystemExclusionType = function (systemExclusionTypeId) {
        var systemExclusionType = this._allSystemExclusionTypes.find(function (type) { return type.id === systemExclusionTypeId; });
        if ((this.currentSystemExclusionType = systemExclusionType)) {
            this.systemExclusionsRepository.sourceItem = this.currentSystemExclusionType;
            this.itemActions = this.systemExclusionsService.getSystemExclusionsActions(this.currentSystemExclusionType);
            this.currentTabId = systemExclusionTypeId;
            this.setDataviewOptions();
        }
        else {
            this.router.navigate(['../' + this._allSystemExclusionTypes[0].id], {
                relativeTo: this.route,
                queryParams: {
                    page: null,
                    page_size: null,
                    filters: null,
                    search: null,
                    ordering: null,
                    fields: null,
                },
                queryParamsHandling: 'merge',
            });
        }
    };
    SystemExclusionsDataviewComponent.prototype.setDataviewOptions = function () {
        this.fields = this.systemExclusionBaseFields.fields.concat(this.currentSystemExclusionType.properties.map(function (property) {
            return new DataviewField(Object.assign({
                getDisplay: function (item) { return item.properties[property.id]; },
                className: 'nowrap wcd-text-overflow-medium-large',
            }, property));
        }));
        this.dataViewConfig = {
            id: 'systemExclusions_' + this.currentTabId,
            fixedFilterValues: { type: [this.currentSystemExclusionType.id] },
        };
    };
    SystemExclusionsDataviewComponent.prototype.isItemClickable = function (systemExclusion) {
        return !systemExclusion.isReadonly;
    };
    SystemExclusionsDataviewComponent.prototype.setTabs = function (counts) {
        this.tabsData = compact(this._allSystemExclusionTypes.map(function (systemExclusionType) {
            var value = counts[systemExclusionType.id.toString()];
            if (systemExclusionType.hideIfEmpty && !value) {
                return null;
            }
            return new TabModel({
                id: systemExclusionType.id.toString(),
                name: systemExclusionType.name,
                routerLink: "/preferences2/system_exclusions/" + systemExclusionType.id,
                value: counts[systemExclusionType.id.toString()],
                icon: systemExclusionType.icon,
            });
        }));
    };
    return SystemExclusionsDataviewComponent;
}());
export { SystemExclusionsDataviewComponent };
