var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { ChartComponent } from '../chart.component.base';
import * as d3 from 'd3';
import { I18nService } from '@wcd/i18n';
var HorizontalBarsChartComponent = /** @class */ (function (_super) {
    __extends(HorizontalBarsChartComponent, _super);
    function HorizontalBarsChartComponent(elementRef, i18nService) {
        var _this = _super.call(this, elementRef) || this;
        _this.i18nService = i18nService;
        _this.chartSettings = {
            chartType: 'bar',
            options: {
                axis: {
                    rotated: true,
                    x: {},
                    y: {
                        show: false,
                    },
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                oninit: _this.onInit.bind(_this),
            },
            useValues: true,
            xProperty: 'label',
            setColors: true,
            data: {
                order: 'desc',
            },
        };
        return _this;
    }
    HorizontalBarsChartComponent.prototype.onInit = function () {
        var _this = this;
        setTimeout(function () {
            var options = _this.getChartOptions();
            options.bindto = _this.el;
            var horizontalBarsChart = _this.el;
            d3.selectAll(horizontalBarsChart.querySelectorAll(".c3-bar"))
                .each(function (dataPoint, index, nodesList) {
                d3.select(nodesList[index]).attr('aria-label', options.axis.x.categories[dataPoint.x] + ", " + dataPoint.value);
            });
        }, 0);
    };
    return HorizontalBarsChartComponent;
}(ChartComponent));
export { HorizontalBarsChartComponent };
