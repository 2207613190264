import { ChangeDetectionStrategy, Component, OnInit, OnDestroy } from '@angular/core';
import { LegacyUser } from '@wcd/domain';

import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Subscription } from 'rxjs';
import { Feature, FeaturesService } from '@wcd/config';
import { filter } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../services/users.service';
import { ReportModel } from '../../../reports/models/report.model';
import { userReportModel } from './user-overview.component';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<tabs
				class="wcd-flex-none wcd-margin-medium-bottom wcd-padding-xxLarge-horizontal wcd-margin-large-bottom"
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
			></tabs>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class UserEntityComponent extends EntityComponentBase<LegacyUser> implements OnInit, OnDestroy {
	report: ReportModel;
	tabs: ReadonlyArray<TabModel>;
	private subscription: Subscription;

	constructor(
		private readonly usersService: UsersService,
		private readonly featuresService: FeaturesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly i18nService: I18nService
	) {
		super();
		this.report = userReportModel;
	}

	get user(): LegacyUser {
		return this.entity;
	}

	ngOnInit() {
		this.subscription = this.route.queryParamMap.subscribe(params => {
			const accountName = params.get('accountName');
			const accountDomainName = params.get('accountDomainName');
			this.tabs = this.getTabs(accountName, accountDomainName);
		});
	}

	ngOnDestroy() {
		this.subscription && this.subscription.unsubscribe();
	}

	private getTabs(accountName: string, accountDomainName: string): ReadonlyArray<TabModel> {
		const tabs = [
			{
				id: 'alerts',
				name: this.i18nService.get('users.tabs.alerts'),
				routerLink: 'alerts',
				routerLinkQueryParams: { accountName, accountDomainName },
			},
			{
				id: 'machines',
				name: this.i18nService.get('users.tabs.observed'),
				routerLink: 'machines',
				routerLinkQueryParams: { accountName, accountDomainName },
			},
		];

		tabs.unshift({
			id: 'overview',
			name: this.i18nService.get('users.tabs.overview'),
			routerLink: 'overview',
			routerLinkQueryParams: { accountName, accountDomainName },
		});

		return tabs.map(tab => new TabModel(tab));
	}
}
