var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { ServiceSourceType } from '../sources/service-source.enum';
var ɵ0 = ['Id', 'id'], ɵ1 = ['Name', 'name'], ɵ2 = ['EntityPageUrl', 'entityPageUrl'], ɵ3 = ['AlertProduct', 'alertProduct'], ɵ4 = ['EvidenceId', 'evidenceId'], ɵ5 = ['Type', 'type'];
var IncidentLinkedByEntity = /** @class */ (function (_super) {
    __extends(IncidentLinkedByEntity, _super);
    function IncidentLinkedByEntity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ0, required: false }),
        __metadata("design:type", String)
    ], IncidentLinkedByEntity.prototype, "id", void 0);
    __decorate([
        EntityField({ data: ɵ1, required: true }),
        __metadata("design:type", String)
    ], IncidentLinkedByEntity.prototype, "name", void 0);
    __decorate([
        EntityField({ data: ɵ2, required: false }),
        __metadata("design:type", String)
    ], IncidentLinkedByEntity.prototype, "entityPageUrl", void 0);
    __decorate([
        EntityField({ data: ɵ3, required: false }),
        __metadata("design:type", Number)
    ], IncidentLinkedByEntity.prototype, "entitySource", void 0);
    __decorate([
        EntityField({ data: ɵ4, required: false }),
        __metadata("design:type", Number)
    ], IncidentLinkedByEntity.prototype, "evidenceId", void 0);
    __decorate([
        EntityField({ data: ɵ5, required: true }),
        __metadata("design:type", String)
    ], IncidentLinkedByEntity.prototype, "type", void 0);
    IncidentLinkedByEntity = __decorate([
        ValueObject({
            singularName: 'Entity linked to the alert',
            pluralName: 'Entities linked to the alert',
            readonly: true,
        })
    ], IncidentLinkedByEntity);
    return IncidentLinkedByEntity;
}(ModelBase));
export { IncidentLinkedByEntity };
export var IncidentLinkedByEntityTypeMapper = {
    Machine: 'machine',
    File: 'file',
    User: 'user',
    Url: 'url',
    Ip: 'ip',
    Mailbox: 'mailbox',
    MailMessage: 'mailMessage',
    MailCluster: 'mailCluster',
    CommandLine: 'commandLine'
};
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
