import { Component } from '@angular/core';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'manage-non-windows-onboarding',
	templateUrl: './manage-non-windows-onboarding.component.html',
})
export class ManageNonWindowsOnboardingComponent {
	macOsOnboardingEnabled: boolean;

	constructor(private featuresService: FeaturesService) {
		this.macOsOnboardingEnabled = featuresService.isEnabled(Feature.MacOsOnboarding);
	}
}
