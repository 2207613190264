import { NgZone, OnDestroy } from '@angular/core';
import { Dictionary } from '@wcd/config';
import { Notification } from '../models/notification.model';
import { BehaviorSubject, of } from 'rxjs';
import { Router } from '@angular/router';
import { isNil } from 'lodash-es';
import { share } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/config/src/lib/services/preferences.service";
import * as i2 from "@angular/router";
var SNOOZE_PREFERENCE_ID = 'notificationsSnoozed';
var SNOOZE_TIME = 60 * 60 * 1000; // In milliseconds
var NotificationsService = /** @class */ (function () {
    function NotificationsService(ngZone, preferencesService, router) {
        this.ngZone = ngZone;
        this.preferencesService = preferencesService;
        this.router = router;
        this.snoozed$ = new BehaviorSubject(false);
        this._notifications = new Dictionary();
        this._highPriorityNotifications = new Dictionary();
        this._notificationGroups = new Dictionary();
        this._notificationsBehaviorSubject = new BehaviorSubject([]);
        this._highPriorityNotificationsBehaviorSubject = new BehaviorSubject([]);
        this._notificationGroupsBehaviorSubject = new BehaviorSubject([]);
        this._isSnoozed = false;
        this.hiddenHighPriorityNotificationIds = new Set();
        this.notifications$ = this._notificationsBehaviorSubject.asObservable().pipe(share());
        this.highPriorityNotifications$ = this._highPriorityNotificationsBehaviorSubject
            .asObservable()
            .pipe(share());
        this.notificationGroups$ = this._notificationGroupsBehaviorSubject.asObservable();
    }
    NotificationsService.prototype.init = function () {
        var _this = this;
        this._prefereceServiceSub = this.preferencesService.preferences$.subscribe(function (preferences) {
            var snoozed = preferences && preferences.get(SNOOZE_PREFERENCE_ID);
            if (isNil(snoozed))
                _this.unSnoozeNotifications();
            else {
                var timeLeftForSnooze = snoozed.from + SNOOZE_TIME - new Date().valueOf();
                if (timeLeftForSnooze > 0) {
                    _this.snoozed$.next((_this._isSnoozed = true));
                    _this.setSnoozeTimeout(timeLeftForSnooze);
                }
                else
                    _this.unSnoozeNotifications();
            }
        });
        return of(null);
    };
    NotificationsService.prototype.setNotification = function (notificationId, notificationConfig) {
        if (!notificationConfig)
            return this.removeNotification(notificationId);
        notificationConfig.id = notificationId;
        var notification = new Notification(notificationConfig);
        this._notifications.set(notificationId, notification);
        this.updateNotifications();
    };
    NotificationsService.prototype.setNotifications = function (notifications) {
        var _this = this;
        notifications.forEach(function (notification) { return _this.setNotification(notification.id, notification); });
    };
    NotificationsService.prototype.removeNotification = function (notificationId) {
        this._notifications.delete(notificationId);
        this.updateNotifications();
    };
    NotificationsService.prototype.removeNotificationGroup = function (groupId) {
        var _this = this;
        if (!groupId)
            return;
        var groupNotificationIds = [];
        this._notifications.forEach(function (notification) {
            if (notification.group === groupId)
                groupNotificationIds.push(notification.id);
        });
        if (groupNotificationIds.length) {
            groupNotificationIds.forEach(function (notificationId) { return _this._notifications.delete(notificationId); });
            this.updateNotifications();
        }
    };
    NotificationsService.prototype.setHiddenHighPriorityNotificationId = function (notificationId, filterOut) {
        if (filterOut === void 0) { filterOut = true; }
        if (filterOut)
            this.hiddenHighPriorityNotificationIds.add(notificationId);
        else
            this.hiddenHighPriorityNotificationIds.delete(notificationId);
        this.updateHighPriorityNotifications();
    };
    NotificationsService.prototype.selectNotification = function (notification) {
        if (notification.link)
            this.router.navigate(notification.link);
    };
    NotificationsService.prototype.updateHighPriorityNotifications = function () {
        var _this = this;
        this._highPriorityNotificationsBehaviorSubject.next(this._highPriorityNotifications
            .toArray()
            .filter(function (notification) {
            return !notification.isMinimized &&
                !_this.hiddenHighPriorityNotificationIds.has(notification.id);
        }));
    };
    NotificationsService.prototype.snoozeNotifications = function () {
        this.setSnooze(true);
    };
    NotificationsService.prototype.unSnoozeNotifications = function () {
        this.setSnooze(false);
    };
    /**
     * Sets the snooze and saves the user preference (or removes it if snooze is off)
     * @param isSnoozed Whether the snooze is turned on or off
     */
    NotificationsService.prototype.setSnooze = function (isSnoozed) {
        if (isSnoozed === this._isSnoozed)
            return;
        this.snoozed$.next((this._isSnoozed = isSnoozed));
        if (isSnoozed) {
            var snoozeStart = new Date();
            this.preferencesService.setPreference(SNOOZE_PREFERENCE_ID, {
                from: snoozeStart.valueOf(),
            });
            this.setSnoozeTimeout(SNOOZE_TIME);
            this._highPriorityNotifications.clear();
            this._highPriorityNotificationsBehaviorSubject.next([]);
        }
        else {
            clearTimeout(this._snoozeTimeout);
            this.preferencesService.removePreference(SNOOZE_PREFERENCE_ID);
            this.updateNotifications();
        }
    };
    NotificationsService.prototype.setSnoozeTimeout = function (time) {
        clearTimeout(this._snoozeTimeout);
        this._snoozeTimeout = setTimeout(this.unSnoozeNotifications.bind(this), time);
    };
    NotificationsService.prototype.updateNotifications = function () {
        var _this = this;
        clearTimeout(this._updateNotificationsTimeout);
        this._updateNotificationsTimeout = setTimeout(function () {
            var notificationsArray = _this._notifications.toArray().sort(_this.sortNotifications);
            _this._notificationsBehaviorSubject.next(notificationsArray);
            _this.setNotificationGroups();
            _this._notificationGroupsBehaviorSubject.next(_this._notificationGroups.toArray());
            var highPriorityNotifications = Dictionary.fromList(notificationsArray.filter(function (notification) { return notification.isHighPriority; }), 'id'), highPriorityNotificationsChanged = !_this._highPriorityNotifications.equalsWith(highPriorityNotifications, function (currentNotification, newNotification) {
                return (currentNotification &&
                    newNotification &&
                    (currentNotification === newNotification ||
                        currentNotification.timestamp === newNotification.timestamp));
            });
            if (highPriorityNotificationsChanged) {
                _this._highPriorityNotifications = highPriorityNotifications;
                _this.updateHighPriorityNotifications();
            }
        }, 500);
    };
    NotificationsService.prototype.sortNotifications = function (a, b) {
        if (a.priority === b.priority)
            return 0;
        return a.priority > b.priority ? 1 : -1;
    };
    NotificationsService.prototype.setNotificationGroups = function () {
        var _this = this;
        this._notificationGroups.clear();
        this._notifications
            .toArray()
            .sort(this.sortNotifications)
            .forEach(function (notification) {
            if (notification.group) {
                var existingGroup = _this._notificationGroups.get(notification.group);
                if (!existingGroup) {
                    existingGroup = notification.clone();
                    existingGroup.count = 0;
                    _this._notificationGroups.set(notification.group, existingGroup);
                }
                existingGroup.count += notification.count || 1;
            }
            else {
                _this._notificationGroups.set(notification.id, notification.clone());
            }
        });
    };
    NotificationsService.prototype.ngOnDestroy = function () {
        this._prefereceServiceSub && this._prefereceServiceSub.unsubscribe();
        this.snoozed$.complete();
    };
    NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i0.NgZone), i0.ɵɵinject(i1.PreferencesService), i0.ɵɵinject(i2.Router)); }, token: NotificationsService, providedIn: "root" });
    return NotificationsService;
}());
export { NotificationsService };
