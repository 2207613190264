var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Machine } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MachinesService } from '../../../../machines/services/machines.service';
var SoftwareInstallationEntityPanelComponent = /** @class */ (function (_super) {
    __extends(SoftwareInstallationEntityPanelComponent, _super);
    function SoftwareInstallationEntityPanelComponent(paris, machinesService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.machinesService = machinesService;
        _this.machineRepository = paris.getRepository(Machine);
        return _this;
    }
    SoftwareInstallationEntityPanelComponent.prototype.initEntity = function (installation, isExtendedData) {
        var _this = this;
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, installation, isExtendedData);
        this.machineRepository.getItemById(installation.assetId).subscribe(function (m) {
            _this.machine = m;
            _this.changeDetectorRef.markForCheck();
        });
    };
    SoftwareInstallationEntityPanelComponent.prototype.showLoggedOnUsers = function () {
        this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
            noShadow: true,
            hasCloseButton: false,
        });
    };
    return SoftwareInstallationEntityPanelComponent;
}(EntityPanelComponentBase));
export { SoftwareInstallationEntityPanelComponent };
