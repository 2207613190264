import { Routes } from '@angular/router';
import { MdeAllowedActionsGuard, AppConfigValueGuard, AuthenticatedGuard, EvaluationEnabledGuard, ExposedToAllMachineGroupsGuard, FeatureEnabledGuard, IsLiveResponseEnabled, NotOnboardGuard, OnboardGuard, } from '@wcd/auth';
import { BreadcrumbsResolver } from './breadcrumbs/services/breadcrumbs-resolver.service';
import { EnableAutomatedIrComponent, Feature } from '@wcd/config';
import { DEPRECATED_HUNTING_ROUTE, DEPRECATED_HUNTING_ROUTE2, HUNTING_ROUTE, LogicalOperator, } from '@wcd/shared';
import { MdeUserRoleActionEnum, FilterImpactedAssets } from '@wcd/domain';
import { FlagsComponent } from './flags/components/flags.component';
import { HistorySafeRedirectComponent } from './shared/components/history-safe-redirect.component';
export var FLAGS_URL = 'flags2';
var ɵ0 = {
    redirectTo: 'dashboards_junction',
}, ɵ1 = {
    MdeAllowedActionsGuardConfig: {
        FallbackOnUnauthorized: '/tvm_dashboard',
        NavigateToOnAuthorized: '/dashboard',
        AllowedActions: MdeUserRoleActionEnum.viewData,
        AbsolutePath: true,
    },
}, ɵ2 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ3 = {
    loadingComponentConfig: {
        entityTypeNameKey: 'investigation_entityType_singularName',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ4 = {
    loadingComponentConfig: {
        entityTypeNameKey: 'investigation_entityType_singularName',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ5 = {
    features: [Feature.LiveResponse],
    appConfigValidator: IsLiveResponseEnabled,
    loadingComponentConfig: {
        descriptionKey: 'loading_liveResponse',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ6 = {
    features: [Feature.Incidents],
    loadingComponentConfig: {
        entityTypeNameKey: 'incident_entityType_pluralName',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ7 = {
    features: [Feature.WebThreatProtectionReport],
    loadingComponentConfig: {
        descriptionKey: 'loading_web_threat_domains',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ8 = {
    features: {
        enableFeatures: [Feature.TvmPages, Feature.TvmBaselineCompliance],
        logicalOperator: LogicalOperator.And,
    },
    loadingComponentConfig: {
        descriptionKey: 'tvm_baseline_loading',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ9 = {
    features: {
        enableFeatures: [Feature.TvmPages, Feature.TvmBaselineCompliance],
        logicalOperator: LogicalOperator.And,
    },
    loadingComponentConfig: {
        descriptionKey: 'tvm_baseline_loading',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ10 = {
    features: {
        enableFeatures: [Feature.TvmPages],
    },
    loadingComponentConfig: {
        descriptionKey: 'loading_events',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ11 = {
    features: {
        enableFeatures: [Feature.TvmPages, Feature.TvmComparisonKit],
        logicalOperator: LogicalOperator.And,
    },
    loadingComponentConfig: {
        descriptionKey: 'loading_compare_kit',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ12 = {
    features: [Feature.TvmPages],
    loadingComponentConfig: {
        entityTypeNameKey: 'vulnerability_entityType_pluralName',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
    queryParams: {
        filters: "numOfImpactedAssets=" + FilterImpactedAssets.hasEffect,
    },
}, ɵ13 = {
    features: [Feature.TvmPages],
    loadingComponentConfig: {
        descriptionKey: 'loading_software_inventory',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ14 = {
    features: [Feature.TvmPages],
    loadingComponentConfig: {
        entityTypeNameKey: 'securityRecommendation_entityType_pluralName',
        transparent: true,
    },
    queryParams: {
        filters: 'status=Active',
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ15 = {
    features: [Feature.TvmPages],
    loadingComponentConfig: {
        descriptionKey: 'loading_remediation_page',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ16 = {
    features: [Feature.ActionHistory],
    loadingComponentConfig: {
        descriptionKey: 'loading_action_center',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ17 = {
    loadingComponentConfig: {
        entityTypeNameKey: 'machine_entityType_pluralName',
        transparent: true,
    },
}, ɵ18 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ19 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ20 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ21 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ22 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ23 = {
    features: [Feature.ThreatAnalytics3],
}, ɵ24 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ25 = {
    pageTitle: 'Playbooks',
    features: [Feature.Playbooks],
    enableAirsRoute: [
        '/settings/enable_airs',
        { messageClass: 'relative enable-auto-ir-investigation-message' },
    ],
    loadingComponentConfig: {
        descriptionKey: 'loading_playbooks',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ26 = {
    features: [Feature.WebThreatProtectionReport, Feature.WebContentFilteringPolicy],
    loadingComponentConfig: {
        descriptionKey: 'loading_web_protection_reports',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
}, ɵ27 = {
    features: [Feature.Reports, Feature.ThreatReport],
    loadingComponentConfig: {
        descriptionKey: 'loading_report',
        transparent: true,
    },
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData | MdeUserRoleActionEnum.tvmViewData,
    },
}, ɵ28 = {
    features: [Feature.Partners],
    loadingComponentConfig: {
        descriptionKey: 'common_loading',
        transparent: true,
    },
}, ɵ29 = {
    features: [Feature.OnboardingWizardUpgrade],
}, ɵ30 = {
    features: [Feature.ConfigurationManagement],
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.configurationManagement,
    },
}, ɵ31 = {
    features: [Feature.WebContentFilteringPolicy],
    loadingComponentConfig: {
        descriptionKey: 'loading_category_reports',
        transparent: true,
    },
}, ɵ32 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    loadingComponentConfig: {
        descriptionKey: 'loading_cms',
        transparent: true,
    },
}, ɵ33 = {
    pageTitle: 'Flags',
    features: [Feature.Flags],
};
export var appRoutes = [
    {
        path: '',
        resolve: {
            breadcrumbs: BreadcrumbsResolver,
        },
        children: [
            {
                path: '',
                component: HistorySafeRedirectComponent,
                data: ɵ0,
                pathMatch: 'full',
            },
            {
                path: 'dashboards_junction',
                children: [],
                data: ɵ1,
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
            },
            {
                path: 'enable_airs',
                component: EnableAutomatedIrComponent,
                data: ɵ2,
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
            },
            {
                // This is a dummy route, it should not be used!
                // It is defined only to force localhost Angular compiler to detect the SccSettingsModule lazy module as local build goes through non scc module tree
                path: 'settings_scc_mtp',
                loadChildren: 'app/scc-settings/scc-settings.module#SccSettingsModule',
            },
            {
                // This is a dummy route, it should not be used!
                // It is defined only to force localhost Angular compiler to detect the scconboarding lazy module as local build goes through non scc module tree
                path: 'enable_scc_mtp',
                loadChildren: 'app/scc-onboarding-package/scc-onboarding-package.module#SccOnboardingPackageModule',
            },
            {
                path: 'investigation',
                loadChildren: 'app/graph/airs-investigation/investigation-graph.module#InvestigationGraphModule',
                data: ɵ3,
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
            },
            {
                path: 'mtp-investigation',
                loadChildren: 'app/graph/mtp-investigation/mtp-investigation-routing.module#MtpInvestigationRoutingModule',
                data: ɵ4,
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
            },
            {
                path: 'live-response',
                loadChildren: 'app/@entities/live_response/live-response.module#LiveResponseModule',
                canActivate: [FeatureEnabledGuard, AppConfigValueGuard, MdeAllowedActionsGuard],
                data: ɵ5,
            },
            {
                path: 'incidents',
                loadChildren: 'app/@entities/incidents/incidents.module#IncidentsModule',
                canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
                data: ɵ6,
            },
            {
                path: 'web-threat-domains',
                loadChildren: 'app/@entities/domains/domains.module#DomainsModule',
                canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
                data: ɵ7,
            },
            {
                path: 'baseline-compliance',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
                data: ɵ8,
            },
            {
                path: 'baseline-compliance_t',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/baseline-compliance/baseline-compliance.module#BaselineComplianceModule',
                data: ɵ9,
            },
            {
                path: 'tvm-event-insights',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/events/change-event.module#ChangeEventModule',
                data: ɵ10,
            },
            {
                path: 'compare-kit',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/comparison-tool/comparison-tool.module#ComparisonToolModule',
                data: ɵ11,
            },
            {
                path: 'vulnerabilities',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/vulnerabilities/vulnerabilities.module#VulnerabilitiesModule',
                data: ɵ12,
            },
            {
                path: 'software-inventory',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/software-inventory/software-inventory.module#SoftwareInventoryModule',
                data: ɵ13,
            },
            {
                path: 'security-recommendations',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/security-recommendations/security-recommendations.module#SecurityRecommendationsModule',
                data: ɵ14,
            },
            {
                path: 'remediation',
                canActivate: [FeatureEnabledGuard, AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/@tvm/remediation/remediation.module#TvmRemediationModule',
                data: ɵ15,
            },
            {
                path: 'action-center',
                canActivate: [FeatureEnabledGuard, MdeAllowedActionsGuard],
                loadChildren: 'app/@entities/action_center/action-center.module#ActionCenterModule',
                data: ɵ16,
            },
            {
                path: 'machines',
                loadChildren: 'app/@entities/machines/machines.module#MachinesModule',
                data: ɵ17,
            },
            {
                path: 'machine/:id',
                redirectTo: '/machines/:id/main',
                pathMatch: 'prefix',
            },
            {
                path: 'files',
                loadChildren: 'app/@entities/files/files.module#FilesModule',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ18,
            },
            {
                path: 'urls',
                loadChildren: 'app/@entities/urls/urls.module#UrlsModule',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ19,
            },
            {
                path: 'ips',
                loadChildren: 'app/@entities/ips/ips.module#IpsModule',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ20,
            },
            {
                path: 'users',
                loadChildren: 'app/@entities/users/users.module#UsersModule',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ21,
            },
            {
                path: HUNTING_ROUTE,
                loadChildren: 'app/hunting-package/hunting-package.module#HuntingPackageModule',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ22,
            },
            {
                path: DEPRECATED_HUNTING_ROUTE,
                redirectTo: HUNTING_ROUTE,
                pathMatch: 'full',
            },
            {
                path: DEPRECATED_HUNTING_ROUTE2,
                redirectTo: HUNTING_ROUTE,
                pathMatch: 'full',
            },
            {
                path: 'custom_detection',
                loadChildren: 'app/@entities/scheduled_hunting/scheduled-hunting-routing.module#ScheduledHuntingRoutingModule',
            },
            {
                path: 'scheduled_hunting',
                redirectTo: 'custom_detection',
                pathMatch: 'prefix',
            },
            {
                path: 'tutorials',
                loadChildren: 'app/tutorials/tutorials.module#TutorialsModule',
            },
            {
                path: 'threatanalytics2',
                redirectTo: 'threatanalytics3',
            },
            {
                path: 'threatanalytics3',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
                data: ɵ23,
                loadChildren: 'app/dashboards/threat-analytics3/threat-analytics.module#ThreatAnalyticsModule',
            },
            {
                path: 'evaluation',
                canActivate: [
                    AuthenticatedGuard,
                    OnboardGuard,
                    ExposedToAllMachineGroupsGuard,
                    EvaluationEnabledGuard,
                    MdeAllowedActionsGuard,
                ],
                data: ɵ24,
                loadChildren: 'app/evaluation/evaluation.module#EvaluationModule',
            },
            {
                path: 'tenanthealth',
                canActivate: [AuthenticatedGuard, OnboardGuard],
                loadChildren: 'app/service-health/service-health.module#ServiceHealthModule',
            },
            {
                path: 'playbooks',
                loadChildren: 'app/@entities/playbooks/playbooks.module#PlaybooksModule',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ25,
            },
            {
                path: 'reports/webThreatProtection',
                loadChildren: 'app/reporting/web-threat-protection/web-threat-protection-reporting.module#WebThreatProtectionReportingModule',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ26,
            },
            {
                path: 'reports',
                loadChildren: 'app/reporting/reporting.module#ReportingModule',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ27,
            },
            {
                path: 'interoperability',
                loadChildren: 'app/interoperability/interoperability.module#InteroperabilityModule',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
                data: ɵ28,
            },
            {
                path: 'onboarding2',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, NotOnboardGuard],
                data: ɵ29,
                loadChildren: 'app/onboarding-wizard/onboarding.wizard.module#OnboardingWizardModule',
            },
            {
                path: 'preferences2',
                canActivate: [AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard],
                loadChildren: 'app/admin/admin.module#AdminModule',
            },
            {
                path: 'configuration-management',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
                data: ɵ30,
                loadChildren: 'app/configuration-management/configuration-management.module#ConfigurationManagementModule',
            },
            {
                path: 'web-content-filtering-report',
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard, OnboardGuard],
                loadChildren: 'app/@entities/web_content_filtering/web-content-filtering-report.module#WebContentFilteringReportModule',
                data: ɵ31,
            },
            {
                path: 'cms',
                canActivate: [AuthenticatedGuard, OnboardGuard, MdeAllowedActionsGuard],
                data: ɵ32,
                loadChildren: 'app/cms/cms.module#CmsModule',
            },
            {
                path: FLAGS_URL,
                component: FlagsComponent,
                canActivate: [AuthenticatedGuard, FeatureEnabledGuard],
                data: ɵ33,
            },
        ],
    },
];
var AppRoutingModule = /** @class */ (function () {
    function AppRoutingModule() {
    }
    return AppRoutingModule;
}());
export { AppRoutingModule };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29, ɵ30, ɵ31, ɵ32, ɵ33 };
