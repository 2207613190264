import { ReportWidgetComponent } from '../components/report-widget.component.base';
import { Type } from '@angular/core';

const lastIds: { [rowOrColumns: string]: number } = { row: 0, column: 0 };

export abstract class ReportWidgetsLayoutModel {
	id: number;
	widgetComponents: Array<ReportLayoutWidgetConfig>;
	className: string;

	type: string;

	constructor(config: ReportWidgetsLayoutConfig) {
		this.id = ++lastIds[this.type];
		this.className = config.className;
		this.widgetComponents = (config.widgets || []).map(
			(widget: ReportLayoutWidgetConfig | Type<ReportWidgetComponent>) => {
				if (!(<ReportLayoutWidgetConfig>widget).type) {
					widget = {
						type: <Type<ReportWidgetComponent>>widget,
						weight: 1,
					};
				} else if (isNaN((<ReportLayoutWidgetConfig>widget).weight))
					(<ReportLayoutWidgetConfig>widget).weight = 1;

				return <ReportLayoutWidgetConfig>widget;
			}
		);
	}
}

export interface ReportWidgetsLayoutConfig {
	className?: string;
	widgets?: Array<ReportLayoutWidgetConfig | Type<ReportWidgetComponent>>;
}

export interface ReportLayoutWidgetConfig {
	type: Type<ReportWidgetComponent>;
	weight?: number;
	custom?: any;
}
