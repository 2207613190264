import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { UrlEntityPanelComponent } from './components/url.entity-panel.component';
import { UrlEntityTypeService } from './services/url.entity-type.service';
import { UrlEntityComponent } from './components/url.entity.component';
import { UrlsService } from './services/urls.service';
import { EntityCustomTiActionsConfigService } from '../common/services/entity.custom-ti-actionconfig.service';
import { UrlAlertsSummaryWidgetComponent } from './components/widgets/url.alerts-summary.widget';
import { ReportsModule } from '../../reports/reports.module';
import { A11yModule } from '@angular/cdk/a11y';
import {UrlEntityResolver} from "./services/url.entity.resolver";
import {UrlBreadcrumbsResolver} from "./services/url.breadcrumbs.resolver";

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, ReportsModule, A11yModule],
	providers: [UrlEntityTypeService, UrlsService, EntityCustomTiActionsConfigService, UrlEntityResolver, UrlBreadcrumbsResolver],
	declarations: [UrlEntityPanelComponent, UrlEntityComponent, UrlAlertsSummaryWidgetComponent],
	entryComponents: [UrlEntityPanelComponent, UrlEntityComponent, UrlAlertsSummaryWidgetComponent],
})
export class UrlEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(UrlEntityTypeService);
	}
}
