/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../dataviews/components/dataview.component.ngfactory";
import * as i2 from "../../../../../../dataviews/components/dataview.component";
import * as i3 from "../../../../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i4 from "../../../../../../dialogs/services/dialogs.service";
import * as i5 from "../../../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i6 from "../../../../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i7 from "@angular/router";
import * as i8 from "../../../../../../dataviews/services/dataview-repository.service";
import * as i9 from "../../../../../../insights/services/app-insights.service";
import * as i10 from "../../../../../../dialogs/services/errors-dialog.service";
import * as i11 from "../../../../../../global_entities/services/entity-panels.service";
import * as i12 from "../../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i13 from "../../../../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "@angular/cdk/a11y";
import * as i16 from "../../../../../../dialogs/confirm/confirm.service";
import * as i17 from "../../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i18 from "@angular/common";
import * as i19 from "../services/profile-device.fields.service";
import * as i20 from "../../../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i21 from "../../../../../../rbac/services/rbac.service";
import * as i22 from "./profile-device-configurations.dataview.component";
import * as i23 from "../../../../../../tvm/services/tvm-download.service";
var styles_ProfileDeviceConfigurationsDataviewComponent = [];
var RenderType_ProfileDeviceConfigurationsDataviewComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ProfileDeviceConfigurationsDataviewComponent, data: {} });
export { RenderType_ProfileDeviceConfigurationsDataviewComponent as RenderType_ProfileDeviceConfigurationsDataviewComponent };
function View_ProfileDeviceConfigurationsDataviewComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "dataview", [["class", "wcd-full-height"], ["id", "basline-device-configuration-dataview"]], null, null, null, i1.View_DataViewComponent_0, i1.RenderType_DataViewComponent)), i0.ɵdid(1, 245760, null, 0, i2.DataViewComponent, [i3.WicdSanitizerService, i4.DialogsService, i5.PanelService, i6.PreferencesService, i7.ActivatedRoute, i8.DataviewRepositoryService, i7.Router, i0.ChangeDetectorRef, i9.AppInsightsService, i10.ErrorsDialogService, i11.EntityPanelsService, i12.FeaturesService, i13.AppContextService, i14.Paris, i15.LiveAnnouncer, i16.ConfirmationService, i17.I18nService], { allowFilters: [0, "allowFilters"], allowPaging: [1, "allowPaging"], disableSelection: [2, "disableSelection"], navigateOnChange: [3, "navigateOnChange"], id: [4, "id"], searchEnabled: [5, "searchEnabled"], allowColumnCustomization: [6, "allowColumnCustomization"], giveSearchBoxMoreSpace: [7, "giveSearchBoxMoreSpace"], ignoreQueryParams: [8, "ignoreQueryParams"], dataViewConfig: [9, "dataViewConfig"], fields: [10, "fields"], repository: [11, "repository"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = false; var currVal_1 = true; var currVal_2 = true; var currVal_3 = false; var currVal_4 = "basline-device-configuration-dataview"; var currVal_5 = true; var currVal_6 = false; var currVal_7 = true; var currVal_8 = true; var currVal_9 = _co.dataViewConfig; var currVal_10 = _co.fields; var currVal_11 = _co.configurationsRepo; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_ProfileDeviceConfigurationsDataviewComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_ProfileDeviceConfigurationsDataviewComponent_1)), i0.ɵdid(1, 16384, null, 0, i18.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.sourceValue; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileDeviceConfigurationsDataviewComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "profile-device-configurations-dataview", [], null, null, null, View_ProfileDeviceConfigurationsDataviewComponent_0, RenderType_ProfileDeviceConfigurationsDataviewComponent)), i0.ɵprd(512, null, i19.ProfileDeviceFieldsService, i19.ProfileDeviceFieldsService, [i20.TzDateService, i17.I18nService, i12.FeaturesService, i21.RbacService]), i0.ɵdid(2, 114688, null, 0, i22.ProfileDeviceConfigurationsDataviewComponent, [i19.ProfileDeviceFieldsService, i23.TvmDownloadService, i14.Paris], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var ProfileDeviceConfigurationsDataviewComponentNgFactory = i0.ɵccf("profile-device-configurations-dataview", i22.ProfileDeviceConfigurationsDataviewComponent, View_ProfileDeviceConfigurationsDataviewComponent_Host_0, { profileName: "profileName", deviceName: "deviceName", sourceValue: "sourceValue" }, {}, []);
export { ProfileDeviceConfigurationsDataviewComponentNgFactory as ProfileDeviceConfigurationsDataviewComponentNgFactory };
