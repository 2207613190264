import { Injectable, OnDestroy } from '@angular/core';
import { catchError, map, switchMap, tap, mergeMap } from 'rxjs/operators';
import { Paris, DataSet } from '@microsoft/paris';
import {
	ExtendedOutbreak,
	ExtendedOutbreaksApiCall,
	ExtendedOutbreaksCalculation,
	GetNewOutbreaksCountAPICall,
	OutbreakDailyImpactedDevices,
	PatchOutbreakUserStateAPICall,
	ThreatReportType,
	outbreakTags,
	getReportTypeI18nKey,
	ThreatExposureType,
} from '@wcd/domain';
import { BehaviorSubject, EMPTY, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { AuthService } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService, FlavorService, PreferencesService } from '@wcd/config';
import { StretchedDonutBarItem } from '@wcd/charts';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { KnownColor } from '../../../shared/models/colors/known-colors.models';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
import { I18nService } from '@wcd/i18n';
import { HUNTING_ROUTE, LocalStorageService } from '@wcd/shared';
import {
	DEFAULT_THREAT_ANALYTICS_PREFERENCES,
	OutbreaksCalculationStatus,
	OutbreaksDashboardPreferences,
	OutbreakSortOption,
} from '../threat.model';
import { sccHostService } from '@wcd/scc-interface';
import { clone, cloneDeep } from 'lodash-es';
import { AppFlavorConfig } from '@wcd/scc-common';
import { DistributionData } from '../../../shared/modules/distribution/distribution.component';
import { FilterValuesChecklistData } from '@wcd/ng-filters';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';

const THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY: string = 'threatAnalytics_dashboard';

@Injectable()
export class OutbreakService implements OnDestroy {
	private lastVisitTimes: Map<string, Date>;
	private extOutbreakUpdates$: Subject<ExtendedOutbreak> = new Subject<ExtendedOutbreak>();
	private extOutbreaksSource$: BehaviorSubject<ExtendedOutbreaksCalculation> = new BehaviorSubject<
		ExtendedOutbreaksCalculation
	>(null);
	private outbreakUpdatesSubscription: Subscription;

	private calculationStatus: OutbreaksCalculationStatus;
	private allOutbreaksSource$: Subject<DataSet<ExtendedOutbreak>> = new Subject<
		DataSet<ExtendedOutbreak>
	>();

	private notFilteredOutbreaksSource$: Subject<Array<ExtendedOutbreak>> = new Subject<
		Array<ExtendedOutbreak>
	>();

	private changeCountBehavior$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
	changeCount$: Observable<number> = this.changeCountBehavior$.asObservable();

	allOutbreaks$: Observable<DataSet<ExtendedOutbreak>> = this.allOutbreaksSource$.asObservable();
	notFilteredOutbreaks$: Observable<
		Array<ExtendedOutbreak>
	> = this.notFilteredOutbreaksSource$.asObservable();

	outbreakAllImpact: Array<OutbreakDailyImpactedDevices>;

	extOutbreaks$: Observable<ExtendedOutbreaksCalculation> = this.extOutbreaksSource$.asObservable();

	distributionData$: Observable<Array<DistributionData>>;

	private isMtpFeatureEnabled: boolean;

	private isK8sMigrationEnabled: boolean;

	constructor(
		private paris: Paris,
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService,
		protected preferencesService: PreferencesService,
		private router: Router,
		private knownColorsService: KnownColorsService,
		private i18nService: I18nService,
		private localStorageService: LocalStorageService,
		private flavorService: FlavorService,
		private activatedRoute: ActivatedRoute,
		private appInsightsService: AppInsightsService
	) {
		const threatAnalyticsMtpFeatures: Array<string> = [
			Feature.ThreatAnalyticsMTPForMDATP,
			Feature.ThreatAnalyticsMTP,
		];
		this.isMtpFeatureEnabled = featuresService.isAnyEnabled(threatAnalyticsMtpFeatures);
		this.isK8sMigrationEnabled = featuresService.isEnabled(Feature.K8SMigrationThreatAnalytics);

		this.init();
	}

	private init() {
		if (
			!this.appConfigService.isOnboardingComplete ||
			this.appConfigService.isSuspended ||
			this.appConfigService.isDeleted
		) {
			return;
		}

		this.getOutbreaksChangeCountAsync().subscribe();
	}

	getExtOutbreaks(): Observable<ExtendedOutbreaksCalculation> {
		this.getLastVisitTimes();

		return this.paris
			.apiCall(ExtendedOutbreaksApiCall, {
				isMtp: this.isMtpFeatureEnabled,
				isK8sMigration: this.isK8sMigrationEnabled,
			})
			.pipe(
				map((outbreaksData: ExtendedOutbreaksCalculation) => {
					return {
						...outbreaksData,
						items: ExtendedOutbreak.adjustOutbreaksData(outbreaksData.items, this.lastVisitTimes),
					};
				}),
				tap((outbreaksCalculation: ExtendedOutbreaksCalculation) => {
					this.extOutbreaksSource$.next(outbreaksCalculation);
				})
			);
	}

	getOutbreakById(outbreakId: string): Observable<ExtendedOutbreak> {
		return this.extOutbreaks$.pipe(
			map((extOutbreaks) => {
				const outbreak: ExtendedOutbreak =
					extOutbreaks && extOutbreaks.items.find((extOutbreak) => extOutbreak.id === outbreakId);
				return outbreak;
			})
		);
	}

	getAllImpact(): Observable<Array<OutbreakDailyImpactedDevices>> {
		return this.extOutbreaksSource$.pipe(
			map((extOutbreaks) => extOutbreaks.items.map((extOutbreak) => extOutbreak.impact)),
			tap((allImpact) => (this.outbreakAllImpact = allImpact))
		);
	}

	extractImpactFromExtOutbreaks(
		extOutbreaks: Array<ExtendedOutbreak>
	): Array<OutbreakDailyImpactedDevices> {
		return extOutbreaks.map((extOutbreak) => extOutbreak.impact);
	}

	setUserFavoriteState(outbreakId: string, isFavorite: boolean) {
		return this.extOutbreaksSource$.pipe(
			tap((extOutbreaks) => {
				extOutbreaks.items.find(
					(extOutbreak) => extOutbreak.id === outbreakId
				).userState.isFavorite = isFavorite;
			})
		);
	}

	getOutbreaksChangeCountAsync(): Observable<number> {
		return this.paris
			.apiCall(GetNewOutbreaksCountAPICall)
			.pipe(tap((changeCount) => this.changeCountBehavior$.next(changeCount)));
	}

	markAllOutbreaksRead(outbreaks: Array<ExtendedOutbreak>): Observable<void> {
		// TODO: update when change count has changed
		const affectedOutbreaks = (outbreaks || []).filter((o) => o.isNew);
		if (!affectedOutbreaks || affectedOutbreaks.length < 1) {
			return EMPTY;
		}

		const markAllReadPayload = affectedOutbreaks.map((o) => ({
			OutbreakId: o.id,
			LastVisitTime: new Date(),
			IsFavorite: o.userState.isFavorite,
		}));

		return this.paris
			.apiCall(PatchOutbreakUserStateAPICall, markAllReadPayload)
			.pipe(tap(() => this.changeCountBehavior$.next(0)));
	}

	onOutbreakVisit(outbreakId: string): Observable<void> {
		return this.paris
			.apiCall(PatchOutbreakUserStateAPICall, [{ OutbreakId: outbreakId, LastVisitTime: new Date() }])
			.pipe(
				switchMap(() => this.getOutbreaksChangeCountAsync()),
				tap((changeCount: number) => this.changeCountBehavior$.next(changeCount)),
				mergeMap(() => EMPTY),
				catchError((err) => {
					return throwError(
						'Failed to set outbreak ' + outbreakId + 'as visited: ' + err.toString()
					);
				})
			);
	}

	private getLastVisitTimes(): Map<string, Date> {
		if (!this.lastVisitTimes) {
			// parse json object to map, because mistakenly I used map as json object i.e map["key"] = val instead of map.set("key", val)
			const data = this.localStorageService.getItem(this.getStorageKey());
			this.lastVisitTimes = new Map<string, Date>();
			if (data) {
				const jsonObj = JSON.parse(data);
				for (const key in jsonObj) {
					if (jsonObj.hasOwnProperty(key)) {
						this.lastVisitTimes.set(key, new Date(jsonObj[key]));
					}
				}
			}
		}
		return this.lastVisitTimes;
	}

	private getStorageKey(): string {
		const currentUserName = this.authService.currentUser
			? this.authService.currentUser.name
			: 'defaultUser';

		return `${currentUserName}_outbreaks.lastVisit`;
	}

	sortOutbreaks(outbreaks: Array<ExtendedOutbreak>, sortOptions: OutbreakSortOption) {
		if (!outbreaks) return;

		if (sortOptions === OutbreakSortOption.impact) {
			outbreaks.sort((o1: ExtendedOutbreak, o2: ExtendedOutbreak) =>
				this.isMtpFeatureEnabled
					? this.compareMtpOutbreaksImpact(o1, o2, true)
					: this.compareMdatpOutbreaksImpact(o1, o2, true)
			);
		} else if (sortOptions === OutbreakSortOption.date) {
			outbreaks.sort(this.compareOutbreaksLastUpdated.bind(this));
		} else if (sortOptions === OutbreakSortOption.exposure) {
			outbreaks.sort(this.compareOutbreaksExposure.bind(this));
		}

		// save sorting preferences
		const preferences = this.getDashboardPreferences();
		if (preferences.sortOptions !== sortOptions) {
			preferences.sortOptions = sortOptions;
			this.saveDashboardPreferences(preferences);
		}
	}

	getDashboardPreferences(): OutbreaksDashboardPreferences {
		return (
			this.preferencesService.getPreference(THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY) ||
			DEFAULT_THREAT_ANALYTICS_PREFERENCES
		);
	}

	private saveDashboardPreferences(preferences: OutbreaksDashboardPreferences): void {
		this.preferencesService.setPreference(THREAT_ANALYTICS_DASHBOARD_PREFERENCES_KEY, preferences);
	}

	getActiveColor(outbreak: ExtendedOutbreak): KnownColor {
		return outbreak.impact.compromisedMachines > 0
			? sccHostService.isSCC
				? this.knownColorsService.knownColorsMap['redDark']
				: this.knownColorsService.knownColorsMap['red']
			: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'];
	}

	getRedColor = () =>
		sccHostService.isSCC
			? this.knownColorsService.knownColorsMap['redDark']
			: this.knownColorsService.knownColorsMap['red'];

	getResolvedColor(outbreak: ExtendedOutbreak): KnownColor {
		return outbreak.impact.blockedMachines > 0
			? sccHostService.isSCC
				? this.knownColorsService.knownColorsMap['green']
				: this.knownColorsService.knownColorsMap['greenLight']
			: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'];
	}

	getGreenColor = () =>
		sccHostService.isSCC
			? this.knownColorsService.knownColorsMap['green']
			: this.knownColorsService.knownColorsMap['greenLight'];

	compareMdatpOutbreaksImpact(
		o1: ExtendedOutbreak,
		o2: ExtendedOutbreak,
		sortDescending: boolean = false
	): number {
		const impact1 = o1.impact;
		const impact2 = o2.impact;

		let result: number;
		if (impact1 && impact2) {
			result =
				impact1.compromisedMachines - impact2.compromisedMachines ||
				impact1.blockedMachines - impact2.blockedMachines ||
				this.compareOutbreaksLastUpdated(o1, o2);
		} else {
			result = impact1 ? 1 : impact2 ? -1 : this.compareOutbreaksLastUpdated(o1, o2);
		}

		return sortDescending ? result * -1 : result;
	}

	compareMtpOutbreaksImpact(
		o1: ExtendedOutbreak,
		o2: ExtendedOutbreak,
		sortDescending: boolean = false
	): number {
		const alertsCount1 = o1.alertsCount;
		const alertsCount2 = o2.alertsCount;

		const result =
			alertsCount1.activeAlertsCount - alertsCount2.activeAlertsCount ||
			alertsCount1.resolvedAlertsCount - alertsCount2.resolvedAlertsCount ||
			this.compareOutbreaksLastUpdated(o1, o2);

		return sortDescending ? result * -1 : result;
	}

	compareOutbreaksLastUpdated(o1: ExtendedOutbreak, o2: ExtendedOutbreak): number {
		return o2.lastUpdatedOn.getTime() - o1.lastUpdatedOn.getTime() || (o1.id > o2.id ? -1 : 1);
	}

	compareOutbreaksExposure(o1: ExtendedOutbreak, o2: ExtendedOutbreak): number {
		return o1.exposureScore > o2.exposureScore ? -1 : 1;
	}

	getTopThreats$(outbreakSortOption: OutbreakSortOption): Observable<Array<StretchedDonutBarItem>> {
		return this.notFilteredOutbreaksSource$.pipe(
			map((outbreaksData) => {
				const items = cloneDeep(outbreaksData);
				this.sortOutbreaks(items, outbreakSortOption);
				if (outbreakSortOption === OutbreakSortOption.exposure) {
					return this.getThreatExposureStretchedDonutChartItems(
						items.filter((item) => item.cveAndScidNotDefined == false).slice(0, 4)
					);
				}
				return this.getStretchedDonutChartItems(items.slice(0, 4));
			})
		);
	}

	getThreatExposureStretchedDonutChartItems(
		outbreaks: Array<ExtendedOutbreak>
	): Array<StretchedDonutBarItem> {
		const data: Array<StretchedDonutBarItem> = outbreaks.map(
			this.convertThreatExposureToStretchedDonutChartItem
		);
		return data.filter((item) => item);
	}

	getStretchedDonutChartItems(outbreaks: Array<ExtendedOutbreak>): Array<StretchedDonutBarItem> {
		const data: Array<StretchedDonutBarItem> = outbreaks.map(
			this.isMtpFeatureEnabled
				? this.convertMtpOutbreakToStretchedDonutChartItem
				: this.convertMdatpOutbreakToStretchedDonutChartItem
		);
		return data.filter((item) => item);
	}

	convertMdatpOutbreakToStretchedDonutChartItem = (outbreak: ExtendedOutbreak): StretchedDonutBarItem => {
		const outbreakImpact = outbreak.impact;
		return {
			id: outbreak.id,
			title: outbreak.displayName,
			value: outbreakImpact && outbreakImpact.compromisedMachines,
			total: outbreakImpact && outbreakImpact.compromisedMachines + outbreakImpact.blockedMachines,
			valueColor: outbreakImpact
				? this.getActiveColor(outbreak)
				: this.knownColorsService.knownColorsMap['neutralTertiary'],
			totalColor: outbreakImpact
				? this.getResolvedColor(outbreak)
				: this.knownColorsService.knownColorsMap['neutralTertiary'],
			width: '100%',
			height: 8,
			noDataText: outbreakImpact
				? ''
				: this.i18nService.get(
						'dashboard.threatAnalytics.landingPage.tabs.threats.notSupportedThreat'
				  ),
		};
	};

	convertMtpOutbreakToStretchedDonutChartItem = (outbreak: ExtendedOutbreak): StretchedDonutBarItem => {
		const totalAlertsCount =
			outbreak.alertsCount.activeAlertsCount + outbreak.alertsCount.resolvedAlertsCount;
		return {
			id: outbreak.id,
			title: outbreak.displayName,
			value: outbreak.alertsCount.activeAlertsCount,
			total: totalAlertsCount,
			valueColor: totalAlertsCount
				? this.getRedColor()
				: this.knownColorsService.knownColorsMap['neutralTertiary'],
			totalColor: totalAlertsCount
				? this.getGreenColor()
				: this.knownColorsService.knownColorsMap['neutralTertiary'],
			width: '100%',
			height: 8,
			noDataText: outbreak.impact
				? ''
				: this.i18nService.get(
						'dashboard.threatAnalytics.landingPage.tabs.threats.notSupportedThreat'
				  ),
		};
	};

	convertThreatExposureToStretchedDonutChartItem = (outbreak: ExtendedOutbreak): StretchedDonutBarItem => {
		const noAvailableText = this.i18nService.get('dashboard_threatAnalytics_mtp_exposureNotAvailable');
		const customText =
			outbreak.exposureSeverity.type === ThreatExposureType.None
				? noAvailableText
				: `${outbreak.exposureScore} - ${outbreak.exposureSeverity.type}`;
		return {
			id: outbreak.id,
			title: outbreak.displayName,
			value: outbreak.exposureScore,
			total: 100,
			valueColor: this.knownColorsService.knownColorsMap[outbreak.exposureSeverity.className],
			totalColor: this.knownColorsService.knownColorsMap['neutralTertiary'],
			width: '100%',
			height: 8,
			useCustomDisplay: true,
			noDataText: customText,
		};
	};

	isThreatAnalyticsMdeEnabled = () => {
		return (
			this.appConfigService.isMdatpActive &&
			this.flavorService.isEnabled(AppFlavorConfig.threatAnalytics.mde)
		);
	};

	goToThreat(outbreakId: string): void {
		this.router.navigate(['/threatanalytics3', outbreakId]);
	}

	goToHunting(encodedQuery: string) {
		this.router.navigate([`/${HUNTING_ROUTE}`], {
			queryParams: {
				query: encodedQuery,
				runQuery: 'true',
			},
		});
	}

	setOutbreaksData(dataSet: DataSet<ExtendedOutbreak>) {
		const status = dataSet.meta as OutbreaksCalculationStatus;
		this.calculationStatus = status;
		this.allOutbreaksSource$.next(dataSet);
	}

	setNotFilteredOutbreaks() {
		this.paris
			.apiCall(ExtendedOutbreaksApiCall, {
				isMtp: this.isMtpFeatureEnabled,
				isK8sMigration: this.isK8sMigrationEnabled,
			})
			.pipe(
				tap((outbreaks: ExtendedOutbreaksCalculation) => {
					this.calculationStatus = {
						devicesCalculationStatus: outbreaks.devicesCalculationStatus,
						mailboxesCalculationStatus: outbreaks.mailboxesCalculationStatus,
						threatExposureCalculationStatus: outbreaks.threatExposureCalculationStatus,
					};

					this.notFilteredOutbreaksSource$.next(outbreaks.items);
					this.distributionData$ = this.createSummaryObservableForOutbreakTags(outbreaks.items);
				})
			)
			.subscribe();
	}

	getOutbreaksCalculationStatus() {
		return this.calculationStatus;
	}

	getFilters(): Observable<Record<string, FilterValuesChecklistData<number | string>>> {
		const filterMappings = {
			tags: {
				values: Object.values(
					outbreakTags.map((tag) => {
						return {
							value: tag.type,
							name: this.i18nService.get(tag.nameI18nKey),
							priority: tag.priority,
						};
					})
				),
			},
			reportType: {
				values: Object.values(ThreatReportType).map((reportType) => {
					return {
						value: reportType,
						name: this.i18nService.get(getReportTypeI18nKey(reportType)),
					};
				}),
			},
		};
		return of(filterMappings);
	}

	filterByTag(tag: DistributionData) {
		const outbreakTag = outbreakTags.find((t) => this.i18nService.get(t.nameI18nKey) == tag.title);
		if (!outbreakTag) {
			return;
		}

		this.appInsightsService.track({
			id: 'DistributionFiltersChanged',
			component: 'OutbreakDistributionTags',
			type: TrackingEventType.Filter,
			value: outbreakTag.type,
		});

		const queryParams: Params = clone(this.activatedRoute.snapshot.queryParams);
		queryParams.filters += `,tags=${outbreakTag.type}`;
		this.router.navigate(['./threatanalytics3'], {
			relativeTo: this.activatedRoute,
			queryParams,
			queryParamsHandling: 'merge',
			replaceUrl: true,
		});
	}

	createSummaryObservableForOutbreakTags(data: Array<ExtendedOutbreak>): Observable<DistributionData[]> {
		if (!this.featuresService.isEnabled(Feature.ThreatAnalyticsOutbreakTags)) {
			return of(null);
		}

		const result = outbreakTags
			.map((tag) => {
				return {
					title: this.i18nService.get(tag.nameI18nKey),
					barColor: this.knownColorsService.knownColorsMap[tag.color].name,
					mainText: data ? data.filter((o) => o.tags.includes(tag.type)).length : 0,
					priority: tag.priority,
				};
			})
			.sort((a, b) => (a.priority < b.priority ? -1 : 1));

		return of(result);
	}

	ngOnDestroy() {
		this.extOutbreakUpdates$.complete();
		this.extOutbreaksSource$.complete();
		this.outbreakUpdatesSubscription && this.outbreakUpdatesSubscription.unsubscribe();
	}
}
