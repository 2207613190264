
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<h4 class="definition-list-title">
				{{ 'machines_entityDetails_sections_details' | i18n }}
			</h4>
			<machine-entity-details
				[machine]="machine"
				(loggedOnUsersClick)="showLoggedOnUsers()"
				[includeMachineName]="true"
				[collapsibleSections]="false"
			></machine-entity-details>
		</section>
	