var _a;
var wcdKeyCodes = {
    up: 38,
    down: 40,
    left: 37,
    right: 39,
    home: 36,
    end: 35,
    tab: 9,
    pageUp: 33,
    pageDown: 34,
};
var WCDDirectionalKeyCodes = (_a = {},
    _a[wcdKeyCodes.up] = 1,
    _a[wcdKeyCodes.down] = 1,
    _a[wcdKeyCodes.left] = 1,
    _a[wcdKeyCodes.right] = 1,
    _a[wcdKeyCodes.home] = 1,
    _a[wcdKeyCodes.end] = 1,
    _a[wcdKeyCodes.tab] = 1,
    _a[wcdKeyCodes.pageUp] = 1,
    _a[wcdKeyCodes.pageDown] = 1,
    _a);
/**
 * Returns true if the keycode is a directional keyboard key.
 */
export function wcdIsDirectionalKeyCode(which) {
    return !!WCDDirectionalKeyCodes[which];
}
