var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config) { return config.data.serviceUrls.incidentUpdate; }, ɵ1 = function (incidentUpdateOptions) {
    return {
        data: Object.assign({ Ids: incidentUpdateOptions.incidentIds }, incidentUpdateOptions.determination
            ? {
                Determination: incidentUpdateOptions.determination.id,
            }
            : null, incidentUpdateOptions.assignedTo === null
            ? {
                AssignedTo: null,
            } :
            {
                AssignedTo: incidentUpdateOptions.assignedTo,
            }, incidentUpdateOptions.status ? { Status: incidentUpdateOptions.status.id } : null, incidentUpdateOptions.classification
            ? {
                Classification: incidentUpdateOptions.classification.id,
            }
            : null, incidentUpdateOptions.title ? { Title: incidentUpdateOptions.title } : null, incidentUpdateOptions.comment ? { Comment: incidentUpdateOptions.comment } : null),
    };
};
var IncidentsUpdateApiCall = /** @class */ (function (_super) {
    __extends(IncidentsUpdateApiCall, _super);
    function IncidentsUpdateApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IncidentsUpdateApiCall = __decorate([
        ApiCall({
            name: 'Update incidents',
            endpoint: 'incidents',
            method: 'POST',
            baseUrl: ɵ0,
            separateArrayParams: true,
            parseQuery: ɵ1,
        })
    ], IncidentsUpdateApiCall);
    return IncidentsUpdateApiCall;
}(ApiCallModel));
export { IncidentsUpdateApiCall };
export { ɵ0, ɵ1 };
