import {EntityField, ModelBase, ValueObject} from "@microsoft/paris";
import { Machine } from '../machine/machine.entity';
import { AadUser } from '../AAD/user/aad.user.entity';
import { Mailbox } from '../mailbox/mailbox.entity';
import { Application } from "../application/application-entity";

@ValueObject({
	singularName: 'Incident Impacted Entity',
	pluralName: 'Incident Impacted Entities',
	readonly: true,
})
export class IncidentImpactedEntities extends ModelBase {
	@EntityField({ data: "Machine", arrayOf: Machine })
	machines: Array<Machine>;

	@EntityField({ data: "User", arrayOf: AadUser })
	users: Array<AadUser>;

	@EntityField({ data: "Mailbox", arrayOf: Mailbox })
	mailboxes: Array<Mailbox>;

	@EntityField({ data: "Apps", arrayOf: Application })
	apps: Array<Application>;
}
