import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

/**
 * returns a URL-friendly base 64 encoded query
 * Can be used to generate share URL
 */
@ApiCall({
	name: 'URL-encode hunting query text',
	endpoint: 'queries/encode',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseQuery: (queryText: string) => ({ data: { QueryText: queryText } }),
})
export class EncodeQueryTextApiCall extends ApiCallModel<string, string> {}

/**
 *  Returns decoded query string
 *  Can be used to parse query from share URL
 */
@ApiCall({
	name: 'URL-decode hunting query text',
	endpoint: 'queries/decode',
	method: 'GET',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseQuery: (encodedQuery: string) => ({ params: { query: encodedQuery } }),
	parseData: (data: string) => data,
})
export class DecodeQueryTextApiCall extends ApiCallModel<string> {}
