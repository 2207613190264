/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./overflow-container.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./overflow-container.component";
var styles_OverflowContainerComponent = [i0.styles];
var RenderType_OverflowContainerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_OverflowContainerComponent, data: {} });
export { RenderType_OverflowContainerComponent as RenderType_OverflowContainerComponent };
function View_OverflowContainerComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_OverflowContainerComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0, null, View_OverflowContainerComponent_3))], null, null); }
function View_OverflowContainerComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "li", [], [[1, "aria-label", 0]], null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "margin-right.px": 0, "visibility": 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_OverflowContainerComponent_2)), i1.ɵdid(6, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(7, { itemData: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _ck(_v, 3, 0, ((_v.context.index !== (((_co.visibleItems == null) ? null : _co.visibleItems.length) - 1)) && _co.itemsHorizontalSpacing), (_co.initComplete ? null : "hidden")); _ck(_v, 2, 0, currVal_1); var currVal_2 = _ck(_v, 7, 0, _v.context.$implicit); var currVal_3 = _co.itemTemplate; _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = _v.context.$implicit.ariaLabel; _ck(_v, 0, 0, currVal_0); }); }
export function View_OverflowContainerComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(402653184, 1, { itemsListElementRef: 0 }), (_l()(), i1.ɵeld(1, 0, [[1, 0], ["itemsList", 1]], null, 4, "ul", [["class", "items-list wcd-flex-items-center-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_OverflowContainerComponent_1)), i1.ɵdid(4, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.visibleItems || _co.items); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OverflowContainerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "overflow-container", [], null, null, null, View_OverflowContainerComponent_0, RenderType_OverflowContainerComponent)), i1.ɵdid(1, 704512, null, 0, i3.OverflowContainerComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var OverflowContainerComponentNgFactory = i1.ɵccf("overflow-container", i3.OverflowContainerComponent, View_OverflowContainerComponent_Host_0, { minimumExpendedItems: "minimumExpendedItems", items: "items", itemsContainer: "itemsContainer", itemTemplate: "itemTemplate", createOverflowItemData: "createOverflowItemData", horizontalPadding: "horizontalPadding", overflowItemWidth: "overflowItemWidth", itemsHorizontalSpacing: "itemsHorizontalSpacing" }, {}, []);
export { OverflowContainerComponentNgFactory as OverflowContainerComponentNgFactory };
