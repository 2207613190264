import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Investigation Actions Summary',
	pluralName: 'Investigation Actions Summaries',
	readonly: true,
})
export class InvestigationActionsSummary extends ModelBase {
	@EntityField({ defaultValue: 0 })
	remediated: number;
	@EntityField({ defaultValue: 0 })
	total: number;
	@EntityField({ defaultValue: 0 })
	running: number;
	@EntityField({ defaultValue: 0 })
	pending: number;
	@EntityField({ defaultValue: 0 })
	queued: number;
}
