
		<wcd-panel (close)="destroy()" [settings]="settings">
			<editable-comments-list
				[comments]="comments"
				[isSaving]="isSavingComment"
				[rbac]="rbacSettings"
				(save)="saveComment($event)"
				[readonly]="!incident.isFullyMachineRbacExposed"
				[isLoading]="isLoadingComments"
			></editable-comments-list>
		</wcd-panel>
	