<div class="wcd-full-height wcd-flex-vertical">
	<section class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal">
		<h4 class="definition-list-title">{{'ips.details.ip' | i18n}}</h4>
		<dl class="key-values" role="none">
			<dt>{{ 'ips.fields.alerts.title' | i18n }}</dt>
			<dd>
				<wcd-contents-state [contentState]="alertsSeveritySummaryContentState">
					<ng-container loading *ngTemplateOutlet="loading"></ng-container>
					<ng-container error *ngTemplateOutlet="error"></ng-container>
					<ng-container complete *ngTemplateOutlet="alerts"></ng-container>
					<ng-container empty *ngTemplateOutlet="alerts"></ng-container>

					<ng-template #alerts>
						<severity-summary [severitiesCountMap]="alertsSeveritiesCountMap"
										  size="compact"></severity-summary>
					</ng-template>
				</wcd-contents-state>
			</dd>
		</dl>
	</section>
	<section class="wcd-flex-none wcd-padding-large-horizontal">
		<ip-entity-details [entity]="ip"></ip-entity-details>
	</section>
</div>


<ng-template #error>
	<span>{{ 'help.externalLoadError' | i18n:{ asset: dataAsset } }}</span>
</ng-template>

<ng-template #loading>
	<span><i class="loader-icon wcd-margin-right"></i>{{ 'loading' | i18n }}</span>
</ng-template>
