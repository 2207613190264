import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class SccRolesGuard implements CanActivate {
	constructor() {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
		if (!route.routeConfig.data) {
			return true;
		}
		const { sccroles } = route.routeConfig.data;

		return sccHostService.auth.isInRoles(sccroles).then(res => {
			if (res.some(role => !role)) {
				sccHostService.state.go('homepage');
				return Promise.reject(false);
			}
			return Promise.resolve(true);
		});
	}
}
