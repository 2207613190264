import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { MainSearchEntityTypesService, SearchEntityType } from '../services/main-search-entity-types.service';
import { SearchService } from '../services/search.service';
import { merge, Subject, Subscription } from 'rxjs';
import { ShortcutEvent, ShortcutsService } from '../../dialogs/shortcuts/shortcuts.service';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { FancySelectComponent } from '../../forms/components/fancy-select.component';
import { MainAppState, MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { NavigationEnd, Router } from '@angular/router';
import { debounceTime, filter } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { omit } from 'lodash-es';
import { breadcrumbsStateService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';

const MIN_SEARCH_LENGTH: number = 3;
const LAST_SEARCH_TYPE_PREFERENCE_ID: string = 'lastSearchType';
const SEARCH_RESULTS_URLS: ReadonlyArray<RegExp> = Object.freeze<RegExp>([
	/^\/?search[\./]/,
	/^\/?file[\./]/,
	/^\/?ip[\./]/,
	/^\/?url[\./]/,
]);

@Component({
	selector: 'main-search',
	templateUrl: './main-search.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./main-search.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MainSearchComponent implements OnInit, OnDestroy {
	@Input() isHovered: boolean = false;
	@Output() close: EventEmitter<void> = new EventEmitter<void>();
	@Input() isScc = false;

	searchDebouncer: Subject<string> = new Subject();
	searchTerm: string;
	readonly searchEntityTypes: ReadonlyArray<SearchEntityType>; // TODO: The type here should really be ReadonlyArray<Omit<SearchEntityType>>, exists in TS 2.8+.
	currentSearchEntityType: SearchEntityType;
	textFocused: boolean;
	typeFocused: boolean;
	lengthError: boolean = false;
	persistSearchValue: boolean = false;

	get active(): boolean {
		return this.typeFocused || this.textFocused;
	}

	get isShownInFixedMode(): boolean {
		const headerSearchBtn: HTMLDivElement = <HTMLDivElement>(
			document.getElementById('main-header-search-btn')
		);
		return headerSearchBtn && window.getComputedStyle(headerSearchBtn).display === 'none';
	}

	private readonly _shortcutsSubscription: Subscription;
	private readonly _headerSearchClicksSubscription: Subscription;
	private readonly _navigationSubscription: Subscription;

	@ViewChild('searchInput', { static: false }) searchInput: ElementRef;
	@ViewChild('searchTypeInput', { static: false }) searchTypeInput: FancySelectComponent;

	constructor(
		private searchService: SearchService,
		private changeDetectionRef: ChangeDetectorRef,
		shortcutsService: ShortcutsService,
		mainAppStateService: MainAppStateService,
		private appInsightsService: AppInsightsService,
		private preferencesService: PreferencesService,
		router: Router,
		private readonly mainSearchEntityTypesService: MainSearchEntityTypesService,
		private liveAnnouncer: LiveAnnouncer,
		private i18nService: I18nService,
	) {
		let type: SearchEntityType;
		const lastTypeId = this.preferencesService.getPreference<string>(LAST_SEARCH_TYPE_PREFERENCE_ID);

		this.searchEntityTypes = mainSearchEntityTypesService.all.map(
			searchEntityType => omit(searchEntityType, 'entityType') as SearchEntityType
		);

		if (lastTypeId) {
			type = this.searchEntityTypes.find(type => type.id === lastTypeId);
		} else {
			type = mainSearchEntityTypesService.default;
		}
		this.setEntityType(type);

		this._shortcutsSubscription = shortcutsService.events$
			.pipe(filter((event: ShortcutEvent) => event === ShortcutEvent.search))
			.subscribe(() => {
				if (!this.isShownInFixedMode) {
					mainAppStateService.toggleMainSearch(true);
				}
				setTimeout(() => this.focusOnSearchTerm(), 100);
			});

		this._headerSearchClicksSubscription = mainAppStateService.state$.subscribe(
			(mainAppState: MainAppState) => {
				if (mainAppState.mainSearchShown) {
					setTimeout(() => this.focusOnSearchTerm(), 100);
				}
			}
		);

		this._navigationSubscription = merge(
			router.events.pipe(filter(event => event instanceof NavigationEnd)),
			mainAppStateService.navChange$
		).subscribe((event: NavigationEnd | string) => {
			const url = event instanceof NavigationEnd ? event.url : event;
			if (
				!SEARCH_RESULTS_URLS.some((routeRegExp: RegExp) => routeRegExp.test(url)) &&
				!this.persistSearchValue
			) {
				this.clearSearch();
			}
			this.persistSearchValue = false;
		});
	}

	ngOnInit() {
		this.searchDebouncer.pipe(debounceTime(300)).subscribe(() => this.resolveType());
	}

	ngOnDestroy() {
		this._shortcutsSubscription && this._shortcutsSubscription.unsubscribe();
		this._headerSearchClicksSubscription && this._headerSearchClicksSubscription.unsubscribe();
		this._navigationSubscription && this._navigationSubscription.unsubscribe();
	}

	resolveType() {
		const type: SearchEntityType = this.searchService.resolveType(this.searchTerm);
		if (type) {
			this.setEntityType(type);
		}
	}

	search() {
		if (!this.searchTerm) {
			return;
		} else if (this.searchTerm.length && this.searchTerm.length < MIN_SEARCH_LENGTH) {
			this.lengthError = true;
			this.liveAnnouncer.announce(this.i18nService.get('main.search.minLengthError'), 'assertive');
			setTimeout(() => {
				this.lengthError = false;
				this.changeDetectionRef.markForCheck();
			}, 3000);
			return;
		} else {
			// Resetting the breadcrumbs fixes time the breadcrumbs would stay when automatically
			// redirected to the search result (i.e. when searching for an IP or a URL)
			if (!sccHostService.isSCC) {
				breadcrumbsStateService.reset();
			}
			this.close.emit(null);
			this.persistSearchValue = true;
			this.searchService.onSearch(this.searchTerm, this.currentSearchEntityType);
			this.changeDetectionRef.markForCheck();
			this.appInsightsService.track({
				type: TrackingEventType.Selection,
				id: `searchType_${this.currentSearchEntityType.id}`,
				component: 'Main Search',
			});
		}
	}

	typeBlurred() {
		setTimeout(() => {
			this.typeFocused = false;
			this.changeDetectionRef.markForCheck();
		}, 100);
	}

	typeChanged() {
		this.setTypeToPreference();
		this.focusOnSearchTerm();
	}

	cancelSearch() {
		this.clearSearch();
		this.close.emit(null);
		if (this.searchTypeInput.dropdown.isOpen) {
			this.searchTypeInput.dropdown.close();
		}
		this.searchInput.nativeElement.blur();
		this.typeFocused = false;
		this.changeDetectionRef.markForCheck();
	}

	searchTermChanged() {
		this.searchTerm = this.searchTerm.trim();

		this.searchDebouncer.next();
	}

	private setEntityType(type?: SearchEntityType) {
		const matchingSearchEntityType = type
			? this.searchEntityTypes.find(searchEntityType => searchEntityType.id === type.id)
			: null;
		this.currentSearchEntityType = matchingSearchEntityType || this.mainSearchEntityTypesService.default;
		this.setTypeToPreference();
		this.changeDetectionRef.markForCheck();
	}

	private focusOnSearchTerm() {
		this.searchInput.nativeElement.focus();
		this.changeDetectionRef.markForCheck();
	}

	private clearSearch() {
		this.searchTerm = null;
		this.persistSearchValue = false;
		this.changeDetectionRef.markForCheck();
	}

	private setTypeToPreference() {
		this.preferencesService.setPreference(
			LAST_SEARCH_TYPE_PREFERENCE_ID,
			this.currentSearchEntityType.id
		);
	}
}
