import { NgModule } from '@angular/core';
import { AdvancedSettingsComponent } from './components/advanced-settings.component';
import { PasswordDisplayComponent } from './components/password-display.component';
import { AdvancedSettingsBackendService } from './services/advanced-settings.backend.service';
import { AdvancedSettingsStore } from './services/advanced-settings.store';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [AdvancedSettingsComponent, PasswordDisplayComponent],
	exports: [],
	providers: [AdvancedSettingsBackendService, AdvancedSettingsStore],
})
export class AdvancedSettingsModule {}
