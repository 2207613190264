var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { ProfileComplianceStatus } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';
import { ExternalProfileActions } from '../services/profile.entity-type.service';
var ProfileEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ProfileEntityPanelComponent, _super);
    function ProfileEntityPanelComponent(paris, changeDetectorRef, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.SpinnerSize = SpinnerSize;
        _this.isLoading = true;
        _this.noComplianceStatus = false;
        _this.isSaving = false;
        return _this;
    }
    Object.defineProperty(ProfileEntityPanelComponent.prototype, "profile", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ProfileEntityPanelComponent.prototype.initEntity = function (profile, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, profile, isExtendedData);
        this.isEnabled = this.profile.status;
        this.setProfilesComplianceStatus();
        this.changeDetectorRef.markForCheck();
    };
    ProfileEntityPanelComponent.prototype.setProfilesComplianceStatus = function () {
        var _this = this;
        this.paris
            .getRepository(ProfileComplianceStatus).getItemById(this.profile.id)
            .subscribe(function (status) {
            _this.profilesComplianceStatus = [status];
            _this.isLoading = false;
            _this.changeDetectorRef.detectChanges();
        }, function (e) {
            _this.isLoading = false;
            _this.noComplianceStatus = true;
            _this.changeDetectorRef.detectChanges();
        });
    };
    ProfileEntityPanelComponent.prototype.updateStatus = function () {
        this.isSaving = true;
        _super.prototype.runEntityAction.call(this, ExternalProfileActions.UPDATE_STATUS_ACTION);
    };
    return ProfileEntityPanelComponent;
}(EntityPanelComponentBase));
export { ProfileEntityPanelComponent };
