var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { sccHostService } from '@wcd/scc-interface';
var ɵ0 = function (config, query) {
    return "Save/AirAdminAction";
}, ɵ1 = function (manualAdminActionRequest) {
    return {
        params: {
            tenantId: manualAdminActionRequest.TenantId,
        },
        data: { Payload: manualAdminActionRequest, TenantId: manualAdminActionRequest.TenantId },
    };
};
var ManualActionOnEmailApiCall = /** @class */ (function (_super) {
    __extends(ManualActionOnEmailApiCall, _super);
    function ManualActionOnEmailApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ManualActionOnEmailApiCall = __decorate([
        ApiCall({
            name: 'Manual action on email',
            endpoint: ɵ0,
            method: 'POST',
            baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
            customHeaders: { 'content-type': 'multipart/form-data' },
            parseQuery: ɵ1,
        })
    ], ManualActionOnEmailApiCall);
    return ManualActionOnEmailApiCall;
}(ApiCallModel));
export { ManualActionOnEmailApiCall };
export var AdminActionType;
(function (AdminActionType) {
    AdminActionType[AdminActionType["None"] = 0] = "None";
    AdminActionType[AdminActionType["MailAction"] = 1] = "MailAction";
    AdminActionType[AdminActionType["BlockUrlAction"] = 2] = "BlockUrlAction";
    AdminActionType[AdminActionType["BlockSenderAction"] = 3] = "BlockSenderAction";
})(AdminActionType || (AdminActionType = {}));
export var MdoManualAction;
(function (MdoManualAction) {
    MdoManualAction["MoveToJunk"] = "MoveToJunk";
    MdoManualAction["MoveToInbox"] = "MoveToInbox";
    MdoManualAction["Delete"] = "HardDeleteEmail";
    MdoManualAction["SoftDelete"] = "SoftDeleteEmail";
    MdoManualAction["MoveToDeletedItems"] = "MoveToDeletedItems";
})(MdoManualAction || (MdoManualAction = {}));
export var SubActionTypeValue;
(function (SubActionTypeValue) {
    SubActionTypeValue[SubActionTypeValue["MoveToJunk"] = 1] = "MoveToJunk";
    SubActionTypeValue[SubActionTypeValue["MoveToInbox"] = 2] = "MoveToInbox";
    SubActionTypeValue[SubActionTypeValue["Delete"] = 5] = "Delete";
    SubActionTypeValue[SubActionTypeValue["SoftDelete"] = 6] = "SoftDelete";
    SubActionTypeValue[SubActionTypeValue["MoveToDeletedItems"] = 7] = "MoveToDeletedItems";
})(SubActionTypeValue || (SubActionTypeValue = {}));
//TODO: LIRAN - need to add 'MTP_ActionCenter' value to this enum when we have it in Substrate repo (client task: https://microsoft.visualstudio.com/OS/_workitems/edit/32054585)
export var AdminActionSource;
(function (AdminActionSource) {
    AdminActionSource[AdminActionSource["None"] = 0] = "None";
    AdminActionSource[AdminActionSource["MTP_CustomDetection"] = 1] = "MTP_CustomDetection";
    AdminActionSource[AdminActionSource["MTP_BulkAction"] = 2] = "MTP_BulkAction";
    AdminActionSource[AdminActionSource["OATP_Explorer"] = 3] = "OATP_Explorer";
})(AdminActionSource || (AdminActionSource = {}));
export { ɵ0, ɵ1 };
