import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '@wcd/domain';

@ApiCall({
	name: 'Get Support Central configuration',
	endpoint: 'api/SupportCentral',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
})
export class SupportCentralApiCall extends ApiCallModel<SupportCentralResponse> {}

export interface SupportCentralResponse {
	token: string;
	endpointUrl: string;
}
