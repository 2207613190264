var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { RelationshipRepository } from '@microsoft/paris';
import { PanelContainer } from '@wcd/panels';
import { Vulnerability, TvmEndPoint } from '@wcd/domain';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { VulnerabilityFieldsService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { VulnerabilityService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
var RelatedCvesPanelComponent = /** @class */ (function (_super) {
    __extends(RelatedCvesPanelComponent, _super);
    function RelatedCvesPanelComponent(router, globalEntityTypesService, featuresService, vulnerabilityFieldsService, tvmDownloadService, vulnerabilityService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.vulnerabilityFieldsService = vulnerabilityFieldsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.vulnerabilityService = vulnerabilityService;
        _this.i18nService = i18nService;
        _this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
        _this.fields = vulnerabilityFieldsService.fields.filter(function (field) {
            return ['name', 'severity', 'numOfImpactedAssets'].includes(field.id);
        });
        _this.entityType = globalEntityTypesService.getEntityType(Vulnerability);
        return _this;
    }
    Object.defineProperty(RelatedCvesPanelComponent.prototype, "sourceValue", {
        get: function () {
            return this._sourceValue;
        },
        set: function (value) {
            this._sourceValue = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RelatedCvesPanelComponent.prototype, "relatedCvesTitle", {
        get: function () {
            return this._relatedCvesTitle;
        },
        set: function (value) {
            this._relatedCvesTitle = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RelatedCvesPanelComponent.prototype, "repository", {
        set: function (value) {
            this._repository = value;
        },
        enumerable: true,
        configurable: true
    });
    RelatedCvesPanelComponent.prototype.exportCveData = function () {
        this.tvmDownloadService.downloadCsvFromRelationshipRepository(this._repository, TvmEndPoint.Analytics, this.relatedCvesTitle + " - " + this.i18nService.get('tvm.common.relatedCVE.title'));
    };
    RelatedCvesPanelComponent.prototype.openVulnerabilityPanel = function (vulnerability) {
        this.vulnerabilityService.showVulnerabilityPanel(vulnerability, this.includeExposedMachinesComponent, this.isNetworkGearCve);
    };
    return RelatedCvesPanelComponent;
}(PanelContainer));
export { RelatedCvesPanelComponent };
