import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LegendComponent } from './legend.component';
import { WcdIconsModule } from '@wcd/icons';
import { TooltipsModule } from '@wcd/dialogs';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [WcdIconsModule, TooltipsModule, I18nModule, CommonModule],
	declarations: [LegendComponent],
	exports: [LegendComponent],
})
export class LegendModule {}
