import { Pipe, PipeTransform } from '@angular/core';

const trail = '...',
	defaultLimit = 30;

@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
	transform(value: string, limit?: number): string {
		if (!limit) limit = defaultLimit;
		return value.length > limit ? value.substring(0, limit) + trail : value;
	}
}
