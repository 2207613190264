
		<wcd-panel (close)='destroy()' [settings]='settings'>
			<form
				#troubleshootingForm='ngForm'
				class='wcd-auto-height-responsive wcd-full-height wcd-flex-vertical'
			>
				<div class='wcd-padding-large-horizontal wcd-flex-1 wcd-scroll-vertical wcd-padding-all'>
					<header
						class='wcd-flex-none wcd-padding-vertical wcd-flex-horizontal'
					>
						<div class='wcd-flex-1 wcd-flex-center-vertical'>
							<h3 #panelHeader class='side-panel-title wcd-font-weight-bold'>
								<fab-icon iconName="Warning" contentClass="color-text-warning"></fab-icon>
								{{ 'machines.entityDetails.actions_turnOnTroubleshootingMode.panel.title' | i18n }}
							</h3>
						</div>
					</header>
					<div class='wcd-margin-largeMedium-top wcd-margin-small-bottom'>
						<ng-container>
							<ul>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item1' | i18n }}
								</li>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item2' | i18n }}
								</li>
								<li>
									{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_description_item3' | i18n }}
								</li>
							</ul>
						</ng-container>
					</div>
					<div class='wcd-margin-largeMedium-top wcd-font-weight-bold'>
						{{ 'machines.entityDetails.actions.turnOnTroubleshootingMode.panel.diagnosticData.title' | i18n }}
					</div>
					<div class='wcd-margin-small-bottom'>
						<p>
							{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_diagnosticData_description_1' | i18n }}
						</p>
						<p>
							{{ 'machines_entityDetails_actions_turnOnTroubleshootingMode_panel_diagnosticData_description_2' | i18n }}
						</p>
					</div>
					<div class='wcd-padding-large-vertical'>
						<a [href]='cookiesLink' target='_blank' class='wcd-padding-xsmall-left'>
							{{ "machines.entityDetails.actions.turnOnTroubleshootingMode.panel.privacyAndCookiesLink.text" | i18n }}
						</a>
					</div>

				</div>

				<footer
					class='wcd-padding-large-horizontal wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal'>
					<fab-primary-button
						data-track-id='Submit'
						data-track-type='Button'
						[disabled]='isSaving'
						(onClick)='submitRequest()'
						[text]="('buttons.submit' | i18n)">
						<fab-spinner *ngIf='isSaving' [size]='SpinnerSize.xSmall'></fab-spinner>
					</fab-primary-button>
				</footer>
			</form>
		</wcd-panel>
	