import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineSecurityControlProperty } from './machine-security-control-property.value-object';

@ValueObject({
	singularName: 'Security control',
	pluralName: 'Security controls',
	readonly: true,
})
export class MachineSecurityControl extends ModelBase {
	@EntityField({ data: 'ControlType' })
	controlType: string;

	@EntityField({ data: 'Score' })
	score: number;

	@EntityField({ data: 'OverallStatus' })
	overallStatus: string;

	@EntityField({ data: 'MaxTotalScore' })
	maxTotalScore: number;

	@EntityField({
		data: 'PropertyImprovementScores',
		parse: data => {
			return Object.keys(data).map(key => {
				return { propertyName: key, improvementScore: data[key] };
			});
		},
		arrayOf: MachineSecurityControlProperty,
	})
	propertyImprovementScores: Array<MachineSecurityControlProperty>;
}
