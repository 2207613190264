import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { stringToNumberParse } from './aad.user.entity';

@ValueObject({
	singularName: 'User Details',
	pluralName: 'Users Details',
	readonly: true,
})
export class AadUserMcasDetails extends ModelBase {
	@EntityField({ data: 'McasInvestigationPriority', parse: stringToNumberParse })
	investigationPriority: number;

	@EntityField({ data: 'McasPercentile', parse: stringToNumberParse })
	percentile: number;
}
