import { Component, Injector, OnChanges } from '@angular/core';
import { Mailbox } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../models/entity-type.interface';
import { FeaturesService, Feature } from '@wcd/config';

@Component({
	selector: 'mailbox-entity-details',
	templateUrl: './mailbox-entity-details.component.html',
})
export class MailboxEntityDetailsComponent extends EntityDetailsComponentBase<Mailbox> implements OnChanges {
	mailboxPageLink: string;
	showLink: boolean;
	shouldLinkTabOut: boolean;
	displayName: string;
	mailboxEntity: EntityType<Mailbox>;

	constructor(injector: Injector, globalEntityTypesService: GlobalEntityTypesService, private featuresService: FeaturesService) {
		super(injector);
		this.mailboxEntity = globalEntityTypesService.getEntityType(Mailbox);
	}

	ngOnChanges() {
		this.showLink = !this.featuresService.isEnabled(Feature.PortedSccPages);
		this.mailboxPageLink = this.mailboxEntity.getEntitiesLink([this.entity]);
		this.shouldLinkTabOut = this.mailboxEntity.getUseExternalRouting([this.entity])
		this.displayName = this.mailboxEntity.getEntityName(this.entity);
	}
}
