import { OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import * as i0 from "@angular/core";
var TelemetryService = /** @class */ (function () {
    function TelemetryService() {
        this._events$ = new ReplaySubject();
        this._errors$ = new ReplaySubject();
        this.events$ = this._events$.asObservable();
        this.errors$ = this._errors$.asObservable();
    }
    TelemetryService.prototype.trackEvent = function (event) {
        this._events$.next(event);
    };
    TelemetryService.prototype.trackError = function (errorEvent) {
        this._errors$.next(errorEvent);
    };
    TelemetryService.prototype.ngOnDestroy = function () {
        this._events$.complete();
        this._errors$.complete();
    };
    TelemetryService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TelemetryService_Factory() { return new TelemetryService(); }, token: TelemetryService, providedIn: "root" });
    return TelemetryService;
}());
export { TelemetryService };
