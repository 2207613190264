
		<ng-container *ngLet="(iconImageUrl$ | async) as iconImageUrl">
			<div class="wcd-flex-center-horizontal wcd-padding-mediumSmall-top">
				<fab-persona
					[imageUrl]="iconImageUrl"
					[size]="size24"
					[imageInitials]="nameInitials"
					[hidePersonaDetails]="true"
				></fab-persona>
			</div>
		</ng-container>
	