import { EndpointManagementPageComponent } from './components/endpoint-management-page.component';
import { Routes } from '@angular/router';
export var offBoardingTitleKey = 'offboarding';
var ɵ0 = {
    pageTitle: 'Offboarding',
    pageTitleKey: offBoardingTitleKey,
};
export var endpointManagementRoutes = [
    {
        path: 'suspended/offboarding',
        component: EndpointManagementPageComponent,
        data: ɵ0,
    },
];
var EndpointManagementModule = /** @class */ (function () {
    function EndpointManagementModule() {
    }
    return EndpointManagementModule;
}());
export { EndpointManagementModule };
export { ɵ0 };
