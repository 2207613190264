import { Injectable } from '@angular/core';
import { ColorsService } from '../../shared/services/colors.service';
import { KnownColorsService } from '../../shared/services/known-colors.service';
import { KnownColorName } from '../../shared/models/colors/known-colors.models';
import { BaselineComplianceProfileStatusPercentage } from '../components/tvm-profile-compliance-status-bars/baseline-compliance-profile-status-percentage.enum';

const baselineComplianceProfileStatusColorName: Record<
	BaselineComplianceProfileStatusPercentage,
	KnownColorName
> = {
	[BaselineComplianceProfileStatusPercentage.MoreThan75PercentConfiguredDevices]: 'greenLight',
	[BaselineComplianceProfileStatusPercentage.MoreThan50LessThan75PercentConfiguredDevices]: 'lowSeverity',
	[BaselineComplianceProfileStatusPercentage.MoreThan25LessThan50PercentConfiguredDevices]: 'mediumSeverity',
	[BaselineComplianceProfileStatusPercentage.LessThan25PercentConfiguredDevices]: 'highSeverity',
	[BaselineComplianceProfileStatusPercentage.NoDataDevices]: 'neutralTertiary',
};

@Injectable()
export class BaselineComplianceProfileStatusColorService extends ColorsService<
	BaselineComplianceProfileStatusPercentage
> {
	constructor(knownColorsService: KnownColorsService) {
		super(knownColorsService, baselineComplianceProfileStatusColorName);
	}
}
