import { Dimensions } from './dimensions.interface';
import { Position } from './position.interface';

/**
 * Given a position with top/left in `Position` and `Dimensions` that need to
 * be placed in that position, returns a `Position` that can be in that position and also fits inside
 * the current window. A margin value can be provided to offset the position from the specific `position`.
 */
export function fitPosition(
	position: Position,
	dimensions: Dimensions,
	margin: number = 0,
	offset: number = 0
): Position {
	const windowDimensions: Dimensions = getWindowDimensions(),
		resultPosition: Position = {
			top: position.top + offset,
			left: position.left + offset,
		};

	if (position.left + margin + offset + dimensions.width > windowDimensions.width - margin)
		resultPosition.left = position.left - dimensions.width - offset;

	if (resultPosition.left < margin)
		resultPosition.left = windowDimensions.width - dimensions.width - margin;

	if (position.top + offset + dimensions.height > windowDimensions.height - margin)
		resultPosition.top = position.top - dimensions.height - offset;

	if (resultPosition.top < margin)
		resultPosition.top = windowDimensions.height - dimensions.height - margin;

	return resultPosition;
}

export function fitsPosition(
	position: Position,
	dimensions: Dimensions,
	margin = 0,
	offset = 0
): FitsPositionInterface {
	const windowDimensions = getWindowDimensions();

	return {
		right: position.left + dimensions.width + margin + offset <= windowDimensions.width,
		bottom: position.top + dimensions.height + margin + offset <= windowDimensions.height,
		top: dimensions.height + margin * 2 <= windowDimensions.height,
		left: dimensions.width + margin * 2 <= windowDimensions.width,
	};
}

export function constrainTo(
	dimensions1: Dimensions,
	dimensions2?: Dimensions,
	margin: number = 0
): Dimensions {
	if (!dimensions2) dimensions2 = getWindowDimensions();

	return {
		width: Math.min(dimensions1.width, dimensions2.width - 2 * margin),
		height: Math.min(dimensions1.height, dimensions2.height - 2 * margin),
	};
}

function getWindowDimensions(): Dimensions {
	return {
		width: document.documentElement.clientWidth,
		height: document.documentElement.clientHeight,
	};
}

interface FitsPositionInterface {
	top: boolean;
	right: boolean;
	bottom: boolean;
	left: boolean;
}
