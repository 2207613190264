import { TypedChanges, TypedChange, OnChanges } from '../types/angular/on-changes';
import { mapValues } from 'lodash-es';
import { SimpleChange } from '@angular/core';

export const wasChanged = <T>(change: TypedChange<T>) =>
	change && change.currentValue !== change.previousValue;

export const wasChangedAndExists = <T>(
	change: TypedChange<T>,
	existsPredicate: (value: T) => boolean = value => value !== undefined
) => wasChanged(change) && existsPredicate(change.currentValue);

export const generateChanges = <K extends keyof any>(
	props: Record<K, any>,
	instance?: Partial<Record<K, any>>
) => {
	return mapValues(
		props,
		(val, key) => new SimpleChange(instance ? instance[key] : undefined, val, !instance)
	);
};

export const isNgChanges = <T extends object>(comp: T | OnChanges<T>): comp is OnChanges<T> => {
	const maybeOnChanges = comp as OnChanges<T>;
	return maybeOnChanges.ngOnChanges !== undefined && typeof maybeOnChanges.ngOnChanges === 'function';
};
