var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
var SimulatorAgentStepComponent = /** @class */ (function (_super) {
    __extends(SimulatorAgentStepComponent, _super);
    function SimulatorAgentStepComponent() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    SimulatorAgentStepComponent.prototype.ngOnInit = function () {
        this.setStepValidation(true);
    };
    SimulatorAgentStepComponent.prototype.checkValidity = function () {
        // none of the simulators selected - all is valid
        if (!this.data.withSafeBreach && !this.data.withAiq) {
            this.setStepValidation(true);
            return;
        }
        // at least one simulator is selected,
        // and at least one of the MS consent parts is not accepted
        // this is an invalid state
        if (!this.data.msTermsConsentAccepted || !this.data.msInfoSharingConsentAccepted) {
            this.setStepValidation(false);
            return;
        }
        // AttachIQ is selected but the AttackIQ consent was not accepted
        // this is an invalid state
        if (this.data.withAiq && !this.data.attackIQConsentAccepted) {
            this.setStepValidation(false);
            return;
        }
        this.setStepValidation(true);
    };
    return SimulatorAgentStepComponent;
}(WizardBaseStep));
export { SimulatorAgentStepComponent };
