/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./tvm-score-trend.component";
import * as i7 from "../../services/tvm-color.service";
import * as i8 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
var styles_TvmScoreTrendComponent = [];
var RenderType_TvmScoreTrendComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmScoreTrendComponent, data: {} });
export { RenderType_TvmScoreTrendComponent as RenderType_TvmScoreTrendComponent };
function View_TvmScoreTrendComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 16, "div", [["class", "wcd-margin-bottom wcd-flex-baseline"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "div", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t", "\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 2, "fab-icon", [], null, null, null, i1.View_FabIconComponent_0, i1.RenderType_FabIconComponent)), i0.ɵdid(6, 5816320, null, 0, i2.FabIconComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(9, 0, null, null, 1, "div", [["class", "ms-color-gray140 wcd-font-size-s"]], null, null, null, null, null)), (_l()(), i0.ɵted(10, null, ["\n\t\t", "\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(12, 0, null, null, 3, "div", [["class", "wcd-padding-small-left ms-color-gray140 wcd-font-size-mi"]], null, null, null, null, null)), (_l()(), i0.ɵted(13, null, ["\n\t\t", "\n\t"])), i0.ɵpod(14, { days: 0 }), i0.ɵppd(15, 2), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.iconColorClass + " wcd-padding-small-left"); var currVal_2 = _co.iconName; _ck(_v, 6, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.title; _ck(_v, 3, 0, currVal_0); var currVal_3 = _co.advancementScore; _ck(_v, 10, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 13, 0, _ck(_v, 15, 0, i0.ɵnov(_v.parent, 0), "tvm.historyTrends.daysAnnotation", _ck(_v, 14, 0, _co.historyDays))); _ck(_v, 13, 0, currVal_4); }); }
export function View_TvmScoreTrendComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmScoreTrendComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.historyDays > 0); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TvmScoreTrendComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-score-trend", [], null, null, null, View_TvmScoreTrendComponent_0, RenderType_TvmScoreTrendComponent)), i0.ɵdid(1, 49152, null, 0, i6.TvmScoreTrendComponent, [i7.TvmColorService, i8.PrettyNumberService], null, null)], null, null); }
var TvmScoreTrendComponentNgFactory = i0.ɵccf("tvm-score-trend", i6.TvmScoreTrendComponent, View_TvmScoreTrendComponent_Host_0, { options: "options" }, {}, []);
export { TvmScoreTrendComponentNgFactory as TvmScoreTrendComponentNgFactory };
