import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

@Entity({
    ...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Device',
	pluralName: 'Devices',
	endpoint: 'baseline/profilesCompliance',
	parseItemQuery: id => `baseline/profiles/${id}/complianceStatus`,
	readonly: true
})
export class ProfileComplianceStatus extends EntityModelBase {
	@EntityField() readonly profileId: string;

	@EntityField() readonly profileDisplayName: string;

	@EntityField() readonly applicableDevices: number;

	@EntityField() readonly compliantDevices: number;

	@EntityField() readonly moreThan75PercentConfiguredDevices: number;

	@EntityField() readonly moreThan50LessThan75PercentConfiguredDevices: number;

	@EntityField() readonly moreThan25LessThan50PercentConfiguredDevices: number;

	@EntityField() readonly lessThan25PercentConfiguredDevices: number;

	@EntityField() readonly noDataDevices: number;
}

