import { EntityField, EntityModelBase } from '@microsoft/paris';
import { HuntingQuery, QueryKind } from './hunting-query.type';
import { GuidedHuntingQuery } from './guided-query/guided-hunting-query.value-object';

export abstract class HuntingQueryBase extends EntityModelBase<number> implements HuntingQuery {
	@EntityField({ serialize: false })
	abstract readonly kind: QueryKind;

	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: number;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'QueryText' })
	text: string;

	@EntityField({ data: 'Path' })
	path: string;

	@EntityField({ data: 'UserId', serialize: false })
	userId: string;

	@EntityField({ data: 'IsReadOnly', defaultValue: false, serialize: false })
	readonly: boolean;

	@EntityField({ data: 'IsGuided', defaultValue: false })
	isGuided: boolean;

	@EntityField({
		data: 'GuidedQuery',
		serialize: (itemData) => {
			return itemData ? { Filters: itemData.filters, FilteredViews: itemData.views } : null;
		},
	})
	guidedQuery?: GuidedHuntingQuery;

	/**
	 * @internal Do not use, needed for BE save calls 😠
	 */
	@EntityField({ data: 'Shared' })
	abstract readonly shared: boolean;

	/**
	 * @internal Do not use, needed for BE save calls 😠
	 */
	@EntityField({ data: 'IsScheduled' })
	abstract readonly isScheduled: boolean;
}
