var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { DataQuerySortDirection, Entity, EntityField, EntityModelBase, } from '@microsoft/paris';
import { MachineGroup } from '../rbac/machine-group.entity';
import { ScheduledHuntingRunStatus } from './scheduled-hunting-run-status.entity';
import { omit } from 'lodash-es';
import { Severity } from '../severity/severity.entity';
import * as HuntingRuleTypes from './hunting-rule-custom-action.entity';
import { HuntingRuleImpactedEntity } from './hunting-rule-impacted-entity.value-object';
import { HuntingCustomAction } from './actions/hunting-custom-action.value-object';
import { MtpWorkload } from '../rbac/mtp-workload-enum';
import { ServiceSource } from '../alert/sources/service-source.entity';
import { RULE_STATUS_ID_DOES_NOT_APPLY } from './shceduled-hunting-run-status.values';
/**
 * Subset of the AlertCategoryType enum.
 * Contains only alert categories that are supported in the BE, see: https://microsoft.visualstudio.com/WDATP/_git/Detection.Platform?path=%2FSevilleIntegration%2FWcdDetectionEngines.BondEntities%2FWcdDetectionEnginesEnumerations.cs&_a=contents&version=GBmaster
 */
export var HuntingRuleAlertCategory;
(function (HuntingRuleAlertCategory) {
    HuntingRuleAlertCategory["Collection"] = "Collection";
    HuntingRuleAlertCategory["CommandAndControl"] = "CommandAndControl";
    HuntingRuleAlertCategory["CredentialAccess"] = "CredentialAccess";
    HuntingRuleAlertCategory["DefenseEvasion"] = "DefenseEvasion";
    HuntingRuleAlertCategory["Discovery"] = "Discovery";
    HuntingRuleAlertCategory["Execution"] = "Execution";
    HuntingRuleAlertCategory["Exfiltration"] = "Exfiltration";
    HuntingRuleAlertCategory["Exploit"] = "Exploit";
    HuntingRuleAlertCategory["InitialAccess"] = "InitialAccess";
    HuntingRuleAlertCategory["LateralMovement"] = "LateralMovement";
    HuntingRuleAlertCategory["Malware"] = "Malware";
    HuntingRuleAlertCategory["Persistence"] = "Persistence";
    HuntingRuleAlertCategory["PrivilegeEscalation"] = "PrivilegeEscalation";
    HuntingRuleAlertCategory["Ransomware"] = "Ransomware";
    HuntingRuleAlertCategory["SuspiciousActivity"] = "SuspiciousActivity";
    HuntingRuleAlertCategory["UnwantedSoftware"] = "UnwantedSoftware";
    HuntingRuleAlertCategory["Impact"] = "Impact";
})(HuntingRuleAlertCategory || (HuntingRuleAlertCategory = {}));
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ1 = function (item) { return (item.id ? 'PATCH' : 'POST'); }, ɵ2 = function (item, serializedItem, entity, config, serializationData) { return (__assign({}, serializedItem, { QueryText: serializationData.queryText, AlertDescription: serializedItem.Description, AlertSeverity: serializedItem.Severity, AlertCategory: serializedItem.Category, AlertRecommendedAction: serializedItem.RecommendedAction })); }, ɵ3 = function (dataQuery) {
    if (!dataQuery)
        return {};
    return Object.assign({}, omit(dataQuery.where, ['page', 'page_size', 'ordering']), {
        pageIndex: dataQuery.page || 1,
        pageSize: dataQuery.pageSize,
    }, dataQuery.sortBy && dataQuery.sortBy.length
        ? {
            sortByField: dataQuery.sortBy[0].field,
            sortOrder: dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
                ? 'Ascending'
                : 'Descending',
        }
        : undefined);
}, ɵ4 = function (_a) {
    var LastRunStatus = _a.LastRunStatus, IntervalHours = _a.IntervalHours;
    return IntervalHours === 0 ? RULE_STATUS_ID_DOES_NOT_APPLY : LastRunStatus;
}, ɵ5 = function (rawData) {
    return rawData &&
        rawData.map(function (workload) { return (typeof workload === 'string' ? MtpWorkload[workload] : workload); });
}, ɵ6 = ['Description', 'AlertDescription'], ɵ7 = ['Severity', 'AlertSeverity'], ɵ8 = ['Category', 'AlertCategory'], ɵ9 = ['RecommendedAction', 'AlertRecommendedAction'], ɵ10 = function (machineGroupIdsAsCsv) { return machineGroupIdsAsCsv && machineGroupIdsAsCsv.split(','); }, ɵ11 = function (items) {
    return items ? items.map(function (item) { return item.id; }).join(',') : null;
};
var HuntingRule = /** @class */ (function (_super) {
    __extends(HuntingRule, _super);
    function HuntingRule() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", Number)
    ], HuntingRule.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'QueryId', serialize: false }),
        __metadata("design:type", Number)
    ], HuntingRule.prototype, "queryId", void 0);
    __decorate([
        EntityField({ data: 'LastRunTime', serialize: false }),
        __metadata("design:type", Date)
    ], HuntingRule.prototype, "lastRunTime", void 0);
    __decorate([
        EntityField({
            data: '__self',
            parse: ɵ4,
            serialize: false,
        }),
        __metadata("design:type", ScheduledHuntingRunStatus)
    ], HuntingRule.prototype, "lastRunStatus", void 0);
    __decorate([
        EntityField({ data: 'LastRunFailureReason', serialize: false }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "lastRunFailureReason", void 0);
    __decorate([
        EntityField({ data: 'NextRunTime', serialize: false }),
        __metadata("design:type", Date)
    ], HuntingRule.prototype, "nextRunTime", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy', serialize: false }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "createdBy", void 0);
    __decorate([
        EntityField({ data: 'CreationTime', serialize: false }),
        __metadata("design:type", Date)
    ], HuntingRule.prototype, "createdOn", void 0);
    __decorate([
        EntityField({ data: 'LastUpdatedBy', serialize: false }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "lastUpdatedBy", void 0);
    __decorate([
        EntityField({ data: 'LastUpdatedTime', serialize: false }),
        __metadata("design:type", Date)
    ], HuntingRule.prototype, "lastUpdatedOn", void 0);
    __decorate([
        EntityField({ data: 'IoaDefinitionId', serialize: false }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "ioaDefinitionId", void 0);
    __decorate([
        EntityField({
            data: 'MtpWorkloads',
            parse: ɵ5,
            serialize: false,
        }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "mtpWorkloads", void 0);
    __decorate([
        EntityField({ data: 'ServiceSources', required: false, arrayOf: ServiceSource, serialize: false }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "serviceSources", void 0);
    __decorate([
        EntityField({ data: 'Name' }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "alertTitle", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "alertDescription", void 0);
    __decorate([
        EntityField({ data: 'IntervalHours' }),
        __metadata("design:type", Number)
    ], HuntingRule.prototype, "intervalHours", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", Severity)
    ], HuntingRule.prototype, "alertSeverity", void 0);
    __decorate([
        EntityField({ data: ɵ8 }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "alertCategory", void 0);
    __decorate([
        EntityField({ data: 'ThreatId' }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "threatId", void 0);
    __decorate([
        EntityField({ data: 'MitreTechniques' }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "mitreTechniques", void 0);
    __decorate([
        EntityField({ data: ɵ9 }),
        __metadata("design:type", String)
    ], HuntingRule.prototype, "recommendedActions", void 0);
    __decorate([
        EntityField({
            data: 'RbacGroupIds',
            arrayOf: MachineGroup,
            parse: ɵ10,
            serialize: ɵ11,
        }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "machineGroups", void 0);
    __decorate([
        EntityField({ data: 'IsEnabled' }),
        __metadata("design:type", Boolean)
    ], HuntingRule.prototype, "isEnabled", void 0);
    __decorate([
        EntityField({
            data: 'CustomizedActions',
            serialize: false,
            parse: HuntingRuleTypes.parseCustomActions,
            get defaultValue() {
                return HuntingRuleTypes.huntingRuleCustomActionTypeValues.reduce(function (acc, item) {
                    acc[item.id] = {
                        actionType: item.id,
                        displayName: item.displayName,
                        category: item.category,
                        active: false,
                    };
                    return acc;
                }, {});
            },
        }),
        __metadata("design:type", Object)
    ], HuntingRule.prototype, "customActions", void 0);
    __decorate([
        EntityField({
            data: 'CustomActions',
            arrayOf: HuntingCustomAction,
            defaultValue: [],
        }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "actions", void 0);
    __decorate([
        EntityField({ data: 'ImpactedEntities', arrayOf: HuntingRuleImpactedEntity, defaultValue: [] }),
        __metadata("design:type", Array)
    ], HuntingRule.prototype, "impactedEntities", void 0);
    __decorate([
        EntityField({ data: 'IsMdatp' }),
        __metadata("design:type", Boolean)
    ], HuntingRule.prototype, "isMdatp", void 0);
    HuntingRule = __decorate([
        Entity({
            singularName: 'Detection rule',
            pluralName: 'Detection rules',
            endpoint: 'rules',
            baseUrl: ɵ0,
            loadAll: false,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            saveMethod: ɵ1,
            serializeItem: ɵ2,
            parseDataQuery: ɵ3,
        })
    ], HuntingRule);
    return HuntingRule;
}(EntityModelBase));
export { HuntingRule };
export function isContinuousHuntingRule(rule) {
    return rule.intervalHours === 0;
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11 };
