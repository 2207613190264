
		<fab-date-picker
			[isMonthPickerVisible]="!!config?.isMonthPickerVisible"
			(onSelectDate)="onSelectDate($event.date)"
			[showGoToToday]="!!config?.showGoToToday"
			[minDate]="config?.minDate"
			[maxDate]="config?.maxDate"
			[placeholder]="config?.placeholder || 'Select date...'"
			[initialPickerDate]="config?.initialDate || selectedValues"
			[value]="selectedValues"
		>
		</fab-date-picker>
	