/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./seville-investigations.dataview.ngfactory";
import * as i2 from "../services/seville-investigation.fields";
import * as i3 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i4 from "../../../global_entities/services/global-entity-types.service";
import * as i5 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i6 from "../services/investigation.entity-type.service";
import * as i7 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "./seville-investigations.dataview";
import * as i10 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i11 from "../services/investigations.service";
import * as i12 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i13 from "@angular/router";
import * as i14 from "../../../shared/services/download.service";
import * as i15 from "../../../global_entities/services/entity-panels.service";
import * as i16 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i17 from "@angular/common/http";
import * as i18 from "@microsoft/paris/dist/lib/paris";
import * as i19 from "../../../shared/services/time-ranges.service";
import * as i20 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i21 from "./investigations.dataview.ngfactory";
import * as i22 from "../services/investigation.fields";
import * as i23 from "./investigations.dataview";
import * as i24 from "@angular/common";
import * as i25 from "./investigations-dataview-selector";
var styles_InvestigationsDataviewSelectorComponent = [];
var RenderType_InvestigationsDataviewSelectorComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_InvestigationsDataviewSelectorComponent, data: {} });
export { RenderType_InvestigationsDataviewSelectorComponent as RenderType_InvestigationsDataviewSelectorComponent };
function View_InvestigationsDataviewSelectorComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "seville-investigations-dataview", [], null, null, null, i1.View_SevilleInvestigationsDataviewComponent_0, i1.RenderType_SevilleInvestigationsDataviewComponent)), i0.ɵprd(512, null, i2.SevilleInvestigationFields, i2.SevilleInvestigationFields, [i3.AppConfigService, i4.GlobalEntityTypesService, i5.IconsService, i6.InvestigationEntityTypeService, i7.AppContextService, i8.I18nService]), i0.ɵdid(2, 49152, null, 0, i9.SevilleInvestigationsDataviewComponent, [i10.PreferencesService, i2.SevilleInvestigationFields, i11.InvestigationsService, i12.AuthService, i13.ActivatedRoute, i13.Router, i14.DownloadService, i4.GlobalEntityTypesService, i15.EntityPanelsService, i3.AppConfigService, i16.FeaturesService, i17.HttpClient, i18.Paris, i8.I18nService, i19.TimeRangesService, i20.TzDateService], null, null)], null, null); }
function View_InvestigationsDataviewSelectorComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "investigations-dataview", [], null, null, null, i21.View_InvestigationsDataviewComponent_0, i21.RenderType_InvestigationsDataviewComponent)), i0.ɵprd(512, null, i22.InvestigationsFields, i22.InvestigationsFields, [i18.Paris, i3.AppConfigService, i4.GlobalEntityTypesService, i5.IconsService, i6.InvestigationEntityTypeService, i8.I18nService]), i0.ɵdid(3, 573440, null, 0, i23.InvestigationsDataviewComponent, [i10.PreferencesService, i22.InvestigationsFields, i11.InvestigationsService, i12.AuthService, i13.ActivatedRoute, i13.Router, i14.DownloadService, i4.GlobalEntityTypesService, i15.EntityPanelsService, i3.AppConfigService, i8.I18nService, i19.TimeRangesService, i18.Paris, i20.TzDateService], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_InvestigationsDataviewSelectorComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_InvestigationsDataviewSelectorComponent_1)), i0.ɵdid(2, 16384, null, 0, i24.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["oldInvestigations", 2]], null, 0, null, View_InvestigationsDataviewSelectorComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.useSevilleData; var currVal_1 = i0.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_InvestigationsDataviewSelectorComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "investigations-dataview-selector", [], null, null, null, View_InvestigationsDataviewSelectorComponent_0, RenderType_InvestigationsDataviewSelectorComponent)), i0.ɵdid(1, 49152, null, 0, i25.InvestigationsDataviewSelectorComponent, [i16.FeaturesService], null, null)], null, null); }
var InvestigationsDataviewSelectorComponentNgFactory = i0.ɵccf("investigations-dataview-selector", i25.InvestigationsDataviewSelectorComponent, View_InvestigationsDataviewSelectorComponent_Host_0, {}, {}, []);
export { InvestigationsDataviewSelectorComponentNgFactory as InvestigationsDataviewSelectorComponentNgFactory };
