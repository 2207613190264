import { SevilleAlertsModule } from './seville.alerts.module';
import '../services/seville.threatintel.alertFilter';
import '../services/seville.threatintel.alertsManagement';
import { AppConfigService } from '@wcd/app-config';
import { Feature } from '@wcd/config';
import { remove } from 'lodash-es';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { Alert } from '@wcd/domain';
import { Subscription } from 'rxjs';

SevilleAlertsModule.directive('alertGroup', alertGroupDirective);
SevilleAlertsModule.controller('seville.threatintel.alert.group', alertGroupController);

alertGroupDirective.$inject = [];

function alertGroupDirective() {
	return {
		restrict: 'EA',
		scope: {
			alerts: '=',
			sort: '=',
			sortOrder: '=',
			loading: '=',
			showAlertHighlight: '=',
			showMachineContext: '=',
			showUserContext: '=',
			entityPageLayout: '=',
			loadAlerts: '&',
			pageIndex: '=',
			refreshInvestigationStates: '=',
		},
		template: `
		<div class="col-md-12"  role="table"  aria-label="alerts">
        <div class="row table-header alert-queue-header hidden-xs" role="rowgroup">
            <!-- checkmark + title - queue -->
            <div class="col-sm-6 alerts-queue-results-header" ng-if="!vm.entityPageLayout" ng-class="::vm.getColumnSize()">
                <table class="alert-queue-checkmark-title-col" role="none">
                    <tr role="none">
                        <td role="none">
                            <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled" role="heading">
                                <div class="alert-queue-checkmark-col">
                                    <i class="icon icon-CheckMark"></i>
                                </div>
                            </button>
                        </td>
                        <td role="none">
                            <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled alert-queue-header-margin" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled"  role="heading">
                                Title
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <!-- checkmark + last seen - entity pages -->
            <div class="col-sm-2 col-md-1 alerts-queue-results-header" ng-if="vm.entityPageLayout">
                <table class="alert-queue-checkmark-last-activity-col" role="none">
                    <tr role="none">
                        <td role="none">
                            <label class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled" role="heading">
                                <div class="alert-queue-checkmark-col">
                                    <i class="icon icon-CheckMark"></i>
                                </div>
                            </label>
                        </td>
                        <td role="none">
                            <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button alert-queue-header-last-activity-margin" data-toggle="dropdown" role="heading" aria-expanded="true"
                                    ng-disabled="vm.loading" type="button" ng-click="vm.updateQueue('lastevent')">
                                <span ng-class="{'bold' : vm.sort == 'lastevent'}">Last activity</span>
                                <span ng-if="vm.sort == 'lastevent' && vm.sortOrder == 'Descending'" class="icon alerts-queue-header-icon icon-SortDown"></span>
                                <span ng-if="vm.sort == 'lastevent' && vm.sortOrder != 'Descending'" class="icon alerts-queue-header-icon icon-SortUp"></span>
                            </button>
                        </td>
                    </tr>
                </table>
            </div>

            <!-- Title - entity page layout -->
            <div class="col-sm-6 col-md-4 alerts-queue-results-header" ng-if="vm.entityPageLayout">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled" role="heading">
                    Title
                </button>
            </div>

            <!-- Machine / User -->
            <div class="alerts-queue-results-header"
                 ng-class="{
                 'col-sm-3 col-md-3': (vm.entityPageLayout && !vm.isAutoIRFeatureEnabled),
                 'col-sm-2 col-md-2': (!vm.entityPageLayout || (vm.entityPageLayout && vm.isAutoIRFeatureEnabled)),
                 }">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" role="heading" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled">
                    <span ng-if="vm.showMachineContext && vm.showUserContext">Device and user</span>
                    <span ng-if="vm.showMachineContext && !vm.showUserContext">Device</span>
                    <span ng-if="!vm.showMachineContext && vm.showUserContext">User</span>
                    <span ng-if="!vm.showMachineContext && !vm.showUserContext">Context</span>
                </button>
            </div>

            <!-- Severity-->
            <div class="hidden-sm col-md-1 alerts-queue-results-header">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button" data-toggle="dropdown" aria-expanded="true"
                        ng-disabled="vm.loading" type="button" ng-click="vm.updateQueue('severityvalue')" role="heading">
                    <span ng-class="{'bold' : vm.sort == 'severityvalue'}">Severity</span>
                    <span ng-if="vm.sort == 'severityvalue' && vm.sortOrder == 'Descending'" class="icon alerts-queue-header-icon icon-SortDown"></span>
                    <span ng-if="vm.sort == 'severityvalue' && vm.sortOrder != 'Descending'" class="icon alerts-queue-header-icon icon-SortUp"></span>
                </button>
            </div>

            <!-- Last activity time - queue -->
            <div class="alerts-queue-results-header col-sm-2 col-md-1" ng-if="!vm.entityPageLayout" role="none">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button" data-toggle="dropdown" aria-expanded="true"
                        ng-disabled="vm.loading" type="button" ng-click="vm.updateQueue('lastevent')" role="heading">
                    <span ng-class="{'bold' : vm.sort == 'lastevent'}">Last activity</span>
                    <span ng-if="vm.sort == 'lastevent' && vm.sortOrder == 'Descending'" class="icon alerts-queue-header-icon icon-SortDown"></span>
                    <span ng-if="vm.sort == 'lastevent' && vm.sortOrder != 'Descending'" class="icon alerts-queue-header-icon icon-SortUp"></span>
                </button>
            </div>

            <!--Time in queue - queue-->
            <div class="col-sm-1 col-md-1 alerts-queue-results-header"
                 ng-if="!vm.entityPageLayout">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button" data-toggle="dropdown" aria-expanded="true"
                        ng-disabled="vm.loading" type="button" ng-click="vm.updateQueue('firstseen')" role="heading">
                    <span ng-class="{'bold' : vm.sort == 'firstseen'}">Time in queue</span>
                    <span ng-if="vm.sort == 'firstseen' && vm.sortOrder == 'Descending'" class="icon alerts-queue-header-icon icon-SortDown"></span>
                    <span ng-if="vm.sort == 'firstseen' && vm.sortOrder != 'Descending'" class="icon alerts-queue-header-icon icon-SortUp"></span>
                </button>
			</div>

			<!--detection source- queue-->
			<div class="hidden-xs hidden-sm col-md-1 alerts-queue-results-header"
				ng-if="!vm.entityPageLayout">
		    	<button class="btn btn-link alerts-queue-results-header-button-disabled" disabled="disabled" role="heading">Detection source</button>
	   		</div>

            <!-- Status -->
            <div class="hidden-sm col-md-1 alerts-queue-results-header">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" role="heading" data-toggle="dropdown" aria-expanded="true" type="button" disabled="disabled">
                    Status
                </button>
            </div>

            <!--Investigation State-->
            <div ng-if="vm.isAutoIRFeatureEnabled" class="col-sm-1 col-md-1 alerts-queue-results-header">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" role="heading" disabled="disabled">Investigation State</button>
            </div>

            <!-- Assigned to and menu -->
            <div class="col-sm-1 alerts-queue-results-header"
            	ng-class="::{ 'col-md-2': vm.entityPageLayout }">
                <button class="btn btn-link dropdown-toggle alerts-queue-results-header-button-disabled" role="heading" data-toggle="dropdown" aria-expanded="true" aria-label="Assign to" type="button" disabled="disabled">
                    <table class="alert-queue-menu-table" role="none">
                        <tr  role="none">
                            <td class="hidden-sm">
                                Assigned to
                            </td>
                            <td class="alert-queue-menu-col" role="none"><!-- place holder for menu --></td>
                        </tr>
                    </table>
                </button>
            </div>
        </div>

        <div ng-repeat="alert in vm.alerts track by $index">
            <div data-context-menu="{{ !vm.showAlertHighlight || alert.ContainedAlerts ? '' : 'templates/seville.threatintel.alertcontextmenu.html' }}"
                 ng-model="alert"
                 scroll-container=".wrapper"
                 ng-controller="alertsContextMenuController as alertsContextMenuController">
              <div>
                <alert-row alert="alert"
                			selected="vm.selectedAlertIds.has(alert.AlertId)"
                           manage-alert="vm.manageAlert"
                           select-row="vm.selectRow"
                           checkmark-click="vm.checkmarkClick"
                           show-machine-context="vm.showMachineContext"
                           show-user-context="vm.showUserContext"
                           entity-page-layout="vm.entityPageLayout"
                           show-alert-highlight="vm.showAlertHighlight" />
                <div ng-if="!alert.collapsed && alert.ContainedAlerts" class="alert-group-animate">
                  <div ng-repeat="child in alert.ContainedAlerts track by $index">
                    <alert-row alert="child"
                    			selected="vm.selectedAlertIds.has(alert.AlertId)"
                               manage-alert="vm.manageAlert"
                               select-row="vm.selectRow"
                               checkmark-click="vm.checkmarkClick"
                               show-machine-context="vm.showMachineContext"
                               show-user-context="vm.showUserContext"
                               entity-page-layout="vm.entityPageLayout"
                               show-alert-highlight="vm.showAlertHighlight" />
                  </div>
                </div>
              </div>


            </div>
        </div>
    </div>
		`,
		bindToController: true,
		controllerAs: 'vm',
		controller: 'seville.threatintel.alert.group',
	};
}

alertGroupController.$inject = [
	'$scope',
	'$state',
	'$window',
	'$log',
	'appConfig',
	'alertFilterService',
	'urlMapping',
	'alertManagementService',
	'sidePaneService',
	'featuresService',
	'$filter',
	'investigationService',
	'rbacMachineGroupService',
	'appInsights',
	'entityPanelsService',
];

function alertGroupController(
	$scope,
	$state,
	$window,
	$log,
	appConfig: AppConfigService,
	alertFilterService,
	urlMapping,
	alertManagementService,
	sidePaneService,
	featuresService,
	$filter,
	investigationService,
	rbacMachineGroupService,
	appInsights: AppInsightsService,
	entityPanelsService: EntityPanelsService
) {
	var vm = this;

	$log.debug('Displaying alerts');
	vm.lastClickedAlertIndex = -1;
	vm.minSelected = -1;
	vm.maxSelected = -1;
	vm.stickySelect = false;
	vm.flatAlerts = [];
	vm.isAutoIRFeatureEnabled = appConfig.isAutomatedIrEnabled;
	vm.isRbacEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
	var stateMap = urlMapping.investigationsStates;
	var investigationsStateIntervalCancelFunction;
	var queuedRedirectionIntervalCancelFunction;

	const selectedAlertIds: Set<string> = (vm.selectedAlertIds = new Set<string>());

	const displayedAlertsSubscription: Subscription = entityPanelsService
		.getCurrentlyDisplayedItems(Alert)
		.subscribe((displayedAlerts: Array<Alert>) => {
			selectedAlertIds.clear();
			displayedAlerts.forEach(alert => selectedAlertIds.add(alert.id));
		});

	if (vm.isAutoIRFeatureEnabled && vm.refreshInvestigationStates) {
		refreshInvestigationsStates();
	}

	$scope.$watch(
		function() {
			return vm.loading;
		},
		function(newValue, oldValue) {
			if (!newValue) {
				vm.recalculateIndexesAndIds();
			}
		}
	);

	$scope.$on('sidePane:paneUpdated', function() {
		setManagedAlerts();
	});

	$scope.$on('alertsMgmt:alertsUpdated', function(event, payload) {
		if (!payload.alerts) {
			return;
		}
		vm.updateAlerts(payload.alerts);
	});

	$scope.$on('alertFilter:changeDate', function() {
		setHighlightedAlerts();
	});

	$scope.$on('alertContextMenu:selectAlert', function(event, payload) {
		if (!payload.alertId) {
			return;
		}
		var alert = getAlertById(payload.alertId);
		if (!alert) {
			alert = payload.alert; // Can happen from event timeline
		}

		// Match selection in filters
		if (vm.showAlertHighlight) {
			alertFilterService.selectAlerts([alert], true);
		}

		alert.highlighted = isHighlighted(alert);
	});

	$scope.$on('$destroy', function() {
		displayedAlertsSubscription && displayedAlertsSubscription.unsubscribe();

		// if investigation states update interval is running - stopping it.
		if (investigationsStateIntervalCancelFunction) {
			investigationsStateIntervalCancelFunction();
			investigationsStateIntervalCancelFunction = undefined;
		}

		// if queued investigation update interval is running - stopping it.
		if (queuedRedirectionIntervalCancelFunction) {
			queuedRedirectionIntervalCancelFunction();
			queuedRedirectionIntervalCancelFunction = undefined;
		}
	});

	// Goes over all alerts, collect any alerts with auto investigations require to update on its state every minute (pending / running / etc) and get updates.
	function refreshInvestigationsStates() {
		var alertsToRefreshByInvestigationId = {}; // object with ids as keys and array of alert indexes in alerts object that require state update
		var idsToUpdateArray = []; // auto investigation ids to get update on (pending / running states)
		var alertIdsToIndexInAlertsObjectMap = {}; // object that maps between alert id and its index in vm.alerts
		var queuedAlertIds = []; // alert ids with queued investigations

		for (var alertIndexInAlerts = 0; alertIndexInAlerts < vm.alerts.length; alertIndexInAlerts++) {
			var alert = vm.alerts[alertIndexInAlerts];
			if (alert.InvestigationId) {
				if (alert.InvestigationId === -1 && !alertIdsToIndexInAlertsObjectMap[alert.AlertId]) {
					// collect alerts with queued auto investigation (id == -1)
					alertIdsToIndexInAlertsObjectMap[alert.AlertId] = alertIndexInAlerts;
					queuedAlertIds.push(alert.AlertId);
				} else {
					// look for alerts with state require refreshings (update on the state)
					trackInvestigationStateIfNeeded(
						alertsToRefreshByInvestigationId,
						idsToUpdateArray,
						alert.InvestigationId,
						alert.InvestigationState,
						alertIndexInAlerts
					);
				}
			}
		}

		updateOnQueuedAlerts(
			queuedAlertIds,
			alertIdsToIndexInAlertsObjectMap,
			idsToUpdateArray,
			alertsToRefreshByInvestigationId
		);
		updateOnInvestigations(idsToUpdateArray, alertsToRefreshByInvestigationId);
	}

	function trackInvestigationStateIfNeeded(
		alertsToRefreshByInvestigationId,
		idsToUpdateArray,
		autoInvestigationId,
		autoInvestigationState,
		alertIndexInAlerts
	) {
		if (!investigationService.isInvestigationStateTracked(autoInvestigationState)) {
			// alert investigation state does not need to be tracked
			return false;
		}

		if (!alertsToRefreshByInvestigationId[autoInvestigationId]) {
			// did not yet had any alert related to this auto-investigation, adding this auto-investigation id to the ids array to get state update on
			idsToUpdateArray.push(autoInvestigationId);

			// save the alert index of this investigation id to later reach it faster and update its state without any need to search over all alerts
			alertsToRefreshByInvestigationId[autoInvestigationId] = {
				currentInvestigationState: autoInvestigationState, // current state of that auto-investigation
				alertsIndexes: [alertIndexInAlerts], // array of alerts related to that auto-investigation
			};
		} else {
			// already has this auto-investigation id in the array of ids to update on their states, save the alert index
			alertsToRefreshByInvestigationId[autoInvestigationId].alertsIndexes.push(alertIndexInAlerts);
		}

		return true;
	}

	function updateOnQueuedAlerts(
		queuedAlertIds,
		alertIdsToIndexInAlertsObjectMap,
		idsToUpdateArray,
		alertsToRefreshByInvestigationId
	) {
		if (!queuedAlertIds || queuedAlertIds.length === 0) {
			return;
		}

		var trackNewInvestigations = false;

		queuedRedirectionIntervalCancelFunction = investigationService.refreshOnQueuedAutoInvestigationsStates(
			queuedAlertIds,
			function(responseData, intervalCancelFunction) {
				responseData.forEach(function(alertObject) {
					if (
						!(
							alertObject &&
							alertObject.AlertInvestigation &&
							alertObject.AlertInvestigation.InvestigationId !== -1
						)
					) {
					} else {
						// alert auto investigation changed from queued and received an auto investigation id - update the alert
						var relatedAlertIndexInAlertsObjet =
							alertIdsToIndexInAlertsObjectMap[alertObject.AlertId];
						var relatedAlert = vm.alerts[relatedAlertIndexInAlertsObjet];
						relatedAlert.InvestigationId = alertObject.AlertInvestigation.InvestigationId;
						relatedAlert.InvestigationState = alertObject.AlertInvestigation.State;

						// stop getting updates over queued state for this alert
						remove(queuedAlertIds, function(alertId) {
							return alertId === alertObject.AlertId;
						});
						alertIdsToIndexInAlertsObjectMap[alertObject.AlertId] = undefined;

						// add the new investigation id to list of investigations states to be tracked if needed (pending / running)
						trackNewInvestigations = trackInvestigationStateIfNeeded(
							alertsToRefreshByInvestigationId,
							idsToUpdateArray,
							relatedAlert.InvestigationId,
							relatedAlert.InvestigationState,
							relatedAlertIndexInAlertsObjet
						);
					}
				});

				if (!queuedAlertIds || queuedAlertIds.length === 0) {
					// no more alerts with queued state - stop updates from server
					intervalCancelFunction();
					if (queuedRedirectionIntervalCancelFunction) {
						queuedRedirectionIntervalCancelFunction = undefined;
					}
				}

				if (trackNewInvestigations) {
					if (investigationsStateIntervalCancelFunction) {
						// cancel any old update states interval before starting a new one in updateOnInvestigations
						investigationsStateIntervalCancelFunction();
					}
					updateOnInvestigations(idsToUpdateArray, alertsToRefreshByInvestigationId);
					trackNewInvestigations = false;
				}
			}
		);
	}

	// get investigation state updates over array of ids
	function updateOnInvestigations(idsToUpdateArray, alertsToRefreshByIncedentId) {
		if (!idsToUpdateArray || idsToUpdateArray.length === 0) {
			return;
		}

		investigationsStateIntervalCancelFunction = investigationService.refreshOnAutoInvestigationsStates(
			idsToUpdateArray,
			function(responseData, intervalCancelFunction) {
				// go over the result array (updates over the investigations)
				for (var resIndex = 0; resIndex < responseData.length; resIndex++) {
					var autoInvestigationObject = responseData[resIndex];
					// looks for investigations that their state changed
					if (
						autoInvestigationObject &&
						autoInvestigationObject.InvestigationId &&
						autoInvestigationObject.State &&
						alertsToRefreshByIncedentId[autoInvestigationObject.InvestigationId] &&
						autoInvestigationObject.State !==
							alertsToRefreshByIncedentId[autoInvestigationObject.InvestigationId]
								.currentInvestigationState
					) {
						// auto-investigation state changed - update the state in alerts
						updateAlertsState(alertsToRefreshByIncedentId, autoInvestigationObject);

						// remove any auto-investigation ids that are no longer in pending / running state from array of ids to be tracked
						removeIdFromTracking(
							idsToUpdateArray,
							alertsToRefreshByIncedentId,
							autoInvestigationObject
						);
					}
				}

				if (idsToUpdateArray.length === 0) {
					// no more investigations to track - stop calling for update request
					intervalCancelFunction();
					if (investigationsStateIntervalCancelFunction) {
						investigationsStateIntervalCancelFunction = undefined;
					}
				}
			}
		);
	}

	function updateAlertsState(alertsToRefreshByIncedentId, autoInvestigationObject) {
		alertsToRefreshByIncedentId[autoInvestigationObject.InvestigationId].currentInvestigationState =
			autoInvestigationObject.State;
		var alertsIndexesToUpdate =
			alertsToRefreshByIncedentId[autoInvestigationObject.InvestigationId].alertsIndexes;
		alertsIndexesToUpdate.forEach(function(alertIndex) {
			// update the alert investigation state
			if (vm.alerts[alertIndex]) {
				vm.alerts[alertIndex].InvestigationState = autoInvestigationObject.State;
			}
		});
	}

	function removeIdFromTracking(idsToUpdateArray, alertsToRefreshByIncedentId, autoInvestigationObject) {
		if (
			[stateMap.PendingResource, stateMap.PendingApproval, stateMap.Running].indexOf(
				autoInvestigationObject.State
			) === -1
		) {
			// no need to keep tracking this investigation id, remove it from the ids array to be tracked:
			remove(idsToUpdateArray, function(id) {
				return id === autoInvestigationObject.InvestigationId;
			});
			alertsToRefreshByIncedentId[autoInvestigationObject.InvestigationId] = undefined;
		}
	}

	vm.getGroupKey = function(groupKey) {
		if (groupKey === undefined) {
			return '';
		}
		switch (groupKey) {
			case 0:
			case 'ThreatFamilyName':
				return 'threat family';
			case 1:
			case 'KnownProcessWithCommandline':
				return 'commandline';
			case 2:
			case 'Sha1':
				return 'file';
			case 3:
			case 'Url':
				return 'destination host name';
			default:
				return '';
		}
	};

	vm.updateAlerts = function(updatedAlerts) {
		var parents = [];
		for (var i = 0; i < updatedAlerts.length; i++) {
			var alert = getAlertById(updatedAlerts[i].AlertId);
			if (!alert) {
				continue;
			}
			updateAlert(alert, updatedAlerts[i]);
		}
	};

	var setManagedAlerts = function() {
		for (var i = 0; i < vm.alerts.length; i++) {
			var currAlert = vm.alerts[i];
			currAlert.managed = isManaged(currAlert);
			if (currAlert.ContainedAlerts) {
				for (var j = 0; j < currAlert.ContainedAlerts.length; j++) {
					var child = currAlert.ContainedAlerts[j];
					child.managed = isManaged(child);
				}
			}
		}
	};

	var setHighlightedAlerts = function() {
		for (var i = 0; i < vm.alerts.length; i++) {
			var currAlert = vm.alerts[i];
			if (currAlert.ContainedAlerts) {
				var highlighted = true;
				for (var j = 0; j < currAlert.ContainedAlerts.length; j++) {
					var child = currAlert.ContainedAlerts[j];
					child.highlighted = isHighlighted(alert);
					if (!child.highlighted) {
						highlighted = false;
					}
				}
				currAlert.highlighted = highlighted;
			} else {
				currAlert.highlighted = isHighlighted(alert);
			}
		}
	};

	var getAlertById = function(alertId) {
		var potentialAlerts = vm.flatAlerts.filter(function(a) {
			return a.AlertId == alertId;
		});

		if (!potentialAlerts || !potentialAlerts.length) {
			$log.info('No alerts in list match alert id ' + alertId + ' which was managed');
			return '';
		}
		if (potentialAlerts.length !== 1) {
			$log.error('More than 1 alert in list match alert id ' + alertId + ' which was managed');
			return '';
		}

		return potentialAlerts[0];
	};

	var updateAlert = function(oldAlert, newAlert) {
		oldAlert.Status = newAlert.Status;
		oldAlert.AssignedTo = newAlert.AssignedTo;
		oldAlert.assignedToUser = vm.getAssignedToUser(oldAlert);
		oldAlert.Classification = newAlert.Classification;
		oldAlert.Determination = newAlert.Determination;
		if (oldAlert.parent) {
			updateParent(oldAlert.parent);
		}
	};

	var updateParent = function(parent) {
		parent.Status = getAggregatedAlertField(parent, function(alert) {
			return alert.Status;
		});
		parent.Classification = getAggregatedAlertField(parent, function(alert) {
			return alert.Classification;
		});
		parent.Determination = getAggregatedAlertField(parent, function(alert) {
			return alert.Determination;
		});
		parent.AssignedToCount = getAssignedToCount(parent);
		if (parent.AssignedToCount === 1) {
			parent.AssignedTo = parent.ContainedAlerts[0].AssignedTo;
		} else {
			parent.AssignedTo = '';
		}
		parent.assignedToUser = vm.getAssignedToUser(parent);
	};

	var getAssignedToCount = function(alert) {
		var assignedTos = [];
		var hasUnassignedChildAlerts = false;
		for (var i = 0; i < alert.ContainedAlerts.length; i++) {
			var child = alert.ContainedAlerts[i];
			if (child.AssignedTo) {
				if (assignedTos.indexOf(child.AssignedTo) < 0) {
					assignedTos.push(child.AssignedTo);
				}
			} else {
				hasUnassignedChildAlerts = true;
			}
		}

		if (hasUnassignedChildAlerts && assignedTos.length > 0) {
			// Some alerts are assigned while others are unassigned
			return -1;
		} else {
			// All child alerts are either assigned or unassigned - return number of assigned owners
			return assignedTos.length;
		}
	};

	var isManaged = function(alert) {
		return sidePaneService.isSelected(alert.sidePaneKey);
	};

	var isHighlighted = function(alert) {
		return vm.showAlertHighlight && alertFilterService.ishighlightActive(alert.sidePaneKey);
	};

	var setAlertFields = function(alert) {
		alert.alertLink = vm.buildAlertLink(alert);
		alert.assignedToUser = vm.getAssignedToUser(alert);
		alert.sidePaneKey = getSidePaneKey(alert);
		alert.managed = isManaged(alert);
		alert.highlighted = isHighlighted(alert);
		alert.clickSensitive = !alert.AlertId || vm.showAlertHighlight;
		alert.isGroup = alert.ContainedAlerts ? true : false;
		alert.formattedLastEventTime = $filter('sevilleDate')(alert.LastEventTime);
	};

	var getAggregatedAlertField = function(alert, getChildField) {
		var fieldVal = '';

		for (var i = 0; i < alert.ContainedAlerts.length; i++) {
			var child = alert.ContainedAlerts[i];
			if (!fieldVal) {
				fieldVal = getChildField(child);
			} else {
				var currVal = getChildField(child);
				if (currVal != fieldVal) {
					return 'Multiple';
				}
			}
		}

		return fieldVal;
	};

	vm.recalculateIndexesAndIds = function() {
		if (!vm.alerts) return;
		var maxAlert: any;
		var minAlert: any;
		var lastSelectedAlert: any;

		if (vm.stickySelect) {
			maxAlert = vm.flatAlerts[vm.maxSelected];
			minAlert = vm.flatAlerts[vm.minSelected];
		} else {
			lastSelectedAlert = vm.flatAlerts[vm.lastClickedAlertIndex];
		}

		vm.flatAlerts = [];
		var index = 0;
		for (var i = 0; i < vm.alerts.length; i++) {
			var currAlert = vm.alerts[i];
			if (currAlert.ContainedAlerts) {
				currAlert.minIndex = index;
				for (var j = 0; j < currAlert.ContainedAlerts.length; j++) {
					var child = currAlert.ContainedAlerts[j];
					currAlert.maxIndex = index;
					child.index = index++;
					child.parent = currAlert;
					setAlertFields(child);
					vm.flatAlerts.push(child);
				}

				updateParent(currAlert); // fix aggregated fields
				currAlert.similarBy = vm.getGroupKey(currAlert.GroupKey);

				// default alerts to collapsed
				if (currAlert.collapsed === undefined) {
					currAlert.collapsed = true;
				}
			} else {
				currAlert.index = index++;
				vm.flatAlerts.push(currAlert);
			}

			setAlertFields(currAlert);
		}

		var minAlertId = -1;
		if (vm.stickySelect) {
			minAlertId = minAlert ? minAlert.index : minAlertId;
		} else {
			minAlertId = lastSelectedAlert ? lastSelectedAlert.index : minAlertId;
		}

		var maxAlertId = maxAlert ? maxAlert.index : -1;
		setStickySelection(vm.stickySelect, minAlertId, maxAlertId);
	};

	var setStickySelection = function(newValue, minSelected?, maxSelected?) {
		if (!newValue) {
			vm.lastClickedAlertIndex = minSelected;
			vm.stickySelect = false;
		} else {
			vm.stickySelect = true;
			vm.minSelected = minSelected;
			vm.maxSelected = maxSelected;
		}
	};

	vm.isSelected = function(alert) {
		if (alert.ContainedAlerts) {
			for (var i = 0; i < alert.ContainedAlerts.length; i++) {
				if (!sidePaneService.isSelected(alert.ContainedAlerts[i].AlertId)) {
					return false;
				}
			}
			return true;
		} else {
			return vm.showAlertHighlight
				? alertFilterService.ishighlightActive(alert.AlertId)
				: sidePaneService.isSelected(alert.AlertId);
		}
	};

	vm.updateQueue = function(sortBy) {
		vm.pageIndex = 1;

		if (vm.sort == sortBy) {
			if (vm.sortOrder == 'Ascending') {
				vm.sortOrder = 'Descending';
			} else {
				vm.sortOrder = 'Ascending';
			}
		} else {
			vm.sort = sortBy;
			vm.sortOrder = 'Descending';
		}
	};

	var getSidePaneKey = function(alert) {
		if (!alert.ContainedAlerts) {
			return alert.AlertId;
		}

		var ids = [];
		for (var i = 0; i < alert.ContainedAlerts.length; i++) {
			ids.push(alert.ContainedAlerts[i].AlertId);
		}

		return ids;
	};

	var addRemoveToSelection = function(alert) {
		if (alert.ContainedAlerts) {
			// toggle as a group - select all or none, no matter what was earlier
			var allSelected = true;
			for (var i = 0; i < alert.ContainedAlerts.length && allSelected; i++) {
				if (!alertManagementService.isManaged(alert.ContainedAlerts[i].AlertId)) {
					allSelected = false;
				}
			}

			// Only add in case not all are selected (=group needs to be select all)
			alertManagementService.toggleInSelection(alert.ContainedAlerts, !allSelected);
			setStickySelection(false, alert.minIndex);
		} else {
			alertManagementService.toggleInSelection([alert]);
			setStickySelection(false, alert.index);
		}

		return;
	};

	vm.buildAlertLink = function(alert) {
		if (!alert.AlertId) return undefined;
		return $state.href('alert', { id: alert.AlertId });
	};

	vm.getAssignedToUser = function(alert) {
		if (alert.ContainedAlerts) {
			if (alert.AssignedToCount > 1) return alert.AssignedToCount + ' owners';
			else if (alert.AssignedToCount == -1) return 'Partially assigned';
		}

		if (!alert.AssignedTo) return '';

		return alert.AssignedTo.split('@')[0];
	};

	vm.checkmarkClick = function(alert, event) {
		const checkCandidate = alert.AlertId ? alert.AlertId : alert.GroupId ? alert.GroupId : null;
		if (vm.isRbacEnabled && checkCandidate !== null) {
			rbacMachineGroupService.isUserExposedToAlert(alert.AlertId).then(
				response => {
					if (response.status === 200 && response.data === true) {
						isUserExposedCallBack(alert, event);
					} else {
						appInsights.trackException(
							new Error(
								'Alerts list page: isUserExposedToAlert: User is not authorized to view an alert sidepane, status code: ' +
									response.status +
									' alertID: ' +
									alert.AlertId
							)
						);
					}
				},
				function(e) {
					appInsights.trackException(
						new Error(
							'Something went wrong, Cant check if user is authorized to view alert: ' + e
						)
					);
				}
			);
		} else {
			isUserExposedCallBack(alert, event);
		}
	};

	function isUserExposedCallBack(alert, event) {
		event.stopPropagation();
		event.preventDefault();

		if (event.ctrlKey || event.metaKey) {
			addRemoveToSelection(alert);
			return;
		}

		if (event.shiftKey) {
			var alertsToSelect = [];
			var minAlertIndex = alert.ContainedAlerts ? alert.minIndex : alert.index;
			var maxAlertIndex = alert.ContainedAlerts ? alert.maxIndex : alert.index;
			var startIndex = vm.minSelected;
			var endIndex = vm.maxSelected;
			if (vm.stickySelect) {
				if (minAlertIndex < startIndex) {
					// Move selection up
					startIndex = minAlertIndex;
				} else {
					endIndex = maxAlertIndex;
				}
			} else {
				startIndex =
					vm.lastClickedAlertIndex >= 0
						? Math.min(minAlertIndex, vm.lastClickedAlertIndex)
						: minAlertIndex;
				endIndex =
					vm.lastClickedAlertIndex >= 0
						? Math.max(maxAlertIndex, vm.lastClickedAlertIndex)
						: maxAlertIndex;
			}

			for (var i = startIndex; i <= endIndex; i++) {
				alertsToSelect.push(vm.flatAlerts[i]);
			}

			alertManagementService.selectAlerts(alertsToSelect);

			setStickySelection(true, startIndex, endIndex);
			return;
		}

		// Click with no extra buttons - switches selection
		if (alert.ContainedAlerts) {
			alertManagementService.selectAlerts(alert.ContainedAlerts);
			setStickySelection(true, alert.minIndex, alert.maxIndex);
		} else {
			alertManagementService.selectAlerts([alert]);
			setStickySelection(false, alert.index);
		}
	}

	vm.selectRow = function(alert, event, goToAlert) {
		if (goToAlert) {
			// avoid triggering the click event of the entire row when clicking on the event title.
			event.stopPropagation();
			// link to alert was pressed - navigate to the alert page.
			if (event.which === 2 || (event.which === 1 && event.ctrlKey)) {
				$window.open('/alert/' + alert.AlertId, '_blank');
			} else {
				$state.go('alert', { id: alert.AlertId });
			}

			return;
		}

		// alert row selected - highlight related events.
		event.preventDefault();
		if (alert.ContainedAlerts) {
			alert.collapsed = !alert.collapsed;
			return;
		}

		if (vm.showAlertHighlight) {
			alertFilterService.selectAlerts([alert], true);
			alert.highlighted = isHighlighted(alert);
		}
	};

	vm.manageAlert = function(alert, event) {
		// Detect middle click and just ignore
		if (event.which == 2 || event.button == 4) {
			return;
		}

		event.preventDefault();

		// Select alerts and only open side pane
		if (alert.ContainedAlerts) {
			alertManagementService.selectAlerts(alert.ContainedAlerts, true);
			setStickySelection(true, alert.minIndex, alert.maxIndex);
		} else {
			alertManagementService.selectAlerts([alert], true);
			setStickySelection(false, alert.index);
		}
	};

	vm.getColumnSize = function() {
		return vm.isAutoIRFeatureEnabled ? 'col-md-3' : 'col-md-4';
	};
}
