import { ApplicationInitStatus, Injectable, Injector, Type } from '@angular/core';
import { DataEntityType, ModelBase } from '@microsoft/paris';
import { EntityTypeService } from '../models/entity-type-service.interface';
import { EntityType } from '../models/entity-type.interface';
import { NavItemModel } from '@wcd/shared';

@Injectable()
export class GlobalEntityTypesService {
	private readonly _entityTypesByData: Map<DataEntityType, EntityType> = new Map();
	private readonly _entityTypesById: Map<string, EntityType> = new Map();

	constructor(private readonly injector: Injector, private readonly appInitStatus: ApplicationInitStatus) {}

	get entityTypes(): ReadonlyArray<EntityType> {
		return Array.from(this._entityTypesByData.values());
	}

	registerEntityType(entityTypeService: Type<EntityTypeService>) {
		this.appInitStatus.donePromise.then(() => {
			const { entityType } = this.injector.get(entityTypeService);
			this._entityTypesByData.set(entityType.entity, entityType);
			this._entityTypesById.set(entityType.id, entityType);
		});
	}

	getEntityType<T extends ModelBase, U = {}>(
		dataEntityTypeOrId: string | number | DataEntityType<T>
	): EntityType<T, U> {
		if (typeof dataEntityTypeOrId === 'string' || typeof dataEntityTypeOrId === 'number') {
			return this._entityTypesById.get(String(dataEntityTypeOrId)) as any as EntityType<T, U>;
		}

		return this._entityTypesByData.get(dataEntityTypeOrId) as any as EntityType<T, U>;
	}

	getEntityLink<T extends ModelBase, U = {}>(
		dataEntityTypeOrId: DataEntityType<T> | string | number,
		entity: T,
		options?: { [key: string]: any }
	): string {
		if (!dataEntityTypeOrId || !entity) {
			return null;
		}
		const entityType: EntityType<T, U> = this.getEntityType(dataEntityTypeOrId);
		return entityType.getEntitiesLink && entityType.getEntitiesLink([entity]);
	}

	getEntityTypeIcon<T extends ModelBase, U = {}>(dataEntityType: DataEntityType<T>): string {
		if (!dataEntityType) {
			return null;
		}
		const entityType: EntityType<T> = this.getEntityType(dataEntityType);
		return entityType ? entityType.icon : null;
	}

	getEntityName<T extends ModelBase, U = {}>(
		dataEntityTypeOrId: DataEntityType<T> | string | number,
		entity: T
	): string {
		if (!dataEntityTypeOrId || !entity) {
			return null;
		}
		const entityType: EntityType<T, U> = this.getEntityType(dataEntityTypeOrId);
		return entityType.getEntityName && entityType.getEntityName(entity);
	}

	getRouteLinkModel<T extends ModelBase, U = {}>(
		dataEntityTypeOrId: DataEntityType<T> | string | number,
		entity: T
	): Partial<NavItemModel> {
		if (!dataEntityTypeOrId || !entity) {
			return null;
		}
		const entityType: EntityType<T, U> = this.getEntityType(dataEntityTypeOrId);
		return entityType.getNavigationModel && entityType.getNavigationModel(entity);
	}

	static getCommonValue<T = {}, U = any>(items: ReadonlyArray<T>, getValue: (item: T) => U): U {
		if (!items || !items.length) return null;

		const firstValue: U = getValue(items[0]);
		return items.slice(1).every(item => getValue(item) === firstValue) ? firstValue : null;
	}

	getEntityTooltip<T extends ModelBase, U = {}>(dataEntityType: DataEntityType<T>, entity: T) {
		if (!dataEntityType || !entity) {
			return null;
		}
		const entityType: EntityType<T, U> = this.getEntityType(dataEntityType);
		return entityType.getEntityTooltip && entityType.getEntityTooltip(entity);
	}
}
