import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges } from '@angular/core';
import { Investigation, MtpInvestigation, OfficeUtils, PendingAction } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { TimeRange, TimeRanges } from '../../../utils/time-ranges.model';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'investigation-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './investigation.entity-details.component.html',
})
export class InvestigationEntityDetailsComponent extends EntityDetailsComponentBase<Investigation>
	implements OnChanges {
	@Input() investigation: Investigation;
	totalPendingTime: number;
	shortInvestigationId: number | string;

	constructor(
		injector: Injector,
		public globalEntityTypesService: GlobalEntityTypesService,
		public i18nService: I18nService
	) {
		super(injector);
	}

	ngOnChanges() {
		const isMtpInvestigation = this.investigation instanceof MtpInvestigation;

		this.totalPendingTime = isMtpInvestigation
			? this.getTotalPendingTimeRanges()
			: (this.investigation.remediationPendingTime || 0) +
			  (this.investigation.resourcePendingTime || 0);

		this.shortInvestigationId = OfficeUtils.getShortId(this.investigation.id);
	}

	getTotalPendingTimeRanges(): number {
		return (
			new TimeRanges(
				this.investigation.pendingActions.map((pendingAction: PendingAction) => {
					return new TimeRange(pendingAction.startTime, pendingAction.endTime);
				})
			).getTotalTime() / 1000
		);
	}
}
