import { DataQuery, Entity, EntityConfig, EntityField, EntityModelBase } from '@microsoft/paris';
import { AirsEntityTypeConfig } from './airs-entity-type-config.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { AirsEntityAction } from './airs-entity-action.value-object';
import { AirsEntityReport } from './airs-entity-report.value-object';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { AirsRelatedEntityReport } from './airs-related-entity-report.value-object';
import { AirsRelatedEntity } from './airs-related-entity.value-object';
import { AirsEntityBehaviorGroup } from './airs-entity-behavior.interface';
import { RemediationAction } from '../remediation/remediation-action.entity';
import { SystemExclusion } from '../system_exclusions/system-exclusion.entity';
import { AirsEntityRemediationStatus } from './airs-entity-remediation-status.entity';
import { AirsEntityVerdict } from './airs-entity-verdict.entity';
import { EvidenceDetectionSource } from '../evidence/evidence-detection-source.value-object';
import { compact, get } from 'lodash-es';
import { mapAlertV3EntityToAirsEntity } from './alertV3/converters/airs-entity.converter.utils';
import { OfficeUtils } from '../utils/office-utils';
import { AirsEntityType, AirsEntityTypeValue, MappableAirsEntityTypes } from './airs-entity-type.entity';
import { AirsEntityRefNames } from './types/airs-entity-ref-names';
import { dateStrToUtc } from '../utils';
import { RemediationStatusId } from './airs-entity-status-types.enum';
import { sccHostService } from '@wcd/scc-interface';

export const airsEntityConfig: EntityConfig<AirsEntity> = Object.freeze({
	singularName: 'Entity',
	pluralName: 'Entities',
	allItemsProperty: 'results',
	allItemsEndpointTrailingSlash: false,
	separateArrayParams: true,
	endpoint: (config, query) => {
		if (query.where['incident_id']) {
			if (query.where['EvidenceApiV2']) {
				return `evidenceapiservice/evidence/incident/${query.where['incident_id']}`;
			}
			return `entities/evidence/${query.where['incident_id']}`;
		}

		if (query.where['useOfficeEntityApi']) {
			return `Find/MtpBatch?tenantid=${
				query.where['tenantId']
			}&PageSize=200&Filter=ModelType eq 0 and ContainerUrn eq '${query.where['investigation_urn']}'`;
		}

		return 'entities/';
	},

	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
		if (params && params.useEvidenceApiV2 && params.mergeByKey && params.alertId && params.type_id && params.evidenceV2.callDetails){
			return `evidenceapiservice/evidence/details?AlertId=${params.alertId}&MergeByKey=${
				params.mergeByKey
			}`;
		}

		if (params && params.useMdiEntityApi) {
			const sid = params.sid;
			const aadUserId = params.aadUserId;
			const url = `officeui/mdi/account-details`;

			if (!sid) {
				throw new Error("Can't get entity data, missing sid.");
			}

			return aadUserId ? `${url}?sid=${sid}&aadUserId=${aadUserId}` : `${url}?sid=${sid}`;
		}

		const intEntityId = parseInt(<string>itemId);
		const isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
		if (isIdNegative) {
			throw new Error('Entity id cannot be negative.');
		}

		if (params && params.useOfficeEntityApi) {
			// There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
			return `Find/MtpBatch?tenantid=${
				params.tenantId
			}&PageSize=50&Filter=ModelType eq 0 and UrnProp eq '${itemId}'`;
		}

		if (!params || !params.type_id || !params.investigation_id) {
			throw new Error("Can't get entity, missing entity type.");
		}
		const entityId = params.useEvidenceApiV2 && params.evidenceV2.entityId ? params.evidenceV2.entityId : itemId;
		return `entities/${entityId}?type_id=${params.type_id}&investigation_id=${params.investigation_id}`;
	},
	baseUrl: (config, query) => {
		const useEvidenceApiV2 =
			query && query.where && (query.where['useEvidenceApiV2'] || query.where['EvidenceApiV2']);
		// when using the new evidence service (EvidenceApiV2 flag is enabled) we don't need to go through office to get the data
		if (query && query.where && query.where['useOfficeEntityApi'] && !useEvidenceApiV2) {
			return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
		}
		return config.data.serviceUrls.automatedIr;
	},
	parseData: (data, _, query) => {
		const shouldConvertDataFromAlertV3 =
			query &&
			query.where &&
			(query.where['useEvidenceApiV2'] || query.where['EvidenceApiV2']) &&
			(!query.where['evidenceV2'] || query.where['evidenceV2'].callDetails);

		if (shouldConvertDataFromAlertV3 || (query && query.where['useOfficeEntityApi'])) {
			if (query.where['useOfficeEntityApi'] && !shouldConvertDataFromAlertV3) {
				data = get(data, 'ResultData[0].EntityBasePayload');
			}

			data = OfficeUtils.convertDataFromAlertV3(data);
			data = data && (Array.isArray(data) ? data : [data]).map(e => mapAlertV3EntityToAirsEntity(e));
			data = compact(data); // we might have unknown types so we need to filter out the falsey values
			if (!query.where['isListView']) {
				return (data && data.length && data[0]) || null;
			}
			return data;
		}

		const useNewStatus = query && query.where['useNewStatus'];
		return Object.assign(
			{},
			data,
			data.results && {
				results: data.results.map(r => Object.assign({}, r, { useNewStatus })),
			}
		);
	},
});

const AirsEntityRefNameMap: Record<MappableAirsEntityTypes, AirsEntityRefNames> = {
	[AirsEntityTypeValue.File]: AirsEntityRefNames.AirsFile,
	[AirsEntityTypeValue.Process]: AirsEntityRefNames.AirsProcess,
	[AirsEntityTypeValue.Driver]: AirsEntityRefNames.AirsDriver,
	[AirsEntityTypeValue.Service]: AirsEntityRefNames.AirsService,
	[AirsEntityTypeValue.URL]: AirsEntityRefNames.AirsUrl,
	[AirsEntityTypeValue.WebPage]: AirsEntityRefNames.AirsUrl,
	[AirsEntityTypeValue.IP]: AirsEntityRefNames.AirsIp,
	[AirsEntityTypeValue.AppendedFile]: AirsEntityRefNames.AirsAppendedFile,
	[AirsEntityTypeValue.EmailUrl]: AirsEntityRefNames.AirsEmailLink,
	[AirsEntityTypeValue.User]: AirsEntityRefNames.AirsUser,
	[AirsEntityTypeValue.PersistenceMethod]: AirsEntityRefNames.AirsPersistenceMethod,
	[AirsEntityTypeValue.UserActivity]: AirsEntityRefNames.AirsUserActivity,
	[AirsEntityTypeValue.MailCluster]: AirsEntityRefNames.AirsEmailCluster,
	[AirsEntityTypeValue.MailMessage]: AirsEntityRefNames.AirsEmailMessage,
	[AirsEntityTypeValue.Mailbox]: AirsEntityRefNames.AirsMailbox,
	[AirsEntityTypeValue.MailboxConfiguration]: AirsEntityRefNames.AirsMailboxConfiguration,
	[AirsEntityTypeValue.SubmissionMail]: AirsEntityRefNames.AirsEmailMessageSubmission,
	[AirsEntityTypeValue.Account]: AirsEntityRefNames.Account,
	[AirsEntityTypeValue.SecurityGroup]: AirsEntityRefNames.AirsSecurityGroup,
	[AirsEntityTypeValue.RegistryKey]: AirsEntityRefNames.AirsRegistryKey,
	[AirsEntityTypeValue.RegistryValue]: AirsEntityRefNames.AirsRegistryValue,
	[AirsEntityTypeValue.CloudApplication]: AirsEntityRefNames.AirsCloudApplication,
	[AirsEntityTypeValue.CloudLogonSession]: AirsEntityRefNames.AirsCloudLogonSession,
	[AirsEntityTypeValue.OauthApplication]: AirsEntityRefNames.AirsOauthApplication,
	[AirsEntityTypeValue.ActiveDirectoryDomain]: AirsEntityRefNames.AirsActiveDirectoryDomain,
};

@Entity({
	...airsEntityConfig,
	modelWith: (rawData, query) => {
		const typeIdFromQuery =
			query &&
			query.where &&
			(query.where['type_id'] != null ? query.where['type_id'] : query.where['entity_type']);

		let typeId: string | Array<string>;

		if (typeIdFromQuery == null) {
			typeId = rawData && rawData['type_id'] != null ? rawData['type_id'] : rawData['entity_type'];
		} else {
			typeId = typeIdFromQuery;
		}

		if (typeId instanceof Array) {
			typeId = typeId[0];
		}
		const intTypeId = parseInt(typeId, 10);
		// reverse enum lookup
		const typeKey = AirsEntityType[intTypeId];
		return AirsEntityRefNameMap[typeKey];
	},
})
export class AirsEntity extends EntityModelBase<string> {
	@EntityField({
		data: ['urn', 'id'],
		parse: (id, rawData) => {
			const intEntityId = parseInt(<string>rawData.id);
			const isIdNegative = !isNaN(intEntityId) && intEntityId < 0;
			// negative 'id' means that it's not AirsEntity and we don't want to use AIRS BE
			return isIdNegative && rawData.urn ? rawData.urn : rawData.id;
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'merge_by_key' })
	mergeByKey: string;

	@EntityField({ data: 'urn' })
	urn: string;

	@EntityField() name: string;

	entityName: string;

	@EntityField({
		data: ['entity_type', 'type_id'],
		parse: (type, rawData, query: DataQuery) => {
			if (type !== undefined && type !== null) return type;

			const typeId = query.where['type_id'];
			if (typeId instanceof Array) {
				return typeId[0];
			}

			return typeId;
		},
	})
	type: AirsEntityTypeConfig;

	/**
	 * @deprecated
	 */
	@EntityField({ data: 'entity_status', require: data => data })
	status: AirsEntityStatus;

	@EntityField({ defaultValue: false })
	useNewStatus: boolean;

	@EntityField({
		data: 'remediation_status',
		require: data => data,
		parse: status => {
			/* 	remediation status should be empty in case of 'Active' status
				(see the following bug: https://microsoft.visualstudio.com/OS/_workitems/edit/31830546/)
			*/
			if (status !== RemediationStatusId.Active) {
				return status;
			}
		},
	})
	remediationStatus?: AirsEntityRemediationStatus;

	@EntityField({ data: 'verdict', require: data => data })
	verdict?: AirsEntityVerdict;

	@EntityField() action: AirsEntityAction;

	@EntityField({ data: '__self' })
	protected _rawData: any;

	@EntityField({ data: 'hosts', arrayOf: InvestigatedMachine })
	machines: Array<InvestigatedMachine>;

	@EntityField({ data: 'host' })
	machine: InvestigatedMachine;

	@EntityField() report: AirsEntityReport;

	@EntityField({ data: 'related_reports', arrayOf: AirsRelatedEntityReport })
	relatedReports: Array<AirsRelatedEntityReport>;

	@EntityField({ arrayOf: AirsRelatedEntity })
	relations: Array<AirsRelatedEntity>;

	@EntityField({ data: 'status_details' })
	statusDetails: Array<string>;

	@EntityField({
		parse: (investigation, rawData) => {
			return (
				(investigation && { id: investigation.investigation_id, title: investigation.title }) ||
				(rawData.AirsInvestigationId && { id: rawData.AirsInvestigationId, title: '' })
			);
		},
	})
	investigation: { id: string | number; title: string };

	@EntityField({
		parse: behavior => {
			const behaviorArray = [];
			let behaviorObject;

			for (const groupName in behavior) {
				behaviorObject = {
					name: groupName,
					values: [],
				};

				for (const propertyName in behavior[groupName]) {
					const propertyValues: Array<string | { [index: string]: string }> =
						behavior[groupName][propertyName];
					if (propertyValues && propertyValues.length) {
						behaviorObject.values.push({
							name: propertyName,
							values: propertyValues
								.map(
									(
										value: string | { [index: string]: string }
									): string | Array<Array<string>> => {
										if (Object(value) === value) {
											const objValues: Array<Array<string>> = [];
											for (const objProperty in <Object>value)
												objValues.push([objProperty, value[objProperty]]);
											return objValues.length && objValues;
										}
										return <string>value;
									}
								)
								.filter(value => !!value),
						});
					}
				}

				if (behaviorObject.values.length) behaviorArray.push(behaviorObject);
			}

			return behaviorArray;
		},
	})
	behavior: Array<AirsEntityBehaviorGroup>;

	@EntityField({
		data: 'all_actions',
		arrayOf: RemediationAction,
		parse: allActions => allActions && allActions.filter(action => action['undo_state'] === 'undoable'),
	})
	undoableActions: Array<RemediationAction>;

	@EntityField({
		data: 'all_actions',
		arrayOf: RemediationAction,
		parse: (allActions, rawData) => {
			if (!allActions) {
				return rawData['remediation_actions'];
			}
			return allActions.filter(action => action['is_pending']);
		},
	})
	remediationActions: Array<RemediationAction>;

	@EntityField({ data: 'acl_rule' })
	systemExclusionRule: SystemExclusion;

	@EntityField({ data: 'entity_page_url' })
	deepLink?: string;

	@EntityField({ data: 'entity_detection_src' })
	detectionContext?: EvidenceDetectionSource;

	@EntityField({ data: 'first_seen', parse: dateStr => dateStrToUtc(dateStr) })
	firstSeen?: Date;

	// if this value is true - need to call to AIRS apis
	@EntityField({
		parse: (data, rawData) => {
			return (rawData.EntityId || rawData.id) > 0;
		},
	})
	isConvergedPlatform: boolean;

	//here we can save all the data that needs to be supported temporary
	@EntityField({
		parse: (data, rawData) => {
			return {
				evidenceV2: {
					entityId: rawData.EntityId,
				},
			};
		},
	})
	additionalData: { [index: string]: any };

	@EntityField({ data: 'is_office_entity' })
	private _isOfficeEntity: boolean;

	isOfficeEntity: boolean;

	constructor(data) {
		super(data);
		this.isOfficeEntity = this._isOfficeEntity || !!this.urn;
		this.entityName = this.name;
	}

	get key(): string {
		return `${this.type.typeName}.${this.id}`;
	}

	private _relationCount;
	get relationCount(): number {
		if (isNaN(this._relationCount)) {
			if (!this.relations) this._relationCount = 0;

			this._relationCount = this.relations.reduce((relationCount, relation) => {
				return relation.relationTypes.reduce((count, relationType) => {
					return count + relationType.entities.length;
				}, relationCount);
			}, 0);
		}

		return this._relationCount;
	}
}
