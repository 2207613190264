import { Component, Input, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef, OnInit } from '@angular/core';
import { format } from 'd3-format';
import { TvmHistoryTrendOptions } from '../tvm-history-trend/tvm-history-trend.component';
import { I18nService } from '@wcd/i18n';
import { generateDatesForHistoryArray } from '../../tvm-utils';
import { AreaChartOptions, ChartColor } from '@wcd/charts';
import { LocaleConfigService } from '@wcd/localization';

const EXPOSED_ASSETS_CHART_HEIGHT = 22;
const EXPOSED_ASSETS_CHART_WIDTH = 85;

@Component({
	selector: 'tvm-assets-statistics',
	templateUrl: './tvm-assets-statistics.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TvmAssetsStatisticsComponent implements OnDestroy, OnInit {
	private static unlocked = true;
	private destroyed = false;

	newChartOptions: AreaChartOptions;

	@Input() isImprovementNegative: boolean = true;
	@Input() totalAssetCount: number;
	@Input() assetsCount: number;
	@Input()
	set assetsCountHistory(newAssetsCountHistory: number[]){
		this._assetsCountHistory = newAssetsCountHistory;

		if (this.newChartOptions) {
			// On new input - force re-render
			this.newChartOptions = null;
			this.changeDetectorRef.detectChanges();
		}
		this.setNewChartOptions(newAssetsCountHistory);
		this.changeDetectorRef.detectChanges();
	}
	get assetsCountHistory(): number[] {
		return this._assetsCountHistory;
	}

	@Input()
	set productNeverMatched(flag: boolean) {
		this._productNeverMatched = flag;
		this.resetView();
		this.renderChart();
	}

	private _assetsCountHistory: number[];

	_productNeverMatched: boolean;

	productNeverMatchedTooltip: string;

	_trendOptions: TvmHistoryTrendOptions;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		private localeConfigService: LocaleConfigService,
		private i18nService: I18nService
	) {
		this.productNeverMatchedTooltip = this.i18nService.strings.tvm_softwareInventory_productNeverMatchedTooltip;
	}

	ngOnInit(){

	}

	ngOnDestroy() {
		TvmAssetsStatisticsComponent.unlocked = true;
		this.destroyed = true;
	}

	private setNewChartOptions(assetsHistory: number[]) {
		const historyDataPoints = generateDatesForHistoryArray(
			assetsHistory,
			this.localeConfigService.isLocalTimeZone
		);
		const improvement = this.isImprovementNegative
								? assetsHistory[0] < assetsHistory[assetsHistory.length - 1] // today's assets count is lower then when history started
								: assetsHistory[0] > assetsHistory[assetsHistory.length - 1]; // today's assets count is higher then when history started

		this.newChartOptions = {
			data: historyDataPoints,
			yMinValue: 0,
			yMaxValue: Math.max(...assetsHistory),
			color: improvement ? ChartColor.Green : ChartColor.Red,
			width: EXPOSED_ASSETS_CHART_WIDTH,
			height: EXPOSED_ASSETS_CHART_HEIGHT,
			// We need id to be unique for all charts in each page because it raise accessability error.
			id: `areaChart-${this.totalAssetCount}-${this.assetsCount}`,
		};
	}

	/**
	 * Rendering chart takes time
	 * Resetting old view so if angular re-use the component it looks good
	 */
	private resetView() {
		if (this._trendOptions) {
			this._trendOptions = null;
			this.changeDetectorRef.detectChanges();
		}
	}

	/**
	 * Rendering multiple components like this can block browser thread
	 * To resolve the issue - we make sure to have timeout between renders, so the browser can be more interactive
	 */
	private renderChart() {
		if (TvmAssetsStatisticsComponent.unlocked && !this.destroyed) {
			TvmAssetsStatisticsComponent.unlocked = false;
			this.setTrendOptions();
			this.changeDetectorRef.detectChanges();

			setTimeout(() => (TvmAssetsStatisticsComponent.unlocked = true), 0);
		} else if (!this.destroyed) {
			setTimeout(() => this.renderChart(), 0);
		}
	}

	private setTrendOptions(): void {
		if (!this.assetsCountHistory) {
			this._trendOptions = null;
			return;
		}

		this._trendOptions = this.getHistoryTrendOptions();
	}

	private getHistoryTrendOptions(): TvmHistoryTrendOptions {
		return {
			data: this.assetsCountHistory,

			area: true,
			graphColor: {
				byImprovement: true,
			},
			xAxisValues: {
				show: false,
			},
			yAxisValues: {
				show: false,
			},
			showYGrid: false,
			height: 33,
			width: 103,
			advancement: {
				show: false,
			},
			showNumberOfDays: false,
			toolTipOptions: {
				format: {
					value: (n: string) => format(',')(parseInt(n, 10)),
					title: (n: string) => null,
					name: (n: string) => '',
				},
				contents: (data: any[], defaultTitleFormat: string, defaultValueFormat: string, color: any) =>
					`<div style="background: white;box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.4);opacity: 0.8;">
					${data[0].value}</div>`,
			},
		};
	}
}
