import { SevilleModule } from '../seville.module';

SevilleModule.factory('autoRefreshService', autoRefreshService);

autoRefreshService.$inject = ['$interval', '$state'];

function autoRefreshService($interval, $state) {
	var model = this;
	var service = {
		isOn: function() {
			return model.running || false;
		},
		turnOn: function(interval) {
			if (model.running) return;
			model.running = true;
			model.interval = interval || 60;
			model.count = model.interval;
			model.id = $interval(function() {
				model.count--;
				if (model.count <= 0) {
					model.count = model.interval;
					$state.reload();
				}
			}, 1000);
		},
		turnOff: function() {
			model.running = false;
			if (model.id) {
				$interval.cancel(model.id);
			}
		},
		getCount: function() {
			return model.running ? model.count : '';
		},
	};

	return service;
}
