import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

const VENDOR_LOGOS_FOLDER = 'assets/images/detection_systems';

@Entity({
	singularName: 'Service Template',
	pluralName: 'Service Templates',
	endpoint: 'services/templates',
	loadAll: true,
	allItemsProperty: 'results',
	allItemsEndpoint: '/all',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	readonly: true,
})
export class ServiceTemplate extends EntityModelBase {
	@EntityField() name: string;
	@EntityField() vendor: string;
	@EntityField() product: string;
	@EntityField() categories: Array<string>;

	readonly logoUrl: string;

	constructor(data) {
		super(data);

		this.logoUrl =
			this.name.toLowerCase() === 'other'
				? null
				: `${VENDOR_LOGOS_FOLDER}/${this.vendor.toLowerCase()}.png`;
	}
}
