import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ChecklistValue } from '@wcd/forms';
import { AppConfigService } from '@wcd/app-config';
import { map } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { OnboardingService } from '@wcd/onboarding';

const SAW_GEO_PANEL = 'MtpOnboarding-SawGeoPanel';
const CLICKED_START = 'MtpOnboarding-ClickedStartOnGeoPanel';
const CLICKED_CANCEL = 'MtpOnboarding-ClickedCancelOnGeoPanel';

@Component({
	template: `
		<wcd-panel #panel (close)="destroy()" [settings]="settings">
			<div
				class="wcd-padding-xLarge-top wcd-padding-bottom wcd-padding-largeMedium-horizontal wcd-full-height wcd-flex-vertical wcd-flex-justify-content-space-between"
			>
				<div>
					<h2 class="wcd-font-weight-bold">{{ 'sccOnboarding.geoSelection.title' | i18n }}</h2>
					<div class="wcd-margin-large-top">
						<div class="wcd-padding-mediumSmall-bottom wcd-font-size-base wcd-font-weight-bold">
							{{ 'sccOnboarding.geoSelection.subtitle' | i18n }}
						</div>
						<markdown [data]="'sccOnboarding.geoSelection.description' | i18n"> </markdown>
						<div class="wcd-margin-medium-top">
							<wcd-radiolist
								[values]="values"
								name="geoRegion"
								[(ngModel)]="this.currentlySelectedGeoRegion"
								(ngModelChange)="onChange()"
								[defaultValue]="this.currentlySelectedGeoRegion"
							>
							</wcd-radiolist>
						</div>
					</div>
				</div>
				<div class="wcd-flex-horizontal wcd-padding-mediumSmall-top wcd-border-top-light">
					<fab-primary-button
						className="wcd-padding-large-horizontal"
						data-track-id="StartOnboardingAfterGeoSelection"
						data-track-type="Button"
						data-track-value="this.currentlySelectedGeoRegion.id"
						[text]="'buttons.start' | i18n"
						[disabled]="!currentlySelectedGeoRegion"
						(onClick)="onClose(currentlySelectedGeoRegion)"
					>
					</fab-primary-button>
					<fab-default-button
						className="wcd-margin-small-horizontal wcd-padding-largeMedium-horizontal btn-cancel"
						data-track-id="CancelGeoSelection"
						data-track-type="Button"
						[text]="'buttons.cancel' | i18n"
						(onClick)="onClose()"
					>
					</fab-default-button>
				</div>
			</div>
		</wcd-panel>
	`,
})
export class GeoSelectionComponent extends PanelContainer implements OnInit {
	values: Array<ChecklistValue>;
	currentlySelectedGeoRegion: ChecklistValue;
	@Output() onGeoSelected = new EventEmitter<ChecklistValue>();

	constructor(
		private appConfigService: AppConfigService,
		private i18nService: I18nService,
		router: Router,
		private onboardingService: OnboardingService,
		private changeDetectorRef: ChangeDetectorRef
	) {
		super(router);
	}

	ngOnInit() {
		this.onboardingService.trackEvent(SAW_GEO_PANEL);
		this.values = map(
			this.appConfigService.supportedGeoRegions,
			geo =>
				<ChecklistValue>{
					id: geo,
					name: this.i18nService.get('endpointManagement.supportedGeoRegions.' + geo),
				}
		);
	}

	onChange() {
		// Temporary hack to allow change detection before migration to React
		this.changeDetectorRef.detectChanges();
	}

	onClose(geo?: ChecklistValue) {
		if (!!geo) {
			this.onboardingService.trackEvent(CLICKED_START, geo.name);
		} else {
			this.onboardingService.trackEvent(CLICKED_CANCEL);
		}
		this.onGeoSelected.emit(geo);
		this.closePanel();
		this.changeDetectorRef.detectChanges();
	}
}
