/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i3 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i4 from "../../../shared/pipes/url-with-token.pipe";
import * as i5 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i6 from "../../../dialogs/modals/components/modal.component.ngfactory";
import * as i7 from "../../../dialogs/modals/components/modal.component";
import * as i8 from "@angular/common";
import * as i9 from "./external-source-action-results.modal";
import * as i10 from "@angular/router";
import * as i11 from "../../../../../../../projects/app-config/src/lib/service-urls/services/service-urls.service";
var styles_ExternalSourceActionResultsModalComponent = [];
var RenderType_ExternalSourceActionResultsModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ExternalSourceActionResultsModalComponent, data: {} });
export { RenderType_ExternalSourceActionResultsModalComponent as RenderType_ExternalSourceActionResultsModalComponent };
function View_ExternalSourceActionResultsModalComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "a", [["data-track-id", "ViewFullResults"], ["data-track-type", "Navigation"], ["target", "_blank"]], [[1, "href", 4]], null, null, null, null)), i0.ɵppd(1, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "wcd-shared-icon", [["class", "small-icon"]], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵdid(4, 573440, null, 0, i2.IconComponent, [i3.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\tView Full Results\n\t\t\t\t"]))], function (_ck, _v) { var currVal_1 = "popOut"; _ck(_v, 4, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), ((_co.ApiBaseUrl + "/services/result?result_id=") + _co.resultId))); _ck(_v, 0, 0, currVal_0); }); }
export function View_ExternalSourceActionResultsModalComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i4.UrlWithTokenPipe, [i5.AuthService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 11, "modal", [["data-track-component", "AirsExternalSourceResults"]], null, [[null, "close"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i6.View_ModalComponent_0, i6.RenderType_ModalComponent)), i0.ɵdid(3, 180224, null, 0, i7.ModalComponent, [i0.ElementRef, i0.ChangeDetectorRef], { settings: [0, "settings"] }, { close: "close" }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 7, "div", [["class", "wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "pre", [["class", "code block"]], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ExternalSourceActionResultsModalComponent_1)), i0.ɵdid(11, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 3, 0, currVal_0); var currVal_2 = _co.isPartial; _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.result; _ck(_v, 8, 0, currVal_1); }); }
export function View_ExternalSourceActionResultsModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "external-source-action-results-modal", [], null, null, null, View_ExternalSourceActionResultsModalComponent_0, RenderType_ExternalSourceActionResultsModalComponent)), i0.ɵdid(1, 245760, null, 0, i9.ExternalSourceActionResultsModalComponent, [i10.Router, i11.ServiceUrlsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ExternalSourceActionResultsModalComponentNgFactory = i0.ɵccf("external-source-action-results-modal", i9.ExternalSourceActionResultsModalComponent, View_ExternalSourceActionResultsModalComponent_Host_0, { settings: "settings", result: "result", isPartial: "isPartial", resultId: "resultId" }, {}, []);
export { ExternalSourceActionResultsModalComponentNgFactory as ExternalSourceActionResultsModalComponentNgFactory };
