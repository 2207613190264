var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AirsEntity } from '@wcd/domain';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { PENDING_ACTION_ID_STR, UNDO_ACTION_ID_STR } from '../services/airs-entity.entity-type.service';
var AirsEntityEntityPanelMultiComponent = /** @class */ (function (_super) {
    __extends(AirsEntityEntityPanelMultiComponent, _super);
    function AirsEntityEntityPanelMultiComponent(i18nService, globalEntityTypesService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.airsEntityEntityTypeService = _this.globalEntityTypesService.getEntityType(AirsEntity);
        return _this;
    }
    AirsEntityEntityPanelMultiComponent.prototype.setEntities = function (entities) {
        _super.prototype.setEntities.call(this, entities);
        var actions$ = toObservable(this.airsEntityEntityTypeService.getActions(entities));
        this.showWarning$ = actions$.pipe(map(function (actions) {
            return !actions.some(function (a) { return a.id.includes(PENDING_ACTION_ID_STR) || a.id === UNDO_ACTION_ID_STR; });
        }));
    };
    return AirsEntityEntityPanelMultiComponent;
}(EntitiesPanelComponentBase));
export { AirsEntityEntityPanelMultiComponent };
