export interface entityIconTheme {
	iconName: string;
	iconColor: string;
	iconBackgroundColor: string;
}

/****************************************************************************************************
 * This is a copy of entity-icon-theme-mapping from InE package, used for Angular entities still defined
 * in this package.
 * IT ONLY CONTAINS ENTITIES THAT HAS ENTITY PAGES IN ANGULAR!!!! Please remove any migrated entity from here!
 * Please remove once all are migrated
 *****************************************************************************************************/

/**
 * Get a map of icon and icon colors per entity.
 * Based on this Figma:
 * https://www.figma.com/file/pqAvLnT1gPGrsxqKwHXk1j/Search?node-id=4207%3A408339
 * Design Owner: Michal Har Gev
 * @param theme
 */
export const getEntityIconThemeMapping = (theme) : { [key: string] : entityIconTheme } =>{
	const evidenceTheme = {
		iconColor: theme.palette.neutralPrimary,
		iconBackgroundColor: theme.palette.neutralQuaternaryAlt
	};

	return {
		"file": {
			iconName: "Page",
			...evidenceTheme
		},
		"ip": {
			iconName: "Streaming",
			...evidenceTheme
		},
		"url": {
			iconName: "Link",
			...evidenceTheme
		},
		"machine": {
			iconName: "System",
			iconColor: theme.palette.themeLighter,
			iconBackgroundColor: theme.palette.themeDarker
		},
		"incident" : {
			iconName: "ShieldAlert",
			iconColor: '#610000', //theme.semanticColors.criticalStatus,
			iconBackgroundColor: theme.semanticColors.errorBackground
		}
	};
};
