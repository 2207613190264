import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { IPreload } from '@wcd/shared';
import { Observable, of, Subscription } from 'rxjs';
import {
	ApplicationInsights,
	IConfig,
	IConfiguration,
	ITelemetryItem,
	SeverityLevel,
	DistributedTracingModes,
} from '@microsoft/applicationinsights-web';
import { TrackingEventPropertiesBase } from '../models/tracking-event.interface';
import { TitleService } from '../../shared/services/title.service';
import { AppConfigService } from '@wcd/app-config';
import { ErrorsService } from '../../shared/services/errors.service';
import { Severity, TelemetryService, TrackingEventType as TelemetryTrackingEventType } from '@wcd/telemetry';
import { TrackingEventType } from '../models/tracking-event-type.enum';
import { Tags } from '@microsoft/applicationinsights-core-js';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { isObject, isString } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';

const ENVELOPE_PRIVATE_DATA_FIELDS: Array<string> = [
	'name',
	'data',
	'url',
	'page',
	'PageUrl',
	'refUri',
	'uri',
]; // fields in appInsights tracking data that may contain PII and should be cleaned before sent.
const ENVELOPE_EXT_TRACE_PRIVATE_DATA_FIELDS: Array<string> = ['name', 'url']; // inner appInsights fields that are sent with the tracking data. please notice that "operation" field is the original browser url when app loaded and is not changed during app navigating.

let appInsightsRef: any;
let appInsightsOn: boolean = false;

/**
 * API for AppInsights
 * Full documentation at: https://github.com/Microsoft/ApplicationInsights-JS/blob/master/API-reference.md
 */
@Injectable({
	providedIn: 'root',
})
export class AppInsightsService implements IPreload, OnDestroy {
	appInsights: any;
	private _firstLoadFired: boolean = false;
	private _appReady$: Observable<number>;
	private _appReadySubscription: Subscription;
	private _telemetryEventsSubscription: Subscription;
	private _telemetryErrorsSubscription: Subscription;
	private sccInsights = sccHostService.isSCC;
	// Maintain reference of binded function to later clear by reference
	private telemetryInitializers: Array<(item: ITelemetryItem) => boolean | void> = [
		this.addDetailsToTracking.bind(this),
		this.cleanEnvelopeFromPrivateData.bind(this),
	];

	set appReadyListener$(value: Observable<number>) {
		if (!this._firstLoadFired && !this._appReady$) {
			this._appReady$ = value;
			this._appReadySubscription = this._appReady$.subscribe((time: number) =>
				this.trackAppReadyEvent(null, { time: time }),
			);
		}
	}

	constructor(
		private authService: AuthService,
		private appConfigService: AppConfigService,
		private titleService: TitleService,
		private readonly telemetryService: TelemetryService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
	) {

	}

	init(): Observable<any> {
		if(this.sccInsights){
			this.appInsights = true;
			this.initSubscription();
			return of(true);
		}
		if (
			!this.appInsights &&
			this.appConfigService.useAppInsights &&
			this.appConfigService.appInsightsInstrumentationKey
		) {
			appInsightsOn = true;
			const config: IConfiguration & IConfig = {
				instrumentationKey: this.appConfigService.appInsightsInstrumentationKey,
				endpointUrl: this.appConfigService.appInsightsEndpointUrl || undefined,
				accountId: this.appConfigService.tenantId,
				disableTelemetry: false,
				disableExceptionTracking: false,
				disableAjaxTracking: false,
				disableFetchTracking: false,
				autoTrackPageVisitTime: true,
				enableAutoRouteTracking: true,
				distributedTracingMode: DistributedTracingModes.AI_AND_W3C,
				namePrefix: this.appContextService.getCurrentAppPrefix(),
			};
			if (appInsightsRef) {
				this.appInsights = appInsightsRef;
			} else {
				const init = new ApplicationInsights({ config });
				this.appInsights = appInsightsRef = init.loadAppInsights();
				this.appInsights.addTelemetryInitializer(() => {
					// Ignore calls when not in MDATP pages
					return appInsightsOn;
				});
				if (this.authService.currentUser) {
					(this.appInsights as any).setAuthenticatedUserContext(
						this.authService.currentUser.aadUserId,
					);
					this.trackEvent(
						'UI Auth',
						{
							buildVersion: this.appConfigService.buildVersion,
							dataCenter: this.appConfigService.dataCenter,
							isAdmin: this.authService.currentUser.isMdeAdmin,
							isReadonly: this.authService.currentUser.isReadonly,
							tenantId: this.appConfigService.tenantId,
							session: this.authService.sessionId,
							userId: this.authService.currentUser && this.authService.currentUser.aadUserId,
						},
						{ count: 1 },
					);
				}
			}

			this.telemetryInitializers.forEach(initializer =>
				this.appInsights.addTelemetryInitializer(initializer),
			);
			this.initSubscription();

		}

		return of(true);
	}

	private initSubscription(){
		const trackingEventTypeToAiType: Record<TelemetryTrackingEventType, TrackingEventType> = {
			Action: TrackingEventType.Action,
			Button: TrackingEventType.Button,
			Close: TrackingEventType.Close,
			Export: TrackingEventType.Export,
			ExternalLink: TrackingEventType.ExternalLink,
			Filter: TrackingEventType.Filter,
			Navigation: TrackingEventType.Navigation,
			Save: TrackingEventType.Saving,
			Search: TrackingEventType.Search,
			Selection: TrackingEventType.Selection,
			Toggle: TrackingEventType.Toggle,
		};

		this._telemetryEventsSubscription = this.telemetryService.events$.subscribe(event => {
			this.track(
				{
					component: event.component,
					componentType: event.componentType,
					id: event.id,
					type: event.type && trackingEventTypeToAiType[event.type],
					value: event.payload,
				},
				event.measurements,
			);
		});

		const telemetrySeverityToAiSeverity: Record<Severity, SeverityLevel> = {
			Verbose: SeverityLevel.Verbose,
			Information: SeverityLevel.Information,
			Warning: SeverityLevel.Warning,
			Error: SeverityLevel.Error,
			Critical: SeverityLevel.Critical,
		};

		this._telemetryErrorsSubscription = this.telemetryService.errors$.subscribe(error => {
			this.trackException(
				error.error,
				error.handledAt,
				{ package: error.package },
				null,
				error.severity && telemetrySeverityToAiSeverity[error.severity],
			);
		});
	}

	trackEvent(name: string, properties?: any, measurements?: any) {
		if (this.appConfigService.useAppInsights && this.appInsights) {
			if (this.sccInsights) {
				sccHostService.log.trackEvent(name, { ...this.getDefaultTrackingProperties(), ...this.parseProperties(properties) });
			} else {
				this.appInsights.trackEvent({
					name,
					properties: { ...this.getDefaultTrackingProperties(), ...this.parseProperties(properties) },
					measurements,
				});
			}
		}
	}

	track(properties: TrackingEventPropertiesBase, measurements?: any) {
		this.trackEvent('UI', properties, measurements);
	}

	trackPageView(name?: string, uri?: string, properties?: any, measurements?: any, duration?: number) {
		if(this.sccInsights){
			return;
		}
		if (this.appConfigService.useAppInsights && this.appInsights) {
			this.appInsights.trackPageView({
				name: name || this.titleService.state$.getValue().pageTitle,
				uri,
				properties: { ...properties, duration },
				measurements,
			});
		}
	}

	trackException(
		exception: Error,
		handledAt?: string,
		properties?: { [index: string]: any },
		measurements?: { [index: string]: number },
		severityLevel?: SeverityLevel,
	) {
		if (this.appConfigService.useAppInsights && this.appInsights) {
			let exceptionStr: string;
			try {
				exceptionStr = JSON.stringify(exception);
			} catch (err) {
				exceptionStr = ErrorsService.getErrorMessage(exception);
			}
			properties = Object.assign(this.getDefaultTrackingProperties(), properties, {
				error: exceptionStr,
				severityLevel
			});
			if (this.sccInsights) {
				sccHostService.log.trackException(exception, { ...this.parseProperties(properties), handledAt });
			} else {
				this.appInsights.trackException({
					exception,
					properties: { ...this.parseProperties(properties), handledAt },
					measurements,
					severityLevel,
				});
			}
		}
	}

	// this is our benchmark for app load (finished static files load, preload, data load).
	// it will be fired only once, from the first page that loaded fully and registered appReadyListener$
	private trackAppReadyEvent(properties?: any, measurements?: any) {
		if(this.sccInsights){
			return;
		}
		if (this._firstLoadFired) return;
		else {
			this.trackEvent('UI App Ready', properties, measurements);
			this._firstLoadFired = true;
			this._appReadySubscription && this._appReadySubscription.unsubscribe();
		}
	}

	private parseProperties(properties?: any): { [index: string]: string } {
		const parsedProperties = {};

		if (properties) {
			for (const p in properties) {
				if (properties.hasOwnProperty(p)) {
					const value = properties[p];
					parsedProperties[p] = typeof value === 'object' ? JSON.stringify(value) : String(value);
				}
			}
		}

		return parsedProperties;
	}

	private cleanEnvelopeFromPrivateData(envelope: ITelemetryItem): void {
		if (envelope.data) {
			envelope.data = this.removePiiDataFromObject(
				envelope.data as Tags & Tags[],
				ENVELOPE_PRIVATE_DATA_FIELDS,
			);
		}

		if (envelope.baseData) {
			envelope.baseData = this.removePiiDataFromObject(
				envelope.baseData as Tags & Tags[],
				ENVELOPE_PRIVATE_DATA_FIELDS,
			);
			if (envelope.baseData.properties) {
				envelope.baseData.properties = this.removePiiDataFromObject(
					envelope.baseData.properties,
					ENVELOPE_PRIVATE_DATA_FIELDS,
				);
			}
		}

		if (envelope.ext && envelope.ext.trace) {
			envelope.ext.trace = this.removePiiDataFromObject(
				envelope.ext.trace,
				ENVELOPE_EXT_TRACE_PRIVATE_DATA_FIELDS,
			);
		}
	}

	private removePiiDataFromObject(data: Tags & Tags[], fieldNames: Array<string>): Tags & Tags[] {
		Object.keys(data).forEach(prop => {
			if (isObject(data[prop])) {
				return (data[prop] = this.removePiiDataFromObject(data[prop], fieldNames));
			}
			if (isString(data[prop]) && fieldNames.includes(prop)) {
				data[prop] = data[prop]
					.replace(/(.*)(\/user[s]?)(\/.*)?(\/.*)(\/)?/gi, (src, prefix, user, isId, suffix) =>
						this.piiReplace('user', isId, prefix, user, suffix))
					.replace(/(.*)(\/file[s:]?)(\/.*)?(\/.*)(\/)?/gi, (src, prefix, file, isId, suffix) =>
						this.piiReplace('file', isId, prefix, file, suffix))
					.replace(/(.*)(\/[_]?machine[s]?)(\/.*)?(\/.*)(\/)?/gi, (src, prefix, machine, isId, suffix) =>
						this.piiReplace('machine', isId, prefix, machine, suffix))
					.replace(/(.*)(\/ip[s]?)(\/.*)?(\/.*)(\/)?/gi, (src, prefix, user, isId, suffix) =>
						this.piiReplace('ip', isId, prefix, user, suffix))
					.replace(/(.*)(\/mailbox(es)?)(\/.*)?(\/.*)(\/)?/gi, (src, prefix, mailbox, isId, suffix) =>
						this.piiReplace('mailbox', isId, prefix, mailbox, suffix))
					.replace(/(computerDnsName=)([^&]+)/gi, '$1_machine_pii_removed')
					.replace(
						/(userAccount=|AccountName=|AccountDomainName=|AccountDomain=|userAccountDomain=)([^&]+)/gi,
						'$1_user_pii_removed',
					);
			}
		});
		return data;
	}

	// piiReplace examples:
	//  http://stg.localhost:4200/machines/macine-name-to-remove/overview => http://stg.localhost:4200/machines/macine-_pii_removed/overview
	private piiReplace(entityName: string, isId: string, prefix: string, entity: string, suffix: string) {
		suffix = (suffix || '').replace(/(.*)(\?)(.*)/gi, '$1'); // Remove all text after '?' in case the suffix contains PII.
		return isId ?
			`${prefix}${entity}/_${entityName}_pii_removed${suffix}` :
			`${prefix}${entity}/_${entityName}_pii_removed`;
	}

	private addDetailsToTracking(envelope: ITelemetryItem): void {
		const item = envelope.baseData;
		item.properties = Object.assign(this.getDefaultTrackingProperties(), item.properties);

		if (envelope.baseType === 'RemoteDependencyData') {
			// set custom properties:
			item.properties['duration'] = item['duration'];
			item.properties['name'] = item['name'];

			// set custom metrics:
			item.measurements = item.measurements || {};
			item.measurements['success'] = +item['success'];
			item.measurements['duration'] = item['duration'];
		}
	}

	private getDefaultTrackingProperties() {
		let page = this.titleService.state$.getValue().pageTitle;
		if (!page) {
			const pathParts = window.location.pathname.split('/');
			page = (pathParts && pathParts[1]) || '/';
		}

		const data = {
			buildVersion: this.appConfigService.buildVersion,
			dataCenter: this.appConfigService.dataCenter,
			origin: window.location.origin,
			url: window.location.href,
			page: page,
			session: this.authService.sessionId,
			tenantId: this.appConfigService.tenantId,
			userId: this.authService.currentUser && this.authService.currentUser.aadUserId,
			pckVersion: sccHostService.isSCC
				? sccHostService.resource.version('wdatp')
				: window.config.buildVersion,
		};

		if(this.sccInsights){
			//ts-ignore
			this.cleanEnvelopeFromPrivateData({data} as any);
		}
		return data;

	}

	ngOnDestroy(): void {
		this._telemetryEventsSubscription && this._telemetryEventsSubscription.unsubscribe();
		this._telemetryErrorsSubscription && this._telemetryErrorsSubscription.unsubscribe();
		if(this.sccInsights){
			return;
		}
		this._appReadySubscription && this._appReadySubscription.unsubscribe();
		if (!this.appInsights) return;
		(this.appInsights as any).flush(false);
		(this.appInsights as any).appInsights._telemetryInitializers = (this
			.appInsights as any).appInsights._telemetryInitializers.filter(
				initializer => !this.telemetryInitializers.includes(initializer),
			);
		appInsightsOn = false;
	}
}
