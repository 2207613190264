var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { map } from 'rxjs/operators';
import { MachinesFiltersService } from './machines.filters.service';
import { DataviewField } from '@wcd/dataview';
import { compact } from 'lodash-es';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { MachinesFieldsService } from './machines.fields';
import { I18nService } from '@wcd/i18n';
import { TagsService } from '../../../tags/services/tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
export var RELATION_MACHINE_FIELDS = {
    machinename: 1,
    riskScores: 1,
    tags: 1,
};
var MACHINE_FIELDS_ORDER = ["machinename", "id", "riskScores", "tags", "incidentAlerts", "firstEventTime", "lastEventTime", "sensitivity"];
var RelationMachineService = /** @class */ (function () {
    function RelationMachineService(machinesFiltersService, machineFields, i18nService, tagsService, featuresService, flavorService) {
        this.machinesFiltersService = machinesFiltersService;
        this.machineFields = machineFields;
        this.i18nService = i18nService;
        this.tagsService = tagsService;
        this.featuresService = featuresService;
        this.flavorService = flavorService;
    }
    RelationMachineService.prototype.getRelationMachineFilters = function () {
        return this.machinesFiltersService.getMachinesFilters({}).pipe(map(function (m) { return ({
            riskScores: m.riskScores,
            tags: { count: m.tags.count, values: m.tags.values.filter(function (tag) { return !!tag.name; }) },
        }); }));
    };
    RelationMachineService.prototype.getFields = function () {
        var _this = this;
        return this.machineFields.fields
            .filter(function (field) { return RELATION_MACHINE_FIELDS[field.id]; })
            .map(function (field) { return (__assign({}, field, { useDefaultEmptyFieldComponent: true, sort: { enabled: field.id !== 'tags' } })); })
            .map(function (field) { return new DataviewField(field); })
            .concat(compact([
            {
                id: 'id',
                name: this.i18nService.strings.machines_dataView_fields_id,
                getDisplay: function (machine) { return machine.id; },
                getTooltip: function (machine) { return machine.id; },
                sort: { enabled: false },
            },
            {
                id: 'incidentAlerts',
                name: this.i18nService.get('incident.machines.field.incidentAlerts'),
                descriptionKey: 'machines.incidentAlerts',
                getDisplay: function (machine) {
                    return machine.totalAlerts ? machine.activeAlerts + " / " + machine.totalAlerts : null;
                },
                getTooltip: function (machine) { return _this.i18nService.get('machines.incidentAlertsTooltip', machine); },
                sort: { enabled: false },
                useDefaultEmptyFieldComponent: true,
            },
            {
                id: 'firstEventTime',
                name: this.i18nService.get('incident.machines.field.firstEventTime'),
                component: {
                    type: TzDateComponent,
                    getProps: function (machine) { return ({ date: machine.firstEventTime }); },
                },
                useDefaultEmptyFieldComponent: true,
            },
            {
                id: 'lastEventTime',
                name: this.i18nService.get('incident.machines.field.lastEventTime'),
                component: {
                    type: TzDateComponent,
                    getProps: function (machine) { return ({ date: machine.lastEventTime }); },
                },
                useDefaultEmptyFieldComponent: true,
            },
            // TODO: check if this is relevant only for incidents..
            this.featuresService.isEnabled(Feature.IncidentSensitivity) && this.flavorService.isEnabled(AppFlavorConfig.devices.dataSensitivity)
                ? {
                    id: 'sensitivity',
                    name: this.i18nService.get('incident.fields.sensitivity.title'),
                    descriptionKey: 'machines.entityDetails.fields.sensitivity.tooltip',
                    useDefaultEmptyFieldComponent: true,
                    getDisplay: function (machine) {
                        return machine.sensitivity && machine.sensitivity.label;
                    },
                    getCssClass: function (machine) {
                        return machine.sensitivity
                            ? _this.tagsService.getDataSensitivityTag(machine.sensitivity).className
                            : null;
                    },
                    sort: { enabled: false },
                }
                : null,
        ]).map(function (field) { return new DataviewField(field); })).sort(function (fieldA, fieldB) { return MACHINE_FIELDS_ORDER.indexOf(fieldA.id) - MACHINE_FIELDS_ORDER.indexOf(fieldB.id); });
    };
    return RelationMachineService;
}());
export { RelationMachineService };
