
		<dl [class.key-values]="asKeyValueList" role="none">
			<ng-container *ngIf="block.container">
				<dt>{{ 'antivirusBlock.fields.container.title' | i18n }}</dt>
				<dd>{{ block.container }}</dd>
			</ng-container>

			<ng-container *ngIf="block.threatName">
				<dt>{{ 'antivirusBlock.fields.threatName.title' | i18n }}</dt>
				<dd>{{ block.threatName }}</dd>
			</ng-container>

			<ng-container *ngIf="block.remediationAction">
				<dt>{{ 'antivirusBlock.fields.remediationAction.title' | i18n }}</dt>
				<dd>{{ RemediationAction }}</dd>
			</ng-container>

			<ng-container *ngIf="block.wasRemediated !== null">
				<dt>{{ 'antivirusBlock.fields.wasRemediated.title' | i18n }}</dt>
				<dd>
					{{
						'antivirusBlock.fields.wasRemediated.' + (block.wasRemediated ? 'success' : 'failed')
							| i18n
					}}
				</dd>
			</ng-container>

			<ng-container *ngIf="block.wasExecutingWhileDetected !== null">
				<dt>{{ 'antivirusBlock.fields.wasExecutingWhileDetected.title' | i18n }}</dt>
				<dd>{{ block.wasExecutingWhileDetected }}</dd>
			</ng-container>
		</dl>
	