import { NgModule } from '@angular/core';
import { ExternalSourceActionResultsModalComponent } from './components/external-source-action-results.modal';
import { SharedModule } from '../../shared/shared.module';

@NgModule({
	imports: [SharedModule],
	declarations: [ExternalSourceActionResultsModalComponent],
	entryComponents: [ExternalSourceActionResultsModalComponent],
})
export class ServicesModule {}
