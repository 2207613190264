var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { AirsNotificationRuleTypesService } from '../services/airs-notification-rule-types.service';
import { AirsNotificationRuleConditionModel, } from './airs-notification-rule-condition.model';
import { AirsNotificationRuleReportSchedule } from './airs-notification-rule-report-schedule';
import { Identifiable } from '../../../data/models/identifiable.model';
import { merge } from 'lodash-es';
var AirsNotificationRuleModel = /** @class */ (function (_super) {
    __extends(AirsNotificationRuleModel, _super);
    function AirsNotificationRuleModel(notificationRuleConditionGroupsStore, data) {
        var _this = _super.call(this, data, [notificationRuleConditionGroupsStore]) || this;
        _this.notificationRuleConditionGroupsStore = notificationRuleConditionGroupsStore;
        if (!data) {
            _this.type = AirsNotificationRuleTypesService.default;
            _this.emails = [];
            _this.reportSchedule = new AirsNotificationRuleReportSchedule();
        }
        return _this;
    }
    Object.defineProperty(AirsNotificationRuleModel.prototype, "ruleId", {
        get: function () {
            return (this.type.requireSchedule ? 'report' : 'rule') + "_" + this.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleModel.prototype, "type", {
        get: function () {
            return this._type;
        },
        set: function (value) {
            this._type = value;
            this.conditions = [];
            if (value.allowConditions) {
                if (value.allowConditions)
                    this.addCondition();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleModel.prototype, "isValid", {
        get: function () {
            if (!this.name ||
                (!this.emails.length && !this.email) ||
                (this.type.allowConditions && (!this.conditions || !this.conditions.length)))
                return false;
            if (this.type.allowConditions) {
                var conditionsAreValid = this.conditions.every(function (condition) { return condition.isValid; });
                if (!conditionsAreValid)
                    return false;
                if (!this.conditions.some(function (condition) { return !condition.isEmpty; }))
                    return false;
            }
            return !(this.type.requireSchedule && !this.reportSchedule.isValid);
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleModel.prototype.setData = function (data, notificationRuleConditionGroupsStore) {
        _super.prototype.setData.call(this, data);
        this.emails = data.emails;
        this.body = data.body;
        this._type =
            data.notification_type === undefined
                ? AirsNotificationRuleTypesService.reportsNotificationRuleType
                : AirsNotificationRuleTypesService.getNotificationRuleTypeById(data.notification_type);
        if (data.rule)
            this.conditions = data.rule.map(function (conditionData) {
                return new AirsNotificationRuleConditionModel(notificationRuleConditionGroupsStore, conditionData);
            });
        this.reportSchedule = new AirsNotificationRuleReportSchedule(this.type.requireSchedule ? data : null);
    };
    AirsNotificationRuleModel.prototype.getBackendData = function () {
        var conditions = {};
        if (this.type.allowConditions) {
            this.conditions
                .filter(function (condition) {
                return !condition.isEmpty;
            })
                .forEach(function (condition) {
                conditions[condition.group.id] = condition.values;
            });
        }
        var backendData = {
            name: this.name,
            emails: this.emails,
        };
        if (this.email && !~backendData.emails.indexOf(this.email))
            backendData.emails.splice(0, 0, this.email);
        if (this.type.requireSchedule)
            merge(backendData, this.reportSchedule.getBackendData());
        else {
            merge(backendData, {
                notification_type: this.type.id,
                rule: conditions,
            });
        }
        return backendData;
    };
    AirsNotificationRuleModel.prototype.addCondition = function () {
        this.conditions.push(new AirsNotificationRuleConditionModel(this.notificationRuleConditionGroupsStore));
    };
    AirsNotificationRuleModel.prototype.removeCondition = function (condition) {
        var conditionIndex = this.conditions.indexOf(condition);
        if (~conditionIndex)
            this.conditions.splice(conditionIndex, 1);
    };
    AirsNotificationRuleModel.prototype.containsGroup = function (group) {
        if (!group || !this.type.allowConditions)
            return false;
        return this.conditions.some(function (condition) { return condition.group && condition.group.id === group.id; });
    };
    AirsNotificationRuleModel.prototype.updateEmail = function () {
        if (this.email) {
            var emailExists = ~this.emails.indexOf(this.email);
            if (!emailExists)
                this.emails.splice(0, 0, this.email);
            this.email = '';
            return !emailExists;
        }
        return false;
    };
    AirsNotificationRuleModel.prototype.removeEmail = function (email) {
        var emailIndex = this.emails.indexOf(email);
        if (~emailIndex)
            this.emails.splice(emailIndex, 1);
    };
    return AirsNotificationRuleModel;
}(Identifiable));
export { AirsNotificationRuleModel };
