/**
 * Automatically generated. DO NOT MANUALLY MODIFY
 */
export enum FabricIconNames {
    PageLink = 'PageLink',
    CommentSolid = 'CommentSolid',
    ChangeEntitlements = 'ChangeEntitlements',
    Installation = 'Installation',
    WebAppBuilderModule = 'WebAppBuilderModule',
    WebAppBuilderFragment = 'WebAppBuilderFragment',
    WebAppBuilderSlot = 'WebAppBuilderSlot',
    BullseyeTargetEdit = 'BullseyeTargetEdit',
    WebAppBuilderFragmentCreate = 'WebAppBuilderFragmentCreate',
    PageData = 'PageData',
    PageHeaderEdit = 'PageHeaderEdit',
    ProductList = 'ProductList',
    UnpublishContent = 'UnpublishContent',
    DependencyAdd = 'DependencyAdd',
    DependencyRemove = 'DependencyRemove',
    EntitlementPolicy = 'EntitlementPolicy',
    EntitlementRedemption = 'EntitlementRedemption',
    SchoolDataSyncLogo = 'SchoolDataSyncLogo',
    PinSolid12 = 'PinSolid12',
    PinSolidOff12 = 'PinSolidOff12',
    AddLink = 'AddLink',
    SharepointAppIcon16 = 'SharepointAppIcon16',
    DataflowsLink = 'DataflowsLink',
    TimePicker = 'TimePicker',
    UserWarning = 'UserWarning',
    ComplianceAudit = 'ComplianceAudit',
    GlobalNavButton = 'GlobalNavButton',
    InternetSharing = 'InternetSharing',
    Brightness = 'Brightness',
    MapPin = 'MapPin',
    Airplane = 'Airplane',
    Tablet = 'Tablet',
    QuickNote = 'QuickNote',
    ChevronDown = 'ChevronDown',
    ChevronUp = 'ChevronUp',
    Edit = 'Edit',
    Add = 'Add',
    Cancel = 'Cancel',
    More = 'More',
    Settings = 'Settings',
    Video = 'Video',
    Mail = 'Mail',
    People = 'People',
    Phone = 'Phone',
    Pin = 'Pin',
    Shop = 'Shop',
    Stop = 'Stop',
    Link = 'Link',
    Filter = 'Filter',
    AllApps = 'AllApps',
    Zoom = 'Zoom',
    ZoomOut = 'ZoomOut',
    Microphone = 'Microphone',
    Search = 'Search',
    Camera = 'Camera',
    Attach = 'Attach',
    Send = 'Send',
    FavoriteList = 'FavoriteList',
    PageSolid = 'PageSolid',
    Forward = 'Forward',
    Back = 'Back',
    Refresh = 'Refresh',
    Share = 'Share',
    Lock = 'Lock',
    BlockedSite = 'BlockedSite',
    ReportHacked = 'ReportHacked',
    EMI = 'EMI',
    MiniLink = 'MiniLink',
    Blocked = 'Blocked',
    FavoriteStar = 'FavoriteStar',
    FavoriteStarFill = 'FavoriteStarFill',
    ReadingMode = 'ReadingMode',
    Favicon = 'Favicon',
    Remove = 'Remove',
    Checkbox = 'Checkbox',
    CheckboxComposite = 'CheckboxComposite',
    CheckboxFill = 'CheckboxFill',
    CheckboxIndeterminate = 'CheckboxIndeterminate',
    CheckboxCompositeReversed = 'CheckboxCompositeReversed',
    CheckMark = 'CheckMark',
    BackToWindow = 'BackToWindow',
    FullScreen = 'FullScreen',
    Print = 'Print',
    Up = 'Up',
    Down = 'Down',
    OEM = 'OEM',
    Delete = 'Delete',
    Save = 'Save',
    ReturnKey = 'ReturnKey',
    Cloud = 'Cloud',
    Flashlight = 'Flashlight',
    CommandPrompt = 'CommandPrompt',
    Sad = 'Sad',
    RealEstate = 'RealEstate',
    SIPMove = 'SIPMove',
    EraseTool = 'EraseTool',
    GripperTool = 'GripperTool',
    Dialpad = 'Dialpad',
    PageLeft = 'PageLeft',
    PageRight = 'PageRight',
    MultiSelect = 'MultiSelect',
    KeyboardClassic = 'KeyboardClassic',
    Play = 'Play',
    Pause = 'Pause',
    ChevronLeft = 'ChevronLeft',
    ChevronRight = 'ChevronRight',
    InkingTool = 'InkingTool',
    Emoji2 = 'Emoji2',
    GripperBarHorizontal = 'GripperBarHorizontal',
    System = 'System',
    Personalize = 'Personalize',
    SearchAndApps = 'SearchAndApps',
    Globe = 'Globe',
    EaseOfAccess = 'EaseOfAccess',
    ContactInfo = 'ContactInfo',
    Unpin = 'Unpin',
    Contact = 'Contact',
    Memo = 'Memo',
    IncomingCall = 'IncomingCall',
    Paste = 'Paste',
    WindowsLogo = 'WindowsLogo',
    Error = 'Error',
    GripperBarVertical = 'GripperBarVertical',
    Unlock = 'Unlock',
    Slideshow = 'Slideshow',
    Calendar = 'Calendar',
    Megaphone = 'Megaphone',
    Trim = 'Trim',
    AutoEnhanceOn = 'AutoEnhanceOn',
    AutoEnhanceOff = 'AutoEnhanceOff',
    Color = 'Color',
    SaveAs = 'SaveAs',
    Light = 'Light',
    Filters = 'Filters',
    AspectRatio = 'AspectRatio',
    Contrast = 'Contrast',
    Redo = 'Redo',
    Undo = 'Undo',
    Crop = 'Crop',
    PhotoCollection = 'PhotoCollection',
    Album = 'Album',
    Rotate = 'Rotate',
    PanoIndicator = 'PanoIndicator',
    Translate = 'Translate',
    RedEye = 'RedEye',
    ViewOriginal = 'ViewOriginal',
    ThumbnailView = 'ThumbnailView',
    Package = 'Package',
    Telemarketer = 'Telemarketer',
    Warning = 'Warning',
    Financial = 'Financial',
    Education = 'Education',
    ShoppingCart = 'ShoppingCart',
    Train = 'Train',
    Flag = 'Flag',
    Move = 'Move',
    Page = 'Page',
    TouchPointer = 'TouchPointer',
    Merge = 'Merge',
    TurnRight = 'TurnRight',
    Ferry = 'Ferry',
    Highlight = 'Highlight',
    PowerButton = 'PowerButton',
    Tab = 'Tab',
    Admin = 'Admin',
    TVMonitor = 'TVMonitor',
    Speakers = 'Speakers',
    Game = 'Game',
    HorizontalTabKey = 'HorizontalTabKey',
    UnstackSelected = 'UnstackSelected',
    StackIndicator = 'StackIndicator',
    Nav2DMapView = 'Nav2DMapView',
    StreetsideSplitMinimize = 'StreetsideSplitMinimize',
    Car = 'Car',
    Bus = 'Bus',
    EatDrink = 'EatDrink',
    SeeDo = 'SeeDo',
    LocationCircle = 'LocationCircle',
    Home = 'Home',
    SwitcherStartEnd = 'SwitcherStartEnd',
    ParkingLocation = 'ParkingLocation',
    IncidentTriangle = 'IncidentTriangle',
    Touch = 'Touch',
    MapDirections = 'MapDirections',
    CaretHollow = 'CaretHollow',
    CaretSolid = 'CaretSolid',
    History = 'History',
    Location = 'Location',
    MapLayers = 'MapLayers',
    SearchNearby = 'SearchNearby',
    Work = 'Work',
    Recent = 'Recent',
    Hotel = 'Hotel',
    Bank = 'Bank',
    LocationDot = 'LocationDot',
    Dictionary = 'Dictionary',
    ChromeBack = 'ChromeBack',
    FolderOpen = 'FolderOpen',
    Pinned = 'Pinned',
    PinnedFill = 'PinnedFill',
    RevToggleKey = 'RevToggleKey',
    USB = 'USB',
    View = 'View',
    Previous = 'Previous',
    Next = 'Next',
    Clear = 'Clear',
    Sync = 'Sync',
    Download = 'Download',
    Help = 'Help',
    Upload = 'Upload',
    Emoji = 'Emoji',
    MailForward = 'MailForward',
    ClosePane = 'ClosePane',
    OpenPane = 'OpenPane',
    PreviewLink = 'PreviewLink',
    ZoomIn = 'ZoomIn',
    Bookmarks = 'Bookmarks',
    Document = 'Document',
    ProtectedDocument = 'ProtectedDocument',
    OpenInNewWindow = 'OpenInNewWindow',
    MailFill = 'MailFill',
    ViewAll = 'ViewAll',
    Switch = 'Switch',
    Rename = 'Rename',
    Go = 'Go',
    Remote = 'Remote',
    SelectAll = 'SelectAll',
    Orientation = 'Orientation',
    Import = 'Import',
    Folder = 'Folder',
    Picture = 'Picture',
    ChromeClose = 'ChromeClose',
    ShowResults = 'ShowResults',
    Message = 'Message',
    CalendarDay = 'CalendarDay',
    CalendarWeek = 'CalendarWeek',
    MailReplyAll = 'MailReplyAll',
    Read = 'Read',
    Cut = 'Cut',
    PaymentCard = 'PaymentCard',
    Copy = 'Copy',
    Important = 'Important',
    MailReply = 'MailReply',
    Sort = 'Sort',
    GotoToday = 'GotoToday',
    Font = 'Font',
    FontColor = 'FontColor',
    FolderFill = 'FolderFill',
    Permissions = 'Permissions',
    DisableUpdates = 'DisableUpdates',
    Unfavorite = 'Unfavorite',
    Italic = 'Italic',
    Underline = 'Underline',
    Bold = 'Bold',
    MoveToFolder = 'MoveToFolder',
    Dislike = 'Dislike',
    Like = 'Like',
    AlignRight = 'AlignRight',
    AlignCenter = 'AlignCenter',
    AlignLeft = 'AlignLeft',
    OpenFile = 'OpenFile',
    ClearSelection = 'ClearSelection',
    FontDecrease = 'FontDecrease',
    FontIncrease = 'FontIncrease',
    FontSize = 'FontSize',
    CellPhone = 'CellPhone',
    Tag = 'Tag',
    RepeatOne = 'RepeatOne',
    RepeatAll = 'RepeatAll',
    Calculator = 'Calculator',
    Library = 'Library',
    PostUpdate = 'PostUpdate',
    NewFolder = 'NewFolder',
    CalendarReply = 'CalendarReply',
    UnsyncFolder = 'UnsyncFolder',
    SyncFolder = 'SyncFolder',
    BlockContact = 'BlockContact',
    AddFriend = 'AddFriend',
    Accept = 'Accept',
    BulletedList = 'BulletedList',
    Preview = 'Preview',
    News = 'News',
    Chat = 'Chat',
    Group = 'Group',
    World = 'World',
    Comment = 'Comment',
    DockLeft = 'DockLeft',
    DockRight = 'DockRight',
    Repair = 'Repair',
    Accounts = 'Accounts',
    Street = 'Street',
    RadioBullet = 'RadioBullet',
    Stopwatch = 'Stopwatch',
    Clock = 'Clock',
    WorldClock = 'WorldClock',
    AlarmClock = 'AlarmClock',
    Photo = 'Photo',
    ActionCenter = 'ActionCenter',
    Hospital = 'Hospital',
    Timer = 'Timer',
    FullCircleMask = 'FullCircleMask',
    LocationFill = 'LocationFill',
    ChromeMinimize = 'ChromeMinimize',
    ChromeRestore = 'ChromeRestore',
    Annotation = 'Annotation',
    Fingerprint = 'Fingerprint',
    Handwriting = 'Handwriting',
    ChromeFullScreen = 'ChromeFullScreen',
    Completed = 'Completed',
    Label = 'Label',
    FlickDown = 'FlickDown',
    FlickUp = 'FlickUp',
    FlickLeft = 'FlickLeft',
    FlickRight = 'FlickRight',
    MiniExpand = 'MiniExpand',
    MiniContract = 'MiniContract',
    Streaming = 'Streaming',
    MusicInCollection = 'MusicInCollection',
    OneDriveLogo = 'OneDriveLogo',
    CompassNW = 'CompassNW',
    Code = 'Code',
    LightningBolt = 'LightningBolt',
    Info = 'Info',
    CalculatorMultiply = 'CalculatorMultiply',
    CalculatorAddition = 'CalculatorAddition',
    CalculatorSubtract = 'CalculatorSubtract',
    CalculatorPercentage = 'CalculatorPercentage',
    CalculatorEqualTo = 'CalculatorEqualTo',
    PrintfaxPrinterFile = 'PrintfaxPrinterFile',
    StorageOptical = 'StorageOptical',
    Communications = 'Communications',
    Headset = 'Headset',
    Health = 'Health',
    Webcam2 = 'Webcam2',
    FrontCamera = 'FrontCamera',
    ChevronUpSmall = 'ChevronUpSmall',
    ChevronDownSmall = 'ChevronDownSmall',
    ChevronLeftSmall = 'ChevronLeftSmall',
    ChevronRightSmall = 'ChevronRightSmall',
    ChevronUpMed = 'ChevronUpMed',
    ChevronDownMed = 'ChevronDownMed',
    ChevronLeftMed = 'ChevronLeftMed',
    ChevronRightMed = 'ChevronRightMed',
    Devices2 = 'Devices2',
    PC1 = 'PC1',
    PresenceChickletVideo = 'PresenceChickletVideo',
    Reply = 'Reply',
    HalfAlpha = 'HalfAlpha',
    ConstructionCone = 'ConstructionCone',
    DoubleChevronLeftMed = 'DoubleChevronLeftMed',
    Volume0 = 'Volume0',
    Volume1 = 'Volume1',
    Volume2 = 'Volume2',
    Volume3 = 'Volume3',
    Chart = 'Chart',
    Robot = 'Robot',
    Manufacturing = 'Manufacturing',
    LockSolid = 'LockSolid',
    FitPage = 'FitPage',
    FitWidth = 'FitWidth',
    BidiLtr = 'BidiLtr',
    BidiRtl = 'BidiRtl',
    RightDoubleQuote = 'RightDoubleQuote',
    Sunny = 'Sunny',
    CloudWeather = 'CloudWeather',
    Cloudy = 'Cloudy',
    PartlyCloudyDay = 'PartlyCloudyDay',
    PartlyCloudyNight = 'PartlyCloudyNight',
    ClearNight = 'ClearNight',
    RainShowersDay = 'RainShowersDay',
    Rain = 'Rain',
    Thunderstorms = 'Thunderstorms',
    RainSnow = 'RainSnow',
    Snow = 'Snow',
    BlowingSnow = 'BlowingSnow',
    Frigid = 'Frigid',
    Fog = 'Fog',
    Squalls = 'Squalls',
    Duststorm = 'Duststorm',
    Unknown = 'Unknown',
    Precipitation = 'Precipitation',
    SortLines = 'SortLines',
    Ribbon = 'Ribbon',
    AreaChart = 'AreaChart',
    Assign = 'Assign',
    FlowChart = 'FlowChart',
    CheckList = 'CheckList',
    Diagnostic = 'Diagnostic',
    Generate = 'Generate',
    LineChart = 'LineChart',
    Equalizer = 'Equalizer',
    BarChartHorizontal = 'BarChartHorizontal',
    BarChartVertical = 'BarChartVertical',
    Freezing = 'Freezing',
    FunnelChart = 'FunnelChart',
    Processing = 'Processing',
    Quantity = 'Quantity',
    ReportDocument = 'ReportDocument',
    StackColumnChart = 'StackColumnChart',
    SnowShowerDay = 'SnowShowerDay',
    HailDay = 'HailDay',
    WorkFlow = 'WorkFlow',
    HourGlass = 'HourGlass',
    StoreLogoMed20 = 'StoreLogoMed20',
    TimeSheet = 'TimeSheet',
    TriangleSolid = 'TriangleSolid',
    UpgradeAnalysis = 'UpgradeAnalysis',
    VideoSolid = 'VideoSolid',
    RainShowersNight = 'RainShowersNight',
    SnowShowerNight = 'SnowShowerNight',
    Teamwork = 'Teamwork',
    HailNight = 'HailNight',
    PeopleAdd = 'PeopleAdd',
    Glasses = 'Glasses',
    DateTime2 = 'DateTime2',
    Shield = 'Shield',
    Header1 = 'Header1',
    PageAdd = 'PageAdd',
    NumberedList = 'NumberedList',
    PowerBILogo = 'PowerBILogo',
    Info2 = 'Info2',
    MusicInCollectionFill = 'MusicInCollectionFill',
    List = 'List',
    Asterisk = 'Asterisk',
    ErrorBadge = 'ErrorBadge',
    CircleRing = 'CircleRing',
    CircleFill = 'CircleFill',
    Record2 = 'Record2',
    AllAppsMirrored = 'AllAppsMirrored',
    BookmarksMirrored = 'BookmarksMirrored',
    BulletedListMirrored = 'BulletedListMirrored',
    CaretHollowMirrored = 'CaretHollowMirrored',
    CaretSolidMirrored = 'CaretSolidMirrored',
    ChromeBackMirrored = 'ChromeBackMirrored',
    ClearSelectionMirrored = 'ClearSelectionMirrored',
    ClosePaneMirrored = 'ClosePaneMirrored',
    DockLeftMirrored = 'DockLeftMirrored',
    DoubleChevronLeftMedMirrored = 'DoubleChevronLeftMedMirrored',
    GoMirrored = 'GoMirrored',
    HelpMirrored = 'HelpMirrored',
    ImportMirrored = 'ImportMirrored',
    ImportAllMirrored = 'ImportAllMirrored',
    ListMirrored = 'ListMirrored',
    MailForwardMirrored = 'MailForwardMirrored',
    MailReplyMirrored = 'MailReplyMirrored',
    MailReplyAllMirrored = 'MailReplyAllMirrored',
    MiniContractMirrored = 'MiniContractMirrored',
    MiniExpandMirrored = 'MiniExpandMirrored',
    OpenPaneMirrored = 'OpenPaneMirrored',
    ParkingLocationMirrored = 'ParkingLocationMirrored',
    SendMirrored = 'SendMirrored',
    ShowResultsMirrored = 'ShowResultsMirrored',
    ThumbnailViewMirrored = 'ThumbnailViewMirrored',
    Media = 'Media',
    Devices3 = 'Devices3',
    Focus = 'Focus',
    VideoLightOff = 'VideoLightOff',
    Lightbulb = 'Lightbulb',
    StatusTriangle = 'StatusTriangle',
    VolumeDisabled = 'VolumeDisabled',
    Puzzle = 'Puzzle',
    EmojiNeutral = 'EmojiNeutral',
    EmojiDisappointed = 'EmojiDisappointed',
    HomeSolid = 'HomeSolid',
    Ringer = 'Ringer',
    PDF = 'PDF',
    HeartBroken = 'HeartBroken',
    StoreLogo16 = 'StoreLogo16',
    MultiSelectMirrored = 'MultiSelectMirrored',
    Broom = 'Broom',
    AddToShoppingList = 'AddToShoppingList',
    Cocktails = 'Cocktails',
    Wines = 'Wines',
    Articles = 'Articles',
    Cycling = 'Cycling',
    DietPlanNotebook = 'DietPlanNotebook',
    Pill = 'Pill',
    ExerciseTracker = 'ExerciseTracker',
    HandsFree = 'HandsFree',
    Medical = 'Medical',
    Running = 'Running',
    Weights = 'Weights',
    Trackers = 'Trackers',
    AddNotes = 'AddNotes',
    AllCurrency = 'AllCurrency',
    BarChart4 = 'BarChart4',
    CirclePlus = 'CirclePlus',
    Coffee = 'Coffee',
    Cotton = 'Cotton',
    Market = 'Market',
    Money = 'Money',
    PieDouble = 'PieDouble',
    PieSingle = 'PieSingle',
    RemoveFilter = 'RemoveFilter',
    Savings = 'Savings',
    Sell = 'Sell',
    StockDown = 'StockDown',
    StockUp = 'StockUp',
    Lamp = 'Lamp',
    Source = 'Source',
    MSNVideos = 'MSNVideos',
    Cricket = 'Cricket',
    Golf = 'Golf',
    Baseball = 'Baseball',
    Soccer = 'Soccer',
    MoreSports = 'MoreSports',
    AutoRacing = 'AutoRacing',
    CollegeHoops = 'CollegeHoops',
    CollegeFootball = 'CollegeFootball',
    ProFootball = 'ProFootball',
    ProHockey = 'ProHockey',
    Rugby = 'Rugby',
    SubstitutionsIn = 'SubstitutionsIn',
    Tennis = 'Tennis',
    Arrivals = 'Arrivals',
    Design = 'Design',
    Website = 'Website',
    Drop = 'Drop',
    HistoricalWeather = 'HistoricalWeather',
    SkiResorts = 'SkiResorts',
    Snowflake = 'Snowflake',
    BusSolid = 'BusSolid',
    FerrySolid = 'FerrySolid',
    AirplaneSolid = 'AirplaneSolid',
    TrainSolid = 'TrainSolid',
    Heart = 'Heart',
    HeartFill = 'HeartFill',
    Ticket = 'Ticket',
    WifiWarning4 = 'WifiWarning4',
    Devices4 = 'Devices4',
    AzureLogo = 'AzureLogo',
    BingLogo = 'BingLogo',
    MSNLogo = 'MSNLogo',
    OutlookLogoInverse = 'OutlookLogoInverse',
    OfficeLogo = 'OfficeLogo',
    SkypeLogo = 'SkypeLogo',
    Door = 'Door',
    EditMirrored = 'EditMirrored',
    GiftCard = 'GiftCard',
    DoubleBookmark = 'DoubleBookmark',
    StatusErrorFull = 'StatusErrorFull',
    Certificate = 'Certificate',
    FastForward = 'FastForward',
    Rewind = 'Rewind',
    Photo2 = 'Photo2',
    OpenSource = 'OpenSource',
    Movers = 'Movers',
    CloudDownload = 'CloudDownload',
    Family = 'Family',
    WindDirection = 'WindDirection',
    Bug = 'Bug',
    SiteScan = 'SiteScan',
    BrowserScreenShot = 'BrowserScreenShot',
    F12DevTools = 'F12DevTools',
    CSS = 'CSS',
    JS = 'JS',
    DeliveryTruck = 'DeliveryTruck',
    ReminderPerson = 'ReminderPerson',
    ReminderGroup = 'ReminderGroup',
    ReminderTime = 'ReminderTime',
    TabletMode = 'TabletMode',
    Umbrella = 'Umbrella',
    NetworkTower = 'NetworkTower',
    CityNext = 'CityNext',
    CityNext2 = 'CityNext2',
    Section = 'Section',
    OneNoteLogoInverse = 'OneNoteLogoInverse',
    ToggleFilled = 'ToggleFilled',
    ToggleBorder = 'ToggleBorder',
    SliderThumb = 'SliderThumb',
    ToggleThumb = 'ToggleThumb',
    Documentation = 'Documentation',
    Badge = 'Badge',
    Giftbox = 'Giftbox',
    VisualStudioLogo = 'VisualStudioLogo',
    HomeGroup = 'HomeGroup',
    ExcelLogoInverse = 'ExcelLogoInverse',
    WordLogoInverse = 'WordLogoInverse',
    PowerPointLogoInverse = 'PowerPointLogoInverse',
    Cafe = 'Cafe',
    SpeedHigh = 'SpeedHigh',
    Commitments = 'Commitments',
    ThisPC = 'ThisPC',
    MusicNote = 'MusicNote',
    MicOff = 'MicOff',
    PlaybackRate1x = 'PlaybackRate1x',
    EdgeLogo = 'EdgeLogo',
    CompletedSolid = 'CompletedSolid',
    AlbumRemove = 'AlbumRemove',
    MessageFill = 'MessageFill',
    TabletSelected = 'TabletSelected',
    MobileSelected = 'MobileSelected',
    LaptopSelected = 'LaptopSelected',
    TVMonitorSelected = 'TVMonitorSelected',
    DeveloperTools = 'DeveloperTools',
    Shapes = 'Shapes',
    InsertTextBox = 'InsertTextBox',
    LowerBrightness = 'LowerBrightness',
    WebComponents = 'WebComponents',
    OfflineStorage = 'OfflineStorage',
    DOM = 'DOM',
    CloudUpload = 'CloudUpload',
    ScrollUpDown = 'ScrollUpDown',
    DateTime = 'DateTime',
    Event = 'Event',
    Cake = 'Cake',
    Tiles = 'Tiles',
    Org = 'Org',
    PartyLeader = 'PartyLeader',
    DRM = 'DRM',
    CloudAdd = 'CloudAdd',
    AppIconDefault = 'AppIconDefault',
    Photo2Add = 'Photo2Add',
    Photo2Remove = 'Photo2Remove',
    Calories = 'Calories',
    POI = 'POI',
    AddTo = 'AddTo',
    RadioBtnOff = 'RadioBtnOff',
    RadioBtnOn = 'RadioBtnOn',
    ExploreContent = 'ExploreContent',
    Embed = 'Embed',
    Product = 'Product',
    ProgressLoopInner = 'ProgressLoopInner',
    ProgressLoopOuter = 'ProgressLoopOuter',
    Blocked2 = 'Blocked2',
    FangBody = 'FangBody',
    Toolbox = 'Toolbox',
    PageHeader = 'PageHeader',
    Glimmer = 'Glimmer',
    ChatInviteFriend = 'ChatInviteFriend',
    Brush = 'Brush',
    Shirt = 'Shirt',
    Crown = 'Crown',
    Diamond = 'Diamond',
    ScaleUp = 'ScaleUp',
    QRCode = 'QRCode',
    Feedback = 'Feedback',
    SharepointLogoInverse = 'SharepointLogoInverse',
    YammerLogo = 'YammerLogo',
    Hide = 'Hide',
    Uneditable = 'Uneditable',
    ReturnToSession = 'ReturnToSession',
    OpenFolderHorizontal = 'OpenFolderHorizontal',
    CalendarMirrored = 'CalendarMirrored',
    SwayLogoInverse = 'SwayLogoInverse',
    OutOfOffice = 'OutOfOffice',
    Trophy = 'Trophy',
    ReopenPages = 'ReopenPages',
    EmojiTabSymbols = 'EmojiTabSymbols',
    AADLogo = 'AADLogo',
    AccessLogo = 'AccessLogo',
    AdminALogoInverse32 = 'AdminALogoInverse32',
    AdminCLogoInverse32 = 'AdminCLogoInverse32',
    AdminDLogoInverse32 = 'AdminDLogoInverse32',
    AdminELogoInverse32 = 'AdminELogoInverse32',
    AdminLLogoInverse32 = 'AdminLLogoInverse32',
    AdminMLogoInverse32 = 'AdminMLogoInverse32',
    AdminOLogoInverse32 = 'AdminOLogoInverse32',
    AdminPLogoInverse32 = 'AdminPLogoInverse32',
    AdminSLogoInverse32 = 'AdminSLogoInverse32',
    AdminYLogoInverse32 = 'AdminYLogoInverse32',
    DelveLogoInverse = 'DelveLogoInverse',
    ExchangeLogoInverse = 'ExchangeLogoInverse',
    LyncLogo = 'LyncLogo',
    OfficeVideoLogoInverse = 'OfficeVideoLogoInverse',
    SocialListeningLogo = 'SocialListeningLogo',
    VisioLogoInverse = 'VisioLogoInverse',
    Balloons = 'Balloons',
    Cat = 'Cat',
    MailAlert = 'MailAlert',
    MailCheck = 'MailCheck',
    MailLowImportance = 'MailLowImportance',
    MailPause = 'MailPause',
    MailRepeat = 'MailRepeat',
    SecurityGroup = 'SecurityGroup',
    Table = 'Table',
    VoicemailForward = 'VoicemailForward',
    VoicemailReply = 'VoicemailReply',
    Waffle = 'Waffle',
    RemoveEvent = 'RemoveEvent',
    EventInfo = 'EventInfo',
    ForwardEvent = 'ForwardEvent',
    WipePhone = 'WipePhone',
    AddOnlineMeeting = 'AddOnlineMeeting',
    JoinOnlineMeeting = 'JoinOnlineMeeting',
    RemoveLink = 'RemoveLink',
    PeopleBlock = 'PeopleBlock',
    PeopleRepeat = 'PeopleRepeat',
    PeopleAlert = 'PeopleAlert',
    PeoplePause = 'PeoplePause',
    TransferCall = 'TransferCall',
    AddPhone = 'AddPhone',
    UnknownCall = 'UnknownCall',
    NoteReply = 'NoteReply',
    NoteForward = 'NoteForward',
    NotePinned = 'NotePinned',
    RemoveOccurrence = 'RemoveOccurrence',
    Timeline = 'Timeline',
    EditNote = 'EditNote',
    CircleHalfFull = 'CircleHalfFull',
    Room = 'Room',
    Unsubscribe = 'Unsubscribe',
    Subscribe = 'Subscribe',
    HardDrive = 'HardDrive',
    RecurringTask = 'RecurringTask',
    TaskManager = 'TaskManager',
    TaskManagerMirrored = 'TaskManagerMirrored',
    Combine = 'Combine',
    Split = 'Split',
    DoubleChevronUp = 'DoubleChevronUp',
    DoubleChevronLeft = 'DoubleChevronLeft',
    DoubleChevronRight = 'DoubleChevronRight',
    Ascending = 'Ascending',
    Descending = 'Descending',
    TextBox = 'TextBox',
    TextField = 'TextField',
    NumberField = 'NumberField',
    Dropdown = 'Dropdown',
    PenWorkspace = 'PenWorkspace',
    BookingsLogo = 'BookingsLogo',
    ClassNotebookLogoInverse = 'ClassNotebookLogoInverse',
    DelveAnalyticsLogo = 'DelveAnalyticsLogo',
    DocsLogoInverse = 'DocsLogoInverse',
    Dynamics365Logo = 'Dynamics365Logo',
    DynamicSMBLogo = 'DynamicSMBLogo',
    OfficeAssistantLogo = 'OfficeAssistantLogo',
    OfficeStoreLogo = 'OfficeStoreLogo',
    OneNoteEduLogoInverse = 'OneNoteEduLogoInverse',
    PlannerLogo = 'PlannerLogo',
    PowerApps = 'PowerApps',
    Suitcase = 'Suitcase',
    ProjectLogoInverse = 'ProjectLogoInverse',
    CaretLeft8 = 'CaretLeft8',
    CaretRight8 = 'CaretRight8',
    CaretUp8 = 'CaretUp8',
    CaretDown8 = 'CaretDown8',
    CaretLeftSolid8 = 'CaretLeftSolid8',
    CaretRightSolid8 = 'CaretRightSolid8',
    CaretUpSolid8 = 'CaretUpSolid8',
    CaretDownSolid8 = 'CaretDownSolid8',
    ClearFormatting = 'ClearFormatting',
    Superscript = 'Superscript',
    Subscript = 'Subscript',
    Strikethrough = 'Strikethrough',
    Export = 'Export',
    ExportMirrored = 'ExportMirrored',
    SingleBookmark = 'SingleBookmark',
    SingleBookmarkSolid = 'SingleBookmarkSolid',
    DoubleChevronDown = 'DoubleChevronDown',
    FollowUser = 'FollowUser',
    ReplyAll = 'ReplyAll',
    WorkforceManagement = 'WorkforceManagement',
    RecruitmentManagement = 'RecruitmentManagement',
    Questionnaire = 'Questionnaire',
    ManagerSelfService = 'ManagerSelfService',
    ProductionFloorManagement = 'ProductionFloorManagement',
    ProductRelease = 'ProductRelease',
    ProductVariant = 'ProductVariant',
    ReplyMirrored = 'ReplyMirrored',
    ReplyAllMirrored = 'ReplyAllMirrored',
    Medal = 'Medal',
    AddGroup = 'AddGroup',
    QuestionnaireMirrored = 'QuestionnaireMirrored',
    CloudImportExport = 'CloudImportExport',
    TemporaryUser = 'TemporaryUser',
    CaretSolid16 = 'CaretSolid16',
    GroupedDescending = 'GroupedDescending',
    GroupedAscending = 'GroupedAscending',
    SortUp = 'SortUp',
    SortDown = 'SortDown',
    AwayStatus = 'AwayStatus',
    MyMoviesTV = 'MyMoviesTV',
    SyncToPC = 'SyncToPC',
    GenericScan = 'GenericScan',
    AustralianRules = 'AustralianRules',
    WifiEthernet = 'WifiEthernet',
    TrackersMirrored = 'TrackersMirrored',
    DateTimeMirrored = 'DateTimeMirrored',
    StopSolid = 'StopSolid',
    DoubleChevronUp12 = 'DoubleChevronUp12',
    DoubleChevronDown12 = 'DoubleChevronDown12',
    DoubleChevronLeft12 = 'DoubleChevronLeft12',
    DoubleChevronRight12 = 'DoubleChevronRight12',
    CalendarAgenda = 'CalendarAgenda',
    ConnectVirtualMachine = 'ConnectVirtualMachine',
    AddEvent = 'AddEvent',
    AssetLibrary = 'AssetLibrary',
    DataConnectionLibrary = 'DataConnectionLibrary',
    DocLibrary = 'DocLibrary',
    FormLibrary = 'FormLibrary',
    FormLibraryMirrored = 'FormLibraryMirrored',
    ReportLibrary = 'ReportLibrary',
    ReportLibraryMirrored = 'ReportLibraryMirrored',
    ContactCard = 'ContactCard',
    CustomList = 'CustomList',
    CustomListMirrored = 'CustomListMirrored',
    IssueTracking = 'IssueTracking',
    IssueTrackingMirrored = 'IssueTrackingMirrored',
    PictureLibrary = 'PictureLibrary',
    OfficeAddinsLogo = 'OfficeAddinsLogo',
    OfflineOneDriveParachute = 'OfflineOneDriveParachute',
    OfflineOneDriveParachuteDisabled = 'OfflineOneDriveParachuteDisabled',
    LargeGrid = 'LargeGrid',
    TriangleSolidUp12 = 'TriangleSolidUp12',
    TriangleSolidDown12 = 'TriangleSolidDown12',
    TriangleSolidLeft12 = 'TriangleSolidLeft12',
    TriangleSolidRight12 = 'TriangleSolidRight12',
    TriangleUp12 = 'TriangleUp12',
    TriangleDown12 = 'TriangleDown12',
    TriangleLeft12 = 'TriangleLeft12',
    TriangleRight12 = 'TriangleRight12',
    ArrowUpRight8 = 'ArrowUpRight8',
    ArrowDownRight8 = 'ArrowDownRight8',
    DocumentSet = 'DocumentSet',
    GoToDashboard = 'GoToDashboard',
    DelveAnalytics = 'DelveAnalytics',
    ArrowUpRightMirrored8 = 'ArrowUpRightMirrored8',
    ArrowDownRightMirrored8 = 'ArrowDownRightMirrored8',
    CompanyDirectory = 'CompanyDirectory',
    OpenEnrollment = 'OpenEnrollment',
    CompanyDirectoryMirrored = 'CompanyDirectoryMirrored',
    OneDriveAdd = 'OneDriveAdd',
    ProfileSearch = 'ProfileSearch',
    Header2 = 'Header2',
    Header3 = 'Header3',
    Header4 = 'Header4',
    RingerSolid = 'RingerSolid',
    Eyedropper = 'Eyedropper',
    MarketDown = 'MarketDown',
    CalendarWorkWeek = 'CalendarWorkWeek',
    SidePanel = 'SidePanel',
    GlobeFavorite = 'GlobeFavorite',
    CaretTopLeftSolid8 = 'CaretTopLeftSolid8',
    CaretTopRightSolid8 = 'CaretTopRightSolid8',
    ViewAll2 = 'ViewAll2',
    DocumentReply = 'DocumentReply',
    PlayerSettings = 'PlayerSettings',
    ReceiptForward = 'ReceiptForward',
    ReceiptReply = 'ReceiptReply',
    ReceiptCheck = 'ReceiptCheck',
    Fax = 'Fax',
    RecurringEvent = 'RecurringEvent',
    ReplyAlt = 'ReplyAlt',
    ReplyAllAlt = 'ReplyAllAlt',
    EditStyle = 'EditStyle',
    EditMail = 'EditMail',
    Lifesaver = 'Lifesaver',
    LifesaverLock = 'LifesaverLock',
    InboxCheck = 'InboxCheck',
    FolderSearch = 'FolderSearch',
    CollapseMenu = 'CollapseMenu',
    ExpandMenu = 'ExpandMenu',
    Boards = 'Boards',
    SunAdd = 'SunAdd',
    SunQuestionMark = 'SunQuestionMark',
    LandscapeOrientation = 'LandscapeOrientation',
    DocumentSearch = 'DocumentSearch',
    PublicCalendar = 'PublicCalendar',
    PublicContactCard = 'PublicContactCard',
    PublicEmail = 'PublicEmail',
    PublicFolder = 'PublicFolder',
    WordDocument = 'WordDocument',
    PowerPointDocument = 'PowerPointDocument',
    ExcelDocument = 'ExcelDocument',
    GroupedList = 'GroupedList',
    ClassroomLogo = 'ClassroomLogo',
    Sections = 'Sections',
    EditPhoto = 'EditPhoto',
    Starburst = 'Starburst',
    ShareiOS = 'ShareiOS',
    AirTickets = 'AirTickets',
    PencilReply = 'PencilReply',
    Tiles2 = 'Tiles2',
    SkypeCircleCheck = 'SkypeCircleCheck',
    SkypeCircleClock = 'SkypeCircleClock',
    SkypeCircleMinus = 'SkypeCircleMinus',
    SkypeCheck = 'SkypeCheck',
    SkypeClock = 'SkypeClock',
    SkypeMinus = 'SkypeMinus',
    SkypeMessage = 'SkypeMessage',
    ClosedCaption = 'ClosedCaption',
    ATPLogo = 'ATPLogo',
    OfficeFormsLogoInverse = 'OfficeFormsLogoInverse',
    RecycleBin = 'RecycleBin',
    EmptyRecycleBin = 'EmptyRecycleBin',
    Hide2 = 'Hide2',
    Breadcrumb = 'Breadcrumb',
    BirthdayCake = 'BirthdayCake',
    ClearFilter = 'ClearFilter',
    Flow = 'Flow',
    TimeEntry = 'TimeEntry',
    CRMProcesses = 'CRMProcesses',
    PageEdit = 'PageEdit',
    PageArrowRight = 'PageArrowRight',
    PageRemove = 'PageRemove',
    Database = 'Database',
    DataManagementSettings = 'DataManagementSettings',
    CRMServices = 'CRMServices',
    EditContact = 'EditContact',
    ConnectContacts = 'ConnectContacts',
    AppIconDefaultAdd = 'AppIconDefaultAdd',
    AppIconDefaultList = 'AppIconDefaultList',
    ActivateOrders = 'ActivateOrders',
    DeactivateOrders = 'DeactivateOrders',
    ProductCatalog = 'ProductCatalog',
    ScatterChart = 'ScatterChart',
    AccountActivity = 'AccountActivity',
    DocumentManagement = 'DocumentManagement',
    CRMReport = 'CRMReport',
    KnowledgeArticle = 'KnowledgeArticle',
    Relationship = 'Relationship',
    HomeVerify = 'HomeVerify',
    ZipFolder = 'ZipFolder',
    SurveyQuestions = 'SurveyQuestions',
    TextDocument = 'TextDocument',
    TextDocumentShared = 'TextDocumentShared',
    PageCheckedOut = 'PageCheckedOut',
    PageShared = 'PageShared',
    SaveAndClose = 'SaveAndClose',
    Script = 'Script',
    Archive = 'Archive',
    ActivityFeed = 'ActivityFeed',
    Compare = 'Compare',
    EventDate = 'EventDate',
    ArrowUpRight = 'ArrowUpRight',
    CaretRight = 'CaretRight',
    SetAction = 'SetAction',
    ChatBot = 'ChatBot',
    CaretSolidLeft = 'CaretSolidLeft',
    CaretSolidDown = 'CaretSolidDown',
    CaretSolidRight = 'CaretSolidRight',
    CaretSolidUp = 'CaretSolidUp',
    PowerAppsLogo = 'PowerAppsLogo',
    PowerApps2Logo = 'PowerApps2Logo',
    SearchIssue = 'SearchIssue',
    SearchIssueMirrored = 'SearchIssueMirrored',
    FabricAssetLibrary = 'FabricAssetLibrary',
    FabricDataConnectionLibrary = 'FabricDataConnectionLibrary',
    FabricDocLibrary = 'FabricDocLibrary',
    FabricFormLibrary = 'FabricFormLibrary',
    FabricFormLibraryMirrored = 'FabricFormLibraryMirrored',
    FabricReportLibrary = 'FabricReportLibrary',
    FabricReportLibraryMirrored = 'FabricReportLibraryMirrored',
    FabricPublicFolder = 'FabricPublicFolder',
    FabricFolderSearch = 'FabricFolderSearch',
    FabricMovetoFolder = 'FabricMovetoFolder',
    FabricUnsyncFolder = 'FabricUnsyncFolder',
    FabricSyncFolder = 'FabricSyncFolder',
    FabricOpenFolderHorizontal = 'FabricOpenFolderHorizontal',
    FabricFolder = 'FabricFolder',
    FabricFolderFill = 'FabricFolderFill',
    FabricNewFolder = 'FabricNewFolder',
    FabricPictureLibrary = 'FabricPictureLibrary',
    PhotoVideoMedia = 'PhotoVideoMedia',
    AddFavorite = 'AddFavorite',
    AddFavoriteFill = 'AddFavoriteFill',
    BufferTimeBefore = 'BufferTimeBefore',
    BufferTimeAfter = 'BufferTimeAfter',
    BufferTimeBoth = 'BufferTimeBoth',
    PublishContent = 'PublishContent',
    ClipboardList = 'ClipboardList',
    ClipboardListMirrored = 'ClipboardListMirrored',
    CannedChat = 'CannedChat',
    SkypeForBusinessLogo = 'SkypeForBusinessLogo',
    TabCenter = 'TabCenter',
    PageCheckedin = 'PageCheckedin',
    PageList = 'PageList',
    ReadOutLoud = 'ReadOutLoud',
    CaretBottomLeftSolid8 = 'CaretBottomLeftSolid8',
    CaretBottomRightSolid8 = 'CaretBottomRightSolid8',
    FolderHorizontal = 'FolderHorizontal',
    MicrosoftStaffhubLogo = 'MicrosoftStaffhubLogo',
    GiftboxOpen = 'GiftboxOpen',
    StatusCircleOuter = 'StatusCircleOuter',
    StatusCircleInner = 'StatusCircleInner',
    StatusCircleRing = 'StatusCircleRing',
    StatusTriangleOuter = 'StatusTriangleOuter',
    StatusTriangleInner = 'StatusTriangleInner',
    StatusTriangleExclamation = 'StatusTriangleExclamation',
    StatusCircleExclamation = 'StatusCircleExclamation',
    StatusCircleErrorX = 'StatusCircleErrorX',
    StatusCircleCheckmark = 'StatusCircleCheckmark',
    StatusCircleInfo = 'StatusCircleInfo',
    StatusCircleBlock = 'StatusCircleBlock',
    StatusCircleBlock2 = 'StatusCircleBlock2',
    StatusCircleQuestionMark = 'StatusCircleQuestionMark',
    StatusCircleSync = 'StatusCircleSync',
    Toll = 'Toll',
    ExploreContentSingle = 'ExploreContentSingle',
    CollapseContent = 'CollapseContent',
    CollapseContentSingle = 'CollapseContentSingle',
    InfoSolid = 'InfoSolid',
    GroupList = 'GroupList',
    ProgressRingDots = 'ProgressRingDots',
    CaloriesAdd = 'CaloriesAdd',
    BranchFork = 'BranchFork',
    MuteChat = 'MuteChat',
    AddHome = 'AddHome',
    AddWork = 'AddWork',
    MobileReport = 'MobileReport',
    ScaleVolume = 'ScaleVolume',
    HardDriveGroup = 'HardDriveGroup',
    FastMode = 'FastMode',
    ToggleLeft = 'ToggleLeft',
    ToggleRight = 'ToggleRight',
    TriangleShape = 'TriangleShape',
    RectangleShape = 'RectangleShape',
    CubeShape = 'CubeShape',
    Trophy2 = 'Trophy2',
    BucketColor = 'BucketColor',
    BucketColorFill = 'BucketColorFill',
    Taskboard = 'Taskboard',
    SingleColumn = 'SingleColumn',
    DoubleColumn = 'DoubleColumn',
    TripleColumn = 'TripleColumn',
    ColumnLeftTwoThirds = 'ColumnLeftTwoThirds',
    ColumnRightTwoThirds = 'ColumnRightTwoThirds',
    AccessLogoFill = 'AccessLogoFill',
    AnalyticsLogo = 'AnalyticsLogo',
    AnalyticsQuery = 'AnalyticsQuery',
    NewAnalyticsQuery = 'NewAnalyticsQuery',
    AnalyticsReport = 'AnalyticsReport',
    WordLogo = 'WordLogo',
    WordLogoFill = 'WordLogoFill',
    ExcelLogo = 'ExcelLogo',
    ExcelLogoFill = 'ExcelLogoFill',
    OneNoteLogo = 'OneNoteLogo',
    OneNoteLogoFill = 'OneNoteLogoFill',
    OutlookLogo = 'OutlookLogo',
    OutlookLogoFill = 'OutlookLogoFill',
    PowerPointLogo = 'PowerPointLogo',
    PowerPointLogoFill = 'PowerPointLogoFill',
    PublisherLogo = 'PublisherLogo',
    PublisherLogoFill = 'PublisherLogoFill',
    ScheduleEventAction = 'ScheduleEventAction',
    FlameSolid = 'FlameSolid',
    ServerProcesses = 'ServerProcesses',
    Server = 'Server',
    SaveAll = 'SaveAll',
    LinkedInLogo = 'LinkedInLogo',
    Decimals = 'Decimals',
    SidePanelMirrored = 'SidePanelMirrored',
    ProtectRestrict = 'ProtectRestrict',
    Blog = 'Blog',
    UnknownMirrored = 'UnknownMirrored',
    PublicContactCardMirrored = 'PublicContactCardMirrored',
    GridViewSmall = 'GridViewSmall',
    GridViewMedium = 'GridViewMedium',
    GridViewLarge = 'GridViewLarge',
    Step = 'Step',
    StepInsert = 'StepInsert',
    StepShared = 'StepShared',
    StepSharedAdd = 'StepSharedAdd',
    StepSharedInsert = 'StepSharedInsert',
    ViewDashboard = 'ViewDashboard',
    ViewList = 'ViewList',
    ViewListGroup = 'ViewListGroup',
    ViewListTree = 'ViewListTree',
    TriggerAuto = 'TriggerAuto',
    TriggerUser = 'TriggerUser',
    PivotChart = 'PivotChart',
    StackedBarChart = 'StackedBarChart',
    StackedLineChart = 'StackedLineChart',
    BuildQueue = 'BuildQueue',
    BuildQueueNew = 'BuildQueueNew',
    UserFollowed = 'UserFollowed',
    ContactLink = 'ContactLink',
    Stack = 'Stack',
    Bullseye = 'Bullseye',
    VennDiagram = 'VennDiagram',
    FiveTileGrid = 'FiveTileGrid',
    FocalPoint = 'FocalPoint',
    Insert = 'Insert',
    RingerRemove = 'RingerRemove',
    TeamsLogoInverse = 'TeamsLogoInverse',
    TeamsLogo = 'TeamsLogo',
    TeamsLogoFill = 'TeamsLogoFill',
    SkypeForBusinessLogoFill = 'SkypeForBusinessLogoFill',
    SharepointLogo = 'SharepointLogo',
    SharepointLogoFill = 'SharepointLogoFill',
    DelveLogo = 'DelveLogo',
    DelveLogoFill = 'DelveLogoFill',
    OfficeVideoLogo = 'OfficeVideoLogo',
    OfficeVideoLogoFill = 'OfficeVideoLogoFill',
    ExchangeLogo = 'ExchangeLogo',
    ExchangeLogoFill = 'ExchangeLogoFill',
    Signin = 'Signin',
    DocumentApproval = 'DocumentApproval',
    CloneToDesktop = 'CloneToDesktop',
    InstallToDrive = 'InstallToDrive',
    Blur = 'Blur',
    Build = 'Build',
    ProcessMetaTask = 'ProcessMetaTask',
    BranchFork2 = 'BranchFork2',
    BranchLocked = 'BranchLocked',
    BranchCommit = 'BranchCommit',
    BranchCompare = 'BranchCompare',
    BranchMerge = 'BranchMerge',
    BranchPullRequest = 'BranchPullRequest',
    BranchSearch = 'BranchSearch',
    BranchShelveset = 'BranchShelveset',
    RawSource = 'RawSource',
    MergeDuplicate = 'MergeDuplicate',
    RowsGroup = 'RowsGroup',
    RowsChild = 'RowsChild',
    Deploy = 'Deploy',
    Redeploy = 'Redeploy',
    ServerEnviroment = 'ServerEnviroment',
    VisioDiagram = 'VisioDiagram',
    HighlightMappedShapes = 'HighlightMappedShapes',
    TextCallout = 'TextCallout',
    IconSetsFlag = 'IconSetsFlag',
    VisioLogo = 'VisioLogo',
    VisioLogoFill = 'VisioLogoFill',
    VisioDocument = 'VisioDocument',
    TimelineProgress = 'TimelineProgress',
    TimelineDelivery = 'TimelineDelivery',
    Backlog = 'Backlog',
    TeamFavorite = 'TeamFavorite',
    TaskGroup = 'TaskGroup',
    TaskGroupMirrored = 'TaskGroupMirrored',
    ScopeTemplate = 'ScopeTemplate',
    AssessmentGroupTemplate = 'AssessmentGroupTemplate',
    NewTeamProject = 'NewTeamProject',
    CommentAdd = 'CommentAdd',
    CommentNext = 'CommentNext',
    CommentPrevious = 'CommentPrevious',
    ShopServer = 'ShopServer',
    LocaleLanguage = 'LocaleLanguage',
    QueryList = 'QueryList',
    UserSync = 'UserSync',
    UserPause = 'UserPause',
    StreamingOff = 'StreamingOff',
    MoreVertical = 'MoreVertical',
    ArrowTallUpLeft = 'ArrowTallUpLeft',
    ArrowTallUpRight = 'ArrowTallUpRight',
    ArrowTallDownLeft = 'ArrowTallDownLeft',
    ArrowTallDownRight = 'ArrowTallDownRight',
    FieldEmpty = 'FieldEmpty',
    FieldFilled = 'FieldFilled',
    FieldChanged = 'FieldChanged',
    FieldNotChanged = 'FieldNotChanged',
    RingerOff = 'RingerOff',
    PlayResume = 'PlayResume',
    BulletedList2 = 'BulletedList2',
    BulletedList2Mirrored = 'BulletedList2Mirrored',
    ImageCrosshair = 'ImageCrosshair',
    GitGraph = 'GitGraph',
    Repo = 'Repo',
    RepoSolid = 'RepoSolid',
    FolderQuery = 'FolderQuery',
    FolderList = 'FolderList',
    FolderListMirrored = 'FolderListMirrored',
    LocationOutline = 'LocationOutline',
    POISolid = 'POISolid',
    CalculatorNotEqualTo = 'CalculatorNotEqualTo',
    BoxSubtractSolid = 'BoxSubtractSolid',
    BoxAdditionSolid = 'BoxAdditionSolid',
    BoxMultiplySolid = 'BoxMultiplySolid',
    BoxPlaySolid = 'BoxPlaySolid',
    BoxCheckmarkSolid = 'BoxCheckmarkSolid',
    CirclePauseSolid = 'CirclePauseSolid',
    CirclePause = 'CirclePause',
    MSNVideosSolid = 'MSNVideosSolid',
    CircleStopSolid = 'CircleStopSolid',
    CircleStop = 'CircleStop',
    NavigateBack = 'NavigateBack',
    NavigateBackMirrored = 'NavigateBackMirrored',
    NavigateForward = 'NavigateForward',
    NavigateForwardMirrored = 'NavigateForwardMirrored',
    UnknownSolid = 'UnknownSolid',
    UnknownMirroredSolid = 'UnknownMirroredSolid',
    CircleAddition = 'CircleAddition',
    CircleAdditionSolid = 'CircleAdditionSolid',
    FilePDB = 'FilePDB',
    FileTemplate = 'FileTemplate',
    FileSQL = 'FileSQL',
    FileJAVA = 'FileJAVA',
    FileASPX = 'FileASPX',
    FileCSS = 'FileCSS',
    FileSass = 'FileSass',
    FileLess = 'FileLess',
    FileHTML = 'FileHTML',
    JavaScriptLanguage = 'JavaScriptLanguage',
    CSharpLanguage = 'CSharpLanguage',
    CSharp = 'CSharp',
    VisualBasicLanguage = 'VisualBasicLanguage',
    VB = 'VB',
    CPlusPlusLanguage = 'CPlusPlusLanguage',
    CPlusPlus = 'CPlusPlus',
    FSharpLanguage = 'FSharpLanguage',
    FSharp = 'FSharp',
    TypeScriptLanguage = 'TypeScriptLanguage',
    PythonLanguage = 'PythonLanguage',
    PY = 'PY',
    CoffeeScript = 'CoffeeScript',
    MarkDownLanguage = 'MarkDownLanguage',
    FullWidth = 'FullWidth',
    FullWidthEdit = 'FullWidthEdit',
    Plug = 'Plug',
    PlugSolid = 'PlugSolid',
    PlugConnected = 'PlugConnected',
    PlugDisconnected = 'PlugDisconnected',
    UnlockSolid = 'UnlockSolid',
    Variable = 'Variable',
    Parameter = 'Parameter',
    CommentUrgent = 'CommentUrgent',
    Storyboard = 'Storyboard',
    DiffInline = 'DiffInline',
    DiffSideBySide = 'DiffSideBySide',
    ImageDiff = 'ImageDiff',
    ImagePixel = 'ImagePixel',
    FileBug = 'FileBug',
    FileCode = 'FileCode',
    FileComment = 'FileComment',
    BusinessHoursSign = 'BusinessHoursSign',
    FileImage = 'FileImage',
    FileSymlink = 'FileSymlink',
    AutoFillTemplate = 'AutoFillTemplate',
    WorkItem = 'WorkItem',
    WorkItemBug = 'WorkItemBug',
    LogRemove = 'LogRemove',
    ColumnOptions = 'ColumnOptions',
    Packages = 'Packages',
    BuildIssue = 'BuildIssue',
    AssessmentGroup = 'AssessmentGroup',
    VariableGroup = 'VariableGroup',
    FullHistory = 'FullHistory',
    Wheelchair = 'Wheelchair',
    SingleColumnEdit = 'SingleColumnEdit',
    DoubleColumnEdit = 'DoubleColumnEdit',
    TripleColumnEdit = 'TripleColumnEdit',
    ColumnLeftTwoThirdsEdit = 'ColumnLeftTwoThirdsEdit',
    ColumnRightTwoThirdsEdit = 'ColumnRightTwoThirdsEdit',
    StreamLogo = 'StreamLogo',
    PassiveAuthentication = 'PassiveAuthentication',
    AlertSolid = 'AlertSolid',
    MegaphoneSolid = 'MegaphoneSolid',
    TaskSolid = 'TaskSolid',
    ConfigurationSolid = 'ConfigurationSolid',
    BugSolid = 'BugSolid',
    CrownSolid = 'CrownSolid',
    Trophy2Solid = 'Trophy2Solid',
    QuickNoteSolid = 'QuickNoteSolid',
    ConstructionConeSolid = 'ConstructionConeSolid',
    PageListSolid = 'PageListSolid',
    PageListMirroredSolid = 'PageListMirroredSolid',
    StarburstSolid = 'StarburstSolid',
    ReadingModeSolid = 'ReadingModeSolid',
    SadSolid = 'SadSolid',
    HealthSolid = 'HealthSolid',
    ShieldSolid = 'ShieldSolid',
    GiftBoxSolid = 'GiftBoxSolid',
    ShoppingCartSolid = 'ShoppingCartSolid',
    MailSolid = 'MailSolid',
    ChatSolid = 'ChatSolid',
    RibbonSolid = 'RibbonSolid',
    FinancialSolid = 'FinancialSolid',
    FinancialMirroredSolid = 'FinancialMirroredSolid',
    HeadsetSolid = 'HeadsetSolid',
    PermissionsSolid = 'PermissionsSolid',
    ParkingSolid = 'ParkingSolid',
    ParkingMirroredSolid = 'ParkingMirroredSolid',
    DiamondSolid = 'DiamondSolid',
    AsteriskSolid = 'AsteriskSolid',
    OfflineStorageSolid = 'OfflineStorageSolid',
    BankSolid = 'BankSolid',
    DecisionSolid = 'DecisionSolid',
    Parachute = 'Parachute',
    ParachuteSolid = 'ParachuteSolid',
    FiltersSolid = 'FiltersSolid',
    ColorSolid = 'ColorSolid',
    ReviewSolid = 'ReviewSolid',
    ReviewRequestSolid = 'ReviewRequestSolid',
    ReviewRequestMirroredSolid = 'ReviewRequestMirroredSolid',
    ReviewResponseSolid = 'ReviewResponseSolid',
    FeedbackRequestSolid = 'FeedbackRequestSolid',
    FeedbackRequestMirroredSolid = 'FeedbackRequestMirroredSolid',
    FeedbackResponseSolid = 'FeedbackResponseSolid',
    WorkItemBar = 'WorkItemBar',
    WorkItemBarSolid = 'WorkItemBarSolid',
    Separator = 'Separator',
    NavigateExternalInline = 'NavigateExternalInline',
    PlanView = 'PlanView',
    TimelineMatrixView = 'TimelineMatrixView',
    EngineeringGroup = 'EngineeringGroup',
    ProjectCollection = 'ProjectCollection',
    CaretBottomRightCenter8 = 'CaretBottomRightCenter8',
    CaretBottomLeftCenter8 = 'CaretBottomLeftCenter8',
    CaretTopRightCenter8 = 'CaretTopRightCenter8',
    CaretTopLeftCenter8 = 'CaretTopLeftCenter8',
    DonutChart = 'DonutChart',
    ChevronUnfold10 = 'ChevronUnfold10',
    ChevronFold10 = 'ChevronFold10',
    DoubleChevronDown8 = 'DoubleChevronDown8',
    DoubleChevronUp8 = 'DoubleChevronUp8',
    DoubleChevronLeft8 = 'DoubleChevronLeft8',
    DoubleChevronRight8 = 'DoubleChevronRight8',
    ChevronDownEnd6 = 'ChevronDownEnd6',
    ChevronUpEnd6 = 'ChevronUpEnd6',
    ChevronLeftEnd6 = 'ChevronLeftEnd6',
    ChevronRightEnd6 = 'ChevronRightEnd6',
    ContextMenu = 'ContextMenu',
    AzureAPIManagement = 'AzureAPIManagement',
    AzureServiceEndpoint = 'AzureServiceEndpoint',
    VSTSLogo = 'VSTSLogo',
    VSTSAltLogo1 = 'VSTSAltLogo1',
    VSTSAltLogo2 = 'VSTSAltLogo2',
    FileTypeSolution = 'FileTypeSolution',
    WordLogoInverse16 = 'WordLogoInverse16',
    WordLogo16 = 'WordLogo16',
    WordLogoFill16 = 'WordLogoFill16',
    PowerPointLogoInverse16 = 'PowerPointLogoInverse16',
    PowerPointLogo16 = 'PowerPointLogo16',
    PowerPointLogoFill16 = 'PowerPointLogoFill16',
    ExcelLogoInverse16 = 'ExcelLogoInverse16',
    ExcelLogo16 = 'ExcelLogo16',
    ExcelLogoFill16 = 'ExcelLogoFill16',
    OneNoteLogoInverse16 = 'OneNoteLogoInverse16',
    OneNoteLogo16 = 'OneNoteLogo16',
    OneNoteLogoFill16 = 'OneNoteLogoFill16',
    OutlookLogoInverse16 = 'OutlookLogoInverse16',
    OutlookLogo16 = 'OutlookLogo16',
    OutlookLogoFill16 = 'OutlookLogoFill16',
    PublisherLogoInverse16 = 'PublisherLogoInverse16',
    PublisherLogo16 = 'PublisherLogo16',
    PublisherLogoFill16 = 'PublisherLogoFill16',
    VisioLogoInverse16 = 'VisioLogoInverse16',
    VisioLogo16 = 'VisioLogo16',
    VisioLogoFill16 = 'VisioLogoFill16',
    TestBeaker = 'TestBeaker',
    TestBeakerSolid = 'TestBeakerSolid',
    TestExploreSolid = 'TestExploreSolid',
    TestAutoSolid = 'TestAutoSolid',
    TestUserSolid = 'TestUserSolid',
    TestImpactSolid = 'TestImpactSolid',
    TestPlan = 'TestPlan',
    TestStep = 'TestStep',
    TestParameter = 'TestParameter',
    TestSuite = 'TestSuite',
    TestCase = 'TestCase',
    Sprint = 'Sprint',
    SignOut = 'SignOut',
    TriggerApproval = 'TriggerApproval',
    Rocket = 'Rocket',
    AzureKeyVault = 'AzureKeyVault',
    Onboarding = 'Onboarding',
    Transition = 'Transition',
    LikeSolid = 'LikeSolid',
    DislikeSolid = 'DislikeSolid',
    CRMCustomerInsightsApp = 'CRMCustomerInsightsApp',
    EditCreate = 'EditCreate',
    PlayReverseResume = 'PlayReverseResume',
    PlayReverse = 'PlayReverse',
    SearchData = 'SearchData',
    UnSetColor = 'UnSetColor',
    DeclineCall = 'DeclineCall',
    RectangularClipping = 'RectangularClipping',
    TeamsLogo16 = 'TeamsLogo16',
    TeamsLogoFill16 = 'TeamsLogoFill16',
    Spacer = 'Spacer',
    SkypeLogo16 = 'SkypeLogo16',
    SkypeForBusinessLogo16 = 'SkypeForBusinessLogo16',
    SkypeForBusinessLogoFill16 = 'SkypeForBusinessLogoFill16',
    FilterSolid = 'FilterSolid',
    MailUndelivered = 'MailUndelivered',
    MailTentative = 'MailTentative',
    MailTentativeMirrored = 'MailTentativeMirrored',
    MailReminder = 'MailReminder',
    ReceiptUndelivered = 'ReceiptUndelivered',
    ReceiptTentative = 'ReceiptTentative',
    ReceiptTentativeMirrored = 'ReceiptTentativeMirrored',
    Inbox = 'Inbox',
    IRMReply = 'IRMReply',
    IRMReplyMirrored = 'IRMReplyMirrored',
    IRMForward = 'IRMForward',
    IRMForwardMirrored = 'IRMForwardMirrored',
    VoicemailIRM = 'VoicemailIRM',
    EventAccepted = 'EventAccepted',
    EventTentative = 'EventTentative',
    EventTentativeMirrored = 'EventTentativeMirrored',
    EventDeclined = 'EventDeclined',
    IDBadge = 'IDBadge',
    BackgroundColor = 'BackgroundColor',
    OfficeFormsLogoInverse16 = 'OfficeFormsLogoInverse16',
    OfficeFormsLogo = 'OfficeFormsLogo',
    OfficeFormsLogoFill = 'OfficeFormsLogoFill',
    OfficeFormsLogo16 = 'OfficeFormsLogo16',
    OfficeFormsLogoFill16 = 'OfficeFormsLogoFill16',
    OfficeFormsLogoInverse24 = 'OfficeFormsLogoInverse24',
    OfficeFormsLogo24 = 'OfficeFormsLogo24',
    OfficeFormsLogoFill24 = 'OfficeFormsLogoFill24',
    PageLock = 'PageLock',
    NotExecuted = 'NotExecuted',
    NotImpactedSolid = 'NotImpactedSolid',
    FieldReadOnly = 'FieldReadOnly',
    FieldRequired = 'FieldRequired',
    BacklogBoard = 'BacklogBoard',
    ExternalBuild = 'ExternalBuild',
    ExternalTFVC = 'ExternalTFVC',
    ExternalXAML = 'ExternalXAML',
    IssueSolid = 'IssueSolid',
    DefectSolid = 'DefectSolid',
    LadybugSolid = 'LadybugSolid',
    NugetLogo = 'NugetLogo',
    TFVCLogo = 'TFVCLogo',
    ProjectLogo32 = 'ProjectLogo32',
    ProjectLogoFill32 = 'ProjectLogoFill32',
    ProjectLogo16 = 'ProjectLogo16',
    ProjectLogoFill16 = 'ProjectLogoFill16',
    SwayLogo32 = 'SwayLogo32',
    SwayLogoFill32 = 'SwayLogoFill32',
    SwayLogo16 = 'SwayLogo16',
    SwayLogoFill16 = 'SwayLogoFill16',
    ClassNotebookLogo32 = 'ClassNotebookLogo32',
    ClassNotebookLogoFill32 = 'ClassNotebookLogoFill32',
    ClassNotebookLogo16 = 'ClassNotebookLogo16',
    ClassNotebookLogoFill16 = 'ClassNotebookLogoFill16',
    ClassNotebookLogoInverse32 = 'ClassNotebookLogoInverse32',
    ClassNotebookLogoInverse16 = 'ClassNotebookLogoInverse16',
    StaffNotebookLogo32 = 'StaffNotebookLogo32',
    StaffNotebookLogoFill32 = 'StaffNotebookLogoFill32',
    StaffNotebookLogo16 = 'StaffNotebookLogo16',
    StaffNotebookLogoFill16 = 'StaffNotebookLogoFill16',
    StaffNotebookLogoInverted32 = 'StaffNotebookLogoInverted32',
    StaffNotebookLogoInverted16 = 'StaffNotebookLogoInverted16',
    KaizalaLogo = 'KaizalaLogo',
    TaskLogo = 'TaskLogo',
    ProtectionCenterLogo32 = 'ProtectionCenterLogo32',
    GallatinLogo = 'GallatinLogo',
    Globe2 = 'Globe2',
    Guitar = 'Guitar',
    Breakfast = 'Breakfast',
    Brunch = 'Brunch',
    BeerMug = 'BeerMug',
    Vacation = 'Vacation',
    Teeth = 'Teeth',
    Taxi = 'Taxi',
    Chopsticks = 'Chopsticks',
    SyncOccurence = 'SyncOccurence',
    UnsyncOccurence = 'UnsyncOccurence',
    GIF = 'GIF',
    PrimaryCalendar = 'PrimaryCalendar',
    SearchCalendar = 'SearchCalendar',
    VideoOff = 'VideoOff',
    MicrosoftFlowLogo = 'MicrosoftFlowLogo',
    BusinessCenterLogo = 'BusinessCenterLogo',
    ToDoLogoBottom = 'ToDoLogoBottom',
    ToDoLogoTop = 'ToDoLogoTop',
    EditSolid12 = 'EditSolid12',
    EditSolidMirrored12 = 'EditSolidMirrored12',
    UneditableSolid12 = 'UneditableSolid12',
    UneditableSolidMirrored12 = 'UneditableSolidMirrored12',
    UneditableMirrored = 'UneditableMirrored',
    AdminALogo32 = 'AdminALogo32',
    AdminALogoFill32 = 'AdminALogoFill32',
    ToDoLogoInverse = 'ToDoLogoInverse',
    Snooze = 'Snooze',
    WaffleOffice365 = 'WaffleOffice365',
    ImageSearch = 'ImageSearch',
    NewsSearch = 'NewsSearch',
    VideoSearch = 'VideoSearch',
    R = 'R',
    FontColorA = 'FontColorA',
    FontColorSwatch = 'FontColorSwatch',
    LightWeight = 'LightWeight',
    NormalWeight = 'NormalWeight',
    SemiboldWeight = 'SemiboldWeight',
    GroupObject = 'GroupObject',
    UngroupObject = 'UngroupObject',
    AlignHorizontalLeft = 'AlignHorizontalLeft',
    AlignHorizontalCenter = 'AlignHorizontalCenter',
    AlignHorizontalRight = 'AlignHorizontalRight',
    AlignVerticalTop = 'AlignVerticalTop',
    AlignVerticalCenter = 'AlignVerticalCenter',
    AlignVerticalBottom = 'AlignVerticalBottom',
    HorizontalDistributeCenter = 'HorizontalDistributeCenter',
    VerticalDistributeCenter = 'VerticalDistributeCenter',
    Ellipse = 'Ellipse',
    Line = 'Line',
    Octagon = 'Octagon',
    Hexagon = 'Hexagon',
    Pentagon = 'Pentagon',
    RightTriangle = 'RightTriangle',
    HalfCircle = 'HalfCircle',
    QuarterCircle = 'QuarterCircle',
    ThreeQuarterCircle = 'ThreeQuarterCircle',
    SixPointStar = 'SixPointStar',
    TwelvePointStar = 'TwelvePointStar',
    ArrangeBringToFront = 'ArrangeBringToFront',
    ArrangeSendToBack = 'ArrangeSendToBack',
    ArrangeSendBackward = 'ArrangeSendBackward',
    ArrangeBringForward = 'ArrangeBringForward',
    BorderDash = 'BorderDash',
    BorderDot = 'BorderDot',
    LineStyle = 'LineStyle',
    LineThickness = 'LineThickness',
    WindowEdit = 'WindowEdit',
    HintText = 'HintText',
    MediaAdd = 'MediaAdd',
    AnchorLock = 'AnchorLock',
    AutoHeight = 'AutoHeight',
    ChartSeries = 'ChartSeries',
    ChartXAngle = 'ChartXAngle',
    ChartYAngle = 'ChartYAngle',
    Combobox = 'Combobox',
    LineSpacing = 'LineSpacing',
    Padding = 'Padding',
    PaddingTop = 'PaddingTop',
    PaddingBottom = 'PaddingBottom',
    PaddingLeft = 'PaddingLeft',
    PaddingRight = 'PaddingRight',
    NavigationFlipper = 'NavigationFlipper',
    AlignJustify = 'AlignJustify',
    TextOverflow = 'TextOverflow',
    VisualsFolder = 'VisualsFolder',
    VisualsStore = 'VisualsStore',
    PictureCenter = 'PictureCenter',
    PictureFill = 'PictureFill',
    PicturePosition = 'PicturePosition',
    PictureStretch = 'PictureStretch',
    PictureTile = 'PictureTile',
    Slider = 'Slider',
    SliderHandleSize = 'SliderHandleSize',
    DefaultRatio = 'DefaultRatio',
    NumberSequence = 'NumberSequence',
    GUID = 'GUID',
    ReportAdd = 'ReportAdd',
    DashboardAdd = 'DashboardAdd',
    MapPinSolid = 'MapPinSolid',
    WebPublish = 'WebPublish',
    PieSingleSolid = 'PieSingleSolid',
    BlockedSolid = 'BlockedSolid',
    DrillDown = 'DrillDown',
    DrillDownSolid = 'DrillDownSolid',
    DrillExpand = 'DrillExpand',
    DrillShow = 'DrillShow',
    SpecialEvent = 'SpecialEvent',
    OneDriveFolder16 = 'OneDriveFolder16',
    FunctionalManagerDashboard = 'FunctionalManagerDashboard',
    BIDashboard = 'BIDashboard',
    CodeEdit = 'CodeEdit',
    RenewalCurrent = 'RenewalCurrent',
    RenewalFuture = 'RenewalFuture',
    SplitObject = 'SplitObject',
    BulkUpload = 'BulkUpload',
    DownloadDocument = 'DownloadDocument',
    GreetingCard = 'GreetingCard',
    Flower = 'Flower',
    WaitlistConfirm = 'WaitlistConfirm',
    WaitlistConfirmMirrored = 'WaitlistConfirmMirrored',
    LaptopSecure = 'LaptopSecure',
    DragObject = 'DragObject',
    EntryView = 'EntryView',
    EntryDecline = 'EntryDecline',
    ContactCardSettings = 'ContactCardSettings',
    ContactCardSettingsMirrored = 'ContactCardSettingsMirrored',
    CalendarSettings = 'CalendarSettings',
    CalendarSettingsMirrored = 'CalendarSettingsMirrored',
    HardDriveLock = 'HardDriveLock',
    HardDriveUnlock = 'HardDriveUnlock',
    AccountManagement = 'AccountManagement',
    ReportWarning = 'ReportWarning',
    TransitionPop = 'TransitionPop',
    TransitionPush = 'TransitionPush',
    TransitionEffect = 'TransitionEffect',
    LookupEntities = 'LookupEntities',
    ExploreData = 'ExploreData',
    AddBookmark = 'AddBookmark',
    SearchBookmark = 'SearchBookmark',
    DrillThrough = 'DrillThrough',
    MasterDatabase = 'MasterDatabase',
    CertifiedDatabase = 'CertifiedDatabase',
    MaximumValue = 'MaximumValue',
    MinimumValue = 'MinimumValue',
    VisualStudioIDELogo32 = 'VisualStudioIDELogo32',
    PasteAsText = 'PasteAsText',
    PasteAsCode = 'PasteAsCode',
    BrowserTab = 'BrowserTab',
    BrowserTabScreenshot = 'BrowserTabScreenshot',
    DesktopScreenshot = 'DesktopScreenshot',
    FileYML = 'FileYML',
    ClipboardSolid = 'ClipboardSolid',
    FabricUserFolder = 'FabricUserFolder',
    FabricNetworkFolder = 'FabricNetworkFolder',
    BullseyeTarget = 'BullseyeTarget',
    AnalyticsView = 'AnalyticsView',
    Video360Generic = 'Video360Generic',
    Untag = 'Untag',
    Leave = 'Leave',
    Trending12 = 'Trending12',
    Blocked12 = 'Blocked12',
    Warning12 = 'Warning12',
    CheckedOutByOther12 = 'CheckedOutByOther12',
    CheckedOutByYou12 = 'CheckedOutByYou12',
    CircleShapeSolid = 'CircleShapeSolid',
    SquareShapeSolid = 'SquareShapeSolid',
    TriangleShapeSolid = 'TriangleShapeSolid',
    DropShapeSolid = 'DropShapeSolid',
    RectangleShapeSolid = 'RectangleShapeSolid',
    ZoomToFit = 'ZoomToFit',
    InsertColumnsLeft = 'InsertColumnsLeft',
    InsertColumnsRight = 'InsertColumnsRight',
    InsertRowsAbove = 'InsertRowsAbove',
    InsertRowsBelow = 'InsertRowsBelow',
    DeleteColumns = 'DeleteColumns',
    DeleteRows = 'DeleteRows',
    DeleteRowsMirrored = 'DeleteRowsMirrored',
    DeleteTable = 'DeleteTable',
    AccountBrowser = 'AccountBrowser',
    VersionControlPush = 'VersionControlPush',
    StackedColumnChart2 = 'StackedColumnChart2',
    TripleColumnWide = 'TripleColumnWide',
    QuadColumn = 'QuadColumn',
    WhiteBoardApp16 = 'WhiteBoardApp16',
    WhiteBoardApp32 = 'WhiteBoardApp32',
    PinnedSolid = 'PinnedSolid',
    InsertSignatureLine = 'InsertSignatureLine',
    ArrangeByFrom = 'ArrangeByFrom',
    Phishing = 'Phishing',
    CreateMailRule = 'CreateMailRule',
    PublishCourse = 'PublishCourse',
    DictionaryRemove = 'DictionaryRemove',
    UserRemove = 'UserRemove',
    UserEvent = 'UserEvent',
    Encryption = 'Encryption',
    PasswordField = 'PasswordField',
    OpenInNewTab = 'OpenInNewTab',
    Hide3 = 'Hide3',
    VerifiedBrandSolid = 'VerifiedBrandSolid',
    MarkAsProtected = 'MarkAsProtected',
    AuthenticatorApp = 'AuthenticatorApp',
    WebTemplate = 'WebTemplate',
    DefenderTVM = 'DefenderTVM',
    MedalSolid = 'MedalSolid',
    D365TalentLearn = 'D365TalentLearn',
    D365TalentInsight = 'D365TalentInsight',
    D365TalentHRCore = 'D365TalentHRCore',
    BacklogList = 'BacklogList',
    ButtonControl = 'ButtonControl',
    TableGroup = 'TableGroup',
    MountainClimbing = 'MountainClimbing',
    TagUnknown = 'TagUnknown',
    TagUnknownMirror = 'TagUnknownMirror',
    TagUnknown12 = 'TagUnknown12',
    TagUnknown12Mirror = 'TagUnknown12Mirror',
    Link12 = 'Link12',
    Presentation = 'Presentation',
    Presentation12 = 'Presentation12',
    Lock12 = 'Lock12',
    BuildDefinition = 'BuildDefinition',
    ReleaseDefinition = 'ReleaseDefinition',
    SaveTemplate = 'SaveTemplate',
    UserGauge = 'UserGauge',
    BlockedSiteSolid12 = 'BlockedSiteSolid12',
    TagSolid = 'TagSolid',
    OfficeChat = 'OfficeChat',
    OfficeChatSolid = 'OfficeChatSolid',
    MailSchedule = 'MailSchedule',
    WarningSolid = 'WarningSolid',
    Blocked2Solid = 'Blocked2Solid',
    SkypeCircleArrow = 'SkypeCircleArrow',
    SkypeArrow = 'SkypeArrow',
    SyncStatus = 'SyncStatus',
    SyncStatusSolid = 'SyncStatusSolid',
    ProjectDocument = 'ProjectDocument',
    ToDoLogoOutline = 'ToDoLogoOutline',
    VisioOnlineLogoFill32 = 'VisioOnlineLogoFill32',
    VisioOnlineLogo32 = 'VisioOnlineLogo32',
    VisioOnlineLogoCloud32 = 'VisioOnlineLogoCloud32',
    VisioDiagramSync = 'VisioDiagramSync',
    Event12 = 'Event12',
    EventDateMissed12 = 'EventDateMissed12',
    UserOptional = 'UserOptional',
    ResponsesMenu = 'ResponsesMenu',
    DoubleDownArrow = 'DoubleDownArrow',
    DistributeDown = 'DistributeDown',
    BookmarkReport = 'BookmarkReport',
    FilterSettings = 'FilterSettings',
    GripperDotsVertical = 'GripperDotsVertical',
    MailAttached = 'MailAttached',
    AddIn = 'AddIn',
    LinkedDatabase = 'LinkedDatabase',
    TableLink = 'TableLink',
    PromotedDatabase = 'PromotedDatabase',
    BarChartVerticalFilter = 'BarChartVerticalFilter',
    BarChartVerticalFilterSolid = 'BarChartVerticalFilterSolid',
    MicOff2 = 'MicOff2',
    MicrosoftTranslatorLogo = 'MicrosoftTranslatorLogo',
    ShowTimeAs = 'ShowTimeAs',
    FileRequest = 'FileRequest',
    WorkItemAlert = 'WorkItemAlert',
    PowerBILogo16 = 'PowerBILogo16',
    PowerBILogoBackplate16 = 'PowerBILogoBackplate16',
    BulletedListText = 'BulletedListText',
    BulletedListBullet = 'BulletedListBullet',
    BulletedListTextMirrored = 'BulletedListTextMirrored',
    BulletedListBulletMirrored = 'BulletedListBulletMirrored',
    NumberedListText = 'NumberedListText',
    NumberedListNumber = 'NumberedListNumber',
    NumberedListTextMirrored = 'NumberedListTextMirrored',
    NumberedListNumberMirrored = 'NumberedListNumberMirrored',
    RemoveLinkChain = 'RemoveLinkChain',
    RemoveLinkX = 'RemoveLinkX',
    FabricTextHighlight = 'FabricTextHighlight',
    ClearFormattingA = 'ClearFormattingA',
    ClearFormattingEraser = 'ClearFormattingEraser',
    Photo2Fill = 'Photo2Fill',
    IncreaseIndentText = 'IncreaseIndentText',
    IncreaseIndentArrow = 'IncreaseIndentArrow',
    DecreaseIndentText = 'DecreaseIndentText',
    DecreaseIndentArrow = 'DecreaseIndentArrow',
    IncreaseIndentTextMirrored = 'IncreaseIndentTextMirrored',
    IncreaseIndentArrowMirrored = 'IncreaseIndentArrowMirrored',
    DecreaseIndentTextMirrored = 'DecreaseIndentTextMirrored',
    DecreaseIndentArrowMirrored = 'DecreaseIndentArrowMirrored',
    CheckListText = 'CheckListText',
    CheckListCheck = 'CheckListCheck',
    CheckListTextMirrored = 'CheckListTextMirrored',
    CheckListCheckMirrored = 'CheckListCheckMirrored',
    NumberSymbol = 'NumberSymbol',
    Coupon = 'Coupon',
    VerifiedBrand = 'VerifiedBrand',
    ReleaseGate = 'ReleaseGate',
    ReleaseGateCheck = 'ReleaseGateCheck',
    ReleaseGateError = 'ReleaseGateError',
    M365InvoicingLogo = 'M365InvoicingLogo',
    RemoveFromShoppingList = 'RemoveFromShoppingList',
    ShieldAlert = 'ShieldAlert',
    FabricTextHighlightComposite = 'FabricTextHighlightComposite',
    Dataflows = 'Dataflows',
    GenericScanFilled = 'GenericScanFilled',
    DiagnosticDataBarTooltip = 'DiagnosticDataBarTooltip',
    SaveToMobile = 'SaveToMobile',
    Orientation2 = 'Orientation2',
    ScreenCast = 'ScreenCast',
    ShowGrid = 'ShowGrid',
    SnapToGrid = 'SnapToGrid',
    ContactList = 'ContactList',
    NewMail = 'NewMail',
    EyeShadow = 'EyeShadow',
    FabricFolderConfirm = 'FabricFolderConfirm',
    InformationBarriers = 'InformationBarriers',
    CommentActive = 'CommentActive',
    ColumnVerticalSectionEdit = 'ColumnVerticalSectionEdit',
    WavingHand = 'WavingHand',
    ShakeDevice = 'ShakeDevice',
    SmartGlassRemote = 'SmartGlassRemote',
    Rotate90Clockwise = 'Rotate90Clockwise',
    Rotate90CounterClockwise = 'Rotate90CounterClockwise',
    CampaignTemplate = 'CampaignTemplate',
    ChartTemplate = 'ChartTemplate',
    PageListFilter = 'PageListFilter',
    SecondaryNav = 'SecondaryNav',
    ColumnVerticalSection = 'ColumnVerticalSection',
    SkypeCircleSlash = 'SkypeCircleSlash',
    SkypeSlash = 'SkypeSlash',
    CustomizeToolbar = 'CustomizeToolbar',
    DuplicateRow = 'DuplicateRow',
    RemoveFromTrash = 'RemoveFromTrash',
    MailOptions = 'MailOptions',
    Childof = 'Childof',
    Footer = 'Footer',
    Header = 'Header',
    BarChartVerticalFill = 'BarChartVerticalFill',
    StackedColumnChart2Fill = 'StackedColumnChart2Fill',
    PlainText = 'PlainText',
    AccessibiltyChecker = 'AccessibiltyChecker',
    DatabaseSync = 'DatabaseSync',
    ReservationOrders = 'ReservationOrders',
    TabOneColumn = 'TabOneColumn',
    TabTwoColumn = 'TabTwoColumn',
    TabThreeColumn = 'TabThreeColumn',
    BulletedTreeList = 'BulletedTreeList',
    MicrosoftTranslatorLogoGreen = 'MicrosoftTranslatorLogoGreen',
    MicrosoftTranslatorLogoBlue = 'MicrosoftTranslatorLogoBlue',
    InternalInvestigation = 'InternalInvestigation',
    AddReaction = 'AddReaction',
    ContactHeart = 'ContactHeart',
    VisuallyImpaired = 'VisuallyImpaired',
    EventToDoLogo = 'EventToDoLogo',
    Variable2 = 'Variable2',
    ModelingView = 'ModelingView',
    DisconnectVirtualMachine = 'DisconnectVirtualMachine',
    ReportLock = 'ReportLock',
    Uneditable2 = 'Uneditable2',
    Uneditable2Mirrored = 'Uneditable2Mirrored',
    BarChartVerticalEdit = 'BarChartVerticalEdit',
    GlobalNavButtonActive = 'GlobalNavButtonActive',
    PollResults = 'PollResults',
    Rerun = 'Rerun',
    QandA = 'QandA',
    QandAMirror = 'QandAMirror',
    BookAnswers = 'BookAnswers',
    AlertSettings = 'AlertSettings',
    TrimStart = 'TrimStart',
    TrimEnd = 'TrimEnd',
    TableComputed = 'TableComputed',
    DecreaseIndentLegacy = 'DecreaseIndentLegacy',
    IncreaseIndentLegacy = 'IncreaseIndentLegacy',
    SizeLegacy = 'SizeLegacy'
}
