import { AadUser, Application, Machine, MachineGroup, Mailbox } from '@wcd/domain';
import { DataEntityType } from '@microsoft/paris';

export type EntitiesNames = 'machines' | 'machineGroup' | 'users' | 'mailboxes' | 'apps';

export interface EntityTypeMap {
	type: DataEntityType;
	/** Order of presentation */
	order: number;
	/**
	 * All optional display properties of the entity.
	 * First property in the array that is populated
	 * in the entity object will be used
	 */
	displayProps: Array<string>;
}

export const entityMap: Record<EntitiesNames, EntityTypeMap> = {
	machines: {
		type: Machine,
		displayProps: ['name', 'senseMachineId', 'ipAddress'],
		order: 0,
	},
	machineGroup: {
		type: MachineGroup,
		displayProps: ['name', 'id'],
		order: 1,
	},
	users: {
		type: AadUser,
		displayProps: ['displayName', 'sid', 'aadUserId'],
		order: 2,
	},
	mailboxes: {
		type: Mailbox,
		displayProps: ['displayName', 'id'],
		order: 3,
	},
	apps: {
		type: Application,
		displayProps: ['name', 'id'],
		order: 4,
	},
};
