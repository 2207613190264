<h4 class="definition-list-title" *ngIf="showTitle">{{alertDetailsTitle}}</h4>

<dl [class.key-values]="asKeyValueList">
	<ng-container *ngIf="alert.incidentId">
		<dt>{{ 'incidents.incident' | i18n }}</dt>
		<dd>
			<ng-container *ngIf="showIncidentLink; else notAlink">
				<a [routerLink]="['/incidents', alert.incidentId]" data-track-id="IncidentNameLink"
					data-track-type="Navigation">
					{{alert.incidentName || alert.incidentId}}
				</a>
				<a *ngIf="!isScc" href="{{ SCCIncidentUrl }}" target="_blank" data-track-id="IncidentMtpLink"
					data-track-type="Navigation">
					(
					<wcd-shared-icon [iconName]="'externalSource'" [verticalAlign]="'baseline'"></wcd-shared-icon>
					{{ 'alerts.panel.details.incident.open.in.mtp' | i18n }}
					)
				</a>
			</ng-container>
			<ng-template #notAlink>
				<span>{{alert.incidentName || alert.incidentId}}</span>
			</ng-template>
		</dd>
	</ng-container>

	<ng-container *ngIf="showServiceSource">
		<dt class="info-label">{{'alerts.fields.serviceSource.title' | i18n}}</dt>
		<dd>{{alert.serviceSource.nameI18nKey | i18n}}</dd>
	</ng-container>

	<ng-container *ngIf="showDetectionSource">
		<dt class="info-label">{{'alerts.fields.detectionSource.title' | i18n}}</dt>
		<dd>{{alert.detectionSource.nameI18nKey | i18n}}</dd>
	</ng-container>

	<ng-container
		*ngIf="alert.detectionTechnology != undefined && alert.detectionTechnology != '' && (alert.detectionTechnology | detectionTechnologyDisplay) != null">
		<dt>{{'alerts.fields.detectionTechnology.title' | i18n}}</dt>
		<dd>{{alert.detectionTechnology | detectionTechnologyDisplay}}</dd>
	</ng-container>

	<ng-container *ngIf="alert.detectionStatus != null && ((alert.detectionStatus | detectionStatusDisplay) != null)">
		<dt>{{'alerts.fields.detectionStatus.title' | i18n}}</dt>
		<dd [wcdTooltip]="alert.detectionStatus | detectionStatusTooltip | i18n ">
			{{ alert.detectionStatus | detectionStatusI18nName | i18n }}
		</dd>
	</ng-container>

	<ng-container *ngIf="alert.categories?.length">
		<dt class="info-label">
			{{ "alerts.fields.categor" + (alert.categories.length === 1 ? 'y' : 'ies') + "_title" | i18n }}</dt>
		<dd>{{alert.categories.join(', ')}}</dd>
	</ng-container>

	<ng-container *ngIf="showAlertTechniques && (alert.mitreTechniques | filterMitreTechniques); let mitreTechniques">
		<ng-container *ngIf="mitreTechniques.length">
			<dt>{{'alerts.fields.mitreTechniques.title' | i18n}}</dt>
			<dd>
				<div *ngFor="let mitreTechnique of mitreTechniques">
					<a href="{{ mitreTechnique | mitreTechniqueLink }}"
						target="_blank">{{ mitreTechnique | mitreTechniqueDisplay }}</a>
				</div>
			</dd>
		</ng-container>
	</ng-container>

	<dt>{{'alerts.activity.first' | i18n}}</dt>
	<dd>{{alert.firstEventTime | date:'medium'}}</dd>

	<dt>{{'alerts.activity.last' | i18n}}</dt>
	<dd>{{alert.lastEventTime | date:'medium'}}</dd>

	<dt>{{'alerts.firstSeen.title' | i18n}}</dt>
	<dd>{{alert.firstSeen | date:'medium'}}</dd>

	<dt class="info-label">{{'alerts.fields.assignedTo.title' | i18n}}</dt>
	<dd>{{alert.assignedTo || ('alerts.fields.assignedTo.unassigned' | i18n)}}</dd>

	<ng-container *ngIf="investigationEnabled">
		<dt>
			{{ 'alerts.automatedInvestigationDetails' | i18n }}
		</dt>
		<dd>
			<alert-investigation-status [alertId]="alert.id"></alert-investigation-status>
		</dd>
	</ng-container>

	<ng-container *ngIf="alert.threatFamilyName">
		<dt>{{'alerts.fields.threatFound.title' | i18n}}</dt>
		<dd>
			<span class="tag color-box-malware">{{alert.threatFamilyName}}</span>
		</dd>
	</ng-container>
</dl>
