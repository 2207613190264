import { Injectable } from '@angular/core';
import { Feature, FeaturesService } from '@wcd/config';
import {
	LiveResponseSettings,
	Machine,
	MachineResponseType,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { map } from 'rxjs/operators';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { AppConfigService } from '@wcd/app-config';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';

const SUPPORTED_OS = [
	OperatingSystemPlatformCategories.Windows10,
	OperatingSystemPlatformCategories.Windows10WVD,
	OperatingSystemPlatformCategories.Windows11,
];

const FLIGHTED_SERVER_MAP = {
	[OperatingSystemPlatformCategories.WindowsServer2019]: Feature.Server2019BackportSupportToRS6,
	[OperatingSystemPlatformCategories.WindowsServerSAC]: Feature.Server2019BackportSupportToRS6,
	[OperatingSystemPlatformCategories.WindowsServer2016]: Feature.LiveResponseForWindowsServer2016,
	[OperatingSystemPlatformCategories.WindowsServer2012R2]: Feature.LiveResponseForWindowsServer2012R2,
	[OperatingSystemPlatformCategories.Linux]: Feature.LiveResponseForLinux,
	[OperatingSystemPlatformCategories.WindowsServer2022]: Feature.Server2022BackportSupport,
} as const;

const FLIGHTED_OS_MAP = {
	...FLIGHTED_SERVER_MAP,
	[OperatingSystemPlatformCategories.macOS]: Feature.LiveResponseForMac,
} as const;

const SUPPORTED_CLOUD_ACTIONS: Array<MachineResponseType> = [
	'IsolationResponse',
	'ScanResponse',
	'ForensicsResponse',
];

const SUPPORTED_CLOUD_OS = [
	OperatingSystemPlatformCategories.WindowsServer2016,
	OperatingSystemPlatformCategories.WindowsServer2012R2,
	OperatingSystemPlatformCategories.Linux,
	OperatingSystemPlatformCategories.macOS,
];

const LIVERESPONSE_V3_TAG_NAME = 'LiveResponseV3';

@Injectable({
	providedIn: 'root',
})
export class LiveResponsePermissionsService {
	constructor(
		private readonly paris: Paris,
		private readonly featuresService: FeaturesService,
		private readonly i18nService: I18nService,
		private readonly appConfigService: AppConfigService,
		private readonly rbacControlService: RbacControlService
	) {}

	async isLiveResponseSupportedForMachine(
		machine: Machine
	): Promise<
		| {
				disabled: true;
				disableReason: string;
		  }
		| {
				disabled: false;
		  }
	> {
		if (machine.isMdatp && !machine.isManagedByMdatp) {
			return {
				disabled: true,
				disableReason: this.i18nService.strings
					.machines_entityDetails_actions_createLiveResponse_not_available_for_device,
			};
		}

		// LR prop validation
		if (!this.appConfigService.isLiveResponseEnabled) {
			return {
				disabled: true,
				disableReason: this.i18nService.strings
					.machines_entityDetails_actions_createLiveResponse_disabled_propertyOff,
			};
		}

		const osCategory = machine && machine.os && machine.os.platform && machine.os.platform.category;

		let isOsSupported =
			SUPPORTED_OS.includes(osCategory) ||
			(osCategory in FLIGHTED_OS_MAP && this.featuresService.isEnabled(FLIGHTED_OS_MAP[osCategory]));

		// Windows Server validation
		if (isOsSupported && osCategory in FLIGHTED_SERVER_MAP) {
			if (this.featuresService.isEnabled(Feature.LiveResponseForServerExcludeTenant)) {
				isOsSupported = false;
			} else {
				const useV2Api = this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation);
				const useV3Api = this.featuresService.isEnabled(Feature.CloudLiveResponseV3) || machine.extendedTags.userDefinedTags.includes(LIVERESPONSE_V3_TAG_NAME);

				const isLiveResponseForServersEnabled = await this.paris
					.getItemById(LiveResponseSettings, 1, null, { useV2Api: useV2Api, useV3Api: useV3Api})
					.pipe(map(({ liveResponseForServers }) => liveResponseForServers))
					.toPromise();

				if (!isLiveResponseForServersEnabled) {
					return {
						disabled: true,
						disableReason: this.i18nService.strings
							.machines_entityDetails_actions_createLiveResponse_disabled_serversOff,
					};
				}
			}
		}

		// RBAC check
		if (
			!this.rbacControlService.hasRequiredRbacPermissions({
				permissions: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.liveResponseAdvanced],
			})
		) {
			return {
				disabled: true,
				disableReason: this.i18nService.strings.common_permissions_noPermissionTooltip,
			};
		}

		// Unsupported OS check
		if (!isOsSupported) {
			return {
				disabled: true,
				disableReason: this.i18nService.strings
					.machines_entityDetails_actions_createLiveResponse_disabled_unsupportedOs,
			};
		}

		return { disabled: false };
	}

	async isCloudActionSupported(machine: Machine, actionType: MachineResponseType): Promise<boolean> {
		const osCategory = machine && machine.os && machine.os.platform && machine.os.platform.category;
		if (!(SUPPORTED_CLOUD_ACTIONS.includes(actionType) && SUPPORTED_CLOUD_OS.includes(osCategory))) {
			return false;
		}
		const { disabled } = await this.isLiveResponseSupportedForMachine(machine);
		return !disabled;
	}

	// coupled with perimission validation in the cloud - AutomatedIR.LiveResponse - src/airs/web/website/live_response/views.py
	hasLibraryPermissions() {
		const hasAdvancedPermissions = this.rbacControlService.hasRequiredRbacPermissions({
			permissions: [RbacMdeAllowedActions.liveResponseAdvanced]
		});
		const hasBasicAndSecurityPermissions = this.rbacControlService.hasRequiredRbacPermissions({
			permissions: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.securitySettings],
			requireAllPermissions: true
		});
		return hasAdvancedPermissions || hasBasicAndSecurityPermissions;
	}
}
