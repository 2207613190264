import { IconsService } from '@wcd/icons';
import { I18nService } from '@wcd/i18n';
var TvmThreatIconsComponent = /** @class */ (function () {
    function TvmThreatIconsComponent(iconsService, i18nService) {
        this.iconsService = iconsService;
        this.i18nService = i18nService;
    }
    Object.defineProperty(TvmThreatIconsComponent.prototype, "config", {
        set: function (threatIconsComponentConfig) {
            this.setLabel(threatIconsComponentConfig);
            this._allowTooltipHTMLRender = threatIconsComponentConfig.allowTooltipHTMLRender;
            this._indicationIcons = [
                {
                    show: threatIconsComponentConfig.activeThreatIcon.show,
                    icon: this.iconsService.getIcon('tvm.isThreatActive'),
                    state: threatIconsComponentConfig.activeThreatIcon.isThreatActive
                        ? 0 /* On */
                        : 1 /* Off */,
                    tooltip: threatIconsComponentConfig.activeThreatIcon.tooltip || '',
                },
                {
                    show: threatIconsComponentConfig.exploitIcon.show,
                    icon: this.iconsService.getIcon('tvm.hasExploit'),
                    state: threatIconsComponentConfig.exploitIcon.hasExploit
                        ? 0 /* On */
                        : 1 /* Off */,
                    tooltip: threatIconsComponentConfig.exploitIcon.tooltip || '',
                },
                {
                    show: threatIconsComponentConfig.insightIcon.show,
                    icon: this.iconsService.getIcon('tvm.insight'),
                    state: threatIconsComponentConfig.insightIcon.hasInsight
                        ? 2 /* Neutral */
                        : 1 /* Off */,
                    tooltip: threatIconsComponentConfig.insightIcon.tooltip || '',
                },
            ];
        },
        enumerable: true,
        configurable: true
    });
    TvmThreatIconsComponent.prototype.setLabel = function (threatIconsComponentConfig) {
        //setting the label with booleans depending on their values
        this.label = threatIconsComponentConfig.exploitIcon.hasExploit ? this.i18nService.get('tvm_threat_has_exploit') + ", " : "";
        this.label += threatIconsComponentConfig.insightIcon.hasInsight ? this.i18nService.get('tvm_threat_has_insight') + ", " : "";
        this.label += threatIconsComponentConfig.activeThreatIcon.isThreatActive ? this.i18nService.get('tvm_threat_is_active_threat') + ", " : "";
        //removing the last ", "
        this.label = this.label.slice(0, -2);
        if (this.label === "") {
            this.label = this.i18nService.get('tvm_threat_no_threats');
        }
    };
    return TvmThreatIconsComponent;
}());
export { TvmThreatIconsComponent };
