import { ValueObject, EntityField, EntityModelBase, ModelBase } from '@microsoft/paris';
import { Process } from '../../entity/process.entity';
import { File } from '../../file/file.entity';
import { RegistryOperation } from '../../entity/registry-operation.entity';
import { EventTag } from './event-tag.value-object';
import { CyberEvent } from '../../event/cyber-event.entity';

/**
 * old alert timeline element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Timeline Element',
	pluralName: 'Alert Timeline Elements',
})
export class AlertTimelineElement extends ModelBase {
	@EntityField({ data: 'ElementId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'IpAddress' })
	ipAddress: string;

	@EntityField({ data: 'Protocol' })
	protocol: string;

	@EntityField({ data: 'Port' })
	port: number;

	@EntityField({ data: 'Domain' })
	domain: string;

	@EntityField({ data: 'AnomalousDescription' })
	anomalousDescription: string;

	@EntityField({ data: 'IsAssociatedWithAlert', defaultValue: false })
	isAssociatedWithAlert: boolean;

	@EntityField({ data: 'SubElements', arrayOf: AlertTimelineElement })
	children: Array<AlertTimelineElement>;

	@EntityField({ data: 'Url' })
	url: string;

	//@EntityField({ data: 'LogonType' })
	//logonType: string;

	@EntityField({ data: 'InitiatingProcessName' })
	initiatingProcessName: string;

	@EntityField({ data: 'InitiatingProcessUser' })
	initiatingProcessUser: string;

	@EntityField({ data: 'InitiatingProcessDomain' })
	initiatingProcessDomain: string;

	// @EntityField({ data: 'EtwEventPropertiesAsJson' })
	// etwEventProperties: EtwEventProperties;

	@EntityField({ data: '__self', require: 'ProcessId' })
	process: Process;

	@EntityField({ data: '__self', require: data => data.FileName || data.Sha1 })
	file: File;

	@EntityField({ data: 'RegistryOperation' })
	registryOperation: RegistryOperation;

	// #region fields used only in workspace (for now)

	// @EntityField({ data: 'EventTags', arrayOf: EventTag })
	// tags: Array<EventTag>;

	@EntityField({ data: 'RiskScore' })
	riskScore: number;

	@EntityField({ data: 'ReportIndex' })
	reportIndex: string;

	@EntityField({ data: 'EventTime' })
	eventTime: Date;

	// #endregion fields used only in workspace (for now)

	//private _titleItems: Array<CyberEventEntityTitleItem>;
	private _descriptions: Array<string>;

	// get name(): string {
	// 	let titleItems: Array<CyberEventEntityTitleItem> = this.titleItems;
	// 	return titleItems && titleItems.length && titleItems[0].name;
	// }

	// get typeName(): string {
	// 	return this.actionType && this.actionType.name || this.type.name;
	// }
	//
	// get icon(): string {
	// 	return this.actionType && this.actionType.icon || this.type.icon;
	// }

	get descriptions(): Array<string> {
		// if (this._descriptions === undefined && this.actionType && this.actionType.getDescriptions)
		// 	this._descriptions = this.actionType.getDescriptions(this) || null;

		return this._descriptions;
	}

	private _depth: number;
	get depth(): number {
		// if (this._depth === undefined) {
		// 	if (!this.$parent || !(this.$parent instanceof AlertTimelineElement))
		// 		this._depth = 0;
		// 	else
		// 		this._depth = (<AlertTimelineElement>this.$parent).depth + 1;
		// }

		return this._depth;
	}

	get hasChildren(): boolean {
		return this.children && this.children.length > 0;
	}

	// get titleItems(): Array<CyberEventEntityTitleItem> {
	// 	if (!this._titleItems) {
	// 		// if (this.actionType && this.actionType.getActionTitleItems)
	// 		// 	this._titleItems = this.actionType.getActionTitleItems(this);
	// 		//
	// 		// if (!this._titleItems && this.type.getEntityTitleItems)
	// 		// 	this._titleItems = this.type.getEntityTitleItems(this);
	//
	// 		if (!this._titleItems) {
	// 			let fallbackName = '';
	// 			if (this.file && this.file.fileName) {
	// 				fallbackName = this.file.fileName;
	// 			} else if (this.registryOperation) {
	// 				fallbackName = this.registryOperation.name;
	// 			} else if (this.remoteMachine && this.remoteMachine.name) {
	// 				fallbackName = this.remoteMachine.name;
	// 			} else {
	// 				fallbackName = this.domain || this.url || this.ipAddress;
	// 				if (!fallbackName) {
	// 					fallbackName = 'n/a';
	// 				}
	// 			}
	// 			console.error(`Could not calculate titleItems for AlertTimelineElement - falling back to ${fallbackName}`);
	// 			this._titleItems = [{ name: fallbackName }];
	// 		}
	// 	}
	//
	// 	return this._titleItems;
	// }
}
