import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { MDESuppressionRuleIOCConditionsComponent } from './components/mde-suppression-rule-ioc-conditions.component';

@NgModule({
	imports: [SharedModule],
	declarations: [MDESuppressionRuleIOCConditionsComponent],
	exports: [MDESuppressionRuleIOCConditionsComponent],
	entryComponents: [MDESuppressionRuleIOCConditionsComponent]
})
export class MDEConditionsModule { }
