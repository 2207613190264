import { FabricIconNames } from '@wcd/scc-common';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { Machine } from '@wcd/domain';
var MachineNameComponent = /** @class */ (function () {
    function MachineNameComponent(globalEntityTypesService) {
        this.globalEntityTypesService = globalEntityTypesService;
        this.FabricIconNames = FabricIconNames;
        this.showIcon = false;
        this.iconCssClass = 'field-value-icon';
        this.showLink = false;
        this.preferFullName = false;
    }
    Object.defineProperty(MachineNameComponent.prototype, "machine", {
        get: function () {
            return this._machine;
        },
        set: function (value) {
            this._machine = value;
            this.setMachineData();
        },
        enumerable: true,
        configurable: true
    });
    MachineNameComponent.prototype.setMachineData = function () {
        if (this.machine) {
            var machineEntity = void 0;
            if (typeof this.machine === 'string') {
                machineEntity = new Machine({
                    name: this.machine,
                    id: null,
                });
            }
            else {
                var machineBase = this.machine;
                machineEntity = new Machine({
                    name: machineBase.name || machineBase.machineId || '',
                    id: machineBase.machineId,
                });
            }
            this.fullName = machineEntity.name;
            this.shortName = this.globalEntityTypesService.getEntityName(Machine, machineEntity);
            this.machineId = machineEntity.id;
            this.machineRouteLinkModel = this.globalEntityTypesService.getRouteLinkModel(Machine, { senseMachineId: this.machineId, name: this.machineId });
        }
        this.hasName = !!(this.fullName || this.shortName);
    };
    return MachineNameComponent;
}());
export { MachineNameComponent };
