import { ComponentFactoryResolver, EventEmitter, Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import assertNever from 'assert-never';
import { Observable, merge, EMPTY } from 'rxjs';
import { filter, map, mergeMap, catchError } from 'rxjs/operators';
import {
	AntivirusScanParameters,
	AntivirusScanResponse,
	CancelAntivirusScanParameters,
	CancelCollectionInvestigationParameters,
	CancelIsolationParameters,
	CancelRestrictAppExecutionParameters,
	CollectionInvestigationParameters,
	CollectionInvestigationResponse,
	IsolateParameters,
	IsolateResponse,
	IsolationAction,
	IsolationPublicApiParameters,
	IsolationTypeValues,
	Machine,
	MachineCancelAntivirusScanApiCall,
	MachineCancelCollectInvestigationPackageApiCall,
	MachineCancelIsolationApiCall,
	MachineCancelRestrictAppExecutionApiCall,
	MachineCollectInvesigationPackageRequest,
	MachineCollectInvestigationPackageApiCall,
	MachineIsolateApiCall,
	MachineIsolationRequest,
	MachineMachineRequestLatestRelationship,
	MachineRemoveAppExecutionRestrictionApiCall,
	MachineRequest,
	MachineRestrictAppExecutionApiCall,
	MachineRestrictAppExecutionRequest,
	MachineRunAntivirusScanApiCall,
	MachineRunAntivirusScanRequest,
	MachineUnisolateApiCall,
	RemoveRestrictAppExecutionParameters,
	RemoveRestrictAppExecutionResponse,
	RestrictAppExecutionParameters,
	RestrictAppExecutionResponse,
	UnisolateParameters,
	UnisolateResponse,
	MachineIsolatePublicApiApiCall,
	MachineReleaseIsolatePublicApiApiCall,
	RestrictAppExecutionPublicApiApiCall,
	RemoveRestrictAppExecutionPublicApiApiCall,
	MachineActionParameters,
	LogsCollectionParameters,
	LogsCollectionResponse,
	MachineLogsCollectionApiCall,
	MachineLogsCollectionRequest,
	CancelLogsCollectionParameters,
	MachineCancelLogsCollectionApiCall,
} from '@wcd/domain';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { IsolateModalComponent, IsolateModalPayload } from '../components/modal/isolate-modal.component';
import {
	RunAntivirusScanModalComponent,
	RunAntivirusScanModalPayload,
} from '../components/modal/run-antivirus-scan-modal.component';
import { MachineActionCenterPanelComponent } from '../components/panel/action-center/action-center.component';
import { TroubleshootingModePanelComponent } from '../components/panel/troubleshooting-mode/troubleshooting-mode.component';
import { toPromise } from '../../../utils/rxjs/utils';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { v4 as uuid4 } from 'uuid';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { MagellanIsolationStatusService } from './magellan-isolation.service';

const MAX_CONCURRENT_ISOLATION_REQUESTS = 5;

@Injectable()
export class MachinesActionsService {
	constructor(
		private readonly paris: Paris,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private readonly panelService: PanelService,
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly magellanIsolationStatusProvider: MagellanIsolationStatusService,
		private readonly confirmationService: ConfirmationService
	) {}

	collectInvestigationPackage(machine: Machine): Promise<ConfirmEvent<CollectionInvestigationResponse>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.entityDetails.actions.collectInvestigationPackage.dialog.title',
			titleKeyParams: {
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
			},
			textKey: 'machines.entityDetails.actions.collectInvestigationPackage.dialog.content',
			kind: 'create',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<CollectionInvestigationResponse, CollectionInvestigationParameters>(
					MachineCollectInvestigationPackageApiCall,
					{ machine, comment }
				),
		});
	}

	collectSupportLogs(machine: Machine): Promise<ConfirmEvent<LogsCollectionResponse>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.entityDetails.actions.logsCollection.dialog.title',
			titleKeyParams: {
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
			},
			textKey: 'machines.entityDetails.actions.logsCollection.dialog.content',
			kind: 'create',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<LogsCollectionResponse, LogsCollectionParameters>(
					MachineLogsCollectionApiCall,
					{ machine, comment }
				),
		});
	}

	async openActionCenter(theMachine: Machine, requestGuid?: string): Promise<void> {
		await this.panelService
			.create(MachineActionCenterPanelComponent, null, { machine: theMachine, requestGuid: requestGuid ? null : requestGuid })
			.toPromise();
	}

	async openTroubleshootingModePanel(theMachine: Machine): Promise<void> {
		await this.panelService
			.create(TroubleshootingModePanelComponent, null, { machine: theMachine })
			.toPromise();
	}

	runAntivirusScan(machine: Machine): Promise<ConfirmEvent<AntivirusScanResponse>> {
		return this.dialogsService.confirm<
			RunAntivirusScanModalPayload,
			RunAntivirusScanModalComponent,
			Partial<RunAntivirusScanModalComponent>,
			AntivirusScanResponse
		>({
			componentType: RunAntivirusScanModalComponent,
			componentFactoryResolver: this.componentFactoryResolver,
			inputs: {
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
			},
			onConfirm: ({ comment, scanType }) =>
				this.paris.apiCall<AntivirusScanResponse, AntivirusScanParameters>(
					MachineRunAntivirusScanApiCall,
					{ machine, comment, scanType }
				),
		});
	}

	restrictAppExecution(machine: Machine): Promise<ConfirmEvent<RestrictAppExecutionResponse>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.restrict.title',
			titleKeyParams: {
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
			},
			textKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.restrict.content',
			kind: 'create',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<RestrictAppExecutionResponse, RestrictAppExecutionParameters>(
					MachineRestrictAppExecutionApiCall,
					{ machine, comment }
				),
		});
	}

	multipleRestrictAppExecution(machines: ReadonlyArray<Machine>): Promise<ConfirmEvent<void>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines_entityDetails_actions_multipleRestrictAppExecution_dialog_restrict_title',
			titleKeyParams: {
				machineCount: machines.length,
			},
			textKey: 'machines_entityDetails_actions_restrictAppExecution_dialog_restrict_content',
			kind: 'create',
			onConfirm: ({ comment }) => {
				const bulkId = uuid4();
				const responses = machines.map(machine => {
					return this.paris
						.apiCall<void, MachineActionParameters>(RestrictAppExecutionPublicApiApiCall, {
							comment: comment,
							externalId: bulkId,
							machineId: machine.id,
						})
						.pipe(catchError(err => EMPTY));
				});

				// TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
				merge(...responses, MAX_CONCURRENT_ISOLATION_REQUESTS).toPromise();

				this.showBulkActionsConfirmationDialog(bulkId);
				return Promise.resolve();
			},
		});
	}

	removeAppRestrictions(machine: Machine): Promise<ConfirmEvent<RemoveRestrictAppExecutionResponse>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.removeRestriction.title',
			titleKeyParams: {
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
			},
			textKey: 'machines.entityDetails.actions.restrictAppExecution.dialog.removeRestriction.content',
			kind: 'create',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<RemoveRestrictAppExecutionResponse, RemoveRestrictAppExecutionParameters>(
					MachineRemoveAppExecutionRestrictionApiCall,
					{ machine, comment }
				),
		});
	}

	multipleRemoveAppRestrictions(machines: Array<Machine>): Promise<ConfirmEvent<void>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey:
				'machines_entityDetails_actions_multipleRestrictAppExecution_dialog_removeRestriction_title',
			titleKeyParams: {
				machineCount: machines && machines.length,
			},
			textKey: 'machines_entityDetails_actions_restrictAppExecution_dialog_removeRestriction_content',
			kind: 'create',
			onConfirm: ({ comment }) => {
				const bulkId = uuid4();
				const responses = machines.map(machine => {
					return this.paris
						.apiCall<void, MachineActionParameters>(RemoveRestrictAppExecutionPublicApiApiCall, {
							comment: comment,
							externalId: bulkId,
							machineId: machine.id,
						})
						.pipe(catchError(err => EMPTY));
				});

				// TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
				merge(...responses, MAX_CONCURRENT_ISOLATION_REQUESTS).toPromise();

				this.showBulkActionsConfirmationDialog(bulkId);
				return Promise.resolve();
			},
		});
	}

	isolate(
		machine: Machine,
		isSelectiveIsolationEnabled: boolean,
		callback = () => Promise.resolve<any>(undefined)
	): Promise<ConfirmEvent<IsolateResponse>> {
		return this.dialogsService.confirm<
			IsolateModalPayload,
			IsolateModalComponent,
			Partial<IsolateModalComponent>,
			IsolateResponse
		>({
			componentType: IsolateModalComponent,
			componentFactoryResolver: this.componentFactoryResolver,
			inputs: {
				device: machine,
				machineName: this.globalEntityTypesService.getEntityName(Machine, machine),
				showAllowOutlookAndSkypeCheckbox: isSelectiveIsolationEnabled && machine.isManagedByMdatp,
			},
			onConfirm: ({ comment, allowOutlookAndSkype }) => {
				let isolationType = IsolationTypeValues.Full;

				if (allowOutlookAndSkype) {
					isolationType = IsolationTypeValues.Selective;
				}

				if (!machine.isManagedByMdatp) {
					isolationType = IsolationTypeValues.UnManagedDevice;
				}

				return this.paris
					.apiCall<IsolateResponse, IsolateParameters>(MachineIsolateApiCall, {
						machine,
						comment,
						isolationType,
					})
					.toPromise()
					.then(response => callback().then(() => response));
			},
		});
	}

	multipleIsolate(
		machines: ReadonlyArray<Machine>,
		isSelectiveIsolationEnabled: boolean
	): Promise<ConfirmEvent<void>> {
		return this.dialogsService.confirm<
			IsolateModalPayload,
			IsolateModalComponent,
			Partial<IsolateModalComponent>,
			void
		>({
			componentType: IsolateModalComponent,
			componentFactoryResolver: this.componentFactoryResolver,
			inputs: {
				showAllowOutlookAndSkypeCheckbox: isSelectiveIsolationEnabled,
				isMultipleSelection: true,
			},
			onConfirm: ({ comment, allowOutlookAndSkype, bulkName }) => {
				const bulkId = uuid4();
				const isolationType = allowOutlookAndSkype
					? IsolationTypeValues.Selective
					: IsolationTypeValues.Full;

				const responses = machines.map(machine => {
					return this.paris
						.apiCall<void, IsolationPublicApiParameters>(MachineIsolatePublicApiApiCall, {
							comment: comment,
							title: bulkName,
							isolationType: isolationType,
							externalId: bulkId,
							machineId: machine.id,
						})
						.pipe(catchError(err => EMPTY));
				});

				// TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
				merge(...responses, MAX_CONCURRENT_ISOLATION_REQUESTS).toPromise();

				this.showBulkActionsConfirmationDialog(bulkId, bulkName);
			},
		});
	}

	releaseFromIsolation(
		machine: Machine,
		callback = () => Promise.resolve<any>(undefined)
	): Promise<ConfirmEvent<UnisolateResponse>> {
		const confirmedEvent = new EventEmitter<boolean>();
		return this.showSimpleCommentConfirmDialog({
			titleKey: machine.isManagedByMdatp ? 'machines.entityDetails.actions.isolateMachine.dialog.removeIsolation.title' : 'machines.entityDetails.actions.isolateNotManagedDevice.dialog.removeIsolation.title',
			titleKeyParams: { machineName: machine.name || machine.id },
			textKey: 'machines.entityDetails.actions.isolateMachine.dialog.removeIsolation.content',
			kind: 'create',
			showLoaderAndCloseOnEvent: confirmedEvent,
			onConfirm: ({ comment }) => {
				return this.paris
					.queryForItem<Machine, MachineRequest>(MachineMachineRequestLatestRelationship, machine)
					.pipe(
						map(({ items }) => items.find(item => item.type === 'IsolationResponse')),
						filter(isolationRequest => Boolean(isolationRequest)),
						map(
							(isolationRequest: MachineIsolationRequest) =>
								isolationRequest.isolationType
						),
						mergeMap(isolationType =>
							this.paris.apiCall<UnisolateResponse, UnisolateParameters>(
								MachineUnisolateApiCall,
								{ machine, comment, isolationType }
							)
						)
					)
					.toPromise()
					.then(response =>
						callback().then(() => {
							confirmedEvent.emit();
							return response;
						})
					);
			},
		});
	}

	multipleReleaseFromIsolation(machines: ReadonlyArray<Machine>): Promise<ConfirmEvent<void>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines_entityDetails_actions_isolateMachine_dialog_multiRemoveIsolation_title',
			titleKeyParams: { machineCount: machines && machines.length },
			textKey: 'machines_entityDetails_actions_multipleIsolateMachine_dialog_removeIsolation_content',
			kind: 'create',
			onConfirm: ({ comment }) => {
				const bulkId = uuid4();
				const responses = machines.map(machine => {
					return this.paris
						.apiCall<void, MachineActionParameters>(MachineReleaseIsolatePublicApiApiCall, {
							comment: comment,
							externalId: bulkId,
							machineId: machine.id,
						})
						.pipe(catchError(err => EMPTY));
				});

				// TODO: This is a temp solution, only for DEMO. The logic of the multi-select should be done in the BE.
				merge(...responses, MAX_CONCURRENT_ISOLATION_REQUESTS).toPromise();

				this.showBulkActionsConfirmationDialog(bulkId);
				return Promise.resolve();
			},
		});
	}

	cancelCollectInvestigationPackage(
		requestGuid: string
	): Promise<ConfirmEvent<MachineCollectInvesigationPackageRequest>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.actionCenter.actions.cancelCollectInvestigationPackage.dialog.title',
			textKey: 'machines.actionCenter.actions.cancelCollectInvestigationPackage.dialog.content',
			kind: 'cancel',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<
					MachineCollectInvesigationPackageRequest,
					CancelCollectionInvestigationParameters
				>(MachineCancelCollectInvestigationPackageApiCall, { requestGuid, comment }),
		});
	}

	cancelAntivirusScan(requestGuid: string): Promise<ConfirmEvent<MachineRunAntivirusScanRequest>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.actionCenter.actions.cancelAntivirusScan.dialog.title',
			textKey: 'machines.actionCenter.actions.cancelAntivirusScan.dialog.content',
			kind: 'cancel',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<MachineRunAntivirusScanRequest, CancelAntivirusScanParameters>(
					MachineCancelAntivirusScanApiCall,
					{ requestGuid, comment }
				),
		});
	}

	cancelRestrictAppExecution(
		requestGuid: string
	): Promise<ConfirmEvent<MachineRestrictAppExecutionRequest>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.actionCenter.actions.cancelRestrictAppExecution.dialog.title',
			textKey: 'machines.actionCenter.actions.cancelRestrictAppExecution.dialog.content',
			kind: 'cancel',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<MachineRestrictAppExecutionRequest, CancelRestrictAppExecutionParameters>(
					MachineCancelRestrictAppExecutionApiCall,
					{ requestGuid, comment }
				),
		});
	}

	cancelIsolation(
		action: IsolationAction,
		requestGuid: string
	): Promise<ConfirmEvent<MachineIsolationRequest>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey:
				action === 'Isolate'
					? 'machines.actionCenter.actions.cancelIsolation.dialog.title.isolate'
					: action === 'Unisolate'
					? 'machines.actionCenter.actions.cancelIsolation.dialog.title.removeIsolation'
					: assertNever(action),
			textKey: 'machines.actionCenter.actions.cancelIsolation.dialog.content',
			kind: 'cancel',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<MachineIsolationRequest, CancelIsolationParameters>(
					MachineCancelIsolationApiCall,
					{ requestGuid, comment }
				),
		});
	}

	cancelLogsCollection(
		requestGuid: string
	): Promise<ConfirmEvent<MachineLogsCollectionRequest>> {
		return this.showSimpleCommentConfirmDialog({
			titleKey: 'machines.actionCenter.actions.cancelLogsCollection.dialog.title',
			textKey: 'machines.actionCenter.actions.cancelLogsCollection.dialog.content',
			kind: 'cancel',
			onConfirm: ({ comment }) =>
				this.paris.apiCall<
					MachineLogsCollectionRequest,
					CancelLogsCollectionParameters
					>(MachineCancelLogsCollectionApiCall, { requestGuid, comment }),
		});
	}

	private showBulkActionsConfirmationDialog(bulkId: string, remediationName?: string) {
		/*
			The short bulkId (also called remediation ID) is only for user display.
			This ID will be displayed in the AC as well under 'Remediation ID' column.
		 */
		const shortBulkId = bulkId && bulkId.substr(bulkId.length - 6);
		const text = remediationName
			? this.i18nService.get(
					'machines_entityDetails_actions_multiple_machineAction_actionSubmitted_message',
					{ bulkId: shortBulkId, remediationName: remediationName }
			  )
			: this.i18nService.get(
					'machines_entityDetails_actions_multiple_machineAction_actionSubmitted_message_only_bulkId',
					{ bulkId: shortBulkId }
			  );

		this.confirmationService.confirm({
			title: this.i18nService.strings
				.machines_entityDetails_actions_multiple_machineAction_actionSubmitted_title,
			text: text,
			showConfirm: false,
			cancelText: this.i18nService.strings.buttons_done,
		});
	}

	private async showSimpleCommentConfirmDialog<TConfirmActionResponse>(options: {
		titleKey?: string;
		titleKeyParams?: object;
		textKey: string;
		kind: 'create' | 'cancel';
		showLoaderAndCloseOnEvent?: EventEmitter<boolean>;
		onConfirm: (confirmDialogPayload: {
			comment: string;
		}) => Observable<TConfirmActionResponse> | Promise<TConfirmActionResponse>;
	}): Promise<ConfirmEvent<TConfirmActionResponse>> {
		const confirmModalResult = await this.dialogsService.confirm<{ comment: string }>({
			title: this.i18nService.get(options.titleKey, options.titleKeyParams),
			text: this.i18nService.get(options.textKey),
			requireMessage: {
				type: 'textarea',
				min: 1,
				max: 1000,
				placeholder: this.i18nService.get(
					options.kind === 'create'
						? 'machines.entityDetails.actions.$general.dialog.form.comment.placeholder'
						: options.kind === 'cancel'
						? 'machines.actionCenter.actions.$general.dialog.form.comment.placeholder'
						: assertNever(options.kind)
				),
				property: 'comment',
			},
			showLoaderAndCloseOnEvent: options.showLoaderAndCloseOnEvent,
		});

		if (!confirmModalResult.confirmed) {
			return { confirmed: false };
		}

		if (options.onConfirm) {
			const actionResult = await toPromise(options.onConfirm(confirmModalResult.data));

			return {
				confirmed: true,
				data: actionResult,
			};
		}
	}
}
