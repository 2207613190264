import { SevilleModule } from '../../../seville/seville.module';
import { SevilleUtils } from '../../../common/services/seville.utils';

declare let angular: angular.IAngularStatic;

SevilleModule.directive('timelineTimebar', timebarDirective);

timebarDirective.$inject = ['$window', '$filter'];

function timebarDirective($window, $filter) {
	return {
		restrict: 'EA',
		scope: {
			fromDate: '=',
			toDate: '=',
		},
		replace: true,
		template: `
            	<div class="timebar">
            		<div class="timebar-date-selected-from"></div>
            		<div class="timebar-date-selected-to"></div>
            		<div class="timebar-background">
            			<div class="timebar-range">
            				<div class="timebar-left-indicator"></div>
            				<div class="timebar-right-indicator"></div>
						</div>
					</div>
					<div class="timebar-legend"></div>
				</div>`,
		link: function($scope, element, attrs) {
			$scope.$watch(
				function() {
					return $scope.fromDate + $scope.toDate;
				},
				function(newValue, oldValue) {
					calculateDimensions();
				}
			);

			angular.element($window).on('resize', calculateDimensions);
			var timeRangeElement,
				backgroundElement,
				timebarFromDate,
				timebarToDate,
				totalDays,
				timebarLegend,
				mainWidth,
				unitWidth;
			var monthsOffsets = [];
			var monthsToDisplay = [];
			var indicators = [];
			var months = [];
			var dateFilter = $filter('date');
			var dateFormatFilter = $filter('sevilleDate');
			var minRightOffset = 30;

			function buildTimebar() {
				var timeBarElem = $(element);
				timeRangeElement = timeBarElem.find('.timebar-range');
				backgroundElement = timeBarElem.find('.timebar-background');
				timebarLegend = timeBarElem.find('.timebar-legend');
				timebarFromDate = timeBarElem.find('.timebar-date-selected-from');
				timebarToDate = timeBarElem.find('.timebar-date-selected-to');
				buildMonthIndicators();
				calcMonthsOffsetArray();
				buildMonthsDisplay();
			}

			function buildMonthIndicators() {
				for (var i = 0; i < 6; i++) {
					var indicator = $('<div class="timebar-date-marker"></div>');
					backgroundElement.append(indicator);
				}
				indicators = backgroundElement.find('.timebar-date-marker');
			}

			function calcMonthsOffsetArray() {
				var today = new Date();
				var currentDate = new Date();
				var minDate = new Date();
				minDate.setMonth(minDate.getMonth() - 6); // the date 6 months ago
				totalDays = calcDaysDiff(currentDate, minDate);

				while (true) {
					var firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
					if (currentDate.getDate() != 1) {
						var offset = calcDaysDiff(today, firstOfMonth);
						currentDate = firstOfMonth;
						monthsOffsets.unshift(offset + 1);

						continue;
					}

					currentDate.setMonth(currentDate.getMonth() - 1);
					if (currentDate < minDate) {
						currentDate.setMonth(currentDate.getMonth() + 1);
						var offset = calcDaysDiff(minDate, currentDate);
						monthsOffsets.unshift(offset);
						break;
					} else {
						var offset = calcDaysDiff(firstOfMonth, currentDate);
						monthsOffsets.unshift(offset);
					}
				}
			}

			function buildMonthsDisplay() {
				getMonthsDisplay();
				for (var i = 0; i < monthsOffsets.length; i++) {
					var month = $('<div class="timebar-legend-month"></div>');
					month.text(monthsToDisplay[i]);
					timebarLegend.append(month);
				}
				months = timebarLegend.find('.timebar-legend-month');
			}

			function displaySelectedDatesInTimebar() {
				var fromDate = new Date($scope.fromDate);
				fromDate.setHours(23, 59, 59, 59);
				var toDate = new Date($scope.toDate);
				toDate.setHours(23, 59, 59, 59);
				var daysDiff = calcDaysDiff(fromDate, toDate);
				var formattedFromDate = dateFormatFilter(fromDate, 'date');
				var formattedToDate = dateFormatFilter(toDate, 'date');
				if (daysDiff > 0) {
					timebarFromDate.text(formattedFromDate);
				} else {
					timebarFromDate.text('');
				}

				timebarToDate.text(formattedToDate);
			}

			function calculateDimensions() {
				mainWidth = backgroundElement.width();
				unitWidth = mainWidth / (totalDays + monthsOffsets.length - 1);

				updateRangeOfSelectedDates();
				calcMonthIndicatorsWidth();
				calcMonthsTitleWidth();
			}

			function updateRangeOfSelectedDates() {
				//calc diff in dates between today and the toDate after converting them both to the same format and timezone.
				var newDate = dateFilter(new Date(), 'yyyy-MM-ddTHH:mm:ss.sss', 'UTC');
				var toDateUtc = dateFilter(new Date($scope.toDate), 'yyyy-MM-ddTHH:mm:ss.sss', 'UTC');
				newDate = new Date(newDate);
				newDate.setHours(0, 0, 0, 0);

				toDateUtc = new Date(toDateUtc);
				toDateUtc.setHours(0, 0, 0, 0);
				var rightOffsetInDays = calcDaysDiff(newDate, toDateUtc);

				// calc markers to add to the offset of the selected time range
				var rightOffsetMarkers = 0;
				var today = new Date();
				today.setHours(0, 0, 0, 0);
				var toDate = new Date($scope.toDate);
				toDate.setHours(0, 0, 0, 0);
				var fromDate = new Date($scope.fromDate);
				fromDate.setHours(0, 0, 0, 0);
				var firstOfMonthToday = new Date(today.getFullYear(), today.getMonth(), 1);

				while (firstOfMonthToday > toDate) {
					rightOffsetMarkers++;
					firstOfMonthToday.setMonth(firstOfMonthToday.getMonth() - 1);
				}

				// calc markers to add to the width
				var widthMarkers = 0;

				// interate over toDate and fromDate and substract the months offset - if the toDate is still bigger, add a marker.
				var firstOfMonthToDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);

				while (firstOfMonthToDate > fromDate) {
					widthMarkers++;
					firstOfMonthToDate.setMonth(firstOfMonthToDate.getMonth() - 1);
				}

				// calc the time range differences and set the width and the offset of the time range element.
				var timeRangeInDays = calcDaysDiff(new Date($scope.fromDate), new Date($scope.toDate)) + 1;

				var timeRangeWidth = (timeRangeInDays + widthMarkers) * unitWidth;
				var timeRangeOffset = (rightOffsetInDays + rightOffsetMarkers) * unitWidth + minRightOffset;
				timeRangeElement.width(timeRangeWidth);
				timeRangeElement.css('right', timeRangeOffset);
				calcSelectedDatesOffset(timeRangeWidth, timeRangeOffset);
			}

			function calcSelectedDatesOffset(timeRangeWidth, timeRangeOffset) {
				displaySelectedDatesInTimebar();
				var timebarToDateElement = $(timebarToDate);
				var timebarFromDateElement = $(timebarFromDate);
				var timebarToDateWidth = timebarToDateElement.width();
				var timebarFromDateWidth = timebarFromDateElement.width();
				var backgroundContainerWidth = $(backgroundElement).width();

				// if the min offest for the to date is smaller than the min offset of the time range bar.
				var toDateOffsetSmallerThanMinOffset =
					timeRangeOffset - 0.5 * timebarToDateWidth < minRightOffset ? true : false;

				// if we're at the left corner and the from date offset is begger than the background container.
				var fromDateOffsetBiggerThanBackground =
					timeRangeOffset + timeRangeWidth - 0.5 * timebarFromDateWidth >
					backgroundContainerWidth - 0.5 * timebarFromDateWidth
						? true
						: false;

				// if the width of the selected time range is smaller than the sum of the widths of the dates - meaning they will override each other.
				var widthSmallerThanSelectedDates =
					timeRangeWidth < 0.5 * timebarFromDateWidth + 0.5 * timebarToDateWidth ? true : false;

				// if the from date offset is smaller than the to date display width
				var fromDateOffsetSmallerThanToDateWidth =
					timeRangeOffset + timeRangeWidth - 0.5 * timebarFromDateWidth < timebarToDateWidth
						? true
						: false;

				// calc the offset of the from and to dates at a regular state.
				var timebarToDateOffset = timeRangeOffset - 0.5 * timebarToDateWidth;
				var timebarFromDateOffset = timeRangeOffset + timeRangeWidth - 0.5 * timebarFromDateWidth;

				// we're at the right corner of the timebar and no other condition is true.
				if (toDateOffsetSmallerThanMinOffset) {
					timebarToDateOffset = minRightOffset;
					if (timebarFromDateOffset < timebarToDateOffset + timebarToDateWidth) {
						timebarFromDateOffset = timebarToDateOffset + timebarToDateWidth + unitWidth;
					}
				}

				// we're at the left corner of the timebar and no other condition is true.
				if (fromDateOffsetBiggerThanBackground) {
					timebarFromDateOffset = backgroundContainerWidth - 0.5 * timebarFromDateWidth;
					if (timebarToDateOffset > timebarFromDateOffset - timebarFromDateWidth) {
						timebarToDateOffset = timebarFromDateOffset - timebarFromDateWidth - unitWidth;
					}
				}

				if (fromDateOffsetSmallerThanToDateWidth && !widthSmallerThanSelectedDates) {
					// update the offset of the from date and add unitWidth for padding.
					timebarFromDateOffset = timebarToDateWidth + timebarToDateOffset + unitWidth;

					if (fromDateOffsetBiggerThanBackground) {
						timebarFromDateOffset = backgroundContainerWidth - 0.5 * timebarFromDateWidth;
					}
				}

				if (widthSmallerThanSelectedDates && !fromDateOffsetSmallerThanToDateWidth) {
					// add to each date half of it's side to seperate them
					timebarFromDateOffset = timebarFromDateOffset + 0.5 * timebarFromDateWidth;
					if (fromDateOffsetBiggerThanBackground) {
						timebarFromDateOffset = backgroundContainerWidth - 0.5 * timebarFromDateWidth;
						timebarToDateOffset = timebarFromDateOffset - 0.5 * timebarFromDateWidth - unitWidth;
					} else {
						// update the offset of the to date.
						timebarToDateOffset = Math.max(
							timebarToDateOffset - 0.5 * timebarToDateWidth,
							minRightOffset
						);
					}
				}

				// if both the width is smaller than (sum of the widths of the selected dates )/2 and the offest is smaller than timebarToDateWidth
				if (widthSmallerThanSelectedDates && fromDateOffsetSmallerThanToDateWidth) {
					// update the width of the offset of the to date.
					timebarFromDateOffset = timebarToDateWidth + minRightOffset + unitWidth;
					if (fromDateOffsetBiggerThanBackground) {
						timebarFromDateOffset = backgroundContainerWidth - 0.5 * timebarFromDateWidth;
						timebarToDateOffset = timebarToDateOffset - timebarFromDateWidth;
					}
				}

				if (fromDateOffsetBiggerThanBackground && widthSmallerThanSelectedDates) {
					timebarToDateOffset = timebarToDateOffset - 0.5 * timebarFromDateWidth;
				}

				timebarToDateElement.css('right', timebarToDateOffset);
				timebarFromDateElement.css('right', timebarFromDateOffset);
			}

			function calcMonthIndicatorsWidth() {
				var offset = 0;
				for (var i = 0; i < monthsOffsets.length - 1; i++) {
					var unitsOffset = monthsOffsets[i] + offset + i - 1;
					var left = unitWidth * unitsOffset + minRightOffset;
					var indicator = $(indicators[i]);

					indicator.css('left', left);
					indicator.width(unitWidth);

					offset += monthsOffsets[i];
				}
			}

			function calcMonthsTitleWidth() {
				var offset = 0;
				for (var i = 0; i < monthsOffsets.length - 1; i++) {
					var unitsOffset = monthsOffsets[i] + offset + i;
					var currentMonth = $(months[i]);
					var monthWidth = currentMonth.width();
					var left = unitWidth * unitsOffset + minRightOffset - 0.5 * monthWidth;
					currentMonth.css('left', left);
					offset += monthsOffsets[i];
				}
			}

			function calcDaysDiff(firstDate, secondDate) {
				SevilleUtils.date.endOfDay(firstDate);
				SevilleUtils.date.endOfDay(secondDate);

				var day = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds

				return Math.round(Math.abs((firstDate.getTime() - secondDate.getTime()) / day));
			}

			function getMonthsDisplay() {
				var currentDate = new Date();
				var minDate = new Date();
				minDate.setMonth(minDate.getMonth() - 6);

				while (true) {
					if (currentDate.getDate() != 1) {
						var firstOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
						currentDate = firstOfMonth;
						monthsToDisplay.unshift(dateFilter(currentDate, 'MMM yyyy'));

						continue;
					}

					currentDate.setMonth(currentDate.getMonth() - 1);
					if (currentDate < minDate) break;
					monthsToDisplay.unshift(dateFilter(currentDate, 'MMM yyyy'));
				}
			}

			buildTimebar();
			calculateDimensions();
		},
	};
}
