var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Dictionary } from '@wcd/config';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
var CancelInvestigationModalComponent = /** @class */ (function (_super) {
    __extends(CancelInvestigationModalComponent, _super);
    function CancelInvestigationModalComponent(router, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.reasonsType = 'investigation';
        _this.selectedReasonName = '';
        _this.investigationCancelReasons = Object.freeze([
            { id: 23, name: _this.i18nService.strings.investigation_cancel_modal_reason_skipDevice },
            { id: 24, name: _this.i18nService.strings.investigation_cancel_modal_reason_duplicateInvestigation },
            { id: 25, name: _this.i18nService.strings.investigation_cancel_modal_reason_deviceRemoved },
            { id: 26, name: _this.i18nService.strings.investigation_cancel_modal_reason_falsePositive },
            { id: 27, name: _this.i18nService.strings.investigation_cancel_modal_reason_malfunction },
            { id: 28, name: _this.i18nService.strings.investigation_cancel_modal_reason_other, requireText: true },
        ]);
        _this.hostInvestigationCancelReasons = Object.freeze([
            { id: 33, name: "Microsoft Defender for Endpoint shouldn't analyze device (:hostName)" },
            { id: 35, name: 'Device (:hostName) was removed from the network' },
            { id: 37, name: 'This is a duplicated Investigation on device (:hostName)' },
            { id: 38, name: '(Other)', requireText: true },
        ]);
        return _this;
    }
    CancelInvestigationModalComponent.prototype.ngOnInit = function () {
        this.selectedReasonName = '';
        this.cancelReasons =
            this.reasonsType === 'host' ? this.getHostCancelReasons() : this.investigationCancelReasons;
        this.cancelReasonsDictionary = Dictionary.fromList(this.cancelReasons, 'name');
    };
    CancelInvestigationModalComponent.prototype.setCurrentSelectedReason = function () {
        this.selectedReason = this.cancelReasonsDictionary.get(this.selectedReasonName);
    };
    CancelInvestigationModalComponent.prototype.submitCancelInvestigation = function () {
        this.confirm({
            reason: this.selectedReason.id,
            comment: this.selectedReason.requireText ? this.comment : this.selectedReason.name,
        });
    };
    CancelInvestigationModalComponent.prototype.close = function () {
        this.cancel();
    };
    CancelInvestigationModalComponent.prototype.getHostCancelReasons = function () {
        var _this = this;
        var hostCancelReasons = cloneDeep(this.hostInvestigationCancelReasons);
        hostCancelReasons.forEach(function (cancelReason) { return (cancelReason.name = cancelReason.name.replace('(:hostName)', _this.hostName)); });
        return Object.freeze(hostCancelReasons);
    };
    return CancelInvestigationModalComponent;
}(ModalContainer));
export { CancelInvestigationModalComponent };
