import { EntityField, EntityModelBase, Entity, ModelBase, ValueObject } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationMachineStatus } from './evaluation-machine-status.entity';
import { EvaluationSimulatorStatus } from './evaluation-simulator-status.entity';

@ValueObject({
	singularName: 'Evaluation simulator status',
	pluralName: 'Evaluation simulators status',
	readonly: true,
})
export class EvaluationSimulatorsStatus extends ModelBase {
	@EntityField({ data: 'AttackIQ' })
	attackIQ: EvaluationSimulatorStatus;

	@EntityField({ data: 'SafeBreach' })
	safeBreach: EvaluationSimulatorStatus;
}

@Entity({
	singularName: 'Evaluation device',
	pluralName: 'Evaluation devices',
	endpoint: 'evaluation/machines',
	allItemsProperty: 'machines',
	loadAll: false,
	parseSaveQuery: (value: EvaluationMachine) => 'evaluation/machines',
	saveMethod: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class EvaluationMachine extends EntityModelBase<string> {
	@EntityField({ data: 'Index' })
	index: number;

	@EntityField({ data: ['Name', 'name'], parse: (machineName: string) => machineName.toLowerCase() })
	name: string;

	@EntityField({ data: 'MachineFqdn' })
	machineFqdn: string;

	@EntityField({ data: 'CreationTime' })
	creationTime: Date;

	@EntityField({ data: 'ProvisioningTime' })
	provisioningTime: Date;

	@EntityField({ data: 'PublicIp' })
	publicIp: string;

	@EntityField({ data: 'Status' })
	status: EvaluationMachineStatus;

	@EntityField({ data: 'SimulatorsStatus' })
	simulatorsStatus: EvaluationSimulatorsStatus;

	@EntityField({ data: ['Password', 'password'] })
	password: string;

	@EntityField({ data: 'SenseMachineId' })
	senseMachineId: string;

	@EntityField({
		data: 'MachineOS',
	})
	machineOs: EvaluationMachineOperatingSystem;

	@EntityField({
		data: 'ToolsToExclude',
	})
	toolsToExclude?: Array<EvaluationMachineTool>;

	@EntityField({
		data: 'DomainStatus',
	})
	domainStatus: DomainStatus;

	@EntityField({
		data: 'DomainName',
	})
	domainName: string;	
}

export enum EvaluationMachineOperatingSystem {
	Windows10 = 'windows10',
	Windows11 = 'windows11',
	WindowsServer2019 = 'windowsServer2019',
	LinuxUbuntuServer = 'LinuxUbuntuServer',
	WindowsServer2016 = 'WindowsServer2016',
}

export enum DomainStatus
{
	Workgroup = 'Workgroup',
	DomainJoined = 'DomainJoined',
	DomainController = 'DomainController'
}

export enum EvaluationMachineTool {
	Office = 'Office',
	Jre = 'Jre',
	Python = 'Python',
	SysInternals = 'SysInternals',
}
