import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmMcmSharedEntityConfigurations } from '../../mcmEndPointUtils';
import { BenchmarkDetails } from './benchmark-details.entity';

@Entity({
	...TvmMcmSharedEntityConfigurations,
	singularName: 'Setting',
	pluralName: 'Settings',
	endpoint: 'baseline-profiles/settings',
	readonly: true,
})
export class BaselineProfileSettings extends EntityModelBase<string> {
    @EntityField() readonly operatingSystem: string;
    
    @EntityField() readonly operatingSystemVersion: string;
    
	@EntityField() readonly benchmarks: BenchmarkDetails[];
}
