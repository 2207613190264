import { SevilleModule } from '../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.directive('deepAnalysisRequestId', deepAnalysisRequestIdDirective);

deepAnalysisRequestIdDirective.$inject = ['appConfig', 'authenticationService'];

function deepAnalysisRequestIdDirective(appConfig: AppConfigService, authenticationService) {
	var sampleCollectFailureToolTipHtml =
		'Try the following troubleshooting steps:' +
		'<ul>' +
		'<li>Verify that the devices reporting the file are online</li>' +
		"<li>Ensure that file still exists and it hasn't been corrupted or modified</li>" +
		'<li>Verify that the policy setting for file collection is enabled</li>' +
		'<li>You can wait a short while and try to submit the file again, in case there was a temporary connection or communication error</li>' +
		'</ul>';

	var fileCollectionInProgressToolTipHtml =
		'File collection from Windows 10 online devices is in progress. Operation will be canceled in 3 hours if collection fails.';
	var deepAnalysisInProgressToolTipHtml =
		'File analysis can take up to 15 minutes. The result of the analysis will be available when the analysis is completed.';

	var fileCantBeCollectedToolTipHtml =
		'Deep analysis is available if:' +
		'<ul>' +
		'<li>A file was reported from a Windows 10 onboarded device in the last 30 days or</li>' +
		'<li>A file exists in the Microsoft sample store</li>' +
		'</ul>';

	var fileTypeNotSupportedToolTipHtml =
		'Deep analysis currently supports analysis of portable executable (PE) files (for example, .exe and .dll files).';

	return {
		restrict: 'EA',
		scope: {
			sha1: '=',
			isPeFile: '=',
		},
		template: `
		<div class="row">
        <div class="col-xs-12 nopadding">
            <div class="deepanalysis-title">
                <span>Deep analysis</span>
            </div>
        </div>
    </div>
    <!-- Request -->
    <div class="row border-default">
        <div class="row-same-height">
            <div class="col-xs-4 col-xs-height col-middle">
                <b>Deep analysis request</b>
                <i class="icon deepanalysis-request-info-icon icon-WhatsThis"
                   tooltips tooltip-size="medium"
                   tooltip-side="right"
                   tooltip-html="Submitting file to deep analysis collects the file from the device or from Microsoft sample store if the file already exists.<br>Collecting the file can take up to 3 hours depending on file and device availability. The collected file is analyzed in a secured environment and a detailed report is created.">
                </i>
            </div>
            <div class="col-xs-2 col-xs-height col-middle">
                <img class="deepanalysis-request-title-item deepanalysis-request-progress-image img-responsive" ng-if="request.hasActiveDeepAnalysisSubmission()" src="/assets/images/linear-loader.gif" />
            </div>
            <div class="col-xs-6 col-xs-height text-right col-middle deepanalysis-request-no-right-padding">
                <i class="icon deepanalysis-request-status-icon icon-CheckMark" ng-if="request.showStatusIconType == 'complete'"></i>
                <i class="icon deepanalysis-request-status-icon icon-IncidentTriangle" ng-if="request.showStatusIconType == 'investigation'"
                   tooltips tooltip-size="medium"
                   tooltip-side="left"
                   tooltip-html="{{request.statusToolTipHtml}}"></i>
                <i class="icon deepanalysis-request-status-icon deepanalysis-request-status-icon-info icon-WhatsThis" ng-if="request.showStatusIconType == 'info'"
                   tooltips tooltip-size="medium"
                   tooltip-side="left"
                   tooltip-html="{{request.statusToolTipHtml}}"></i>
                <span class="deepanalysis-request-title-item">{{request.statusMessage}}</span>
                <button class="deepanalysis-request-submit-btn" ng-disabled="!request.enableDeepAnalysisSubmission()" ng-click="request.submitForDeepAnalysis()">{{request.status <= 0 ? 'Submit' : 'Resubmit'}}</button>
            </div>
        </div>
    </div>
		`,
		controller: [
			'$scope',
			'$http',
			'$log',
			'$timeout',
			'$element',
			'$rootScope',
			function($scope, $http, $log, $timeout, $element, $rootScope) {
				$scope.baseUrl = appConfig.serviceUrls.deepAnalysis;
				$scope.alertsInvestigationAllowed = authenticationService.isUserActionAllowed([
					RbacMdeAllowedActions.alertsInvestigation,
				]);
				$scope.request = {
					// Initialize the status with 'Init'
					status: -3,
					statusMessage: '',
					isSupportedType: $scope.isPeFile,
					getStatusInBackground: undefined,
					statusToolTipHtml: undefined,
					showStatusIconType: undefined,

					hasValidSha1: function() {
						return $scope.sha1 != null && $scope.sha1.length > 0;
					},

					hasActiveDeepAnalysisSubmission: function() {
						return (
							$scope.request.status === 1 ||
							$scope.request.status === 2 ||
							$scope.request.status === 5
						);
					},

					enableDeepAnalysisSubmission: function() {
						if (!$scope.request.hasValidSha1()) {
							// If we don't have a valid sha1, disable
							return false;
						}

						if (!$scope.alertsInvestigationAllowed) {
							// If the user has read-only access, disable
							return false;
						}

						// If there is a deep analysis in progress, disable
						if ($scope.request.hasActiveDeepAnalysisSubmission()) {
							return false;
						}

						// If a previous request is complete but not with sample collection error, enable
						if (
							$scope.request.status > 0 &&
							$scope.request.status !== 3 &&
							$scope.request.status !== 4
						) {
							return true;
						}

						// If we have the sample and the file type is unknown or supported, enable
						// Note, the null comparison will cover both undefined and null
						if (
							$scope.request.existsInSampleStore &&
							($scope.request.isSupportedType == null || $scope.request.isSupportedType)
						) {
							return true;
						}

						// If we have a telemetry for it and the type is supported, enable
						if ($scope.request.hasRecentReports && $scope.request.isSupportedType) {
							return true;
						}

						return false;
					},

					submitForDeepAnalysis: function() {
						// Do nothing if should be disabled
						if (!$scope.request.enableDeepAnalysisSubmission()) {
							return;
						}

						// Set the status to 'submitted'
						$scope.request.status = 1;
						var url = $scope.baseUrl + '/api/DeepAnalysisRequest';
						$log.debug('Submitting for deep analysis, sha1=' + $scope.sha1);

						// Post the actual request
						$http.post(url, JSON.stringify($scope.sha1)).then(
							function(response) {
								if (response.status === 200) {
									$log.debug(
										'Successfully submitted a deep analysis request, for sha1=' +
											$scope.sha1
									);
									$scope.request.status = response.data.RequestState;
									// Trigger a background refresh after a successful submission
									if (!$scope.request.getStatusInBackground) {
										$scope.request.getStatusInBackground = $timeout(
											getDeepAnalysisStatus,
											20000
										);
									} else {
										$log.error(
											'Bug, Background refresh is active during deep analysis submission'
										);
									}
								} else {
									$scope.request.status = -2;
									$log.error('Error occur while submitting deep analysis request');
								}
							},
							function() {
								// Set an error status for submitting deep analysis request
								$scope.request.status = -2;
								$log.error('Error occur while submitting deep analysis request');
							}
						);
					},
				};

				$scope.$watch(
					function() {
						return $scope.request.status;
					},
					function(newValue, oldValue) {
						// If there is no previous request, check whether we need to display file availability status
						if (newValue === 0) {
							// If sample is not available, override
							if (
								$scope.request.hasRecentReports !== undefined &&
								$scope.request.existsInSampleStore !== undefined &&
								!$scope.request.hasRecentReports &&
								!$scope.request.existsInSampleStore
							) {
								$scope.request.statusMessage = 'File can’t be collected';
								$scope.request.statusToolTipHtml = fileCantBeCollectedToolTipHtml;
								$scope.request.showStatusIconType = 'info';
								return;
							}

							// If file type is not supported, override
							// Note, the null comparison will cover both undefined and null
							if ($scope.request.isSupportedType != null && !$scope.request.isSupportedType) {
								$scope.request.statusMessage = 'File type not supported';
								$scope.request.statusToolTipHtml = fileTypeNotSupportedToolTipHtml;
								$scope.request.showStatusIconType = 'info';
								return;
							}
						}

						switch (newValue) {
							case -4:
								// No valid sha1
								$scope.request.statusMessage = 'File can’t be collected';
								$scope.request.statusToolTipHtml = fileCantBeCollectedToolTipHtml;
								$scope.request.showStatusIconType = 'info';
								break;
							case -3:
								// Init
								$scope.request.statusMessage = '';
								$scope.request.showStatusIconType = '';
								$scope.request.statusToolTipHtml = '';
								break;
							case -2:
								$scope.request.statusMessage =
									'Failed to submit deep analysis request - resubmit the file';
								$scope.request.showStatusIconType = 'investigation';
								$scope.request.statusToolTipHtml = '';
								break;
							case -1:
								$scope.request.statusMessage =
									'Failed to load deep analysis status - refresh the view';
								$scope.request.showStatusIconType = 'investigation';
								$scope.request.statusToolTipHtml = '';
								break;
							case 0:
								$scope.request.statusMessage = '';
								$scope.request.showStatusIconType = '';
								$scope.request.statusToolTipHtml = '';
								break;
							case 1:
								$scope.request.statusMessage = 'Processing request... (1 of 3)';
								$scope.request.showStatusIconType = '';
								$scope.request.statusToolTipHtml = '';
								break;
							case 2:
								$scope.request.statusMessage = 'Collecting file from devices... (2 of 3)';
								$scope.request.showStatusIconType = 'info';
								$scope.request.statusToolTipHtml = fileCollectionInProgressToolTipHtml;
								break;
							case 3:
							case 4:
								$scope.request.statusMessage =
									'Failed to collect the file - resubmit the file';
								$scope.request.showStatusIconType = 'investigation';
								$scope.request.statusToolTipHtml = sampleCollectFailureToolTipHtml;
								break;
							case 5:
								$scope.request.statusMessage = 'Analyzing file... (3 of 3)';
								$scope.request.showStatusIconType = 'info';
								$scope.request.statusToolTipHtml = deepAnalysisInProgressToolTipHtml;
								break;
							case 6:
								$scope.request.statusMessage =
									'Last deep analysis request failed - resubmit the file';
								$scope.request.showStatusIconType = 'investigation';
								$scope.request.statusToolTipHtml = '';
								break;
							case 7:
								$scope.request.statusMessage =
									'Last deep analysis timed out - resubmit the file';
								$scope.request.showStatusIconType = 'investigation';
								$scope.request.statusToolTipHtml = '';
								break;
							case 255:
								$scope.request.statusMessage = 'Results available';
								$scope.request.showStatusIconType = 'complete';
								$scope.request.statusToolTipHtml = '';
								break;
							default:
								$scope.request.statusMessage = '';
								$scope.request.statusToolTipHtml = '';
								$scope.request.showStatusIconType = '';
						}
					}
				);

				function getDeepAnalysisStatus() {
					if (!$scope.request.hasValidSha1()) {
						$log.debug('No valid sha1, skipping loading deep analysis status');
						// set a failure status
						$scope.request.status = -4;
						return;
					}

					var url = $scope.baseUrl + '/api/DeepAnalysisRequest';
					$log.debug('Loading deep analysis status, for sha1=' + $scope.sha1);
					$http
						.get(url, {
							params: {
								sha1: $scope.sha1,
							},
						})
						.then(
							function(response) {
								if (response.status === 200) {
									var data = response.data;
									$log.debug(
										'deep analysis status, for sha1=' +
											$scope.sha1 +
											' loaded successfully'
									);

									// If there is a previous 'inProgress' status and we are switching to completed successfully, reload the summary
									if (
										$scope.request.hasActiveDeepAnalysisSubmission() &&
										data.RequestState === 255
									) {
										$rootScope.$broadcast('deepanalysis.request.completed');
									}

									$scope.request.hasRecentReports = data.HasRecentSenseReports;
									$scope.request.existsInSampleStore = data.ExistsInSampleStore;

									// Update our file type knowledge only if there was Sense telemetry
									if (data.HasRecentSenseReports) {
										$scope.request.isSupportedType =
											$scope.request.isSupportedType || data.SupportedTypeBasedOnSense;
									}

									// Update the status last, to update the status message correctly
									$scope.request.status = data.RequestState;

									// If there is an 'inProgress' deep analysis, schedule another one after some delay
									if ($scope.request.hasActiveDeepAnalysisSubmission()) {
										$scope.request.getStatusInBackground = $timeout(
											getDeepAnalysisStatus,
											40000
										);
									} else {
										// Clear any existing background refresh promise
										$scope.request.getStatusInBackground = undefined;
									}
								} else {
									// Set an error status for getting deep analysis request status
									$scope.request.status = -1;
									delete $scope.request.statusToolTipHtml;
									delete $scope.request.showStatusIconType;
									delete $scope.request.hasRecentReports;
									delete $scope.request.isSupportedType;
									delete $scope.request.existsInSampleStore;
									$log.error(
										'Error occur while loading deep analysis status for sha1=' +
											$scope.sha1
									);
								}
							},
							function(response) {
								// Set an error status for getting deep analysis request status
								$scope.request.status = -1;
								delete $scope.request.statusToolTipHtml;
								delete $scope.request.showStatusIconType;
								delete $scope.request.hasRecentReports;
								delete $scope.request.isSupportedType;
								delete $scope.request.existsInSampleStore;
								$log.error(
									'Error occur while loading deep analysis status for sha1=' + $scope.sha1
								);
							}
						);
				}

				// listen on DOM destroy (removal) event, and cancel the next UI update
				// to prevent updating time after the DOM element was removed.
				$element.on('$destroy', function() {
					if ($scope.request.getStatusInBackground) {
						$timeout.cancel($scope.request.getStatusInBackground);
					}
				});

				getDeepAnalysisStatus();
			},
		],
	};
}
