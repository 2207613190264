import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Output,
	EventEmitter,
	ChangeDetectorRef,
	OnInit,
} from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
import { DataSet, ModelBase, ReadonlyRepository } from '@microsoft/paris';
import { DataviewField, DataViewConfig } from '@wcd/dataview';

@Component({
	selector: 'collapsible-items-preview',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './collapsible-items-preview.component.html',
})
export class CollapsibleItemsPreviewComponent<T extends ModelBase> implements OnInit {
	_disableSort: boolean;
	SpinnerSize = SpinnerSize;
	loaded = false;
	itemsCount: number;
	sortDisabledFieldsIds: Array<string>;
	hideTable: boolean;
	dataViewConfig: DataViewConfig;

	@Input() repository: ReadonlyRepository<T>;
	@Input() title: string;
	@Input() loadingText: string;
	@Input() maxItems: number;
	@Input() disableSort: boolean = true;
	@Input() fields: Array<DataviewField<T>>;
	@Input() defaultSortFieldId: string;
	@Input() initiallyCollapsed = true;
	@Input() dataviewLabel: string;
	@Input() disableSelection: boolean = false;

	@Output() showMore = new EventEmitter<void>();
	@Output() onItemClick = new EventEmitter<T>();
	@Output() export = new EventEmitter<void>();

	constructor(private changeDetectorRef: ChangeDetectorRef) {}

	ngOnInit() {
		const allFieldsIds = this.fields.map(field => field.id);
		this.dataViewConfig = {
			id: `relatedItemsDetails(${this.repository.entity.singularName}-_-${allFieldsIds.join()})`, //must supply unique id for correct dataview caching purposes - unique id is based on the fields
		};
		this.sortDisabledFieldsIds = this.disableSort ? allFieldsIds : [];
		this.hideTable = this.initiallyCollapsed;
	}

	onData(dataSet: DataSet<T>) {
		this.itemsCount = dataSet.count || dataSet.items.length;
		this.loaded = true;
		this.changeDetectorRef.markForCheck();
	}

	onCollapseChange(isCollapsed: boolean) {
		if (this.hideTable && !isCollapsed) {
			this.hideTable = false;
			this.changeDetectorRef.markForCheck();
		}
	}
}
