import { FabIconComponent } from '@angular-react/fabric';
import {
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Input,
	ViewChild,
	ViewContainerRef,
} from '@angular/core';
import { OnChanges, TypedChange, TypedChanges } from '@wcd/angular-extensions';
import { WcdIconComponent } from './wcd-icon.component';
import { isWcdIconName, WcdIconNames } from '@wcd/icons-font';
import { FabricIconNames, isFabricIconName } from '@wcd/scc-common';
import assertNever from 'assert-never';
import { memoize } from 'lodash-es';
import { IIconStyles } from 'office-ui-fabric-react';

export type IconType = 'wcd' | 'fabric';

@Component({
	// tslint:disable-next-line:component-selector
	selector: 'icon-switch',
	template: ` <ng-container #placeholder></ng-container> `,
})
export class IconSwitchComponent implements OnChanges<IconSwitchComponent> {
	@Input() iconName: FabricIconNames | WcdIconNames;
	@Input() contentClass?: string;
	@Input() ariaLabel?: string;
	@Input() styles?: IIconStyles;

	@ViewChild('placeholder', { read: ViewContainerRef, static: true })
	readonly placeholder: ViewContainerRef;

	private readonly _wcdIconComponentFactory: ComponentFactory<WcdIconComponent>;
	private readonly _fabricIconComponentFactory: ComponentFactory<FabIconComponent>;
	private _currentComponentRef: ComponentRef<FabIconComponent> | ComponentRef<WcdIconComponent>;

	constructor(private readonly cfResolver: ComponentFactoryResolver) {
		this._wcdIconComponentFactory = this.cfResolver.resolveComponentFactory(WcdIconComponent);
		this._fabricIconComponentFactory = this.cfResolver.resolveComponentFactory(FabIconComponent);

		// `inferIconType` is a pure function, so we can memoize it to make the code more readable - with call results being cached.
		this.inferIconType = memoize(this.inferIconType);
	}

	ngOnChanges(changes: TypedChanges<IconSwitchComponent>) {
		const wasChanged = <T>(change: TypedChange<T>) =>
			change && (change.firstChange || change.currentValue !== change.previousValue);

		const iconTypeChanged =
			wasChanged(changes.iconName) &&
			this.inferIconType(changes.iconName.currentValue) !==
				this.inferIconType(changes.iconName.previousValue);
		if (iconTypeChanged) {
			const newIconType = this.inferIconType(changes.iconName.currentValue);
			if (this._currentComponentRef) this._currentComponentRef.destroy();
			this._currentComponentRef = this.creatIconComponentRef(
				newIconType,
				this.iconName,
				this.contentClass,
				this.ariaLabel
			);
		}

		if (this._currentComponentRef) {
			if (this._currentComponentRef.componentType === FabIconComponent) {
				this.updateFabIcon(
					this._currentComponentRef as ComponentRef<FabIconComponent>,
					wasChanged(changes.iconName)
						? (changes.iconName.currentValue as FabricIconNames)
						: undefined,
					wasChanged(changes.contentClass) ? changes.contentClass.currentValue : undefined,
					wasChanged(changes.ariaLabel) ? changes.ariaLabel.currentValue : undefined,
					wasChanged(changes.styles) ? changes.styles.currentValue : undefined
				);
			} else if (this._currentComponentRef.componentType === WcdIconComponent) {
				this.updateWcdIcon(
					this._currentComponentRef as ComponentRef<WcdIconComponent>,
					wasChanged(changes.iconName)
						? (changes.iconName.currentValue as WcdIconNames)
						: undefined,
					wasChanged(changes.contentClass) ? changes.contentClass.currentValue : undefined,
					wasChanged(changes.ariaLabel) ? changes.ariaLabel.currentValue : undefined
				);
			}
			if (this._currentComponentRef.instance && this._currentComponentRef.instance['changeDetectorRef'])
				this._currentComponentRef.instance['changeDetectorRef'].markForCheck();
		}
	}

	private inferIconType(iconName: FabricIconNames | WcdIconNames): IconType {
		if (!iconName) {
			return null;
		}

		return isWcdIconName(iconName)
			? 'wcd'
			: isFabricIconName(iconName)
			? 'fabric'
			: assertNever(<never>iconName);
	}

	private creatIconComponentRef(
		iconType: IconType,
		iconName: FabricIconNames | WcdIconNames,
		className?: string,
		ariaLabel?: string,
		styles?: IIconStyles
	): ComponentRef<FabIconComponent> | ComponentRef<WcdIconComponent> {
		return iconType === 'fabric'
			? this.createFabIcon(iconName as FabricIconNames, className, ariaLabel, styles)
			: iconType === 'wcd'
			? this.createWcdIcon(iconName as WcdIconNames, className, ariaLabel)
			: assertNever(iconType);
	}

	private createFabIcon(
		iconName: FabricIconNames,
		className?: string,
		ariaLabel?: string,
		styles?: IIconStyles
	): ComponentRef<FabIconComponent> {
		const componentRef = this.placeholder.createComponent(this._fabricIconComponentFactory, 0);
		this.updateFabIcon(componentRef, iconName, className, ariaLabel, styles);
		return componentRef;
	}

	private updateFabIcon(
		componentRef: ComponentRef<FabIconComponent>,
		iconName?: FabricIconNames,
		className?: string,
		ariaLabel?: string,
		styles?: IIconStyles
	) {
		if (iconName !== undefined) {
			componentRef.instance.iconName = iconName;
		}

		if (className !== undefined) {
			componentRef.instance.contentClass = className as any; // type error in @angular-react/core, overriding until fixed
		}

		if (ariaLabel !== undefined) {
			componentRef.instance.ariaLabel = ariaLabel;
		}

		if (styles !== undefined) {
			componentRef.instance.styles = styles;
		}
	}

	private createWcdIcon(
		iconName: WcdIconNames,
		className?: string,
		ariaLabel?: string
	): ComponentRef<WcdIconComponent> {
		const componentRef = this.placeholder.createComponent(this._wcdIconComponentFactory, 0);
		this.updateWcdIcon(componentRef, iconName, className, ariaLabel);
		return componentRef;
	}

	private updateWcdIcon(
		componentRef: ComponentRef<WcdIconComponent>,
		iconName?: WcdIconNames,
		className?: string,
		ariaLabel?: string
	) {
		if (iconName !== undefined) {
			componentRef.instance.iconName = iconName;
		}

		if (className !== undefined) {
			componentRef.instance.className = className;
		}

		if (ariaLabel !== undefined) {
			(componentRef.location as ElementRef<HTMLElement>).nativeElement.setAttribute(
				'aria-label',
				ariaLabel
			);
		}
	}
}
