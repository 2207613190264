import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AirsEntitiesService } from '../services/airs-entities.service';
import {
	AirsEntity,
	AirsEntityStatus,
	AirsEntityTypeConfig,
	AirsRelatedEntity,
	AirsEntityRelationType,
	AirsEntityRelationTypeEntity,
} from '@wcd/domain';
import { RelatedEntitiesService } from '../services/related-entities.service';

@Component({
	selector: 'entity-relations',
	templateUrl: './entity-relations.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entity-relations.component.scss'],
})
export class EntityRelationsComponent implements OnInit {
	@Input() entity: AirsEntity;
	@Input() flatView: boolean = false;

	currentRelationType: AirsEntityRelationType;
	currentRelation: AirsRelatedEntity;

	relationTypeCounts: Map<AirsEntityRelationType, Array<{ status: AirsEntityStatus; count: number }>>;

	constructor(
		private entitiesService: AirsEntitiesService,
		private relatedEntitiesService: RelatedEntitiesService
	) {}

	ngOnInit() {
		if (this.entity.relations && this.entity.relations.length) {
			this.setImportantStatusCounts();
			const currentRelation = this.entity.relations[0];
			this.setCurrentRelationType(currentRelation.relationTypes[0], currentRelation);
		}
	}

	setCurrentRelationType(relationType: AirsEntityRelationType, relation: AirsRelatedEntity) {
		this.currentRelationType = relationType;
		this.currentRelation = relation;
	}

	addRelation(relatedEntityType: AirsEntityTypeConfig, relation: AirsEntityRelationTypeEntity) {
		this.relatedEntitiesService.showRelatedEntity(this.entity, relatedEntityType, relation.id);
	}

	setImportantStatusCounts() {
		this.relationTypeCounts = new Map();

		if (!this.entity.relations) return;

		this.entity.relations.forEach((relationEntity: AirsRelatedEntity) => {
			relationEntity.relationTypes.forEach((relationType: AirsEntityRelationType) => {
				const importantStatusesCount: {
					[index: string]: { status: any; count: number };
				} = {};

				relationType.entities.forEach((relatedEntity: AirsEntityRelationTypeEntity) => {
					if (relatedEntity.status.isImportant) {
						let status = importantStatusesCount[relatedEntity.status.id];
						if (!status)
							status = importantStatusesCount[relatedEntity.status.id] = {
								status: relatedEntity.status,
								count: 0,
							};

						status.count++;
					}
				});

				const statusCounts: Array<{ status: any; count: number }> = [];
				for (const statusId in importantStatusesCount) {
					statusCounts.push(importantStatusesCount[statusId]);
				}

				if (statusCounts.length) this.relationTypeCounts.set(relationType, statusCounts);
			});
		});
	}
}
