import { AfterViewInit, ComponentFactory, ComponentFactoryResolver, ComponentRef, Injector, OnChanges, OnDestroy, QueryList, ViewContainerRef, OnInit, EventEmitter, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import { DateRangeModel, getUserTimeZone, LocaleConfigService, resetToUTC, shiftTime, TzDateService, } from '@wcd/localization';
import { cloneDeep, flatMap, isEqual, merge } from 'lodash-es';
import { combineLatest, Subject } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { ReportModel } from '../models/report.model';
import { ReportsService, yesterday } from '../../shared-reports/services/reports.service';
import { ReportColumnComponent } from './report-column.component';
import { ReportRowComponent } from './report-row.component';
import { I18nService } from '@wcd/i18n';
import { PollingService } from '@wcd/config';
import moment from 'moment';
import { Panel, PanelType } from '@wcd/panels';
import { FabricIconNames } from '@wcd/scc-common';
import { Positions } from '@wcd/forms';
var dashboardFirstLoad = true;
var lastId = 0;
var ReportComponent = /** @class */ (function () {
    function ReportComponent(reportsService, appInsightsService, appConfigService, route, router, resolver, localeConfigService, i18nService, pollingService, tzDateService) {
        var _this = this;
        this.reportsService = reportsService;
        this.appInsightsService = appInsightsService;
        this.appConfigService = appConfigService;
        this.route = route;
        this.router = router;
        this.resolver = resolver;
        this.localeConfigService = localeConfigService;
        this.i18nService = i18nService;
        this.pollingService = pollingService;
        this.tzDateService = tzDateService;
        this.showControls = true;
        this.removePadding = false;
        this.removePaddingLeft = false;
        this.removePaddingRight = false;
        /**
         * Should polling errors be shown.
         * @default true
         */
        this.showPollingError = true;
        /**
         * Whether or not to show widget loaders when refreshing from GUI or on changes (doesn't affect polling)
         * @default false
         */
        this.showIndividualLoadersOnReload = false;
        this.titleDashboardId = "dashboard-title-container-" + lastId++;
        this.openMenuPosition = Positions.Default;
        this.smallScreenView = false;
        this.enableLargePadding = false;
        this.enableFocusOnFirstElement = true;
        this.onWidgetsLoaded = new EventEmitter();
        this.onReportRenderComplete = new EventEmitter();
        this.noData = false;
        this.isExporting = false;
        this.isLoading = true;
        this.isPollingErrorExist = false;
        this.appReady$ = new Subject();
        this.paddingTop = false;
        this.isDirty = false;
        this.icon = FabricIconNames.Calendar;
        this.widgetsLoadedSubject = new Subject();
        this._appReadyFired = false;
        this.createFormatLabel = function () {
            _this.formatLabel = function (value, isSelectionItem) {
                if (isSelectionItem === void 0) { isSelectionItem = false; }
                if (!isSelectionItem && _this.customRange && _this.currentTimeRange.allowCustomRange) {
                    _this.customRangeFromDisplay = _this.tzDateService.format(_this.customRange.from, 'shortDate');
                    _this.customRangeToDisplay = _this.tzDateService.format(_this.customRange.to, 'shortDate');
                    return _this.customRangeFromDisplay + "-" + _this.customRangeToDisplay;
                }
                return value.name;
            };
        };
        var PANEL_SETTINGS = new Panel({
            type: PanelType.medium,
            headerText: '',
            showOverlay: false,
            isBlocking: false,
            isModal: true,
            isStatic: false,
            noBodyPadding: true,
        });
        if (dashboardFirstLoad) {
            this.appInsightsService.appReadyListener$ = this.appReady$.asObservable();
            dashboardFirstLoad = false;
        }
        this.panelSettings = PANEL_SETTINGS;
        this.panelSettings.headerText = this.i18nService.get('common_daterange_panel_header');
    }
    Object.defineProperty(ReportComponent.prototype, "params", {
        get: function () {
            return this._params;
        },
        set: function (value) {
            this._params = merge({}, value, this.report.params);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "allWidgets", {
        get: function () {
            if (!this.columns)
                return [];
            return (flatMap(this.columns.toArray().map(function (column) { return column.allWidgets; }))).concat((flatMap(this.rows.toArray().map(function (row) { return row.allWidgets; }))));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ReportComponent.prototype, "showHeader", {
        get: function () {
            return !!(this.report.title || this.report.titleNameKey) || this.showControls;
        },
        enumerable: true,
        configurable: true
    });
    ReportComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.createFormatLabel();
        if (this.report.refreshOnQueryParamsChange !== false) {
            this._queryParamsChangeSubscription = this.route.queryParams
                .pipe(distinctUntilChanged(function (before, after) { return isEqual(before, after); }))
                .subscribe(function (params) {
                _this.setRange(params);
                _this.createFormatLabel();
                _this._params = merge({}, _this.params, params);
                _this.reloadWidgetsData();
            });
        }
    };
    ReportComponent.prototype.ngOnDestroy = function () {
        this._loadingSubscription && this._loadingSubscription.unsubscribe();
        this._pollingErrorSubscription && this._pollingErrorSubscription.unsubscribe();
        this._refreshSubscription && this._refreshSubscription.unsubscribe();
        this._queryParamsChangeSubscription && this._queryParamsChangeSubscription.unsubscribe();
        this.widgetsLoadedSubject.complete();
    };
    ReportComponent.prototype.ngOnChanges = function (changes) {
        if (changes.report) {
            this._params = merge({}, this._params, { range: this.reportsService.defaultTimeRange.value }, this.report.params);
            this.reportTitle = this.report.titleNameKey
                ? this.i18nService.get(this.report.titleNameKey)
                : this.report.title || '';
        }
        if (changes.dataOptions &&
            this.reportLoadStart &&
            !isEqual(changes.dataOptions.currentValue, changes.dataOptions.previousValue)) {
            this.reloadWidgetsData(this.showIndividualLoadersOnReload);
        }
    };
    ReportComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this.reportLoadStart = new Date();
        this._loadingSubscription = combineLatest(this.allWidgets.map(function (widgetComponent) { return widgetComponent.isLoadingData$; }))
            .pipe(map(function (widgetsLoading) {
            return widgetsLoading.some(function (isLoading) { return isLoading; });
        }), distinctUntilChanged(), tap(function (isLoading) {
            if (!isLoading) {
                // Hack suggested on Infra office hour, to wait until angular finish to render the loaded data
                setTimeout(function () {
                    _this.onRenderComplete();
                }, 0);
                if (_this.report.autoRefresh) {
                    _this._refreshSubscription && _this._refreshSubscription.unsubscribe();
                    _this._refreshSubscription = _this.pollingService
                        .poll(_this.report.refreshRate, _this.report.refreshRate)
                        .subscribe(function () { return _this.reloadData(); });
                }
            }
        }))
            .subscribe(function (isLoading) {
            _this.isLoading = isLoading;
            if (!isLoading) {
                if (!_this.widgetsLoaded) {
                    _this.widgetsLoaded = true;
                    if (_this.enableFocusOnFirstElement)
                        _this.widgetsLoadedSubject.next(true);
                    _this.onWidgetsLoaded.emit(true);
                }
                _this.lastLoadTime = new Date();
                var loadFinishedTime = new Date(), loadTime = loadFinishedTime.valueOf() - _this.reportLoadStart.valueOf();
                _this.appInsightsService.trackEvent('UI Report Load', { dashboard: _this.report.name }, { time: loadTime });
                if (!_this._appReadyFired && _this.appConfigService.appNavigateStartTime) {
                    _this.appReady$.next(loadFinishedTime.valueOf() - _this.appConfigService.appNavigateStartTime.valueOf());
                    _this.appReady$.complete();
                    _this.appReady$ = null;
                    _this._appReadyFired = true;
                }
            }
        });
        this._pollingErrorSubscription = combineLatest(this.allWidgets.map(function (widgetComponent) { return widgetComponent.pollingError$; }))
            .pipe(map(function (widgetsPollingError) {
            return widgetsPollingError.some(function (pollingError) { return pollingError; });
        }), distinctUntilChanged())
            .subscribe(function (isPollingErrorExist) { return (_this.isPollingErrorExist = isPollingErrorExist); });
        this.isEmpty$ = combineLatest(this.allWidgets.map(function (widgetComponent) { return widgetComponent.data$; })).pipe(map(function (widgetsData) { return widgetsData.every(function (widgetData) { return widgetData === null; }); }), distinctUntilChanged(), tap(function (isEmpty) { return _this.setEmptyComponent(isEmpty); }));
    };
    ReportComponent.prototype.reloadWidgetsData = function (showIndividualLoadersOnReload) {
        var _this = this;
        if (showIndividualLoadersOnReload === void 0) { showIndividualLoadersOnReload = false; }
        if (this.report.autoRefresh) {
            this._refreshSubscription && this._refreshSubscription.unsubscribe();
            this._refreshSubscription = this.pollingService
                .poll(0, this.report.refreshRate)
                .subscribe(function () { return _this.reloadData(showIndividualLoadersOnReload); });
        }
        else {
            this.reloadData(showIndividualLoadersOnReload);
        }
    };
    ReportComponent.prototype.reloadData = function (showIndividualLoadersOnReload) {
        var _this = this;
        if (showIndividualLoadersOnReload === void 0) { showIndividualLoadersOnReload = false; }
        this.reportLoadStart = new Date();
        this.allWidgets.forEach(function (widgetComponent) {
            return widgetComponent.reload(merge(_this.getCustomRangeParam(), _this.dataOptions), showIndividualLoadersOnReload);
        });
    };
    ReportComponent.prototype.getCustomRangeParam = function () {
        if (!this.customRange)
            return null;
        return {
            selection: {
                fromDate: this.customRange.from,
                toDate: this.customRange.to,
            },
        };
    };
    ReportComponent.prototype.reset = function () {
        this.allWidgets.forEach(function (widget) {
            widget.reset();
        });
        this.lastLoadTime = null;
    };
    ReportComponent.prototype.exportReport = function () {
        var _this = this;
        this.isExporting = true;
        this.reportsService
            .exportReport(this.report)
            .then(function () { return (_this.isExporting = false); }, function () { return (_this.isExporting = false); });
    };
    ReportComponent.prototype.onRangeSelect = function () {
        var rangeParamValue = this.currentTimeRange.allowCustomRange
            ? this.currentTimeRange.dateRange.toString()
            : this.currentTimeRange.value;
        this.router.navigate([], {
            queryParams: { range: rangeParamValue },
            relativeTo: this.route,
            queryParamsHandling: 'merge',
        });
    };
    ReportComponent.prototype.onRangeChange = function (timeRange) {
        this.currentTimeRange = timeRange;
        if (this.currentTimeRange.allowCustomRange) {
            this.togglePanel(true);
            this.originalCustomRange = cloneDeep(this.customRange);
        }
        else {
            this.togglePanel(false);
        }
        if (!timeRange.allowCustomRange)
            this.onRangeSelect();
    };
    ReportComponent.prototype.onCustomRangeChange = function () {
        this.isDirty = true;
    };
    ReportComponent.prototype.setRange = function (params) {
        var customRange = DateRangeModel.fromString(this.tzDateService, params.range, false);
        this.currentTimeRange =
            this.reportsService.getTimeRangeByValue(customRange ? 'custom' : params.range) ||
                this.reportsService.defaultTimeRange;
        if (customRange)
            this.reportsService.setCustomTimeRange(customRange, this.updateOptionsNeeded());
        else {
            this.reportsService.currentTimeRange = this.currentTimeRange;
            var toDate = this.getDefaultToDate(), fromDate = moment(toDate)
                .subtract(parseInt(this.currentTimeRange.value), 'days')
                .toDate();
            customRange = new DateRangeModel(this.tzDateService, fromDate, toDate);
        }
        var newTimeZone = params.timezone ? parseInt(params.timezone) : getUserTimeZone();
        if (this.reportsService.currentTimezone !== newTimeZone) {
            this.reportsService.currentTimezone = newTimeZone;
        }
        var timezone = this.reportsService.currentTimezone;
        var displayRange = customRange.clone();
        var displayTime = this.lastLoadTime ? new Date(this.lastLoadTime) : new Date();
        displayRange.resetToUTC();
        displayTime = resetToUTC(displayTime);
        if (timezone) {
            displayRange.shiftTimeByHours(timezone);
            displayTime = shiftTime(displayTime, { type: 'hours', amount: timezone });
        }
        this.reportDatesTitle = displayRange.format('shortDate', this.localeConfigService.selectedLocale);
        this.reportTimeTitle = this.tzDateService.format(displayTime, 'mediumTime');
        this.customRange = customRange;
    };
    ReportComponent.prototype.updateOptionsNeeded = function () {
        return !(this.report.id === 'threatProtectionTrendReport' ||
            this.report.id === 'machineProtectionTrendReport');
    };
    ReportComponent.prototype.getDefaultToDate = function () {
        return this.report.id === 'threatProtectionTrendReport' ||
            this.report.id === 'machineProtectionTrendReport'
            ? yesterday
            : new Date();
    };
    /**
     * If the report has a defined emptyDashboardComponent, adds the component to the empty container, so it's displayed instead of the dashboard's widgets
     */
    ReportComponent.prototype.setEmptyComponent = function (isEmpty) {
        if (!this.report.emptyDashboardComponent)
            return;
        this.emptyComponentPlaceholder.clear();
        if (isEmpty) {
            var injector = Injector.create({
                providers: [],
                parent: this.emptyComponentPlaceholder.parentInjector,
            }), factory = this.resolver.resolveComponentFactory(this.report.emptyDashboardComponent), emptyComponentRef = factory ? factory.create(injector) : null;
            this.emptyComponentPlaceholder.insert(emptyComponentRef.hostView);
        }
    };
    ReportComponent.prototype.togglePanel = function (panelOpen) {
        this.customRangePanelOpened = panelOpen;
    };
    ReportComponent.prototype.apply = function () {
        this.reportsService.setCustomTimeRange(this.customRange, this.updateOptionsNeeded());
        this.onRangeSelect();
        this.togglePanel(false);
        this.isDirty = false;
    };
    ReportComponent.prototype.cancel = function () {
        this.togglePanel(false);
        this.isDirty = false;
        this.customRange = cloneDeep(this.originalCustomRange);
    };
    ReportComponent.prototype.onRenderComplete = function () {
        this.onReportRenderComplete.emit();
    };
    return ReportComponent;
}());
export { ReportComponent };
