import { Component, ElementRef, EventEmitter, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { Positions } from '@wcd/forms';
import { DataViewModel } from '../../models/dataview.model';
import { DataviewActionButtonComponent } from './dataview-action-button.component';
import { DataviewActionColumnsCustomizationComponent } from './dataview-action-columns-customization.component';
import { DataviewActionFancySelectComponent } from './dataview-action-fancy-select.component';
import { DataviewActionFilterComponent } from './dataview-action-filter.component';
import { DataviewActionTypes } from './dataview-actions.model';


export interface DataViewActionElementButtonPicker {
	buttonElementPicker: ()=>HTMLElement;
}

@Component({
	selector: 'dataview-action-wrapper',
	template: `
		<ng-container [ngSwitch]="actionType">
			<dataview-action-content
				[ngClass]="actionClass"
				[contentActionConfig]="action"
				[smallScreenView]="smallScreenView"
				*ngSwitchCase="dataviewActionTypes.Content"
			>
			</dataview-action-content>

			<dataview-action-button
				[ngClass]="actionClass"
				[buttonActionConfig]="action"
				[smallScreenView]="smallScreenView"
				[isInMoreMenu]="isMoreActionsList"
				*ngSwitchCase="dataviewActionTypes.Button"
				#focusableDataviewAction
			>
			</dataview-action-button>

			<dataview-action-columns-customization
				[ngClass]="actionClass"
				[openMenuPosition]="position"
				[dataViewModel]="dataViewModel"
				[smallScreenView]="smallScreenView"
				[columnsCustomizationActionConfig]="action"
				*ngSwitchCase="dataviewActionTypes.ColumnsCustomization"
				#focusableDataviewAction
			>
			</dataview-action-columns-customization>

			<dataview-action-fancy-select
				[ngClass]="actionClass"
				[openMenuPosition]="position"
				[smallScreenView]="false"
				[fancySelectActionConfig]="action"
				[isInMoreMenu]="isMoreActionsList"
				*ngSwitchCase="dataviewActionTypes.FancySelect"
				#focusableDataviewAction
			>
			</dataview-action-fancy-select>

			<ng-container *ngSwitchCase="dataviewActionTypes.Filter">
				<dataview-action-filter
					*ngIf="dataViewModel.allowFilters"
					[ngClass]="actionClass"
					[dataViewModel]="dataViewModel"
					[smallScreenView]="smallScreenView"
					[filterActionConfig]="action"
					[isInMoreMenu]="isMoreActionsList"
					#focusableDataviewAction
				>
				</dataview-action-filter>
			</ng-container>

			<dataview-action-search
				[smallScreenView]="smallScreenView"
				[hidden]="isMoreActionsList"
				[ngClass]="isMoreActionsList ? 'dataview-action-hidden' : 'dataview-action'"
				class="dataview-action-search"
				[dataViewModel]="dataViewModel"
				[searchActionConfig]="action"
				*ngSwitchCase="dataviewActionTypes.Search"
				#focusableDataviewAction
			>
			</dataview-action-search>

			<dataview-action-custom-range
				[openMenuPosition]="position"
				[smallScreenView]="smallScreenView"
				class="dataview-action"
				[ngClass]="actionClass"
				[customRangeActionConfig]="action"
				*ngSwitchCase="dataviewActionTypes.CustomRange"
				#focusableDataviewAction
			>
			</dataview-action-custom-range>
		</ng-container>
	`,
})

export class DataviewActionWrapperComponent {
	@Input() actionClass: string = '';
	@Input() position: Positions = Positions.Left;
	@Input() action: DataviewActionFancySelectComponent;
	@Input() isMoreActionsList: boolean;
	@Input() smallScreenView: boolean;
	@Input() actionType: DataviewActionTypes;
	@Input() dataViewModel: DataViewModel;

	dataviewActionTypes = DataviewActionTypes;

	_dropdownButtonRef: ElementRef;
	get dropdownButtonRef() {
		if (!this._dropdownButtonRef) {
			this._dropdownButtonRef = new ElementRef(this.focusable.first.buttonElementPicker());
		}
		return this._dropdownButtonRef;
	}

	@ViewChildren('focusableDataviewAction') focusable: QueryList<DataViewActionElementButtonPicker>;

	get nativeElement() {
		return this.dropdownButtonRef.nativeElement;
	}
}
