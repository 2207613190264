import {
	Entity,
	EntityField,
	EntityModelBase,
	ParisConfig,
	HttpOptions,
	DataQuery,
	queryToHttpOptions,
} from '@microsoft/paris';
import { WcdPortalParisConfig } from './../paris-config.interface';
import { CustomTiIndicatorActionsTypes } from '../custom_ti_indicators/custom-ti-indicators-actions-type';

@Entity({
	singularName: 'Policy',
	pluralName: 'Policies',
	endpoint: 'webcategory/policies',
	saveMethod: (item: WebContentFilteringPolicy, config?: ParisConfig) => (item.id ? 'PUT' : 'POST'),
	loadAll: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	parseSaveQuery: (item: WebContentFilteringPolicy, entity, config, options?: HttpOptions) =>
		'webcategory/policy',
	parseDataQuery: (query: DataQuery) => {
		if (!query) return {};

		if (query.where && query.where['blockedCategories']) {
			const params: { [index: string]: any } = {};
			Object.keys(query.where)
				.filter(key => key !== 'blockedCategories')
				.forEach(key => {
					params[key] = query.where[key];
				});

			params['IndicatorValue'] = query.where['blockedCategories'];
			params['Action'] = CustomTiIndicatorActionsTypes.AlertAndBlock;

			return params;
		}

		return queryToHttpOptions(query).params;
	},
	allItemsEndpointTrailingSlash: false,
	separateArrayParams: true,
})
export class WebContentFilteringPolicy extends EntityModelBase<string> {
	@EntityField({ data: 'PolicyName' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'PolicyName' })
	policyName: string;

	@EntityField({ data: 'BlockedCategoryIds' })
	blockedCategoryIds: Array<number>;

	@EntityField({ data: 'AuditCategoryIds' })
	auditCategoryIds: Array<number>;

	@EntityField({ data: 'RbacGroupIds' })
	rbacGroupIds: Array<number>;

	@EntityField({ data: 'CreatedBy' })
	createdBy: string;

	@EntityField({ data: 'LastUpdateTime' })
	lastUpdateTime: Date;

	@EntityField({ data: 'IndicatorValueIdMappings' })
	indicatorValueIdMappings: { [key: number]: number };
}
