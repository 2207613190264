import { ChangeDetectorRef, Component, ComponentRef, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Alert, Incident, IncidentAlertsRelationship } from '@wcd/domain';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { Subscription } from 'rxjs';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RbacService } from '../../../rbac/services/rbac.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertsService } from '../../alerts/services/alerts.service';
import { AppContextService } from '@wcd/config';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { DataviewField } from '@wcd/dataview';
import { PanelSettings, PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { IncidentAlertsPanelComponent } from './incident.alerts.panel.component';

enum CollapsibleID {
	ImpactedEntities = 'incident-entity-impacted-entities',
	Alerts = 'incident-entity-alerts',
}

@Component({
	selector: 'incident-entity-panel',
	templateUrl: './incident.entity-panel.component.html',
})
export class IncidentEntityPanelComponent
	extends EntityPanelComponentBase<Incident, AlertEntityDetailsOptions>
	implements OnDestroy, OnInit {
	currentTab: string;
	collapsibleID = CollapsibleID;
	incidentAlertsRepository: RelationshipRepository<Incident, Alert>;
	partialRelatedAlerts: Array<Alert>;
	relatedAlerts: Array<Alert>;
	fields:	Array<DataviewField<Alert>>;
	isItemSelectable = () => false;
	maxAlerts = 5;
	alertsComponentPanel: ComponentRef<IncidentAlertsPanelComponent>;

	get incident(): Incident {
		return this.entity;
	}

	private _onActionSubscription: Subscription;
	isMtpEnabled: boolean = this.appContextService.isMtp;

	constructor(
		private alertsService: AlertsService,
		private paris: Paris,
		private rbacService: RbacService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private appContextService: AppContextService,
		changeDetectorRef: ChangeDetectorRef,
		private alertsFields: AlertsFields,
		private dialogService: DialogsService,
	) {
		super(changeDetectorRef);
		const fieldNames =  ['title', 'severity', 'status'];

		this.fields = (this.appContextService.isMtp ? alertsFields.mtpFields : alertsFields.allFields)
			.filter(field => fieldNames.includes(field.id))
			.map(field => new DataviewField <Alert>({...field, sort: {enabled: false}}))
	}

	ngOnInit() {
		super.ngOnInit();

		this._onActionSubscription = this.action$.subscribe(
			($event: { action: ItemActionModel; data: any }) => this.onAction($event.action, $event.data)
		);

		this.setAlertsRepository(this.entity);
	}

	ngOnDestroy() {
		this._onActionSubscription && this._onActionSubscription.unsubscribe();
		if (this.alertsComponentPanel) {
			this.alertsComponentPanel.destroy();
		}
	}

	private onAction(action: ItemActionModel, data: any) {
		this.requestEntitiesRefresh.emit();
		this.changeDetectorRef.markForCheck();
	}

	protected initEntity(entity: Incident, isExtendedData: boolean = false) {
		super.initEntity(entity);
		this.changeDetectorRef.markForCheck();
	}

	private setAlertsRepository(incident: Incident) {
		if (incident.alertCount) {
			this.incidentAlertsRepository = this.paris.getRelationshipRepository(IncidentAlertsRelationship);
			this.incidentAlertsRepository.sourceItem = incident;
			this.incidentAlertsRepository
				.query()
				.subscribe((response) => {
					this.relatedAlerts = response.items;
					this.partialRelatedAlerts = this.relatedAlerts.splice(0, this.maxAlerts);
					this.changeDetectorRef.markForCheck();
				})
		}
	}

	openAlerts() {
		const panelSettings: PanelSettings = Object.assign({
			id: 'incident-alert-panel',
			type: PanelType.large,
			showOverlay: true,
			isBlocking: false,
			scrollBody: true,
			back: {
				onClick: () => {
					this.alertsComponentPanel.destroy()
				}
			},
		});
		this.dialogService.showPanel(IncidentAlertsPanelComponent, panelSettings, { incident: this.entity, alerts: this.relatedAlerts})
			.subscribe((panel: ComponentRef<IncidentAlertsPanelComponent>) => {
			this.alertsComponentPanel = panel;

			panel.onDestroy(() => {
				this.alertsComponentPanel = null;
			});
		});
	}
}

export interface AlertEntityDetailsOptions {
	showInvestigation: boolean;
	showMachine: boolean;
	showVariables: boolean;
}
