import { InvestigationStatus, Alert, InvestigationStatusEnum, Investigation } from '@wcd/domain';
import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnInit,
	OnDestroy,
	ChangeDetectorRef,
} from '@angular/core';
import { Paris } from '@microsoft/paris';
import { tap, map, mergeMap } from 'rxjs/operators';
import { of, Subscription } from 'rxjs';
import { PollingService } from '@wcd/config';

interface StatusInfo {
	ready: boolean;
	investigationId: number;
	investigationStatus: InvestigationStatus;
}

@Component({
	selector: 'alert-investigation-status',
	template: `
		<div *ngIf="statusInfo.ready; else loader">
			<wcd-shared-icon [iconName]="statusInfo.investigationStatus.iconName"> </wcd-shared-icon>
			<wcd-help
				[text]="
					'investigations.status.' + statusInfo.investigationStatus.type + '.description' | i18n
				"
			>
				{{ statusInfo.investigationStatus.name }}
			</wcd-help>
		</div>
		<ng-template #loader>
			<div>
				<i class="large-loader-icon"></i>
			</div>
		</ng-template>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertInvestigationStatusComponent implements OnInit, OnDestroy {
	@Input() alertId: string;
	private subscription: Subscription;

	statusInfo: StatusInfo = {
		ready: false,
		investigationId: -1,
		investigationStatus: this.paris.getValue(InvestigationStatus, InvestigationStatusEnum.queued),
	};

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private readonly pollService: PollingService
	) {}

	ngOnInit() {
		this.subscription = this.pollService
			.poll(0, 60 * 1000)
			.pipe(
				mergeMap(() => {
					if (this.statusInfo.investigationId !== -1) {
						return this.paris.getItemById(Investigation, this.statusInfo.investigationId).pipe(
							map((investigation: Investigation) => {
								return {
									ready: true,
									investigationId: investigation.id,
									investigationStatus: investigation.status,
								};
							})
						);
					}
					return this.paris.getItemById(Alert, this.alertId).pipe(
						map((alert: Alert) => {
							return {
								ready: true,
								investigationId: alert.investigationId,
								investigationStatus: alert.investigationStatus,
							};
						})
					);
				})
			)
			.subscribe((statusInfo: StatusInfo) => {
				const status = statusInfo.investigationStatus;
				if (!status.isPending && !status.isRunning && statusInfo.investigationId !== -1) {
					this.clearSubscription();
				}
				this.statusInfo = statusInfo;
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnDestroy(): void {
		this.clearSubscription();
	}

	private clearSubscription() {
		if (this.subscription) {
			this.subscription.unsubscribe();
			this.subscription = undefined;
		}
	}
}
