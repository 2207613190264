var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { RelationshipRepository, ModelBase, Paris } from '@microsoft/paris';
import { Asset, TvmEndPoint, SecurityRecommendation, RecommendationContextType, RecommendationExposedNetworkDeviceRelationship, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { Observable } from 'rxjs';
import { RecommendationContextComponent } from '../../../@entities/@tvm/security-recommendations/components/recommendation-context.component';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';
import { RecommendationContextFieldsService } from '../../../@entities/@tvm/security-recommendations/services/recommendation-context.fields.service';
import { TvmRecommendationContextService } from '../../../tvm/services/tvm-recommendation-context.service';
var ExposedAssetsPanelComponent = /** @class */ (function (_super) {
    __extends(ExposedAssetsPanelComponent, _super);
    function ExposedAssetsPanelComponent(i18nService, productivityImpactService, tvmDownloadService, tvmTextsService, paris, fieldsService, recommendationContextFieldsService, recommendationContextService, router, featuresService, globalEntityTypesService) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.productivityImpactService = productivityImpactService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.tvmTextsService = tvmTextsService;
        _this.paris = paris;
        _this.fieldsService = fieldsService;
        _this.recommendationContextFieldsService = recommendationContextFieldsService;
        _this.recommendationContextService = recommendationContextService;
        _this.featuresService = featuresService;
        _this.isUserProductivityImpact = false;
        _this.nestedComponent = {
            type: RecommendationContextComponent,
            getProps: function (asset) { return ({ asset: asset }); },
        };
        _this.entityType = globalEntityTypesService.getEntityType(Asset);
        _this.isExportEnabled = featuresService.isEnabled(Feature.TvmExportCsvFromSidePanel);
        _this.isRecommendationContextSupported = featuresService.isEnabled(Feature.TvmRecommendationContext);
        return _this;
    }
    // TODO: Review with Shai VSTS[25319843]
    ExposedAssetsPanelComponent.prototype.getGroupItems = function (asset) {
        return new Observable(function (observer) {
            var assets = [];
            observer.next(assets);
        });
    };
    Object.defineProperty(ExposedAssetsPanelComponent.prototype, "sourceValue", {
        get: function () {
            return this._sourceValue;
        },
        set: function (value) {
            this._sourceValue = value;
            if (value instanceof SecurityRecommendation &&
                this.productivityImpactService.isProductivityImpactExposed &&
                !value.oneAsset &&
                !!value.assetsStatistics.nonProductivityImpactedAssets) {
                // Adding the productivity impact column only to exposed assets with a context of only recommendations
                // which had populated the nonProductivityImpactedAssets -> on get exposed assets it will have the productivity impact values for them
                this.isUserProductivityImpact = true;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExposedAssetsPanelComponent.prototype, "exposedAssetsTitle", {
        get: function () {
            return this._exposedAssetsTitle;
        },
        set: function (value) {
            this._exposedAssetsTitle = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ExposedAssetsPanelComponent.prototype, "repository", {
        set: function (value) {
            this._repository = this.paris.getRelationshipRepository(value);
        },
        enumerable: true,
        configurable: true
    });
    ExposedAssetsPanelComponent.prototype.ngOnInit = function () {
        this.isRecommendationContextNeededInCSV = this.recommendationContextService.isRecommendationContextNeededInCSV(this.recommendationContextType);
        var addRecommendationContextFields = this.isRecommendationContextSupported &&
            this.recommendationContextType &&
            this.recommendationContextType !== RecommendationContextType.Unknown;
        if (this.isUserProductivityImpact) {
            this.fields = this.fieldsService.exposedAssetsUserImpactFields;
        }
        else {
            this.fields = this.isNetworkGear
                ? this.fieldsService.exposedDevicesFields
                : this.fieldsService.exposedAssetsFields;
        }
        var allFieldsIds = this.fields.map(function (field) { return field.id; });
        this.dataViewConfig = {
            id: "exposedAssets(" + allFieldsIds.join() + ")",
        };
        if (addRecommendationContextFields) {
            this.fields = this.fields.filter(function (field) { return field.id !== 'recommendationContextCount'; });
            this.fields.push(this.recommendationContextFieldsService.getRecommendationContextCountField(this.recommendationContextType));
            if (!this.fields.find(function (field) { return field.id === 'expand'; })) {
                this.fields.unshift(this.recommendationContextFieldsService.getRecommendationContextTableExpandField());
            }
        }
        if (this.isNetworkGear) {
            this.networkDeviceRelationshipRepository = this.paris.getRelationshipRepository(RecommendationExposedNetworkDeviceRelationship);
            if (this._sourceValue instanceof SecurityRecommendation) {
                this.networkDeviceRelationshipRepository.sourceItem = this._sourceValue;
            }
        }
    };
    ExposedAssetsPanelComponent.prototype.showNestedComponent = function (asset) {
        return asset.recommendationContext.length > 0;
    };
    ExposedAssetsPanelComponent.prototype.exportCveData = function () {
        if (this.isNetworkGear) {
            this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.networkDeviceRelationshipRepository, TvmEndPoint.Analytics, this.exposedAssetsTitle + " - " + this.i18nService.strings.tvm_common_exposedDevices, undefined);
        }
        else {
            this.tvmDownloadService.downloadCsvFromRelationshipRepository(this._repository, TvmEndPoint.Analytics, this.exposedAssetsTitle + " - " + this.i18nService.strings.tvm_common_exposedDevices, {
                where: {
                    includeProductivityColumn: this.isUserProductivityImpact,
                },
            }, { isRecommendationContextNeededInCSV: this.isRecommendationContextNeededInCSV });
        }
    };
    return ExposedAssetsPanelComponent;
}(PanelContainer));
export { ExposedAssetsPanelComponent };
