
		<action-history-base-panel
			[entity]="entity"
			[options]="options"
			[isInit]="isInit"
			[moreActionsFilter]="moreActionsFilter"
			[moreActionsEnabled]="moreActionsEnabled"
			(requestMetadataRefresh)="requestMetadataRefresh.emit()"
		>
		</action-history-base-panel>
	