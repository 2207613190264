import { I18nService } from '@wcd/i18n';
import { HealthStatus, OsPlatform, OsVersion, AvStatus } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { KnownColorsService } from '../shared/services/known-colors.service';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i2 from "../shared/services/known-colors.service";
import * as i3 from "../../../../../projects/config/src/lib/services/features.service";
var MachineProtectionStatusMapping = /** @class */ (function () {
    function MachineProtectionStatusMapping(i18nService, knownColorsService, featuresService) {
        var _a, _b, _c, _d;
        this.i18nService = i18nService;
        this.knownColorsService = knownColorsService;
        this.featuresService = featuresService;
        this._healthStatusMapping = (_a = {},
            _a[HealthStatus.Active] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['green'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.machineReport.healthStatus.active"),
                machineListFilter: 'Active',
            },
            _a[HealthStatus.ImpairedCommunication] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['orangeLighter'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.machineReport.healthStatus.impairedCommunication"),
                machineListFilter: 'MisconfiguredCyberNoCnc',
            },
            _a[HealthStatus.Inactive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['red'],
                    trend: this.knownColorsService.knownColorsMap['red'],
                },
                label: this.i18nService.get("reporting.machineReport.healthStatus.inactive"),
                machineListFilter: 'Inactive',
            },
            _a[HealthStatus.NoSensorData] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['neutralTertiary'],
                    trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
                },
                label: this.i18nService.get("reporting.machineReport.healthStatus.noSensorData"),
                machineListFilter: 'MisconfiguredCncNoCyber',
            },
            _a);
        this._avStatusMapping = (_b = {},
            _b[AvStatus.Updated] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['green'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.machineReport.antivirusStatus.updated"),
                machineListFilter: '',
                priority: 1,
            },
            _b[AvStatus.NotUpdated] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['orangeLighter'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.machineReport.antivirusStatus.notUpdated"),
                machineListFilter: 'AntiVirusSignatureVersion',
                priority: 2,
            },
            _b[AvStatus.Disabled] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['red'],
                    trend: this.knownColorsService.knownColorsMap['red'],
                },
                label: this.i18nService.get("reporting.machineReport.antivirusStatus.disabled"),
                machineListFilter: 'AntiVirusEnabled',
                priority: 3,
            },
            _b[AvStatus.Unknown] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['neutralTertiary'],
                    trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
                },
                label: featuresService.isEnabled(Feature.UseTvmMachinesAvStatus) ? this.i18nService.get("reporting.machineReport.antivirusStatus.unknownNew") :
                    this.i18nService.get("reporting.machineReport.antivirusStatus.unknown"),
                machineListFilter: 'AntiVirusNotReporting',
                priority: 4,
            },
            _b);
        this._osPlatformMapping = (_c = {},
            _c[OsPlatform.Windows11] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['greenLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows11"),
                machineListFilter: 'Windows11',
            },
            _c[OsPlatform.Windows10] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['purpleLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows10"),
                machineListFilter: 'Windows10',
            },
            _c[OsPlatform.Windows8Blue] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows8blue"),
                machineListFilter: 'Windows8Blue',
            },
            _c[OsPlatform.Windows8] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['tealLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows8"),
                machineListFilter: 'Windows8',
            },
            _c[OsPlatform.Windows7] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['blue'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows7"),
                machineListFilter: 'Windows7',
            },
            _c[OsPlatform.WindowsServer2022] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orangeLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windowsServer2022"),
                machineListFilter: 'WindowsServer2022',
            },
            _c[OsPlatform.WindowsServer2019] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magenta'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windowsServer2019"),
                machineListFilter: 'WindowsServer2019',
            },
            _c[OsPlatform.WindowsServer2016] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windowsServer2016"),
                machineListFilter: 'WindowsServer2016',
            },
            _c[OsPlatform.WindowsServer2012R2] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['purple'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windowsServer2012R2"),
                machineListFilter: 'WindowsServer2012R2',
            },
            _c[OsPlatform.WindowsServer2008R2] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windowsServer2008R2"),
                machineListFilter: 'WindowsServer2008R2',
            },
            _c[OsPlatform.Windows10WVD] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['blueLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.windows10WVD"),
                machineListFilter: 'Windows10WVD',
            },
            _c[OsPlatform.Linux] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.linux"),
                machineListFilter: 'Linux',
            },
            _c[OsPlatform.macOS] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['teal'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.macOS"),
                machineListFilter: 'macOS',
            },
            _c[OsPlatform.iOS] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.iOS"),
                machineListFilter: 'iOS',
            },
            _c[OsPlatform.Android] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['greenDark'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.android"),
                machineListFilter: 'Android',
            },
            _c[OsPlatform.Other] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['blueMid'],
                },
                label: this.i18nService.get("reporting.machineReport.osPlatform.other"),
                machineListFilter: 'WindowsXp,None,Windows2003,Windows2008,Windows2016,Windowsvista,Windows8blueserver,Windows8server,Windowsxpembedded,Windows2012',
            },
            _c);
        this._osVersionMapping = (_d = {},
            _d[OsVersion.RS1] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magenta'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs1"),
                machineListFilter: '1607',
            },
            _d[OsVersion.RS2] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['tealLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs2"),
                machineListFilter: '1703',
            },
            _d[OsVersion.RS3] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['purpleLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs3"),
                machineListFilter: '1709',
            },
            _d[OsVersion.RS4] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['purple'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs4"),
                machineListFilter: '1803',
            },
            _d[OsVersion.RS5] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs5"),
                machineListFilter: '1809',
            },
            _d[OsVersion.RS6] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs6"),
                machineListFilter: '1903',
            },
            _d[OsVersion.RS7] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['blue'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs7"),
                machineListFilter: '1909',
            },
            _d[OsVersion.RS8] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs8"),
                machineListFilter: '2004',
            },
            _d[OsVersion.RS9] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs9"),
                machineListFilter: '20H2',
            },
            _d[OsVersion.RS10] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['greenLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs10"),
                machineListFilter: '21H1',
            },
            _d[OsVersion.RS11] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['blueLight'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.rs11"),
                machineListFilter: '21H2',
            },
            _d[OsVersion.Future] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralTertiary'],
                },
                label: this.i18nService.get("reporting.machineReport.osVersion.future"),
                machineListFilter: 'Future',
            },
            _d);
    }
    Object.defineProperty(MachineProtectionStatusMapping.prototype, "healthStatusMapping", {
        get: function () {
            return this._healthStatusMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineProtectionStatusMapping.prototype, "avStatusMapping", {
        get: function () {
            return this._avStatusMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineProtectionStatusMapping.prototype, "osPlatformMapping", {
        get: function () {
            return this._osPlatformMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MachineProtectionStatusMapping.prototype, "osVersionMapping", {
        get: function () {
            return this._osVersionMapping;
        },
        enumerable: true,
        configurable: true
    });
    MachineProtectionStatusMapping.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MachineProtectionStatusMapping_Factory() { return new MachineProtectionStatusMapping(i0.ɵɵinject(i1.I18nService), i0.ɵɵinject(i2.KnownColorsService), i0.ɵɵinject(i3.FeaturesService)); }, token: MachineProtectionStatusMapping, providedIn: "root" });
    return MachineProtectionStatusMapping;
}());
export { MachineProtectionStatusMapping };
