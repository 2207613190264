import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Advanced features',
	pluralName: 'Advanced features',
	endpoint: 'GetAdvancedFeaturesSetting',
	parseItemQuery: () => 'GetAdvancedFeaturesSetting',
	parseSaveQuery: () => 'SaveAdvancedFeaturesSetting',
	saveMethod: 'POST',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.settings,
})
export class AdvancedFeatures extends EntityModelBase {
	@EntityField({ data: 'AatpIntegrationEnabled', defaultValue: false })
	aatpIntegrationEnabled: boolean;

	@EntityField({ data: 'AatpWorkspaceExists', defaultValue: false })
	aatpWorkspaceExists: boolean;

	@EntityField({ data: 'EnableWdavAuditMode', defaultValue: false })
	enableWdavAuditMode: boolean;

	@EntityField({ data: 'EnableWdavPassiveModeRemediation', defaultValue: false })
	enableWdavPassiveModeRemediation: boolean;

	@EntityField({ data: 'BlockListEnabled', defaultValue: false })
	blockListEnabled: boolean;

	@EntityField({ data: 'LicenseEnabled', defaultValue: false })
	licenseEnabled: boolean;

	@EntityField({ data: 'O365ToAtpIntegrationEnabled', defaultValue: false })
	o365ToAtpIntegrationEnabled: boolean;

	@EntityField({ data: 'OfficeIntegrationEnabled', defaultValue: false })
	officeIntegrationEnabled: boolean;

	@EntityField({ data: 'OfficeLicenseEnabled', defaultValue: false })
	oOfficeLicenseEnabled: boolean;

	@EntityField({ data: 'ShowUserAadProfile', defaultValue: false })
	showUserAadProfile: boolean;

	@EntityField({ data: 'SkypeIntegrationEnabled', defaultValue: false })
	skypeIntegrationEnabled: boolean;

	@EntityField({ data: 'DartDataCollection', defaultValue: false })
	dartDataCollection: boolean;

	@EntityField({ data: 'AutoResolveInvestigatedAlerts', defaultValue: false })
	autoResolveInvestigatedAlerts: boolean;

	@EntityField({ data: 'EnableMcasIntegration', defaultValue: false })
	enableMcasIntegration: boolean;

	@EntityField({ data: 'EnableAipIntegration', defaultValue: false })
	enableAipIntegration: boolean;

	@EntityField({ data: 'EnableWdavAntiTampering', defaultValue: false })
	enableWdavAntiTampering: boolean;

	@EntityField({ data: 'AllowWdavNetworkBlock', defaultValue: false })
	allowWdavNetworkBlock: boolean;

	@EntityField({ data: 'EnableBuiltInProtection', defaultValue: false })
	enableBuiltInProtection: boolean;
	
	@EntityField({ data: 'EnableBuiltInProtectionDates', defaultValue: "" })
	enableBuiltInProtectionDates: string;

	@EntityField({ data: 'WebCategoriesEnabled', defaultValue: false })
	webCategoriesEnabled: boolean;

	@EntityField({ data: 'EnableEndpointDlp', defaultValue: false })
	endpointDlpEnabled: boolean;

	@EntityField({ data: 'EnableAuditTrail', defaultValue: false })
	enableAuditTrail: boolean;

	@EntityField({ data: 'EnableQuarantinedFileDownload', defaultValue: false })
	enableQuarantinedFileDownload: boolean;

	@EntityField({ data: 'MagellanOptOut', defaultValue: false })
	magellanOptOut: boolean;

	@EntityField({ data: 'IsolateIncidentsWithDifferentDeviceGroups', defaultValue: false })
	isolateIncidentsWithDifferentDeviceGroups: boolean;
}
