import { Directive, ElementRef, Injector } from '@angular/core';
import '../../upgrade-router-link.directive';
import './services/seville.services.umd';
import './sidepane/seville.sidepane';
import '../threatintel/threatintel.umd';
import '../common/filters/seville.filters.umd';
import '../common/directives/seville.common.directives.umd';
import '../../management/management.umd';
import '../../daas/daas.umd';

import { SevilleModule } from './seville.module';
import { UpgradeComponent } from '@angular/upgrade/static';
import { AppConfigService } from '@wcd/app-config';
import { MainAppState, MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';

SevilleModule.component('sevilleApp', {
	template: `
	<div class="wcd-full-height relative"
		data-track-component="SevilleApp"
		 ng-class="{ 'seville-app': app.isOnboardingComplete, 'onboarding-app': !app.isOnboardingComplete }"
		 ng-if="app.appStateService.isSeville">
    <div id="global-alert" class="alert alert-danger ng-cloak" role="alert" ng-if="app.globalAlertMsg">
      <span class="glyphicon glyphicon-exclamation-sign" aria-hidden="true"></span>
      <span class="sr-only">Alert: </span>
      {{ app.globalAlertMsg }}
    </div>
    <div class="wcd-full-height container-fluid nopadding nomargin">
      <div class="wcd-full-height wrapper ng-cloak"
           id="seville-main-contents"
           ng-class="{'content-section-side-pane-opened': app.sidePaneOpened }">

        <!-- Content container -->

        <div class="wcd-full-height container-fluid"
             ng-class="{
             'content-section-side-pane-opened': app.sidePaneOpened,
             'content-section-side-pane-closed': !app.sidePaneOpened }">

          <div class="wcd-full-height row">
            <div class="wcd-full-height col-xs-12">
              <div ui-view="main" class="wcd-full-height" id="main-site-container" ng-if="!app.globalAlertMsg" ng-class="{'with-breadcrumbs' : app.isBreadcrumbsShown}"></div>
            </div>
          </div>
        </div>
      </div>
      <aside ng-controller="seville.sidepane as pane">
          <div id="side-pane" ng-class="{ 'side-pane-opened': app.sidePaneOpened, 'side-pane-closed': !app.sidePaneOpened }">

          <div ng-if="pane.opened" class="side-pane-container">
              <div class="side-pane-header">
                  <i class="icon side-pane-icon {{pane.instance.icon}}" ng-if="pane.instance.icon"></i>
                  <span class="side-pane-title {{pane.instance.titleStyle}}" ng-if="!pane.instance.link">{{pane.instance.paneTitle}}</span>
                  <a class="side-pane-title side-pane-title-link {{pane.instance.titleStyle}}"
                  	data-track-id="SevillePanelTitleLink"
				   data-track-type="Navigation"
				   ng-href="{{pane.resolveLink(pane.instance.link)}}"
                  	ng-if="pane.instance.link">{{pane.instance.paneTitle}}</a>
                  <i class="icon icon-Cancel pointer side-pane-dismiss-btn" ng-click="pane.close()" tabindex="0"></i>
              </div>
              <div class="side-pane-loading text-center" ng-if="pane.instance.loading">
            <img src="/assets/images/circle-loading.gif" />
              </div>
              <div class="side-pane-body" ng-if="!pane.instance.loading && !pane.instance.errorMsg" ng-switch="pane.instance.type">
                  <div ng-switch-when="entity">
                      <ng-include src="'/side-pane-entity-section.html'" class="side-pane-include" onload="section = pane.instance" ng-if="pane.instance.props.length"></ng-include>
                      <ng-include src="'/side-pane-entity-section.html'" class="side-pane-include" ng-repeat="section in pane.instance.sections track by $index"></ng-include>
                  </div>
                  <div ng-switch-when="content">
                      <div dyn-controller template="pane.instance.template" controller="pane.instance.controller" class="side-pane-include"></div>
                  </div>
                  <div class="side-pane-link" ng-if="pane.instance.link">
                      <a ng-href="{{pane.resolveLink(pane.instance.link)}}"
					  data-track-id="SevilleSidePaneLink"
						data-track-type="Navigation">{{pane.instance.link.text}}</a>
                  </div>
              </div>
              <div class="side-pane-body" ng-if="pane.instance.errorMsg">
                  {{pane.instance.errorMsg}}
              </div>
          </div>
      </div>

      <script type="text/ng-template" id="/side-pane-entity-section.html">
          <div class="side-pane-section">
              <div class="side-pane-section-title-container side-pane-title-margin" ng-if="section.title">
                  <table class="side-pane-section-title-table">
                      <tr>
                          <td>
                              <span class="side-pane-section-title" ng-if="section.title">{{section.title}}</span>
                              <span class="side-pane-section-subtitle" ng-if="section.subtitle">{{section.subtitle}}</span>
                          </td>
                          <td class="col-top">
                              <button class="btn btn-link dropdown-toggle side-pane-section-title-button" data-toggle="dropdown" aria-expanded="true" type="button" ng-click="pane.toggleSection(section)" ng-disabled="actionStatus.loading">
                                  <span ng-if="!section.closed" class="icon side-pane-title-button-icon icon-ChevronUp"></span>
                                  <span ng-if="section.closed" class="icon side-pane-title-button-icon icon-ChevronDown"></span>
                              </button>
                          </td>
                      </tr>
                  </table>
              </div>
              <div class="side-pane-section-animate" ng-if="!section.closed">
                  <div class="side-pane-section-loading text-center" ng-if="section.loading">
                      <img src="/assets/images/circle-loading.gif" />
                  </div>
                  <table class="side-pane-props" ng-if="!section.loading && !section.errorMsg">
                      <tr class="side-pane-property" ng-repeat="prop in section.props | filter: pane.expectValueFilter">
                          <td class="side-pane-property-key" ng-if="prop.type != 'scrollable-block' && prop.type != 'button' && prop.type != 'clickable'">{{prop.key}}:</td>
                          <td class="side-pane-property-value" ng-if="prop.type != 'scrollable-block'">
                              <i class="icon {{prop.icon}}" ng-if="prop.icon" />
                              <span ng-switch="prop.type" class="{{prop.style}}">
                                  <span ng-switch-when="activeAlerts">
                                      <span class="side-pane-property-active-alert sev-high">{{prop.value.high || 0}}</span>
                                      <span class="side-pane-property-active-alert sev-medium">{{prop.value.medium || 0}}</span>
                                      <span class="side-pane-property-active-alert sev-low">{{prop.value.low || 0}}</span>
                                      <span class="side-pane-property-active-alert sev-informational">{{prop.value.informational || 0}}</span>
                                  </span>
                                  <span ng-switch-when="timeago">
                                      <span time-ago utc="{{prop.value}}"></span>
                                  </span>
                                  <span ng-switch-when="primitive">
                                      {{prop.value}}
                                  </span>
                                  <span ng-switch-when="date">
                                      <date src="prop.value" type="date"></date>
                                  </span>
                                  <span ng-switch-when="datetime">
                                      <date src="prop.value"></date>
                                  </span>
                                  <span ng-switch-when="link" >
                                      <i ng-if="::prop.value.icon" class="icon icon-{{prop.value.icon}} nopadding"></i>
                                      <a ng-href="{{pane.resolveLink(prop.value)}}"
                                      	data-track-id="SevilleSidePaneInnerLink"
	   									data-track-type="Navigation"
                                      	ng-attr-target="{{prop.value.newWindow ? '_blank' : null}}">
                                          {{prop.value.text}}
                                      </a>
                                  </span>
                                  <span ng-switch-when="button">
                                      <span>
                                          <machine-tags tags="prop.value.userTags" can-remove-tags="false" read-only-tags="prop.value.builtInTags"></machine-tags>
                                      </span>
                                      <span ng-click="pane.handleButtonClick(prop)"
										  data-track-id="tagsButton"
										  data-track-type="Button"
                                      		class="pointer machine-tag-button">
                                          <i ng-if="::prop.value.icon" class="icon {{prop.value.icon}} machine-tag-icon-enabled"></i>
                                          {{prop.value.text}}
                                      </span>
								  </span>
								  <span ng-switch-when="tags">
									  <ul class="tags-list" ng-if="prop.value.tags">
										  <li ng-repeat="tag in prop.value.tags" class="tag tag-builtin">
										  	<span class="tag-text" tooltips tooltip-title="{{tag}}" tooltip-side="left">{{tag | limitTo:30}}{{(tag.length > 30 ? '...': '')}}</span>
										  </li>
									  </ul>
								  </span>
                                  <ng-bind-html ng-switch-when="clickable" ng-bind-html="prop.value.html" ng-click="pane.handleButtonClick(prop)"></ng-bind-html>
                                  <span ng-switch-when="scrollable-block"></span> <!-- just so it won't fail-->
                                  <span ng-switch-when="copy-block"></span> <!-- just so it won't fail-->
                                  <span ng-switch-default>
                                      {{prop.type}} is not a supported property type
                                  </span>
                              </span>
                              <div ng-if="prop.type == 'copy-block'">
                                  <side-pane-copy-block key="prop.key" text=prop.value />
                              </div>
                              <span ng-if="prop.link">
                                  <a class="hero-button" ng-href="{{pane.resolveLink(prop.link)}}"
                                  	data-track-id="SevilleSidePanePropLink"
	   								data-track-type="Navigation"
	   								aria-label="Property link"
                                  	ng-attr-target="{{prop.link.newWindow ? '_blank' : null}}">
                                      <i class="icon icon-{{prop.link.icon}} side-pane-link-icon"></i>
                                  </a>
                              </span>
                          </td>
                          <td colspan="2" ng-if="prop.type == 'scrollable-block'">
                              <div class="side-pane-property-key-full-line">{{prop.key}}:</div>
                              <div class="side-pane-property-scrollable-block">{{prop.value}}</div>
                          </td>
                      </tr>
                  </table>
                  <div ng-if="section.errorMsg" class="side-pane-error-msg">
                      {{section.errorMsg}}
                  </div>
              </div>
          </div>
      </script>
      </aside>
    </div>
  </div>
	`,
	controller: sevilleAppController,
	controllerAs: 'app',
});

@Directive({
	selector: 'seville-app',
})
export class SevilleAppDirective extends UpgradeComponent {
	constructor(elementRef: ElementRef, injector: Injector) {
		super('sevilleApp', elementRef, injector);
	}
}

sevilleAppController.$inject = [
	'$rootScope',
	'authenticationService',
	'appConfig',
	'appStateService',
	'mainAppStateService',
	'featuresService',
];

function sevilleAppController(
	$rootScope,
	authenticationService,
	appConfig: AppConfigService,
	appStateService,
	mainAppStateService: MainAppStateService,
	featuresService
) {
	const vm = this;

	this.isOnboardingComplete = appConfig.isOnboardingComplete;
	this.appStateService = appStateService;
	this.isBreadcrumbsShown = false;

	mainAppStateService.state$
		.pipe(
			filter((state: MainAppState) => {
				return this.isBreadcrumbsShown !== state.showBreadcrumbs;
			})
		)
		.subscribe((state: MainAppState) => {
			this.isBreadcrumbsShown = state.showBreadcrumbs;
		});

	this.$onInit = function() {
		vm.loggedInUser = authenticationService.loggedInUser();
		vm.isReadOnlyUser = authenticationService.isReadOnlyUser();

		$rootScope.$on('appState:globalalert', function(event, payload) {
			vm.globalAlertMsg = payload.msg;
		});

		$rootScope.$on('sidePane:paneStateUpdated', function(event, payload) {
			vm.sidePaneOpened = payload.opened;
		});
	};
}
