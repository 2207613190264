import { scheduleConfig } from '../services/airs-notification-rule-schedule-config.service';
import { AirsReportTypes } from '../services/airs-report-types.service';
import { AirsNotificationRuleConfig } from './airs-notification-rule.model';
import { find } from 'lodash-es';

export class AirsNotificationRuleReportSchedule {
	reportTypes: Array<number> = AirsReportTypes.ALL.map(_report => _report.id);
	range: NameValue = scheduleConfig.ranges[1];
	repeats: IRepeats = scheduleConfig.repeats[1];
	repeatEvery: number = 1;
	repeatDays: Array<number> = [0];
	time: string = '09:00';

	get isValid(): boolean {
		if (!this.reportTypes.length) return false;

		if (!this.range || !this.repeats || !this.repeatEvery || !this.time) return false;

		if (this.repeats.requireDays && !this.repeatDays.length) return false;

		return true;
	}

	get reportTypeValues() {
		return this.reportTypes.map(reportTypeId => AirsReportTypes.getReportTypeById(reportTypeId));
	}

	get repeatDaysValues() {
		return this.repeatDays.map(_day => scheduleConfig.repeatOn[_day]);
	}

	constructor(data?: AirsNotificationRuleConfig) {
		if (data) {
			this.reportTypes = data.report_type;
			this.range = find(scheduleConfig.ranges, _range => _range.value === data.report_period);
			this.repeats = find(scheduleConfig.repeats, _repeats => _repeats.value === data.repeat_period);
			this.repeatEvery = data.repeats;
			this.repeatDays = data.repeat_day;
			this.time = data.time;
		}
	}

	getBackendData(): AirsNotificationRuleReportScheduleConfig {
		const data: AirsNotificationRuleReportScheduleConfig = {
			report_type: this.reportTypes,
			report_period: this.range.value,
			repeats: this.repeatEvery,
			repeat_period: this.repeats.value,
			time: this.time,
		};

		if (this.repeats.requireDays) data.repeat_day = this.repeatDays;

		return data;
	}
}

export interface AirsNotificationRuleReportScheduleConfig {
	report_type: Array<number>;
	report_period: number;
	repeats: number;
	repeat_period: number;
	repeat_day?: Array<number>;
	time: string;
	time_offset?: number;
}

interface NameValue {
	value: number;
	name: string;
}

interface IRepeats extends NameValue {
	requireDays: boolean;
}
