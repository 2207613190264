import { ActivatedRoute } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var EntityIdRouteService = /** @class */ (function () {
    function EntityIdRouteService(activatedRoute) {
        this.activatedRoute = activatedRoute;
    }
    EntityIdRouteService.prototype.getRouteId = function (_a) {
        var _b = _a === void 0 ? {} : _a, _c = _b.route, route = _c === void 0 ? this.activatedRoute.snapshot : _c, _d = _b.itemIdParamName, itemIdParamName = _d === void 0 ? 'id' : _d;
        return route.data.entityIdResolver
            ? route.data.entityIdResolver(route)
            : route.params[itemIdParamName];
    };
    EntityIdRouteService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EntityIdRouteService_Factory() { return new EntityIdRouteService(i0.ɵɵinject(i1.ActivatedRoute)); }, token: EntityIdRouteService, providedIn: "root" });
    return EntityIdRouteService;
}());
export { EntityIdRouteService };
