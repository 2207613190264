<tags-list *ngIf="!autoOverflow else overflowTags"
		   [tags]="localTags"
		   [editable]="false"
		   [maxItems]="maxItems"
		   [orientation]="orientation">
</tags-list>
<ng-template #overflowTags>
	<overflow-tags-list [tags]="localTags">
	</overflow-tags-list>
</ng-template>
