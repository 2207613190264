/**
 * This used to be in Scripts/site.js
 * That file added global vars and violated native prototypes, so it had to be killed with fire.
 * @type {{}}
 */
export let SevilleUtils = {
	date: {
		addTZOffset: function(date: Date) {
			const hoursDifference = (date.getTimezoneOffset() * -1) / 60;
			date.setHours(
				date.getHours() + hoursDifference,
				date.getMinutes(),
				date.getSeconds(),
				date.getMilliseconds()
			);
			return date;
		},
		endOfDay: function(date: Date) {
			date.setHours(23, 59, 59, 999);
			return date;
		},
	},
	detectEdge: function() {
		var userAgent = window.navigator.userAgent;
		return userAgent.indexOf('Edge') >= 0;
	},
	detectIE: function() {
		var ua = window.navigator.userAgent;

		// Test values; Uncomment to check result …

		// IE 10
		// ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

		// IE 11
		// ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

		// Edge 12 (Spartan)
		// ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

		// Edge 13
		// ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

		var msie = ua.indexOf('MSIE ');
		if (msie > 0) {
			// IE 10 or older => return version number
			return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
		}

		var trident = ua.indexOf('Trident/');
		if (trident > 0) {
			// IE 11 => return version number
			var rv = ua.indexOf('rv:');
			return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
		}

		var edge = ua.indexOf('Edge/');
		if (edge > 0) {
			// Edge (IE 12+) => return version number
			return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
		}

		// other browser
		return false;
	},
	groupBy: function(array, func) {
		const groups = {};
		array.forEach(function(o) {
			const group = JSON.stringify(func(o));
			groups[group] = groups[group] || [];
			groups[group].push(o);
		});

		return Object.keys(groups).map(function(group) {
			return groups[group];
		});
	},
};
