import { Component, ViewEncapsulation } from '@angular/core';
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { LiveResponseSession, Machine } from '@wcd/domain';
import { map, startWith, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { LiveResponseService } from '../../../@entities/live_response/services/live-response.service';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';

@Component({
	template: `
		<div class="wcd-flex-vertical fix-top-margin">
			<span *ngLet="(connectionData$ | async) as connectionData" class="wcd-padding-none-vertical padding-vertical-sm-screens wcd-font-size-s">
				<span class="wcd-badge" [ngClass]="'color-box-' + connectionData.className"> </span>
				<span class="wcd-font-weight-bold">
					{{ connectionData.text }}
				</span>
			</span>
		</div>
	`,
	styleUrls: ['./live-response-session.header-bottom.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class LiveResponseSessionHeaderBottomComponent extends EntityHeaderBottomComponentBase<
	LiveResponseSession
> {
	connectionData$: Observable<{ className: string; text: string }>;
	connectionStatus: string;

	constructor(
		protected readonly globalEntityTypesService: GlobalEntityTypesService,
		public readonly i18nService: I18nService,
		private readonly liveResponseService: LiveResponseService,
		private readonly entityPanelsService: EntityPanelsService,
		private readonly liveAnnouncer: LiveAnnouncer,
	) {
		super(globalEntityTypesService, i18nService);
	}

	setEntity(entity) {
		super.setEntity(entity);
		this.connectionData$ = this.liveResponseService.getSessionUntilDone(entity).pipe(
			tap(session => {
				super.setEntity(session);
			}),
			startWith(entity),
			map(session => {
				const tempStatus = this.i18nService.strings[`liveResponse_status_${session.status.type}`] || session.status.name
				if (this.connectionStatus != tempStatus){
					this.connectionStatus = tempStatus;
					this.liveAnnouncer.announce(this.connectionStatus);
				}
				return {
					className: session.status.className,
					text:
						this.i18nService.strings[`liveResponse_status_${session.status.type}`] ||
						session.status.name,
				};
			})
		);
	}

	openMachinePanel() {
		this.entityPanelsService.showEntity(Machine, this.entity.machine);
	}
}
