var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
export var SampleRequestStatus;
(function (SampleRequestStatus) {
    SampleRequestStatus[SampleRequestStatus["Failed"] = -1] = "Failed";
    SampleRequestStatus[SampleRequestStatus["Active"] = 0] = "Active";
    SampleRequestStatus[SampleRequestStatus["Completed"] = 1] = "Completed";
})(SampleRequestStatus || (SampleRequestStatus = {}));
var SampleRequestInfo = /** @class */ (function (_super) {
    __extends(SampleRequestInfo, _super);
    function SampleRequestInfo() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'RequestGuid' }),
        __metadata("design:type", String)
    ], SampleRequestInfo.prototype, "requestGuid", void 0);
    __decorate([
        EntityField({ data: 'Sha1' }),
        __metadata("design:type", String)
    ], SampleRequestInfo.prototype, "sha1", void 0);
    __decorate([
        EntityField({ data: 'OrgId' }),
        __metadata("design:type", String)
    ], SampleRequestInfo.prototype, "orgId", void 0);
    __decorate([
        EntityField({ data: 'Requestor' }),
        __metadata("design:type", String)
    ], SampleRequestInfo.prototype, "requestor", void 0);
    __decorate([
        EntityField({ data: 'RequestorComment' }),
        __metadata("design:type", String)
    ], SampleRequestInfo.prototype, "requestorComment", void 0);
    __decorate([
        EntityField({ data: 'Status' }),
        __metadata("design:type", Number)
    ], SampleRequestInfo.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'CreationTime' }),
        __metadata("design:type", Date)
    ], SampleRequestInfo.prototype, "creationTime", void 0);
    __decorate([
        EntityField({ data: 'LastUpdateTime' }),
        __metadata("design:type", Date)
    ], SampleRequestInfo.prototype, "lastUpdateTime", void 0);
    SampleRequestInfo = __decorate([
        ValueObject({
            singularName: 'Sample request info',
            pluralName: '',
            readonly: true,
        })
    ], SampleRequestInfo);
    return SampleRequestInfo;
}(ModelBase));
export { SampleRequestInfo };
