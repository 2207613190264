import { sccHostService } from '@wcd/scc-interface';
import { MtpPermission, tenantContextCache } from '@wcd/auth';
import { AppConfigService } from '@wcd/app-config';
var AppContextService = /** @class */ (function () {
    function AppContextService(appConfigService) {
        this.appConfigService = appConfigService;
    }
    Object.defineProperty(AppContextService.prototype, "isMtp", {
        /**
         * Determines if the app is running in 'MTP' mode
         */
        get: function () {
            return sccHostService.isSCC && tenantContextCache.hasMtpConsent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppContextService.prototype, "isSCC", {
        get: function () {
            return sccHostService.isSCC;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppContextService.prototype, "isMdatpActive", {
        /**
         * Determines if MDATP is active.
         * If the app is running in SCC - check isMdatpActive flag; Otherwise the app is running in MDATP portal - return true
         */
        get: function () {
            return !this.isSCC || this.appConfigService.isMdatpActive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppContextService.prototype, "isOatpActive", {
        /**
         * Determines if OATP is active.
         */
        get: function () {
            return this.isSCC && this.appConfigService.isOatpActive;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppContextService.prototype, "hasOatpReadPermissions", {
        get: function () {
            return this.appConfigService.oatpMtpPermissions
                && this.appConfigService.oatpMtpPermissions.includes(MtpPermission.SecurityData_Read);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppContextService.prototype, "isAadIpActive", {
        get: function () {
            return this.isSCC && this.appConfigService.isAadIpActive;
        },
        enumerable: true,
        configurable: true
    });
    AppContextService.prototype.getCurrentAppPrefix = function () {
        if (this.isSCC) {
            return 'mdatp-scc-';
        }
        return '';
    };
    return AppContextService;
}());
export { AppContextService };
