/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i2 from "../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i3 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "./scheduled-hunting-run-time.component";
var styles_ScheduledHuntingRunTimeComponent = [];
var RenderType_ScheduledHuntingRunTimeComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScheduledHuntingRunTimeComponent, data: {} });
export { RenderType_ScheduledHuntingRunTimeComponent as RenderType_ScheduledHuntingRunTimeComponent };
function View_ScheduledHuntingRunTimeComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵpid(131072, i1.TzDatePipe, [i2.TzDateService, i3.LocaleConfigService, i0.ChangeDetectorRef])], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 1, 0, i0.ɵnov(_v, 2).transform(_co.runTime, "medium")); _ck(_v, 1, 0, currVal_0); }); }
function View_ScheduledHuntingRunTimeComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), "hunting_scheduledMonitorSidePane_fields_huntingRuleFrequency_continuous")); _ck(_v, 0, 0, currVal_0); }); }
export function View_ScheduledHuntingRunTimeComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ScheduledHuntingRunTimeComponent_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["continuousRule", 2]], null, 0, null, View_ScheduledHuntingRunTimeComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.rule.intervalHours !== 0); var currVal_1 = i0.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_ScheduledHuntingRunTimeComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "scheduled-hunting-run-time", [], null, null, null, View_ScheduledHuntingRunTimeComponent_0, RenderType_ScheduledHuntingRunTimeComponent)), i0.ɵdid(1, 49152, null, 0, i7.ScheduledHuntingRunTimeComponent, [], null, null)], null, null); }
var ScheduledHuntingRunTimeComponentNgFactory = i0.ɵccf("scheduled-hunting-run-time", i7.ScheduledHuntingRunTimeComponent, View_ScheduledHuntingRunTimeComponent_Host_0, { rule: "rule", runTime: "runTime" }, {}, []);
export { ScheduledHuntingRunTimeComponentNgFactory as ScheduledHuntingRunTimeComponentNgFactory };
