import { SevilleModule } from '../../../../../app/seville/seville.module';
import { AppConfigService } from '@wcd/app-config';
import { downloadPackageTexts } from '../../commononboarding/services/seville.downloadPackageTexts';
import { OMS_SETUP_MODAL_TEMPLATE } from './seville.settings.download.omssetupmodal';
import { AppInsightsService } from '../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.controller('seville.settings.download', settingsDownloadController);

SevilleModule.config([
	'$stateProvider',
	function($stateProvider) {
		// register url route
		$stateProvider.state('download', {
			url: '/download/:type',
			title: 'Device management',
			views: {
				main: {
					template: DOWNLOAD_PAGES_TEMPLATE,
					controllerProvider: [
						'$stateParams',
						function($stateParams) {
							var type = $stateParams.type.toLowerCase();
							if (type == 'nonwindows') return 'seville.settings.nonwindows as nonwindows';
							else return 'seville.settings.download as download';
						},
					],
				},
			},
		});
	},
]);

settingsDownloadController.$inject = [
	'$window',
	'appConfig',
	'$http',
	'authenticationService',
	'$uibModal',
	'featuresService',
	'appInsights',
	'seville.settings.clientsideutils',
	'$stateParams',
];

function settingsDownloadController(
	$window,
	appConfig: AppConfigService,
	$http,
	authenticationService,
	$modal,
	featuresService,
	appInsights: AppInsightsService,
	clientSideUtils,
	$stateParams
) {
	var vm = this;
	vm.type = $stateParams.type.toLowerCase();
	vm.isSuspended = appConfig.isSuspended;
	vm.baseUrl =
		appConfig.serviceUrls && appConfig.isSuspended
			? appConfig.serviceUrls.authorization
			: appConfig.serviceUrls.management;
	vm.serverOnboardingUrl = appConfig.serviceUrls.serveronboarding;
	vm.offboardingMinimized = true;
	vm.isreadonlyuser = !authenticationService.isUserActionAllowed([RbacMdeAllowedActions.securitySettings]);
	vm.detectionTestCode =
		'pow' +
		'ers' +
		'hell.exe -NoExit -Ex' +
		'ecutionPolicy Byp' +
		'ass -WindowStyle Hidden ' +
		"$ErrorActionPreference= 'silentlycontinue';" +
		'(New-Object System.Net.WebClient).Down' +
		'loa' +
		"dFile('http://127.0.0.1/1.exe', 'C:\\\\test-WDATP-test\\\\invoice.exe');Start-Process 'C:\\\\test-WDATP-test\\\\invoice.exe'";

	vm.demoOnboardingScriptEnabled = featuresService.isEnabled('DemoOnboardingScript');
	vm.vdiEndpointManagementFeatureEnabled = featuresService.isEnabled('VdiEndpointManagement');
	vm.serverEndpointManagementFeatureEnabled = featuresService.isEnabled('ServerEndpointManagement');
	vm.sccm1606PackageTitle = downloadPackageTexts['SCCM1606'].title;
	vm.sccm1606PackageDescription = downloadPackageTexts['SCCM1606'].description;
	vm.packageType = vm.sccm1606PackageTitle;
	vm.offboardingPackageType = vm.sccm1606PackageTitle;

	var localScriptDemoTitle = downloadPackageTexts['LocalScriptDemo'].title;
	var localScriptTitle = downloadPackageTexts['LocalScript'].title;

	vm.localScriptTitle =
		downloadPackageTexts[vm.demoOnboardingScriptEnabled ? 'LocalScriptDemo' : 'LocalScript'].title;
	vm.localScriptDescription =
		downloadPackageTexts[vm.demoOnboardingScriptEnabled ? 'LocalScriptDemo' : 'LocalScript'].description;

	vm.sccmPackageTitle = downloadPackageTexts['SCCM'].title;
	vm.sccmPackageDescription = downloadPackageTexts['SCCM'].description;

	vm.mdmPackageTitle = downloadPackageTexts['MDM'].title;
	vm.mdmPackageDescription = downloadPackageTexts['MDM'].description;

	vm.gpPackageTitle = downloadPackageTexts['GroupPolicy'].title;
	vm.gpPackageDescription = downloadPackageTexts['GroupPolicy'].description;

	var mgmtTool = {};
	mgmtTool[vm.gpPackageTitle] = 0;
	mgmtTool[vm.sccmPackageTitle] = 1;
	mgmtTool[vm.sccm1606PackageTitle] = 2;
	mgmtTool[vm.mdmPackageTitle] = 2;
	mgmtTool[localScriptTitle] = 3;
	mgmtTool[localScriptDemoTitle] = 4;

	vm.copyToClipboardWorkspaceIdIcon = 'icon-Copy';
	vm.copyToClipboardWorkspaceKeyIcon = 'icon-Copy';
	vm.copyToClipboardIcon = 'icon-Copy';

	vm.provisioningStatus = {
		Success: 1,
		Failure: 2,
		InProgress: 3,
		NotStarted: 4,
		Unknown: 5,
	};

	vm.omsStatus = vm.provisioningStatus.Unknown;

	var offboardingPackageExpirationDays = {
		'10 days': 10,
		'30 days': 30,
		'60 days': 60,
	};

	$('input[type=radio]').prop('disabled', vm.isreadonlyuser);

	vm.downloadOffboardingPackage = function() {
		if (vm.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}

		$modal.open({
			template: `
			<div class="offboarding-modal">
          <div class="settings-title">
              Are you sure you want to download the device offboarding package?
          </div>
          <br />
          <div>
              <table>
                  <tr>
                      <td class="icon icon-lg icon-Error offboarding-modal-warning-padding"></td>
                      <td>
                          By clicking download you'll create an offboarding package that expires on <date src="packageExpirationDate" type="date"></date>.
                          <br />
                          The offboarding package can be used to remove Microsoft Defender for Endpoint settings from devices. Offboarded devices will stop collecting and sending data.
                      </td>
                  </tr>
              </table>
          </div>
          <br />
          <div class="row">
              <div class="col-xs-1">
                  <button class="settings-button ng-class:{'settings-button-enabled':!isreadonlyuser, 'settings-button-disabled':isreadonlyuser}"
                          ng-disabled="isreadonlyuser"
                          ng-click="downloadoffboardingpackage()">
                  <span class="icon icon-Download"></span>
                  Download
                  </button>
              </div>
              <div class="col-xs-2 col-xs-offset-3 offboarding-modal-button-margin">
                  <button type="reset" class="normal-button" ng-click="cancel()">Cancel</button>
              </div>
          </div>
          <br />
      </div>
			`,
			controller: 'seville.settings.download.offboardingmodal',
			size: 'md',
			resolve: {
				packageType: function() {
					return mgmtTool[vm.offboardingPackageType];
				},
				expirationDays: function() {
					return offboardingPackageExpirationDays[vm.offboardingPackageExpirationDays];
				},
			},
		});
	};

	function ValidatePermissions() {
		$http
			.get(vm.baseUrl + '/ValidatePermissions', {
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status !== 200) {
						console.log('status: ' + response.status);
					}
				},
				function(response) {
					console.log('status: ' + response.status);
				}
			);
	}

	vm.startOmsProvisioning = function() {
		if (vm.isreadonlyuser) {
			console.log('read only user, skipping');
			return;
		}

		var modalInstance = $modal.open({
			template: OMS_SETUP_MODAL_TEMPLATE,
			controller: 'seville.settings.download.omssetupmodal',
			size: 'md',
		});

		modalInstance.result.then(function() {
			vm.omsStatus = vm.provisioningStatus.InProgress;

			$http
				.post(
					vm.serverOnboardingUrl + '/StartOmsProvisioning',
					//vm.existingSubscriptions.find(isCurrentSubscription).SubscriptionId,
					{
						timeout: 60000,
					}
				)
				.then(
					function(response) {
						if (response.status === 200) {
							if (response.data === null) {
								console.log(response.data);
								vm.omsStatus = vm.provisioningStatus.Failure;
								return;
							}
							vm.getOmsProvisioningStatus();
						} else {
							vm.omsStatus = vm.provisioningStatus.Failure;
							appInsights.trackEvent('ButtonClick', {
								ButtonType: 'StartOmsProvisioning',
								Page: 'SettingsDownloadServer',
								ResponseStatus: response.status,
								FailureType: 'API',
							});
						}
					},
					function(response) {
						vm.omsStatus = vm.provisioningStatus.Failure;
						console.log('Server Onboarding call has failed');
						appInsights.trackEvent('ButtonClick', {
							ButtonType: 'StartOmsProvisioning',
							Page: 'SettingsDownloadServer',
							ResponseStatus: response.status,
							FailureType: 'Request',
						});
					}
				);
		});
	};

	function setCopyToClipboardIcon(id, icon) {
		if (id === 'download.server.workspaceId') {
			vm.copyToClipboardWorkspaceIdIcon = icon;
		}

		if (id === 'download.server.workspaceKey') {
			vm.copyToClipboardWorkspaceKeyIcon = icon;
		}

		if (id === 'download.server.testAlertCommand') {
			vm.copyToClipboardIcon = icon;
		}
	}

	vm.copyToClipboard = function(elementId) {
		vm.copyToClipboard = function(elementId) {
			clientSideUtils.copyToClipboard('Oms', elementId, setCopyToClipboardIcon);
		};
	};

	vm.getOmsProvisioningStatus = function() {
		$http
			.get(vm.serverOnboardingUrl + '/GetOmsProvisioningStatus', {
				// params: { subscriptionId: vm.existingSubscriptions.find(isCurrentSubscription).SubscriptionId },
				timeout: 60000,
			})
			.then(
				function(response) {
					if (response.status === 200) {
						if (response.data === null) {
							console.log(response.data);
							return;
						}

						vm.omsStatus = response.data.OmsStatus;
						if (vm.omsStatus === vm.provisioningStatus.InProgress) {
							$window.setTimeout(function() {
								vm.getOmsProvisioningStatus();
							}, 150);
						}
						if (vm.omsStatus === vm.provisioningStatus.Success) {
							vm.omsWorkspaceId = response.data.OmsWorkspaceId;
							vm.omsWorkspaceKey = response.data.OmsWorkspaceKey;
						}
					} else {
						console.log('GetOmsProvisioningStatus failed');
					}
				},
				function(response) {
					console.log('GetOmsProvisioningStatus call is failed');
				}
			);
	};

	ValidatePermissions();

	if (vm.isSuspended) {
		appInsights.trackEvent('LoginFailure', { Type: 'SubscriptionExpired' });
	} else if (vm.serverEndpointManagementFeatureEnabled) {
		vm.getOmsProvisioningStatus();
	}
}

const DOWNLOAD_PAGES_TEMPLATE: string = `
<div ng-switch="(download && download.type) || (nonwindows && nonwindows.type)">
    <div ng-switch-when="client">
        <div class="settingsmodule-page">
            <div ng-if="!download.isSuspended" class="settings-endpoint-mgmt-table-cell">
                <img src="/assets/images/onboarding/img_onboarding.svg" class="settings-endpoint-mgmt-side-image" />
            </div>
            <div class="settings-endpoint-mgmt-table-cell" ng-class="{'settings-endpoint-mgmt-table-cell-main': !download.isSuspended}">
                <div ng-if="!download.isSuspended">
                    <div class="settings-endpoint-mgmt-page-title">
                        Client device onboarding
                    </div>
                    <div><download-endpoint-onboarding-body></download-endpoint-onboarding-body></div>
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div ng-if="download.isSuspended" class="authorization-body">
                        <div class="authorization-error-details">
                            <table>
                                <tr>
                                    <td class="authorization-warning-shield-table-cell">
                                        <i class="icon icon-lg icon-Shield authorization-warning-shield"></i>
                                        <span class="authorization-shield-text">!</span>
                                    </td>
                                    <td>
                                        <div class="page-header-title">
                                            Your subscription has expired
                                        </div>
                                        <br />
                                        Your Microsoft Defender for Endpoint subscription has expired.<br />
                                        To be able to continue to access the portal renew your subscription. <br />
                                        See <a href="https://www.microsoft.com/en-us/WindowsForBusiness/windows-atp" target="_blank">Microsoft Defender for Endpoint product site</a> or contact your Microsoft account team for information on how to renew your subscription.
                                        If you want to stop using Microsoft Defender for Endpoint, you will need to offboard your devices.
                                        See the Device offboarding section to download an offboarding package.
                                        <div class="authorization-footer">
                                            Need further assistance? <a href="https://support.microsoft.com/en-us/assistedsupportproducts" target="_blank">Contact support</a>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                      <br /><br /><br />
                    </div>
                    <div ng-if="download.offboardingMinimized" class="offboarding-minimized" ng-click="download.offboardingMinimized = !download.offboardingMinimized">
                        <hr class="offboarding-top-hr" />
                        <div tabindex="0" class="offboarding-offset">
                            <div class="offboarding-title">
                                Device offboarding
                                <span class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                            </div>
                        </div>
                        <hr class="offboarding-bottom-hr" />
                    </div>
                    <div ng-if="!download.offboardingMinimized">
                        <hr class="offboarding-top-hr" />
                        <div class="offboarding-offset">
                            <div tabindex="0" ng-click="download.offboardingMinimized = !download.offboardingMinimized">
                                <div class="offboarding-title">
                                    Device offboarding
                                    <span class="icon icon-ChevronDown rotate-180 onboarding-icon-chevron"></span>
                                </div>
                            </div>
                            <br />
                            Discontinue Microsoft Defender for Endpoint monitoring on a given device by applying a configuration change to it.
                            <br />
                            Download the following offboarding configuration package available for a range of deployment tools.
                            <br />
                            Select the one relevant for your organization and follow the instructions.
                            See <a href="https://go.microsoft.com/fwlink/p/?linkid=822802" target="_blank">Configure devices</a> section in the <a href="https://go.microsoft.com/fwlink/p/?LinkID=733764&clcid=0x409" target="_blank">Microsoft Defender for Endpoint guide</a> for further instructions on how to offboard devices.
                            <br /><br /><br />
                            For security reasons, the offboarding package will expire within 30 days of its creation.
                            <br />
                            The expiry date is embedded in the created package name: <i>WindowsDefenderATPOffboardingPackage_valid_until_YYYY-MM-DD.zip</i> (where YYYY-MM-DD is the expiry date of the package).
                            <br />
                            Expired offboarding packages sent to a device will fail to offboard the device.
                            <br />
                            <br />
                            <div class="onboarding-dropdown-header">
                                Select your deployment tool:
                            </div>
                            <div ng-class="{'onboarding-dropdown-custom':!download.isreadonlyuser, 'onboarding-dropdown-custom-disabled':download.isreadonlyuser}" class="btn-group settings-endpoint-mgmt-100-width" style="text-align: right" uib-dropdown>
                                <button ng-class="{'onboarding-dropdown':!download.isreadonlyuser, 'onboarding-dropdown-disabled':download.isreadonlyuser}" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle ng-disabled="download.isreadonlyuser" class="onboarding-download-width">
                                    <div class="settings-endpoint-mgmt-table-cell settings-endpoint-mgmt-100-width">
                                        {{download.offboardingPackageType}}
                                    </div>
                                    <div class="settings-endpoint-mgmt-table-cell">
                                        <span tabindex="0" class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                                    </div>
                                </button>

                                <ul ng-class="{'onboarding-dropdown-custom':!isreadonlyuser, 'onboarding-dropdown-custom-disabled':download.isreadonlyuser}" class="dropdown-menu-shadow dropdown-menu settings-endpoint-mgmt-dropdown-menu settings-endpoint-mgmt-100-width" role="menu">
                                    <li tabindex="0" ng-disabled="download.isreadonlyuser" ng-model="download.offboardingPackageType" uib-btn-radio="'{{download.gpPackageTitle}}'"><a href="#">{{download.gpPackageTitle}}</a></li>
                                    <li ng-disabled="download.isreadonlyuser" ng-model="download.offboardingPackageType" uib-btn-radio="'{{download.sccm1606PackageTitle}}'"><a href="#">{{download.sccm1606PackageTitle}}</a></li>
                                    <li ng-disabled="download.isreadonlyuser" ng-model="download.offboardingPackageType" uib-btn-radio="'{{download.sccmPackageTitle}}'"><a href="#">{{download.sccmPackageTitle}}</a></li>
                                    <li ng-disabled="download.isreadonlyuser" ng-model="download.offboardingPackageType" uib-btn-radio="'{{download.mdmPackageTitle}}'"><a href="#">{{download.mdmPackageTitle}}</a></li>
                                    <li ng-disabled="download.isreadonlyuser" ng-model="download.offboardingPackageType" uib-btn-radio="'{{download.localScriptTitle}}'"><a href="#">{{download.localScriptTitle}}</a></li>
                                </ul>
                            </div>
                            <div class="onboarding-package-description">
                                <table>
                                    <tr>
                                        <td ng-if="download.offboardingPackageType == download.gpPackageTitle" ng-bind-html="download.gpPackageDescription"></td>
                                        <td ng-if="download.offboardingPackageType == download.sccm1606PackageTitle" ng-bind-html="download.sccm1606PackageDescription"></td>
                                        <td ng-if="download.offboardingPackageType == download.sccmPackageTitle" ng-bind-html="download.sccmPackageDescription"></td>
                                        <td ng-if="download.offboardingPackageType == download.mdmPackageTitle" ng-bind-html="download.mdmPackageDescription"></td>
                                        <td ng-if="download.offboardingPackageType == download.localScriptTitle" ng-bind-html="download.localScriptDescription"></td>
                                    </tr>
                                </table>
                            </div>
                            <br />
                            <div>
                                <form ng-submit="download.downloadOffboardingPackage()">
                                    <button
                                            class="settings-button settings-download-button ng-class:{'settings-button-enabled':!download.isreadonlyuser, 'settings-button-disabled':download.isreadonlyuser}"
                                            ng-disabled="download.isreadonlyuser">
                                        <span class="icon icon-Download "></span>
                                        Download package
                                    </button>
                                </form>
                            </div>
                        </div>
                        <br />
                        <hr class="offboarding-bottom-hr" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div ng-switch-when="server">
        <div class="settingsmodule-page">
            <div class="settings-endpoint-mgmt-table-cell">
                <img src="/assets/images/onboarding/img_onboarding.svg" class="settings-endpoint-mgmt-side-image" />
            </div>
            <div class="settings-endpoint-mgmt-table-cell settings-endpoint-mgmt-table-cell-main">
                <div class="settings-endpoint-mgmt-page-title">
                    Server device onboarding
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                        1. Turn on server device monitoring
                    </div>
                    <div class="settings-endpoint-mgmt-padding">
                        To track server devices, turn on server device monitoring and then configure the monitoring agent on the devices.<br />
                        Prepare Microsoft Defender for Endpoint to receive signals from your server devices.
                    </div>
                    <div>
                        <div ng-if="download.omsStatus == download.provisioningStatus.NotStarted || download.omsStatus == download.provisioningStatus.Failure">
                            <span>
                                <button class="btn-group settings-button settings-download-oms-button settings-download-oms-setup-button ng-class:{'settings-button-enabled':!download.isreadonlyuser, 'settings-button-disabled settings-disabled':download.isreadonlyuser}" ng-click="download.startOmsProvisioning()">
                                    Turn on server monitoring
                                </button>
                            </span>
                        </div>
                        <div ng-if="download.omsStatus == download.provisioningStatus.Unknown">
                            <img class="settings-download-oms-provisioningImg img-responsive" src="/assets/images/linear-loader.gif" />
                        </div>
                        <div ng-if="download.omsStatus == download.provisioningStatus.InProgress">
                            Turning on server device monitoring...<br />
                            <img class="settings-download-oms-provisioningImg img-responsive" src="/assets/images/linear-loader.gif" />
                        </div>
                        <div ng-if="download.omsStatus == download.provisioningStatus.Failure" class="settings-error-message">
                            Problems encountered while turning on server device monitoring. Try again later. If this issue persists, please contact support.
                        </div>
                        <div ng-if="download.omsStatus == download.provisioningStatus.Success">
                            <span tabindex="0" class="icon icon-Completed settings-download-oms-completed-icon"></span>
                            Server device monitoring enabled
                        </div>
                    </div>
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                        2. Install Microsoft Monitoring Agent
                    </div>
                    <div>
                        Refer to <a href="https://go.microsoft.com/fwlink/p/?linkid=851222" target="_blank">these instructions</a> to install and set up the Microsoft Monitoring Agent on the server devices you want to monitor. <br/>
                        If your server devices already have the agent, connect them to an additional workspace.
                    </div>
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                        3. Configure connection
                    </div>
                    <div class="settings-endpoint-mgmt-padding">
                        Configure the agents to connect using the following workspace information:
                    </div>
                    <div>
                        <div class="settings-endpoint-mgmt-padding">
                            <div class="settings-endpoint-mgmt-align-left settings-endpoint-mgmt-download-title">Workspace ID</div>
                            <div>
                                <input id="download.server.workspaceId" ng-model="download.omsWorkspaceId" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-clientsideutils-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': download.omsStatus == download.provisioningStatus.Success , 'settings-clientsideutils-copy-disabled': !(download.omsStatus == download.provisioningStatus.Success) }" ng-click="download.copyToClipboard('download.server.workspaceId')">
                                    <span class="icon {{download.copyToClipboardWorkspaceIdIcon}}"></span> Copy
                                </span>
                            </div>
                        </div>
                        <div>
                            <div class="settings-endpoint-mgmt-align-left settings-endpoint-mgmt-download-title">Workspace Key</div>
                            <div>
                                <input id="download.server.workspaceKey" ng-model="download.omsWorkspaceKey" class="settings-customerTI-appPropertyDisabled" />
                                <span class="settings-clientsideutils-copyToClipboard-icon" ng-class="{'settings-clientsideutils-copy-enabled': download.omsStatus == download.provisioningStatus.Success , 'settings-clientsideutils-copy-disabled': !(download.omsStatus == download.provisioningStatus.Success) }" ng-click="download.copyToClipboard('download.server.workspaceKey')">
                                    <span class="icon {{download.copyToClipboardWorkspaceKeyIcon}}"></span> Copy
                                </span>
                            </div>
                            <div class="settings-endpoint-mgmt-server-note">
                                <span class="icon icon-Info color-text-primary"></span>
                                New devices are visible on the device list within an hour of onboarding.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                        4. Run a detection test
                    </div>
                    <div class="settings-endpoint-mgmt-padding">
                        To verify that the device is properly onboarded and reporting to the service, run the detection script on the newly onboarded device:
                        <ol type="a" class="settings-endpoint-mgmt-list">
                            <li>Open a Command Prompt window</li>
                            <li>At the prompt, copy and run the command below. The Command Prompt window will close automatically.</li>
                            <li class="settings-endpoint-mgmt-list-no-letter">
                                <div class="settings-endpoint-mgmt-box">
                                    <textarea id="download.server.testAlertCommand" class="settings-endpoint-mgmt-textarea" rows="3" readonly>{{::download.detectionTestCode}}</textarea>
                                </div>
                                <div class="settings-endpoint-mgmt-copy-icon">
                                    <div ng-click="download.copyToClipboard('download.server.testAlertCommand')" class="settings-clientsideutils-copy-enabled settings-endpoint-mgmt-copy-icon-div">
                                        <span class="icon {{download.copyToClipboardIcon}}"></span> Copy
                                    </div>

                                </div>
                            </li>
                        </ol>
                        If successful, a new alert will appear in few minutes.
                    </div>
                </div>
                <div class="settings-endpoint-mgmt-section">
                    <div ng-if="download.offboardingMinimized" class="offboarding-minimized" ng-click="download.offboardingMinimized = !download.offboardingMinimized">
                        <hr class="offboarding-top-hr" />
                        <div tabindex="0" class="offboarding-offset">
                            <div class="offboarding-title">
                                Server offboarding
                                <span class="icon icon-ChevronDown settings-download-icon-chevron"></span>
                            </div>
                        </div>
                        <hr class="offboarding-bottom-hr" />
                    </div>
                    <div ng-if="!download.offboardingMinimized">
                        <hr class="offboarding-top-hr" />
                        <div class="offboarding-offset">
                            <div class="offboarding-title" tabindex="0" ng-click="download.offboardingMinimized = !download.offboardingMinimized">
                                Server offboarding
                                <span class="icon icon-ChevronDown rotate-180 settings-download-icon-chevron"></span>
                            </div>
                            <div class="settings-endpoint-mgmt-padding">
                                Refer to documentation for guidance on <a href="https://go.microsoft.com/fwlink/p/?linkid=852906" target="_blank">how to offboard server devices</a>.
                            </div>
                        </div>
                        <hr class="offboarding-bottom-hr" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div ng-switch-when="nonwindows">
        <div class="settingsmodule-page settings-base">
           <div class="settings-endpoint-mgmt-table-cell">
             <img src="/assets/images/onboarding/img_onboarding.svg" class="settings-endpoint-mgmt-side-image" />
           </div>
           <div class="settings-endpoint-mgmt-table-cell settings-endpoint-mgmt-table-cell-main">
               <div class="settings-title wcd-padding-bottom">
                  Non-Windows device management
               </div>
               <div class="settings-page-title">
                  Enable coverage for devices running non-Windows platforms by integrating third-party solutions. You will need to generate a separate access token for each available solution. Before proceeding, <a href="https://go.microsoft.com/fwlink/?linkid=853856" target="_blank">read more about multiplatform support</a>.
               </div>
               <div ng-if="!nonwindows.loadStatusFailed && nonWindows.applications.length > 0">
                  <div class="settings-endpoint-mgmt-partners-section">
                     <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                         1. Turn on third-party integration
                     </div>
                     <nonwindows-partner-management ng-if="::nonwindows.getPartner('Bitdefender')" partner="::nonwindows.getPartner('Bitdefender')" isreadonlyuser="::nonwindows.isreadonlyuser" is-settings-loading="nonwindows.settingsLoading">
                          Get telemetry from macOS and Linux devices with <a href="https://go.microsoft.com/fwlink/?linkid=860032" target="_blank">Bitdefender</a> antivirus.
                     </nonwindows-partner-management>
                     <nonwindows-partner-management ng-if="::nonwindows.getPartner('Ziften')" partner="::nonwindows.getPartner('Ziften')" isreadonlyuser="::nonwindows.isreadonlyuser" is-settings-loading="nonwindows.settingsLoading">
                          Get telemetry from macOS and Linux devices with the <a href="https://go.microsoft.com/fwlink/?linkid=862988" target="_blank">Ziften</a> agent.
                     </nonwindows-partner-management>
                     <nonwindows-partner-management ng-if="::nonwindows.getPartner('SentinelOne')" partner="::nonwindows.getPartner('SentinelOne')" isreadonlyuser="::nonwindows.isreadonlyuser" is-settings-loading="nonwindows.settingsLoading">
                          Get telemetry from macOS and Linux devices with the <a href="https://go.microsoft.com/fwlink/?linkid=866934" target="_blank">SentinelOne</a> agent.
                     </nonwindows-partner-management>
                     <nonwindows-partner-management ng-if="::nonwindows.getPartner('Lookout')" partner="::nonwindows.getPartner('Lookout')" isreadonlyuser="::nonwindows.isreadonlyuser" is-settings-loading="nonwindows.settingsLoading">
                          Get telemetry from macOS and Linux devices with the <a href="https://go.microsoft.com/fwlink/?linkid=866935" target="_blank">Lookout</a> agent.
                     </nonwindows-partner-management>
                  </div>
               </div>
               <div ng-if="nonWindows.applications.length > 0" class="settings-endpoint-mgmt-section">
                   <div class="settings-endpoint-mgmt-title settings-endpoint-mgmt-title-server">
                       2. Run a detection test
                   </div>
                   <div class="btn-group onboarding-dropdown-margin-top onboarding-dropdown-custom" style="text-align: right" uib-dropdown>
                      <button class="onboarding-dropdown onboarding-nonwindows-dropdown" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle>
                         <span ng-if="nonwindows.selectedPartner">{{nonwindows.selectedPartner}}</span>
                         <span ng-if="!nonwindows.selectedPartner">Select the third-party solution</span>
                         <span class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                      </button>
                      <ul class="dropdown-menu onboarding-dropdown-custom onboarding-nonwindows-dropdown" role="menu">
                         <li ng-repeat="partner in nonWindows.applications" tabindex="{{$index}}" ng-if="nonWindows.applicationsInformation[partner.partnerName].instructions" role="menuitem" ng-model="nonwindows.selectedPartner" uib-btn-radio="'{{partner.partnerName}}'" ng-change="nonwindows.changePartnerTest()"><a href="#">{{partner.partnerName}}</a></li>
                      </ul>
                   </div>
                   <div class="settings-endpoint-mgmt-padding">
                     {{nonWindows.applicationsInformation[nonwindows.selectedPartner].instructions}}
                   </div>
                   <div ng-if="nonwindows.selectedPartner" class="settings-endpoint-mgmt-padding">
                      <div class="settings-endpoint-mgmt-box">
                        <textarea id="settings.nonwindows.detectionTestString" class="settings-endpoint-mgmt-textarea settings-endpoint-mgmt-test-textarea" rows="1" readonly>{{nonWindows.applicationsInformation[nonwindows.selectedPartner].command}}</textarea>
                      </div>
                      <div class="settings-endpoint-mgmt-copy-icon">
                         <div ng-click="nonwindows.copyToClipboard()" class="settings-clientsideutils-copy-enabled settings-endpoint-mgmt-copy-icon-div">
                            <span class="icon {{nonwindows.copyToClipboardDetectionTestCssClass}}"></span> Copy
                         </div>
                      </div>
                   </div>
               </div>
           </div>
           <br />
           <div ng-if="nonwindows.errorMessage" class="settings-error-message">
              {{nonwindows.errorMessage}}
           </div>
        </div>
    </div>
</div>
`;
