
		<div class="wcd-flex-horizontal-spaced wcd-flex-wrap" data-track-component="SensorHealthWidget">
			<wcd-pie-chart
				[data]="chartData$ | async"
				[hidden]="error$ | async"
				[settings]="settings"
				[allowTitleClick]="true"
				[upperTitle]="pieChartTitle$ | async"
				(titleClick)="navigateToMachinesPage()"
				[ariaLabel]="'securityOperations_sensorHealth_widgetTitle' | i18n"
			>
			</wcd-pie-chart>
			<div [hidden]="error$ | async" class="wcd-flex-vertical">
				<wcd-chart-legend [items]="legendData$ | async" [showValues]="false"></wcd-chart-legend>
				<a
					href="https://go.microsoft.com/fwlink/p/?linkid=839876"
					target="_blank"
					style="padding-top: 1.5em"
					data-track-id="MoreInfoDocs"
					data-track-type="Navigation"
				>
					{{ 'widget_moreInfo_sensorHealth' | i18n }}
				</a>
			</div>
		</div>
	