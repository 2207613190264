import { NgModule } from '@angular/core';
import { AreaChartComponent } from './area-chart.component';
import { I18nModule } from '@wcd/i18n';
import { CommonModule } from '@angular/common';
import { FabIconModule } from '@angular-react/fabric';

@NgModule({
	imports: [CommonModule, FabIconModule, I18nModule],
	entryComponents: [],
	declarations: [AreaChartComponent],
	exports: [AreaChartComponent],
})
export class AreaChartModule {}
