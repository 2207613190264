import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { AssetRecommendationContext } from '@wcd/domain';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Recommendation Context Properties',
	pluralName: 'Recommendation Context Properties',
	endpoint: 'configurations',
	readonly: true,
})
export class RecommendationContextProperties extends ModelBase {
	@EntityField({ data: 'recommendationContext' })
	readonly recommendationContext: AssetRecommendationContext;

	@EntityField({ data: 'name' })
	readonly name: string;

	@EntityField({ data: 'assetId' })
	readonly assetId: string;
}
