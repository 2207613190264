/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "./filter-values.datepicker.component";
var styles_FilterValuesDatePickerComponent = [];
var RenderType_FilterValuesDatePickerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterValuesDatePickerComponent, data: {} });
export { RenderType_FilterValuesDatePickerComponent as RenderType_FilterValuesDatePickerComponent };
export function View_FilterValuesDatePickerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "fab-date-picker", [], null, [[null, "onSelectDate"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onSelectDate" === en)) {
        var pd_0 = (_co.onSelectDate($event.date) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabDatePickerComponent_0, i1.RenderType_FabDatePickerComponent)), i0.ɵdid(2, 5816320, null, 0, i2.FabDatePickerComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2], { isMonthPickerVisible: [0, "isMonthPickerVisible"], placeholder: [1, "placeholder"], value: [2, "value"], showGoToToday: [3, "showGoToToday"], minDate: [4, "minDate"], maxDate: [5, "maxDate"], initialPickerDate: [6, "initialPickerDate"] }, { onSelectDate: "onSelectDate" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!((_co.config == null) ? null : _co.config.isMonthPickerVisible); var currVal_1 = (((_co.config == null) ? null : _co.config.placeholder) || "Select date..."); var currVal_2 = _co.selectedValues; var currVal_3 = !!((_co.config == null) ? null : _co.config.showGoToToday); var currVal_4 = ((_co.config == null) ? null : _co.config.minDate); var currVal_5 = ((_co.config == null) ? null : _co.config.maxDate); var currVal_6 = (((_co.config == null) ? null : _co.config.initialDate) || _co.selectedValues); _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
export function View_FilterValuesDatePickerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-filter-values-datepicker", [], null, null, null, View_FilterValuesDatePickerComponent_0, RenderType_FilterValuesDatePickerComponent)), i0.ɵdid(1, 49152, null, 0, i3.FilterValuesDatePickerComponent, [i0.ChangeDetectorRef, i0.NgZone], null, null)], null, null); }
var FilterValuesDatePickerComponentNgFactory = i0.ɵccf("wcd-filter-values-datepicker", i3.FilterValuesDatePickerComponent, View_FilterValuesDatePickerComponent_Host_0, { data: "data", fieldId: "fieldId", config: "config", selectedValues: "selectedValues", fixedSelectedValues: "fixedSelectedValues" }, { filterValuesChange: "filterValuesChange" }, []);
export { FilterValuesDatePickerComponentNgFactory as FilterValuesDatePickerComponentNgFactory };
