import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataEntityType, ModelBase, Paris, ReadonlyRepository } from '@microsoft/paris';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { DataviewField } from '@wcd/dataview';
import { DataViewConfig } from '@wcd/dataview';
import { PreferencesService } from '@wcd/config';
import {
	isCustomTimeRangeValue,
	SpecificTimeRangeValue,
	TimeRangeValue,
	TimeRangeId,
} from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { EntityDataViewOptions, EntityType } from '../../models/entity-type.interface';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import {
	DataviewAction,
	DataviewActionTypes,
} from '../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
import { DataviewActionButtonConfig } from '../../../dataviews/components/actions-components/dataview-action-button.component';
import { PanelSettings } from '@wcd/panels';

const defaultDataViewConfig: DataViewConfig = {};
const getTimeRangePreferenceId = <TEntity extends ModelBase>(entity: DataEntityType<TEntity>) => {
	if (!entity.pluralName) {
		throw new Error(
			`Searchable entity with a time range must have a 'pluralName' (Entity: ${entity.name}).`
		);
	}

	return `saved_${entity.pluralName}_time_range_`;
};

@Component({
	selector: 'entity-dataview',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dataview
			*ngIf="entityType && options"
			[id]="id || entityType.pluralName + '_list'"
			[className]="className"
			[defaultSortFieldId]="options.defaultSortFieldId"
			[repository]="repository"
			[entityType]="dataViewEntityType"
			[fields]="fields"
			[dataViewConfig]="dataViewConfig"
			[disableFilterPanelAutoFocus]="disableFilterPanelAutoFocus"
			[fixedOptions]="fixedOptions"
			[disabledFields]="options?.disabledFields"
			[getFilterQueryOptions]="options?.getFilterQueryOptions"
			(onData)="dataChanged.emit($event)"
			(onNewItem)="newItemAdded.emit($event)"
			(groupExpand)="groupExpanded.emit($event)"
			[label]="label"
			[focusOnTable]="focusOnTable"
			[queueHeader]="queueHeader"
			[padLeft]="padLeft"
			[commandBarNoPadding]="commandBarNoPadding"
			(afterTableInit)="!options?.dateRangeOptions ? afterTableInit($event) : null"
			(onTableRenderComplete)="onDataTableRenderComplete()"
			[tabIndex]="options?.dateRangeOptions && !isFirstInitDone ? null : -1"
			[focusOnFirstMenuItem]="!options?.dateRangeOptions"
			[responsiveActionBar]="responsiveActionBar"
			[responsiveLayout]="responsiveLayout"
			[customActionsRight]="commandBarRight"
			[removePadding]="removePadding"
			[removePaddingRight]="removePaddingRight"
			[customActionsLeft]="customActionsLeft ? customActionsLeft : []"
			[entitySidePanelSettings]="entitySidePanelSettings"
		>
			<ng-container
				*ngIf="!responsiveActionBar && options.dateRangeOptions as dateRangeOptions"
				dataview-controls
			>
				<fancy-select
					[(ngModel)]="currentRange"
					(ngModelChange)="onRangeTypeSelect()"
					class="command-bar-item-dropdown"
					buttonIcon="calendar"
					[label]="'name'"
					[values]="dateRangeOptions.supportedRanges"
					[ariaLabel]="i18nService.strings.dataview_timePeriod"
					[focusOnInit]="!isFirstInitDone"
					(focus)="afterFirstElementFocused()"
				></fancy-select>
			</ng-container>
			<ng-container dataview-header>
				<ng-content select="[dataview-header]"></ng-content>
			</ng-container>
		</dataview>
	`,
})
export class EntityDataviewComponent<TEntity extends ModelBase, TFixedDataViewOptions extends object>
	implements OnInit, OnChanges<EntityDataviewComponent<TEntity, TFixedDataViewOptions>>, OnDestroy {
	@Input() entityType: DataEntityType<TEntity>;
	@Input() options: EntityDataViewOptions<TEntity, TFixedDataViewOptions>;
	@Input() className: string;
	@Input() id: string;
	// a label to be read by the narrator
	@Input() label = '';
	@Input() removePadding = false;
	@Input() removePaddingRight = false;
	/**
	 * Disable panel auto focus when it's not needed, like in search-results page - search results should be focused.
	 */
	@Input() disableFilterPanelAutoFocus: boolean;

	@Input() focusOnTable: boolean;

	@Input() queueHeader: boolean = false;
	@Input() padLeft: boolean = true;

	@Input() customActionsLeft: DataviewActionButtonConfig[];

	// Remove the dataview's command bar's horizontal padding.
	@Input() commandBarNoPadding: boolean;
	@Input() responsiveActionBar: boolean;
	@Input() responsiveLayout: boolean;
	@Input() entitySidePanelSettings: PanelSettings = {};

	@Output() fixedOptionsChanged = new EventEmitter<TFixedDataViewOptions>();
	@Output() dataChanged: DataViewComponent['onData'] = new EventEmitter();
	@Output() newItemAdded: DataViewComponent['onNewItem'] = new EventEmitter();
	@Output() groupExpanded: DataViewComponent['groupExpand'] = new EventEmitter();
	@Output() onTableRenderComplete = new EventEmitter();

	fields: ReadonlyArray<DataviewField<TEntity>>;
	dataViewEntityType: EntityType<TEntity>;
	repository: ReadonlyRepository<TEntity>;
	currentRange: SpecificTimeRangeValue;
	fixedOptions: TFixedDataViewOptions;
	dataViewConfig: DataViewConfig<TEntity>;
	commandBarRight: DataviewAction[] = [];
	wasTableInit: boolean = false;
	isFirstElementBeenFocused: boolean = false;
	isFirstInitDone: boolean = false;
	currentTimeRange: BehaviorSubject<TimeRangeValue>;

	private timeRangePreferenceId: string;
	private readonly subscriptions: Subscription[] = [];
	rangeInRoute: boolean = false;

	constructor(
		private readonly injector: Injector,
		private readonly paris: Paris,
		private readonly preferencesService: PreferencesService,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute,
		private readonly timeRangesService: TimeRangesService,
		public i18nService: I18nService
	) {}

	ngOnInit(): void {
		this.subscriptions.push(
			this.route.queryParamMap
				.pipe(
					map((queryParams) => queryParams.get('range')),
					filter(Boolean), // Make sure that a range was given in the URL
					map(Number), // parse the param
					map((rangeValue: number) =>
						this.timeRangesService.all.find(
							(range) => !isCustomTimeRangeValue(range) && range.value === rangeValue
						)
					),
					filter(Boolean), // Guard against unknown ranges
					tap(() => {
						this.rangeInRoute = true;
					}),
					filter((newRange: SpecificTimeRangeValue) => newRange.id !== this.currentRange.id)
				)
				.subscribe((range) => {
					this.currentRange = range;
					this.setFixedOptions();
					this.currentTimeRange.next(this.currentRange);
				})
		);
		if (this.responsiveActionBar && this.options.dateRangeOptions) {
			this.commandBarRight = [
				{
					onSelectionChanged: this.onRangeTypeSelect.bind(this),
					currentSelection: this.currentTimeRange,
					selections: [...this.options.dateRangeOptions.supportedRanges],
					icon: FabricIconNames.Calendar,
					ariaLabel: this.i18nService.get('dataview.timePeriod'),
					dataTrackId: 'timeRange_IncidentsDataview',
					listId: 'timeRangeSelector',
					focus: this.afterFirstElementFocused.bind(this),
					focusOnInit: !this.isFirstInitDone,
					propertyIdForLabel: 'name',
					actionType: DataviewActionTypes.FancySelect,
				},
			];
		}
	}

	ngOnChanges(changes: TypedChanges<EntityDataviewComponent<TEntity, TFixedDataViewOptions>>) {
		// Check that we have both needed values to render
		if (!this.entityType || !this.options) {
			return;
		}

		const currentEntityType = changes.entityType ? changes.entityType.currentValue : this.entityType;
		const currentOptions = changes.options ? changes.options.currentValue : this.options;

		this.timeRangePreferenceId = getTimeRangePreferenceId(currentEntityType);

		this.fields = Array.isArray(currentOptions.fields)
			? currentOptions.fields
			: this.injector.get(currentOptions.fields).fields;

		this.dataViewEntityType = this.globalEntityTypesService.getEntityType(currentEntityType);
		this.repository = this.paris.getRepository(currentEntityType);

		if (currentOptions.dateRangeOptions && !this.rangeInRoute) {
			const {
				defaultTimeRangeId,
				supportedRanges,
				restoreSavedRange,
			} = currentOptions.dateRangeOptions;

			const savedTimeRangePreference = this.preferencesService.getPreference<string>(
				this.timeRangePreferenceId
			);
			this.currentRange =
				restoreSavedRange && savedTimeRangePreference
					? supportedRanges.find((range) => range.id === savedTimeRangePreference)
					: supportedRanges.find((range) => range.id === defaultTimeRangeId || range.isDefault);

			if (this.currentTimeRange) this.currentTimeRange.next(this.currentRange);
			else this.currentTimeRange = new BehaviorSubject(this.currentRange);
		}

		this.setFixedOptions();
		this.setDataViewConfig();

		const { previousValue: previousOptions } = changes.options;
		if (
			!changes.options.isFirstChange() &&
			previousOptions !== currentOptions &&
			previousOptions.lifecycleHooks &&
			previousOptions.lifecycleHooks.ngOnDestroy
		) {
			previousOptions.lifecycleHooks.ngOnDestroy();
		}
	}

	ngOnDestroy() {
		if (this.options.lifecycleHooks && this.options.lifecycleHooks.ngOnDestroy) {
			this.options.lifecycleHooks.ngOnDestroy();
		}

		if (this.subscriptions) {
			this.subscriptions.forEach((subscription) => subscription.unsubscribe());
		}
	}

	afterTableInit(loading: boolean) {
		this.wasTableInit = loading;
	}

	onDataTableRenderComplete(loading: boolean) {
		this.onTableRenderComplete.emit(loading);
	}

	afterFirstElementFocused() {
		this.isFirstElementBeenFocused = true;
		this.checkIfFirstInitDone();
	}

	/**
	 *  focusing on the first interactive element
	 *  Fixing bug: 27220333 (https://dev.azure.com/microsoft/OS/_workitems/edit/27220333/)
	 */
	checkIfFirstInitDone() {
		if (this.isFirstElementBeenFocused && this.wasTableInit && !this.isFirstInitDone) {
			this.isFirstInitDone = true;
		}
	}

	onRangeTypeSelect(newRangeType?: SpecificTimeRangeValue) {
		if (newRangeType) this.currentRange = newRangeType;
		this.currentTimeRange.next(this.currentRange);
		this.preferencesService.setPreference(this.timeRangePreferenceId, this.currentRange.id);

		this.setFixedOptions();

		this.router.navigate(['.'], {
			relativeTo: this.route,
			queryParams: Object.assign(
				{
					range: this.currentRange.value,
				},
				this.options.dataViewConfig.allowPaging && { pageIndex: null }
			),
			queryParamsHandling: 'merge',
		});
	}

	private setFixedOptions(options?: this['options']) {
		const fixedOptions = (options && options.fixedOptions) || this.options.fixedOptions;
		this.fixedOptions = fixedOptions && fixedOptions(this.currentRange);

		this.fixedOptionsChanged.emit(this.fixedOptions);
	}

	private setDataViewConfig() {
		this.dataViewConfig =
			{
				...this.options.dataViewConfig,
				exportResults:
					this.options.exportOptions &&
					((options, format, dataQuery) =>
						this.options.exportOptions.exportResults(
							options,
							this.fixedOptions,
							null,
							dataQuery
						)),

				showModalOnExport: this.options.exportOptions && this.options.exportOptions.showModalOnExport,
				disabledVisibleFieldIds:
					this.options.disabledFields || this.options.dataViewConfig.disabledVisibleFieldIds,
			} || defaultDataViewConfig;
	}
}
