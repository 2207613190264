var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { AadUser } from './aad.user.entity';
import { EvidenceAlertsSummary } from '../../incident/evidence-alerts-summary.value-object';
var ɵ0 = function (_, query) { return "user/" + query.where['id'] + "/alerts"; }, ɵ1 = function (aadUser) { return ({ id: aadUser.id }); }, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; };
var AadUserAlertsRelationship = /** @class */ (function () {
    function AadUserAlertsRelationship() {
    }
    AadUserAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: AadUser,
            dataEntity: EvidenceAlertsSummary,
            endpoint: ɵ0,
            getRelationshipData: ɵ1,
            fixedData: { lookBackInDays: 180 },
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ2,
        })
    ], AadUserAlertsRelationship);
    return AadUserAlertsRelationship;
}());
export { AadUserAlertsRelationship };
export { ɵ0, ɵ1, ɵ2 };
