
		<div class="wcd-full-height wcd-flex-vertical">
			<tabs
				[tabsData]="tabs"
				[currentRouteIsActive]="true"
				class="wcd-flex-0 wcd-padding-medium-all"
			></tabs>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	