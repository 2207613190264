import { NgModule } from '@angular/core';
import { HuntingQueryResultRecordEntityPanelComponent } from './components/hunting-query-result-record.entity-panel.component';
import { HuntingQueryResultRecordEntityTypeService } from './services/hunting-query-result-record.entity-type.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { PanelsModule } from '@wcd/panels';
import { WcdFormsModule } from '@wcd/forms';
import { WcdSharedModule } from '@wcd/shared';
import { ResultsTableModule } from '@wcd/hunting';
import { CyberEventsModule } from '../cyber_events/cyber-events.module';
import { FabSpinnerModule } from '@angular-react/fabric';
import { HuntingCustomActionsModule } from '../../hunting-custom-actions/hunting-custom-actions.module';
import { ImpactedEntitiesModule } from '../../impacted-entities/impacted-entities.module';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		PanelsModule,
		WcdFormsModule,
		WcdSharedModule,
		ResultsTableModule,
		CyberEventsModule,
		FabSpinnerModule,
		HuntingCustomActionsModule,
		ImpactedEntitiesModule,
	],
	providers: [HuntingQueryResultRecordEntityTypeService],
	declarations: [HuntingQueryResultRecordEntityPanelComponent],
	exports: [],
	entryComponents: [HuntingQueryResultRecordEntityPanelComponent],
})
export class HuntingRecordModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(HuntingQueryResultRecordEntityTypeService);
	}
}
