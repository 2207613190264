import { EntityType, EntityDataViewOptions } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import {
	SecurityRecommendation,
	MdeUserRoleActionEnum,
	ResolveEntityURL,
	TvmEndPoint,
	RemediationType,
} from '@wcd/domain';
import { SecurityRecommendationFieldsService } from './security-recommendation.fields.service';
import { SecurityRecommendationEntityPanelComponent } from '../components/security-recommendation.entity-panel.component';
import { SecurityRecommendationEntityDetailsComponent } from '../../../../global_entities/components/entity-details/security-recommendation.entity-details.component';
import {
	ItemActionModelConfig,
	ItemActionModel,
	ItemActionType,
	ItemActionValue,
} from '../../../../dataviews/models/item-action.model';
import { SecurityRecommendationService } from './security-recommendation.service';
import { TvmDownloadService } from '../../../../tvm/services/tvm-download.service';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { Injectable } from '@angular/core';
import { SidePanelService } from '../../common/side-panel.service';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { FilterTypesEnum } from '../components/filter/filter-types.enum';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

export interface SecurityRecommendationsDataViewFixedOptions {}

export const FILTER_ONLY_SECURITY_RECOMMENDATION_FIELDS = [];

@Injectable()
export class SecurityRecommendationEntityTypeService implements EntityTypeService<SecurityRecommendation> {
	isTvmExposedOperatingSystems: boolean;

	constructor(
		private router: Router,
		private securityRecommendationService: SecurityRecommendationService,
		private tvmTextsService: TvmTextsService,
		private tvmTagsService: TvmTagsService,
		private sidePanelService: SidePanelService,
		private reportInaccuracyService: TvmReportInaccuracyService,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService,
		private tvmDownloadService: TvmDownloadService,
		private appContextService: AppContextService
	) {
		this.entityType.searchOptions = {
			displayName: i18nService.strings.entity_type_display_name_recommendation,
			getSearchParams: (searchTerm: string) => {
				const resultsUrl = this.appContextService.isSCC
					? `/searchResults/securityRecommendation/${searchTerm}`
					: `/security-recommendations`;
				return {
					url: resultsUrl,
					filter: searchTerm,
				};
			},
			searchDropdownOrder: 1,
			requiredAllowedActions: MdeUserRoleActionEnum.tvmViewData,
			flavorConfig: AppFlavorConfig.search.tvmEntities,
		};
		this.isTvmExposedOperatingSystems = this.featuresService.isEnabled(
			Feature.TvmExposedOperatingSystems
		);
	}

	readonly entityType: EntityType<SecurityRecommendation> = {
		id: 'SecurityRecommendation',
		entity: SecurityRecommendation,
		entityDetailsComponentType: SecurityRecommendationEntityDetailsComponent,
		singleEntityPanelComponentType: SecurityRecommendationEntityPanelComponent,
		getEntityName: (securityRecommendation: SecurityRecommendation) =>
			this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, securityRecommendation),
		entitySingularNameKey: 'tvm_common_securityRecommendation',
		entityPluralNameKey: 'tvm_common_securityRecommendations',
		getEntityDataviewLink: () => '/security-recommendations',
		getActions: (
			securityRecommendations: Array<SecurityRecommendation>,
			options: { subResource?: boolean }
		) => {
			if (securityRecommendations.length !== 1) return;

			const actions: Array<ItemActionModelConfig> = [];

			if (this.isRecommendationHasSoftware(securityRecommendations[0], options)) {
				actions.push(this.buildOpenSoftwareAction(securityRecommendations[0]));
			}
			actions.push(
				{
					id: 'requestRemediation',
					nameKey: 'tvm.securityRecommendation.remediationOptions',
					icon: FabricIconNames.PageList,
					closeOnAction: methodResolution => methodResolution,
					method: (securityRecommendations: Array<SecurityRecommendation>) => {
						this.appInsightsService.trackEvent(
							'tvm-recommendation-side-panel-request-remediation-click',
							{
								id: securityRecommendations[0].id,
								onboardingStatus: securityRecommendations[0].onboardingStatus
							}
						);

						// Sending null will cause only recommendations including productivity impact to populate productivity impact type value (others don't use it)
						return this.securityRecommendationService.showRemediationTaskCreationPanel(
							securityRecommendations[0],
							null
						);
					},
				},
				{
					id: 'createException',
					nameKey: 'tvm.securityRecommendation.createException',
					icon: FabricIconNames.FullHistory,
					closeOnAction: methodResolution => methodResolution,
					method: (securityRecommendations: Array<SecurityRecommendation>) => {
						return this.securityRecommendationService.showRecommendationExceptionCreationPanel(
							securityRecommendations[0]
						);
					},
				},
				{
					id: 'openReportInaccuracy',
					nameKey: 'tvm.reportInaccuracy.reportButton',
					icon: FabricIconNames.Feedback,
					closeOnAction: methodResolution => methodResolution,
					method: (recommendations: Array<SecurityRecommendation>) =>
						this.reportInaccuracyService.openReportInaccuracyPanel(
							this.reportInaccuracyService.evaluateContext(false, recommendations[0]),
							recommendations[0]
						),
				}
			);

			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
		getTags: (securityRecommendations: SecurityRecommendation[]) =>
			// Only sending the first recommendation since we don't allow multiple selection
			this.tvmTagsService.getRecommendationTags(securityRecommendations[0]),

		loadFullEntityInPanel: false,
		dataViewOptions: <
			EntityDataViewOptions<SecurityRecommendation, SecurityRecommendationsDataViewFixedOptions>
		>{
			fields: SecurityRecommendationFieldsService,
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, format, searchTerm, dataQuery) => {
					const url = ResolveEntityURL({
						endPoint: TvmEndPoint.Analytics,
						entityModelBaseOrRelationship: SecurityRecommendation,
					});

					return this.tvmDownloadService.downloadCsv({
						url: url,
						fileName: 'export-tvm-recommendations',
						dataQuery: dataQuery,
					});
				},
			},
			dataViewConfig: {
				getFiltersData: () =>
					this.securityRecommendationService.getFiltersByTypes([
						FilterTypesEnum.RemediationType,
						FilterTypesEnum.RelatedComponent,
						FilterTypesEnum.RecommendationStatus,
						FilterTypesEnum.RecommendationTags,
						FilterTypesEnum.OsPlatform,
					]),
			},
		},
	};

	private buildDropdownInputs(recommendation: SecurityRecommendation): Array<ItemActionValue> {
		const vendor = recommendation.productId.split('-_-')[0];
		return recommendation.productsNames.map(product => {
			return {
				id: this.tvmTextsService.getText(TextToken.EntityName, product),
				name: this.tvmTextsService.getText(TextToken.EntityName, product),
				method: () => this.router.navigate([`/software-inventory/${vendor}-_-${product}`]),
			} as ItemActionValue;
		});
	}

	private isRecommendationHasSoftware(
		recommendation: SecurityRecommendation,
		options: { subResource?: boolean }
	): boolean {
		if (recommendation.remediationType === RemediationType.ConfigurationChange && recommendation.productName.toLowerCase() === 'linux') return false;
		if (this.isTvmExposedOperatingSystems && recommendation.productsNames.length > 1) return true;
		if (
			(!options || !options.subResource) &&
			recommendation.productId &&
			recommendation.productName &&
			recommendation.productName !== ''
		)
			return true;
		return false;
	}

	private buildOpenSoftwareAction(recommendation: SecurityRecommendation): ItemActionModelConfig {
		if (this.isTvmExposedOperatingSystems && recommendation.productsNames.length > 1) {
			return {
				id: 'navigateToSoftwares',
				nameKey: 'tvm.securityRecommendation.openSoftwarePage',
				icon: FabricIconNames.AppIconDefault,
				values: this.buildDropdownInputs(recommendation),
			};
		} else {
			return {
				id: 'navigateToSoftware',
				nameKey: 'tvm.securityRecommendation.openSoftwarePage',
				icon: FabricIconNames.AppIconDefault,
				closeOnAction: methodResolution => methodResolution,
				method: (recommendations: Array<SecurityRecommendation>) => {
					const url = `/software-inventory/${encodeURIComponent(recommendations[0].productId)}`;
					return this.router.navigate([url]);
				},
			};
		}
	}
}
