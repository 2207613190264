import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirsEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'entity-action',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<h4 *ngIf="showTitle" class="definition-list-title">
			{{ i18nService.strings.entities_action_title }}
		</h4>
		<dl role="none">
			<dt>{{ i18nService.strings.entities_action_title }}</dt>
			<dd>
				<wcd-shared-icon class="small-icon" [iconName]="entity.action.remediationActionType.icon">
				</wcd-shared-icon>
				{{ entity.action.remediationActionType.remediationText([entity]) }}
			</dd>
			<dt>{{ entity.action.status.name }} by</dt>
			<dd>
				<wcd-shared-icon [iconName]="entity.action.user.type.icon" class="small-icon">
				</wcd-shared-icon>
				{{
					entity.action.user.type.isSystem
						? i18nService.strings.airsEntities_action_user_system
						: entity.action.user.name
				}}
			</dd>
		</dl>
		<div class="error-message wcd-margin-top" *ngIf="entity.action.reason">
			{{ entity.action.reason }}
		</div>
	`,
})
export class EntityActionComponent {
	@Input() entity: AirsEntity;
	@Input() showTitle: boolean = true;

	constructor(public i18nService: I18nService) {}
}
