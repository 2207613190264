var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, OnInit, TrackByFunction, } from '@angular/core';
import { isSameDay, subDays, subMonths, subWeeks } from 'date-fns';
import { TzDateService } from '@wcd/localization';
import { isCustomTimeRangeValue, } from './time-range-value.model';
var CommandBarDatePickerComponent = /** @class */ (function () {
    function CommandBarDatePickerComponent(changeDetectorRef, tzDateService) {
        this.changeDetectorRef = changeDetectorRef;
        this.tzDateService = tzDateService;
        this.isCustomTimeRangeValue = isCustomTimeRangeValue;
        this.allowTimeSelection = true;
        this.currentTimeRangeChange = new EventEmitter();
        this.currentSelectionChange = new EventEmitter();
        this.customTimeRangesTrackBy = function (index, item) { return item.key; };
    }
    Object.defineProperty(CommandBarDatePickerComponent.prototype, "customTimeRange", {
        get: function () {
            if (this.currentTimeRange && isCustomTimeRangeValue(this.currentTimeRange)) {
                return this.currentTimeRange;
            }
            return null;
        },
        enumerable: true,
        configurable: true
    });
    CommandBarDatePickerComponent.prototype.ngOnInit = function () {
        if (!this.currentTimeRange) {
            this.currentTimeRange = this.timeRanges[0];
        }
    };
    CommandBarDatePickerComponent.prototype.onDateRangeChange = function (range, externalNotify) {
        this.currentSelectionChange.emit(range);
        this.currentTimeRange = range;
        this.changeDetectorRef.detectChanges();
        if (externalNotify) {
            this.currentTimeRangeChange.emit(this.currentTimeRange);
        }
    };
    CommandBarDatePickerComponent.prototype.onTimeRangeChange = function (range) {
        this.currentTimeRange = __assign({}, this.currentTimeRange, { id: "custom" /* custom */ });
        this.changeDetectorRef.markForCheck();
        this.currentTimeRangeChange.emit(this.currentTimeRange);
    };
    CommandBarDatePickerComponent.prototype.calculateSecondaryText = function (range) {
        var _this = this;
        var formatDate = function (date) {
            return _this.tzDateService.format(date, 'mediumDate', _this.sinceDateTimezone);
        };
        var getSinceText = function (date) { return "since " + formatDate(date); };
        var now = new Date();
        if (isCustomTimeRangeValue(range)) {
            if (range.customRange.from && range.customRange.to) {
                if (isSameDay(now, range.customRange.to)) {
                    return getSinceText(range.customRange.from);
                }
                return formatDate(range.customRange.from) + " - " + formatDate(range.customRange.to);
            }
            if (range.customRange.from) {
                return getSinceText(range.customRange.from);
            }
            if (range.customRange.to) {
                return getSinceText(range.customRange.to);
            }
            return '';
        }
        var rangeIdMap = {
            day: subDays(now, 1),
            '3days': subDays(now, 3),
            week: subWeeks(now, 1),
            month: subMonths(now, 1),
            '6months': subMonths(now, 6),
        };
        var sinceDate = this.useExactDays ? subDays(now, range.value) : rangeIdMap[range.id];
        return getSinceText(sinceDate);
    };
    return CommandBarDatePickerComponent;
}());
export { CommandBarDatePickerComponent };
