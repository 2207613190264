import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	QueryList,
} from '@angular/core';
import { fromEvent } from 'rxjs';
import { Subscription } from 'rxjs/Subscription';
import { DropdownComponent } from './dropdown.component';
import { debounceTime } from 'rxjs/operators';

const MARGIN = 50;

@Component({
	selector: 'wcd-nav-dropdown',
	templateUrl: './dropdown-base.component.html',
	styleUrls: ['./nav-dropdown.component.scss'],
})
export class NavDropdownComponent extends DropdownComponent implements OnInit, AfterViewInit, OnDestroy {
	private _onResizeSubscription: Subscription;
	@Input() setHeightAuto: boolean;

	@ContentChildren('accessibleListItem', { descendants: true }) set newDropdownItems(
		dropdown: QueryList<ElementRef>
	) {
		this.dropdownItems.reset(
			dropdown.filter(item => {
				return item.nativeElement.classList.contains('dropdownNav');
			})
		);
	}

	constructor(elementRef: ElementRef, protected changeDetectorRef: ChangeDetectorRef) {
		super(elementRef, changeDetectorRef);
	}

	ngOnInit() {
		super.ngOnInit();
		this.onToggle.subscribe(isOpen => {
			if (isOpen && this.focusedElementRef) {
				const focusedElement = this.dropdownItems.find(item => {
					return Boolean(JSON.parse(item.nativeElement.getAttribute('aria-selected')));
				});
				this.setFocusedElement(focusedElement);
				setTimeout(() => this.focusedElementRef.nativeElement.scrollIntoView());
			}
		});

	}

	ngAfterViewInit(){
		super.ngAfterViewInit();
		this.initOnResizeSubscription();
	}

	ngOnDestroy(){
		super.ngOnDestroy();
		this._onResizeSubscription.unsubscribe();
	}

	initOnResizeSubscription() {
		this._onResizeSubscription = fromEvent(window, 'resize')
			.pipe(debounceTime(500))
			.subscribe(() => {
				this.setMenuPositionAndShow();
			});
	}


	setMenuPositionAndShow() {
		const buttonRect = this.buttonEl.getBoundingClientRect();

		this.menuEl.style.top = buttonRect.bottom + 'px';
		this.menuEl.style.left = buttonRect.left + 'px';
		this.menuEl.style.minWidth = buttonRect.width - 1 + 'px';
		this.menuEl.style.height = this.setHeightAuto ? 'auto' : buttonRect.height * 5 + 'px';

		const documentHeight = document.documentElement.clientHeight;
		const maxHeight = documentHeight - MARGIN;

		const menuClientRect = this.menuEl.getBoundingClientRect();

		if (menuClientRect.height > maxHeight - buttonRect.bottom) {
			const newHeight = maxHeight - buttonRect.bottom;
			const minHeight = this.buttonEl.offsetHeight;

			this.menuEl.style.height = newHeight + 'px';
			if (newHeight < minHeight){
				this.menuEl.style.height = maxHeight + 'px';
				this.menuEl.style.top = MARGIN + 'px';
				this.menuEl.style.minWidth = buttonRect.width + 1 + 'px';
			}



		}

		this.isVisible = true;
		this.changeDetectorRef.detectChanges();
		// if getFocusedElementRef return value this element is a dropdown and focus should be on the first element in the dropdown.
		// if getFocusedElementRef return null this is a hover-card styled element and focus needs to be on the menuEL.
		const focusedElement = this.getFocusedElementRef();
		const nativeFocusedElement = focusedElement ? focusedElement.nativeElement : this.menuEl;
		nativeFocusedElement.focus();
	}
}
