var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ChangeEventCategory } from '@wcd/domain';
import { ChangeEventService } from './../services/change-event.service';
import { Router } from '@angular/router';
var WindowsRelatedApps;
(function (WindowsRelatedApps) {
    WindowsRelatedApps["Windows10"] = "microsoft-_-windows_10";
    WindowsRelatedApps["InternetExplorer"] = "microsoft-_-internet_explorer";
    WindowsRelatedApps["Edge"] = "microsoft-_-edge";
})(WindowsRelatedApps || (WindowsRelatedApps = {}));
var ChangeEventEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ChangeEventEntityPanelComponent, _super);
    function ChangeEventEntityPanelComponent(changeDetectorRef, router, changeEventService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.router = router;
        _this.changeEventService = changeEventService;
        return _this;
    }
    Object.defineProperty(ChangeEventEntityPanelComponent.prototype, "changeEvent", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ChangeEventEntityPanelComponent.prototype.initEntity = function (changeEvent, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, changeEvent, isExtendedData);
        this.changeDetectorRef.markForCheck();
    };
    ChangeEventEntityPanelComponent.prototype.navigateToRecommendation = function () {
        var prefix;
        var recommendationId;
        var _relatedProductId = this.changeEventService.getRelatedProductId(this.entity);
        if (this.entity.category === ChangeEventCategory.Vulnerability) {
            // handle Windows built-in apps
            prefix = 'va-_-';
            recommendationId =
                _relatedProductId === WindowsRelatedApps.InternetExplorer ||
                    _relatedProductId === WindowsRelatedApps.Edge
                    ? WindowsRelatedApps.Windows10
                    : _relatedProductId;
        }
        else {
            prefix = 'sca-_-';
            recommendationId = this.entity.scid;
        }
        this.router.navigate(['/security-recommendations', "" + prefix + recommendationId], {
            queryParams: {
                search: recommendationId,
            },
        });
    };
    return ChangeEventEntityPanelComponent;
}(EntityPanelComponentBase));
export { ChangeEventEntityPanelComponent };
