export const iconLibraries = {
	wcd: {
		name: 'wcd-icons',
		useClass: 'wcd-icons--',
	},
	fabric: {
		// https://dev.office.com/fabric#/styles/icons
		name: 'ms-Icon',
		useClass: 'ms-Icon--',
	},
	fabricReact: null,
	mdl2: {
		name: 'mdl2-icons',
		useClass: 'icon-',
	},
};

export interface IconLibrary {
	name: string;
	useClass?: string;
}
