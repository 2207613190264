import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { BaselineConfiguration, BaselineConfigurationDevice, BaselineProfileDevice } from '@wcd/domain';
import { FieldsService } from '../../../../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import {
	BaselineCompliantBarComponent,
} from '../../../../../../tvm/components/baseline-compliant-bar/baseline-compliant-bar.component';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmColorService } from '../../../../../../tvm/services/tvm-color.service';
import { BaselineDetectedValuesService } from '../../../../../../tvm/services/baseline-detected-value.service';

@Injectable()
export class BaselineConfigurationFieldsService implements FieldsService<BaselineConfiguration> {
	private _fields: Array<DataviewField<BaselineConfiguration>>;
	private _singleAssetFields: Array<DataviewField<BaselineConfiguration>>;
	private _applicableAssetsFields: Array<DataviewField<BaselineProfileDevice>>;

	constructor(
		private i18nService: I18nService,
		private tvmColorService: TvmColorService,
		private baselineDetectedValuesService: BaselineDetectedValuesService) {
	}

	get fields(): Array<DataviewField<BaselineConfiguration>> {
		if (!this._fields) {
			this._fields = this.getFields(false);
		}
		return this._fields;
	}

	get singleAssetFields(): Array<DataviewField<BaselineConfiguration>> {
		if (!this._singleAssetFields) {
			this._singleAssetFields = this.getFields(true);
		}
		return this._singleAssetFields;
	}

	getFields(singleAsset: boolean): Array<DataviewField<BaselineConfiguration>> {
		return DataviewField.fromList<BaselineConfiguration>([
			{
				id: 'configurationId',
				name: this.i18nService.strings.tvm_baseline_profiles_configurations_ID_title,
				getDisplay: (configuration: BaselineConfiguration) => configuration.id,
				sort: { enabled: false },
			},
			{
				id: 'name',
				name: this.i18nService.strings.tvm_common_name,
				getTooltip: (configuration: BaselineConfiguration) => configuration.name,
				maxWidth: 500,
				help: (configuration: BaselineConfiguration) => this.isCustomValues(configuration) ? this.i18nService.strings.tvm_baseline_compliant_enum_customized_values : null,
			},
			{
				id: 'category',
				name: this.i18nService.strings.tvm_baseline_profiles_configurations_category_title,
				getTooltip: (configuration: BaselineConfiguration) => configuration.category,
				filter: { priority: singleAsset ? 2 : 1 },
				maxWidth: 250,
			},
			singleAsset ?
				{
					id: 'compliantDevices',
					name: this.i18nService.strings
						.tvm_baseline_profiles_configurations_complianceStatus_title,
					icon: {
						fabricIcon: FabricIconNames.StatusCircleInner,
						className: (configuration: BaselineConfiguration) =>
							this.tvmColorService.getCompliantIcon(configuration.compliantDevices === 1),
					},
					getDisplay: (configuration: BaselineConfiguration) =>
						this.isInProgress(configuration) ? this.i18nService.strings.tvm_baseline_compliant_enum_in_progress :
							configuration.compliantDevices === 1
								? this.i18nService.strings.tvm_baseline_compliant_enum_compliant
								: this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant,
					maxWidth: 150,
					allowResize: false,
					filter: { priority: 1 },
					help: (configuration: BaselineConfiguration) => this.isInProgress(configuration) ? this.i18nService.strings.tvm_baseline_compliant_enum_in_progress_tooltip : null
				}
				: {
					id: 'compliantDevices',
					name: this.i18nService.strings
						.tvm_baseline_profiles_configurations_compliantDevices_title,
					getDynamicComponent: (configuration: BaselineConfiguration) => {
						if (this.isInProgress(configuration)) {
							return null;
						}
						return {
							type: BaselineCompliantBarComponent,
							getProps: (configuration: BaselineConfiguration) => ({
								showLegend: false,
								compliantCount: configuration.compliantDevices,
								notCompliantCount:
									configuration.applicableDevices - configuration.compliantDevices,
								total: configuration.applicableDevices,
								showPercentage: false,
								showNumbers: true,
							}),
						};
					},
					getDisplay: (configuration: BaselineConfiguration) => this.isInProgress(configuration) ? this.i18nService.strings.tvm_baseline_compliant_enum_in_progress : null,
					help: (configuration: BaselineConfiguration) => this.isInProgress(configuration) ? this.i18nService.strings.tvm_baseline_compliant_enum_in_progress_tooltip : null,
					maxWidth: 250,
					allowResize: true
				},
			{
				id: 'complianceLevels',
				name: this.i18nService.strings.baseline_profile_wizard_profile_compliance_level_label,
				getDisplay: (configuration: BaselineConfiguration) => {
					const levelsLength =
						configuration.complianceLevels && configuration.complianceLevels.length;
					const firstLevel = levelsLength ? configuration.complianceLevels[0] : '-';
					return levelsLength > 1 ? `${firstLevel} (+${levelsLength - 1} more)` : firstLevel;
				},
				getTooltip: (configuration: BaselineConfiguration) =>
					configuration.complianceLevels
						? `<div>
							${configuration.complianceLevels.join('<br>')}
						</div>`
						: '',
				valueTooltipAllowHtmlRendering: true,
				sort: { enabled: false },
				minWidth: 150,
				filter: { priority: singleAsset ? 3 : 2 },
			},
		]);
	}

	getApplicableAssetsFields = (configuration: BaselineConfiguration | BaselineConfigurationDevice): Array<DataviewField<BaselineConfigurationDevice>> => DataviewField.fromList<BaselineConfigurationDevice>([
		{
			id: 'name',
			name: this.i18nService.strings.tvm_common_name,
			icon: {
				fabricIcon: FabricIconNames.System,
			},
			getTooltip: (device: BaselineConfigurationDevice) => device.name,
			getLink: (device: BaselineConfigurationDevice) => `/machines/${encodeURIComponent(device.id)}/baseline-compliance`,
			sort: { enabled: false },
			className: 'nowrap wcd-text-overflow-medium',
		},
		{
			id: 'deviceCompliant',
			name: this.i18nService.strings.tvm_baseline_configuration_compliant_column_name,
			getDisplay: (device: BaselineConfigurationDevice) =>
				device.compliantConfigurations === 1 || device.isCompliant ?
					this.i18nService.strings.common_yes :
					this.i18nService.strings.common_no
			,
			filter: { priority: 1 },
		},
		this.baselineDetectedValuesService.isConfigurationSupported(configuration) &&
		{
			id: 'detectValue',
			name: this.i18nService.strings.tvm_baseline_profile_entity_configurationTab_sidePanel_currentState,
			getDisplay: (device: BaselineConfigurationDevice) => this.baselineDetectedValuesService.getConfigurationDetectedValuesString(device),
			sort: { enabled: false },
		},
	].filter(Boolean));

	isInProgress(configuration: BaselineConfiguration) {
		return !configuration || configuration.compliantDevices === -1;
	}

	// TODO: lischach to update logic once other value is present
	isCustomValues(configuration: BaselineConfiguration) {
		return configuration && configuration.id && configuration.id.includes(":");
	}
}
