import {
	FabButtonModule,
	FabDialogModule,
	FabSpinnerModule,
	FabTextFieldModule,
} from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationDialogComponent } from './confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog.service';
import { ConfirmationDialogTextInputContentComponent } from './content-components/confirmation-dialog-text-input-content.component';
import { MarkdownModule } from 'ngx-markdown';
import { I18nModule } from '@wcd/i18n';
import { WcdFormsModule } from '@wcd/forms';

const entryComponents = [ConfirmationDialogComponent, ConfirmationDialogTextInputContentComponent];

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		FabDialogModule,
		FabButtonModule,
		FabSpinnerModule,
		FabTextFieldModule,
		MarkdownModule,
		WcdFormsModule,
		I18nModule,
	],
	declarations: [...entryComponents],
	entryComponents: entryComponents,
	providers: [ConfirmationDialogService],
})
export class ConfirmationDialogModule {}
