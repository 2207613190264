import { Injectable } from '@angular/core';
import { Incident, IncidentLinkedByEntityTypeMapper, RelatedIncident } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { EntityNameComponent } from '../../../../global_entities/components/entity-name/entity-name.component';
import { GlobalEntityTypesService } from '../../../../global_entities/services/global-entity-types.service';
import { AlertIncidentService } from '../../../alerts/services/alert-incident.service';

@Injectable()
export class AssociatedIncidentsFields {
	private _fields: Array<DataTableField<RelatedIncident>>;
	constructor(
		private i18nService: I18nService,
		private entityTypeService: GlobalEntityTypesService,
		private alertIncidentService: AlertIncidentService
	) {}

	get fields(): Array<DataTableField<RelatedIncident>> {
		if (!this._fields) {
			this._fields = DataTableField.fromList([
				{
					id: 'incidentId',
					name: this.i18nService.get('incident.associatedIncidents.table.fields.incident.id'),
					sort: { enabled: false },
					component: {
						type: EntityNameComponent,
						getProps: (incident: RelatedIncident) => {
							return {
								entity: { id: incident.targetIncidentId },
								/**The EntityTypeService can't resolve 'RelatedIncident' (Not a real 'Incident').
								 * Since 'EntityNameComponent' only requires the incident id to generate a URL, we mimic the incident type by providing it alone **/
								entityTypeId: this.entityTypeService.getEntityType(Incident).entity,
								entityName: incident.targetIncidentId,
								hideIcon: true,
							};
						},
					},
				},
				{
					id: 'reason',
					name: this.i18nService.get('incident.associatedIncidents.table.fields.reason'),
					sort: { enabled: false },
					className: 'nowrap wcd-text-overflow-medium',
					getDisplay: (incident: RelatedIncident) =>
						this.alertIncidentService.getCategoryDisplayText(incident.category),
					getTooltip: (incident: RelatedIncident) =>
						this.alertIncidentService.getCategoryDisplayText(incident.category),
				},
				{
					id: 'entity',
					name: this.i18nService.get('incident.associatedIncidents.table.fields.entity'),
					sort: { enabled: false },
					component: {
						type: EntityNameComponent,
						getProps: (incident: RelatedIncident) => {
							const rawType = IncidentLinkedByEntityTypeMapper[incident.entity.type];
							return incident
								? {
										entity: incident.entity,
										entityName: incident.entity.name,
										entityTypeId: this.entityTypeService.getEntityType(rawType).entity,
										hideIcon: true,
								  }
								: null;
						},
					},
				},
			]);
		}
		return this._fields;
	}
}
