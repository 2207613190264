import { scheduleConfig } from '../services/airs-notification-rule-schedule-config.service';
import { AirsReportTypes } from '../services/airs-report-types.service';
import { find } from 'lodash-es';
var AirsNotificationRuleReportSchedule = /** @class */ (function () {
    function AirsNotificationRuleReportSchedule(data) {
        this.reportTypes = AirsReportTypes.ALL.map(function (_report) { return _report.id; });
        this.range = scheduleConfig.ranges[1];
        this.repeats = scheduleConfig.repeats[1];
        this.repeatEvery = 1;
        this.repeatDays = [0];
        this.time = '09:00';
        if (data) {
            this.reportTypes = data.report_type;
            this.range = find(scheduleConfig.ranges, function (_range) { return _range.value === data.report_period; });
            this.repeats = find(scheduleConfig.repeats, function (_repeats) { return _repeats.value === data.repeat_period; });
            this.repeatEvery = data.repeats;
            this.repeatDays = data.repeat_day;
            this.time = data.time;
        }
    }
    Object.defineProperty(AirsNotificationRuleReportSchedule.prototype, "isValid", {
        get: function () {
            if (!this.reportTypes.length)
                return false;
            if (!this.range || !this.repeats || !this.repeatEvery || !this.time)
                return false;
            if (this.repeats.requireDays && !this.repeatDays.length)
                return false;
            return true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleReportSchedule.prototype, "reportTypeValues", {
        get: function () {
            return this.reportTypes.map(function (reportTypeId) { return AirsReportTypes.getReportTypeById(reportTypeId); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleReportSchedule.prototype, "repeatDaysValues", {
        get: function () {
            return this.repeatDays.map(function (_day) { return scheduleConfig.repeatOn[_day]; });
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleReportSchedule.prototype.getBackendData = function () {
        var data = {
            report_type: this.reportTypes,
            report_period: this.range.value,
            repeats: this.repeatEvery,
            repeat_period: this.repeats.value,
            time: this.time,
        };
        if (this.repeats.requireDays)
            data.repeat_day = this.repeatDays;
        return data;
    };
    return AirsNotificationRuleReportSchedule;
}());
export { AirsNotificationRuleReportSchedule };
