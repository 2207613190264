var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
import { isNil } from 'lodash-es';
var FilterValuesToggleComponent = /** @class */ (function (_super) {
    __extends(FilterValuesToggleComponent, _super);
    function FilterValuesToggleComponent(changeDetectorRef) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        return _this;
    }
    Object.defineProperty(FilterValuesToggleComponent.prototype, "value", {
        get: function () {
            return this.selectedValues;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesToggleComponent.prototype.setSelectedValues = function (selectedValues) {
        this.changeDetectorRef.detectChanges();
    };
    FilterValuesToggleComponent.prototype.serialize = function () {
        var _a;
        if (isNil(this.selectedValues))
            return null;
        return _a = {}, _a[this.fieldId] = this.selectedValues.toString(), _a;
    };
    FilterValuesToggleComponent.prototype.deserialize = function (serializedValue) {
        var strVal = serializedValue[this.fieldId];
        if (isNil(strVal)) {
            return null;
        }
        if (strVal instanceof Array)
            strVal = strVal[0];
        return strVal === 'true';
    };
    FilterValuesToggleComponent.prototype.onChange = function (newValue) {
        this.selectedValues = newValue;
        this.filterValuesChange.emit(this.selectedValues);
    };
    return FilterValuesToggleComponent;
}(FilterValuesComponent));
export { FilterValuesToggleComponent };
