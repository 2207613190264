import { Entity, EntityField } from '@microsoft/paris';
import { airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsEmail } from './airs-entity.email';
import { dateStrToUtc } from '../../utils';

@Entity({
	...airsEntityConfig,
	singularName: 'Email Submission',
	pluralName: 'Email Submissions',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsEmailMessageSubmission,
})
export class AirsEmailSubmission extends AirsEmail {
	@EntityField()
	submitter: string;

	@EntityField({ data: 'timestamp', parse: dateStr => dateStrToUtc(dateStr) })
	timestamp: Date;

	@EntityField({ data: 'reported_on', parse: dateStr => dateStrToUtc(dateStr) })
	reportedOn: Date;

	@EntityField({ data: 'report_type' })
	reportTypeI18nKey: string;

	@EntityField({ data: 'submission_id' })
	submissionId: string;

	constructor(data) {
		super(data);
		this.entityName = this.subject;
	}
}
