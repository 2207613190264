import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AirsEmailCluster, getMailClusterInternalIdFromUrns } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';
import { Feature, FeaturesService } from '@wcd/config';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders, HttpXsrfTokenExtractor } from '@angular/common/http';
import { catchError, map, startWith } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { SccExportService } from '../../../../shared/services/scc-export.service';
import { ErrorsDialogService } from '../../../../dialogs/services/errors-dialog.service';

const LOADING_SYMBOL = Symbol();

@Component({
	selector: 'airs-email-cluster-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.mailCount">
				<ng-container *ngLet="(mailSubmission$ | async) as mailSubmission">
					<ng-container
						*ngIf="
							mailSubmission === LOADING_SYMBOL && emailClusterExtraDataEnabled;
							else detailedSubmissionField
						"
					>
						<div class="wcd-flex-center-all wcd-padding-all">
							<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
						</div>
					</ng-container>
					<ng-template #detailedSubmissionField>
						<ng-container *ngIf="mailSubmission && emailClusterExtraDataEnabled; else mailCount">
							<dt role="none">
								{{ i18nService.strings.airsEntities_emailCluster_fields_emailCount }}
							</dt>
							<dd role="none">{{ getMailSubmissionDetails(mailSubmission) }}</dd>
							<dt role="none">
								{{
									this.i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_mailSubmission_export
								}}
							</dt>
							<dd role="none">
								<button
									class="btn btn-link command-bar-item-button wcd-flex-center-vertical"
									data-track-type="Export"
									(click)="downloadMailSubmissionData(mailSubmission)"
									[wcdTooltip]="
										i18nService.strings
											.airsEntities_emailCluster_side_panel_view_fields_mailSubmission_export
									"
									#focusable
								>
									<wcd-shared-icon iconName="Download"></wcd-shared-icon>
								</button>
							</dd>
						</ng-container>
					</ng-template>
					<ng-template #mailCount>
						<dt role="none">
							{{ i18nService.strings.airsEntities_emailCluster_fields_emailCount }}
						</dt>
						<dd role="none">{{ entity.mailCount }}</dd>
					</ng-template>
				</ng-container>
			</ng-container>
			<ng-container *ngLet="(actionLog$ | async) as actionLog">
				<ng-container *ngIf="actionLog === LOADING_SYMBOL; else actionLogField">
					<div class="wcd-flex-center-all wcd-padding-all">
						<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
					</div>
				</ng-container>
				<ng-template #actionLogField>
					<ng-container *ngIf="actionLog">
						<ng-container *ngIf="actionLog.isManualRerun">
							<dt role="none">
								{{
									i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_actionLogs_remediationRestarted
								}}
							</dt>
							<dd role="none">
								{{
									this.i18nService.get(
										'emailCluster_actionLog_manualRerun_' + actionLog.isManualRerun,
										null,
										true
									) || actionLog.isManualRerun
								}}
							</dd>
						</ng-container>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs
							}}
						</dt>
						<dd role="none">{{ getActionLogDetails(actionLog) }}</dd>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionsLogs_remediationId_field
							}}
						</dt>
						<dd role="none">{{ actionLog.remediationId }}</dd>
						<dt role="none">
							{{
								i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs_status
							}}
						</dt>
						<dd role="none">{{ actionLog.status }}</dd>
						<dt role="none">
							{{
								this.i18nService.strings
									.airsEntities_emailCluster_side_panel_view_fields_actionLogs_export
							}}
						</dt>
						<dd role="none">
							<button
								class="btn btn-link command-bar-item-button wcd-flex-center-vertical"
								data-track-type="Export"
								(click)="downloadActionLogData(actionLog)"
								[wcdTooltip]="
									i18nService.strings
										.airsEntities_emailCluster_side_panel_view_fields_actionLogs_export
								"
								#focusable
							>
								<wcd-shared-icon iconName="Download"></wcd-shared-icon>
							</button>
						</dd>
					</ng-container>
				</ng-template>
			</ng-container>
			<ng-container *ngIf="entity.entityName">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_name }}</dt>
				<dd role="none">{{ entity.entityName }}</dd>
			</ng-container>
			<ng-container *ngIf="!entity.showClusterAdditionalData; else clusterAdditionalData">
				<ng-container *ngIf="threatsDisplay">
					<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
					<dd role="none">{{ threatsDisplay }}</dd>
				</ng-container>
			</ng-container>

			<ng-template #clusterAdditionalData>
				<!-- Threats collapsible section-->
				<dt role="none">
					{{ i18nService.strings.airsEntities_emailCluster_fields_threats_sectionTitle }}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings.airsEntities_emailCluster_fields_threats_sectionTitle
						"
						[visible]="true"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container *ngIf="entity.emailClusterThreats">
								<dt role="none">
									{{ i18nService.strings.airsEntities_general_fields_threats }}
								</dt>
								<dd role="none">{{ threatsDisplay }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_malware }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.malwareCount || 0 }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_phish }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.phishCount || 0 }}</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_highConfidencePhish }}
								</dt>
								<dd role="none">
									{{ entity.emailClusterThreats.highConfidencePhishCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_spam }}
								</dt>
								<dd role="none">{{ entity.emailClusterThreats.spamCount || 0 }}</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>

				<!-- Latest delivery locations collapsible section-->
				<dt role="none">
					{{
						i18nService.strings
							.airsEntities_emailCluster_fields_latestDeliveryLocations_sectionTitle
					}}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings
								.airsEntities_emailCluster_fields_latestDeliveryLocations_sectionTitle
						"
						[visible]="true"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container
								*ngIf="entity.emailClusterDeliveryLocations as emailClusterDeliveryLocations"
							>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_mailbox }}
								</dt>
								<dd role="none">{{ emailClusterDeliveryLocations.mailboxCount || 0 }}</dd>

								<dt role="none">
									{{ i18nService.strings.remediation_fields_notInMailbox }}
								</dt>
								<dd role="none">
									{{ emailClusterDeliveryLocations.notInMailboxCount || 0 }}
								</dd>

								<dt role="none">
									{{ i18nService.strings.remediation_fields_onPremOrExternal }}
								</dt>
								<dd role="none">
									{{ emailClusterDeliveryLocations.onPremOrExternalCount || 0 }}
								</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>

				<!-- Original delivery location collapsible section-->
				<dt role="none">
					{{
						i18nService.strings
							.airsEntities_emailCluster_fields_originalDeliveryLocations_sectionTitle
					}}
				</dt>
				<dd role="none">
					<generic-object
						[fieldName]="
							i18nService.strings
								.airsEntities_emailCluster_fields_originalDeliveryLocations_sectionTitle
						"
					>
						<dl class="key-values clearfix" role="none">
							<ng-container
								*ngIf="
									entity.emailClusterOriginalDeliveryLocations as emailClusterOriginalDeliveryLocations
								"
							>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_delivered }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.deliveredCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_junked }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.junkedCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_replaced }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.replacedCount || 0 }}
								</dd>
								<dt role="none">
									{{ i18nService.strings.remediation_fields_blocked }}
								</dt>
								<dd role="none">
									{{ emailClusterOriginalDeliveryLocations.blockedCount || 0 }}
								</dd>
							</ng-container>
						</dl>
					</generic-object>
				</dd>
			</ng-template>
			<ng-container *ngIf="entity.volumeAnomaly">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_volumeAnomaly }}</dt>
				<dd role="none">{{ entity.volumeAnomaly }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.queryTime">
				<dt role="none">{{ i18nService.strings.airsEntities_emailCluster_fields_queryTime }}</dt>
				<dd role="none">{{ entity.queryTime | date: 'short' }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsEmailClusterDetailsComponent implements OnChanges {
	@Input() entity: AirsEmailCluster;
	emailClusterExtraDataEnabled: boolean;
	mailSubmission$: Observable<FormattedMailSubmission | typeof LOADING_SYMBOL>;
	actionLog$: Observable<FormattedActionLog | typeof LOADING_SYMBOL>;
	emailClusterBatchId: string;
	threatsDisplay: string;

	SpinnerSize = SpinnerSize;
	LOADING_SYMBOL = LOADING_SYMBOL;

	constructor(
		private featuresService: FeaturesService,
		public i18nService: I18nService,
		private httpClient: HttpClient,
		public threatsDisplayNameService: ThreatsDisplayNameService,
		private httpXsrfTokenExtractor: HttpXsrfTokenExtractor,
		private sccExportService: SccExportService,
		private errorsDialogService: ErrorsDialogService
	) {}

	getMailSubmissionDetails(submission: FormattedMailSubmission): string {
		return (
			submission.emailSubmitted +
			' ' +
			this.i18nService.get('airsEntities_emailCluster_fields_remediableAndNonRemediableEmailCount', {
				remediableMailCount: submission.remediable,
				nonRemediableMailCount: submission.nonRemediable,
			})
		);
	}

	ngOnChanges() {
		this.threatsDisplay = this.threatsDisplayNameService.getThreatsDisplayName(this.entity.threats);

		this.emailClusterExtraDataEnabled = this.featuresService.isEnabled(Feature.EmailClusterExtraData);

		if (this.emailClusterExtraDataEnabled) {
			/*
			 calculate the internal ID for the given email cluster in order to
			 later match it up with action logs and mail submissions and find
			 which one of each is associated with the current email cluster.
			 Once the 'Admin playbook' is open to all customers this calculation will be removed and only
			 'mdoBatchId' field will be used.
			*/
			this.emailClusterBatchId =
				this.entity.mdoBatchId ||
				(this.entity.id &&
					this.entity.investigation &&
					this.entity.investigation.id &&
					getMailClusterInternalIdFromUrns(
						this.entity.investigation.id.toString(),
						this.entity.id.toString()
					));
			this.loadMailSubmission();
			this.loadActionLog();
		}
	}

	loadMailSubmission(): void {
		if (!this.entity.mdoInternalId) return;
		// TODO: use proxy - change 'api/EmailInvestigations' to 'ei' (task: https://microsoft.visualstudio.com/OS/_workitems/edit/31107111)
		this.mailSubmission$ = this.httpClient
			.get<{ CurrentPage: Array<MailSubmission> }>(
				`/api/EmailInvestigations/${this.entity.mdoInternalId}/EmailSubmissions`,
				{
					headers: new HttpHeaders({
						'X-ClientPage': window.location.pathname,
						'x-xsrf-token': this.httpXsrfTokenExtractor.getToken(),
					}),
				}
			)
			.pipe(
				map(mailSubmissions => {
					return (
						mailSubmissions &&
						mailSubmissions.CurrentPage &&
						mailSubmissions.CurrentPage.reduce((res, currentValue) => {
							const formattedMailSubmission = {
								batchId: currentValue.Id,
								createdDate: currentValue.CreatedDate,
								createdBy: currentValue.CreatedBy,
								emailSubmitted: currentValue.TotalCount,
								remediable: currentValue.RemediableCount,
								nonRemediable: currentValue.NonRemediableCount,
							};
							/*
							 If the mail submission currently being evaluated is the one directly associated with the current
							 email cluster add it to the result. There should be exactly one such mail submission.
							*/
							if (this.emailClusterBatchId === formattedMailSubmission.batchId)
								res.push(formattedMailSubmission);
							return res;
						}, [])
					);
				}),
				map(mailSubmissions => mailSubmissions && mailSubmissions.length === 1 && mailSubmissions[0]),
				catchError(err => of(null)),
				startWith(LOADING_SYMBOL)
			);
	}

	downloadMailSubmissionData(mailSubmission: FormattedMailSubmission) {
		// TODO: use proxy - change 'api/EmailInvestigations' to 'ei' (task: https://microsoft.visualstudio.com/OS/_workitems/edit/31107111)
		const url = `/api/EmailInvestigations/${this.entity.mdoInternalId}/EmailSubmissions/${
			mailSubmission.batchId
		}/Emails`;

		try {
			this.sccExportService.exportSccData(url);
		} catch (e) {
			this.errorsDialogService.showError({
				title: this.i18nService.strings.common_error,
				data: this.i18nService.strings
					.airsEntities_emailCluster_side_panel_view_fields_export_downloadError,
			});
		}
	}

	private sortActionsByTime(a: ActionLog, b: ActionLog) {
		if (a.CreatedDate === b.CreatedDate) return 0;
		return a.CreatedDate > b.CreatedDate ? 1 : -1;
	}

	loadActionLog() {
		if (!this.entity.mdoInternalId) return;
		// TODO: use proxy - change 'api/EmailInvestigations' to 'ei' (task: https://microsoft.visualstudio.com/OS/_workitems/edit/31107111)
		this.actionLog$ = this.httpClient
			.get<{ CurrentPage: Array<ActionLog> }>(
				`/api/EmailInvestigations/${this.entity.mdoInternalId}/Remediations`,
				{
					headers: new HttpHeaders({
						'X-ClientPage': window.location.pathname,
						'x-xsrf-token': this.httpXsrfTokenExtractor.getToken(),
					}),
				}
			)
			.pipe(
				map(actionLogs => {
					return (
						actionLogs &&
						actionLogs.CurrentPage &&
						actionLogs.CurrentPage.reduce((res, currentValue) => {
							const actionResult = currentValue.ActionResult;
							const actionTypeI18nKey = `emailCluster_actionLog_actionType_${
								currentValue.ActionType
							}`;
							const statusI18nKey = `emailCluster_actionLog_status_${currentValue.Status}`;
							/*
								TODO:
								 According to Nibiru code - need to show this data according to a condition
								 task: https://microsoft.visualstudio.com/OS/_workitems/edit/31107195
							 */
							const formattedActionLog = {
								batchId: currentValue.EmailSubmissionId,
								remediationId: currentValue.Id,
								approvedDate: currentValue.CreatedDate,
								approvedBy: currentValue.CreatedBy,
								actionType:
									this.i18nService.get(actionTypeI18nKey, null, true) ||
									currentValue.ActionType,
								status:
									this.i18nService.get(statusI18nKey, null, true) || currentValue.Status,
								remediable: actionResult ? actionResult.TotalCount : 0,
								failed: actionResult.FailedCount || 0,
								successful: actionResult.SuccessCount || 0,
								alreadyInDestination: actionResult.NonActionableCount || 0,
								isManualRerun: currentValue.IsManualRerun,
							};
							/*
							 If the action log currently being evaluated is the one directly associated with the current
							 email cluster add it to the result. There should be exactly one such action log.
							*/
							if (this.emailClusterBatchId === formattedActionLog.batchId)
								res.push(formattedActionLog);
							return res;
						}, [])
					);
				}),
				map(actionLogs => {
					if (actionLogs && actionLogs.length) {
						if (actionLogs.length > 1) {
							// If there are multiple results we should pick the latest result
							actionLogs.sort(this.sortActionsByTime);
						}

						return actionLogs[actionLogs.length - 1];
					}
					return null;
				}),
				catchError(err => of(null)),
				startWith(LOADING_SYMBOL)
			);
	}

	downloadActionLogData(formattedActionLog: FormattedActionLog) {
		// TODO: use proxy - change 'api/EmailInvestigations' to 'ei' (task: https://microsoft.visualstudio.com/OS/_workitems/edit/31107111)
		const url = `/api/EmailInvestigations/${this.entity.mdoInternalId}/Remediations/${
			formattedActionLog.remediationId
		}/export`;

		try {
			this.sccExportService.exportSccData(url);
		} catch (e) {
			this.errorsDialogService.showError({
				title: this.i18nService.strings.common_error,
				data: this.i18nService.strings
					.airsEntities_emailCluster_side_panel_view_fields_export_downloadError,
			});
		}
	}

	getActionLogDetails(actionLog: FormattedActionLog): string {
		return this.i18nService.get('airsEntities_emailCluster_side_panel_view_fields_actionsLogs_counts', {
			successfulCount: actionLog.successful,
			failedCount: actionLog.failed,
			alreadyInDestinationCount: actionLog.alreadyInDestination,
		});
	}
}

interface FormattedActionLog {
	batchId: string;
	remediationId: string;
	approvedDate: Date;
	approvedBy: string;
	actionType: string;
	status: string;
	remediable: number;
	failed: number;
	successful: number;
	alreadyInDestination: number;
	isManualRerun: boolean;
}

interface ActionLog {
	InvestigationId: string;
	Id: string;
	CreatedDate: Date;
	EmailSubmissionId: string;
	CreatedBy: string;
	ActionType: string;
	Status: string;
	IsManualRerun: boolean;
	ActionResult: {
		TotalCount: number;
		SuccessCount: number;
		NonActionableCount: number;
		FailedCount: number;
	};
}

interface FormattedMailSubmission {
	batchId: string;
	createdDate: Date;
	createdBy: string;
	emailSubmitted: number;
	remediable: number;
	nonRemediable: number;
}

interface MailSubmission {
	InvestigationId: string;
	Id: string;
	CreatedDate: Date;
	CreatedBy: string;
	TotalCount: number; // 'Messages submitted' column
	RemediableCount: number;
	NonRemediableCount: number;
}
