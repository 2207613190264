import { Component } from '@angular/core';
import { Machine } from '@wcd/domain';
import { EntityStatusComponentBase } from '../../../global_entities/components/entity-status/entity-status.component.base';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';

@Component({
	template: `
		<div
			*ngIf="machine.senseMachineId; else notOnboarded"
			class="wcd-flex-horizontal wcd-padding-small-top wcd-flex-horizontal-align-items-center"
		>
			<div *ngIf="machine.riskScore" class="wcd-margin-large-right wcd-flex-horizontal">
				<span class="wcd-padding-small-right" [wcdTooltip]="'machines.riskLevel' | i18n">{{
					'machineRiskScore.title' | i18n
				}}</span>
				<div class="wcd-font-weight-semibold">
					<span
						class="wcd-severity"
						[ngClass]="'wcd-severity-' + machine.riskScore.id.toLocaleLowerCase()"
						>{{ 'machineRiskScore.' + machine.riskScore.id | i18n }}</span>
				</div>
			</div>

			<ng-container *ngIf="withExposureLevel && machine.senseMachineId && machine.exposureScore">
				<span class="wcd-padding-small-right" [wcdTooltip]="'machines.exposureLevel' | i18n">{{
					'machineExposureLevel.title' | i18n
				}}</span>
				<wcd-shared-icon
					[iconName]="machine.exposureScore.icon"
					[ngClass]="'color-text-' + machine.exposureScore.className"
					class="small-icon wcd-font-weight-semibold wcd-margin-xsmall-right"
				></wcd-shared-icon>
				<span class="wcd-font-weight-semibold">{{ ('machineExposureScoreType_' + machine.exposureScore.id) | i18n }}</span>
			</ng-container>
		</div>
		<ng-template #notOnboarded>
			<div class="wcd-margin-small-top">
				<wcd-info-box>
					<markdown> {{ 'machines.notOnboardedCta' | i18n }}</markdown>
				</wcd-info-box>
			</div>
		</ng-template>
	`,
})
export class MachineEntityStatusComponent extends EntityStatusComponentBase<Machine> {
	get machine() {
		return this.entity;
	}

	withExposureLevel: boolean;

	constructor(private readonly flavorService: FlavorService) {
		super();
		this.withExposureLevel = flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
	}
}
