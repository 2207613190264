import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Router } from '@angular/router';
import { TabModel } from '../../shared/components/tabs/tab.model';
import { ResearcherAssistanceApiCall } from '@wcd/domain';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { isNil } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'wcd-feedback-modal',
	template: `
		<modal
			(close)="destroy()"
			[settings]="settings"
			data-html2canvas-ignore
			data-track-component="FeedbackModal"
		>
			<div class="wcd-full-height wcd-flex-vertical">
				<div class="wcd-flex-none dialog-contents">
					<tabs
						[tabsData]="tabs"
						[currentTab]="currentTabId"
						(select)="selectTab($event.tab)"
					></tabs>
				</div>
				<div class="wcd-flex-1 wcd-scroll-vertical">
					<ng-container *ngIf="currentTabId === 'collect'; else openTicket">
						<wcd-feedback
							[isEmailOptional]="false"
							[includeCause]="true"
							submitI18nKey="researcherAssistance.submit"
							i18nType="researcherAssistance."
							[submitApiCall]="submitApiCall"
							(done)="onCollect($event)"
						></wcd-feedback>
					</ng-container>
					<ng-template #openTicket>
						<div class="dialog-contents">
							<markdown [data]="'researcherAssistance.done.text' | i18n"></markdown>
							<p class="wcd-margin-top">
								<strong>
									{{ 'researcherAssistance.done.id' | i18n }}:
									<span class="color-text-primary">{{ ticketId }}</span>
								</strong>
							</p>
							<div class="wcd-margin-large-top">
								<fab-primary-button
									data-track-id="OpenTicket"
									data-track-type="Button"
									target="_blank"
									href="https://go.microsoft.com/fwlink/?linkid=2044716"
								>
									{{ 'researcherAssistance.openTicket' | i18n }}
								</fab-primary-button>
							</div>
						</div>
					</ng-template>
				</div>
			</div>
		</modal>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AskThreatExpertModalComponent extends ModalContainer {
	readonly submitApiCall = ResearcherAssistanceApiCall;

	tabs: Array<TabModel>;

	currentTabId: AskThreatExpertTabId = 'collect';
	ticketId: number;

	constructor(router: Router, private i18nService: I18nService) {
		super(router);

		this.setTabs();
	}

	onCollect(ticketId: number) {
		this.ticketId = ticketId;
		this.currentTabId = 'openTicket';
		this.setTabs();
	}

	selectTab(tab: TabModel) {
		this.currentTabId = <AskThreatExpertTabId>tab.id;
	}

	setTabs() {
		this.tabs = [
			{
				id: 'collect',
				name: `${this.ticketId ? '' : '1. '}${this.i18nService.get(
					'researcherAssistance.tabs.collect'
				)}`,
				icon: this.ticketId ? 'success' : null,
				iconClassName: 'ms-color-green',
			},
			{
				id: 'openTicket',
				name: `2. ${this.i18nService.get('researcherAssistance.tabs.openTicket')}`,
				disabled: isNil(this.ticketId),
			},
		].map(tab => new TabModel(tab));
	}
}

type AskThreatExpertTabId = 'collect' | 'openTicket';
