import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { AssetHardwareAndFirmware } from './asset-hardware-and-firmware.entity';

@ApiCall({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	name: 'asset-hardware-and-firmware-api-call',
	method: 'GET',
	endpoint: (_, query) => `assets/${query.where['assetId']}/hardwareAndFirmwareInfo`,
	cache: null,
})
export class GetAssetHardwareAndFirmwareApiCall extends ApiCallModel<AssetHardwareAndFirmware[]> {}
