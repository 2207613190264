import { SnackBarMethod, SnackBarMethodConfig } from './snackbar-method.model';

var lastSnackBarId = 0;

export class SnackBar {
	id: string;
	text: string;
	icon: string;
	iconClassName: string;
	className: string;
	method: SnackBarMethod;
	data: any;
	// set focus on element when the snackbar close. (different then the one that trigger the snackbar)
	focusableQuery: string;
	disableForceFocus: boolean;

	constructor(data: SnackBarConfig) {
		this.id = data.id || `snackbar-${lastSnackBarId++}`;
		this.text = data.text;
		this.icon = data.icon;
		this.iconClassName = data.iconClassName;
		this.data = data.data;
		this.className = data.className;
		this.focusableQuery = data.focusableQuery;
		this.disableForceFocus = data.disableForceFocus;
		if (data.method) {
			this.method = new SnackBarMethod(data.method);
		}
	}
}

export interface SnackBarConfig {
	id?: string;
	text: string;
	className?: string;
	icon?: string;
	iconClassName?: string;
	method?: SnackBarMethodConfig;
	data?: any;
	focusableQuery?: string;
	disableForceFocus?: boolean;
}
