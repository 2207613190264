var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Router } from '@angular/router';
import { PrettyNumberService } from '@wcd/prettify';
import { map } from 'rxjs/operators';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { FeaturesService } from '@wcd/config';
import { TrackingEventType } from '../../../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../../../insights/services/app-insights.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { MachinesFiltersService } from '../../../../../@entities/machines/services/machines.filters.service';
import { I18nService } from '@wcd/i18n';
var MORE_INFO_URL = 'https://go.microsoft.com/fwlink/p/?linkid=839876';
var MISCONFIGURED_ID = 'Misconfigured';
var INACTIVE_ID = 'Inactive';
var SensorHealthWidget = /** @class */ (function (_super) {
    __extends(SensorHealthWidget, _super);
    function SensorHealthWidget(reportsService, router, featuresService, serviceUrlsService, appInsightsService, appConfigService, prettyNumberService, machinesFiltersService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.router = router;
        _this.featuresService = featuresService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.appInsightsService = appInsightsService;
        _this.appConfigService = appConfigService;
        _this.prettyNumberService = prettyNumberService;
        _this.machinesFiltersService = machinesFiltersService;
        _this.i18nService = i18nService;
        _this.pieChartTitle$ = _this.data$.pipe(map(function (data) {
            return _this.prettyNumberService.prettyNumber(data ? data.legendData.map(function (item) { return item.value; }).reduce(function (a, b) { return a + b; }, 0) : 0);
        }));
        _this.legendData$ = _this.data$.pipe(map(function (data) {
            return data && data.legendData;
        }));
        _this.chartData$ = _this.data$.pipe(map(function (data) {
            return data && data.sensorHealthData;
        }));
        _this.settings = {
            dataPath: 'columns',
            options: {
                data: {
                    colors: {
                        Misconfigured: '#0078D4',
                        Inactive: '#d9d9d9',
                    },
                    onclick: function (e) { return _this.navigateToMachinesPage(e.id); },
                },
                tooltip: {
                    format: {
                        value: function (name, ratio) {
                            return name;
                        },
                    },
                },
                donut: {
                    title: _this.i18nService.get('securityOperations_sensorHealth_pie_centerlabel'),
                },
                legend: {
                    show: false,
                },
                size: {
                    width: 250,
                    height: 200,
                },
            },
        };
        _this.navigateToMachinesPage = function (category) {
            _this.appInsightsService.track({
                id: category,
                type: TrackingEventType.Navigation,
                component: String(_this.widgetConfig.id),
                componentType: 'Widget',
            });
            _this.router.navigate(['/machines'], {
                queryParams: {
                    filters: "healthStatuses=" + encodeURIComponent(category || 'Inactive|Misconfigured'),
                    ordering: 'healthStatuses',
                    range: 180,
                },
            });
        };
        return _this;
    }
    Object.defineProperty(SensorHealthWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'sensorHealth',
                name: this.i18nService.get('securityOperations_sensorHealth_widgetTitle'),
                api: {
                    url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/GetMachinesHealth"; },
                    isExternal: true,
                },
                parseData: this.parseData.bind(this),
            };
        },
        enumerable: true,
        configurable: true
    });
    SensorHealthWidget.prototype.parseData = function (data) {
        var _a;
        var misconfiguredValue = Object.keys(data)
            .filter(function (key) { return key.startsWith(MISCONFIGURED_ID); })
            .reduce(function (sum, key) {
            return sum + data[key];
        }, 0);
        var inactiveValue = data.InactiveRecent || 0;
        var legendData = [
            {
                name: this.i18nService.get('securityOperations_sensorHealth_misconfigured_label'),
                value: misconfiguredValue,
                iconClassName: 'ms-fontColor-themePrimary',
                helpKey: 'securityOperations.sensorHealth.misconfigured',
            },
            {
                name: this.i18nService.get('securityOperations_sensorHealth_inactive_label'),
                value: inactiveValue,
                iconClassName: 'ms-fontColor-neutralQuaternaryAlt',
                helpKey: 'securityOperations.sensorHealth.inactive',
            },
        ];
        var sensorHealthData = {
            columns: [
                { name: MISCONFIGURED_ID, value: misconfiguredValue },
                { name: INACTIVE_ID, value: inactiveValue },
            ],
            names: (_a = {},
                _a[MISCONFIGURED_ID] = this.i18nService.get('securityOperations_sensorHealth_misconfigured_label'),
                _a[INACTIVE_ID] = this.i18nService.get('securityOperations_sensorHealth_inactive_label'),
                _a),
        };
        return { legendData: legendData, sensorHealthData: sensorHealthData };
    };
    return SensorHealthWidget;
}(ReportWidgetComponent));
export { SensorHealthWidget };
