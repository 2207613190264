var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { RemediationAction } from '@wcd/domain';
import { RemediationActionEntityPanelComponent } from '../components/remediation-action.entity-panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RemediationActionsService } from './remediation-actions.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { from, of, timer } from 'rxjs';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AuthService } from '@wcd/auth';
import { map } from 'rxjs/operators';
import { omit } from 'lodash-es';
import { INVESTIGATION_REFRESH_RATE } from '../../mtp_investigations/services/mtp-investigations.service';
var RemediationActionEntityTypeService = /** @class */ (function () {
    function RemediationActionEntityTypeService(remediationActionsService, i18nService, authService) {
        var _this = this;
        this.remediationActionsService = remediationActionsService;
        this.i18nService = i18nService;
        this.authService = authService;
        this.entityType = {
            id: 'remediation-action',
            entity: RemediationAction,
            loadFullEntityInPanel: false,
            getIcon: function (remediationActions) {
                return remediationActions[0].remediationActionType.icon;
            },
            getIconCssClass: function (remediationActions) {
                return "round-icon color-box-warning-dark";
            },
            singleEntityPanelComponentType: RemediationActionEntityPanelComponent,
            getEntityName: function (remediationAction) {
                return remediationAction.remediationActionType.titleText(1);
            },
            getEntitiesLink: function (remediationActions) {
                return remediationActions.length === 1
                    ? "/investigation/" + remediationActions[0].investigationId
                    : null;
            },
            getEntitiesLinkText: function () { return _this.i18nService.strings.actionHistory_panel_button_openInvestigationPage; },
            getActions: function (actions) {
                var isSecAdmin = _this.authService.currentUser.isSecAdmin;
                var isAllowed = isSecAdmin || actions.every(function (a) { return !a.isOfficeInvestigation; });
                var ignoreRbac = sccHostService.isSCC && actions.every(function (a) { return a.isOfficeInvestigation; });
                var isAllowed$;
                if (!isAllowed && sccHostService.isSCC) {
                    isAllowed$ = from(sccHostService.auth.isInRole(SccRoles.searchAndPurge));
                }
                else {
                    isAllowed$ = of(isAllowed);
                }
                return isAllowed$.pipe(map(function (hasPermission) {
                    return [
                        {
                            id: 'approveRemediationAction',
                            nameKey: 'remediationActions_approve',
                            icon: 'checkCircle',
                            tooltip: hasPermission
                                ? _this.i18nService.strings.remediationActions_tooltips_approve
                                : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                            method: function (remediationActions) { return __awaiter(_this, void 0, void 0, function () {
                                var confirmEvent;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.remediationActionsService.approvePendingActions(remediationActions, false)];
                                        case 1:
                                            confirmEvent = _a.sent();
                                            if (!(confirmEvent &&
                                                confirmEvent.confirmed &&
                                                actions.some(function (a) { return a.isOfficeInvestigation; }))) return [3 /*break*/, 3];
                                            return [4 /*yield*/, timer(INVESTIGATION_REFRESH_RATE).toPromise()];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3:
                                            this.remediationActionsService.showNotificationOnApprove(remediationActions);
                                            return [2 /*return*/, confirmEvent];
                                    }
                                });
                            }); },
                            refreshOnResolve: true,
                            allowRbacTooltipOverride: true,
                            rbac: [RbacMdeAllowedActions.remediationActions],
                            closeOnAction: true,
                            disabled: !hasPermission,
                        },
                        {
                            id: 'rejectRemediationAction',
                            nameKey: 'remediationActions_dismiss',
                            icon: 'block',
                            tooltip: hasPermission
                                ? _this.i18nService.strings.remediationActions_tooltips_decline
                                : _this.i18nService.strings.common_permissions_noPermissionTooltip,
                            method: function (remediationActions) { return __awaiter(_this, void 0, void 0, function () {
                                var confirmEvent;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, this.remediationActionsService.confirmAndDismissPendingActionsByType(remediationActions)];
                                        case 1:
                                            confirmEvent = _a.sent();
                                            if (!(confirmEvent &&
                                                confirmEvent.confirmed &&
                                                actions.some(function (a) { return a.isOfficeInvestigation; }))) return [3 /*break*/, 3];
                                            return [4 /*yield*/, timer(INVESTIGATION_REFRESH_RATE).toPromise()];
                                        case 2:
                                            _a.sent();
                                            _a.label = 3;
                                        case 3: return [2 /*return*/, confirmEvent];
                                    }
                                });
                            }); },
                            refreshOnResolve: true,
                            allowRbacTooltipOverride: true,
                            rbac: [RbacMdeAllowedActions.remediationActions],
                            closeOnAction: function (confirmData) { return confirmData && confirmData.confirmed; },
                            disabled: !hasPermission,
                        },
                    ].map(function (itemActionConfig) {
                        return new ItemActionModel(ignoreRbac
                            ? omit(itemActionConfig, 'rbac', 'allowRbacTooltipOverride')
                            : itemActionConfig);
                    });
                }));
            },
        };
    }
    return RemediationActionEntityTypeService;
}());
export { RemediationActionEntityTypeService };
