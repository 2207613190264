import {
	EndpointManagementFlow,
	EndpointManagementProcess,
	EndpointManagementProcessType,
	EndpointManagementPackage,
} from '../models/endpoint-management-process.model';
import { FeaturesService, Feature } from '@wcd/config';
import { Injectable } from '@angular/core';
import { find } from 'lodash-es';

export const MANAGEMENT_FLOWS: { [index: string]: EndpointManagementFlow } = {
	onboarding: { id: 'onboarding', enabled: true },
	offboarding: { id: 'offboarding', enabled: true },
};

export const PROCESS_TYPES: { [index: string]: EndpointManagementProcessType } = {
	sense: { id: 'sense' },
	agent: { id: 'agent', features: [Feature.ServerEndpointManagement] },
	nonWindows: { id: 'nonWindows', features: [Feature.NonWindowsSupportControl] },
	macOS: { id: 'macOS', features: [Feature.NonWindowsSupportControlV2] },
	linuxServer: { id: 'linuxServer', features: [Feature.NonWindowsSupportControlV2] },
	linuxWorkstation: { id: 'linuxWorkstation', features: [Feature.LinuxWorkstationOnboarding] },
	nonWindows3rdParty: { id: 'nonWindows3rdParty', features: [Feature.NonWindowsSupportControlV2] },
	android: { id: 'android', features: [Feature.NonWindowsSupportControlV2] },
};

export const SENSE_ENDPOINT_MANAGEMENT_PACKAGES: { [index: string]: EndpointManagementPackage } = {
	localScript: {
		id: 3,
		name: 'localScript',
		disableFeatures: [Feature.DemoOnboardingScript],
	},
	localScriptDemo: {
		id: 4,
		name: 'localScriptDemo',
		features: [Feature.DemoOnboardingScript],
	},
	groupPolicy: {
		id: 0,
		name: 'groupPolicy',
	},
	SCCM1606: {
		id: 2,
		name: 'sccm1606',
	},
	SCCM: {
		id: 1,
		name: 'sccm',
	},
	SCCM_all: {
		id: 1,
		name: 'sccm_all',
	},
	SCCM2107: {
		id: 2,
		name: 'sccm2107',
	},
	MDM: {
		id: 2,
		name: 'mdm',
	},
	VDI: {
		id: 5,
		name: 'vdi',
		features: [Feature.VdiEndpointManagement],
		disableFlow: [MANAGEMENT_FLOWS.offboarding],
	},
	MacOsScript: {
		id: 7,
		name: 'macOsScript',
		features: [Feature.MacOsOnboarding],
	},
	MacOsIntune: {
		id: 6,
		name: 'macOsIntune',
		features: [Feature.MacOsOnboarding],
	},
	LinuxServerScript: {
		id: 9,
		name: 'linuxServerScript',
		features: [Feature.LinuxServerOnboarding],
	},
	LinuxServerMgmtTool: {
		id: 8,
		name: 'linuxServerMgmtTool',
		features: [Feature.LinuxServerOnboarding],
	},
	LinuxWorkstationScript: {
		id: 11,
		name: 'linuxWorkstationScript',
		features: [Feature.LinuxWorkstationOnboarding],
	},
	LinuxWorkstationMgmtTool: {
		id: 10,
		name: 'linuxWorkstationMgmtTool',
		features: [Feature.LinuxWorkstationOnboarding],
	},
};

const PROCESSES: Array<EndpointManagementProcess> = [
	{
		id: 0,
		name: 'windows7',
		processType: PROCESS_TYPES.agent,
		features: [Feature.Windows7SupportControl],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 1,
		name: 'windows10',
		processType: PROCESS_TYPES.sense,
		isDefault: true,
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM2107,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 2,
		name: 'server2008',
		processType: PROCESS_TYPES.agent,
		isServer: true,
		disableFeatures: [Feature.WS2012StandaloneAgentSupport],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 2,
		name: 'server2008_mma',
		processType: PROCESS_TYPES.agent,
		isServer: true,
		features: [Feature.WS2012StandaloneAgentSupport],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 3,
		name: 'server2012',
		processType: PROCESS_TYPES.sense,
		isServer: true,
		isDownlevel: true,
		features: [Feature.WS2012StandaloneAgentSupport],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 3,
		name: 'server1803',
		processType: PROCESS_TYPES.sense,
		isServer: true,
		features: [Feature.WindowsServer1803SupportControl],
		disableFeatures: [Feature.WindowsServer2019SupportControl],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 3,
		name: 'server2019',
		processType: PROCESS_TYPES.sense,
		isServer: true,
		features: [Feature.WindowsServer2019SupportControl],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 4,
		name: 'otherOS',
		processType: PROCESS_TYPES.nonWindows,
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScriptDemo,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.groupPolicy,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM2107,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.VDI,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 5,
		name: 'macOS',
		processType: PROCESS_TYPES.macOS,
		features: [Feature.MacOsOnboarding],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScriptDemo,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.groupPolicy,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM2107,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.VDI,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 6,
		name: 'linuxServer',
		isServer: true,
		processType: PROCESS_TYPES.linuxServer,
		features: [Feature.LinuxServerOnboarding],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScriptDemo,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.groupPolicy,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM2107,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.VDI,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxWorkstationMgmtTool,
		],
	},
	{
		id: 7,
		name: 'linuxWorkstation',
		isServer: false,
		processType: PROCESS_TYPES.linuxWorkstation,
		features: [Feature.LinuxWorkstationOnboarding],
		unsupportedPackageTypes: [
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.localScriptDemo,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.groupPolicy,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM_all,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM1606,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.SCCM2107,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MDM,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.VDI,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.MacOsIntune,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerScript,
			SENSE_ENDPOINT_MANAGEMENT_PACKAGES.LinuxServerMgmtTool,
		],
	},
	{
		id: 8,
		name: 'iOS',
		processType: PROCESS_TYPES.nonWindows3rdParty,
		features: [Feature.iOSOnboarding],
		unsupportedPackageTypes: [],
	},
	{
		id: 9,
		name: 'android',
		processType: PROCESS_TYPES.android,
		features: [Feature.AndroidOnboarding],
	},
].map(processConfig => new EndpointManagementProcess(processConfig));

@Injectable()
export class EndpointManagementProcessesService {
	get all(): Array<EndpointManagementProcess> {
		return PROCESSES.filter((endpointProcess: EndpointManagementProcess) =>
			this.isProcessEnabled(endpointProcess)
		);
	}

	get default(): EndpointManagementProcess {
		return find(PROCESSES, process => !!process.isDefault);
	}

	constructor(private featuresService: FeaturesService) {}

	private isProcessEnabled(process: EndpointManagementProcess): boolean {
		if (process.features && !this.featuresService.isAnyEnabled(process.features)) return false;

		if (process.disableFeatures && this.featuresService.isAnyEnabled(process.disableFeatures))
			return false;

		if (process.processType.features && !this.featuresService.isAnyEnabled(process.processType.features))
			return false;

		return true;
	}
}
