/* tslint:disable:template-accessibility-label-for */
import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { Disableable, DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { Paris } from '@microsoft/paris';
import { SystemExclusion, SystemExclusionListType, SystemExclusionType } from '@wcd/domain';
import { RegExpService } from '@wcd/shared';
import { catchError, finalize } from 'rxjs/operators';
import { Subscription, throwError } from 'rxjs';
import { ErrorsService } from '../../../shared/services/errors.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'system-exclusion-edit',
	templateUrl: './system-exclusion-edit.component.html',
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => SystemExclusionEditComponent),
		},
	],
})
export class SystemExclusionEditComponent implements Disableable {
	@Input() title: string;
	@Input() systemExclusion: SystemExclusion;
	@Input() systemExclusionType: SystemExclusionType;
	@Input() systemExclusionListTypes: Array<SystemExclusionListType>;
	@Input() editableProperties: Array<DataviewField>;
	@Input() formIsDirtyOnInit: boolean = false;
	@Input() readonly: boolean = false;
	@Output() done: EventEmitter<SystemExclusion> = new EventEmitter<SystemExclusion>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	constructor(private paris: Paris, public i18nService: I18nService) {}

	disableReason: string;
	RegExpService = RegExpService;
	isSaving: boolean;
	error: string;

	private savingSubscription: Subscription;

	save() {
		this.isSaving = true;
		this.error = null;
		this.savingSubscription = this.paris
			.getRepository(SystemExclusion)
			.save(this.systemExclusion, { params: { type: this.systemExclusionType.id } })
			.pipe(
				catchError(err => {
					this.error = ErrorsService.getErrorMessage(err);
					return throwError(err);
				}),
				finalize(() => (this.isSaving = false))
			)
			.subscribe((systemExclusion: SystemExclusion) => this.done.emit(systemExclusion));
	}

	cancelEdit() {
		this.savingSubscription && this.savingSubscription.unsubscribe();
		this.cancel.emit(null);
	}

	onFileSelect(event, systemExclusionForm) {
		const files = event.srcElement.files;
		this.systemExclusion.file = files && files[0];
		systemExclusionForm.form.markAsDirty();
	}
}
