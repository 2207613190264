import { DeviceType } from './device-type.enum';
import { keyBy } from 'lodash-es';
var ɵ0 = DeviceType.Workstation, ɵ1 = DeviceType.Server, ɵ2 = DeviceType.Mobile;
var endpointDeviceTypes = [
    {
        id: ɵ0,
        i18nNameKey: 'devices_page_device_type_value_wrokstation',
        priority: 9,
    },
    {
        id: ɵ1,
        i18nNameKey: 'devices_page_device_type_value_server',
        priority: 10,
    },
    {
        id: ɵ2,
        i18nNameKey: 'devices_page_device_type_value_mobile',
        priority: 11,
    },
];
var ɵ3 = DeviceType.Printer, ɵ4 = DeviceType.AudioAndVideo, ɵ5 = DeviceType.Surveillance, ɵ6 = DeviceType.SmartAppliance, ɵ7 = DeviceType.Communication, ɵ8 = DeviceType.SmartFacility, ɵ9 = DeviceType.Miscellaneous, ɵ10 = DeviceType.Unknown;
export var iotDeviceTypes = [
    {
        id: ɵ3,
        i18nNameKey: 'devices_page_device_type_value_printer',
        priority: 2,
    },
    {
        id: ɵ4,
        i18nNameKey: 'devices_page_device_type_value_audio_and_video',
        priority: 3,
    },
    {
        id: ɵ5,
        i18nNameKey: 'devices_page_device_type_value_surveillance',
        priority: 4,
    },
    {
        id: ɵ6,
        i18nNameKey: 'devices_page_device_type_value_smart_appliance',
        priority: 6,
    },
    {
        id: ɵ7,
        i18nNameKey: 'devices_page_device_type_value_communication',
        priority: 7,
    },
    {
        id: ɵ8,
        i18nNameKey: 'devices_page_device_type_value_smart_facility',
        priority: 6,
    },
    {
        id: ɵ9,
        i18nNameKey: 'devices_page_device_type_value_miscellaneous',
        priority: 108,
    },
    {
        id: ɵ10,
        i18nNameKey: 'devices_page_device_type_value_unknown',
        priority: 109,
    },
];
var ɵ11 = DeviceType.NetworkDevice;
export var networkDeviceTypes = [
    {
        id: ɵ11,
        i18nNameKey: 'devices_page_device_type_value_network_device',
        priority: 1,
    },
];
var ɵ12 = DeviceType.Desktop;
// those deprecated types are used for translation and mapping of old types
var deprecatedEndpointTypes = [
    {
        id: ɵ12,
        i18nNameKey: 'devices_page_device_type_value_wrokstation',
        priority: 9,
        isDeprecated: true,
        mappedInto: DeviceType.Workstation,
    },
];
var ɵ13 = DeviceType.GamingConsole, ɵ14 = DeviceType.GenericIoT;
var deprecatedIotTypes = [
    {
        id: ɵ13,
        i18nNameKey: 'devices_page_device_type_value_gaming_console',
        priority: 3,
        isDeprecated: true,
        mappedInto: DeviceType.AudioAndVideo,
    },
    {
        id: ɵ14,
        i18nNameKey: 'devices_page_device_type_value_generic_iot',
        priority: 107,
        isDeprecated: true,
        mappedInto: DeviceType.Miscellaneous,
    },
];
export var deviceTypes = [].concat(iotDeviceTypes, networkDeviceTypes, deprecatedIotTypes, endpointDeviceTypes, deprecatedEndpointTypes);
var ɵ15 = function (t) { return t.id; };
export var deviceTypesMap = keyBy(deviceTypes, ɵ15);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15 };
