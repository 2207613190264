import { ReadOnlyIdentifiable } from '../../../data/models/readonly-identifiable.model';
import { Dictionary } from '@wcd/config';

export class AirsNotificationRuleConditionGroupModel extends ReadOnlyIdentifiable<string> {
	values: Array<AirsNotificationRuleConditionGroupValue>;

	private _allValues: Map<string, Array<AirsNotificationRuleConditionGroupValue>> = new Map<
		string,
		Array<AirsNotificationRuleConditionGroupValue>
	>();
	private _valuesMap: Dictionary<any, AirsNotificationRuleConditionGroupValue>;

	constructor(data: any) {
		super(data);
	}

	get valuesMap(): Dictionary<any, AirsNotificationRuleConditionGroupValue> {
		if (!this._valuesMap)
			this._valuesMap = Dictionary.fromList<string, AirsNotificationRuleConditionGroupValue>(
				this.values,
				'id'
			);

		return this._valuesMap;
	}

	setData(data: any) {
		super.setData(data);
		this.values = data.values;
	}

	getValuesByIds(valueIds: Array<any>): Array<AirsNotificationRuleConditionGroupValue> {
		const valuesMap = this.valuesMap;
		return valueIds.map(valueId => {
			let value = valuesMap.get(valueId);
			if (!value) {
				value = AirsNotificationRuleConditionGroupModel.getInvalidValue(valueId);
				valuesMap.set(valueId, value);
			}

			return value;
		});
	}

	getAllValues(valueIds?: Array<any>): Array<AirsNotificationRuleConditionGroupValue> {
		if (!valueIds || !valueIds.length) return this.values;

		const allValuesKey = valueIds.join('_'),
			allCurrentValues = this._allValues.get(allValuesKey);

		if (allCurrentValues) return allCurrentValues;

		let values = this.values;
		const valuesMap = this.valuesMap;

		values = values.concat(
			valueIds.reduce((invalidValues, value) => {
				if (!valuesMap.get(value)) {
					const invalidValue = AirsNotificationRuleConditionGroupModel.getInvalidValue(value);
					invalidValues.push(invalidValue);
					valuesMap.set(value, invalidValue);
				}
				return invalidValues;
			}, [])
		);

		this._allValues.set(allValuesKey, values);
		return values;
	}

	static getInvalidValue(valueId: any): AirsNotificationRuleConditionGroupValue {
		return {
			name: valueId.constructor === String ? valueId : '(Unknown)',
			invalid: true,
			id: valueId,
		};
	}
}

interface AirsNotificationRuleConditionGroupValue {
	id: any;
	name: string;
	invalid?: boolean;
}
