import { Injectable } from '@angular/core';
import { RecommendationException } from '@wcd/domain';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../global_entities/models/entity-type-service.interface';
import { RecommendationExceptionsFieldsService } from './recommendation-exceptions.fields.service';
import { RecommendationExceptionEntityPanelComponent } from '../components/recommendation-exception.entity-panel.component';
import { RecommendationExceptionEntityDetailsComponent } from '../../../../../global_entities/components/entity-details/recommendation-exception.entity-details.component';
import { RecommendationExceptionService } from './recommendation-exception.service';

export interface RecommendationExceptionDataViewFixedOptions {}

export const FILTER_ONLY_REMEDIATION_REQUEST_FIELDS = [];

@Injectable()
export class RecommendationExceptionEntityTypeService implements EntityTypeService<RecommendationException> {
	constructor(private recommendationExceptionService: RecommendationExceptionService) {}

	readonly entityType: EntityType<RecommendationException> = {
		id: 'RecommendationException',
		entity: RecommendationException,
		entityDetailsComponentType: RecommendationExceptionEntityDetailsComponent,
		singleEntityPanelComponentType: RecommendationExceptionEntityPanelComponent,
		getEntityName: recommendationException => recommendationException.title,
		getEntityDataviewLink: () => '/recommendation-exception',
		getActions: this.recommendationExceptionService.getActions,
		loadFullEntityInPanel: false,
		dataViewOptions: <
			EntityDataViewOptions<RecommendationException, RecommendationExceptionDataViewFixedOptions>
		>{
			fields: RecommendationExceptionsFieldsService,
		},
	};
}
