import { Dictionary } from '@wcd/config';
import { AlertStatus } from '@wcd/domain';

const ALERT_STATUSES: Dictionary<number, AlertStatus> = Dictionary.fromList<number, AlertStatus>(
	[
		{ id: 1, name: 'Unspecified', type: 'Unspecified' },
		{
			id: 2,
			name: 'New',
			type: 'New',
			title: 'New Alerts',
			isSelectable: true,
			allowClassification: true,
		},
		{
			id: 4,
			name: 'In Progress',
			type: 'InProgress',
			title: 'Alerts In Progress',
			isSelectable: true,
			allowClassification: true,
		},
		{
			id: 8,
			name: 'Resolved',
			type: 'Resolved',
			title: 'Resolved Alerts',
			isSelectable: true,
			allowClassification: true,
		},
		{ id: 16, name: 'Hidden', type: 'Hidden', isHidden: true },
		{ id: 32, name: 'Resolved [Valid]', type: 'Resolved [Valid]' },
		{ id: 64, name: 'Resolved [Valid - Allowed]', type: 'Resolved [Valid - Allowed]' },
		{ id: 128, name: 'Resolved [False Alarm]', type: 'Resolved [False Alarm]' },
		{ id: 256, name: 'Multiple', type: 'Multiple' },
	].map(statusConfig => new AlertStatus(statusConfig)),
	'id'
);

export class AlertStatusesService {
	static get selectableStatuses(): Array<AlertStatus> {
		return ALERT_STATUSES.toArray().filter(status => status.isSelectable);
	}

	static getAlertStatusById(statusId: number): AlertStatus {
		return ALERT_STATUSES.get(statusId);
	}

	static getAlertStatusByType(statusType: string): AlertStatus {
		return ALERT_STATUSES.find(status => status.type === statusType);
	}
}
