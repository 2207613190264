import { Type } from '@angular/core';
import { Params } from '@angular/router';
import { isNil, isObject, merge } from 'lodash-es';
import { DataTableFieldSort } from './datatable-field-sort.interface';
import { DataTableFieldIcon } from './datatable-icon.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { EmptyFieldComponent } from '../components/empty-field.component';

// @dynamic
export class DataTableField<TData = any, TComponent = any>
	implements DataTableFieldConfig<TData, TComponent> {
	constructor(dataViewFieldConfig: DataTableFieldConfig) {
		Object.assign(this, dataViewFieldConfig, {
			fluidWidth: dataViewFieldConfig.fluidWidth
				? Math.max(0, Math.min(dataViewFieldConfig.fluidWidth, 1))
				: null,
			minWidth: dataViewFieldConfig.minWidth ? Math.max(0, dataViewFieldConfig.minWidth) : null,
			listValues: dataViewFieldConfig.listValues
				? Object.assign({}, dataViewFieldConfig.listValues, {
						field: dataViewFieldConfig.listValues.field
							? new DataTableField(
									Object.assign(
										{
											id: `${dataViewFieldConfig.id}__list-value`,
											name: dataViewFieldConfig.name,
										},
										dataViewFieldConfig.listValues.field
									)
							  )
							: null,
				  })
				: null,
			sort: Object.assign({}, dataViewFieldConfig.sort, {
				enabled: !dataViewFieldConfig.sort || dataViewFieldConfig.sort.enabled !== false,
			}),
		});
	}

	readonly id: string;
	readonly allowResize: boolean;
	readonly valueTooltipAllowHtmlRendering: boolean;
	readonly descriptionTooltipAllowHtmlRendering: boolean;
	readonly className: string;
	readonly component: DataTableFieldComponent<TData, TComponent>;
	readonly getDynamicComponent: (value: TData) => DataTableFieldComponent<TData, TComponent>;
	readonly useDefaultEmptyFieldComponent: boolean;
	readonly custom: any;
	readonly description: string;
	readonly fluidWidth: number;
	readonly getCssClass: (value: TData) => string;
	readonly getDisplay: (value: TData) => any;
	readonly getFieldCssClass: (value: TData) => string;
	readonly icon: DataTableFieldIcon<TData>;
	readonly getImage: (value: TData) => string;
	readonly getLink: (value: TData) => string | Array<string>;
	readonly getLinkQueryParams: (value: TData) => Params;
	readonly getTooltip: (value: TData) => string;
	readonly headerClass: string;
	readonly help: (value: TData) => string;
	readonly isTabbale: boolean;
	readonly itemProperty: string;
	readonly listValues: FieldValueList<TData>;
	readonly maxWidth: number;
	readonly minWidth: number;
	readonly name: string;
	readonly ariaLabel?: string;
	readonly onClick?: (value: TData) => any;
	readonly overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';
	readonly sort: DataTableFieldSort<TData>;
	readonly tooltip: string;
	readonly primaryField: boolean;
	readonly disabledInnerContent?: (value: TData) => boolean;

	defaultEmptyComponent: DataTableFieldComponent<TData, any> = {
		type: EmptyFieldComponent,
	};

	/**
	 * Small helper to easily map an array of DataviewFieldConfig objects to DataviewFields.
	 */
	static fromList<TData>(fields: Array<DataTableFieldConfig<TData>>): Array<DataTableField<TData>> {
		return fields.filter(Boolean).map((field) => new DataTableField<TData>(field));
	}

	getIsEmptyField(value: TData): boolean {
		if (this.getDisplay && this.getDisplay(value)) {
			return false;
		}
		if (this.component) {
			return this.getIsEmptyComponentProps(this.component, value);
		}
		if (this.getDynamicComponent) {
			const component = this.getDynamicComponent(value);
			return !component || this.getIsEmptyComponentProps(component, value);
		}

		return true;
	}

	getIsEmptyComponentProps(component: DataTableFieldComponent<TData, TComponent>, value: TData) {
		const props = component.getProps(value);
		for (const key in props) {
			if (props[key]) {
				return false;
			}
		}
		return true;
	}

	display(item: TData): string {
		try {
			if (!item) return null;

			if (this.getDisplay) {
				const displayValue = this.getDisplay(item);
				return isNil(displayValue)
					? ''
					: isObject(displayValue)
					? displayValue.toString()
					: String(displayValue);
			}

			if (!isObject(item)) return String(item);

			const itemValue = item[this.itemProperty || this.id];
			return !isNil(itemValue) && !isObject(itemValue) ? itemValue : '';
		} catch (e) {
			console.warn(`Failed to get display for field ${this.id}`, e, ', value: ', item);
			throw e;
		}
	}

	clone(data?: any) {
		return new DataTableField(merge({}, this, data));
	}
}

export interface DataTableFieldConfig<TData = any, TComponent = any> {
	/**
	 * Unique id for this field. This is the name of the field that's sent to the backend as sortField or when filtering.
	 */
	id: string;

	/**
	 * If the DataTable is allowed to resize, `allowResize` for a field allows to control whether a specific field is resizable.
	 * @default true
	 */
	allowResize?: boolean;

	/**
	 * Allow to render tooltip string as HTML in the field's 'value' cell.
	 * @default false
	 */
	valueTooltipAllowHtmlRendering?: boolean;

	/**
	 * Allow to render tooltip string as HTML in the field's 'column header title' cell.
	 * @default false
	 */
	descriptionTooltipAllowHtmlRendering?: boolean;

	/**
	 * A class name (or classes) to add to the data table cells in the field's column
	 */
	className?: string;

	/**
	 * A custom component to display in the column's cells
	 */
	component?: DataTableFieldComponent<TData, TComponent>;

	/**
	 * Determines if this field is empty
	 */
	getIsEmptyField?: (value: TData) => Boolean;

	/**
	 * A custom component to display in the column's cells that changes dynamicly based on the specific item
	 */
	getDynamicComponent?: (value: TData) => DataTableFieldComponent<TData, TComponent>;

	/**
	 * Whether to use a default component if the field is empty
	 */
	useDefaultEmptyFieldComponent?: boolean;

	/**
	 * Custom data to add to a field, to be used by the code that uses the field, not built-in dataview infra.
	 */
	custom?: any;

	/**
	 * Text help tooltip for the field's header in the dataview
	 */
	description?: string;

	/**
	 * A function that gets a value and returns a CSS class to add to the field value contents in the dataview.
	 * @returns CSS class(es)
	 */
	getCssClass?: (value?: TData) => string; // Class for the field's contents

	/**
	 * Determines how to display the value in the field. If not specified, the FieldValueComponent will use value[field.id] as display.
	 * @returns Basically a string, but anything that's returned will be stringified and displayed.
	 */
	getDisplay?: (value?: TData) => any;

	/**
	 * Returns a CSS class (or classes) to apply to the cell that holds the field value.
	 * This is different from getCssClass, which sets the class on the FieldValueComponent itself.
	 */
	getFieldCssClass?: (value?: TData) => string; // Class for the field itself (the TD in items tables)

	/**
	 * Returns the URL of an image to add before the field value contents.
	 */
	getImage?: (value?: TData) => string;

	/**
	 * If specified, the contents of the field are a router link to the returned route.
	 * @returns A route - either string or array of strings, same as for [routeLink]
	 */
	getLink?: (value?: TData) => string | Array<string>;

	/**
	 * If the field contents are router-linked (see getLink), this function returns an object of query params to add to the link.
	 */
	getLinkQueryParams?: (value?: TData) => Params;

	/**
	 * Returns a text to use as tooltip when hovering over the field's contents
	 */
	getTooltip?: (value?: TData) => string;

	/**
	 * A CSS class to add to the field's header in the dataview
	 */
	headerClass?: string;

	/**
	 * Icon name to set at the feild's header
	 */
	headerIcon?: FabricIconNames;

	/**
	 * Returns an i18n path to use as help tooltip inside a dataview cell (see FieldValueComponent for usage).
	 * Different than getTooltip in that this adds an 'info' icon after the field's contents, which when hovered on displayes the tooltip, while getTooltip doesn't add an icon and the tooltip is displayed when hovering over the field's contents.
	 * @returns i18n path
	 */
	help?: (value?: TData) => string;

	/**
	 * Properties to set the field's icons
	 */
	icon?: DataTableFieldIcon<TData>;

	/**
	 * Determines whether the field is tabble\focusable (should have tabindex="0" attribute)
	 * Binded to 'expand groups' button behavior.
	 * Do not use. In order to get focusable behavior, please use standard HTML tags.
	 */
	isTabbale?: boolean;

	/**
	 * To make a column's cells auto-width, set `fluidWidth` to a number between 0-1, which specifies how much width the column should have, relative to other fluid columns.
	 * For example, if there is only one fluid column, set this to 1.
	 * If there are two fluid columns, and there is one that should be twice the width of the first, set the first column's `fluidWidth` to 0.66 and the second to 0.33.
	 */
	fluidWidth?: number;

	/**
	 * A property path in the value to display in the field.
	 * Example - if itemProperty === 'status.name', the field will display value.status?.name, rather than the default value[field.id].
	 */
	itemProperty?: string;

	/**
	 * Specifies that the contents of the field are a list, and should be displayed as such.
	 */
	listValues?: FieldValueList<TData>;

	/**
	 * The maximum width, in pixels, of the column. A user may still resize the column to more than this size, but the column's initial width (which is calculated according to contents) will respect this max width.
	 */
	maxWidth?: number;

	/**
	 * The minimum width, in pixels, of the column. A user may still resize the column to less than this size, but the column's initial width (which is calculated according to contents) will respect this min width.
	 */
	minWidth?: number;

	/**
	 * The name of the field's header in the dataview
	 */
	name: string;

	/**
	 * The aria-label of the field's header in the dataview
	 */
	ariaLabel?: string;

	/**
	 * A function to call when clicking on the field value
	 */
	onClick?: (value: TData) => any;

	/**
	 * By default, the overflow of fields is 'hidden', but it can be overridden with this property.
	 */
	overflow?: 'visible' | 'hidden' | 'scroll' | 'auto';

	/**
	 * Configuration for how to sort the field in the dataview
	 */
	sort?: DataTableFieldSort<TData>;

	/**
	 * Tooltip to display when hovering over the field. Added to the container of the field contents.
	 * getTooltip use more useful normally, since it allows to take into account the value which is displayed.
	 */
	tooltip?: string;

	/**
	 * Will display the field as a bold and left indented.
	 */
	primaryField?: boolean;

	/**
	 * Will add disabled attribute to the root element
	 */
	disabledInnerContent?: (value: TData) => boolean;
}

export interface FieldValueList<TData = any, TListData = any> {
	/**
	 * Returns the array to use as data for the list
	 */
	data?: (value?: TData) => Array<TListData>;

	/**
	 * The field configuration for displaying each item in the list
	 */
	field?: DataTableFieldConfig<TListData>;

	/**
	 * Whether to add commas between list items.
	 */
	isCommaSeparated?: boolean;

	/**
	 * Whether to add arrows between list items.
	 */
	isArrowSeparated?: boolean;

	/**
	 * Whether the list is inline, rather than the default behavior for <UL> / <LI>
	 */
	isInlineList?: boolean;

	/**
	 * The path of the array to use as data for the list, inside the TData object.
	 */
	path?: string;

	/**
	 * If true, the list in the field value will have bullets, otherwise no list style is applied.
	 */
	withBullets?: boolean;
}

export interface DataTableFieldComponent<TData, TComponent> {
	type: Type<TComponent>;
	getProps?: (data: TData) => Partial<TComponent>;
}
