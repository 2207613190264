var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ1 = function (_a) {
    var file = _a.file, reason = _a.reason, rbacGroups = _a.rbacGroups, action = _a.action;
    var rbacGroupsStateValue = action === 'block';
    var rbacGroupsState = rbacGroups.reduce(function (groupsState, groupId) {
        var _a;
        return (__assign({}, groupsState, (_a = {}, _a[groupId] = rbacGroupsStateValue, _a)));
    }, {});
    return {
        data: {
            FileIdentifier: file.sha1,
            FileIdentifierType: 'Sha1',
            RequestorComment: reason,
            RbacGroupsState: rbacGroupsState,
            FileIdentifierSecondary: file.sha256,
            FileIdentifierTypeSecondary: 'Sha256',
        },
    };
};
var FileBlockApiCall = /** @class */ (function (_super) {
    __extends(FileBlockApiCall, _super);
    function FileBlockApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    FileBlockApiCall = __decorate([
        ApiCall({
            name: 'Block a file',
            endpoint: 'bannedfiles/rbac/upsert',
            method: 'POST',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
        })
    ], FileBlockApiCall);
    return FileBlockApiCall;
}(ApiCallModel));
export { FileBlockApiCall };
export { ɵ0, ɵ1 };
