var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
// tslint:disable:template-accessibility-label-for
import { EventEmitter, OnInit, ElementRef, } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { I18nService } from '@wcd/i18n';
var RunAntivirusScanModalComponent = /** @class */ (function (_super) {
    __extends(RunAntivirusScanModalComponent, _super);
    function RunAntivirusScanModalComponent(router, i18nService, elementRef) {
        var _this = _super.call(this, router) || this;
        _this.i18nService = i18nService;
        _this.elementRef = elementRef;
        _this.isSaving = false;
        _this.onConfirm = new EventEmitter();
        _this.onCancel = new EventEmitter();
        _this.scanTypesOptions = [
            {
                key: 'Quick',
                text: i18nService.get('machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.options.quick'),
            },
            {
                key: 'Full',
                text: i18nService.get('machines.entityDetails.actions.runAntivirusScan.dialog.form.scanType.options.full'),
            },
        ];
        return _this;
    }
    RunAntivirusScanModalComponent.prototype.ngOnInit = function () {
        this.settings = __assign({}, this.settings, { className: 'wcd-flex-vertical', title: this.i18nService.get('machines.entityDetails.actions.runAntivirusScan.dialog.title', {
                machineName: this.machineName,
            }) });
    };
    RunAntivirusScanModalComponent.prototype.onConfirmHandler = function () {
        this.onConfirm.emit({
            comment: this.commentText,
            scanType: this.selectedScanType,
        });
        this.onConfirm.complete();
    };
    RunAntivirusScanModalComponent.prototype.onCancelHandler = function () {
        this.onCancel.emit();
        this.onCancel.complete();
    };
    RunAntivirusScanModalComponent.prototype.onScanTypeChanged = function (_a) {
        var option = _a.option;
        this.selectedScanType = option.key;
    };
    return RunAntivirusScanModalComponent;
}(ModalContainer));
export { RunAntivirusScanModalComponent };
