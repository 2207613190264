import { MachineGroup } from '@wcd/domain';

export interface KeyValue {
	key: string;
	value: string;
}

export class EmailNotificationFormData {
	id?: string;
	name: string;
	description: string;
	recipients: string[];
	machineGroups: Array<MachineGroup>;
	allRbacGroups: boolean;
	isUpdateFlow: boolean;
	includeOrgName: boolean;

	constructor(
		emailNotificationRuleFormData = {
			id: undefined,
			name: '',
			description: '',
			recipients: [],
			machineGroups: [],
			allRbacGroups: false,
			includeOrgName: false,
		}
	) {
		this.name = emailNotificationRuleFormData.name;
		this.description = emailNotificationRuleFormData.description;
		this.recipients = emailNotificationRuleFormData.recipients;
		if (emailNotificationRuleFormData.id) {
			this.id = emailNotificationRuleFormData.id;
		}

		this.machineGroups =
			emailNotificationRuleFormData.machineGroups.length > 0
				? emailNotificationRuleFormData.machineGroups.filter(Boolean)
				: emailNotificationRuleFormData.machineGroups;
		this.includeOrgName = emailNotificationRuleFormData.includeOrgName;
		this.allRbacGroups = emailNotificationRuleFormData.allRbacGroups;
	}
}
