import { SevilleModule } from '../../seville/seville.module';

SevilleModule.factory('investigationPackageService', investigationPackageService);

investigationPackageService.$inject = ['$log', '$q', '$http', 'appConfig'];

function investigationPackageService($log, $q, $http, appConfig) {
	var service = {
		loadInvestigationPackageUri: function(requestGuid, computerDnsName) {
			$log.debug('loading investigation package uri');

			return $q(function(resolve, reject) {
				var url = appConfig.serviceUrls.userRequests + '/requests/forensics/downloaduribyguid';

				$http
					.get(url, {
						params: {
							requestGuid: requestGuid,
							packageIdentity: computerDnsName,
						},
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('investigation package uri retrieved');
								resolve(response.data);
							} else {
								$log.debug('investigation package uri not found');
								reject();
							}
						},
						function(response) {
							$log.debug('investigation package uri loading failed');
							reject();
						}
					);
			});
		},
	};

	return service;
}
