var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { SevilleModule } from '../../seville/seville.module';
import './seville.threatintel.demoTenantResolver';
import './seville.threatintel.genericEtwService';
import './seville.threatintel.sidePaneDataProvider';
import { Feature } from '@wcd/config';
import { AntivirusRemediationAction, EntityWithContext, File, FileActionDetails, FileInstance, LegacyUser, Process, ProcessActionDetails, } from '@wcd/domain';
import { RegExpService } from '@wcd/shared';
import { zip } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { toObservable } from '../../../../utils/rxjs/utils';
SevilleModule.factory('executionDetailsSidePaneService', executionDetailsSidePaneService);
executionDetailsSidePaneService.$inject = [
    '$log',
    'sidePaneDataProviderService',
    'sidePaneService',
    'demoTenantResolverService',
    '$state',
    '$rootScope',
    'genericEtwService',
    'featuresService',
    'entityPanelsService',
    'sevilleDowngradeUtilsService',
];
// temporary hack to bridge between the old alert process tree and the new execution details side pane
var eventsSupportedInNewSidePane = [
    'CreateFile',
    'CreateProcess',
    'OpenProcess',
    'ProcessInjection',
    'LoadImage',
    'FileSpecifiedInCommandline',
    'WDAVDetection',
    'IRSpynetReport',
    'AlertEtw',
];
function executionDetailsSidePaneService($log, sidePaneDataProviderService, sidePaneService, demoTenantResolverService, $state, $rootScope, genericEtwService, featuresService, entityPanelsService, sevilleDowngradeUtilsService) {
    var loadingOperations;
    var genericEtwElementType = genericEtwService.genericEtwElementType;
    function getIcon(event) {
        if (!event.ActionType) {
            return '';
        }
        if (event.EventType === 'NetworkConnection' || event.SidePaneType === 'NetworkConnection') {
            return 'icon-Streaming';
        }
        switch (event.SidePaneType) {
            case 'CreateFile':
            case 'FileSpecifiedInCommandline':
                return 'icon-Page';
            case 'CreateProcess':
                return 'icon-Settings';
            case 'Registry':
                return 'icon-OEM';
            case 'LoadImage':
                return 'icon-Processing';
            case 'UserLogon':
                return 'icon-Permissions';
            default:
                if (event.ElementType === genericEtwElementType) {
                    return genericEtwService.getEtwSidePaneIcon(event.SidePaneType);
                }
                return '';
        }
    }
    function getTitle(event) {
        var defaultTitle = event.FileName;
        if (!defaultTitle &&
            (event.EventType === 'NetworkConnection' || event.SidePaneType === 'NetworkConnection')) {
            defaultTitle = event.Domain || event.IpAddress || 'NetworkConnection';
        }
        if (!defaultTitle) {
            defaultTitle = event.SidePaneType;
        }
        if (!event.SidePaneType) {
            return defaultTitle;
        }
        switch (event.SidePaneType) {
            case 'NetworkConnection':
                return getNetworkEventId(event);
            case 'ShellStartLink':
                return 'Open link';
            case 'Registry':
                switch (event.RegistryOperation) {
                    case 'SetValue':
                        return 'Set registry value';
                    case 'CreateKey':
                        return 'Create registry key';
                    case 'DeleteKey':
                        return 'Delete registry key';
                    case 'RenameKey':
                        return 'Rename registry key';
                    case 'DeleteValue':
                        return 'Delete registry value';
                    default:
                        sidePaneService
                            .getCurrentPaneInstance()
                            .setError('Unsupported registry operation '.concat(event.RegistryOperation));
                        return 'Registry';
                }
            case 'UserLogon':
                return event.Domain + '\\' + event.User;
            default:
                if (event.ElementType === genericEtwElementType) {
                    return genericEtwService.getEtwSidePaneTitle(event, defaultTitle);
                }
                return defaultTitle;
        }
    }
    function getNetworkEventId(event) {
        var dnsString = event.Domain ? event.Domain + ' ' : '';
        var ipString = event.IpAddress ? (event.Domain ? ' (' + event.IpAddress + ')' : event.IpAddress) : '';
        return dnsString + ipString;
    }
    function getEventSectionTitle(event) {
        var defaultTitle = 'Execution details';
        if (!event.SidePaneType) {
            return defaultTitle;
        }
        switch (event.SidePaneType) {
            case 'CreateFile':
                return 'File creation details';
            case 'CreateProcess':
                return 'Execution details';
            case 'NetworkConnection':
                return 'Network connection details';
            case 'Registry':
                return 'Registry details';
            case 'FileSpecifiedInCommandline':
                return 'Execution details';
            case 'LoadImage':
                return 'File load details';
            case 'UserLogon':
                return 'User log-on details';
            default:
                if (event.ElementType === genericEtwElementType) {
                    return genericEtwService.getEtwSidePaneSectionTitle(event, defaultTitle);
                }
                return defaultTitle;
        }
    }
    function checkIfLoadingIsDone(sidePaneInstance) {
        loadingOperations--;
        if (!loadingOperations) {
            sidePaneInstance.setLoading(false);
        }
        else {
            $log.debug('Loading operations remaining: ' + loadingOperations);
        }
    }
    function getEventSection(event) {
        var defaultSection = '';
        if (!event.ActionType) {
            return defaultSection;
        }
        if (event.EventType === 'NetworkConnection' || event.SidePaneType === 'NetworkConnection') {
            var sectionProperties = [
                {
                    key: 'Time',
                    value: event.ActionTime,
                    type: 'datetime',
                    order: 1,
                },
                {
                    key: 'Ip address',
                    type: 'link',
                    value: {
                        state: 'ip',
                        params: { id: event.IpAddress },
                        text: event.IpAddress,
                    },
                    order: 3,
                },
                {
                    key: 'Protocol',
                    value: event.Protocol,
                    type: 'primitive',
                    order: 4,
                },
                {
                    key: 'Port',
                    value: event.Port,
                    type: 'primitive',
                    order: 5,
                },
            ];
            if (event.Domain) {
                sectionProperties.push({
                    key: 'Local DNS name',
                    value: {
                        text: event.Domain,
                        href: $state.href('url', { id: event.Domain }),
                    },
                    type: 'link',
                    order: 2,
                });
            }
            return sectionProperties;
        }
        switch (event.SidePaneType) {
            case 'CreateFile':
                return [
                    {
                        key: 'Creation time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Path',
                        value: event.FolderPath,
                        type: 'primitive',
                        order: 2,
                    },
                    {
                        key: 'Download IP',
                        value: event.FileMotwHostIp,
                        type: 'primitive',
                        order: 3,
                    },
                    {
                        key: 'Download URL',
                        value: event.FileMotwHostUrl,
                        type: 'scrollable-block',
                        order: 4,
                    },
                    {
                        key: event.FileMotwHostUrl || event.FileMotwHostIp ? 'Referer URL' : 'Extracted from',
                        value: event.FileMotwReferrerUrl,
                        type: 'scrollable-block',
                        order: 5,
                    },
                ];
            case 'CreateProcess':
                return [
                    {
                        key: 'Execution time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Path',
                        value: event.FolderPath,
                        type: 'primitive',
                        order: 2,
                    },
                    {
                        key: 'User',
                        value: event.ProcessAccount,
                        icon: 'icon-Contact event-side-pane-user-icon',
                        type: 'primitive',
                        style: 'white-space',
                        order: 3,
                    },
                    {
                        key: 'Access privileges (UAC)',
                        value: event.ProcessTokenElevation,
                        type: 'primitive',
                        order: 4,
                    },
                    {
                        key: 'Integrity level',
                        value: event.ProcessIntegrityLevel,
                        type: 'primitive',
                        order: 5,
                    },
                    {
                        key: 'Process ID',
                        value: event.ProcessId,
                        type: 'primitive',
                        order: 6,
                    },
                    {
                        key: 'Command line',
                        value: event.ProcessCommandLine,
                        type: 'scrollable-block',
                        order: 7,
                    },
                    {
                        key: 'Decoded Powershell command',
                        value: event.PowershellDecodedCommand,
                        type: 'scrollable-block',
                        order: 8,
                    },
                    {
                        key: 'Executed Powershell commands',
                        value: event.PowershellCommands ? event.PowershellCommands.join('\n') : '',
                        type: 'scrollable-block',
                        order: 9,
                    },
                ];
            case 'Registry':
                return [
                    {
                        key: 'Time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Registry key',
                        value: event.RegistryKey,
                        type: 'scrollable-block',
                        order: 2,
                    },
                    {
                        key: 'Previous registry key',
                        value: event.RegistryPreviousKey,
                        type: 'scrollable-block',
                        order: 3,
                    },
                    {
                        key: 'Value name',
                        value: event.RegistryValueName,
                        type: 'scrollable-block',
                        order: 4,
                    },
                    {
                        key: 'Value data',
                        value: event.RegistryValueData,
                        type: 'scrollable-block',
                        order: 5,
                    },
                    {
                        key: 'Previous value data',
                        value: event.RegistryPreviousValueData,
                        type: 'scrollable-block',
                        order: 6,
                    },
                ];
            case 'ShellStartLink':
                return [
                    {
                        key: 'Time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Url',
                        value: event.Url,
                        type: 'scrollable-block',
                        order: 2,
                    },
                ];
            case 'FileSpecifiedInCommandline':
                return [
                    {
                        key: 'Time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Folder path',
                        value: event.FolderPath,
                        type: 'primitive',
                        order: 2,
                    },
                ];
            case 'LoadImage':
                return [
                    {
                        key: 'File load time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Folder path',
                        value: event.FolderPath,
                        type: 'primitive',
                        order: 2,
                    },
                ];
            case 'UserLogon':
                return [
                    {
                        key: 'Time',
                        value: event.ActionTime,
                        type: 'datetime',
                        order: 1,
                    },
                    {
                        key: 'Log-on type',
                        value: event.LogonType,
                        type: 'primitive',
                        order: 2,
                    },
                ];
            default:
                if (event.ElementType === genericEtwElementType) {
                    return genericEtwService.getEtwSidePaneSections(event, defaultSection);
                }
                return defaultSection;
        }
    }
    function getEventDetails(event, sidePaneInstance) {
        var eventSectionTitle = getEventSectionTitle(event);
        checkIfLoadingIsDone(sidePaneInstance);
        var section = getEventSection(event);
        if (section) {
            sidePaneInstance.setSection(eventSectionTitle, section);
            sidePaneInstance.setSectionLoading(eventSectionTitle, false);
        }
        else {
            sidePaneInstance.setError('Data could not be retrieved');
        }
    }
    function toggleNewAlertSidePane(rawEvent, eventToClose) {
        var file$ = sevilleDowngradeUtilsService.createItem(FileInstance, rawEvent);
        var process$ = sevilleDowngradeUtilsService.createItem(Process, rawEvent);
        var targetProcess = rawEvent.EtwEventPropertiesAsJson && rawEvent.EtwEventPropertiesAsJson.TargetProcess;
        var targetProcessAction$ = toObservable(targetProcess &&
            sevilleDowngradeUtilsService.createItem(ProcessActionDetails, __assign({}, rawEvent, targetProcess, { CreationTime: targetProcess.CreationTimeUtc, ProcessCommandLine: targetProcess.CommandLine, IntegrityLevel: null })));
        var fileAction$ = sevilleDowngradeUtilsService.createItem(FileActionDetails, __assign({}, rawEvent, { EtwEventPropertiesAsJson: rawEvent.EtwEventPropertiesAsJson && __assign({}, rawEvent.EtwEventPropertiesAsJson, { Action: rawEvent.EtwEventPropertiesAsJson.Action &&
                    AntivirusRemediationAction[rawEvent.EtwEventPropertiesAsJson.Action] }) }));
        zip(file$, process$, targetProcessAction$, fileAction$)
            .pipe(take(1), map(function (_a) {
            var file = _a[0], process = _a[1], targetProcessAction = _a[2], fileAction = _a[3];
            // some tweaking of the received entities, as the alert process tree backend entities are somewhat different
            if (file && !RegExpService.sha1.test(file.sha1)) {
                file = new FileInstance(__assign({}, file, { sha1: null }));
            }
            if (process) {
                var user = new LegacyUser({
                    id: rawEvent.ProcessAccount,
                    accountName: rawEvent.ProcessAccount,
                });
                process = new Process(__assign({}, process, { file: file, user: user }));
            }
            if (targetProcessAction && targetProcessAction.process) {
                targetProcessAction.process = new Process(__assign({}, targetProcessAction.process, { file: file }));
            }
            return [file, process, targetProcessAction, fileAction];
        }), tap(function (_a) {
            var file = _a[0], process = _a[1], targetProcessAction = _a[2], fileAction = _a[3];
            // create appropriate entity and entity context to display, based on the action type
            var mainEntity = file && {
                item: file,
                type: File,
            };
            var entityContext = fileAction && {
                item: fileAction,
                type: FileActionDetails,
                nameKey: 'alerts.events.details.eventDetails',
            };
            switch (rawEvent.SidePaneType) {
                case 'WDAVDetection':
                case 'IRSpynetReport':
                    if (entityContext) {
                        entityContext.nameKey = 'alerts.events.details.blockDetails';
                    }
                    break;
                case 'CreateProcess':
                case 'FileSpecifiedInCommandline':
                    entityContext = process && {
                        item: process,
                        type: Process,
                        nameKey: 'alerts.events.details.executionDetails',
                    };
                    break;
                case 'ProcessInjection':
                case 'OpenProcess':
                    entityContext = targetProcessAction && {
                        item: targetProcessAction,
                        type: ProcessActionDetails,
                        nameKey: 'alerts.events.details.targetProcessDetails',
                    };
                    break;
                case 'CreateFile':
                    entityContext = fileAction && {
                        item: fileAction,
                        type: FileActionDetails,
                        nameKey: 'alerts.events.details.fileCreationDetails',
                    };
                    break;
                case 'LoadImage':
                    entityContext = fileAction && {
                        item: fileAction,
                        type: FileActionDetails,
                        nameKey: 'alerts.events.details.imageLoadDetails',
                    };
                    break;
            }
            var entityWithContext = new EntityWithContext({
                id: undefined,
                mainEntity: mainEntity,
                entityContext: entityContext,
            });
            openNewEntitySidePane(EntityWithContext, entityWithContext, null, rawEvent, eventToClose);
        }))
            .subscribe();
    }
    function toggleFileRelatedSidePane(event) {
        var fileProfile;
        var fileIdentifier = event.Sha1 || event.Sha256;
        var sidePaneServiceParameters = {
            key: event.ElementId,
            title: getTitle(event),
            type: 'entity',
            icon: getIcon(event),
            sections: [
                {
                    title: getEventSectionTitle(event),
                    order: 1,
                },
                {
                    title: 'File details',
                    order: 2,
                },
                {
                    title: 'Detections',
                    order: 3,
                },
                {
                    title: 'Observed worldwide',
                    order: 4,
                },
                {
                    title: 'Observed in organization',
                    subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
                    order: 5,
                },
            ],
        };
        // Add file page link only if the file's Sha1 is available
        if (fileIdentifier && fileIdentifier != 'Hash could not be retrieved') {
            sidePaneServiceParameters.link = {
                text: 'Go to file page',
                state: 'file',
                params: {
                    id: fileIdentifier,
                },
            };
        }
        var sidePaneInstance = sidePaneService.open(sidePaneServiceParameters);
        function getProfile(event) {
            var detectionsLoadingOperations = 2;
            var fileIdentifier = event.Sha1 || event.Sha256;
            sidePaneDataProviderService.loadFileProfile(fileIdentifier, sidePaneInstance).then(function (response) {
                if (response.section) {
                    fileProfile = response.fileProfile;
                    sidePaneInstance.setSection('File details', response.section);
                    sidePaneInstance.setSectionLoading('File details', false);
                    sidePaneDataProviderService
                        .loadVirusTotalData(fileIdentifier, sidePaneInstance, fileProfile)
                        .then(function (vtData) {
                        sidePaneInstance.setSection('Detections', vtData.section);
                        detectionsLoadingOperations--;
                        if (detectionsLoadingOperations == 0) {
                            sidePaneInstance.setSectionLoading('Detections', false);
                        }
                    }, function (vtData) {
                        if (vtData.error) {
                            sidePaneInstance.setSectionError('Detections', vtData.error);
                        }
                        else {
                            sidePaneInstance.setSectionError('Detections', 'Data could not be retrieved');
                        }
                    });
                    sidePaneDataProviderService.loadAlertsForFile(fileIdentifier, sidePaneInstance).then(function (alertsData) {
                        sidePaneInstance.setSection('Detections', alertsData.section);
                        detectionsLoadingOperations--;
                        if (detectionsLoadingOperations == 0) {
                            sidePaneInstance.setSectionLoading('Detections', false);
                        }
                    }, function (alertsData) {
                        if (alertsData.error) {
                            sidePaneInstance.setSectionError('Detections', alertsData.error);
                        }
                        else {
                            sidePaneInstance.setSectionError('Detections', 'Data could not be retrieved');
                        }
                    });
                    sidePaneDataProviderService
                        .loadFileStats(fileIdentifier, sidePaneInstance, fileProfile)
                        .then(function (stats) {
                        sidePaneInstance.setSection('Observed worldwide', stats['Observed worldwide']);
                        sidePaneInstance.setSection('Observed in organization', stats['Observed in organization']);
                        sidePaneInstance.setSectionLoading('Observed worldwide', false);
                        sidePaneInstance.setSectionLoading('Observed in organization', false);
                    }, function (stats) {
                        if (stats.error) {
                            sidePaneInstance.setSectionError('Observed worldwide', stats.error);
                            sidePaneInstance.setSectionError('Observed in organization', stats.error);
                        }
                        else {
                            sidePaneInstance.setSectionError('Observed worldwide', 'Data could not be retrieved');
                            sidePaneInstance.setSectionError('Observed in organization', 'Data could not be retrieved');
                        }
                    });
                }
                else {
                    if (response.error) {
                        sidePaneInstance.setSectionError('File details', response.error || 'Data could not be retrieved');
                        sidePaneInstance.setSectionError('Detections', 'Data could not be retrieved');
                        sidePaneInstance.setSectionError('Observed worldwide', 'Data could not be retrieved');
                        sidePaneInstance.setSectionError('Observed in organization', 'Data could not be retrieved');
                    }
                }
            }, function (response) {
                sidePaneInstance.setSectionError('File details', response.error || 'Data could not be retrieved');
                sidePaneInstance.setSectionError('Detections', 'Data could not be retrieved');
                sidePaneInstance.setSectionError('Observed worldwide', 'Data could not be retrieved');
                sidePaneInstance.setSectionError('Observed in organization', 'Data could not be retrieved');
            });
        }
        getEventDetails(event, sidePaneInstance);
        getProfile(event);
        return sidePaneInstance;
    }
    function toggleSingleSectionSidePane(event) {
        var sidePaneServiceParameters = {
            key: event.ElementId,
            title: getTitle(event),
            type: 'entity',
            icon: getIcon(event),
            sections: [
                {
                    title: getEventSectionTitle(event),
                    order: 1,
                },
            ],
        };
        var sidePaneInstance = sidePaneService.open(sidePaneServiceParameters);
        getEventDetails(event, sidePaneInstance);
        return sidePaneInstance;
    }
    function toggleNetworkSidePane(event) {
        var ipDetailsOperations = 2;
        var orderIndex = 1;
        var sidePaneInstance;
        var sections = [
            {
                title: getEventSectionTitle(event),
                order: orderIndex++,
            },
        ];
        if (event.Domain) {
            sections.push({
                title: 'Domain details',
                order: orderIndex++,
            });
            sections.push({
                title: 'Domain observed in organization',
                subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
                order: orderIndex++,
            });
        }
        if (event.IpAddress) {
            sections.push({
                title: 'Ip details',
                order: orderIndex++,
            });
            sections.push({
                title: 'Ip observed in organization',
                subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
                order: orderIndex++,
            });
        }
        sidePaneInstance = sidePaneService.open({
            key: event.ElementId,
            title: getTitle(event),
            type: 'entity',
            icon: getIcon(event),
            sections: sections,
        });
        getEventDetails(event, sidePaneInstance);
        if (event.IpAddress) {
            getIpDetails(event);
            getIpAlerts(event);
            getIpStats(event);
        }
        if (event.Domain) {
            getUrlDetails(event);
            getUrlAlerts(event);
            getUrlStats(event);
        }
        return sidePaneInstance;
        function getIpDetails(event) {
            sidePaneDataProviderService
                .loadIpProfile(event.IpAddress, sidePaneInstance, event.ActionTime)
                .then(function (response) {
                if (response.section) {
                    ipDetailsOperations--;
                    if (ipDetailsOperations == 0) {
                        sidePaneInstance.setSectionLoading('Ip details', false);
                    }
                    sidePaneInstance.setSection('Ip details', response.section);
                }
                else {
                    sidePaneInstance.setSectionError('Ip details', response.error || 'Data could not be retrieved');
                }
            }, function (response) {
                sidePaneInstance.setSectionError('Ip details', response.error || 'Data could not be retrieved');
            });
        }
        function getIpAlerts(event) {
            sidePaneDataProviderService.loadAlertsForIp(event.IpAddress, sidePaneInstance).then(function (response) {
                if (response.section) {
                    ipDetailsOperations--;
                    if (ipDetailsOperations == 0) {
                        sidePaneInstance.setSectionLoading('Ip details', false);
                    }
                    sidePaneInstance.setSection('Ip details', response.section);
                }
                else {
                    sidePaneInstance.setSectionError('Ip details', response.error || 'Data could not be retrieved');
                }
            }, function (response) {
                sidePaneInstance.setSectionError('Ip details', response.error || 'Data could not be retrieved');
            });
        }
        function getIpStats(event) {
            sidePaneDataProviderService.loadIpStats(event.IpAddress, sidePaneInstance).then(function (response) {
                if (response.section) {
                    sidePaneInstance.setSection('Ip observed in organization', response.section);
                    sidePaneInstance.setSectionLoading('Ip observed in organization', false);
                }
                else {
                    sidePaneInstance.setSectionError('Ip observed in organization', response.error || 'Data could not be retrieved');
                }
            }, function (response) {
                sidePaneInstance.setSectionError('Ip observed in organization', response.error || 'Data could not be retrieved');
            });
        }
        function getUrlDetails(event) {
            var section = sidePaneDataProviderService.getUrlDetails(event.Domain);
            sidePaneInstance.setSection('Domain details', section);
        }
        function getUrlAlerts(event) {
            sidePaneDataProviderService.loadAlertsForUrl(event.Domain, sidePaneInstance).then(function (response) {
                if (response.section) {
                    sidePaneInstance.setSectionLoading('Domain details', false);
                    sidePaneInstance.setSection('Domain details', response.section);
                }
                else {
                    sidePaneInstance.setSectionError('Domain details', response.error || 'Data could not be retrieved');
                }
            }, function (response) {
                sidePaneInstance.setSectionError('Domain details', response.error || 'Data could not be retrieved');
            });
        }
        function getUrlStats(event) {
            sidePaneDataProviderService.loadUrlStats(event.Domain, sidePaneInstance).then(function (response) {
                if (response.section) {
                    sidePaneInstance.setSection('Domain observed in organization', response.section);
                    sidePaneInstance.setSectionLoading('Domain observed in organization', false);
                }
                else {
                    sidePaneInstance.setSectionError('Domain observed in organization', response.error || 'Data could not be retrieved');
                }
            }, function (response) {
                sidePaneInstance.setSectionError('Domain observed in organization', response.error || 'Data could not be retrieved');
            });
        }
    }
    function openNewEntitySidePane(entityType, entity, options, selectedEvent, eventToClose) {
        // record the displayed entity in the selected event, needed to keep track which event is displayed in the side pane
        selectedEvent.newSidePaneEntity = entity;
        selectedEvent.newSidePaneEntityType = entityType;
        entityPanelsService.showEntity(entityType, entity, options);
        if (eventToClose && eventToClose.newSidePaneEntityType !== selectedEvent.newSidePaneEntityType) {
            close(eventToClose);
        }
    }
    function close(event) {
        if (event.newSidePaneEntity && event.newSidePaneEntityType) {
            entityPanelsService.closeEntityPanel(event.newSidePaneEntityType);
        }
        else {
            sidePaneService.close();
        }
    }
    var service = {
        open: function (event, eventToClose) {
            if (!event.ActionType) {
                throw new Error('ActionType is mandatory');
            }
            event.SidePaneType = event.ActionType;
            if (event.ElementType === genericEtwElementType) {
                event.EtwSidePaneDetails = genericEtwService.getEtwSidePaneDetails(event.SidePaneType);
                event.SidePaneType = genericEtwService.getEtwSidePaneType(event);
                if (event.EtwSidePaneDetails.getNewSidePaneDetails) {
                    var settings = event.EtwSidePaneDetails.getNewSidePaneDetails(event);
                    openNewEntitySidePane(settings.entityConstructor, settings.entity, settings.options, event, eventToClose);
                    return;
                }
            }
            if (featuresService.isEnabled(Feature.UpgradeFileSidePane) &&
                eventsSupportedInNewSidePane.includes(event.SidePaneType)) {
                toggleNewAlertSidePane(event, eventToClose);
            }
            else if (event.EventType === 'NetworkConnection' ||
                event.SidePaneType === 'NetworkConnection') {
                // SidePaneType is defined by the ActionType - and ActionType is defined differently between the alert process tree and the machine timeline for network connection events.
                return toggleNetworkSidePane(event);
            }
            else if (event.SidePaneType === 'Registry' ||
                event.SidePaneType === 'UserLogon' ||
                (event.ElementType === genericEtwElementType &&
                    genericEtwService.isSingelSectionEtwSidepane(event)) ||
                event.SidePaneType === 'ShellStartLink') {
                return toggleSingleSectionSidePane(event);
            }
            else {
                // else this is a file related side pane
                return toggleFileRelatedSidePane(event);
            }
        },
        close: close,
    };
    return service;
}
