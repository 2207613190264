var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { CustomTiIndicator, CustomTiIndicatorActionsTypes, CustomTiIndicatorSeverityType, CustomTiIndicatorsType, CustomTiIndicatorsTypes, KnownRequestSource, MachineGroup, } from '@wcd/domain';
import { CheckboxComponent } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { combineLatest } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { RegExpService } from '@wcd/shared';
import { MachinesService } from '../../machines/services/machines.service';
import { CustomTiIndicatorComponentService, MAX_BYPASSDURATION, ScopeTypesEnum, } from '../services/custom-ti-indicator-component.service';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AuthService } from '@wcd/auth';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppFlavorConfig } from '@wcd/scc-common';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "custom-ti-indicator-details";
    CollapsibleID["ResponseAction"] = "custom-ti-indicator-response-action";
    CollapsibleID["OrganizationalScope"] = "custom-ti-indicator-organizational-scope";
})(CollapsibleID || (CollapsibleID = {}));
var CustomTiIndicatorEditComponent = /** @class */ (function (_super) {
    __extends(CustomTiIndicatorEditComponent, _super);
    function CustomTiIndicatorEditComponent(paris, dialogsService, changeDetectorRef, machinesService, entityPanelsService, customTiIndicatorsService, i18nService, featuresService, flavorService, indicatorMachineComponentService, authService, globalEntityTypesService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.dialogsService = dialogsService;
        _this.machinesService = machinesService;
        _this.entityPanelsService = entityPanelsService;
        _this.customTiIndicatorsService = customTiIndicatorsService;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.flavorService = flavorService;
        _this.indicatorMachineComponentService = indicatorMachineComponentService;
        _this.authService = authService;
        _this.CustomTiIndicatorActionsTypes = CustomTiIndicatorActionsTypes;
        _this.RegExpService = RegExpService;
        _this.SpinnerSize = SpinnerSize;
        _this.ScopeTypesEnum = ScopeTypesEnum;
        _this.MAX_BYPASSDURATION = MAX_BYPASSDURATION;
        _this.CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;
        _this.asKeyValueList = true;
        _this.otherMachineGroupsCount = 0;
        _this.isSaving = false;
        _this.isDirty = false;
        _this.collapsibleID = CollapsibleID;
        _this._now = new Date();
        _this.onMachineGroupScopeChange = function (newOption) {
            _this.indicatorMachineComponentService.allowSpecificMachineGroups =
                newOption ===
                    _this.indicatorMachineComponentService.selectableMachineGroupScopes[ScopeTypesEnum.specific];
            if (!_this.indicatorMachineComponentService.allowSpecificMachineGroups) {
                _this.indicatorMachineComponentService.setCurrentMachineGroups((_this.customTiIndicator.rbacGroupIds = _this.indicatorMachineComponentService.currentMachineGroups = []));
            }
            _this.isDirty = true;
        };
        _this.onActionOptionsChange = function (newOption) {
            _this.indicatorMachineComponentService.onActionOptionsChange(newOption, _this.customTiIndicator, _this.customTiIndicatorType, _this.generateAlertCheckbox);
            _this.isDirty = true;
        };
        _this.onGenerateAlertChange = function (shouldGenenerateAlert) {
            _this.indicatorMachineComponentService.setGenerateAlertChange(shouldGenenerateAlert, _this.customTiIndicator);
        };
        _this._customTiIndicatorRepo = paris.getRepository(CustomTiIndicator);
        _this._machineGroupsRepo = paris.getRepository(MachineGroup);
        _this.indicatorMachineComponentService.getFormatSeverityLabel = _this.indicatorMachineComponentService.getFormatSeverityLabel.bind(_this); // this function is called from a child component and requires this component's context
        _this.indicatorMachineComponentService.getFormatCategoryLabel = _this.indicatorMachineComponentService.getFormatCategoryLabel.bind(_this); // this function is called from a child component and requires this component's context
        _this.tvmApplicationBlockEnabled = _this.featuresService.isEnabled(Feature.TVMApplicationBlock);
        _this.historicalDetectionEnabled = _this.featuresService.isEnabled(Feature.HistoricalDetection);
        _this.mitreTechniquesEnabled = _this.featuresService.isEnabled(Feature.IndicatorMitreTechniques);
        _this.allowBlockOnlyForUrlIndicator = _this.featuresService.isEnabled(Feature.AllowBlockOnlyForUrlIndicator);
        _this.indicatorTypeService = globalEntityTypesService.getEntityType(CustomTiIndicator);
        _this.isOrganizationalScopeVisible = _this.flavorService.isEnabled(AppFlavorConfig.settings.indicators);
        return _this;
    }
    CustomTiIndicatorEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.severityList = this.paris.getRepository(CustomTiIndicatorSeverityType).entity.values;
        this.indicatorMachineComponentService.setIsAlertable(this.customTiIndicator);
        this._setIsEditable();
        if (this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
            this._machineGroupSubscription = this.indicatorMachineComponentService
                .setMachineGroups(this.customTiIndicator)
                .subscribe(function () {
                _this.indicatorMachineComponentService.setCurrentMachineGroups(_this.customTiIndicator.rbacGroupIds);
                _this.indicatorMachineComponentService.loadingMachineGroups = false;
                setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
            }, function (error) {
                _this.indicatorMachineComponentService.loadingMachineGroups = false;
                setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
            });
        }
        else {
            this.indicatorMachineComponentService.loadingMachineGroups = false;
        }
        this.indicatorMachineComponentService.init(this.customTiIndicator, this.customTiIndicatorType);
        setTimeout(function () { return _this.changeDetectorRef.detectChanges(); }, 0);
    };
    CustomTiIndicatorEditComponent.prototype.ngOnDestroy = function () {
        this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();
        this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
        this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
        this.indicatorMachineComponentService.destroy();
    };
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "customTiIndicator", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "customTiIndicatorType", {
        get: function () {
            return this._entityType;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "customTiIcon", {
        get: function () {
            return this.customTiIndicatorsService.getIcon(this._entityType.id);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "isExpired", {
        get: function () {
            return this.customTiIndicator.expirationTime && this.customTiIndicator.expirationTime < this._now;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "utcExpirationAsLocal", {
        get: function () {
            return this.customTiIndicatorsService.reinterpretUtcAsLocalTime(this.customTiIndicator.expirationTime);
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorEditComponent.prototype.setEntityPageRouterLink = function () {
        switch (this.customTiIndicatorType.id) {
            case CustomTiIndicatorsTypes.Files: {
                this.entityRouteLinkId = 'file';
                break;
            }
            case CustomTiIndicatorsTypes.Ip: {
                if (this.customTiIndicator.IsIpRange) {
                    this.entityRouteLink = null;
                    return;
                }
                this.entityRouteLinkId = 'ip';
                break;
            }
            case CustomTiIndicatorsTypes.Url: {
                this.entityRouteLinkId = 'url';
                break;
            }
            default: {
                this.entityRouteLink = null;
                return;
            }
        }
        this.entityRouteLink = this.indicatorTypeService.getEntitiesLink([this.customTiIndicator]);
    };
    CustomTiIndicatorEditComponent.prototype.setEntity = function (entity) {
        var clonedEntity = cloneDeep(entity);
        _super.prototype.setEntity.call(this, clonedEntity);
        this._entityType = this.paris.getValue(CustomTiIndicatorsType, clonedEntity.tiIndicatorType.type);
        this.setEntityPageRouterLink();
    };
    CustomTiIndicatorEditComponent.prototype.save = function () {
        var _this = this;
        this.isSaving = true;
        this.indicatorMachineComponentService.resetRedundantFields(this.customTiIndicator);
        this._saveCustomTiSubscription = this._customTiIndicatorRepo.save(this.customTiIndicator).subscribe(function () {
            _this.isSaving = false;
            setTimeout(function () {
                _this.dialogsService.showSuccessSnackbar({
                    text: _this.i18nService.get('customTiIndicator.detailsSidePane.indicatorSaved'),
                });
            });
            _this.entityPanelsService.closeEntityPanel(CustomTiIndicator);
            _this.requestEntitiesRefresh.emit();
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('customTiIndicator_error_FailedToUpdateCustomTI'),
                data: error,
            });
        });
    };
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "isValid", {
        get: function () {
            if (!this.customTiIndicator.description || this.customTiIndicator.description.trim() === '') {
                return false;
            }
            if (!this.customTiIndicator.title || this.customTiIndicator.title.trim() === '') {
                return false;
            }
            if (this.indicatorMachineComponentService.currentActionOption.id ===
                CustomTiIndicatorActionsTypes.Warn &&
                !this.indicatorMachineComponentService.isWarnDetailsValid(this.customTiIndicator)) {
                return false;
            }
            if (this.indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.specific &&
                this.indicatorMachineComponentService.currentMachineGroups.length === 0) {
                return false;
            }
            //// Validate alerts details
            if (this.indicatorMachineComponentService.currentActionOption &&
                this.indicatorMachineComponentService.currentActionOption.id ===
                    CustomTiIndicatorActionsTypes.Allow) {
                return true;
            }
            if (this.tvmApplicationBlockEnabled) {
                return ((this.customTiIndicator.generateAlert && !!this.customTiIndicator.severity) ||
                    !this.customTiIndicator.generateAlert);
            }
            else {
                return (this.indicatorMachineComponentService.currentActionOption &&
                    (this.indicatorMachineComponentService.currentActionOption.id ===
                        CustomTiIndicatorActionsTypes.Warn ||
                        this.indicatorMachineComponentService.currentActionOption.id ===
                            CustomTiIndicatorActionsTypes.Block ||
                        !!this.customTiIndicator.severity));
            }
        },
        enumerable: true,
        configurable: true
    });
    CustomTiIndicatorEditComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!selectedMachineGroups || !selectedMachineGroups.length) {
            this.indicatorMachineComponentService.setCurrentMachineGroups((this.customTiIndicator.rbacGroupIds = []));
            this.isDirty = true;
        }
        else {
            this._onMachineGroupsChangedSubscription = combineLatest(selectedMachineGroups.map(function (selectedGroup) {
                return _this._machineGroupsRepo.getItemById(selectedGroup.id);
            })).subscribe(function (machineGroups) {
                var machineGroupIds = machineGroups.map(function (group) { return group.id; });
                _this.indicatorMachineComponentService.setCurrentMachineGroups((_this.customTiIndicator.rbacGroupIds = machineGroupIds));
                _this.isDirty = true;
            });
        }
    };
    CustomTiIndicatorEditComponent.prototype.getMitreTechniqueDropDownPlaceHolder = function () {
        return this.indicatorMachineComponentService.getMitreTechniqueDropDownPlaceHolder(this.customTiIndicator);
    };
    CustomTiIndicatorEditComponent.prototype.onCategoryChanged = function ($event) {
        this.isDirty = true;
        this.indicatorMachineComponentService.onCategoryChanged($event, this.customTiIndicator);
    };
    CustomTiIndicatorEditComponent.prototype._setIsEditable = function () {
        if (this.customTiIndicator.createdBySource &&
            this.customTiIndicator.createdBySource.id === KnownRequestSource.TVM) {
            this.isEditable = false;
            return;
        }
        this.isEditable =
            !this.customTiIndicator.rbacGroupIds || this.customTiIndicator.rbacGroupIds.length === 0
                ? this.authService.currentUser.isMdeAdmin
                : true;
    };
    Object.defineProperty(CustomTiIndicatorEditComponent.prototype, "isGenerateAlertDisabled", {
        get: function () {
            return (this.indicatorMachineComponentService.currentActionOption &&
                (this.indicatorMachineComponentService.currentActionOption.id ===
                    CustomTiIndicatorActionsTypes.Audit ||
                    this.indicatorMachineComponentService.currentActionOption.id ===
                        CustomTiIndicatorActionsTypes.Allow));
        },
        enumerable: true,
        configurable: true
    });
    return CustomTiIndicatorEditComponent;
}(EntityPanelComponentBase));
export { CustomTiIndicatorEditComponent };
