
		<modal (close)="destroy()" [settings]="settings">
			<div class="message-dialog wcd-flex-horizontal">
				<div class="message-dialerrorog-color color-box-{{ cssClass }}">
					<wcd-shared-icon [iconName]="icon"> </wcd-shared-icon>
				</div>
				<div class="wcd-flex-1 message-dialog-contents">
					<div class="message-dialog-body">
						<h3 class="message-dialog-title color-text-{{ cssClass }}">{{ title }}</h3>
						<div
							class="message-dialog-text wcd-scroll-vertical"
							style="max-height: 300px;"
							data-test-id="error-message"
						>
							<div class="message-dialog-subtitle" *ngIf="subTitle">{{ subTitle }}</div>
							<ul *ngIf="messages" class="inline-list">
								<li *ngFor="let message of messages">
									{{ message }}
								</li>
							</ul>
							<span *ngIf="message">{{ message }}</span>
						</div>
					</div>
					<div class="message-dialog-actions">
						<button class="btn btn-link" (click)="closeModal()">{{i18nService.strings.buttons_close}}</button>
					</div>
				</div>
			</div>
		</modal>
	