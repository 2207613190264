import { incidentGraphNodeSourceValues } from './incident-graph-node-source.values';
import { Entity, EntityModelBase } from '@microsoft/paris';

@Entity({
	singularName: 'Incident graph node source',
	pluralName: 'Incident graph node sources',
	values: incidentGraphNodeSourceValues,
})
export class IncidentGraphNodeSource extends EntityModelBase<string> {
	icon: string;
	iconName: string;
}
