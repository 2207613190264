var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
var IncidentGraphNodeEntityPanelComponent = /** @class */ (function (_super) {
    __extends(IncidentGraphNodeEntityPanelComponent, _super);
    function IncidentGraphNodeEntityPanelComponent(changeDetectorRef, entityPanelsService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.entityPanelsService = entityPanelsService;
        return _this;
    }
    Object.defineProperty(IncidentGraphNodeEntityPanelComponent.prototype, "incidentGraphNode", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    IncidentGraphNodeEntityPanelComponent.prototype.showChildNode = function (childNode) {
        this.entityPanelsService.showEntity(childNode.entity.constructor, childNode.entity);
    };
    return IncidentGraphNodeEntityPanelComponent;
}(EntityPanelComponentBase));
export { IncidentGraphNodeEntityPanelComponent };
