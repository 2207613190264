var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { File } from '../file.entity';
import { FileQuarantineActions } from '../file.quarantine-actions.value-object';
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
var statusMap = {
    SucceededPendingReboot: 'Succeeded',
    ReceivedByServer: 'Pending',
    ReceivedByClient: 'Pending',
};
var RECEIVED_BY_SERVER_STATUS = 'ReceivedByServer';
var ɵ0 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ1 = function (data) {
    // The data that is returned from backend is a mess. It'd be nice to ask the backend people to return the data like this...
    var quarantineActions = data.reduce(function (actionCenterData, result) {
        if (!actionCenterData.cancel && result.CancellationDateTimeUtc) {
            actionCenterData.cancel = {
                time: new Date(result.CancellationDateTimeUtc),
                comment: result.CancellationComment,
                user: result.CancellationRequestor,
            };
        }
        if (!actionCenterData.request && result.CreationDateTimeUtc) {
            actionCenterData.request = {
                time: new Date(result.CreationDateTimeUtc),
                comment: result.RequestorComment,
                user: result.Requestor,
            };
        }
        if (!actionCenterData.isCancelable && result.RemediationStatus === RECEIVED_BY_SERVER_STATUS)
            actionCenterData.isCancelable = true;
        var status = statusMap[result.RemediationStatus] || result.RemediationStatus;
        actionCenterData.statuses[status] = (actionCenterData.statuses[status] || 0) + 1;
        return actionCenterData;
    }, { statuses: {} });
    if (quarantineActions.cancel) {
        quarantineActions.statuses['Cancelled'] =
            (quarantineActions.statuses['Cancelled'] || 0) + (quarantineActions.statuses['Pending'] || 0);
        delete quarantineActions.statuses['Pending'];
    }
    quarantineActions.statuses = Object.entries(quarantineActions.statuses).map(function (_a) {
        var id = _a[0], count = _a[1];
        return ({
            id: id,
            count: count,
        });
    });
    return quarantineActions;
};
var FileQuarantineActionsRelationship = /** @class */ (function () {
    function FileQuarantineActionsRelationship() {
    }
    FileQuarantineActionsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileQuarantineActions,
            foreignKey: 'FileIdentifier',
            fixedData: { FileIdentifierType: 0 },
            endpoint: 'remediation/latestforfile',
            baseUrl: ɵ0,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            parseData: ɵ1,
        })
    ], FileQuarantineActionsRelationship);
    return FileQuarantineActionsRelationship;
}());
export { FileQuarantineActionsRelationship };
export { ɵ0, ɵ1 };
