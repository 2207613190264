var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AlertV3ActionTypeMap } from './alertV3-action-type-types';
import { remediationActionTypeValues } from '../remediation/remediation-action-type.values';
import { InvestigationActionStatusId } from '../investigation/actions/investigation-action-status.values';
import { airsEntityTypeConfigs } from '../airs_entity/airs-entity-type-config.values';
import { AlertV3EntityTypes } from '../airs_entity/alertV3/alertV3-entity-types.enum';
import { AirsEntityType } from '../airs_entity/airs-entity-type.entity';
var ActionFields;
(function (ActionFields) {
    ActionFields["ActionType"] = "ActionType";
    ActionFields["ActionStatus"] = "ActionStatus";
    ActionFields["ActionId"] = "ActionId";
    ActionFields["EntityType"] = "EntityType";
    ActionFields["RelatedEntities"] = "RelatedEntities";
    ActionFields["ComputerName"] = "ComputerName";
    ActionFields["MachineId"] = "MachineId";
    ActionFields["Comment"] = "Comment";
})(ActionFields || (ActionFields = {}));
export function getEntityTypeIdByName(typeName) {
    var entityType = airsEntityTypeConfigs.find(function (e) { return e.alertV3Name && e.alertV3Name === AlertV3EntityTypes[typeName]; });
    var entityTypeId = entityType ? entityType.id : AirsEntityType[typeName];
    return entityTypeId || null;
}
export function mapActionFromApiV2ToActionHistory(actionData) {
    var mappedAction = Object.entries(actionData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case ActionFields.ActionType:
                    var alertV3ActionType_1 = AlertV3ActionTypeMap[val];
                    var actionType = remediationActionTypeValues.find(function (s) { return s.type === alertV3ActionType_1; });
                    acc['ActionType'] = actionType && actionType.id;
                    break;
                case ActionFields.ActionStatus:
                    acc['ActionStatus'] = InvestigationActionStatusId[val];
                    break;
                case ActionFields.ActionId:
                    var intActionId = parseInt(val, 10);
                    acc['ActionId'] = isNaN(intActionId) ? val : intActionId;
                    break;
                case ActionFields.EntityType:
                    acc['EntityType'] = getEntityTypeIdByName(val);
                    break;
                case ActionFields.RelatedEntities:
                    var updatedRelatedEntities = val &&
                        val.reduce(function (res, relatedEntity) {
                            res.push(__assign({}, relatedEntity, { EntityType: getEntityTypeIdByName(relatedEntity.EntityType) }));
                            return res;
                        }, []);
                    acc['RelatedEntities'] = updatedRelatedEntities;
                    break;
                case ActionFields.ComputerName:
                    acc['ComputerName'] = val;
                    break;
                case ActionFields.MachineId:
                    acc['MachineId'] = val;
                    break;
                case ActionFields.Comment:
                    // relevant only for action history entity
                    acc['OriginalActionComment'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
    var machineId = mappedAction['MachineId'];
    var computerName = mappedAction['ComputerName'] || '';
    if (machineId) {
        return Object.assign(mappedAction, {
            Machine: { MachineId: machineId, SenseMachineId: machineId, ComputerName: computerName },
        });
    }
    // Do not return action with unrecognized action type
    return mappedAction['ActionType'] ? mappedAction : null;
}
