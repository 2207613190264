import { TvmLicenseType } from './tvm-license-type.enum';
import { TvmLicenseCollection } from './tvm-license-collection.model';
import { TvmLicenseStatus } from './tvm-license-status.enum';

export class TvmLicensesService {
	private isTvmPremiumFeatureEnabled = false;

	constructor(
		private tenantTvmLicensesCollection: TvmLicenseCollection,
		isTvmPremiumFeatureEnabled: boolean
	) {
		this.isTvmPremiumFeatureEnabled = isTvmPremiumFeatureEnabled;
	}

	isEnabled(licenseType: TvmLicenseType): boolean {
		if (!this.isTvmPremiumFeatureEnabled) {
			return true;
		}

		if (!this.tenantTvmLicensesCollection || !this.tenantTvmLicensesCollection.Licenses) {
			return false;
		}

		const license = this.tenantTvmLicensesCollection.Licenses.find(
			license => license.LicenseType === licenseType
		);
		return (
			license !== undefined &&
			(license.Status === TvmLicenseStatus.Active || license.Status === TvmLicenseStatus.Trial)
		);
	}
}
