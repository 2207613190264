var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { HuntingRecordExtendApiCall, HuntingRecordEntitiesApiCall, Alert, EntityTypes, } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ContentState } from '@wcd/contents-state';
import { CyberEventsActionTypesService } from '../../cyber_events/services/cyber-events-action-types.service';
import { isEmpty, isObjectLike } from 'lodash-es';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
var HuntingQueryResultRecordEntityPanelComponent = /** @class */ (function (_super) {
    __extends(HuntingQueryResultRecordEntityPanelComponent, _super);
    function HuntingQueryResultRecordEntityPanelComponent(changeDetectorRef, paris, i18nService, cyberEventActionTypeService, entityPanelsService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.cyberEventActionTypeService = cyberEventActionTypeService;
        _this.entityPanelsService = entityPanelsService;
        _this.isSupportedCyberEventActionType = false;
        return _this;
    }
    Object.defineProperty(HuntingQueryResultRecordEntityPanelComponent.prototype, "record", {
        get: function () {
            return this.entity.dataItem;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingQueryResultRecordEntityPanelComponent.prototype, "columnsInfo", {
        get: function () {
            return this.options && this.options.columnsInfo;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(HuntingQueryResultRecordEntityPanelComponent.prototype, "asKeyValueList", {
        get: function () {
            return this.options && this.options.asKeyValuesListDisplay;
        },
        enumerable: true,
        configurable: true
    });
    HuntingQueryResultRecordEntityPanelComponent.prototype.ngOnInit = function () {
        this.processTreeSectionTitle = this.i18nService.get('hunting_results_record_panel_sections_processTree_title');
        this.assetsSectionTitle = this.i18nService.get('hunting_results_record_panel_sections_assets_title');
        this.initCyberEvent();
        this.initEntities();
    };
    HuntingQueryResultRecordEntityPanelComponent.prototype.showPropertyInPanel = function (columnInfo) {
        var value = this.record[columnInfo.name];
        if ([null, '', undefined].includes(value)) {
            return false;
        }
        var parsedJsonValue;
        try {
            parsedJsonValue = JSON.parse(value);
        }
        catch (_a) {
            // not a json value, also not empty / null.
            return true;
        }
        // show property if it isn't object / array, or if it isn't an empty object / array.
        return !isObjectLike(parsedJsonValue) || !isEmpty(parsedJsonValue);
    };
    HuntingQueryResultRecordEntityPanelComponent.prototype.initEntities = function () {
        var _this = this;
        this.mainEntitiesSectionState = ContentState.Loading;
        this.paris
            .apiCall(HuntingRecordEntitiesApiCall, this.entity)
            .pipe(take(1))
            .subscribe(function (res) {
            if (!res || !res.impactedEntities) {
                _this.mainEntitiesSectionState = ContentState.Error;
                _this.changeDetectorRef.markForCheck();
                return;
            }
            if ((!res.impactedEntities.machines || !res.impactedEntities.machines.length) &&
                (!res.impactedEntities.mailboxes || !res.impactedEntities.mailboxes.length) &&
                (!res.impactedEntities.users || !res.impactedEntities.users.length)) {
                // empty impacted entities
                _this.mainEntitiesSectionState = ContentState.Empty;
                _this.changeDetectorRef.markForCheck();
                return;
            }
            _this.mainEntities = res.impactedEntities;
            _this.mainEntitiesSectionState = ContentState.Complete;
            _this.changeDetectorRef.markForCheck();
        }, function (e) {
            _this.mainEntitiesSectionState =
                e.status === 404 ? ContentState.Empty : ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        });
    };
    HuntingQueryResultRecordEntityPanelComponent.prototype.initCyberEvent = function () {
        var _this = this;
        this.processTreeSectionState = ContentState.Loading;
        this.paris
            .apiCall(HuntingRecordExtendApiCall, this.entity)
            .pipe(take(1))
            .subscribe(function (res) {
            if (!res || !res.cyberEvent) {
                _this.processTreeSectionState = ContentState.Error; // Unexpected response
                _this.changeDetectorRef.markForCheck();
                return;
            }
            _this.cyberEvent = res.cyberEvent;
            _this.isSupportedCyberEventActionType =
                _this.cyberEventActionTypeService.isCyberEventSupportedByProcessTree(_this.cyberEvent) && _this.hasProcessTreeProperties(_this.cyberEvent);
            _this.processTreeSectionState = ContentState.Complete;
            _this.changeDetectorRef.markForCheck();
        }, function (e) {
            /**
             * Expected empty state statuses:
             * 404 - Event identifiers (time, report index and machine id) provided but the event was not found in database.
             * 422 - Request is valid but BE was unable to convert the hunting record data to telemetry action / cyber event, that is used for building process tree.
             */
            _this.processTreeSectionState = [404, 422].includes(e.status) ? ContentState.Empty : ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        });
    };
    HuntingQueryResultRecordEntityPanelComponent.prototype.hasProcessTreeProperties = function (cyberEvent) {
        return Boolean(cyberEvent.target ||
            cyberEvent.source ||
            cyberEvent.sourceParent ||
            cyberEvent.sourceParentParent ||
            (cyberEvent.registryModificationDetails && cyberEvent.registryModificationDetails.current) ||
            (cyberEvent.registryModificationDetails && cyberEvent.registryModificationDetails.previous) ||
            cyberEvent.localEndpoint ||
            cyberEvent.remoteEndpoint);
    };
    HuntingQueryResultRecordEntityPanelComponent.prototype.onOpenEntitySidePanelClicked = function ($event) {
        var _this = this;
        if ($event.entityType == EntityTypes.alert) {
            this.entityPanelsService.showEntityById(Alert, $event.ItemId, null, {
                back: {
                    onClick: function () {
                        _this.entityPanelsService.closeEntityPanel(Alert);
                    },
                },
                hasCloseButton: false,
            });
        }
    };
    return HuntingQueryResultRecordEntityPanelComponent;
}(EntityPanelComponentBase));
export { HuntingQueryResultRecordEntityPanelComponent };
