import { Entity, EntityField } from '@microsoft/paris';
import { airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsLinkBase } from './airs-entity.link-base';

@Entity({
	...airsEntityConfig,
	singularName: 'URL',
	pluralName: 'URLs',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsUrl,
})
export class AirsUrl extends AirsLinkBase {
	@EntityField({ data: 'screenshot' })
	screenshot: string;

	// this field does not exist in AIRS BE
	@EntityField({ data: 'threats' })
	threats: string;
}
