import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import {
	Alert,
	File,
	incidentGraphNodeEntityTypeValues,
	IncidentGraphRelationType,
	LegacyUser,
	Machine,
	Mailbox,
	MailCluster,
	MailMessage,
	NetworkEndpoint,
	Registry,
} from '@wcd/domain';
import { Observable, of } from 'rxjs';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import {
	AlertIncidentService,
	CategorizedIncidentLinkedByEntity,
} from '../../alerts/services/alert-incident.service';
import { flatten, lowerFirst, uniqBy } from 'lodash-es';
import { AlertEntityTypeService } from '../../alerts/services/alert.entity-type.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { TimebarRange } from '@wcd/timebar';

@Injectable()
export class IncidentGraphService {
	constructor(
		private paris: Paris,
		private alertIncidentService: AlertIncidentService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private alertEntityTypeService: AlertEntityTypeService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	getAlertsGraphData(alerts, timeRange?: TimebarRange): Observable<IncidentAlertsGraph> {
		// filter out-of-range alerts
		const filteredAlerts = alerts.items.filter(
			alert =>
				!timeRange || (alert.lastEventTime > timeRange.from && alert.lastEventTime < timeRange.to)
		);

		// create alert nodes
		const alertNodes = filteredAlerts.map((alert: Alert) => this.getAlertNodeFromAlert(alert));

		// get relations from alerts
		const relations = filteredAlerts.reduce((acc, alert) => {
			const reasons = this.alertIncidentService
				.aggregateUniqueLinkReasonsByCategoryEntity(alert.incidentLinkedBy)
				.map(reason => ({ alert: alert, reason: reason }));
			return [...acc, ...reasons];
		}, []);

		// filter out relations with missing nodes
		const filteredRelations = relations.filter(relation => {
			const exists = filteredAlerts.some(alert => relation.reason.targetAlert.id === alert.id);
			if (!exists) console.warn('Alert is missing: ', relation.reason.targetAlert.id);
			return exists;
		});

		// create entities nodes from relations and dedup
		const entitiesNodes = uniqBy(
			filteredRelations.map(relation => this.getEntityNodeFromRelation(relation.reason)),
			(node: IncidentAlertsGraphNode) => node.id
		);

		const totalNodes = [...alertNodes, ...entitiesNodes];

		// create links from relations and filter out dups (when alert is linked to itself)
		const totalRelations: Array<IncidentAlertsGraphRelation> = flatten(
			filteredRelations.map((value: { alert: Alert; reason: CategorizedIncidentLinkedByEntity }) =>
				this.getRelationFromReason(
					value,
					!timeRange || filteredAlerts.some(alert => value.reason.targetAlert.id === alert.id)
				)
			)
		);

		// left here for initial period debugging. can be removed when done.
		console.log('alerts: ', alerts);
		console.log('nodes: ', totalNodes);
		console.log('relations: ', totalRelations);
		return of({
			nodes: totalNodes,
			relations: totalRelations,
		});
	}

	private getAlertNodeFromAlert(alert: Alert): IncidentAlertsGraphNode {
		return {
			id: alert.id,
			alert: alert,
			name: alert.name,
			_entity: alert,
			entityType: <EntityType<IncidentAlertsGraphSupportedEntity>>(
				this.alertEntityTypeService.entityType
			),
			reasons: <Array<string>>this.alertIncidentService.getReasonsTextByAlert(alert, {
				asArray: true,
				showLabels: true,
			}),
		};
	}

	private getEntityNodeFromRelation(reason: CategorizedIncidentLinkedByEntity): IncidentAlertsGraphNode {
		const entity = reason.entity;
		const entityGraphType = incidentGraphNodeEntityTypeValues.find(node => node.id === entity.type);

		return {
			id: entity.id,
			name: entity.name,
			machine: entity.type === 'Machine' ? new Machine(entity) : null,
			file: entity.type === 'File' ? new File({ id: entity.id, filename: entity.name }) : null,
			user:
				entity.type === 'User'
					? new LegacyUser({ id: entity.id, sid: entity.id, name: entity.name })
					: null,
			networkEndpoint:
				entity.type === 'Ip'
					? new NetworkEndpoint({
							id: entity.id,
							ip: { id: entity.id, name: entity.name },
					  })
					: entity.type === 'Url'
					? new NetworkEndpoint({
							id: entity.id,
							url: { id: entity.id, name: entity.name },
					  })
					: null,
			mailbox: entity.type === 'Mailbox' ? new Mailbox({ id: entity.id, upn: entity.name }) : null,
			mailMessage: entity.type === 'MailMessage' ? new MailMessage(entity) : null,
			mailCluster: entity.type === 'MailCluster' ? new MailCluster(entity) : null,
			_entity: entity,
			entityType: this.globalEntityTypesService.getEntityType(lowerFirst(entity.type)),
			icon: entityGraphType && entityGraphType.icon,
		};
	}

	private getRelationFromReason(
		value: {
			alert: Alert;
			reason: CategorizedIncidentLinkedByEntity;
		},
		addConnectionToAlert: boolean
	): Array<IncidentAlertsGraphRelation> {
		const addTarget = addConnectionToAlert && value.alert.id !== value.reason.targetAlert.id;
		let entityToTargetAlert;
		const getType = reason => ({
			id: reason.category,
			name: this.alertIncidentService.getCategoryDisplayText(reason.category),
			isDirectional: true,
		});

		const sourceAlertToEntity = {
			source: value.alert.id,
			target: value.reason.entity.id,
			relationType: getType(value.reason),
		};

		if (addTarget) {
			entityToTargetAlert = {
				source: value.reason.entity.id,
				target: value.reason.targetAlert.id,
				relationType: getType(value.reason),
			};
		}

		return [sourceAlertToEntity, entityToTargetAlert].filter(Boolean);
	}
}

export class IncidentAlertsGraph {
	nodes: Array<IncidentAlertsGraphNode>;

	relations: Array<IncidentAlertsGraphRelation>;
}

export class IncidentAlertsGraphNode {
	id: string;

	name: string;

	_entity: any; // contains the original linked reason entity, untyped

	entityType: EntityType<IncidentAlertsGraphSupportedEntity>;

	icon?: string;

	alert?: Alert;

	machine?: Machine;

	file?: File;

	networkEndpoint?: NetworkEndpoint;

	user?: LegacyUser;

	mailbox?: Mailbox;

	mailMessage?: MailMessage;

	mailCluster?: MailCluster;

	registry?: Registry;

	reasons?: Array<string>;
}

export class IncidentAlertsGraphRelation {
	source: string;

	target: string;

	relationType: IncidentGraphRelationType;
}

type IncidentAlertsGraphSupportedEntity =
	| Alert
	| Machine
	| File
	| NetworkEndpoint
	| LegacyUser
	| Mailbox
	| MailMessage
	| MailCluster
	| Registry;
