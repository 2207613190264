
		<ng-container *ngIf="alertsSeveritySummary?.alertsCount">
			<ng-container *ngIf="asText; else title" [ngTemplateOutlet]="text"></ng-container>
			<ng-template #title>
				<h4>
					<ng-container [ngTemplateOutlet]="text"></ng-container>
				</h4>
			</ng-template>
		</ng-container>
		<ng-template #text>
			<div class="wcd-inline-block bold wcd-font-size-base wcd-padding-mediumSmall-bottom">
				{{ alertsSeveritySummary.alertsCount | prettyNumber }}
				{{
					'reports.widgets.alertsSummary.activeAlerts.' +
						(alertsSeveritySummary.alertsCount === 1 ? 'singular' : 'plural') | i18n
				}}
			</div>
			<div class="wcd-inline-block bold wcd-font-size-base wcd-padding-mediumSmall-bottom">
				{{ 'reports.widgets.alertsSummary.in' | i18n }}
				{{ alertsSeveritySummary.incidentsCount | prettyNumber }}
				{{
					'reports.widgets.alertsSummary.incidents.' +
						(alertsSeveritySummary.incidentsCount === 1 ? 'singular' : 'plural') | i18n
				}}
			</div>
		</ng-template>
	