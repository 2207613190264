import { AppConfigService } from '@wcd/app-config';
import { SevilleModule } from '../../../../../../app/seville/seville.module';
import { downloadPackageTexts } from '../../../commononboarding/services/seville.downloadPackageTexts';
import { AppInsightsService } from '../../../../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../../../../rbac/enums/mde-allowed-actions.enum';

SevilleModule.directive('downloadEndpointOnboardingBody', downloadEndpointOnboardingBodyDirective);

function downloadEndpointOnboardingBodyDirective() {
	return {
		restrict: 'E',
		scope: {
			endpointOnboardingChanged: '&',
		},
		template: `
		<div class="settings-endpoint-mgmt-section">
        <div class="settings-endpoint-mgmt-padding">
            <h3 class="settings-endpoint-mgmt-title">
                1. Onboard a device
            </h3>
            <div>
                <span>First device onboarded:</span>
                <span ng-if="!clientDownload.machineOnboarded && !clientDownload.loadingStatus">Incomplete</span>
                <span ng-if="clientDownload.machineOnboarded && !clientDownload.loadingStatus">
                    <span>Completed</span><span class="icon icon-CompletedSolid settings-endpoint-mgmt-complete"></span>
                </span>
            </div>
        </div>
        <div class="settings-endpoint-mgmt-padding">
            Onboard devices to Microsoft Defender for Endpoint using the onboarding configuration package that matches your <a href="https://go.microsoft.com/fwlink/p/?linkid=822802" target="_blank">preferred deployment method</a>.
            For other device preparation instructions, read <a href="https://go.microsoft.com/fwlink/p/?linkid=822801" target="_blank">Onboard and set up</a>.
        </div>
        <div class="settings-endpoint-mgmt-padding">
            <div ng-class="{'onboarding-dropdown-custom':!clientDownload.isreadonlyuser, 'onboarding-dropdown-custom-disabled':clientDownload.isreadonlyuser}" class="btn-group onboarding-download-width settings-endpoint-mgmt-align-right" uib-dropdown>
                <div class="settings-endpoint-mgmt-align-left settings-endpoint-mgmt-download-title">Deployment method</div>
                <button ng-class="{'onboarding-dropdown':!clientDownload.isreadonlyuser, 'onboarding-dropdown-disabled':clientDownload.isreadonlyuser}" class="onboarding-download-width" type="button" data-toggle="dropdown" aria-expanded="true" uib-dropdown-toggle ng-disabled="clientDownload.isreadonlyuser">
                    <div class="settings-endpoint-mgmt-table-cell settings-endpoint-mgmt-100-width">
                        {{clientDownload.packageType}}
                    </div>
                    <div class="settings-endpoint-mgmt-table-cell">
                        <span tabindex="0" class="icon icon-ChevronDown onboarding-icon-chevron"></span>
                    </div>
                </button>

                <ul ng-class="{'onboarding-dropdown-custom':!isreadonlyuser, 'onboarding-dropdown-custom-disabled':clientDownload.isreadonlyuser}" class="dropdown-menu onboarding-download-width settings-endpoint-mgmt-dropdown-menu settings-endpoint-mgmt-100-width" role="menu">
                    <li tabindex="0" ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio="clientDownload.gpPackageTitle"><a href="#">{{clientDownload.gpPackageTitle}}</a></li>
                    <li ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio='clientDownload.sccm1606PackageTitle'><a href="#">{{clientDownload.sccm1606PackageTitle}}</a></li>
                    <li ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio='clientDownload.sccmPackageTitle'><a href="#">{{clientDownload.sccmPackageTitle}}</a></li>
                    <li ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio='clientDownload.mdmPackageTitle'><a href="#">{{clientDownload.mdmPackageTitle}}</a></li>
                    <li ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio='clientDownload.localScriptTitle'><a href="#">{{clientDownload.localScriptTitle}}</a></li>
                    <li ng-if="clientDownload.vdiEndpointManagementFeatureEnabled"
                        ng-disabled="clientDownload.isreadonlyuser" ng-model="clientDownload.packageType" uib-btn-radio='clientDownload.vdiPackageTitle'><a href="#">{{clientDownload.vdiPackageTitle}}</a></li>
                </ul>
            </div>
            <div class="onboarding-package-description">
                <table>
                    <tr>
                        <td ng-if="clientDownload.packageType == clientDownload.gpPackageTitle" ng-bind-html="clientDownload.gpPackageDescription"></td>
                        <td ng-if="clientDownload.packageType == clientDownload.sccm1606PackageTitle" ng-bind-html="clientDownload.sccm1606PackageDescription"></td>
                        <td ng-if="clientDownload.packageType == clientDownload.sccmPackageTitle" ng-bind-html="clientDownload.sccmPackageDescription"></td>
                        <td ng-if="clientDownload.packageType == clientDownload.mdmPackageTitle" ng-bind-html="clientDownload.mdmPackageDescription"></td>
                        <td ng-if="clientDownload.packageType == clientDownload.localScriptTitle" ng-bind-html="clientDownload.localScriptDescription"></td>
                        <td ng-if="clientDownload.packageType == clientDownload.vdiPackageTitle" ng-bind-html="clientDownload.vdiPackageDescription"></td>
                    </tr>
                </table>
            </div>
        </div>
        <div>
            <button
                    class="settings-button settings-download-button ng-class:{'settings-button-enabled':!clientDownload.isreadonlyuser, 'settings-button-disabled':clientDownload.isreadonlyuser}"
                    ng-disabled="clientDownload.isreadonlyuser" ng-click="clientDownload.downloadOnboardingPackage()">
                <span class="icon icon-Download"></span>
                Download package
            </button>
        </div>
    </div>
    <div class="settings-endpoint-mgmt-section">
        <div class="settings-endpoint-mgmt-padding">
            <h3 class="settings-endpoint-mgmt-title">
                2. Run detection test
            </h3>
            <div>
                <span>Detection test:</span>
                <span ng-if="!clientDownload.ranTestAlert && !clientDownload.loadingStatus">Incomplete</span>
                <span ng-if="clientDownload.ranTestAlert && !clientDownload.loadingStatus">
                    <span>Completed</span><span class="icon icon-CompletedSolid settings-endpoint-mgmt-complete"></span>
                </span>
            </div>
        </div>
        <div class="settings-endpoint-mgmt-padding">
            To verify that the device is properly onboarded and reporting to the service, run the detection script on the newly onboarded device:
            <ol type="a" class="settings-endpoint-mgmt-list">
                <li>Open a Command Prompt window</li>
                <li>At the prompt, copy and run the command below. The Command Prompt window will close automatically.</li>
                <li class="settings-endpoint-mgmt-list-no-letter">
                    <div class="settings-endpoint-mgmt-box">
                        <textarea id="settings.endpointDownload.testAlertCommand" class="settings-endpoint-mgmt-textarea" rows="3" readonly>{{::clientDownload.detectionTestCode}}</textarea>
                    </div>
                    <div class="settings-endpoint-mgmt-copy-icon">
                        <div ng-click="clientDownload.copyToClipboard('settings.endpointDownload.testAlertCommand')" class="settings-clientsideutils-copy-enabled settings-endpoint-mgmt-copy-icon-div">
                            <i class="icon {{clientDownload.copyToClipboardIcon}}"></i> Copy
                        </div>

                    </div>
                </li>
            </ol>
            If successful, the detection test will be marked as completed and a new alert will appear in few minutes.
        </div>
	</div>
	<div class="settings-endpoint-mgmt-section" ng-if="clientDownload.simulationsAndTutorialsEnabled && clientDownload.isOnboardingComplete">
        <div class="settings-endpoint-mgmt-padding">
			<div class="settings-endpoint-mgmt-tutorials-box">
				<div class="settings-endpoint-mgmt-tutorials-image">
					<img src="../../../../../../../assets/images/onboarding/img_diy.svg">
				</div>
				<div class="settings-endpoint-mgmt-tutorials-text">
					<div class="settings-endpoint-mgmt-tutorials-title">Want to experience the service in depth?</div>
					<div class="settings-endpoint-mgmt-tutorials-sub-title" ng-click="clientDownload.goToTutorials()">
						Explore simulations & tutorials
						<span class="icon icon-Sell rotate-270 settings-endpoint-mgmt-tutorials-icon"></span>
					</div>
				</div>
            </div>
        </div>
    </div>
		`,
		bindToController: true,
		controllerAs: 'clientDownload',
		controller: [
			'$log',
			'$window',
			'$http',
			'appConfig',
			'featuresService',
			'authenticationService',
			'seville.settings.download.endpointonboardingstatus',
			'seville.settings.clientsideutils',
			'$interval',
			'$scope',
			'appInsights',
			'ng2router',
			function(
				$log,
				$window,
				$http,
				appConfig: AppConfigService,
				featuresService,
				authenticationService,
				endpointOnboardingStatus,
				clientSideUtils,
				$interval,
				$scope,
				appInsights: AppInsightsService,
				ng2router
			) {
				const vm = this;
				vm.isOnboardingComplete = appConfig.isOnboardingComplete;

				vm.loadingStatus = true;
				vm.detectionTestCode =
					'pow' +
					'ers' +
					'hell.exe -NoExit -Ex' +
					'ecutionPolicy Byp' +
					'ass -WindowStyle Hidden ' +
					"$ErrorActionPreference= 'silentlycontinue';" +
					'(New-Object System.Net.WebClient).Down' +
					'loa' +
					"dFile('http://127.0.0.1/1.exe', 'C:\\\\test-WDATP-test\\\\invoice.exe');Start-Process 'C:\\\\test-WDATP-test\\\\invoice.exe'";

				vm.simulationsAndTutorialsEnabled = featuresService.isEnabled('SimulationsAndTutorials');

				vm.demoOnboardingScriptEnabled = featuresService.isEnabled('DemoOnboardingScript');
				vm.vdiEndpointManagementFeatureEnabled = featuresService.isEnabled('VdiEndpointManagement');

				vm.sccm1606PackageTitle = downloadPackageTexts['SCCM1606'].title;
				vm.sccm1606PackageDescription = downloadPackageTexts['SCCM1606'].description;

				vm.offboardingPackageType = vm.sccm1606PackageTitle;
				vm.offboardingPackageExpirationDays = '30 days';
				vm.isreadonlyuser = !authenticationService.isUserActionAllowed([
					RbacMdeAllowedActions.securitySettings,
				]);

				const localScriptDemoTitle = downloadPackageTexts['LocalScriptDemo'].title;
				const localScriptTitle = downloadPackageTexts['LocalScript'].title;

				vm.localScriptTitle =
					downloadPackageTexts[
						vm.demoOnboardingScriptEnabled ? 'LocalScriptDemo' : 'LocalScript'
					].title;
				vm.localScriptDescription =
					downloadPackageTexts[
						vm.demoOnboardingScriptEnabled ? 'LocalScriptDemo' : 'LocalScript'
					].description;

				vm.packageType = vm.localScriptTitle;

				vm.sccmPackageTitle = downloadPackageTexts['SCCM'].title;
				vm.sccmPackageDescription = downloadPackageTexts['SCCM'].description;

				vm.mdmPackageTitle = downloadPackageTexts['MDM'].title;
				vm.mdmPackageDescription = downloadPackageTexts['MDM'].description;

				vm.gpPackageTitle = downloadPackageTexts['GroupPolicy'].title;
				vm.gpPackageDescription = downloadPackageTexts['GroupPolicy'].description;

				vm.vdiPackageTitle = downloadPackageTexts['VDI'].title;
				vm.vdiPackageDescription = downloadPackageTexts['VDI'].description;

				const mgmtTool = {};
				mgmtTool[vm.gpPackageTitle] = 0;
				mgmtTool[vm.sccmPackageTitle] = 1;
				mgmtTool[vm.sccm1606PackageTitle] = 2;
				mgmtTool[vm.mdmPackageTitle] = 2;
				mgmtTool[localScriptTitle] = 3;
				mgmtTool[localScriptDemoTitle] = 4;
				mgmtTool[vm.vdiPackageTitle] = 5;

				vm.goToTutorials = function() {
					ng2router.navigate(['/tutorials']);
				};

				vm.downloadOnboardingPackage = function() {
					if (vm.isreadonlyuser) {
						console.log('read only user, skipping');
						return;
					}

					$http
						.get(appConfig.serviceUrls.packages + '/DownloadOnboardingPackage', {
							params: { mgmtTool: mgmtTool[vm.packageType] },
							timeout: 60000,
						})
						.then(
							function(response) {
								if (response.status === 200) {
									if (response.data === null) {
										console.log(response.data);
									}
									$window.location.href = response.data;
									appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
										Page: 'Download',
										ResponseStatus: response.status,
										RanTestAlert: vm.ranTestAlert,
										MachineOnboarded: vm.machineOnboarded,
									});
								} else {
									console.log('Download onboarding package failed');
									appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
										Page: 'Download',
										ResponseStatus: response.status,
										FailureType: 'API',
										RanTestAlert: vm.ranTestAlert,
										MachineOnboarded: vm.machineOnboarded,
									});
								}
							},
							function(response) {
								console.log('Download onboarding package call failed');
								appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
									Page: 'Download',
									ResponseStatus: response.status,
									FailureType: 'Request',
									RanTestAlert: vm.ranTestAlert,
									MachineOnboarded: vm.machineOnboarded,
								});
							}
						);
				};

				function loadEndpointOnboardingStatus() {
					vm.loadingStatus = true;

					endpointOnboardingStatus.getStatus().then(
						function(endpointOnboardingInfo) {
							vm.loadingStatus = false;
							vm.ranTestAlert = endpointOnboardingInfo.RanTestAlert;
							vm.machineOnboarded = endpointOnboardingInfo.MachineOnboarded;
							appInsights.trackEvent('EndpointOnboarding-Status', {
								Page: 'Download',
								RanTestAlert: vm.ranTestAlert,
								MachineOnboarded: vm.machineOnboarded,
							});

							if (!vm.ranTestAlert || !vm.machineOnboarded) {
								vm.intervalId = $interval(loadEndpointOnboardingStatus, 5 * 60000, 1);
							}

							if (typeof vm.endpointOnboardingChanged === 'function') {
								appInsights.trackEvent('EndpointOnboarding-statusChanged', {
									Page: 'Download',
									RanTestAlert: vm.ranTestAlert,
									MachineOnboarded: vm.machineOnboarded,
								});
								vm.endpointOnboardingChanged({
									machineOnboarded: vm.machineOnboarded,
									ranTestAlert: vm.ranTestAlert,
								});
							}
						},
						function(error) {
							vm.loadingStatus = false;
							console.log('GetStatus from endpointOnboardingStatus service failed: ' + error);
							$interval(loadEndpointOnboardingStatus, 5 * 60000, 1);
						}
					);
				}

				vm.copyToClipboard = function(elementId) {
					clientSideUtils.copyToClipboard(
						'CopyTestAlertCommand',
						elementId,
						setCopyToClipboardIcon
					);
					appInsights.trackEvent('EndpointOnboarding-CopyTestAlert', {
						Page: 'Download',
						RanTestAlert: vm.ranTestAlert,
						MachineOnboarded: vm.machineOnboarded,
					});
				};

				vm.copyToClipboardIcon = 'icon-Copy';

				function setCopyToClipboardIcon(id, icon) {
					if (id === 'settings.endpointDownload.testAlertCommand') {
						vm.copyToClipboardIcon = icon;
					}
				}

				$scope.$on('$destroy', function() {
					// Make sure that the interval is destroyed too
					if (vm.intervalId !== undefined) {
						$interval.cancel(vm.intervalId);
					}
				});

				loadEndpointOnboardingStatus();
			},
		],
	};
}
