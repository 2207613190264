import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Alert, AlertIocContextBase, Ip, File, Url } from '@wcd/domain';
import { ModelBase, DataEntityType } from '@microsoft/paris';
import { of, Observable } from 'rxjs';
import { filter, map, take, tap } from 'rxjs/operators';
import { ActivatedEntity } from '../../../../../app/global_entities/services/activated-entity.service';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { TzDateComponent } from '../../../../../app/shared/components/tz-date.component';
import { EntityNameComponent } from '../../../../../app/global_entities/components/entity-name/entity-name.component';

type ArtifactEntityType = DataEntityType<File | Ip | Url>;

interface ArtifactTimelineItem {
	lastSeen: Date;
	firstSeen: Date;
	name: string;
	id: string;
	sha1: string;
	entityConstructor: ArtifactEntityType;
}

const createContextToArtifactTimelineItemMapper = (alert: Alert, entityConstructor: ArtifactEntityType) => (
	c: AlertIocContextBase
): ArtifactTimelineItem => {
	return {
		lastSeen: c.lastSeen || alert.lastSeen,
		firstSeen: c.firstSeen || alert.firstSeen,
		name: c.name,
		id: String(entityConstructor === File ? c.sha1 : c.id),
		sha1: c.sha1,
		entityConstructor,
	};
};

@Component({
	selector: 'alert-artifact-timeline',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-full-height wcd-flex-1 wcd-flex-vertical">
			<div
				*ngIf="(events$ | async) as events; else loader"
				class="wcd-flex-1 wcd-margin-small-horizontal"
			>
				<wcd-datatable
					*ngIf="events.length; else noData"
					[items]="events"
					[columns]="tableFields"
					[selectEnabled]="false"
				></wcd-datatable>
			</div>
			<ng-template #loader>
				<div class="wcd-full-height loading-overlay">
					<i class="large-loader-icon"></i>
				</div>
			</ng-template>
			<ng-template #noData>
				<div class="wcd-padding-all">
					<i class="icon icon-Error"></i>
					{{ 'alert.tabs.timeline.noData' | i18n }}
				</div>
			</ng-template>
		</div>
	`,
})
export class AlertArtifactTimelineComponent implements OnInit {
	@Input() alert: Alert;
	events$: Observable<ArtifactTimelineItem[]>;
	readonly tableFields = DataTableField.fromList<ArtifactTimelineItem>([
		{
			id: 'lastSeen',
			name: this.i18nService.get('alert.tabs.timeline.fields.lastSeen'),
			className: 'nowrap',
			component: {
				type: TzDateComponent,
				getProps: e => ({ date: e.lastSeen }),
			},
		},
		{
			id: 'entity',
			name: this.i18nService.get('alert.tabs.timeline.fields.entity'),
			component: {
				type: EntityNameComponent,
				getProps: e => ({
					entity: {
						id: e.sha1,
					},
					entityName: e.name,
					entityTypeId: e.entityConstructor,
				}),
			},
		},
		{
			id: 'firstSeen',
			name: this.i18nService.get('alert.tabs.timeline.fields.firstSeen'),
			className: 'nowrap',
			component: {
				type: TzDateComponent,
				getProps: e => ({ date: e.firstSeen }),
			},
		},
		{
			id: 'sha1',
			name: this.i18nService.get('alert.tabs.timeline.fields.sha1'),
			getDisplay: f => f.sha1,
		},
	]);

	constructor(
		private readonly i18nService: I18nService,
		private readonly activatedEntity: ActivatedEntity
	) {}

	getAlert(): Observable<Alert> {
		if (this.alert) {
			return of(this.alert);
		}
		return this.activatedEntity.currentEntity$.pipe(
			filter((entity: ModelBase) => entity instanceof Alert),
			take(1),
			tap((alert: Alert) => {
				this.alert = alert;
			})
		);
	}

	ngOnInit(): void {
		const createMapper = createContextToArtifactTimelineItemMapper;

		this.events$ = this.getAlert().pipe(
			map((alert: Alert) => {
				const events = [
					...alert.ipContexts.map(createMapper(alert, Ip)),
					...alert.fileContexts.map(createMapper(alert, File)),
					...alert.urlContexts.map(createMapper(alert, Url)),
				].sort((a, b) => b.lastSeen.getTime() - a.lastSeen.getTime());
				return events;
			})
		);
	}
}
