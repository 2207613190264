import { Machine } from '@wcd/domain';
import { RegExpService } from '@wcd/shared';

export const getMachineName = (machine: Machine) => {
	if (!machine) {
		return '';
	}

	// Cases of IP machine page - with a supported machine page - MDATP
	if (RegExpService.ip.test(machine.name)) {
		return machine.name;
	}

	/*
	Cases of IP machine page - without a supported machine page - MCAS/AATP
	Should be remove once this bug is getting solved:
	Bug 26961733: AATP machine results in broken link from IP
	*/

	if (!machine.name && RegExpService.ip.test(machine.ipAddress)) {
		return machine.ipAddress;
	}

	// Cases of regular machine page
	return machine.shortName ? machine.shortName : machine.id; // if all else fails, use the machine id. It's better than an empty string
};
