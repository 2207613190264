import { OnDestroy } from '@angular/core';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { BehaviorSubject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../dialogs/services/dialogs.service";
import * as i2 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
var HttpInterceptorService = /** @class */ (function () {
    function HttpInterceptorService(dialogsService, i18nService) {
        var _this = this;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.activePopup = new BehaviorSubject(false);
        this.showHttp429Error = function () {
            if (!!_this.activePopup.getValue()) {
                return;
            }
            _this.activePopup.next(true);
            _this.dialogsService
                .confirm({
                text: _this.i18nService.get('errors.http429.text'),
                title: _this.i18nService.get('errors.http429.title'),
                showConfirm: false,
                showOverlay: false,
                cancelText: _this.i18nService.get('errors.http429.cancelText'),
            })
                .then(function () { return _this.activePopup.next(false); });
        };
    }
    HttpInterceptorService.prototype.ngOnDestroy = function () {
        this.activePopup.complete();
    };
    HttpInterceptorService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HttpInterceptorService_Factory() { return new HttpInterceptorService(i0.ɵɵinject(i1.DialogsService), i0.ɵɵinject(i2.I18nService)); }, token: HttpInterceptorService, providedIn: "root" });
    return HttpInterceptorService;
}());
export { HttpInterceptorService };
