var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { countBy, flatMap, mapValues, sortBy, uniq } from 'lodash-es';
import { Machine, MachineTagsCollectionRelationship, Tag, MachineDataSensitivityRelationship, OperatingSystemPlatformCategories, MachineValue, FIRST_SEEN_DEFAULT_TIME_COMPARISON, FirstSeen, MachineExclusionState, } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { FabricIconNames } from '@wcd/scc-common';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '@wcd/utils';
import { MachineEntityPanelComponent } from '../components/machine.entity-panel.component';
import { MachineEntityComponent } from '../components/machine.entity.component';
import { MachineEntityTypeActionsService } from './machine.entity-type-actions.service';
import { MachinesFieldsService } from './machines.fields';
import { MachinesFiltersService } from './machines.filters.service';
import { MachinesService } from './machines.service';
import { map, catchError } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { TagsService } from '../../../tags/services/tags.service';
import { I18nService } from '@wcd/i18n';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { getMachineName } from '../helpers/machine.helpers';
import { MachineMinimizedDetailsComponent } from '../../../global_entities/components/entity-minimized/machine.minimized.details.component';
import { MachineEntityStatusComponent } from './machine-entity-status.component';
import { MachineHeaderBottomComponent } from '../../../global_entities/components/entity-header-bottom/machine.header-bottom.component';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
export var EXCLUSION_STATES_FIELD_ID = 'exclusionStates';
export var ONBOARDING_STATUSES_FIELD_ID = 'onBoardingStatuses';
export var FILTER_ONLY_MACHINE_FIELDS = [
    'securityPropertiesRequiringAttention',
    'threatCategory',
    'noTags',
    'rbacGroupIds',
    'machineGroups',
    '__groupsWithTags',
    'outbreakId',
    'mitigationTypes',
    'vulnerabilitySeverityLevels',
    'vulnerabilityAgeLevels',
    'exploitLevels',
];
var MachineEntityTypeService = /** @class */ (function () {
    function MachineEntityTypeService(paris, machinesService, machinesFiltersService, timeRangesService, featuresService, appConfigService, machinesEntityTypeActionsService, tagsService, i18nService, machineFieldsService, advancedFeaturesService, appContext) {
        var _this = this;
        this.paris = paris;
        this.machinesService = machinesService;
        this.machinesFiltersService = machinesFiltersService;
        this.timeRangesService = timeRangesService;
        this.featuresService = featuresService;
        this.appConfigService = appConfigService;
        this.machinesEntityTypeActionsService = machinesEntityTypeActionsService;
        this.tagsService = tagsService;
        this.i18nService = i18nService;
        this.machineFieldsService = machineFieldsService;
        this.advancedFeaturesService = advancedFeaturesService;
        this.appContext = appContext;
        this._timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick([
                "day" /* day */,
                "3days" /* '3days' */,
                "week" /* week */,
                "month" /* month */,
                "6months" /* '6months' */,
            ]);
        });
        this.entityType = {
            id: 'machine',
            entity: Machine,
            icon: FabricIconNames.System,
            getIcon: function (machines) {
                var getIcon = function (machine) {
                    return machine.os &&
                        machine.os.platform &&
                        (machine.os.platform.id === OperatingSystemPlatformCategories.iOS ||
                            machine.os.platform.id === OperatingSystemPlatformCategories.Android)
                        ? FabricIconNames.CellPhone
                        : FabricIconNames.System;
                };
                var firstIcon = getIcon(machines[0]);
                if (machines.length === 1 || machines.every(function (machine) { return getIcon(machine) === firstIcon; }))
                    return firstIcon;
                else
                    return FabricIconNames.System;
            },
            getImage: function () {
                return "/assets/images/icons/entities/machine.svg";
            },
            entityContentsComponentType: MachineEntityComponent,
            entityDetailsComponentType: MachineEntityDetailsComponent,
            entityPanelStatusComponentType: MachineEntityStatusComponent,
            singleEntityPanelComponentType: MachineEntityPanelComponent,
            entityMinimizedComponentType: MachineMinimizedDetailsComponent,
            entityHeaderBottomComponentType: MachineHeaderBottomComponent,
            getEntityName: getMachineName,
            entityPluralNameKey: 'machine_entityType_pluralName',
            entitySingularNameKey: 'machine_entityType_singularName',
            getEntityTooltip: function (machine) {
                return machine.senseMachineId ? null : _this.i18nService.get('machines.notOnboarded');
            },
            getEntityDataviewLink: function () { return '/machines'; },
            getEntityNotification: function (machine) {
                if (machine.isDeleted) {
                    return machine.mergedIntoMachineId
                        ? _this.i18nService.get('DevicePageInvalidDeviceMetgedIntoOtherDeviceNotification', {
                            link: "/machines/" + machine.mergedIntoMachineId,
                        })
                        : _this.i18nService.strings.DevicePageInvalidDeviceNotification;
                }
            },
            getAsyncBottomEntityNotification: function (machine) {
                if (!_this.featuresService.isEnabled(Feature.ExcludedDevices) ||
                    machine.exclusionState === MachineExclusionState.Included) {
                    return of(null);
                }
                return of(_this.i18nService.get('DevicePageExcludedDeviceNotification'));
            },
            getEntitiesLink: function (machines, options) {
                if (machines.length === 1) {
                    if (!machines[0] || (!machines[0].senseMachineId && !machines[0].name)) {
                        return null;
                    }
                    var senseMachineId = machines[0].senseMachineId;
                    var machineId = !senseMachineId && _this.featuresService.isEnabled(Feature.NewDevicePage)
                        ? machines[0].name
                        : senseMachineId;
                    return _this.machinesService.getMachineLink(machineId, true, options && options.actionTime);
                }
                return null;
            },
            getNavigationModel: function (machine, serviceSource) {
                var link = _this.entityType.getEntitiesLink([machine]);
                return link ? { routerLink: [link], id: 'machine' } : null;
            },
            getActions: function (machines, options, entityViewType, entityCommandBarDisplayMode) {
                return _this.machinesEntityTypeActionsService.getEntityActions(machines, _this, options, entityCommandBarDisplayMode);
            },
            dataViewOptions: {
                fields: this.machineFieldsService.fields,
                dateRangeOptions: {
                    supportedRanges: this._timeRanges.value,
                    defaultTimeRangeId: this.appConfigService.isDemoTenant ? '6months' : 'month',
                },
                defaultSortFieldId: 'riskScores',
                disabledFields: FILTER_ONLY_MACHINE_FIELDS,
                fixedOptions: function (currentRange, searchTerm) {
                    return Object.assign({
                        lookingBackIndays: currentRange.value.toString(),
                    }, searchTerm && {
                        machineNamePrefix: searchTerm,
                    }, {
                        machinesApiServiceMigration: _this.featuresService.isEnabled(Feature.MachinesApiServiceMigration),
                    }, {
                        useTvmMachinesAvStatus: _this.featuresService.isEnabled(Feature.UseTvmMachinesAvStatus),
                    });
                },
                dataViewConfig: {
                    getFiltersData: function (fixedOptions) { return _this.machinesService.getMachinesFilters(fixedOptions); },
                    disabledVisibleFieldIds: FILTER_ONLY_MACHINE_FIELDS,
                    searchFilterValues: function (field, term, options) {
                        return _this.machinesFiltersService.searchFilterValues(field, term, options);
                    },
                },
                exportOptions: {
                    showModalOnExport: false,
                    exportResults: function (options, fixedOptions) {
                        return _this.machinesService.downloadCsv(__assign({}, options, fixedOptions));
                    },
                },
                lifecycleHooks: {
                    ngOnDestroy: function () { return _this.machinesService.clearMachinesFilters(); },
                },
                getFilterQueryOptions: function (serializedFilters) {
                    var mappedFilters = mapValues(serializedFilters, function (_value, param) { return serializedFilters[param]; });
                    if (serializedFilters.firstseen) {
                        Object.assign(mappedFilters, _this.getFirstSeenQuery(serializedFilters.firstseen));
                        delete mappedFilters.firstseen;
                    }
                    if (serializedFilters.healthStatuses) {
                        mappedFilters.healthStatuses = _this.getHealthStatusesQuery(serializedFilters.healthStatuses);
                    }
                    return mappedFilters;
                },
            },
            searchOptions: {
                displayName: this.i18nService.strings.entity_type_display_name_machine,
                getSearchParams: function (searchTerm) {
                    return { url: "/searchResults/machines/" + searchTerm };
                },
                searchDropdownOrder: 0,
            },
            getTags: function (machines) {
                if (machines.length === 1) {
                    var isInternetFacing_1 = _this.featuresService.isEnabled(Feature.InternetFacing) && machines[0].isInternetFacing;
                    var internetFacingReason_1 = machines[0].internetFacingReason;
                    var machineTags$ = (_this.featuresService.isEnabled(Feature.UnifiedMachineAPI)
                        ? of(machines[0].extendedTags)
                        : _this.paris.getRelatedItem(MachineTagsCollectionRelationship, machines[0], {
                            where: {
                                migrateToVNext: _this.featuresService.isEnabled(Feature.MachinesControllerMigrationGetTags)
                            }
                        })).pipe(map(function (tags) { return (tags ? tags.allTags : []).concat(isInternetFacing_1 ? [_this.tagsService.getInternetFacingTag(internetFacingReason_1)] : []); }), catchError(function () { return of([]); }));
                    if (!_this.featuresService.isEnabled(Feature.FileSensitivity)) {
                        return machineTags$;
                    }
                    var tooltip_1 = _this.i18nService.get('machines.entityDetails.fields.sensitivity.tooltip');
                    var sensitivityTag$ = _this.paris
                        .getRelatedItem(MachineDataSensitivityRelationship, machines[0])
                        .pipe(map(function (sensitivity) {
                        return sensitivity && !!sensitivity.label ? [_this.tagsService.getDataSensitivityTag(sensitivity, tooltip_1)] : [];
                    }), catchError(function () { return of([]); }));
                    return combineLatest(sensitivityTag$, machineTags$).pipe(map(function (_a) {
                        var sensitivityTags = _a[0], machineTags = _a[1];
                        return sensitivityTags.concat(machineTags);
                    }));
                }
                var machineTagCounts = countBy(flatMap(machines, function (machine) { return (machine.extendedTags && machine.extendedTags.allTags) || machine.tags; }));
                var machinesTags = Object.entries(machineTagCounts).map(function (_a) {
                    var tag = _a[0], count = _a[1];
                    return new Tag({
                        id: tag,
                        name: tag,
                        isPartial: count < machines.length,
                        isEditable: machines.every(function (machine) { return machine.builtInTag !== tag; }),
                    });
                });
                return of(sortBy(machinesTags, function (tag) { return tag.name.toLowerCase(); }));
            },
            pseudoTags: {
                get: function (machines) {
                    return _this.machinesService.getMachineValuePseudoTag(machines);
                },
                set: function (machines, action) {
                    if (action === "machineValue" /* MachineValue */) {
                        return _this.machinesService.setMachinesValue(machines);
                    }
                },
            },
            entityPageViewMode: EntityPageViewMode.Asset,
            hideTagsInEntityComponent: true,
            showMigrationToggle: function () { return _this.featuresService.isEnabled(Feature.ReactMachineTimeline); },
            isMigrationToggleDisabled: function () { return false; },
            getMigrationLocalStorageKey: function () { return _this.entityType.id + "-show-new-timeline"; },
            isMigrationToggledOn: function () { return localStorage.getItem(_this.entityType.id + "-show-new-timeline") !== 'false'; },
            getMigrationToggleTitle: function (isOn) { return isOn ? _this.i18nService.strings.machines_entityDetails_newTimeline_toggle_on : _this.i18nService.strings.machines_entityDetails_newTimeline_toggle_off; }
        };
        this.machineValues = [
            {
                id: MachineValue.Low,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_low'),
            },
            {
                id: MachineValue.Normal,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_normal'),
            },
            {
                id: MachineValue.High,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_high'),
            },
        ];
    }
    MachineEntityTypeService.prototype.getHealthStatusesQuery = function (value) {
        var _this = this;
        value = value instanceof Array ? value : [value];
        return value.reduce(function (allHealthStatuses, healthStatusId) {
            var healthStatusCategory = _this.machinesFiltersService
                .machineHealthStatusCategoriesMap[healthStatusId];
            var isCategory = !!healthStatusCategory;
            if (!healthStatusCategory) {
                var category = Object.entries(_this.machinesFiltersService.machineHealthStatusCategoriesMap).find(function (_a) {
                    var healthStatusCategoryFilter = _a[1];
                    return healthStatusCategoryFilter &&
                        healthStatusCategoryFilter.children.map(function (child) { return child.id; }).includes(healthStatusId);
                });
                if (category)
                    healthStatusCategory = category[1];
            }
            if (healthStatusCategory) {
                var filterValues = healthStatusCategory.values
                    ? healthStatusCategory.values.map(function (_value) { return _value.id; })
                    : [];
                if (isCategory) {
                    if (healthStatusCategory.children)
                        filterValues = filterValues.concat(healthStatusCategory.children.map(function (child) { return child.id; }));
                }
                else
                    filterValues.push(healthStatusId);
                return uniq(allHealthStatuses.concat(filterValues));
            }
            else
                return allHealthStatuses.concat([healthStatusId]);
        }, []);
    };
    MachineEntityTypeService.prototype.getFirstSeenQuery = function (firstseen) {
        return firstseen.length == 1
            ? {
                firstSeenLessThan: firstseen[0] === FirstSeen.Over7DaysAgo,
                firstSeenComparisonDays: FIRST_SEEN_DEFAULT_TIME_COMPARISON,
            }
            : {};
    };
    return MachineEntityTypeService;
}());
export { MachineEntityTypeService };
