import {
	Component,
	ChangeDetectionStrategy,
	Input,
	OnDestroy,
	OnInit,
	ChangeDetectorRef,
} from '@angular/core';
import { AggregatedIncidentLinkedBy, Alert, LinkedEntityAlert } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { Subscription } from 'rxjs';
import { LinkedAlerts, GetAlertLinkedAlertsApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { Router } from '@angular/router';
import { AlertsFields } from '../../services/alerts.fields';
import { DataviewField } from '@wcd/dataview';
import { Feature, FeaturesService } from '@wcd/config';
import { AlertLinkedFields } from '../../services/alert.linked.fields';

@Component({
	selector: 'alert-linked-alerts',
	templateUrl: './alert-linked-alerts-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AlertLinkedAlertsPanelComponent extends PanelContainer
	implements OnChanges<AlertLinkedAlertsPanelComponent>, OnDestroy, OnInit {
	@Input() alertId: string;
	@Input() linkedByReason: AggregatedIncidentLinkedBy;

	tableFields: Array<DataviewField> = [];
	alerts: Array<Alert|LinkedEntityAlert> = [];
	unexposedAlertsCount: number = 0;
	loading = true;
	apiCallSubscription: Subscription;
	constructor(
		protected router: Router,
		private readonly paris: Paris,
		private readonly alertFields: AlertsFields,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly featuresService: FeaturesService,
		private readonly alertLinkedFields: AlertLinkedFields
	) {
		super(router);
	}

	ngOnDestroy() {
		this.unsubscribe();
		super.ngOnDestroy();
	}

	ngOnInit() {
		this.init();
	}

	ngOnChanges(changes: TypedChanges<AlertLinkedAlertsPanelComponent>) {
		if (
			(changes.alertId && this.alertId !== changes.alertId.currentValue) ||
			(changes.linkedByReason && changes.linkedByReason.currentValue !== this.linkedByReason)
		) {
			this.init();
		}
	}

	private init() {
		if (this.alertId && this.linkedByReason && this.linkedByReason.sourceEntity) {
			const fields = this.alertLinkedFields.getAlertLinkedFields(this.linkedByReason.sourceEntity.type)
			this.tableFields = fields
				.map(field => {
					return { ...field, sort: { ...field.sort, enabled: false } };
				})
				.map(field => new DataviewField<Alert>(field));

			this.unsubscribe();
			this.apiCallSubscription = this.paris
				.apiCall(GetAlertLinkedAlertsApiCall, { ...this.linkedByReason, alertId: this.alertId, vNextApi: this.featuresService.isEnabled(Feature.LinkedAlertsVNextMigration) }, { ignoreFieldsCasing: true })
				.subscribe(
					({ linkedAlertsList, unexposedAlertsCount }: LinkedAlerts) => {
						this.alerts = linkedAlertsList;
						this.unexposedAlertsCount = unexposedAlertsCount;
						this.endLoading();
					},
					() => {
						this.endLoading();
					}
				);
		}
	}

	private unsubscribe() {
		if (this.apiCallSubscription) {
			this.apiCallSubscription.unsubscribe();
		}
	}

	private endLoading() {
		this.loading = false;
		this.changeDetectorRef.markForCheck();
	}
}
