var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReadOnlyIdentifiable } from '../../../data/models/readonly-identifiable.model';
import { Dictionary } from '@wcd/config';
var AirsNotificationRuleConditionGroupModel = /** @class */ (function (_super) {
    __extends(AirsNotificationRuleConditionGroupModel, _super);
    function AirsNotificationRuleConditionGroupModel(data) {
        var _this = _super.call(this, data) || this;
        _this._allValues = new Map();
        return _this;
    }
    Object.defineProperty(AirsNotificationRuleConditionGroupModel.prototype, "valuesMap", {
        get: function () {
            if (!this._valuesMap)
                this._valuesMap = Dictionary.fromList(this.values, 'id');
            return this._valuesMap;
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleConditionGroupModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
        this.values = data.values;
    };
    AirsNotificationRuleConditionGroupModel.prototype.getValuesByIds = function (valueIds) {
        var valuesMap = this.valuesMap;
        return valueIds.map(function (valueId) {
            var value = valuesMap.get(valueId);
            if (!value) {
                value = AirsNotificationRuleConditionGroupModel.getInvalidValue(valueId);
                valuesMap.set(valueId, value);
            }
            return value;
        });
    };
    AirsNotificationRuleConditionGroupModel.prototype.getAllValues = function (valueIds) {
        if (!valueIds || !valueIds.length)
            return this.values;
        var allValuesKey = valueIds.join('_'), allCurrentValues = this._allValues.get(allValuesKey);
        if (allCurrentValues)
            return allCurrentValues;
        var values = this.values;
        var valuesMap = this.valuesMap;
        values = values.concat(valueIds.reduce(function (invalidValues, value) {
            if (!valuesMap.get(value)) {
                var invalidValue = AirsNotificationRuleConditionGroupModel.getInvalidValue(value);
                invalidValues.push(invalidValue);
                valuesMap.set(value, invalidValue);
            }
            return invalidValues;
        }, []));
        this._allValues.set(allValuesKey, values);
        return values;
    };
    AirsNotificationRuleConditionGroupModel.getInvalidValue = function (valueId) {
        return {
            name: valueId.constructor === String ? valueId : '(Unknown)',
            invalid: true,
            id: valueId,
        };
    };
    return AirsNotificationRuleConditionGroupModel;
}(ReadOnlyIdentifiable));
export { AirsNotificationRuleConditionGroupModel };
