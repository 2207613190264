var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, NgZone } from '@angular/core';
import { FilterValuesComponent } from '../../filter-values.component';
var FilterValuesDatePickerComponent = /** @class */ (function (_super) {
    __extends(FilterValuesDatePickerComponent, _super);
    function FilterValuesDatePickerComponent(changeDetectorRef, ngZone) {
        var _this = _super.call(this) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.ngZone = ngZone;
        return _this;
    }
    Object.defineProperty(FilterValuesDatePickerComponent.prototype, "value", {
        get: function () {
            return this.selectedValues;
        },
        enumerable: true,
        configurable: true
    });
    FilterValuesDatePickerComponent.prototype.setSelectedValues = function (selectedValues) {
        if (!selectedValues)
            this.selectedValues = this.config && this.config.initialDate;
        this.changeDetectorRef.markForCheck();
    };
    FilterValuesDatePickerComponent.prototype.serialize = function () {
        var _a;
        return this.selectedValues ? (_a = {}, _a[this.fieldId] = this.selectedValues.toISOString(), _a) : null;
    };
    FilterValuesDatePickerComponent.prototype.deserialize = function (serializedDate) {
        var strDate = serializedDate[this.fieldId];
        if (strDate instanceof Array)
            strDate = strDate[0];
        return strDate ? new Date(strDate) : null;
    };
    FilterValuesDatePickerComponent.prototype.onSelectDate = function (date) {
        var _this = this;
        this.ngZone.run(function () {
            _this.selectedValues = date;
            _this.filterValuesChange.emit(date);
        });
    };
    return FilterValuesDatePickerComponent;
}(FilterValuesComponent));
export { FilterValuesDatePickerComponent };
