import { SeverityValue, WeaknessSeverity, OsDistribution } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
var SoftwareCommonService = /** @class */ (function () {
    function SoftwareCommonService(featuresService, i18nService) {
        var _this = this;
        this.i18nService = i18nService;
        this.getOsDistributionFilterData = function () { return ({
            osDistribution: {
                values: Object.keys(OsDistribution)
                    .filter(function (distribution) {
                    return distribution !== OsDistribution.None &&
                        (_this.isDebianFeatureEnabled || distribution !== OsDistribution.Debian);
                })
                    .map(function (osDistribution) { return ({
                    value: osDistribution,
                    name: _this.i18nService.get("tvm_common_osDistribution_" + OsDistribution[osDistribution]),
                }); }),
            },
        }); };
        this.isDebianFeatureEnabled = featuresService.isEnabled(Feature.TvmDataCookingEnableDebian);
    }
    SoftwareCommonService.prototype.getDecapitalizedString = function (str) {
        return str
            .split(' ')
            .map(function (s) { return s.charAt(0).toLowerCase() + s.substring(1); })
            .join('_');
    };
    SoftwareCommonService.prototype.generateSeveritiesDistributionData = function (weaknessCountBySeverityArr) {
        var _a;
        var returnObj = (_a = {},
            _a[SeverityValue.Critical] = 0,
            _a[SeverityValue.High] = 0,
            _a[SeverityValue.Medium] = 0,
            _a[SeverityValue.Low] = 0,
            _a);
        weaknessCountBySeverityArr.forEach(function (countByWeaknessSeverity) {
            /*
                PM: include the unknown severities in the total count, and add them to the HIGH distribution:
            */
            if ([WeaknessSeverity.High, WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(countByWeaknessSeverity.severity)) {
                returnObj[SeverityValue.High] += countByWeaknessSeverity.weaknesses;
            }
            else {
                returnObj[countByWeaknessSeverity.severity] = countByWeaknessSeverity.weaknesses;
            }
        });
        return returnObj;
    };
    return SoftwareCommonService;
}());
export { SoftwareCommonService };
