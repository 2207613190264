import { Component, EventEmitter, forwardRef, Input, Output, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Disableable, DISABLEABLE_TOKEN } from '../models/disableable.interface';
import { I18nService } from '@wcd/i18n';

const SPACE_KEY_CODE = 32;
let lastId = 0;

@Component({
	selector: 'wcd-toggle',
	template: `
		<span
			class="wcd-toggle"
			[ngClass]="{ disabled: isDisabled }"
			tabindex="0"
			role="switch"
			[attr.aria-label]="ariaLabel ? ariaLabel : trueLabel"
			[attr.aria-checked]="!!checked"
			(click)="_onInteractionEvent($event)"
		>
			<input
				type="checkbox"
				[checked]="checked"
				(change)="_onInteractionEvent($event)"
				[disabled]="isDisabled"
				[name]="name"
				[id]="id"
			/>
			<label class="wcd-toggle-label" [attr.for]="id" [wcdTooltip]="isDisabled && disableReason">
				<span class="wcd-toggle-slider">
					<i class="wcd-toggle-ball"><em class="wcd-high-contrast-toggle-ball"></em></i>
				</span>
			</label>
			<label class="wcd-toggle-true" [attr.for]="id" *ngIf="checked; else unChecked">{{
				trueLabel
			}}</label>
			<wcd-help [text]="helpText" *ngIf="helpText"> </wcd-help>
			<ng-template #unChecked>
				<label class="wcd-toggle-false" [attr.for]="id">{{ falseLabel }}</label>
			</ng-template>
		</span>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => ToggleComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => ToggleComponent),
		},
	],
	styleUrls: ['./toggle.component.scss'],
})
export class ToggleComponent implements ControlValueAccessor, Disableable {
	private _checked: boolean;
	disableReason: string;

	@Input() id = `checkbox-toggle-${lastId++}`;
	@Input() trueLabel = this.i18nService.strings.common_yes;
	@Input() falseLabel = this.i18nService.strings.common_no;
	@Input() isDisabled: boolean;
	@Input() name: string;
	@Input() helpText: string;
	@Input() ariaLabel: string;

	@Input()
	set checked(checked: boolean) {
		if (checked != this.checked) this._checked = checked;
	}

	get checked() {
		return this._checked;
	}

	@Output() change: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private i18nService: I18nService) {}
	onChange: (value: any) => void = value => {};
	onTouched = () => {};

	writeValue(checked: any): void {
		this.checked = !!checked;
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	/**
	 * Toggles the `checked` value between true and false
	 */
	toggle() {
		this.checked = !this.checked;
	}

	/**
	 * Event handler for checkbox input element.
	 * Toggles checked state if element is not disabled.
	 * @param event
	 */
	_onInteractionEvent(event: Event) {
		// We always have to stop propagation on the change event.
		// Otherwise the change event, from the input element, will bubble up and
		// emit its event object to the `change` output.
		event.stopPropagation();
		event.preventDefault();

		if (!this.isDisabled) {
			this.toggle();

			this.onChange(this.checked);
			this.change.emit(this.checked);
		}
	}

	@HostListener('keydown', ['$event'])
	onKeyDown($event: KeyboardEvent) {
		if ($event.keyCode === SPACE_KEY_CODE) this._onInteractionEvent(event);
	}
}
