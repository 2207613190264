import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { LiveResponseCommand } from '../entities/live-response-command.entity';

@ApiCall({
	name: 'Download file',
	endpoint: (config, query) => {
		if(query && query.where && query.where['useV3Api']) {
			return 'cloud/live_response/download_file'
		}

		return query && query.where && query.where['useV2Api']
			? 'v2/live_response/download_file'
			: 'live_response/download_file'
	},
	baseUrl: (config: WcdPortalParisConfig, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	responseType: 'blob',
	parseQuery: (command: LiveResponseCommand) => {
		return {
			params: {
				token: command.downloadToken,
				session_id: command.sessionId,
				useV2Api: command.useV2Api,
				useV3Api: command.useV3Api,
			},
		};
	},
	// create a new blob to force default blob type
	parseData: (file: Blob) => new Blob([file], { type: 'application/octet-stream' }),
})
export class DownloadLiveResponseFileApiCall extends ApiCallModel<File, LiveResponseCommand> {}
