import { Component, Input, OnInit } from '@angular/core';
import { ModalContainer } from '../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';

const MAX_EXPORT_ROW_COUNT = 1000;

@Component({
	selector: 'export-dataset-modal',
	template: `
		<modal (close)="destroy()" [settings]="settings" data-track-component="ExportModal">
			<form #exportForm="ngForm" class="wcd-full-height wcd-flex-vertical" (submit)="exportDataSet()">
				<div class="dialog-contents wcd-flex-1">
					<p>{{ i18nService.get('dataview_export_dialog_how_many', { totalCount: totalCount}) }}</p>
					<div class="large-form-control">
						<div class="form-control-input">
							<input
								type="number"
								min="1"
								[validateMinMax]="{ min: 1, max: maxRowCount }"
								required
								#rowCountInput="ngModel"
								name="rowCount"
								[attr.placeholder]="i18nService.strings.dataview_export_dialog_result_count"
								maxlength="4"
								minlength="1"
								[(ngModel)]="rowCount"
								[attr.max]="maxRowCount"
								[attr.aria-invalid]="!rowCountInput.valid"
								[attr.aria-label]="i18nService.strings.dataview_export_dialog_result_count"
							/>
							<div
								class="error-message"
								*ngIf="!rowCountInput.valid"
								role="alert"
								aria-live="polite">
								{{ 'errors.minMaxNumber' | i18n: { min: '1', max: maxRowCount.toString() } }}
							</div>
						</div>
					</div>
				</div>
				<footer class="dialog-footer wcd-flex-none wcd-flex-horizontal">
					<div>
						<button
							type="button"
							class="btn"
							data-track-id="Cancel"
							data-track-type="Button"
							[disabled]="isExporting"
							(click)="destroy()"
						>
							{{ 'buttons.cancel' | i18n }}
						</button>
					</div>
					<div>
						<button
							*ngIf="!isError"
							type="submit"
							class="btn btn-primary"
							data-track-id="Submit"
							data-track-type="Button"
							[disabled]="exportForm.form.invalid || isExporting"
						>
							<i class="loader-icon" [hidden]="!isExporting"></i>
							{{ isExporting ? i18nService.strings.dataview_export_dialog_please_wait : i18nService.strings.dataview_export_dialog_export }}
						</button>
						<strong
							class="error-message"
							*ngIf="isError"
							role="alert"
							aria-live="polite"
						>
							{{ i18nService.strings.dataview_export_dialog_error }}
						</strong>
					</div>
				</footer>
			</form>
		</modal>
	`,
})
export class ExportDataSetModalComponent extends ModalContainer implements OnInit {
	@Input() totalCount: number;
	@Input() setExportCount: (rowCount: number) => Promise<any>;

	maxRowCount: number;
	isExporting: boolean = false;
	rowCount: number;
	isError: boolean;

	constructor(protected router: Router, private appInsightsService: AppInsightsService, public i18nService: I18nService) {
		super(router);
	}

	ngOnInit() {
		this.rowCount = this.maxRowCount = Math.min(MAX_EXPORT_ROW_COUNT, this.totalCount);
	}

	exportDataSet() {
		this.isExporting = true;
		this.setExportCount(this.rowCount || MAX_EXPORT_ROW_COUNT).then(
			() => {
				this.isExporting = false;
				this.destroy();
			},
			error => {
				this.isExporting = false;
				this.isError = true;
				this.appInsightsService.trackException(new Error('Failed to export data'));
			}
		);
	}
}
