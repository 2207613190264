import { ChangeDetectorRef, Component, Injector, Input } from '@angular/core';
import {
	AirsEntity,
	AirsEntityTypeConfig,
	Alert,
	HuntingContext,
	Investigation,
	Machine,
	MtpInvestigation,
	RemediationAction,
	RemediationActionEntity,
	RemediationActionType,
	RemediationActionTypeMainEntities,
	AirsEntityType,
} from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { CommentModel } from '../../../comments/models/comment.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { CommentsStore } from '../../../comments/services/comments.store';
import { Paris, Repository } from '@microsoft/paris';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { combineLatest, Observable, of } from 'rxjs';
import { flatMap } from 'lodash-es';
import { finalize, switchMap } from 'rxjs/operators';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { Feature, FeaturesService } from '@wcd/config';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';

export const PIVOT_MULTIPLE_MACHINES_ACTION_ID: number = 12;

@Component({
	selector: 'remediation-action-entity-panel',
	templateUrl: './remediation-action.entity-panel.component.html',
})
export class RemediationActionEntityPanelComponent
	extends EntityPanelComponentBase<RemediationAction, RemediationActionEntityPanelOptions>
	implements OnChanges<RemediationActionEntityPanelComponent> {
	investigation: Investigation;
	airsEntities: Array<AirsEntity>;
	alert: Alert;
	commentsOptions: { investigation_action: number };
	isSavingComment: boolean = false;
	refreshOn: Date;
	machines: Array<Machine>;
	users: Array<string>;
	isLoadingAirsEntities: boolean = false;
	isLoadingAlert: boolean = false;
	isLoadingInvestigation: boolean = false;

	@Input() partialEntities: Array<AirsEntity>;
	@Input() fullEntities: Array<AirsEntity>;
	@Input() allowEditComments: boolean = true;
	@Input() comments: ReadonlyArray<CommentModel>;
	@Input() alertId?: string;
	@Input() summarizeEntities: boolean = false;

	get action(): RemediationAction {
		return this.entity;
	}

	get type(): RemediationActionType {
		return (this.action && this.action.remediationActionType) || this.options.type;
	}

	get pivotActionId(): number {
		return PIVOT_MULTIPLE_MACHINES_ACTION_ID;
	}

	constructor(
		private paris: Paris,
		private commentsStore: CommentsStore,
		private dialogsService: DialogsService,
		private injector: Injector,
		public i18nService: I18nService,
		private liveAnnouncer: LiveAnnouncer,
		private featuresService: FeaturesService,
		private appConfigService: AppConfigService,
		changeDetectorRef: ChangeDetectorRef
	) {
		super(changeDetectorRef);
	}

	setEntity(entity: RemediationAction) {
		const isSameInvestigation: boolean =
			(entity && entity.investigationId) === (this.action && this.action.investigationId);
		const isSameAlert: boolean = this.alertId === (this.alert && this.alert.id);
		const isDifferentEntity: boolean = !this.entity || entity.id !== this.entity.id;

		super.setEntity(entity);

		if (!entity) return;

		if (!this.commentsOptions || this.commentsOptions.investigation_action !== +this.action.id) {
			const actionId = parseInt(this.action.id);
			this.commentsOptions = !isNaN(actionId) ? { investigation_action: +this.action.id } : null;
		}

		if (this.isInit) {
			if (isDifferentEntity || (!this.airsEntities && !this.isLoadingAirsEntities)) {
				this.setAirsEntities();
			}
			if (
				(!this.options || this.options.loadInvestigation) &&
				((this.options && this.options.showInvestigation) || !this.alertId) &&
				(!isSameInvestigation || (!this.investigation && !this.isLoadingInvestigation))
			) {
				this.setInvestigation();
			}
			if (!isSameAlert || (this.alertId && !this.alert && !this.isLoadingAlert)) {
				this.setAlert();
			}
		}
	}

	async setAirsEntities() {
		this.airsEntities = null;
		if (this.fullEntities) {
			this.airsEntities = this.fullEntities;
			this.changeDetectorRef.markForCheck();
		} else if (
			!this.options.ignoreEntityData &&
			((this.action && this.type && this.type.mainEntities && this.type.mainEntities.length) ||
				(this.partialEntities && this.partialEntities.length))
		) {
			this.isLoadingAirsEntities = true;

			let entityGetters: Array<Observable<Array<AirsEntity>>>;

			if (this.type && this.type.mainEntities && this.type.mainEntities.length) {
				entityGetters = this.type.mainEntities.map(
					(entityConfig: RemediationActionTypeMainEntities) => {
						return this.paris.getItemById(AirsEntityTypeConfig, entityConfig.entityTypeId).pipe(
							switchMap((entityType: AirsEntityTypeConfig) => {
								if (entityType) {
									const entities:
										| Array<RemediationActionEntity>
										| RemediationActionEntity = <any>this.action[entityConfig.fieldName];

									const entitiesBaseData = this.getEntitiesBaseData(entities);

									if (entitiesBaseData && entitiesBaseData.length) {
										return combineLatest(
											entitiesBaseData.map((entityBaseData: EntityBaseData) => {
												return this.paris.getItemById(
													AirsEntity,
													entityBaseData.entityId,
													undefined,
													{
														type_id: entityType.id,
														investigation_id: this.action.investigationId,
														useOfficeEntityApi: this.action.isOfficeInvestigation,
														useMdiEntityApi:
															entityBaseData.entityTypeId ===
															AirsEntityType.Account,
														tenantId: sccHostService.isSCC
															? sccHostService.loginUser.tenantId
															: null,
														sid: entityBaseData.sid,
														aadUserId: entityBaseData.aadUserId,
													}
												);
											})
										);
									}
								}
								return of([]);
							})
						);
					}
				);
			} else if (this.partialEntities && this.partialEntities.length) {
				entityGetters = [
					combineLatest(
						this.partialEntities
							.filter(e => e.id)
							.map((entity: AirsEntity) => {
								return this.paris.getItemById(AirsEntity, entity.id, null, {
									type_id: entity.type.id,
									investigation_id: this.action.investigationId,
								});
							})
					),
				];
			}

			this.addExtraDataSubscription(
				combineLatest(entityGetters)
					.pipe(
						finalize(() => {
							this.isLoadingAirsEntities = false;
							this.changeDetectorRef.markForCheck();
						})
					)
					.subscribe(
						(entities: Array<Array<AirsEntity>>) => {
							this.airsEntities = flatMap(
								entities.filter(entityArr => entityArr && entityArr.length)
							);
							this.isLoadingAirsEntities = false;
							this.changeDetectorRef.markForCheck();
						},
						error => {
							console.error(error);
						}
					)
			);
		}
	}

	private getEntitiesBaseData(
		entities: Array<RemediationActionEntity> | RemediationActionEntity
	): Array<EntityBaseData> {
		if (!entities) {
			return null;
		}

		if (entities instanceof Array) {
			return entities.map(entity => this.getEntityBaseData(entity)).filter(Boolean);
		} else {
			const entityBaseData = this.getEntityBaseData(entities);
			return entityBaseData ? [entityBaseData] : null;
		}

		return null;
	}

	private getEntityBaseData(remediationActionEntity: RemediationActionEntity): EntityBaseData {
		if (!remediationActionEntity) {
			return null;
		}

		const entityId = remediationActionEntity.id;
		const entityTypeId = remediationActionEntity.entityTypeId;

		if (entityId) {
			const entityBaseData = { entityId: entityId, entityTypeId: entityTypeId };

			if (entityTypeId === AirsEntityType.Account) {
				if (!remediationActionEntity.sid) {
					return null;
				}

				return Object.assign(entityBaseData, {
					sid: remediationActionEntity.sid,
					aadUserId: remediationActionEntity.aadUserId,
				});
			}

			return entityBaseData;
		}

		return null;
	}

	ngOnChanges(changes: TypedChanges<RemediationActionEntityPanelComponent>) {
		if (changes.partialEntities || changes.fullEntities) {
			this.setAirsEntities();
		}
	}

	setInvestigation() {
		this.investigation = null;
		this.isLoadingInvestigation = true;
		if (!this.alertId) {
			this.alert = null;
			this.isLoadingAlert = true;
		}
		if (this.action.investigationId) {
			/*
				TODO: LIRAN:
				it may broke MDATP investigation loading in case PortedSccPages flag is enabled.
				consider adding indication in backend if it's an action from office investigation.
			 */
			const repo: Repository<Investigation> =
				this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
				this.action.isOfficeInvestigation
					? this.paris.getRepository(MtpInvestigation)
					: this.paris.getRepository(Investigation);
			this.addExtraDataSubscription(
				repo
					.getItemById(this.action.investigationId, undefined, {
						tenantId: this.appConfigService.tenantId,
					})
					.pipe(
						finalize(() => {
							this.isLoadingInvestigation = false;
							this.changeDetectorRef.markForCheck();
						})
					)
					.subscribe((investigation: Investigation) => {
						this.investigation = investigation;
						this.isLoadingInvestigation = false;
						if (!this.alertId) {
							this.setAlert();
						}
						this.changeDetectorRef.markForCheck();
					})
			);
		} else {
			this.isLoadingInvestigation = false;
			if (!this.alertId) {
				this.isLoadingAlert = false;
			}
		}
		this.changeDetectorRef.markForCheck();
	}

	setAlert() {
		this.alert = null;
		this.isLoadingAlert = true;
		const alertId =
			this.alertId || (this.investigation && this.investigation.alert && this.investigation.alert.id);
		if (alertId) {
			this.addExtraDataSubscription(
				this.paris
					.getRepository(Alert)
					.getItemById(alertId)
					.pipe(
						finalize(() => {
							this.isLoadingAlert = false;
							this.changeDetectorRef.markForCheck();
						})
					)
					.subscribe((alert: Alert) => {
						this.alert = alert;
						this.isLoadingAlert = false;
						this.changeDetectorRef.markForCheck();
					})
			);
		} else {
			this.isLoadingAlert = false;
		}
		this.changeDetectorRef.markForCheck();
	}

	showRelatedEntity(entity: AirsEntity) {
		this.injector.get(EntityPanelsService).showEntity(
			AirsEntity,
			entity,
			{ investigation_id: this.action.investigationId },
			{
				back: {
					onClick: () => this.injector.get(EntityPanelsService).closeEntityPanel(AirsEntity),
				},
			}
		);
	}

	saveComment(commentText: string): void {
		this.isSavingComment = true;
		const comment: CommentModel = this.commentsStore.createComment(commentText, {
			investigation: (this.investigation && this.investigation.id) || this.action.investigationId,
			investigation_action: this.action.id,
		});
		this.commentsStore
			.save(comment, null, true, '#Action-entity-panel editable-comments-list textarea')
			.then(
				() => {
					this.isSavingComment = false;
					this.refreshOn = new Date();
					this.liveAnnouncer.announce(
						this.i18nService.get('comments_save_success', {
							date: comment.timestamp,
						})
					);

					this.changeDetectorRef.detectChanges();
				},
				error => {
					this.isSavingComment = false;
					this.dialogsService.showError({
						title: error.message || error,
						message: this.i18nService.strings.comments_save_error,
						data: error.data,
					});
				}
			);
	}
}

export interface RemediationActionEntityPanelOptions {
	type: RemediationActionType;
	showInvestigation?: boolean;
	allowStatusSplit?: boolean;
	showComments?: boolean;
	loadInvestigation?: boolean;
	huntingContext?: HuntingContext;
	ignoreEntityData?: boolean;
}

export interface EntityBaseData {
	entityId: number;
	entityTypeId: AirsEntityType;
	sid?: string;
	aadUserId?: string;
}
