import { NgModule } from '@angular/core';
import { AirsNotificationRuleEditComponent } from './components/airs-notification-rule-edit.component';
import { AirsNotificationRulesComponent } from './components/airs-notification-rules.component';
import { AirsNotificationRuleConditionGroupsBackendService } from './services/airs-notification-rule-condition-groups.backend.service';
import { AirsNotificationRuleConditionGroupsStore } from './services/airs-notification-rule-condition-groups.store';
import { AirsNotificationRulesBackendService } from './services/airs-notification-rules.backend.service';
import { AirsNotificationRulesStore } from './services/airs-notification-rules.store';
import { AirsNotificationRulesReportsBackendService } from './services/airs-notification-rules-reports.backend.service';
import { AirsNotificationRulesReportsStore } from './services/airs-notification-rules-reports.store';
import { SharedModule } from '../../shared/shared.module';
import { AirsNotificationRulesService } from './services/airs-notification-rules.service';
import { AirsNotificationRuleEditPanelComponent } from './components/airs-notification-rule-edit.panel.component';
import { PanelsModule } from '@wcd/panels';
@NgModule({
	imports: [SharedModule, PanelsModule],
	declarations: [
		AirsNotificationRuleEditComponent,
		AirsNotificationRulesComponent,
		AirsNotificationRuleEditPanelComponent,
	],
	exports: [AirsNotificationRuleEditComponent, AirsNotificationRulesComponent],
	entryComponents: [AirsNotificationRulesComponent, AirsNotificationRuleEditPanelComponent],
	providers: [
		AirsNotificationRuleConditionGroupsBackendService,
		AirsNotificationRuleConditionGroupsStore,
		AirsNotificationRulesBackendService,
		AirsNotificationRulesStore,
		AirsNotificationRulesReportsBackendService,
		AirsNotificationRulesReportsStore,
		AirsNotificationRulesService,
	],
})
export class AirsNotificationRulesModule {}
