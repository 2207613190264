import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

export enum SampleRequestStatus {
	Failed = -1,
	Active = 0,
	Completed = 1,
}

@ValueObject({
	singularName: 'Sample request info',
	pluralName: '',
	readonly: true,
})
export class SampleRequestInfo extends ModelBase {
	@EntityField({ data: 'RequestGuid' })
	readonly requestGuid: string;

	@EntityField({ data: 'Sha1' })
	readonly sha1: string;

	@EntityField({ data: 'OrgId' })
	readonly orgId: string;

	@EntityField({ data: 'Requestor' })
	readonly requestor: string;

	@EntityField({ data: 'RequestorComment' })
	readonly requestorComment: string;

	@EntityField({ data: 'Status' })
	readonly status: SampleRequestStatus;

	@EntityField({ data: 'CreationTime' })
	readonly creationTime: Date;

	@EntityField({ data: 'LastUpdateTime' })
	readonly lastUpdateTime: Date;
}
