var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, OnInit, QueryList, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DataTableComponent, DataTableField } from '@wcd/datatable';
import { AadUsersMcasDetailsApiCall, ImpactedEntities, } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { wasChangedAndExists } from '@wcd/angular-extensions';
import { entityMap } from '../impacted-entities.model';
import { SpinnerSize } from 'office-ui-fabric-react';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { areAnyEntitiesTruncated, areEntitiesTruncated, getEntities, } from './impacted-entities-datatable.helpers';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { ImpactedEntitiesPanelComponent } from '../impacted-entities-panel.component';
import { I18nService } from '@wcd/i18n';
import { AadUserInvestigationPriorityComponent } from '../../../global_entities/components/entity-details/aad-user/aad-user-investigation-priority.component';
import { isNumber } from 'lodash-es';
import { Feature, FeaturesService } from '@wcd/config';
var largeWidth = 290;
var smallWidth = 190;
var MAX_ITEMS = 3;
var ImpactedEntitiesDatatablesComponent = /** @class */ (function () {
    function ImpactedEntitiesDatatablesComponent(paris, changeDetectorRef, dialogsService, i18nService, featuresService) {
        var _this = this;
        this.paris = paris;
        this.changeDetectorRef = changeDetectorRef;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.showTitle = true;
        this.maxItems = MAX_ITEMS;
        this.showAll = {};
        this.dataLoaded = false;
        this.SpinnerSize = SpinnerSize;
        this.entitiesColumns = {
            machines: DataTableField.fromList([
                {
                    id: 'machines',
                    name: this.i18nService.get('impacted_entities_datatables_machine_title'),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (machine) {
                            return {
                                entity: machine,
                                entityTypeId: 'machine',
                            };
                        },
                    },
                    useDefaultEmptyFieldComponent: true,
                    maxWidth: smallWidth,
                    minWidth: smallWidth,
                    sort: { enabled: false },
                },
                {
                    id: 'riskScores',
                    name: this.i18nService.get('impacted_entities_datatables_ristk_level_title'),
                    getDisplay: function (machine) {
                        return machine.riskScore && _this.i18nService.get(machine.riskScore.nameI18nKey);
                    },
                    className: 'nowrap',
                    useDefaultEmptyFieldComponent: true,
                    maxWidth: smallWidth,
                    minWidth: smallWidth,
                    getCssClass: function (machine) {
                        return machine.riskScore
                            ? "wcd-severity wcd-severity-" + machine.riskScore.id.toLocaleLowerCase()
                            : 'disabled';
                    },
                    sort: { enabled: false },
                },
                {
                    id: 'exposureScores',
                    name: this.i18nService.get('impacted_entities_datatables_exposure_level_title'),
                    getDisplay: function (machine) {
                        return machine.exposureScore &&
                            _this.i18nService.get('machineExposureScoreType_' + machine.exposureScore.id);
                    },
                    icon: {
                        fabricIcon: function (machine) {
                            var warningThreshold = 4;
                            return machine.exposureScore && machine.exposureScore.priority < warningThreshold
                                ? FabricIconNames.Warning
                                : null;
                        },
                        className: function (machine) {
                            return machine.exposureScore ? 'color-text-' + machine.exposureScore.className : null;
                        },
                    },
                    className: 'nowrap',
                    getCssClass: function (machine) { return (machine.exposureScore ? null : 'disabled'); },
                    useDefaultEmptyFieldComponent: true,
                    sort: { enabled: false },
                },
            ]),
            machineGroup: DataTableField.fromList([]),
            users: DataTableField.fromList([
                {
                    id: 'users',
                    name: this.i18nService.get('impacted_entities_datatables_users_title'),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (aadUser) {
                            return {
                                entity: aadUser,
                                entityTypeId: 'aaduser',
                            };
                        },
                    },
                    maxWidth: largeWidth,
                    minWidth: largeWidth,
                    sort: { enabled: false },
                },
                {
                    id: 'investigation priority',
                    name: this.i18nService.get('impacted_entities_datatables_investigation_priority_title'),
                    component: {
                        type: AadUserInvestigationPriorityComponent,
                        getProps: function (user) { return ({
                            percentile: user.percentile,
                            priority: user.investigationPriority,
                        }); },
                    },
                    className: 'nowrap',
                    useDefaultEmptyFieldComponent: true,
                    getIsEmptyField: function (user) { return !user || !isNumber(user.investigationPriority); },
                    sort: { enabled: false },
                },
            ]),
            mailboxes: DataTableField.fromList([
                {
                    id: 'mailboxes',
                    name: this.i18nService.get('impacted_entities_datatables_mailboxes_title'),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (mailbox) {
                            return {
                                entity: mailbox,
                                entityTypeId: 'mailbox',
                            };
                        },
                    },
                    maxWidth: largeWidth,
                    minWidth: largeWidth,
                    sort: { enabled: false },
                },
                {
                    id: 'displayName',
                    name: this.i18nService.get('impacted_entities_datatables_display_name_title'),
                    getDisplay: function (mailbox) { return mailbox.displayName; },
                    className: 'nowrap',
                    icon: {
                        fabricIcon: FabricIconNames.Contact,
                    },
                    useDefaultEmptyFieldComponent: true,
                    maxWidth: largeWidth,
                    minWidth: largeWidth,
                    sort: { enabled: false },
                },
            ]),
            apps: DataTableField.fromList(this.featuresService.isEnabled(Feature.ConsiderApplicationAsAsset)
                ? [
                    {
                        id: 'type',
                        name: this.i18nService.strings.impacted_entities_datatables_apps_title,
                        className: EntityNameComponent.entityNameDefaultCssClass,
                        icon: { fabricIcon: FabricIconNames.Cloud },
                        component: {
                            type: EntityNameComponent,
                            getProps: function (entity) { return ({
                                entity: entity,
                                entityTypeId: 'app',
                                entityName: entity.name,
                            }); },
                        },
                    },
                    {
                        id: 'riskLevel',
                        name: this.i18nService.strings.incident_apps_field_risk,
                        getDisplay: function (app) {
                            return app.riskLevel &&
                                _this.i18nService.get("incident.apps.field.risk." + app.riskLevel);
                        },
                        className: 'nowrap',
                        getCssClass: function (app) {
                            return app.riskLevel
                                ? "wcd-severity wcd-severity-" + app.riskLevel.toLocaleLowerCase()
                                : 'disabled';
                        },
                    },
                ]
                : []),
        };
    }
    ImpactedEntitiesDatatablesComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (!this.entities.users.length ||
            this.entities.users.every(function (user) { return isNumber(user.investigationPriority); })) {
            this.dataLoaded = true;
            return;
        }
        this.paris
            .apiCall(AadUsersMcasDetailsApiCall, this.entities.users.map(function (user) { return user.aadUserId; }))
            .pipe(catchError(function (err) { return of({}); }))
            .subscribe(function (res) {
            _this.entities.users = _this.entities.users.map(function (user) { return (__assign({}, user, { investigationPriority: user.investigationPriority ||
                    (res[user.aadUserId] && res[user.aadUserId].McasInvestigationPriority
                        ? parseInt(res[user.aadUserId].McasInvestigationPriority, 10)
                        : null) })); });
            _this.dataLoaded = true;
            _this.changeDetectorRef.markForCheck();
        });
    };
    ImpactedEntitiesDatatablesComponent.prototype.updateTableHeaders = function () {
        if (this.tables && this.tables.length) {
            this.tables.forEach(function (table) {
                table.updateHeaderCells();
            });
        }
    };
    ImpactedEntitiesDatatablesComponent.prototype.ngOnChanges = function (changes) {
        if (!wasChangedAndExists(changes.entities)) {
            return;
        }
        this.entitiesTotalCount = Object.values(changes.entities.currentValue).reduce(function (acc, entities) { return acc + ((entities && entities.length) || 0); }, 0);
    };
    ImpactedEntitiesDatatablesComponent.prototype.keyDescOrder = function (a, b) {
        if (!entityMap[a.key] || !entityMap[b.key]) {
            return 0;
        }
        return entityMap[a.key].order - entityMap[b.key].order;
    };
    ImpactedEntitiesDatatablesComponent.prototype.getDisplayedEntities = function (entityList) {
        return this.showAllItems || this.showAll[entityList.key]
            ? entityList.value
            : this.truncatedEntities[entityList.key];
    };
    Object.defineProperty(ImpactedEntitiesDatatablesComponent.prototype, "truncatedEntities", {
        get: function () {
            return getEntities(this.entities, this.maxItems);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ImpactedEntitiesDatatablesComponent.prototype, "areAnyEntitiesTruncated", {
        get: function () {
            return areAnyEntitiesTruncated(this.entities, this.maxItems);
        },
        enumerable: true,
        configurable: true
    });
    ImpactedEntitiesDatatablesComponent.prototype.areEntitiesTruncated = function (entityType) {
        return areEntitiesTruncated(this.entities, entityType, this.maxItems);
    };
    ImpactedEntitiesDatatablesComponent.prototype.openPanel = function () {
        this.dialogsService.showPanel(ImpactedEntitiesPanelComponent, {
            headerText: this.i18nService.get('entities.impactedEntities.title') + " (" + this.entitiesTotalCount + ")",
            type: PanelType.large,
        }, { entities: this.entities });
    };
    ImpactedEntitiesDatatablesComponent.prototype.toggleShowAll = function (entityListKey) {
        this.showAll[entityListKey] = !this.showAll[entityListKey];
    };
    ImpactedEntitiesDatatablesComponent.prototype.isItemClickable = function (entity) {
        return false;
    };
    return ImpactedEntitiesDatatablesComponent;
}());
export { ImpactedEntitiesDatatablesComponent };
