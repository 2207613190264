/* tslint:disable:template-click-events-have-key-events */
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Threat } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';

@Component({
	selector: 'threat-campaigns-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './threat-campaigns.widget.html',
	styleUrls: ['./threat-campaigns.widget.scss'],
})
export class TvmThreatCampaignsWidget extends ReportWidgetComponent<Array<Threat>> {
	exposureTooltip: string;
	configurationTooltip: string;
	widgetTooltip: string;

	constructor(
		reportsService: ReportsService,
		private router: Router,
		private paris: Paris,
		tvmTextsService: TvmTextsService,
		private i18nService: I18nService
	) {
		super(reportsService);

		this.exposureTooltip = tvmTextsService.getText(TextToken.ExposureScoreTooltip);
		this.configurationTooltip = tvmTextsService.getText(TextToken.ConfigurationScoreTooltip);
		this.widgetTooltip = tvmTextsService.getText(TextToken.ScoreImpactInfo);
	}

	get widgetConfig() {
		return {
			id: 'threatCampaigsnWidget',
			name: this.i18nService.get('tvm.dashboard.threatCampaigns.title'),
			noDataMessage: this.i18nService.get(`tvm.dashboard.noData.threatCampaigns`),
			noDataIcon: FabricIconNames.Trackers,
			NoIconLeftAlign: true,
			loadData: () =>
				this.paris
					.query<Threat>(Threat, {
						page: 1,
						pageSize: 4,
						sortBy: [
							{
								field: 'exposureScore',
								direction: DataQuerySortDirection.descending,
							},
							{
								field: 'secureScore',
								direction: DataQuerySortDirection.descending,
							},
						],
					})
					.pipe(
						map(ds => {
							return ds.items
								.sort((a, b) => {
									if (a.exposureScore === b.exposureScore) {
										return (b.secureScore || 0) - (a.secureScore || 0);
									}
									return b.exposureScore - a.exposureScore;
								})
								.slice(0, 4);
						})
					),
			minHeight: '210px',
		};
	}

	openThreatCampaign(threat: Threat): void {
		this.router.navigate(['/threatanalytics3', threat.id]);
	}

	openThreatAnalyticsDashBoard(): void {
		this.router.navigate(['/threatanalytics3']);
	}
}
