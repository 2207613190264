var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { CapacityState, CustomTiIndicatorsCapacityApiCall, CustomTiIndicatorsType, CustomTiIndicatorsTypes, SystemExclusionType, } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { of, race } from 'rxjs';
import { map, mapTo, startWith, switchMap, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
var loadingSymbol = Symbol();
var AddFilesToAllowlistModal = /** @class */ (function (_super) {
    __extends(AddFilesToAllowlistModal, _super);
    function AddFilesToAllowlistModal(paris, router, customTiIndicatorService, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.router = router;
        _this.customTiIndicatorService = customTiIndicatorService;
        _this.i18nService = i18nService;
        _this.cancel = new EventEmitter();
        _this.currentFileIndex = 0;
        _this.loadingSymbol = loadingSymbol;
        _this.filesSystemExclusionType = _this.paris.getValue(SystemExclusionType, 'files');
        return _this;
    }
    AddFilesToAllowlistModal.prototype.ngOnInit = function () {
        var _this = this;
        this.setUniqueHashes();
        this.setCurrentFileValues();
        this.tiCapacityData$ = this.paris.apiCall(CustomTiIndicatorsCapacityApiCall).pipe(map(function (capacity) {
            if (capacity.capacityState === CapacityState.Unavailable) {
                return _this.i18nService.strings
                    .customTiIndicator_dataview_actions_capacity_status_unavailable_title;
            }
            if (capacity.total + _this.uniqueHashes.length > capacity.limit) {
                return _this.i18nService.strings
                    .customTiIndicator_dataview_actions_capacity_status_exceeded_tooltip;
            }
            return null;
        }), startWith(loadingSymbol));
    };
    AddFilesToAllowlistModal.prototype.setUniqueHashes = function () {
        this.uniqueHashes = Array.from(new Set((this.hashes || []).filter(Boolean)));
    };
    AddFilesToAllowlistModal.prototype.setCurrentFileValues = function () {
        this.currentFileHash =
            (this.uniqueHashes.length && this.uniqueHashes[this.currentFileIndex]) || this.sha1;
    };
    AddFilesToAllowlistModal.prototype.setSystemExclusionRule = function () {
        var _this = this;
        this.showExclusionPanel(this.filesSystemExclusionType, this.currentFileHash).then(function () {
            _this.onDone && _this.onDone();
        }, function () {
            _this.onDone && _this.onDone();
        });
        this.destroy();
    };
    AddFilesToAllowlistModal.prototype.showExclusionPanel = function (filesSystemExclusion, sha1) {
        var _this = this;
        if (!sha1) {
            return Promise.resolve(null);
        }
        var nextSha1;
        var handlePromise = function () {
            _this.currentFileIndex++;
            _this.setCurrentFileValues();
            nextSha1 = !_this.currentFileHash || sha1 === nextSha1 ? null : _this.currentFileHash;
            return _this.showExclusionPanel(filesSystemExclusion, nextSha1);
        };
        return this.customTiIndicatorService
            .showCustomTiIndicatorNewDialog(this.paris.getValue(CustomTiIndicatorsType, CustomTiIndicatorsTypes.Files), sha1)
            .pipe(switchMap(function (panel) {
            return race([
                panel.instance.save.pipe(mapTo(true)),
                panel.instance.cancel.pipe(mapTo(false)),
            ]).pipe(take(1));
        }), switchMap(function (saved) {
            if (saved || _this.uniqueHashes.length > 1) {
                return handlePromise();
            }
            return of(null);
        }))
            .toPromise();
    };
    return AddFilesToAllowlistModal;
}(ModalContainer));
export { AddFilesToAllowlistModal };
