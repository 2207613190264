var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { Incident, IncidentAssociatedIncidentsApiCall, } from '@wcd/domain';
import { map } from 'rxjs/operators';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { AssociatedIncidentsFields } from '../incident.associated-incidents.fields';
var AssociatedIncidentsPanelComponent = /** @class */ (function (_super) {
    __extends(AssociatedIncidentsPanelComponent, _super);
    function AssociatedIncidentsPanelComponent(paris, router, associatedIncidentsFields) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.router = router;
        _this.associatedIncidentsFields = associatedIncidentsFields;
        return _this;
    }
    Object.defineProperty(AssociatedIncidentsPanelComponent.prototype, "sourceValue", {
        set: function (incident) {
            this._originalIncident = incident;
        },
        enumerable: true,
        configurable: true
    });
    AssociatedIncidentsPanelComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.associatedIncidents$ = this.paris
            .apiCall(IncidentAssociatedIncidentsApiCall, { id: this._originalIncident.id })
            .pipe(map(function (result) { return result.relatedIncidents; }));
    };
    return AssociatedIncidentsPanelComponent;
}(PanelContainer));
export { AssociatedIncidentsPanelComponent };
