<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="(data$ | async) as data" class="wcd-full-height wcd-flex-vertical" data-track-component="TvmThreatCampaignsWidget">
	<div class="wcd-flex-vertical wcd-flex-1">
		<table class="wcd-table wcd-full-width table-grouped no-side-padding">
			<thead>
				<tr>
					<th>{{ ('tvm.dashboard.threatCampaigns.name' | i18n) }}</th>
					<th>{{ ('tvm.dashboard.threatCampaigns.machinesAtRisk' | i18n) }}</th>
					<th>{{ ('tvm.common.securityRecommendations' | i18n) }}</th>
					<th>{{ ('tvm.common.impact' | i18n) }} <fab-icon iconName="Info" [wcdTooltip]="widgetTooltip"></fab-icon></th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let threat of data"
					class="table-group-header"
					data-track-type="Navigation"
					data-track-id="openThreatCampaign"
					(click) = "openThreatCampaign(threat)"
					>

					<td class="threat-name full-width-cell">
						{{ threat.name }}
					</td>
					<td class="nowrap">
						{{ threat.affectedDevicesCount | prettyNumber }}
					</td>
					<td class="nowrap wcd-font-weight-semibold">
						{{ threat.securityRecommendationsCount | prettyNumber }}
					</td>
					<td class="nowrap">
							<tvm-impact-score [scoreData]="{exposureScore: threat.exposureScore, secureScore: threat.secureScore,  exposureScoreTooltip: exposureTooltip, secureScoreTooltip: configurationTooltip}"></tvm-impact-score>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<a
		[attr.aria-label]="'tvm.dashboard.showMore.threatCampaigns' | i18n"
		tabindex="0"
	 	class="wcd-margin-top"
		data-track-id="openThreatAnalyticsDashBoard"
		data-track-type="Navigation"
		(click)="openThreatAnalyticsDashBoard()">{{ ('common.showMore' | i18n) }}
	</a>
</div>
