import { Entity, EntityField } from '@microsoft/paris';
import { airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsFileBase } from './airs-entity.file-base';

@Entity({
	...airsEntityConfig,
	singularName: 'File',
	pluralName: 'Files',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsFile,
})
export class AirsFile extends AirsFileBase {
	@EntityField({ defaultValue: Object.freeze({}) })
	hashes: { md5: string; sha1: string; sha256: string };

	@EntityField({ data: 'created_time' })
	createdTime: Date;

	@EntityField({ data: 'modified_time' })
	modifiedTime: Date;

	@EntityField({ data: 'directory_path' })
	directoryPath: string;

	@EntityField({ data: 'is_read_only' })
	isReadOnly: boolean;

	@EntityField({ data: 'is_downloaded' })
	isDownloaded: boolean;

	@EntityField({ data: 'is_hidden' })
	isHidden: boolean;

	@EntityField({ data: 'path' })
	path: string;

	@EntityField({ data: 'host_os' })
	hostOs: string;

	@EntityField({ data: 'host_group' })
	hostGroup: string;
}
