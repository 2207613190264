<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngIf="showIpAddress">
		<dt>{{ 'machines.ips.fields.ipAddress.title' | i18n }}</dt>
		<dd [wcdTooltip]="ip.address">{{ ip.address }}</dd>
	</ng-container>

	<dt>{{ 'machines.ips.fields.physicalAddress.title' | i18n }}</dt>
	<dd>
		<copyable-input [ariaLabel]="'machines.ips.fields.physicalAddress.title' | i18n" *ngIf="ip.adapter.physicalAddress; else noPhysicalAddress" [value]="ip.adapter.physicalAddress" [readonly]="true"></copyable-input>
		<ng-template #noPhysicalAddress>
			{{ 'machines.ips.fields.physicalAddress.notAvailable' | i18n }}
			<wcd-help *ngIf="showPhysicalAddressNotAvailableTooltip" [text]="'machines.ips.fields.physicalAddress.notAvailableTooltip' | i18n"></wcd-help>
		</ng-template>
	</dd>

	<ng-container *ngIf="ip.adapter.connectedNetworks?.length">
		<dt>{{ 'machines.ips.fields.networkCategory.title' | i18n }}</dt>
		<dd [wcdTooltip]="ip.adapter.connectedNetworks[0].category">{{ ip.adapter.connectedNetworks[0].category }}</dd>
		<dt>{{ 'machines.ips.fields.networkDescription.title' | i18n }}</dt>
		<dd [wcdTooltip]="ip.adapter.connectedNetworks[0].description">{{ ip.adapter.connectedNetworks[0].description }}</dd>
	</ng-container>

	<ng-container *ngIf="ip.adapter.dnsSuffix">
		<dt>{{ 'machines.ips.fields.dnsSuffix.title' | i18n }}</dt>
		<dd [wcdTooltip]="ip.adapter.dnsSuffix">{{ ip.adapter.dnsSuffix }}</dd>
	</ng-container>

	<ng-container *ngIf="ip.isInternal != null">
		<dt>{{ 'machines.ips.fields.ipType.title' | i18n }}</dt>
		<dd>{{ 'machines.ips.fields.ipType.' + (ip.isInternal ? 'internal' : 'external') | i18n }}</dd>
	</ng-container>

	<ng-container *ngIf="ip.adapter.adapterType">
		<dt>{{ 'machines.ips.fields.adapterType.title' | i18n }}</dt>
		<dd>{{ ip.adapter.adapterType | ipAdapterType }}</dd>
	</ng-container>
</dl>
