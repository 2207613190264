import { Subject, of, Observable } from 'rxjs';
import { Component, OnInit, Input } from '@angular/core';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { EmailNotificationFormData, KeyValue } from './email-notification-model';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { EmailNotificationCompletedComponent } from './email-notification-completed.component';
import { catchError, map } from 'rxjs/operators';
import { NotificationRuleServiceSourceConditionValue } from '@wcd/domain';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './email-notification-review-step.component.html',
})
export class EmailNotificationReviewStepComponent<T extends EmailNotificationFormData>
	extends WizardBaseStep<T>
	implements OnInit {
	saving$ = new Subject<boolean>();

	/*
		Some configurations for showing the requested fields in the overview, based of the form data.
		These configurations are passed as is to the email-notification-summary component.
	*/
	@Input() shouldDisplayNotificationSettings: (data: T) => boolean;
	@Input() customSettings: (data: T) => KeyValue[];
	@Input() rulesSettings: (data: T) => {'serviceSources': NotificationRuleServiceSourceConditionValue[], 'severitiesText': string}[];
	@Input() notificationSettings: (data: T) => string[];
	@Input() shouldDisplayDeviceGroups: boolean;
	@Input() isSettingsV2: boolean;
	@Input() status: (data: T) => string;
	@Input() explicitGlobalNotificationText: boolean;

	/*
		Function which calls 'save' function of the relevant Paris repository with the form data.
	*/
	@Input() saveRuleFunction: (data: T) => Observable<any>;

	constructor(public i18n: I18nService, private dialogsService: DialogsService) {
		super();
	}

	createRule = () => {
		this.setBackButtonEnabled(false);
		this.setStepValidation(false);
		this.saving$.next(true);
		return this.saveRuleFunction(this.data).pipe(
			map(() => {
				this.markWizardCompleted(EmailNotificationCompletedComponent);
				this.saving$.next(false);
				return true;
			}),
			catchError(() => {
				this.dialogsService.showSnackbar({
					icon: 'ErrorBadge',
					text: this.i18n.strings.email_notification_summaryStep_creationError,
					className: 'color-box-failureBackground',
				});
				this.setBackButtonEnabled(true);
				this.setStepValidation(true);
				this.saving$.next(false);
				return of(false);
			})
		);
	};

	ngOnInit(): void {
		this.setOnNext(this.createRule);
		this.setStepValidation(true);
	}
}
