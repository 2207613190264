import { EventEmitter, OnDestroy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { combineLatest, of } from 'rxjs';
import { mergeMap, tap } from 'rxjs/operators';
import { MachineGroup, WebContentFilteringPolicy, WebContentFilteringCategoryType, } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { MachinesService } from '../../machines/services/machines.service';
import { WebContentFilteringPolicyService, ScopeTypesEnum, } from '../services/web-content-filtering-policy.service';
import { SpinnerSize } from 'office-ui-fabric-react';
import { AppFlavorConfig } from '@wcd/scc-common';
var Tabs;
(function (Tabs) {
    Tabs["details"] = "details";
    Tabs["action"] = "action";
    Tabs["groups"] = "groups";
    Tabs["review"] = "review";
})(Tabs || (Tabs = {}));
var WebContentFilteringPolicyNewComponent = /** @class */ (function () {
    function WebContentFilteringPolicyNewComponent(paris, i18nService, authService, featuresService, flavorService, machinesService, dialogsService, changeDetectorRef, policyService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.authService = authService;
        this.featuresService = featuresService;
        this.flavorService = flavorService;
        this.machinesService = machinesService;
        this.dialogsService = dialogsService;
        this.changeDetectorRef = changeDetectorRef;
        this.policyService = policyService;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.Tabs = Tabs;
        this.ScopeTypesEnum = ScopeTypesEnum;
        this.SpinnerSize = SpinnerSize;
        this.isScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.webContentFiltering);
        this.tabs = [
            {
                id: Tabs.details,
                name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.details.title'),
            },
            {
                id: Tabs.action,
                name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.action.title'),
                disabled: true,
            }
        ].concat((this.isScopeVisible
            ? [
                {
                    id: Tabs.groups,
                    name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.scope.title'),
                    disabled: true,
                },
            ]
            : []), [
            {
                id: Tabs.review,
                name: this.i18nService.get('webContentFilteringPolicy.sidePane.tabs.summary.title'),
                disabled: true,
            },
        ]).map(function (tab) { return new TabModel(tab); });
        this.currentTabIndex = 0;
        this.currentTab = this.tabs[this.currentTabIndex];
        this.isSaving = false;
        this.loadingMachineGroups = true;
        this.allowSpecificMachineGroups = false; // True if #available machine groups > 0
        this.selectedMachineGroups = []; // Selected machine groups
        this.availableMachineGroups = []; // User accesible machine groups
        this._valuesLabelTexts = new Map();
        this.currentBlockedCategories = [];
        this.supportedCategoryIds = [];
        this.onMachineGroupChange = function (newOption) {
            _this.allowSpecificMachineGroups =
                newOption === _this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
            if (!_this.allowSpecificMachineGroups) {
                _this._setSelectedMachineGroups((_this.policy.rbacGroupIds = _this.selectedMachineGroups = []));
            }
        };
        this.onNavButtonPressed = function (isNext) {
            if (isNext) {
                _this.currentTabIndex++;
                while (!_this.tabs[_this.currentTabIndex].isVisible && _this.currentTabIndex < _this.tabs.length) {
                    _this.currentTabIndex++;
                }
                _this.tabs[_this.currentTabIndex].disabled = false;
            }
            else {
                _this.currentTab.disabled = true;
                _this.currentTabIndex--;
                while (!_this.tabs[_this.currentTabIndex].isVisible && _this.currentTabIndex >= 0) {
                    _this.currentTabIndex--;
                }
            }
            _this.currentTab = _this.tabs[_this.currentTabIndex];
        };
        this.onTabChanged = function (newTab) {
            _this.currentTab.disabled = true;
            _this.currentTab = newTab;
            _this.currentTab.disabled = false;
            _this.currentTabIndex = _this.tabs.findIndex(function (tabModel) { return tabModel.id === _this.currentTab.id; });
        };
        this.savePolicy = function () {
            _this.isSaving = true;
            _this._savePolicySubscription = _this._policyRepo.save(_this.policy).subscribe(function () {
                _this.isSaving = false;
                _this.save.emit(_this.policy);
            }, function (error) {
                _this.isSaving = false;
                _this.dialogsService
                    .showError({
                    title: _this.i18nService.get('webContentFilteringPolicy.dataview.actions.new.errorMessage'),
                    data: error,
                })
                    .then(function () { return _this.changeDetectorRef.markForCheck(); });
            });
        };
        this._machineGroupsRepo = paris.getRepository(MachineGroup);
        this._policyRepo = paris.getRepository(WebContentFilteringPolicy);
        this._rbacMachineGroupsEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroups);
    }
    Object.defineProperty(WebContentFilteringPolicyNewComponent.prototype, "machineGroupsFieldId", {
        get: function () {
            return 'web-content-filtering-policy-machine-groups';
        },
        enumerable: true,
        configurable: true
    });
    WebContentFilteringPolicyNewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.policy = this._policyRepo.createNewItem();
        this.selectableMachineGroupScopes = this.policyService.getSelectableMachineGroupScopes();
        this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
        var machineGroupsObservable = this._rbacMachineGroupsEnabled
            ? this.setMachineGroups()
            : of(null);
        this._machineGroupSubscription = machineGroupsObservable.subscribe(function () {
            _this.loadingMachineGroups = false;
            if (_this._rbacMachineGroupsEnabled) {
                _this._setSelectedMachineGroups(_this.policy.rbacGroupIds);
            }
            else {
                _this._setTabVisibility(Tabs.groups, false);
            }
        }, function (error) {
            _this.loadingMachineGroups = false;
        });
        this._categoryList = this.paris
            .getRepository(WebContentFilteringCategoryType)
            .entity.values.filter(function (c) { return _this.policyService.filterCategory(c); });
        this.categoryChecklistConfig = this.policyService.getCategoryChecklistConfig();
        this.supportedCategoryIds = this._categoryList.map(function (category) { return category.id; });
        this.policy.auditCategoryIds = this.supportedCategoryIds;
    };
    WebContentFilteringPolicyNewComponent.prototype.ngOnDestroy = function () {
        this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
        this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
        this._savePolicySubscription && this._savePolicySubscription.unsubscribe();
    };
    WebContentFilteringPolicyNewComponent.prototype.onBlockedCategoriesChange = function (selectedIds) {
        var _this = this;
        this.policy.blockedCategoryIds = selectedIds;
        this.policy.auditCategoryIds = this.supportedCategoryIds.filter(function (id) { return !selectedIds.includes(id); });
        this.currentBlockedCategories = this._categoryList
            .filter(function (category) {
            return selectedIds.includes(category.id);
        })
            .map(function (category) {
            return _this.i18nService.get('webCategories.categories.' + category.name);
        });
    };
    WebContentFilteringPolicyNewComponent.prototype.getLabelText = function (fieldId) {
        return (this._valuesLabelTexts.get(fieldId) ||
            this.i18nService.get('webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues'));
    };
    WebContentFilteringPolicyNewComponent.prototype.setLabelText = function (fieldId, values) {
        var labelText = this._setLabelText(values), currentValue = this._valuesLabelTexts.get(fieldId);
        if (!currentValue || currentValue !== labelText)
            this._valuesLabelTexts.set(fieldId, labelText);
    };
    WebContentFilteringPolicyNewComponent.prototype.setMachineGroups = function () {
        var _this = this;
        return this._machineGroupsRepo.allItems$.pipe(tap(function (groups) {
            _this._allMachineGroups = groups;
            _this._setAvailableScopes();
        }), mergeMap(function () { return _this._getUserExposedRbacGroups(); }));
    };
    WebContentFilteringPolicyNewComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!selectedMachineGroups || !selectedMachineGroups.length)
            this._setSelectedMachineGroups((this.policy.rbacGroupIds = []));
        else {
            this._onMachineGroupsChangedSubscription = combineLatest(selectedMachineGroups.map(function (selectedGroup) {
                return _this._machineGroupsRepo.getItemById(selectedGroup.id);
            })).subscribe(function (machineGroups) {
                var machineGroupIds = machineGroups.map(function (group) { return group.id; });
                _this._setSelectedMachineGroups((_this.policy.rbacGroupIds = machineGroupIds));
            });
        }
    };
    WebContentFilteringPolicyNewComponent.prototype.isValid = function () {
        switch (this.currentTab.id) {
            case Tabs.details: {
                return this.isPolicyNameValid();
            }
            case Tabs.action: {
                return true;
            }
            case Tabs.groups: {
                return this.isGroupsValid();
            }
            case Tabs.review: {
                return this.isPolicyNameValid() && this.isGroupsValid();
            }
            default:
                return false;
        }
    };
    WebContentFilteringPolicyNewComponent.prototype.isPolicyNameValid = function () {
        return !!this.policy.policyName;
    };
    WebContentFilteringPolicyNewComponent.prototype.isGroupsValid = function () {
        return (!this.loadingMachineGroups &&
            ((this.selectedMachineGroupScope && this.selectedMachineGroupScope.id === ScopeTypesEnum.All) ||
                !!this.policy.rbacGroupIds.length));
    };
    WebContentFilteringPolicyNewComponent.prototype._setSelectedMachineGroups = function (machineGroupIds) {
        var _this = this;
        this.selectedMachineGroups = this._allMachineGroups
            .filter(function (machineGroup) { return machineGroupIds.includes(machineGroup.id); })
            .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        this.setLabelText(this.machineGroupsFieldId, this.selectedMachineGroups);
    };
    WebContentFilteringPolicyNewComponent.prototype._setLabelText = function (values) {
        if (values.length > 3)
            return values.length + " values";
        var _values = values.map(function (value) { return value.name; });
        if (_values.length)
            return _values.join(', ');
        return this.i18nService.get("webContentFilteringPolicy.sidePane.sections.organizationalscope.machinegroups.selectValues");
    };
    WebContentFilteringPolicyNewComponent.prototype._setAvailableScopes = function () {
        if (this._allMachineGroups.length) {
            this.allowSpecificMachineGroups = true;
            if (this.authService.currentUser.isMdeAdmin) {
                this.allowAllMachineGroups = true;
            }
            else {
                this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.Specific];
            }
        }
        else {
            this.selectedMachineGroupScope = this.selectableMachineGroupScopes[ScopeTypesEnum.All];
        }
    };
    WebContentFilteringPolicyNewComponent.prototype._getUserExposedRbacGroups = function () {
        var _this = this;
        return this.machinesService.getFullUserExposedMachineGroups().pipe(tap(function (userExposedMachineGroups) {
            _this.availableMachineGroups = _this._allMachineGroups
                .filter(function (machineGroup) {
                return (userExposedMachineGroups &&
                    userExposedMachineGroups.some(function (group) { return group.id === machineGroup.id; }));
            })
                .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        }));
    };
    WebContentFilteringPolicyNewComponent.prototype._getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18nService.get('machineGroup.unassignedGroup.name')
                : machineGroup.name,
        };
    };
    WebContentFilteringPolicyNewComponent.prototype._setTabVisibility = function (tabId, showTab) {
        var machineGroupTab = this.tabs.find(function (tab) { return tab.id == tabId; });
        machineGroupTab.show = function () { return showTab; };
    };
    return WebContentFilteringPolicyNewComponent;
}());
export { WebContentFilteringPolicyNewComponent };
