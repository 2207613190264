<div class="wcd-flex wcd-flex-align-items-center wcd-font-weight-semibold" style="height: 33px;">
	<ng-container *ngIf="_productNeverMatched && assetsCount === 0; else supported">
		<div class="wcd-flex-center-vertical">
			-
			<span class="wcd-margin-xsmall-left color-text-neutralPrimaryAlt">
				/ {{ totalAssetCount | prettyNumber }}
				<wcd-help [text]="productNeverMatchedTooltip"></wcd-help>
			</span>
		</div>
	</ng-container>

	<ng-template #supported>
		{{ assetsCount | prettyNumber }}
		<span class="wcd-margin-xsmall-left color-text-neutralPrimaryAlt">
			/ {{ totalAssetCount | prettyNumber }}
		</span>
		<wcd-area-chart *ngIf="newChartOptions" style="margin-left: auto;" class="wcd-margin-xsmall-top"
			[options]="newChartOptions">
		</wcd-area-chart>
	</ng-template>
</div>
