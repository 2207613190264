import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { HuntingTableColumnSchema } from './hunting-table-column-schema.value-object';

export enum HuntingTableSectionName {
	Alerts,
	Devices,
	Itp,
	Emails,
	Tvm,
}

@Entity({
	singularName: 'Hunting table schema',
	pluralName: 'Hunting tables schemas',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	endpoint: 'hunting/database/schema',
	idProperty: 'Name',
})
export class HuntingTableSchema extends EntityModelBase<string> {
	@EntityField({ data: 'Name' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({ data: 'Name' })
	readonly name: string;

	@EntityField({ data: 'IsBeta' })
	readonly isBeta: boolean;

	@EntityField({
		data: 'Schema',
		arrayOf: HuntingTableColumnSchema,
	})
	readonly schema: Array<HuntingTableColumnSchema>;

	@EntityField({ data: 'TableSection' })
	readonly tableSectionName: HuntingTableSectionName;
}
