import {
	Entity,
	EntityModelBase,
	EntityField,
	DataEntityType,
	ValueObject,
	ModelBase,
} from '@microsoft/paris';
import { StatusModel } from '../../status.model';
import { Machine } from '../../machine/machine.entity';
import { File } from '../../file/file.entity';
import { HuntingRuleCustomSupportedActionType } from '../hunting-rule-custom-action.entity';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
import { AadUser } from '../../AAD/user/aad.user.entity';
import { MailMessage } from '../../mailbox/mail-message.value-object';
import { Mailbox } from '../../mailbox/mailbox.entity';
import { Url } from '../../url/url.entity';

export enum HuntingCustomActionStatusCode {
	Submitted,
	Running,
	Completed,
	Failed,
	Skipped,
}

@Entity({
	singularName: 'Custom action status',
	pluralName: 'Custom action statuses',
	values: [
		{
			id: HuntingCustomActionStatusCode.Submitted,
			name: '',
			type: 'submitted',
			nameI18nKey: 'statuses_submitted',
			iconName: 'CompletedSolid',
			className: 'success',
		},
		{
			id: HuntingCustomActionStatusCode.Running,
			name: '',
			type: 'running',
			nameI18nKey: 'statuses_running',
			image: '/assets/images/circle-loading.gif',
			className: 'primary',
		},
		{
			id: HuntingCustomActionStatusCode.Completed,
			name: '',
			type: 'completed',
			nameI18nKey: 'statuses_completed',
			iconName: 'CompletedSolid',
			className: 'success',
		},
		{
			id: HuntingCustomActionStatusCode.Failed,
			name: '',
			type: 'failure',
			nameI18nKey: 'statuses_failed',
			iconName: 'Error',
			className: 'error',
		},
		{
			id: HuntingCustomActionStatusCode.Skipped,
			name: '',
			type: 'skipped',
			nameI18nKey: 'statuses_skipped',
			iconName: 'Error',
			className: 'primary',
		},
	],
})
export class CustomActionRunStatus extends StatusModel<number> { }

@Entity({
	singularName: 'Custom action entity type',
	pluralName: 'Custom action entity types',
	values: [
		{ id: HuntingRuleEntityType.Machine, type: Machine },
		{ id: HuntingRuleEntityType.File, type: File },
		{ id: HuntingRuleEntityType.User, type: AadUser },
		{ id: HuntingRuleEntityType.Mailbox, type: Mailbox },
		{ id: HuntingRuleEntityType.MailMessage, type: MailMessage },
		{ id: HuntingRuleEntityType.Url, type: Url },
	],
})
export class CustomActionEntityType extends EntityModelBase {
	@EntityField()
	type: DataEntityType;
}

export enum HuntingCustomActionEntityRole {
	ImpactedAsset = 'ImpactedAsset',
	TargetEntity = 'TargetEntity',
}

@ValueObject({
	singularName: 'Hunting custom action entity',
	pluralName: 'Hunting custom action entities',
})
export class HuntingCustomActionEntity extends ModelBase {
	@EntityField({ data: 'EntityId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'EntityName' })
	name?: string;

	@EntityField({ data: 'EntityType' })
	entityType: CustomActionEntityType;

	get role(): HuntingCustomActionEntityRole {
		return this.entityType.id === HuntingRuleEntityType.File ||
			this.entityType.id === HuntingRuleEntityType.MailMessage
			? HuntingCustomActionEntityRole.TargetEntity
			: HuntingCustomActionEntityRole.ImpactedAsset;
	}
}

@ValueObject({
	singularName: 'Hunting custom action result',
	pluralName: 'Hunting custom action results',
	readonly: true,
})
export class HuntingCustomActionResult extends ModelBase {
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;

	@EntityField({ data: 'ActionType' })
	actionType: HuntingRuleCustomSupportedActionType;

	private _entities: Array<HuntingCustomActionEntity>;
	@EntityField({
		data: '__self',
		parse: ({ Entities, EntityId, EntityName, EntityType }) =>
			Entities && Entities.length ? Entities : [{ EntityId, EntityName, EntityType }],
		arrayOf: HuntingCustomActionEntity,
		defaultValue: [],
	})
	set entities(value: Array<HuntingCustomActionEntity>) {
		this._entities = value || [];
		this._impactedAsset = this._entities.find(
			e => e.role === HuntingCustomActionEntityRole.ImpactedAsset
		);
	}

	get entities(): Array<HuntingCustomActionEntity> {
		return this._entities;
	}

	private _impactedAsset: HuntingCustomActionEntity;
	get impactedAsset(): HuntingCustomActionEntity {
		return this._impactedAsset;
	}

	@EntityField({ data: 'StartTime' })
	startTime: Date;

	@EntityField({ data: 'Status' })
	status: CustomActionRunStatus;

	@EntityField({ data: 'StatusDetails' })
	failureReason: string;

	@EntityField({ data: 'AsyncActionId' })
	asyncActionId: number;
}

@ValueObject({
	singularName: 'Custom action run',
	pluralName: 'Custom action runs',
})
export class CustomActionRun extends HuntingCustomActionResult {
	@EntityField({ data: 'RuleId' })
	ruleId: number;

	@EntityField({ data: 'CustomActionRunId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number;
}
