import {
	DataQuery,
	DataQuerySortDirection,
	Entity,
	EntityField,
	EntityModelBase,
	queryToHttpOptions,
} from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { LegacyUserProfile } from '../legacy/user/legacy-user-profile.entity';
import { PendingAction } from '../remediation/pending_actions/pending-action.entity';
import { RemediationActionTypeActionCount } from '../remediation/remediation-action-type-action-count.value-object';
import { Tag } from '../tag/tag.entity';
import { ThreatTypeCount } from '../threats/threat-type-count.value-object';
import { InvestigationActionSummary } from './actions/investigation-action-summary.value-object';
import { InvestigatedMachine } from './investigated-machine.entity';
import { InvestigationActionsSummary } from './investigation-actions-summary.value-object';
import { InvestigationPendingType } from './investigation-pending-type.entity';
import { InvestigationStatus } from './investigation-status.entity';
import { sevilleInvestigationStatusToDisplayStatus } from './investigation-status.values';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { InvestigatedUser } from './investigated-identity.value-object';
import { ServiceSource } from '../alert/sources/service-source.entity';

@Entity({
	singularName: 'Investigation',
	pluralName: 'Investigations',
	endpoint: (_, query: DataQuery) => {
		const api = 'investigations';
		if (query && query.where && query.where['useSevilleApi']) {
			return `${api}/list`;
		}
		return api;
	},
	allItemsProperty: 'results',
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery): string => {
		if (query && query.where && query.where['useSevilleApi']) {
			return config.data.serviceUrls.threatIntel;
		}

		return config.data.serviceUrls.automatedIr;
	},
	separateArrayParams: true,
	cache: {
		time: (investigation: Investigation) => (investigation.isRunning ? 2500 : 1000 * 60 * 2),
		max: 10,
	},
	parseDataQuery: (query: DataQuery) => {
		if (query && query.where && query.where['useSevilleApi']) {
			const params: { [index: string]: any } = {};
			if (query.pageSize) {
				params.pageSize = query.pageSize;
			}
			if (query.page) {
				params.pageIndex = query.page;
			}
			if (query.sortBy && query.sortBy.length) {
				params.sortByField = query.sortBy[0].field;
				params.sortOrder =
					query.sortBy[0].direction === DataQuerySortDirection.ascending
						? 'Ascending'
						: 'Descending';
			}
			Object.keys(query.where)
				.filter(key => !~['pageSize', 'page', 'sortBy', 'useSevilleApi'].indexOf(key))
				.forEach(key => {
					params[key] = query.where[key];
				});
			return params;
		}

		return queryToHttpOptions(query).params;
	},
})
export class Investigation extends EntityModelBase<number | string> {
	@EntityField({ data: 'end_date' })
	endDate: Date;

	@EntityField({ data: 'running_time' })
	runningTime: number;

	@EntityField({ data: 'start_date' })
	startDate: Date;

	@EntityField({ data: 'title' })
	title: string;

	@EntityField({ data: 'is_live_response' })
	isLiveResponse: boolean;

	@EntityField({
		parse: (fieldData, itemData, query) => {
			if (query && query.where && query.where['useSevilleApi']) {
				const displayStatus = sevilleInvestigationStatusToDisplayStatus[fieldData];
				return displayStatus && displayStatus.id;
			}
			return fieldData;
		},
	})
	status: InvestigationStatus;

	@EntityField({ data: 'requested_status' })
	requestedStatus: InvestigationStatus;

	@EntityField({ data: 'pending_type' })
	pendingType: InvestigationPendingType;

	@EntityField({
		data: 'pending_action_types.data',
		parse: (actionTypes: Array<{ action: number; count: number }>) => {
			return actionTypes && actionTypes.filter(type => type.count > 0);
		},
		arrayOf: RemediationActionTypeActionCount,
	})
	pendingActionTypes: Array<RemediationActionTypeActionCount>;

	@EntityField({ data: 'pending_action_types.is_remediation_disabled' })
	isRemediationDisabled: boolean;

	@EntityField({ data: 'pending_actions', arrayOf: PendingAction })
	pendingActions: Array<PendingAction>;

	get pendingUserActions(): Array<PendingAction> {
		return (this.pendingActions || []).filter(
			(pendingAction: PendingAction) => pendingAction.isUserPending
		);
	}
	get pendingResourceActions(): Array<PendingAction> {
		return (this.pendingActions || []).filter(
			(pendingAction: PendingAction) => !pendingAction.isUserPending
		);
	}

	@EntityField({
		data: 'user',
		parse: user => {
			return user
				? typeof user === 'string'
					? { DisplayName: user }
					: {
							DisplayName:
								user.first_name && user.last_name
									? `${user.first_name} ${user.last_name}`
									: user.username,
							Email: user.mail,
							Phone: user.mobile || user.phone,
							ThumbnailPhotoSrc: user.picture ? `data:image/jpeg;base64,${user.picture}` : null,
							Title: user.user_title,
							Department: user.department,
					  }
				: null;
		},
	})
	canceledByUser: LegacyUserProfile;

	@EntityField({
		data: 'hosts',
		arrayOf: InvestigatedMachine,
		parse: (hosts, rawData, query: DataQuery) => {
			if (hosts) {
				return hosts;
			}
			// Seville's api
			if (query && query.where && query.where['useSevilleApi'] && rawData.sense_machine_id) {
				return [
					{
						id: rawData.sense_machine_id,
						machine_id: rawData.sense_machine_id,
						name: rawData.computer_dns_name,
					},
				];
			}
		},
	})
	machines: Array<InvestigatedMachine>;

	@EntityField({ data: 'user_activities', arrayOf: InvestigatedUser })
	investigatedUsers: Array<InvestigatedUser>;

	@EntityField({ data: 'pending_since' })
	pendingSince: Date;

	@EntityField({
		data: 'actions',
		parse: (value, rawData) => {
			return (
				value || {
					total: rawData.total_action_count || rawData.total_actions || 0,
					remediated: rawData.remediation_actions
						? rawData.remediation_actions.length
						: rawData.remediation_action_count || 0,
				}
			);
		},
	})
	actionsSummary: InvestigationActionsSummary;

	@EntityField({ data: 'is_cleanup_applied' })
	dataRetentionApplied: boolean;

	@EntityField() reasons: Array<string>;

	@EntityField({ data: 'affected_groups', require: 'affected_users' })
	groupNames: Array<string>;

	@EntityField({ data: 'tags', arrayOf: Tag })
	tags: Array<Tag>;

	@EntityField({ data: 'last_action' })
	lastAction: InvestigationActionSummary;

	@EntityField({ data: 'error_description' })
	errorDescription: string;

	@EntityField({ data: 'attention_required', defaultValue: 0 })
	attentionRequiredCount: number;

	@EntityField({
		data: 'threat_types',
		parse: data =>
			data &&
			data.map(threatType =>
				typeof threatType === 'number' ? { threat_type: threatType } : threatType
			),
		arrayOf: ThreatTypeCount,
	})
	threatTypes: Array<ThreatTypeCount>;

	@EntityField({ data: 'names' })
	alertRuleNames: Array<string>;

	@EntityField({ data: 'alert_count' })
	totalAlertsCount: number;

	@EntityField({
		require: 'alert',
	})
	alert: Alert;

	@EntityField({ data: 'alert_to_remediation_time' })
	alertToRemediationTime: number;

	@EntityField({ data: 'remediation_pending_time' })
	remediationPendingTime: number;

	@EntityField({ data: 'resource_pending_time' })
	resourcePendingTime: number;

	@EntityField({ data: 'comments' })
	commentCount: number;

	@EntityField({ data: 'entity_count' })
	entityCount: number;

	@EntityField({ data: 'host_groups' })
	hostGroups: Array<string>;

	@EntityField({ data: 'InvestigationPageUrl', required: false })
	externalInvestigationPageUrl?: string;

	@EntityField({ data: 'InvestigationPageUrl', parse: pageUrl => !!pageUrl })
	isOfficeInvestigation: boolean;

	@EntityField({
		data: 'service_source',
	})
	serviceSource: ServiceSource;

	doesInvestigationExist: boolean;

	@EntityField({
		data: 'detection_system',
		parse: (fieldData, _, query) => {
			if (query && query.where && query.where['useSevilleApi']) {
				return fieldData;
			}
			return null;
		},
	})
	protected _detectionSourceName: string;

	get totalThreatCount(): number {
		return this.threatTypes.reduce((total: number, threatTypeCount) => {
			return total + threatTypeCount.count;
		}, 0);
	}

	get duration(): number {
		let runningTime = this.runningTime;

		if (runningTime === null || runningTime === undefined)
			runningTime = ((this.endDate || new Date()).valueOf() - this.startDate.valueOf()) / 1000;

		return runningTime;
	}

	get isPending(): boolean {
		return this.status.isPending && this.pendingType !== null;
	}

	get isPendingUser(): boolean {
		return this.pendingType && this.pendingType.isUserPending;
	}

	get isPendingSystem(): boolean {
		return this.pendingType && !this.pendingType.isUserPending;
	}

	get wasPending(): boolean {
		return !this.isRunning && this.pendingActions && this.pendingActions.length > 0;
	}

	get wasPendingUser(): boolean {
		return this.wasPending && this.pendingActions.some(pendingAction => pendingAction.isUserPending);
	}

	get wasPendingResource(): boolean {
		return this.wasPending && this.pendingActions.some(pendingAction => !pendingAction.isUserPending);
	}

	get isStopping(): boolean {
		return (this.requestedStatus && this.requestedStatus.isTerminated) || false;
	}

	get isRunning(): boolean {
		return this.status && this.status.isRunning;
	}

	get isTerminated(): boolean {
		return this.status.isTerminated;
	}

	get hasPendingActions(): boolean {
		return this.actionsSummary.pending > 0;
	}

	get isError(): boolean {
		return this.status.isError;
	}

	get pendingTime(): number {
		if (!this.pendingSince) return null;

		return (new Date().valueOf() - this.pendingSince.valueOf()) / 1000;
	}

	get correlatedAlertsCount(): number {
		return this.totalAlertsCount && this.totalAlertsCount > 1 ? this.totalAlertsCount - 1 : 0;
	}

	get detectionSourceName(): string {
		return this._detectionSourceName;
	}

	constructor(data) {
		super(data);
		if (this.doesInvestigationExist === undefined) {
			this.doesInvestigationExist = true;
		}
	}
}
