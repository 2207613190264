import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

const IDENTITY_FUNCTION = (x: any) => x;
const EMPTY_FUNCTION = (x: any) => '';

@Component({
	selector: 'wcd-sync-datatable-field',
	template: `
		<span [ngClass]="getCssClassFunc(value$ | async)">
			<wcd-shared-icon
				*ngIf="getIconNameFunc && getIconNameFunc(value$ | async)"
				[ngClass]="getIconCssClassFunc(value$ | async)"
				[iconName]="getIconNameFunc(value$ | async)"
			>
			</wcd-shared-icon>
			{{ getTextFunc(value$ | async) }}
		</span>
	`,
})
export class AsyncDataTableFieldComponent {
	@Input() value$: Observable<any>;

	@Input() getTextFunc: (data: any) => string = IDENTITY_FUNCTION;
	@Input() getCssClassFunc: (data: any) => string = EMPTY_FUNCTION;
	@Input() getIconNameFunc: (data: any) => string;
	@Input() getIconCssClassFunc: (data: any) => string = EMPTY_FUNCTION;
}
