import { NgModule, InjectionToken, ModuleWithProviders } from '@angular/core';
import { RouteLinkComponent } from './components/route-link.component';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { WcdIconsModule } from '@wcd/icons';
import { RoutesService } from './services/routes.service';
import { MessageBarComponent } from './components/message-bar.component';
import { I18nModule } from '@wcd/i18n';
import { TooltipsModule } from '@wcd/dialogs';
import { MarkdownModule } from 'ngx-markdown';
import { BasePathPipe } from './pipes/basePath.pipe';
import { InfoBoxComponent } from './components/info-box.component';
import { FabIconModule, FabSpinnerModule } from '@angular-react/fabric';
import { AccessibleSpinnerComponent } from './components/accessible-spinner';
import { A11yModule } from '@angular/cdk/a11y';

export const SccRoutesService = new InjectionToken<string[]>('sccRoutes');

const components = [MessageBarComponent, RouteLinkComponent, InfoBoxComponent, AccessibleSpinnerComponent];
const pipes = [BasePathPipe];
const all = [...components, ...pipes];

@NgModule({
	declarations: all,
	imports: [
		RouterModule,
		CommonModule,
		WcdIconsModule,
		I18nModule,
		TooltipsModule,
		MarkdownModule,
		FabIconModule,
		FabSpinnerModule,
		A11yModule,
	],
	exports: all,
	entryComponents: components,
})
export class WcdSharedModule {
	static forRoot(sccRoutes: string[]): ModuleWithProviders {
		return {
			ngModule: WcdSharedModule,
			providers: [RoutesService, { provide: SccRoutesService, useValue: sccRoutes }],
		};
	}
}
