export const registryOperationValues = [
	{
		id: 'DeleteValue',
		name: 'Value deleted',
		operation: 'deleted registry value',
	},
	{
		id: 'DeleteKey',
		name: 'Key deleted',
		operation: 'deleted registry key',
	},
	{
		id: 'CreateKey',
		name: 'Key created',
		operation: 'created registry key',
	},
	{
		id: 'RenameKey',
		name: 'Key renamed',
		operation: 'renamed registry key',
	},
	{
		id: 'SetValue',
		name: 'Value set',
		operation: 'set registry value',
	},
];
