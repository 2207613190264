var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { MachineGroup } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { MachineGroupFields } from '../../services/machine-group.fields';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { MachineGroupRemediationLevelEditPanelComponent } from './machine-group-remediation-level-edit.panel.component';
import { map, take } from 'rxjs/operators';
import { MachinesService } from '../../../machines/services/machines.service';
import { FabricIconNames } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../../insights/services/performance.scc.service';
var MachineGroupsRemediationLevelDataviewComponent = /** @class */ (function () {
    function MachineGroupsRemediationLevelDataviewComponent(paris, machineGroupFields, dialogsService, machinesService, performanceSccService) {
        var _this = this;
        this.paris = paris;
        this.machineGroupFields = machineGroupFields;
        this.dialogsService = dialogsService;
        this.machinesService = machinesService;
        this.performanceSccService = performanceSccService;
        this.isScc = sccHostService.isSCC;
        var repository = paris.getRepository(MachineGroup);
        this.itemName = repository.entity.singularName;
        this.dataViewConfig = {
            id: "unappliedMachineGroups",
            fixedOptions: {
                addAadGroupNames: 'true',
                addMachineGroupCount: 'true',
            },
            loadResults: function (options) {
                return _this.machinesService
                    .getFullUserExposedMachineGroups(__assign({}, options, { where: {
                        addAadGroupNames: 'true',
                        addMachineGroupCount: 'true',
                    } }))
                    .pipe(map(function (groups) { return ({ items: groups, count: groups.length }); }));
            },
        };
    }
    MachineGroupsRemediationLevelDataviewComponent.prototype.openEditPanel = function (machineGroup) {
        var _this = this;
        this.dialogsService
            .showPanel(MachineGroupRemediationLevelEditPanelComponent, {
            id: 'machine-group-edit-panel',
            type: PanelType.large,
            isModal: true,
            showOverlay: true,
            isBlocking: true,
            headerIcon: FabricIconNames.System,
            noBodyPadding: true,
            hasCloseButton: false,
        }, {
            machineGroup: machineGroup,
            onDone: function () {
                _this.lastUpdate = new Date();
            },
        })
            .pipe(take(1))
            .subscribe();
    };
    MachineGroupsRemediationLevelDataviewComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('endpoints-auto-remediation-levels');
    };
    return MachineGroupsRemediationLevelDataviewComponent;
}());
export { MachineGroupsRemediationLevelDataviewComponent };
