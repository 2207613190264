// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { NetworkDevice, NetworkDeviceRelatedVulnerabilitiesRelationship, NetworkDeviceRelatedSecurityRecommendationsRelationship, TvmEndPoint, } from '@wcd/domain';
import { RelationshipRepository, Paris } from '@microsoft/paris';
import { MessageBarType } from 'office-ui-fabric-react';
import { take, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { I18nService } from '@wcd/i18n';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { SecurityRecommendationFieldsService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.fields.service';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
var NetworkDeviceEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(NetworkDeviceEntityDetailsComponent, _super);
    function NetworkDeviceEntityDetailsComponent(injector, paris, router, tvmDownloadService, i18nService, sidePanelService, securityRecommendationFieldsService, tvmTextsService) {
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.router = router;
        _this.tvmDownloadService = tvmDownloadService;
        _this.i18nService = i18nService;
        _this.sidePanelService = sidePanelService;
        _this.securityRecommendationFieldsService = securityRecommendationFieldsService;
        _this.tvmTextsService = tvmTextsService;
        _this.MillisecondsToDays = 86400000;
        _this.MaxDays = 21;
        _this.DaysConditionToShowMessage = 4;
        _this.MessageBarType = MessageBarType;
        _this.vulnerabilitiesRelationshipRepository = _this.paris.getRelationshipRepository(NetworkDeviceRelatedVulnerabilitiesRelationship);
        _this.securityRecommendationsRelationshipRepository = _this.paris.getRelationshipRepository(NetworkDeviceRelatedSecurityRecommendationsRelationship);
        return _this;
    }
    Object.defineProperty(NetworkDeviceEntityDetailsComponent.prototype, "device", {
        get: function () {
            return this._device;
        },
        set: function (device) {
            var _this = this;
            this._device = device;
            this.vulnerabilitiesRelationshipRepository.sourceItem = device;
            this.securityRecommendationsRelationshipRepository.sourceItem = device;
            this.securityRecommendationsRelationshipRepository
                .queryForItem(this._device)
                .pipe(take(1), map(function (result) { return (result.items[0] ? result.items[0] : null); }))
                .subscribe(function (recommendation) {
                if (recommendation) {
                    _this.recommendationTitle = _this.securityRecommendationFieldsService.getTitleFieldsParams(recommendation).title;
                    _this.recommendationDescription = _this.tvmTextsService.getText(TextToken.SecurityRecommendationDescription, { recommendation: recommendation });
                    _this.productId = recommendation.productId;
                }
            });
            this.calcDays();
        },
        enumerable: true,
        configurable: true
    });
    NetworkDeviceEntityDetailsComponent.prototype.navigateToRecommendation = function () {
        this.router.navigate(['/security-recommendations', "va-_-" + this.productId], {
            queryParams: {
                search: this.productId,
            },
        });
    };
    NetworkDeviceEntityDetailsComponent.prototype.exportCves = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.vulnerabilitiesRelationshipRepository, TvmEndPoint.Analytics, this._device.ipAddress + " - " + this.i18nService.get('tvm.common.relatedCVE.title'));
    };
    NetworkDeviceEntityDetailsComponent.prototype.openAllRelatedCves = function () {
        this.sidePanelService.showAllRelatedCvesPanel(this._device, false, true);
    };
    NetworkDeviceEntityDetailsComponent.prototype.calcDays = function () {
        var today = new Date();
        var diffMilliseconds = today.getTime() - this._device.lastScanned.getTime();
        this.diffDays = Math.round(diffMilliseconds / this.MillisecondsToDays);
        this.remainingDays = this.MaxDays - this.diffDays;
        this.shouldShowMessage = this.diffDays > this.DaysConditionToShowMessage && this.remainingDays >= 0;
    };
    return NetworkDeviceEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { NetworkDeviceEntityDetailsComponent };
