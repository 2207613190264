import { Input, OnDestroy, OnInit } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PanelSettings } from './panel-settings.model';
import { filter } from 'rxjs/operators';

export class PanelContainer implements OnInit, OnDestroy {
	@Input() settings: PanelSettings;

	destroy: () => void;
	startClose: () => void;
	private _onNavigateSubscription: Subscription;

	constructor(protected router: Router) {}

	ngOnInit() {
		if (!this.settings || !this.settings.persistOnNavigate) {
			this._onNavigateSubscription = this.router.events
				.pipe(filter((event: Event) => event instanceof NavigationEnd))
				.subscribe(() => this.destroy());
		}
	}

	ngOnDestroy() {
		this._onNavigateSubscription && this._onNavigateSubscription.unsubscribe();
	}

	closePanel(): void {
		this.startClose();
		this.destroy();
	}
}
