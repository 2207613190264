import { SystemExclusionsDataviewComponent } from './components/system-exclusions.dataview.component';
import { Feature } from '@wcd/config';
import { HistorySafeRedirectComponent } from '../../shared/components/history-safe-redirect.component';
export var systemExclusionsTitle = 'exclusion_title';
export var systemExclusionsIcon = 'exclusion';
export function getSystemExclusionPageTitleKey(featuresService) {
    return featuresService.isEnabled(Feature.AclOffloadingPhase1) &&
        featuresService.isEnabled(Feature.AclOffloadingPhase2)
        ? 'process_memory_indicators'
        : systemExclusionsTitle;
}
var ɵ0 = {
    pageTitleKey: systemExclusionsTitle,
    getPageTitleKey: getSystemExclusionPageTitleKey,
    pageTitleIcon: systemExclusionsIcon,
    pageDescriptionKey: 'exclusion.description',
}, ɵ1 = {
    redirectTo: 'files'
};
export var systemExclusionsRoutes = [
    {
        path: ':systemExclusionType',
        component: SystemExclusionsDataviewComponent,
        data: ɵ0,
    },
    {
        path: '',
        component: HistorySafeRedirectComponent,
        data: ɵ1,
        pathMatch: 'full',
    },
];
var SystemExclusionsModule = /** @class */ (function () {
    function SystemExclusionsModule() {
    }
    return SystemExclusionsModule;
}());
export { SystemExclusionsModule };
export { ɵ0, ɵ1 };
