import { Type } from '@angular/core';

import { WizardBaseStep } from '../components/base-wizard-step.component';

export interface WizardStepConfig<T> {
	id: number;
	name: string;
	component: Type<WizardBaseStep<T>>;
	componentInputs?: Map<string, any>;
	componentOutputs?: Map<string, Function>;
	verticalStepKeyName: string;
	onNext?: boolean;
	nextButtonText?: string;

	// Full width step
	wideStep?: boolean;
}

export interface CurrentWizardStepSettings {
	withBackButton: boolean;
	withNextButton: boolean;
	withDoneButton: boolean;
	withCloseButton: boolean;
	nextButtonText?: string;
	wideStep?: boolean;
}

export const StepStatus = {
	completed: "completed",
	current: "current",
	next: "next",
}
