import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { TvmOrgSettingsSharedEntityConfigurations } from '../orgSettingsEndPointUtils';
import { UserInfo } from '..//common/remediation/requester.value-object';
import { VulnerabilityNotificationRuleArgs } from './vulnerability-notification-rule-args.value-object';
import { MachineGroup } from '@wcd/domain';

@Entity({
	...TvmOrgSettingsSharedEntityConfigurations,
	singularName: 'TVM Notification Rule',
	pluralName: 'TVM Notification Rules',
	endpoint: 'vulnerability-notification-rules',
	parseItemQuery: id => {
		return `vulnerability-notification-rules/${id}`;
	},
})
export class VulnerabilityNotificationRule extends EntityModelBase<string> {
	@EntityField()
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField()
	readonly title: string;

	@EntityField()
	readonly eTag: string;

	@EntityField()
	readonly description: string;

	@EntityField({
		serialize: false,
	})
	readonly createdOn?: Date;

	@EntityField({
		serialize: false,
	})
	readonly lastModifiedOn?: Date;

	@EntityField()
	readonly createdBy?: UserInfo;

	@EntityField()
	readonly lastModifiedBy?: UserInfo;

	@EntityField()
	readonly isEnabled: boolean;

	@EntityField()
	readonly recipients: string[];

	@EntityField()
	readonly isGlobalNotification: boolean;

	@EntityField({
		data: 'rbacGroupIds',
		arrayOf: MachineGroup,
		serialize: (items: Array<MachineGroup>) => {
			return items.map(item => item.id);
		},
	})
	rbacGroupIds: Array<MachineGroup>;

	@EntityField()
	readonly includeOrgName: boolean;

	@EntityField()
	readonly notificationRuleArgsCollection: VulnerabilityNotificationRuleArgs[];
}
