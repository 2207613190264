var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { WidgetType } from '../reports/components/report-with-filters.component';
import { ReportsService } from './services/reports.service';
import { I18nService } from '@wcd/i18n';
import { BaseReportWidgetComponent } from '../reporting/base-reporting-widget';
import { ThreatDimensionType } from '@wcd/domain';
import { ChartType } from '../reporting/reporting.types';
import { ThreatProtectionReport2Service } from './services/threat-protection-report.service';
import { ThreatProtectionStatusMapping } from './threat-protection-status-mapping';
var ThreatProtectionDetectionSourceWidget = /** @class */ (function (_super) {
    __extends(ThreatProtectionDetectionSourceWidget, _super);
    function ThreatProtectionDetectionSourceWidget(reportsService, i18nService, threatProtectionSvc, threatProtectionStatusMapping) {
        var _this = _super.call(this, 'threatProtectionDetectionSource', 'reporting.alertsByDetectionSource.dailyTitleNew', reportsService, i18nService) || this;
        _this.threatProtectionSvc = threatProtectionSvc;
        _this.threatProtectionStatusMapping = threatProtectionStatusMapping;
        _this.widgetType = WidgetType.Daily;
        _this.dimensionType = ThreatDimensionType.AlertDetectionSourceType;
        return _this;
    }
    Object.defineProperty(ThreatProtectionDetectionSourceWidget.prototype, "dataFunc", {
        get: function () {
            return this.threatProtectionSvc.getAlertsByDimension.bind(this.threatProtectionSvc);
        },
        enumerable: true,
        configurable: true
    });
    ThreatProtectionDetectionSourceWidget.prototype.getSettings = function (data) {
        var _this = this;
        return this.threatProtectionSvc.getSettingsDaily(ChartType.Vertical, this.threatProtectionStatusMapping.detectionSourceMapping, data, function (detectionSource) { return _this.getDimensionFilterData(detectionSource); });
    };
    ThreatProtectionDetectionSourceWidget.prototype.getDimensionFilterData = function (detectionSource) {
        return {
            currentFilters: this.currentFilters,
            type: 'detectionSource',
            dimensionFilter: "detectionSource=" + encodeURIComponent(this.threatProtectionStatusMapping.detectionSourceMapping[detectionSource].alertListFilter),
        };
    };
    return ThreatProtectionDetectionSourceWidget;
}(BaseReportWidgetComponent));
export { ThreatProtectionDetectionSourceWidget };
