/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../global_entities/components/entity-details/remediation-task.entity-details.component.ngfactory";
import * as i2 from "../../../../../global_entities/components/entity-details/remediation-task.entity-details.component";
import * as i3 from "../../../../../global_entities/services/activated-entity.service";
import * as i4 from "../../../../../shared/pipes/calculate-remaining-days.pipe";
import * as i5 from "../../../../../tvm/pipes/remediation-task-display-remaining-days.pipe";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i8 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i9 from "../../../../../tvm/services/tvm-productivity-impact.service";
import * as i10 from "../../../../../tvm/services/tvm-texts.service";
import * as i11 from "../../../../../tvm/services/tvm-machine-groups-filter.service";
import * as i12 from "../../common-remediation-service/common-remediation.service";
import * as i13 from "../../../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i14 from "./remediation-task.entity-panel.component";
var styles_RemediationTaskEntityPanelComponent = [];
var RenderType_RemediationTaskEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_RemediationTaskEntityPanelComponent, data: {} });
export { RenderType_RemediationTaskEntityPanelComponent as RenderType_RemediationTaskEntityPanelComponent };
export function View_RemediationTaskEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "remediation-task-entity-details", [], null, null, null, i1.View_RemediationTaskEntityDetailsComponent_0, i1.RenderType_RemediationTaskEntityDetailsComponent)), i0.ɵdid(3, 245760, null, 0, i2.RemediationTaskEntityDetailsComponent, [i3.ActivatedEntity, i4.CalculateRemainingDaysPipe, i5.RemediationTaskDisplayRemainingDaysPipe, i6.Router, i7.FeaturesService, i0.Injector, i8.I18nService, i9.TvmProductivityImpactService, i10.TvmTextsService, i11.TvmMachineGroupsFilterService, i12.CommonRemediationService, i13.AppConfigService], { remediationTask: [0, "remediationTask"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.remediationTask; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_RemediationTaskEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "remediation-task-entity-panel", [], null, null, null, View_RemediationTaskEntityPanelComponent_0, RenderType_RemediationTaskEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i14.RemediationTaskEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RemediationTaskEntityPanelComponentNgFactory = i0.ɵccf("remediation-task-entity-panel", i14.RemediationTaskEntityPanelComponent, View_RemediationTaskEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { RemediationTaskEntityPanelComponentNgFactory as RemediationTaskEntityPanelComponentNgFactory };
