import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Registry } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';

@Component({
	selector: 'registry-entity-panel',
	templateUrl: './registry.entity-panel.component.html',
})
export class RegistryEntityPanelComponent extends EntityPanelComponentBase<Registry>
	implements OnDestroy, OnInit {
	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	get registry(): Registry {
		return this.entity;
	}
}
