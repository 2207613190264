import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Security update CVE',
	pluralName: 'Security update CVEs',
	readonly: true,
})
export class MachineSecurityKbToCve extends ModelBase {
	@EntityField({ data: 'CveId' })
	cveId: string;

	@EntityField({ data: 'CveDescription' })
	cveDescription: string;

	@EntityField({ data: 'KbId' })
	kbId: string;

	@EntityField({ data: 'KbFullName' })
	kbFullName: string;
}
