/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./file.reputation.widget.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../global_entities/components/entity-details/file-detections-summary.component.ngfactory";
import * as i3 from "../../../../../../global_entities/components/entity-details/file-detections-summary.component";
import * as i4 from "../../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../dialogs/services/dialogs.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "./malware.detection.widget";
import * as i10 from "../../../../../../shared-reports/services/reports.service";
import * as i11 from "../../../../../../global_entities/services/activated-entity.service";
import * as i12 from "../../../../../../../../../../projects/config/src/lib/services/features.service";
var styles_MalwareDetectionWidgetComponent = [i0.styles];
var RenderType_MalwareDetectionWidgetComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MalwareDetectionWidgetComponent, data: {} });
export { RenderType_MalwareDetectionWidgetComponent as RenderType_MalwareDetectionWidgetComponent };
function View_MalwareDetectionWidgetComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i1.ɵppd(4, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "file-detections-summary", [["class", "wcd-font-size-s color-text-gray-700 wcd-font-weight-bold"]], null, null, null, i2.View_FileDetectionsSummaryComponent_0, i2.RenderType_FileDetectionsSummaryComponent)), i1.ɵdid(7, 114688, null, 0, i3.FileDetectionsSummaryComponent, [i4.I18nService, i5.Paris, i6.DialogsService], { file: [0, "file"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_1 = _v.parent.parent.context.ngIf.file; _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v.parent.parent.parent, 0), "file.malwareDetection")); _ck(_v, 3, 0, currVal_0); }); }
function View_MalwareDetectionWidgetComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-m365-typography wcd-full-height"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MalwareDetectionWidgetComponent_3)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.parent.context.ngIf.showFileVerdict; _ck(_v, 5, 0, currVal_0); }, null); }
function View_MalwareDetectionWidgetComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "h4", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), i1.ɵppd(3, 1), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent, 0), "common.noDataAvailable")); _ck(_v, 2, 0, currVal_0); }); }
function View_MalwareDetectionWidgetComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MalwareDetectionWidgetComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(0, [["noData", 2]], null, 0, null, View_MalwareDetectionWidgetComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.showFileVerdict; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_MalwareDetectionWidgetComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i8.I18nPipe, [i4.I18nService]), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_MalwareDetectionWidgetComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i7.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_MalwareDetectionWidgetComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "malware-detection-widget", [], null, null, null, View_MalwareDetectionWidgetComponent_0, RenderType_MalwareDetectionWidgetComponent)), i1.ɵdid(1, 245760, null, 0, i9.MalwareDetectionWidgetComponent, [i10.ReportsService, i11.ActivatedEntity, i5.Paris, i4.I18nService, i12.FeaturesService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MalwareDetectionWidgetComponentNgFactory = i1.ɵccf("malware-detection-widget", i9.MalwareDetectionWidgetComponent, View_MalwareDetectionWidgetComponent_Host_0, {}, {}, []);
export { MalwareDetectionWidgetComponentNgFactory as MalwareDetectionWidgetComponentNgFactory };
