/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component.ngfactory";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/help.component";
import * as i3 from "@angular/platform-browser";
import * as i4 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i5 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i6 from "@angular/common";
import * as i7 from "./productivity-impact.component";
import * as i8 from "../../../tvm/services/tvm-texts.service";
var styles_TvmProductivityImpactComponent = [];
var RenderType_TvmProductivityImpactComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmProductivityImpactComponent, data: {} });
export { RenderType_TvmProductivityImpactComponent as RenderType_TvmProductivityImpactComponent };
function View_TvmProductivityImpactComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wcd-help", [], null, null, null, i1.View_HelpComponent_0, i1.RenderType_HelpComponent)), i0.ɵdid(1, 638976, null, 0, i2.HelpComponent, [i3.DomSanitizer], { text: [0, "text"] }, null), i0.ɵppd(2, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent, 0), "tvm.securityRecommendation.userImpact.unknownTooltipText")); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TvmProductivityImpactComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i4.I18nPipe, [i5.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t", "\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_TvmProductivityImpactComponent_1)), i0.ɵdid(5, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.isUserProductivityImpacted == null); _ck(_v, 5, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co._status; _ck(_v, 3, 0, currVal_0); }); }
export function View_TvmProductivityImpactComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tvm-productivity-impact", [], null, null, null, View_TvmProductivityImpactComponent_0, RenderType_TvmProductivityImpactComponent)), i0.ɵdid(1, 114688, null, 0, i7.TvmProductivityImpactComponent, [i8.TvmTextsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TvmProductivityImpactComponentNgFactory = i0.ɵccf("tvm-productivity-impact", i7.TvmProductivityImpactComponent, View_TvmProductivityImpactComponent_Host_0, { isUserProductivityImpacted: "isUserProductivityImpacted" }, {}, []);
export { TvmProductivityImpactComponentNgFactory as TvmProductivityImpactComponentNgFactory };
