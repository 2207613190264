/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./filters.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./filters-field.component.ngfactory";
import * as i3 from "./filters-field.component";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "../../../../i18n/src/lib/services/i18n.service";
import * as i6 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i7 from "@angular-react/fabric";
import * as i8 from "@angular/common";
import * as i9 from "./filters.component";
var styles_FiltersComponent = [i0.styles];
var RenderType_FiltersComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_FiltersComponent, data: {} });
export { RenderType_FiltersComponent as RenderType_FiltersComponent };
function View_FiltersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-filters-field", [], null, [[null, "filterChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("filterChange" === en)) {
        var pd_0 = (_co.onFieldChange($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_FiltersFieldComponent_0, i2.RenderType_FiltersFieldComponent)), i1.ɵdid(1, 770048, [[2, 4], [1, 4], ["focusable", 4]], 0, i3.FiltersFieldComponent, [i1.ComponentFactoryResolver, i1.ChangeDetectorRef, i4.LiveAnnouncer, i5.I18nService], { field: [0, "field"], data: [1, "data"], selectedValues: [2, "selectedValues"], fixedSelectedValues: [3, "fixedSelectedValues"] }, { filterChange: "filterChange" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.$implicit; var currVal_1 = _co.data[_v.context.$implicit.id]; var currVal_2 = ((_co.fieldSelectedValues == null) ? null : _co.fieldSelectedValues.get(_v.context.$implicit.id)); var currVal_3 = _co.fixedSelectedValues; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_FiltersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "fab-primary-button", [["className", "wcd-margin-xsmall-right"]], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.apply() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FabPrimaryButtonComponent_0, i6.RenderType_FabPrimaryButtonComponent)), i1.ɵdid(3, 6012928, null, 1, i7.FabPrimaryButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i1.NgZone], { className: [0, "className"], text: [1, "text"] }, { onClick: "onClick" }), i1.ɵqud(603979776, 3, { menuItemsDirectives: 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "fab-default-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FabDefaultButtonComponent_0, i6.RenderType_FabDefaultButtonComponent)), i1.ɵdid(7, 6012928, null, 1, i7.FabDefaultButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i1.NgZone], { text: [0, "text"] }, { onClick: "onClick" }), i1.ɵqud(603979776, 4, { menuItemsDirectives: 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-margin-xsmall-right"; var currVal_1 = _co.i18nService.strings.buttons_apply; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.i18nService.strings.buttons_cancel; _ck(_v, 7, 0, currVal_2); }, null); }
function View_FiltersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "fab-default-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.clearAllFilters() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i6.View_FabDefaultButtonComponent_0, i6.RenderType_FabDefaultButtonComponent)), i1.ɵdid(1, 6012928, null, 1, i7.FabDefaultButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i1.NgZone], { text: [0, "text"], iconProps: [1, "iconProps"] }, { onClick: "onClick" }), i1.ɵqud(603979776, 5, { menuItemsDirectives: 1 }), i1.ɵpod(3, { iconName: 0 }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.filters_clear; var currVal_1 = _ck(_v, 3, 0, "ClearFilter"); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_FiltersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FiltersComponent_5)), i1.ɵdid(2, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.fieldSelectedValues == null) ? null : _co.fieldSelectedValues.size); _ck(_v, 2, 0, currVal_0); }, null); }
function View_FiltersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-flex-none wcd-padding-horizontal wcd-padding-small-top wcd-padding-bottom wcd-border-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FiltersComponent_3)), i1.ɵdid(3, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(0, [["clearFilters", 2]], null, 0, null, View_FiltersComponent_4)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDirty; var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_FiltersComponent_0(_l) { return i1.ɵvid(2, [i1.ɵqud(671088640, 1, { focusable: 1 }), i1.ɵqud(671088640, 2, { fieldComponents: 1 }), (_l()(), i1.ɵeld(2, 0, null, null, 10, "section", [["aria-label", "Filters"], ["class", "wcd-filters wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "div", [["class", "wcd-flex-1 wcd-scroll-vertical wcd-padding-large-horizontal"]], [[1, "aria-hidden", 0]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FiltersComponent_1)), i1.ɵdid(7, 278528, null, 0, i8.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FiltersComponent_2)), i1.ɵdid(11, 16384, null, 0, i8.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.fieldsWithData; _ck(_v, 7, 0, currVal_1); var currVal_2 = (_co.isDirty || ((_co.fieldSelectedValues == null) ? null : _co.fieldSelectedValues.size)); _ck(_v, 11, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checkFocus; _ck(_v, 4, 0, currVal_0); }); }
export function View_FiltersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-filters", [], null, null, null, View_FiltersComponent_0, RenderType_FiltersComponent)), i1.ɵdid(1, 4243456, null, 0, i9.FiltersComponent, [i1.ChangeDetectorRef, i1.NgZone, i5.I18nService, i4.LiveAnnouncer], null, null)], null, null); }
var FiltersComponentNgFactory = i1.ɵccf("wcd-filters", i9.FiltersComponent, View_FiltersComponent_Host_0, { fields: "fields", data: "data", parentFocusable: "parentFocusable", selectedValues: "selectedValues", fixedSelectedValues: "fixedSelectedValues", serializedFilters: "serializedFilters", allowFocusOnFirstElement: "allowFocusOnFirstElement", checkFocus: "checkFocus" }, { filtersChange: "filtersChange", clear: "clear", filtersCancel: "filtersCancel" }, []);
export { FiltersComponentNgFactory as FiltersComponentNgFactory };
