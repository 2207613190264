import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { MachineGroup } from '../rbac/machine-group.entity';
import { File } from './file.entity';

@ApiCall({
	name: 'Block a file',
	endpoint: 'bannedfiles/rbac/upsert',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({ file, reason, rbacGroups, action }: BlockFileData) => {
		const rbacGroupsStateValue = action === 'block';
		const rbacGroupsState: Record<MachineGroup['id'], boolean> = rbacGroups.reduce(
			(groupsState, groupId) => ({
				...groupsState,
				[groupId]: rbacGroupsStateValue,
			}),
			{}
		);

		return {
			data: {
				FileIdentifier: file.sha1,
				FileIdentifierType: 'Sha1',
				RequestorComment: reason,
				RbacGroupsState: rbacGroupsState,
				FileIdentifierSecondary: file.sha256,
				FileIdentifierTypeSecondary: 'Sha256',
			},
		};
	},
})
export class FileBlockApiCall extends ApiCallModel<any, BlockFileData> {}

export interface BlockFileData {
	file: File;
	reason: string;
	action: 'block' | 'unblock';
	rbacGroups: ReadonlyArray<MachineGroup['id']>;
}
