import { ModuleWithProviders } from '@angular/core';
import { PendingActionsService } from './services/pending-actions.service';
import { PendingActionsPageComponent } from './components/pending-actions.page.component';
import { Routes } from '@angular/router';
import { AuthenticatedGuard, OnboardGuard, FeatureEnabledGuard, MdeAllowedActionsGuard } from '@wcd/auth';
import { AirsEnabledGuard } from '@wcd/config';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { RemediationActionEntityTypeService } from './services/remediation-action.entity-type.service';
import { MdeUserRoleActionEnum } from '@wcd/domain';
var pendingTitle = 'Pending actions';
var ɵ0 = {
    MdeAllowedActionsGuardConfig: {
        AllowedActions: MdeUserRoleActionEnum.viewData,
    },
    pageTitle: pendingTitle,
    pageTitleIcon: 'users.user_attention',
    pageDescriptionKey: 'attentionRequired.description',
    enableAirsRoute: [
        '/enable_airs',
        {
            title: pendingTitle,
            messageClass: 'relative enable-auto-ir-investigation-message',
        },
    ],
};
var pendingActionsRoutes = [
    {
        path: 'pending',
        component: PendingActionsPageComponent,
        canActivate: [
            AuthenticatedGuard,
            OnboardGuard,
            FeatureEnabledGuard,
            AirsEnabledGuard,
            MdeAllowedActionsGuard,
        ],
        data: ɵ0,
    },
];
var RemediationModule = /** @class */ (function () {
    function RemediationModule(globalEntityTypesService) {
        globalEntityTypesService.registerEntityType(RemediationActionEntityTypeService);
    }
    RemediationModule.forRoot = function () {
        return {
            ngModule: RemediationModule,
            providers: [PendingActionsService],
        };
    };
    return RemediationModule;
}());
export { RemediationModule };
export { ɵ0 };
