<div *ngIf="!isNarrowLayout || !isPanel else dropdownSteps" [ngClass]="containerClassName">
	<ng-container *ngTemplateOutlet="stepsList"></ng-container>
</div>

<ng-template #dropdownSteps>
	<button class="steps-dropdown-step btn-unstyled wcd-padding-mediumSmall-vertical wcd-padding-large-horizontal"
			(click)="toggleStepsDropdown()"
			[attr.aria-expanded]="stepsDropdownOpen"
			[attr.aria-labelledby]="'current_status_icon current_status_name'">
		<fab-icon
			[attr.id]="'current_status_icon'"
			[iconName]="currentStep.iconName || getIconForStatus(currentStep.status)"
			[contentClass]="'small-icon ' + (currentStep.contentClass || ('ms-color-' + (currentStep.status === StepStatus.next ? 'gray-40' : 'sharedCyanBlue10')))"
			[ariaLabel]="getStepAriaLabelKey(currentStep.status) | i18n"></fab-icon>
		<span id="current_status_name"
			  class="steps-dropdown-step-name wcd-flex-1 wcd-margin-mediumSmall-left wcd-font-weight-bold wcd-font-size-m">
			{{currentStep.text}}
		</span>
		<fab-icon contentClass="wcd-flex-0 wcd-font-size-base"
				  [iconName]="stepsDropdownOpen ? 'ChevronUp' : 'ChevronDown'" aria-hidden="true"></fab-icon>
	</button>
	<div *ngIf="stepsDropdownOpen" class="wcd-padding-large-horizontal wcd-padding-small-top wcd-border-top">
		<ng-container *ngTemplateOutlet="stepsList"></ng-container>
	</div>
</ng-template>

<ng-template #stepsList>
	<ng-container
		*ngFor="let step of steps; let isFirst = first; last as last; let index = index; trackBy: trackByFunction">
		<div *ngIf="!isFirst" class="wcd-flex-horizontal wcd-flex-center-vertical">
			<div class="wcd-flex-center-horizontal">
				<div class="vertical-line wcd-margin-small-vertical"
					 [ngClass]="'ms-background-color-' + (step.status === StepStatus.completed ? 'sharedCyanBlue10' : 'gray-40')">
				</div>
			</div>
		</div>

		<div class="wcd-flex-horizontal wcd-flex-center-vertical"
			 [ngClass]="last && 'wcd-margin-small-bottom'"
			 [attr.aria-labelledby]="'step_status_icon_'+ index +' current_status_name_' + index">
			<div class="left-side wcd-flex-center-horizontal">
				<fab-icon [attr.id]="'step_status_icon_'+ index"
						  [iconName]="currentStep.iconName || getIconForStatus(step.status)"
						  [contentClass]="'small-icon ' + (step.contentClass || ('ms-color-' + (step.status === StepStatus.next ? 'gray-40' : 'sharedCyanBlue10')))"
						  [ariaLabel]="getStepAriaLabelKey(step.status) | i18n"></fab-icon>
			</div>
			<div [attr.id]="'current_status_name_'+ index"
				 class="wcd-padding-small-left"
				 [ngClass]="{'wcd-font-weight-semibold': step.isBold}">
				{{step.text}}
			</div>
		</div>

	</ng-container>
</ng-template>
