export enum PanelType {
	custom, // Renders the panel in 'custom' mode using customWidth, anchored to the far side (right in LTR mode).
	extraLarge, // Renders the panel in 'extra large' mode, anchored to the far side (right in LTR mode).
	large, // Renders the panel in 'large' mode, anchored to the far side (right in LTR mode), and is fluid at XXX-Large breakpoint.
	largeFixed, // Renders the panel in 'large' mode, anchored to the far side (right in LTR mode), and is fixed at XXX-Large breakpoint.
	medium, // Renders the panel in 'medium' mode, anchored to the far side (right in LTR mode).
	smallFixedFar, // Renders the panel in 'small' mode, anchored to the far side (right in LTR mode), and has a fixed width.
	smallFixedNear, // Renders the panel in 'small' mode, anchored to the near side (left in LTR mode), and has a fixed width.
	smallFluid, // Renders the panel in 'small' mode, anchored to the far side (right in LTR mode), and has a fluid width.
	wizard, // Renders the panel in 'wizard' (very wide) mode, anchored to the far side (right in LTR mode).
	fullScreen // Renders the panel on the whole screen
}
