var _a;
import { PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { MachineRiskScoreType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
var machineRiskScoreDisplayKeysMap = (_a = {},
    _a[MachineRiskScoreType.None] = 'machineRiskScore.none',
    _a[MachineRiskScoreType.Informational] = 'machineRiskScore.informational',
    _a[MachineRiskScoreType.Low] = 'machineRiskScore.low',
    _a[MachineRiskScoreType.Medium] = 'machineRiskScore.medium',
    _a[MachineRiskScoreType.High] = 'machineRiskScore.high',
    _a);
var MachineRiskScoreTypeDisplayTextPipe = /** @class */ (function () {
    function MachineRiskScoreTypeDisplayTextPipe(i18nService) {
        this.i18nService = i18nService;
        this.transform = memoize(this.transform);
    }
    MachineRiskScoreTypeDisplayTextPipe.prototype.transform = function (value) {
        if (!(value in machineRiskScoreDisplayKeysMap)) {
            return null;
        }
        return this.i18nService.get(machineRiskScoreDisplayKeysMap[value]);
    };
    return MachineRiskScoreTypeDisplayTextPipe;
}());
export { MachineRiskScoreTypeDisplayTextPipe };
