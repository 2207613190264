// Can't extend native Map, as of Typescript 1.8... :(
/**
 * @deprecated Use `Map` instead.
 */
var Dictionary = /** @class */ (function () {
    function Dictionary() {
        this.keys = new Map();
    }
    Object.defineProperty(Dictionary.prototype, "isEmpty", {
        get: function () {
            return !this.keys.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dictionary.prototype, "hasValues", {
        get: function () {
            return !!this.keys.size;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(Dictionary.prototype, "size", {
        get: function () {
            return this.keys.size;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @param key
     * @param value
     * @returns {Dictionary}
     * @deprecated
     */
    Dictionary.prototype.add = function (key, value) {
        this.keys.set(key, value);
        return this;
    };
    Dictionary.prototype.set = function (key, value) {
        this.keys.set(key, value);
        return this;
    };
    Dictionary.prototype.delete = function (key) {
        var value = this.get(key);
        if (value !== undefined) {
            this.keys.delete(key);
            return value;
        }
        return undefined;
    };
    /**
     * If the Dictionary already has the specified key, return its value. Otherwise, add the key with the specified value and return the new value.
     * @param key
     * @param value
     * @returns {U}
     */
    Dictionary.prototype.addIfNotExists = function (key, value) {
        if (this.has(key))
            return this.get(key);
        this.set(key, value);
        return value;
    };
    Dictionary.prototype.addList = function (values, keyProperty, stringifyKey) {
        var _this = this;
        if (stringifyKey === void 0) { stringifyKey = false; }
        if (values)
            values.forEach(function (value) {
                return _this.set(keyProperty ? (stringifyKey ? String(value[keyProperty]) : value[keyProperty]) : value, value);
            });
        return this;
    };
    Dictionary.prototype.has = function (key) {
        return this.keys.has(key);
    };
    Dictionary.prototype.clear = function () {
        this.keys.clear();
        return this;
    };
    Dictionary.prototype.get = function (key) {
        return this.keys.get(key);
    };
    Dictionary.prototype.getList = function (keys) {
        var _this = this;
        return keys.map(function (key) { return _this.get(key); }).filter(function (value) { return !!value; });
    };
    Dictionary.prototype.first = function () {
        if (!this.hasValues)
            return null;
        return this.toArray()[0];
    };
    Dictionary.prototype.toObject = function () {
        var obj = {};
        this.keys.forEach(function (value, key) {
            obj[String(key)] = value;
        });
        return obj;
    };
    Dictionary.prototype.toArray = function () {
        var valuesArray = [], values = this.keys.values();
        var value;
        while ((value = values.next().value))
            valuesArray.push(value);
        return valuesArray;
    };
    Dictionary.prototype.equals = function (otherDictionary) {
        if (!otherDictionary)
            return false;
        if (otherDictionary.size !== this.size)
            return false;
        if (this.some(function (value, key) { return !otherDictionary.has(key); }))
            return false;
        return true;
    };
    Dictionary.prototype.equalsWith = function (otherDictionary, callbackFn) {
        if (!this.equals(otherDictionary))
            return false;
        return this.every(function (value, key) { return callbackFn(value, otherDictionary.get(key), key); });
    };
    Dictionary.prototype.getKeys = function () {
        var keysArray = [], keys = this.keys.keys();
        var key;
        while ((key = keys.next().value))
            keysArray.push(key);
        return keysArray;
    };
    Dictionary.prototype.some = function (callbackfn) {
        var keys = this.keys.keys();
        var result;
        while (!(result = keys.next()).done) {
            if (callbackfn(this.get(result.value), result.value))
                return true;
        }
        return false;
    };
    Dictionary.prototype.stringify = function () {
        return JSON.stringify(this.toObject());
    };
    Dictionary.prototype.every = function (callbackfn) {
        var keys = this.keys.keys();
        var result;
        while (!(result = keys.next()).done) {
            if (!callbackfn(this.get(result.value), result.value))
                return false;
        }
        return true;
    };
    Dictionary.prototype.find = function (callbackfn) {
        var keys = this.keys.keys();
        var result;
        while (!(result = keys.next()).done) {
            var value = this.get(result.value);
            if (callbackfn(value, result.value))
                return value;
        }
        return null;
    };
    Dictionary.prototype.map = function (callbackfn) {
        var mapped = new Array(this.keys.size), keys = this.keys.keys();
        var key, result, indexNumber = 0;
        while (!(result = keys.next()).done) {
            key = result.value;
            mapped[indexNumber] = callbackfn(this.keys.get(key), key, indexNumber);
            indexNumber++;
        }
        return mapped;
    };
    Dictionary.prototype.forEach = function (callbackfn) {
        var keys = this.keys.keys();
        var key, result, indexNumber = 0;
        while (!(result = keys.next()).done) {
            key = result.value;
            callbackfn(this.keys.get(key), key, indexNumber++);
        }
        return this;
    };
    Dictionary.prototype.filter = function (callbackfn) {
        var filteredDictionary = new Dictionary();
        var keys = this.keys.keys();
        var value;
        var key;
        var result;
        var indexNumber = 0;
        while (!(result = keys.next()).done) {
            key = result.value;
            value = this.keys.get(key);
            if (callbackfn(value, key, indexNumber++))
                filteredDictionary.set(key, value);
        }
        return filteredDictionary;
    };
    /**
     * Creates a dictionary from an array of objects, indexes it according to the specified property of each object
     * @param values An array of objects
     * @param keyProperty The name of the property in each object to use as Dictionary key
     * @param stringifyKey {boolean = false} If true, the value of the ID property is converted to a String, so the keys of the Dictionary are always strings
     * @returns {Dictionary<T, U>}
     */
    Dictionary.fromList = function (values, keyProperty, stringifyKey) {
        if (stringifyKey === void 0) { stringifyKey = false; }
        var dictionary = new Dictionary();
        return dictionary.addList(values, keyProperty, stringifyKey);
    };
    Dictionary.fromRawList = function (V, rawList, keyProperty) {
        return Dictionary.fromList(rawList.map(function (item) { return new V(item); }), keyProperty);
    };
    Dictionary.fromIndex = function (keysIndex) {
        var dictionary = new Dictionary();
        if (keysIndex) {
            for (var key in keysIndex) {
                if (keysIndex.hasOwnProperty(key))
                    dictionary.set(key, keysIndex[key]);
            }
        }
        return dictionary;
    };
    Dictionary.fromDictionary = function (dictionary, keyProperty) {
        return Dictionary.fromList(dictionary.toArray(), keyProperty);
    };
    return Dictionary;
}());
export { Dictionary };
