import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { RemediationTask } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../../global_entities/components/entity-panels/entity-panel.component.base';

@Component({
	selector: 'remediation-task-entity-panel',
	templateUrl: './remediation-task.entity-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RemediationTaskEntityPanelComponent extends EntityPanelComponentBase<RemediationTask> {
	get remediationTask(): RemediationTask {
		return this.entity;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(remediationTask: RemediationTask, isExtendedData: boolean = false) {
		super.initEntity(remediationTask, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}
}
