import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MarkdownModule } from 'ngx-markdown';
import { HexaditeFormsModule } from '../forms/forms.module';
import { I18nModule } from '@wcd/i18n';
import { WcdIconsModule } from '@wcd/icons';
import { AlertCardComponent } from './alert-card.component';
import { BasicDialogsModule } from './basic-dialogs.module';
import { ConfirmModalComponent } from './confirm/confirm.modal';
import { ConfirmationService } from './confirm/confirm.service';
import { ErrorModalComponent } from './modals/components/error.modal';
import { ModalsModule } from './modals/modals.module';
import { PanelsModule } from '@wcd/panels';
import { DialogsService } from './services/dialogs.service';
import { ShortcutComponent } from './shortcuts/shortcut.component';
import { ShortcutsService } from './shortcuts/shortcuts.service';
import { A11yModule } from '@angular/cdk/a11y';
import { FabButtonModule } from '@angular-react/fabric';
import { AngularExtensionsModule } from '@wcd/angular-extensions';

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		BasicDialogsModule,
		WcdIconsModule,
		ModalsModule,
		HexaditeFormsModule,
		I18nModule,
		MarkdownModule.forChild(),
		A11yModule,
		FabButtonModule,
		AngularExtensionsModule,
	],
	declarations: [ShortcutComponent, AlertCardComponent, ErrorModalComponent, ConfirmModalComponent],
	exports: [BasicDialogsModule, ShortcutComponent, AlertCardComponent, ModalsModule, PanelsModule],
	entryComponents: [ShortcutComponent, ErrorModalComponent, ConfirmModalComponent],
})
export class DialogsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: DialogsModule,
			providers: [DialogsService, ShortcutsService, ConfirmationService],
		};
	}
}
