export enum LogonType {
	Interactive = 'Interactive',
	Network = 'Network',
	Batch = 'Batch',
	Service = 'Service',
	Unlock = 'Unlock',
	NewCredentials = 'NewCredentials',
	RemoteInteractive = 'RemoteInteractive',
	CachedInteractive = 'CachedInteractive',
	NetworkCleartext = 'NetworkCleartext',
	CachedRemoteInteractive = 'CachedRemoteInteractive',
	CachedUnlock = 'CachedUnlock',
	Local = 'Local'
}
