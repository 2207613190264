import { AirsEntityType } from '@wcd/domain';

export const enum CloudLogonSessionEntityAlertV3Fields {
	Account = 'Account',
	SessionId = 'SessionId',
	UserAgent = 'UserAgent',
}

export function mapCloudLogonSessionEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				case CloudLogonSessionEntityAlertV3Fields.Account:
					acc['account'] = {
						username: val['Name'],
						nt_domain: val['NTDomain'],
						sid: val['Sid'],
						display_name: val['DisplayName'],
						type_id: AirsEntityType.Account, // need the entity id to model AirsAccount object in order to open the side panel
					};
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case CloudLogonSessionEntityAlertV3Fields.SessionId:
					acc['session_id'] = val;
					break;
				case CloudLogonSessionEntityAlertV3Fields.UserAgent:
					acc['user_agent'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
