export enum CyberEventActionSubGroupTypeName {
	callWin32k = 'callWin32k',
	childProcess = 'childProcess',
	dynamicCode = 'dynamicCode',
	exportAddressFilter = 'exportAddressFilter',
	importAddressFilter = 'importAddressFilter',
	loadFromRemote = 'loadFromRemote',
	loadLowImage = 'loadLowImage',
	loadNonMicrosoft = 'loadNonMicrosoft',
	ROP = 'ROP',
}
