import { EntityConfig, DataQuery, ApiCallConfig } from '@microsoft/paris';
import { AddMachineGroupsFilterQueryParam, Cache, DefaultApiVersion, TvmEndPoint } from './commonEndPointUtils';

const BaseUrl = config => `${config.data.serviceUrls.tvm}/${TvmEndPoint.OrgSettings}`;

const SharedConfig: Partial<EntityConfig<any>> | Partial<ApiCallConfig<any>> = {
	cache: Cache,
	baseUrl: BaseUrl,
	customHeaders: DefaultApiVersion,
};

export const TvmOrgSettingsSharedEntityConfigurations: Partial<EntityConfig<any>> = {
	...SharedConfig,
	saveMethod: (item: any) => (item.id ? 'PUT' : 'POST'),
	readonly: true,
};

export const TvmOrgSettingsApiCallSharedConfigurations: Partial<ApiCallConfig<any>> = {
	...SharedConfig,
	parseQuery: input => {
		const params = {};

		if (input.hasOwnProperty('configurationId')) {
			params['configurationId'] = input.configurationId;
		}

		AddMachineGroupsFilterQueryParam(params);

		return {
			params: params,
		};
	},
};
