
		<ng-container *ngIf="(data$ | async) as data; else noData">
			<div
				*ngIf="!summaryView; else summary"
				class="wcd-flex-vertical wcd-full-height wcd-m365-typography"
			>
				<div *ngIf="data.isO365IntegrationEnabled && !isNewFilePageEnabled" class="wcd-margin-small-bottom">
					<fab-shimmer [isDataLoaded]="data.mailStats !== loadingSymbol">
						<h4
							*ngIf="data.mailStats && data.mailStats !== loadingSymbol"
							class="wcd-margin-none-bottom"
						>
							{{
								'file.mailStats.emailInboxes'
									| i18n
										: {
												numberOfInboxes:
													data?.mailStats?.numberOfInboxes | prettyNumber
										  }
							}}
						</h4>
					</fab-shimmer>
					<fab-shimmer
						[isDataLoaded]="data.mailStats !== loadingSymbol"
						[styles]="secondaryTextShimmerStyle"
					>
						<div
							*ngIf="data.mailStats && data.mailStats !== loadingSymbol"
							class="wcd-flex-horizontal-align-items-end"
						>
							<div class="wcd-secondary-text">
								<span *ngIf="data.mailStats.sender">
									{{ 'file.mailStats.sender' | i18n: { sender: data?.mailStats?.sender } }}
									|
								</span>
								<span *ngIf="data.mailStats.firstSeen">
									{{ 'files.fields.firstSeen.title' | i18n }}:
									{{ data.mailStats.firstSeen | timeAgo }}
								</span>
							</div>
							<a
								*ngIf="data.mailStats.deepLinkToO365Portal"
								href="{{ data.mailStats.deepLinkToO365Portal }}"
								class="wcd-margin-small-left"
								data-track-id="deepLinkToO365Portal"
								data-track-type="Navigation"
							>
								{{ 'file.mailStats.openInO365' | i18n }}
							</a>
						</div>
					</fab-shimmer>
				</div>
				<ng-container *ngTemplateOutlet="stats"></ng-container>
			</div>
			<ng-template #summary>
				<div class="wcd-flex-horizontal">
					<div
						*ngIf="data.isO365IntegrationEnabled && data?.mailStats?.numberOfInboxes != null"
						class="wcd-flex-1"
					>
						<distribution-element
							[title]="'file.mailStats.emailInboxesSummary' | i18n"
							[mainText]="data?.mailStats?.numberOfInboxes | prettyNumber"
						>
						</distribution-element>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="data?.mailStats?.numberOfInboxes > 0">
								{{ 'files.fields.firstSeen.title' | i18n }}:
								{{ data.mailStats.firstSeen | timeAgo }}
								<br />
								{{ 'files.fields.lastSeen.title' | i18n }}:
								{{ data.mailStats.lastSeen | timeAgo }}
							</ng-container>
						</div>
					</div>
					<ng-container
						*ngTemplateOutlet="stats; context: { className: 'wcd-flex-3' }"
					></ng-container>
				</div>
			</ng-template>
			<ng-template #stats let-className="className">
				<ng-container *ngIf="!supportNewLayout || !isNewFilePageEnabled; else newFilePrevalence">
					<entity-statistics
						[ngClass]="className"
						[statistics]="data.fileStats"
						[isLoadingOrganizationPrevalence]="data.isLoadingOrganizationFileStats"
						[summaryView]="summaryView"
						[timeRange]="currentOrgPrevDateRange.id"
						(timeRangeChange)="onOrgPrevDateRangeChange($event)"
						[prevalenceBuckets]="filePrevalenceBuckets"
					></entity-statistics>
				</ng-container>
				<ng-template #newFilePrevalence>
					<entity-prevalence-statistics
						[ngClass]="className"
						[mailStats]="data.mailStats"
						[statistics]="data.fileStats"
						[prevalenceBuckets]="filePrevalenceBuckets"
						[file]="_file"
						[isLoadingOrganizationPrevalence]="data.isLoadingOrganizationFileStats"
					></entity-prevalence-statistics>
				</ng-template>
			</ng-template>
		</ng-container>
		<ng-template #noData>
			<h4>{{ 'reports.widgets.prevalence.noData' | i18n }}</h4>
		</ng-template>
	