import { OperatingSystemPlatform } from './operating-system-platform.entity';
import { keyBy } from 'lodash-es';

export enum OperatingSystemPlatformCategories {
	Windows11 = 'Windows11',
	Windows10 = 'Windows10',
	Windows8Blue = 'Windows8Blue',
	Windows8 = 'Windows8',
	Windows7 = 'Windows7',
	WindowsServer2022 = 'WindowsServer2022',
	WindowsServer2019 = 'WindowsServer2019',
	Windows2016 = 'Windows2016',
	WindowsServer2016 = 'WindowsServer2016',
	WindowsServer2012 = 'WindowsServer2012',
	WindowsServer2012R2 = 'WindowsServer2012R2',
	WindowsServer2008R2 = 'WindowsServer2008R2',
	WindowsServerSAC = 'WindowsServerSAC',
	Windows10WVD = 'Windows10WVD',
	Windows = 'Windows',
	Linux = 'Linux',
	macOS = 'macOS',
	iOS = 'iOS',
	Android = 'Android',
	OtherPlatform = 'Other',
}

export const operatingSystemPlatformValues: Array<OperatingSystemPlatform> = [
	{
		id: OperatingSystemPlatformCategories.WindowsServer2022,
		name: 'Windows Server 2022',
		categoryName: 'Windows Server 2022',
		category: OperatingSystemPlatformCategories.WindowsServer2022,
		priority: 6,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Windows11,
		name: 'Windows 11',
		categoryName: 'Windows 11',
		category: OperatingSystemPlatformCategories.Windows11,
		priority: 1,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Windows10,
		name: 'Windows 10',
		categoryName: 'Windows 10',
		category: OperatingSystemPlatformCategories.Windows10,
		priority: 2,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Windows8Blue,
		name: 'Windows 8.1',
		categoryName: 'Windows 8.1',
		category: OperatingSystemPlatformCategories.Windows8Blue,
		priority: 3,
		isUpgradeable: true,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows8',
		name: 'Windows 8',
		categoryName: 'Windows 8',
		category: OperatingSystemPlatformCategories.Windows8,
		priority: 4,
		isUpgradeable: true,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Windows7,
		name: 'Windows 7',
		categoryName: 'Windows 7',
		category: OperatingSystemPlatformCategories.Windows7,
		priority: 5,
		isUpgradeable: true,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServer2019,
		name: 'Windows Server 2019',
		categoryName: 'Windows Server 2019',
		category: OperatingSystemPlatformCategories.WindowsServer2019,
		priority: 7,
		isUpgradeable: true,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Windows2016,
		name: 'Windows 2016',
		categoryName: 'Windows 2016',
		category: OperatingSystemPlatformCategories.Windows2016,
		isBackendSupportedFilter: false,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServer2016,
		name: 'Windows Server 2016',
		categoryName: 'Windows Server 2016',
		category: OperatingSystemPlatformCategories.WindowsServer2016,
		priority: 8,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServer2012,
		name: 'Windows Server 2012',
		categoryName: 'Windows Server 2012',
		category: OperatingSystemPlatformCategories.WindowsServer2012,
		isBackendSupportedFilter: false,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServer2012R2,
		name: 'Windows Server 2012 R2',
		categoryName: 'Windows Server 2012 R2',
		category: OperatingSystemPlatformCategories.WindowsServer2012R2,
		priority: 9,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServer2008R2,
		name: 'Windows Server 2008 R2',
		categoryName: 'Windows Server 2008 R2',
		category: OperatingSystemPlatformCategories.WindowsServer2008R2,
		priority: 10,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.WindowsServerSAC,
		name: 'Windows Server SAC',
		categoryName: 'Windows Server SAC',
		category: OperatingSystemPlatformCategories.WindowsServerSAC,
		isBackendSupportedFilter: false,
	},
	{
		id: OperatingSystemPlatformCategories.Windows10WVD,
		name: 'Windows 10 WVD',
		categoryName: 'Windows 10 WVD',
		category: OperatingSystemPlatformCategories.Windows10WVD,
		priority: 15,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Linux,
		name: 'Linux',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		priority: 11,
		isBackendSupportedFilter: true,
	},
	{
		id: 'SuseLinuxEnterpriseServer',
		name: 'SuseLinux Enterprise Server',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: 'RedHatEnterpriseLinux',
		name: 'Red Hat Enterprise Linux',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: 'Ubuntu',
		name: 'Ubuntu',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: 'OracleLinux',
		name: 'Oracle Linux',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: 'CentOS',
		name: 'Cent OS',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: 'Debian',
		name: 'Debian',
		categoryName: 'Linux',
		category: OperatingSystemPlatformCategories.Linux,
		isBackendSupportedFilter: false,
	},
	{
		id: OperatingSystemPlatformCategories.macOS,
		name: 'macOS',
		categoryName: 'macOS',
		category: OperatingSystemPlatformCategories.macOS,
		priority: 12,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.iOS,
		name: 'iOS',
		categoryName: 'iOS',
		category: OperatingSystemPlatformCategories.iOS,
		priority: 13,
		isBackendSupportedFilter: true,
	},
	{
		id: OperatingSystemPlatformCategories.Android,
		name: 'Android',
		categoryName: 'Android',
		category: OperatingSystemPlatformCategories.Android,
		priority: 14,
		isBackendSupportedFilter: true,
	},
	{
		id: 'WindowsXp',
		name: 'Windows Xp',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'None',
		name: 'None',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Other',
		name: 'None',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: false,
	},
	{
		id: 'Windows2003',
		name: 'Windows 2003',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows2008',
		name: 'Windows 2008',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows2016',
		name: 'Windows 2016',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windowsvista',
		name: 'Windows Vista',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows8blueserver',
		name: 'Windows 8 Blue Server',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows8server',
		name: 'Windows 8 Server',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windowsxpembedded',
		name: 'Windows Xp Embedded',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows2012',
		name: 'Windows 2012',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: true,
	},
	{
		id: 'Windows',
		name: 'Windows',
		categoryName: 'Other',
		category: OperatingSystemPlatformCategories.OtherPlatform,
		isBackendSupportedFilter: false,
	},
];

export const osPlatformsMap = keyBy(operatingSystemPlatformValues, os => os.id);
