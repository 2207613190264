import { Injectable } from '@angular/core';
import {
	EntityDataViewOptions,
	EntityType,
} from '../../../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../../../global_entities/models/entity-type-service.interface';
import { BaselineProfileDevice } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { BaselineProfileDeviceEntityDetailsComponent } from '../../../../../../global_entities/components/entity-details/baseline-profile-device.entity-details.component';
import { ProfileDeviceFieldsService } from './profile-device.fields.service';
import { ProfileDeviceEntityPanelComponent } from '../components/profile-device.entity-panel.component';
import { EntityViewType } from '../../../../../../global_entities/models/entity-view-type.enum';
import { ItemActionModel, ItemActionModelConfig } from '../../../../../../dataviews/models/item-action.model';
import { Router } from '@angular/router';
import { ActivatedEntity } from '../../../../../../global_entities/services/activated-entity.service';

export interface ProfileDevicesDataViewFixedOptions { }

@Injectable()
export class ProfileDeviceEntityTypeService implements EntityTypeService<BaselineProfileDevice> {
	constructor(
		private router: Router,
		private activatedEntity: ActivatedEntity,) { }

	readonly entityType: EntityType<BaselineProfileDevice> = {
		id: 'profile-device',
		entity: BaselineProfileDevice,
		icon: FabricIconNames.PageList,
		entityDetailsComponentType: BaselineProfileDeviceEntityDetailsComponent,
		singleEntityPanelComponentType: ProfileDeviceEntityPanelComponent,
		getEntityName: configuration => configuration.name,
		entityPluralNameKey: 'profile_device_entityType_pluralName',
		entitySingularNameKey: 'profile_device_entityType_singularName',
		getEntityDataviewLink: () => '/baseline-compliance_t/profiles/:id/devices',
		loadFullEntityInPanel: false,
		dataViewOptions: <EntityDataViewOptions<BaselineProfileDevice, ProfileDevicesDataViewFixedOptions>>{
			fields: ProfileDeviceFieldsService,
		},
		getActions: (profiles: Array<BaselineProfileDevice>, options, entityViewType: EntityViewType) => {
			if (profiles.length !== 1 || entityViewType === EntityViewType.EntityPage) {
				return [];
			}

			const actions: Array<ItemActionModelConfig> = [
				{
					id: 'gotoMachine',
					nameKey: 'tvm_baseline_profile_entity_deviceTab_sidePanel_buttonText',
					icon: FabricIconNames.PageRight,
					closeOnAction: methodResolution => methodResolution,
					method: (devices: Array<BaselineProfileDevice>) => {
						const deviceId = devices[0].id;
						const url = `/machines/${encodeURIComponent(deviceId)}/baseline-compliance`;
						this.activatedEntity.setNextEntity(devices[0]);
						return this.router.navigate([url]);
					},
				},
			];

			return actions.map(itemActionConfig => new ItemActionModel(itemActionConfig));
		},
	};
}
