import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { HuntingQuerySchemaField } from './hunting-query-schema.value-object';
import { RawResultSetItem } from './hunting-query.models';
import { HuntingQueryQuotaUsage } from './hunting-query-quota-usage.value-object';
import { HuntingChartVisualization } from './hunting-query-chart-visualization.value-object';

@ValueObject({
	singularName: 'Hunting query result',
	pluralName: 'Hunting query results',
	readonly: true,
})
export class HuntingQueryResult extends ModelBase {
	@EntityField({
		data: 'Schema',
		arrayOf: HuntingQuerySchemaField,
	})
	readonly schema: Array<HuntingQuerySchemaField>;

	@EntityField({ data: 'Results' })
	readonly resultSet: Array<Readonly<RawResultSetItem>>;

	@EntityField({ data: 'Quota' })
	readonly quota: HuntingQueryQuotaUsage;

	@EntityField({ data: 'ChartVisualization' })
	readonly visualization: HuntingChartVisualization;
}
