import { RecommendationExceptionService } from './recommendation-exception.service';
import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FieldsService } from '../../../../../global_entities/models/entity-type.interface';
import { TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { CalculateRemainingDaysPipe } from '../../../../../shared/pipes/calculate-remaining-days.pipe';
import {
	RecommendationException,
	RecommendationExceptionStateValue,
	RecommendationExceptionAggregated,
} from '@wcd/domain';
import { TextToken, TvmTextsService } from '../../../../../tvm/services/tvm-texts.service';

@Injectable()
export class RecommendationExceptionsFieldsService implements FieldsService<RecommendationException> {
	private _fields: Array<DataviewField<RecommendationException>>;
	private _relatedExceptionsFields: Observable<Array<DataviewField<RecommendationException>>>;
	private _aggregatedExceptionsFields: Array<DataviewField<RecommendationExceptionAggregated>>;

	constructor(
		private tzDateService: TzDateService,
		private i18nService: I18nService,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private tvmTextsService: TvmTextsService,
		private recommendationExceptionService: RecommendationExceptionService
	) {}

	get fields(): Array<DataviewField<RecommendationException>> {
		/*
		//TODO: consider:
		Ben Grynhaus
			FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
			Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
			It does exactly what the C# equivalent does.
		*/
		if (!this._fields) {
			this._fields = DataviewField.fromList<RecommendationException>([
				{
					id: 'title',
					name: this.i18nService.get('tvm.recommendationException.name'),
					minWidth: 350,
				},
				{
					id: 'exceptionJustification',
					name: this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.justification'
					),
					getDisplay: (recommendationException: RecommendationException) => {
						return this.recommendationExceptionService.getJustificationLabel(
							recommendationException.exceptionJustification
						);
					},
				},
				{
					id: 'exceptionType',
					name: this.i18nService.get('tvm.recommendationException.type'),
					getDisplay: (recommendationException: RecommendationException) => {
						return this.tvmTextsService.getText(
							TextToken.ExceptionRemediationType,
							recommendationException
						);
					},
				},
				{
					id: 'status',
					name: this.i18nService.get('status'),
					getDisplay: (recommendationException: RecommendationException) => {
						return this.i18nService.get(
							`tvm.recommendationException.status.${recommendationException.status.value}`
						);
					},
				},
				{
					id: 'createdBy',
					name: this.i18nService.get('tvm.common.createdBy'),
					getDisplay: (recommendationException: RecommendationException) =>
						recommendationException.requester
							? recommendationException.requester.email
							: this.i18nService.get('notAvailable.short'),
				},
				{
					id: 'creationTime',
					name: this.i18nService.get('tvm.securityRecommendation.relatedRemediation.createdOn'),
					getDisplay: (recommendationException: RecommendationException) => {
						return this.tzDateService.format(recommendationException.creationTime, 'shortDate');
					},
					sort: {
						sortCompareFunction: (
							exception1: RecommendationException,
							exception2: RecommendationException
						) => exception1.creationTime.getTime() - exception2.creationTime.getTime(),
					},
				},
				{
					id: 'expiringOn',
					name: this.i18nService.get('tvm.recommendationException.expiry.date'),
					getDisplay: (recommendationException: RecommendationException) => {
						if (
							recommendationException.status.value ===
							RecommendationExceptionStateValue.Cancelled
						) {
							return this.tzDateService.format(
								recommendationException.status.lastModifiedOn,
								'shortDate'
							);
						} else {
							return this.tzDateService.format(recommendationException.expiringOn, 'shortDate');
						}
					},
					sort: {
						sortCompareFunction: (
							exception1: RecommendationException,
							exception2: RecommendationException
						) => {
							let expiringOn1 = exception1.expiringOn.getTime();
							let expiringOn2 = exception2.expiringOn.getTime();
							if (exception1.status.value === RecommendationExceptionStateValue.Cancelled) {
								expiringOn1 = exception1.status.lastModifiedOn.getTime();
							}

							if (exception2.status.value === RecommendationExceptionStateValue.Cancelled) {
								expiringOn2 = exception2.status.lastModifiedOn.getTime();
							}

							return expiringOn1 - expiringOn2;
						},
					},
				},
				{
					id: 'remainingDays',
					name: this.i18nService.get('tvm.remediationTask.ticket.dueDate'),
					getDisplay: (recommendationException: RecommendationException) => {
						const remainingDays = this.calculateRemainingDaysPipe.transform(
							recommendationException.expiringOn
						);
						if (
							recommendationException.status.value !== RecommendationExceptionStateValue.Active
						) {
							return '-';
						} else {
							return `${remainingDays} days`;
						}
					},
					sort: {
						sortCompareFunction: (
							exception1: RecommendationException,
							exception2: RecommendationException
						) => {
							if (exception1.status.value === RecommendationExceptionStateValue.Expired) {
								return 1;
							}
							if (exception2.status.value === RecommendationExceptionStateValue.Expired) {
								return -1;
							}
							if (exception1.status.value === RecommendationExceptionStateValue.Cancelled) {
								return 1;
							}
							if (exception2.status.value === RecommendationExceptionStateValue.Cancelled) {
								return -1;
							}
							return exception1.expiringOn.getTime() - exception2.expiringOn.getTime();
						},
					},
				},
			]);
		}
		return this._fields;
	}

	get relatedExceptionsFields(): Observable<Array<DataviewField<RecommendationException>>> {
		if (!this._relatedExceptionsFields) {
			this._relatedExceptionsFields = this.recommendationExceptionService.rbacGroupIdToNameMap$.pipe(
				map(rbacGroupIdToNameMap => {
					const additionalFields = DataviewField.fromList<RecommendationException>([
						{
							id: 'machineGroup',
							name: this.i18nService.get('tvm.common.machineGroup'),
							getDisplay: exception =>
								rbacGroupIdToNameMap[exception.rbacGroupId] ||
								this.i18nService.get('notAvailable.short'),
						},
					]);

					const fieldsIdMap = (id: string) => {
						switch (id) {
							case 'machineGroup':
								return 0;
							case 'createdBy':
								return 1;
							case 'status':
								return 2;
							case 'expiringOn':
								return 3;
							case 'exceptionJustification':
								return 4;
							default:
								return Number.MAX_VALUE;
						}
					};

					return this.fields
						.filter(
							field =>
								field.id === 'status' ||
								field.id === 'expiringOn' ||
								field.id === 'exceptionJustification'
						)
						.concat(additionalFields)
						.sort((first, second) => fieldsIdMap(first.id) - fieldsIdMap(second.id));
				})
			);
		}

		return this._relatedExceptionsFields;
	}

	get aggregatedExceptionsFields(): Array<DataviewField<RecommendationExceptionAggregated>> {
		if (!this._aggregatedExceptionsFields) {
			this._aggregatedExceptionsFields = DataviewField.fromList<RecommendationExceptionAggregated>([
				{
					id: 'title',
					name: this.i18nService.get('tvm.recommendationException.name.singular'),
					minWidth: 350,
				},
				{
					id: 'exceptionScope',
					name: this.i18nService.get('tvm.recommendationException.exceptionScope'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
						return this.tvmTextsService.getText(
							TextToken.AggregatedExceptionScope,
							recommendationExceptionAggregated
						);
					},
				},
				{
					id: 'exceptionJustification',
					name: this.i18nService.get(
						'tvm.securityRecommendation.recommendationExceptionCreation.justification'
					),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
						const distinctJustifications = [
							...recommendationExceptionAggregated.distinctJustifications,
						];
						const justificationLabel = this.recommendationExceptionService.getJustificationLabel(
							distinctJustifications.shift()
						);
						return (
							justificationLabel +
							(distinctJustifications.length > 0
								? ` (${this.i18nService.get('tvm.common.plusMore', {
										amount: distinctJustifications.length,
								  })})`
								: '')
						);
					},
					getTooltip: (recommendationExceptionAggregated: RecommendationExceptionAggregated) =>
						recommendationExceptionAggregated.distinctJustifications
							.map(justification =>
								this.recommendationExceptionService.getJustificationLabel(justification)
							)
							.join('<br>'),
					valueTooltipAllowHtmlRendering: true
				},
				{
					id: 'exceptionType',
					name: this.i18nService.get('tvm.recommendationException.type'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
						return this.tvmTextsService.getText(
							TextToken.ExceptionRemediationType,
							recommendationExceptionAggregated
						);
					},
				},
				{
					id: 'status',
					name: this.i18nService.get('status'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
						const distinctStatus = [
							...recommendationExceptionAggregated.distinctStatusWithCount,
						].map(statusWithCount => statusWithCount.status);
						const statusLabel = this.i18nService.get(
							`tvm.recommendationException.status.${distinctStatus.shift()}`
						);
						return (
							statusLabel +
							(distinctStatus.length > 0
								? ` (${this.i18nService.get('tvm.common.plusMore', {
										amount: distinctStatus.length,
								  })})`
								: '')
						);
					},
					getTooltip: (recommendationExceptionAggregated: RecommendationExceptionAggregated) =>
						recommendationExceptionAggregated.distinctStatusWithCount
							.map(statusWithCount =>
								this.i18nService.get(
									`tvm.recommendationException.status.${statusWithCount.status}`
								)
							)
							.join('<br>'),
					valueTooltipAllowHtmlRendering: true
				},
				{
					id: 'createdBy',
					name: this.i18nService.get('tvm.common.createdBy'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) =>
						this.tvmTextsService.getText(
							TextToken.AggregatedExceptionRequester,
							recommendationExceptionAggregated
						),
				},
				{
					id: 'createdOn',
					name: this.i18nService.get('tvm.securityRecommendation.relatedRemediation.createdOn'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) =>
						recommendationExceptionAggregated.createdOn
							? this.tzDateService.format(
									recommendationExceptionAggregated.createdOn,
									'shortDate'
							  )
							: '',
				},
				{
					id: 'expiringOn',
					name: this.i18nService.get('tvm.recommendationException.expiry.date'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) =>
						recommendationExceptionAggregated.expiringOn
							? this.tzDateService.format(
									recommendationExceptionAggregated.expiringOn,
									'shortDate'
							  )
							: '',
				},
				{
					id: 'remainingDays',
					name: this.i18nService.get('tvm.remediationTask.ticket.dueDate'),
					getDisplay: (recommendationExceptionAggregated: RecommendationExceptionAggregated) => {
						if (recommendationExceptionAggregated.expiringOn) {
							const remainingDays = this.calculateRemainingDaysPipe.transform(
								recommendationExceptionAggregated.expiringOn
							);
							return remainingDays >= 0 ? `${remainingDays} days` : '-';
						} else {
							return '';
						}
					},
				},
			]);
		}

		return this._aggregatedExceptionsFields;
	}
}
