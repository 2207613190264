import { Entity } from '@microsoft/paris';
import { StatusModel } from '../../../status.model';
import { domainCategoryValues } from './domain-category.entity.values';
import { DomainCategoryId } from './domain-category.models';

@Entity({
	singularName: 'Domain category',
	pluralName: 'Domain categories',
	values: domainCategoryValues,
})
export class DomainCategory extends StatusModel<DomainCategoryId> {
	isOpen: boolean;
}
