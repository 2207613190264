var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship } from '@microsoft/paris';
import { Alert } from '../alert.entity';
import { AlertIncidentGraph } from './incident-graph';
var ɵ0 = function (alert) { return ({
    id: alert.id,
}); }, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; };
var AlertIncidentGraphRelationship = /** @class */ (function () {
    function AlertIncidentGraphRelationship() {
    }
    AlertIncidentGraphRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Alert,
            dataEntity: AlertIncidentGraph,
            getRelationshipData: ɵ0,
            fixedData: {
                lookbackInDays: 30,
            },
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            endpoint: 'ScopeOfBreach',
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ1,
        })
    ], AlertIncidentGraphRelationship);
    return AlertIncidentGraphRelationship;
}());
export { AlertIncidentGraphRelationship };
export { ɵ0, ɵ1 };
