import { Alert } from '@wcd/domain';
import { Component, Input, ChangeDetectionStrategy, ViewEncapsulation } from '@angular/core';
import { AlertEntityTypeService } from '../services/alert.entity-type.service';
import { AlertsService } from '../services/alerts.service';

@Component({
	selector: 'alert-name-field',
	template: `
		<route-link
			className="field-value-link wcd-flex wcd-vertical-align-center"
			data-track-id="AlertNameLinkToAlertPage"
			data-track-type="Navigation"
			*ngIf="alertEntityTypeService.entityType.getNavigationModel(alert) as alertRoute; else contents"
			[routeLink]="alertRoute"
			[linkTabIndex]="linkTabIndex"
		>
			<ng-container *ngTemplateOutlet="contents"></ng-container>
		</route-link>
		<ng-template #contents>
			<div class="wcd-flex-items-center router-link-text wcd-text-overflow-ellipsis">
				<ng-container *ngIf="showTags">
					<tag
						*ngIf="alert.isThreatExpertOriginated"
						[tag]="alert.detectionSource.nameI18nKey | i18n"
						className="wcd-margin-xsmall-right tag-color-box-threatExpert wcd-flex-none"
					>
					</tag>
					<tag
						*ngIf="alert.actor"
						[tag]="alert.actor"
						className="wcd-margin-xsmall-right wcd-flex-none tag-color-box-actor"
					>
					</tag>
				</ng-container>
				<span [wcdTooltip]="alert.name" class="nowrap wcd-text-overflow-ellipsis wcd-field-alert-name">
					{{ alert.isGroup ? alert.groupedAlertsCount + ' alerts: ' + alert.name : alert.name }}
				</span>
			</div>
		</ng-template>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./alert-name-field.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AlertNameFieldComponent {
	@Input() alert: Alert;
	@Input() showTags: boolean = true;
	@Input() linkTabIndex: 0 | -1 | null= -1;

	constructor(
		public alertEntityTypeService: AlertEntityTypeService,
		private alertsService: AlertsService
	) {}
}
