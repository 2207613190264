
		<div class="page-header" *ngIf="title">
			<h2>{{ title }}</h2>
		</div>
		<div
			class="wcd-flex-center-all wcd-full-height wcd-full-width wcd-full-height-from-width-800"
			[ngClass]="messageClass"
		>
			<a [routerLink]="['/preferences2', 'integration']" class="wcd-flex-center-vertical">
				<wcd-shared-icon [iconName]="'investigationOutline'" class="wcd-padding-small-right">
				</wcd-shared-icon>
				<h2 class="wcd-font-size-l">
					{{ 'enableAirs.title' | i18n }}
				</h2>
			</a>
		</div>
	