<div role="list" [class.wcd-flex-horizontal]="orientation === FlexDirection.Horizontal">
  <ng-container *ngFor="let item of items; last as isLast">
    <ng-container
      [ngTemplateOutlet]="item.selectable ? selectableRef : nonSelectableRef"
      [ngTemplateOutletContext]="{ item:item, isLast: isLast }">
    </ng-container>
  </ng-container>
</div>

<ng-template #selectableRef let-item="item" let-isLast='isLast'>
  <div
    tabIndex="0"
    class="selectable"
    [attr.role]="itemRole"
    [ngClass]="getItemClass(isLast)"
    [wcdTooltip]="item.tooltip"
    (click)="select.emit(item)" >
    <ng-container [ngTemplateOutlet]="itemRef" [ngTemplateOutletContext]="{ item:item }">
    </ng-container>
  </div>
</ng-template>

<ng-template #nonSelectableRef let-item="item" let-isLast='isLast'>
  <div
    [attr.role]="itemRole"
    [ngClass]="getItemClass(isLast)"
    [wcdTooltip]="item.tooltip" >
    <ng-container [ngTemplateOutlet]="itemRef" [ngTemplateOutletContext]="{ item:item }"></ng-container>
  </div>
</ng-template>

<ng-template #itemRef let-item="item">
  <div
    class="key wcd-text-overflow-ellipsis-nowrap"
    [class.wcd-margin-large-right]="orientation === FlexDirection.Vertical"
    [class.wcd-margin-xsmall-right]="orientation === FlexDirection.Horizontal"
  >
    <wcd-shared-icon
      [iconName]="item.iconName || 'square'"
      [iconColor]="item.iconColorName"
      [ngStyle]="item.iconColor ? { color: item.iconColor } : null"
      [ngClass]="item.iconClassName"
    ></wcd-shared-icon>
    <span *ngIf="item.name">{{ item.name }}</span>
    <span *ngIf="item.nameHtml" [innerHTML]="item.nameHtml">></span>
    <wcd-help *ngIf="item.helpKey" [text]="item.helpKey | i18n"></wcd-help>
  </div>
  <div *ngIf="showValues" class="text-right value nowrap">
    {{ formatter(item.value) }}
  </div>
</ng-template>
