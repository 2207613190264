import { Injectable, OnDestroy } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { TelemetryEvent } from './models/telemetry-event.model';
import { ErrorEvent } from './models/error-event.model';

@Injectable({ providedIn: 'root' })
export class TelemetryService implements OnDestroy {
	private readonly _events$ = new ReplaySubject<TelemetryEvent<any>>();
	private readonly _errors$ = new ReplaySubject<ErrorEvent<Error>>();

	readonly events$ = this._events$.asObservable();
	readonly errors$ = this._errors$.asObservable();

	trackEvent<TPayload = never>(event: TelemetryEvent<TPayload>) {
		this._events$.next(event);
	}

	trackError<TError extends Error>(errorEvent: ErrorEvent<TError>) {
		this._errors$.next(errorEvent);
	}

	ngOnDestroy(): void {
		this._events$.complete();
		this._errors$.complete();
	}
}
