import { BreadcrumbsResolver } from '../../../breadcrumbs/services/breadcrumbs-resolver.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Paris, EntityModelBase } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { BreadcrumbsService } from '../../../breadcrumbs/services/breadcrumbs.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { EntityIdRouteService } from '../../../global_entities/services/entity-id-route.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { FileEntityResolver } from './file.entity.resolver';
import { File } from '@wcd/domain';
import { AppContextService } from '@wcd/config';
import { Router } from '@angular/router';
import { RoutesService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class FileBreadcrumbsResolver extends BreadcrumbsResolver<File> {
	constructor(
		paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		breadcrumbsService: BreadcrumbsService,
		activatedEntity: ActivatedEntity,
		entityIdRouteService: EntityIdRouteService,
		appContext: AppContextService,
		private fileEntityResolver: FileEntityResolver,
		router: Router,
		routesService: RoutesService,
		i18nService: I18nService
	) {
		super(
			paris,
			globalEntityTypesService,
			breadcrumbsService,
			activatedEntity,
			entityIdRouteService,
			appContext,
			router,
			routesService,
			i18nService
		);
	}

	protected getEntity(
		entityId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<EntityModelBase> {
		// resolve file name from query parameters
		return this.fileEntityResolver.resolve(route, state);
	}
}
