import {EntityField, ModelBase, ValueObject} from '@microsoft/paris';

@ValueObject({
	singularName: 'Active trend item',
	pluralName: 'Active trend items',
	readonly: true,
})
export class ActiveTrendItem extends ModelBase {
	@EntityField({data: ['timeUtc', 'TimeUtc']})
	timeUtc: Date;

	@EntityField({data: ['count', 'Count']})
	count: number;
}
