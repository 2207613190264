/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i2 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i3 from "@angular/cdk/a11y";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i6 from "@angular/common";
import * as i7 from "./top-exposed-machines.widget";
import * as i8 from "../../../../shared-reports/services/reports.service";
import * as i9 from "../../../services/tvm-exposure-score.service";
import * as i10 from "../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i11 from "../../../../@entities/@tvm/asset/services/asset.fields.service";
import * as i12 from "../../../services/tvm-texts.service";
import * as i13 from "../../../services/tvm-machine-groups-filter.service";
import * as i14 from "@angular/router";
import * as i15 from "@microsoft/paris/dist/lib/paris";
var styles_TvmTopExposedMachinesWidget = [];
var RenderType_TvmTopExposedMachinesWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_TvmTopExposedMachinesWidget, data: {} });
export { RenderType_TvmTopExposedMachinesWidget as RenderType_TvmTopExposedMachinesWidget };
function View_TvmTopExposedMachinesWidget_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "wcd-datatable", [], null, [[null, "itemClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("itemClick" === en)) {
        var pd_0 = (_co.openMachinePage($event.item) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_DataTableComponent_0, i1.RenderType_DataTableComponent)), i0.ɵdid(3, 13352960, null, 0, i2.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.LiveAnnouncer, i4.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], fixedTable: [3, "fixedTable"], fluidHeaderWidth: [4, "fluidHeaderWidth"] }, { itemClick: "itemClick" }), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 3, "a", [["data-track-id", "OpenMachinesPage"], ["data-track-type", "Navigation"], ["tabindex", "0"]], [[1, "aria-label", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openMachinesPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵppd(7, 1), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _v.context.ngIf; var currVal_1 = false; var currVal_2 = _co.fields; var currVal_3 = false; var currVal_4 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, function (_ck, _v) { var currVal_5 = i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v.parent, 0), "tvm.dashboard.showMore.exposedMachines")); _ck(_v, 6, 0, currVal_5); var currVal_6 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), "common.showMore")); _ck(_v, 8, 0, currVal_6); }); }
export function View_TvmTopExposedMachinesWidget_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i5.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_TvmTopExposedMachinesWidget_1)), i0.ɵdid(3, 16384, null, 0, i6.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i6.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.data$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_TvmTopExposedMachinesWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "top-exposed-machines-widget", [], null, null, null, View_TvmTopExposedMachinesWidget_0, RenderType_TvmTopExposedMachinesWidget)), i0.ɵdid(1, 245760, null, 0, i7.TvmTopExposedMachinesWidget, [i8.ReportsService, i4.I18nService, i9.TvmExposureScoreService, i10.PrettyNumberService, i11.AssetFieldsService, i12.TvmTextsService, i13.TvmMachineGroupsFilterService, i14.Router, i15.Paris], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TvmTopExposedMachinesWidgetNgFactory = i0.ɵccf("top-exposed-machines-widget", i7.TvmTopExposedMachinesWidget, View_TvmTopExposedMachinesWidget_Host_0, {}, {}, []);
export { TvmTopExposedMachinesWidgetNgFactory as TvmTopExposedMachinesWidgetNgFactory };
