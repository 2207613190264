import { NgModule } from '@angular/core';
import { ImpactedEntitiesCounterComponent } from './components/impacted-entities-counter/impacted-entities-counter.component';
import { FabIconModule, FabSpinnerModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { ImpactedEntitiesDatatablesComponent } from './components/impacted-entities-datatables/impacted-entities-datatables.component';
import { DataTableModule } from '@wcd/datatable';
import { SharedModule } from '../shared/shared.module';
import { ImpactedEntitiesPanelComponent } from './components/impacted-entities-panel.component';
import { FabHoverCardModule} from '@angular-react/fabric';
import { ImpactedEntitiesLinkedByComponent} from './components/impacted-entities-linked-by/impacted-entities-linked-by.component';
import { GlobalEntitiesModule} from '../global_entities/global-entities.module'
@NgModule({
	imports: [CommonModule, FabIconModule, DataTableModule, SharedModule, FabSpinnerModule, FabHoverCardModule, GlobalEntitiesModule],
	declarations: [
		ImpactedEntitiesCounterComponent,
		ImpactedEntitiesDatatablesComponent,
		ImpactedEntitiesPanelComponent,
		ImpactedEntitiesLinkedByComponent,
	],
	entryComponents: [
		ImpactedEntitiesCounterComponent,
		ImpactedEntitiesPanelComponent,
		ImpactedEntitiesLinkedByComponent,
	],
	exports: [
		ImpactedEntitiesCounterComponent,
		ImpactedEntitiesDatatablesComponent,
		ImpactedEntitiesLinkedByComponent,
	],
	providers: [],
})
export class ImpactedEntitiesModule {}
