import { downgradeComponent, downgradeInjectable } from '@angular/upgrade/static';
import { AuthService } from '@wcd/auth';
import { AppComponent } from '../app.component';
import { AppContextService, FeaturesService, PreferencesSetupSettingsService } from '@wcd/config';
import { AppInsightsService } from '../insights/services/app-insights.service';
import { HybridRoutingService } from '../hybrid-routing.service';
import { AdminService } from '../admin/_base/services/admin.service';
import { TitleService } from '../shared/services/title.service';
import { LocaleConfigService } from '@wcd/localization';
import { MainAppStateService } from '../shared/main/services/main-app-state.service';
import { SearchService } from '../search/services/search.service';
import { ErrorsService } from '../shared/services/errors.service';
import { AppConfigService } from '@wcd/app-config';
import { IconsService } from '@wcd/icons';
import { MachinesService } from '../@entities/machines/services/machines.service';
import { UserRoleActionsService } from '../@entities/rbac/services/user-role-actions.service';
import { RiskScoreService } from './app/threatintel/services/seville.threatintel.machine.riskscore.service';
import { InvestigationsService } from '../@entities/investigations/services/investigations.service';
import { OfficeIntegrationService } from '../admin/services/office-integration.service';
import { LogonTypePipe } from '../@entities/machines/pipes/logon-type.pipe';
import { SuppressionRulesService } from '../@entities/suppression_rules/services/suppression-rules.service';
import { BreadcrumbsService } from '../breadcrumbs/services/breadcrumbs.service';
import { EntityPanelsService } from '../global_entities/services/entity-panels.service';
import { AlertsService } from '../@entities/alerts/services/alerts.service';
import { FilesBackendService } from '../@entities/files/services/files.backend.service';
import { TagsService } from '../tags/services/tags.service';
import { IpsService } from '../@entities/ips/services/ips.service';
import { UrlsService } from '../@entities/urls/services/urls.service';
import { FilesService } from '../@entities/files/services/files.service';
import { UsersService } from '../@entities/users/services/users.service';
import { SevilleDowngradeUtilsService } from './seville.downgrade-utils.service';
import { PanelService } from '@wcd/panels';
import { AskThreatExpertService } from '../feedback/services/ask-threat-expert.service';

declare let angular: angular.IAngularStatic;

class SevilleAuthProvider {
	$get: Array<string | Function>;

	constructor() {
		this.$get = [
			'authService',
			(authService: AuthService) => {
				return authService;
			},
		];
	}
}

// WHEN adding a new service here - please add a mock of it in 'seville.downgrade.mock.ts' to support our karma-ui tests
// and run 'npm run test.ng1' to validate your service did not fail any test
export let SevilleDowngradeModule = angular
	.module('Downgrade', [])
	.factory('authService', downgradeInjectable(AuthService))
	.factory('featuresService', downgradeInjectable(FeaturesService))
	.provider('authServiceProvider', SevilleAuthProvider)
	.directive('app', downgradeComponent({ component: AppComponent }))
	.factory('appConfig', downgradeInjectable(AppConfigService))
	.factory('appInsights', downgradeInjectable(AppInsightsService))
	.factory('ng2router', downgradeInjectable(HybridRoutingService))
	.factory('adminService', downgradeInjectable(AdminService))
	.factory('titleService', downgradeInjectable(TitleService))
	.factory('localeConfigService', downgradeInjectable(LocaleConfigService))
	.factory('mainAppStateService', downgradeInjectable(MainAppStateService))
	.factory('searchService', downgradeInjectable(SearchService))
	.factory('investigationsService', downgradeInjectable(InvestigationsService))
	.factory('errorsService', downgradeInjectable(ErrorsService))
	.factory('iconsService', downgradeInjectable(IconsService))
	.factory('machinesService', downgradeInjectable(MachinesService))
	.factory('suppressionRulesService2', downgradeInjectable(SuppressionRulesService))
	.factory('riskScoreService', downgradeInjectable(RiskScoreService))
	.factory('ipsService', downgradeInjectable(IpsService))
	.factory('urlsService', downgradeInjectable(UrlsService))
	.factory('filesService', downgradeInjectable(FilesService))
	.factory('usersService', downgradeInjectable(UsersService))
	.factory('userRoleActionsService', downgradeInjectable(UserRoleActionsService))
	.factory('preferencesSetupSettingService', downgradeInjectable(PreferencesSetupSettingsService))
	.factory('officeIntegrationService', downgradeInjectable(OfficeIntegrationService))
	.factory('breadcrumbsService', downgradeInjectable(BreadcrumbsService))
	.factory('logonTypePipe', downgradeInjectable(LogonTypePipe))
	.factory('entityPanelsService', downgradeInjectable(EntityPanelsService))
	.factory('alertsService', downgradeInjectable(AlertsService))
	.factory('filesBackendService', downgradeInjectable(FilesBackendService))
	.factory('tagsService', downgradeInjectable(TagsService))
	.factory('sevilleDowngradeUtilsService', downgradeInjectable(SevilleDowngradeUtilsService))
	.factory('askTheExpertService', downgradeInjectable(AskThreatExpertService))
	.factory('panelService', downgradeInjectable(PanelService))
	.factory('appContextService', downgradeInjectable(AppContextService));
