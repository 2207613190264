import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import {
	MailClusterUtils,
	getMailClusterMailboxCount,
	getMailClusterNotInMailboxCount,
	getMailClusterOnPremOrExternalCount,
	getMailClusterInternalIdFromUrns,
} from '../../mailbox/mail-cluster.utils';
import {
	OfficeDeliveryLocation,
	OfficeProtectionStatus,
	OfficeThreatTypes,
} from '../../investigation/actions/office-action.utils';
import { dateStrToUtc } from '../../utils';

interface EmailClusterThreats {
	threats: string;
	malwareCount: number;
	phishCount: number;
	highConfidencePhishCount: number;
	spamCount: number;
}

interface EmailClusterDeliveryLocations {
	mailboxCount: number;
	notInMailboxCount: number;
	onPremOrExternalCount: number;
}

interface EmailClusterOriginalDeliveryLocations {
	deliveredCount: number;
	junkedCount: number;
	replacedCount: number;
	blockedCount: number;
}

@Entity({
	...airsEntityConfig,
	singularName: 'Email Cluster',
	pluralName: 'Email Clusters',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsEmailCluster,
})
export class AirsEmailCluster extends AirsEntity {
	@EntityField({ data: 'network_message_ids' })
	networkMessageIds: Array<string>;

	/*
		TODO:
		 For 'count_by_threat_type' & 'count_by_protection_status' & 'count_by_delivery_location' fields
		 BE returns invalid json string (with '' and not "").
		 it should be returned as object - fix this code after BE fix (bug: https://dev.azure.com/microsoft/OS/_workitems/edit/29776213)
	 */
	@EntityField({
		data: 'count_by_threat_type',
		parse: threatTypes => {
			if (typeof threatTypes === 'string') {
				// Note: since the keys and values are known we can use this hack
				const convertedThreatTypes = threatTypes && threatTypes.replace(/'/g, '"');
				return convertedThreatTypes ? JSON.parse(convertedThreatTypes) : null;
			}

			return threatTypes;
		},
	})
	private countByThreatType: { [key in OfficeThreatTypes]: number };

	@EntityField({
		data: 'count_by_delivery_location',
		parse: deliveryLocations => {
			if (typeof deliveryLocations === 'string') {
				// Note: since the keys and values are known we can use this hack
				const convertedDeliveryLocations = deliveryLocations && deliveryLocations.replace(/'/g, '"');
				return convertedDeliveryLocations ? JSON.parse(convertedDeliveryLocations) : null;
			}

			return deliveryLocations;
		},
	})
	private countByDeliveryLocation: { [key in OfficeDeliveryLocation]: number };

	@EntityField({
		data: 'count_by_protection_status',
		parse: protectionStatuses => {
			if (typeof protectionStatuses === 'string') {
				// Note: since the keys and values are known we can use this hack
				const convertedProtectionStatuses =
					protectionStatuses && protectionStatuses.replace(/'/g, '"');

				return convertedProtectionStatuses ? JSON.parse(convertedProtectionStatuses) : null;
			}

			return protectionStatuses;
		},
	})
	private countByProtectionStatus: { [key in OfficeProtectionStatus]: number };

	@EntityField()
	threats: string;

	@EntityField()
	query: string;

	@EntityField({ data: 'query_time', parse: dateStr => dateStrToUtc(dateStr) })
	queryTime: Date;

	@EntityField({ data: 'mail_count' })
	mailCount: number;

	@EntityField()
	source: string;

	@EntityField({ data: 'volume_anomaly' })
	volumeAnomaly: boolean;

	@EntityField({ data: 'clustered_by' })
	clusteredBy: string;

	@EntityField({ data: 'cluster_by_value' })
	clusterByValue: string;

	@EntityField({
		data: 'mdo_internal_id',
	})
	mdoInternalId: string; // represent 'InvestigationId' of the email in office

	@EntityField({
		data: 'mdo_remediation_id',
	})
	mdoRemediationId: string;

	@EntityField({
		data: 'mdo_batch_id',
	})
	mdoBatchId: string;

	emailClusterThreats: EmailClusterThreats;
	emailClusterDeliveryLocations: EmailClusterDeliveryLocations;
	emailClusterOriginalDeliveryLocations: EmailClusterOriginalDeliveryLocations;

	showClusterAdditionalData: boolean = false;

	constructor(data) {
		super(data);

		let isClusterThreatsExists = false;
		let isClusterDeliveryLocationsExists = false;
		let isClusterOriginalDeliveryLocationsExists = false;

		if (this.countByThreatType) {
			this.emailClusterThreats = {
				threats: this.threats,
				malwareCount: this.countByThreatType[OfficeThreatTypes.Malware],
				phishCount: this.countByThreatType[OfficeThreatTypes.Phish],
				highConfidencePhishCount: this.countByThreatType[OfficeThreatTypes.HighConfidencePhish],
				spamCount: this.countByThreatType[OfficeThreatTypes.Spam],
			};

			isClusterThreatsExists = Object.values(this.emailClusterThreats).some(data => Boolean(data));
		}

		if (this.countByDeliveryLocation) {
			this.emailClusterDeliveryLocations = {
				mailboxCount: getMailClusterMailboxCount(this.countByDeliveryLocation),
				notInMailboxCount: getMailClusterNotInMailboxCount(this.countByDeliveryLocation),
				onPremOrExternalCount: getMailClusterOnPremOrExternalCount(this.countByDeliveryLocation),
			};

			isClusterDeliveryLocationsExists = Object.values(this.emailClusterDeliveryLocations).some(data =>
				Boolean(data)
			);
		}

		if (this.countByProtectionStatus) {
			this.emailClusterOriginalDeliveryLocations = {
				deliveredCount: this.countByProtectionStatus[OfficeProtectionStatus.Delivered],
				junkedCount: this.countByProtectionStatus[OfficeProtectionStatus.DeliveredAsSpam],
				replacedCount: this.countByProtectionStatus[OfficeProtectionStatus.Replaced],
				blockedCount: this.countByProtectionStatus[OfficeProtectionStatus.Blocked],
			};

			isClusterOriginalDeliveryLocationsExists = Object.values(
				this.emailClusterOriginalDeliveryLocations
			).some(data => Boolean(data));
		}

		this.showClusterAdditionalData =
			isClusterThreatsExists ||
			isClusterDeliveryLocationsExists ||
			isClusterOriginalDeliveryLocationsExists;

		this.entityName = MailClusterUtils.getDisplayQuery(this.clusteredBy, this.clusterByValue);

		const investigationId = this.investigation && this.investigation.id;

		/*
			'mdoInternalId' is NOT empty in case of Admin playbook.
			Once the 'Admin playbook' is open to all customer this calculation can be removed
			because this field will not be empty.
		 */
		this.mdoInternalId =
			this.mdoInternalId ||
			(typeof investigationId === 'string'
				? getMailClusterInternalIdFromUrns(<string>investigationId)
				: null);
	}
}
