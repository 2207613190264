<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-margin-small-bottom" *ngIf="(associatedIncidents$ | async)?.length">
	<fab-message-bar
		[messageBarType]="MessageBarType.info"
		[isMultiline]="false"
		[truncated]="true"
		[overflowButtonAriaLabel]="'incident.associatedIncidents.moreInfo' | i18n"
		>{{ 'incident.associatedIncidents.infoBox' | i18n }}</fab-message-bar>
	<dl role="none" class="wcd-margin-medium-top">
		<dt role="none">{{ 'incident.associatedIncidents.title' | i18n }}</dt>
		<dd role="none">
			<wcd-datatable
				[columns]="associatedIncidentsFields.fields"
				[items]="(associatedIncidents$ | async)"
				[selectEnabled]="false"
				[fixedTable]="true"
				[label]="'incident.associatedIncidents.title' | i18n"
				[isItemClickable]="isItemClickable"
			>
			</wcd-datatable>
			<a *ngIf="hasMore" class="wcd-font-size-m wcd-margin-small-bottom" (click)="showAllIncidents()" role="button">
				{{ 'incident.associatedIncidents.viewMore' | i18n }}
			</a>
		</dd>
	</dl>
</div>
