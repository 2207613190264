import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Incident graph grouping logics',
	pluralName: 'Incident graph grouping logics',
	readonly: true,
})
export class IncidentGraphGroupingLogics extends ModelBase {
	@EntityField({ data: 'Type' })
	type: 'Signer' | 'FileContent' | 'Hostname' | 'FileName' | 'IpAddress' | 'RegistryKey';

	@EntityField({ data: 'IsSameValue' })
	isSameValue: boolean;
}
