import { Injectable } from '@angular/core';
import { EntityType } from '../../../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../../../global_entities/models/entity-type-service.interface';
import { Machine, Tag, SoftwareVersion } from '@wcd/domain';
import { SoftwareVersionEntityPanelComponent } from '../components/software-entity/software-version.entity-panel.component';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';

@Injectable()
export class SoftwareVersionEntityTypeService implements EntityTypeService<SoftwareVersion> {
	constructor(private readonly tvmTagsService: TvmTagsService) {}

	readonly entityType: EntityType<SoftwareVersion> = {
		id: 'software-version',
		entity: SoftwareVersion,
		icon: FabricIconNames.System,
		getEntityName: (softwareVersion: SoftwareVersion) => `Version ${softwareVersion.version}`,
		singleEntityPanelComponentType: SoftwareVersionEntityPanelComponent,
		getEntityDataviewLink: () => '/software-inventory',
		loadFullEntityInPanel: false,
		getTags: (version: SoftwareVersion[]) => this.tvmTagsService.getSoftwareVersionTags(version[0]),
	};
}
