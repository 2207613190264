import { ApiCall, ApiCallModel, DataQuery } from '@microsoft/paris';
import { Alert } from '../alert.entity';
import { Incident } from '../../incident/incident.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ApiCall({
	name: 'Link alert to incident',
	endpoint: 'alerts/incidentLinks',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['newApi'] ? config.data.serviceUrls.alertsLinks : config.data.serviceUrls.threatIntel;
	},	
    parseQuery: (alertLinksConfig: AlertLinksConfig) => {
		return {
			data: {
				AlertIds: alertLinksConfig.alertIds,
				IncidentId: alertLinksConfig.incidentId,
				Comment: alertLinksConfig.comment,
			},
			params: {
				newApi: alertLinksConfig.newApi,
			}
		};
	},
})
export class AlertLinksApiCall extends ApiCallModel<void, AlertLinksConfig> {}

export interface AlertLinksConfig {
	alertIds: Array<Alert['id']>;
	incidentId: Incident['id'];
	comment: string;
	newApi: boolean
}
