import { Component, Input } from '@angular/core';
import { DataviewField } from '@wcd/dataview';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SystemExclusion, SystemExclusionListType, SystemExclusionType } from '@wcd/domain';

@Component({
	selector: 'system-exclusion-edit-modal',
	template: `
		<wcd-panel (close)="onCancel()" [settings]="settings">
			<system-exclusion-edit
				[systemExclusion]="systemExclusion"
				[systemExclusionType]="systemExclusionType"
				[systemExclusionListTypes]="systemExclusionListTypes"
				[editableProperties]="editableProperties"
				[formIsDirtyOnInit]="formIsDirtyOnInit"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(done)="onDone($event)"
				(cancel)="onCancel()"
			></system-exclusion-edit>
		</wcd-panel>
	`,
})
export class SystemExclusionEditModalComponent extends PanelContainer {
	@Input() onDone: Function;
	@Input() onCancel: Function;
	@Input() systemExclusion: SystemExclusion;
	@Input() systemExclusionType: SystemExclusionType;
	@Input() systemExclusionListTypes: Array<SystemExclusionListType>;
	@Input() editableProperties: Array<DataviewField>;
	@Input() formIsDirtyOnInit: boolean = false;

	constructor(protected router: Router) {
		super(router);
	}
}
