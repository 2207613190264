import { Component } from '@angular/core';
import { UserAlertsSummaryWidgetComponent } from './widgets/user-alerts-summary-widget.component';
import { UserDeviceListWidgetComponent } from './widgets/user-device-list-widget.component';
import { ReportModel } from '../../../reports/models/report.model';

export const userReportModel = new ReportModel({
	id: 'user-entity-widgets',
	allowRangeSelect: false,
	allowExport: false,
	enablePadding: false,
	overflow: 'vertical',
	refreshOnQueryParamsChange: false,
	rows: [
		{
			columns: [
				{ widgets: [UserAlertsSummaryWidgetComponent], weight: 2 },
				{ widgets: [UserDeviceListWidgetComponent], weight: 1 },
			],
		},
	],
	transparent: true,
});

@Component({
	selector: 'user-overview',
	template: `
		<report [report]="report" [showPollingError]="false" [showControls]="false"> </report>
	`,
})
export class UserOverviewComponent {
	report: ReportModel;

	constructor() {
		this.report = userReportModel;
	}
}
