import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import {
	ChangeDetectionStrategy,
	Component,
	ChangeDetectorRef,
	Injector,
	ViewChild,
	OnDestroy,
	OnInit,
} from '@angular/core';
import {
	HuntingRule,
	MachineGroup,
	HuntingRuleCustomSupportedActionType,
	HuntingRuleCustomAction,
	ScheduledHuntingRuleActionRelationship,
	CustomActionRun,
	HuntingRuleImpactedEntity,
	Outbreak,
} from '@wcd/domain';
import { EntityDetailsComponentBase } from '../../../global_entities/components/entity-details/entity-details.component.base';
import { orderBy, lowerFirst } from 'lodash-es';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { ScheduledHuntingActionEntityTypeService } from '../services/scheduled-hunting-action.entity-type.service';
import { ScheduledHuntingActionsFields } from '../scheduled-hunting.actions.fields';
import { DataTableComponent, DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { NavigationEnd, Router, UrlTree } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { AppConfigData } from '@wcd/app-config';
import { tenantContextCache } from '@wcd/auth';
import { AppFlavorConfig } from '@wcd/scc-common';

const MAX_LAST_RUN_ACTIONS = 5;

enum CollapsibleID {
	AlertDetails = 'scheduled-hunting-entity-alert-details',
	ExecutionDetails = 'scheduled-hunting-entity-execution-details',
	LastRunActions = 'scheduled-hunting-entity-last-run-actions',
	MachineGroups = 'scheduled-hunting-entity-machine-groups',
}

@Component({
	selector: 'scheduled-hunting-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './scheduled-hunting.entity-details.component.html',
})
export class ScheduledHuntingEntityDetailsComponent
	extends EntityDetailsComponentBase<HuntingRule>
	implements OnInit, OnDestroy {
	private _ruleMachineGroups: MachineGroup[];
	readonly rbacMachineGroupsFeatureEnabled: boolean;
	readonly supportedCustomActionTypes = HuntingRuleCustomSupportedActionType;
	readonly customActionsFeatureEnabled: boolean;
	readonly threatAnalyticsCustomDetectionFeatureEnabled: boolean;
	readonly showImpactedEntities: boolean;
	readonly actionTypes = [
		this.supportedCustomActionTypes.IsolateMachine,
		this.supportedCustomActionTypes.CollectInvestigationPackage,
		this.supportedCustomActionTypes.RunAntivirusScan,
		this.supportedCustomActionTypes.InitiateInvestigation,
		this.supportedCustomActionTypes.AllowFile,
		this.supportedCustomActionTypes.BlockFile,
		this.supportedCustomActionTypes.QuarantineFile,
	];
	ruleActionsRepository: RelationshipRepository<HuntingRule, CustomActionRun>;
	lastRunActions: CustomActionRun[] = [];
	moreLastRunActionsExist: boolean;
	showingAllActions: boolean = false;

	@ViewChild(DataTableComponent, { static: false }) dataTable: DataTableComponent;

	collapsibleID = CollapsibleID;
	routerSubscription: Subscription;

	getThreatAnalyticsReportText$: Observable<any>;

	constructor(
		injector: Injector,
		appContextService: AppContextService,
		private changeDetectorRef: ChangeDetectorRef,
		private readonly featuresService: FeaturesService,
		private readonly paris: Paris,
		private readonly _fields: ScheduledHuntingActionsFields,
		private readonly scheduledHuntingActionEntityTypeService: ScheduledHuntingActionEntityTypeService,
		private readonly i18nService: I18nService,
		private readonly router: Router
	) {
		super(injector);
		const tenantContext: AppConfigData = tenantContextCache.appConfig;

		this.rbacMachineGroupsFeatureEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
		this.customActionsFeatureEnabled = featuresService.isEnabled(Feature.HuntingCustomActions);
		this.showImpactedEntities =
			appContextService.isMtp && featuresService.isEnabled(Feature.HuntingCustomDetectionUsingEvidence);

		this.threatAnalyticsCustomDetectionFeatureEnabled =
			featuresService.isEnabled(Feature.ThreatAnalyticsCustomDetection) &&
			((tenantContext.IsMdatpActive &&
				AppFlavorConfig.threatAnalytics.mde.mdeFlavors.includes(tenantContext.MdeFlavor)) ||
				tenantContext.IsOatpActive);

		this.ruleActionsRepository = paris.getRelationshipRepository(ScheduledHuntingRuleActionRelationship);
	}

	get rule(): HuntingRule {
		return this.entity;
	}

	get fields(): DataTableField<CustomActionRun, any>[] {
		return this._fields.dataTableFields;
	}

	get allActionRunsLink(): string {
		return this.scheduledHuntingActionEntityTypeService.entityType.getEntitiesLink(this.lastRunActions);
	}

	trackByAction(index, item: HuntingRuleCustomAction) {
		return item.actionType;
	}

	get ruleMachineGroups(): MachineGroup[] {
		if (!this._ruleMachineGroups) {
			//we have to remove undefined machine groups because of a server bug that
			//returns deleted machine group ids in the hunting rule
			this._ruleMachineGroups = orderBy(this.entity.machineGroups.filter(Boolean), [
				(machineGroup) => machineGroup.name.toLowerCase(),
			]);
		}

		return this._ruleMachineGroups;
	}

	get alertCategoryText(): string {
		return this.i18nService.get('reporting.alertsByCategory.' + lowerFirst(this.rule.alertCategory));
	}

	ngOnInit() {
		this.routerSubscription = this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.showingAllActions = this.setShowingAllAction(event.url);
				this.changeDetectorRef.detectChanges();
			});

		this.showingAllActions = this.setShowingAllAction(this.router.url);
		this.changeDetectorRef.detectChanges();
		this.getThreatAnalyticsReportText$ = this.paris
			.getItemById(Outbreak, this.rule.threatId)
			.pipe(take(1));
	}

	ngOnDestroy() {
		if (this.routerSubscription) this.routerSubscription.unsubscribe();
	}

	setShowingAllAction(url) {
		return url.split('?')[0].split('/').pop() == 'actions';
	}

	updateTable() {
		if (this.dataTable) {
			this.dataTable.updateHeaderCells();
		}
	}

	setEntity(entity: HuntingRule) {
		super.setEntity(entity);
		this.changeDetectorRef.markForCheck();
		this.ruleActionsRepository.sourceItem = this.rule;
		this.ruleActionsRepository.query({ where: { range: 180, lastRunOnly: true } }).subscribe((data) => {
			this.lastRunActions = data.items.slice(0, MAX_LAST_RUN_ACTIONS);
			this.moreLastRunActionsExist = data.items.length > MAX_LAST_RUN_ACTIONS;
			this.changeDetectorRef.markForCheck();
		});
	}

	getImpactedEntityName(entity: HuntingRuleImpactedEntity): string {
		return this.i18nService.get(
			'hunting.scheduledMonitorSidePane.wizardSections.entities.' + lowerFirst(entity.entityType)
		);
	}

	showAllActionRuns() {
		this.router.navigateByUrl(this.allActionRunsLink);
	}
}
