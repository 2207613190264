import { Pipe, PipeTransform } from '@angular/core';
import { memoize } from 'lodash-es';
import { ExposureScoreCategory } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

const machineExposureLevelDisplayKeysMap: Record<ExposureScoreCategory, string> = {
	[ExposureScoreCategory.Low]: 'machineExposureLevel.low',
	[ExposureScoreCategory.Medium]: 'machineExposureLevel.medium',
	[ExposureScoreCategory.High]: 'machineExposureLevel.high',
};

@Pipe({
	name: 'machineExposureLevelTypeDisplayText',
})
export class MachineExposureLevelTypeDisplayTextPipe implements PipeTransform {
	constructor(private readonly i18nService: I18nService) {
		this.transform = memoize(this.transform);
	}

	transform(value: ExposureScoreCategory): string | null {
		if (!(value in machineExposureLevelDisplayKeysMap)) {
			return null;
		}

		return this.i18nService.get(machineExposureLevelDisplayKeysMap[value]);
	}
}
