var DetailsListComponent = /** @class */ (function () {
    function DetailsListComponent() {
        this.display = 0 /* GRID */;
    }
    Object.defineProperty(DetailsListComponent.prototype, "columnar", {
        get: function () {
            return this.display === 1 /* COLUMN */;
        },
        enumerable: true,
        configurable: true
    });
    return DetailsListComponent;
}());
export { DetailsListComponent };
