import { Pipe, PipeTransform } from '@angular/core';
import { I18nService } from '../services/i18n.service';

@Pipe({ name: 'i18n' })
export class I18nPipe implements PipeTransform {
	constructor(private i18nService: I18nService) {}

	transform(keyPath: string, params?: any, optional?: boolean) {
		return this.i18nService.get(keyPath, params, optional);
	}
}
