<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="recommendationExceptionAggregated && exceptionScopeText" class="wcd-scroll-vertical wcd-margin-medium-top">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">

        <div class="tvm-list__header">Recommendation exception details</div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.recommendationException.exceptionScope' | i18n}}</dt>
			<dd>{{exceptionScopeText}}</dd>
		</div>

        <div class="tvm-list__key_val_item">
			<dt>{{'tvm.recommendationException.type' | i18n}}</dt>
			<dd>{{exceptionTypeText}}</dd>
        </div>

		<div class="tvm-list__key_val_item">
			<dt>{{'tvm.common.status' | i18n}}</dt>
			<dd>
				<ul>
					<li *ngFor="let status of exceptionStatusTextList">
						{{status}}
					</li>
				</ul>
			</dd>
		</div>

		<div *ngIf="recommendationExceptionAggregated.exceptionArgs.type === 'SoftwarePatch'" class="tvm-list__key_val_item">
			<dt>{{'tvm.common.relatedComponent' | i18n}}</dt>
			<dd>
				<a
					data-track-id="exceptionNavigateToRelatedComponent"
					data-track-type="Navigation"
					(click)="navigateToRelatedComponent()">
					{{relatedComponent}}
				</a>
			</dd>
		</div>
	</div>

	<related-exceptions-details
		[sourceItem]="recommendationExceptionAggregated">
	</related-exceptions-details>

	<div class="tvm-list__block_item">
		<a
			data-track-id="navigateToRelatedRecommendation"
			data-track-type="Navigation"
			(click)="navigateToRelatedRecommendation()"
			>{{'tvm.common.viewRecommendation' | i18n}}
		</a>
	</div>
</div>
