import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FabButtonModule, FabContextualMenuModule } from '@angular-react/fabric';
import { DateTimePickerModule } from '../date-time-picker.module';
import { CommandBarDatePickerComponent } from './command-bar-date-picker/command-bar-date-picker.component';

@NgModule({
	imports: [CommonModule, DateTimePickerModule, FabButtonModule, FabContextualMenuModule],
	declarations: [CommandBarDatePickerComponent],
	exports: [CommandBarDatePickerComponent],
})
export class DateTimePickerCommandBarModule {}
