import { EntityPanelsService } from './services/entity-panels.service';
import { EntityPanelComponent } from './components/entity-panels/entity-panel.component';
import { GlobalEntityTypesService } from './services/global-entity-types.service';
import { SharedModule } from '../shared/shared.module';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { TagsModule } from '../tags/tags.module';
import { PanelsModule } from '@wcd/panels';
import { GlobalEntitiesModule } from './global-entities.module';

@NgModule({
	imports: [SharedModule, TagsModule, PanelsModule, GlobalEntitiesModule],
	declarations: [EntityPanelComponent],
	exports: [EntityPanelComponent],
	entryComponents: [EntityPanelComponent],
})
export class GlobalEntityPanelsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: GlobalEntityPanelsModule,
			providers: [EntityPanelsService, GlobalEntityTypesService],
		};
	}
}
