/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./details-tab.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../global_entities/components/entity-panels/entity-panel.component.ngfactory";
import * as i3 from "../../../global_entities/components/entity-panels/entity-panel.component";
import * as i4 from "@angular/router";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../rbac/services/rbac.service";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/common";
import * as i10 from "./details-tab.component";
import * as i11 from "../services/alert-page.service";
var styles_DetailsTabComponent = [i0.styles];
var RenderType_DetailsTabComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DetailsTabComponent, data: {} });
export { RenderType_DetailsTabComponent as RenderType_DetailsTabComponent };
function View_DetailsTabComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 5, "entity-panel", [["contentClass", "alert-entity-panel-adjust-for-zoom"]], null, null, null, i2.View_EntityPanelComponent_0, i2.RenderType_EntityPanelComponent)), i1.ɵdid(3, 770048, null, 0, i3.EntityPanelComponent, [i4.Router, i1.ComponentFactoryResolver, i5.Paris, i1.ChangeDetectorRef, i6.I18nService, i7.RbacService, i8.LiveAnnouncer], { settings: [0, "settings"], entity: [1, "entity"], entities: [2, "entities"], entityType: [3, "entityType"], options: [4, "options"], extendEntity: [5, "extendEntity"], disableTrapFocus: [6, "disableTrapFocus"], disableAutoFocus: [7, "disableAutoFocus"], contentClass: [8, "contentClass"] }, null), i1.ɵpad(4, 1), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), i1.ɵpod(6, { hideOpenLink: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entityPanelSettings; var currVal_1 = _v.context.ngIf.entity; var currVal_2 = _ck(_v, 4, 0, _v.context.ngIf.entity); var currVal_3 = _v.context.ngIf.entityType; var currVal_4 = _ck(_v, 6, 0, ((_v.context.ngIf.entityTypeName == _co.TreeElementCyberEntityTypeEnum.alert) && (_v.context.ngIf.entity.id == i1.ɵunv(_v, 3, 4, i1.ɵnov(_v, 5).transform(_co.mainAlertPageId$))))); var currVal_5 = false; var currVal_6 = true; var currVal_7 = true; var currVal_8 = "alert-entity-panel-adjust-for-zoom"; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8); }, null); }
export function View_DetailsTabComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_DetailsTabComponent_1)), i1.ɵdid(2, 16384, null, 0, i9.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i9.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.detailsTab$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_DetailsTabComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "details-tab", [], null, null, null, View_DetailsTabComponent_0, RenderType_DetailsTabComponent)), i1.ɵdid(1, 49152, null, 0, i10.DetailsTabComponent, [i11.AlertPageService], null, null)], null, null); }
var DetailsTabComponentNgFactory = i1.ɵccf("details-tab", i10.DetailsTabComponent, View_DetailsTabComponent_Host_0, {}, {}, []);
export { DetailsTabComponentNgFactory as DetailsTabComponentNgFactory };
