
		<div class="external-map">
			<div class="map" *ngIf="isMapAvailable">
				<iframe [src]="mapUrl | safeUrl" (load)="onIframeLoad()"></iframe>
			</div>
			<div class="alert alert-danger" *ngIf="!isMapAvailable">
				<wcd-shared-icon [iconName]="'error'" class="large-icon pull-left"> </wcd-shared-icon>
				<div class="with-large-icon">
					<div>{{ 'help.externalLoadError' | i18n: { asset: mapAsset } }}</div>
					<a target="_blank" [href]="externalMapUrl | safeUrl">location link</a>
				</div>
			</div>
		</div>
	