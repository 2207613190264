/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./airs-registry-key-details.component";
import * as i3 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_AirsRegistryKeyDetailsComponent = [];
var RenderType_AirsRegistryKeyDetailsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AirsRegistryKeyDetailsComponent, data: {} });
export { RenderType_AirsRegistryKeyDetailsComponent as RenderType_AirsRegistryKeyDetailsComponent };
function View_AirsRegistryKeyDetailsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dd", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_registryKey_fields_key; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entity.keyData; _ck(_v, 6, 0, currVal_1); }); }
function View_AirsRegistryKeyDetailsComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "dd", [["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.airsEntities_registryKey_fields_hive; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.entity.hive; _ck(_v, 6, 0, currVal_1); }); }
export function View_AirsRegistryKeyDetailsComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "dl", [["class", "key-values clearfix"], ["role", "none"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AirsRegistryKeyDetailsComponent_1)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AirsRegistryKeyDetailsComponent_2)), i0.ɵdid(7, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.entity.keyData; _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.entity.hive; _ck(_v, 7, 0, currVal_1); }, null); }
export function View_AirsRegistryKeyDetailsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "airs-registry-key-details", [], null, null, null, View_AirsRegistryKeyDetailsComponent_0, RenderType_AirsRegistryKeyDetailsComponent)), i0.ɵdid(1, 49152, null, 0, i2.AirsRegistryKeyDetailsComponent, [i3.I18nService], null, null)], null, null); }
var AirsRegistryKeyDetailsComponentNgFactory = i0.ɵccf("airs-registry-key-details", i2.AirsRegistryKeyDetailsComponent, View_AirsRegistryKeyDetailsComponent_Host_0, { entity: "entity" }, {}, []);
export { AirsRegistryKeyDetailsComponentNgFactory as AirsRegistryKeyDetailsComponentNgFactory };
