/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';

const KEY_CODES = {
	ENTER: 13,
	SPACE: 32,
};

@Component({
	selector: 'entity-panel-collapsible-section',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./entity-panel-collapsible-section.component.scss'],
	template: `
		<div *ngIf="loaded" class="wcd-border-top wcd-padding-top wcd-padding-bottom">
			<div
				class="wcd-flex-spaceBetween-horizontal"
				role="button"
				[attr.aria-expanded]="!collapsed"
				tabindex="0"
				(click)="toggleCollapsed()"
				(keypress)="toggleCollapsed($event)"
			>
				<label class="pointer header color-text-gray-700 wcd-font-weight-bold wcd-flex-1">
					{{ title }} {{ count === undefined ? '' : '(' + (count | prettyNumber) + ')' }}
				</label>
				<button
					data-track-id="tvm-export-to-csv"
					data-track-type="Button"
					[attr.data-track-value]="'Export to csv of ' + title"
					class="section-export-button wcd-flex-center-vertical"
					*ngIf="enableExport && export?.observers?.length > 0"
					(click)="export.emit(); $event.stopPropagation()"
					[wcdTooltip]="'tvm.common.exportToCSV' | i18n"
				>
					<wcd-shared-icon iconName="Download"> </wcd-shared-icon>
					<span>{{ 'export' | i18n }}</span>
				</button>
				<fab-icon contentClass="pointer" [iconName]="collapsed ? 'ChevronDown' : 'ChevronUp'">
				</fab-icon>
			</div>
			<div [hidden]="collapsed">
				<ng-content></ng-content>
			</div>
		</div>

		<div *ngIf="!loaded" class="wcd-padding-top wcd-padding-bottom">
			<fab-spinner [size]="SpinnerSize.large" [label]="loadingText"></fab-spinner>
		</div>
	`,
})
export class EntityPanelCollapsibleComponent {
	SpinnerSize = SpinnerSize;

	@Input() loaded = true;
	@Input() loadingText: string;
	@Input() title: string;
	@Input() count: number;
	@Input() enableExport: boolean = true;
	@Output() export = new EventEmitter<void>();
	@Output() onCollapseChange = new EventEmitter<Boolean>();

	@Input()
	set collapsed(collapsed: boolean) {
		// Allow input to set only the initial state
		if (this._collapsed === undefined) {
			this._collapsed = collapsed;
		}
	}

	get collapsed(): boolean {
		return this._collapsed === undefined ? true : this._collapsed;
	}
	private _collapsed: boolean;

	toggleCollapsed(e?: KeyboardEvent) {
		if (!e || (e && (e.charCode === KEY_CODES.ENTER || e.charCode === KEY_CODES.SPACE))) {
			this._collapsed = !this.collapsed;
			this.onCollapseChange.emit(this._collapsed);
		}
	}
}
