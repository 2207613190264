
		<div class="wcd-full-height">
			<dataview
				class="wcd-full-height"
				id="investigations"
				[repository]="investigationsRepo"
				[entityType]="entityType"
				[dataViewConfig]="dataviewConfig"
				[fields]="investigationFields.fields"
				[setItemActions]="setInvestigationActions"
				defaultSortFieldId="start_date"
				[fixedOptions]="fixedOptions"
				[disableSelection]="authService.currentUser.isReadonly"
				[disabledFields]="disabledFields"
				[disabledFilters]="disabledFilters"
				[visibleFields]="visibleFields"
				[label]="
					i18nService.strings.evaluation_dashboard_widgets_evaluationReportOverview_investigations
				"
			>
				<ng-container dataview-controls>
					<fancy-select
						[ngModel]="currentRange"
						(ngModelChange)="onRangeTypeSelect($event)"
						class="command-bar-item-dropdown"
						[buttonIcon]="'calendar'"
						[label]="'name'"
						[values]="timeRanges"
					></fancy-select>
					<daterange
						*ngIf="isCustomTimeRangeValue(currentRange) && !currentRange.customRange"
						[(ngModel)]="customDateRange"
						(ngModelChange)="onRangeSelect()"
						class="inline-daterange wcd-padding-small-left"
					></daterange>
				</ng-container>
				<div class="page-header" dataview-header>
					<h2>{{ i18nService.strings.investigations_title }}</h2>
				</div>
			</dataview>
		</div>
	