import { MailCluster } from '@wcd/domain';
import { Lazy } from '../../../utils/lazy';
import { FabricIconNames } from '@wcd/scc-common';
var MailClusterEntityTypeService = /** @class */ (function () {
    function MailClusterEntityTypeService() {
        this._entityType = new Lazy(function () { return ({
            entity: MailCluster,
            id: 'mailCluster',
            icon: FabricIconNames.CreateMailRule,
            getEntityName: function (mailCluster) { return mailCluster.name; },
            getEntitiesLink: function (mailClusters) {
                if (!mailClusters || mailClusters.length !== 1) {
                    return null;
                }
                var mailmailCluster = mailClusters[0];
                return mailmailCluster.mailClusterPageUrl;
            },
            getNavigationModel: function (mailCluster) { return ({
                externalLink: mailCluster.mailClusterPageUrl,
            }); },
            getUseExternalRouting: function (mailClusters) { return true; },
        }); });
    }
    Object.defineProperty(MailClusterEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return MailClusterEntityTypeService;
}());
export { MailClusterEntityTypeService };
