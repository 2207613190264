
		<ul
			class="wcd-radiolist"
			[ngClass]="{ 'wcd-flex-horizontal': isHorizontal }"
			role="radiogroup"
			#radiogroup
			[attr.aria-label]="ariaLabel"
		>
			<li
				*ngFor="let item of values; trackBy: trackItemById; let i = index"
				[attr.aria-label]="getItemLabel(item)"
				[class.checked]="isSelectedItem(item)"
				[class.disabled-completely]="isDisabled || item.disabled"
				[ngClass]="itemClassName"
				role="radio"
				[tabIndex]="getTabIndex(item)"
				(blur)="onBlur(item, i)"
				(focus)="onFocus(item)"
				(keydown.arrowDown)="keyboardNavigation(i + 1)"
				(keydown.arrowUp)="keyboardNavigation(i - 1)"
				(keydown.arrowRight)="keyboardNavigation(i + 1)"
				(keydown.arrowLeft)="keyboardNavigation(i - 1)"
				(keydown.enter)="selectValue(item)"
				(keydown.space)="selectValue(item)"
				[attr.aria-checked]="isSelectedItem(item)"
				(click)="selectValue(item)"
			>
				<label
					class="wcd-radiolist--label wcd-flex-horizontal-align-items-end"
					[attr.for]="'radio-' + name + '_' + item.id"
					[class.disabled-completely]="isDisabled || item.disabled"
				>
					<input
						type="radio"
						[name]="name"
						[id]="'radio-' + name + '_' + item.id"
						[attr.value]="item.id"
						(change)="selectValue(item)"
						[disabled]="isDisabled || item.disabled"
						[checked]="isSelectedItem(item)"
					/>
					<span class="wcd-radiolist--icon">
						<fab-icon [iconName]="getIconName(item)"></fab-icon>
					</span>
					<span class="wcd-radiolist--label--text">{{ getItemLabel(item) }}</span>
					<wcd-help
						(onFocus)="onHelpFocus()"
						(onBlur)="onHelpBlur()"
						*ngIf="item.helpText"
						[text]="item.helpText"
						[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
						[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
						[tabIndexValue]="this.focusedItem == item ? TAB_STOP : NO_TAB_STOP"
					></wcd-help>
					<wcd-help
						(onFocus)="onHelpFocus()"
						(onBlur)="onHelpBlur()"
						[attr.aria-hidden]="ariaHidden"
						*ngIf="!item.helpText && item.helpKey"
						[text]="getItemText(item.helpKey)"
						[wcdTooltipAllowHtmlRendering]="wcdTooltipAllowHtmlRendering"
						[wcdTooltipSanitizeHtml]="wcdTooltipSanitizeHtml"
						[tabIndexValue]="this.focusedItem == item ? TAB_STOP : NO_TAB_STOP"
					></wcd-help>
				</label>
				<div *ngIf="item.description" class="wcd-radiolist--description">
					{{ item.description }}
				</div>
			</li>
		</ul>
	