import { Injectable, Injector } from '@angular/core';
import { File, MatchType } from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { EntityType, FieldsService } from '../../../global_entities/models/entity-type.interface';
import { I18nService } from '@wcd/i18n';
import { Lazy } from '@wcd/utils';
import { FileEntityTypeService } from './file.entity-type.service';
import { FabricIconNames } from '@wcd/scc-common';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';

const matchTypeIconMap = new Map<MatchType, FabricIconNames>([
	[MatchType.Exact, FabricIconNames.CircleFill],
	[MatchType.ContainsOrEndsWith, FabricIconNames.CircleHalfFull],
]);

const matchTypeDisplayNameMap = new Map<MatchType, string>([
	[MatchType.Exact, 'search_exactMatch'],
	[MatchType.ContainsOrEndsWith, 'search_partialMatch'],
]);

@Injectable()
export class FilesFieldsService implements FieldsService<File> {
	private readonly _fields = new Lazy<ReadonlyArray<DataviewField<File>>>(() => {
		const fields: Array<DataviewFieldConfig<File>> = [
			{
				id: 'filename',
				name: this.i18nService.get('files.fields.fileName.title'),
				getDisplay: (file) => file.fileName,
				getLink: (file) => this.entityType.getEntitiesLink([file]),
				icon: {
					fabricIcon: this.entityType.icon,
				},
				sort: { sortLocally: false }, // avoid default client side sorting when no paging
			},
			{
				id: 'matchType',
				name: this.i18nService.get('search_matchType'),
				icon: {
					fabricIcon: (file) => matchTypeIconMap.get(file.matchType),
					className: '',
				},
				getDisplay: (file) => this.i18nService.get(matchTypeDisplayNameMap.get(file.matchType)),
				filter: {
					component: {
						type: FilterValuesChecklistComponent,
						config: {
							mapFilterValue: (matchType: MatchType) => {
								return {
									id: matchType,
									value: matchType,
									rawValue: matchType,
									name: this.i18nService.get(matchTypeDisplayNameMap.get(matchType)),
									icon: matchTypeIconMap.get(matchType),
								};
							},
						},
					},
				},
				sort: { sortLocally: false, sortDescendingByDefault: false }, // avoid default client side sorting when no paging
				// TODO: Add a property to specify that this field should only be added for search results dataview
			},
			{
				id: 'lastSeen',
				name: this.i18nService.get('files.fields.lastSeen.title'),
				getDisplay: (file) => file.lastSeen,
				component: {
					type: TzDateComponent,
					getProps: (file: File) => ({ date: file.lastSeen }),
				},
				sort: { sortLocally: false }, // avoid default client side sorting when no paging
			},
			{
				id: 'firstSeen',
				name: this.i18nService.get('files.fields.firstSeen.title'),
				getDisplay: (file) => file.firstSeen,
				component: {
					type: TzDateComponent,
					getProps: (file: File) => ({ date: file.firstSeen }),
				},
				sort: { sortLocally: false }, // avoid default client side sorting when no paging
			},
			{
				id: 'id',
				name: this.i18nService.get('files.fields.details.title'),
				getDisplay: (file) => file.sha1 || file.sha256,
				getImage: (file) =>
					file.sha1
						? '/assets/images/icons/sha1.svg'
						: file.sha256
						? '/assets/images/icons/sha256.svg'
						: null,
				sort: { sortLocally: false }, // avoid default client side sorting when no paging
			},
		];

		return DataviewField.fromList<File>(fields);
	});

	private readonly _entityType = new Lazy<Readonly<EntityType<File>>>(
		() => this.injector.get(FileEntityTypeService).entityType
	);

	/**
	 * _`Injector` is injected here in order to avoid a cyclic dependency between `FilesFieldsService` and `FileEntityTypeService`, each requires other parts from the other._
	 */
	constructor(private readonly injector: Injector, private readonly i18nService: I18nService) {}

	get entityType(): Readonly<EntityType<File>> {
		return this._entityType.value;
	}

	get fields(): ReadonlyArray<DataviewField<File>> {
		return this._fields.value;
	}
}
