import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { FileActionPermission } from '../file.action-permission.value-object';
import { File } from '../file.entity';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileActionPermission,
	endpoint: 'remediation/permission',
	baseUrl: config => config.data.serviceUrls.userRequests,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (file: File) => {
		return {
			FileIdentifierType: file.sha1 ? 'Sha1' : 'Sha256',
			FileIdentifier: file.sha1 || file.sha256,
		};
	},
	cache: {
		max: 5,
		time: 5 * 60 * 1000,
	},
})
export class FileActionPermissionRelationship
	implements EntityRelationshipRepositoryType<File, FileActionPermission> {}
