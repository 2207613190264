import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { FileVerdict } from '../file.file-verdict.value-object';
import { Alert } from '../../alert/alert.entity';
import {convertAlertV3ToLegacyAlert} from "../../alert/alert.utils";
import {WcdPortalParisConfig} from "../../paris-config.interface";

@EntityRelationship({
	sourceEntity: FileVerdict,
	dataEntity: Alert,
	endpoint: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useFileAssociatedAlertsVnextApi'] ?
			'Evidences/file/AlertsByFileThreatName' :
			'AssociatedAlerts';
	},
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (file: FileVerdict) => ({
		fileThreatName: file.threatName,
	}),
	allItemsProperty: 'Items',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useFileAssociatedAlertsVnextApi'] ?
			config.data.serviceUrls.alertsApiService :
			config.data.serviceUrls.threatIntel;
	},
	parseDataQuery: (dataQuery: DataQuery) => {
		const where: Record<string, string> =
			dataQuery && dataQuery.where ? (dataQuery.where as Record<string, string>) : {};

		return {
			detectionSource: 'WindowsDefenderAv',
			...where,
			lookBackInDays: 180,
		};
	},
	parseData: (data, _, query) =>
		query && query.where && query.where['useFileAssociatedAlertsVnextApi'] ?
			({items: data.items && data.items.map((alert) => convertAlertV3ToLegacyAlert(alert))}) : data
})
export class FileVerdictAlertsRelationship implements EntityRelationshipRepositoryType<FileVerdict, Alert> {}
