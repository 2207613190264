/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "./evidence-detection-context.component.ngfactory";
import * as i6 from "./evidence-detection-context.component";
import * as i7 from "../services/evidence.service";
import * as i8 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i10 from "./evidence-detection-context-multi.component";
var styles_EvidenceDetectionContextMultiComponent = [];
var RenderType_EvidenceDetectionContextMultiComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EvidenceDetectionContextMultiComponent, data: {} });
export { RenderType_EvidenceDetectionContextMultiComponent as RenderType_EvidenceDetectionContextMultiComponent };
function View_EvidenceDetectionContextMultiComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EvidenceDetectionContextMultiComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextMultiComponent_3)), i0.ɵdid(2, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 2, 0, currVal_0); }, null); }
function View_EvidenceDetectionContextMultiComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_EvidenceDetectionContextMultiComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 11, "div", [["class", "wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextMultiComponent_5)), i0.ɵdid(4, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 5, "span", [["class", "wcd-padding-left"], ["wcdTooltip", ""]], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i0.ɵnov(_v, 7).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i0.ɵnov(_v, 7).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i0.ɵnov(_v, 7).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i0.ɵnov(_v, 7).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i0.ɵdid(7, 147456, null, 0, i2.TooltipDirective, [i0.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], tooltipInnerComponent: [1, "tooltipInnerComponent"], innerComponentInput: [2, "innerComponentInput"] }, null), i0.ɵpod(8, { detectionContexts: 0 }), (_l()(), i0.ɵted(9, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i0.ɵpod(10, { alertCount: 0 }), i0.ɵppd(11, 2), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v.parent.parent, 5); _ck(_v, 4, 0, currVal_0); var currVal_1 = ""; var currVal_2 = _co.EvidenceDetectionContextTooltipComponent; var currVal_3 = _ck(_v, 8, 0, _co.detectionContexts.slice(1)); _ck(_v, 7, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = i0.ɵunv(_v, 9, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent.parent, 0), "evidence_fields_detectionContext_extraAlerts", _ck(_v, 10, 0, (_co.detectionContexts.length - 1)))); _ck(_v, 9, 0, currVal_4); }); }
function View_EvidenceDetectionContextMultiComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, null, null, null, null, null, null, null)), i0.ɵdid(1, 16384, null, 0, i1.NgPlural, [i1.NgLocalization], { ngPlural: [0, "ngPlural"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextMultiComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgPluralCase, [[8, "=1"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextMultiComponent_4)), i0.ɵdid(7, 16384, null, 0, i1.NgPluralCase, [[8, "other"], i0.TemplateRef, i0.ViewContainerRef, i1.NgPlural], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.detectionContexts == null) ? null : _co.detectionContexts.length); _ck(_v, 1, 0, currVal_0); }, null); }
function View_EvidenceDetectionContextMultiComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "evidence-detection-context", [["class", "wcd-text-overflow-ellipsis"]], null, null, null, i5.View_EvidenceDetectionContextComponent_0, i5.RenderType_EvidenceDetectionContextComponent)), i0.ɵdid(2, 49152, null, 0, i6.EvidenceDetectionContextComponent, [i7.EvidenceService], { detectionContext: [0, "detectionContext"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detectionContexts[0]; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EvidenceDetectionContextMultiComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i8.I18nPipe, [i9.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextMultiComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["firstDetectionContext", 2]], null, 0, null, View_EvidenceDetectionContextMultiComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.detectionContexts == null) ? null : _co.detectionContexts.length); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EvidenceDetectionContextMultiComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "evidence-detection-context-multi", [], null, null, null, View_EvidenceDetectionContextMultiComponent_0, RenderType_EvidenceDetectionContextMultiComponent)), i0.ɵdid(1, 49152, null, 0, i10.EvidenceDetectionContextMultiComponent, [], null, null)], null, null); }
var EvidenceDetectionContextMultiComponentNgFactory = i0.ɵccf("evidence-detection-context-multi", i10.EvidenceDetectionContextMultiComponent, View_EvidenceDetectionContextMultiComponent_Host_0, { detectionContexts: "detectionContexts" }, {}, []);
export { EvidenceDetectionContextMultiComponentNgFactory as EvidenceDetectionContextMultiComponentNgFactory };
