<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="(data$ | async) as data" class="wcd-full-height wcd-flex-vertical">
	<wcd-datatable
		[items]="data"
		[columns]="fields"
		[selectEnabled]="false"
		[fixedTable]="false"
		[fluidHeaderWidth]="true"
		(itemClick)="openChangeEventPage($event.item)">
	</wcd-datatable>
	<a
		[attr.aria-label]="'tvm.dashboard.showMore.changeEvents' | i18n"
		tabindex="0"
		data-track-id="OpenChangeEventsPage"
		data-track-type="Navigation"
		(click)="openChangeEventsPage()">{{'tvm.common.showMore' | i18n}}</a>
</div>
