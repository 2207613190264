/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../tag/tag.component.ngfactory";
import * as i2 from "../tag/tag.component";
import * as i3 from "../../services/tags.service";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../shared/components/overflow-container/overflow-container.component.ngfactory";
import * as i6 from "../../../shared/components/overflow-container/overflow-container.component";
import * as i7 from "@angular/common";
import * as i8 from "./overflow-tags-list.component";
var styles_OverflowTagsList = [];
var RenderType_OverflowTagsList = i0.ɵcrt({ encapsulation: 2, styles: styles_OverflowTagsList, data: {} });
export { RenderType_OverflowTagsList as RenderType_OverflowTagsList };
function View_OverflowTagsList_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "tag", [], null, null, null, i1.View_TagComponent_0, i1.RenderType_TagComponent)), i0.ɵdid(2, 573440, null, 0, i2.TagComponent, [i3.TagsService, i4.I18nService], { tag: [0, "tag"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = _v.context.itemData; _ck(_v, 2, 0, currVal_0); }, null); }
function View_OverflowTagsList_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, [[1, 0], ["overflowContainer", 1]], null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "overflow-container", [], null, null, null, i5.View_OverflowContainerComponent_0, i5.RenderType_OverflowContainerComponent)), i0.ɵdid(3, 704512, null, 0, i6.OverflowContainerComponent, [i0.ChangeDetectorRef], { minimumExpendedItems: [0, "minimumExpendedItems"], items: [1, "items"], itemsContainer: [2, "itemsContainer"], itemTemplate: [3, "itemTemplate"], createOverflowItemData: [4, "createOverflowItemData"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.minimumExpendedItems; var currVal_1 = _co.tags; var currVal_2 = _co.tagsContainer; var currVal_3 = i0.ɵnov(_v.parent, 1); var currVal_4 = _co.createOverflowItem; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_OverflowTagsList_0(_l) { return i0.ɵvid(2, [i0.ɵqud(671088640, 1, { overflowContainerRef: 0 }), (_l()(), i0.ɵand(0, [["tagTemplate", 2]], null, 0, null, View_OverflowTagsList_1)), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_OverflowTagsList_2)), i0.ɵdid(4, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !!((_co.tags == null) ? null : _co.tags.length); _ck(_v, 4, 0, currVal_0); }, null); }
export function View_OverflowTagsList_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "overflow-tags-list", [], null, null, null, View_OverflowTagsList_0, RenderType_OverflowTagsList)), i0.ɵdid(1, 4243456, null, 0, i8.OverflowTagsList, [i0.ChangeDetectorRef, i4.I18nService], null, null)], null, null); }
var OverflowTagsListNgFactory = i0.ɵccf("overflow-tags-list", i8.OverflowTagsList, View_OverflowTagsList_Host_0, { minimumExpendedItems: "minimumExpendedItems", tags: "tags" }, {}, []);
export { OverflowTagsListNgFactory as OverflowTagsListNgFactory };
