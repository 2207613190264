import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Asset, RecommendationContextType } from '@wcd/domain';
import { TvmRecommendationContextService } from '../../../../tvm/services/tvm-recommendation-context.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'asset-recommnedation-context',
	template: `
		<table class="wcd-margin-large-left">
			<tr>
				<th *ngFor="let header of contextTableHeaders">
					{{ 'tvm.securityRecommendation.recommendationContext.' + header | i18n }}
				</th>
			</tr>
			<tr *ngFor="let contextDataItem of _asset.recommendationContext">
				<td
					*ngFor="let header of contextTableHeaders; let first = first; let last = last"
					[class]="
						last
							? 'wcd-border-bottom wcd-font-size-s'
							: 'wcd-border-bottom full-width-cell wcd-font-size-s'
					"
					[wcdTooltip]="contextDataItem[header]"
				>
					<span
						*ngIf="first && recommendationContextIcon !== ''"
						[class]="recommendationContextIcon"
					></span>
					{{ contextDataItem[header] }}
				</td>
			</tr>
		</table>
	`,
})
export class RecommendationContextComponent {
	_asset: Asset;
	recommendationContextType: RecommendationContextType;
	contextTableHeaders: Array<string> = [];
	recommendationContextIcon: String;

	@Input()
	set asset(asset: Asset) {
		this._asset = asset;
		this.recommendationContextType = this.recommendationContextService.getRecommendationContextTypeByAsset(
			asset
		);
		this.contextTableHeaders = this.recommendationContextService.getRecommendationContextTableHeadersByType(
			this.recommendationContextType
		);
		this.recommendationContextIcon = this.recommendationContextService.getRecommendationContextKeyIconNameByType(
			this.recommendationContextType
		);
	}

	constructor(private recommendationContextService: TvmRecommendationContextService) {}
}
