import { EventEmitter, OnInit } from '@angular/core';
import { RecommendationContextType } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { TvmTextsService } from '../../../../tvm/services/tvm-texts.service';
import { TvmRecommendationContextService } from '../../../../tvm/services/tvm-recommendation-context.service';
var ExposedMachinesDetailsComponent = /** @class */ (function () {
    function ExposedMachinesDetailsComponent(featuresService, tvmTextsService, recommendationContextService, router) {
        this.featuresService = featuresService;
        this.tvmTextsService = tvmTextsService;
        this.recommendationContextService = recommendationContextService;
        this.router = router;
        this.SpinnerSize = SpinnerSize;
        this.isListCollapsed = true;
        this.oneDay = 60 * 60 * 24 * 1000;
        //Recommendation context related fields:
        this._machinesTableHeadersCount = 2;
        this._collapsed = [];
        this._isRecommendationContextFeatureFlagEnabled = false;
        this.contextTableHeaders = [];
        this.showContextTable = false;
        this.contextTableColSpan = 1;
        this.machinesTableColSpan = 1;
        this.misconfiguredContextItemsCountHeaderTitle = '';
        this.assetsSubject$ = new BehaviorSubject(null);
        this.includeOperatingSystem = false;
        this.openByMachineNameNewTab = false;
        this.isShowMore = true;
        this.showMore = new EventEmitter();
        this.export = new EventEmitter();
        this.oneDayAgo = new Date().getTime() - this.oneDay;
        this._isRecommendationContextFeatureFlagEnabled = this.featuresService.isEnabled(Feature.TvmRecommendationContext);
    }
    Object.defineProperty(ExposedMachinesDetailsComponent.prototype, "assets$", {
        set: function (assets) {
            var _this = this;
            assets.subscribe(function (assets) {
                _this.processRecommendationContextTable(assets);
                _this.assetsSubject$.next(assets);
            });
        },
        enumerable: true,
        configurable: true
    });
    ExposedMachinesDetailsComponent.prototype.ngOnInit = function () {
        if (this.includeProductivityAssessment) {
            this._machinesTableHeadersCount += 1;
        }
    };
    ExposedMachinesDetailsComponent.prototype.onItemClick = function (assetId) {
        if (this.openByMachineNameNewTab) {
            var baseCompareUrl = this.router.url;
            var baseUrl = baseCompareUrl.substring(0, baseCompareUrl.lastIndexOf('/'));
            window.open(baseUrl + ("/machines/" + assetId + "/overview"), '_blank');
        }
        else {
            var link = this.featuresService.isEnabled(Feature.UpgradeMachinePage)
                ? "/machines/" + assetId
                : "/machine/" + assetId;
            this.router.navigate([link]);
        }
    };
    ExposedMachinesDetailsComponent.prototype.openAllExposedMachines = function () {
        this.showMore.emit();
    };
    ExposedMachinesDetailsComponent.prototype.getLastSeenDate = function (asset) {
        return new Date(asset.lastSeen).toLocaleString();
    };
    ExposedMachinesDetailsComponent.prototype.processRecommendationContextTable = function (assets) {
        this.showContextTable =
            this._isRecommendationContextFeatureFlagEnabled &&
                assets.items.some(function (asset) {
                    return asset.recommendationContext &&
                        asset.recommendationContext.length > 0 &&
                        !asset.recommendationContext.some(function (contextItem) { return contextItem.contextType === RecommendationContextType.Unknown; });
                });
        if (this.showContextTable) {
            // Build the context table according to the type of the context:
            this.buildContext(this.recommendationContextService.getRecommendationContextTypeByAssets(assets));
            this.updateColSpanValues();
            this.initContextCollapseStatesArray(assets.items.length);
        }
    };
    ExposedMachinesDetailsComponent.prototype.getContextItemCollapseState = function (i) {
        return this._collapsed[i];
    };
    ExposedMachinesDetailsComponent.prototype.toggleContextItemCollapseState = function (i) {
        this._collapsed[i] = !this._collapsed[i];
    };
    ExposedMachinesDetailsComponent.prototype.buildContext = function (recommendationContextType) {
        this.contextTableHeaders = this.recommendationContextService.getRecommendationContextTableHeadersByType(recommendationContextType);
        this.misconfiguredContextItemsCountHeaderTitle = this.tvmTextsService.recommendationContextToCountHeader[recommendationContextType];
        this._machinesTableHeadersCount += 1;
        this.recommendationContextIcon = this.recommendationContextService.getRecommendationContextKeyIconNameByType(recommendationContextType);
    };
    ExposedMachinesDetailsComponent.prototype.updateColSpanValues = function () {
        if (this._machinesTableHeadersCount < this.contextTableHeaders.length) {
            this.machinesTableColSpan += this.contextTableHeaders.length - this._machinesTableHeadersCount;
        }
        else {
            this.contextTableColSpan += this._machinesTableHeadersCount - this.contextTableHeaders.length;
        }
    };
    ExposedMachinesDetailsComponent.prototype.initContextCollapseStatesArray = function (assetsCount) {
        for (var i = 0; i < assetsCount; i++) {
            this._collapsed[i] = false;
        }
    };
    return ExposedMachinesDetailsComponent;
}());
export { ExposedMachinesDetailsComponent };
