/* tslint:disable:template-accessibility-elements-content */
import { Component, EventEmitter, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { Paris, Repository } from '@microsoft/paris';
import { DataExportSettingsEntity, LogProperties } from '@wcd/domain';
import { ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { PanelContainer } from '@wcd/panels';
import { cloneDeep } from 'lodash-es';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { LogType } from '../enums/log-type.enum';
import { DataExportService } from '../services/data-export.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AuthService } from '@wcd/auth';

@Component({
	selector: 'data-export-new',
	templateUrl: './data-export-new.component.html',
})
export class DataExportNewModalComponent extends PanelContainer {
	@ViewChild('newDataExportForm', { static: false }) newDataExportForm: NgForm;
	@Output() save: EventEmitter<void> = new EventEmitter<void>();

	clickedSave = false;

	isSaving = false;
	name: string;
	sendToStorage = false;
	sendToEventHub = false;
	eventHubName: string;

	availableLogTypes: Array<ChecklistValue>;

	// start with all logs enabled by default
	selectedLogTypes: Array<ChecklistValue>;

	userExposedToNewDataExportPage: boolean = false;

	private storageAccountId: string;
	private eventHubResourceId: string;
	private dataExportRepository: Repository<DataExportSettingsEntity>;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		protected router: Router,
		private dataExportService: DataExportService,
		private authService: AuthService,
		private featureService: FeaturesService,
		private dialogsService: DialogsService
	) {
		super(router);

		this.dataExportRepository = this.paris.getRepository(DataExportSettingsEntity);

		this.availableLogTypes = dataExportService.notDeprecatedAvailableLogTypes;
		this.selectedLogTypes = cloneDeep(this.availableLogTypes);
		this.userExposedToNewDataExportPage = this.featureService.isEnabled(Feature.DataExportSettingsV2Page);
	}

	get isDirty(): boolean {
		return this.newDataExportForm.form.dirty;
	}

	onSave() {
		this.save.emit();
		this.clickedSave = true;
		this.closePanel();
	}

	cancel() {
		this.closePanel();
	}

	saveDataExport() {
		if (!this.clickedSave) {
			this.clickedSave = true;
		}

		if (!this.isValid) {
			return;
		}

		this.isSaving = true;

		const newDataExport = this.dataExportRepository.createNewItem();

		Object.assign(newDataExport, {
			id: this.name,
			eventHubProperties: this.sendToEventHub
				? {
						eventHubResourceId: this.eventHubResourceId,
						name: this.eventHubName,
				  }
				: null,
			storageAccountProperties: this.sendToStorage
				? {
						storageAccountResourceId: this.storageAccountId,
				  }
				: null,
			logs: this.selectedLogTypes.map(
				checklistValue =>
					<LogProperties>{
						category: LogType[checklistValue.id],
						enabled: this.availableLogTypes.some(logType => logType.id === checklistValue.id),
					}
			),
		});

		// Adding a new data export item takes 1-3 seconds, so artificial delay is required
		// to ensure that item appears after the panel close and the data view components
		// tried to read the latest set of items
		this.dataExportRepository.save(newDataExport).subscribe(
			() => {
				this.isSaving = false;
				this.save.emit();
				this.closePanel();
			},
			error => {
				this.isSaving = false;
				let errorMessage = error.message || error;

				if(!this.authService.currentUser.isSecAdmin) {
					const addedMessage = this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.missingAadPermissions', { tenantid: this.authService.tenantId });
					errorMessage += ` (${addedMessage})`;
					error.message = errorMessage;
				}

				this.dialogsService.showError({
					title: this.i18nService.get(
						'endpointManagement.nonWindows.applications.dataExport.addNew.failedToSave'
					),
					message: errorMessage,
					data: error,
				});
			}
		);
	}

	get isValid(): boolean {
		if (!this.name) {
			return false;
		}

		if (!this.sendToEventHub && !this.sendToStorage) {
			return false;
		}

		if (this.sendToEventHub && (!this.eventHubResourceId || !this.eventHubName)) {
			return false;
		}

		if (this.sendToStorage && !this.storageAccountId) {
			return false;
		}

		if (this.selectedLogTypes.length === 0) {
			return false;
		}

		return true;
	}
}
