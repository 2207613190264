import { Component, Input } from '@angular/core';
import { fromPairs, isNil } from 'lodash-es';
import { SeverityType } from '@wcd/domain';
import { OnChanges, TypedChange, TypedChanges } from '@wcd/angular-extensions';
import { KnownColorName } from '../../models/colors/known-colors.models';
import { SeverityTypesService } from '../../services/severity-types.service';
import { SeverityTypeColorService } from '../../services/severity-type-color.service';
import { DistributionElementSize } from '../../modules/distribution/distribution-element/distribution-element.component';

const severityTypeTitleMap: Record<SeverityType, string> = fromPairs(
	([SeverityType.High, SeverityType.Medium, SeverityType.Low, SeverityType.Informational] as Array<
		SeverityType
	>).map(severityType => [severityType, SeverityTypesService.getSeverityByType(severityType).name])
) as Record<SeverityType, string>;

@Component({
	selector: 'severity-bar',
	styles: [
		`
			:host {
				display: inline-block;
			}
		`,
	],
	template: `
		<distribution-element
			[barColor]="barColor"
			[title]="title"
			[mainText]="mainText"
			[secondaryText]="mainText && secondaryText"
			[size]="size"
		>
		</distribution-element>
	`,
})
export class SeverityBarComponent implements OnChanges<SeverityBarComponent> {
	@Input() size?: DistributionElementSize = 'compact';
	@Input() severity: SeverityType;
	@Input() totalCount?: number;
	@Input() count: number;

	barColor: KnownColorName;
	title: string;
	mainText: string = '-';
	secondaryText: string;

	constructor(private readonly severityTypeColorService: SeverityTypeColorService) {}

	ngOnChanges(changes: TypedChanges<SeverityBarComponent>) {
		const wasChanged = <T>(change: TypedChange<T>) =>
			change && (change.isFirstChange() || change.currentValue !== change.previousValue);

		if (wasChanged(changes.severity)) {
			this.barColor = this.severityTypeColorService.colorsMap.get(this.severity).name;
			this.title = severityTypeTitleMap[this.severity];
		}

		if (wasChanged(changes.count) && !isNil(this.count)) {
			this.mainText = this.count.toString();
		}

		this.secondaryText = !isNil(this.totalCount) && `/${this.totalCount}`;
	}
}
