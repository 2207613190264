import { ChangeDetectorRef, OnInit, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { Disableable } from '../models/disableable.interface';
var CHECKLIST_ITEM_HEIGHT = 26;
var ChecklistComponent = /** @class */ (function () {
    function ChecklistComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.disableEmptySelection = false;
        this.isDisabled = false;
        this.selectById = true; // means that value selected and 'output'-ed is the object id
        this.label = 'name';
        this.navigateUsingArrowKeysOnly = false;
        this.wrapLabel = false;
        this.itemHeight = CHECKLIST_ITEM_HEIGHT; // item height must be determined explicitly when using virtual scroll
        this.withVirtualScroll = false;
        this.listRole = 'menu';
        this.itemListRole = 'presentation';
        this.selectedValues = {};
        this.isLastSelectedValue = false;
        this.onChange = function (_) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(ChecklistComponent.prototype, "values", {
        get: function () {
            return this._values;
        },
        set: function (values) {
            this._values = values;
            this.setSelectedValues();
        },
        enumerable: true,
        configurable: true
    });
    ChecklistComponent.prototype.ngOnInit = function () {
        if (this.value)
            this.setSelectedValues();
    };
    ChecklistComponent.prototype.writeValue = function (value) {
        var _this = this;
        this.value =
            value &&
                value.map(function (_value) { return (_this.isChecklistValue(_value) && _this.selectById ? _value.id : _value); });
        this.changeDetectorRef.markForCheck();
        if (this.values) {
            this.setSelectedValues();
        }
        else {
            this.selectedValues = {};
        }
    };
    ChecklistComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    ChecklistComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    ChecklistComponent.prototype.getItemLabel = function (item) {
        if (this.formatLabel)
            return this.formatLabel(item);
        return item[this.label];
    };
    ChecklistComponent.prototype.isChecklistValue = function (value) {
        return value && value.id && value.name;
    };
    ChecklistComponent.prototype.setSelectedValues = function () {
        var _this = this;
        this.selectedValues = {};
        this.values.forEach(function (_value) {
            return (_this.selectedValues[_value.id] = _this.value
                ? _this.value.some(function (val) {
                    return _value.id === (_this.selectById ? val : _this.isChecklistValue(val) && val.id);
                })
                : false);
        });
        this.setIsLast();
        this.changeDetectorRef.detectChanges();
    };
    ChecklistComponent.prototype.setValue = function () {
        this.onChange((this.value = this.getChecklistValue()));
    };
    ChecklistComponent.prototype.selectAll = function () {
        this.setAll(true);
    };
    ChecklistComponent.prototype.selectNone = function () {
        this.setAll(false);
    };
    ChecklistComponent.prototype.setAll = function (isSelected) {
        var _this = this;
        this.values.forEach(function (value) { return !value.disabled && (_this.selectedValues[value.id] = isSelected); });
        if (!isSelected && this.disableEmptySelection)
            this.selectedValues[this.values[0].id] = true;
        this.setValue();
    };
    ChecklistComponent.prototype.selectValues = function (selectedValues) {
        var _this = this;
        this.values.forEach(function (value) { return (_this.selectedValues[value.id] = selectedValues.includes(value.id)); });
        this.setValue();
    };
    ChecklistComponent.prototype.isLastCheckedValue = function () {
        var _this = this;
        var selectedKeys = Object.keys(this.selectedValues).filter(function (key) { return _this.selectedValues[key]; });
        return selectedKeys.length === 1;
    };
    ChecklistComponent.prototype.setIsLast = function () {
        this.isLastSelectedValue = this.isLastCheckedValue();
    };
    ChecklistComponent.prototype.getChecklistValue = function () {
        var _this = this;
        this.setIsLast();
        return this.values
            .filter(function (value) { return _this.selectedValues[value.id]; })
            .map(function (value) { return (_this.selectById ? value.id : value); });
    };
    return ChecklistComponent;
}());
export { ChecklistComponent };
