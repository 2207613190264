var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
var ScheduledHuntingEntityComponent = /** @class */ (function (_super) {
    __extends(ScheduledHuntingEntityComponent, _super);
    function ScheduledHuntingEntityComponent(i18nService, featuresService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.tabs = [];
        return _this;
    }
    Object.defineProperty(ScheduledHuntingEntityComponent.prototype, "rule", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingEntityComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs(this.rule);
    };
    ScheduledHuntingEntityComponent.prototype.getTabs = function (rule) {
        var _this = this;
        return [
            {
                id: 'alerts',
                name: this.i18nService.get('scheduledHunting.tabs.alerts'),
                routerLink: './alerts',
            },
            {
                id: 'actions',
                name: this.i18nService.get('scheduledHunting.tabs.actions'),
                routerLink: './actions',
            },
        ]
            .filter(function (tab) { return _this.featuresService.isEnabled(Feature.HuntingCustomActions) || tab.id !== 'actions'; })
            .map(function (tab) { return new TabModel(tab); });
    };
    return ScheduledHuntingEntityComponent;
}(EntityComponentBase));
export { ScheduledHuntingEntityComponent };
