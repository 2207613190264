import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
	LegacyUser,
	LegacyUserLegacyUserNetworkInformationRelationship,
	LegacyUserNetworkInformation,
	Machine,
} from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Paris } from '@microsoft/paris';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { LogonTypePipe } from '../../machines/pipes/logon-type.pipe';
import { Observable } from 'rxjs';

@Component({
	selector: 'user-network-activity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl [class.key-values]="asKeyValueList" role="none">
			<ng-container *ngIf="user.loggedOnMachinesCount">
				<dt>
					{{
						'users.entityDetails.sections.networkActivity.fields.loggedOnMachinesCount.title'
							| i18n
					}}
				</dt>
				<dd>{{ user.loggedOnMachinesCount }}</dd>
			</ng-container>

			<dt>{{ 'users.entityDetails.sections.networkActivity.fields.firstSeen.title' | i18n }}</dt>
			<dd>{{ user.firstSeen | date: 'medium' }}</dd>

			<dt>{{ 'users.entityDetails.sections.networkActivity.fields.lastSeen.title' | i18n }}</dt>
			<dd>{{ user.lastSeen | date: 'medium' }}</dd>

			<ng-template #machineDefinition let-machine="machine" let-titleKey="titleKey">
				<ng-container *ngIf="machine">
					<dt>{{ titleKey | i18n }}</dt>
					<dd class="wcd-flex-horizontal">
						<wcd-shared-icon
							[iconName]="
								(machineEntityTypeService.getIcon &&
									machineEntityTypeService.getIcon([machine])) ||
								machineEntityTypeService.icon
							"
						>
						</wcd-shared-icon>
						&nbsp;
						<entity-link
							class="wcd-break-words"
							[entity]="machine"
							[entityType]="machineType"
							[linkText]="machine.name"
						></entity-link>
					</dd>
				</ng-container>
			</ng-template>

			<ng-container *ngIf="(role$ | async) as role">
				<dt>{{ 'users.entityDetails.sections.details.role.title' | i18n }}</dt>
				<dd>{{ role }}</dd>
			</ng-container>

			<ng-container *ngIf="(logonType$ | async) as logonType">
				<dt>{{ 'users.entityDetails.sections.details.logonTypes.title' | i18n }}</dt>
				<dd>{{ logonType }}</dd></ng-container
			>
		</dl>
	`,
})
export class UserNetworkActivityDetailsComponent implements OnChanges<UserNetworkActivityDetailsComponent> {
	readonly machineType = Machine;

	@Input() user: LegacyUser;
	@Input() asKeyValueList = false;

	readonly machineEntityTypeService: EntityType<Machine>;

	role$: Observable<string>;
	logonType$: Observable<string>;

	constructor(
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly i18nService: I18nService,
		private readonly logonTypePipe: LogonTypePipe,
		private readonly paris: Paris
	) {
		this.machineEntityTypeService = globalEntityTypesService.getEntityType(Machine);
	}

	ngOnChanges(changes: TypedChanges<UserNetworkActivityDetailsComponent>) {
		const networkInformation$ = this.paris.getRelatedItem<LegacyUser, LegacyUserNetworkInformation>(
			LegacyUserLegacyUserNetworkInformationRelationship,
			changes.user.currentValue
		);

		const domainAdminTextKey = 'users.entityDetails.sections.details.role.values.domainAdmin';
		const localAdminTextTextKey = 'users.entityDetails.sections.details.role.values.localAdmin';

		// Calculates the text to show based on the user admin status (both domain, local).
		// If this is needed elsewhere - please extract to a `Pipe` (```user | userRoleKey | i18n``` or similar).
		this.role$ = networkInformation$.pipe(
			map(({ isDomainAdmin, isLocalAdmin }) =>
				isDomainAdmin && isLocalAdmin
					? [domainAdminTextKey, localAdminTextTextKey]
					: isDomainAdmin
					? [domainAdminTextKey]
					: isLocalAdmin
					? [localAdminTextTextKey]
					: []
			),
			map(translationKeys => translationKeys.map(key => this.i18nService.get(key)).join(', '))
		);

		this.logonType$ = networkInformation$.pipe(
			map(({ logonTypes }) => logonTypes.map(logonType => this.logonTypePipe.transform(logonType))),
			map(logonTypesDisplayTexts => logonTypesDisplayTexts.join(', '))
		);
	}
}
