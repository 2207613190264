<div class="notifications wcd-full-height wcd-flex-vertical" data-track-component="Notifications">
	<header class="notifications-header wcd-flex-none">
		<button class="btn-icon-only btn-unstyled pull-right"
				data-track-id="CloseNotifications"
				data-track-type="Button"
				[wcdTooltip]="'Close Notifications'"
				(click)="close.emit()"
				data-test-id="notifications-close">
			 <wcd-shared-icon [iconName]="'close'"> </wcd-shared-icon >
		</button>
		<button class="btn-icon-only btn-unstyled pull-right"
				data-test-id="notifications-unsnooze"
				data-track-id="UnSnooze"
				data-track-type="Button"
				[wcdTooltip]="'Unsnooze Notifications'"
				*ngIf="notificationsService.snoozed$ | async"
				(click)="notificationsService.unSnoozeNotifications()">
			 <wcd-shared-icon iconName="remove"> </wcd-shared-icon >
		</button>
		<h2>Notifications</h2>
	</header>
	<div class="wcd-flex-1 notifications-list">
		<notification *ngFor="let notification of notificationsService.notifications$ | async"
					  [ngClass]="{ 'new-notification': notification.isNew }"
					  [notification]="notification"
					  (select)="notificationsService.selectNotification(notification)"
					  (snooze)="notificationsService.snoozeNotifications()"
		></notification>
	</div>
</div>
