import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Command progress',
	pluralName: '',
	readonly: true,
})
export class LiveResponseCommandProgress extends ModelBase {
	@EntityField({ data: 'progress_percentage' })
	readonly progressPercent: number;

	@EntityField() readonly message: string;
}
