import { ValueObject, EntityField, ModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: 'Alerts by detection source count',
	pluralName: 'Plural alerts by detection source count',
	readonly: true,
})
export class AlertsByDetectionSourceCount extends ModelBase {
	@EntityField({ data: 'WindowsDefenderAtp', defaultValue: 0 })
	readonly edr: number;

	@EntityField({ data: 'WindowsDefenderAv', defaultValue: 0 })
	readonly antivirus: number;

	@EntityField({ data: 'WindowsDefenderSmartScreen', defaultValue: 0 })
	readonly smartScreen: number;

	@EntityField({ data: 'OfficeATP', defaultValue: 0 })
	readonly officeAtp: number;

	@EntityField({ data: 'CustomerTI', defaultValue: 0 })
	readonly customTi: number;

	@EntityField({ data: 'ThirdParty', defaultValue: 0 })
	readonly thirdParty: number;

	@EntityField({ data: 'ThirdPartySensors', defaultValue: 0 })
	readonly thirdPartySensors: number;

	@EntityField({ data: 'AutomatedInvestigation', defaultValue: 0 })
	readonly automatedInvestigation: number;

	@EntityField({ data: 'ThreatExperts', defaultValue: 0 })
	readonly threatExperts: number;

	@EntityField({ data: 'CustomDetection', defaultValue: 0 })
	readonly customDetection: number;

	@EntityField({ data: 'MTP', defaultValue: 0 })
	readonly mtp: number;
}

@ValueObject({
	singularName: 'Alerts by detection source',
	pluralName: 'Plural alerts by detection source',
	readonly: true,
})
export class AlertsByDetectionSource extends ModelBase {
	@EntityField({ data: 'DateTime' })
	readonly date: Date;

	@EntityField({
		data: 'AlertCount',
		defaultValue: {
			edr: 0,
			antivirus: 0,
			smartScreen: 0,
			officeAtp: 0,
			customTi: 0,
			thirdParty: 0,
			thirdPartySensors: 0,
			automatedInvestigation: 0,
			threatExperts: 0,
			customDetection: 0,
			mtp: 0,
		},
	})
	readonly alertsCount: AlertsByDetectionSourceCount;
}

@ValueObject({
	singularName: 'Alerts by detection source over time',
	pluralName: 'Plural alerts by detection source over time',
	readonly: true,
})
export class AlertsByDetectionSourceOverTime extends ModelBase {
	@EntityField({ arrayOf: AlertsByDetectionSource })
	readonly items: Array<AlertsByDetectionSource>;
}
