import { RiskScore } from '../entity/common/machine.riskscore.enum';

export class RiskScoreService {
	/**
	 * Returns Risk Score details
	 */
	public getRiskScoreDetails(riskScoreStr: string) {
		var riskScore: RiskScore = RiskScore[riskScoreStr];
		switch (riskScore) {
			case RiskScore.High:
				return {
					text: 'High',
					className: 'wcd-severity-high',
				};
			case RiskScore.Medium:
				return {
					text: 'Medium',
					className: 'wcd-severity-medium',
				};
			case RiskScore.Low:
				return {
					text: 'Low',
					className: 'wcd-severity-low',
				};
			default:
				return {
					text: undefined,
					className: 'wcd-severity-informational',
				};
		}
	}
}
