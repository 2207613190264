import { Component, Input } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { MachineModel } from '../models/machine.model';

@Component({
	selector: 'app-machine-ips-panel',
	template: `
		<div class="machine-ip-side-pane">
			<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
				<machine-ips [machine]="machine"></machine-ips>
			</wcd-panel>
		</div>
	`,
})
export class MachineIpsPanelComponent extends PanelContainer {
	@Input() machine: MachineModel;

	constructor(router: Router) {
		super(router);
	}
}
