var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
var ScheduledHuntingEntityPanelComponent = /** @class */ (function (_super) {
    __extends(ScheduledHuntingEntityPanelComponent, _super);
    function ScheduledHuntingEntityPanelComponent(dialogsService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.dialogsService = dialogsService;
        return _this;
    }
    Object.defineProperty(ScheduledHuntingEntityPanelComponent.prototype, "rule", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return ScheduledHuntingEntityPanelComponent;
}(EntityPanelComponentBase));
export { ScheduledHuntingEntityPanelComponent };
