<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngIf="email.sender">
		<dt>{{ 'events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.sender' | i18n }}</dt>
		<dd>
			{{email.sender}}
		</dd>
	</ng-container>
	<ng-container *ngIf="email.subject">
		<dt>{{ 'events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.subject' | i18n }}</dt>
		<dd>
			{{email.subject}}
		</dd>
	</ng-container>
	<ng-container *ngIf="email.recipients">
		<dt>{{ 'events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.recipients' | i18n }}</dt>
		<dd>
			{{email.recipients.join('; ')}}
		</dd>
	</ng-container>
	<ng-container *ngIf="email.receivedDate">
		<dt>{{ 'events.actionTypes.fileCreated.outlookSavedAttachment.emailFields.received' | i18n }}</dt>
		<dd>
			{{email.receivedDate | date:'medium'}}
		</dd>
	</ng-container>
</dl>
