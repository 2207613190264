var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { finalize } from 'rxjs/operators';
import { IpActiveAlertsSummaryRelationship } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { buildSeveritiesMap, } from '../../../shared/components/severity-summary/severity-summary.component';
import { I18nService } from '@wcd/i18n';
var IpEntityPanelComponent = /** @class */ (function (_super) {
    __extends(IpEntityPanelComponent, _super);
    function IpEntityPanelComponent(changeDetectorRef, paris, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.alertsSeveritySummaryContentState = ContentState.Loading;
        _this.dataAsset = _this.i18nService.get('help_externalLoadError_data_asset');
        return _this;
    }
    Object.defineProperty(IpEntityPanelComponent.prototype, "ip", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    IpEntityPanelComponent.prototype.setEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entity, isExtendedData);
        this.setActiveAlertsSummary();
    };
    IpEntityPanelComponent.prototype.ngOnDestroy = function () {
        if (this.activeAlertsSummarySubscription) {
            this.activeAlertsSummarySubscription.unsubscribe();
        }
    };
    IpEntityPanelComponent.prototype.setActiveAlertsSummary = function () {
        var _this = this;
        this.activeAlertsSummarySubscription = this.paris
            .getRelatedItem(IpActiveAlertsSummaryRelationship, this.entity)
            .pipe(finalize(function () { return _this.changeDetectorRef.markForCheck(); }))
            .subscribe(function (alertsSeveritySummary) {
            return (_this.alertsSeveritiesCountMap = buildSeveritiesMap(alertsSeveritySummary));
        }, function () { return (_this.alertsSeveritySummaryContentState = ContentState.Error); }, function () {
            return (_this.alertsSeveritySummaryContentState = _this.alertsSeveritiesCountMap
                ? ContentState.Complete
                : ContentState.Empty);
        });
    };
    return IpEntityPanelComponent;
}(EntityPanelComponentBase));
export { IpEntityPanelComponent };
