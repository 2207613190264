import { EntityField, ValueObject, EntityModelBase } from '@microsoft/paris';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { sevilleInvestigationStatusToDisplayStatus } from '../investigation/investigation-status.values';

@ValueObject({
	singularName: 'Evaluation investigation',
	pluralName: 'Evaluation investigation',
})
export class EvaluationInvestigation extends EntityModelBase<number> {
	@EntityField({ data: 'InvestigationId' })
	investigationId: number;

	@EntityField({ data: 'end_date' })
	endDate: Date;

	@EntityField({ data: 'start_date' })
	startDate: Date;

	@EntityField({ data: 'title' })
	title: string;

	@EntityField({
		data: 'status',
		parse: fieldData => {
			const displayStatus = sevilleInvestigationStatusToDisplayStatus[fieldData];
			return displayStatus && displayStatus.id;
		},
	})
	status: InvestigationStatus;
}
