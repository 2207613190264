import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Entity approval',
	endpoint: 'autoir/entities/evidence/v2/change_approval',
	baseUrl: config => config.data.serviceUrls.k8s,
	method: 'POST',
	parseQuery: ({ entityData, status, declineReason, comment }) => ({
		data: {
			evidence_data: entityData.map(e => ({ merge_by_key: e.mergeByKey, alert_id: e.alertId })),
			status,
			decline_reason: declineReason,
			comment,
		},
	}),
})
export class EntityApprovalApiCall extends ApiCallModel<void, EntityApprovalData> {}

export type EntityApprovalData = {
	entityData: Array<{ mergeByKey: string; alertId: string }>;
} & ({ status: 'approved' } | { status: 'declined'; declineReason: string; comment?: string });
