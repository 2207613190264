import { SharedModule } from '../shared/shared.module';
import {
	FabDropdownModule,
	FabIconModule,
	FabMessageBarModule,
	FabPeoplePickerModule,
	FabShimmerModule,
	FabSpinnerModule,
} from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { DataViewsModule } from '../dataviews/dataviews.module';
import { TagsModule } from '../tags/tags.module';
import { EntityDataviewComponent } from './components/entity-dataview/entity-dataview.component';
import { AlertEntityDetailsComponent } from './components/entity-details/alert.entity-details.component';
import { AlertsManageComponent } from './components/entity-details/alerts-manage.component';
import { AlertedDescriptionComponent } from './components/entity-details/alerted-description.component';
import { EntityDetailsSectionHeaderComponent } from './components/entity-details/entity-details.section-header.component';
import { FileEntityDetailsComponent } from './components/entity-details/file.entity-details.component';
import { CyberEventEntityDetailsComponent } from './components/entity-details/cyber-event.entity-details.component';
import { IncidentEntityDetailsComponent } from './components/entity-details/incident.entity-details.component';
import { IncidentEntityDetailsTagsComponent } from './components/entity-details/incident.entity-details-tags/incident.entity-details-tags.component';
import { InvestigatedMachinesTableComponent } from './components/entity-details/investigated-machines-table.component';
import { InvestigationEntityDetailsComponent } from './components/entity-details/investigation.entity-details.component';
import { IpEntityDetailsComponent } from './components/entity-details/ip.entity-details.component';
import { UserEntityDetailsComponent } from './components/entity-details/user-entity-details.component';
import { MachineEntityDetailsComponent } from './components/entity-details/machine.entity-details.component';
import { NetworkEndpointEntityDetailsComponent } from './components/entity-details/network-endpoint.entity-details.component';
import { ProcessEntityDetailsComponent } from './components/entity-details/process.entity-details.component';
import { RegistryModificationEntityDetailsComponent } from './components/entity-details/registry-modification.entity-details.component';
import { UrlEntityDetailsComponent } from './components/entity-details/url.entity-details.component';
import { EntityComponent } from './components/entity.component';
import { EntityDetailsPanelComponent } from './components/entity-details-pane/entity-details-pane.component';
import { EntityUpperBarComponent } from './components/entity-component/entity.upper-bar.component';
import { ActivatedEntity } from './services/activated-entity.service';
import { EntityResolver } from './services/entity.resolver';
import { TvmEntityResolver } from './services/tvm.entity.resolver';
import { SoftwareEntityDetailsComponent } from './components/entity-details/software.entity-details.component';
import { RemediationTaskEntityDetailsComponent } from './components/entity-details/remediation-task.entity-details.component';
import { CommandLineEntityDetailsComponent } from './components/entity-details/command-line.entity-details.component';
import { AccessTokenEntityDetailsComponent } from './components/entity-details/access-token.entity-details.component';
import { VulnerabilityEntityDetailsComponent } from './components/entity-details/vulnerability.entity-details.component';
import { SecurityRecommendationEntityDetailsComponent } from './components/entity-details/security-recommendation.entity-details.component';
import { RecommendationContextPropertiesEntityDetailsComponent } from './components/entity-details/recommendation-context-properties.entity-details.component';
import { ScheduledTaskEntityDetailsComponent } from './components/entity-details/scheduled-task.entity-details.component';
import { TvmModule } from '../tvm/tvm.module';
import { EmailEntityDetailsComponent } from './components/entity-details/email.entity-details.component';
import { LiveResponseSessionEntityDetailsComponent } from './components/entity-details/live-response-session.entity-details.component';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { RecommendationExceptionEntityDetailsComponent } from './components/entity-details/recommendation-exception.entity-details.component';
import { EntityLinkComponent } from './components/entity-link.component';
import { MachineTimelineLinkComponent } from './components/entity-details/machine.timeline-link.component';
import { UserContactDetailsComponent } from '../@entities/users/components/user-contact-details.component';
import { UserNetworkActivityDetailsComponent } from '../@entities/users/components/user-network-activity-details.component';
import { UserSecurityInfoDetailsComponent } from '../@entities/users/components/user-security-info-details.component';
import { MissingKbEntityDetailsComponent } from './components/entity-details/missing-kb/missing-kb.entity-details.component';
import { AntivirusBlockEntityDetailsComponent } from './components/entity-details/antivirus-block.entity-details.component';
import { FileActionEntityDetailsComponent } from './components/entity-details/file-action.entity-details.component';
import { ProcessActionEntityDetailsComponent } from './components/entity-details/process-action.entity-details.component';
import { GlobalEntityDetailsComponent } from './components/entity-details/global-entity-details.component';
import { FileDetectionsSummaryComponent } from './components/entity-details/file-detections-summary.component';
import { EntityRelatedEvidenceComponent } from './components/entity-related-evidence/entity-related-evidence.component';
import { ExposedMachinesDetailsComponent } from './components/entity-details/exposed-machines/exposed-machines.details.component';
import { InstalledMachinesDetailsComponent } from './components/entity-details/exposed-machines/installed-machines.details.component';
import { RecommendationContextDetailsComponent } from './components/entity-details/recommendation-context/recommendation-context.details.component';
import { RelatedCvesDetailsComponent } from './components/entity-details/related-cves/related-cves.details.component';
import { MachineGroupDataviewPanelComponent } from './components/entity-panels/machine-group-dataview.panel.component';
import { ExposedAssetsPanelComponent } from './components/entity-panels/exposed-assets.panel.component';
import { InstalledAssetsPanelComponent } from './components/entity-panels/installed-assets.panel.component';
import { RelatedCvesPanelComponent } from './components/entity-panels/related-cves.panel.component';
import { RecommendationContextKeyPanelComponent } from './components/entity-panels/recommendation-context-key.panel.component';
import { AadUserEntityDetailsComponent } from './components/entity-details/aad-user/aad-user-entity-details.component';
import { MailboxEntityDetailsComponent } from './components/entity-details/mailbox-entity-details.component';
import { PanelsModule } from '@wcd/panels';
import { EntityStatisticsComponent } from './components/entity-widgets/prevalence/entity-statistics.component';
import { EntityNameComponent } from './components/entity-name/entity-name.component';
import { EntityNamesComponent } from './components/entity-name/entity-names.component';
import { RelatedTasksDetailsComponent } from './components/entity-details/related-tasks/related-tasks.details.component';
import { WcdIconsModule } from '@wcd/icons';
import { UsbEventEntityDetailsComponent } from './components/entity-details/usb-event.entity-details.component';
import { BreadcrumbsModule } from '../breadcrumbs/breadcrumbs.module';
import { IncidentRedirectGuard } from './services/incident-redirect-guard.service';
import { AadUserInvestigationPriorityComponent } from './components/entity-details/aad-user/aad-user-investigation-priority.component';
import { ThreatInsightsDetailsComponent } from './components/entity-details/threat-insights/threat-insights.details.component';
import { DetectionLogicDetailsComponent } from './components/entity-details/detection-logic/detection-logic.details.component';
import { AlertInvestigationStatusComponent } from './components/entity-details/alert-investigation-status.component';
import { EntityPanelCollapsibleComponent } from './components/entity-panels/entity-panel-collapsible-section.component';
import { EntityMinimizedDetailsComponentBase } from './components/entity-minimized/entity-minimized-details.component.base';
import { MachineMinimizedDetailsComponent } from './components/entity-minimized/machine.minimized.details.component';
import { UserMinimizedDetailsComponent } from './components/entity-minimized/user.minimized.details.component';
import { EntityHeaderBottomComponentBase } from './components/entity-header-bottom/entity-header-bottom.component.base';
import { MachineHeaderBottomComponent } from './components/entity-header-bottom/machine.header-bottom.component';
import { LiveResponseSessionHeaderBottomComponent } from './components/entity-header-bottom/live-response-session.header-bottom.component';
import { ChangeEventEntityDetailsComponent } from './components/entity-details/change-event.entity-details.component';
import { FabPersonaModule } from '@angular-react/fabric';
import { ProductEvidenceDetailsComponent } from './components/entity-details/evidence/product-evidence.details.component';
import { ReportsModule } from '../reports/reports.module';
import { DataTableModule } from '@wcd/datatable';
import { ComparisonResultEntityDetailsComponent } from '../@entities/@tvm/comparison-tool/components/comparison-result.entity-details.component';
import { MtpPromotionModule } from '../mtp-promotion/mtp-promotion.module';
import { NetworkDeviceFieldComponent } from './components/entity-details/network-device-field/network-device-field.component';
import { NetworkDeviceIconComponent } from './components/entity-details/network-device-field/network-device-icon.component';
import { TvmProductivityImpactComponent } from './components/entity-details/productivity-impact.component';
import { EntityCommandBarComponent } from './components/entity-command-bar/entity-command-bar.component';
import { SoftwareVersionEntityDetailsComponent } from './components/entity-details/software-version.entity-details.component';
import { RecommendationExceptionAggregatedEntityDetailsComponent } from './components/entity-details/recommendation-exception-aggregated.entity-details.component';
import { CollapsibleItemsPreviewComponent } from './components/entity-details/common/collapsible-items-preview.component';
import { DeviceDetails } from './components/entity-details/machine.device-details.component';
import { RelatedExceptionsDetailsComponent } from './components/entity-details/related-exceptions/related-exceptions.details.component';
import { RelatedExceptionsPanelComponent } from './components/entity-panels/related-exceptions.panel.component';
import { IncidentAssociatedIncidentsComponent } from '../@entities/incidents/components/incident.associated-incidents/incident.associated-incidents.component';
import { AssociatedIncidentsPanelComponent } from '../@entities/incidents/components/incident.associated-incidents/associated-incidents-panel/associated-incidents-panel.component';
import { AlertIncidentDetailsComponent } from './components/entity-details/alert-incident-details.component';
import { AlertedActorComponent } from './components/entity-details/alerted-actor.component';
import { NetworkDeviceEntityDetailsComponent } from './components/entity-details/network-device.entity-details.component';
import { RecommendationWorkaroundsPanelComponent } from './components/entity-panels/recommendation-workarounds.panel.component';
import { AlertCustomDetectionRuleDetailsComponent } from './components/entity-details/alert-custom-detection-rule-details.component';
import { LegendModule } from '@wcd/charts';
import { BaselineProfileHeaderBottomComponent } from './components/entity-header-bottom/baseline-profile.header-bottom.component';
import { BaselineConfigurationEntityDetailsComponent } from './components/entity-details/baseline-configuration.entity-details.component';
import { GenericFieldsListComponent } from './components/entity-details/common/generic-fields/generic-fields-list.component';
import { BaselineProfileDeviceEntityDetailsComponent } from './components/entity-details/baseline-profile-device.entity-details.component';
import { IncidentRbacPermissionMessageComponent } from './components/entity-details/incident.rbac.permission.message.component';
import { SuppressionRuleEntityDetailsComponent } from '../@entities/suppression_rules/components/suppression-rule.entity-details.component';
import { CommentsModule } from '../comments/comments.module';
import { AssigneePickerComponent } from '../forms/components/assignee-picker.component';
import { EntityPrevalenceStatisticsComponent } from './components/entity-widgets/prevalence/entity-prevalence-statistics.component';
import { MachineOnboardingStatusMessageComponent } from './components/entity-details/machine.onboarding-status-message.component';
import { AdIotUpSellMessageComponent } from './components/entity-details/machine.adiot-up-sell-message.component';
import { DisputeCategoryPanelComponent } from './components/entity-panels/dispute-category.panel.component';
import { BaselineComplianceStatusComponent } from '../@entities/@tvm/baseline-compliance/components/baseline-configurations/baseline-device-compliance-status.component';
import { MDEConditionsModule } from '../mde-conditions/mde-conditions.module';
import { CollapsibleAssetHardwareAndFirmwareComponent } from './components/entity-details/hardware-and-firmware/components/collapsible-asset-hardware-and-firmware-section.component';

const entityDetailsComponents = [
	EntityPanelCollapsibleComponent,
	AlertEntityDetailsComponent,
	AlertedDescriptionComponent,
	MachineEntityDetailsComponent,
	MachineOnboardingStatusMessageComponent,
	AdIotUpSellMessageComponent,
	DeviceDetails,
	BaselineConfigurationEntityDetailsComponent,
	BaselineComplianceStatusComponent,
	BaselineProfileDeviceEntityDetailsComponent,
	SoftwareEntityDetailsComponent,
	SoftwareVersionEntityDetailsComponent,
	RemediationTaskEntityDetailsComponent,
	RecommendationExceptionEntityDetailsComponent,
	RecommendationExceptionAggregatedEntityDetailsComponent,
	SecurityRecommendationEntityDetailsComponent,
	RecommendationContextPropertiesEntityDetailsComponent,
	VulnerabilityEntityDetailsComponent,
	ComparisonResultEntityDetailsComponent,
	ProductEvidenceDetailsComponent,
	UserContactDetailsComponent,
	UserNetworkActivityDetailsComponent,
	UserSecurityInfoDetailsComponent,
	UserEntityDetailsComponent,
	InvestigatedMachinesTableComponent,
	AlertsManageComponent,
	InvestigationEntityDetailsComponent,
	IncidentEntityDetailsComponent,
	IncidentEntityDetailsTagsComponent,
	EntityComponent,
	EntityDetailsPanelComponent,
	EntityUpperBarComponent,
	EntityLinkComponent,
	FileEntityDetailsComponent,
	CyberEventEntityDetailsComponent,
	ProcessEntityDetailsComponent,
	IpEntityDetailsComponent,
	UrlEntityDetailsComponent,
	NetworkEndpointEntityDetailsComponent,
	RegistryModificationEntityDetailsComponent,
	CommandLineEntityDetailsComponent,
	AccessTokenEntityDetailsComponent,
	EntityDataviewComponent,
	EntityDetailsSectionHeaderComponent,
	ScheduledTaskEntityDetailsComponent,
	EmailEntityDetailsComponent,
	LiveResponseSessionEntityDetailsComponent,
	MissingKbEntityDetailsComponent,
	AntivirusBlockEntityDetailsComponent,
	FileActionEntityDetailsComponent,
	ProcessActionEntityDetailsComponent,
	GlobalEntityDetailsComponent,
	FileDetectionsSummaryComponent,
	EntityRelatedEvidenceComponent,
	ExposedMachinesDetailsComponent,
	InstalledMachinesDetailsComponent,
	RecommendationContextDetailsComponent,
	RelatedCvesDetailsComponent,
	AadUserEntityDetailsComponent,
	MailboxEntityDetailsComponent,
	EntityStatisticsComponent,
	RelatedTasksDetailsComponent,
	ThreatInsightsDetailsComponent,
	DetectionLogicDetailsComponent,
	UsbEventEntityDetailsComponent,
	MachineTimelineLinkComponent,
	EntityMinimizedDetailsComponentBase,
	MachineMinimizedDetailsComponent,
	UserMinimizedDetailsComponent,
	ChangeEventEntityDetailsComponent,
	EntityHeaderBottomComponentBase,
	MachineHeaderBottomComponent,
	LiveResponseSessionHeaderBottomComponent,
	BaselineProfileHeaderBottomComponent,
	TvmProductivityImpactComponent,
	CollapsibleItemsPreviewComponent,
	RelatedExceptionsDetailsComponent,
	RelatedExceptionsPanelComponent,
	IncidentAssociatedIncidentsComponent,
	AssociatedIncidentsPanelComponent,
	AlertIncidentDetailsComponent,
	AlertedActorComponent,
	NetworkDeviceEntityDetailsComponent,
	AlertCustomDetectionRuleDetailsComponent,
	GenericFieldsListComponent,
	SuppressionRuleEntityDetailsComponent,
	EntityPrevalenceStatisticsComponent,
	CollapsibleAssetHardwareAndFirmwareComponent,
];

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		TagsModule,
		FabIconModule,
		LegendModule,
		TvmModule,
		AngularExtensionsModule,
		FabShimmerModule,
		FabSpinnerModule,
		FabMessageBarModule,
		PanelsModule,
		WcdIconsModule,
		BreadcrumbsModule,
		FabPersonaModule,
		ReportsModule,
		DataTableModule,
		MtpPromotionModule,
		CommentsModule,
		MDEConditionsModule,
		FabPeoplePickerModule,
		FabDropdownModule,
	],
	declarations: [
		entityDetailsComponents,
		ExposedAssetsPanelComponent,
		InstalledAssetsPanelComponent,
		RelatedCvesPanelComponent,
		EntityNameComponent,
		EntityNamesComponent,
		AadUserInvestigationPriorityComponent,
		AlertInvestigationStatusComponent,
		NetworkDeviceFieldComponent,
		NetworkDeviceIconComponent,
		RecommendationContextKeyPanelComponent,
		EntityCommandBarComponent,
		MachineGroupDataviewPanelComponent,
		IncidentAssociatedIncidentsComponent,
		RecommendationWorkaroundsPanelComponent,
		IncidentRbacPermissionMessageComponent,
		AssigneePickerComponent,
		DisputeCategoryPanelComponent,
	],
	exports: [
		entityDetailsComponents,
		ExposedAssetsPanelComponent,
		InstalledAssetsPanelComponent,
		RelatedCvesPanelComponent,
		EntityNameComponent,
		EntityNamesComponent,
		AadUserInvestigationPriorityComponent,
		NetworkDeviceFieldComponent,
		NetworkDeviceIconComponent,
		RecommendationContextKeyPanelComponent,
		RecommendationContextPropertiesEntityDetailsComponent,
		EntityCommandBarComponent,
		MachineGroupDataviewPanelComponent,
		RecommendationWorkaroundsPanelComponent,
		IncidentRbacPermissionMessageComponent,
		AssigneePickerComponent,
		DisputeCategoryPanelComponent,
	],
	entryComponents: [
		MachineEntityDetailsComponent,
		MachineOnboardingStatusMessageComponent,
		AdIotUpSellMessageComponent,
		LiveResponseSessionEntityDetailsComponent,
		FileEntityDetailsComponent,
		IpEntityDetailsComponent,
		UrlEntityDetailsComponent,
		BaselineComplianceStatusComponent,
		BaselineConfigurationEntityDetailsComponent,
		BaselineProfileDeviceEntityDetailsComponent,
		SoftwareEntityDetailsComponent,
		SoftwareVersionEntityDetailsComponent,
		RemediationTaskEntityDetailsComponent,
		RecommendationExceptionEntityDetailsComponent,
		SecurityRecommendationEntityDetailsComponent,
		RecommendationContextPropertiesEntityDetailsComponent,
		VulnerabilityEntityDetailsComponent,
		ComparisonResultEntityDetailsComponent,
		IncidentEntityDetailsComponent,
		UserEntityDetailsComponent,
		ExposedAssetsPanelComponent,
		InstalledAssetsPanelComponent,
		RelatedCvesPanelComponent,
		AadUserEntityDetailsComponent,
		MailboxEntityDetailsComponent,
		EntityNameComponent,
		EntityNamesComponent,
		AadUserInvestigationPriorityComponent,
		EntityMinimizedDetailsComponentBase,
		MachineMinimizedDetailsComponent,
		UserMinimizedDetailsComponent,
		NetworkDeviceFieldComponent,
		NetworkDeviceIconComponent,
		MachineHeaderBottomComponent,
		LiveResponseSessionHeaderBottomComponent,
		BaselineProfileHeaderBottomComponent,
		TvmProductivityImpactComponent,
		RecommendationContextKeyPanelComponent,
		EntityCommandBarComponent,
		RelatedExceptionsPanelComponent,
		MachineGroupDataviewPanelComponent,
		IncidentAssociatedIncidentsComponent,
		AssociatedIncidentsPanelComponent,
		NetworkDeviceEntityDetailsComponent,
		RecommendationWorkaroundsPanelComponent,
		SuppressionRuleEntityDetailsComponent,
		DisputeCategoryPanelComponent,
		CollapsibleAssetHardwareAndFirmwareComponent,
	],
})
export class GlobalEntitiesModule {
	static forRoot() {
		return {
			ngModule: GlobalEntitiesModule,
			providers: [EntityResolver, TvmEntityResolver, ActivatedEntity, IncidentRedirectGuard],
		};
	}
}
