import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';

const NOTIFICATION_RULES_API_ENDPOINT = '/notifications/rules';

@Injectable()
export class AirsNotificationRulesBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + NOTIFICATION_RULES_API_ENDPOINT;
	}
}
