import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Tag, Machine } from '@wcd/domain';
import { MachineEntityTypeService } from '../../services/machine.entity-type.service';

@Component({
	selector: 'machines-dataview-tags',
	templateUrl: './machines-dataview-tags.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MachinesDataviewTagsComponent {
	private _machine: Machine;

	@Input()
	set machine(machine: Machine) {
		this._machine = machine;
		this.update();
	}

	tags$: Observable<ReadonlyArray<Tag>>;

	private update() {
		this.tags$ = this.machineEntityTypeService.entityType.pseudoTags.get([this._machine]).pipe(
			map(tags => {
				return tags.concat(this._machine.tags.map(tagStr => ({ id: tagStr, name: tagStr })));
			})
		);
		this.changeDetectionRef.markForCheck();
	}

	constructor(
		private machineEntityTypeService: MachineEntityTypeService,
		private changeDetectionRef: ChangeDetectorRef
	) {}
}
