import { QueryList } from '@angular/core';
import { ReportWidgetsLayoutColumnModel } from '../models/report-widgets-layout.column.model';
import { ReportWidgetDynamicComponent } from './report-widget.component';
import { flatMap } from 'lodash-es';
var ReportColumnComponent = /** @class */ (function () {
    function ReportColumnComponent() {
        this.headerLevel = 2;
    }
    Object.defineProperty(ReportColumnComponent.prototype, "allWidgets", {
        get: function () {
            return this.widgets
                .toArray()
                .map(function (dynamicWidget) { return dynamicWidget.widgetComponent; })
                .concat((flatMap(this.rows.toArray().map(function (row) { return row.allWidgets; }))));
        },
        enumerable: true,
        configurable: true
    });
    ReportColumnComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    return ReportColumnComponent;
}());
export { ReportColumnComponent };
