import { EvidenceDetectionSourceType } from './evidence-detection-source-type.entity';
import { EvidenceDetectionSourceTypeEnum } from './evidence-detection-source-type.enum';

export const airsEntityDetectionSourceTypes: Array<EvidenceDetectionSourceType> = [
	{
		id: EvidenceDetectionSourceTypeEnum.alert,
		name: 'Alert',
		iconName: 'alert',
		entityTypeId: 'alert',
	},
	{
		id: EvidenceDetectionSourceTypeEnum.investigation,
		name: 'Investigation',
		iconName: 'investigation',
		entityTypeId: 'investigation-action',
	},
];
