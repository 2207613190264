import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

/**
 * old alert incident graph element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Incident Graph Entity',
	pluralName: 'Alert Incident Graph Entity',
})
export class AlertIncidentGraphItemEntity extends ModelBase {
	@EntityField() name: string;
}
