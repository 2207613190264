import { QueryList } from '@angular/core';
import { ReportWidgetsLayoutRowModel } from '../models/report-widgets-layout.row.model';
import { ReportWidgetDynamicComponent } from './report-widget.component';
import { flatMap } from 'lodash-es';
var ReportRowComponent = /** @class */ (function () {
    function ReportRowComponent() {
        this.headerLevel = 2;
    }
    Object.defineProperty(ReportRowComponent.prototype, "allWidgets", {
        get: function () {
            return this.widgets
                .toArray()
                .map(function (dynamicWidget) { return dynamicWidget.widgetComponent; })
                .concat((flatMap(this.columns.toArray().map(function (column) { return column.allWidgets; }))));
        },
        enumerable: true,
        configurable: true
    });
    return ReportRowComponent;
}());
export { ReportRowComponent };
