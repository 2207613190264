import {
	FabButtonModule,
	FabIconModule,
	FabMessageBarModule,
	FabCommandBarModule,
	FabSpinnerModule,
} from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { SharedModule } from '../../../../shared/shared.module';
import { StandardDiscoveryMessageComponent } from './standard-discovery.component';

@NgModule({
	imports: [
		SharedModule,
		FabButtonModule,
		FabIconModule,
		FabMessageBarModule,
		FabCommandBarModule,
		FabSpinnerModule,
	],
	providers: [],
	declarations: [StandardDiscoveryMessageComponent],
	exports: [StandardDiscoveryMessageComponent],
	entryComponents: [],
})
export class StandardDiscoveryMessageModule {}
