import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AirsActiveDirectoryDomain } from '@wcd/domain';

@Component({
	selector: 'airs-active-directory-domain-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.firstSeen">
				<dt role="none">
					{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_firstSeen }}
				</dt>
				<dd role="none">{{ entity.firstSeen | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_name }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.id">
				<dt role="none">{{ i18nService.strings.airsEntities_activeDirectoryDomain_fields_id }}</dt>
				<dd role="none">{{ entity.id }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsActiveDirectoryDomainDetailsComponent {
	@Input() entity: AirsActiveDirectoryDomain;

	constructor(public i18nService: I18nService) {}
}
