<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="software" class="tvm wcd-scroll-vertical" [ngClass]="{ 'wcd-margin-medium-top': mode !== 'EntityPage'}">
	<wcd-dl [display]="dlDisplayMode">
		<div class="span-2 wcd-margin-small-bottom" *ngIf="software.productNeverMatched">
			<fab-message-bar [messageBarType]="MessageBarType.info">
				{{'tvm_softwareInventory_productNeverMatchedMessage' | i18n}}
			</fab-message-bar>
		</div>
		<div class="span-2 wcd-margin-small-bottom" *ngIf="showZeroDayMessage">
			<fab-message-bar  [messageBarType]="MessageBarType.severeWarning">
				{{'tvm_software_zeroDay_notification' | i18n}}
			</fab-message-bar>
		</div>
		<div *ngIf="showEolMessage" class="span-2 wcd-padding-bottom">
			<fab-message-bar
				[messageBarType]="messageBarType"
				[isMultiline]="false"
				[truncated]="shouldShowLegalNote"
				[overflowButtonAriaLabel]="i18nService.strings.entityCommon_panel_impactedEntities_showMore"
				[styles]="getStyles.bind(this)">
				<div class="spaced">{{eolText}}<ng-container *ngIf="software.hasEolVersions || software.hasUpcomingEolVersions">{{'tvm.securityRecommendation.goToSoftwarePagePrefix' | i18n}}<a (click)="openSoftwareVersions()">{{softwareVersionsLinkText}} </a>{{'tvm.securityRecommendation.goToSoftwarePagePostfix' | i18n}}</ng-container></div>
				<div class="wcd-margin-top" *ngIf="messageBarState.showBody" >{{legalNote}}<ng-container *ngIf="showLegalNoteLink"><a [href]="legalNoteLink">{{legalNoteLinkText}}</a></ng-container></div>
			</fab-message-bar>
		</div>

		<div class="title">{{('tvm.software.entity.details.title'| i18n)}}</div>

		<dl>
			<dt>{{ ('tvm.common.vendor' | i18n) }}</dt>
			<dd>{{software.vendor || ('notAvailable.short' | i18n)}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ i18nService.strings.tvm_common_osPlatform }}</dt>
			<dd>{{osPlatformText}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item" *ngIf="showEolDate">
			<dt>{{ i18nService.strings.tvm_software_eolDate }}</dt>
			<dd>{{software.eolSoftwareSinceDate | date:'shortDate'}}</dd>
		</dl>

		<ng-container *ngIf="software.isNormalized">
			<div class="title">{{ ('file.quarantine.details.fileDetails.prevalenceOrganization' | i18n) }}</div>

			<dl>
				<dt>{{ ('tvm.versionDetails.installedOn' | i18n) }}</dt>
				<dd>{{software.assetsStatistics.totalAssetCount | prettyNumber}}</dd>
			</dl>
			<ng-container *ngIf="!software.productNeverMatched">
				<dl>
					<dt>{{exposedDevicesTitle}}</dt>
					<dd>{{software.assetsStatistics.assetsAtRiskCount | prettyNumber}}</dd>
				</dl>
			</ng-container>
		</ng-container>
		<ng-container *ngIf="!software.productNeverMatched">
			<div class="title">{{ ('tvm.software.entity.details.threatContext' | i18n) }}</div>

			<dl>
				<dt>{{ ('tvm.vulnerability.exploitsAvailable' | i18n) }}</dt>
				<dd>{{software.threatInfo.hasExploit ? ('common.yes' | i18n) : ('common.no' | i18n)}}</dd>
			</dl>

			<div class="title">{{ ('tvm.software.entity.details.recommendations' | i18n) }}</div>

			<dl>
				<dt>{{ ('tvm.common.impact' | i18n) }}</dt>
				<dd>
					<tvm-impact-score
						[scoreData]="{exposureScore: software.exposureScoreImprovement, exposureScoreTooltip: tooltip}">
					</tvm-impact-score>
				</dd>
			</dl>
		</ng-container>
	</wcd-dl>

	<product-evidence-details *ngIf="installation && isProductEvidenceEnabled" [assetId]="installation.assetId"
		[productId]="installation.getProductId()" [productVersion]="installation.installedVersion" [nonCpeProgram] = "!software.isNormalized">
	</product-evidence-details>

</div>
