import { Component } from '@angular/core';
import { HuntingRule } from '@wcd/domain';
import { DataEntityType } from '@microsoft/paris';
import { EntityDataViewOptions } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { TitleService } from '../../../shared/services/title.service';
import { AppInsightsService } from 'app/insights/services/app-insights.service';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { sccHostService } from '@wcd/scc-interface';

@Component({
	selector: 'scheduled-hunting-dataview',
	template: `
		<entity-dataview
			id="scheduled-hunting"
			[entityType]="entityType"
			[options]="dataViewOptions"
			[queueHeader]="true"
			[padLeft]="false"
			[focusOnTable]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
			commandBarNoPadding="true"
			responsiveActionBar="true"
			responsiveLayout="true"
			[removePadding]="isScc"
			[removePaddingRight]="isScc"
			[label]="'settings.navItem.customDetections' | i18n"
			[entitySidePanelSettings]="{ role: 'none' }"
		>
			></entity-dataview
		>
	`,
})
export class ScheduledHuntingDataviewComponent {
	readonly entityType: DataEntityType<HuntingRule>;
	readonly dataViewOptions: EntityDataViewOptions<HuntingRule, {}>;
	isScc = sccHostService.isSCC;

	constructor(
		globalEntityTypesService: GlobalEntityTypesService,
		private appInsights: AppInsightsService,
		public readonly titleService: TitleService,
		private performanceSccService: PerformanceSccService,
	) {
		this.entityType = HuntingRule;
		this.dataViewOptions = globalEntityTypesService.getEntityType<HuntingRule>(HuntingRule)
			.dataViewOptions as EntityDataViewOptions<HuntingRule, {}>;
	}

	onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('queue');
	}
}
