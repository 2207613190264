import { dynamicSort } from '../helpers/dynamicSort';
import { SevilleModule } from '../seville.module';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';

SevilleModule.run([
	'$rootScope',
	'sidePaneService',
	function($rootScope, sidePaneService) {
		$rootScope.$on('$stateChangeStart', function() {
			var instance = sidePaneService.getCurrentPaneInstance();
			if (instance) {
				sidePaneService.close(instance);
			}
		});
	},
]);

SevilleModule.provider('sidePaneService', sidePaneService);

sidePaneService.$inject = [];

function sidePaneService() {
	var provider = {
		$get: [
			'$rootScope',
			'$q',
			'entityPanelsService',
			function($rootScope, $q, entityPanelsService: EntityPanelsService) {
				var pane: any = {};
				var currentPaneInstance;
				var supportedPaneTypes = ['entity', 'content'];
				var supportedPropTypes = [
					'primitive',
					'link',
					'activeAlerts',
					'timeago',
					'date',
					'datetime',
					'scrollable-block',
					'copy-block',
					'button',
					'clickable',
					'tags',
				];

				function destroyPane(instance, skipBroadcast) {
					instance.opened = false;

					for (var i = 0; i < instance.requestsCancelers.length; i++) {
						instance.requestsCancelers[i].resolve();
					}

					instance.requestsCancelers = [];

					if (!skipBroadcast) {
						broadcastUpdate(instance);
					}
				}

				function validateProps(props) {
					if (!props) return;
					if (!Array.isArray(props)) {
						throw new Error('props should be an array');
					}

					for (var i = 0; i < props.length; i++) {
						var prop = props[i];
						if (!prop.key) {
							throw new Error('property is missing key');
						}

						if (!prop.type) {
							throw new Error('property is missing type');
						}

						if (!~supportedPropTypes.indexOf(prop.type)) {
							throw new Error('property type ' + prop.type + ' is not supported');
						}

						if (prop.link) {
							if (!prop.link.icon)
								throw new Error('property link should have an icon specified');

							if (!prop.link.state && !prop.link.href)
								throw new Error('property link should have href or state specified');
						}

						if (prop.type == 'button') {
							if (!prop.value || !prop.value.method) {
								throw new Error('property button method should be specified');
							}
						}

						if (prop.type == 'link') {
							if (!prop.value.text)
								throw new Error(
									"property of type link should have text specified in it's value"
								);

							if (!prop.value.state && !prop.value.href)
								throw new Error(
									"property of type link should have href or state specified in it's value"
								);
						}

						if (prop.type == 'clickable') {
							if (!prop.value || !prop.value.html || !prop.value.method)
								throw new Error(
									'poperty of type clickable should have html and method specified.'
								);
						}
					}
				}

				function validateSections(sections) {
					if (!sections) return;
					if (!Array.isArray(sections)) {
						throw new Error('sections should be an array');
					}

					for (var i = 0; i < sections.length; i++) {
						var section = sections[i];
						if (!section.title) {
							throw new Error('section title is missing');
						}

						if (section.loading == undefined) {
							section.loading = true;
						}
					}
				}

				function sortArray(props) {
					if (!props) return [];
					return props.sort(dynamicSort('order'));
				}

				function broadcastUpdate(instance) {
					if (instance.key != currentPaneInstance.key) return;

					$rootScope.$broadcast('sidePane:paneUpdated', instance);
				}

				pane.open = function(paneOptions) {
					if (!paneOptions || !paneOptions.type || !paneOptions.title) {
						throw new Error('pane options must be specified with type & paneTitle');
					}

					if (!~supportedPaneTypes.indexOf(paneOptions.type)) {
						throw new Error('pane type ' + paneOptions.type + ' is not supported');
					}

					if (!paneOptions.key) {
						throw new Error(
							'pane options must be specified with unique key, which represent this pane content, type: ' +
								paneOptions.type +
								' title:' +
								paneOptions.title
						);
					}

					if (paneOptions.link && (!paneOptions.link.text || !paneOptions.link.state)) {
						throw new Error('link was specified but link state \\ text is missing');
					}

					if (
						paneOptions.type == 'content' &&
						(!paneOptions.controller || (!paneOptions.templateUrl && !paneOptions.template))
					) {
						throw new Error(
							'controller and templateUrl are mandatory when opening content sidepane'
						);
					}

					validateProps(paneOptions.props);
					validateSections(paneOptions.sections);

					if (currentPaneInstance) {
						pane.close(currentPaneInstance, true);
					}

					var newPaneInstance = {
						key: paneOptions.key,
						type: paneOptions.type,
						paneTitle: paneOptions.title,
						titleStyle: paneOptions.titleStyle,
						icon: paneOptions.icon,
						props: paneOptions.props == undefined ? [] : paneOptions.props,
						opened: paneOptions.opened == undefined ? true : false,
						loading: paneOptions.loading == undefined ? true : paneOptions.loading,
						sections: paneOptions.sections == undefined ? [] : sortArray(paneOptions.sections),
						template: paneOptions.template,
						controller:
							paneOptions.controller == undefined
								? undefined
								: paneOptions.controllerAs
								? paneOptions.controller + ' as ' + paneOptions.controllerAs
								: paneOptions.controller,
						requestsCancelers: [],
						link: paneOptions.link
							? {
									text: paneOptions.link.text,
									state: paneOptions.link.state,
									params: paneOptions.link.params,
									href: paneOptions.link.href,
							  }
							: undefined,
						dismiss: function() {
							paneOptions.dismissed = true;
						},
						close: function() {
							pane.close(this);
						},
						setProps: function(props) {
							validateProps(props);

							this.props = sortArray(this.props.concat(props));
							broadcastUpdate(this);
						},
						setLoading: function(isLoading) {
							this.loading = isLoading;
							broadcastUpdate(this);
						},
						updateTitle: function(title) {
							if (!title) {
								throw new Error('pane title is required');
							}

							this.title = title;
							broadcastUpdate(this);
						},
						setError: function(errorMsg) {
							if (!errorMsg) {
								throw new Error('error message is required');
							}

							this.loading = false;
							this.paneTitle = 'Error';
							this.errorMsg = errorMsg;
							broadcastUpdate(this);
						},
						addSection: function(title, isLoading, props) {
							if (!this.opened || !this.sections) return;
							if (title == undefined) {
								throw new Error('section title is mandatory');
							}

							if (isLoading == undefined) {
								isLoading = true;
							}

							validateProps(props);
							props = sortArray(props);

							this.sections.push({
								title: title,
								loading: isLoading,
								props: props || [],
							});

							this.sections = sortArray(this.sections);

							broadcastUpdate(this);
						},
						setSection: function(title, props) {
							if (!this.opened || !this.sections) return;
							if (title == undefined) {
								throw new Error('section title is mandatory');
							}

							validateProps(props);

							var section = this.sections.filter(function(e) {
								return e.title == title;
							});
							if (!section || section.length == 0) {
								section = {
									title: title,
									loading: false,
									props: [],
								};

								this.sections.push(section);
								this.sections = sortArray(this.sections);
							} else {
								section = section[0];
							}

							if (!section.props || !Array.isArray(section.props)) {
								section.props = [];
							}

							section.props = sortArray(section.props.concat(props));

							broadcastUpdate(this);
						},
						setSectionError: function(title, errorMsg) {
							if (!errorMsg) {
								throw new Error('error message is required');
							}

							var section = this.sections.filter(function(e) {
								return e.title == title;
							});
							if (!section || section.length == 0) {
								throw new Error('section ' + title + ' does not exist');
							}

							section[0].loading = false;
							section[0].errorMsg = errorMsg;
							broadcastUpdate(this);
						},
						setSectionLoading: function(title, isLoading) {
							var section = this.sections.filter(function(e) {
								return e.title == title;
							});
							if (!section || section.length == 0) {
								throw new Error('section ' + title + ' does not exist');
							}

							section[0].loading = isLoading;
							broadcastUpdate(this);
						},
						isSelected: function(key) {
							if (!this.opened || !this.key) return false;
							if (!Array.isArray(this.key)) return this.key == key;

							if (!Array.isArray(key)) return this.key.indexOf(key) >= 0;

							// both keys are arrays - return true if key is contained in this.key
							for (var i = 0; i < key.length; i++) {
								var keyPart = key[i];
								if (this.key.indexOf(keyPart) < 0) return false;
							}

							return true;
						},
						getSection: function(title) {
							var section = this.sections.filter(function(e) {
								return e.title == title;
							});
							if (!section || section.length == 0) {
								throw new Error('section ' + title + ' does not exist');
							}

							return section[0];
						},
						requestsCanceler: function() {
							var canceler = $q.defer();
							this.requestsCancelers.push(canceler);

							return canceler.promise;
						},
					};

					currentPaneInstance = newPaneInstance;

					broadcastUpdate(newPaneInstance);

					// close any entity side pane hiding our pane
					entityPanelsService.closeAllEntityPanels();

					return newPaneInstance;
				};

				pane.getCurrentPaneInstance = function() {
					return currentPaneInstance;
				};

				pane.getOpenPaneKey = function() {
					return currentPaneInstance && currentPaneInstance.opened ? currentPaneInstance.key : null;
				};

				pane.close = function(instance, skipBroadcast) {
					var paneInstance = instance || pane.getCurrentPaneInstance();

					if (paneInstance) {
						destroyPane(paneInstance, skipBroadcast);
					}
				};

				pane.isSelected = function(key) {
					return (
						currentPaneInstance &&
						currentPaneInstance.opened &&
						currentPaneInstance.isSelected(key)
					);
				};

				return pane;
			},
		],
	};

	return provider;
}
