import { WcdPortalParisConfig } from '@wcd/domain';
import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { MagellanFeatures } from './magellan-features.entity';

@ApiCall({
	endpoint: 'settings/v2/MagellanFeatures',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
	name: 'Get proactive device discovery settings',
	method: 'GET',
	parse: MagellanFeatures.From,
})
export class GetMagellanSettingsApiCall extends ApiCallModel<MagellanFeatures, void> {}
