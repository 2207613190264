import { keyBy } from 'lodash-es';
import { FirstSeen } from './device-first-seen.enum';
var ɵ0 = FirstSeen.Last7Days, ɵ1 = FirstSeen.Over7DaysAgo;
export var firstSeenValues = [
    {
        id: ɵ0,
        i18nNameKey: 'devices_page_last_7_days',
        priority: 1,
    },
    {
        id: ɵ1,
        i18nNameKey: 'devices_page_over_7_days',
        priority: 2,
    },
];
export var FIRST_SEEN_DEFAULT_TIME_COMPARISON = '7';
var ɵ2 = function (o) { return o.id; };
export var firstSeenValuesMap = keyBy(firstSeenValues, ɵ2);
export { ɵ0, ɵ1, ɵ2 };
