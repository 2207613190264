<ng-container *ngIf="machine">
	<wcd-datatable
		[items]="[machine]"
		[columns]="machineFields"
		[selectEnabled]="false"
		[showHeaders]="true"
		class="table-with-legend"
		(itemClick)="showMachineSidePanel($event.item)"
		[label]="'alerts.panel.impactedAssets.machines.table' | i18n">
	</wcd-datatable>
</ng-container>

<ng-container *ngIf="user">
	<wcd-datatable
		[items]="[user]"
		[columns]="userFields"
		[selectEnabled]="false"
		[showHeaders]="true"
		class="table-with-legend"
		(itemClick)="showUserSidePanel($event.item)"
		[label]="'alerts.panel.impactedAssets.users.table' | i18n">
	</wcd-datatable>
</ng-container>
