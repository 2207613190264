import { Entity, EntityField, EntityModelBase, HttpOptions } from '@microsoft/paris';
import { SystemExclusionListType } from './system-exclusion-list-type.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Allowed/Blocked list rule',
	pluralName: 'Allowed/Blocked list rules',
	endpoint: 'acl',
	allItemsProperty: 'results',
	allItemsEndpoint: '/all',
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
	parseSaveQuery: (item: SystemExclusion, entity, config, options?: HttpOptions) => {
		let suffix: string;
		if (!item.isNew) suffix = String(item.id);
		else if (options && options.params && options.params['type'] === 'certificate')
			suffix = 'certificate/upload';
		else suffix = 'all';
		return 'acl/' + suffix;
	},
	serializeItem: (item: SystemExclusion, modelData) => {
		const apiObj = Object.assign(modelData, item.properties);
		delete apiObj.file_hash_type;

		for (const p in apiObj) {
			if (apiObj[p] === undefined || apiObj[p] === null) delete apiObj[p];
		}

		if (apiObj.ip) {
			delete apiObj.from_ip;
			delete apiObj.to_ip;
		}

		if (apiObj['file[]']) {
			const formData = new FormData();

			for (const p in apiObj) formData.append(p, apiObj[p]);

			return formData;
		}

		return apiObj;
	},
})
export class SystemExclusion extends EntityModelBase {
	@EntityField({ data: 'creation_time' })
	creationTime: Date;

	@EntityField({ data: 'creator_name' })
	creatorName: string;

	@EntityField({ data: 'acl_type' })
	listType: SystemExclusionListType;

	@EntityField({
		defaultValue: Object.freeze({}),
		parse: properties => {
			return properties && properties.from_ip
				? Object.assign(
						{
							ip:
								properties.to_ip !== properties.from_ip
									? `${properties.from_ip} - ${properties.to_ip}`
									: properties.from_ip,
						},
						properties
				  )
				: properties;
		},
	})
	properties: any;

	@EntityField({ data: 'entity_id' })
	entityId: number | string;

	@EntityField({ data: ['file[]', 'file'] })
	file: File;

	get isReadonly(): boolean {
		return (this.properties && this.properties.issuer) || false;
	}

	get isNew(): boolean {
		return this.id === undefined;
	}
}
