import { Pipe, PipeTransform } from '@angular/core';
import { RemediationTask, RemediationTaskStateValue, RemediationType } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

declare const moment: typeof import('moment');

@Pipe({ name: 'remediationTaskDisplayRemainingDays' })
export class RemediationTaskDisplayRemainingDaysPipe implements PipeTransform {
	constructor(private i18nService: I18nService) {}

	transform(remediationTask: RemediationTask, remainingDays: number) {
		if (
			remediationTask.status.value === RemediationTaskStateValue.Completed ||
			(remediationTask.taskArgs.softwareArgs &&
				remediationTask.taskArgs.softwareArgs.taskType === RemediationType.AttentionRequired)
		) {
			return this.i18nService.strings.tvm_remediationTask_notApply;
		} else if (remainingDays < 0) {
			return `Past due (${Math.abs(remainingDays)} days)`;
		} else {
			return `${remainingDays} days`;
		}
	}
}
