
		<modal (close)="destroy()" [settings]="settings">
			<div class="wcd-padding-large-bottom wcd-padding-large-horizontal">
				<div class="wcd-padding-bottom" *ngIf="lab && provisionedMachines">
					{{ getDescription() }}
				</div>
				<markdown
					[data]="
						'evaluation.dashboard.dataView.commandBar.addMachine.saveConnectionDetails' | i18n
					"
				></markdown>
				<div class="wcd-margin-top">
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.machineName' | i18n }}
					</span>
					{{ machineName }}
				</div>
				<div>
					<span class="wcd-font-weight-bold">
						{{ 'evaluation.dashboard.dataView.commandBar.addMachine.userName' | i18n }}
					</span>
					{{ evaluationService.getMachineUserName() }}
				</div>
				<fab-text-field [value]="password" [readOnly]="true" [disabled]="true"></fab-text-field>
				<copy-to-clipboard
					[content]="password"
					[originId]="'evaluation-machine-password'"
					class="wcd-flex-items-end-vertical"
				>
				</copy-to-clipboard>
			</div>
		</modal>
	