import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { EvaluationInvestigation } from './evaluation-investigation.value-object';

@ValueObject({
	singularName: 'Evaluation investigations report',
	pluralName: 'Evaluation investigations report',
})
export class EvaluationInvestigations extends ModelBase {
	@EntityField({ data: 'Investigations', arrayOf: EvaluationInvestigation })
	investigations: Array<EvaluationInvestigation>;

	@EntityField({ data: 'KeyFindingsCount' })
	keyFindings: number;
}
