/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "./file-detections-summary.component";
import * as i5 from "@microsoft/paris/dist/lib/paris";
import * as i6 from "../../../dialogs/services/dialogs.service";
var styles_FileDetectionsSummaryComponent = [];
var RenderType_FileDetectionsSummaryComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FileDetectionsSummaryComponent, data: {} });
export { RenderType_FileDetectionsSummaryComponent as RenderType_FileDetectionsSummaryComponent };
function View_FileDetectionsSummaryComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "a", [["class", "wcd-font-weight-regular"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showFileVerdictPanel(_v.parent.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(1, null, ["\n\t\t\t\t", "\n\t\t\t"])), i0.ɵppd(2, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i0.ɵnov(_v.parent.parent, 0), "files.entityDetails.sections.detections.fields.malwareDetected.seeDetails")); _ck(_v, 1, 0, currVal_0); }); }
function View_FileDetectionsSummaryComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 10, "div", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpod(3, { "wcd-flex-vertical wcd-flex-items-start": 0, "wcd-flex-center-vertical wcd-flex-wrap": 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "span", [["class", "wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i0.ɵted(6, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_FileDetectionsSummaryComponent_2)), i0.ɵdid(9, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.showLinkBelow, !_co.showLinkBelow); _ck(_v, 2, 0, currVal_0); var currVal_2 = ((_v.context.ngIf.length !== 0) && _co.showFileVerdictPanelLink); _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getFileVerdictText(_v.context.ngIf); _ck(_v, 6, 0, currVal_1); }); }
export function View_FileDetectionsSummaryComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_FileDetectionsSummaryComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i1.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.fileVerdicts$)); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_FileDetectionsSummaryComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "file-detections-summary", [], null, null, null, View_FileDetectionsSummaryComponent_0, RenderType_FileDetectionsSummaryComponent)), i0.ɵdid(1, 114688, null, 0, i4.FileDetectionsSummaryComponent, [i3.I18nService, i5.Paris, i6.DialogsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FileDetectionsSummaryComponentNgFactory = i0.ɵccf("file-detections-summary", i4.FileDetectionsSummaryComponent, View_FileDetectionsSummaryComponent_Host_0, { file: "file", showFileVerdictPanelLink: "showFileVerdictPanelLink", showLinkBelow: "showLinkBelow" }, {}, []);
export { FileDetectionsSummaryComponentNgFactory as FileDetectionsSummaryComponentNgFactory };
