import { ComponentRef, Injector } from '@angular/core';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { InvestigationCommentsPanelComponent } from '../../investigations_common/components/investigation-comments.panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService } from '@wcd/auth';
import { Investigation, InvestigationStatus, Tag } from '@wcd/domain';
import { InvestigationsBackendService } from './investigations.backend.service';
import { CancelInvestigationModalComponent } from '../../investigations_common/components/cancel-investigation.modal';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { from, never, of } from 'rxjs';
import { DataQuerySortDirection, Paris } from '@microsoft/paris';
import { TagsStore } from '../../../tags/services/tags.store';
import { TagModel } from '../../../tags/models/tags.model';
import { TagsService } from '../../../tags/services/tags.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { Router } from '@angular/router';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { Feature, FeaturesService, PollingService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { compact, keyBy } from 'lodash-es';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
var INVESTIGATION_REFRESH_RATE = 5000;
var SEVILLE_STATUS_TO_STATUS = keyBy(InvestigationStatus.entityConfig.values, 'sevilleStatusId');
var InvestigationsService = /** @class */ (function () {
    function InvestigationsService(dialogsService, authService, tagsStore, paris, injector, router, featuresService, i18nService, backendService, pollingService) {
        this.dialogsService = dialogsService;
        this.authService = authService;
        this.tagsStore = tagsStore;
        this.paris = paris;
        this.injector = injector;
        this.router = router;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.backendService = backendService;
        this.pollingService = pollingService;
    }
    InvestigationsService.prototype.showCurrentInvestigationCommentsPanel = function (investigation, investigationGraphData) {
        var _this = this;
        var panelSettings = {
            id: 'investigation-comments-panel',
            type: PanelType.medium,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            noBodyPadding: true,
            scrollBody: false,
        };
        var panelInputs = {
            investigation: investigation,
            investigationGraphData: investigationGraphData,
        };
        this.dialogsService
            .showPanel(InvestigationCommentsPanelComponent, panelSettings, panelInputs)
            .subscribe(function (panel) {
            _this._commentsPanel = panel;
            panel.onDestroy(function () {
                _this._commentsPanel = null;
            });
        });
    };
    InvestigationsService.prototype.getInvestigationPolling = function (investigationId) {
        var _this = this;
        var poll$ = this.pollingService
            .poll(0, INVESTIGATION_REFRESH_RATE)
            .pipe(mergeMap(function () {
            return _this.paris.getItemById(Investigation, investigationId).pipe(catchError(function (error) {
                if (error.status === 403 &&
                    _this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
                    var rbacService = _this.injector.get(RbacService);
                    rbacService.addToExposureCache(Investigation, String(investigationId), { isExposed: false });
                    rbacService.showNoAccessModal(_this.i18nService.strings.rbac_accessDenied_description_investigation);
                    _this.router.navigate(['/investigations']);
                    return never();
                }
                else {
                    console.warn("Investigation polling failed. trying again in " + INVESTIGATION_REFRESH_RATE /
                        1000 + " seconds.");
                    return of(error);
                }
            }));
        }));
        return poll$;
    };
    InvestigationsService.prototype.getInvestigationActions = function (investigation) {
        return this.getInvestigationsActions([investigation]);
    };
    InvestigationsService.prototype.getInvestigationsActions = function (investigations) {
        var actions = [
        // new ItemActionModel({
        // 	id: 'investigationTags',
        // 	rbac: ['alertsInvestigation'],
        // 	nameKey: 'tags.manage',
        // 	type: ItemActionType.Tags,
        // 	icon: 'tag',
        // 	refreshOnResolve: true,
        // 	options: {
        // 		tags: this.getInvestigationTagsSettings(investigations),
        // 	},
        // }),
        ];
        if (investigations.every(function (investigation) { return investigation.isRunning && !investigation.isOfficeInvestigation; }))
            actions.push(this._investigationDataViewActions.cancel);
        return compact(actions);
    };
    InvestigationsService.prototype.getInvestigationTagsSettings = function (investigations) {
        var _this = this;
        return {
            getTags: function (investigations) {
                return of(TagsService.getTagsArray(investigations, function (investigation) { return investigation.tags; }));
            },
            setTags: function (tags, addedTags, removedTags) {
                var investigationIds = investigations.map(function (investigation) { return investigation.id; });
                if (addedTags && addedTags.length)
                    return _this.tagsStore.addTagsToObjects(new TagModel(addedTags[0]), 'investigation', investigationIds);
                else if (removedTags && removedTags.length)
                    return _this.tagsStore.removeTagsFromObjects(new TagModel(removedTags[0]), 'investigation', investigationIds);
                return of(null);
            },
            searchFunction: function (term) {
                return _this.paris
                    .query(Tag, {
                    pageSize: 6,
                    where: { search: term },
                    sortBy: [
                        {
                            field: 'popularity',
                            direction: DataQuerySortDirection.descending,
                        },
                    ],
                })
                    .pipe(map(function (dataSet) {
                    return dataSet.items.map(function (tag) {
                        return {
                            label: tag.name,
                            value: tag.id,
                            item: tag,
                        };
                    });
                }));
            },
            createNewTag: function (tag) { return from(_this.tagsStore.save(new TagModel(tag))); },
            searchSettings: {},
        };
    };
    Object.defineProperty(InvestigationsService.prototype, "_investigationDataViewActions", {
        get: function () {
            if (!this._investigationDataViewActionsIndex) {
                var investigationActions = compact([
                    !this.authService.currentUser.isReadonly
                        ? {
                            id: 'cancel',
                            nameKey: 'cancel',
                            icon: 'stopInvestigation',
                            method: this.cancelInvestigations.bind(this),
                            tooltip: this.i18nService.strings.investigations_actions_cancel_tooltip,
                            refreshOnResolve: true,
                            rbac: [RbacMdeAllowedActions.alertsInvestigation],
                        }
                        : null,
                ]).map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
                this._investigationDataViewActionsIndex = keyBy(investigationActions, 'id');
            }
            return this._investigationDataViewActionsIndex;
        },
        enumerable: true,
        configurable: true
    });
    InvestigationsService.prototype.cancelInvestigations = function (investigations, options) {
        var _this = this;
        var runningInvestigations = investigations &&
            investigations.filter(function (investigation) { return investigation.isRunning && !investigation.isOfficeInvestigation; });
        return new Promise(function (resolve, reject) {
            _this.getCancelInvestigationComment('investigation', runningInvestigations ? runningInvestigations.length : null).then(function (cancelData) {
                _this.backendService
                    .cancelInvestigations(runningInvestigations
                    ? runningInvestigations.map(function (investigation) { return investigation.id; })
                    : null, cancelData, options)
                    .subscribe(function (result) {
                    var count = result && result.count !== undefined
                        ? result.count
                        : (investigations && investigations.length) || 0, notificationText = count
                        ? count > 1
                            ? _this.i18nService.get('investigations_actions_cancel_successMessage_plural', {
                                investigationCount: count,
                            })
                            : _this.i18nService.strings
                                .investigations_actions_cancel_successMessage_singular
                        : _this.i18nService.strings
                            .investigations_actions_cancel_successMessage_general;
                    _this.dialogsService.showSnackbar({
                        text: notificationText,
                        icon: 'stopInvestigation',
                    });
                    resolve(result);
                }, function (error) {
                    var title = _this.i18nService.strings
                        .investigations_actions_cancel_errorMessage;
                    _this.dialogsService.showError({
                        title: title,
                        data: error,
                    });
                    reject(title);
                });
            }, function (error) { return reject(error); });
        });
    };
    InvestigationsService.prototype.getCancelInvestigationComment = function (cancelReasonsType, investigationCount, hostName) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var modal;
            _this.dialogsService
                .showModal(CancelInvestigationModalComponent, {
                id: 'cancel-investigation-modal',
                dimensions: new DimensionsModel(480, 350),
                title: cancelReasonsType === 'host'
                    ? investigationCount > 1
                        ? _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_plural_host
                        : _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_singular_host
                    : investigationCount > 1
                        ? _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_plural
                        : _this.i18nService.strings
                            .investigations_actions_cancel_approvalMessage_singular,
            }, {
                investigationCount: investigationCount,
                reasonsType: cancelReasonsType,
                confirm: function (cancelData) {
                    resolve(cancelData);
                    modal.destroy();
                },
                cancel: function () { return modal.destroy(); },
                hostName: hostName,
            })
                .subscribe(function (_modal) {
                modal = _modal;
                _modal.onDestroy(function () { return reject(); });
            });
        });
    };
    /**
     * This is only for AngularJS, which can't access Paris directly.
     * @param {string} investigationStatusTypeName
     * @returns {InvestigationStatus}
     */
    InvestigationsService.prototype.getInvestigationStatusByTypeName = function (investigationStatusTypeName) {
        return this.paris.getValue(InvestigationStatus, function (investigationStatus) {
            return investigationStatus.type.toLowerCase() === investigationStatusTypeName.toLowerCase();
        });
    };
    InvestigationsService.getInvestigationStatusBySevilleStatus = function (sevilleStatus) {
        var statusIdInt = parseInt(sevilleStatus, 10);
        if (isNaN(statusIdInt)) {
            return null;
        }
        return SEVILLE_STATUS_TO_STATUS[statusIdInt];
    };
    InvestigationsService.getInvestigationLink = function (investigation) {
        return investigation
            ? investigation.externalInvestigationPageUrl ||
                (investigation.isLiveResponse && "/live-response/" + investigation.id) ||
                "/investigation/" + investigation.id
            : null;
    };
    return InvestigationsService;
}());
export { InvestigationsService };
