import { EventEmitter, ViewContainerRef, Injector, ComponentFactory, ComponentRef, ComponentFactoryResolver, ChangeDetectorRef, OnInit, OnDestroy, } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FilterValuesChecklistComponent } from './filter-values/checklist/filter-values.checklist.component';
import { clone } from 'lodash-es';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { I18nService } from '@wcd/i18n';
var DEFAULT_FILTER_VALUES_COMPONENT = FilterValuesChecklistComponent;
var FiltersFieldComponent = /** @class */ (function () {
    function FiltersFieldComponent(resolver, changeDetectorRef, liveAnnouncer, i18nService) {
        this.resolver = resolver;
        this.changeDetectorRef = changeDetectorRef;
        this.liveAnnouncer = liveAnnouncer;
        this.i18nService = i18nService;
        this.focus = false;
        this.filterChange = new EventEmitter();
        this.isMinimized = false;
    }
    Object.defineProperty(FiltersFieldComponent.prototype, "selectedValues", {
        set: function (value) {
            this._selectedValues = clone(value);
            if (this._isInit)
                this.setSelectedValues();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FiltersFieldComponent.prototype, "hasSelectedValues", {
        get: function () {
            return !!this._selectedValues;
        },
        enumerable: true,
        configurable: true
    });
    FiltersFieldComponent.prototype.ngOnInit = function () {
        this.setFilterValuesComponent();
        this._isInit = true;
    };
    FiltersFieldComponent.prototype.ngOnChanges = function (changes) {
        if (changes.data && this._filterValuesComponent && this._filterValuesComponent.instance) {
            this._filterValuesComponent.instance.data = this.data;
        }
    };
    FiltersFieldComponent.prototype.ngOnDestroy = function () {
        if (this._selectedValuesChangedSubscription)
            this._selectedValuesChangedSubscription.unsubscribe();
    };
    FiltersFieldComponent.prototype.triggerChange = function (selectedValues) {
        this.filterChange.emit({
            selectedValues: selectedValues,
            field: this.field,
        });
    };
    FiltersFieldComponent.prototype.setFocus = function () {
        this.focus = true;
        this.changeDetectorRef.detectChanges();
    };
    FiltersFieldComponent.prototype.resetFocus = function () {
        this.focus = false;
        this.changeDetectorRef.detectChanges();
    };
    FiltersFieldComponent.prototype.setFilterValuesComponent = function () {
        var _this = this;
        var component = this.field.component;
        var injector = Injector.create({
            providers: [],
            parent: this.filterValuesPlaceholder.parentInjector,
        }), factory = this.resolver.resolveComponentFactory(component && component.type ? component.type : DEFAULT_FILTER_VALUES_COMPONENT);
        this._filterValuesComponent = factory.create(injector);
        var fixedFilterValuesSelection = this.fixedSelectedValues
            ? this._filterValuesComponent.instance.deserialize(this.fixedSelectedValues)
            : null;
        Object.assign(this._filterValuesComponent.instance, {
            fieldName: this.field.name,
            fieldId: this.field.id,
            config: component && component.config,
            fixedSelectedValues: fixedFilterValuesSelection,
        });
        // Setting data AFTER fieldId and config, since doing something with the data might require to first have a config available (in setter situations, for example).
        this._filterValuesComponent.instance.data = this.data;
        this._selectedValuesChangedSubscription = this._filterValuesComponent.instance.filterValuesChange.subscribe(function ($event) { return _this.triggerChange($event); });
        this.setSelectedValues();
        this.filterValuesPlaceholder.insert(this._filterValuesComponent.hostView);
        this.changeDetectorRef.detectChanges();
    };
    FiltersFieldComponent.prototype.setSelectedValues = function () {
        if (!this._filterValuesComponent)
            return;
        this._filterValuesComponent.instance.selectedValues = this._selectedValues;
        this._filterValuesComponent.instance.setSelectedValues(this._selectedValues);
    };
    /**
     * Returns the selected values in this field as a a key/value (string value), using the filter component's `serialize` method.
     */
    FiltersFieldComponent.prototype.serialize = function () {
        var serialized = this._filterValuesComponent.instance.serialize();
        if (this.field.serializeFilterValues)
            return this.field.serializeFilterValues(this._filterValuesComponent.instance.selectedValues, serialized, this._filterValuesComponent.instance.value);
        return serialized;
    };
    FiltersFieldComponent.prototype.deserialize = function (serializedSelection) {
        if (this.field.deserializeFilterValues) {
            var deserialized = this.field.deserializeFilterValues(serializedSelection);
            return deserialized instanceof Observable ? deserialized : of(deserialized);
        }
        return of(this._filterValuesComponent.instance.deserialize(serializedSelection));
    };
    FiltersFieldComponent.prototype.minimizedClicked = function (event) {
        var _this = this;
        this.liveAnnouncer.announce(this.i18nService.get(!this.isMinimized ? "common.button.collapsed" : "common.button.expanded"), 'assertive', 1000).then(function () {
            setTimeout(function () {
                _this.isMinimized = !_this.isMinimized;
                event.stopPropagation();
                event.preventDefault();
                _this.changeDetectorRef.markForCheck();
            }, 200);
        });
    };
    return FiltersFieldComponent;
}());
export { FiltersFieldComponent };
