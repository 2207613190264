import { FlavorConfig } from '../models';

export interface ISearchConfig {
	commonEntities: FlavorConfig;
	tvmEntities: FlavorConfig;
	user: FlavorConfig;
}

export const SearchConfig: ISearchConfig = {
	commonEntities: { //File, url, ip
		mdeFlavors: ['P2'],
	},
	user: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true,
	},
	tvmEntities: {
		mdeFlavors: ['P2', 'Smb'],
	},

}
