import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvaluationStep } from './evaluation-step.value-object';

@ApiCall({
	name: 'Evaluation status',
	endpoint: 'evaluation/steps',
	method: 'PATCH',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class PatchEvaluationStatusApiCall extends ApiCallModel<void, EvaluationStep> {}
