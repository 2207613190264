
		<span class="wcd-datatable-checkbox" >
			<input type="checkbox" [checked]="checked" (change)="_onInteractionEvent($event)" [id]="id" />
			<label
				[id]="id + '-label'"
				[attr.for]="id"
				class="wcd-datatable-checkbox__label"
				(mousedown)="onCheckboxClick($event)"
				[class.wcd-datatable-checkbox__label--checked]="checked"
				[attr.tabindex]="checked ? 0 : -1"
				[attr.aria-label]="'grid_columnHeader_selectRow' | i18n"
				role="checkbox"
			>
				<fab-icon iconName="CircleRing" contentClass="wcd-datatable-checkbox__label__ring"></fab-icon>
				<fab-icon
					iconName="StatusCircleCheckmark"
					contentClass="wcd-datatable-checkbox__label__check"
				></fab-icon>
			</label>
		</span>
	