import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('stickOnScroll', stickOnScrollDirective);

stickOnScrollDirective.$inject = ['$rootScope'];

function stickOnScrollDirective($rootScope) {
	return {
		restrict: 'EA',
		link: function(scope, element, attrs) {
			var scrollContainer = attrs.stickOnScrollContainer ? $(attrs.stickOnScrollContainer) : $(window);
			var offset = attrs.stickOnScrollOffset ? attrs.stickOnScrollOffset : 0;
			var initialPosition = 0,
				safeMargin = 4;
			var isSticky = false;
			var destroyed = false;
			var body = $('body');

			scrollContainer.scroll(function() {
				if (destroyed || scrollContainer.is(':animated')) return;

				var divTop = element.offset().top;
				if (!isSticky && divTop <= 56) {
					isSticky = true;
					$rootScope.stickyOnScrollActive = true;
					initialPosition = scrollContainer.scrollTop() + divTop - offset;
					element.addClass('sticky-on-scroll');
					body.addClass('sticky-on-scroll-active');
					applyWidth();
				} else if (isSticky && initialPosition > scrollContainer.scrollTop() + safeMargin) {
					element.removeClass('sticky-on-scroll');
					body.removeClass('sticky-on-scroll-active');
					element.removeAttr('style');
					isSticky = false;
					$rootScope.stickyOnScrollActive = false;
				}
			});

			element.on('$destroy', function() {
				destroyed = true;
				element.removeClass('sticky-on-scroll');
				body.removeClass('sticky-on-scroll-active');
				element.removeAttr('style');
				isSticky = false;
				$rootScope.stickyOnScrollActive = false;
			});

			$(window).resize(applyWidth);

			function applyWidth() {
				if (isSticky) {
					var width = element.parent().width() + 30;
					element.css('width', width);
				}
			}
		},
	};
}
