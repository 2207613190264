import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { userRoleVisibilityValues } from './user-role-visibility.values';

@Entity({
	singularName: 'User Role Visibility',
	pluralName: 'User Role Visibilities',
	values: userRoleVisibilityValues,
})
export class UserRoleVisibility extends EntityModelBase<number> {
	@EntityField() name: string;
}
