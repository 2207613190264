export enum CyberEventActionGroupTypeName {
	allocateMemoryGroup = 'allocateMemoryGroup',
	antivirusEventGroup = 'antivirusEventGroup',
	appControlGroup = 'appControlGroup',
	appGuardContainerGroup = 'appGuardContainerGroup',
	connectionEventGroup = 'connectionEventGroup',
	exploitGuardGroup = 'exploitGuardGroup',
	exploitGuardAsrGroup = 'exploitGuardAsrGroup',
	exploitGuardNetworkProtectionGroup = 'exploitGuardNetworkProtectionGroup',
	fileEventGroup = 'fileEventGroup',
	firewallConnectionBlockedGroup = 'firewallConnectionBlockedGroup',
	huntingRecordGroup = 'huntingRecordGroup',
	logonEventGroup = 'logonEventGroup',
	processCreatedGroup = 'processCreatedGroup',
	processInjectionGroup = 'processInjectionGroup',
	registryEventGroup = 'registryEventGroup',
	smartScreenGroup = 'smartScreenGroup',
	scheduledTaskGroup = 'scheduledTaskGroup',
	userToUserActivityGroup = 'userToUserActivityGroup',
	usbEventGroup = 'usbEventGroup',
}
