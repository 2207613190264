
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'users.entityDetails.sections.securityInfo.incidents.title' | i18n }}</dt>
			<dd>{{ openIncidentsCount$ | async }}</dd>

			<dt>{{ 'users.entityDetails.sections.securityInfo.activeAlerts.title' | i18n }}</dt>
			<dd>
				<ng-container [ngSwitch]="kind">
					<severity-summary
						*ngSwitchCase="'full'"
						size="compact"
						[severitiesCountMap]="severitiesCountMap$ | async"
					></severity-summary>

					<span *ngSwitchCase="'compact'">
						{{ alertsCount$ | async }}
					</span>
				</ng-container>
			</dd>
		</dl>
	