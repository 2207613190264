var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-accessibility-label-for */
import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBarType } from 'office-ui-fabric-react';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService, ConsentType } from '../../services/evaluation.service';
var SimulatorAgentsConsentFormComponent = /** @class */ (function (_super) {
    __extends(SimulatorAgentsConsentFormComponent, _super);
    function SimulatorAgentsConsentFormComponent(router, evaluationService) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.onAccepted = new EventEmitter();
        _this.MessageBarType = MessageBarType;
        return _this;
    }
    SimulatorAgentsConsentFormComponent.prototype.ngOnInit = function () {
        switch (this.consentType) {
            case ConsentType.MsInformation: {
                this.consentTitle = 'msConsentFirstDeclaration';
                this.consentContent = 'msFirstConsentContent';
                break;
            }
            case ConsentType.MsInformationSharing: {
                this.consentTitle = 'msConsentSecondDeclaration';
                this.consentContent = 'msSecondConsentContent';
                break;
            }
            case ConsentType.AttackIQ: {
                this.consentTitle = 'attackIqConsentTitle';
                this.consentContent = 'attackIqConsentContent';
                break;
            }
        }
    };
    SimulatorAgentsConsentFormComponent.prototype.onConsentAccepted = function () {
        this.closePanel();
        this.onAccepted.emit(true);
    };
    return SimulatorAgentsConsentFormComponent;
}(PanelContainer));
export { SimulatorAgentsConsentFormComponent };
