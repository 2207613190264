import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { alertClassificationValues } from './alert-classification.entity.values';

@Entity({
	singularName: 'Alert Classification',
	pluralName: 'Alert Classifications',
	values: alertClassificationValues,
})
export class AlertClassification extends StatusModel {
	@EntityField({ defaultValue: false })
	allowDetermination?: boolean;

	@EntityField({ defaultValue: true })
	isSelectable: boolean;

	@EntityField({ defaultValue: false })
	combinedClassification?: boolean;
}
