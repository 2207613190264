import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Incident, Alert } from '@wcd/domain';
import { AlertsService } from '../../alerts/services/alerts.service';
import { AppContextService } from '@wcd/config';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';


@Component({
	selector: 'incident-alerts-panel',
	templateUrl: './incident.alerts.panel.component.html',
})
export class IncidentAlertsPanelComponent
	extends PanelContainer
	implements OnDestroy, OnInit {

	@Input()
	incident: Incident;

	@Input()
	alerts: Alert[];

	isMtpEnabled: boolean = this.appContextService.isMtp;
	i18nService: I18nService;
	@ViewChild('incidentAlertsComponent', { static:false})
	incidentAlertsComponent: any

	constructor(
		private alertsService: AlertsService,
		private appContextService: AppContextService,
		changeDetectorRef: ChangeDetectorRef,
		router: Router,
		private _i18nService: I18nService,
		private alertsFields: AlertsFields,
	) {
		super(router);
		this.i18nService = _i18nService;

	}

	ngOnInit() {
		super.ngOnInit();
	}

	ngOnDestroy() {
		super.ngOnDestroy();
	}

	export() {
		this.alertsService.downloadCsv(this.alerts, {
			columns: ['title', 'severity', 'status'],
			fields: this.alertsFields.allFields
		})
	}
}
