<wizard-panel
	[panelSettings]="settings"
	[currentStep]="currentStep"
	[stepsData]="stepsData"
	[wizardTitle]="'hunting.bulkActions.title' | i18n"
	[onBackPressed]="previousStep.bind(this)"
	[onNextPressed]="nextStep.bind(this)"
	[onClosePressed]="onClosePanel.bind(this)"
	[isNextEnabled]="currentStep.isValid()"
	[isLoading]="didSave"
	[isCompleted]="didSave"
>
	<div class="bulk-actions-wizard wcd-full-height wcd-flex-vertical">
		<h3 class="wcd-font-size-xxl wcd-font-weight-bold wcd-margin-small-bottom">
			{{ currentStep.subText }}
		</h3>
		<div>
			<ng-container *ngIf="currentStep.id === WizardStepId.SelectAction">
				<p>{{ 'hunting_bulkActions_chooseActions_subtitle' | i18n }}</p>
				<wcd-contents-state [contentState]="supportedActionsState">
					<fab-spinner
						loading
						contentClass="wcd-inline-block wcd-margin-small-left"
					></fab-spinner>
					<span error>{{ 'hunting_common_error' | i18n }}</span>

					<hunting-custom-actions
						complete
						name="customActions"
						[supportedActions]="supportedActions$ | async"
						[(ngModel)]="selectedActions"
						required
					>
					</hunting-custom-actions>
				</wcd-contents-state>
			</ng-container>
			<div
				[hidden]="currentStep.id !== WizardStepId.SelectEntities"
				class="wcd-full-height"
			>
				<editable-text
					name="bulkName"
					[(ngModel)]="bulkName"
					label="{{ 'hunting_bulkActions_remediationName_label' | i18n }}"
					labelClass="form-control-label"
					inputClass="form-control">
				</editable-text>

				<editable-text
					name="bulkComment"
					[(ngModel)]="bulkComment"
					label="{{ 'hunting_bulkActions_description_label' | i18n }}"
					labelClass="form-control-label"
					inputClass="form-control">
				</editable-text>

				<p class="wcd-margin-xLarge-top">{{ selectEntitiesStepSubtitle }}</p>

				<wcd-datatable
					[className]="'wizard'"
					[items]="entities$ | async"
					[columns]="entityFields"
					[itemsSelected]="true"
					[selectEnabled]="true"
					[selectAllEnabled]="true"
					[allowMultipleSelection]="true"
					[fullHeight]="false"
					(select)="onRecordSelectionChange($event)"
				>
				</wcd-datatable>
			</div>
			<ng-container *ngIf="currentStep.id === WizardStepId.Confirm && !didSave">
				<editable-text
					name="bulkName2"
					[(ngModel)]="bulkName"
					[allowEdit]="isEditingBulkName"
					label="{{ 'hunting_bulkActions_remediationName_label' | i18n }}"
					labelClass="form-control-label"
					inputClass="form-control"
					textClass="wcd-font-size-base">
				</editable-text>
				<button class="btn-link no-padding" (click)="isEditingBulkName = !isEditingBulkName">
					{{ (isEditingBulkName ? 'buttons_save': 'hunting_bulkActions_remediationName_edit_button') | i18n }}
				</button>

				<editable-text
					name="bulkComment2"
					[(ngModel)]="bulkComment"
					[allowEdit]="isEditingBulkComment"
					label="{{ 'hunting_bulkActions_description_label' | i18n }}"
					labelClass="form-control-label"
					inputClass="form-control"
					textClass="wcd-font-size-base">
				</editable-text>
				<button class="btn-link no-padding" (click)="isEditingBulkComment = !isEditingBulkComment">
					{{ (isEditingBulkComment ? 'buttons_save' : 'hunting_bulkActions_description_edit_button') | i18n }}
				</button>

				<label for="confirmActionsTable" class="form-control-label">{{ 'hunting_bulkActions_confirm_actions_label' | i18n }}</label>
				<p>{{ 'hunting_bulkActions_confirm_subtitle' | i18n }}</p>
				<wcd-datatable
					id="confirmActionsTable"
					[className]="'wizard'"
					[items]="selectedEntities"
					[columns]="confirmationFields"
					[selectEnabled]="false"
					[fullHeight]="false"
				>
				</wcd-datatable>
			</ng-container>
			<ng-container *ngIf="didSave">
				<p class="wcd-margin-small-bottom">
					<ng-container *ngIf="showActionCenterLink; else subtitleText">
						{{ 'hunting_bulkActions_actionStatus_subtitlePrefix' | i18n }}
						<a href="/action-center/history">{{
							'hunting_bulkActions_actionStatus_actionCenterLink' | i18n
							}}</a>
						{{
						'hunting_bulkActions_actionStatus_subtitleSuffix' | i18n : { bulkId: actionCenterBulkIdDisplay }
						}}
					</ng-container>
					<ng-template #subtitleText>
						{{ 'hunting_bulkActions_actionStatus_subtitle' | i18n }}
					</ng-template>
				</p>

				<wcd-datatable
					[className]="'wizard'"
					[items]="actionsStatus$ | async"
					[columns]="actionsFields"
					[selectEnabled]="false"
					[fullHeight]="false"
				>
				</wcd-datatable>
			</ng-container>
		</div>
	</div>
</wizard-panel>
