import { Observable } from 'rxjs';
import { DataSet, DataQuery } from '@microsoft/paris';
import { merge } from 'lodash-es';
import { SerializedFilters } from '@wcd/ng-filters';
import { DataviewField } from './dataview-field.model';
import { DatasetBackendOptions } from './dataset-options.interface';

export interface DataViewConfig<TData = any> {
	/**
	 * Set to false to not have filters in the dataview.
	 * @default true
	 */
	allowFilters?: boolean;

	/**
	 * Set to false to just query the backend once for data, without paging.
	 * Sorting will be done locally.
	 * @default true
	 */
	allowPaging?: boolean;

	/**
	 * If set, the dataview won't load data from an API, but will use this data instead.
	 */
	data?: ReadonlyArray<TData>;

	/**
	 * Used to override the default page size.
	 */
	defaultPageSize?: number;

	/**
	 * The field to use for initial sort.
	 */
	defaultSortFieldId?: string;

	/**
	 * An array of field IDs that specifies the default fields to initially display in the dataview.
	 * The fields can be selected by the user with column selection.
	 */
	defaultVisibleFieldIds?: Array<string>;

	/**
	 * An array of field IDs to use for not including specific fields in the dataview.
	 */
	disabledVisibleFieldIds?: Array<string>;

	/**
	 * An array of field IDs - the specified fields won't have filters in the dataview.
	 */
	disabledFilterFieldIds?: Array<string>;

	/**
	 * An array of field IDs - the specified fields "sort" option will be overridden to "disabled".
	 */
	sortDisabledFieldIds?: Array<string>;

	/**
	 * A function that's called to export (usually by downloading a file) the items in the dataview.
	 * @param options - the current state of the dataview as options object (including fixed options).
	 */
	exportResults?: (options: DatasetBackendOptions, format: string, query: DataQuery) => Promise<any>;

	/**
	 * The search parameter name that is sent to the BE
	 */
	searchParamName?: string;

	/**
	 * Allow user to search by multiple words without considering the order
	 */
	allowSearchMultipleWords?: boolean;

	/**
	 * The fields to display in the dataview.
	 */
	fields?: Array<DataviewField<TData>>;

	/**
	 * By default, the filters in the dataview are closed.
	 * @default true
	 */
	filtersHiddenByDefault?: boolean;

	/**
	 * Sets values which should be considered always selected by the filters, and filtered on.
	 * The key of the object is the field ID and the value is an array of fixed filter values.
	 */
	fixedFilterValues?: SerializedFilters;

	/**
	 * Options to send to backend when loading data. Will be sent as query params in each network request.
	 */
	fixedOptions?: { [index: string]: any };

	/**
	 * Function to use for filtering items in the dataview, locally (no backend involved)
	 */
	freeTextFilter?: (searchTerm: string, item: TData) => boolean;

	/**
	 * The function to use for fetching available filter values.
	 */
	getFiltersData?: (options?: SerializedFilters) => Observable<Record<string, any>>;

	/**
	 * Translates the selected filter values to the `filters` URL query param value.
	 */
	getFilterQueryOptions?: (
		serializedFilters: SerializedFilters
	) => SerializedFilters | Observable<SerializedFilters> | Promise<SerializedFilters>;

	/**
	 * The ID of the dataview. Used in IDs for tracking (telemetry), error reporting and caching.
	 */
	id?: string;

	/**
	 * If `true`, the dataview won't have a paging control (prev/next buttons), but instead will load more items on scrolling.
	 * The infinite scrolling requires the DataSet loaded by the dataview to have a `next` property.
	 */
	infiniteScrolling?: boolean;

	/**
	 * If `true`, the dataview will perform both 'search' & 'sort' actions against the backend API
	 * (the Model::IsLocalData will be evaluated to 'false')
	 */
	forceGetDataFromApi?: boolean;

	/**
	 * If `true`, the dataview allows fetching data to add to top of table (in contrary to "infinite scrolling", which adds data to bottom of table).
	 * Loading data to top of table requires the DataSet loaded by the dataview to have a `previous` property.
	 */
	loadItemsOnTableTop?: boolean;

	/**
	 * When loading data in an infinite scroll mode, data is loaded by URL, rather than through options.
	 * This URL has to be available in the DataSet's `next` property. Then loadNextResults is called with the `next` value to get more items to be added to the dataview.
	 */
	loadNextResults?: (nextResultsUrl: string) => Observable<DataSet<TData>>;

	/**
	 * When loading data in an infinite scroll mode, data is loaded by URL, rather than through options.
	 * This URL has to be available in the DataSet's `previous` property. Then loadPreviousResults is called with the `previous` value to get more items to be added to the top of the table.
	 */
	loadPreviousResults?: (previousResultsUrl: string) => Observable<DataSet<TData>>;

	/**
	 * When loading data in an infinite scroll mode, data is loaded by URL, rather than through options.
	 * This method is used to get the relevant url.
	 */
	getLoadNextResultsUrl?: () => string;

	/**
	 * When loading data in an infinite scroll mode, data is loaded by URL, rather than through options.
	 * This method is used to get the relevant url.
	 */
	getLoadPreviousResultsUrl?: () => string;

	/**
	 * The function to use for retrieving data from backend.
	 */
	loadResults?: (options: DatasetBackendOptions) => Observable<DataSet<TData>>;

	options?: { [index: string]: any };

	pageSize?: number;

	/**
	 * If set to `false`, `getFiltersData` won't be invoked or required for getting external data to the filters.
	 */
	requireFiltersData?: boolean;

	/**
	 * Set this to true if the dataview loads data only after the user has entered a search.
	 * @default false
	 */
	searchOnly?: boolean;

	/**
	 * When clicking on 'Export' in the dataview, by default a modal is shown prompting the user for export options.
	 * Settings this to false disables the modal and calls the export function directly.
	 * @default true
	 */
	showModalOnExport?: boolean;

	/**
	 * Set this to true if when clicking on 'Export' in the dataview, a sppiner will be shown on the export button and it will be disabled.
	 * @default false
	 */
	showBusyOnExport?: boolean;

	/**
	 * Set this to true if partial response should be supported
	 * @default false
	 */
	supportPartialResponse?: boolean;

	/**
	 * Same as defaultVisibleFieldIds, but takes precedence.
	 * TODO: Remove this?
	 */
	visibleFields?: Array<string>;

	/**
	 * fires when the sorting in the dataview is changing
	 * @param sortField The DataviewField for the field that was fired
	 * @param sortDescending signals if the sorting direction was changed
	 */
	sortFieldChanged?: (sortField: DataviewField<TData>, sortDescending?: boolean) => {};
}

export function mergeDataViewConfig<TData = any, T extends DataViewConfig<TData> = any>(
	...configs: Array<DataViewConfig>
): DataViewConfig<TData> {
	return merge.apply(null, [{}, ...configs]);
}
