<div *ngIf="vulnerability" class="tvm wcd-scroll-vertical wcd-margin-medium-top">
	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
		<div class="tvm-list__block_item">
			<div class="wcd-margin-small-bottom" *ngIf="showZeroDayMessage">
				<fab-message-bar  [messageBarType]="MessageBarType.severeWarning">
					{{'tvm_vulnerability_zeroDay_notification' | i18n}}
				</fab-message-bar>
			</div>
			<div class="wcd-margin-small-bottom" *ngIf="productNeverMatched">
				<fab-message-bar [messageBarType]="MessageBarType.info">
					{{'tvm_softwareInventory_weaknessNotAvailableMessage' | i18n}}
				</fab-message-bar>
			</div>
			<fab-message-bar
				[messageBarType]="MessageBarType.info"
				[isMultiline]="false"
				[truncated]="true"
				[overflowButtonAriaLabel]="i18nService.strings.common_legalNotice + ', ' + i18nService.strings.entityCommon_panel_impactedEntities_showMore"
				[styles]="getStyles.bind(this)"
				>
				<div>{{"common.legalNotice" | i18n}}</div><div class="wcd-margin-top" *ngIf="messageBarState.showBody" [innerHTML]="legalNoticeText"></div>
			</fab-message-bar>
		</div>

		<div class="tvm-list__header">{{ 'tvm.vulnerability.description' | i18n }}</div>
		<div class="tvm-list__block_item">{{vulnerability.description || ('notAvailable.short' | i18n)}}</div>

		<div *ngIf="hasOsFeature" class="tvm-list__block_item">
			<fab-message-bar [messageBarType]="MessageBarType.info" dismissable="false">
				{{ osFeatureNote }}
			</fab-message-bar>
		</div>

		<div class="tvm-list__header">{{ 'tvm.vulnerability.details' | i18n }}</div>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_common_vulnerabilityName' | i18n }}
				<wcd-help [text]="'tvm_vulnerability_name_tooltip' | i18n"></wcd-help>
			</dt>
			<dd>{{vulnerability.name || ('notAvailable.short' | i18n)}}</dd>
		</dl>

		<dl *ngIf="!isNewExploitExperienceEnabled" class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.exploitsAvailable' | i18n }}</dt>
			<dd>{{ (vulnerability.threatInfo.hasExploit ? 'common.yes' : 'common.no') | i18n }}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.common.severity' | i18n }}</dt>
			<dd><span *ngIf="severityInfo.showIcon"><fab-icon iconName="Warning" [contentClass]="severityInfo.iconClass + ' bottom-aligned-icon'"></fab-icon>&nbsp;</span>{{severityInfo.text}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>CVSS</dt>
			<dd>{{vulnerability.cvss || ('notAvailable.short' | i18n)}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.publishedOn' | i18n }}</dt>
			<dd>{{(vulnerability.published | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.updatedOn' | i18n }}</dt>
			<dd>{{(vulnerability.updated | date:'shortDate') || ('notAvailable.short' | i18n)}}</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.age' | i18n }}</dt>
			<dd>{{ vulnerabilityAge }}</dd>
		</dl>

		<dl *ngIf="!isNewExploitExperienceEnabled" class="tvm-list__key_val_item">
			<dt>{{ 'tvm.common.threats' | i18n }}</dt>
			<dd>
				<tvm-threat-icons [config]="threatConfigs"></tvm-threat-icons>
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.relatedSoftware' | i18n }}</dt>
			<dd [wcdTooltip]="softwareTooltip" [wcdTooltipAllowHtmlRendering]="true">{{ relatedSoftware }}</dd>
		</dl>

		<dl *ngIf="hasOsFeature" class="tvm-list__key_val_item">
			<dt>{{ 'tvm.vulnerability.osFeature' | i18n }}</dt>
			<dd [wcdTooltip]="osFeatureTooltip" [wcdTooltipAllowHtmlRendering]="true">{{ osFeature }}</dd>
		</dl>

		<dl *ngIf="showZeroDayFormerId" class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_vulnerability_formalTemporaryId' | i18n }}
				<wcd-help [text]="'tvm_vulnerability_formalTemporaryId_tooltip' | i18n"></wcd-help>
			</dt>
			<dd>{{ vulnerability.formalTemporaryId }}</dd>
		</dl>
	</div>

	<threat-insights *ngIf="isNewExploitExperienceEnabled" [threatInfo]="vulnerability.threatInfo"></threat-insights>
	<detection-logic *ngIf="isDetectionLogicEnabled && contextOptions && contextOptions.isSingleAsset" 
		[installedAffectedProducts]="vulnerability.installedAffectedProducts" 
		[cveSources]="vulnerability.cveSources">
	</detection-logic>
	<div *ngIf="!productNeverMatched && (includeExposedMachinesComponent || (contextOptions && !contextOptions.isSingleAsset))">
		<exposed-machines-details [includeProductivityAssessment]="showProductivityImpactDescription"
			[assets$]="assets$"
			[title]="exposedAssetsTitle"
			[loadingText]="exposedAssetsLoadingText"
			[isNetworkDevice]="isNetworkDevice"
			[includeLastSeenStatus]="includeLastSeenStatus"
			[includeOperatingSystem]="includeOperatingSystem"
			(showMore)=openAllExposedMachines()
			(export)="exportExposedMachines()">
		</exposed-machines-details>
	</div>
</div>
