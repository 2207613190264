var _a;
import { BaselineComplianceProfileStatusColorService } from './baseline-compliance-profile-status-color.service';
import { DisplayTextPipe } from '../../shared/pipes/display-text.pipe';
import { BaselineComplianceProfileStatusPercentage } from '../components/tvm-profile-compliance-status-bars/baseline-compliance-profile-status-percentage.enum';
var BaselineComplianceProfileStatusPercentageDisplayKeysMap = (_a = {},
    _a[BaselineComplianceProfileStatusPercentage.MoreThan75PercentConfiguredDevices] = 'tvm_baseline_profile_compliance_status_widget_75',
    _a[BaselineComplianceProfileStatusPercentage.MoreThan50LessThan75PercentConfiguredDevices] = 'tvm_baseline_profile_compliance_status_widget_75_50',
    _a[BaselineComplianceProfileStatusPercentage.MoreThan25LessThan50PercentConfiguredDevices] = 'tvm_baseline_profile_compliance_status_widget_50_25',
    _a[BaselineComplianceProfileStatusPercentage.LessThan25PercentConfiguredDevices] = 'tvm_baseline_profile_compliance_status_widget_25',
    _a[BaselineComplianceProfileStatusPercentage.NoDataDevices] = 'tvm_baseline_profile_compliance_status_widget_na',
    _a);
var TvmProfilesComplianceStatusBarsService = /** @class */ (function () {
    function TvmProfilesComplianceStatusBarsService(baselineComplianceProfileStatusColorService, displayTextPipe) {
        var _this = this;
        this.baselineComplianceProfileStatusColorService = baselineComplianceProfileStatusColorService;
        this.displayTextPipe = displayTextPipe;
        this.complianceStatusesColorMap = {
            type: 'class',
            map: this.baselineComplianceProfileStatusColorService.backgroundColorsClassMap,
        };
        this.statusPercentageKeys = Object.values(BaselineComplianceProfileStatusPercentage);
        this.complianceStatusesTextFormatter = function (status) {
            return _this.displayTextPipe.transform(status, BaselineComplianceProfileStatusPercentageDisplayKeysMap);
        };
        this.legendTextFormatter = function (count) { return "(" + count + ")"; };
        this.legendItems = this.statusPercentageKeys.map(function (key) { return ({
            name: _this.complianceStatusesTextFormatter(key),
            iconClassName: _this.baselineComplianceProfileStatusColorService.fontColorsClassMap.get(key),
        }); });
    }
    return TvmProfilesComplianceStatusBarsService;
}());
export { TvmProfilesComplianceStatusBarsService };
