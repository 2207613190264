/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../modules/distribution/distribution-element/distribution-element.component.ngfactory";
import * as i2 from "../../modules/distribution/distribution-element/distribution-element.component";
import * as i3 from "../../services/known-colors.service";
import * as i4 from "./severity-bar.component";
import * as i5 from "../../services/severity-type-color.service";
var styles_SeverityBarComponent = ["[_nghost-%COMP%] {\n\t\t\t\tdisplay: inline-block;\n\t\t\t}"];
var RenderType_SeverityBarComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_SeverityBarComponent, data: {} });
export { RenderType_SeverityBarComponent as RenderType_SeverityBarComponent };
export function View_SeverityBarComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "distribution-element", [], null, null, null, i1.View_DistributionElementComponent_0, i1.RenderType_DistributionElementComponent)), i0.ɵdid(2, 573440, null, 0, i2.DistributionElementComponent, [i3.KnownColorsService], { barColor: [0, "barColor"], title: [1, "title"], mainText: [2, "mainText"], secondaryText: [3, "secondaryText"], size: [4, "size"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.barColor; var currVal_1 = _co.title; var currVal_2 = _co.mainText; var currVal_3 = (_co.mainText && _co.secondaryText); var currVal_4 = _co.size; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_SeverityBarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "severity-bar", [], null, null, null, View_SeverityBarComponent_0, RenderType_SeverityBarComponent)), i0.ɵdid(1, 573440, null, 0, i4.SeverityBarComponent, [i5.SeverityTypeColorService], null, null)], null, null); }
var SeverityBarComponentNgFactory = i0.ɵccf("severity-bar", i4.SeverityBarComponent, View_SeverityBarComponent_Host_0, { size: "size", severity: "severity", totalCount: "totalCount", count: "count" }, {}, []);
export { SeverityBarComponentNgFactory as SeverityBarComponentNgFactory };
