export const incidentGraphNodeSourceValues = [
	{
		id: 'MailAttachment',
		icon: '\uE723',
		iconName: 'attachment',
	},
	{
		id: 'BrowserDownload',
		icon: '\uEBD3',
		iconName: 'cloudDownload',
	},
	{
		id: 'MailLink',
		icon: '\uE715',
		iconName: 'email',
	},
];
