/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./checkbox.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "../../../../angular-extensions/src/lib/directives/keyboard-navigable-element.directive";
import * as i6 from "../../../../angular-extensions/src/lib/services/ie11.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i9 from "@angular-react/fabric";
import * as i10 from "@angular/forms";
import * as i11 from "./checkbox.component";
import * as i12 from "../models/disableable.interface";
var styles_CheckboxComponent = [i0.styles];
var RenderType_CheckboxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_CheckboxComponent, data: {} });
export { RenderType_CheckboxComponent as RenderType_CheckboxComponent };
function View_CheckboxComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CheckboxComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "label", [["class", "wcd-flex-horizontal"], ["keyboard-navigable-element", ""]], [[1, "role", 0], [1, "aria-checked", 0], [1, "for", 0], [2, "invalid", null], [1, "aria-disabled", 0], [2, "force-wrap-normal", null]], [[null, "keydown.space"], [null, "onTabIndexChange"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("keydown.space" === en)) {
        var pd_4 = (_co._onInteractionEvent($event) !== false);
        ad = (pd_4 && ad);
    } if (("onTabIndexChange" === en)) {
        var pd_5 = (_co.focusChange.emit($event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(3, 147456, null, 0, i2.TooltipDirective, [i1.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipShowOnOverflowOnly: [1, "wcdTooltipShowOnOverflowOnly"] }, null), i1.ɵdid(4, 1196032, null, 0, i5.KeyboardNavigableElementDirective, [i1.ElementRef, i6.Ie11Service], { elementVisible: [0, "elementVisible"] }, { onTabIndexChange: "onTabIndexChange" }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_2)), i1.ɵdid(7, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.tooltipText || (_co.isDisabled && _co.disableReason)); var currVal_7 = _co.tooltipOnOverFlowOnly; _ck(_v, 3, 0, currVal_6, currVal_7); var currVal_8 = _co.elementVisible; _ck(_v, 4, 0, currVal_8); var currVal_9 = i1.ɵnov(_v.parent, 11); _ck(_v, 7, 0, currVal_9); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ariaRole; var currVal_1 = _co.checked; var currVal_2 = _co.id; var currVal_3 = _co.isInvalid; var currVal_4 = _co.isDisabled; var currVal_5 = _co.wrapLabel; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_CheckboxComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_CheckboxComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "label", [["class", "wcd-flex-horizontal"], ["tabindex", "0"]], [[1, "role", 0], [1, "aria-checked", 0], [1, "for", 0], [2, "invalid", null], [1, "aria-disabled", 0], [2, "force-wrap-normal", null]], [[null, "keydown.space"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 2).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 2).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("keydown.space" === en)) {
        var pd_4 = (_co._onInteractionEvent($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 147456, null, 0, i2.TooltipDirective, [i1.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipShowOnOverflowOnly: [1, "wcdTooltipShowOnOverflowOnly"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_4)), i1.ɵdid(5, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_6 = (_co.tooltipText || (_co.isDisabled && _co.disableReason)); var currVal_7 = _co.tooltipOnOverFlowOnly; _ck(_v, 2, 0, currVal_6, currVal_7); var currVal_8 = i1.ɵnov(_v.parent, 11); _ck(_v, 5, 0, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ariaRole; var currVal_1 = (!_co.isPartiallyChecked ? _co.checked : "mixed"); var currVal_2 = _co.id; var currVal_3 = _co.isInvalid; var currVal_4 = _co.isDisabled; var currVal_5 = _co.wrapLabel; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_CheckboxComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "i", [["class", "wcd-checkbox--partial"]], null, null, null, null, null))], null, null); }
function View_CheckboxComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 1, "fab-icon", [], null, null, null, i8.View_FabIconComponent_0, i8.RenderType_FabIconComponent)), i1.ɵdid(2, 5816320, null, 0, i9.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.checked ? "checked" : ""); var currVal_1 = (_co.checked ? "CheckboxCompositeReversed" : "Checkbox"); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_CheckboxComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "wcd-flex-none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_6)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(0, [["checkboxIcon", 2]], null, 0, null, View_CheckboxComponent_7)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(9, 0, null, null, 3, "div", [["class", "wcd-flex-1 wcd-checkbox-label-contents"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["\n\t\t\t\t", "\n\t\t\t\t"])), i1.ɵncd(null, 0), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isPartiallyChecked; var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.label; _ck(_v, 10, 0, currVal_2); }); }
export function View_CheckboxComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["class", "wcd-checkbox"]], [[2, "disabled", null]], null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0], [8, "name", 0], [8, "disabled", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co._onInteractionEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_CheckboxComponent_1)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵand(0, [["noKeyboardSupport", 2]], null, 0, null, View_CheckboxComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n\n\t\t"])), (_l()(), i1.ɵand(0, [["checkboxContent", 2]], null, 0, null, View_CheckboxComponent_5)), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_5 = _co.setKeyboardSupport; var currVal_6 = i1.ɵnov(_v, 9); _ck(_v, 6, 0, currVal_5, currVal_6); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isDisabled; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.checked; var currVal_2 = _co.id; var currVal_3 = _co.name; var currVal_4 = _co.isDisabled; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3, currVal_4); }); }
export function View_CheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "wcd-checkbox", [], null, null, null, View_CheckboxComponent_0, RenderType_CheckboxComponent)), i1.ɵprd(5120, null, i10.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i11.CheckboxComponent]), i1.ɵprd(6144, null, i12.DISABLEABLE_TOKEN, null, [i11.CheckboxComponent]), i1.ɵdid(3, 49152, null, 0, i11.CheckboxComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var CheckboxComponentNgFactory = i1.ɵccf("wcd-checkbox", i11.CheckboxComponent, View_CheckboxComponent_Host_0, { id: "id", label: "label", isDisabled: "isDisabled", name: "name", tooltipText: "tooltipText", tooltipOnOverFlowOnly: "tooltipOnOverFlowOnly", isPartiallyChecked: "isPartiallyChecked", isInvalid: "isInvalid", setKeyboardSupport: "setKeyboardSupport", ariaRole: "ariaRole", wrapLabel: "wrapLabel", elementVisible: "elementVisible", checked: "checked" }, { change: "change", focusChange: "focusChange" }, ["*"]);
export { CheckboxComponentNgFactory as CheckboxComponentNgFactory };
