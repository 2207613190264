import { I18nService } from '@wcd/i18n';
import { TvmTextsService, InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { FeaturesService } from '@wcd/config';
import { Paris } from '@microsoft/paris';
import { Injectable } from '@angular/core';
import { SecurityRecommendationFieldsServiceBase } from './security-recommendation.fields.service.base';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { TzDateService } from '@wcd/localization';
import { TvmReportInaccuracyService } from '../../../../tvm/services/tvm-report-inaccuracy.service';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TvmProductivityImpactService } from '../../../../tvm/services/tvm-productivity-impact.service';
import { AuthService } from '@wcd/auth';
import { PrettyNumberService } from '@wcd/prettify';
import { SecurityRecommendationService } from './security-recommendation.service';
import { AppConfigService } from '@wcd/app-config';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';

@Injectable()
export class SingleAssetSecurityRecommendationFieldsService extends SecurityRecommendationFieldsServiceBase {
	constructor(
		tvmTaggedFieldService: TvmTaggedFieldService,
		tzDateService: TzDateService,
		i18nService: I18nService,
		tvmTextsService: TvmTextsService,
		featuresService: FeaturesService,
		paris: Paris,
		tvmIconBuilderService: TvmIconBuilderService,
		reportInaccuracyService: TvmReportInaccuracyService,
		tvmTagsService: TvmTagsService,
		authService: AuthService,
		productivityImpactService: TvmProductivityImpactService,
		prettyNumberService: PrettyNumberService,
		securityRecommendationService: SecurityRecommendationService,
		appConfigService: AppConfigService
	) {
		super(
			tvmTaggedFieldService,
			tzDateService,
			i18nService,
			tvmTextsService,
			featuresService,
			paris,
			tvmIconBuilderService,
			reportInaccuracyService,
			tvmTagsService,
			authService,
			productivityImpactService,
			prettyNumberService,
			securityRecommendationService,
			appConfigService
		);
		this.singleAssetRecommendation = true;
	}
}
