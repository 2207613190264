import { NgModule } from '@angular/core';
import { TimeSeriesChartComponent } from './time-series-chart.component';
import { I18nModule } from '@wcd/i18n';
import { CommonModule } from '@angular/common';

@NgModule({
	imports: [I18nModule, CommonModule],
	declarations: [TimeSeriesChartComponent],
	exports: [TimeSeriesChartComponent],
	entryComponents: [TimeSeriesChartComponent],
})
export class TimeSeriesChartModule {}
