import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { IMachineExploitLevel, MachineExploitLevelType } from './machine-exploit-level.models';
import { machineExploitLevelValues } from './machine-exploit-level.values';

@Entity({
	singularName: 'Device Exploit Level',
	pluralName: 'Device Exploit Levels',
	values: machineExploitLevelValues,
})
export class MachineExploitLevel extends EntityModelBase<MachineExploitLevelType> implements IMachineExploitLevel {
	@EntityField() name: string;
	@EntityField() nameI18nKey: string;

	@EntityField() priority: number;

	get type(): MachineExploitLevelType {
		return this.id;
	}
}
