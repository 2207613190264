import { IMachineExploitLevel, MachineExploitLevelType } from './machine-exploit-level.models';

export const machineExploitLevelValues: Array<IMachineExploitLevel> = [
	{
		id: MachineExploitLevelType.NoKnownExploit,
		name: 'NoKnownExploit',
		nameI18nKey: 'machineExploitLevel_NoKnownExploit',
		priority: 4,
	},
	{
		id: MachineExploitLevelType.HasExploit,
		name: 'HasExploit',
		nameI18nKey: 'machineExploitLevel_HasExploit',
		priority: 3,
	},
	{
		id: MachineExploitLevelType.ExploitVerified,
		name: 'ExploitVerified',
		nameI18nKey: 'machineExploitLevel_ExploitVerified',
		priority: 2,
	},
	{
		id: MachineExploitLevelType.InExploitKit,
		name: 'InExploitKit',
		nameI18nKey: 'machineExploitLevel_InExploitKit',
		priority: 1,
	},
];
