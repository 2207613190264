import { SevilleModule } from '../seville.module';
import { SearchService } from '../../../../search/services/search.service';
import { ErrorsService } from '../../../../shared/services/errors.service';
import { merge } from 'rxjs';

declare var angular: angular.IAngularStatic;

SevilleModule.factory('investigationService', investigationService);

investigationService.$inject = ['$log', '$interval', '$http', 'urlMapping', 'searchService', 'errorsService'];

function investigationService(
	$log,
	$interval,
	$http,
	urlMapping,
	searchService: SearchService,
	errorsService: ErrorsService
) {
	var intervalRefreshTime = 60000;
	var stateMap = urlMapping.investigationsStates;

	var cancelIntervalFunction = function(interval) {
		return function() {
			try {
				$interval.cancel(interval);
			} catch (e) {
				$log.warning('Could not cancel interval: ');
				$log.error(e);
			}
		};
	};

	var requestByInterval = function(urlAddress, requestParameters, onResponseFunction) {
		if (!onResponseFunction) {
			$log.error('requestByInterval: response function is required and was not supplied');
			return null;
		}

		var interval = $interval(function() {
			$http
				.get(urlAddress, {
					params: requestParameters,
				})
				.then(
					function(res) {
						if (res && res.data) {
							onResponseFunction(res.data, function() {
								$interval.cancel(interval);
							});
						}
					},
					function(e) {
						$log.error(e);
					}
				);
		}, intervalRefreshTime);

		// returns cancel interval operation - if needed to be called on page redirection
		return cancelIntervalFunction(interval);
	};

	var service = {
		flowItems: [],
		tempFlowItems: [],
		clearFlow: function() {
			this.flowItems = [];
			this.tempFlowItems = [];
		},
		setActive: function(data) {
			data.displaystate = data.displaystate || data.state;
			data.state = data.state.toLowerCase();
			$log.debug('setting active investigation flow item ' + data.Id);
			var itemIndex = -1;
			var tempItemIndex = -1;
			for (var i = 0; i < this.flowItems.length; i++) {
				if (this.flowItems[i].Id === data.Id) {
					itemIndex = i;
					break;
				}
			}

			for (var i = 0; i < this.tempFlowItems.length; i++) {
				if (this.tempFlowItems[i].Id === data.Id) {
					tempItemIndex = i;
					break;
				}
			}

			if (itemIndex !== -1) {
				for (var j = this.flowItems.length - 1; j > itemIndex; j--) {
					this.tempFlowItems.splice(0, 0, this.flowItems[j]);
				}

				this.flowItems.splice(itemIndex + 1, this.flowItems.length);
			} else if (tempItemIndex !== -1) {
				for (var i = 0; i <= tempItemIndex; i++) {
					this.flowItems.push(this.tempFlowItems[i]);
				}

				this.tempFlowItems.splice(0, tempItemIndex + 1);
			} else {
				this.tempFlowItems = [];
				this.flowItems.push(data);
			}
		},

		saveState: function(key, value) {
			if (!this.flowItems || this.flowItems.length == 0) return;

			var currentItem = this.flowItems[this.flowItems.length - 1];
			if (!currentItem.savedState) {
				currentItem.savedState = {};
			}

			currentItem.savedState[key] = value;
		},

		getState: function(key) {
			if (!this.flowItems || this.flowItems.length == 0) return null;

			var currentItem = this.flowItems[this.flowItems.length - 1];
			if (currentItem.savedState) return currentItem.savedState[key];

			return null;
		},

		refreshOnQueuedAutoInvestigationsStates: function(queuedAlertIdsArray, onResponseFunction) {
			if (!queuedAlertIdsArray) {
				return null;
			} // no ids to update states on
			if (!onResponseFunction) {
				$log.error(
					'refreshOnQueuedAutoInvestigationsStates: response function is required and was not supplied'
				);
				return null;
			}

			// returns cancel interval operation - if needed to be called on page redirection
			return requestByInterval(
				urlMapping.getThreatIntelUrl() + '/AlertsByIds',
				{ alertsIds: queuedAlertIdsArray },
				onResponseFunction
			);
		},

		isInvestigationStateTracked: function(investigationState) {
			return (
				[stateMap.PendingResource, stateMap.PendingApproval, stateMap.Running].indexOf(
					investigationState
				) !== -1
			);
		},

		refreshOnAutoInvestigationsStates: function(autoInvestigationIdsArray, onResponseFunction) {
			if (!autoInvestigationIdsArray) {
				return null;
			} // no ids to update states on
			if (!onResponseFunction) {
				$log.error(
					'refreshOnAutoInvestigationsStates: response function is required and was not supplied'
				);
				return null;
			}

			// returns cancel interval operation - if needed to be called on page redirection
			return requestByInterval(
				urlMapping.getThreatIntelUrl() + '/Investigations',
				{ investigationIds: autoInvestigationIdsArray },
				onResponseFunction
			);
		},
	};

	merge(searchService.search$, errorsService.errorPage$).subscribe(() => service.clearFlow());

	return service;
}
