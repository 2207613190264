/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../shared/components/tabs/tabs.component.ngfactory";
import * as i2 from "../../../shared/components/tabs/tabs.component";
import * as i3 from "@angular/router";
import * as i4 from "@angular/common";
import * as i5 from "./suppression-rule.entity.component";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../shared/services/title.service";
var styles_SuppressionRuleEntityComponent = [];
var RenderType_SuppressionRuleEntityComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuppressionRuleEntityComponent, data: {} });
export { RenderType_SuppressionRuleEntityComponent as RenderType_SuppressionRuleEntityComponent };
function View_SuppressionRuleEntityComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tabs", [["class", "wcd-flex-0 wcd-padding-medium-all"]], [[2, "wcd-responsive-shell-padding-none-left-sm-screens", null]], null, null, i1.View_TabsComponent_0, i1.RenderType_TabsComponent)), i0.ɵdid(1, 4833280, null, 0, i2.TabsComponent, [i0.ChangeDetectorRef, i3.ActivatedRoute, i3.Router], { tabsData: [0, "tabsData"], currentRouteIsActive: [1, "currentRouteIsActive"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.tabs; var currVal_2 = true; _ck(_v, 1, 0, currVal_1, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isScc; _ck(_v, 0, 0, currVal_0); }); }
export function View_SuppressionRuleEntityComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "div", [["class", "wcd-full-height wcd-flex-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SuppressionRuleEntityComponent_1)), i0.ɵdid(4, 16384, null, 0, i4.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(6, 0, null, null, 4, "div", [["class", "wcd-flex-1"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(8, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i0.ɵdid(9, 212992, null, 0, i3.RouterOutlet, [i3.ChildrenOutletContexts, i0.ViewContainerRef, i0.ComponentFactoryResolver, [8, null], i0.ChangeDetectorRef], null, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.tabs; _ck(_v, 4, 0, currVal_0); _ck(_v, 9, 0); }, null); }
export function View_SuppressionRuleEntityComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "suppression-rule-entity", [], null, null, null, View_SuppressionRuleEntityComponent_0, RenderType_SuppressionRuleEntityComponent)), i0.ɵdid(1, 114688, null, 0, i5.SuppressionRuleEntityComponent, [i6.I18nService, i7.TitleService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppressionRuleEntityComponentNgFactory = i0.ɵccf("suppression-rule-entity", i5.SuppressionRuleEntityComponent, View_SuppressionRuleEntityComponent_Host_0, { entity: "entity", isFullScreen: "isFullScreen", action$: "action$", entityPageViewMode: "entityPageViewMode" }, { runAction$: "runAction$" }, []);
export { SuppressionRuleEntityComponentNgFactory as SuppressionRuleEntityComponentNgFactory };
