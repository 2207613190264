<fab-message-bar *ngIf="ruleHaveNonSupportedCategories">
	<markdown>
		{{ 'endpointManagement.nonWindows.applications.dataExport.goToNewPageMarkdown' | i18n}}
	</markdown>
</fab-message-bar>
<form #dataExportDetailsForm="ngForm" (submit)="save()" (keydown.enter)="$event.preventDefault()" class="wcd-full-height wcd-flex-vertical">
	<div class="wcd-padding-vertical wcd-padding-large-horizontal wcd-scroll-vertical wcd-flex-1">
	<section class="wcd-padding-bottom">
			<dl [class.key-values]="asKeyValueList" role="none">
				<dt>{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.id' | i18n }}</dt>
				<dd>
					{{ dataExportSettings.id }}
				</dd>
				<dt></dt>
				<ng-container *ngIf="dataExportSettings.eventHubProperties">
					<dt>{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.eventhub' | i18n }}</dt>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.subscriptionId' | i18n }}</dt>
					<dd>{{ dataExportSettings.eventHubProperties.subscriptionId }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.resourceGroup' | i18n }}</dt>
					<dd>{{ dataExportSettings.eventHubProperties.resourceGroup }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.namespace' | i18n }}</dt>
					<dd>{{ dataExportSettings.eventHubProperties.namespace }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.id' | i18n }}</dt>
					<dd>{{ dataExportSettings.eventHubProperties.name }}</dd>
				</ng-container>
				<ng-container *ngIf="dataExportSettings.storageAccountProperties">
					<dt>{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.storage' | i18n }}</dt><td></td>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.subscriptionId' | i18n }}</dt>
					<dd>{{ dataExportSettings.storageAccountProperties.subscriptionId }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.resourceGroup' | i18n }}</dt>
					<dd>{{ dataExportSettings.storageAccountProperties.resourceGroup }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.id' | i18n }}</dt>
					<dd>{{ dataExportSettings.storageAccountProperties.name }}</dd>
				</ng-container>
				<ng-container *ngIf="dataExportSettings.workspaceProperties">
					<dt>{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.workspace' | i18n }}</dt><td></td>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.subscriptionId' | i18n }}</dt>
					<dd>{{ dataExportSettings.workspaceProperties.subscriptionId }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.resourceGroup' | i18n }}</dt>
					<dd>{{ dataExportSettings.workspaceProperties.resourceGroup }}</dd>
					<dt class="wcd-padding-small-left">{{ 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.id' | i18n }}</dt>
					<dd>{{ dataExportSettings.workspaceProperties.name }}</dd>
				</ng-container>
			</dl>
		</section>
		<section class="wcd-padding-bottom">
			<div class="wcd-margin-small-top form-group">
				<div class="wcd-margin-small-bottom">{{ 'endpointManagement.nonWindows.applications.dataExport.details.eventTypesCombo' | i18n }}</div>
				<div *ngIf="deprecatedEventTypesAreUsed" class="wcd-padding-small-bottom">
					<data-export-info-box>
						<markdown class="wcd-flex-1">{{ 'endpointManagement.nonWindows.applications.dataExport.deprecationMessage' | i18n}}</markdown>
					</data-export-info-box>
				</div>
				<wcd-checklist
					[ariaLabel]="'endpointManagement.nonWindows.applications.dataExport.details.eventTypesCombo' | i18n"
					id="eventTypesFieldId"
					name="data-export-event-types"
					[selectById]="false"
					[(ngModel)]="selectedEventTypes"
					(ngModelChange)="onLogTypesChange($event)"
					class="wcd-margin-medium-bottom"
					[isDisabled]="isReadOnlyPanel"
					[values]="availableEventTypes">
				</wcd-checklist>
				<div class="error-message wcd-margin-small-top"
					[class.invisible]="selectedEventTypes.length > 0">
					{{ 'endpointManagement.nonWindows.applications.dataExport.details.validations.eventTypes' | i18n }}
				</div>
			</div>
		</section>
	</div>
	<div class="wcd-flex-none wcd-padding-large-horizontal wcd-padding-vertical wcd-border-top wcd-flex-horizontal">
		<div class="wcd-flex-1 wcd-margin-right wcd-flex-content-start">
			<div [wcdTooltip]="tooltip">
				<fab-primary-button
						className="wcd-margin-small-right"
						data-track-id="SaveDataExportEdit"
						data-track-type="Button"
						[disabled]="!isValid || !isDirty || !userIsGlobalAdmin || isReadOnlyPanel"
						(onClick)="save()">
					{{ 'buttons.save' | i18n }}
					<fab-spinner *ngIf="isSaving" [size]="SpinnerSize.xSmall" className="wcd-margin-xsmall-horizontal"></fab-spinner>
				</fab-primary-button>
			</div>
			<fab-default-button
				(onClick)="cancel()"
				data-track-id="CancelDataExportEdit"
				data-track-type="Button"
				[text]="'endpointManagement.nonWindows.applications.dataExport.details.discardButton' | i18n"
				></fab-default-button>
		</div>
	</div>
</form>
