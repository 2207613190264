import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ThreatInfo } from '@wcd/domain';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmTextsService } from '../../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'threat-insights',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './threat-insights.details.component.html',
})
export class ThreatInsightsDetailsComponent implements OnInit {
	SpinnerSize = SpinnerSize;
	exploitTypesStr: string;
	isListCollapsed = false;
	@Input() threatInfo: ThreatInfo;

	constructor(private tvmTextService: TvmTextsService) {}

	ngOnInit() {
		this.exploitTypesStr = this.threatInfo.exploitTypes
			.map(type => this.tvmTextService.exploitTypesToText[type])
			.join(' / ');
	}
}
