import { AppInsightsService } from './../../../../insights/services/app-insights.service';
import { SevilleModule } from '../../seville/seville.module';

SevilleModule.directive('sidePaneIp', sidePaneIpIndicatorDirective);

sidePaneIpIndicatorDirective.$inject = ['sidePaneService'];

function sidePaneIpIndicatorDirective(sidePaneService) {
	return {
		restrict: 'E',
		bindToController: true,
		controllerAs: 'pi',
		scope: {
			ip: '=',
			hideIndicator: '=',
			eventTime: '=',
		},
		template: `
		<span class="side-pane-indicator" tabindex="0" role="button" aria-label="Open IP side pane" data-track-id="sidePaneButtonIp" data-track-type="SidePaneToggleButton">
        <i class="icon icon-{{pi.icon}} pointer side-pane-indicator-icon" ng-click="toggle($event)" ng-if="!pi.hideIndicator"></i>
    </span>
		`,
		controller: [
			'$scope',
			'$http',
			'urlMapping',
			'$filter',
			'demoTenantResolverService',
			'$log',
			'sidePaneDataProviderService',
			'appInsights',
			function(
				$scope,
				$http,
				urlMapping,
				$filter,
				demoTenantResolverService,
				$log,
				sidePaneDataProviderService,
				appInsights: AppInsightsService
			) {
				var vm = this;

				this.$onInit = () => {
					vm.key = vm.ip;
				};

				this.togglePane = function() {
					appInsights.trackEvent('UsageTrack', {
						ButtonType: 'SidePane',
						Page: 'IP',
						Component: 'IpSidePane',
					});

					vm.instance = sidePaneService.open({
						key: vm.ip,
						title: vm.ip,
						type: 'entity',
						icon: 'icon-Streaming',
						link: {
							text: 'Go to IP page',
							state: 'ip',
							params: { id: vm.ip },
						},
						sections: [
							{
								title: 'IP details',
								order: 1,
							},
							{
								title: 'Related alerts',
								order: 2,
							},
							{
								title: 'Observed in organization',
								subtitle: '(Last ' + demoTenantResolverService.getStatsLookback() + ' days)',
								order: 3,
							},
						],
					});

					function loadProfile() {
						sidePaneDataProviderService.loadIpProfile(vm.ip, vm.instance, vm.eventTime).then(
							function(response) {
								if (response.section) {
									vm.instance.setSection('IP details', response.section);
									setupIpDetails();
								} else {
									if (response.error) {
										vm.instance.setSectionError('IP details', response.error);
									} else {
										vm.instance.setError('IP details', 'Data could not be retrieved.');
									}
								}
							},
							function(response) {
								if (response.error) {
									vm.instance.setError(response.error);
								} else {
									vm.instance.setError('Data could not be retrieved.');
								}
							}
						);
					}

					function loadAlerts() {
						sidePaneDataProviderService.loadAlertsForIp(vm.ip, vm.instance).then(
							function(response) {
								if (response.section) {
									vm.instance.setSection('Related alerts', response.section);
									vm.instance.setSectionLoading('Related alerts', false);
								} else {
									if (response.error) {
										vm.instance.setSectionError('Related alerts', response.error);
									} else {
										vm.instance.setSectionError(
											'Related alerts',
											'Data could not be retrieved.'
										);
									}
								}
							},
							function(response) {
								if (response.error) {
									vm.instance.setSectionError('Related alerts', response.error);
								} else {
									vm.instance.setSectionError(
										'Related alerts',
										'Data could not be retrieved.'
									);
								}
							}
						);
					}

					function loadStats() {
						sidePaneDataProviderService.loadIpStats(vm.ip, vm.instance).then(
							function(response) {
								if (response.section) {
									vm.instance.setSection('Observed in organization', response.section);
									vm.instance.setSectionLoading('Observed in organization', false);
								} else {
									if (response.error) {
										vm.instance.setSectionError(
											'Observed in organization',
											response.error
										);
									} else {
										vm.instance.setSectionError(
											'Observed in organization',
											'Data could not be retrieved.'
										);
									}
								}
							},
							function(response) {
								if (response.error) {
									vm.instance.setSectionError('Observed in organization', response.error);
								} else {
									vm.instance.setSectionError(
										'Observed in organization',
										'Data could not be retrieved.'
									);
								}
							}
						);
					}

					function setupIpDetails() {
						var section = vm.instance.getSection('IP details');
						if (!section) {
							$log.error('Section IP details does not exist in ip side pane');
							return;
						}

						var nonNullData = section.props.filter(function(dat) {
							return dat.value;
						});
						if (!nonNullData || nonNullData.length == 0) {
							vm.instance.setSectionError('IP details', 'No data available');
						}

						vm.instance.setLoading(false);
						vm.instance.setSectionLoading('IP details', false);
					}

					loadProfile();
					loadAlerts();
					loadStats();
				};
			},
		],
	};
}
