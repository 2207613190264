var NavItemModel = /** @class */ (function () {
    function NavItemModel(navItemConfig) {
        this.isExpanded = false;
        this.id = navItemConfig.id || navItemConfig.route;
        if (!this.id)
            throw new Error("Can't create NavMenuItem - no ID.");
        this.name = navItemConfig.name;
        if (!this.name)
            throw new Error("Can't create NavMenuItem, no menu or state title.");
        this.icon = navItemConfig.icon;
        this.getImage = navItemConfig.getImage;
        this.getImageClass = navItemConfig.getImageClass;
        this.route = navItemConfig.route || navItemConfig.path;
        this.isFolder = navItemConfig.isFolder === true;
        this.isDisabled = navItemConfig.isDisabled;
        this.isLegacy = !!navItemConfig.isLegacy;
        this.externalLink = navItemConfig.externalLink;
        this.externalLinkTarget = navItemConfig.externalLinkTarget;
        this.showExternalLinkIcon = navItemConfig.showExternalLinkIcon;
        this.routeParams = navItemConfig.routeParams;
        this.features = navItemConfig.features || null;
        this.featuresLogicalOperator = navItemConfig.featuresLogicalOperator;
        this.disableFeatures = navItemConfig.disableFeatures || null;
        this.section = navItemConfig.section;
        this.queryParams = navItemConfig.queryParams;
        this.badgeId = navItemConfig.badgeId;
        this.openInNewTab = navItemConfig.openInNewTab;
        this.isAlias = navItemConfig.isAlias;
        if (navItemConfig.isFolder && navItemConfig.children)
            this.children = navItemConfig.children.map(function (childConfig) { return new NavItemModel(childConfig); });
    }
    Object.defineProperty(NavItemModel.prototype, "routerLink", {
        get: function () {
            return ['/' + this.route].concat(this.routeParams || []);
        },
        enumerable: true,
        configurable: true
    });
    return NavItemModel;
}());
export { NavItemModel };
