var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ1 = function (queryText) { return ({ data: { QueryText: queryText } }); }, ɵ2 = function (_a) {
    var MtpWorkloads = _a.MtpWorkloads;
    return MtpWorkloads;
};
var HuntingQueryMtpWorkloadsApiCall = /** @class */ (function (_super) {
    __extends(HuntingQueryMtpWorkloadsApiCall, _super);
    function HuntingQueryMtpWorkloadsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQueryMtpWorkloadsApiCall = __decorate([
        ApiCall({
            name: 'Get query MTP workloads',
            endpoint: 'hunting/query/mtpWorkloads',
            baseUrl: ɵ0,
            method: 'POST',
            parseQuery: ɵ1,
            parse: ɵ2,
        })
    ], HuntingQueryMtpWorkloadsApiCall);
    return HuntingQueryMtpWorkloadsApiCall;
}(ApiCallModel));
export { HuntingQueryMtpWorkloadsApiCall };
var ɵ3 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ4 = function (queryText) { return ({ data: JSON.stringify(queryText) }); }, ɵ5 = function (_a) {
    var MtpWorkloads = _a.MtpWorkloads;
    return MtpWorkloads;
};
var HuntingQueryMtpWorkloadsApiCallVNext = /** @class */ (function (_super) {
    __extends(HuntingQueryMtpWorkloadsApiCallVNext, _super);
    function HuntingQueryMtpWorkloadsApiCallVNext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQueryMtpWorkloadsApiCallVNext = __decorate([
        ApiCall({
            name: 'Get query MTP workloads',
            endpoint: 'schema/query/mtpWorkloads',
            baseUrl: ɵ3,
            method: 'POST',
            parseQuery: ɵ4,
            parse: ɵ5,
        })
    ], HuntingQueryMtpWorkloadsApiCallVNext);
    return HuntingQueryMtpWorkloadsApiCallVNext;
}(ApiCallModel));
export { HuntingQueryMtpWorkloadsApiCallVNext };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
