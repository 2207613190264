
		<div class="wcd-full-height wcd-flex-1 search-results">
			<entity-dataview
				[textHighlight]="searchTerm$ | async"
				tags="wcd-datatable-field-value"
				[entityType]="(entityType$ | async).entity"
				[options]="dataViewOptions$ | async"
				[disableFilterPanelAutoFocus]="true"
				(fixedOptionsChanged)="onFixedOptionsChanged($event)"
				(dataChanged)="onDataChanged($event)"
				(newItemAdded)="updateTextHighlight()"
				(groupExpanded)="updateTextHighlight()"
				[queueHeader]="true"
				[padLeft]="dataviewLeftPadding"
				commandBarNoPadding="true"
				[removePadding]="isScc"
				[removePaddingRight]="isScc"
				[responsiveActionBar]="true"
			>
				<div dataview-header class="results-header">
					<h3>{{ header$ | async }}</h3>
				</div>
			</entity-dataview>
		</div>
	