import {
	ChangeDetectionStrategy,
	Component,
	HostBinding,
	Input,
	ChangeDetectorRef,
	ViewEncapsulation,
} from '@angular/core';

export interface TvmImpactScoreData {
	exposureScore?: number;
	secureScore?: number;
	exposureScoreTooltip?: string;
	secureScoreTooltip?: string;
}

@Component({
	selector: 'tvm-impact-score',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tvm-impact-score.component.html',
	styleUrls: ['./tvm-impact-score.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class TvmImpactScoreComponent {
	@HostBinding('class.wcd-inline-flex-center-vertical') readonly hostInlineFlexCenterVertical = true;

	_scoreData: TvmImpactScoreData;
	exposureScore: string;

	@Input()
	set scoreData(scoreData: TvmImpactScoreData) {
		this._scoreData = scoreData;
		this.hasExposureScore = typeof scoreData.exposureScore === 'number';
		this.exposureScore = !this.hasExposureScore
			? undefined
			: this._scoreData.exposureScore >= 0.01 || this._scoreData.exposureScore === 0
			? this._scoreData.exposureScore.toFixed(2)
			: '<0.01';
		this.hasSecureScore = typeof scoreData.secureScore === 'number';
		this.showSeparator = this.hasExposureScore && this.hasSecureScore;
		this.changeDetectorRef.markForCheck();
	}

	hasExposureScore: boolean;
	hasSecureScore: boolean;
	showSeparator: boolean;

	constructor(private changeDetectorRef: ChangeDetectorRef) {}
}
