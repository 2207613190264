
		<div class="wcd-full-height" [class.wcd-flex-center-vertical]="!presentValueAsTable">
			<span *ngIf="propertyData && columnInfo.icon" class="hunting-property-cell__entity-icon">
				<fab-icon [iconName]="columnInfo.icon"></fab-icon>
			</span>
			<route-link
				*ngIf="link && propertyData && !columnInfo.isClickable; else cellContentTemplate"
				[routeLink]="link"
				class="hunting-property-cell__link"
				rel="noopener"
				[linkAriaLabel]="columnInfo.name + ': ' + propertyData"
			>
				<ng-container *ngTemplateOutlet="cellContentTemplate"></ng-container>
			</route-link>

			<ng-template #cellContentTemplate>
				<ng-container *ngIf="!presentValueAsTable; else presentAsTable">
					<ng-container [ngSwitch]="columnInfo.type">
						<span
							*ngSwitchCase="'date'"
							[wcdTooltip]="propertyData | date: 'MM/dd/yyyy HH:mm:ss.SSS'"
						>
							{{ propertyData | date: 'M/d/yyyy H:mm:ss' }}
						</span>
						<span
							*ngSwitchCase="'object'"
							[wcdTooltip]="formatObjectString(propertyData)"
							[filterHtml]="true"
							>{{ formatObjectString(propertyData) }}</span
						>

						<span *ngSwitchDefault [wcdTooltip]="propertyData" [filterHtml]="true">
							<span *ngIf="columnInfo.isClickable; else notClickable">
								<span *ngIf="!isUserEntity; else userDataCell"
									class="hunting-property-cell__link"
									(click)="
									openEntitySidePanelClickEvent.emit({
										ItemId: propertyData,
										entityType: columnInfo.entityType
									})">
									{{ propertyData }}
								</span>
								<ng-template #userDataCell>
									<span class="hunting-property-cell__link"
										  (click)="onUserCellClick($event)">
										{{ propertyData }}
									</span>
								</ng-template>
							</span>
							<ng-template #notClickable>
								{{ propertyData }}
							</ng-template>
						</span>
					</ng-container>
				</ng-container>
				<ng-template #presentAsTable>
					<wcd-datatable
						[columns]="tableColumns"
						[items]="tableItems"
						[selectEnabled]="false"
						[isSmallPadding]="true"
						[showHeaders]="showTableHeaders"
					>
					</wcd-datatable>
				</ng-template>
			</ng-template>
		</div>
	