import { FabButtonModule, FabIconModule, FabSpinnerModule, FabMessageBarModule } from '@angular-react/fabric';
import { NgModule } from '@angular/core';
import { DataViewsModule } from '../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { CustomTiIndicatorEditComponent } from './components/custom-ti-indicator-details.component';
import { CustomTiIndicatorImportComponent } from './components/custom-ti-indicator-import.component';
import { CustomTiIndicatorImportPanelComponent } from './components/custom-ti-indicator-import.panel.component';
import { CustomTiIndicatorNewComponent } from './components/custom-ti-indicator-new.component';
import { CustomTiIndicatorNewModalComponent } from './components/custom-ti-indicator-new.modal.component';
import { CustomTiIndicatorBackendService } from './services/custom-ti-indicator.backend.services';
import { CustomTiIndicatorEntityTypeService } from './services/custom-ti-indicator.entity-type.service';
import { CustomTiIndicatorsFields } from './services/custom-ti-indicators.fields';
import { CustomTiIndicatorsService } from './services/custom-ti-indicators.service';
import { PanelsModule } from '@wcd/panels';

@NgModule({
	imports: [
		SharedModule,
		DataViewsModule,
		FabIconModule,
		FabSpinnerModule,
		FabButtonModule,
		FabMessageBarModule,
		PanelsModule,
	],
	declarations: [
		CustomTiIndicatorEditComponent,
		CustomTiIndicatorNewComponent,
		CustomTiIndicatorNewModalComponent,
		CustomTiIndicatorImportComponent,
		CustomTiIndicatorImportPanelComponent,
	],
	providers: [
		CustomTiIndicatorsService,
		CustomTiIndicatorsFields,
		CustomTiIndicatorEntityTypeService,
		CustomTiIndicatorBackendService,
	],
	entryComponents: [
		CustomTiIndicatorEditComponent,
		CustomTiIndicatorNewComponent,
		CustomTiIndicatorNewModalComponent,
		CustomTiIndicatorImportComponent,
		CustomTiIndicatorImportPanelComponent,
	],
})
export class CustomTiIndicatorsEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(CustomTiIndicatorEntityTypeService);
	}
}
