//Based on the following: https://gist.github.com/timgit/7bc5896f5297301afb02

import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('megaNumber', megaNumberFilter);

function megaNumberFilter() {
	return function(number, fractionSize) {
		if (number === undefined) return '0';
		if (number === null) return '0';
		if (number === 0) return '0';

		if (!fractionSize || fractionSize < 0) fractionSize = 1;

		var abs = Math.abs(number);
		var rounder = Math.pow(10, fractionSize);
		var isNegative = number < 0;
		var key = '';
		var powers = [
			{ key: 'q', value: Math.pow(10, 15) },
			{ key: 't', value: Math.pow(10, 12) },
			{ key: 'b', value: Math.pow(10, 9) },
			{ key: 'm', value: Math.pow(10, 6) },
			{ key: 'k', value: 1000 },
		];

		for (var i = 0; i < powers.length; i++) {
			var reduced = abs / powers[i].value;

			reduced = Math.round(reduced * rounder) / rounder;

			if (reduced >= 1) {
				abs = reduced;
				key = powers[i].key;
				break;
			}
		}

		return (isNegative ? '-' : '') + abs + key;
	};
}
