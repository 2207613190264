import {
	ApplicationRef,
	ComponentFactory,
	ComponentFactoryResolver,
	EmbeddedViewRef,
	Inject,
	Injectable,
	Injector,
} from '@angular/core';
import { CopyDialogComponent, CopyDialogComponentOptions } from './components/copy-dialog.component';
import { DOCUMENT } from '@angular/common';
import { take } from 'rxjs/operators';

@Injectable()
export class CopyDialogService {
	private readonly _copyComponentFactory: ComponentFactory<CopyDialogComponent>;

	constructor(
		private readonly applicationRef: ApplicationRef,
		private readonly injector: Injector,
		@Inject(DOCUMENT) private readonly document: Document,
		componentFactoryResolver: ComponentFactoryResolver
	) {
		this._copyComponentFactory = componentFactoryResolver.resolveComponentFactory(CopyDialogComponent);
	}

	async showCopyDialog(copyDialogComponentOptions: CopyDialogComponentOptions) {
		const componentRef = this._copyComponentFactory.create(this.injector);
		componentRef.instance.options = copyDialogComponentOptions;
		componentRef.changeDetectorRef.detectChanges();

		this.applicationRef.attachView(componentRef.hostView);
		this.document.body.appendChild((componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0]);

		await componentRef.instance.onClosed.pipe(take(1)).toPromise<boolean>();

		this.applicationRef.detachView(componentRef.hostView);
		componentRef.destroy();
	}
}
