var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GenericFieldType, Url, File } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from 'app/global_entities/services/global-entity-types.service';
import { FeaturesService, Feature } from "@wcd/config";
var GenericFieldsListComponent = /** @class */ (function () {
    function GenericFieldsListComponent(featuresService, paris, globalEntityTypesService) {
        this.featuresService = featuresService;
        this.paris = paris;
        this.globalEntityTypesService = globalEntityTypesService;
        this.processTreeElementStyle = false;
        this.genericFieldType = GenericFieldType;
        this.cyberEntities = new Map();
    }
    GenericFieldsListComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.genericFields.filter(function (gField) { return gField.valueType == GenericFieldType.CyberEntity; })
            .forEach(function (gField) { return _this.fetchCyberEntities(gField); });
    };
    GenericFieldsListComponent.prototype.fetchCyberEntities = function (gField) {
        var _this = this;
        var _a;
        var cyberEntityType = this.getCyberEntityType(gField);
        if (!cyberEntityType) {
            return;
        }
        if (cyberEntityType === Url) {
            var decodeUrl = decodeURI(gField.entityId);
            this.cyberEntities.set(gField.entityId, new Url({ id: decodeUrl, name: decodeUrl }));
        }
        else if (cyberEntityType === File) {
            this.paris.getItemById(cyberEntityType, gField.entityId, undefined, (_a = {}, _a['newFilesApi'] = this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW), _a)).subscribe(function (item) {
                _this.cyberEntities.set(gField.entityId, __assign({}, item, { fileName: gField.value }));
            });
        }
        else {
            this.paris.getItemById(cyberEntityType, gField.entityId).subscribe(function (item) {
                _this.cyberEntities.set(gField.entityId, item);
            });
        }
    };
    GenericFieldsListComponent.prototype.getCyberEntityType = function (genericField) {
        var type = genericField.entityType.toString();
        return this.globalEntityTypesService.getEntityType(type === 'process' ? 'file' : type).entity;
    };
    GenericFieldsListComponent.prototype.hasTooltipValue = function (valueType) {
        return (valueType == GenericFieldType.String ||
            valueType == GenericFieldType.DateTime ||
            valueType == GenericFieldType.CopyPastableString ||
            valueType == GenericFieldType.LinkableString);
    };
    GenericFieldsListComponent.prototype.getGenericFieldValueClass = function (valueType) {
        return {
            'alert-process-tree-details': this.processTreeElementStyle,
            'alert-process-tree-details--value': this.processTreeElementStyle,
            'alert-process-tree-details--value--mitreTechnique': this.processTreeElementStyle && this.isMitreTechniques(valueType),
            'wcd-text-overflow-ellipsis-nowrap': this.processTreeElementStyle && !this.isMitreTechniques(valueType),
            'wcd-text-overflow-ellipsis': this.processTreeElementStyle && !this.isMitreTechniques(valueType),
        };
    };
    GenericFieldsListComponent.prototype.isMitreTechniques = function (valueType) {
        return valueType == this.genericFieldType.MitreTechniques;
    };
    GenericFieldsListComponent.prototype.isEntityClickable = function (genericField) {
        return !!(this.cyberEntities &&
            this.cyberEntities.has(genericField.entityId));
    };
    GenericFieldsListComponent.prototype.getCyberEntity = function (genericField) {
        return this.cyberEntities.get(genericField.entityId);
    };
    return GenericFieldsListComponent;
}());
export { GenericFieldsListComponent };
