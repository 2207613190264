import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	OnDestroy,
	Injector,
	OnChanges,
	SimpleChanges,
} from '@angular/core';
import { of, Subscription, Observable } from 'rxjs';
import { catchError, filter } from 'rxjs/operators';
import {
	BaselineProfileDevice,
	DataSensitivity,
	Machine,
	MachineDataSensitivityRelationship,
} from '@wcd/domain';
import { ModelBase, Paris } from '@microsoft/paris';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { SpinnerSize } from 'office-ui-fabric-react';

@Component({
	selector: 'baseline-profile-device-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './baseline-profile-device.entity-details.component.html',
})
export class BaselineProfileDeviceEntityDetailsComponent
	extends EntityDetailsComponentBase<BaselineProfileDevice>
	implements OnInit, OnDestroy, OnChanges {
	private _subscription: Subscription;
	readonly SpinnerSize = SpinnerSize;

	@Input() baselineProfileDevice?: BaselineProfileDevice;
	@Input() machine?: Machine;
	noDataAvailable: string;
	dataSensitivity$: Observable<DataSensitivity>;

	constructor(
		private readonly paris: Paris,
		private activatedEntity: ActivatedEntity,
		public i18nService: I18nService,
		injector: Injector
	) {
		super(injector);
		this.noDataAvailable = i18nService.strings.notAvailable_short;
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.machine) {
			this.dataSensitivity$ = this.paris
				.getRelatedItem<Machine, DataSensitivity>(MachineDataSensitivityRelationship, this.machine)
				.pipe(catchError(_ => of(null)));
		}
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.baselineProfileDevice) {
			this._subscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof BaselineProfileDevice))
				.subscribe((device: BaselineProfileDevice) => {
					this.baselineProfileDevice = device;
				});
		}
	}

	ngOnDestroy() {
		this._subscription && this._subscription.unsubscribe();
	}
}
