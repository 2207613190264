import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Allow non authenticated Sense - status',
	pluralName: 'Allow non authenticated Sense - status',
	endpoint: 'senseauth/allownonauthsense',
	parseItemQuery: () => 'senseauth/allownonauthsense',
	saveMethod: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	readonly: true,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class AllowNonAuthenticatedSenseStatus extends EntityModelBase {
	@EntityField({
		parse: (value, data) => data === true,
		defaultValue: false,
	})
	allowNonAuthenticatedSense: boolean;

	failed: boolean = false;
}
