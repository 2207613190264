import { OnChanges } from '@angular/core';
import { Incident, GetIncidentUserGroupsApiCall, GetIncidentMachineSensitivitiesApiCall, GetIncidentMachineGroupsApiCall, IncidentUsersSummaryRelationship, UsersSummary, Machine, IncidentMachinesRelationship, } from '@wcd/domain';
import { DataQuerySortDirection, Paris } from '@microsoft/paris';
import { throwError, combineLatest, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AppContextService, FlavorService } from '@wcd/config';
import { ShimmerElementType } from 'office-ui-fabric-react';
import { uniqBy, compact } from 'lodash-es';
import { EntityTagsService } from '../../../../@entities/common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
var SHIMMER_LINE = { type: ShimmerElementType.line, width: '30%' };
var SHIMMER_GAP = { type: ShimmerElementType.gap, width: '5%' };
var SHIMMER_ELEMENTS = [SHIMMER_LINE, SHIMMER_GAP, SHIMMER_LINE, SHIMMER_GAP, SHIMMER_LINE];
var incidentMachinesQueryOptions = {
    sortBy: [{ field: 'RiskScore', direction: DataQuerySortDirection.descending }],
};
var IncidentEntityDetailsTagsComponent = /** @class */ (function () {
    function IncidentEntityDetailsTagsComponent(paris, appContextService, entityTagsService, flavorService) {
        this.paris = paris;
        this.entityTagsService = entityTagsService;
        this.shimmerElements = SHIMMER_ELEMENTS;
        this.apiData = {
            dataClassifications: {
                tags$: null,
                apiCallType: GetIncidentMachineSensitivitiesApiCall,
                error: false,
            },
            machineGroups: {
                tags$: null,
                apiCallType: GetIncidentMachineGroupsApiCall,
                error: false,
            },
            userGroups: {
                tags$: null,
                apiCallType: GetIncidentUserGroupsApiCall,
                error: false,
            },
        };
        this.isMtp = false;
        this.isMtp = appContextService.isMtp;
        this.incidentMachinesRepository = paris.getRelationshipRepository(IncidentMachinesRelationship);
        this.withDataSensitivity = flavorService.isEnabled(AppFlavorConfig.incidents.dataSensitivity);
    }
    IncidentEntityDetailsTagsComponent.prototype.ngOnChanges = function (changes) {
        var incident = changes && changes.incident.currentValue;
        if (incident) {
            this.incidentMachinesRepository.sourceItem = incident;
            this.setApiCall(this.apiData.dataClassifications);
            this.setApiCall(this.apiData.machineGroups, incident.incidentTags ? of(null) : this.getIncidentMachineTags());
            if (this.isMtp) {
                this.useUserSummaryApi
                    ? this.setUserGroupsRelationship(incident)
                    : this.setApiCall(this.apiData.userGroups);
            }
        }
    };
    Object.defineProperty(IncidentEntityDetailsTagsComponent.prototype, "incidentTags", {
        get: function () {
            return this.incident.incidentTags
                ? this.entityTagsService.getIncidentTags(this.incident)
                : this.incident.tags;
        },
        enumerable: true,
        configurable: true
    });
    IncidentEntityDetailsTagsComponent.prototype.setUserGroupsRelationship = function (incident) {
        var repository = this.paris.getRelationshipRepository(IncidentUsersSummaryRelationship);
        var apiData = this.apiData.userGroups;
        repository.sourceItem = incident;
        apiData.tags$ = repository.queryItem().pipe(map(function (userSummary) {
            return userSummary.userGroups && userSummary.userGroups.length > 0 && userSummary.userGroups;
        }), catchError(function (err) {
            apiData.error = true;
            return throwError(err);
        }));
        apiData.error = false;
    };
    IncidentEntityDetailsTagsComponent.prototype.setApiCall = function (apiData, userTags$) {
        if (userTags$ === void 0) { userTags$ = of([]); }
        apiData.tags$ = combineLatest(this.paris.apiCall(apiData.apiCallType, this.incident).pipe(catchError(function (err) {
            apiData.error = true;
            return throwError(err);
        })), userTags$).pipe(map(function (_a) {
            var machineTags = _a[0], userTags = _a[1];
            return compact(machineTags.concat(userTags));
        }), map(function (tags) { return tags.length > 0 && tags; }));
        apiData.error = false;
    };
    IncidentEntityDetailsTagsComponent.prototype.getIncidentMachineTags = function () {
        return this.incidentMachinesRepository.query(incidentMachinesQueryOptions).pipe(map(function (r) {
            return uniqBy(r.items.reduce(function (flattenTags, machine) {
                var userTags = machine.userDefinedTags.map(function (tag) { return ({
                    id: tag,
                    name: tag,
                    type: 'user',
                }); });
                return flattenTags.concat(userTags);
            }, []), function (tag) { return tag.name; });
        }), map(function (tags) { return tags.length > 0 && tags; }));
    };
    return IncidentEntityDetailsTagsComponent;
}());
export { IncidentEntityDetailsTagsComponent };
