import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
	DataQuerySortDirection,
} from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { AlertsSeveritySummary } from '../alert/alerts-severity-summary.value-object';
import { AatpProfile } from '../azure-threat-analytics/aatp-profile.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { TagsCollection } from '../tag/tag-collection';
import { MachineIdType } from './machine-id-type.enum';
import { MachineNetworkInfo } from './machine-ip-adapters';
import { MachineSecurityAnalytics } from './machine-security-analytics/machine-security-analytics.value-object';
import { MachineUserDetails } from './machine-user-details';
import { MachineUserDetailsAccount } from './machine-user-details-account.value-object';
import { getMachineRequestIds, Machine } from './machine.entity';
import { DataSensitivity } from '../file/data-sensitivity.value-object';
import { MachineIncidentCount } from './machine-incidents-count.value-object';
import { Incident } from '../incident/incident.entity';
import { CyberEventMark } from '../event/cyber-event-mark.value-object';
import { MachineExclusionDetails } from './machine-exclusion/machine-exclusion-details.value-object';
import { MachinesMigrationDataQuery } from './machines-migration-dataQuery';
import { omit } from 'lodash-es';
import { SeenByMachines } from './machine-seen-by.value-object';
import { MachineSecurityHealthStatus } from './machine-security-health-status.value-object';
import { DefaultApiVersion } from '../tvm/commonEndPointUtils';

export interface MachineBackendOptions {
	/**
	 * whether Mtp unified device page is enabled
	 * @todo delete when MTPUnifiedDevicePage reaches GA
	 **/
	isUnifiedDevicePage?: boolean;
	isVnext?: boolean;
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineUserDetails,
	getRelationshipData: (machine: Machine) => getMachineRequestIds(machine),
	endpoint: (_, query: DataQuery) => query.where['isUnifiedDevicePage'] ? 'TopUsersByIds' : `${query.where['machineId']}/TopUsers`,
	allItemsEndpointTrailingSlash: false,
	fixedData: { lookingBackIndays: 30 },
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		max: 10,
		time: 1000 * 60,
	},
	baseUrl: (config: WcdPortalParisConfig, query) => (query && query.where['isUnifiedDevicePage']) ? config.data.serviceUrls.getTopUsersByIds : config.data.serviceUrls.getTopUsers,
})
export class MachineUserDetailsRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineUserDetails> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineUserDetailsAccount,
	getRelationshipData: (machine: Machine) => getMachineRequestIds(machine),
	endpoint: (_, query) => query.where['isUnifiedDevicePage'] ? 'MachineLogonUsersByIds' : 'MachineLogonUsers',
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToMany],
	fixedData: {
		logonTypes: 'Interactive,RemoteInteractive,Network,Batch,Service',
		lookingBackIndays: 30,
	},
	baseUrl: (config: WcdPortalParisConfig, query) => (query && query.where['isUnifiedDevicePage']) ? config.data.serviceUrls.machineLogonUsersByIds : config.data.serviceUrls.machineLogonUsers,
})
export class MachineLoggedOnUsers
	implements EntityRelationshipRepositoryType<Machine, MachineUserDetailsAccount> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: CyberEventMark,
	getRelationshipData: (machine: Machine) => getMachineRequestIds(machine),
	endpoint: (_, query) => {
		return `machines/${query.where['machineId']}/eventMarks`;
	},
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.getMachineMarkedEvents,
	allItemsProperty: 'Items',
})
export class MachineCyberEventMarksRelationship
	implements EntityRelationshipRepositoryType<Machine, CyberEventMark> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: Alert,
	endpoint: (config: WcdPortalParisConfig, query: DataQuery) => 
		query && query.where && query.where['useDetectionAssociatedAlertsVnextApi'] ? 'Alerts' : `AssociatedAlerts`,
	allowedTypes: [RelationshipType.OneToMany],
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'Items',
	getRelationshipData: (machine: Machine) => ({
		WcdMachineId: machine.id,
		ComputerDnsName: machine.name || '',
		MachineId: machine.senseMachineId || '',
	}),
	parseDataQuery: (dataQuery: DataQuery) => {
		const where = dataQuery && dataQuery.where ? dataQuery.where : {};

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;
		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		const lookBackInDays: { lookBackInDays: number } = { lookBackInDays: 180 };
		return Object.assign(
			{},
			where,
			pageSettings,
			lookBackInDays,
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
					sortByField: dataQuery.sortBy[0].field,
					sortOrder:
						dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
							? 'Ascending'
							: 'Descending',
				}
				: undefined,
		);
	},
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) => {
		return query && query.where && query.where['useDetectionAssociatedAlertsVnextApi']
			? `${config.data.serviceUrls.alertsApiService}/Evidences/device`
			: config.data.serviceUrls.threatIntel;
	},
})
export class MachineAlertsRelationship implements EntityRelationshipRepositoryType<Machine, Alert> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: Incident,
	foreignKey: 'MachineId',
	endpoint: 'incidents/associated',
	allowedTypes: [RelationshipType.OneToMany],
	allItemsEndpointTrailingSlash: false,
	parseDataQuery: (dataQuery: DataQuery) => {
		const where = dataQuery && dataQuery.where;

		const pageSettings: { pageIndex?: number; pageSize?: number } = {};
		if (dataQuery.page) pageSettings.pageIndex = dataQuery.page;
		if (dataQuery.pageSize) pageSettings.pageSize = dataQuery.pageSize;

		const lookBackInDays: { lookBackInDays: number } = { lookBackInDays: 180 };
		return Object.assign(
			{},
			where,
			pageSettings,
			lookBackInDays,
			dataQuery.sortBy && dataQuery.sortBy.length
				? {
					sortByField: dataQuery.sortBy[0].field,
					sortOrder:
						dataQuery.sortBy[0].direction === DataQuerySortDirection.ascending
							? 'Ascending'
							: 'Descending',
				}
				: undefined,
		);
	},
})
export class MachineIncidentsRelationship implements EntityRelationshipRepositoryType<Machine, Incident> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: TagsCollection,
	endpoint: (config: WcdPortalParisConfig, query: MachinesMigrationDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return 'machines/machineTags';
		}
		return 'MachineTags';
	},
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		max: 10,
		time: 1000 * 60,
	},
	getRelationshipData: (machine: Machine) => {
		return { internalMachineId: machine.internalMachineId };
	},
	baseUrl: (config: WcdPortalParisConfig, query: MachinesMigrationDataQuery) => {
		if (query && query.where && query.where.migrateToVNext) {
			return config.data.serviceUrls.k8s;
		}
		return config.data.serviceUrls.threatIntel;
	},
	parseDataQuery: (dataQuery: MachinesMigrationDataQuery) => {
		if (!dataQuery || !dataQuery.where) return {};

		return omit(dataQuery.where, 'migrateToVNext');
	},
})
export class MachineTagsCollectionRelationship
	implements EntityRelationshipRepositoryType<Machine, TagsCollection> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: AlertsSeveritySummary,
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		max: 10,
		time: 1000 * 60,
	},
	getRelationshipData: (machine: Machine) => {
		return {
			WcdMachineId: machine.machineId || machine.name,
			ComputerDnsName: machine.name || '',
			MachineId: machine.senseMachineId || '',
		};
	},
	endpoint: 'activeAlertsSummary',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.activeAlertsSummary,
})
export class MachineAlertsSeveritySummaryRelationship
	implements EntityRelationshipRepositoryType<Machine, AlertsSeveritySummary> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineIncidentCount,
	endpoint: 'incidentCount',
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToOne],
	cache: {
		max: 10,
		time: 1000 * 60,
	},
	getRelationshipData: (machine: Machine) => ({
		ComputerDnsName: machine.name || '',
		MachineId: machine.senseMachineId || '',
	}),
	baseUrl: '<mtp>',
})
export class MachineIncidentsCountRelationship
	implements EntityRelationshipRepositoryType<Machine, AlertsSeveritySummary> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineSecurityAnalytics,
	foreignKey: 'senseMachineId',
	allItemsEndpointTrailingSlash: false,
	endpoint: 'SecurityAnalyticsMachineEnricher/MachineLatestState',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.securityAnalytics,
	allowedTypes: [RelationshipType.OneToOne],
})
export class MachineSecurityAnalyticsRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineSecurityAnalytics> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineNetworkInfo,
	getRelationshipData: (machine: Machine) => {
		/**
		 * @todo remove once isUnifiedDevicePage reaches GA
		 * and use return this.backendService.getMachineRequestIdsAsStruct(machine);
		 */
		const params = {
			machineId: machine.senseMachineId || machine.name,
			idType: machine.senseMachineId ? MachineIdType.SenseMachineId : MachineIdType.Name,
			lastSeen: machine.lastSeen && machine.lastSeen.toISOString(),
		};

		if (machine.senseMachineId) {
			Object.assign(params, { senseMachineId: machine.senseMachineId });
		}

		if (machine.name) {
			Object.assign(params, { computerDnsName: machine.name });
		}

		return params;
	},
	endpoint: (_, query) => query.where['isUnifiedDevicePage'] ? 'LatestMachineIpsByIds' : 'LatestMachineIps',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig, query) => (query && query.where['isUnifiedDevicePage']) ? config.data.serviceUrls.getLatestMachineIpsByIds : config.data.serviceUrls.getLatestMachineIps,
})
export class MachineNetworkInfoRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineNetworkInfo> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: AatpProfile,
	getRelationshipData: (machine: Machine) => ({
		computerDnsName: machine.name,
	}),
	endpoint: 'ti/aatp/AtaMachineSummary',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class MachineAatpProfileRelationship
	implements EntityRelationshipRepositoryType<Machine, AatpProfile> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: DataSensitivity,
	getRelationshipData: (machine: Machine) => ({ id: machine.id }),
	endpoint: (_, query) => `machines/${query.where['id']}/dataSensitivity`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.getDataSensitivity,
})
export class MachineDataSensitivityRelationship
	implements EntityRelationshipRepositoryType<Machine, DataSensitivity> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineExclusionDetails,
	getRelationshipData: (machine: Machine) => ({ senseMachineId: machine.senseMachineId }),
	endpoint: (_, query) => `machines/${query.where['senseMachineId']}/exclusionDetails`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class MachineExclusionDetailsRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineExclusionDetails> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: SeenByMachines,
	getRelationshipData: (machine: Machine) => ({ senseMachineId: machine.senseMachineId }),
	endpoint: (_, query) => `machine/${query.where['senseMachineId']}/InterceptingMachines`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.detectionDeviceTimeline,
})
export class MachineInterceptingMachinesRelationship
	implements EntityRelationshipRepositoryType<Machine, SeenByMachines> {
}

@EntityRelationship({
	sourceEntity: Machine,
	dataEntity: MachineSecurityHealthStatus,
	getRelationshipData: (machine: Machine) => ({ senseMachineId: machine.senseMachineId }),
	endpoint: (_, query) => `analytics/devicehealth/?$filter=(machineId eq '${query.where['senseMachineId']}')`,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.tvm,
	customHeaders: DefaultApiVersion,
	allItemsProperty: 'results',
	allItemsEndpointTrailingSlash: false,
})
export class MachineSecurityHealthStatusRelationship
	implements EntityRelationshipRepositoryType<Machine, MachineSecurityHealthStatus> {
}
