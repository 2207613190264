import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@wcd/shared';
import { MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { MagellanFeaturesProvider } from '@wcd/domain';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { Paris } from '@microsoft/paris';
import { RoutesService } from '@wcd/shared';
import { AuthService, MtpPermission } from '@wcd/auth';
import { TzDateService } from '@wcd/localization';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';
import { AdvancedFeaturesService } from '../../../../admin/integration-settings/advanced-features.service';
import { MtpWorkload } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';

const DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY = 'proactive-message-dismissed';

@Component({
	selector: 'standard-discovery-message',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<fab-message-bar
				*ngIf="showProactiveDiscoveryAutoStartMessage"
				dismissable="true"
				(onDismiss)="hideProactiveDiscoveryAutoStartMessage()"
				[dismissButtonAriaLabel]="'dismiss' | i18n"
				[messageBarType]="MessageBarType.info"
				[renderActions]="actions"
			>
				<div>
					<span> {{ proactiveDiscoveryAutoStartMessage }}</span>
					<a href="https://aka.ms/AAbknru" target="_blank" rel="noopener noreferrer">{{
						'DevicesPageAutoProactiveMessageLearnMore' | i18n
					}}</a>
				</div>
			</fab-message-bar>
			<ng-template #actions>
				<span class="wcd-padding-mediumSmall-right">
					<fab-messagebar-button
						[text]="'EnableProactiveDeviceDiscoveryButtonText' | i18n"
						(onClick)="activateProactiveSettings()"
						[disabled]="!isAdmin"
					></fab-messagebar-button>
					<fab-messagebar-button
						[text]="'GoToProactiveDeviceDiscoverySettingsButtonText' | i18n"
						(onClick)="navigateToProactiveSettings()"
					></fab-messagebar-button>
				</span>
			</ng-template>
		</div>
	`,
})
export class StandardDiscoveryMessageComponent {
	readonly MessageBarType = MessageBarType;
	public showProactiveDiscoveryAutoStartMessage = false;
	public isAdmin = this.authService.currentUser.isMdeAdmin;
	proactiveDiscoveryAutoStartMessage: string;
	magellanFeaturesProvider: MagellanFeaturesProvider;
	magellanOptOut = this.appConfigService.magellanOptOut;

	constructor(
		private router: Router,
		private localStorageService: LocalStorageService,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService,
		private authService: AuthService,
		private appContextService: AppContextService,
		private tzDateService: TzDateService,
		private advancedFeaturesService: AdvancedFeaturesService,
		private appConfigService: AppConfigService,
		paris: Paris
	) {
		this.magellanFeaturesProvider = new MagellanFeaturesProvider(paris);
		const hasAnyViewPermission =
			this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
				MtpPermission.SecurityData_Read,
				MtpWorkload.Mdatp
			) ||
			this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
				MtpPermission.TvmData_Read,
				MtpWorkload.Mdatp
			);

		if (
			hasAnyViewPermission &&
			!appConfigService.magellanOptOut &&
			!this.localStorageService.getItem(DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY) &&
			this.featuresService.isEnabled(Feature.AutoProactiveDiscoveryMessage)
		) {
			this.displayAutoProactiveDeviceDiscoveryNotificationIfNotSet();
		}
	}

	displayAutoProactiveDeviceDiscoveryNotificationIfNotSet() {
		this.magellanFeaturesProvider
			.getFeaturesWithDates()
			.then(
				({
					isProactiveDiscoveryEmpty,
					proactiveDiscoveryEnablingDate,
					isProactiveDiscoveryEnablingDatePassed,
				}) => {
					this.showProactiveDiscoveryAutoStartMessage =
						isProactiveDiscoveryEmpty && !isProactiveDiscoveryEnablingDatePassed;
					this.proactiveDiscoveryAutoStartMessage = this.i18nService.get(
						'DevicesPageAutoProactiveMessage',
						{ date: this.tzDateService.format(proactiveDiscoveryEnablingDate, 'MMMM d, y') }
					);
					this.appInsightsService.trackEvent(
						'magellan_settings_display_auto_standard_discovery_message_in_device_inventory',
						{
							component: 'DevicesComponent',
							showProactiveDiscoveryAutoStartMessage: this
								.showProactiveDiscoveryAutoStartMessage,
						}
					);
				}
			);
	}

	navigateToProactiveSettings() {
		this.appInsightsService.track({
			type: TrackingEventType.Navigation,
			id: 'securitysettings_device_discovery_edit',
			component: 'DevicesComponent',
		});

		if (this.appContextService.isSCC) {
			this.router.navigate(['/securitysettings/device_discovery']);
		} else {
			window.open(`${RoutesService.getSCCRootUrl()}/securitysettings/device_discovery`, '_blank');
		}
	}

	activateProactiveSettings() {
		this.appInsightsService.track({
			type: TrackingEventType.Navigation,
			id: 'securitysettings_device_discovery_setStandardDiscovery_true',
			component: 'DevicesComponent',
		});

		if (this.appContextService.isSCC) {
			this.router.navigate(['/securitysettings/device_discovery'], {
				queryParams: { setStandardDiscovery: true },
			});
		} else {
			window.open(
				`${RoutesService.getSCCRootUrl()}/securitysettings/device_discovery?setStandardDiscovery=true`,
				'_blank'
			);
		}
	}

	hideProactiveDiscoveryAutoStartMessage() {
		this.localStorageService.setItem(DISMISS_PROACTIVE_NOTIFICATION_STORAGE_KEY, '1');
		this.showProactiveDiscoveryAutoStartMessage = false;
	}
}
