import { CyberEventActionType } from './cyber-event-action-type.entity';
import { CyberEventActionTypeName } from './cyber-event-action-type-name.enum';
import { CyberEventActionGroupTypeName } from './cyber-event-action-group-type-name.enum';
import { CyberEventActionSubGroupTypeName } from './cyber-event-action-sub-group-type-name.enum';
import { lowerFirst } from 'lodash-es';

export const cyberEventActionTypes: Array<CyberEventActionType> = [
	{
		id: CyberEventActionTypeName.Alert,
	},
	{
		id: CyberEventActionTypeName.AmsiScriptDetection,
		groupTypeName: CyberEventActionGroupTypeName.fileEventGroup,
	},
	{
		id: CyberEventActionTypeName.AntivirusDetection,
		groupTypeName: CyberEventActionGroupTypeName.antivirusEventGroup,
	},
	{
		id: CyberEventActionTypeName.AntivirusReport,
		groupTypeName: CyberEventActionGroupTypeName.antivirusEventGroup,
	},
	{
		id: CyberEventActionTypeName.AntivirusScanCancelled,
		groupTypeName: CyberEventActionGroupTypeName.antivirusEventGroup,
	},
	{
		id: CyberEventActionTypeName.AntivirusScanCompleted,
		groupTypeName: CyberEventActionGroupTypeName.antivirusEventGroup,
	},
	{
		id: CyberEventActionTypeName.AntivirusScanFailed,
		groupTypeName: CyberEventActionGroupTypeName.antivirusEventGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlAppInstallationBlocked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlCodeIntegrityDriverRevoked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlCodeIntegrityImageAudited,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlCodeIntegrityImageRevoked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlCodeIntegrityPolicyAudited,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlCodeIntegrityPolicyBlocked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlExecutableAudited,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlExecutableBlocked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlPackagedAppAudited,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlPackagedAppBlocked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlScriptAudited,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppControlScriptBlocked,
		groupTypeName: CyberEventActionGroupTypeName.appControlGroup,
	},
	{
		id: CyberEventActionTypeName.AppGuardBrowseToUrl,
	},
	{
		id: CyberEventActionTypeName.AppGuardCreateContainer,
		groupTypeName: CyberEventActionGroupTypeName.appGuardContainerGroup,
	},
	{
		id: CyberEventActionTypeName.AppGuardLaunchedWithUrl,
	},
	{
		id: CyberEventActionTypeName.AppGuardResumeContainer,
		groupTypeName: CyberEventActionGroupTypeName.appGuardContainerGroup,
	},
	{
		id: CyberEventActionTypeName.AppGuardStopContainer,
		groupTypeName: CyberEventActionGroupTypeName.appGuardContainerGroup,
	},
	{
		id: CyberEventActionTypeName.AppGuardSuspendContainer,
		groupTypeName: CyberEventActionGroupTypeName.appGuardContainerGroup,
	},
	{
		id: CyberEventActionTypeName.BrowserLaunchedToOpenUrl,
	},
	{
		id: CyberEventActionTypeName.ConnectionFailed,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.InboundConnectionRejected,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionFound,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.InboundConnectionFound,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionObserved,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionSuccess,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionRequest,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionAttempt,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.InboundConnectionAttempt,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.ConnectionAcknowledged,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.InboundConnectionAcknowledged,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.CreateRemoteThreadApiCall,
		groupTypeName: CyberEventActionGroupTypeName.processInjectionGroup,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardAcgAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.dynamicCode,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardAcgEnforced,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.dynamicCode,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardAsrAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardAsrGroup,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardAsrBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardAsrGroup,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardChildProcessAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.childProcess,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardChildProcessBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.childProcess,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardEafViolationAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.exportAddressFilter,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardEafViolationBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.exportAddressFilter,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardIafViolationAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.importAddressFilter,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardIafViolationBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.importAddressFilter,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardLowIntegrityImageAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadLowImage,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardLowIntegrityImageBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadLowImage,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardNetworkProtectionBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardNetworkProtectionGroup,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardNetworkProtectionAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardNetworkProtectionGroup,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardNonMicrosoftSignedAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadNonMicrosoft,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardNonMicrosoftSignedBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadNonMicrosoft,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardRopExploitAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.ROP,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardRopExploitBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.ROP,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardSharedBinaryAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadFromRemote,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardSharedBinaryBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.loadFromRemote,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardWin32SystemCallAudited,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.callWin32k,
	},
	{
		id: CyberEventActionTypeName.ExploitGuardWin32SystemCallBlocked,
		groupTypeName: CyberEventActionGroupTypeName.exploitGuardGroup,
		subGroupTypeName: CyberEventActionSubGroupTypeName.callWin32k,
	},
	{
		id: CyberEventActionTypeName.FirewallInboundConnectionBlocked,
		groupTypeName: CyberEventActionGroupTypeName.firewallConnectionBlockedGroup,
	},
	{
		id: CyberEventActionTypeName.FirewallInboundConnectionToAppBlocked,
	},
	{
		id: CyberEventActionTypeName.FirewallServiceStopped,
	},
	{
		id: CyberEventActionTypeName.FirewallOutboundConnectionBlocked,
		groupTypeName: CyberEventActionGroupTypeName.firewallConnectionBlockedGroup,
	},
	{
		id: CyberEventActionTypeName.FileCreated,
		groupTypeName: CyberEventActionGroupTypeName.fileEventGroup,
	},
	{
		id: CyberEventActionTypeName.FileModified,
		groupTypeName: CyberEventActionGroupTypeName.fileEventGroup,
	},
	{
		id: CyberEventActionTypeName.FileRenamed,
		groupTypeName: CyberEventActionGroupTypeName.fileEventGroup,
	},
	{
		id: CyberEventActionTypeName.HuntingRecord,
		groupTypeName: CyberEventActionGroupTypeName.huntingRecordGroup,
	},
	{
		id: CyberEventActionTypeName.ImageLoaded,
	},
	{
		id: CyberEventActionTypeName.InboundConnectionAccepted,
		groupTypeName: CyberEventActionGroupTypeName.connectionEventGroup,
	},
	{
		id: CyberEventActionTypeName.LogonFailed,
		groupTypeName: CyberEventActionGroupTypeName.logonEventGroup,
	},
	{
		id: CyberEventActionTypeName.LogonSuccess,
		groupTypeName: CyberEventActionGroupTypeName.logonEventGroup,
	},
	{
		id: CyberEventActionTypeName.NtAllocateVirtualMemoryApiCall,
		groupTypeName: CyberEventActionGroupTypeName.allocateMemoryGroup,
	},
	{
		id: CyberEventActionTypeName.NtAllocateVirtualMemoryRemoteApiCall,
		groupTypeName: CyberEventActionGroupTypeName.allocateMemoryGroup,
	},
	{
		id: CyberEventActionTypeName.NtMapViewOfSectionRemoteApiCall,
		groupTypeName: CyberEventActionGroupTypeName.processInjectionGroup,
	},
	{
		id: CyberEventActionTypeName.NtProtectVirtualMemoryApiCall,
		groupTypeName: CyberEventActionGroupTypeName.allocateMemoryGroup,
	},
	{
		id: CyberEventActionTypeName.OneCyber,
	},
	{
		id: CyberEventActionTypeName.OpenProcessApiCall,
	},
	{
		id: CyberEventActionTypeName.OtherAlertRelatedActivity,
	},
	{
		id: CyberEventActionTypeName.PasswordChangeAttempt,
		groupTypeName: CyberEventActionGroupTypeName.userToUserActivityGroup,
	},
	{
		id: CyberEventActionTypeName.PowerShellCommand,
	},
	{
		id: CyberEventActionTypeName.ProcessCreated,
		groupTypeName: CyberEventActionGroupTypeName.processCreatedGroup,
	},
	{
		id: CyberEventActionTypeName.ProcessCreatedUsingWmiQuery,
		groupTypeName: CyberEventActionGroupTypeName.processCreatedGroup,
	},
	{
		id: CyberEventActionTypeName.ProcessPrimaryTokenModified,
	},
	{
		id: CyberEventActionTypeName.QueueUserApcRemoteApiCall,
		groupTypeName: CyberEventActionGroupTypeName.processInjectionGroup,
	},
	{
		id: CyberEventActionTypeName.RegistryKeyCreated,
		groupTypeName: CyberEventActionGroupTypeName.registryEventGroup,
	},
	{
		id: CyberEventActionTypeName.RegistryKeyDeleted,
		groupTypeName: CyberEventActionGroupTypeName.registryEventGroup,
	},
	{
		id: CyberEventActionTypeName.RegistryKeyRenamed,
		groupTypeName: CyberEventActionGroupTypeName.registryEventGroup,
	},
	{
		id: CyberEventActionTypeName.RegistryValueDeleted,
		groupTypeName: CyberEventActionGroupTypeName.registryEventGroup,
	},
	{
		id: CyberEventActionTypeName.RegistryValueSet,
		groupTypeName: CyberEventActionGroupTypeName.registryEventGroup,
	},
	{
		id: CyberEventActionTypeName.ResponseAction,
	},
	{
		id: CyberEventActionTypeName.SetThreadContextRemoteApiCall,
		groupTypeName: CyberEventActionGroupTypeName.processInjectionGroup,
	},
	{
		id: CyberEventActionTypeName.ScheduledTaskCreated,
		groupTypeName: CyberEventActionGroupTypeName.scheduledTaskGroup,
	},
	{
		id: CyberEventActionTypeName.ScheduledTaskDeleted,
		groupTypeName: CyberEventActionGroupTypeName.scheduledTaskGroup,
	},
	{
		id: CyberEventActionTypeName.ScheduledTaskDisabled,
		groupTypeName: CyberEventActionGroupTypeName.scheduledTaskGroup,
	},
	{
		id: CyberEventActionTypeName.ScheduledTaskEnabled,
		groupTypeName: CyberEventActionGroupTypeName.scheduledTaskGroup,
	},
	{
		id: CyberEventActionTypeName.ScreenshotTaken,
	},
	{
		id: CyberEventActionTypeName.ShellLinkCreateFileEvent,
		groupTypeName: CyberEventActionGroupTypeName.fileEventGroup,
	},
	{
		id: CyberEventActionTypeName.SmartScreenAppWarning,
		groupTypeName: CyberEventActionGroupTypeName.smartScreenGroup,
	},
	{
		id: CyberEventActionTypeName.SmartScreenExploitWarning,
		groupTypeName: CyberEventActionGroupTypeName.smartScreenGroup,
	},
	{
		id: CyberEventActionTypeName.SmartScreenUrlWarning,
		groupTypeName: CyberEventActionGroupTypeName.smartScreenGroup,
	},
	{
		id: CyberEventActionTypeName.SmartScreenUserOverride,
		groupTypeName: CyberEventActionGroupTypeName.smartScreenGroup,
	},
	{
		id: CyberEventActionTypeName.UsbDriveDriveLetterChanged,
		groupTypeName: CyberEventActionGroupTypeName.usbEventGroup,
	},
	{
		id: CyberEventActionTypeName.UsbDriveMount,
		groupTypeName: CyberEventActionGroupTypeName.usbEventGroup,
	},
	{
		id: CyberEventActionTypeName.UsbDriveUnmount,
		groupTypeName: CyberEventActionGroupTypeName.usbEventGroup,
	},
	{
		id: CyberEventActionTypeName.UsbDriveMounted,
		groupTypeName: CyberEventActionGroupTypeName.usbEventGroup,
	},
	{
		id: CyberEventActionTypeName.UsbDriveUnmounted,
		groupTypeName: CyberEventActionGroupTypeName.usbEventGroup,
	},
	{
		id: CyberEventActionTypeName.UserAccountCreated,
		groupTypeName: CyberEventActionGroupTypeName.userToUserActivityGroup,
	},
].map(config => {
	return {
		...config,
		typeName: lowerFirst(config.id),
	};
});
