import { SevilleModule } from '../seville.module';
import { SevilleUtils } from '../../common/services/seville.utils';
import { isEmpty, findIndex, cloneDeep } from 'lodash-es';

declare let angular: angular.IAngularStatic;

SevilleModule.controller('seville.sidepane', sidePaneController);

sidePaneController.$inject = ['$rootScope', '$scope', '$state', '$log', '$http', 'urlMapping', 'ng2router'];

function sidePaneController($rootScope, $scope, $state, $log, $http, urlMapping, ng2router) {
	var vm = this;
	angular.extend(vm, {
		$onInit: activate,
		close: close,
		expectValueFilter: expectValueFilter,
		resolveLink: resolveLink,
		toggleSection: toggleSection,
		handleButtonClick: handleButtonClick,
	});

	function activate() {
		vm.opened = false;

		$scope.$watch(
			function() {
				return vm.opened;
			},
			function(newValue, oldValue) {
				if (oldValue != newValue) fireResize();
			}
		);

		$scope.$on('sidePane:paneUpdated', function(event, payload) {
			vm.instance = payload;
			if (vm.instance && vm.instance.opened) {
				vm.opened = vm.instance.opened;
			} else {
				vm.opened = false;
				delete vm.instance;
			}

			$rootScope.$broadcast('sidePane:paneStateUpdated', { opened: vm.opened });
		});
	}

	function close() {
		if (!vm.instance) return;
		vm.instance.close();
	}

	function expectValueFilter(item) {
		return item.value ? true : false;
	}

	function resolveLink(link) {
		if (link.href) return link.href;
		return $state.href(link.state, link.params);
	}

	function toggleSection(section) {
		section.closed = !section.closed;
	}

	function handleButtonClick(prop) {
		if (!prop || !prop.value || !prop.value.method) {
			return;
		}

		prop.value.method(prop.value.params);
	}

	function fireResize() {
		setTimeout(
			function() {
				if (document.createEvent) {
					// W3C
					var ev = document.createEvent('Event');
					ev.initEvent('resize', true, true);
					window.dispatchEvent(ev);
				} else {
					// IE
					var element: any = document.documentElement;
					var event = (<any>document).createEventObject();
					element.fireEvent('onresize', event);
				}
			},
			SevilleUtils.detectIE() === false ? 250 : 600
		);
	}
}
