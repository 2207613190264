import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { Machine, MachineSecurityHealthStatus, MachineSecurityHealthStatusRelationship } from '@wcd/domain';
import { take, map } from 'rxjs/operators';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';

@Injectable()
export class MachineSecurityHealthStatusDataService {

	constructor(private readonly paris: Paris) {}

	fetchSecurityHealthStatusData(machine: Machine): Observable<MachineSecurityHealthStatus> {
		return this.paris
			.getRelationshipRepository(MachineSecurityHealthStatusRelationship)
			.queryForItem(machine)
			.pipe(
				take(1),
				catchHttpError(404, { items: [] }),
				map(result => result.items[0])
			);
	}
}
