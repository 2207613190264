import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { compact, escapeRegExp } from 'lodash-es';
import { combineLatest, defer, EMPTY, from, Observable, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import {
	DefenderRunningMode,
	InvestigateMachineApiCall,
	IsolateResponse,
	TroubleshootResponse,
	LiveResponseSession,
	Machine,
	MachineAnyRequestsActive,
	MachineAnyRequestsActiveRelationship,
	MachineExclusionState,
	MachineMachineRequestLatestRelationship,
	MachineMachineRequestsStateRelationship,
	MachineRequest,
	MachineRequestsState,
	MachineRequestState,
	MachineResponseType,
	MachineTagsCollectionRelationship,
	OperatingSystemPlatformCategories,
	RestrictAppExecutionResponse,
	SoftwareInstallationBase,
	Tag,
	TagType,
	TroubleshootModeRequest,
} from '@wcd/domain';
import { Feature, FeaturesService, PollingService, FlavorService } from '@wcd/config';
import {
	ItemActionModel,
	ItemActionModelConfig,
	ItemActionType,
} from '../../../dataviews/models/item-action.model';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { MachinesActionsService } from './machines-actions.service';
import {
	MACHINE_RESPONSE_FEATURE_MAP,
	MachineRequestsPermissionsService,
} from './machines-requests.permissions.service';
import { MachinesService } from './machines.service';
import { LiveResponseService } from '../../live_response/services/live-response.service';
import { Router } from '@angular/router';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
import { MachineEntityTypeOptions, MachineEntityTypeService } from './machine.entity-type.service';
import { EntityCommandBarDisplayMode } from '../../../global_entities/models/entity-command-bar-display-mode.enum';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { LiveResponsePermissionsService } from '../../live_response/services/live-response-permissions.service';
import { sccHostService } from '@wcd/scc-interface';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { ReactPanelsService } from '../../../shared/services/react-panels.service';
import { DEVICE_ENTITY_TYPE_GO_HUNT_ID } from '../../../hunting-go-hunt/services/go-hunt-query-builder';
import { AppConfigService } from '@wcd/app-config';
import { openDeviceReportInaccuracyPanel } from '@wcd/shared';

interface ItemActionModelConfigSettings<TActionData> {
	readonly nameKey: string;
	readonly tooltipKey: string;
	readonly disabled?: boolean;
	readonly action?: (machine: Machine) => Promise<ConfirmEvent<TActionData>>;
	readonly trackId?: string;
	readonly refreshOnResolve?: boolean;
}

export const enum MachineAction { //TODO: use this enum instead of hard coding the actions multiple times
	MachineValue = 'machineValue',
}

const machineTrackingComponent = 'Machine Actions';
const pollingIntervalMilliseconds = 2 * 60 * 1000; // 2 minutes
const actionOrderWeights = {
	machineTags: 10,
	evidenceGoHunt: 15,
	isolateMachine: 20,
	restrictAppExecution: 30,
	runAntivirusScan: 40,
	collectInvestigationPackage: 50,
	liveResponse: 60,
	investigateMachine: 70,
	consultThreatExpert: 80,
	[MachineAction.MachineValue]: 90,
	troubleshootingMode: 100,
	logsCollection: 110,
	actionCenter: 120,
	reportInaccuracy: 130,
};

@Injectable()
export class MachineEntityTypeActionsService {
	machine: Machine;
	hasMdeLicenseWorkloads: boolean;

	constructor(
		private readonly paris: Paris,
		private readonly appConfigService: AppConfigService,
		private readonly featuresService: FeaturesService,
		private readonly flavorService: FlavorService,
		private readonly machinesService: MachinesService,
		private readonly machinesPermissionsService: MachineRequestsPermissionsService,
		private readonly machinesActionsService: MachinesActionsService,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private readonly errorsDialogService: ErrorsDialogService,
		private readonly panelsService: PanelService,
		private readonly liveResponseService: LiveResponseService,
		private readonly router: Router,
		private readonly askThreatExpertService: AskThreatExpertService,
		private readonly pollingService: PollingService,
		private readonly goHuntService: GoHuntService,
		private readonly rbacControlService: RbacControlService,
		private readonly advancedFeaturesService: AdvancedFeaturesService,
		private readonly reactPanelsService: ReactPanelsService,
		private readonly liveResponsePermissionsService: LiveResponsePermissionsService
	) {
		this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
	}

	getEntityActions(
		machines: ReadonlyArray<Machine>,
		machineEntityTypeService: MachineEntityTypeService,
		options?: MachineEntityTypeOptions,
		entityCommandBarDisplayMode: EntityCommandBarDisplayMode = EntityCommandBarDisplayMode.Default
	): Observable<ReadonlyArray<ItemActionModel>> {
		const [machine] = machines;

		// used for refetch machine isolation status
		this.machine = machine;

		if (machines.some(machine => !machine.senseMachineId)) {
			// return only actions that are relevant without sense machine id
			const machineActionConfigs: Array<Observable<ItemActionModelConfig>> = [];

			if (machines.length == 1 && this.flavorService.isEnabled(AppFlavorConfig.devices.goHunt)) {
				machineActionConfigs.push(this._createGoHuntFromIncidentAction(machine, options));
			}

			return combineLatest(machineActionConfigs).pipe(
				map(actionConfigs => compact(actionConfigs)),
				map(actionConfigs => actionConfigs.map(actionConfig => new ItemActionModel(actionConfig)))
			);
		}

		const p1LicenseInMixedLicenseMode = this.appConfigService.mixedLicenseMode && machines.some(machine => machine.hasP1tag);
		const commonActionsConfigs$ = combineLatest([
			entityCommandBarDisplayMode != EntityCommandBarDisplayMode.Overflow
				? this._createTagsActionConfig(machines)
				: EMPTY,
			...(this.hasMdeLicenseWorkloads && this.flavorService.isEnabled(AppFlavorConfig.devices.autoIr)
				? [this._createInvestigateActionConfig(machines)]
				: []),
			...(this.hasMdeLicenseWorkloads &&
			this.flavorService.isEnabled(AppFlavorConfig.devices.liveResponseSession) && !p1LicenseInMixedLicenseMode
				? [this._createLiveResponseActionConfig(machines)]
				: []),
			...(this.flavorService.isEnabled(AppFlavorConfig.devices.machineValue) && !p1LicenseInMixedLicenseMode
				? [this._createMachineValueActionConfig(machineEntityTypeService)]
				: []),
			...(this.featuresService.isEnabled(Feature.MagellanReportInaccuracy)
				? [this._createReportInaccuracyActionConfig()]
				: []),
			...[this._createMachineExclusionStateActionConfig$(machines)],
		]).pipe(map(actionConfigs => compact(actionConfigs)));

		// multi select case
		if (!this.featuresService.isEnabled(Feature.UpgradeMachinePage) || machines.length > 1) {
			const multipleMachineActionsConfigs =
				this.featuresService.isEnabled(Feature.BulkCloudActions) && sccHostService.isSCC
					? [
							this._createMultipleIsolateMachineActionConfig(),
							this._createMultipleRestrictAppExecutionActionConfig(),
							this._createMultipleReverseIsolateActionConfig(),
							this._createMultipleRemoveAppRestrictionsActionConfig(),
					  ]
					: [];

			return commonActionsConfigs$.pipe(
				map(actionsConfigs =>
					actionsConfigs.concat(multipleMachineActionsConfigs).map(actionConfig => {
						return new ItemActionModel(actionConfig);
					})
				)
			);
		}

		// single select case
		return combineLatest([
			commonActionsConfigs$.pipe(
				catchError(err => of([])),
				startWith([])
			),
			this.getMachineCloudActions(machine).pipe(
				catchError(err => of([])),
				startWith([])
			),
			this._createConsultThreatExpertConfig(machine).pipe(
				catchError(err => of([])),
				startWith([])
			),
			this._createActionCenterActionConfig(machine).pipe(
				catchError(err => of(null)),
				startWith(null)
			),
			this._createGoHuntFromIncidentAction(machine, options).pipe(
				catchError(err => of(null)),
				startWith(null)
			),
		]).pipe(
			map(
				([
					commonActionsConfigs,
					machineCloudActions,
					consultThreatExpertConfig,
					actionCenterAction,
					goHuntAction,
				]) => {
					const machineCloudAction = this.hasMdeLicenseWorkloads ? machineCloudActions : [];
					const consultThreatExpertConfigAction = this.hasMdeLicenseWorkloads && !p1LicenseInMixedLicenseMode
						? consultThreatExpertConfig
						: [];
					const actionCenterActions = this.hasMdeLicenseWorkloads ? actionCenterAction : [];
					const huntAction = this.hasMdeLicenseWorkloads ? goHuntAction : [];

					const allActions = compact([
						...commonActionsConfigs,
						...machineCloudAction,
						...consultThreatExpertConfigAction,
						actionCenterActions,
						...(this.flavorService.isEnabled(AppFlavorConfig.devices.goHunt) ? huntAction : []),
					]);

					const getWeight = id => actionOrderWeights[id] || 999;
					return allActions.sort((a, b) => getWeight(a.id) - getWeight(b.id));
				}
			),
			map(actionsConfigs => actionsConfigs.map(actionConfig => new ItemActionModel(actionConfig)))
		);
	}

	private getMachineCloudActions(machine: Machine): Observable<Array<ItemActionModelConfig>> {
		const isResponseFeatureSupported$ = this.machinesPermissionsService.isResponseFeatureEnabled(machine);

		const featureSupportedStatus$ = this.machinesPermissionsService.getFeaturesStatus(machine);

		const machineActiveActionsCreateFuncs: ReadonlyArray<{
			type: MachineResponseType;
			createAction: (machine: Machine) => Observable<ItemActionModelConfig<void>>;
		}> = [
			{
				type: 'ForensicsResponse',
				createAction: machine => this._createCollectInvestigationPackageActionConfig(machine),
			},
			{
				type: 'ScanResponse',
				createAction: machine => this._createRunAntivirusActionConfig(machine),
			},
			{
				type: 'RestrictExecutionResponse',
				createAction: machine => this._createRestrictAppExecutionActionConfig(machine),
			},
			{
				type: 'IsolationResponse',
				createAction: machine => this._createIsolateMachineActionConfig(machine),
			},
			{
				type: 'LogsCollectionResponse',
				createAction: machine => this._createCollectSupportLogsActionConfig(machine),
			},
			{
				type: 'TroubleshootResponse',
				createAction: machine => this._createTroubleshootingModeConfig(machine),
			},
		];

		// Also listening to active panels to re-trigger creation of actions when Action Center opens or closes
		return this.pollingService.poll(0, pollingIntervalMilliseconds).pipe(
			switchMap(() =>
				combineLatest([
					isResponseFeatureSupported$,
					featureSupportedStatus$,
					this.panelsService.activePanels$,
				])
			),
			switchMap(([enabled, featureSupportedStatus]) => {
				const actions = machineActiveActionsCreateFuncs
					.map(({ type, createAction }) => {
						const isSupportedInCloud =
							(this.featuresService.isEnabled(Feature.MagellanShieldsUp) &&
								type === 'IsolationResponse' &&
								machine.isMdatp &&
								!machine.isManagedByMdatp) ||
							(enabled && !!featureSupportedStatus[type]);
						if (isSupportedInCloud) {
							return createAction(machine);
						}
						const feature = MACHINE_RESPONSE_FEATURE_MAP[type];
						if (!this.featuresService.isEnabled(feature)) {
							return null;
						}
						return defer(async () => {
							const isSupportedInLiveResponse = await this.liveResponsePermissionsService.isCloudActionSupported(
								machine,
								type
							);
							const action = await createAction(machine)
								.pipe(take(1))
								.toPromise();
							return Object.assign({}, action, {
								disabled: true,
								tooltip: isSupportedInLiveResponse
									? this.i18nService.strings
											.machines_entityDetails_actions_cloudActions_supportedInLiveResponse
									: this.i18nService.strings
											.machines_entityDetails_actions_cloudActions_unsupportedDevice,
							});
						});
					})
					.filter(Boolean)
					.map(action$ => action$.pipe(startWith(null)));
				return combineLatest(actions);
			})
		);
	}

	private _createConsultThreatExpertConfig(machine: Machine): Observable<ItemActionModelConfig[]> {
		return of<Array<ItemActionModelConfig>>(
			this.askThreatExpertService.shouldShowThreatExpertPanel()
				? [this.askThreatExpertService.getAskTheExpertCommandConfig(machineTrackingComponent)]
				: []
		);
	}

	private _createGoHuntFromIncidentAction(
		machine: Machine,
		options?: MachineEntityTypeOptions
	): Observable<ItemActionModelConfig> {
		return this.goHuntService.getGoHuntActionObservable(machine, DEVICE_ENTITY_TYPE_GO_HUNT_ID, {
			incident: options && options.incident,
		});
	}

	private _createTagsActionConfig(machines: ReadonlyArray<Machine>): Observable<ItemActionModelConfig> {
		return of<ItemActionModelConfig>({
			id: 'machineTags',
			nameKey: 'tags.manage',
			type: ItemActionType.Tags,
			icon: FabricIconNames.Tag,
			rbac: [RbacMdeAllowedActions.alertsInvestigation],
			rbacState: RbacControlState.disabled,
			refreshOnResolve: true,
			tracking: {
				id: 'machineTagsAction',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			options: {
				tags: {
					setTags: tags =>
						this.machinesService.updateMachinesTags(machines, tags).pipe(
							tap(() => {
								this.machinesService.clearCachedMachines();
								this.paris
									.getRelationshipRepository(MachineTagsCollectionRelationship)
									.clearCache();
								this.machinesService.clearAllUserDefinedMachineTags();
							})
						),
					createNewTag: tag =>
						of({
							id: tag.id,
							name: tag.name,
							type: TagType.user,
						}),
					searchFunction: (term: string) => {
						const termRegExp = new RegExp(escapeRegExp(term), 'i');

						return this.machinesService.getAllUserDefinedMachineTags().pipe(
							map(tags =>
								tags
									.filter(tag => termRegExp.test(tag.name))
									.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
									.map((tag: Tag) => ({
										item: tag,
										label: tag.name,
										value: tag.id,
										type: tag.type,
									}))
									.slice(0, 10)
							)
						);
					},
					searchSettings: { showSuggestions: true },
				},
			},
		});
	}

	private _createCollectInvestigationPackageActionConfig(
		machine: Machine
	): Observable<ItemActionModelConfig<void>> {
		return this.paris
			.getRelatedItem<Machine, MachineRequestsState>(MachineMachineRequestsStateRelationship, machine)
			.pipe(
				map(requestsState => requestsState.forensics || 'RequestCompleted'),
				map<MachineRequestState, ItemActionModelConfig>(forensicsRequestState => ({
					id: 'collectInvestigationPackage',
					nameKey: 'machines.entityDetails.actions.collectInvestigationPackage.title',
					tooltip: this.i18nService.get(
						`${
							forensicsRequestState === 'RequestPending'
								? 'machines.entityDetails.actions.$general.tooltip.requestPending'
								: 'machines.entityDetails.actions.collectInvestigationPackage.tooltip'
						}`
					),
					icon: FabricIconNames.FolderList,
					disabled: forensicsRequestState === 'RequestPending',
					rbac: [RbacMdeAllowedActions.alertsInvestigation],
					rbacState: RbacControlState.disabled,
					tracking: {
						id: 'machineCollectInvestigationPackageAction',
						type: TrackingEventType.Action,
						component: machineTrackingComponent,
					},
					refreshOnResolve: false,
					method: async ([machine]: [Machine]) => {
						const response = await this.machinesActionsService.collectInvestigationPackage(
							machine
						);

						if (response.confirmed) {
							await this.machinesActionsService.openActionCenter(machine, response.data.requestGuid);
						}
					},
				}))
			);
	}

	private _createCollectSupportLogsActionConfig(machine: Machine): Observable<ItemActionModelConfig<void>> {
		if (!this.featuresService.isEnabled(Feature.LogsCollection)) {
			return of(null);
		}

		return this.paris
			.getRelatedItem<Machine, MachineRequestsState>(MachineMachineRequestsStateRelationship, machine)
			.pipe(
				map(requestsState => {
					return requestsState.collectLogs || 'RequestCompleted';
				}),
				map<MachineRequestState, ItemActionModelConfig>(collectLogsRequestState => ({
					id: 'logsCollection',
					nameKey: 'machines.entityDetails.actions.logsCollection.title',
					tooltip: this.i18nService.get(
						`${
							collectLogsRequestState === 'RequestPending'
								? 'machines.entityDetails.actions.$general.tooltip.requestPending'
								: 'machines.entityDetails.actions.logsCollection.tooltip'
						}`
					),
					icon: FabricIconNames.ProductList,
					disabled: collectLogsRequestState === 'RequestPending',
					rbac: [RbacMdeAllowedActions.alertsInvestigation],
					rbacState: RbacControlState.disabled,
					tracking: {
						id: 'machineCollectSupportLogsAction',
						type: TrackingEventType.Action,
						component: machineTrackingComponent,
					},
					refreshOnResolve: false,
					method: async ([machine]: [Machine]) => {
						const response = await this.machinesActionsService.collectSupportLogs(machine);

						if (response.confirmed) {
							await this.machinesActionsService.openActionCenter(machine, response.data.requestGuid);
						}
					},
				}))
			);
	}

	private _createRunAntivirusActionConfig(machine: Machine): Observable<ItemActionModelConfig<void>> {
		type PartialConfig = Partial<ItemActionModelConfigSettings<RestrictAppExecutionResponse>> &
			Pick<ItemActionModelConfigSettings<RestrictAppExecutionResponse>, 'tooltipKey' | 'disabled'>;

		type PartialMachineState = Exclude<
			MachineRequestState,
			'ReverseRequestPending' | 'ReverseRequestCompleted'
		>;

		const requestStateConfigMap: Record<PartialMachineState | 'NotSupported', PartialConfig> = {
			RequestCompleted: {
				tooltipKey: 'machines.entityDetails.actions.runAntivirusScan.tooltip.scan',
			},
			RequestPending: {
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
				disabled: true,
			},
			NotSupported: {
				tooltipKey: 'machines.entityDetails.actions.runAntivirusScan.tooltip.notSupported',
				disabled: true,
			},
			Unknown: {
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error',
				disabled: true,
			},
		};

		return this.paris
			.getRelatedItem<Machine, MachineRequestsState>(MachineMachineRequestsStateRelationship, machine)
			.pipe(
				map(requestsState => {
					if (
						machine.os.platform.category !== OperatingSystemPlatformCategories.Linux &&
						machine.os.platform.category !== OperatingSystemPlatformCategories.macOS &&
						machine.os.platform.category !== OperatingSystemPlatformCategories.Android &&
						machine.os.platform.category !== OperatingSystemPlatformCategories.iOS &&
						(!machine.defenderRunningMode ||
							machine.defenderRunningMode === DefenderRunningMode.NotRunning ||
							machine.defenderRunningMode === DefenderRunningMode.FailedGettingStatus)
					) {
						return 'NotSupported';
					}

					return requestsState.scan || 'RequestCompleted';
				}),
				map<MachineRequestState | 'NotSupported', ItemActionModelConfig>(scanRequestState => {
					const { tooltipKey, disabled } = requestStateConfigMap[scanRequestState];

					return {
						id: 'runAntivirusScan',
						disabled: disabled,
						icon: FabricIconNames.Shield,
						nameKey: 'machines.entityDetails.actions.runAntivirusScan.title',
						tooltip: this.i18nService.get(tooltipKey),
						rbac: [RbacMdeAllowedActions.alertsInvestigation],
						rbacState: RbacControlState.disabled,
						refreshOnResolve: false,
						tracking: {
							id: 'machineAntivirusScanAction',
							type: TrackingEventType.Action,
							component: machineTrackingComponent,
						},
						method: async ([machine]: [Machine]) => {
							const response = await this.machinesActionsService.runAntivirusScan(machine);

							if (response.confirmed) {
								await this.machinesActionsService.openActionCenter(machine, response.data.requestGuid);
							}
						},
					};
				})
			);
	}

	private _createRestrictAppExecutionActionConfig(
		machine: Machine
	): Observable<ItemActionModelConfig<void>> {
		type PartialConfig = Partial<ItemActionModelConfigSettings<RestrictAppExecutionResponse>> &
			Pick<ItemActionModelConfigSettings<RestrictAppExecutionResponse>, 'nameKey' | 'refreshOnResolve'>;
		const removeRestrictionPartialConfig: PartialConfig = {
			refreshOnResolve: false,
			nameKey: 'machines.entityDetails.actions.restrictAppExecution.title.removeRestriction',
			trackId: 'machineRemoveAppRestrictionsAction',
		};

		const restrictPartialConfig: PartialConfig = {
			nameKey: 'machines.entityDetails.actions.restrictAppExecution.title.restrict',
			trackId: 'machineAppRestrictionsAction',
			refreshOnResolve: false,
		};

		const requestStateConfigMap: Record<
			MachineRequestState | 'NotSupported',
			ItemActionModelConfigSettings<RestrictAppExecutionResponse>
		> = {
			RequestCompleted: {
				...removeRestrictionPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.removeRestriction',
				action: machine => this.machinesActionsService.removeAppRestrictions(machine),
			},
			ReverseRequestPending: {
				...removeRestrictionPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
				disabled: true,
			},
			RequestPending: {
				...removeRestrictionPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
				disabled: true,
			},
			NotSupported: {
				...restrictPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.notSupported',
				disabled: true,
			},
			ReverseRequestCompleted: {
				...restrictPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.restrictAppExecution.tooltip.restrict',
				action: machine => this.machinesActionsService.restrictAppExecution(machine),
			},
			Unknown: {
				...restrictPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error',
				disabled: true,
			},
		};

		return this.paris
			.getRelatedItem<Machine, MachineRequestsState>(MachineMachineRequestsStateRelationship, machine)
			.pipe(
				map(requestsState => {
					if (
						(!requestsState.restrictExecution ||
							requestsState.restrictExecution === 'ReverseRequestCompleted') &&
						machine.defenderRunningMode !== DefenderRunningMode.Normal
					) {
						return 'NotSupported';
					}

					return requestsState.restrictExecution || 'ReverseRequestCompleted';
				}),
				map<MachineRequestState | 'NotSupported', ItemActionModelConfig>(requestState => {
					const { nameKey, tooltipKey, action, disabled, trackId } = requestStateConfigMap[
						requestState
					];
					return {
						id: 'restrictAppExecution',
						icon: 'Admin',
						rbac: [RbacMdeAllowedActions.remediationActions],
						rbacState: RbacControlState.disabled,
						nameKey,
						tooltip: this.i18nService.get(tooltipKey),
						disabled: disabled,
						refreshOnResolve: false,
						tracking: {
							id: trackId,
							type: TrackingEventType.Action,
							component: machineTrackingComponent,
						},
						method: async ([machine]: [Machine]) => {
							const response = await action(machine);
							if (response.confirmed) {
								await this.machinesActionsService.openActionCenter(machine, response.data.requestGuid);
							}
						},
					};
				})
			);
	}

	private _createMultipleRestrictAppExecutionActionConfig(): ItemActionModelConfig<Machine> {
		// 'Restrict app execution' action
		return {
			id: 'multipleRestrictAppExecution',
			icon: FabricIconNames.Admin,
			rbac: [RbacMdeAllowedActions.remediationActions],
			rbacState: RbacControlState.disabled,
			name: this.i18nService.strings.machines_entityDetails_actions_restrictAppExecution_title_restrict,
			tooltip: this.i18nService.strings
				.machines_entityDetails_actions_restrictAppExecution_tooltip_restrict,
			refreshOnResolve: false,
			tracking: {
				id: 'multipleMachineAppRestrictionsAction',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (selectedMachines: Array<Machine>) => {
				this.machinesActionsService.multipleRestrictAppExecution(selectedMachines);
				return Promise.resolve();
			},
		};
	}

	private _createMultipleRemoveAppRestrictionsActionConfig(): ItemActionModelConfig<Machine> {
		// 'Remove app restriction' action
		return {
			id: 'multipleRemoveRestrictAppExecution',
			icon: FabricIconNames.Admin,
			rbac: [RbacMdeAllowedActions.remediationActions],
			rbacState: RbacControlState.disabled,
			name: this.i18nService.strings
				.machines_entityDetails_actions_restrictAppExecution_title_removeRestriction,
			tooltip: this.i18nService.strings
				.machines_entityDetails_actions_restrictAppExecution_tooltip_removeRestriction,
			refreshOnResolve: false,
			tracking: {
				id: 'multipleMachineAppRemoveRestrictionsAction',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (selectedMachines: Array<Machine>) => {
				this.machinesActionsService.multipleRemoveAppRestrictions(selectedMachines);
				return Promise.resolve();
			},
		};
	}

	getMachineIsolationState() {
		return this.paris
			.getRelatedItem<Machine, MachineRequestsState>(
				MachineMachineRequestsStateRelationship,
				this.machine
			)
			.toPromise();
	}

	machineIsolationAction$ = new Subject<MachineRequestsState>();

	async requestMachineIsolationState() {
		try {
			const state = await this.getMachineIsolationState();
			this.machineIsolationAction$.next(state);
			return state;
		} catch (error) {
			this.machineIsolationAction$.thrownError(error);
			throw error;
		}
	}

	private _createIsolateMachineActionConfig(machine: Machine): Observable<ItemActionModelConfig<void>> {
		type PartialConfig = Partial<ItemActionModelConfigSettings<IsolateResponse>> &
			Pick<ItemActionModelConfigSettings<IsolateResponse>, 'nameKey'>;

		const removeIsolationPartialConfig: PartialConfig = {
			nameKey: machine.isManagedByMdatp
				? 'machines_entityDetails_actions_isolateMachine_title_removeIsolation'
				: 'machines_entityDetails_actions_isolateDevice_title_removeIsolation',
			trackId: 'machineReleaseFromIsolationAction',
			refreshOnResolve: false,
		};

		const isolatePartialConfig: PartialConfig = {
			nameKey: machine.isManagedByMdatp
				? 'machines_entityDetails_actions_isolateMachine_title_isolate'
				: 'machines_entityDetails_actions_isolateDevice_title_isolate',
			trackId: 'machineIsolateAction',
			refreshOnResolve: false,
		};

		const requestStateConfigMap: Record<
			MachineRequestState,
			ItemActionModelConfigSettings<IsolateResponse>
		> = {
			RequestCompleted: {
				...removeIsolationPartialConfig,
				tooltipKey: this.machine.isManagedByMdatp
					? 'machines.entityDetails.actions.isolateMachine.tooltip.removeIsolation'
					: 'machines.entityDetails.actions.isolateNotManagedDevice.tooltip.removeIsolation',
				action: machine =>
					this.machinesActionsService.releaseFromIsolation(machine, () =>
						this.requestMachineIsolationState()
					),
			},
			ReverseRequestPending: {
				...removeIsolationPartialConfig,
				nameKey: 'machines.entityDetails.actions.isolateMachine.title.removeIsolation',
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
				disabled: true,
			},
			RequestPending: {
				...isolatePartialConfig,
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.requestPending',
				disabled: true,
			},
			ReverseRequestCompleted: {
				...isolatePartialConfig,
				tooltipKey: this.machine.isManagedByMdatp
					? 'machines.entityDetails.actions.isolateMachine.tooltip.isolate'
					: 'machines.entityDetails.actions.isolateNotManagedDevice.tooltip.isolate',
				action: machine =>
					this.machinesActionsService.isolate(
						machine,
						this.machinesPermissionsService.isSelectiveIsolationSupported(machine),
						() => this.requestMachineIsolationState()
					),
				disabled:
					machine.isMdatp &&
					!machine.isManagedByMdatp &&
					!this.featuresService.isEnabled(Feature.MagellanShieldsUp),
			},
			Unknown: {
				...isolatePartialConfig,
				tooltipKey: 'machines.entityDetails.actions.$general.tooltip.error',
				disabled: true,
			},
		};

		this.requestMachineIsolationState();

		return this.machineIsolationAction$
			.pipe(map(requestsState => requestsState.isolation || 'ReverseRequestCompleted'))
			.pipe(
				map<MachineRequestState, ItemActionModelConfig>(requestState => {
					const { nameKey, tooltipKey, action, disabled, trackId } = requestStateConfigMap[
						requestState
					];
					return {
						id: 'isolateMachine',
						icon: FabricIconNames.Blocked,
						rbac: [RbacMdeAllowedActions.remediationActions],
						rbacState: RbacControlState.disabled,
						nameKey,
						tooltip: this.i18nService.get(tooltipKey),
						disabled: disabled,
						refreshOnResolve: false,
						tracking: {
							id: trackId,
							type: TrackingEventType.Action,
							component: machineTrackingComponent,
						},
						method: async ([machine]: [Machine]) => {
							const response = await action(machine);
							if (!response.confirmed) {
								return;
							}

							if (machine.isManagedByMdatp) {
								await this.machinesActionsService.openActionCenter(machine, response.data.requestGuid);
							}
						},
					};
				})
			);
	}

	private _createMultipleIsolateMachineActionConfig(): ItemActionModelConfig<Machine> {
		// 'Isolate machine' action
		return {
			id: 'multipleIsolateMachine',
			icon: FabricIconNames.Blocked,
			rbac: [RbacMdeAllowedActions.remediationActions],
			rbacState: RbacControlState.disabled,
			name: this.i18nService.strings
				.machines_entityDetails_actions_isolateMachine_title_multipleIsolate,
			tooltip: this.i18nService.strings
				.machines_entityDetails_actions_multiIsolateMachine_tooltip_isolate,
			refreshOnResolve: false,
			tracking: {
				id: 'multipleMachineIsolateAction',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (selectedMachines: Array<Machine>) => {
				this.machinesActionsService.multipleIsolate(selectedMachines, true);

				return Promise.resolve();
			},
		};
	}

	private _createMultipleReverseIsolateActionConfig(): ItemActionModelConfig<Machine> {
		// 'Release machine from isolation' action
		return {
			id: 'multipleReverseIsolateMachine',
			icon: FabricIconNames.Blocked,
			rbac: [RbacMdeAllowedActions.remediationActions],
			rbacState: RbacControlState.disabled,
			name: this.i18nService.strings
				.machines_entityDetails_actions_isolateMachine_title_removeIsolation,
			tooltip: this.i18nService.strings
				.machines_entityDetails_actions_multiIsolateMachine_tooltip_removeIsolation,
			refreshOnResolve: false,
			tracking: {
				id: 'multipleMachineReleaseFromIsolationAction',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (selectedMachines: Array<Machine>) => {
				this.machinesActionsService.multipleReleaseFromIsolation(selectedMachines);
				return Promise.resolve();
			},
		};
	}

	private _createInvestigateActionConfig(
		machines: ReadonlyArray<Machine>
	): Observable<ItemActionModelConfig<Machine>> {
		if (!this.featuresService.isEnabled(Feature.InvestigateMachine)) {
			return of(null);
		}
		return of({
			id: 'investigateMachine',
			icon: 'investigationOutline',
			rbac: [RbacMdeAllowedActions.alertsInvestigation],
			rbacState: RbacControlState.disabled,
			nameKey: 'machines.entityDetails.actions.investigateMachine.title',
			tooltip: this.i18nService.get('machines.entityDetails.actions.investigateMachine.tooltip'),
			refreshOnResolve: false,
			disabled: machines.some(machine => {
				const osCategory =
					machine && machine.os && machine.os.platform && machine.os.platform.category;
				return (
					(machine.isMdatp && !machine.isManagedByMdatp) ||
					osCategory === OperatingSystemPlatformCategories.macOS ||
					osCategory === OperatingSystemPlatformCategories.Linux
				);
			}),
			method: (machines: ReadonlyArray<Machine>) => {
				const calls = machines.map((machine: Machine) => {
					return this.paris.apiCall(InvestigateMachineApiCall, machine);
				});

				return combineLatest(calls)
					.pipe(
						take(1),
						tap(
							() => {
								this.dialogsService.showSuccessSnackbar({
									text: this.i18nService.get(
										'machines.entityDetails.actions.investigateMachine.successMessage'
									),
									disableForceFocus: true,
								});
							},
							err => {
								this.errorsDialogService.showError({
									title: this.i18nService.get(
										'machines.entityDetails.actions.investigateMachine.failureMessage'
									),
									data: err,
								});
							}
						)
					)
					.toPromise();
			},
		});
	}

	private _createLiveResponseActionConfig(
		machines: ReadonlyArray<Machine>
	): Observable<ItemActionModelConfig<Machine>> {
		if (!this.featuresService.isEnabled(Feature.LiveResponse) || machines.length > 1) {
			return of(null);
		}
		const [machine] = machines;
		return defer(async () => {
			const supportData = await this.liveResponsePermissionsService.isLiveResponseSupportedForMachine(
				machine
			);
			return {
				id: 'liveResponse',
				icon: FabricIconNames.Play,
				rbac: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.liveResponseAdvanced],
				rbacState: RbacControlState.disabled,
				name: this.i18nService.strings.machines_entityDetails_actions_createLiveResponse_title,
				allowRbacTooltipOverride: false,
				tooltip:
					(supportData.disabled && supportData.disableReason) ||
					this.i18nService.strings.machines_entityDetails_actions_createLiveResponse_tooltip,
				refreshOnResolve: false,
				disabled: supportData.disabled,
				method: (_machines: ReadonlyArray<Machine>) => {
					return this.liveResponseService
						.createSession(_machines[0])
						.then((session: LiveResponseSession) => {
							this.router.navigate(['live-response', session.id]);
						});
				},
			};
		});
	}

	private _createTroubleshootingModeConfig(machine: Machine): Observable<ItemActionModelConfig<void>> {
		if (!this.featuresService.isEnabled(Feature.TroubleshootingMachine)) {
			return of(null);
		}

		const isUserAllowedToEdit = this.rbacControlService.hasRequiredMdePermission([
			RbacMdeAllowedActions.securitySettings,
		]);

		type PartialConfig = Partial<ItemActionModelConfigSettings<TroubleshootResponse>> &
			Pick<ItemActionModelConfigSettings<TroubleshootResponse>, 'nameKey'>;

		const troubleshootPartialConfig: PartialConfig = {
			nameKey: 'machines.entityDetails.actions.turnOnTroubleshootingMode.title',
			trackId: 'machineTroubleshootAction',
		};

		// TODO: Get proper strings from PMs/writers when they are ready...
		const requestStateConfigMap: Record<
			MachineRequestState | 'NotSupported',
			ItemActionModelConfigSettings<TroubleshootResponse>
		> = {
			RequestCompleted: {
				...troubleshootPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode',
				disabled: false,
			},
			ReverseRequestPending: {
				...troubleshootPartialConfig,
				nameKey: 'machines.entityDetails.actions.troubleshoot.title.pendingTroubleshoot',
				tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.pendingTroubleshoot',
				disabled: true,
			},
			RequestPending: {
				...troubleshootPartialConfig,
				nameKey: 'machines.entityDetails.actions.troubleshoot.title.pendingTroubleshoot',
				tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.pendingTroubleshoot',
				disabled: true,
			},
			ReverseRequestCompleted: {
				...troubleshootPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode',
				disabled: false,
			},
			NotSupported: {
				...troubleshootPartialConfig,
				tooltipKey:
					'machines_entityDetails_actions_troubleshoot_tooltip_notSupportedTroubleshootMode',
				disabled: true,
			},
			Unknown: {
				...troubleshootPartialConfig,
				tooltipKey: 'machines.entityDetails.actions.troubleshoot.tooltip.troubleshootMode',
				disabled: false,
			},
		};

		const hasTroubleshootContent$ = this.paris
			.queryForItem<Machine, MachineRequest>(MachineMachineRequestLatestRelationship, machine)
			.pipe(map(dataSet => dataSet.items.filter(e => e.type === 'TroubleshootResponse')));

		const getLatestTroubleshootState$ = this.paris
			.getRelatedItem<Machine, MachineRequestsState>(MachineMachineRequestsStateRelationship, machine)
			.pipe(map(requestsState => requestsState.troubleshoot || 'ReverseRequestCompleted'));

		return combineLatest([hasTroubleshootContent$, getLatestTroubleshootState$]).pipe(
			map<[TroubleshootModeRequest[], MachineRequestState], ItemActionModelConfig>(
				([hasTsContent, requestState]) => {
					const { nameKey, tooltipKey, disabled, trackId } = requestStateConfigMap[requestState];

					const succeededRequests = hasTsContent.filter(
						r => r.requestStatus === 'Succeeded' && r.troubleshootState == 1
					);

					const hasSucceededRequests = succeededRequests.length > 0;
					const expirationDate =
						hasSucceededRequests && succeededRequests[0].troubleshootExpirationDateTimeUtc;

					let menuTitleKey = nameKey;

					let tooltipMessage: string = isUserAllowedToEdit
						? this.i18nService.get(tooltipKey)
						: this.i18nService.get(
								'machines.entityDetails.actions.troubleshoot.tooltip.rbacError'
						  );

					let shouldDisableTSModeCommand = disabled;
					if (requestState == 'RequestCompleted' && hasSucceededRequests && isUserAllowedToEdit) {
						// 5 min in milliseconds. Time delta to allow some tolerance between the scheduled
						// expiration time and the current time, to make sure that TS mode is already
						// expired.
						const timeDelta = 1000 * 60 * 5;
						const timeNow = Date.now();
						if (expirationDate.getTime() + timeDelta > timeNow) {
							shouldDisableTSModeCommand = true;
							tooltipMessage = this.i18nService.get(
								'machines.entityDetails.actions.troubleshoot.tooltip.isOn'
							);
							menuTitleKey = 'machines.entityDetails.actions.troubleshoot.title.isOn';
						}
					}

					return {
						id: 'troubleshootMode',
						icon: FabricIconNames.DeveloperTools,
						nameKey: menuTitleKey,
						tooltip: tooltipMessage,
						disabled: shouldDisableTSModeCommand || !isUserAllowedToEdit,
						tracking: {
							id: trackId,
							type: TrackingEventType.Action,
							component: machineTrackingComponent,
						},
						method: ([machine]: [Machine]) =>
							this.machinesActionsService.openTroubleshootingModePanel(machine),
					};
				}
			)
		);
	}

	private _createActionCenterActionConfig(machine: Machine): Observable<ItemActionModelConfig<void>> {
		const hasActionCenterContent$ = this.paris
			.queryForItem<Machine, MachineRequest>(MachineMachineRequestLatestRelationship, machine)
			.pipe(map(dataSet => dataSet.items && dataSet.items.length > 0));

		const anyRequestActive$ = this.paris
			.getRelatedItem<Machine, MachineAnyRequestsActive>(MachineAnyRequestsActiveRelationship, machine)
			.pipe(map(anyRequestsActive => anyRequestsActive.isActive));

		return combineLatest([hasActionCenterContent$, anyRequestActive$]).pipe(
			map<[boolean, boolean], ItemActionModelConfig>(([hasActionCenterContent, anyRequestActive]) => ({
				id: 'actionCenter',
				nameKey: 'machines.entityDetails.actions.actionCenter.title',
				tooltip: this.i18nService.get('machines.entityDetails.actions.actionCenter.tooltip'),
				refreshOnResolve: false,
				disabled: !anyRequestActive && !hasActionCenterContent,
				tracking: {
					id: 'MachineOpenActionCenter',
					type: TrackingEventType.Action,
					component: machineTrackingComponent,
				},
				icon: {
					iconName: FabricIconNames.F12DevTools,
					'data-badged-blue': anyRequestActive ? '!' : null,
				},
				method: ([machine]: [Machine]) => this.machinesActionsService.openActionCenter(machine),
			}))
		);
	}

	private _createMachineValueActionConfig(
		machineEntityTypeService: MachineEntityTypeService
	): Observable<ItemActionModelConfig<Machine>> {
		if (!this.featuresService.isEnabled(Feature.TvmMachineValue)) {
			return of(null);
		}
		return of({
			id: MachineAction.MachineValue,
			icon: FabricIconNames.Sort,
			nameKey: 'machines_entityDetails_actions_machineValue_title',
			tooltip: this.i18nService.get('machines_entityDetails_actions_machineValue_title'),
			refreshOnResolve: true,
			rbac: [RbacMdeAllowedActions.alertsInvestigation],
			refreshEntityPanelOnResolve: true,
			tracking: {
				id: MachineAction.MachineValue + 'CommandBar',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (machines: ReadonlyArray<Machine> | ReadonlyArray<SoftwareInstallationBase>) =>
				this.getMachineValueActionMethod(machineEntityTypeService, machines),
		});
	}

	private _createReportInaccuracyActionConfig(): Observable<ItemActionModelConfig<Machine>> {
		if (!this.featuresService.isEnabled(Feature.MagellanReportInaccuracy)) {
			return of(null);
		}
		return of({
			id: 'reportInaccuracy',
			icon: FabricIconNames.Feedback,
			nameKey: 'machines_entityDetails_actions_reportInaccuracy_title',
			tooltip: this.i18nService.strings.machines_entityDetails_actions_reportInaccuracy_title,
			refreshOnResolve: true,
			refreshEntityPanelOnResolve: true,
			tracking: {
				id: 'reportInaccuracyCommandBar',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			method: (machines: ReadonlyArray<Machine>) =>
				this.getReportInaccuracyActionMethod(machines),
		});
	}

	private getMachineExclusionStateActionConfigProperties(
		isMultiselectMode: boolean,
		proposedExclusionState: MachineExclusionState,
		isUserAllowedToEdit: boolean
	): { nameKey; icon; isButtonDisabled } {
		const nameKey =
			proposedExclusionState == MachineExclusionState.Excluded
				? isMultiselectMode
					? 'machines_entityDetails_actions_excludeDevices_title'
					: 'machines_entityDetails_actions_excludeDevice_title'
				: isMultiselectMode
				? 'machines_entityDetails_actions_cancelExclusion_title'
				: 'machines_entityDetails_actions_exclusionDetailsDevice_title';
		const icon =
			!isMultiselectMode || proposedExclusionState == MachineExclusionState.Excluded
				? FabricIconNames.DisconnectVirtualMachine
				: FabricIconNames.ConnectVirtualMachine;

		const isButtonDisabled =
			!isUserAllowedToEdit &&
			(proposedExclusionState === MachineExclusionState.Excluded ||
				(proposedExclusionState === MachineExclusionState.Included && isMultiselectMode));
		return { nameKey, icon, isButtonDisabled };
	}

	private createMachineExclusionStateActionConfig(
		machines: ReadonlyArray<Machine>
	): ItemActionModelConfig<Machine> {
		const isMultiselectMode = machines.length != 1;
		const proposedExclusionState = machines.every(
			machine => machine.exclusionState === MachineExclusionState.Excluded
		)
			? MachineExclusionState.Included
			: MachineExclusionState.Excluded;
		const isUserAllowedToEdit = this.rbacControlService.hasRequiredMdePermission([
			RbacMdeAllowedActions.securitySettings,
		]);
		const { nameKey, icon, isButtonDisabled } = this.getMachineExclusionStateActionConfigProperties(
			isMultiselectMode,
			proposedExclusionState,
			isUserAllowedToEdit
		);

		return {
			id: 'excludeOrIncludeDevice',
			icon: icon,
			nameKey: nameKey,
			tooltip: this.i18nService.get(nameKey),
			refreshOnResolve: true,
			refreshEntityPanelOnResolve: true,
			tracking: {
				id: 'excludeOrIncludeDevice',
				type: TrackingEventType.Action,
				component: machineTrackingComponent,
			},
			disabled: isButtonDisabled,
			method: async (machines: ReadonlyArray<Machine>) => {
				this.reactPanelsService.renderComponent({
					componentName: 'ManageMachineExclusionStateFlyoutWithAppBootstrap@wicd-ine/main',
					props: {
						machines,
						isUserAllowedToEdit: isUserAllowedToEdit,
						proposedExclusionState: proposedExclusionState,
						isMultiselectMode: isMultiselectMode,
						shouldSelfDismissOnDone: true,
						onDone: () => {
							this.machinesService.clearCachedMachines();
							this.router.navigate(['machines']);
						},
					},
				});
			},
		};
	}

	private _createMachineExclusionStateActionConfig$(
		machines: ReadonlyArray<Machine>
	): Observable<ItemActionModelConfig<Machine>> {
		if (!this.featuresService.isEnabled(Feature.ExcludedDevices) || !sccHostService.isSCC) {
			return of(null);
		}

		return of(this.createMachineExclusionStateActionConfig(machines));
	}

	private getMachineValueActionMethod(
		machineEntityTypeService: MachineEntityTypeService,
		machines: ReadonlyArray<Machine> | ReadonlyArray<SoftwareInstallationBase>
	): Promise<any> {
		if (machines[0] instanceof Machine) {
			return machineEntityTypeService.entityType.pseudoTags.set(
				machines as ReadonlyArray<Machine>,
				MachineAction.MachineValue
			);
		} else {
			const machineRepository = this.paris.getRepository(Machine);
			machineRepository
				.getItemById((machines[0] as SoftwareInstallationBase).assetId)
				.pipe(
					map(machine => {
						return machineEntityTypeService.entityType.pseudoTags.set(
							[machine],
							MachineAction.MachineValue
						);
					})
				)
				.toPromise();
		}
	}

	private async getReportInaccuracyActionMethod(
		machines: ReadonlyArray<Machine> | ReadonlyArray<SoftwareInstallationBase>
	): Promise<any> {
		return openDeviceReportInaccuracyPanel({selectedItems: machines, entryPoint:'DeviceInventoryFromDevicePage', isPanelOpen:true});
	}
}
