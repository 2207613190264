import { ChangeDetectorRef } from '@angular/core';
import { AirsNotificationRulesStore } from '../services/airs-notification-rules.store';
import { AirsNotificationRulesReportsStore } from '../services/airs-notification-rules-reports.store';
import { AuthService } from '@wcd/auth';
import { AirsNotificationRulesService } from '../services/airs-notification-rules.service';
var AirsNotificationRulesComponent = /** @class */ (function () {
    function AirsNotificationRulesComponent(notificationRulesStore, notificationRulesService, notificationRulesReportsStore, changeDetectorRef, authService) {
        this.notificationRulesStore = notificationRulesStore;
        this.notificationRulesService = notificationRulesService;
        this.notificationRulesReportsStore = notificationRulesReportsStore;
        this.changeDetectorRef = changeDetectorRef;
        this.authService = authService;
        this.notificationRules = [];
        this.notificationRulesReports = [];
        this.updateNotificationRules();
        notificationRulesService.isEditingNotificationRule$.subscribe(function () { return changeDetectorRef.markForCheck(); });
    }
    Object.defineProperty(AirsNotificationRulesComponent.prototype, "allNotificationRules", {
        get: function () {
            return this.notificationRules
                .concat(this.notificationRulesReports)
                .sort(function (a, b) {
                return a.id < b.id ? 1 : -1;
            });
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRulesComponent.prototype.updateNotificationRules = function () {
        var _this = this;
        this.notificationRulesStore.items$.subscribe(function (data) {
            _this.notificationRules = data;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.error = error;
            _this.changeDetectorRef.markForCheck();
        });
    };
    AirsNotificationRulesComponent.prototype.removeNotificationRule = function (notificationRule) {
        var store = notificationRule.type.requireSchedule
            ? this.notificationRulesReportsStore
            : this.notificationRulesStore;
        store.remove(notificationRule);
    };
    return AirsNotificationRulesComponent;
}());
export { AirsNotificationRulesComponent };
