import { InjectionToken } from '@angular/core';

/**
 * Classes that implement this interface must declare either a `readonly` or a `isDisabled` property
 */
export interface Disableable {
	readonly?: boolean;
	isDisabled?: boolean;
	disableReason?: string;
}

export const DISABLEABLE_TOKEN = new InjectionToken<Disableable>('DisableableToken');
