
		<div
			class="wcd-full-height wcd-flex-vertical"
			data-track-component="Logged-on users panel"
			data-track-component-type="Side Panel"
		>
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="page-header-icon wcd-flex-none">
					<wcd-shared-icon class="round-icon color-box-gray-200" iconName="users.user">
					</wcd-shared-icon>
				</div>
				<div class="wcd-flex-1">
					<h3 class="side-panel-title" id="machine-logged-on-users-panel-header" #panelHeader>
						{{ 'machines.loggedOnUsers.title' | i18n }}
						<span class="ms-color-gray140">{{
							'machines.loggedOnUsers.subtitle' | i18n: { days: 30 }
						}}</span>
					</h3>
					<div class="page-subtitle">
						<fab-icon iconName="System" className="small-icon"></fab-icon>
						{{ globalEntityTypesService.getEntityType('machine').getEntityName(machine) }}
					</div>
				</div>
			</header>
			<dataview
				class="wcd-flex-1"
				*ngIf="machine"
				id="machine-logged-on-users"
				className="no-left-padding"
				[repository]="machineLoggedOnUsersRepository"
				[navigateOnChange]="false"
				[allowPaging]="false"
				[allowFilters]="false"
				[fields]="machineUserFields"
				[defaultSortFieldId]="'loginCount'"
				[disableSelection]="true"
				[label]="'machines.report.widgets.loggedOnUsers.data.plural' | i18n"
				[ignoreQueryParams]="true"
				[fixedOptions]="backendOptions"
			></dataview>
		</div>
	