var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { WebContentFilteringCategoryType, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { FabricIconNames } from '@wcd/scc-common';
var WebContentFilteringMachineListReportDetailsComponent = /** @class */ (function (_super) {
    __extends(WebContentFilteringMachineListReportDetailsComponent, _super);
    function WebContentFilteringMachineListReportDetailsComponent(paris, i18nService, changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this._categoryIdNameMappings = {};
        _this.topDomainsFields = DataTableField.fromList([
            {
                id: 'domain',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.domain'),
                getDisplay: function (item) { return item.name; },
                getLink: function (item) { return "url/" + item.name; },
            },
            {
                id: 'count',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDomains.columns.accessCount'),
                getDisplay: function (item) { return item.count; },
            },
        ]);
        _this.topDevicesFields = DataTableField.fromList([
            {
                id: 'device',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.device'),
                getDisplay: function (item) { return item.name; },
                getLink: function (item) { return "machines/" + item.deviceId + "/main"; },
                icon: {
                    fabricIcon: function () { return FabricIconNames.System; },
                },
            },
            {
                id: 'deviceCount',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.commonPivots.sections.topDevices.columns.deviceCount'),
                getDisplay: function (item) { return item.count; },
            },
        ]);
        _this.topCategoriesFields = DataTableField.fromList([
            {
                id: 'category',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.machineList.sections.topCategories.columns.category'),
                getDisplay: function (item) { return _this._categoryIdNameMappings[item.categoryId]; },
            },
            {
                id: 'count',
                name: _this.i18nService.get('reporting.webContentFilteringReport.pivots.machineList.sections.topCategories.columns.accessCount'),
                getDisplay: function (item) { return item.count; },
            },
        ]);
        var categoryList = _this.paris.getRepository(WebContentFilteringCategoryType).entity.values;
        categoryList.forEach(function (category) {
            return (_this._categoryIdNameMappings[category.id] = _this.i18nService.get("webCategories.categories." + category.name));
        });
        return _this;
    }
    Object.defineProperty(WebContentFilteringMachineListReportDetailsComponent.prototype, "report", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(WebContentFilteringMachineListReportDetailsComponent.prototype, "trendsDeltaPercentageAsAbs", {
        get: function () {
            return Math.abs(this.entity.trendsDeltaPercentage);
        },
        enumerable: true,
        configurable: true
    });
    WebContentFilteringMachineListReportDetailsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
    };
    return WebContentFilteringMachineListReportDetailsComponent;
}(EntityPanelComponentBase));
export { WebContentFilteringMachineListReportDetailsComponent };
