var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { EvaluationSimulatorStatusType, EvaluationSimulatorAgent, } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { EvaluationService } from '../services/evaluation.service';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { tap, finalize } from 'rxjs/operators';
import { DialogsService } from '../../dialogs/services/dialogs.service';
var EvaluationCreateSimulationPanelComponent = /** @class */ (function (_super) {
    __extends(EvaluationCreateSimulationPanelComponent, _super);
    function EvaluationCreateSimulationPanelComponent(router, evaluationService, dialogsService, i18nService, changeDetectorRef) {
        var _this = _super.call(this, router) || this;
        _this.evaluationService = evaluationService;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.simulationsCatalog = [];
        _this.canCreateSimulation = false;
        _this.SpinnerSize = SpinnerSize;
        _this.MessageBarType = MessageBarType;
        _this.createInProgress = false;
        _this.getSimulationLabel = function (simulationCatalog) {
            return (simulationCatalog &&
                (_this.selectedSimulator === EvaluationSimulatorAgent.All
                    ? simulationCatalog.simulator + ": " + simulationCatalog.name
                    : simulationCatalog.name));
        };
        _this.getMachineLabel = function (machine) {
            return machine && machine.name;
        };
        return _this;
    }
    EvaluationCreateSimulationPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getSimulationsCatalog();
        this.evaluationService.evaluationMachines$
            .pipe(tap(function () {
            _this.canCreateSimulation = !!_this.evaluationService.getMachinesWithSimulatorsReady()
                .length;
            _this.changeDetectorRef.markForCheck();
        }))
            .subscribe();
    };
    EvaluationCreateSimulationPanelComponent.prototype.getSimulators = function () {
        return Object.values(EvaluationSimulatorAgent);
    };
    EvaluationCreateSimulationPanelComponent.prototype.getSimulationsCatalog = function () {
        var _this = this;
        this.evaluationService
            .getSimulationsCatalog()
            .pipe(tap(function (simulationsCatalog) {
            if (_this.simulationId) {
                var simulation = simulationsCatalog.find(function (s) { return s.simulationId === _this.simulationId; });
                _this.selectedSimulator = simulation.simulator;
                _this.selectedSimulation = simulation;
            }
            _this.simulationsCatalog =
                _this.selectedSimulator === EvaluationSimulatorAgent.All
                    ? simulationsCatalog
                    : simulationsCatalog.filter(function (simulation) {
                        return EvaluationSimulatorAgent[simulation.simulator] ===
                            _this.selectedSimulator;
                    });
        }))
            .subscribe();
    };
    EvaluationCreateSimulationPanelComponent.prototype.onSimulatorSelect = function () {
        this.selectedSimulation = null;
        this.selectedMachine = null;
        this.getSimulationsCatalog();
    };
    EvaluationCreateSimulationPanelComponent.prototype.getSimulatorRelatedMachines = function () {
        var _this = this;
        if (!this.selectedSimulator ||
            (this.selectedSimulator === EvaluationSimulatorAgent.All &&
                (!this.selectedSimulation || !this.selectedSimulation.simulator)))
            return;
        return this.machinesWithSimulatorsReady.filter(function (m) {
            var simulatorsExist = Object.entries(m.simulatorsStatus)
                .filter(function (k) { return k.every(function (v) { return v; }); })
                .filter(function (s) {
                return ((s[0].toLowerCase() === _this.selectedSimulator.toLowerCase() ||
                    s[0].toLowerCase() ===
                        (_this.selectedSimulation &&
                            _this.selectedSimulation.simulator.toLowerCase())) &&
                    s[1].id === EvaluationSimulatorStatusType.Completed);
            });
            return simulatorsExist.length;
        });
    };
    EvaluationCreateSimulationPanelComponent.prototype.createSimulation = function () {
        var _this = this;
        this.createInProgress = true;
        this.changeDetectorRef.detectChanges();
        return this.evaluationService
            .createSimulation(this.selectedSimulation.simulationId, this.selectedMachine.index)
            .pipe(finalize(function () { return (_this.createInProgress = false); }))
            .subscribe(function () {
            _this.destroy();
        }, function (error) {
            var isSimulationBlocked = error.status === 400 && error.response && error.response.errorCode === 'SimulationRunningOnSameMachine';
            var isDefault = error.status === 400 && error.response && error.response.errorCode === 'Default';
            _this.changeDetectorRef.markForCheck();
            _this.dialogsService.showError({
                title: _this.i18nService.get('evaluation.dashboard.simulationDataView.commandBar.createSimulation.error'),
                data: isSimulationBlocked ? _this.i18nService.get('evaluation.dashboard.simulationDataView.commandBar.createSimulation.error.simulationRunningOnMachine') : isDefault ? '' : error,
            });
        });
    };
    EvaluationCreateSimulationPanelComponent.prototype.getTooltip = function () {
        return !this.selectedMachine || !this.selectedSimulation
            ? this.i18nService.get('evaluation.dashboard.simulationDataView.commandBar.createSimulation.missingChoose')
            : '';
    };
    return EvaluationCreateSimulationPanelComponent;
}(PanelContainer));
export { EvaluationCreateSimulationPanelComponent };
