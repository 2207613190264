import { Component, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';

@Component({
	selector: 'web-content-filtering-policy-new-modal',
	template: `
		<wcd-panel (close)="closePanel()" [settings]="settings">
			<web-content-filtering-policy-new
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				(save)="closePanel()"
				(cancel)="closePanel()"
			>
			</web-content-filtering-policy-new>
		</wcd-panel>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WebContentFilteringPolicyNewModalComponent extends PanelContainer {
	constructor(protected router: Router) {
		super(router);
	}
}
