import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../shared/shared.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { ProcessEntityPanelComponent } from './components/process.entity-panel.component';
import { ProcessEntityTypeService } from './services/process.entity-type.service';
import { FileEntityTypeModule } from '../files/file.entity-type.module';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, FileEntityTypeModule],
	declarations: [ProcessEntityPanelComponent],
	exports: [ProcessEntityPanelComponent],
	entryComponents: [ProcessEntityPanelComponent],
	providers: [ProcessEntityTypeService],
})
export class ProcessEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(ProcessEntityTypeService);
	}
}
