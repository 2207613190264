
<ul class="list-unstyled wcd-margin-xsmall-bottom" [attr.aria-labelledby]="ariaLabelledby">
	<li
		*ngFor="let item of data; trackBy:trackById"
		[class]="barCssClass"
		[ngClass]="{'clickable pointer': !item.disableClick}"
		>
		<wcd-bar
			(onClick)="onClick.emit(item)"
			[value] = "item.value"
			[total] = "item.total"
			[fillRatio]="(item.total > 0) ? (item.value / item.total) : (item.isDefaultFull ? 1 : 0)"
			[colorName]="item?.valueColor?.name"
			[backgroundColorName]="item?.totalColor?.name"
			[width]="item.width"
			[height]="item.height"
			[icon]="item.icon"
			[iconTooltip]="item.iconTooltip"
			[title]="item.title"
			[barTitleCssClass]="barTitleCssClass"
			[unit]="item.unit"
			[valuePrefix]="item.valuePrefix"
			[restPrefix]="item.restPrefix"
			[noDataText]="item.noDataText"
			[useCustomDisplay]="item.useCustomDisplay"
			[showValueAsPercentage]="item.showValueAsPercentage"
			[tabIndex]="item.tabIndex !== undefined ? item.tabIndex : '0'"
			[valueAriaLabel]='valueAriaLabel'
			[totalAriaLabel]='totalAriaLabel'
			spaceBetweenBars="2px">
        </wcd-bar>
    </li>
</ul>
