/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i4 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./machine.onboarding-status-message.component";
import * as i7 from "../../../../../../../projects/config/src/lib/services/app-flavor.service";
import * as i8 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i9 from "@angular/router";
import * as i10 from "../../../insights/services/app-insights.service";
import * as i11 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i12 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
var styles_MachineOnboardingStatusMessageComponent = [];
var RenderType_MachineOnboardingStatusMessageComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MachineOnboardingStatusMessageComponent, data: {} });
export { RenderType_MachineOnboardingStatusMessageComponent as RenderType_MachineOnboardingStatusMessageComponent };
function View_MachineOnboardingStatusMessageComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "wcd-margin-small-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 3, "fab-messagebar-button", [], null, [[null, "onClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClick" === en)) {
        var pd_0 = (_co.goToOnboardingPage() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_FabMessageBarButtonComponent_0, i1.RenderType_FabMessageBarButtonComponent)), i0.ɵdid(4, 6012928, null, 1, i2.FabMessageBarButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { disabled: [0, "disabled"], text: [1, "text"] }, { onClick: "onClick" }), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isAdmin; var currVal_1 = i0.ɵunv(_v, 4, 1, _ck(_v, 6, 0, i0.ɵnov(_v.parent.parent, 0), "DevicePaneOnboardingCtaMessageButtonText")); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_MachineOnboardingStatusMessageComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "div", [["class", "wcd-margin-medium-bottom  message-bar"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 9, "fab-message-bar", [], null, null, null, i1.View_FabMessageBarComponent_0, i1.RenderType_FabMessageBarComponent)), i0.ɵdid(3, 5881856, null, 0, i2.FabMessageBarComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { messageBarType: [0, "messageBarType"], renderActions: [1, "renderActions"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 5, "div", [["class", "wcd-font-size-s wcd-margin-none-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), i0.ɵppd(9, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\n\t\t\t"])), (_l()(), i0.ɵand(0, [["actions", 2]], null, 0, null, View_MachineOnboardingStatusMessageComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.MessageBarType.warning; var currVal_1 = i0.ɵnov(_v, 13); _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = i0.ɵunv(_v, 8, 0, _ck(_v, 9, 0, i0.ɵnov(_v.parent, 0), "DevicePaneOnboardingCtaMessage")); _ck(_v, 8, 0, currVal_2); }); }
export function View_MachineOnboardingStatusMessageComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i3.I18nPipe, [i4.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_MachineOnboardingStatusMessageComponent_1)), i0.ɵdid(3, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.shouldDisplayOnboardingCta; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_MachineOnboardingStatusMessageComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "onboarding-status-message", [], null, null, null, View_MachineOnboardingStatusMessageComponent_0, RenderType_MachineOnboardingStatusMessageComponent)), i0.ɵdid(1, 573440, null, 0, i6.MachineOnboardingStatusMessageComponent, [i7.FlavorService, i8.FeaturesService, i9.Router, i10.AppInsightsService, i11.AuthService, i12.AppConfigService], null, null)], null, null); }
var MachineOnboardingStatusMessageComponentNgFactory = i0.ɵccf("onboarding-status-message", i6.MachineOnboardingStatusMessageComponent, View_MachineOnboardingStatusMessageComponent_Host_0, { device: "device" }, {}, []);
export { MachineOnboardingStatusMessageComponentNgFactory as MachineOnboardingStatusMessageComponentNgFactory };
