
		<wcd-panel (close)="destroy()" [settings]="settings">
			<domain-urls
				class="wcd-flex-1"
				[domainId]="domainIdSelected"
				[allowPaging]="false"
				[navigateOnChange]="false"
				[fullUrlList]="true"
				[hideControls]="false"
				[searchEnabled]="true"
				[defaultSortFieldId]="'accessCount'"
				[hideCommandBarOnEmptyData]="false"
			></domain-urls>
		</wcd-panel>
	