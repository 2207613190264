import { FlavorConfig } from '../models';

export interface IThreatAnalyticsConfig {
	mde: FlavorConfig;
}

export const ThreatAnalyticsConfig: IThreatAnalyticsConfig = {
	mde: {
		mdeFlavors: ['P2', 'Smb']
	},
}
