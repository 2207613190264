import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import {
	CapacityState,
	CustomTiIndicatorsCapacityApiCall,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	SystemExclusion,
	SystemExclusionType,
} from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { CustomTiIndicatorsService } from '../../custom_ti_indicators/services/custom-ti-indicators.service';
import { Observable, of, race } from 'rxjs';
import { map, mapTo, startWith, switchMap, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';

const loadingSymbol = Symbol();

@Component({
	selector: 'add-files-to-allowlist',
	template: `
		<modal (close)="cancel.emit()" [settings]="settings">
			<div class="wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex">
				<markdown>{{ message }}</markdown>
			</div>

			<footer class="dialog-footer wcd-flex-none right-text">
				<button
					class="btn btn-primary"
					data-track-id="DontAddToWhitelist"
					data-track-type="Button"
					(click)="cancel.emit()"
				>
					{{ i18nService.strings.buttons_done }}
				</button>
				<ng-container *ngLet="(tiCapacityData$ | async) as tiCapacityData">
					<ng-container *ngIf="tiCapacityData !== loadingSymbol">
						<button
							*ngIf="!tiCapacityData; else showTiError"
							type="button"
							class="btn-link"
							data-track-id="SetSystemExclusionRule"
							data-track-type="Button"
							[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
							(click)="setSystemExclusionRule()"
						>
							<fab-icon iconName="OpenInNewWindow"></fab-icon>
							{{ i18nService.strings.remediationActions_openAllowListPanel }}
						</button>
						<ng-template #showTiError>
							<span class="wcd-padding-left color-text-error">{{ tiCapacityData }}</span>
						</ng-template>
					</ng-container>
				</ng-container>
			</footer>
		</modal>
	`,
})
export class AddFilesToAllowlistModal extends ModalContainer implements OnInit, OnDestroy {
	@Input() message: string;
	@Input() sha1: string;
	@Input() hashes: Array<string>;
	@Input() onDone: () => any;
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	currentFileIndex: number = 0;
	uniqueHashes: Array<string>;
	currentFileHash: string;
	tiCapacityData$: Observable<string | typeof loadingSymbol>;
	loadingSymbol = loadingSymbol;

	private filesSystemExclusionType: SystemExclusionType = this.paris.getValue(SystemExclusionType, 'files');

	constructor(
		private paris: Paris,
		protected router: Router,
		private customTiIndicatorService: CustomTiIndicatorsService,
		public i18nService: I18nService
	) {
		super(router);
	}

	ngOnInit() {
		this.setUniqueHashes();
		this.setCurrentFileValues();
		this.tiCapacityData$ = this.paris.apiCall(CustomTiIndicatorsCapacityApiCall).pipe(
			map(capacity => {
				if (capacity.capacityState === CapacityState.Unavailable) {
					return this.i18nService.strings
						.customTiIndicator_dataview_actions_capacity_status_unavailable_title;
				}
				if (capacity.total + this.uniqueHashes.length > capacity.limit) {
					return this.i18nService.strings
						.customTiIndicator_dataview_actions_capacity_status_exceeded_tooltip;
				}
				return null;
			}),
			startWith(loadingSymbol)
		);
	}

	private setUniqueHashes() {
		this.uniqueHashes = Array.from(new Set((this.hashes || []).filter(Boolean)));
	}

	setCurrentFileValues() {
		this.currentFileHash =
			(this.uniqueHashes.length && this.uniqueHashes[this.currentFileIndex]) || this.sha1;
	}

	setSystemExclusionRule() {
		this.showExclusionPanel(this.filesSystemExclusionType, this.currentFileHash).then(
			() => {
				this.onDone && this.onDone();
			},
			() => {
				this.onDone && this.onDone();
			}
		);
		this.destroy();
	}

	private showExclusionPanel(
		filesSystemExclusion: SystemExclusionType,
		sha1: string
	): Promise<SystemExclusion> {
		if (!sha1) {
			return Promise.resolve(null);
		}
		let nextSha1: string;

		const handlePromise = (): Promise<SystemExclusion> => {
			this.currentFileIndex++;
			this.setCurrentFileValues();
			nextSha1 = !this.currentFileHash || sha1 === nextSha1 ? null : this.currentFileHash;
			return this.showExclusionPanel(filesSystemExclusion, nextSha1);
		};

		return this.customTiIndicatorService
			.showCustomTiIndicatorNewDialog(
				this.paris.getValue(CustomTiIndicatorsType, CustomTiIndicatorsTypes.Files),
				sha1
			)
			.pipe(
				switchMap(panel =>
					race([
						panel.instance.save.pipe(mapTo(true)),
						panel.instance.cancel.pipe(mapTo(false)),
					]).pipe(take(1))
				),
				switchMap(saved => {
					if (saved || this.uniqueHashes.length > 1) {
						return handlePromise();
					}
					return of(null);
				})
			)
			.toPromise();
	}
}
