import { RemediationAction } from '@wcd/domain';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { Injectable } from '@angular/core';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { RemediationActionEntityPanelComponent } from '../components/remediation-action.entity-panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RemediationActionsService } from './remediation-actions.service';
import { I18nService } from '@wcd/i18n';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { from, Observable, of, timer } from 'rxjs';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AuthService } from '@wcd/auth';
import { map } from 'rxjs/operators';
import { omit } from 'lodash-es';
import { INVESTIGATION_REFRESH_RATE } from '../../mtp_investigations/services/mtp-investigations.service';

@Injectable()
export class RemediationActionEntityTypeService implements EntityTypeService<RemediationAction> {
	constructor(
		private remediationActionsService: RemediationActionsService,
		private i18nService: I18nService,
		private authService: AuthService
	) {}

	readonly entityType: EntityType<RemediationAction> = {
		id: 'remediation-action',
		entity: RemediationAction,
		loadFullEntityInPanel: false,
		getIcon: (remediationActions: Array<RemediationAction>) => {
			return remediationActions[0].remediationActionType.icon;
		},
		getIconCssClass: (remediationActions: Array<RemediationAction>) => {
			return `round-icon color-box-warning-dark`;
		},
		singleEntityPanelComponentType: RemediationActionEntityPanelComponent,
		getEntityName: (remediationAction: RemediationAction) =>
			remediationAction.remediationActionType.titleText(1),
		getEntitiesLink: (remediationActions: Array<RemediationAction>) =>
			remediationActions.length === 1
				? `/investigation/${remediationActions[0].investigationId}`
				: null,
		getEntitiesLinkText: () => this.i18nService.strings.actionHistory_panel_button_openInvestigationPage,
		getActions: actions => {
			const isSecAdmin = this.authService.currentUser.isSecAdmin;
			const isAllowed = isSecAdmin || actions.every(a => !a.isOfficeInvestigation);

			const ignoreRbac = sccHostService.isSCC && actions.every(a => a.isOfficeInvestigation);
			let isAllowed$: Observable<boolean>;

			if (!isAllowed && sccHostService.isSCC) {
				isAllowed$ = from(<Promise<boolean>>sccHostService.auth.isInRole(SccRoles.searchAndPurge));
			} else {
				isAllowed$ = of(isAllowed);
			}
			return isAllowed$.pipe(
				map(hasPermission =>
					[
						{
							id: 'approveRemediationAction',
							nameKey: 'remediationActions_approve',
							icon: 'checkCircle',
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_approve
								: this.i18nService.strings.common_permissions_noPermissionTooltip,
							method: async (remediationActions?: Array<RemediationAction>) => {
								const confirmEvent = await this.remediationActionsService.approvePendingActions(
									remediationActions,
									false
								);
								if (
									confirmEvent &&
									confirmEvent.confirmed &&
									actions.some(a => a.isOfficeInvestigation)
								) {
									await timer(INVESTIGATION_REFRESH_RATE).toPromise();
								}
								this.remediationActionsService.showNotificationOnApprove(remediationActions);
								return confirmEvent;
							},
							refreshOnResolve: true,
							allowRbacTooltipOverride: true,
							rbac: [RbacMdeAllowedActions.remediationActions],
							closeOnAction: true,
							disabled: !hasPermission,
						},
						{
							id: 'rejectRemediationAction',
							nameKey: 'remediationActions_dismiss',
							icon: 'block',
							tooltip: hasPermission
								? this.i18nService.strings.remediationActions_tooltips_decline
								: this.i18nService.strings.common_permissions_noPermissionTooltip,
							method: async (remediationActions?: Array<RemediationAction>) => {
								const confirmEvent = await this.remediationActionsService.confirmAndDismissPendingActionsByType(
									remediationActions
								);
								if (
									confirmEvent &&
									confirmEvent.confirmed &&
									actions.some(a => a.isOfficeInvestigation)
								) {
									await timer(INVESTIGATION_REFRESH_RATE).toPromise();
								}
								return confirmEvent;
							},
							refreshOnResolve: true,
							allowRbacTooltipOverride: true,
							rbac: [RbacMdeAllowedActions.remediationActions],
							closeOnAction: confirmData => confirmData && confirmData.confirmed,
							disabled: !hasPermission,
						},
					].map(
						itemActionConfig =>
							new ItemActionModel(
								ignoreRbac
									? omit(itemActionConfig, 'rbac', 'allowRbacTooltipOverride')
									: itemActionConfig
							)
					)
				)
			);
		},
	};
}
