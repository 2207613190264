<div [ngClass]="{ 'wcd-full-height wcd-flex-vertical': !flatView }">
	<div *ngIf="tabs?.length > 1" class="wcd-margin-large-horizontal">
		<tabs [tabsData]="tabs" (select)="setTab($event.tab)"></tabs>
	</div>

	<div [ngSwitch]="currentTab.id" [class.wcd-scroll-vertical]="!flatView">
		<div
			*ngSwitchCase="tabIds.relations"
			class="tab-contents wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-1 wcd-flex-horizontal"
		>
			<entity-relations *ngIf="entity.relations?.length" [entity]="entity" class="wcd-full-width">
			</entity-relations>
		</div>

		<div
			*ngSwitchCase="tabIds.behavior"
			class="tab-contents wcd-flex-1 wcd-padding-vertical wcd-padding-large-horizontal"
		>
			<entity-behavior
				*ngIf="entity.behavior?.length"
				[entityBehavior]="entity.behavior"
			></entity-behavior>
		</div>

		<div *ngSwitchCase="tabIds.evidenceView" class="tab-contents">
			<ng-container [ngTemplateOutlet]="evidenceViewRef"></ng-container>
		</div>

		<div class="tab-contents" *ngSwitchDefault>
			<ng-container [ngTemplateOutlet]="evidenceViewRef"></ng-container>
			<!--report-->
			<wcd-collapsible-section
				*ngIf="entity.report?.providers?.length"
				[sectionId]="'airs-entity-panel-entity-details'"
				[label]="i18nService.strings.entities_panelSections_report"
			>
				<entity-report
					[entityType]="entity.type"
					[report]="entity.report"
					[viewProvidersByDefault]="true"
				></entity-report>
			</wcd-collapsible-section>

			<!--action details-->
			<wcd-collapsible-section
				*ngIf="entity.action"
				[label]="i18nService.strings.entities_action_title"
				[sectionId]="'airs-entity-panel-entity-action'"
			>
				<entity-action [entity]="entity" [showTitle]="false"></entity-action>
			</wcd-collapsible-section>
			<!--booleans-->
			<wcd-collapsible-section
				*ngIf="booleanProperties?.length"
				[label]="i18nService.strings.entities_panelSections_properties"
				[sectionId]="'airs-entity-panel-boolean-properties'"
			>
				<dl class="key-values" role="none">
					<ng-container *ngFor="let property of booleanProperties">
						<dt>{{ property.name }}</dt>
						<dd [class.color-text-success]="property.value">
							{{ (property.value ? 'common_yes' : 'common_no') | i18n }}
						</dd>
					</ng-container>
				</dl>
			</wcd-collapsible-section>
			<!--map-->
			<wcd-collapsible-section
				*ngIf="entity.location && isMapAvailable"
				[label]="i18nService.strings.entities_panelSections_location"
				[sectionId]="'airs-entity-panel-location'"
			>
				<external-map [location]="mapCoordinates" (error)="onMapError()"></external-map>
			</wcd-collapsible-section>
			<!--screenshot-->
			<wcd-collapsible-section
				*ngIf="entity.screenshot && isScreenshotAvailable"
				[label]="i18nService.strings.entities_panelSections_preview"
				[sectionId]="'airs-entity-panel-screenshot'"
			>
				<p>{{ i18nService.strings.entities_preview }}</p>
				<a
					[href]="entity.screenshot | safeUrl"
					(click)="(!isScreenshotError)"
					target="_blank"
					[class.not-active]="isScreenshotError"
				>
					<external-image [src]="entity.screenshot" (error)="onScreenshotError()"></external-image>
				</a>
			</wcd-collapsible-section>
			<!--machines-->
			<wcd-collapsible-section
				*ngIf="entity.machines?.length"
				[label]="i18nService.strings.entities_panelSections_machines"
				[sectionId]="'airs-entity-panel-machine-list'"
			>
				<investigated-machines-list [machines]="entity.machines"></investigated-machines-list>
			</wcd-collapsible-section>
			<!--related reports-->
			<wcd-collapsible-section
				*ngIf="entity.relatedReports?.length"
				[label]="
					entity.relatedReports.length > 1
						? i18nService.strings.entities_panelSections_relatedReportsPlural
						: i18nService.strings.entities_panelSections_relatedReportsSingular
				"
				[sectionId]="'airs-entity-panel-entity-report'"
			>
				<div *ngFor="let relatedEntity of entity.relatedReports">
					<h4>
						<wcd-shared-icon [iconName]="relatedEntity.entityType.icon"> </wcd-shared-icon>
						{{ relatedEntity.entityName }}
					</h4>
					<entity-report
						[entityType]="entity.type"
						[report]="relatedEntity.report"
						[viewProvidersByDefault]="true"
					></entity-report>
				</div>
			</wcd-collapsible-section>
			<!--memory contents-->
			<wcd-collapsible-section
				*ngIf="entity.memoryContents?.length && !(isLoadingEntity$ | async)"
				[label]="
					(entity.memoryContents.length > 1
						? 'entities_panelSections_memoryContentReportPlural'
						: 'entities_panelSections_memoryContentReportSingular')
						| i18n: { reportCount: entity.memoryContents.length }
				"
				[sectionId]="'airs-entity-panel-memory-content'"
			>
				<memory-contents
					*ngFor="let memoryContents of entity.memoryContents"
					[memoryContents]="memoryContents"
					class="wcd-margin-bottom"
					(exclusionChange)="requestEntitiesRefresh.emit()"
					[entityType]="entity.type"
				></memory-contents>
			</wcd-collapsible-section>
		</div>
	</div>
</div>
<ng-template #evidenceViewRef>
	<wcd-collapsible-section
		[sectionId]="collapsibleID.Details"
		[label]="'entities_details_title' | i18n: { entityType: entity.type.singularName }"
	>
		<entity-details [entity]="entity" [allowStatusSplit]="options?.allowStatusSplit"></entity-details>
		<ng-container *ngLet="(goHuntActionConfig$ | async) as actionConfig">
			<div *ngIf="actionConfig || threatExplorerLink" class="wcd-margin-top">
				<a
					*ngIf="actionConfig"
					[id]="actionConfig.id"
					[href]="actionConfig.href"
					[target]="actionConfig.openLinkInNewTab ? '_blank' : '_self'"
					class="btn btn-primary ms-background-color-themePrimary ms-color-white"
				>
					<wcd-shared-icon [ariaLabel]="''" [iconName]="actionConfig.icon"></wcd-shared-icon>
					{{ actionConfig.nameKey | i18n }}
				</a>
				<a
					*ngIf="threatExplorerLink"
					[id]="'airsEntityThreatExplorerLink'"
					[href]="threatExplorerLink"
					target="_blank"
					class="btn btn-primary ms-background-color-themePrimary ms-color-white"
				>
					<wcd-shared-icon [ariaLabel]="''" [iconName]="'ExploreData'"></wcd-shared-icon>
					{{ i18nService.strings.airsEntities_panel_openInExplorer }}
				</a>
			</div>
		</ng-container>
	</wcd-collapsible-section>
	<ng-container *ngIf="entity.type.id === AirsEntityType.File">
		<airs-entity-file [file]="file"> </airs-entity-file>
	</ng-container>
</ng-template>
