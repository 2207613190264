import { ComponentRef, Injectable, OnDestroy } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AirsNotificationRuleEditPanelComponent } from '../components/airs-notification-rule-edit.panel.component';
import { PanelType } from '@wcd/panels';
import { AirsNotificationRulesStore } from './airs-notification-rules.store';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AirsNotificationRulesService implements OnDestroy {
	isEditingNotificationRule$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(
		private dialogsService: DialogsService,
		private notificationRulesStore: AirsNotificationRulesStore
	) {}

	showNotificationRuleEditPanel(
		notificationRule?: AirsNotificationRuleModel
	): Promise<AirsNotificationRuleModel> {
		let panel: ComponentRef<AirsNotificationRuleEditPanelComponent>;

		if (this.isEditingNotificationRule$.getValue())
			return Promise.reject('Already editing notification rule.');

		this.isEditingNotificationRule$.next(true);

		return new Promise<AirsNotificationRuleModel>((resolve, reject) => {
			this.dialogsService
				.showPanel(
					AirsNotificationRuleEditPanelComponent,
					{
						id: 'airs-notification-rule-edit-panel',
						type: PanelType.smallFixedFar,
						isModal: false,
						headerText: `${notificationRule ? 'Edit' : 'Create'} Notification rule`,
						headerIcon: 'notificationRule',
					},
					{
						notificationRule: notificationRule
							? this.notificationRulesStore.cloneItem(notificationRule)
							: this.notificationRulesStore.createItem(),
						onDone: (notificationRule: AirsNotificationRuleModel) => {
							resolve(notificationRule);
							this.isEditingNotificationRule$.next(false);
							panel.instance.closePanel();
						},
						onCancel: () => {
							resolve(notificationRule);
							this.isEditingNotificationRule$.next(false);
							panel.instance.closePanel();
						},
					}
				)
				.subscribe((_panel: ComponentRef<AirsNotificationRuleEditPanelComponent>) => {
					panel = _panel;
					panel.onDestroy(() => {
						this.isEditingNotificationRule$.next(false);
					});
				});
		});
	}

	ngOnDestroy(): void {
		this.isEditingNotificationRule$.complete();
	}
}
