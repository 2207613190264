import { ChangeDetectorRef, Component } from '@angular/core';
import { MdeUserRoleActionEnum } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PortalRedirectionService } from '../services/portal-redirection.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { ContentState } from '@wcd/contents-state';
import { MessageBarType } from 'office-ui-fabric-react';
import { ConfirmEvent } from '../../../dialogs/confirm/confirm.event';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

const ENABLED_MDATP_REDIRECTION = 'EnabledMdatpPortalRedirection';
const DISABLED_MDATP_REDIRECTION = 'DisabledMdatpPortalRedirection';
const SAW_ERROR_MESSAGE = 'MdatpPortalRedirection-SawErrorMessage';

@Component({
	providers: [PortalRedirectionService],
	template: `
		<wcd-contents-state [contentState]="contentState">
			<ng-container loading>
				<div class="wcd-margin-xxLarge-top center-text">
					<fab-spinner></fab-spinner>
				</div>
			</ng-container>
			<ng-container complete>
				<div class="wcd-padding-large-horizontal" [class.wcd-padding-none-horizontal-right-important]="isScc">
					<h3 class="wcd-padding-large-bottom wcd-font-weight-bold">{{'settings_navItem_portalRedirection' | i18n}}</h3>
					<div class="wcd-flex-vertical wcd-full-height wcd-margin-large-bottom">
						<div>
							<markdown [data]="'settings.portalRedirection.description' | i18n"></markdown>
						</div>
						<div class="wcd-padding-xLarge-top wcd-font-weight-bold">
							{{'settings.portalRedirection.toggleTitle' | i18n}}
						</div>
						<div class="wcd-padding-small-top">
							<fab-toggle
								[ariaLabel]="('settings.portalRedirection.toggleTitle' | i18n) +' '+('settings.portalRedirection.onText' | i18n) +'/'+ ('settings.portalRedirection.offText' | i18n)"
								[onnText]="'settings.portalRedirection.onText' | i18n"
								[offText]="'settings.portalRedirection.offText' | i18n"
								[disabled]="!isUserAllowedToControlRedirection"
								[checked]="this.isPortalRedirectionEnabled"
								(onChange)="onToggleChange()"
								[attr.aria-describedby]="'durationInfo'"
							></fab-toggle>
							<div *ngIf="!this.isUserAllowedToControlRedirection" class="wcd-margin-top">
								{{'settings.portalRedirection.contactAdminText' | i18n}}
							</div>
							<fab-message-bar
								[messageBarType]="MessageBarType.info"
								contentClass="wcd-margin-top"
							>
								<span id="durationInfo">{{ 'settings.portalRedirection.durationInfo' | i18n }}</span>
							</fab-message-bar>
							<fab-message-bar
								*ngIf="this.showErrorMessage"
								[messageBarType]="MessageBarType.error"
								contentClass="wcd-margin-top"
							>
								{{ 'settings.portalRedirection.errorChangingSettingMessage' | i18n }}
							</fab-message-bar>
						</div>
					</div>
				</div>
			</ng-container>
			<ng-container error>
				<div class="wcd-margin-top center-text">
					{{ 'settings.portalRedirection.errorLoadingPageMessage' | i18n }}
				</div>
			</ng-container>
		</wcd-contents-state>
	`,
})
export class PortalRedirectionComponent {
	contentState: ContentState = ContentState.Loading;
	showErrorMessage: boolean = false;
	MessageBarType = MessageBarType;
	isScc = sccHostService.isSCC;
	isPortalRedirectionEnabled: boolean;
	isUserAllowedToControlRedirection: boolean = false;

	constructor(
		private authService: AuthService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private appInsightsService: AppInsightsService,
		private changeDetectorRef: ChangeDetectorRef,
		private portalRedirectionService: PortalRedirectionService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		private performanceSccService: PerformanceSccService
	) {
		this.isUserAllowedToControlRedirection = authService.currentUser.isSecAdmin; // We allow global or security admins to change the toggle state
	}

	ngOnInit() {
		this.portalRedirectionService.getPortalRedirectionSetting()
			.subscribe((IsRedirectionEnabled) => {
				this.isPortalRedirectionEnabled = IsRedirectionEnabled;
				this.contentState = ContentState.Complete;
				this.changeDetectorRef.detectChanges();
			},
				error => (this.contentState = ContentState.Error),
				() => (this.performanceSccService.endNgPageLoadPerfSession('settings-portal-redirection')))
	}

	onToggleChange() {
		const initialPortalRedirectionSettingState = this.isPortalRedirectionEnabled;
		this.isPortalRedirectionEnabled = !this.isPortalRedirectionEnabled;
		this.changeDetectorRef.detectChanges();

		const isHighExposureEnabled = this.featuresService.isEnabled(Feature.HighExposureCoachBubble)

		const dialogueTitle = this.isPortalRedirectionEnabled ? 'settings.portalRedirection.dialogue.enableTitle' : 'settings.portalRedirection.dialogue.disableTitle';
		const dialogueText = this.isPortalRedirectionEnabled ? 'settings.portalRedirection.dialogue.enableText' : `${'settings.portalRedirection.dialogue.disableText'}${isHighExposureEnabled ? '_highExposure':''}`;
		const dialogueConfirmButtonText = this.isPortalRedirectionEnabled ? 'settings.portalRedirection.dialogue.enableConfirmButtonText' : 'settings.portalRedirection.dialogue.disableConfirmButtonText';
		const title = this.i18nService.get(dialogueTitle);
		const text = this.i18nService.get(dialogueText);

		this.dialogsService
			.confirm({
				title,
				text,
				ariaLabel: `${title} ${text}`,
				confirmText: this.i18nService.get(dialogueConfirmButtonText),
				cancelText: this.i18nService.get('settings.portalRedirection.dialogue.cancelText'),
			})
			.then((e: ConfirmEvent) => {
				if (e.confirmed) {
					this.saveChanges(initialPortalRedirectionSettingState);
					if (!this.isPortalRedirectionEnabled && this.appContextService.isSCC)
					{
						// Open Sensei feedback if redirection was turned OFF and only in SCC
						window.SenseiFeedback.ShowUnsolicitedFeedback();
					}
				}
				else {
					// User didn't confirm, return toggle to its initial state
					this.isPortalRedirectionEnabled = initialPortalRedirectionSettingState;
					this.changeDetectorRef.detectChanges();
				}
			});
	}

	saveChanges(initialPortalRedirectionSettingState: boolean) {
		this.portalRedirectionService.setPortalRedirectionSetting(this.isPortalRedirectionEnabled).subscribe(
			() => {
				const eventToTrack = this.isPortalRedirectionEnabled ? ENABLED_MDATP_REDIRECTION : DISABLED_MDATP_REDIRECTION;
				this.appInsightsService.trackEvent(eventToTrack);
				this.showErrorMessage = false; // Remove error message in case call succeeded on a second try
				this.changeDetectorRef.detectChanges();
			},
			() => {
				this.appInsightsService.trackEvent(SAW_ERROR_MESSAGE);
				this.isPortalRedirectionEnabled = initialPortalRedirectionSettingState;
				this.showErrorMessage = true;
				this.changeDetectorRef.detectChanges();
			}
		);
	}
}
