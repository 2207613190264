import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { AppConfigService } from '@wcd/app-config';

@Injectable()
export class ExposedToAllMachineGroupsGuard implements CanActivate {
	constructor(private appConfigService: AppConfigService) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		return this.appConfigService.isExposedToAllMachineGroups;
	}
}
