import { KnownColorsService } from '../../../shared/services/known-colors.service';
var RemediationTaskStatusBarComponent = /** @class */ (function () {
    function RemediationTaskStatusBarComponent(knownColorsService) {
        this.knownColorsService = knownColorsService;
        /**
         * option to display legend under the bar
         * @default false
         */
        this.showLegend = false;
        /**
         * If value == null, bar will not be displayed. If showTitleIfNoBar == false, nothing will be displayed
         * @default true
         */
        this.showTitleIfNoBar = true;
        this.currentValue = null;
    }
    Object.defineProperty(RemediationTaskStatusBarComponent.prototype, "total", {
        /**
         * A number which reflects the total part of the bar. For example, '50' in: "30/50"
         */
        set: function (total) {
            if (!this.currentTotal || this.currentTotal !== total) {
                this.currentTotal = total;
                this.updateStrechedDonutChartItem();
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RemediationTaskStatusBarComponent.prototype, "value", {
        /**
         * A number which reflects the completed part of the bar. For example, '30' in: "30/50"
         */
        set: function (value) {
            if (!this.currentValue || this.currentValue !== value) {
                this.currentValue = value;
                this.updateStrechedDonutChartItem();
            }
        },
        enumerable: true,
        configurable: true
    });
    RemediationTaskStatusBarComponent.prototype.updateStrechedDonutChartItem = function () {
        this.strechedDonutChartItem = [
            {
                id: '',
                title: this.title,
                valueColor: this.knownColorsService.knownColorsMap['blue'],
                totalColor: this.knownColorsService.knownColorsMap['neutralSecondaryAlt'],
                value: this.currentValue,
                total: this.currentTotal,
                width: this.width,
                disableClick: true,
                valuePrefix: this.valuePrefix,
                restPrefix: this.restPrefix,
                isDefaultFull: true,
                tabIndex: null,
            },
        ];
    };
    return RemediationTaskStatusBarComponent;
}());
export { RemediationTaskStatusBarComponent };
