import { ChangeDetectorRef, Component } from '@angular/core';
import { FolderExclusionsStore } from '../services/folder-exclusions.store';
import { FolderExclusionModel } from '../models/folder-exclusion.model';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'folder-exclusions',
	templateUrl: './folder-exclusions.component.html',
})
export class FolderExclusionsComponent {
	newFolderExclusion: FolderExclusionModel;
	folderExclusions: Array<FolderExclusionModel>;

	folderExclusionsItems$: Observable<Array<FolderExclusionModel>> = this.folderExclusionsStore.items$.pipe(
		tap(() => {
			this.performanceSccService.endNgPageLoadPerfSession('endpoints-automation-folder-exclusions');
		})
	);


	constructor(
		public folderExclusionsStore: FolderExclusionsStore,
		public authService: AuthService,
		public i18nService: I18nService,
		private changeDetectorRef: ChangeDetectorRef,
		private performanceSccService: PerformanceSccService
	) {}

	addNewFolderExclusion() {
		this.newFolderExclusion = new FolderExclusionModel();
		this.newFolderExclusion.isEditing = true;
		this.changeDetectorRef.detectChanges();
	}

	clearNew() {
		this.newFolderExclusion = null;
		this.changeDetectorRef.detectChanges();
	}
}
