import { memoize } from 'lodash-es';
import { FabricIconNames } from './fabric-icon-names';

export type FabricIconName = keyof typeof FabricIconNames;

export const fabricIconNames: ReadonlySet<FabricIconName> = new Set<FabricIconName>(
	Object.values(FabricIconNames)
);

const isFabricIconName_ = (iconName: string): iconName is FabricIconName =>
	fabricIconNames.has(iconName as FabricIconName);

export const isFabricIconName: (iconName: string) => iconName is FabricIconName = memoize(isFabricIconName_);
