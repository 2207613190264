import { SevilleModule } from '../../seville/seville.module';

declare let angular: angular.IAngularStatic;

SevilleModule.filter('objectLimitTo', objectLimitToFilter);

function objectLimitToFilter() {
	return function(obj, limit) {
		var keys = Object.keys(obj);
		if (keys.length < 1) {
			return [];
		}

		var ret = new Object(),
			count = 0;
		angular.forEach(keys, function(key, arrayIndex) {
			if (count >= limit) {
				return false;
			}
			ret[key] = obj[key];
			count++;
		});
		return ret;
	};
}
