import { NgZone, EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
var SNACKBAR_SHOW_DURATION = 5000;
var REMOVE_ANIMATION_DURATION = 500;
var TIME_BETWEEN_SNACKBARS = 100;
var SnackbarService = /** @class */ (function () {
    function SnackbarService(ngZone) {
        this.ngZone = ngZone;
        this.snackbarsQueue = [];
        this.isRemovingVisibleSnackbar = false;
        this.snackbarChange = new EventEmitter();
        this.snackbarIsRemovingVisible = new EventEmitter();
    }
    SnackbarService.prototype.add = function (snackbar) {
        if (!snackbar || !snackbar.text) {
            return;
        }
        this.snackbarsQueue.push(snackbar);
        if (!this.visibleSnackbar) {
            this.showNext();
        }
    };
    SnackbarService.prototype.callVisibleSnackbarMethod = function () {
        if (!this.visibleSnackbar || !this.visibleSnackbar.method) {
            return;
        }
        this.closeVisibleSnackbar(true);
        this.visibleSnackbar.method.onExecute(this.visibleSnackbar.data);
    };
    SnackbarService.prototype.showNext = function () {
        var _this = this;
        if (this.snackbarsQueue.length) {
            this.visibleSnackbar = this.snackbarsQueue.shift();
            this.snackbarChange.emit(true);
            setTimeout(function () {
                _this.closeVisibleSnackbar(true);
            }, SNACKBAR_SHOW_DURATION);
        }
        else if (this.visibleSnackbar) {
            this.closeVisibleSnackbar();
        }
    };
    SnackbarService.prototype.closeVisibleSnackbar = function (showNext) {
        var _this = this;
        this.ngZone.run(function () {
            _this.isRemovingVisibleSnackbar = true;
            _this.snackbarIsRemovingVisible.emit(true);
        });
        setTimeout(function () {
            _this.isRemovingVisibleSnackbar = false;
            _this.snackbarIsRemovingVisible.emit(false);
            _this.visibleSnackbar = null;
            _this.snackbarChange.emit(false);
            if (showNext) {
                setTimeout(function () {
                    _this.showNext();
                }, TIME_BETWEEN_SNACKBARS);
            }
        }, REMOVE_ANIMATION_DURATION);
    };
    SnackbarService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SnackbarService_Factory() { return new SnackbarService(i0.ɵɵinject(i0.NgZone)); }, token: SnackbarService, providedIn: "root" });
    return SnackbarService;
}());
export { SnackbarService };
