/* tslint:disable:template-accessibility-label-for template-accessibility-alt-text */
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	Output,
	ViewChild,
	ElementRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent as WcdDropdownComponent } from '@wcd/forms';
import { Disableable, DISABLEABLE_TOKEN } from '../../shared/interfaces/disableable.interface';
import { isObject, get, find, isEqual } from 'lodash-es';
import { BrowserDetectionService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { Positions } from '@wcd/forms';

let lastId = 0;

@Component({
	selector: 'fancy-select',
	template: `
		<span
			[id]="listId"
			[ngClass]="{ 'disabled-not-allowed': isDisabled }"
			[wcdTooltip]="isDisabled && disableReason"
		>
			<label *ngIf="dropdownLabel" class="dropdown-label">{{ dropdownLabel }}</label>
			<wcd-dropdown
				[position]="openMenuPosition"
				[buttonText]="useValueAsButtonText && value ? getLabel(value) : placeholder"
				[buttonClass]="labelClass"
				[buttonTooltip]="tooltip"
				[buttonIconClass]="getAttributeValue(value, iconColorClassName)"
				[buttonImage]="getAttributeValue(value, image) || buttonImage"
				[buttonIcon]="getAttributeValue(value, icon) || buttonIcon"
				[iconButtonOnly]="iconButtonOnly"
				(focus)="focus.emit()"
				(blur)="blur.emit()"
				(exit)="exit.emit()"
				(onToggle)="onToggle($event)"
				[closeOnClick]="true"
				[disabled]="isDisabled"
				[showIconDropdown]="showIconDropdown"
				[isBordered]="isBordered"
				[isFullWidth]="isFullWidth"
				[ariaLabel]="ariaLabel"
				[navigateUsingArrowKeysOnly]="navigateUsingArrowKeysOnly"
				[navigateUsingTab]="navigateUsingTab"
				[optionAriaSetSize]="(values?.length || 0) + (specialValues?.length || 0)"
				[optionAriaRole]=" ['combobox','button'].includes(dropdownAreaRole) ? null : 'option'"
				[optionAriaPositionInSet]="ariaPositinInSet"
				[ariaRole]="dropdownAreaRole"
				[buttonId]="buttonId"
				[ariaHaspopup]="ariaHaspopup"
				[focusOnInit]="focusOnInit"
				[required]="required"
			>
				<ul
					#dropDownContent
					class="dropdown-list"
					[attr.data-test-id]="listId + '-menu'"
					role="listbox"
					[attr.aria-labelledby]="buttonId + '_ariaLabel'"
				>
					<li
						*ngFor="let _value of values; let valueIndex = index"
						(click)="selectValue(_value, valueIndex)"
						[wcdTooltip]="getAttributeValue(_value, itemTooltip)"
						[attr.data-test-id]="getAttributeValue(_value, itemTestId)"
						[class.selected]="highlightSelected && _value === value"
						[ngClass]="getAttributeValue(_value, optionClass) || ''"
						#accessibleListItem
						tabindex="0"
						[attr.aria-selected]="_value === value"
						role="option"
						[attr.aria-posinset]="valueIndex + 1"
						[attr.aria-setsize]="values.length"
					>
						<div class="dropdown-list-item-contents">
							<wcd-shared-icon
								*ngIf="icon"
								[iconName]="getAttributeValue(_value, icon)"
								class="dropdown-list-icon"
								[ngClass]="getAttributeValue(_value, iconColorClassName)"
								aria-hidden="true"
							>
							</wcd-shared-icon>
							<img
								*ngIf="image"
								[attr.src]="getAttributeValue(_value, image)"
								class="dropdown-list-icon"
							/>
							{{ getLabel(_value) }}
						</div>
					</li>
					<li
						*ngFor="let specialValue of specialValues; let specialValueIndex = index"
						(click)="onSelectSpecialValue(specialValue, values.length + specialValueIndex)"
						[attr.data-test-id]="'btn-' + specialValue.id"
						#accessibleListItem
						tabindex="0"
						[attr.aria-selected]="_value === value"
						role="option"
						[attr.aria-posinset]="values.length + specialValueIndex + 1"
						[attr.aria-setsize]="values.length + specialValues.length"
					>
						<div class="dropdown-list-item-contents">
							<wcd-shared-icon
								*ngIf="specialValue.icon"
								[iconName]="specialValue.icon"
								class="dropdown-list-icon"
							>
							</wcd-shared-icon>
							{{ specialValue.name }}
						</div>
					</li>
				</ul>
			</wcd-dropdown>
		</span>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => FancySelectComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => FancySelectComponent),
		},
	],
})
export class FancySelectComponent implements ControlValueAccessor, OnChanges, Disableable {
	@Input() highlightSelected: boolean = true;
	@Input() dropdownLabel: string;
	@Input() label: string;
	@Input() labelI18nKey: string;
	@Input() labelClass: string;
	@Input() formatLabel: (value: any) => string;
	@Input() placeholder: string;
	@Input() image: string;
	@Input() buttonIcon: string;
	@Input() buttonImage: string;
	@Input() iconButtonOnly: boolean = false;
	@Input() icon: string;
	@Input() iconColorClassName: string;
	@Input() tooltip: string;
	@Input() itemTooltip: string;
	@Input() optionClass: string;
	@Input() itemTestId: string;
	@Input() values: Array<any>;
	@Input() specialValues: Array<SpecialValue> = [];
	@Input() listId: string = `fancy-select-${lastId++}`;
	@Input() showIconDropdown: boolean = true;
	@Input() trackBy: string;
	@Input() useValueAsButtonText: boolean = true;
	@Input() isDisabled: boolean = false;
	@Input() focusOnInit = false;
	@Input() focusButtonOnClose = true;
	@Input() isBordered = false;
	@Input() isFullWidth = false;
	@Input() ariaLabel: string;
	@Input() navigateUsingArrowKeysOnly: boolean = false;
	@Input() navigateUsingTab: boolean = false;
	@Input() dropdownAreaRole: string = 'listbox';
	@Input() ariaHaspopup: string = 'listbox';
	@Input() openMenuPosition: Positions = Positions.Default;
	@Input() required?;

	@Output() selectSpecialValue: EventEmitter<SpecialValue> = new EventEmitter<SpecialValue>();
	@Output() focus: EventEmitter<void> = new EventEmitter<void>();
	@Output() blur: EventEmitter<void> = new EventEmitter<void>();
	@Output() exit = new EventEmitter<void>();

	@ViewChild(WcdDropdownComponent, { static: false }) dropdown: WcdDropdownComponent;
	@ViewChild('dropDownContent', { static: false }) dropDownContent: ElementRef<HTMLDivElement>;

	value: any;
	disableReason: string;

	ariaPositinInSet: number = 0;

	buttonId = 'button_' + this.listId;
	private _tempValue: any;

	constructor(
		private changeDetectionRef: ChangeDetectorRef,
		private browserDetect: BrowserDetectionService,
		private i18nService: I18nService
	) {}

	ngOnChanges(changes) {
		if (changes.values) this.writeValue(this._tempValue || this.value);
	}

	selectValue(value, index: number = 0) {
		this.value = value;
		this.ariaPositinInSet = index + 1;
		this.onChange(this.value);
	}

	onSelectSpecialValue(specialValue: SpecialValue, index: number = 0) {
		this.ariaPositinInSet = index + 1;
		this.selectSpecialValue.emit(specialValue);
	}

	getAttributeValue(_value: any, attributePath: string) {
		if (!attributePath) return null;

		return isObject(_value) ? get(_value, attributePath) : _value;
	}

	getLabel(value: any): string {
		if (this.formatLabel) return this.formatLabel(value);
		return this.labelI18nKey
			? this.i18nService.get(this.getAttributeValue(value, this.labelI18nKey))
			: this.label
				? this.getAttributeValue(value, this.label)
				: String(value);
	}

	onChange = (_: any) => {};
	onTouched = () => {};

	writeValue(value: any): void {
		const existingValue: any = this.getValue(value);

		if (existingValue) this.value = existingValue;
		else if (!isObject(value) && this.trackBy && this.values)
			this.value = find(this.values, _value => _value[this.trackBy] === value);

		if (this.value) {
			if (!value) this.value = null;
		} else {
			if (!this.values) this._tempValue = value;
			else this.value = null;
		}
		this.ariaPositinInSet = this.getValuePosition();
		this.changeDetectionRef.markForCheck();
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	toggle() {
		this.dropdown.toggle();
	}

	onToggle(isOpen) {
		// This is to enable correct focus on Edge when Narrator is on.
		if (this.browserDetect.browserData.isEdge) {
			setTimeout(() => {
				if (isOpen) {
					const selectedItem: HTMLElement = <HTMLElement>(
						this.dropDownContent.nativeElement.querySelector('li.selected')
					);
					if (selectedItem) {
						selectedItem.focus();
					}
				}
			}, 100);
		}
	}

	getValuePosition(): number {
		if (!this.value) return 0;
		let index = this.values.indexOf(this.value);

		if (index < 0) {
			index = this.specialValues.indexOf(this.value);
			index += index >= 0 ? this.values.length : 0;
		}

		return index + 1;
	}

	private getValue(value: any): any {
		if (!this.values || value === undefined || value === null) return null;

		return find(this.values, _value => {
			if (this.trackBy && isObject(value) && isObject(_value))
				return value[this.trackBy] === _value[this.trackBy];

			return this.valuesAreEqual(_value, value);
		});
	}

	/**
	 * Testing equality in values, but without private members!
	 * @param val1
	 * @param val2
	 * @returns {boolean}
	 */
	private valuesAreEqual(val1, val2): boolean {
		const equals: boolean = isEqual(val1, val2);
		// since lodash's isEqual compares the objects without inherited properties,
		// only make deep comparison in case it considers the objects equal
		// one exception: don't compare Paris's $parent property
		if (equals) {
			if (isObject(val1) && isObject(val2)) {
				for (const p in val1) {
					if (!val1.hasOwnProperty(p)) return !val2.hasOwnProperty(p);
					else {
						if (!val2.hasOwnProperty(p)) return false;
						if (/^[^_]/.test(p) && !/\$parent/.test(p)) {
							if (!this.valuesAreEqual(val1[p], val2[p])) return false;
						}
					}
				}
				return true;
			}
		}
		return equals;
	}
}

export interface SpecialValue {
	id: any;
	name: string;
	data?: any;
	icon?: string;
}
