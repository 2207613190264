var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { convertOfficeActionToInvestigationAction, InvestigationAction } from './investigation-action.entity';
import { OfficeUtils } from '../../utils/office-utils';
import { get } from 'lodash-es';
import { sccHostService } from '@wcd/scc-interface';
var OFFICE_ALERT_PREFIX = "fa";
var ɵ0 = function (itemId, entity, config, params) {
    // There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
    return "Find/MtpBatch?tenantid=" + params.tenantId + "&PageSize=50&Filter=ModelType eq 1 and UrnProp eq '" + itemId + "'";
}, ɵ1 = function (config) {
    return sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>';
}, ɵ2 = function (data) {
    var rawData = get(data, 'ResultData[0].InvestigationActionPayload[0]');
    var convertedRawData = OfficeUtils.convertDataFromAlertV3(rawData);
    var actionData = convertOfficeActionToInvestigationAction(convertedRawData);
    return __assign({}, convertedRawData, actionData);
}, ɵ3 = function (relatedAlerts) { return relatedAlerts && relatedAlerts.map(function (a) { return OFFICE_ALERT_PREFIX + a; }); };
var OfficeInvestigationAction = /** @class */ (function (_super) {
    __extends(OfficeInvestigationAction, _super);
    function OfficeInvestigationAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Description' }),
        __metadata("design:type", String)
    ], OfficeInvestigationAction.prototype, "description", void 0);
    __decorate([
        EntityField({
            data: 'RelatedAlertIds',
            parse: ɵ3,
        }),
        __metadata("design:type", Array)
    ], OfficeInvestigationAction.prototype, "relatedAlerts", void 0);
    OfficeInvestigationAction = __decorate([
        Entity({
            singularName: 'Investigation Action',
            pluralName: 'Investigation Actions',
            endpoint: 'autoir/officeui/action',
            allItemsProperty: 'results',
            separateArrayParams: true,
            parseItemQuery: ɵ0,
            baseUrl: ɵ1,
            parseData: ɵ2,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
        })
    ], OfficeInvestigationAction);
    return OfficeInvestigationAction;
}(InvestigationAction));
export { OfficeInvestigationAction };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
