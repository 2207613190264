<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="canDeactivate">
	<div class="large-form wcd-auto-height-responsive wcd-full-height wcd-textarea-placeholder">
		<form *ngIf="alerts" #linkToIncidentForm="ngForm" (submit)="submit()" class="wcd-full-height wcd-flex-vertical" data-track-component="linkToIncidentForm">
			<header class="wcd-flex-none wcd-padding-all">
				<div class="wcd-flex-center-vertical wcd-flex-horizontal">
					<div class="page-header-icon wcd-flex-none">
						 <wcd-shared-icon class="round-icon color-box-gray-200" iconName="incident"> </wcd-shared-icon >
					</div>
					<div class="wcd-flex-1">
						<h3 #panelHeader class="side-panel-title">{{'alerts.linkToIncident.fullTitle' | i18n}}</h3>
					</div>
				</div>
			</header>
			<section class="wcd-padding-vertical wcd-padding-large-horizontal">
				<div class="color-box-gray-100 wcd-flex-horizontal wcd-padding-small-all wcd-flex-center-vertical"
					 *ngIf="areLinkedToSameIncident">
					<div class="wcd-flex-none wcd-padding-small-right">
						 <wcd-shared-icon iconName="Info" class="small-icon"> </wcd-shared-icon >
					</div>
					<div class="wcd-flex-1">
						{{ currentLinkedMessage}}
						<a [routerLink]="['/incidents', alerts[0].incidentId]"
						   [attr.aria-label]="currentLinkedMessage + ' ' + (alerts[0].incidentName || alerts[0].incidentId)"
						   data-track-id="CurrentLinkedIncident"
						   data-track-type="Navigation"
						   class="message-bar-link">
							{{ alerts[0].incidentName || alerts[0].incidentId }}
						</a>
					</div>
				</div>
			</section>
			<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-vertical wcd-padding-large-horizontal">
				<section class="wcd-margin-large-bottom">
					<div class="form-group">
						<wcd-radiolist [values]="selectableActions"
									name="link-action"
									[(ngModel)]="action"></wcd-radiolist>
					</div>
					<div class="form-group" [hidden]="action.id !== 'existing'">
						<wcd-search (select)="setIncident($event)"
								(searchTermCleared)="clearIncident()"
								class="wcd-margin-small-vertical wcd-full-width"
								[fullWidth]="true"
								[settings]="searchSettings"
								[searchFunction]="getSearchIncidentsMethod"
								placeholder="{{ 'alerts.linkToIncident.search.description' | i18n}}"></wcd-search>
					</div>
					<div class="form-group wcd-margin-large-top">
						<label for="link-comment" class="required">
							{{'alerts.linkToIncident.comment.title' | i18n}}
						</label>
						<textarea
							id="link-comment"
							class="form-control wcd-full-width link-comment"
							name="comment"
							rows="4"
							required
							placeholder="{{'alerts.linkToIncident.comment.description' | i18n}}"
							[(ngModel)]="comment"
							[rbac]="rbacSettings"></textarea>
					</div>
				</section>
			</div>
			<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
				<div class="wcd-flex-1 wcd-margin-right">
					<button class="btn btn-primary"
							[rbac]="rbacSettings"
							[disabled]="linkToIncidentForm.form.pristine || !linkToIncidentForm.form.valid || !isValid || isSaving"
							data-track-id="SaveLinkToIncident"
							data-track-type="Button"
							type="submit">
						<i class="loader-icon" [hidden]="!isSaving"></i>
						{{ 'buttons.' + (isSaving ? 'saving' : 'save') | i18n }}
					</button>
				</div>
				<div class="wcd-flex-none">
					<button class="btn btn-cancel"
							data-track-id="Cancel"
							data-track-type="Button"
							type="button"
							(click)="destroy()">
						{{'buttons.cancel' | i18n}}
					</button>
				</div>
			</div>
		</form>
	</div>
</wcd-panel>
