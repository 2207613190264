import { MtpPermission } from "./mtp-permission-enum";

export enum UnifiedRbacPermission {
	SecOpsSecurityDataRead = 'microsoft.xdr/secops/securitydata/read',
	SecOpsSecurityDataAlertsManage = 'microsoft.xdr/secops/securitydata/alerts/manage',
	SecOpsSecurityDataResponseManage = 'microsoft.xdr/secops/securitydata/response/manage',
	SecOpsSecurityDataEmailQuarantineManage = 'microsoft.xdr/secops/securitydata/emailquarantine/manage',
	SecOpsSecurityDataEmailAdvancedOptionsManage = 'microsoft.xdr/secops/securitydata/emailadvancedactions/manage',
	SecOpsSecurityDataLiveResponseBasicManage = 'microsoft.xdr/secops/securitydata/liveresponse-basic/manage',
	SecOpsSecurityDataLiveResponseAdvancedManage = 'microsoft.xdr/secops/securitydata/liveresponse-advanced/manage',
	SecOpsRawDataHuntingSchemaRead = 'microsoft.xdr/secops/rawdata/huntingschema/read',
	SecOpsRawDataEmailContentRead = 'microsoft.xdr/secops/rawdata/emailcontent/read',
	SecPostureTvmRead = 'microsoft.xdr/securityposture/tvm/read',
	SecPostureTvmExceptionHandlingManage = 'microsoft.xdr/securityposture/tvm/exceptionhandling/manage',
	SecPostureTvmRemediateManage = 'microsoft.xdr/securityposture/tvm/remediate/manage',
	SecPostureTvmApplicationHandlingManage = 'microsoft.xdr/securityposture/tvm/applicationhandling/manage',
	ConfigurationAuthorizationRead = 'microsoft.xdr/configuration/authorization/read',
	ConfigurationAuthorizationManage = 'microsoft.xdr/configuration/authorization/manage',
	ConfigurationSecurityRead = 'microsoft.xdr/configuration/security/read',
	ConfigurationSecurityManage = 'microsoft.xdr/configuration/security/manage',
	ConfigurationSystemRead = 'microsoft.xdr/configuration/system/read',
	ConfigurationSystemManage = 'microsoft.xdr/configuration/system/manage',
	ConfigurationMemSecuritySettingsManage = 'microsoft.xdr/configuration/MEMsecuritysettings/manage',
}

export type RbacPermission = MtpPermission | UnifiedRbacPermission;
