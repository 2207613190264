import { Dictionary } from '@wcd/config';
import { MdeUserRoleAction } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
var UserRoleActionsService = /** @class */ (function () {
    function UserRoleActionsService(paris) {
        this.paris = paris;
    }
    Object.defineProperty(UserRoleActionsService.prototype, "userRoleActionsMap", {
        get: function () {
            if (!this._userRoleActionsMap) {
                var userRoleActionsRepo = this.paris.getRepository(MdeUserRoleAction);
                this._userRoleActionsMap = Dictionary.fromList(MdeUserRoleAction.flatten(userRoleActionsRepo.entity.values), 'name');
            }
            return this._userRoleActionsMap;
        },
        enumerable: true,
        configurable: true
    });
    return UserRoleActionsService;
}());
export { UserRoleActionsService };
