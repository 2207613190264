<fab-date-picker
	[pickerAriaLabel]="ariaLabel"
	[ariaLabel]="ariaLabel"
	[disabled]="disabled"
	[isMonthPickerVisible]="isMonthPickerVisible"
	[minDate]="minDate"
	[maxDate]="maxDate"
	[formatDate]="formatDate"
	(onSelectDate)="emitDate($event)"
	[placeholder]="placeHolder"
	[disableAutoFocus]="disableAutoFocus">
</fab-date-picker>
