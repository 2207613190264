var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function (itemId, entity, config, params) {
    if (params === void 0) { params = {}; }
    return "GetPreviewExperienceSetting?context=" + (params.context || 'MdatpContext');
}, ɵ1 = function (value) {
    return "SavePreviewExperienceSetting?context=" + (value.context || 'MdatpContext');
}, ɵ2 = function (value) {
    return { IsOptIn: value.previewExperienceEnabled };
}, ɵ3 = function (config) { return config.data.serviceUrls.settings; }, ɵ4 = function (value, data) {
    return data.SliceId === PreviewExperienceSlice.PublicPreview ||
        data.SliceId === PreviewExperienceSlice.GA ||
        data.SliceId === PreviewExperienceSlice.MtpPublicPreview ||
        data.SliceId === PreviewExperienceSlice.MtpGa
        ? value
        : true;
}, ɵ5 = function (value, data) {
    return value !== PreviewExperienceSlice.PublicPreview &&
        value !== PreviewExperienceSlice.GA &&
        value !== PreviewExperienceSlice.MtpPublicPreview &&
        value !== PreviewExperienceSlice.MtpGa;
};
var PreviewExperienceSettings = /** @class */ (function (_super) {
    __extends(PreviewExperienceSettings, _super);
    function PreviewExperienceSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            data: 'IsOptIn',
            parse: ɵ4,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], PreviewExperienceSettings.prototype, "previewExperienceEnabled", void 0);
    __decorate([
        EntityField({
            data: 'SliceId',
            parse: ɵ5,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], PreviewExperienceSettings.prototype, "forcedEnabled", void 0);
    __decorate([
        EntityField({
            data: 'SliceId',
        }),
        __metadata("design:type", Number)
    ], PreviewExperienceSettings.prototype, "sliceId", void 0);
    PreviewExperienceSettings = __decorate([
        Entity({
            singularName: 'Preview experience settings',
            pluralName: 'Preview experience settings',
            endpoint: 'GetPreviewExperienceSetting',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            serializeItem: ɵ2,
            readonly: true,
            saveMethod: 'POST',
            baseUrl: ɵ3,
            supportedEntityGetMethods: [EntityGetMethod.getItem],
        })
    ], PreviewExperienceSettings);
    return PreviewExperienceSettings;
}(EntityModelBase));
export { PreviewExperienceSettings };
var PreviewExperienceSlice;
(function (PreviewExperienceSlice) {
    PreviewExperienceSlice[PreviewExperienceSlice["PublicPreview"] = 40] = "PublicPreview";
    PreviewExperienceSlice[PreviewExperienceSlice["MtpPublicPreview"] = 1040] = "MtpPublicPreview";
    PreviewExperienceSlice[PreviewExperienceSlice["GA"] = 100] = "GA";
    PreviewExperienceSlice[PreviewExperienceSlice["MtpGa"] = 1100] = "MtpGa";
})(PreviewExperienceSlice || (PreviewExperienceSlice = {}));
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
