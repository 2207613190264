
		<dataview
			class="wcd-full-height"
			id="incident-alerts"
			*ngIf="this.incidentAlertsRepository?.sourceItem; else noData"
			[disabledFields]="['incident']"
			[padLeft]="false"
			commandBarNoPadding="true"
			[repository]="incidentAlertsRepository"
			[entityType]="alertEntityType"
			[allowFilters]="allowFilters"
			[visibleFields]="visibleFields"
			[fields]="fields"
			[hideControls]="hideControls"
			[navigateOnChange]="navigateOnChange"
			[allowGroupItems]="isGroupedView()"
			[isItemGroup]="isGroup"
			[getGroupItems]="boundGetContainedAlerts"
			[fixedOptions]="dataviewFixedOptions"
			[allowPaging]="allowPaging"
			[fixedTable]="fixedTable"
			[label]="'alerts.title' | i18n"
			[dataViewConfig]="dataViewConfig"
			[getFilterQueryOptions]="alertEntityType.dataViewOptions.getFilterQueryOptions"
			defaultSortFieldId="firstEventTime"
			[className]="isScc ? '' : 'wcd-entity-page-content'"
			[sortDisabledFields]="sortDisabledFields"
			[ignoreQueryParams]="ignoreQueryParams"
			(filtersChange)="onFiltersChanged($event)"
			[removePadding]="true"
			(onSortChanged)="onSortChanged($event)"
			[shouldShowPageNumbersOnPagination]="true"
			[responsiveActionBar]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
		>
			<span dataview-controls-right *ngIf="featuresService.isEnabled(Feature.NestedAlertsInIncident)">
				<fancy-select
					[(ngModel)]="alertsViewType"
					(ngModelChange)="onAlertTypeChange()"
					class="command-bar-item-dropdown"
					labelI18nKey="nameI18nKey"
					icon="icon"
					[values]="alertTypeValues"
				></fancy-select>
			</span>
		</dataview>
		<ng-template #noData>
			<div class="wcd-full-height wcd-flex-center-all">
				<div class="widget-nodata-message">{{ 'alerts.titleNoData' | i18n }}</div>
			</div>
		</ng-template>
	