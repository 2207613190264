// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'

import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { NetworkDevice, NetworkDevicesConnectionState } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { FabricIconNames } from '@wcd/scc-common';
import { I18nService } from '@wcd/i18n';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';

@Injectable()
export class NetworkDevicesFieldsService {
	private _fields: Array<DataviewField<NetworkDevice>>;

	constructor(
		private tzDateService: TzDateService,
		private i18nService: I18nService,
		private tvmWeaknessSeverityService: TvmWeaknessSeverityService,
		private tvmColorService: TvmColorService,
	) { }

	get fields(): Array<DataviewField<NetworkDevice>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<NetworkDevice>([
				{
					id: 'ipAddress',
					name: this.i18nService.get('tvm_network_devices_ip'),
				},
				{
					id: 'deviceName',
					name: this.i18nService.get('tvm_network_devices_name'),
					getDisplay: (networkDevice: NetworkDevice) => networkDevice.deviceName || this.i18nService.get('tvm_network_devices_unknown_device_name')
				},
				{
					id: 'status',
					name: this.i18nService.get('tvm_network_devices_status'),
					getDisplay: (networkDevice: NetworkDevice) => {
						if (networkDevice.status === NetworkDevicesConnectionState.Active) {
							return this.i18nService.get('tvm_network_devices_active_status');
						}
						return this.i18nService.get('tvm_network_devices_inactive_status');
					},
					icon: {
						fabricIcon: (networkDevice: NetworkDevice) => networkDevice.status !== NetworkDevicesConnectionState.Active ? FabricIconNames.StatusCircleInner : null,
						className: (networkDevice: NetworkDevice) => networkDevice.status !== NetworkDevicesConnectionState.Active ? `ms-fontColor-${this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name} field-value-font-size-mi` : "",
					}
				},
				{
					id: 'deviceType',
					name: this.i18nService.get('tvm_network_devices_device_type'),

				},
				{
					id: 'vendor',
					name: this.i18nService.get('tvm_network_devices_vendor'),
				},
				{
					id: 'os',
					name: this.i18nService.get('tvm_network_devices_os'),
				},
				{
					id: 'osVersion',
					name: this.i18nService.get('tvm_network_devices_os_version'),
				},
				{
					id: 'exposureLevel',
					name: this.i18nService.get('tvm_network_devices_exposure_level'),
					description: this.i18nService.get('machines.exposureLevel'),
					icon: {
						fabricIcon: (networkDevice: NetworkDevice) => networkDevice.exposureLevel ? FabricIconNames.Warning : null,
						className: (networkDevice: NetworkDevice) => this.tvmWeaknessSeverityService.getWeaknessColorClass(networkDevice.exposureLevel),
					},
				},
				{
					id: 'cvesCount',
					name: this.i18nService.get('tvm_network_devices_cves'),
				},
				{
					id: 'lastScanned',
					name: this.i18nService.get('tvm_network_devices_last_scanned'),
					getDisplay: (networkDevice: NetworkDevice) => {
						return this.tzDateService.format(networkDevice.lastScanned, 'short');
					},
				},
				{
					id: 'firstSeen',
					name: this.i18nService.get('tvm_network_devices_first_seen'),
					getDisplay: (networkDevice: NetworkDevice) => {
						return this.tzDateService.format(networkDevice.firstSeen, 'short');
					},
					enabledByDefault: false,
				},
			]);
		}
		return this._fields;
	}
}
