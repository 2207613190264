import { Input, Provider, Type } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { Observable } from 'rxjs';
import { NextButtonModeEnum } from './next-button-mode.enum';

export const SHARED_FORM_PROVIDER: Provider = {
	provide: ControlContainer,
	useExisting: NgForm,
};

export class WizardBaseStep<T> {
	@Input() public data: T;
	setStepTrackingProperties: (properties: Map<string, string>) => void;
	setStepValidation: (isValid: boolean) => void;
	nextStep: () => void;
	previousStep: () => void;
	goToStep: (step: number) => void;
	setOnNext: (onNext: () => Observable<boolean>) => void;
	markWizardCompleted: (completeComponent?: Type<WizardBaseStep<T>>) => void;
	setBackButtonEnabled: (enabled: boolean) => void;
	setNextButtonMode: (mode: NextButtonModeEnum, text?: string) => void;
}
