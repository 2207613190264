var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReadOnlyStore } from './readonly-store.base';
import { Observable } from 'rxjs';
import { ItemActionModel } from '../../dataviews/models/item-action.model';
import { SnackBarMethod } from '../../dialogs/snackbars/models/snackbar-method.model';
import { findIndex } from 'lodash-es';
import { map, share } from 'rxjs/operators';
var Store = /** @class */ (function (_super) {
    __extends(Store, _super);
    function Store(backendService, ngZone, itemConstructor, dialogsService, i18nService, options) {
        var _this = _super.call(this, backendService, ngZone, itemConstructor, options) || this;
        _this.backendService = backendService;
        _this.ngZone = ngZone;
        _this.itemConstructor = itemConstructor;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.changes$ = new Observable(function (observer) {
            _this._changesObserver = observer;
        }).pipe(share());
        return _this;
    }
    Store.prototype.save = function (item, apiOptions, showNotification, focusableQuery) {
        var _this = this;
        if (showNotification === void 0) { showNotification = true; }
        if (focusableQuery === void 0) { focusableQuery = null; }
        item.isSaving = true;
        var observable, backendData;
        try {
            backendData = item.getBackendData();
        }
        catch (e) {
            var errorTitle_1 = this.i18nService.get('store_base_error_failed_save', { itemName: this.options.itemNameSingular });
            this.dialogsService.showError({
                title: errorTitle_1,
                data: e,
            });
            item.isSaving = false;
            return new Promise(function (resolve, reject) {
                reject(errorTitle_1);
            });
        }
        var isNewItem = item.isNew;
        if (item.isNew) {
            observable = this.backendService.create(backendData, apiOptions).pipe(map(function (data) {
                var newItem = _this.createItem(data);
                if (_this._items) {
                    _this._items.push(newItem);
                    _this.itemsDictionary.set(newItem.id, newItem);
                }
                if (_this.options.cacheItems)
                    _this.itemsDictionary.set(newItem.id, newItem);
                _this.ngZone.run(function () {
                    _this._itemsObserver &&
                        _this._itemsObserver.next(_this._items ? _this._items.slice(0) : [newItem]);
                    _this._changesObserver &&
                        _this._changesObserver.next({
                            change: StoreModelChangeType.created,
                            item: newItem,
                        });
                });
                return newItem;
            }));
        }
        else {
            observable = this.backendService.update(item.id, backendData, apiOptions).pipe(map(function (data) {
                var newItem;
                if (!data || typeof data !== 'object')
                    newItem = item;
                else {
                    data.id = item.id;
                    newItem = _this.createItem(data);
                }
                if (_this._items) {
                    var currentItemIndex = findIndex(_this._items, function (_item) { return _item.id === item.id; });
                    if (~currentItemIndex)
                        _this._items[currentItemIndex] = newItem;
                    else
                        _this._items.push(newItem);
                    if (_this.options.cacheItems)
                        _this.itemsDictionary.set(newItem.id, newItem);
                }
                _this.ngZone.run(function () {
                    _this._itemsObserver &&
                        _this._itemsObserver.next(_this._items ? _this._items.slice(0) : [item]);
                    _this._changesObserver &&
                        _this._changesObserver.next({
                            change: StoreModelChangeType.updated,
                            item: newItem,
                        });
                });
                return newItem;
            }));
        }
        return observable
            .toPromise()
            .then(function (item) {
            if (_this.options.showSnackbarOnUpdate !== false && showNotification) {
                _this.dialogsService.showSnackbar({
                    text: _this.options.itemNameSingular + " " + (item.name ? '"' + item.name + '" ' : '') + "was " + (isNewItem ? 'created' : 'updated'),
                    icon: 'check',
                    className: 'color-box-successBackground',
                    focusableQuery: focusableQuery,
                });
            }
            item.isSaving = false;
            return Promise.resolve(item);
        })
            .catch(function (error) {
            var errorMessage = _this.i18nService.get("store_base_error_failed_" + (item.isNew ? 'create' : 'update'), { itemNameS: _this.options.itemNameSingular });
            _this.dialogsService.showError({
                title: errorMessage,
                data: error,
            });
            item.isSaving = false;
            return new Promise(function (resolve, reject) {
                reject(errorMessage);
            });
        });
    };
    Store.prototype.updateItems = function (updateData, items, options) {
        return this.backendService.updateMultiple(updateData, items.map(function (item) { return item.id; }), options);
    };
    Store.prototype.remove = function (item, requireConfirm, apiOptions) {
        var _this = this;
        if (requireConfirm === void 0) { requireConfirm = true; }
        if (requireConfirm) {
            var itemName = item.name;
            var itemNameSingular = this.options.itemNameSingular;
            return this.dialogsService
                .confirm({
                title: this.i18nService.get('store_base_delete_dialog_title', { itemNameSingular: itemNameSingular }),
                text: itemName ?
                    this.i18nService.get('store_base_delete_message_with_name', { itemName: itemName, itemNameSingular: itemNameSingular }) :
                    this.i18nService.get('store_base_delete_message', { itemNameSingular: itemNameSingular }),
                confirmText: this.i18nService.get('store_base_confirm_delete'),
            })
                .then(function (e) {
                if (e.confirmed) {
                    return new Promise(function (resolve, reject) {
                        _this.ngZone.run(function () {
                            _this._remove(item, true, apiOptions).then(function (item) { return resolve(item); }, function (error) { return reject(error); });
                        });
                    });
                }
            });
        }
        else
            return this._remove(item, true, apiOptions);
    };
    Store.prototype.removeItems = function (items, requireConfirm, apiOptions) {
        var _this = this;
        if (requireConfirm === void 0) { requireConfirm = true; }
        return new Promise(function (resolve, reject) {
            var remove = function () {
                Promise.all(items.map(function (item) { return _this._remove(item, false, apiOptions); })).then(function (items) {
                    _this.ngZone.run(function () {
                        resolve(items);
                        _this.dialogsService.showSnackbar({
                            text: items.length === 1 ?
                                _this.i18nService.get('store_base_deleted_snackbar_single', { itemNameSingular: _this.options.itemNameSingular }) :
                                _this.i18nService.get('store_base_deleted_snackbar_multiple', { itemNamePlural: _this.options.itemNamePlural, items: items.length }),
                            icon: 'delete',
                            iconClassName: 'color-text-error',
                        });
                    });
                }, onError);
            };
            var onError = function (error) {
                _this.ngZone.run(function () {
                    _this.dialogsService.showError({
                        title: items && items.length == 1 ?
                            _this.i18nService.get('store_base_failed_delete_singular', { itemNameSingular: _this.options.itemNameSingular }) :
                            _this.i18nService.get('store_base_failed_delete_multiple', { itemNamePlural: _this.options.itemNamePlural }),
                        data: error,
                    });
                    reject(error);
                });
            };
            console.log('requireConfirm', requireConfirm);
            if (requireConfirm) {
                var itemName = items.length === 1
                    ? "" + (items[0].name
                        ? _this.options.itemNameSingular + " '" + items[0].name + "'"
                        : 'this ' + _this.options.itemNameSingular)
                    : "these " + items.length + " " + _this.options.itemNamePlural;
                _this.dialogsService
                    .confirm({
                    title: "Delete " + (items.length === 1
                        ? _this.options.itemNameSingular
                        : items.length + ' ' + _this.options.itemNamePlural),
                    text: "Are you sure you wish to delete " + itemName + "?",
                    confirmText: 'Delete',
                })
                    .then(function (e) { return (e.confirmed ? remove() : resolve()); });
            }
            else
                remove();
        });
    };
    Store.prototype.getRemoveItemAction = function () {
        var _this = this;
        return new ItemActionModel({
            id: 'delete',
            name: 'Delete',
            icon: 'delete',
            method: function (items) { return _this.removeItems(items, true); },
            tooltip: 'Delete the selected ' + this.options.itemNamePlural,
            refreshOnResolve: false,
        });
    };
    Store.prototype._remove = function (item, showSnackbar, apiOptions) {
        var _this = this;
        if (showSnackbar === void 0) { showSnackbar = true; }
        item.isDeleting = true;
        return this.backendService
            .remove(item.id, apiOptions)
            .pipe(map(function () {
            item.isDeleting = false;
            if (_this._items) {
                var currentItemIndex = _this._items.indexOf(item);
                _this._items.splice(currentItemIndex, 1);
                _this._items = _this._items.slice();
                _this._itemsObserver.next(_this._items);
            }
            _this._changesObserver &&
                _this._changesObserver.next({
                    change: StoreModelChangeType.removed,
                    item: item,
                });
            if (showSnackbar) {
                var itemName = _this.options.itemNameSingular.toLowerCase() +
                    (item.name ? " '" + item.name + "'" : '');
                _this.dialogsService.showSnackbar({
                    text: "Deleted " + itemName,
                    icon: 'delete',
                    iconClassName: 'color-text-error',
                    method: _this.options.allowDeleteUndo
                        ? new SnackBarMethod({
                            text: 'Undo',
                            icon: 'undo',
                            method: function () {
                                _this.restore(item, null, false).then(function (item) {
                                    _this._changesObserver &&
                                        _this._changesObserver.next({
                                            change: StoreModelChangeType.restored,
                                            item: item,
                                        });
                                });
                            },
                        })
                        : null,
                });
            }
            return item;
        }))
            .toPromise();
    };
    Store.prototype.restore = function (item, apiOptions, showNotification) {
        if (showNotification === void 0) { showNotification = false; }
        item.id = undefined;
        return this.save(item, apiOptions, showNotification);
    };
    return Store;
}(ReadOnlyStore));
export { Store };
export var StoreModelChangeType;
(function (StoreModelChangeType) {
    StoreModelChangeType[StoreModelChangeType["created"] = 0] = "created";
    StoreModelChangeType[StoreModelChangeType["updated"] = 1] = "updated";
    StoreModelChangeType[StoreModelChangeType["removed"] = 2] = "removed";
    StoreModelChangeType[StoreModelChangeType["restored"] = 3] = "restored";
})(StoreModelChangeType || (StoreModelChangeType = {}));
