// TODO(khaw): Remove as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'

import { Entity, EntityField } from '@microsoft/paris';
import { WeaknessSeverity } from '@wcd/domain';
import { NetworkDevicesConnectionState } from './network-devices-connection-state.enum';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { NetworkDeviceBase } from './network-device.base.entity';

export class NetworkDevicesSummaryModel {
	disconnectedCount: number;
	totalCount: number;
}

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Network',
	pluralName: 'Networks',
	endpoint: 'network-devices',
	readonly: true,
})
export class NetworkDevice extends NetworkDeviceBase {
	@EntityField({
		required: true,
	})
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({
		required: false,
	})
	readonly deviceName: string;

	@EntityField({
		required: true,
		parse: (status: string) => NetworkDevicesConnectionState[status],
	})
	readonly status: NetworkDevicesConnectionState;

	@EntityField({
		required: false,
	})
	readonly deviceType: string;

	@EntityField({
		required: false,
	})
	readonly vendor: string;

	@EntityField({
		required: false,
	})
	readonly osVersion: string;

	@EntityField({
		required: false,
	})
	readonly exposureLevel: WeaknessSeverity;

	@EntityField({
		required: false,
	})
	readonly cvesCount: number;

	@EntityField({
		required: true,
	})
	readonly lastScanned: Date;

	@EntityField({
		required: true,
	})
	readonly firstSeen: Date;
}
