import { Injector } from '@angular/core';
import { MatchType } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { Lazy } from '@wcd/utils';
import { FileEntityTypeService } from './file.entity-type.service';
import { FabricIconNames } from '@wcd/scc-common';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
var matchTypeIconMap = new Map([
    [MatchType.Exact, FabricIconNames.CircleFill],
    [MatchType.ContainsOrEndsWith, FabricIconNames.CircleHalfFull],
]);
var matchTypeDisplayNameMap = new Map([
    [MatchType.Exact, 'search_exactMatch'],
    [MatchType.ContainsOrEndsWith, 'search_partialMatch'],
]);
var FilesFieldsService = /** @class */ (function () {
    /**
     * _`Injector` is injected here in order to avoid a cyclic dependency between `FilesFieldsService` and `FileEntityTypeService`, each requires other parts from the other._
     */
    function FilesFieldsService(injector, i18nService) {
        var _this = this;
        this.injector = injector;
        this.i18nService = i18nService;
        this._fields = new Lazy(function () {
            var fields = [
                {
                    id: 'filename',
                    name: _this.i18nService.get('files.fields.fileName.title'),
                    getDisplay: function (file) { return file.fileName; },
                    getLink: function (file) { return _this.entityType.getEntitiesLink([file]); },
                    icon: {
                        fabricIcon: _this.entityType.icon,
                    },
                    sort: { sortLocally: false },
                },
                {
                    id: 'matchType',
                    name: _this.i18nService.get('search_matchType'),
                    icon: {
                        fabricIcon: function (file) { return matchTypeIconMap.get(file.matchType); },
                        className: '',
                    },
                    getDisplay: function (file) { return _this.i18nService.get(matchTypeDisplayNameMap.get(file.matchType)); },
                    filter: {
                        component: {
                            type: FilterValuesChecklistComponent,
                            config: {
                                mapFilterValue: function (matchType) {
                                    return {
                                        id: matchType,
                                        value: matchType,
                                        rawValue: matchType,
                                        name: _this.i18nService.get(matchTypeDisplayNameMap.get(matchType)),
                                        icon: matchTypeIconMap.get(matchType),
                                    };
                                },
                            },
                        },
                    },
                    sort: { sortLocally: false, sortDescendingByDefault: false },
                },
                {
                    id: 'lastSeen',
                    name: _this.i18nService.get('files.fields.lastSeen.title'),
                    getDisplay: function (file) { return file.lastSeen; },
                    component: {
                        type: TzDateComponent,
                        getProps: function (file) { return ({ date: file.lastSeen }); },
                    },
                    sort: { sortLocally: false },
                },
                {
                    id: 'firstSeen',
                    name: _this.i18nService.get('files.fields.firstSeen.title'),
                    getDisplay: function (file) { return file.firstSeen; },
                    component: {
                        type: TzDateComponent,
                        getProps: function (file) { return ({ date: file.firstSeen }); },
                    },
                    sort: { sortLocally: false },
                },
                {
                    id: 'id',
                    name: _this.i18nService.get('files.fields.details.title'),
                    getDisplay: function (file) { return file.sha1 || file.sha256; },
                    getImage: function (file) {
                        return file.sha1
                            ? '/assets/images/icons/sha1.svg'
                            : file.sha256
                                ? '/assets/images/icons/sha256.svg'
                                : null;
                    },
                    sort: { sortLocally: false },
                },
            ];
            return DataviewField.fromList(fields);
        });
        this._entityType = new Lazy(function () { return _this.injector.get(FileEntityTypeService).entityType; });
    }
    Object.defineProperty(FilesFieldsService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FilesFieldsService.prototype, "fields", {
        get: function () {
            return this._fields.value;
        },
        enumerable: true,
        configurable: true
    });
    return FilesFieldsService;
}());
export { FilesFieldsService };
