import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { RelatedComponentFilter } from './related-component-filters.value-object';
import { TvmAnalyticsApiCallSharedConfigurations } from '../analyticsEndPointUtils';

@ApiCall({
	...TvmAnalyticsApiCallSharedConfigurations,
	name: 'Get recommendations/related components subcategories',
	endpoint: 'recommendations/filters',
	type: RelatedComponentFilter,
})
export class RecommendationFiltersApiCall extends ApiCallModel<RelatedComponentFilter[]> {}
