<span
	class="wcd-searchbox"
	[class.wcd-searchbox__loading]="isLoading"
	[class.wcd-full-width]="fullWidth"
	[attr.role]="role"
	[attr.aria-expanded]="showResults"
	[attr.aria-owns]="searchboxId"
	aria-haspopup="true"
	[attr.id]="searchboxId + '-combobox'">
	<input type="text"
		   #inputEl
		   [(ngModel)]="searchValue"
		   [attr.placeholder]="placeholder"
		   (keydown)="onKeyDown($event)"
		   (focus)="onFocus()"
		   (blur)="onBlur()"
		   (select)="selectionChanged($event)"
		   aria-autocomplete="both"
		   [attr.aria-controls]="showResults && results.length ? searchboxId : null"
		   [attr.id]="searchboxId + '-input'"
		   [attr.aria-label]="ariaLabel"
		   [attr.aria-activedescendant]="highlightedResult >=0 ? searchboxId + '-' + highlightedResult : null"
		   class="wcd-searchbox--input"
		   [class.wcd-full-width]="fullWidth"
		   [focusOn]="setFocus" />
	<fab-icon contentClass="wcd-searchbox--icon" [hidden]="isLoading" iconName="Search"></fab-icon>
	<span class="wcd-searchbox--spinner" *ngIf="isLoading">
		<fab-spinner [size]="SpinnerSize.medium"></fab-spinner>
	</span>
	<ul class="wcd-searchbox--results"
		[class.resultVisible]="resultVisible"
		#resultsEl
		*ngIf="showResults && results.length"
		role="listbox"
		[attr.aria-labelledby]="searchboxId + '-input'"
		[id]="searchboxId">
		<li *ngFor="let result of results; let i = index"
			(mousedown)="selectItem(i)"
			class="wcd-searchbox--result"
			[class.wcd-searchbox--result__highlighted]="i === highlightedResult"
			[attr.aria-selected]="(result.label || result.value) === searchValue"
			[id]="searchboxId + '-' + i"
			role="option">
			<ng-container *ngIf="settings && settings.highlightTermInResults ; else plainResult">
				<span [textHighlight]="searchValue" tags="span">
					<ng-container [ngTemplateOutlet]="plainResult"></ng-container>
				</span>
			</ng-container>
			<ng-template #plainResult>
				<span>{{result.label || result.value}}</span>
			</ng-template>
		</li>
	</ul>
	<div #noResultsEl class="wcd-searchbox--no-results wcd-flex-center-all resultVisible"
		*ngIf="!isLoading && showResults && !results.length">
		<div class="wcd-margin-small-vertical">{{'filters_search_result_not_found' | i18n}}</div>
	</div>
</span>
