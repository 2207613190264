import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../data/models/store.backend.base';
import { DownloadService } from '../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';

const COMMENTS_API_ENDPOINT = '/comments';

@Injectable()
export class CommentsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + COMMENTS_API_ENDPOINT;
	}
}
