import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TvmTextsService } from '../../../tvm/services/tvm-texts.service';

@Component({
	selector: 'tvm-productivity-impact',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div>
			{{ _status }}
			<wcd-help
				*ngIf="isUserProductivityImpacted == null"
				[text]="'tvm.securityRecommendation.userImpact.unknownTooltipText' | i18n"
			>
			</wcd-help>
		</div>
	`,
})
export class TvmProductivityImpactComponent {
	@Input()
	isUserProductivityImpacted?: boolean;

	_status: string;

	constructor(private tvmTextsService: TvmTextsService) {}

	ngOnInit() {
		this._status = this.tvmTextsService.getProductivityImpactedLabel(this.isUserProductivityImpacted);
	}
}
