import { Entity, EntityField, ParisConfig, EntityModelBase } from '@microsoft/paris';
import { suppressionRuleHistoryItemTypes } from './suppression-rule-history-item-type.values';
import { SuppressionRuleHistoryItem } from './suppression-rule-history-item.entity';

@Entity({
	singularName: 'Suppression Rule History Item Type',
	pluralName: 'Suppression Rule History Item Types',
	values: suppressionRuleHistoryItemTypes,
})
export class SuppressionRuleHistoryItemType extends EntityModelBase {
	@EntityField() icon: string;
	@EntityField() getMessage: (item: SuppressionRuleHistoryItem) => string;
}
