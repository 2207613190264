import {
	AfterViewInit,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewContainerRef,
	ViewEncapsulation,
	OnInit, Output, EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfigService } from '@wcd/app-config';
import {
	DateRangeModel,
	getUserTimeZone,
	LocaleConfigService,
	resetToUTC,
	shiftTime,
	TzDateService,
} from '@wcd/localization';
import { cloneDeep, flatMap, isEqual, merge } from 'lodash-es';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, map, tap } from 'rxjs/operators';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { ReportWidgetsContainer } from '../models/report-widgets-container.interface';
import { ReportModel } from '../models/report.model';
import { ReportsService, yesterday } from '../../shared-reports/services/reports.service';
import { ReportColumnComponent } from './report-column.component';
import { ReportRowComponent } from './report-row.component';
import { ReportWidgetComponent } from './report-widget.component.base';
import { ReportTimeRange } from '../models/report-time-range.interface';
import { I18nService } from '@wcd/i18n';
import { PollingService } from '@wcd/config';
import moment from 'moment';
import { Panel, PanelType } from '@wcd/panels';
import { FabricIconNames } from '@wcd/scc-common';
import { Positions } from '@wcd/forms';

let dashboardFirstLoad: boolean = true;
let lastId = 0;

@Component({
	selector: 'report',
	templateUrl: './report.component.html',
	styleUrls: ['./report.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class ReportComponent implements ReportWidgetsContainer, OnInit, OnChanges, OnDestroy, AfterViewInit {
	@Input() report: ReportModel;
	@Input() showControls: boolean = true;
	@Input() removePadding = false;
	@Input() removePaddingLeft = false;
	@Input() removePaddingRight = false;
	/**
	 * Should polling errors be shown.
	 * @default true
	 */
	@Input() showPollingError?: boolean = true;

	@Input() dataOptions: {};

	/**
	 * Whether or not to show widget loaders when refreshing from GUI or on changes (doesn't affect polling)
	 * @default false
	 */
	@Input() showIndividualLoadersOnReload: boolean = false;

	@Input()
	set params(value: { [index: string]: any }) {
		this._params = merge({}, value, this.report.params);
	}

	get params(): { [index: string]: any } {
		return this._params;
	}

	@Input() titleDashboardId: string = `dashboard-title-container-${lastId++}`;
	@Input() openMenuPosition: Positions = Positions.Default;
	@Input() smallScreenView: boolean = false;
	@Input() enableLargePadding: boolean = false;
	@Input() enableFocusOnFirstElement: boolean = true;

	@Output() onWidgetsLoaded = new EventEmitter<boolean>()
	@Output() onReportRenderComplete: EventEmitter<void> = new EventEmitter<void>();

	timezone: string;
	lastLoadTime: Date;
	noData: boolean = false;
	isExporting: boolean = false;
	widgetsLoaded: boolean;
	isLoading: boolean = true;
	reportDatesTitle: string;
	reportTimeTitle: string;
	customRange: DateRangeModel;
	isPollingErrorExist: boolean = false;
	reportLoadStart: Date;
	appReady$: Subject<number> = new Subject<number>();
	isEmpty$: Observable<boolean>;
	currentTimeRange: ReportTimeRange;
	reportTitle: string;

	paddingTop: boolean = false;
	panelSettings: Panel;
	customRangePanelOpened: boolean;
	isDirty: boolean = false;
	icon: string = FabricIconNames.Calendar;
	formatLabel: (value: ReportTimeRange, isSelectionItem: boolean) => string;
	customRangeFromDisplay: string;
	customRangeToDisplay: string;
	originalCustomRange: DateRangeModel;
	widgetsLoadedSubject = new Subject<boolean>()

	private _appReadyFired: boolean = false;
	private _params: { [index: string]: any };
	private _loadingSubscription: Subscription;
	private _pollingErrorSubscription: Subscription;
	private _refreshSubscription: Subscription;
	private _queryParamsChangeSubscription: Subscription;

	@ViewChild('emptyComponentPlaceholder', { read: ViewContainerRef, static: false })
	emptyComponentPlaceholder: ViewContainerRef;

	@ViewChildren(ReportColumnComponent) columns: QueryList<ReportColumnComponent>;
	@ViewChildren(ReportRowComponent) rows: QueryList<ReportRowComponent>;

	constructor(
		public reportsService: ReportsService,
		private appInsightsService: AppInsightsService,
		private appConfigService: AppConfigService,
		private route: ActivatedRoute,
		private router: Router,
		private resolver: ComponentFactoryResolver,
		private localeConfigService: LocaleConfigService,
		private i18nService: I18nService,
		private pollingService: PollingService,
		private tzDateService: TzDateService,
	) {
		const PANEL_SETTINGS: Panel = new Panel({
			type: PanelType.medium,
			headerText: '',
			showOverlay: false,
			isBlocking: false,
			isModal: true,
			isStatic: false,
			noBodyPadding: true,
		});

		if (dashboardFirstLoad) {
			this.appInsightsService.appReadyListener$ = this.appReady$.asObservable();
			dashboardFirstLoad = false;
		}
		this.panelSettings = PANEL_SETTINGS;
		this.panelSettings.headerText = this.i18nService.get('common_daterange_panel_header');
	}

	get allWidgets(): Array<ReportWidgetComponent> {
		if (!this.columns) return [];

		return (<Array<ReportWidgetComponent>>(
			flatMap(this.columns.toArray().map((column: ReportWidgetsContainer) => column.allWidgets))
		)).concat(<Array<ReportWidgetComponent>>(
			flatMap(this.rows.toArray().map((row: ReportWidgetsContainer) => row.allWidgets))
		));
	}

	get showHeader(): boolean {
		return !!(this.report.title || this.report.titleNameKey) || this.showControls;
	}

	ngOnInit() {
		this.createFormatLabel();

		if (this.report.refreshOnQueryParamsChange !== false) {
			this._queryParamsChangeSubscription = this.route.queryParams
				.pipe(distinctUntilChanged((before, after) => isEqual(before, after)))
				.subscribe((params: Params) => {
					this.setRange(params);
					this.createFormatLabel();
					this._params = merge({}, this.params, params);
					this.reloadWidgetsData();
				});
		}
	}

	ngOnDestroy() {
		this._loadingSubscription && this._loadingSubscription.unsubscribe();
		this._pollingErrorSubscription && this._pollingErrorSubscription.unsubscribe();
		this._refreshSubscription && this._refreshSubscription.unsubscribe();
		this._queryParamsChangeSubscription && this._queryParamsChangeSubscription.unsubscribe();
		this.widgetsLoadedSubject.complete()
	}

	ngOnChanges(changes) {
		if (changes.report) {
			this._params = merge(
				{},
				this._params,
				{ range: this.reportsService.defaultTimeRange.value },
				this.report.params
			);
			this.reportTitle = this.report.titleNameKey
				? this.i18nService.get(this.report.titleNameKey)
				: this.report.title || '';
		}

		if (
			changes.dataOptions &&
			this.reportLoadStart &&
			!isEqual(changes.dataOptions.currentValue, changes.dataOptions.previousValue)
		) {
			this.reloadWidgetsData(this.showIndividualLoadersOnReload);
		}
	}

	ngAfterViewInit() {
		this.reportLoadStart = new Date();

		this._loadingSubscription = combineLatest(
			this.allWidgets.map((widgetComponent: ReportWidgetComponent) => widgetComponent.isLoadingData$)
		)
			.pipe(
				map((widgetsLoading: Array<boolean>) => {
					return widgetsLoading.some(isLoading => isLoading);
				}),
				distinctUntilChanged(),
				tap(isLoading => {
					if (!isLoading) {
						// Hack suggested on Infra office hour, to wait until angular finish to render the loaded data
						setTimeout(() => {
							this.onRenderComplete();
						}, 0);

						if (this.report.autoRefresh) {
							this._refreshSubscription && this._refreshSubscription.unsubscribe();
							this._refreshSubscription = this.pollingService
								.poll(this.report.refreshRate, this.report.refreshRate)
								.subscribe(() => this.reloadData());
						}
					}
				})
			)
			.subscribe((isLoading: boolean) => {
				this.isLoading = isLoading;
				if (!isLoading) {
					if (!this.widgetsLoaded) {
						this.widgetsLoaded = true;
						if(this.enableFocusOnFirstElement)
							this.widgetsLoadedSubject.next(true);
						this.onWidgetsLoaded.emit(true);
					}
					this.lastLoadTime = new Date();

					const loadFinishedTime: Date = new Date(),
						loadTime: number = loadFinishedTime.valueOf() - this.reportLoadStart.valueOf();
					this.appInsightsService.trackEvent(
						'UI Report Load',
						{ dashboard: this.report.name },
						{ time: loadTime }
					);
					if (!this._appReadyFired && this.appConfigService.appNavigateStartTime) {
						this.appReady$.next(
							loadFinishedTime.valueOf() - this.appConfigService.appNavigateStartTime.valueOf()
						);
						this.appReady$.complete();
						this.appReady$ = null;
						this._appReadyFired = true;
					}
				}
			});

		this._pollingErrorSubscription = combineLatest(
			this.allWidgets.map((widgetComponent: ReportWidgetComponent) => widgetComponent.pollingError$)
		)
			.pipe(
				map((widgetsPollingError: Array<boolean>) => {
					return widgetsPollingError.some(pollingError => pollingError);
				}),
				distinctUntilChanged()
			)
			.subscribe((isPollingErrorExist: boolean) => (this.isPollingErrorExist = isPollingErrorExist));

		this.isEmpty$ = combineLatest(this.allWidgets.map(widgetComponent => widgetComponent.data$)).pipe(
			map((widgetsData: Array<any>) => widgetsData.every(widgetData => widgetData === null)),
			distinctUntilChanged(),
			tap(isEmpty => this.setEmptyComponent(isEmpty))
		);
	}

	reloadWidgetsData(showIndividualLoadersOnReload: boolean = false) {
		if (this.report.autoRefresh) {
			this._refreshSubscription && this._refreshSubscription.unsubscribe();
			this._refreshSubscription = this.pollingService
				.poll(0, this.report.refreshRate)
				.subscribe(() => this.reloadData(showIndividualLoadersOnReload));
		} else {
			this.reloadData(showIndividualLoadersOnReload);
		}
	}

	private reloadData(showIndividualLoadersOnReload: boolean = false) {
		this.reportLoadStart = new Date();

		this.allWidgets.forEach((widgetComponent: ReportWidgetComponent) =>
			widgetComponent.reload(
				merge(this.getCustomRangeParam(), this.dataOptions),
				showIndividualLoadersOnReload
			)
		);
	}

	private getCustomRangeParam(): { selection: { fromDate: Date; toDate: Date } } {
		if (!this.customRange) return null;

		return {
			selection: {
				fromDate: this.customRange.from,
				toDate: this.customRange.to,
			},
		};
	}

	reset() {
		this.allWidgets.forEach((widget: ReportWidgetComponent) => {
			widget.reset();
		});
		this.lastLoadTime = null;
	}

	exportReport() {
		this.isExporting = true;

		this.reportsService
			.exportReport(this.report)
			.then(() => (this.isExporting = false), () => (this.isExporting = false));
	}

	onRangeSelect(): void {
		const rangeParamValue: string = this.currentTimeRange.allowCustomRange
			? this.currentTimeRange.dateRange.toString()
			: this.currentTimeRange.value;

		this.router.navigate([], {
			queryParams: { range: rangeParamValue },
			relativeTo: this.route,
			queryParamsHandling: 'merge',
		});
	}

	onRangeChange(timeRange: ReportTimeRange) {
		this.currentTimeRange = timeRange;

		if (this.currentTimeRange.allowCustomRange) {
			this.togglePanel(true);
			this.originalCustomRange = cloneDeep(this.customRange);
		} else {
			this.togglePanel(false);
		}

		if (!timeRange.allowCustomRange) this.onRangeSelect();
	}

	onCustomRangeChange(): void {
		this.isDirty = true;
	}

	setRange(params: Params): void {
		let customRange: DateRangeModel = DateRangeModel.fromString(this.tzDateService, params.range, false);
		this.currentTimeRange =
			this.reportsService.getTimeRangeByValue(customRange ? 'custom' : params.range) ||
			this.reportsService.defaultTimeRange;
		if (customRange) this.reportsService.setCustomTimeRange(customRange, this.updateOptionsNeeded());
		else {
			this.reportsService.currentTimeRange = this.currentTimeRange;
			const toDate: Date = this.getDefaultToDate(),
				fromDate: Date = moment(toDate)
					.subtract(parseInt(this.currentTimeRange.value), 'days')
					.toDate();

			customRange = new DateRangeModel(this.tzDateService, fromDate, toDate);
		}

		const newTimeZone = params.timezone ? parseInt(params.timezone) : getUserTimeZone();
		if (this.reportsService.currentTimezone !== newTimeZone) {
			this.reportsService.currentTimezone = newTimeZone;
		}
		const timezone: number = this.reportsService.currentTimezone;
		const displayRange: DateRangeModel = customRange.clone();
		let displayTime: Date = this.lastLoadTime ? new Date(this.lastLoadTime) : new Date();

		displayRange.resetToUTC();
		displayTime = resetToUTC(displayTime);

		if (timezone) {
			displayRange.shiftTimeByHours(timezone);
			displayTime = shiftTime(displayTime, { type: 'hours', amount: timezone });
		}

		this.reportDatesTitle = displayRange.format(
			'shortDate',
			this.localeConfigService.selectedLocale
		);
		this.reportTimeTitle = this.tzDateService.format(displayTime, 'mediumTime');
		this.customRange = customRange;
	}

	private updateOptionsNeeded(): Boolean {
		return !(
			this.report.id === 'threatProtectionTrendReport' ||
			this.report.id === 'machineProtectionTrendReport'
		);
	}

	private getDefaultToDate() {
		return this.report.id === 'threatProtectionTrendReport' ||
			this.report.id === 'machineProtectionTrendReport'
			? yesterday
			: new Date();
	}

	/**
	 * If the report has a defined emptyDashboardComponent, adds the component to the empty container, so it's displayed instead of the dashboard's widgets
	 */
	private setEmptyComponent(isEmpty: boolean) {
		if (!this.report.emptyDashboardComponent) return;

		this.emptyComponentPlaceholder.clear();

		if (isEmpty) {
			const injector = Injector.create({
					providers: [],
					parent: this.emptyComponentPlaceholder.parentInjector,
				}),
				factory: ComponentFactory<any> = this.resolver.resolveComponentFactory(
					this.report.emptyDashboardComponent
				),
				emptyComponentRef: ComponentRef<any> = factory ? factory.create(injector) : null;

			this.emptyComponentPlaceholder.insert(emptyComponentRef.hostView);
		}
	}

	togglePanel(panelOpen: boolean) {
		this.customRangePanelOpened = panelOpen;
	}

	apply() {
		this.reportsService.setCustomTimeRange(this.customRange, this.updateOptionsNeeded());
		this.onRangeSelect();
		this.togglePanel(false);
		this.isDirty = false;
	}

	cancel() {
		this.togglePanel(false);
		this.isDirty = false;
		this.customRange = cloneDeep(this.originalCustomRange);
	}

	createFormatLabel = () => {
		this.formatLabel = (value, isSelectionItem = false) => {
			if (!isSelectionItem && this.customRange && this.currentTimeRange.allowCustomRange) {
				this.customRangeFromDisplay = this.tzDateService.format(this.customRange.from, 'shortDate');
				this.customRangeToDisplay = this.tzDateService.format(this.customRange.to, 'shortDate');
				return `${this.customRangeFromDisplay}-${this.customRangeToDisplay}`;
			}
			return value.name;
		};
	};

	onRenderComplete() {
		this.onReportRenderComplete.emit();
	}

}
