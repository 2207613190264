import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { OAuthAppRisk } from '../../application/oauth-app-risk.values';

@Entity({
	...airsEntityConfig,
	singularName: 'OAuth application',
	pluralName: 'OAuth applications',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsOauthApplication,
})
export class AirsOauthApplication extends AirsEntity {
	@EntityField({ data: 'oauth_app_id' })
	oAuthAppId: string;

	@EntityField({ data: 'oauth_object_id' })
	oAuthObjectId: string;

	@EntityField({ data: 'risk' })
	risk: OAuthAppRisk;
}
