var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { AirsNotificationRuleConditionGroupsStore } from './airs-notification-rule-condition-groups.store';
import { AirsNotificationRulesReportsBackendService } from './airs-notification-rules-reports.backend.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Store } from '../../../data/models/store.base';
import { I18nService } from '@wcd/i18n';
var AirsNotificationRulesReportsStore = /** @class */ (function (_super) {
    __extends(AirsNotificationRulesReportsStore, _super);
    function AirsNotificationRulesReportsStore(dialogsService, notificationRuleConditionGroupsStore, backendService, ngZone, i18nService) {
        return _super.call(this, backendService, ngZone, AirsNotificationRuleModel, dialogsService, i18nService, {
            itemParams: [notificationRuleConditionGroupsStore],
            itemNameSingular: 'Notification Rule',
            itemNamePlural: 'Notification Rules',
            iconName: 'notificationRule',
        }) || this;
    }
    return AirsNotificationRulesReportsStore;
}(Store));
export { AirsNotificationRulesReportsStore };
