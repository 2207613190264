/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i2 from "../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i3 from "../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i4 from "./system-exclusion-edit.component.ngfactory";
import * as i5 from "./system-exclusion-edit.component";
import * as i6 from "@microsoft/paris/dist/lib/paris";
import * as i7 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "../../../shared/interfaces/disableable.interface";
import * as i9 from "../../../rbac/directives/rbac-control.directive";
import * as i10 from "../../../rbac/services/rbac-control.service";
import * as i11 from "../../../../../../../projects/shared/src/lib/services/wicd-sanitizer.service";
import * as i12 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i13 from "./system-exclusion-edit.modal.component";
import * as i14 from "@angular/router";
var styles_SystemExclusionEditModalComponent = [];
var RenderType_SystemExclusionEditModalComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SystemExclusionEditModalComponent, data: {} });
export { RenderType_SystemExclusionEditModalComponent as RenderType_SystemExclusionEditModalComponent };
export function View_SystemExclusionEditModalComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 10, "wcd-panel", [], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_PanelComponent_0, i1.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i2.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i3.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, null, 1, 5, "system-exclusion-edit", [], null, [[null, "done"], [null, "cancel"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("done" === en)) {
        var pd_0 = (_co.onDone($event) !== false);
        ad = (pd_0 && ad);
    } if (("cancel" === en)) {
        var pd_1 = (_co.onCancel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i4.View_SystemExclusionEditComponent_0, i4.RenderType_SystemExclusionEditComponent)), i0.ɵdid(6, 49152, null, 0, i5.SystemExclusionEditComponent, [i6.Paris, i7.I18nService], { systemExclusion: [0, "systemExclusion"], systemExclusionType: [1, "systemExclusionType"], systemExclusionListTypes: [2, "systemExclusionListTypes"], editableProperties: [3, "editableProperties"], formIsDirtyOnInit: [4, "formIsDirtyOnInit"] }, { done: "done", cancel: "cancel" }), i0.ɵprd(2048, null, i8.DISABLEABLE_TOKEN, null, [i5.SystemExclusionEditComponent]), i0.ɵdid(8, 4800512, null, 0, i9.RbacControlDirective, [i10.RbacControlService, i0.ElementRef, i0.ChangeDetectorRef, i11.WicdSanitizerService, [6, i8.DISABLEABLE_TOKEN], i12.TooltipsService, i0.ViewContainerRef, i7.I18nService], { rbac: [0, "rbac"] }, null), i0.ɵpad(9, 1), i0.ɵpod(10, { permissions: 0, state: 1 }), (_l()(), i0.ɵted(-1, 1, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.systemExclusion; var currVal_2 = _co.systemExclusionType; var currVal_3 = _co.systemExclusionListTypes; var currVal_4 = _co.editableProperties; var currVal_5 = _co.formIsDirtyOnInit; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_6 = _ck(_v, 10, 0, _ck(_v, 9, 0, "securitySettings"), "disabled"); _ck(_v, 8, 0, currVal_6); }, null); }
export function View_SystemExclusionEditModalComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "system-exclusion-edit-modal", [], null, null, null, View_SystemExclusionEditModalComponent_0, RenderType_SystemExclusionEditModalComponent)), i0.ɵdid(1, 245760, null, 0, i13.SystemExclusionEditModalComponent, [i14.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SystemExclusionEditModalComponentNgFactory = i0.ɵccf("system-exclusion-edit-modal", i13.SystemExclusionEditModalComponent, View_SystemExclusionEditModalComponent_Host_0, { settings: "settings", onDone: "onDone", onCancel: "onCancel", systemExclusion: "systemExclusion", systemExclusionType: "systemExclusionType", systemExclusionListTypes: "systemExclusionListTypes", editableProperties: "editableProperties", formIsDirtyOnInit: "formIsDirtyOnInit" }, {}, []);
export { SystemExclusionEditModalComponentNgFactory as SystemExclusionEditModalComponentNgFactory };
