import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'generic-object',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<button
			class="btn-link wcd-margin-none-all wcd-padding-none-all"
			(click)="visible = !visible"
			data-track-id="GenericObjectDetailsToggle"
			data-track-type="Toggle"
		>
			<wcd-shared-icon [iconName]="visible ? 'visibility.off' : 'visibility.on'" class="small-icon">
			</wcd-shared-icon>
			<span aria-live="polite">
				{{
					visible
						? i18nService.strings.generic_object_details_hide
						: i18nService.strings.generic_object_details_show
				}}
				{{ fieldName }}
			</span>
		</button>
		<div class="details-card" *ngIf="visible">
			<ng-content></ng-content>
		</div>
	`,
})
export class GenericObjectComponent {
	@Input()
	visible: boolean = false;

	@Input() fieldName: string;

	constructor(public i18nService: I18nService) {}
}
