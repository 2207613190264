import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { Paris, RelationshipRepository } from "@microsoft/paris";
import { BaselineConfiguration, BaselineConfigurationDevice, BaselineProfileConfigurationIds } from "@wcd/domain";
import { DataviewField, DataViewConfig } from '@wcd/dataview';
import { TvmDownloadService } from "../../../../../../../../tvm/services/tvm-download.service";
import { BaselineConfigurationFieldsService } from "../../../services/baseline-configuration.fields.service";
import { BaselineDetectedValuesService } from '../../../../../../../../tvm/services/baseline-detected-value.service';

@Component({
	selector: 'app-configuration-devices.base',
	templateUrl: './configuration-devices.base.component.html',
	providers: [BaselineConfigurationFieldsService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfigurationDevicesBaseComponent implements OnInit {
	fields: Array<DataviewField<BaselineConfigurationDevice>>;
	dataViewConfig: DataViewConfig;
	isDetectedValueEnabled: boolean;
	isInProgress: boolean;
	repository: RelationshipRepository<BaselineProfileConfigurationIds | BaselineConfiguration, BaselineConfigurationDevice>;
	protected exportUrl: string;
	protected dataviewId: string;
	protected exportFileName: string;

	@Input()
	configuration: BaselineConfiguration;

	constructor(
		private fieldsService: BaselineConfigurationFieldsService,
		private tvmDownloadService: TvmDownloadService,
		private detectedValuesService: BaselineDetectedValuesService,
		protected paris: Paris
	) {
	}

	ngOnInit(): void {
		this.isDetectedValueEnabled = this.detectedValuesService.isConfigurationSupported(this.configuration);

		// TODO: lischach to update logic once other value is present
		this.isInProgress = !this.configuration || this.configuration.compliantDevices === -1;

		this.fields = this.fieldsService.getApplicableAssetsFields(this.configuration);
		this.dataViewConfig = {
			id: this.dataviewId,
			showModalOnExport: false,
			exportResults: (_, __, dataQuery) => this.exportResults(dataQuery),
		};
	}

	exportResults(dataQuery) {
		return this.tvmDownloadService.downloadCsv({
			url: this.exportUrl,
			fileName: `Export_${this.exportFileName}_devices_${(new Date()).getTime()}`,
			dataQuery: dataQuery,
		});
	}
}
