var _a;
export var MicrosoftProductIdsWithLegalNote = {
    Windows7: 'microsoft-_-windows_7',
    WindowsServer2008: 'microsoft-_-windows_server_2008_r2',
    Windows10: 'microsoft-_-windows_10',
    SqlServer2012: 'microsoft-_-sql_server_2012',
    SqlServer2014: 'microsoft-_-sql_server_2014',
    SqlServer2016: 'microsoft-_-sql_server_2016'
};
export var EolWindows = [MicrosoftProductIdsWithLegalNote.Windows7, MicrosoftProductIdsWithLegalNote.WindowsServer2008];
export var ShouldShowLegalNoteFor = Object.values(MicrosoftProductIdsWithLegalNote);
export var LegalNoteLinks = (_a = {},
    _a[MicrosoftProductIdsWithLegalNote.Windows10] = 'https://docs.microsoft.com/en-us/windows/release-information/',
    _a[MicrosoftProductIdsWithLegalNote.SqlServer2012] = 'https://support.microsoft.com/en-us/lifecycle/search',
    _a[MicrosoftProductIdsWithLegalNote.SqlServer2014] = 'https://support.microsoft.com/en-us/lifecycle/search',
    _a[MicrosoftProductIdsWithLegalNote.SqlServer2016] = 'https://support.microsoft.com/en-us/lifecycle/search',
    _a);
