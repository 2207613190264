import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('alertDetectionTech', alertDetectionTechFilter);

function alertDetectionTechFilter() {
	return function(input) {
		if (!input) {
			return null;
		}

		const techs = input.split(',');
		const techDisplayNames = techs
			.map(getDisplayName)
			.filter(Boolean)
			.sort((x, y) => (x > y ? 1 : -1));

		return techDisplayNames.length ? techDisplayNames.join(', ') : null;
	};
}

export const getAlertDetectionTech = alertDetectionTechFilter();

function getDisplayName(input: string): string {
	switch (input) {
		case 'Behavior':
			return 'Behavioral';
		case 'MachineLearning':
			return 'Machine Learning';
		case 'ThreatIntelligence':
			return 'Threat Intelligence';
		case 'CustomerTi':
			return 'Custom TI';
		case 'Memory':
			return 'Memory';
		case 'Amsi':
			return 'Amsi';
		case 'Kernel':
			return 'Kernel';
		case 'ExploitProtection':
			return 'Exploit Protection';
		case 'Network':
			return 'Network';
		case 'Smartscreen':
			return 'Smartscreen';
		case 'Client':
			return 'Client';
		case 'Hardware':
			return 'Hardware';
		case 'StaticSignature':
			return 'Static Signature';
		default:
			return null;
	}
}
