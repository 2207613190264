var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) { return query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr; }, ɵ1 = function (command) { return ({
    params: {
        commandId: command.id,
        session_id: command.sessionId,
        useV2Api: command.useV2Api,
        useV3Api: command.useV3Api,
    },
}); }, ɵ2 = function (config, query) {
    var commandId = query.where.commandId;
    var useV2Api = query.where.useV2Api;
    var useV3Api = query.where.useV3Api;
    var endpoint = useV3Api ? "cloud/live_response/commands/" + commandId + "/background" : useV2Api
        ? "v2/live_response/commands/" + commandId + "/background"
        : "live_response/commands/" + commandId + "/background";
    delete query.where['commandId'];
    delete query.where['useV2Api'];
    return endpoint;
};
var BackgroundCommandApiCall = /** @class */ (function (_super) {
    __extends(BackgroundCommandApiCall, _super);
    function BackgroundCommandApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    BackgroundCommandApiCall = __decorate([
        ApiCall({
            name: 'Move Live Response Command to background',
            baseUrl: ɵ0,
            parseQuery: ɵ1,
            endpoint: ɵ2,
            method: 'POST',
        })
    ], BackgroundCommandApiCall);
    return BackgroundCommandApiCall;
}(ApiCallModel));
export { BackgroundCommandApiCall };
export { ɵ0, ɵ1, ɵ2 };
