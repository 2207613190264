/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hunting-documentation-table-reference.panel.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "@angular/common";
import * as i5 from "./hunting-documentation-record-list.component";
var styles_HuntingDocumentationRecordListComponent = [i0.styles];
var RenderType_HuntingDocumentationRecordListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HuntingDocumentationRecordListComponent, data: {} });
export { RenderType_HuntingDocumentationRecordListComponent as RenderType_HuntingDocumentationRecordListComponent };
function View_HuntingDocumentationRecordListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "dt", [["class", "wcd-documentation-tab_textBtn"], ["role", "button"], ["tabindex", "0"]], [[1, "aria-label", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.recordNameClicked$.emit(_v.context.$implicit.name) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.recordNameClicked$.emit(_v.context.$implicit.name) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "dd", [["class", "wcd-border-bottom-light wcd-padding-small-bottom"], ["role", "none"]], null, null, null, null, null)), (_l()(), i1.ɵted(6, null, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], null, function (_ck, _v) { var currVal_0 = ((_v.context.$implicit.name + ": ") + _v.context.$implicit.description); _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.name; _ck(_v, 3, 0, currVal_1); var currVal_2 = _v.context.$implicit.description; _ck(_v, 6, 0, currVal_2); }); }
function View_HuntingDocumentationRecordListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "wcd-padding-medium-horizontal btn-link wcd-padding-mediumSmall-bottom"], ["data-track-id", "record.id"], ["data-track-type", "Button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.changeViewClicked() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(2, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 2, 0, i1.ɵnov(_v.parent, 0), ("hunting.documentation.table.reference.panel.view." + ((_co.shownRecords == _co.defaultNumberOfElements) ? "all.button" : "less.button")))); _ck(_v, 1, 0, currVal_0); }); }
export function View_HuntingDocumentationRecordListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 2, "dt", [["role", "none"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(4, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "dd", [["class", "wcd-padding-bottom"], ["role", "none"]], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["\n\t\t\t", "\n\t\t"])), i1.ɵppd(8, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 5, "dl", [["class", "wcd-padding-medium-horizontal wcd-padding-small-bottom"], ["role", "none"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_HuntingDocumentationRecordListComponent_1)), i1.ɵdid(13, 278528, null, 0, i4.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpid(0, i4.SlicePipe, []), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HuntingDocumentationRecordListComponent_2)), i1.ɵdid(18, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform(_co.records, 0, _co.shownRecords)); _ck(_v, 13, 0, currVal_2); var currVal_3 = (_co.records.length > _co.defaultNumberOfElements); _ck(_v, 18, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i1.ɵnov(_v, 0), _co.header)); _ck(_v, 3, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 7, 0, _ck(_v, 8, 0, i1.ɵnov(_v, 0), _co.description)); _ck(_v, 7, 0, currVal_1); }); }
export function View_HuntingDocumentationRecordListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "hunting-documentation-record-list", [], null, null, null, View_HuntingDocumentationRecordListComponent_0, RenderType_HuntingDocumentationRecordListComponent)), i1.ɵdid(1, 49152, null, 0, i5.HuntingDocumentationRecordListComponent, [], null, null)], null, null); }
var HuntingDocumentationRecordListComponentNgFactory = i1.ɵccf("hunting-documentation-record-list", i5.HuntingDocumentationRecordListComponent, View_HuntingDocumentationRecordListComponent_Host_0, { records: "records", header: "header", description: "description" }, { recordNameClicked$: "recordNameClicked$" }, []);
export { HuntingDocumentationRecordListComponentNgFactory as HuntingDocumentationRecordListComponentNgFactory };
