var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { GlobalEntityTypesService } from './global-entity-types.service';
import { EntityWithContext } from '@wcd/domain';
import { EntityWithContextPanelComponent } from '../components/entity-panels/entity-with-context.entity-panel.component';
import { Paris } from '@microsoft/paris';
import { map, switchMap } from 'rxjs/operators';
import { of, defer } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { toObservable } from '../../utils/rxjs/utils';
/**
 * Provides support for displaying entities together with some context.
 * e.g. display a file (entity) that was blocked by an antivirus (context)
 * Most of the time, this service takes the properties from the main entity type
 * To use this service, create entities of type EntityWithContext, and display them using the global entities infra, as you do for other, concrete entities.
 */
var EntityWithContextEntityTypeService = /** @class */ (function () {
    function EntityWithContextEntityTypeService(globalEntityTypesService, paris, i18nService) {
        var _this = this;
        this.globalEntityTypesService = globalEntityTypesService;
        this.paris = paris;
        this.i18nService = i18nService;
        this.entityType = {
            id: 'entity-with-context',
            entity: EntityWithContext,
            singleEntityPanelComponentType: EntityWithContextPanelComponent,
            loadFullEntityInPanel: true,
            getEntityName: function (entityWithContext) {
                return _this.delegateToEntityType([entityWithContext], function (entityType, entity) { return entityType.getEntityName && entityType.getEntityName(entity); });
            },
            getIcon: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) {
                    return entityType.icon || (entityType.getIcon && entityType.getIcon([entity]));
                });
            },
            getImage: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) { return entityType.getImage && entityType.getImage([entity]); });
            },
            getIconCssClass: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) { return entityType.getIconCssClass && entityType.getIconCssClass([entity]); });
            },
            getItemParams: function (entityWithContext, options) {
                return _this.delegateToEntityType([entityWithContext], function (entityType, entity) { return entityType.getItemParams && entityType.getItemParams(entity, options); });
            },
            getItem: function (entityWithContext, loadedEntity$, options, allowCache) {
                return _this.delegateToEntityType([entityWithContext], function (entityType, entity) {
                    if (entityType.loadFullEntityInPanel === false) {
                        return entityWithContext;
                    }
                    // load main entity, retain context
                    var loadedMainEntity$ = defer(function () {
                        var params$ = toObservable(entityType.getItemParams ? entityType.getItemParams(entity, options) : null);
                        return params$.pipe(switchMap(function (params) {
                            return _this.paris.getItemById(entityType.entity, entity.id, { allowCache: allowCache }, params);
                        }));
                    });
                    var mainEntity$ = entityType.getItem
                        ? toObservable(entityType.getItem(entity, loadedMainEntity$, options, allowCache))
                        : loadedMainEntity$;
                    return mainEntity$.pipe(map(function (loadedEntity) {
                        //@ts-ignore
                        return new EntityWithContext(__assign({}, entityWithContext, { mainEntity: __assign({}, entityWithContext.mainEntity, { item: loadedEntity }) }));
                    }));
                });
            },
            getActions: function (entities, options, entityViewType) {
                return _this.delegateToEntityType(entities, function (entityType, entity) {
                    var mainEntityActions = entityType.getActions && entityType.getActions([entity], options, entityViewType), mainEntityActions$ = mainEntityActions && toObservable(mainEntityActions);
                    return (mainEntityActions$ &&
                        mainEntityActions$.pipe(map(function (actions) { return actions.map(function (action) { return _this.convertEntityAction(action); }); })));
                });
            },
            getEntityClassName: function (entityWithContext) {
                return _this.delegateToEntityType([entityWithContext], function (entityType, entity) { return entityType.getEntityClassName && entityType.getEntityClassName(entity); });
            },
            getEntitiesLink: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) { return entityType.getEntitiesLink && entityType.getEntitiesLink([entity]); });
            },
            getEntitiesLinkText: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) {
                    return (entityType.getEntitiesLinkText && entityType.getEntitiesLinkText([entity])) ||
                        _this.i18nService.get('entityCommon.commandBar.openPage', {
                            entity: entityType.entity.singularName,
                        });
                });
            },
            getSubtitle: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) { return entityType.getSubtitle && entityType.getSubtitle([entity]); });
            },
            isUserExposedToEntity: function (entityWithContext) {
                return _this.delegateToEntityType([entityWithContext], function (entityType, entity) {
                    return entityType.isUserExposedToEntity
                        ? entityType.isUserExposedToEntity(entity)
                        : entityType.isUserExposedToEntityById
                            ? entityType.isUserExposedToEntityById(entity.id.toString())
                            : of(true);
                });
            },
            getTags: function (entities) {
                return _this.delegateToEntityType(entities, function (entityType, entity) { return (entityType.getTags && entityType.getTags([entity])) || []; });
            },
        };
    }
    EntityWithContextEntityTypeService.prototype.delegateToEntityType = function (entitiesWithContext, fn) {
        var mainEntityWithType = entitiesWithContext && entitiesWithContext.length === 1 && entitiesWithContext[0]
            ? entitiesWithContext[0].mainEntity
            : null, mainEntity = mainEntityWithType && mainEntityWithType.item, mainEntityType = mainEntityWithType && this.globalEntityTypesService.getEntityType(mainEntityWithType.type);
        return mainEntity && mainEntityType && fn(mainEntityType, mainEntity);
    };
    EntityWithContextEntityTypeService.prototype.convertEntityAction = function (action) {
        var _this = this;
        // The UI infra expects actions performed on this entity type to work with entities of type EntityWithContext
        // the actions are delegated to the main entity type, which works with entities of type TEntity
        // redirect the action to the main entity
        return __assign({}, action, { method: function (entitiesWithContext, options, dataset, itemActionValue) {
                return action.method(_this.convertToMainEntities(entitiesWithContext), options, dataset && __assign({}, dataset, { items: _this.convertToMainEntities(dataset.items) }), itemActionValue);
            } });
    };
    EntityWithContextEntityTypeService.prototype.convertToMainEntities = function (entitiesWithContext) {
        return (entitiesWithContext &&
            entitiesWithContext.map(function (ec) { return ec.mainEntity.item; }));
    };
    return EntityWithContextEntityTypeService;
}());
export { EntityWithContextEntityTypeService };
