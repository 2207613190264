import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { OperatingSystemPlatform } from './operating-system-platform.entity';
import { ReleaseVersion } from './release-version.entity';
import { OperatingSystemPlatformCategories } from '@wcd/domain';

@ValueObject({
	singularName: 'Operating System',
	pluralName: 'Operating Systems',
	readonly: true,
})
export class OperatingSystem extends ModelBase {
	@EntityField({ data: 'OsBuild' })
	build: string;

	@EntityField({ data: 'OsRevision' })
	revision: string;

	@EntityField({ data: 'OsPlatform', required: true })
	osPlatform: OperatingSystemPlatform;

	@EntityField({ data: 'OsPlatform', required: true })
	osPlatformString: string;

	@EntityField({ data: 'OsPlatformFriendlyName' })
	osPlatformFriendlyName: OperatingSystemPlatform;

	@EntityField({ data: 'OsPlatformFriendlyName' })
	osPlatformFriendlyNameString: string;

	get platform(): OperatingSystemPlatform {
		return this.osPlatformFriendlyName || (!this.isIot && this.osPlatform) || {
			id: this.osPlatformFriendlyNameString,
			name: this.osPlatformFriendlyNameString,
			categoryName: 'Other',
			category: OperatingSystemPlatformCategories.OtherPlatform,
			isBackendSupportedFilter: false
		};
	}

	@EntityField({
		data: 'OsProcessor',
		parse: processor => (processor === 'x32' ? 'x86' : processor),
	})
	processor: string;

	@EntityField({ data: 'OsVersion' })
	version: string;

	@EntityField({ data: 'OsVersionFriendlyName' })
	osVersionFriendlyNameString: string;

	get osVersionFriendlyName() {
		if ((this.osVersionFriendlyNameString === 'Other' && this.osPlatformFriendlyNameString === 'Other') ||
			// There is no official version name for WindowsServer2022, see https://docs.microsoft.com/en-us/windows/release-health/windows-server-release-info
			this.platform.category === OperatingSystemPlatformCategories.WindowsServer2022) {
			return null;
		} else {
			return this.osVersionFriendlyNameString;
		}
	}

	@EntityField({ data: 'ReleaseVersion' })
	releaseVersion: ReleaseVersion;

	@EntityField({ data: 'IsManagedByMdatp', parse: isManagedByMdatp => isManagedByMdatp === false })
	private isIot: boolean;

	get fullBuild() {
		return this.platform.category !== OperatingSystemPlatformCategories.macOS &&
			   this.platform.category !== OperatingSystemPlatformCategories.Linux &&
			   (this.build ? this.build + (this.revision ? '.' + this.revision : '') : '');
	}
}
