import { SevilleModule } from '../../seville/seville.module';
import { userAccountService } from '../../seville/services/seville.userAccounts';
import './seville.threatintel.demoTenantResolver';
import './seville.threatintel.machineClassificationService';
import { RegExpService } from '@wcd/shared';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { MachineModel } from '../../../../@entities/machines/models/machine.model';
import { Feature } from '@wcd/config';
import { compact, find, assign } from 'lodash-es';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

SevilleModule.factory('sidePaneDataProviderService', sidePaneDataProviderService);

sidePaneDataProviderService.$inject = [
	'$http',
	'urlMapping',
	'$filter',
	'$log',
	'demoTenantResolverService',
	'$q',
	'machineClassificationService',
	'machinesService',
	'featuresService',
	'rbacMachineGroupService',
	'$localStorage',
	'appInsights',
];

function sidePaneDataProviderService(
	$http,
	urlMapping,
	$filter,
	$log,
	demoTenantResolverService,
	$q,
	machineClassificationService,
	machinesService: MachinesService,
	featuresService,
	rbacMachineGroupService,
	$localStorage,
	appInsights: AppInsightsService
) {
	const isRbacEnabled = featuresService.isEnabled(Feature.RbacMachineGroups);
	let userExposedRbacGroups = undefined;

	var service = {
		loadFileProfile: function(sha, sidePaneInstance) {
			return $q(function(resolve, reject) {
				if (!sha || sha == 'Hash could not be retrieved') {
					reject({ error: 'Data could not be retrieved.' });
					return;
				}

				$log.debug('loading file details for ' + sha);
				var profileUrl = urlMapping.getThreatIntelUrl() + '/files';
				$http
					.get(profileUrl, {
						params: {
							searchTerm: sha,
							searchBy: sha.length == 40 ? 'sha1' : 'sha256',
							lookingBackIndays: 180,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 404) {
								reject({
									error: 'No telemetry found in your network for this file.',
								});
							} else if (response.status == 200) {
								var signerVal = !response.data.Signer
									? 'unsigned'
									: response.data.IsValidCertificate
									? response.data.Signer
									: response.data.Signer.indexOf('INVALID') == 0
									? response.data.Signer
									: 'INVALID: ' + response.data.Signer;

								var issuerVal = !response.data.Issuer
									? 'unsigned'
									: response.data.IsValidCertificate
									? response.data.Issuer
									: response.data.Issuer.indexOf('INVALID') == 0
									? response.data.Issuer
									: 'INVALID: ' + response.data.Issuer;

								resolve({
									section: [
										{
											key: 'SHA1',
											value: response.data.Sha1,
											type: 'copy-block',
											order: 1,
										},
										{
											key: 'SHA256',
											value: response.data.Sha256,
											type: 'copy-block',
											order: 2,
										},
										{
											key: 'MD5',
											value: response.data.Md5,
											type: 'copy-block',
											order: 3,
										},
										{
											key: 'Size',
											value: $filter('bytes')(response.data.Size),
											type: 'primitive',
											order: 4,
										},
										{
											key: 'Signer',
											value: signerVal,
											type: 'primitive',
											order: 5,
										},
										{
											key: 'Issuer',
											value: issuerVal,
											type: 'primitive',
											order: 6,
										},
									],
									fileProfile: response.data,
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadUserProfile: function(accountName, accountDomainName, accountSid, sidePaneInstance) {
			return $q(function(resolve, reject) {
				$log.debug('loading user information for user: ' + accountName + '\\' + accountDomainName);
				$http
					.get(urlMapping.getThreatIntelUrl() + '/user', {
						params: {
							userAccount: accountName,
							userAccountDomain: accountDomainName,
							sid: accountSid,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('user profile loaded successfully');
								var data = response.data;
								resolve({
									section: compact([
										{
											key: 'First seen',
											value: data.FirstSeen,
											type: 'timeago',
											order: 1,
										},
										{
											key: 'Last seen',
											value: data.LastSeen,
											type: 'timeago',
											order: 2,
										},
										{
											key: 'Logged on devices',
											value: $filter('megaNumber')(data.LoggedOnMachines),
											type: 'primitive',
											order: 3,
										},
										data.MostPrevalentSenseMachineId && data.MostPrevalentComputerDnsName
											? {
													key: 'Most frequent',
													type: 'link',
													value: {
														state: 'machine',
														params: {
															id: data.MostPrevalentSenseMachineId,
														},
														text: $filter('parts')(
															data.MostPrevalentComputerDnsName,
															1
														),
														icon: 'DeviceLaptopNoPic',
													},
													order: 4,
													style: 'overflow white-space',
											  }
											: null,
										data.LeastPrevalentSenseMachineId &&
										data.LeastPrevalentComputerDnsName
											? {
													key: 'Least frequent',
													type: 'link',
													value: {
														state: 'machine',
														params: {
															id: data.LeastPrevalentSenseMachineId,
														},
														text: $filter('parts')(
															data.LeastPrevalentComputerDnsName,
															1
														),
														icon: 'DeviceLaptopNoPic',
													},
													order: 5,
													style: 'overflow white-space',
											  }
											: null,
									]),
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadIpProfile: function(ip, sidePaneInstance, eventTime) {
			return $q(function(resolve, reject) {
				var lookback = demoTenantResolverService.isDemoTenant()
					? demoTenantResolverService.demoTenantLookback
					: 30;

				$log.debug('loading ip information for ip: ' + ip);
				$http
					.get(urlMapping.getThreatIntelUrl() + '/GeoLocation', {
						params: {
							ip: ip,
							lookbackInDays: lookback,
							eventTime: eventTime,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('ip profile loaded successfully');
								var data = response.data;
								resolve({
									section: [
										{
											key: 'Organization',
											value: data.Organization,
											type: 'primitive',
											order: 1,
											style: 'text-uppercase overflow',
										},
										{
											key: 'ASN',
											value: data.Asn,
											type: 'primitive',
											order: 2,
											style: 'text-capitalize',
										},
										{
											key: 'Country',
											value: data.Country,
											type: 'primitive',
											order: 3,
											style: 'text-capitalize',
										},
										{
											key: 'State',
											value: data.State,
											type: 'primitive',
											order: 4,
											style: 'text-capitalize',
										},
										{
											key: 'City',
											value: data.City,
											type: 'primitive',
											order: 5,
											style: 'text-capitalize',
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadMachineProfile: function(machineid, sidePaneInstance) {
			return $q(function(resolve, reject) {
				var senseMachineIdRegex = /^[A-Fa-f0-9]{40}$/;
				var machineIdType = 'Id';

				if (machineid.length == 40 && senseMachineIdRegex.test(machineid)) {
					machineIdType = 'SenseMachineId';
				}

				$log.debug('loading machine information for machineid: ' + machineid);
				$http
					.get(urlMapping.getThreatIntelUrl() + '/machines?', {
						params: {
							machineId: machineid,
							lookingBackIndays: 180,
							idType: machineIdType,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('machine profile loaded successfully');
								var data = response.data;
								var sections = {
									'Device details': [
										{
											key: 'Domain',
											value: $filter('domain')(data.ComputerDnsName, false),
											type: 'primitive',
											order: 1,
											style: 'overflow white-space',
										},
										{
											key: 'OS',
											value:
												data.OsPlatform +
												' ' +
												$filter('processor')(data.OsProcessor),
											type: 'primitive',
											order: 2,
											style: 'text-capitalize',
										},
										{
											key: 'Health state',
											value: $filter('healthStatusText')(data.HealthStatus, 'summary'),
											type: 'primitive',
											order: 3,
											style: 'overflow white-space',
										},
									],
									'Last IP addresses': [
										{
											key: 'External IP',
											value: data.LastExternalIpAddress,
											type: 'primitive',
											order: 1,
										},
										{
											key: 'Internal IP',
											value: data.LastIpAddress,
											type: 'primitive',
											order: 2,
										},
									],
									'Device reporting': [
										{
											key: 'First seen',
											value: data.FirstSeen,
											type: 'timeago',
											order: 1,
										},
										{
											key: 'Last seen',
											value: data.LastSeen,
											type: 'timeago',
											order: 2,
										},
									],
									'Device properties': {
										internalId: data.InternalMachineId,
										computerDnsName: $filter('parts')(data.ComputerDnsName, 1),
										machineId: machineid,
										osPlatform: data.OsPlatform,
									},
								};
								let sectionCandidate;

								// Change content of this section
								sections['Last IP addresses'] = [
									{
										key: 'Last updated',
										value: data.LastSeen,
										type: 'datetime',
										order: 1,
									},
									{
										key: 'Link to IP pane',
										value: {
											method: () =>
												machinesService.showMachineIpsPanel(new MachineModel(data)),
											html:
												'<span class="btn-link nopadding">See all IPs <i class="icon icon-ChevronRightSm"></i></span>',
										},
										type: 'clickable',
										order: 2,
									},
								];

								if (isRbacEnabled && data.RbacGroupId) {
									rbacMachineGroupService.getUserExposedRbacGroups().then(response => {
										userExposedRbacGroups = response.data;
										sectionCandidate = {
											key: 'Group',
											type: 'primitive',
											order: 3,
											style: 'overflow white-space',
										};
										const machineRbacGroup = find(
											userExposedRbacGroups.data,
											(item: any) => {
												return item.id === data.RbacGroupId;
											}
										);
										machineRbacGroup && machineRbacGroup.label
											? assign(sectionCandidate, {
													value: machineRbacGroup.label,
											  })
											: assign(sectionCandidate, {
													value: 'Not Available',
											  });
										sectionCandidate.value &&
											sections['Machine details'].push(sectionCandidate);
										resolve(sections);
									});
								} else {
									sectionCandidate = {
										key: 'Group',
										value: data.MachineGroup,
										type: 'primitive',
										order: 3,
										style: 'overflow white-space',
									};
									sectionCandidate.value &&
										sections['Machine details'].push(sectionCandidate);
									resolve(sections);
								}
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		getUrlDetails: function(url) {
			return [
				{
					key: 'Details',
					value: {
						href: `https://www.whois.com/whois/${url}`,
						text: 'View details at WhoIs',
						newWindow: true,
					},
					type: 'link',
					order: 1,
				},
			];
		},

		loadUserDetailsOnMachine: function(machineid, sidePaneInstance, osPlatform) {
			return $q(function(resolve, reject) {
				$log.debug('loading user details for machineid: ' + machineid);

				$http
					.get(urlMapping.getThreatIntelUrl() + '/UserDetailsOnMachine', {
						params: {
							senseMachineId: machineid,
							lookingBackIndays: 30,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('user details on machine loaded successfully');
								var data = response.data;

								// if there are no logged on users - only show the number , without most and least user accounts
								if (data.LoggedOnusers == 0) {
									resolve({
										section: [
											{
												key: 'Count',
												value: data.LoggedOnusers,
												type: 'primitive',
												order: 1,
												style: 'overflow white-space',
											},
										],
									});
								} else {
									let isUserLinkAllowed = true;
									if (osPlatform) {
										isUserLinkAllowed = osPlatform.toLowerCase() !== 'windows7';
									} else {
										appInsights.trackException(
											new Error(
												`Machine side pane: Machine '${machineid}' is missing os platform.`
											)
										);
									}
									const mostPrevalentUserText = userAccountService.resolveUserName(
										data.MostPrevalentAccountName,
										data.MostPrevalentAccountDomainName
									);
									const leastPrevalentUserText = userAccountService.resolveUserName(
										data.LeastPrevalentAccountName,
										data.LeastPrevalentAccountDomainName
									);
									resolve({
										section: [
											{
												key: 'Count',
												value: data.LoggedOnusers,
												type: 'primitive',
												order: 1,
												style: 'overflow white-space',
											},
											{
												key: 'Most frequent',
												type: !isUserLinkAllowed ? 'primitive' : 'link',
												value: !isUserLinkAllowed
													? mostPrevalentUserText
													: {
															state: 'user',
															params: {
																id: data.MostPrevalentAccountName,
																userdomain:
																	data.MostPrevalentAccountDomainName,
															},
															text: mostPrevalentUserText,
															icon: 'Contact',
													  },
												order: 2,
												style: 'overflow white-space',
											},
											{
												key: 'Least frequent',
												type: !isUserLinkAllowed ? 'primitive' : 'link',
												value: !isUserLinkAllowed
													? leastPrevalentUserText
													: {
															state: 'user',
															params: {
																id: data.LeastPrevalentAccountName,
																userdomain:
																	data.LeastPrevalentAccountDomainName,
															},
															text: leastPrevalentUserText,
															icon: 'Contact',
													  },
												order: 3,
												style: 'overflow white-space',
											},
										],
									});
								}
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadVirusTotalData: function(sha, sidePaneInstance, fileProfile) {
			return $q(function(resolve, reject) {
				$log.debug('loading virus total file report information, for file with id=' + sha);

				var vtFileReportUrl = urlMapping.getThreatIntelUrl() + '/VirusTotalFileReport';

				$http
					.get(vtFileReportUrl, {
						params: {
							fileIdentifier: sha,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							var retVal;
							if (
								response.status == 200 &&
								response.data.positives != null &&
								response.data.total != null &&
								response.data.permalink
							) {
								retVal = {
									section: [
										{
											key: 'VirusTotal detection ratio',
											value: response.data.positives + '/' + response.data.total,
											type: 'primitive',
											link: {
												icon: 'OpenInNewWindow',
												href: response.data.permalink,
												newWindow: true,
											},
											order: 1,
										},
									],
								};
							} else {
								retVal = {
									section: [
										{
											key: 'VirusTotal detection ratio',
											value: 'File not found in VirusTotal',
											type: 'primitive',
											order: 1,
										},
									],
								};
							}

							if (fileProfile.Threat) {
								retVal.section.push({
									key: 'Windows Defender AV',
									value: fileProfile.Threat,
									type: 'primitive',
									link: {
										icon: 'ReadingMode',
										href:
											'http://go.microsoft.com/fwlink/?LinkID=142185&Name=' +
											fileProfile.Threat,
										newWindow: true,
									},
									order: 2,
								});
							} else {
								retVal.section.push({
									key: 'Windows Defender AV',
									value: 'No detections found',
									type: 'primitive',
									order: 2,
									style: 'overflow',
								});
							}

							resolve(retVal);
						},
						function(response) {
							reject({
								error: 'Virus Total File Report Data could not be retrieved.',
							});
						}
					);
			});
		},

		loadFileStats: function(fileIdentifier, sidePaneInstance, fileProfile) {
			var sha1 = getValidSha1OrNull(fileIdentifier);
			var sha256 = getValidSha256OrNull(fileIdentifier);

			return $q(function(resolve, reject) {
				var statsUrl = urlMapping.getThreatIntelUrl() + '/FileStats';
				$http
					.get(statsUrl, {
						params: {
							sha1: sha1,
							sha256: sha256,
							lookingBackIndays: demoTenantResolverService.getStatsLookback(),
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200 && response.data) {
								var data = response.data;
								var orgFirstseen = data.OrgFirstSeen;
								var orgLastseen = data.OrgLastSeen;
								var orgPrevalence = data.OrgPrevalence ? parseInt(data.OrgPrevalence) : 0;

								var worldPrevalence = fileProfile.TelemetryPrevalence
									? parseInt(fileProfile.TelemetryPrevalence)
									: 0;

								if (worldPrevalence < orgPrevalence) {
									worldPrevalence = orgPrevalence;
								}

								if (
									(!fileProfile.FirstObserved && orgFirstseen) ||
									fileProfile.FirstObserved > orgFirstseen
								) {
									fileProfile.FirstObserved = orgFirstseen;
								}

								if (
									(!fileProfile.LastObserved && orgLastseen) ||
									fileProfile.LastObserved < orgLastseen
								) {
									fileProfile.LastObserved = orgLastseen;
								}

								resolve({
									'Observed worldwide': [
										{
											key: 'Count',
											value: $filter('megaNumber')(worldPrevalence),
											type: 'primitive',
											order: 1,
										},
										{
											key: 'First seen',
											value: fileProfile.FirstObserved,
											type: 'timeago',
											order: 2,
										},
										{
											key: 'Last seen',
											value: fileProfile.LastObserved,
											type: 'timeago',
											order: 3,
										},
									],

									'Observed in organization': [
										{
											key: 'Count',
											value: $filter('megaNumber')(orgPrevalence),
											type: 'primitive',
											order: 1,
										},
										{
											key: 'First seen',
											value: orgFirstseen,
											type: 'timeago',
											order: 2,
										},
										{
											key: 'Last seen',
											value: orgLastseen,
											type: 'timeago',
											order: 3,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadIpStats: function(ip, sidePaneInstance) {
			return $q(function(resolve, reject) {
				var statsUrl = urlMapping.getThreatIntelUrl() + '/IpStats';
				$http
					.get(statsUrl, {
						params: {
							ip: ip,
							lookingBackIndays: demoTenantResolverService.getStatsLookback(),
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200 && response.data) {
								var data = response.data;
								var orgFirstseen = data.OrgFirstSeen;
								var orgLastseen = data.OrgLastSeen;
								var orgPrevalence = data.OrgPrevalence ? parseInt(data.OrgPrevalence) : 0;

								resolve({
									section: [
										{
											key: 'Count',
											value: $filter('megaNumber')(orgPrevalence),
											type: 'primitive',
											order: 1,
										},
										{
											key: 'First seen',
											value: orgFirstseen,
											type: 'timeago',
											order: 2,
										},
										{
											key: 'Last seen',
											value: orgLastseen,
											type: 'timeago',
											order: 3,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadUrlStats: function(url, sidePaneInstance) {
			return $q(function(resolve, reject) {
				var domain = decodeURIComponent(url);
				var statsUrl = urlMapping.getThreatIntelUrl() + '/UrlStats';
				$http
					.get(statsUrl, {
						params: {
							url: domain,
							lookingBackIndays: demoTenantResolverService.getStatsLookback(),
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200 && response.data) {
								var data = response.data;
								var orgFirstseen = data.OrgFirstSeen;
								var orgLastseen = data.OrgLastSeen;
								var orgPrevalence = data.OrgPrevalence ? parseInt(data.OrgPrevalence) : 0;

								resolve({
									section: [
										{
											key: 'Count',
											value: $filter('megaNumber')(orgPrevalence),
											type: 'primitive',
											order: 1,
										},
										{
											key: 'First seen',
											value: orgFirstseen,
											type: 'timeago',
											order: 2,
										},
										{
											key: 'Last seen',
											value: orgLastseen,
											type: 'timeago',
											order: 3,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadAlertsForFile: function(fileIdentifier, sidePaneInstance) {
			var sha1 = getValidSha1OrNull(fileIdentifier);
			var sha256 = getValidSha256OrNull(fileIdentifier);

			return $q(function(resolve, reject) {
				$log.debug('loading alerts, for file with id=' + fileIdentifier);

				var alertsUrl = urlMapping.getActiveAlertsSummaryUrl() + '/activeAlertsSummary';

				$http
					.get(alertsUrl, {
						params: {
							Sha1: sha1,
							Sha256: sha256,
							lookingBackInDays: 180,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								resolve({
									section: [
										{
											key: 'Alerts',
											value: {
												high: response.data.HighSevAlerts,
												medium: response.data.MediumSevAlerts,
												low: response.data.LowSevAlerts,
												informational: response.data.InformationalSevAlerts,
											},
											type: 'activeAlerts',
											order: 0,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadAlertsForIp: function(ip, sidePaneInstance) {
			return $q(function(resolve, reject) {
				$log.debug('loading alerts, for ip with id=' + ip);

				var alertsUrl = urlMapping.getActiveAlertsSummaryUrl() + '/activeAlertsSummary';
				$http
					.get(alertsUrl, {
						params: {
							Ip: ip,
							lookingBackInDays: 180,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								resolve({
									section: [
										{
											key: 'Alerts',
											value: {
												high: response.data.HighSevAlerts,
												medium: response.data.MediumSevAlerts,
												low: response.data.LowSevAlerts,
												informational: response.data.InformationalSevAlerts,
											},
											type: 'activeAlerts',
											order: 0,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadAlertsForUrl: function(url, sidePaneInstance) {
			return $q(function(resolve, reject) {
				$log.debug('loading alerts, for url with id=' + url);

				var alertsUrl = urlMapping.getActiveAlertsSummaryUrl() + '/activeAlertsSummary';
				$http
					.get(alertsUrl, {
						params: {
							Url: url,
							lookingBackInDays: 180,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								resolve({
									section: [
										{
											key: 'Alerts',
											value: {
												high: response.data.HighSevAlerts,
												medium: response.data.MediumSevAlerts,
												low: response.data.LowSevAlerts,
												informational: response.data.InformationalSevAlerts,
											},
											type: 'activeAlerts',
											order: 0,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadAlertsForUser: function(accountName, accountDomainName, accountSid, sidePaneInstance) {
			return $q(function(resolve, reject) {
				$log.debug('loading alerts, for user ' + accountName + '\\' + accountDomainName);

				var alertsUrl = urlMapping.getActiveAlertsSummaryUrl() + '/activeAlertsSummary';
				$http
					.get(alertsUrl, {
						params: {
							AccountName: accountName,
							AccountDomainName: accountDomainName,
							AccountSid: accountSid,
							lookingBackInDays: 30,
							includeEventReferences: true,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								resolve({
									section: [
										{
											key: 'Alerts',
											value: {
												high: response.data.HighSevAlerts,
												medium: response.data.MediumSevAlerts,
												low: response.data.LowSevAlerts,
												informational: response.data.InformationalSevAlerts,
											},
											type: 'activeAlerts',
											order: 0,
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},

		loadUserSidePaneDetails: function(accountName, accountDomainName, sidePaneInstance) {
			return $q(function(resolve, reject) {
				$log.debug('loading user information for user: ' + accountName + '\\' + accountDomainName);
				$http
					.get(urlMapping.getThreatIntelUrl() + '/UserSidePaneDetails', {
						params: {
							userAccount: accountName,
							userAccountDomain: accountDomainName,
						},
						timeout: sidePaneInstance.requestsCanceler(),
					})
					.then(
						function(response) {
							if (response.status == 200) {
								$log.debug('UserSidePaneDetails loaded successfully');
								var data = response.data;
								resolve({
									section: [
										{
											key: 'Role',
											value: resolveUserRoles(data),
											type: 'primitive',
											order: 1,
										},
										{
											key: 'Logon types',
											value: data.LogonTypes,
											type: 'primitive',
											order: 2,
											style: 'text-capitalize',
										},
									],
								});
							} else {
								reject({ error: 'Data could not be retrieved.' });
							}
						},
						function(response) {
							reject({ error: 'Data could not be retrieved.' });
						}
					);
			});
		},
	};

	function resolveUserRoles(data) {
		if (data.IsDomainAdmin && data.IsLocalAdmin) {
			return 'Domain admin, Local admin';
		}

		if (data.IsDomainAdmin) {
			return 'Domain admin';
		}

		if (data.IsLocalAdmin) {
			return 'Local admin';
		}

		return '';
	}

	function getValidSha1OrNull(fileId) {
		return fileId && RegExpService.sha1.test(fileId) ? fileId : null;
	}

	function getValidSha256OrNull(fileId) {
		return fileId && RegExpService.sha256.test(fileId) ? fileId : null;
	}

	return service;
}
