import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {I18nService} from '@wcd/i18n';
import {SCC_BASE_URL} from '../../shared/main/services/main-nav-config.service';
import {TryItUrls} from '../enums/try-it-urls';
import {MtpPromotionService} from '../services/mtp-promotion.service';

@Component({
	selector: 'promotion-banner',
	template: `
		<wcd-banner
			*ngIf="navigateTo"
			[text]="text"
			[linkOptions]="{
				id: 'mtpPromotion_TryNow',
				buttonTextKey: 'mtpPromotion.tryNow',
				navigateTo: navigateTo
			}"
			(onClose)="close()"
		>
		</wcd-banner>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PromotionBannerComponent {
	@Input() set textMessageKey(value: string) {
		this.text = this.i18nService.get(value) + this.i18nService.get('mtpPromotion.learnMoreLink');
	}
	@Input() trackId: string;
	@Input() set url(value: TryItUrls) {
		this.navigateTo = `${SCC_BASE_URL}/${value}`;
	}

	text: string;
	navigateTo: string;

	constructor(private i18nService: I18nService, private mtpPromotionService: MtpPromotionService) {}

	close = (): void => {
		this.navigateTo = null;
		this.mtpPromotionService.dismissMtpPromotion(true);
	};
}
