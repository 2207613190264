import { NgModule } from '@angular/core';
import { I18nModule } from '../../../../../../../../projects/i18n/src/public_api';
import { ServiceNowProvisioningComponent } from './components/servicenow-provisioning.component';
import { SharedModule } from '../../../../shared/shared.module';
import { ItsmProvisioningService } from './services/itsm-provisioning.service';
import { FabSpinnerModule, FabMessageBarModule } from '@angular-react/fabric';

@NgModule({
	imports: [SharedModule, I18nModule, FabSpinnerModule, FabMessageBarModule],
	declarations: [ServiceNowProvisioningComponent],
	exports: [],
	providers: [ItsmProvisioningService],
})
export class ItsmProvisioningModule {}
