import { Identifiable } from '../../data/models/identifiable.model';
import { ReadOnlyIdentifiableConfig } from '../../data/models/readonly-identifiable.model';

/**
 * old tag model. please use "Tag" entity.
 * @deprecated
 */
export class TagModel extends Identifiable<number> {
	isPartial: boolean = false;
	isEditable: boolean = true;
	tooltip?: string;

	constructor(data?: TagConfig | string) {
		super(typeof data === 'string' ? null : data);

		if (typeof data === 'string') this.name = data;
		else this.isEditable = (<TagConfig>data).isEditable !== false;
	}

	setData(data: TagConfig) {
		super.setData(data);
	}

	getBackendData() {
		return {
			name: this.name,
		};
	}
}

export interface TagConfig extends ReadOnlyIdentifiableConfig {
	isEditable?: boolean;
}
