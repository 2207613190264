var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var _a, _b;
import { Feature, FeaturesService } from '@wcd/config';
import { LiveResponseSettings, OperatingSystemPlatformCategories, } from '@wcd/domain';
import { map } from 'rxjs/operators';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { AppConfigService } from '@wcd/app-config';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i3 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i5 from "../../../rbac/services/rbac-control.service";
var SUPPORTED_OS = [
    OperatingSystemPlatformCategories.Windows10,
    OperatingSystemPlatformCategories.Windows10WVD,
    OperatingSystemPlatformCategories.Windows11,
];
var FLIGHTED_SERVER_MAP = (_a = {},
    _a[OperatingSystemPlatformCategories.WindowsServer2019] = Feature.Server2019BackportSupportToRS6,
    _a[OperatingSystemPlatformCategories.WindowsServerSAC] = Feature.Server2019BackportSupportToRS6,
    _a[OperatingSystemPlatformCategories.WindowsServer2016] = Feature.LiveResponseForWindowsServer2016,
    _a[OperatingSystemPlatformCategories.WindowsServer2012R2] = Feature.LiveResponseForWindowsServer2012R2,
    _a[OperatingSystemPlatformCategories.Linux] = Feature.LiveResponseForLinux,
    _a[OperatingSystemPlatformCategories.WindowsServer2022] = Feature.Server2022BackportSupport,
    _a);
var FLIGHTED_OS_MAP = __assign({}, FLIGHTED_SERVER_MAP, (_b = {}, _b[OperatingSystemPlatformCategories.macOS] = Feature.LiveResponseForMac, _b));
var SUPPORTED_CLOUD_ACTIONS = [
    'IsolationResponse',
    'ScanResponse',
    'ForensicsResponse',
];
var SUPPORTED_CLOUD_OS = [
    OperatingSystemPlatformCategories.WindowsServer2016,
    OperatingSystemPlatformCategories.WindowsServer2012R2,
    OperatingSystemPlatformCategories.Linux,
    OperatingSystemPlatformCategories.macOS,
];
var LIVERESPONSE_V3_TAG_NAME = 'LiveResponseV3';
var LiveResponsePermissionsService = /** @class */ (function () {
    function LiveResponsePermissionsService(paris, featuresService, i18nService, appConfigService, rbacControlService) {
        this.paris = paris;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.appConfigService = appConfigService;
        this.rbacControlService = rbacControlService;
    }
    LiveResponsePermissionsService.prototype.isLiveResponseSupportedForMachine = function (machine) {
        return __awaiter(this, void 0, void 0, function () {
            var osCategory, isOsSupported, useV2Api, useV3Api, isLiveResponseForServersEnabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (machine.isMdatp && !machine.isManagedByMdatp) {
                            return [2 /*return*/, {
                                    disabled: true,
                                    disableReason: this.i18nService.strings
                                        .machines_entityDetails_actions_createLiveResponse_not_available_for_device,
                                }];
                        }
                        // LR prop validation
                        if (!this.appConfigService.isLiveResponseEnabled) {
                            return [2 /*return*/, {
                                    disabled: true,
                                    disableReason: this.i18nService.strings
                                        .machines_entityDetails_actions_createLiveResponse_disabled_propertyOff,
                                }];
                        }
                        osCategory = machine && machine.os && machine.os.platform && machine.os.platform.category;
                        isOsSupported = SUPPORTED_OS.includes(osCategory) ||
                            (osCategory in FLIGHTED_OS_MAP && this.featuresService.isEnabled(FLIGHTED_OS_MAP[osCategory]));
                        if (!(isOsSupported && osCategory in FLIGHTED_SERVER_MAP)) return [3 /*break*/, 3];
                        if (!this.featuresService.isEnabled(Feature.LiveResponseForServerExcludeTenant)) return [3 /*break*/, 1];
                        isOsSupported = false;
                        return [3 /*break*/, 3];
                    case 1:
                        useV2Api = this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation);
                        useV3Api = this.featuresService.isEnabled(Feature.CloudLiveResponseV3) || machine.extendedTags.userDefinedTags.includes(LIVERESPONSE_V3_TAG_NAME);
                        return [4 /*yield*/, this.paris
                                .getItemById(LiveResponseSettings, 1, null, { useV2Api: useV2Api, useV3Api: useV3Api })
                                .pipe(map(function (_a) {
                                var liveResponseForServers = _a.liveResponseForServers;
                                return liveResponseForServers;
                            }))
                                .toPromise()];
                    case 2:
                        isLiveResponseForServersEnabled = _a.sent();
                        if (!isLiveResponseForServersEnabled) {
                            return [2 /*return*/, {
                                    disabled: true,
                                    disableReason: this.i18nService.strings
                                        .machines_entityDetails_actions_createLiveResponse_disabled_serversOff,
                                }];
                        }
                        _a.label = 3;
                    case 3:
                        // RBAC check
                        if (!this.rbacControlService.hasRequiredRbacPermissions({
                            permissions: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.liveResponseAdvanced],
                        })) {
                            return [2 /*return*/, {
                                    disabled: true,
                                    disableReason: this.i18nService.strings.common_permissions_noPermissionTooltip,
                                }];
                        }
                        // Unsupported OS check
                        if (!isOsSupported) {
                            return [2 /*return*/, {
                                    disabled: true,
                                    disableReason: this.i18nService.strings
                                        .machines_entityDetails_actions_createLiveResponse_disabled_unsupportedOs,
                                }];
                        }
                        return [2 /*return*/, { disabled: false }];
                }
            });
        });
    };
    LiveResponsePermissionsService.prototype.isCloudActionSupported = function (machine, actionType) {
        return __awaiter(this, void 0, void 0, function () {
            var osCategory, disabled;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        osCategory = machine && machine.os && machine.os.platform && machine.os.platform.category;
                        if (!(SUPPORTED_CLOUD_ACTIONS.includes(actionType) && SUPPORTED_CLOUD_OS.includes(osCategory))) {
                            return [2 /*return*/, false];
                        }
                        return [4 /*yield*/, this.isLiveResponseSupportedForMachine(machine)];
                    case 1:
                        disabled = (_a.sent()).disabled;
                        return [2 /*return*/, !disabled];
                }
            });
        });
    };
    // coupled with perimission validation in the cloud - AutomatedIR.LiveResponse - src/airs/web/website/live_response/views.py
    LiveResponsePermissionsService.prototype.hasLibraryPermissions = function () {
        var hasAdvancedPermissions = this.rbacControlService.hasRequiredRbacPermissions({
            permissions: [RbacMdeAllowedActions.liveResponseAdvanced]
        });
        var hasBasicAndSecurityPermissions = this.rbacControlService.hasRequiredRbacPermissions({
            permissions: [RbacMdeAllowedActions.liveResponseBasic, RbacMdeAllowedActions.securitySettings],
            requireAllPermissions: true
        });
        return hasAdvancedPermissions || hasBasicAndSecurityPermissions;
    };
    LiveResponsePermissionsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LiveResponsePermissionsService_Factory() { return new LiveResponsePermissionsService(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.FeaturesService), i0.ɵɵinject(i3.I18nService), i0.ɵɵinject(i4.AppConfigService), i0.ɵɵinject(i5.RbacControlService)); }, token: LiveResponsePermissionsService, providedIn: "root" });
    return LiveResponsePermissionsService;
}());
export { LiveResponsePermissionsService };
