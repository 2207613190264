import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ModelBase, DataEntityType } from '@microsoft/paris';
import { NgClassInput } from '@wcd/angular-extensions';

export interface EntityNameInput<T extends ModelBase = ModelBase> {
	entity: T;
	entityTypeId: string | number | DataEntityType<T>;
	entityName?: string;
	tooltip?: string;
	linkClass?: string;
}

@Component({
	selector: 'entity-names',
	template: `
		<div *ngIf="entities" [ngClass]="className">
			<entity-name
				*ngFor="let entity of entities"
				[entity]="entity.entity"
				[entityTypeId]="entity.entityTypeId"
				[entityName]="entity.entityName"
				[tooltip]="entity.tooltip"
				[hideIcon]="hideIcon"
				[ngClass]="entityNameClass"
				[linkClass]="entity.linkClass"
			>
			</entity-name>
		</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityNamesComponent {
	@Input()
	entities: EntityNameInput[];

	@Input()
	hideIcon: boolean;

	@Input()
	className: NgClassInput = 'wcd-flex-horizontal';

	@Input()
	entityNameClass: NgClassInput = 'wcd-flex-1 wcd-text-overflow-ellipsis';
}
