<wcd-panel
	(close)="destroy()"
	[settings]="settings"
	class="wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="missing-kb-related-cves-panel"
	data-track-component-type="Side Panel"
>
	<div class="wcd-flex-horizontal wcd-padding-large-horizontal">
		<h4 class="wcd-flex-1 wcd-padding-bottom side-panel-title">
			{{ 'tvm.common.relatedCVE.title' | i18n }}
		</h4>
		<button
			class="tvm-show-more-csv-export-button"
			*ngIf="isExportEnabled"
			(click)="exportCveData()"
			[wcdTooltip]="exportTooltipText || 'Export data to CSV'"
		>
			<wcd-shared-icon iconName="Download"></wcd-shared-icon>
			<span>{{ 'export' | i18n }}</span>
		</button>
	</div>
	<dataview
		class="wcd-flex-1"
		*ngIf="_repository"
		id="related-vulnerabilities-dataview"
		[repository]="_repository"
		[navigateOnChange]="false"
		[allowPaging]="true"
		[allowFilters]="false"
		[searchEnabled]="true"
		[ignoreQueryParams]="true"
		[defaultSortFieldId]="'severity'"
		[allowColumnCustomization]="false"
		[disableSelection]="true"
		(onItemClick)="openVulnerabilityPanel($event.item)"
		[fields]="fields"
		[label]="'tvm.common.relatedCVE.title' | i18n"
	>
	</dataview>
</wcd-panel>
