var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleConditionIocType } from './suppression-rule-condition-ioc-type.enum';
import { SuppressionRuleConditionIdType } from './suppression-rule-condition-id-type.enum';
import { SuppressionRuleType } from './suppression-rule-type.enum';
var ɵ0 = SuppressionRuleConditionIdType.FileName, ɵ1 = SuppressionRuleConditionIdType.FolderPath, ɵ2 = SuppressionRuleConditionIdType.Sha1, ɵ3 = SuppressionRuleConditionIdType.Ip, ɵ4 = SuppressionRuleConditionIdType.Url, ɵ5 = SuppressionRuleConditionIdType.CommandLine, ɵ6 = SuppressionRuleConditionIdType.UserSid, ɵ7 = SuppressionRuleConditionIdType.ContentSha256, ɵ8 = SuppressionRuleConditionIdType.FileSha256, ɵ9 = SuppressionRuleConditionIdType.ProcessSha256, ɵ10 = SuppressionRuleConditionIdType.AmsiContent, ɵ11 = SuppressionRuleConditionIdType.UserName, ɵ12 = SuppressionRuleConditionIdType.Consumer, ɵ13 = SuppressionRuleConditionIdType.Ess, ɵ14 = SuppressionRuleConditionIdType.Namespace, ɵ15 = SuppressionRuleConditionIdType.PossibleCause, ɵ16 = SuppressionRuleConditionIdType.TaskName, ɵ17 = SuppressionRuleConditionIdType.ParentProcessSha1, ɵ18 = SuppressionRuleConditionIdType.ParentProcessSha256, ɵ19 = SuppressionRuleConditionIdType.ParentProcessFolderPath, ɵ20 = SuppressionRuleConditionIdType.ParentProcessFileName, ɵ21 = SuppressionRuleConditionIdType.ParentProcessCommandLine, ɵ22 = SuppressionRuleConditionIdType.TaskExecutables, ɵ23 = SuppressionRuleConditionIdType.LdapSearchQuery, ɵ24 = SuppressionRuleConditionIdType.LdapDistinguishedName, ɵ25 = SuppressionRuleConditionIdType.RegistryValueName, ɵ26 = SuppressionRuleConditionIdType.RegistryKey, ɵ27 = SuppressionRuleConditionIdType.RegistryHive, ɵ28 = SuppressionRuleConditionIdType.WmiQuery, ɵ29 = SuppressionRuleConditionIdType.WmiClass;
var SuppressionRuleConditionType = /** @class */ (function (_super) {
    __extends(SuppressionRuleConditionType, _super);
    function SuppressionRuleConditionType() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], SuppressionRuleConditionType.prototype, "supportIocType", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], SuppressionRuleConditionType.prototype, "type", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "dataFieldName", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "propertyName", void 0);
    __decorate([
        EntityField({ defaultValue: true }),
        __metadata("design:type", Boolean)
    ], SuppressionRuleConditionType.prototype, "allowEdit", void 0);
    __decorate([
        EntityField({ defaultValue: false }),
        __metadata("design:type", Boolean)
    ], SuppressionRuleConditionType.prototype, "allowWildCard", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "pattern", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleConditionType.prototype, "patternErrorText", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], SuppressionRuleConditionType.prototype, "supportRuleTypes", void 0);
    SuppressionRuleConditionType = __decorate([
        Entity({
            singularName: 'Suppression Rule Condition Type',
            pluralName: 'Suppression Rule Condition Types',
            values: [
                {
                    id: ɵ0,
                    name: 'File name',
                    type: SuppressionRuleConditionIocType.File,
                    supportIocType: [SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'FileName',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: true,
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ1,
                    name: 'Folder path',
                    type: SuppressionRuleConditionIocType.File,
                    supportIocType: [SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'FolderPath',
                    propertyName: 'path',
                    allowEdit: true,
                    allowWildCard: true,
                    pattern: 'folderPath',
                    patternErrorText: 'Folder path must end with \\, *, or /',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ2,
                    name: 'SHA1',
                    type: SuppressionRuleConditionIocType.File,
                    supportIocType: [SuppressionRuleConditionIocType.File, SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'Sha1',
                    propertyName: 'sha1',
                    allowEdit: false,
                    pattern: 'sha1',
                    patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ3,
                    name: 'IP',
                    type: SuppressionRuleConditionIocType.Ip,
                    supportIocType: [SuppressionRuleConditionIocType.Ip],
                    dataFieldName: 'Ip',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: false,
                    pattern: 'ip',
                    patternErrorText: 'Ip address is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ4,
                    name: 'URL',
                    type: SuppressionRuleConditionIocType.Url,
                    supportIocType: [SuppressionRuleConditionIocType.Url],
                    dataFieldName: 'Url',
                    propertyName: 'name',
                    allowEdit: true,
                    allowWildCard: true,
                    patternErrorText: 'Url address is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ5,
                    name: 'Command line',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'CommandLine',
                    propertyName: 'command',
                    allowEdit: true,
                    allowWildCard: true,
                    patternErrorText: 'Command line is not valid',
                    supportRuleTypes: [SuppressionRuleType.Default, SuppressionRuleType.MDE],
                },
                {
                    id: ɵ6,
                    name: 'User',
                    type: SuppressionRuleConditionIocType.User,
                    supportIocType: [SuppressionRuleConditionIocType.User, SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'UserSid',
                    propertyName: 'userSid',
                    allowEdit: true,
                    allowWildCard: false,
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ7,
                    name: 'Script SHA256',
                    type: SuppressionRuleConditionIocType.AmsiScript,
                    supportIocType: [SuppressionRuleConditionIocType.AmsiScript],
                    dataFieldName: '20BA6007-0793-49E8-A609-10E33066DFC7',
                    propertyName: 'SHA256',
                    allowEdit: true,
                    allowWildCard: false,
                    pattern: 'sha256',
                    patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ8,
                    name: 'SHA256',
                    type: SuppressionRuleConditionIocType.File,
                    supportIocType: [SuppressionRuleConditionIocType.File],
                    dataFieldName: 'CE3AB7EE-6002-4ADE-B956-78E4327BCE7A',
                    propertyName: 'SHA256',
                    allowEdit: true,
                    allowWildCard: false,
                    pattern: 'sha256',
                    patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ9,
                    name: 'SHA256',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: '3D6BE1D4-264C-4DAC-9DEE-AAAFDD5D498E',
                    propertyName: 'SHA256',
                    allowEdit: true,
                    allowWildCard: false,
                    pattern: 'sha256',
                    patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ10,
                    name: 'Script content',
                    type: SuppressionRuleConditionIocType.AmsiScript,
                    supportIocType: [SuppressionRuleConditionIocType.AmsiScript],
                    dataFieldName: 'FE5EB4B1-2415-41C9-B351-99EB729A3926',
                    allowWildCard: false,
                    propertyName: 'script',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ11,
                    name: 'User name',
                    type: SuppressionRuleConditionIocType.User,
                    supportIocType: [SuppressionRuleConditionIocType.User],
                    dataFieldName: '17FC6469-9914-48F0-80A6-68B1128EAC72',
                    allowWildCard: false,
                    propertyName: 'userName',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ12,
                    name: 'Consumer',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: 'DD39759A-5102-4869-B67F-38F4F567717B',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'consumer',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ13,
                    name: 'Ess',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: '29CE382C-C613-4623-84F8-9317DA5C7A2A',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'ess',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ14,
                    name: 'Namespace',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: 'A27CAEEF-15A1-409C-A9A1-E829E7C18BAC',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'namespace',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ15,
                    name: 'Possible cause',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: '2997B7D2-3208-4AC6-83FF-C7CF74762615',
                    allowEdit: true,
                    allowWildCard: false,
                    propertyName: 'possibleCause',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ16,
                    name: 'Task name',
                    type: SuppressionRuleConditionIocType.ScheduledTask,
                    supportIocType: [SuppressionRuleConditionIocType.ScheduledTask],
                    dataFieldName: 'FA8284B0-3F19-4895-9909-31C1F7DDBE48',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'task',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ17,
                    name: 'Parent process SHA1',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'C27C7233-8926-41D8-8E9D-59ED83E3C017',
                    allowWildCard: false,
                    allowEdit: false,
                    pattern: 'sha1',
                    patternErrorText: 'Sha1 must be hexadecimal number 40 digits long',
                    propertyName: 'parentProcessSha1',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ18,
                    name: 'Parent process SHA256',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: '1BC91DAA-D8C9-490C-802B-81DE4A551327',
                    allowWildCard: false,
                    allowEdit: false,
                    pattern: 'sha256',
                    patternErrorText: 'Sha256 must be hexadecimal number 64 digits long',
                    propertyName: 'parentProcessSha256',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ19,
                    name: 'Parent process folder path',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: '2B1598AB-4A8F-4505-8579-18404BDE7837',
                    allowEdit: true,
                    allowWildCard: true,
                    pattern: 'folderPath',
                    patternErrorText: 'Folder path must end with \\, *, or /',
                    propertyName: 'parentProcessFolderPath',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ20,
                    name: 'Parent process file name',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: 'E88C36A5-FBA6-4968-8F75-E78DF4730FC0',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'parentProcessFileName',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ21,
                    name: 'Parent process command line',
                    type: SuppressionRuleConditionIocType.Process,
                    supportIocType: [SuppressionRuleConditionIocType.Process],
                    dataFieldName: '7684343D-EF0B-4860-993A-B3B4D52C545B',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'parentProcessCommandLine',
                    patternErrorText: 'Command line is not valid',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ22,
                    name: 'Task executables',
                    type: SuppressionRuleConditionIocType.ScheduledTask,
                    supportIocType: [SuppressionRuleConditionIocType.ScheduledTask],
                    dataFieldName: '47497D74-F603-4AF3-B18E-001430F665E9',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'taskExecutables',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ23,
                    name: 'LDAP search query',
                    type: SuppressionRuleConditionIocType.LdapQuery,
                    supportIocType: [SuppressionRuleConditionIocType.LdapQuery],
                    dataFieldName: '94f538af-b9da-405c-82ae-1d7bfc7f792e',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'ldapSearchQuery',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ24,
                    name: 'Distinguished name',
                    type: SuppressionRuleConditionIocType.LdapQuery,
                    supportIocType: [SuppressionRuleConditionIocType.LdapQuery],
                    dataFieldName: '8f289831-9013-4e1c-85f5-6b29c0eff340',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'distinguishedName',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ25,
                    name: 'Name',
                    type: SuppressionRuleConditionIocType.Registry,
                    supportIocType: [SuppressionRuleConditionIocType.Registry],
                    dataFieldName: 'e28009cc-40a5-412f-b5da-a28642d75f13',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'registryValueName',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ26,
                    name: 'Key',
                    type: SuppressionRuleConditionIocType.Registry,
                    supportIocType: [SuppressionRuleConditionIocType.Registry],
                    dataFieldName: '2841587a-eb98-4e0d-8145-ff1a2ffa6c6f',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'registryValueKey',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ27,
                    name: 'Registry hive',
                    type: SuppressionRuleConditionIocType.Registry,
                    supportIocType: [SuppressionRuleConditionIocType.Registry],
                    dataFieldName: '4d541e70-8320-4dc3-b269-224cf55d99ea',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'registryValueHive',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ28,
                    name: 'WMI query',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: '16a86019-fc26-4d48-9c71-9442a67a6ebe',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'wmiQuery',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
                {
                    id: ɵ29,
                    name: 'WMI class',
                    type: SuppressionRuleConditionIocType.WmiEvent,
                    supportIocType: [SuppressionRuleConditionIocType.WmiEvent],
                    dataFieldName: 'db88ebfb-be75-428a-af70-0e4d7681264b',
                    allowEdit: true,
                    allowWildCard: true,
                    propertyName: 'wmiClass',
                    supportRuleTypes: [SuppressionRuleType.MDE],
                },
            ],
        })
    ], SuppressionRuleConditionType);
    return SuppressionRuleConditionType;
}(EntityModelBase));
export { SuppressionRuleConditionType };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
