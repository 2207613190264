/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../projects/shared/src/lib/components/info-box.component.ngfactory";
import * as i2 from "../../../../../../../../projects/shared/src/lib/components/info-box.component";
import * as i3 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component.ngfactory";
import * as i4 from "../../../../../../../../projects/datatable/src/lib/components/datatable.component";
import * as i5 from "@angular/cdk/a11y";
import * as i6 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "@angular/common";
import * as i8 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i9 from "../../../../../../../../projects/panels/src/lib/components/panel.component.ngfactory";
import * as i10 from "../../../../../../../../projects/panels/src/lib/components/panel.component";
import * as i11 from "../../../../../../../../projects/panels/src/lib/services/panels.service";
import * as i12 from "./alert-linked-alerts-panel.component";
import * as i13 from "@angular/router";
import * as i14 from "@microsoft/paris/dist/lib/paris";
import * as i15 from "../../services/alerts.fields";
import * as i16 from "../../../../../../../../projects/config/src/lib/services/features.service";
import * as i17 from "../../services/alert.linked.fields";
var styles_AlertLinkedAlertsPanelComponent = [];
var RenderType_AlertLinkedAlertsPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AlertLinkedAlertsPanelComponent, data: {} });
export { RenderType_AlertLinkedAlertsPanelComponent as RenderType_AlertLinkedAlertsPanelComponent };
function View_AlertLinkedAlertsPanelComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "wcd-info-box", [["class", "wcd-block wcd-margin-xLarge-bottom"]], null, null, null, i1.View_InfoBoxComponent_0, i1.RenderType_InfoBoxComponent)), i0.ɵdid(1, 49152, null, 0, i2.InfoBoxComponent, [], null, null), (_l()(), i0.ɵted(2, 0, ["\n\t\t", "\n\t"])), i0.ɵpod(3, { count: 0 }), i0.ɵppd(4, 2)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent, 0), "alerts.panel.linkedByIncidentDetails.hiddenAlertsMessage", _ck(_v, 3, 0, _co.unexposedAlertsCount))); _ck(_v, 2, 0, currVal_0); }); }
function View_AlertLinkedAlertsPanelComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-datatable", [], null, null, null, i3.View_DataTableComponent_0, i3.RenderType_DataTableComponent)), i0.ɵdid(1, 13352960, null, 0, i4.DataTableComponent, [i0.ElementRef, i0.ChangeDetectorRef, i5.LiveAnnouncer, i6.I18nService, i0.ComponentFactoryResolver, i0.NgZone, i0.Renderer2], { items: [0, "items"], selectEnabled: [1, "selectEnabled"], columns: [2, "columns"], allowResize: [3, "allowResize"], fixedTable: [4, "fixedTable"], showHeaders: [5, "showHeaders"], fullHeight: [6, "fullHeight"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alerts; var currVal_1 = false; var currVal_2 = _co.tableFields; var currVal_3 = false; var currVal_4 = true; var currVal_5 = true; var currVal_6 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_AlertLinkedAlertsPanelComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵppd(4, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent, 0), "common.loading")); _ck(_v, 3, 0, currVal_0); }); }
function View_AlertLinkedAlertsPanelComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_AlertLinkedAlertsPanelComponent_4)), i0.ɵdid(2, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.loading; var currVal_1 = i0.ɵnov(_v.parent, 13); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_AlertLinkedAlertsPanelComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(0, null, ["\n\t\t", "\n\t"])), i0.ɵppd(1, 1)], null, function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i0.ɵnov(_v.parent, 0), "common.noDataAvailable")); _ck(_v, 0, 0, currVal_0); }); }
export function View_AlertLinkedAlertsPanelComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i8.I18nPipe, [i6.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 13, "wcd-panel", [["data-track-component", "AlertLinkedAlertsPanel"]], null, [[null, "close"], ["window", "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onKeydown($event) !== false);
        ad = (pd_0 && ad);
    } if (("close" === en)) {
        var pd_1 = (_co.destroy() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i9.View_PanelComponent_0, i9.RenderType_PanelComponent)), i0.ɵdid(2, 114688, null, 1, i10.PanelComponent, [i0.ElementRef, i0.ChangeDetectorRef, i11.PanelService], { settings: [0, "settings"] }, { close: "close" }), i0.ɵqud(603979776, 1, { panelHeader: 0 }), (_l()(), i0.ɵted(-1, 1, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_AlertLinkedAlertsPanelComponent_1)), i0.ɵdid(6, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\n\t"])), (_l()(), i0.ɵand(16777216, null, 1, 1, null, View_AlertLinkedAlertsPanelComponent_2)), i0.ɵdid(9, 16384, null, 0, i7.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, 1, ["\n\n\t"])), (_l()(), i0.ɵand(0, [["loadingOrError", 2]], 1, 0, null, View_AlertLinkedAlertsPanelComponent_3)), (_l()(), i0.ɵted(-1, 1, ["\n\t"])), (_l()(), i0.ɵand(0, [["noData", 2]], 1, 0, null, View_AlertLinkedAlertsPanelComponent_5)), (_l()(), i0.ɵted(-1, 1, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.settings; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.unexposedAlertsCount > 0); _ck(_v, 6, 0, currVal_1); var currVal_2 = ((_co.alerts == null) ? null : _co.alerts.length); var currVal_3 = i0.ɵnov(_v, 11); _ck(_v, 9, 0, currVal_2, currVal_3); }, null); }
export function View_AlertLinkedAlertsPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "alert-linked-alerts", [], null, null, null, View_AlertLinkedAlertsPanelComponent_0, RenderType_AlertLinkedAlertsPanelComponent)), i0.ɵdid(1, 770048, null, 0, i12.AlertLinkedAlertsPanelComponent, [i13.Router, i14.Paris, i15.AlertsFields, i0.ChangeDetectorRef, i16.FeaturesService, i17.AlertLinkedFields], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AlertLinkedAlertsPanelComponentNgFactory = i0.ɵccf("alert-linked-alerts", i12.AlertLinkedAlertsPanelComponent, View_AlertLinkedAlertsPanelComponent_Host_0, { settings: "settings", alertId: "alertId", linkedByReason: "linkedByReason" }, {}, []);
export { AlertLinkedAlertsPanelComponentNgFactory as AlertLinkedAlertsPanelComponentNgFactory };
