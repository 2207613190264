import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { EmailMetadata } from '@wcd/domain';

const DETECTION_TECHNOLOGIES_TUPLE_I18N_KEY =
	'airsEntity_emailMessage_fields_threatsDetectionTechnologies_tuple';

@Injectable()
export class EmailDetectionTechnologiesDisplayNameService {
	/*
	 * This service exists to align with the
	 * "Threats / Detection technologies" field in the MDO email details side panel.
	 * The logic is adapted from MDOs code in Nibiru repository (see below link)
	 * */
	constructor(private i18nService: I18nService) {}

	/*
	 * Creates a threat / detection technology display name.
	 * adapted from https://o365exchange.visualstudio.com/O365%20Core/_git/Nibiru?path=/sources/dev/m365scc/src/Client/ReactM365MATP/app/ThreatIntel/shared/Util/TiUtilities.ts&version=GBmaster&line=581&lineEnd=581&lineStartColumn=14&lineEndColumn=38&lineStyle=plain&_a=contents
	 */
	getEmailThreatDetectionMethodsDisplayName(mailMetadata: EmailMetadata): string {
		const threatFilterControls = mailMetadata.threatFilterControls;
		const spamDetectionTechKey = threatFilterControls && threatFilterControls.spam;
		const malwareDetectionTechKey = threatFilterControls && threatFilterControls.malware;
		const phishDetectionTechKey = threatFilterControls && threatFilterControls.phish;

		if (
			!threatFilterControls ||
			(spamDetectionTechKey === null &&
				malwareDetectionTechKey === null &&
				phishDetectionTechKey === null)
		) {
			return '';
		}
		const threatDetectionTuples: string[] = [];

		if (spamDetectionTechKey) {
			const spamDetectionTechnology = this.i18nService.get(DETECTION_TECHNOLOGIES_TUPLE_I18N_KEY, {
				threat: this.i18nService.strings.airsEntities_emailMessage_urls_threat_types_spam,
				detectionTechnology: this.i18nService.get(
					`airsEntity_emailMessage_fields_threatsDetectionTechnologies_${spamDetectionTechKey}`
				),
			});
			if (spamDetectionTechnology) {
				threatDetectionTuples.push(spamDetectionTechnology);
			}
		}

		if (malwareDetectionTechKey) {
			const malwareDetectionTechnology = this.i18nService.get(DETECTION_TECHNOLOGIES_TUPLE_I18N_KEY, {
				threat: this.i18nService.strings.airsEntities_emailMessage_urls_threat_types_malware,
				detectionTechnology: this.i18nService.get(
					`airsEntity_emailMessage_fields_threatsDetectionTechnologies_${malwareDetectionTechKey}`
				),
			});
			if (malwareDetectionTechnology) {
				threatDetectionTuples.push(malwareDetectionTechnology);
			}
		}

		if (phishDetectionTechKey) {
			const phishDetectionTechnology = this.i18nService.get(DETECTION_TECHNOLOGIES_TUPLE_I18N_KEY, {
				threat: this.i18nService.strings.airsEntities_emailMessage_urls_threat_types_phish,
				detectionTechnology: this.i18nService.get(
					`airsEntity_emailMessage_fields_threatsDetectionTechnologies_${phishDetectionTechKey}`
				),
			});
			if (phishDetectionTechnology) {
				threatDetectionTuples.push(phishDetectionTechnology);
			}
		}

		return threatDetectionTuples.join(', ');
	}
}
