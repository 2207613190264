<ng-container *ngIf="!macOsOnboardingEnabled; else firstParty">
    <manage-non-windows-third-party></manage-non-windows-third-party>
</ng-container>
<ng-template #firstParty>
    <wcd-expander [label]="'endpointManagement.nonWindows.offboarding.firstParty.title' | i18n"
        labelClass="wcd-font-size-l wcd-font-weight-regular wcd-padding-small-bottom"
        [isExpanded]="true"
        class="wcd-margin-large-bottom">
        <div class="wcd-margin-large-horizontal wcd-margin-medium-vertical">
            <manage-non-windows-first-party-offboarding></manage-non-windows-first-party-offboarding>
        </div>
    </wcd-expander>
    <wcd-expander [label]="'endpointManagement.nonWindows.offboarding.thirdParty.title' | i18n"
        labelClass="wcd-font-size-l wcd-font-weight-regular wcd-padding-small-bottom"
        [isExpanded]="true"
        class="wcd-margin-large-bottom">
        <div class="wcd-margin-large-horizontal wcd-margin-medium-vertical">
            <manage-non-windows-third-party></manage-non-windows-third-party>
        </div>
    </wcd-expander>
</ng-template>
