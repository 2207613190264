import { Entity } from '@microsoft/paris';
import { airsEntityStatusValues } from './airs-entity-status.entity';
import { AirsEntityStatus } from './airs-entity-status.entity';
import { RemediationStatusId, RemediationStatusType } from './airs-entity-status-types.enum';

export const airsEntityRemediationStatuses = airsEntityStatusValues
	.filter(s => !s.isVerdict)
	.concat([
		{
			id: RemediationStatusId.Failed,
			name: 'Failed',
			helpKey: null,
			type: RemediationStatusType.Failed,
			className: 'error',
			paletteColor: 'redDark',
			priority: 1,
			isImportant: true,
			iconName: 'malware',
			isMalicious: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.Prevented,
			name: 'Prevented',
			helpKey: null,
			type: RemediationStatusType.Prevented,
			className: 'success',
			paletteColor: 'green',
			priority: 1,
			isImportant: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.PendingApproval,
			name: 'Pending approval',
			helpKey: null,
			type: RemediationStatusType.PendingApproval,
			className: 'warning',
			paletteColor: 'yellow',
			priority: 0,
			isImportant: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.Declined,
			name: 'Declined',
			helpKey: null,
			type: RemediationStatusType.Declined,
			className: 'warning-dark',
			paletteColor: 'orangeLighter',
			priority: 1,
			isImportant: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.Running,
			name: 'Running',
			helpKey: null,
			type: RemediationStatusType.Running,
			className: 'primary',
			paletteColor: 'themePrimary',
			priority: 1,
			isImportant: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.Active,
			name: 'Active',
			helpKey: null,
			type: RemediationStatusType.Active,
			className: 'error',
			paletteColor: 'redDark',
			priority: 1,
			isImportant: true,
			iconName: 'malware',
			isMalicious: true,
			isVerdict: false,
		},
		{
			id: RemediationStatusId.Blocked,
			name: 'Blocked',
			helpKey: null,
			type: RemediationStatusType.Blocked,
			className: 'success',
			paletteColor: 'green',
			priority: 1,
			isImportant: true,
			isVerdict: false,
		},
	]);

@Entity({
	singularName: 'Entity Remediation Status',
	pluralName: 'Entity Remediation Statuses',
	values: airsEntityRemediationStatuses,
})
export class AirsEntityRemediationStatus extends AirsEntityStatus {}
