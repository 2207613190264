import { EntityModelBase, ValueObject, ModelBase, DataEntityType } from '@microsoft/paris';

@ValueObject({
	singularName: 'Entity with context',
	pluralName: 'Entities with context',
	readonly: true,
})
export class EntityWithContext<
	TEntity extends EntityModelBase<string | number>,
	TContext extends ModelBase
> extends EntityModelBase {
	/**
	 * The main entity.
	 */
	readonly mainEntity: EntityWithType<TEntity>;

	/**
	 * The context, that describes an activity or extra details related to the main entity.
	 */
	readonly entityContext: EntityContext<TContext>;

	// override a property with a getter/setter pair isn't allowed 
	// @ts-expect-error
	get id(): string {
		return `${this.mainEntity && this.mainEntity.item.id}_Context:${this.entityContext &&
			this.entityContext.item.id}`;
	}

	set id(_: string) {
		// Populated by Paris. Ignore since the ID is computed
	}
}

export interface EntityWithType<TEntity extends ModelBase> {
	item: TEntity;
	type: DataEntityType<TEntity>;
}

export interface EntityContext<TContext extends ModelBase> extends EntityWithType<TContext> {
	nameKey?: string;
}
