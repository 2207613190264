import { Component, ChangeDetectionStrategy, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { EvaluationService } from '../services/evaluation.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { LAB_CONFIGURATION, DEFAULT_LAB_CONFIGURATION_ID, LabConfigurationOption } from '@wcd/domain';

@Component({
	selector: 'evaluation-creat-lab-panel',
	templateUrl: './evaluation-lab-setup-panel.component.html',
})
export class EvaluationLabSetupPanelComponent extends PanelContainer {
	constructor(
		router: Router,
		private evaluationService: EvaluationService,
		private dialogsService: DialogsService,
		private i18nService: I18nService
	) {
		super(router);
	}

	SpinnerSize = SpinnerSize;
	MessageBarType = MessageBarType;
	LAB_CONFIGURATION = LAB_CONFIGURATION;

	selectedLabConfiguration: LabConfigurationOption = LAB_CONFIGURATION.find(
		c => c.id === DEFAULT_LAB_CONFIGURATION_ID
	);

	createLab() {
		this.evaluationService
			.createEvaluationLab({
				id: 0,
				maxNumberOfMachines: this.selectedLabConfiguration.numberOfMachines,
				machineExpirationInHours: this.selectedLabConfiguration.hoursPerMachine,
			})
			.subscribe(
				() => {
					this.evaluationService.evaluationLabCreated$.next(true);
					this.destroy();
				},
				(error: Error) => {
					this.dialogsService.showError({
						title: this.i18nService.get('evaluation.lab.error'),
						data: error,
					});
				}
			);
	}
}
