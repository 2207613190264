import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Paris } from '@microsoft/paris';
import {
	File,
	FileQuarantineActions,
	FileQuarantineActionsRelationship,
	FileRemediationCancel,
} from '@wcd/domain';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { ConfirmationService } from '../../../../dialogs/confirm/confirm.service';
import { I18nService } from '@wcd/i18n';
import { FilesBackendService } from '../../services/files.backend.service';

@Component({
	selector: 'file-action-center-quarantine',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './file.action-center.quarantine.component.html',
	styleUrls: ['./file.action-center.panel.component.scss'],
})
export class FileActionCenterQuarantineComponent implements OnInit {
	@Input() file: File;
	@Output() foundActions: EventEmitter<boolean> = new EventEmitter<boolean>();

	quarantineActions$: Observable<FileQuarantineActions>;
	private quarantineActionsTrigger$: BehaviorSubject<FileQuarantineActions> = new BehaviorSubject(null);

	constructor(
		private paris: Paris,
		private confirmationService: ConfirmationService,
		private i18nService: I18nService,
		private readonly filesBackendService: FilesBackendService
	) {}

	ngOnInit() {
		this.quarantineActions$ = this.quarantineActionsTrigger$
			.asObservable()
			.pipe(
				switchMap(() =>
					this.paris
						.getRelatedItem<File, FileQuarantineActions>(
							FileQuarantineActionsRelationship,
							this.file
						)
						.pipe(tap(actions => this.foundActions.emit(actions.statuses.length > 0)))
				)
			);
	}

	async cancelQuarantineAction() {
		const confirmation = await this.confirmationService.confirm({
			title: this.i18nService.get('file.quarantine.cancel.confirm.title'),
			text: this.i18nService.get('file.quarantine.cancel.confirm.text'),
			requireMessage: {
				placeholder: this.i18nService.get('file.quarantine.cancel.confirm.placeholder'),
				property: 'comment',
			},
		});

		if (confirmation.confirmed) {
			this.paris
				.apiCall(FileRemediationCancel, { file: this.file, comment: confirmation.data.comment })
				.subscribe(() => {
					this.quarantineActionsTrigger$.next(null);
				});
		}
	}

	exportQuarantineActionsResults() {
		this.filesBackendService.exportQuarantineActionsResults(this.file.sha1);
	}
}
