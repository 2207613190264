import { OnInit } from '@angular/core';
import { ActiveIncidents, Alert, Incident } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { config, KnownColorsService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { MainAppStateService } from '../../../../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';
var ActiveIncidentsTrendsComponent = /** @class */ (function () {
    function ActiveIncidentsTrendsComponent(tzDateService, knownColorsService, i18nService, globalEntityTypesService, mainAppStateService) {
        var _this = this;
        this.tzDateService = tzDateService;
        this.knownColorsService = knownColorsService;
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.mainAppStateService = mainAppStateService;
        this.hideTitle = false;
        this.isNarrowLayout = false;
        this.chartValueFormatter = function (value) { return "(" + value + ")"; };
        this.getSettings = function (data) {
            var _a;
            var max = data.incidentsTrend.concat(data.alertsTrend).reduce(function (r, item) { return r > item.count ? r : item.count; }, 0);
            var maxY = Math.ceil(max / 100) * 100;
            var blueMid = _this.knownColorsService.knownColorsMap['blueMid'].raw;
            var themePrimary = _this.knownColorsService.knownColorsMap['themePrimary'].raw;
            _this.trendChartSettings = {
                options: {
                    data: {
                        columns: [
                            [_this.incidentsTranslation].concat(data.incidentsTrend.map(function (trend) { return trend.count; })),
                            [_this.alertTranslation].concat(data.alertsTrend.map(function (trend) { return trend.count; })),
                        ],
                        colors: (_a = {},
                            _a[_this.incidentsTranslation] = _this.knownColorsService.knownColorsMap['themePrimary'].raw,
                            _a[_this.alertTranslation] = _this.knownColorsService.knownColorsMap['blueMid'].raw,
                            _a),
                    },
                    point: { show: false },
                    axis: {
                        // max: maxY,
                        y: {
                            tick: {
                                count: 3
                            },
                        },
                        x: {
                            type: 'categories',
                            categories: data.alertsTrend.map(function (item) { return _this.tzDateService.format(item.timeUtc, 'hh:mm:ss aaa'); }),
                            tick: {
                                width: 80,
                                culling: {
                                    max: 4
                                },
                                rotate: !_this.isNarrowLayout ? 0 : 45,
                            },
                            padding: {
                                left: 10,
                                right: 10,
                            },
                        },
                    },
                    grid: {
                        y: {
                            show: true,
                        },
                    },
                    legend: {
                        show: false,
                    },
                    size: {
                        height: 174,
                    },
                    tooltip: {
                        contents: function (point) {
                            var date = _this.tzDateService.format(data.incidentsTrend[point[0].index].timeUtc, 'MM/dd/yy');
                            var time = _this.tzDateService.format(data.incidentsTrend[point[0].index].timeUtc, 'h:mm a').toLocaleLowerCase();
                            return "<div class=\"wcd-flex wcd-flex-vertical custom-tooltip\">\n\t\t\t\t\t\t\t\t<div class=\"wcd-font-size-s wcd-flex-horizontal wcd-flex-horizontal-spaced tooltip-title\" style=\"margin-bottom: 10px;margin-top:10px;text-align: center;\">\n\t\t\t\t\t\t\t\t\t<div>" + date + "</div>\n\t\t\t\t\t\t\t\t\t<div>" + time + "</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t\t<div style=\"border-left: 4px solid " + themePrimary + ";\" class=\"number-section\" >\n\t\t\t\t\t\t\t\t\t<div class=\"wcd-font-size-s\" style=\"margin-left: 8px;\">" + point[0].name + "</div>\n\t\t\t\t\t\t\t\t\t<div class=\"wcd-font-size-xl ms-color-themePrimary wcd-font-weight-bold number-value\" style=\"margin-left: 8px;\">" + point[0].value + "</div>\n\t\t\t\t\t\t\t\t</div>\n\n\n\t\t\t\t\t\t\t\t<div style=\"border-left: 4px solid " + blueMid + ";\" class=\"number-section\">\n\t\t\t\t\t\t\t\t\t<div class=\"wcd-font-size-s\" style=\"margin-left: 8px;\">" + point[1].name + "</div>\n\t\t\t\t\t\t\t\t\t<div class=\"wcd-font-size-xl ms-color-blueMid wcd-font-weight-bold number-value\" style=\"margin-left: 8px;\">" + point[1].value + "</div>\n\t\t\t\t\t\t\t\t</div>\n\t\t\t\t\t\t\t</div>";
                        }
                    },
                },
                disableCursor: true,
            };
        };
    }
    ActiveIncidentsTrendsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.incidentEntityType = this.globalEntityTypesService.getEntityType(Incident);
        this.alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
        this.incidentsTranslation = this.i18nService.get(this.incidentEntityType.entityPluralNameKey);
        this.alertTranslation = this.i18nService.get(this.alertEntityType.entityPluralNameKey);
        this.incidentAlertTrendLegend = [
            {
                name: this.incidentsTranslation,
                iconClassName: 'ms-color-themePrimary'
            },
            {
                name: this.alertTranslation,
                iconClassName: 'ms-color-blueMid'
            }
        ];
        this.mainAppStateService.state$
            .pipe(filter(function (state) {
            return (_this.isNarrowLayout !==
                config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.xl));
        }))
            .subscribe(function (state) {
            var currentIsNarrowLayout = _this.isNarrowLayout;
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.xl);
            if (currentIsNarrowLayout !== _this.isNarrowLayout) {
                _this.getSettings(_this.data);
            }
        });
        this.getSettings(this.data);
    };
    return ActiveIncidentsTrendsComponent;
}());
export { ActiveIncidentsTrendsComponent };
