import { ApiCall, ApiCallModel } from '@microsoft/paris';

@ApiCall({
	name: 'Get if hunting rule query is mdatp or not',
	endpoint: 'hunting/query/isMdatpQuery',
	baseUrl: config => config.data.serviceUrls.threatIntel,
	method: 'POST',
	parseQuery: (queryText: string) => ({ data: { QueryText: queryText } }),
})
export class HuntingRuleQueryIsMdatpApiCall extends ApiCallModel<boolean, string> {}

@ApiCall({
	name: 'Get if hunting rule query is mdatp or not vNext API',
	endpoint: 'schema/query/isMdatp',
	baseUrl: config => config.data.serviceUrls.huntingService,
	method: 'POST',
	parseQuery: (queryText: string) => ({ data: { QueryText: queryText } }),
})
export class HuntingRuleQueryIsMdatpVNextApiCall extends ApiCallModel<boolean, string> {}
