import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { DataSensitivity } from './data-sensitivity.value-object';

@ValueObject({
	singularName: 'File protection info',
	pluralName: 'File protection info',
})
export class FileProtectionInfo extends ModelBase {
	@EntityField({ data: 'Sensitivity' })
	readonly sensitivity?: DataSensitivity;

	@EntityField({ data: 'IsWindowsInfoProtectionApplied' })
	readonly isWindowsInfoProtectionApplied?: boolean;

	@EntityField({ data: 'IsAzureInfoProtectionApplied' })
	readonly isAzureInfoProtectionApplied?: boolean;
}
