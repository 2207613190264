var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import { BooleanOperator, GuidedHuntingFilterCategory, QueryOperatorType, } from './hunting-guided-query.models';
var ɵ0 = function (rawData) { return (!isNil(rawData.Filters) ? GroupFilter : SingleFilter); };
var GuidedQueryFilter = /** @class */ (function (_super) {
    __extends(GuidedQueryFilter, _super);
    function GuidedQueryFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'NextBooleanOperator' }),
        __metadata("design:type", String)
    ], GuidedQueryFilter.prototype, "nextBooleanOperator", void 0);
    GuidedQueryFilter = __decorate([
        ValueObject({
            singularName: 'Guided hunting filter',
            pluralName: 'Guided hunting filters',
            modelWith: ɵ0,
        })
    ], GuidedQueryFilter);
    return GuidedQueryFilter;
}(ModelBase));
export { GuidedQueryFilter };
var SingleFilter = /** @class */ (function (_super) {
    __extends(SingleFilter, _super);
    function SingleFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'FieldCategory' }),
        __metadata("design:type", String)
    ], SingleFilter.prototype, "fieldCategory", void 0);
    __decorate([
        EntityField({ data: 'FieldName' }),
        __metadata("design:type", String)
    ], SingleFilter.prototype, "fieldName", void 0);
    __decorate([
        EntityField({ data: 'Operator' }),
        __metadata("design:type", String)
    ], SingleFilter.prototype, "operator", void 0);
    __decorate([
        EntityField({ data: 'Values' }),
        __metadata("design:type", Array)
    ], SingleFilter.prototype, "values", void 0);
    SingleFilter = __decorate([
        ValueObject({ singularName: 'Guided hunting single filter', pluralName: 'Guided hunting single filters' })
    ], SingleFilter);
    return SingleFilter;
}(GuidedQueryFilter));
export { SingleFilter };
var GroupFilter = /** @class */ (function (_super) {
    __extends(GroupFilter, _super);
    function GroupFilter() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Filters', arrayOf: GuidedQueryFilter }),
        __metadata("design:type", Array)
    ], GroupFilter.prototype, "filters", void 0);
    GroupFilter = __decorate([
        ValueObject({ singularName: 'Guided hunting group filter', pluralName: 'Guided hunting group filters' })
    ], GroupFilter);
    return GroupFilter;
}(GuidedQueryFilter));
export { GroupFilter };
export { ɵ0 };
