import { BehaviorSubject, Observable, Observer, ReplaySubject, Subject, Subscription } from 'rxjs';
import { InvestigationChangeEvent } from '../../../@entities/investigations/interfaces/investigation-change-event.interface';
import {
	AirsEntityTypeResults,
	Alert,
	InvestigatedUser,
	InvestigatedMachine,
	Investigation,
	InvestigationPendingType,
	InvestigationStatus,
	RemediatedThreatsStatuses,
	RemediationActionTypeActionCount,
	Service,
	ThreatTypeCount,
} from '@wcd/domain';
import { InvestigationGraphService } from '../services/investigation-graph.service';
import { delay, share } from 'rxjs/operators';
import { isNil } from 'lodash-es';
import { GraphData } from '@wcd/investigation-graph';

export class InvestigationGraphDataModel implements GraphData<InvestigationChangeEvent> {
	investigation: Investigation;

	alertCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	alert$: BehaviorSubject<Alert> = new BehaviorSubject(null);
	actionCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	commentCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	change$: Observable<InvestigationChangeEvent>;
	dataSources$: BehaviorSubject<Array<Service>> = new BehaviorSubject([]);
	end$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	entityCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	entityResults$: ReplaySubject<Array<AirsEntityTypeResults>> = new ReplaySubject<
		Array<AirsEntityTypeResults>
	>(1);
	error$: Observable<any>;
	externalSourcesCount$: BehaviorSubject<number> = new BehaviorSubject(0);
	machines$: BehaviorSubject<Array<InvestigatedMachine>> = new BehaviorSubject([]);
	users$: BehaviorSubject<Array<InvestigatedUser>> = new BehaviorSubject([]);
	init$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	pendingActionTypes$: BehaviorSubject<Array<RemediationActionTypeActionCount>> = new BehaviorSubject([]);
	pendingType$: BehaviorSubject<InvestigationPendingType> = new BehaviorSubject(null);
	remediationEntities$: BehaviorSubject<RemediatedThreatsStatuses> = new BehaviorSubject<
		RemediatedThreatsStatuses
	>(null);
	remediationSources$: BehaviorSubject<Array<Service>> = new BehaviorSubject([]);
	requestedStatus$: BehaviorSubject<InvestigationStatus> = new BehaviorSubject(null);
	status$: BehaviorSubject<InvestigationStatus> = new BehaviorSubject(null);
	threatTypes$: BehaviorSubject<Array<ThreatTypeCount>> = new BehaviorSubject([]);
	isRemediationDisabled$: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private _investigationSubscription: Subscription;
	private _changeObserver: Observer<InvestigationChangeEvent>;
	private _errorSubject: Subject<any> = new Subject();

	constructor(private investigation$: Observable<Investigation | Error>) {
		this.error$ = this._errorSubject.asObservable().pipe(share());
		this.change$ = new Observable(observer => (this._changeObserver = observer)).pipe(share());

		this.setInvestigationSubscription();
	}

	destroy() {
		this._investigationSubscription.unsubscribe();

		this.end$.complete();
		this.machines$.complete();
		this.users$.complete();
		this.pendingActionTypes$.complete();
		this._changeObserver.complete();
		this.status$.complete();
		this.requestedStatus$.complete();
		this.threatTypes$.complete();
		this.actionCount$.complete();
		this.commentCount$.complete();
		this.entityCount$.complete();
		this.entityResults$.complete();
		this.externalSourcesCount$.complete();
		this.dataSources$.complete();
		this.remediationSources$.complete();
		this.remediationEntities$.complete();
		this.pendingType$.complete();
	}

	refresh(): void {
		this.setInvestigationSubscription();
	}

	/**
	 * Set the investigation, which then calls next on behavior subjects with different data
	 * @param investigation
	 */
	setInvestigation(investigation: Investigation) {
		const oldValue: Investigation = this.investigation;
		this.investigation = investigation;

		if (!oldValue) {
			if (investigation.machines && investigation.machines.length)
				this.machines$.next(investigation.machines);

			if (investigation.investigatedUsers && investigation.investigatedUsers.length)
				this.users$.next(investigation.investigatedUsers);

			if (investigation.pendingActionTypes.length)
				this.pendingActionTypes$.next(investigation.pendingActionTypes);

			if (!isNil(investigation.isRemediationDisabled))
				this.isRemediationDisabled$.next(investigation.isRemediationDisabled);

			if (investigation.pendingType) this.pendingType$.next(investigation.pendingType);

			if (investigation.threatTypes && investigation.threatTypes.length)
				this.threatTypes$.next(investigation.threatTypes);

			if (investigation.totalAlertsCount) this.alertCount$.next(investigation.totalAlertsCount);

			if (investigation.commentCount) this.commentCount$.next(investigation.commentCount);

			if (investigation.actionsSummary.total)
				this.actionCount$.next(investigation.actionsSummary.total);

			if (investigation.entityCount) this.entityCount$.next(investigation.entityCount);

			if (investigation.requestedStatus) this.requestedStatus$.next(investigation.requestedStatus);

			if (!investigation.isRunning) {
				this.end$.next(true);
				this.onEndInvestigation();
			}

			this.init$.next(true);
			this.init$.complete();
		} else {
			const difference: InvestigationChangeEvent = InvestigationGraphService.getInvestigationDifference(
				this.investigation,
				oldValue
			);

			if (Object.keys(difference).length) {
				if (difference.machines) this.machines$.next(difference.machines);

				if (difference.investigatedUsers) this.users$.next(difference.investigatedUsers);

				if (difference.pendingActionTypes)
					this.pendingActionTypes$.next(difference.pendingActionTypes);

				if (!isNil(difference.isRemediationDisabled))
					this.isRemediationDisabled$.next(difference.isRemediationDisabled);

				if (difference.pendingType) this.pendingType$.next(difference.pendingType);

				if (difference.threatTypes) this.threatTypes$.next(difference.threatTypes);

				if (difference.actionCount) this.actionCount$.next(difference.actionCount);

				if (difference.alertCount) this.alertCount$.next(difference.alertCount);

				if (difference.commentCount) this.commentCount$.next(difference.commentCount);

				if (difference.entityCount) this.entityCount$.next(difference.entityCount);

				if (difference.requestedStatus) this.requestedStatus$.next(difference.requestedStatus);

				if (!isNil(difference.externalSourcesCount)) {
					this.externalSourcesCount$.next(difference.externalSourcesCount);

					if (difference.dataSources) this.dataSources$.next(difference.dataSources);

					if (difference.remediationSources)
						this.remediationSources$.next(difference.remediationSources);
				}

				this._changeObserver.next(difference);

				if (difference.status) {
					this.status$.next(difference.status);
					this.checkEnd(oldValue);
				}
			}
		}
	}

	private setInvestigationSubscription(): void {
		if (this._investigationSubscription) this._investigationSubscription.unsubscribe();

		this._investigationSubscription = this.investigation$.pipe(delay(1)).subscribe(
			(data: Investigation | Error) => {
				if (data instanceof Error) this._errorSubject.next(data);
				else {
					try {
						this.setInvestigation(<Investigation>data);
						this._errorSubject.next(null);
					} catch (error) {
						this._errorSubject.next(error);
					}
				}
			},
			error => {
				this._errorSubject.next(error);
			}
		);
	}

	private checkEnd(investigation: Investigation): boolean {
		if (investigation.isRunning !== this.investigation.isRunning) {
			this.end$.next(!this.investigation.isRunning);
			if (!this.investigation.isRunning) this.onEndInvestigation();

			return true;
		}

		return false;
	}

	private onEndInvestigation() {
		this._investigationSubscription && this._investigationSubscription.unsubscribe();
	}
}
