import { Severity } from '../severity/severity.entity';
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Incident severity count',
	pluralName: '',
	readonly: true,
})
export class IncidentSeverityCount extends ModelBase {
	@EntityField() severity: Severity;

	@EntityField({ data: 'incidentCount' })
	count: number;
}
