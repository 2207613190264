var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["AlertsManage"] = "alerts-entity-panel-manage";
    CollapsibleID["AlertComments"] = "alerts-entity-panel-comments";
})(CollapsibleID || (CollapsibleID = {}));
var AlertsEntityPanelComponent = /** @class */ (function (_super) {
    __extends(AlertsEntityPanelComponent, _super);
    function AlertsEntityPanelComponent() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.collapsibleID = CollapsibleID;
        return _this;
    }
    Object.defineProperty(AlertsEntityPanelComponent.prototype, "hasReadOnlyAlert", {
        get: function () {
            return this.entities.some(function (alert) { return alert.isReadOnly; });
        },
        enumerable: true,
        configurable: true
    });
    AlertsEntityPanelComponent.prototype.onStatusChanged = function () {
        this.runEntityAction('alertAssignToMe');
        this.refreshOnClose(true);
    };
    AlertsEntityPanelComponent.prototype.onClassificationChanged = function () {
        this.refreshOnClose(true);
    };
    AlertsEntityPanelComponent.prototype.onAssigneeChanged = function () {
        this.refreshOnClose(true);
    };
    AlertsEntityPanelComponent.prototype.onDeterminationChanged = function () {
        this.refreshOnClose(true);
    };
    return AlertsEntityPanelComponent;
}(EntitiesPanelComponentBase));
export { AlertsEntityPanelComponent };
