/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./events-summary-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i3 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i4 from "@angular/platform-browser";
import * as i5 from "@angular/common";
import * as i6 from "./events-summary-bar.component";
var styles_EventsSummaryBarComponent = [i0.styles];
var RenderType_EventsSummaryBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EventsSummaryBarComponent, data: {} });
export { RenderType_EventsSummaryBarComponent as RenderType_EventsSummaryBarComponent };
function View_EventsSummaryBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["aria-roledescription", "tooltip"], ["class", "summary-bar"]], [[2, "events-summary-bars--selectable", null], [4, "background-color", null], [4, "flex", null], [1, "tabindex", 0], [1, "role", 0], [1, "aria-label", 0]], [[null, "click"], [null, "keyup.enter"], [null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } if (("click" === en)) {
        var pd_4 = (_co.select.emit(_v.context.$implicit.metadata.event) !== false);
        ad = (pd_4 && ad);
    } if (("keyup.enter" === en)) {
        var pd_5 = (_co.select.emit(_v.context.$implicit.metadata.event) !== false);
        ad = (pd_5 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 147456, null, 0, i2.TooltipDirective, [i1.ElementRef, i3.TooltipsService, i4.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"] }, null), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(4, 2)], function (_ck, _v) { var _co = _v.component; var currVal_6 = _co.getTooltip(_v.context.$implicit.metadata.event); _ck(_v, 1, 0, currVal_6); var currVal_7 = "summary-bar"; var currVal_8 = _ck(_v, 4, 0, _v.context.$implicit.colorClass, ("events-summary-bars--" + _co.size)); _ck(_v, 3, 0, currVal_7, currVal_8); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isSelectable; var currVal_1 = _v.context.$implicit.color; var currVal_2 = _v.context.$implicit.ratio; var currVal_3 = (_co.isSelectable ? "0" : undefined); var currVal_4 = (_co.isSelectable ? "button" : "img"); var currVal_5 = _co.getTooltip(_v.context.$implicit.metadata.event); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5); }); }
function View_EventsSummaryBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "summary-bar"]], [[4, "flex", null]], null, null, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpad(3, 2)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "summary-bar"; var currVal_2 = _ck(_v, 3, 0, _co.emptyColorClass, ("events-summary-bars--" + _co.size)); _ck(_v, 2, 0, currVal_1, currVal_2); }, function (_ck, _v) { var currVal_0 = 1; _ck(_v, 0, 0, currVal_0); }); }
export function View_EventsSummaryBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsSummaryBarComponent_1)), i1.ɵdid(2, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_EventsSummaryBarComponent_2)), i1.ɵdid(5, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.eventsDisplayItems; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_co.eventsDisplayItems || (_co.eventsDisplayItems.length == 0)) && _co.emptyColorClass); _ck(_v, 5, 0, currVal_1); }, null); }
export function View_EventsSummaryBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "events-summary-bars", [], null, null, null, View_EventsSummaryBarComponent_0, RenderType_EventsSummaryBarComponent)), i1.ɵdid(1, 573440, null, 0, i6.EventsSummaryBarComponent, [], null, null)], null, null); }
var EventsSummaryBarComponentNgFactory = i1.ɵccf("events-summary-bars", i6.EventsSummaryBarComponent, View_EventsSummaryBarComponent_Host_0, { events: "events", kindColorMap: "kindColorMap", displayTextFormatter: "displayTextFormatter", size: "size", isSelectable: "isSelectable", hideTooltip: "hideTooltip", emptyColorClass: "emptyColorClass" }, { select: "select" }, []);
export { EventsSummaryBarComponentNgFactory as EventsSummaryBarComponentNgFactory };
