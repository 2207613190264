import { ReportWidgetsLayoutConfig, ReportWidgetsLayoutModel } from './report-widgets-layout.base';
import { ReportWidgetsLayoutRowConfig, ReportWidgetsLayoutRowModel } from './report-widgets-layout.row.model';

export class ReportWidgetsLayoutColumnModel extends ReportWidgetsLayoutModel {
	rows: Array<ReportWidgetsLayoutRowModel>;
	width: number;
	weight: number = 1;

	get type(): string {
		return 'column';
	}

	constructor(config: ReportWidgetsLayoutColumnConfig) {
		super(config);

		this.width = config.width;
		this.weight = config.weight || 1;
		this.rows = config.rows
			? config.rows.map(
					(rowConfig: ReportWidgetsLayoutRowConfig) => new ReportWidgetsLayoutRowModel(rowConfig)
			  )
			: null;
	}
}

export interface ReportWidgetsLayoutColumnConfig extends ReportWidgetsLayoutConfig {
	width?: number;
	weight?: number;
	rows?: Array<ReportWidgetsLayoutRowConfig>;
}
