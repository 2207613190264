import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { CommandLine } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';

@Injectable()
export class CommandLineEntityTypeService implements EntityTypeService<CommandLine> {

	readonly entityType: Readonly<EntityType<CommandLine>> = {
		entity: CommandLine,
		id: 'commandLine',
		getIcon: () => FabricIconNames.CommandPrompt,
		icon: FabricIconNames.CommandPrompt,
		getEntityName: commandLine => commandLine.name,
		entityPluralNameKey: 'commandLine_entityType_pluralName',
		entitySingularNameKey: 'commandLine_entityType_singularName',

		getNavigationModel: () => {
			return {
				routerLink: null
			}
		},
		entityPageViewMode: EntityPageViewMode.Default,
		hideTagsInEntityComponent: true,
	};
}
