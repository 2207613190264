/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i2 from "@angular-react/fabric";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i4 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i5 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i6 from "../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i7 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i8 from "@angular/common";
import * as i9 from "./convergence-promotion-toolbar.component";
import * as i10 from "../services/convergence-promotion.service";
var styles_ConvergencePromotionToolbarComponent = [];
var RenderType_ConvergencePromotionToolbarComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ConvergencePromotionToolbarComponent, data: {} });
export { RenderType_ConvergencePromotionToolbarComponent as RenderType_ConvergencePromotionToolbarComponent };
function View_ConvergencePromotionToolbarComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 13, "div", [["class", "wcd-flex-justify-end ms-bgColor-gray10"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 10, "div", [["class", "wcd-margin-small-horizontal wcd-flex-none wcd-padding-small-vertical semi-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 7, "fab-command-bar-button", [["className", "ms-bgColor-gray10"], ["data-track-id", "PromotionBannerExploreFromBarClick"], ["data-track-type", "Button"], ["target", "_blank"]], null, null, null, i1.View_FabCommandBarButtonComponent_0, i1.RenderType_FabCommandBarButtonComponent)), i0.ɵdid(5, 6012928, null, 1, i2.FabCommandBarButtonComponent, [i0.ElementRef, i0.ChangeDetectorRef, i0.Renderer2, i0.NgZone], { href: [0, "href"], className: [1, "className"] }, null), i0.ɵqud(603979776, 1, { menuItemsDirectives: 1 }), (_l()(), i0.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(8, 0, null, 0, 1, "wcd-shared-icon", [["class", "wcd-padding-small-right"], ["iconName", "OpenInNewWindow"]], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i0.ɵdid(9, 573440, null, 0, i4.IconComponent, [i5.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(10, 0, ["\n\t\t\t\t\t", "\n\t\t\t\t"])), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigateToUrl; var currVal_1 = "ms-bgColor-gray10"; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = "OpenInNewWindow"; _ck(_v, 9, 0, currVal_2); }, function (_ck, _v) { var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v.parent, 0), "promotion.action.navbar")); _ck(_v, 10, 0, currVal_3); }); }
export function View_ConvergencePromotionToolbarComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i6.I18nPipe, [i7.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_ConvergencePromotionToolbarComponent_1)), i0.ɵdid(3, 16384, null, 0, i8.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.shouldHideToolbar; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_ConvergencePromotionToolbarComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "convergance-promotion-toolbar", [], null, null, null, View_ConvergencePromotionToolbarComponent_0, RenderType_ConvergencePromotionToolbarComponent)), i0.ɵdid(1, 49152, null, 0, i9.ConvergencePromotionToolbarComponent, [i10.ConvergencePromotionService], null, null)], null, null); }
var ConvergencePromotionToolbarComponentNgFactory = i0.ɵccf("convergance-promotion-toolbar", i9.ConvergencePromotionToolbarComponent, View_ConvergencePromotionToolbarComponent_Host_0, {}, {}, []);
export { ConvergencePromotionToolbarComponentNgFactory as ConvergencePromotionToolbarComponentNgFactory };
