import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { IPreload } from '@wcd/shared';
import { empty, Observable, of, Subscription } from 'rxjs';
import { MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { Feature, FeaturesService } from '@wcd/config';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { catchError, filter, mergeMap } from 'rxjs/operators';
import { PollingService } from '@wcd/config';

const COMMUNITY_POLLING_FREQUENCY = 1000 * 60 * 20; // refresh every 20 minutes
const COMMUNITY_NOTIFICATIONS_URL = 'CommunityNotifications';
const COMMUNITY_PAGE_VISIT_UPDATE_URL = 'CommunityVisit';

@Injectable()
export class CommunityService implements IPreload, OnDestroy {
	private communityNotifications$: Observable<number>;
	private pollSubscriptions: Subscription;

	constructor(
		private http: HttpClient,
		private mainAppStateService: MainAppStateService,
		private serviceUrlsService: ServiceUrlsService,
		private featuresService: FeaturesService,
		private appInsightsService: AppInsightsService,
		private appConfigService: AppConfigService,
		private pollingService: PollingService
	) {}

	init() {
		this.setCommunityPolling();
		this.pollSubscriptions = this.communityNotifications$.subscribe((communityCount: number) => {
			const badgeNewValue: string = communityCount > 0 ? communityCount.toString() : null;
			this.mainAppStateService.setMainNavBadge('community', badgeNewValue);
		});

		this.mainAppStateService.mainHeaderClick$.pipe(filter(id => id === 'community')).subscribe(() => {
			this.communityPageVisitUpdate();
		});

		return of(null);
	}

	private setCommunityPolling() {
		// avoid request community notifications if the tenant is still onboarding or the account mode is Suspended\Deleted.
		if (
			!this.featuresService.isEnabled(Feature.EnableCommunityNotifications) ||
			!this.appConfigService.isOnboardingComplete ||
			this.appConfigService.isSuspended ||
			this.appConfigService.isDeleted		
		)
			this.communityNotifications$ = empty();
		else
			this.communityNotifications$ = this.pollingService.poll(0, COMMUNITY_POLLING_FREQUENCY).pipe(
				mergeMap(() =>
					this.http.get<number>(
						`${this.serviceUrlsService.threatIntel}/${COMMUNITY_NOTIFICATIONS_URL}`
					)
				),
				catchError((err: HttpErrorResponse) => {
					console.error(err);
					if (err.status !== 401)
						this.appInsightsService.trackException(err, 'CommunityNotifications');
					return of(0);
				})
			);
	}

	private communityPageVisitUpdate() {
		if (!this.featuresService.isEnabled(Feature.EnableCommunityNotifications) || !this.appConfigService.isOnboardingComplete) {
			// avoid updating user visit update if the tenant is still onboarding.
			return;
		}

		// reset the community notification badge on user click
		this.mainAppStateService.setMainNavBadge('community', null);

		// update the server that the current user clicked on community button so it can update its last visit time
		this.http.get(`${this.serviceUrlsService.threatIntel}/${COMMUNITY_PAGE_VISIT_UPDATE_URL}`).subscribe(
			onSuccess => {},
			err => {
				console.error('Failed to update the server with the user last visit time');
				this.appInsightsService.trackException(err, 'communityPageVisitUpdate');
			}
		);
	}

	ngOnDestroy(): void {
		this.pollSubscriptions && this.pollSubscriptions.unsubscribe();
	}
}
