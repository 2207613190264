/* tslint:disable:template-mouse-events-have-key-events */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
	Inject,
	ViewChild,
	ElementRef,
	HostListener,
	AfterViewInit,
} from '@angular/core';
import { Location, DOCUMENT } from '@angular/common';
import { NavItemModel } from '@wcd/shared';
import { NavigationEnd, Router, UrlHandlingStrategy, UrlTree } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { MainAppState, MainAppStateService } from '../services/main-app-state.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MainNavConfigService } from '../services/main-nav-config.service';
import { MainNavService } from '../services/main-nav.service';
import { merge } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { NavSectionModel } from '../../models/nav-section.model';

@Component({
	selector: 'main-nav',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './main-nav.component.html',
	animations: [
		trigger('slideFromTop', [
			state(
				'hidden',
				style({
					height: '0px',
					overflow: 'hidden',
				})
			),
			state('shown', style({})),
			transition('hidden <=> shown', animate('.15s ease')),
		]),
	],
	styleUrls: ['./main-nav.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class MainNavComponent implements OnInit, AfterViewInit {
	navSections: Array<NavSectionModel>;
	mainAppState: MainAppState;
	subNavsState: {
		[subNavName: string]: {
			visibility: 'hidden' | 'shown';
			parent: NavItemModel;
		};
	} = {};
	submenuScrollAmount: number = 0;
	submenuTop: number = 0;
	hasScrollbar: boolean;
	isMobileSize: boolean;
	@Input() showItems: boolean = true;
	@Input() isExpanded: boolean = false;
	@ViewChild('navScrollable', { static: false }) navScrollable: ElementRef;

	private selectedNavItem: NavItemModel;

	@HostListener('window:resize', ['$event']) onResize(event) {
		this.calculateHasScroll();
		this.isMobileSize = window.innerWidth < 480;
	}

	constructor(
		private router: Router,
		public mainAppStateService: MainAppStateService,
		private changeDetectionRef: ChangeDetectorRef,
		public authService: AuthService,
		private location: Location,
		private urlHandlingStrategy: UrlHandlingStrategy,
		private mainNavConfigService: MainNavConfigService,
		public mainNavService: MainNavService,
		private featuresService: FeaturesService,
		public appContextService: AppContextService,
		@Inject(DOCUMENT) private readonly document: Document
	) {
		mainAppStateService.state$.subscribe((mainAppState: MainAppState) => {
			this.mainAppState = mainAppState;
			this.changeDetectionRef.markForCheck();
		});

		merge(
			router.events.pipe(filter(event => event instanceof NavigationEnd)),
			this.mainAppStateService.navChange$
		).subscribe(() => {
			setTimeout(() => this.changeDetectionRef.markForCheck());
		});

		location.subscribe(() => setTimeout(() => this.changeDetectionRef.markForCheck()));

		this.featuresService.featureChanged$.subscribe(() => {
			this.mainNavConfigService.update();
			this.navSections = this.mainNavConfigService.mainNavConfig;
			this.changeDetectionRef.markForCheck();
		});
		this.isMobileSize = window.innerWidth < 480;
	}

	ngOnInit() {
		this.setNavItems();
		this.changeDetectionRef.markForCheck();

		//accessibility requirement MAS1.4.13, requires a mechanism (here: hitting escape) to dismiss additional content that hides the underneath content (here: the menu pop ups)
		window.addEventListener('keyup', (e: KeyboardEvent) => {
			if (e.keyCode === 27) {
				//escape key
				this.closeAllSubNavsBySections();
				this.changeDetectionRef.markForCheck();
			}
		});
	}

	ngAfterViewInit(): void {
		this.calculateHasScroll();
	}

	onFolderClickMobile(navItem: NavItemModel, $event: MouseEvent) {
		if ($event.ctrlKey || $event.shiftKey || $event.altKey) return;
		$event.preventDefault();
		if (!this.isExpanded) {
			this.toggleMainNav();
		}
		this.toggleSubNavState(navItem, true);
	}

	onFolderClick(navItem: NavItemModel, $event: MouseEvent) {
		if ($event.ctrlKey || $event.shiftKey || $event.altKey) return;

		$event.preventDefault();

		if (this.mainAppState.mainNavIsExpanded) {
			navItem.isExpanded = !navItem.isExpanded;
			this.toggleSubNavState(navItem);
		} else {
			const urlTree: UrlTree = this.router.parseUrl(navItem.routerLink[0]);
			const isUpgradedLink: boolean = this.urlHandlingStrategy.shouldProcessUrl(urlTree);

			if (!isUpgradedLink) this.location.go(navItem.routerLink[0]);

			this.router.navigate(navItem.routerLink);

			navItem.isExpanded = false;
		}
	}

	onNavFocus($event, navItem) {
		$event.stopPropagation();
		this.focusElement(navItem.id);
	}

	closeAllSubNavsBySections() {
		this.navSections.forEach(navSection => this.closeAllSubNavs(navSection.items));
	}

	closeAllSubNavs(navItems: Array<NavItemModel>) {
		navItems
			.filter(navItem => navItem.isFolder)
			.forEach(navItemFolder => {
				navItemFolder.isExpanded = false;
				this.subNavsState[navItemFolder.name].visibility = 'hidden';
			});
	}

	toggleMainNav() {
		this.closeAllSubNavsBySections();
		setTimeout(() => {
			this.mainAppStateService.toggleMainNav(!this.mainAppState.mainNavIsExpanded);
		}, 100);
	}

	toggleFloatingSubNav($event, navItem: NavItemModel, value: boolean) {
		$event.stopPropagation();
		if (navItem.isFolder && !this.mainAppState.mainNavIsExpanded) {
			if (value) {
				this.updateSubmenuTop($event.currentTarget, navItem);
			}
			navItem.isExpanded = value; //only controls the caret icon
			this.toggleSubNavState(navItem, value);
		}
	}

	toggleSubNavState(navItem: NavItemModel, value?: boolean) {
		this.subNavsState[navItem.name].visibility =
			value || this.subNavsState[navItem.name].visibility === 'hidden' ? 'shown' : 'hidden';
	}

	openSubNav(navItem: NavItemModel) {
		navItem.isExpanded = true;
		this.subNavsState[navItem.name].visibility = 'shown';
	}

	closeSubNav(navItem: NavItemModel) {
		navItem.isExpanded = false;
		this.subNavsState[navItem.name].visibility = 'hidden';
	}

	isActive(navItem: NavItemModel): boolean {
		return (
			!navItem.isAlias &&
			(this.location.path().startsWith('/' + navItem.route) ||
				(navItem.children && navItem.children.some(subNavItem => this.isActive(subNavItem))))
		);
	}

	getAllNavItem() {
		return [].concat(...this.navSections.map((section: NavSectionModel) => section.items));
	}

	/**** Keyboard accessibility:
	- Definitions
		Visually we have the following types of "nav bar items" (ignoring the currently not used "sections" feature):
		- "root item": a link in the root level
		- "folder item": appears in the root level, when "clicked" shows/hides a "sub nav" that holds "sub items"
		- "sub nav": the section that holds the "sub items"
		- "sub item": a link inside a "sub nav"


	- Design goals
		1.	We need to provide single tab stop for the entire navbar, and it should put the focus on the first root level item in the navbar.
		2.	We should then enable easy navigation inside the navbar using the 4 arrow keys.
		3.	Pressing enter on any nav item will perform the action that a normal click provide, be it hitting a link or showing/hiding sub navs (this requirement is already implemented as the browser treats "enter" as if a mouse click happened).
		4.	We need to handle cyclic navigation where required.
		5.	Our current navbar design offers two different modes: collapsed and expanded, so we must support both:
			a.	Collapsed mode:
				i.	Entering and leaving sub menus using right & left arrows respectively. auto focusing on the first sub item when entering sub menu.
				ii.	Navigating between nav items (either root or sub items) in the *same level* using up & down keys, with cyclic behavior on the edges (on the root level edges as well as the sub nav edges)
			b.	Expanded mode:
				i.	Opening / closing sub menus using the enter key (already implemented with the usual 'click' handler)
				ii.	Expanded mode is one vertical column of items, so navigating between nav items (root items & sub items) using up & down keys will also transition between root items and sub items. Cyclic behavior will happen only on the root level edges.
		6.	We need to make sure that a focused element shows a proper visual feedback (in terms of color, contrast etc.).

	- implementation overview
		- We are not changing the current navbar config data structure. This data structure is an array of section definitions, where each section holds its root items, and some root items (folders) hold their sub items (folder's nav items)
		- We see that each nav bar item renders a single anchor tag (<a>)
		- We implement goal #1 by setting the attribute tabIndex=0 on the anchor tag of the first root level, and setting tabIndex=-1 on all the rest anchor tags. we also make sure all other native tab stopping elements (anchors, buttons, etc.) have tabIndex=-1 to eliminate them as tab stops.
		- We implement goals #2 to #6 using the following approach:
			- we make sure every anchor has its own unique id attribute (will help us to later focus on the required anchor)
			- we make sure every anchor emits event on either button up/down/right/left event
			- we then have 4 corresponding event handlers (onNavItemArrowRight, onNavItemArrowLeft...) that given the current navbar mode (collapsed/expanded) and the source element that produced the event, decide and set focus to the proper nav item (anchor).
	*/

	/* user clicked right while nav item is in focus  */
	onNavItemArrowRight({
		$event,
		navItem,
		sectionIndex,
		navItemIndex,
		parentNavItem,
		subNavItemIndex,
	}: {
		$event;
		navItem: NavItemModel;
		sectionIndex: number;
		navItemIndex: number;
		parentNavItem?: NavItemModel;
		subNavItemIndex?: number;
	}) {
		$event.stopPropagation();
		if (
			navItem.isFolder &&
			!this.mainAppState.mainNavIsExpanded &&
			this.subNavsState[navItem.name].visibility !== 'shown'
		) {
			this.updateSubmenuTop($event.currentTarget, navItem);
			/* the menu is collapsed, it's a folder, and the subNav is hidden, so open the subNav:  */
			this.openSubNav(navItem);
			/* and set the focus to the first child */
			const children = navItem.children.filter(child => !child.isDisabled);
			const firstChildId = children.length && children[0].id;
			if (firstChildId) {
				this.focusElement(firstChildId);
			}
		}
	}

	/* user clicked left while nav item is in focus  */
	onNavItemArrowLeft({
		$event,
		navItem,
		sectionIndex,
		navItemIndex,
		parentNavItem,
		subNavItemIndex,
	}: {
		$event;
		navItem: NavItemModel;
		sectionIndex: number;
		navItemIndex: number;
		parentNavItem?: NavItemModel;
		subNavItemIndex?: number;
	}) {
		$event.stopPropagation();
		if (
			!navItem.isFolder &&
			!this.mainAppState.mainNavIsExpanded &&
			parentNavItem &&
			this.subNavsState[parentNavItem.name].visibility === 'shown'
		) {
			/* the menu is collapsed, it is a folder's leaf (part of a subNav), we should close the subNav and set the focus on the parent:  */
			this.closeSubNav(parentNavItem);
			this.focusElement(parentNavItem.id);
		}
	}

	onNavItemArrowUp({
		$event,
		navItem,
		sectionIndex,
		navItemIndex,
		parentNavItem,
		subNavItemIndex,
	}: {
		$event;
		navItem: NavItemModel;
		sectionIndex: number;
		navItemIndex: number;
		parentNavItem?: NavItemModel;
		subNavItemIndex?: number;
	}) {
		$event.stopPropagation();
		$event.preventDefault();
		if (!this.mainAppState.mainNavIsExpanded) {
			const previousSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'previous')
				.id;
			this.focusElement(previousSiblingId);
		} else {
			let prev: NavItemModel;
			let prevIsOpen: boolean;

			if (
				subNavItemIndex === undefined &&
				(prev = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'previous')) &&
				(prevIsOpen = prev.isFolder) &&
				this.subNavsState[prev.name].visibility === 'shown'
			) {
				//if root item, and previous item is an *open* folder, go to this folder's last item
				const prevLastChildId = prev.children[prev.children.length - 1].id;
				this.focusElement(prevLastChildId);
			} else {
				const nextSiblingId = this.getSibling(
					sectionIndex,
					navItemIndex,
					subNavItemIndex,
					'previous',
					false
				).id;
				this.focusElement(nextSiblingId);
			}
		}
	}

	onNavItemArrowDown({
		$event,
		navItem,
		sectionIndex,
		navItemIndex,
		parentNavItem,
		subNavItemIndex,
	}: {
		$event;
		navItem: NavItemModel;
		sectionIndex: number;
		navItemIndex: number;
		parentNavItem?: NavItemModel;
		subNavItemIndex?: number;
	}) {
		$event.stopPropagation();
		$event.preventDefault();
		if (!this.mainAppState.mainNavIsExpanded) {
			const nextSiblingId = this.getSibling(sectionIndex, navItemIndex, subNavItemIndex, 'next').id;
			this.focusElement(nextSiblingId);
		} else {
			if (
				subNavItemIndex === undefined &&
				this.subNavsState[navItem.name] &&
				this.subNavsState[navItem.name].visibility === 'shown'
			) {
				//if folder and open, focus first child
				const children = navItem.children.filter(child => !child.isDisabled);
				const firstChildId = children.length && children[0].id;
				if (firstChildId) {
					this.focusElement(firstChildId);
				}
			} else {
				const nextSiblingId = this.getSibling(
					sectionIndex,
					navItemIndex,
					subNavItemIndex,
					'next',
					false
				).id;
				this.focusElement(nextSiblingId);
			}
		}
	}

	onScroll(event) {
		requestAnimationFrame(() => {
			this.submenuScrollAmount =
				(event.currentTarget && event.currentTarget.scrollTop) || this.submenuScrollAmount;
		});
	}

	private updateSubmenuTop(submenuItem: HTMLElement, navItem: NavItemModel) {
		if (this.selectedNavItem === navItem) {
			return;
		}
		const submenuHeight = (submenuItem.querySelector('ul.nav-submenu') as HTMLElement).offsetHeight;
		const menuHeight = this.navScrollable.nativeElement.offsetHeight;
		this.submenuScrollAmount = this.navScrollable.nativeElement.scrollTop || 0;
		const submenuParentTop = submenuItem.offsetTop;
		this.selectedNavItem = navItem;
		const originalPos = submenuParentTop - this.submenuScrollAmount;
		if (menuHeight - submenuHeight - originalPos < 0) {
			this.submenuTop = menuHeight - submenuHeight;
		} else {
			this.submenuTop = Math.max(originalPos, 0);
		}
	}

	private calculateHasScroll() {
		if (!this.navScrollable || !this.navScrollable.nativeElement) {
			this.hasScrollbar = false;
			return;
		}
		const scrollbarElement = this.navScrollable.nativeElement;
		this.hasScrollbar = scrollbarElement.scrollHeight - scrollbarElement.offsetHeight > 0;
	}

	/**
	 * Returns the NavItemModel ("sibling") that is the next (or previous) sibling of the provided NavItemModel ("source") in the navSection data structure, where the source is provided via it's address in the array: the section index, the item index, and, if the item is a sub item, the sub item index
	 * @param {number} sectionIndex the section index of the source NavItemModel
	 * @param {number} navItemIndex (if the source is root level item) the index of the source NavItemModel, or (if the source is a sub nav item) the index of the source' parent NavItemModel
	 * @param {number} subNavItemIndex (if the source is sub item) the index of the source NavItemModel
	 * @param {'next' | 'previous'} direction if to return the next or previous sibling
	 * @param {boolean} cycleSubNavItems
	 * 		true (default): the function returns a sub nav item's sibling in a cyclic fashion (e.g. the next sibling of the last item in a sub group is the first item in the group) a cyclic through
	 * 		false: e.g. the next sibling of the last item in a sub group is the next sibling of the item's parent
	 * @returns {NavItemModel}
	 */
	private getSibling(
		sectionIndex: number,
		navItemIndex: number,
		subNavItemIndex: number,
		direction: 'next' | 'previous',
		cycleSubNavItems = true
	): NavItemModel {
		let itemsArr;
		const sectionLength = this.navSections[sectionIndex].items.length;
		if (subNavItemIndex === undefined && navItemIndex === 0 && direction === 'previous') {
			if (sectionIndex === 0) {
				// (cyclic behaviour on the root level)
				itemsArr = this.navSections[this.navSections.length - 1].items;
			} else {
				itemsArr = this.navSections[--sectionIndex].items;
			}
			return itemsArr[itemsArr.length - 1];
		} else if (
			subNavItemIndex === undefined &&
			navItemIndex === sectionLength - 1 &&
			direction === 'next'
		) {
			if (sectionIndex === this.navSections.length - 1) {
				// (cyclic behaviour on the root level)
				itemsArr = this.navSections[0].items;
			} else {
				itemsArr = this.navSections[++sectionIndex].items;
			}
			return itemsArr[0];
		} else {
			if (subNavItemIndex !== undefined && !cycleSubNavItems) {
				//sub item, no cycling, if we're on the edges need to escape to the parent level
				if (
					direction === 'next' &&
					subNavItemIndex === this.navSections[sectionIndex].items[navItemIndex].children.length - 1
				) {
					//last sub item and requesting next, escape to the parent's next sibling
					return this.getSibling(sectionIndex, navItemIndex, undefined, 'next');
				} else if (direction === 'previous' && subNavItemIndex === 0) {
					//first sub item and requesting previous, escape to the parent
					return this.navSections[sectionIndex].items[navItemIndex];
				}
			}

			let currItemIndex;
			if (subNavItemIndex === undefined) {
				currItemIndex = navItemIndex;
				itemsArr = this.navSections[sectionIndex].items;
			} else {
				currItemIndex = subNavItemIndex;
				itemsArr = this.navSections[sectionIndex].items[navItemIndex].children;
			}
			return itemsArr[
				(currItemIndex + (direction === 'next' ? 1 : -1) + itemsArr.length) % itemsArr.length
			];
		}
	}

	private focusElement(elementId: string) {
		const el: HTMLElement = this.document.getElementById(elementId);
		if (el) {
			el.focus();
		}
	}

	onNavItemsKeydown(e: KeyboardEvent) {
		if (e.keyCode === 9) {
			//on pressing Tab (or Shift+Tab), and before navigating away from the nav, we should close any open sub menu
			this.closeAllSubNavsBySections();
		}
	}
	/*******(end of Keyboard accessibility******/

	private setNavItems(): void {
		this.navSections = this.mainNavConfigService.mainNavConfig;
		this.navSections.forEach(navSection => this.setChildrenNavItem(navSection.items));
	}

	private setChildrenNavItem(navItems: Array<NavItemModel>) {
		navItems.forEach((navItem: NavItemModel) => {
			if (navItem.children)
				this.subNavsState[navItem.name] = {
					visibility: 'hidden', //controls showing/hiding the subNav via animation (shows IFF 'shown')
					parent: navItem,
				};
		});
	}
}
