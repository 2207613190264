import { combineLatest, defer, Observable, of } from 'rxjs';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Breadcrumb, BreadcrumbItemConfig, BreadcrumbsRouteConfig } from '@wcd/shared';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { BreadcrumbsService } from './breadcrumbs.service';
import { compact } from 'lodash-es';
import { ActivatedEntity } from '../../global_entities/services/activated-entity.service';
import { EntityIdRouteService } from '../../global_entities/services/entity-id-route.service';
import { AppContextService } from '@wcd/config';
import { IBreadcrumbInfo, sccHostService } from '@wcd/scc-interface';
import { RoutesService } from '@wcd/shared';
import { I18nService } from '@wcd/i18n';
import { toObservable } from '../../utils/rxjs/utils';

/**
 * Breadcrumb resolver: creates breadcrumb using entity type service and Paris getItemById if needed.
 * Can be used for entities with\without global entityType service.
 * Fallbacks to hard-coded route data config.
 */
@Injectable({
	providedIn: 'root',
})
export class BreadcrumbsResolver<TEntity extends EntityModelBase> implements Resolve<Array<Breadcrumb>> {
	constructor(
		protected paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		private breadcrumbsService: BreadcrumbsService,
		private activatedEntity: ActivatedEntity,
		private readonly entityIdRouteService: EntityIdRouteService,
		private appContext: AppContextService,
		private router: Router,
		private routesService: RoutesService,
		private i18nService: I18nService
	) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Array<Breadcrumb>> {
		const routeWithBreadcrumbsConfig = this.breadcrumbsService.getRouteWithBreadcrumbsConfig(route);

		if (!routeWithBreadcrumbsConfig) return of(null);

		return combineLatest(
			routeWithBreadcrumbsConfig.data['breadcrumbsConfig'] &&
				routeWithBreadcrumbsConfig.data['breadcrumbsConfig']['addParentWhenEmpty']
				? this.createBreadcrumb(routeWithBreadcrumbsConfig, state, true)
				: of(null),
			this.createBreadcrumb(routeWithBreadcrumbsConfig, state)
		).pipe(map(compact));
	}

	/**
	 * creates a breadcrumb item out of route data
	 * @param {ActivatedRouteSnapshot} route
	 * @param {RouterStateSnapshot} state
	 * @param {boolean} isForParent: if set to 'true', returns a breadcrumb item of the route's 'parent' item. for example, "all alerts" for route "alert item".
	 * @returns {Observable<Breadcrumb>}
	 * @private
	 */
	private createBreadcrumb(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		isForParent?: boolean
	): Observable<Breadcrumb> {
		const breadcrumbsRouteConfig: BreadcrumbsRouteConfig = route.data['breadcrumbsConfig'];

		if (!breadcrumbsRouteConfig || breadcrumbsRouteConfig.addItemOnEnter === false) return of(null);

		const entityType: EntityType<TEntity> = this.globalEntityTypesService.getEntityType(
			route.data.entity
		);
		const breadcrumbItemConfig: BreadcrumbItemConfig =
			(isForParent && breadcrumbsRouteConfig && breadcrumbsRouteConfig.parentItemConfig) ||
			breadcrumbsRouteConfig.itemConfig;
		const entity: DataEntityType = (entityType && entityType.entity) || (route.data && route.data.entity);

		if (!entity && !breadcrumbItemConfig) throw new Error('Missing details for breadcrumbs.');

		const routeId = this.entityIdRouteService.getRouteId({
			route,
			itemIdParamName: breadcrumbItemConfig && breadcrumbItemConfig.idFieldName,
		});

		if (routeId && !isForParent) {
			// create breadcrumbs for entity

			const isRecentEntityPopulated =
				this.activatedEntity.recentEntity &&
				this.activatedEntity.recentEntity.constructor === route.data.entity &&
				this.activatedEntity.recentEntity.id === routeId;

			const params$ = defer(() =>
				toObservable(
					entityType && entityType.getItemParams
						? entityType.getItemParams(route.data.entity, { routeId: routeId })
						: null
				)
			);

			const entity$ = isRecentEntityPopulated
				? of(this.activatedEntity.recentEntity)
				: params$.pipe(switchMap(params => this.getEntity(routeId, route, state, params)));

			return entity$.pipe(
				first(),
				map((item: TEntity) => {
					const url =
						(entityType && entityType.getEntitiesLink && entityType.getEntitiesLink([item])) ||
						(breadcrumbItemConfig && breadcrumbItemConfig.url) ||
						state.url.split('?')[0];
					return {
						id:
							(entity && `${entity.singularName.toLowerCase()}_${item.id}`) ||
							(breadcrumbItemConfig && breadcrumbItemConfig.id) ||
							item.id,
						label: this.getItemLabel(item, entityType, breadcrumbItemConfig),
						url,
						queryParams: route.queryParams,
						sccBCConfig: this.getSCCConfig(url),
					};
				})
			);
		} else {
			// create breadcrumbs for dataview
			const url =
				(entityType && entityType.getEntityDataviewLink && entityType.getEntityDataviewLink()) ||
				(breadcrumbItemConfig && breadcrumbItemConfig.url) ||
				state.url.split('?')[0];
			return of({
				id:
					(entity && entity.pluralName.toLowerCase()) ||
					(breadcrumbItemConfig && breadcrumbItemConfig.id),
				label: this.getParentLabel(entity, entityType, breadcrumbItemConfig),
				url,
				queryParams: isForParent ? {} : route.queryParams,
				sccBCConfig: this.getSCCConfig(url),
			});
		}
	}

	protected getSCCConfig(link: string): Partial<IBreadcrumbInfo> {
		if (!this.appContext.isSCC) return null;
		let inSccLink = link;
		try {
			inSccLink = this.routesService.getMdatpFromSccLinkConfig(link).url;
		} catch (e) {
			console.warn('getMdatpFromSccLinkConfig returned null inside SCC');
		}
		inSccLink = inSccLink[0] === '/' ? inSccLink.substr(1) : inSccLink;

		//Split on first /
		const [base, path] = inSccLink.split(/\/(.+)?/);
		if (!path) {
			return { state: base };
		}

		// We have do handle paths where the page part contains two sections: endpoints/<specific page name>. This is the case for
		// some of MDATP pages inside SCC portal
		if (base === 'endpoints') {
			const [endpointsPage, endpointsPagePath] = path.split(/\/(.+)?/);
			if (!endpointsPagePath) {
				return {
					state: `${base}/${endpointsPage}`,
				};
			}
			return {
				state: `${base}/${endpointsPage}.child`,
				params: {
					path: endpointsPagePath,
				},
			};
		}
		return {
			state: `${base}.child`,
			params: {
				path,
			},
		};
	}

	protected getEntity(
		entityId: string,
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot,
		params: {}
	): Observable<EntityModelBase> | Observable<any> {
		const entityType: EntityType<TEntity> = this.globalEntityTypesService.getEntityType(
			route.data.entity
		);

		const encodedEntityId = entityType && entityType.getEncodedEntityId ? entityType.getEncodedEntityId(entityId) : entityId;

		// Since the root route also uses this breadcrumb resolver, we add an option to ignore getting the entity
		// on the root router. It will read this from the route config (the specific route config for the entity) and
		// returns an empty entity. This is used by the Url entity for example , where in the UrlBreadcrumb resolver
		// we have a different way to load the entity.
		const breadcrumbsRouteConfig: BreadcrumbsRouteConfig = route.data['breadcrumbsConfig'];
		if (breadcrumbsRouteConfig.ignoreRootResolver) {
			return of({id: encodedEntityId});
		}

		return this.paris.getItemById(route.data.entity, encodedEntityId, null, params).pipe(
			catchError(error => {
				this.appContext.isSCC
					? setTimeout(() => sccHostService.state.go('Error.child', { path: '404' }))
					: this.router.navigateByUrl('/Error/404');
				return of(null);
			})
		);
	}

	private getItemLabel(
		item: TEntity,
		entityType?: EntityType<TEntity>,
		breadcrumbItemConfig?: BreadcrumbItemConfig
	) {
		return (
			(entityType &&
				((entityType.getEntityName && entityType.getEntityName(item)) ||
					(entityType.entitySingularNameKey &&
						this.i18nService.get(entityType.entitySingularNameKey)))) ||
			(breadcrumbItemConfig &&
				((breadcrumbItemConfig.labelKey && this.i18nService.get(breadcrumbItemConfig.labelKey)) ||
					breadcrumbItemConfig.label)) ||
			item['name'] ||
			item.id
		);
	}

	private getParentLabel(
		entity: DataEntityType,
		entityType?: EntityType<TEntity>,
		breadcrumbItemConfig?: BreadcrumbItemConfig
	) {
		return (
			(entity &&
				entityType &&
				entityType.entityPluralNameKey &&
				this.i18nService.get(entityType.entityPluralNameKey)) ||
			(entity && entity.pluralName) ||
			(breadcrumbItemConfig &&
				((breadcrumbItemConfig.labelKey && this.i18nService.get(breadcrumbItemConfig.labelKey)) ||
					breadcrumbItemConfig.label))
		);
	}
}
