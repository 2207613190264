import { Component, Input } from '@angular/core';
import { GenericField, GenericFieldType, Url, File } from '@wcd/domain';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from 'app/global_entities/services/global-entity-types.service';
import {FeaturesService,Feature} from "@wcd/config";

@Component({
	selector: 'generic-fields-list',
	templateUrl: './generic-fields-list.component.html',
	styleUrls: ['./generic-fields-list.component.scss'],
})
export class GenericFieldsListComponent {
	@Input() genericFields: Array<GenericField>;
	@Input() processTreeElementStyle: boolean = false;
	@Input() linkLogContext?: string;

	readonly genericFieldType = GenericFieldType;
	cyberEntities: Map<string, EntityModelBase> = new Map<string, EntityModelBase>();

	constructor(private readonly featuresService: FeaturesService,
				private readonly paris: Paris,
				private readonly globalEntityTypesService: GlobalEntityTypesService) {
	}

	ngOnInit(): void {
		this.genericFields.filter(gField => gField.valueType == GenericFieldType.CyberEntity)
			.forEach(gField => this.fetchCyberEntities(gField)
			);
	}

	private fetchCyberEntities(gField: GenericField) {
		const cyberEntityType = this.getCyberEntityType(gField);
	 	if(!cyberEntityType){
	 		return;
		}

		if (cyberEntityType === Url) {
			const decodeUrl = decodeURI(gField.entityId);
			this.cyberEntities.set(gField.entityId, new Url({ id: decodeUrl, name: decodeUrl }));
		}
		else if(cyberEntityType === File){
			this.paris.getItemById(cyberEntityType, gField.entityId,undefined, {['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW)}).subscribe(item => {
					this.cyberEntities.set(gField.entityId, { ...item, fileName: gField.value });
				},
			)
		}
		else {
			this.paris.getItemById(cyberEntityType, gField.entityId).subscribe(item => {
					this.cyberEntities.set(gField.entityId, item);
				},
			);
		}
	}

	getCyberEntityType(genericField: GenericField): DataEntityType {
		const type = genericField.entityType.toString();
		return this.globalEntityTypesService.getEntityType<EntityModelBase<string>>(type === 'process' ? 'file' : type).entity;
	}

	hasTooltipValue(valueType: string): boolean {
		return (
			valueType == GenericFieldType.String ||
			valueType == GenericFieldType.DateTime ||
			valueType == GenericFieldType.CopyPastableString ||
			valueType == GenericFieldType.LinkableString
		);
	}

	getGenericFieldValueClass(valueType: string) {
		return {
			'alert-process-tree-details': this.processTreeElementStyle,
			'alert-process-tree-details--value': this.processTreeElementStyle,
			'alert-process-tree-details--value--mitreTechnique': this.processTreeElementStyle && this.isMitreTechniques(valueType),
			'wcd-text-overflow-ellipsis-nowrap': this.processTreeElementStyle && !this.isMitreTechniques(valueType),
			'wcd-text-overflow-ellipsis': this.processTreeElementStyle && !this.isMitreTechniques(valueType),
		};
	}

	private isMitreTechniques(valueType: string): boolean {
		return valueType == this.genericFieldType.MitreTechniques;
	}

	isEntityClickable(genericField: GenericField<string>): boolean {
		return !!(this.cyberEntities &&
			this.cyberEntities.has(genericField.entityId));
	}

	getCyberEntity(genericField: GenericField<string>): EntityModelBase {
		return this.cyberEntities.get(genericField.entityId);
	}
}
