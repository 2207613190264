/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./support-central.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i4 from "@angular-react/fabric";
import * as i5 from "./support-central.component";
import * as i6 from "../../../../../projects/code-editor/src/lib/code-editor/services/internal/dynamic-script.service";
import * as i7 from "@microsoft/paris/dist/lib/paris";
import * as i8 from "../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i9 from "../insights/services/app-insights.service";
var styles_SupportCentralComponent = [i0.styles];
var RenderType_SupportCentralComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_SupportCentralComponent, data: {} });
export { RenderType_SupportCentralComponent as RenderType_SupportCentralComponent };
function View_SupportCentralComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "support-central-collapse-button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(3, { "right.px": 0, "width.px": 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 5, "fab-icon-button", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleCollapse() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_FabIconButtonComponent_0, i3.RenderType_FabIconButtonComponent)), i1.ɵdid(6, 6012928, null, 1, i4.FabIconButtonComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2, i1.NgZone], { styles: [0, "styles"], iconProps: [1, "iconProps"] }, null), i1.ɵqud(603979776, 2, { menuItemsDirectives: 1 }), i1.ɵpod(8, { height: 0 }), i1.ɵpod(9, { root: 0 }), i1.ɵpod(10, { iconName: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _co.collapseButtonRightPositionPx, _co.collapseButtonWidthPx); _ck(_v, 2, 0, currVal_0); var currVal_1 = _ck(_v, 9, 0, _ck(_v, 8, 0, 40)); var currVal_2 = _ck(_v, 10, 0, _co.collapseButtonIcon); _ck(_v, 6, 0, currVal_1, currVal_2); }, null); }
export function View_SupportCentralComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { parentElement: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, [[1, 0], ["parentElement", 1]], null, 3, "div", [["class", "support-central-parent"], ["style", "position: fixed; visibility: hidden;"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(5, { display: 0 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SupportCentralComponent_1)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 5, 0, _co.widgetParentDisplay); _ck(_v, 4, 0, currVal_0); var currVal_1 = _co.withCollapseButton; _ck(_v, 8, 0, currVal_1); }, null); }
export function View_SupportCentralComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "support-central", [], null, null, null, View_SupportCentralComponent_0, RenderType_SupportCentralComponent)), i1.ɵdid(1, 4243456, null, 0, i5.SupportCentralComponent, [i6.DynamicScriptService, i7.Paris, i8.LocaleConfigService, i9.AppInsightsService], null, null)], null, null); }
var SupportCentralComponentNgFactory = i1.ɵccf("support-central", i5.SupportCentralComponent, View_SupportCentralComponent_Host_0, {}, {}, []);
export { SupportCentralComponentNgFactory as SupportCentralComponentNgFactory };
