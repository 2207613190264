import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';

@Entity({
	...airsEntityConfig,
	singularName: 'Persistence Method',
	pluralName: 'Persistence Methods',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsPersistenceMethod,
})
export class AirsPersistenceMethod extends AirsEntity {
	@EntityField({ data: 'method_type' })
	methodType: string;

	@EntityField({ data: 'category' })
	category: string;

	@EntityField({ data: 'command_line' })
	commandLine: string;

	@EntityField({ data: 'registry_path' })
	registryPath: string;

	@EntityField({ data: 'registry_value_name' })
	registryValueName: string;

	@EntityField({ data: 'registry_clsid_path' })
	registryClsidPath: string;

	@EntityField({ data: 'lnk_description' })
	lnkDescription: string;

	@EntityField({ data: 'lnk_path' })
	lnkPath: string;

	@EntityField({ data: 'ini_path' })
	iniPath: string;

	@EntityField({ data: 'ini_section' })
	iniSection: string;

	@EntityField({ data: 'ini_key' })
	iniKey: string;

	@EntityField({ data: 'paths' })
	paths: string;
}
