var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ComponentRef } from '@angular/core';
import { AlertEntityPanelComponent } from '../components/alert.entity-panel.component';
import { AlertsEntityPanelComponent } from '../components/alerts.entity-panel.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Alert, AlertEntityExposureApiCall, InvestigationStatusesMapping, Outbreak, ScheduledHuntingByAlertApiCall, ServiceSourceType, TagType, } from '@wcd/domain';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthService, MtpPermission } from '@wcd/auth';
import { AlertsService } from './alerts.service';
import { Paris } from '@microsoft/paris';
import { SuppressionRulePanelMode, SuppressionRulesService, } from '../../suppression_rules/services/suppression-rules.service';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { AlertLinkToIncidentPanelComponent } from '../components/alert-link-to-incident-panel.component';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { map, mergeMap, switchMap, take } from 'rxjs/operators';
import { combineLatest, of } from 'rxjs';
import { AppFlavorConfig, FabricIconNames } from '@wcd/scc-common';
import { castArray, flatMap, uniq } from 'lodash-es';
import { MachinesService } from '../../machines/services/machines.service';
import { AlertEntityComponent } from '../components/alert-entity.component';
import { AlertEntityDetailsComponent } from '../../../global_entities/components/entity-details/alert.entity-details.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { AlertStatusComponent } from '../components/alert-status.component';
import { HUNTING_ROUTE, isUnifiedAlert } from '@wcd/shared';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { sccHostService } from '@wcd/scc-interface';
import { AskThreatExpertService } from '../../../feedback/services/ask-threat-expert.service';
//This is the initial date used to determine supported mcas alerts in UAP, all alerts after this date are supported.
//TO-DO: update the date when we have the final one. Task #38189631
var SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT = new Date(2022, 1, 17);
var MCAS_ALERT_ID_PREFIX = "ca";
var AlertEntityTypeService = /** @class */ (function () {
    function AlertEntityTypeService(authService, alertsService, paris, suppressionRulesService, dialogsService, featuresService, machinesService, appContextService, entityPanelsService, i18nService, askThreatExpertService, router, flavorService) {
        var _this = this;
        this.authService = authService;
        this.alertsService = alertsService;
        this.paris = paris;
        this.suppressionRulesService = suppressionRulesService;
        this.dialogsService = dialogsService;
        this.featuresService = featuresService;
        this.machinesService = machinesService;
        this.appContextService = appContextService;
        this.entityPanelsService = entityPanelsService;
        this.i18nService = i18nService;
        this.askThreatExpertService = askThreatExpertService;
        this.router = router;
        this.entityType = {
            id: 'alert',
            entity: Alert,
            icon: FabricIconNames.LightningBolt,
            entityPluralNameKey: 'alert_entityType_pluralName',
            entitySingularNameKey: 'alert_entityType_singularName',
            getItem: function (alert, loadedEntity$, options) {
                if (options && options['loadExtraData'] === false) {
                    return of(alert);
                }
                return loadedEntity$;
            },
            getImage: function (alerts) {
                var commonSeverity = GlobalEntityTypesService.getCommonValue(alerts, function (alert) { return alert.severity; });
                return "/assets/images/icons/entities/alert_" + (commonSeverity ? commonSeverity.name.toLowerCase() : 'informational') + ".svg";
            },
            entityContentsComponentType: AlertEntityComponent,
            entityDetailsComponentType: AlertEntityDetailsComponent,
            singleEntityPanelComponentType: AlertEntityPanelComponent,
            multipleEntitiesComponentType: AlertsEntityPanelComponent,
            entityPanelStatusComponentType: AlertStatusComponent,
            getTags: function (alerts) {
                var tags = [];
                alerts.forEach(function (alert) {
                    if (alert.isThreatExpertOriginated) {
                        tags.push({
                            id: alert.detectionSource.id,
                            name: _this.i18nService.strings.incident_tags_mte_tag,
                            type: TagType.behavior,
                            className: 'tag-color-box-threatExpert',
                        });
                    }
                    if (alert.actor) {
                        tags.push({
                            id: alert.actor,
                            name: alert.actor,
                            type: TagType.behavior,
                            className: "color-box-" + alert.severity.className,
                        });
                    }
                });
                return tags;
            },
            getNavigationModel: function (alert) {
                var unsupportedMcas = alert.id && alert.id.startsWith(MCAS_ALERT_ID_PREFIX) &&
                    alert.firstEventTime < SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT;
                // MCAS alerts are supported in UAP after a specific Date, Set as SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT.
                if (alert.id &&
                    isUnifiedAlert(alert.id, _this.featuresService, alert.detectionSource)) {
                    if (_this.shouldRedirectAlertToAttackStoryTab(alert)) {
                        var tab = _this.featuresService.isEnabled(Feature.IncidentV2Tab) ? 'overview' : 'incident-graph';
                        return {
                            routerLink: ["/incidents/" + alert.incidentId + "/" + tab],
                            queryParams: { alertId: alert.id },
                        };
                    }
                    else if (!unsupportedMcas) {
                        return {
                            sccInternalRedirectDefinition: function () { return '/alerts/' + alert.id; },
                        };
                    }
                }
                if (alert.alertPageExternalUrl) {
                    return {
                        externalLink: alert.alertPageExternalUrl,
                    };
                }
                return _this.alertsService.isAlertLinkableToMdatp(alert)
                    ? {
                        routerLink: alert.id ? [_this.alertsService.getAlertLink(alert.id)] : null,
                    }
                    : null;
            },
            getEntityName: function (alert) { return alert.name; },
            getEntityDataviewLink: function () { return (sccHostService.isSCC ? '/alerts' : '/alertsQueue'); },
            getEntitiesLink: function (alerts) {
                if (alerts.length === 1 &&
                    isUnifiedAlert(alerts[0].id, _this.featuresService, alerts[0].detectionSource)) {
                    if (_this.shouldRedirectAlertToAttackStoryTab(alerts[0])) {
                        var tab = _this.featuresService.isEnabled(Feature.IncidentV2Tab) ? 'overview' : 'incident-graph';
                        return "/incidents/" + alerts[0].incidentId + "/" + tab + "?alertId=" + alerts[0].id;
                    }
                    // MCAS alerts are supported in UAP after a specific Date, Set as SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT.
                    if (alerts[0].id.startsWith(MCAS_ALERT_ID_PREFIX) &&
                        alerts[0].firstEventTime < SUPPORTED_MCAS_UNIFIED_PAGE_DATE_LIMIT) {
                        return alerts[0].alertPageExternalUrl;
                    }
                    return '/alerts/' + alerts[0].id;
                }
                if (alerts.length === 1) {
                    return (alerts[0].alertPageExternalUrl ||
                        (_this.alertsService.isAlertLinkableToMdatp(alerts[0]) &&
                            _this.alertsService.getAlertLink(alerts[0].id)));
                }
            },
            getUseExternalRouting: function (alerts) {
                return (alerts.length === 1 &&
                    Boolean(alerts[0].alertPageExternalUrl) &&
                    !isUnifiedAlert(alerts[0].id, _this.featuresService, alerts[0].detectionSource));
            },
            getActions: function (alerts, options, entityViewType) {
                var allAlertsAssignedToCurrentUser = alerts.every(function (alert) {
                    return _this.authService.isCurrentUser(alert.assignedTo);
                });
                var actions = [];
                var singleAlert = alerts.length === 1 && alerts[0];
                // At the moment "externalAlert"==="isReadOnly". In the feature we might have a situation
                // where some alerts are external but are editable
                var onlyInternalAlerts = alerts.every(function (alert) { return !alert.isReadOnly; });
                var workloads = uniq(flatMap(alerts.map(function (alert) { return alert.mtpWorkloads; })));
                var machine = singleAlert && singleAlert.machine;
                if (_this.timelineAction && machine && onlyInternalAlerts) {
                    var machineLink = _this.machinesService.getMachineLink(machine.machineId, true, singleAlert.lastEventTime || singleAlert.lastSeen, singleAlert.id);
                    if (machineLink) {
                        actions.push({
                            id: 'seeAlertInMachineTimeline',
                            nameKey: 'alerts.seeInTimeline.title',
                            href: machineLink,
                            icon: FabricIconNames.Timeline,
                        });
                    }
                }
                if (_this.featuresService.isEnabled(Feature.Incidents) &&
                    alerts.every(function (alert) { return alert.status && !alert.status.isHidden; })) {
                    actions.push({
                        id: 'linkToIncident',
                        nameKey: 'alerts.linkToIncident.title',
                        icon: 'incident',
                        refreshOnResolve: true,
                        localRefreshOnResolve: true,
                        rbacState: RbacControlState.hidden,
                        rbacMtpPermission: [MtpPermission.SecurityData_Manage],
                        rbacRequireAllPermissions: true,
                        rbacMtpWorkloads: workloads,
                        method: function (alerts) {
                            var panelSettings = {
                                id: 'link-alert-to-incident-panel',
                                type: PanelType.large,
                                noBodyPadding: false,
                                isBlocking: false,
                            };
                            //TODO fix explicit construction
                            return new Promise(function (resolve) {
                                _this.dialogsService
                                    .showPanel(AlertLinkToIncidentPanelComponent, panelSettings, {
                                    alerts: alerts,
                                })
                                    .subscribe(function (panel) {
                                    panel.onDestroy(function () {
                                        resolve();
                                    });
                                });
                            });
                        },
                    });
                }
                if (!_this.featuresService.isEnabled(Feature.AssignToSomeoneElse) &&
                    !allAlertsAssignedToCurrentUser &&
                    onlyInternalAlerts) {
                    actions.push({
                        id: 'alertAssignToMe',
                        nameKey: 'alerts.assignToMe',
                        icon: 'users.userCheck',
                        refreshOnResolve: true,
                        rbacState: RbacControlState.hidden,
                        rbacMtpPermission: [MtpPermission.SecurityData_Manage],
                        rbacRequireAllPermissions: true,
                        rbacMtpWorkloads: workloads,
                        method: function (alerts) {
                            return _this.alertsService
                                .assignAlertsToCurrentUser(alerts)
                                .toPromise()
                                .then(function (assignedUser) {
                                alerts.forEach(function (alert) { return (alert.assignedTo = assignedUser.name); });
                                return assignedUser;
                            });
                        },
                    });
                }
                if (singleAlert &&
                    !singleAlert.suppressionRuleId &&
                    !_this.appContextService.isMtp &&
                    onlyInternalAlerts) {
                    actions.push({
                        id: 'alertSuppressionRule',
                        nameKey: 'alerts.addSuppressionRule',
                        icon: 'suppressionRule',
                        refreshOnResolve: true,
                        localRefreshOnResolve: true,
                        rbacState: RbacControlState.hidden,
                        rbacMtpPermission: [MtpPermission.SecurityConfig_Manage],
                        rbacRequireAllPermissions: true,
                        rbacMtpWorkloads: workloads,
                        method: function (alerts) {
                            return _this.suppressionRulesService
                                .showRulePanel(SuppressionRulePanelMode.create, null, alerts[0])
                                .then(function (suppressionRule) {
                                Object.assign(alerts[0], {
                                    suppressionRuleId: suppressionRule.id,
                                    suppressionRuleTitle: suppressionRule.name,
                                });
                                return suppressionRule;
                            });
                        },
                    });
                }
                if (_this.threatExpertAction && _this.askThreatExpertService.shouldShowThreatExpertPanel()) {
                    actions.push(_this.askThreatExpertService.getAskTheExpertCommandConfig('alertEntityCommandBar'));
                }
                if (singleAlert && entityViewType === EntityViewType.EntityPage) {
                    actions.unshift({
                        id: 'manage',
                        nameKey: 'alert.actions.manage.title',
                        method: function (alerts) { return __awaiter(_this, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                this.entityPanelsService.showEntities(Alert, alerts);
                                return [2 /*return*/];
                            });
                        }); },
                    });
                }
                var contextMatchingServiceSource = _this.appContextService.isMtp
                    ? ServiceSourceType.Mtp
                    : ServiceSourceType.Wdatp;
                if (singleAlert &&
                    singleAlert.isCustomDetection &&
                    _this.featuresService.isEnabled(Feature.ShowScheduledHuntingOnMTP) &&
                    singleAlert.serviceSource.id === contextMatchingServiceSource // MTP alert in MTP context or MDATP alert in MDATP context
                ) {
                    var monthAgo = new Date();
                    monthAgo.setDate(monthAgo.getDate() - 30);
                    var alertOlderThan30Days = singleAlert.lastEventTime <= monthAgo;
                    var goHuntActionButton = {
                        id: 'goHunt',
                        nameKey: 'alert.actions.goHunt.title',
                        icon: 'hunting',
                        tooltip: _this.i18nService.get(alertOlderThan30Days
                            ? 'alert.actions.goHunt.availableWithin30Days'
                            : 'alert.actions.goHunt.descriptionTooltip'),
                        disabled: alertOlderThan30Days,
                        method: alertOlderThan30Days
                            ? null
                            : function (alrts) {
                                var relatedAlert = alrts[0];
                                return _this.paris
                                    .apiCall(ScheduledHuntingByAlertApiCall, relatedAlert)
                                    .pipe(switchMap(function (queryId) {
                                    var goHuntQueryParams = {
                                        queryId: queryId,
                                        fromDate: relatedAlert.firstEventTime.toISOString(),
                                        toDate: relatedAlert.lastEventTime.toISOString(),
                                        runQuery: true,
                                    };
                                    return _this.router.navigate(["/" + HUNTING_ROUTE], {
                                        queryParams: goHuntQueryParams,
                                    });
                                }))
                                    .toPromise();
                            },
                    };
                    actions.push(goHuntActionButton);
                }
                return actions.map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
            },
            isUserExposedToEntity: function (alert) {
                if (!alert.isGroup) {
                    return _this.isExposedToAlert(alert.id);
                }
                return _this.alertsService.getContainedAlerts(alert).pipe(map(function (alerts) { return alerts.map(function (alert) { return alert.id; }); }), map(function (alertIds) { return alertIds.map(function (alertId) { return _this.isExposedToAlert(alertId); }); }), mergeMap(function (isExposedObservableArray) { return combineLatest(isExposedObservableArray); }), map(function (isExposedArray) { return isExposedArray.reduce(function (prev, curr) { return prev && curr; }, true); }));
            },
            dataViewOptions: {
                getFilterQueryOptions: function (serializedFilters) { return __awaiter(_this, void 0, void 0, function () {
                    var serializedFilterValues;
                    var _this = this;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, Promise.all(Object.entries(serializedFilters).map(function (_a) {
                                    var param = _a[0], value = _a[1];
                                    return __awaiter(_this, void 0, void 0, function () {
                                        var _b, _c, _d, values, allIoas, parsedValues, transformedValues, namedTransformedValues;
                                        var _this = this;
                                        return __generator(this, function (_e) {
                                            switch (_e.label) {
                                                case 0:
                                                    if (!(param === 'IoaDefinitionIds')) return [3 /*break*/, 2];
                                                    values = castArray(value);
                                                    return [4 /*yield*/, Promise.all(values.map(function (outbreakId) {
                                                            return _this.paris
                                                                .getItemById(Outbreak, outbreakId)
                                                                .pipe(map(function (outbreak) { return outbreak.ioaIds; }), take(1))
                                                                .toPromise();
                                                        }))];
                                                case 1:
                                                    allIoas = _e.sent();
                                                    return [2 /*return*/, (_b = {},
                                                            _b[param] = uniq(flatMap(allIoas)).join(','),
                                                            _b)];
                                                case 2:
                                                    if (param === 'investigationStates') {
                                                        parsedValues = castArray(value).map(function (val) { return parseInt(val, 10); });
                                                        transformedValues = flatMap(parsedValues, function (val) {
                                                            return val === InvestigationStatusesMapping.Pending
                                                                ? [
                                                                    InvestigationStatusesMapping.PendingApproval,
                                                                    InvestigationStatusesMapping.PendingResource,
                                                                ]
                                                                : [val];
                                                        });
                                                        namedTransformedValues = transformedValues.map(function (val) {
                                                            // Choose the right backend key for duplicate values in InvestigationStatusesMapping
                                                            if (val === InvestigationStatusesMapping.FullyRemediated) {
                                                                return 'SuccessfullyRemediated';
                                                            }
                                                            if (val === InvestigationStatusesMapping.PartiallyRemediated) {
                                                                return 'PartiallyRemediated';
                                                            }
                                                            if (val === InvestigationStatusesMapping.PartiallyInvestigated) {
                                                                return 'PartiallyInvestigated';
                                                            }
                                                            return InvestigationStatusesMapping[val];
                                                        });
                                                        return [2 /*return*/, (_c = {},
                                                                _c[param] = namedTransformedValues.join(','),
                                                                _c)];
                                                    }
                                                    else {
                                                        return [2 /*return*/, (_d = {}, _d[param] = value, _d)];
                                                    }
                                                    _e.label = 3;
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    });
                                }))];
                            case 1:
                                serializedFilterValues = _a.sent();
                                return [2 /*return*/, serializedFilterValues.reduce(function (_serializedFilters, fieldValue) { return (__assign({}, _serializedFilters, fieldValue)); }, {})];
                        }
                    });
                }); },
            },
            getShowOpenPageLink: function () { return _this.openAlertPage; },
        };
        this.threatExpertAction = flavorService.isEnabled(AppFlavorConfig.threatExpert.mde);
        this.timelineAction = flavorService.isEnabled(AppFlavorConfig.alerts.timeline);
        this.openAlertPage = flavorService.isEnabled(AppFlavorConfig.incidents.fileOpenPage);
    }
    AlertEntityTypeService.prototype.shouldRedirectAlertToAttackStoryTab = function (alert) {
        return alert.incidentId &&
            this.appContextService.isMtp &&
            this.featuresService.isEnabled('RedirectAlertsToAttackStory') &&
            (this.featuresService.isEnabled(Feature.IncidentV2) || this.featuresService.isEnabled(Feature.IncidentV2Tab));
    };
    AlertEntityTypeService.prototype.isExposedToAlert = function (alertId) {
        return this.paris.apiCall(AlertEntityExposureApiCall, alertId);
    };
    return AlertEntityTypeService;
}());
export { AlertEntityTypeService };
