import { ApiCallModel, ApiCall } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { WebContentFilteringLicenseStatusEntity } from './licensing-status.value-object';
import { DomainFeedback } from './domain-feedback.value-object';

@ApiCall({
	name: 'Web Content Filtering domain feedback request',
	endpoint: 'WebContentFiltering/Reports/ReportFeedback',
	method: 'POST',
	type: WebContentFilteringLicenseStatusEntity,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.webThreatProtection,
})
export class WebContentFilteringDomainFeedback extends ApiCallModel<
	void,
	DomainFeedback
> {}
