import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { ServiceNowConfigMetadata } from './servicenow-config-metadata.value-object';

@ValueObject({
	singularName: 'ServiceNow config details',
	pluralName: 'ServiceNow config details',
})
export class ServiceNowConfigDetails extends ModelBase {
	@EntityField({
		data: 'endpoint',
	})
	readonly endpoint: string;

	@EntityField({
		data: 'clientId',
	})
	readonly clientId: string;

	@EntityField({
		data: 'validConnection',
	})
	readonly validConnection: boolean;

	@EntityField({
		data: 'latestAppVersion',
	})
	readonly latestAppVersion: string;

	@EntityField({
		data: 'appMetadata',
	})
	readonly appMetadata: ServiceNowConfigMetadata;
}
