/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "@angular/router";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i4 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i5 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i6 from "../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i7 from "../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i8 from "../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i9 from "./nav-list.component";
var styles_NavListComponent = [];
var RenderType_NavListComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_NavListComponent, data: {} });
export { RenderType_NavListComponent as RenderType_NavListComponent };
function View_NavListComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NavListComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 8, "a", [["data-track-type", "Navigation"]], [[1, "data-track-id", 0], [1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 3).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = ((!_v.parent.context.$implicit.disabled && _co.selectNavItem(_v.parent.context.$implicit, $event)) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵdid(3, 671744, [[2, 4]], 0, i2.RouterLinkWithHref, [i2.Router, i2.ActivatedRoute, i1.LocationStrategy], { queryParams: [0, "queryParams"], queryParamsHandling: [1, "queryParamsHandling"], routerLink: [2, "routerLink"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NavListComponent_3)), i0.ɵdid(6, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(7, { navItem: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.getTabClassName(_co.tab); _ck(_v, 2, 0, currVal_3); var currVal_4 = _v.parent.context.$implicit.routerLinkQueryParams; var currVal_5 = _v.parent.context.$implicit.routerQueryParamsHandling; var currVal_6 = _v.parent.context.$implicit.routerLink; _ck(_v, 3, 0, currVal_4, currVal_5, currVal_6); var currVal_7 = _ck(_v, 7, 0, _v.parent.context.$implicit); var currVal_8 = i0.ɵnov(_v.parent, 10); _ck(_v, 6, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = ("NavlistLink_" + _v.parent.context.$implicit.id); var currVal_1 = i0.ɵnov(_v, 3).target; var currVal_2 = i0.ɵnov(_v, 3).href; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_NavListComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_NavListComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "a", [["data-track-type", "Button"]], [[1, "data-track-id", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((!_v.parent.context.$implicit.disabled && _co.selectNavItem(_v.parent.context.$implicit, $event)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_NavListComponent_5)), i0.ɵdid(6, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(7, { navItem: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.getNavItemClassName(_v.parent.context.$implicit); _ck(_v, 3, 0, currVal_1); var currVal_2 = _ck(_v, 7, 0, _v.parent.context.$implicit); var currVal_3 = i0.ɵnov(_v.parent, 10); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = ("NavlistButton_" + _v.parent.context.$implicit.id); _ck(_v, 1, 0, currVal_0); }); }
function View_NavListComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 0, "img", [], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.parent.context.navItem.image; _ck(_v, 0, 0, currVal_0); }); }
function View_NavListComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "wcd-shared-icon", [], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i4.IconComponent, [i5.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, [" "]))], function (_ck, _v) { var currVal_0 = _v.parent.context.navItem.icon; _ck(_v, 1, 0, currVal_0); }, null); }
function View_NavListComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["(", ")"])), i0.ɵpid(131072, i6.PrettyNumberPipe, [i7.PrettyNumberService, [2, i8.LocaleConfigService], [2, i0.ChangeDetectorRef]])], function (_ck, _v) { var currVal_0 = (_v.parent.context.navItem.valueClassName || "navItem-value"); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var currVal_1 = i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_v.parent.context.navItem.value)); _ck(_v, 3, 0, currVal_1); }); }
function View_NavListComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavListComponent_7)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavListComponent_8)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(7, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(8, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavListComponent_9)), i0.ɵdid(11, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var currVal_0 = _v.context.navItem.image; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.navItem.icon; _ck(_v, 5, 0, currVal_1); var currVal_3 = ((_v.context.navItem.value !== undefined) && (_v.context.navItem.value !== null)); _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var currVal_2 = (_v.context.navItem.text || _v.context.navItem.name); _ck(_v, 8, 0, currVal_2); }); }
function View_NavListComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 11, "li", [], [[2, "active", null]], null, null, null, null)), i0.ɵdid(1, 1720320, null, 2, i2.RouterLinkActive, [i2.Router, i0.ElementRef, i0.Renderer2, [2, i2.RouterLink], [2, i2.RouterLinkWithHref]], { routerLinkActive: [0, "routerLinkActive"] }, null), i0.ɵqud(603979776, 1, { links: 1 }), i0.ɵqud(603979776, 2, { linksWithHrefs: 1 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavListComponent_2)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(0, [["noLink", 2]], null, 0, null, View_NavListComponent_4)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(0, [["itemContents", 2]], null, 0, null, View_NavListComponent_6)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.currentRouteIsActive ? "active" : ""); _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.$implicit.routerLink; var currVal_3 = i0.ɵnov(_v, 8); _ck(_v, 6, 0, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_v.context.$implicit.id === _co.currentNavItemId); _ck(_v, 0, 0, currVal_0); }); }
export function View_NavListComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "ul", [["class", "nav-list"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_NavListComponent_1)), i0.ɵdid(4, 278528, null, 0, i1.NgForOf, [i0.ViewContainerRef, i0.TemplateRef, i0.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navItems; _ck(_v, 4, 0, currVal_0); }, null); }
export function View_NavListComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "nav-list", [], null, null, null, View_NavListComponent_0, RenderType_NavListComponent)), i0.ɵdid(1, 573440, null, 0, i9.NavListComponent, [], null, null)], null, null); }
var NavListComponentNgFactory = i0.ɵccf("nav-list", i9.NavListComponent, View_NavListComponent_Host_0, { navItems: "navItems", currentNavItemId: "currentNavItemId", currentRouteIsActive: "currentRouteIsActive" }, { select: "select" }, []);
export { NavListComponentNgFactory as NavListComponentNgFactory };
