import { EntityModelBase, Entity, EntityField, DataEntityType } from '@microsoft/paris';
import { incidentGraphNodeEntityTypeValues } from './incident-graph-node-entity-type.values';

@Entity({
	singularName: 'Incident graph node type',
	pluralName: 'Incident graph node types',
	readonly: true,
	values: incidentGraphNodeEntityTypeValues,
})
export class IncidentGraphNodeEntityType extends EntityModelBase<string> {
	@EntityField() name: string;

	@EntityField() icon: string;

	@EntityField() iconName: string;
}
