import { Ip, IpActiveAlertsSummaryRelationship } from '@wcd/domain';
import { AlertsSummaryWidgetBase } from '../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.base';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { I18nService } from '@wcd/i18n';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
	selector: 'ip-alerts-summary-widget',
	templateUrl:
		'../../../../global_entities/components/entity-widgets/alerts-summary/entity-alerts-summary.widget.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IpAlertsSummaryWidgetComponent extends AlertsSummaryWidgetBase<Ip> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		i18nService: I18nService,
		reportsService: ReportsService
	) {
		super(paris, activatedEntity, reportsService, i18nService);
	}

	id = 'ip-alerts-summary';

	entityType = Ip;

	entityAlertsSummaryRelationship = IpActiveAlertsSummaryRelationship;
}
