var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnInit } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { AppContextService, FeaturesService, Feature, FlavorService } from '@wcd/config';
import { AlertEntityTypeService } from '../../../@entities/alerts/services/alert.entity-type.service';
import { Router } from '@angular/router';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { I18nService } from '@wcd/i18n';
import { RoutesService } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
var AlertEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(AlertEntityDetailsComponent, _super);
    function AlertEntityDetailsComponent(injector, featuresService, router, alertEntityTypeService, appContextService, i18nService, flavorService) {
        var _this = _super.call(this, injector) || this;
        _this.featuresService = featuresService;
        _this.router = router;
        _this.alertEntityTypeService = alertEntityTypeService;
        _this.appContextService = appContextService;
        _this.i18nService = i18nService;
        _this.flavorService = flavorService;
        _this.showTitle = true;
        _this.alertDetailsTitle = 'Alert details';
        _this.isScc = sccHostService.isSCC;
        return _this;
    }
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "alert", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AlertEntityDetailsComponent.prototype.ngOnInit = function () {
        this.investigationEnabled =
            this.mode !== EntityDetailsMode.SidePane &&
                this.flavorService.isEnabled(AppFlavorConfig.routes.investigation);
    };
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "showIncidentLink", {
        get: function () {
            var pathSegments = this.router.url && this.router.url.split('/');
            var incidentsIndex = pathSegments.indexOf('incidents');
            var currentIncident = pathSegments.length > incidentsIndex + 1 && pathSegments[incidentsIndex + 1];
            return +currentIncident !== +this.alert.incidentId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "showServiceSource", {
        get: function () {
            return Boolean(this.appContextService.isMtp && this.alert.serviceSource);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "showDetectionSource", {
        get: function () {
            return (this.alert.detectionSource &&
                (!this.appContextService.isMtp || this.detectionSourceDoesNotMatchServiceSource()));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "showAlertTechniques", {
        get: function () {
            return this.featuresService.isEnabled(Feature.AlertDetailsMitreTechniques);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertEntityDetailsComponent.prototype, "SCCIncidentUrl", {
        get: function () {
            return RoutesService.getSCCRootUrl() + "/incidents/" + this.alert.incidentId + "/overview";
        },
        enumerable: true,
        configurable: true
    });
    AlertEntityDetailsComponent.prototype.detectionSourceDoesNotMatchServiceSource = function () {
        return (this.alert.detectionSource &&
            (!this.alert.serviceSource || this.alert.detectionSource.name !== this.alert.serviceSource.name));
    };
    return AlertEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { AlertEntityDetailsComponent };
