import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
	selector: 'manage-linux-offboarding',
	templateUrl: './manage-linux-offboarding.component.html',
})

export class ManageLinuxOffboardingComponent {
	@Input() isServer = false;
}
