import { OnDestroy } from '@angular/core';
import { NotificationsService } from '../../../notifications/services/notifications.service';
import { RemediationActionsBackendService } from './remediation-actions.backend.service';
import { BehaviorSubject, Observable, of, timer } from 'rxjs';
import { catchError, distinctUntilChanged, map, mergeMap, publishReplay, refCount, share, switchMap, tap, } from 'rxjs/operators';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RemediationActionTypeActionCount, MdeUserRoleActionEnum } from '@wcd/domain';
import { RemediationActionsService } from './remediation-actions.service';
import { Paris } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import { differenceBy } from 'lodash-es';
import { AirsEntitiesService } from '../../airs_entities/services/airs-entities.service';
import { FeaturesService, Feature } from '@wcd/config';
import { PollingService } from '@wcd/config';
import { AppContextService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
var PENDING_NOTIFICATION_GROUP = 'Pending Actions';
export var PENDING_USER_NOTIFICATION_ID = 'pendingUser';
var PENDING_RESOURCE_NOTIFICATION_ID = 'pendingResource';
var PENDING_ACTIONS_PAGE_URL = ['/pending'];
var PENDING_ACTIONS_CENTER_PAGE_URL = ['/action-center', 'pending'];
var PENDING_ACTIONS_REFRESH_RATE = 60 * 1000;
var PENDING_ACTIONS_ERROR_REFRESH_RATE = 10 * 1000;
var PendingActionsService = /** @class */ (function () {
    function PendingActionsService(remediationActionsBackendService, entitiesService, remediationActionsService, notificationsService, paris, dialogsService, authService, featuresService, pollingService, appContextService, i18nService) {
        this.remediationActionsBackendService = remediationActionsBackendService;
        this.entitiesService = entitiesService;
        this.remediationActionsService = remediationActionsService;
        this.notificationsService = notificationsService;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.authService = authService;
        this.featuresService = featuresService;
        this.pollingService = pollingService;
        this.appContextService = appContextService;
        this.i18nService = i18nService;
        this._startPendingActionsTimer$ = new BehaviorSubject(null);
        this.isRemediationDisabledSubject$ = new BehaviorSubject(false);
        this.isRemediationDisabled$ = this.isRemediationDisabledSubject$.asObservable();
    }
    Object.defineProperty(PendingActionsService.prototype, "pendingActions$", {
        get: function () {
            if (!this._pendingActions$)
                this.setPendingActionsObservable();
            return this._pendingActions$;
        },
        enumerable: true,
        configurable: true
    });
    /**
     * Resets the polling, so that pending actions are polled immediately, then the timer is restarted.
     */
    PendingActionsService.prototype.resetPolling = function () {
        this._startPendingActionsTimer$.next(null);
    };
    PendingActionsService.prototype.setPendingActionsObservable = function () {
        var _this = this;
        return (this._pendingActions$ = new Observable(function (observer) {
            var resetSubscription;
            var sub = _this._startPendingActionsTimer$
                .asObservable()
                .pipe(switchMap(function () { return _this.pollingService.poll(0, PENDING_ACTIONS_REFRESH_RATE); }), mergeMap(function () {
                return _this.getPendingRemediationActionTypes().pipe(map(function (typeCounts) {
                    return _this.getPendingActionTypes(typeCounts);
                }), tap(function (data) {
                    _this.setNotifications(data);
                    _this._previousPendingActions = data;
                    _this.isRemediationDisabledSubject$.next(false);
                    _this.remediationDisabledMessage = null;
                }), catchError(function (error) {
                    resetSubscription = _this.handleDataLoadError(error, true);
                    _this.notificationsService.setNotification(PENDING_USER_NOTIFICATION_ID, null);
                    _this.notificationsService.setNotification(PENDING_RESOURCE_NOTIFICATION_ID, null);
                    _this._previousPendingActions = null;
                    return of(error);
                }));
            }), distinctUntilChanged(function (previousValue, currentValues) {
                return (_this.serializePendingActions(previousValue) ===
                    _this.serializePendingActions(currentValues));
            }))
                .subscribe(observer);
            return function () {
                sub.unsubscribe();
                resetSubscription && resetSubscription.unsubscribe();
                _this.notificationsService.setNotification(PENDING_USER_NOTIFICATION_ID, null);
                _this.notificationsService.setNotification(PENDING_RESOURCE_NOTIFICATION_ID, null);
            };
        }).pipe(share(), publishReplay(1), refCount()));
    };
    PendingActionsService.prototype.getPendingRemediationActionTypes = function () {
        return this.paris
            .callQuery(RemediationActionTypeActionCount, Object.assign({
            allItemsEndpointTrailingSlash: false,
        }, this.featuresService.isEnabled(Feature.AirsApiOffloading)
            ? {
                endpoint: 'investigations/pending_actions_summary',
                baseUrl: function (config) {
                    return config.data.serviceUrls.threatIntel;
                },
            }
            : {
                endpoint: 'remediation_actions/pending_actions',
                baseUrl: function (config) {
                    return config.data.serviceUrls.automatedIr;
                },
            }))
            .pipe(map(function (dataSet) { return dataSet.items; }));
    };
    PendingActionsService.prototype.serializePendingActions = function (pendingActionTypes) {
        var actionIds = pendingActionTypes.all &&
            pendingActionTypes.all.reduce(function (actionIds, actionType) {
                return actionIds.concat(actionType.actionCount + "_" + actionType.remediationActionType.id);
            }, []);
        return actionIds ? actionIds.join(',') : '';
    };
    PendingActionsService.prototype.setNotifications = function (pendingActions) {
        if (this.featuresService.isEnabled(Feature.DisablePortalRemediationNotifications)) {
            this.notificationsService.setHiddenHighPriorityNotificationId(PENDING_USER_NOTIFICATION_ID);
        }
        if (this.shouldDisplayNotifications()) {
            if (!PendingActionsService.pendingActionsAreEqual(this._previousPendingActions && this._previousPendingActions.user, pendingActions.user))
                this.notificationsService.setNotification(PENDING_USER_NOTIFICATION_ID, pendingActions.user.length
                    ? this.getPendingUserActionsNotification(pendingActions.user)
                    : null);
            if (!PendingActionsService.pendingActionsAreEqual(this._previousPendingActions && this._previousPendingActions.resource, pendingActions.resource))
                this.notificationsService.setNotification(PENDING_RESOURCE_NOTIFICATION_ID, pendingActions.resource.length
                    ? this.getPendingResourceActionsNotification(pendingActions.resource)
                    : null);
        }
    };
    PendingActionsService.prototype.shouldDisplayNotifications = function () {
        return (!(this.appContextService.isSCC && this.featuresService.isEnabled(Feature.PortedSccPages)) &&
            this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.remediationActions));
    };
    PendingActionsService.pendingActionsAreEqual = function (pendingActions1, pendingActions2) {
        if (!pendingActions1 && !pendingActions2)
            return true;
        if (!pendingActions1 || !pendingActions2)
            return false;
        if (pendingActions1.length !== pendingActions2.length)
            return false;
        var difference = differenceBy(pendingActions1, pendingActions2, function (action) {
            return action.actionCount + "_" + action.remediationActionType.id;
        });
        return !difference.length;
    };
    PendingActionsService.prototype.getPendingActionTypes = function (data) {
        var pendingActions = {
            user: [],
            resource: [],
            all: [],
        };
        data.forEach(function (actionType) {
            if (actionType.actionCount) {
                // The backend sends actions with count=0. It's hard to filter data using Python.
                (actionType.remediationActionType.isUserPending
                    ? pendingActions.user
                    : pendingActions.resource).push(actionType);
                pendingActions.all.push(actionType);
            }
        });
        return pendingActions;
    };
    /**
     * Compares two collections of pending action types. Returns true if both have the same types and counts, false otherwise.
     * @param actionTypes1 {Array<RemediationActionTypeActionCount>}
     * @param actionTypes2 {Array<RemediationActionTypeActionCount>}
     */
    PendingActionsService.comparePendingActionTypes = function (actionTypes1, actionTypes2) {
        if (actionTypes1.length !== actionTypes2.length)
            return false;
        var sortedActionTypes1 = actionTypes1.slice(0).sort(sortTypes), sortedActionTypes2 = actionTypes2.slice(0).sort(sortTypes);
        for (var i = 0; i < actionTypes1.length; i++) {
            if (sortedActionTypes1[i].remediationActionType !== sortedActionTypes2[i].remediationActionType ||
                sortedActionTypes1[i].actionCount !== sortedActionTypes2[i].actionCount)
                return false;
        }
        return true;
        function sortTypes(a, b) {
            if (a.remediationActionType === b.remediationActionType)
                return 0;
            return a.remediationActionType.id > b.remediationActionType.id ? 1 : -1;
        }
    };
    PendingActionsService.prototype.getPendingUserActionsNotification = function (data) {
        var totalActionsCount = data.reduce(function (total, actionCount) { return total + actionCount.actionCount; }, 0);
        if (!totalActionsCount)
            return null;
        var notificationTitle = data.length === 1
            ? data[0].remediationActionType.pendingText(data[0].actionCount)
            : this.i18nService.get('remediation_pendingActions_attentionRequired', {
                itemCount: totalActionsCount,
            });
        return {
            id: PENDING_USER_NOTIFICATION_ID,
            title: notificationTitle,
            count: totalActionsCount,
            group: PENDING_NOTIFICATION_GROUP,
            iconName: 'investigations.status.paused',
            iconClassName: 'color-text-warning-dark',
            link: this.featuresService.isEnabled(Feature.ActionHistory)
                ? PENDING_ACTIONS_CENTER_PAGE_URL
                : PENDING_ACTIONS_PAGE_URL,
            priority: 3,
            actions: [],
            contents: data.length === 1
                ? null
                : data.map(function (actionTypeCount) {
                    return {
                        text: actionTypeCount.remediationActionType.pendingText(actionTypeCount.actionCount),
                    };
                }),
        };
    };
    PendingActionsService.prototype.getPendingResourceActionsNotification = function (data) {
        var _this = this;
        var totalActionsCount = data.reduce(function (total, actionCount) {
            return total + actionCount.actionCount;
        }, 0);
        if (!totalActionsCount)
            return null;
        var notificationTitle = data.length === 1
            ? data[0].remediationActionType.pendingText(data[0].actionCount)
            : totalActionsCount + " Pending resource items";
        return {
            id: PENDING_RESOURCE_NOTIFICATION_ID,
            group: PENDING_NOTIFICATION_GROUP,
            title: notificationTitle,
            count: totalActionsCount,
            iconName: 'hosts.uncoveredHost',
            iconClassName: 'partial',
            link: PENDING_ACTIONS_PAGE_URL,
            priority: 4,
            actions: [
                {
                    name: 'Retry All',
                    method: function (notification) {
                        notification.text = 'Retrying...';
                        notification.actionsDisabled = true;
                        _this.remediationActionsService
                            .retryPendingActions()
                            .toPromise()
                            .then(function () {
                            _this.notificationsService.removeNotification(PENDING_RESOURCE_NOTIFICATION_ID);
                            // TODO: Request from websocket
                        });
                    },
                    iconName: 'refresh',
                },
            ],
            contents: data.length === 1
                ? null
                : data.map(function (actionTypeCount) {
                    return {
                        text: actionTypeCount.remediationActionType.pendingText(actionTypeCount.actionCount),
                    };
                }),
        };
    };
    PendingActionsService.prototype.handleDataLoadError = function (error, resetPolling) {
        var _this = this;
        if (resetPolling === void 0) { resetPolling = false; }
        if (error.status === 405) {
            this.isRemediationDisabledSubject$.next(true);
            this.remediationDisabledMessage = error.response;
        }
        else {
            this.isRemediationDisabledSubject$.next(false);
            this.remediationDisabledMessage = null;
            if (resetPolling) {
                console.warn("pending actions polling failed. trying again in " + PENDING_ACTIONS_ERROR_REFRESH_RATE /
                    1000 + " seconds.");
                this.timerSub && this.timerSub.unsubscribe();
                return (this.timerSub = timer(PENDING_ACTIONS_ERROR_REFRESH_RATE).subscribe(function () {
                    return _this.resetPolling();
                }));
            }
        }
    };
    PendingActionsService.prototype.ngOnDestroy = function () {
        this.timerSub && this.timerSub.unsubscribe();
        this._startPendingActionsTimer$.complete();
        this.isRemediationDisabledSubject$.complete();
    };
    return PendingActionsService;
}());
export { PendingActionsService };
