<button *ngIf="onClick; else taggedField" class="field-value-button" type="button"
						(click)="onClick(value)">
	<ng-template [ngTemplateOutlet]="taggedField"></ng-template>
</button>

<ng-template #taggedField>
	<div class="wcd-flex-center-vertical">
		<div class="wcd-flex-1 wcd-flex-horizontal-align-items-center wcd-text-overflow-ellipsis" *ngIf="!tagAfterText; else afterTitle">
			<div
				*ngIf="showTag"
				class="wcd-flex-horizontal tag wcd-margin-small-right color-box-dark-medium-opacity"
				[wcdTooltip]="tooltip">
				{{ tagText }}
			</div>
			<fab-icon
				*ngIf="productivityAssessmentInfo.isProductivityAssessmentIncluded"
				wcdTooltip
				[wcdTooltip]="productivityAssessmentInfo.productivityAssessmentTooltip"
				[wcdTooltipAllowHtmlRendering]="true"
				(onDisplay)="onTooltipDisplay()"
				iconName="UserGauge"
				[contentClass]="productivityAssessmentInfo.iconColoring.iconColorClass + ' tvm-productivity-assessment-icon ' + productivityAssessmentInfo.iconColoring.backgroundColorClass"
			>
			</fab-icon>
				{{ title }}
		</div>
		<ng-template #afterTitle>
			<div class="wcd-flex-1 wcd-flex-horizontal">
				<div class="wcd-text-overflow-ellipsis">
					{{ title }}
				</div>
				<div *ngIf="showTag" class="wcd-flex-horizontal">
					<div [class]="tagColorClass +' tvm-tag-icon wcd-margin-small-left '+ tagBackgroundColorClass">
					</div>
					<div [class]="tagColorClass + ' wcd-padding-small-left'" [wcdTooltip]="tooltip">
						{{ tagText }}
					</div>
				</div>
			</div>
		</ng-template>
		<tvm-report-inaccuracy-dropdown
			[inaccuracyContext]="inaccuracyContext"
			[contextObject]="contextObject"
		></tvm-report-inaccuracy-dropdown>
	</div>
</ng-template>
