/* tslint:disable:template-accessibility-alt-text */
import { Component, Input } from '@angular/core';
import { StatusModel } from '@wcd/domain';

@Component({
	selector: 'wcd-status',
	template: `
		<span *ngIf="status">
			<wcd-shared-icon
				*ngIf="status.iconName; else checkImg"
				[iconName]="status.iconName"
				[iconClass]="status.className ? 'color-text-' + status.className : ''"
				[ariaLabel]="ariaLabel"
			></wcd-shared-icon>
			<ng-template #checkImg>
				<img *ngIf="status.image" [src]="status.image | basePath" class="status-img" />
			</ng-template>
			{{ status.nameI18nKey ? (status.nameI18nKey | i18n) : status.name }}
		</span>
	`,
	styleUrls: ['./status.component.scss'],
})
export class StatusComponent {
	@Input() status: StatusModel;
	@Input() ariaLabel?: string;
}
