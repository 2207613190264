import { ModuleWithProviders, NgModule } from '@angular/core';
import { I18nPipe } from './pipes/i18n.pipe';
import { I18nService } from './services/i18n.service';

@NgModule({
	declarations: [I18nPipe],
	imports: [],
	exports: [I18nPipe],
})
export class I18nModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: I18nModule,
			providers: [I18nService],
		};
	}
}
