import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { NotificationRuleConditionType } from './notification-rule-condition-type';
import { NotificationRuleSeverityConditionValue } from './notification-rule-condition-value';

@ValueObject({
	singularName: 'Notification Rule Condition Item',
	pluralName: 'Notification Rule Condition Items',
})
export class NotificationRuleCondition extends ModelBase {
	@EntityField({ data: 'RuleType' })
	type: NotificationRuleConditionType;

	@EntityField({
		data: 'Values',
		serialize: (values: Array<NotificationRuleSeverityConditionValue>) => {
			return values.map(value => value.id);
		},
		arrayOf: NotificationRuleSeverityConditionValue,
	})
	values: Array<NotificationRuleSeverityConditionValue>;

	get isValid(): boolean {
		return this.type && !!this.values.length;
	}
}
