/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./dataview-action-content.component";
var styles_DataviewActionContentComponent = [".template-wrapper[_ngcontent-%COMP%] {\n\t\t\t\tmin-height: 40px;\n\t\t\t\tdisplay: flex;\n\t\t\t}"];
var RenderType_DataviewActionContentComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DataviewActionContentComponent, data: {} });
export { RenderType_DataviewActionContentComponent as RenderType_DataviewActionContentComponent };
export function View_DataviewActionContentComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 8, "div", [["class", "hidden-action"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 5, "div", [["class", "template-wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 16777216, null, null, 2, null, null, null, null, null, null, null)), i0.ɵdid(6, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i0.ɵpod(7, { smallScreenView: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 7, 0, _co.smallScreenView); var currVal_1 = _co.template; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_DataviewActionContentComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dataview-action-content", [], null, null, null, View_DataviewActionContentComponent_0, RenderType_DataviewActionContentComponent)), i0.ɵdid(1, 114688, null, 0, i2.DataviewActionContentComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DataviewActionContentComponentNgFactory = i0.ɵccf("dataview-action-content", i2.DataviewActionContentComponent, View_DataviewActionContentComponent_Host_0, { contentActionConfig: "contentActionConfig", smallScreenView: "smallScreenView", isHidden: "isHidden" }, {}, []);
export { DataviewActionContentComponentNgFactory as DataviewActionContentComponentNgFactory };
