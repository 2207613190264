
		<ng-container *ngLet="(interflowData$ | async) as interflowData">
			<dl class="key-values clearfix" role="none">
				<ng-container *ngIf="entity.address">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_address }}</dt>
					<dd role="none">{{ entity.address }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.organizationAsn">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_asn }}</dt>
					<dd role="none">{{ entity.organizationAsn }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.organizationName">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_organization }}</dt>
					<dd role="none">{{ entity.organizationName }}</dd>
				</ng-container>
				<ng-container *ngIf="interflowData && interflowData !== LOADING_SYMBOL">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_geoInformation }}</dt>
					<dd role="none">
						<generic-object
							[fieldName]="i18nService.strings.airsEntities_ip_fields_geoInformation"
						>
							<dl class="key-values clearfix" role="none">
								<ng-container *ngIf="interflowData.city">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_city }}
									</dt>
									<dd role="none">{{ interflowData.city | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.postalCode">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_postal }}
									</dt>
									<dd role="none">{{ interflowData.postalCode }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.country || interflowData.region">
									<dt role="none">
										{{
											i18nService.strings.airsEntities_ip_fields_geoInformation_country_and_region
										}}
									</dt>
									<dd role="none">
										{{ countryName }}
									</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.state">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_state }}
									</dt>
									<dd role="none">{{ interflowData.state | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.carrier">
									<dt role="none">
										{{
											i18nService.strings.airsEntities_ip_fields_geoInformation_carrier
										}}
									</dt>
									<dd role="none">{{ interflowData.carrier | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.coordinates">
									<dt role="none">
										{{
											i18nService.strings
												.airsEntities_ip_fields_geoInformation_coordinates
										}}
									</dt>
									<dd role="none">{{ interflowData.coordinates }}</dd>
								</ng-container>
							</dl></generic-object
						>
					</dd>
				</ng-container>
			</dl>
			<ng-container *ngIf="interflowData === LOADING_SYMBOL" [ngTemplateOutlet]="loader"></ng-container>
		</ng-container>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</ng-template>
	