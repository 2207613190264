import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AirsNotificationRuleModel } from '../models/airs-notification-rule.model';
import { PanelContainer } from '@wcd/panels';

@Component({
	selector: 'airs-notification-rule-edit-panel',
	template: `
		<wcd-panel (close)="destroy()" (startClose)="startClose()" [settings]="settings">
			<airs-notification-rule-edit
				[notificationRule]="notificationRule"
				(save)="onDone($event)"
				(cancel)="onCancel()"
			></airs-notification-rule-edit>
		</wcd-panel>
	`,
})
export class AirsNotificationRuleEditPanelComponent extends PanelContainer {
	@Input() onDone: Function;
	@Input() onCancel: Function;
	@Input() notificationRule: AirsNotificationRuleModel;

	constructor(protected router: Router) {
		super(router);
	}

	cancelEdit() {
		this.startClose();
		this.onCancel();
	}

	save($event) {
		this.onDone($event);
		this.startClose();
	}
}
