/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i4 from "../../../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i5 from "../../../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i6 from "./assessment-job-completed.component";
import * as i7 from "@angular/router";
var styles_AssessmentJobCompletedComponent = [];
var RenderType_AssessmentJobCompletedComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AssessmentJobCompletedComponent, data: {} });
export { RenderType_AssessmentJobCompletedComponent as RenderType_AssessmentJobCompletedComponent };
export function View_AssessmentJobCompletedComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 7, "h2", [["class", "wcd-font-weight-bold"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "wcd-shared-icon", [["class", "large-icon ms-color-greenLight wcd-vertical-align-bottom wcd-margin-small-right"], ["iconName", "SkypeCircleCheck"]], null, null, null, i3.View_IconComponent_0, i3.RenderType_IconComponent)), i0.ɵdid(4, 573440, null, 0, i4.IconComponent, [i5.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵted(6, null, ["\n    ", "\n"])), i0.ɵppd(7, 1), i0.ɵppd(8, 1), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(10, 0, null, null, 3, "div", [["class", "wcd-margin-large-top wcd-margin-mediumSmall-bottom"]], null, null, null, null, null)), (_l()(), i0.ɵted(11, null, ["\n    ", "\n"])), i0.ɵppd(12, 1), i0.ɵppd(13, 1), (_l()(), i0.ɵted(-1, null, ["\n\n"])), (_l()(), i0.ɵeld(15, 0, null, null, 2, "div", [["class", "wcd-margin-xLarge-top wcd-font-weight-semibold"]], null, null, null, null, null)), (_l()(), i0.ɵted(16, null, ["\n    ", "\n"])), i0.ɵppd(17, 1), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(19, 0, null, null, 2, "a", [["href", "#"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.navigateToDeviceInventory() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(20, null, ["", ""])), i0.ɵppd(21, 1), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var currVal_0 = "SkypeCircleCheck"; _ck(_v, 4, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.data.isNewDraft ? i0.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_new_completed_step_title")) : i0.ɵunv(_v, 6, 0, _ck(_v, 8, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_update_completed_step_title"))); _ck(_v, 6, 0, currVal_1); var currVal_2 = (_co.data.isNewDraft ? i0.ɵunv(_v, 11, 0, _ck(_v, 12, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_create_completed_step_description")) : i0.ɵunv(_v, 11, 0, _ck(_v, 13, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_update_completed_step_description"))); _ck(_v, 11, 0, currVal_2); var currVal_3 = i0.ɵunv(_v, 16, 0, _ck(_v, 17, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_completed_step_related_features")); _ck(_v, 16, 0, currVal_3); var currVal_4 = i0.ɵunv(_v, 20, 0, _ck(_v, 21, 0, i0.ɵnov(_v, 0), "tvm_authenticatedScan_wizard_completed_step_device_inventory")); _ck(_v, 20, 0, currVal_4); }); }
export function View_AssessmentJobCompletedComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_AssessmentJobCompletedComponent_0, RenderType_AssessmentJobCompletedComponent)), i0.ɵdid(1, 49152, null, 0, i6.AssessmentJobCompletedComponent, [i7.Router], null, null)], null, null); }
var AssessmentJobCompletedComponentNgFactory = i0.ɵccf("ng-component", i6.AssessmentJobCompletedComponent, View_AssessmentJobCompletedComponent_Host_0, { data: "data" }, {}, []);
export { AssessmentJobCompletedComponentNgFactory as AssessmentJobCompletedComponentNgFactory };
