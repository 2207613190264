import { EventEmitter } from '@angular/core';
import { memoize } from 'lodash-es';
import { PrettyNumberService } from '@wcd/prettify';
import { FlexDirection } from '@wcd/shared';
var ɵ0 = function (isLast, orientation) {
    var margin = orientation === FlexDirection.Horizontal && !isLast ? 'wcd-margin-small-right' : '';
    return "wcd-flex-spaceBetween-horizontal wcd-chart-legend " + margin;
};
var getLegendItemClass = memoize(ɵ0);
var LegendComponent = /** @class */ (function () {
    function LegendComponent(prettyNumberService) {
        var _this = this;
        this.showValues = false;
        this.legendKind = 'regular';
        this.orientation = FlexDirection.Vertical;
        this.wrap = false;
        this.select = new EventEmitter();
        this.itemRole = 'listitem';
        this.FlexDirection = FlexDirection;
        this.getItemClass = function (isLast) { return getLegendItemClass(isLast, _this.orientation); };
        this._defaultValueFormatter = function (value) { return prettyNumberService.prettyNumber(value); };
    }
    Object.defineProperty(LegendComponent.prototype, "hostFullHeight", {
        /**
         * To be used by the Angular template engine to apply class to the host component.
         */
        get: function () {
            return this.orientation === FlexDirection.Vertical;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegendComponent.prototype, "hostFlexHorizontal", {
        /**
         * Computed value to allow the Angular template engine to apply a class based on a condition.
         */
        get: function () {
            return this.orientation === FlexDirection.Horizontal;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegendComponent.prototype, "hostFlexWrap", {
        /**
         * Computed value to allow the Angular template engine to apply a class based on a condition.
         */
        get: function () {
            return this.wrap === true;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegendComponent.prototype, "hostFlexVertical", {
        /**
         * Computed value to allow the Angular template engine to apply a class based on a condition.
         */
        get: function () {
            return this.orientation === FlexDirection.Vertical;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegendComponent.prototype, "hostSmall", {
        /**
         * Computed value to allow the Angular template engine to apply a class based on a condition.
         */
        get: function () {
            return this.legendKind === 'small';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(LegendComponent.prototype, "formatter", {
        get: function () {
            return this.valueFormatter || this._defaultValueFormatter;
        },
        enumerable: true,
        configurable: true
    });
    return LegendComponent;
}());
export { LegendComponent };
export { ɵ0 };
