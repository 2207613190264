import { SevilleModule } from '../../seville/seville.module';

SevilleModule.controller(
	'seville.actioncenter.response.cancel.confirmation',
	responseCancelConfirmationController
);

responseCancelConfirmationController.$inject = [
	'$log',
	'$scope',
	'$state',
	'$http',
	'$uibModalInstance',
	'entity',
	'action',
	'openActionCenterPanel',
	'blackListService',
];

function responseCancelConfirmationController(
	$log,
	$scope,
	$state,
	$http,
	$uibModalInstance,
	entity,
	action,
	openActionCenterPanel,
	blackListService
) {
	var vm = $scope;

	vm.entity = entity;
	vm.action = action;
	vm.openActionCenterPanel = openActionCenterPanel;

	vm.ok = function() {
		if (!vm.entity.commentText || vm.entity.commentText == '') return;

		if (vm.action == 'Unblock' && vm.openActionCenterPanel == true) {
			blackListService.blackList(
				vm.entity,
				vm.entity.commentText,
				true,
				'Unblocked',
				vm.openActionCenterPanel,
				vm.entity.machineGroupsIds
			);

			vm.entity.commentText = '';
		}

		$uibModalInstance.close(true);
	};

	vm.close = function() {
		$uibModalInstance.dismiss();
	};
}
