import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import {
	RemediationCategory,
	RemediationTask,
	RemediationTicketStateValue,
	RemediationType,
	RemediationTaskStateValue,
	RemediationTaskPriority,
	ItsmTool,
} from '@wcd/domain';
import { FieldsService } from '../../../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { RemediationTaskFixedAssetsPipe } from '../../../../../tvm/pipes/remediation-task-fixed-assets.pipe';
import { RemediationTaskStatusBarComponent } from '../../../../../tvm/components/remediation-task.status-bar/remediation-task.status-bar.component';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../../../tvm/pipes/remediation-task-display-remaining-days.pipe';
import { CalculateRemainingDaysPipe } from '../../../../../shared/pipes/calculate-remaining-days.pipe';
import { TvmTextsService, TextToken } from '../../../../../tvm/services/tvm-texts.service';
import { TruncatePipe } from '../../../../../shared/pipes/truncate.pipe';
import { FeaturesService, Feature } from '@wcd/config';
import _ from 'lodash';

@Injectable()
export class RemediationTasksFieldsService implements FieldsService<RemediationTask> {
	private _fields: Array<DataviewField<RemediationTask>>;

	constructor(
		private tzDateService: TzDateService,
		private i18nService: I18nService,
		private remediationTaskFixedAssetsPipe: RemediationTaskFixedAssetsPipe,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private remediationTaskDisplayRemainingDaysPipe: RemediationTaskDisplayRemainingDaysPipe,
		private tvmTextsService: TvmTextsService,
		private truncatePipe: TruncatePipe,
		private featuresService: FeaturesService
	) {}

	get fields(): Array<DataviewField<RemediationTask>> {
		/*
		//TODO: consider:
		Ben Grynhaus
			FYI there's a Lazy<T> class I wrote, partially due to this, which also disallows the use of readonly on the _fields (even though it's only written once).
			Not everyone likes it, but at least I feel it makes the code more readable (& safe), so no obligation to use it, but up to you.
			It does exactly what the C# equivalent does.
		*/
		if (!this._fields) {
			this._fields = DataviewField.fromList<RemediationTask>([
				{
					id: 'name',
					name: this.i18nService.strings.tvm_common_remediationActivity,
					minWidth: 350,
				},
				{
					id: 'relatedComponent',
					name: this.i18nService.strings.tvm_common_relatedComponent,
					getDisplay: (remediationTask: RemediationTask) =>
						this.tvmTextsService.getText(TextToken.RemediationRelatedComponent, remediationTask),
				},
				{
					id: 'remediationType',
					name: this.i18nService.strings.tvm_common_remediationType,
					getDisplay: (remediationTask: RemediationTask) => {
						return this.i18nService.get(
							`tvm_remediationTask_type_${
								remediationTask.taskArgs.category ===
								RemediationCategory.SecurityConfiguration
									? remediationTask.taskArgs.securityConfigurationArgs.taskType
									: remediationTask.taskArgs.softwareArgs.taskType
							}`
						);
					},
				},
				{
					id: 'priority',
					name: this.i18nService.strings.tvm_common_priority,
					getDisplay: (remediationTask: RemediationTask) =>
						remediationTask.priority !== 0
							? this.i18nService.get(
									`tvm_remediationTask_priority_${
										RemediationTaskPriority[remediationTask.priority]
									}`
							  )
							: this.i18nService.strings.notAvailable_short,
					sort: {
						sortCompareFunction: (task1: RemediationTask, task2: RemediationTask) =>
							task1.priority - task2.priority,
					},
				},
				{
					id: 'status',
					name: this.i18nService.strings.tvm_remediationTask_taskDetailsDescription,
					description: this.i18nService.strings.tvm_remediationTask_monitoring_description,
					component: {
						type: RemediationTaskStatusBarComponent,
						getProps: (remediationTask: RemediationTask) => ({
							valuePrefix: this.i18nService.strings.tvm_remediationTask_monitoring_completed,
							restPrefix: this.i18nService.strings.tvm_remediationTask_monitoring_incomplete,
							width: '100%',
							title: this.getTruncatedTitle(
								this.tvmTextsService.getText(
									TextToken.RemediationTaskStatusDescription,
									remediationTask
								)
							),
							value: this.remediationTaskFixedAssetsPipe.transform(remediationTask),
							total: remediationTask.targetAssets,
							showTitleIfNoBar: true,
							// Hide behind flight to not ruin existing behaviour. We want to show hyphen if remediation type is type AttentionRequired
							inDataTable: true,
							showHyphen:
								remediationTask.taskArgs.softwareArgs &&
								remediationTask.taskArgs.softwareArgs.taskType ===
									RemediationType.AttentionRequired,
						}),
					},
					sort: { sortLocally: false },
					minWidth: 250,
				},
				{
					id: 'itsmService',
					name: this.i18nService.strings.tvm_remediationTask_ticket_statusTitle,
					icon: {
						fabricIcon: (remediationTask: RemediationTask) => {
							if (
								remediationTask.ticket &&
								remediationTask.ticket.status === RemediationTicketStateValue.FailedToCreate
							) {
								return FabricIconNames.Error;
							}
							return null;
						},
						className: 'color-text-warning-dark',
					},
					getDisplay: (remediationTask: RemediationTask) => {
						if (
							!remediationTask.ticket ||
							remediationTask.ticket.status === RemediationTicketStateValue.CompletedTask ||
							(!this.featuresService.isEnabled(Feature.TvmServiceNowIntegration) &&
								remediationTask.ticket.itsmTool === ItsmTool.ServiceNow)
						) {
							return this.i18nService.strings.tvm_remediationTask_noTickets;
						} else {
							return this.tvmTextsService.getText(
								TextToken.RemediationTicketStatusDescription,
								remediationTask.ticket
							);
						}
					},
				},
				{
					id: 'createdBy',
					name: this.i18nService.strings.tvm_common_createdBy,
					getDisplay: (remediationTask: RemediationTask) =>
						remediationTask.requester
							? remediationTask.requester.email
							: this.i18nService.strings.notAvailable_short,
				},
				{
					id: 'completedBy',
					name: this.i18nService.strings.tvm_common_completedBy,
					getDisplay: (remediationTask: RemediationTask) =>
						this.tvmTextsService.getText(TextToken.RemediationTaskCompletedBy, remediationTask),
				},
				{
					id: 'createdOn',
					name: this.i18nService.strings.tvm_securityRecommendation_relatedRemediation_createdOn,
					getDisplay: (remediationTask: RemediationTask) => {
						return this.tzDateService.format(remediationTask.creationTime, 'shortDate');
					},
					sort: {
						sortCompareFunction: (task1: RemediationTask, task2: RemediationTask) =>
							task1.creationTime.getTime() - task2.creationTime.getTime(),
					},
				},
				{
					id: 'dueDate',
					name: this.i18nService.strings.tvm_common_dueOn,
					getDisplay: (remediationTask: RemediationTask) => {
						return remediationTask.taskArgs.softwareArgs &&
							remediationTask.taskArgs.softwareArgs.taskType ===
								RemediationType.AttentionRequired
							? this.i18nService.strings.tvm_remediationTask_noDate
							: this.tzDateService.format(remediationTask.dueDate, 'shortDate');
					},
					sort: {
						sortCompareFunction: (task1: RemediationTask, task2: RemediationTask) =>
							task1.dueDate.getTime() - task2.dueDate.getTime(),
					},
				},
				{
					id: 'remainingDays',
					name: this.i18nService.strings.tvm_remediationTask_ticket_dueDate,
					getDisplay: (remediationTask: RemediationTask) => {
						const remainingDays = this.calculateRemainingDaysPipe.transform(
							remediationTask.dueDate
						);
						return this.remediationTaskDisplayRemainingDaysPipe.transform(
							remediationTask,
							remainingDays
						);
					},
					sort: {
						sortCompareFunction: (task1: RemediationTask, task2: RemediationTask) =>
							task1.status.value === RemediationTaskStateValue.Completed
								? 1
								: task2.status.value === RemediationTaskStateValue.Completed
								? -1
								: task1.dueDate.getTime() - task2.dueDate.getTime(),
					},
					icon: {
						fabricIcon: (remediationTask: RemediationTask) => {
							const remainingDays = this.calculateRemainingDaysPipe.transform(
								remediationTask.dueDate
							);
							if (
								remediationTask.status.value !== RemediationTaskStateValue.Completed &&
								remainingDays < 0
							) {
								return FabricIconNames.Warning;
							}
						},
						className: 'color-text-warning',
					},
				},
			]).filter(field => !_.isNull(field));
		}

		return this._fields;
	}

	getTruncatedTitle(title: string) {
		return this.truncatePipe.transform(title, 45);
	}
}
