import { Paris } from '@microsoft/paris';
import { FabricIconNames } from '@wcd/scc-common';
import { Lazy } from '../../../utils/lazy';
import { combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { AadUserEntityPanelComponent } from './components/aad-user-entity-panel.component';
import { AadUser, AadUserLegacyUserProfileRelationship, AirsEntityType, } from '@wcd/domain';
import { AadUserEntityDetailsComponent } from '../../../global_entities/components/entity-details/aad-user/aad-user-entity-details.component';
import { AadUserStatusComponent } from './components/aad-user-entity-status.component';
import { FeaturesService } from '@wcd/config';
import { Router } from '@angular/router';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { compact } from 'lodash-es';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { RoutesService } from '@wcd/shared';
var AadUserEntityTypeService = /** @class */ (function () {
    function AadUserEntityTypeService(paris, featuresService, router, routesService, goHuntService) {
        var _this = this;
        this.paris = paris;
        this.featuresService = featuresService;
        this.router = router;
        this.routesService = routesService;
        this.goHuntService = goHuntService;
        this._entityType = new Lazy(function () { return ({
            id: 'aaduser',
            entity: AadUser,
            icon: FabricIconNames.Contact,
            getPanelImage: function (users) {
                if (!users.length) {
                    return null;
                }
                return _this.paris
                    .getRelatedItem(AadUserLegacyUserProfileRelationship, users[0])
                    .pipe(map(function (userProfile) { return userProfile.thumbnailPhotoSrc; }));
            },
            getImageCssClass: function (users) { return 'wcd-circle aaduser-image'; },
            singleEntityPanelComponentType: AadUserEntityPanelComponent,
            entityPanelStatusComponentType: AadUserStatusComponent,
            entityDetailsComponentType: AadUserEntityDetailsComponent,
            loadFullEntityInPanel: false,
            getEntityName: function (user) { return user.displayName || user.sid || user.aadUserId; },
            entityPluralNameKey: 'user_entityType_pluralName',
            entitySingularNameKey: 'user_entityType_singularName',
            getEntitiesLink: function (users) {
                if (!users || users.length !== 1) {
                    return null;
                }
                var user = users[0];
                return user.userPageUrl;
            },
            getActions: function (users, options) {
                var user = users[0];
                var actions = [];
                if (users.length == 1) {
                    actions.push(_this.createGoHuntFromIncidentAction(user, options));
                }
                return combineLatest(actions).pipe(map(function (actionConfigs) { return compact(actionConfigs); }), map(function (actionsConfigs) { return actionsConfigs.map(function (actionConfig) { return new ItemActionModel(actionConfig); }); }));
            },
            getUseExternalRouting: function (users) { return true; },
            getNavigationModel: function (user) {
                var sccRedirectFunc = function (path) {
                    return user.aadUserId
                        ? "/user/?aad=" + user.aadUserId
                        : user.sid
                            ? "/user/?sid=" + user.sid
                            : "/user/?accountName=" + user.accountName + "&accountDomain=" + user.domainName;
                };
                return {
                    externalLink: user.userPageUrl,
                    sccInternalRedirectDefinition: sccRedirectFunc,
                };
            },
        }); });
    }
    Object.defineProperty(AadUserEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    AadUserEntityTypeService.prototype.isMcasInternalRoutingEnabled = function () {
        return (this.router.url.startsWith('/incidents'));
    };
    AadUserEntityTypeService.prototype.createGoHuntFromIncidentAction = function (user, options) {
        return this.goHuntService.getGoHuntActionObservable(user, AirsEntityType.User, {
            incident: options && options.incident,
        });
    };
    return AadUserEntityTypeService;
}());
export { AadUserEntityTypeService };
