/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "./async-datatable-field.component";
var styles_AsyncDataTableFieldComponent = [];
var RenderType_AsyncDataTableFieldComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AsyncDataTableFieldComponent, data: {} });
export { RenderType_AsyncDataTableFieldComponent as RenderType_AsyncDataTableFieldComponent };
function View_AsyncDataTableFieldComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 6, "wcd-shared-icon", [], null, null, null, i1.View_IconComponent_0, i1.RenderType_IconComponent)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), i0.ɵdid(4, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getIconCssClassFunc(i0.ɵunv(_v, 2, 0, i0.ɵnov(_v, 3).transform(_co.value$))); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.getIconNameFunc(i0.ɵunv(_v, 4, 0, i0.ɵnov(_v, 5).transform(_co.value$))); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_AsyncDataTableFieldComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 9, "span", [], null, null, null, null, null)), i0.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 2, null, View_AsyncDataTableFieldComponent_1)), i0.ɵdid(7, 16384, null, 0, i2.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(9, null, ["\n\t\t\t", "\n\t\t"])), i0.ɵpid(131072, i2.AsyncPipe, [i0.ChangeDetectorRef]), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getCssClassFunc(i0.ɵunv(_v, 3, 0, i0.ɵnov(_v, 4).transform(_co.value$))); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.getIconNameFunc && _co.getIconNameFunc(i0.ɵunv(_v, 7, 0, i0.ɵnov(_v, 8).transform(_co.value$)))); _ck(_v, 7, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.getTextFunc(i0.ɵunv(_v, 9, 0, i0.ɵnov(_v, 10).transform(_co.value$))); _ck(_v, 9, 0, currVal_2); }); }
export function View_AsyncDataTableFieldComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-sync-datatable-field", [], null, null, null, View_AsyncDataTableFieldComponent_0, RenderType_AsyncDataTableFieldComponent)), i0.ɵdid(1, 49152, null, 0, i5.AsyncDataTableFieldComponent, [], null, null)], null, null); }
var AsyncDataTableFieldComponentNgFactory = i0.ɵccf("wcd-sync-datatable-field", i5.AsyncDataTableFieldComponent, View_AsyncDataTableFieldComponent_Host_0, { value$: "value$", getTextFunc: "getTextFunc", getCssClassFunc: "getCssClassFunc", getIconNameFunc: "getIconNameFunc", getIconCssClassFunc: "getIconCssClassFunc" }, {}, []);
export { AsyncDataTableFieldComponentNgFactory as AsyncDataTableFieldComponentNgFactory };
