export const incidentGraphCategoryValues = [
	{
		id: 'StartingPoint',
		name: 'Starting point',
		priority: 0,
		color: '#5c005c',
	},
	{
		id: 'Persistence',
		name: 'Persistence',
		priority: 1,
		color: '#008272',
	},
	{
		id: 'SuspiciousNetworkCommunications',
		name: 'Suspicious network communications',
		priority: 2,
		color: '#00188f',
	},
	{
		id: 'Infected',
		name: 'Infected',
		priority: 3,
		color: '#a80000',
	},
	{
		id: 'CompromisedResource',
		name: 'Compromised resource',
		priority: 5,
		color: '#d83b01',
	},
];
