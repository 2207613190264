import { FabIconButtonComponent } from '@angular-react/fabric';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnDestroy,
	OnInit,
	SecurityContext,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { IContextualMenuItem } from 'office-ui-fabric-react';
import { Subject, Subscription } from 'rxjs';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetMenuItem } from '../models/report-widget-menu-item.interface';
import { ReportWidgetModel } from '../models/report-widget.model';
import { WicdSanitizerService } from '@wcd/shared';

@Component({
	selector: 'report-widget-header',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="widget-contents-header wcd-flex-horizontal widget-contents-header-top">
			<div class="wcd-flex-1 wcd-flex-center-vertical paddingRight20">
				<div>
					<header class="widget-header" *ngIf="name">
						<h3 [attr.aria-level]="headerLevel">
							<span [id]="headerId || widget.id + '-header'">{{ name }}</span>
							<wcd-help
								*ngIf="help"
								[text]="help"
								[wcdTooltipAllowHtmlRendering]="widget.helpTooltipAllowHtmlRendering"
								[wcdTooltipEnableKeyboardEvents]="true"
								class="wcd-padding-small-left"></wcd-help>
						</h3>
					</header>
				</div>
			</div>
			<div *ngIf="extraHtml" [innerHtml]="extraHtml" class="wcd-flex-1"></div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="rangeInDays"
			>
				{{ rangeInDays }} {{ 'datetime.day.plural.value' | i18n }}
			</div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="dateOfWidget"
			>
				{{ dateOfWidget }}
			</div>
			<div
				class="wcd-flex-1 wcd-flex-justify-end wcd-flex-center-vertical widget-timestamp"
				*ngIf="dateRangeOfWidget"
			>
				{{ dateRangeOfWidget }}
			</div>

			<fab-icon-button
				[menuIconProps]="{ iconName: 'MoreVertical' }"
				contentClass="wcd-flex-none noprint"
				data-track-id="WidgetMenuOpen"
				data-track-type="Button"
				*ngIf="widget.menu"
				[menuProps]="{ items: menuItems }"
				[styles]="dropdownMenuStyles"
			>
			</fab-icon-button>
		</div>
	`,
})
export class ReportWidgetHeaderComponent implements OnInit, OnDestroy {
	@Input() widget: ReportWidgetModel;
	@Input() data$: Subject<any>;
	@Input() params: { [index: string]: any };
	@Input() headerId: string = null;
	@Input() headerLevel: number = 3;

	description: string;
	name: string;
	help: string;
	extraHtml: SafeHtml;
	dateOfWidget: string;
	dateRangeOfWidget: string;
	rangeInDays: number;
	menuItems: ReadonlyArray<IContextualMenuItem>;
	dropdownMenuStyles: FabIconButtonComponent['styles'];

	private _dataSubscription: Subscription;

	get ApiBaseUrl(): string {
		return this.serviceUrlsService.automatedIr;
	}

	get paramsString(): string {
		return this.params
			? Object.keys(this.params)
					.map((key: string) => `${key}=${encodeURIComponent(this.params[key])}`)
					.join('&')
			: '';
	}

	constructor(
		private _changeDetectionRef: ChangeDetectorRef,
		private serviceUrlsService: ServiceUrlsService,
		private sanitizer: WicdSanitizerService
	) {
		this.dropdownMenuStyles = {
			root: { height: '100%', width: '100%' },
			menuIcon: { margin: 0 },
		};
	}

	ngOnInit() {
		this.name = this.widget.name;
		this.help = this.widget.help;
		this.rangeInDays = typeof this.widget.rangeInDays === 'number' && this.widget.rangeInDays;
		this.menuItems =
			this.widget.menu &&
			this.widget.menu.map<IContextualMenuItem>(item => ({
				key: item.key,
				href: this.getMenuItemHref(item),
				onClick: item.onClick ? () => item.onClick() : null,
				target: item.target || '_self',
				text: item.text,
				iconProps: item.icon && {
					iconName: item.icon,
				},
			}));

		if (
			this.widget.getName ||
			this.widget.extraHeaderHtml ||
			this.widget.getDateOfWidget ||
			this.widget.getDateRangeOfWidget
		) {
			this._dataSubscription = this.data$.subscribe(data => {
				if (this.widget.getName) {
					this.name = this.widget.getName(data, this.params);
				}

				if (typeof this.widget.rangeInDays === 'function') {
					this.rangeInDays = this.widget.rangeInDays(data, this.params);
				}

				if (this.widget.extraHeaderHtml) {
					this.extraHtml = this.sanitizer.sanitize(
						SecurityContext.HTML,
						this.widget.extraHeaderHtml(data, this.params)
					);
				}

				if (this.widget.getDateOfWidget) {
					const currentDate = this.widget.getDateOfWidget(data);
					this.dateOfWidget = currentDate && currentDate.toDateString();
				}

				if (this.widget.getDateRangeOfWidget) {
					const dateRange = this.widget.getDateRangeOfWidget(data);
					this.dateRangeOfWidget = dateRange && this.getDateRangeString(dateRange[0], dateRange[1]);
				}

				this._changeDetectionRef.markForCheck();
			});
		}
	}

	ngOnDestroy() {
		if (this._dataSubscription) {
			this._dataSubscription.unsubscribe();
		}
	}

	getMenuItemHref(menuItem: ReportWidgetMenuItem): string | null {
		if (menuItem.getUrl) {
			return `${this.ApiBaseUrl}/${menuItem.getUrl(this.paramsString)}`;
		}

		if (menuItem.url) {
			return menuItem.url;
		}

		return null;
	}

	private getDateRangeString(from: Date, to: Date) {
		return `${from.toDateString()} - ${to.toDateString()}`;
	}
}
