var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, Injector, OnDestroy, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { BehaviorSubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { PreferencesService } from '@wcd/config';
import { isCustomTimeRangeValue, SpecificTimeRangeValue, TimeRangeValue, } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { DataviewActionTypes, } from '../../../dataviews/components/actions-components/dataview-actions.model';
import { FabricIconNames } from '@wcd/scc-common';
var defaultDataViewConfig = {};
var getTimeRangePreferenceId = function (entity) {
    if (!entity.pluralName) {
        throw new Error("Searchable entity with a time range must have a 'pluralName' (Entity: " + entity.name + ").");
    }
    return "saved_" + entity.pluralName + "_time_range_";
};
var ɵ0 = getTimeRangePreferenceId;
var EntityDataviewComponent = /** @class */ (function () {
    function EntityDataviewComponent(injector, paris, preferencesService, globalEntityTypesService, router, route, timeRangesService, i18nService) {
        this.injector = injector;
        this.paris = paris;
        this.preferencesService = preferencesService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.router = router;
        this.route = route;
        this.timeRangesService = timeRangesService;
        this.i18nService = i18nService;
        // a label to be read by the narrator
        this.label = '';
        this.removePadding = false;
        this.removePaddingRight = false;
        this.queueHeader = false;
        this.padLeft = true;
        this.entitySidePanelSettings = {};
        this.fixedOptionsChanged = new EventEmitter();
        this.dataChanged = new EventEmitter();
        this.newItemAdded = new EventEmitter();
        this.groupExpanded = new EventEmitter();
        this.onTableRenderComplete = new EventEmitter();
        this.commandBarRight = [];
        this.wasTableInit = false;
        this.isFirstElementBeenFocused = false;
        this.isFirstInitDone = false;
        this.subscriptions = [];
        this.rangeInRoute = false;
    }
    EntityDataviewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.subscriptions.push(this.route.queryParamMap
            .pipe(map(function (queryParams) { return queryParams.get('range'); }), filter(Boolean), // Make sure that a range was given in the URL
        map(Number), // parse the param
        map(function (rangeValue) {
            return _this.timeRangesService.all.find(function (range) { return !isCustomTimeRangeValue(range) && range.value === rangeValue; });
        }), filter(Boolean), // Guard against unknown ranges
        tap(function () {
            _this.rangeInRoute = true;
        }), filter(function (newRange) { return newRange.id !== _this.currentRange.id; }))
            .subscribe(function (range) {
            _this.currentRange = range;
            _this.setFixedOptions();
            _this.currentTimeRange.next(_this.currentRange);
        }));
        if (this.responsiveActionBar && this.options.dateRangeOptions) {
            this.commandBarRight = [
                {
                    onSelectionChanged: this.onRangeTypeSelect.bind(this),
                    currentSelection: this.currentTimeRange,
                    selections: this.options.dateRangeOptions.supportedRanges.slice(),
                    icon: FabricIconNames.Calendar,
                    ariaLabel: this.i18nService.get('dataview.timePeriod'),
                    dataTrackId: 'timeRange_IncidentsDataview',
                    listId: 'timeRangeSelector',
                    focus: this.afterFirstElementFocused.bind(this),
                    focusOnInit: !this.isFirstInitDone,
                    propertyIdForLabel: 'name',
                    actionType: DataviewActionTypes.FancySelect,
                },
            ];
        }
    };
    EntityDataviewComponent.prototype.ngOnChanges = function (changes) {
        // Check that we have both needed values to render
        if (!this.entityType || !this.options) {
            return;
        }
        var currentEntityType = changes.entityType ? changes.entityType.currentValue : this.entityType;
        var currentOptions = changes.options ? changes.options.currentValue : this.options;
        this.timeRangePreferenceId = getTimeRangePreferenceId(currentEntityType);
        this.fields = Array.isArray(currentOptions.fields)
            ? currentOptions.fields
            : this.injector.get(currentOptions.fields).fields;
        this.dataViewEntityType = this.globalEntityTypesService.getEntityType(currentEntityType);
        this.repository = this.paris.getRepository(currentEntityType);
        if (currentOptions.dateRangeOptions && !this.rangeInRoute) {
            var _a = currentOptions.dateRangeOptions, defaultTimeRangeId_1 = _a.defaultTimeRangeId, supportedRanges = _a.supportedRanges, restoreSavedRange = _a.restoreSavedRange;
            var savedTimeRangePreference_1 = this.preferencesService.getPreference(this.timeRangePreferenceId);
            this.currentRange =
                restoreSavedRange && savedTimeRangePreference_1
                    ? supportedRanges.find(function (range) { return range.id === savedTimeRangePreference_1; })
                    : supportedRanges.find(function (range) { return range.id === defaultTimeRangeId_1 || range.isDefault; });
            if (this.currentTimeRange)
                this.currentTimeRange.next(this.currentRange);
            else
                this.currentTimeRange = new BehaviorSubject(this.currentRange);
        }
        this.setFixedOptions();
        this.setDataViewConfig();
        var previousOptions = changes.options.previousValue;
        if (!changes.options.isFirstChange() &&
            previousOptions !== currentOptions &&
            previousOptions.lifecycleHooks &&
            previousOptions.lifecycleHooks.ngOnDestroy) {
            previousOptions.lifecycleHooks.ngOnDestroy();
        }
    };
    EntityDataviewComponent.prototype.ngOnDestroy = function () {
        if (this.options.lifecycleHooks && this.options.lifecycleHooks.ngOnDestroy) {
            this.options.lifecycleHooks.ngOnDestroy();
        }
        if (this.subscriptions) {
            this.subscriptions.forEach(function (subscription) { return subscription.unsubscribe(); });
        }
    };
    EntityDataviewComponent.prototype.afterTableInit = function (loading) {
        this.wasTableInit = loading;
    };
    EntityDataviewComponent.prototype.onDataTableRenderComplete = function (loading) {
        this.onTableRenderComplete.emit(loading);
    };
    EntityDataviewComponent.prototype.afterFirstElementFocused = function () {
        this.isFirstElementBeenFocused = true;
        this.checkIfFirstInitDone();
    };
    /**
     *  focusing on the first interactive element
     *  Fixing bug: 27220333 (https://dev.azure.com/microsoft/OS/_workitems/edit/27220333/)
     */
    EntityDataviewComponent.prototype.checkIfFirstInitDone = function () {
        if (this.isFirstElementBeenFocused && this.wasTableInit && !this.isFirstInitDone) {
            this.isFirstInitDone = true;
        }
    };
    EntityDataviewComponent.prototype.onRangeTypeSelect = function (newRangeType) {
        if (newRangeType)
            this.currentRange = newRangeType;
        this.currentTimeRange.next(this.currentRange);
        this.preferencesService.setPreference(this.timeRangePreferenceId, this.currentRange.id);
        this.setFixedOptions();
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: Object.assign({
                range: this.currentRange.value,
            }, this.options.dataViewConfig.allowPaging && { pageIndex: null }),
            queryParamsHandling: 'merge',
        });
    };
    EntityDataviewComponent.prototype.setFixedOptions = function (options) {
        var fixedOptions = (options && options.fixedOptions) || this.options.fixedOptions;
        this.fixedOptions = fixedOptions && fixedOptions(this.currentRange);
        this.fixedOptionsChanged.emit(this.fixedOptions);
    };
    EntityDataviewComponent.prototype.setDataViewConfig = function () {
        var _this = this;
        this.dataViewConfig =
            __assign({}, this.options.dataViewConfig, { exportResults: this.options.exportOptions &&
                    (function (options, format, dataQuery) {
                        return _this.options.exportOptions.exportResults(options, _this.fixedOptions, null, dataQuery);
                    }), showModalOnExport: this.options.exportOptions && this.options.exportOptions.showModalOnExport, disabledVisibleFieldIds: this.options.disabledFields || this.options.dataViewConfig.disabledVisibleFieldIds }) || defaultDataViewConfig;
    };
    return EntityDataviewComponent;
}());
export { EntityDataviewComponent };
export { ɵ0 };
