import { Machine } from '../../machine/machine.entity';
import { LegacyUser } from '../..';

export enum IncidentGraphNodeEntityTypeId {
	Machine = 'Machine',
	File = 'File',
	NetworkEndpoint = 'NetworkEndpoint',
	Email = 'Email',
	Registry = 'Registry',
	User = 'User',
	Ip = 'Ip',
	Url = 'Url',
	Mailbox = 'Mailbox',
	MailCluster = 'MailCluster',
}

export const incidentGraphNodeEntityTypeValues = [
	{
		id: IncidentGraphNodeEntityTypeId.Machine,
		name: 'Device',
		icon: '\uE770',
		iconName: 'System',
		dataEntityType: Machine,
	},
	{
		id: IncidentGraphNodeEntityTypeId.File,
		name: 'File',
		iconName: 'file',
		icon: '\uE7C3',
	},
	{
		id: IncidentGraphNodeEntityTypeId.NetworkEndpoint,
		name: 'Network endpoint',
		iconName: 'entities.url',
		icon: '\uE774',
	},
	{
		id: IncidentGraphNodeEntityTypeId.Email,
		name: 'Email',
		iconName: 'email',
		icon: '\uE715',
	},
	{
		id: IncidentGraphNodeEntityTypeId.Registry,
		name: 'Registry',
		iconName: 'entities.registry',
		icon: '\uE74C',
	},
	{
		id: IncidentGraphNodeEntityTypeId.User,
		name: 'User',
		iconName: 'users.user',
		icon: '\uE77B',
		dataEntityType: LegacyUser,
	},
	{
		id: IncidentGraphNodeEntityTypeId.Ip,
		name: 'Ip address',
		iconName: 'entities.url',
		icon: '\uE774',
	},
	{
		id: IncidentGraphNodeEntityTypeId.Url,
		name: 'Url',
		iconName: 'entities.url',
		icon: '\uE774',
	},
	{
		id: IncidentGraphNodeEntityTypeId.Mailbox,
		name: 'Mailbox',
		iconName: 'entities.mailbox',
		icon: '\uF41C',
	},
	{
		id: IncidentGraphNodeEntityTypeId.MailCluster,
		name: 'Mail cluster',
		iconName: 'entities.email_cluster',
		icon: '\uF67A',
	},
];
