import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { TvmOrgSettingsSharedEntityConfigurations } from '../../../orgSettingsEndPointUtils';
import { ProfileFiltersFields } from './profile-filters-fields.value-object';

@ApiCall({
	...TvmOrgSettingsSharedEntityConfigurations,
    name: 'Get all available filters fields',
    endpoint: 'baseline/profiles/filters/',
    type: ProfileFiltersFields,
})
export class ProfileFiltersFieldsApiCall extends ApiCallModel<ProfileFiltersFields[]> { }
