<dl [class.key-values]="asKeyValueList">
	<dt>{{ i18nService.strings.investigations_fields_investigationId }}</dt>
	<dd>
		<entity-name
			[entity]="investigation"
			[entityName]="shortInvestigationId"
			[entityTypeId]="'investigation'"
			[trackId]="'InvestigationNameLink'"
			[iconName]="'investigationOutline'"
			[iconClass]="'small-icon'"
		></entity-name>
	</dd>
	<dt>{{ i18nService.strings.investigations_fields_investigationStatus }}</dt>
	<dd>
		<wcd-shared-icon
			[iconName]="investigation.status.iconName"
			[ngClass]="'color-text-' + investigation.status.className"
			class="small-icon"
		>
		</wcd-shared-icon>
		{{ investigation.isPending ? investigation.pendingType.name : investigation.status.name }}
		<wcd-help
			[text]="
				(investigation.status.isPending && investigation.pendingType
					? 'investigations.pendingTypes.' + investigation.pendingType.type
					: 'investigations.status.' + investigation.status.type) + '.description' | i18n
			"
			[title]="
				(investigation.status.isPending && investigation.pendingType
					? 'investigations.pendingTypes.' + investigation.pendingType.type
					: 'investigations.status.' + investigation.status.type) + '.title' | i18n
			"
		></wcd-help>
	</dd>
	<!--<ng-container *ngIf="investigation.tags?.length">-->
	<!--<dt>Tags</dt>-->
	<!--<dd>-->
	<!--<span *ngFor="let tag of investigation.tags" class="tag wcd-margin-small-right">{{tag.name}}</span>-->
	<!--</dd>-->
	<!--</ng-container>-->
	<dt>{{ i18nService.strings.investigations_fields_duration }}</dt>
	<dd *ngIf="investigation.isRunning">
		<stopwatch
			[start]="investigation.startDate"
			[end]="investigation.endDate"
			[prettify]="false"
			[zeroText]="'00:00:00'"
		></stopwatch>
	</dd>
	<dd *ngIf="!investigation.isRunning">{{ investigation.duration | prettyTime: true }}</dd>
	<dt>{{ i18nService.strings.investigation_graph_started }}</dt>
	<dd>{{ investigation.startDate | date: 'medium' }}</dd>
	<ng-container *ngIf="investigation.endDate">
		<dt>{{ i18nService.strings.investigation_graph_ended }}</dt>
		<dd>{{ investigation.endDate | date: 'medium' }}</dd>
	</ng-container>
	<ng-container *ngIf="totalPendingTime">
		<dt>{{ i18nService.strings.investigation_panel_pendingTime }}</dt>
		<dd>{{ totalPendingTime | prettyTime }}</dd>
	</ng-container>
</dl>
