var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { format } from 'd3-format';
import { find, merge } from 'lodash-es';
import { map } from 'rxjs/operators';
import { StackedBarsChartComponent } from '@wcd/charts';
import { config } from '@wcd/shared';
import { ServiceUrlsService } from '@wcd/app-config';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { LocaleConfigService, TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import * as d3 from 'd3';
var WIDGET_RANGE = 30;
var DailyMachineReportingWidget = /** @class */ (function (_super) {
    __extends(DailyMachineReportingWidget, _super);
    function DailyMachineReportingWidget(reportsService, serviceUrlsService, prettyNumberService, tzDateService, localeConfigService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        _this.prettyNumberService = prettyNumberService;
        _this.tzDateService = tzDateService;
        _this.localeConfigService = localeConfigService;
        _this.i18nService = i18nService;
        _this.widgetData$ = _this.data$.pipe(map(function (data) { return data && data.widgetData; }));
        _this.settings = {
            stackGroups: false,
            useValues: false,
            setColors: true,
            series: [{ value: 'Count', name: 'Machines', color: config.color.secondary }],
            xProperty: 'label',
            options: {
                padding: {
                    right: 0,
                    top: 10,
                },
                data: {},
                bar: {
                    width: {
                        max: 35,
                        ratio: 0.7,
                    },
                },
                axis: {
                    x: {
                        tick: {
                            format: function (date) { return _this.tzDateService.format(date, 'MMM d'); },
                        },
                        type: 'timeseries',
                        height: 35,
                    },
                    y: {
                        tick: {
                            format: function (value) { return _this.prettyNumberService.prettyNumber(value); },
                            count: 2,
                        },
                        min: 0,
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                onrendered: function () {
                    var self = _this;
                    var machineChart = _this.machineChart.getEl();
                    d3.select(machineChart)
                        .attr('role', 'document')
                        .attr('aria-label', self.i18nService.get('widget_title_dailyDevices'));
                    d3.selectAll(machineChart.querySelectorAll("path.c3-bar"))
                        .attr('tabindex', '0')
                        .attr('focusable', 'true')
                        .attr('role', 'img')
                        .each(function (dataPoint, index, nodesList) {
                        d3.select(nodesList[index]).attr('aria-label', self.tzDateService.format(dataPoint.x, 'MMM d') + ": " + dataPoint.value);
                    });
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                tooltip: {
                    format: {
                        value: function (n) { return format(',')(parseInt(n, 10)); },
                        title: function (date) { return _this.tzDateService.format(date, 'shortDate'); },
                    },
                },
                legend: {
                    show: false,
                },
                size: {
                    height: 150,
                },
            },
        };
        return _this;
    }
    Object.defineProperty(DailyMachineReportingWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'dailyMachineReporting',
                name: this.i18nService.get('widget_title_dailyDevices'),
                rangeInDays: WIDGET_RANGE,
                api: [
                    {
                        url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/ReportingMachinesCounts"; },
                        isExternal: true,
                        params: { readFromCache: String(true) },
                    },
                    {
                        url: function () { return _this.serviceUrlsService.threatIntel + "/Dashboard/TotalMachinesCount"; },
                        isExternal: true,
                        params: { lookingBackInDays: WIDGET_RANGE, readFromCache: String(true) },
                    },
                ],
                getName: function (data) {
                    var count = (data && data.titleData && data.titleData.machinesCount) || 0;
                    return (_this.i18nService.get('widget_title_dailyDevices') +
                        (count
                            ? " " + _this.i18nService.get('widget_title_monthlyUniqueDevices', {
                                count: data.titleData.machinesCount,
                            })
                            : ''));
                },
                parseData: this.parseData.bind(this),
            };
        },
        enumerable: true,
        configurable: true
    });
    DailyMachineReportingWidget.prototype.parseData = function (data) {
        var titleData = (find(data, function (d) { return d['machinesCount']; }));
        var widgetData = (find(data, function (d) { return d['machinesCounts']; }));
        var now = new Date();
        var rangeStartDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0);
        var toDate = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
        rangeStartDate.setDate(now.getDate() -
            ((widgetData && widgetData.machinesCounts && widgetData.machinesCounts.length) ||
                WIDGET_RANGE));
        return {
            titleData: titleData,
            widgetData: ReportsService.padTimeSeriesDates(ReportsService.parseTimeSeriesData(widgetData && widgetData.machinesCounts
                ? widgetData.machinesCounts.map(function (item) {
                    return merge({ from: item.DateTimeUtc.split('T')[0] }, item);
                })
                : []), rangeStartDate, toDate, 'Count'),
        };
    };
    return DailyMachineReportingWidget;
}(ReportWidgetComponent));
export { DailyMachineReportingWidget };
