<form class="small-form tags-edit-form" name="editTagsForm" #hostForm="ngForm">
	<div class="card-contents wcd-flex-vertical"
		 style="height: 200px"
		 [attr.id]="dialogId"
		 [attr.aria-hidden]="!tagEditDialogOpen"
>
		<div class="wcd-margin-bottom wcd-flex-none" *ngIf="!readonly">
			<wcd-search (select)="onTagAddClick($event)"
					class="wcd-full-width"
					[settings]="settings?.searchSettings"
					[searchFunction]="boundSearchAndAddNew"
					[placeholder]="'tags.autoCompleteInstructions' | i18n"
					(escOnClosedResult)="escapeClicked()"
					[forceAlphanumeric]="true"
					[setFocus]="tagEditDialogOpen"
					[ariaLabel]="'tags.manage' | i18n"
					[enableTopPosition]="false"
					[searchboxId]="dialogId + '_searchbox'"
					[role]="'none'">
			</wcd-search>
		</div>
		<div class="wcd-flex-1 wcd-scroll-vertical">
			<tags-list [tags]="localTags"
					   [editable]="!readonly"
					   (tagRemoved)="onTagRemoved($event)"></tags-list>
		</div>
	</div>
</form>
