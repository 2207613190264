import { ChangeDetectorRef, OnInit, } from '@angular/core';
import { Subject } from 'rxjs';
import { DropdownComponent } from '@wcd/forms';
import { HuntingQueryListService } from '../../queries/services/hunting-query-list.service';
var SaveQueryContentComponent = /** @class */ (function () {
    function SaveQueryContentComponent(changeDetectorRef, huntingQueryListService) {
        this.changeDetectorRef = changeDetectorRef;
        this.huntingQueryListService = huntingQueryListService;
        this.isValid$ = new Subject();
        this.isSaving = false;
    }
    Object.defineProperty(SaveQueryContentComponent.prototype, "errorMessage", {
        get: function () {
            return this._errorMessage;
        },
        set: function (value) {
            this._errorMessage = value;
            this.changeDetectorRef.markForCheck();
        },
        enumerable: true,
        configurable: true
    });
    SaveQueryContentComponent.prototype.ngOnInit = function () {
        var selectedQueryListKind = this.query.kind === 'shared' ? 'shared' : 'user';
        this.selection = {
            folderName: this.huntingQueryListService.displayNames[selectedQueryListKind],
            kind: selectedQueryListKind,
        };
    };
    SaveQueryContentComponent.prototype.onFolderSelected = function (queryKind, _a) {
        var name = _a.name, path = _a.path;
        this.dropdown.close();
        this.selection = {
            kind: queryKind,
            folderName: name,
            folderPath: path,
        };
        this.changeDetectorRef.markForCheck();
    };
    return SaveQueryContentComponent;
}());
export { SaveQueryContentComponent };
