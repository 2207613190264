import { Component, ComponentRef } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { DataExportSettingsEntity, LogProperties } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { PanelType } from '@wcd/panels';
import { Observable } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { DataExportNewModalComponent } from './data-export-new.component';
import { AuthService } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';

const MAX_ITEMS = 5;
const NO_DATA: string = '';

@Component({
	selector: 'data-export-dataview',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div class="page-header wcd-flex-none queue-layout-header"
				  [class.wcd-padding-none-horizontal-important]="isScc">
				<h2>{{ 'endpointManagement.nonWindows.applications.dataExport.name' | i18n }}</h2>
			</div>
			<div
				*ngIf="capacityString"
				class="wcd-padding-medium-bottom"
				[class.wcd-padding-large-left]="!isScc">
				<span
					>{{ 'endpointManagement.nonWindows.applications.dataExport.availableCapacity' | i18n }}
				</span>
				<span class="wcd-margin-xsmall-left">
					{{ capacityString }}
				</span>
			</div>
			<dataview
				class="wcd-flex-1"
				[entityType]="globalEntityType"
				[allowFilters]="false"
				[fields]="fields"
				[refreshOn]="lastUpdate"
				id="dataviewexport"
				[allowPaging]="false"
				emptyDataMarkdown="No data export settings found.\n[Learn more](https://go.microsoft.com/fwlink/?linkid=2095611) about how to forward Microsoft Defender for Endpoint events to Azure storage or Event-Hub."
				[repository]="dataExportRepository"
				[disableAddButton]="disableAddButton"
				allowMultipleSelection="false"
				[disabledAddTooltip]="disabledAddTooltip"
				[allowAdd]="true"
				[itemName]="'endpointManagement.nonWindows.applications.dataExport.name' | i18n"
				(onNewItem)="showDataExportNewDialog()"
				[queueHeader]="true"
				[assetCommandBar]="true"
				[padLeft]="false"
				[responsiveActionBar]="true"
				[removePadding]="isScc"
			>
			</dataview>
		</div>
	`,
})
export class DataExportDataviewComponent {
	readonly fields: Array<DataviewField>;

	dataExportRepository: Repository<DataExportSettingsEntity>;
	globalEntityType = this.globalEntityTypesService.getEntityType(DataExportSettingsEntity);
	dataViewConfig: DataViewConfig;
	disableAddButton: boolean;
	disabledAddTooltip: string;
	lastUpdate: Date;
	isScc = sccHostService.isSCC;
	capacityString: string;

	entityType: EntityType<DataExportSettingsEntity> = this.globalEntityTypesService.getEntityType(
		DataExportSettingsEntity
	);

	constructor(
		private paris: Paris,
		private globalEntityTypesService: GlobalEntityTypesService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private authService: AuthService,
	) {
		this.dataExportRepository = this.paris.getRepository(DataExportSettingsEntity);

		this.dataExportRepository.allItems$.subscribe(items => {
			if (!this.authService.isGlobalAdmin) {
				this.disableAddButton = true;
				this.disabledAddTooltip = this.i18nService.get(
					'endpointManagement.nonWindows.applications.dataExport.dataView.noPermissionsTooltip'
				);
			} else if (items.length >= MAX_ITEMS) {
				this.disableAddButton = true;
				this.disabledAddTooltip = this.i18nService.get(
					'endpointManagement.nonWindows.applications.dataExport.dataView.itemsLimit'
				);
			}

			this.capacityString = `${MAX_ITEMS - items.length}/${MAX_ITEMS}`;
		});

		this.fields = this.createFields();
	}

	showDataExportNewDialog() {
		this.dialogsService
			.showPanel(DataExportNewModalComponent, {
				id: 'new-data-export',
				type: PanelType.large,
				noBodyPadding: true,
				headerText: this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.addNew.title')
			})
			.subscribe((panel: ComponentRef<DataExportNewModalComponent>) => {
				panel.instance.save.subscribe(() => {
					this.lastUpdate = new Date();
				});
			});
	}

	private createFields(): Array<DataviewField> {
		const columnNameI18Prefix = 'endpointManagement.nonWindows.applications.dataExport.dataView.columns.';

		return [
			{
				id: 'id',
				getDisplay: item => item.id,
			},
			{
				id: 'eventHubResourceGroup',
				getDisplay: item =>
					item.eventHubProperties == null ? NO_DATA : item.eventHubProperties.resourceGroup,
			},
			{
				id: 'eventhub',
				getDisplay: item =>
					item.eventHubProperties == null
						? NO_DATA
						: `${item.eventHubProperties.namespace}/${item.eventHubProperties.name}`,
			},
			{
				id: 'storageResourceGroup',
				getDisplay: item =>
					item.storageAccountProperties == null
						? NO_DATA
						: item.storageAccountProperties.resourceGroup,
			},
			{
				id: 'storageAccount',
				getDisplay: item =>
					item.storageAccountProperties == null ? NO_DATA : item.storageAccountProperties.name,
			},
			{
				id: 'eventTypes',
				maxWidth: 100,
				getDisplay: item => this.getLogCategoriesString(item.logs),
			},
		].map(
			props =>
				new DataviewField({ ...props, name: this.i18nService.get(columnNameI18Prefix + props.id) })
		);
	}

	private getLogCategoriesString(logs: Array<LogProperties>): string {
		if (logs.length === 1) {
			return this.i18nService.get(
				`endpointManagement.nonWindows.applications.dataExport.logTypes.${logs[0].category}`
			);
		}

		const eventTypes: string = this.i18nService
			.get('endpointManagement.nonWindows.applications.dataExport.dataView.columns.eventTypes')
			.toLowerCase();

		return `${logs.filter(log => !!log.enabled).length} ${eventTypes}`;
	}
}
