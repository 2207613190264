var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Injector } from '@angular/core';
import { AntivirusRemediationAction } from '@wcd/domain';
var AntivirusBlockEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(AntivirusBlockEntityDetailsComponent, _super);
    function AntivirusBlockEntityDetailsComponent(injector) {
        return _super.call(this, injector) || this;
    }
    Object.defineProperty(AntivirusBlockEntityDetailsComponent.prototype, "block", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AntivirusBlockEntityDetailsComponent.prototype, "RemediationAction", {
        get: function () {
            return AntivirusRemediationAction[this.entity.remediationAction];
        },
        enumerable: true,
        configurable: true
    });
    return AntivirusBlockEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { AntivirusBlockEntityDetailsComponent };
