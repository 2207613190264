import { Injectable } from '@angular/core';
import {
	AirsEntityHuntingQueryApiCall,
	AirsEntityHuntingQueryRequest,
	AirsEntityType,
	GoHuntQueryResponse,
} from '@wcd/domain';
import { EMPTY, Observable } from 'rxjs';
import { ItemActionModelConfig } from '../../dataviews/models/item-action.model';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { catchError, map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { HUNTING_ROUTE } from '@wcd/shared';
import { TimeRangeId } from '@wcd/date-time-picker';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { HuntingComponentQueryParams } from '@wcd/hunting';
import {
	DEVICE_ENTITY_TYPE_GO_HUNT_ID,
	EntityOrEvidenceType,
	GoHuntActionOptions,
	GoHuntQueryBuilder,
} from './go-hunt-query-builder';

@Injectable()
export class GoHuntService {
	queryBuilder: GoHuntQueryBuilder;

	constructor(
		private featuresService: FeaturesService,
		private readonly router: Router,
		private appContextService: AppContextService,
		private readonly paris: Paris,
		private readonly appInsightService: AppInsightsService
	) {
		this.queryBuilder = new GoHuntQueryBuilder();
	}

	getGoHuntActionObservable(
		entity: EntityOrEvidenceType,
		evidenceTypeId: AirsEntityType,
		options?: GoHuntActionOptions
	): Observable<ItemActionModelConfig> {
		if (
			!this.featuresService.isAnyEnabled([
				Feature.EvidenceGoHuntActionMtp,
				Feature.EvidenceGoHuntAction,
			])
		) {
			return EMPTY;
		}

		const huntingQueryRequest = this.queryBuilder.getAirsEntityHuntingRequest(
			entity,
			evidenceTypeId,
			options
		);

		const entityTypeTrackId =
			evidenceTypeId == DEVICE_ENTITY_TYPE_GO_HUNT_ID
				? 'Device'
				: AirsEntityType[evidenceTypeId];

		const shouldCallVNextApi = this.featuresService.isEnabled(Feature.HuntingMigrateGoHuntFE);

		return !huntingQueryRequest
			? EMPTY
			: this.paris
					.apiCall<GoHuntQueryResponse, AirsEntityHuntingQueryRequest>(
						AirsEntityHuntingQueryApiCall,
						{...huntingQueryRequest, migrateToVNext: shouldCallVNextApi}
					)
					.pipe(
						map((evidenceGoHuntResponse: GoHuntQueryResponse) => {

							if (!evidenceGoHuntResponse || (!evidenceGoHuntResponse.encodedHuntingQuery && !evidenceGoHuntResponse.allActivity)) {
								this.trackGoHunt(entityTypeTrackId, false);
								return null;
							}

							this.trackGoHunt(entityTypeTrackId, true);

							const queryText = evidenceGoHuntResponse.encodedHuntingQuery === undefined ?
								evidenceGoHuntResponse.allActivity :
								evidenceGoHuntResponse.encodedHuntingQuery;

							const queryParams: HuntingComponentQueryParams = {
								timeRangeId: TimeRangeId.month,
								query: queryText,
								runQuery: true,
								queryResultsTrackId: `GoHuntQueryResults_${entityTypeTrackId}`,
							};
							const tid = this.getTenantIdUrlParam();
							if (tid != null && tid != '') {
								queryParams['tid'] = tid;
							}
							return {
								id: 'evidenceGoHunt',
								nameKey: 'entities_panel_action_goHunt_buttonTitle',
								icon: 'hunting',
								href: this.router.serializeUrl(
									this.router.createUrlTree([`/${HUNTING_ROUTE}`], { queryParams })
								),
								openLinkInNewTab: true,
							};
						}),
						catchError((err) => {
							this.appInsightService.trackException(err, 'evidenceGoHunt');
							return EMPTY;
						})
					);
	}

	private trackGoHunt(evidenceTypeTrackId: string, succeededCreatingGoHuntQuery: boolean) {
		this.appInsightService.trackEvent('GoHuntUsage', {
			EntityType: evidenceTypeTrackId,
			BESucceededCreatingGoHuntQuery: succeededCreatingGoHuntQuery,
		});
	}
	private getTenantIdUrlParam() : string  {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('tid');
	};
}
