import { HuntingQueryBase } from './hunting-query.entity.base';
import { Entity } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { QueryKind } from './hunting-query.type';

/**
 * @deprecated use HuntingSavedQuery instead
 */
@Entity({
	singularName: "Detection Rule's query",
	pluralName: "Detection Rule's queries",
	endpoint: 'hunting/queries',
	allItemsEndpoint: '/scheduled',
	saveMethod: (item: any) => (item.id ? 'PATCH' : 'POST'),
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class HuntingScheduledQuery extends HuntingQueryBase {
	readonly kind: QueryKind = 'scheduled';
	readonly shared = false;
	readonly isScheduled = true;
}
