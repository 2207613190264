var _a;
import { isNil } from 'lodash-es';
import { airsEntityStatusValues } from '../../airs-entity-status.entity';
import { AirsEntityType, AirsEntityTypeValue } from '../../airs-entity-type.entity';
import { VerdictStatusType } from '../../airs-entity-status-types.enum';
import { getThreatsNamesFromThreatIntelligence, getLastRemediationProvider, getRemediationStateId, getThreatsNames, } from '../../../mtp-investigation/mtp-investigation.entity';
import { InvestigationActionStatusType } from '../../../investigation/actions/investigation-action-status.values';
import { mapOfficeActionToRemediationAction } from '../../../investigation/actions/office-action.utils';
import { mapEmailClusterEntityToAirsEntity } from './airs-entity.email-cluster.converter';
import { mapMailMessageEntityToAirsEntity } from './airs-entity.mail-message.converter';
import { mapMailboxEntityToAirsEntity } from './airs-entity.mailbox.converter';
import { mapMailboxConfigurationEntityToAirsEntity } from './airs-entity.mailbox-configuration.converter';
import { mapSubmissionMailEntityToAirsEntity } from './airs-entity.submission-mail.converter';
import { mapUrlEntityToAirsEntity } from './airs-entity.url.converter';
import { mapIpEntityToAirsEntity } from './airs-entity.ip.converter';
import { mapFileEntityToAirsEntity } from './airs-entity.file.converter';
import { mapProcessEntityToAirsEntity } from './airs-entity.process.converter';
import { mapSecurityGroupEntityToAirsEntity } from './airs-entity.security-group.converter';
import { mapRegistryKeyEntityToAirsEntity } from './airs-entity.registry-key.converter';
import { mapRegistryValueEntityToAirsEntity } from './airs-entity.registry-value.converter';
import { mapCloudApplicationEntityToAirsEntity } from './airs-entity.cloud-application.converter';
import { mapCloudLogonSessionEntityToAirsEntity } from './airs-entity.cloud-logon-session.converter';
import { mapOauthApplicationEntityToAirsEntity } from './airs-entity.oauth-application.converter';
import { mapActiveDirectoryDomainEntityToAirsEntity } from './airs-entity.active-directory-domain.converter';
import { EntityDisplayNameFunction } from '../entity-display-name.utils';
import { EvidenceDetectionSourceTypeEnum } from '../../../evidence/evidence-detection-source-type.enum';
import { AlertV3EntityTypes } from '../alertV3-entity-types.enum';
import { EvidenceAssetTypeType } from '../../../evidence/evidence-asset-type.entity';
var ENTITY_TYPE_PROPS = (_a = {},
    _a[AlertV3EntityTypes.MailCluster] = {
        id: AirsEntityType.MailCluster,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailCluster],
        entityMapper: mapEmailClusterEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.MailMessage] = {
        id: AirsEntityType.MailMessage,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailMessage],
        entityMapper: mapMailMessageEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.SubmissionMail] = {
        id: AirsEntityType.SubmissionMail,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.SubmissionMail],
        entityMapper: mapSubmissionMailEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.Mailbox] = {
        id: AirsEntityType.Mailbox,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.Mailbox],
        entityMapper: mapMailboxEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.MailboxConfiguration] = {
        id: AirsEntityType.MailboxConfiguration,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.MailboxConfiguration],
        entityMapper: mapMailboxConfigurationEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.URL] = {
        id: AirsEntityType.URL,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.URL],
        entityMapper: mapUrlEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.IP] = {
        id: AirsEntityType.IP,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.IP],
        entityMapper: mapIpEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.File] = {
        id: AirsEntityType.File,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.File],
        entityMapper: mapFileEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.Process] = {
        id: AirsEntityType.Process,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.Process],
        entityMapper: mapProcessEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.SecurityGroup] = {
        id: AirsEntityType.SecurityGroup,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.SecurityGroup],
        entityMapper: mapSecurityGroupEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.RegistryKey] = {
        id: AirsEntityType.RegistryKey,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.RegistryKey],
        entityMapper: mapRegistryKeyEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.RegistryValue] = {
        id: AirsEntityType.RegistryValue,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.RegistryValue],
        entityMapper: mapRegistryValueEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.CloudApplication] = {
        id: AirsEntityType.CloudApplication,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.CloudApplication],
        entityMapper: mapCloudApplicationEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.CloudLogonSession] = {
        id: AirsEntityType.CloudLogonSession,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.CloudLogonSession],
        entityMapper: mapCloudLogonSessionEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.OauthApplication] = {
        id: AirsEntityType.OauthApplication,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.OauthApplication],
        entityMapper: mapOauthApplicationEntityToAirsEntity,
    },
    _a[AlertV3EntityTypes.ActiveDirectoryDomain] = {
        id: AirsEntityType.ActiveDirectoryDomain,
        displayNameFunc: EntityDisplayNameFunction[AirsEntityTypeValue.ActiveDirectoryDomain],
        entityMapper: mapActiveDirectoryDomainEntityToAirsEntity,
    },
    _a);
export function getBaseAirsEntity(entityData, entityTypeId) {
    var res = Object.entries(entityData).reduce(function (acc, _a) {
        var _b;
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "ThreatIntelligence" /* ThreatIntelligence */:
                    acc['threats'] = getThreatsNamesFromThreatIntelligence(val);
                    break;
                case "ThreatAnalysisSummary" /* ThreatAnalysisSummary */:
                    if (acc['threats'])
                        break;
                    var threats = getThreatsNames(val);
                    acc['threats'] = threats.length ? threats.join(', ') : null;
                    break;
                case "CreatedTimeUtc" /* CreatedTimeUtc */:
                    acc['created_on'] = val;
                    break;
                case "LastUpdatedTimeUtc" /* LastUpdatedTimeUtc */:
                    acc['updated_on'] = val;
                    break;
                case "FirstEvidenceTime" /* FirstEvidenceTime */:
                case "FirstSeen" /* FirstSeen */:
                    acc['first_seen'] = val;
                    break;
                case "Verdict" /* Verdict */:
                case "LastVerdict" /* LastVerdict */:
                    var verdictType_1 = val && VerdictStatusType[val];
                    var verdict = airsEntityStatusValues.find(function (s) { return s.type === verdictType_1; });
                    acc['verdict'] = verdict != null ? verdict.id : null;
                    acc['entity_status'] = verdict != null ? verdict.id : null;
                    break;
                case "Timestamp" /* Timestamp */:
                    acc['timestamp'] = val;
                    break;
                case "MergeByKey" /* MergeByKey */:
                    acc['merge_by_key'] = val;
                    break;
                case "Urn" /* Urn */:
                    acc['urn'] = val;
                    break;
                case "LastRemediationState" /* LastRemediationState */:
                    var remediationStatusTypeId_1 = getRemediationStateId(val, {});
                    acc['remediation_status'] = remediationStatusTypeId_1;
                    break;
                case "EntityPageUrl" /* EntityPageUrl */:
                    acc['entity_page_url'] = val;
                    break;
                case "Alerts" /* Alerts */:
                    var entityDetectionSourceList = val &&
                        val.reduce(function (res, alert) {
                            res.push({
                                detection_type: EvidenceDetectionSourceTypeEnum.alert,
                                detection_source_name: alert["AlertTitle" /* AlertTitle */],
                                detection_source_id: alert["AlertId" /* AlertId */],
                                additional_data: {
                                    alert: {
                                        severity: alert["AlertSeverity" /* AlertSeverity */],
                                    },
                                },
                            });
                            return res;
                        }, []);
                    acc['entity_detection_src_list'] = entityDetectionSourceList;
                    // taking the most updated alert (Alerts array are sorted descending by 'LastUpdateTimeUtc')
                    acc['entity_detection_src'] =
                        entityDetectionSourceList &&
                            entityDetectionSourceList.length &&
                            entityDetectionSourceList[0];
                    break;
                case "Assets" /* Assets */:
                    acc['impacted_assets'] =
                        val &&
                            val.reduce(function (res, asset) {
                                var assetType = asset.AssetType;
                                var assetData = asset.Asset;
                                res.push({
                                    asset_type: assetType,
                                    asset: Object.assign({
                                        name: assetData.Name,
                                    }, assetType === EvidenceAssetTypeType.Machine
                                        ? { machine_id: assetData.MachineId }
                                        : null),
                                });
                                return res;
                            }, []);
                    break;
                case "Host" /* Host */:
                    if (val) {
                        var machineData = val;
                        acc['host'] = {
                            machine_id: machineData.MachineId,
                            name: machineData.HostName,
                            rbac_group_id: machineData.RbacGroupId,
                        };
                    }
                    break;
                case 'IsConvergedPlatform':
                    // Note: 'IsConvergedPlatform' is not part of AlertV3 schema, hence, it's ot part of the enum
                    acc['is_converged_platform'] = val;
                    break;
                default:
                    if (typeof key === 'string' && key.includes('.')) {
                        var _c = key.split('.'), k = _c[0], v = _c[1];
                        if (k in entityData) {
                            entityData[k][v] = val;
                        }
                        else {
                            entityData[k] = (_b = {}, _b[v] = val, _b);
                        }
                    }
                    else {
                        acc[key] = val;
                    }
            }
        }
        catch (e) { }
        return acc;
    }, { type_id: entityTypeId });
    /*
        In AlertV3 schema 'RemediationProviders' data is an array.
        Not only office use AlertV3 schema, hence, it should support all cases
        (for example Azure has more than 1 provider but currently not supported).
        In case of office - there will be only 1 provider, hence, we are taking the last which is the only one.
     */
    var lastRemediationProvider = getLastRemediationProvider(entityData.RemediationProviders);
    // set timestamp
    var startTimeUtc = lastRemediationProvider ? lastRemediationProvider.StartTimeUtc : null;
    if (!isNil(startTimeUtc) && !res.hasOwnProperty['timestamp']) {
        res['timestamp'] = startTimeUtc;
    }
    // set remediation status
    var remediationStatusTypeId = getRemediationStateId(entityData.LastRemediationState, lastRemediationProvider);
    if (remediationStatusTypeId) {
        res['remediation_status'] = remediationStatusTypeId;
    }
    if (lastRemediationProvider &&
        lastRemediationProvider.ActionStatus === InvestigationActionStatusType.Pending) {
        var remediationAction = mapOfficeActionToRemediationAction(lastRemediationProvider, entityData);
        res['remediation_actions'] = remediationAction ? [remediationAction] : null;
    }
    // set investigation id
    var investigationId = lastRemediationProvider && lastRemediationProvider.InvestigationId;
    res['investigation'] = investigationId ? { investigation_id: investigationId } : null;
    res['id'] = entityData.Urn || entityData.MergeByKey;
    return res;
}
export function mapAlertV3EntityToAirsEntity(entityData) {
    var entityType = entityData && entityData.Type;
    var entityTypeProps = ENTITY_TYPE_PROPS[entityType];
    var typeId = entityTypeProps && entityTypeProps.id;
    if (!typeId) {
        return;
    }
    var baseAirsEntity = getBaseAirsEntity(entityData, typeId);
    var entityMapper = entityTypeProps && entityTypeProps.entityMapper;
    var specificEntity = entityMapper && entityMapper(entityData);
    // get the calculated display_name according to the current entity type
    var entityDisplayName = entityTypeProps && entityTypeProps.displayNameFunc
        ? entityTypeProps.displayNameFunc(specificEntity)
        : '';
    var res = Object.assign(baseAirsEntity, specificEntity, { display_name: entityDisplayName });
    // keeping the '_rawData' cause we need it for the evidence dataview panels
    res['_rawData'] = res;
    return res;
}
