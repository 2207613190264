
		<ng-container *ngIf="(detailsTab$ | async) as detailsTabDisplayEntity">
			<entity-panel
				[entity]="detailsTabDisplayEntity.entity"
				[entities]="[detailsTabDisplayEntity.entity]"
				[entityType]="detailsTabDisplayEntity.entityType"
				[settings]="entityPanelSettings"
				[extendEntity]="false"
				[disableAutoFocus]="true"
				[disableTrapFocus]="true"
				[options]="{
					hideOpenLink:
						detailsTabDisplayEntity.entityTypeName == TreeElementCyberEntityTypeEnum.alert &&
						detailsTabDisplayEntity.entity.id == (mainAlertPageId$ | async)
				}"
				contentClass="alert-entity-panel-adjust-for-zoom"
			>
			</entity-panel>
		</ng-container>
	