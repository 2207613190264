import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { SecurityAnalyticsSettingsComponent } from './security-analytics-settings.component';
import { SecurityAnalyticsService } from './security-analytics-settings.service';

@NgModule({
	imports: [SharedModule],
	declarations: [SecurityAnalyticsSettingsComponent],
	exports: [SecurityAnalyticsSettingsComponent],
	providers: [SecurityAnalyticsService],
})
export class SecurityAnalyticsSettingsModule {}
