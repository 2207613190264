/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./panel-placeholder.component";
import * as i2 from "../services/panels.service";
var styles_WcdPanelPlaceholderComponent = [];
var RenderType_WcdPanelPlaceholderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WcdPanelPlaceholderComponent, data: {} });
export { RenderType_WcdPanelPlaceholderComponent as RenderType_WcdPanelPlaceholderComponent };
export function View_WcdPanelPlaceholderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { viewContainerRef: 0 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 16777216, [[1, 3], ["panelplaceholder", 1]], null, 0, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_WcdPanelPlaceholderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-panel-placeholder", [], null, null, null, View_WcdPanelPlaceholderComponent_0, RenderType_WcdPanelPlaceholderComponent)), i0.ɵdid(1, 114688, null, 0, i1.WcdPanelPlaceholderComponent, [i2.PanelService, i0.Injector, i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var WcdPanelPlaceholderComponentNgFactory = i0.ɵccf("wcd-panel-placeholder", i1.WcdPanelPlaceholderComponent, View_WcdPanelPlaceholderComponent_Host_0, {}, {}, []);
export { WcdPanelPlaceholderComponentNgFactory as WcdPanelPlaceholderComponentNgFactory };
