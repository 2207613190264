import { EntityPanelComponentBase } from './entity-panel.component.base';
import { Machine } from '@wcd/domain';
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { of, Subscription } from 'rxjs';
import { ModelBase, Paris } from '@microsoft/paris';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { switchMap } from 'rxjs/operators';

export class EntityPanelWithMachineComponent<T extends ModelBase = ModelBase>
	extends EntityPanelComponentBase<T>
	implements OnDestroy {
	machine: Machine;
	isLoadingMachine: boolean = false;

	private _machineSubscription: Subscription;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		protected paris: Paris,
		protected globalEntityTypesService: GlobalEntityTypesService,
		protected rbacService: RbacService,
		protected machinesService: MachinesService
	) {
		super(changeDetectorRef);
	}

	initEntity(entity: T, isExtendedData: boolean = false) {
		this.machine = null;
		this.isLoadingMachine = false;
		this.changeDetectorRef.markForCheck();

		super.initEntity(entity, isExtendedData);
	}

	ngOnDestroy() {
		this._machineSubscription && this._machineSubscription.unsubscribe();
	}

	setMachine(machineId: string) {
		this.machine = null;
		this.isLoadingMachine = true;
		this.changeDetectorRef.markForCheck();

		this._machineSubscription = this.rbacService
			.isUserExposedToEntity(this.globalEntityTypesService.getEntityType(Machine), machineId)
			.pipe(
				switchMap(userExposureResult => {
					if (userExposureResult.isExposed)
						return this.paris.getRepository(Machine).getItemById(machineId);
					return of(null);
				})
			)
			.subscribe(
				(machine: Machine) => {
					this.isLoadingMachine = false;
					this.machine = machine;
					this.changeDetectorRef.markForCheck();
				},
				error => {
					this.isLoadingMachine = false;
					this.changeDetectorRef.markForCheck();
				}
			);
	}

	showLoggedOnUsers() {
		this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
			noShadow: true,
			hasCloseButton: false,
		});
	}
}
