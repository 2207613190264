import { Observable, Subject } from 'rxjs';
import { NavItemModel } from '@wcd/shared';

export class MainNavService {
	navClick$: Observable<NavItemModel>;
	private _navClickSubject: Subject<NavItemModel>;

	constructor() {
		this._navClickSubject = new Subject();
		this.navClick$ = this._navClickSubject.asObservable();
	}

	notifyNavClick(navItem: NavItemModel) {
		this._navClickSubject.next(navItem);
	}
}
