var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ChangeDetectorRef, EventEmitter, } from '@angular/core';
var MessageBarComponent = /** @class */ (function () {
    function MessageBarComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.messageKey = 'polling.warning'; // message text key on i18 json. default is polling error.
        this.messageData = 'content'; // additional params for message text
        this.iconName = 'warning';
        this.className = 'color-box-warning-severe';
        this.messageRole = 'none';
        this.actionFailed = new EventEmitter();
        this._runningActions = new Set();
    }
    MessageBarComponent.prototype.runAction = function (action) {
        var _this = this;
        this.addAction(action);
        try {
            action.method().then(function () {
                _this.deleteAction(action);
            }, function (error) {
                _this.actionFailed.emit(__assign({}, action, error));
                _this.deleteAction(action);
            });
        }
        catch (e) {
            this.actionFailed.emit(__assign({}, action, e));
            this.deleteAction(action);
        }
    };
    MessageBarComponent.prototype.isRunning = function (action) {
        return this._runningActions.has(action.id);
    };
    MessageBarComponent.prototype.addAction = function (action) {
        this._runningActions.add(action.id);
        this.changeDetectorRef.markForCheck();
    };
    MessageBarComponent.prototype.deleteAction = function (action) {
        this._runningActions.delete(action.id);
        this.changeDetectorRef.markForCheck();
    };
    return MessageBarComponent;
}());
export { MessageBarComponent };
