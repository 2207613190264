import { get } from 'lodash-es';
import { DataTableField } from '../models/datatable-field.model';
import { Router } from '@angular/router';
import { AppContextService } from '@wcd/config';
var DEFAULT_ICON_CLASSNAME = 'field-value-icon';
var DataTableFieldValueComponent = /** @class */ (function () {
    function DataTableFieldValueComponent(appContext, router) {
        this.appContext = appContext;
        this.router = router;
        this.allowLink = true;
        this.shortDisplay = false;
        this.visible = false;
    }
    Object.defineProperty(DataTableFieldValueComponent.prototype, "refreshOn", {
        set: function (refreshOn) {
            this.updateValue();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataTableFieldValueComponent.prototype, "itemValue", {
        get: function () {
            return ((this.field.itemProperty && get(this.value, this.field.itemProperty)) || this.value[this.field.id]);
        },
        enumerable: true,
        configurable: true
    });
    DataTableFieldValueComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.field || changes.value) && this.field && this.value) {
            this.updateValue();
        }
    };
    DataTableFieldValueComponent.prototype.updateValue = function () {
        var _this = this;
        try {
            this.display = this.field.display(this.value);
        }
        catch (e) {
            this.isDisplayError = true;
            e.message = "Field '" + this.field.id + "' display parsing error: " + e.message;
            console.error(e);
        }
        this.setIcon();
        this.imageSrc = this.field.getImage && this.field.getImage(this.value);
        this.link = this.allowLink && this.field.getLink && this.field.getLink(this.value);
        if (this.link && this.field.getLinkQueryParams) {
            this.linkQueryParams = this.field.getLinkQueryParams(this.value);
        }
        if (this.field.listValues) {
            if (this.field.listValues.data) {
                this.listItems = this.field.listValues.data(this.value);
            }
            else if (this.field.listValues.path) {
                this.listItems = get(this.value, this.field.listValues.path);
            }
        }
        this.customFields =
            this.field.custom && this.field.custom.fields
                ? this.field.custom.fields.filter(function (field) { return !!_this.value[_this.field.id][field.id]; }) // show rows that has a value only
                : [];
        this.tooltip = (this.field.getTooltip && this.field.getTooltip(this.value)) || this.field.tooltip || this.field.display(this.value);
        this.helpText = this.field.help && this.field.help(this.value);
    };
    DataTableFieldValueComponent.prototype.setIcon = function () {
        var icon = this.field.icon;
        if (!icon)
            return;
        if (icon.fabricIcon) {
            this.fabricIconName =
                icon.fabricIcon instanceof Function ? icon.fabricIcon(this.value) : icon.fabricIcon;
        }
        if (icon.wcdIcon) {
            this.wcdIconName = icon.wcdIcon instanceof Function ? icon.wcdIcon(this.value) : icon.wcdIcon;
        }
        this.iconCssClass =
            icon.className &&
                (icon.className instanceof Function ? icon.className(this.value) : icon.className);
        if (this.iconCssClass)
            this.iconCssClass = "field-value-icon-base " + this.iconCssClass;
        else
            this.iconCssClass = DEFAULT_ICON_CLASSNAME;
    };
    DataTableFieldValueComponent.prototype.getUseCustomComponent = function (item) {
        var customComponent = this.getCustomComponent(item);
        return customComponent !== undefined;
    };
    DataTableFieldValueComponent.prototype.getCustomComponent = function (item) {
        return ((this.field.useDefaultEmptyFieldComponent &&
            this.field.getIsEmptyField(item) &&
            this.field.defaultEmptyComponent) ||
            (this.field.getDynamicComponent && this.field.getDynamicComponent(item)) ||
            this.field.component);
    };
    return DataTableFieldValueComponent;
}());
export { DataTableFieldValueComponent };
