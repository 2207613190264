import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { PanelContainer } from '@wcd/panels';
import { MachineGroup } from '@wcd/domain';
import { MachineGroupEditEnhancedComponent } from './machine-group-edit-enhanced.component';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { Observable, from, of } from 'rxjs';
import { I18nService } from '@wcd/i18n';
import { map } from 'rxjs/operators';
import { Paris, Repository } from '@microsoft/paris';

@Component({
	selector: 'machine-group-edit-enhanced-panel',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings" [canDeactivate]="boundCanDeactivate">
			<machine-group-edit-enhanced
				[machineGroup]="machineGroup"
				class="wcd-full-height"
				(saveChanges)="saveChanges($event)"
				(cancel)="cancel()"
			></machine-group-edit-enhanced>
		</wcd-panel>
	`,
})
export class MachineGroupEditEnhancedPanelComponent extends PanelContainer implements OnInit {
	@Input() onDone: Function;
	@Input() onCancel: Function;
	@Input() machineGroup: MachineGroup;

	@Output() onSaveChanges: EventEmitter<MachineGroup> = new EventEmitter<MachineGroup>();

	@ViewChild(MachineGroupEditEnhancedComponent, { static: false }) machineGroupEdit: MachineGroupEditEnhancedComponent;

	boundCanDeactivate: () => Observable<boolean>;

	machineGroupsRepo: Repository<MachineGroup>;

	constructor(
		protected router: Router,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private paris: Paris
	) {
		super(router);
		this.boundCanDeactivate = this.canDeactivate.bind(this);
		this.machineGroupsRepo = this.paris.getRepository(MachineGroup);
	}

	saveChanges(event: any) {
		this.onSaveChanges.emit(event);
	}

	canDeactivate(): Observable<boolean> {
		if (this.machineGroupEdit.isDirty) {
			return from(
				this.dialogsService.confirm({
					title: this.i18nService.get('discardChanges'),
					text: this.i18nService.get('discardMessage.singular', {
						itemName: this.machineGroupsRepo.entity.singularName.toLowerCase(),
					}),
					confirmText: this.i18nService.get('buttons.discard'),
				})
			).pipe(map((e: ConfirmEvent) => e.confirmed));
		} else {
			return of(true);
		}
	}

	cancel() {
		this.canDeactivate().subscribe((_canDeactivate: boolean) => {
			if (_canDeactivate) this.closePanel();
		});
	}

	closePanel() {
		this.startClose();
		setTimeout(() => this.destroy(), 200);
	}
}
