import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { scheduledHuntingRunStatus } from './shceduled-hunting-run-status.values';

@Entity({
	singularName: 'Scheduled Hunting Run Status',
	pluralName: 'Scheduled Hunting Run Statuses',
	values: scheduledHuntingRunStatus,
})
export class ScheduledHuntingRunStatus extends StatusModel<number> {
	@EntityField({ defaultValue: false })
	isError: boolean;
}
