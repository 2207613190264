import {
	Component,
	ChangeDetectionStrategy,
	Input,
	EventEmitter,
	Output,
	OnInit,
	ChangeDetectorRef,
} from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { filter } from 'rxjs/operators';
import { ModelBase, EntityModelBase, Paris } from '@microsoft/paris';
import {
	Machine,
	SendFeedbackApiCall,
	SoftwareInstallation,
	Vulnerability,
	RecommendationBase,
	Feedback,
	Software,
	Asset,
} from '@wcd/domain';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { TvmTextsService, TextToken, InaccuracyContext } from '../../../services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { TvmReportInaccuracyService } from '../../../services/tvm-report-inaccuracy.service';
import { MessageBarType } from 'office-ui-fabric-react';
import { FeaturesService, Feature } from '@wcd/config';

const ORG_CONTEXTS = [
	InaccuracyContext.OrgInventory,
	InaccuracyContext.OrgVulnerabilities,
	InaccuracyContext.OrgScaRecommendation,
	InaccuracyContext.OrgVaRecommendation,
];

const SOFTWARE_CONTEXTS = [
	InaccuracyContext.OrgInventory,
	InaccuracyContext.MachineInventory,
	InaccuracyContext.MachineVaRecommendation,
	InaccuracyContext.OrgVaRecommendation,
];

@Component({
	selector: 'report-inaccuracy-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './report-inaccuracy-panel.component.html',
})
export class TvmReportInaccuracyPanelComponent extends PanelContainer implements OnInit {
	isSending = false;
	canSubmit = false;

	contextMachine: Machine;
	notes: string;
	currentReason: string;

	includeEmail = true;
	email: string;

	includeMachineName = true;
	machineName: string;

	reasons: Array<string>;
	reasonsTitle: string;

	showDisclaimer = false;
	disclaimerText: string;

	messageBarType = MessageBarType;
	messageBarStyles = {
		root: {
			background: '#FFF4CE',
		},
	};
	showFastlaneIndication: boolean;

	private _isSoftwareContext: boolean;
	private _isOrgContext: boolean;
	private _configurationDisclaimer: string;
	private _softwareDisclaimer: string;

	private _showSoftwareDisclaimerReasons: string[];
	private _showConfigurationsDisclaimerReasons: string[];

	private _inaccuracyContext: InaccuracyContext;
	private _alreadyAppliedReason: string;
	orgScaInaccuracyBlockText: string;
	showReportForSpecificDeviceText: boolean;

	@Input('inaccuracyContext')
	set inaccuracyContext(context: InaccuracyContext) {
		this._inaccuracyContext = context;
		this._isOrgContext = ORG_CONTEXTS.includes(context);
		this.reasons = this.tvmTextsService.getInaccuracyReasons(context);
		this.reasonsTitle = this.tvmTextsService.getText(TextToken.ReportInaccuracyReasonTitle, context);
		this._isSoftwareContext = SOFTWARE_CONTEXTS.includes(context);
		this.disclaimerText = this._isSoftwareContext
			? this._softwareDisclaimer
			: this._configurationDisclaimer;
	}

	private _contextObject: EntityModelBase;
	contextTitle: string;
	@Input('contextObject') set contextObject(obj: EntityModelBase) {
		this._contextObject = obj;

		if (obj instanceof RecommendationBase) {
			this.contextTitle = this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, obj);
		} else if (obj instanceof SoftwareInstallation) {
			this.contextTitle = obj.productName;
		} else if (obj instanceof Vulnerability) {
			this.contextTitle = obj.name;
		} else if (obj instanceof Software) {
			this.contextTitle = `${obj.vendor} - ${obj.name}`;
		}
	}

	@Output() reportSent = new EventEmitter<any>();

	readonly submitApiCall = SendFeedbackApiCall;

	constructor(
		public router: Router,
		private paris: Paris,
		private authService: AuthService,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private activatedEntity: ActivatedEntity,
		private tvmTextsService: TvmTextsService,
		private changeDetectorRef: ChangeDetectorRef,
		private reportInaccuracyService: TvmReportInaccuracyService,
		private featuresService: FeaturesService,
	) {
		super(router);
		this.activatedEntity.currentEntity$
			.pipe(filter((entity: ModelBase) => entity instanceof Machine))
			.subscribe((machine: Machine) => {
				this.contextMachine = machine;
				if (this.featuresService.isEnabled(Feature.TvmDataCookingEnableFastLane)) {
					this.paris.getItemById(Asset, machine.id).subscribe(asset => this.showFastlaneIndication = this.isShowFastlaneIndication(asset));
				}
			});
		this.setEmail();
		this.setMachineName();
		this._softwareDisclaimer = this.i18nService.get('tvm.reportInaccuracy.softwareDisclaimer');
		this._configurationDisclaimer = this.i18nService.get('tvm.reportInaccuracy.configurationDisclaimer');
		this._alreadyAppliedReason = this.i18nService.strings.tvm_reportInaccuracy_reasons_recommendations_alreadyFixed;
		this.orgScaInaccuracyBlockText = this.i18nService.strings.tvm_reportInaccuracy_orgScaInaccuracyBlock;

		this._showSoftwareDisclaimerReasons = [
			this._alreadyAppliedReason,
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExistInOrg'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.doesNotExist'),
			this.i18nService.get('tvm.reportInaccuracy.reasons.inventory.wrongVersion'),
			this.tvmTextsService.otherInaccuracyReason,
		];

		this._showConfigurationsDisclaimerReasons = [
			this._alreadyAppliedReason,
			this.tvmTextsService.otherInaccuracyReason,
		];
	}

	isMachineContext() {
		return this.contextMachine && !this._isOrgContext;
	}

	setMachineName() {
		if (this.isMachineContext()) {
			this.machineName = this.includeMachineName ? this.contextMachine.name : null;
			setTimeout(() => this.changeDetectorRef.markForCheck());
		}
	}

	setEmail() {
		this.email = this.includeEmail ? this.authService.currentUser.name : null;
		setTimeout(() => this.changeDetectorRef.markForCheck());
	}

	submitReport() {
		this.isSending = true;
		this._contextObject = this.reportInaccuracyService.removePIIandIrrelevantDataFromContextObject(
			this._contextObject
		);

		const report: Partial<Feedback> = {
			email: this.includeEmail && this.email,
			description: this.createDescription(),
		};
		this.paris.apiCall(this.submitApiCall, report).subscribe(
			result => this.reportSent.emit(result),
			error => this.dialogsService.showError({
				title: this.i18nService.get('feedback.error.title'),
				text: this.i18nService.get('feedback.error.text'),
				data: error,
			})
		);
	}

	private createDescription(): string {
		let descriptionStrings = [
			`'${this._inaccuracyContext}'`,
			`reason - '${this.currentReason}'`,
			this._isSoftwareContext ? `software - '${this._contextObject.id}'` : '',
			this.notes ? `user notes - '${this.notes}'` : '',
			`context object - '${this.convertObjectToString(this._contextObject)}'`,
		];
		if (this.isMachineContext()) {
			// machine name is currently can be selected to include with the feedback but it's not sent to the backend
			const machine = this.contextMachine;
			descriptionStrings = [
				...descriptionStrings,
				`machine ID - '${machine.machineId}'`,
				`os:build - '${machine.os.osPlatformString}:${machine.os.fullBuild}'`,
				`first seen - '${machine.firstSeen}', last seen - '${machine.lastSeen}'`,
				`status category - '${machine.status.category}'`,
				machine.status.description ? `device description - '${machine.status.description}'` : '',
			];
		}
		return descriptionStrings.filter(Boolean).join(', ');
	}

	private convertObjectToString(obj: any) {
		// function to avoid circular dependencies in the object
		const cache = [];
		const str = JSON.stringify(obj, function (key, value) {
			if (typeof value === 'object' && value !== null) {
				if (cache.indexOf(value) !== -1) {
					// Duplicate reference found, discard key
					return;
				}
				cache.push(value);
			}
			return value;
		});
		return str;
	}

	private isShowFastlaneIndication(asset: Asset) {
		return this.isMachineContext() && asset.isFastlaneSource;
	}

	updateReasonSelected() {
		this.showDisclaimer = this._isSoftwareContext
			? this._showSoftwareDisclaimerReasons.includes(this.currentReason)
			: this._showConfigurationsDisclaimerReasons.includes(this.currentReason);

		this.showReportForSpecificDeviceText = this._isOrgContext && !this._isSoftwareContext && this.currentReason === this._alreadyAppliedReason;
		this.updateCanSubmit();
	}

	updateCanSubmit() {
		this.canSubmit = this.showReportForSpecificDeviceText ?
			false :
			this.isOtherReasonSelected() ?
				!!this.notes :
				!!this.currentReason;
	}

	isOtherReasonSelected(): boolean {
		return this.currentReason === this.tvmTextsService.otherInaccuracyReason;
	}
}
