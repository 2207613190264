<entity-panel-collapsible-section *ngLet="(evidence$ | async) as evidence" [loaded]="evidence"
	[loadingText]="'tvm.evidence.loadingProductEvidence' | i18n" [title]="'tvm.evidence.productEvidenceTitle' | i18n"
	[collapsed]=false>

	<div class="tvm-list wcd-margin-medium-top">

		<div class="tvm-list__key_val_item tvm-list__key_val_item--span-2" *ngIf="evidence?.diskPaths?.length > 0">
			<dt>{{'tvm.evidence.productPathsSubtitle' | i18n}}</dt>
			<copyable-input *ngFor="let diskPath of evidence.diskPaths" [value]="diskPath" [readonly]="true"
				class="wcd-margin-xsmall-bottom" [ariaLabel]="'tvm.evidence.productPathsSubtitle' | i18n"></copyable-input>
		</div>

		<div class="tvm-list__key_val_item tvm-list__key_val_item--span-2" *ngIf="evidence?.registryPaths?.length > 0">
			<dt>{{'tvm.evidence.registryPathsSubtitle' | i18n}}</dt>
			<copyable-input *ngFor="let registryPath of evidence.registryPaths" [value]="registryPath"
				[readonly]="false" class="wcd-margin-xsmall-bottom" [ariaLabel]="'tvm.evidence.registryPathsSubtitle' | i18n"></copyable-input>
		</div>

		<div class="tvm-list__key_val_item tvm-list__key_val_item--span-2" *ngIf="evidence?.registryPaths?.length + evidence?.diskPaths?.length == 0">
			<dt>{{'tvm.evidence.noEvidenceAvailable' | i18n}}</dt>
		</div>

		<div class="tvm-list__key_val_item tvm-list__key_val_item--span-2" *ngIf="evidence?.lastSeenTimestampUtc">
			<dt>{{ 'tvm.evidence.lastFoundOn' | i18n }}</dt>
			<dd>{{ evidence.lastSeenTimestampUtc | date:'medium' }}</dd>
		</div>

	</div>

</entity-panel-collapsible-section>
