
		<div
			class="noprint wcd-margin-large-bottom wcd-textarea-placeholder"
			*ngIf="!readonly"
			data-track-component="EditableCommentsList"
		>
			<form (submit)="onSubmit()">
				<textarea
					attr.aria-label="{{ 'common.textareaAriaLabelComment.title' | i18n }}"
					[required]="commentText.dirty"
					class="comment-input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[(ngModel)]="newComment"
					name="comment-text"
					[placeholder]="newCommentPlaceholderKey | i18n"
					[disabled]="isVisibleSnackBar$ | async"
					rows="4"
					#commentText="ngModel"
				></textarea>
				<fab-default-button
					className="btn-secondary pull-right"
					data-track-id="AddComment"
					data-track-type="Button"
					type="submit"
					[text]="'editable.comments.list.add.button' | i18n"
					[disabled]="!newComment || isSaving || (isVisibleSnackBar$ | async)"
				></fab-default-button>
			</form>
		</div>

		<ng-container *ngIf="isLoading; else checkComments">
			<div class="wcd-flex-center-all">
				<i class="loader-icon"></i>
			</div>
		</ng-container>

		<ng-template #checkComments>
			<comments-list
				*ngIf="comments"
				[class.wcd-padding-large-top]="!readonly"
				[comments]="comments"
				[showLink]="showLink"
				[getLinkTitle]="getLinkTitle"
				[defaultCommentIcon]="defaultCommentIcon"
				(commentLinkClick)="commentLinkClick.emit($event)"
			>
			</comments-list>
		</ng-template>
	