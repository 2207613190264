import { ChangeDetectorRef, EventEmitter, } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
var lastId = 0;
var DatatableCheckboxComponent = /** @class */ (function () {
    function DatatableCheckboxComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.id = "datatable-checkbox-" + lastId++;
        this.change = new EventEmitter();
        this.mouseDown = new EventEmitter();
        this.onChange = function (value) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(DatatableCheckboxComponent.prototype, "checked", {
        get: function () {
            return this._checked;
        },
        set: function (checked) {
            var value = !!checked;
            if (value !== this.checked)
                this._checked = value;
        },
        enumerable: true,
        configurable: true
    });
    DatatableCheckboxComponent.prototype.writeValue = function (checked) {
        this.checked = !!checked;
        this.changeDetectorRef.markForCheck();
    };
    DatatableCheckboxComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DatatableCheckboxComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    /**
     * Toggles the `checked` value between true and false
     */
    DatatableCheckboxComponent.prototype.toggle = function () {
        this.checked = !this.checked;
    };
    /**
     * Event handler for checkbox input element.
     * Toggles checked state if element is not disabled.
     * @param event
     */
    DatatableCheckboxComponent.prototype._onInteractionEvent = function (event) {
        event.stopPropagation();
        this.toggle();
        this.onChange(this.checked);
        this.change.emit(this.checked);
    };
    DatatableCheckboxComponent.prototype.onCheckboxClick = function ($event) {
        // If shift is pressed, the (changed) event won't be fired, so doing it explicitly.
        if ($event.shiftKey) {
            $event.preventDefault();
        }
        this.mouseDown.emit($event);
    };
    return DatatableCheckboxComponent;
}());
export { DatatableCheckboxComponent };
