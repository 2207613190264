<div class="wcd-margin-bottom">
	<h3 class="wcd-padding-bottom">{{'endpointManagement.sense.offboarding.title' | i18n}}</h3>
	<div class="wcd-padding-bottom">
		<markdown [data]="'endpointManagement.sense.offboarding.descriptionA' | i18n"></markdown>
	</div>
	<div class="wcd-margin-bottom">
		<markdown [data]="'endpointManagement.sense.offboarding.descriptionB' | i18n"></markdown>
	</div>
	<div class="wcd-margin-bottom">
		<markdown [data]="'endpointManagement.sense.offboarding.descriptionC' | i18n"></markdown>
	</div>
</div>
<section class="form-section">
	<div class="form-group">
		<label for="onboard-method">{{'endpointManagement.sense.offboarding.deploymentMethod' | i18n}}</label>
		<div>
			<fancy-select [(ngModel)]="currentMethod"
				listId="onboard-method"
				name="onboard-method"
				[formatLabel]="getDeploymentMethodLabel"
				[isBordered]="true"
				labelClass="dropdown-width-medium"
				[values]="packages"></fancy-select>
		</div>
	</div>
	<div class="wcd-margin-bottom wcd-padding-small-all color-box-gray-100">
		<markdown [data]="'endpointManagement.deploymentMethods.' + currentMethod.name + '.description' | i18n"></markdown>
	</div>
	<div>
		<fab-primary-button
				[disabled]="!currentMethod"
				[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
				data-track-id="DownloadSenseOffboarding"
				data-track-type="Button"
				(onClick)="confirmAndDownloadPackage()">
				<wcd-shared-icon iconName="download"> </wcd-shared-icon >
			{{"endpointManagement.sense.offboarding.download" | i18n}}
		</fab-primary-button>
	</div>
</section>
