import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';

@Component({
	selector: 'progress-indicator',
	template: `
		<div class="progress-indicator">
			<span *ngIf="label">{{ label }}</span>
			<div class="progress-track color-box-gray-175">
				<div
					#content
					class="color-box-primary wcd-full-height"
					role="progressbar"
					aria-valuemin="0"
					aria-valuemax="100"
				></div>
			</div>
		</div>
	`,
	styles: [
		`
			.progress-track {
				height: 2px;
				margin: 4px 0;
			}
		`,
	],
})
export class ProgressIndicatorComponent implements OnInit, OnChanges {
	/**
	 * The time for the progress bar, in milliseconds.
	 */
	@Input() time: number;
	@Input() percent: number;

	/**
	 * The time that progress bar should start from (useful for long progress to keep the progress on the correct percent in case of page refresh).
	 */
	@Input() timeStart: number = 0;
	@Input() label: string;
	@Output() end: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('content', { static: true }) content: ElementRef;

	private _timeStart: number;

	ngOnInit() {
		if (this.time) {
			this._timeStart = this.timeStart ? this.timeStart : new Date().valueOf();
			this.setContentTimeFrame();
		} else {
			this.setContentSize(this.percent);
			if (this.percent >= 100) this.end.emit();
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.time) {
			this.setContentSize(changes.percent.currentValue);
			if (this.percent >= 100) this.end.emit();
		}
	}

	setContentSize(percent: number) {
		if (!percent || isNaN(percent)) percent = 0;
		else if (percent > 100) percent = 100;

		this.content.nativeElement.style.width = `${percent}%`;
		this.content.nativeElement.setAttribute('aria-valuenow', percent);
	}

	private setContentTimeFrame() {
		requestAnimationFrame(() => {
			const timePassed: number = new Date().valueOf() - this._timeStart;

			this.setContentSize((100 * timePassed) / this.time);
			if (timePassed < this.time) this.setContentTimeFrame();
			else this.end.emit();
		});
	}
}
