import { BehaviorSubject } from 'rxjs';
var AirsNotificationRuleConditionModel = /** @class */ (function () {
    function AirsNotificationRuleConditionModel(notificationRuleConditionGroupsStore, data) {
        var _this = this;
        this.values = [];
        this._groupBehaviorSubject = new BehaviorSubject(null);
        this.group$ = this._groupBehaviorSubject.asObservable();
        if (data) {
            if (data.values)
                this.values = data.values;
            notificationRuleConditionGroupsStore
                .getItemById(data.group, null, true)
                .subscribe(function (group) { return (_this.group = group); });
        }
    }
    Object.defineProperty(AirsNotificationRuleConditionModel.prototype, "group", {
        get: function () {
            return this._group;
        },
        set: function (group) {
            this._group = group;
            this._groupBehaviorSubject.next(group);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleConditionModel.prototype, "isEmpty", {
        get: function () {
            return !this.group || !this.values.length;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsNotificationRuleConditionModel.prototype, "isValid", {
        get: function () {
            return !this.group || !!this.values.length;
        },
        enumerable: true,
        configurable: true
    });
    AirsNotificationRuleConditionModel.prototype.clearValues = function () {
        this.values = [];
    };
    return AirsNotificationRuleConditionModel;
}());
export { AirsNotificationRuleConditionModel };
