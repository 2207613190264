import { Component, ChangeDetectionStrategy, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import {
	MdeUserRoleActionEnum,
	ItsmTool,
	ItsmProvisioning,
} from '@wcd/domain';
import { ItsmProvisioningService } from '../services/itsm-provisioning.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { SpinnerSize, MessageBarType } from 'office-ui-fabric-react';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { FeedbackService } from '../../../../../feedback/services/feedback.service';
import { compareVersion } from '../../../../../tvm/tvm-utils';

// tslint:disable-next-line: interface-over-type-literal
type Step = { title: string; action: string };

enum FailureReason {
	Unauthorized = 'Unauthorized',
	BadRequest = 'BadRequest',
	Forbidden = 'Forbidden',
	InternalServerError = 'InternalServerError',
	Ok = 'Ok',
}

enum ProvisionState {
	Success = 'Success',
	Failure = 'Failure',
	TicketingServiceException = 'TicketingServiceException',
}

@Component({
	selector: 'servicenow-provisioning',
	templateUrl: './servicenow-provisioning.component.html',
	styleUrls: ['./servicenow-provisioning.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ServiceNowProvisioningComponent implements OnInit, OnDestroy {
	readonly SpinnerSize = SpinnerSize;
	readonly authStateParamName = 'ServiceNowAuthState';
	readonly statusCodeParamName = 'ServiceNowStatusCode';

	private updateProvisioningSubscription: Subscription;
	private getProvisioningStatusSubscription: Subscription;
	private clientId: string;
	private clientSecret: string;
	private instanceName: string;
	private failureReason: FailureReason;
	private provisionState: ProvisionState;

	isUserAllowedToProvision: boolean;
	serviceNowAppVersion: string;
	MessageBarType = MessageBarType;
	shouldDisableForm: boolean;
	isResponsePending: boolean;
	isServiceNowProvisioned: boolean;
	messageBarType: MessageBarType;
	messageBarText: string;
	enableErrorMessageBarButton: boolean;
	steps: Step[];

	constructor(
		private i18nService: I18nService,
		private itsmProvisioningService: ItsmProvisioningService,
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		readonly telemetryService: TelemetryService,
		public feedbackService: FeedbackService,
		authService: AuthService
	) {
		this.steps = Array.from(Array(4).keys()).map(step => {
			return {
				title: i18nService.get(`tvm_provisioning_serviceNow_steps_${step}_title`),
				action: i18nService.get(`tvm_provisioning_serviceNow_steps_${step}_action`),
			};
		});

		this.isUserAllowedToProvision = authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.admin
		);

		const queryParams = router.parseUrl(router.url).queryParams;
		this.provisionState = queryParams[this.authStateParamName];
		this.failureReason = queryParams[this.statusCodeParamName];

		if (this.provisionState) {
			this.telemetryService.trackEvent({
				type: TrackingEventType.Navigation,
				id: 'ServiceNowProvisioningSuccess',
				payload: {
					state: this.provisionState,
					reason: this.failureReason,
					isSuccess: this.provisionState === ProvisionState.Success,
				},
			});
		}
	}

	ngOnInit() {
		this.reloadComponent();
	}

	ngOnDestroy() {
		this.updateProvisioningSubscription && this.updateProvisioningSubscription.unsubscribe();
		this.getProvisioningStatusSubscription && this.getProvisioningStatusSubscription.unsubscribe();
	}

	authorize() {
		this.isResponsePending = true;
		const toolToProvision: Partial<ItsmProvisioning> = {
			endpoint: this.instanceName,
			clientId: this.clientId,
			clientSecret: this.clientSecret,
			redirectToWhenCompleted: window.location.href,
			itsmTool: ItsmTool.ServiceNow,
		};
		this.updateProvisioningSubscription = this.itsmProvisioningService
			.provisionItsmTool(toolToProvision)
			.subscribe(redirectLink => {
				window.location.href = redirectLink;
			});
	}

	disconnect() {
		this.isResponsePending = true;
		const toolToDeprovision: Partial<ItsmProvisioning> = {
			itsmTool: ItsmTool.ServiceNow,
		};
		this.updateProvisioningSubscription = this.itsmProvisioningService
			.deprovisionItsmTool(toolToDeprovision)
			.subscribe(_ => {
				this.reloadComponent();
			});
	}

	private reloadComponent() {
		this.isResponsePending = false;
		this.enableErrorMessageBarButton = false;
		this.messageBarType = undefined;
		this.shouldDisableForm = this.shouldDisableForms();
		this.getProvisioningStatusSubscription = this.itsmProvisioningService
			.getItsmToolProvisioning(ItsmTool.ServiceNow)
			.subscribe(
				itsmProvisioning => {
					this.instanceName =
						itsmProvisioning.serviceNowDetails && itsmProvisioning.serviceNowDetails.endpoint;
					this.clientId =
						itsmProvisioning.serviceNowDetails && itsmProvisioning.serviceNowDetails.clientId;
					this.isServiceNowProvisioned = itsmProvisioning.isProvisioned;
					if (this.isServiceNowProvisioned) {
						if (
							itsmProvisioning.serviceNowDetails.appMetadata &&
							itsmProvisioning.serviceNowDetails.appMetadata.version
						) {
							this.serviceNowAppVersion = `${
								this.i18nService.strings.tvm_provisioning_title_version
							} ${itsmProvisioning.serviceNowDetails.appMetadata.version} ${
								itsmProvisioning.serviceNowDetails.latestAppVersion &&
								compareVersion(
									itsmProvisioning.serviceNowDetails.latestAppVersion,
									itsmProvisioning.serviceNowDetails.appMetadata.version
								) === 1
									? this.i18nService.strings.tvm_provisioning_title_version_available
									: ''
							}`;
						}
						if (itsmProvisioning.serviceNowDetails.validConnection === false) {
							this.messageBarType = MessageBarType.severeWarning;
							this.messageBarText = `${
								this.i18nService.strings.tvm_servicenow_provisioning_errors_reprovision_1
							}<br>${
								this.i18nService.strings.tvm_servicenow_provisioning_errors_reprovision_2
							}`;
						} else {
							this.messageBarType = MessageBarType.success;
							this.messageBarText = this.i18nService.get(
								'tvm.provisioning.connectionEnabledMessage',
								{
									service: 'ServiceNow',
								}
							);
						}
					}
					this.shouldDisableForm = this.shouldDisableForms();
					this.changeDetectorRef.detectChanges();
				},
				_ => {
					this.messageBarType = MessageBarType.error;
					this.messageBarText = this.i18nService.get(
						'tvm.provisioning.authorizationStatusUnavailable'
					);
					this.enableErrorMessageBarButton = true;
					this.changeDetectorRef.detectChanges();
				}
			);
		if (this.isProvisionFailed() && this.isServiceNowProvisioned === undefined) {
			this.messageBarType = MessageBarType.error;

			if (this.provisionState === ProvisionState.Failure) {
				switch (this.failureReason) {
					case FailureReason.Unauthorized:
						this.messageBarText = this.i18nService.strings.tvm_servicenow_provisioning_errors_401_500;
						break;
					case FailureReason.InternalServerError:
						this.messageBarText = `${
							this.i18nService.strings.tvm_servicenow_provisioning_errors_500_500_1
						}<br>${
							this.i18nService.strings.tvm_servicenow_provisioning_errors_500_500_2
						}<a href="https://www.servicenow.com/support/contact-support.html" target="_blank" rel="noopener noreferrer">${
							this.i18nService.strings.tvm_servicenow_provisioning_errors_500_500_link
						}</a>`;
						break;
					case FailureReason.Forbidden:
						this.messageBarText = `${
							this.i18nService.strings.tvm_servicenow_provisioning_errors_403_500_1
						}<br>${this.i18nService.strings.tvm_servicenow_provisioning_errors_403_500_2}`;
						break;
					default:
						this.messageBarText = `${
							this.i18nService.strings.tvm_servicenow_provisioning_errors_default_1
						}<br>${this.i18nService.strings.tvm_servicenow_provisioning_errors_default_2}`;
						break;
				}
			} else {
				this.messageBarText = `${
					this.i18nService.strings.tvm_servicenow_provisioning_errors_200_500_1
				}<br>${this.i18nService.strings.tvm_servicenow_provisioning_errors_200_500_2}`;
			}
		}
	}

	private isProvisionFailed(): boolean {
		return this.provisionState && this.provisionState !== ProvisionState.Success;
	}

	private shouldDisableForms(): boolean {
		return !this.isUserAllowedToProvision || this.isServiceNowProvisioned;
	}
}
