import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { RemediationStatusType } from '../airs_entity/airs-entity-status-types.enum';

@Entity({
	singularName: 'Remediated Threat Status',
	pluralName: 'Remediated Threat Statuses',
	values: [
		{
			id: RemediationStatusType.Remediated,
			name: 'Remediated entities',
			isFailed: false,
			className: 'success',
		},
		{
			id: RemediationStatusType.Failed,
			name: 'Remediation failures',
			isFailed: true,
			className: 'error',
		},
	],
})
export class RemediatedThreatStatus extends StatusModel {
	@EntityField() isFailed: boolean;
}
