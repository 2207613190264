export class DimensionsModel {
	constructor(public width: number, public height: number) {}

	constrainTo(dimensions?: DimensionsModel, margin: number = 0): DimensionsModel {
		if (!dimensions)
			dimensions = new DimensionsModel(
				document.documentElement.clientWidth,
				document.documentElement.clientHeight
			);

		return new DimensionsModel(
			Math.min(this.width, dimensions.width - 2 * margin),
			Math.min(this.height, dimensions.height - 2 * margin)
		);
	}
}
