import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { Vulnerability } from '../weakness/vulnerability/vulnerability.entity';
import { TvmAnalyticsSharedEntityRelationshipConfigurations } from '../analyticsEndPointUtils';
import { ChangeEvent, ExposedOperatingSystemsStatistics } from '@wcd/domain';
import { SecurityRecommendation } from '../security-recommendation/security-recommendation.entity';

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: ChangeEvent,
	dataEntity: SecurityRecommendation,
	allItemsEndpointTrailingSlash: false,
	endpoint: (_, query) => `recommendations?search=${query.where['id']}`,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (changeEvent: ChangeEvent) => ({ id: changeEvent.scid }),
	parseData: (data, _, query) =>
		data.results.find(recommendation => recommendation.scId === query.where['id']),
})
export class ChangeEventRecommendationRelationship
	implements EntityRelationshipRepositoryType<ChangeEvent, SecurityRecommendation> {}

@EntityRelationship({
	sourceEntity: ChangeEvent,
	dataEntity: Vulnerability,
	endpoint: (_, query) => `changeEvents/${encodeURIComponent(query.where['id'])}/vulnerabilities`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (changeEvent: ChangeEvent) => ({ id: changeEvent.id }),
	parseDataQuery: (dataQuery: DataQuery) => {
		//TODO[omcheri]: parse query correctly once analytics endpoint is up: VSTS 24334392
		const query = TvmAnalyticsSharedEntityRelationshipConfigurations.parseDataQuery(dataQuery);
		return query;
	},
})
export class ChangeEventRelatedVulnerabilitiesRelationship
	implements EntityRelationshipRepositoryType<ChangeEvent, Vulnerability> {}

@EntityRelationship({
	...TvmAnalyticsSharedEntityRelationshipConfigurations,
	sourceEntity: ChangeEvent,
	dataEntity: ExposedOperatingSystemsStatistics,
	endpoint: () => `recommendations/recommendation/operatingSystems`,
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (changeEvent: ChangeEvent) => ({ recommendationId: `sca-_-${changeEvent.scid}` }),
})
export class ChangeEventExposedOsRelationship
	implements EntityRelationshipRepositoryType<ChangeEvent, ExposedOperatingSystemsStatistics> {}
