import { Component, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { Incident, SeverityType } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { upperFirst } from 'lodash-es';
import {
	EventModel,
	ColorMap,
} from '../../../shared/components/events-summary-bar/events-summary-bar.component';
import { SeverityTypeColorService } from '../../../shared/services/severity-type-color.service';
import { LegendItem } from '@wcd/charts';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'incidents-entity-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<dl role="none">
				<dt>{{ 'alerts.severity' | i18n }}</dt>
				<dd style="max-width: 200px">
					<events-summary-bars
						class="events-summary-bars"
						[events]="severityData"
						[kindColorMap]="severityColorMap"
					></events-summary-bars>
					<wcd-chart-legend
						class="wcd-full-width wcd-margin-small-top wcd-margin-large-bottom"
						orientation="horizontal"
						[showValues]="true"
						[items]="calcSeverityLegend(severityData)"
					></wcd-chart-legend>
				</dd>
				<dt>{{ 'incident.setClassification.incidentStatus' | i18n }}</dt>
				<dd style="max-width: 200px">
					<wcd-bars-chart
						[data]="statusData"
						[settings]="{ isSelectable: false, outerValues: true }"
					></wcd-bars-chart>
				</dd>
				<dt>{{ 'alerts.classification' | i18n }}</dt>
				<dd style="max-width: 200px">
					<wcd-bars-chart
						[data]="classificationData"
						[settings]="{ isSelectable: false, outerValues: true }"
					></wcd-bars-chart>
				</dd>
			</dl>
		</section>
	`,
})
export class IncidentsEntityPanelComponent extends EntitiesPanelComponentBase<Incident> {
	constructor(
		public authService: AuthService,
		private changeDetectorRef: ChangeDetectorRef,
		private severityTypeColorService: SeverityTypeColorService,
		private i18nService: I18nService
	) {
		super();
		this.severityColorMap = {
			type: 'class',
			map: severityTypeColorService.backgroundColorsClassMap,
		};
	}

	statusData: Array<{ name: string; value: number }>;
	classificationData: Array<{ name: string; value: number }>;
	severityData: Array<EventModel<SeverityType>>;
	severityColorMap: ColorMap<SeverityType>;

	setEntities(entities: Array<Incident>): void {
		super.setEntities(entities);
		this.classificationData = this.calcClassification();
		this.statusData = this.calcStatus();
		this.severityData = this.calcSeverity();
		this.changeDetectorRef.markForCheck();
	}

	private calcClassification(): Array<{ name: string; value: number }> {
		const classificationsIndex: Record<string, number> = this.entities.reduce(
			(classifications: Record<string, number>, incident) => {
				const currClassificationName = this.i18nService.get(incident.classification ?
					incident.classification.nameI18nKey : 'common_unclassified');
				if (!classifications[currClassificationName]) {
					classifications[currClassificationName] = 0;
				}
				classifications[currClassificationName]++;
				return classifications;
			},
			{}
		);

		return Object.entries(classificationsIndex).map(([name, value]) => ({ name, value }));
	}

	private calcStatus(): Array<{ name: string; value: number }> {
		const statusIndex: Record<string, number> = this.entities.reduce(
			(status: Record<string, number>, incident) => {
				if (!status[incident.status.nameI18nKey]) {
					status[incident.status.nameI18nKey] = 0;
				}
				status[incident.status.nameI18nKey]++;
				return status;
			},
			{}
		);

		return Object.entries(statusIndex).map(([name, value]) => ({ name: this.i18nService.get(name), value }));
	}

	private calcSeverity(): Array<EventModel<SeverityType>> {
		const severityIndex: Partial<Record<SeverityType, number>> = this.entities.reduce(
			(status, incident) => {
				if (!status[incident.severity.type]) {
					status[incident.severity.type] = 0;
				}
				status[incident.severity.type]++;
				return status;
			},
			{}
		);

		return [SeverityType.Low, SeverityType.Medium, SeverityType.High, SeverityType.Informational].map(
			severityType => ({ kind: severityType, count: severityIndex[severityType] || 0 })
		);
	}

	calcSeverityLegend(index: Array<EventModel<SeverityType>>): ReadonlyArray<LegendItem> {
		return index
			.map(severity => ({
				name: this.i18nService.get(`incident_severity_values_${upperFirst(severity.kind)}`),
				iconClassName: this.severityTypeColorService.fontColorsClassMap.get(severity.kind),
				value: severity.count,
			}))
			.filter(legendItem => legendItem.value);
	}
}
