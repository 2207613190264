import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { AadUser } from './aad.user.entity';
import { EvidenceAlertsSummary } from '../../incident/evidence-alerts-summary.value-object';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@EntityRelationship({
	sourceEntity: AadUser,
	dataEntity: EvidenceAlertsSummary,
	endpoint: (_, query) => `user/${query.where['id']}/alerts`,
	getRelationshipData: (aadUser: AadUser) => ({ id: aadUser.id }),
	fixedData: { lookBackInDays: 180 },
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class AadUserAlertsRelationship
	implements EntityRelationshipRepositoryType<AadUser, EvidenceAlertsSummary> {}
