import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

export enum PlatformProvisionStatus {
	Deprovisioned = 'Deprovisioned',
	NotProvisioned = 'NotProvisioned',
	InProgress = 'InProgress',
	PlatformProvisioned = 'PlatformProvisioned',
	AdditionalPlatformProvisioned = 'AdditionalPlatformProvisioned',
}

@ValueObject({
	singularName: 'Platform provision status',
	pluralName: 'Platform provision status',
})
export class PlatformProvisionStatusModel extends ModelBase {
	@EntityField()
	platformProvision: PlatformProvisionStatus;
}
