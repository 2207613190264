
		<modal (close)="cancel.emit()" [settings]="settings">
			<div class="wcd-flex-1 dialog-contents wcd-scroll-vertical ie11Patch ie11Flex">
				<markdown>{{ message }}</markdown>
			</div>

			<footer class="dialog-footer wcd-flex-none right-text">
				<button
					class="btn btn-primary"
					data-track-id="DontAddToWhitelist"
					data-track-type="Button"
					(click)="cancel.emit()"
				>
					{{ i18nService.strings.buttons_done }}
				</button>
				<ng-container *ngLet="(tiCapacityData$ | async) as tiCapacityData">
					<ng-container *ngIf="tiCapacityData !== loadingSymbol">
						<button
							*ngIf="!tiCapacityData; else showTiError"
							type="button"
							class="btn-link"
							data-track-id="SetSystemExclusionRule"
							data-track-type="Button"
							[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
							(click)="setSystemExclusionRule()"
						>
							<fab-icon iconName="OpenInNewWindow"></fab-icon>
							{{ i18nService.strings.remediationActions_openAllowListPanel }}
						</button>
						<ng-template #showTiError>
							<span class="wcd-padding-left color-text-error">{{ tiCapacityData }}</span>
						</ng-template>
					</ng-container>
				</ng-container>
			</footer>
		</modal>
	