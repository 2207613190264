import { EntityModelBase, Entity, EntityField } from '@microsoft/paris';
import { cyberEventActionTypes } from './cyber-event-action-type.values';

@Entity({
	singularName: 'Cyber event action type',
	pluralName: 'Cyber event action types',
	values: cyberEventActionTypes,
})
export class CyberEventActionType extends EntityModelBase {
	/**
	 * automatically generated field: 'id' value is converted from PascalCase to camelCase. see 'cyberEventActionTypes'.
	 */
	@EntityField({data: 'TypeName' })
	typeName?: string;

	@EntityField({data: 'GroupTypeName' })
	groupTypeName?: string;

	@EntityField({data: 'SubGroupTypeName'})
	subGroupTypeName?: string;

	@EntityField({ data: 'DataTypeAsString' })
	dataType?: string;
}
