var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DownloadService } from '../../shared/services/download.service';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { ServiceUrlsService } from '@wcd/app-config';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { AddMachineGroupsFilterQueryParam, AddODataSearchQueryParam, AddODataFilterQueryParam, AddODataSortingQueryParam, DefaultApiVersion, fieldFiltersMap, ResolveEntityURLAndQueryParams, } from '@wcd/domain';
var TvmDownloadService = /** @class */ (function () {
    function TvmDownloadService(downloadService, dialogsService, i18nService, appInsightsService, serviceUrlsService) {
        this.downloadService = downloadService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.serviceUrlsService = serviceUrlsService;
    }
    TvmDownloadService.prototype.downloadCsvFromRelationshipRepository = function (repository, endpoint, filename, dataQuery, extraDataQuery, customFilter) {
        var _a = ResolveEntityURLAndQueryParams({
            endPoint: endpoint,
            entityModelBaseOrRelationship: repository,
            sourceModel: repository.sourceItem,
            extraQueryData: extraDataQuery,
        }), url = _a.url, queryParams = _a.queryParams;
        return this.downloadCsv({
            url: url,
            fileName: filename,
            dataQuery: dataQuery,
            queryParams: queryParams,
            customQueryParams: customFilter,
        });
    };
    TvmDownloadService.prototype.downloadCsv = function (downloadCsvConfig) {
        var queryParams = downloadCsvConfig.queryParams || {};
        AddMachineGroupsFilterQueryParam(queryParams);
        var dataQuery = downloadCsvConfig.dataQuery;
        if (dataQuery) {
            AddODataSearchQueryParam(dataQuery, queryParams);
            AddODataFilterQueryParam(dataQuery, queryParams, fieldFiltersMap);
            AddODataSortingQueryParam(dataQuery, queryParams);
        }
        this.appInsightsService.trackEvent('tvm-export-csv-event', {
            fileName: downloadCsvConfig.fileName,
            url: downloadCsvConfig.url,
        });
        var customQueryParams = downloadCsvConfig.customQueryParams || {};
        return this.downloadFile(downloadCsvConfig, 'csv', __assign({}, queryParams, customQueryParams));
    };
    TvmDownloadService.prototype.downloadFile = function (downloadFileConfig, filetype, queryParams) {
        var endpoint = this.serviceUrlsService.tvm + "/" + downloadFileConfig.url;
        var fileName = "" + downloadFileConfig.fileName;
        this.dialogsService.showSnackbar({
            text: this.i18nService.get('tvm.download.waitForDownload'),
            icon: 'Info',
        });
        if (filetype === 'csv') {
            endpoint =
                endpoint + (downloadFileConfig.alreadyHasQueryParamInUrl ? "&asCsv=true" : "?asCsv=true");
            fileName = fileName + ".csv";
        }
        return this.downloadService.downloadFromUrl(endpoint, {
            downloadedFileName: fileName,
            headers: DefaultApiVersion,
            params: queryParams,
        });
    };
    return TvmDownloadService;
}());
export { TvmDownloadService };
