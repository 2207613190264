<collapsible-items-preview *ngIf="fields"
	[repository]="repository"
	[title]="'tvm.recommendationException.deviceGroupsExceptionDetails' | i18n"
	[loadingText]="'common.loading' | i18n"
	maxItems="3"
	disableSort="true"
	[fields]="fields"
	[initiallyCollapsed]="false"
	[dataviewLabel]="'tvm.recommendationException.deviceGroupsExceptionDetails' | i18n"
	(showMore)="openAllRelatedExceptions()"
	(onItemClick)="openRelatedExceptionPanel($event)">
</collapsible-items-preview>