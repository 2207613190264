import { Entity, EntityField, EntityModelBase, ParisConfig } from '@microsoft/paris';
import { Machine } from '../../machine/machine.entity';
import { InvestigationStatus } from '../../investigation/investigation-status.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { IEntityConfigBase } from '@microsoft/paris/dist/lib/config/model-config';
import { HttpOptions } from '@microsoft/paris/dist/lib/data_access/http.service';

@Entity({
	singularName: 'Session',
	pluralName: 'Sessions',
	baseUrl: (config: WcdPortalParisConfig, query) => query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr,
	endpoint: 'NOOP',
	parseItemQuery: (itemId, entity, config, params: { [index: string]: any }) => {
	    const id = String(itemId);

	    if (id.startsWith('CLR')) {
	    	return `cloud/live_response/sessions/${itemId}`
		}

	    return (String(itemId).startsWith('LR') ? 'v2/live_response/sessions' : 'live_response/sessions') +
		`/${itemId}`
	},
	parseSaveQuery: (session: LiveResponseSession, entity, config?: ParisConfig, options?: HttpOptions) => {
		if(session.useV3Api){
			return 'cloud/live_response/create_session'
		}

		return session.useV2Api ? 'v2/live_response/create_session' : 'live_response/create_session'
	},
	serializeItem: (session: LiveResponseSession) => ({
		end_date: session.endDate || null,
		machine_last_seen: session.machine.lastSeen || null,
		machine_id: session.machine.id,
		session_status: session.status || null,
		start_date: session.startDate || null,
	}),
})
export class LiveResponseSession extends EntityModelBase<number | string> {
	@EntityField({ data: 'session_id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: number | string;

	@EntityField({
		data: 'machine_id',
		parse: (machineId, rawData) => {
			const machineInfo = rawData['machine_info'];
			if (machineInfo) {
				return machineInfo.machine_id;
			}
			return machineId;
		},
	})
	machine: Machine;

	@EntityField({ data: 'session_status' })
	status: InvestigationStatus;

	@EntityField({ data: 'user' })
	initiatingUser: string;

	@EntityField({ data: 'start_date' })
	startDate: Date;

	@EntityField({ data: 'end_date' })
	endDate: Date;

	@EntityField({ data: 'session_id', parse: fieldData => String(fieldData).startsWith('LR') })
	useV2Api: boolean;

	@EntityField({ data: 'session_id', parse: fieldData => String(fieldData).startsWith('CLR') })
	useV3Api: boolean;
}
