// TODO(khaw): Remove (if not used for the new component) as part of 'Task 31195382: [Petra] [Magellan] Remove FS from code and clean old pages'
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NetworkDevicesConnectionState } from '@wcd/domain';
import { EntityStatusComponentBase } from '../../../../global_entities/components/entity-status/entity-status.component.base';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { I18nService } from '@wcd/i18n';
var NetworkDeviceEntityStatusComponent = /** @class */ (function (_super) {
    __extends(NetworkDeviceEntityStatusComponent, _super);
    function NetworkDeviceEntityStatusComponent(tvmWeaknessSeverityService, tvmColorService, i18nService) {
        var _this = _super.call(this) || this;
        _this.tvmWeaknessSeverityService = tvmWeaknessSeverityService;
        _this.tvmColorService = tvmColorService;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(NetworkDeviceEntityStatusComponent.prototype, "networkDevice", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    NetworkDeviceEntityStatusComponent.prototype.getWeaknessColorClass = function (networkDevice) {
        return this.tvmWeaknessSeverityService.getWeaknessColorClass(networkDevice.exposureLevel);
    };
    NetworkDeviceEntityStatusComponent.prototype.getStatusColorClass = function (networkDevice) {
        if (networkDevice.status !== NetworkDevicesConnectionState.Active) {
            return "ms-fontColor-" + this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name;
        }
        return "";
    };
    NetworkDeviceEntityStatusComponent.prototype.getStatusText = function (networkDevice) {
        if (networkDevice.status === NetworkDevicesConnectionState.Active) {
            return this.i18nService.get('tvm_network_devices_active_status');
        }
        return this.i18nService.get('tvm_network_devices_inactive_status');
    };
    NetworkDeviceEntityStatusComponent.prototype.shouldShowStatusIcon = function (networkDevice) {
        return networkDevice.status !== NetworkDevicesConnectionState.Active;
    };
    return NetworkDeviceEntityStatusComponent;
}(EntityStatusComponentBase));
export { NetworkDeviceEntityStatusComponent };
