import { SccAuthService } from "./auth.svc";
import { SccResourceService } from "./resource.svc";

export enum SccRoles {
	searchAndPurge = 'ucc:SearchAndPurge///UserPermissions:MDO:microsoft.xdr/secops/securitydata/emailadvancedactions/manage',
	enableSecurityGeneralSettings = 'setting:PlatformClient$EnableSecurityGeneralSettings',
	hasMtpViewPermission = 'mtprbac:MTP:UserViewPermissions',
	mtpEligible = 'mtprbac:MTP:Eligible',
	hasMtpOnboardingPermission = 'mtprbac:MTP:UserOnboardingPermissions',
	mdatpLicenseExpired = 'mtprbac:Mdatp:LicenseExpired',
	hasUnifiedUserPage = 'mtprbac:Feature:UnifiedUserPage',
	hasUnifiedUserPane = 'mtprbac:Feature:UnifiedUserPane',
	mdatpWorkloadActive = 'mtprbac:workload:Mdatp:Active',
	hasMtpUrbacReadPermissions = 'mtpurbac:HasConfigurationReadPermissions',
	hasMtpUrbacManagePermissions = 'mtpurbac:HasConfigurationManagePermissions',
	isAadAdmin = 'IsAadCompanyAdmin,IsAadSecurityAdmin',
}

/**
 * Manages SCC Roles
 */
export class SccRolesService {
	private rolesDictionary: { [key: string]: boolean } = {};
	private roles: string[];
	private promiseResolver;
	private inRoleAsyncPromise;

	constructor(private sccAuth: SccAuthService, private sccResource: SccResourceService) {
		this.roles = Object.keys(SccRoles).map(key => SccRoles[key]) as string[];
		this.loadRoles();
		this.inRoleAsyncPromise = new Promise((resolve)=> {
			this.promiseResolver = resolve;
		})
	}

	private loadRoles() {
		if (this.sccAuth) {
			this.sccAuth.isInRoles(this.roles).then(values => {
				this.rolesDictionary = this.roles.reduce((dic, name, index) => {
					dic[name] = values[index];
					return dic;
				}, this.rolesDictionary);
			});
		}

		if (this.sccResource) {
			this.sccResource.load('cas/config')
				.then(({userPageEnabledPromise, userProfilePaneEnabledPromise}) => {
					userPageEnabledPromise.then((userPageEnabled) => {
						if (userPageEnabled) {
							this.rolesDictionary[SccRoles.hasUnifiedUserPage] = true;
						}
					});
					userProfilePaneEnabledPromise.then((userProfileEnabled)=> {
						if (userProfileEnabled) {
							this.rolesDictionary[SccRoles.hasUnifiedUserPane] = true;
						}
						this.promiseResolver();
					})
				});
		}
	}

	inRole(role: SccRoles): boolean {
		return this.rolesDictionary && this.rolesDictionary[role];
	};

	//The inRole method is a synchronized function that depends on an async data, which is a bad design
	// adding this to solve a blocker bug, This needs to be refactored.
	inRoleAsync(role){
		return this.inRoleAsyncPromise.then(() => {
			return this.inRole(role)
		})
	}
}
