
		<div class="wcd-scroll-vertical">
			<!--details-->
			<section class="wcd-padding-large-horizontal wcd-padding-vertical">
				<div class="wcd-flex wcd-flex-wrap">
					<dl role="none">
						<!--Started-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_startedOn }}</dt>
						<dd>{{ command.startTime | date: 'medium' }}</dd>

						<!--Entities-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_entities }}</dt>
						<dd>{{ command.entityCount || 0 }}</dd>

						<!--Name-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_commandName }}</dt>
						<dd>{{ (commandType$ | async)?.displayName }}</dd>
					</dl>
					<dl role="none">
						<!--Completed-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_completedOn }}</dt>
						<dd>
							<ng-container *ngIf="command.endTime; else notAvailable">
								{{ command.endTime | date: 'medium' }}
							</ng-container>
							<ng-template #notAvailable>
								{{ i18nService.strings.notAvailable_short }}
							</ng-template>
						</dd>

						<!--Status-->
						<dt>{{ i18nService.strings.liveResponse_command_panel_status }}</dt>
						<dd>{{ command.status.name }}</dd>

						<!--Script name?-->
						<!--<dt>Script name</dt>-->
						<!--<dd></dd>-->
					</dl>
				</div>
			</section>
			<!--input-->
			<wcd-collapsible-section
				*ngIf="command.rawCommand"
				[label]="i18nService.strings.liveResponse_command_panel_section_input"
				[sectionId]="'live-response-command-panel-command-Input'"
			>
				<ng-container
					*ngTemplateOutlet="
						displayIO;
						context: {
							text: i18nService.strings.liveResponse_command_panel_section_inputLink,
							str: command.rawCommand
						}
					"
				>
				</ng-container>
			</wcd-collapsible-section>
			<!--output-->
			<wcd-collapsible-section
				*ngIf="commandOutput"
				[label]="i18nService.strings.liveResponse_command_panel_section_output"
				[sectionId]="'live-response-command-panel-command-Output'"
			>
				<ng-container
					*ngTemplateOutlet="
						displayIO;
						context: {
							text: i18nService.strings.liveResponse_command_panel_section_outputLink,
							str: commandOutput
						}
					"
				>
				</ng-container>
			</wcd-collapsible-section>
			<ng-template #displayIO let-text="text" let-str="str">
				<pre class="code wcd-full-width">{{ str }}</pre>
				<fab-action-button
					(onClick)="openIoInNewTab(str)"
					[text]="text"
					[iconProps]="{ iconName: 'OpenInNewWindow' }"
				>
				</fab-action-button>
			</ng-template>
		</div>
	