/* tslint:disable:template-click-events-have-key-events */
import { Component, ChangeDetectionStrategy } from '@angular/core';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { RemediationTask } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { map, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { CalculateRemainingDaysPipe } from '../../../../shared/pipes/calculate-remaining-days.pipe';
import { RemediationTaskDisplayRemainingDaysPipe } from '../../../pipes/remediation-task-display-remaining-days.pipe';
import { StretchedDonutBarItem } from '@wcd/charts';
import { TruncatePipe } from '../../../../shared/pipes/truncate.pipe';
import { RemediationTaskFixedAssetsPipe } from '../../../pipes/remediation-task-fixed-assets.pipe';
import { TvmColorService, TvmColor } from '../../../services/tvm-color.service';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { zip } from 'rxjs';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';

@Component({
	selector: 'tvm-active-remediation-tasks-widget',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './tvm-active-remediation-tasks.widget.html',
	styleUrls: ['./tvm-active-remediation-tasks.widget.scss'],
})
export class TvmActiveRemediationTasksWidget extends ReportWidgetComponent<Array<StretchedDonutBarItem>> {
	private isMgSelected: boolean;

	constructor(
		reportsService: ReportsService,
		private router: Router,
		private machineGroupsFilterService: TvmMachineGroupsFilterService,
		private tvmTextsService: TvmTextsService,
		private paris: Paris,
		private i18nService: I18nService,
		private tvmColorService: TvmColorService,
		private calculateRemainingDaysPipe: CalculateRemainingDaysPipe,
		private truncatePipe: TruncatePipe,
		private remediationTaskDisplayRemainingDaysPipe: RemediationTaskDisplayRemainingDaysPipe,
		private remediationTaskFixedAssetsPipe: RemediationTaskFixedAssetsPipe
	) {
		super(reportsService);
	}

	get widgetConfig() {
		return {
			id: 'improvementOpportunitiesWidget',
			name: this.i18nService.get('tvm.dashboard.topRemediationActivities'),
			noDataMessage: () =>
				this.tvmTextsService.getText(TextToken.NoDataForWidget, {
					noDataKey: 'tvm.activeRemediation.emptyExperienceMessage',
					isGroupSelected: this.isMgSelected,
				}),
			NoIconLeftAlign: true,
			loadData: () => {
				const mg$ = this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
				const remediationActivities$ = this.paris.allItems(RemediationTask).pipe(
					map(tasksArray => {
						const filteredTasks = tasksArray.filter(x => x.status.value !== 'Completed');
						return this.getStrechedDonutChartItems(filteredTasks.slice(0, 5));
					})
				);
				return zip(mg$, remediationActivities$).pipe(
					map(([mg, remediationActivities]) => {
						this.isMgSelected = mg.isFiltering;
						return remediationActivities;
					})
				);
			},
			noDataIcon: FabricIconNames.Trackers,
			minHeight: '466px',
		};
	}

	getStrechedDonutChartItems(remediationTasks: Array<RemediationTask>): Array<StretchedDonutBarItem> {
		return remediationTasks.map(
			remediationTask =>
				remediationTask && {
					id: remediationTask.id,
					title: this.truncatePipe.transform(remediationTask.name, 35),
					valueColor: this.tvmColorService.colorsMap.get(TvmColor.Blue),
					totalColor: this.tvmColorService.colorsMap.get(TvmColor.GraphSeriesLight),
					value: this.remediationTaskFixedAssetsPipe.transform(remediationTask, true),
					total: remediationTask.targetAssets,
					icon:
						this.calculateRemainingDaysPipe.transform(remediationTask.dueDate) < 0
							? FabricIconNames.Warning
							: null,
					iconTooltip: this.displayDays(remediationTask),
					width: '100%',
					valuePrefix: this.i18nService.get(`tvm.remediationTask.monitoring.completed`),
					restPrefix: this.i18nService.get(`tvm.remediationTask.monitoring.incomplete`),
				}
		);
	}

	openRemediationTask(id: string): void {
		this.router.navigate(['remediation/remediation-activities', id]);
	}

	openRemediationTasksScreen(): void {
		this.router.navigate(['remediation/remediation-activities']);
	}

	private displayDays(remediationTask: RemediationTask) {
		return this.remediationTaskDisplayRemainingDaysPipe.transform(
			remediationTask,
			this.calculateRemainingDaysPipe.transform(remediationTask.dueDate)
		);
	}
}
