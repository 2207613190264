var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useCallback } from 'react';
import { panelService } from '@wcd/shared';
import { Panel, PanelType } from 'office-ui-fabric-react';
export var AngularWrapperPanels = function (_a) {
    var panels = _a.panels;
    var onRenderNavigationContent = useCallback(function (config, panelProps, defaultRender) {
        return config.navigationConfig ?
            React.createElement(AngularWrapperPanelNavigation, __assign({}, config.navigationConfig, { panelKey: config.id, onDismiss: panelProps.onDismiss }))
            : defaultRender(panelProps);
    }, []);
    return (panels && panels.length > 0) ?
        React.createElement(React.Fragment, null, (panels.map(function (panel) {
            return React.createElement(Panel, { key: panel.id, type: PanelType.medium, onDismiss: function () { return panelService.dismissPanel(panel.id); }, onRenderNavigationContent: function (props, defaultRender) { return onRenderNavigationContent(panel, props, defaultRender); }, hasCloseButton: true, isFooterAtBottom: true, isBlocking: false, isOpen: true, styles: panelStyles }, panel.content);
        }))) : null;
};
var AngularWrapperPanelNavigation = function (_a) {
    var panelKey = _a.panelKey, upButtonOnClick = _a.upButtonOnClick, upButtonDisabled = _a.upButtonDisabled, upButtonLabel = _a.upButtonLabel, downButtonOnClick = _a.downButtonOnClick, downButtonDisabled = _a.downButtonDisabled, downButtonLabel = _a.downButtonLabel, dismissButtonLabel = _a.dismissButtonLabel, onDismiss = _a.onDismiss;
    var DetailPanelV2Nav = window.M365SccControls.AdminControls.DetailPanelV2Nav;
    var upLabel = upButtonLabel || 'Move up';
    var downLabel = downButtonLabel || 'Move down';
    var dismissLabel = dismissButtonLabel || 'Close';
    var items = [
        {
            key: 'up',
            name: upLabel,
            iconProps: { iconName: 'up' },
            ariaLabel: upLabel,
            onClick: function () {
                panelService.dismissPanel(panelKey);
                upButtonOnClick();
            },
            disabled: upButtonDisabled,
        },
        {
            key: 'down',
            name: downLabel,
            iconProps: { iconName: 'down' },
            ariaLabel: downLabel,
            onClick: function () {
                panelService.dismissPanel(panelKey);
                downButtonOnClick();
            },
            disabled: downButtonDisabled,
        },
    ];
    return (React.createElement(DetailPanelV2Nav, { closeButtonProps: {
            onClick: onDismiss,
            ariaLabel: dismissLabel,
        }, closeButtonToolTipHostProps: { content: dismissLabel }, extraButtons: items }));
};
var ɵ0 = AngularWrapperPanelNavigation;
var takeOverScreenAtBreakpoint = {
    '@media only screen and (max-width: 640px)': {
        width: '100%',
    },
};
var panelStyles = {
    main: takeOverScreenAtBreakpoint,
    content: { height: '100%', padding: 0, boxSizing: 'border-box' },
    scrollableContent: { minHeight: '50%', paddingBottom: 16, paddingLeft: 32, paddingRight: 32 },
};
export { ɵ0 };
