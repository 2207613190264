/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./assessment-job-edit.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../../projects/wizard/src/lib/components/wizard.component.ngfactory";
import * as i3 from "../../../../../../../../projects/wizard/src/lib/components/wizard.component";
import * as i4 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "../../../../insights/services/app-insights.service";
import * as i6 from "./assessment-job-edit.component";
import * as i7 from "../services/assessment-job.service";
var styles_AssessmentJobEditComponent = [i0.styles];
var RenderType_AssessmentJobEditComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AssessmentJobEditComponent, data: {} });
export { RenderType_AssessmentJobEditComponent as RenderType_AssessmentJobEditComponent };
export function View_AssessmentJobEditComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵeld(1, 0, null, null, 2, "wcd-wizard", [["wizardTrackingName", "AuthenticatedScanWizard"]], null, [[null, "onClose"], [null, "onDone"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.closePanel() !== false);
        ad = (pd_0 && ad);
    } if (("onDone" === en)) {
        var pd_1 = (_co.closePanel() !== false);
        ad = (pd_1 && ad);
    } return ad; }, i2.View_WcdWizardComponent_0, i2.RenderType_WcdWizardComponent)), i1.ɵdid(2, 245760, null, 0, i3.WcdWizardComponent, [i4.I18nService, i1.ChangeDetectorRef, i1.ComponentFactoryResolver, i5.AppInsightsService], { steps: [0, "steps"], data: [1, "data"], wizardTitle: [2, "wizardTitle"], wizardTrackingName: [3, "wizardTrackingName"] }, { onDone: "onDone", onClose: "onClose" }), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.steps; var currVal_1 = _co.assessmentJobModel; var currVal_2 = _co.wizardTitle; var currVal_3 = "AuthenticatedScanWizard"; _ck(_v, 2, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
export function View_AssessmentJobEditComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "assessment-job-edit", [], null, null, null, View_AssessmentJobEditComponent_0, RenderType_AssessmentJobEditComponent)), i1.ɵdid(1, 114688, null, 0, i6.AssessmentJobEditComponent, [i7.AssessmentJobService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AssessmentJobEditComponentNgFactory = i1.ɵccf("assessment-job-edit", i6.AssessmentJobEditComponent, View_AssessmentJobEditComponent_Host_0, { assessmentJob: "assessmentJob", readOnly: "readOnly" }, { close: "close" }, []);
export { AssessmentJobEditComponentNgFactory as AssessmentJobEditComponentNgFactory };
