import { DataviewActionColumnsCustomizationConfig } from './dataview-action-columns-customization.component';
import { DataviewActionContentConfig } from './dataview-action-content.component';
import { DataviewActionFilterConfig } from './dataview-action-filter.component';
import { DataviewActionPaginationConfig } from './dataview-action-pagination.component';
import { DataviewActionSearchConfig } from './dataview-action-search.component';
import { DataviewActionButtonConfig } from './dataview-action-button.component';
import { DataviewActionFancySelectConfig } from './dataview-action-fancy-select.component';
import { DataviewActionCustomRangeConfig } from './dataview-action-custom-range.component';

export enum DataviewActionTypes {
	Search,
	Button,
	ColumnsCustomization,
	Content,
	FancySelect,
	Pagination,
	Filter,
	CustomRange,
}

export type DataviewAction =
	| DataviewActionColumnsCustomizationConfig
	| DataviewActionContentConfig
	| DataviewActionFilterConfig
	| DataviewActionPaginationConfig
	| DataviewActionSearchConfig
	| DataviewActionButtonConfig
	| DataviewActionFancySelectConfig
	| DataviewActionCustomRangeConfig;
