import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { alertDeterminationValues } from './alert-determination.entity.values';

@Entity({
	singularName: 'Alert Determination',
	pluralName: 'Alert Determinations',
	values: alertDeterminationValues,
})
export class AlertDetermination extends StatusModel {
	@EntityField({ defaultValue: false })
	combinedClassification?: boolean;
}
