var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityHeaderBottomComponentBase } from './entity-header-bottom.component.base';
import { Machine } from '@wcd/domain';
import { map, startWith, tap } from 'rxjs/operators';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { LiveResponseService } from '../../../@entities/live_response/services/live-response.service';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var LiveResponseSessionHeaderBottomComponent = /** @class */ (function (_super) {
    __extends(LiveResponseSessionHeaderBottomComponent, _super);
    function LiveResponseSessionHeaderBottomComponent(globalEntityTypesService, i18nService, liveResponseService, entityPanelsService, liveAnnouncer) {
        var _this = _super.call(this, globalEntityTypesService, i18nService) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        _this.liveResponseService = liveResponseService;
        _this.entityPanelsService = entityPanelsService;
        _this.liveAnnouncer = liveAnnouncer;
        return _this;
    }
    LiveResponseSessionHeaderBottomComponent.prototype.setEntity = function (entity) {
        var _this = this;
        _super.prototype.setEntity.call(this, entity);
        this.connectionData$ = this.liveResponseService.getSessionUntilDone(entity).pipe(tap(function (session) {
            _super.prototype.setEntity.call(_this, session);
        }), startWith(entity), map(function (session) {
            var tempStatus = _this.i18nService.strings["liveResponse_status_" + session.status.type] || session.status.name;
            if (_this.connectionStatus != tempStatus) {
                _this.connectionStatus = tempStatus;
                _this.liveAnnouncer.announce(_this.connectionStatus);
            }
            return {
                className: session.status.className,
                text: _this.i18nService.strings["liveResponse_status_" + session.status.type] ||
                    session.status.name,
            };
        }));
    };
    LiveResponseSessionHeaderBottomComponent.prototype.openMachinePanel = function () {
        this.entityPanelsService.showEntity(Machine, this.entity.machine);
    };
    return LiveResponseSessionHeaderBottomComponent;
}(EntityHeaderBottomComponentBase));
export { LiveResponseSessionHeaderBottomComponent };
