import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { RemediationCategory } from '../remediation/remediation-category.enum';
import { SoftwareArgs } from './software-args.value-object';
import { SecurityConfigurationArgs } from './security-configuration-args.value-object';
import { ProductivityImpactRemediationType } from '../../remediation-task/remediation-task-user-impact.enum';

@ValueObject({
	singularName: 'Task arguments',
	pluralName: 'Tasks arguments',
})
export class TaskArgs extends ModelBase {
	@EntityField()
	readonly productivityImpactRemediationType?: ProductivityImpactRemediationType;

	@EntityField({
		require: data => data.category === RemediationCategory.Software,
		data: 'softwareArgs',
	})
	readonly softwareArgs?: SoftwareArgs;

	@EntityField({
		require: data => data.category === RemediationCategory.SecurityConfiguration,
		data: 'securityConfigurationArgs',
	})
	readonly securityConfigurationArgs?: SecurityConfigurationArgs;

	@EntityField({
		required: true,
		data: 'category',
	})
	readonly category: RemediationCategory;

	@EntityField({
		data: 'areAssetsManaged',
	})
	readonly areAssetsManaged?: boolean;
}
