import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';

export enum EvaluationMachineStatusType {
	'PendingCreation' = 'PendingCreation',
	'Creating' = 'Creating',
	'Created' = 'Created',
	'ProvisionFailure' = 'ProvisionFailure',
	'PendingPasswordReset' = 'PendingPasswordReset',
	'ExecutingPasswordReset' = 'ExecutingPasswordReset',
	'PasswordResetFailure' = 'PasswordResetFailure',
	'PendingDeletion' = 'PendingDeletion',
	'Deleting' = 'Deleting',
	'Deleted' = 'Deleted',
}

export const evaluationMachineStatusValues: EvaluationMachineStatus[] = [
	{
		id: 'PendingCreation',
		name: 'pendingCreation',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.PendingCreation,
	},
	{
		id: 'Creating',
		name: 'creating',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.Creating,
	},
	{
		id: 'Created',
		name: 'created',
		className: 'ms-color-greenLight',
		type: EvaluationMachineStatusType.Created,
	},
	{
		id: 'ProvisionFailure',
		name: 'provisionFailure',
		className: 'ms-color-red',
		type: EvaluationMachineStatusType.ProvisionFailure,
	},
	{
		id: 'PendingPasswordReset',
		name: 'pendingPasswordReset',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.PendingPasswordReset,
	},
	{
		id: 'ExecutingPasswordReset',
		name: 'executingPasswordReset',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.ExecutingPasswordReset,
	},
	{
		id: 'PasswordResetFailure',
		name: 'passwordResetFailure',
		className: 'ms-color-red',
		type: EvaluationMachineStatusType.PasswordResetFailure,
	},
	{
		id: 'PendingDeletion',
		name: 'pendingDeletion',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.PendingDeletion,
	},
	{
		id: 'Deleting',
		name: 'deleting',
		className: 'ms-color-themeSecondary',
		type: EvaluationMachineStatusType.Deleting,
	},
	{
		id: 'Deleted',
		name: 'deleted',
		className: 'ms-color-neutralTertiary',
		type: EvaluationMachineStatusType.Deleted,
	},
];

@Entity({
	singularName: 'Device status',
	pluralName: 'Device status',
	values: evaluationMachineStatusValues,
})
export class EvaluationMachineStatus extends StatusModel<string> {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() type: EvaluationMachineStatusType;
}
