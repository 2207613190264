/**
 * Represents relation between an entity to its preceding entity
 * For example, the "InitiatingProcess" entity is a "Child" of "InitiatingProcessParent" entity
 */
export enum CyberEventEntityRelationTypeEnum {
	Child, // entity is created by its preceding entity
	Empty, // entity has no preceding entity
	Outer, // entity has an outer-entry link to its preceding entity (for example, entity is an outer url that is used to connect to its preceding entity)
	Parent, // entity is a parent\ancestor entity of its preceding entity (for example, it's a remote machine that is the origin of a login event)
	Sibling, // entity has a sibling\parallel link to its preceding entity
}
