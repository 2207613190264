var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { LegacyUser } from '../legacy/user/legacy-user.entity';
var ɵ0 = function (data) { return (data ? data.split(',').map(function (logonType) { return logonType.trim(); }) : null); };
var MachineUserDetailsAccount = /** @class */ (function (_super) {
    __extends(MachineUserDetailsAccount, _super);
    function MachineUserDetailsAccount() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'AccountName' }),
        __metadata("design:type", String)
    ], MachineUserDetailsAccount.prototype, "name", void 0);
    __decorate([
        EntityField({ data: 'AccountDomainName' }),
        __metadata("design:type", String)
    ], MachineUserDetailsAccount.prototype, "domain", void 0);
    __decorate([
        EntityField({ defaultValue: null, data: 'IsLocalAdmin' }),
        __metadata("design:type", Boolean)
    ], MachineUserDetailsAccount.prototype, "isLocalAdmin", void 0);
    __decorate([
        EntityField({ data: '__self' }),
        __metadata("design:type", LegacyUser)
    ], MachineUserDetailsAccount.prototype, "user", void 0);
    __decorate([
        EntityField({ data: 'FirstSeen' }),
        __metadata("design:type", Date)
    ], MachineUserDetailsAccount.prototype, "firstSeen", void 0);
    __decorate([
        EntityField({ data: 'LastSeen' }),
        __metadata("design:type", Date)
    ], MachineUserDetailsAccount.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({ data: 'LoginCount' }),
        __metadata("design:type", Number)
    ], MachineUserDetailsAccount.prototype, "loginCount", void 0);
    __decorate([
        EntityField({
            data: 'LogonTypes',
            parse: ɵ0,
        }),
        __metadata("design:type", Array)
    ], MachineUserDetailsAccount.prototype, "logonTypes", void 0);
    MachineUserDetailsAccount = __decorate([
        ValueObject({
            singularName: 'Device User Details Account',
            pluralName: 'Device User Details Account',
            readonly: true,
        })
    ], MachineUserDetailsAccount);
    return MachineUserDetailsAccount;
}(ModelBase));
export { MachineUserDetailsAccount };
export { ɵ0 };
