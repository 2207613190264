import { ChangeEventEntityTypeModule } from './@entities/@tvm/events/change-event.entity-type.module';
import { AngularReactBrowserModule } from '@angular-react/core';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SecurityRecommendationEntityTypeModule } from './@entities/@tvm/security-recommendations/security-recommendation.entity-type.module';
import { SoftwareInventoryEntityTypeModule } from './@entities/@tvm/software-inventory/software-inventory.entity-type.module';
import { VulnerabilityEntityTypeModule } from './@entities/@tvm/vulnerabilities/vulnerability.entity-type.module';
import { AirsEntitiesModule } from './@entities/airs_entities/airs-entities.module';
import { AlertsModule } from './@entities/alerts/alerts.module';
import { CustomTiIndicatorsEntityTypeModule } from './@entities/custom_ti_indicators/custom-ti-indicators.entity-type.module';
import { CyberEventsModule } from './@entities/cyber_events/cyber-events.module';
import { FileEntityTypeModule } from './@entities/files/file.entity-type.module';
import { DomainsEntityTypeModule } from './@entities/domains/domains.entity-type.module';
import { IncidentEntityTypeModule } from './@entities/incidents/incident.entity-type.module';
import { InvestigationsModule } from './@entities/investigations/investigations.module';
import { InvestigationsCommonModule } from './@entities/investigations_common/investigations-common.module';
import { MtpInvestigationsModule } from './@entities/mtp_investigations/mtp-investigations.module';
import { InvestigationActionsModule } from './@entities/investigation_actions/investigation-actions.module';
import { IpEntityTypeModule } from './@entities/ips/ip.entity-type.module';
import { MachineEntityTypeModule } from './@entities/machines/machine.entity-type.module';
import { NetworkEndpointEntityTypeModule } from './@entities/network_endpoints/network-endpoint.entity-type.module';
import { ProcessEntityTypeModule } from './@entities/processes/process.entity-type.module';
import { RbacEntitiesModule } from './@entities/rbac/rbac-entities.module';
import { RegistryEntityTypeModule } from './@entities/registry/registry.entity-type.module';
import { RemediationModule } from './@entities/remediation/remediation.module';
import { ScheduledHuntingModule } from './@entities/scheduled_hunting/scheduled-hunting.module';
import { SuppressionRulesModule } from './@entities/suppression_rules/suppression-rules.module';
import { UrlEntityTypeModule } from './@entities/urls/url.entity-type.module';
import { AdvancedSettingsModule } from './admin/advanced/advanced-settings.module';
import { AuthModule } from '@wcd/auth';
import { CommunityModule } from './community/community.module';
import { ConfigModule, FlavorService } from '@wcd/config';
import { DataViewsModule } from './dataviews/dataviews.module';
import { DialogsModule } from './dialogs/dialogs.module';
import { ErrorModule } from './error/error.module';
import { GlobalEntitiesModule } from './global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from './global_entities/global-entity-panels.module';
import { I18nModule } from '@wcd/i18n';
import { AppInsightsModule } from './insights/insights.module';
import { OnboardingModule } from './onboarding/onboarding.module';
import { PreloadModule } from './preloader/preload.module';
import { MainAppStateService } from './shared/main/services/main-app-state.service';
import { SharedModule } from './shared/shared.module';
import { OutbreakEntityTypeModule } from './@entities/outbreaks/outbreak.entity-type.module';
import { ActionHistoryEntityTypeModule } from './@entities/action_center/action-history.entity-type.module';
import { SoftwareInstallationEntityTypeModule } from './@entities/@tvm/software-inventory/software-installation.entity-type.module';
import { SoftwareInstallationAggEntityTypeModule } from './@entities/@tvm/software-inventory/software-installation-agg.entity-type.module';
import { LiveResponseSessionEntityTypeModule } from './@entities/live_response/live-response-session.entity-type.module';
import { PendingActionsHistoryEntityTypeModule } from './@entities/action_center/pending-actions-history.entity-type.module';
import { TelemetryModule } from '@wcd/telemetry';
import { UserEntityTypeModule } from './@entities/users/user.entity-type.module';
import { RemediationTaskEntityTypeModule } from './@entities/@tvm/remediation/remediation-tasks/remediation-task.entity-type.module';
import { RecommendationExceptionEntityTypeModule } from './@entities/@tvm/remediation/recommendation-exceptions/recommendation-exception.entity-type.module';
import { LiveResponseCommandEntityTypeModule } from './@entities/live_response/live-response-command.entity-type.module';
import { MissingKbEntityTypeModule } from './@entities/@tvm/software-inventory/missing-kb.entity-type.module';
import { PartnersEntityModule } from './interoperability/partners/partners.entity.module';
import { EvaluationPasswordModule } from './evaluation/evaluation.password.module';
import { WebContentFilteringPolicyEntityTypeModule } from './@entities/web_content_filtering/web-content-filtering-policy.entity-type.module';
import { AadUserEntityTypeModule } from './@entities/AAD/users/aad-user.entity-type.module';
import { WebContentFilteringReportEntityTypeModule } from './@entities/web_content_filtering/web-content-filtering-report.entity-type.module';
import { MailboxEntityTypeModule } from './@entities/mailboxes/mailbox.entity-type.module';
import { PanelsModule } from '@wcd/panels';
import { FeedbackModule } from './feedback/feedback.module';
import { EvaluationEntityTypeModule } from './evaluation/evaluation.entity-type.module';
import { ComparisonResultEntityTypeModule } from './@entities/@tvm/comparison-tool/comparison-result.entity-type.module';
import { AssetEntityTypeModule } from './@entities/@tvm/asset/asset.entity-type.module';
import { HuntingRecordModule } from './@entities/hunting_query_result_record/hunting-record.module';
import { SoftwareVersionEntityTypeModule } from './@entities/@tvm/software-inventory/software-version.entity-type.module';
import { HuntingDocumentationModule } from './@entities/hunting_documentation/hunting-documentation.module';
import { OfficeIntegrationService } from './admin/services/office-integration.service';
import { ItsmProvisioningModule } from './@entities/@tvm/remediation/itsm-provisioning/itsm-provisioning.module';
import { IntegrationSettingsModule } from './admin/integration-settings/integration-settings.module';
import { DataExportModule } from './@entities/data_export/data-export.module';
import { AuthenticatedScansModule } from './@entities/@tvm/authenticated-scans/authenticated-scans.module';
import { NotificationRulesModule } from './admin/notification_rules/notification-rules.module';
import { AirsNotificationRulesModule } from './admin/airs_notification_rules/airs-notification-rules.module';
import { SystemExclusionsModule } from './@entities/system_exclusions/system-exclusions.module';
import { FolderExclusionsModule } from './admin/folder_exclusions/folder-exclusions.module';
import { ServicesModule } from './@entities/airs_services/services.module';
import { EndpointManagementModule } from './endpoint_management/endpoint-management.module';
import { SecurityAnalyticsSettingsModule } from './admin/security-analytics/security-analytics-settings.module';
import { GeneralSettingsModule } from './admin/general/general-settings.module';
import { CommonRemediationModule } from './@entities/@tvm/remediation/common-remediation-service/common-remediation.module';
import { NetworkDevicesEntityTypeModule } from './@entities/@tvm/network-devices/network-devices.entity-type.module';
import { FlagsModule } from './flags/flags.module';
import { CommandLineModule } from './@entities/command_line/command-line.module';
import { VulnerabilityNotificationRuleEntityTypeModule } from './@entities/@tvm/vulnerability_notifications_rules/vulnerability-notification-entity-type.module';
import { ProfileEntityTypeModule } from './@entities/@tvm/baseline-compliance/profiles-tab/profile.entity-type.module';
import { ProfileConfigurationEntityTypeModule } from './@entities/@tvm/baseline-compliance/profiles-tab/configurations/profile-configuration.entity-type.module';
import { ProfileDeviceEntityTypeModule } from './@entities/@tvm/baseline-compliance/profiles-tab/devices/profile-device.entity-type.module';
import { ApplicationEntityTypeModule } from './@entities/applications/application.entity-type.module';

export const imports: any[] = [
	AngularReactBrowserModule,
	BrowserAnimationsModule,
	HttpClientModule,
	AuthModule.forRoot(),
	I18nModule.forRoot(),
	PanelsModule.forRoot(),
	DialogsModule.forRoot(),
	SharedModule.forRoot(),
	InvestigationActionsModule.forRoot(),
	DataViewsModule.forRoot(),
	PreloadModule,
	FeedbackModule,
	ConfigModule.forRoot(),
	ErrorModule,
	InvestigationsModule.forRoot(),
	InvestigationsCommonModule,
	MtpInvestigationsModule.forRoot(),
	RemediationModule.forRoot(),
	AirsEntitiesModule,
	AdvancedSettingsModule,
	AlertsModule,
	MachineEntityTypeModule,
	ActionHistoryEntityTypeModule,
	PendingActionsHistoryEntityTypeModule,
	FileEntityTypeModule,
	LiveResponseSessionEntityTypeModule,
	LiveResponseCommandEntityTypeModule,
	UserEntityTypeModule,
	AppInsightsModule,
	OnboardingModule,
	SuppressionRulesModule,
	CommunityModule,
	IncidentEntityTypeModule.forRoot(),
	VulnerabilityEntityTypeModule,
	ComparisonResultEntityTypeModule,
	DomainsEntityTypeModule,
	SoftwareInventoryEntityTypeModule,
	ProfileEntityTypeModule,
	ProfileConfigurationEntityTypeModule,
	ProfileDeviceEntityTypeModule,
	RemediationTaskEntityTypeModule,
	RecommendationExceptionEntityTypeModule,
	SecurityRecommendationEntityTypeModule,
	SoftwareInstallationEntityTypeModule,
	SoftwareInstallationAggEntityTypeModule,
	SoftwareVersionEntityTypeModule,
	MissingKbEntityTypeModule,
	CustomTiIndicatorsEntityTypeModule,
	RbacEntitiesModule,
	ScheduledHuntingModule,
	GlobalEntitiesModule.forRoot(),
	GlobalEntityPanelsModule.forRoot(),
	CyberEventsModule,
	ProcessEntityTypeModule,
	UrlEntityTypeModule,
	IpEntityTypeModule,
	OutbreakEntityTypeModule,
	NetworkEndpointEntityTypeModule,
	RegistryEntityTypeModule,
	TelemetryModule,
	PartnersEntityModule,
	EvaluationPasswordModule,
	WebContentFilteringPolicyEntityTypeModule,
	WebContentFilteringReportEntityTypeModule,
	AadUserEntityTypeModule,
	MailboxEntityTypeModule,
	ApplicationEntityTypeModule,
	EvaluationEntityTypeModule,
	ChangeEventEntityTypeModule,
	AssetEntityTypeModule,
	HuntingRecordModule,
	HuntingDocumentationModule,
	SharedModule,
	AirsNotificationRulesModule,
	SystemExclusionsModule,
	FolderExclusionsModule,
	AdvancedSettingsModule,
	RemediationModule,
	ServicesModule,
	AlertsModule,
	SuppressionRulesModule,
	EndpointManagementModule,
	IntegrationSettingsModule,
	SecurityAnalyticsSettingsModule,
	NotificationRulesModule,
	GeneralSettingsModule,
	DataExportModule,
	ItsmProvisioningModule,
	AuthenticatedScansModule,
	CommonRemediationModule,
	NetworkDevicesEntityTypeModule,
	FlagsModule,
	CommandLineModule,
	VulnerabilityNotificationRuleEntityTypeModule,
];

export const providers: any[] = [
	MainAppStateService,
	{
		provide: APP_BASE_HREF,
		useValue: '/',
	},
	OfficeIntegrationService,
	FlavorService,
];

@NgModule({
	imports: imports,
})
export class AppModuleCommons {}
