import { EventEmitter, Injector, OnDestroy, OnInit, ElementRef, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { CustomTiIndicatorsType, ImportTiIndicatorsApiCall, } from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
var MAX_CSV_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;
var CustomTiIndicatorImportComponent = /** @class */ (function () {
    function CustomTiIndicatorImportComponent(paris, injector, i18nService) {
        this.paris = paris;
        this.injector = injector;
        this.i18nService = i18nService;
        this.done = new EventEmitter();
        this.import = new EventEmitter();
        this.isSaving = false;
        this.showInvalidCsvFileSizeMessage = false;
    }
    CustomTiIndicatorImportComponent.prototype.ngOnInit = function () {
        this.dialogsService = this.injector.get(DialogsService);
        this.customTiIndicatorsService = this.injector.get(CustomTiIndicatorsService);
    };
    CustomTiIndicatorImportComponent.prototype.ngOnDestroy = function () {
        this._importSubscription && this._importSubscription.unsubscribe();
    };
    CustomTiIndicatorImportComponent.prototype.onImport = function () {
        var _this = this;
        this.isSaving = true;
        this._importSubscription = this.paris
            .apiCall(ImportTiIndicatorsApiCall, this._importTiIndicators)
            .subscribe(function (results) {
            _this.isSaving = false;
            _this.totalCustomTiParsed = 0;
            _this.successfullyImported = 0;
            if (results) {
                _this.totalCustomTiParsed = results.length;
                _this.importFailures = results.filter(function (importReslt) { return importReslt.isFailed; });
                _this.successfullyImported = _this.totalCustomTiParsed - _this.importFailures.length;
                _this.import.emit(_this.successfullyImported > 0);
            }
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('customTiIndicator.dataview.actions.import.failed'),
                data: error,
            });
        });
    };
    CustomTiIndicatorImportComponent.prototype.downloadCsvSample = function () {
        this.customTiIndicatorsService.downloadCsv({
            type: this.customTiIndicatorType.id,
            downloadImportCsvSample: true,
        });
    };
    CustomTiIndicatorImportComponent.prototype.onFileSelect = function (event) {
        var files = event.srcElement.files;
        var file = files && files[0];
        if (!!file) {
            if (file.size > MAX_CSV_FILE_SIZE_IN_BYTES) {
                this.showInvalidCsvFileSizeMessage = true;
                this.importCsvFileInput.nativeElement.value = null;
            }
            else {
                this.showInvalidCsvFileSizeMessage = false;
                this._importTiIndicators = {
                    file: file,
                    type: this.customTiIndicatorType.id,
                };
            }
        }
    };
    Object.defineProperty(CustomTiIndicatorImportComponent.prototype, "isValid", {
        get: function () {
            return !!(this._importTiIndicators && this._importTiIndicators.file);
        },
        enumerable: true,
        configurable: true
    });
    return CustomTiIndicatorImportComponent;
}());
export { CustomTiIndicatorImportComponent };
