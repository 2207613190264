import { Entity, EntityField, DataQuery } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { SoftwareInstallationBase } from './software-installation-base.entity';
import { OsDistribution } from '../common/os-distribution.enum';

// TODO: there is a bug in paris - use of idProperty: 'assetId' has no affect and the id is null
@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	//enriching the query with the Asset's exposure score:
	parseDataQuery: (dataQuery: DataQuery) => ({
		...TvmAnalyticsSharedEntityConfigurations.parseDataQuery(dataQuery),
		$expand: 'Asset($select=ExposureScore)',
	}),
	singularName: 'installation',
	pluralName: 'installations',
	endpoint: 'installations/aggregate',
	readonly: true,
})
export class SoftwareInstallationAgg extends SoftwareInstallationBase {
	@EntityField({
		required: true,
		parse: (_, item) => `${item.assetId}-${item.productName}-${item.vendor}`,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	readonly id: string;

	@EntityField({
		required: true,
	})
	readonly installedVersions: string[];

	@EntityField({
		data: 'rawInfo.rawProgram',
	})
	readonly description: string;

	@EntityField()
	readonly ipAddress: string;

	@EntityField()
	readonly osDistribution?: OsDistribution;

	@EntityField()
	readonly totalUsageInDays: number;
}
