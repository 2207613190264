/* tslint:disable:template-click-events-have-key-events */
import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { MessageBarType } from 'office-ui-fabric-react';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import {
	Incident,
	AssociatedIncidentResponse,
	IncidentAssociatedIncidentsApiCallSample,
	RelatedIncident,
} from '@wcd/domain';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { AssociatedIncidentsPanelComponent } from './associated-incidents-panel/associated-incidents-panel.component';
import { AssociatedIncidentsFields } from './incident.associated-incidents.fields';

@Component({
	providers: [AssociatedIncidentsFields],
	selector: 'incident-associated-incidents',
	encapsulation: ViewEncapsulation.None,
	templateUrl: './incident.associated-incidents.component.html',
})
export class IncidentAssociatedIncidentsComponent implements OnInit {
	@Input() incident: Incident;
	readonly MessageBarType = MessageBarType;
	associatedIncidents$: Observable<RelatedIncident[]>;
	hasMore: boolean;

	constructor(
		public associatedIncidentsFields: AssociatedIncidentsFields,
		private readonly dialogsService: DialogsService,
		private readonly paris: Paris
	) {}

	ngOnInit() {
		this.associatedIncidents$ = this.paris
			.apiCall<any, any>(IncidentAssociatedIncidentsApiCallSample, { id: this.incident.id })
			.pipe(
				tap((response: AssociatedIncidentResponse) => {
					this.hasMore = response.hasMore;
				}),
				map((response: AssociatedIncidentResponse) => response.relatedIncidents)
			);
	}

	showAllIncidents() {
		this.dialogsService.showPanel(
			AssociatedIncidentsPanelComponent,
			{
				id: 'associated-incidents-panel',
				type: PanelType.large,
				isBlocking: false,
				scrollBody: false,
			},
			{
				sourceValue: this.incident,
			}
		);
	}

	isItemClickable(entity: any): boolean {
		return false;
	}
}
