import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, shareReplay, startWith } from 'rxjs/operators';
import { EntityModelBase } from '@microsoft/paris';
import { compact, uniqueId } from 'lodash-es';
import { ICommandBarItemOptionsRenderContext } from '@angular-react/fabric';
import { ItemActionModel, ItemActionType } from '../../../dataviews/models/item-action.model';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { CommandBarItemService } from '../../../shared/components/command-bar/command-bar-item.service';
import { ICommandBarItem } from '../../../shared/components/command-bar/models/command-bar-item.models';
import { toObservable } from '../../../utils/rxjs/utils';
import { EntityType } from '../../models/entity-type.interface';
import { EntityViewType } from '../../models/entity-view-type.enum';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { RoutesService } from '@wcd/shared';
import { Router } from '@angular/router';
import { Tag } from '@wcd/domain';
import { ICommandBarStyles } from 'office-ui-fabric-react';
import { EntityCommandBarDisplayMode } from '../../models/entity-command-bar-display-mode.enum';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';

const overFlowCommandBarHeight = 36;
const overFlowCommandBarStyles: ICommandBarStyles = {
	root: {
		height: overFlowCommandBarHeight,
		padding: 0,
	},
};

// make link look like a button, and override SCC underline styles
const buttonStyles = {
	rootHovered: {
		textDecoration: 'none !important',
	},
	rootFocused: {
		textDecoration: 'none !important',
	},
	rootDisabled: {
		pointerEvents: 'inherit',
	},
};

interface LinkConfig {
	readonly text: string;
	readonly tooltip: string;
	readonly path: string;
	readonly isExternal: boolean;
	readonly disabled: boolean;
}

interface actionEventEmitter {
	readonly action: ItemActionModel;
	readonly data: any;
	readonly tags: Array<Tag>;
}

@Component({
	selector: 'entity-command-bar',
	templateUrl: './entity-command-bar.component.html',
	styleUrls: ['./entity-command-bar.component.scss'],
})
export class EntityCommandBarComponent<
	TEntity extends EntityModelBase<TId>,
	TId extends string | number = string
> implements OnInit, OnChanges, OnDestroy {
	@Input() entityType: EntityType<TEntity>;
	@Input() entity: TEntity;
	@Input() entities: Array<TEntity>;
	@Input() options: any;
	@Input() isLoadingEntitySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() lastSetActionsTime: Date;
	@Input() detailsActionId: string;
	@Input() lastDetailsActionTime: Date;
	@Input() loadEntityError: boolean = false;
	@Input() isUserExposed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	@Input() overFlowActionsView: boolean = false;
	@Input() entityCommandBarDisplayMode: EntityCommandBarDisplayMode = null;

	@Output() commandBarAction: EventEmitter<actionEventEmitter> = new EventEmitter<actionEventEmitter>();

	readonly isRunningAction$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	commandBarItems$: Observable<Array<ICommandBarItem>>;
	tags$: Observable<ReadonlyArray<Tag>>;
	overFlowCommandBarStyles: ICommandBarStyles = overFlowCommandBarStyles;

	private actions$: Observable<ReadonlyArray<ItemActionModel>>;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();

	@ViewChild('tagsItem', { read: TemplateRef, static: true })
	tagsItemTemplateRef: TemplateRef<ICommandBarItemOptionsRenderContext>;

	tagEditOpen: boolean = false;
	private destroyed = false;

	constructor(
		protected router: Router,
		private readonly commandBarItemService: CommandBarItemService,
		private readonly routesService: RoutesService,
		private readonly activatedEntity: ActivatedEntity,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly i18nService: I18nService
	) {}

	get isSingleEntity(): boolean {
		return this.entities && this.entities.length === 1;
	}

	ngOnInit() {
		this.setActions();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.lastSetActionsTime || changes.options) {
			this.setActions();
		}

		if (changes.lastDetailsActionTime) {
			this.runActionById(this.detailsActionId);
		}

		if (changes.entities) {
			this.updateTags();
		}

		this.changeDetectorRef.markForCheck();
	}

	ngOnDestroy() {
		this.destroyed = true;
	}

	private updateTags() {
		if (this.entityType.getTags) {
			this.tags$ = merge(toObservable(this.entityType.getTags(this.entities)), this._tagsSubject$).pipe(
				shareReplay(1)
			);
		}
	}

	setActions() {
		this.actions$ = toObservable(
			this.entityType.getActions
				? this.entityType.getActions(
						this.entities,
						this.options,
						this.entities.length === 1
							? EntityViewType.SingleEntitySidePanel
							: EntityViewType.MultipleEntitySidePanel,
						this.entityCommandBarDisplayMode || EntityCommandBarDisplayMode.Default
				  ) || []
				: []
		).pipe(
			catchError((err) => {
				return of([]);
			})
		);

		// The item actions, disabled and with a progress cursor if needed, based on the loading state of the entity in the panel or if an action is currently running.
		const disableOverrideConsideredActions$ = combineLatest(
			this.actions$,
			this.isLoadingEntitySubject$,
			this.isRunningAction$
		).pipe(
			map(([itemActions, isLoadingEntity, isRunningAction]) => {
				const newItemActions = itemActions.filter((item) => item.type != ItemActionType.Hidden);
				return newItemActions && newItemActions.length
					? newItemActions.map<ItemActionModel>((itemAction: ItemActionModel) => ({
							...itemAction,
							disabled:
								itemAction.disabled ||
								(!itemAction.allowDisplayWhileLoading && isLoadingEntity) ||
								isRunningAction,
							buttonClass: isRunningAction && 'cursor-progress',
							tooltip:
								itemAction.tooltip ||
								itemAction.name ||
								this.i18nService.get(itemAction.nameKey),
					  }))
					: [];
			})
		);

		const actionsCommandBarItems$ = disableOverrideConsideredActions$.pipe(
			map((itemActions) => itemActions.map((itemAction) => this.actionToCommandBarItem(itemAction)))
		);

		this.commandBarItems$ = actionsCommandBarItems$.pipe(
			startWith([]),
			map((actionsCommandBarItems) => compact([this.openItemCommandBarItem, ...actionsCommandBarItems]))
		);
	}

	private actionToCommandBarItem(itemAction: ItemActionModel): ICommandBarItem {
		return this.commandBarItemService.fromItemActionModel({
			itemActionModel: itemAction,
			tagsTemplateRef: this.tagsItemTemplateRef,
			onClick: (itemActionModel) => {
				this.runAction(itemActionModel);
			},
		});
	}

	private get openItemCommandBarItem(): ICommandBarItem {
		if (
			this.entityType &&
			this.entityType.getShowOpenPageLink &&
			!this.entityType.getShowOpenPageLink(this.entity)
		) {
			return null;
		}

		if (this.entityType && this.entityType.getItemParams) {
			try {
				const { hideOpenLink } = <any>this.entityType.getItemParams(this.entities[0]);
				if (hideOpenLink && hideOpenLink()) {
					return null;
				}
			} catch (e) {}
		}

		if (this.options && this.options.hideOpenLink) {
			return null;
		}

		const entitiesLink = this.entityType.getEntitiesLink
			? this.entityType.getEntitiesLink(this.entities)
			: null;

		const entityName = this.isSingleEntity
			? (this.entityType.entitySingularNameKey &&
					this.i18nService.get(this.entityType.entitySingularNameKey)) ||
			  this.entityType.entity.singularName.toLowerCase()
			: (this.entityType.entityPluralNameKey &&
					this.i18nService.get(this.entityType.entityPluralNameKey)) ||
			  this.entityType.entity.pluralName.toLowerCase();

		const entitiesLinkText =
			entitiesLink && this.entityType.getEntitiesLinkText
				? this.entityType.getEntitiesLinkText(this.entities)
				: this.i18nService.get('entityCommon_commandBar_openPage', {
						entity: entityName.toLowerCase(),
				  });

		const entitiesLinkTooltip = this.loadEntityError ?
			this.i18nService.get('entityCommon_commandBar_openPage_unavailble', {
				entity: entityName.toLowerCase(),
			}) : entitiesLink && this.entityType.getEntitiesLinkTooltip
				? this.entityType.getEntitiesLinkTooltip(this.entities)
				: entitiesLinkText;

		const entitiesLinkDisabled = this.loadEntityError || (this.entityType.getEntitiesLinkDisabled
			? this.entityType.getEntitiesLinkDisabled(this.entities)
			: false);

		let urlInContext: string = entitiesLink;
		let isExternal: boolean = false;
		if (urlInContext) {
			if (
				this.entityType.getNavigationModel &&
				this.entityType.getNavigationModel(this.entities[0]) &&
				this.entityType.getNavigationModel(this.entities[0]).sccInternalRedirectDefinition
			) {
				const navModel = this.entityType.getNavigationModel(this.entities[0]);
				const sccRouteDef = navModel.sccInternalRedirectDefinition(navModel.externalLink);
				urlInContext = sccRouteDef;
			} else {
				isExternal =
					this.entityType.getUseExternalRouting && this.entityType.getUseExternalRouting(this.entities);

				const mdatpFromSccUrl = !isExternal && this.routesService.getMdatpFromSccLinkConfig(entitiesLink);
				if (mdatpFromSccUrl) {
					urlInContext = mdatpFromSccUrl.url;
					isExternal = mdatpFromSccUrl.isExternal;
				}
			}
		}

		return this.createOpenEntityCommandBarItem({
			text: entitiesLinkText,
			tooltip: entitiesLinkTooltip,
			path: urlInContext,
			isExternal: isExternal,
			disabled: entitiesLinkDisabled,
		});
	}

	private createOpenEntityCommandBarItem(linkConfig: LinkConfig): ICommandBarItem | null {
		if (!linkConfig || !linkConfig.path) {
			return null;
		}

		let openEntityCommandBarItem: ICommandBarItem = {
			key: uniqueId('openEntityLink'),
			text: linkConfig.text,
			title: linkConfig.tooltip,
			iconProps: {
				iconName: FabricIconNames.PageRight,
			},
			buttonStyles,
			disabled: linkConfig.disabled,
		};

		openEntityCommandBarItem = this.commandBarItemService.withRouting(openEntityCommandBarItem, {
			link: [linkConfig.path],
			isExternal: linkConfig.isExternal,
		});

		openEntityCommandBarItem = this.commandBarItemService.withTracking(openEntityCommandBarItem, {
			id: `EntityPanelLinkToEntityPage_${linkConfig.text}`,
			type: TrackingEventType.Navigation,
		});

		return openEntityCommandBarItem;
	}

	runActionById(actionId: string): void {
		if (!actionId) return;

		this.actions$
			.pipe(
				map((actions) => actions.find((action) => action.id === actionId)),
				filter((action) => Boolean(action))
			)
			.subscribe((action) => this.runAction(action));
	}

	async runAction(action: ItemActionModel) {
		this.isRunningAction$.next(true);

		try {
			const data = await action.method(this.entities, this.options);
			this.commandBarAction.emit({ action, data, tags: null });
		} finally {
			this.isRunningAction$.next(false);
			if (!this.destroyed) {
				this.changeDetectorRef.detectChanges();
			}
		}
	}

	onEntitiesLinkClick() {
		if (this.isSingleEntity) {
			const entity = this.entity || (this.entities && this.entities[0]);
			this.activatedEntity.setNextEntity(entity);
		}
	}

	async onChangeTags(tags: Array<Tag>, action: ItemActionModel) {
		this.isRunningAction$.next(true);
		this._tagsSubject$.next(tags);
		this.changeDetectorRef.markForCheck();
		this.commandBarAction.emit({ action, data: null, tags });
		this.isRunningAction$.next(false);
	}

	tagsEditorToggled(open) {
		this.tagEditOpen = open;
	}
}
