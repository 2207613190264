import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	forwardRef,
	Input,
	OnChanges,
	Output,
	ViewChild,
	SimpleChanges,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from './dropdown.component';
import { Disableable, DISABLEABLE_TOKEN } from '../models/disableable.interface';
import { find, get, isObject } from 'lodash-es';
import { valuesAreEqual } from './form-component.shared';
import { Positions } from '@wcd/forms';

let lastId = 0;

@Component({
	selector: 'wcd-select',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<span
			[attr.id]="dropdownId"
			[ngClass]="{ 'disabled-not-allowed': isDisabled }"
			[wcdTooltip]="isDisabled && disableReason"
		>
			<label *ngIf="dropdownLabel" class="dropdown-label">{{ dropdownLabel }}</label>
			<wcd-dropdown
				[buttonText]="useValueAsButtonText && value ? getLabel(value, false) : placeholder"
				[buttonClass]="labelClass"
				[buttonTooltip]="tooltip"
				[buttonIconClass]="getAttributeValue(value, iconColorClassName)"
				[buttonImage]="getAttributeValue(value, image) || buttonImage"
				[buttonIcon]="getAttributeValue(value, icon) || buttonIcon"
				(focus)="focus.emit()"
				(blur)="blur.emit()"
				[closeOnClick]="true"
				[isBordered]="isBordered"
				[disabled]="isDisabled"
				[isFullWidth]="isFullWidth"
				[isBordered]="isBordered"
				[showIconDropdown]="showIconDropdown"
				[ariaLabel]="ariaLabel"
				[navigateUsingArrowKeysOnly]="true"
				[optionAriaSetSize]="values?.length || 0"
				optionAriaRole="option"
				[optionAriaPositionInSet]="ariaPositinInSet"
				[ariaRole]="ariaRole"
				[buttonId]="buttonId"
				[position]="openMenuPosition"
				ariaHaspopup="listbox"
				(onToggle)="toggleSelectorState($event)"
			>
				<ul
					class="wcd-dropdown-list dropdown-list"
					[attr.data-test-id]="dropdownId + '-menu'"
					role="listbox"
					[attr.aria-labelledby]="buttonId + '_ariaLabel'"
				>
					<li
						*ngFor="let _value of values; let valueIndex = index"
						(click)="selectValue(_value)"
						[wcdTooltip]="getAttributeValue(_value, itemTooltip)"
						[attr.data-test-id]="getAttributeValue(_value, itemTestId)"
						[class.selected]="_value === value"
						[ngClass]="getAttributeValue(_value, optionClass) || ''"
						#accessibleListItem
						role="option"
						tabindex="-1"
						[attr.aria-selected]="_value === value"
						[attr.aria-posinset]="valueIndex + 1"
						[attr.aria-setsize]="values.length"
					>
						<fab-icon
							*ngIf="icon"
							[iconName]="getAttributeValue(_value, icon)"
							[contentClass]="
								'wcd-dropdown-list--icon ' + getAttributeValue(_value, iconColorClassName)
							"
						></fab-icon>
						<img
							*ngIf="image"
							[attr.src]="getAttributeValue(_value, image)"
							class="wcd-dropdown-list--icon"
						/>
						{{ getLabel(_value, true) }}
					</li>
				</ul>
			</wcd-dropdown>
		</span>
	`,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectComponent),
			multi: true,
		},
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => SelectComponent),
		},
	],
})
export class SelectComponent<TValue = any> implements ControlValueAccessor, OnChanges, Disableable {
	@Input() dropdownLabel: string;
	@Input() label: string;
	@Input() labelClass: string;
	@Input() formatLabel: (value: TValue, isSelectionItem?: boolean) => string;
	@Input() placeholder: string;
	@Input() image: string;
	@Input() buttonIcon: string;
	@Input() buttonImage: string;
	@Input() icon: string;
	@Input() iconColorClassName: string;
	@Input() tooltip: string;
	@Input() itemTooltip: string;
	@Input() optionClass: string;
	@Input() itemTestId: string;
	@Input() values: Array<TValue>;
	@Input() dropdownId: string;
	@Input() showIconDropdown = true;
	@Input() trackBy: string;
	@Input() useValueAsButtonText = true;
	@Input() isDisabled = false;
	@Input() isFullWidth = false;
	@Input() isBordered = true;
	@Input() ariaLabel?: string;
	@Input() ariaRole = 'listbox';
	@Input() openMenuPosition: Positions = Positions.Default;
	@Input() defaultSelectedIndex = null;

	@Output() focus: EventEmitter<void> = new EventEmitter<void>();
	@Output() blur: EventEmitter<void> = new EventEmitter<void>();
	@Output() selectorStateChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild(DropdownComponent, { static: false }) dropdown: DropdownComponent;

	value: TValue;
	disableReason: string;

	buttonId = `select_button_${lastId++}`;
	ariaPositinInSet: number = 0;

	private _tempValue: TValue;

	constructor(private changeDetectionRef: ChangeDetectorRef) {}

	ngOnChanges(changes: SimpleChanges) {
		if (changes.values) this.writeValue(this._tempValue || this.value);
	}

	toggleSelectorState(isOpen: boolean) {
		if(isOpen && !this.value && this.defaultSelectedIndex != null && this.values && this.values[this.defaultSelectedIndex]){
			this.value = this.values[this.defaultSelectedIndex];
			this.ariaPositinInSet = this.defaultSelectedIndex + 1;
			this.changeDetectionRef.markForCheck();
		}
		this.selectorStateChanged.emit(isOpen);
	}

	selectValue(value: TValue, index: number = 0) {
		this.value = value;
		this.ariaPositinInSet = index + 1;
		this.onChange(this.value);
	}

	getAttributeValue(_value: TValue, attributePath: string) {
		if (!attributePath) return null;

		return isObject(_value) ? get(_value, attributePath) : _value;
	}

	getLabel(value: TValue, isSelectionItem?: boolean): string {
		if (this.formatLabel) return this.formatLabel(value, isSelectionItem);

		return this.label ? this.getAttributeValue(value, this.label) : String(value);
	}

	onChange = (_: TValue) => {};
	onTouched = () => {};

	writeValue(value: TValue): void {
		const existingValue: TValue = this.getValue(value);

		if (existingValue) this.value = existingValue;
		else if (!isObject(value) && this.trackBy && this.values)
			this.value = find(this.values, _value => _value[this.trackBy] === value);

		if (this.value) {
			if (!value) this.value = null;
		} else {
			if (!this.values) this._tempValue = value;
			else this.value = null;
		}
		this.ariaPositinInSet = this.getValuePosition();
		this.changeDetectionRef.markForCheck();
	}

	registerOnChange(fn: (_: TValue) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	toggle() {
		this.dropdown.toggle();
	}

	getValuePosition(): number {
		if (!this.value) return 0;
		return this.values.indexOf(this.value) + 1;
	}

	private getValue(value: TValue): TValue {
		if (!this.values || value === undefined || value === null) return null;

		return find(this.values, _value => {
			if (this.trackBy && isObject(value) && isObject(_value))
				return value[this.trackBy] === _value[this.trackBy];

			return valuesAreEqual(_value, value);
		});
	}
}
