import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';
import { Observable } from 'rxjs';
import { ProductEvidence } from '@wcd/domain';
import { Paris } from '@microsoft/paris';

@Component({
	selector: 'product-evidence-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './product-evidence.details.component.html',
})
export class ProductEvidenceDetailsComponent {
	SpinnerSize = SpinnerSize;
	@Input() productId: string;
	@Input() productVersion: string;
	@Input() assetId: string;
	@Input() nonCpeProgram: boolean;
	evidence$: Observable<ProductEvidence>;

	constructor(private paris: Paris) {}

	ngOnInit() {
		this.evidence$ = this.paris.getItemById(ProductEvidence, this.productId, null, {
			assetId: this.assetId,
			productVersion: this.productVersion,
			nonCpeProgram: this.nonCpeProgram,
		});
	}
}
