/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../global_entities/components/entity-details/software-version.entity-details.component.ngfactory";
import * as i2 from "../../../../../global_entities/components/entity-details/software-version.entity-details.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i5 from "../../../common/side-panel.service";
import * as i6 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../../../../../tvm/services/tvm-texts.service";
import * as i8 from "../../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i9 from "../../../../../tvm/services/tvm-download.service";
import * as i10 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i11 from "./software-version.entity-panel.component";
var styles_SoftwareVersionEntityPanelComponent = [];
var RenderType_SoftwareVersionEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SoftwareVersionEntityPanelComponent, data: {} });
export { RenderType_SoftwareVersionEntityPanelComponent as RenderType_SoftwareVersionEntityPanelComponent };
export function View_SoftwareVersionEntityPanelComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "section", [["class", "wcd-padding-vertical wcd-padding-large-horizontal"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n    "])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "software-version-entity-details", [], null, null, null, i1.View_SoftwareVersionEntityDetailsComponent_0, i1.RenderType_SoftwareVersionEntityDetailsComponent)), i0.ɵdid(3, 245760, null, 0, i2.SoftwareVersionEntityDetailsComponent, [i3.Paris, i0.ChangeDetectorRef, i4.PrettyNumberService, i5.SidePanelService, i6.I18nService, i7.TvmTextsService, i8.TzDateService, i9.TvmDownloadService, i10.FeaturesService, i0.Injector], { softwareVersion: [0, "softwareVersion"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.version; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_SoftwareVersionEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "software-version-entity-panel", [], null, null, null, View_SoftwareVersionEntityPanelComponent_0, RenderType_SoftwareVersionEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i11.SoftwareVersionEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SoftwareVersionEntityPanelComponentNgFactory = i0.ɵccf("software-version-entity-panel", i11.SoftwareVersionEntityPanelComponent, View_SoftwareVersionEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { SoftwareVersionEntityPanelComponentNgFactory as SoftwareVersionEntityPanelComponentNgFactory };
