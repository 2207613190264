import { SevilleAlertsModule } from './seville.alerts.module';
import '../services/seville.threatintel.executionDetailsSidePaneService';
import '../services/seville.threatintel.genericEtwService';
import { RegExpService } from '@wcd/shared';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { OfficeIntegrationService } from '../../../../admin/services/office-integration.service';
import { OfficeIntegrationSettings, EntityWithContext, Script } from '@wcd/domain';
import { mapNetworkEventTypeToMessage } from '../entity/common/timelineeventcommon';
import { BaseType, event, select, selectAll, Selection } from 'd3-selection';
import { hierarchy, tree } from 'd3-hierarchy';
import { FeaturesService, Feature } from '@wcd/config';
import { HybridRoutingService } from '../../../../hybrid-routing.service';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { FilesService } from '../../../../@entities/files/services/files.service';
import { UrlsService } from '../../../../@entities/urls/services/urls.service';
import { IpsService } from '../../../../@entities/ips/services/ips.service';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';

SevilleAlertsModule.directive('alertProcessTree', alertProcessTreeDirective);

alertProcessTreeDirective.$inject = [
	'appInsights',
	'featuresService',
	'ng2router',
	'machinesService',
	'urlsService',
	'ipsService',
	'entityPanelsService',
];

function alertProcessTreeDirective(
	appInsights: AppInsightsService,
	featuresService: FeaturesService,
	ng2router: HybridRoutingService,
	machinesService: MachinesService,
	urlsService: UrlsService,
	ipsService: IpsService,
	entityPanelsService: EntityPanelsService
) {
	return {
		restrict: 'EA',
		scope: {
			alert: '=',
			isAggregativeAlert: '=',
			flightingOverride: '=',
			onLoaded: '&?',
		},
		controllerAs: 'alertProcessTree',
		bindToController: true,
		template: `
		<div class="row">
        <div class="col-sm-12 nopadding" data-track-component="SevilleAlertProcessTree" data-track-component-type="Tree">
            <div class="entity-section-title process-tree-title">Alert process tree</div>
            <div class="col-sm-12 border-default">
                <div id="processTree-container"></div>
                <div class="graph-loading text-center" ng-if="!alertProcessTree.error && alertProcessTree.loading">
                    <img src="/assets/images/circle-loading.gif" />
                </div>
                <div ng-if="alertProcessTree.error && !alertProcessTree.loading" class="process-tree-error">
                    <i class="icon icon-Error"></i>
                    Alert process tree is not available for this alert
                </div>
                <div class="missing-events" ng-if="!alertProcessTree.loading && alertProcessTree.missingEvents && alertProcessTree.missingEvents.length > 0">
                    <div>
                        <span>This alert is </span><span ng-if="!alertProcessTree.error">also </span><span>related to </span>
                        <span ng-repeat="missingEvent in alertProcessTree.missingEvents">
                            <span ng-if="!$last && !$first">,</span>
                            <span ng-if="$last && !$first">and </span>
                            {{ missingEvent }}
                            <span ng-if="$last"> not displayed here.</span>
                        </span>
                    </div>
                    <div>Last event time is <date src="alertProcessTree.spanningTo" />.</div>
                    <div class="hidden-print">
                        Click <a data-track-id="GoToMachineTimeline"
	   						data-track-type="Navigation"
	   						href="{{::alertProcessTree.getMachineLink(alertProcessTree.alert.MachineId, alertProcessTree.spanningTo)}}"
                        	ng-click="alertProcessTree.gotoTimeline()">here</a> to see all related events in the machine timeline.
                    </div>
                </div>
            </div>
        </div>
    </div>
		`,
		controller: [
			'$log',
			'$http',
			'$state',
			'$stateParams',
			'urlMapping',
			'$q',
			'authenticationService',
			'alertFilterService',
			'sidePaneService',
			'$rootScope',
			'executionDetailsSidePaneService',
			'appInsights',
			'genericEtwService',
			'officeIntegrationService',
			'demoTenantResolverService',
			'filesService',
			function(
				$log,
				$http,
				$state,
				$stateParams,
				urlMapping,
				$q,
				authenticationService,
				alertFilterService,
				sidePaneService,
				$rootScope,
				executionDetailsSidePaneService,
				appInsights: AppInsightsService,
				genericEtwService,
				officeIntegrationService: OfficeIntegrationService,
				demoTenantResolverService,
				filesService: FilesService
			) {
				var vm = this;
				var iconFontSize = 20;
				var textFontSize = 14;
				var expandMargin = 1;
				var expandToIcon = 13;
				var sidePaneIconSize = 12;
				var expandIconSize = 12;
				var iconPadding = 2; // comes from css icon
				var textVerticalAlignment = -2; // make sure not have a value ending with 0.5 so text is not on pixel intersection
				var textToAdditionalText = 2;
				var rowHeight = 32;
				var additionalTextHeight = 8;
				var additionalTextHeight2 = 16;
				var defualtHeight = 400;
				var svgObject;

				var alertCircleRadius = 7;
				var bugCircleRadius = 7;
				var alertIconFontSize = 10;
				var alertCircleMargin = 10;
				var iconOffset = (iconFontSize - expandIconSize) / 2;
				var encyclopediaLink = 'http://go.microsoft.com/fwlink/?LinkID=142185&Name=';
				var toolTipConfig = {
					padding: 24,
					rectPadding: 8,
					xPadding: 4,
					yPadding: 2,
				};

				var distanceToIconNode = expandIconSize + expandToIcon;
				var distanceToSidePane = distanceToIconNode + iconFontSize + expandMargin + 1;
				var distanceToText = distanceToSidePane + sidePaneIconSize + expandMargin;
				var xLevelDistance = distanceToIconNode + (iconFontSize - sidePaneIconSize) / 2; // put the next level under the icon in the middle
				var bugXLocation = distanceToIconNode + 10;
				var whiteCircleBugLocation = distanceToIconNode + 15;

				var alertTimeline: any = '';
				var totalHeight = 0;
				var margin = { top: 30, right: 0, bottom: 30, left: 20 };

				var spacingBetweenTrees = 70;

				vm.sidePaneInstance = '';
				vm.selectedNode = null;

				var duration = 100;

				this.$onInit = function() {
					vm.error = false;
					vm.loading = true;
					vm.calledProcessTreeApi = false;

					vm.officeTenantPrefix = null;
					officeIntegrationService.officeIntegrationSettings$.subscribe(
						(officeIntegrationSettings: OfficeIntegrationSettings) => {
							if (demoTenantResolverService.isDemoTenant()) {
								vm.officeTenantPrefix = 'demomock';
							} else if (
								officeIntegrationSettings &&
								officeIntegrationSettings.isE2EIntegrationEnabled
							) {
								officeIntegrationService
									.getOfficeTenantUrlPrefix()
									.subscribe((officeTenantUrlPrefix: string) => {
										vm.officeTenantPrefix = officeTenantUrlPrefix;
									});
							}

							if (!vm.calledProcessTreeApi) {
								// Load alert process tree if it wasn't loaded before.
								// We use o365 integration oppurtunistically - if settings are preloaded we use them, otherwise we load without the o365 lookup.
								setAlertTimeline();
							}
						},
						() => {
							$log.error(
								'Failed to get office integration settings - loading alert process tree with integration disabled'
							);

							if (!vm.calledProcessTreeApi) {
								// Load alert process tree if it wasn't loaded before
								setAlertTimeline();
							}
						}
					);

					vm.displayedEntitiesSubscription = combineLatest(
						entityPanelsService.getCurrentlyDisplayedItems(EntityWithContext),
						entityPanelsService.getCurrentlyDisplayedItems(Script)
					)
						.pipe(map(([entities, scripts]) => [...entities, ...scripts]))
						.subscribe(displayedEntities => {
							if (
								vm.selectedNode &&
								vm.selectedNode.newSidePaneEntity &&
								!displayedEntities.includes(vm.selectedNode.newSidePaneEntity)
							) {
								// side pane was closed
								vm.selectedNode = null;
								updateNodeCheckboxes();
							}
						});
				};

				this.$onDestory = function() {
					vm.displayedEntitiesSubscription && vm.displayedEntitiesSubscription.unsubscribe();

					vm.sidePaneUpdateUnsubscriber && vm.sidePaneUpdateUnsubscriber();
				};

				this.isValidSha1 = function(str) {
					return RegExpService.sha1.test(str);
				};

				function setAlertTimeline() {
					const threatIntelAlertTimelineUrl = urlMapping.getThreatIntelUrl() + '/AlertTimeline';
					vm.calledProcessTreeApi = true;
					getAlertTimeline(threatIntelAlertTimelineUrl).then(
						resolveAlertTimeline,
						rejectAlertTimeline
					);
				}

				function getAlertTimeline(url) {
					return $http.get(url, {
						params: {
							id: vm.alert.AlertId,
							isAggregativeAlert: vm.isAggregativeAlert,
							flightingOverride: vm.flightingOverride,
							officeTenantPrefix: vm.officeTenantPrefix,
						},
					});
				}

				function resolveAlertTimeline(response) {
					vm.loading = false;
					if (response.status == 200) {
						var data = response.data;
						if (!data) {
							vm.error = true;
							$log.debug('alert process tree: loading failed - no data');
						} else {
							$log.debug('alert process tree: loaded successfully');
							alertTimeline = data;
						}
					} else {
						$log.debug('alert process tree: loading failed - status ' + response.status);
						vm.error = true;
					}
					if (alertTimeline) {
						try {
							$rootScope.$broadcast('PROCESS_TREE_CALCULATED', true);
							if (alertTimeline.MissingEventsSummary) {
								vm.spanningTo = alertTimeline.MissingEventsSummary.LastEventTime;
								vm.missingEvents = getMissingEventsString(alertTimeline.MissingEventsSummary);
							}

							if (!alertTimeline.RootElements || !alertTimeline.RootElements.length) {
								$log.debug('No elements returned for process tree');
								vm.error = true;
							} else {
								$log.debug('alert process tree: rendering');
								var graphData = buildGraphData(alertTimeline.RootElements, 0, null);
								vm.renderGraph(graphData);

								var distinctShasArray = getGraphDistinctShas(graphData);
								if (distinctShasArray.length > 0) {
									var bulkQueryVirusTotalUrl =
										urlMapping.getThreatIntelUrl() + '/VirusTotalFilesReports';
									var virusTotalHttpPromise = $http.get(bulkQueryVirusTotalUrl, {
										params: {
											alertId: vm.alert.AlertId,
											filesIdentifier: distinctShasArray,
										},
									});
									var bulkQueryAzureTableUrl =
										urlMapping.getThreatIntelUrl() + '/FilesProfileAsync';
									var WDAVHttpPromise = $http.get(bulkQueryAzureTableUrl, {
										params: {
											fileIdentifiers: distinctShasArray,
											returnOnlyThreatFiles: true,
										},
									});
									$q.all([virusTotalHttpPromise, WDAVHttpPromise]).then(
										function(responses) {
											var virusTotalResponse = responses[0];
											var defenderResponse = responses[1];
											if (
												virusTotalResponse.status == 200 &&
												defenderResponse.status == 200
											) {
												var detectedSha1 = getDetectedSha1s(
													virusTotalResponse.data,
													defenderResponse.data
												);
												$rootScope.$broadcast(
													'PROCESS_TREE_WDAV_AND_VT_CALCULATED',
													detectedSha1
												);
												updateVirusTotalDetecionRate(
													virusTotalResponse.data,
													defenderResponse.data,
													graphData
												);

												update(true);
												$log.debug(
													'done updating the process tree with virus total and WDAV data'
												);
											} else {
												$log.debug(
													'Virus Total query resulted with response status ' +
														virusTotalResponse.status
												);
												$log.debug(
													'WDAV query resulted with response status ' +
														defenderResponse.status
												);
											}
										},
										function(response) {
											$log.debug(
												'query failed with response status ' + response.status
											);
										}
									);
								}

								$log.debug('alert process tree: done drawing');
							}
						} catch (e) {
							console.error(e);
							$log.debug(e + ' - Error occur while loading alert process tree');
							vm.error = true;
						}
					}
					if (vm.onLoaded) {
						vm.onLoaded();
					}
				}

				function rejectAlertTimeline(response) {
					vm.loading = false;
					$log.debug('alert process tree: loading failed - status ' + response.status);
					vm.error = true;
					if (vm.onLoaded) {
						vm.onLoaded();
					}
				}

				function isDetectionEvent(node) {
					return (
						node.element.ActionType === 'WDAVDetection' ||
						((node.element.ActionType === 'WDAVDefenderDataConsumer' ||
							node.element.ActionType === 'IRSpynetReport') &&
							node.element.EtwEventPropertiesAsJson &&
							node.element.EtwEventPropertiesAsJson.ThreatName)
					);
				}

				//function returns an array of all the shas in the graph
				function getGraphShasAsArray(graphData, shasArray) {
					for (var i = 0; i < graphData.length; i++) {
						var node = graphData[i];
						if (node.element && node.element.Sha1 && !isDetectionEvent(node)) {
							shasArray = shasArray.concat([node.element.Sha1]);
						} else if (node.element && node.element.Sha256 && !isDetectionEvent(node)) {
							shasArray = shasArray.concat([node.element.Sha256]);
						}
						//recursive call for node with visible children in the process tree
						if (node.children) {
							shasArray = getGraphShasAsArray(node.children, shasArray);
						}
						//recursive call for node with hidden children in the process tree (collapsed nodes)
						if (node._children) {
							shasArray = getGraphShasAsArray(node._children, shasArray);
						}
					}
					return shasArray;
				}

				function firstSeenInArray(val, index, array) {
					return array.indexOf(val) == index;
				}

				function getGraphDistinctShas(graphData) {
					var graphShaAsArray = getGraphShasAsArray(graphData, []);
					return graphShaAsArray.filter(function(x, i, a) {
						return firstSeenInArray(x, i, a);
					});
				}

				function lookupAndReturnIndex(name, objArray, propertyName) {
					for (var i = 0; i < objArray.length; i++) {
						if (objArray[i][propertyName] === name) return i;
					}
					return -1;
				}

				function getNodeHeight(node) {
					var additionalText = node.additionalText ? additionalTextHeight : 0;
					var additionalText2 = node.additionalText2 ? additionalTextHeight : 0;
					additionalText2 =
						additionalText && additionalText2 ? additionalTextHeight2 : additionalText2;
					var padding = additionalText || additionalText2 ? iconPadding : iconPadding;
					return rowHeight + additionalText + additionalText2 + padding;
				}

				function getDetectedSha1s(virusTotaldetectionArray, defenderDetectionArray) {
					var res = [];
					virusTotaldetectionArray.forEach(function(item) {
						if (item.positives >= 2) {
							res.push(item.sha1);
						}
					});
					defenderDetectionArray.forEach(function(item) {
						res.push(item.Sha1);
					});
					return res.filter(function(x, i, a) {
						return firstSeenInArray(x, i, a);
					});
				}

				function updateNodeIds(graphData, father, startId, isMappedNode = false) {
					//update the tree data, nodes order are determined by IDs (lower the ID nodes will be higher in the process tree representation)
					var currentIndex = startId;
					var lastChildHasAdditionalText = false;
					var lastChildHasDetectionAdditionalText = false;
					if (graphData && graphData.length) {
						for (var i = 0; i < graphData.length; i++) {
							var node = graphData[i];
							var prevNode = i == 0 ? father : graphData[i - 1];
							if (isMappedNode) {
								node.data.id = currentIndex;
								node.data.prevSibling = prevNode;
							} else {
								node.id = currentIndex;
								node.prevSibling = prevNode;
							}
							currentIndex++;

							if (node.children) {
								var retVal = updateNodeIds(node.children, node, currentIndex, isMappedNode);
								if (i == graphData.length - 1) {
									lastChildHasAdditionalText = retVal.lastChildHasAdditionalText;
									lastChildHasDetectionAdditionalText =
										retVal.lastChildHasDetectionAdditionalText;
								}

								currentIndex = retVal.newIndex;
							}
							lastChildHasAdditionalText =
								lastChildHasAdditionalText || graphData[graphData.length - 1].additionalText;
							lastChildHasDetectionAdditionalText =
								lastChildHasDetectionAdditionalText ||
								graphData[graphData.length - 1].positives;
						}
					}

					return {
						newIndex: currentIndex,
						lastChildHasAdditionalText: lastChildHasAdditionalText,
						lastChildHasDetectionAdditionalText: lastChildHasDetectionAdditionalText,
					};
				}

				function update(shouldAddInfoAsync) {
					var totalTreesSize = margin.top;
					for (var i = 0; i < vm.graphDataWithTrees.length; i++) {
						var currentTree = vm.graphDataWithTrees[i];
						updateSingleTree(currentTree, shouldAddInfoAsync);
						currentTree.svg
							.transition()
							.duration(duration)
							.attr('transform', 'translate(' + margin.left + ',' + totalTreesSize + ')');
						totalTreesSize += currentTree.tree.height + spacingBetweenTrees;
					}

					var heightDuration = 20;
					totalTreesSize = totalTreesSize - spacingBetweenTrees + margin.bottom;

					setTimeout(function() {
						svgObject.attr('height', totalTreesSize);
					}, heightDuration);
				}

				function updateSingleTree(data, shouldAddInfoAsync) {
					var updateData = updateNodeIds([data.mappedTree], '', 0, true);

					// Compute the new tree layout.

					const treeData = vm.tree(data.mappedTree),
						nodes = treeData.descendants(),
						links = treeData.links();

					setUpNodeCalcuatedAttributes(nodes, data);

					var lineLength = data.tree.height + iconPadding;

					if (updateData.lastChildHasAdditionalText) {
						lineLength += additionalTextHeight - iconPadding;
					}
					if (updateData.lastChildHasDetectionAdditionalText) {
						lineLength += additionalTextHeight - iconPadding;
					}

					//will update the process tree async
					if (shouldAddInfoAsync) {
						updateBugIcon(nodes, data);
						updateDetectionText(nodes, data);
					}
					handleNodeUpdate(nodes, data.processTreeData, data.svg);
					drawSideLine(updateData.newIndex, lineLength, data.processTreeLine);
					addAlertIcons(nodes, data.processTreeIcons);
					handleLinkUpdates(nodes, links, data.processTreeData, data.svg);
				}

				function drawSideLine(nodeCount, lineHeight, processTreeLine) {
					// Add a straight line from the middle of the first connector to the bottom node

					var maxY = lineHeight;
					var startX = expandIconSize / 2;
					var startY = 0 - iconPadding;

					var horizonalLineEndX = expandIconSize;

					var verticalLinePath = 'M' + startX + ',' + startY + ' L' + startX + ',' + maxY;
					var bottomLinePath = 'M0,' + maxY + 'L' + horizonalLineEndX + ',' + maxY;
					var pathString = verticalLinePath + bottomLinePath;

					var existingLines = processTreeLine.selectAll('.processTree-sideLine').data([nodeCount]);

					if (nodeCount > 1) {
						existingLines
							.enter()
							.append('path')
							.attr('d', pathString)
							.attr('class', 'processTree-sideLine process-tree-line-color');

						existingLines
							.transition()
							.duration(duration)
							.attr('d', pathString);
					} else {
						existingLines
							.transition()
							.duration(duration)
							.remove();
					}

					existingLines
						.exit()
						.transition()
						.duration(duration)
						.remove();
				}

				function collapseNode(d) {
					if (d.children && d.children.length == 0) return;

					if (!d.data) d.data = {};
					if (!d.data._children) d.data._children = d._children;

					if (d.children) {
						d.children.forEach(child => collapseNode(child));
						d.data._children = d.children;
						d.children = null;
					}
				}

				function toggle(d, runUpdate) {
					if (d.children && d.children.length == 0) return;

					if (!d.data) d.data = {};
					if (!d.data._children) d.data._children = d._children;

					//saves the data for later use (d3 will show only children and will not show _children)
					if (d.children) {
						d.data._children = d.children;
						d.children = null;
						if (runUpdate) {
							update(false);
						}
					} else {
						d.children = d.data._children;
						d.data._children = null;

						if (runUpdate) {
							update(false);
						}
					}
				}

				function addAdditionalText(d, parentGroup) {
					var text;
					if (d.data.additionalText2) {
						var detectionOffset = d.data.additionalText ? additionalTextHeight2 : 0;
						var dy =
							textVerticalAlignment + textFontSize + textToAdditionalText + detectionOffset;
						text = parentGroup
							.append('text')
							.attr('dx', distanceToText)
							.attr('dy', dy)
							.attr('class', 'process-tree-addtional-text')
							.text(function(d) {
								return d.data.additionalText2;
							});

						//append an encyclopedia icon with a link and a tooltip
						if (d.data.threatName) {
							var textWidth = Math.ceil(text.node().getComputedTextLength());
							var readingIcon = parentGroup
								.append('svg:a')
								.attr('xlink:href', encyclopediaLink + d.data.threatName)
								.on('mouseover', function() {
									var parentNode = select(this.parentNode);
									var tooltip = parentNode
										.append('g')
										.attr('class', 'encyclopedia-tooltip-group')
										.attr(
											'transform',
											'translate(' +
												(distanceToText + textWidth + toolTipConfig.padding) +
												',' +
												dy +
												')'
										);

									var tooltipRect = tooltip
										.append('rect')
										.attr('class', 'process-tree-tooltip-rect')
										.attr('height', 20)
										.attr('width', 10);

									var tooltipText = tooltip
										.append('text')
										.attr('dx', 2)
										.attr('dy', 15)
										.text('Read more on Microsoft Encyclopedia');

									tooltipRect.attr(
										'width',
										Math.ceil((<any>tooltipText.node()).getComputedTextLength()) +
											toolTipConfig.rectPadding
									);
								})
								.on('mouseleave', function() {
									this.parentNode.selectAll('.encyclopedia-tooltip-group').remove();
								});

							readingIcon
								.append('text')
								.attr('class', 'icon alert-encyclopedia-icon')
								.attr('dx', distanceToText + textWidth + toolTipConfig.xPadding)
								.attr(
									'dy',
									textVerticalAlignment +
										textFontSize +
										textToAdditionalText +
										detectionOffset +
										toolTipConfig.yPadding
								)
								.text('\uE736'); //book icon
						}
					}
					if (d.data.additionalText3) {
						var detectionOffset2 =
							d.data.additionalText || d.data.additionalText2 ? additionalTextHeight2 : 0;
						detectionOffset2 =
							d.data.additionalText && d.data.additionalText2
								? 2 * additionalTextHeight2
								: detectionOffset2;
						text = parentGroup
							.append('text')
							.attr('dx', distanceToText)
							.attr(
								'dy',
								textVerticalAlignment + textFontSize + textToAdditionalText + detectionOffset2
							)
							.attr('class', 'process-tree-addtional-text')
							.text(function(d) {
								return d.data.additionalText3;
							});
					}
				}

				function handleNodeUpdate(nodes, processTreeData, svg) {
					processTreeData.selectAll('g.processTree-node').remove();

					// Update the node set from the data
					var node = processTreeData.selectAll('g.processTree-node').data(nodes, function(d) {
						return d.data.elementId;
					});

					// NEW NODES - JUST TURNED VISIBLE
					// Enter any new nodes at the correct x / y according to id
					var nodeEnter = node
						.enter()
						.append('g')
						.attr('class', 'processTree-node side-pane-container')
						.attr('transform', function(d) {
							return 'translate(' + d.x + ',' + d.y + ')';
						});

					// g nodes don't response to mouse events - this invisible rect only catches clicks so the hover css will take effect
					nodeEnter
						.append('rect')
						.attr('class', 'process-tree-hover-group')
						.style('visibility', 'hidden')
						.attr('y', -1 * (iconFontSize + iconPadding))
						.attr('x', function(d) {
							return -1 * (d.x + margin.left);
						})
						.attr('width', '100%')
						.attr('height', function(d) {
							return getNodeHeight(d);
						});

					// Add expand / collapse icon
					var expandGroup = nodeEnter
						.append('g')
						.attr('class', 'icon alert-process-tree-expand-icon-group')
						.on('click', function(d) {
							toggle(d, true);

							if (event) {
								event.stopPropagation();
							}
						});

					expandGroup.each(function(d) {
						var thisGroup = select(this);
						if (d.data.hasChildren) {
							var text = '\uE833';
							var path = '';

							if (d.children) {
								path = '';
							} else {
								path = 'M5.5,-9L5.5,-3.5';
							}

							thisGroup
								.append('text')
								.attr('class', 'icon alert-process-tree-expand-icon')
								.text(text);
							thisGroup
								.append('path')
								.attr('d', path)
								.attr('class', 'process-tree-line-color alert-process-tree-plus-line');

							if (d.depth == 0 && d.data.isAssociatedWithAlert) {
								thisGroup.attr('transform', 'translate(' + -1 * margin.left + ',0)');
							}
						}
					});

					// Add node icon
					nodeEnter
						.append('text')
						.attr('class', 'icon alert-process-tree-node-icon')
						.attr('dx', distanceToIconNode)
						.attr('dy', iconOffset)
						.text(function(d) {
							return d.data.icon;
						})
						.on('contextmenu', function(d) {
							$log.debug(d);
						}); // Debugging help - right click on the icon will print the element

					addBugIcon(nodeEnter);

					// Add node name
					var texts = nodeEnter.append('g');
					texts.each(function(d) {
						// Add name and URL
						var thisGroup: Selection<BaseType, any, HTMLElement, any> = select(this);
						var text;
						if (d.data.url) {
							if (d.data.element.ElementType == 'NetworkConnection') {
								var currentTextDistance = distanceToText;
								if (d.data.element.Domain) {
									let domainText: Selection<BaseType, any, HTMLElement, any> = thisGroup
										.append('svg:a')
										.attr('xlink:href', function(d) {
											return d.data.url.domain;
										})
										.append('svg:text')
										.attr('dx', currentTextDistance)
										.attr('dy', textVerticalAlignment + 2)
										.attr('class', 'graph-link icon domain-info-icon')
										.text(function(d) {
											return '\uE946';
										}); // icon-info

									var domainTextLen = (<any>domainText.node()).getComputedTextLength();
									currentTextDistance += domainTextLen + 1;

									domainText = thisGroup
										.append('text')
										.attr('dx', currentTextDistance)
										.attr('dy', textVerticalAlignment)
										.text(function(d) {
											return d.data.element.Domain;
										});

									domainTextLen = (<any>domainText.node()).getComputedTextLength();
									currentTextDistance += domainTextLen + 1;
								}

								if (d.data.element.IpAddress) {
									var ipText = d.data.element.Domain
										? ' (' + d.data.element.IpAddress + ')'
										: d.data.element.IpAddress;

									thisGroup
										.append('svg:a')
										.attr('xlink:href', function(d) {
											return d.data.url.ip;
										})
										.append('svg:text')
										.attr('dx', currentTextDistance)
										.attr('dy', textVerticalAlignment)
										.attr('class', 'graph-link')
										.text(function(d) {
											return ipText;
										});
								}
							} else {
								text = thisGroup
									.append('svg:a')
									.attr('xlink:href', function(d) {
										return d.data.url;
									})
									.append('svg:text')
									.attr('dx', distanceToText)
									.attr('dy', textVerticalAlignment)
									.attr('class', 'graph-link')
									.text(function(d) {
										return d.data.name && d.data.name.length > 100
											? d.data.name.substring(0, 97) + '...'
											: d.data.name;
									});
							}
						} else {
							text = thisGroup
								.append('text')
								.attr('dx', distanceToText)
								.attr('dy', textVerticalAlignment)
								.text(function(d) {
									return d.data.name;
								});
						}

						// Add additinal text
						if (d.data.additionalText) {
							text = thisGroup
								.append('text')
								.attr('dx', distanceToText)
								.attr('dy', textVerticalAlignment + textFontSize + textToAdditionalText)
								.attr('class', 'process-tree-addtional-text')
								.text(function(d) {
									return d.data.additionalText;
								});
						}

						addAdditionalText(d, thisGroup);
						d.data.textLength = text ? (<any>text.node()).getComputedTextLength() : 0;
					});

					// Side pane icon
					var sidePane = nodeEnter
						.append('text')
						.attr('class', function(d) {
							return (
								'icon side-pane-indicator-icon process-tree-side-pane-indicator-icon' +
								(isSelectedInSidePane(d)
									? ' process-tree-side-pane-indicator-icon-selected'
									: '')
							);
						})
						.attr('data-track-id', 'alertProcessTreeSidePaneButton')
						.attr('data-track-type', 'SidePaneToggleButton')
						.attr('aria-label', d => d.data.name)
						.attr('role', 'radio')
						.attr('aria-checked', d => isSelectedInSidePane(d))
						.attr('id', function(d) {
							return 'sidepane-' + d.data.elementId;
						})
						.text(function(d) {
							return getSidePaneIcon(d);
						})
						.attr('dx', distanceToSidePane)
						.attr('tabindex', 0)
						.on('click', function(d) {
							sidePaneActivate(event, d);
						})
						.on('mouseenter', function(d) {
							const thisIcon: any = select('#sidepane-' + d.data.elementId);
							thisIcon.transition().text(getSidePaneIcon(d, true));
						})
						.on('mouseleave', function(d) {
							const thisIcon: any = select('#sidepane-' + d.data.elementId);
							thisIcon.transition().text(getSidePaneIcon(d, false));
						})
						.on('keypress', function(d: any) {
							if (event && (<KeyboardEvent>event).keyCode == 13) {
								// enter key was pressed
								sidePaneActivate(event, d);
							}
						});

					nodeEnter
						.transition()
						.duration(duration)
						.attr('transform', function(d) {
							return 'translate(' + d.x + ',' + d.y + ')';
						});

					// EXISTING NODES
					// Transition nodes to their new position.
					var nodeUpdate = nodeEnter
						.transition()
						.duration(duration)
						.attr('transform', function(d) {
							return 'translate(' + d.x + ',' + d.y + ')';
						});

					// Set up expand / collapse icon
					var expandIconPlus = svg
						.selectAll('.alert-process-tree-plus-line')
						.data(nodes, function(n) {
							return n.elementId;
						});
					expandIconPlus.transition().attr('d', function(d) {
						if (d.data.hasChildren && !d.children) return 'M5.5,-9L5.5,-3.5';
						return '';
					});

					// EXITING NODES - HIDDEN
					// Remove exiting nodes
					var nodeExit = nodeEnter
						.exit()
						.transition()
						.duration(duration)
						.remove();

					// Stash the old positions for transition.
					nodes.forEach(function(d) {
						d.x0 = d.x;
						d.y0 = d.y;
					});
				}

				function sidePaneActivate(event, node) {
					toggleSidePane(node);
					setSidePaneIndicatorIconAndClass(node);

					if (event) {
						event.stopPropagation();
					}
				}

				function toggleSidePane(node) {
					if (isSelectedInSidePane(node)) {
						vm.selectedNode = null;
						executionDetailsSidePaneService.close(node.data.element);

						// outside the normal digest loop since it has no additional queries, so force UI update
						if (vm.sidePaneInstance && vm.sidePaneInstance.sections.length === 1) {
							$rootScope.$digest();
						}
					} else {
						//appInsights.trackEvent("UsageTrack", { ButtonType: "AlertSidePane", Page: "Alert", Component: "ProcessTreeSidePane" });
						const oldSelectedNode = vm.selectedNode;
						vm.selectedNode = node.data.element;
						vm.sidePaneInstance = executionDetailsSidePaneService.open(
							vm.selectedNode,
							oldSelectedNode
						);

						// force pane update
						if (vm.sidePaneInstance && vm.sidePaneInstance.sections.length === 1) {
							$rootScope.$digest();
						}
					}

					updateNodeCheckboxes();
				}

				function isSelectedInSidePane(node) {
					return node && node.data && node.data.element === vm.selectedNode;
				}

				function getSidePaneIcon(node, mouseOver?) {
					if (mouseOver) {
						return isSelectedInSidePane(node) ? '\uEC61' : '\uE930';
					}
					if (!isValidForSidePane(node)) return '';
					return isSelectedInSidePane(node) ? '\uEC61' : '\uE831';
				}

				function getSidePaneClass(thisIcon, selectedClassName, isSelected) {
					var newClass = thisIcon.node().getAttribute('class');

					if (isSelected) {
						if (newClass.indexOf(selectedClassName) == -1) {
							newClass += ' ' + selectedClassName;
						}
					} else {
						if (newClass.indexOf(selectedClassName) !== -1) {
							newClass = newClass.replace(selectedClassName, '');
						}
					}

					return newClass;
				}

				function setSidePaneIndicatorIconAndClass(d) {
					const isSelected = isSelectedInSidePane(d);
					const icon: any = select('#sidepane-' + d.data.elementId);
					const newClass = getSidePaneClass(
						icon,
						'process-tree-side-pane-indicator-icon-selected',
						isSelected
					);
					icon.attr('aria-checked', isSelected);
					icon.transition()
						.text(getSidePaneIcon(d))
						.attr('class', newClass);
				}

				function isValidForSidePane(node) {
					if (node.data.element && node.data.element.ElementType === 'GenericEtw') {
						var etwIsValidForSidePaneFunction = genericEtwService.getEtwSidePaneDetails(
							node.data.element.ActionType
						).isEtwValidForSidePane;
						return etwIsValidForSidePaneFunction
							? etwIsValidForSidePaneFunction(node.data.element)
							: false;
					}

					return (
						node &&
						node.data &&
						node.data.element &&
						(node.data.element.Sha1 ||
							node.data.element.FileName ||
							node.data.element.IpAddress ||
							node.data.element.Domain ||
							node.data.element.RegistryOperation ||
							node.data.element.Url)
					);
				}

				function addAlertIcons(nodes, processTreeIcons) {
					var biggerCircleRadiusDiff = 2;
					var translateY =
						iconFontSize / 2 -
						(biggerCircleRadiusDiff + alertCircleMargin * 2 - iconFontSize) -
						2; // 2 for the icon padding-bottom

					// Update the node set from the data
					var node = processTreeIcons
						.selectAll('.processTree-alertIconNode')
						.data(nodes, function(d) {
							return d.data.elementId;
						});

					// NEW NODES - JUST TURNED VISIBLE
					// Enter any new nodes at the correct x / y according to id
					var nodeEnter = node
						.enter()
						.append('g')
						.attr('class', 'processTree-alertIconNode')
						.attr('transform', function(d) {
							return 'translate( 0,' + (d.y - iconOffset) + ')';
						});

					const nodesElements: Selection<BaseType, any, HTMLElement, any> = selectAll(
						'.processTree-alertIconNode'
					);

					nodesElements.each(function(node) {
						if (node.data.isAssociatedWithAlert) {
							var associatedAlertTitles = node.data.element.AssociatedAlertTitles;
							if (!vm.alert.AlertCount || vm.alert.AlertCount <= 1) {
								associatedAlertTitles = ['Alert evidence'];
							}

							var textIndentation = 5;
							var longestAlertTitle = 15; // length of "Alert evidence" - min width of this tooltip text box
							var tooltipWidth = 100;
							for (var i = 0; i < associatedAlertTitles.length; i++) {
								if (associatedAlertTitles[i].length > longestAlertTitle) {
									longestAlertTitle = associatedAlertTitles[i].length;
									tooltipWidth = textIndentation + Math.ceil(6.8 * longestAlertTitle);
								}
							}

							var group = select(this);

							var alertIconCircle = group
								.append('g')
								.attr('class', 'alert-icon-circle')
								.on('mouseover', function() {
									//will expand the element to original size when the mouse enter the circle object
									const parentNode = select<SVGGElement, any>(<SVGGElement>(
										(<SVGGElement>this).parentNode
									));
									parentNode
										.selectAll('.process-tree-tooltip-rect')
										.attr('width', tooltipWidth);
									parentNode.selectAll('.associated-alert-titles').attr('textLength', null);
								})
								.on('mouseleave', function() {
									//will minimize the element when the mouse leaves the circle object (to avoid overlapping)
									const parentNode = select<SVGGElement, any>(<SVGGElement>(
										(<SVGGElement>this).parentNode
									));
									parentNode.selectAll('.process-tree-tooltip-rect').attr('width', 10);
									parentNode.selectAll('.associated-alert-titles').attr('textLength', 10);
								});

							alertIconCircle
								.append('circle')
								.attr('class', 'alert-icon-node-circle-outer')
								.attr('cx', alertCircleRadius - 1)
								.attr('r', alertCircleRadius + biggerCircleRadiusDiff);

							//TODO support link from event to original alert (with different severity icon per node)
							alertIconCircle
								.append('circle')
								.attr(
									'class',
									'alert-icon-node-circle-' + node.data.element.AssociatedAlertSeverity
								)
								.attr('cx', alertCircleRadius - 1)
								.attr('r', alertCircleRadius);

							alertIconCircle
								.append('text')
								.attr(
									'class',
									'icon alert-icon-node-text alert-icon-node-text-' +
										node.data.element.AssociatedAlertSeverity
								)
								.text('\uE945') //icon-LightningBolt
								.attr('dx', alertCircleRadius / 2 - 2)
								.attr(
									'dy',
									alertCircleRadius / 2 + (alertIconFontSize - alertCircleRadius) / 2
								);

							var tooltipGroup = group
								.append('g')
								.attr('class', 'tooltip-group')
								.attr('transform', 'translate(15,0)');

							//width is set to 10 to prevent elements overlapping
							tooltipGroup
								.append('rect')
								.attr('height', associatedAlertTitles.length * 20)
								.attr('width', 10)
								.attr('class', 'process-tree-tooltip-rect');

							var tooltipText = tooltipGroup.append('text');

							for (var i = 0; i < associatedAlertTitles.length; i++) {
								//textLength is set to 10 to prevent elements overlapping
								tooltipText
									.append('tspan')
									.text(associatedAlertTitles[i])
									.attr('class', 'associated-alert-titles')
									.attr('textLength', 10)
									.attr('x', textIndentation)
									.attr('dy', i == 0 ? 15 : 20);
							}
						}
					});

					// EXISTING NODES
					// Transition nodes to their new position.
					var nodeUpdate = node
						.transition()
						.duration(duration)
						.attr('transform', function(d) {
							return 'translate( 0,' + (d.y - iconOffset) + ')';
						});

					// EXITING NODES - HIDDEN
					// Remove exiting nodes
					var nodeExit = node
						.exit()
						.transition()
						.duration(duration)
						.remove();
				}

				function handleLinkUpdates(nodes, links, processTreeData, svg) {
					// Update the node set from the data
					var link = processTreeData.selectAll('.process-tree-path').data(links, function(d) {
						return 'source' + d.source.elementId + 'target' + d.target.elementId;
					});

					// Enter any new nodes at the correct x / y according to id
					var linkEnter = link
						.enter()
						.append('path')
						.attr('class', 'process-tree-path process-tree-line-color')
						.transition()
						.duration(duration)
						.attr('d', function(l) {
							return getProcessTreeConnectorLine(l, nodes);
						});

					// update exsiting links
					svg.selectAll('.process-tree-path')
						.transition()
						.duration(duration)
						.attr('d', function(l) {
							return getProcessTreeConnectorLine(l, nodes);
						});

					// Remove exiting links
					link.exit()
						.transition()
						.duration(duration)
						.remove();
				}

				function calculateAdditionalTextHeight(node) {
					var firstLine = node && node.data && node.data.additionalText ? additionalTextHeight : 0;
					var secondLine =
						node && node.data && node.data.additionalText2 ? additionalTextHeight : 0;
					secondLine = firstLine && secondLine ? additionalTextHeight2 : secondLine;
					var thirdLine = node && node.data && node.data.additionalText3 ? additionalTextHeight : 0;
					thirdLine =
						(firstLine && thirdLine) || (secondLine && thirdLine)
							? additionalTextHeight2
							: thirdLine;
					return firstLine + secondLine + thirdLine;
				}

				function setUpNodeCalcuatedAttributes(nodes, data) {
					//calculate new nodes positions and update the tree height
					nodes.sort(function(a, b) {
						return a.data.id - b.data.id;
					});
					for (var i = 0; i < nodes.length; i++) {
						var currentNode = nodes[i];
						var prevNode: any = '';
						if (i > 0) {
							prevNode = nodes[i - 1];
						}

						currentNode.x = currentNode.depth * xLevelDistance;

						//calculate node's Y position
						var prevNodeAdditionalTextHeight = calculateAdditionalTextHeight(prevNode);
						var levelHeight = rowHeight + iconPadding + prevNodeAdditionalTextHeight;
						var startFrom = prevNode ? prevNode.y : 0;
						currentNode.y = i == 0 ? 0 : startFrom + levelHeight;
						data.tree.height = currentNode.y;
					}
				}

				function getProcessTreeConnectorLine(link, nodes) {
					var source = link.source;
					var target = link.target;

					var arrowMarginFromIcons = 2;

					// start point X: source x + enough to be in the middle of the node icon
					var startX = Math.ceil(source.x + distanceToIconNode + iconFontSize / 2);

					// start point Y:
					// if the previous sibling has an expand icon (or this is the first node), take a margin
					// otherwise - just start where it starts
					var prevSiblingY = 0;
					if (target.data.prevSibling) {
						const prevNode = nodes.find(
							d => d.data.elementId === target.data.prevSibling.data.elementId
						);
						prevSiblingY = prevNode ? prevNode.y : 0;
					}
					var prevSiblingHasChildren = target.data.prevSibling
						? target.data.prevSibling.data.hasChildren
						: false;

					var startY = Math.ceil(
						target.data.isFirstChild
							? prevSiblingY + iconPadding + arrowMarginFromIcons + iconOffset
							: prevSiblingHasChildren
							? prevSiblingY + arrowMarginFromIcons
							: prevSiblingY - iconOffset - 2
					);

					// end point X : target x with margin
					var endpointX = Math.ceil(target.x + distanceToIconNode - arrowMarginFromIcons * 2);

					// endpoint Y: middle of linetom
					var endpointY = Math.ceil(target.y - iconOffset - iconPadding);

					var pathString = 'M ' + startX + ',' + startY + ', ';

					// Target: if the target node as an expand icon, take a margin both vertically and horizontically
					if (target.data.hasChildren) {
						var lineHeight = target.y - arrowMarginFromIcons - iconFontSize - prevSiblingY;
						pathString +=
							'L ' +
							startX +
							',' +
							Math.ceil(target.y - arrowMarginFromIcons - expandIconSize) +
							', ';
						pathString +=
							'M ' +
							Math.ceil(startX + sidePaneIconSize - arrowMarginFromIcons) +
							',' +
							endpointY +
							', ';
					} else {
						pathString += 'L ' + startX + ',' + endpointY + ', ';
					}

					// Line from where we stopped to right before the node icon
					pathString += 'L ' + endpointX + ',' + endpointY;
					return pathString;
				}

				function updateVirusTotalDetecionRate(
					virusTotaldetectionArray,
					defenderDetectionArray,
					graphData
				) {
					var node;
					for (var i = 0; i < graphData.length; i++) {
						node = graphData[i].data || graphData[i];
						if ((node.element.Sha1 || node.element.Sha256) && !isDetectionEvent(node)) {
							var indexVtSha1 = lookupAndReturnIndex(
								node.element.Sha1,
								virusTotaldetectionArray,
								'sha1'
							);
							var indexVtSha256 = lookupAndReturnIndex(
								node.element.Sha256,
								virusTotaldetectionArray,
								'sha256'
							);
							var indexDefender = lookupAndReturnIndex(
								node.element.Sha1,
								defenderDetectionArray,
								'Sha1'
							);

							if (indexDefender >= 0 && defenderDetectionArray[indexDefender].Threat) {
								node.positives = 1;
								node.additionalText2 =
									'Detected as ' +
									defenderDetectionArray[indexDefender].Threat +
									' by Windows Defender AV';
								node.threatName = defenderDetectionArray[indexDefender].Threat;
							}

							if (indexVtSha1 >= 0 || indexVtSha256 >= 0) {
								var indexVt = indexVtSha1 >= 0 ? indexVtSha1 : indexVtSha256;
								node.positives = virusTotaldetectionArray[indexVt].positives;
								var totalDetectors = virusTotaldetectionArray[indexVt].total;
								if (virusTotaldetectionArray[indexVt].positives) {
									node.additionalText3 =
										'VirusTotal detection ratio: ' +
										node.positives +
										'/' +
										totalDetectors;
								}
							}
						}
						if (node.children)
							updateVirusTotalDetecionRate(
								virusTotaldetectionArray,
								defenderDetectionArray,
								node.children
							);
						if (node._children)
							updateVirusTotalDetecionRate(
								virusTotaldetectionArray,
								defenderDetectionArray,
								node._children
							);
					}
				}

				function buildGraphData(elements, depth, parentElement) {
					var data = [];

					if (elements && elements.length) {
						for (var i = 0; i < elements.length; i++) {
							var element = elements[i];
							element.Parent = parentElement;

							if (element.ElementType === 'GenericEtw') {
								element.fullEtwType = element.ActionType;
								element.ActionType = genericEtwService.getEtwEventType(element);
								if (
									!genericEtwService.isEtwSupported(element.ActionType) ||
									!genericEtwService.getEtwAlertProcessTreeDetails(element.ActionType)
								) {
									$log.debug(
										'Etw event of unsupported type: "' +
											element.ActionType +
											'" will not be presented in process tree'
									);
									continue;
								}
							}

							var item: any = {
								icon: getNodeIcon(element),
								url: getNodeUrl(element),
								depth: depth,
								isAssociatedWithAlert: element.IsAssociatedWithAlert,
								weight: element.Weight,
								name: getNodeName(element),
								element: element,
								elementId: element.ElementId,
								additionalText: '',
								isFirstChild: i == 0,
							};

							// set additionalText and optionally additionalText2 fields
							item.additionalText = getNodeAdditionalText(element, item);

							if (element.children || (element.SubElements && element.SubElements.length)) {
								item._children = buildGraphData(element.SubElements, depth + 1, element);
								item.hasChildren = true;
								item.childsCount = item._children.length;
							}

							data.push(item);
						}
					}

					return data;
				}

				function addBugIcon(node) {
					node.each(function(d) {
						if (d.data.positives) {
							var circleParam = getBugIconCircleParameters(d.data.element.ActionType);
							select(this)
								.append('circle')
								.attr('class', 'alert-icon-circle bug-icon-node-circle')
								.attr('cx', circleParam.cx)
								.attr('cy', circleParam.cy)
								.attr('r', circleParam.radius);
						}
					});

					node.each(function(d) {
						if (d.data.positives) {
							var bugIconParameters = getBugIconParameters(d.data.element.ActionType);
							select(this)
								.append('text')
								.attr('class', 'icon ' + bugIconParameters.bugIcon)
								.attr('dx', bugIconParameters.dx)
								.attr('dy', bugIconParameters.dy)
								.text('\uEBE8') //bug icon
								.style('fill', '#a80000');
						}
					});
				}

				function getBugIconCircleParameters(actionType) {
					if (actionType === 'LoadImage') {
						return {
							radius: bugCircleRadius - 1,
							cx: whiteCircleBugLocation + 3,
							cy: 1.5,
						};
					}
					return {
						radius: bugCircleRadius,
						cx: whiteCircleBugLocation,
						cy: 0,
					};
				}

				function getBugIconParameters(actionType) {
					if (actionType === 'LoadImage') {
						return {
							bugIcon: 'alert-VT-WDAV-bug-overlay-LoadImage',
							dx: bugXLocation + 2,
							dy: iconOffset + 2,
						};
					}
					return {
						bugIcon: 'alert-VT-WDAV-bug-overlay',
						dx: bugXLocation,
						dy: iconOffset + 3,
					};
				}

				function updateBugIcon(nodes, data) {
					var node = data.processTreeData.selectAll('g.processTree-node').data(nodes, function(d) {
						return d.data.elementId;
					});

					addBugIcon(node);
				}

				function updateDetectionText(nodes, data) {
					var node = data.processTreeData.selectAll('.graph-link').data(nodes, function(d) {
						return d.data.elementId;
					});

					node.each(function(d) {
						addAdditionalText(d, select(this.parentNode.parentNode));
					});
				}

				function getMissingEventsString(missingEventsData) {
					if (!missingEventsData) return '';

					// Translate dictionary<event type, counter> to dictionary<event type friendly name, counter>
					var missingEventsDictionary = {};
					for (var d in missingEventsData.EventsByType) {
						var eventCount = missingEventsData.EventsByType[d];
						var eventFriendlyName = getEventTypeFriendlyName(d);

						if (eventFriendlyName in missingEventsDictionary) {
							// Multiple event types could be mapped to the same friendly description - so we should sum them together
							missingEventsDictionary[eventFriendlyName] =
								missingEventsDictionary[eventFriendlyName] + eventCount;
						} else {
							missingEventsDictionary[eventFriendlyName] = eventCount;
						}
					}

					var missingEventsDescriptions = [];
					for (var eventFriendlyName in missingEventsDictionary) {
						var eventCount = missingEventsDictionary[eventFriendlyName];
						var suffix = eventCount == 1 ? 'event' : 'events';
						missingEventsDescriptions.push(eventCount + ' ' + eventFriendlyName + ' ' + suffix);
					}

					return missingEventsDescriptions;
				}

				function getEventTypeFriendlyName(eventType) {
					var eventText = '';
					switch (eventType) {
						case 'CreateFile':
							eventText = 'file modification';
							break;
						case 'CreateProcess':
							eventText = 'process creation';
							break;
						case 'NetworkConnection':
							eventText = 'network connection';
							break;
						case 'Registry':
							eventText = 'registry modification';
							break;
						case 'LoadImage':
							eventText = 'module load';
							break;
						case 'Scan':
							eventText = 'detection';
							break;
						case 'UserLogon':
							eventText = 'user log-on';
							break;
						case 'FileSignatures':
							eventText = 'scan';
							break;
						default:
							if (genericEtwService.isEtwSupported(eventType)) {
								var alertProcessDetails = genericEtwService.getEtwAlertProcessTreeDetails(
									eventType
								);
								if (
									alertProcessDetails &&
									alertProcessDetails.eventTypeFriendlyName != null
								) {
									eventText = alertProcessDetails.eventTypeFriendlyName;
									break;
								}
							}
							eventText = 'other';
							break;
					}

					return eventText;
				}

				vm.getMachineLink = (machineId, time) => {
					const eventTime = time && new Date(time);
					if (featuresService.isEnabled(Feature.UpgradeMachinePage)) {
						return machinesService.getMachineLink(machineId, true, eventTime);
					} else {
						return $state.href('machine', {
							id: machineId,
							time: eventTime.toISOString(),
						});
					}
				};

				vm.gotoTimeline = function() {
					if (vm.alert.MachineId && vm.alert.MachineId.length > 0) {
						var state = $state.get('machine');
						if (!state.data) {
							state.data = {};
						}

						state.data.activeAlertId = vm.alert.AlertId;
						state.data.activeAlertSeverity = vm.alert.Severity;
						state.data.activeAlertTime = vm.alert.LastEventTime || vm.alert.LastSeen;
						state.data.slider = new Date(state.data.activeAlertTime);
					}
				};

				const getFileLink = (fileId: string, fileName?: string) =>
					filesService.getFileLink(fileId, null, fileName);

				var getNodeIcon = function(node) {
					if (!node.ElementType) {
						return;
					}

					switch (node.ElementType) {
						case 'File':
							return '\uE160'; // icon-Page
						case 'Registry':
							return '\uE74C'; //icon-OEM
						case 'Process':
							return '\uE115'; // icon-Settings
						case 'Module':
							return '\uE9F5'; // icon-Processing
						case 'Url':
							return '\uE167'; // icon-Link
						case 'NetworkConnection':
							return '\uE93E'; // icon-Streaming
						case 'GenericEtw':
							return genericEtwService.getEtwIcons(node.ActionType).alertProcessTreeIconCode;
						case 'Machine':
							return '\uE7F8'; // icon-DeviceLaptopNoPic
						case 'User':
							return '\uEE05'; // icon of user login
						default:
							return '\uE115'; // icon-Settings
					}
				};

				function getNodeUrl(node) {
					if (!node.ElementType) {
						return '';
					}

					switch (node.ElementType) {
						case 'GenericEtw':
							return genericEtwService.getEtwAlertNodeUrl(node);
						case 'File':
						case 'Process':
						case 'Module':
							if (node.Sha1 && RegExpService.sha1.test(node.Sha1)) {
								return getFileLink(node.Sha1, node.FileName);
							}
							break;
						case 'Registry':
							// No profile page for registry keys/values - so no link
							return '';
						case 'Url':
							return $state.href('url', { id: node.Url });
						case 'NetworkConnection':
							var ipUrl = node.IpAddress ? ipsService.getIpLink(node.IpAddress) : '';
							var domainUrl = node.Domain ? urlsService.getUrlLink(node.Domain) : '';
							return { ip: ipUrl, domain: domainUrl };
						case 'Machine':
							if (node.RemoteMachineId == 'multiple') {
								// If multiple matching machine ID were found - link to the search page
								return $state.href('search.machine', {
									id: node.RemoteMachineName,
								});
							} else if (node.RemoteMachineId) {
								// If a machine ID was found, link to that machine page
								// Use as timestamp the powershell process timestamp + 5 minute
								// (this delay is to show events that occur afterwards, to account for unsync clocks and because the Powershell script might take time to run)
								const timestamp: Date = new Date(node.ActionTime);
								timestamp.setMinutes(timestamp.getMinutes() + 5);

								return machinesService.getMachineLink(
									node.RemoteMachineId,
									true,
									new Date(timestamp)
								);
							} else {
								// If no machine with that name was found, return no link.
								return '';
							}
					}

					return '';
				}

				function truncateStringWithElipsis(text, maxLength) {
					return text !== null
						? text.substring(0, maxLength) + (text.length > maxLength ? '.....' : '')
						: text;
				}

				function getNodeAdditionalText(node, item) {
					if (!node.ActionType) {
						return '';
					}

					if (!node.InitiatingProcessName) {
						node.InitiatingProcessName = 'Unknown process';
					}

					switch (node.ActionType) {
						case 'UserLogon':
							if (node.AnomalousDescription) {
								item.additionalText2 = node.AnomalousDescription;
							}
							return node.LogonType + ' logon occurred before the detected activities';
						case 'CreateFile':
							if (node.ParentProcessType === 'Email') {
								return `Email attachment written to disk`;
							} else if (node.FileMotwHostUrl || node.FileMotwHostIp) {
								var downloadAddressDescription = node.FileMotwHostIp;
								if (node.FileMotwHostUrl) {
									downloadAddressDescription = truncateStringWithElipsis(
										node.FileMotwHostUrl,
										30
									);
								}
								return (
									node.InitiatingProcessName +
									' downloaded ' +
									node.FileName +
									' from ' +
									downloadAddressDescription
								);
							} else if (node.FileMotwReferrerUrl) {
								return node.InitiatingProcessName + ' extracted ' + node.FileName;
							} else {
								return node.InitiatingProcessName + ' created file ' + node.FileName;
							}
						case 'ShellStartLink':
							if (node.InitiatingProcessName) {
								var lowerParent = node.InitiatingProcessName.toLowerCase();
								if (
									lowerParent === 'outlook.exe' ||
									lowerParent === 'winword.exe' ||
									lowerParent === 'excel.exe' ||
									lowerParent === 'powerpnt.exe' ||
									lowerParent === 'skype.exe'
								) {
									// These known processes don't open links in the browser, so we know it was clicked by the user
									return 'User clicked on link';
								} else {
									// Unknown process - we don't know if url was opened due to a user click or not, so use more vague terms
									return node.InitiatingProcessName + ' opened link';
								}
							} else {
								// With lack of process details, default to a user-friendly description, even if not 100% accurate.
								return 'User clicked on link';
							}
						case 'FileSpecifiedInCommandline':
							var parentProcessName = node.InitiatingProcessName.toLowerCase();
							if (parentProcessName === 'winword.exe' || parentProcessName === 'powerpnt.exe') {
								return node.InitiatingProcessName + ' opened ' + node.FileName;
							} else if (
								parentProcessName.indexOf('7z') === 0 ||
								parentProcessName.indexOf('rar') !== -1 ||
								parentProcessName.indexOf('zip') !== -1
							) {
								return node.InitiatingProcessName + ' extracted ' + node.FileName;
							} else {
								return node.InitiatingProcessName + ' executed ' + node.FileName;
							}
						case 'CreateProcess':
							if (node.ParentProcessType === 'LogicalElevate') {
								return (
									'Process ' +
									node.FileName +
									' has been executed with elevated permissions via svchost.exe'
								);
							}
							if (node.ParentProcessType === 'LogicalWmi') {
								return (
									'Process ' +
									node.InitiatingProcessName +
									' executed ' +
									node.FileName +
									' via a WMI call'
								);
							}
							if (node.ParentProcessType === 'LogicalWmiRemote') {
								return (
									'Process ' +
									node.FileName +
									' was executed via a remote WMI call from ' +
									node.Parent.RemoteMachineName
								);
							}
							return '';
						case 'ConnectionSuccess':
						case 'TcpConnectionSuccess': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
						case 'ConnectionFailed':
						case 'TcpConnectionFailed': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
						case 'InboundConnectionAccepted':
						case 'InboundTcpConnectionAccepted': // TODO - remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
						case 'ConnectionRequest':
						case 'TcpConnectionRequest': // TODO -remove once PR 2349491 (InE) is completed and deployed to production. used for backward supportability until only action type will be sent by the BE instead of the network event type
						case 'ConnectionObserved': // TODO - backward support. remove when this value will disappear from network event type column in kusto (~March 19)
						case 'ConnectionFound':
							return (
								node.InitiatingProcessName +
								' ' +
								mapNetworkEventTypeToMessage(node.ActionType) +
								' ' +
								getNodeName(node) +
								' using port ' +
								node.Port
							);
						case 'RemotePowershellCommand':
							return (
								node.InitiatingProcessName +
								' executed Powershell commands on remote device ' +
								node.RemoteMachineName
							);
						case 'RemoteScQuery':
							return (
								node.InitiatingProcessName +
								' queried service details on remote device ' +
								node.RemoteMachineName
							);
						case 'RemoteScManage':
							return (
								node.InitiatingProcessName +
								' managed service on remote device ' +
								node.RemoteMachineName
							);
						case 'Registry':
							var registryOperationDescription = 'modified registry';
							switch (node.RegistryOperation) {
								case 'DeleteValue':
									registryOperationDescription = 'deleted registry value';
									break;
								case 'DeleteKey':
									registryOperationDescription = 'deleted registry key';
									break;
								case 'CreateKey':
									registryOperationDescription = 'created registry key';
									break;
								case 'RenameKey':
									registryOperationDescription = 'renamed registry key';
									break;
								case 'SetValue':
									registryOperationDescription = 'set registry value';
									break;
								default:
									$log.debug(
										'alert process tree: unsupported registry operation ' +
											node.RegistryOperation
									);
							}

							return node.InitiatingProcessName + ' ' + registryOperationDescription;
						case 'LoadImage':
							return node.InitiatingProcessName + ' loaded module ' + node.FileName;
						default:
							if (node.ElementType === 'GenericEtw') {
								var etwProcessTreeDetails = genericEtwService.getEtwAlertProcessTreeDetails(
									node.ActionType
								);
								if (etwProcessTreeDetails && etwProcessTreeDetails.nodeDescription != null) {
									return etwProcessTreeDetails.nodeDescription(node);
								}
							}
							return '';
					}
				}

				function getNodeName(node) {
					if (node.ElementType == 'Registry') {
						var registryFullPath = node.RegistryKey;
						if (node.RegistryValueName) {
							registryFullPath = node.RegistryKey + '\\' + node.RegistryValueName;
						}
						var maxRegistryLength = 70;
						if (registryFullPath.length <= maxRegistryLength) {
							// If registry path is short enough - return as-is
							return registryFullPath;
						}

						// Display up to maxRegistryLength characters from the end of the registry path
						var registryTokens = registryFullPath.split('\\');
						var shortenedRegisteryPath = registryTokens[registryTokens.length - 1];
						for (var i = registryTokens.length - 2; i > 0; i--) {
							const concatenatedPath = registryTokens[i] + '\\' + shortenedRegisteryPath;
							if (concatenatedPath.length > maxRegistryLength) {
								break;
							} else {
								shortenedRegisteryPath = concatenatedPath;
							}
						}

						return '...\\' + shortenedRegisteryPath;
					}

					if (node.ElementType === 'Machine') {
						return node.RemoteMachineName;
					}

					if (node.ElementType === 'User') {
						return node.Domain + '\\' + node.User;
					}

					if (node.ElementType === 'Url') {
						return truncateStringWithElipsis(node.Url, 70);
					}

					if (node.ElementType === 'GenericEtw') {
						var processTreeDetails = genericEtwService.getEtwAlertProcessTreeDetails(
							node.ActionType
						);
						if (processTreeDetails.nodeTitle != null) {
							return processTreeDetails.nodeTitle(node);
						}
					}

					if (node.ElementType === 'NetworkConnection') {
						var dnsString = node.Domain ? node.Domain + ' ' : '';
						var ipString = node.IpAddress
							? node.Domain
								? ' (' + node.IpAddress + ')'
								: node.IpAddress
							: '';
						return dnsString + ipString;
					}

					if (!node.FileName && node.ElementType === 'Process') {
						return 'Unknown process';
					}

					return node.FileName;
				}

				vm.renderGraph = function(graphData) {
					vm.graphDataWithTrees = [];

					totalHeight = defualtHeight; // default for start - will immediately change when we call the update function
					var width = '100%';

					const container = select('#processTree-container');

					// Remove existing svg elements if this is not the first time render is called
					container.select('svg').remove();

					container
						.append('svg')
						.attr('width', '100%')
						.attr('height', defualtHeight + margin.top + margin.bottom);

					svgObject = select('#processTree-container svg');

					vm.sidePaneUpdateUnsubscriber = $rootScope.$on('sidePane:paneUpdated', function(
						event,
						payload
					) {
						if (
							vm.selectedNode &&
							!vm.selectedNode.ElementId === sidePaneService.getOpenPaneKey() &&
							!vm.selectedNode.newSidePaneEntity
						) {
							// side pane was closed
							vm.selectedNode = null;
							updateNodeCheckboxes();
						}
					});

					var startY = 0;
					for (var i = 0; i < graphData.length; i++) {
						var data = graphData[i];
						vm.graphDataWithTrees.push(createTree(data, i, startY));
						startY += 200;
					}

					update(false);

					vm.graphDataWithTrees.forEach(tree => {
						collapseUnrelatedNodes([tree.mappedTree], false, tree.root.weight);
					});

					update(false);

					select(self.frameElement).style('height', '100%');

					function createTree(data, index, startY) {
						totalHeight = defualtHeight; // default for start - will immediately change when we call the update function
						updateNodeIds([data], '', 0);
						var containerEl: HTMLElement = <HTMLElement>(
								document.querySelector('#processTree-container')
							),
							width = containerEl ? containerEl.clientWidth : 960,
							height = totalHeight;

						vm.tree = tree().size([height, width]);
						const mappedTree = hierarchy(data, d => d.children || d._children);

						var svg = svgObject
							.append('g')
							.attr('id', 'tree_' + index)
							.attr('transform', 'translate(' + margin.left + ',' + startY + ')');

						// Make sure the line is at the bottom!
						var processTreeLine = svg.append('g').attr('class', 'processTreeLine');

						var processTreeData = svg.append('g').attr('class', 'processTreeData');

						var processTreeIcons = svg.append('g').attr('class', 'processTreeIcons');

						return {
							mappedTree: mappedTree,
							root: data,
							tree: tree,
							svg: svg,
							processTreeLine: processTreeLine,
							processTreeData: processTreeData,
							processTreeIcons: processTreeIcons,
						};
					}

					function collapseUnrelatedNodes(graphData, alertFoundInAncestors, treeWeight) {
						var alertFoundInDescendants = '';
						if (graphData && graphData.length) {
							for (var i = 0; i < graphData.length; i++) {
								var node = graphData[i];
								var alertInDirectDescendants = '';
								if (node.data.hasChildren) {
									alertInDirectDescendants = collapseUnrelatedNodes(
										node.children || node._children,
										alertFoundInAncestors || node.data.isAssociatedWithAlert,
										treeWeight
									);
								}

								// Keep Expanded:
								// All nodes (only very small trees)
								// Alert evidence
								// Direct paths to evidence
								// Descendants of evidence that do not have evidence (only for small trees)
								if (
									treeWeight < 10 ||
									node.data.isAssociatedWithAlert ||
									alertInDirectDescendants ||
									(alertFoundInAncestors && treeWeight < 20)
								) {
									// keep expanded. do nothing.
								} else {
									collapseNode(node);
								}

								alertFoundInDescendants =
									alertFoundInDescendants ||
									alertInDirectDescendants ||
									node.data.isAssociatedWithAlert;
							}
						}

						return alertFoundInDescendants;
					}
				};

				function updateNodeCheckboxes() {
					var icons = selectAll('.process-tree-side-pane-indicator-icon');
					icons.each(function(d) {
						setSidePaneIndicatorIconAndClass(d);
					});
				}
			},
		],
	};
}
