import { MachineHealthStatus } from './machine-health-status.entity';
import { keyBy, transform, values } from 'lodash-es';

enum StatusCategories {
	Active = 'Active',
	Misconfigured = 'Misconfigured',
	Inactive = 'Inactive',
}

const statusConfigs = {
	active: {
		name: 'Active',
		nameI18nKey: 'machine_healthStatus_active',
		category: StatusCategories.Active,
	},
	impairedComms: {
		name: 'Impaired communications',
		nameI18nKey: 'machine_healthStatus_impairedCommunications',
		description:
			'Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.',
		category: StatusCategories.Misconfigured,
	},
	noData: {
		name: 'No sensor data',
		nameI18nKey: 'machine_healthStatus_noSensorData',
		description:
			'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available.',
		category: StatusCategories.Misconfigured,
	},
	misconfigured: {
		name: 'No sensor data, impaired communications',
		nameI18nKey: 'machine_healthStatus_misconfigured',
		description:
			'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available. Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.',
		category: StatusCategories.Misconfigured,
	},
	inactive: {
		name: 'Inactive',
		nameI18nKey: 'machine_healthStatus_inactive',
		description: 'Device has completely stopped sending signals for more than 7 days.',
		category: StatusCategories.Inactive,
	},
};

export const machineHealthStatuses: { [index: string]: MachineHealthStatus } = transform(
	{
		MisconfiguredCyberNoCnc: {
			...statusConfigs.impairedComms,
			childOf: StatusCategories.Misconfigured,
		},
		MisconfiguredCncNoCyber: {
			...statusConfigs.noData,
			childOf: StatusCategories.Misconfigured,
		},
		MisconfiguredDefenderOnly: statusConfigs.misconfigured,
		MisconfiguredUtcAndDefenderOnly: statusConfigs.misconfigured,
		MisconfiguredUtcOnly: statusConfigs.misconfigured,
		InactiveDefault: statusConfigs.inactive,
		InactiveRecent: statusConfigs.inactive,
		InactiveGoneDark: statusConfigs.inactive,
		Active: statusConfigs.active,
		ActiveDefault: statusConfigs.active,
	},
	(result, value, key) => {
		result[key] = Object.assign({}, value, { id: key });
	},
	{}
);

export const machineHealthStatusValues: Array<MachineHealthStatus> = values(machineHealthStatuses);

export const machineHealthStatusMap = keyBy(machineHealthStatusValues, status => status.id);
