import { ReleaseVersion } from './release-version.entity';
import {keyBy} from "lodash-es";

export enum ReleaseVersions {
	Windows10RS1 = '1607',
	Windows10RS2 = '1703',
	Windows10RS3 = '1709',
	Windows10RS4 = '1803',
	Windows10RS5 = '1809',
	Windows10RS6 = '1903',
	Windows10RS7 = '1909',
	Windows10RS8 = '2004',
	Windows10RS9 = '20H2',
	Windows10RS10 = '21H1',
	Windows10RS11 = '21H2',
	Future = 'Future',
}

export enum CodeToVersion {
	RS1 = '1607',
	RS2 = '1703',
	RS3 = '1709',
	RS4 = '1803',
	RS5 = '1809',
	RS6 = '1903',
	RS7 = '1909',
	RS8 = '2004',
	RS9 = '20H2',
	RS10 = '21H1',
	RS11 = '21H2',
	Future = 'Future',
}

export const releaseVersionValues: Array<ReleaseVersion> = [
	{
		id: ReleaseVersions.Windows10RS1,
		name: ReleaseVersions.Windows10RS1,
		priority: 1,
	},
	{
		id: ReleaseVersions.Windows10RS2,
		name: ReleaseVersions.Windows10RS2,
		priority: 2,
	},
	{
		id: ReleaseVersions.Windows10RS3,
		name: ReleaseVersions.Windows10RS3,
		priority: 3,
	},
	{
		id: ReleaseVersions.Windows10RS4,
		name: ReleaseVersions.Windows10RS4,
		priority: 4,
	},
	{
		id: ReleaseVersions.Windows10RS5,
		name: ReleaseVersions.Windows10RS5,
		priority: 5,
	},
	{
		id: ReleaseVersions.Windows10RS6,
		name: ReleaseVersions.Windows10RS6,
		priority: 6,
	},
	{
		id: ReleaseVersions.Windows10RS7,
		name: ReleaseVersions.Windows10RS7,
		priority: 7,
	},
	{
		id: ReleaseVersions.Windows10RS8,
		name: ReleaseVersions.Windows10RS8,
		priority: 8,
	},
	{
		id: ReleaseVersions.Windows10RS9,
		name: ReleaseVersions.Windows10RS9,
		priority: 9,
	},
	{
		id: ReleaseVersions.Windows10RS10,
		name: ReleaseVersions.Windows10RS10,
		priority: 10,
	},
	{
		id: ReleaseVersions.Windows10RS11,
		name: ReleaseVersions.Windows10RS11,
		priority: 11,
	},
	{
		id: ReleaseVersions.Future,
		name: ReleaseVersions.Future,
		priority: 12,
	},
];

export const releaseVersionMap = keyBy(releaseVersionValues, os => os.id);
