import { Component } from '@angular/core';
import { AirsEntity } from '@wcd/domain';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { Observable } from 'rxjs';
import { EntitiesPanelComponentBase } from '../../../global_entities/components/entity-panels/entities-panel.component.base';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { toObservable } from '../../../utils/rxjs/utils';
import { PENDING_ACTION_ID_STR, UNDO_ACTION_ID_STR } from '../services/airs-entity.entity-type.service';

@Component({
	selector: 'airs-entity-multi-entity-panel',
	template: `
		<div *ngIf="(showWarning$ | async)" class="wcd-margin-large-horizontal wcd-flex">
			<fab-icon iconName="Warning" contentClass="color-text-error"></fab-icon>
			<span class="wcd-padding-small-left">{{
				i18nService.strings.airsEntities_panel_multipleStatus_warning
			}}</span>
		</div>
	`,
})
export class AirsEntityEntityPanelMultiComponent extends EntitiesPanelComponentBase<AirsEntity> {
	private airsEntityEntityTypeService: EntityType<AirsEntity>;
	showWarning$: Observable<boolean>;

	constructor(public i18nService: I18nService, private globalEntityTypesService: GlobalEntityTypesService) {
		super();
		this.airsEntityEntityTypeService = this.globalEntityTypesService.getEntityType(AirsEntity);
	}

	setEntities(entities: Array<AirsEntity>) {
		super.setEntities(entities);
		const actions$ = toObservable(this.airsEntityEntityTypeService.getActions(entities));
		this.showWarning$ = actions$.pipe(
			map(
				actions =>
					!actions.some(a => a.id.includes(PENDING_ACTION_ID_STR) || a.id === UNDO_ACTION_ID_STR)
			)
		);
	}
}
