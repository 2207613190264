var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityMinimizedDetailsComponentBase } from './entity-minimized-details.component.base';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { map, share, take } from 'rxjs/operators';
import { of } from 'rxjs';
import { ServiceUrlsService } from '@wcd/app-config';
import { I18nService } from '@wcd/i18n';
var UserMinimizedDetailsComponent = /** @class */ (function (_super) {
    __extends(UserMinimizedDetailsComponent, _super);
    function UserMinimizedDetailsComponent(globalEntityTypesService, serviceUrls, i18nService) {
        var _this = _super.call(this, globalEntityTypesService, i18nService) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.serviceUrls = serviceUrls;
        _this.i18nService = i18nService;
        _this.name = null;
        _this.nameInitials = null;
        _this.size24 = 1;
        return _this;
    }
    UserMinimizedDetailsComponent.prototype.setEntity = function (entity) {
        var _this = this;
        _super.prototype.setEntity.call(this, entity);
        this.name = this.entityType.getEntityName(entity);
        this.nameInitials = this.name
            ? this.name
                .split(' ')
                .map(function (n) { return n[0]; })
                .join('')
                .toUpperCase()
            : null;
        this.iconCssClass = this.entityType.getIconCssClass && this.entityType.getIconCssClass([this.entity]);
        this.iconImageUrl$ = this.entityType.getImage
            ? toObservable(this.entityType.getImage([this.entity])).pipe(map(function (imageUrl) { return "" + _this.serviceUrls.assetsBaseUrl + imageUrl; }), take(1), share())
            : of(null);
    };
    return UserMinimizedDetailsComponent;
}(EntityMinimizedDetailsComponentBase));
export { UserMinimizedDetailsComponent };
