import { Component, OnInit } from '@angular/core';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { ProfileForCreationFormData } from '../../profiles-tab/models/profile-for-creation.model';
import { Subject, of } from 'rxjs';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { catchError, map } from 'rxjs/operators';
import { BaselineProfileCompletedComponent } from './baseline-profile-completed.component';
import { DISPLAY_MODE as DETAILS_DISPLAY_MODE } from '../../../../../shared/components/details-list/details-list.component';
import { ProfilesService } from '../../profiles-tab/services/profiles.service';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './profile-review-step.component.html',
})
export class ProfileReviewStepComponent extends WizardBaseStep<ProfileForCreationFormData>
	implements OnInit {
	saving$ = new Subject<boolean>();

	dlDisplayMode: DETAILS_DISPLAY_MODE = DETAILS_DISPLAY_MODE.GRID;
	name: string;
	description: string;
	status: string;
	operatingSystem: string;
	benchmark: string;
	complianceLevel: string;
	configurationsAssignment: {[category: string]: number}

	constructor(
		public i18n: I18nService,
		private dialogsService: DialogsService,
		private profilesService: ProfilesService
		) {
		super();
	}
	createProfile = () => {
		this.setBackButtonEnabled(false);
		this.setStepValidation(false);
		this.saving$.next(true);
		return this.profilesService.saveBaselineProfile(this.data).pipe(
			map(() => {
				this.markWizardCompleted(BaselineProfileCompletedComponent);
				this.saving$.next(false);
				return true;
			}),
			catchError(() => {
				this.dialogsService.showSnackbar({
					icon: 'ErrorBadge',
					text: this.i18n.strings.tvm_baseline_profile_summaryStep_creationError,
					className: 'color-box-failureBackground',
				});
				this.setBackButtonEnabled(true);
				this.setStepValidation(true);
				this.saving$.next(false);
				return of(false);
			})
		);
	};

	ngOnInit(): void {
		this.setOnNext(this.createProfile);
		this.setStepValidation(true);
		this.name = this.data.name;
		this.description = this.data.description;
		this.status = this.data.isEnabled ?
			this.i18n.strings.tvm_baseline_profile_wizard_status_enabled :
			this.i18n.strings.tvm_baseline_profile_wizard_status_disabled;
		this.operatingSystem = `${this.data.operatingSystem} ${this.data.operatingSystemVersion}`;
		this.benchmark = `${this.data.benchmark} ${this.data.benchmarkVersion}`;
		this.complianceLevel = this.data.complianceLevel;

		this.data.checks = this.data.selectedSettings.map(setting => setting.id);
		this.configurationsAssignment = this.data.selectedSettings.reduce((assignmentsByCategory, selectedSetting) => {
			if(!assignmentsByCategory[selectedSetting["category"]])
				assignmentsByCategory[selectedSetting["category"]] = 0;
			assignmentsByCategory[selectedSetting["category"]]++;
			return assignmentsByCategory;
		  }, {});
	}
}
