var _a;
export var AlertClassificationType;
(function (AlertClassificationType) {
    AlertClassificationType["Unknown"] = "Unknown";
    AlertClassificationType["NotSet"] = "NotSet";
    AlertClassificationType["FalsePositive"] = "FalsePositive";
    AlertClassificationType["TruePositive"] = "TruePositive";
    AlertClassificationType["BenignPositive"] = "BenignPositive";
})(AlertClassificationType || (AlertClassificationType = {}));
export var NewAlertClassificationType;
(function (NewAlertClassificationType) {
    NewAlertClassificationType["Unknown"] = "Unknown";
    NewAlertClassificationType["NotSet"] = "NotSet";
    NewAlertClassificationType["FalsePositive"] = "FalsePositive";
    NewAlertClassificationType["TruePositive"] = "TruePositive";
    NewAlertClassificationType["BenignPositive"] = "BenignPositive";
})(NewAlertClassificationType || (NewAlertClassificationType = {}));
export var AlertClassificationId = (_a = {},
    _a[AlertClassificationType.Unknown] = '0',
    _a[AlertClassificationType.FalsePositive] = '10',
    _a[AlertClassificationType.TruePositive] = '20',
    _a[NewAlertClassificationType.BenignPositive] = '30',
    _a);
export var alertClassificationValues = [
    {
        id: AlertClassificationId.Unknown,
        name: 'Not set',
        nameI18nKey: 'alertsByClassification_unknown',
        type: AlertClassificationType.Unknown,
        priority: 3,
        isSelectable: true,
    },
    {
        id: AlertClassificationId.FalsePositive,
        name: 'False alert',
        nameI18nKey: 'alertsByClassification_falseAlert',
        type: AlertClassificationType.FalsePositive,
        priority: 2,
        isSelectable: true,
    },
    {
        id: AlertClassificationId.TruePositive,
        name: 'True alert',
        nameI18nKey: 'alertsByClassification_trueAlert',
        type: AlertClassificationType.TruePositive,
        priority: 1,
        allowDetermination: true,
        isSelectable: true,
    },
    {
        id: AlertClassificationId.BenignPositive,
        name: 'Benign Positive',
        nameI18nKey: 'alertsByClassification_BenignPositive',
        type: NewAlertClassificationType.BenignPositive,
        priority: 1,
        allowDetermination: true,
        isSelectable: true,
        combinedClassification: true
    },
];
