import { Component } from '@angular/core';
import { Alert } from '@wcd/domain';
import { EntityStatusComponentBase } from '../../../global_entities/components/entity-status/entity-status.component.base';

@Component({
	template: `
		<div>
			<span
				class="wcd-severity wcd-margin-large-right"
				[ngClass]="'wcd-severity-' + alert.severity.type"
				role="presentation"
				wcdTooltip="{{ 'alerts.severity' | i18n }}: {{ alert.severity.nameI18nKey | i18n }}"
			>
				{{ alert.severity.nameI18nKey | i18n }}
			</span>
			<strong
				role="presentation"
				wcdTooltip="{{ 'alerts.status.title' | i18n }}: {{ alert.status.name }}"
				>{{ alert.status.nameI18nKey | i18n }}</strong
			>
		</div>
	`,
})
export class AlertStatusComponent extends EntityStatusComponentBase<Alert> {
	get alert() {
		return this.entity;
	}
}
