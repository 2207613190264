/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tag.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltip.directive";
import * as i7 from "../../../../../../../projects/dialogs/src/lib/tooltips/tooltips.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./tag.component";
import * as i12 from "../../services/tags.service";
var styles_TagComponent = [i0.styles];
var RenderType_TagComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TagComponent, data: {} });
export { RenderType_TagComponent as RenderType_TagComponent };
function View_TagComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "a", [["class", "link-remove wcd-flex-none"], ["data-track-id", "RemoveTag"], ["data-track-type", "Action"], ["role", "button"], ["tabindex", "0"]], [[8, "title", 0], [1, "aria-label", 0]], [[null, "click"], [null, "keydown.enter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.removeButtonClick() !== false);
        ad = (pd_0 && ad);
    } if (("keydown.enter" === en)) {
        var pd_1 = (_co.removeButtonClick() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵpod(1, { name: 0 }), i1.ɵppd(2, 2), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "wcd-shared-icon", [["iconName", "close"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i1.ɵdid(5, 573440, null, 0, i3.IconComponent, [i4.IconsService, i1.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_2 = "close"; _ck(_v, 5, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.tags_removeTag; var currVal_1 = i1.ɵunv(_v, 0, 1, _ck(_v, 2, 0, i1.ɵnov(_v.parent.parent, 0), "tags.removeTagName", _ck(_v, 1, 0, _co.computedTag.name))); _ck(_v, 0, 0, currVal_0, currVal_1); }); }
function View_TagComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "span", [["class", "tag wcd-flex-horizontal"]], [[2, "partial", null]], [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 3).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 3).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 3).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 3).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵdid(3, 147456, null, 0, i6.TooltipDirective, [i1.ElementRef, i7.TooltipsService, i8.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], filterHtml: [1, "filterHtml"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagComponent_2)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "span", [["class", "tag-name"]], null, null, null, null, null)), (_l()(), i1.ɵted(9, null, ["\n\t\t", "\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = "tag wcd-flex-horizontal"; var currVal_2 = _co.getClass(_co.tag); _ck(_v, 2, 0, currVal_1, currVal_2); var currVal_3 = (_co.computedTag.tooltip || _co.computedTag.name); var currVal_4 = true; _ck(_v, 3, 0, currVal_3, currVal_4); var currVal_5 = (_co.removable && (_co.computedTag.isEditable !== false)); _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.computedTag.isPartial; _ck(_v, 0, 0, currVal_0); var currVal_6 = _co.computedTag.name; _ck(_v, 9, 0, currVal_6); }); }
export function View_TagComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i9.I18nPipe, [i10.I18nService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TagComponent_1)), i1.ɵdid(2, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.computedTag; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TagComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tag", [], null, null, null, View_TagComponent_0, RenderType_TagComponent)), i1.ɵdid(1, 573440, null, 0, i11.TagComponent, [i12.TagsService, i10.I18nService], null, null)], null, null); }
var TagComponentNgFactory = i1.ɵccf("tag", i11.TagComponent, View_TagComponent_Host_0, { className: "className", tag: "tag", removable: "removable" }, { removeClick: "removeClick" }, []);
export { TagComponentNgFactory as TagComponentNgFactory };
