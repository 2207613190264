import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { AdvancedSettingsStore } from '../services/advanced-settings.store';
import { RegExpService } from '@wcd/shared';
import { NgForm } from '@angular/forms';
import { AdvancedSettingsModel } from '../models/advanced-settings.model';
import { FormComponent } from '../../../shared/models/form.component';
import { FeaturesService, Feature } from '@wcd/config';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'advanced-settings',
	templateUrl: './advanced-settings.component.html',
})
export class AdvancedSettingsComponent implements FormComponent, OnInit {
	isSaving: boolean = false;
	settings: AdvancedSettingsModel;
	isMemoryContentEnabled: boolean;

	@ViewChild('advancedSettingsForm', { static: false }) settingsForm: NgForm;

	constructor(
		private advancedSettingsStore: AdvancedSettingsStore,
		public authService: AuthService,
		private featuresService: FeaturesService,
		private performanceSccService: PerformanceSccService
	) {
		this.isMemoryContentEnabled = featuresService.isEnabled(Feature.AutomatedIRInvestigateProcess);
	}

	get regExpService(): any {
		return RegExpService;
	}

	get isDirty(): boolean {
		return this.settingsForm.dirty;
	}

	ngOnInit() {
		this.advancedSettingsStore.getAdvancedSettings().subscribe((settings: AdvancedSettingsModel) => {
			this.performanceSccService.endNgPageLoadPerfSession('endpoints-automation-uploads');
			this.settings = settings;
		});
	}

	saveSettings() {
		this.isSaving = true;
		this.advancedSettingsStore
			.save(this.settings)
			.then((settings: AdvancedSettingsModel) => {
				this.isSaving = false;
				this.settingsForm.form.markAsPristine();
			})
			.catch(() => {
				this.isSaving = false;
			});
	}

	cancelEdit() {
		this.settings.restore();
		this.settingsForm.form.markAsPristine();
	}
}
