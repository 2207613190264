<!-- tslint:disable:template-click-events-have-key-events -->
<ng-container *ngIf="description || recommendedAction">
	<markdown *ngIf="allowHtmlRendering; else plainDescription"
	[data]="descriptionText"
	class="wcd-block"></markdown>

	<a *ngIf="showOauthUrl"
		[href]="oAuthAlertPageLink"
		class="oAuthAlert-link"
		target="_blank"	>
		{{ oauthUrlText }}
	</a>

	<ng-template #plainDescription>
		<div class="wcd-block pre-line">
			{{ descriptionText }}
		</div>
	</ng-template>

	<div *ngIf="decodedLinkToEncyclopedia"
		class="wcd-padding-small-top">
		<a	[href]="decodedLinkToEncyclopedia"
			class="encyclopedia-link"
			target="_blank"
			data-track-id="MicrosoftEncyclopediaReadMore"
			data-track-type="ExternalLink">
			<fab-icon iconName="ReadingMode"></fab-icon>
			{{ 'alert.description.defender.readMore' | i18n }}
		</a>
	</div>

	<ng-container *ngIf="expandableDescription">
		<div class="read-more ms-fontColor-blue wcd-padding-largeMedium-top"
			(click)="descriptionExpanded = !descriptionExpanded">
			{{ descriptionExpanded ? ('alert.threatExpert.description.readLess' | i18n)  : ('alert.threatExpert.description.readMore' | i18n) }}
		</div>
	</ng-container>
</ng-container>
