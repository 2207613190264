import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { Omit } from '@wcd/types';
import * as Requests from '../machine-requests.models';
import { IsolationAction, IsolationRawApiRequestBody, RawIsolationAction } from './isolation-requests.models';
import { MachineIsolationRequest } from './machine-isolation-request.value-object';

export type CancelIsolationRawApiRequest = Requests.CancelApiRequestBody<'IsolationRequest'>;
export type CancelIsolationParameters = Requests.CancelParameters;

@ApiCall({
	name: 'Cancel machine isolation/unisolation',
	endpoint: 'requests/cancelbyid',
	method: 'POST',
	type: MachineIsolationRequest,
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: ({
		requestGuid,
		comment,
	}: CancelIsolationParameters): HttpOptions<CancelIsolationRawApiRequest> => ({
		params: {
			requestGuid: requestGuid,
			comment: comment,
		},
	}),
})
export class MachineCancelIsolationApiCall extends ApiCallModel<
	MachineIsolationRequest,
	CancelIsolationParameters
> {}
