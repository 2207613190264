var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityDetailsMode } from '../../../global_entities/models/entity-details-mode.enum';
var UserEntityPanelComponent = /** @class */ (function (_super) {
    __extends(UserEntityPanelComponent, _super);
    function UserEntityPanelComponent(changeDetectorRef) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.EntityDetailsMode = EntityDetailsMode;
        return _this;
    }
    Object.defineProperty(UserEntityPanelComponent.prototype, "user", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    return UserEntityPanelComponent;
}(EntityPanelComponentBase));
export { UserEntityPanelComponent };
