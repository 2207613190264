import { ChangeDetectionStrategy, Component, Input, ViewChild, ElementRef, OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { DataviewActionTypes } from './dataview-actions.model';
import { isNil } from 'lodash-es';
import { Panel, PanelComponent, PanelType } from '@wcd/panels';
import { FabricIconNames } from '@wcd/scc-common';
import { FiltersComponent, FiltersState } from '@wcd/ng-filters';
import { DataViewActionElementButtonPicker } from './dataview-action-wrapper.component';

export const FILTERS_PANEL_SETTINGS: Panel = new Panel({
	type: PanelType.smallFixedFar,
	headerText: 'Filters',
	showOverlay: false,
	isBlocking: false,
	isModal: true,
	isStatic: false,
	hasCloseButton: true,
	noBodyPadding: true,
});

export interface DataviewActionFilterConfig {
	showText: boolean;
	disableFilterPanelAutoFocus: boolean;
	datatablePadTop: boolean;
	onFiltersChangedCallback: () => void;
	onFiltersCancelCallback?: () => void;
	dataviewId: string;
	actionType: DataviewActionTypes;
	defaultQueryFilters: Record<string, any>;
}

@Component({
	selector: 'dataview-action-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<button
			[attr.id]="dataviewId + (isInMoreMenu ? '-more' : '')"
			class="hidden-action btn btn-link command-bar-item-button icon-only command-bar-filter"
			[class.checked]="filtersOpened"
			(click)="toggleFilters()"
			data-track-id="toggleFilters"
			data-track-type="Button"
			*ngIf="dataViewModel.allowFilters"
			[attr.aria-label]="'grid.commands.filters.text' | i18n"
			[wcdTooltip]="getLocalizedTooltip()"
			#filterButton
		>
			<fab-icon [iconName]="icon"></fab-icon>
			<span *ngIf="showText && !smallScreenView">{{ 'grid.commands.filters.text' | i18n }}</span>
		</button>

		<wcd-panel
			#filterPanel
			(close)="toggleFilters(false)"
			(open)="toggleFilters(true)"
			class="responsive-panel"
			*ngIf="dataViewModel?.allowFilters && filtersOpened"
			[disableAutoFocus]="disableFilterPanelAutoFocus"
			[class.wcd-padding-large-top]="datatablePadTop"
			[settings]="filtersPanelSettings"
			(keydown.arrowUp)="onKeydown($event)"
			(keydown.arrowDown)="onKeydown($event)"
		>
			<div class="wcd-full-height wcd-flex-center-all" *ngIf="dataViewModel.filtersError; else filters">
				<span class="error-message">
					{{ i18nService.strings.dataview_error_loading_filters }}
				</span>
			</div>
			<ng-template #filters>
				<ng-container *ngIf="dataViewModel.filtersData$ | async as filtersData; else loadingFilters">
					<wcd-filters
						[data]="filtersData"
						[fields]="dataViewModel.filterFields"
						[fixedSelectedValues]="dataViewModel.fixedFilterValues"
						[serializedFilters]="dataViewModel.filtersState?.serialized"
						class="wcd-full-height"
						(filtersChange)="onFiltersChanged($event)"
						(filtersCancel)="onFiltersCancelCallback ? onFiltersCancelCallback() : null"
						[parentFocusable]="filterPanel.binnedFocusOnFirstfocusable"
					>
					</wcd-filters>
				</ng-container>
				<ng-template #loadingFilters>
					<div class="wcd-full-height wcd-flex-center-all">
						<fab-spinner [label]="'filters.loading' | i18n"></fab-spinner>
					</div>
				</ng-template>
			</ng-template>
		</wcd-panel>
	`,
})
export class DataviewActionFilterComponent implements DataViewActionElementButtonPicker, OnInit {
	@Input() filterActionConfig: DataviewActionFilterConfig;
	@Input() dataViewModel: DataViewModel;
	@Input() smallScreenView: boolean = false;
	@Input() isInMoreMenu: boolean = true;

	@ViewChild('filterButton', { static: false }) filterButton: ElementRef;
	@ViewChild(FiltersComponent, { static: false }) private _filtersComponent: FiltersComponent;
	@ViewChild(PanelComponent, { static: false }) filterPanel: PanelComponent;

	icon: string = FabricIconNames.Filter;
	showText: boolean;
	disableFilterPanelAutoFocus: boolean;
	datatablePadTop: boolean;
	onFiltersChangedCallback: (filtersState: FiltersState) => void;
	onFiltersCancelCallback: () => void;
	dataviewId: string;
	filtersPanelSettings: Panel;
	filtersOpened: boolean = false;
	defaultQueryFilters: Record<string, any>;
	binnedFocusOnFirstFocusable: () => void;

	buttonElementPicker() {
		return this.filterButton.nativeElement;
	}

	getLocalizedTooltip() {
		return this.filtersOpened
			? this.i18nService.get('grid.commands.filters.hide')
			: this.i18nService.get('grid.commands.filters.show');
	}

	onFiltersChanged(filtersState: FiltersState) {
		if (!filtersState.selection || Object.keys(filtersState.selection).length === 0) {
			this.resetFilters(filtersState);
		}
		this.onFiltersChangedCallback(filtersState);
	}

	toggleFilters = (isOpen?: boolean) => {
		this.disableFilterPanelAutoFocus = false;
		this.filtersOpened = isNil(isOpen) ? !this.filtersOpened : isOpen;
		if (!this.filtersOpened) {
			setTimeout(() => this.filterButton.nativeElement.focus(), 0);
		}
	};

	resetFilters(filtersState: FiltersState) {
		filtersState.selection = this.defaultQueryFilters;
		filtersState.serialized = this.defaultQueryFilters;
		this._filtersComponent.resetSelectedValues(this.defaultQueryFilters);
	}

	onKeydown($event: KeyboardEvent) {
		$event.stopPropagation();
	}

	ngOnInit() {
		Object.assign(this, { ...this.filterActionConfig });
	}

	constructor(public i18nService: I18nService) {
		this.filtersPanelSettings = new Panel(FILTERS_PANEL_SETTINGS);
	}
}
