import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { File } from '../file.entity';
import { FileVerdict } from '../file.file-verdict.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: FileVerdict,
	allowedTypes: [RelationshipType.OneToMany],
	baseUrl: config => config.data.serviceUrls.files,
	endpoint: (_, query) => `files/${query.where['sha1']}/FileVerdict`,
	getRelationshipData: (file: File) => ({
		sha1: file.sha1,
	}),
	parseData: rawData => ({
		items: rawData.FileVerdicts,
		count: rawData.Count,
	}),
	parseDataQuery: (query: DataQuery) => null,
	allItemsEndpointTrailingSlash: false,
	cache: {
		time: 30 * 1000,
	},
})
export class FileFileVerdictsRelationship implements EntityRelationshipRepositoryType<File, FileVerdict> {}
