<div class="wcd-full-height wcd-flex-vertical">
	<section class="wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-vertical wcd-scroll-vertical">
		<change-event-entity-details [changeEvent]="changeEvent"></change-event-entity-details>
	</section>
	<footer class="wcd-padding-small-top ng-star-inserted flex-bottom" style="">
        <div class="wcd-flex-none wcd-padding-all wcd-padding-large-horizontal">
			<fab-primary-button
				data-track-id="navigateToRelatedRecommendation"
				data-track-type="Navigation"
				(onClick)="navigateToRecommendation()"
				[text]="'tvm.changeEvent.goToRelatedRecommendation' | i18n">
			</fab-primary-button>
		</div>
	</footer>
</div>