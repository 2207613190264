import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import {
	Asset,
	RecommendationContextKeyAndExposedMachinesCount,
	AssetRecommendationContext,
	RecommendationContextType,
	RecommendationContextProperties,
} from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { WcdIconNames } from '@wcd/icons';
import { PrettyNumberService } from '@wcd/prettify';
import { Feature, FeaturesService } from '@wcd/config';
import { TvmTextsService } from '../../../../../app/tvm/services/tvm-texts.service';

@Injectable()
export class RecommendationContextFieldsService {
	private _recommendationContextToCountField = new Map<RecommendationContextType, DataviewField>();

	constructor(
		private prettyNumberService: PrettyNumberService,
		private featuresService: FeaturesService,
		private tvmTextsService: TvmTextsService
	) {
		// Build the fields of exposed recommendation context count header
		const recommendationContextTypes = Object.keys(
			this.tvmTextsService.recommendationContextToCountHeader
		) as Array<RecommendationContextType>;
		recommendationContextTypes.forEach(recommendationContextType => {
			this._recommendationContextToCountField.set(
				recommendationContextType,
				new DataviewField({
					id: 'recommendationContextCount',
					name: this.tvmTextsService.recommendationContextToCountHeader[recommendationContextType],
					sort: { enabled: false },
					getDisplay: (asset: Asset) => asset.recommendationContext.length,
					className: 'nowrap wcd-text-overflow-medium',
					alwaysDisplay: true,
				})
			);
		});
	}

	private _serviceNameField = new DataviewField({
		id: 'serviceName',
		name: 'Service Name',
		sort: { enabled: false },
		icon: {
			wcdIcon: WcdIconNames.service,
		},
		className: 'field-value-link nowrap wcd-text-overflow-medium',
	});
	private _pathField = new DataviewField({
		id: 'path',
		name: 'Service Path',
		sort: { enabled: false },
	});
	private _sharePathField = new DataviewField({
		id: 'sharePath',
		name: 'Share Path',
		sort: { enabled: false },
	});
	private _pathForContextPropsField = new DataviewField({
		id: 'path',
		name: 'Service Path',
		sort: { enabled: false },
		getDisplay: (prop: RecommendationContextProperties) => prop.recommendationContext.path,
	});
	private _sharePathForContextPropsField = new DataviewField({
		id: 'sharePath',
		name: 'Share Path',
		sort: { enabled: false },
		getDisplay: (prop: RecommendationContextProperties) => prop.recommendationContext.path,
	});	
	private _userNameField = new DataviewField({
		id: 'userName',
		name: 'User Name',
		sort: { enabled: false },
		icon: {
			wcdIcon: WcdIconNames.user,
		},
		className: 'field-value-link nowrap wcd-text-overflow-medium',
	});
	private _shareNameField = new DataviewField({
		id: 'shareName',
		name: 'Share Name',
		sort: { enabled: false },
		icon: {
			wcdIcon: WcdIconNames.service,
		},
		className: 'field-value-link nowrap wcd-text-overflow-medium',
	});
	private _exposedMachinesField = new DataviewField({
		id: 'exposedMachines',
		name: 'Exposed Devices',
		sort: { enabled: false },
		getDisplay: (event: RecommendationContextKeyAndExposedMachinesCount) =>
			this.prettyNumberService.prettyNumber(event.exposedMachines),
	});
	private _machineNameField = new DataviewField({
		id: 'name',
		name: 'Device Name',
		sort: { enabled: false },
		getLink: (prop: RecommendationContextProperties) =>
			this.featuresService.isEnabled(Feature.UpgradeMachinePage)
				? `/machines/${prop.assetId}`
				: `/machine/${prop.assetId}`,
		icon: {
			fabricIcon: FabricIconNames.System,
		},
		className: 'nowrap wcd-text-overflow-medium',
	});
	private _expandField = new DataviewField({
		id: 'expand',
		name: '',
		enabledByDefault: true,
		alwaysDisplay: true,
		allowResize: false,
		getFieldCssClass: (asset: Asset) =>
			`${asset.recommendationContext.length > 0 ? 'datatable-expand' : ''}`,
		sort: { enabled: false },
	});

	private _servicesExposedMachinesFields = [this._serviceNameField, this._exposedMachinesField];
	private _usersExposedMachinesFields = [this._userNameField, this._exposedMachinesField];
	private _sharesExposedMachinesFields = [this._shareNameField, this._exposedMachinesField];

	private _servicesWithPathPropertiesFields = [this._machineNameField, this._pathForContextPropsField];
	private _servicesPropertiesFields = [this._machineNameField];
	private _usersPropertiesFields = [this._machineNameField];
	private _sharesWithPathPropertiesFields = [this._machineNameField, this._sharePathForContextPropsField];

	private _allServicesWithPathFields = [this._serviceNameField, this._pathField];
	private _allServicesFields = [this._serviceNameField];
	private _allUsersFields = [this._userNameField];
	private _allSharesWithPathFields = [this._shareNameField, this._sharePathField]

	getExposedMachinesCountPerRecommendationContextKeyFields(
		recommendationContextType: RecommendationContextType
	): Array<DataviewField<RecommendationContextKeyAndExposedMachinesCount>> {
		if (
			recommendationContextType === RecommendationContextType.ContextWithServiceName ||
			recommendationContextType === RecommendationContextType.ContextWithServiceNameAndServicePath
		) {
			return this._servicesExposedMachinesFields;
		} else if (recommendationContextType === RecommendationContextType.ContextWithUserName) {
			return this._usersExposedMachinesFields;
		} else if (recommendationContextType === RecommendationContextType.ContextWithShareNameAndSharePath) {
			return this._sharesExposedMachinesFields
		}
		return [];
	}

	getRecommendationContextProperties(
		recommendationContextType: RecommendationContextType
	): Array<DataviewField<RecommendationContextProperties>> {
		if (recommendationContextType === RecommendationContextType.ContextWithServiceName) {
			return this._servicesPropertiesFields;
		} else if (
			recommendationContextType === RecommendationContextType.ContextWithServiceNameAndServicePath
		) {
			return this._servicesWithPathPropertiesFields;
		} else if (recommendationContextType === RecommendationContextType.ContextWithUserName) {
			return this._usersPropertiesFields;
		} else if (recommendationContextType === RecommendationContextType.ContextWithShareNameAndSharePath) {
			return this._sharesWithPathPropertiesFields;
		}
		return [];
	}

	getAssetRecommendationContextFields(
		recommendationContextType: RecommendationContextType
	): Array<DataviewField<AssetRecommendationContext>> {
		if (recommendationContextType == RecommendationContextType.ContextWithServiceName) {
			return this._allServicesFields;
		} else if (
			recommendationContextType == RecommendationContextType.ContextWithServiceNameAndServicePath
		) {
			return this._allServicesWithPathFields;
		} else if (recommendationContextType == RecommendationContextType.ContextWithUserName) {
			return this._allUsersFields;
		} else if (recommendationContextType == RecommendationContextType.ContextWithShareNameAndSharePath) {
			return this._allSharesWithPathFields;
		}
		return [];
	}

	getRecommendationContextCountField(recommendationContextType: RecommendationContextType): DataviewField {
		return this._recommendationContextToCountField.get(recommendationContextType);
	}

	getRecommendationContextTableExpandField() {
		return this._expandField;
	}
}
