var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { Injector, OnInit, ChangeDetectorRef, } from '@angular/core';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { SecurityRecommendation, RemediationType, RecommendationInstalledAssetsRelationship, RecommendationContextType, RecommendationRelatedVulnerabilitiesRelationship, Vulnerability, RecommendatioMitigatedVulnerabilitiesRelationship, RecommendationExposedAssetsRelationship, TvmEndPoint, ProductivityImpactRemediationType, RecommendationCategory, RecommendationExposedOperatingSystemsRelationship, RecommendationContextKeyAndExposedMachinesCountRelationship, AssetRecommendationContextRelationship, AssetRecommendation, ShouldShowLegalNoteFor, MicrosoftProductIdsWithLegalNote, LegalNoteLinks, EolState, VulnerabilityType, RecommendationExposedNetworkDeviceRelationship, RecommendationInstalledNetworkDevicesRelationship, TvmRecommendationOnboardingStatus, } from '@wcd/domain';
import { TvmTextsService, TextToken } from '../../../tvm/services/tvm-texts.service';
import { TvmDownloadService } from '../../../tvm/services/tvm-download.service';
import { Paris } from '@microsoft/paris';
import { FeaturesService, Feature } from '@wcd/config';
import { SpinnerSize } from 'office-ui-fabric-react';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { I18nService } from '@wcd/i18n';
import { SecurityRecommendationService } from '../../../@entities/@tvm/security-recommendations/services/security-recommendation.service';
import { VulnerabilityService } from '../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { Router } from '@angular/router';
import { TvmProductivityImpactService } from '../../../tvm/services/tvm-productivity-impact.service';
import { TvmRecommendationContextService } from '../../../tvm/services/tvm-recommendation-context.service';
import { MessageBarStyles } from '../../../@entities/@tvm/common/styles';
import { ExposedOperatingSystemsComponent } from '../../../tvm/components/tooltips/exposed-operating-systems/exposed-operating-systems.component';
import { MessageBarType } from 'office-ui-fabric-react';
import { MaxItemsInCollapseExpandTable } from '../../../tvm/tvm.constants';
import { RemediationItsmToolsService } from '../../../@entities/@tvm/remediation/remediation-tasks/services/remediation-itsm-tools-service';
import { Tabs } from '../../../@entities/@tvm/security-recommendations/components/remediation-task-creation.component';
import { TzDateService } from '@wcd/localization';
var SecurityRecommendationEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(SecurityRecommendationEntityDetailsComponent, _super);
    function SecurityRecommendationEntityDetailsComponent(paris, tvmTextsService, featuresService, i18nService, injector, changeDetectorRef, sidePanelService, tvmDownloadService, securityRecommendationService, vulnerabilityService, productivityImpactService, recommendationContextService, remediationItsmToolsService, // don't delete, this is used to pre-load the provisioned itsm tools
    router, tzDateService) {
        var _a;
        var _this = _super.call(this, injector) || this;
        _this.paris = paris;
        _this.tvmTextsService = tvmTextsService;
        _this.featuresService = featuresService;
        _this.i18nService = i18nService;
        _this.changeDetectorRef = changeDetectorRef;
        _this.sidePanelService = sidePanelService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.securityRecommendationService = securityRecommendationService;
        _this.vulnerabilityService = vulnerabilityService;
        _this.productivityImpactService = productivityImpactService;
        _this.recommendationContextService = recommendationContextService;
        _this.router = router;
        _this.tzDateService = tzDateService;
        _this.MessageBarType = MessageBarType;
        _this.MaxItemsInCollapseExpandTable = MaxItemsInCollapseExpandTable;
        _this.SpinnerSize = SpinnerSize;
        _this.isAssetsMinimized = true;
        _this.isCvesMinimized = true;
        _this.remediationType = RemediationType;
        _this._messageBarStyles = MessageBarStyles;
        _this.recommendationContextType = RecommendationContextType.Unknown;
        _this.unknownRecommendationContextType = RecommendationContextType.Unknown;
        _this.isRecommendationContextNeededInCSV = false;
        _this.isNetworkDevice = false;
        _this.messageBarState = {
            showBody: false,
        };
        _this.ExposedOperatingSystemsComponent = ExposedOperatingSystemsComponent;
        _this.windowsDescriptionLinks = (_a = {},
            _a[MicrosoftProductIdsWithLegalNote.Windows7] = 'https://www.microsoft.com/en-us/microsoft-365/windows/end-of-windows-7-support',
            _a[MicrosoftProductIdsWithLegalNote.WindowsServer2008] = 'https://docs.microsoft.com/en-us/windows-server/get-started/modernize-windows-server-2008',
            _a);
        _this.exposureTooltip = _this.tvmTextsService.getText(TextToken.ExposureScoreTooltip);
        _this.configurationTooltip = _this.tvmTextsService.getText(TextToken.ConfigurationScoreTooltip);
        _this.assetRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationExposedAssetsRelationship);
        _this.networkDeviceRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationExposedNetworkDeviceRelationship);
        _this.installationNetworkDeviceRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationInstalledNetworkDevicesRelationship);
        _this.installationRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationInstalledAssetsRelationship);
        _this.vulnerabilitiesRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationRelatedVulnerabilitiesRelationship);
        _this.recommendationContextRelationshipRepository = _this.paris.getRelationshipRepository(RecommendationContextKeyAndExposedMachinesCountRelationship);
        _this.assetRecommendationContextRelationshipRepository = _this.paris.getRelationshipRepository(AssetRecommendationContextRelationship);
        _this.mitigatedVulnerabilitiesRepository = _this.paris.getRelationshipRepository(RecommendatioMitigatedVulnerabilitiesRelationship);
        _this.exposedOperatingSystemsStatisticsRepository = _this.paris.getRelationshipRepository(RecommendationExposedOperatingSystemsRelationship);
        _this.isRequiredAttentionEnabled = _this.featuresService.isEnabled(Feature.TvmRequiredAttention);
        _this.isExposedOperatingSystem = _this.featuresService.isEnabled(Feature.TvmExposedOperatingSystems);
        _this.zeroDayFeatureEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
        return _this;
    }
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "recommendationOptions", {
        get: function () {
            return this._recommendationOptions;
        },
        set: function (options) {
            this._recommendationOptions = options;
            this.isSingleAssetRecommendation = options && options.singleAssetRecommendation;
            if (options) {
                this.assetId = options.machineId;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "recommendation", {
        get: function () {
            return this._recommendation;
        },
        set: function (recommendation) {
            this._recommendation = recommendation;
            this.isEol = this.recommendation.eolSoftwareState === EolState.AlreadyEOL;
            this.isUpcomingEol = this.recommendation.eolSoftwareState === EolState.UpcomingEOL;
            this.isConfigurationChange = recommendation.remediationType === RemediationType.ConfigurationChange;
            this.loadNewAssets(recommendation);
            this.loadNewInstallations(recommendation);
            if (this.isConfigurationChange) {
                this.mitigatedVulnerabilitiesRepository.sourceItem = recommendation;
            }
            else {
                this.vulnerabilitiesRelationshipRepository.sourceItem = recommendation;
            }
            if (this.isExposedOperatingSystem) {
                this.loadExposedOperatingSystems(recommendation);
            }
            this.recommendationDescription = this.tvmTextsService.getText(TextToken.SecurityRecommendationDescription, { recommendation: recommendation });
            this.recommendationRelatedComponent = this.tvmTextsService.getText(TextToken.SecurityRecommendationRelatedComponent, recommendation);
            this.setProductivityImpact(recommendation.assetsStatistics, recommendation.isProductivityImpacted);
            this.shouldShowLegalNote = ShouldShowLegalNoteFor.includes(this.recommendation.productId);
            this.showUpgradeLink =
                recommendation.remediationType === RemediationType.Upgrade &&
                    Object.keys(this.windowsDescriptionLinks).includes(recommendation.productId);
            this.upgradeLinkText = this.tvmTextsService.getText(TextToken.UpgradeLinkText, this.recommendation);
            this.upgradeLink = this.windowsDescriptionLinks[recommendation.productId];
            this.showLegalNoteLink =
                recommendation.remediationType === RemediationType.Update &&
                    Object.keys(LegalNoteLinks).includes(recommendation.productId);
            this.legalNoteLink = LegalNoteLinks[recommendation.productId];
            this.legalNoteLinkText = this.tvmTextsService.getText(TextToken.LegalNoteLinkText, recommendation.productId);
            this.messageBarType =
                this.recommendation.hasEolVersions || this.isEol ? MessageBarType.warning : MessageBarType.info;
            this.widgetEosTooltip = this.i18nService.strings.tvm_securityRecommendation_eosTooltip;
            this.hasWeaknesses = recommendation.weaknessesCount !== 0;
            this.recommendationStatus = this.securityRecommendationService.getRecommendationStatus(recommendation.status, true);
            this.isZeroDayRecommendation = this.shouldShowZeroDayMessage(recommendation);
            if (this.shouldShowNewPatchMessage(recommendation)) {
                this.newPatchMessage = this.i18nService.get('tvm_securityRecommendation_zeroDay_newPatch_notification', {
                    patchReleaseDate: this.tzDateService.format(recommendation.patchReleaseDate, 'shortDate'),
                    softwareName: this.recommendationRelatedComponent,
                });
            }
            this.shouldShowWorkaround = recommendation.workarounds && recommendation.workarounds.length > 0;
            this.isSingleVulnerability = recommendation.weaknessesCount === 1;
            this.workaroundsMainText = this.getWorkaroundsMainText();
        },
        enumerable: true,
        configurable: true
    });
    SecurityRecommendationEntityDetailsComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this.exceptionLink = "remediation/recommendation-exceptions/" + (this.recommendation.remediationType === RemediationType.ConfigurationChange
            ? this.recommendation.scId
            : this.recommendation.productId);
        this.explanationText = this.tvmTextsService.getText(TextToken.SecurityRecommendationAttentionExplanation, this.recommendation);
        this.eolText = this.getEolText();
        this.softwareVersionsLinkText = this.getSoftwareVersionsLinkText();
        this.showEolMessage = this.shouldShowEolMessage();
        this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedDevices');
        if (this.recommendation.category === RecommendationCategory.NetworkGear) {
            this.configureNetworkDeviceRecommendation();
        }
        this.legalNote = this.isEol
            ? this.tvmTextsService.getText(TextToken.SoftwareEolOsLegalNoteRecommendation, this.recommendation.productId)
            : this.tvmTextsService.getText(TextToken.HasEolVersionsMicrosoftLegalNoteRecommendation, this.recommendation.productId);
        if (this.featuresService.isEnabled(Feature.TvmRecommendationContext)) {
            if (this.isSingleAssetRecommendation) {
                this.recommendationContextType = this.recommendationContextService.getRecommendationContextTypeByScId(this._recommendation.scId);
                if (this.recommendationContextType !== RecommendationContextType.Unknown) {
                    this.setRecommendationContextSectionTitles();
                    this.assetRecommendationContextRelationshipRepository.sourceItem = new AssetRecommendation({
                        id: this._recommendation.id,
                        scId: this._recommendation.scId,
                        assetId: this.assetId,
                    });
                }
            }
            else {
                this.assets$.subscribe(function (assets) {
                    _this.recommendationContextType = _this.recommendationContextService.getRecommendationContextTypeByAssets(assets);
                    if (_this.recommendationContextType !== RecommendationContextType.Unknown) {
                        _this.isRecommendationContextNeededInCSV = _this.recommendationContextService.isRecommendationContextNeededInCSV(_this.recommendationContextType);
                        _this.setRecommendationContextSectionTitles();
                        _this.recommendationContextRelationshipRepository.sourceItem = _this._recommendation;
                    }
                });
            }
        }
    };
    SecurityRecommendationEntityDetailsComponent.prototype.setRecommendationContextSectionTitles = function () {
        this.recommendationContextExposedMachinesTitle = this.tvmTextsService.recommendationContextToExposedSectionTitle[this.recommendationContextType];
        this.recommendationContextExposedMachinesLoadingText = this.tvmTextsService.recommendationContextToExposedSectionLoadingTitle[this.recommendationContextType];
    };
    SecurityRecommendationEntityDetailsComponent.prototype.setProductivityImpact = function (assetsStatistics, isProductivityImpacted) {
        this.showProductivityImpactDescription =
            this.productivityImpactService.isProductivityImpactExposed &&
                (this.isSingleAssetRecommendation
                    ? isProductivityImpacted || isProductivityImpacted === false
                    : !!assetsStatistics.nonProductivityImpactedAssets);
        if (!this.showProductivityImpactDescription) {
            return;
        }
        this.userImpactDescription = this.isSingleAssetRecommendation
            ? this.tvmTextsService.getText(TextToken.AssetRecommendationProductivityImpact, isProductivityImpacted)
            : this.tvmTextsService.getText(TextToken.OrgRecommendationProductivityImpact, assetsStatistics);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.loadNewAssets = function (recommendation) {
        var queryOptions = {
            page: 1,
            pageSize: MaxItemsInCollapseExpandTable,
        };
        this.assetRelationshipRepository.sourceItem = recommendation;
        this.assets$ = this.assetRelationshipRepository.query(queryOptions);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.loadExposedOperatingSystems = function (recommendation) {
        this.exposedOperatingSystemsStatisticsRepository.sourceItem = recommendation;
        this.exposedOperatingSystemsStatistics$ = this.exposedOperatingSystemsStatisticsRepository.query();
    };
    SecurityRecommendationEntityDetailsComponent.prototype.loadNewInstallations = function (recommendation) {
        var queryOptions = {
            page: 1,
            pageSize: MaxItemsInCollapseExpandTable,
        };
        this.installationRelationshipRepository.sourceItem = recommendation;
        this.installations$ = this.installationRelationshipRepository.query(queryOptions);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openSafeAssetsRemediation = function () {
        this.securityRecommendationService.showRemediationTaskCreationPanel(this.recommendation, ProductivityImpactRemediationType.NonImpactedAssets);
    };
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "vulnerabilitiesTitle", {
        get: function () {
            return this.isConfigurationChange
                ? this.i18nService.get('tvm.securityRecommendation.mitigatedCVE.title')
                : this.i18nService.get('tvm.common.relatedCVE.title');
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "properlyConfiguredInsight", {
        get: function () {
            return this.tvmTextsService.getText(TextToken.SecurityRecommendationSCAProperlyConfiguredInsight, this.recommendation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "supportedBenchmarksInsight", {
        get: function () {
            return this.tvmTextsService.getText(TextToken.SecurityRecommendationSCARecommendedBenchmarksInsight, this.recommendation);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationEntityDetailsComponent.prototype, "contextOptions", {
        get: function () {
            return {
                isSingleAsset: this.isSingleAssetRecommendation,
            };
        },
        enumerable: true,
        configurable: true
    });
    SecurityRecommendationEntityDetailsComponent.prototype.openAllExposedMachines = function () {
        this.sidePanelService.showAllExposedAssetsPanel(this._recommendation, this._recommendation.category === RecommendationCategory.NetworkGear, this.recommendationContextType);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openWorkaroundPanel = function () {
        this.sidePanelService.showWorkaroundSidePanel(this.recommendation, this.recommendationRelatedComponent);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openZeroDayRemediation = function () {
        this.securityRecommendationService.showRemediationTaskCreationPanel(this.recommendation, null, Tabs.none);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openRemediationOption = function () {
        this.securityRecommendationService.showRemediationTaskCreationPanel(this.recommendation, null);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openAllInstalledMachines = function () {
        this.sidePanelService.showAllInstalledAssetsPanel(this._recommendation);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openAllRelatedCves = function () {
        this.sidePanelService.showAllRelatedCvesPanel(this._recommendation, undefined, this.isNetworkGearCve);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openAllRecommendationContextKeyAndExposedMachinesCountPanel = function () {
        this.sidePanelService.showRecommendationContextKeyAndExposedMachinesCountPanel(this._recommendation, this.recommendationContextType);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openAllRecommendationContextKeyAndPropertiesPanel = function () {
        this.sidePanelService.showRecommendationContextKeyAndPropertiesPanel(this._recommendation, this.recommendationContextType);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.exportRecommendationContextData = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.isSingleAssetRecommendation
            ? this.assetRecommendationContextRelationshipRepository
            : this.recommendationContextRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation) + " - " + this.recommendationContextExposedMachinesTitle, undefined);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.exportCveData = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.isConfigurationChange
            ? this.mitigatedVulnerabilitiesRepository
            : this.vulnerabilitiesRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation) + " - " + this.i18nService.strings.tvm_common_relatedCVE_title, undefined);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.exportExposedMachines = function () {
        var where = { includeProductivityColumn: this.showProductivityImpactDescription };
        var isNotOnboardedRecommendation = this._recommendation.onboardingStatus === TvmRecommendationOnboardingStatus.NotOnboarded;
        var notOnboardedCustomQuery = isNotOnboardedRecommendation ? { exportExtendType: 'UnmanagedAssets' } : {};
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.assetRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation) + " - " + this.i18nService.strings.tvm_common_exposedDevices, { where: where }, {
            isRecommendationContextNeededInCSV: this.isRecommendationContextNeededInCSV,
        }, notOnboardedCustomQuery);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.exportInstalledMachines = function () {
        return this.tvmDownloadService.downloadCsvFromRelationshipRepository(this.installationRelationshipRepository, TvmEndPoint.Analytics, this.tvmTextsService.getText(TextToken.SecurityRecommendationTitle, this.recommendation) + " - " + this.i18nService.strings.tvm_common_installedDevices, undefined);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openVulnerabilityPanel = function (cveId) {
        var _this = this;
        this.paris
            .getItemById(Vulnerability, cveId)
            .subscribe(function (v) {
            return _this.vulnerabilityService.showVulnerabilityPanel(v, true, _this.isNetworkGearCve);
        });
    };
    SecurityRecommendationEntityDetailsComponent.prototype.shouldShowEolMessage = function () {
        return (this.isEol ||
            this.isUpcomingEol ||
            this.recommendation.hasEolVersions ||
            this.recommendation.hasUpcomingEolVersions);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.getEolText = function () {
        if (this.isEol) {
            return this.tvmTextsService.getText(TextToken.EolSoftwareText, this.recommendation.eolSoftwareSinceDate);
        }
        if (this.isUpcomingEol) {
            return this.tvmTextsService.getText(TextToken.UpcomingEolSoftwareText, this.recommendation.eolSoftwareSinceDate);
        }
        if (this.recommendation.hasEolVersions && this.recommendation.hasUpcomingEolVersions) {
            return this.tvmTextsService.getText(TextToken.CombinedEolVersionsText, this.recommendation.productName);
        }
        if (this.recommendation.hasEolVersions) {
            return this.tvmTextsService.getText(TextToken.HasEolVersionsText, this.recommendation.productName);
        }
        if (this.recommendation.hasUpcomingEolVersions) {
            return this.tvmTextsService.getText(TextToken.HasUpcomingEolVersionsText, this.recommendation.productName);
        }
    };
    SecurityRecommendationEntityDetailsComponent.prototype.openSoftwareVersions = function () {
        var alreadyEOL = this.recommendation.hasEolVersions ? 'AlreadyEOL' : '';
        var upcomingEOL = this.recommendation.hasUpcomingEolVersions ? 'UpcomingEOL' : '';
        this.router.navigate(["/software-inventory/" + encodeURIComponent(this.recommendation.productId) + "/versions"], {
            queryParams: {
                filters: "versionDistributionTags=" + [alreadyEOL, upcomingEOL]
                    .filter(function (s) { return s.length > 0; })
                    .join('|'),
            },
        });
    };
    SecurityRecommendationEntityDetailsComponent.prototype.getSoftwareVersionsLinkText = function () {
        return this.tvmTextsService.getText(TextToken.SoftwareVersionsLinkText, this.recommendation.productName);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.configureNetworkDeviceRecommendation = function () {
        this.exposedAssetsTitle = this.i18nService.get('tvm.common.exposedNetworkDevices');
        this.exposedAssetsLoadingText = this.i18nService.get('tvm.securityRecommendation.loadingExposedNetworkDevices');
        this.installedAssetsTitle = this.i18nService.get('tvm.common.installedNetworkDevices');
        this.installedAssetsLoadingText = this.i18nService.get('tvm.securityRecommendation.loadingInstalledNetworkDevices');
        this.isNetworkDevice = true;
        this.includeLastSeenStatus = true;
        this.isNetworkGearCve = true;
        this.networkDeviceRelationshipRepository.sourceItem = this._recommendation;
        this.installationNetworkDeviceRelationshipRepository.sourceItem = this._recommendation;
    };
    SecurityRecommendationEntityDetailsComponent.prototype.generateExposedOperatingSystems = function (operatingSystems) {
        var res = '';
        if (operatingSystems.length > 0) {
            res += this.tvmTextsService.getOsPlatformLabel(operatingSystems[0].osName);
        }
        if (operatingSystems.length > 1) {
            res += ", " + this.tvmTextsService.getOsPlatformLabel(operatingSystems[1].osName);
        }
        if (operatingSystems.length > 2) {
            res += " (+" + (operatingSystems.length - 2) + " more)";
        }
        return res;
    };
    SecurityRecommendationEntityDetailsComponent.prototype.getStyles = function (messageBarProps) {
        if (this.messageBarState.showBody !== messageBarProps.expandSingleLine) {
            this.messageBarState.showBody = messageBarProps.expandSingleLine;
            this.changeDetectorRef.detectChanges();
        }
        return this._messageBarStyles;
    };
    SecurityRecommendationEntityDetailsComponent.prototype.shouldShowZeroDayMessage = function (recommendation) {
        return (this.zeroDayFeatureEnabled &&
            recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
            !recommendation.patchReleaseDate);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.shouldShowNewPatchMessage = function (recommendation) {
        var dateBrier = moment()
            .subtract(7, 'days')
            .utc()
            .toDate();
        return (this.zeroDayFeatureEnabled &&
            recommendation.mostSevereVulnerabilityType === VulnerabilityType.ZeroDay &&
            recommendation.patchReleaseDate > dateBrier);
    };
    SecurityRecommendationEntityDetailsComponent.prototype.getWorkaroundsMainText = function () {
        var workaroundKeyBase = this.shouldShowWorkaround
            ? 'tvm_securityRecommendation_va_zeroDay_card_text_with_workaround_postLink'
            : 'tvm_securityRecommendation_va_zeroDay_card_text_without_workaround';
        return this.i18nService.get("" + workaroundKeyBase + (this.isSingleAssetRecommendation ? '_singleAsset' : ''));
    };
    return SecurityRecommendationEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { SecurityRecommendationEntityDetailsComponent };
