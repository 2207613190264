var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
var SETTINGS_API_ENDPOINT = '/admin';
var PHISHING_ENABLED_API_URL = 'phishing_enabled';
var AdvancedSettingsBackendService = /** @class */ (function (_super) {
    __extends(AdvancedSettingsBackendService, _super);
    function AdvancedSettingsBackendService(http, downloadService, serviceUrlsService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.serviceUrlsService = serviceUrlsService;
        return _this;
    }
    Object.defineProperty(AdvancedSettingsBackendService.prototype, "apiUrl", {
        get: function () {
            return this.serviceUrlsService.automatedIr + SETTINGS_API_ENDPOINT;
        },
        enumerable: true,
        configurable: true
    });
    AdvancedSettingsBackendService.prototype.getIsPhishingEnabled = function () {
        return this.get(PHISHING_ENABLED_API_URL);
    };
    return AdvancedSettingsBackendService;
}(StoreBackendBase));
export { AdvancedSettingsBackendService };
