var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { SoftwareFieldsService } from './../../../../@entities/@tvm/software-inventory/services/software.fields.service';
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Software } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { map, take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { TvmMachineGroupsFilterService } from '../../../services/tvm-machine-groups-filter.service';
import { zip } from 'rxjs';
import { DataviewField } from '@wcd/dataview';
var TvmTopVulnerableSoftwareWidget = /** @class */ (function (_super) {
    __extends(TvmTopVulnerableSoftwareWidget, _super);
    function TvmTopVulnerableSoftwareWidget(reportsService, i18nService, softwareFieldsService, machineGroupsFilterService, router, paris, tvmTextsService) {
        var _this = _super.call(this, reportsService) || this;
        _this.i18nService = i18nService;
        _this.machineGroupsFilterService = machineGroupsFilterService;
        _this.router = router;
        _this.paris = paris;
        _this.tvmTextsService = tvmTextsService;
        _this.isThreatActiveTooltip = _this.i18nService.get('tvm.software.threatActiveTooltip');
        _this.noThreatActiveTooltip = _this.i18nService.get('tvm.software.noThreatActiveTooltip');
        _this.fields = softwareFieldsService.topVulnerableSoftwareWidgetFields.map(function (field) { return new DataviewField(Object.assign({}, field, { sort: { enabled: false } })); });
        return _this;
    }
    Object.defineProperty(TvmTopVulnerableSoftwareWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'topVulnerableSoftwareWidget',
                name: this.i18nService.get('tvm.dashboard.topVulnerableSoftware'),
                noDataMessage: function () {
                    return _this.tvmTextsService.getText(TextToken.NoDataForWidget, {
                        noDataKey: 'tvm.dashboard.noData.topVulnerableSoftware',
                        isGroupSelected: _this.isMgSelected,
                    });
                },
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    var mg$ = _this.machineGroupsFilterService.machineGroupsFilter$.pipe(take(1));
                    // TODO[khaw]: Bring only pagesize=3 without sorting logic after supporting orderby in backend
                    var software$ = _this.paris
                        .query(Software, {
                        sortBy: [
                            {
                                field: 'riskScore',
                                direction: DataQuerySortDirection.descending,
                            },
                        ],
                        pageSize: 100,
                    })
                        .pipe(map(function (ds) {
                        return ds.items
                            .sort(function (a, b) {
                            return b.exposureScoreImprovement - a.exposureScoreImprovement;
                        })
                            .slice(0, 3);
                    }));
                    return zip(mg$, software$).pipe(map(function (_a) {
                        var mg = _a[0], software = _a[1];
                        _this.isMgSelected = mg.isFiltering;
                        return software;
                    }));
                },
                minHeight: '210px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmTopVulnerableSoftwareWidget.prototype.openSoftwareVulnerabilities = function (software) {
        this.router.navigate(['/software-inventory', encodeURIComponent(software.id)]);
    };
    TvmTopVulnerableSoftwareWidget.prototype.openSoftwareInventory = function () {
        this.router.navigate(['/software-inventory']);
    };
    return TvmTopVulnerableSoftwareWidget;
}(ReportWidgetComponent));
export { TvmTopVulnerableSoftwareWidget };
