/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./stopwatch.component";
var styles_StopWatchComponent = [];
var RenderType_StopWatchComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StopWatchComponent, data: {} });
export { RenderType_StopWatchComponent as RenderType_StopWatchComponent };
export function View_StopWatchComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [["class", "stopwatch"]], null, null, null, null, null)), (_l()(), i0.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.display; _ck(_v, 1, 0, currVal_0); }); }
export function View_StopWatchComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "stopwatch", [], null, null, null, View_StopWatchComponent_0, RenderType_StopWatchComponent)), i0.ɵdid(1, 704512, null, 0, i1.StopWatchComponent, [i0.ChangeDetectorRef], null, null)], null, null); }
var StopWatchComponentNgFactory = i0.ɵccf("stopwatch", i1.StopWatchComponent, View_StopWatchComponent_Host_0, { start: "start", end: "end", zeroText: "zeroText", prettify: "prettify" }, {}, []);
export { StopWatchComponentNgFactory as StopWatchComponentNgFactory };
