import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

/**
 * old alert timeline element. please use "cyber-event" model.
 * @deprecated
 */
@ValueObject({
	singularName: 'Alert Timeline Missing Events Summary',
	pluralName: 'Alert Timeline Missing Events Summaries',
})
export class AlertTimelineMissingEventsSummary extends ModelBase {
	@EntityField({ data: 'LastEventTime' })
	lastEventTime: Date;

	@EntityField({ data: 'EventsByType' })
	eventsByTime: { [eventType: string]: number };
}
