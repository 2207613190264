import { Directive, forwardRef } from '@angular/core';
import { FormControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
	selector: '[validateLength][ngModel],[validateLength][formControl]',
	providers: [{ provide: NG_VALIDATORS, useExisting: forwardRef(() => LengthValidator), multi: true }],
})
export class LengthValidator {
	validate(c: FormControl) {
		return c.value && c.value.length
			? null
			: {
					validateLength: {
						valid: false,
					},
			  };
	}
}
