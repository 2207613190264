var _a;
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { EolState, FILTER_TAG, VA_RECOMMENDATION_FILTER_TAG, VERSION_DISTRIBUTION_FILTER_TAG, SCA_RECOMMENDATION_FILTER_TAG, RemediationType, VulnerabilityType, } from '@wcd/domain';
import { TvmProductivityImpactService } from './tvm-productivity-impact.service';
import { SOFTWARE_CATEGORY } from '../../@entities/@tvm/software-inventory/components/software-entity/software.entity.component';
import { TvmTagsServiceCommon } from './tvm-tag.service.common';
var COVID_19_SCIDS = [
    'scid-24',
    'scid-28',
    'scid-30',
    'scid-41',
    'scid-42',
    'scid-44',
    'scid-55',
    'scid-64',
    'scid-63',
    'scid-68',
    'scid-87',
    'scid-88',
    'scid-94',
    'scid-95',
    'scid-2000',
    'scid-2001',
    'scid-2002',
    'scid-2010',
    'scid-2011',
    'scid-2012',
    'scid-2014',
    'scid-2016',
    'scid-3011',
];
var HUMAN_OPERATED_RANSOMWARE_SCIDS = [
    'scid-24',
    'scid-25',
    'scid-41',
    'scid-44',
    'scid-84',
    'scid-93',
    'scid-2003',
    'scid-2501',
    'scid-2509',
    'scid-2510',
    'scid-5006',
];
var IMPACT_ASSESSMENT_TAG_KEY = 'UserImpactAssessment';
var COVID_19_TAG_KEY = 'covid19';
var HUMAN_OPERATED_RANSOMWARE_KEY = 'HumanOperatedRansomware';
var portalToAnalyticsTagKeys = (_a = {},
    _a[SCA_RECOMMENDATION_FILTER_TAG.covid19] = COVID_19_TAG_KEY,
    _a);
var TvmTagsService = /** @class */ (function () {
    function TvmTagsService(productivityImpactService, featuresService, i18nService) {
        this.productivityImpactService = productivityImpactService;
        this.i18nService = i18nService;
        this._scaSupportedFilterTags = [];
        this._productivityImpactExposed = this.productivityImpactService.isProductivityImpactExposed;
        this._humanOperatedRansomwareTagEnabled = featuresService.isEnabled(Feature.TvmHumanOpRansomwareTags);
        this.hasNetworkScanEnabled = featuresService.isEnabled(Feature.TvmNetworkScan);
        this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
        this.eolTag = this.getEolTag();
        this.upcomingEolTag = this.getUpcomingEolTag();
        this.networkDeviceTag = this.getNetworkDeviceTag();
        this.eolVersionTag = this.getEolVersionTag();
        this.upcomingEolVersionTag = this.getUpcomingEolVersionTag();
        this.hasEolVersionsTag = this.getHasEolVersionsTag();
        this.hasUpcomingEolVersionsTag = this.getHasUpcomingEolVersionsTag();
        this.zeroDayTag = this.getZeroDayTagTag();
        this.tagServiceCommon = new TvmTagsServiceCommon(featuresService);
        this.setScaSupportedFilterTags();
    }
    TvmTagsService.prototype.getSoftwareInstallationVersionTags = function (installation) {
        return this.tagServiceCommon.getSoftwareInstallationVersionTags(installation, this.i18nService.get('tvm.securityRecommendation.endOfLifeVersion'), this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersions'));
    };
    TvmTagsService.prototype.getVulnerabilityTags = function (vulnerability) {
        return this.tagServiceCommon.getVulnerabilityTags(vulnerability, this.i18nService.strings.tvm_zeroDay_tag);
    };
    TvmTagsService.prototype.getRecommendationTags = function (recommendation) {
        var _this = this;
        var tags = new Array();
        if (recommendation.remediationType === RemediationType.ConfigurationChange && recommendation.scId) {
            recommendation.tags
                .filter(function (t) { return _this._scaTags.has(t); })
                .forEach(function (t) { return tags.push(_this._scaTags.get(t)); });
        }
        if (this.verifyZeroDayTag(recommendation.mostSevereVulnerabilityType, recommendation.patchReleaseDate)) {
            tags.push(this.zeroDayTag);
        }
        if (this.verifyAlreadyEolSoftwareTag(recommendation.eolSoftwareState)) {
            tags.push(this.eolTag);
        }
        if (this.verifyUpcomingEolSoftwareTag(recommendation.eolSoftwareState)) {
            tags.push(this.upcomingEolTag);
        }
        if (recommendation.hasEolVersions) {
            tags.push(this.hasEolVersionsTag);
        }
        if (recommendation.hasUpcomingEolVersions) {
            tags.push(this.hasUpcomingEolVersionsTag);
        }
        return tags;
    };
    TvmTagsService.prototype.getRemediationTags = function (remediationTask) {
        var tags = new Array();
        if (!remediationTask || !remediationTask.taskArgs) {
            return tags;
        }
        var taskArgs = remediationTask.taskArgs;
        var softwareArgs = taskArgs.softwareArgs;
        var scaArgs = taskArgs.securityConfigurationArgs;
        if (softwareArgs) {
            if (softwareArgs.isEOL) {
                tags.push(this.eolTag);
            }
            if (softwareArgs.isUpcomingEol) {
                tags.push(this.upcomingEolTag);
            }
            if (softwareArgs.hasEolVersions) {
                tags.push(this.hasEolVersionsTag);
            }
            if (softwareArgs.hasUpcomingEolVersions) {
                tags.push(this.hasUpcomingEolVersionsTag);
            }
        }
        else if (scaArgs) {
            // TODO: evhvoste - remove this once remediation will also support tags
            // VSTS - Task 26790234: Add support for tags in remediation service created tasks
            if (this._productivityImpactExposed && taskArgs.productivityImpactRemediationType) {
                tags.push(this._scaTags.get(IMPACT_ASSESSMENT_TAG_KEY));
            }
            if (COVID_19_SCIDS.includes(scaArgs.scid)) {
                tags.push(this._scaTags.get(COVID_19_TAG_KEY));
            }
            if (this._humanOperatedRansomwareTagEnabled &&
                HUMAN_OPERATED_RANSOMWARE_SCIDS.includes(scaArgs.scid)) {
                tags.push(this._scaTags.get(HUMAN_OPERATED_RANSOMWARE_KEY));
            }
        }
        return tags;
    };
    TvmTagsService.prototype.getSoftwareTags = function (software) {
        var tags = new Array();
        if (this.verifyZeroDayTag(software.mostSevereVulnerabilityType, software.patchReleaseDate)) {
            tags.push(this.zeroDayTag);
        }
        if (this.verifyAlreadyEolSoftwareTag(software.eolSoftwareState)) {
            tags.push(this.eolTag);
        }
        if (this.verifyUpcomingEolSoftwareTag(software.eolSoftwareState)) {
            tags.push(this.upcomingEolTag);
        }
        if (software.hasEolVersions) {
            tags.push(this.hasEolVersionsTag);
        }
        if (software.hasUpcomingEolVersions) {
            tags.push(this.hasUpcomingEolVersionsTag);
        }
        if (software.category === SOFTWARE_CATEGORY.NetworkGear) {
            tags.push(this.networkDeviceTag);
        }
        return tags;
    };
    TvmTagsService.prototype.getSoftwareVersionTags = function (version) {
        var tags = new Array();
        if (this.verifyAlreadyEolVersionTag(version.eolVersionState)) {
            tags.push(this.eolVersionTag);
        }
        if (this.verifyUpcomingEolVersionTag(version.eolVersionState)) {
            tags.push(this.upcomingEolVersionTag);
        }
        return tags;
    };
    TvmTagsService.prototype.getSoftwareInstallationTags = function (softwareInstallation) {
        var tags = new Array();
        if (this.verifyAlreadyEolVersionTag(softwareInstallation.eolVersionState)) {
            tags.push(this.eolVersionTag);
        }
        if (this.verifyUpcomingEolVersionTag(softwareInstallation.eolVersionState)) {
            tags.push(this.upcomingEolVersionTag);
        }
        return tags;
    };
    TvmTagsService.prototype.getMissingKbTags = function (missingKb) {
        var tags = new Array();
        if (this.verifyAlreadyEolVersionTag(missingKb.eolVersionState)) {
            tags.push(this.eolVersionTag);
        }
        if (this.verifyUpcomingEolVersionTag(missingKb.eolVersionState)) {
            tags.push(this.upcomingEolVersionTag);
        }
        return tags;
    };
    TvmTagsService.prototype.getSoftwareTagsForFiltering = function () {
        var _this = this;
        return Object.keys(FILTER_TAG).filter(function (tag) {
            switch (FILTER_TAG[tag]) {
                case FILTER_TAG.networkGear:
                    return _this.hasNetworkScanEnabled;
                case FILTER_TAG.zeroDay:
                    return _this.zeroDayEnabled;
                default:
                    return true;
            }
        });
    };
    TvmTagsService.prototype.getSoftwareVersionTagsForFiltering = function () {
        return Object.keys(VERSION_DISTRIBUTION_FILTER_TAG);
    };
    TvmTagsService.prototype.getVaRecommendationsTagsForFiltering = function () {
        var _this = this;
        return Object.keys(VA_RECOMMENDATION_FILTER_TAG).filter(function (tag) {
            switch (VA_RECOMMENDATION_FILTER_TAG[tag]) {
                case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
                    return _this.zeroDayEnabled;
                default:
                    return true;
            }
        });
    };
    Object.defineProperty(TvmTagsService.prototype, "scaRecommendationsTagsForFiltering", {
        get: function () {
            return this._scaSupportedFilterTags;
        },
        enumerable: true,
        configurable: true
    });
    TvmTagsService.prototype.setScaSupportedFilterTags = function () {
        var _this = this;
        // TODO: once each tag is GA - convert to [tag1, tag2].forEach like for SCA_RECOMMENDATION_FILTER_TAG.covid19
        this._scaTags = new Map();
        if (this._productivityImpactExposed) {
            this._scaSupportedFilterTags.push(SCA_RECOMMENDATION_FILTER_TAG.userImpactAssessment);
            this._scaTags.set(IMPACT_ASSESSMENT_TAG_KEY, {
                id: IMPACT_ASSESSMENT_TAG_KEY,
                name: this.i18nService.get('tvm.securityRecommendation.tag.userImpactAssessment'),
                tooltip: this.i18nService.get('tvm.securityRecommendation.userImpact.productivityImpactTagTooltip'),
            });
        }
        [SCA_RECOMMENDATION_FILTER_TAG.covid19].forEach(function (tag) {
            var tagKey = portalToAnalyticsTagKeys[tag];
            _this._scaSupportedFilterTags.push(tag);
            _this._scaTags.set(tagKey, {
                id: tagKey,
                name: _this.i18nService.strings["tvm_securityRecommendation_tag_" + tagKey],
                tooltip: _this.i18nService.strings["tvm_securityRecommendation_tag_" + tagKey + "Tooltip"],
            });
        });
        if (this._humanOperatedRansomwareTagEnabled) {
            this._scaSupportedFilterTags.push(SCA_RECOMMENDATION_FILTER_TAG.humanOperatedRansomware);
            this._scaTags.set(HUMAN_OPERATED_RANSOMWARE_KEY, {
                id: HUMAN_OPERATED_RANSOMWARE_KEY,
                name: this.i18nService.strings.tvm_securityRecommendation_tag_humanOperatedRansomware,
                tooltip: this.i18nService.strings
                    .tvm_securityRecommendation_tag_humanOperatedRansomwareTooltip,
            });
        }
    };
    TvmTagsService.prototype.getZeroDayTagTag = function () {
        return {
            id: 'zeroDayTag',
            name: this.i18nService.strings.tvm_zeroDay_tag,
            className: 'color-box-attention',
        };
    };
    TvmTagsService.prototype.getEolTag = function () {
        return {
            id: 'endOfLifeTag',
            name: this.i18nService.get('tvm.securityRecommendation.endOfLife'),
            className: 'color-box-dark-medium-opacity',
        };
    };
    TvmTagsService.prototype.getUpcomingEolTag = function () {
        return {
            id: 'upcomingEndOfLifeTag',
            name: this.i18nService.get('tvm.securityRecommendation.upcomingEndOfLife'),
            className: 'tag-color-box-default',
        };
    };
    TvmTagsService.prototype.getNetworkDeviceTag = function () {
        if (!this.hasNetworkScanEnabled) {
            return undefined;
        }
        return {
            id: 'networkSeviceTag',
            name: this.i18nService.get('tvm.securityRecommendation.networkDevice'),
            className: 'tag-color-box-default',
        };
    };
    TvmTagsService.prototype.getEolVersionTag = function () {
        return {
            id: 'endOfLifeVersionTag',
            name: this.i18nService.get('tvm.securityRecommendation.endOfLifeVersion'),
        };
    };
    TvmTagsService.prototype.getUpcomingEolVersionTag = function () {
        return {
            id: 'upcomingEndOfLifeVersionTag',
            name: this.i18nService.get('tvm.securityRecommendation.upcomingEndOfLifeVersion'),
        };
    };
    TvmTagsService.prototype.getHasEolVersionsTag = function () {
        return {
            id: 'hasEolVersionsTag',
            name: this.i18nService.get('tvm.securityRecommendation.hasEolVersions'),
        };
    };
    TvmTagsService.prototype.getHasUpcomingEolVersionsTag = function () {
        return {
            id: 'hasUpcomingEolVersionsTag',
            name: this.i18nService.get('tvm.securityRecommendation.hasUpcomingEolVersions'),
        };
    };
    TvmTagsService.prototype.verifyUpcomingEolVersionTag = function (eolVersionState) {
        return eolVersionState == EolState.UpcomingEOL;
    };
    TvmTagsService.prototype.verifyAlreadyEolVersionTag = function (eolVersionState) {
        return eolVersionState == EolState.AlreadyEOL;
    };
    TvmTagsService.prototype.verifyUpcomingEolSoftwareTag = function (eolSoftwareState) {
        return eolSoftwareState == EolState.UpcomingEOL;
    };
    TvmTagsService.prototype.verifyAlreadyEolSoftwareTag = function (eolSoftwareState) {
        return eolSoftwareState == EolState.AlreadyEOL;
    };
    TvmTagsService.prototype.verifyZeroDayTag = function (vulnerabilityType, patchReleaseDate) {
        return this.zeroDayEnabled && vulnerabilityType === VulnerabilityType.ZeroDay && !patchReleaseDate;
    };
    return TvmTagsService;
}());
export { TvmTagsService };
