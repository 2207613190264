import { SevilleModule } from '../../seville/seville.module';
import { RegExpService } from '@wcd/shared';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { mapNetworkEventTypeToMessage } from './common/timelineeventcommon';
import { breadcrumbsStateService } from '@wcd/shared';
import { UrlsService } from '../../../../@entities/urls/services/urls.service';
import { Feature } from '@wcd/config';
import { filter } from 'rxjs/operators';
import { TrackingEventType } from '../../../../insights/models/tracking-event-type.enum';

SevilleModule.controller('seville.threatintel.url', urlController);

SevilleModule.config([
	'$stateProvider',
	'searchProvider',
	function($stateProvider, searchProvider) {
		$stateProvider.state('url', {
			url: '/url/:id/:time',
			params: {
				time: {
					value: null,
					squash: true,
				},
			},
			showBreadcrumbs: true,
			featureFlagToggleFeatureId: Feature.UpgradeUrlPage,
			showToggleFeatureId: Feature.ShowUpgradeUrlPageToggle,
			title: 'URL',
			views: {
				main: {
					template: `
				<div ng-if="url.urlExistsInOrg" data-track-component="UrlPage">
					<div ng-if="url.showDeprecatedMessage"
						class="wcd-padding-horizontal wcd-flex-center-vertical wcd-flex-horizontal ms-background-color-themeLight"
						style="height:50px; margin: 0 -12px;">
						<i class="icon icon-Info wcd-margin-small-right"></i>
						<div class="message-bar-text wcd-flex-1">
							<span>{{url.deprecatedMessageText}}</span>
							<a ng-click="url.turnOnNewUrlPage()">Take me to the new url page!</a>
						</div>
					</div>
                <div class="row entity-title">
                    URL worldwide
                </div>
                <div class="row entity">
                    <!-- Main -->
                    <div class="col-sm-12 entity-cube">
                        <div class="border-default inner-box" style="min-height:0">
                            <div>
                                <i class="icon icon-md icon-Link"></i>
                                <span class="entity-meta-main-title">URL</span>
                            </div>
                            <div>
                                <div class="entity-meta-main-sub">{{url.originalUrl}}</div>
							</div>
							<div>
								<a href="{{url.whoIsLink}}"
									data-track-id="DetailsOnWhoIsLink"
									data-track-type="ExternalLink"
									target="_blank">View details at WhoIs</a>
							</div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-sm-12">

                        <!-- Associated Alerts -->
                        <associated-ioas entity="'Url'" url="url.originalUrl" refresh-investigation-states="true"></associated-ioas>
                        <div class="row entity-title entity-section">
                            URL in organization
                        </div>

                        <div class="row">
                            <div>
                                Filter by:
                                <div class="dropdown inline" uib-dropdown>
                                    <button class="btn btn-link dropdown-toggle alerts-btn" uib-dropdown-toggle type="button" data-toggle="dropdown" aria-expanded="true">
                                        <span>
                                            <span ng-if="url.telemetryLookback == 180">6 months</span>
                                            <span ng-if="url.telemetryLookback == 1">1 day</span>
                                            <span ng-if="url.telemetryLookback != 180 && url.telemetryLookback > 1">
                                                {{url.telemetryLookback}} days
                                            </span>
                                        </span>
                                        <span class="icon icon-sm icon-ChevronDown"></span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-shadow dropdown-margin">
                                        <li ng-class="{'dropdown-selected' : url.telemetryLookback == '1'}"><a href="#"
                                        	data-track-id="TelemetryLookback1d"
											data-track-type="Selection"
											ng-model="url.telemetryLookback" uib-btn-radio="'1'">1 day</a></li>
                                        <li ng-class="{'dropdown-selected' : url.telemetryLookback == '3'}"><a href="#"
                                        	data-track-id="TelemetryLookback3d"
											data-track-type="Selection"
                                        	ng-model="url.telemetryLookback" uib-btn-radio="'3'">3 days</a></li>
                                        <li ng-class="{'dropdown-selected' : url.telemetryLookback == '7'}"><a href="#"
                                        	data-track-id="TelemetryLookback7d"
											data-track-type="Selection"
                                        	ng-model="url.telemetryLookback" uib-btn-radio="'7'">7 days</a></li>
                                        <li ng-class="{'dropdown-selected' : url.telemetryLookback == '30'}"><a href="#"
                                        	data-track-id="TelemetryLookback30d"
											data-track-type="Selection"
                                        	ng-model="url.telemetryLookback" uib-btn-radio="'30'">30 days</a></li>
                                        <li ng-class="{'dropdown-selected' : url.telemetryLookback == '180'}"><a href="#"
                                        	data-track-id="TelemetryLookback180d"
											data-track-type="Selection"
                                        	ng-model="url.telemetryLookback" uib-btn-radio="'180'">6 months</a></li>
                                    </ul>
                                </div>
                            </div>

                            <!--Prevalence-->
                            <prevalence-cube loaded="url.prevalenceLoaded" class="entity-cube col-sm-12"
                                             org="true"
                                             prevalence="url.orgPrevalence"
                                             first-seen="url.orgFirstseen"
                                             lookback="url.telemetryLookback"
                                             last-seen="url.orgLastseen">
                            </prevalence-cube>
                        </div>
                    </div>
				</div>

                <!-- Date picker & time bar-->
				<div ng-class=":: {'entity-toolbox': url.isExportToCsvEnabled, 'row': !url.isExportToCsvEnabled}" class="wcd-margin-large-top">
					<timeline-datepicker from-date="url.fromDate" to-date="url.toDate" load-timeline="url.loadUrlTimeline()" start-date="url.telemetryFromDate" end-date="url.telemetryToDate" mask-results="url.maskResults" in-cold-storage="url.inColdStorage" picker-title="'Most recent observed devices with Url from:'" cold-storage-enabled="true"></timeline-datepicker>
					<timeline-timebar from-date="url.fromDate" to-date="url.toDate"></timeline-timebar>
				</div>
				<div ng-if="::url.isExportToCsvEnabled" class="entity-toolbox entity-toolbar">
					<button ng-click="url.openExportToCsvDialog()" class="entity-toolbox-button entity-toolbar-button pull-right">
						<i class="icon icon-ExcelLogo export-to-csv-excel-logo"></i> Export
					</button>
				</div>

				<div class="row table-header event-timeline-columnsheader">
                    <div class="col-md-5 col-md-offset-1 user-timeline-title-header">Machine</div>
                    <div class="col-md-5 col-md-offset-1 user-timeline-title-header user-timeline-title-header-description">Description</div>
                </div>
                <!-- Url grid -->
                <timeline-grid loading="url.loading" partial-results-loaded="url.partialResultsLoaded" events="url.events" records-per-page="url.telemetryPageSize" mask-results="url.maskResults" in-cold-storage="url.inColdStorage" load-timeline="url.loadUrlTimeline()" entityname="url.originalUrl"></timeline-grid>
            </div>
						`,
					controller: 'seville.threatintel.url',
					controllerAs: 'url',
				},
			},
		});

		searchProvider.registerEntitySearchProvider(
			'URL',
			function(searchTerm) {
				return searchTerm.indexOf('://') > -1;
			},
			function(searchTerm, $state) {
				$state.go('url', { type: 'url', id: encodeURIComponent(searchTerm) }, { reload: true });
			}
		);
	},
]);

urlController.$inject = [
	'$scope',
	'$filter',
	'$log',
	'$http',
	'$state',
	'$stateParams',
	'$q',
	'urlMapping',
	'investigationService',
	'demoTenantResolverService',
	'coldStorage',
	'coldStorageConsts',
	'progressiveTimelineLoader',
	'featuresService',
	'exportToCsvService',
	'appInsights',
	'ng2router',
	'urlsService',
];

function urlController(
	$scope,
	$filter,
	$log,
	$http,
	$state,
	$stateParams,
	$q,
	urlMapping,
	investigation,
	demoTenantResolverService,
	coldStorage,
	coldStorageConsts,
	progressiveTimelineLoader,
	featuresService,
	exportToCsvService,
	appInsights: AppInsightsService,
	ng2router,
	urlsService: UrlsService
) {
	var vm = this;
	if (!$stateParams.id) {
		ng2router.navigate(['/errors']);
		return;
	}

	// handle old links: if url is of old url page but "upgrade url page" flag is on, redirect to new url page
	if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
		goToNewUrlPage();
		return;
	} else {
		if (
			featuresService._defaultFeatures.has(Feature.UpgradeUrlPage) &&
			!featuresService._defaultFeatures.has(Feature.ShowUpgradeUrlPageToggle) &&
			!featuresService.getLocalFeatureValue(Feature.UpgradeUrlPage)
		) {
			featuresService.clearLocalFeatureValue(Feature.ShowUpgradeUrlPageToggle);
			featuresService.clearLocalFeatureValue(Feature.UpgradeUrlPage);
			goToNewUrlPage();
			return;
		}
	}

	vm.loading = 0;
	vm.urlExistsInOrg = false;
	vm.partialResultsLoaded = false;
	vm.main = { EntityName: 'url', Id: $stateParams.id };
	vm.telemetryLookback = '30'; // 30 days by default
	vm.telemetryPageSize = 100;
	vm.events = [];
	vm.originalUrl = decodeURIComponent($stateParams.id);
	var lookback = demoTenantResolverService.isDemoTenant()
		? demoTenantResolverService.demoTenantLookback
		: coldStorageConsts.URL_TIMELINE_LOOKBACK;
	vm.telemetryToDate = $stateParams.time ? new Date($stateParams.time) : new Date();
	vm.telemetryFromDate = coldStorage.overrideStartDateIfBeyondHotData(vm.telemetryToDate, lookback);
	vm.maskResults = false;
	vm.inColdStorage = false;
	var isoStringFilter = $filter('isoString');
	vm.isExportToCsvEnabled = featuresService.isEnabled('ExportEntityTimelinesToCsv');
	vm.loadUrlTimeline = loadUrlTimeline;
	vm.whoIsLink = `https://www.whois.com/whois/${vm.originalUrl}`;
	vm.showDeprecatedMessage = featuresService.isEnabled(Feature.ShowDeprecatedUrlPageMessage);

	const privatePreviewDisableDate = new Date(Date.UTC(2019, 7, 25, 23, 59, 59)); // 25.8.2019 23:59:59
	const disableDate = new Date() < privatePreviewDisableDate ? 'August 25' : 'September 1';
	vm.deprecatedMessageText = `Please note that this view will be unavailable starting ${disableDate}, 2019.`;

	loadEntity();

	$scope.$watch(
		function() {
			return vm.telemetryLookback;
		},
		function() {
			if (vm.urlExistsInOrg) {
				loadUrlStats();
			}
		}
	);

	vm.turnOnNewUrlPage = function() {
		appInsights.trackEvent('UI', {
			type: TrackingEventType.Action,
			id: 'turnOnNewUrlPageFromDeprecatedPageMessage',
			component: 'OldUrlPage',
		});
		featuresService.setLocalFeatureValue(Feature.UpgradeUrlPage, true);
	};

	// listen to "OptIn" feature toggle and navigate to new url page
	vm.featuresChangeSubscription = featuresService.featureChanged$
		.pipe(filter(({ featureId }) => featureId === Feature.UpgradeUrlPage))
		.subscribe((featureId: string) => {
			if (featuresService.isEnabled(Feature.UpgradeUrlPage)) {
				goToNewUrlPage();
			}
		});

	$scope.$on('$destroy', () => {
		vm.featuresChangeSubscription && vm.featuresChangeSubscription.unsubscribe();
	});

	function loadEntity() {
		vm.urlExistsInOrg = false;

		const domain = decodeURIComponent($stateParams.id);

		// The call to whois shouldn't return whois info- we're moving away from it. It's only used to determine whether the URL exists in Org.
		// Name is only preserved to not creating a breaking change. TODO change to a more appropriate BE API when available
		const url = urlMapping.getThreatIntelUrl() + '/whois';
		const whoIsLookback = demoTenantResolverService.isDemoTenant()
			? demoTenantResolverService.demoTenantLookback
			: 30;

		$log.debug('Checking whether domain exists in Org. Domain=' + domain);
		$http
			.get(url, {
				params: {
					id: domain,
					lookbackInDays: whoIsLookback,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug(`Domain ${domain} exists in org`);
						vm.urlExistsInOrg = true;

						loadUrlStats();
						loadUrlTimeline();

						updateInvestigationFlow(vm.main);
					} else if (response.status === 404 || response.status === 400) {
						ng2router.navigate(['/errors/URLNotFound'], {
							queryParams: { itemId: domain },
						});
						return;
					} else {
						vm.events = null;
					}
				},
				function(response) {
					vm.events = null;
					$log.error('Error occur while checking domain existence in org. Domain = ' + domain);
				}
			);
	}

	function loadUrlStats() {
		vm.orgFirstseen = null;
		vm.orgLastseen = null;
		vm.orgPrevalence = null;
		vm.prevalenceLoaded = false;

		var domain = decodeURIComponent($stateParams.id);
		var lookback = demoTenantResolverService.isDemoTenant()
			? demoTenantResolverService.demoTenantLookback
			: vm.telemetryLookback;
		$log.debug('loading first & last seen, for url with id=' + domain);
		$http
			.get(urlMapping.getThreatIntelUrl() + '/UrlStats', {
				params: {
					url: domain,
					lookingBackIndays: lookback,
				},
			})
			.then(
				function(response) {
					if (response.status == 200) {
						$log.debug('loading url stats for url with id=' + domain + ' loaded successfully');
						if (response.data) {
							vm.orgFirstseen = response.data.OrgFirstSeen;
							vm.orgLastseen = response.data.OrgLastSeen;
							vm.orgPrevalence = response.data.OrgPrevalence
								? parseInt(response.data.OrgPrevalence)
								: 0;

							vm.prevalenceLoaded = true;
						}
					}
				},
				function(data, status, headers, config) {
					$log.error('Error occur while loading first & last seen information');
				}
			);
	}

	function getUrlForBreadcrumbs(url) {
		if (url.length < 25) return url;
		return url.substring(0, 25) + '..';
	}

	function updateInvestigationFlow(data) {
		if (!data || data.length <= 1) {
			data = {};
		}

		data.icon = 'icon-Link';
		data.state = 'URL';
		data.displaystate = vm.originalUrl ? getUrlForBreadcrumbs(vm.originalUrl) : 'URL';
		investigation.setActive(data);
		breadcrumbsStateService.add({
			id: `url_${data.Id}`,
			label: data.displaystate,
			url: $state.href($state.current),
			queryParams: $state.params,
		});
	}

	function loadUrlTimeline() {
		if (!vm.main || !vm.telemetryToDate) {
			return;
		}

		progressiveTimelineLoader.loadTimelineProgressively(
			loadTimelineEvents,
			vm.telemetryFromDate,
			vm.telemetryToDate,
			vm.telemetryPageSize,
			function(newEvents, isPartialResultsLoaded) {
				vm.events = newEvents;
				vm.partialResultsLoaded = isPartialResultsLoaded;
			},
			function(record) {
				return record.machineId || record.MachineId;
			}
		);
	}

	function loadTimelineEvents(fromDate, toDate) {
		return $q(function(resolve, reject) {
			if (vm.loading > 0 || !vm.telemetryToDate) {
				return reject();
			}
			vm.loading++;

			var domain = vm.originalUrl;
			var url = urlMapping.getThreatIntelUrl() + '/UrlTimeline';
			$log.debug('loading url timeline, for url=' + domain);
			$http
				.get(url, {
					params: {
						Url: domain,
						fromDate: fromDate,
						toDate: toDate,
						pageSize: vm.telemetryPageSize,
						pageIndex: 1,
					},
				})
				.then(
					function(response) {
						if (response.status == 200) {
							var data = response.data;
							if (!data || !data.Items) {
								$log.error('Error occur while loading events timeline');
								vm.loading--;

								return;
							}
							$log.debug('url timeline events loaded successfully');

							var newEvents = [];
							for (var i = 0; i < data.Items.length; i++) {
								var record = data.Items[i];
								var files = {
									TotalResults: 1,
									Items: [],
								};

								if (!record.Sha1 || !RegExpService.sha1.test(record.Sha1)) {
									record.Sha1 = 'Hash could not be retrieved';
									record.invalidHash = true;
								}

								files.Items.push({
									Sha1: record.Sha1,
									Path: record.RealPath,
									Name: record.FileName,
									Valid: !record.invalidHash,
									Signer: record.Signer,
								});

								const description = mapNetworkEventTypeToMessage(record.NetworkEventType);

								var item = {
									time: record.Timestamp,
									sort: $filter('date')(record.Timestamp, 'yyyy-MM-dd-HH-mm-sss', 'UTC'),
									desc: record.Description,
									wcdMachineId: record.WcdMachineId,
									machineId: record.MachineId,
									filename: record.FileName,
									sha1: record.Sha1,
									realPath: record.RealPath,
									signer: record.Signer,
									isValidCertificate: record.IsValidCertificate,
									computerDnsName: record.ComputerDnsName,
									type: 'Machine',
									source: record.Source,
									message: description,
									files: files,
									valid: !record.invalidHash,
									json: record,
									isoTime: isoStringFilter(record.Timestamp),
									containerId: record.ContainerId,
									uri: removeSchema(record.Uri || vm.originalUrl),
								};

								newEvents.push(item);
							}

							resolve(newEvents);
						}

						vm.loading--;
					},
					function(response) {
						$log.error('Error occured while loading url timeline events');
						vm.loading--;
						return reject();
					}
				);
		});
	}

	function removeSchema(uri: string) {
		return (uri || '').replace(RegExpService.urlSchema, '');
	}

	function goToNewUrlPage() {
		const alertTime = $state.params.time && new Date($state.params.time);
		ng2router.navigate([
			urlsService.getUrlLink((vm.main && vm.main.originalUrl) || $state.params.id, alertTime),
		]);
	}

	vm.openExportToCsvDialog = function() {
		if (vm.originalUrl) {
			appInsights.trackEvent('UsageTrack', {
				ButtonType: 'ExportToCsv',
				Page: 'URL',
				Component: 'UrlExportToCsv',
			});
			return exportToCsvService.openDialog({
				title: 'Export URL organizational footprint',
				maxDays: 30,
				maxDaysInColdStorage: 7,
				timelineDate: vm.telemetryToDate,
				initialStartDate: vm.telemetryFromDate,
				initialEndDate: vm.telemetryToDate,
				httpRequestParams: { url: vm.originalUrl },
				checkResultsUrl: '/LookForUrlEventsToExport',
				getAuthTokenUrl: '/GetAuthToken?exportUrl=DownloadUrlTimelineToCsv',
				downloadUrl: '/DownloadUrlTimelineToCsv',
			});
		}
	};
}
