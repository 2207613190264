var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ModalContainer } from '../modals/models/modal-container.model';
import { Router } from '@angular/router';
var ShortcutComponent = /** @class */ (function (_super) {
    __extends(ShortcutComponent, _super);
    function ShortcutComponent(router) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        return _this;
    }
    ShortcutComponent.prototype.submitShortcut = function () {
        if (this.shortcutValue)
            this.onEnterShortcut(this.shortcutValue);
    };
    return ShortcutComponent;
}(ModalContainer));
export { ShortcutComponent };
