/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component.ngfactory";
import * as i2 from "../../../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component";
import * as i3 from "../../../../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i4 from "../../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i5 from "@angular/common";
import * as i6 from "./compliant-devices-dataview-cell.component";
import * as i7 from "@microsoft/paris/dist/lib/paris";
var styles_CompliantDevicesDataviewCellComponent = [];
var RenderType_CompliantDevicesDataviewCellComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_CompliantDevicesDataviewCellComponent, data: {} });
export { RenderType_CompliantDevicesDataviewCellComponent as RenderType_CompliantDevicesDataviewCellComponent };
function View_CompliantDevicesDataviewCellComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "tvm-assets-statistics", [], null, null, null, i1.View_TvmAssetsStatisticsComponent_0, i1.RenderType_TvmAssetsStatisticsComponent)), i0.ɵdid(1, 245760, null, 0, i2.TvmAssetsStatisticsComponent, [i0.ChangeDetectorRef, i3.LocaleConfigService, i4.I18nService], { isImprovementNegative: [0, "isImprovementNegative"], totalAssetCount: [1, "totalAssetCount"], assetsCount: [2, "assetsCount"], assetsCountHistory: [3, "assetsCountHistory"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isImprovementNegative; var currVal_1 = _co.totalAssetCount; var currVal_2 = _co.assetsCount; var currVal_3 = _co.assetsCountHistory; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3); }, null); }
function View_CompliantDevicesDataviewCellComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i0.ɵted(2, null, ["\n\t\t", "\n\t"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.i18nService.strings.common_loading; _ck(_v, 2, 0, currVal_0); }); }
export function View_CompliantDevicesDataviewCellComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵand(16777216, null, null, 1, null, View_CompliantDevicesDataviewCellComponent_1)), i0.ɵdid(1, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵand(0, [["loading", 2]], null, 0, null, View_CompliantDevicesDataviewCellComponent_2)), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = !_co.isLoading; var currVal_1 = i0.ɵnov(_v, 3); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_CompliantDevicesDataviewCellComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-compliant-devices-dataview-cell", [], null, null, null, View_CompliantDevicesDataviewCellComponent_0, RenderType_CompliantDevicesDataviewCellComponent)), i0.ɵdid(1, 704512, null, 0, i6.CompliantDevicesDataviewCellComponent, [i7.Paris, i0.ChangeDetectorRef, i4.I18nService], null, null)], null, null); }
var CompliantDevicesDataviewCellComponentNgFactory = i0.ɵccf("app-compliant-devices-dataview-cell", i6.CompliantDevicesDataviewCellComponent, View_CompliantDevicesDataviewCellComponent_Host_0, { profileId: "profileId", totalAssetCount: "totalAssetCount", assetsCount: "assetsCount", assetsCountHistory: "assetsCountHistory" }, {}, []);
export { CompliantDevicesDataviewCellComponentNgFactory as CompliantDevicesDataviewCellComponentNgFactory };
