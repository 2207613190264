import { map, filter } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { LegacyUser, LegacyUserLegacyUserProfileRelationship, ServiceSourceType, MdeUserRoleActionEnum, } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '@wcd/utils';
import { UserEntityPanelComponent } from '../components/user-entity-panel.component';
import { LegacyUsersFieldsService } from './legacy-users.fields.service';
import { UsersService } from './users.service';
import { UserEntityComponent } from '../components/user-entity.component';
import { UserEntityDetailsComponent } from '../../../global_entities/components/entity-details/user-entity-details.component';
import { Router } from '@angular/router';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { UserMinimizedDetailsComponent } from '../../../global_entities/components/entity-minimized/user.minimized.details.component';
import { I18nService } from '@wcd/i18n';
import { Feature, FeaturesService, AppContextService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
var UserEntityTypeService = /** @class */ (function () {
    function UserEntityTypeService(paris, usersService, timeRangesService, router, i18nService, featuresService, appContextService) {
        var _this = this;
        this.paris = paris;
        this.usersService = usersService;
        this.timeRangesService = timeRangesService;
        this.router = router;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.appContextService = appContextService;
        this.timeRanges = this.timeRangesService.standard;
        this._entityType = new Lazy(function () { return ({
            id: 'user',
            entity: LegacyUser,
            icon: FabricIconNames.Contact,
            getImage: function (users) {
                if (users.length !== 1) {
                    return null;
                }
                return _this.paris
                    .getRelatedItem(LegacyUserLegacyUserProfileRelationship, users[0])
                    .pipe(map(function (userProfile) { return userProfile.thumbnailPhotoSrc; }), filter(function (url) { return !!url; }));
            },
            getIcon: function () { return FabricIconNames.Contact; },
            getIconCssClass: function () { return "wcd-circle"; },
            singleEntityPanelComponentType: UserEntityPanelComponent,
            entityContentsComponentType: UserEntityComponent,
            entityDetailsComponentType: UserEntityDetailsComponent,
            entityMinimizedComponentType: UserMinimizedDetailsComponent,
            getUseExternalRouting: function (users) { return false; },
            getEntityName: function (user) { return user.fullName; },
            entityPluralNameKey: 'user_entityType_pluralName',
            entitySingularNameKey: 'user_entityType_singularName',
            getEntitiesLink: function (users) {
                if (!users || users.length !== 1) {
                    return null;
                }
                var user = users[0];
                // we currently support user link only if we have either sid or accountName + accountDomainName.
                if (!user.sid && !(user.accountName && user.accountDomainName)) {
                    return null;
                }
                var _a = _this.usersService.getUserLink({
                    accountName: user.accountName,
                    accountDomainName: user.accountDomainName,
                    sid: user.sid,
                }), commands = _a.commands, queryParams = _a.queryParams;
                return _this.router.serializeUrl(_this.router.createUrlTree(commands, { queryParams: queryParams }));
            },
            getNavigationModel: function (user, serviceSource) {
                if (!_this.featuresService.isEnabled(Feature.PortedSccPages) ||
                    serviceSource !== ServiceSourceType.Wdatp) {
                    return null;
                }
                var url = _this.entityType.getEntitiesLink([user]);
                if (!url) {
                    return null;
                }
                var _a = url.split('?'), routerLink = _a[0], queryParamsPart = _a[1];
                var queryParams = {};
                // IE and older Edge dont support URLSearchParams
                window.URLSearchParams &&
                    new URLSearchParams(queryParamsPart).forEach(function (value, key) { return (queryParams[key] = value); });
                return {
                    routerLink: [routerLink],
                    queryParams: queryParams,
                };
            },
            dataViewOptions: {
                fields: LegacyUsersFieldsService,
                dateRangeOptions: {
                    defaultTimeRangeId: 'month',
                    supportedRanges: _this.timeRanges,
                },
                fixedOptions: function (dateRange, searchTerm) { return ({
                    lookingBackIndays: dateRange.value.toString(),
                    searchTerm: searchTerm,
                }); },
                exportOptions: {
                    showModalOnExport: false,
                    exportResults: function (options, fixedOptions, searchTerm) {
                        return _this.usersService.downloadCsv({
                            lookingBackIndays: fixedOptions.lookingBackIndays,
                            userAccount: searchTerm,
                            userAccountDomain: null,
                        });
                    },
                },
                dataViewConfig: {
                    allowPaging: false,
                    allowFilters: false,
                },
            },
            searchOptions: {
                displayName: _this.i18nService.strings.entity_type_display_name_user,
                resolveTypeFromSearchTerm: function (searchTerm) {
                    if (searchTerm) {
                        var splitValues = searchTerm.split('\\');
                        if (splitValues.length === 2 && splitValues[0].length > 0 && splitValues[1].length > 0) {
                            return true;
                        }
                    }
                    return false;
                },
                getSearchParams: function (searchTerm) {
                    var resultsPageName = _this.appContextService.isSCC || _this.featuresService.isEnabled(Feature.UpgradeSearchUsers)
                        ? 'searchResults'
                        : 'search';
                    return { url: "/" + resultsPageName + "/users/" + searchTerm };
                },
                searchDropdownOrder: 0,
                requiredAllowedActions: MdeUserRoleActionEnum.viewData,
                flavorConfig: AppFlavorConfig.search.user,
            },
            entityPageViewMode: EntityPageViewMode.Asset,
        }); });
    }
    Object.defineProperty(UserEntityTypeService.prototype, "entityType", {
        get: function () {
            return this._entityType.value;
        },
        enumerable: true,
        configurable: true
    });
    return UserEntityTypeService;
}());
export { UserEntityTypeService };
