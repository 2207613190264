import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

export enum ComponentProvisioningStatus {
	NotProvisioned = 'NotProvisioned',
	InProgress = 'InProgress',
	Provisioned = 'Provisioned',
	ProvisionFailed = 'ProvisionFailed',
}

@ValueObject({
	singularName: 'Component provisioning status',
	pluralName: 'Component provisioning status',
})
export class ComponentProvisioningStatusModel extends ModelBase {
	@EntityField()
	componentProvisioning: ComponentProvisioningStatus;
}
