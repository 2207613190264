import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MachinesFiltersService } from './machines.filters.service';
import { DataviewField } from '@wcd/dataview';
import { Machine } from '@wcd/domain';
import { compact } from 'lodash-es';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { MachinesFieldsService } from './machines.fields';
import { I18nService } from '@wcd/i18n';
import { TagsService } from '../../../tags/services/tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';

export const RELATION_MACHINE_FIELDS = {
	machinename: 1,
	riskScores: 1,
	tags: 1,
};

const MACHINE_FIELDS_ORDER = ["machinename", "id", "riskScores", "tags", "incidentAlerts", "firstEventTime", "lastEventTime", "sensitivity" ]

@Injectable()
export class RelationMachineService {

	constructor(
		private machinesFiltersService: MachinesFiltersService,
		private machineFields: MachinesFieldsService,
		private i18nService: I18nService,
		private tagsService: TagsService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
	) {}

	getRelationMachineFilters(): Observable<Record<string, any>> {
		return this.machinesFiltersService.getMachinesFilters({}).pipe(
			map(m => ({
				riskScores: m.riskScores,
				tags: { count: m.tags.count, values: m.tags.values.filter(tag => !!tag.name) },
			}))
		);
	}

	getFields() : Array<DataviewField<Machine>> {
		return this.machineFields.fields
			.filter(field => RELATION_MACHINE_FIELDS[field.id])
			.map(field => ({
				...field,
				useDefaultEmptyFieldComponent: true,
				sort: { enabled: field.id !== 'tags' },
			}))
			.map(field => new DataviewField<Machine>(field))
			.concat(
				compact([
					{
						id: 'id',
						name: this.i18nService.strings.machines_dataView_fields_id,
						getDisplay: (machine: Machine) => machine.id,
						getTooltip: (machine: Machine) => machine.id,
						sort: { enabled: false },
					},
					{
						id: 'incidentAlerts',
						name: this.i18nService.get('incident.machines.field.incidentAlerts'),
						descriptionKey: 'machines.incidentAlerts',
						getDisplay: (machine: Machine) =>
							machine.totalAlerts ? `${machine.activeAlerts} / ${machine.totalAlerts}` : null,
						getTooltip: machine => this.i18nService.get('machines.incidentAlertsTooltip', machine),
						sort: { enabled: false },
						useDefaultEmptyFieldComponent: true,
					},
					{
						id: 'firstEventTime',
						name: this.i18nService.get('incident.machines.field.firstEventTime'),
						component: {
							type: TzDateComponent,
							getProps: (machine: Machine) => ({ date: machine.firstEventTime }),
						},
						useDefaultEmptyFieldComponent: true,
					},
					{
						id: 'lastEventTime',
						name: this.i18nService.get('incident.machines.field.lastEventTime'),
						component: {
							type: TzDateComponent,
							getProps: (machine: Machine) => ({ date: machine.lastEventTime }),
						},
						useDefaultEmptyFieldComponent: true,
					},
					// TODO: check if this is relevant only for incidents..
					this.featuresService.isEnabled(Feature.IncidentSensitivity) && this.flavorService.isEnabled(AppFlavorConfig.devices.dataSensitivity)
						? {
							id: 'sensitivity',
							name: this.i18nService.get('incident.fields.sensitivity.title'),
							descriptionKey: 'machines.entityDetails.fields.sensitivity.tooltip',
							useDefaultEmptyFieldComponent: true,
							getDisplay: (machine: Machine) =>
								machine.sensitivity && machine.sensitivity.label,
							getCssClass: (machine: Machine) =>
								machine.sensitivity
									? this.tagsService.getDataSensitivityTag(machine.sensitivity).className
									: null,
							sort: { enabled: false },
						}
						: null,
				]).map(field => new DataviewField<Machine>(field))
			).sort((fieldA, fieldB) => MACHINE_FIELDS_ORDER.indexOf(fieldA.id) - MACHINE_FIELDS_ORDER.indexOf(fieldB.id));
	}
}
