import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Threat Type Count',
	pluralName: 'Threat Type Counts',
	readonly: true,
})
export class ThreatTypeCount extends ModelBase {
	@EntityField() count: number;
}
