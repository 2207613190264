
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.keyData">
				<dt role="none">{{ i18nService.strings.airsEntities_registryKey_fields_key }}</dt>
				<dd role="none">{{ entity.keyData }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.hive">
				<dt role="none">{{ i18nService.strings.airsEntities_registryKey_fields_hive }}</dt>
				<dd role="none">{{ entity.hive }}</dd>
			</ng-container>
		</dl>
	