import { Dictionary } from '@wcd/config';
var REPORTS = [
    { id: 0, name: 'Phishing' },
    { id: 1, name: 'Investigations' },
    { id: 2, name: 'Endpoints' },
    { id: 3, name: 'Threats' },
    { id: 4, name: 'Dashboard' },
];
var reportsDictionary;
var AirsReportTypes = /** @class */ (function () {
    function AirsReportTypes() {
    }
    Object.defineProperty(AirsReportTypes, "ALL", {
        get: function () {
            return REPORTS;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AirsReportTypes, "DEFAULT", {
        get: function () {
            return REPORTS[0];
        },
        enumerable: true,
        configurable: true
    });
    AirsReportTypes.getReportTypeById = function (reportId) {
        if (!reportsDictionary)
            reportsDictionary = Dictionary.fromList(REPORTS, 'id');
        return reportsDictionary.get(reportId);
    };
    return AirsReportTypes;
}());
export { AirsReportTypes };
