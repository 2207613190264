import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { VirusTotalFileReport } from '../file.virus-total-file-report.value-object';

@EntityRelationship({
	sourceEntity: File,
	dataEntity: VirusTotalFileReport,
	allowedTypes: [RelationshipType.OneToOne],
	baseUrl: config => config.data.serviceUrls.virusFileReport,
	endpoint: 'VirusTotalFileReport',
	getRelationshipData: (file: File) => ({
		fileIdentifier: file.id,
	}),
})
export class FileVirusTotalFileReportRelationship
	implements EntityRelationshipRepositoryType<File, VirusTotalFileReport> {}
