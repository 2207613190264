/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../forms/src/lib/components/toggle.component.ngfactory";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../../../forms/src/lib/components/toggle.component";
import * as i4 from "../../../../../../forms/src/lib/models/disableable.interface";
import * as i5 from "../../../../../../i18n/src/lib/services/i18n.service";
import * as i6 from "./filter-values.toggle.component";
var styles_FilterValuesToggleComponent = [];
var RenderType_FilterValuesToggleComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_FilterValuesToggleComponent, data: {} });
export { RenderType_FilterValuesToggleComponent as RenderType_FilterValuesToggleComponent };
export function View_FilterValuesToggleComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "wcd-toggle", [], null, [[null, "change"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i0.ɵnov(_v, 4).onKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("change" === en)) {
        var pd_1 = (_co.onChange($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i1.View_ToggleComponent_0, i1.RenderType_ToggleComponent)), i0.ɵprd(5120, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i3.ToggleComponent]), i0.ɵprd(6144, null, i4.DISABLEABLE_TOKEN, null, [i3.ToggleComponent]), i0.ɵdid(4, 49152, null, 0, i3.ToggleComponent, [i5.I18nService], { trueLabel: [0, "trueLabel"], falseLabel: [1, "falseLabel"], helpText: [2, "helpText"], ariaLabel: [3, "ariaLabel"], checked: [4, "checked"] }, { change: "change" }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.config == null) ? null : _co.config.trueLabel); var currVal_1 = ((_co.config == null) ? null : _co.config.falseLabel); var currVal_2 = ((_co.config == null) ? null : _co.config.helpText); var currVal_3 = ((_co.config == null) ? null : _co.config.ariaLabel); var currVal_4 = _co.selectedValues; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
export function View_FilterValuesToggleComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-filter-values-toggle", [], null, null, null, View_FilterValuesToggleComponent_0, RenderType_FilterValuesToggleComponent)), i0.ɵdid(1, 49152, null, 0, i6.FilterValuesToggleComponent, [i0.ChangeDetectorRef], null, null)], null, null); }
var FilterValuesToggleComponentNgFactory = i0.ɵccf("wcd-filter-values-toggle", i6.FilterValuesToggleComponent, View_FilterValuesToggleComponent_Host_0, { data: "data", fieldId: "fieldId", config: "config", selectedValues: "selectedValues", fixedSelectedValues: "fixedSelectedValues" }, { filterValuesChange: "filterValuesChange" }, []);
export { FilterValuesToggleComponentNgFactory as FilterValuesToggleComponentNgFactory };
