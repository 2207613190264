var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef, AfterViewInit, OnDestroy, ChangeDetectorRef, } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { of, zip } from 'rxjs';
import { filter, map, mergeMap, tap, take } from 'rxjs/operators';
import { MachinesService } from '../../../../@entities/machines/services/machines.service';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { RegExpService } from '@wcd/shared';
import { Alert, AlertAlertTimelineRelationship, FileInstance, Process, ProcessActionDetails, FileActionDetails, AntivirusRemediationAction, LegacyUser, File, EntityWithContext, } from '@wcd/domain';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { AlertsEtwService } from '../../services/alert-etw.service';
import { AlertProcessTreeHelperService } from '../../services/alert-process-tree-helper.service';
import { toObservable } from '../../../../utils/rxjs/utils';
var AlertProcessTreeComponent = /** @class */ (function () {
    function AlertProcessTreeComponent(changeDetection, genericEtwService, machinesService, entityPanelsService, paris, activatedEntity, helper) {
        this.changeDetection = changeDetection;
        this.genericEtwService = genericEtwService;
        this.machinesService = machinesService;
        this.entityPanelsService = entityPanelsService;
        this.paris = paris;
        this.activatedEntity = activatedEntity;
        this.helper = helper;
        this.subscriptions = [];
        this.missingEventInfo = null;
        this.loading = true;
        this.error = null;
    }
    AlertProcessTreeComponent.prototype.getAlert = function () {
        var _this = this;
        if (this.alert) {
            return of(this.alert);
        }
        return this.activatedEntity.currentEntity$.pipe(filter(function (entity) { return entity instanceof Alert; }), take(1), tap(function (alert) {
            _this.alert = alert;
        }));
    };
    AlertProcessTreeComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.alertTimeline$ = this.getAlert().pipe(mergeMap(function (alert) {
            return _this.paris
                .getRelationshipRepository(AlertAlertTimelineRelationship)
                .getRelatedItem(alert, {
                where: { flightingOverride: '', isAggregativeAlert: false },
            });
        }));
    };
    AlertProcessTreeComponent.prototype.ngOnDestroy = function () {
        this.subscriptions.forEach(function (s) { return s.unsubscribe(); });
    };
    AlertProcessTreeComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        var dataSubscription = this.alertTimeline$.pipe(take(1)).subscribe(function (_a) {
            var alertTimeline = _a.raw;
            if (!alertTimeline) {
                _this.error = new Error('No data received');
                return;
            }
            if (alertTimeline.MissingEventsSummary) {
                var events = _this.getMissingEventsString(alertTimeline.MissingEventsSummary);
                if (events) {
                    _this.missingEventInfo = {
                        events: events,
                        spanningTo: alertTimeline.MissingEventsSummary.LastEventTime,
                        machineUrl: _this.alert.machine &&
                            _this.machinesService.getMachineLink(_this.alert.machine.id, false, alertTimeline.MissingEventsSummary.LastEventTime),
                    };
                }
            }
            if (!alertTimeline.RootElements || !alertTimeline.RootElements.length) {
                _this.error = new Error('No elements');
            }
            else {
                var clickSubscription = _this.helper
                    .init(_this.treeContainerEl, _this.alert, alertTimeline)
                    .subscribe(function (rawEvent) { return _this.onNodeClick(rawEvent); });
                _this.subscriptions.push(clickSubscription);
            }
            _this.changeDetection.markForCheck();
        }, function (error) {
            _this.error = error;
        }, function () {
            _this.loading = false;
            _this.changeDetection.markForCheck();
        });
        this.subscriptions.push(dataSubscription);
    };
    AlertProcessTreeComponent.prototype.getMissingEventsString = function (missingEventsData) {
        if (!missingEventsData)
            return '';
        // Translate dictionary<event type, counter> to dictionary<event type friendly name, counter>
        var missingEventsDictionary = {};
        for (var d in missingEventsData.EventsByType) {
            var eventCount = missingEventsData.EventsByType[d];
            var eventFriendlyName = this.helper.getEventTypeFriendlyName(d);
            if (eventFriendlyName in missingEventsDictionary) {
                // Multiple event types could be mapped to the same friendly description - so we should sum them together
                missingEventsDictionary[eventFriendlyName] =
                    missingEventsDictionary[eventFriendlyName] + eventCount;
            }
            else {
                missingEventsDictionary[eventFriendlyName] = eventCount;
            }
        }
        var missingEventsDescriptions = [];
        for (var eventFriendlyName in missingEventsDictionary) {
            var eventCount = missingEventsDictionary[eventFriendlyName];
            var suffix = eventCount == 1 ? 'event' : 'events';
            missingEventsDescriptions.push(eventCount + ' ' + eventFriendlyName + ' ' + suffix);
        }
        return missingEventsDescriptions.join(', ');
    };
    AlertProcessTreeComponent.prototype.onNodeClick = function (rawEvent) {
        var _this = this;
        rawEvent.SidePaneType = rawEvent.ActionType;
        if (rawEvent.ElementType === this.genericEtwService.genericEtwElementType) {
            rawEvent.EtwSidePaneDetails = this.genericEtwService.getEtwSidePaneDetails(rawEvent.SidePaneType);
            rawEvent.SidePaneType = this.genericEtwService.getEtwSidePaneType(rawEvent);
        }
        var file$ = this.paris.createItem(FileInstance, rawEvent);
        var process$ = this.paris.createItem(Process, rawEvent);
        var targetProcess = rawEvent.EtwEventPropertiesAsJson && rawEvent.EtwEventPropertiesAsJson.TargetProcess;
        var targetProcessAction$ = toObservable(targetProcess &&
            this.paris.createItem(ProcessActionDetails, __assign({}, rawEvent, targetProcess, { CreationTime: targetProcess.CreationTimeUtc, ProcessCommandLine: targetProcess.CommandLine, IntegrityLevel: null })));
        var fileAction$ = this.paris.createItem(FileActionDetails, __assign({}, rawEvent, { EtwEventPropertiesAsJson: rawEvent.EtwEventPropertiesAsJson && __assign({}, rawEvent.EtwEventPropertiesAsJson, { Action: rawEvent.EtwEventPropertiesAsJson.Action &&
                    AntivirusRemediationAction[rawEvent.EtwEventPropertiesAsJson.Action] }) }));
        zip(file$, process$, targetProcessAction$, fileAction$)
            .pipe(take(1), map(function (_a) {
            var file = _a[0], process = _a[1], targetProcessAction = _a[2], fileAction = _a[3];
            // some tweaking of the received entities, as the alert process tree backend entities are somewhat different
            if (file && !RegExpService.sha1.test(file.sha1)) {
                file = new FileInstance(__assign({}, file, { sha1: null }));
            }
            if (process) {
                var user = new LegacyUser({
                    id: rawEvent.ProcessAccount,
                    accountName: rawEvent.ProcessAccount,
                });
                process = new Process(__assign({}, process, { file: file, user: user }));
            }
            if (targetProcessAction && targetProcessAction.process) {
                targetProcessAction.process = new Process(__assign({}, targetProcessAction.process, { file: file }));
            }
            return [file, process, targetProcessAction, fileAction];
        }))
            .subscribe(function (_a) {
            var file = _a[0], process = _a[1], targetProcessAction = _a[2], fileAction = _a[3];
            // create appropriate entity and entity context to display, based on the action type
            var mainEntity = file && {
                item: file,
                type: File,
            };
            var entityContext = fileAction && {
                item: fileAction,
                type: FileActionDetails,
                nameKey: 'alerts.events.details.eventDetails',
            };
            switch (rawEvent.SidePaneType) {
                case 'WDAVDetection':
                case 'IRSpynetReport':
                    if (entityContext) {
                        entityContext.nameKey = 'alerts.events.details.blockDetails';
                    }
                    break;
                case 'CreateProcess':
                case 'FileSpecifiedInCommandline':
                    entityContext = process && {
                        item: process,
                        type: Process,
                        nameKey: 'alerts.events.details.executionDetails',
                    };
                    break;
                case 'ProcessInjection':
                case 'OpenProcess':
                    entityContext = targetProcessAction && {
                        item: targetProcessAction,
                        type: ProcessActionDetails,
                        nameKey: 'alerts.events.details.targetProcessDetails',
                    };
                    break;
                case 'CreateFile':
                    entityContext = fileAction && {
                        item: fileAction,
                        type: FileActionDetails,
                        nameKey: 'alerts.events.details.fileCreationDetails',
                    };
                    break;
                case 'LoadImage':
                    entityContext = fileAction && {
                        item: fileAction,
                        type: FileActionDetails,
                        nameKey: 'alerts.events.details.imageLoadDetails',
                    };
                    break;
            }
            var entityWithContext = new EntityWithContext({
                id: undefined,
                mainEntity: mainEntity,
                entityContext: entityContext,
            });
            _this.entityPanelsService.showEntity(EntityWithContext, entityWithContext);
        });
    };
    return AlertProcessTreeComponent;
}());
export { AlertProcessTreeComponent };
