import { Component, OnInit } from '@angular/core';
import { WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { EmailNotificationFormData } from './email-notification-model';

@Component({
	templateUrl: './email-notification-completed.component.html',
})
export class EmailNotificationCompletedComponent<T extends EmailNotificationFormData> extends WizardBaseStep<T> implements OnInit {
	title: string;

	constructor(private i18nService: I18nService) {
		super();
	}

	ngOnInit(){
		this.title = this.data.isUpdateFlow
			? this.i18nService.strings.email_notification_wizard_ruleUpdated_title
			: this.i18nService.strings.email_notification_wizard_ruleCreated_title
	}
}
