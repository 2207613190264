<wcd-dropdown
	[buttonText]="buttonText || placeholder"
	[position]="openMenuPosition"
	[buttonIcon]="buttonIcon"
	[buttonClass]="labelClass"
	[wcdTooltip]="buttonTooltip"
	[closeOnClick]="false"
	[showIconDropdown]="showIconDropdown"
	[isBordered]="isBordered"
	[isFullWidth]="isFullWidth"
	[disabled]="disableDropdown"
	[ariaLabel]="ariaLabel"
	[optionAriaRole]="optionAriaRole"
	[ariaRole]="ariaRole"
	[ariaHaspopup]="ariaHaspopup"
	(onToggle)="setVisible($event)"
	[allowFocusOnMenu]="null"
	>
	<div cdkTrapFocus (keydown.esc)="closeDropdown()">
		<div [hidden]="isDisabled || values.length <= 1" class="wcd-checklist-dropdown--actions">
			<button (click)="selectAllOrNone()"
					(keydown.enter)="selectAllOrNone()"
					[disabled]="allValuesAreDisabled"
					type="button"
					[class.disabled]="allValuesAreDisabled"
					class="dropdown-action-btn"
					[focusOn]="visible"
					#selectAllOrNoneButton
					>
				{{'checklist_dropdown_button_check_un_check' | i18n}}
			</button>
			<button *ngIf="secondButton"
					type="button"
					(click)="secondButtonClick.emit()"
					(keydown.enter)="secondButtonClick.emit()"
					class="dropdown-action-btn"
					[disabled]="disableSecondButton">
				{{secondButton}}
			</button>
		</div>
		<div class="wcd-checklist-dropdown--contents wcd-scroll-vertical" style="max-height: 400px">
			<wcd-checklist
				[values]="values"
				[disableEmptySelection]="disableEmptySelection"
				[name]="name"
				[ngModel]="value"
				[selectById]="selectById"
				(ngModelChange)="onChecklistChange($event)"
				[isDisabled]="isDisabled"
				[wrapLabel]="wrapItemsLabel"
				[navigateUsingArrowKeysOnly]="navigateUsingArrowKeysOnly"></wcd-checklist>
		</div>
	</div>
</wcd-dropdown>
