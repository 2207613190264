var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';
var DeviceUserAccountControlFlags = /** @class */ (function (_super) {
    __extends(DeviceUserAccountControlFlags, _super);
    function DeviceUserAccountControlFlags() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Script' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "script", void 0);
    __decorate([
        EntityField({ data: 'AccountDisabled' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "accountDisabled", void 0);
    __decorate([
        EntityField({ data: 'HomedirRequired' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "homedirRequired", void 0);
    __decorate([
        EntityField({ data: 'AccountLocked' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "accountLocked", void 0);
    __decorate([
        EntityField({ data: 'PasswordNotRequired' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "passwordNotRequired", void 0);
    __decorate([
        EntityField({ data: 'CannotChangePassword' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "cannotChangePassword", void 0);
    __decorate([
        EntityField({ data: 'EncryptedTextPasswordAllowed' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "encryptedTextPasswordAllowed", void 0);
    __decorate([
        EntityField({ data: 'TemporaryDuplicateAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "temporaryDuplicateAccount", void 0);
    __decorate([
        EntityField({ data: 'NormalAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "normalAccount", void 0);
    __decorate([
        EntityField({ data: 'InterdomainTrustAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "interdomainTrustAccount", void 0);
    __decorate([
        EntityField({ data: 'WorkstationTrustAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "workstationTrustAccount", void 0);
    __decorate([
        EntityField({ data: 'ServerTrustAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "serverTrustAccount", void 0);
    __decorate([
        EntityField({ data: 'PasswordNeverExpires' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "passwordNeverExpires", void 0);
    __decorate([
        EntityField({ data: 'MnsLogonAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "mnsLogonAccount", void 0);
    __decorate([
        EntityField({ data: 'SmartcardRequired' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "smartcardRequired", void 0);
    __decorate([
        EntityField({ data: 'TrustedForDelegation' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "trustedForDelegation", void 0);
    __decorate([
        EntityField({ data: 'NotDelegated' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "notDelegated", void 0);
    __decorate([
        EntityField({ data: 'UseDesKeyOnly' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "useDesKeyOnly", void 0);
    __decorate([
        EntityField({ data: 'DontRequirePreauthentication' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "dontRequirePreauthentication", void 0);
    __decorate([
        EntityField({ data: 'PasswordExpired' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "passwordExpired", void 0);
    __decorate([
        EntityField({ data: 'TrustedToAuthenticationForDelegation' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "trustedToAuthenticationForDelegation", void 0);
    __decorate([
        EntityField({ data: 'PartialSecretsAccount' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "partialSecretsAccount", void 0);
    __decorate([
        EntityField({ data: 'UseAesKeys' }),
        __metadata("design:type", Boolean)
    ], DeviceUserAccountControlFlags.prototype, "useAesKeys", void 0);
    DeviceUserAccountControlFlags = __decorate([
        ValueObject({
            singularName: 'User account control flags',
            pluralName: 'User account control flags',
        })
    ], DeviceUserAccountControlFlags);
    return DeviceUserAccountControlFlags;
}(ModelBase));
export { DeviceUserAccountControlFlags };
