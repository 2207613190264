import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature } from '@wcd/config';
import { Lazy } from '@wcd/utils';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { sccHostService } from '@wcd/scc-interface';
import { ScheduledHuntingEntityTypeService } from './scheduled-hunting.entity-type.service';
import { ScheduledHuntingLastRunStatusComponent } from '../components/scheduled-hunting-last-run-status.component';
import { ScheduledHuntingRunTimeComponent } from '../components/scheduled-hunting-run-time.component';
var ScheduledHuntingFieldsService = /** @class */ (function () {
    function ScheduledHuntingFieldsService(i18nService, featuresService, entityTypeService) {
        var _this = this;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.entityTypeService = entityTypeService;
        this.machineGroupsFieldId = 'MachineGroups';
        this._allFields = new Lazy(function () {
            var scheduledHuntingFields = [
                {
                    id: 'Name',
                    name: _this.i18nService.get('scheduledHunting.fields.name.title'),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (huntingRule) {
                            return {
                                entity: huntingRule,
                                entityTypeId: _this.entityTypeService.entityType.id,
                                entityName: huntingRule.name,
                                hideIcon: sccHostService.isSCC,
                            };
                        },
                    },
                    className: EntityNameComponent.entityNameDefaultCssClass,
                },
                {
                    id: 'Title',
                    name: _this.i18nService.get('scheduledHunting.fields.monitorName.title'),
                    getDisplay: function (rule) { return rule.alertTitle; },
                },
                {
                    id: 'Severity',
                    name: _this.i18nService.get('hunting.scheduledMonitorSidePane.fields.alertSeverity.title'),
                    enabledByDefault: true,
                    getDisplay: function (rule) {
                        return _this.i18nService.get(rule.alertSeverity.nameI18nKey);
                    },
                    getCssClass: function (rule) {
                        return "wcd-severity wcd-severity-" + rule.alertSeverity.type;
                    },
                    sort: {
                        sortDescendingByDefault: true,
                    },
                },
                {
                    id: 'CreationTime',
                    name: _this.i18nService.get('scheduledHunting.fields.createdOn.title'),
                    component: {
                        type: TzDateComponent,
                        getProps: function (huntingRule) { return ({ date: huntingRule.createdOn }); },
                    },
                    className: 'nowrap',
                    sort: {
                        sortDescendingByDefault: true,
                    },
                },
                {
                    id: 'CreatedBy',
                    name: _this.i18nService.get('scheduledHunting.fields.createdBy.title'),
                    getDisplay: function (rule) { return rule.createdBy; },
                },
                {
                    id: 'Actions',
                    name: _this.i18nService.get('scheduledHunting.fields.actions.title'),
                    getDisplay: function (rule) {
                        if (rule.actions && rule.actions.length) {
                            return rule.actions.length === 1
                                ? _this.i18nService.get("hunting.customDetections.actions." + rule.actions[0].actionType)
                                : _this.i18nService.get('scheduledHunting.fields.actions.multipleActions', {
                                    count: rule.actions.length,
                                });
                        }
                    },
                    getTooltip: function (rule) {
                        if (rule.actions && rule.actions.length > 1) {
                            return rule.actions
                                .map(function (action) {
                                return _this.i18nService.get("hunting.customDetections.actions." + action.actionType);
                            })
                                .join(', ');
                        }
                    },
                    sort: { enabled: false },
                },
                {
                    id: 'LastRunTime',
                    name: _this.i18nService.get('scheduledHunting.fields.lastRunTime.title'),
                    component: {
                        type: ScheduledHuntingRunTimeComponent,
                        getProps: function (rule) { return ({ rule: rule, runTime: rule.lastRunTime }); },
                    },
                    className: 'nowrap',
                },
                {
                    id: 'LastRunStatus',
                    name: _this.i18nService.get('scheduledHunting.fields.lastRunStatus.title'),
                    component: {
                        type: ScheduledHuntingLastRunStatusComponent,
                        getProps: function (huntingRule) { return ({ rule: huntingRule }); },
                    },
                    sort: { enabled: false },
                }
            ].concat((_this.featuresService.isEnabled('HuntingRuleFrequency')
                ? [
                    {
                        id: 'HuntingRuleFrequency',
                        name: _this.i18nService.get('scheduledHunting.fields.huntingRuleFrequency.title'),
                        getDisplay: function (rule) {
                            if (rule.intervalHours) {
                                return (_this.i18nService.get("hunting.scheduledMonitorSidePane.fields.huntingRuleFrequency.everyFrequencyHours." + (+rule.intervalHours > 1 ? 'plural' : 'singular'), { freq: rule.intervalHours }));
                            }
                            if (rule.intervalHours === 0) {
                                return _this.i18nService.get('hunting_scheduledMonitorSidePane_fields_huntingRuleFrequency_continuous');
                            }
                            return '';
                        },
                        className: 'nowrap',
                    },
                ]
                : []), [
                {
                    id: 'NextRunTime',
                    name: _this.i18nService.get('scheduledHunting.fields.nextRunTime.title'),
                    component: {
                        type: ScheduledHuntingRunTimeComponent,
                        getProps: function (rule) { return ({ rule: rule, runTime: rule.nextRunTime }); },
                    },
                    className: 'nowrap',
                },
                {
                    id: 'LastUpdatedTime',
                    name: _this.i18nService.get('scheduledHunting.fields.lastUpdatedOn.title'),
                    component: {
                        type: TzDateComponent,
                        getProps: function (huntingRule) { return ({ date: huntingRule.lastUpdatedOn }); },
                    },
                    className: 'nowrap',
                    sort: {
                        sortDescendingByDefault: true,
                    },
                },
                {
                    id: 'LastUpdatedBy',
                    name: _this.i18nService.get('scheduledHunting.fields.lastUpdatedBy.title'),
                    getDisplay: function (rule) { return rule.lastUpdatedBy; },
                    sort: { enabled: false },
                },
                {
                    id: _this.machineGroupsFieldId,
                    name: _this.i18nService.get('scheduledHunting.fields.machineGroups.title'),
                    getDisplay: function (rule) { return _this.getMachineGroupsLabel(rule); },
                    sort: { enabled: false },
                    getTooltip: function (rule) { return _this.getMachineGroupsTooltip(rule); },
                },
                {
                    id: 'IsEnabled',
                    name: _this.i18nService.get('scheduledHunting.fields.isEnabled.title'),
                    getDisplay: function (rule) { return (rule.isEnabled ? 'On' : 'Off'); },
                },
                {
                    id: 'ServiceSource',
                    name: _this.i18nService.get('scheduledHunting.fields.serviceSources.title'),
                    enabledByDefault: true,
                    getDisplay: function (rule) {
                        return (rule.serviceSources &&
                            rule.serviceSources
                                .filter(Boolean)
                                .map(function (source) {
                                return _this.i18nService.strings["shorthand_" + source.nameI18nKey] ||
                                    _this.i18nService.get(source.nameI18nKey);
                            })
                                .join(', '));
                    },
                    description: _this.i18nService.get('scheduledHunting.fields.serviceSources.toolTip'),
                    sort: { enabled: false },
                },
            ]);
            return DataviewField.fromList(scheduledHuntingFields);
        });
    }
    Object.defineProperty(ScheduledHuntingFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
                var scheduledHuntingFields = this._allFields.value.filter(function (field) { return field.id !== _this.machineGroupsFieldId; });
                return scheduledHuntingFields;
            }
            return this._allFields.value;
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingFieldsService.prototype.getMachineGroupsLabel = function (rule) {
        var groups = rule.machineGroups || [];
        var params = groups.length === 0
            ? {}
            : {
                numGroups: groups.length,
                plural: groups.length > 1 ? 's' : '',
            };
        return groups.length !== 0 ?
            (groups.length > 1 ?
                this.i18nService.get('scheduledHunting.fields.machineGroups.specificGroups', params) :
                this.i18nService.get('scheduledHunting.fields.machineGroups.specificGroups.single')) :
            this.i18nService.get('scheduledHunting.fields.machineGroups.all');
    };
    ScheduledHuntingFieldsService.prototype.getMachineGroupsTooltip = function (rule) {
        return (rule.machineGroups || [])
            .filter(Boolean)
            .map(function (group) { return group.name; })
            .join(', ');
    };
    return ScheduledHuntingFieldsService;
}());
export { ScheduledHuntingFieldsService };
