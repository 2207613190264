import { FabCommandBarModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { DateTimePickerCommandBarModule } from '@wcd/date-time-picker';
import { RbacModule } from '../../../rbac/rbac.module';
import { WcdIconsModule } from '@wcd/icons';
import { HexaditeFormsModule } from '../../../forms/forms.module';
import { CommandBarCustomIconComponent } from './command-bar-custom-icon.component';
import { CommandBarItemService } from './command-bar-item.service';
import { CommandBarComponent } from './command-bar.component';
import { I18nModule } from '@wcd/i18n';

@NgModule({
	imports: [
		RouterModule,
		CommonModule,
		FormsModule,
		WcdIconsModule,
		HexaditeFormsModule,
		RbacModule,
		FabCommandBarModule,
		DateTimePickerCommandBarModule,
		I18nModule,
	],
	providers: [CommandBarItemService],
	declarations: [CommandBarComponent, CommandBarCustomIconComponent],
	exports: [DateTimePickerCommandBarModule, CommandBarComponent],
	entryComponents: [CommandBarCustomIconComponent],
})
export class CommandBarModule {}
