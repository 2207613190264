var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, OnDestroy } from '@angular/core';
import { Machine } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { I18nService } from '@wcd/i18n';
import { EntityPanelsService } from '../../services/entity-panels.service';
import { ActivatedRoute, Router } from '@angular/router';
var LiveResponseSessionEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(LiveResponseSessionEntityDetailsComponent, _super);
    function LiveResponseSessionEntityDetailsComponent(injector, i18nService, entityPanelsService, route, router) {
        var _this = _super.call(this, injector) || this;
        _this.i18nService = i18nService;
        _this.entityPanelsService = entityPanelsService;
        _this.route = route;
        _this.router = router;
        _this.entityUpdateListener = function (e) {
            if (e.detail.newEntityId !== _this.entity.id) {
                var entityTypePath = _this.route.parent.routeConfig.path;
                _this.router.navigate(['/' + entityTypePath, e.detail.newEntityId, e.detail.entityData ? e.detail.entityData : {}], {
                    queryParamsHandling: "merge",
                });
            }
        };
        return _this;
    }
    LiveResponseSessionEntityDetailsComponent.prototype.ngOnInit = function () {
        _super.prototype.ngOnInit.call(this);
        this.setupEntityUpdateListener();
    };
    Object.defineProperty(LiveResponseSessionEntityDetailsComponent.prototype, "session", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    LiveResponseSessionEntityDetailsComponent.prototype.openMachinePanel = function () {
        this.entityPanelsService.showEntity(Machine, this.entity.machine);
    };
    LiveResponseSessionEntityDetailsComponent.prototype.clearEntityUpdateListener = function () {
        document.removeEventListener("liveResponseSessionEntityUpdated", this.entityUpdateListener);
    };
    LiveResponseSessionEntityDetailsComponent.prototype.setupEntityUpdateListener = function () {
        this.clearEntityUpdateListener();
        document.addEventListener("liveResponseSessionEntityUpdated", this.entityUpdateListener);
    };
    LiveResponseSessionEntityDetailsComponent.prototype.ngOnDestroy = function () {
        this.clearEntityUpdateListener();
    };
    return LiveResponseSessionEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { LiveResponseSessionEntityDetailsComponent };
