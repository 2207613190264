import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DeviceResource } from '@wcd/domain';
import { DataTableField } from '@wcd/datatable';
import { I18nService } from '@wcd/i18n';
import { TzDateService } from '@wcd/localization';

interface FormattedDeviceResource {
	name: string;
	domainName: string;
	formattedLastSeen: string;
}

@Component({
	selector: 'machine-itp-resources',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<header class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal">
			<div class="wcd-flex-1">
				<h3 class="side-panel-title" id="machine-resources-panel-header" #panelHeader>
					{{ 'machines.entityDetails.fields.resources.title' | i18n }}
				</h3>
			</div>
		</header>
		<div class="wcd-padding-medium-left">
			<wcd-datatable
				[items]="formattedResources"
				[columns]="tableFields"
				[selectEnabled]="false"
				[fieldWidths]="{
					name: resourcesTableFieldsWidth,
					formattedLastSeen: resourcesTableFieldsWidth,
					domainName: resourcesTableFieldsWidth
				}"
			>
			</wcd-datatable>
		</div>
	`,
})
export class MachineResourcesComponent {
	@Input() resources: Array<DeviceResource>;

	tableFields: Array<DataTableField>;
	formattedResources: ReadonlyArray<FormattedDeviceResource>;
	resourcesTableFieldsWidth: number = 167;

	constructor(private i18nService: I18nService, private tzDateService: TzDateService) {}

	ngOnInit() {
		this.tableFields = DataTableField.fromList<ReadonlyArray<FormattedDeviceResource>>([
			{
				id: 'name',
				name: this.i18nService.get('machines.entityDetails.fields.resources.resourceName'),
			},
			{
				id: 'formattedLastSeen',
				name: this.i18nService.get('machines.entityDetails.fields.lastSeen.title'),
			},
			{
				id: 'domainName',
				name: this.i18nService.get('machines.entityDetails.fields.domain.title'),
			},
		]);

		this.formattedResources = this.resources.map((resource: DeviceResource) => {
			return {
				name: resource.name,
				domainName: resource.domainName,
				formattedLastSeen: this.tzDateService.format(resource.lastSeen, 'medium'),
			};
		});
	}
}
