import {
	Tag,
	EolState,
	SoftwareInstallation,
	VA_RECOMMENDATION_FILTER_TAG,
	VERSION_DISTRIBUTION_FILTER_TAG,
	VulnerabilityType,
	Vulnerability,
} from '@wcd/domain';

import { Feature, IFeaturesService } from '@wcd/scc-common';

export class TvmTagsServiceCommon {
	private zeroDayEnabled: boolean;

	private _scaSupportedFilterTags: string[] = [];

	constructor(featuresService: IFeaturesService) {
		this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
	}

	getSoftwareInstallationVersionTags(
		installation: SoftwareInstallation,
		eolVersionLocalizedString: string,
		hasUpcomingEolVersionsLocalizedString: string
	): Array<Tag> {
		const tags = new Array<Tag>();
		if (this.verifyAlreadyEolVersionTag(installation.eolVersionState)) {
			tags.push({
				id: 'endOfLifeVersionTag',
				name: eolVersionLocalizedString,
			});
		}
		if (this.verifyUpcomingEolVersionTag(installation.eolVersionState)) {
			tags.push({
				id: 'hasUpcomingEolVersionsTag',
				name: hasUpcomingEolVersionsLocalizedString,
			});
		}
		return tags;
	}

	getVulnerabilityTags(vulnerability: Vulnerability, zeroDayLocalizedString: string): Array<Tag> {
		const tags = new Array<Tag>();

		if (
			this.verifyZeroDayTag(vulnerability.mostSevereVulnerabilityType, vulnerability.patchReleaseDate)
		) {
			tags.push({
				id: 'zeroDayTag',
				name: zeroDayLocalizedString,
				className: 'color-box-attention',
			});
		}

		return tags;
	}

	getSoftwareVersionTagsForFiltering(): string[] {
		return Object.keys(VERSION_DISTRIBUTION_FILTER_TAG);
	}

	getVaRecommendationsTagsForFiltering(): string[] {
		return Object.keys(VA_RECOMMENDATION_FILTER_TAG).filter((tag) => {
			switch (VA_RECOMMENDATION_FILTER_TAG[tag]) {
				case VA_RECOMMENDATION_FILTER_TAG.zeroDay:
					return this.zeroDayEnabled;
				default:
					return true;
			}
		});
	}

	get scaRecommendationsTagsForFiltering(): string[] {
		return this._scaSupportedFilterTags;
	}

	private verifyUpcomingEolVersionTag(eolVersionState): boolean {
		return eolVersionState == EolState.UpcomingEOL;
	}

	private verifyAlreadyEolVersionTag(eolVersionState): boolean {
		return eolVersionState == EolState.AlreadyEOL;
	}

	private verifyZeroDayTag(vulnerabilityType: VulnerabilityType, patchReleaseDate: Date): boolean {
		return this.zeroDayEnabled && vulnerabilityType === VulnerabilityType.ZeroDay && !patchReleaseDate;
	}
}
