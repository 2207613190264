import { Injectable } from '@angular/core';
import { Investigation, InvestigationStatus, Machine, OfficeUtils } from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Prettify } from '../../../utils/services/prettify.service';
import { InvestigationsService } from './investigations.service';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService } from '@wcd/config';
import {
	FilterValuesChecklistComponent,
	FilterValuesDatePickerComponent,
	ListFilterValueConfig,
} from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { InvestigationEntityTypeService } from './investigation.entity-type.service';
import { compact } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class SevilleInvestigationFields {
	private _fields: Array<DataviewField>;

	constructor(
		private appConfigService: AppConfigService,
		private globalEntityTypesService: GlobalEntityTypesService,
		private iconsService: IconsService,
		private investigationEntityTypeService: InvestigationEntityTypeService,
		private appContextService: AppContextService,
		private i18nService: I18nService
	) {}

	get fields(): Array<DataviewField> {
		if (!this._fields) {
			this._fields = DataviewField.fromList(
				compact(<Array<DataviewFieldConfig>>[
					{
						id: 'originatingAlertTitles',
						name: this.i18nService.strings.investigations_fields_triggeringAlert,
						enabledByDefault: true,
						sort: { enabled: false },
						className: EntityNameComponent.entityNameDefaultCssClass,
						component: {
							type: EntityNameComponent,
							getProps: (investigationInput: Investigation) => {
								return {
									entity: investigationInput,
									entityTypeId: this.investigationEntityTypeService.entityType.id,
								};
							},
						},
					},
					{
						id: 'Id',
						name: this.i18nService.strings.investigations_fields_investigationId,
						className: 'small-cell',
						enabledByDefault: !this.appConfigService.isDemoTenant,
						getDisplay: (investigation: Investigation) =>
							OfficeUtils.getShortId(investigation.id),
					},
					{
						id: 'status',
						name: this.i18nService.strings.investigations_fields_investigationStatus,
						enabledByDefault: true,
						className: 'auto-size nowrap',
						getDisplay: (investigation: Investigation) => {
							return investigation.status.name;
						},
						icon: {
							wcdIcon: (investigation: Investigation) =>
								investigation.status.iconName &&
								WcdIconNames[this.iconsService.getIcon(investigation.status.iconName).name],
							className: (investigation: Investigation) =>
								`color-text-${investigation.status.className}`,
						},
						getImage: (investigation: Investigation) =>
							investigation.status && investigation.status.image,
						sort: { enabled: false },
						filter: {
							priority: 0,
							component: {
								type: FilterValuesChecklistComponent,
								config: {
									mapFilterValue: (statusId: string): ListFilterValueConfig<string> => {
										const status: InvestigationStatus = InvestigationsService.getInvestigationStatusBySevilleStatus(
											statusId
										);
										if (status) {
											const statusIcon = this.iconsService.getIcon(status.iconName);

											return {
												icon: statusIcon
													? {
															wcdIconName: statusIcon.name,
															className: `color-text-${status.className}`,
													  }
													: null,
												image: status.image,
												name: status.name,
												id: statusId,
											};
										}

										return null;
									},
								},
							},
						},
						custom: {
							allowFilterValueTracking: true,
						},
					},
					this.appContextService.isMtp
						? {
								id: 'serviceSource',
								name: this.i18nService.strings.investigations_fields_serviceSource,
								enabledByDefault: true,
								className: 'nowrap',
								getDisplay: (investigation: Investigation) =>
									investigation.serviceSource
										? this.i18nService.get(investigation.serviceSource.nameI18nKey)
										: null,
								custom: {
									allowFilterValueTracking: true,
								},
								sort: {
									enabled: false,
								},
						  }
						: null,
					{
						id: 'detectionSource',
						name: this.i18nService.strings.investigations_fields_detectionSource,
						enabledByDefault: !this.appContextService.isMtp,
						className: 'nowrap',
						getDisplay: (investigation: Investigation) => investigation.detectionSourceName,
						custom: {
							allowFilterValueTracking: true,
						},
						sort: {
							enabled: false,
						},
					},
					{
						id: 'machineNames',
						name: this.i18nService.strings.investigations_fields_entities,
						enabledByDefault: true,
						className: 'nowrap wcd-text-overflow-medium',
						getDisplay: (investigation: Investigation) =>
							investigation.machines.length
								? investigation.machines.length === 1
									? investigation.machines[0].name.toLowerCase()
									: this.i18nService.get('investigations_fields_deviceCount', {
											deviceCount: investigation.machines.length,
									  })
								: null,
						icon: {
							fabricIcon: (investigation: Investigation) =>
								investigation.machines.length
									? this.globalEntityTypesService.getEntityTypeIcon(Machine)
									: null,
						},
						getLink: (investigation: Investigation) => {
							if (investigation.machines.length === 1) {
								const id = investigation.machines[0].machineId;
								this.globalEntityTypesService.getEntityLink(Machine, <Partial<Machine>>{
									name: id,
									senseMachineId: id,
								});
							}
							return null;
						},
						filter: {
							component: {
								type: FilterValuesChecklistComponent,
								config: {
									mapFilterValue: (machineName) => {
										return {
											id: machineName,
											name: machineName,
											type: 'host',
										};
									},
								},
							},
						},
					},
					{
						id: 'StartTime',
						name: this.i18nService.strings.investigations_fields_startDate,
						enabledByDefault: true,
						component: {
							type: TzDateComponent,
							getProps: (investigation: Investigation) => ({ date: investigation.startDate }),
						},
						className: 'nowrap',
						sort: {
							sortDescendingByDefault: true,
						},
						filter: {
							component: {
								type: FilterValuesDatePickerComponent,
							},
						},
					},
					{
						id: 'RunningTime',
						name: this.i18nService.strings.investigations_fields_duration,
						enabledByDefault: true,
						getDisplay: (investigation: Investigation) =>
							Prettify.prettyTime(investigation.duration),
						className: 'auto-size',
					},
					{
						id: 'error',
						name: this.i18nService.strings.investigations_fields_errorDescription,
						enabledByDefault: false,
						className: 'color-text-error',
						getDisplay: (investigation: Investigation) => investigation.errorDescription,
						sort: { enabled: false },
					},
					{
						id: 'reasons',
						name: this.i18nService.strings.investigations_fields_statusDetails,
						enabledByDefault: false,
						className: 'full-width-cell',
						listValues: {
							path: 'reasons',
							withBullets: true,
						},
						sort: { enabled: false },
					},
					{
						id: 'canceledBy',
						name: this.i18nService.strings.investigations_fields_canceledBy,
						enabledByDefault: false,
						getDisplay: (investigation: Investigation) =>
							investigation.canceledByUser ? investigation.canceledByUser.name : null,
						sort: { enabled: false },
					},
					{
						id: 'lastAction',
						name: this.i18nService.strings.investigations_fields_lastAction,
						enabledByDefault: false,
						className: 'full-width-cell',
						getDisplay: (investigation: Investigation) =>
							investigation.lastAction ? investigation.lastAction.details : null,
						sort: { enabled: false },
					},
					{
						id: 'RemediationActionCount',
						name: this.i18nService.strings.investigations_fields_remediationActions,
						enabledByDefault: false,
						getDisplay: (investigation: Investigation) =>
							investigation.actionsSummary.remediated || '',
						className: 'no-wrap auto-size',
						getTooltip: (investigation: Investigation) =>
							this.i18nService.get('investigations_fields_remediationActionsTooltip', {
								remediationActionCount: investigation.actionsSummary.remediated,
								totalActionCount: investigation.actionsSummary.total,
							}),
						getCssClass: (investigation: Investigation) =>
							investigation.actionsSummary.remediated ? 'bold color-text-success' : null,
						sort: {
							sortDescendingByDefault: true,
						},
					},
				])
			);
		}

		return this._fields;
	}
}
