import { ApiCall, ApiCallModel, DataQuerySortDirection, DataSet } from '@microsoft/paris';
import {
	convertOSPlatformParameter,
	Alert,
	WcdPortalParisConfig,
	InvestigationStatusToFilterQueryString,
	severityValues,
} from '@wcd/domain';
import { flatMap, omit } from 'lodash-es';

export interface AlertsFilterOptions {
	page_size?: number;
	page?: number;
	pageIndex?: number;
	ordering?: string;
	sortBy?: { field: string, direction: DataQuerySortDirection }[],
	filters?: string;
	[index: string]: any;
	tag?: string[] | string;
	investigationStates?: string | number[],
	where?: Object;
	groupType?: string;
}

const parseProp = (obj: AlertsFilterOptions, prop: string) => obj[prop] ? obj[prop] = JSON.parse(obj[prop]) : null;

const convertTag = (options: AlertsFilterOptions) => {
	const tagValue = options.tag;
	if (tagValue && tagValue instanceof Array) {
		const newTag = tagValue[0].split(',');
		return { ...options, ...{ tag: newTag } };
	}
	return options;
}

const convertInvestigationStatusParameter = (options: AlertsFilterOptions): AlertsFilterOptions => {
	const investigationStatesValue = options.investigationStates;
	if (investigationStatesValue &&  typeof investigationStatesValue === 'string' ) {
		const values = investigationStatesValue.split(',');
		const parsedValues = values.map((val) => parseInt(InvestigationStatusToFilterQueryString[val], 10));
		const transformedValues: InvestigationStatusToFilterQueryString[] = flatMap(parsedValues, (val) =>
			val === InvestigationStatusToFilterQueryString.Pending
				? [
						InvestigationStatusToFilterQueryString.PendingApproval,
						InvestigationStatusToFilterQueryString.PendingResource,
				  ]
				: [val]
		);
		return { ...options, ...{ investigationStates: transformedValues } };
	}
	return options;
}

const convertToNumbers = (options: AlertsFilterOptions, prop: string) => {
	const value = options[prop];
	if (value && value instanceof Array) {
		const array = value.map(x => parseInt(x));
		return { ...options, ...{ [prop]: array } };
	}
	return options;
}

const convertSeverityToNumbers = (options: AlertsFilterOptions, prop: string) => {
	const value = options[prop];
	if (value && value instanceof Array) {
		const array = value.map(x => {
			const severityEnum = severityValues.find((severity) => severity.name == x && severity.isSelectable);
			return severityEnum && severityEnum.id || parseInt(x);
		});
		return { ...options, ...{ [prop]: array } };
	}
	return options;
}

const convertPagingOrdering = (options: AlertsFilterOptions) => {
	options.pageIndex = options.page;
	if (!options.sortBy) {
		return options;
	}
	const [sort] = options.sortBy;
	const sortOrder = sort.direction === DataQuerySortDirection.descending ? 'Descending' : 'Ascending';
	const sortByField = sort.field;
	const result = omit({ ...options, sortOrder, sortByField }, ['ordering', 'sortBy']);
	return result;
}

// This function and all related functions, handle type conversions to be aligned with the HTTP post API call.
const convertData = (dataOptions: AlertsFilterOptions) => {
	const { where } = dataOptions;
	let options: AlertsFilterOptions = { ...dataOptions, ...where };
	convertOSPlatformParameter(options);
	parseProp(options, 'serviceSources');
	options = convertTag(options);
	options = convertToNumbers(options, 'rbacGroup');
	options = convertSeverityToNumbers(options, 'severity');
	options = convertPagingOrdering(options);
	options = convertInvestigationStatusParameter(options);
	return omit(options, ['page_size', 'page', 'where']);
};

export interface AssociatedAlertsResult {
	dataset: DataSet<Alert>;
	count: number;
}

@ApiCall({
	name: 'AssociatedAlerts',
	endpoint: 'AssociatedAlerts',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (optionsData) => ({
		data: convertData(optionsData)
	}),
	parse: (data: { Items: Array<Alert>; TotalResults: number;}) => {
		return {
			dataset: data.Items,
			count: data.TotalResults,
		};
	},
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class RelatedAlertsApiCall extends ApiCallModel<AssociatedAlertsResult, AlertsFilterOptions> { }
