import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { IncidentLinkedByAlert } from './incident-linked-by-alert.value-object';
import { IncidentLinkedByCategory } from './incident-linked-by-category.model';

@ValueObject({
	singularName: 'Incident link reason',
	pluralName: 'Incident link reasons',
	readonly: true,
})
export class IncidentLinkedBy extends ModelBase {
	@EntityField({ data: 'Category', required: true })
	readonly category: IncidentLinkedByCategory;

	/**
	 * The alert that the incident is linked to.
	 *
	 * **Note that if `category` is `Automation` this won't exist (otherwise it will).**
	 */
	@EntityField({ data: 'TargetAlert', required: false })
	readonly targetAlert?: IncidentLinkedByAlert;
}
