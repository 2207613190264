import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'User preferences',
	pluralName: 'User preferences',
	endpoint: 'api/ine/userpreferencesservice/userPreference',
	parseItemQuery: () => 'api/ine/userpreferencesservice/userPreference',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userPreferences,
})
export class UserPreferences extends EntityModelBase {
	@EntityField({
		data: '__self',
		parse: fieldData => {
			if (!fieldData) return null;
			const prefMap = Object.keys(fieldData).reduce((map, current) => {
				const currentData = fieldData[current];
				let parsedData;
				try {
					parsedData = JSON.parse(currentData);
				} catch (e) {
					console.warn(`Failed to parse preferences json. key: ${current}, value: ${currentData}`);
				}
				map.set(current, parsedData || currentData);
				return map;
			}, new Map<string, any>());
			return prefMap;
		},
	})
	preferences: Map<string, any>;
}
