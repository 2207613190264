/* tslint:disable:template-click-events-have-key-events */
import { Component, Input, OnChanges } from '@angular/core';
import { InvestigatedMachine } from '@wcd/domain';

const MACHINES_SHOWN_NUM_DEFAULT = 5;

@Component({
	selector: 'investigated-machines-list',
	template: `
		<span *ngIf="countOnly; else machinesList"> {{ machines?.length }} devices </span>
		<ng-template #machinesList>
			<ul class="investigated-machines-list" *ngIf="machines">
				<li *ngFor="let machine of (machines | slice: 0:currentLimit)">
					<machine-link [machine]="machine"></machine-link>
				</li>
			</ul>
			<a
				*ngIf="isMoreButtonShown"
				data-track-id="MachinesFullListToggle"
				data-track-type="Toggle"
				(click)="onShowMoreClick()"
			>
				{{ isFullListShown ? 'View less' : 'View all' }}
			</a>
		</ng-template>
	`,
})
export class InvestigatedMachinesListComponent implements OnChanges {
	@Input() machines: Array<InvestigatedMachine>;
	@Input() limit: number = MACHINES_SHOWN_NUM_DEFAULT;
	@Input() countOnly: boolean = false;

	currentLimit: number;
	isFullListShown: boolean = false;
	isMoreButtonShown: boolean = false;

	ngOnChanges() {
		if (this.machines) {
			this.isMoreButtonShown = this.machines.length > this.limit;
			this.currentLimit = this.limit;
		}
	}

	onShowMoreClick() {
		this.isFullListShown = !this.isFullListShown;
		this.currentLimit = this.isFullListShown ? this.machines.length : this.limit;
	}
}
