import {Component, Input} from '@angular/core';
import {EvidenceAlertsSummary, Mailbox, MailboxAlertsRelationship} from '@wcd/domain';
import {Observable, of} from 'rxjs';
import {catchError, startWith} from 'rxjs/operators';
import {Paris, RelationshipRepository} from '@microsoft/paris';
import {Feature, FeaturesService} from "@wcd/config";

const loadingSymbol: unique symbol = Symbol();
type Loadable<T> = T | typeof loadingSymbol;

@Component({
	selector: 'mailbox-alerts',
	template: `
		<ng-container *ngIf="(alertData$ | async) as alertData">
			<fab-shimmer [isDataLoaded]="alertData !== loadingSymbol">
				<ng-container *ngIf="alertData?.alertsSeveritySummary?.hasAlerts">
					<div class="wcd-padding-large-bottom">
						<alerts-severity-incident-summary
							[alertsSeveritySummary]="alertData.alertsSeveritySummary"
						>
						</alerts-severity-incident-summary>
					</div>
				</ng-container>
				<alerts-dataview
					*ngIf="alertData.alerts?.length"
					[dataViewId]="'mailbox-entity-alert-dataview'"
					[dataViewConfig]="{ data: alertData.alerts, id: 'mailbox-entity-alert-dataview' }"
					[showHeader]="false"
					[allowFilters]="false"
					[allowPaging]="false"
					[allowGrouping]="false"
					[allowTimeRangeSelect]="false"
					[hideControls]="true"
					[disableSelection]="true"
					[onlyFields]="['title', 'severity']"
					[sortDisabledFields]="['title', 'severity']"
					[maxItems]="5"
					[removePadding]="false"
				></alerts-dataview>
			</fab-shimmer>
		</ng-container>
	`,
})
export class MailboxAlertsComponent {
	alertData$: Observable<Loadable<EvidenceAlertsSummary>>;
	loadingSymbol = loadingSymbol;
	private mailboxAlertRepo: RelationshipRepository<Mailbox, EvidenceAlertsSummary>;

	constructor(private paris: Paris, private featureService: FeaturesService) {
		this.mailboxAlertRepo = this.paris.getRelationshipRepository(MailboxAlertsRelationship);
	}

	private _mailbox: Mailbox;

	get mailbox(): Mailbox {
		return this._mailbox;
	}

	@Input()
	set mailbox(mailbox: Mailbox) {
		this._mailbox = mailbox;
		if (!this.mailbox) {
			this.alertData$ = null;
			return;
		}
		this.alertData$ = this.paris
			.getRelatedItem<Mailbox, EvidenceAlertsSummary>(MailboxAlertsRelationship,
				this.mailbox,
				{ where: { useMailboxAlertsVnextApi: this.featureService.isEnabled(Feature.AssociatedAlertsByEntity )} }
			)
			.pipe(
				startWith(loadingSymbol),
				catchError(err => of(null))
			);
	}
}
