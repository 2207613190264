import { Component, Host, Input, OnDestroy, OnInit, Optional, ViewChild } from '@angular/core';
import { DataSet, ModelBase, Paris, RelationshipRepository } from '@microsoft/paris';
import {
	Alert,
	DEFAULT_LOOKBACK_IN_DAYS_VALUE,
	Incident,
	IncidentAlertsRelationship,
	IncidentsFilterOptions,
	RelatedAlertsApiCall,
} from '@wcd/domain';
import { AppContextService } from '@wcd/config';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AlertsFields } from '../../alerts/services/alerts.fields';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { filter, map, mergeMap } from 'rxjs/operators';
import { DatasetBackendOptions, DataViewConfig, DataviewField } from '@wcd/dataview';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { forkJoin, Observable, of, Subscription } from 'rxjs';
import { IncidentEntityComponent } from './incident.entity.component';
import { AlertsService } from '../../alerts/services/alerts.service';
import { AlertType, alertTypes, alertTypesArray } from '../../alerts/models/alert-type.model';
import { PreferencesService, FeaturesService, Feature } from '@wcd/config';
import { FiltersService, FiltersState, SerializedFilters } from '@wcd/ng-filters';
import { ActivatedRoute } from '@angular/router';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
import { sccHostService } from '@wcd/scc-interface';
import { DataViewModel } from '../../../dataviews/models/dataview.model';

type RelatedAlertsResultType = Observable<[DataSet<Alert>, number]>;

const SUPPORTED_FILTER_FIELD_NAMES = [
	'status',
	'AssignedTo',
	'detectionSource',
	'serviceSource',
	'severity',
	'investigationStates',
	'category',
];

@Component({
	selector: 'incident-alerts',
	providers: [AlertsFields],
	template: `
		<dataview
			class="wcd-full-height"
			id="incident-alerts"
			*ngIf="this.incidentAlertsRepository?.sourceItem; else noData"
			[disabledFields]="['incident']"
			[padLeft]="false"
			commandBarNoPadding="true"
			[repository]="incidentAlertsRepository"
			[entityType]="alertEntityType"
			[allowFilters]="allowFilters"
			[visibleFields]="visibleFields"
			[fields]="fields"
			[hideControls]="hideControls"
			[navigateOnChange]="navigateOnChange"
			[allowGroupItems]="isGroupedView()"
			[isItemGroup]="isGroup"
			[getGroupItems]="boundGetContainedAlerts"
			[fixedOptions]="dataviewFixedOptions"
			[allowPaging]="allowPaging"
			[fixedTable]="fixedTable"
			[label]="'alerts.title' | i18n"
			[dataViewConfig]="dataViewConfig"
			[getFilterQueryOptions]="alertEntityType.dataViewOptions.getFilterQueryOptions"
			defaultSortFieldId="firstEventTime"
			[className]="isScc ? '' : 'wcd-entity-page-content'"
			[sortDisabledFields]="sortDisabledFields"
			[ignoreQueryParams]="ignoreQueryParams"
			(filtersChange)="onFiltersChanged($event)"
			[removePadding]="true"
			(onSortChanged)="onSortChanged($event)"
			[shouldShowPageNumbersOnPagination]="true"
			[responsiveActionBar]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
		>
			<span dataview-controls-right *ngIf="featuresService.isEnabled(Feature.NestedAlertsInIncident)">
				<fancy-select
					[(ngModel)]="alertsViewType"
					(ngModelChange)="onAlertTypeChange()"
					class="command-bar-item-dropdown"
					labelI18nKey="nameI18nKey"
					icon="icon"
					[values]="alertTypeValues"
				></fancy-select>
			</span>
		</dataview>
		<ng-template #noData>
			<div class="wcd-full-height wcd-flex-center-all">
				<div class="widget-nodata-message">{{ 'alerts.titleNoData' | i18n }}</div>
			</div>
		</ng-template>
	`,
})
export class IncidentAlertsComponent implements OnInit, OnChanges<IncidentAlertsComponent>, OnDestroy {
	readonly alertTypeValues: Array<AlertType> = alertTypesArray;
	readonly originalFields: Array<DataviewField<Alert>>;
	readonly Feature = Feature;

	@Input() incident: Incident;
	@Input() visibleFields: Array<string>;
	@Input() filteredFields: Array<string>;
	@Input() sortDisabledFields: Array<string>;
	@Input() hideControls: boolean = false;
	@Input() allowPaging: boolean = true;
	@Input() allowFilters: boolean = true;
	@Input() navigateOnChange: boolean = true;
	@Input() fixedTable: boolean;
	@Input() ignoreQueryParams: boolean;
	@Input() allowGrouping = true;
	@Input() dataViewId: string = 'incident-alerts-updated';

	@ViewChild(DataViewComponent, { static: false }) dataViewComponent: DataViewComponent;

	dataViewConfig: DataViewConfig;
	incidentAlertsRepository: RelationshipRepository<Incident, Alert>;
	alertEntityType: EntityType<Alert>;
	fields: Array<DataviewField<Alert>>;

	alertsViewType: AlertType;
	alertViewTypePreferenceId: string;
	boundGetContainedAlerts: (alert: Alert) => Observable<Array<Alert>>;
	dataviewFixedOptions: { [alertType: string]: any };
	private _currentEntitySubscription: Subscription;
	private filterWithHttpPostEnabled: boolean;
	filtersState: FiltersState;
	isScc = sccHostService.isSCC;
	groupedSorting = {
		sortField: 'firstEventTime',
		sortDescending: true,
	};
	constructor(
		private paris: Paris,
		globalEntityTypesService: GlobalEntityTypesService,
		private alertsFields: AlertsFields,
		private activatedEntity: ActivatedEntity,
		private appContextService: AppContextService,
		private alertsService: AlertsService,
		private preferencesService: PreferencesService,
		private featuresService: FeaturesService,
		@Optional()
		@Host()
		public incidentEntityComponent: IncidentEntityComponent,
		private route: ActivatedRoute,
		private performanceSccService: PerformanceSccService
	) {
		this.boundGetContainedAlerts = this.getContainedAlerts.bind(this);
		this.alertEntityType = globalEntityTypesService.getEntityType(Alert);

		this.alertsViewType = this.enableGrouping() ? alertTypes.grouped : alertTypes.flat;

		this.originalFields = (this.appContextService.isMtp ? alertsFields.mtpFields : alertsFields.allFields)
			.map((field) => {
				switch (field.id) {
					case 'investigationStates':
						return { ...field, enabledByDefault: false, sort: { enabled: true } };
					case 'title':
					case 'category':
					case 'detectionSource':
					case 'AssignedTo':
					case 'status':
					case 'classification':
					case 'determination':
					case 'impactedEntities':
						return { ...field, sort: { enabled: true } };
					case 'firstEventTime':
						return { ...field, sort: { sortDescendingByDefault: false } };
					default:
						return field;
				}
			})
			.map((field) => new DataviewField<Alert>(field));

		/*this.alertsViewType must be initialized before calling initDataTable because it uses its value inside*/
		this.alertViewTypePreferenceId = `${this.dataViewId}_alertsViewType`;
		const alertsViewPreference = this.preferencesService.getPreference(this.alertViewTypePreferenceId);
		this.alertsViewType =
			this.enableGrouping() && alertsViewPreference !== alertTypes.flat.type
				? alertTypes.grouped
				: alertTypes.flat;

		this.initDataTable();

		this.filterWithHttpPostEnabled = this.featuresService.isEnabled(Feature.DataviewFilterWithHttpPost);
		const showServiceSources = appContextService.isMtp;
		this.dataViewConfig = this.filterWithHttpPostEnabled
			? {
					getFiltersData: () =>
						this.alertsService.getAlertsFilters(SUPPORTED_FILTER_FIELD_NAMES, showServiceSources),
					filtersHiddenByDefault: true,
					loadResults: (options: DatasetBackendOptions): Observable<DataSet<Alert>> => {
						const configOptions = DataViewModel.getDataQueryFromOptions(
							this.dataViewConfig,
							options
						);
						configOptions.where['IncidentId'] =
							this.incidentAlertsRepository &&
							this.incidentAlertsRepository.sourceItem &&
							this.incidentAlertsRepository.sourceItem.id;
						configOptions['groupType'] = 'GroupHash';
						configOptions['lookBackInDays'] = 180;
						return this.getItems(configOptions as IncidentsFilterOptions).pipe(
							map(([data, count]) => ({ count, items: data.items }))
						);
					},
			  }
			: {
					getFiltersData: () =>
						this.alertsService.getAlertsFilters(SUPPORTED_FILTER_FIELD_NAMES, showServiceSources),
					filtersHiddenByDefault: true,
			  };
	}

	onSortChanged(event) {
		this.groupedSorting = {
			sortField: event.sortField.id,
			sortDescending: event.sortDescending,
		};
	}

	private onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('alerts');
	}

	ngOnInit() {
		if (this.incident) {
			this.setAlertsRepository(this.incident);
		} else {
			this._currentEntitySubscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof Incident))
				.subscribe((incident: Incident) => {
					this.setAlertsRepository(incident);
				});
		}
	}

	ngOnChanges(changes: TypedChanges<IncidentAlertsComponent>) {
		if (changes.filteredFields) {
			this.fields = this.alertsFields.fields.filter((field) =>
				changes.filteredFields.currentValue.includes(field.id)
			);
			if (this.sortDisabledFields && this.sortDisabledFields.length) {
				this.fields.map((field) =>
					this.sortDisabledFields.includes(field.id)
						? field.clone({ sort: { enabled: false } })
						: field
				);
			}
		}
		if (changes.incident && this.incidentAlertsRepository) {
			this.incidentAlertsRepository.sourceItem = changes.incident.currentValue;
			if (
				!changes.incident.firstChange &&
				changes.incident.currentValue.id !== changes.incident.previousValue.id
			) {
				this.dataViewComponent.refreshData();
			}
		}
		if (changes.allowGrouping != null) {
			this.initDataTable();
		}
	}

	ngOnDestroy() {
		this._currentEntitySubscription && this._currentEntitySubscription.unsubscribe();
	}

	isGroup(alert: Alert): boolean {
		return alert.isGroup;
	}

	onAlertTypeChange() {
		this.preferencesService.setPreference(this.alertViewTypePreferenceId, this.alertsViewType.type);
		this.initDataTable();
	}

	private initDataTable() {
		this.setFixedOptions();
		this.setFields();
	}

	private setFixedOptions() {
		this.dataviewFixedOptions = {
			alertType: this.isGroupedView() ? alertTypes.grouped.id : alertTypes.flat.id,
			includeExtendedAlertData: true,
		};
	}

	private enableGrouping(): boolean {
		return this.featuresService.isEnabled(Feature.NestedAlertsInIncident) && this.allowGrouping;
	}

	private isGroupedView(): boolean {
		return this.enableGrouping() && this.alertsViewType.isGrouped;
	}

	private setFields() {
		if (!this.filteredFields) {
			this.fields = this.originalFields.filter(
				(field: DataviewField<Alert>) => this.isGroupedView() || field.id !== 'expand'
			);
		}
	}

	private getContainedAlerts(alert: Alert): Observable<Array<Alert>> {
		const queryFilters = this.getQuerySerializedFilters();
		return this.alertsService.getContainedAlerts(
			alert,
			{
				sortByField: this.groupedSorting.sortField,
				sortOrder: this.groupedSorting.sortDescending,
				incidentId: alert.incidentId,
				lookBackInDays: DEFAULT_LOOKBACK_IN_DAYS_VALUE,
				includeExtendedAlertData: true,
				...queryFilters,
				...(this.filtersState ? this.filtersState.selection : {}),
			},
			true
		);
	}

	private setAlertsRepository(incident: Incident) {
		if (incident.alertCount) {
			this.incidentAlertsRepository = this.paris.getRelationshipRepository(IncidentAlertsRelationship);
			this.incidentAlertsRepository.sourceItem = incident;
		}
	}

	onFiltersChanged(filtersState: FiltersState) {
		this.filtersState = filtersState;
	}

	private getQuerySerializedFilters(): SerializedFilters {
		return FiltersService.filtersQueryParamToSerializedFilters(
			this.route.snapshot.queryParams['filters']
		);
	}

	private getItems(options: IncidentsFilterOptions): RelatedAlertsResultType {
		const entities$ = this.paris.apiCall(RelatedAlertsApiCall, options);
		return entities$.pipe(
			mergeMap((rawDataSet) =>
				forkJoin([
					this.paris.modeler.rawDataToDataSet(rawDataSet.dataset, Alert, null),
					of(rawDataSet.count),
				])
			)
		);
	}
}
