import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { Ip } from '../ip/ip.entity';
import { Url } from '../url/url.entity';

@ValueObject({
	singularName: 'Network endpoint',
	pluralName: 'Network endpoints',
	readonly: true,
})
export class NetworkEndpoint extends EntityModelBase<string> {
	@EntityField({
		data: '__self',
		parse: rawData =>
			rawData['Url'] || rawData['Hostname'] || rawData['IPAddress'] || rawData['IpAddress'], // making sure empty string value is ignored
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	get name(): string {
		const ip = this.ip && `${this.ip.name}${this.port ? ':' + this.port : ''}`;
		let url = this.url && `${this.url.name}`;
		if (ip && url) url = ` (${url})`;
		return `${ip || ''}${url || ''}`;
	}

	@EntityField({
		data: '__self',
		parse: rawData => {
			return rawData.Url ? rawData : null;
		},
	})
	url: Url;

	@EntityField({ data: 'Hostname' })
	hostName: string;

	@EntityField({ data: 'RegisteredDomain' })
	registeredDomain: string;

	@EntityField({
		data: '__self',
		parse: rawData => {
			return rawData.IPAddress || rawData.IpAddress ? rawData : null;
		},
	})
	ip: Ip;

	@EntityField({ data: 'Port' })
	port: number;

	@EntityField({ data: 'Protocol' })
	protocol: string;
}
