import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ModelBase } from '@microsoft/paris';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

export abstract class EntitiesPanelComponentBase<TEntity extends ModelBase = ModelBase, TOptions = {}>
	implements OnDestroy, OnInit {
	@Input() entities: Array<TEntity>;
	@Input() options: TOptions;
	@Input() action$: Observable<{ action: ItemActionModel; data: any }>;
	@Input() isUserExposed$: BehaviorSubject<boolean>;
	@Input() isLoadingEntity$: Observable<boolean>;

	/**
	 * Emit this to run one of the EntityType's actions
	 */
	@Output() requestAction: EventEmitter<{ actionId: string }> = new EventEmitter<{ actionId: string }>();

	/**
	 * Emit this to refresh parent component on closing panel from not command-bar updates
	 */
	 @Output() refreshOnResolve: EventEmitter<{ refreshOnResolve: boolean }> = new EventEmitter<{ refreshOnResolve: boolean }>();

	/**
	 * Emit this to have the entity panel refresh the entity.
	 */
	@Output() requestEntitiesRefresh: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Emit this to have the entity panel metadata refreshed.
	 */
	@Output() requestMetadataRefresh: EventEmitter<void> = new EventEmitter<void>();

	private setExtraDataSubscription: Subscription;

	ngOnInit() {
		this.setEntities(this.entities);
	}

	ngOnDestroy() {
		this.setExtraDataSubscription && this.setExtraDataSubscription.unsubscribe();
	}

	setEntities(entities: Array<TEntity>): void {
		this.entities = entities;
	}

	refreshEntities(): void {
		this.setEntities(this.entities);
	}

	runEntityAction(actionId: string): void {
		if (actionId) this.requestAction.emit({ actionId: actionId });
	}

	refreshOnClose(refreshOnResolve: boolean): void {
		this.refreshOnResolve.emit({ refreshOnResolve });
	}

	protected resetExtraDataSubscriptions() {
		this.setExtraDataSubscription && this.setExtraDataSubscription.unsubscribe();
		this.setExtraDataSubscription = new Subscription();
	}

	protected addExtraDataSubscription(subscription: Subscription) {
		if (!this.setExtraDataSubscription) this.setExtraDataSubscription = new Subscription();

		this.setExtraDataSubscription.add(subscription);
	}
}
