import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { ITextField } from 'office-ui-fabric-react';
import { Subject } from 'rxjs';

import { HuntingQuery, QueryKind } from '@wcd/domain';
import { IConfirmationDialogContentComponent } from '@wcd/dialogs';
import { DropdownComponent } from '@wcd/forms';

import { HuntingQueryListService } from '../../queries/services/hunting-query-list.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styles: [
		`
			.wcd-hunting-save-query-content__dropdown-menu {
				max-height: 200px;
			}
		`,
	],
	template: `
		<div class="wcd-flex-vertical">
			<p role="alert"
			   *ngIf="errorMessage" class="wcd-margin-horizontal wcd-margin-none-vertical ms-fontColor-red"
			   [attr.id]="'query_name_error'">
				{{ errorMessage }}
			</p>

			<wcd-text-field
				[attr.aria-describedby]="'query_name_error'"
				[label]="'hunting.saveQueryDialog.queryNameLabel' | i18n"
				[required]="true"
				[disabled]="isSaving"
				className="wcd-full-width"
				[(text)]="query.name"
				(textChange)="this.isValid$.next(!!$event)"
			>
			</wcd-text-field>

			<!-- TODO: Use Fabric's Callout instead -->

			<label class="wcd-margin-top" for="folder-select" [attr.id]="'wcd-dropdown-label'">{{
				'hunting_saveQueryDialog_queryFolderLabel' | i18n
			}}</label>
			<wcd-dropdown
				id="folder-select"
				[isBordered]="true"
				[isFullWidth]="true"
				[attr.aria-labelledby]="'wcd-dropdown-label'"
				buttonClass="wcd-full-width ms-fontSize-m"
				[buttonText]="selection.folderName"
				[closeOnClick]="false"
				[disabled]="isSaving"
				[showIconDropdown]="true"
				menuClass="wcd-hunting-save-query-content__dropdown-menu"
			>
				<wcd-hunting-queries-list
					kind="shared"
					[toggleOnTitleClick]="false"
					[configurationOverrides]="{
						treeOptions: {
							showQueries: false,
							enableNewFolders: true,
							toggleOnFolderNameClick: false
						}
					}"
					(folderClicked)="onFolderSelected('shared', $event)"
					(folderCreated)="onFolderSelected('shared', $event)"
				></wcd-hunting-queries-list>

				<wcd-hunting-queries-list
					kind="user"
					[toggleOnTitleClick]="false"
					[configurationOverrides]="{
						treeOptions: {
							showQueries: false,
							enableNewFolders: true,
							toggleOnFolderNameClick: false
						}
					}"
					(folderClicked)="onFolderSelected('user', $event)"
					(folderCreated)="onFolderSelected('user', $event)"
				></wcd-hunting-queries-list>
			</wcd-dropdown>
		</div>
	`,
})
export class SaveQueryContentComponent implements OnInit, IConfirmationDialogContentComponent {
	readonly isValid$ = new Subject<boolean>();
	private _errorMessage?: string;

	@Input() query: HuntingQuery;
	@Input()
	set errorMessage(value: string) {
		this._errorMessage = value;
		this.changeDetectorRef.markForCheck();
	}
	get errorMessage() {
		return this._errorMessage;
	}

	@ViewChild(DropdownComponent, { static: false }) readonly dropdown: DropdownComponent;

	isSaving = false;

	selection: {
		folderName: string;
		folderPath?: string;
		kind: QueryKind;
	};

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly huntingQueryListService: HuntingQueryListService
	) {}

	ngOnInit() {
		const selectedQueryListKind: QueryKind = this.query.kind === 'shared' ? 'shared' : 'user';

		this.selection = {
			folderName: this.huntingQueryListService.displayNames[selectedQueryListKind],
			kind: selectedQueryListKind,
		};
	}

	onFolderSelected(queryKind: QueryKind, { name, path }: { name: string; path: string }) {
		this.dropdown.close();

		this.selection = {
			kind: queryKind,
			folderName: name,
			folderPath: path,
		};

		this.changeDetectorRef.markForCheck();
	}
}
