<!-- tslint:disable:template-click-events-have-key-events -->
<div class="wcd-flex-1 wcd-flex-vertical wcd-full-height"
	 data-track-component="AlertDetails"
     data-track-component-type="Page">
	 <standard-discovery-message></standard-discovery-message>
	<div class="wcd-full-width wcd-padding-large-left wcd-padding-medium-top">
		<h2 class="wcd-text-overflow-ellipsis wcd-margin-small-bottom wcd-font-weight-bold header-height">{{alert.name}}</h2>
	</div>

	<div class="wcd-flex-horizontal wcd-flex-1">
		<div class="alert-main-panel
					wcd-padding-large-left
					wcd-padding-large-right
					wcd-padding-large-top
					wcd-flex-vertical
					wcd-full-height">
			<div role="list" class="wcd-flex-horizontal element element--centered">
				<span class="wcd-padding-xsmall-horizontal wcd-padding-xsmall-vertical wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis" role="presentation">
					<alerted-machine-details *ngIf="machine"
							 role="presentation"
							[machine]="machine"
							[alert]="alert">
					</alerted-machine-details>
				</span>
				<span class="wcd-padding-xsmall-horizontal wcd-padding-xsmall-vertical wcd-text-overflow-ellipsis-nowrap wcd-text-overflow-ellipsis" role="presentation">
					<alerted-user-details *ngIf="user"
							role="presentation"
							[user]="user">
					</alerted-user-details>
				</span>
			</div>

			<div class="wcd-full-width wcd-padding-small-vertical">
				<span class="tree-element--title--box--text">
					{{ 'alerts.alertStory.capitalLetters' | i18n }}
				</span>
				<span *ngIf="!defaultAlertContext && treeElements"
					 class="alert-align-right">
					<a (click)="setTreeMinimized(allCollapsed)"
					   data-track-type="Button"
					   [attr.data-track-id]="allCollapsed ? 'expand-all' : 'collapse-all'"
					   tabindex="0"
					   role="button"
					   [attr.aria-description]="('alert.accessibility.process.tree.' + (allCollapsed ? 'collapse' : 'expand') + '.all.label') | i18n">
						{{ (allCollapsed ? 'alert.tabs.tree.collapse' : 'alert.tabs.tree.expand') | i18n }}
					</a>
				</span>
			</div>
			<div class="alert-page-inner-background wcd-flex-1 wcd-scroll-vertical alert-story">
				<ng-container *ngIf="alert.isThreatExpertOriginated && (alert.description || alert.recommendedAction)">
					<div class="element--border element">
						<div class="tree-element--title--box
									wcd-padding-bottom">
							<h4 class="wcd-margin-none-all
									   wcd-padding-vertical
									   wcd-padding-large-left
									   wcd-padding-right
									   wcd-border-bottom
									   wcd-font-weight-bold">
								{{ 'alerts.whatHappened' | i18n }}
							</h4>

							<div class="wcd-padding-large-left wcd-padding-right wcd-padding-top">
								<alerted-description [description]="alert.description"
													 [recommendedAction]="alert.recommendedAction"
													 [allowHtmlRendering]="alert.isThreatExpertOriginated">
								</alerted-description>
							</div>
						</div>
					</div>
				</ng-container>

				<div class="wcd-padding-largeMedium-horizontal wcd-padding-medium-bottom">
					<ng-container *ngIf="!processTreeLoading">
						<ng-container *ngIf="!defaultAlertContext && !processTreeError">
							<div [class.wcd-padding-medium-top]="treeElements[0].relationToPrecedingElement"
								 [attr.aria-label]="'alert.accessibility.process.tree.label' | i18n"
								 role="tree">
								<multiple-alerts-process-tree
									role="presentation"
									[treeElements]="treeElements"
									(doneRendering)="OnDoneRendering()"></multiple-alerts-process-tree>
							</div>
						</ng-container>
						<ng-container *ngIf="defaultAlertContext">
							<div class="wcd-padding-medium-top">
								<associated-alerts [associatedAlerts]="[ defaultAlertContext ]"></associated-alerts>
							</div>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="processTreeError">
						<div class="wcd-padding-medium-top">
							<i class="icon icon-Error"></i>
							<ng-container [ngSwitch]="processTreeErrorType">
								<ng-container *ngSwitchCase="ProcessTreeErrorType.unavailableAlertStory">{{ 'alert.process.tree.error.unavailableAlertStory' | i18n }}</ng-container>
								<ng-container *ngSwitchCase="ProcessTreeErrorType.unSupportedAlertStory">{{ 'alert.process.tree.error.unSupportedAlertStory' | i18n }}</ng-container>
								<ng-container *ngSwitchCase="ProcessTreeErrorType.unSupportedJson">{{ 'alert.process.tree.error.unSupportedJson' | i18n }}</ng-container>
								<ng-container *ngSwitchDefault>{{ 'alert.process.tree.error.unKnownAlertStoryError' | i18n }}</ng-container>
							</ng-container>
							<ng-container *ngIf="seeInTimelineLink">
								<div>
									{{ 'alert.process.tree.error.seeInTimelineLink.prefix' | i18n }}
									<a href="{{ seeInTimelineLink }}">
										{{ 'alert.process.tree.error.seeInTimelineLink.middle' | i18n }}
									</a>
									{{ 'alert.process.tree.error.seeInTimelineLink.suffix' | i18n }}
								</div>
							</ng-container>
						</div>
					</ng-container>

					<ng-container *ngIf="processTreeLoading">
						<fab-spinner className="wcd-padding-medium-top" [size]="SpinnerSize.large"></fab-spinner>
					</ng-container>
				</div>
			</div>
		</div>

		<!--Side panel-->
		<div class="alert-side-panel wcd-full-height wcd-flex-vertical">
			<div class="wcd-padding-large-horizontal">
				<tabs
					[tabsData]="tabs"
					[currentRouteIsActive]="true"
				></tabs>
			</div>
			<div class="wcd-full-height wcd-flex-vertical">
				<router-outlet></router-outlet>
			</div>
		</div>
	</div>
</div>
