import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { AdminService, SettingsNavSection } from '../services/admin.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MainAppState, MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { Location } from '@angular/common';
import { NavItemModel } from '@wcd/shared';
import { IDropdownOption } from 'office-ui-fabric-react';
import { filter } from 'rxjs/operators';
import { AppContextService } from '@wcd/config';
import { sccHostService } from '@wcd/scc-interface';

type NavDropdown = IDropdownOption & { item?: NavItemModel };
type IndexedItemModel = NavItemModel & { index: number };
type IndexedSettingsNav = SettingsNavSection & { indexedStates: IndexedItemModel[] };

let lastId = 0;
@Component({
	template: `
		<div class="wcd-full-height wcd-flex-vertical  wcd-full-height-from-width-800">
			<header class="wcd-flex-none page-header" [class.wcd-padding-none-horizontal-important]="isScc">
				<h2>{{
						(!appContextService.isSCC
							? 'settings_page_title'
							: 'settings_endpoints_page_title') | i18n
					}}</h2>
			</header>
			<div class="wcd-flex-1">
				<div
					class="wcd-flex-horizontal wcd-full-height wcd-flex-horizontal-medium navigation"
				>
					<wcd-nav-dropdown
						[buttonText]="activeElement && activeElement.name"
						buttonClass="dropdown-width-full"
						isFullWidth="true"
						[optionAriaSetSize]="resultCount"
						optionAriaRole="option"
						[optionAriaPositionInSet]="activeElement && activeElement.index"
						[ariaRole]="'listbox'"
						ariaHaspopup="listbox"
						focusOnInit="true"
						navigateUsingArrowKeysOnly="true"
						ariaLabel="Settings"
						[buttonId]="buttonId"
					>
						<ng-container
							*ngTemplateOutlet="menuTemplate; context: { isDropdown: true }"
						></ng-container>
						<ng-template #menuTemplate let-isDropdown="isDropdown">
							<section
								class="nav-list-section nested"
								*ngFor="let section of indexedSettingsNav; let index = index"
							>
								<span [id]="'title-' + index + (isDropdown ? '_dropdown' : '')" class="nav-list-section-title">{{ section.name }}</span>
								<ul
									class="nav-list small-spacing "
									role="listbox"
									[attr.aria-labelledby]="'title-' + index + (isDropdown ? '_dropdown' : '')"
								>
									<li
										*ngFor="let state of section.indexedStates"
										class="nowrap"
										[ngClass]="{
											active: activeElement && state.index == activeElement.index
										}"
										routerLinkActive="active"
										role="presentation"
									>
										<ng-container *ngIf="state.isLegacy; else routerLink">
											<route-link
												[routeLink]="state"
												[routerLinkActiveCssClass]="'active'"
												class="nav-list-link"
												[linkAriaSelected]="
													activeElement && state.index == activeElement.index
												"
												[linkAriaPosinset]="state.index + 1"
												[linkAriaSetsize]="resultCount"
												keyboard-navigable-element
											>
												<ng-container
													*ngTemplateOutlet="
														linkTemplate;
														context: {
															name: state.name,
															badgeId: state.badgeId
														}
													"
												></ng-container>
											</route-link>
										</ng-container>
										<ng-template #routerLink>
											<a
												class="nav-list-link"
												[attr.data-track-id]="state.route"
												[class.dropdownNav]="isDropdown"
												data-track-type="Navigation"
												[routerLink]="['/preferences2', state.route]"
												#accessibleListItem
												role="option"
												[attr.aria-posinset]="state.index + 1"
												[attr.aria-setsize]="resultCount"
												[attr.aria-selected]="
													activeElement && state.index == activeElement.index
												"
												keyboard-navigable-element
											>
												<ng-container
													*ngTemplateOutlet="
														linkTemplate;
														context: {
															name: state.name,
															badgeId: state.badgeId
														}
													"
												></ng-container>
											</a>
										</ng-template>
									</li>
								</ul>
							</section>
							<ng-template #linkTemplate let-name="name" let-badgeId="badgeId">
								<span>{{ name }}</span>
								<span
									class="wcd-badge wcd-badge-raised color-box-error"
									*ngIf="mainAppState.mainNavItemBadges[badgeId]"
								>
									{{ mainAppState.mainNavItemBadges[badgeId] }}
								</span>
							</ng-template>
						</ng-template>
					</wcd-nav-dropdown>
					<nav
						class="wcd-flex-none wcd-border-right-light wcd-padding-large-right wcd-padding-large-bottom wcd-scroll-vertical"
						[class.wcd-padding-xxLarge-left]="!isScc"
						[keyboard-navigable-container]
					>
						<ng-container
							*ngTemplateOutlet="menuTemplate; context: { isDropdown: false }"
						></ng-container>
					</nav>
					<div class="wcd-flex-1 content-area">
						<div
							class="wcd-full-height largeMedium-left-padding"
							[hidden]="mainAppState.loadingComponent?.id === 'settings'"
						>
							<router-outlet></router-outlet>
						</div>
						<div
							*ngIf="mainAppState.loadingComponent?.id === 'settings'"
							class="wcd-full-height loading-overlay"
						>
							<i class="large-loader-icon"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
	`,
	styleUrls: ['./admin.component.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit {
	@Input() buttonId = `settings-nav-button-id-${lastId++}`;

	settingsNav: Array<SettingsNavSection>;
	resultCount = 0;
	mainAppState: MainAppState;
	options: NavDropdown[];
	valueIndex = 0;
	activeElement: IndexedItemModel;
	isScc = sccHostService.isSCC;
	indexedSettingsNav: IndexedSettingsNav[];
	mappedSettingNav: IndexedItemModel[];

	constructor(
		public adminService: AdminService,
		public route: ActivatedRoute,
		private router: Router,
		private mainAppStateService: MainAppStateService,
		private location: Location,
		public appContextService: AppContextService
	) {
		mainAppStateService.state$.subscribe((mainAppState: MainAppState) => {
			this.mainAppState = mainAppState;
		});

		router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe((event: NavigationEnd) => {
				this.setActive(event.url);
			});
	}

	ngOnInit() {
		this.settingsNav = this.adminService.getSettingsNav(this.route);
		let counter = 0;

		[this.indexedSettingsNav, this.mappedSettingNav] = this.settingsNav.reduce(
			([indexedNav, mappedNav], setting) => {
				const [indexedStates] = setting.states.reduce(
					([indexedStates, mappedNav], state) => {
						const indexState = <IndexedItemModel>{ ...state, index: counter++ };
						mappedNav.push(indexState);
						indexedStates.push(indexState);
						return [indexedStates, mappedNav];
					},
					[<IndexedItemModel[]>[], mappedNav]
				);
				const indexedSettingsNav = {
					...setting,
					indexedStates: indexedStates,
				};

				indexedNav.push(indexedSettingsNav);
				return [indexedNav, mappedNav];
			},
			[<IndexedSettingsNav[]>[], <IndexedItemModel[]>[]]
		);

		this.resultCount = counter;
		if (this.mappedSettingNav) this.activeElement = this.getActive();
	}

	setActive(path) {
		if (this.mappedSettingNav) this.activeElement = this.getActive(path);
	}

	getActive(path = this.location.path()): IndexedItemModel {
		return this.mappedSettingNav.find(state => {
			return path.startsWith((state.isLegacy ? '' : '/preferences2/') + state.route);
		});
	}
}
