var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { DomainCategory } from './domain-category/domain-category.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.webThreatProtection; }, ɵ1 = function (itemId, entity, config, params) {
    // Since the id of the domain will most likely have periods in it, send it as query parameter
    // itemId is domain name (ex. www.microsoft.com)
    return "domains/allDomains?id=" + itemId;
};
var Domain = /** @class */ (function (_super) {
    __extends(Domain, _super);
    function Domain() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'DomainId' }),
        __metadata("design:type", String)
    ], Domain.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], Domain.prototype, "title", void 0);
    __decorate([
        EntityField({ data: 'AccessCount' }),
        __metadata("design:type", Number)
    ], Domain.prototype, "accessCount", void 0);
    __decorate([
        EntityField({ data: 'Blocks' }),
        __metadata("design:type", Number)
    ], Domain.prototype, "blocks", void 0);
    __decorate([
        EntityField({ data: 'AccessTrend' }),
        __metadata("design:type", Number)
    ], Domain.prototype, "accessTrend", void 0);
    __decorate([
        EntityField({ data: 'WebThreatCategory' }),
        __metadata("design:type", DomainCategory)
    ], Domain.prototype, "webThreatCategory", void 0);
    __decorate([
        EntityField({ data: 'MachinesDetectedOn' }),
        __metadata("design:type", Number)
    ], Domain.prototype, "machinesDetectedOn", void 0);
    __decorate([
        EntityField({ data: 'Urls' }),
        __metadata("design:type", Array)
    ], Domain.prototype, "urls", void 0);
    __decorate([
        EntityField({ data: 'Severity' }),
        __metadata("design:type", String)
    ], Domain.prototype, "severity", void 0);
    __decorate([
        EntityField({ data: 'Status' }),
        __metadata("design:type", String)
    ], Domain.prototype, "status", void 0);
    Domain = __decorate([
        Entity({
            singularName: 'Domain',
            pluralName: 'Domains',
            endpoint: 'webThreats/domains/allDomains',
            allItemsProperty: 'results',
            baseUrl: ɵ0,
            cache: {
                time: 1000 * 60,
                max: 10,
            },
            parseItemQuery: ɵ1,
        })
    ], Domain);
    return Domain;
}(EntityModelBase));
export { Domain };
export { ɵ0, ɵ1 };
