/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../convergence-promotion/components/convergence-promotion-widget.component.ngfactory";
import * as i2 from "../../../../../convergence-promotion/components/convergence-promotion-widget.component";
import * as i3 from "../../../../../convergence-promotion/services/convergence-promotion.service";
import * as i4 from "../../../../../../../../../projects/config/src/lib/services/features.service";
import * as i5 from "./promotion-banner.widget";
import * as i6 from "../../../../../shared-reports/services/reports.service";
import * as i7 from "../../../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i8 from "../../../../../../../../../projects/i18n/src/lib/services/i18n.service";
var styles_PromotionBannerWidget = [];
var RenderType_PromotionBannerWidget = i0.ɵcrt({ encapsulation: 2, styles: styles_PromotionBannerWidget, data: {} });
export { RenderType_PromotionBannerWidget as RenderType_PromotionBannerWidget };
export function View_PromotionBannerWidget_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 1, "convergence-promotion-widget", [], null, [[null, "onDismiss"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onDismiss" === en)) {
        var pd_0 = (_co.dismiss() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_ConvergencePromotionWidgetComponent_0, i1.RenderType_ConvergencePromotionWidgetComponent)), i0.ɵdid(2, 49152, null, 0, i2.ConvergencePromotionWidgetComponent, [i3.ConvergencePromotionService, i4.FeaturesService], null, { onDismiss: "onDismiss" }), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], null, null); }
export function View_PromotionBannerWidget_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "promotion-banner-widget", [], null, null, null, View_PromotionBannerWidget_0, RenderType_PromotionBannerWidget)), i0.ɵdid(1, 245760, null, 0, i5.PromotionBannerWidget, [i6.ReportsService, i4.FeaturesService, i7.PreferencesService, i3.ConvergencePromotionService, i8.I18nService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PromotionBannerWidgetNgFactory = i0.ɵccf("promotion-banner-widget", i5.PromotionBannerWidget, View_PromotionBannerWidget_Host_0, {}, {}, []);
export { PromotionBannerWidgetNgFactory as PromotionBannerWidgetNgFactory };
