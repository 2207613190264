import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { WcdFormsModule } from '@wcd/forms';
import { TooltipsModule } from '@wcd/dialogs';
import { FiltersComponent } from './components/filters.component';
import { FabIconModule, FabButtonModule, FabSpinnerModule, FabSliderModule } from '@angular-react/fabric';
import { PrettifyModule } from '@wcd/prettify';
import { FilterValuesChecklistComponent } from './components/filter-values/checklist/filter-values.checklist.component';
import { FiltersFieldComponent } from './components/filters-field.component';
import { FilterValuesChecklistWithSearchComponent } from './components/filter-values/checklist_with_search/filter-values.checklist-with-search.component';
import { WcdIconsModule } from '@wcd/icons';
import { FabDatePickerModule } from '@angular-react/fabric';
import { FilterValuesDatePickerComponent } from './components/filter-values/datepicker/filter-values.datepicker.component';
import { FilterValuesDropdownComboComponent } from './components/filter-values/dropdown_combo/filter-values.dropdown-combo.component';
import { FilterValuesToggleComponent } from './components/filter-values/toggle/filter-values.toggle.component';
import { FilterValuesRangeSliderComponent } from './components/filter-values/range-slider/filter-values.range-slider.component';
import { WcdSharedModule } from '@wcd/shared';
import { AngularExtensionsModule } from '@wcd/angular-extensions';
import { I18nModule } from '@wcd/i18n';
import { A11yModule } from '@angular/cdk/a11y';

const filterValueComponents = [
	FilterValuesChecklistComponent,
	FilterValuesChecklistWithSearchComponent,
	FilterValuesDatePickerComponent,
	FilterValuesDropdownComboComponent,
	FilterValuesToggleComponent,
	FilterValuesRangeSliderComponent,
];

const exportedComponents = [FiltersComponent, FiltersFieldComponent, ...filterValueComponents];

@NgModule({
	imports: [
		CommonModule,
		WcdFormsModule,
		TooltipsModule,
		FormsModule,
		FabIconModule,
		FabButtonModule,
		FabSpinnerModule,
		FabSliderModule,
		FabDatePickerModule,
		PrettifyModule,
		WcdIconsModule,
		WcdSharedModule,
		AngularExtensionsModule,
		I18nModule,
		A11yModule
	],
	declarations: exportedComponents,
	exports: exportedComponents,
	entryComponents: filterValueComponents,
})
export class FiltersModule {}
