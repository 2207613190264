/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../shared/components/banner/banner.component.ngfactory";
import * as i2 from "../../shared/components/banner/banner.component";
import * as i3 from "../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i4 from "../../shared/main/services/main-app-state.service";
import * as i5 from "@angular/common";
import * as i6 from "./promotion-banner.component";
import * as i7 from "../services/mtp-promotion.service";
var styles_PromotionBannerComponent = [];
var RenderType_PromotionBannerComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_PromotionBannerComponent, data: {} });
export { RenderType_PromotionBannerComponent as RenderType_PromotionBannerComponent };
function View_PromotionBannerComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "wcd-banner", [], null, [[null, "onClose"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onClose" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_BannerComponent_0, i1.RenderType_BannerComponent)), i0.ɵdid(1, 4243456, null, 0, i2.BannerComponent, [i3.I18nService, i4.MainAppStateService, i0.ChangeDetectorRef], { text: [0, "text"], linkOptions: [1, "linkOptions"] }, { onClose: "onClose" }), i0.ɵpod(2, { id: 0, buttonTextKey: 1, navigateTo: 2 }), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text; var currVal_1 = _ck(_v, 2, 0, "mtpPromotion_TryNow", "mtpPromotion.tryNow", _co.navigateTo); _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
export function View_PromotionBannerComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_PromotionBannerComponent_1)), i0.ɵdid(2, 16384, null, 0, i5.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.navigateTo; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_PromotionBannerComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "promotion-banner", [], null, null, null, View_PromotionBannerComponent_0, RenderType_PromotionBannerComponent)), i0.ɵdid(1, 49152, null, 0, i6.PromotionBannerComponent, [i3.I18nService, i7.MtpPromotionService], null, null)], null, null); }
var PromotionBannerComponentNgFactory = i0.ɵccf("promotion-banner", i6.PromotionBannerComponent, View_PromotionBannerComponent_Host_0, { textMessageKey: "textMessageKey", trackId: "trackId", url: "url" }, {}, []);
export { PromotionBannerComponentNgFactory as PromotionBannerComponentNgFactory };
