
		<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingTemplate">
			<div
				*ngIf="showSummary"
				class="wcd-font-size-s wcd-padding-small-top"
				[ngSwitch]="tableItems.length"
			>
				<p *ngSwitchCase="0">
					{{ 'files.entityDetails.sections.detections.fields.malwareDetected.none' | i18n }}
				</p>
				<p *ngSwitchCase="1">{{ tableItems[0].threatName }}</p>
				<p *ngSwitchDefault>
					{{ 'files.entityDetails.sections.detections.fields.malwareDetected.multiple' | i18n }}
				</p>
			</div>
			<div *ngIf="tableItems.length" class="wcd-padding-small-top">
				<wcd-datatable
					[items]="tableItems"
					[columns]="tableFields"
					[selectEnabled]="false"
					[label]="'files.entityDetails.sections.detections.fields.malwareDetected.title' | i18n"
				></wcd-datatable>
			</div>
		</ng-container>

		<ng-template #loadingTemplate>
			<fab-spinner></fab-spinner>
		</ng-template>
	