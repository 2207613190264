import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AirsMailboxConfiguration } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { ThreatsDisplayNameService } from '../../../mtp_investigations/services/threats-display-name-field.service';

@Component({
	selector: 'airs-mailbox-configuration-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.mailboxPrimaryAddress">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_mailbox }}
				</dt>
				<dd role="none">{{ entity.mailboxPrimaryAddress }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.configType">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_configurationAction }}
				</dt>
				<dd role="none">
					{{ 'airsEntities_mailConfiguration_confType_' + entity.configType | i18n }}
				</dd>
			</ng-container>
			<ng-container *ngIf="entity.performedBy">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_performedBy }}
				</dt>
				<dd role="none">{{ entity.performedBy }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.accessToMailbox">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_accessTo }}
				</dt>
				<dd role="none">{{ entity.accessToMailbox }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.delegatedToUser">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_delegatedToUser }}
				</dt>
				<dd role="none">{{ entity.delegatedToUser }}</dd>
			</ng-container>
			<ng-container
				*ngIf="
					('airsEntities_mailboxConfiguration_fields_accessLevel_' + entity.accessRightsI18nKey
						| i18n: null:true) || entity.accessRightsI18nKey as accessLevel
				"
			>
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_configurationSettings }}
				</dt>
				<dd role="none">{{ accessLevel }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.upn">
				<dt role="none">{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_upn }}</dt>
				<dd role="none">{{ entity.upn }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.timestamp">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_actionTime }}
				</dt>
				<dd role="none">{{ entity.timestamp | date: 'short' }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.forwardingSmtpAddress">
				<dt role="none">
					{{ i18nService.strings.airsEntities_mailboxConfiguration_fields_forwardingSmtpAddress }}
				</dt>
				<dd role="none">{{ entity.forwardingSmtpAddress }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.deliverToMailboxAndForward">
				<dt role="none">
					{{
						i18nService.strings
							.airsEntities_mailboxConfiguration_fields_deliverToMailboxAndForward
					}}
				</dt>
				<dd role="none">{{ entity.deliverToMailboxAndForward }}</dd>
			</ng-container>
			<ng-container
				*ngIf="threatsDisplayNameService.getThreatsDisplayName(entity.threats) as threatsDisplay"
			>
				<dt role="none">{{ i18nService.strings.airsEntities_general_fields_threats }}</dt>
				<dd role="none">{{ threatsDisplay }}</dd>
			</ng-container>
		</dl>
	`,
})
export class AirsMailboxConfigurationDetailsComponent {
	@Input() entity: AirsMailboxConfiguration;

	constructor(
		public i18nService: I18nService,
		public threatsDisplayNameService: ThreatsDisplayNameService
	) {}
}
