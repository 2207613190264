import { Selection as D3Selection, BaseType as D3BaseType } from 'd3-selection';
import * as ChartsUtils from '../charts.utils';

export interface GaugeNeedleDatum {
	needleLength: number;
	circleRadius: number;
	percent: number;
}

export class GaugeNeedleGenerator {
	generate(
		data: Array<GaugeNeedleDatum>,
		appendTo: D3Selection<D3BaseType, any, any, any>
	): D3Selection<D3BaseType, GaugeNeedleDatum, D3BaseType, any> {
		const enterSelection = appendTo
			.append('g')
			.attr('class', 'needle-container')
			.selectAll()
			.data(data)
			.enter();

		enterSelection
			.append('path')
			.attr('class', 'needle-outer')
			.attr('stroke-width', 6)
			.attr('d', d => this.getPathDirections(d.percent, d.needleLength));

		enterSelection
			.append('path')
			.attr('class', 'needle')
			.attr('d', d => this.getPathDirections(d.percent, d.needleLength));

		enterSelection
			.append('circle')
			.attr('class', 'needle-outer-circle')
			.attr('cx', 0)
			.attr('cy', 0)
			.attr('r', d => d.circleRadius + 2);

		return enterSelection
			.append('circle')
			.attr('class', 'needle-circle')
			.attr('cx', 0)
			.attr('cy', 0)
			.attr('r', d => d.circleRadius);
	}

	private getPathDirections(percent: number, length: number) {
		const angleRad = ChartsUtils.percToRad(percent);
		const topX = -length * Math.cos(angleRad);
		const topY = -length * Math.sin(angleRad);
		return `M ${0} ${0} L ${topX} ${topY}`;
	}
}
