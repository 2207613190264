
		<ng-container>
			<ng-container *ngFor="let actionWithStatus of actionsWithStatus">
				<dataview-action-wrapper
					*ngIf="actionWithStatus.action.actionType != dataviewActionTypes.Pagination"
					[actionClass]="getActionClass(false, actionWithStatus.isUnderMoreActionsIcon)"
					[action]="actionWithStatus.action"
					[position]="positions.Default"
					[isMoreActionsList]="false"
					[smallScreenView]="smallScreenView"
					[actionType]="actionWithStatus.action.actionType"
					[dataViewModel]="dataViewModel"
				></dataview-action-wrapper>

				<dataview-action-pagination
					[hidden]="false"
					[ngClass]="'dataview-action'"
					[dataViewModel]="dataViewModel"
					[paginationActionConfig]="actionWithStatus.action"
					[shouldShowPageNumbers] ="shouldShowPageNumbersOnPagination"
					*ngIf="actionWithStatus.action.actionType == dataviewActionTypes.Pagination"
				></dataview-action-pagination>

			</ng-container>
			<wcd-dropdown
				[hidden]="!showMoreActionsDropdown"
				[closeOnMouseUp]="false"
				class="dataview-action"
				[buttonIcon]="'More'"
				[showIconDropdown]="false"
				[closeOnClick]="false"
				[ariaLabel]="'entityCommon_commandBar_moreOptions' | i18n"
				[buttonText]="'entityCommon_commandBar_moreOptions' | i18n"
				ariaRole="listbox"
				optionAriaRole="option"
				[allowFocusOnMenu]="null"
				[iconButtonOnly]="true"
				[focusHelper]="focusButton"
				[fixDropdownItems]="fixDropdownItems"
			>
				<div class="dataview-more-actions">
					<ng-container *ngFor="let actionWithStatus of actionsWithStatus">
						<ng-container
							*ngIf="
								!isActionHidden(true, actionWithStatus.isUnderMoreActionsIcon) &&
								(actionWithStatus.action.actionType == dataviewActionTypes.Button ||
								actionWithStatus.action.actionType == dataviewActionTypes.ColumnsCustomization ||
								actionWithStatus.action.actionType == dataviewActionTypes.FancySelect ||
								(actionWithStatus.action.actionType == dataviewActionTypes.Filter && dataViewModel.allowFilters));
								else noAccessibleListItem
							"
							>
							<dataview-action-wrapper #accessibleListItem
								[actionClass]="getActionClass(true, actionWithStatus.isUnderMoreActionsIcon)"
								[action]="actionWithStatus.action"
								[position]=" isRightSideActionBar
												? positions.Left
												: positions.Right"
								[isMoreActionsList]="true"
								[smallScreenView]="false"
								[actionType]="actionWithStatus.action.actionType"
								[dataViewModel]="dataViewModel"
							></dataview-action-wrapper>
						</ng-container>

						<ng-template #noAccessibleListItem>
							<ng-container *ngIf="actionWithStatus.action.actionType == dataviewActionTypes.Pagination">
								<dataview-action-wrapper
									[actionClass]="getActionClass(true, actionWithStatus.isUnderMoreActionsIcon)"
									[action]="actionWithStatus.action"
									[position]=" isRightSideActionBar
													? positions.Left
													: positions.Right"
									[isMoreActionsList]="true"
									[smallScreenView]="false"
									[actionType]="actionWithStatus.action.actionType"
									[dataViewModel]="dataViewModel"
								></dataview-action-wrapper>
							</ng-container>
						</ng-template>
					</ng-container>
				</div>
			</wcd-dropdown>
		</ng-container>
	