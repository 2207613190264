<dl [class.key-values]="asKeyValueList" *ngIf="process?.name || process?.id || process?.file?.id; else unknownProcess" role="none">
	<dt>{{ 'processes.fields.name.title' | i18n }}</dt>
	<dd>
		<ng-container *ngIf="showEntityPanelLink && process.file?.id; else processName">
			<entity-link [entity]="process"
						 [entityType]="processType"
						 [linkText]="process.name"></entity-link>
		</ng-container>
		<ng-template #processName>{{process.name || 'Unknown'}}</ng-template>
	</dd>
	<ng-container *ngIf="process.creationTime">
		<dt>{{ 'processes.fields.creationTime.title' | i18n }}</dt>
		<dd>
			<tz-date [date]="process.creationTime" [dateFormat]="'shortWithMillis'"></tz-date>
		</dd>
	</ng-container>
	<ng-container *ngIf="process.file?.fullPath">
		<dt>{{ 'processes.fields.path.title' | i18n }}</dt>
		<dd>
			{{process.file.fullPath}}
		</dd>
	</ng-container>
	<ng-container *ngIf="process.integrityLevel">
		<dt>{{ 'processes.fields.integrityLevel.title' | i18n }}</dt>
		<dd>{{ process.integrityLevel }}</dd>
	</ng-container>
	<ng-container *ngIf="process.tokenElevation">
		<dt>{{ 'processes.fields.tokenElevation.title' | i18n }}</dt>
		<dd>{{ process.tokenElevation }}</dd>
	</ng-container>
	<ng-container *ngIf="process.id">
		<dt>{{ 'processes.fields.id.title' | i18n }}</dt>
		<dd>
			{{process.id}}
		</dd>
	</ng-container>
	<ng-container *ngIf="process.commandLine">
		<dt>{{ 'processes.fields.commandLine.title' | i18n }}</dt>
		<dd>
			<copyable-input [ariaLabel]="'processes.fields.commandLine.title' | i18n" [value]="process.commandLine" showFullValue="true" [readonly]="true"></copyable-input>
		</dd>
	</ng-container>
	<ng-container *ngIf="process.decodedCommandLine">
		<dt>{{ 'processes.fields.decodedCommandLine.title' | i18n }}</dt>
		<dd>
			<copyable-input [ariaLabel]="'processes.fields.decodedCommandLine.title' | i18n" [value]="process.decodedCommandLine" showFullValue="true" [readonly]="true"></copyable-input>
		</dd>
	</ng-container>
	<ng-container *ngIf="process.executedCommands">
		<dt>{{ 'processes.fields.executedCommands.title' | i18n }}</dt>
		<dd>
			<copyable-input [ariaLabel]="'processes.fields.executedCommands.title' | i18n" [value]="process.executedCommands" showFullValue="true" [readonly]="true"></copyable-input>
		</dd>
	</ng-container>
	<ng-container *ngIf="process?.additionalFields?.uri">
		<dt>{{ 'processes.fields.uri.title' | i18n }}</dt>
		<dd>
			<copyable-input [ariaLabel]="'processes.fields.uri.title' | i18n" [value]="process.additionalFields.uri" showFullValue="true"></copyable-input>
		</dd>
	</ng-container>
</dl>
<file-entity-details *ngIf="process?.file?.id" [entity]="process.file" [dataEnriched]="dataEnriched"></file-entity-details>

<ng-template #unknownProcess>
	<span class="subtle">{{'events.entities.unknownProcess' | i18n}}</span>
</ng-template>
