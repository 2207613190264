import {
	ChangeDetectionStrategy,
	Component,
	Input,
	Injector,
	ChangeDetectorRef,
	OnInit,
	OnDestroy,
} from '@angular/core';
import { ModelBase } from '@microsoft/paris';
import { BaselineProfileDetails, ProfileComplianceStatus } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { filter, map } from 'rxjs/operators';
import { RbacService } from '../../../../../rbac/services/rbac.service';
import { Subscription } from 'rxjs';
import { EntityDetailsComponentBase } from '../../../../../global_entities/components/entity-details/entity-details.component.base';
import { ActivatedEntity } from '../../../../../global_entities/services/activated-entity.service';

@Component({
	selector: 'baseline-profile-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './baseline-profile.entity-details.component.html',
})
export class BaselineProfileEntityDetailsComponent extends EntityDetailsComponentBase<BaselineProfileDetails> implements OnInit, OnDestroy {
	private _currentEntitySubscription: Subscription;
	private _userExposedRbacGroupsSubscription: Subscription;

	@Input() profile;
	deviceTags : string;
	rbacGroupNames : string;
	profilesComplianceStatus: ProfileComplianceStatus[];
	osPlatform: string;
	rbacService: RbacService;

	constructor(
		private cd: ChangeDetectorRef,
		public i18nService: I18nService,
		private activatedEntity: ActivatedEntity,
		rbacService: RbacService,
		injector: Injector
	) {
		super(injector);
		this.rbacService = rbacService;
	}

	ngOnInit() {
		super.ngOnInit();
		if (!this.profile) {
			this._currentEntitySubscription = this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof BaselineProfileDetails))
				.subscribe((profile: BaselineProfileDetails) => {
					this.profile = profile;
					if (!this.rbacGroupNames) {
						this._userExposedRbacGroupsSubscription = this.rbacService.userExposedRbacGroups$.pipe(
							map(rbacGroups => {
								const machineGroupNameById: { [id: number]: string } = rbacGroups.reduce(
									(map, currMachineGroup) => {
										map[currMachineGroup.id] = currMachineGroup.name;
										return map;
									},
									{}
								);
								return machineGroupNameById;
							})
						).subscribe((machineGroupNameById) => {
							this.rbacGroupNames = this.profile.rbacGroupIds.filter(id => machineGroupNameById[id]).map(id => machineGroupNameById[id]).join(', ');
							this.cd.markForCheck();
						});
					}
				});
		}
		this.osPlatform = `${this.profile.operatingSystem} ${this.profile.operatingSystemVersion}`;
		this.deviceTags = this.profile.deviceTags.join(', ');

	}

	ngOnDestroy(): void {
		this._currentEntitySubscription && this._currentEntitySubscription.unsubscribe();
		this._userExposedRbacGroupsSubscription && this._userExposedRbacGroupsSubscription.unsubscribe();
	}
}
