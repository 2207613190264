var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleScopeType } from './suppression-rule-scope-type.enum';
var SuppressionRuleScope = /** @class */ (function (_super) {
    __extends(SuppressionRuleScope, _super);
    function SuppressionRuleScope() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleScope.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleScope.prototype, "nameI18nKey", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], SuppressionRuleScope.prototype, "type", void 0);
    SuppressionRuleScope = __decorate([
        Entity({
            singularName: 'Suppression Rule Scope',
            pluralName: 'Suppression Rule Scopes',
            values: [
                {
                    id: 1,
                    name: 'Device',
                    nameI18nKey: 'SuppressionRuleScope_Device',
                    type: SuppressionRuleScopeType.Machine,
                },
                {
                    id: 2,
                    name: 'Organization',
                    nameI18nKey: 'SuppressionRuleScope_Organization',
                    type: SuppressionRuleScopeType.AllOrganization,
                },
                {
                    id: 3,
                    name: 'MachineGroups',
                    nameI18nKey: 'SuppressionRuleScope_MachineGroups',
                    type: SuppressionRuleScopeType.MachineGroups,
                },
                {
                    id: 4,
                    name: 'Conditional',
                    type: SuppressionRuleScopeType.Conditional,
                }
            ],
        })
    ], SuppressionRuleScope);
    return SuppressionRuleScope;
}(EntityModelBase));
export { SuppressionRuleScope };
