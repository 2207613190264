var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ReportWidgetComponent } from '../../../../reports/components/report-widget.component.base';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { Threat } from '@wcd/domain';
import { Router } from '@angular/router';
import { Paris, DataQuerySortDirection } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { TvmTextsService, TextToken } from '../../../services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
var TvmThreatCampaignsWidget = /** @class */ (function (_super) {
    __extends(TvmThreatCampaignsWidget, _super);
    function TvmThreatCampaignsWidget(reportsService, router, paris, tvmTextsService, i18nService) {
        var _this = _super.call(this, reportsService) || this;
        _this.router = router;
        _this.paris = paris;
        _this.i18nService = i18nService;
        _this.exposureTooltip = tvmTextsService.getText(TextToken.ExposureScoreTooltip);
        _this.configurationTooltip = tvmTextsService.getText(TextToken.ConfigurationScoreTooltip);
        _this.widgetTooltip = tvmTextsService.getText(TextToken.ScoreImpactInfo);
        return _this;
    }
    Object.defineProperty(TvmThreatCampaignsWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: 'threatCampaigsnWidget',
                name: this.i18nService.get('tvm.dashboard.threatCampaigns.title'),
                noDataMessage: this.i18nService.get("tvm.dashboard.noData.threatCampaigns"),
                noDataIcon: FabricIconNames.Trackers,
                NoIconLeftAlign: true,
                loadData: function () {
                    return _this.paris
                        .query(Threat, {
                        page: 1,
                        pageSize: 4,
                        sortBy: [
                            {
                                field: 'exposureScore',
                                direction: DataQuerySortDirection.descending,
                            },
                            {
                                field: 'secureScore',
                                direction: DataQuerySortDirection.descending,
                            },
                        ],
                    })
                        .pipe(map(function (ds) {
                        return ds.items
                            .sort(function (a, b) {
                            if (a.exposureScore === b.exposureScore) {
                                return (b.secureScore || 0) - (a.secureScore || 0);
                            }
                            return b.exposureScore - a.exposureScore;
                        })
                            .slice(0, 4);
                    }));
                },
                minHeight: '210px',
            };
        },
        enumerable: true,
        configurable: true
    });
    TvmThreatCampaignsWidget.prototype.openThreatCampaign = function (threat) {
        this.router.navigate(['/threatanalytics3', threat.id]);
    };
    TvmThreatCampaignsWidget.prototype.openThreatAnalyticsDashBoard = function () {
        this.router.navigate(['/threatanalytics3']);
    };
    return TvmThreatCampaignsWidget;
}(ReportWidgetComponent));
export { TvmThreatCampaignsWidget };
