import { Entity, EntityField } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { RecommendationContextBase } from './recommendation-context-base.entity';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Recommendation Context Key and Exposed Machines Count',
	pluralName: 'Recommendation Context Keys and Exposed Machines Counts',
	endpoint: 'configurations',
	readonly: true,
})
export class RecommendationContextKeyAndExposedMachinesCount extends RecommendationContextBase {
	@EntityField({
		data: 'ExposedMachines',
		parse: (o, rawData) => parseInt(rawData.ExposedMachines, 10),
		required: true,
	})
	readonly exposedMachines: number;

	@EntityField({ data: 'scId' })
	scId?: string;
}
