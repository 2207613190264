
		<ul
			[ngClass]="{
				'wcd-margin-large-top': shouldShowTitle,
				'wcd-margin-medium-horizontal': fullPanelView,
				'clear-list-style': true
			}"
		>
			<li *ngIf="dataList.length > 0 && shouldShowTitle" class="data-row-title">
				{{ i18n.get(titleKey, { count: dataList.length }) }}
			</li>
			<ng-template ngFor let-dataItem [ngForOf]="dataList" let-i="index">
				<li *ngIf="!openPanelForAllItems || i < 3" class="data-row">
					<div class="wcd-flex-horizontal wcd-flex-center-vertical">
						<div class="wcd-width-large wcd-margin-small-right">
							<wcd-shared-icon
								*ngIf="shouldShowIcon"
								[iconName]="iconName"
								class="small-icon wcd-margin-small-right"
							></wcd-shared-icon>
							{{ dataItem }}
						</div>
						<button
							*ngIf="shouldShowDeleteButton"
							type="button"
							class="btn-link"
							data-track-id="RemoveEmail"
							data-track-type="Button"
							(click)="onDeleteClicked(dataItem)"
							wcdTooltip="{{ i18n.strings.notificationRules_ruleEmails_remove_description }}"
							[attr.aria-label]="
								('notificationRules.ruleEmails.remove.title' | i18n) + ' ' + dataItem
							"
						>
							<wcd-shared-icon [iconName]="'delete'" class="small-icon"></wcd-shared-icon>
						</button>
					</div>
				</li>
			</ng-template>
		</ul>
		<div *ngIf="openPanelForAllItems && dataList.length > 3">
			<a
				data-track-type="SidePaneToggleButton"
				data-track-id="OpenAllDataListEntitiesPanel"
				(click)="openPanel()"
				(keydown.enter)="openPanel()"
				tabindex="0"
				role="button"
			>
				{{ i18n.strings[seeAllKey] }}
			</a>
		</div>
	