import { Entity, EntityField } from '@microsoft/paris';
import { RecommendationContextType } from '../common/recommendation/recommendation-context-type.enum';
import { RecommendationContextBase } from '../recommendation-context/recommendation-context-base.entity';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Asset Recommendation Context',
	pluralName: 'Asset Recommendation Contexts',
	endpoint: 'configurations',
	readonly: true,
})
export class AssetRecommendationContext extends RecommendationContextBase {
	@EntityField({ data: 'Path' })
	readonly path?: string;

	@EntityField({ data: 'SharePath' })
	readonly sharePath?: string;

	@EntityField({
		data: 'contextType',
		parse: (o, rawData) => {
			if (rawData.ServiceName) {
				if (rawData.Path) {
					return RecommendationContextType.ContextWithServiceNameAndServicePath;
				} else {
					return RecommendationContextType.ContextWithServiceName;
				}
			} else if (rawData.UserName) {
				return RecommendationContextType.ContextWithUserName;
			} else if (rawData.ShareName) {
				return RecommendationContextType.ContextWithShareNameAndSharePath;
			}
			return RecommendationContextType.Unknown;
		},
		required: true,
	})
	readonly contextType: RecommendationContextType;
}
