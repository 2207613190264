import { ChangeDetectorRef, ElementRef, EventEmitter, QueryList, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PanelType } from '../models/panel-type.enum';
import { Panel } from '../models/panel.model';
import { PanelService } from '../services/panels.service';
var lastId = 0;
var wcdPanelManger = [];
var PanelComponent = /** @class */ (function () {
    function PanelComponent(elementRef, changeDetectorRef, panelService) {
        this.elementRef = elementRef;
        this.changeDetectorRef = changeDetectorRef;
        this.panelService = panelService;
        this.close = new EventEmitter();
        this.startClose = new EventEmitter();
        this.onMenuActionClick = new EventEmitter();
        this.previous = new EventEmitter();
        this.next = new EventEmitter();
        this.isNotFocused = new EventEmitter();
        this.isOpen = true;
        this.disableTrapFocus = false;
        this.disableAutoFocus = false;
        this.disableHeaderRole = false;
        this.tryKeepFocusOnCurrent = false;
        this.isInit = false;
        this.isFocused = false;
        this.binnedFocusOnFirstfocusable = this.focusOnFirstfocusable.bind(this);
        this.TITLE_ELEMENT_ID = "side-panel-header-container-" + lastId++;
        this.titleId = '';
        this.headerRole = "heading";
        this.headerAriaLevel = 1;
    }
    Object.defineProperty(PanelComponent.prototype, "settings", {
        get: function () {
            return this._settings;
        },
        set: function (settings) {
            this.setSettings(settings);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PanelComponent.prototype, "panelTypeName", {
        get: function () {
            return this.settings && PanelType[this.settings.type];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(PanelComponent.prototype, "selectableActions", {
        get: function () {
            return (this.settings &&
                this.settings.menuActions &&
                this.settings.menuActions.filter(function (action) { return action.isSelectable; }));
        },
        enumerable: true,
        configurable: true
    });
    PanelComponent.prototype.onKeydown = function ($event) {
        if ($event.keyCode === 27) {
            // ESCAPE
            $event.preventDefault();
            $event.stopPropagation();
            $event.stopImmediatePropagation();
            this.panelService.panelRef[this.panelService.activeInstances - 1].instance.closePanel();
            return false;
        }
        return true;
    };
    PanelComponent.prototype.ngOnInit = function () {
        if (this.filterData) {
            this.isNotFocused.emit(true);
        }
        if (this.disableHeaderRole) {
            this.headerRole = null;
            this.headerAriaLevel = null;
        }
    };
    PanelComponent.prototype.setSettings = function (settings) {
        var _this = this;
        this._settings = settings;
        this.isFocused = false;
        setTimeout(function () {
            _this.changeDetectorRef.detectChanges();
            setTimeout(function () {
                _this.isInit = true;
                var headerElementId = '';
                if (_this.panelHeader && _this.panelHeader.nativeElement) {
                    var headerElement = _this.panelHeader.nativeElement;
                    if (!headerElement.id) {
                        headerElementId = 'panelHeader_' + Date.now();
                        headerElement.id = headerElementId;
                    }
                    else {
                        headerElementId = headerElement.id;
                    }
                }
                else {
                    // The id option is a fallback, since not all element can be found using the ContentChildren (for some reason...)
                    if (settings.headerElementId) {
                        var panelHeaderById = _this.elementRef.nativeElement.querySelector("#" + settings.headerElementId);
                        if (panelHeaderById) {
                            headerElementId = panelHeaderById.id;
                        }
                        else {
                            headerElementId = _this.panelHiddenHeader.nativeElement.id;
                        }
                    }
                    else if (_this.settings.headerText) {
                        headerElementId = _this.TITLE_ELEMENT_ID;
                    }
                    else {
                        headerElementId = _this.panelHiddenHeader.nativeElement.id;
                    }
                }
                _this.titleId = headerElementId;
                _this.changeDetectorRef.detectChanges();
                if (!_this.disableAutoFocus) {
                    _this.focusOnFirstfocusable();
                }
            }, 40);
        });
    };
    PanelComponent.prototype.closePanel = function (fromOverlay) {
        var _this = this;
        if (fromOverlay === void 0) { fromOverlay = false; }
        if (fromOverlay && this.settings.disableOverlayClick)
            return false;
        if (this.canDeactivate) {
            return this.canDeactivate().subscribe(function (_canDeactivate) {
                if (_canDeactivate)
                    _this.doClosePanel();
                else
                    return false;
            }, function (error) {
                return false;
            });
        }
        this.doClosePanel();
    };
    PanelComponent.prototype.doClosePanel = function () {
        var _this = this;
        this.isOpen = false;
        this.startClose.emit();
        this.changeDetectorRef.detectChanges();
        setTimeout(function () {
            _this.close.emit();
        }, 200);
    };
    PanelComponent.prototype.previousClick = function () {
        this.tryKeepFocusOnCurrent = true;
        if (!this.settings.previous.isDisabled)
            this.settings.previous.onClick();
    };
    PanelComponent.prototype.nextClick = function () {
        this.tryKeepFocusOnCurrent = true;
        if (!this.settings.next.isDisabled)
            this.settings.next.onClick();
    };
    PanelComponent.prototype.back = function () {
        this.tryKeepFocusOnCurrent = true;
        if (this.settings.back.onClick)
            this.settings.back.onClick();
        else
            window.history.back();
    };
    PanelComponent.prototype.overlayClicked = function (event) {
        if (event.target === this.panelOverlay.nativeElement) {
            this.closePanel(true);
        }
    };
    PanelComponent.prototype.focusOnFirstfocusable = function () {
        var _this = this;
        var focusable = this.focusable.filter(function (element) { return (!element.nativeElement.disabled); });
        var focused = focusable.find(function (elm) { return elm.nativeElement == document.activeElement; });
        if (this.tryKeepFocusOnCurrent && focused) {
            focused.nativeElement.blur();
        }
        else {
            focused = this.focusable.filter(function (element) { return !element.nativeElement.disabled; })[0];
            this.isFocused = true;
        }
        if (!focused)
            return;
        this.tryKeepFocusOnCurrent = false;
        if (this.filterData) {
            focused.nativeElement.focus();
            setTimeout(function () {
                _this.isNotFocused.emit(false);
            }, 150);
        }
        else {
            setTimeout(function () {
                focused.nativeElement.focus();
            }, 150);
        }
    };
    return PanelComponent;
}());
export { PanelComponent };
