/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../node_modules/ngx-markdown/ngx-markdown.ngfactory";
import * as i4 from "ngx-markdown";
import * as i5 from "./agent-offboarding.component";
var styles_AgentOffboardingComponent = [];
var RenderType_AgentOffboardingComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_AgentOffboardingComponent, data: {} });
export { RenderType_AgentOffboardingComponent as RenderType_AgentOffboardingComponent };
export function View_AgentOffboardingComponent_0(_l) { return i0.ɵvid(2, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 5, "div", [["class", "wcd-margin-large-bottom"], ["data-track-component", "Agent Offboarding"], ["data-track-component-type", "Settings"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(4, 0, null, null, 2, "markdown", [], null, null, null, i3.View_MarkdownComponent_0, i3.RenderType_MarkdownComponent)), i0.ɵdid(5, 4767744, null, 0, i4.MarkdownComponent, [i0.ElementRef, i4.MarkdownService], { data: [0, "data"] }, null), i0.ɵppd(6, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i0.ɵnov(_v, 0), "endpointManagement.agent.offboarding.description")); _ck(_v, 5, 0, currVal_0); }, null); }
export function View_AgentOffboardingComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "agent-offboarding", [], null, null, null, View_AgentOffboardingComponent_0, RenderType_AgentOffboardingComponent)), i0.ɵdid(1, 49152, null, 0, i5.AgentOffboardingComponent, [], null, null)], null, null); }
var AgentOffboardingComponentNgFactory = i0.ɵccf("agent-offboarding", i5.AgentOffboardingComponent, View_AgentOffboardingComponent_Host_0, {}, {}, []);
export { AgentOffboardingComponentNgFactory as AgentOffboardingComponentNgFactory };
