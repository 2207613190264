export const enum MailClusterEntityAlertV3Fields {
	IsVolumeAnamoly = 'IsVolumeAnamoly', // TODO: this has a typo, should be removed
	IsVolumeAnomaly = 'IsVolumeAnomaly',
	MailCount = 'MailCount',
	CountByThreatType = 'CountByThreatType',
	CountByProtectionStatus = 'CountByProtectionStatus',
	CountByDeliveryLocation = 'CountByDeliveryLocation',
	ClusterBy = 'ClusterBy',
	ClusterByValue = 'ClusterByValue',
	Query = 'Query',
	NetworkMessageIds = 'NetworkMessageIds',
	InternalId = 'InternalId',
	QueryTime = 'QueryTime',
	Urn = 'Urn',
	Threats = 'Threats',
	MdoBatchId = 'MdoBatchId', // TODO: should be added to AlertV3 schema in the future
	MdoRemediationId = 'MdoRemediationId', // TODO: should be added to AlertV3 schema in the future
	MdoInternalId = 'MdoInternalId', // TODO: should be added to AlertV3 schema in the future
}

export function mapEmailClusterEntityToAirsEntity(entityData): any {
	return Object.entries(entityData).reduce((acc, [key, val]) => {
		try {
			switch (key) {
				// TODO: this has a typo, should be removed
				case MailClusterEntityAlertV3Fields.IsVolumeAnamoly:
				case MailClusterEntityAlertV3Fields.IsVolumeAnomaly:
					acc['volume_anomaly'] = val ? 'Yes' : 'No';
					break;
				case MailClusterEntityAlertV3Fields.MailCount:
					acc['mail_count'] = val;
					break;
				case MailClusterEntityAlertV3Fields.CountByThreatType:
					acc['count_by_threat_type'] = val;
					break;
				case MailClusterEntityAlertV3Fields.CountByProtectionStatus:
					acc['count_by_protection_status'] = val;
					break;
				case MailClusterEntityAlertV3Fields.CountByDeliveryLocation:
					acc['count_by_delivery_location'] = val;
					break;
				case MailClusterEntityAlertV3Fields.ClusterBy:
					acc['clustered_by'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case MailClusterEntityAlertV3Fields.ClusterByValue:
					acc['source'] = val;
					acc['cluster_by_value'] = val;
					acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
					break;
				case MailClusterEntityAlertV3Fields.Query:
					acc['query'] = val;
					break;
				case MailClusterEntityAlertV3Fields.NetworkMessageIds:
					acc['network_message_ids'] = val;
					break;
				case MailClusterEntityAlertV3Fields.InternalId:
					acc['internal_id'] = val;
					break;
				case MailClusterEntityAlertV3Fields.QueryTime:
					acc['query_time'] = val;
					break;
				case MailClusterEntityAlertV3Fields.Threats:
					acc['threats'] = val;
					break;
				case MailClusterEntityAlertV3Fields.MdoBatchId:
					acc['mdo_batch_id'] = val;
					break;
				case MailClusterEntityAlertV3Fields.MdoRemediationId:
					acc['mdo_remediation_id'] = val;
					break;
				case MailClusterEntityAlertV3Fields.MdoInternalId:
					acc['mdo_internal_id'] = val;
					break;
				default:
					acc[key] = val;
			}
		} catch (e) {}
		return acc;
	}, {});
}
