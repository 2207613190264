/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./wcd-icon.component";
var styles_WcdIconComponent = [];
var RenderType_WcdIconComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_WcdIconComponent, data: {} });
export { RenderType_WcdIconComponent as RenderType_WcdIconComponent };
export function View_WcdIconComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 2, "i", [], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(3, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵinlineInterpolate(1, "wcd-icons--", _co.iconName, ""); var currVal_1 = _co.className; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_WcdIconComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-icon", [], null, null, null, View_WcdIconComponent_0, RenderType_WcdIconComponent)), i0.ɵdid(1, 49152, null, 0, i2.WcdIconComponent, [i0.ChangeDetectorRef], null, null)], null, null); }
var WcdIconComponentNgFactory = i0.ɵccf("wcd-icon", i2.WcdIconComponent, View_WcdIconComponent_Host_0, { iconName: "iconName", className: "className" }, {}, []);
export { WcdIconComponentNgFactory as WcdIconComponentNgFactory };
