import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	Output,
	OnInit,
} from '@angular/core';
import { catchError, filter, map, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { BehaviorSubject, combineLatest, defer, Observable, of, throwError, EMPTY } from 'rxjs';
import {
	File,
	FileFileStatsRelationship,
	FileMailStatsRelationship,
	MailStats,
	EntityStatistics,
} from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { SpecificTimeRangeValue, TimeRangeId } from '@wcd/date-time-picker';
import { AppConfigService } from '@wcd/app-config';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FilePrevalenceRelationship } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';

const loadingSymbol: unique symbol = Symbol();
type Loadable<T> = T | Symbol;

@Component({
	selector: 'file-prevalence',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngIf="(data$ | async) as data; else noData">
			<div
				*ngIf="!summaryView; else summary"
				class="wcd-flex-vertical wcd-full-height wcd-m365-typography"
			>
				<div *ngIf="data.isO365IntegrationEnabled && !isNewFilePageEnabled" class="wcd-margin-small-bottom">
					<fab-shimmer [isDataLoaded]="data.mailStats !== loadingSymbol">
						<h4
							*ngIf="data.mailStats && data.mailStats !== loadingSymbol"
							class="wcd-margin-none-bottom"
						>
							{{
								'file.mailStats.emailInboxes'
									| i18n
										: {
												numberOfInboxes:
													data?.mailStats?.numberOfInboxes | prettyNumber
										  }
							}}
						</h4>
					</fab-shimmer>
					<fab-shimmer
						[isDataLoaded]="data.mailStats !== loadingSymbol"
						[styles]="secondaryTextShimmerStyle"
					>
						<div
							*ngIf="data.mailStats && data.mailStats !== loadingSymbol"
							class="wcd-flex-horizontal-align-items-end"
						>
							<div class="wcd-secondary-text">
								<span *ngIf="data.mailStats.sender">
									{{ 'file.mailStats.sender' | i18n: { sender: data?.mailStats?.sender } }}
									|
								</span>
								<span *ngIf="data.mailStats.firstSeen">
									{{ 'files.fields.firstSeen.title' | i18n }}:
									{{ data.mailStats.firstSeen | timeAgo }}
								</span>
							</div>
							<a
								*ngIf="data.mailStats.deepLinkToO365Portal"
								href="{{ data.mailStats.deepLinkToO365Portal }}"
								class="wcd-margin-small-left"
								data-track-id="deepLinkToO365Portal"
								data-track-type="Navigation"
							>
								{{ 'file.mailStats.openInO365' | i18n }}
							</a>
						</div>
					</fab-shimmer>
				</div>
				<ng-container *ngTemplateOutlet="stats"></ng-container>
			</div>
			<ng-template #summary>
				<div class="wcd-flex-horizontal">
					<div
						*ngIf="data.isO365IntegrationEnabled && data?.mailStats?.numberOfInboxes != null"
						class="wcd-flex-1"
					>
						<distribution-element
							[title]="'file.mailStats.emailInboxesSummary' | i18n"
							[mainText]="data?.mailStats?.numberOfInboxes | prettyNumber"
						>
						</distribution-element>
						<div class="wcd-secondary-text">
							<ng-container *ngIf="data?.mailStats?.numberOfInboxes > 0">
								{{ 'files.fields.firstSeen.title' | i18n }}:
								{{ data.mailStats.firstSeen | timeAgo }}
								<br />
								{{ 'files.fields.lastSeen.title' | i18n }}:
								{{ data.mailStats.lastSeen | timeAgo }}
							</ng-container>
						</div>
					</div>
					<ng-container
						*ngTemplateOutlet="stats; context: { className: 'wcd-flex-3' }"
					></ng-container>
				</div>
			</ng-template>
			<ng-template #stats let-className="className">
				<ng-container *ngIf="!supportNewLayout || !isNewFilePageEnabled; else newFilePrevalence">
					<entity-statistics
						[ngClass]="className"
						[statistics]="data.fileStats"
						[isLoadingOrganizationPrevalence]="data.isLoadingOrganizationFileStats"
						[summaryView]="summaryView"
						[timeRange]="currentOrgPrevDateRange.id"
						(timeRangeChange)="onOrgPrevDateRangeChange($event)"
						[prevalenceBuckets]="filePrevalenceBuckets"
					></entity-statistics>
				</ng-container>
				<ng-template #newFilePrevalence>
					<entity-prevalence-statistics
						[ngClass]="className"
						[mailStats]="data.mailStats"
						[statistics]="data.fileStats"
						[prevalenceBuckets]="filePrevalenceBuckets"
						[file]="_file"
						[isLoadingOrganizationPrevalence]="data.isLoadingOrganizationFileStats"
					></entity-prevalence-statistics>
				</ng-template>
			</ng-template>
		</ng-container>
		<ng-template #noData>
			<h4>{{ 'reports.widgets.prevalence.noData' | i18n }}</h4>
		</ng-template>
	`,
})
export class FilePrevalenceComponent implements OnInit {
	private _file: File;
	private tenantPrefix$: Observable<string>;
	private mailStats$: Observable<Loadable<MailStats>>;
	private fileStats$: Observable<Loadable<EntityStatistics>>;
	private fileStatsRelationshipTypeToUse: Function;
	filePrevalenceBuckets: number[];
	isNewFilePageEnabled: boolean;
	fileOrgPrevalenceDateRangeChange$: BehaviorSubject<{
		newRangeValue: SpecificTimeRangeValue;
		oldRangeValue: SpecificTimeRangeValue;
	}>;

	loadingSymbol = loadingSymbol;
	currentOrgPrevDateRange: SpecificTimeRangeValue;

	secondaryTextShimmerStyle = {
		shimmerWrapper: {
			height: 10,
			maxWidth: '65%',
		},
		root: {
			marginTop: 4,
		},
	};

	data$: Observable<
		Loadable<{
			fileStats: Loadable<EntityStatistics>;
			mailStats: Loadable<MailStats>;
			isLoadingOrganizationFileStats: boolean;
		}>
	>;

	@Input() summaryView: boolean = false;
	@Input() supportNewLayout:boolean = false;

	@Input()
	set file(value: File) {
		if (!this.isSameFile(value)) {
			this._file = value;
			this.setMailObservable();
			this.setFileObservable();
			this.setDataObservable();
		}
	}

	@Output() error = new EventEmitter<Error>();

	constructor(
		private readonly paris: Paris,
		private readonly timeRangeService: TimeRangesService,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly officeIntegrationService: OfficeIntegrationService,
		private readonly appConfigService: AppConfigService,
		private readonly appInsightService: AppInsightsService,
		private readonly featuresService: FeaturesService
	) {
		this.currentOrgPrevDateRange = <SpecificTimeRangeValue>(
			this.timeRangeService.pick([TimeRangeId.month])[0]
		);

		this.fileOrgPrevalenceDateRangeChange$ = new BehaviorSubject({
			newRangeValue: this.currentOrgPrevDateRange,
			oldRangeValue: this.currentOrgPrevDateRange,
		});

		this.tenantPrefix$ = this.officeIntegrationService.isIntegrationEnabled$.pipe(
			filter(isEnabled => isEnabled),
			switchMap(_ => this.officeIntegrationService.getOfficeTenantUrlPrefix()),
			filter(tenantPrefix => Boolean(tenantPrefix)),
			shareReplay({ bufferSize: 1, refCount: true }),
			catchError(error => {
				this.appInsightService.trackException(error, 'officeIntegrationError');
				return EMPTY;
			})
		);
		this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
	}

	ngOnInit() {
		// TODO - always use FilePrevalenceRelationship when once FilePrevalenceAndTopNamesNewApi fully enabled.
		this.fileStatsRelationshipTypeToUse = this.featuresService.isEnabled(
			Feature.FilePrevalenceAndTopNamesNewApi
		)
			? FilePrevalenceRelationship
			: FileFileStatsRelationship;

		this.filePrevalenceBuckets = this.featuresService.isEnabled(Feature.FilePrevalenceBuckets)
			? [1000, 5000, 10000, 20000, 50000]
			: null;

	}

	private setMailObservable() {
		if (!this._file) {
			this.mailStats$ = null;
		} else {
			this.mailStats$ = this.tenantPrefix$.pipe(
				switchMap(officeTenantPrefix =>
					this.paris.getRelatedItem<File, MailStats>(FileMailStatsRelationship, this._file, {
						where: {
							officeTenantPrefix: officeTenantPrefix,
							vNextApi: this.featuresService.isEnabled('K8SMigration-EPSFilesMailStats-kw')
						},
					})
				),
				catchError(error => {
					this.appInsightService.trackException(error, 'mailStatsDeepLinkToO365Portal');
					return of(null);
				}),
				startWith(loadingSymbol)
			);
		}
		this.changeDetectorRef.markForCheck();
	}

	private setFileObservable() {
		if (!this._file) {
			this.fileStats$ = null;
		} else {
			this.fileStats$ = defer(() => {
				let data: EntityStatistics;

				return this.fileOrgPrevalenceDateRangeChange$.pipe(
					switchMap(timeRangeChangeObject =>
						this.paris
							.getRelatedItem<File, EntityStatistics>(
								this.fileStatsRelationshipTypeToUse,
								this._file,
								{
									where: {
										lookingBackInDays: timeRangeChangeObject.newRangeValue.value,
										//relevant only for FileStats, ignored by FilePrevalence
										filesPrefix: this.featuresService.isEnabled('K8SMigration-EPSFilePrevalence-kw')
									},
								}
							)
							.pipe(
								tap(
									_data => {
										data = _data;
									},
									err => {
										// set the previous date range back to the range relevant to the existing data
										this.currentOrgPrevDateRange = timeRangeChangeObject.oldRangeValue;
										this.changeDetectorRef.markForCheck();
									}
								),
								catchError(err => {
									if (data) {
										return of(data);
									}
									return throwError(err);
								}),
								startWith(loadingSymbol)
							)
					)
				);
			});
		}
		this.changeDetectorRef.markForCheck();
	}

	private setDataObservable() {
		if (!this._file) {
			this.data$ = null;
		} else {
			this.data$ = defer(() => {
				let _fileStats: EntityStatistics;

				return combineLatest(
					this.fileStats$,
					this.mailStats$,
					this.tenantPrefix$.pipe(startWith(undefined))
				).pipe(
					map(([fileStats, mailStats, tenantPrefix]) => {
						const isLoadingOrganizationFileStats = fileStats === loadingSymbol;

						if (!_fileStats && !isLoadingOrganizationFileStats) {
							// setting cached file stats data
							_fileStats = fileStats as EntityStatistics;
						}

						if (_fileStats) {
							// Keep worldwide file stats data.
							// This is done since the current BE api returns worldwide and organization stats together
							// while the date range should only be applied on the organization prevalence stats.
							fileStats = isLoadingOrganizationFileStats
								? _fileStats
								: {
										...fileStats,
										worldwideFirstSeen: _fileStats.worldwideFirstSeen,
										worldwideLastSeen: _fileStats.worldwideLastSeen,
										worldwidePrevalence: _fileStats.worldwidePrevalence,
								  };
						}

						if (fileStats || mailStats) {
							return {
								fileStats,
								mailStats,
								isO365IntegrationEnabled: !!tenantPrefix,
								isLoadingOrganizationFileStats,
							};
						} else {
							return null;
						}
					}),
					tap({
						error: err => {
							this.error.emit(err);
						},
					}),
					startWith(loadingSymbol)
				);
			});
		}
		this.changeDetectorRef.markForCheck();
	}

	onOrgPrevDateRangeChange(timeRange: SpecificTimeRangeValue) {
		if (this.currentOrgPrevDateRange !== timeRange) {
			const timeRangeChangeObject = {
				newRangeValue: timeRange,
				oldRangeValue: this.currentOrgPrevDateRange,
			};
			this.currentOrgPrevDateRange = timeRangeChangeObject.newRangeValue;
			this.fileOrgPrevalenceDateRangeChange$.next(timeRangeChangeObject);
		}
	}

	private isSameFile(file: File): boolean {
		return (
			(this._file && this._file.sha1) === (file && file.sha1) &&
			(this._file && this._file.sha256) === (file && file.sha256)
		);
	}
}
