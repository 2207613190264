import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { GeneralSettingsComponent } from './general-settings.component';
import { FabChoiceGroupModule, FabMessageBarModule } from '@angular-react/fabric';

@NgModule({
	imports: [SharedModule, FabMessageBarModule, FabChoiceGroupModule],
	declarations: [GeneralSettingsComponent],
})
export class GeneralSettingsModule {}
