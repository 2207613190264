import { NgModule } from '@angular/core';
import { FabIconModule, FabSpinnerModule, FabTextFieldModule } from '@angular-react/fabric';
import { SharedModule } from '../../shared/shared.module';
import { WebContentFilteringPolicyService } from './services/web-content-filtering-policy.service';
import { WebContentFilteringPolicyEntityTypeService } from './services/web-content-filtering-policy.entity-type.service';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { WebContentFilteringPolicyNewModalComponent } from './components/web-content-filtering-new.modal.component';
import { WebContentFilteringPolicyNewComponent } from './components/web-content-filtering-policy-new.component';
import { WebContentFilteringPolicyEditComponent } from './components/web-content-filtering-policy-edit.component';
import { FiltersModule } from '@wcd/ng-filters';
import { PanelsModule } from '@wcd/panels';

@NgModule({
	imports: [SharedModule, FabIconModule, FabSpinnerModule, FabTextFieldModule, FiltersModule, PanelsModule],
	declarations: [
		WebContentFilteringPolicyNewComponent,
		WebContentFilteringPolicyEditComponent,
		WebContentFilteringPolicyNewModalComponent,
	],
	providers: [WebContentFilteringPolicyService, WebContentFilteringPolicyEntityTypeService],
	entryComponents: [WebContentFilteringPolicyEditComponent, WebContentFilteringPolicyNewModalComponent],
})
export class WebContentFilteringPolicyEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(WebContentFilteringPolicyEntityTypeService);
	}
}
