
		<div
			class="pie-chart-wrapper"
			[id]="'pie-chart-'+id"
			[class.wcd-full-height]="isFullHeight"
			[attr.aria-label]="ariaLabel || ('charts.pieChart' | i18n)"
			[class.wcd-pie-align-center]="alignCenter"
		>
			<div
				class="chart"
				[class.wcd-full-height]="isFullHeight"
				[attr.data-track-id]="settings.tracking?.id"
				[attr.data-track-type]="settings.tracking?.type"
			></div>
			<div class="pie-chart-content">
				<ng-content></ng-content>
			</div>
		</div>
	