var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DataviewField } from '@wcd/dataview';
import { WeaknessSeverity } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TvmWeaknessSeverityService } from '../../../../tvm/services/tvm-vulnerability-severity.service';
import { TzDateService } from '@wcd/localization';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { I18nService } from '@wcd/i18n';
import { TvmTextsService, TextToken, InaccuracyContext } from '../../../../tvm/services/tvm-texts.service';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { PrettyNumberService } from '@wcd/prettify';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';
var MACHINE_VULNERABILITY_FIELDS = [
    'severity',
    'cvssV3',
    'productIds',
    'publishedOn',
    'updatedOn',
    'threats',
];
var DEFAULT_SIDE_PANEL_FIELDS = ['name', 'severity', 'numOfImpactedAssets'];
var VulnerabilityFieldsService = /** @class */ (function () {
    function VulnerabilityFieldsService(prettyNumberService, tvmWeaknessSeverityService, tzDateService, i18nService, tvmTextsService, tvmIconBuilderService, tvmTagsService, tvmTaggedFieldService, featuresService) {
        var _this = this;
        this.prettyNumberService = prettyNumberService;
        this.tvmWeaknessSeverityService = tvmWeaknessSeverityService;
        this.tzDateService = tzDateService;
        this.i18nService = i18nService;
        this.tvmTextsService = tvmTextsService;
        this.tvmIconBuilderService = tvmIconBuilderService;
        this.tvmTagsService = tvmTagsService;
        this.tvmTaggedFieldService = tvmTaggedFieldService;
        this._nameField = {
            id: 'name',
            name: this.i18nService.get('tvm.common.name'),
            component: {
                type: TaggedFieldComponent,
                getProps: function (vulnerability) {
                    return _this.tvmTaggedFieldService.getZeroDayBaseProps(vulnerability.mostSevereVulnerabilityType, vulnerability.patchReleaseDate, vulnerability.name);
                },
            },
        };
        this._nameFieldWithInaccuracyContext = __assign({}, this._nameField, { component: __assign({}, this._nameField.component, { getProps: function (vulnerability) { return (__assign({}, _this._nameField.component.getProps(vulnerability), { inaccuracyContext: InaccuracyContext.MachineVulnerabilities, contextObject: vulnerability, title: vulnerability.name })); } }) });
        this._severityField = {
            id: 'severity',
            name: this.i18nService.get('tvm.common.severity'),
            getDisplay: function (vulnerability) {
                return [WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(vulnerability.severity)
                    ? _this.i18nService.get('common.noDataAvailable')
                    : vulnerability.severity;
            },
            icon: {
                fabricIcon: function (vulnerability) {
                    return [WeaknessSeverity.None, WeaknessSeverity.Unknown].includes(vulnerability.severity)
                        ? null
                        : FabricIconNames.Warning;
                },
                className: function (vulnerability) {
                    return _this.tvmWeaknessSeverityService.getWeaknessColorClass(vulnerability.severity);
                },
            },
            sort: {
                sortDescendingByDefault: true,
            },
        };
        this._impactedAssetsField = {
            id: 'numOfImpactedAssets',
            name: this.i18nService.get('tvm.common.exposedDevices'),
            getDisplay: function (vulnerability) {
                return Number.isInteger(vulnerability.impactedAssetsCount)
                    ? _this.prettyNumberService.prettyNumber(vulnerability.impactedAssetsCount)
                    : _this.i18nService.get('notAvailable.long');
            },
            sort: {
                sortDescendingByDefault: true,
            },
        };
        this._threatsField = {
            id: 'threats',
            name: this.i18nService.get('tvm.common.threats'),
            sort: {
                enabled: false,
            },
            component: {
                type: TvmThreatIconsComponent,
                getProps: function (vulnerability) {
                    return _this.tvmIconBuilderService.configureIcons(vulnerability.threatInfo, null, 'vulnerability', false, null, true);
                },
            },
        };
        this.zeroDayEnabled = featuresService.isEnabled(Feature.TvmZeroDay);
    }
    Object.defineProperty(VulnerabilityFieldsService.prototype, "defaultSidePanelFieldsConfig", {
        get: function () {
            return [this._nameField, this._severityField, this._impactedAssetsField];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VulnerabilityFieldsService.prototype, "changeEventFieldsConfig", {
        get: function () {
            return [this._nameField, this._severityField, this._threatsField];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VulnerabilityFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    this._nameField,
                    this._severityField,
                    {
                        id: 'cvssV3',
                        name: this.i18nService.get('tvm.common.cvss'),
                        getDisplay: function (vulnerability) {
                            return vulnerability.cvss || _this.i18nService.get('notAvailable.short');
                        },
                    },
                    {
                        id: 'productIds',
                        name: this.i18nService.get('tvm.vulnerability.relatedSoftware'),
                        getDisplay: function (vulnerability) {
                            return _this.tvmTextsService.getText(TextToken.RelatedSoftware, vulnerability);
                        },
                        getTooltip: function (vulnerability) {
                            return _this.tvmTextsService.getText(TextToken.RelatedSoftwareTooltip, vulnerability);
                        },
                        valueTooltipAllowHtmlRendering: true,
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'age',
                        name: this.i18nService.get('tvm.vulnerability.age'),
                        getDisplay: function (vulnerability) {
                            return _this.tvmTextsService.getText(TextToken.VulnerabilityAge, vulnerability);
                        },
                    },
                    {
                        id: 'publishedOn',
                        name: this.i18nService.get('tvm.vulnerability.publishedOn'),
                        getDisplay: function (vulnerability) {
                            return _this.tzDateService.format(vulnerability.published, 'shortDate');
                        },
                    },
                    {
                        id: 'updatedOn',
                        name: this.i18nService.get('tvm.vulnerability.updatedOn'),
                        getDisplay: function (vulnerability) {
                            return _this.tzDateService.format(vulnerability.updated, 'shortDate');
                        },
                    },
                    this._threatsField,
                    this._impactedAssetsField,
                    this.zeroDayEnabled
                        ? {
                            id: 'tags',
                            name: this.i18nService.get('common.tags'),
                            component: {
                                type: TagsFieldComponent,
                                getProps: function (vulnerability) { return ({
                                    tags: _this.tvmTagsService.getVulnerabilityTags(vulnerability),
                                }); },
                            },
                            sort: { enabled: false },
                        }
                        : null,
                ]).filter(Boolean);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    VulnerabilityFieldsService.prototype.createClickableNameField = function (onNameClick) {
        var _this = this;
        return __assign({}, this._nameField, { component: {
                type: this._nameField.component.type,
                getProps: function (vulnerability) { return (__assign({}, _this._nameField.component.getProps(vulnerability), { onClick: function () { return onNameClick(vulnerability); } })); },
            } });
    };
    Object.defineProperty(VulnerabilityFieldsService.prototype, "defaultSidePanelFields", {
        get: function () {
            return this.fields.filter(function (field) { return DEFAULT_SIDE_PANEL_FIELDS.includes(field.id); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VulnerabilityFieldsService.prototype, "machineVulnerabilityFields", {
        get: function () {
            return [
                DataviewField.fromList([this._nameFieldWithInaccuracyContext])[0]
            ].concat(this.fields.filter(function (field) { return MACHINE_VULNERABILITY_FIELDS.includes(field.id); }));
        },
        enumerable: true,
        configurable: true
    });
    return VulnerabilityFieldsService;
}());
export { VulnerabilityFieldsService };
