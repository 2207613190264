import { Injectable } from '@angular/core';
import { some } from 'lodash-es';
import { I18nService } from '@wcd/i18n';

/**
 * Map message keys sent from the backend to i18n keys
 */
enum HuntingMessageKeys {
	'HUNTING_QUERY_SYNTAX_ERROR' = 'hunting_validateScheduledQuerySchema_errors_syntaxError',
	'HUNTING_EMPTY_QUERY_NAME' = 'hunting_saveQueryDialog_errors_emptyName',
	'HUNTING_QUERY_NAME_TOO_LONG' = 'hunting_saveQueryDialog_errors_nameTooLong',
	'HUNTING_QUERY_PATH_TOO_LONG' = 'hunting_saveQueryDialog_errors_pathTooLong',
	'HUNTING_QUERY_PATH_TOO_MANY_LEVELS' = 'hunting_saveQueryDialog_errors_tooManyPathLevels',
	'HUNTING_EMPTY_QUERY_TEXT' = 'hunting_saveQueryDialog_errors_emptyQueryText',
	'HUNTING_QUERY_MISSING_COLUMNS' = 'hunting_validateScheduledQuerySchema_errors_missingFields',
	'HUNTING_QUERY_NO_IMPACTED_ENTITIES' = 'hunting_validateScheduledQuerySchema_errors_noImpactedEntities',
	'HUNTING_QUERY_MISSING_RULE_ENTITY_OR_ACTION' = 'hunting_validateScheduledQuerySchema_errors_missingRuleEntityOrAction',
	'HUNTING_RULE_MISSING_PERMISSION' = 'common_permissions_noPermissionForActionDescription'
}

@Injectable()
export class ScheduledHuntingErrorParsingService {
	constructor(private readonly i18nService: I18nService) {}

	parseError(
		error: ScheduledQueryApiError,
		showExtendedErrorMessage: boolean = false
	): ScheduledHuntingError {
		if (error.status === 409) {
			return new ScheduledHuntingError(
				error,
				this.i18nService.get('hunting.saveQueryDialog.errors.conflict')
			);
		}
		if (error.status === 401 || error.status === 403) {
			return new ScheduledHuntingError(
				error,
				this.i18nService.get('common_permissions_noPermissionForActionDescription')
			);
		}

		switch (error.message.Type) {
			case 'InvalidSchema':
				return new ScheduledHuntingErrorInvalidSchema(
					error,
					this.getLocalizedMessage(error.message.LocalizableMessage) || this.i18nService.get('hunting.validateScheduledQuerySchema.errors.missingFields'),
					showExtendedErrorMessage
				);
			case 'SyntaxError':
				return new ScheduledHuntingError(
					error,
					this.i18nService.get('hunting.validateScheduledQuerySchema.errors.syntaxError')
				);
			case 'InvalidParameter':
				return new ScheduledHuntingError(
					error,
					this.getLocalizedMessage(error.message.LocalizableMessage) || error.message.ErrorMessage
				);
			case 'Unauthorized':
				return new ScheduledHuntingError(
					error,
					this.i18nService.get('common_permissions_noPermissionForActionDescription')
				);
			default:
				return new ScheduledHuntingError(
					error,
					this.getLocalizedMessage(error.message.LocalizableMessage)
				);
		}
	}

	private getLocalizedMessage(message: LocalizableMessage): string {
		const localizedMessage =
			message &&
			message.MessageKey &&
			HuntingMessageKeys[message.MessageKey] &&
			this.i18nService.get(HuntingMessageKeys[message.MessageKey], message.Parameters);
		return localizedMessage || (message && message.AltText);
	}
}

export interface ScheduledQueryApiError {
	readonly status: number;
	readonly message: ScheduledQueryApiErrorMessage;
}

export function getScheduledQueryError(error: any): ScheduledQueryApiError | null {
	if (isScheduledQueryApiError(error)) {
		return error;
	}

	// the proxy server changes the structure of the response we are getting, from ajaxError to axiosResponse.
	if (error.response && error.response.status != undefined && error.response.data && error.response.data.Type) {
		return { status: error.response.status, message: error.response.data };
	}

	return null;
}

function isScheduledQueryApiError(error: any): error is ScheduledQueryApiError {
	return error && typeof error === 'object' && error.status === 400 && error.message && error.message.Type;
}

export type ScheduledHuntingQueryErrorType = 'InvalidSchema' | 'SyntaxError' | 'InvalidParameter' | 'Unauthorized';

export interface LocalizableMessage {
	readonly MessageKey: string;
	readonly Parameters: object;
	readonly AltText: string;
}

export interface ScheduledQueryApiErrorMessage {
	readonly Type: ScheduledHuntingQueryErrorType;
	readonly AdditionalInfo?: object;
	readonly ErrorMessage?: string;
	readonly LocalizableMessage: LocalizableMessage;
}

export class ScheduledHuntingError {
	readonly type: ScheduledHuntingQueryErrorType;

	constructor(error: ScheduledQueryApiError, public errorMessage: string) {
		this.type = error.message.Type;
	}
}

export class ScheduledHuntingErrorInvalidSchema extends ScheduledHuntingError {
	readonly type: 'InvalidSchema';
	readonly requiredQueryFields: Readonly<Record<string, boolean>>;

	constructor(
		error: ScheduledQueryApiError,
		errorMessage: string,
		showExtendedErrorMessage: boolean = false
	) {
		super(error, errorMessage);

		this.requiredQueryFields = <{ [index: string]: any }>error.message.AdditionalInfo || {};

		if (showExtendedErrorMessage) {
			this.errorMessage = `${errorMessage} ${this.missingFieldNames.join(', ')}`;
		}
	}

	get areFieldsMissing(): boolean {
		return some(this.requiredQueryFields, f => !f);
	}

	get requiredQueryFieldNames(): ReadonlyArray<string> {
		return Object.keys(this.requiredQueryFields);
	}

	get missingFieldNames(): ReadonlyArray<string> {
		return Object.keys(this.requiredQueryFields).filter(k => !this.requiredQueryFields[k]);
	}
}
