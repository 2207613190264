/* tslint:disable:template-accessibility-label-for */
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	forwardRef,
	Input,
	NgZone,
	OnInit,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {DateRangeModel, TzDateService} from '@wcd/localization';

const DATERANGE_CUSTOM_ACCESSOR = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => DateRangeComponent),
	multi: true,
};

@Component({
	selector: 'daterange',
	template: `
		<div [ngClass]="verticalDirection ? 'vertical-daterange' : 'daterange'" *ngIf="dateRange">
			<label>
				<span
					[ngClass]="verticalDirection ? 'vertical-daterange-label' : 'daterange-label'"
					(ngModelChange)="onChange(dateRange)"
				>
					{{ 'common.datepicker.from' | i18n }}
				</span>
				<datepicker
					[(ngModel)]="dateRange.from"
					(ngModelChange)="onChange(dateRange)"
					[allowExactTimeSelection]="allowExactTimeSelection"
					[earliestDateAllowed]="earliestDateAllowed"
					[latestDateAllowed]="latestDateAllowed"
					[ariaLabel]="'common.datepicker.from' | i18n"
					[showInPanel]="showInPanel"
				></datepicker>
			</label>
			<label>
				<span [ngClass]="verticalDirection ? 'vertical-daterange-label' : 'daterange-label'">{{
					'common.datepicker.to' | i18n
				}}</span>
				<datepicker
					class="daterange-datepicker"
					[(ngModel)]="dateRange.to"
					(ngModelChange)="onChangeTo(dateRange)"
					[allowExactTimeSelection]="allowExactTimeSelection"
					[earliestDateAllowed]="earliestDateAllowedTo"
					[latestDateAllowed]="latestDateAllowedTo"
					[ariaLabel]="'common.datepicker.to' | i18n"
					[showInPanel]="showInPanel"
				></datepicker>
			</label>
		</div>
	`,
	providers: [DATERANGE_CUSTOM_ACCESSOR],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateRangeComponent implements ControlValueAccessor, OnInit {
	dateRange: DateRangeModel;

	get value(): DateRangeModel {
		return this.dateRange;
	}

	set value(dateRange: DateRangeModel) {
		if (dateRange !== this.dateRange) {
			this.dateRange = dateRange;
			this.onChange(dateRange);
		}
	}

	@Input() allowExactTimeSelection: boolean = false;
	@Input() earliestDateAllowed: Date = null;
	@Input() latestDateAllowed: Date = null;
	@Input() earliestDateAllowedTo: Date = this.earliestDateAllowed;
	@Input() latestDateAllowedTo: Date = this.latestDateAllowed;
	@Input() showInPanel: boolean = false;
	@Input() verticalDirection: boolean = false;

	constructor(private readonly ngZone: NgZone, private readonly changeDetectorRef: ChangeDetectorRef, private tzDateService: TzDateService) {}

	ngOnInit() {
		if (!this.dateRange) {
			this.dateRange = new DateRangeModel(this.tzDateService, new Date(), new Date());
		}
	}

	onChange = (_: any) => {};
	onTouched = () => {};

	writeValue(value: DateRangeModel): void {
		this.ngZone.run(() => {
			this.dateRange = value || new DateRangeModel(this.tzDateService, new Date(), new Date());
			this.changeDetectorRef.detectChanges();
		});
	}

	onChangeTo() {
		if (!this.allowExactTimeSelection) {
			this.dateRange.to = DateRangeModel.endOfDay(this.dateRange.to);
		}
		this.onChange(this.dateRange);
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}
}
