import { IIncidentStatus, IncidentStatusType } from './incident-status.models';

export const incidentStatusValues: Array<IIncidentStatus> = [
	{
		id: 1,
		name: 'Active',
		type: IncidentStatusType.Open,
		priority: 1,
		className: 'attention',
		isOpen: true,
		nameI18nKey: 'incident_status_values_Active',
	},
	{
		id: 2,
		name: 'Resolved',
		type: IncidentStatusType.Closed,
		priority: 3,
		className: 'success',
		isOpen: false,
		nameI18nKey: 'incident_status_values_Resolved',
	},
	{
		id: 4,
		name: 'In Progress',
		type: IncidentStatusType.InProgress,
		priority: 2,
		className: 'sync',
		isOpen: true,
		nameI18nKey: 'incident_status_values_InProgress',
	},
];

export enum IncidentStatusValueIds {
	IncidentActiveStatusId = incidentStatusValues[0].id,
	IncidentResolvedStatusId = incidentStatusValues[1].id,
	IncidentInProgressStatusId = incidentStatusValues[2].id
}
