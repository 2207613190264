<entity-panel-collapsible-section [title]="'tvm.detectionLogic.title' | i18n" [collapsed] = false>
	<ng-container *ngIf="(versionItems && versionItems.length > 0) || (kbItems && kbItems.length > 0); else noData;">
		<p>
			<br><b>{{ 'tvm.detectionLogic.subTitle' | i18n}}</b>
		</p>
		<ng-container *ngIf="osFeatures && osFeatures.length > 0">
			<ul>
				<li><b>{{ 'tvm.detectionLogic.osFeature' | i18n}}</b></li>
			</ul>
			<ul style="list-style: none;">
				<li *ngFor="let feature of osFeatures" [innerHTML]=feature></li>
			</ul>
		</ng-container>
		<ng-container *ngIf="kbItems && kbItems.length > 0">
			<ul>
				<li><b>{{ 'tvm.detectionLogic.subTitleKBs' | i18n}}</b></li>
			</ul>
			<ul style="list-style: none;">
				<li *ngFor="let kb of kbItems" [innerHTML]=kb></li>
			</ul>
		</ng-container>
		<ng-container *ngIf="versionItems && versionItems.length > 0">
			<ul>
				<li><b>{{ 'tvm.detectionLogic.subTitleVersions' | i18n}}</b></li>
			</ul>
			<ul style="list-style: none;">
				<li *ngFor="let version of versionItems" [innerHTML]=version></li>
			</ul>
		</ng-container>
		<p>
			<b>{{ 'tvm.detectionLogic.detectedSoftware' | i18n}}</b><br>
		</p>

		<ng-container *ngFor="let product of installedProducts">{{product}}<br></ng-container>

		<br>
		<div *ngIf="cveSources && cveSources.length > 0" [innerHTML]="cveSourcesHtml"></div>
	</ng-container>

	<ng-template #noData>
		<h5 *ngIf="(!versionItems || versionItems.length === 0) && (!kbItems || kbItems.length === 0)">
			{{ 'common.notAvailable' | i18n }}
		</h5>
	</ng-template>
</entity-panel-collapsible-section>
