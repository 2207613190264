import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { Process } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { FULL_DATE_WITH_MILLISECONDS_FORMAT } from '@wcd/localization';

@Component({
	selector: 'process-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './process.entity-details.component.html',
})
export class ProcessEntityDetailsComponent extends EntityDetailsComponentBase<Process> {
	readonly processType = Process;
	readonly fullDateFormat = FULL_DATE_WITH_MILLISECONDS_FORMAT;

	get process(): Process {
		return this.entity;
	}

	constructor(injector: Injector) {
		super(injector);
	}
}
