export enum RbacMdeAllowedActions {
	admin = 'admin',
	viewData = 'viewData',
	alertsInvestigation = 'alertsInvestigation',
	remediationActions = 'remediationActions',
	systemSettings = 'systemSettings',
	securitySettings = 'securitySettings',
	liveResponseBasic = 'liveResponseBasic',
	liveResponseAdvanced = 'liveResponseAdvanced',
	tvmViewData = 'tvmViewData',
	tvmRemediationHandling = 'tvmRemediationHandling',
	tvmExceptionHandling = 'tvmExceptionHandling',
	configurationManagement = 'configurationManagement',
}
