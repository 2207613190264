
		<h2 class="wcd-font-weight-bold">{{ 'evaluation.lab.setupPanel.configurationStep.title' | i18n }}</h2>
		<div class="wcd-margin-large-top">
			<fab-message-bar [messageBarType]="MessageBarType.warning">
				<markdown [data]="'evaluation.lab.setupPanel.configurationStep.disclaimer' | i18n">
				</markdown>
			</fab-message-bar>
			<div class="wcd-margin-largeMedium-vertical">
				{{ 'evaluation.lab.setupPanel.configurationStep.configDescription' | i18n }}
			</div>
			<radio-list
				[values]="LAB_CONFIGURATION"
				name="evaluation-lab-config-selection"
				[defaultValue]="data.labConfiguration"
				[(ngModel)]="data.labConfiguration"
				[isBordered]="true"
			>
				<ng-template #radioContent let-item>
					<lab-configuration [configuration]="item"></lab-configuration>
				</ng-template>
			</radio-list>
			<div class="wcd-margin-medium-vertical">
				{{ 'evaluation.lab.setupPanel.configurationStep.noNewMachines' | i18n }}
			</div>
		</div>
	