/* tslint:disable:template-accessibility-alt-text */
import { Component, ChangeDetectionStrategy, Input, ChangeDetectorRef, HostBinding } from '@angular/core';
import { HuntingRule, ScheduledHuntingRunStatus } from '@wcd/domain';
import { Subscription, of } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { PollingService } from '@wcd/config';
import { mergeMap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';

const RunningStatus = 'running';
const StatusPollingInterval = 30_000;

@Component({
  selector: 'scheduled-hunting-last-run-status',
  templateUrl: './scheduled-hunting-last-run-status.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduledHuntingLastRunStatusComponent {

  @HostBinding('class.wcd-flex-center-vertical')
  get flexCenterVertical() {
    return this.showInPage;
  }

  @Input()
  set rule(value: HuntingRule) {
    this._rule = value;
    if (value && this.isRunning(value.lastRunStatus)) {
      this.startPolling();
    }
  }
  get rule() {
    return this._rule;
  }

  @Input() showInPage = false;

  private subscription: Subscription;
  private _rule: HuntingRule;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly paris: Paris,
    private readonly pollService: PollingService,
    private readonly i18nService: I18nService
  ) { }

  ngOnDestroy(): void {
    this.stopPolling();
  }

  get status() {
    return this.rule && this.rule.lastRunStatus;
  }

  get tooltip() {
    return this.status.isError && this.rule.lastRunFailureReason ? this.rule.lastRunFailureReason : '';
  }

  isRunning(status: ScheduledHuntingRunStatus) {
    return status && status.type === RunningStatus;
  }

  getLastRunStatusText(withError = false) {
    const status: string = this.i18nService.get(this.rule.lastRunStatus.nameI18nKey);
    return withError && this.rule.lastRunStatus.isError && this.rule.lastRunFailureReason ?
      status + ': ' + this.rule.lastRunFailureReason : status;
  }

  private startPolling() {
    if (this.subscription) {
      return;
    }
    this.subscription = this.pollService
      .poll(StatusPollingInterval, StatusPollingInterval)
      .pipe(
        mergeMap(() => this.rule && this.rule.id ?
          this.paris.getItemById(HuntingRule, this.rule.id, null, { includeLastRun: true })
          : of(null)
        )
      )
      .subscribe((rule: HuntingRule) => {
        if (!rule || !this.isRunning(rule.lastRunStatus)) {
          this.stopPolling();
        }
        this._rule = rule;
        this.changeDetectorRef.markForCheck();
      });
  }

  private stopPolling() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = undefined;
    }
  }

}
