import { ComponentRef } from '@angular/core';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { HuntingDocumentationTableReferencePanelComponent } from '../components/hunting-documentation-table-reference.panel.component';
import { HuntingDocumentationSchemaTableListReferencePanelComponent } from '../components/hunting-documentation-schema-table-List-reference.panel.component';
import { map, take } from 'rxjs/operators';
import { SchemaService } from '@wcd/hunting';
import { AppContextService } from '@wcd/config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i2 from "../../../dialogs/services/dialogs.service";
import * as i3 from "../../../../../../../projects/config/src/lib/services/app-context.service";
import * as i4 from "../../../../../../../projects/hunting/src/lib/schema/services/schema.service";
var MDATP_HELP_LINK = 'https://go.microsoft.com/fwlink/?linkid=2044079';
var MTP_HELP_LINK = 'https://aka.ms/mtp-ah-docs';
var HuntingDocumentationService = /** @class */ (function () {
    function HuntingDocumentationService(i18nService, dialogsService, appContextService, schemaService) {
        var _this = this;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.appContextService = appContextService;
        this.huntingDocSampleQueryClick$ = new Subject();
        this.huntingDocActionTypeOrFieldClick$ = new Subject();
        schemaService
            .getSchema()
            .pipe(map(function (schema) { return schema.tables; }), take(1))
            .subscribe(function (schema) {
            _this._schemaTablesList = schema.map(function (table) { return table.name; });
            _this._schemaTablesList.sort();
        });
    }
    Object.defineProperty(HuntingDocumentationService.prototype, "schemaTablesList", {
        get: function () {
            return this._schemaTablesList;
        },
        enumerable: true,
        configurable: true
    });
    HuntingDocumentationService.prototype.getHelpLink = function () {
        return this.appContextService.isMtp ? MTP_HELP_LINK : MDATP_HELP_LINK;
    };
    HuntingDocumentationService.prototype.openDocTableSidePanel = function (tableName) {
        var _this = this;
        if (!tableName) {
            return;
        }
        if (this._huntingDocsPanel) {
            this._huntingDocsPanel.destroy();
        }
        this.dialogsService
            .showPanel(HuntingDocumentationTableReferencePanelComponent, {
            id: 'hunting-documentation-table-reference-panel',
            isModal: true,
            showOverlay: false,
            hasCloseButton: true,
            type: PanelType.large,
            noBodyPadding: true,
            scrollBody: true,
            isBlocking: false,
            back: { onClick: function () { return _this.openDocSchemaTableListSidePanel(); } },
        }, {
            tableName: tableName,
        })
            .subscribe(function (panel) {
            _this._huntingDocsPanel = panel;
            panel.onDestroy(function () {
                _this._huntingDocsPanel = null;
            });
        });
    };
    HuntingDocumentationService.prototype.openDocSchemaTableListSidePanel = function () {
        var _this = this;
        if (!(Array.isArray(this._schemaTablesList) && this._schemaTablesList.length)) {
            return;
        }
        if (this._huntingDocsPanel) {
            this._huntingDocsPanel.destroy();
        }
        this.dialogsService
            .showPanel(HuntingDocumentationSchemaTableListReferencePanelComponent, {
            id: 'hunting-documentation-schema-table-list-panel',
            isModal: true,
            showOverlay: false,
            hasCloseButton: true,
            type: PanelType.large,
            noBodyPadding: true,
            scrollBody: true,
            isBlocking: false,
            role: "none"
        }, {
            tableNameList: this._schemaTablesList,
        })
            .subscribe(function (panel) {
            _this._huntingDocsPanel = panel;
            panel.onDestroy(function () {
                _this._huntingDocsPanel = null;
            });
        });
    };
    HuntingDocumentationService.prototype.runSampleQueryClicked = function (queryText) {
        this.huntingDocSampleQueryClick$.next(queryText);
    };
    HuntingDocumentationService.prototype.onFieldOrActionTypeClick = function (recordId) {
        this.huntingDocActionTypeOrFieldClick$.next(recordId);
    };
    HuntingDocumentationService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function HuntingDocumentationService_Factory() { return new HuntingDocumentationService(i0.ɵɵinject(i1.I18nService), i0.ɵɵinject(i2.DialogsService), i0.ɵɵinject(i3.AppContextService), i0.ɵɵinject(i4.SchemaService)); }, token: HuntingDocumentationService, providedIn: "root" });
    return HuntingDocumentationService;
}());
export { HuntingDocumentationService };
