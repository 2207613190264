import { ModuleWithProviders, NgModule } from '@angular/core';
import { FiltersModule } from '@wcd/ng-filters';
import { DataViewComponent } from './components/dataview.component';
import { ExportDataSetModalComponent } from './components/export-dataset.modal';
import { SharedModule } from '../shared/shared.module';
import { DataviewRepositoryService } from './services/dataview-repository.service';
import { SelectableDataviewFieldsPipe } from './pipes/selectable-dataview-fields.pipe';
import { WcdFormsModule } from '@wcd/forms';
import { DataTableModule } from '@wcd/datatable';
import { FabIconModule, FabSpinnerModule } from '@angular-react/fabric';
import { PanelsModule } from '@wcd/panels';
import { DataviewActionsComponent } from './components/actions-components/dataview-actions.component';
import { DataviewActionButtonComponent } from './components/actions-components/dataview-action-button.component';
import { DataviewActionColumnsCustomizationComponent } from './components/actions-components/dataview-action-columns-customization.component';
import { DataviewActionContentComponent } from './components/actions-components/dataview-action-content.component';
import { DataviewActionFilterComponent } from './components/actions-components/dataview-action-filter.component';
import { DataviewActionFancySelectComponent } from './components/actions-components/dataview-action-fancy-select.component';
import { DataviewActionPaginationComponent } from './components/actions-components/dataview-action-pagination.component';
import { DataviewActionSearchComponent } from './components/actions-components/dataview-action-search.component';
import { DataviewActionCustomRangeComponent } from './components/actions-components/dataview-action-custom-range.component';
import { DataviewActionWrapperComponent } from './components/actions-components/dataview-action-wrapper.component';

@NgModule({
	imports: [
		FiltersModule,
		SharedModule,
		WcdFormsModule,
		FiltersModule,
		DataTableModule,
		FabIconModule,
		FabSpinnerModule,
		PanelsModule,
	],
	declarations: [
		DataViewComponent,
		ExportDataSetModalComponent,
		SelectableDataviewFieldsPipe,
		DataviewActionsComponent,
		DataviewActionSearchComponent,
		DataviewActionButtonComponent,
		DataviewActionColumnsCustomizationComponent,
		DataviewActionContentComponent,
		DataviewActionFilterComponent,
		DataviewActionPaginationComponent,
		DataviewActionFancySelectComponent,
		DataviewActionCustomRangeComponent,
		DataviewActionWrapperComponent,
	],
	exports: [DataViewComponent],
	entryComponents: [DataViewComponent, ExportDataSetModalComponent],
})
export class DataViewsModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: DataViewsModule,
			providers: [DataviewRepositoryService],
		};
	}
}
