var _a;
var LOADER_IMAGE_URL = 'assets/images/circle-loading.gif';
export var InvestigationActionStatusType;
(function (InvestigationActionStatusType) {
    InvestigationActionStatusType["Running"] = "Running";
    InvestigationActionStatusType["Completed"] = "Completed";
    InvestigationActionStatusType["Failed"] = "Failed";
    InvestigationActionStatusType["Skipped"] = "Skipped";
    InvestigationActionStatusType["Resumed"] = "Resumed";
    InvestigationActionStatusType["Submitted"] = "Submitted";
    InvestigationActionStatusType["Declined"] = "Declined";
    InvestigationActionStatusType["Created"] = "Created";
    InvestigationActionStatusType["Pending"] = "Pending";
    InvestigationActionStatusType["WaitingForResource"] = "WaitingForResource";
})(InvestigationActionStatusType || (InvestigationActionStatusType = {}));
export var InvestigationActionStatusId = (_a = {},
    _a[InvestigationActionStatusType.Running] = 0,
    _a[InvestigationActionStatusType.Completed] = 1,
    _a[InvestigationActionStatusType.Failed] = 2,
    _a[InvestigationActionStatusType.Skipped] = 3,
    _a[InvestigationActionStatusType.Resumed] = 4,
    _a[InvestigationActionStatusType.Submitted] = 5,
    _a[InvestigationActionStatusType.Declined] = 6,
    _a[InvestigationActionStatusType.Created] = 7,
    _a[InvestigationActionStatusType.Pending] = 129,
    _a[InvestigationActionStatusType.WaitingForResource] = 130,
    _a);
export var investigationActionStatusValues = [
    {
        id: InvestigationActionStatusId.Running,
        type: 'running',
        name: 'Running',
        image: LOADER_IMAGE_URL,
        className: 'primary',
        isRunning: true,
    },
    {
        id: InvestigationActionStatusId.Completed,
        type: 'completed',
        name: 'Completed',
        iconName: 'Accept',
        className: 'success',
    },
    {
        id: InvestigationActionStatusId.Failed,
        type: 'failed',
        name: 'Failed',
        iconName: 'Cancel',
        className: 'error',
    },
    {
        id: InvestigationActionStatusId.Skipped,
        type: 'skipped',
        name: 'Skipped',
        iconName: 'Blocked',
        className: 'neutral',
    },
    {
        id: InvestigationActionStatusId.Resumed,
        type: 'resumed',
        name: 'Resumed',
        isRunning: true,
        isResumed: true,
        image: LOADER_IMAGE_URL,
        className: 'primary',
    },
    {
        id: InvestigationActionStatusId.Submitted,
        type: 'submitted',
        name: 'Queued',
        isRunning: true,
        iconName: 'BuildQueue',
        className: 'primary',
    },
    {
        id: InvestigationActionStatusId.Declined,
        type: 'declined',
        name: 'Declined',
        iconName: 'Dislike',
        className: 'error',
    },
    {
        id: InvestigationActionStatusId.Created,
        type: 'created',
        name: '(Just created)',
        isRunning: true,
        image: LOADER_IMAGE_URL,
        className: 'primary',
    },
    {
        id: InvestigationActionStatusId.Pending,
        type: 'pending',
        name: 'Pending approval',
        iconName: 'Pause',
        className: 'partial',
        isRunning: true,
        isPending: true,
    },
    {
        id: InvestigationActionStatusId.WaitingForResource,
        type: 'pendingResource',
        name: 'Waiting for device',
        wcdIconName: 'host_unavailable',
        className: 'partial',
        isPending: true,
        isRetriable: true,
        isRunning: true,
    },
];
