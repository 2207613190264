import { ValueObject, ModelBase, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Group membership',
	pluralName: 'Group memberships',
})
export class DeviceGroupMembership extends ModelBase {
	@EntityField({ data: 'DisplayName' })
	name?: string;

	@EntityField({ data: 'DomainDnsName' })
	domainName?: string;

	@EntityField({ data: 'Description' })
	description?: string;
}
