import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WcdIconComponent } from './components/wcd-icon.component';
import { IconComponent } from './components/icon.component';
import { IconsService } from './services/icons.service';
import { IconSwitchComponent } from './components/icon-switch.component';
import { FabIconComponent, FabIconModule } from '@angular-react/fabric';

@NgModule({
	imports: [CommonModule, FabIconModule],
	declarations: [WcdIconComponent, IconSwitchComponent, IconComponent],
	exports: [WcdIconComponent, IconSwitchComponent, IconComponent],
	entryComponents: [FabIconComponent, WcdIconComponent, IconComponent],
	providers: [IconsService],
})
export class WcdIconsModule {}
