import { Injectable, NgZone, EventEmitter } from '@angular/core';
import { SnackbarOptions } from './snackbar.models';

const SNACKBAR_SHOW_DURATION = 5000;
const REMOVE_ANIMATION_DURATION = 500;
const TIME_BETWEEN_SNACKBARS = 100;

@Injectable({ providedIn: 'root' })
export class SnackbarService {
	readonly snackbarsQueue: SnackbarOptions[] = [];
	visibleSnackbar: SnackbarOptions;
	isRemovingVisibleSnackbar = false;
	snackbarChange: EventEmitter<boolean> = new EventEmitter<boolean>();
	snackbarIsRemovingVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private readonly ngZone: NgZone) {}

	add(snackbar: SnackbarOptions) {
		if (!snackbar || !snackbar.text) {
			return;
		}

		this.snackbarsQueue.push(snackbar);

		if (!this.visibleSnackbar) {
			this.showNext();
		}
	}

	callVisibleSnackbarMethod(): void {
		if (!this.visibleSnackbar || !this.visibleSnackbar.method) {
			return;
		}

		this.closeVisibleSnackbar(true);
		this.visibleSnackbar.method.onExecute(this.visibleSnackbar.data);
	}

	private showNext() {
		if (this.snackbarsQueue.length) {
			this.visibleSnackbar = this.snackbarsQueue.shift();
			this.snackbarChange.emit(true);

			setTimeout(() => {
				this.closeVisibleSnackbar(true);
			}, SNACKBAR_SHOW_DURATION);
		} else if (this.visibleSnackbar) {
			this.closeVisibleSnackbar();
		}
	}

	private closeVisibleSnackbar(showNext?: boolean) {
		this.ngZone.run(() => {
			this.isRemovingVisibleSnackbar = true
			this.snackbarIsRemovingVisible.emit(true);

		});

		setTimeout(() => {
			this.isRemovingVisibleSnackbar = false;
			this.snackbarIsRemovingVisible.emit(false);
			this.visibleSnackbar = null;
			this.snackbarChange.emit(false);
			if (showNext) {
				setTimeout(() => {
					this.showNext();
				}, TIME_BETWEEN_SNACKBARS);
			}
		}, REMOVE_ANIMATION_DURATION);
	}
}
