<wcd-panel (close)="destroy()" [settings]="settings"
	class="wcd-full-height wcd-flex-vertical wcd-flex-1"
	data-track-component="recommendation-context-properties-panel"
	data-track-component-type="Side Panel">
	<header class="wcd-padding-vertical wcd-padding-large-horizontal">
		<h3 #panelHeader class="wcd-flex-1 wcd-flex-center-vertical side-panel-title">
			{{ title }}
		</h3>
	</header>

	<div class="wcd-flex-horizontal">
		<h4 class="wcd-flex-1 wcd-padding-bottom wcd-padding-large-left side-panel-title">
			{{ this.recommendationContextKey }}
		</h4>
		<button
			class="tvm-show-more-csv-export-button"
			*ngIf="isExportEnabled"
			(click)="exportCveData()"
			[wcdTooltip]="exportTooltipText || 'Export data to CSV'">
			<wcd-shared-icon iconName="Download"></wcd-shared-icon>
			<span>{{ 'export' | i18n }}</span>
		</button>
	</div>
	<recommendation-context-properties-entity-details
		[repository]="repository"
		[recommendationContextKey]="recommendationContextKey"
		[recommendationContextType]="recommendationContextType"
	></recommendation-context-properties-entity-details>
</wcd-panel>
