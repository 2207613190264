import { ApiCall, ApiCallModel, ParisConfig } from '@microsoft/paris';
import { Machine } from '../machine.entity';

@ApiCall({
	name: 'Investigate machine',
	endpoint: 'investigations/',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.automatedIr,
	parseQuery: (machine: Machine) => ({
		data: {
			machine_id: machine.machineId,
		},
	}),
})
export class InvestigateMachineApiCall extends ApiCallModel<void, Machine> {}
