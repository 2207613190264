import {
	Outbreak,
	OutbreakCalculationStatusType,
	OutbreakDailyImpactedDevices,
	OutbreakMitigationCalculation,
	OutbreakOverviewSection,
} from '@wcd/domain';

export const reportRefreshRate = 5 * 60000;
export const reportWidth = 1541;

export enum OutbreakSortOption {
	date = 1,
	impact = 2,
	exposure = 3
}

export class OutbreaksDashboardPreferences {
	sortOptions: OutbreakSortOption;
}

export const DEFAULT_THREAT_ANALYTICS_PREFERENCES: OutbreaksDashboardPreferences = {
	sortOptions: OutbreakSortOption.date,
};

export enum ThreatType {
	ActiveAlerts = 'ActiveAlerts',
	ResolvedAlerts = 'ResolvedAlerts',
	NoAlerts = 'NoAlerts',
	NotSupported = 'NotSupported',
}

export enum AlertType {
	Resolved = 'Resolved',
	Active = 'Active',
}

export enum SecurityType {
	Exposed = 'Exposed',
	Secured = 'Secured',
	NotApplicable = 'NotApplicable',
	Unknown = 'Unknown',
}

export enum PatchType {
	Patched = 'Secured',
	Exposed = 'Exposed',
}

export interface OutbreakReportDataOptions {
	outbreakId: string;
	impactOvertime?: Array<OutbreakDailyImpactedDevices>;
	timnaIntegrationEnabled?: boolean;
}

export interface OutbreakMitigationCalculationData {
	outbreakId: string;
	outbreak: Outbreak;
	mitigationCalculation: OutbreakMitigationCalculation;
}

export interface OutbreakOverviewData {
	overviewSection: Array<OutbreakOverviewSection>;
	shouldDisplay: boolean;
}

export interface OutbreakOverviewSummaryData {
	summary: string;
	shouldDisplay: boolean;
}

export interface MtpWidgetParsedData {
	emptyStateMessageKey?: string;
}

export interface WidgetParsedData<T> extends MtpWidgetParsedData {
	finalizedData?: T;
}

export interface OutbreaksCalculationStatus {
	devicesCalculationStatus: OutbreakCalculationStatusType;
	mailboxesCalculationStatus: OutbreakCalculationStatusType;
	threatExposureCalculationStatus: OutbreakCalculationStatusType;
}

export interface ThreatKeyColor {
	key: string;
	color: string;
}
