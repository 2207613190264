import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Set MTP consent (v2 API)',
	endpoint: 'mtp/workloads/consent',
	method: 'POST',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class MtpEnableConsentApiCall extends ApiCallModel<any, void> {}

@ApiCall({
	name: 'Clear MTP consent (v2 API)',
	endpoint: 'mtp/workloads/consent',
	method: 'DELETE',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class MtpDisableConsentApiCall extends ApiCallModel<any, void> {}

@ApiCall({
	name: 'Check MTP consent (v2 API)',
	endpoint: 'mtp/workloads/consent',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class GetMtpConsent extends ApiCallModel<{ MtpConsent: boolean }, void> {}
