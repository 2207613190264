var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ElementRef, EventEmitter, AfterViewInit, ChangeDetectorRef, } from '@angular/core';
import { clamp } from 'lodash-es';
var RangeValue = /** @class */ (function () {
    function RangeValue() {
    }
    return RangeValue;
}());
export { RangeValue };
var RangeSliderComponent = /** @class */ (function () {
    function RangeSliderComponent(changeDetectorRef) {
        this.changeDetectorRef = changeDetectorRef;
        this.max = 100;
        this.min = 0;
        this.showValue = true;
        this.onChange = new EventEmitter();
        this._isViewInitialized = false;
    }
    Object.defineProperty(RangeSliderComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (value) {
            this._value = value;
            if (this._isViewInitialized) {
                this.setThumbsPositions();
            }
        },
        enumerable: true,
        configurable: true
    });
    RangeSliderComponent.prototype.ngOnInit = function () {
        if (!this.value) {
            this.value = this.defaultValue || { from: this.max, to: this.min };
        }
    };
    RangeSliderComponent.prototype.ngAfterViewInit = function () {
        var _this = this;
        this._railElement = this.railRef.nativeElement;
        this._minThumbElement = this.minThumbRef.nativeElement;
        this._maxThumbElement = this.maxThumbRef.nativeElement;
        this._isViewInitialized = true;
        setTimeout(function () { return _this.changeDetectorRef.detectChanges(); });
    };
    /**
     * This method calculates the 'value' from the position of the thumbs as they move
     */
    RangeSliderComponent.prototype.onDragMoved = function (thumbType) {
        var railBoundingRect = this._railElement.getBoundingClientRect();
        var parentWidth = railBoundingRect.width;
        if (thumbType === 'maxElem') {
            var maxThumbBoundingRect = this._maxThumbElement.getBoundingClientRect();
            var xRelativeToParent = this.getRectCenterX(maxThumbBoundingRect) - railBoundingRect.left;
            this.value.to = Math.min(this.max, Math.round((xRelativeToParent / parentWidth) * 100));
        }
        else {
            var minThumbBoundingRect = this._minThumbElement.getBoundingClientRect();
            var xRelativeToParent = this.getRectCenterX(minThumbBoundingRect) - railBoundingRect.left;
            this.value.from = Math.max(this.min, Math.round((xRelativeToParent / parentWidth) * 100));
        }
    };
    RangeSliderComponent.prototype.onDragReleased = function () {
        this.onChange.emit(this.value);
    };
    RangeSliderComponent.prototype.getMaxBoundaryAbsoluteWidth = function () {
        if (!this._isViewInitialized)
            return 0;
        var railBoundingRect = this._railElement.getBoundingClientRect();
        var minThumbBoundingRect = this._minThumbElement.getBoundingClientRect();
        var rightEdge = railBoundingRect.right + minThumbBoundingRect.width / 2;
        var leftEdge = minThumbBoundingRect.left;
        return rightEdge - leftEdge;
    };
    RangeSliderComponent.prototype.getMinBoundaryAbsoluteWidth = function () {
        if (!this._isViewInitialized)
            return 0;
        var railBoundingRect = this._railElement.getBoundingClientRect();
        var maxThumbBoundingRect = this._maxThumbElement.getBoundingClientRect();
        var leftEdge = railBoundingRect.left - maxThumbBoundingRect.width / 2;
        var rightEdge = maxThumbBoundingRect.right;
        return rightEdge - leftEdge;
    };
    // relative to the whole width of the rail
    RangeSliderComponent.prototype.getInactiveRailRelativeWidth = function (thumbType) {
        switch (thumbType) {
            case 'minElem':
                return (this.value.from / (this.max - this.min)) * 100;
            case 'maxElem':
                return ((this.max - this.value.to) / (this.max - this.min)) * 100;
        }
    };
    RangeSliderComponent.prototype.keyPressed = function (thumbType, event) {
        var jumpValue = Math.floor((this.max - this.min) / 10); // 10% of the slider
        switch (event.key) {
            case 'ArrowUp':
            case 'ArrowRight':
                thumbType === 'maxElem' ? this.increaseTo(1) : this.increaseFrom(1);
                break;
            case 'ArrowDown':
            case 'ArrowLeft':
                thumbType === 'maxElem' ? this.increaseTo(-1) : this.increaseFrom(-1);
                break;
            case 'PageUp':
                thumbType === 'maxElem' ? this.increaseTo(jumpValue) : this.increaseFrom(jumpValue);
                break;
            case 'PageDown':
                thumbType === 'maxElem' ? this.increaseTo(-jumpValue) : this.increaseFrom(-jumpValue);
                break;
            case 'End':
                // set to maximum value
                thumbType === 'maxElem' ? this.increaseTo(Infinity) : this.increaseFrom(Infinity);
                break;
            case 'Home':
                // set to minimum value
                thumbType === 'maxElem' ? this.increaseTo(-Infinity) : this.increaseFrom(-Infinity);
                break;
        }
    };
    RangeSliderComponent.prototype.getRectCenterX = function (rect) {
        return rect.left + rect.width / 2;
    };
    /**
     *  Increase the 'to' value of the range (aka the upper bound)
     */
    RangeSliderComponent.prototype.increaseTo = function (value) {
        this.value = __assign({}, this.value, { to: clamp(this.value.to + value, this.value.from + 1, this.max) });
        this.onChange.emit(this.value);
    };
    /**
     *  Increase the 'from' value of the range (aka the lower bound)
     */
    RangeSliderComponent.prototype.increaseFrom = function (value) {
        this.value = __assign({}, this.value, { from: clamp(this.value.from + value, this.min, this.value.to - 1) });
        this.onChange.emit(this.value);
    };
    /**
     * Calculate the thumb positions from the current 'value',
     * use freeDragPosition to set the position (as offset from the start and end of the rail)
     */
    RangeSliderComponent.prototype.setThumbsPositions = function () {
        var railBoundingRect = this._railElement.getBoundingClientRect();
        var maxThumbBoundingRectHalfWidth = this._maxThumbElement.getBoundingClientRect().width / 2;
        var minThumbBoundingRectHalfWidth = this._minThumbElement.getBoundingClientRect().width / 2;
        this.maxThumbFreeDragPosition = {
            x: -((this.getInactiveRailRelativeWidth('maxElem') / 100) * railBoundingRect.width -
                maxThumbBoundingRectHalfWidth),
            y: 0,
        };
        this.minThumbFreeDragPosition = {
            x: (this.getInactiveRailRelativeWidth('minElem') / 100) * railBoundingRect.width -
                minThumbBoundingRectHalfWidth,
            y: 0,
        };
    };
    return RangeSliderComponent;
}());
export { RangeSliderComponent };
