var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { SecurityRecommendation } from '@wcd/domain';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
var SecurityRecommendationEntityPanelComponent = /** @class */ (function (_super) {
    __extends(SecurityRecommendationEntityPanelComponent, _super);
    function SecurityRecommendationEntityPanelComponent(changeDetectorRef, appInsightsService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.appInsightsService = appInsightsService;
        return _this;
    }
    Object.defineProperty(SecurityRecommendationEntityPanelComponent.prototype, "securityRecommendation", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SecurityRecommendationEntityPanelComponent.prototype, "singleAssetRecommendationOptions", {
        get: function () {
            return this.options;
        },
        enumerable: true,
        configurable: true
    });
    SecurityRecommendationEntityPanelComponent.prototype.ngAfterViewInit = function () {
        this.appInsightsService.trackEvent('security-recommendation-panel-load', {
            id: this.entity.id,
            onboardingStatus: this.entity.onboardingStatus,
        });
    };
    SecurityRecommendationEntityPanelComponent.prototype.initEntity = function (securityRecommendation, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, securityRecommendation, isExtendedData);
        this.changeDetectorRef.markForCheck();
    };
    return SecurityRecommendationEntityPanelComponent;
}(EntityPanelComponentBase));
export { SecurityRecommendationEntityPanelComponent };
