import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Mail Cluster',
	pluralName: 'Mail Clusters',
})
export class MailCluster extends ModelBase {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Name', required: false })
	name?: string;

	@EntityField({ data: 'MailClusterPageUrl', required: false })
	mailClusterPageUrl?: string;
}
