import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { EndpointManagementPageComponent } from './components/endpoint-management-page.component';
import { RouterModule, Routes } from '@angular/router';
import { EndpointManagementProcessesService } from './services/endpoint-management-processes.service';
import { DetectionTestComponent } from './components/shared/detection-test.component';
import { SenseOnboardingComponent } from './components/onboarding/sense-onboarding.component';
import { SenseOffboardingComponent } from './components/offboarding/sense-offboarding.component';
import { AgentOnboardingComponent } from './components/onboarding/agent-onboarding.component';
import { AgentOffboardingComponent } from './components/offboarding/agent-offboarding.component';
import { DetectionTestSettingsService } from './services/endpoint-management-detection-test-settings.service';
import { EndpointManagementSensePackagesService } from './services/endpoint-management-sense-packages.service';
import { EndpointManagementService } from './services/endpoint-management.service';
import { AscOnboardingComponent } from './components/onboarding/asc-onboarding.component';
import { ConfigModule } from '@wcd/config';
import { ManageNonWindowsThirdPartyComponent } from './components/shared/manage-non-windows-third-party.component';
import { ManageNonWindowsOnboardingComponent } from './components/onboarding/manage-non-windows-onboarding.component';
import { ManageNonWindowsFirstPartyOnboardingComponent } from './components/onboarding/manage-non-windows-first-party-onboarding.component';
import { ManageNonWindowsOffboardingComponent } from './components/offboarding/manage-non-windows-offboarding.component';
import { ManageNonWindowsFirstPartyOffboardingComponent } from './components/offboarding/manage-non-windows-first-party-offboarding.component';
import { ManageLinuxOnboardingComponent } from './components/onboarding/manage-linux-onboarding.component';
import { ManageLinuxFirstPartyOnboardingComponent } from './components/onboarding/manage-linux-first-party-onboarding.component';
import { ManageLinuxOffboardingComponent } from './components/offboarding/manage-linux-offboarding.component';
import { ManageLinuxFirstPartyOffboardingComponent } from './components/offboarding/manage-linux-first-party-offboarding.component';
import { ManageAndroidOnboardingComponent } from './components/onboarding/manage-android-onboarding.component';
import { ManageAndroidFirstPartyOnboardingComponent } from './components/onboarding/manage-android-first-party-onboarding.component';

export const offBoardingTitleKey = 'offboarding';

export const endpointManagementRoutes: Routes = [
	{
		path: 'suspended/offboarding',
		component: EndpointManagementPageComponent,
		data: {
			pageTitle: 'Offboarding',
			pageTitleKey: offBoardingTitleKey,
		},
	},
];

@NgModule({
	imports: [RouterModule.forChild(endpointManagementRoutes), SharedModule, ConfigModule],
	declarations: [
		EndpointManagementPageComponent,
		DetectionTestComponent,
		SenseOnboardingComponent,
		SenseOffboardingComponent,
		AgentOnboardingComponent,
		AgentOffboardingComponent,
		ManageNonWindowsOnboardingComponent,
		ManageNonWindowsFirstPartyOnboardingComponent,
		ManageNonWindowsOffboardingComponent,
		ManageNonWindowsFirstPartyOffboardingComponent,
		ManageNonWindowsThirdPartyComponent,
		ManageLinuxOnboardingComponent,
		ManageLinuxFirstPartyOnboardingComponent,
		ManageLinuxOffboardingComponent,
		ManageLinuxFirstPartyOffboardingComponent,
		AscOnboardingComponent,
		ManageAndroidOnboardingComponent,
		ManageAndroidFirstPartyOnboardingComponent,
	],
	exports: [RouterModule, EndpointManagementPageComponent, SenseOnboardingComponent],
	providers: [
		EndpointManagementService,
		EndpointManagementProcessesService,
		EndpointManagementSensePackagesService,
		DetectionTestSettingsService,
	],
	entryComponents: [EndpointManagementPageComponent],
})
export class EndpointManagementModule {}
