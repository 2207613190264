import { ServiceSourceType } from '@wcd/domain';
import { HoverCardType } from 'office-ui-fabric-react';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { DataTableField } from '@wcd/datatable';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
var firstLetterToLowerCase = function (str) { return str.replace(/^\w/, function (c) { return c.toLowerCase(); }); };
var ɵ0 = firstLetterToLowerCase;
var ImpactedEntitiesLinkedByComponent = /** @class */ (function () {
    function ImpactedEntitiesLinkedByComponent(globalEntityTypesService, i18nService) {
        this.globalEntityTypesService = globalEntityTypesService;
        this.i18nService = i18nService;
        this.showTooltip = false;
        this.cardType = HoverCardType.plain;
        this.showEntityNameComponent = true;
        this.showCardComponent = true;
    }
    ImpactedEntitiesLinkedByComponent.prototype.ngOnInit = function () {
        var _this = this;
        var entityCount = this.targetEntities.reduce(function (result, item) {
            result[item.type] = result[item.type] ? result[item.type] + 1 : 1;
            return result;
        }, {});
        this.entitiesList = Object.entries(entityCount)
            .map(function (entry) {
            var entityType = _this.globalEntityTypesService.getEntityType(firstLetterToLowerCase(entry[0]));
            var pluralName = entityType.entityPluralNameKey
                ? _this.i18nService.get(entityType.entityPluralNameKey)
                : entityType.entity.pluralName;
            var singularName = entityType.entitySingularNameKey
                ? _this.i18nService.get(entityType.entitySingularNameKey)
                : entityType.entity.singularName;
            return {
                icon: entityType.icon,
                display: entry[1] > 1
                    ? entry[1] + " " + pluralName
                    : "" + singularName,
            };
        });
        this.items = this.getItemsByEntity(this.entityTypeId);
        this.columns = this.getColumnsByEntityType(this.entityTypeId);
        this.showEntityNameComponent = !this.items;
    };
    ImpactedEntitiesLinkedByComponent.prototype.getColumnsByEntityType = function (entityTypeId) {
        if (entityTypeId === 'mailMessage') {
            return DataTableField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.get('impacted_entities_linked_by_email_message', { count: this.items.length }),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (item) {
                            return item ? {
                                entity: item,
                                entityTypeId: item.entityTypeId,
                            }
                                : null;
                        },
                    },
                    sort: { enabled: false },
                },
            ]);
        }
        if (entityTypeId === 'machine') {
            return DataTableField.fromList([
                {
                    id: 'name',
                    name: this.i18nService.get('impacted_entities_linked_by_devices_title'),
                    component: {
                        type: EntityNameComponent,
                        getProps: function (item) {
                            return item ? {
                                entity: item,
                                entityTypeId: item.entityTypeId,
                            }
                                : null;
                        },
                    },
                    sort: { enabled: false },
                },
                {
                    id: 'direction',
                    name: this.i18nService.get('impacted_entities_linked_by_direction_title'),
                    sort: { enabled: false },
                },
            ]);
        }
        return null;
    };
    ImpactedEntitiesLinkedByComponent.prototype.getItemsByEntity = function (entityTypeId) {
        var _this = this;
        if (this.targetEntities && this.targetEntities.length) {
            if (entityTypeId === 'mailMessage') {
                return this.targetEntities.map(function (entity) { return ({
                    name: entity.name,
                    entityTypeId: firstLetterToLowerCase(entity.type),
                }); });
            }
            if (entityTypeId === 'machine') {
                return [
                    {
                        name: this.entity.name,
                        shortName: this.entity.name,
                        entityTypeId: entityTypeId,
                        direction: this.i18nService.get('impacted_entities_linked_by_source_device_title'),
                    }
                ].concat(this.targetEntities.map(function (entity) { return ({
                    name: entity.name,
                    shortName: entity.name,
                    entityTypeId: firstLetterToLowerCase(entity.type),
                    direction: _this.i18nService.get('impacted_entities_linked_by_remote_device_title'),
                }); }));
            }
        }
        return null;
    };
    return ImpactedEntitiesLinkedByComponent;
}());
export { ImpactedEntitiesLinkedByComponent };
export { ɵ0 };
