/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datatable-checkbox.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../i18n/src/lib/pipes/i18n.pipe";
import * as i3 from "../../../../i18n/src/lib/services/i18n.service";
import * as i4 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i5 from "@angular-react/fabric";
import * as i6 from "@angular/forms";
import * as i7 from "./datatable-checkbox.component";
var styles_DatatableCheckboxComponent = [i0.styles];
var RenderType_DatatableCheckboxComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DatatableCheckboxComponent, data: {} });
export { RenderType_DatatableCheckboxComponent as RenderType_DatatableCheckboxComponent };
export function View_DatatableCheckboxComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.I18nPipe, [i3.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "span", [["class", "wcd-datatable-checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "input", [["type", "checkbox"]], [[8, "checked", 0], [8, "id", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co._onInteractionEvent($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(6, 0, null, null, 8, "label", [["class", "wcd-datatable-checkbox__label"], ["role", "checkbox"]], [[8, "id", 0], [1, "for", 0], [2, "wcd-datatable-checkbox__label--checked", null], [1, "tabindex", 0], [1, "aria-label", 0]], [[null, "mousedown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("mousedown" === en)) {
        var pd_0 = (_co.onCheckboxClick($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵppd(7, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-datatable-checkbox__label__ring"], ["iconName", "CircleRing"]], null, null, null, i4.View_FabIconComponent_0, i4.RenderType_FabIconComponent)), i1.ɵdid(10, 5816320, null, 0, i5.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fab-icon", [["contentClass", "wcd-datatable-checkbox__label__check"], ["iconName", "StatusCircleCheckmark"]], null, null, null, i4.View_FabIconComponent_0, i4.RenderType_FabIconComponent)), i1.ɵdid(13, 5816320, null, 0, i5.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_7 = "wcd-datatable-checkbox__label__ring"; var currVal_8 = "CircleRing"; _ck(_v, 10, 0, currVal_7, currVal_8); var currVal_9 = "wcd-datatable-checkbox__label__check"; var currVal_10 = "StatusCircleCheckmark"; _ck(_v, 13, 0, currVal_9, currVal_10); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.checked; var currVal_1 = _co.id; _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_2 = (_co.id + "-label"); var currVal_3 = _co.id; var currVal_4 = _co.checked; var currVal_5 = (_co.checked ? 0 : (0 - 1)); var currVal_6 = i1.ɵunv(_v, 6, 4, _ck(_v, 7, 0, i1.ɵnov(_v, 0), "grid_columnHeader_selectRow")); _ck(_v, 6, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_DatatableCheckboxComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "wcd-datatable-checkbox", [], null, null, null, View_DatatableCheckboxComponent_0, RenderType_DatatableCheckboxComponent)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.DatatableCheckboxComponent]), i1.ɵdid(2, 49152, null, 0, i7.DatatableCheckboxComponent, [i1.ChangeDetectorRef], null, null)], null, null); }
var DatatableCheckboxComponentNgFactory = i1.ɵccf("wcd-datatable-checkbox", i7.DatatableCheckboxComponent, View_DatatableCheckboxComponent_Host_0, { checked: "checked" }, { change: "change", mouseDown: "mouseDown" }, []);
export { DatatableCheckboxComponentNgFactory as DatatableCheckboxComponentNgFactory };
