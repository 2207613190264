import {
	Component,
	ElementRef,
	EventEmitter,
	forwardRef,
	Input,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	ViewChild,
	ViewChildren,
	ViewEncapsulation,
	OnChanges,
	ChangeDetectionStrategy,
} from '@angular/core';
import { NgForm } from '@angular/forms';
import { DataEntityType, EntityModelBase, Paris, Repository } from '@microsoft/paris';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import {
	AdvancedFeatures,
	CustomTiIndicator,
	CustomTiIndicatorActionsType,
	CustomTiIndicatorActionsTypes,
	CustomTiIndicatorIdentifierType,
	CustomTiIndicatorIdentifierTypes,
	CustomTiIndicatorSeverityType,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	File,
	FileSearchCriterion,
	Ip,
	MachineGroup,
	Url,
	IndicatorAlertCategory,
} from '@wcd/domain';
import { CheckboxComponent, ChecklistValue, Focusable } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { MessageBarType } from 'office-ui-fabric-react';
import { combineLatest, Observable, of, Subscription } from 'rxjs';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { ErrorMessageOptions } from '../../../dialogs/errors/error-message-options.interface';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { DISABLEABLE_TOKEN } from '../../../shared/interfaces/disableable.interface';
import { RegExpService } from '@wcd/shared';
import { MachinesService } from '../../machines/services/machines.service';
import {
	CustomTiIndicatorComponentService,
	MAX_BYPASSDURATION,
	ScopeTypesEnum,
} from '../services/custom-ti-indicator-component.service';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { lowerFirst } from 'lodash-es';
import { AppFlavorConfig } from '@wcd/scc-common';

declare const moment: typeof import('moment');

export enum Tabs {
	details = 'details',
	action = 'action',
	groups = 'groups',
	review = 'review',
}

const MAX_CERTIFICATE_FILE_SIZE_IN_BYTES = 10 * 1024 * 1024;

const TRUSTED_FILES_REASONS: Array<string> = [
	'FileCertificateMicrosoftRoot',
	'FileCertificateNonMsTrustedSigner',
];

const INDICATOR_DOC_URLS: Partial<Record<CustomTiIndicatorsTypes, string>> = {
	[CustomTiIndicatorsTypes.Files]: 'https://go.microsoft.com/fwlink/?linkid=2132509',
	[CustomTiIndicatorsTypes.Ip]: 'https://go.microsoft.com/fwlink/?linkid=2132343',
	[CustomTiIndicatorsTypes.Url]: 'https://go.microsoft.com/fwlink/?linkid=2132343',
	[CustomTiIndicatorsTypes.Certificate]: 'https://go.microsoft.com/fwlink/?linkid=2132510',
};

@Component({
	selector: 'custom-ti-indicator-new',
	templateUrl: './custom-ti-indicator-new.component.html',
	providers: [
		{
			provide: DISABLEABLE_TOKEN,
			useExisting: forwardRef(() => CustomTiIndicatorNewComponent),
		},
		CustomTiIndicatorComponentService,
	],
	encapsulation: ViewEncapsulation.None,
	styles: [
		`
			custom-ti-indicator-new .datepicker-dialog {
				top: 496px !important;
				left: 16px !important;
			}

			.wrap-text {
				white-space: normal;
			}
		`,
	],
})
export class CustomTiIndicatorNewComponent implements OnInit, OnDestroy {
	readonly MessageBarType = MessageBarType;
	readonly RegExpService = RegExpService;
	readonly MAX_BYPASSDURATION = MAX_BYPASSDURATION;

	@Input() customTiIndicatorType: CustomTiIndicatorsType;
	@Input() indicatorValue?: string;

	@Output() save: EventEmitter<CustomTiIndicator> = new EventEmitter<CustomTiIndicator>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('customTiIndicatorForm', { static: true }) customTiIndicatorForm: NgForm;

	@ViewChild('certificateInput', { static: false }) certificateInput: ElementRef;
	@ViewChildren('focusable') focusable: QueryList<ElementRef | Focusable>;

	@ViewChild('generateAlert', { static: false }) generateAlertCheckbox: CheckboxComponent;

	get machineGroupsFieldId(): string {
		return 'custom-ti-machine-groups';
	}

	get showImpactSection(): boolean {
		return (
			this.flavorService.isEnabled(AppFlavorConfig.settings.customTI) &&
			(this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Files ||
				this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
				this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Url)
		);
	}

	get customTiIcon() {
		return this.customTiIndicatorsService.getIcon(this.customTiIndicatorType.id);
	}

	lastLocalExpiration: Date;
	lastTiExpiration: Date;

	get localExpiration() {
		if (this.lastTiExpiration != this.customTiIndicator.expirationTime) {
			const value = this.customTiIndicator.expirationTime;
			const utcDateAsLocal = moment([
				value.getUTCFullYear(),
				value.getUTCMonth(),
				value.getUTCDate(),
				value.getUTCHours(),
				value.getUTCMinutes(),
				value.getUTCSeconds(),
			])
				.utcOffset(new Date().getTimezoneOffset() / 60, true)
				.toDate();

			this.lastTiExpiration = this.customTiIndicator.expirationTime;
			this.lastLocalExpiration = utcDateAsLocal;
		}

		return this.lastLocalExpiration;
	}

	set localExpiration(d: Date) {
		// Reinterpret full datetime from picker as UTC day (without time)
		this.customTiIndicator.expirationTime = moment([d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0])
			.utcOffset(0, true)
			.toDate();
	}

	readonly Tabs = Tabs;
	readonly CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;
	readonly ScopeTypesEnum = ScopeTypesEnum;
	readonly CustomTiIndicatorActionsTypes = CustomTiIndicatorActionsTypes;
	readonly isScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.indicators);

	tabs: Array<TabModel> = [
		{
			id: Tabs.details,
			name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.details.title'),
		},
		{
			id: Tabs.action,
			name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.action.title'),
			disabled: true,
		},
		...(this.isScopeVisible
			? [
					{
						id: Tabs.groups,
						name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.scope.title'),
						disabled: true,
					},
			  ]
			: []),
		{
			id: Tabs.review,
			name: this.i18nService.get('customTiIndicator.detailsSidePane.tabs.summary.title'),
			disabled: true,
		},
	].map((tab) => new TabModel(tab));

	expirationOptions: Array<ChecklistValue> = [
		{
			id: 0,
			name: this.i18nService.get('customTiIndicator.dataview.entity.fields.expiresOn.options.never'),
		},
		{
			id: 1,
			name: this.i18nService.get('customTiIndicator.dataview.entity.fields.expiresOn.options.custom'),
		},
	];

	currentTabIndex = 0;
	currentTab = this.tabs[this.currentTabIndex];
	currentExpirationOption: ChecklistValue = this.expirationOptions[0];
	severityList: Array<CustomTiIndicatorSeverityType>;
	customTiIndicator: CustomTiIndicator;
	isSaving: boolean = false;
	asKeyValueList: boolean = true;
	loadingImpact: boolean = false;
	showNoImpactFoundMessage: boolean = false;
	earliestDateAllowed: Date;
	title: string;
	entitySingularName: string;
	entitySingularNameCapitalized: string;
	warningHelpKey: string;
	showInvalidFileSizeMessage: boolean = false;
	showMd5IsNotRecommendedWarning: boolean = false;
	tvmApplicationBlockEnabled: boolean;
	historicalDetectionEnabled: boolean;
	mitreTechniquesEnabled: boolean;
	allowBlockOnlyForUrlIndicator: boolean;
	xplatIndicatorEnabled: boolean;
	indicatorDocUrl: string;
	isValidData: boolean;
	isCustomTiIndicatorValidData: boolean;

	private _machineGroupsRepo: Repository<MachineGroup>;
	private _customTiIndicatorRepo: Repository<CustomTiIndicator>;
	private _filesRepo: Repository<File>;
	private _ipRepo: Repository<Ip>;
	private _urlRepo: Repository<Url>;
	private _rbacMachineGroupsEnabled: boolean;
	private _blockIpAndDomainEnabled: boolean;
	private _fileSearchByParam: FileSearchCriterion;
	private _impactSubscription: Subscription;
	private _machineGroupsSubscription: Subscription;
	private _onMachineGroupsChangedSubscription: Subscription;
	private _saveCustomTiSubscription: Subscription;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		public machinesService: MachinesService,
		private dialogsService: DialogsService,
		private entityPanelsService: EntityPanelsService,
		private customTiIndicatorsService: CustomTiIndicatorsService,
		private advancedFeaturesService: AdvancedFeaturesService,
		public indicatorMachineComponentService: CustomTiIndicatorComponentService,
		private flavorService: FlavorService
	) {
		this._machineGroupsRepo = paris.getRepository(MachineGroup);
		this._customTiIndicatorRepo = paris.getRepository(CustomTiIndicator);

		this._filesRepo = paris.getRepository(File);
		this._ipRepo = paris.getRepository(Ip);
		this._urlRepo = paris.getRepository(Url);

		this._rbacMachineGroupsEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroups);
		this._blockIpAndDomainEnabled = this.featuresService.isEnabled(Feature.BlockIpAndDomain);
		this.tvmApplicationBlockEnabled = this.featuresService.isEnabled(Feature.TVMApplicationBlock);
		this.historicalDetectionEnabled = this.featuresService.isEnabled(Feature.HistoricalDetection);
		this.mitreTechniquesEnabled = this.featuresService.isEnabled(Feature.IndicatorMitreTechniques);
		this.allowBlockOnlyForUrlIndicator = this.featuresService.isEnabled(
			Feature.AllowBlockOnlyForUrlIndicator
		);
		this.xplatIndicatorEnabled = this.featuresService.isEnabled(Feature.XplatIndicators);

		this.indicatorMachineComponentService.getFormatSeverityLabel = this.indicatorMachineComponentService.getFormatSeverityLabel.bind(
			this
		); // this function is called from a child component and requires this component's context
		this.indicatorMachineComponentService.getFormatCategoryLabel = this.indicatorMachineComponentService.getFormatCategoryLabel.bind(
			this
		); // this function is called from a child component and requires this component's context
	}

	ngOnInit(): void {
		this.isValidData = false;
		this.customTiIndicator = this._customTiIndicatorRepo.createNewItem();

		if (!!this.indicatorValue) {
			this.customTiIndicator.tiIndicator = this.indicatorValue;
		}

		this.isCustomTiIndicatorValidData = this.isCustomTiIndicatorValid();

		this.severityList = this.paris.getRepository(CustomTiIndicatorSeverityType).entity.values;

		this.setCustomTi();
		this._setEarliestDateAllowed();

		const advancedFeaturesObservable: Observable<AdvancedFeatures> = this._blockIpAndDomainEnabled
			? this.advancedFeaturesService.getAdvancedFeaturesSettings()
			: of(null);

		const machineGroupsObservable: Observable<Array<MachineGroup>> = this._rbacMachineGroupsEnabled
			? this.indicatorMachineComponentService.setMachineGroups(this.customTiIndicator)
			: of(null);

		combineLatest(advancedFeaturesObservable, machineGroupsObservable).subscribe(
			([advancedFeatures]) => {
				this.indicatorMachineComponentService.loadingMachineGroups = false;
				if (this._rbacMachineGroupsEnabled) {
					this.indicatorMachineComponentService.setCurrentMachineGroups(
						this.customTiIndicator.rbacGroupIds
					);
				} else {
					this._setTabVisibility(Tabs.groups, false);
				}

				this._setActionInfoMessage(advancedFeatures);
				this.indicatorMachineComponentService.loadingMachineGroups = false;
			},
			(error) => {
				this.indicatorMachineComponentService.loadingMachineGroups = false;
			}
		);

		this.indicatorDocUrl = INDICATOR_DOC_URLS[this.customTiIndicatorType.id];
		this.indicatorMachineComponentService.init(this.customTiIndicator, this.customTiIndicatorType);
	}

	ngOnDestroy(): void {
		this._impactSubscription && this._impactSubscription.unsubscribe();
		this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
		this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();

		this.indicatorMachineComponentService.destroy();
	}

	onExpirationOptionsChange = (newOption: ChecklistValue) => {
		const expirationDate: Date = this._getInitialExpirationDate();
		this.customTiIndicator.expirationTime =
			newOption === this.expirationOptions[1] ? expirationDate : null;
	};

	onMachineGroupChange = (newOption: ChecklistValue) => {
		this.indicatorMachineComponentService.allowSpecificMachineGroups =
			newOption ===
			this.indicatorMachineComponentService.selectableMachineGroupScopes[ScopeTypesEnum.specific];

		if (!this.indicatorMachineComponentService.allowSpecificMachineGroups) {
			this.indicatorMachineComponentService.setCurrentMachineGroups(
				(this.customTiIndicator.rbacGroupIds = this.indicatorMachineComponentService.currentMachineGroups = [])
			);
		}
	};

	onActionOptionsChange = (newOption: ChecklistValue) => {
		this.indicatorMachineComponentService.onActionOptionsChange(
			newOption,
			this.customTiIndicator,
			this.customTiIndicatorType,
			this.generateAlertCheckbox
		);
	};

	onGenerateAlertChange = (shouldGenenerateAlert: boolean) => {
		this.indicatorMachineComponentService.setGenerateAlertChange(
			shouldGenenerateAlert,
			this.customTiIndicator
		);
	};

	onHistoricalDetectionChange = (isHistoricalDetection: boolean) => {
		this.customTiIndicator.historicalDetection = isHistoricalDetection;
		this.customTiIndicator.lookBackPeriod = 30;
	};

	getLookBackPeriodLabel = (days: number) => {
		return this.i18nService.get(`customTiIndicator.dataview.entity.fields.lookBackPeriod.${days}`);
	};

	onCategoryChanged($event: IndicatorAlertCategory) {
		this.indicatorMachineComponentService.onCategoryChanged($event, this.customTiIndicator);
	}

	getMitreTechniqueDropDownPlaceHolder(): string {
		return this.indicatorMachineComponentService.getMitreTechniqueDropDownPlaceHolder(
			this.customTiIndicator
		);
	}

	onNavButtonPressed = (isNext: boolean) => {
		if (isNext) {
			this.currentTabIndex++;
			while (!this.tabs[this.currentTabIndex].isVisible && this.currentTabIndex < this.tabs.length) {
				this.currentTabIndex++;
			}

			this.tabs[this.currentTabIndex].disabled = false;
		} else {
			this.currentTab.disabled = true;
			this.currentTabIndex--;
			while (!this.tabs[this.currentTabIndex].isVisible && this.currentTabIndex >= 0) {
				this.currentTabIndex--;
			}
		}

		this.currentTab = this.tabs[this.currentTabIndex];

		this.focusOnFirstFocusable();
	};

	private focusOnFirstFocusable() {
		setTimeout(() => {
			if (this.focusable.first instanceof ElementRef) {
				this.focusable.first.nativeElement.focus();
			} else if (this.focusable.first && (this.focusable.first as Focusable).setFocus) {
				(this.focusable.first as Focusable).setFocus();
			}
		}, 0);
	}

	onTabChanged = (newTab: TabModel) => {
		this.currentTab.disabled = true;
		this.currentTab = newTab;
		this.currentTabIndex = this.tabs.findIndex(
			(tabModel: TabModel) => tabModel.id === this.currentTab.id
		);
	};

	showEntityPanel = <T extends EntityModelBase<TId>, TId extends string = string>(
		entityConstructor: DataEntityType<T, any, string>,
		ob: Observable<T>
	) =>
		ob.subscribe(
			(indicator: T) => {
				this.loadingImpact = false;
				if (!indicator || !indicator.id) {
					this.showNoImpactFoundMessage = true;
					return;
				}

				this.entityPanelsService.showEntityById(entityConstructor, indicator.id);
			},
			(error) => {
				this.showNoImpactFoundMessage = true;
				this.loadingImpact = false;
			}
		);

	getImpactPreview = () => {
		this.loadingImpact = true;

		switch (this.customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Files: {
				this._impactSubscription = this.showEntityPanel(
					File,
					this._filesRepo.getItemById(this.customTiIndicator.tiIndicator, undefined, {
						[File.fileSearchByParamName]: this._fileSearchByParam,
						['newFilesApi']: this.featuresService.isEnabled(Feature.K8SMigrationFileProfileKW),
					})
				);
				break;
			}
			case CustomTiIndicatorsTypes.Ip: {
				this._impactSubscription = this.showEntityPanel(
					Ip,
					this._ipRepo.getItemById(this.customTiIndicator.tiIndicator)
				);
				break;
			}
			case CustomTiIndicatorsTypes.Url: {
				this._impactSubscription = this.showEntityPanel(
					Url,
					this._urlRepo.getItemById(this.customTiIndicator.tiIndicator)
				);
				break;
			}
		}
	};

	onIndicatorChange = () => {
		this.showNoImpactFoundMessage = false;
		this.isCustomTiIndicatorValidData = this.isCustomTiIndicatorValid();
	};

	saveRule = () => {
		this.isSaving = true;
		this.indicatorMachineComponentService.resetRedundantFields(this.customTiIndicator);
		this._saveCustomTiSubscription = this._customTiIndicatorRepo.save(this.customTiIndicator).subscribe(
			() => {
				this.isSaving = false;
				// set timeout is used because the "add indicator" button gets focused
				// immediately after the panel is closed and message is not read by the narrator.
				setTimeout(() => {
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get('customTiIndicator.detailsSidePane.indicatorSaved'),
					});
				});
				this.save.emit(this.customTiIndicator);
			},
			(error) => {
				this.isSaving = false;

				let errorMessageOptions: ErrorMessageOptions = {
					title: this.i18nService.get(
						'customTiIndicator.dataview.actions.add.errorMessages.generalError.title'
					),
					data: error,
				};

				if (error.status === 403 && error.message && error.message.Message) {
					const message = TRUSTED_FILES_REASONS.find((reason) => reason === error.message.Message);

					if (message) {
						const publisher = this.i18nService.get(
							`customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.publishers.${
								message === TRUSTED_FILES_REASONS[0] ? 'microsoft' : 'trusted'
							}`
						);

						errorMessageOptions = {
							title: this.i18nService.get(
								'customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.title'
							),
							data: this.i18nService.get(
								'customTiIndicator.dataview.actions.add.errorMessages.trustedFiles.description',
								{ publisher: publisher }
							),
						};
					}
				}

				this.dialogsService.showError(errorMessageOptions);
			}
		);
	};

	setCustomTi() {
		this.customTiIndicator.isEnabled = true;
		this.indicatorMachineComponentService.currentMachineGroupScope = this.indicatorMachineComponentService.selectableMachineGroupScopes[
			ScopeTypesEnum.all
		];
		this.customTiIndicator.action = this.paris.getValue(
			CustomTiIndicatorActionsType,
			CustomTiIndicatorActionsTypes.Allow
		);

		this.customTiIndicator.generateAlert = false;
		this.setEntityPanelDetails();
	}

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!selectedMachineGroups || !selectedMachineGroups.length)
			this.indicatorMachineComponentService.setCurrentMachineGroups(
				(this.customTiIndicator.rbacGroupIds = [])
			);
		else {
			this._onMachineGroupsChangedSubscription = combineLatest(
				selectedMachineGroups.map((selectedGroup: ChecklistValue) =>
					this._machineGroupsRepo.getItemById(selectedGroup.id)
				)
			).subscribe((machineGroups: Array<MachineGroup>) => {
				const machineGroupIds: Array<number> = machineGroups.map((group: MachineGroup) => group.id);
				this.indicatorMachineComponentService.setCurrentMachineGroups(
					(this.customTiIndicator.rbacGroupIds = machineGroupIds)
				);
			});
		}
	}

	isValid(): boolean {
		switch (this.currentTab.id) {
			case Tabs.details: {
				this.isValidData = this.isCustomTiIndicatorValidData;
				break;
			}
			case Tabs.action: {
				this.isValidData = this.isAlertDetailsValid() && this.isWarnDetailsValid();
				break;
			}
			case Tabs.groups: {
				this.isValidData = this.isGroupsValid();
				break;
			}
			case Tabs.review: {
				this.isValidData =
					this.isCustomTiIndicatorValidData &&
					this.isAlertDetailsValid() &&
					this.isWarnDetailsValid() &&
					this.isGroupsValid();
				break;
			}

			default:
				this.isValidData = false;
		}

		return this.isValidData;
	}

	isCustomTiIndicatorValid(): boolean {
		if (
			this.customTiIndicatorType.id !== CustomTiIndicatorsTypes.Certificate &&
			!this.customTiIndicator.tiIndicator
		) {
			return false;
		}

		switch (this.customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Files: {
				this.showMd5IsNotRecommendedWarning = false;

				if (RegExpService.sha256.test(this.customTiIndicator.tiIndicator)) {
					this.customTiIndicator.tiIndicatorType = this.paris.getValue(
						CustomTiIndicatorIdentifierType,
						CustomTiIndicatorIdentifierTypes.FileSha256
					);
					this._fileSearchByParam = FileSearchCriterion.Sha256;
					return true;
				} else if (RegExpService.sha1.test(this.customTiIndicator.tiIndicator)) {
					this.customTiIndicator.tiIndicatorType = this.paris.getValue(
						CustomTiIndicatorIdentifierType,
						CustomTiIndicatorIdentifierTypes.FileSha1
					);
					this._fileSearchByParam = FileSearchCriterion.Sha1;
					return true;
				} else if (RegExpService.md5.test(this.customTiIndicator.tiIndicator)) {
					this.customTiIndicator.tiIndicatorType = this.paris.getValue(
						CustomTiIndicatorIdentifierType,
						CustomTiIndicatorIdentifierTypes.FileMd5
					);
					this._fileSearchByParam = FileSearchCriterion.Md5;
					this.showMd5IsNotRecommendedWarning = true;
					return true;
				}

				this.customTiIndicatorForm.form.controls['custom-ti-indicator-value'].setErrors({
					wrongformat: true,
				});
				return false;
			}
			case CustomTiIndicatorsTypes.Ip: {
				this.customTiIndicator.tiIndicatorType = this.paris.getValue(
					CustomTiIndicatorIdentifierType,
					CustomTiIndicatorIdentifierTypes.IpAddress
				);
				return RegExpService.ip.test(this.customTiIndicator.tiIndicator);
			}
			case CustomTiIndicatorsTypes.Url: {
				const isValidUrl = this.indicatorMachineComponentService.isValidUrl(
					this.customTiIndicator.tiIndicator
				);
				if (isValidUrl) {
					this.customTiIndicator.tiIndicatorType = this.paris.getValue(
						CustomTiIndicatorIdentifierType,
						CustomTiIndicatorIdentifierTypes.Url
					);

					return true;
				}

				if (RegExpService.domain.test(this.customTiIndicator.tiIndicator)) {
					this.customTiIndicator.tiIndicatorType = this.paris.getValue(
						CustomTiIndicatorIdentifierType,
						CustomTiIndicatorIdentifierTypes.DomainName
					);

					return true;
				}
				return false;
			}
			case CustomTiIndicatorsTypes.Certificate: {
				this.customTiIndicator.tiIndicatorType = this.paris.getValue(
					CustomTiIndicatorIdentifierType,
					CustomTiIndicatorIdentifierTypes.CertificateThumbprint
				);
				return !!this.customTiIndicator.file;
			}
		}
		return false;
	}

	isAlertDetailsValid(): boolean {
		if (
			this.isNullOrEmpty(this.customTiIndicator.description) ||
			this.isNullOrEmpty(this.customTiIndicator.title)
		) {
			return false;
		}

		if (
			this.indicatorMachineComponentService.currentActionOption &&
			this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Allow
		) {
			return true;
		}

		if (this.tvmApplicationBlockEnabled) {
			return (
				(this.customTiIndicator.generateAlert && !!this.customTiIndicator.severity) ||
				!this.customTiIndicator.generateAlert
			);
		} else {
			return (
				this.indicatorMachineComponentService.currentActionOption &&
				(this.indicatorMachineComponentService.currentActionOption.id ===
					CustomTiIndicatorActionsTypes.Warn ||
					this.indicatorMachineComponentService.currentActionOption.id ===
						CustomTiIndicatorActionsTypes.Block ||
					!!this.customTiIndicator.severity)
			);
		}
	}

	isWarnDetailsValid(): boolean {
		return this.indicatorMachineComponentService.isWarnDetailsValid(this.customTiIndicator);
	}

	isGroupsValid(): boolean {
		return (
			(this.indicatorMachineComponentService.currentMachineGroupScope &&
				this.indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.all) ||
			!!this.customTiIndicator.rbacGroupIds.length
		);
	}

	onFileSelect(event, customTiIndicatorForm) {
		const files = event.srcElement.files;
		const file = files && files[0];
		if (!!file) {
			if (file.size > MAX_CERTIFICATE_FILE_SIZE_IN_BYTES) {
				this.showInvalidFileSizeMessage = true;
				this.certificateInput.nativeElement.value = null;
				this.customTiIndicator.file = null;
			} else {
				this.showInvalidFileSizeMessage = false;
				this.customTiIndicator.file = file;
				customTiIndicatorForm.form.markAsDirty();
			}
		}

		this.isCustomTiIndicatorValidData = this.isCustomTiIndicatorValid();
	}

	setEntityPanelDetails(): void {
		this.indicatorMachineComponentService.setIsAlertable(this.customTiIndicator);

		let entityName = '';

		switch (this.customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Files: {
				entityName = this.i18nService
					.get('customTiIndicator.dataview.entity.names.file.singularName')
					.toLowerCase();
				break;
			}
			case CustomTiIndicatorsTypes.Ip: {
				entityName = this.i18nService.get('customTiIndicator.dataview.entity.names.ip.singularName');
				break;
			}
			case CustomTiIndicatorsTypes.Url: {
				entityName = `${this.i18nService.get(
					'customTiIndicator.dataview.entity.names.url.singularName'
				)}/${this.i18nService.get('customTiIndicator.dataview.entity.names.domain.singularName')}`;
				break;
			}
			case CustomTiIndicatorsTypes.Certificate: {
				entityName = this.i18nService
					.get('customTiIndicator.dataview.entity.names.certificate.singularName')
					.toLowerCase();
				break;
			}
		}

		this.entitySingularName = entityName;
		this.entitySingularNameCapitalized =
			entityName.length > 0 ? entityName.charAt(0).toUpperCase() + entityName.slice(1) : entityName;
		this.title = this.i18nService.get('customTiIndicator.dataview.actions.add.title', {
			entityName: entityName,
		});
	}

	private isNullOrEmpty(value: string) {
		return !value || value.trim() === '';
	}

	private _getInitialExpirationDate() {
		const expirationDate: Date = new Date();
		expirationDate.setMonth(expirationDate.getMonth() + 1);
		expirationDate.setHours(0, 0, 0, 0);

		return expirationDate;
	}

	private _setEarliestDateAllowed() {
		this.earliestDateAllowed = new Date();
		this.earliestDateAllowed.setDate(this.earliestDateAllowed.getDate() + 1);
		this.earliestDateAllowed.setHours(0, 0, 0, 0);
	}

	private _setTabVisibility(tabId: Tabs, showTab: boolean) {
		const machineGroupTab: TabModel = this.tabs.find((tab: TabModel) => tab.id == tabId);
		machineGroupTab.show = () => showTab;
	}

	get actionsVisible(): boolean {
		return (
			this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Files ||
			this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Ip ||
			this.customTiIndicatorType.id === CustomTiIndicatorsTypes.Url
		);
	}

	private _setActionInfoMessage(advancedFeatures: AdvancedFeatures) {
		switch (this.customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Ip:
			case CustomTiIndicatorsTypes.Url: {
				if (!this._blockIpAndDomainEnabled) {
					this.warningHelpKey =
						'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.notExposed';
				} else if (!advancedFeatures || !advancedFeatures.allowWdavNetworkBlock) {
					this.warningHelpKey =
						'customTiIndicator.dataview.entity.fields.action.values.alertandblock.info.network.turnedOff';
				} else {
					this.warningHelpKey = null;
				}

				break;
			}
			default: {
				this.warningHelpKey = null;
			}
		}
	}

	get isGenerateAlertDisabled() {
		return (
			this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Audit ||
			this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Allow
		);
	}
}
