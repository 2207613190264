import { ApiCall, ApiCallModel, ValueObject, ModelBase, EntityField } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { ImpactedEntities } from '../impacted-entities/incident.impacted.entities';

@ValueObject({
	singularName: 'Hunting record main entities response',
	pluralName: '',
})
export class HuntingRecordEntitiesResponse extends ModelBase {
	@EntityField({ data: 'ImpactedEntities' })
	impactedEntities?: ImpactedEntities;
}

@ApiCall({
	name: 'Get main entities of hunting query result record',
	endpoint: 'record/entities',
	method: 'POST',
	type: HuntingRecordEntitiesResponse,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.huntingService,
	parseData: (data) => ({ ImpactedEntities: data }),
	parseQuery: (params) => ({ data: { row: params.dataItem } }),
})
export class HuntingRecordEntitiesApiCall extends ApiCallModel<HuntingRecordEntitiesResponse, any> {}
