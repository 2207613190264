import { Component, Input } from '@angular/core';

@Component({
	selector: 'colored-dot-indication-field',
	template: `
		<span *ngIf="fieldData">
			<div
				class="wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"
				[ngClass]="'color-box-' + fieldData.color"
			></div>
			{{ fieldData.name }}
		</span>
	`,
})

export class ColoredDotIndicationFieldComponent {
	@Input()
	fieldData: { name: string; color: string };
}
