import { Entity, EntityField } from '@microsoft/paris';
import { StatusModel } from '../status.model';
import { antiVirusStatuses } from './machine-antivirus-status.values';

@Entity({
	singularName: 'Antivirus Status',
	pluralName: 'Antivirus Statuses',
	values: antiVirusStatuses,
})
export class AntivirusStatus extends StatusModel {
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	@EntityField() name: string;
}
