var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ColorsService } from '../../../shared/services/colors.service';
import { KnownColorsService } from '../../../shared/services/known-colors.service';
var logonTypeColorName = {
    Batch: 'purpleDark',
    CachedInteractive: 'purple',
    CachedRemoteInteractive: 'purpleLight',
    CachedUnlock: 'blueMid',
    Local: 'tealLight',
    Interactive: 'blue',
    Network: 'blueLight',
    NetworkCleartext: 'magentaDark',
    NewCredentials: 'magenta',
    RemoteInteractive: 'magentaLight',
    Service: 'tealDark',
    Unlock: 'teal',
};
var LogonTypeColorsService = /** @class */ (function (_super) {
    __extends(LogonTypeColorsService, _super);
    function LogonTypeColorsService(colorsService) {
        return _super.call(this, colorsService, logonTypeColorName) || this;
    }
    return LogonTypeColorsService;
}(ColorsService));
export { LogonTypeColorsService };
