/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { AirsEntityReport, AirsEntityReportProvider, AirsEntityTypeConfig } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'entity-report',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './entity-report.component.html',
})
export class EntityReportComponent implements OnInit {
	@Input() report: AirsEntityReport;
	@Input() entityType: AirsEntityTypeConfig;
	@Input() viewProvidersByDefault: boolean;
	@Input() showDescription: boolean = true;

	expandedProviders: { [index: string]: boolean } = {};
	showAllProviderSummaryItems: { [index: string]: boolean } = {};
	viewProviders: boolean = false;

	constructor(public authService: AuthService, public i18nService: I18nService) {}

	ngOnInit() {
		this.viewProviders = this.viewProvidersByDefault === true;
	}

	toggleProvider(provider: AirsEntityReportProvider) {
		this.expandedProviders[provider.name] = !this.expandedProviders[provider.name];
	}

	getProviderSummaryItems(provider: AirsEntityReportProvider) {
		const itemsToShow = this.showAllProviderSummaryItems[provider.name] ? undefined : 5;
		return provider.summary.slice(0, itemsToShow);
	}

	toggleProviderSummary(provider: AirsEntityReportProvider) {
		this.showAllProviderSummaryItems[provider.name] = !this.showAllProviderSummaryItems[provider.name];
	}

	providerHasDetails(provider: AirsEntityReportProvider): boolean {
		return !!(
			provider.link ||
			provider.detectedBy ||
			provider.categories ||
			(provider.summary && provider.summary.length)
		);
	}
}
