import { Component, OnDestroy, Input } from '@angular/core';
import {
	DetectionTestSettingsService,
	TestDetectionSettings,
} from '../../services/endpoint-management-detection-test-settings.service';
import { EndpointManagementSensePackagesService } from '../../services/endpoint-management-sense-packages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { SenseEndpointsManagementState } from '@wcd/domain';
import { Subscription } from 'rxjs';
import { EndpointManagementService } from '../../services/endpoint-management.service';
import {
	MANAGEMENT_FLOWS,
	EndpointManagementProcessesService,
	SENSE_ENDPOINT_MANAGEMENT_PACKAGES,
	PROCESS_TYPES,
} from '../../services/endpoint-management-processes.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import {
	EndpointManagementProcess,
	EndpointManagementPackage,
} from '../../models/endpoint-management-process.model';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'manage-linux-first-party-onboarding',
	templateUrl: './manage-linux-first-party-onboarding.component.html',
})
export class ManageLinuxFirstPartyOnboardingComponent {
	@Input() handleErrorAsIncompleteStatus = false;

	@Input() isServer = false;

	linuxServerDetectionSettings: TestDetectionSettings;
	loading: boolean = true;
	error: boolean = false;
	detectionTestSettings: TestDetectionSettings = this.detectionTestSettingsService.all.sense;
	packages: Array<EndpointManagementPackage>;
	currentMethod: EndpointManagementPackage;

	constructor(
		private endpointManagementService: EndpointManagementService,
		private endpointManagementProcessesService: EndpointManagementProcessesService,
		public packagesService: EndpointManagementSensePackagesService,
		private detectionTestSettingsService: DetectionTestSettingsService,
		private dialogsService: DialogsService,
		private appInsights: AppInsightsService,
		private i18nService: I18nService
	) {
		this.linuxServerDetectionSettings = Object.assign({
			instructionsText: `
				${this.i18nService.get('endpointManagement.linuxServer.onboarding.firstParty.step2.description')}
				<ol type="a">
					<li class="wcd-margin-small-top">${this.i18nService.get(
						'endpointManagement.linuxServer.onboarding.firstParty.step2.description.a'
					)}</li>
					<li class="wcd-margin-small-top">${this.i18nService.get(
						'endpointManagement.linuxServer.onboarding.firstParty.step2.description.b'
					)}</li>
				</ol>
				`,
			resultText: this.i18nService.get(
				'endpointManagement.linuxServer.onboarding.firstParty.step2.after'
			),
			commandTitle: 'bash',
			command: 'curl -o ~/Downloads/eicar.com.txt https://www.eicar.org/download/eicar.com.txt',
			commandTextRows: 1,
		});

		this.getDeploymentMethodLabel = this.getDeploymentMethodLabel.bind(this);
	}

	ngOnInit() {
		this.packages = this.packagesService.getEnabledPackages(
			MANAGEMENT_FLOWS.onboarding,
			this.endpointManagementProcessesService.all.find(p => (this.isServer && p.processType === PROCESS_TYPES.linuxServer) || (!this.isServer && p.processType === PROCESS_TYPES.linuxWorkstation))
		);
		
		this.currentMethod = this.packages && this.packages[0];
	}

	getDeploymentMethodLabel(managementPackage: EndpointManagementPackage) {
		return this.i18nService.get(
			'endpointManagement.deploymentMethods.' + managementPackage.name + '.title'
		);
	}

	downloadOnboardingPackage() {
		this.endpointManagementService.downloadSensePackage(true, this.currentMethod.id).subscribe(
			(res: string) => {
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(200),
				});
				window.location.href = res;
			},
			(error: HttpErrorResponse) => {
				this.dialogsService.showError({
					title: this.i18nService.get('endpointManagement.download.package.failed'),
					data: error,
				});
				this.appInsights.trackEvent('EndpointOnboarding-DownloadPackage', {
					ResponseStatus: String(error.status),
					FailureType: 'API',
				});
			}
		);
	}
}
