import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { RemediationActionTypeMainEntities } from './remediation-action-type-main-entities.value-object';
import { remediationActionTypeValues } from './remediation-action-type.values';
import { RemediationActionTypeTypes } from './remediation-action-type-types.enum';

@Entity({
	singularName: 'Remediation Action Type',
	pluralName: 'Remediation Action Types',
	values: remediationActionTypeValues,
})
export class RemediationActionType extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField() type: RemediationActionTypeTypes;

	@EntityField() icon: string;

	@EntityField() api: string;

	@EntityField({ arrayOf: RemediationActionTypeMainEntities })
	mainEntities: Array<RemediationActionTypeMainEntities>;

	@EntityField() simpleName: string;

	@EntityField() remediationText: (items: Array<any>) => string;

	@EntityField() dismissText: (items: Array<any>) => string;

	@EntityField() pendingText: (count: number) => string;

	@EntityField() titleText: (count: number) => string;

	@EntityField() notificationText: (items: Array<any>) => string;

	@EntityField() isDisabled: (items: Array<any>) => boolean;

	@EntityField({ defaultValue: true })
	isUserPending: boolean;

	@EntityField({ defaultValue: true })
	allowDismiss: boolean;
}
