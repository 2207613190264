import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { GoHuntQueryResponse } from '../event/cyber-event-go-hunt-query.api-call';
import { AirsEntityType } from "./airs-entity-type.entity";

const DEVICE_ENTITY_TYPE_GO_HUNT_ID  = -19;

@ApiCall({
	name: 'Get evidence hunting encoded query',
	method: 'POST',
	parseQuery: (huntingQueryRequest: AirsEntityHuntingQueryRequest) => ({
		params: {
			evidenceType: huntingQueryRequest.evidenceTypeId,
			vNextEndpoint: huntingQueryRequest.evidenceTypeId == DEVICE_ENTITY_TYPE_GO_HUNT_ID
				? 'Device'
				: AirsEntityType[huntingQueryRequest.evidenceTypeId],
			migrateToVNext: huntingQueryRequest.migrateToVNext,
		},
		data: huntingQueryRequest,
	}),
	endpoint: (config, query) => {
		if (query && query.where && query.where['migrateToVNext']) {
			return `goHunt/${query.where['vNextEndpoint']}`;
		}
		return `evidence/${query.where['evidenceType']}/huntingQuery`;
	},
	baseUrl: (config: WcdPortalParisConfig , query) => {
		if (query && query.where && query.where['migrateToVNext']) {
			return config.data.serviceUrls.huntingService;
		}
		return config.data.serviceUrls.threatIntel
	},
	parseData: (data: GoHuntQueryResponse) : GoHuntQueryResponse => ({
		allActivity: data['AllActivity'],
		encodedHuntingQuery: data.encodedHuntingQuery,
	}),
	cache: {
		time: 1000 * 60,
		max: 10,
	},
})
export class AirsEntityHuntingQueryApiCall extends ApiCallModel<
	GoHuntQueryResponse,
	AirsEntityHuntingQueryRequest
> {}

export const enum HuntingContext {
	Investigation = 'Investigation',
}

// TODO - extend with optional properties of different entity types
export interface AirsEntityHuntingQueryRequest {
	evidenceTypeId: number;
	evidenceId: string | number;
	machineId?: string;
	actionTime?: Date;
	incidentId?: string;
	context?: HuntingContext;

	// File / process identifiers
	sha1?: string;
	sha256?: string;
	fileName?: string;

	// Email
	recipientEmailAddress?: string;
	senderEmailAddress?: string;
	emailSubject?: string;

	// Email cluster (and Email)
	networkMessageIds?: Array<string>;

	// Mailbox
	upn?: string;
	mailboxPrimaryAddress?: string;

	// Device
	deviceId?: string;
	deviceName?: string;

	// User
	aadUserId?: string;
	accountSid?: string;
	accountName?: string;

	// IP
	ip?: string;

	// URL
	url?: string;

	// Process
	processId?: number;

	migrateToVNext?: boolean;
}
