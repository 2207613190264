import { Params } from '@angular/router';
import { TemplateRef } from '@angular/core';

export type QueryParamsHandling = 'merge' | 'preserve' | '';

export class TabModel {
	id: string;
	name: string;
	icon: string;
	iconClassName: string;
	image: string;
	value: number;
	valueClassName: string;
	className: string;
	routerLink: string | Array<string>;
	routerLinkQueryParams: Params;
	routerQueryParamsHandling: QueryParamsHandling;
	disabled: boolean = false;
	show: () => boolean;
	data: any;
	tooltip: string;
	badge: string;
	badgeClassName: string;
	shouldForceActive: () => boolean; //TODO: this was added to ignore query paramaters instead of using routerLinkActive, which doesn't support this feature. Remove this code when it is added to angular: https://github.com/angular/angular/issues/13205
	templateRef?: TemplateRef<any>;

	constructor(data: TabModelConfig) {
		Object.assign(this, data);
	}

	get isVisible(): boolean {
		return this.show ? this.show.call(this) : true;
	}
}

export interface TabModelConfig {
	id: string;
	name?: string;
	icon?: string;
	iconClassName?: string;
	image?: string;
	value?: number;
	valueClassName?: string;
	routerLink?: string | Array<string>;
	routerLinkQueryParams?: Params;
	routerQueryParamsHandling?: QueryParamsHandling;
	routerLinkActiveClass?: string;
	disabled?: boolean;
	show?: () => boolean;
	className?: string;
	data?: any;
	tooltip?: string;
	badge?: string;
	badgeClassName?: string;
	shouldForceActive?: () => boolean;
	templateRef?: TemplateRef<any>;
}
