import { Pipe, PipeTransform } from '@angular/core';
import { FeaturesService } from '../services/features.service';

@Pipe({ name: 'featureEnabled' })
export class FeatureEnabledPipe implements PipeTransform {
	constructor(private readonly featuresService: FeaturesService) {}

	transform(featureName: string): boolean {
		return this.featuresService.isEnabled(featureName);
	}
}
