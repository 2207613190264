/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./datatable-field-value.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./datatable-field-value-custom-component.component.ngfactory";
import * as i3 from "./datatable-field-value-custom-component.component";
import * as i4 from "./datatable-field-value.component";
import * as i5 from "../../../../config/src/lib/services/app-context.service";
import * as i6 from "@angular/router";
import * as i7 from "@angular/common";
import * as i8 from "../../../../shared/src/lib/components/route-link.component.ngfactory";
import * as i9 from "../../../../shared/src/lib/components/route-link.component";
import * as i10 from "../../../../shared/src/lib/services/routes.service";
import * as i11 from "../../../../icons/src/lib/components/wcd-icon.component.ngfactory";
import * as i12 from "../../../../icons/src/lib/components/wcd-icon.component";
import * as i13 from "../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i14 from "@angular-react/fabric";
import * as i15 from "../../../../dialogs/src/lib/tooltips/help.component.ngfactory";
import * as i16 from "../../../../dialogs/src/lib/tooltips/help.component";
import * as i17 from "@angular/platform-browser";
import * as i18 from "../../../../dialogs/src/lib/tooltips/tooltip.directive";
import * as i19 from "../../../../dialogs/src/lib/tooltips/tooltips.service";
import * as i20 from "../../../../shared/src/lib/pipes/basePath.pipe";
var styles_DataTableFieldValueComponent = [i0.styles];
var RenderType_DataTableFieldValueComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_DataTableFieldValueComponent, data: {} });
export { RenderType_DataTableFieldValueComponent as RenderType_DataTableFieldValueComponent };
function View_DataTableFieldValueComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "wcd-datatable-field-value-custom-component", [], null, null, null, i2.View_DataTableFieldValueCustomComponent_0, i2.RenderType_DataTableFieldValueCustomComponent)), i1.ɵdid(3, 573440, null, 0, i3.DataTableFieldValueCustomComponent, [i1.ChangeDetectorRef, i1.ComponentFactoryResolver], { field: [0, "field"], value: [1, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field; var currVal_1 = _co.value; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DataTableFieldValueComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "wcd-datatable-field-value", [], null, null, null, View_DataTableFieldValueComponent_0, RenderType_DataTableFieldValueComponent)), i1.ɵdid(3, 573440, null, 0, i4.DataTableFieldValueComponent, [i5.AppContextService, i6.Router], { field: [0, "field"], value: [1, "value"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.listValues.field; var currVal_1 = _v.context.$implicit; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DataTableFieldValueComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "ul", [["class", "field-value-list"]], [[2, "text-list", null], [2, "comma-separated", null], [2, "arrow-separated", null]], null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "inline-list with-bullets": 0, unstyled: 1 }), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_6)), i1.ɵdid(6, 278528, null, 0, i7.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_3 = "field-value-list"; var currVal_4 = _ck(_v, 3, 0, _co.field.listValues.withBullets, (!_co.field.listValues.withBullets || (_co.listItems && (_co.listItems.length === 1)))); _ck(_v, 2, 0, currVal_3, currVal_4); var currVal_5 = _co.listItems; _ck(_v, 6, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.field.listValues.isArrowSeparated || _co.field.listValues.isCommaSeparated) || _co.field.listValues.isInlineList); var currVal_1 = _co.field.listValues.isCommaSeparated; var currVal_2 = _co.field.listValues.isArrowSeparated; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2); }); }
function View_DataTableFieldValueComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_5)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listItems; _ck(_v, 3, 0, currVal_0); }, null); }
function View_DataTableFieldValueComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_DataTableFieldValueComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-datatable-field-value", [], null, null, null, View_DataTableFieldValueComponent_0, RenderType_DataTableFieldValueComponent)), i1.ɵdid(1, 573440, null, 0, i4.DataTableFieldValueComponent, [i5.AppContextService, i6.Router], { field: [0, "field"], value: [1, "value"], allowLink: [2, "allowLink"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field; var currVal_1 = _co.value; var currVal_2 = false; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_DataTableFieldValueComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 6, "route-link", [["linkTabIndex", "-1"]], null, null, null, i8.View_RouteLinkComponent_0, i8.RenderType_RouteLinkComponent)), i1.ɵdid(3, 573440, null, 0, i9.RouteLinkComponent, [i6.UrlHandlingStrategy, i6.Router, i10.RoutesService], { routeLink: [0, "routeLink"], linkTabIndex: [1, "linkTabIndex"] }, null), i1.ɵpod(4, { routerLink: 0, queryParams: 1 }), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_DataTableFieldValueComponent_9)), i1.ɵdid(7, 540672, null, 0, i7.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵted(-1, 0, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(0, [["innerContent", 2]], null, 0, null, View_DataTableFieldValueComponent_10)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 4, 0, _co.link, _co.linkQueryParams); var currVal_1 = "-1"; _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = i1.ɵnov(_v, 10); _ck(_v, 7, 0, currVal_2); }, null); }
function View_DataTableFieldValueComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "button", [["class", "field-value-button"], ["tabindex", "-1"], ["type", "button"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.field.onClick(_co.value) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "wcd-datatable-field-value", [], null, null, null, View_DataTableFieldValueComponent_0, RenderType_DataTableFieldValueComponent)), i1.ɵdid(3, 573440, null, 0, i4.DataTableFieldValueComponent, [i5.AppContextService, i6.Router], { field: [0, "field"], value: [1, "value"], allowLink: [2, "allowLink"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.field; var currVal_2 = _co.value; var currVal_3 = false; _ck(_v, 3, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.field.disabledInnerContent && _co.field.disabledInnerContent(_co.value)); _ck(_v, 0, 0, currVal_0); }); }
function View_DataTableFieldValueComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-icon", [], null, null, null, i11.View_WcdIconComponent_0, i11.RenderType_WcdIconComponent)), i1.ɵdid(1, 49152, null, 0, i12.WcdIconComponent, [i1.ChangeDetectorRef], { iconName: [0, "iconName"], className: [1, "className"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.wcdIconName; var currVal_1 = _co.iconCssClass; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DataTableFieldValueComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "fab-icon", [], null, null, null, i13.View_FabIconComponent_0, i13.RenderType_FabIconComponent)), i1.ɵdid(1, 5816320, null, 0, i14.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.iconCssClass; var currVal_1 = _co.fabricIconName; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_DataTableFieldValueComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "img", [["alt", "image"], ["class", "field-value-icon-base"]], [[1, "src", 4]], null, null, null, null)), i1.ɵppd(1, 1)], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 0, 0, _ck(_v, 1, 0, i1.ɵnov(_v.parent.parent.parent.parent.parent, 0), _co.imageSrc)); _ck(_v, 0, 0, currVal_0); }); }
function View_DataTableFieldValueComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, "span", [], null, null, null, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_13)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_14)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_15)), i1.ɵdid(11, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "span", [], [[2, "error-message", null]], null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.field.getCssClass && _co.field.getCssClass(_co.value)); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.wcdIconName; _ck(_v, 5, 0, currVal_1); var currVal_2 = _co.fabricIconName; _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.imageSrc; _ck(_v, 11, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_4 = _co.isDisplayError; _ck(_v, 13, 0, currVal_4); var currVal_5 = (_co.isDisplayError ? "(Error)" : _co.display); _ck(_v, 14, 0, currVal_5); }); }
function View_DataTableFieldValueComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_8)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_11)), i1.ɵdid(5, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_12)), i1.ɵdid(8, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((!_co.link && _co.field.onClick) && (_co.allowLink !== false)); _ck(_v, 5, 0, currVal_1); var currVal_2 = ((!_co.link && !_co.field.onClick) || (_co.allowLink === false)); _ck(_v, 8, 0, currVal_2); }, null); }
function View_DataTableFieldValueComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-help", [], null, null, null, i15.View_HelpComponent_0, i15.RenderType_HelpComponent)), i1.ɵdid(1, 638976, null, 0, i16.HelpComponent, [i17.DomSanitizer], { text: [0, "text"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.helpText; _ck(_v, 1, 0, currVal_0); }, null); }
function View_DataTableFieldValueComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 12, "span", [], null, [[null, "focus"], [null, "mouseenter"], [null, "keydown"], [null, "mouseleave"]], function (_v, en, $event) { var ad = true; if (("focus" === en)) {
        var pd_0 = (i1.ɵnov(_v, 4).onFocus($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 4).onMouseEnter($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown" === en)) {
        var pd_2 = (i1.ɵnov(_v, 4).onKeyDown($event) !== false);
        ad = (pd_2 && ad);
    } if (("mouseleave" === en)) {
        var pd_3 = (i1.ɵnov(_v, 4).onMouseLeave() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i7.ɵNgClassImpl, i7.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i7.NgClass, [i7.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵdid(4, 147456, null, 0, i18.TooltipDirective, [i1.ElementRef, i19.TooltipsService, i17.DomSanitizer], { tooltipHTML: [0, "tooltipHTML"], wcdTooltipAllowHtmlRendering: [1, "wcdTooltipAllowHtmlRendering"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_4)), i1.ɵdid(7, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(0, [["noList", 2]], null, 0, null, View_DataTableFieldValueComponent_7)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_16)), i1.ɵdid(12, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field.className; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.tooltip; var currVal_2 = _co.field.valueTooltipAllowHtmlRendering; _ck(_v, 4, 0, currVal_1, currVal_2); var currVal_3 = _co.field.listValues; var currVal_4 = i1.ɵnov(_v, 9); _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.helpText; _ck(_v, 12, 0, currVal_5); }, null); }
function View_DataTableFieldValueComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_2)), i1.ɵdid(3, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(0, [["fieldValueContents", 2]], null, 0, null, View_DataTableFieldValueComponent_3)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getUseCustomComponent(_co.value); var currVal_1 = i1.ɵnov(_v, 5); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_DataTableFieldValueComponent_17(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["\n\t", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 0, 0, currVal_0); }); }
export function View_DataTableFieldValueComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i20.BasePathPipe, [i5.AppContextService]), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DataTableFieldValueComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["noField", 2]], null, 0, null, View_DataTableFieldValueComponent_17)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.field; var currVal_1 = i1.ɵnov(_v, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
export function View_DataTableFieldValueComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "wcd-datatable-field-value", [], null, null, null, View_DataTableFieldValueComponent_0, RenderType_DataTableFieldValueComponent)), i1.ɵdid(1, 573440, null, 0, i4.DataTableFieldValueComponent, [i5.AppContextService, i6.Router], null, null)], null, null); }
var DataTableFieldValueComponentNgFactory = i1.ɵccf("wcd-datatable-field-value", i4.DataTableFieldValueComponent, View_DataTableFieldValueComponent_Host_0, { field: "field", value: "value", allowLink: "allowLink", shortDisplay: "shortDisplay", refreshOn: "refreshOn" }, {}, []);
export { DataTableFieldValueComponentNgFactory as DataTableFieldValueComponentNgFactory };
