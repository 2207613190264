import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { MtpPermission } from '@wcd/auth';
import { AppContextService } from '@wcd/config';
import { MtpWorkload } from '@wcd/domain';
import { RbacControlService } from '../../../../../apps/portal/src/app/rbac/services/rbac-control.service';

@Injectable()
export class OatpGuard implements CanActivate {
	constructor(private appContextService: AppContextService, private rbacControlService: RbacControlService) {}

	canActivate(): boolean {
		const hasReadPermissionOnOatp = this.rbacControlService.hasRequiredRbacPermissions({
			mtpPermissions: [MtpPermission.SecurityData_Read],
			mtpWorkloads: [MtpWorkload.Oatp],
		});


		return this.appContextService.isOatpActive && hasReadPermissionOnOatp;
	}
}
