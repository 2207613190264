import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentFactory,
	ComponentFactoryResolver,
	ComponentRef,
	EventEmitter,
	Injector,
	Input,
	OnDestroy,
	OnInit,
	Output,
	Type,
	ViewChild,
	ViewContainerRef,
	ViewEncapsulation,
} from '@angular/core';
import { Router } from '@angular/router';
import { DataEntityType, EntityModelBase, Paris } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import {
	BehaviorSubject,
	defer,
	merge,
	Observable,
	of,
	Subject,
	Subscription,
	throwError,
	combineLatest,
	from,
} from 'rxjs';
import { every, finalize, share, startWith, switchMap, take, tap, shareReplay, map } from 'rxjs/operators';
import { Alert, Tag } from '@wcd/domain';
import { OnChanges, TypedChanges } from '@wcd/angular-extensions';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { PanelComponent, PanelContainer } from '@wcd/panels';
import { RbacService } from '../../../rbac/services/rbac.service';
import { toObservable } from '../../../utils/rxjs/utils';
import { EntityType } from '../../models/entity-type.interface';
import { EntitiesPanelComponentBase } from './entities-panel.component.base';
import { EntityPanelComponentBase } from './entity-panel.component.base';
import { I18nService } from '@wcd/i18n';
import {LiveAnnouncer} from "@angular/cdk/a11y";

@Component({
	selector: 'entity-panel',
	templateUrl: './entity-panel.component.html',
	styleUrls: ['./entity-panel.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None, // Set to None since some classes are passed into React
})
export class EntityPanelComponent extends PanelContainer
	implements OnChanges<EntityPanelComponent>, OnInit, OnDestroy {
	@Input() entityId: string | number;
	@Input() entity: EntityModelBase;
	@Input() entities: Array<EntityModelBase>;
	@Input() entityType: EntityType;
	@Input() options: any;
	@Input() extendEntity: boolean = true;
	@Input() disableTrapFocus: boolean = false;
	@Input() disableAutoFocus: boolean = false;
	@Input() contentClass: string;
	@Input()  contextLog: string;

	@Output() actionRun: EventEmitter<ItemActionModel> = new EventEmitter<ItemActionModel>();

	get isLoadingEntity(): boolean {
		return this._isLoadingEntity;
	}

	set isLoadingEntity(value: boolean) {
		this._isLoadingEntity = value;
		this.isLoadingEntitySubject$.next(value);
	}

	entityImage$: Observable<string>;
	entityName: string;
	entitySubtitle: string;
	loadEntityError: boolean;
	loadEntityRbacError: boolean = false;
	actions$: Observable<ReadonlyArray<ItemActionModel>>;
	tags$: Observable<ReadonlyArray<Tag>>;
	tagsWithPseudoTags$: Observable<ReadonlyArray<Tag>>;
	lastSetActionsTime: Date;
	detailsActionId: string;
	lastDetailsActionTime: Date;

	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();

	@ViewChild('entityDetailsPlaceholder', { read: ViewContainerRef, static: true })
	entityDetailsPlaceholder: ViewContainerRef;

	@ViewChild('entityStatusPlaceholder', { read: ViewContainerRef, static: true })
	entityStatusPlaceholder: ViewContainerRef;

	@ViewChild(PanelComponent, { static: true }) panel: PanelComponent;
	isUserExposed$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	private onAction$: Subject<{ action: ItemActionModel; data: any }> = new Subject<{
		action: ItemActionModel;
		data: any;
	}>();

	private entityPanelComponent: ComponentRef<EntityPanelComponentBase>;
	private entitiesPanelComponent: ComponentRef<EntitiesPanelComponentBase>;
	private _loadEntitySubscription: Subscription;
	private _entitiesExposureSubscription: Subscription;
	private _isLoadingEntity: boolean = false;
	readonly isLoadingEntitySubject$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(
		protected router: Router,
		private readonly resolver: ComponentFactoryResolver,
		private readonly paris: Paris,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly i18nService: I18nService,
		private readonly rbacService: RbacService,
		private readonly liveAnnouncer: LiveAnnouncer
	) {
		super(router);
	}

	get isSingleEntity(): boolean {
		return this.entities && this.entities.length === 1;
	}

	private get entitiesDetailsComponent(): EntitiesPanelComponentBase {
		const entitiesPanelComponentRef = this.entityPanelComponent || this.entitiesPanelComponent;
		return entitiesPanelComponentRef && entitiesPanelComponentRef.instance;
	}

	get entitySingularName() {
		const key = this.entityType.entitySingularNameKey;
		return key ? this.i18nService.get(key) : this.entityType.entity.singularName;
	}

	ngOnInit() {
		super.ngOnInit();
		this.update();
	}

	ngOnDestroy() {
		this._loadEntitySubscription && this._loadEntitySubscription.unsubscribe();
		this._entitiesExposureSubscription && this._entitiesExposureSubscription.unsubscribe();
		this.entities = null;
	}

	update() {
		if (this.entity && !this.entities) {
			this.entities = [this.entity];
		}

		this.setEntityDetailsComponent();
		this.setEntities(this.entities);
	}

	ngOnChanges(changes: TypedChanges<EntityPanelComponent>) {
		this.update();
	}

	setContextLog(context: string) {
		this.contextLog = context;
	}

	setEntity(
		entity: EntityModelBase,
		loadExtendedData: boolean = true,
		allowCache: boolean = true,
		isSameEntities: boolean = false
	) {
		this.setEntities([entity], loadExtendedData, allowCache, isSameEntities);
	}

	//TODO: setEntities gets called twice on panel opening (e.g. machine panel), causes bad jittering experience and added complexity!
	setEntities(
		entities: Array<EntityModelBase>,
		loadExtendedData: boolean = true,
		allowCache: boolean = true,
		isSameEntities: boolean = false
	) {
		loadExtendedData = entities && entities.length > 1 ? false : loadExtendedData;
		this.entities = entities;
		this.isLoadingEntity = false;
		this.updatePanelMetadata(loadExtendedData);

		const tagsSources = [
			this.entityType.getTags ? toObservable(this.entityType.getTags(this.entities)) : null,
			this._tagsSubject$,
		];
		this.tags$ = merge(...tagsSources.filter(Boolean)).pipe(shareReplay(1));

		/**
		 * the tags list shows merge of the tags and the pseudo tags. the tags edit component shows/edits only real tags.
		 */
		const tagsWithPseudoTagsSources = [
			this.tags$,
			this.entityType.pseudoTags ? this.entityType.pseudoTags.get(entities) : null,
		];
		this.tagsWithPseudoTags$ = combineLatest(...tagsWithPseudoTagsSources.filter(Boolean)).pipe(
			map(([realTags, pseudoTags]) => {
				return (pseudoTags ? pseudoTags : []).concat(realTags ? realTags : []);
			}),
			shareReplay(1)
		);

		this.entityImage$ = this.entityType.getPanelImage
			? toObservable(this.entityType.getPanelImage(this.entities)).pipe(
					take(1),
					share()
			  )
			: null;

		// By update this property to the current date, entity-command-bar component will call to 'setActions' method.
		this.lastSetActionsTime = new Date();

		if (isSameEntities) {
			this.setEntityDetailsComponent(false, !loadExtendedData);
		} else {
			if (this._entitiesExposureSubscription) {
				this._entitiesExposureSubscription.unsubscribe();
			}

			if (entities && entities.length > 1) {
				this.isUserExposed$.next(false);
				this._entitiesExposureSubscription = merge(
					...entities.map((entity: EntityModelBase) =>
						this.rbacService.isUserExposedToEntity(this.entityType, entity)
					)
				)
					.pipe(every(item => item.isExposed))
					.subscribe(
						isUserExposed => this.isUserExposed$.next(isUserExposed),
						error => this.isUserExposed$.error(error)
					);
			}

			if (this._loadEntitySubscription) {
				this._loadEntitySubscription.unsubscribe();
			}
			this.setEntityDetailsComponent(true, !loadExtendedData);
			if (loadExtendedData && this.entityType.loadFullEntityInPanel !== false) {
				this.loadEntity(allowCache);
			} else {
				this.setIsUserEntityIsExposedToEntity().subscribe();
			}
		}
		this.changeDetectorRef.markForCheck();
	}

	private updatePanelMetadata(loadExtendedData?: boolean) {
		const entityName = this.getName();
		this.entityName =
			((loadExtendedData || this.entities.length > 1) && entityName) || this.entityName || '';
		this.entitySubtitle = this.entityType.getSubtitle ? this.entityType.getSubtitle(this.entities) : null;

		this.entityStatusPlaceholder.clear();
		if (this.entityType.entityPanelStatusComponentType && this.entities.length === 1) {
			const factory = this.resolver.resolveComponentFactory(
				this.entityType.entityPanelStatusComponentType
			);
			const statusComponentRef = this.entityStatusPlaceholder.createComponent(factory);
			statusComponentRef.instance.entity = this.entities[0];
		}
	}

	private setIsUserEntityIsExposedToEntity(): Observable<boolean> {
		this.isUserExposed$.next(false);

		return this.rbacService.isUserExposedToEntity(this.entityType, this.entities[0]).pipe(
			map(item => item.isExposed),
			tap(isUserExposedToEntity => this.isUserExposed$.next(isUserExposedToEntity))
		);
	}

	loadEntity(allowCache: boolean = true) {
		if (this._loadEntitySubscription) {
			this._loadEntitySubscription.unsubscribe();
		}
		this.loadEntityError = this.loadEntityRbacError = false;

		if (!this.entities || this.entities.length !== 1) {
			this.isLoadingEntity = false;
			this.changeDetectorRef.markForCheck();
			return;
		}

		this.isLoadingEntity = true;
		this.changeDetectorRef.detectChanges();

		this._loadEntitySubscription = this.setIsUserEntityIsExposedToEntity()
			.pipe(
				switchMap((isUserExposedToEntity: boolean) => {
					if (isUserExposedToEntity) {
						return this.getEntity(allowCache);
					} else {
						return throwError({ isUserExposed: false });
					}
				}),
				finalize(() => {
					this.isLoadingEntity = false;
					this.changeDetectorRef.markForCheck();
				}),
				switchMap((entity: EntityModelBase) => {
					if (!this.entityType.getEntityPolling) {
						return of(entity);
					}

					let entityGetter$: Observable<EntityModelBase> = this.getEntity(false);
					if (entity) {
						entityGetter$ = entityGetter$.pipe(startWith(entity));
					}

					return this.entityType.getEntityPolling(entityGetter$).pipe(startWith(entity));
				})
			)
			.subscribe(
				(entity: EntityModelBase) => {
					this.setEntity(entity, false, undefined, true);
				},
				(error: any) => {
					if (error && error.isUserExposed === false) {
						this.loadEntityRbacError = true;
					} else {
						this.loadEntityError = true;
					}
				}
			);
	}

	private getEntity(allowCache: boolean): Observable<EntityModelBase> {
		if (!this.extendEntity && this.entity) {
			return of(this.entity);
		}

		const loadedEntity$ = defer(() => {
			const params$ = toObservable(
				this.entityType.getItemParams
					? this.entityType.getItemParams(this.entities[0], this.options)
					: null
			);
			return params$.pipe(
				switchMap(params =>
					this.paris.getItemById(
						<DataEntityType<EntityModelBase<any>>>this.entityType.entity,
						<any>(!isNil(this.entityId) ? this.entityId : this.entities[0].id),
						{ allowCache: allowCache },
						params
					)
				)
			);
		});

		return this.entityType.getItem
			? <Observable<EntityModelBase<any>>>(
					toObservable(
						this.entityType.getItem(this.entities[0], loadedEntity$, this.options, allowCache)
					)
			  )
			: loadedEntity$;
	}

	onRunAction(action: ItemActionModel, data?: any, tags?: Array<Tag>): void {
		this.actionRun.emit(action);
		let shouldClose;

		if (tags) {
			this._tagsSubject$.next(tags);
		}

		try {
			shouldClose =
				typeof action.closeOnAction === 'function'
					? action.closeOnAction(data)
					: action.closeOnAction;
		} catch {
			shouldClose = false;
		}

		if (shouldClose) this.closePanel();
		else {
			this.onAction$.next({ action: action, data: data });
			if (action.refreshEntityPanelOnResolve) {
				this.update();
			}
		}

		this.changeDetectorRef.markForCheck();
	}

	private setEntityDetailsComponent(destroy: boolean = true, isExtendedData?: boolean): void {
		if (destroy) {
			this.destroyEntityDetailsComponent();
			this.createEntitiesDetailsComponent();
		} else {
			if (this.entityPanelComponent && this.isSingleEntity) {
				this.entityPanelComponent.instance.setEntity(this.entities[0], isExtendedData);
			} else if (this.entitiesPanelComponent && !this.isSingleEntity) {
				this.entitiesPanelComponent.instance.setEntities(this.entities);
			} else {
				this.setEntityDetailsComponent(true);
			}
		}
	}

	private createEntitiesDetailsComponent() {
		this.isLoadingEntity = true;
		this.changeDetectorRef.detectChanges()

		const injector = Injector.create({
				providers: [],
				parent: this.entityDetailsPlaceholder.parentInjector,
			}),
			detailsComponentType: Type<EntityPanelComponentBase | EntitiesPanelComponentBase> = this
				.isSingleEntity
				? this.entityType.singleEntityPanelComponentType
				: this.entityType.multipleEntitiesComponentType,
			factory: ComponentFactory<
				EntityPanelComponentBase | EntitiesPanelComponentBase
			> = detailsComponentType
				? this.resolver.resolveComponentFactory<
						EntityPanelComponentBase | EntitiesPanelComponentBase
				  >(detailsComponentType)
				: null,
			detailsComponentRef: ComponentRef<EntityPanelComponentBase | EntitiesPanelComponentBase> = factory
				? factory.create(injector)
				: null;

		this.entityPanelComponent = this.isSingleEntity
			? <ComponentRef<EntityPanelComponentBase>>detailsComponentRef
			: null;
		this.entitiesPanelComponent = this.isSingleEntity
			? null
			: <ComponentRef<EntitiesPanelComponentBase>>detailsComponentRef;

		if (detailsComponentRef) {
			const panelInputs = this.isSingleEntity
				? { entity: this.entities[0], entities: this.entities, contextLog: this.contextLog }
				: { entities: this.entities };

			Object.assign(
				detailsComponentRef.instance,
				{
					options: this.options || {},
					action$: this.onAction$.asObservable(),
					isUserExposed$: this.isUserExposed$,
					isLoadingEntity$: this.isLoadingEntitySubject$.asObservable(),
				},
				panelInputs
			);

			detailsComponentRef.instance.requestAction.subscribe($event => {
				this.detailsActionId = $event.actionId;
				this.lastDetailsActionTime = new Date();
			});

			detailsComponentRef.instance.refreshOnResolve.subscribe($event => {
				this.actionRun.emit($event);
			});

			detailsComponentRef.instance.requestEntitiesRefresh.subscribe(() => this.loadEntity(false));
			detailsComponentRef.instance.requestMetadataRefresh.subscribe(() =>
				this.updatePanelMetadata(true)
			);

			this.entityDetailsPlaceholder.insert(detailsComponentRef.hostView);
		}
		setTimeout(()=>{
			this.isLoadingEntity = false;
			this.changeDetectorRef.markForCheck();
		})

	}

	private destroyEntityDetailsComponent() {
		if (this.entitiesDetailsComponent) {
			if (this.entityPanelComponent) {
				this.entityPanelComponent.destroy();
				this.entityPanelComponent = null;
			}
			if (this.entitiesPanelComponent) {
				this.entitiesPanelComponent.destroy();
				this.entitiesPanelComponent = null;
			}
		}
	}

	public getName(): string {
		const count = this.entities.length;
		if (count === 1)
			return this.entityType.getEntityName
				? this.entityType.getEntityName(this.entities[0])
				: this.entityType.entitySingularNameKey
				? this.i18nService.get(this.entityType.entitySingularNameKey)
				: this.entityType.entity.singularName;

		const itemType = this.entityType.entityPluralNameKey
			? this.i18nService.get(this.entityType.entityPluralNameKey)
			: this.entityType.entity.pluralName;
		return this.i18nService.get('panel.title.multiple.selected', { count, itemType });
	}
}
