import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { NetworkFiltersFields } from './network-filters-fields.value-object';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';

@ApiCall({
    ...TvmAnalyticsSharedEntityConfigurations,
    name: 'Get all available filters fields',
    endpoint: 'network-devices/filters/',
    type: NetworkFiltersFields,
    cache: {
        time: 1000 * 60,
    },
})
export class NetworkFiltersFieldsApiCall extends ApiCallModel<NetworkFiltersFields[]> { }