import { ChangeDetectionStrategy, Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MessageBarType } from 'office-ui-fabric-react';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { Machine, OnboardingStatus } from '@wcd/domain';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AuthService } from '@wcd/auth';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { openAdIotUpSellPanel } from '@wcd/shared';

@Component({
	selector: 'ad-iot-up-sell-message',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="wcd-margin-medium-bottom message-bar" *ngIf="shouldDisplayAdIotUpsellMessage">
			<div>
				<wcd-info-box>
					<div class="wcd-font-size-s wcd-margin-none-bottom">
						<div>{{ 'DevicePaneAdIotUpsellMessage' | i18n }}</div>
						<a href="#" (click)="openAdIotUpsellSidePanel()">{{
							'DevicePaneAdIotUpsellMessageLinkText' | i18n
						}}</a>
					</div>
				</wcd-info-box>
			</div>
		</div>
	`,
})
export class AdIotUpSellMessageComponent implements OnChanges {
	@Input()
	device: Machine;

	shouldDisplayAdIotUpsellMessage: boolean;
	shouldDisplayOnboardingCta: boolean;
	MessageBarType = MessageBarType;
	isAdmin = this.authService.currentUser.isMdeAdmin;

	constructor(
		private flavorService: FlavorService,
		private featuresService: FeaturesService,
		private appConfigService: AppConfigService,
		private router: Router,
		private appInsightsService: AppInsightsService,
		private authService: AuthService
	) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (!this.device) {
			return;
		}

		const isAdIotUpsellEnabled = this.featuresService.isEnabled(Feature.MagellanUpsellMessage);
		const ifOpenedToFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.iotDevices);
		const isDeviceDiscoveryEnabled = !this.appConfigService.magellanOptOut;
		const doesTheTenantHadAdIoTLicense = this.appConfigService.adIotIntegrationStatus !== 'None';
		const deviceCantBeOnboarded = [
			OnboardingStatus.InsufficientInfo,
			OnboardingStatus.Unsupported,
		].includes(this.device.onboardingStatus);

		this.shouldDisplayAdIotUpsellMessage =
			isAdIotUpsellEnabled &&
			ifOpenedToFlavor &&
			isDeviceDiscoveryEnabled &&
			!doesTheTenantHadAdIoTLicense &&
			deviceCantBeOnboarded;
	}

	openAdIotUpsellSidePanel() {
		this.appInsightsService.trackEvent('device-pane-clicked-on-ad-iot-up-sell-cta');
		openAdIotUpSellPanel();
	}
}
