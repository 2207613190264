import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Input,
	OnInit,
	QueryList,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import { ModelBase, Paris } from '@microsoft/paris';
import { DataTableComponent, DataTableField } from '@wcd/datatable';
import {
	AadUser,
	AadUsersMcasDetailsApiCall,
	Application,
	ImpactedEntities,
	Machine,
	MachineGroup,
	Mailbox,
} from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import { TypedChanges, wasChangedAndExists } from '@wcd/angular-extensions';
import { EntitiesNames, entityMap } from '../impacted-entities.model';
import { SpinnerSize } from 'office-ui-fabric-react';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { KeyValue } from '@angular/common';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import {
	areAnyEntitiesTruncated,
	areEntitiesTruncated,
	getEntities,
} from './impacted-entities-datatable.helpers';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { PanelType } from '@wcd/panels';
import { ImpactedEntitiesPanelComponent } from '../impacted-entities-panel.component';
import { I18nService } from '@wcd/i18n';
import { AadUserInvestigationPriorityComponent } from '../../../global_entities/components/entity-details/aad-user/aad-user-investigation-priority.component';
import { isNumber } from 'lodash-es';
import { Feature, FeaturesService } from '@wcd/config';

const largeWidth: number = 290;
const smallWidth: number = 190;
const MAX_ITEMS = 3;

@Component({
	selector: 'impacted-entities-datatables',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	template: `
		<div *ngIf="dataLoaded; else loader" class="wcd-impacted-entities-datatable-container">
			<h4 class="definition-list-title" *ngIf="showTitle">
				{{ 'entities.impactedEntities.title' | i18n }} ({{ entitiesTotalCount }})
			</h4>
			<div *ngFor="let entityList of entities | keyvalue: keyDescOrder">
				<wcd-datatable
					*ngIf="entityList?.value?.length > 0"
					[items]="getDisplayedEntities(entityList)"
					[columns]="entitiesColumns[entityList.key]"
					[selectEnabled]="false"
					[isItemClickable]="isItemClickable"
					[fullHeight]="false"
					[label]="'entities.impactedEntities.tableAriaLabel.' + entityList.key | i18n"
				></wcd-datatable>
				<a
					*ngIf="!openPanelForAllItems && !showAllItems && areEntitiesTruncated(entityList.key)"
					class="relative wcd-flex-justify-end wcd-after-datatable-link"
					(click)="toggleShowAll(entityList.key)"
					(keydown.enter)="toggleShowAll(entityList.key)"
					tabindex="0"
					role="button"
				>
					{{
						(showAll[entityList.key]
							? 'entities.impactedEntities.showFewer'
							: 'entities.impactedEntities.showMore'
						) | i18n
					}}
				</a>
			</div>
			<div class="wcd-padding-top">
				<a
					*ngIf="openPanelForAllItems && areAnyEntitiesTruncated"
					data-track-type="SidePaneToggleButton"
					data-track-id="OpenAllImpactedEntitiesPanel"
					(click)="openPanel()"
					(keydown.enter)="openPanel()"
					tabindex="0"
					role="button"
				>
					{{ 'entities.impactedEntities.seeAll' | i18n }}
				</a>
			</div>
		</div>
		<ng-template #loader>
			<div class="wcd-flex-1 wcd-flex-center-all">
				<fab-spinner
					[size]="SpinnerSize.large"
					label="{{ 'entities.impactedEntities.loading' | i18n }}"
				></fab-spinner>
			</div>
		</ng-template>
	`,
})
export class ImpactedEntitiesDatatablesComponent<
	TEntity extends ModelBase,
	TFixedDataViewOptions extends object
> implements OnInit {
	/*
	Entities map listing
	 */
	@Input() entities: ImpactedEntities;
	@Input() showTitle: boolean = true;
	@Input() showAllItems: boolean;
	@Input() openPanelForAllItems: boolean;
	@Input() maxItems: number = MAX_ITEMS;

	@ViewChildren(DataTableComponent) tables: QueryList<DataTableComponent>;

	public showAll: { [key: string]: boolean } = {};

	dataLoaded: boolean = false;
	SpinnerSize = SpinnerSize;
	entitiesTotalCount: number;
	entitiesColumns: Record<EntitiesNames, Array<DataTableField>> = {
		machines: DataTableField.fromList<Machine>([
			{
				id: 'machines',
				name: this.i18nService.get('impacted_entities_datatables_machine_title'),
				component: {
					type: EntityNameComponent,
					getProps: (machine: Machine) => {
						return {
							entity: machine,
							entityTypeId: 'machine',
						};
					},
				},
				useDefaultEmptyFieldComponent: true,
				maxWidth: smallWidth,
				minWidth: smallWidth,
				sort: { enabled: false },
			},
			{
				id: 'riskScores',
				name: this.i18nService.get('impacted_entities_datatables_ristk_level_title'),
				getDisplay: (machine: Machine) =>
					machine.riskScore && this.i18nService.get(machine.riskScore.nameI18nKey),
				className: 'nowrap',
				useDefaultEmptyFieldComponent: true,
				maxWidth: smallWidth,
				minWidth: smallWidth,
				getCssClass: (machine: Machine) =>
					machine.riskScore
						? `wcd-severity wcd-severity-${machine.riskScore.id.toLocaleLowerCase()}`
						: 'disabled',
				sort: { enabled: false },
			},
			{
				id: 'exposureScores',
				name: this.i18nService.get('impacted_entities_datatables_exposure_level_title'),
				getDisplay: (machine: Machine) =>
					machine.exposureScore &&
					this.i18nService.get('machineExposureScoreType_' + machine.exposureScore.id),
				icon: {
					fabricIcon: (machine: Machine) => {
						const warningThreshold: number = 4;
						return machine.exposureScore && machine.exposureScore.priority < warningThreshold
							? FabricIconNames.Warning
							: null;
					},
					className: (machine: Machine) =>
						machine.exposureScore ? 'color-text-' + machine.exposureScore.className : null,
				},
				className: 'nowrap',
				getCssClass: (machine: Machine) => (machine.exposureScore ? null : 'disabled'),
				useDefaultEmptyFieldComponent: true,
				sort: { enabled: false },
			},
		]),

		machineGroup: DataTableField.fromList<MachineGroup>([]),

		users: DataTableField.fromList<AadUser>([
			{
				id: 'users',
				name: this.i18nService.get('impacted_entities_datatables_users_title'),
				component: {
					type: EntityNameComponent,
					getProps: (aadUser: AadUser) => {
						return {
							entity: aadUser,
							entityTypeId: 'aaduser',
						};
					},
				},
				maxWidth: largeWidth,
				minWidth: largeWidth,
				sort: { enabled: false },
			},
			{
				id: 'investigation priority',
				name: this.i18nService.get('impacted_entities_datatables_investigation_priority_title'),
				component: {
					type: AadUserInvestigationPriorityComponent,
					getProps: (user: AadUser) => ({
						percentile: user.percentile,
						priority: user.investigationPriority,
					}),
				},
				className: 'nowrap',
				useDefaultEmptyFieldComponent: true,
				getIsEmptyField: (user: AadUser) => !user || !isNumber(user.investigationPriority),
				sort: { enabled: false },
			},
		]),

		mailboxes: DataTableField.fromList<Mailbox>([
			{
				id: 'mailboxes',
				name: this.i18nService.get('impacted_entities_datatables_mailboxes_title'),
				component: {
					type: EntityNameComponent,
					getProps: (mailbox: Mailbox) => {
						return {
							entity: mailbox,
							entityTypeId: 'mailbox',
						};
					},
				},
				maxWidth: largeWidth,
				minWidth: largeWidth,
				sort: { enabled: false },
			},
			{
				id: 'displayName',
				name: this.i18nService.get('impacted_entities_datatables_display_name_title'),
				getDisplay: (mailbox: Mailbox) => mailbox.displayName,
				className: 'nowrap',
				icon: {
					fabricIcon: FabricIconNames.Contact,
				},
				useDefaultEmptyFieldComponent: true,
				maxWidth: largeWidth,
				minWidth: largeWidth,
				sort: { enabled: false },
			},
		]),
		apps: DataTableField.fromList<Application>(
			this.featuresService.isEnabled(Feature.ConsiderApplicationAsAsset)
				? [
						{
							id: 'type',
							name: this.i18nService.strings.impacted_entities_datatables_apps_title,
							className: EntityNameComponent.entityNameDefaultCssClass,
							icon: { fabricIcon: FabricIconNames.Cloud },
							component: {
								type: EntityNameComponent,
								getProps: (entity: Application) => ({
									entity,
									entityTypeId: 'app',
									entityName: entity.name,
								}),
							},
						},
						{
							id: 'riskLevel',
							name: this.i18nService.strings.incident_apps_field_risk,
							getDisplay: (app: Application) =>
								app.riskLevel &&
								this.i18nService.get(`incident.apps.field.risk.${app.riskLevel}`),
							className: 'nowrap',
							getCssClass: (app: Application) =>
								app.riskLevel
									? `wcd-severity wcd-severity-${app.riskLevel.toLocaleLowerCase()}`
									: 'disabled',
						},
				  ]
				: []
		),
	};

	constructor(
		private paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private featuresService: FeaturesService
	) {}

	ngOnInit(): void {
		if (
			!this.entities.users.length ||
			this.entities.users.every((user) => isNumber(user.investigationPriority))
		) {
			this.dataLoaded = true;
			return;
		}

		this.paris
			.apiCall(
				AadUsersMcasDetailsApiCall,
				this.entities.users.map((user) => user.aadUserId)
			)
			.pipe(catchError((err) => of({})))
			.subscribe((res: Record<string, any>) => {
				this.entities.users = this.entities.users.map((user) => ({
					...user,
					investigationPriority:
						user.investigationPriority ||
						(res[user.aadUserId] && res[user.aadUserId].McasInvestigationPriority
							? parseInt(res[user.aadUserId].McasInvestigationPriority, 10)
							: null),
				}));
				this.dataLoaded = true;
				this.changeDetectorRef.markForCheck();
			});
	}

	updateTableHeaders(): void {
		if (this.tables && this.tables.length) {
			this.tables.forEach((table) => {
				table.updateHeaderCells();
			});
		}
	}

	ngOnChanges(changes: TypedChanges<ImpactedEntitiesDatatablesComponent<TEntity, TFixedDataViewOptions>>) {
		if (!wasChangedAndExists(changes.entities)) {
			return;
		}
		this.entitiesTotalCount = Object.values(changes.entities.currentValue).reduce(
			(acc, entities) => acc + ((entities && entities.length) || 0),
			0
		);
	}

	keyDescOrder(a: KeyValue<number, string>, b: KeyValue<number, string>): number {
		if (!entityMap[a.key] || !entityMap[b.key]) {
			return 0;
		}
		return entityMap[a.key].order - entityMap[b.key].order;
	}

	getDisplayedEntities(entityList: KeyValue<number, string>) {
		return this.showAllItems || this.showAll[entityList.key]
			? entityList.value
			: this.truncatedEntities[entityList.key];
	}

	get truncatedEntities(): ImpactedEntities {
		return getEntities(this.entities, this.maxItems);
	}

	get areAnyEntitiesTruncated(): boolean {
		return areAnyEntitiesTruncated(this.entities, this.maxItems);
	}

	areEntitiesTruncated(entityType: string): boolean {
		return areEntitiesTruncated(this.entities, entityType, this.maxItems);
	}

	openPanel() {
		this.dialogsService.showPanel(
			ImpactedEntitiesPanelComponent,
			{
				headerText: `${this.i18nService.get('entities.impactedEntities.title')} (${
					this.entitiesTotalCount
				})`,
				type: PanelType.large,
			},
			{ entities: this.entities }
		);
	}

	toggleShowAll(entityListKey: string) {
		this.showAll[entityListKey] = !this.showAll[entityListKey];
	}

	isItemClickable(entity: any): boolean {
		return false;
	}
}
