import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobService } from './assessment-job.service';
var AssessmentJobFieldsService = /** @class */ (function () {
    function AssessmentJobFieldsService(i18nService, assessmentJobService) {
        this.i18nService = i18nService;
        this.assessmentJobService = assessmentJobService;
    }
    Object.defineProperty(AssessmentJobFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'scanName',
                        name: this.i18nService.get('tvm.authenticatedScan.jobName'),
                        sort: {
                            enabled: true,
                        },
                    },
                    {
                        id: 'agentName',
                        name: this.i18nService.get('tvm.authenticatedScan.creationPanel.scanner'),
                        sort: {
                            enabled: true,
                        },
                    }
                ].concat((this.assessmentJobService.isWindowsScanFeatureEnabled()
                    ? [
                        {
                            id: 'scanType',
                            name: this.i18nService.get('tvm.authenticatedScan.assessmentType'),
                            sort: {
                                enabled: true,
                            },
                        },
                    ]
                    : []), [
                    {
                        id: 'originalTargetRanges',
                        name: this.i18nService.get('tvm.authenticatedScan.target'),
                        sort: {
                            enabled: true,
                        },
                    },
                    {
                        id: 'createdBy',
                        name: this.i18nService.get('tvm.common.createdBy'),
                        sort: {
                            enabled: true,
                        },
                    },
                    {
                        id: 'active',
                        name: this.i18nService.get('tvm.authenticatedScan.status'),
                        getDisplay: function (assessmentJob) {
                            return assessmentJob.active
                                ? _this.i18nService.get('tvm.authenticatedScan.dataview.jobStatus.active')
                                : _this.i18nService.get('tvm.authenticatedScan.dataview.jobStatus.inactive');
                        },
                        sort: {
                            enabled: true,
                        },
                    },
                    {
                        id: 'lastScanStatus',
                        name: this.i18nService.get('tvm.authenticatedScan.lastRun.status'),
                        sort: {
                            enabled: true,
                        },
                    },
                    {
                        id: 'lastScanDate',
                        name: this.i18nService.get('tvm.authenticatedScan.lastRun.date'),
                        getDisplay: function (assessment) {
                            return assessment.lastScanDate ? new Date(assessment.lastScanDate).toLocaleString() : '-';
                        },
                        sort: {
                            enabled: true,
                            sortCompareFunction: function (assessment1, assessment2) { return (assessment1.lastScanDate ? assessment1.lastScanDate.getTime() : 0) - (assessment2.lastScanDate ? assessment2.lastScanDate.getTime() : 0); },
                        },
                    },
                ]));
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return AssessmentJobFieldsService;
}());
export { AssessmentJobFieldsService };
