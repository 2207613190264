<section class="wcd-margin-large-bottom" data-track-component-type="Settings" data-track-component="NonWindows FirstParty Onboarding">
	<h3 class="wcd-padding-bottom">{{"endpointManagement.nonWindows.macOS.onboarding.firstParty.step1.title" | i18n}}</h3>
	<div class="wcd-padding-bottom">
		<markdown [data]="'endpointManagement.nonWindows.macOS.onboarding.firstParty.step1.description' | i18n"></markdown>
	</div>
	<section class="form-section">
		<div class="form-group">
			<label for="onboard-method">{{'endpointManagement.nonWindows.macOS.onboarding.firstParty.step1.deploymentMethod' | i18n}}</label>
			<div>
				<fancy-select [(ngModel)]="currentMethod"
					listId="onboard-method"
					name="onboard-method"
					[formatLabel]="getDeploymentMethodLabel"
					class="dropdown-framed wcd-full-width"
					[values]="packages"
					[isBordered]="true"></fancy-select>
			</div>
		</div>
		<div class="wcd-margin-bottom wcd-padding-small-all color-box-gray-100">
			<markdown [data]="'endpointManagement.deploymentMethods.' + currentMethod.name + '.description' | i18n"></markdown>
	    </div>
		<div class="wcd-flex-horizontal">
			<div class="wcd-padding-right">
				<button class="btn btn-primary wcd-padding-right"
						[disabled]="!currentMethod"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						data-track-id="DownloadInstallationPackage"
						data-track-type="Button"
						(click)="downloadInstallationPackage()">
					 <wcd-shared-icon iconName="download"> </wcd-shared-icon >
					{{"endpointManagement.nonWindows.macOS.onboarding.firstParty.step1.downloadInstallationPackage" | i18n}}
				</button>
			</div>
			<div>
				<button class="btn btn-primary"
						[disabled]="!currentMethod"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						data-track-id="DownloadMacOsOnboarding"
						data-track-type="Button"
						(click)="downloadOnboardingPackage()">
					 <wcd-shared-icon iconName="download"> </wcd-shared-icon >
					{{"endpointManagement.nonWindows.macOS.onboarding.firstParty.step1.downloadOnboardingPackage" | i18n}}
				</button>
			</div>
		</div>
	</section>
</section>
<section class="wcd-margin-large-bottom">
	<h3 class="wcd-padding-bottom">{{"endpointManagement.nonWindows.onboarding.firstParty.step2.title" | i18n}}</h3>
	<detection-test [settings]="macOsDetectionSettings"></detection-test>
</section>
