import { keyBy } from 'lodash-es';
import { FirstSeen } from './device-first-seen.enum';
export const firstSeenValues = [
	{
		id: FirstSeen.Last7Days,
		i18nNameKey: 'devices_page_last_7_days',
		priority: 1,
	},
	{
		id: FirstSeen.Over7DaysAgo,
		i18nNameKey: 'devices_page_over_7_days',
		priority: 2,
	},
];

export const FIRST_SEEN_DEFAULT_TIME_COMPARISON = '7';
export const firstSeenValuesMap = keyBy(firstSeenValues, o => o.id);
