/* tslint:disable:template-accessibility-label-for */
import { ElementRef, EventEmitter, forwardRef, NgZone, OnDestroy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { PositionModel } from '../../dialogs/models/position.model';
import { PositionService } from '../../dialogs/services/position.service';
import { getClientTimeZoneString, LocaleConfigService, TzDateService } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var DATEPICKER_CUSTOM_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DatePickerComponent; }),
    multi: true,
};
var ARIA_DATE_FORMAT_OPTIONS = {
    month: 'long',
    day: 'numeric',
    year: 'numeric',
};
var DATE_TIME_FORMAT_OPTIONS = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
};
var lastId = 0;
var DatePickerComponent = /** @class */ (function () {
    function DatePickerComponent(el, ngZone, tzDateService, changeDetector, localeConfigService, i18nService, liveAnnouncer) {
        var _this = this;
        this.el = el;
        this.ngZone = ngZone;
        this.tzDateService = tzDateService;
        this.changeDetector = changeDetector;
        this.localeConfigService = localeConfigService;
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this._value = new Date();
        this.allowFutureDates = false;
        this.allowExactTimeSelection = false;
        this.earliestDateAllowed = null;
        this.latestDateAllowed = null;
        this.ariaLabel = null;
        this.showInPanel = false;
        this.valueChange = new EventEmitter(false);
        this.showDialog = false;
        this.datePickerId = "datepicker-" + lastId++;
        this.onChange = function (_) { };
        this.onTouched = function () { };
        this.displayDate = new Date();
        this.setCurrentTimezoneLabel();
        this.timezoneChangeSubscription = this.localeConfigService.config$.subscribe(function () {
            _this.setCurrentTimezoneLabel();
            _this.changeDetector.markForCheck();
        });
    }
    Object.defineProperty(DatePickerComponent.prototype, "isUTCTimeZone", {
        get: function () {
            return !this.localeConfigService.isLocalTimeZone;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.setTime = function (hours, minutes, seconds) {
        if (hours !== null && hours >= 0 && hours < 24) {
            this.selectedDate.setHours(hours);
            this.userChangedHoursValue = true;
        }
        if (minutes !== null && minutes >= 0 && minutes < 60) {
            this.selectedDate.setMinutes(minutes);
            this.userChangedMinutesValue = true;
        }
        if (seconds !== null && seconds >= 0 && seconds < 60)
            this.selectedDate.setSeconds(seconds);
    };
    DatePickerComponent.prototype.isTimeValid = function (hours, minutes, seconds) {
        hours = parseInt(hours);
        minutes = parseInt(minutes);
        seconds = parseInt(seconds);
        return ((!isNaN(hours) &&
            hours < 24 &&
            hours >= 0 &&
            !isNaN(minutes) &&
            minutes < 60 &&
            minutes >= 0 &&
            !isNaN(seconds) &&
            seconds < 60 &&
            seconds >= 0));
    };
    Object.defineProperty(DatePickerComponent.prototype, "value", {
        get: function () {
            return this._value;
        },
        set: function (v) {
            this._value = v;
        },
        enumerable: true,
        configurable: true
    });
    DatePickerComponent.prototype.ngOnInit = function () {
        var dialogWrapper = this.el.nativeElement.querySelector('.datepicker-wrapper');
        this._dialogEl = dialogWrapper.querySelector('.datepicker-content');
    };
    DatePickerComponent.prototype.ngOnDestroy = function () {
        this.timezoneChangeSubscription && this.timezoneChangeSubscription.unsubscribe();
    };
    DatePickerComponent.prototype.writeValue = function (value) {
        var _this = this;
        this.ngZone.run(function () {
            // If UTC timezone is selected, we need to convert the date to UTC (and bypass the browser's 'new Date()' method that always creates dates in local timezone)
            var correctTimeZoneDate = value && _this.isUTCTimeZone && !_this.rawDate ? _this.convertLocalToUTCDate(value) : value;
            _this.setValue(correctTimeZoneDate);
        });
    };
    DatePickerComponent.prototype.convertLocalToUTCDate = function (date) {
        return moment([
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getHours(),
            date.getMinutes(),
            date.getSeconds(),
        ]).toDate();
    };
    DatePickerComponent.prototype.convertUTCDateToLocal = function (date) {
        return moment([
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            this.userChangedHoursValue ? date.getHours() : date.getUTCHours(),
            this.userChangedMinutesValue ? date.getMinutes() : date.getUTCMinutes(),
            date.getSeconds(),
        ])
            .utcOffset(0, true)
            .toDate();
    };
    DatePickerComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DatePickerComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    DatePickerComponent.prototype.setValue = function (value) {
        this.value = value;
        if (value) {
            this.selectedDate = copyDate(value);
            if (this.rawDate) {
                var utcDateAsLocal = moment([
                    value.getUTCFullYear(),
                    value.getUTCMonth(),
                    value.getUTCDate(),
                    value.getUTCHours(),
                    value.getUTCMinutes(),
                    value.getUTCSeconds(),
                ])
                    .utcOffset(new Date().getTimezoneOffset() / 60, true)
                    .toDate();
                this.updateDisplayValue(utcDateAsLocal);
                this.dateAriaLabel = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale, ARIA_DATE_FORMAT_OPTIONS).format(utcDateAsLocal);
            }
            else {
                this.updateDisplayValue(this.selectedDate);
                this.dateAriaLabel = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale, ARIA_DATE_FORMAT_OPTIONS).format(this.selectedDate);
            }
        }
        else {
            this.selectedDate = null;
            this.displayValue = '';
        }
        this.changeDetector.detectChanges();
    };
    DatePickerComponent.prototype.updateDisplayValue = function (date) {
        if (this.allowExactTimeSelection) {
            this.displayValue = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale, DATE_TIME_FORMAT_OPTIONS).format(date);
        }
        else {
            this.displayValue = new Intl.DateTimeFormat(this.localeConfigService.selectedLocale).format(date);
        }
    };
    DatePickerComponent.prototype.revert = function () {
        this.setDisplayDate(this.value || new Date());
        this.showDialog = false;
        this.selectedDate = copyDate(this.value);
        this.userChangedHoursValue = false;
        this.userChangedMinutesValue = false;
        this.changeDetector.detectChanges();
    };
    DatePickerComponent.prototype.onWrapperClick = function ($event) {
        if ($event.target.className === 'datepicker-wrapper') {
            this.revert();
            this.changeDetector.detectChanges();
        }
    };
    DatePickerComponent.prototype.save = function () {
        var _this = this;
        this.showDialog = false;
        setTimeout(function () {
            if (!datesAreEqual(_this.value, _this.selectedDate)) {
                _this.setValue(_this.selectedDate);
                // If UTC timezone is selected, we need to convert the date to UTC (and bypass the browser's 'new Date()' method that always creates dates in local timezone)
                // It gets tricky if the user only changes one of the inputs, because we need to set the UTC time of the untouched input too
                var correctLocalTimezoneDate = _this.selectedDate && _this.isUTCTimeZone && !_this.rawDate
                    ? _this.convertUTCDateToLocal(_this.selectedDate)
                    : _this.selectedDate;
                _this.valueChange.emit(correctLocalTimezoneDate);
                _this.onChange(correctLocalTimezoneDate);
                _this.userChangedHoursValue = false;
                _this.userChangedMinutesValue = false;
                _this.changeDetector.detectChanges();
            }
        });
    };
    DatePickerComponent.prototype.toggleDialog = function () {
        var _this = this;
        if (!this.showDialog) {
            this.ngZone.run(function () {
                _this.setDisplayDate(_this.value || new Date());
                setTimeout(function () {
                    _this.showDialog = true;
                    if (_this.showInPanel) {
                        _this.setDialogInPanelPosition();
                    }
                    else {
                        _this.setDialogPosition();
                    }
                    _this._dialogEl.querySelector('h4').focus();
                    _this.changeDetector.detectChanges();
                }, 60);
            });
        }
        this.showDialog = false;
    };
    DatePickerComponent.prototype.hideDialogEsc = function (event) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        this.showDialog = false;
    };
    DatePickerComponent.prototype.selectDate = function (date, event) {
        event && event.preventDefault();
        if (!this.isDayDisabled(date)) {
            var dateCopy = copyDate(date);
            dateCopy.setHours(this.selectedDate.getHours());
            dateCopy.setMinutes(this.selectedDate.getMinutes());
            dateCopy.setSeconds(this.selectedDate.getSeconds());
            this.selectedDate = dateCopy;
            this.changeDetector.detectChanges();
        }
    };
    DatePickerComponent.prototype.setDisplayDate = function (date) {
        this.displayDate = copyDate(date);
        this.currentMonth = this.getMonthData(date);
        this.days = this.getDays();
        this.liveAnnouncer.announce(this.currentMonth.name + ' ' + this.currentMonth.year, 'assertive', 300);
        this.changeDetector.detectChanges();
    };
    // If UTC timezone is selected, we're showing the user what he expects, while keeping the real date in local timezone, and parse it again before save
    // If the user manually changes value, we're showing him the value he inserted and we're setting it to the selectedDate object
    DatePickerComponent.prototype.getDisplayHours = function () {
        return this.localeConfigService.isLocalTimeZone || this.userChangedHoursValue
            ? this.selectedDate.getHours()
            : this.selectedDate.getUTCHours();
    };
    DatePickerComponent.prototype.getDisplayMinutes = function () {
        return this.localeConfigService.isLocalTimeZone || this.userChangedMinutesValue
            ? this.selectedDate.getMinutes()
            : this.selectedDate.getUTCMinutes();
    };
    DatePickerComponent.prototype.setDialogInPanelPosition = function () {
        this.dialogPosition = new PositionModel(this.el.nativeElement.offsetTop + 40, // 32px is the input height inside panels, and 8px is the desired padding
        this.el.nativeElement.offsetLeft);
    };
    DatePickerComponent.prototype.setDialogPosition = function () {
        var inputClientRect = this.el.nativeElement.getBoundingClientRect(), dialogClientRect = this._dialogEl.getBoundingClientRect();
        var inputPosition = new PositionModel(inputClientRect.bottom, inputClientRect.left);
        var positionFits = PositionService.fitsPosition(inputPosition, dialogClientRect, 16);
        this.dialogPosition = new PositionModel(positionFits.bottom ? inputPosition.top : inputClientRect.top - dialogClientRect.height, positionFits.right ? inputPosition.left : inputClientRect.right - dialogClientRect.width);
    };
    DatePickerComponent.prototype.prevMonth = function () {
        this.displayDate.setMonth(this.displayDate.getMonth() - 1);
        this.setDisplayDate(this.displayDate);
    };
    DatePickerComponent.prototype.nextMonth = function () {
        this.displayDate.setMonth(this.displayDate.getMonth() + 1);
        this.setDisplayDate(this.displayDate);
    };
    DatePickerComponent.prototype.getMonthData = function (date) {
        return new DatePickerMonth(date.getFullYear(), date.getMonth(), this.localeConfigService);
    };
    DatePickerComponent.prototype.isDayDisabled = function (date) {
        if (this.earliestDateAllowed && date < this.earliestDateAllowed)
            return true;
        if (this.latestDateAllowed && date > this.latestDateAllowed)
            return true;
        if (this.allowFutureDates)
            return false;
        var tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        tomorrow.setHours(0);
        tomorrow.setMinutes(0);
        tomorrow.setSeconds(0);
        tomorrow.setMilliseconds(-1);
        return date > tomorrow;
    };
    DatePickerComponent.prototype.getDays = function () {
        // just the first sunday
        var sunday = new Date('1970-01-04T12:00:00.000Z');
        var days = [];
        for (var day = 0; day < 7; day++) {
            days.push(this.tzDateService.format(sunday, 'E', '+0000'));
            sunday.setDate(sunday.getDate() + 1);
        }
        return days;
    };
    DatePickerComponent.prototype.setCurrentTimezoneLabel = function () {
        this.timezoneLabel = this.localeConfigService.isLocalTimeZone
            ? this.i18nService.get('datepicker_timezoneLocalUTC', {
                clientTimeZone: getClientTimeZoneString(),
            })
            : this.i18nService.get('datepicker_timezoneUTC');
    };
    DatePickerComponent.prototype.setDaysFocus = function (event) {
        if (!this.currentMonth) {
            return;
        }
        this.currentMonth.weeks[0].days[0].isFocused = true;
    };
    DatePickerComponent.prototype.setDayFocus = function (dayIndex, weekIndex, event) {
        var currentWeek = this.currentMonth.weeks[weekIndex];
        var nextWeek = this.currentMonth.weeks[weekIndex + 1];
        var prevWeek = this.currentMonth.weeks[weekIndex - 1];
        var nextDay = currentWeek.days[dayIndex];
        var dayOffset = 0;
        currentWeek.days[dayIndex].isFocused = false;
        switch (event.key) {
            case 'Down': // IE/Edge specific value
            case 'ArrowDown':
                if (nextWeek && nextWeek.days[dayIndex]) {
                    dayOffset = Math.max(nextWeek.days.length - currentWeek.days.length, 0);
                    nextDay = nextWeek.days[dayIndex + dayOffset];
                }
                else if (nextWeek)
                    nextDay = nextWeek.days[nextWeek.days.length - 1];
                break;
            case 'Up': // IE/Edge specific value
            case 'ArrowUp':
                if (prevWeek) {
                    dayOffset = Math.max(currentWeek.days.length - prevWeek.days.length, 0);
                    var nextDayIndex = dayIndex < dayOffset ? 0 : dayIndex - dayOffset;
                    nextDay = prevWeek.days[nextDayIndex];
                }
                break;
            case 'Left': // IE/Edge specific value
            case 'ArrowLeft':
                if (currentWeek.days[dayIndex - 1])
                    nextDay = currentWeek.days[dayIndex - 1];
                break;
            case 'Right': // IE/Edge specific value
            case 'ArrowRight':
                if (currentWeek.days[dayIndex + 1])
                    nextDay = currentWeek.days[dayIndex + 1];
                break;
            case 'Esc': // IE/Edge specific value
            case 'Escape':
                this.showDialog = false;
                break;
        }
        nextDay.isFocused = true;
    };
    return DatePickerComponent;
}());
export { DatePickerComponent };
var DatePickerMonth = /** @class */ (function () {
    function DatePickerMonth(year, month, localeConfigService) {
        this.year = year;
        this.month = month;
        this.localeConfigService = localeConfigService;
        this.weeks = [];
        this.setWeeks();
        this.name = this.getMonthName();
    }
    DatePickerMonth.prototype.setWeeks = function () {
        var daysCount = this.getDaysCount();
        var firstDay = this.getFirstDay();
        var weeksCount = this.getWeeksCount();
        var firstWeekDay;
        var firstWeekDate;
        for (var week = 0; week < weeksCount; week++) {
            firstWeekDay = week ? 0 : firstDay;
            firstWeekDate = new Date(this.year, this.month, week ? 7 - firstDay + 1 + (week - 1) * 7 : 1);
            this.weeks.push(new DatePickerWeek(firstWeekDate, firstWeekDay, daysCount));
        }
    };
    // http://stackoverflow.com/questions/1184334/get-number-days-in-a-specified-month-using-javascript
    DatePickerMonth.prototype.getDaysCount = function () {
        return new Date(this.year, this.month + 1, 0).getDate();
    };
    /**
     * Returns the zero-based number of the day of the week on which the month begins. 0=Sunday, 1=Monday, etc.
     */
    DatePickerMonth.prototype.getFirstDay = function () {
        return new Date(this.year, this.month, 1).getDay();
    };
    DatePickerMonth.prototype.getMonthName = function () {
        var date = new Date(this.year, this.month, 1);
        return date.toLocaleString(this.localeConfigService.selectedLocale, { month: 'long' });
    };
    DatePickerMonth.prototype.getWeeksCount = function () {
        var daysCount = this.getDaysCount(), firstDay = this.getFirstDay();
        return 1 + Math.ceil((daysCount - 7 + firstDay) / 7);
    };
    return DatePickerMonth;
}());
var DatePickerWeek = /** @class */ (function () {
    function DatePickerWeek(firstDate, firstDay, lastDate) {
        this.days = [];
        this.padLeft = 0;
        this.padRight = 0;
        var month = firstDate.getMonth();
        var year = firstDate.getFullYear();
        var lastDateDate = new Date(year, month, lastDate);
        var now = new Date();
        var foundToday = now.getFullYear() !== year ||
            now.getMonth() !== month ||
            now.getDate() < firstDate.getDate() ||
            now.getDate() > lastDate;
        this.padLeft = firstDay;
        this.padRight = 6 - lastDateDate.getDay();
        for (var day = firstDay, date = firstDate.getDate(), isToday = void 0, dayDate = void 0; day < 7 && date <= lastDate; day++, date++) {
            dayDate = new Date(year, month, date);
            if (!foundToday) {
                if ((isToday = datesAreEqual(now, dayDate)))
                    foundToday = true;
            }
            else
                isToday = false;
            this.days.push(new DatePickerDay(dayDate, isToday));
        }
    }
    return DatePickerWeek;
}());
var DatePickerDay = /** @class */ (function () {
    function DatePickerDay(date, isToday) {
        this.isFocused = false;
        this.date = date;
        this.displayDate = date.getDate();
        this.isToday = isToday;
    }
    DatePickerDay.prototype.equals = function (otherDate) {
        if (!otherDate)
            return false;
        var compareTo = copyDate(otherDate);
        compareTo.setHours(0);
        compareTo.setMinutes(0);
        compareTo.setSeconds(0);
        return datesAreEqual(this.date, compareTo);
    };
    return DatePickerDay;
}());
function datesAreEqual(date1, date2) {
    if (!date1 || !date2)
        return false;
    return (date1.getFullYear() === date2.getFullYear() &&
        date1.getMonth() === date2.getMonth() &&
        date1.getDate() === date2.getDate() &&
        date1.getHours() === date2.getHours() &&
        date1.getMinutes() === date2.getMinutes() &&
        date1.getSeconds() === date2.getSeconds());
}
function copyDate(date) {
    if (!date)
        return date;
    return new Date(date.valueOf());
}
