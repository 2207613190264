<fab-message-bar *ngIf="isCustomValue"
				 [messageBarType]="MessageBarType.warning"
				 [isMultiline]="true"
>
	{{ 'tvm_baseline_profile_entity_configurationTab_custom_config_warning' | i18n }}
</fab-message-bar>
<div *ngIf="configuration"  class="tvm wcd-scroll-vertical wcd-margin-medium-top">

	<div class="tvm-list" [ngClass]="{ 'tvm-list--no-columns': mode === 'EntityPage' }">
		<div class="tvm-list__header">
			{{ 'common_description' | i18n }}
		</div>
		<div class="tvm-list__block_item" [innerHTML]="configuration.description">
		</div>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_cceReference_title' | i18n }}
			</dt>
			<dd>
				{{configuration.cce || noDataAvailable}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item">
			<dt>
				{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_category_title' | i18n }}
			</dt>
			<dd>
				{{configuration.category || noDataAvailable}}
			</dd>
		</dl>

		<dl class="tvm-list__key_val_item" *ngIf="!isDetectedValueEnabled || !singleAsset">
			<div *ngIf="!singleAsset; else singleAssetComplianceStatus">
				<dt>
					{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_compliantDevices_title' | i18n }}
				</dt>
				<dd>
					<ng-container *ngIf="!isInProgress; else inProgress">

						<div *ngIf="configuration.compliantDevices >= 0 && configuration.applicableDevices > 0; else noCompliance" class="wcd-font-weight-semibold">
							{{configuration.compliantDevices | prettyNumber}}
							<span class="color-text-gray-400">
								/ {{configuration.applicableDevices | prettyNumber}}
							</span>
						</div>
						<ng-template #noCompliance>
							<span ng>
								{{noDataAvailable}}
							</span>

						</ng-template>
					</ng-container>
					<ng-template #inProgress>
						<wcd-help [text]="'tvm_baseline_compliant_enum_in_progress_tooltip' | i18n" [showIcon]=false>
							<wcd-shared-icon [iconName]="'warning'" [iconColor]="'orange'"> </wcd-shared-icon >
							{{i18nService.strings.tvm_baseline_compliant_enum_in_progress}}
						</wcd-help>
					</ng-template>
				</dd>
			</div>
			<ng-template #singleAssetComplianceStatus>
				<dt>
					{{ 'tvm_baseline_profiles_configurations_complianceStatus_title' | i18n }}
				</dt>
				<dd>
					{{ complianceStatus }}
				</dd>
			</ng-template>
		</dl>
		<ng-container *ngIf="isDetectedValueEnabled">
			<dl class="tvm-list__key_val_item" *ngIf="configuration.expectedValues">
				<ng-container *ngIf="!isCustomValue; else customValue">
					<dt>
						{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_recommendedState' | i18n }}
					</dt>
				</ng-container>
				<ng-template #customValue>
					<dt>
						{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_customState' | i18n }}
					</dt>
				</ng-template>
				<dd>
					{{ configuration.expectedValues[0] || ('notAvailable_long' | i18n)}}
				</dd>
			</dl>
			<dl class="tvm-list__key_val_item" *ngIf="singleAsset">
				<dt>
					{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_currentState' | i18n }}
					<wcd-help [text]="'tvm_baseline_profile_entity_configurationTab_sidePanel_currentStateTooltip' | i18n"></wcd-help>
				</dt>
				<dd>
					{{ detectedValueString }}
				</dd>
			</dl>
		</ng-container>
	</div>

	<ng-container *ngIf="isDetectedValueEnabled && configuration.sourceValues">
		<div class="header ms-color-neutralPrimaryAlt wcd-font-weight-semibold">
			{{ 'tvm_baseline_profile_entity_configurationTab_sidePanel_detectedValueSource' | i18n }}
			<wcd-help [text]="'tvm_baseline_profile_entity_configurationTab_sidePanel_detectedValueSourceTooltip' | i18n"></wcd-help>
		</div>
		<div class="tvm-list__block_item">
			<span class="break-all wcd-padding-xsmall-bottom wcd-block" *ngFor="let source of sourceValues">
				{{ source }}
				<copy-to-clipboard
					[content]="source"
					[settings]="{copyText: ''}"
				></copy-to-clipboard>
				<br/>
			</span>
		</div>
	</ng-container>

	<entity-panel-collapsible-section *ngIf="!isCustomValue"
		[title]="'tvm_baseline_profile_entity_configurationTab_sidePanel_rationale_title' | i18n"
		[collapsed]="false">
		<div class="wcd-margin-medium-top" [innerHTML]="configuration.rationale">
		</div>
	</entity-panel-collapsible-section>

	<entity-panel-collapsible-section *ngIf="!isCustomValue"
		[title]="'tvm_baseline_profile_entity_configurationTab_sidePanel_remediation_title' | i18n"
		[collapsed]="false">
		<div class="wcd-margin-medium-top" [innerHTML]="configuration.remediation">
		</div>
	</entity-panel-collapsible-section>
</div>
