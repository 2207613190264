var _a;
import { AirsAppendedFile } from './airs-entity.appended-file';
import { AirsDriver } from './airs-entity.driver';
import { AirsEmail } from './airs-entity.email';
import { AirsEmailSubmission } from './airs-entity.email-submission';
import { AirsEmailCluster } from './airs-entity.email-cluster';
import { AirsEmailLink } from './airs-entity.email-link';
import { AirsFile } from './airs-entity.file';
import { AirsFileBase } from './airs-entity.file-base';
import { AirsIp } from './airs-entity.ip';
import { AirsMailbox } from './airs-entity.mailbox';
import { AirsMailboxConfiguration } from './airs-entity.mailbox-configuration';
import { AirsPersistenceMethod } from './airs-entity.persistence-method';
import { AirsProcess } from './airs-entity.process';
import { AirsService } from './airs-entity.service';
import { AirsLinkBase } from './airs-entity.link-base';
import { AirsUrl } from './airs-entity.url';
import { AirsUser } from './airs-entity.user';
import { AirsUserActivity } from './airs-entity.user-activity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsAccount } from './airs-entity.account';
import { AirsSecurityGroup } from './airs-entity.security-group';
import { AirsRegistryKey } from './airs-entity.registry-key';
import { AirsRegistryValue } from './airs-entity.registry-value';
import { AirsCloudApplication } from './airs-entity.cloud-application';
import { AirsCloudLogonSession } from './airs-entity.cloud-logon-session';
import { AirsOauthApplication } from './airs-entity.oauth-application';
import { AirsActiveDirectoryDomain } from './airs-entity.active-directory-domain';
// these classes aren't used directly in the Angular project.
// instead, they are used using paris' 'modelWith' feature.
// because of this, they are getting tree-shaked by the Angular compiler.
// this unused const is here to 'fool' the Angular compiler and avoid tree-shaking for these classes
var __airsEntitiesStub = (_a = {},
    _a[AirsEntityRefNames.AirsAppendedFile] = AirsAppendedFile,
    _a[AirsEntityRefNames.AirsDriver] = AirsDriver,
    _a[AirsEntityRefNames.AirsEmailMessage] = AirsEmail,
    _a[AirsEntityRefNames.AirsEmailMessageSubmission] = AirsEmailSubmission,
    _a[AirsEntityRefNames.AirsEmailCluster] = AirsEmailCluster,
    _a[AirsEntityRefNames.AirsEmailLink] = AirsEmailLink,
    _a[AirsEntityRefNames.AirsFile] = AirsFile,
    _a[AirsEntityRefNames.AirsIp] = AirsIp,
    _a[AirsEntityRefNames.AirsMailbox] = AirsMailbox,
    _a[AirsEntityRefNames.AirsMailboxConfiguration] = AirsMailboxConfiguration,
    _a[AirsEntityRefNames.AirsPersistenceMethod] = AirsPersistenceMethod,
    _a[AirsEntityRefNames.AirsProcess] = AirsProcess,
    _a[AirsEntityRefNames.AirsService] = AirsService,
    _a[AirsEntityRefNames.AirsUrl] = AirsUrl,
    _a[AirsEntityRefNames.AirsUser] = AirsUser,
    _a[AirsEntityRefNames.AirsUserActivity] = AirsUserActivity,
    _a[AirsEntityRefNames.Account] = AirsAccount,
    _a[AirsEntityRefNames.AirsSecurityGroup] = AirsSecurityGroup,
    _a[AirsEntityRefNames.AirsRegistryKey] = AirsRegistryKey,
    _a[AirsEntityRefNames.AirsRegistryValue] = AirsRegistryValue,
    _a[AirsEntityRefNames.AirsCloudApplication] = AirsCloudApplication,
    _a[AirsEntityRefNames.AirsCloudLogonSession] = AirsCloudLogonSession,
    _a[AirsEntityRefNames.AirsOauthApplication] = AirsOauthApplication,
    _a[AirsEntityRefNames.AirsActiveDirectoryDomain] = AirsActiveDirectoryDomain,
    _a);
export { AirsAppendedFile, AirsDriver, AirsEmail, AirsEmailSubmission, AirsEmailCluster, AirsEmailLink, AirsFile, AirsFileBase, AirsIp, AirsMailbox, AirsMailboxConfiguration, AirsPersistenceMethod, AirsProcess, AirsService, AirsLinkBase, AirsUrl, AirsUser, AirsUserActivity, AirsAccount, AirsSecurityGroup, AirsRegistryKey, AirsRegistryValue, AirsCloudApplication, AirsCloudLogonSession, AirsOauthApplication, AirsActiveDirectoryDomain, };
