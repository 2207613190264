import { Component, ChangeDetectorRef, OnInit, ChangeDetectionStrategy } from '@angular/core';
import {
	HuntingQueryResultRecord,
	CyberEvent,
	HuntingRecordExtendApiCall,
	HuntingRecordResponse,
	ImpactedEntities,
	HuntingRecordEntitiesResponse,
	HuntingRecordEntitiesApiCall,
	Alert,
	EntityTypes,
} from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { columnClickEventPayload, HuntingGridColumn } from '@wcd/hunting';
import { Paris } from '@microsoft/paris';
import { take } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { ContentState } from '@wcd/contents-state';
import { CyberEventsActionTypesService } from '../../cyber_events/services/cyber-events-action-types.service';
import { isEmpty, isObjectLike } from 'lodash-es';
import { AjaxError } from 'rxjs/ajax';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';

export interface HuntingQueryResultRecordPanelOptions {
	columnsInfo: HuntingGridColumn[];
	asKeyValuesListDisplay?: boolean;
}

@Component({
	selector: 'hunting-record-entity-panel',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<wcd-collapsible-section [label]="assetsSectionTitle">
			<wcd-contents-state [contentState]="mainEntitiesSectionState">
				<ng-container loading *ngTemplateOutlet="loading"></ng-container>
				<ng-container
					error
					*ngTemplateOutlet="error; context: { asset: assetsSectionTitle.toLowerCase() }"
				></ng-container>
				<ng-container empty *ngTemplateOutlet="entitiesEmpty"></ng-container>
				<ng-container complete *ngTemplateOutlet="entitiesComplete"></ng-container>

				<ng-template #entitiesEmpty>
					{{ 'hunting_results_record_panel_sections_assets_empty' | i18n }}
				</ng-template>

				<ng-template #entitiesComplete>
					<impacted-entities-datatables
						#impactedEntities
						*ngIf="mainEntities"
						[openPanelForAllItems]="true"
						[showTitle]="false"
						[entities]="mainEntities"
					>
					</impacted-entities-datatables>
				</ng-template>
			</wcd-contents-state>
		</wcd-collapsible-section>
		<wcd-collapsible-section [label]="processTreeSectionTitle">
			<wcd-contents-state [contentState]="processTreeSectionState">
				<ng-container loading *ngTemplateOutlet="loading"></ng-container>
				<ng-container
					error
					*ngTemplateOutlet="error; context: { asset: processTreeSectionTitle.toLowerCase() }"
				></ng-container>
				<ng-container complete *ngTemplateOutlet="complete"></ng-container>
				<ng-container empty *ngTemplateOutlet="unsupportedCyberEvent"></ng-container>

				<ng-template #complete>
					<cyber-event-entities-graph
						*ngIf="isSupportedCyberEventActionType; else unsupportedCyberEvent"
						[event]="cyberEvent"
						[collapseEntities]="'parents'"
					>
					</cyber-event-entities-graph>
				</ng-template>
				<ng-template #unsupportedCyberEvent>
					{{ 'hunting.results.record.panel.sections.processTree.unsupportedEvent' | i18n }}
				</ng-template>
			</wcd-contents-state>
		</wcd-collapsible-section>
		<wcd-collapsible-section [label]="'hunting_results_record_panel_sections_all_title' | i18n">
			<div
				class="wcd-full-height wcd-flex-vertical"
				data-track-component="HuntingQueryResultRecordEntityPanel"
			>
				<dl [class.key-values]="asKeyValueList" role="none">
					<ng-container *ngFor="let columnInfo of columnsInfo">
						<ng-container *ngIf="showPropertyInPanel(columnInfo)">
							<dt>{{ columnInfo.name }}</dt>
							<dd>
								<hunting-record-property-display
									[columnInfo]="columnInfo"
									[propertyData]="record[columnInfo.name]"
									[link]="
										(columnInfo.getRouteLinkModel &&
											columnInfo.getRouteLinkModel(columnInfo, record)) ||
										null
									"
									[presentObjectValuesAsTable]="true"
									(openEntitySidePanelClickEvent)="onOpenEntitySidePanelClicked($event)"
								>
								</hunting-record-property-display>
							</dd>
						</ng-container>
					</ng-container>
				</dl>
			</div>
		</wcd-collapsible-section>

		<ng-template #loading>
			<fab-spinner></fab-spinner>
		</ng-template>

		<ng-template #error let-asset="asset">
			<span>{{ 'help.externalLoadError' | i18n: { asset: asset } }}</span>
		</ng-template>

		<ng-template #missingIdentifiers let-text="text">
			{{ text }}
		</ng-template>
	`,
})
export class HuntingQueryResultRecordEntityPanelComponent
	extends EntityPanelComponentBase<HuntingQueryResultRecord, HuntingQueryResultRecordPanelOptions>
	implements OnInit {
	get record(): { [key: string]: any } {
		return this.entity.dataItem;
	}

	get columnsInfo(): HuntingGridColumn[] {
		return this.options && this.options.columnsInfo;
	}

	get asKeyValueList(): boolean {
		return this.options && this.options.asKeyValuesListDisplay;
	}

	mainEntities: ImpactedEntities;
	cyberEvent: CyberEvent;
	processTreeSectionState: ContentState;
	mainEntitiesSectionState: ContentState;
	isSupportedCyberEventActionType: boolean = false;
	processTreeSectionTitle: string;
	assetsSectionTitle: string;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private i18nService: I18nService,
		private cyberEventActionTypeService: CyberEventsActionTypesService,
		private entityPanelsService: EntityPanelsService
	) {
		super(changeDetectorRef);
	}

	ngOnInit() {
		this.processTreeSectionTitle = this.i18nService.get(
			'hunting_results_record_panel_sections_processTree_title'
		);
		this.assetsSectionTitle = this.i18nService.get('hunting_results_record_panel_sections_assets_title');

		this.initCyberEvent();
		this.initEntities();
	}

	showPropertyInPanel(columnInfo: HuntingGridColumn): boolean {
		const value = this.record[columnInfo.name];
		if ([null, '', undefined].includes(value)) {
			return false;
		}

		let parsedJsonValue;
		try {
			parsedJsonValue = JSON.parse(value);
		} catch {
			// not a json value, also not empty / null.
			return true;
		}

		// show property if it isn't object / array, or if it isn't an empty object / array.
		return !isObjectLike(parsedJsonValue) || !isEmpty(parsedJsonValue);
	}

	private initEntities() {
		this.mainEntitiesSectionState = ContentState.Loading;
		this.paris
			.apiCall<HuntingRecordEntitiesResponse, HuntingQueryResultRecord>(
				HuntingRecordEntitiesApiCall,
				this.entity
			)
			.pipe(take(1))
			.subscribe(
				(res: HuntingRecordEntitiesResponse) => {
					if (!res || !res.impactedEntities) {
						this.mainEntitiesSectionState = ContentState.Error;
						this.changeDetectorRef.markForCheck();
						return;
					}

					if (
						(!res.impactedEntities.machines || !res.impactedEntities.machines.length) &&
						(!res.impactedEntities.mailboxes || !res.impactedEntities.mailboxes.length) &&
						(!res.impactedEntities.users || !res.impactedEntities.users.length)
					) {
						// empty impacted entities
						this.mainEntitiesSectionState = ContentState.Empty;
						this.changeDetectorRef.markForCheck();
						return;
					}

					this.mainEntities = res.impactedEntities;
					this.mainEntitiesSectionState = ContentState.Complete;
					this.changeDetectorRef.markForCheck();
				},
				(e: AjaxError) => {
					this.mainEntitiesSectionState =
						e.status === 404 ? ContentState.Empty : ContentState.Error;
					this.changeDetectorRef.markForCheck();
				}
			);
	}

	private initCyberEvent() {
		this.processTreeSectionState = ContentState.Loading;
		this.paris
			.apiCall<HuntingRecordResponse, HuntingQueryResultRecord>(HuntingRecordExtendApiCall, this.entity)
			.pipe(take(1))
			.subscribe(
				(res: HuntingRecordResponse) => {
					if (!res || !res.cyberEvent) {
						this.processTreeSectionState = ContentState.Error; // Unexpected response
						this.changeDetectorRef.markForCheck();
						return;
					}

					this.cyberEvent = res.cyberEvent;
					this.isSupportedCyberEventActionType =
						this.cyberEventActionTypeService.isCyberEventSupportedByProcessTree(
							this.cyberEvent
						) && this.hasProcessTreeProperties(this.cyberEvent);

					this.processTreeSectionState = ContentState.Complete;
					this.changeDetectorRef.markForCheck();
				},
				(e: AjaxError) => {
					/**
					 * Expected empty state statuses:
					 * 404 - Event identifiers (time, report index and machine id) provided but the event was not found in database.
					 * 422 - Request is valid but BE was unable to convert the hunting record data to telemetry action / cyber event, that is used for building process tree.
					 */
					this.processTreeSectionState = [404,422].includes(e.status) ? ContentState.Empty : ContentState.Error;
					this.changeDetectorRef.markForCheck();
				}
			);
	}

	private hasProcessTreeProperties(cyberEvent: CyberEvent): boolean {
		return Boolean(
			cyberEvent.target ||
				cyberEvent.source ||
				cyberEvent.sourceParent ||
				cyberEvent.sourceParentParent ||
				(cyberEvent.registryModificationDetails && cyberEvent.registryModificationDetails.current) ||
				(cyberEvent.registryModificationDetails && cyberEvent.registryModificationDetails.previous) ||
				cyberEvent.localEndpoint ||
				cyberEvent.remoteEndpoint
		);
	}

	onOpenEntitySidePanelClicked($event: columnClickEventPayload) {
		if ($event.entityType == EntityTypes.alert) {
			this.entityPanelsService.showEntityById(Alert, $event.ItemId, null, {
				back: {
					onClick: () => {
						this.entityPanelsService.closeEntityPanel(Alert);
					},
				},
				hasCloseButton: false,
			});
		}
	}
}
