import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { HasSecureScore } from '../common/secure-score/has-secure-score.interface';
import { HasExposureScore } from '../common/exposure-score/has-exposure-score.interface';
import { ExposureScoreCategory } from '../common/exposure-score/exposure-score-category.enum';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { AssetRecommendationContext } from '../asset/asset-recommendation-context.entity';
import { TvmSupportedOsPlatform } from '../common/tvm-supported-os-platform.enum';

@Entity({
	...TvmAnalyticsSharedEntityConfigurations,
	singularName: 'Asset',
	pluralName: 'Assets',
	endpoint: 'assets',
	parseItemQuery: id => {
		return `assets/${id}`;
	},
	readonly: true,
})
export class Asset extends EntityModelBase<string> implements HasSecureScore, HasExposureScore {
	@EntityField() readonly name: string;

	@EntityField() readonly osName: string;

	@EntityField({
		data: 'recommendationContext',
		arrayOf: AssetRecommendationContext,
	})
	recommendationContext: Array<AssetRecommendationContext>;

	@EntityField() readonly exposureScore: number;

	@EntityField() readonly exposureLevel: ExposureScoreCategory;

	@EntityField() readonly secureScore: number;

	@EntityField() readonly maxSecureScore: number;

	@EntityField() readonly securityRecommendations?: number;

	@EntityField() readonly discoveredVulnerabilities?: number;

	@EntityField() readonly isUserProductivityImpacted?: boolean;

	@EntityField() readonly isFastlaneSource?: boolean;

	@EntityField() readonly affectedSoftwareIds?: string[];

	@EntityField() readonly numOfPaths?: number;

	@EntityField() readonly mitigationStatus?: string;

	@EntityField({
		parse: (date: string) => new Date(date).getTime(),
	})
	readonly lastSeen: string;

	@EntityField({
		parse: (_, Asset) => new Date(Asset.lastSeen).toLocaleString(),
	})
	readonly lastSeenString?: string;

	@EntityField() readonly osPlatform?: TvmSupportedOsPlatform;

	@EntityField() readonly osVersion?: string;

	@EntityField() readonly isManagedByMdatp?: boolean;
}
