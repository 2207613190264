import { Injectable } from '@angular/core';
import { EvaluationMachine, EvaluationLab } from '@wcd/domain';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import { EntityTypeService } from '../../global_entities/models/entity-type-service.interface';

@Injectable()
export class EvaluationEntityTypeService implements EntityTypeService<EvaluationLab> {
	readonly entityType: EntityType<EvaluationLab> = {
		entity: EvaluationLab,
		id: 'index',
	};
}
