import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import {
	CustomTiIndicator,
	CustomTiIndicatorActionsTypes,
	CustomTiIndicatorSeverityType,
	CustomTiIndicatorsType,
	CustomTiIndicatorsTypes,
	IndicatorAlertCategory,
	KnownRequestSource,
	MachineGroup,
} from '@wcd/domain';
import { CheckboxComponent, ChecklistValue } from '@wcd/forms';
import { I18nService } from '@wcd/i18n';
import { cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { combineLatest, Subscription } from 'rxjs';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { RegExpService } from '@wcd/shared';
import { MachinesService } from '../../machines/services/machines.service';
import {
	CustomTiIndicatorComponentService,
	MAX_BYPASSDURATION,
	ScopeTypesEnum,
} from '../services/custom-ti-indicator-component.service';
import { CustomTiIndicatorsService } from '../services/custom-ti-indicators.service';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AuthService } from '@wcd/auth';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppFlavorConfig } from '@wcd/scc-common';

declare const moment: typeof import('moment');

enum CollapsibleID {
	Details = 'custom-ti-indicator-details',
	ResponseAction = 'custom-ti-indicator-response-action',
	OrganizationalScope = 'custom-ti-indicator-organizational-scope',
}

@Component({
	selector: 'custom-ti-indicator-details',
	templateUrl: './custom-ti-indicator-details.component.html',
	styles: [
		`
			:host {
				height: 100%;
				flex-direction: column;
				display: flex;
			}
		`,
	],
	providers: [CustomTiIndicatorComponentService],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomTiIndicatorEditComponent extends EntityPanelComponentBase<CustomTiIndicator> {
	readonly CustomTiIndicatorActionsTypes = CustomTiIndicatorActionsTypes;
	readonly RegExpService = RegExpService;
	readonly SpinnerSize = SpinnerSize;
	readonly ScopeTypesEnum = ScopeTypesEnum;
	readonly MAX_BYPASSDURATION = MAX_BYPASSDURATION;
	readonly CustomTiIndicatorsTypes = CustomTiIndicatorsTypes;
	readonly indicatorTypeService: EntityType<CustomTiIndicator>;

	asKeyValueList: boolean = true;
	currentMachineGroupsNames: Array<string>;
	otherMachineGroupsCount: number = 0;
	isSaving: boolean = false;
	severityList: Array<CustomTiIndicatorSeverityType>;
	isDirty: boolean = false;
	entityRouteLink: string;
	entityRouteLinkId: string;
	collapsibleID = CollapsibleID;
	tvmApplicationBlockEnabled: boolean;
	historicalDetectionEnabled: boolean;
	mitreTechniquesEnabled: boolean;
	allowBlockOnlyForUrlIndicator: boolean;
	isEditable: boolean;
	isOrganizationalScopeVisible: boolean;

	@ViewChild('generateAlert', { static: false }) generateAlertCheckbox: CheckboxComponent;

	private _entityType: CustomTiIndicatorsType;
	private _now: Date = new Date();
	private _saveCustomTiSubscription: Subscription;
	private _customTiIndicatorRepo: Repository<CustomTiIndicator>;
	private _machineGroupsRepo: Repository<MachineGroup>;
	private _onMachineGroupsChangedSubscription: Subscription;
	private _machineGroupSubscription: Subscription;

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		changeDetectorRef: ChangeDetectorRef,
		public machinesService: MachinesService,
		private entityPanelsService: EntityPanelsService,
		private customTiIndicatorsService: CustomTiIndicatorsService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
		public indicatorMachineComponentService: CustomTiIndicatorComponentService,
		private authService: AuthService,
		globalEntityTypesService: GlobalEntityTypesService
	) {
		super(changeDetectorRef);

		this._customTiIndicatorRepo = paris.getRepository(CustomTiIndicator);
		this._machineGroupsRepo = paris.getRepository(MachineGroup);

		this.indicatorMachineComponentService.getFormatSeverityLabel = this.indicatorMachineComponentService.getFormatSeverityLabel.bind(
			this
		); // this function is called from a child component and requires this component's context
		this.indicatorMachineComponentService.getFormatCategoryLabel = this.indicatorMachineComponentService.getFormatCategoryLabel.bind(
			this
		); // this function is called from a child component and requires this component's context
		this.tvmApplicationBlockEnabled = this.featuresService.isEnabled(Feature.TVMApplicationBlock);
		this.historicalDetectionEnabled = this.featuresService.isEnabled(Feature.HistoricalDetection);
		this.mitreTechniquesEnabled = this.featuresService.isEnabled(Feature.IndicatorMitreTechniques);
		this.allowBlockOnlyForUrlIndicator = this.featuresService.isEnabled(
			Feature.AllowBlockOnlyForUrlIndicator
		);

		this.indicatorTypeService = globalEntityTypesService.getEntityType(CustomTiIndicator);
		this.isOrganizationalScopeVisible = this.flavorService.isEnabled(AppFlavorConfig.settings.indicators);
	}

	ngOnInit() {
		super.ngOnInit();

		this.severityList = this.paris.getRepository<CustomTiIndicatorSeverityType>(
			CustomTiIndicatorSeverityType
		).entity.values;

		this.indicatorMachineComponentService.setIsAlertable(this.customTiIndicator);
		this._setIsEditable();

		if (this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
			this._machineGroupSubscription = this.indicatorMachineComponentService
				.setMachineGroups(this.customTiIndicator)
				.subscribe(
					() => {
						this.indicatorMachineComponentService.setCurrentMachineGroups(
							this.customTiIndicator.rbacGroupIds
						);
						this.indicatorMachineComponentService.loadingMachineGroups = false;
						setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
					},
					(error) => {
						this.indicatorMachineComponentService.loadingMachineGroups = false;
						setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
					}
				);
		} else {
			this.indicatorMachineComponentService.loadingMachineGroups = false;
		}

		this.indicatorMachineComponentService.init(this.customTiIndicator, this.customTiIndicatorType);
		setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
	}

	ngOnDestroy() {
		this._saveCustomTiSubscription && this._saveCustomTiSubscription.unsubscribe();
		this._onMachineGroupsChangedSubscription && this._onMachineGroupsChangedSubscription.unsubscribe();
		this._machineGroupSubscription && this._machineGroupSubscription.unsubscribe();
		this.indicatorMachineComponentService.destroy();
	}

	get customTiIndicator(): CustomTiIndicator {
		return this.entity;
	}

	get customTiIndicatorType(): CustomTiIndicatorsType {
		return this._entityType;
	}

	get customTiIcon() {
		return this.customTiIndicatorsService.getIcon(this._entityType.id);
	}

	get isExpired() {
		return this.customTiIndicator.expirationTime && this.customTiIndicator.expirationTime < this._now;
	}

	get utcExpirationAsLocal() {
		return this.customTiIndicatorsService.reinterpretUtcAsLocalTime(
			this.customTiIndicator.expirationTime
		);
	}

	setEntityPageRouterLink(): void {
		switch (this.customTiIndicatorType.id) {
			case CustomTiIndicatorsTypes.Files: {
				this.entityRouteLinkId = 'file';
				break;
			}
			case CustomTiIndicatorsTypes.Ip: {
				if (this.customTiIndicator.IsIpRange) {
					this.entityRouteLink = null;
					return;
				}

				this.entityRouteLinkId = 'ip';
				break;
			}
			case CustomTiIndicatorsTypes.Url: {
				this.entityRouteLinkId = 'url';
				break;
			}
			default: {
				this.entityRouteLink = null;
				return;
			}
		}

		this.entityRouteLink = this.indicatorTypeService.getEntitiesLink([this.customTiIndicator]);
	}

	setEntity(entity: CustomTiIndicator) {
		const clonedEntity: CustomTiIndicator = cloneDeep(entity);
		super.setEntity(clonedEntity);

		this._entityType = this.paris.getValue<CustomTiIndicatorsType, string>(
			CustomTiIndicatorsType,
			clonedEntity.tiIndicatorType.type
		);
		this.setEntityPageRouterLink();
	}

	save() {
		this.isSaving = true;
		this.indicatorMachineComponentService.resetRedundantFields(this.customTiIndicator);
		this._saveCustomTiSubscription = this._customTiIndicatorRepo.save(this.customTiIndicator).subscribe(
			() => {
				this.isSaving = false;
				setTimeout(() => {
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get('customTiIndicator.detailsSidePane.indicatorSaved'),
					});
				});
				this.entityPanelsService.closeEntityPanel(CustomTiIndicator);
				this.requestEntitiesRefresh.emit();
				this.changeDetectorRef.markForCheck();
			},
			(error) => {
				this.isSaving = false;
				this.dialogsService.showError({
					title: this.i18nService.get('customTiIndicator_error_FailedToUpdateCustomTI'),
					data: error,
				});
			}
		);
	}

	get isValid(): boolean {
		if (!this.customTiIndicator.description || this.customTiIndicator.description.trim() === '') {
			return false;
		}

		if (!this.customTiIndicator.title || this.customTiIndicator.title.trim() === '') {
			return false;
		}

		if (
			this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Warn &&
			!this.indicatorMachineComponentService.isWarnDetailsValid(this.customTiIndicator)
		) {
			return false;
		}

		if (
			this.indicatorMachineComponentService.currentMachineGroupScope.id === ScopeTypesEnum.specific &&
			this.indicatorMachineComponentService.currentMachineGroups.length === 0
		) {
			return false;
		}

		//// Validate alerts details
		if (
			this.indicatorMachineComponentService.currentActionOption &&
			this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Allow
		) {
			return true;
		}

		if (this.tvmApplicationBlockEnabled) {
			return (
				(this.customTiIndicator.generateAlert && !!this.customTiIndicator.severity) ||
				!this.customTiIndicator.generateAlert
			);
		} else {
			return (
				this.indicatorMachineComponentService.currentActionOption &&
				(this.indicatorMachineComponentService.currentActionOption.id ===
					CustomTiIndicatorActionsTypes.Warn ||
					this.indicatorMachineComponentService.currentActionOption.id ===
						CustomTiIndicatorActionsTypes.Block ||
					!!this.customTiIndicator.severity)
			);
		}
	}

	onMachineGroupScopeChange = (newOption: ChecklistValue) => {
		this.indicatorMachineComponentService.allowSpecificMachineGroups =
			newOption ===
			this.indicatorMachineComponentService.selectableMachineGroupScopes[ScopeTypesEnum.specific];

		if (!this.indicatorMachineComponentService.allowSpecificMachineGroups) {
			this.indicatorMachineComponentService.setCurrentMachineGroups(
				(this.customTiIndicator.rbacGroupIds = this.indicatorMachineComponentService.currentMachineGroups = [])
			);
		}
		this.isDirty = true;
	};

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!selectedMachineGroups || !selectedMachineGroups.length) {
			this.indicatorMachineComponentService.setCurrentMachineGroups(
				(this.customTiIndicator.rbacGroupIds = [])
			);
			this.isDirty = true;
		} else {
			this._onMachineGroupsChangedSubscription = combineLatest(
				selectedMachineGroups.map((selectedGroup: ChecklistValue) =>
					this._machineGroupsRepo.getItemById(selectedGroup.id)
				)
			).subscribe((machineGroups: Array<MachineGroup>) => {
				const machineGroupIds: Array<number> = machineGroups.map((group: MachineGroup) => group.id);
				this.indicatorMachineComponentService.setCurrentMachineGroups(
					(this.customTiIndicator.rbacGroupIds = machineGroupIds)
				);
				this.isDirty = true;
			});
		}
	}

	onActionOptionsChange = (newOption: ChecklistValue) => {
		this.indicatorMachineComponentService.onActionOptionsChange(
			newOption,
			this.customTiIndicator,
			this.customTiIndicatorType,
			this.generateAlertCheckbox
		);
		this.isDirty = true;
	};

	onGenerateAlertChange = (shouldGenenerateAlert: boolean) => {
		this.indicatorMachineComponentService.setGenerateAlertChange(
			shouldGenenerateAlert,
			this.customTiIndicator
		);
	};

	getMitreTechniqueDropDownPlaceHolder(): string {
		return this.indicatorMachineComponentService.getMitreTechniqueDropDownPlaceHolder(
			this.customTiIndicator
		);
	}

	onCategoryChanged($event: IndicatorAlertCategory) {
		this.isDirty = true;
		this.indicatorMachineComponentService.onCategoryChanged($event, this.customTiIndicator);
	}

	private _setIsEditable() {
		if (
			this.customTiIndicator.createdBySource &&
			this.customTiIndicator.createdBySource.id === KnownRequestSource.TVM
		) {
			this.isEditable = false;
			return;
		}

		this.isEditable =
			!this.customTiIndicator.rbacGroupIds || this.customTiIndicator.rbacGroupIds.length === 0
				? this.authService.currentUser.isMdeAdmin
				: true;
	}

	get isGenerateAlertDisabled() {
		return (
			this.indicatorMachineComponentService.currentActionOption &&
			(this.indicatorMachineComponentService.currentActionOption.id ===
				CustomTiIndicatorActionsTypes.Audit ||
				this.indicatorMachineComponentService.currentActionOption.id ===
					CustomTiIndicatorActionsTypes.Allow)
		);
	}
}
