
		<dl [class.key-values]="asKeyValueList" role="none">
			<dt>{{ 'alerts.events.details.actionTime' | i18n }}</dt>
			<dd>{{ action.actionTime | date: 'medium' }}</dd>
		</dl>
		<ng-container *ngIf="action.process">
			<process-entity-details
				[entity]="action.process"
				[showEntityPanelLink]="false"
				[asKeyValueList]="asKeyValueList"
			></process-entity-details>
		</ng-container>
	