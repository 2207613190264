/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./baseline-compliant-bar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../../projects/prettify/src/lib/pipes/pretty-number.pipe";
import * as i3 from "../../../../../../../projects/prettify/src/lib/services/pretty-number.service";
import * as i4 from "../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i5 from "@angular/common";
import * as i6 from "../../../shared/components/events-summary-bar/events-summary-bar.component.ngfactory";
import * as i7 from "../../../shared/components/events-summary-bar/events-summary-bar.component";
import * as i8 from "../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i9 from "@angular-react/fabric";
import * as i10 from "./baseline-compliant-bar.component";
import * as i11 from "../../services/baseline-compliant-color.service";
import * as i12 from "../../pipes/calculate-percentage.pipe";
import * as i13 from "../../services/baseline-compliant-bar.service";
var styles_BaselineCompliantBarComponent = [i0.styles];
var RenderType_BaselineCompliantBarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_BaselineCompliantBarComponent, data: {} });
export { RenderType_BaselineCompliantBarComponent as RenderType_BaselineCompliantBarComponent };
function View_BaselineCompliantBarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "wcd-flex-justify-end-horizontal wcd-font-weight-semibold text-align-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n\t\t\t"])), i1.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i1.ChangeDetectorRef]]), (_l()(), i1.ɵeld(3, 0, null, null, 2, "span", [["class", "color-text-gray-400"]], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["\n\t\t\t\t/ ", "\n\t\t\t"])), i1.ɵpid(131072, i2.PrettyNumberPipe, [i3.PrettyNumberService, [2, i4.LocaleConfigService], [2, i1.ChangeDetectorRef]]), (_l()(), i1.ɵted(-1, null, ["\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.compliantCount)); _ck(_v, 1, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 4, 0, i1.ɵnov(_v, 5).transform(_co.total)); _ck(_v, 4, 0, currVal_1); }); }
function View_BaselineCompliantBarComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "wcd-margin-xsmall-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["\n\t\t\t", "\n\t\t"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.percentage; _ck(_v, 1, 0, currVal_0); }); }
function View_BaselineCompliantBarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [["class", "wcd-flex-horizontal"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_3)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_4)), i1.ɵdid(6, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.compliantCount >= 0) && (_co.total > 0)); _ck(_v, 3, 0, currVal_0); var currVal_1 = (_co.showPercentage && _co.percentage); _ck(_v, 6, 0, currVal_1); }, null); }
function View_BaselineCompliantBarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_2)), i1.ɵdid(3, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "events-summary-bars", [["class", "events-summary-bars wcd-margin-xsmall-top wcd-flex-auto"]], null, null, null, i6.View_EventsSummaryBarComponent_0, i6.RenderType_EventsSummaryBarComponent)), i1.ɵdid(6, 573440, null, 0, i7.EventsSummaryBarComponent, [], { events: [0, "events"], kindColorMap: [1, "kindColorMap"], displayTextFormatter: [2, "displayTextFormatter"], size: [3, "size"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNumbers; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.events; var currVal_2 = _co.colorMap; var currVal_3 = _co.textFormatter; var currVal_4 = _co.size; _ck(_v, 6, 0, currVal_1, currVal_2, currVal_3, currVal_4); }, null); }
function View_BaselineCompliantBarComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "span", [["class", "wcd-inline-block wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "fab-icon", [["iconName", "SquareShapeSolid"]], null, null, null, i8.View_FabIconComponent_0, i8.RenderType_FabIconComponent)), i1.ɵdid(3, 5816320, null, 0, i9.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { contentClass: [0, "contentClass"], iconName: [1, "iconName"] }, null), (_l()(), i1.ɵted(4, null, ["\n\t\t", "\n\t"]))], function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "wcd-vertical-align-bottom ", _v.context.$implicit.iconClassName, ""); var currVal_1 = "SquareShapeSolid"; _ck(_v, 3, 0, currVal_0, currVal_1); }, function (_ck, _v) { var currVal_2 = _v.context.$implicit.name; _ck(_v, 4, 0, currVal_2); }); }
function View_BaselineCompliantBarComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "wcd-padding-mediumSmall-top"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_6)), i1.ɵdid(3, 278528, null, 0, i5.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_BaselineCompliantBarComponent_0(_l) { return i1.ɵvid(2, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_1)), i1.ɵdid(1, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BaselineCompliantBarComponent_5)), i1.ɵdid(4, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.events; _ck(_v, 1, 0, currVal_0); var currVal_1 = (_co.showLegend && _co.events); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_BaselineCompliantBarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "baseline-compliant-bar", [], null, null, null, View_BaselineCompliantBarComponent_0, RenderType_BaselineCompliantBarComponent)), i1.ɵdid(1, 638976, null, 0, i10.BaselineCompliantBarComponent, [i1.ChangeDetectorRef, i11.BaselineCompliantColorService, i12.CalculatePercentagePipe, i13.BaselineCompliantBarService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BaselineCompliantBarComponentNgFactory = i1.ɵccf("baseline-compliant-bar", i10.BaselineCompliantBarComponent, View_BaselineCompliantBarComponent_Host_0, { showLegend: "showLegend", size: "size", compliantCount: "compliantCount", notCompliantCount: "notCompliantCount", dataUnavailableCount: "dataUnavailableCount", total: "total", showPercentage: "showPercentage", showNumbers: "showNumbers" }, {}, []);
export { BaselineCompliantBarComponentNgFactory as BaselineCompliantBarComponentNgFactory };
