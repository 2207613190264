import {
	Component,
	Input,
	OnInit,
	OnDestroy,
	ChangeDetectionStrategy,
	EventEmitter,
	Output,
	ChangeDetectorRef,
} from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import {
	CustomTimeRangeValue,
	TimeRangeValue,
	isCustomTimeRangeValue,
	SpecificTimeRangeValue,
} from '@wcd/date-time-picker';
import { DateRangeModel, TzDateService, LocaleConfigService } from '@wcd/localization';
import { DataviewActionTypes } from './dataview-actions.model';
import { Panel, PanelType } from '@wcd/panels';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { PreferencesService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ActivatedRoute } from '@angular/router';
import { cloneDeep } from 'lodash-es';
import { Positions } from '@wcd/forms';
import { sccHostService } from '@wcd/scc-interface';

declare const moment: typeof import('moment');

interface TimebarRange {
	from: Date;
	to: Date;
}

export interface DataviewActionCustomRangeConfig {
	currentRange: TimeRangeValue;
	timeRanges: TimeRangeValue[];
	customDateRange: DateRangeModel;
	onNewRangeSelectedCallback: (currentRange: TimeRangeValue, customDateRange: DateRangeModel) => void;
	actionType: DataviewActionTypes;
	paddingTop?: boolean;
	preferenceId: string;
	dataViewId: string;
	validateRange: (range: TimebarRange) => TimebarRange | null;
	dateFormat?: string;
}

const PANEL_SETTINGS: Panel = new Panel({
	type: PanelType.medium,
	headerText: '',
	showOverlay: false,
	isBlocking: false,
	isModal: true,
	isStatic: false,
	noBodyPadding: true,
});
@Component({
	selector: 'dataview-action-custom-range',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container dataview-controls>
			<wcd-select
				[openMenuPosition]="openMenuPosition"
				[(ngModel)]="currentRange"
				(ngModelChange)="onRangeTypeSelect($event)"
				class="hidden-action command-bar-item-dropdown"
				[isBordered]="false"
				[buttonIcon]="icon"
				label="name"
				[wcdTooltip]="formatLabel(currentRange, true)"
				[values]="timeRanges"
				[formatLabel]="formatLabel"
				[useValueAsButtonText]="!smallScreenView"
				[showIconDropdown]="!smallScreenView"
				(selectorStateChanged)="toggleSelectorState($event)"
			></wcd-select>

			<wcd-panel
				(close)="togglePanel(false)"
				class="responsive-panel"
				[class.wcd-padding-large-top]="paddingTop"
				[settings]="panelSettings"
				*ngIf="customRangePanelOpened"
			>
				<div class="daterange-panel-content">
					<daterange
						class="wcd-flex-1 wcd-scroll-vertical"
						[showInPanel]="true"
						[verticalDirection]="true"
						[(ngModel)]="customDateRange"
						[allowExactTimeSelection]="true"
						(ngModelChange)="onRangeSelect()"
					></daterange>
					<div
						class="wcd-flex-none wcd-padding-horizontal wcd-padding-small-vertical wcd-border-top"
					>
						<ng-container>
							<fab-primary-button
								[disabled]="!isDirty"
								(onClick)="apply()"
								[text]="i18nService.strings.buttons_apply"
								className="wcd-margin-xsmall-right"
							></fab-primary-button>
							<fab-default-button
								(onClick)="cancel()"
								[text]="i18nService.strings.buttons_cancel"
							></fab-default-button>
						</ng-container>
					</div>
				</div>
			</wcd-panel>
		</ng-container>
	`,
})
export class DataviewActionCustomRangeComponent implements OnInit, OnDestroy {
	@Input() customRangeActionConfig: DataviewActionCustomRangeConfig;
	@Input() smallScreenView: boolean = false;
	@Input() openMenuPosition: Positions = Positions.Default;
	@Output() toggleRangeSelectorState: EventEmitter<boolean> = new EventEmitter();

	toggleSelectorState = (isOpen: boolean) => {
		this.toggleRangeSelectorState.emit(isOpen);
	};
	currentRange: TimeRangeValue;
	originalCurrentRange: TimeRangeValue;
	allowTimeRangeSelect: boolean;
	timeRanges: TimeRangeValue[];
	isCustomTimeRangeValue: (timeRange: TimeRangeValue) => timeRange is CustomTimeRangeValue;
	customDateRange: DateRangeModel;
	onNewRangeSelectedCallback: (currentRange?: TimeRangeValue, customDateRange?: DateRangeModel) => void;
	icon: string = FabricIconNames.Calendar;
	paddingTop: boolean = false;
	customRangePanelOpened: boolean;
	panelSettings: Panel;
	preferenceId: string;
	originalCustomDateRange: DateRangeModel;
	dataViewId: string;
	customRangeFromDisplay: string;
	customRangeToDisplay: string;
	formatLabel: (value: TimeRangeValue, isSelectionItem: boolean) => string;
	routeSubscription: any;
	isDirty: boolean = false;

	constructor(
		public i18nService: I18nService,
		private appInsightsService: AppInsightsService,
		private preferencesService: PreferencesService,
		private tzDateService: TzDateService,
		private dialogsService: DialogsService,
		private localeConfigService: LocaleConfigService,
		private route: ActivatedRoute,
		private readonly changeDetector: ChangeDetectorRef
	) {
		this.panelSettings = PANEL_SETTINGS;
		this.panelSettings.headerText = this.i18nService.get('common_daterange_panel_header');
	}

	createFormatLabel = () => {
		this.formatLabel = (value, isSelectionItem = false) => {
			if (
				!isSelectionItem &&
				this.customDateRange &&
				isCustomTimeRangeValue(this.currentRange) &&
				isCustomTimeRangeValue(value)
			) {
				if (sccHostService.isSCC) {
					const dateFormat = this.customRangeActionConfig.dateFormat || 'longDate';
					return `${this.tzDateService.format(
						this.customDateRange.from,
						dateFormat
					)}-${this.tzDateService.format(this.customDateRange.to, dateFormat)}`;
				}

				this.customRangeFromDisplay = new Intl.DateTimeFormat(
					this.localeConfigService.selectedLocale
				).format(this.customDateRange.from);
				this.customRangeToDisplay = new Intl.DateTimeFormat(
					this.localeConfigService.selectedLocale
				).format(this.customDateRange.to);
				return `${this.customRangeFromDisplay}-${this.customRangeToDisplay}`;
			}
			return value.name;
		};
	};

	validateRange: (range: TimebarRange) => TimebarRange | null;

	togglePanel(panelOpen: boolean) {
		this.customRangePanelOpened = panelOpen;
	}

	onRangeTypeSelect(newRange: TimeRangeValue) {
		this.currentRange = newRange;
		const isCustomRange = isCustomTimeRangeValue(this.currentRange);

		if (isCustomRange) {
			this.togglePanel(true);
		}

		if (!isCustomRange) this.preferencesService.setPreference(this.preferenceId, this.currentRange.id);

		if (
			!isCustomRange ||
			(this.originalCustomDateRange &&
				this.customDateRange.toString() !== this.originalCustomDateRange.toString())
		) {
			this.onRangeSelect();
		}
	}

	apply() {
		if (isCustomTimeRangeValue(this.currentRange)) {
			this.onNewRangeSelectedCallback(null, this.customDateRange);
		} else {
			this.onNewRangeSelectedCallback(this.currentRange);
		}

		this.togglePanel(false);
		this.isDirty = false;
		this.originalCurrentRange = cloneDeep(this.currentRange);
		this.originalCustomDateRange = cloneDeep(this.customDateRange);
	}

	cancel() {
		this.togglePanel(false);
		this.isDirty = false;
		this.customDateRange = cloneDeep(this.originalCustomDateRange);
		this.currentRange = cloneDeep(this.originalCurrentRange);
	}

	onRangeSelect() {
		this.isDirty = true;
		this.appInsightsService.trackEvent('UsageTrack', {
			ButtonType: isCustomTimeRangeValue(this.currentRange) ? 'CustomDateRange' : 'DateListPicker',
			Component: this.dataViewId,
		});

		if (isCustomTimeRangeValue(this.currentRange)) {
			const validatedRange = this.validateRange(this.customDateRange);
			if (validatedRange) {
				const textKey = `events.dateRange.${
					validatedRange.to !== this.customDateRange.to
						? 'validatedLaterThanNow'
						: 'validatedArchiveRange'
				}`;
				this.dialogsService.showSnackbar({
					text: this.i18nService.get(textKey, {
						from: this.tzDateService.format(validatedRange.from, 'medium'),
						to: this.tzDateService.format(validatedRange.to, 'medium'),
					}),
					icon: 'Info',
				});
				this.customDateRange = new DateRangeModel(
					this.tzDateService,
					new Date(validatedRange.from),
					new Date(validatedRange.to),
					false
				);
			}
		} else {
			this.customDateRange = DateRangeModel.fromDays(
				this.tzDateService,
				(this.currentRange as SpecificTimeRangeValue).value,
				undefined,
				undefined,
				false
			);
			this.apply();
		}
	}

	ngOnInit() {
		Object.assign(this, { ...this.customRangeActionConfig });
		this.originalCurrentRange = cloneDeep(this.currentRange);
		this.createFormatLabel();

		this.routeSubscription = this.route.queryParams.subscribe(() => {
			this.currentRange = this.customRangeActionConfig.currentRange;
			this.customDateRange = this.customRangeActionConfig.customDateRange;
			this.originalCurrentRange = cloneDeep(this.currentRange);
			this.createFormatLabel();
			this.changeDetector.detectChanges();
		});
	}

	ngOnDestroy() {
		this.routeSubscription.unsubscribe();
	}
}
