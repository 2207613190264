var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
var SAVE_DATA = { timeout: 60000 };
var ɵ0 = function () { return 'onboarding/intune/status'; }, ɵ1 = function (value) {
    return "onboarding/intune/" + (value.intuneIntegrationEnabled ? '' : 'de') + "provision";
}, ɵ2 = function () { return SAVE_DATA; }, ɵ3 = function (config) { return config.data.serviceUrls.userRequests; }, ɵ4 = function (value, data) { return data === 1; }, ɵ5 = function (value, data) { return data === 0 || data === 1; };
var IntuneIntegrationStatus = /** @class */ (function (_super) {
    __extends(IntuneIntegrationStatus, _super);
    function IntuneIntegrationStatus() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.failed = false;
        return _this;
    }
    __decorate([
        EntityField({
            parse: ɵ4,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], IntuneIntegrationStatus.prototype, "intuneIntegrationEnabled", void 0);
    __decorate([
        EntityField({
            parse: ɵ5,
            defaultValue: false,
        }),
        __metadata("design:type", Boolean)
    ], IntuneIntegrationStatus.prototype, "licenseEnabled", void 0);
    IntuneIntegrationStatus = __decorate([
        Entity({
            singularName: 'Intune integration status',
            pluralName: 'Intune integration status',
            endpoint: 'onboarding/intune/status',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            serializeItem: ɵ2,
            saveMethod: 'POST',
            readonly: true,
            baseUrl: ɵ3,
            supportedEntityGetMethods: [EntityGetMethod.getItem],
        })
    ], IntuneIntegrationStatus);
    return IntuneIntegrationStatus;
}(EntityModelBase));
export { IntuneIntegrationStatus };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5 };
