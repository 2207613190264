/* tslint:disable:template-accessibility-alt-text */
/* tslint:disable:template-accessibility-label-for */
import { Component, ChangeDetectionStrategy, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { AuthService } from '@wcd/auth';
import { I18nService } from '@wcd/i18n';
import {
	PostThreatExpertRequestApiCall,
	BilbaoLicenseApiCall,
	BilbaoLicense,
} from '@wcd/domain';
import { ContentState } from '@wcd/contents-state';
import { Paris } from '@microsoft/paris';
import { MessageBarType, SpinnerSize } from 'office-ui-fabric-react';
import { RbacControlService } from '../../rbac/services/rbac-control.service';
import { RbacMdeAllowedActions } from '../../rbac/enums/mde-allowed-actions.enum';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { config } from '@wcd/shared';
import { MainAppState, MainAppStateService } from '../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';
import { Feature, FeaturesService } from '@wcd/config';

const BANNER_TITLE_PREFIX = 'threatExpert.banner';

@Component({
	selector: 'ask-the-expert-panel',
	templateUrl: './ask-threat-expert.panel.component.html',
	styleUrls: ['../ask-threat-expert.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class AskTheExpertPanelComponent extends PanelContainer implements OnInit {
	message: string;
	email: string;
	isSaving = false;
	cause: string;
	ContentStates = ContentState;
	messageBarType: MessageBarType;
	messageBarText: string;
	hasSubmitted: boolean;
	bilbaoLicenseState = ContentState.Loading;
	bannerTitle: string;
	bannerDescription: string;
	isFormDisabled = false;
	showBanner = true;
	SpinnerSize = SpinnerSize;
	isNarrowLayout = false;
	formBodyPlaceholder: string;
	isFloatingFeedbackWidget: boolean;

	constructor(
		router: Router,
		private authService: AuthService,
		private i18nService: I18nService,
		private paris: Paris,
		private changeDetectorRef: ChangeDetectorRef,
		private readonly rbacControlService: RbacControlService,
		private dialogsService: DialogsService,
		mainAppStateService: MainAppStateService,
		private readonly featuresService: FeaturesService,
	) {
		super(router);
		this.cause = window.location.href;
		this.setEmail();
		mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return (
						this.isNarrowLayout !==
						config.widthBreakpointSmallerThan(
							state.screenMaxWidthBreakpoint,
							config.msScreenSizeBreakpoints.small
						)
					);
				})
			)
			.subscribe((state: MainAppState) => {
				this.isNarrowLayout = config.widthBreakpointSmallerThan(
					state.screenMaxWidthBreakpoint,
					config.msScreenSizeBreakpoints.small
				);
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnInit() {
		super.ngOnInit();
		this.paris.apiCall(BilbaoLicenseApiCall).subscribe(
			bilbaoData => {
				this.bilbaoLicenseState = ContentState.Complete;
				this.setBannerTitle(bilbaoData);
				this.changeDetectorRef.detectChanges();
			},
			() => {
				this.bilbaoLicenseState = ContentState.Error;
				this.changeDetectorRef.detectChanges();
			}
		);
	}

	setEmail() {
		this.email = this.authService.currentUser.name;
	}

	submitRequest() {
		if (this.isSaving) {
			return;
		}

		this.isSaving = true;
		this.isFormDisabled = true;
		this.messageBarType = MessageBarType.info;
		this.messageBarText = this.i18nService.get('threatExpert.submitting');

		this.paris
			.apiCall(
				PostThreatExpertRequestApiCall,
				{
					questionArea: this.cause,
					questionBody: this.message,
					sourceUrl: window.location.href,
					userEmail: this.email,
					language: 'en-us',
					requestId: crypto.getRandomValues(new Uint32Array(4)).join('-'),
					useProvisioningStore: this.featuresService.isEnabled(Feature.EnableExpertsOnDemandPSFlow)
				}
			)
			.subscribe(
				() => {
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get('threatExpert.submitSuccessMsg'),
					});
					this.closePanel();
				},
				() => {
					this.messageBarType = MessageBarType.error;
					this.messageBarText = this.i18nService.get('threatExpert.submitErrorMsg');
					this.isFormDisabled = false;
				},
				() => {
					this.hasSubmitted = true;
					this.isSaving = false;
					this.changeDetectorRef.detectChanges();
				}
			);
	}

	private setBannerTitle(licenseData: BilbaoLicense) {
		const hadTrial = licenseData.inTrial || licenseData.daysLeft === 0; // based on backend design

		if (licenseData.enabled || hadTrial) {
			if (!this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings])) {
				// Check permissions before allowing the user to submit EOD request
				this.isFormDisabled = true;
				this.bannerTitle = this.i18nService.get(`${BANNER_TITLE_PREFIX}.titleNoPermissions`);
				this.bannerDescription = this.i18nService.get(
					`${BANNER_TITLE_PREFIX}.descriptionNoPermissions`
				);
			} else if (licenseData.enabled) {
				// Valid EOD subscription
				this.showBanner = false;
			} else if (licenseData.inTrial) {
				// Trial mode
				this.bannerTitle = this.i18nService.get(`${BANNER_TITLE_PREFIX}.titleTrial`);
				this.bannerDescription = this.i18nService.get(`${BANNER_TITLE_PREFIX}.descriptionTrial`, {
					days: licenseData.daysLeft,
				});
			} else {
				// Expired trial
				this.isFormDisabled = true;
				this.bannerTitle = this.i18nService.get(`${BANNER_TITLE_PREFIX}.titleTrialExpired`);
				this.bannerDescription = this.i18nService.get(
					`${BANNER_TITLE_PREFIX}.descriptionTrialExpired`
				);
			}
		} else {
			// Not enrolled state
			this.isFormDisabled = true;
			this.bannerTitle = this.i18nService.get(`${BANNER_TITLE_PREFIX}.titleNotEnrolled`);
			this.bannerDescription = this.i18nService.get(`${BANNER_TITLE_PREFIX}.descriptionNotEnrolled`);
		}

		if (licenseData.enabled || licenseData.inTrial) {
			this.formBodyPlaceholder = this.i18nService.get(`threatExpert.form.body.placeholder`);
		} else {
			this.formBodyPlaceholder = this.i18nService.get(`threatExpert.form.body.disabledPlaceholder`);
		}
	}
}
