import { DataQuery, DataEntityType, RelationshipRepository } from '@microsoft/paris';
import { EntityConfig } from '@microsoft/paris';
import { ODataFilterQueryBuilder } from './oDataFilterQueryBuilder';

export enum TvmEndPoint {
	Analytics = 'analytics',
	RemediationTasks = 'remediation-tasks',
	ItsmProvisioning = 'itsm',
	AuthenticatedScans = 'networkscan', // historically authenticated scans were called network scans so the endpoint remained with the old name
	OrgSettings = 'orgsettings',
	Manualcontentmanagement = 'mcm',
	Feedback = 'feedback',
}

export type FilterPredicate = ((filterValue: Array<any> | any) => string) | boolean;

export type FilterPredicateWithContext = {
	predicate: FilterPredicate;
	context?: string; //if undefined the predicate is considered the default
};

export type FiltersMapValue = FilterPredicateWithContext[] | FilterPredicate;

export const ApiVersionHeaderName = 'api-version';

export const DefaultApiVersion = { [ApiVersionHeaderName]: '1.0' };

export const Cache = {
	time: 1000 * 60 * 5, // 5 minuets
	max: 100,
};

export const AddMachineGroupsFilterQueryParam = queryParams => {
	const isMachinesPath = window.location.pathname.startsWith("/machines");

	//TODO: usage of the window object as a temporary bridge between TvmMachineGroupsFilterService and the domain (to be used by Entity's "parseDataQuery" to send the groups via query param). fix by extending Paris to pass ParisConfig.data as an arg to parseDataQuery => then we can assign this service instance to the data and use it on parseDataQuery.
	const tvm_machineGroupsFilterData = (<any>window)._tvm_machineGroupsFilterData;
	if (tvm_machineGroupsFilterData && tvm_machineGroupsFilterData.isFiltering && !isMachinesPath) {
		const groupIds: string = tvm_machineGroupsFilterData.machineGroups
			.filter(mg => mg.isSelected)
			.map(mg => mg.groupId)
			.join();
		queryParams['rbacGroups'] = `groups in (${groupIds})`;
	}
};

export interface ResolveEntityConfig {
	endPoint: TvmEndPoint; //the relevant end point
	entityModelBaseOrRelationship: RelationshipRepository<any, any> | DataEntityType<any, any>; //a Paris Entity/Relationship config (entityModelBaseOrRelationship)
	id?: string | number; //DEPRECATED - use sourceModel
	sourceModel?: any; //for relationship, provide the model of the parent resource
	extraQueryData?: { [index: string]: any };
}

/**
 * resolves the URL for a BE api based on the provided Paris Entity/Relationship config (entityModelBaseOrRelationship),
 * 	e.g. results for Entity:
 * 		'products'
 * 	e.g. results for Relationship:
 * 		products/434/recommendations
 */
export const ResolveEntityURL = (config: ResolveEntityConfig): string => {
	return ResolveEntityURLAndQueryParams(config).url;
};

/**
 * resolves the URL for a BE api based on the provided Paris Entity/Relationship config (entityModelBaseOrRelationship),
 * 	e.g. results for Entity:
 * 		'products'
 * 	e.g. results for Relationship:
 * 		products/434/recommendations
 */
export const ResolveEntityURLAndQueryParams = (
	config: ResolveEntityConfig
): { url: string; queryParams: { [index: string]: any } } => {
	const apiConfig: EntityConfig<any, any> =
		config.entityModelBaseOrRelationship['entityConfig'] ||
		config.entityModelBaseOrRelationship['relationshipConfig'];

	let relationshipData = {};

	if (config.sourceModel) {
		relationshipData = (<any>apiConfig).getRelationshipData(config.sourceModel);
	}

	const dataQuery: DataQuery = {
		where: {
			id: config.id,
			extraQueryData: config.extraQueryData,
			...relationshipData,
		},
	};

	const apiComponent =
		typeof apiConfig.endpoint === 'function' ? apiConfig.endpoint(null, dataQuery) : apiConfig.endpoint;

	return {
		url: `${config.endPoint}/${apiComponent}`,
		queryParams: apiConfig.parseDataQuery ? apiConfig.parseDataQuery(dataQuery) : {},
	};
};

export const AddODataFilterQueryParam = (
	dataQuery: DataQuery,
	queryParams,
	fieldFiltersMap: Record<string, FiltersMapValue>,
	context?: string
) => {
	if (dataQuery.where && typeof dataQuery.where === 'object') {
		const filters: Array<string> = [];
		Object.keys(dataQuery.where)
			.filter(field => dataQuery.where[field] != undefined)
			.map(field => {
				if (fieldFiltersMap[field]) {
					if (fieldFiltersMap[field] === true) {
						filters.push(oDataFilterFormatter(dataQuery.where[field], field));
					} else if (Array.isArray(fieldFiltersMap[field])) {
						const predicatesArray = fieldFiltersMap[field] as Array<FilterPredicateWithContext>;
						const matchedPredicate: FilterPredicateWithContext = context
							? predicatesArray.find(val => val.context === context)
							: predicatesArray.find(predicate => !predicate.context);

						if (matchedPredicate.predicate === true) {
							filters.push(oDataFilterFormatter(dataQuery.where[field], field));
						} else {
							const callback = matchedPredicate
								? matchedPredicate.predicate
								: predicatesArray[0].predicate;

							const filterValue = (callback as ((filterValue: Array<any> | any) => string))(
								dataQuery.where[field]
							);
							if (filterValue !== null) filters.push(filterValue);
						}
					} else {
						const callback = fieldFiltersMap[field] as ((
							filterValue: Array<any> | any
						) => string);
						const filterValue = callback(dataQuery.where[field]);
						if (filterValue !== null) filters.push(filterValue);
					}
				}
			});
		if (filters.length) {
			queryParams['$filter'] = filters.map(f => '(' + f + ')').join(' and ');
		}
	}
};

const oDataFilterFormatter = (filterBy: Array<string> | string, field: string) => {
	const filter = filterBy instanceof Array ? filterBy : [filterBy];
	let queryBuilder = new ODataFilterQueryBuilder('or');
	filter.forEach(val => (queryBuilder = queryBuilder.equals(field, val)));
	return queryBuilder.toQuery();
};
