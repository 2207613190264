import { ApiBaseModel } from '@wcd/data';
import { Observable } from 'rxjs';
import { DownloadService } from '../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { merge } from 'lodash-es';
import { map } from 'rxjs/operators';
import { DataSet } from '@microsoft/paris';
import { getUserTimeZone } from '@wcd/localization';

export abstract class StoreBackendBase extends ApiBaseModel {
	protected abstract apiUrl: string;
	protected abstractapiUrl: string;
	protected itemApiUrl: string;
	protected getAllResultsParams: { [index: string]: any } = {};
	protected getAllResultsEndpoint: string = 'all';
	protected commonParams: { [index: string]: any } = {};
	protected updateMethod: string = 'patch';

	constructor(protected http: HttpClient, protected downloadService: DownloadService) {
		super(http);
	}

	/**
	 * Performs a request with `get` http method.
	 */
	get<T>(url: string, options?: object): Observable<T> {
		return this.http.get<T>(this.apiUrl + '/' + url, options);
	}

	/**
	 * Performs a request with 'post' http method.
	 */
	post<T>(url: string, body: any, options?: object): Observable<T> {
		return this.http.post<T>(this.apiUrl + '/' + url, body, options);
	}

	/**
	 * Performs a request with 'put' http method.
	 */
	put<T>(url: string, body: any, options?: object): Observable<T> {
		return this.http.put<T>(this.apiUrl + '/' + url, body, options);
	}

	/**
	 * Performs a request with 'delete' http method.
	 */
	delete<T>(url: string, options?: object): Observable<T> {
		return this.http.delete<T>(this.apiUrl + '/' + url, options);
	}

	/**
	 * Performs a request with 'patch' http method.
	 */
	patch<T>(url: string, body: any, options?: object): Observable<T> {
		return this.http.patch<T>(this.apiUrl + '/' + url, body, options);
	}

	getAllResults(options?: { [index: string]: any }): Observable<DataSet<any>> {
		const params = this.getUrlParams(
			merge({}, { page_size: 99999 }, this.commonParams, this.getAllResultsParams, options)
		);

		return new Observable(observer => {
			this.http
				.get<any>(`${this.apiUrl}/${this.getAllResultsEndpoint}`, params)
				.pipe(
					map((res: any) => {
						if (res.results)
							return {
								count: res.count,
								items: res.results,
								next: res.next,
							};

						if (res['data']) return { items: res['data'] };

						// TODO: this is WDATP object structure. we need to get real count from backend in order to allow paging
						if (res['Items']) return { count: res['Items'].length, items: res['Items'] };

						// TODO: this is WDATP object structure. we need to get real count from backend in order to allow paging
						if (res instanceof Array) return { count: res.length, items: res };

						return res;
					})
				)
				.subscribe(observer);
		});
	}

	exportAllResults(options?: { [index: string]: any }, format: string = 'csv'): Promise<any> {
		return this.downloadService.downloadFromUrl(`${this.apiUrl}/all.${format}`, options);
	}

	getFilterValues(options?: { [index: string]: any }): Observable<any> {
		return this.http.get<any>(
			`${this.apiUrl}/filters`,
			this.getUrlParams(merge({}, this.commonParams, options))
		);
	}

	searchFilterValues(
		fieldId: string,
		term: string,
		options?: { [index: string]: any }
	): Observable<Array<any>> {
		const searchParams = merge({}, options, { field: fieldId, term: term });

		return this.http
			.get(`${this.apiUrl}/all`, this.getUrlParams(merge({}, this.commonParams, searchParams)))
			.pipe(map(this.extractData));
	}

	getItem(itemId: number | string, options?: { [index: string]: any }): Observable<any> {
		return this.http.get(
			`${this.itemApiUrl || this.apiUrl}/${itemId}`,
			this.getUrlParams(merge({}, this.commonParams, options))
		);
	}

	remove(id: number | string, options?: any): Observable<any> {
		return this.http.delete(
			`${this.itemApiUrl || this.apiUrl}/${id}`,
			this.getUrlParams(this.commonParams)
		);
	}

	create(obj: any, options?: any): Observable<any> {
		return this.http.post(
			`${this.apiUrl}/all?time_offset=${getUserTimeZone()}`,
			obj,
			this.getUrlParams(this.commonParams)
		);
	}

	update(id: number | string, data: any, options?: any): Observable<any> {
		return this.http[this.updateMethod](
			`${this.itemApiUrl || this.apiUrl}/${id}?time_offset=${getUserTimeZone()}`,
			data,
			this.getUrlParams(this.commonParams)
		);
	}

	updateMultiple<U>(data: Object, itemIds: Array<U>, options?: Object): Observable<any> {
		return this.http.patch(
			`${this.apiUrl}/all?time_offset=${getUserTimeZone()}`,
			merge({}, data, itemIds ? { ids: itemIds } : null),
			this.getUrlParams(merge({}, this.commonParams, options))
		);
	}

	export(itemIds?: Array<string | number>, options?: any): Promise<any> {
		const exportOptions: { [index: string]: any } = { time_offset: getUserTimeZone() };
		if (itemIds) exportOptions.ids = itemIds;

		return this.downloadService.downloadFromUrl(
			`${this.apiUrl}/export`,
			merge({}, options, exportOptions)
		);
	}
}
