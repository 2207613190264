import { Injectable, OnDestroy } from '@angular/core';
import { AuthService } from '@wcd/auth';
import { Dictionary, PollingService } from '@wcd/config';
import { BehaviorSubject, from, Observable, of, Subscription } from 'rxjs';
import { IPreload, LocalStorageService } from '@wcd/shared';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { Paris, Repository } from '@microsoft/paris';
import { SaveUserPreferencesApiCall, UserPreferences } from '@wcd/domain';
import { get, isEqual } from 'lodash-es';

const PREFERENCES_REFRESH_INTERVAL: number = 60 * 1000;
const PREFERENCES_KEY = 'user_preferences';

@Injectable()
export class PreferencesService implements IPreload, OnDestroy {
	private _preferences: Dictionary<string, any> = new Dictionary<string, any>();
	private _preferencesSubject: BehaviorSubject<Dictionary<string, any>> = new BehaviorSubject<
		Dictionary<string, any>
	>(undefined);
	preferences$: Observable<Dictionary<string, any>> = this._preferencesSubject.asObservable();
	preferencesRepository: Repository<UserPreferences>;
	refreshPreferencesSubscription: Subscription;

	private get preferences(): Dictionary<string, any> {
		return this._preferences;
	}

	constructor(
		private authService: AuthService,
		private localStorageService: LocalStorageService,
		private paris: Paris,
		private pollingService: PollingService
	) {
		this.preferencesRepository = this.paris.getRepository(UserPreferences);
	}

	init(): Observable<Dictionary<string, any>> {
		// listens to changes on other tabs\computers
		this.refreshPreferencesSubscription = this.pollingService
			.poll(PREFERENCES_REFRESH_INTERVAL, PREFERENCES_REFRESH_INTERVAL)
			.pipe(switchMap(() => from(this.getPersistedPreferences())))
			.subscribe((preferences: Dictionary<string, any>) => {
				if (isEqual(this._preferences, preferences)) return;

				this._preferences = preferences;
				this._preferencesSubject.next(this._preferences);
			});

		return from(this.getPersistedPreferences()).pipe(
			tap(preferences => {
				this.incidentNameColumnConvertor(preferences);
				this._preferences = preferences;
				this._preferencesSubject.next(this._preferences);
			})
		);
	}

	setPreference<T = any>(preferenceId: string, value: T): void {
		this.preferences.set(preferenceId, value);
		this.persistPreferences();
	}

	getPreference<T = any>(preferenceId: string): T {
		return this.preferences.get(preferenceId);
	}

	removePreference(preferenceId: string): void {
		this.preferences.delete(preferenceId);
		this.persistPreferences();
	}

	private async getPersistedPreferences(): Promise<Dictionary<string, any>> {
		let userPreferences: UserPreferences = null;
		try {
			userPreferences = await this.preferencesRepository.getItemById(null).toPromise();
		} catch (e) {}
		const preferences = (userPreferences && userPreferences.preferences) || null;
		if (!preferences || !preferences.size) {
			return this.getLocalStoragePreferences();
		}
		return Dictionary.fromIndex<any>(preferences.get(PREFERENCES_KEY));
	}

	private async persistPreferences(): Promise<void> {
		if (!this.authService.currentUser) return;

		await this.paris
			.apiCall(SaveUserPreferencesApiCall, {
				key: PREFERENCES_KEY,
				preferences: this.preferences.stringify(),
			})
			.pipe(catchError(() => of(null)))
			.toPromise();
	}

	private getLocalStoragePreferences(): Dictionary<string, any> {
		let localStorageData;
		try {
			localStorageData = this.localStorageService.getItem(this.getStorageKey());
		} catch (e) {
			console.warn('Failed to access local storage');
		}
		return Dictionary.fromIndex<any>(localStorageData ? JSON.parse(localStorageData) : null);
	}

	private getStorageKey(): string {
		const currentUserName = this.authService.currentUser
			? this.authService.currentUser.name
			: 'defaultUser';
		return `${currentUserName}_preferences`;
	}

	private incidentNameColumnConvertor(preferences: Dictionary<string, any>) {
		const dataViewIncidents = preferences.get('dataView_incidents');
		if (!!dataViewIncidents) {
			const visibleFields = dataViewIncidents['visibleFields'];
			const nameColumn = get(visibleFields, 0);
			// replace old col id with the new id (title => name)
			if (nameColumn === 'title') {
				visibleFields[0] = 'name';
			}
		}
	}

	ngOnDestroy() {
		this._preferencesSubject.complete();
		this.refreshPreferencesSubscription && this.refreshPreferencesSubscription.unsubscribe();
	}
}
