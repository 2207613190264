
		<ng-container dataview-controls>
			<wcd-select
				[openMenuPosition]="openMenuPosition"
				[(ngModel)]="currentRange"
				(ngModelChange)="onRangeTypeSelect($event)"
				class="hidden-action command-bar-item-dropdown"
				[isBordered]="false"
				[buttonIcon]="icon"
				label="name"
				[wcdTooltip]="formatLabel(currentRange, true)"
				[values]="timeRanges"
				[formatLabel]="formatLabel"
				[useValueAsButtonText]="!smallScreenView"
				[showIconDropdown]="!smallScreenView"
				(selectorStateChanged)="toggleSelectorState($event)"
			></wcd-select>

			<wcd-panel
				(close)="togglePanel(false)"
				class="responsive-panel"
				[class.wcd-padding-large-top]="paddingTop"
				[settings]="panelSettings"
				*ngIf="customRangePanelOpened"
			>
				<div class="daterange-panel-content">
					<daterange
						class="wcd-flex-1 wcd-scroll-vertical"
						[showInPanel]="true"
						[verticalDirection]="true"
						[(ngModel)]="customDateRange"
						[allowExactTimeSelection]="true"
						(ngModelChange)="onRangeSelect()"
					></daterange>
					<div
						class="wcd-flex-none wcd-padding-horizontal wcd-padding-small-vertical wcd-border-top"
					>
						<ng-container>
							<fab-primary-button
								[disabled]="!isDirty"
								(onClick)="apply()"
								[text]="i18nService.strings.buttons_apply"
								className="wcd-margin-xsmall-right"
							></fab-primary-button>
							<fab-default-button
								(onClick)="cancel()"
								[text]="i18nService.strings.buttons_cancel"
							></fab-default-button>
						</ng-container>
					</div>
				</div>
			</wcd-panel>
		</ng-container>
	