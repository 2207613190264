import { EntityModelBase, Entity, EntityField } from '@microsoft/paris';
import { incidentGraphCategoryValues } from './incident-graph-category.values';

@Entity({
	singularName: 'Incident category',
	pluralName: 'Incident categories',
	readonly: true,
	values: incidentGraphCategoryValues,
})
export class IncidentGraphCategory extends EntityModelBase<string> {
	@EntityField() name: string;

	@EntityField() priority: number;

	@EntityField() color: string;
}
