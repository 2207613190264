import { Injectable } from '@angular/core';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { MachinesService } from '../../machines/services/machines.service';
import { Observable, of, combineLatest, zip } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { isEmpty, differenceWith, uniq } from 'lodash-es';
import { Repository, Paris } from '@microsoft/paris';
import { HuntingRule, HuntingRuleCustomSupportedActionType, MachineGroup, MtpWorkload } from '@wcd/domain';
import { AuthService, MtpPermission } from '@wcd/auth';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';

@Injectable()
export class HuntingRbacService {
	private readonly machineGroupsRepo: Repository<MachineGroup>;

	constructor(
		private appConfigService: AppConfigService,
		private featuresService: FeaturesService,
		private machinesService: MachinesService,
		private authService: AuthService,
		private rbacControlService: RbacControlService,
		paris: Paris
	) {
		this.machineGroupsRepo = paris.getRepository(MachineGroup);
	}

	isUserExposedToHuntingRulesScope(huntingRules: ReadonlyArray<HuntingRule>): Observable<boolean> {
		const mdatpHuntingRules: Array<HuntingRule> = huntingRules.filter(
			(rule) => rule.mtpWorkloads && rule.mtpWorkloads.includes(MtpWorkload.Mdatp)
		);

		if (
			mdatpHuntingRules.length === 0 ||
			!this.appConfigService.hasMachineGroups ||
			this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.admin])
		) {
			return of(true);
		}

		const userExposedGroups$ = this.machinesService.getFullUserExposedMachineGroups().pipe(take(1));
		const allMachineGroups$ = this.machineGroupsRepo.query().pipe(map((dataSet) => dataSet.items));

		const isExposedToHuntingRule = (rule: HuntingRule) => {
			return zip(userExposedGroups$, allMachineGroups$).pipe(
				map(([userExposedGroups, allMachineGroups]: [Array<MachineGroup>, Array<MachineGroup>]) => {
					const huntingRuleGroups = isEmpty(rule.machineGroups)
						? allMachineGroups
						: rule.machineGroups;
					return isEmpty(
						differenceWith(huntingRuleGroups, userExposedGroups, (a, b) => a.id === b.id)
					);
				})
			);
		};

		return combineLatest(mdatpHuntingRules.map(isExposedToHuntingRule)).pipe(
			map((isExposedToRule) => isExposedToRule.reduce((prev, current) => prev && current, true))
		);
	}

	isUserAllowedToEdit(rule: HuntingRule): boolean {
		if (this.featuresService.isEnabled(Feature.MtpCustomRolesSupport)) {
			if (rule.mtpWorkloads && rule.mtpWorkloads.length) {
				// user must have permission for all products participating in the rule
				if (
					rule.mtpWorkloads.some(
						(product) =>
							!this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
								MtpPermission.SecurityConfig_Manage,
								product
							)
					)
				) {
					return false;
				}
			} else {
				// anyway, the user must have permission on at least one product
				if (
					!this.authService.currentUser.hasRequiredMtpPermission(
						MtpPermission.SecurityConfig_Manage
					)
				) {
					return false;
				}
			}

			if (rule.actions && rule.actions.length > 0) {
				// the user must have remediate permission for all actions
				const actionWorkloads = uniq(
					rule.actions.map(({ actionType }) => this.getActionMtpWorkload(actionType))
				);
				if (
					actionWorkloads.some(
						(workload) =>
							!this.authService.currentUser.hasRequiredMtpPermissionInWorkload(
								MtpPermission.SecurityData_Remediate,
								workload
							)
					)
				) {
					return false;
				}
			}

			return true;
		}

		// hunting MTP RBAC is not enabled - require security settings role, as before
		return this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings]);
	}

	isUserAllowedToCreateNewRule(): boolean {
		// to create a new rule, user must have permission to at least one product
		// more RBAC checks may be applied by the BE for a particular query string
		return this.featuresService.isEnabled(Feature.MtpCustomRolesSupport)
			? this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityConfig_Manage)
			: this.rbacControlService.hasRequiredMdePermission([RbacMdeAllowedActions.securitySettings]);
	}

	private getActionMtpWorkload(actionType: HuntingRuleCustomSupportedActionType): MtpWorkload {
		let exhaustiveCheck:never;

		switch (actionType) {
			case HuntingRuleCustomSupportedActionType.IsolateMachine:
			case HuntingRuleCustomSupportedActionType.CollectInvestigationPackage:
			case HuntingRuleCustomSupportedActionType.RunAntivirusScan:
			case HuntingRuleCustomSupportedActionType.InitiateInvestigation:
			case HuntingRuleCustomSupportedActionType.QuarantineFile:
			case HuntingRuleCustomSupportedActionType.AllowFile:
			case HuntingRuleCustomSupportedActionType.BlockFile:
			case HuntingRuleCustomSupportedActionType.RestrictExecution:
				return MtpWorkload.Mdatp;
			case HuntingRuleCustomSupportedActionType.MarkUserAsCompromised:
				return MtpWorkload.AadIp;
			case HuntingRuleCustomSupportedActionType.MoveEmailToFolder:
			case HuntingRuleCustomSupportedActionType.DeleteEmail:
			case HuntingRuleCustomSupportedActionType.BlockEmailSender:
			case HuntingRuleCustomSupportedActionType.BlockEmailUrl:
			case HuntingRuleCustomSupportedActionType.StartNewSubmission:
			case HuntingRuleCustomSupportedActionType.TriggerInvestigation:
				return MtpWorkload.Oatp;
			case HuntingRuleCustomSupportedActionType.DisableUser:
			case HuntingRuleCustomSupportedActionType.ForceUserPasswordReset:
				return MtpWorkload.AadIp; // TODO: change to ITP (MCAS) or Mdi once they support the remediate role
			default:
				exhaustiveCheck = actionType; // generate compilation error if the switch is not exhaustive
				throw new Error(
					`Hunting action type ${exhaustiveCheck} is not mapped to a workload. Please add it to the mapping.`
				);
		}
	}
}
