import { Pipe, PipeTransform } from '@angular/core';
import { AdapterType } from '@wcd/domain';
import { isNil } from 'lodash-es';

@Pipe({ name: 'ipAdapterType' })
export class IpAdapterTypePipe implements PipeTransform {
	transform(adapterType: AdapterType): string {
		switch (adapterType) {
			case AdapterType.Wireless80211:
				return 'IEEE 802.11';
			case AdapterType.Ppp:
				return 'PPP';
			case AdapterType.TokenRing:
				return 'Token ring';
			case AdapterType.Atm:
				return 'ATM';
			case AdapterType.HighPerformanceSerialBus:
				return 'IEEE 1394';
			default:
				return !isNil(adapterType) ? AdapterType[adapterType] : '';
		}
	}
}
