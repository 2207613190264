<!-- tslint:disable:template-accessibility-alt-text -->
<div id="hexadite-main"
	 wcdTelemetryDelegate
	 class="wcd-full-height"
	 *ngIf="mainAppState.isInit; else loading"
	 data-ready="true">
	<div class="wcd-full-height">
		<div class="wcd-flex-horizontal wcd-full-height">
			<div class="wcd-flex-1 wcd-flex-vertical wcd-full-height">
				<main-header class="wcd-flex-none"></main-header>
				<div class="wcd-flex-1 wcd-flex-horizontal">
					<main-nav id="main-side-nav"
							  class="noprint wcd-flex-none"
							  [showItems]="appConfigService?.isOnboardingComplete && !appConfigService.isSuspended && !appConfigService.isDeleted"
							  [isExpanded]="mainAppState.mainNavIsExpanded">
					</main-nav>
					<div role="main" id="app-contents"
						class="wcd-flex-1 wcd-flex-vertical"
						[style.margin-right]="panelsWidth + 'px'"
						[class.nav-expanded]="mainAppState.mainNavIsExpanded">
						<snackbars></snackbars>
						<div class="wcd-flex-horizontal wcd-flex-center-vertical wcd-flex-horizontal-justify-items-end">
							<app-breadcrumbs class="wcd-margin-small-all wcd-flex-1"
											 *ngIf="mainAppState.pageMode === entityPageViewMode.Default"
											 [items$]="breadcrumbs$"
											 [hidden]="!mainAppState.showBreadcrumbs || !(breadcrumbs$ | async).length"></app-breadcrumbs>
							<span *ngIf="featureFlagToggleFeatureId" class="wcd-margin-right wcd-flex-none">
								<wcd-toggle [name]="'feature-flag-toggle'"
										[id]="'feature-flag-toggle'"
										[(ngModel)]="featureFlagToggleValue"
										(ngModelChange)="onFeatureToggleChange(featureFlagToggleFeatureId, $event)"
										[isDisabled]="false"
										[trueLabel]="'flags.toggleFeature.' + featureFlagToggleFeatureId + '.turnedOn' | i18n"
										[falseLabel]="'flags.toggleFeature.' + featureFlagToggleFeatureId + '.turnedOff' | i18n"
										[ariaLabel]="'flags.toggleFeature.' + featureFlagToggleFeatureId + '.turnedOff' | i18n">
								</wcd-toggle>
							</span>
						</div>
						<span class="wcd-flex-1">
							<span id="airs-contents">
								<router-outlet></router-outlet>
							</span>
							<seville-app></seville-app>
						</span>
					</div>
				</div>
			</div>
		</div>
		<div class="overlay loading-overlay" [hidden]="!mainAppState.showLoader">
			<i class="large-loader-icon"></i>
		</div>
		<div id="notifications-panel"
			 class="notifications-panel noprint"
			 [hidden]="!mainAppState.showNotifications"
			 [ngClass]="{ open: mainAppState.notificationsAreOpen }">
			<notifications class="wcd-full-height" (close)="mainAppStateService.closeNotifications()"></notifications>
		</div>
		<div id="high-priority-notifications-panel"
			 class="notifications-panel noprint"
			 [hidden]="mainAppState.showNotifications">
			<floating-notifications class="wcd-full-height"></floating-notifications>
		</div>
		<modal-placeholder class="modal-placeholder"></modal-placeholder>
		<wcd-panel-placeholder class="panel-placeholder"></wcd-panel-placeholder>
		<wcd-tooltip-placeholder></wcd-tooltip-placeholder>
		<support-central *ngIf="supportCentralService.withSupportCentral"></support-central>
	</div>
</div>
<ng-template #loading>
	<div class="wcd-full-height">
		<div class="overlay overlay-center-all ms-background-color-white">
			<div class="center-text wcd-padding-xsmall-bottom">
				<img src="/assets/images/circle-loading.gif"/>
			</div>
		</div>
	</div>
</ng-template>
<ng-container *ngIf="mainAppState.loadingComponent?.id === 'global'">
	<div class="overlay loading-overlay top-header"
		 [ngClass]="{ transparent: mainAppState.loadingComponent.transparent }">
		<i class="large-loader-icon"></i>
		<span *ngIf="mainAppState.loadingComponent?.description" class="wcd-padding-small-left">
			{{mainAppState.loadingComponent?.description}}
		</span>
	</div>
</ng-container>
