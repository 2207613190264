import { Component } from '@angular/core';
import { LiveResponseSession } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { ActivatedRoute } from '@angular/router';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'live-response-session-entity',
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<div class="wcd-flex-none wcd-padding-xxLarge-horizontal wcd-padding-none-left-sm-screens">
				<tabs [tabsData]="tabs" [currentTab]="currentTabId" (select)="onTabChange($event)" [activeRouteById]="true"></tabs>
			</div>
			<div class="wcd-flex-1 wcd-scroll-vertical responsive-margin wcd-padding-vertical wcd-padding-xxLarge-left">
				<live-response-terminal *ngIf="isInitTerminal" [hidden]="currentTab.id !== 'console'">
				</live-response-terminal>
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
	styleUrls: ['./live-response-session.entity.component.scss']
})
export class LiveResponseSessionEntityComponent extends EntityComponentBase<LiveResponseSession> {
	tabs: Array<TabModel> = [
		{
			id: 'console',
			name: this.i18nService.strings.liveResponse_tabs_console,
			routerLink: './',
		},
		{
			id: 'log',
			name: this.i18nService.strings.liveResponse_tabs_log,
			routerLink: './log',
		},
	].map(t => new TabModel(t));

	currentTab: TabModel;
	isInitTerminal: boolean;

	get currentTabId(): string {
		return this.currentTab.id;
	}

	constructor(readonly route: ActivatedRoute, private i18nService: I18nService) {
		super();
		const childComponentUrl = route.snapshot.firstChild && route.snapshot.firstChild.url.toString();
		this.currentTab =
			this.tabs.find(tab => new RegExp(`\.\/${childComponentUrl}$`).test(<string>tab.routerLink)) ||
			this.tabs[0];
		this.isInitTerminal = this.currentTab.id === 'console';
	}

	onTabChange(event) {
		this.currentTab = event.tab;
		if (!this.isInitTerminal) {
			this.isInitTerminal = this.currentTab.id === 'console';
		}
	}

	get session(): LiveResponseSession {
		return this.entity;
	}

}
