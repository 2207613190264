
		<div class="wcd-padding-medium-left wcd-padding-medium-top">
			<h2 class="wcd-margin-xsmall-top wcd-margin-bottom wcd-font-weight-bold">
				{{ 'onboarding.title' | i18n }}
			</h2>
			<div class="wcd-margin-vertical" *ngIf="appConfigService.isAscTenant">
				<h4>{{ 'onboarding.asc.title' | i18n }}</h4>
				<markdown>{{ 'onboarding.asc.description' | i18n }}</markdown>
			</div>
			<div class="wcd-padding-bottom">
				{{ 'onboarding.description' | i18n }}
			</div>
			<div *ngIf="evaluationEnabled">
				<ng-container
					*ngTemplateOutlet="itemText; context: { item: 'evaluateLab', routerLink: '/evaluation' }"
				></ng-container>
			</div>
			<div *ngIf="(data$ | async)?.MachineOnboarded; else machineNotOnboarded">
				<ng-container
					*ngTemplateOutlet="itemText; context: { item: 'runSimulation', routerLink: '/tutorials' }"
				></ng-container>
			</div>
			<ng-template #machineNotOnboarded>
				<ng-container
					*ngTemplateOutlet="
						itemText;
						context: { item: 'onboardMachine', routerLink: '/preferences2/onboarding' }
					"
				></ng-container>
			</ng-template>
			<ng-template #itemText let-item="item" let-routerLink="routerLink">
				<div class="wcd-flex-horizontal wcd-padding-small-top">
					<a
						[attr.data-track-id]="'GoTo' + item"
						data-track-type="Navigation"
						[routerLink]="routerLink"
					>
						<img [src]="getImage(item)" />
					</a>
					<div class="wcd-padding-medium-left">
						<div class="wcd-font-weight-bold">
							{{ 'onboarding.' + item + '.title' | i18n }}
						</div>
						{{ 'onboarding.' + item + '.description' | i18n }}
					</div>
				</div>
			</ng-template>
			<div class="wcd-padding-medium-top">
				<a
					(click)="confirmAndDismiss()"
					(keydown.enter)="confirmAndDismiss()"
					data-track-id="OnboardingWidget"
					data-track-value="hideCard"
					role="button"
					tabindex="0"
				>
					{{ 'onboarding.hideCard' | i18n }}
				</a>
			</div>
		</div>
	