const MAX_OFFICE_ID_LENGTH = 6;
const ID_KEY = '$id';
const REF_KEY = '$ref';

export class OfficeUtils {
	static getShortId(id: string | number): string | number {
		if (typeof id === 'string') {
			if (isNaN(Number(id)) && id.length > MAX_OFFICE_ID_LENGTH) {
				return id.substr(id.length - MAX_OFFICE_ID_LENGTH);
			}
		}

		return id;
	}

	private static createIds(obj: any) {
		if (obj == null || typeof obj !== 'object') {
			return {};
		}

		if (Array.isArray(obj)) {
			return obj.reduce((acc, nested) => {
				return Object.assign({}, acc, this.createIds(nested));
			}, {});
		}

		return Object.entries(obj).reduce((acc, [k, v]) => {
			if (k === ID_KEY) {
				acc[<string>v] = obj;
			} else if (typeof v === 'object') {
				Object.assign(acc, this.createIds(v));
			}
			return acc;
		}, {});
	}

	private static replaceRefs(refs: any, obj: any, replacements: Array<any>): any {
		if (obj == null || typeof obj !== 'object') {
			return obj;
		}

		if (Array.isArray(obj)) {
			return obj.map((nested, idx) => {
				const repl = this.replaceRefs(refs, nested, replacements);
				replacements.push({ k: idx, obj: obj, val: repl });
				return repl;
			});
		}

		return Object.entries(obj).reduce((acc: any, [k, v]) => {
			if (k === REF_KEY) {
				return refs[<string>v];
			}
			acc[k] = this.replaceRefs(refs, v, replacements);
			return acc;
		}, {});
	}

	static convertDataFromAlertV3(rawData) {
		const replacements: Array<{ k: number; obj: Array<any>; val: any }> = [];
		const ids = this.createIds(rawData);

		// replace reference to objects inside the ids array
		Object.entries(ids).forEach(([k, v]) => {
			ids[k] = this.replaceRefs(ids, v, replacements);
		});

		// replace reference in the original rawData object
		rawData = this.replaceRefs(ids, rawData, replacements);
		replacements.forEach((r: any) => {
			r.obj[r.k] = r.val;
		});

		return rawData;
	}
}
