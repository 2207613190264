import { sccHostService } from '@wcd/scc-interface';
import { Strings } from '../models/strings';
import { isNil } from 'lodash-es';
import { DEFAULT_LANGUAGE, getPortalLanguage } from './i18n.helpers';

const PARAMS_REGEXP: RegExp = /\(\:(\w+)\)/g;

class I18nLoader {
	private _dicPromise: Promise<Strings>;
	public strings: Strings;

	get i18nDictionaryPromise() {
		return this._dicPromise;
	}

	get(keyPath: keyof Strings | string, params?: any, allowNotFound: boolean = false): string {
		if (!keyPath) return '';

		if (!this.strings) {
			console.warn('keys not loaded: ', keyPath);
			return '';
		}
		let key = <string>keyPath;
		if (keyPath.includes('.')) {
			// Allow dot notation for backwards compatibility.
			// Can be removed once no more dot notations keys exists on templates and services.
			key = keyPath.replace(/\./g, '_');
		}
		const value = this.strings[key];
		if (!value) {
			if (allowNotFound) return '';

			console.warn(`i18n value not found: ${keyPath}.`);
			return '[Not found]';
		}

		if (params)
			return value.replace(PARAMS_REGEXP, (match, propertyName: string) =>
				isNil(params[propertyName]) ? '' : params[propertyName]
			);

		return value;
	}

	constructor() {
		this._dicPromise = this.loadI18nDictionary()
			.then((keys) => (this.strings = keys))
			.catch((err) => (this._dicPromise = null));
	}

	private loadI18nDictionary(): Promise<Strings> {
		const language = getPortalLanguage();
		return this.getLocaleStrings(language).catch((err) => {
			console.error(`Failed to get ${language} language strings`, err);
			return this.getLocaleStrings(DEFAULT_LANGUAGE);
		});
	}

	private getLocaleStrings(locale): Promise<Strings> {
		const prefix = !sccHostService.isSCC ? '' : sccHostService.getPackageBasePathByPkg();
		const suffix = !sccHostService.isSCC ? `?v=${window.config.buildVersion}` : '';
		return fetch(`${prefix}/assets/i18n/${locale}/resources.json${suffix}`).then((res) => res.json());
	}
}

export const i18nLoader = new I18nLoader();
