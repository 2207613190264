import { DataviewField } from '@wcd/dataview';
import { SoftwareInstallationAgg } from '@wcd/domain';
import { TzDateService } from '@wcd/localization';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
var SoftwareInstallationAggFieldsService = /** @class */ (function () {
    function SoftwareInstallationAggFieldsService(tzDateService, tvmIconBuilderService, featuresService, i18nService, tvmTextsService, tvmTagsService) {
        var _this = this;
        this.tzDateService = tzDateService;
        this.tvmIconBuilderService = tvmIconBuilderService;
        this.featuresService = featuresService;
        this.i18nService = i18nService;
        this.tvmTextsService = tvmTextsService;
        this.tvmTagsService = tvmTagsService;
        this.fieldsConfig = [
            {
                id: 'assetName',
                name: 'Device',
            },
            {
                id: 'installedVersions',
                name: 'Installed Versions',
                getDisplay: function (softwareInstallation) {
                    return _this.tvmTextsService.getText(TextToken.InstallationAggVersions, softwareInstallation);
                },
                getTooltip: function (softwareInstallation) {
                    return _this.tvmTextsService.getText(TextToken.InstallationAggVersionsTooltip, softwareInstallation);
                },
                valueTooltipAllowHtmlRendering: true,
                sort: {
                    enabled: false,
                },
            },
            {
                id: 'lastSeen',
                name: 'Last seen',
                getDisplay: function (softwareInstallation) {
                    return _this.tzDateService.format(softwareInstallation.lastSeen, 'short');
                },
            },
            {
                id: 'highSevAlert',
                name: 'High Alerts (last 7d)',
                getDisplay: function (softwareInstallation) {
                    return softwareInstallation.highSevAlert ? 'Yes' : 'No';
                },
            },
        ];
    }
    Object.defineProperty(SoftwareInstallationAggFieldsService.prototype, "fields", {
        get: function () {
            if (!this._fields) {
                this._fields = DataviewField.fromList(this.fieldsConfig);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoftwareInstallationAggFieldsService.prototype, "networkSoftwareInstallationFields", {
        get: function () {
            if (!this._networkDeviceSoftwareInstallationFields) {
                this._networkDeviceSoftwareInstallationFields = DataviewField.fromList(this.fieldsConfig.concat([
                    {
                        id: 'ipAddress',
                        name: 'Ip address',
                    },
                    {
                        id: 'description',
                        name: 'Description',
                        maxWidth: 250,
                        getTooltip: function (softwareInstallation) {
                            return softwareInstallation.description;
                        },
                    },
                ]));
            }
            return this._networkDeviceSoftwareInstallationFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoftwareInstallationAggFieldsService.prototype, "linuxSoftwareInstallationFields", {
        get: function () {
            var _this = this;
            if (!this._linuxSoftwareInstallationFields) {
                var linuxFields = this.fieldsConfig.slice(0, 1).concat([
                    {
                        id: 'osDistribution',
                        name: this.i18nService.get('tvm_common_osDistribution'),
                        sort: { enabled: false },
                        getDisplay: function (softwareInstallation) {
                            return softwareInstallation.osDistribution
                                ? _this.i18nService.get("tvm_common_osDistribution_" + softwareInstallation.osDistribution)
                                : _this.i18nService.strings.notAvailable_short;
                        },
                    }
                ], this.fieldsConfig.slice(1));
                this._linuxSoftwareInstallationFields = DataviewField.fromList(linuxFields);
            }
            return this._linuxSoftwareInstallationFields;
        },
        enumerable: true,
        configurable: true
    });
    return SoftwareInstallationAggFieldsService;
}());
export { SoftwareInstallationAggFieldsService };
