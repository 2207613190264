import { Inject, Injectable, LOCALE_ID, Optional } from '@angular/core';
import { LocaleConfigService } from '@wcd/localization';
import { prettyNumber } from '@wcd/prettify';

@Injectable()
export class PrettyNumberService {
	constructor(
		@Inject(LOCALE_ID) private readonly appLocale: string,
		@Optional() private readonly localeConfigService: LocaleConfigService
	) {}

	prettyNumber(number: number, locale?: string): string {
		if (!locale && this.localeConfigService) {
			locale = this.localeConfigService.selectedLocale;
		}
		return prettyNumber(number, locale || this.appLocale);
	}
}
