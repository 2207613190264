var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { ExposureScoreCategory } from '../common/exposure-score/exposure-score-category.enum';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { AssetRecommendationContext } from '../asset/asset-recommendation-context.entity';
import { TvmSupportedOsPlatform } from '../common/tvm-supported-os-platform.enum';
var ɵ0 = function (id) {
    return "assets/" + id;
}, ɵ1 = function (date) { return new Date(date).getTime(); }, ɵ2 = function (_, Asset) { return new Date(Asset.lastSeen).toLocaleString(); };
var Asset = /** @class */ (function (_super) {
    __extends(Asset, _super);
    function Asset() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "osName", void 0);
    __decorate([
        EntityField({
            data: 'recommendationContext',
            arrayOf: AssetRecommendationContext,
        }),
        __metadata("design:type", Array)
    ], Asset.prototype, "recommendationContext", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "exposureScore", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "exposureLevel", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "secureScore", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "maxSecureScore", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "securityRecommendations", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "discoveredVulnerabilities", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Asset.prototype, "isUserProductivityImpacted", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Asset.prototype, "isFastlaneSource", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], Asset.prototype, "affectedSoftwareIds", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], Asset.prototype, "numOfPaths", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "mitigationStatus", void 0);
    __decorate([
        EntityField({
            parse: ɵ1,
        }),
        __metadata("design:type", String)
    ], Asset.prototype, "lastSeen", void 0);
    __decorate([
        EntityField({
            parse: ɵ2,
        }),
        __metadata("design:type", String)
    ], Asset.prototype, "lastSeenString", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "osPlatform", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], Asset.prototype, "osVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], Asset.prototype, "isManagedByMdatp", void 0);
    Asset = __decorate([
        Entity(__assign({}, TvmAnalyticsSharedEntityConfigurations, { singularName: 'Asset', pluralName: 'Assets', endpoint: 'assets', parseItemQuery: ɵ0, readonly: true }))
    ], Asset);
    return Asset;
}(EntityModelBase));
export { Asset };
export { ɵ0, ɵ1, ɵ2 };
