var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ModelBase, EntityField, ValueObject } from '@microsoft/paris';
var TotalSecureScore = /** @class */ (function (_super) {
    __extends(TotalSecureScore, _super);
    function TotalSecureScore() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({
            required: true,
            data: 'currentScore',
        }),
        __metadata("design:type", Number)
    ], TotalSecureScore.prototype, "secureScore", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'maxScore',
        }),
        __metadata("design:type", Number)
    ], TotalSecureScore.prototype, "maxSecureScore", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'currentScoreHistory',
        }),
        __metadata("design:type", Array)
    ], TotalSecureScore.prototype, "secureScoreHistory", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'maxScoreHistory',
        }),
        __metadata("design:type", Array)
    ], TotalSecureScore.prototype, "maxSecureScoreHistory", void 0);
    __decorate([
        EntityField({
            required: true,
            data: 'secureScoreHistory',
        }),
        __metadata("design:type", Array)
    ], TotalSecureScore.prototype, "secureScoreHistoryPercentage", void 0);
    TotalSecureScore = __decorate([
        ValueObject({
            singularName: 'Total secure score',
            pluralName: 'Total secure scores',
            readonly: true,
        })
    ], TotalSecureScore);
    return TotalSecureScore;
}(ModelBase));
export { TotalSecureScore };
