declare let angular: angular.IAngularStatic;
import { SevilleModule } from '../../seville/seville.module';

// This directive controls collapse/expand of the transcluded element.
// The expand/collapse buttons are shown only if the transcluded element is too heigh - if it's truncated after applying the class from the heightLimitClassName parameter

SevilleModule.directive('dynamicExpandableDiv', dynamicExpandableDivDirective);

dynamicExpandableDivDirective.$inject = [];

function dynamicExpandableDivDirective() {
	return {
		restrict: 'EA',
		transclude: true,
		scope: {
			heightLimitClassName: '@',
			collapseExpandDivClassName: '@',
		},
		controllerAs: 'collapseControls',
		template: `
		<div ng-transclude class="{{collapseControls.appliedClass}} transcluded-collapsible-element"></div>
    <div class="text-center" ng-class="::collapseExpandDivClassName" ng-show="collapseControls.showCollapseExpandButtonsElement">
		<a
			href="javascript:void(0)"
			ng-click="collapseControls.collapseElement = !collapseControls.collapseElement"
			role="button"
			ng-attr-aria-label="{{ collapseControls.collapseElement ? 'Show more' : 'Show less' }}"
		>
            <span ng-show="collapseControls.collapseElement">
                Show more
            </span>
            <span ng-show="!collapseControls.collapseElement">
                Show less
            </span>
        </a>
    </div>
		`,
		controller: [
			'$scope',
			'$element',
			'$window',
			'$rootScope',
			'$timeout',
			function($scope, $element, $window, $rootScope, $timeout) {
				var vm = this;

				vm.showCollapseExpandButtonsElement = false;
				vm.collapseElement = true;
				vm.appliedClass = $scope.heightLimitClassName;
				vm.transcludedWrapperDiv = $element.find('.transcluded-collapsible-element')[0];

				// Update the applied class (that applies the truncation - the height limitation) based on $scope.appliedClass field
				$scope.$watch(
					function() {
						return vm.collapseElement;
					},
					function(newValue, oldValue) {
						if (newValue) {
							vm.appliedClass = $scope.heightLimitClassName;
							$timeout(vm.setVisibilityOfCollapseExpandButtonsElement, 100);
						} else {
							vm.appliedClass = '';
						}
					}
				);

				// Show the section with the collapse/expand buttons if the transcluded Element is overflown or if the section was expanded by the user,
				// and hide otherwise.
				vm.setVisibilityOfCollapseExpandButtonsElement = function() {
					vm.showCollapseExpandButtonsElement =
						!vm.collapseElement ||
						vm.transcludedWrapperDiv.clientHeight < vm.transcludedWrapperDiv.scrollHeight;
				};

				$rootScope.$on('sidePane:paneUpdated', vm.setVisibilityOfCollapseExpandButtonsElement);

				angular.element($window).bind('resize', function() {
					vm.setVisibilityOfCollapseExpandButtonsElement();
					// manuall $digest required as resize event is outside of angular
					$scope.$digest();
				});

				$timeout(vm.setVisibilityOfCollapseExpandButtonsElement, 0);
			},
		],
	};
}
