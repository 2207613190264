import { keyBy } from 'lodash-es';
import { OnboardingStatus } from './device-onboarding-status.enum';
export const onboardingStatuses = [
	{
		id: OnboardingStatus.Onboarded,
		i18nNameKey: 'devices_page_Onboarded',
		priority: 1,
	},
	{
		id: OnboardingStatus.NotOnboarded,
		i18nNameKey: 'devices_page_NotOnboarded',
		priority: 2,
	},
	{
		id: OnboardingStatus.Unsupported,
		i18nNameKey: 'devices_page_Unsupported',
		priority: 3,
	},

	{
		id: OnboardingStatus.InsufficientInfo,
		i18nNameKey: 'devices_page_InsufficientInfo',
		priority: 4,
	},
];

export const onboardingStatusesMap = keyBy(onboardingStatuses, o => o.id);
