<div class="wizard wcd-flex-vertical wcd-full-height" *ngLet="(currentStep$ | async) as currentStep">
	<header
		class="wizard-header wcd-border-bottom wcd-padding-large-horizontal wcd-padding-mediumSmall-vertical">
		<div class="wcd-flex-1 wcd-flex-horizontal">
			<div *ngIf="wizardTitlePrefix" class="spaced">{{ wizardTitlePrefix | i18n }} </div>
			<div class="wcd-font-weight-bold">{{ wizardTitle | i18n }}</div>
		</div>
		<button class="btn-close" (click)="onClose.emit()"
			(click)="onClose.emit()"
			[attr.aria-label]="'buttons.close' | i18n"
			[wcdTooltip]="'buttons.close' | i18n"
			role="button"
			tabindex="0"
			[focusOn]="true">
					<fab-icon contentClass="medium-icon" iconName="Cancel"></fab-icon>
		</button>
	</header>
	<div class="wizard-container wcd-full-height">
		<div class="steps wcd-flex-0 wcd-border-right wcd-scroll-vertical">
			<vertical-steps
				[steps]="verticalSteps$ | async"
				[isPanel]="true"
				containerClassName="wcd-padding-top wcd-padding-large-horizontal"
			></vertical-steps>
		</div>
		<div class="wizard-step">
			<form #wizardForm="ngForm" class="wizard-content wcd-padding-large-vertical wcd-padding-large-horizontal">
				<div #stepTarget></div>
			</form>
			<footer class="wizard-footer wcd-justify-self-end wcd-border-top">
				<div class="wcd-flex-1 wcd-margin-small-right">
					<fab-default-button
						className="btn-secondary"
						*ngIf="currentStep?.withBackButton"
						[disabled]="!backButtonEnabled[this.currentStepIndex]"
						(onClick)="previousStep()"
					>
						{{ 'buttons.back' | i18n }}
					</fab-default-button>
					<fab-primary-button
						*ngIf="currentStep?.withNextButton && nextButtonMode[this.currentStepIndex] === NextButtonModeEnum.Normal"
						[disabled]="!wizardForm.valid || !isValidStep()"
						(onClick)="nextStep()"
					>
						{{ currentStep?.nextButtonText | i18n }}
					</fab-primary-button>
					<fab-default-button
						className="btn-secondary"
						*ngIf="currentStep?.withNextButton && nextButtonMode[this.currentStepIndex] === NextButtonModeEnum.Skip"
						[disabled]="!wizardForm.valid || !isValidStep()"
						(onClick)="nextStep()"
					>
						{{ nextSkipButtonText[this.currentStepIndex] | i18n }}
					</fab-default-button>
				</div>

				<div class="wcd-flex-none">
					<fab-primary-button *ngIf="currentStep?.withDoneButton" [fabButtonFocusOn]="currentStep?.withDoneButton" [focusingDelay]="1" (onClick)="doneStep()">
						{{ 'buttons.done' | i18n }}
					</fab-primary-button>
				</div>
			</footer>
		</div>
	</div>
</div>
