
		<div *ngIf="inaccuracyContext && contextObject" class="wcd-flex-none">
			<fancy-select
				[values]="moreMenuValues"
				[showIconDropdown]="false"
				[highlightSelected]="false"
				[useValueAsButtonText]="false"
				[buttonIcon]="'MoreVertical'"
				[dropdownAreaRole]="role"
				[ariaLabel]="'tvm.reportInaccuracy.moreOptions' | i18n"
				[ngModel]="selection"
				(ngModelChange)="onSelect($event)"
			></fancy-select>
		</div>
	