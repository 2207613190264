import { Tag, DataSensitivity, TagType, internetFacingReasonMap } from '@wcd/domain';
import { Injectable } from '@angular/core';
import { I18nService } from '@wcd/i18n';

@Injectable()
export class TagsService {
	constructor(private readonly i18n: I18nService) {}

	static getTagsArray<T = {}>(items: Array<T>, getItemTags: (item: T) => Array<Tag>): Array<Tag> {
		let tagsMap: Map<string, { tag: Tag; count: number }> = new Map<
			string,
			{ tag: Tag; count: number }
		>();
		const tags: Array<Tag> = [];

		// get all items' tags and map partials occurrences for combined tags list
		if (items) {
			tagsMap = items.reduce((tagsMap, item) => {
				const itemTags: Array<Tag> = getItemTags(item);
				if (itemTags) {
					itemTags.forEach((tag: Tag) => {
						const existingTag: { tag: Tag; count: number } = tagsMap.get(tag.id);
						tagsMap.set(tag.id, {
							tag: tag,
							count: existingTag ? ++existingTag.count : 1,
						});
					});
				}

				return tagsMap;
			}, new Map<string, { tag: Tag; count: number }>());

			if (tagsMap.size) {
				tagsMap.forEach(value => {
					tags.push(value.tag);
					if (value.count < items.length) value.tag.isPartial = true;
				});
			}
		}

		return tags;
	}

	getDataSensitivityTag(sensitivity: DataSensitivity, tooltip?: string, showPrefix: boolean = true): Tag {
		const tagType: TagType = sensitivity.isEndpointDlpApplied
			? TagType.sensitiveData
			: TagType.classifiedData;

		return {
			id: 'dataSensitivity',
			name: (showPrefix ? this.i18n.get('files.sensitivity.tag.prefix') : '') + sensitivity.label,
			type: tagType,
			className: `tag tag-wide tag-color-box-${tagType}`,
			tooltip: tooltip,
			isEditable: false,
		};
	}

	getInternetFacingTag(internetFacingReason): Tag {
		return {
			id: 'device-internet-facing-tag',
			name: this.i18n.strings.files_internetFacing_tag,
			type: TagType.internetFacing,
			className: `tag tag-wide tag-color-box-threatExpert`,
			tooltip: this.i18n.get(internetFacingReasonMap[internetFacingReason].i18nNameKey),
			isEditable: false,
		};
	}
}
