import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { SampleActionStateResponse } from './file-sample-action-state-response.value.object';
import { SampleActionStateRequest } from './file-sample-action-state-request.value.object';

@ApiCall({
	name: 'Get sample status',
	endpoint: 'sampledownloadrequest/state',
	method: 'GET',
	type: SampleActionStateResponse,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: (params: SampleActionStateRequest) => ({
		params: {
			Sha: params.sha,
			CheckPrevalence: params.checkPrevalence,
		},
	}),
})
export class GetSampleStateApiCall extends ApiCallModel<
	SampleActionStateResponse,
	SampleActionStateRequest
> {}
