var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataViewModel } from '../../models/dataview.model';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FabricIconNames } from '@wcd/scc-common';
import { CsvUtils } from '@wcd/shared';
import { take } from 'rxjs/operators';
var DataviewActionSearchComponent = /** @class */ (function () {
    function DataviewActionSearchComponent(i18nService, appInsightsService) {
        var _this = this;
        this.i18nService = i18nService;
        this.appInsightsService = appInsightsService;
        this.smallScreenView = false;
        this.icon = FabricIconNames.Search;
        this.tooltip = 'select.clear';
        this.SearchSafePadding = 2;
        this.searchOpened = false;
        this.allowMultipleWords = false;
        this.openSearch = function () {
            _this.searchOpened = true;
        };
    }
    DataviewActionSearchComponent.prototype.ngOnInit = function () {
        var _this = this;
        Object.assign(this, __assign({}, this.searchActionConfig));
        this.searchLocalized = this.searchActionConfig.searchLocalized;
        this.searchButtonConfig = {
            localizedTooltip: this.searchLocalized,
            onClickCallback: this.openSearch,
            icon: FabricIconNames.Search,
            localizedLabel: this.searchLocalized,
        };
        // When the first event is fired (if the page is loaded with search term in url) sets the value
        this._onSearchChangeSubscription = this.dataViewModel.searchTerm$.pipe(take(1)).subscribe(function () {
            if (_this.dataViewModel.currentSearchTerm) {
                _this.onSearchBlur();
                _this.openSearch();
            }
        });
    };
    DataviewActionSearchComponent.prototype.search = function (term) {
        var _this = this;
        // if ignoring the query params and the data isn't local, the search term won't be updated because navigate on change
        // won't happen so the search term must be set inside the data view model as the third argument (setCurrentSearchTerm)
        var trimmedTerm = term.trim();
        if (this.lastSearched !== trimmedTerm) {
            this.lastSearched = trimmedTerm;
            var setCurrentSearchTerm = this.ignoreQueryParams;
            this.dataViewModel.setSearchTerm(this.allowMultipleWords ? DataviewActionSearchComponent.parseWords(trimmedTerm) : trimmedTerm, true, setCurrentSearchTerm);
            this.trackEvent('DataviewSearchGo');
            setTimeout(function () { return _this.searchActionConfig.searchCallback(); });
        }
    };
    DataviewActionSearchComponent.prototype.cancelSearch = function () {
        this.searchOpened = false;
        this.search('');
        this.searchTerm = null;
        this.trackEvent('DataviewSearchClear');
    };
    DataviewActionSearchComponent.prototype.onSearchBlur = function () {
        if (!this.searchTerm && this.dataViewModel.currentSearchTerm)
            this.searchTerm = this.dataViewModel.currentSearchTerm;
    };
    DataviewActionSearchComponent.prototype.destroy = function () {
        if (this.dataViewModel) {
            this._onSearchChangeSubscription && this._onSearchChangeSubscription.unsubscribe();
        }
    };
    DataviewActionSearchComponent.prototype.trackEvent = function (eventId) {
        this.appInsightsService.track({
            id: eventId,
            component: this.searchActionConfig.id + "_DataView",
            type: TrackingEventType.Button,
            componentType: 'DataView',
        });
    };
    DataviewActionSearchComponent.parseWords = function (searchTerm) {
        var wordsToSearch = [];
        if (searchTerm.startsWith('"') && searchTerm.endsWith('"')) {
            // if starts and ends with '"' - we should consider this string as a single word
            wordsToSearch = [searchTerm.slice(1, -1)]; // remove first and last '"'
        }
        else {
            wordsToSearch = searchTerm.match(/\S+/g) || [];
        }
        return wordsToSearch.map(function (word) { return CsvUtils.encodeCsv(word); }).join(',');
    };
    return DataviewActionSearchComponent;
}());
export { DataviewActionSearchComponent };
