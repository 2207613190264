
		<modal (close)="destroy()" [settings]="settings">
			<form class="dialog-contents" (submit)="submit()" data-track-component="LiveResponseScriptUpload">
				<div class="wcd-flex-vertical inputs-container">
					<input
						type="file"
						id="scriptContent"
						name="scriptContent"
						class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[attr.aria-label]="
							filename || i18nService.strings.liveResponse_actions_uploadScript_scriptContent
						"
						(change)="setFile($event)"
					/>
					<input
						type="text"
						id="description"
						name="scriptDescription"
						[attr.aria-label]="
							i18nService.strings.liveResponse_actions_uploadScript_scriptDescription
						"
						class="form-control wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[placeholder]="
							i18nService.strings.liveResponse_actions_uploadScript_scriptDescription
						"
						[(ngModel)]="script.description"
					/>
					<wcd-checkbox
						[id]="'overwriteExistingFile'"
						name="overwriteExistingFile"
						class="wcd-margin-small-all wcd-flex-1"
						[label]="i18nService.strings.liveResponse_actions_uploadScript_overwriteFile"
						[(ngModel)]="overwriteExistingFile"
					>
						<wcd-help
							[text]="i18nService.strings.liveResponse_actions_uploadScript_fileAlreadyExists"
						>
						</wcd-help>
					</wcd-checkbox>
					<wcd-checkbox
						[id]="'scriptHasParams'"
						name="scriptHasParams"
						class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[label]="i18nService.strings.liveResponse_actions_uploadScript_scriptParameters"
						[(ngModel)]="script.hasParams"
					>
					</wcd-checkbox>
					<input
						*ngIf="script.hasParams"
						type="text"
						id="paramsDescription"
						name="scriptParamDecription"
						class="form-control wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex"
						[attr.placeholder]="
							i18nService.strings.liveResponse_actions_uploadScript_parametersDescription
						"
						[(ngModel)]="script.paramsDescription"
					/>
				</div>
				<footer class="wcd-flex-horizontal wcd-margin-top">
					<div class="wcd-margin-small-all wcd-flex-1 ie11Patch ie11Flex">
						<button
							type="submit"
							data-track-id="Submit"
							data-track-type="Button"
							class="btn btn-primary wcd-margin-small-right"
							[disabled]="!script?.file || isSaving"
						>
							<i *ngIf="isSaving" class="loader-icon"></i>
							{{ i18nService.strings.buttons_confirm }}
						</button>
					</div>
				</footer>
			</form>
		</modal>
	