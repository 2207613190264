var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnInit } from '@angular/core';
import { ModalContainer } from '../../../dialogs/modals/models/modal-container.model';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
var AVAILABLE_RESTORE_ALERTS_OPTIONS = [
    { id: true, textKey: 'restoreAlerts' },
    { id: false, textKey: 'KeepAlertsSuppressed' },
];
var DisableRuleConfirmModalComponent = /** @class */ (function (_super) {
    __extends(DisableRuleConfirmModalComponent, _super);
    function DisableRuleConfirmModalComponent(router, i18nService) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.i18nService = i18nService;
        _this.setActionTexts();
        return _this;
    }
    Object.defineProperty(DisableRuleConfirmModalComponent.prototype, "isValid", {
        get: function () {
            return !!(this.confirmText && this.restoreAlerts);
        },
        enumerable: true,
        configurable: true
    });
    DisableRuleConfirmModalComponent.prototype.setActionTexts = function () {
        var _this = this;
        this.selectableActions = AVAILABLE_RESTORE_ALERTS_OPTIONS.map(function (action) {
            return {
                id: action.id,
                name: _this.i18nService.get("suppressionRules.disable." + action.textKey),
            };
        });
    };
    DisableRuleConfirmModalComponent.prototype.ngOnInit = function () {
        if (this.isDeleteMode) {
            this.confirmBtn = this.i18nService.get("suppressionRules.delete.confirmButton");
        }
        else {
            this.confirmBtn = this.i18nService.get("suppressionRules.disable.confirmButton");
        }
    };
    DisableRuleConfirmModalComponent.prototype.submitDisable = function () {
        this.confirm({ restoreAlerts: this.restoreAlerts.id, comment: this.confirmText });
    };
    DisableRuleConfirmModalComponent.prototype.close = function () {
        this.cancel();
    };
    return DisableRuleConfirmModalComponent;
}(ModalContainer));
export { DisableRuleConfirmModalComponent };
