import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import { AirsIp, InterflowIpDataApiCall } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { EMPTY, Subject, of } from 'rxjs';
import { distinctUntilChanged, switchMap, map, startWith, catchError } from 'rxjs/operators';
import { SpinnerSize } from 'office-ui-fabric-react';
import { tenantContextCache } from '@wcd/auth';
import { startCase, toLower } from 'lodash-es';

const LOADING_SYMBOL = Symbol();

@Component({
	selector: 'airs-ip-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngLet="(interflowData$ | async) as interflowData">
			<dl class="key-values clearfix" role="none">
				<ng-container *ngIf="entity.address">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_address }}</dt>
					<dd role="none">{{ entity.address }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.organizationAsn">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_asn }}</dt>
					<dd role="none">{{ entity.organizationAsn }}</dd>
				</ng-container>
				<ng-container *ngIf="entity.organizationName">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_organization }}</dt>
					<dd role="none">{{ entity.organizationName }}</dd>
				</ng-container>
				<ng-container *ngIf="interflowData && interflowData !== LOADING_SYMBOL">
					<dt role="none">{{ i18nService.strings.airsEntities_ip_fields_geoInformation }}</dt>
					<dd role="none">
						<generic-object
							[fieldName]="i18nService.strings.airsEntities_ip_fields_geoInformation"
						>
							<dl class="key-values clearfix" role="none">
								<ng-container *ngIf="interflowData.city">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_city }}
									</dt>
									<dd role="none">{{ interflowData.city | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.postalCode">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_postal }}
									</dt>
									<dd role="none">{{ interflowData.postalCode }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.country || interflowData.region">
									<dt role="none">
										{{
											i18nService.strings.airsEntities_ip_fields_geoInformation_country_and_region
										}}
									</dt>
									<dd role="none">
										{{ countryName }}
									</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.state">
									<dt role="none">
										{{ i18nService.strings.airsEntities_ip_fields_geoInformation_state }}
									</dt>
									<dd role="none">{{ interflowData.state | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.carrier">
									<dt role="none">
										{{
											i18nService.strings.airsEntities_ip_fields_geoInformation_carrier
										}}
									</dt>
									<dd role="none">{{ interflowData.carrier | title }}</dd>
								</ng-container>
								<ng-container *ngIf="interflowData.coordinates">
									<dt role="none">
										{{
											i18nService.strings
												.airsEntities_ip_fields_geoInformation_coordinates
										}}
									</dt>
									<dd role="none">{{ interflowData.coordinates }}</dd>
								</ng-container>
							</dl></generic-object
						>
					</dd>
				</ng-container>
			</dl>
			<ng-container *ngIf="interflowData === LOADING_SYMBOL" [ngTemplateOutlet]="loader"></ng-container>
		</ng-container>
		<ng-template #loader>
			<div class="wcd-flex-center-all wcd-padding-all">
				<fab-spinner [size]="SpinnerSize.small"></fab-spinner>
			</div>
		</ng-template>
	`,
})
export class AirsIpDetailsComponent implements OnChanges, AfterViewInit {
	LOADING_SYMBOL = LOADING_SYMBOL;
	SpinnerSize = SpinnerSize;
	@Input() entity: AirsIp;

	private address$ = new Subject<string>();
	interflowData$ = this.address$.pipe(
		distinctUntilChanged(),
		switchMap(address =>
			!tenantContextCache.hasMtpConsent && !tenantContextCache.appConfig.IsMdatpActive
				? EMPTY
				: of(address)
		),
		switchMap(address => (address ? this.paris.apiCall(InterflowIpDataApiCall, { address }) : of(null))),
		map(interflowData => {
			const currentData =
				interflowData &&
				Object.values(interflowData).some(v =>
					typeof v === 'object' ? Object.values(v).some(Boolean) : !!v
				)
					? interflowData
					: null;

			this.countryName = currentData.country && startCase(toLower(currentData.country));
			this.countryName += currentData.country && currentData.region && '/';
			this.countryName += currentData.region && startCase(toLower(currentData.region));

			return currentData;
		}),
		catchError(err => of(null)),
		startWith(LOADING_SYMBOL)
	);

	countryName: string;

	constructor(private paris: Paris, public i18nService: I18nService) {}

	ngAfterViewInit() {
		this.setAddress();
	}

	ngOnChanges(changes) {
		this.setAddress();
	}

	private setAddress() {
		this.address$.next(this.entity && this.entity.address);
	}
}
