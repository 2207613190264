import { DataviewField } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { AssessmentJob } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { AssessmentJobService } from './assessment-job.service';

@Injectable()
export class AssessmentJobFieldsService {
	private _fields: Array<DataviewField<AssessmentJob>>;

	constructor(private i18nService: I18nService, private assessmentJobService: AssessmentJobService) { }

	get fields(): Array<DataviewField<AssessmentJob>> {
		if (!this._fields) {
			this._fields = DataviewField.fromList<AssessmentJob>([
				{
					id: 'scanName',
					name: this.i18nService.get('tvm.authenticatedScan.jobName'),
					sort: {
						enabled: true,
					},
				},
				{
					id: 'agentName',
					name: this.i18nService.get('tvm.authenticatedScan.creationPanel.scanner'),
					sort: {
						enabled: true,
					},
				},
				...(this.assessmentJobService.isWindowsScanFeatureEnabled()
					? [
							{
								id: 'scanType',
								name: this.i18nService.get('tvm.authenticatedScan.assessmentType'),
								sort: {
									enabled: true,
								},
							},
					  ]
					: []),
				{
					id: 'originalTargetRanges',
					name: this.i18nService.get('tvm.authenticatedScan.target'),
					sort: {
						enabled: true,
					},
				},
				{
					id: 'createdBy',
					name: this.i18nService.get('tvm.common.createdBy'),
					sort: {
						enabled: true,
					},
				},
				{
					id: 'active',
					name: this.i18nService.get('tvm.authenticatedScan.status'),
					getDisplay: (assessmentJob: AssessmentJob) =>
					assessmentJob.active
							? this.i18nService.get('tvm.authenticatedScan.dataview.jobStatus.active')
							: this.i18nService.get('tvm.authenticatedScan.dataview.jobStatus.inactive'),
					sort: {
						enabled: true,
					},
				},
				{
					id: 'lastScanStatus',
					name: this.i18nService.get('tvm.authenticatedScan.lastRun.status'),
					sort: {
						enabled: true,
					},
				},
				{
					id: 'lastScanDate',
					name: this.i18nService.get('tvm.authenticatedScan.lastRun.date'),
					getDisplay: (assessment: AssessmentJob) => {
						return assessment.lastScanDate ? new Date(assessment.lastScanDate).toLocaleString() : '-';
					},
					sort: {
						enabled: true,
						sortCompareFunction: (
							assessment1: AssessmentJob,
							assessment2: AssessmentJob
						) => (assessment1.lastScanDate ? assessment1.lastScanDate.getTime() : 0) - (assessment2.lastScanDate ? assessment2.lastScanDate.getTime() : 0),
					},
				},
			]);
		}

		return this._fields;
	}
}
