
		<wcd-panel (close)="destroy()" (save)="closePanel()" [settings]="settings">
			<form
				#sampleCollectForm="ngForm"
				(keydown.enter)="$event.preventDefault()"
				class="wcd-full-height wcd-flex-vertical"
			>
				<div class="wcd-flex-1">
					<div class="subtle wcd-margin-large-bottom">
						{{ file.name }}
					</div>
					<div class="wcd-margin-xsmall-bottom">
						{{ 'file.sample.collect.collectReasonHeader' | i18n }}
					</div>
					<textarea
						class="form-control comment-input wcd-full-width wcd-margin-large-bottom wcd-required wcd-no-resize"
						[placeholder]="'file.sample.collect.collectReasonPlaceholder' | i18n"
						maxlength="200"
						autofocus
						required
						rows="5"
						[(ngModel)]="sampleCollectionReason"
						name="reason"
						[attr.aria-label]="'file.sample.collect.collectReasonHeader' | i18n"
					></textarea>
				</div>
				<div class="wcd-flex-none wcd-padding-all">
					<fab-message-bar
						[messageBarType]="MessageBarType.info"
						[styles]="{ text: { marginBottom: 16 } }"
					>
						{{ 'file.sample.collect.collectionDurationInfo' | i18n }}
					</fab-message-bar>
				</div>
				<div class="wcd-flex-none wcd-padding-all wcd-border-top wcd-flex-horizontal">
					<fab-primary-button
						[disabled]="!isValid()"
						(onClick)="collectSample($event)"
						(keyup.enter)="collectSample($event)"
						className="wcd-margin-small-right"
						data-track-type="Button"
						[text]="'file.sample.collect.buttons.submit' | i18n"
						type="submit"
					>
					</fab-primary-button>
					<fab-default-button
						(onClick)="closePanel()"
						(keyup.enter)="closePanel()"
						data-track-type="Button"
						[text]="'file.sample.collect.buttons.cancel' | i18n"
					>
					</fab-default-button>
				</div>
			</form>
		</wcd-panel>
	