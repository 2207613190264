<modal (close)="destroy()" [settings]="settings">
	<form class="dialog-contents" #bilbaoRegistrationForm="ngForm" (submit)="submit()" data-track-component="BilbaoRegistration">
		<markdown class="wcd-margin-bottom" [data]="'settings.advancedFeatures.features.managedThreatHuntingRegistration.description' | i18n"></markdown>
		<div class="wcd-flex-vertical">
			<input type="text"
				id="bilbao-name"
				#firstNameControl="ngModel"
				name="bilbaoRegistrationName"
				[required]="true"
				class="form-control wcd-margin-small-vertical wcd-flex-1"
				[placeholder]="'settings.advancedFeatures.features.managedThreatHuntingRegistration.placeholder.name' | i18n"
				[(ngModel)]="registration.name" />
			<input type="email"
				id="bilbao-email"
				validateRegExp="emailWithSpecialChars"
				#emailControl="ngModel"
				name="bilbaoRegistrationEmail"
				[required]="true"
				class="form-control wcd-margin-small-vertical wcd-flex-1"
				[placeholder]="'settings.advancedFeatures.features.managedThreatHuntingRegistration.placeholder.email' | i18n"
				[(ngModel)]="registration.email" />
			<markdown [data]="'common.privacyStatement' | i18n"></markdown>
		</div>
		<footer class="wcd-flex-horizontal wcd-margin-top">
			<div class="wcd-flex-1">
				<fab-primary-button
					data-track-id="Submit"
					data-track-type="Button"
					[text]="('settings.advancedFeatures.features.managedThreatHuntingRegistration.' + (isSaving ? 'submitting' : 'submit')) | i18n"
					(onClick)="submit()"
					[disabled]="!bilbaoRegistrationForm.form.valid || isSaving"></fab-primary-button>
				<div class="error-message" [class.invisible]="emailControl.valid">
					{{'settings.advancedFeatures.features.managedThreatHuntingRegistration.invalidEmail' | i18n}}
				</div>
			</div>
		</footer>
	</form>
</modal>
