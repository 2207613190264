import { I18nService } from '@wcd/i18n';
import { AlertCategoryType, alertCategoryValues, AlertClassificationType, AlertDetectionSourceType, AlertDeterminationType, AlertSeverityType, AlertStatusReportType, combinedAlertDeterminationType, SeverityType, } from '@wcd/domain';
import { SeverityTypeColorService } from '../shared/services/severity-type-color.service';
import { KnownColorsService } from '../shared/services/known-colors.service';
import { Feature, FeaturesService } from '@wcd/config';
import * as i0 from "@angular/core";
import * as i1 from "../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i2 from "../shared/services/known-colors.service";
import * as i3 from "../shared/services/severity-type-color.service";
import * as i4 from "../../../../../projects/config/src/lib/services/features.service";
var ThreatProtectionStatusMapping = /** @class */ (function () {
    function ThreatProtectionStatusMapping(i18nService, knownColorsService, severityTypeColorService, featureService) {
        var _a, _b, _c, _d, _e, _f;
        this.i18nService = i18nService;
        this.knownColorsService = knownColorsService;
        this.severityTypeColorService = severityTypeColorService;
        this.featureService = featureService;
        this.isCombinedClassificationEnabled = false;
        this._severityMapping = (_a = {},
            _a[AlertSeverityType.High] = {
                knownColorsMap: {
                    daily: this.severityTypeColorService.colorsMap.get(SeverityType.High),
                    trend: this.severityTypeColorService.colorsMap.get(SeverityType.High),
                },
                label: this.i18nService.get("alert.severity.high"),
                alertListFilter: 'High',
                priority: 1,
            },
            _a[AlertSeverityType.Medium] = {
                knownColorsMap: {
                    daily: this.severityTypeColorService.colorsMap.get(SeverityType.Medium),
                    trend: this.severityTypeColorService.colorsMap.get(SeverityType.Medium),
                },
                label: this.i18nService.get("alert.severity.medium"),
                alertListFilter: 'Medium',
                priority: 2,
            },
            _a[AlertSeverityType.Low] = {
                knownColorsMap: {
                    daily: this.severityTypeColorService.colorsMap.get(SeverityType.Low),
                    trend: this.severityTypeColorService.colorsMap.get(SeverityType.Low),
                },
                label: this.i18nService.get("alert.severity.low"),
                alertListFilter: 'Low',
                priority: 3,
            },
            _a[AlertSeverityType.Informational] = {
                knownColorsMap: {
                    daily: this.severityTypeColorService.colorsMap.get(SeverityType.Informational),
                    trend: this.severityTypeColorService.colorsMap.get(SeverityType.Informational),
                },
                label: this.i18nService.get("alert.severity.informational"),
                alertListFilter: 'Informational',
                priority: 4,
            },
            _a);
        this._detectionSourceMapping = (_b = {},
            _b[AlertDetectionSourceType.EDR] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magenta'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.edr"),
                alertListFilter: 'WindowsDefenderAtp',
                priority: 1,
            },
            _b[AlertDetectionSourceType.Antivirus] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['tealLight'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.antivirus"),
                alertListFilter: 'WindowsDefenderAv',
                priority: 2,
            },
            _b[AlertDetectionSourceType.SmartScreen] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['purpleLight'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.smartScreen"),
                alertListFilter: 'WindowsDefenderSmartScreen',
                priority: 3,
            },
            _b[AlertDetectionSourceType.CustomTI] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['purple'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.customTi"),
                alertListFilter: 'CustomerTI',
                priority: 5,
            },
            _b[AlertDetectionSourceType.OfficeATP] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.officeAtp"),
                alertListFilter: 'OfficeATP',
                priority: 6,
            },
            _b[AlertDetectionSourceType.AutomatedInvestigation] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['greenDark'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.automatedInvestigation"),
                alertListFilter: 'AutomatedInvestigation',
                priority: 7,
            },
            _b[AlertDetectionSourceType.ThreatExperts] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralSecondary'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.threatExperts"),
                alertListFilter: 'ThreatExperts',
                priority: 8,
            },
            _b[AlertDetectionSourceType.CustomDetection] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.customDetection"),
                alertListFilter: 'CustomDetection',
                priority: 9,
            },
            _b[AlertDetectionSourceType.ThirdPartySensors] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByDetectionSource.thirdPartySensors"),
                alertListFilter: 'ThirdPartySensors',
                priority: 12,
            },
            _b);
        this._categoryMapping = (_c = {},
            _c[AlertCategoryType.CommandAndControl] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magenta'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.CommandAndControl; }).type),
                alertListFilter: 'commandAndControl',
            },
            _c[AlertCategoryType.LateralMovement] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['tealLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.LateralMovement; }).type),
                alertListFilter: 'lateralMovement',
            },
            _c[AlertCategoryType.Malware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Malware; }).type),
                alertListFilter: 'malware',
            },
            _c[AlertCategoryType.Persistence] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['neutralSecondary'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Persistence; }).type),
                alertListFilter: 'persistence',
            },
            _c[AlertCategoryType.PrivilegeEscalation] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.PrivilegeEscalation; }).type),
                alertListFilter: 'privilegeEscalation',
            },
            _c[AlertCategoryType.Ransomware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Ransomware; }).type),
                alertListFilter: 'ransomware',
            },
            _c[AlertCategoryType.SuspiciousActivity] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.SuspiciousActivity; }).type),
                alertListFilter: 'suspiciousActivity',
            },
            _c[AlertCategoryType.UnwantedSoftware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.UnwantedSoftware; }).type),
                alertListFilter: 'unwantedSoftware',
            },
            _c[AlertCategoryType.Exploit] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Exploit; }).type),
                alertListFilter: 'exploit',
            },
            _c[AlertCategoryType.InitialAccess] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.InitialAccess; }).type),
                alertListFilter: 'initialAccess',
            },
            _c[AlertCategoryType.Execution] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['teal'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Execution; }).type),
                alertListFilter: 'execution',
            },
            _c[AlertCategoryType.Exfiltration] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Exfiltration; }).type),
                alertListFilter: 'exfiltration',
            },
            _c[AlertCategoryType.Collection] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['orange'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Collection; }).type),
                alertListFilter: 'collection',
            },
            _c[AlertCategoryType.CredentialAccess] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['teal'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.CredentialAccess; }).type),
                alertListFilter: 'credentialAccess',
            },
            _c[AlertCategoryType.DefenseEvasion] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.DefenseEvasion; }).type),
                alertListFilter: 'defenseEvasion',
            },
            _c[AlertCategoryType.Discovery] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Discovery; }).type),
                alertListFilter: 'discovery',
            },
            _c[AlertCategoryType.Impact] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['blue'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByCategory." + alertCategoryValues.find(function (a) { return a.name === AlertCategoryType.Impact; }).type),
                alertListFilter: 'impact',
            },
            _c);
        this._statusMapping = (_d = {},
            _d[AlertStatusReportType.New] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['purpleLight'],
                    trend: this.knownColorsService.knownColorsMap['purpleLight'],
                },
                label: this.i18nService.get("reporting.alertsByStatus.new"),
                alertListFilter: 'New',
                priority: 1,
            },
            _d[AlertStatusReportType.InProgress] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['orangeLighter'],
                    trend: this.knownColorsService.knownColorsMap['orangeLighter'],
                },
                label: this.i18nService.get("reporting.alertsByStatus.inProgress"),
                alertListFilter: 'InProgress',
                priority: 2,
                hiddenOnTrend: true,
            },
            _d[AlertStatusReportType.Resolved] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['greenLight'],
                    trend: this.knownColorsService.knownColorsMap['greenLight'],
                },
                label: this.i18nService.get("reporting.alertsByStatus.resolved"),
                alertListFilter: 'Resolved',
                priority: 3,
                hiddenOnDaily: true,
            },
            _d);
        this._classificationAndDeterminationMapping = (_e = {},
            _e[AlertClassificationType.NotSet] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unknown"),
                alertListFilter: '',
                priority: 1,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _e[AlertClassificationType.Unknown] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unknown"),
                alertListFilter: '',
                priority: 1,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _e[AlertClassificationType.FalsePositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.falseAlert"),
                alertListFilter: 'FalsePositive',
                priority: 2,
            },
            _e[AlertClassificationType.TruePositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.trueAlert"),
                alertListFilter: 'TruePositive',
                priority: 3,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _e[AlertClassificationType.BenignPositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("alertsByClassification.BenignPositive"),
                alertListFilter: 'BenignPositive',
                priority: 4,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _e[AlertDeterminationType.Apt] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['blueMid'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.apt"),
                alertListFilter: 'atp',
                priority: 5,
            },
            _e[AlertDeterminationType.Malware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['teal'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.malware"),
                alertListFilter: 'malware',
                priority: 6,
            },
            _e[AlertDeterminationType.Other] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magentaLight'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.other"),
                alertListFilter: 'other',
                priority: 7,
            },
            _e[AlertDeterminationType.SecurityPersonnel] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magentaDark'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.securityPersonnel"),
                alertListFilter: 'securityPersonnel',
                priority: 8,
            },
            _e[AlertDeterminationType.SecurityTesting] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['purpleLight'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.securityTesting"),
                alertListFilter: 'securityTesting',
                priority: 9,
            },
            _e[AlertDeterminationType.UnwantedSoftware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['blueDark'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unwantedSoftware"),
                alertListFilter: 'unwantedSoftware',
                priority: 10,
            },
            _e);
        this._classificationAndCombinedDeterminationMapping = (_f = {},
            _f[AlertClassificationType.NotSet] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unknown"),
                alertListFilter: '',
                priority: 1,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _f[AlertClassificationType.Unknown] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unknown"),
                alertListFilter: '',
                priority: 2,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _f[AlertClassificationType.FalsePositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.falseAlert"),
                alertListFilter: 'FalsePositive',
                priority: 3,
            },
            _f[AlertClassificationType.TruePositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.trueAlert"),
                alertListFilter: 'TruePositive',
                priority: 4,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _f[AlertClassificationType.BenignPositive] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['neutralQuaternaryAlt'],
                },
                label: this.i18nService.get("alertsByClassification.BenignPositive"),
                alertListFilter: 'BenignPositive',
                priority: 5,
                hiddenOnDaily: true,
                hiddenOnTrend: true,
            },
            _f[combinedAlertDeterminationType.MultiStagedAttack] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['lowSeverity'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.multiStagedAttack"),
                alertListFilter: 'multiStagedAttack',
                priority: 6,
            },
            _f[combinedAlertDeterminationType.Malware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['mediumSeverity'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.malware"),
                alertListFilter: 'malware',
                priority: 7,
            },
            _f[combinedAlertDeterminationType.MaliciousUserActivity] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['highSeverity'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.maliciousUserActivity"),
                alertListFilter: 'maliciousUserActivity',
                priority: 8,
            },
            _f[combinedAlertDeterminationType.UnwantedSoftware] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['criticalStatus'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.unwantedSoftware"),
                alertListFilter: 'unwantedSoftware',
                priority: 9,
            },
            _f[combinedAlertDeterminationType.Phishing] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['dataVis9'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.phishing"),
                alertListFilter: 'phishing',
                priority: 10,
            },
            _f[combinedAlertDeterminationType.CompromisedUser] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['dataVis7'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.compromisedUser"),
                alertListFilter: 'compromisedUser',
                priority: 11,
            },
            _f[combinedAlertDeterminationType.Apt] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['magentaDark'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.apt"),
                alertListFilter: 'apt',
                priority: 12,
            },
            _f[combinedAlertDeterminationType.Other] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['dataVis6'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.other"),
                alertListFilter: 'other',
                priority: 13,
            },
            _f[combinedAlertDeterminationType.SecurityTesting] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['themeDark'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.securityTesting"),
                alertListFilter: 'securityTesting',
                priority: 14,
            },
            _f[combinedAlertDeterminationType.ConfirmedUserActivity] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['themeDarkAlt'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.confirmedUserActivity"),
                alertListFilter: 'confirmedUserActivity',
                priority: 15,
            },
            _f[combinedAlertDeterminationType.LineOfBusinessApplication] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['themeSecondary'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.lineOfBusinessApplication"),
                alertListFilter: 'lineOfBusinessApplication',
                priority: 16,
            },
            _f[combinedAlertDeterminationType.SecurityPersonnel] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['blueMid'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.securityPersonnel"),
                alertListFilter: 'securityPersonnel',
                priority: 17,
            },
            _f[combinedAlertDeterminationType.Clean] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['green'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.clean"),
                alertListFilter: 'clean',
                priority: 18,
            },
            _f[combinedAlertDeterminationType.InsufficientData] = {
                knownColorsMap: {
                    daily: this.knownColorsService.knownColorsMap['tealLight'],
                    trend: this.knownColorsService.knownColorsMap['needHelpBackgroundPressed'],
                },
                label: this.i18nService.get("reporting.alertsByClassification.insufficientData"),
                alertListFilter: 'insufficientData',
                priority: 19,
            },
            _f);
        this.isCombinedClassificationEnabled = this.featureService.isEnabled(Feature.CombinedClassification);
    }
    Object.defineProperty(ThreatProtectionStatusMapping.prototype, "severityMapping", {
        get: function () {
            return this._severityMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreatProtectionStatusMapping.prototype, "detectionSourceMapping", {
        get: function () {
            return this._detectionSourceMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreatProtectionStatusMapping.prototype, "categoryMapping", {
        get: function () {
            return this._categoryMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreatProtectionStatusMapping.prototype, "statusMapping", {
        get: function () {
            return this._statusMapping;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ThreatProtectionStatusMapping.prototype, "classificationAndDeterminationMapping", {
        get: function () {
            return this.isCombinedClassificationEnabled ?
                this._classificationAndCombinedDeterminationMapping : this._classificationAndDeterminationMapping;
        },
        enumerable: true,
        configurable: true
    });
    ThreatProtectionStatusMapping.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThreatProtectionStatusMapping_Factory() { return new ThreatProtectionStatusMapping(i0.ɵɵinject(i1.I18nService), i0.ɵɵinject(i2.KnownColorsService), i0.ɵɵinject(i3.SeverityTypeColorService), i0.ɵɵinject(i4.FeaturesService)); }, token: ThreatProtectionStatusMapping, providedIn: "root" });
    return ThreatProtectionStatusMapping;
}());
export { ThreatProtectionStatusMapping };
