import { Pipe, PipeTransform } from '@angular/core';
import {
	getAlertBlockingStatus,
	getAlertBlockingStatusI18nName,
	getBlockingStatusTooltip,
	getDetectionStatusIcon,
} from '../../_seville/app/common/filters/alertBlockingStatus.filter';

@Pipe({ name: 'detectionStatusDisplay' })
export class DetectionStatusDisplayPipe implements PipeTransform {
	transform(techName: string) {
		return getAlertBlockingStatus(techName);
	}
}

@Pipe({ name: 'detectionStatusI18nName' })
export class DetectionStatusI18nNamePipe implements PipeTransform {
	transform(techName: string) {
		return getAlertBlockingStatusI18nName(techName);
	}
}

@Pipe({ name: 'detectionStatusTooltip' })
export class DetectionStatusTooltipPipe implements PipeTransform {
	transform(techName: string) {
		return getBlockingStatusTooltip(techName);
	}
}

@Pipe({ name: 'detectionStatusIcon' })
export class DetectionStatusIconPipe implements PipeTransform {
	transform(techName: string) {
		return getDetectionStatusIcon(techName);
	}
}
