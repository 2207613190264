import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { from, Observable } from 'rxjs';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { RemediationActionType, RemediationActionTypeTypes } from '@wcd/domain';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
import { v4 as uuid4 } from 'uuid';

const REMEDIATION_ACTIONS_API_ENDPOINT = '/remediation_actions';

export type ActionPostData = {
	action_id: string | number;
	investigation_id: string | number;
	action_type: RemediationActionType;
};

@Injectable()
export class RemediationActionsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		return this.serviceUrlsService.automatedIr + REMEDIATION_ACTIONS_API_ENDPOINT;
	}

	private oatpChangeApproval(postData): Observable<any> {
		return from(
			sccHostService.auth.getToken().then(token => {
				return sccHostService.ajax.request({
					method: 'post',
					url: '<di>/Save/AirApprovalBatch',
					params: {
						tenantid: sccHostService.loginUser.tenantId,
					},
					headers: {
						authorization: `Bearer ${token}`,
					},
					data: JSON.stringify(postData),
				});
			})
		);
	}

	approveActions(actionData: Array<ActionPostData>): Observable<any> {
		if (
			sccHostService.isSCC &&
			!tenantContextCache.hasMtpConsent &&
			!tenantContextCache.appConfig.IsMdatpActive
		) {
			return this.oatpChangeApproval(this.oatpActionsToPostData(actionData, Decision.Approve));
		}
		return this.post('approve', this.mtpActionsToPostData(actionData));
	}

	dismissActions(actionData: Array<ActionPostData>, reason: string, comment: string): Observable<any> {
		if (
			sccHostService.isSCC &&
			!tenantContextCache.hasMtpConsent &&
			!tenantContextCache.appConfig.IsMdatpActive
		) {
			return this.oatpChangeApproval(this.oatpActionsToPostData(actionData, Decision.Decline, comment));
		}
		return this.post(
			'decline',
			Object.assign(
				{},
				comment && { comment },
				this.mtpActionsToPostData(actionData),
				reason && { decline_reason: reason }
			)
		);
	}

	retryPendingActions(actionIds: Array<number>): Observable<any> {
		return this.post('retry', actionIds ? { action_ids: actionIds } : {});
	}

	private mtpActionsToPostData(
		actions: Array<ActionPostData>
	): {
		action_data?: Array<{
			action_id: string | number;
			investigation_id: string | number;
			action_type_id: number;
			action_type_name: RemediationActionTypeTypes;
		}>;
	} {
		return actions
			? {
					action_data: actions.map(a => {
						const type = a.action_type;
						return {
							action_id: a.action_id,
							investigation_id: a.investigation_id,
							action_type_id: type.id,
							action_type_name: type.type,
						};
					}),
			  }
			: {};
	}

	private oatpActionsToPostData(
		actions: Array<ActionPostData>,
		decision: Decision,
		comment = 'Action Approved'
	) {
		return actions
			? {
					TenantId: sccHostService.loginUser.tenantId,
					Payload: actions.map(a => ({
						// fact type is just a hard coded value
						FactTypeId: 'cc68e1cb-4913-4fcf-9f5f-ec496dd33d1b',
						ApproverUrn: sccHostService.loginUser.upn,
						Decision: decision,
						DecisionComments: comment,
						TenantId: sccHostService.loginUser.tenantId,
						ContainerUrn: a.investigation_id,
						ParentUrn: a.action_id,
						Urn: `urn:${new Date().toISOString()}:${uuid4().replace(/-/g, '')}`,
						InstanceName: 'ActionApproval',
						InstanceValue: sccHostService.loginUser.upn,
					})),
			  }
			: {};
	}
}

const enum Decision {
	Decline = 0,
	Approve = 1,
}
