import { DataTableField, DataTableFieldConfig } from '@wcd/datatable';
import { HuntingCustomActionResult, HuntingCustomActionEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { TzDateComponent } from '../../shared/components/tz-date.component';
import { EntityNameComponent } from '../../global_entities/components/entity-name/entity-name.component';
import { EntityNamesComponent } from '../../global_entities/components/entity-name/entity-names.component';
import { Injectable } from '@angular/core';

@Injectable()
export class ScheduledHuntingActionsFields {
	private _dataTableFields: Array<DataTableField<HuntingCustomActionResult>>;
	private _dataviewFields: Array<DataviewField<HuntingCustomActionResult>>;
	private _bulkActionsFields: Array<DataTableField<HuntingCustomActionResult>>;

	constructor(private readonly i18nService: I18nService) {}

	// field list, keyed by id. Use 'Map' as it preserves the insertion order
	private _fields: Map<string, DataviewFieldConfig | DataTableFieldConfig> = [
		{
			id: 'startTime',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.startTime'),
			sort: {
				sortDescendingByDefault: true,
				getLocalSortValue: 'startTime',
			},
			component: {
				type: TzDateComponent,
				getProps: (actionRun: HuntingCustomActionResult) => ({ date: actionRun.startTime }),
			},
		},
		{
			id: 'impactedAsset',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.impactedAsset'),
			className: EntityNameComponent.entityNameDefaultCssClass,
			component: {
				type: EntityNameComponent,
				getProps: (actionRun: HuntingCustomActionResult) =>
					this.getEntityProps(actionRun.impactedAsset),
			},
		},
		{
			id: 'actionType',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.actionType'),
			getDisplay: (actionRun: HuntingCustomActionResult) =>
				this.i18nService.get(`hunting.customDetections.actions.${actionRun.actionType}`),
		},
		{
			id: 'targetEntities',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.targetEntity'),
			className: EntityNameComponent.entityNameDefaultCssClass,
			component: {
				type: EntityNamesComponent,
				getProps: (actionRun: HuntingCustomActionResult) => ({
					entities: actionRun.entities.map(entity =>
						this.getEntityProps(entity, { showPrettyName: false })
					),
				}),
			},
		},
		{
			id: 'status',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.status'),
			getDisplay: (actionRun: HuntingCustomActionResult) =>
				this.i18nService.get(actionRun.status.nameI18nKey),
			icon: {
				fabricIcon: (actionRun: HuntingCustomActionResult) => actionRun.status.iconName,
				className: (actionRun: HuntingCustomActionResult) =>
					`color-text-${actionRun.status.className}`,
			},
			getImage: (actionRun: HuntingCustomActionResult) => actionRun.status.image,
			getTooltip: (actionRun: HuntingCustomActionResult) => actionRun.failureReason,
		},
		{
			id: 'failureReason',
			name: this.i18nService.get('hunting.customDetections.actionRuns.fields.failureReason'),
			sort: { enabled: false },
		},
	].reduce((map: Map<string, any>, x) => map.set(x.id, x), new Map<string, any>());

	get dataTableFields(): Array<DataTableField<HuntingCustomActionResult>> {
		if (!this._dataTableFields) {
			this._dataTableFields = DataviewField.fromList<HuntingCustomActionResult>(
				[
					this._fields.get('actionType'),
					{
						...this._fields.get('targetEntities'),
						maxWidth: 200,
					},
					this._fields.get('status'),
				].map(field => ({ ...field, sort: { enabled: false } }))
			);
		}
		return this._dataTableFields;
	}

	get dataviewFields(): Array<DataviewField<HuntingCustomActionResult>> {
		if (!this._dataviewFields) {
			this._dataviewFields = DataviewField.fromList<HuntingCustomActionResult>(
				Array.from(this._fields.values()).filter(field => field.id != 'impactedAsset')
			);
		}

		return this._dataviewFields;
	}

	get bulkActionsFields(): Array<DataTableField<HuntingCustomActionResult>> {
		if (!this._bulkActionsFields) {
			const wideColumnWidth = 230;
			const fields: Array<DataTableFieldConfig> = [
				{
					...this._fields.get('impactedAsset'),
					maxWidth: wideColumnWidth,
				},
				this._fields.get('actionType'),
				{
					...this._fields.get('targetEntities'),
					maxWidth: 500,
				},
				{
					...this._fields.get('status'),
					minWidth: 130,
				},
				{
					...this._fields.get('failureReason'),
					minWidth: wideColumnWidth,
				},
			];

			this._bulkActionsFields = DataTableField.fromList<HuntingCustomActionResult>(
				fields.map(field => ({
					...field,
					sort: { enabled: false },
				}))
			);
		}

		return this._bulkActionsFields;
	}

	private getEntityProps(entity: HuntingCustomActionEntity, options: { showPrettyName: boolean } = null) {
		return (
			entity && {
				entity: {
					senseMachineId: entity.id,
					id: entity.id,
					fileName: entity.id,
					displayName: entity.id,
					name: entity.id,
				},
				entityName: options && options.showPrettyName === false ? null : entity.name,
				entityTypeId: entity.entityType.type,
				linkClass: 'field-value-link',
			}
		);
	}
}
