import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import { HuntingDocRecord } from '@wcd/domain';

@Component({
	selector: 'hunting-documentation-record-list',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./hunting-documentation-table-reference.panel.component.scss'],
	template: `
		<dt role="none">
			{{ header | i18n }}
		</dt>
		<dd role="none" class="wcd-padding-bottom">
			{{ description | i18n }}
		</dd>
		<dl role="none" class="wcd-padding-medium-horizontal wcd-padding-small-bottom">
			<ng-container *ngFor="let record of (records | slice: 0:shownRecords)">
				<dt class="wcd-documentation-tab_textBtn"
					(click)="recordNameClicked$.emit(record.name)"
					(keydown.enter)="recordNameClicked$.emit(record.name)"
					tabindex="0"
					[attr.aria-label]="record.name + ': ' + record.description"
					role="button"
				>
					{{ record.name }}
				</dt>
				<dd role="none" class="wcd-border-bottom-light wcd-padding-small-bottom">
					{{ record.description }}
				</dd>
			</ng-container>
		</dl>
		<button
			*ngIf="records.length > defaultNumberOfElements"
			data-track-id="record.id"
			data-track-type="Button"
			class="wcd-padding-medium-horizontal btn-link wcd-padding-mediumSmall-bottom"
			(click)="changeViewClicked()"
		>
			{{
				'hunting.documentation.table.reference.panel.view.' +
					(shownRecords == defaultNumberOfElements ? 'all.button' : 'less.button') | i18n
			}}
		</button>
	`,
})
export class HuntingDocumentationRecordListComponent {
	@Input() records: Array<HuntingDocRecord>;
	@Input() header: string;
	@Input() description: string;

	@Output() recordNameClicked$: EventEmitter<string> = new EventEmitter<string>();

	readonly defaultNumberOfElements: number = 3;
	shownRecords: number = this.defaultNumberOfElements;

	constructor() {}

	changeViewClicked() {
		this.shownRecords =
			this.shownRecords < this.records.length ? this.records.length : this.defaultNumberOfElements;
	}
}
