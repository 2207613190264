<dl [class.key-values]="asKeyValueList" role="none">
	<ng-container *ngIf="event.driveLetter">
		<dt>{{ 'usbEvent.fields.driveLetter' | i18n }}</dt>
		<dd>
			{{event.driveLetter}}
		</dd>
	</ng-container>
	<ng-container *ngIf="event.busType">
		<dt>{{ 'usbEvent.fields.busType' | i18n }}</dt>
		<dd>
			{{event.busType}}
		</dd>
	</ng-container>
	<ng-container *ngIf="event.productName">
		<dt>{{ 'usbEvent.fields.productName' | i18n }}</dt>
		<dd>
			{{event.productName}}
		</dd>
	</ng-container>
	<ng-container *ngIf="event.serialNumber">
		<dt>{{ 'usbEvent.fields.serialNumber' | i18n }}</dt>
		<dd>
			{{event.serialNumber}}
		</dd>
	</ng-container>
	<ng-container *ngIf="event.manufacturer">
		<dt>{{ 'usbEvent.fields.manufacturer' | i18n }}</dt>
		<dd>
			{{event.manufacturer}}
		</dd>
	</ng-container>
	<ng-container *ngIf="event.volume">
		<dt>{{ 'usbEvent.fields.volume' | i18n }}</dt>
		<dd>
			{{event.volume}}
		</dd>
	</ng-container>
</dl>
