import { EntityModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'Access token modification',
	pluralName: 'Access token modifications',
	readonly: true,
})
export class AccessTokenModificationDetails extends EntityModelBase<string> {
	@EntityField({ data: 'currentTokenIntegrityLevelName' })
	currentTokenIntegrityLevel: string;

	@EntityField({ data: 'originalTokenIntegrityLevelName' })
	originalTokenIntegrityLevel: string;

	@EntityField({ data: 'isChangedToSystemToken' })
	isChangedToSystemToken: boolean;

	@EntityField({ data: 'privilegesFlags' })
	privilegesFlags: Array<string>;
}
