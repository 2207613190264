import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import { TiIndicatorFilterSearchResult } from './custom-ti-indicator-filter-search-result.value.object';
import { TiIndicatorsSearchParams } from './custom-ti-indicator-filter-search.value.object';

@ApiCall({
	name: 'Custom TI indicator filters search',
	endpoint: 'ti/indicators/filters/search',
	method: 'GET',
	type: TiIndicatorFilterSearchResult,
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: (params: TiIndicatorsSearchParams): HttpOptions<FormData> => {
		return {
			params: params,
		};
	},
})
export class TiIndicatorsFilterSearchApiCall<TValue extends string | number> extends ApiCallModel<
	Array<TiIndicatorFilterSearchResult<TValue>>,
	TiIndicatorsSearchParams
> {}
