import { ComponentRef } from '@angular/core';
import { HuntingRule, ScheduledHuntingRunApiCall, ScheduledHuntingUpdateStatusApiCall } from '@wcd/domain';
import { ScheduledHuntingEntityPanelComponent, } from '../components/scheduled-hunting.entity-panel.component';
import { ScheduledHuntingFieldsService } from './scheduled-hunting.fields';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { Paris } from '@microsoft/paris';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { ScheduledHuntingRuleEditComponent } from '../components/scheduled-hunting-rule-edit.component';
import { PanelType } from '@wcd/panels';
import { Router } from '@angular/router';
import { I18nService } from '@wcd/i18n';
import { ScheduledHuntingEntityDetailsComponent } from '../components/scheduled-hunting.entity-details.component';
import { ScheduledHuntingEntityComponent } from '../components/scheduled-hunting.entity.component';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { HuntingRbacService } from './hunting-rbac-service';
import { map } from 'rxjs/operators';
import { FabricIconNames } from '@wcd/scc-common';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { HUNTING_ROUTE } from '@wcd/shared';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { HuntingQueryListService } from '@wcd/hunting';
import { getTenantIdUrlParam } from './scheduled-hunting-utils';
var ScheduledHuntingEntityTypeService = /** @class */ (function () {
    function ScheduledHuntingEntityTypeService(paris, router, i18nService, dialogsService, huntingRbacService, appContextService, huntingQueryListService, featureService) {
        var _this = this;
        this.paris = paris;
        this.router = router;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.huntingRbacService = huntingRbacService;
        this.appContextService = appContextService;
        this.huntingQueryListService = huntingQueryListService;
        this.featureService = featureService;
        this.entityType = {
            id: 'scheduled-hunting',
            entity: HuntingRule,
            icon: FabricIconNames.DateTime,
            entityDetailsComponentType: ScheduledHuntingEntityDetailsComponent,
            entityContentsComponentType: ScheduledHuntingEntityComponent,
            singleEntityPanelComponentType: ScheduledHuntingEntityPanelComponent,
            getItemParams: function (rule) {
                return { includeLastRun: true };
            },
            getEntityName: function (rule) { return rule.name; },
            entityPluralNameKey: 'scheduledHunting_entityType_pluralName',
            entitySingularNameKey: 'scheduledHunting_entityType_singularName',
            getEntityDataviewLink: function () { return _this.scheduledHuntingDataviewLink; },
            getEntitiesLink: function (rules) {
                return rules.length === 1 ? "/custom_detection/" + rules[0].id : null;
            },
            getEntitiesLinkText: function (rules) {
                return _this.i18nService.get('scheduledHunting.actions.openMonitorPage');
            },
            getNavigationModel: function (huntingRule) {
                var link = _this.entityType.getEntitiesLink([huntingRule]);
                return link ? { routerLink: [link], queryParams: getTenantIdUrlParam() } : null;
            },
            entityPageViewMode: EntityPageViewMode.Asset,
            getActions: function (rules, options, entityViewType) {
                var isSingleRule = rules.length === 1;
                var areRulesEnabled = rules.every(function (rule) { return rule.isEnabled; });
                var isUserAllowedToEdit = rules.every(function (rule) {
                    return _this.huntingRbacService.isUserAllowedToEdit(rule);
                });
                if (!isUserAllowedToEdit) {
                    return [];
                }
                return _this.huntingRbacService.isUserExposedToHuntingRulesScope(rules).pipe(map(function (isUserExposedToHuntingRulesScope) {
                    var actions = [];
                    if (areRulesEnabled && isUserExposedToHuntingRulesScope) {
                        actions = actions.concat([
                            {
                                id: 'runRule',
                                nameKey: 'scheduledHunting.actions.runRule',
                                icon: FabricIconNames.Play,
                                method: function (rules) {
                                    var runRulesCall = _this.paris.apiCall(ScheduledHuntingRunApiCall, {
                                        RuleIds: rules.map(function (rule) { return rule.id; }),
                                    });
                                    return runRulesCall.toPromise().then(function () {
                                        var rulesRepo = _this.paris.getRepository(HuntingRule);
                                        rulesRepo.clearCache();
                                        _this.dialogsService.showSuccessSnackbar({
                                            text: _this.i18nService.get('scheduledHunting_actions_runRule_Scheduled', {
                                                text: (rules.length === 1
                                                    ? rulesRepo.entity.singularName
                                                    : rules.length + ' ' + rulesRepo.entity.pluralName).toLowerCase(),
                                            }),
                                        });
                                    }, function (error) {
                                        _this.dialogsService.showError({
                                            title: _this.i18nService.get(isSingleRule
                                                ? 'hunting.scheduledMonitorSidePane.errors.failedRuns.single'
                                                : 'hunting.scheduledMonitorSidePane.errors.failedRuns'),
                                            data: error,
                                        });
                                    });
                                },
                                closeOnAction: true,
                            },
                        ]);
                    }
                    if (isSingleRule && isUserExposedToHuntingRulesScope) {
                        var isMdatpRuleUnderMtpContext = _this.appContextService.isMtp &&
                            rules[0].isMdatp &&
                            !(_this.featureService.isEnabled(Feature.PortedSccPages) &&
                                _this.featureService.isEnabled(Feature.ShowHuntingNotificationBar));
                        var mdatpRuleEditDisabledMessage = _this.i18nService.get('scheduledHunting_actions_mdatp_rule_edit_not_allowed_in_mtp');
                        actions = actions.concat([
                            {
                                id: 'editRule',
                                nameKey: 'scheduledHunting.actions.editRule',
                                icon: 'edit',
                                disabled: isMdatpRuleUnderMtpContext,
                                tooltip: isMdatpRuleUnderMtpContext ? mdatpRuleEditDisabledMessage : null,
                                method: function (rules) {
                                    var rulesRepo = _this.paris.getRepository(HuntingRule);
                                    rulesRepo.clearCache();
                                    var panelSettings = {
                                        id: 'scheduled-hunting-edit-panel',
                                        type: PanelType.wizard,
                                        noBodyPadding: true,
                                        disableOverlayClick: true,
                                    };
                                    //TODO explicit construction
                                    return new Promise(function (resolve, reject) {
                                        _this.dialogsService
                                            .showPanel(ScheduledHuntingRuleEditComponent, panelSettings, {
                                            huntingRule: rules[0],
                                            queryText: (options && options.editedQueryText) || null,
                                        })
                                            .subscribe(function (panel) {
                                            var ruleSavedSub = panel.instance.itemSaved.subscribe(function (rule) {
                                                options.huntingRuleSaved &&
                                                    options.huntingRuleSaved(rule);
                                                _this.huntingQueryListService.refreshQueriesLists();
                                            });
                                            panel.onDestroy(function () {
                                                ruleSavedSub && ruleSavedSub.unsubscribe();
                                                resolve();
                                            });
                                        });
                                    });
                                },
                                closeOnAction: true,
                            },
                            {
                                id: 'editQuery',
                                nameKey: 'scheduledHunting.actions.editQuery',
                                icon: 'hunting',
                                disabled: isMdatpRuleUnderMtpContext,
                                tooltip: isMdatpRuleUnderMtpContext ? mdatpRuleEditDisabledMessage : null,
                                method: function (_a) {
                                    var rule = _a[0];
                                    return _this.router.navigate(["/" + HUNTING_ROUTE], {
                                        queryParams: { queryId: rule.queryId },
                                    });
                                },
                                refreshOnResolve: false,
                                closeOnAction: true,
                            },
                        ]);
                    }
                    var isFirstRuleEnabled = rules[0].isEnabled;
                    if (isUserExposedToHuntingRulesScope &&
                        (isSingleRule || rules.every(function (rule) { return rule.isEnabled === isFirstRuleEnabled; }))) {
                        actions = actions.concat([
                            {
                                id: 'changeStatus',
                                name: _this.i18nService.get('scheduledHunting.actions.changeStatus', {
                                    status: isFirstRuleEnabled
                                        ? _this.i18nService.get('scheduledHunting_actions_changeStatus_result_off')
                                        : _this.i18nService.get('scheduledHunting_actions_changeStatus_result_on'),
                                }),
                                icon: isFirstRuleEnabled ? 'clear' : 'check',
                                method: function (rules) {
                                    var updateStatusApi = _this.paris.apiCall(ScheduledHuntingUpdateStatusApiCall, {
                                        RuleIds: rules.map(function (rule) { return rule.id; }),
                                        IsEnabled: !isFirstRuleEnabled,
                                    });
                                    return updateStatusApi.toPromise().then(function () {
                                        var rulesRepo = _this.paris.getRepository(HuntingRule);
                                        rulesRepo.clearCache();
                                        _this.dialogsService.showSuccessSnackbar({
                                            text: _this.i18nService.get('scheduledHunting_actions_changeStatus_result', {
                                                newStatus: isFirstRuleEnabled
                                                    ? _this.i18nService.get('scheduledHunting_actions_changeStatus_result_off')
                                                    : _this.i18nService.get('scheduledHunting_actions_changeStatus_result_on'),
                                                entity: rules.length === 1
                                                    ? rulesRepo.entity.singularName
                                                    : rules.length +
                                                        ' ' +
                                                        rulesRepo.entity.pluralName,
                                            }),
                                            icon: isFirstRuleEnabled ? 'clear' : 'check',
                                        });
                                    }, function (error) {
                                        _this.dialogsService.showError({
                                            title: _this.i18nService.get('scheduledHunting_actions_changeStatus_result_error', {
                                                status: isFirstRuleEnabled
                                                    ? _this.i18nService.get('scheduledHunting_actions_changeStatus_result_off')
                                                    : _this.i18nService.get('scheduledHunting_actions_changeStatus_result_on'),
                                            }),
                                            data: error,
                                        });
                                    });
                                },
                                closeOnAction: true,
                            },
                        ]);
                    }
                    if (isUserExposedToHuntingRulesScope) {
                        actions = actions.concat([
                            {
                                id: 'delete',
                                nameKey: 'delete',
                                icon: 'delete',
                                method: function (rules) {
                                    return new Promise(function (resolve, reject) {
                                        var rulesRepo = _this.paris.getRepository(HuntingRule);
                                        _this.dialogsService
                                            .confirm({
                                            title: rules.length === 1
                                                ? _this.i18nService.get('deleteItem', {
                                                    itemName: rulesRepo.entity.singularName.toLowerCase(),
                                                })
                                                : _this.i18nService.get('deleteSelectedItems', {
                                                    itemsName: rulesRepo.entity.pluralName.toLowerCase(),
                                                }),
                                            text: rules.length === 1
                                                ? _this.i18nService.get('deleteConfirmSingular', {
                                                    itemSingularName: rulesRepo.entity.singularName.toLowerCase(),
                                                })
                                                : _this.i18nService.get('deleteConfirm', {
                                                    itemPluralName: rulesRepo.entity.pluralName.toLowerCase(),
                                                }),
                                            confirmText: _this.i18nService.get('delete'),
                                        })
                                            .then(function (e) {
                                            var ids = rules.map(function (rule) { return rule.id; });
                                            if (e.confirmed)
                                                resolve(rulesRepo
                                                    .remove(rules, { data: { ids: ids, RuleIds: ids } })
                                                    .toPromise()
                                                    .then(function (items) {
                                                    _this.dialogsService.showSnackbar({
                                                        text: "Deleted " + (items.length === 1
                                                            ? rulesRepo.entity.singularName
                                                            : items.length +
                                                                ' ' +
                                                                rulesRepo.entity.pluralName).toLowerCase(),
                                                        icon: 'delete',
                                                        iconClassName: 'color-text-error',
                                                    });
                                                    if (entityViewType ===
                                                        EntityViewType.EntityPage)
                                                        _this.router.navigate([
                                                            _this.scheduledHuntingDataviewLink,
                                                        ]);
                                                    _this.huntingQueryListService.refreshQueriesLists();
                                                }, function (error) {
                                                    _this.dialogsService.showError({
                                                        title: _this.i18nService.strings
                                                            .scheduled_hunting_error_delete,
                                                        data: error,
                                                    });
                                                }));
                                            else
                                                reject(true);
                                        });
                                    });
                                },
                                closeOnAction: true,
                            },
                        ]);
                    }
                    return actions.map(function (itemActionConfig) { return new ItemActionModel(itemActionConfig); });
                }));
            },
            dataViewOptions: {
                fields: ScheduledHuntingFieldsService,
                defaultSortFieldId: 'LastUpdatedTime',
                dataViewConfig: {
                    allowFilters: false,
                },
            },
        };
    }
    Object.defineProperty(ScheduledHuntingEntityTypeService.prototype, "scheduledHuntingDataviewLink", {
        get: function () {
            // In MDATP, the scheduled hunting rules dataview is under settings page
            return this.appContextService.isSCC ? '/custom_detection' : '/preferences2/scheduled_hunting';
        },
        enumerable: true,
        configurable: true
    });
    return ScheduledHuntingEntityTypeService;
}());
export { ScheduledHuntingEntityTypeService };
