import { EntityRelationship, EntityRelationshipRepositoryType } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';
import { Alert } from '../alert.entity';
import { AlertIncidentGraph } from './incident-graph';

@EntityRelationship({
	sourceEntity: Alert,
	dataEntity: AlertIncidentGraph,
	getRelationshipData: (alert: Alert) => ({
		id: alert.id,
	}),
	fixedData: {
		lookbackInDays: 30,
	},
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	endpoint: 'ScopeOfBreach',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class AlertIncidentGraphRelationship
	implements EntityRelationshipRepositoryType<Alert, AlertIncidentGraph> {}
