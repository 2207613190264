import { Injectable } from '@angular/core';
import {
	Alert, IncidentLinkedByEntityType,
	LinkedEntityAlert,
} from '@wcd/domain';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { AlertNameFieldComponent } from '../components/alert-name-field.component';
import { ImpactedEntitiesCounterComponent } from '../../../impacted-entities/components/impacted-entities-counter/impacted-entities-counter.component';

type PartialDataviewFiled = Partial<DataviewField>;

interface FieldsData {
	title: PartialDataviewFiled;
	lasteventtime: PartialDataviewFiled;
	commandLine: PartialDataviewFiled;
	impactedEntities: PartialDataviewFiled;
}

@Injectable()
export class AlertLinkedFields {
	allFields: FieldsData = {
		title: {
			id: 'title',
			name: this.i18nService.get('alerts.fields.title.title'),
			component: {
				type: AlertNameFieldComponent,
				getProps: (item: LinkedEntityAlert) => ({ alert: item.alert }),
			},
			getFieldCssClass: () => `datatable-group-item-indent-left title-field`,
		},
		lasteventtime: {
			id: 'lasteventtime',
			name: this.i18nService.get('alerts.fields.lastActivity.title'),
			className: 'nowrap',
			sort: {
				sortDescendingByDefault: true,
				getLocalSortValue: 'lasteventtime',
			},
			component: {
				type: TzDateComponent,
				getProps: (item: LinkedEntityAlert) => ({ date: item.alert.lastEventTime }),
			},
		},
		commandLine: {
			id: 'commandLine',
			name: this.i18nService.get('alerts_fields_command_line_title'),
			getDisplay: (item: LinkedEntityAlert) => item.entity ? item.entity.id : '',
			sort: { enabled: false },
		},
		impactedEntities: {
			id: 'impactedEntities',
			name: this.i18nService.get('alerts.fields.impactedEntities.title'),
			className: 'wcd-text-overflow-medium',
			component: {
				type: ImpactedEntitiesCounterComponent,
				getProps: (item: LinkedEntityAlert) => {
					let machine = 0;
					let aaduser = 0;
					let mailbox = 0;
					if (item.alert.impactedEntities) {
						machine = item.alert.impactedEntities.machineCount || (item.alert.impactedEntities.machines &&
							item.alert.impactedEntities.machines.length) || 0;
						aaduser = item.alert.impactedEntities.userCount || (item.alert.impactedEntities.users &&
							item.alert.impactedEntities.users.length) || 0;
						mailbox = item.alert.impactedEntities.mailboxCount || (item.alert.impactedEntities.mailboxes &&
							item.alert.impactedEntities.mailboxes.length) || 0;
					}

					return {
						entities: item.alert.impactedEntities,
						entitiesCount: { machine, aaduser, mailbox }
					}
				},
			},
			sort: { enabled: false },
			enabledByDefault: true,
		},
	};

	constructor(
		private i18nService: I18nService,
	) {
	}

	public getAlertLinkedFields(sourceEntityType: IncidentLinkedByEntityType): Array<DataviewField<Alert>> {
		const fieldsData = this.allFields;
		const fields = sourceEntityType === 'CommandLine' ?
			[fieldsData.title, fieldsData.commandLine, fieldsData.lasteventtime]:
			[fieldsData.title, fieldsData.impactedEntities, fieldsData.lasteventtime]

		return DataviewField.fromList<Alert>(<Array<DataviewFieldConfig>>fields);
	}

}
