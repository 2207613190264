import { Injectable } from '@angular/core';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { InvestigationAction, InvestigationActionCategory, InvestigationActionStatus } from '@wcd/domain';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { InvestigationActionEntityPanelComponent } from '../components/investigation-action.entity-panel.component';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { I18nService } from '@wcd/i18n';
import { InvestigationActionsService } from './investigation-actions.service';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { concat, filter, map, multicast, scan, switchMap, take, takeWhile } from 'rxjs/operators';
import { from, Observable, of, ReplaySubject } from 'rxjs';
import { PollingService } from '@wcd/config';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { AuthService } from '@wcd/auth';
import { omit } from 'lodash-es';

@Injectable()
export class InvestigationActionEntityTypeService implements EntityTypeService<InvestigationAction> {
	constructor(
		private i18nService: I18nService,
		private investigationActionsService: InvestigationActionsService,
		private pollingService: PollingService,
		private authService: AuthService
	) {}

	readonly entityType: EntityType<InvestigationAction> = {
		id: 'investigation-action',
		entity: InvestigationAction,
		getEntityPolling: entityGetter$ => {
			return this.pollingService.poll(3000, 3000).pipe(
				switchMap(() => entityGetter$),
				scan(
					(
						obj: { previous?: InvestigationAction; current: InvestigationAction },
						action: InvestigationAction
					) => {
						obj.previous = obj.current;
						obj.current = action;
						return obj;
					},
					{} as any
				),
				filter((obj: { previous?: InvestigationAction; current: InvestigationAction }) => {
					return !obj.previous || obj.previous.status !== obj.current.status;
				}),
				map(obj => obj.current),
				multicast(
					() => new ReplaySubject<InvestigationAction>(1),
					(action$: Observable<InvestigationAction>) =>
						action$.pipe(
							takeWhile(_action => _action.isRunning),
							concat(action$.pipe(take(1)))
						)
				)
			);
		},
		getIcon: (investigationActions: Array<InvestigationAction>) => {
			const commonCategory: InvestigationActionCategory = GlobalEntityTypesService.getCommonValue(
				investigationActions,
				action => action.category
			);
			return (commonCategory && commonCategory.iconName) || 'actions';
		},
		getIconCssClass: (investigationActions: Array<InvestigationAction>) => {
			const commonStatus: InvestigationActionStatus = GlobalEntityTypesService.getCommonValue(
				investigationActions,
				action => action.status
			);
			if (commonStatus) return `round-icon color-box-${commonStatus.className}`;
		},
		singleEntityPanelComponentType: InvestigationActionEntityPanelComponent,
		getEntityName: (action: InvestigationAction) => {
			return (
				(action.i18nNameKey &&
					this.i18nService.get(
						`investigationAction_field_name_${action.i18nNameKey}`,
						null,
						true
					)) ||
				action.name
			);
		},
		getSubtitle: (investigationActions: Array<InvestigationAction>) =>
			investigationActions.length === 1 ? investigationActions[0].extraData.generalDescription : null,
		getActions: (investigationActions: Array<InvestigationAction>) => {
			const actions: Array<ItemActionModelConfig> = [];

			if (
				investigationActions.every(
					action => action.status.isRetriable && action.pendingType.isUserPending
				)
			) {
				actions.push({
					id: 'retryInvestigationAction',
					nameKey: 'investigationActions_actions_retry_name',
					tooltip: this.i18nService.strings.investigationActions_actions_retry_description,
					icon: 'refresh',
					refreshOnResolve: true,
					rbac: [RbacMdeAllowedActions.remediationActions],
					rbacState: RbacControlState.hidden,
					method: (investigationActions: Array<InvestigationAction>) => {
						return this.investigationActionsService.retryActions(investigationActions);
					},
				});
			}

			if (investigationActions.every(action => action.status.isPending && !action.status.isRetriable)) {
				const isSecAdmin = this.authService.currentUser.isSecAdmin;
				const isAllowed = isSecAdmin || investigationActions.every(a => !a.isOfficeAction);

				const ignoreRbac = sccHostService.isSCC && investigationActions.every(a => a.isOfficeAction);
				let isAllowed$: Observable<boolean>;

				if (!isAllowed && sccHostService.isSCC) {
					isAllowed$ = from(<Promise<boolean>>(
						sccHostService.auth.isInRole(SccRoles.searchAndPurge)
					));
				} else {
					isAllowed$ = of(isAllowed);
				}
				return isAllowed$.pipe(
					map(hasPermission =>
						[
							...actions,
							{
								id: 'approveInvestigationAction',
								nameKey: 'remediationActions_approve',
								tooltip: hasPermission
									? this.i18nService.strings.remediationActions_tooltips_approve
									: this.i18nService.strings.common_permissions_noPermissionTooltip,
								icon: 'checkCircle',
								refreshOnResolve: true,
								rbac: [RbacMdeAllowedActions.remediationActions],
								rbacState: RbacControlState.hidden,
								allowRbacTooltipOverride: true,
								method: (investigationActions: Array<InvestigationAction>) => {
									return this.investigationActionsService.approveActions(
										investigationActions
									);
								},
								disabled: !hasPermission,
							},
							{
								id: 'rejectInvestigationAction',
								nameKey: 'remediationActions_dismiss',
								tooltip: hasPermission
									? this.i18nService.strings.remediationActions_tooltips_decline
									: this.i18nService.strings.common_permissions_noPermissionTooltip,
								icon: 'block',
								refreshOnResolve: true,
								rbac: [RbacMdeAllowedActions.remediationActions],
								rbacState: RbacControlState.hidden,
								allowRbacTooltipOverride: true,
								method: (investigationActions: Array<InvestigationAction>) => {
									return this.investigationActionsService.declineActions(
										investigationActions
									);
								},
								disabled: !hasPermission,
							},
						].map(
							itemActionConfig =>
								new ItemActionModel(
									ignoreRbac
										? omit(itemActionConfig, 'rbac', 'allowRbacTooltipOverride')
										: itemActionConfig
								)
						)
					)
				);
			}

			return of(actions.map(itemActionConfig => new ItemActionModel(itemActionConfig)));
		},
		getItemParams: (action: InvestigationAction) => {
			return action.isOfficeAction
				? {
						useOfficeApi: true,
						tenantId: sccHostService.loginUser.tenantId,
				  }
				: null;
		},
	};
}
