export enum TrackingEventType {
	Action = 'Action',
	Button = 'Button',
	Close = 'Close',
	Export = 'Export',
	ExternalLink = 'ExternalLink',
	Filter = 'Filter',
	Navigation = 'Navigation',
	Save = 'Save',
	Search = 'Search',
	Selection = 'Selection',
	Toggle = 'Toggle',
}

export interface TelemetryEvent<TPayload = never> {
	package?: string;
	component?: string;
	componentType?: string;
	id: string;
	type: TrackingEventType | string;
	payload?: TPayload;
	measurements?: { time?: number };
}
