import { Component } from '@angular/core';
import { BaselineConfiguration } from '@wcd/domain';
import { TvmColorService } from '../../../../../tvm/services/tvm-color.service';
import { EntityStatusComponentBase } from '../../../../../global_entities/components/entity-status/entity-status.component.base';
import { I18nService } from '@wcd/i18n';

@Component({
	template: `
		<div *ngIf=isSingleAsset(configuration) class="wcd-flex-horizontal-align-items-center">
			<wcd-shared-icon iconName="StatusCircleInner" [class]="getIconClass(configuration)">
			</wcd-shared-icon>
			<span>
				{{ getComplianceMessage(configuration) }}
			</span>
		</div>
	`,
})
export class BaselineComplianceStatusComponent extends EntityStatusComponentBase<BaselineConfiguration> {
	get configuration() {
		return this.entity;
	}

	constructor(
		private tvmColorService: TvmColorService,
		private i18nService: I18nService,
	) {
		super();
	}

	isSingleAsset(configuration: BaselineConfiguration): boolean {
		return configuration.applicableDevices === 1;
	}

	getIconClass(configuration: BaselineConfiguration): string {
		return this.tvmColorService.getCompliantIcon(configuration.compliantDevices === 1) + " wcd-margin-xsmall-right";
	}

	getComplianceMessage(configuration: BaselineConfiguration): string {
		return configuration.compliantDevices === 1 ?
			this.i18nService.strings.tvm_baseline_compliant_enum_compliant :
			this.i18nService.strings.tvm_baseline_compliant_enum_notCompliant
	}
}
