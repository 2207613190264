import { Machine, OfficeUtils } from '@wcd/domain';
import { DataviewField } from '@wcd/dataview';
import { Prettify } from '../../../utils/services/prettify.service';
import { InvestigationsService } from './investigations.service';
import { AppConfigService } from '@wcd/app-config';
import { AppContextService } from '@wcd/config';
import { FilterValuesChecklistComponent, FilterValuesDatePickerComponent, } from '@wcd/ng-filters';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { InvestigationEntityTypeService } from './investigation.entity-type.service';
import { compact } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
var SevilleInvestigationFields = /** @class */ (function () {
    function SevilleInvestigationFields(appConfigService, globalEntityTypesService, iconsService, investigationEntityTypeService, appContextService, i18nService) {
        this.appConfigService = appConfigService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.iconsService = iconsService;
        this.investigationEntityTypeService = investigationEntityTypeService;
        this.appContextService = appContextService;
        this.i18nService = i18nService;
    }
    Object.defineProperty(SevilleInvestigationFields.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList(compact([
                    {
                        id: 'originatingAlertTitles',
                        name: this.i18nService.strings.investigations_fields_triggeringAlert,
                        enabledByDefault: true,
                        sort: { enabled: false },
                        className: EntityNameComponent.entityNameDefaultCssClass,
                        component: {
                            type: EntityNameComponent,
                            getProps: function (investigationInput) {
                                return {
                                    entity: investigationInput,
                                    entityTypeId: _this.investigationEntityTypeService.entityType.id,
                                };
                            },
                        },
                    },
                    {
                        id: 'Id',
                        name: this.i18nService.strings.investigations_fields_investigationId,
                        className: 'small-cell',
                        enabledByDefault: !this.appConfigService.isDemoTenant,
                        getDisplay: function (investigation) {
                            return OfficeUtils.getShortId(investigation.id);
                        },
                    },
                    {
                        id: 'status',
                        name: this.i18nService.strings.investigations_fields_investigationStatus,
                        enabledByDefault: true,
                        className: 'auto-size nowrap',
                        getDisplay: function (investigation) {
                            return investigation.status.name;
                        },
                        icon: {
                            wcdIcon: function (investigation) {
                                return investigation.status.iconName &&
                                    WcdIconNames[_this.iconsService.getIcon(investigation.status.iconName).name];
                            },
                            className: function (investigation) {
                                return "color-text-" + investigation.status.className;
                            },
                        },
                        getImage: function (investigation) {
                            return investigation.status && investigation.status.image;
                        },
                        sort: { enabled: false },
                        filter: {
                            priority: 0,
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (statusId) {
                                        var status = InvestigationsService.getInvestigationStatusBySevilleStatus(statusId);
                                        if (status) {
                                            var statusIcon = _this.iconsService.getIcon(status.iconName);
                                            return {
                                                icon: statusIcon
                                                    ? {
                                                        wcdIconName: statusIcon.name,
                                                        className: "color-text-" + status.className,
                                                    }
                                                    : null,
                                                image: status.image,
                                                name: status.name,
                                                id: statusId,
                                            };
                                        }
                                        return null;
                                    },
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    this.appContextService.isMtp
                        ? {
                            id: 'serviceSource',
                            name: this.i18nService.strings.investigations_fields_serviceSource,
                            enabledByDefault: true,
                            className: 'nowrap',
                            getDisplay: function (investigation) {
                                return investigation.serviceSource
                                    ? _this.i18nService.get(investigation.serviceSource.nameI18nKey)
                                    : null;
                            },
                            custom: {
                                allowFilterValueTracking: true,
                            },
                            sort: {
                                enabled: false,
                            },
                        }
                        : null,
                    {
                        id: 'detectionSource',
                        name: this.i18nService.strings.investigations_fields_detectionSource,
                        enabledByDefault: !this.appContextService.isMtp,
                        className: 'nowrap',
                        getDisplay: function (investigation) { return investigation.detectionSourceName; },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                        sort: {
                            enabled: false,
                        },
                    },
                    {
                        id: 'machineNames',
                        name: this.i18nService.strings.investigations_fields_entities,
                        enabledByDefault: true,
                        className: 'nowrap wcd-text-overflow-medium',
                        getDisplay: function (investigation) {
                            return investigation.machines.length
                                ? investigation.machines.length === 1
                                    ? investigation.machines[0].name.toLowerCase()
                                    : _this.i18nService.get('investigations_fields_deviceCount', {
                                        deviceCount: investigation.machines.length,
                                    })
                                : null;
                        },
                        icon: {
                            fabricIcon: function (investigation) {
                                return investigation.machines.length
                                    ? _this.globalEntityTypesService.getEntityTypeIcon(Machine)
                                    : null;
                            },
                        },
                        getLink: function (investigation) {
                            if (investigation.machines.length === 1) {
                                var id = investigation.machines[0].machineId;
                                _this.globalEntityTypesService.getEntityLink(Machine, {
                                    name: id,
                                    senseMachineId: id,
                                });
                            }
                            return null;
                        },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (machineName) {
                                        return {
                                            id: machineName,
                                            name: machineName,
                                            type: 'host',
                                        };
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'StartTime',
                        name: this.i18nService.strings.investigations_fields_startDate,
                        enabledByDefault: true,
                        component: {
                            type: TzDateComponent,
                            getProps: function (investigation) { return ({ date: investigation.startDate }); },
                        },
                        className: 'nowrap',
                        sort: {
                            sortDescendingByDefault: true,
                        },
                        filter: {
                            component: {
                                type: FilterValuesDatePickerComponent,
                            },
                        },
                    },
                    {
                        id: 'RunningTime',
                        name: this.i18nService.strings.investigations_fields_duration,
                        enabledByDefault: true,
                        getDisplay: function (investigation) {
                            return Prettify.prettyTime(investigation.duration);
                        },
                        className: 'auto-size',
                    },
                    {
                        id: 'error',
                        name: this.i18nService.strings.investigations_fields_errorDescription,
                        enabledByDefault: false,
                        className: 'color-text-error',
                        getDisplay: function (investigation) { return investigation.errorDescription; },
                        sort: { enabled: false },
                    },
                    {
                        id: 'reasons',
                        name: this.i18nService.strings.investigations_fields_statusDetails,
                        enabledByDefault: false,
                        className: 'full-width-cell',
                        listValues: {
                            path: 'reasons',
                            withBullets: true,
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'canceledBy',
                        name: this.i18nService.strings.investigations_fields_canceledBy,
                        enabledByDefault: false,
                        getDisplay: function (investigation) {
                            return investigation.canceledByUser ? investigation.canceledByUser.name : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'lastAction',
                        name: this.i18nService.strings.investigations_fields_lastAction,
                        enabledByDefault: false,
                        className: 'full-width-cell',
                        getDisplay: function (investigation) {
                            return investigation.lastAction ? investigation.lastAction.details : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'RemediationActionCount',
                        name: this.i18nService.strings.investigations_fields_remediationActions,
                        enabledByDefault: false,
                        getDisplay: function (investigation) {
                            return investigation.actionsSummary.remediated || '';
                        },
                        className: 'no-wrap auto-size',
                        getTooltip: function (investigation) {
                            return _this.i18nService.get('investigations_fields_remediationActionsTooltip', {
                                remediationActionCount: investigation.actionsSummary.remediated,
                                totalActionCount: investigation.actionsSummary.total,
                            });
                        },
                        getCssClass: function (investigation) {
                            return investigation.actionsSummary.remediated ? 'bold color-text-success' : null;
                        },
                        sort: {
                            sortDescendingByDefault: true,
                        },
                    },
                ]));
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    return SevilleInvestigationFields;
}());
export { SevilleInvestigationFields };
