var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ComponentRef } from '@angular/core';
import { HuntingQueryResultRecord } from '@wcd/domain';
import { HuntingQueryResultRecordEntityPanelComponent, } from '../components/hunting-query-result-record.entity-panel.component';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { HuntingBulkActionsComponent } from '../../../hunting-custom-actions/components/hunting-bulk-actions.component';
import { FeaturesService, Feature } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { FabricIconNames } from '@wcd/scc-common';
import { AuthService, MtpPermission } from '@wcd/auth';
var HuntingQueryResultRecordEntityTypeService = /** @class */ (function () {
    function HuntingQueryResultRecordEntityTypeService(i18nService, dialogsService, featuresService, authService) {
        var _this = this;
        this.i18nService = i18nService;
        this.dialogsService = dialogsService;
        this.featuresService = featuresService;
        this.authService = authService;
        this.entityType = {
            id: 'huntingQueryResultRecord',
            entity: HuntingQueryResultRecord,
            singleEntityPanelComponentType: HuntingQueryResultRecordEntityPanelComponent,
            loadFullEntityInPanel: false,
            getEntityName: function (huntingQueryResultRecord) {
                return _this.i18nService.get('hunting.results.record.panel.title');
            },
            getActions: function (records, options) {
                var areBulkActionsAllowed = _this.authService.currentUser.hasRequiredMtpPermission(MtpPermission.SecurityData_Remediate);
                return [
                    _this.featuresService.isEnabled(Feature.HuntingBulkActions) ||
                        _this.featuresService.isEnabled(Feature.HuntingBulkActionsMtp)
                        ? new ItemActionModel({
                            id: 'bulkActions',
                            nameKey: 'hunting.bulkActions.title',
                            icon: FabricIconNames.F12DevTools,
                            disabled: !areBulkActionsAllowed,
                            tooltip: areBulkActionsAllowed
                                ? null
                                : _this.i18nService.get('hunting.bulkActions.noPermissions'),
                            refreshOnResolve: false,
                            method: function (records) { return __awaiter(_this, void 0, void 0, function () {
                                var panelSettings;
                                var _this = this;
                                return __generator(this, function (_a) {
                                    panelSettings = {
                                        id: 'hunting-bulk-actions-panel',
                                        type: PanelType.wizard,
                                        noBodyPadding: true,
                                        disableOverlayClick: true,
                                        className: 'panel-wizard',
                                    };
                                    return [2 /*return*/, new Promise(function (resolve, reject) {
                                            var subscription = _this.dialogsService
                                                .showPanel(HuntingBulkActionsComponent, panelSettings, {
                                                records: records,
                                                columnsInfo: options.columnsInfo,
                                            })
                                                .subscribe(function (panel) {
                                                panel.onDestroy(function () {
                                                    resolve();
                                                    subscription.unsubscribe();
                                                });
                                            });
                                        })];
                                });
                            }); },
                        })
                        : null,
                ].filter(Boolean);
            },
        };
    }
    return HuntingQueryResultRecordEntityTypeService;
}());
export { HuntingQueryResultRecordEntityTypeService };
