import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, HostBinding } from '@angular/core';
import { SpinnerSize } from 'office-ui-fabric-react';

export interface ConfirmModalButtonOptions {
	readonly text?: string;
	readonly disabled?: boolean;
}

@Component({
	selector: 'confirm-modal-content',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			:host {
				display: flex;
			}
		`,
	],
	template: `
		<div
			class="wcd-flex-1 wcd-flex-vertical ie11Patch ie11Flex"
			data-track-component="ConfirmModalContent"
		>
			<main class="dialog-contents wcd-flex-1 ie11Patch ie11Flex wcd-scroll-vertical">
				<ng-content></ng-content>
			</main>

			<footer class="dialog-footer wcd-flex-none wcd-flex-justify-end-horizontal">
				<fab-primary-button
					className="wcd-margin-small-right"
					data-track-id="Confirm"
					data-track-type="Button"
					[disabled]="confirmButtonOptions?.disabled"
					(onClick)="onConfirm.emit()"
				>
					<fab-spinner
						*ngIf="isSaving"
						[size]="SpinnerSize.xSmall"
						className="wcd-margin-xsmall-right"
					></fab-spinner>
					<span>{{ confirmButtonOptions?.text || ('buttons.confirm' | i18n) }}</span>
				</fab-primary-button>

				<fab-default-button
					data-track-id="Cancel"
					data-track-type="Button"
					[disabled]="cancelButtonOptions?.disabled"
					[text]="cancelButtonOptions?.text || ('buttons.cancel' | i18n)"
					(onClick)="onCancel.emit()"
				>
				</fab-default-button>
			</footer>
		</div>
	`,
})
// TODO: Currently the confirm modal doesn't use this component, we should make it use it  and just pass some templates based on the options passed to it
export class ConfirmModalContentComponent {
	readonly SpinnerSize = SpinnerSize;

	@Input() confirmButtonOptions?: ConfirmModalButtonOptions;
	@Input() cancelButtonOptions?: ConfirmModalButtonOptions;
	@Input() isSaving?: boolean;

	@Output() readonly onConfirm = new EventEmitter<void>();
	@Output() readonly onCancel = new EventEmitter<void>();
}
