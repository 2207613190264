import { SevilleModule } from '../../seville/seville.module';

SevilleModule.filter('healthStatusText', healthStatusTextFilter);
healthStatusTextFilter.$inject = ['$log', '$filter'];

function healthStatusTextFilter($log, $filter) {
	return function(input, textType) {
		if (!input || !input.HealthStatus) {
			return '';
		}
		var summary = '';
		var listSummary = '';
		var description = '';

		var healthStatusCategory = $filter('healthStatusCategory')(input.HealthStatus);
		if (input.HealthStatus === 'MisconfiguredCyberNoCnc') {
			summary = 'Impaired communications';
			listSummary = summary;
			description =
				'Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.';
		} else if (input.HealthStatus === 'MisconfiguredCncNoCyber') {
			summary = 'No sensor data';
			listSummary = summary;
			description =
				'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available.';
		} else if (healthStatusCategory === 'Misconfigured') {
			summary = 'No sensor data, impaired communications';
			listSummary = '<div>No sensor data</div><div>Impaired communications</div>';
			description =
				'Device has stopped sending sensor data. Windows Defender AV alerts and timeline events may still be available. Ability to communicate with device is impaired. Unable to obtain files for deep analysis, block files and isolate device.';
		} else if (healthStatusCategory === 'Inactive') {
			summary = 'Inactive';
			listSummary = summary;
			description = 'Device has completely stopped sending signals for more than 7 days.';
		} else if (healthStatusCategory === 'Active') {
			summary = 'Active';
			listSummary = summary;
			description = 'Device has completely stopped sending signals for more than 7 days.';
		}

		if (textType === 'summary') {
			return summary;
		}

		if (textType === 'listSummary') {
			return listSummary;
		}

		return description;
	};
}
