import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { keyBy } from 'lodash-es';

export enum WebContentFilteringParentCategoryTypes {
	LegalLiability = 'Legal liability',
	AdultContent = 'Adult content',
	HighBandwidth = 'High bandwidth',
	Leisure = 'Leisure',
	Uncategorized = 'Uncategorized',
}

export enum WebContentFilteringCategoryTypes {
	Unknown = 0,
	AdvertisementsAndPopUps = 1,
	AlcoholAndTobacco = 2,
	Anonymizers = 3,
	Arts = 4,
	Business = 5,
	Transportation = 6,
	Chat = 7,
	ForumsAndNewsgroups = 9,
	Compromised = 10,
	ComputersAndTechnology = 11,
	CriminalActivity = 12,
	DatingAndPersonals = 13,
	DownloadSites = 14,
	Education = 15,
	Entertainment = 16,
	Finance = 17,
	Gambling = 18,
	Games = 19,
	Government = 20,
	HateAndIntolerance = 21,
	HealthAndMedicine = 22,
	IllegalDrug = 23,
	JobSearch = 24,
	StreamingMediaAndDownloads = 26,
	News = 27,
	NonprofitsAndNGOs = 28,
	Nudity = 29,
	PersonalSites = 30,
	PhishingAndFraud = 31,
	Politics = 32,
	PornographyOrSexuallyExplicit = 33,
	RealEstate = 34,
	Religion = 35,
	RestaurantsAndDining = 36,
	SearchEnginesAndPortals = 37,
	Shopping = 38,
	SocialNetworking = 39,
	SpamSites = 40,
	Sports = 41,
	Malware = 42,
	Translators = 44,
	Travel = 45,
	Violence = 46,
	Weapons = 47,
	WebbasedEmail = 48,
	General = 49,
	LeisureAndRecreation = 50,
	Botnets = 61,
	Cults = 62,
	FashionAndBeauty = 63,
	Greetingcards = 64,
	Hacking = 65,
	IllegalSoftware = 67,
	ImageSharing = 68,
	InformationSecurity = 69,
	InstantMessaging = 70,
	NetworkErrors = 71,
	ParkedDomains = 51,
	PeerToPeer = 73,
	PrivateIPAddresses = 74,
	SchoolCheating = 75,
	SexEducation = 76,
	Tasteless = 77,
	ChildAbuseImages = 78,
	NatureAndConservation = 79,
	FileRepository = 80,
	GayOrLesbianOrBisexual = 81,
	SuspectedMalware = 82,
	SuspectedPhishing = 83,
	SelfHarm = 84,
	LingerieAndSwimsuits = 85,
	Marijuana = 91,
	ProfessionalNetworking = 92,
	ChildInappropriate = 93,
	NewlyRegisteredDomains = 52,
}

const webCategories: WebContentFilteringCategoryType[] = [
	{
		id: WebContentFilteringCategoryTypes.ChildAbuseImages,
		name: 'childAbuseImages',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.CriminalActivity,
		name: 'criminalActivity',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Hacking,
		name: 'hacking',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.HateAndIntolerance,
		name: 'hateAndIntolerance',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.IllegalDrug,
		name: 'illegalDrug',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.IllegalSoftware,
		name: 'illegalSoftware',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.SchoolCheating,
		name: 'schoolCheating',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.SelfHarm,
		name: 'selfHarm',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Weapons,
		name: 'weapons',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Cults,
		name: 'cults',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Gambling,
		name: 'gambling',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Nudity,
		name: 'nudity',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.PornographyOrSexuallyExplicit,
		name: 'pornographyOrSexuallyExplicit',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.SexEducation,
		name: 'sexEducation',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Tasteless,
		name: 'tasteless',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Violence,
		name: 'violence',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.DownloadSites,
		name: 'downloadSites',
		type: WebContentFilteringParentCategoryTypes.HighBandwidth,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.ImageSharing,
		name: 'imageSharing',
		type: WebContentFilteringParentCategoryTypes.HighBandwidth,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.PeerToPeer,
		name: 'peerToPeer',
		type: WebContentFilteringParentCategoryTypes.HighBandwidth,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.StreamingMediaAndDownloads,
		name: 'streamingMediaAndDownloads',
		type: WebContentFilteringParentCategoryTypes.HighBandwidth,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Chat,
		name: 'chat',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Games,
		name: 'games',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.InstantMessaging,
		name: 'instantMessaging',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.ProfessionalNetworking,
		name: 'professionalNetworking',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.WebbasedEmail,
		name: 'webbasedEmail',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.SocialNetworking,
		name: 'socialNetworking',
		type: WebContentFilteringParentCategoryTypes.Leisure,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.Unknown,
		name: 'unknown',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: true,
	},
	{
		id: WebContentFilteringCategoryTypes.AdvertisementsAndPopUps,
		name: 'advertisementsAndPopUps',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.AlcoholAndTobacco,
		name: 'alcoholAndTobacco',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Anonymizers,
		name: 'anonymizers',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Arts,
		name: 'arts',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Business,
		name: 'business',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Transportation,
		name: 'transportation',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.ForumsAndNewsgroups,
		name: 'forumsAndNewsgroups',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Compromised,
		name: 'compromised',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.ComputersAndTechnology,
		name: 'computersAndTechnology',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.DatingAndPersonals,
		name: 'datingAndPersonals',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Education,
		name: 'education',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Entertainment,
		name: 'entertainment',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Finance,
		name: 'finance',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Government,
		name: 'government',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.HealthAndMedicine,
		name: 'healthAndMedicine',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.JobSearch,
		name: 'jobSearch',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.News,
		name: 'news',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.NonprofitsAndNGOs,
		name: 'nonprofitsAndNGOs',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.PersonalSites,
		name: 'personalSites',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.PhishingAndFraud,
		name: 'phishingAndFraud',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Politics,
		name: 'politics',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.RealEstate,
		name: 'realEstate',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Religion,
		name: 'religion',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.RestaurantsAndDining,
		name: 'restaurantsAndDining',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.SearchEnginesAndPortals,
		name: 'searchEnginesAndPortals',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Shopping,
		name: 'shopping',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.SpamSites,
		name: 'spamSites',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Sports,
		name: 'sports',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Malware,
		name: 'malware',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Translators,
		name: 'translators',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Travel,
		name: 'travel',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.General,
		name: 'general',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.LeisureAndRecreation,
		name: 'leisureAndRecreation',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Botnets,
		name: 'botnets',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.FashionAndBeauty,
		name: 'fashionAndBeauty',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Greetingcards,
		name: 'greetingcards',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.InformationSecurity,
		name: 'informationSecurity',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.NetworkErrors,
		name: 'networkErrors',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.ParkedDomains,
		name: 'parkedDomains',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.PrivateIPAddresses,
		name: 'privateIPAddresses',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.NatureAndConservation,
		name: 'natureAndConservation',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.FileRepository,
		name: 'fileRepository',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.GayOrLesbianOrBisexual,
		name: 'gayOrLesbianOrBisexual',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.SuspectedMalware,
		name: 'suspectedMalware',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.SuspectedPhishing,
		name: 'suspectedPhishing',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.LingerieAndSwimsuits,
		name: 'lingerieAndSwimsuits',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.Marijuana,
		name: 'marijuana',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.ChildInappropriate,
		name: 'childInappropriate',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	},
	{
		id: WebContentFilteringCategoryTypes.NewlyRegisteredDomains,
		name: 'newlyRegisteredDomains',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
		isSupported: false,
	}
];

export const webCategoriesMap = keyBy<WebContentFilteringCategoryType>(webCategories, category => category.id);

const supportedParentCategories = [
	{
		id: WebContentFilteringParentCategoryTypes.AdultContent,
		name: 'adultContent',
		type: WebContentFilteringParentCategoryTypes.AdultContent,
	},
	{
		id: WebContentFilteringParentCategoryTypes.HighBandwidth,
		name: 'highBandwidth',
		type: WebContentFilteringParentCategoryTypes.HighBandwidth,
	},
	{
		id: WebContentFilteringParentCategoryTypes.LegalLiability,
		name: 'legalLiability',
		type: WebContentFilteringParentCategoryTypes.LegalLiability,
	},
	{
		id: WebContentFilteringParentCategoryTypes.Leisure,
		name: 'leisure',
		type: WebContentFilteringParentCategoryTypes.Leisure,
	},
	{
		id: WebContentFilteringParentCategoryTypes.Uncategorized,
		name: 'uncategorized',
		type: WebContentFilteringParentCategoryTypes.Uncategorized,
	},
];

@Entity({
	singularName: 'Content filtering category type',
	pluralName: 'Content filtering category types',
	values: webCategories,
	readonly: true,
})
export class WebContentFilteringCategoryType extends EntityModelBase<number> {
	@EntityField() name: string;

	@EntityField() type: string;

	@EntityField() isSupported: boolean;
}

@Entity({
	singularName: 'Content filtering parent category type',
	pluralName: 'Content filtering parent category types',
	values: supportedParentCategories,
	readonly: true,
})
export class WebContentFilteringParentCategoryType extends EntityModelBase<
	WebContentFilteringParentCategoryTypes
> {
	@EntityField() name: string;

	@EntityField() type: string;
}
