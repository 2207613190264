var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Paris } from '@microsoft/paris';
import { isNil } from 'lodash-es';
import { of } from 'rxjs';
import { InvestigationStatus, Alert } from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature } from '@wcd/config';
import { ServiceUrlsService } from '@wcd/app-config';
import { Prettify } from '../../../../../utils/services/prettify.service';
import { ReportWidgetComponent } from '../../../../components/report-widget.component.base';
import { ReportsService } from '../../../../../shared-reports/services/reports.service';
import { PrettyNumberService } from '@wcd/prettify';
import { getUserTimeZone } from '@wcd/localization';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
var WIDGET_API_URL = 'dashboard/automated_investigations_statistics';
var AutomatedInvestigationsStatisticsWidget = /** @class */ (function (_super) {
    __extends(AutomatedInvestigationsStatisticsWidget, _super);
    function AutomatedInvestigationsStatisticsWidget(reportsService, featuresService, serviceUrlsService, paris, appConfigService, prettyNumberService, i18nService, globalEntityTypesService) {
        var _this = _super.call(this, reportsService) || this;
        _this.featuresService = featuresService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.paris = paris;
        _this.appConfigService = appConfigService;
        _this.prettyNumberService = prettyNumberService;
        _this.i18nService = i18nService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this._filterableInvestigationIds = InvestigationStatus.entityConfig.values
            .filter(function (status) { return status.id >= 0; })
            .filter(function (status) {
            return _this.featuresService.isEnabled(Feature.AirsApiOffloading)
                ? !isNil(status.sevilleStatusId)
                : !status.isTempOffloadingStatus;
        })
            .map(function (status) { return status.id; })
            .join('|');
        return _this;
    }
    Object.defineProperty(AutomatedInvestigationsStatisticsWidget.prototype, "widgetConfig", {
        get: function () {
            var _this = this;
            var rangeInDays = this.appConfigService.isDemoTenant
                ? this.appConfigService.widgetLookback
                : 7;
            return Object.assign({
                id: 'automatedInvestigationsStatistics',
                name: this.i18nService.get('widget_title_automatedInvestgationsStatistics'),
                noDataMessage: 'No automated investigations performed',
                noDataIcon: 'investigationOutline',
                rangeInDays: rangeInDays,
            }, this.appConfigService.isAutomatedIrEnabled
                ? {
                    api: this.featuresService.isEnabled(Feature.AirsApiOffloading)
                        ? {
                            url: function () { return _this.serviceUrlsService.threatIntel + "/" + WIDGET_API_URL; },
                            isExternal: true,
                            params: {
                                lookingBackInDays: rangeInDays,
                            },
                        }
                        : {
                            url: WIDGET_API_URL,
                            params: {
                                range: rangeInDays,
                                timezone: getUserTimeZone(),
                            },
                        },
                    parseData: this.parseData.bind(this),
                }
                : {
                    loadData: function () { return of([null]); },
                });
        },
        enumerable: true,
        configurable: true
    });
    AutomatedInvestigationsStatisticsWidget.prototype.parseData = function (data) {
        if (!data.investigations_number)
            return null;
        var alertEntityType = this.globalEntityTypesService.getEntityType(Alert);
        return [
            {
                id: 'automatedInvestigations',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_automated_investigations'),
                value: this.prettyNumberService.prettyNumber(data.investigations_number || 0),
                icon: 'investigationOutline',
                url: '/investigations',
                queryParams: {
                    range: this.widgetConfig.rangeInDays,
                },
            },
            {
                id: 'remediatedInvestigations',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_remediated_investigations'),
                value: this.prettyNumberService.prettyNumber(data.fully_remediated_investigations || 0),
                icon: 'investigations.status.success',
                iconClassName: 'color-text-success',
                url: '/investigations',
                queryParams: {
                    filters: "status=" + (this.paris.getValue(InvestigationStatus, function (status) { return status.type === 'fullyRemediated'; }))[this.featuresService.isEnabled(Feature.AirsApiOffloading)
                        ? 'sevilleStatusId'
                        : 'id'],
                    range: this.widgetConfig.rangeInDays,
                },
            },
            {
                id: 'avgInvestigationPendingTime',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_average_pending_time'),
                value: Prettify.prettyTime(data.investigations_pending_time || 0),
                icon: 'investigations.status.paused',
                iconClassName: 'color-text-warning-dark',
                trend: (data.investigations_pending_time || 0) ===
                    (data.investigations_pending_time_previous_era || 0)
                    ? null
                    : (data.investigations_pending_time || 0) >
                        (data.investigations_pending_time_previous_era || 0)
                        ? 'up'
                        : 'down',
                get trendCssClass() {
                    return "trend-" + (this.trend === 'up' ? 'down' : 'up');
                },
            },
            {
                id: 'avgTimeToRemediation',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_average_time_to_remediate'),
                value: Prettify.prettyTime(data.avg_alert_to_remediation_time || 0),
                icon: 'clock',
                helpKey: 'dashboard.avgAlertToRemediationTime.description',
                trend: (data.avg_alert_to_remediation_time || 0) ===
                    (data.avg_alert_to_remediation_time_previous_era || 0)
                    ? null
                    : (data.avg_alert_to_remediation_time || 0) >
                        (data.avg_alert_to_remediation_time_previous_era || 0)
                        ? 'up'
                        : 'down',
                get trendCssClass() {
                    return "trend-" + (this.trend === 'up' ? 'down' : 'up');
                },
            },
            {
                id: 'alertsInvestigated',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_alerts_investigated'),
                value: this.prettyNumberService.prettyNumber(data.alerts_investigated || 0),
                icon: 'alertWithA',
                url: alertEntityType.getEntityDataviewLink(),
                queryParams: {
                    filters: "investigationStates=" + this._filterableInvestigationIds,
                },
            },
            {
                id: 'hoursAutomated',
                text: this.i18nService.get('automatedInvestigationsStatisticsWidget_hours_automated'),
                value: this.prettyNumberService.prettyNumber(data.saved_time || 0),
                icon: 'timeEntry',
            },
        ];
    };
    return AutomatedInvestigationsStatisticsWidget;
}(ReportWidgetComponent));
export { AutomatedInvestigationsStatisticsWidget };
