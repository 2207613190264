import { Injectable } from '@angular/core';
import { IPreload } from '@wcd/shared';
import { from, Observable } from 'rxjs';
import { i18nLoader } from './i18n.loader.svc';
import { Strings } from '../models/strings';

@Injectable()
export class I18nService implements IPreload {
	get strings(): Strings {
		return i18nLoader.strings;
	}

	init(): Observable<Strings> {
		return from(i18nLoader.i18nDictionaryPromise);
	}

	get(keyPath: keyof Strings | string, params?: any, allowNotFound: boolean = false): string {
		return i18nLoader.get(keyPath, params, allowNotFound);
	}
}
