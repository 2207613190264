import { NgModule } from '@angular/core';
import { FabIconModule } from '@angular-react/fabric';
import { CommonModule } from '@angular/common';
import { TooltipsModule } from '@wcd/dialogs';
import { SimplePaginationComponent } from './simple-pagination.component';

const exportedComponents = [SimplePaginationComponent];

@NgModule({
	imports: [CommonModule, TooltipsModule, FabIconModule,],
	declarations: [...exportedComponents],
	exports: [...exportedComponents],
})
export class PaginationModule {}
