
		<div class="wcd-margin-medium-bottom message-bar" *ngIf="shouldDisplayAdIotUpsellMessage">
			<div>
				<wcd-info-box>
					<div class="wcd-font-size-s wcd-margin-none-bottom">
						<div>{{ 'DevicePaneAdIotUpsellMessage' | i18n }}</div>
						<a href="#" (click)="openAdIotUpsellSidePanel()">{{
							'DevicePaneAdIotUpsellMessageLinkText' | i18n
						}}</a>
					</div>
				</wcd-info-box>
			</div>
		</div>
	