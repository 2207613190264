import { upperFirst, camelCase } from 'lodash-es';

export enum AirsEntityType {
	File = 1,
	Process = 2,
	Driver = 3,
	Service = 4,
	Certificate = 5,
	URL = 6,
	IP = 7,
	AppendedFile = 8,
	EmailUrl = 9,
	User = 10,
	WebPage = 11,
	CertificateAnalysis = 12,
	FileAnalysis = 13,
	URLAnalysis = 14,
	IPAnalysis = 15,
	Email = 16,
	PersistenceMethod = 17,
	MemoryContent = 18,
	MemoryContentHashAnalysis = 19,
	PersistenceMethodAnalysis = 20,
	UserActivity = 21,
	Account = 22,

	MailCluster = -1,
	MailMessage = -2,
	Mailbox = -3,
	SubmissionMail = -4,
	MailboxConfiguration = -5,

	// entities that not defined in Airs BE - using fake ids
	SecurityGroup = 1001,
	RegistryKey = 1002,
	RegistryValue = 1003,
	CloudApplication = 1004,
	CloudLogonSession = 1005,
	OauthApplication = 1006,
	ActiveDirectoryDomain = 1007,
}

export type MappableAirsEntityTypes = Exclude<
	keyof typeof AirsEntityType,
	| 'CertificateAnalysis'
	| 'FileAnalysis'
	| 'URLAnalysis'
	| 'IPAnalysis'
	| 'MemoryContent'
	| 'MemoryContentHashAnalysis'
	| 'PersistenceMethodAnalysis'
	// deprecated
	| 'Certificate'
	| 'Email'
>;

export enum AirsEntityTypeValue {
	File = 'File',
	Process = 'Process',
	Driver = 'Driver',
	Service = 'Service',
	Certificate = 'Certificate',
	URL = 'URL',
	IP = 'IP',
	AppendedFile = 'AppendedFile',
	EmailUrl = 'EmailUrl',
	User = 'User',
	WebPage = 'WebPage',
	CertificateAnalysis = 'CertificateAnalysis',
	FileAnalysis = 'FileAnalysis',
	URLAnalysis = 'URLAnalysis',
	IPAnalysis = 'IPAnalysis',
	Email = 'Email',
	PersistenceMethod = 'PersistenceMethod',
	MemoryContent = 'MemoryContent',
	MemoryContentHashAnalysis = 'MemoryContentHashAnalysis',
	PersistenceMethodAnalysis = 'PersistenceMethodAnalysis',
	UserActivity = 'UserActivity',
	Account = 'Account',
	SecurityGroup = 'SecurityGroup',
	RegistryKey = 'RegistryKey',
	RegistryValue = 'RegistryValue',
	CloudApplication = 'CloudApplication',
	CloudLogonSession = 'CloudLogonSession',
	OauthApplication = 'OauthApplication',
	ActiveDirectoryDomain = 'ActiveDirectoryDomain',

	MailCluster = 'MailCluster',
	MailMessage = 'MailMessage',
	Mailbox = 'Mailbox',
	SubmissionMail = 'SubmissionMail',
	MailboxConfiguration = 'MailboxConfiguration',
}

/**
 * This is a temporary conversion to the correct case for entity type.
 * We need to get the type in correct case from Office in the future and we will remove this conversion.
 * @param entityType
 */
export const convertEntityTypeCase = (entityType: string): string => {
	if (entityType) {
		const entityTypeUpper = entityType.toUpperCase();

		return entityTypeUpper === AirsEntityTypeValue.URL || entityTypeUpper === AirsEntityTypeValue.IP
			? entityTypeUpper
			: upperFirst(camelCase(entityType));
	}
	return '';
};
