import { Input, OnDestroy, OnInit } from '@angular/core';
import { PopupSettings } from '../../models/popup-settings';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';

export class ModalContainer implements OnInit, OnDestroy {
	@Input() settings: PopupSettings;
	destroy: () => void;
	private _onNavigateSubscription: Subscription;

	constructor(protected router: Router) {}

	ngOnInit() {
		if (!this.settings || !this.settings.persistOnNavigate) {
			this._onNavigateSubscription = this.router.events
				.pipe(filter((event: Event) => event instanceof NavigationEnd))
				.subscribe(() => this.destroy && this.destroy());
		}
	}

	ngOnDestroy() {
		if (this._onNavigateSubscription) {
			this._onNavigateSubscription.unsubscribe();
		}
	}

	closeModal(): void {
		this.destroy();
	}
}
