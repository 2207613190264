import { Component, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { ComparisonResultSingularEntity } from '@wcd/domain';

@Component({
	selector: 'comparison-result-entity-panel',
	template: `
		<section class="wcd-padding-vertical wcd-padding-large-horizontal">
			<comparison-result-entity-details
				[comparisonResult]="comparisonResult"
				[contextOptions]="contextOptions"
			></comparison-result-entity-details>
		</section>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ComparisonResultEntityPanelComponent extends EntityPanelComponentBase<
	ComparisonResultSingularEntity,
	TvmContextOptions
> {
	get comparisonResult(): ComparisonResultSingularEntity {
		return this.entity;
	}

	get contextOptions(): TvmContextOptions {
		return this.options;
	}

	constructor(changeDetectorRef: ChangeDetectorRef) {
		super(changeDetectorRef);
	}

	initEntity(comparisonResult: ComparisonResultSingularEntity, isExtendedData: boolean = false) {
		super.initEntity(comparisonResult, isExtendedData);
		this.changeDetectorRef.markForCheck();
	}
}

export interface TvmContextOptions {
	isSingleAsset?: boolean;
	machineId?: string;
	isNetworkGear?: boolean;
}
