import { Entity, EntityField, ValueObject, ModelBase, DataQuery } from '@microsoft/paris';
import { Machine } from '../machine/machine.entity';
import { File, parseFileItemQuery } from './file.entity';
import { NetworkEndpoint } from '../entity/network-endpoint.value-object';
import { FileId } from './file-id.type';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ValueObject({
	singularName: 'File Created By',
	pluralName: 'File Created By'
})
export class FileCreatedBy extends ModelBase {
	@EntityField({ data: 'ProcessId' })
	readonly processId?: string;

	@EntityField({ data: 'ProcessName' })
	readonly processName?: string;
}

@ValueObject({
	singularName: 'Mark of web',
	pluralName: 'Marks of web'
})
export class MarkOfWeb extends ModelBase {
	@EntityField({ data: 'Zone' })
	readonly zone?: string;

	@EntityField({ data: 'HostUrl' })
	readonly hostUrl?: string;

	@EntityField({ data: 'ReferrerUrl' })
	readonly referrerUrl?: string;

	@EntityField({ data: 'HostIpAddress' })
	readonly hostIpAddress?: string;
}

@Entity({
	singularName: 'File',
	pluralName: 'Files',
	cache: {
		time: 1000 * 60, // 1 minute
		max: 10,
	},
	readonly: true,
	endpoint: `files`,
	parseItemQuery: parseFileItemQuery,
	baseUrl: (config: WcdPortalParisConfig, query: DataQuery) =>
		query.where && query.where['newFilesApi']
			? config.data.serviceUrls.cyberProfileService
			: config.data.serviceUrls.threatIntel, // remove threatIntel after migration to VNext is completed.
})
export class FileInstance extends File {

	@EntityField({ data: ['Sha1', 'Sha256', 'id'] })
	readonly id: FileId;

	@EntityField({ data: ['FolderPath'] })
	readonly folderPath?: string;

	@EntityField({ data: ['FullPath'] })
	readonly fullPath?: string;

	@EntityField({ data: ['MachineId'] })
	readonly machine?: Machine;

	@EntityField({ data: 'IsAzureInfoProtectionApplied' })
	readonly isAzureInfoProtectionApplied?: boolean;

	readonly fileOrigin?: NetworkEndpoint;

	readonly fileOriginReferer?: NetworkEndpoint;

	@EntityField({ data: 'CreationTime' })
	readonly creationTime?: Date;

	@EntityField({ data: 'deviceName' })
	readonly deviceName?: string;

	@EntityField({ data: 'deviceId' })
	readonly deviceId?: string;

	@EntityField({ data: 'MarkOfWeb' })
	readonly markOfWeb?: MarkOfWeb;

	@EntityField({ parse(_, entity) { return { ProcessId: entity.CreatingProcessId, ProcessName: entity.CreatingProcessName }; }})
	readonly createdBy?: FileCreatedBy

	@EntityField({ data: 'LastFileEvent' })
	readonly lastFileEvent?: Date;

	@EntityField({ data: 'MergeByKey' })
	readonly uniqueEntityId?: string;
}
