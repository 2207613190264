import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThreatProtectionDetectionSourceWidget } from './threat-protection-detection-source.widget';
import { BarsChartModule, TimeSeriesChartModule } from '@wcd/charts';
import { ThreatProtectionDetectionSourceDashboardWidget } from './threat-protection-detection-source-dashboard-widget';
import { FabIconModule } from '@angular-react/fabric';
import { MachineProtectionActiveStatusOvertimeWidget } from './machine-protection-active-status-overtime-widget';

@NgModule({
	declarations: [ThreatProtectionDetectionSourceWidget, ThreatProtectionDetectionSourceDashboardWidget, MachineProtectionActiveStatusOvertimeWidget],
	imports: [CommonModule, BarsChartModule, TimeSeriesChartModule, FabIconModule],
	entryComponents: [ThreatProtectionDetectionSourceWidget, ThreatProtectionDetectionSourceDashboardWidget, MachineProtectionActiveStatusOvertimeWidget],
})
export class SharedReportsModule {}
