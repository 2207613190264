<wcd-panel (close)="destroy()" [settings]="settings" data-track-component="TvmReportInaccuracy">
	<div class="wcd-full-height wcd-flex-vertical wcd-padding-large-horizontal wcd-padding-vertical">
		<header>
			<h2 #panelHeader class="tvm-list__header">
				{{ 'tvm.reportInaccuracy.panelTitle' | i18n }}
			</h2>
		</header>

		<div class="wcd-flex-1 wcd-scroll-vertical tvm-list tvm-list--no-columns">
			<div class="wcd-padding-top tvm-list__header">{{'tvm.common.description' | i18n}}</div>
			<div class="tvm-list__block_item">{{'tvm.reportInaccuracy.descriptionText' | i18n}}</div>

			<ng-container *ngIf="showFastlaneIndication">
			<div class="wcd-margin-right wcd-margin-large-bottom">
				<fab-message-bar
					[styles]="messageBarStyles"
					[messageBarType]="messageBarType.info">
					{{'tvm.machine.fastlane.header.warning' | i18n}}
				</fab-message-bar>
			</div>
			</ng-container>

			<div class="tvm-list__header">{{ 'tvm.reportInaccuracy.reportAbout' | i18n }}</div>
			<div class="tvm-list__block_item">{{ contextTitle }}</div>

			<div class="tvm-list__header">{{ reasonsTitle }}</div>
			<div class="tvm-list__block_item">
				<fancy-select class="command-bar-item-dropdown"
					[isBordered]="true"
					[labelClass]="'dropdown-width-medium'"
					[(ngModel)]="currentReason"
					(ngModelChange)="updateReasonSelected()"
					[values]="reasons"
					[isDisabled]="isSending"
					[ariaLabel]="showDisclaimer ? disclaimerText : reasonsTitle"
					[placeholder]="'tvm.reportInaccuracy.selectPlaceholder' | i18n">
				</fancy-select>
				<ng-container *ngIf="showDisclaimer || showReportForSpecificDeviceText" [ngTemplateOutlet]="disclaimer" [ngTemplateOutletContext]="{message: showReportForSpecificDeviceText ? orgScaInaccuracyBlockText : disclaimerText }">
				</ng-container>
			</div>

			<div class="tvm-list__block_item">
				<div class="wcd-flex-horizontal">
					<wcd-checkbox class="checkbox-no-outline"
						[(ngModel)]="includeEmail"
						(ngModelChange)="setEmail()"
						name="reportIncludeEmail"
						[label]="'feedback.includeEmail' | i18n"
						[tooltipText]="'feedback.includeEmailHelp' | i18n">
						<wcd-help class="wcd-padding-xsmall-left" [tabIndexValue]="null" [text]="'feedback.includeEmailHelp' | i18n"></wcd-help>
					</wcd-checkbox>
				</div>

				<input type="email"
					id="report-email"
					validateRegExp="email"
					#emailControl="ngModel"
					name="feedbackEmail"
					[disabled]="showReportForSpecificDeviceText || !includeEmail"
					[required]="includeEmail"
					class="form-control wcd-full-width wcd-margin-small-top"
					[placeholder]="'feedback.emailPlaceholder' | i18n"
					[(ngModel)]="email"/>
				<div class="error-message"
					[class.invisible]="showReportForSpecificDeviceText || !email || emailControl.valid">
					{{'feedback.invalidEmail' | i18n}}
				</div>
			</div>

			<div *ngIf="isMachineContext()" class="tvm-list__block_item">
				<wcd-checkbox
					[(ngModel)]="includeMachineName"
					(ngModelChange)="setMachineName()"
					name="reportIncludeMachineName"
					[label]="'tvm.reportInaccuracy.includeMachineName' | i18n">
				</wcd-checkbox>
				<input type="text"
					id="report-machine-name"
					name="reportMachineName"
					[disabled]="!includeMachineName"
					class="form-control wcd-full-width wcd-margin-small-top"
					[placeholder]="'tvm.reportInaccuracy.machineNamePlaceholder' | i18n"
					[(ngModel)]="machineName"
					readonly/>
			</div>

			<div class="tvm-list__block_item wcd-flex-1 wcd-scroll-vertical">
				<div class="tvm-list__header">
					{{ 'tvm.reportInaccuracy.tellUsMore' | i18n }}
				</div>
				<textarea
					class="wcd-no-resize comment-input wcd-padding-small-all wcd-margin-small-bottom wcd-full-width"
					[attr.aria-label]="'tvm.reportInaccuracy.tellUsMore' | i18n"
					[(ngModel)]="notes"
					(ngModelChange)="updateCanSubmit()"
					name="notes-text"
					maxlength="1000"
					rows="4"
					[disabled]="showReportForSpecificDeviceText || isSending"
					[placeholder]="'tvm.reportInaccuracy.notesPlaceHolder' | i18n">
				</textarea>
			</div>
			<div class="wcd-margin-xLarge-right">
				<markdown [data]="('tvm.reportInaccuracy.feedbackDisclaimer' | i18n) + ' ' + ('tvm.reportInaccuracy.privacyStatement' | i18n)"></markdown>
			</div>
		</div>

		<footer class="wcd-padding-small-top">
			<div class="wcd-padding-small-top wcd-border-top">
				<fab-primary-button
					data-track-id="SubmitReportInaccuracy"
					data-track-type="Button"
					(onClick)="submitReport()"
					[disabled]="isSending || !canSubmit">
					<i class="loader-icon" [hidden]="!isSending"></i>
					{{ 'tvm.reportInaccuracy.submit' | i18n }}
				</fab-primary-button>
			</div>
		</footer>
	</div>
</wcd-panel>

<ng-template #disclaimer let-message="message">
	<div class="ms-color-red wcd-padding-small-top wcd-font-weight-semibold">
		{{ message }}
	</div>
</ng-template>
