/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../global_entities/components/entity-name/entity-name.component.ngfactory";
import * as i3 from "../../../global_entities/components/entity-name/entity-name.component";
import * as i4 from "../../../global_entities/services/global-entity-types.service";
import * as i5 from "../../../global_entities/services/entity-panels.service";
import * as i6 from "../../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i7 from "../../../../../../../projects/icons/src/lib/components/icon.component";
import * as i8 from "../../../../../../../projects/icons/src/lib/services/icons.service";
import * as i9 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./evidence-detection-context.component";
import * as i12 from "../services/evidence.service";
var styles_EvidenceDetectionContextComponent = [];
var RenderType_EvidenceDetectionContextComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EvidenceDetectionContextComponent, data: {} });
export { RenderType_EvidenceDetectionContextComponent as RenderType_EvidenceDetectionContextComponent };
function View_EvidenceDetectionContextComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, "span", [["class", "wcd-severity wcd-margin-small-left"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(2, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(3, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i0.ɵppd(4, 1)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-severity wcd-margin-small-left"; var currVal_1 = ("wcd-severity-" + _co.detectionSource.alertSeverity.type); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = i0.ɵunv(_v, 3, 0, _ck(_v, 4, 0, i0.ɵnov(_v.parent.parent.parent, 0), _co.detectionSource.alertSeverity.nameI18nKey)); _ck(_v, 3, 0, currVal_2); }); }
function View_EvidenceDetectionContextComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "entity-name", [], null, null, null, i2.View_EntityNameComponent_0, i2.RenderType_EntityNameComponent)), i0.ɵdid(3, 573440, null, 0, i3.EntityNameComponent, [i4.GlobalEntityTypesService, i5.EntityPanelsService], { entity: [0, "entity"], entityTypeId: [1, "entityTypeId"], showTooltip: [2, "showTooltip"], hideIcon: [3, "hideIcon"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextComponent_3)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.alertEntity; var currVal_1 = _co.Alert; var currVal_2 = _co.showTooltip; var currVal_3 = true; _ck(_v, 3, 0, currVal_0, currVal_1, currVal_2, currVal_3); var currVal_4 = _co.detectionSource.alertSeverity; _ck(_v, 6, 0, currVal_4); }, null); }
function View_EvidenceDetectionContextComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 2, "div", [["class", "wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"]], null, null, null, null, null)), i0.ɵprd(512, null, i1.ɵNgClassImpl, i1.ɵNgClassR2Impl, [i0.IterableDiffers, i0.KeyValueDiffers, i0.ElementRef, i0.Renderer2]), i0.ɵdid(4, 278528, null, 0, i1.NgClass, [i1.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i0.ɵted(5, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-inline-block wcd-severity-round-icon medium wcd-margin-small-left"; var currVal_1 = ("color-box-" + _co.detectionSource.actionStatus.className); _ck(_v, 4, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.detectionSource.actionStatus.name; _ck(_v, 5, 0, currVal_2); }); }
function View_EvidenceDetectionContextComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(3, null, ["", ""])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextComponent_5)), i0.ɵdid(6, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.detectionSource.actionStatus; _ck(_v, 6, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.investigationDetectionName; _ck(_v, 3, 0, currVal_0); }); }
function View_EvidenceDetectionContextComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 14, "span", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "wcd-shared-icon", [["class", "wcd-margin-xsmall-right"]], null, null, null, i6.View_IconComponent_0, i6.RenderType_IconComponent)), i0.ɵdid(3, 573440, null, 0, i7.IconComponent, [i8.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 8, null, null, null, null, null, null, null)), i0.ɵdid(6, 16384, null, 0, i1.NgSwitch, [], { ngSwitch: [0, "ngSwitch"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextComponent_2)), i0.ɵdid(9, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextComponent_4)), i0.ɵdid(12, 278528, null, 0, i1.NgSwitchCase, [i0.ViewContainerRef, i0.TemplateRef, i1.NgSwitch], { ngSwitchCase: [0, "ngSwitchCase"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.detectionSource.detectionType.iconName; _ck(_v, 3, 0, currVal_0); var currVal_1 = _co.detectionSource.detectionType.id; _ck(_v, 6, 0, currVal_1); var currVal_2 = _co.EvidenceDetectionSourceTypeEnum.alert; _ck(_v, 9, 0, currVal_2); var currVal_3 = _co.EvidenceDetectionSourceTypeEnum.investigation; _ck(_v, 12, 0, currVal_3); }, null); }
export function View_EvidenceDetectionContextComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i9.I18nPipe, [i10.I18nService]), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EvidenceDetectionContextComponent_1)), i0.ɵdid(3, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.detectionSource == null) ? null : _co.detectionSource.detectionType); _ck(_v, 3, 0, currVal_0); }, null); }
export function View_EvidenceDetectionContextComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "evidence-detection-context", [], null, null, null, View_EvidenceDetectionContextComponent_0, RenderType_EvidenceDetectionContextComponent)), i0.ɵdid(1, 49152, null, 0, i11.EvidenceDetectionContextComponent, [i12.EvidenceService], null, null)], null, null); }
var EvidenceDetectionContextComponentNgFactory = i0.ɵccf("evidence-detection-context", i11.EvidenceDetectionContextComponent, View_EvidenceDetectionContextComponent_Host_0, { showTooltip: "showTooltip", detectionContext: "detectionContext" }, {}, []);
export { EvidenceDetectionContextComponentNgFactory as EvidenceDetectionContextComponentNgFactory };
