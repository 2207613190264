var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { AuthenticatedScanSession } from './authenticated-scan-session.entity';
import { AuthenticatedScanResult } from './authenticated-scan-result.entity';
import { TvmAuthenticatedScanSharedEntityConfigurations } from '@wcd/domain';
import { AuthenticatedScanResultCsv } from './authenticated-scan-result-csv.entity';
var ɵ0 = function (_, query) {
    return "commands/liveResults?sessionId=" + encodeURIComponent(query.where['id']) + "&fromTimestamp=0";
}, ɵ1 = function (AuthenticatedScanSession) {
    return { id: AuthenticatedScanSession.id };
};
var AuthenticatedScanSessionRelationship = /** @class */ (function () {
    function AuthenticatedScanSessionRelationship() {
    }
    AuthenticatedScanSessionRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAuthenticatedScanSharedEntityConfigurations, { sourceEntity: AuthenticatedScanSession, dataEntity: AuthenticatedScanResult, endpoint: ɵ0, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ1 }))
    ], AuthenticatedScanSessionRelationship);
    return AuthenticatedScanSessionRelationship;
}());
export { AuthenticatedScanSessionRelationship };
var ɵ2 = function (_, query) {
    return "commands/liveResultsCsv?sessionId=" + encodeURIComponent(query.where['id']) + "&fromTimestamp=0";
}, ɵ3 = function (AuthenticatedScanSession) {
    return { id: AuthenticatedScanSession.id };
};
var AuthenticatedScanSessionCsvRelationship = /** @class */ (function () {
    function AuthenticatedScanSessionCsvRelationship() {
    }
    AuthenticatedScanSessionCsvRelationship = __decorate([
        EntityRelationship(__assign({}, TvmAuthenticatedScanSharedEntityConfigurations, { sourceEntity: AuthenticatedScanSession, dataEntity: AuthenticatedScanResultCsv, endpoint: ɵ2, allowedTypes: [RelationshipType.OneToMany], getRelationshipData: ɵ3 }))
    ], AuthenticatedScanSessionCsvRelationship);
    return AuthenticatedScanSessionCsvRelationship;
}());
export { AuthenticatedScanSessionCsvRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
