import { Entity, EntityField } from '@microsoft/paris';
import { AirsEntity, airsEntityConfig } from '../airs-entity.entity';
import { AirsEntityRefNames } from './airs-entity-ref-names';
import { AirsAccount } from './airs-entity.account';

@Entity({
	...airsEntityConfig,
	singularName: 'Cloud Logon Session',
	pluralName: 'Cloud Logon Sessions',
	modelWith: null,
	forwardRefName: AirsEntityRefNames.AirsCloudLogonSession,
})
export class AirsCloudLogonSession extends AirsEntity {
	@EntityField({ data: 'session_id' })
	sessionId: string;

	@EntityField({ data: 'account' })
	account: AirsAccount;

	@EntityField({ data: 'user_agent' })
	userAgent: string;

	constructor(data) {
		super(data);
		this.entityName = (this.account && this.account.userDisplayName) || '';
	}
}
