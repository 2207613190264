
		<dl class="key-values clearfix" role="none">
			<ng-container *ngIf="entity.name">
				<dt role="none">{{ i18nService.strings.airsEntities_service_fields_serviceName }}</dt>
				<dd role="none">{{ entity.name }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.binPath">
				<dt role="none">{{ i18nService.strings.airsEntities_service_fields_servicePath }}</dt>
				<dd role="none">{{ entity.binPath }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.startType">
				<dt role="none">{{ i18nService.strings.airsEntities_service_fields_serviceStartType }}</dt>
				<dd role="none">{{ entity.startType }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.args">
				<dt role="none">{{ i18nService.strings.airsEntities_service_fields_serviceArguments }}</dt>
				<dd role="none">{{ entity.args }}</dd>
			</ng-container>
			<ng-container *ngIf="entity.username">
				<dt role="none">{{ i18nService.strings.airsEntities_service_fields_username }}</dt>
				<dd role="none">{{ entity.username }}</dd>
			</ng-container>
		</dl>
	