import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';

@ValueObject({
	singularName: "Incident's history item",
	pluralName: "Incident's history items",
})
export class IncidentHistoryItem extends ModelBase {
	@EntityField({
		data: 'CreatedBy',
		parse: itemData => ({
			Upn: itemData,
		}),
	})
	readonly user: { Upn: string };

	@EntityField({ data: 'NewValue' })
	readonly message: string;

	@EntityField({ data: 'Timestamp' })
	readonly timestamp: Date;

	readonly icon: string = 'comment';
}
