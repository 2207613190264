import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { ReportModel } from '../../../reports/models/report.model';
import { Subscription } from 'rxjs';
import { TitleService } from '../../../shared/services/title.service';
import { FeaturesService, Feature } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { Alert } from '@wcd/domain';
import { routeToLegacyAlertRoute } from '../services/alerts.service';
import { AlertContextWidgetComponent } from './widgets/alert-context-widget.component';
import { AlertStatusWidgetComponent } from './widgets/alert-status-widget.component';

@Component({
	template: `
		<div class="wcd-full-height wcd-flex-vertical">
			<report
				*ngIf="report"
				[hidden]="isFullScreen"
				[report]="report"
				[showPollingError]="false"
				[showControls]="false"
			>
			</report>
			<div
				class="wcd-flex-none wcd-margin-medium-horizontal wcd-margin-medium-bottom wcd-flex-spaceBetween-horizontal wcd-flex-center-vertical"
			>
				<ng-container *ngIf="tabs">
					<tabs [tabsData]="tabs" [currentRouteIsActive]="true"></tabs>
				</ng-container>
			</div>
			<div class="wcd-flex-1">
				<router-outlet></router-outlet>
			</div>
		</div>
	`,
})
export class AlertEntityComponent extends EntityComponentBase<Alert> implements OnInit, OnDestroy {
	tabs: ReadonlyArray<TabModel>;
	report: ReportModel;
	featuresChangeSubscription: Subscription;

	constructor(
		private readonly i18nService: I18nService,
		private readonly titleService: TitleService,
		private readonly featuresService: FeaturesService,
		private readonly router: Router,
		private readonly route: ActivatedRoute
	) {
		super();

		this.featuresChangeSubscription = this.featuresService.featureChanged$.subscribe(
			({ featureId, value: isEnabled }) => {
				if (featureId === Feature.UpgradeAlertPage && !isEnabled) {
					const urlTree = routeToLegacyAlertRoute(this.route.snapshot) as any[];
					this.router.navigate(urlTree, { replaceUrl: true });
				}
			}
		);

		this.report = new ReportModel({
			id: 'alert-entity-widgets',
			allowRangeSelect: false,
			allowExport: false,
			enablePadding: false,
			overflow: 'none',
			refreshOnQueryParamsChange: false,
			rows: [
				{
					columns: [
						{
							widgets: [AlertContextWidgetComponent],
						},
						{
							widgets: [AlertStatusWidgetComponent],
						},
					],
				},
			],
			transparent: true,
		});
	}

	get alert(): Alert {
		return this.entity;
	}

	ngOnInit() {
		this.titleService.setState({
			pageTitle: this.i18nService.get('alert.page.title', { name: this.alert.name }),
		});

		this.tabs = [
			{
				id: 'tree',
			},
			{
				id: 'graph',
			},
			{
				id: 'timeline',
			},
			{
				id: 'description',
			},
		].map(
			tab =>
				new TabModel({
					name: this.i18nService.get(`alert.tabs.${tab.id}.title`),
					routerLink: `./${tab.id}`,
					...tab,
				})
		);
	}

	ngOnDestroy() {
		if (this.featuresChangeSubscription) {
			this.featuresChangeSubscription.unsubscribe();
		}
	}
}
