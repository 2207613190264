import { Component, Injector, Input, OnInit } from '@angular/core';

import { DataTableField } from '@wcd/datatable';
import { Router } from '@angular/router';
import { Paris } from '@microsoft/paris';
import { Observable } from 'rxjs';
import { Alert, FileVerdict } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { I18nService } from '@wcd/i18n';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FileVerdictAlertService } from '../services/file-verdict-alert.service';
import { AlertNameFieldComponent } from '../../alerts/components/alert-name-field.component';

@Component({
	selector: 'malware-alert',
	template: `
		<wcd-panel (close)="destroy()" [settings]="settings">
			<ng-container *ngIf="(tableItems$ | async) as tableItems; else loadingTemplate">
				<wcd-datatable
					[items]="tableItems"
					[columns]="tableFields"
					[selectEnabled]="false"
					[label]="
						'files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.title'
							| i18n
					"
				></wcd-datatable>
			</ng-container>

			<ng-template #loadingTemplate>
				<fab-spinner [label]="'loading' | i18n"></fab-spinner>
			</ng-template>
		</wcd-panel>
	`,
})
export class MalwareAlertsComponent extends PanelContainer implements OnInit {
	@Input() sha1: string;
	@Input() fileVerdict: FileVerdict;

	tableItems$: Observable<Array<Alert>>;
	entityPanelsService: EntityPanelsService;

	readonly tableFields = DataTableField.fromList<Alert>([
		{
			id: 'detectionDate',
			name: this.i18nService.get(
				'files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.detectionDate'
			),
			component: {
				type: TzDateComponent,
				getProps: (alert: Alert) => ({ date: alert.firstEventTime }),
			},
		},
		{
			id: 'alertName',
			name: this.i18nService.get(
				'files.entityDetails.sections.detections.fields.malwareDetected.malwareAlertPanel.alertName'
			),
			component: {
				type: AlertNameFieldComponent,
				getProps: (alert: Alert) => ({ alert }),
			},
		},
	]);

	private readonly alertEntityTypeService: EntityType<Alert>;

	constructor(
		readonly router: Router,
		private readonly paris: Paris,
		private readonly injector: Injector,
		private readonly i18nService: I18nService,
		globalEntityTypesService: GlobalEntityTypesService,
		private readonly fileVerdictAlertService: FileVerdictAlertService
	) {
		super(router);
		this.entityPanelsService = this.injector.get(EntityPanelsService);
		this.alertEntityTypeService = globalEntityTypesService.getEntityType(Alert);
	}

	ngOnInit() {
		this.tableItems$ = this.fileVerdictAlertService.getFileVerdictAlerts(this.sha1, this.fileVerdict);
	}

	openAlertPage(alert: Alert) {
		this.router.navigateByUrl(this.alertEntityTypeService.getEntitiesLink([alert]));
	}
}
