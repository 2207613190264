/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "../../../../../../projects/icons/src/lib/components/icon.component.ngfactory";
import * as i3 from "../../../../../../projects/icons/src/lib/components/icon.component";
import * as i4 from "../../../../../../projects/icons/src/lib/services/icons.service";
import * as i5 from "./entity-link.component";
import * as i6 from "../services/global-entity-types.service";
import * as i7 from "../../insights/services/app-insights.service";
import * as i8 from "../../../../../../projects/shared/src/lib/services/routes.service";
import * as i9 from "../../../../../../projects/config/src/lib/services/features.service";
var styles_EntityLinkComponent = [];
var RenderType_EntityLinkComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_EntityLinkComponent, data: {} });
export { RenderType_EntityLinkComponent as RenderType_EntityLinkComponent };
function View_EntityLinkComponent_4(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_EntityLinkComponent_3(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0, null, View_EntityLinkComponent_4))], null, null); }
function View_EntityLinkComponent_2(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_3)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 4); _ck(_v, 3, 0, currVal_0); }, null); }
function View_EntityLinkComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 5, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_2)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.originalEntityType !== _co.networkEndpointType); var currVal_1 = i0.ɵnov(_v.parent, 6); _ck(_v, 4, 0, currVal_0, currVal_1); }, null); }
function View_EntityLinkComponent_6(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "wcd-shared-icon", [["class", "field-value-icon field-additional-icon"], ["iconName", "popOut"]], null, null, null, i2.View_IconComponent_0, i2.RenderType_IconComponent)), i0.ɵdid(1, 573440, null, 0, i3.IconComponent, [i4.IconsService, i0.ChangeDetectorRef], { iconName: [0, "iconName"] }, null)], function (_ck, _v) { var currVal_0 = "popOut"; _ck(_v, 1, 0, currVal_0); }, null); }
function View_EntityLinkComponent_5(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 4, "a", [], [[8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openEntityPanel($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i0.ɵted(2, null, ["", "\n\t\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_6)), i0.ɵdid(4, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showExternalLinkIcon; _ck(_v, 4, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.link; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.getLinkText(); _ck(_v, 2, 0, currVal_1); }); }
function View_EntityLinkComponent_8(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-link", [], null, null, null, View_EntityLinkComponent_0, RenderType_EntityLinkComponent)), i0.ɵdid(1, 573440, null, 0, i5.EntityLinkComponent, [i0.Injector, i6.GlobalEntityTypesService, i7.AppInsightsService, i8.RoutesService, i9.FeaturesService], { entityType: [0, "entityType"], entity: [1, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.ipType; var currVal_1 = _co.item.ip; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EntityLinkComponent_11(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
function View_EntityLinkComponent_10(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0, null, View_EntityLinkComponent_11))], null, null); }
function View_EntityLinkComponent_9(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t("])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_10)), i0.ɵdid(3, 540672, null, 0, i1.NgTemplateOutlet, [i0.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i0.ɵted(-1, null, [")\n\t\t\t"]))], function (_ck, _v) { var currVal_0 = i0.ɵnov(_v.parent.parent, 8); _ck(_v, 3, 0, currVal_0); }, null); }
function View_EntityLinkComponent_7(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_8)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_9)), i0.ɵdid(5, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.ip; _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.item.ip && _co.item.url); var currVal_2 = i0.ɵnov(_v.parent, 8); _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_EntityLinkComponent_13(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-link", [], null, null, null, View_EntityLinkComponent_0, RenderType_EntityLinkComponent)), i0.ɵdid(1, 573440, null, 0, i5.EntityLinkComponent, [i0.Injector, i6.GlobalEntityTypesService, i7.AppInsightsService, i8.RoutesService, i9.FeaturesService], { entityType: [0, "entityType"], entity: [1, "entity"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.urlType; var currVal_1 = _co.item.url; _ck(_v, 1, 0, currVal_0, currVal_1); }, null); }
function View_EntityLinkComponent_12(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_13)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.item.url; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EntityLinkComponent_0(_l) { return i0.ɵvid(2, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_EntityLinkComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["linkContent", 2]], null, 0, null, View_EntityLinkComponent_5)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["ipAndUrlLinks", 2]], null, 0, null, View_EntityLinkComponent_7)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(0, [["urlLinkContent", 2]], null, 0, null, View_EntityLinkComponent_12)), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.item && _co.itemEntityType); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_EntityLinkComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "entity-link", [], null, null, null, View_EntityLinkComponent_0, RenderType_EntityLinkComponent)), i0.ɵdid(1, 573440, null, 0, i5.EntityLinkComponent, [i0.Injector, i6.GlobalEntityTypesService, i7.AppInsightsService, i8.RoutesService, i9.FeaturesService], null, null)], null, null); }
var EntityLinkComponentNgFactory = i0.ɵccf("entity-link", i5.EntityLinkComponent, View_EntityLinkComponent_Host_0, { linkText: "linkText", actionTime: "actionTime", linkLogContext: "linkLogContext", entityType: "entityType", entity: "entity" }, {}, []);
export { EntityLinkComponentNgFactory as EntityLinkComponentNgFactory };
