
		<div>
			<!-- duplicate ips note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="devicesWithSameIp.length > 1"
					[messageBarType]="MessageBarType.warning"
				>
					<span>{{
						'MachineAction.IsolatDeviceWithDuplicateIps.warning'
							| i18n: { duplicatesCount: devicesWithSameIp.length - 1 }
					}}</span>
					<a href="{{ devicesWithSameIpLink }}" target="_blank" rel="noopener noreferrer">{{
						'MachineAction_IsolatDeviceMaybeHasDuplicateIps_Link' | i18n
					}}</a>
				</fab-message-bar>
			</div>

			<!-- unknown duplicate ips note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="errorOnDevicesWithSameIpRequest"
					[messageBarType]="MessageBarType.info"
				>
					<div>{{ 'MachineAction.IsolatDeviceMaybeHasDuplicateIps.info.first.line' | i18n }}</div>
					<div>
						{{ 'MachineAction.IsolatDeviceMaybeHasDuplicateIps.info.second.line' | i18n
						}}<a href="{{ devicesWithSameIpLink }}" target="_blank" rel="noopener noreferrer">{{
							'MachineAction.IsolatDeviceMaybeHasDuplicateIps.Link' | i18n
						}}</a>
					</div>
				</fab-message-bar>
			</div>

			<!-- isolating network device note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar *ngIf="isNetworkDevice" [messageBarType]="MessageBarType.warning">
					<span>{{ 'MachineAction.IsolatNetworkDevice.warning' | i18n }}</span>
				</fab-message-bar>
			</div>

			<!-- isolating high valued device note -->
			<div class="wcd-margin-mediumSmall-bottom">
				<fab-message-bar
					*ngIf="isHighValueAsset && !isNetworkDevice"
					[messageBarType]="MessageBarType.info"
				>
					<span>{{ 'MachineAction.IsolatHighValueDevice.info' | i18n }}</span>
				</fab-message-bar>
			</div>
		</div>
	