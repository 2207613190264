import { FeaturesService } from '@wcd/config';
import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { Breadcrumb, BreadcrumbsRouteConfig, breadcrumbsStateService } from '@wcd/shared';
import { filter } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

/**
 * A service with breadcrumbs helpers methods
 */
@Injectable({
	providedIn: 'root',
})
export class BreadcrumbsService implements OnDestroy {
	private _routeSubscriptions: Subscription;
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private featuresService: FeaturesService
	) {}

	/**
	 * Used by angularJs router in order to signal route change and showBreadcrumbs flag on given route.
	 * Can be removed when angularJs router is removed. TODO: remove when angularJs router is removed
	 * @type {Subject<boolean>}
	 */
	legacyShow$: Subject<boolean> = new Subject<boolean>();

	/**
	 * Gets the route (or route's child) that contains breadcrumbs config.
	 * Returns null if no child route contains breadcrumbs config.
	 * @param {ActivatedRouteSnapshot} route
	 * @returns {ActivatedRouteSnapshot | null}
	 */
	public getRouteWithBreadcrumbsConfig(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
		let routeWithBreadcrumbsConfig: ActivatedRouteSnapshot = route;
		while (routeWithBreadcrumbsConfig && !routeWithBreadcrumbsConfig.data['breadcrumbsConfig']) {
			routeWithBreadcrumbsConfig = routeWithBreadcrumbsConfig.firstChild;
		}

		return routeWithBreadcrumbsConfig;
	}

	/**
	 * Registers a listener on route events in order to update breadcrumbs list
	 */
	public register() {
		// listens to route change and updates breadcrumbs list
		this._routeSubscriptions = this.router.events
			.pipe(filter(event => event instanceof NavigationEnd))
			.subscribe(event => {
				const routeWithBreadcrumbsConfig: ActivatedRouteSnapshot = this.getRouteWithBreadcrumbsConfig(
					this.route.snapshot
				);

				if (!routeWithBreadcrumbsConfig) return;

				const breadcrumbs: Array<Breadcrumb> = routeWithBreadcrumbsConfig.data['breadcrumbs'];
				const breadcrumbsConfig: BreadcrumbsRouteConfig =
					routeWithBreadcrumbsConfig.data['breadcrumbsConfig'];

				// reset breadcrumbs if not allowed by feature flighting
				if (this.isBreadcrumbsAllowed(breadcrumbsConfig)) {
					breadcrumbsStateService.reset();
					return;
				}

				// reset list
				if (breadcrumbsConfig.resetListOnEnter) breadcrumbsStateService.reset();

				// add route 'parent' item
				if (
					breadcrumbsConfig.addParentWhenEmpty &&
					!breadcrumbsStateService.breadcrumbs$.value.length
				) {
					if (!breadcrumbs || breadcrumbs.length < 2)
						console.warn(
							'Add parent breadcrumb when list is empty is set, but breadcrumbs are missing.',
							breadcrumbs
						);
					else breadcrumbsStateService.add(breadcrumbs[0]);
				}

				// add route breadcrumb item
				if (breadcrumbs && breadcrumbs.length) {
					const lastCrumb: Breadcrumb = breadcrumbs[breadcrumbs.length - 1];
					breadcrumbsStateService.add({
						...lastCrumb,
						...(lastCrumb.queryParams !== this.route.snapshot.queryParams
							? { queryParams: this.route.snapshot.queryParams }
							: {}),
					});
				}
			});
	}

	private isBreadcrumbsAllowed(breadcrumbsConfig: BreadcrumbsRouteConfig) {
		return (
			breadcrumbsConfig.features &&
			!breadcrumbsConfig.features.every(feature => this.featuresService.isEnabled(feature))
		);
	}

	ngOnDestroy(): void {
		this.legacyShow$.complete();
		this._routeSubscriptions && this._routeSubscriptions.unsubscribe();
	}
}
