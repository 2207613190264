
		<div 
			class="wcd-flex-items-center" 
			[wcdTooltip]="showTooltip? ('incident.overview.scope.fields.riskLevel.tooltip' | i18n) : null"
		>
			<wcd-shared-icon
				*ngIf="priority"
				class="icon small-icon wcd-margin-small-right"
				iconName="warning"
				[ngClass]="{
					'color-text-warning': percentile > 0 && percentile < 90,
					'color-text-warning-dark': percentile >= 90
				}"
			>
			</wcd-shared-icon>
			{{ priorityText || ('common.noDataAvailable' | i18n) }}
		</div>
	