import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';

export enum KnownRequestSource {
	Unspecified,
	PublicApi,
	Portal,
	MCAS,
	TVM,
	CustomDetection,
	BulkAction,
	ISG,
	NDR,
}

@Entity({
	singularName: 'Indicators created by source type',
	pluralName: 'Indicators created by source types',
	values: [
		{
			id: KnownRequestSource.Unspecified,
			name: 'Unspecified',
		},
		{
			id: KnownRequestSource.PublicApi,
			name: 'PublicApi',
		},
		{
			id: KnownRequestSource.Portal,
			name: 'Portal',
		},
		{
			id: KnownRequestSource.MCAS,
			name: 'MCAS',
		},
		{
			id: KnownRequestSource.TVM,
			name: 'TVM',
		},
		{
			id: KnownRequestSource.CustomDetection,
			name: 'CustomDetection',
		},
		{
			id: KnownRequestSource.BulkAction,
			name: 'BulkAction',
		},
		{
			id: KnownRequestSource.ISG,
			name: 'ISG',
		},
		{
			id: KnownRequestSource.NDR,
			name: 'NDR',
		},
	],
})
export class IndicatorsCreateBySourceType extends EntityModelBase<number> {
	@EntityField() name: string;
}
