// tslint:disable:template-click-events-have-key-events
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { PanelContainer } from '@wcd/panels';
import { Router } from '@angular/router';
import { HuntingDocumentationService } from '../services/hunting-documentation.service';

@Component({
	selector: 'hunting-documentation-schema-table-List',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./hunting-documentation-table-reference.panel.component.scss'],
	template: `
		<wcd-panel
			(close)="destroy()"
			[settings]="settings"
			class="wcd-full-height "
			data-track-component="hunting-documentation-schema-table-list-panel"
			data-track-component-type="Side Panel"
		>
			<div *ngIf="loading" class="wcd-padding-large-horizontal wcd-full-height wcd-flex-vertical">
				<accessible-spinner [loadingEntityName]="'entity_header_dialog' | i18n : {'header': 'hunting.documentation.schema.reference.button' | i18n}" spinnerSize="large" labelPosition="right"></accessible-spinner>
			</div>
			<div *ngIf="!loading" class="wcd-padding-large-horizontal wcd-full-height wcd-flex-vertical">
				<header class="wcd-padding-largeMedium-vertical">
					<h3 class="wcd-documentation-tab_title">
						{{ 'hunting.documentation.schema.reference.button' | i18n }}
					</h3>
				</header>

				<div
					class="wcd-flex-horizontal wcd-flex-center-all wcd-padding-small-vertical wcd-margin-medium-bottom wcd-padding-small-left wcd-documentation-tab_input_div">
					<wcd-shared-icon
						iconColor="blue"
						aria-hidden="true"
						iconName="search"
						class="wcd-padding-xsmall-right"
					>
					</wcd-shared-icon>
					<input
						id="schemaReferenceSearchTableList"
						type="text"
						[placeholder]="'hunting.documentation.schema.table.list.search.place.holder' | i18n"
						[attr.aria-label]="'hunting.documentation.schema.table.list.search.place.holder' | i18n"
						class="wcd-documentation-tab_input wcd-flex-1"
						maxlength="100"
						[(ngModel)]="filterTerm"
						(ngModelChange)="searchTermChanged()"
					/>
				</div>
				<div class="wcd-scroll-vertical wcd-flex-vertical">
					<p
						[attr.data-track-id]="'SchemaReference_'+ tableName"
						data-track-type="Button"
						role="button"
						tabindex="0"
						*ngFor="let tableName of tableNamesToShow"
						class="wcd-padding-medium-vertical wcd-border-bottom wcd-padding-small-horizontal wcd-documentation-tab_p"
						(click)="openTableReferenceSidePanel(tableName)"
						(keydown.enter)="openTableReferenceSidePanelKeyboard($event, tableName)"
					>
						{{ tableName }}
					</p>
				</div>
				<div class="wcd-padding-medium-vertical wcd-full-width">
					<a
						type="button"
						data-track-id="fullDocumentationButton"
						data-track-type="Button"
						class="btn-link"
						[href]="huntingDocumentationService.getHelpLink()"
						target="_blank"
					>
						<wcd-shared-icon class="btn-text" iconName="ReadingMode"></wcd-shared-icon>
						{{ 'hunting.documentation.see.full.documentation.button' | i18n }}
					</a>
				</div>
			</div>
		</wcd-panel>
	`,
})
export class HuntingDocumentationSchemaTableListReferencePanelComponent extends PanelContainer
	implements OnInit, AfterViewInit {
	filterTerm: string = null;
	tableNamesToShow: string[];

	@Input() tableNameList: string[];

	loading = true;

	constructor(readonly huntingDocumentationService: HuntingDocumentationService, router: Router, private changeDetectorRef: ChangeDetectorRef) {
		super(router);
	}

	ngOnInit() {
		super.ngOnInit();
		this.tableNamesToShow = this.tableNameList;
	}

	ngAfterViewInit(){
		this.loading=false;
		this.changeDetectorRef.markForCheck();
	}

	searchTermChanged() {
		this.tableNamesToShow = this.tableNameList.filter(value =>
			value.toLowerCase().includes(this.filterTerm.toLowerCase())
		);
	}

	openTableReferenceSidePanelKeyboard($event: KeyboardEvent, tableName: string){
		$event.preventDefault();
		$event.stopPropagation();
		this.openTableReferenceSidePanel(tableName);
	}

	openTableReferenceSidePanel(tableName: string) {
		this.huntingDocumentationService.openDocTableSidePanel(tableName);
	}
}
