/* tslint:disable:template-accessibility-alt-text */
import { ChangeDetectorRef } from '@angular/core';
import { HuntingRule } from '@wcd/domain';
import { of } from 'rxjs';
import { Paris } from '@microsoft/paris';
import { PollingService } from '@wcd/config';
import { mergeMap } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
var RunningStatus = 'running';
var StatusPollingInterval = 30000;
var ScheduledHuntingLastRunStatusComponent = /** @class */ (function () {
    function ScheduledHuntingLastRunStatusComponent(changeDetectorRef, paris, pollService, i18nService) {
        this.changeDetectorRef = changeDetectorRef;
        this.paris = paris;
        this.pollService = pollService;
        this.i18nService = i18nService;
        this.showInPage = false;
    }
    Object.defineProperty(ScheduledHuntingLastRunStatusComponent.prototype, "flexCenterVertical", {
        get: function () {
            return this.showInPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingLastRunStatusComponent.prototype, "rule", {
        get: function () {
            return this._rule;
        },
        set: function (value) {
            this._rule = value;
            if (value && this.isRunning(value.lastRunStatus)) {
                this.startPolling();
            }
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingLastRunStatusComponent.prototype.ngOnDestroy = function () {
        this.stopPolling();
    };
    Object.defineProperty(ScheduledHuntingLastRunStatusComponent.prototype, "status", {
        get: function () {
            return this.rule && this.rule.lastRunStatus;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ScheduledHuntingLastRunStatusComponent.prototype, "tooltip", {
        get: function () {
            return this.status.isError && this.rule.lastRunFailureReason ? this.rule.lastRunFailureReason : '';
        },
        enumerable: true,
        configurable: true
    });
    ScheduledHuntingLastRunStatusComponent.prototype.isRunning = function (status) {
        return status && status.type === RunningStatus;
    };
    ScheduledHuntingLastRunStatusComponent.prototype.getLastRunStatusText = function (withError) {
        if (withError === void 0) { withError = false; }
        var status = this.i18nService.get(this.rule.lastRunStatus.nameI18nKey);
        return withError && this.rule.lastRunStatus.isError && this.rule.lastRunFailureReason ?
            status + ': ' + this.rule.lastRunFailureReason : status;
    };
    ScheduledHuntingLastRunStatusComponent.prototype.startPolling = function () {
        var _this = this;
        if (this.subscription) {
            return;
        }
        this.subscription = this.pollService
            .poll(StatusPollingInterval, StatusPollingInterval)
            .pipe(mergeMap(function () { return _this.rule && _this.rule.id ?
            _this.paris.getItemById(HuntingRule, _this.rule.id, null, { includeLastRun: true })
            : of(null); }))
            .subscribe(function (rule) {
            if (!rule || !_this.isRunning(rule.lastRunStatus)) {
                _this.stopPolling();
            }
            _this._rule = rule;
            _this.changeDetectorRef.markForCheck();
        });
    };
    ScheduledHuntingLastRunStatusComponent.prototype.stopPolling = function () {
        if (this.subscription) {
            this.subscription.unsubscribe();
            this.subscription = undefined;
        }
    };
    return ScheduledHuntingLastRunStatusComponent;
}());
export { ScheduledHuntingLastRunStatusComponent };
