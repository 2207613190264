import { SevilleModule } from '../../seville/seville.module';
import { ERROR_POPUP_TEMPLATE } from '../../../../error/components/error-page.component';
import { CANCEL_CONFIRMATION_TEMPLATE } from './seville.actioncenter.file';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';

SevilleModule.controller('seville.actioncenter.machine', machineActionCenterController);

machineActionCenterController.$inject = [
	'$log',
	'$scope',
	'$http',
	'entity',
	'appConfig',
	'featuresService',
	'$filter',
	'$window',
	'investigationPackageService',
	'$uibModal',
	'appInsights',
];

function machineActionCenterController(
	$log,
	$scope,
	$http,
	entity,
	appConfig,
	featuresService,
	$filter,
	$window,
	investigationPackageService,
	$modal,
	appInsights: AppInsightsService
) {
	var vm = this;
	vm.machine = entity;

	var responseStatusFilter = $filter('response');

	loadMachineStates();

	function loadMachineStates() {
		var versionElements = vm.machine.SenseClientVersion.split('.');
		appInsights.trackEvent('UsageTrack', {
			ButtonType: 'ActionCenter',
			Page: 'Device',
			Component: 'ActionsButton',
		});
		// Check if AV scan feature is enabled
		if (vm.machine.responseFeauresStatus.scan) {
			vm.showAvScan = true;
			vm.loadingScan = true;
		} else {
			vm.showAvScan = false;
			$log.debug('Action center will not show scan');
		}

		// Check if isolation feature is enabled
		if (vm.machine.responseFeauresStatus.isolation) {
			vm.showIsolation = true;
			vm.loadingIsolation = true;
		} else {
			vm.showIsolation = false;
			$log.debug('Action center will not show isolation, since tenant is not flighting the feature');
		}

		// Check if investigation feature is enabled
		if (vm.machine.responseFeauresStatus.forensic) {
			vm.showInvestigation = true;
			vm.loadingInvestigation = true;
		} else {
			vm.showInvestigation = false;
			$log.debug(
				'Action center will not show investigation, since tenant is not flighting the feature'
			);
		}

		// Check if ExecutionPolicy feature is enabled
		if (vm.machine.responseFeauresStatus.executionPolicy) {
			vm.showExecutionRestriction = true;
			vm.loadingExecutionRestriction = true;
		} else {
			vm.showExecutionRestriction = false;
			$log.debug(
				'Action center will not show ExecutionPolicy, since tenant is not flighting the feature'
			);
		}

		var url = appConfig.serviceUrls.userRequests + '/requests/latest';

		var latestPromise = $http.get(url, {
			params: {
				machineId: vm.machine.MachineId,
			},
		});

		handleMachineStateResponse(latestPromise);
	}

	function handleMachineStateResponse(postResponsePromise, openErrorDialog?) {
		postResponsePromise.then(
			function(response) {
				if (response.status == 200 && response.data) {
					if (vm.showAvScan) {
						loadScanState(response.data);
					}

					if (vm.showIsolation) {
						loadIsolateState(response.data);
					}

					if (vm.showInvestigation) {
						loadInvestigationState(response.data);
					}

					if (vm.showExecutionRestriction) {
						loadExecutionPolicyState(response.data);
					}
				} else {
					$log.debug('Machine response state loading failed');

					if (openErrorDialog == true) {
						$modal.open({
							animation: $scope.animationsEnabled,
							template: ERROR_POPUP_TEMPLATE,
							size: 'md',
							windowClass: 'machine-actions-modal',
							backdrop: false,
						});
					}

					vm.loadingScan = false;
					vm.loadingIsolation = false;
					vm.loadingInvestigation = false;
					vm.loadingExecutionRestriction = false;
				}
			},
			function(response) {
				$log.debug('Machine response state loading failed');

				vm.loadingScan = false;
				vm.loadingIsolation = false;
				vm.loadingInvestigation = false;
				vm.loadingExecutionRestriction = false;
			}
		);
	}

	function loadScanState(data) {
		var avScanData = getResponseByType(data, 'ScanResponse');
		if (avScanData) {
			vm.avScan = avScanData;
			vm.avScan.ActionStatus = responseStatusFilter(vm.avScan.RequestStatus, 'Scan');
			if (vm.avScan.RequestStatus == 'Cancelled') {
				vm.machine.ScanState = 'None';
			}
			vm.avScan.cancel = cancelScan;
			vm.avScan.DisplayCancelButton = vm.avScan.RequestStatus == 'Submitted';
			fillAvScanCommentDescriptions();
		}
		vm.loadingScan = false;
	}

	function loadIsolateState(data) {
		var isolationData = getResponseByType(data, 'IsolationResponse');
		if (isolationData) {
			vm.isolation = isolationData;
			vm.isolation.ActionStatus = responseStatusFilter(
				vm.isolation.RequestStatus,
				'Isolation',
				vm.isolation.Action
			);
			if (vm.isolation.RequestStatus == 'Cancelled') {
				vm.machine.IsolationStatus =
					vm.isolation.Action == 1 ? 'RequestCompleted' : 'ReverseRequestCompleted';
			}
			vm.isolation.cancel = cancelIsolation;
			vm.isolation.DisplayCancelButton = vm.isolation.RequestStatus == 'Submitted';
			fillIsolationCommentDescriptions();
		}
		vm.loadingIsolation = false;
	}

	function loadInvestigationState(data) {
		var investigationData = getResponseByType(data, 'ForensicsResponse');
		if (investigationData) {
			vm.investigation = investigationData;
			if (vm.investigation.RequestStatus == 'Succeeded') {
				vm.investigation.refreshUriAndDownloadPackage = refreshUriAndDownloadPackage;

				refreshUriAndDownloadPackage(false);
			} else {
				if (vm.investigation.RequestStatus == 'Cancelled') {
					vm.machine.ForensicStatus = 'None';
				}
				vm.investigation.DisplayCancelButton = vm.investigation.RequestStatus == 'Submitted';
				vm.loadingInvestigation = false;
			}

			vm.investigation.ActionStatus = responseStatusFilter(vm.investigation.RequestStatus, 'Forensic');
			vm.investigation.cancel = cancelInvestigationPackage;
		}
		vm.loadingInvestigation = false;
	}

	function loadExecutionPolicyState(data) {
		var executionRestrictionData = getResponseByType(data, 'RestrictExecutionResponse');
		if (executionRestrictionData) {
			vm.executionRestriction = executionRestrictionData;
			vm.executionRestriction.ActionStatus = responseStatusFilter(
				vm.executionRestriction.RequestStatus,
				'ExecutionRestriction',
				vm.executionRestriction.PolicyType
			);
			if (vm.executionRestriction.RequestStatus == 'Cancelled') {
				vm.machine.ExecutionPolicyState =
					vm.executionRestriction.PolicyType == 0 ? 'ReverseRequestCompleted' : 'RequestCompleted';
			}

			vm.executionRestriction.cancel = cancelExecutionRestriction;
			vm.executionRestriction.DisplayCancelButton =
				vm.executionRestriction.RequestStatus == 'Submitted';
			fillRestrictExecutionCommentDescriptions();
		}
		vm.loadingExecutionRestriction = false;
	}

	function getResponseByType(data, responseType) {
		if (Array.isArray(data)) {
			return data.filter(function(r) {
				return r.Type === responseType;
			})[0];
		}

		return data.Type == responseType ? data : null;
	}

	function fillIsolationCommentDescriptions() {
		var responseAction = vm.isolation.Action == 0 ? 'Device isolation' : 'Release from isolation';
		vm.isolation.actionDescription = responseAction + ' submitted';

		if (vm.isolation.Action == 0 && vm.isolation.IsolationType == 1) {
			vm.isolation.actionDescription += ' with Outlook & Skype exception';
		}

		vm.isolation.cancelactionDescription = responseAction + ' canceled';
	}

	function fillAvScanCommentDescriptions() {
		var responseAction = (vm.avScan.ScanType == 0 ? 'Quick' : 'Full') + ' antivirus scan';

		vm.avScan.actionDescription = responseAction + ' submitted';
		vm.avScan.cancelactionDescription = responseAction + ' canceled';
	}

	function fillRestrictExecutionCommentDescriptions() {
		var responseAction =
			vm.executionRestriction.PolicyType == 0
				? 'Code execution restriction'
				: 'Code execution restriction removal';
		vm.executionRestriction.actionDescription = responseAction + ' submitted';
		vm.executionRestriction.cancelactionDescription = responseAction + ' canceled';
	}

	function refreshUriAndDownloadPackage(downloadPackage) {
		investigationPackageService
			.loadInvestigationPackageUri(vm.investigation.RequestGuid, vm.machine.ComputerDnsName)
			.then(
				function(packageUri) {
					vm.investigation.packageUri = packageUri;

					if (downloadPackage == true && vm.investigation.packageUri) {
						$log.debug('Opening a new tab to start download a package...');
						$window.open(vm.investigation.packageUri, '_blank');
					}

					if (vm.investigation.RequestStatus == 'Succeeded' && !vm.investigation.packageUri) {
						vm.investigation.ActionStatus = 'Package expired';
					}

					vm.loadingInvestigation = false;
				},
				function(packageUri) {
					delete vm.investigation.packageUri;
					vm.investigation.ActionStatus = 'Package expired';
					vm.loadingInvestigation = false;
				}
			);
	}

	function cancelIsolation() {
		var additionalParams = {
			Action: vm.isolation.Action,
		};

		cancelResponse(
			vm.isolation.Action,
			vm.isolation.Action == 1 ? 'release from isolation' : 'isolate',
			'IsolationRequest',
			function(isLoading) {
				vm.loadingIsolation = isLoading;
			},
			additionalParams
		);
	}

	function cancelInvestigationPackage() {
		cancelResponse(
			'Investigation Package',
			'collection of investigation package',
			'ForensicsRequest',
			function(isLoading) {
				vm.loadingInvestigation = isLoading;
			}
		);
	}

	function cancelScan() {
		cancelResponse('Scan', 'antivirus scan', 'ScanRequest', function(isLoading) {
			vm.loadingScan = isLoading;
		});
	}

	function cancelExecutionRestriction() {
		var additionalParams = {
			PolicyType: vm.executionRestriction.PolicyType,
		};

		cancelResponse(
			'Execution Restriction - ' +
				(vm.executionRestriction.PolicyType == 1 ? 'Unrestrict' : 'Restrict'),
			vm.executionRestriction.PolicyType == 1 ? 'removal of app restrictions' : 'app restriction',
			'RestrictExecutionRequest',
			function(isLoading) {
				vm.loadingExecutionRestriction = isLoading;
			},
			additionalParams
		);
	}

	function cancelResponse(
		actionName,
		actionDescription,
		requestType,
		setIsLoadingCallback,
		additionalParams?
	) {
		if (vm.posting) return;

		vm.posting = true;
		$log.debug('Canceling - Action: ' + actionName + ', MachineId: ' + vm.machine.SenseMachineId);

		var modalInstance = $modal.open({
			animation: $scope.animationsEnabled,
			template: CANCEL_CONFIRMATION_TEMPLATE,
			windowClass: 'machine-actions-modal',
			controller: 'seville.actioncenter.response.cancel.confirmation',
			backdrop: false,
			resolve: {
				entity: function() {
					return vm.machine;
				},
				action: function() {
					return actionDescription;
				},
				openActionCenterPanel: function() {
					return false;
				},
			},
		});

		modalInstance.result.then(
			function(result) {
				if (result == true) {
					if (setIsLoadingCallback) {
						setIsLoadingCallback(true);
					}

					var params: any = {};

					if (additionalParams) {
						params = additionalParams;
					}

					params.MachineId = vm.machine.SenseMachineId;
					params.Type = requestType;
					params.RequestorComment = vm.machine.commentText;
					params.OsPlatform = vm.machine.OsPlatform;
					params.SenseClientVersion = vm.machine.SenseClientVersion;

					var cancelPromise = $http.post(
						appConfig.serviceUrls.userRequests + '/requests/cancel',
						params
					);

					handleMachineStateResponse(cancelPromise, true);
				}
				vm.machine.commentText = '';
			},
			function() {
				if (setIsLoadingCallback) {
					setIsLoadingCallback(false);
				}

				vm.machine.commentText = '';
			}
		);

		vm.posting = false;
	}
}

export const MACHINE_TEMPLATE: string = `
<div>
    <span rbacjs state="'disabled'" permissions="['alertsInvestigation']" >
      <action-status ng-if="actioncentermachine.showInvestigation" results="actioncentermachine.investigation" header="'Investigation package collection'" loading="actioncentermachine.loadingInvestigation" summary="true" action-description="'Package collection submitted'" cancel-action-description="'Package collection canceled'" cancel-button-title="'Cancel action'"></action-status>
      <div ng-if="actioncentermachine.showInvestigation" class="action-center-action-section-border" />
    </span>
    <span rbacjs state="'disabled'" permissions="['alertsInvestigation']" >
      <action-status ng-if="actioncentermachine.showAvScan" results="actioncentermachine.avScan" header="'Antivirus scan'" loading="actioncentermachine.loadingScan" summary="true" action-description="actioncentermachine.avScan.actionDescription" cancel-action-description="actioncentermachine.avScan.cancelactionDescription" cancel-button-title="'Cancel action'"></action-status>
      <div ng-if="actioncentermachine.showAvScan" class="action-center-action-section-border" />
    </span>
    <span rbacjs state="'disabled'" permissions="['remediationActions']" >
      <action-status ng-if="actioncentermachine.showExecutionRestriction" results="actioncentermachine.executionRestriction" header="'App restriction'" loading="actioncentermachine.loadingExecutionRestriction" summary="true" action-description="actioncentermachine.executionRestriction.actionDescription" cancel-action-description="actioncentermachine.executionRestriction.cancelactionDescription" cancel-button-title="'Cancel action'"></action-status>
      <div ng-if="actioncentermachine.showExecutionRestriction" class="action-center-action-section-border" />
    </span>
    <span rbacjs state="'disabled'" permissions="['remediationActions']" >
      <action-status ng-if="actioncentermachine.showIsolation" results="actioncentermachine.isolation" header="'Device isolation'" loading="actioncentermachine.loadingIsolation" summary="true" action-description="actioncentermachine.isolation.actionDescription" cancel-action-description="actioncentermachine.isolation.cancelactionDescription" cancel-button-title="'Cancel action'"></action-status>
    </span>
</div>
<div class="action-center-bottom">
    <div class="action-center-info">
        <i class="icon icon-Info"></i>
        <span>For submitted actions to take effect, device must be connected to the network.</span>
    </div>
    <button class="btn btn-primary primary-button" ng-click="$close()">Close</button>
</div>

`;
