import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ChangeDetectorRef, EventEmitter, OnChanges, } from '@angular/core';
import { I18nService } from '@wcd/i18n';
var SimplePaginationComponent = /** @class */ (function () {
    function SimplePaginationComponent(changeDetectorRef, i18nService, liveAnnouncer) {
        this.changeDetectorRef = changeDetectorRef;
        this.i18nService = i18nService;
        this.liveAnnouncer = liveAnnouncer;
        this.shouldShowPageNumbers = false;
        this.showOnSinglePage = false;
        this.pageChange = new EventEmitter();
        this.ariaPagePrevIndex = -1;
        this.ariaPageNextIndex = 1;
    }
    Object.defineProperty(SimplePaginationComponent.prototype, "showCount", {
        get: function () {
            return !isNaN(this.total);
        },
        enumerable: true,
        configurable: true
    });
    SimplePaginationComponent.prototype.ngOnChanges = function () {
        this.update();
        this.announcePageUpdated(this.i18nService.get('simplePagination_item_range_page') + " " + this.firstIndex + " - " + this.lastIndex + " " + (this.total ? this.i18nService.get('simplePagination_of') : '') + " " + (this.total ? this.total : ''));
    };
    SimplePaginationComponent.prototype.next = function () {
        if (this.page === this.pageCount) {
            return;
        }
        this.page = this.page + 1;
        this.notifyPageChange();
        this.update();
    };
    SimplePaginationComponent.prototype.prev = function () {
        this.page = this.page - 1;
        this.notifyPageChange();
        this.update();
    };
    SimplePaginationComponent.prototype.notifyPageChange = function () {
        this.pageChange.emit({ page: this.page });
        this.announcePageUpdated(this.i18nService.get('simplePagination_loading_page_items'));
    };
    SimplePaginationComponent.prototype.update = function () {
        this.page = this.page || 1;
        this.pageCount = this.total ? Math.ceil(this.total / this.pageSize) : null;
        this.isLastPage = this.pageCount
            ? this.page === this.pageCount
            : this.currentPageCount < this.pageSize;
        this.firstIndex = (this.page - 1) * this.pageSize + 1;
        this.lastIndex = this.firstIndex + this.currentPageCount - 1;
        this.changeDetectorRef.markForCheck();
    };
    SimplePaginationComponent.prototype.calculatePagesNavigationAriaLabel = function (buttonNameKey, buttonIndex) {
        var translatedButtonName = this.i18nService.get(buttonNameKey);
        var page = this.page + buttonIndex;
        var firstIndex = (page - 1) * this.pageSize + 1;
        var isNextPageLast = this.pageCount && page === this.pageCount;
        var lastIndex = isNextPageLast ? this.total : firstIndex + this.currentPageCount - 1;
        if (firstIndex < 0 || lastIndex > this.total) {
            return translatedButtonName;
        }
        return translatedButtonName + " " + firstIndex + " - " + lastIndex + " " + this.i18nService.strings.simplePagination_of + " " + this.total;
    };
    SimplePaginationComponent.prototype.shouldShowRange = function () {
        if (isNaN(this.lastIndex) || !this.lastIndex) {
            return false;
        }
        return this.showOnSinglePage || this.pageCount !== 1;
    };
    SimplePaginationComponent.prototype.announcePageUpdated = function (announcement) {
        this.liveAnnouncer.announce(announcement, 'assertive', 300);
    };
    return SimplePaginationComponent;
}());
export { SimplePaginationComponent };
