import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { SoftwareInventoryEntityTypeService } from './services/software-inventory.entity-type.service';
import { SoftwareInventoryEntityPanelComponent } from './components/software-inventory.entity-panel.component';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SoftwareFieldsService } from './services/software.fields.service';
import { SoftwareVersionFieldsService } from './services/software-version.fields.service';
import { SoftwareInstallationFieldsService } from './services/software-installation.fields.service';
import { SoftwareCommonService } from './services/software.common.service';
import { MissingKbFieldsService } from './services/missing-kb.fields.service';
import { TvmModule } from '../../../tvm/tvm.module';
import { ThreatInfoService } from './services/threat-info.service';

@NgModule({
	imports: [SharedModule, GlobalEntitiesModule, GlobalEntityPanelsModule, DataViewsModule, TvmModule],
	declarations: [SoftwareInventoryEntityPanelComponent],
	exports: [SoftwareInventoryEntityPanelComponent],
	entryComponents: [SoftwareInventoryEntityPanelComponent],
	providers: [
		SoftwareCommonService,
		SoftwareFieldsService,
		SoftwareVersionFieldsService,
		SoftwareInstallationFieldsService,
		SoftwareInventoryEntityTypeService,
		MissingKbFieldsService,
		ThreatInfoService,
	],
})
export class SoftwareInventoryEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(SoftwareInventoryEntityTypeService);
	}
}
