/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./suppression-rule.entity-details.component.ngfactory";
import * as i2 from "./suppression-rule.entity-details.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../../global_entities/services/global-entity-types.service";
import * as i5 from "../../../dialogs/services/dialogs.service";
import * as i6 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i7 from "../services/suppression-rules.backend.service";
import * as i8 from "../../../insights/services/app-insights.service";
import * as i9 from "../../../mtp-promotion/services/mtp-promotion.service";
import * as i10 from "./suppression-rule.entity-panel.component";
var styles_SuppressionRuleEntityPanelComponent = [];
var RenderType_SuppressionRuleEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuppressionRuleEntityPanelComponent, data: {} });
export { RenderType_SuppressionRuleEntityPanelComponent as RenderType_SuppressionRuleEntityPanelComponent };
export function View_SuppressionRuleEntityPanelComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵeld(1, 0, null, null, 7, "div", [["class", "wcd-full-height"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(3, 0, null, null, 4, "section", [["class", "wcd-padding-vertical"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i0.ɵeld(5, 0, null, null, 1, "suppression-rule-entity-details", [], null, null, null, i1.View_SuppressionRuleEntityDetailsComponent_0, i1.RenderType_SuppressionRuleEntityDetailsComponent)), i0.ɵdid(6, 114688, null, 0, i2.SuppressionRuleEntityDetailsComponent, [i0.Injector, i3.Paris, i0.ChangeDetectorRef, i4.GlobalEntityTypesService, i5.DialogsService, i6.I18nService, i7.SuppressionRulesBackendService, i8.AppInsightsService, i9.MtpPromotionService], { entity: [0, "entity"], isSuppressionRuleEntityPanel: [1, "isSuppressionRuleEntityPanel"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.rule; var currVal_1 = true; _ck(_v, 6, 0, currVal_0, currVal_1); }, null); }
export function View_SuppressionRuleEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "suppression-rule-entity-panel", [], null, null, null, View_SuppressionRuleEntityPanelComponent_0, RenderType_SuppressionRuleEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i10.SuppressionRuleEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppressionRuleEntityPanelComponentNgFactory = i0.ɵccf("suppression-rule-entity-panel", i10.SuppressionRuleEntityPanelComponent, View_SuppressionRuleEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { SuppressionRuleEntityPanelComponentNgFactory as SuppressionRuleEntityPanelComponentNgFactory };
