<ng-container>
	<div class="wcd-flex-1 wcd-border-all wcd-flex-vertical wcd-multi-select-item-list">
		<div class="wcd-flex-none wcd-border-bottom wcd-padding-small-all wcd-margin-none">
			{{selectedDescription | i18n}} ({{selected.length}})
		</div>
		<div class="subtle wcd-padding-all center-text wcd-full-height wcd-flex-center-all"
			 style="white-space: pre-line"
			 *ngIf="!selected?.length; else selectedChecklist">
			{{noSelectedItemsPlaceholder | i18n}}
		</div>
		<ng-template #selectedChecklist>
			<div class="wcd-flex-1 wcd-scroll-all wcd-padding-small-all">
				<wcd-checklist
				   [values]="selected"
				   [selectById]="false"
				   [rbac]="{ permissions: requiredAllowedActions, state: 'disabled' }"
				   [isDisabled]="isDisabled"
				   name="selected"
				   helpProperty="helpKey"
				   [ngModel]="chosenFromSelected"
				   (ngModelChange)="setChosenFromSelected($event)"
				   [ariaLabel]="(selectedDescription | i18n ) + ' ' + selected.length"
				   [listRole]="'listbox'"
				   [itemListRole]="'option'"
				></wcd-checklist>
			</div>
		</ng-template>
	</div>
	<ng-container *ngIf="canEdit">
		<div class="wcd-flex-none wcd-padding-vertical wcd-flex-horizontal wcd-multi-select-button">
			<fab-primary-button	contentClass="wcd-flex-1 wcd-multi-select-responsive-margin-small-right"
								[styles]="commandButtonStyles"
								(onClick)="onRemoveChosen.emit()"
								data-track-id="RemoveSelected"
								data-track-type="Button"
								[disabled]="hasError || !chosenFromSelected?.length || isDisabled">
				<wcd-shared-icon iconName="arrow.down" class="ms-icon-top-2-fix" [ariaLabel]="''"></wcd-shared-icon>
				{{removeButtonText | i18n}}
			</fab-primary-button>
			<fab-primary-button contentClass="wcd-flex-1 wcd-multi-select-responsive-margin-small-left"
								[styles]="commandButtonStyles"
								(onClick)="onAddChosen.emit()"
								data-track-id="AddSelectedGroups"
								data-track-type="Button"
								[disabled]="hasError || !chosenFromAvailable?.length || isDisabled">
				<wcd-shared-icon iconName="arrow.up" class="ms-icon-top-2-fix" [ariaLabel]="''"></wcd-shared-icon>
				{{addButtonText | i18n}}
			</fab-primary-button>
		</div>
		<div class="wcd-flex-1 wcd-border-all wcd-flex-vertical wcd-multi-select-item-list">
			<div class="wcd-border-bottom wcd-padding-small-all wcd-flex-horizontal">
				<div class="wcd-flex-1 wcd-input-placeholder">
					<input type="text"
						   class="form-control"
						   [(ngModel)]="filter"
						   [disabled]="hasError || isLoadingAvailableItems || isDisabled"
						   (keydown.enter)="shouldApplyFilter ? applyFilter() : clearFilter()"
						   name="items-filter"
						   [rbac]="{ permissions: requiredAllowedActions, state: 'hidden' }"
						   placeholder="{{filterPlaceholder | i18n}}"
						   [attr.aria-label]="filterPlaceholder | i18n"
						    />
				</div>
				<div class="wcd-flex-none wcd-padding-small-left">
					<button class="btn btn-secondary-primary"
							(click)="shouldApplyFilter ? applyFilter() : clearFilter()"
							[disabled]="isDisabled || hasError || isLoadingAvailableItems || !appliedFilter && !filter"
							data-track-id="ApplyUserRoleAadGroupFilter"
							data-track-type="Button"
							type="button">
						{{(shouldApplyFilter ? 'common.filter' : 'common.clearFilter') | i18n}}
					</button>
				</div>
			</div>
			<div class="wcd-flex-1 wcd-scroll-all" [ngClass]="virtualScrollAvailableItems ? '' :  'wcd-padding-small-all'">
				<div *ngIf="isLoadingAvailableItems; else itemsContents" class="wcd-full-height wcd-flex-center-all">
					<i class="loader-icon"></i> {{'common_loading' | i18n}}
				</div>
				<ng-template #itemsContents>
					<p class="wcd-padding-all wcd-flex-center-all error-message" *ngIf="hasError; else availableItemsChecklist">Something went wrong</p>
					<ng-template #availableItemsChecklist>
						<div class="wcd-full-height wcd-flex-center-all empty-contents center-text wcd-padding-all"
							 *ngIf="!available?.length; else availableItemsChecklistContents">
							<div>
								{{(appliedFilter ? notFoundForFilterText : notFoundText) | i18n:{ filter: appliedFilter} }}
							</div>
						</div>
						<ng-template #availableItemsChecklistContents>
							<wcd-checklist
								[values]="available"
								[ariaLabel]="(selectedDescription | i18n ) + ' ' + selected.length"
								[withVirtualScroll]="virtualScrollAvailableItems"
								[selectById]="false"
								[rbac]="{ permissions: requiredAllowedActions, state: 'disabled' }"
								name="available"
								[isDisabled]="isDisabled"
								[ngModel]="chosenFromAvailable"
								(ngModelChange)="setChosenFromAvailable($event)"
								[listRole]="'listbox'"
								[itemListRole]="'option'"
							></wcd-checklist>
						</ng-template>
					</ng-template>
				</ng-template>
			</div>
		</div>
	</ng-container>
</ng-container>
