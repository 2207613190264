import { ModelBase, ValueObject, EntityField } from '@microsoft/paris';

@ValueObject({
	singularName: 'VulnerabilitySourceInfo',
	pluralName: 'VulnerabilitySourceInfo',
})
export class VulnerabilitySourceInfo extends ModelBase {
	@EntityField() readonly source?: string;

	@EntityField() readonly url?: string;
}
