var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
import { TvmMcmSharedEntityConfigurations } from '../../mcmEndPointUtils';
var ɵ0 = function (dataQuery) {
    var params = {
        benchmarkName: dataQuery.where["benchmarkName"],
        benchmarkVersion: dataQuery.where["benchmarkVersion"],
        complianceLevel: dataQuery.where["complianceLevel"]
    };
    if (dataQuery && dataQuery.where && dataQuery.where.search) {
        Object.assign(params, { search: dataQuery.where.search });
    }
    return params;
}, ɵ1 = function (category) { return "category-" + category; };
var BaselineConfigurationByCategory = /** @class */ (function (_super) {
    __extends(BaselineConfigurationByCategory, _super);
    function BaselineConfigurationByCategory() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationByCategory.prototype, "category", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineConfigurationByCategory.prototype, "count", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineConfigurationByCategory.prototype, "configurations", void 0);
    __decorate([
        EntityField({ data: 'category', parse: ɵ1 }),
        __metadata("design:type", String)
    ], BaselineConfigurationByCategory.prototype, "id", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationByCategory.prototype, "title", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineConfigurationByCategory.prototype, "complianceLevels", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], BaselineConfigurationByCategory.prototype, "scored", void 0);
    BaselineConfigurationByCategory = __decorate([
        Entity(__assign({}, TvmMcmSharedEntityConfigurations, { singularName: 'Configuration', pluralName: 'Configurations', endpoint: 'baseline-profiles/configurationsByCategory', readonly: true, parseDataQuery: ɵ0 }))
    ], BaselineConfigurationByCategory);
    return BaselineConfigurationByCategory;
}(EntityModelBase));
export { BaselineConfigurationByCategory };
export { ɵ0, ɵ1 };
