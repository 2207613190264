import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ChangeDetectorRef,
	ViewEncapsulation,
	OnChanges,
} from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { DataviewAction, DataviewActionTypes } from './dataview-actions.model';
import { DataViewModel } from '../../models/dataview.model';
import { config, ScreenWidthBreakpoints } from '@wcd/shared';
import { Positions } from '@wcd/forms';
import { MainAppState, MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { filter } from 'rxjs/operators';

const MAX_ACTIONS_VISIBLE_BIG_SCREENS = 7;
const MAX_ACTIONS_VISIBLE_SMALL_SCREENS = 3;

@Component({
	selector: 'dataview-actions',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['./dataview-actions.component.scss'],
	template: `
		<ng-container>
			<ng-container *ngFor="let actionWithStatus of actionsWithStatus">
				<dataview-action-wrapper
					*ngIf="actionWithStatus.action.actionType != dataviewActionTypes.Pagination"
					[actionClass]="getActionClass(false, actionWithStatus.isUnderMoreActionsIcon)"
					[action]="actionWithStatus.action"
					[position]="positions.Default"
					[isMoreActionsList]="false"
					[smallScreenView]="smallScreenView"
					[actionType]="actionWithStatus.action.actionType"
					[dataViewModel]="dataViewModel"
				></dataview-action-wrapper>

				<dataview-action-pagination
					[hidden]="false"
					[ngClass]="'dataview-action'"
					[dataViewModel]="dataViewModel"
					[paginationActionConfig]="actionWithStatus.action"
					[shouldShowPageNumbers] ="shouldShowPageNumbersOnPagination"
					*ngIf="actionWithStatus.action.actionType == dataviewActionTypes.Pagination"
				></dataview-action-pagination>

			</ng-container>
			<wcd-dropdown
				[hidden]="!showMoreActionsDropdown"
				[closeOnMouseUp]="false"
				class="dataview-action"
				[buttonIcon]="'More'"
				[showIconDropdown]="false"
				[closeOnClick]="false"
				[ariaLabel]="'entityCommon_commandBar_moreOptions' | i18n"
				[buttonText]="'entityCommon_commandBar_moreOptions' | i18n"
				ariaRole="listbox"
				optionAriaRole="option"
				[allowFocusOnMenu]="null"
				[iconButtonOnly]="true"
				[focusHelper]="focusButton"
				[fixDropdownItems]="fixDropdownItems"
			>
				<div class="dataview-more-actions">
					<ng-container *ngFor="let actionWithStatus of actionsWithStatus">
						<ng-container
							*ngIf="
								!isActionHidden(true, actionWithStatus.isUnderMoreActionsIcon) &&
								(actionWithStatus.action.actionType == dataviewActionTypes.Button ||
								actionWithStatus.action.actionType == dataviewActionTypes.ColumnsCustomization ||
								actionWithStatus.action.actionType == dataviewActionTypes.FancySelect ||
								(actionWithStatus.action.actionType == dataviewActionTypes.Filter && dataViewModel.allowFilters));
								else noAccessibleListItem
							"
							>
							<dataview-action-wrapper #accessibleListItem
								[actionClass]="getActionClass(true, actionWithStatus.isUnderMoreActionsIcon)"
								[action]="actionWithStatus.action"
								[position]=" isRightSideActionBar
												? positions.Left
												: positions.Right"
								[isMoreActionsList]="true"
								[smallScreenView]="false"
								[actionType]="actionWithStatus.action.actionType"
								[dataViewModel]="dataViewModel"
							></dataview-action-wrapper>
						</ng-container>

						<ng-template #noAccessibleListItem>
							<ng-container *ngIf="actionWithStatus.action.actionType == dataviewActionTypes.Pagination">
								<dataview-action-wrapper
									[actionClass]="getActionClass(true, actionWithStatus.isUnderMoreActionsIcon)"
									[action]="actionWithStatus.action"
									[position]=" isRightSideActionBar
													? positions.Left
													: positions.Right"
									[isMoreActionsList]="true"
									[smallScreenView]="false"
									[actionType]="actionWithStatus.action.actionType"
									[dataViewModel]="dataViewModel"
								></dataview-action-wrapper>
							</ng-container>
						</ng-template>
					</ng-container>
				</div>
			</wcd-dropdown>
		</ng-container>
	`,
})
export class DataviewActionsComponent implements OnChanges {
	@Input() set actions(actionsInput: DataviewAction[]) {
		if (!this.actionsWithStatus.length) {
			this.actionsWithStatus = actionsInput.map(currentAction => {
				return { action: currentAction, isUnderMoreActionsIcon: false };
			});
		}
	}

	@Input() dataViewModel: DataViewModel;
	@Input() isRightSideActionBar: boolean;
	@Input() shouldShowPageNumbersOnPagination: boolean = false;

	positions = Positions;
	smallScreenView: boolean = false;
	dataviewActionTypes = DataviewActionTypes;
	actionsWithStatus: { action: DataviewAction; isUnderMoreActionsIcon: boolean }[] = [];
	showMoreActionsDropdown: boolean = false;
	currentMaxWidthBreakpoint: keyof ScreenWidthBreakpoints;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
		public i18nService: I18nService,
		mainAppStateService: MainAppStateService
	) {
		mainAppStateService.state$
			.pipe(
				filter((state: MainAppState) => {
					return this.currentMaxWidthBreakpoint !== state.pageContentMaxWidthBreakpoint;
				})
			)
			.subscribe((state: MainAppState) => {
				this.currentMaxWidthBreakpoint = state.pageContentMaxWidthBreakpoint;
				this.calculateRepsonsiveActions();
				this.changeDetectorRef.markForCheck();
			});
	}

	ngOnChanges() {
		this.calculateRepsonsiveActions();
	}

	ngDoCheck() {
		this.changeDetectorRef.markForCheck();
	}

	fixDropdownItems(items){
		return items.map(item=>{
			return item.dropdownButtonRef
		});
	}

	focusButton(element) {
		return element.dropdownButtonRef
	}

	isActionHidden(isMoreActionsList: boolean, isUnderMoreActionsIcon: boolean) {
		return (
			(isMoreActionsList && !isUnderMoreActionsIcon) || (!isMoreActionsList && isUnderMoreActionsIcon)
		);
	}

	getActionClass(isMoreActionsList: boolean, isUnderMoreActionsIcon: boolean) {
		return this.isActionHidden(isMoreActionsList, isUnderMoreActionsIcon)
			? 'dataview-action-hidden'
			: 'dataview-action';
	}

	calculateRepsonsiveActions() {
		this.setLabelsVisibility();
		this.buildResponsiveActionsLists();
	}

	setLabelsVisibility() {
		if (
			this.isRightSideActionBar &&
			config.widthBreakpointSmallerThan(
				this.currentMaxWidthBreakpoint,
				config.msScreenSizeBreakpoints.xl
			)
		) {
			this.smallScreenView = true;
		} else {
			this.smallScreenView = config.widthBreakpointSmallerThan(
				this.currentMaxWidthBreakpoint,
				config.msScreenSizeBreakpoints.medium
			);
		}
	}

	buildResponsiveActionsLists() {
		const matchSmall = config.widthBreakpointSmallerThan(
			this.currentMaxWidthBreakpoint,
			config.msScreenSizeBreakpoints.small
		);

		let maxItemsVisible = matchSmall
			? MAX_ACTIONS_VISIBLE_SMALL_SCREENS
			: MAX_ACTIONS_VISIBLE_BIG_SCREENS;

		const showPagination = this.actionsWithStatus.find(
			action => action.action.actionType === DataviewActionTypes.Pagination
		);
		if (showPagination) {
			// Pagination should be counted as 2 actions.
			// note that it is always a visible action since it should always be on a right command bar as the left most action.
			maxItemsVisible = maxItemsVisible - 1;
		}

		this.showMoreActionsDropdown = this.actionsWithStatus.length > maxItemsVisible;
		this.actionsWithStatus.forEach((action, index) => {
			if (this.showMoreActionsDropdown && index + 1 >= maxItemsVisible) {
				action.isUnderMoreActionsIcon = true;
			} else {
				action.isUnderMoreActionsIcon = false;
			}
		});
	}
}
