import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import {
	File,
	FileFileProtectionInfoRelationship,
	FileInstance,
	FileProtectionInfo,
	Tag,
	FileQuarantineActions,
	FileQuarantineActionsRelationship,
	CustomTiIndicatorsType, CustomTiIndicatorsTypes,
} from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Paris } from '@microsoft/paris';
import { shareReplay, take } from 'rxjs/operators';
import { TagsService } from '../../../tags/services/tags.service';
import { Feature, FeaturesService } from '@wcd/config';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { merge, Observable, Subject } from 'rxjs';
import { toObservable } from '../../../utils/rxjs/utils';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityType } from '../../models/entity-type.interface';
import { MessageBarType } from 'office-ui-fabric-react';
import { FileEntityTypeService } from '../../../@entities/files/services/file.entity-type.service';

enum CollapsibleID {
	Tags = 'file-entity-details-tags',
	Details = 'file-entity-details-information',
	ProtectionInformation = 'file-entity-details-protection-information',
	PeMetadata = 'file-pe-metadata'
}

@Component({
	selector: 'file-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './file.entity-details.component.html',
})
export class FileEntityDetailsComponent extends EntityDetailsComponentBase<File> implements OnInit {
	readonly fileInstanceType = FileInstance;
	readonly MessageBarType = MessageBarType;
	entityType: EntityType<File>;
	tags$: Observable<ReadonlyArray<Tag>>;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();
	collapsibleID = CollapsibleID;
	quarantineActionStatus;
	customIndicatorData;
	fileInstance: FileInstance;
	isNewFilePageEnabled;


	@Input() showFileInstanceDetails: boolean = true;

	ngOnInit() {
		this.setEntity(this.entity);
		this.isNewFilePageEnabled = this.featuresService.isEnabled(Feature.NewFilePage);
		this.getQuarantineActionStatus();
		this.getCustomIndicatorData();
	}

	get file(): File {
		return this.entity;
	}

	get sensitivityTag(): Tag {
		return this.file.sensitivity
			? this.tagsService.getDataSensitivityTag(
					this.file.sensitivity,
					this.i18nService.get('files.sensitivity.tag.tooltip'),
					false
			  )
			: null;
	}

	isWindowsInfoProtectionApplied: boolean;

	isAzureInfoProtectionApplied: boolean;

	get isDataSensitivityEnabled(): boolean {
		return this.featuresService.isEnabled(Feature.FileSensitivity);
	}

	get showFileVerdict(): boolean {
		return (
			this.file &&
			this.file.sha1 && // File verdict doesn't support SHA256
			this.mode === EntityDetailsMode.EntityPage &&
			this.featuresService.isEnabled(Feature.FileVerdict)
		);
	}

	constructor(
		injector: Injector,
		private paris: Paris,
		private changeDetector: ChangeDetectorRef,
		private tagsService: TagsService,
		private featuresService: FeaturesService,
		private i18nService: I18nService,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private fileEntityTypeService: FileEntityTypeService
	) {
		super(injector);
	}

	isFileInstance(file: File): file is FileInstance {
		return file instanceof FileInstance;
	}

	setEntity(entity) {
		super.setEntity(entity);
		this.entityType = this.globalEntityTypesService.getEntityType(entity.constructor) ||
			this.globalEntityTypesService.getEntityType(entity.entityType);

		if (this.isDataSensitivityEnabled) {
			if (this.isFileInstance(this.file)) {
				// file instance has these properties set
				this.fileInstance = this.file;
				this.isAzureInfoProtectionApplied = this.file.isAzureInfoProtectionApplied;
				this.isWindowsInfoProtectionApplied = this.file.isWindowsInfoProtectionApplied;
			} else {
				// fetch the latest status for the file
				this.isAzureInfoProtectionApplied = this.isWindowsInfoProtectionApplied = null;
				this.paris
					.getRelatedItem<File, FileProtectionInfo>(FileFileProtectionInfoRelationship, this.file)
					.pipe(take(1))
					.subscribe((info: FileProtectionInfo) => {
						//isWindowsInfoProtectionApplied in applicable only for a particular file instance
						this.isAzureInfoProtectionApplied = info.isAzureInfoProtectionApplied;
						this.changeDetector.markForCheck();
					});
			}
		}

		this.tags$ = merge(toObservable(this.entityType.getTags([this.entity])), this._tagsSubject$).pipe(
			shareReplay(1)
		);
		this.changeDetector.markForCheck();
	}

	getQuarantineActionStatus() {
		this.paris
			.getRelatedItem<File, FileQuarantineActions>(
				FileQuarantineActionsRelationship,
				this.file
			)
			.subscribe(data => this.quarantineActionStatus = data.request)
	}

	getCustomIndicatorData(){
		const indicatorType = this.paris.getValue(CustomTiIndicatorsType, CustomTiIndicatorsTypes.Files);
		this.fileEntityTypeService.getExistentCustomIndicators(this.file, indicatorType)
			.subscribe(data => {
				const indicatorFilters = [this.file.sha1, this.file.sha256, this.file.md5]
				const href = `/preferences2/custom_ti_indicators/${indicatorType.id}?filters=` +
					encodeURI('tiIndicator=' + indicatorFilters.join('|'));
				this.customIndicatorData = data[0] && {href, ...data[0]};
			});
	}
}
