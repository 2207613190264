import { FlavorConfig } from '../models';

export interface IRoutesConfig {
	tvm: FlavorConfig;
	tvmEventTimeline: FlavorConfig;
	evaluation: FlavorConfig;
	simulationsAndTutorials: FlavorConfig;
	investigation: FlavorConfig;
	files: FlavorConfig;
	hunting: FlavorConfig;
	mdeUsers: FlavorConfig;
	urls: FlavorConfig;
	domains: FlavorConfig;
	ips: FlavorConfig;
	interoperability: FlavorConfig;
	policyManagement: FlavorConfig;
	aadDeviceGroupManagement: FlavorConfig;
	configurationManagement: FlavorConfig;
}

export const RoutesConfig: IRoutesConfig = {
	tvm: {
		mdeFlavors: ['P2', 'Smb']
	},
	tvmEventTimeline: {
		mdeFlavors: ['P2']
	},
	configurationManagement: {
		mdeFlavors: ['P2', 'P1']
	},
	evaluation: {
		mdeFlavors: ['P2']
	},
	simulationsAndTutorials: {
		mdeFlavors: ['P2', 'Smb']
	},
	investigation: {
		mdeFlavors: ['P2', 'Smb'],
		enableForNonMdeWorkloads: true
	},
	files: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true
	},
	hunting: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true
	},
	mdeUsers: {
		mdeFlavors: ['P2']
	},
	urls: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true
	},
	domains: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true
	},
	ips: {
		mdeFlavors: ['P2'],
		enableForNonMdeWorkloads: true
	},
	interoperability: {
		mdeFlavors: ['P2']
	},
	policyManagement: {
		mdeFlavors: ['Smb']
	},
	aadDeviceGroupManagement: {
		mdeFlavors: ['Smb']
	}
}
