import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { DeviceUserAccountControlFlags } from '@wcd/domain';

const SUPPORTED_UAC_FLAGS: Array<string> = [
	'passwordNeverExpires',
	'smartcardRequired',
	'accountDisabled',
	'trustedForDelegation',
	'passwordExpired',
	'useDesKeyOnly',
	'accountLocked',
];

@Component({
	selector: 'machine-itp-uac-flags',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ul class="wcd-machine-uac-flag-list">
			<li
				*ngFor="let flagName of supportedUacFlagsKeys"
				class="wcd-machine-uac-flag-item"
				[ngClass]="{ 'wcd-machine-uac-flag-item-enabled': !!uacFlags[flagName] }"
			>
				{{ 'machines.entityDetails.fields.uacFlags.flagsTexts.' + flagName | i18n }}
			</li>
		</ul>
	`,
	styleUrls: ['./machine-itp-uac-flags.component.scss'],
})
export class MachineUacFlagsComponent implements OnInit {
	@Input() uacFlags: DeviceUserAccountControlFlags;
	supportedUacFlagsKeys: Array<string>;

	ngOnInit() {
		this.supportedUacFlagsKeys = Object.keys(this.uacFlags).filter(flagName =>
			SUPPORTED_UAC_FLAGS.includes(flagName)
		);
	}
}
