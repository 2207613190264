var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef, EventEmitter } from '@angular/core';
import { PrettyNumberService } from '@wcd/prettify';
import { select } from 'd3-selection';
import { arc } from 'd3-shape';
import { ChartComponent } from '../chart.component.base';
import { get } from 'lodash-es';
var labelSize = 25;
var LABEL_TEXT_CSS_CLASS = 'ms-pie-label-text';
var LABEL_LINE_CSS_CLASS = 'ms-pie-label-line';
var DYNAMIC_TITLE_CSS_CLASS = 'ms-pie-dynamic-title';
var NO_DATA_ARC_CSS_CLASS = 'no-data-arc';
var lastId = 0;
var PieChartComponent = /** @class */ (function (_super) {
    __extends(PieChartComponent, _super);
    function PieChartComponent(elementRef, prettyNumberService) {
        var _this = _super.call(this, elementRef) || this;
        _this.prettyNumberService = prettyNumberService;
        _this.labelsOutside = true;
        _this.allowTitleClick = false;
        _this.isFullHeight = false;
        _this.ariaLabel = null;
        _this.role = 'group';
        _this.alignCenter = false;
        _this.titleClick = new EventEmitter();
        _this.id = lastId++;
        var self = _this;
        _this.chartSettings = {
            chartType: 'donut',
            columnName: 'name',
            columnValue: 'value',
            options: {
                color: {
                    pattern: ['#d2d2d2', '#a40013', '#f68c1e', '#fbed1d'],
                },
                donut: {
                    width: 19,
                    label: {
                        show: false,
                    },
                },
                onrendered: function () {
                    // use function instead of lambda so we can use c3's ChartInternal object
                    self.onRendered(this);
                },
            },
        };
        return _this;
    }
    PieChartComponent.prototype.onRendered = function (chart) {
        var data = this.data;
        if (this.settings.dataPath)
            data = get(data, this.settings.dataPath);
        var noData = !(data && data.some(function (i) { return i.value !== 0; }));
        if (!(this.upperTitle || this.labelsOutside || noData))
            return;
        var divElement = select(this.el);
        var svg = divElement.select('svg');
        svg.attr('role', this.role);
        svg.select('g')
            .attr('aria-hidden', 'false');
        svg.attr('aria-labelledby', "pie-chart-" + this.id + " pie-chart-id-" + this.id + "-text-count pie-chart-id-" + this.id + "-text-label");
        // remove previously created labels and lines
        svg.selectAll('.' + LABEL_TEXT_CSS_CLASS).remove();
        svg.selectAll('.' + LABEL_LINE_CSS_CLASS).remove();
        svg.selectAll('.' + DYNAMIC_TITLE_CSS_CLASS).remove();
        svg.selectAll('.' + NO_DATA_ARC_CSS_CLASS).remove();
        if (noData)
            this.addNoDataArc(svg, chart);
        if (this.upperTitle)
            this.addDynamicTitle(svg);
        if (this.labelsOutside)
            this.addLabelsWithLines(svg, chart);
    };
    PieChartComponent.prototype.addNoDataArc = function (svg, chart) {
        svg.attr('role', this.role);
        svg.select('g')
            .attr('aria-hidden', 'true');
        svg.select('.c3-chart-arcs')
            .append('svg:g')
            .attr('class', 'c3-chart-arc ' + NO_DATA_ARC_CSS_CLASS)
            .append('svg:g')
            .append('svg:path')
            .attr('d', arc()
            .innerRadius(chart.innerRadius)
            .outerRadius(chart.radius)
            .startAngle(0)
            .endAngle(2 * Math.PI))
            .attr('class', 'color-fill-neutral');
    };
    PieChartComponent.prototype.addLabelsWithLines = function (svg, chart) {
        var originalHeight = Number(svg.attr('height') || 0);
        var originalWidth = Number(svg.attr('width') || 0);
        var originalSize = Math.min(originalHeight, originalWidth);
        // get the circle's radii and arc
        var innerRadius = chart.innerRadius;
        var outerRadius = chart.radius;
        var arcElements = svg.selectAll('.c3-chart-arc');
        var arcEL = arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);
        var self = this;
        var index = 0;
        arcElements.each(function () {
            var currentArc = select(this);
            var arcData = currentArc.datum();
            if (arcData && arcData.value !== 0) {
                // calculate the arc's centroid
                var centroid = arcEL.centroid(arcData), centroidX = centroid[0], centroidY = centroid[1], centroidHeight = Math.sqrt(centroidX * centroidX + centroidY * centroidY);
                // add text elements
                var text_1 = currentArc.append('svg:text');
                text_1.attr('class', LABEL_TEXT_CSS_CLASS + ' pointer')
                    .attr('transform', "translate(" + (centroidX / centroidHeight) * (outerRadius + labelSize) + ",\n\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t\t" + (centroidY / centroidHeight) * (outerRadius + labelSize) + ")")
                    .attr('id', "pie-chart-id-" + self.id + "-text-count-" + index++)
                    .attr('text-anchor', (arcData.startAngle + arcData.endAngle) / 2 > Math.PI ? 'end' : 'start')
                    .attr('dx', (arcData.startAngle + arcData.endAngle) / 2 > Math.PI ? '.3em' : '-.3em')
                    .attr('dy', '.3em')
                    .text(self.prettyNumberService.prettyNumber(arcData.value))
                    .on('click', function (d) {
                    return self.settings.options &&
                        self.settings.options.data &&
                        self.settings.options.data.onclick &&
                        self.settings.options.data.onclick(d.data, text_1);
                });
                // add lines
                currentArc
                    .append('svg:line')
                    .attr('class', LABEL_LINE_CSS_CLASS)
                    .attr('x1', "" + centroidX)
                    .attr('y1', "" + centroidY)
                    .attr('x2', "" + (centroidX / centroidHeight) * (outerRadius + labelSize - 15))
                    .attr('y2', "" + (centroidY / centroidHeight) * (outerRadius + labelSize - 15));
            }
        });
        // add a viewBox on the original svg so it resizes to fit the new elements
        svg.attr('viewBox', "-" + labelSize + " -" + labelSize + " " + (originalWidth + labelSize * 2) + " " + (originalHeight + labelSize * 2));
        // expand the clipPath so everything fits
        this.expandClipPath(svg);
        // scale all text elements in the svg to original size
        this.scaleTextToOriginalSize(svg, originalSize);
    };
    PieChartComponent.prototype.addDynamicTitle = function (svg) {
        var _this = this;
        var arcContainer = svg.select('.c3-chart-arcs');
        var originalTitle = arcContainer.select('.c3-chart-arcs-title').attr('dy', '1em').attr('id', "pie-chart-id-" + this.id + "-text-label");
        arcContainer
            .append('svg:text')
            .attr('class', "c3-chart-arcs-title " + DYNAMIC_TITLE_CSS_CLASS + (this.allowTitleClick ? ' with-link' : ''))
            .attr('id', "pie-chart-id-" + this.id + "-text-count")
            .attr('dy', '-.3rem')
            .on('click', function () { return _this.titleClick.emit(); })
            .text(this.upperTitle);
        arcContainer.append(function () { return originalTitle.node(); });
    };
    PieChartComponent.prototype.scaleTextToOriginalSize = function (svg, originalSize) {
        // use function instead of arrow to use 'this' on each text element
        svg.selectAll('text').attr('transform', function () {
            var transformString = select(this).attr('transform') || '';
            // remove previous scale transformations
            transformString = transformString.replace(/scale\(.*\)/, '');
            return (transformString ? transformString + ' ' : '') + "scale(" + (originalSize + labelSize * 2) / originalSize + ")";
        });
    };
    PieChartComponent.prototype.expandClipPath = function (svg) {
        var padding = 10;
        svg.selectAll('clipPath[id^="c3-"][id$="clip"] rect')
            .attr('x', "-" + (labelSize + padding))
            .attr('y', "-" + (labelSize + padding))
            .attr('height', function () {
            var height = this.getAttribute('height') || 0;
            return Number(height) + (labelSize + padding) * 2;
        })
            .attr('width', function () {
            var width = this.getAttribute('width') || 0;
            return Number(width) + (labelSize + padding) * 2;
        });
    };
    return PieChartComponent;
}(ChartComponent));
export { PieChartComponent };
