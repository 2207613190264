import { Component, Input, SecurityContext } from '@angular/core';
import { Router } from '@angular/router';
import { Incident } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { WicdSanitizerService } from '@wcd/shared';

@Component({
	selector: 'mte-advanced-queries-panel',
	templateUrl: './mte-advanced-queries.panel.component.html',
	styleUrls: ['./mte-advanced-queries.panel.component.scss']
})
export class MteAdvancedQueriesPanelComponent extends PanelContainer {
	@Input() incident: Incident;

	constructor(protected router: Router, private domSanitizer: WicdSanitizerService) {
		super(router);
	}

	sanitize = text => this.domSanitizer.sanitize(SecurityContext.HTML, text)

}
