import { ChangeDetectorRef, EventEmitter, OnChanges, OnInit, } from '@angular/core';
import { Alert, AlertClassification, AlertDetermination, AlertStatus, AlertClassificationType } from '@wcd/domain';
import { AlertsService, getCombinedClassificationMap } from '../../../@entities/alerts/services/alerts.service';
import { Paris } from '@microsoft/paris';
import { AuthService, MtpPermission } from '@wcd/auth';
import { filter, finalize } from 'rxjs/operators';
import { find, flatMap, uniq } from 'lodash-es';
import { MessageBarType } from 'office-ui-fabric-react';
import { I18nService } from '@wcd/i18n';
import { AlertPageService } from '../../../@entities/alerts/services/alert-page.service';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { config } from '@wcd/shared';
import { MainAppStateService } from '../../../shared/main/services/main-app-state.service';
import { AssigneePickerComponent } from '../../../forms/components/assignee-picker.component';
import { Feature, PreferencesService, FeaturesService } from '@wcd/config';
import { AppInsightsService } from "../../../insights/services/app-insights.service";
var AlertsManageComponent = /** @class */ (function () {
    function AlertsManageComponent(alertsService, authService, changeDetectorRef, paris, i18nService, appInsightService, alertPageService, mainAppStateService, preferencesService, featuresService) {
        var _this = this;
        this.alertsService = alertsService;
        this.authService = authService;
        this.changeDetectorRef = changeDetectorRef;
        this.paris = paris;
        this.i18nService = i18nService;
        this.appInsightService = appInsightService;
        this.alertPageService = alertPageService;
        this.preferencesService = preferencesService;
        this.featuresService = featuresService;
        this.restored = new EventEmitter();
        this.statusChanged = new EventEmitter();
        this.classificationChanged = new EventEmitter();
        this.determinationChanged = new EventEmitter();
        this.assigneeChanged = new EventEmitter();
        this.MessageBarType = MessageBarType;
        //for the new classification control
        this.selectedCombinedClassification = 'NotSet';
        this.combinedClassificationMap = [];
        this.isNarrowLayout = false;
        this.isUpdatingAlert = false;
        this.isUpdatingStatus = false;
        this.isUpdatingClassification = false;
        this.isUpdatingDetermination = false;
        this.isUpdatingAssignee = false;
        this.isAssignToSomeoneElseEnabled = false;
        this.isCombinedClassificationEnabled = false;
        this.AlertClassificationType = AlertClassificationType;
        this.fixMessageBarButtonsOrder = { actions: { flexDirection: 'row', justifyContent: 'flex-end' } };
        this.i18nFormat = function (item) {
            return _this.i18nService.strings[item.nameI18nKey] || item.name;
        };
        this.treeElementsUpdates$ = this.alertPageService.treeElementsUpdates$;
        this.isAssignToSomeoneElseEnabled = this.featuresService.isEnabled(Feature.AssignToSomeoneElse);
        this.isCombinedClassificationEnabled = this.featuresService.isEnabled(Feature.CombinedClassification);
        this.combinedClassificationMap = getCombinedClassificationMap(this.i18nService);
        this.selectableStatuses = paris
            .getRepository(AlertStatus)
            .entity.values.filter(function (status) { return status.isSelectable; });
        this.selectableAlertClassifications = paris
            .getRepository(AlertClassification)
            .entity.values.filter(function (status) { return status.isSelectable && (_this.isCombinedClassificationEnabled || !status.combinedClassification); })
            .sort(function (statusA, statusB) { return statusA.priority - statusB.priority; });
        this.allAlertDeterminations = paris.getRepository(AlertDetermination).entity.values.filter(function (value) { return _this.isCombinedClassificationEnabled || !value.combinedClassification; });
        mainAppStateService.state$
            .pipe(filter(function (state) {
            return _this.isNarrowLayout !== config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
        }))
            .subscribe(function (state) {
            _this.isNarrowLayout = config.widthBreakpointSmallerThan(state.screenMaxWidthBreakpoint, config.msScreenSizeBreakpoints.medium);
            _this.changeDetectorRef.markForCheck();
        });
    }
    Object.defineProperty(AlertsManageComponent.prototype, "isSingleAlert", {
        get: function () {
            return this.alerts && this.alerts.length === 1;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AlertsManageComponent.prototype, "alert", {
        get: function () {
            return this.alerts[0];
        },
        enumerable: true,
        configurable: true
    });
    AlertsManageComponent.prototype.ngOnInit = function () {
        this.setManageModels();
        this.assignedTo = this.getAssignedTo();
        this.selectedCombinedClassification = this.getAlertClassification();
    };
    AlertsManageComponent.prototype.ngOnChanges = function (changes) {
        if (changes.alerts)
            this.setManageModels();
    };
    AlertsManageComponent.prototype.getAlertClassification = function () {
        return this.alerts.length === 1 && this.alerts[0].classification ? (this.alerts[0].determination && this.alerts[0].determination.type)
            : 'NotSet';
    };
    AlertsManageComponent.prototype.onNewClassificationDropdownChange = function (event) {
        var _this = this;
        this.selectedCombinedClassification = event.option.key;
        this.isUpdatingAlert = this.isUpdatingClassification = true;
        this.alertsService
            .setAlertsClassificationAndDetermination(this.alerts, this.selectedCombinedClassification)
            .then(function () {
            _this.onUpdatedAlert();
        });
        this.changeDetectorRef.markForCheck();
    };
    /**
     * Sets the common status, classification and determination for the alerts
     */
    AlertsManageComponent.prototype.setManageModels = function () {
        var _this = this;
        if (!this.alerts || !this.alerts.length)
            this.selectedStatus = this.selectedClassification = this.selectedDetermination = null;
        else {
            var isSameStatus = this.alerts.every(function (alert) { return alert.status === _this.alerts[0].status; }), isSameClassification = this.alerts.every(function (alert) { return alert.classification === _this.alerts[0].classification; }), isSameDetermination = this.alerts.every(function (alert) { return alert.determination === _this.alerts[0].determination; });
            this.selectedStatus = this._lastSelectedStatus =
                isSameStatus &&
                    this.alerts[0].status &&
                    find(this.selectableStatuses, function (status) { return status.name === _this.alerts[0].status.name; });
            this.selectedClassification = isSameClassification && this.alerts[0].classification;
            this.selectedDetermination = isSameDetermination && this.alerts[0].determination;
            var workloads = uniq(flatMap(this.alerts.map(function (alert) { return alert.mtpWorkloads; })));
            this.rbacSettings = {
                mtpPermissions: [MtpPermission.SecurityData_Manage],
                mtpWorkloads: workloads,
                requireAllPermissions: true,
                state: RbacControlState.disabled,
            };
            this.assignedTo = this.getAssignedTo();
        }
    };
    AlertsManageComponent.prototype.onStatusSelect = function (alertStatus) {
        var _this = this;
        this.isUpdatingAlert = this.isUpdatingStatus = true;
        this.alertsService
            .setAlertsStatus(this.alerts, alertStatus, true, '[name=alert-status] button')
            .subscribe(function (alertStatus) {
            if (alertStatus) {
                _this.statusChanged.emit(alertStatus);
                _this.selectedStatus = _this._lastSelectedStatus = alertStatus;
                _this.appInsightService.trackEvent("alert-status-change", { status: alertStatus, context: "manage-alert" });
            }
            else
                _this.selectedStatus = _this._lastSelectedStatus;
            _this.onUpdatedAlert();
        });
    };
    AlertsManageComponent.prototype.onClassificationSelectWithValue = function (selectedClassification) {
        this.selectedClassification = selectedClassification;
        this.onClassificationSelect();
    };
    AlertsManageComponent.prototype.alertsHaveServiceSource = function (serviceSource) {
        this.alerts.forEach(function (alert) {
            if (alert.serviceSource.id === serviceSource) {
                return true;
            }
        });
        return false;
    };
    AlertsManageComponent.prototype.onClassificationSelect = function () {
        var _this = this;
        this.isUpdatingAlert = this.isUpdatingClassification = true;
        this.alertsService
            .setAlertsClassification(this.alerts, this.selectedClassification, '[name=alert-classification] button')
            .pipe(finalize(function () { return _this.onUpdatedAlert(); }))
            .subscribe(function () {
            _this.appInsightService.trackEvent("alert-classification-change", { classification: _this.selectedClassification, context: "manage-alert" });
            _this.classificationChanged.emit(_this.selectedClassification);
        });
    };
    AlertsManageComponent.prototype.onDeterminationSelect = function () {
        var _this = this;
        this.isUpdatingAlert = this.isUpdatingDetermination = true;
        this.alertsService
            .setAlertsDetermination(this.alerts, this.selectedDetermination)
            .pipe(finalize(function () { return _this.onUpdatedAlert(); }))
            .subscribe(function () {
            _this.appInsightService.trackEvent("alert-determination-change", { determination: _this.selectedDetermination, context: "manage-alert" });
            _this.determinationChanged.emit(_this.selectedDetermination);
        });
    };
    AlertsManageComponent.prototype.restoreHiddenAlert = function () {
        var _this = this;
        this.isUpdatingAlert = true;
        this.alertsService.restoreHiddenAlerts(this.alerts).then(function () {
            _this.restored.emit();
            _this.onUpdatedAlert();
        }, function () { return _this.onUpdatedAlert(); });
    };
    AlertsManageComponent.prototype.onSelectAssignee = function (assignee) {
        var _this = this;
        this.isUpdatingAssignee = true;
        this.alertsService
            .assignAlertsToSomeoneElse(this.alerts, assignee && assignee.secondaryText)
            .pipe(finalize(function () { return _this.onUpdatedAlert(); }))
            .subscribe(function () {
            _this.assigneeChanged.emit(assignee ? assignee.secondaryText : null);
            _this.alerts.forEach(function (alert) { return alert.assignedTo = assignee ? assignee.secondaryText : null; });
            _this.assignedTo = _this.getAssignedTo();
            _this.appInsightService.trackEvent("alert-assignedTo-change", { context: "manage-alert" });
            AssigneePickerComponent.updateUserPreferredAssignees(assignee, _this.preferencesService);
        });
    };
    AlertsManageComponent.prototype.getAssignedTo = function () {
        var _this = this;
        var assignee = this.alerts.length === 1 ? this.alerts[0].assignedTo :
            this.alerts.every(function (alert) { return alert.assignedTo === _this.alerts[0].assignedTo; }) ? this.alerts[0].assignedTo : 'multipleAssignees';
        return assignee && {
            secondaryText: assignee
        };
    };
    AlertsManageComponent.prototype.onUpdatedAlert = function () {
        this.isUpdatingAlert = this.isUpdatingStatus = this.isUpdatingClassification = this.isUpdatingDetermination = this.isUpdatingAssignee = false;
        this.paris.getRepository(Alert).clearCache();
        this.alertPageService.updateTreeElement(this.alerts.map(function (alert) { return alert.id; }));
        this.changeDetectorRef.markForCheck();
    };
    return AlertsManageComponent;
}());
export { AlertsManageComponent };
