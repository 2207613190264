var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityModelBase, EntityField } from '@microsoft/paris';
var ɵ0 = function (response) { return ({
    count: response.numOfResults,
    items: response.results,
    meta: response.meta,
}); };
var BaselineConfigurationDevice = /** @class */ (function (_super) {
    __extends(BaselineConfigurationDevice, _super);
    function BaselineConfigurationDevice() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationDevice.prototype, "name", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationDevice.prototype, "domain", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Boolean)
    ], BaselineConfigurationDevice.prototype, "isCompliant", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationDevice.prototype, "operatingSystem", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], BaselineConfigurationDevice.prototype, "operatingSystemVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], BaselineConfigurationDevice.prototype, "lastSeen", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Number)
    ], BaselineConfigurationDevice.prototype, "compliantConfigurations", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineConfigurationDevice.prototype, "detectedValues", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineConfigurationDevice.prototype, "expectedValues", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Array)
    ], BaselineConfigurationDevice.prototype, "sourceValues", void 0);
    BaselineConfigurationDevice = __decorate([
        Entity({
            singularName: 'Baseline configuration device',
            pluralName: 'Baseline configuration devices',
            readonly: true,
            endpoint: "baseline/devices",
            parseDataSet: ɵ0
        })
    ], BaselineConfigurationDevice);
    return BaselineConfigurationDevice;
}(EntityModelBase));
export { BaselineConfigurationDevice };
export { ɵ0 };
