import { EntityField, ValueObject, ModelBase } from '@microsoft/paris';

export enum AdapterStatus {
	Unknown = 'Unknown',
	Up = 'Up',
	Down = 'Down',
}

export enum AdapterType {
	Other = 'Other',
	Ethernet = 'Ethernet',
	Wireless80211 = 'Wireless80211',
	Tunnel = 'Tunnel',
	Ppp = 'Ppp',
	Wwanpp = 'Wwanpp',
	Wwanpp2 = 'Wwanpp2',
	TokenRing = 'TokenRing',
	Unknown = 'Unknown',
	Atm = 'Atm',
	HighPerformanceSerialBus = 'HighPerformanceSerialBus',
}

export enum NetworkCategory {
	Public,
	Private,
	Domain,
}

@ValueObject({
	singularName: 'IP address',
	pluralName: 'IP addresses',
	readonly: true,
})
export class IpAddress extends ModelBase {
	@EntityField({ data: 'Address' })
	readonly address: string;

	@EntityField({ data: 'IsInternal' })
	readonly isInternal?: boolean;
}

@ValueObject({
	singularName: 'Network Info',
	pluralName: 'Network Info',
	readonly: true,
})
export class NetworkInfo extends ModelBase {
	@EntityField({ data: 'Name' })
	readonly name: string;

	@EntityField({ data: 'Description' })
	readonly description: string;

	@EntityField({ data: 'Category' })
	readonly category: NetworkCategory;
}

@ValueObject({
	singularName: 'IP adapter',
	pluralName: 'IP adapters',
	readonly: true,
})
export class IpAdapter extends ModelBase {
	@EntityField({ data: 'PhysicalAddress' })
	readonly physicalAddress: string;

	@EntityField({ data: 'InterfaceType' })
	readonly adapterType: AdapterType;

	@EntityField({ data: 'DnsSuffix' })
	readonly dnsSuffix: string;

	@EntityField({
		data: 'OperationalStatus',
		defaultValue: AdapterStatus.Unknown,
	})
	readonly status: AdapterStatus;

	@EntityField({
		parse: (value, rawData) => {
			const ipAddresses =
				rawData.IpAddresses || (rawData.Ipv4Addresses || []).concat(rawData.Ipv6Addresses || []);
			return ipAddresses.map(ip => {
				return { address: ip.Address, isInternal: ip.IsInternal };
			});
		},
	})
	readonly ipAddresses: Array<IpAddress>;

	@EntityField({
		data: 'AdapterNetworks',
		arrayOf: NetworkInfo,
	})
	readonly connectedNetworks: Array<NetworkInfo>;
}

@ValueObject({
	singularName: 'Device network info',
	pluralName: 'Device network info',
	readonly: true,
})
export class MachineNetworkInfo extends ModelBase {
	@EntityField({
		data: 'IpAdapters',
		arrayOf: IpAdapter,
	})
	readonly ipAdapters: Array<IpAdapter>;

	@EntityField({ data: 'IsMacAddressReportedForMachine' })
	readonly isMachineReportingMacAddress?: boolean;
}

@ValueObject({
	singularName: 'IP with adapter',
	pluralName: 'IPs with adapters',
	readonly: true,
})
export class IpWithAdapter extends IpAddress {
	readonly adapter: IpAdapter;
}
