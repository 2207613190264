
		<route-link *ngIf="machine.machineId; else labelOnly" [routeLink]="machineRouteLinkModel">
			<ng-container *ngTemplateOutlet="linkContent"></ng-container>
		</route-link>
		<ng-template #labelOnly>
			<ng-container *ngTemplateOutlet="linkContent"></ng-container>
		</ng-template>
		<ng-template #linkContent>
			<wcd-shared-icon *ngIf="showIcon" iconName="System" class="small-icon color-text-gray-900">
			</wcd-shared-icon>
			<span *ngIf="machine.name; else machineIdLabel">
				<machine-name [machine]="machine.name"></machine-name>
			</span>
			<ng-template #machineIdLabel>{{ machine.machineId }}</ng-template>
		</ng-template>
	