var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { DataExportSettingsEntity } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { LogType } from '../enums/log-type.enum';
import { AuthService } from '@wcd/auth';
import { DataExportService } from '../services/data-export.service';
import { FeaturesService } from '@wcd/config';
var DataExportSettingsDetailsComponent = /** @class */ (function (_super) {
    __extends(DataExportSettingsDetailsComponent, _super);
    function DataExportSettingsDetailsComponent(paris, dialogsService, changeDetectorRef, i18nService, entityPanelsService, dataExportService, featureService, authService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        _this.entityPanelsService = entityPanelsService;
        _this.dataExportService = dataExportService;
        _this.featureService = featureService;
        _this.authService = authService;
        _this.SpinnerSize = SpinnerSize;
        _this.asKeyValueList = true;
        _this.isDirty = false;
        _this.selectedEventTypes = [];
        _this.isSaving = false;
        _this.isReadOnlyPanel = false;
        _this.ruleHaveNonSupportedCategories = false;
        _this.dataExportRepository = _this.paris.getRepository(DataExportSettingsEntity);
        _this.availableEventTypes = _this.dataExportService.availableLogTypes;
        return _this;
    }
    Object.defineProperty(DataExportSettingsDetailsComponent.prototype, "isValid", {
        get: function () {
            return this.selectedEventTypes.length > 0;
        },
        enumerable: true,
        configurable: true
    });
    DataExportSettingsDetailsComponent.prototype.onLogTypesChange = function (event) {
        this.isDirty = true;
    };
    Object.defineProperty(DataExportSettingsDetailsComponent.prototype, "deprecatedEventTypesAreUsed", {
        get: function () {
            var _this = this;
            return !!this.selectedEventTypes.find(function (et) {
                return _this.dataExportService.isDeprecatedLogType(LogType[et.id]);
            });
        },
        enumerable: true,
        configurable: true
    });
    DataExportSettingsDetailsComponent.prototype.setEntity = function (entity) {
        var _this = this;
        _super.prototype.setEntity.call(this, cloneDeep(entity));
        this.selectedEventTypes = Object.keys(LogType)
            .filter(function (logType) {
            return _this.dataExportSettings.logs.some(function (logProps) { return logProps.enabled && LogType[logType] === logProps.category; });
        })
            .map(function (logType) { return _this.dataExportService.logTypeToChecklistValue(logType); });
        this.ruleHaveNonSupportedCategories = this.dataExportSettings.logs.some(function (enabledLog) { return !Object.values(LogType).find(function (logTypeKey) { return logTypeKey === enabledLog.category; }); });
        this.isReadOnlyPanel = !!entity.workspaceProperties || this.ruleHaveNonSupportedCategories;
        if (!this.authService.isGlobalAdmin) {
            this.tooltip = this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.details.noPermissions');
        }
        else if (this.ruleHaveNonSupportedCategories) {
            this.tooltip = this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.details.mustUseMtpPage');
        }
    };
    DataExportSettingsDetailsComponent.prototype.save = function () {
        var _this = this;
        this.isSaving = true;
        Object.assign(this.entity, {
            logs: this.selectedEventTypes.map(function (checklistValue) {
                return ({
                    category: LogType[checklistValue.id],
                    enabled: _this.availableEventTypes.some(function (logType) { return logType.id === checklistValue.id; }),
                });
            }),
        });
        this.dataExportRepository.save(this.dataExportSettings).subscribe(function () {
            _this.isSaving = false;
            _this.entityPanelsService.closeEntityPanel(DataExportSettingsEntity);
            _this.requestEntitiesRefresh.emit();
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isSaving = false;
            var errorMessage = error.message || error;
            if (!_this.authService.currentUser.isSecAdmin) {
                var addedMessage = _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.missingAadPermissions', { tenantid: _this.authService.tenantId });
                errorMessage += " (" + addedMessage + ")";
                error.message = errorMessage;
            }
            _this.dialogsService.showError({
                title: _this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.details.failedToSave'),
                message: errorMessage,
                data: error,
            });
        });
    };
    DataExportSettingsDetailsComponent.prototype.cancel = function () {
        this.entityPanelsService.closeEntityPanel(DataExportSettingsEntity);
    };
    Object.defineProperty(DataExportSettingsDetailsComponent.prototype, "dataExportSettings", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataExportSettingsDetailsComponent.prototype, "userIsGlobalAdmin", {
        get: function () {
            return this.authService.isGlobalAdmin;
        },
        enumerable: true,
        configurable: true
    });
    return DataExportSettingsDetailsComponent;
}(EntityPanelComponentBase));
export { DataExportSettingsDetailsComponent };
