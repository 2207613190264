import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { TvmAnalyticsSharedEntityConfigurations } from '../analyticsEndPointUtils';
import { RecommendationBase } from '../common/recommendation/recommendation.entity.base';

export abstract class RecommendationContextBase extends ModelBase {
	@EntityField({ data: 'ServiceName' })
	readonly serviceName?: string;

	@EntityField({ data: 'UserName' })
	readonly userName?: string;

	@EntityField({ data: 'ShareName' })
	readonly shareName?: string;

	@EntityField({
		data: 'contextKey',
		parse: (o, rawData) => rawData.ServiceName || rawData.UserName || rawData.ShareName,
	})
	readonly contextKey: any;
}
