import { merge } from 'lodash-es';
import { defaultEntitiesDisplayMap } from '../models/defaults/entity-maps.defaults';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
var HuntingSettingsService = /** @class */ (function () {
    function HuntingSettingsService(appContextService, featuresService, entitiesDisplayMap) {
        this.scheduledQueriesFeatureEnabled =
            !appContextService.isMtp || featuresService.isEnabled(Feature.ShowScheduledHuntingOnMTP);
        this._entitiesDisplayMap = merge({}, defaultEntitiesDisplayMap, entitiesDisplayMap);
    }
    Object.defineProperty(HuntingSettingsService.prototype, "entitiesDisplayMap", {
        get: function () {
            return this._entitiesDisplayMap;
        },
        enumerable: true,
        configurable: true
    });
    return HuntingSettingsService;
}());
export { HuntingSettingsService };
