import { ValueObject, EntityModelBase, EntityField } from '@microsoft/paris';
import { RegistryValueTypes } from './registry-value-type.enum';

@ValueObject({
	singularName: 'Registry Key',
	pluralName: 'Registry key items',
	readonly: true,
})
export class RegistryKey extends EntityModelBase<string> {
	@EntityField({ data: 'Id' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Name' })
	name: string;

	@EntityField({ data: 'EvidenceId', required: false })
	evidenceId?: string;

}
