var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { AlertStatusTypeId, AlertV3StatusType, AlertV3StatusTypeMapping, } from '../alert/alert-status/alert-status.models';
import { RemediationActionTypeActionCount } from '../remediation/remediation-action-type-action-count.value-object';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';
import { airsEntityStatusValues } from '../airs_entity/airs-entity-status.entity';
import { airsEntityRemediationStatuses } from '../airs_entity/airs-entity-remediation-status.entity';
import { AirsEntityType, AirsEntityTypeValue, convertEntityTypeCase, } from '../airs_entity/airs-entity-type.entity';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { MachineStatusType } from '../machine/machine-status.entity.values';
import { Mailbox } from '../mailbox/mailbox.entity';
import { remediationActionTypeValues } from '../remediation/remediation-action-type.values';
import { PendingActionDecisionTypes } from '../remediation/pending_actions/pending-action-decision-types.enum';
import { RemediationStatusId, RemediationStatusType, VerdictStatusType, } from '../airs_entity/airs-entity-status-types.enum';
import { capitalize, get, isEmpty, isNil, orderBy } from 'lodash-es';
import { PendingTypeEnum } from '../investigation/pending-type.enum';
import { RemediationThreatStatusSummary } from '../remediation/remediation-threat-status-summary';
import { AssetTypeEnum } from './asset-type.enum';
import { InvestigationProviderTypeEnum } from './investigation-provider.enum';
import { AlertV3ActionTypeMap } from '../action-history/alertV3-action-type-types';
import { DetectionSourceType } from '../alert/sources/detection-source.enum';
import { ServiceSourceType } from '../alert/sources/service-source.enum';
import { SeverityType } from '../severity/severity-type.enum';
import { severityValues } from '../severity/severity.entity.values';
import { Investigation } from '../investigation/investigation.entity';
import { PendingAction } from '../remediation/pending_actions/pending-action.entity';
import { EntityDisplayNameFunction } from '../airs_entity/alertV3/entity-display-name.utils';
import { InvestigationActionStatusId, InvestigationActionStatusType, } from '../investigation/actions/investigation-action-status.values';
import { RemediationAction } from '../remediation/remediation-action.entity';
import { OfficeUtils } from '../utils/office-utils';
import { EvidenceEntity } from '../evidence/evidence.entity';
import { EvidenceDetectionSourceTypeEnum } from '../evidence/evidence-detection-source-type.enum';
import { InvestigationStatusesMapping } from '../alert/alert-investiation-status-mapping.enum';
import { AlertClassificationId, AlertClassificationType } from '../alert/alert-classification.entity.values';
import { InvestigationStatusEnum, InvestigationStatusNameEnum, } from '../investigation/investigation-status.enum';
import { EngineReportParameters } from '../airs_entity/engine-report-parameters.enum';
import { getConvertedOfficeActionType, mapOfficeActionToRemediationAction, } from '../investigation/actions/office-action.utils';
import { dateStrToUtc } from '../utils';
import { sccHostService } from '@wcd/scc-interface';
var ɵ0 = function (s) { return s.type === VerdictStatusType.Unknown; };
var UNKNOWN = airsEntityStatusValues.find(ɵ0).id;
var OFFICE_ALERT_ID_PREFIX = 'fa';
var REMEDIATION_DATE_FIELD = 'RemediationDate';
var NONE = 'None';
export var ANALYSIS_DATE_FIELD = 'AnalysisDate';
var ɵ1 = function (mtpInvestigation) { return (mtpInvestigation.isRunning ? 2500 : 1000 * 60 * 2); }, ɵ2 = function (itemId, entity, config, params) {
    // There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
    return "Find/MtpBatch?tenantid=" + params.tenantId + "&PageSize=50&Filter=ModelType eq 2 and ContainerUrn eq '" + itemId + "'";
}, ɵ3 = function (rawData) {
    var resData = get(rawData, 'ResultData[0].InvestigationDetailsPayload[0]');
    var convertedData = OfficeUtils.convertDataFromAlertV3(resData);
    // filter out mailbox and unknown entities from Evidence
    convertedData.Evidence =
        convertedData &&
            convertedData.Evidence &&
            convertedData.Evidence.filter(function (ev) {
                var convertedType = getConvertedEntityTypeName(ev.Type);
                var typeId = AirsEntityType[convertedType];
                return convertedType !== AirsEntityTypeValue.Mailbox && typeId != null;
            });
    convertedData.Actions =
        convertedData &&
            convertedData.Actions &&
            convertedData.Actions.reduce(function (res, action) {
                // check for 'SubActionType' and set it as ActionType - this is a temp solution until
                // office fix the response and send it as ActionType and not as SubActionType
                var convertedOfficeActionType = getConvertedOfficeActionType(action);
                // change the action status if there's a decision
                var actionStatus = action.ActionStatus === InvestigationActionStatusType.Pending &&
                    (action.ActionApproval && action.ActionApproval !== NONE)
                    ? InvestigationActionStatusType.Running
                    : action.ActionStatus;
                res.push(Object.assign({}, action, {
                    ActionType: convertedOfficeActionType,
                    ActionStatus: actionStatus,
                }));
                return res;
            }, []);
    return convertedData;
}, ɵ4 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ5 = function (dateStr) { return dateStrToUtc(dateStr); }, ɵ6 = function (status) {
    // For office investigation we will get Investigation Status as a String so we need to parse it to int
    var intStatus = parseInt(status);
    if (isNaN(intStatus)) {
        return status === InvestigationStatusNameEnum.Failed
            ? InvestigationStatusEnum.terminatedBySystem
            : InvestigationStatusesMapping[status];
    }
    return intStatus;
}, ɵ7 = function (actions) {
    return (actions &&
        actions
            .filter(function (action) {
            return action.ActionStatus === InvestigationActionStatusType.Pending ||
                action.ActionStatus === InvestigationActionStatusType.WaitingForResource;
        })
            .reduce(function (res, action) {
            // this is a temporary conversion to support V3
            var investigationActionType = AlertV3ActionTypeMap[action.ActionType];
            var actionType = remediationActionTypeValues.find(function (s) { return s.type === investigationActionType; });
            var actionTypeId = actionType && actionType.id;
            if (!isNil(actionTypeId)) {
                var actionIdEntry = res.find(function (d) { return d.action === actionTypeId; });
                if (actionIdEntry) {
                    actionIdEntry.count += 1;
                }
                else {
                    res.push({ action: actionTypeId, count: 1 });
                }
            }
            return res;
        }, []));
}, ɵ8 = function (actions, rawData) {
    return (actions &&
        actions.reduce(function (res, action) {
            // for each type - use different field to represent the displayName
            var firstEntityType = action.Entities && action.Entities.length !== 0 ? action.Entities[0].Type : '';
            var firstEntityConvertedCase = getConvertedEntityTypeName(firstEntityType);
            var entityDisplayNameFunc = EntityDisplayNameFunction[firstEntityConvertedCase];
            var entityDisplayName = entityDisplayNameFunc
                ? entityDisplayNameFunc(action.Entities[0])
                : '';
            var actionEntities = action.Entities &&
                action.Entities.reduce(function (res, entity) {
                    var convertedEntityType = getConvertedEntityTypeName(entity.Type);
                    res.push({
                        entity_id: entity.Urn,
                        entity_type: AirsEntityType[convertedEntityType],
                    });
                    return res;
                }, []);
            var investigationActionType = AlertV3ActionTypeMap[action.ActionType];
            var actionType = investigationActionType &&
                remediationActionTypeValues.find(function (s) { return s.type === investigationActionType; });
            var endTime = action.EndOfPendingUtc || action.ActionApprovedTime || action.EndTimeUtc;
            res.push({
                entity_name: entityDisplayName,
                action_type: actionType,
                action_id: action.ActionId,
                start_time: action.StartTimeUtc &&
                    (action.StartTimeUtc.endsWith('Z')
                        ? action.StartTimeUtc
                        : action.StartTimeUtc + 'Z'),
                end_time: endTime && (endTime.endsWith('Z') ? endTime : endTime + 'Z'),
                entities: actionEntities,
                decision: PendingActionDecisionTypes[action.ActionApproval],
                user: action.ApprovedBy,
                PendingType: action.PendingType,
                ActionStatus: action.ActionStatus,
                IsOfficeAction: rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP,
            });
            return res;
        }, []));
}, ɵ9 = function (actions) {
    return (actions &&
        actions
            .filter(function (action) { return action.ActionStatus === InvestigationActionStatusType.Pending; })
            .reduce(function (res, action) {
            var firstEntity = action.Entities && action.Entities.length > 0 ? action.Entities[0] : {};
            var remediationAction = mapOfficeActionToRemediationAction(action, firstEntity);
            if (remediationAction) {
                res.push(remediationAction);
            }
            return res;
        }, []));
}, ɵ10 = function (assets) {
    var hosts = assets && assets.filter(function (asset) { return asset.Type === AssetTypeEnum.host; });
    return (hosts &&
        hosts.reduce(function (res, asset) {
            res.push({
                status: MachineStatusType[asset.Status],
                name: asset.Name,
                ip: asset.Ip,
                users: asset.Users,
                id: asset.Urn || asset.Id,
            });
            return res;
        }, []));
}, ɵ11 = function (assets) {
    var mailboxes = assets && assets.filter(function (asset) { return asset.Type === AssetTypeEnum.mailbox; });
    return (mailboxes &&
        mailboxes.reduce(function (res, asset) {
            var threatsKeys = getThreatsNames(asset.ThreatAnalysisSummary);
            res.push({
                MailboxPrimaryAddress: asset.MailboxPrimaryAddress,
                Upn: asset.Upn,
                Verdict: asset.Verdict,
                Urn: asset.Urn,
                DisplayName: asset.DisplayName,
                RiskLevel: asset.RiskLevel,
                RiskKeys: threatsKeys,
                RiskyActivities: threatsKeys ? threatsKeys.length : 0,
            });
            return res;
        }, []));
}, ɵ12 = function (assets) {
    var users = assets && assets.filter(function (asset) { return asset.Type === AssetTypeEnum.user; });
    return (users &&
        users.reduce(function (res, asset) {
            res.push({ upn: asset.Upn });
            return res;
        }, []));
}, ɵ13 = function (alerts) { return (alerts ? alerts.length : 0); }, ɵ14 = function (evidence) { return (evidence && evidence.length ? evidence.length : 0); }, ɵ15 = function (evidence, rawData) {
    return (evidence &&
        evidence.reduce(function (res, ev) {
            var convertedEntityType = OfficeEntityTypeValue[ev.Type];
            var convertedEntityTypeName = convertedEntityType ? convertedEntityType.name : '';
            var entityDisplayNameFunc = EntityDisplayNameFunction[convertedEntityTypeName];
            var displayNameValue = entityDisplayNameFunc ? entityDisplayNameFunc(ev) : '';
            // take the last remediationProvider
            var lastRemediationProvider = getLastRemediationProvider(ev.RemediationProviders);
            /*
                TODO:
                 In the future office should add Type (SecurityAlert/InvestigationAction) to RemediationProvider
                 so we can get the 'detectionType' from OfficeEvidenceDetectionSourceEnum.
                 Temporary solution - the only value is 'Alert'
                 (https://microsoft.visualstudio.com/OS/_workitems/edit/26379425)
             */
            // const detectionType = OfficeEvidenceDetectionSourceEnum[lastRemediationProvider.Type];
            // create detection source object (can be for 'alert' or 'investigation' but for now - only for 'alert')
            var entityDetectionSrc = {};
            var detectionType = !isEmpty(lastRemediationProvider)
                ? EvidenceDetectionSourceTypeEnum.alert
                : '';
            if (detectionType) {
                var severity = lastRemediationProvider.Severity
                    ? severityValues.find(function (val) { return val.type === SeverityType[lastRemediationProvider.Severity]; })
                    : '';
                Object.assign(entityDetectionSrc, {
                    detection_source_name_key: "evidence_detectionSource_" + EvidenceDetectionSourceTypeEnum.alert,
                    detection_source_id: lastRemediationProvider.ProviderAlertId,
                    detection_type: detectionType,
                    additional_data: severity
                        ? {
                            alert: { severity: severity.id },
                        }
                        : null,
                });
            }
            // take the last ThreatAnalysisSummary
            var threatsKeys = getThreatsNames(ev.ThreatAnalysisSummary);
            var verdictType = VerdictStatusType[ev.LastVerdict];
            var verdict = airsEntityStatusValues.find(function (s) { return s.type === verdictType; });
            // 'LastRemediationState' will be empty in case of remediation action not started yet.
            var remediationStatusTypeId = getRemediationStateId(ev.LastRemediationState, lastRemediationProvider);
            res.push({
                id: ev.Urn,
                first_seen: dateStrToUtc(ev.FirstSeen),
                entity_type_id: convertedEntityType ? convertedEntityType.id : '',
                display_name: displayNameValue,
                entity_detection_src: !isEmpty(entityDetectionSrc) ? entityDetectionSrc : null,
                verdict: verdict ? verdict.id : '',
                remediation_status: remediationStatusTypeId || '',
                threatsKeys: threatsKeys,
                is_office_entity: rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP,
                investigation: { investigation_id: rawData.InvestigationId },
                _rawData: ev,
            });
            return res;
        }, []));
}, ɵ16 = function (alerts, rawData) {
    return (alerts &&
        alerts.reduce(function (res, alert) {
            var severity = severityValues.find(function (val) { return val.type === SeverityType[alert.Severity]; });
            var providerNameCapitalize = alert.ProviderName ? capitalize(alert.ProviderName) : '';
            var impactedEntities = {};
            var machines = [];
            var mailboxes = [];
            var users = [];
            if (alert.RelatedAssetsIds) {
                alert.RelatedAssetsIds.forEach(function (assetId) {
                    if (rawData.Assets) {
                        var asset = rawData.Assets.find(function (asset) { return asset.Urn === assetId; });
                        if (asset) {
                            switch (asset.Type) {
                                case AssetTypeEnum.host:
                                    machines.push({
                                        Name: asset.Name,
                                        MachineId: asset.Urn,
                                    });
                                    impactedEntities['Machines'] = machines;
                                    break;
                                case AssetTypeEnum.user:
                                    users.push({});
                                    impactedEntities['Users'] = users;
                                    break;
                                case AssetTypeEnum.mailbox:
                                    mailboxes.push({
                                        MailboxPrimaryAddress: asset.MailboxPrimaryAddress,
                                        Upn: asset.Upn,
                                        Urn: asset.Urn,
                                    });
                                    impactedEntities['Mailboxes'] = mailboxes;
                                    break;
                            }
                        }
                    }
                });
            }
            var alertId = rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP
                ? OFFICE_ALERT_ID_PREFIX + alert.ProviderAlertId
                : alert.ProviderAlertId;
            /*
                For office investigation we will get Investigation Status as a String
                and for MDATP investigation we will get it a int so we need to convert it to string
                In addition, temporary solution for office investigation - in case of status 'Failed' - convert to 'Terminated by system'
             */
            var intInvestigationStatus = parseInt(rawData.Status);
            var investigationStatus = isNaN(intInvestigationStatus)
                ? rawData.Status === InvestigationStatusNameEnum.Failed
                    ? InvestigationStatusNameEnum.TerminatedBySystem
                    : rawData.Status
                : InvestigationStatusesMapping[intInvestigationStatus];
            var isFalsePositiveClassification = alert.Status === AlertV3StatusType.Dismissed;
            var convertedAlertStatus = AlertV3StatusTypeMapping[alert.Status];
            var statusId = AlertStatusTypeId[convertedAlertStatus];
            res.push(__assign({}, alert, { Status: statusId, Classification: isFalsePositiveClassification
                    ? AlertClassificationId[AlertClassificationType.FalsePositive]
                    : undefined, DetectionSource: DetectionSourceType[alert.ProviderName], ProductSource: ServiceSourceType[providerNameCapitalize], Title: alert.AlertDisplayName, Severity: severity.id, AlertId: alertId, FirstEventTime: alert.StartTimeUtc, LastEventTime: alert.EndTimeUtc, FirstSeen: alert.TimeGenerated, InvestigationState: investigationStatus, InvestigationId: rawData.InvestigationId, Categories: [alert.Intent], IoaDefinitionId: alert.AlertType, AdditionalUxDescription: alert.Description, ImpactedEntities: impactedEntities, FormattedUxDescription: alert.Description }));
            return res;
        }, []));
}, ɵ17 = function (entities) {
    if (!isEmpty(entities)) {
        return Object.entries(entities)
            .filter(function (_a) {
            var k = _a[0];
            return AirsEntityType[k] && k !== AirsEntityTypeValue.Mailbox;
        })
            .reduce(function (count, _a) {
            var _ = _a[0], v = _a[1];
            return count + v;
        }, 0);
    }
    return 0;
}, ɵ18 = function (analyzedEntities, rawData) {
    if (isEmpty(analyzedEntities)) {
        return;
    }
    var res = Object.entries(analyzedEntities)
        .filter(function (_a) {
        var k = _a[0];
        return k !== AirsEntityTypeValue.Mailbox;
    })
        .reduce(function (res, _a) {
        var type = _a[0], count = _a[1];
        var typeId = AirsEntityType[type];
        if (typeId !== undefined) {
            res.push({ data: [{ result: UNKNOWN, count: count }], type_id: typeId });
        }
        return res;
    }, []);
    var evidence = rawData.Evidence;
    evidence &&
        evidence.forEach(function (e) {
            var convertedType = getConvertedEntityTypeName(e.Type);
            var typeId = AirsEntityType[convertedType];
            var verdictType = VerdictStatusType[e.LastVerdict];
            var lastRemediationProvider = getLastRemediationProvider(e.RemediationProviders);
            var remediationStatusTypeId = getRemediationStateId(e.LastRemediationState, lastRemediationProvider);
            // if entity has remediationStatus (failed/remediated) we will not
            // count according to the verdict - only according to the remediation status.
            var entityStatusId;
            if (remediationStatusTypeId) {
                entityStatusId = remediationStatusTypeId;
            }
            else {
                var statusByVerdict = airsEntityStatusValues.find(function (s) { return s.type === verdictType; });
                entityStatusId = statusByVerdict && statusByVerdict.id;
            }
            var verdictId = entityStatusId || '';
            var entityTypeEntry = res.find(function (e) { return e.type_id === typeId; });
            var verdictInEntityTypeEntry = entityTypeEntry.data.find(function (d) { return d.result === verdictId; });
            if (verdictInEntityTypeEntry) {
                verdictInEntityTypeEntry.count += 1;
            }
            else {
                entityTypeEntry.data.push({ result: verdictId, count: 1 });
            }
            entityTypeEntry.data.find(function (d) { return d.result === UNKNOWN; }).count -= 1;
        });
    return res;
}, ɵ19 = function (evidence) {
    if (!evidence) {
        return {
            statusCount: [],
        };
    }
    var statusCountRes = evidence.reduce(function (res, currentEvidence) {
        var remediationStatus = currentEvidence.LastRemediationState;
        if (!remediationStatus) {
            return res;
        }
        res[RemediationStatusType[remediationStatus]] =
            (res[RemediationStatusType[remediationStatus]] || 0) + 1;
        return res;
    }, {});
    if (statusCountRes) {
        return {
            statusCount: Object.entries(statusCountRes).map(function (_a) {
                var k = _a[0], v = _a[1];
                return ({
                    status: k,
                    entityCount: v,
                });
            }),
        };
    }
    return null;
}, ɵ20 = function (provider) { return provider === InvestigationProviderTypeEnum.OATP; };
var MtpInvestigation = /** @class */ (function (_super) {
    __extends(MtpInvestigation, _super);
    function MtpInvestigation(data) {
        var _this = _super.call(this, data) || this;
        _this.alert = _this.alerts && _this.alerts.length ? _this.alerts[0] : null;
        _this.serviceSource = _this.alerts && _this.alerts.length ? _this.alerts[0].serviceSource : null;
        _this.currentPendingActions =
            _this._allPendingActions &&
                _this._allPendingActions.filter(function (action) {
                    return action.status.id === InvestigationActionStatusId.Pending ||
                        action.status.id === InvestigationActionStatusId.WaitingForResource;
                });
        _this.pendingActions =
            _this._allPendingActions &&
                _this._allPendingActions.filter(function (action) { return !(_this.currentPendingActions && _this.currentPendingActions.includes(action)); });
        _this.pendingSince = _this.getFirstPendingActionTime();
        _this.attentionRequiredCount = _this.currentPendingUserActions.length;
        // Investigation pendingType
        if (_this.status && _this.status.isPending) {
            _this.pendingType =
                _this.currentPendingActions && _this.currentPendingActions.length
                    ? _this.currentPendingActions[0].pendingType
                    : null;
        }
        _this.shortInvestigationId = OfficeUtils.getShortId(_this.id);
        return _this;
    }
    Object.defineProperty(MtpInvestigation.prototype, "duration", {
        get: function () {
            return ((this.endDate || new Date()).valueOf() - this.startDate.valueOf()) / 1000;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "pendingUserActions", {
        get: function () {
            return (this.pendingActions || []).filter(function (pendingAction) {
                return (pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "pendingResourceActions", {
        get: function () {
            return (this.pendingActions || []).filter(function (pendingAction) {
                return (pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.Resource;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "currentPendingUserActions", {
        get: function () {
            return (this.currentPendingActions || []).filter(function (pendingAction) {
                return (pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User;
            });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "wasPendingUser", {
        get: function () {
            return (this.wasPending &&
                this.pendingActions.some(function (pendingAction) {
                    return (pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User;
                }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "wasPendingResource", {
        get: function () {
            return (this.wasPending &&
                this.pendingActions.some(function (pendingAction) {
                    return (pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.Resource;
                }));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MtpInvestigation.prototype, "hasPendingActions", {
        get: function () {
            if (!this.currentPendingActions) {
                return false;
            }
            return (this.currentPendingActions.filter(function (action) { return action.status && action.status.isPending; }).length > 0);
        },
        enumerable: true,
        configurable: true
    });
    MtpInvestigation.prototype.getFirstPendingActionTime = function () {
        if (this.status && this.status.isPending && !isEmpty(this.currentPendingActions)) {
            this.currentPendingActions.sort(this.sortActionsByTime);
            return this.currentPendingActions[0].startTime;
        }
        return null;
    };
    MtpInvestigation.prototype.sortActionsByTime = function (a, b) {
        if (a.startTime === b.startTime)
            return 0;
        return a.startTime > b.startTime ? 1 : -1;
    };
    __decorate([
        EntityField({ data: 'InvestigationId' }),
        __metadata("design:type", Object)
    ], MtpInvestigation.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'EndTime', parse: ɵ4 }),
        __metadata("design:type", Date)
    ], MtpInvestigation.prototype, "endDate", void 0);
    __decorate([
        EntityField({ data: 'StartTime', parse: ɵ5 }),
        __metadata("design:type", Date)
    ], MtpInvestigation.prototype, "startDate", void 0);
    __decorate([
        EntityField({ data: 'Title' }),
        __metadata("design:type", String)
    ], MtpInvestigation.prototype, "title", void 0);
    __decorate([
        EntityField({ data: 'IsLiveResponse' }),
        __metadata("design:type", Boolean)
    ], MtpInvestigation.prototype, "isLiveResponse", void 0);
    __decorate([
        EntityField({
            data: 'Status',
            parse: ɵ6,
        }),
        __metadata("design:type", InvestigationStatus)
    ], MtpInvestigation.prototype, "status", void 0);
    __decorate([
        EntityField({ data: 'RequestedStatus' }),
        __metadata("design:type", InvestigationStatus)
    ], MtpInvestigation.prototype, "requestedStatus", void 0);
    __decorate([
        EntityField({
            data: 'Actions',
            parse: ɵ7,
            arrayOf: RemediationActionTypeActionCount,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "pendingActionTypes", void 0);
    __decorate([
        EntityField({ data: 'IsRemediationDisabled' }),
        __metadata("design:type", Boolean)
    ], MtpInvestigation.prototype, "isRemediationDisabled", void 0);
    __decorate([
        EntityField({
            data: 'Actions',
            arrayOf: PendingAction,
            parse: ɵ8,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "_allPendingActions", void 0);
    __decorate([
        EntityField({
            data: 'Actions',
            arrayOf: RemediationAction,
            parse: ɵ9,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "pendingRemediationActions", void 0);
    __decorate([
        EntityField({
            data: 'Assets',
            arrayOf: InvestigatedMachine,
            parse: ɵ10,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "machines", void 0);
    __decorate([
        EntityField({
            data: 'Assets',
            arrayOf: Mailbox,
            parse: ɵ11,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "mailboxes", void 0);
    __decorate([
        EntityField({
            data: 'Assets',
            arrayOf: InvestigatedUser,
            parse: ɵ12,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "investigatedUsers", void 0);
    __decorate([
        EntityField({ data: 'Alerts', parse: ɵ13 }),
        __metadata("design:type", Number)
    ], MtpInvestigation.prototype, "totalAlertsCount", void 0);
    __decorate([
        EntityField({
            data: 'Evidence',
            parse: ɵ14,
            defaultValue: 0,
        }),
        __metadata("design:type", Number)
    ], MtpInvestigation.prototype, "threatCount", void 0);
    __decorate([
        EntityField({
            data: 'Evidence',
            arrayOf: EvidenceEntity,
            parse: ɵ15,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "threatEntityTypes", void 0);
    __decorate([
        EntityField({
            data: 'Alerts',
            arrayOf: Alert,
            parse: ɵ16,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "alerts", void 0);
    __decorate([
        EntityField({
            data: 'AnalyzedEntities',
            parse: ɵ17,
        }),
        __metadata("design:type", Number)
    ], MtpInvestigation.prototype, "entityCount", void 0);
    __decorate([
        EntityField({
            data: 'AnalyzedEntities',
            arrayOf: AirsEntityTypeResults,
            parse: ɵ18,
        }),
        __metadata("design:type", Array)
    ], MtpInvestigation.prototype, "entityResults", void 0);
    __decorate([
        EntityField({
            data: 'Evidence',
            parse: ɵ19,
        }),
        __metadata("design:type", RemediationThreatStatusSummary)
    ], MtpInvestigation.prototype, "remediationEntities", void 0);
    __decorate([
        EntityField({ data: 'InvestigationProvider' }),
        __metadata("design:type", String)
    ], MtpInvestigation.prototype, "investigationProvider", void 0);
    __decorate([
        EntityField({
            data: 'InvestigationProvider',
            parse: ɵ20,
        }),
        __metadata("design:type", Boolean)
    ], MtpInvestigation.prototype, "isOfficeInvestigation", void 0);
    __decorate([
        EntityField({ data: 'CommentCount' }),
        __metadata("design:type", Number)
    ], MtpInvestigation.prototype, "commentCount", void 0);
    MtpInvestigation = __decorate([
        Entity({
            singularName: 'Investigation',
            pluralName: 'Investigations',
            endpoint: 'Find/MtpBatch',
            allItemsProperty: 'results',
            baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
            separateArrayParams: true,
            cache: {
                time: ɵ1,
                max: 10,
            },
            parseItemQuery: ɵ2,
            parseData: ɵ3,
        }),
        __metadata("design:paramtypes", [Object])
    ], MtpInvestigation);
    return MtpInvestigation;
}(Investigation));
export { MtpInvestigation };
var ɵ21 = AirsEntityType.URL, ɵ22 = AirsEntityType.IP, ɵ23 = AirsEntityType.Email, ɵ24 = AirsEntityType.MailCluster, ɵ25 = AirsEntityType.MailMessage, ɵ26 = AirsEntityType.Mailbox, ɵ27 = AirsEntityType.SubmissionMail, ɵ28 = AirsEntityType.MailboxConfiguration, ɵ29 = AirsEntityType.File;
/**
 * This is a temporary conversion to convert office entity type case to airs entity type case.
 * We will not use it in the future
 */
export var OfficeEntityTypeValue = {
    url: { name: AirsEntityTypeValue.URL, id: ɵ21 },
    ip: { name: AirsEntityTypeValue.IP, id: ɵ22 },
    email: { name: AirsEntityTypeValue.Email, id: ɵ23 },
    mailCluster: { name: AirsEntityTypeValue.MailCluster, id: ɵ24 },
    mailMessage: { name: AirsEntityTypeValue.MailMessage, id: ɵ25 },
    mailbox: { name: AirsEntityTypeValue.Mailbox, id: ɵ26 },
    SubmissionMail: { name: AirsEntityTypeValue.SubmissionMail, id: ɵ27 },
    MailboxConfiguration: {
        name: AirsEntityTypeValue.MailboxConfiguration,
        id: ɵ28,
    },
    file: { name: AirsEntityTypeValue.File, id: ɵ29 },
};
export function getConvertedEntityTypeName(entityType) {
    return ((OfficeEntityTypeValue[entityType] && OfficeEntityTypeValue[entityType].name) ||
        convertEntityTypeCase(entityType) ||
        AirsEntityTypeValue[entityType]);
}
export function getLastThreatAnalysisSummary(threatAnalysisSummary) {
    var orderedThreatAnalysisSummary = threatAnalysisSummary && orderBy(threatAnalysisSummary, [ANALYSIS_DATE_FIELD], ['desc']);
    return orderedThreatAnalysisSummary && orderedThreatAnalysisSummary.length
        ? orderedThreatAnalysisSummary[0]
        : {};
}
export function getThreatsNames(threatAnalysisSummary) {
    var lastThreatAnalysisSummary = getLastThreatAnalysisSummary(threatAnalysisSummary);
    if (lastThreatAnalysisSummary && lastThreatAnalysisSummary.AnalyzersResult) {
        return getThreatsNamesFromThreatIntelligence(lastThreatAnalysisSummary.AnalyzersResult.map(function (analyzerResult) { return analyzerResult.ThreatIntelligence; }));
    }
    return [];
}
export function getThreatsNamesFromThreatIntelligence(threatIntelligence) {
    return threatIntelligence ? threatIntelligence.map(function (ti) { return ti.ThreatName; }) : [];
}
export function getLastRemediationProvider(remediationProviders) {
    var orderedRemediationProviders = remediationProviders && orderBy(remediationProviders, [REMEDIATION_DATE_FIELD], ['desc']);
    var lastRemediationProvider = (orderedRemediationProviders &&
        orderedRemediationProviders.length &&
        orderedRemediationProviders[0].RemediationProvider) ||
        {};
    return Object.assign({}, lastRemediationProvider, {
        ActionType: getConvertedOfficeActionType(lastRemediationProvider),
    });
}
export function getEngineReportData(threatAnalysisSummary) {
    var lastThreatAnalysisSummary = getLastThreatAnalysisSummary(threatAnalysisSummary);
    var analyzersResult = lastThreatAnalysisSummary && lastThreatAnalysisSummary.AnalyzersResult;
    if (analyzersResult && analyzersResult.length) {
        var orderedAnalyzersResult = orderBy(analyzersResult, [ANALYSIS_DATE_FIELD], ['desc']);
        var lastAnalyzerResult = (orderedAnalyzersResult && orderedAnalyzersResult.length && orderedAnalyzersResult[0]) || {};
        var engineReportStr = lastAnalyzerResult['EngineReport'];
        return engineReportStr && JSON.parse(engineReportStr);
    }
    return null;
}
export function getForwardingSmtpAddressValue(threatAnalysisSummary) {
    var engineReport = getEngineReportData(threatAnalysisSummary);
    var parameters = engineReport && engineReport.Parameters;
    var forwardingSmtpAddressParam = parameters &&
        parameters.find(function (param) { return param.ParameterName === EngineReportParameters.ForwardingSmtpAddress; });
    return (forwardingSmtpAddressParam && forwardingSmtpAddressParam['ParameterValue']) || null;
}
export function getMailboxEntityCommonData(entity) {
    var engineReport = getEngineReportData(entity.ThreatAnalysisSummary);
    return {
        MailboxPrimaryAddress: entity.MailboxPrimaryAddress,
        FirstSeen: entity.FirstSeen,
        ForwardingSmtpAddress: getForwardingSmtpAddressValue(entity.ThreatAnalysisSummary),
        CreatedBy: (engineReport && engineReport.UserId) || null,
        Urn: entity.Urn,
    };
}
export function getRemediationStateId(originalRemediationState, remediationProvider) {
    var remediationStatusType = RemediationStatusType[originalRemediationState];
    if (remediationStatusType && remediationStatusType !== RemediationStatusType.Active) {
        return airsEntityRemediationStatuses.find(function (s) { return s.type === remediationStatusType; }).id;
    }
    if (isEmpty(remediationProvider))
        return null;
    if (remediationProvider.ActionStatus === InvestigationActionStatusType.Running) {
        return RemediationStatusId.Running;
    }
    if (remediationProvider.ActionStatus === InvestigationActionStatusType.Pending) {
        // fix for bug 2589293- MtpBatch API doesn't update the action status immediately when an action is taken
        return PendingActionDecisionTypes[remediationProvider.ActionApproval] ===
            PendingActionDecisionTypes.None
            ? RemediationStatusId.PendingApproval
            : RemediationStatusId.Running;
    }
    if (PendingActionDecisionTypes[remediationProvider.ActionApproval] === PendingActionDecisionTypes.Declined) {
        return RemediationStatusId.Declined;
    }
}
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9, ɵ10, ɵ11, ɵ12, ɵ13, ɵ14, ɵ15, ɵ16, ɵ17, ɵ18, ɵ19, ɵ20, ɵ21, ɵ22, ɵ23, ɵ24, ɵ25, ɵ26, ɵ27, ɵ28, ɵ29 };
