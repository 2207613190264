export function mapOauthApplicationEntityToAirsEntity(entityData) {
    return Object.entries(entityData).reduce(function (acc, _a) {
        var key = _a[0], val = _a[1];
        try {
            switch (key) {
                case "OAuthAppId" /* OAuthAppId */:
                    acc['oauth_app_id'] = val;
                    break;
                case "OAuthObjectId" /* OAuthObjectId */:
                    acc['oauth_object_id'] = val;
                    break;
                case "Name" /* Name */:
                    acc[key] = val; // need to save it in AlertV3 schema in order to calculate the display_name
                    acc['name'] = val;
                    break;
                case "Risk" /* Risk */:
                    acc['risk'] = val;
                    break;
                default:
                    acc[key] = val;
            }
        }
        catch (e) { }
        return acc;
    }, {});
}
