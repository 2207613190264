import { Injectable } from '@angular/core';
import { AppContextService } from '@wcd/config';
import { StoreStorageService } from './store-storage.abstract.service';

/*
    Facade for sessionstorage
 */
@Injectable({ providedIn: 'root' })
export class SessionStorageService extends StoreStorageService {
	constructor(appContext: AppContextService) {
		super(appContext, sessionStorage);
	}
}
