/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectorRef, forwardRef, NgZone, OnInit, } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateRangeModel, TzDateService } from '@wcd/localization';
var DATERANGE_CUSTOM_ACCESSOR = {
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(function () { return DateRangeComponent; }),
    multi: true,
};
var DateRangeComponent = /** @class */ (function () {
    function DateRangeComponent(ngZone, changeDetectorRef, tzDateService) {
        this.ngZone = ngZone;
        this.changeDetectorRef = changeDetectorRef;
        this.tzDateService = tzDateService;
        this.allowExactTimeSelection = false;
        this.earliestDateAllowed = null;
        this.latestDateAllowed = null;
        this.earliestDateAllowedTo = this.earliestDateAllowed;
        this.latestDateAllowedTo = this.latestDateAllowed;
        this.showInPanel = false;
        this.verticalDirection = false;
        this.onChange = function (_) { };
        this.onTouched = function () { };
    }
    Object.defineProperty(DateRangeComponent.prototype, "value", {
        get: function () {
            return this.dateRange;
        },
        set: function (dateRange) {
            if (dateRange !== this.dateRange) {
                this.dateRange = dateRange;
                this.onChange(dateRange);
            }
        },
        enumerable: true,
        configurable: true
    });
    DateRangeComponent.prototype.ngOnInit = function () {
        if (!this.dateRange) {
            this.dateRange = new DateRangeModel(this.tzDateService, new Date(), new Date());
        }
    };
    DateRangeComponent.prototype.writeValue = function (value) {
        var _this = this;
        this.ngZone.run(function () {
            _this.dateRange = value || new DateRangeModel(_this.tzDateService, new Date(), new Date());
            _this.changeDetectorRef.detectChanges();
        });
    };
    DateRangeComponent.prototype.onChangeTo = function () {
        if (!this.allowExactTimeSelection) {
            this.dateRange.to = DateRangeModel.endOfDay(this.dateRange.to);
        }
        this.onChange(this.dateRange);
    };
    DateRangeComponent.prototype.registerOnChange = function (fn) {
        this.onChange = fn;
    };
    DateRangeComponent.prototype.registerOnTouched = function (fn) {
        this.onTouched = fn;
    };
    return DateRangeComponent;
}());
export { DateRangeComponent };
