import { Entity, EntityField, ModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '@wcd/domain';

@Entity({
	singularName: 'Proactive device discovery settings',
	pluralName: 'Proactive device discovery settings',
	endpoint: 'settings/MagellanFeatures',
	parseItemQuery: () => 'settings/v2/MagellanFeatures',
	parseSaveQuery: () => 'settings/MagellanFeatures',
	saveMethod: () => 'PUT',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.k8s,
})
export class MagellanFeatures extends ModelBase {
	static get Empty() {
		return {
			isProactiveDiscoveryEnabled: false,
			get isProactiveDiscoveryEmpty(): boolean {
				return true;
			},
		} as MagellanFeatures;
	}

	static From(response): MagellanFeatures {
		if (!response) {
			return MagellanFeatures.Empty;
		}

		const features = new MagellanFeatures();
		features.isProactiveDiscoveryEnabled = response.IsProactiveDiscoveryEnabled;
		features.isCorelightIntegrationEnabled = response.IsCorelightIntegrationEnabled;
		features.isLog4jScanningEnabled = response.IsLog4jScanningEnabled;
		return features;
	}

	@EntityField({ data: 'IsProactiveDiscoveryEnabled' })
	isProactiveDiscoveryEnabled: boolean;

	@EntityField({ data: 'IsCorelightIntegrationEnabled' })
	isCorelightIntegrationEnabled: boolean;

	@EntityField({ data: 'IsLog4jScanningEnabled' })
	isLog4jScanningEnabled: boolean;

	get isProactiveDiscoveryEmpty(): boolean {
		return typeof this.isProactiveDiscoveryEnabled !== 'boolean';
	}

	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;
}
