import { ChangeDetectorRef, Component } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import { DataExportSettingsEntity, LogProperties } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { cloneDeep } from 'lodash-es';
import { SpinnerSize } from 'office-ui-fabric-react';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { LogType } from '../enums/log-type.enum';
import { ChecklistValue } from '@wcd/forms';
import { AuthService } from '@wcd/auth';
import { DataExportService } from '../services/data-export.service';
import { Feature, FeaturesService } from '@wcd/config';

@Component({
	selector: 'data-export-details',
	templateUrl: './data-export-details.component.html',
	styles: [
		`
			:host {
				height: 100%;
				flex-direction: column;
				display: flex;
			}
		`,
	],
})
export class DataExportSettingsDetailsComponent extends EntityPanelComponentBase<DataExportSettingsEntity> {
	readonly SpinnerSize = SpinnerSize;

	asKeyValueList: boolean = true;
	isDirty: boolean = false;
	selectedEventTypes: Array<ChecklistValue> = [];
	isSaving: boolean = false;
	tooltip: string;
	availableEventTypes: Array<ChecklistValue>;
	isReadOnlyPanel: boolean = false;
	private dataExportRepository: Repository<DataExportSettingsEntity>;
	ruleHaveNonSupportedCategories: boolean = false;

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		changeDetectorRef: ChangeDetectorRef,
		private i18nService: I18nService,
		private entityPanelsService: EntityPanelsService,
		private dataExportService: DataExportService,
		private featureService: FeaturesService,
		private authService: AuthService
	) {
		super(changeDetectorRef);

		this.dataExportRepository = this.paris.getRepository(DataExportSettingsEntity);

		this.availableEventTypes = this.dataExportService.availableLogTypes;
	}

	get isValid(): boolean {
		return this.selectedEventTypes.length > 0;
	}

	onLogTypesChange(event: any) {
		this.isDirty = true;
	}

	get deprecatedEventTypesAreUsed(): boolean {
		return !!this.selectedEventTypes.find(et =>
			this.dataExportService.isDeprecatedLogType(LogType[et.id])
		);
	}

	setEntity(entity: DataExportSettingsEntity) {
		super.setEntity(cloneDeep(entity));

		this.selectedEventTypes = Object.keys(LogType)
			.filter(logType =>
				this.dataExportSettings.logs.some(
					logProps => logProps.enabled && LogType[logType] === logProps.category
				)
			)
			.map(logType => this.dataExportService.logTypeToChecklistValue(logType));

		this.ruleHaveNonSupportedCategories = this.dataExportSettings.logs.some(enabledLog => !Object.values(LogType).find(logTypeKey => logTypeKey === enabledLog.category));
		this.isReadOnlyPanel = !!entity.workspaceProperties || this.ruleHaveNonSupportedCategories;

		if (!this.authService.isGlobalAdmin) {
			this.tooltip = this.i18nService.get(
				'endpointManagement.nonWindows.applications.dataExport.details.noPermissions'
			);
		} else if (this.ruleHaveNonSupportedCategories) {
			this.tooltip = this.i18nService.get(
				'endpointManagement.nonWindows.applications.dataExport.details.mustUseMtpPage'
			);
		}
	}

	save() {
		this.isSaving = true;

		Object.assign(this.entity, {
			logs: this.selectedEventTypes.map(
				checklistValue =>
					<LogProperties>{
						category: LogType[checklistValue.id],
						enabled: this.availableEventTypes.some(logType => logType.id === checklistValue.id),
					}
			),
		});

		this.dataExportRepository.save(this.dataExportSettings).subscribe(
			() => {
				this.isSaving = false;
				this.entityPanelsService.closeEntityPanel(DataExportSettingsEntity);
				this.requestEntitiesRefresh.emit();
				this.changeDetectorRef.markForCheck();
			},
			error => {
				this.isSaving = false;
				let errorMessage = error.message || error;

				if(!this.authService.currentUser.isSecAdmin) {
					const addedMessage = this.i18nService.get('endpointManagement.nonWindows.applications.dataExport.missingAadPermissions', { tenantid: this.authService.tenantId });
					errorMessage += ` (${addedMessage})`;
					error.message = errorMessage;
				}

				this.dialogsService.showError({
					title: this.i18nService.get(
						'endpointManagement.nonWindows.applications.dataExport.details.failedToSave'
					),
					message: errorMessage,
					data: error,
				});
			}
		);
	}

	cancel() {
		this.entityPanelsService.closeEntityPanel(DataExportSettingsEntity);
	}

	get dataExportSettings(): DataExportSettingsEntity {
		return this.entity;
	}

	get userIsGlobalAdmin(): boolean {
		return this.authService.isGlobalAdmin;
	}
}
