import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { ParseDataQuery, TvmAnalyticsBaseUrl } from '../analyticsEndPointUtils';
import { ChangeEventByDate } from './change-event-by-date.value-object';
import { ApiVersionHeaderName } from '../commonEndPointUtils';

export const CHANGE_EVENTS_DOMAIN_KEY = 'eventsDomain';
export const EVENTS_PER_DAY_KEY = 'eventsPerDay';

export enum ChangeEventDomain {
	VA = 'va',
	SCA = 'sca',
}

@ApiCall({
	baseUrl: TvmAnalyticsBaseUrl,
	cache: null,
	customHeaders: { [ApiVersionHeaderName]: '2.0' },
	parseQuery: dataQuery => ({
		params: {
			...ParseDataQuery(dataQuery),
			eventsDomain: dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY],
			eventsPerDay: dataQuery.where[EVENTS_PER_DAY_KEY] || 2,
		},
	}),
	name: 'Top change events per day',
	endpoint: (_, dataQuery) => {
		const domain = dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY];
		delete dataQuery.where[CHANGE_EVENTS_DOMAIN_KEY];
		return `changeEvents/${domain}/topPerDay`;
	},
	type: ChangeEventByDate,
})
export class ChangeEventTopPerDayApiCall extends ApiCallModel<ChangeEventByDate[]> {}
