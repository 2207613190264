import { VulnerabilityFieldsService } from './../../../../@entities/@tvm/vulnerabilities/services/vulnerabilities.fields.service';
import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Vulnerability } from '@wcd/domain';
import { VulnerabilityService } from '../../../../@entities/@tvm/vulnerabilities/services/vulnerability.service';
import { TvmContextOptions } from '../../../../@entities/@tvm/vulnerabilities/components/vulnerability.entity-panel.component';
import { RelationshipRepository, ModelBase } from '@microsoft/paris';
import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';

@Component({
	selector: 'related-cves-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './related-cves.details.component.html',
})
export class RelatedCvesDetailsComponent<T extends ModelBase> implements OnInit {
	_repository: RelationshipRepository<T, Vulnerability>;
	fields: Array<DataviewField<Vulnerability>>;

	@Input()
	set repository(value: RelationshipRepository<T, Vulnerability>) {
		this._repository = value;
	}

	@Input() contextOptions: TvmContextOptions;
	@Input() includeExposedMachinesComponent = true;
	@Input() title: string;
	@Input() maxItems: number;
	@Input() disableSort: boolean = true;
	@Input() customFields: Array<DataviewFieldConfig<Vulnerability>>;
	@Input() isNetworkGearCve?: boolean;
	@Input() initiallyCollapsed = true;

	@Output() showMore = new EventEmitter<void>();
	@Output() export = new EventEmitter<void>();

	private _defaultFields: Array<DataviewFieldConfig<Vulnerability>>;

	constructor(
		private vulnerabilityService: VulnerabilityService,
		private vulnerabilityFieldsService: VulnerabilityFieldsService
	) {
		this._defaultFields = this.vulnerabilityFieldsService.defaultSidePanelFieldsConfig;
	}

	ngOnInit() {
		// Enabling the onclick for adding the tab index to the name column
		this.fields = DataviewField.fromList<Vulnerability>(
			(this.customFields ? this.customFields : this._defaultFields).map(field =>
				field.id !== 'name'
					? field
					: this.vulnerabilityFieldsService.createClickableNameField(
							(vulnerability: Vulnerability) => this.openVulnerabilityPanel(vulnerability)
					  )
			)
		);

		if (this.contextOptions && this.contextOptions.isSingleAsset) {
			this.fields = this.fields.filter(field => field.id !== 'numOfImpactedAssets');
		}
	}

	openVulnerabilityPanel(vulnerability: Vulnerability) {
		this.vulnerabilityService.showVulnerabilityPanel(
			vulnerability,
			this.includeExposedMachinesComponent,
			this.isNetworkGearCve
		);
	}

	openAllVulnerabilities() {
		this.showMore.emit();
	}
}
