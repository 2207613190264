import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { EntityModelBase } from '@microsoft/paris';
import { EntityType } from '../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SearchRouteEntityTypeId, searchRouteIdEntityIdMap } from './main-search-entity-types.service';

@Injectable()
export class SearchEntityTypeResolver<TEntity extends EntityModelBase<string | number>>
	implements Resolve<EntityType<TEntity>> {
	constructor(private readonly globalEntityTypesService: GlobalEntityTypesService) {}

	resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): EntityType<TEntity> {
		const searchEntityTypeId = route.paramMap.get('searchEntityTypeId') as SearchRouteEntityTypeId;
		const entityTypeId = searchRouteIdEntityIdMap[searchEntityTypeId];
		const entityType = this.globalEntityTypesService.getEntityType<TEntity>(entityTypeId);

		if (!entityType) {
			throw new Error("Unknown entity type. Entities must have a 'pluralName' property.");
		}

		return entityType;
	}
}
