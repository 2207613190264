var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { DataviewField } from '@wcd/dataview';
import { Software, FILTER_TAG, FilterDiscoveredVulnerabilities } from '@wcd/domain';
import { TvmImpactScoreComponent } from '../../../../tvm/components/tvm-impact-score/tvm-impact-score.component';
import { TvmThreatIconsComponent } from '../../../../tvm/components/tvm-threat-icons/tvm-threat-icons.component';
import { Router } from '@angular/router';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { TvmTextsService, TextToken } from '../../../../tvm/services/tvm-texts.service';
import { I18nService } from '@wcd/i18n';
import { FilterValuesChecklistComponent } from '@wcd/ng-filters';
import { TvmIconBuilderService } from '../../../../tvm/services/tvm-icon-builder.service';
import { TvmAssetsStatisticsComponent } from '../../../../tvm/components/tvm-assets-statistics/tvm-assets-statistics.component';
import { FeaturesService, Feature } from '@wcd/config';
import { TvmTagsService } from '../../../../tvm/services/tvm-tags.service';
import { TagsFieldComponent } from '../../../../tags/components/tags-field/tags-field.component';
import { ThreatInfoService } from './threat-info.service';
import { TaggedFieldComponent } from '../../../../tvm/components/tagged-field/tagged-field.component';
import { TvmTaggedFieldService } from '../../../../tvm/services/tagged-field.service';
var SoftwareFieldsService = /** @class */ (function () {
    function SoftwareFieldsService(router, tvmTextsService, activatedEntity, i18nService, featuresService, tvmTagsService, tvmIconBuilderService, tvmTaggedFieldService, threatInfoService) {
        this.router = router;
        this.tvmTextsService = tvmTextsService;
        this.activatedEntity = activatedEntity;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.tvmTagsService = tvmTagsService;
        this.tvmIconBuilderService = tvmIconBuilderService;
        this.tvmTaggedFieldService = tvmTaggedFieldService;
        this.threatInfoService = threatInfoService;
        this.isNewExploitExperienceEnabled = featuresService.isEnabled(Feature.TvmExploits);
        this.hasNetworkScanEnabled = this.featuresService.isEnabled(Feature.TvmNetworkScan);
    }
    Object.defineProperty(SoftwareFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                this._fields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.get('tvm.common.name'),
                        component: {
                            type: TaggedFieldComponent,
                            getProps: function (software) {
                                var zeroDayProps = _this.tvmTaggedFieldService.getZeroDayBaseProps(software.mostSevereVulnerabilityType, software.patchReleaseDate, software.name);
                                return __assign({}, zeroDayProps, { onClick: function (software) {
                                        _this.activatedEntity.setNextEntity(software);
                                        _this.router.navigate([
                                            "/software-inventory/" + encodeURIComponent(software.id),
                                        ]);
                                    }, value: software });
                            },
                        },
                    },
                    {
                        id: 'osPlatform',
                        name: this.i18nService.get('tvm_common_osPlatform'),
                        sort: { enabled: false },
                        getDisplay: function (software) {
                            return software.osPlatform
                                ? _this.i18nService.get("tvm_common_osPlatform_" + software.osPlatform)
                                : _this.i18nService.strings.notAvailable_short;
                        },
                    },
                    {
                        id: 'vendor',
                        name: this.i18nService.get('tvm.common.vendor'),
                    },
                    {
                        id: 'discoveredVulnerabilities',
                        name: this.i18nService.get('tvm.common.weaknesses'),
                        getDisplay: function (software) {
                            return software.productNeverMatched
                                ? _this.i18nService.get('notAvailable.long')
                                : "" + software.discoveredVulnerabilities;
                        },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (filterValue) {
                                        var values = [
                                            {
                                                value: FilterDiscoveredVulnerabilities.hasWeaknesses,
                                                name: _this.i18nService.get('tvm.softwarePage.filters.discoveredVulnerabilities.hasWeaknesses'),
                                                priority: 1,
                                            },
                                            {
                                                value: FilterDiscoveredVulnerabilities.noWeaknesses,
                                                name: _this.i18nService.get('tvm.softwarePage.filters.discoveredVulnerabilities.noWeaknesses'),
                                                priority: 2,
                                            },
                                            {
                                                value: FilterDiscoveredVulnerabilities.notAvailable,
                                                name: _this.i18nService.get('notAvailable.long'),
                                                priority: 3,
                                            },
                                        ];
                                        var value = values.find(function (val) { return val.value === FilterDiscoveredVulnerabilities[filterValue]; });
                                        return {
                                            id: value.value,
                                            rawValue: value.value,
                                            name: value.name,
                                            priority: value.priority,
                                        };
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'threats',
                        name: this.i18nService.get('tvm.common.threats'),
                        sort: {
                            enabled: false,
                        },
                        component: {
                            type: TvmThreatIconsComponent,
                            getProps: function (software) {
                                return _this.tvmIconBuilderService.configureIcons(software.threatInfo, software.threats, 'software', false, null, true);
                            },
                        },
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (filterValue) {
                                        var values = _this.threatInfoService.getFiltersData();
                                        var value = values.find(function (val) { return val.value === filterValue; });
                                        return {
                                            id: value.value,
                                            rawValue: value.value,
                                            name: value.name,
                                            priority: value.priority,
                                        };
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'exposedDevicesWithTrends',
                        name: this.i18nService.get('tvm.common.exposedDevices'),
                        sort: {
                            enabled: false,
                        },
                        component: {
                            type: TvmAssetsStatisticsComponent,
                            getProps: function (software) { return ({
                                totalAssetCount: software.assetsStatistics.totalAssetCount,
                                assetsCount: software.assetsStatistics.assetsAtRiskCount,
                                assetsCountHistory: software.assetsStatistics.assetsAtRiskHistory,
                                productNeverMatched: software.productNeverMatched,
                            }); },
                        },
                        allowResize: false,
                        minWidth: 200,
                    },
                    {
                        id: 'riskScore',
                        name: this.i18nService.get('tvm.common.impact'),
                        description: this.tvmTextsService.getText(TextToken.ScoreImpactInfo),
                        sort: {
                            sortDescendingByDefault: true,
                        },
                        component: {
                            type: TvmImpactScoreComponent,
                            getProps: function (software) { return ({
                                scoreData: {
                                    exposureScore: software.exposureScoreImprovement,
                                    exposureScoreTooltip: _this.tvmTextsService.getText(TextToken.ExposureScoreTooltip),
                                },
                            }); },
                        },
                    },
                    {
                        id: 'filterTags',
                        name: this.i18nService.get('common.tags'),
                        filterOnly: true,
                        filter: {
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    mapFilterValue: function (filterValue) {
                                        var values = _this.tvmTagsService
                                            .getSoftwareTagsForFiltering()
                                            .map(function (tag) {
                                            return {
                                                value: FILTER_TAG[tag],
                                                name: _this.i18nService.get("tvm_softwarePage_filters_tags_" + tag),
                                                count: null,
                                            };
                                        });
                                        var v = values.find(function (val) { return val.value === FILTER_TAG[filterValue]; });
                                        return {
                                            id: v.value,
                                            rawValue: v.value,
                                            name: v.name,
                                        };
                                    },
                                },
                            },
                        },
                    },
                    {
                        id: 'tags',
                        name: this.i18nService.get('common.tags'),
                        component: {
                            type: TagsFieldComponent,
                            getProps: function (software) { return ({
                                tags: _this.tvmTagsService.getSoftwareTags(software),
                            }); },
                        },
                        sort: { enabled: false },
                    },
                ]).filter(Boolean);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SoftwareFieldsService.prototype, "topVulnerableSoftwareWidgetFields", {
        get: function () {
            var _this = this;
            if (!this._topVulnerableSoftwareWidgetFields) {
                this._topVulnerableSoftwareWidgetFields = DataviewField.fromList([
                    {
                        id: 'name',
                        name: this.i18nService.get('tvm.common.software'),
                        getTooltip: function (software) { return software.name; },
                        component: {
                            type: TaggedFieldComponent,
                            getProps: function (software) {
                                var zeroDayProps = _this.tvmTaggedFieldService.getZeroDayBaseProps(software.mostSevereVulnerabilityType, software.patchReleaseDate, software.name);
                                return __assign({}, zeroDayProps, { value: software });
                            },
                        },
                    },
                ]).concat(this.fields.filter(function (field) {
                    return field.id === 'osPlatform' ||
                        field.id === 'discoveredVulnerabilities' ||
                        field.id === 'threats' ||
                        field.id === 'exposedDevicesWithTrends';
                }));
            }
            return this._topVulnerableSoftwareWidgetFields;
        },
        enumerable: true,
        configurable: true
    });
    return SoftwareFieldsService;
}());
export { SoftwareFieldsService };
