var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { NgZone } from '@angular/core';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Store } from '../../../data/models/store.base';
import { AdvancedSettingsBackendService } from './advanced-settings.backend.service';
import { ADVANCED_SETTINGS_ID, AdvancedSettingsModel, } from '../models/advanced-settings.model';
import { merge } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
var AdvancedSettingsStore = /** @class */ (function (_super) {
    __extends(AdvancedSettingsStore, _super);
    function AdvancedSettingsStore(backendService, dialogsService, i18nService, ngZone) {
        var _this = _super.call(this, backendService, ngZone, AdvancedSettingsModel, dialogsService, i18nService, {
            itemNameSingular: 'Setting',
            itemNamePlural: 'Settings',
            iconName: 'settings',
        }) || this;
        _this.backendService = backendService;
        _this.dialogsService = dialogsService;
        _this.i18nService = i18nService;
        return _this;
    }
    // gets advanced settings by combining "getAdvancedSettings"
    AdvancedSettingsStore.prototype.getAdvancedSettings = function () {
        return this.getItemById(ADVANCED_SETTINGS_ID);
    };
    AdvancedSettingsStore.prototype.parseData = function (data) {
        return merge({}, data.data || data); // backend returns an object with "items" root on GET requests and without items on PUT requests
    };
    return AdvancedSettingsStore;
}(Store));
export { AdvancedSettingsStore };
